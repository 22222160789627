import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import { useAppDispatch } from "hooks/redux";
import axios, { CancelToken } from "axios";

import { PostTvView, TPostTvView } from "api/rpc/2024-04/facilityAdmin/league/tvView/tvView";
import { StatusCode } from "api/protocols";

import { showError, showSuccess } from "redux/actions/ui";

import Card from "components/card/Card";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";

import Page from "components/page/Page";

export default function LeagueTvViewNew() {
  const { leagueId } = useParams<{ leagueId: string }>();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [newViewState, setNewViewState] = useState<TPostTvView>({
    league_id: leagueId,
    title: "",
  });

  async function createTvView() {
    const viewRes = await PostTvView(newViewState, true);

    if (viewRes.status !== StatusCode.OK) {
      dispatch(showError("Error creating TV view")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("TV view created successfully"));
    history.push(`/admin/league/${leagueId}/scoring/tv-views`);
  }

  const primaryAction = {
    content: "Create", // TODO: Translation
    action: createTvView,
  };

  return (
    <Page
      title="New TV View" // TODO: Translation
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: "Tv Views", // TODO: Translation
          url: `/admin/league/${leagueId}/scoring/tv-views`,
        },
      ]}
    >
      <Card>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Input
                label={"Title"} // TODO: Translation
                value={newViewState.title}
                onChange={e => setNewViewState(prev => ({ ...prev, title: e.target.value }))}
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>
    </Page>
  );
}
