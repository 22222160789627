import React from "react";
import Checkbox, { ICheckboxProps } from "components/form/checkbox/Checkbox";
import classNames from "classnames";
import "./newCheckboxGroup.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "components/form/input/Input";

/**

 * @param label Title of checkbox 
 * @param checked Boolean value determining if primary checkbox is checked
 * @param onChange function to handle primary checkbox change
 * @param onSecondaryChange function to handle secondary options change
 * @param secondaryContent Secondary checkbox options shown when main checkbox is clicked
 * @param selectedSecondaryContent Selected secondary checkbox option Ids
 */

interface INewCheckboxGroupProps {
  label: string;
  checked: boolean;
  onChange: () => void;
  onSecondaryChange?: (id?: any) => void;
  secondaryContent?: any;
  selectedSecondaryContent?: any;
  primaryContent?: any;
  selectedPrimaryContent?: any;
}

type NewCheckboxGroupProps = Omit<INewCheckboxGroupProps, "onChange"> & {
  label: string;
  checked: boolean;
  onChange: (primaryContent?: any) => void;
  onSecondaryChange?: (id?: any) => void;
  secondaryContent?: any;
  selectedSecondaryContent?: any;
  primaryContent?: any;
  selectedPrimaryContent?: any;
};

export default function NewCheckboxGroup(props: NewCheckboxGroupProps) {
  const { secondaryContent, selectedSecondaryContent, ...rest } = props;

  function handlePrimaryClick(primaryContent: any) {
    props.onChange(primaryContent);
  }

  function handleSecondaryClick(e: React.MouseEvent<HTMLDivElement>, id?: any) {
    props.onSecondaryChange(id);
  }

  return (
    <div
      className={classNames("new-checkbox-group-container", {
        "new-checkbox-group-container_selected": props.checked,
      })}
    >
      <div
        onClick={() => handlePrimaryClick(props.primaryContent)}
        className={classNames("new-checkbox-group-primary", {
          "new-checkbox-group-primary_selected": props.checked,
        })}
      >
        <div className="pointer-events-none">
          <Checkbox size="small" ariaChecked={props.checked} {...rest} />
        </div>
      </div>

      {props.checked ? (
        <div>
          {secondaryContent?.map((content: any, index: number) => {
            return (
              <div
                key={index}
                onClick={e => handleSecondaryClick(e, content.id)}
                className={classNames("new-checkbox-group-secondary", {
                  "new-checkbox-group-secondary_selected": selectedSecondaryContent.includes(content.id),
                })}
              >
                <div>Include {content.title}</div>
                <div>
                  {selectedSecondaryContent.includes(content.id) && <FontAwesomeIcon icon={["far", "check"]} />}
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}
