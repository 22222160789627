import { APIGet, APIPost, APIPut, APIDelete, StatusCode } from "api/protocols";
import { IConfiguration } from "api/rpc/clientAdmin/client/report";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseReportUrl = "/" + apiVersion + "/" + adminType + "/facility/report";

//GET Report
export interface IReport {
  id: number;
  title: string;
  code: string;
  configurations?: Array<IConfiguration>;
  client_id: string | null;
  category: string;
  sub_category: string | null;
  data: [];
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
}

type IReportType = "Financial" | "Inventory" | "Customers" | "Membership";
interface IGetCSVDownload {
  report_id?: number;
  type?: string;
  status: StatusCode;
}
interface IGetReport {
  id?: number;
  code?: string;
  search?: string;
  configurations?: Array<IConfiguration>;
}

//Retrieve CSV Download URL
export async function GetCSVDownload(params: any, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseReportUrl + "/run", params, useGlobalLoader, token);
}

//Retrive Report
export async function GetReport(params: IGetReport, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<IReport[]>(baseReportUrl, params, useGlobalLoader, token);
}

// Retrive Data for a Report
export async function GetReportRun(params: any, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseReportUrl + "/run", params, useGlobalLoader, token);
}
