import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UIActions from "redux/actions/ui";
import CustomerBookingConfirmation from "pages/guest/Customer/CustomerBookingConfirmation";
import * as BookingEngineActions from "redux/actions/bookingEngine";

const mapStateToProps = (state: any) => {
  return {
    bookingEngineStore: { ...state.bookingEngineStore },
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    bookingEngineActions: bindActionCreators(
      {
        ...BookingEngineActions,
      },
      dispatch,
    ),
    uiActions: bindActionCreators(
      {
        ...UIActions,
      },
      dispatch,
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerBookingConfirmation);
