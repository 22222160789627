import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { useAppDispatch } from "hooks/redux";
import { showSuccess, showError } from "redux/actions/ui";

import { PostPowerCarts } from "api/rpc/2022-09/facilityAdmin/facility/powercart/powercart";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Input from "components/form/input/Input";
import FormLayout from "components/form/FormLayout";
import { Select } from "components/select/index";
import Tabs from "components/tabs/Tabs";
import { StatusCode } from "api/protocols";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface INewInventoryState {
  multipleCarts: boolean;
  cartsAmount: number;
  cartName: string;
  cartNamePrefix: string;
  type: string;
  brand: string;
  starting_number: number;
}

export default function NewCartInventory() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { Option } = Select;

  const [newInventoryState, setNewInventoryState] = useState<INewInventoryState>({
    multipleCarts: false,
    cartsAmount: 0,
    cartName: "",
    cartNamePrefix: "",
    type: "",
    brand: "",
    starting_number: 0,
  });

  const tabs = [
    {
      id: "one-cart",
      content: "1 Cart",
      panelID: "one-cart",
    },

    {
      id: "multiple-carts",
      content: "Multiple Carts",
      panelID: "multiple-carts",
    },
  ];

  // Handle tab change
  const [selected, setSelected] = useState(0);
  const handleTabChange = (selectedTabIndex: number) => {
    setSelected(selectedTabIndex);
  };

  function handleDropDownChange(value: any, property: string) {
    setNewInventoryState(prevState => ({ ...prevState, [property]: value }));
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setNewInventoryState(prevState => ({ ...prevState, [id]: value }));
  }

  async function addToFleet() {
    const powerCartRes = await PostPowerCarts(
      {
        amount: selected === 0 ? 1 : newInventoryState.cartsAmount,
        name: newInventoryState.cartName,
        brand: newInventoryState.brand,
        type: newInventoryState.type,
        starting_number: newInventoryState.starting_number,
      },
      true,
    );

    if (powerCartRes.status !== StatusCode.OK) {
      dispatch(showError("Error creating carts"));
      return;
    }

    dispatch(showSuccess("Carts created successfully"));

    history.push("/admin/settings/cart-inventory");
  }

  const primaryAction = {
    content: (
      <>
        <FontAwesomeIcon icon={["far", "plus"]} className="mr-2" />
        {"Add to Fleet"}
      </>
    ),
    action: addToFleet,
  };

  return (
    <Page
      title="New Cart Inventory"
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: "Back to cart inventory",
          url: "/admin/settings/cart-inventory",
        },
      ]}
    >
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}></Tabs>
      <Card>
        <Card.Section title="Inventory Settings">
          <FormLayout>
            <FormLayout.Group>
              {selected === 0 ? (
                <Input
                  value={newInventoryState.cartName ?? ""}
                  label={"Cart Name"}
                  id="cartName"
                  onChange={handleInputChange}
                />
              ) : (
                <div className="flex justify-between">
                  <Input
                    value={newInventoryState.cartsAmount ?? ""}
                    label={"Amount of Carts"}
                    id="cartsAmount"
                    onChange={handleInputChange}
                    type="number"
                    className="mr-2"
                  />
                  <Input
                    value={newInventoryState.starting_number ?? ""}
                    label={"Starting Number"}
                    id="starting_number"
                    onChange={handleInputChange}
                    type="number"
                  />
                </div>
              )}
              <Select
                defaultValue={"gas"}
                label={"Type"}
                onChange={(value: any) => handleDropDownChange(value, "type")}
              >
                <Option key={0} name={"gas"} value={"gas"}>
                  <span>Gas</span>
                </Option>
                <Option key={1} name={"electric"} value={"electric"}>
                  <span>Electric</span>
                </Option>
              </Select>
              <Input value={newInventoryState.brand ?? ""} label={"Brand"} id="brand" onChange={handleInputChange} />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>
    </Page>
  );
}
