import LeagueCheckout from "pages/guest/League/LeagueRegistration/LeagueCheckout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UIActions from "redux/actions/ui";
import * as CustomerCartActions from "redux/actions/customer/cart";
import * as LeagueRegistrationActions from "redux/actions/customer/leagueRegistration";

const mapStateToProps = (state: any) => {
  return {
    cartStore: { ...state.customerCartStore },
    leagueRegistrationStore: { ...state.leagueRegistrationStore },
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    cartActions: bindActionCreators(
      {
        ...CustomerCartActions,
      },
      dispatch,
    ),
    uiActions: bindActionCreators(
      {
        ...UIActions,
      },
      dispatch,
    ),
    leagueRegistrationActions: bindActionCreators(
      {
        ...LeagueRegistrationActions,
      },
      dispatch,
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeagueCheckout);
