import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";

const apiVersion = "2022-09";
const newApiVersion = "2024-04";
const adminType = "client-admin";
const baseAccountUrl = "/" + apiVersion + "/" + adminType + "/client/account";

export async function GetHouseAccount(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseAccountUrl, params, useGlobalLoader);
}

export async function GetAccountTransactions(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseAccountUrl + "/transaction", params, useGlobalLoader);
}

export async function GetAccountStatements(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseAccountUrl + "/statement", params, useGlobalLoader);
}

export async function GetScheduledPayment(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseAccountUrl + "/scheduled-payment", params, useGlobalLoader);
}

export async function PostScheduledPayment(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseAccountUrl + "/scheduled-payment", params, useGlobalLoader);
}

export async function PutScheduledPayment(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseAccountUrl + "/scheduled-payment", params, useGlobalLoader);
}

export async function CancelScheduledPayment(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseAccountUrl + "/scheduled-payment/cancel", params, useGlobalLoader);
}
