import { APIGet, APIPost, APIPut, APIDelete, APIUrl, AdminType } from "api/protocols";
import { CancelToken } from "axios";
import { HouseAccountType } from "pages/secure/facility/customer/context/contextTypes";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/client/account";

export type TGetAccount = {
  id?: number;
  account_number?: string;
  customer_id?: number;
  /** Return closed accounts */
  closed?: boolean;
  /** Search By
   * - account_number
   * - title
   * - customer first_name
   * - customer last_name
   */
  search?: string;
  limit?: number;
  offset?: number;
};

export type TPostAccount = {
  customer_id?: number;
  global?: boolean;
  title: string;
  account_number?: string;
  currency: string;
  // limit?: number;
  accounting_reference_id?: number;
};

export type TPutAccount = {
  id: number;
  title: string;
  customer_id?: number;
  account_number?: string;
  // limit?: number;
  accounting_reference_id?: number;
  tournament_id?: number;
  league_id?: number;
  global?: boolean;
};

export async function GetAccount(params: TGetAccount, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<HouseAccountType[]>(
    APIUrl(AdminType.FacilityAdmin, "/client/account"),
    params,
    useGlobalLoader,
    token,
  );
}

export async function PostAccount(params: TPostAccount, useGlobalLoader: boolean) {
  return await APIPost(APIUrl(AdminType.FacilityAdmin, "/client/account"), params, useGlobalLoader);
}

export async function PutAccount(params: TPutAccount, useGlobalLoader: boolean) {
  return await APIPut(APIUrl(AdminType.FacilityAdmin, "/client/account"), params, useGlobalLoader);
}

export async function PutAccountBalanceRefresh(params: { account_id: number }, useGlobalLoader: boolean) {
  return await APIPut(APIUrl(AdminType.FacilityAdmin, "/client/account/update"), params, useGlobalLoader);
}

export async function PutCloseHouseAccount(params: { account_id: number }, useGlobalLoader: boolean) {
  return APIPut(APIUrl(AdminType.FacilityAdmin, "/client/account/close"), params, useGlobalLoader);
}

export async function PostImportAccounts(params: FormData, useGlobalLoader: boolean) {
  return APIPost(APIUrl(AdminType.FacilityAdmin, "/client/account/import"), params, useGlobalLoader);
}

export async function GetAccountTransactions(params: any, useGlobalLoader: boolean) {
  return await APIGet(APIUrl(AdminType.FacilityAdmin, "/client/account/transaction"), params, useGlobalLoader);
}
