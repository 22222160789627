import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import { StatusCode } from "api/protocols";
import { GetLeague } from "api/rpc/2024-04/facilityAdmin/league/league";
import { GetFacility } from "api/rpc/2022-09/facilityAdmin/facility/facility";
import { GetAnnouncement, TLeagueAnnouncement } from "api/rpc/2024-04/facilityAdmin/league/announcement";
import { GetParticipant } from "api/rpc/2024-04/facilityAdmin/league/participant";
import { GetLeagueScoresheets } from "api/rpc/2024-04/facilityAdmin/league/scoring/scoresheet";

import { showError } from "redux/actions/ui";
import { ILeague, ILeagueParticipant, TLeagueScoresheet } from "redux/reducers/models/league";
import { IFacility } from "redux/reducers/models/facility";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import Card from "components/card/Card";
import { ButtonNew as Button } from "components/buttonNew";
import CopyLink from "components/copyLink/CopyLink";
import { Badge } from "components/badge/Badge";

import "./League.scss";

interface ILeagueState {
  league: ILeague;
  facility: IFacility;
  announcements: TLeagueAnnouncement[];
  participants: ILeagueParticipant[];
  latestScoresheet: TLeagueScoresheet;
}

const ANNOUNCEMENT_LIMIT = 1;
const PARTICIPANT_LIMIT = 3;

export default function League() {
  const { leagueId } = useParams<{ leagueId: string }>();
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const dispatch = useAppDispatch();

  const [state, setState] = useState<ILeagueState>({
    league: undefined,
    facility: undefined,
    announcements: undefined,
    participants: undefined,
    latestScoresheet: undefined,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadLeague(leagueId, source.token);
    return () => source.cancel();
  }, [leagueId]);

  async function loadLeague(leagueId: string | number, token?: CancelToken) {
    const leagueRes = await GetLeague({ id: leagueId }, true, token);

    if (token && token.reason) {
      return;
    }
    if (leagueRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.league.league.001")));
      return;
    }

    const league = leagueRes.data[0];

    setState(prevState => ({
      ...prevState,
      league,
    }));

    const facilityRes = await GetFacility({ id: league.facility_id }, true, token);

    if (token && token.reason) {
      return;
    }
    if (facilityRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.league.league.002")));
      return;
    }

    const facility = facilityRes.data?.[0];

    if (facilityRes.status === StatusCode.OK) {
      setState(prevState => ({
        ...prevState,
        facility,
      }));
    }

    // Load recent announcements
    const announcementRes = await GetAnnouncement(
      {
        league_id: league.id,
        limit: ANNOUNCEMENT_LIMIT,
      },
      true,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (announcementRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading league announcements")); // TODO: Translation
    } else {
      setState(prevState => ({
        ...prevState,
        announcements: announcementRes.data,
      }));
    }

    // Load recent registrations
    const participantRes = await GetParticipant(
      {
        league_id: league.id,
        limit: PARTICIPANT_LIMIT,
      },
      true,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (participantRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading league participants")); // TODO: Translation
    } else {
      setState(prevState => ({
        ...prevState,
        participants: participantRes.data,
      }));
    }

    // Load latest scoresheet
    const scoresheetRes = await GetLeagueScoresheets(
      {
        league_id: league.id,
        latest: true,
      },
      true,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (scoresheetRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading latest scoresheet")); // TODO: Translation
    } else {
      setState(prevState => ({
        ...prevState,
        latestScoresheet: scoresheetRes.data[0],
      }));
    }
  }

  return state.league ? (
    <Page title={state.league.name} splitLayout>
      <Page.Section twoThirds>
        <Card title="Current Round" /* TODO: Translation */>
          <Card.Section>
            {state.latestScoresheet ? (
              <div className="league-current-round">
                <div className="league-current-round-header">{state.latestScoresheet?.name}</div>
                <div className="league-current-round-info">
                  {moment(state.latestScoresheet?.date_played).format("MMM DD, YYYY")}
                  <div className="flex justify-start gap-1">
                    {state.latestScoresheet?.front_division_title}
                    {state.latestScoresheet?.division_back_id && (
                      <>
                        <FontAwesomeIcon className="mt-1" icon={"arrow-right"} />
                        {state.latestScoresheet?.back_division_title}
                      </>
                    )}
                  </div>
                  {`${state.latestScoresheet?.participation} Rounds Played` /* TODO: Translation */}
                </div>
                <div className="league-current-round-actions">
                  <Button
                    onClick={() =>
                      history.push(`/admin/league/${leagueId}/rounds/scoresheet/${state.latestScoresheet?.id}/scores`)
                    }
                  >
                    Enter Scores {/* TODO: Translation */}
                    <FontAwesomeIcon className="ml-2" icon={"arrow-right"} />
                  </Button>
                  <Button
                    onClick={() =>
                      history.push(
                        `/admin/league/${leagueId}/rounds/scoresheet/${state.latestScoresheet?.id}/leaderboard`,
                      )
                    }
                  >
                    Results {/* TODO: Translation */}
                    <FontAwesomeIcon className="ml-2" icon={"arrow-right"} />
                  </Button>
                </div>
              </div>
            ) : (
              <div className="league-current-round">
                <div className="league-current-round-header">{"No Rounds Created" /* TODO: Translation */}</div>
                <div className="league-current-round-actions">
                  <Button onClick={() => history.push(`/admin/league/${leagueId}/rounds/scoresheet/new`)}>
                    Create Round {/* TODO: Translation */}
                    <FontAwesomeIcon className="ml-2" icon={"arrow-right"} />
                  </Button>
                </div>
              </div>
            )}
          </Card.Section>
        </Card>
        <Card>
          <Card.Section
            title="Details" // TODO: Translation
            sectionTitleActions={[
              {
                content: <FontAwesomeIcon icon={["far", "pencil"]} />,
                action: () => history.push(`/admin/league/${leagueId}/league-settings/general`),
              },
            ]}
          >
            <div className="flex justify-start gap-2">
              {moment(state.league?.start_date).format("MMM DD, YYYY")}
              <FontAwesomeIcon className="mt-1" icon={"arrow-right"} />
              {moment(state.league?.end_date).format("MMM DD, YYYY")}
            </div>
            <div className="flex justify-between">
              {`${state.league?.total_participant_count} Participants` /* TODO: Translation */}
              <div className="flex justify-start gap-2">
                {state.league?.capacity_remaining > 0 ? (
                  <Badge type="success">{
                    `${state.league?.capacity_remaining} Spots Left` /* TODO: Translation */
                  }</Badge>
                ) : (
                  <Badge type="error">{`League is Full` /* TODO: Translation */}</Badge>
                )}
                {state.league?.capacity_remaining > 0 ? (
                  <Badge type="success">{
                    `${state.league?.total_paid_participant_count} Paid` /* TODO: Translation */
                  }</Badge>
                ) : (
                  <Badge type="gray">{`0 Paid` /* TODO: Translation */}</Badge>
                )}
                {state.league?.total_participant_count - state.league?.total_paid_participant_count > 0 ? (
                  <Badge type="warning">{
                    `${
                      state.league?.total_participant_count - state.league?.total_paid_participant_count
                    } Unpaid` /* TODO: Translation */
                  }</Badge>
                ) : (
                  <Badge type="gray">{`0 Unpaid` /* TODO: Translation */}</Badge>
                )}
              </div>
            </div>
            <div>
              {state?.league.display_online ? (
                <div className="flex justify-start gap-2">
                  <FontAwesomeIcon className="mt-1" icon={"circle-check"} color={"#46B171"} />
                  {"League is being displayed online" /* TODO: Translation */}
                </div>
              ) : (
                <div className="flex justify-start gap-2">
                  <FontAwesomeIcon className="mt-1" icon={"circle-x"} color={"#AA0504"} />
                  {"League is not being displayed online" /* TODO: Translation */}
                </div>
              )}
            </div>
          </Card.Section>
          <Card.Section
            title="Registration" // TODO: Translation
            sectionTitleActions={[
              {
                content: <FontAwesomeIcon icon={["far", "pencil"]} />,
                action: () => history.push(`/admin/league/${leagueId}/league-settings/registration`),
              },
            ]}
          >
            {state.league.registration_open ? (
              <div>
                <div className="mb-2">{"Registration is Open!" /* TODO: Translation */}</div>
                <CopyLink
                  label="Registration Link"
                  link={window.origin + `/league/${state.facility?.short_name}/${state.league?.handle}/register`}
                />
              </div>
            ) : (
              <div>{"Registration is Not Enabled" /* TODO: Translation */}</div>
            )}
          </Card.Section>
        </Card>
      </Page.Section>
      <Page.Section oneThird>
        <Card title="Latest Announcement" /* TODO: Translation */>
          {state.announcements?.length > 0 ? (
            <div className="league-home-announcements">
              {state.announcements.map(announcement => (
                <Card.Section key={announcement.id}>
                  <div className="league-home-announcements-header">{announcement.subject}</div>
                  <div className="league-home-announcements-details">
                    {moment(announcement.created_at).format("MMM DD, YYYY")}
                  </div>
                  <div className="league-home-announcements-content">{announcement.content}</div>
                </Card.Section>
              ))}
              <Card.Section>
                <div
                  className="flex justify-start gap-2"
                  onClick={() => history.push(`/admin/league/${leagueId}/announcements`)}
                >
                  {"View All Announcements" /* TODO: Translation */}
                  <FontAwesomeIcon className="mt-1" icon={"arrow-right"} />
                </div>
              </Card.Section>
            </div>
          ) : (
            <Card.Section>
              <p>{"No Announcements" /* TODO: Translation */}</p>
            </Card.Section>
          )}
        </Card>
        <Card title="Latest Registrations" /* TODO: Translation */>
          {state.participants?.length > 0 ? (
            <>
              {state.participants.map(participant => (
                <Card.Section key={participant.id}>{participant.customer.full_name}</Card.Section>
              ))}
              <Card.Section>
                <div
                  className="flex justify-start gap-2"
                  onClick={() => history.push(`/admin/league/${leagueId}/players`)}
                >
                  {"View All Players" /* TODO: Translation */}
                  <FontAwesomeIcon className="mt-1" icon={"arrow-right"} />
                </div>
              </Card.Section>
            </>
          ) : (
            <Card.Section>
              <p>{"No Participants" /* TODO: Translation */}</p>
            </Card.Section>
          )}
        </Card>
      </Page.Section>
    </Page>
  ) : (
    <div />
  );
}
