import classNames from "classnames";
import { ButtonNew } from "components/buttonNew";
import Sheet from "components/sheet/Sheet";
import { PrinterStatus, usePOSPrinter } from "hooks/usePOSPrinter/usePOSPrinter";
import React, { useEffect, useState } from "react";
import "components/posPrinterStatus/POSPrinterStatus.scss";
import { store } from "index";
import Input from "components/form/input/Input";
import FormLayout from "components/form/FormLayout";
import Checkbox from "components/form/checkbox/Checkbox";

export default function POSPrinterStatus() {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [address, setAddress] = useState<string>("");
  const [port, setPort] = useState<number>(-1);
  const {
    loadPrinters,
    loadingPrinters,
    printers,
    defaultPrinter,
    printerStatus,
    updatePOSServerUri,
    setDefaultPOSPrinter,
  } = usePOSPrinter();

  const disableTestButton =
    printerStatus === PrinterStatus.Connected ||
    printerStatus === PrinterStatus.Connecting ||
    printerStatus === PrinterStatus.Printing;

  return (
    <div>
      <span className={classNames("admin-current-active-user")}>
        <ButtonNew
          onClick={() =>
            setModalVisible(v => {
              if (!loadingPrinters && v === false) {
                void loadPrinters();
              }
              return !v;
            })
          }
          type="text"
          size="small"
        >
          {defaultPrinter === undefined ? "POS Printer" : defaultPrinter.title}
        </ButtonNew>
      </span>
      <Sheet
        size="medium"
        title="Printers"
        open={modalVisible}
        onOk={() => updatePOSServerUri(address, port)}
        okText="Set POS Server Address"
        onCancel={() => setModalVisible(v => !v)}
      >
        <div>
          <div className="mb-4">
            {store.getState().facilityStore.printerSettings ? (
              <div className="flex-1 flex-column">
                <span className="font-bold">POS Server Address: </span>
                <span>
                  {store.getState().facilityStore.printerSettings.print_server_ip
                    ? store.getState().facilityStore.printerSettings.print_server_ip
                    : "No IP Set. "}
                  :
                  {store.getState().facilityStore.printerSettings.print_server_port
                    ? store.getState().facilityStore.printerSettings.print_server_port
                    : " No Port Set."}
                </span>
              </div>
            ) : null}
            <div className="mt-2">
              <FormLayout>
                <FormLayout.Group>
                  <div className={classNames("input-raw-container")}>
                    <Input onChange={e => setAddress(e.target.value)} value={address} label="Print Server Address" />
                  </div>
                  <div className={classNames("input-raw-container")}>
                    <Input onChange={e => setPort(Number(e.target.value))} value={port} label="Print Server Port" />
                  </div>
                </FormLayout.Group>
              </FormLayout>
            </div>
          </div>
          {printers.length > 0 && loadingPrinters === false ? (
            <span>Select a printer in the list below to set it as the printer to print order receipts.</span>
          ) : (
            <span>Add a printer to set it as the receipt printer</span>
          )}
        </div>
        {loadingPrinters ? (
          <span>loading...</span>
        ) : (
          <div className="ui-checkbox-group mb-2">
            {printers.map((printer: any, index: number) => {
              return (
                <div
                  key={String(printer.title) + String(printer.id)}
                  onClick={() => setDefaultPOSPrinter(printer)}
                  className={classNames("ui-checkbox-group-item mt-2", {
                    "ui-checkbox-group-item_selected": defaultPrinter?.id === printer.id,
                  })}
                >
                  <div className="ui-checkbox-group-item-content">
                    <div className="ui-checkbox-group-item-text">
                      <p className="text-semibold">{printer.title}</p>
                      <p>{printer.brand}</p>
                      <p>{printer.ip_address}</p>
                    </div>
                  </div>
                  <Checkbox
                    size="medium"
                    value={printer.id}
                    checked={defaultPrinter?.id === printer.id}
                    onChange={() => setDefaultPOSPrinter(printer)}
                  />
                </div>
              );
            })}
          </div>
        )}
      </Sheet>
    </div>
  );
}
