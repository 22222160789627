import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { GetPayout, GetTransactions } from "api/rpc/2022-09/clientAdmin/facility/payout";
import Page from "components/page/Page";
import Card from "components/card/Card";
import { StatusCode } from "api/protocols";
import { useTranslation, Trans } from "react-i18next";
import { IUIActions } from "redux/actions/ui";
import { IClientFacilityStore } from "redux/reducers/clientAdmin/facility";
import ReactDOM from "react-dom";
import moment from "moment";

interface IPayoutState {
  authorization: string;
  status: string;
  currency: string;
  gross: number;
  fee: number;
  net: number;
  process_date: string;
}

interface IProps {
  uiActions: IUIActions;
  clientFacilityStore: IClientFacilityStore;
}

export default function Payouts(props: IProps) {
  const { uiActions, clientFacilityStore } = props;
  const history = useHistory();
  const [payoutState, setPayoutState] = useState<IPayoutState>({
    authorization: "",
    status: "",
    currency: "",
    gross: null,
    fee: null,
    net: null,
    process_date: "",
  });
  const [transactionState, setTransactionState] = useState({ transactions: null });
  const { id } = useParams<{ id: string }>();
  const { t, i18n } = useTranslation();
  const mounted = useRef(false);
  useEffect(() => {
    void loadPayout();
    return () => {
      setPayoutState({
        authorization: "",
        status: "",
        currency: "",
        gross: null,
        fee: null,
        net: null,
        process_date: "",
      });
      setTransactionState({ transactions: null });
    };
  }, []);

  useEffect(() => {
    if (mounted.current) {
      history.push("/admin/settings/facility-settings/payouts");
    } else {
      mounted.current = true;
    }
  }, [clientFacilityStore?.facility]);

  async function loadPayout() {
    const resPayout = await GetPayout({ id: Number(id), facility_id: clientFacilityStore?.facility?.id }, true);

    if (resPayout.status !== StatusCode.OK) {
      uiActions.showError("Error getting payout");
      return;
    }

    const currentPayout = resPayout.data[0];

    const resTransactions = await GetTransactions(
      { facility_id: clientFacilityStore?.facility?.id, payout_id: Number(id) },
      true,
    );

    if (resTransactions.status !== StatusCode.OK) {
      uiActions.showError("Error getting transactions");
      return;
    }

    ReactDOM.unstable_batchedUpdates(() => {
      setPayoutState(prev => ({
        ...prev,
        authorization: currentPayout.authorization,
        status: currentPayout.status,
        currency: currentPayout.currency,
        gross: currentPayout.gross,
        fee: currentPayout.fee,
        net: currentPayout.net,
        process_date: currentPayout.process_date,
      }));
      setTransactionState({ transactions: resTransactions.data });
    });
  }

  console.log(payoutState);

  return (
    <Page
      title={"Payout Information"}
      breadcrumbs={[{ prefix: true, label: "Payouts", url: "/admin/settings/facility-settings/payouts" }]}
    >
      <Card>
        <Card.Section title={"Payout Details"}>
          <div className="flex flex-row p-4">
            <div className="flex flex-col flex-1">
              <span>{"Authorization"}</span>
              <p>{payoutState.authorization}</p>
            </div>
            <div className="flex flex-col flex-1">
              <span>{"Status"}</span>
              <p>{payoutState.status}</p>
            </div>
          </div>

          <div className="flex flex-row p-4">
            <div className="flex flex-col flex-1">
              <span>{"Currency"}</span>
              <p>{payoutState.currency}</p>
            </div>
            <div className="flex flex-col flex-1">
              <span>{"Gross"}</span>
              <p>{payoutState.gross}</p>
            </div>
          </div>

          <div className="flex flex-row p-4">
            <div className="flex flex-col flex-1">
              <span>{"Fee"}</span>
              <p>{payoutState.fee}</p>
            </div>
            <div className="flex flex-col flex-1">
              <span>{"Net"}</span>
              <p>{payoutState.net}</p>
            </div>
          </div>
          <div className="flex flex-row p-4">
            <div className="flex flex-col flex-1">
              <span>{"Process Date"}</span>
              <p>{moment(payoutState.process_date).format("LLL")}</p>
            </div>
          </div>
        </Card.Section>
        <Card.Section title={"Transactions"}>
          <div className="overflow-auto">
            <table className="ui-table">
              <thead>
                <tr>
                  <th>{"Amount"}</th>
                  <th>{"Fee"}</th>
                  <th>{"Net"}</th>
                  <th>{"Currency"}</th>
                  <th>{"Processed At"}</th>
                  <th>{"Available on"}</th>
                </tr>
              </thead>
              <tbody>
                {transactionState.transactions?.map((transaction: Record<string, any>, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{transaction.amount}</td>
                      <td>{transaction.fee}</td>
                      <td>{transaction.net}</td>
                      <td>{transaction.currency}</td>
                      <td>{moment(transaction.processed_at).format("LLL")}</td>
                      <td>{transaction.available_on}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Card.Section>
      </Card>
    </Page>
  );
}
