import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import ReactDOM from "react-dom";
import validator from "validator";
import axios, { CancelToken } from "axios";
import { StatusCode } from "api/protocols";

import { showSuccess, showError } from "redux/actions/ui";

import { convertTime } from "helpers/Helpers";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import { ButtonNew as Button } from "components/buttonNew";
import Radio from "components/radio";
import Card from "components/card/Card";
import GolferCard from "components/bookingPopUp/golferCard/GolferCard";
import { Select } from "components/select/index";
import Sheet from "components/sheet/Sheet";
import { useGuestPortalContext } from "../GuestPortalContext";

import "../PortalBookings/editPortalBooking.scss";
import { useTranslation } from "react-i18next";
import { ILocation, IModule, IReservationBooking, ISegment } from "redux/reducers/models/reservations";
import Spin from "components/spin/spin";
import { IFacility } from "redux/reducers/models/facility";
import Callout from "components/callout/Callout";
import { GetReservationBooking, PutReservationBooking } from "api/rpc/2024-04/customer/reservation";
import FormLayout from "components/form/FormLayout";
import Divider from "components/divider";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { MOBILE_WIDTH } from "helpers/ScreenSizes";
import { GetFacility } from "api/rpc/2024-04/customer/facility";
import {
  GetGuestReservationLocation,
  GetGuestReservationModule,
  GetGuestReservationSegment,
} from "api/rpc/2024-04/guest/reservation";
interface IState {
  reservation: IReservationBooking;
  guestQuantity: number;
  facility: IFacility;
  module: IModule;
  location: ILocation;
  locations: Array<ILocation>;
  endTime: string;
  durationSegments: { [key: string]: Array<ISegment> };
  /**The selected duration key string ('1 Hour', '1.5 Hours'... '5 Hours') */
  durationKey: string;
}

export default function EditPortalReservation() {
  const SEGMENT_MINUTES = 30;
  const { Option } = Select;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { token } = useParams<{ token: string }>();

  const dispatch = useAppDispatch();
  const { portalState } = useGuestPortalContext();

  const windowSize = useWindowSize();

  const [state, setState] = useState<IState>({
    reservation: null,
    guestQuantity: null,
    facility: null,
    module: null,
    location: null,
    locations: null,
    durationSegments: null,
    durationKey: "0.5 Hour",
    endTime: "",
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void getReservation(source.token);
    return () => {
      source.cancel("Cancelled request");
    };
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (state?.reservation && state?.module && state?.location) {
      void setSegments(source.token);
    }
    return () => {
      source.cancel("Cancelled request");
    };
  }, [state?.location]);

  async function getReservation(cancelToken: CancelToken) {
    const reservationRes = await GetReservationBooking({ token: token, extended: true }, true, cancelToken);
    if (reservationRes.status !== StatusCode.OK || reservationRes.data?.length === 0) {
      dispatch(showError("Error getting reservation"));
      void returnToViewReservation();
      return;
    }

    const reservation = reservationRes.data[0];

    const reservationInPast = checkReservationInPast(reservation);
    if (reservationInPast) {
      void returnToViewReservation();
      return;
    }

    if (reservation?.status === "cancelled") {
      void returnToViewReservation();
    }

    let facility = reservation?.location?.facility;
    if (!facility) {
      const facilityRes = await GetFacility({ id: reservation?.location?.facility_id }, true);
      if (facilityRes?.status !== StatusCode.OK || facilityRes?.data?.length === 0) {
        dispatch(showError("Error getting facility"));
        void returnToViewReservation();
        return;
      }
      facility = facilityRes?.data[0];
    }

    const locationRes = await GetGuestReservationLocation({ facility_id: reservation?.location?.facility_id }, true);
    if (locationRes?.status !== StatusCode.OK) {
      dispatch(showError("Error getting locations"));
      void returnToViewReservation();
      return;
    }

    const moduleRes = await GetGuestReservationModule({ id: reservation?.location?.reservation_module_id }, true);
    if (moduleRes?.status !== StatusCode.OK) {
      dispatch(showError("Error getting module"));
      void returnToViewReservation();
      return;
    }

    //Send the user back to the view page if reservation is past the edit window
    const cannotEdit = checkEditCancelTimes(reservation, facility, moduleRes?.data);
    if (cannotEdit) {
      void returnToViewReservation();
      return;
    }

    setState(prevState => ({
      ...prevState,
      reservation: reservation,
      guestQuantity: reservation?.quantity,
      location: reservation?.location,
      module: moduleRes?.data,
      locations: locationRes?.data,
      facility,
    }));
  }

  function checkReservationInPast(reservation: IReservationBooking) {
    const reservationDate = reservation?.date + " " + reservation?.start_time;
    const currentTime = moment().utc();
    const bookingTime = moment(reservationDate)?.utc();
    return currentTime.isAfter(bookingTime);
  }

  function checkEditCancelTimes(booking: IReservationBooking, facility: IFacility, module: IModule) {
    let cannotEdit = false;
    const currentDateUTC = moment.utc();
    const reservationTimeZoneDate = moment
      .tz(`${booking?.date}T${booking?.start_time}`, facility?.timezone)
      .format("YYYY-MM-DD HH:mm:ss")
      .toString();

    const reservationEditTime = new Date(reservationTimeZoneDate);
    reservationEditTime.setHours(reservationEditTime.getHours() - module?.web_edit_hours);

    const reservationEditTimeUTC = moment.utc(reservationEditTime);

    if (currentDateUTC?.isSameOrAfter(reservationEditTimeUTC)) {
      cannotEdit = true;
    }

    return cannotEdit;
  }

  async function setSegments(cancelToken: CancelToken) {
    const segmentsRes = await GetGuestReservationSegment(
      {
        module_id: state?.module?.id,
        date: state?.reservation?.date,
        start_time: state?.reservation?.start_time,
        location_id: state?.location?.id,
      },
      true,
      cancelToken,
    );
    if (segmentsRes?.status !== StatusCode.OK || segmentsRes?.data?.length === 1) {
      if (segmentsRes?.message === "Cancelled request") {
        return;
      }
      dispatch(showError("Error getting segments"));
      void returnToViewReservation();
      return;
    }
    void setDurations(segmentsRes?.data, state?.reservation);
  }

  function returnToViewReservation() {
    history.push(`/tee-on/portal/reservations/${token}/view`);
  }

  function handleDurationDropDownChange(durationKey: string, id: string) {
    const durationToAdd = Number(durationKey?.split(" ")[0]);
    if (durationToAdd) {
      const endTime = getEndTime(durationToAdd);
      setState(prevState => ({ ...prevState, [id]: durationKey, endTime }));
    }
  }

  function handleLocationChange(value: number) {
    const foundLocation = state?.locations?.find(location => location?.id === value);
    if (foundLocation) {
      setState(prevState => ({ ...prevState, location: foundLocation }));
    }
  }

  function handleGuestAmountChange(amount: number) {
    setState(prevState => ({ ...prevState, guestQuantity: amount }));
  }

  function setDurations(segments: Array<ISegment>, reservation: IReservationBooking) {
    //We can only select segments from index 0-9 for 0.5 - 5 hours with 0.5 hour increments
    const max = Math.min(10, segments?.length);
    let durationSegments: { [key: string]: Array<ISegment> } = {};
    let endTime: string = null;
    let currentKey = "";
    let minutes = 0;
    let hours = 0;

    for (let i = 0; i < max; i++) {
      minutes += SEGMENT_MINUTES;
      if (
        ((segments[i]?.blocked_type === "open" && segments[i]?.booking_id === null) ||
          (segments[i]?.blocked_type === "booked" && segments[i]?.booking_id === reservation?.id)) &&
        //In the future, we will need to allow the customer to book a league/tournament they are apart of
        segments[i]?.league_id === null &&
        segments[i]?.tournament_id === null
      ) {
        hours = minutes / 60;
        const timeKey = `${hours} ${hours > 1 ? "Hours" : "Hour"}`;
        durationSegments[timeKey] = segments?.filter((filteredSegment: ISegment, index: number) => index <= i);
        //Get the last segment with the booking_id to set the default duration
        if (segments[i]?.blocked_type === "booked" && segments[i]?.booking_id === reservation?.id) {
          currentKey = timeKey;
        }
      } else {
        break;
      }
    }

    //If no duration times are available to select, return to the home page
    if (Object.keys(durationSegments)?.length === 0) {
      dispatch(showError("Time unavailable for selected location, please choose another"));
      durationSegments = undefined;
      currentKey = undefined;
    } else if (!currentKey) {
      currentKey = Object.keys(durationSegments)[0];
    }

    if (currentKey) {
      endTime = getEndTime(hours, reservation);
    }

    setState(prevState => ({ ...prevState, durationSegments, durationKey: currentKey, endTime }));
  }

  function getEndTime(hours: number, reservation: IReservationBooking = state?.reservation) {
    const endTime: string = moment(`${reservation?.date} ${reservation?.start_time}`)
      .add(hours, "hours")
      .format("HH:mm:ss");
    return endTime;
  }

  async function handleUpdateReservation() {
    if (state?.durationSegments[state?.durationKey] && state?.location && state?.reservation) {
      const segmentIds = state?.durationSegments[state?.durationKey]?.map(segment => segment?.id);
      const updateRes = await PutReservationBooking(
        {
          id: state?.reservation?.id,
          date: state?.reservation?.date,
          quantity: state?.guestQuantity,
          segment_ids: segmentIds,
          location_id: state?.location?.id,
        },
        true,
      );
      if (updateRes?.status !== StatusCode.OK) {
        dispatch(showError("Error updating reservation"));
        return;
      }
      dispatch(showSuccess("Successfully updated reservation"));
      void returnToViewReservation();
    }
  }

  const disableUpdateButton =
    !state?.durationSegments ||
    Object.keys(state?.durationSegments)?.length === 0 ||
    !state?.reservation ||
    !state?.location;

  return (
    <div className="portal-reservations">
      <Page
        full
        title="Edit Reservation"
        breadcrumbs={[
          { prefix: true, label: "Back to View Reservation", url: `/tee-on/portal/reservations/${token}/view` },
        ]}
      >
        {state?.reservation ? (
          <>
            <div className="facility-container">
              {state?.facility?.logo_source && <img src={state?.facility?.logo_source} alt={"Facility logo"} />}
              <div className="facility-details-container">
                <h1 className="facility-name">{state.facility?.long_name}</h1>
                <p className="facility-address">{`${state?.facility?.address_line_1}, ${state?.facility?.city},
${state?.facility?.province_name}, ${state.facility?.postal}`}</p>
              </div>
            </div>
            <Card>
              <Card.Section>
                <p className="time-range">
                  {convertTime(state.reservation?.start_time ?? "")}{" "}
                  {state?.endTime && ` - ${convertTime(state?.endTime ?? "")}`}
                </p>
                <p className="module-name">
                  {state?.module?.title} - {state?.location?.title}
                </p>
                <p className="date-time-info edit-reservations-date">
                  {moment(`${state.reservation?.date}T${state.reservation?.start_time}`).format("LLLL")}
                  {state?.endTime && ` - ${moment(`${state.reservation?.date}T${state.endTime}`).format("LT")}`}
                </p>
                <FormLayout>
                  <FormLayout.Group>
                    <Select
                      defaultValue={state?.reservation?.location_id}
                      onChange={(value: number) => handleLocationChange(value)}
                      label="Location"
                    >
                      {state?.locations?.map((location, index) => {
                        return (
                          <Option key={index} value={location?.id}>
                            {location?.title}
                          </Option>
                        );
                      })}
                    </Select>
                    {state?.durationSegments ? (
                      <Select
                        defaultValue={state?.durationKey}
                        onChange={(value: string) => handleDurationDropDownChange(value, "durationKey")}
                        label="Duration"
                      >
                        {Object?.keys(state?.durationSegments)?.map((durationKey, durationIndex) => {
                          return (
                            <Option key={durationIndex} value={durationKey} name={durationKey}>
                              {durationKey}
                            </Option>
                          );
                        })}
                      </Select>
                    ) : state?.durationSegments === undefined ? (
                      <Callout
                        content={`${state?.location?.title} at ${convertTime(
                          state.reservation?.start_time ?? "the selected time",
                        )} is unavailable. ${windowSize.width > MOBILE_WIDTH ? "Please choose another location" : ""}`}
                        title="Time unavailable"
                        type="info"
                      />
                    ) : null}
                    <div>
                      <p className="edit-reservation-input-title">Guests</p>
                      <Radio.Group
                        name="number_of_guests"
                        onChange={handleGuestAmountChange}
                        value={state.guestQuantity}
                      >
                        {[...Array(6).keys()].map(index => {
                          return (
                            <Radio.Button key={index} value={index + 1}>
                              {index + 1}
                            </Radio.Button>
                          );
                        })}
                      </Radio.Group>
                    </div>
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
            </Card>
            <Divider />
            <div className="bottom-buttons-container">
              <Button
                className="edit-reservations-update-button"
                type="primary"
                size="medium"
                onClick={handleUpdateReservation}
                disabled={disableUpdateButton}
              >
                Update Reservation
              </Button>
            </div>
          </>
        ) : (
          <span style={{ display: "flex", justifyContent: "center" }}>
            <Spin />
          </span>
        )}
      </Page>
    </div>
  );
}
