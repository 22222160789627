import { APIGet, APIPost, APIPut, IAPIResponse } from "api/protocols";
import { CancelToken } from "axios";
import { IBooking } from "redux/reducers/models/teetime";

const apiVersion = "2024-04";
const adminType = "customer";

const baseUrl = "/" + apiVersion + "/" + adminType + "/tee-time/booking";

interface IGetBooking {
  id?: number;
  token?: string;
  extended?: boolean;
  turn_tee_time?: true;
  limit?: number;
  offset?: number;
}

interface IGetParticipantBookings {
  limit: number;
  offset: number;
  client_id?: number;
  extended_booking: boolean;
  customer_id?: number;
  year?: string;
}

interface IPutCustomerBooking {
  token: string;
  power_cart_quantity: number;
  quantity: number;
  holes: number;
  customer_ids: Array<number>;
}

export async function GetBooking(
  params: IGetBooking,
  useGlobalLoader: boolean,
  token?: CancelToken,
): Promise<IAPIResponse<Array<IBooking>>> {
  return await APIGet(baseUrl, params, useGlobalLoader, token);
}

export async function PutCustomerBooking(params: IPutCustomerBooking, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

export async function GetBookingParticipant(
  params: IGetParticipantBookings,
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseUrl + "/participant", params, useGlobalLoader, token);
}

export async function PutCancelBooking(params: { token: string }, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/cancel", params, useGlobalLoader);
}
