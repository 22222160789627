import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { StatusCode } from "api/protocols";
import { DeleteLineItemToCart, PostCart } from "api/rpc/2022-09/facilityAdmin/cart/cart";
import { PutLineItemToCart } from "api/rpc/2024-04/facilityAdmin/order/cart/lineItem";
import { IDiscount, PostDiscountLine } from "api/rpc/2022-09/facilityAdmin/product/discount";
import { IUserPermissions } from "redux/reducers/models/user";
import { showError, showSuccess } from "redux/actions/ui";
import { ICart, ICartLineItem, ICartTaxLine, IDiscountLines } from "redux/reducers/models/cart";
import { IProduct } from "redux/reducers/models/product";

import { isNumeric, isWholeNumber } from "helpers/Helpers";

import Sheet from "components/sheet/Sheet";
import Input from "components/form/input/Input";
import { ButtonNew as Button } from "components/buttonNew/index";
import DiscountLineItemModal from "components/discountLineItemModal/DiscountLineItemModal";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import ButtonGroup from "components/button/ButtonGroup";
import { result } from "lodash";
import { isSeatAbleToMoveLineItems } from "../tableService/LineItemModals/TableServiceMoveLineItemsModal";
import { ItemTypes } from "../settings/floorPlan/floorPlanEdit/FloorPlanEdit";
import { deleteLineItem, loadCart, putCartLineItem } from "redux/actions/cart";
import "elements/register/register.scss";

export interface ILineItem {
  cart_id: number;
  cost: number;
  custom: boolean;
  fulfillment_required: any | null;
  gift_card: boolean;
  id: number;
  kitchen_chit_id: number | null;
  non_refundable: boolean;
  note: string | null;
  parent_id: number | null;
  pre_paid_required: number;
  preferred_title: string | null;
  price: number;
  product: IProduct;
  product_default_image?: string | null;
  product_id: number;
  product_title: string;
  quantity: number;
  requires_shipping: any | null;
  subtitle: string | null;
  subtotal_price: number;
  tax_included: boolean;
  tax_lines: ICartTaxLine[];
  taxable: boolean;
  total_discount: number;
  total_price: number;
  total_tax: number;
  variant_id: number;
  variant_title: string;
}

export interface IUpdateLineItemModalState {
  lineItem: ILineItem;
  inputQuantity: string;
  inputPrice: string;
  inputNote: string;
  isModalVisible: boolean;
}

interface ILineItemModalBase {
  lineItemState: IUpdateLineItemModalState;
  setLineItemState: React.Dispatch<React.SetStateAction<IUpdateLineItemModalState>>;
  openModifiersSheet?: (productId: number) => Promise<void>;
  permissions: IUserPermissions;
  discounts: IDiscount[];
  cart: ICart | Partial<ICart>;
}

interface ILineItemStateData extends ILineItemModalBase {
  dataOrigin: "state";
  modalActions: ILineItemModalActions;
}

interface ILineItemReduxData extends ILineItemModalBase {
  dataOrigin: "redux";
  modalActions: Partial<ILineItemModalActions>;
}

interface ILineItemModalActions {
  onCancel: () => void;
  onOk: (props: { updatedCart: any; quantity: number; isSuccess: boolean }) => void;
  removeLineItem: (cartDataRes: { updatedCart: any }) => void;
  applyLineItemDiscountRes: (postDiscountRes: { updatedCart: any }) => void;
}

type LineItemModalType = ILineItemStateData | ILineItemReduxData;

export default function LineItemModal(props: LineItemModalType) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    lineItemState,
    setLineItemState,
    permissions,
    discounts,
    dataOrigin,
    modalActions,
    openModifiersSheet,
    cart,
  } = props;

  const [discountModal, setDiscountModal] = useState({
    isOpen: false,
    selectedDiscountId: -1,
    selectedDiscount: null,
  });

  const handleDiscountModal = () => {
    setDiscountModal({
      ...discountModal,
      isOpen: !discountModal.isOpen,
      selectedDiscountId: -1,
      selectedDiscount: null,
    });
  };

  const resetInputState = () => {
    setLineItemState({
      ...lineItemState,
      lineItem: null,
      isModalVisible: false,
      inputQuantity: "",
      inputPrice: "",
      inputNote: "",
    });
  };

  // Update the line item
  async function handleUpdateLineItem() {
    let res;
    const quantity = parseInt(lineItemState.inputQuantity);
    const price = parseFloat(lineItemState.inputPrice);

    //determine what to do with lineItem based off quantity wanted
    if (isNumeric(lineItemState.inputPrice)) {
      const dataReturn = {
        id: lineItemState.lineItem.id,
        price: price,
        quantity: quantity,
        note: lineItemState.inputNote,
      };

      //handle OK results dependant on dataOrigin
      if (dataOrigin === "state") {
        res = await PutLineItemToCart(dataReturn, true);

        if (res.status === StatusCode.OK) {
          dispatch(showSuccess(res.message));
          modalActions.onOk({ updatedCart: res.data, quantity: quantity, isSuccess: true });
        } else {
          dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.019")));
          const postCartRes = await PostCart(null, false);
          modalActions.onOk({ updatedCart: postCartRes.data.data, quantity: quantity, isSuccess: false });
        }
      } else if (dataOrigin === "redux") {
        res = dispatch(putCartLineItem(dataReturn, true));
        console.log("Update Res", res);
        // await props.cartActions.postCart(null, false);
      }
    } else {
      dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.018")));
    }

    //reset the modal
    resetInputState();
  }

  // Remove the selected lineItem
  async function handleRemoveLineItem() {
    if (dataOrigin === "state") {
      const deleteRes = await DeleteLineItemToCart({ id: lineItemState.lineItem.id }, true);

      if (deleteRes.status === StatusCode.BAD_REQUEST) {
        dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.017")));

        const postCartRes = await PostCart(null, false);
        modalActions.removeLineItem({ updatedCart: postCartRes.data.data });
        return;
      }

      dispatch(showSuccess(deleteRes.data?.message));
      modalActions.removeLineItem({ updatedCart: deleteRes.data });
    }

    if (dataOrigin === "redux") {
      dispatch(deleteLineItem(lineItemState.lineItem.id, true));
    }

    // refresh inputs
    resetInputState();
  }

  function filterModifiers() {
    const cartLineItems = props.cart?.line_items;
    const parentLineItemID = lineItemState.lineItem.id;
    const modifiers: Array<ICartLineItem> = [];

    if (cartLineItems.length > 0) {
      cartLineItems.forEach((line_item, index) => {
        if (line_item.parent_id == parentLineItemID) {
          modifiers.push(line_item);
        }
      });
    }

    return modifiers;
  }

  // Apply discount to line item
  async function handleLineItemDiscountApply(customAmount?: string, typeFixed?: boolean) {
    const type = typeFixed !== undefined ? (typeFixed ? "fixed_amount" : "percent") : undefined;
    const modifiers = filterModifiers();
    const res = await PostDiscountLine(
      {
        discount_id: discountModal.selectedDiscountId,
        cart_id: lineItemState.lineItem.cart_id,
        cart_line_item_id: lineItemState.lineItem.id,
        value: customAmount ? Number(customAmount) : undefined,
        value_type: type,
      },
      true,
    );

    if (res.status === StatusCode.BAD_REQUEST) {
      dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.020")));
      return;
    }

    if (modifiers.length > 0 && (type == "percent" || discountModal?.selectedDiscount?.value_type === "percent")) {
      for (const modifier of modifiers) {
        if (modifier.price > 0) {
          const res = await PostDiscountLine(
            {
              discount_id: discountModal.selectedDiscountId,
              cart_id: lineItemState.lineItem.cart_id,
              cart_line_item_id: modifier.id,
              value: customAmount ? Number(customAmount) : undefined,
              value_type: type,
            },
            true,
          );

          if (res.status === StatusCode.BAD_REQUEST) {
            dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.020")));
          }
        }
      }
    }

    // allow parent to handle the change
    if (dataOrigin === "state") {
      void modalActions.applyLineItemDiscountRes({ updatedCart: res.data });
    } else if (dataOrigin === "redux") {
      dispatch(loadCart({ id: lineItemState.lineItem.cart_id }, true));
    }

    dispatch(showSuccess(t("secure.facility.tee_sheet.tee_sheet_booking_details.171")));
    //refresh discount modal
    handleDiscountModal();
  }

  return (
    <>
      <Sheet
        closable
        size="small"
        open={lineItemState.isModalVisible}
        title={t("secure.facility.tee_sheet.tee_sheet_booking_details.121")}
        onCancel={() => resetInputState()}
        cancelText={t("secure.facility.tee_sheet.tee_sheet_booking_details.124")}
        onOk={handleUpdateLineItem}
        okText={
          parseInt(lineItemState.inputQuantity) === 0
            ? t("secure.facility.tee_sheet.tee_sheet_booking_details.122")
            : t("secure.facility.tee_sheet.tee_sheet_booking_details.123")
        }
        okDisabled={!isNumeric(lineItemState.inputQuantity) || !isNumeric(lineItemState.inputPrice)}
        backDropCancel={false}
      >
        <div className="update-line-item-modal-container">
          <Button
            onClick={() => handleDiscountModal()}
            type="text"
            className="update-line-item-modal-primary"
            disabled={!permissions?.orders_apply_discount}
          >
            {t("secure.facility.tee_sheet.tee_sheet_booking_details.128")}
          </Button>

          {lineItemState.lineItem.product?.has_modifiers && lineItemState.lineItem?.parent_id === null ? (
            <Button
              onClick={() => openModifiersSheet(lineItemState.lineItem?.product_id)}
              className="update-line-item-modal-primary"
              type="text"
            >
              {t("secure.facility.tee_sheet.tee_sheet_booking_details.130")}
            </Button>
          ) : null}

          <Button
            onClick={handleRemoveLineItem}
            type="text"
            className="update-line-item-modal-error"
            disabled={
              !(
                permissions?.orders_remove_line_item ||
                (permissions?.orders_remove_line_item_after_send && !lineItemState.lineItem?.kitchen_chit_id)
              )
            }
          >
            {t("secure.facility.tee_sheet.tee_sheet_booking_details.131")}
          </Button>
        </div>
        <div className="flex flex-col mb-4">
          <span className="text-lg">
            {lineItemState.lineItem?.preferred_title
              ? lineItemState?.lineItem?.preferred_title
              : lineItemState?.lineItem?.product_title}
          </span>
          {lineItemState.lineItem?.preferred_title !== lineItemState.lineItem?.variant_title &&
            lineItemState?.lineItem?.product_title !== lineItemState.lineItem?.variant_title && (
              <span style={{ color: "#475467" }} className="text-sm">
                {lineItemState.lineItem?.variant_title}
              </span>
            )}
        </div>

        <Form>
          <FormLayout>
            <FormLayout.Group>
              <Input
                label={t("secure.facility.tee_sheet.tee_sheet_booking_details.125")}
                id="inputQuantity"
                value={lineItemState.inputQuantity || ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setLineItemState({ ...lineItemState, [e.target.id]: e.target.value })
                }
                error={!isNumeric(lineItemState.inputQuantity)}
              />
              <Input
                prefix="$"
                label={t("secure.facility.tee_sheet.tee_sheet_booking_details.126")}
                placeholder={t("secure.facility.tee_sheet.tee_sheet_booking_details.127")}
                id="inputPrice"
                value={lineItemState.inputPrice || ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setLineItemState({ ...lineItemState, [e.target.id]: e.target.value })
                }
                disabled={!permissions?.orders_edit_line_item_price || lineItemState?.lineItem?.product?.price_locked}
                error={!isNumeric(lineItemState.inputPrice)}
              />
            </FormLayout.Group>

            <FormLayout.Group>
              <Input
                placeholder="Note"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setLineItemState({ ...lineItemState, [e.target.id]: e.target.value })
                }
                id="inputNote"
                value={lineItemState.inputNote}
              />
            </FormLayout.Group>
          </FormLayout>
        </Form>
      </Sheet>

      {discounts && (
        <DiscountLineItemModal
          title={t("secure.facility.tee_sheet.tee_sheet_booking_details.129")} //"Line Item Discounts"
          open={discountModal.isOpen}
          onCancel={handleDiscountModal}
          onOk={handleLineItemDiscountApply}
          onChange={(id: number, discount: IDiscount) =>
            setDiscountModal({ ...discountModal, selectedDiscountId: id, selectedDiscount: discount })
          }
          okDisabled={!discountModal.selectedDiscountId || discountModal.selectedDiscountId < 0}
          discounts={discounts}
          selectedDiscount={discountModal.selectedDiscount}
        />
      )}
    </>
  );
}
