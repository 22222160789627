import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { TTournamentFlight, TTournamentTeamPlayer } from "redux/reducers/models/tournament";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/tournament/scoring/flight";

export type TGetTournamentFlight = {
  id?: string | number;
  tournament_id?: string | number;
};

export type TPostTournamentFlight = {
  tournament_id?: string | number;
  name: string;
};

export type TPutTournamentFlight = {
  id: string | number; // tournament ID
  name: string;
};

export async function GetTournamentFlights(
  params: TGetTournamentFlight,
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet<TTournamentFlight[]>(baseUrl, params, useGlobalLoader, token);
}

export async function PostTournamentFlights(params: TPostTournamentFlight, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

export async function PutTournamentFlights(params: TPutTournamentFlight, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

export async function DeleteTournamentFlights(params: { id: string | number }, useGlobalLoader: boolean) {
  return await APIDelete(baseUrl, params, useGlobalLoader);
}

export async function GetTournamentFlightPlayers(
  params: { flight_id: string | number },
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet<TTournamentTeamPlayer[]>(baseUrl + "/participant", params, useGlobalLoader, token);
}

export async function PutTournamentFlightPlayers(
  params: { flight_id: string | number; participant_ids: number[] },
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIPut(baseUrl + "/participant", params, useGlobalLoader, token);
}
