import React from "react";
import { useParams } from "react-router-dom";

import CustomerClientContextProvider from "./CustomerClientContext";
import Customer from "../main/Customer";

import "./CustomerClientProvider.scss";

/**
 * ContextProvider wrapper for the base customer element on client-side.
 *   - Obtains customer ID found within the URL, and passes to context.
 */
export default function CustomerClientProvider() {
  const { customerId }: any = useParams();

  return (
    <CustomerClientContextProvider customerId={customerId}>
      <div className="customer-client-provider-container">
        <Customer />
      </div>
    </CustomerClientContextProvider>
  );
}
