import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import * as authActions from "actions/auth";
import Register from "pages/secure/facility/register/Register";
import * as UIActions from "redux/actions/ui";
import * as CartActions from "redux/actions/cart";
import * as FacilityActions from "redux/actions/facility";

const mapDispatchToProps = (dispatch: any) => {
  return {
    uiActions: bindActionCreators(
      {
        ...UIActions,
      },
      dispatch,
    ),
    cartActions: bindActionCreators(
      {
        ...CartActions,
      },
      dispatch,
    ),
    facilityActions: bindActionCreators(
      {
        ...FacilityActions,
      },
      dispatch,
    ),
  };
};

const mapStateToProps = (state: any) => {
  // const { authStore } = state;
  return {
    authStore: { ...state.authStore },
    cartStore: { ...state.cartStore },
    facilityStore: { ...state.facilityStore },
    terminalStore: { ...state.terminalStore },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
