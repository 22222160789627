import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { GetPayout } from "api/rpc/facility";
import Page from "components/page/Page";
import Card from "components/card/Card";
import "../facility/facility.scss";
import { StatusCode } from "api/protocols";

import { useTranslation, Trans } from "react-i18next";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";
import { GetPayoutTransactions } from "api/rpc/2024-04/facilityAdmin/facility/payout";
import axios, { CancelToken } from "axios";
import moment from "moment";

interface IFilterState {
  offset: number;
}

interface IPayoutState {
  authorization: string;
  status: string;
  currency: string;
  gross: number;
  fee: number;
  net: number;
  process_date: string;
}

const TABLE_LIMIT = 25;

export default function Payouts(props: any) {
  const [payoutState, setPayoutState] = useState<IPayoutState>({
    authorization: "",
    status: "",
    currency: "",
    gross: null,
    fee: null,
    net: null,
    process_date: "",
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    offset: 0,
  });

  const [transactionState, setTransactionState] = useState({ transactions: null });

  const { id } = useParams<{ id: string }>();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadPayout(source.token);

    return () => {
      source.cancel();
      setPayoutState({
        authorization: "",
        status: "",
        currency: "",
        gross: null,
        fee: null,
        net: null,
        process_date: "",
      });
      setTransactionState({ transactions: null });
    };
  }, []);

  async function loadPayout(token?: CancelToken) {
    const resPayout = await GetPayout(true);

    if (resPayout.status !== StatusCode.OK) {
      return;
    }

    const currentPayout = resPayout.data.find((payout: any) => payout.id === Number(id));

    setPayoutState(prev => ({
      ...prev,
      authorization: currentPayout.authorization,
      status: currentPayout.status,
      currency: currentPayout.currency,
      gross: currentPayout.gross,
      fee: currentPayout.fee,
      net: currentPayout.net,
      process_date: currentPayout.process_date,
    }));

    if (transactionState.transactions) {
      setTransactionState(prevState => ({ ...prevState, transactions: null }));
    }

    const resTransactions = await GetPayoutTransactions(
      { ...filterState, limit: TABLE_LIMIT, payout_id: id },
      token ? false : true,
      token,
    );

    if (token && token.reason) {
      return;
    }

    if (resTransactions.status !== StatusCode.OK) {
      setTransactionState({ transactions: [] });
      return;
    }

    setTransactionState({ transactions: resTransactions.data });
  }

  function handleTableNavigation(direction: "prev" | "next") {
    switch (direction) {
      case "prev": {
        setFilterState(prev => ({ ...prev, offset: filterState.offset - TABLE_LIMIT }));
        return;
      }
      case "next": {
        setFilterState(prev => ({ ...prev, offset: filterState.offset + TABLE_LIMIT }));
        return;
      }
      default:
        return;
    }
  }

  return (
    <Page
      title={t("secure.facility.settings.payouts.payouts_info.001")}
      narrow
      breadcrumbs={[
        { prefix: true, label: t("secure.facility.settings.payouts.payouts_info.002"), url: "/admin/settings/payouts" },
      ]}
    >
      <hr className="my-8" />
      <Card>
        <Card.Section title={t("secure.facility.settings.payouts.payouts_info.003")}>
          <div className="flex flex-row p-4">
            <div className="flex flex-col flex-1">
              <span>{t("secure.facility.settings.payouts.payouts_info.004")}</span>
              <p>{payoutState.authorization}</p>
            </div>
            <div className="flex flex-col flex-1">
              <span>{t("secure.facility.settings.payouts.payouts_info.005")}</span>
              <p>{payoutState.status}</p>
            </div>
          </div>

          <div className="flex flex-row p-4">
            <div className="flex flex-col flex-1">
              <span>{t("secure.facility.settings.payouts.payouts_info.006")}</span>
              <p>{payoutState.currency}</p>
            </div>
            <div className="flex flex-col flex-1">
              <span>{t("secure.facility.settings.payouts.payouts_info.007")}</span>
              <p>{payoutState.gross}</p>
            </div>
          </div>

          <div className="flex flex-row p-4">
            <div className="flex flex-col flex-1">
              <span>{t("secure.facility.settings.payouts.payouts_info.008")}</span>
              <p>{payoutState.fee}</p>
            </div>
            <div className="flex flex-col flex-1">
              <span>{t("secure.facility.settings.payouts.payouts_info.009")}</span>
              <p>{payoutState.net}</p>
            </div>
          </div>
          <div className="flex flex-row p-4">
            <div className="flex flex-col flex-1">
              <span>{t("secure.facility.settings.payouts.payouts_info.010")}</span>
              <p>{moment(payoutState.process_date).format("LLL")}</p>
            </div>
          </div>
        </Card.Section>
        <Card.Section title={t("secure.facility.settings.payouts.payouts_info.011")}>
          <div className="overflow-auto">
            <div className="settings_table-container">
              <DataTable
                loading={!transactionState.transactions ? true : false}
                columns={[
                  { label: t("secure.facility.settings.payouts.payouts_info.012") },
                  { label: t("secure.facility.settings.payouts.payouts_info.013") },
                  { label: t("secure.facility.settings.payouts.payouts_info.014") },
                  { label: t("secure.facility.settings.payouts.payouts_info.015") },
                  { label: t("secure.facility.settings.payouts.payouts_info.016") },
                  { label: t("secure.facility.settings.payouts.payouts_info.017") },
                ]}
                footer={{
                  tableLimit: TABLE_LIMIT,
                  tableOffset: filterState.offset,
                  disableNextOffset:
                    !transactionState.transactions || !(transactionState.transactions?.length === TABLE_LIMIT),
                  handleTableOffset: direction => handleTableNavigation(direction),
                }}
              >
                {transactionState.transactions?.map((transaction: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{transaction.amount}</td>
                      <td>{transaction.fee}</td>
                      <td>{transaction.net}</td>
                      <td>{transaction.currency}</td>
                      <td>{moment(transaction.processed_at).format("LLL")}</td>
                      <td>{transaction.available_on}</td>
                    </tr>
                  );
                })}
              </DataTable>
            </div>
          </div>
        </Card.Section>
      </Card>
    </Page>
  );
}
