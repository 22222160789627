import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FacilityAdmin from "pages/secure/facility/Admin";
import * as FacilityActions from "redux/actions/facility";
import * as TerminalActions from "redux/actions/facilityAdmin/terminal";
import * as UIActions from "redux/actions/ui";

const mapDispatchToProps = (dispatch: any) => {
  return {
    facilityActions: bindActionCreators(
      {
        ...FacilityActions,
      },
      dispatch,
    ),
    terminalActions: bindActionCreators(
      {
        ...TerminalActions,
      },
      dispatch,
    ),
    uiActions: bindActionCreators(
      {
        ...UIActions,
      },
      dispatch,
    ),
  };
};

const mapStateToProps = (state: any) => {
  const { facilityStore, terminalStore, uiStore, authStore } = state;
  return {
    uiStore: { ...uiStore },
    facilityStore: { ...facilityStore },
    terminalStore: { ...terminalStore },
    authStore: { ...authStore },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FacilityAdmin);
