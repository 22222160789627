import React, { useEffect, useMemo, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";

import { FilterValue, applyDefaults, updatePriceMatchFilter } from "redux/actions/filters/filters";
import { ComponentKey, FilterKey, OrderFilterGrouping } from "redux/reducers/filters/types";

import { useAppSelector, useAppDispatch } from "hooks/redux";

import { useFilterAccordionContext } from "components/accordion/Accordion";
import { Select } from "components/select/index";
import Input from "components/form/input/Input";

import "./filterList.scss";

type FilterList = {
  /** Value provided by FilterAccordionContext takes precedent if present. */
  collection: ComponentKey;
  defaultValue?: {
    key: PriceMatchKey;
    value?: number;
  };
  condensed?: boolean;
};

export type PriceMatchKey = "total_equal_to" | "total_greater_than" | "total_less_than";

const priceMatchFilters = (t: TFunction<"translation", undefined>) => [
  {
    id: "total_equal_to",
    label: t("secure.facility.order.order_filters.018"),
  },
  {
    id: "total_greater_than",
    label: t("secure.facility.order.order_filters.019"),
  },
  {
    id: "total_less_than",
    label: t("secure.facility.order.order_filters.020"),
  },
];

export default function PriceMatchFilterList(props: FilterList) {
  const { t } = useTranslation();

  const filters = useAppSelector(store => store.orderPageFilters);
  const dispatch = useAppDispatch();
  const { collectionContext } = useFilterAccordionContext();

  // Necessary for dispatch params
  const [currentSelect, setCurrentSelect] = useState<PriceMatchKey>(
    props.defaultValue ? props.defaultValue.key : "total_greater_than",
  );
  const [currentInput, setCurrentInput] = useState(props.defaultValue ? props.defaultValue.value : 0);

  const { Option } = Select;

  // captured filter inputs for JSX
  const inputValues = useMemo(() => {
    if (filters.find(collection => collection.label === currentSelect)) {
      return filters
        .filter(collection => collection.label === currentSelect)
        .map(context => {
          return {
            select: context.label,
            input: context.value.length !== 0 ? context.value[0] : "",
          };
        })[0];
    }

    return {
      select: "" as FilterKey,
      input: "" as FilterValue,
    };
  }, [filters, currentSelect]);

  // Apply default filters
  useEffect(() => {
    if (props.defaultValue === undefined) {
      return;
    }

    if (!filters.find(group => group.label === props.defaultValue.key)) {
      dispatch(
        applyDefaults<OrderFilterGrouping>({
          collectionName: collectionContext ? collectionContext : props.collection,
          key: props.defaultValue.key,
          value: [props.defaultValue.value],
          displayLabels: [priceMatchFilters(t).filter(ele => ele.id === props.defaultValue.key)[0].label],
        }),
      );
    }
  }, [props.defaultValue]);

  function selectChange(key: PriceMatchKey, label: string) {
    if (!key || currentSelect === key) {
      return;
    }
    setCurrentSelect(key);

    dispatch(
      updatePriceMatchFilter<OrderFilterGrouping>({
        collectionName: collectionContext ? collectionContext : props.collection,
        groupKey: key,
        value: [currentInput],
        displayLabels: [priceMatchFilters(t).filter(ele => ele.id === key)[0].label],
      }),
    );
  }

  function inputChange(inputValue: string) {
    const inputNumber = Math.max(0, Number(inputValue)) !== 0 ? Math.max(0, Number(inputValue)) : undefined;
    setCurrentInput(inputNumber);

    dispatch(
      updatePriceMatchFilter<OrderFilterGrouping>({
        collectionName: "orders",
        groupKey: currentSelect,
        value: inputNumber !== undefined ? [inputNumber] : [],
        displayLabels: [priceMatchFilters(t).filter(ele => ele.id === currentSelect)[0].label],
      }),
    );
  }

  return (
    <div className={`filter-list price-match${props.condensed ? " condensed" : ""}`}>
      <Select
        defaultValue={inputValues.select}
        onChange={(key: PriceMatchKey, label: string) => selectChange(key, label)}
      >
        {priceMatchFilters(t).map((match, index: number) => {
          return (
            <Option key={match.id} value={match.id} extraValues={match.label}>
              {match.label}
            </Option>
          );
        })}
      </Select>

      <Input
        type="number"
        value={inputValues.input}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => inputChange(event.target.value)}
      />
    </div>
  );
}
