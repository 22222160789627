import React, { useEffect, useState } from "react";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Checkbox from "components/form/checkbox/Checkbox";
import Sheet from "components/sheet/Sheet";
import Input from "components/form/input/Input";
import { Select as SelectNew, Select } from "components/select/";
import { useCustomerClientContext } from "../../context/CustomerClientContext";
import { PostScheduledPayment } from "api/rpc/2022-09/clientAdmin/houseAccount/houseAccount";
import { capitalize, formatDate } from "helpers/Helpers";
import { StatusCode } from "api/protocols";
import { useAppDispatch } from "hooks/redux";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import { showError, showSuccess } from "redux/actions/ui";
import Callout from "components/callout/Callout";
import { CancelToken } from "axios";
import { useTranslation } from "react-i18next";

type AccountScheduledPaymentProps = {
  open: boolean;
  onClose: () => void;
  onOk: () => Promise<void>;
};

interface IScheduledPayment {
  amount: string;
  date: Date;
  paymentMethodId: number;
  chargeStatementBalance: boolean;
  note: string;
}

export default function AccountScheduledPaymentModal(props: AccountScheduledPaymentProps) {
  const { open, onClose, onOk } = props;
  const { state, updateState } = useCustomerClientContext();
  const dispatch = useAppDispatch();
  const { Option } = SelectNew;
  const { t } = useTranslation();

  const [scheduledPayment, setScheduledPayment] = useState<IScheduledPayment>({
    amount: "0",
    date: new Date(),
    paymentMethodId: null,
    chargeStatementBalance: false,
    note: "",
  });

  useEffect(() => {
    if (open && state?.paymentMethods && state?.paymentMethods?.length > 0) {
      setScheduledPayment(prevState => ({ ...prevState, paymentMethodId: state?.paymentMethods[0]?.id }));
    }
  }, [state?.paymentMethods, open]);

  function handleDateChange(date: Date) {
    setScheduledPayment(prevState => ({ ...prevState, date }));
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;
    setScheduledPayment(prevState => ({ ...prevState, [id]: value }));
  }

  function handlePaymentMethodChange(id: number) {
    setScheduledPayment(prevState => ({ ...prevState, paymentMethodId: id }));
  }

  function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, checked } = event.target;
    setScheduledPayment(prevState => ({ ...prevState, [id]: checked, amount: checked ? "0" : prevState?.amount }));
  }

  function handleOnClose() {
    setScheduledPayment(prevState => ({
      ...prevState,
      amount: "0",
      date: new Date(),
      paymentMethodId: null,
      chargeStatementBalance: false,
      note: "",
    }));
    void onClose();
  }

  async function createScheduledPayment() {
    // Check if date is in the future
    const now = new Date();
    if (scheduledPayment?.date <= now) {
      dispatch(showError("Payment date must be in the future"));
      return;
    }

    const res = await PostScheduledPayment(
      {
        account_id: state?.selectedHouseAccount?.id,
        payment_method_id: scheduledPayment?.paymentMethodId,
        amount: Number(scheduledPayment?.amount),
        process_date: formatDate(scheduledPayment?.date),
        charge_balance: scheduledPayment?.chargeStatementBalance,
        note: scheduledPayment?.note,
      },
      true,
    );
    if (res.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.customer.tabs.house_accounts.account_scheduled_payment_modal.001")));
      return;
    }
    dispatch(showSuccess(t("secure.facility.customer.tabs.house_accounts.account_scheduled_payment_modal.002")));
    void handleOnClose();
    await onOk();
  }

  const disableOK =
    scheduledPayment.amount === "" ||
    isNaN(Number(scheduledPayment.amount)) ||
    !scheduledPayment.date ||
    !scheduledPayment.paymentMethodId;

  return (
    <Sheet
      open={open}
      size="small"
      height="flexible"
      closable
      title={t("secure.facility.customer.tabs.house_accounts.account_scheduled_payment_modal.003")}
      onCancel={handleOnClose}
      onOk={createScheduledPayment}
      cancelText={t("secure.facility.customer.tabs.house_accounts.account_scheduled_payment_modal.004")}
      okText={t("secure.facility.customer.tabs.house_accounts.account_scheduled_payment_modal.005")}
      overflow
      okDisabled={disableOK}
    >
      {state?.paymentMethods?.length === 0 ? (
        <Callout
          type="error"
          title={t("secure.facility.customer.tabs.house_accounts.account_scheduled_payment_modal.006")}
          content={t("secure.facility.customer.tabs.house_accounts.account_scheduled_payment_modal.007")}
        />
      ) : (
        <Form>
          <FormLayout>
            <FormLayout.Group>
              <Input
                label={t("secure.facility.customer.tabs.house_accounts.account_scheduled_payment_modal.008")}
                type="number"
                id="amount"
                value={scheduledPayment.amount}
                onChange={handleInputChange}
                disabled={scheduledPayment?.chargeStatementBalance}
              />
              <DatePickerInput
                months={1}
                position="left"
                startingDate={scheduledPayment.date}
                setStartingDate={handleDateChange}
                label={t("secure.facility.customer.tabs.house_accounts.account_scheduled_payment_modal.009")}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <Select
                label={t("secure.facility.customer.tabs.house_accounts.account_scheduled_payment_modal.010")}
                onChange={handlePaymentMethodChange}
                defaultValue={scheduledPayment?.paymentMethodId}
              >
                {state?.paymentMethods?.length > 0 &&
                  state?.paymentMethods?.map((card, index) => {
                    return (
                      <Option key={index} value={card?.id}>
                        {capitalize(card?.brand)} •••• •••• •••• {card?.last4}
                      </Option>
                    );
                  })}
              </Select>
            </FormLayout.Group>
            <FormLayout.Group>
              <Input
                label={t("secure.facility.customer.tabs.house_accounts.account_scheduled_payment_modal.011")}
                id="note"
                value={scheduledPayment.note}
                onChange={handleInputChange}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <Checkbox
                size="medium"
                value={scheduledPayment.chargeStatementBalance}
                checked={scheduledPayment.chargeStatementBalance}
                onChange={handleCheckboxChange}
                label={t("secure.facility.customer.tabs.house_accounts.account_scheduled_payment_modal.012")}
                id="chargeStatementBalance"
              />
            </FormLayout.Group>
          </FormLayout>
        </Form>
      )}
    </Sheet>
  );
}
