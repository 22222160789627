import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { IProduct } from "redux/reducers/models/product";

import { Badge } from "components/badge/Badge";
import Checkbox from "components/form/checkbox/Checkbox";
import ActionBar from "components/actionBar/ActionBar";
import DataTable from "../customer/tabs/houseAccounts/DataTable";

type ProductTableProps = {
  /** Adds 2 columns to Products Table.
   *  - Enables attach/detach functionality with table
   */
  type: "facility";
  products: IProduct[];
  attachProductToFacility: (event: React.MouseEvent<HTMLElement, MouseEvent>, productId: number) => void;
  tableLimit: number;
  tableOffset: number;
  handleTableOffset: (direction: "prev" | "next") => void;
  removeProducts: (productIds: Array<number>) => void;
};

type ClientProductTableProps = {
  /** Only Name | Type & Variants columns are displayed.
   *  - Client cannot attach/detach facility products
   */
  type: "client";
  products: IProduct[];
  attachProductToFacility?: never; // Client cannot attach/detach a product
  tableLimit: number;
  tableOffset: number;
  handleTableOffset: (direction: "prev" | "next") => void;
  removeProducts: (productIds: Array<number>) => void;
};

export default function ProductsTable(props: ProductTableProps | ClientProductTableProps) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [checkboxState, setCheckboxState] = useState<number[]>([]);
  const checkedProducts = props?.products?.filter(product => checkboxState.includes(product.id) && product);

  /** @param [parent=false] Optional param to toggle every checkbox. */
  function handleChangeEvent(event: React.ChangeEvent<HTMLInputElement>, parent = false) {
    const targetId = Number(event.target.id);

    // Title checkbox click.  Return early.
    if (parent) {
      if (!event.target.checked) {
        setCheckboxState([]);
      } else {
        const allFacilityProducts = props.products
          ?.filter(product => product.facility_access)
          ?.map(product => product.id);
        const allClientProducts = props.products?.map(product => product.id);
        setCheckboxState(props.type === "facility" ? allFacilityProducts : allClientProducts);
      }
      return;
    }

    // Table Row checkbox click
    const updatedState: Array<number> = [...checkboxState];
    const foundIndex = checkboxState.indexOf(targetId);

    if (foundIndex > -1) {
      updatedState.splice(foundIndex, 1);
    } else {
      updatedState.push(targetId);
    }

    setCheckboxState(updatedState);
  }

  // Determines items needing removal.  Reset checkboxState.
  function handleProductRemoval() {
    if (checkedProducts.map(product => product.id).length > 0) {
      props.removeProducts(checkedProducts.map(product => product.id));
      setCheckboxState([]);
    }
  }

  return (
    <ActionBar
      visible={checkboxState?.length > 0}
      selectedItemIds={checkboxState}
      clearSelectedItemIds={() => setCheckboxState([])}
      itemSingularTitle={"Product"} // TODO: Translation
      itemPluralTitle={"Products"} // TODO: Translation
      primaryActions={[
        {
          icon: "trash",
          title:
            props.type === "facility" ? t("secure.facility.product.product_table.011") : "Archive Selected Product(s)", // TODO: Translation
          action: handleProductRemoval,
        },
      ]}
    >
      <DataTable
        columns={
          props.type === "facility"
            ? [
                {
                  label: "",
                  content: (
                    <Checkbox
                      size="small"
                      checked={
                        props.products?.length !== 0 &&
                        props.type === "facility" &&
                        checkboxState.length === props.products?.filter(val => val.facility_access).length
                      }
                      onChange={event => handleChangeEvent(event, true)}
                    />
                  ),
                }, // checkbox
                { label: t("secure.facility.product.product_table.001") },
                { label: t("secure.facility.product.product_table.002") },
                { label: t("secure.facility.product.product_table.003") },
                { label: "Vendor" /* TODO: Translation */ },
                { label: "" },
              ]
            : [
                {
                  label: "",
                  content: (
                    <Checkbox
                      size="small"
                      checked={
                        props.products?.length !== 0 &&
                        props.type === "client" &&
                        checkboxState.length === props.products?.length
                      }
                      onChange={event => handleChangeEvent(event, true)}
                    />
                  ),
                }, // checkbox
                { label: t("secure.facility.product.product_table.001") },
                { label: t("secure.facility.product.product_table.002") },
                { label: t("secure.facility.product.product_table.003") },
                { label: "Vendor" /* TODO: Translation */ },
              ]
        }
        stickyHeader
        footer={{
          tableLimit: props.tableLimit,
          tableOffset: props.tableOffset,
          handleTableOffset: direction => props.handleTableOffset(direction),
          disableNextOffset: !props.products || !(props.products?.length === props.tableLimit),
        }}
        loading={props.products === undefined}
      >
        {props.products?.map(product => {
          return (
            <tr
              key={product.id}
              className="clickable"
              onClick={() => history.push("/admin/product/" + String(product.id))}
            >
              <td className="table-data-checkbox" onClick={event => event.stopPropagation()}>
                <Checkbox
                  id={String(product.id)}
                  size="small"
                  checked={checkboxState.includes(product.id) ?? false}
                  onChange={event => handleChangeEvent(event)}
                  disabled={props.type === "facility" && !product?.facility_access}
                />
              </td>
              <td className="table-data-name">
                <p className="table-cell-lead-text">{product.title}</p>
                <p className="table-cell-support-text">{product.subtitle}</p>
              </td>
              <td className="table-data-types">{product.type}</td>
              <td className="table-data-variants">
                {product.variant_count === 1 ? `${product.variant_count} Variant` : `${product.variant_count} Variants`}
              </td>
              <td className="table-data-variants">{product.vendor_title ?? ""}</td>
              {props.type === "facility" ? (
                <td className="table-data-inventory">
                  {/* Cannot attach to facility if it cannot be sold */}
                  {product?.cannot_be_sold ? (
                    <Badge type="error" size="small">
                      {t("secure.facility.product.product_table.005")}
                    </Badge>
                  ) : (
                    <>
                      {!product?.facility_access ? (
                        <div className="inventory-badge">
                          <Badge
                            onClick={event => {
                              setCheckboxState([]);
                              props.attachProductToFacility(event, product.id);
                            }}
                            type="warning"
                            size="small"
                          >
                            {t("secure.facility.product.product_table.006")}
                          </Badge>
                        </div>
                      ) : null}
                    </>
                  )}
                </td>
              ) : null}
            </tr>
          );
        })}
      </DataTable>
    </ActionBar>
  );
}
