import { DragLayerMonitor, XYCoord, useDragLayer } from "react-dnd";
import { IEditInputDropItem, IEditInputLayoutRowRange } from "./useDropEditInputLayout";
import React, { useEffect, useState } from "react";

interface IEditInputLayoutCustomDragLayerProps {
  layoutRef: React.MutableRefObject<HTMLDivElement>;
  canDrop: boolean;
  getLayoutRowDropIndexInRange: (
    layoutCoordinate: XYCoord,
    relativeDragCoordinate: XYCoord,
  ) => [number, IEditInputLayoutRowRange[]];
}

interface IEditInputLayoutDropHintState {
  y: number;
  width: number;
}

export default function EditInputLayoutCustomDragLayer(props: IEditInputLayoutCustomDragLayerProps) {
  const { layoutRef, canDrop, getLayoutRowDropIndexInRange } = props;

  const DROP_HINT_THICKNESS = 1;

  const [dropHintState, setDropHintState] = useState<IEditInputLayoutDropHintState>(null);

  const { clientOffset, item } = useDragLayer((monitor: DragLayerMonitor) => {
    return {
      clientOffset: monitor.getClientOffset(),
      item: monitor.getItem<IEditInputDropItem>(),
    };
  });

  useEffect(() => {
    if (clientOffset && item && canDrop) {
      const layoutBoundingRectangle = layoutRef.current.getBoundingClientRect();

      const layoutCoordinate: XYCoord = {
        x: layoutBoundingRectangle.x,
        y: layoutBoundingRectangle.y,
      };

      const relativeDragCoordinate: XYCoord = {
        x: clientOffset.x - layoutCoordinate.x,
        y: clientOffset.y - layoutCoordinate.y,
      };

      const [rowDropIndex, rowRanges] = getLayoutRowDropIndexInRange(layoutCoordinate, relativeDragCoordinate);

      const dropHintLocation = rowRanges?.[rowDropIndex]?.startY ?? rowRanges[rowRanges.length - 1].endY;

      setDropHintState({
        y: dropHintLocation,
        width: layoutBoundingRectangle.width,
      });
    } else {
      setDropHintState(null);
    }
  }, [clientOffset, item, canDrop]);

  return (
    <div
      style={{
        display: canDrop ? "block" : "none",
        position: "absolute",
        zIndex: 99,
        top: dropHintState?.y,
        left: 0,
        height: DROP_HINT_THICKNESS,
        width: dropHintState?.width,
        backgroundColor: "green",
      }}
    />
  );
}
