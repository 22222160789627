import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetCashOuts } from "api/rpc/2024-04/facilityAdmin/facility/register/shift";

import { showError } from "redux/actions/ui";
import { displayCurrency } from "helpers/Helpers";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { THIS_MONTH, THIS_YEAR } from "components/form/calendarField/helpers";

import Page from "components/page/Page";
import YearDropFilter from "components/filters/YearDropFilter";
import MonthDropFilter from "components/filters/MonthDropFilter";
import RegisterDropFilter from "components/filters/RegisterDropFilter";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";
import { ICashOut } from "api/rpc/2022-09/facilityAdmin/facility/register/cashOut";

export default function CashOuts() {
  const history = useHistory();
  const { t } = useTranslation();

  const authStore = useAppSelector(store => store.authStore);
  const dispatch = useAppDispatch();

  const [cashOuts, setCashOuts] = useState<ICashOut[]>(undefined);

  // Starting filters for the GetCashOuts API call
  const [filterState, setFilterState] = useState({
    year: THIS_YEAR,
    month: THIS_MONTH,
    register_ids: [],
  });

  const [asyncDropFiltersLoaded, setAsyncDropFiltersLoaded] = useState(false);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (asyncDropFiltersLoaded) {
      void loadCashOuts(source.token);
    }
    return () => source.cancel();
  }, [filterState, asyncDropFiltersLoaded]);

  async function loadCashOuts(token?: CancelToken) {
    if (cashOuts !== undefined) {
      setCashOuts(undefined);
    }

    const res = await GetCashOuts(
      {
        filters: [
          { label: "register", value: filterState.register_ids },
          { label: "year", value: filterState.year ? [filterState.year] : [] },
          { label: "month", value: filterState.month ? [filterState.month] : [] },
        ],
      },
      token ? false : true,
    );

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.cash_outs.cash_out.001")));
      setCashOuts([]);
      return;
    }

    const sortedCashOuts = res.data.sort((prevCashOut: ICashOut, nextCashOut: ICashOut) => {
      const prevCashOutCloseDate = new Date(prevCashOut.closed_at + "Z");
      const nextCashOutCloseDate = new Date(nextCashOut.closed_at + "Z");

      return nextCashOutCloseDate.getTime() - prevCashOutCloseDate.getTime();
    });

    setCashOuts(sortedCashOuts);
  }

  const primaryAction = {
    content: t("secure.facility.settings.cash_outs.cash_out.002"),
    action: () => history.push("/admin/settings/cash-out/new"),
  };

  return (
    <Page
      title={t("secure.facility.settings.cash_outs.cash_out.003")}
      subtitle={t("secure.facility.settings.cash_outs.cash_out.004")}
      primaryAction={authStore.user?.permissions.cash_outs_create && primaryAction}
    >
      <div className="flex flex-row flex-wrap gap-4 pb-4">
        <MonthDropFilter
          handleApply={month => setFilterState(prev => ({ ...prev, month: month }))}
          monthIdValue="index"
          defaultCheckboxes={[filterState.month]}
          leftAligned
        />

        <YearDropFilter
          handleApply={year => setFilterState(prev => ({ ...prev, year: year }))}
          defaultCheckboxes={[filterState.year]}
        />

        <RegisterDropFilter
          dataLoaded={() => setAsyncDropFiltersLoaded(true)}
          handleApply={selectedRegisterIds =>
            setFilterState(prev => ({ ...prev, register_ids: selectedRegisterIds }))
          }
          defaultCheckboxes={filterState.register_ids}
          userLevel={"facility"}
          disableDropdown={!asyncDropFiltersLoaded}
        />
      </div>

      <DataTable
        columns={[
          { label: t("secure.facility.settings.cash_outs.cash_out.005") },
          { label: t("secure.facility.settings.cash_outs.cash_out.010") },
          { label: t("secure.facility.settings.cash_outs.cash_out.007") },
          { label: t("secure.facility.settings.cash_outs.cash_out.008") },
          { label: t("secure.facility.settings.cash_outs.cash_out.009") },
          { label: t("secure.facility.settings.cash_outs.cash_out.011") },
          { label: "Created At" }, // TODO: Translation
          { label: "Deposited On" }, // TODO: Translation
        ]}
        loading={cashOuts === undefined}
      >
        {cashOuts?.map(shift => (
          <tr
            key={shift.id}
            className="clickable"
            onClick={() => history.push(`/admin/settings/cash-out/${String(shift.id)}`)}
          >
            <td>
              {moment
                .utc(new Date(shift.closed_at + "Z"))
                .local()
                .format("MMM DD, YYYY")}
            </td>
            <td>{shift.register_title}</td>
            <td>{displayCurrency("cad", shift.deposit_cash)}</td>
            <td>{displayCurrency("cad", shift.deposit_check)}</td>
            <td>{shift.closed_by_full_name}</td>
            <td>{shift.deposit_bag_number}</td>
            <td>
              <span>{moment.utc(shift.created_at).local().format("MMM DD, YYYY")} at </span>
              <span>{moment.utc(shift.created_at).local().format("h:mm A")}</span>
            </td>
            <td>{shift.deposit_date && moment.utc(shift.deposit_date).format("MMM DD, YYYY")}</td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
