import React, { useState, ChangeEvent } from "react";
import { useHistory } from "react-router-dom";
import { TFunction, useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { showError, showSuccess } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";
import { handleChangeEventInput, formatDate } from "helpers/Helpers";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import { Select } from "components/select/index";
import { TWeekDays } from "components/form/calendarField/helpers";
import TimePick from "components/timePick/TimePick";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import { selectDate } from "redux/actions/teesheet";
import { ILeaguePost, PostLeague } from "api/rpc/2024-04/facilityAdmin/league/league";

export default function LeagueNew() {
  const { Option } = Select;
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();

  const [league, setLeague] = useState<ILeaguePost>({
    name: "",
    week_day: "Monday",
    start_time: "12:00",
  });

  const [leagueTimes, setLeagueTimes] = useState({
    start_time: "12:00",
    start_date: new Date(),
    end_date: new Date(),
  });

  async function createLeague(params: ILeaguePost) {
    const leagueParams = {
      ...params,
      start_date: formatDate(leagueTimes.start_date),
      end_date: formatDate(leagueTimes.end_date),
    };
    const leagueRes = await PostLeague(leagueParams, true);
    if (leagueRes.status !== StatusCode.OK) {
      dispatch(showError(typeof leagueRes.data === "string" ? leagueRes.data : "Error creating league."));
      return;
    }

    dispatch(showSuccess("A league was successfully created."));
    history.push("/admin/events");
  }

  const primaryAction = {
    content: t("secure.facility.league.new.008"),
    action: () => createLeague(league),
  };

  function setDate(date: Date, key: string) {
    const formattedDate = formatDate(date);
    setLeague(prevState => ({ ...prevState, [key]: formattedDate }));
    setLeagueTimes(prevState => ({ ...prevState, [key]: date }));
  }

  console.log("league state", league);
  return (
    <Page
      title={t("secure.facility.league.new.009")}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[{ label: t("secure.facility.league.new.010"), url: "/admin/events" }]}
    >
      <Form>
        <Card title={t("secure.facility.league.new.011")}>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={league.name}
                  label={t("secure.facility.league.new.012")}
                  id="name"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeEventInput(e, league, setLeague)}
                  placeholder={t("secure.facility.league.new.012")}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Select
                  label={t("secure.facility.league.new.013")}
                  onChange={(val: TWeekDays) => setLeague(prev => ({ ...prev, week_day: val }))}
                  defaultValue={league.week_day}
                >
                  {daysOfWeek(t).map((weekday, index: number) => {
                    return (
                      <Option key={index} value={weekday.value} name={weekday.label}>
                        <span>{weekday.label}</span>
                      </Option>
                    );
                  })}
                </Select>
                <TimePick
                  label="Start Time"
                  value={league.start_time}
                  onChange={timeString => setLeague(prev => ({ ...prev, start_time: timeString }))}
                  size="large"
                  align="right"
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <DatePickerInput
                  label="Start Date"
                  startingDate={leagueTimes.start_date}
                  setStartingDate={(date: Date) => setDate(date, "start_date")}
                  months={1}
                  position="left"
                />

                <DatePickerInput
                  label="End Date"
                  startingDate={leagueTimes.end_date}
                  setStartingDate={(date: Date) => setDate(date, "end_date")}
                  months={1}
                  position="left"
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>
      </Form>
    </Page>
  );
}

export const daysOfWeek = (t: TFunction): { label: string; value: TWeekDays }[] => {
  return [
    { label: t("secure.facility.league.new.001"), value: "Monday" },
    { label: t("secure.facility.league.new.002"), value: "Tuesday" },
    { label: t("secure.facility.league.new.003"), value: "Wednesday" },
    { label: t("secure.facility.league.new.004"), value: "Thursday" },
    { label: t("secure.facility.league.new.005"), value: "Friday" },
    { label: t("secure.facility.league.new.006"), value: "Saturday" },
    { label: t("secure.facility.league.new.007"), value: "Sunday" },
  ];
};
