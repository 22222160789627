import { APIGet, APIPost, APIPut, APIDelete, IAPIResponse } from "api/protocols";
import { CancelToken } from "axios";
import { ParentMembership } from "pages/secure/facility/customer/context/contextTypes";

const apiVersion = "2024-04";
const baseCustomerUrl = "/" + apiVersion + "/customer/customer/membership";

interface IGetMembership {
  membership_id?: number;
  offset?: number;
  limit?: number;
  client_id?: number;
  customer_id?: number;
}

export interface ICustomerMembership {
  id: number;
  client_id: number;
  customer_id: number;
  membership_id: number;
  membership_title: string;
  membership_type: string;
  membership_number: string;
  membership_subtitle?: string;
  order_id: number;
  order_name: string;
  status: string;
  season: string;
  home_facility_id: number;
  notes: string;
  expiry: string;
  home_facility_long_name?: string;
  parent_membership?: ParentMembership;
  status_readable?: string | null;
}

export type CustomerMembershipsType = {
  client_id: number;
  customer_id: number;
  expiry: string | null;
  home_facility_id: number;
  id: number;
  membership_id: number;
  membership_number: string;
  membership_title: string;
  membership_type: string;
  notes: Record<string, any>;
  order_id: number | null;
  order_name: string | null;
  season: string | null;
  status: string | null;
};

export async function GetCustomerMemberships(
  params: IGetMembership,
  useGlobalLoader: boolean,
  cancelToken?: CancelToken,
): Promise<IAPIResponse<Array<ICustomerMembership>>> {
  return await APIGet(baseCustomerUrl, params, useGlobalLoader, cancelToken);
}
