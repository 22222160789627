import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName, IconPrefix } from "@fortawesome/fontawesome-common-types";
import "components/icon/icon.scss";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { TABLET_WIDTH } from "helpers/ScreenSizes";

interface IIconProps {
  icon: string;
  style: IconPrefix;
  type?: "primary" | "gray" | "error" | "info" | "warning" | "success";
  size?: "xsmall" | "small" | "medium" | "large" | "xlarge";
  primary?: boolean;
  gray?: boolean;
  warning?: boolean;
  success?: boolean;
  error?: boolean;
  className?: string;
  tooltip?: { title: string; description: string; position: "left" | "center" | "right" };
}

const Icon: React.FC<IIconProps> = props => {
  const { icon, style, type, size, primary, gray, warning, success, error, className, tooltip } = props;

  const [iconHovered, setIconHovered] = useState<boolean>(false);
  const [iconPressed, setIconPressed] = useState<boolean>(false);
  const iconRef = useRef<HTMLSpanElement>(null);
  const windowSize = useWindowSize();

  const iconCls = classNames(
    "ui-icon",
    {
      "ui-icon_extra-small": size === "xsmall",
      "ui-icon_small": size === "small",
      "ui-icon_medium": size === "medium",

      "ui-icon_primary": props.type === "primary",
      "ui-icon_gray": props.type === "gray",
      "ui-icon_success": props.type === "success",
      "ui-icon_error": props.type === "error",
      "ui-icon_warning": props.type === "warning",
    },
    className,
  );

  function handleOutsideIconPressed(event: any) {
    if (!iconRef.current || iconRef.current.contains(event.target)) {
      return;
    }

    setIconPressed(false);
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideIconPressed);
    document.addEventListener("touchstart", handleOutsideIconPressed);

    return () => {
      document.removeEventListener("mousedown", handleOutsideIconPressed);
      document.removeEventListener("touchstart", handleOutsideIconPressed);
    };
  }, []);

  function handleIconPressed() {
    if (windowSize.width <= TABLET_WIDTH) {
      setIconPressed(prev => !prev);
    }
  }

  return (
    <span
      ref={iconRef}
      className={iconCls}
      onMouseEnter={() => setIconHovered(true)}
      onMouseLeave={() => setIconHovered(false)}
      onClick={handleIconPressed}
    >
      <FontAwesomeIcon icon={[style, icon as IconName]} className="ui-icon_svg" />
      {tooltip &&
        ((windowSize.width > TABLET_WIDTH && iconHovered) || (windowSize.width <= TABLET_WIDTH && iconPressed)) && (
          <div className={classNames("ui-icon-tooltip", `ui-icon-tooltip-position-${tooltip.position}`)}>
            <div className="ui-icon-tooltip-content">
              <div className="ui-icon-tooltip-content-title">{tooltip.title}</div>
              <div className="ui-icon-tooltip-content-description">{tooltip.description}</div>
            </div>
          </div>
        )}
    </span>
  );
};

export default Icon;
