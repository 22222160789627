import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import { StatusCode } from "api/protocols";
import { GetTournament, PutTournament } from "api/rpc/2022-09/clientAdmin/tournament/tournament";

import { showError, showSuccess } from "redux/actions/ui";
import { ITournament } from "redux/reducers/models/tournament";

import { formatDate } from "helpers/Helpers";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import TextField from "components/form/textField/TextField";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import TimePick from "components/timePick/TimePick";

type TournamentEditState = {
  id: number;
  name: string;
  start_time: string;
  date: Date;
  description: string;
};

export default function Settings(props: any) {
  const dispatch = useAppDispatch();
  const [tournament, setTournament] = useState<ITournament>(null);

  const [state, setState] = useState<TournamentEditState>({
    id: null,
    name: "",
    start_time: "",
    date: null,
    description: "",
  });

  const { tournamentId }: any = useParams();

  useEffect(() => {
    void loadTournament();
  }, []);

  // SYNC input data whenever Touraments API called.
  useEffect(() => {
    if (tournament !== null) {
      setState({
        id: tournament.id,
        name: tournament.name,
        start_time: tournament.start_time,
        date: new Date(tournament.date + " EDT"),
        description: tournament.description ?? "",
      });
    }
  }, [tournament]);

  function handleInputChange(id: keyof TournamentEditState, value: string) {
    setState(prevState => ({ ...prevState, [id]: value }));
  }

  async function loadTournament() {
    const params = {
      id: tournamentId,
      extended: true,
    };

    const tournamentRes = await GetTournament(params, false);

    console.log(tournamentRes);

    if (tournamentRes.status !== StatusCode.OK) {
      return;
    }

    setTournament({ ...tournament, ...tournamentRes.data[0] });
  }

  async function saveTournament() {
    console.log(state);
    const tournamentRes = await PutTournament(
      {
        ...state,
        date: formatDate(state.date),
      },
      true,
    );

    if (tournamentRes.status !== StatusCode.OK) {
      dispatch(showError(tournamentRes.data.message ?? "Error updating tournament."));
      return;
    }

    dispatch(showSuccess("Tournament updated successfully."));
  }

  const primaryAction = {
    content: "Save",
    action: () => {
      void saveTournament();
    },
  };

  return (
    <Page
      title="General Settings"
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[{ label: "Settings", url: "/admin/tournament/menu/" + String(state?.id) + "/settings" }]}
    >
      <Form>
        <Card title="Tournament Details">
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={state?.name}
                  label="Name"
                  id="name"
                  onChange={e => handleInputChange("name", e.target.value)}
                  placeholder="Name"
                />
              </FormLayout.Group>
            </FormLayout>
            <FormLayout>
              <FormLayout.Group>
                <TimePick
                  label={"Time"}
                  value={state.start_time}
                  onChange={(timeString: string) => handleInputChange("start_time", timeString)}
                  size="large"
                  status={state.start_time === undefined ? "error" : undefined}
                />
                <DatePickerInput
                  label="Date"
                  months={1}
                  position={"center"}
                  startingDate={state?.date}
                  setStartingDate={selectedDate => setState({ ...state, date: selectedDate as Date })}
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <TextField
                  value={state?.description}
                  label="Description"
                  id="description"
                  onChange={e => handleInputChange("description", e.target.value)}
                  placeholder="Description"
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
          <Card.Section title="Logo"></Card.Section>
        </Card>
      </Form>
    </Page>
  );
}
