import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";

import { PostDivision } from "api/rpc/2024-04/masterAdmin/facility/division";
import { GetCourse } from "api/rpc/2024-04/masterAdmin/facility/course";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Input from "components/form/input/Input";
import { Select } from "components/select/";
import FormLayout from "components/form/FormLayout";
import Card from "components/card/Card";
import Form from "components/form/Form";

import { isEqualWith, isNull } from "lodash";
import { NotificationType } from "components/notificationBar/NotificationBar";
import { IAuthState } from "redux/reducers/auth";
import { IAuthActions } from "redux/actions/auth";
import { IUIActions } from "redux/actions/ui";
import { isPositiveInteger } from "helpers/Helpers";
import { useAppDispatch, useAppSelector } from "hooks/redux";

interface IDivisionProps {
  authStore: IAuthState;
  authActions: IAuthActions;
  uiActions: IUIActions;
}

interface IDivision {
  title: string;
  course_id: number;
}

export default function DivisionNew(props: IDivisionProps) {
  const { t, i18n } = useTranslation();
  const { Option } = Select;
  const history = useHistory();
  const { masterFacilityStore } = useAppSelector(store => store);

  const [divisionState, setDivisionState] = useState<IDivision>({
    title: "",
    course_id: null,
  });
  const [courseState, setCourseState] = useState({
    courses: [],
  });

  const [divisionStateBeforeChanges, setDivisionStateBeforeChanges] = useState<IDivision>(undefined);

  useEffect(() => {
    void loadCourses();
  }, [masterFacilityStore.facility]);

  async function loadCourses() {
    const courseRes = await GetCourse({ facility_id: masterFacilityStore.facility?.id, scoring_only: true }, true);
    if (courseRes.status !== StatusCode.OK) {
      props.uiActions.showError("Courses do not exist");
      return;
    }

    setCourseState(prev => ({
      ...prev,
      courses: courseRes.data,
    }));
  }

  async function createDivision() {
    const postDivisionRes = await PostDivision(
      {
        title: divisionState.title,
        course_id: divisionState.course_id,
        facility_id: masterFacilityStore.facility?.id,
      },
      true,
    );

    if (postDivisionRes.status !== StatusCode.OK) {
      props.uiActions.showError("Error creating division");
      return;
    }

    // Go back to page
    history.push("/admin/facility/settings/division");
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setDivisionState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleDropDownChange(value: any, property: string) {
    setDivisionState(prev => ({ ...prev, [property]: value }));
  }

  function unsavedChangesExist() {
    if (divisionStateBeforeChanges === undefined) {
      if (divisionState) {
        setDivisionStateBeforeChanges(divisionState);
      }
      return false;
    }

    return !isEqualWith(divisionStateBeforeChanges, divisionState, (originalValue, newValue) => {
      if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
        return true;
      }
    });
  }

  function cancelUnsavedChanges() {
    setDivisionState(divisionStateBeforeChanges);
  }

  const primaryAction = {
    content: "Create",
    action: createDivision,
    disabled: !masterFacilityStore.facility,
  };

  return (
    <Page
      title="New Division"
      subtitle={masterFacilityStore.facility ? masterFacilityStore.facility.long_name : "No Facility Selected"}
      primaryAction={primaryAction}
      narrow
      breadcrumbs={[
        {
          prefix: true,
          label: "Divisions",
          url: "/admin/facility/settings/division",
        },
      ]}
    >
      {masterFacilityStore.facility && (
        <Form>
          <Card>
            <Card.Section>
              <FormLayout>
                <FormLayout.Group>
                  <Input
                    value={divisionState.title}
                    label="Title"
                    id="title"
                    onChange={handleInputChange}
                    placeholder="Title"
                  />
                </FormLayout.Group>
                {courseState.courses.length > 1 ? (
                  <FormLayout.Group>
                    <Select
                      label="Course"
                      onChange={(value: any) => handleDropDownChange(value, "course_id")}
                      defaultValue={divisionState.course_id}
                    >
                      {courseState.courses.map((course: any, index: number) => {
                        return (
                          <Option key={index} value={course.id} name={course.title}>
                            {course.full_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormLayout.Group>
                ) : null}
              </FormLayout>
            </Card.Section>
          </Card>
        </Form>
      )}
    </Page>
  );
}
