import React, { TextareaHTMLAttributes, useEffect, useState, useRef, InputHTMLAttributes } from "react";
import classNames from "classnames";
import { ButtonNew as Button } from "components/buttonNew";
import Connected from "components/connected/Connected";

import "components/form/form.scss";
import "components/form/input/input.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TagNew as Tag } from "components/tagNew";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { TABLET_WIDTH } from "helpers/ScreenSizes";
// Available Props
// label
// value
// onChange
// onKeyDown
// type
// labelAction (content, url)
// multiLine
// labelHidden
// alignRight
// placeholder
// prefix/suffix
// connectedRight
// connectedLeft
// helpText
// type
// error
// disabled
// maxLength
// showCharacterCount
// cleanButton
// clearCuttonClick
// tags
// onTagClick

export interface IInputProps {
  className?: string;
  label?: any;
  value?: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: any;
  type?: any;
  labelAction?: { content: string; onClick: (e: React.MouseEvent<HTMLButtonElement>) => void };
  multiLine?: any;
  labelHidden?: boolean;
  alignRight?: any;
  placeholder?: any;
  prefix?: any;
  prefixColour?: string;
  suffix?: string;
  suffixColour?: string;
  trailingButtons?: JSX.Element[];
  leadingButtons?: JSX.Element[];
  connectedLeft?: any;
  connectedRight?: any;
  helpText?: any;
  error?: boolean;
  disabled?: any;
  maxLength?: any;
  showCharacterCount?: any;
  cleanButton?: any;
  clearCuttonClick?: any;
  tags?: any;
  onTagClick?: (index: number) => void;
  onFocus?: (e: any) => void;
  name?: string;
  id?: any;
  tagType?: "light" | "dark";
  expandable?: boolean;
  readOnly?: boolean;
  defaultValue?: any;
  autoFocus?: boolean;
  //Dropdown
  children?: JSX.Element;
  onBlur?: any;
  labelStyle?: React.CSSProperties;
  autoComplete?: string;
  min?: any;
  max?: any;
  step?: any;
  onSearchClear?: () => void;
}

const InternalInput: React.FC<IInputProps> = props => {
  const tagAreaRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const windowSize = useWindowSize();
  const [overlayAreaHeight, setOverlayAreaHeight] = useState("2.8rem");

  useEffect(() => {
    if (props.tags) {
      setOverlayAreaHeight(`${tagAreaRef.current?.scrollHeight}px`);
    } else {
      if (windowSize?.width > TABLET_WIDTH) {
        setOverlayAreaHeight("44px");
      } else {
        setOverlayAreaHeight("30px");
      }
    }
  }, [windowSize, props.tags]);

  useEffect(() => {
    if (props.autoFocus) {
      inputRef.current.focus();
    }
  }, []);

  function handleSearchClear() {
    void props.onSearchClear();
    inputRef.current.focus();
  }

  const dropDownDisplay = props.children ? (
    <div className="flex items-center justify-start w-full pl-1 items-center cursor-text">
      {React.cloneElement(props.children, {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        className: `${props.children.props.className} ui-dropdown-bar`,
      })}
      <input
        className={
          `mx-2 pt-2 pb-2 z-20 appearance-none border-none focus:outline-none flex-grow ui-text-field-dropdown` +
          (props.className ? " " + props.className : "") +
          (props.error ? " ui-input-error" : "")
        }
        name={props.name}
        ref={inputRef}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
        disabled={props.disabled}
        maxLength={props.maxLength}
        onFocus={props.onFocus}
        id={props.id}
        type={props.type}
        readOnly={props.readOnly}
        defaultValue={props.defaultValue}
        onBlur={props.onBlur}
        autoComplete={props.autoComplete}
        min={props.min}
        max={props.max}
        step={props.step}
      />
      <div
        tabIndex={0}
        style={{
          height: props.tags ? overlayAreaHeight : undefined,
        }}
        className={
          " h-10 absolute z-30 top-0 right-0 bottom-0 left-0 pointer-events-none ui-text-field-dropdown_border"
        }
      ></div>
    </div>
  ) : null;

  const tagDisplay = props.tags ? (
    <div
      onClick={() => {
        inputRef.current.focus();
      }}
      ref={tagAreaRef}
      className="flex flex-wrap items-center justify-start w-full pl-1 pr-1 items-center cursor-text relative"
    >
      {props.tags.map((tag: string, index: number) => {
        return (
          <Tag
            key={`${tag}${index}`}
            closeIcon={<FontAwesomeIcon icon={["fal", "times"]} style={{ fontWeight: "lighter", opacity: 0.5 }} />}
            onClose={e => props.onTagClick(index)}
            closable
            prefixCls="input-tag"
            className={`whitespace-nowrap  items-center rounded-lg my-1 mx-0.5 ${
              props.tagType === "light" ? "tag-light" : "tag-dark"
            }`}
          >
            <p className="whitespace-nowrap overflow-clip inline-block overflow-hidden ml-1 font-semibold">{tag}</p>
          </Tag>
        );
      })}
      <input
        // style={{
        //   width: props.value
        //     ? `${String(Number(props.value.length) + 2)}ch`
        //     : `${String(Number(props.placeholder.length) + 2)}ch`,
        // }}
        className={
          `appearance-none focus:outline-none
        ui-text-field_input_tag max-w-full` + (props.className ? " " + props.className : "")
        }
        name={props.name}
        ref={inputRef}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
        disabled={props.disabled}
        maxLength={props.maxLength}
        onFocus={props.onFocus}
        id={props.id}
        type={props.type}
        readOnly={props.readOnly}
        defaultValue={props.defaultValue}
        onBlur={props.onBlur}
        autoComplete={props.autoComplete}
        min={props.min}
        max={props.max}
        step={props.step}
      />
      <div
        tabIndex={0}
        style={{
          height: props.tags ? overlayAreaHeight : undefined,
        }}
        className={
          " h-10 absolute z-30 top-0 right-0 bottom-0 left-0 pointer-events-none ui-text-field-border_input_tag" +
          (props.error ? " ui-input-error" : "")
        }
      ></div>
    </div>
  ) : null;

  return (
    <div className="ui-input">
      {!props.labelHidden ? (
        <div className="ui-input-label_container">
          <div className="ui-input-label">
            <label style={{ marginBottom: 0 }}>{props.label}</label>
          </div>
          {props.labelAction ? (
            <div className="ui-text-field_label-action">
              <Button type="link" onClick={props.labelAction.onClick}>
                {props.labelAction.content}
              </Button>
            </div>
          ) : null}
        </div>
      ) : null}

      {props.connectedLeft ? <div>{props.connectedLeft}</div> : null}

      <div className="ui-input_input">
        {props.prefix ? (
          <div
            style={{ height: overlayAreaHeight, backgroundColor: props.prefixColour && props.prefixColour }}
            className="ui-input-prefix"
          >
            <span className="whitespace-nowrap overflow-hidden overflow-ellipsis">{props.prefix}</span>
          </div>
        ) : null}

        {props?.leadingButtons &&
          props.leadingButtons?.map((leadingButton, index) => {
            return (
              <div key={index} className="ui-input-leading-button">
                {leadingButton}
              </div>
            );
          })}
        {tagDisplay ? (
          tagDisplay
        ) : dropDownDisplay ? (
          dropDownDisplay
        ) : (
          <div className="ui-input-main-container">
            <input
              className={classNames(props.className, {
                "ui-input-with-trailing-button": props.trailingButtons?.length > 0,
                "ui-input-with-leading-button": props.leadingButtons?.length > 0,
                "ui-input-main-search": props.onSearchClear,
                "ui-text-field_prefix-hard-border": props.prefix,
                "ui-input-error": props.error,
              })}
              name={props.name}
              ref={inputRef}
              value={props.value}
              placeholder={props.placeholder}
              onChange={props.onChange}
              onKeyDown={props.onKeyDown}
              disabled={props.disabled}
              maxLength={props.maxLength}
              onFocus={props.onFocus}
              id={props.id}
              type={props.type}
              readOnly={props.readOnly}
              defaultValue={props.defaultValue}
              onBlur={props.onBlur}
              autoComplete={props.autoComplete}
              min={props.min}
              max={props.max}
              step={props.step}
            />
            <FontAwesomeIcon
              icon={["far", "magnifying-glass"]}
              size="1x"
              className={classNames("input-search-icon input-clear-button", {
                "input-clear-button-hide": !props.onSearchClear,
              })}
            />

            <FontAwesomeIcon
              onClick={handleSearchClear}
              icon={["far", "xmark"]}
              size="1x"
              className={classNames("input-clear-button", {
                "input-clear-button-hide": !props.onSearchClear || !props.value,
              })}
            />
          </div>
        )}

        {props.showCharacterCount ? (
          <div className="mr-5 ml-1 text-gray-200 z-20 text-right">
            <span>11/{props.maxLength}</span>
          </div>
        ) : null}

        {props.suffix ? (
          <div
            style={{ height: overlayAreaHeight, backgroundColor: props.suffixColour && props.suffixColour }}
            className="ui-input-suffix"
          >
            <span className="whitespace-nowrap overflow-hidden overflow-ellipsis">{props.suffix}</span>
          </div>
        ) : null}

        {props.trailingButtons?.map((trailingButton, index) => {
          return (
            <div key={index} className="ui-input-trailing-button">
              {trailingButton}
            </div>
          );
        })}

        {(props.prefix || props.suffix) && (
          <div
            tabIndex={0}
            style={{ height: props.tags ? overlayAreaHeight : undefined }}
            className={
              " h-10 absolute z-30 top-0 right-0 bottom-0 left-0 pointer-events-none ui-text-field_prefix_border"
            }
          ></div>
        )}
      </div>

      {props.helpText ? (
        <div className="ui-input-help-text font-normal text-gray-400 mt-2">
          <span>{props.helpText}</span>
        </div>
      ) : null}
    </div>
  );
};

export default InternalInput;
