import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetDivision } from "api/rpc/2024-04/masterAdmin/facility/division";

import { showError } from "redux/actions/ui";
import { IDivision } from "redux/reducers/models/facility";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import Page from "components/page/Page";
import DataTable from "pages/secure/master/customer/tabs/houseAccounts/DataTable";

export default function Divisions() {
  const history = useHistory();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const authPermissions = useAppSelector(store => store.authStore)?.user?.permissions;
  const facilityPermissions = useAppSelector(store => store.facilityStore)?.facility?.permissions;
  const { masterFacilityStore } = useAppSelector(store => store);

  const [divisions, setDivisions] = useState<IDivision[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadDivisions(source.token);
    return () => source.cancel();
  }, [masterFacilityStore.facility]);

  async function loadDivisions(token?: CancelToken) {
    if (divisions !== undefined) {
      setDivisions(undefined);
    }
    const res = await GetDivision({ facility_id: masterFacilityStore.facility?.id }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading divisions")); // TODO: Translation
    }

    setDivisions(res.status !== StatusCode.OK ? [] : (res.data as IDivision[]));
  }

  const primaryAction = {
    content: t("secure.facility.settings.facility.divisions.001"),
    action: () => history.push("/admin/facility/settings/division/new"),
    disabled: !masterFacilityStore.facility,
  };

  return (
    <Page
      title={t("secure.facility.settings.facility.divisions.002")}
      subtitle={masterFacilityStore.facility ? masterFacilityStore.facility.long_name : "No Facility Selected"}
      narrow
      primaryAction={primaryAction}
    >
      {masterFacilityStore.facility && (
        <DataTable
          columns={[
            { label: t("secure.facility.settings.facility.divisions.003"), width: "40%" },
            { label: "Course", width: "40%" },
            { label: "", width: "20%" },
          ]}
          loading={divisions === undefined}
        >
          {divisions?.map(division => (
            <tr
              key={division.id}
              onClick={() => history.push("/admin/facility/settings/division/" + String(division.id))}
              className="clickable"
            >
              <td>{division.title}</td>
              <td>{division.course.long_name}</td>
              <td>{division.color && <FontAwesomeIcon icon={["fas", "circle"]} color={division?.color} />}</td>
            </tr>
          ))}
        </DataTable>
      )}
    </Page>
  );
}
