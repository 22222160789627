import { APIGet, APIPost, APIPut, IAPIResponse } from "api/protocols";
import { ITeeTime } from "redux/reducers/models/teetime";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseTemplateUrl = "/" + apiVersion + "/" + adminType + "/tee-sheet/template";

interface ITeeSheetTemplate {
  id: number;
  facility_id: number;
  course_id: number;
  name: string;
  format: string;
  start_time: string;
  end_time: string;
  holes: number;
  interval: number;
  turn_time: number;
  tee_times: ITeeTime[];
}

interface IGetTeeSheetTemplate {
  id: number;
  extended?: boolean;
}

interface IGetTeeSheetTemplateResponse extends IAPIResponse {
  data: ITeeSheetTemplate[];
}

export interface ITeeTimeBookingCategoryUpdate extends ITeeTime {
  booking_category_ids?: number[];
}

interface IPutTeeSheetTemplate {
  id: number;
  name: string;
  tee_times: ITeeTimeBookingCategoryUpdate[];
}

interface IPutTeeSheetTemplateResponse extends IAPIResponse {
  data: ITeeSheetTemplate[];
}

export interface IGetShotGunTemplate {
  id: number;
  title?: string;
  extended?: boolean;
}

export interface IShotgunTemplateGroup {
  group_index: number;
  start_hole: string;
  start_time: string;
  checked?: boolean;
}

export interface IApplyShotgunTemplate {
  tee_sheet_id: number;
  start_time: string;
  duration: number;
  lead_time: number;
  shotgun_template_id: number;
  division_id: number;
  turn_division_id: number;
  booking_category_ids: Array<number>;
}

// GET Tee Sheet Template
export async function GetTeeSheetTemplate(
  params: IGetTeeSheetTemplate,
  useGlobalLoader: boolean,
): Promise<IGetTeeSheetTemplateResponse> {
  return await APIGet(baseTemplateUrl, params, useGlobalLoader);
}

// GET Tee Sheet Template
export async function PostTeeSheeTemplate(body: any, useGlobalLoader: boolean) {
  return await APIPost(baseTemplateUrl, body, useGlobalLoader);
}

//PUT Update Tee Sheet Template
export async function PutTeeSheetTemplate(
  body: IPutTeeSheetTemplate,
  useGlobalLoader: boolean,
): Promise<IPutTeeSheetTemplateResponse> {
  return await APIPut(baseTemplateUrl, body, useGlobalLoader);
}

// GET Tee Sheet Season
// This is a temp endpoint
export async function PostTeeSheetSeason(body: any, useGlobalLoader: boolean) {
  return await APIPost("/2021-01/facility-admin/tee-sheet/season", body, useGlobalLoader);
}

// Get Tee Sheet Shotgun Template
export async function GetShotGunTemplate(params: IGetShotGunTemplate, useGlobalLoader: boolean) {
  return await APIGet(baseTemplateUrl + "/shotgun", params, useGlobalLoader);
}

// POST Tee Sheet Shotgun Template
export async function PostShotGunTemplate(
  params: { title: string; groups: Array<IShotgunTemplateGroup> },
  useGlobalLoader: boolean,
) {
  return await APIPost(baseTemplateUrl + "/shotgun", params, useGlobalLoader);
}

// PUT Generate Shotgun Template
export async function PutShotGunTemplateGenerate(holes: string, useGlobalLoader: boolean) {
  return await APIPut(baseTemplateUrl + "/shotgun/generate", holes, useGlobalLoader);
}

// Put Tee Sheet Shotgun Template
export async function PutShotGunTemplateApply(params: IApplyShotgunTemplate, useGlobalLoader: boolean) {
  return await APIPut(baseTemplateUrl + "/shotgun/apply", params, useGlobalLoader);
}

// Put Tee Sheet Shotgun Template
export async function GetTeeSheetTemplatePrint(params: { id: number }, useGlobalLoader: boolean) {
  return await APIGet(baseTemplateUrl + "/print", params, useGlobalLoader);
}
