import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import Checkbox from "components/form/checkbox/Checkbox";
import { Select as SelectNew, Select } from "components/select/";

import "pages/secure/facility/product/Ticket.scss";
import { ITeeSheetState } from "redux/reducers/teesheet";
import { ITeeSheetActions } from "redux/actions/teesheet";
import { GetFacility } from "api/rpc/clientAdmin/facility/facility";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { TABLET_WIDTH } from "helpers/ScreenSizes";
import TextEditor from "components/textEditor/textEditor";
import { PostTicket } from "api/rpc/2024-04/clientAdmin/ticket/ticket";

interface ITicketState {
  id: number;
  title: ITicketInputField;
  subtitle?: string;
  included: ITicketInputField;
  description: ITicketInputField;
  value: string;
  expiry_type: ITicketInputField;
  expiry_date: ITicketInputField;
  expiry_days: ITicketInputField;
  valid_facilities: ITicketArrayField;
  facility_ids_list: IFacilityInformation[];
  selectedDate: Date;
  product_redemption: any;
}

interface IFacilityInformation {
  id: any;
  value: any;
  label: any;
}

interface ITicketProps {
  teeSheetStore: ITeeSheetState;
  teeSheetActions: ITeeSheetActions;
}

interface ITicketInputField {
  data: string;
  isDirty: boolean;
  isValid: boolean;
}

interface ITicketArrayField {
  data: any[];
  isDirty: boolean;
  isValid: boolean;
}

const Ticket: React.FC<ITicketProps> = props => {
  const { teeSheetStore, teeSheetActions } = props;

  const { Option } = SelectNew;
  const { t, i18n } = useTranslation();
  const windowSize = useWindowSize();

  const history = useHistory();
  const [ticketState, setTicketState] = useState<ITicketState>({
    id: null,
    title: { data: "", isDirty: false, isValid: false },
    subtitle: "",
    included: { data: "", isDirty: false, isValid: false },
    description: { data: "", isDirty: false, isValid: false },
    value: "",
    expiry_type: { data: "", isDirty: false, isValid: false },
    expiry_date: { data: "", isDirty: false, isValid: false },
    expiry_days: { data: "", isDirty: false, isValid: false },
    valid_facilities: { data: [], isDirty: false, isValid: false },
    facility_ids_list: [],
    selectedDate: new Date(),
    product_redemption: [],
  });

  useEffect(() => {
    // loadTicketInformation();
    void loadFacilities();
  }, []);

  async function loadFacilities() {
    const params = "client-facilities=true";

    const facilityRes = await GetFacility({ extended: true }, true);

    const tempFacilityIdList: IFacilityInformation[] = [];

    if (facilityRes.status !== StatusCode.OK) {
      return;
    }

    for (let i = 0; i < facilityRes.data.length; i++) {
      const tempFacilityId = {
        id: facilityRes.data[i].id,
        value: facilityRes.data[i].id,
        label: facilityRes.data[i].long_name,
      };

      tempFacilityIdList.push(tempFacilityId);
    }

    setTicketState(prev => ({
      ...prev,
      facility_ids_list: tempFacilityIdList,
    }));
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setTicketState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleCheckboxChange(event: any) {
    const { id, checked, value } = event.target;
    const tempFacilityList = ticketState.valid_facilities.data;

    if (checked === true) {
      //Add id to list
      tempFacilityList.push(id);
    } else if (checked === false) {
      //Remove id from list
      const index = tempFacilityList.indexOf(id);
      if (index > -1) {
        tempFacilityList.splice(index, 1);
      }
    }

    setTicketState(prevState => ({
      ...prevState,
      valid_facilities: {
        data: tempFacilityList,
        isValid: tempFacilityList.length > 0 ? true : prevState?.valid_facilities?.isValid,
        isDirty: tempFacilityList.length > 0 ? true : prevState?.valid_facilities?.isDirty,
      },
    }));
  }

  async function saveTicket() {
    if (
      !ticketState.title.isValid ||
      !ticketState.included.isValid ||
      !ticketState.description.isValid ||
      !ticketState.expiry_type.isValid ||
      !ticketState.valid_facilities.isValid
    ) {
      setTicketState(prev => ({
        ...prev,
        title: { ...prev.title, isDirty: true },
        included: { ...prev.included, isDirty: true },
        description: { ...prev.description, isDirty: true },
        expiry_type: { ...prev.expiry_type, isDirty: true },
        valid_facilities: { ...prev.valid_facilities, isDirty: true },
      }));

      if (ticketState.expiry_type.data === "date") {
        if (!ticketState.expiry_date.isValid) {
          setTicketState(prev => ({
            ...prev,
            expiry_date: { ...prev.expiry_date, isDirty: true },
          }));
        }
      } else if (ticketState.expiry_type.data === "days") {
        if (!ticketState.expiry_days.isValid) {
          setTicketState(prev => ({
            ...prev,
            expiry_days: { ...prev.expiry_days, isDirty: true },
          }));
        }
      }

      return;
    }

    const stateToPass = {
      title: ticketState.title.data,
      id: ticketState.id,
      subtitle: ticketState.subtitle,
      included: ticketState.included.data,
      description: ticketState.description.data,
      value: ticketState.value,
      expiry_type: ticketState.expiry_type.data,
      expiry_date: ticketState.expiry_date.data,
      expiry_days: ticketState.expiry_days.data,
      valid_facilities: ticketState.valid_facilities.data,
      product_redemption: ticketState.product_redemption,
    };

    const ticketRes = await PostTicket(stateToPass, true);
    if (ticketRes.status !== StatusCode.OK) {
      return;
    }

    history.push("/admin/tickets");
    return;
  }

  function navigateToItem(url: string) {
    history.push(url);
  }

  function handleSelectChange(value: any, id: string) {
    setTicketState(prevState => ({ ...prevState, [id]: value }));
  }

  const handleDateSelectorChange = (selectedDate: Date) => {
    const tempDate = new Date(selectedDate).toISOString().slice(0, 10);
    // setTicketState((prevState) => ({ ...prevState, expiry_date: tempDate, selectedDate: selectedDate }));

    setTicketState(prevState => ({
      ...prevState,
      expiry_date: {
        ...prevState["expiry_date" as keyof ITicketState],
        data: tempDate,
        // isValid: true,
        // isDirty: true,

        isValid: tempDate ? true : prevState?.expiry_date?.isValid,
        isDirty: tempDate ? true : prevState?.expiry_date?.isDirty,
      },
      selectedDate: selectedDate,
    }));
  };

  const primaryAction = {
    content: "Create Ticket",
    action: saveTicket,
  };

  function requiredValueExists(value: string) {
    return value !== "" && value !== undefined;
  }

  function handleInputFieldChange(id: string, value: string, isValid: boolean) {
    if (id === undefined || value === undefined || isValid === undefined) {
      return;
    }

    setTicketState(prev => {
      return {
        ...prev,
        [id]: {
          ...(prev[id as keyof ITicketState] as Record<string, any>),
          data: value,
          isValid: isValid,
          isDirty: value ? true : prev?.[id as keyof ITicketState].isDirty,
        },
      };
    });
  }

  function handleDropDownChange(value: any, property: string) {
    if (value === "date") {
      const tempDate = new Date(ticketState.selectedDate).toISOString().slice(0, 10);

      setTicketState(prevState => ({
        ...prevState,
        expiry_date: {
          ...prevState["expiry_date" as keyof ITicketState],
          data: tempDate,
          isValid: value ? true : prevState?.expiry_type?.isValid,
          isDirty: value ? true : prevState?.expiry_type?.isDirty,
        },
      }));
    }

    setTicketState(prevState => ({
      ...prevState,
      [property]: {
        ...prevState[property as keyof ITicketState],
        data: value,
        isValid: value ? true : prevState?.expiry_type?.isValid,
        isDirty: value ? true : prevState?.expiry_type?.isDirty,
      },
    }));
  }

  function renderExpiry() {
    return [
      <Select
        key={1}
        label={t("secure.facility.product.ticket_new.015")}
        defaultValue={ticketState.expiry_type.data}
        onChange={(value: string) => handleDropDownChange(value, "expiry_type")}
        error={ticketState?.expiry_type?.isDirty && !ticketState?.expiry_type?.data}
      >
        <Option value="date">{t("secure.facility.product.ticket_new.016")}</Option>
        <Option value="days">{t("secure.facility.product.ticket_new.017")}</Option>
        <Option value="none">{t("secure.facility.product.ticket_new.018")}</Option>
      </Select>,
      <React.Fragment key={2}>
        {ticketState.expiry_type.data === "date" ? (
          <DatePickerInput
            label="Date"
            months={1}
            position="left"
            startingDate={ticketState.selectedDate}
            setStartingDate={handleDateSelectorChange}
            error={!ticketState.expiry_date.data}
          />
        ) : null}
      </React.Fragment>,
      <React.Fragment key={3}>
        {ticketState.expiry_type.data === "days" ? (
          <Input
            label={t("secure.facility.product.ticket_new.019")}
            id="expiry_days"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleInputFieldChange("expiry_days", event?.target?.value, requiredValueExists(event?.target?.value))
            }
            placeholder={t("secure.facility.product.ticket_new.020")}
            error={ticketState?.expiry_days?.isDirty && !ticketState.expiry_days.data}
          />
        ) : null}
      </React.Fragment>,
    ];
  }

  return (
    <Page
      title="Tickets"
      narrow
      breadcrumbs={[{ label: "Back to Tickets", url: "/admin/tickets" }]}
      primaryAction={primaryAction}
    >
      <Card>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Input
                value={ticketState.title?.data}
                label={t("secure.facility.product.ticket_new.003")}
                id="title"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputFieldChange("title", event?.target?.value, requiredValueExists(event?.target?.value))
                }
                placeholder={t("secure.facility.product.ticket_new.004")}
                error={ticketState.title.isDirty && !ticketState.title.data}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <Input
                value={ticketState.included.data || ""}
                label={t("secure.facility.product.ticket_new.005")}
                id="included"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputFieldChange("included", event?.target?.value, requiredValueExists(event?.target?.value))
                }
                placeholder={t("secure.facility.product.ticket_new.006")}
                helpText={t("secure.facility.product.ticket_new.007")}
                error={ticketState.included.isDirty && !ticketState.included.data}
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
        <Card.Section>
          <TextEditor
            label="Description"
            markdownText={ticketState.description.data ?? ""}
            markdownTextOnChange={value => handleInputFieldChange("description", value, requiredValueExists(value))}
            error={ticketState.description.isDirty && !ticketState.description.data}
          />
        </Card.Section>
        <Card.Section title="Expiry">
          <FormLayout>
            <FormLayout.Group>
              {windowSize.width <= TABLET_WIDTH ? (
                <div className="flex flex-column gap-3">{renderExpiry()}</div>
              ) : (
                renderExpiry()
              )}

              {/* <Select
                key={1}
                label={"Date"}
                defaultValue={ticketState.expiry_type}
                onChange={(value: string) => handleSelectChange(value, "expiry_type")}
              >
                <Option value="date">{"Date"}</Option>
                <Option value="days">{"Days"}</Option>
                <Option value="none">{"None"}</Option>
              </Select>

              <div>
                {ticketState.expiry_type.data === "days" ? (
                  <Input label={"Days"} id="expiry_days" onChange={handleInputChange} placeholder={"Days..."} />
                ) : null}

                {ticketState.expiry_type.data === "date" ? (
                  <div id="ticket-date-selector">
                    <DatePickerInput
                      label={"Date"}
                      months={1}
                      position="left"
                      startingDate={ticketState.selectedDate}
                      setStartingDate={handleDateSelectorChange}
                    />
                  </div>
                ) : null}
              </div> */}
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>

        <Card.Section title="Value">
          <FormLayout>
            <FormLayout.Group>
              <Input
                value={ticketState.value}
                label="Value"
                id="value"
                onChange={handleInputChange}
                placeholder="Value"
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>

        <Card.Section title="Facilities">
          <FormLayout>
            {ticketState.facility_ids_list.map((facility: any, index: number) => {
              return (
                <FormLayout.Group key={index}>
                  <Checkbox
                    id={facility.id}
                    size="small"
                    value={facility.value}
                    onChange={handleCheckboxChange}
                    label={facility.label}
                    error={ticketState.valid_facilities.data.length === 0 && ticketState.valid_facilities.isDirty}
                  />
                </FormLayout.Group>
              );
            })}
          </FormLayout>
        </Card.Section>
      </Card>
    </Page>
  );
};

export default Ticket;
