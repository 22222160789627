import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { ITournament } from "redux/reducers/models/tournament";

const apiVersion = "2022-09";
const adminType = "client-admin";
const baseTournamentUrl = "/" + apiVersion + "/" + adminType + "/tournament";

interface IGetTournament {
  id?: number;
  ids?: number[];
  facility_id?: number;
  year?: number;
  extended?: boolean;
  limit?: number;
  offset?: number;
  search?: string;
}

interface IPostTournament {
  name: string;
  start_time: string;
  date: string;
  tournament_type: string;
  facility_id: number;
}

// GET Tournament
// Retrieve a list of tournaments
export async function GetTournament(params: IGetTournament, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<ITournament[]>(baseTournamentUrl, params, useGlobalLoader, token);
}

// POST Tournament
// Create a new tournament
export async function PostTournament(params: IPostTournament, useGlobalLoader: boolean) {
  return await APIPost(baseTournamentUrl, params, useGlobalLoader);
}

// PUT Tournament
// Update an existing tournament
export async function PutTournament(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseTournamentUrl, params, useGlobalLoader);
}
