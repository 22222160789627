import React, { ReactElement, useEffect } from "react";
import { delay } from "components/_util/delay";
import ReactDOM from "react-dom";
import Icon from "components/icon/Icon";
import "./teeSheetStyle.scss";

interface IToastProps {
  subject: string;
  content: string;
  duration?: number;
  onDismiss?: () => void;
}

const InternalToast: React.FC<IToastProps> = props => {
  const { subject, content, duration, onDismiss } = props;
  useEffect(() => {
    async function dismissToast() {
      try {
        await delay(duration);
        onDismiss();
      } catch (e) {
        console.log(e);
      }
    }
    if (duration) {
      void dismissToast();
    }
  }, []);

  return (
    <div className="tee-sheet-toast">
      <div className="tee-sheet-toast_content-container">
        <div className="tee-sheet-toast-icon">
          <Icon style="fas" icon="bell" />
        </div>
        <div className="tee-sheet-toast_subject">{subject}</div>
        <button className="tee-sheet-toast_dismiss" onClick={onDismiss}>
          <Icon style="fas" icon="window-close" />
        </button>
      </div>
      <div className="tee-sheet-toast_content-container">
        <span className="tee-sheet-toast_content">{content}</span>
      </div>
    </div>
  );
};

interface IToastType extends React.FC<IToastProps> {
  show: (toastList: Array<{ subject: string; content: string; duration?: number; onClose?: () => void }>) => void;
}

const TeeSheetToast = InternalToast as IToastType;

TeeSheetToast.show = (
  toastList: Array<{ subject: string; content: string; duration?: number; onClose?: () => void }>,
) => {
  const root = document.getElementById("root");
  const existingToastManager = document.getElementsByClassName("tee-sheet-toast-list")[0];

  if (existingToastManager) {
    root.removeChild(existingToastManager);
  }

  const toastManager = document.createElement("div");
  toastManager.classList.add("tee-sheet-toast-list");
  root.appendChild(toastManager);

  const destroy = (toast: HTMLDivElement, onClose?: () => void) => () => {
    ReactDOM.unmountComponentAtNode(toast);

    const noToastsExist = Array.from(toastManager.children).every(child => child.innerHTML === "");

    if (noToastsExist) {
      root.removeChild(toastManager);
    }
    if (onClose) {
      onClose();
    }
  };

  toastList.forEach(toastData => {
    const toast = document.createElement("div");
    toastManager.appendChild(toast);
    ReactDOM.render(
      <TeeSheetToast
        subject={toastData.subject}
        content={toastData.content}
        duration={toastData.duration}
        onDismiss={destroy(toast, toastData.onClose)}
      />,
      toast,
    );
  });
};

export default TeeSheetToast;
