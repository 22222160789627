import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import moment from "moment";
import axios, { CancelToken } from "axios";

import { PutShotGunTemplateApply } from "api/rpc/2024-04/clientAdmin/teesheet/shotgun";
import { GetLeague } from "api/rpc/2024-04/clientAdmin/league/league";
import { GetTournament } from "api/rpc/2024-04/clientAdmin/tournament/tournament";
import { StatusCode } from "api/protocols";

import { showError, showSuccess } from "redux/actions/ui";

import { convertTime } from "helpers/Helpers";

import Sheet from "components/sheet/Sheet";
import FormLayout from "components/form/FormLayout";
import { Select } from "components/select/index";
import Checkbox from "components/form/checkbox/Checkbox";
import TimePick from "components/timePick/TimePick";
import Spin from "components/spin/spin";

import { ITeeTimeNew, IBookingCategory } from "redux/reducers/models/teetime";
import { ITournament } from "redux/reducers/models/tournament";
import { ILeague } from "redux/reducers/models/league";
import { IDivision } from "redux/reducers/models/facility";
import { TShotgunTemplate } from "redux/reducers/models/teesheet";

interface ICreateShotgunModal {
  open: boolean;
  teetime: ITeeTimeNew;
  divisions: IDivision[];
  bookingCategories: IBookingCategory[];
  templates: TShotgunTemplate[];
  onOk: () => void;
  onCancel: () => void;
}

interface IShotGunTemplateState {
  shotgun_template_id: number;
  tee_sheet_id: number;
  start_time: string;
  duration: string;
  lead_time: string;
  division_id: number;
  turn_division_id: number;
  tournament_id: number;
  league_id: number;
  tournaments: ITournament[];
  leagues: Array<ILeague>;
  tournamentQuery: string;
  leagueQuery: string;
  booking_category_ids: Array<number>;
}

export default function CreateShotgunModal(props: ICreateShotgunModal) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { clientTeeSheetStore, clientFacilityStore } = useAppSelector(store => store);

  const { open, teetime, divisions, bookingCategories, templates, onOk, onCancel } = props;

  const [state, setState] = useState<IShotGunTemplateState>({
    shotgun_template_id: undefined,
    tee_sheet_id: teetime?.slots[0]?.tee_sheet_id,
    start_time: teetime?.start_time,
    duration: "",
    lead_time: "",
    division_id: undefined,
    turn_division_id: undefined,
    tournament_id: undefined,
    league_id: undefined,
    tournaments: [],
    leagues: [],
    tournamentQuery: "",
    leagueQuery: "",
    booking_category_ids: [],
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadShotgunTournaments(source.token);
    return () => source.cancel();
  }, [state.tournamentQuery]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadShotgunLeagues(source.token);
    return () => source.cancel();
  }, [state.leagueQuery]);

  function updateShotgunTemplateState(newShotgunTemplateState: Partial<IShotGunTemplateState>) {
    setState((cur: IShotGunTemplateState) => {
      return { ...cur, ...newShotgunTemplateState };
    });
  }

  async function loadShotgunTournaments(token?: CancelToken) {
    const date = clientTeeSheetStore?.selectedDate as Date;

    const tournamentRes = await GetTournament(
      {
        search: state.tournamentQuery,
        year: !state.tournamentQuery ? date?.getFullYear() ?? new Date()?.getFullYear() : undefined,
      },
      false,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (tournamentRes.status !== StatusCode.OK) {
      return;
    }

    updateShotgunTemplateState({ tournaments: tournamentRes.data });
  }

  async function loadShotgunLeagues(token?: CancelToken) {
    const date = clientTeeSheetStore?.selectedDate as Date;

    const leagueRes = await GetLeague(
      {
        search: state.leagueQuery,
        year: !state.tournamentQuery ? date?.getFullYear() ?? new Date()?.getFullYear() : undefined,
      },
      false,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (leagueRes.status !== StatusCode.OK) {
      return;
    }
    updateShotgunTemplateState({ leagues: leagueRes.data });
  }

  async function applyShotgunTemplate() {
    const durationTime = state.duration;
    const durationSplit = durationTime.split(":");
    const durationMins = +durationSplit[0] * 60 + +durationSplit[1];
    const leadTime = state.lead_time;
    const leadTimeSplit = leadTime.split(":");
    const leadTimeMins = +leadTimeSplit[0] * 60 + +leadTimeSplit[1];

    const params = {
      facility_id: clientFacilityStore.facility?.id,
      tee_sheet_id: state.tee_sheet_id,
      start_time: state.start_time,
      duration: durationMins,
      lead_time: leadTimeMins,
      shotgun_template_id: state.shotgun_template_id,
      division_id: state.division_id,
      turn_division_id: state.turn_division_id,
      tournament_id: state.tournament_id,
      league_id: state.league_id,
      booking_category_ids: state.booking_category_ids,
    };

    const applyShotgunTemplateRes = await PutShotGunTemplateApply(params, true);
    if (applyShotgunTemplateRes.status !== StatusCode.OK) {
      dispatch(showError(t("elements.tee_sheet.tee_sheet_tabs.052")));
      return;
    }

    dispatch(showSuccess(t("elements.tee_sheet.tee_sheet_tabs.053")));

    updateShotgunTemplateState({
      tee_sheet_id: null,
      start_time: "",
      duration: "",
      lead_time: "",
      shotgun_template_id: null,
      division_id: null,
      turn_division_id: null,
      tournament_id: undefined,
      league_id: undefined,
    });

    void onOk();
  }

  function handleShotgunDropdownChange(value: number, property: string) {
    if ((property === "tournament_id" || property === "league_id") && value === -1) {
      value = undefined;
    }
    updateShotgunTemplateState({ [property]: value });
  }

  function handleShotgunTimePicker(value: string, property: string) {
    updateShotgunTemplateState({ [property]: value });
  }

  function handleBookingCategoryChange(event: any) {
    const value = parseInt(event?.target?.value);
    if (isNaN(value)) {
      return;
    }

    if (state.booking_category_ids.find(bookingCategoryId => bookingCategoryId === value) === undefined) {
      setState(prevState => ({
        ...prevState,
        booking_category_ids: [...prevState.booking_category_ids, value],
      }));
    } else {
      const updatedBookingCategoryIds = state.booking_category_ids.filter(
        bookingCategoryId => bookingCategoryId !== value,
      );
      setState(prevState => ({ ...prevState, booking_category_ids: updatedBookingCategoryIds }));
    }
  }

  return (
    <Sheet
      open={open}
      size="small"
      closable
      title={`${t("elements.tee_sheet.tee_sheet_tabs.112")} ${teetime ? convertTime(teetime?.start_time) : null}`}
      onCancel={() => onCancel()}
      onOk={() => applyShotgunTemplate()}
      cancelText={t("elements.tee_sheet.tee_sheet_tabs.113")}
      okText={t("elements.tee_sheet.tee_sheet_tabs.114")}
      okDisabled={
        state.division_id === undefined ||
        state.duration === "" ||
        state.lead_time === "" ||
        state.shotgun_template_id === undefined ||
        state.start_time === "" ||
        state.tee_sheet_id === null ||
        state.turn_division_id === undefined
      }
      overflow
    >
      <p className="teetime-modal-details-options mb-4">{"Create Shotgun"}</p>
      <FormLayout>
        <FormLayout.Group>
          <Select
            onChange={(value: number) => handleShotgunDropdownChange(value, "shotgun_template_id")}
            label={t("elements.tee_sheet.tee_sheet_tabs.009")}
          >
            {templates?.map((template, index: number) => {
              return (
                <Select.Option key={index} value={template.id} name={template.title}>
                  <span>{template.title}</span>
                </Select.Option>
              );
            })}
          </Select>
          <div>
            <div className="rc-select-dropdown-label">
              <label>{"Booking Categories" /* TODO: Translation */}</label>
            </div>
            {bookingCategories ? (
              <div>
                {bookingCategories.map((bookingCategory, index: number) => {
                  return (
                    <Checkbox
                      key={index}
                      size="small"
                      onChange={handleBookingCategoryChange}
                      value={bookingCategory.id}
                      checked={
                        state.booking_category_ids.find(
                          bookingCategoryId => bookingCategoryId === bookingCategory.id,
                        ) !== undefined
                      }
                      label={bookingCategory.title}
                      circle
                    />
                  );
                })}
              </div>
            ) : (
              <div style={{ height: "32px" }}>
                <Spin />
              </div>
            )}
          </div>
        </FormLayout.Group>
        {divisions && (
          <FormLayout.Group>
            <TimePick
              value={state.start_time}
              onChange={timeString => updateShotgunTemplateState({ start_time: timeString })}
              label={t("elements.tee_sheet.tee_sheet_tabs.008")}
              size="large"
            />
            <Select
              onChange={(value: number) => handleShotgunDropdownChange(value, "division_id")}
              label={t("elements.tee_sheet.tee_sheet_tabs.010")}
            >
              {divisions?.map((division: any, index: number) => {
                return (
                  <Select.Option key={index} value={division.id} name={division.title}>
                    <span>{division.title}</span>
                  </Select.Option>
                );
              })}
            </Select>

            <Select
              onChange={(value: number) => handleShotgunDropdownChange(value, "turn_division_id")}
              label={t("elements.tee_sheet.tee_sheet_tabs.011")}
            >
              {divisions?.map((division: any, index: number) => {
                return (
                  <Select.Option key={index} value={division.id} name={division.title}>
                    <span>{division.title}</span>
                  </Select.Option>
                );
              })}
            </Select>
          </FormLayout.Group>
        )}
        <FormLayout.Group>
          <Select
            showSearch
            showDropDownOnFocus={true}
            placeholder={t("elements.tee_sheet.tee_sheet_tabs.115")}
            onSearch={(query: string) => setState(prevState => ({ ...prevState, tournamentQuery: query }))}
            onChange={(value: number) => handleShotgunDropdownChange(value, "tournament_id")}
            label={t("elements.tee_sheet.tee_sheet_tabs.012")}
            disabled={state.league_id !== undefined}
          >
            <Select.Option value={-1} key={-1} name={"None"}>
              <div>{t("elements.tee_sheet.tee_sheet_tabs.116")}</div>
            </Select.Option>

            {state.tournaments
              ?.filter(tournament => !moment(tournament.date).isBefore(moment(), "day"))
              .map((tournament: any, index: number) => {
                return (
                  <Select.Option key={index} value={tournament.id} name={tournament.name}>
                    <div className="flex items-center">
                      <span>{tournament.name}</span>
                      <span className="shotgun-tournament-date">{moment(tournament.date).format("LL")}</span>
                    </div>
                  </Select.Option>
                );
              })}

            <Select.Option disabled value={-2} key={-2} name={""}>
              <div>
                <hr style={{ margin: "0px" }} />
              </div>
            </Select.Option>

            {state.tournaments
              ?.filter(tournament => moment(tournament.date).isBefore(moment(), "day"))
              .map((tournament: any, index: number) => {
                return (
                  <Select.Option key={index} value={tournament.id} name={tournament.name}>
                    <div className="flex items-center">
                      <span>{tournament.name}</span>
                      <span className="shotgun-tournament-date">{moment(tournament.date).format("LL")}</span>
                    </div>
                  </Select.Option>
                );
              })}
          </Select>
          <Select
            showSearch
            showDropDownOnFocus={true}
            placeholder={t("elements.tee_sheet.tee_sheet_tabs.117")}
            onSearch={(query: string) => setState(prevState => ({ ...prevState, leagueQuery: query }))}
            onChange={(value: number) => handleShotgunDropdownChange(value, "league_id")}
            label={t("elements.tee_sheet.tee_sheet_tabs.013")}
            disabled={state.tournament_id !== undefined}
          >
            <Select.Option value={-1} key={-1} name={"None"}>
              <div>{t("elements.tee_sheet.tee_sheet_tabs.116")}</div>
            </Select.Option>
            {state.leagues?.map((league: any, index: number) => {
              return (
                <Select.Option key={index} value={league.id} name={league.name}>
                  <span>{league.name}</span>
                </Select.Option>
              );
            })}
          </Select>
        </FormLayout.Group>
        <FormLayout.Group>
          <div className="flex flex-col">
            <TimePick
              value={state.duration}
              onChange={(timeString: string) => handleShotgunTimePicker(timeString, "duration")}
              label={t("elements.tee_sheet.tee_sheet_tabs.007")}
              disableClock
              hideMeridiem={12}
            />
          </div>
          <div className="flex flex-col">
            <TimePick
              value={state.lead_time}
              onChange={(timeString: string) => handleShotgunTimePicker(timeString, "lead_time")}
              label={t("elements.tee_sheet.tee_sheet_tabs.006")}
              disableClock
              hideMeridiem={12}
            />
          </div>
        </FormLayout.Group>
      </FormLayout>
    </Sheet>
  );
}
