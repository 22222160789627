import React, { useEffect, useRef, useState } from "react";
import axios, { CancelToken } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StatusCode } from "api/protocols";
import "./clientPortalBooking.scss";
import "../Home/clientPortalHome.scss";
import OrderSummaryLineItem from "components/bookingEngine/orderSummaryLineItem/OrderSummaryLineItem";
import { useHistory, useParams } from "react-router";
import { UserActive } from "api/rpc";
import { dequeue, enqueue, IUIActions, showError, showSuccess } from "redux/actions/ui";
import Card from "components/card/Card";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Radio from "components/radio";
import BookingTimer from "components/bookingEngine/bookingTimer/bookingTimer";
import { ButtonNew as Button } from "components/buttonNew";
import Checkbox from "components/form/checkbox/Checkbox";
import ReactDOM from "react-dom";
import { Select } from "components/select/index";
import Sheet from "components/sheet/Sheet";
import CardSection from "components/cardSection/CardSection";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import Markdown from "components/markdown/Markdown";
import { LocaleCurrency } from "helpers/Locale";
import Spin from "components/spin/spin";
import { IBookingParticipant } from "redux/reducers/models/teetime";
import GolferCard from "components/bookingPopUp/golferCard/GolferCard";
import { useTranslation } from "react-i18next";
import Popup from "components/popup/Popup";
import validator from "validator";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { ClientPortalNavigation } from "../ClientPortalNavigation";
import { clientPortalUpdate } from "redux/actions/clientPortal";
import moment from "moment";
import { PostCustomerBooking } from "api/rpc/2024-04/booking";
import { GetCart, PutCart } from "api/rpc/2024-04/cart";
import {
  GetCustomerFriends,
  GetCustomerPaymentMethods,
  ICustomerFriend,
  PostFriends,
  SearchCustomer,
} from "api/rpc/2024-04/customer/customer";
import { ITaxLine } from "components/OrderSummary/OrderSummary";
import { IOrder } from "pages/guest/models/order";
import { useClientPortal } from "../useClientPortal";
import { PostPaymentMethod, PostSetup } from "api/rpc/2024-04/customer/paymentMethod";
import { GetLeagueParticipants } from "api/rpc/2024-04/customer/league";
import { DeleteLockTeeTime } from "api/rpc/2024-04/customer/teetime";
import { PostCart, PostCheckout, PostLineItemToCart } from "api/rpc/2024-04/guest/cart";
import { PostTransaction, PutCapture } from "api/rpc/2024-04/guest/transaction";
import { PutCompleteOrder } from "api/rpc/guest/order";
import Panel from "components/panel/Panel";
import { MOBILE_WIDTH } from "helpers/ScreenSizes";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import ClientPortalBookingCard from "./ClientPortalBookingCard";

export interface IClientPortalBookingState {
  holeAmount: number;
  golferAmount: number;
  powerCartAmount: number;
  teeTimeLockExpire: string;
  customerCreditCards: Array<Record<string, any>>;
  customerGiftCards: Array<Record<string, any>>;
  customerRainChecks: Array<Record<string, any>>;
  selectedCardId: number;
  selectedCard: Record<string, any>;
  acceptTerms: boolean;
  newCardActive: boolean;
  saveCard: boolean;
  paymentTermsActive: boolean;
  elementComplete: {
    cardNumber: boolean;
    cardExpiry: boolean;
    cardCvc: boolean;
  };
  cart: Record<string, any>;
  loadPutCart: boolean;

  bookingParticipants: Array<Partial<IBookingParticipant>>;
  player2: string;
  player3: string;
  player4: string;
  friendsSearchResult2: Array<ICustomerFriend>;
  friendsSearchResult3: Array<ICustomerFriend>;
  friendsSearchResult4: Array<ICustomerFriend>;
  newPlayerQuery: string;
  selectedNewPlayer: ICustomerFriend;
  addPlayerModalVisible: boolean;
  searching: boolean;
  customerSearchResult: Array<ICustomerFriend>;
  friendsList: Array<ICustomerFriend>;
  leagueParticipantList: Array<Record<string, any>>;
  disableSingleBooking: boolean;
  [key: string]: any;
}

export interface ITeeTimeState {
  activeUser: boolean;
}

interface IParams {
  clientShortName: string;
}

interface IPrepaidWarning {
  title: string;
  description: string;
  open: boolean;
}

export enum PaymentStep {
  post_cart = 1,
  post_line_item = 2,
  post_checkout = 3,
  post_transaction = 4,
  confirm_stripe_payment = 5,
  put_capture = 6,
  put_order_complete = 7,
  post_booking = 8,
}

export interface IClientPortalPaymentState {
  step: PaymentStep;
  cartToken: string;
  order: IOrder;
  transactionId: number;
  clientSecret: string;
  paymentIntent: Record<string, any>;
}

interface IAddFriendState {
  friendsToAdd: Array<Partial<IBookingParticipant>>;
  modalVisible: boolean;
  selectedPlayerIds: Array<number>;
}

export default function ClientPortalBooking(props: any) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { clientShortName } = useParams<IParams>();
  const clientPortalStore = useAppSelector(store => store.clientPortalStore);
  const dispatch = useAppDispatch();
  const { Option } = Select;
  const loadedPaymentMethods = useRef(false);
  const HOME_URL = useClientPortal().getHomePageUrl();
  const windowSize = useWindowSize();

  const stripe = useStripe();
  const elements = useElements();

  const [activeUser, setActiveUser] = useState(false);
  const [holeButtons, setHoleButtons] = useState([true, true]);
  const [loadingComplete, setLoadingComplete] = useState(false);

  const { getSingleBookings } = useClientPortal();

  const [addFriendState, setAddFriendState] = useState<IAddFriendState>({
    friendsToAdd: [],
    modalVisible: false,
    selectedPlayerIds: [],
  });

  const [prepaidWarning, setPrepaidWarning] = useState<IPrepaidWarning>({
    title: "",
    description: "",
    open: false,
  });

  const [bookingState, setBookingState] = useState<IClientPortalBookingState>({
    holeAmount: 18,
    golferAmount: 1,
    powerCartAmount: 0,
    teeTimeLockExpire: "",
    customerCreditCards: [],
    customerGiftCards: [],
    customerRainChecks: [],
    selectedCardId: undefined,
    selectedCard: undefined,
    acceptTerms: false,
    newCardActive: false,
    saveCard: false,
    paymentTermsActive: false,
    elementComplete: {
      cardNumber: false,
      cardExpiry: false,
      cardCvc: false,
    },
    cart: null,
    loadPutCart: false,
    bookingParticipants: [],
    player2: "",
    player3: "",
    player4: "",
    friendsSearchResult2: [],
    friendsSearchResult3: [],
    friendsSearchResult4: [],
    newPlayerQuery: "",
    selectedNewPlayer: null,
    addPlayerModalVisible: false,
    searching: false,
    customerSearchResult: [],
    friendsList: [],
    leagueParticipantList: [],
    disableSingleBooking: false,
  });

  const [paymentState, setPaymentState] = useState<IClientPortalPaymentState>({
    step: PaymentStep.post_cart,
    cartToken: null,
    order: null,
    transactionId: null,
    clientSecret: null,
    paymentIntent: null,
  });

  useEffect(() => {
    if (clientPortalStore?.user == null && clientPortalStore?.selectedTeeTime == null) {
      //Page was refreshed, return to home page
      void returnToHomePage(true);
    } else {
      void getCustomerLoggedIn();
      void getCart();
      void disableHoles();
      //Get the customer's payment methods
      if (
        clientPortalStore?.selectedTeeTime?.credit_card_required ||
        clientPortalStore?.selectedTeeTime?.pre_paid_required
      ) {
        void getCustomerPaymentMethods(clientPortalStore?.client?.id);
        loadedPaymentMethods.current = true;
      }

      //Get league participants if selected time is a league time, else get friends list
      if (clientPortalStore?.selectedTeeTime?.league_id) {
        void getLeagueParticipants();
      } else {
        void getFriendsList();
      }
      setBookingState(prevState => ({
        ...prevState,
        disableSingleBooking: getSingleBookings(
          clientPortalStore?.teeSheetSettings?.single_bookings,
          clientPortalStore?.selectedTeeTime?.quantity_remaining,
        ).disable,
        golferAmount: getSingleBookings(
          clientPortalStore?.teeSheetSettings?.single_bookings,
          clientPortalStore?.selectedTeeTime?.quantity_remaining,
        ).golferAmount,
        powerCartAmount: clientPortalStore?.selectedTeeTime?.cart_rule === "required" ? 1 : 0,
        teeTimeLockExpire: clientPortalStore?.selectedTeeTimeLock?.expireTime,
        bookingParticipants: [
          { customer_id: clientPortalStore?.user?.id, full_name: clientPortalStore?.user?.full_name },
        ],
      }));
      window.scrollTo({ top: 0, left: 0 });
    }
  }, []);

  useEffect(() => {
    if (!loadedPaymentMethods.current && bookingState?.cart?.total_price_due) {
      void getCustomerPaymentMethods(clientPortalStore?.client?.id);
      loadedPaymentMethods.current = true;
    }
  }, [bookingState?.cart?.total_price_due]);

  useEffect(() => {
    if (clientPortalStore?.selectedTeeTime) {
      void disableHoles();
    }
  }, [bookingState.golferAmount, clientPortalStore?.selectedTeeTime]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    async function updateCart() {
      updateBookingState({ loadPutCart: true });
      const customerIds = [
        ...bookingState?.bookingParticipants?.map(participant => participant?.customer_id),
        ...new Array(Math.abs(bookingState.golferAmount - bookingState?.bookingParticipants?.length)).fill(
          clientPortalStore?.user?.id,
        ),
      ];
      const putCartRes = await PutCart(
        {
          cart_token: localStorage.getItem(
            `${clientShortName}_${clientPortalStore?.selectedFacility?.short_name}_booking_engine_cart_token`,
          ),
          tee_time_id: clientPortalStore?.selectedTeeTime?.id,
          holes: bookingState.holeAmount,
          quantity: bookingState.golferAmount,
          power_cart_quantity: bookingState.powerCartAmount,
          customer_ids: customerIds,
        },
        false,
        source.token,
      )
        .then(data => {
          if (data.data === "Cancelled") {
            updateBookingState({ cart: null, loadPutCart: false });
          } else {
            updateBookingState({ cart: data.data, loadPutCart: false });
          }
        })
        .catch(err => {
          dispatch(showError(err.message));
          updateBookingState({ cart: null, loadPutCart: false });
          return;
        });
    }
    // Update the cart line items on load & when a selection is made
    if (
      localStorage.getItem(
        `${clientShortName}_${clientPortalStore?.selectedFacility?.short_name}_booking_engine_cart_token`,
      ) &&
      bookingState.holeAmount &&
      bookingState.golferAmount &&
      bookingState.powerCartAmount != null &&
      clientPortalStore?.selectedTeeTime &&
      loadingComplete
    ) {
      void updateCart();
    }

    return () => {
      //Cancel request (sets the returned data to 'Cancelled' when request is cancelled)"
      source.cancel("Cancelled");
    };
  }, [
    bookingState.holeAmount,
    bookingState.golferAmount,
    bookingState.powerCartAmount,
    loadingComplete,
    localStorage.getItem(
      `${clientShortName}_${clientPortalStore?.selectedFacility?.short_name}_booking_engine_cart_token`,
    ),
    bookingState?.bookingParticipants,
  ]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = setTimeout(() => {
        void search(bookingState.newPlayerQuery, "customerSearchResult", source.token);
      }, 500);
    }
    return () => {
      source.cancel("Cancelled");
      mounted = false;
      clearTimeout(timeoutId);
      setBookingState(prevState => ({ ...prevState, customerSearchResult: [] }));
    };
  }, [bookingState.newPlayerQuery]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = setTimeout(() => {
        void search(bookingState.player2, "friendsSearchResult2", source.token);
      }, 500);
    }
    return () => {
      source.cancel("Cancelled");
      mounted = false;
      clearTimeout(timeoutId);
      setBookingState(prevState => ({ ...prevState, friendsSearchResult2: [] }));
    };
  }, [bookingState.player2]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = setTimeout(() => {
        void search(bookingState.player3, "friendsSearchResult3", source.token);
      }, 500);
    }
    return () => {
      source.cancel("Cancelled");
      mounted = false;
      clearTimeout(timeoutId);
      setBookingState(prevState => ({ ...prevState, friendsSearchResult3: [] }));
    };
  }, [bookingState.player3]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = setTimeout(() => {
        void search(bookingState.player4, "friendsSearchResult4", source.token);
      }, 500);
    }
    return () => {
      source.cancel("Cancelled");
      mounted = false;
      clearTimeout(timeoutId);
      setBookingState(prevState => ({ ...prevState, friendsSearchResult4: [] }));
    };
  }, [bookingState.player4]);

  function updateBookingState(newBookingState: Partial<IClientPortalBookingState>) {
    setBookingState(cur => {
      return { ...cur, ...newBookingState };
    });
  }

  // Check if card inputs are filled
  const elementsComplete =
    bookingState.elementComplete.cardNumber === true &&
    bookingState.elementComplete.cardExpiry === true &&
    bookingState.elementComplete.cardCvc === true;

  const orderSummaryObjects = [
    {
      lineItem: "Date",
      itemValue: moment(clientPortalStore?.selectedTeeTime?.date).format("LL"),
    },
    {
      lineItem: "Time",
      itemValue: moment(clientPortalStore?.selectedTeeTime?.start_time, "hh:mm").format("LT"),
    },
    {
      lineItem: "No. Holes",
      itemValue: bookingState.holeAmount,
    },
    {
      lineItem: "No. Golfers",
      itemValue: bookingState.golferAmount,
    },
    {
      lineItem: "No. Carts",
      itemValue: bookingState.powerCartAmount,
    },
  ];

  const search = async (playerSearchQuery: string, playerSearchResult: string, cancelToken: CancelToken) => {
    try {
      if (playerSearchQuery === "") {
        setBookingState(prevState => ({ ...prevState, [playerSearchResult]: [] }));
        return;
      } else {
        setBookingState(prevState => ({ ...prevState, searching: true }));
        let friendsResults: any;
        if (playerSearchResult === "customerSearchResult") {
          friendsResults = await searchCustomers(cancelToken);
        } else {
          friendsResults = await searchFriends(playerSearchQuery, cancelToken);
        }
        setBookingState(prevState => ({ ...prevState, searching: false, [playerSearchResult]: friendsResults }));
      }
    } catch (error) {
      console.log("err", error);
    }
    return;
  };

  async function getFriendsList() {
    const friendsListRes = await GetCustomerFriends(null, true);
    if (friendsListRes.status !== StatusCode.OK) {
      dispatch(showError("Error getting friends list"));
      return;
    }
    setBookingState(prevState => ({ ...prevState, friendsList: friendsListRes.data }));
  }

  async function getLeagueParticipants() {
    const leagueParticipantRes = await GetLeagueParticipants(
      { league_id: clientPortalStore?.selectedTeeTime?.league_id },
      true,
    );
    if (leagueParticipantRes?.status !== StatusCode.OK) {
      dispatch(showError("Error getting league participants"));
      return;
    }

    const filteredLeagueParticipants = leagueParticipantRes?.data?.filter(
      (participant: Record<string, any>) => participant?.user_id !== clientPortalStore?.user?.id,
    );
    setBookingState(prevState => ({ ...prevState, leagueParticipantList: filteredLeagueParticipants }));
  }

  async function searchCustomers(cancelToken: CancelToken) {
    let searchCustomersRes;
    const phoneNumberFormats = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(bookingState?.newPlayerQuery);
    if (
      validator.isEmail(bookingState?.newPlayerQuery) ||
      validator.isMobilePhone(bookingState?.newPlayerQuery) ||
      validator.isNumeric(bookingState?.newPlayerQuery) ||
      phoneNumberFormats
    ) {
      searchCustomersRes = await SearchCustomer(
        { search: bookingState.newPlayerQuery, client_id: clientPortalStore?.client?.id },
        false,
        cancelToken,
      );
    } else {
      searchCustomersRes = await SearchCustomer(
        { member_search: bookingState.newPlayerQuery, client_id: clientPortalStore?.client?.id },
        false,
        cancelToken,
      );
      searchCustomersRes.data = searchCustomersRes?.data?.filter(
        (customer: Record<string, any>) => !customer?.customer_type[0]?.default,
      );
    }

    if (searchCustomersRes.status !== StatusCode.OK) {
      if (searchCustomersRes.message !== "Cancelled") {
        dispatch(showError("Error getting customers"));
      }
      return [];
    }
    return searchCustomersRes.data as Array<ICustomerFriend>;
  }

  async function searchFriends(playerSearchQuery: string, cancelToken: CancelToken) {
    const searchFriendsRes = await GetCustomerFriends({ search: playerSearchQuery }, false, cancelToken);
    if (searchFriendsRes.status !== StatusCode.OK) {
      if (searchFriendsRes.message !== "Cancelled") {
        dispatch(showError("Error getting friends list"));
      }
      return [];
    }
    return searchFriendsRes.data;
  }

  async function getCart() {
    //Create a new cart if there is no customer cart token in local storage
    if (
      !localStorage.getItem(
        `${clientShortName}_${clientPortalStore?.selectedFacility?.short_name}_booking_engine_cart_token`,
      )
    ) {
      const getCartRes = await GetCart(
        {
          facility_id: clientPortalStore?.selectedFacility?.id,
          token: localStorage.getItem(
            `${clientShortName}_${clientPortalStore?.selectedFacility?.short_name}_booking_engine_cart_token`,
          ),
        },
        true,
      );
      if (getCartRes.status !== StatusCode.OK) {
        dispatch(showError("Error getting cart"));
        return;
      }

      //Set new customer cart token
      localStorage.setItem(
        `${clientShortName}_${clientPortalStore?.selectedFacility?.short_name}_booking_engine_cart_token`,
        getCartRes.data.token,
      );

      updateBookingState({ cart: getCartRes.data });
    }
  }

  async function getCustomerLoggedIn() {
    // Check if user is logged in
    if (clientPortalStore?.user == null) {
      dispatch(enqueue());
      const activeUserRes = await UserActive(false);
      dispatch(dequeue());
      if (activeUserRes.status === StatusCode.OK) {
        ReactDOM.unstable_batchedUpdates(() => {
          setActiveUser(true);
          dispatch(clientPortalUpdate({ user: activeUserRes?.data }));
          updateBookingState({
            customerFirstName: activeUserRes.data.first_name,
            customerLastName: activeUserRes.data.last_name,
            customerEmail: activeUserRes.data.email,
            customerPhone: activeUserRes.data.phone,
          });
        });
      } else {
        //User is not logged in, send them back to the home page
        setActiveUser(false);
        dispatch(showError("User is not logged in. Please log in and try again."));
        void returnToHomePage(false);
      }
    } else {
      setActiveUser(true);
    }
  }

  function handleHoleChange(holeAmount: number) {
    updateBookingState({ holeAmount });
  }

  function handleGolferAmountChange(golferAmount: number) {
    if (golferAmount < bookingState.golferAmount) {
      void handleRemoveGolfers(golferAmount);
    }

    updateBookingState({
      golferAmount: golferAmount,
    });
  }

  function handleCartChange(cartAmount: number) {
    updateBookingState({ powerCartAmount: cartAmount });
  }

  function handleRemoveGolfers(newQuantity: number) {
    const updatedBookingParticipants = [...bookingState.bookingParticipants].filter(
      (participant, index) => index + 1 <= newQuantity,
    );

    setBookingState(prevState => ({
      ...prevState,
      bookingParticipants: updatedBookingParticipants,
      player2: "",
      player3: "",
      player4: "",
    }));
  }

  async function returnToHomePage(deleteLock: boolean) {
    if (deleteLock) {
      const deleteLockTeeTimeRes = await DeleteLockTeeTime(true);

      if (deleteLockTeeTimeRes.status === StatusCode.OK) {
        dispatch(clientPortalUpdate({ selectedTeeTimeLock: null }));
      }
    }
    dispatch(clientPortalUpdate({ selectedTeeTime: null, postedBooking: null }));
    history.push(HOME_URL);
  }

  async function getCustomerPaymentMethods(clientId: number) {
    const customerPaymentMethodsRes = await GetCustomerPaymentMethods(
      { client_id: clientId, type: ["card", "gift_card", "rain_check"] },
      true,
    );
    if (customerPaymentMethodsRes.status !== StatusCode.OK) {
      dispatch(showError("Error getting customer payment methods"));
      return;
    }
    updateBookingState({
      customerCreditCards: customerPaymentMethodsRes.data?.card,
      customerGiftCards: customerPaymentMethodsRes.data?.gift_card,
      customerRainChecks: customerPaymentMethodsRes.data?.rain_check,
      selectedCardId:
        customerPaymentMethodsRes.data?.card?.length >= 1 ? customerPaymentMethodsRes.data?.card[0]?.id : undefined,
      selectedCard:
        customerPaymentMethodsRes.data?.card?.length >= 1 ? customerPaymentMethodsRes.data?.card[0] : undefined,
    });
  }

  function handleCardDropDownChange(value: number, id: string, card: Record<string, any>) {
    setBookingState(prevState => ({ ...prevState, [id]: value, selectedCard: card ? card : prevState.selectedCard }));
  }

  function handleCheckboxChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, checked } = e.target;
    updateBookingState({ [id]: checked });
  }

  function handleAddPlayerCheckbox(participantId: number) {
    const addedPlayerIds = [...addFriendState?.selectedPlayerIds];
    const foundIndex = addedPlayerIds?.indexOf(participantId);
    if (foundIndex === -1) {
      addedPlayerIds?.push(participantId);
    } else {
      addedPlayerIds?.splice(foundIndex, 1);
    }
    setAddFriendState(prevState => ({ ...prevState, selectedPlayerIds: addedPlayerIds }));
  }

  function disableCheckout() {
    //User hasn't accepted the payment terms and conditions
    if (!bookingState.acceptTerms) {
      return true;
    }
    // # of holes, # of players or # of power carts has not been selected
    if (!bookingState.holeAmount || !bookingState.golferAmount || bookingState.powerCartAmount == null) {
      return true;
    }

    // Cart is loading
    if (bookingState.loadPutCart || bookingState.cart === null) {
      return true;
    }

    //Credit card has not been selected
    if (clientPortalStore?.selectedTeeTime?.credit_card_required) {
      if (bookingState.selectedCardId == null) {
        return true;
      }
    }

    //Credit card has not been selected (payment required)
    if (clientPortalStore?.selectedTeeTime?.pre_paid_required || bookingState?.cart?.total_price_due) {
      if (bookingState.selectedCardId == null) {
        return true;
      }
    }

    return false;
  }

  function disableHoles() {
    const tempHoleButtons = [true, true];
    // Disabling holes
    clientPortalStore?.selectedTeeTime?.divisions_available?.forEach(division => {
      // 9 holes available
      if (division === 1) {
        tempHoleButtons[1] = false;
      } else if (division === 2) {
        if (
          (clientPortalStore?.selectedTeeTime?.divisions_available?.length > 1 &&
            clientPortalStore?.selectedTeeTime?.turn_tee_time === null) ||
          clientPortalStore?.selectedTeeTime?.turn_tee_time?.blocked_type === "blocked" ||
          ((clientPortalStore?.selectedTeeTime?.turn_tee_time?.league_id ||
            clientPortalStore?.selectedTeeTime?.turn_tee_time?.tournament_id) &&
            (clientPortalStore?.selectedTeeTime?.league_id !==
              clientPortalStore?.selectedTeeTime?.turn_tee_time?.league_id ||
              clientPortalStore?.selectedTeeTime?.tournament_id !==
                clientPortalStore?.selectedTeeTime?.turn_tee_time?.tournament_id))
        ) {
          // Only 9 holes available
          tempHoleButtons[1] = false;
        } else if (clientPortalStore?.selectedTeeTime?.turn_tee_time != null) {
          // 18 holes available
          if (
            clientPortalStore?.selectedTeeTime?.quantity_remaining >= bookingState.golferAmount &&
            clientPortalStore?.selectedTeeTime?.turn_tee_time?.quantity_remaining >= bookingState.golferAmount
          ) {
            tempHoleButtons[0] = false;
          } else {
            // Only 9 holes available
            tempHoleButtons[1] = false;
          }
        }
      }
    });

    if (tempHoleButtons[0] && tempHoleButtons[1]) {
      updateBookingState({ holeAmount: null });
    } else if (tempHoleButtons[0]) {
      updateBookingState({ holeAmount: 9 });
    } else if (tempHoleButtons[1]) {
      updateBookingState({ holeAmount: 18 });
    }
    setHoleButtons(tempHoleButtons);
    if (!loadingComplete) {
      setLoadingComplete(true);
    }
  }

  function openNewCardModal() {
    updateBookingState({ newCardActive: true });
  }

  function handleCardSectionChange(
    e: StripeCardNumberElementChangeEvent | StripeCardExpiryElementChangeEvent | StripeCardCvcElementChangeEvent,
  ) {
    updateBookingState({
      elementComplete: { ...bookingState.elementComplete, [e.elementType]: e.complete },
    });
  }

  async function handleAddNewCard() {
    if (!stripe || !elements) {
      dispatch(showError("Error occurred before card could save. Please reattempt."));
      return;
    }
    const tempCreditCards = [...bookingState.customerCreditCards];
    dispatch(enqueue());
    try {
      const stripePaymentMethodRes = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement("cardNumber"),
      });

      if (stripePaymentMethodRes.paymentMethod) {
        const setupRes = await PostSetup(false);

        if (setupRes.status !== StatusCode.OK) {
          dispatch(showError("Error setting up card"));
        }
        if (stripePaymentMethodRes.paymentMethod.id !== "undefined" && setupRes.status === StatusCode.OK) {
          const stripeConfirmCardRes = await stripe.confirmCardSetup(setupRes.data.setup_intent.client_secret, {
            payment_method: stripePaymentMethodRes.paymentMethod.id,
          });
          if (stripeConfirmCardRes.error) {
            dispatch(showError(stripeConfirmCardRes.error.message));
          } else {
            const postPaymentRes = await PostPaymentMethod(
              {
                payment_method_id: stripeConfirmCardRes.setupIntent.payment_method,
                save_card: bookingState.saveCard,
                facility_id: clientPortalStore?.selectedFacility?.id,
              },
              false,
            );
            if (postPaymentRes.status !== StatusCode.OK) {
              dispatch(showError("Error adding card"));
            } else {
              tempCreditCards.push(postPaymentRes.data);
              updateBookingState({
                customerCreditCards: tempCreditCards,
                selectedCardId: postPaymentRes.data.id,
                selectedCard: postPaymentRes?.data,
              });
              void closeAddNewCard();
              dispatch(showSuccess("Successfully added card"));
            }
          }
        }
      } else {
        dispatch(showError(stripePaymentMethodRes.error.message));
      }
    } catch (e) {
      dispatch(dequeue());
    }
    dispatch(dequeue());
  }

  function closeAddNewCard() {
    elements.getElement(CardNumberElement).clear();
    elements.getElement(CardExpiryElement).clear();
    elements.getElement(CardCvcElement).clear();
    updateBookingState({ newCardActive: false });
  }

  async function handleCreateBooking() {
    let order = paymentState?.order;
    let currentCartToken = paymentState?.cartToken;
    let clientSecret = paymentState?.clientSecret;
    let transactionId = paymentState?.transactionId;
    let stripePaymentIntent = paymentState?.paymentIntent;

    if (bookingState?.cart?.total_price_due && paymentState?.step < PaymentStep.post_booking) {
      dispatch(enqueue());
      //Create payment cart
      setPrepaidWarning(prevState => ({ ...prevState, open: false }));
      if (paymentState.step <= PaymentStep.post_line_item) {
        const cartRes = await PostCart(
          {
            sales_channel: "booking_engine",
            facility_id: clientPortalStore?.selectedFacility?.id,
            customer_id: clientPortalStore?.user?.id,
          },
          false,
        );
        if (cartRes.status !== StatusCode.OK) {
          setPaymentState(prevState => ({ ...prevState, step: PaymentStep.post_cart }));
          dispatch(showError("Error creating cart"));
          dispatch(dequeue());
          return;
        }

        currentCartToken = cartRes?.data?.token;
      }
      if (paymentState.step <= PaymentStep.post_line_item) {
        //Add prepaid line items to the new cart
        for (const lineItem of bookingState?.cart?.line_items) {
          if (lineItem?.pre_paid_required) {
            const postLineItemRes = await PostLineItemToCart(
              { variant_id: lineItem?.variant_id, cart_token: currentCartToken, quantity: lineItem?.quantity },
              false,
            );
            if (postLineItemRes?.status !== StatusCode.OK) {
              setPaymentState(prevState => ({
                ...prevState,
                step: PaymentStep.post_line_item,
                cartToken: currentCartToken,
              }));
              dispatch(showError("Error adding line item to cart"));
              dispatch(dequeue());
              return;
            }
          }
        }
      }

      if (paymentState?.step <= PaymentStep.post_checkout) {
        const postCheckoutRes = await PostCheckout({ cart_token: currentCartToken }, false);
        if (postCheckoutRes.status !== StatusCode.OK) {
          setPaymentState(prevState => ({
            ...prevState,
            step: PaymentStep.post_checkout,
            cartToken: currentCartToken,
          }));
          dispatch(showError("Checkout error"));
          dispatch(dequeue());
          return;
        }
        order = postCheckoutRes.data?.order;

        if (!order?.token) {
          setPaymentState(prevState => ({
            ...prevState,
            step: PaymentStep.post_checkout,
            cartToken: currentCartToken,
          }));
          dispatch(showError("Order does not exist"));
          dispatch(dequeue());
          return;
        }
      }

      if (paymentState.step <= PaymentStep.post_transaction) {
        //Process transaction
        const postTransactionRes = await PostTransaction(
          {
            processing_type: "online",
            order_token: order.token,
            kind: "authorization",
            source: "online",
            amount: order.total_price,
            payment_method: "card",
          },
          false,
        );

        clientSecret = postTransactionRes?.data?.client_secret;
        transactionId = postTransactionRes?.data?.id;

        if (postTransactionRes.status !== StatusCode.OK || !clientSecret) {
          setPaymentState(prevState => ({
            ...prevState,
            step: PaymentStep.post_transaction,
            cartToken: currentCartToken,
            order: order,
          }));
          dispatch(showError("Error creating transaction"));
          dispatch(dequeue());
          return;
        }
      }

      if (!stripe || !elements) {
        setPaymentState(prevState => ({
          ...prevState,
          step: PaymentStep.confirm_stripe_payment,
          cartToken: currentCartToken,
          order: order,
          clientSecret: clientSecret,
          transactionId: transactionId,
        }));
        dispatch(showError("Payment error. Cannot confirm card payments"));
        dispatch(dequeue());
        return;
      }

      if (paymentState?.step <= PaymentStep.confirm_stripe_payment) {
        //Confrim stripe card payment
        const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
          payment_method: bookingState?.selectedCard?.stripe_payment_method_id,
        });

        if (stripeError) {
          setPaymentState(prevState => ({
            ...prevState,
            step: PaymentStep.confirm_stripe_payment,
            cartToken: currentCartToken,
            order: order,
            clientSecret: clientSecret,
            transactionId: transactionId,
          }));
          dispatch(showError(stripeError.message));
          dispatch(dequeue());
          return;
        }
        stripePaymentIntent = paymentIntent;
      }
      if (paymentState?.step <= PaymentStep.put_capture) {
        //Capture card payment
        const putCaptureRes = await PutCapture(
          { payment_intent_id: stripePaymentIntent?.id, transaction_id: transactionId },
          false,
        );

        if (putCaptureRes.status !== StatusCode.OK) {
          setPaymentState(prevState => ({
            ...prevState,
            step: PaymentStep.put_capture,
            cartToken: currentCartToken,
            order: order,
            clientSecret: clientSecret,
            transactionId: transactionId,
            paymentIntent: stripePaymentIntent,
          }));
          dispatch(showError("Card capture error"));
          dispatch(dequeue());
          return;
        }
      }

      if (paymentState.step <= PaymentStep.put_order_complete) {
        //Complete Order
        const putCompleteOrderRes = await PutCompleteOrder({ token: order.token }, false);

        if (putCompleteOrderRes.status !== StatusCode.OK) {
          setPaymentState(prevState => ({
            ...prevState,
            step: PaymentStep.put_order_complete,
            cartToken: currentCartToken,
            order: order,
            clientSecret: clientSecret,
            transactionId: transactionId,
            paymentIntent: stripePaymentIntent,
          }));
          dispatch(showError("Error completing order"));
          dispatch(dequeue());
          return;
        }
      }
      dispatch(dequeue());
    }

    setPaymentState(prevState => ({
      ...prevState,
      step: PaymentStep.post_booking,
      cartToken: currentCartToken,
      order: order,
      clientSecret: clientSecret,
      transactionId: transactionId,
      paymentIntent: stripePaymentIntent,
    }));

    await postCustomerBooking(order);
  }

  async function postCustomerBooking(order: IOrder) {
    if (
      !bookingState.acceptTerms ||
      !bookingState.holeAmount ||
      !bookingState.golferAmount ||
      bookingState.powerCartAmount == null
    ) {
      console.log("Booking error, missing holes, players or power cart amount. User may not have accepted terms.");
      return;
    }

    if (clientPortalStore?.selectedTeeTime?.credit_card_required && bookingState.selectedCardId == null) {
      console.log("Booking error, missing payment information.");
      return;
    }

    const lockAllOptions = order && bookingState?.cart?.pre_paid_required ? true : false;
    const lockQuantity =
      order && !bookingState?.cart?.pre_paid_required && bookingState?.cart?.booking_fee_required ? true : false;

    const customerIds = setCustomerIds();

    const params = {
      tee_time_id: clientPortalStore?.selectedTeeTime?.id,
      holes: bookingState.holeAmount,
      quantity: bookingState.golferAmount,
      power_cart_quantity: bookingState.powerCartAmount,
      customer_payment_method_id: bookingState.selectedCardId,
      accept_payment_terms: bookingState.acceptTerms,
      cart_token: bookingState.cart?.token,
      order_id: order && bookingState?.cart?.pre_paid_required ? order?.id : undefined,
      booking_fee_order_id: order && bookingState?.cart?.booking_fee_required ? order?.id : undefined,
      lock_quantity: lockAllOptions || lockQuantity,
      lock_holes: lockAllOptions,
      lock_power_cart_quantity: lockAllOptions,
      customer_ids: customerIds,
      waitlist_reference_id: clientPortalStore?.waitlist_reference_id ?? undefined,
    };

    const bookingRes = await PostCustomerBooking(params, true);
    if (bookingRes.status !== StatusCode.OK) {
      dispatch(showError("Error creating booking"));
      return;
    }

    dispatch(
      clientPortalUpdate({
        postedBooking: { ...bookingRes.data, paymentMethodId: bookingState.selectedCardId ?? null },
        selectedTeeTimeLock: null,
        waitlist_reference_id: null,
      }),
    );

    const bookingToken = bookingRes.data?.token as string;

    localStorage.removeItem(
      `${clientShortName}_${clientPortalStore?.selectedFacility?.short_name}_booking_engine_cart_token`,
    );
    const address = `/portal/${clientShortName}/confirm?token=${bookingToken}&facility=${clientPortalStore?.selectedFacility?.short_name}`;
    history.push(address);
  }

  function setCustomerIds() {
    const customerIds: number[] = bookingState?.bookingParticipants?.map(participant => participant?.customer_id);

    const playerDifference = bookingState.golferAmount - customerIds.length;
    // Fill guest slots
    if (customerIds.length < bookingState.golferAmount) {
      for (let i = 0; i < playerDifference; i++) {
        customerIds.push(clientPortalStore?.user?.id);
      }
    }
    return customerIds;
  }

  async function checkFriendsToAdd() {
    //Skip adding friends for leagues
    if (!clientPortalStore?.selectedTeeTime?.league_id) {
      //Display modal to add playing partners that are not in the user's friends list
      const newPlayingPartners = bookingState?.bookingParticipants?.filter(
        participant =>
          participant?.customer_id !== clientPortalStore?.user?.id &&
          !bookingState?.friendsList?.some(friend => friend?.id === participant?.id),
      );
      if (newPlayingPartners?.length > 0) {
        const addedPlayers = newPlayingPartners?.map(partner => partner?.customer_id);
        setAddFriendState(prevState => ({
          ...prevState,
          friendsToAdd: newPlayingPartners,
          modalVisible: true,
          selectedPlayerIds: addedPlayers,
        }));
        return;
      }
    }
    //Make booking if there are no users in the booking that are outside of the user's friends list
    await handleCreateBooking();
  }

  async function handleAddFriends() {
    const usersToAdd = [...addFriendState?.selectedPlayerIds];
    if (usersToAdd?.length > 0) {
      const addRes = await PostFriends({ friend_user_ids: usersToAdd }, true);
      if (addRes?.status !== StatusCode.OK) {
        dispatch(showError("Error adding playing partners"));
      } else {
        dispatch(showSuccess("Successfully added playing partners"));
      }
    }
    await handleCreateBooking();
  }

  function removeGolferFromBooking(index: number) {
    const updatedBookingParticipants = [...bookingState.bookingParticipants];
    updatedBookingParticipants.splice(index, 1);
    ReactDOM.unstable_batchedUpdates(() => {
      setBookingState(prevState => ({ ...prevState, bookingParticipants: updatedBookingParticipants }));
      setBookingState(prevState => ({ ...prevState, player2: "", player3: "", player4: "" }));
    });
  }

  const handleCustomerSearch = (query: string, name: string) => {
    setBookingState(prevState => ({ ...prevState, [name]: query }));
  };

  function handleCustomerSelection(id: number, friend: any) {
    const updatedBookingParticipants = [...bookingState.bookingParticipants];
    updatedBookingParticipants.push({ ...friend, customer_id: friend?.id });
    setBookingState(prevState => ({
      ...prevState,
      bookingParticipants: updatedBookingParticipants,
      player2: "",
      player3: "",
      player4: "",
      selectedNewPlayer: null,
      addPlayerModalVisible: false,
      newPlayerQuery: "",
      customerSearchResult: [],
      friendsSearchResult2: [],
      friendsSearchResult3: [],
      friendsSearchResult4: [],
    }));
  }

  function handleLeagueParticipantSelection(id: number, participant: Record<string, any>) {
    const updatedBookingParticipants = [...bookingState.bookingParticipants];
    updatedBookingParticipants.push({
      ...participant,
      customer_id: participant?.user_id,
      full_name: participant?.customer?.full_name,
    });
    setBookingState(prevState => ({
      ...prevState,
      bookingParticipants: updatedBookingParticipants,
      leagueParticipantQuery_2: "",
      leagueParticipantQuery_3: "",
      leagueParticipantQuery_4: "",
    }));
  }

  function displayAddPlayerModal() {
    setBookingState(prevState => ({
      ...prevState,
      addPlayerModalVisible: !prevState.addPlayerModalVisible,
      selectedNewPlayer: null,
      newPlayerQuery: "",
      customerSearchResult: [],
    }));
  }

  function renderFriendsList(playerSearchResult: string) {
    //Display the search results if the array contains values
    if (bookingState[playerSearchResult]?.length > 0) {
      return bookingState[playerSearchResult] as Array<ICustomerFriend>;
    } else {
      //Else return the entire friends list
      return bookingState.friendsList;
    }
  }

  function handleNewPlayerSelection(id: string, friend: any) {
    setBookingState(prevState => ({ ...prevState, selectedNewPlayer: friend }));
  }

  function removeNewPlayerSelection() {
    setBookingState(prevState => ({ ...prevState, selectedNewPlayer: null, newPlayerQuery: "" }));
  }

  function displayBookingFeeWarning() {
    setPrepaidWarning(prevState => ({
      ...prevState,
      open: true,
      title: "Booking Fee Charge",
      description:
        "This tee time has non refundable booking fees that will be charged to your selected credit card. Do you wish to continue with this booking?",
    }));
  }

  function displayPrePaidWarning() {
    setPrepaidWarning(prevState => ({
      ...prevState,
      open: true,
      title: "Pre Paid Charge",
      description: "This tee time is pre paid and your selected credit card will be charged. Do you wish to continue?",
    }));
  }

  function searchLeagueParticipant(searchString: string, index: number) {
    setBookingState(prevState => ({ ...prevState, [`leagueParticipantQuery_${index + 1}`]: searchString }));
  }

  function displayTerms(e: React.MouseEvent) {
    e.stopPropagation();
    e.preventDefault();
    updateBookingState({ paymentTermsActive: true });
  }

  const bookingFeePaid =
    (paymentState?.step >= PaymentStep.put_order_complete &&
      paymentState?.step <= PaymentStep.post_booking &&
      bookingState?.cart?.total_price_due) ||
    !!paymentState?.order;

  return (
    activeUser &&
    loadingComplete && (
      <>
        <div className="client-portal-booking-background">
          <ClientPortalNavigation />
          <div className="client-portal-booking-timer-container">
            <div className="client-portal-booking-timer-facility-container">
              <span className="client-portal-booking-timer-course-logo">
                {clientPortalStore?.selectedFacility?.logo_source && (
                  <img
                    className="client-portal-booking-timer-course-logo"
                    src={clientPortalStore?.selectedFacility?.logo_source}
                    alt={"Facility Logo"}
                  />
                )}
              </span>
              <div className="flex flex-col gap-1">
                <p className="client-portal-booking-timer-course-name">
                  {clientPortalStore?.selectedFacility?.long_name}
                </p>
                {clientPortalStore?.selectedFacility && (
                  <p className="client-portal-booking-timer-address">{`${clientPortalStore?.selectedFacility?.address_line_1}, ${clientPortalStore?.selectedFacility?.city}, ${clientPortalStore?.selectedFacility?.province_name}, ${clientPortalStore?.selectedFacility?.postal}`}</p>
                )}
              </div>
            </div>
            <div className="client-portal-booking-timer-duration-container">
              <FontAwesomeIcon
                className="client-portal-booking-timer-icon"
                size="1x"
                color="#999999"
                icon={["far", "clock"]}
              />
              <span className="client-portal-booking-timer-address">{"Time left to complete booking:"}</span>
              <span className="client-portal-booking-timer-duration-countdown">
                <BookingTimer
                  expireTime={clientPortalStore?.selectedTeeTimeLock?.expireTime}
                  teeTimeExpiryUrl={HOME_URL}
                  active={true}
                />
              </span>
            </div>
          </div>
          <div className="client-portal-booking-main-container">
            <div className="client-portal-booking-main-container-left-side">
              <Card>
                <Card.Section>
                  <p className="client-portal-booking-booking-details-selection-start-time">
                    {moment(clientPortalStore?.selectedTeeTime?.start_time, "hh:mm:ss").format("h:mm A")}
                  </p>
                  <p className="client-portal-booking-booking-details-selection-date">
                    {clientPortalStore?.selectedTeeTime?.formattedDate}
                  </p>

                  <div className="client-portal-booking-booking-details-selection-container">
                    <div className="client-portal-booking-booking-details-selection-button-group">
                      <p className="client-portal-booking-booking-details-selection-title">{"Holes"}</p>
                      <Radio.Group name="number_of_holes" onChange={handleHoleChange} value={bookingState.holeAmount}>
                        <Radio.Button value={18} disabled={holeButtons[0] || bookingFeePaid}>
                          <span className="mr-2">18</span>
                          <FontAwesomeIcon size="1x" icon={["far", "golf-flag-hole"]} />
                        </Radio.Button>
                        <Radio.Button value={9} disabled={holeButtons[1] || bookingFeePaid}>
                          <span className="mr-2">9</span>
                          <FontAwesomeIcon size="1x" icon={["far", "golf-flag-hole"]} />
                        </Radio.Button>
                      </Radio.Group>
                    </div>

                    <div className="client-portal-booking-booking-details-selection-button-group">
                      <p className="client-portal-booking-booking-details-selection-title">{"Players"}</p>
                      <Radio.Group
                        name="number_of_golfers"
                        onChange={handleGolferAmountChange}
                        value={bookingState.golferAmount}
                      >
                        <Radio.Button value={1} disabled={bookingState.disableSingleBooking || bookingFeePaid}>
                          {1}
                        </Radio.Button>
                        <Radio.Button
                          value={2}
                          disabled={
                            clientPortalStore?.selectedTeeTime?.quantity_remaining < 2 || bookingFeePaid ? true : false
                          }
                        >
                          {2}
                        </Radio.Button>
                        <Radio.Button
                          value={3}
                          disabled={
                            clientPortalStore?.selectedTeeTime?.quantity_remaining < 3 || bookingFeePaid ? true : false
                          }
                        >
                          {3}
                        </Radio.Button>
                        <Radio.Button
                          value={4}
                          disabled={
                            clientPortalStore?.selectedTeeTime?.quantity_remaining < 4 || bookingFeePaid ? true : false
                          }
                        >
                          {4}
                        </Radio.Button>
                      </Radio.Group>
                    </div>
                  </div>
                </Card.Section>
              </Card>

              <Card>
                {clientPortalStore?.teeSheetSettings?.enable_cart_bookings ? (
                  <div>
                    {(clientPortalStore?.selectedTeeTime?.cart_rule === "optional" ||
                      clientPortalStore?.selectedTeeTime?.cart_rule === "required") && (
                      <Card.Section>
                        <div className="client-portal-booking-booking-details-selection-container">
                          <div
                            style={{ width: "100%" }}
                            className="client-portal-booking-booking-details-selection-button-group"
                          >
                            <p className="client-portal-booking-booking-details-selection-title">{"Carts"}</p>
                            <Radio.Group
                              name="number_of_carts"
                              onChange={handleCartChange}
                              value={bookingState.powerCartAmount}
                            >
                              <Radio.Button
                                value={0}
                                disabled={
                                  clientPortalStore?.selectedTeeTime?.cart_rule === "required" || bookingFeePaid
                                    ? true
                                    : false
                                }
                              >
                                {"Walking"}
                              </Radio.Button>
                              <Radio.Button disabled={bookingFeePaid} value={1}>
                                {"1 Cart"}
                              </Radio.Button>
                              <Radio.Button disabled={bookingFeePaid} value={2}>
                                {"2 Carts"}
                              </Radio.Button>
                            </Radio.Group>
                          </div>
                        </div>
                      </Card.Section>
                    )}
                  </div>
                ) : null}

                <Card.Section>
                  <p className="client-portal-booking-booking-details-selection-title">{"Golfers"}</p>
                  {[...Array(clientPortalStore?.selectedTeeTime?.quantity_remaining)]?.map((num, index) => {
                    if (bookingState.bookingParticipants[index]) {
                      return (
                        <GolferCard
                          bookingEngineCard={true}
                          closable={index > 0 && !bookingFeePaid}
                          removeGolfer={() => removeGolferFromBooking(index)}
                          name={bookingState.bookingParticipants[index]?.full_name}
                          key={index}
                        />
                      );
                    } else if (index + 1 <= bookingState?.golferAmount) {
                      const searchResultKey = "friendsSearchResult".concat((index + 1).toString());
                      return (
                        <div key={index} className="new-player-sheet">
                          <Select
                            showSearch
                            onSearch={(query: string) =>
                              clientPortalStore?.selectedTeeTime?.league_id
                                ? searchLeagueParticipant(query, index)
                                : handleCustomerSearch(query, "player".concat((index + 1).toString()))
                            }
                            onChange={(id: number, friendObject: ICustomerFriend) =>
                              clientPortalStore?.selectedTeeTime?.league_id
                                ? handleLeagueParticipantSelection(id, friendObject)
                                : handleCustomerSelection(id, friendObject)
                            }
                            allowClear
                            searchValue={
                              clientPortalStore?.selectedTeeTime?.league_id
                                ? bookingState[`leagueParticipantQuery_${index + 1}`]
                                : bookingState["player".concat((index + 1).toString())]
                            }
                            showDropDownOnFocus={!clientPortalStore?.selectedTeeTime?.league_id ? true : false}
                            searching={bookingState.searching}
                            placeholder={"Guest"}
                            disabled={index + 1 <= bookingState.golferAmount && !bookingFeePaid ? false : true}
                          >
                            {!clientPortalStore?.selectedTeeTime?.league_id && (
                              <div className="ui-select-dropdown-list-item" onClick={displayAddPlayerModal}>
                                {"Add New Playing Partner"}
                              </div>
                            )}
                            {clientPortalStore?.selectedTeeTime?.league_id
                              ? bookingState?.leagueParticipantList
                                  ?.filter(filteredParticipant =>
                                    filteredParticipant?.customer?.full_name
                                      ?.toLowerCase()
                                      .includes(bookingState[`leagueParticipantQuery_${index + 1}`]?.toLowerCase()),
                                  )
                                  ?.map((participant, index) => (
                                    <Option key={index} value={participant.user_id} extraValues={participant}>
                                      <span>{participant?.customer?.full_name}</span>
                                    </Option>
                                  ))
                              : renderFriendsList(searchResultKey)?.map((friend: ICustomerFriend, index: number) => {
                                  return (
                                    <Option key={index} value={friend.id} extraValues={friend}>
                                      <span>{friend.full_name}</span>
                                    </Option>
                                  );
                                })}
                          </Select>
                        </div>
                      );
                    }
                  })}
                </Card.Section>
              </Card>

              {windowSize.width > MOBILE_WIDTH && (
                <ClientPortalBookingCard
                  bookingState={bookingState}
                  paymentState={paymentState}
                  handleCardDropDownChange={handleCardDropDownChange}
                  openNewCardModal={openNewCardModal}
                  handleCheckboxChange={handleCheckboxChange}
                  returnToHomePage={returnToHomePage}
                  disableCheckout={disableCheckout}
                  displayPrePaidWarning={displayPrePaidWarning}
                  displayBookingFeeWarning={displayBookingFeeWarning}
                  checkFriendsToAdd={checkFriendsToAdd}
                  displayTerms={displayTerms}
                  bookingFeePaid={bookingFeePaid}
                />
              )}
            </div>
            <div className="client-portal-booking-main-container-right-side">
              <div className="client-portal-booking-order-summary">
                <Card>
                  <Card.Section>
                    <p className="client-portal-booking-booking-details-selection-title">{"Order Summary"}</p>
                    {orderSummaryObjects?.map((summaryObject, index) => {
                      return (
                        <OrderSummaryLineItem
                          key={index}
                          lineItem={summaryObject.lineItem}
                          itemValue={summaryObject.itemValue}
                        />
                      );
                    })}
                    <br />
                    {bookingState.loadPutCart || bookingState.cart === null ? (
                      <div className="h-8 mt-2 flex justify-center">
                        <Spin className="self-center" />
                      </div>
                    ) : (
                      <>
                        <p>{"Items"}</p>
                        {bookingState.cart?.line_items?.map((lineItem: Record<string, any>, index: number) => {
                          return (
                            <OrderSummaryLineItem
                              key={index}
                              lineItemQuantity={lineItem.quantity}
                              lineItem={lineItem.product_title}
                              lineItemVariant={lineItem.variant_title}
                              prepaid={lineItem.pre_paid_required}
                              itemValue={
                                <LocaleCurrency
                                  currency={bookingState.cart?.currency}
                                  amount={lineItem.subtotal_price}
                                />
                              }
                            />
                          );
                        })}
                        <br />

                        <OrderSummaryLineItem
                          lineItem={"Subtotal"}
                          itemValue={
                            <LocaleCurrency
                              currency={bookingState.cart?.currency}
                              amount={bookingState.cart?.subtotal_price}
                            />
                          }
                        />

                        {bookingState?.cart?.tax_lines?.map((taxLine: ITaxLine, index: number) => {
                          return (
                            <OrderSummaryLineItem
                              key={index}
                              lineItem={taxLine?.title ?? "Tax"}
                              itemSubtitle={taxLine?.rate ? `${100 * taxLine?.rate}%` : undefined}
                              itemValue={
                                <LocaleCurrency currency={bookingState.cart?.currency} amount={taxLine?.price} />
                              }
                            />
                          );
                        })}

                        <OrderSummaryLineItem
                          lineItem={"Total"}
                          itemValue={
                            <LocaleCurrency
                              currency={bookingState.cart?.currency}
                              amount={bookingState.cart?.total_price}
                            />
                          }
                        />

                        <br />
                        <p className="client-portal-booking-order-summary-total-text">{"Due at Course"}</p>
                        <p className="client-portal-booking-order-summary-total-price">
                          <LocaleCurrency
                            currency={bookingState.cart?.currency}
                            amount={
                              bookingState?.cart?.total_price_due
                                ? bookingState.cart?.total_price - bookingState.cart?.total_price_due
                                : bookingState.cart?.total_price
                            }
                          />
                        </p>
                        {bookingState?.cart?.total_price_due ? (
                          <>
                            <br />
                            <p className="client-portal-booking-order-summary-total-text">{"Total Due Now"}</p>
                            <p className="client-portal-booking-order-summary-total-price">
                              <LocaleCurrency
                                currency={bookingState.cart?.currency}
                                amount={bookingState.cart?.total_price_due}
                              />
                            </p>
                          </>
                        ) : null}
                      </>
                    )}
                  </Card.Section>
                  {clientPortalStore?.teeSheetSettings?.booking_terms && windowSize.width > MOBILE_WIDTH && (
                    <Card.Section title={"Booking Terms"}>
                      <Markdown markdownText={clientPortalStore?.teeSheetSettings?.booking_terms} />
                    </Card.Section>
                  )}
                </Card>
                {windowSize.width <= MOBILE_WIDTH && (
                  <ClientPortalBookingCard
                    bookingState={bookingState}
                    paymentState={paymentState}
                    handleCardDropDownChange={handleCardDropDownChange}
                    openNewCardModal={openNewCardModal}
                    handleCheckboxChange={handleCheckboxChange}
                    returnToHomePage={returnToHomePage}
                    disableCheckout={disableCheckout}
                    displayPrePaidWarning={displayPrePaidWarning}
                    displayBookingFeeWarning={displayBookingFeeWarning}
                    checkFriendsToAdd={checkFriendsToAdd}
                    displayTerms={displayTerms}
                    bookingFeePaid={bookingFeePaid}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Add new credit card modal */}

        <Panel
          open={bookingState.newCardActive && windowSize.width < MOBILE_WIDTH}
          onClose={closeAddNewCard}
          title={"Add New Credit Card"}
          animate
          primaryAction={{
            onClick: handleAddNewCard,
            label: "Add Card",
            disabled: !elementsComplete,
          }}
          secondaryAction={{
            onClick: closeAddNewCard,
            label: "Cancel",
          }}
        >
          <div>
            <Form>
              <FormLayout>
                <FormLayout.Group>
                  {windowSize.width < MOBILE_WIDTH && <CardSection onChange={handleCardSectionChange} type="one-row" />}
                </FormLayout.Group>
                <FormLayout.Group>
                  <Checkbox
                    id="saveCard"
                    size="small"
                    onChange={handleCheckboxChange}
                    label={"Save card for future bookings"}
                  />
                </FormLayout.Group>
              </FormLayout>
            </Form>
          </div>
        </Panel>

        <Sheet
          size="small"
          height="flexible"
          open={bookingState.newCardActive && windowSize.width > MOBILE_WIDTH}
          closable
          title={"Add New Credit Card"}
          cancelText={"Cancel"}
          okText={"Add Card"}
          onOk={handleAddNewCard}
          okDisabled={!elementsComplete ? true : false}
          onCancel={closeAddNewCard}
          backDropCancel={false}
        >
          <div>
            <Form>
              <FormLayout>
                <FormLayout.Group>
                  {windowSize.width > MOBILE_WIDTH && <CardSection onChange={handleCardSectionChange} type="one-row" />}
                </FormLayout.Group>
                <FormLayout.Group>
                  <Checkbox
                    id="saveCard"
                    size="small"
                    onChange={handleCheckboxChange}
                    label={"Save card for future bookings"}
                  />
                </FormLayout.Group>
              </FormLayout>
            </Form>
          </div>
        </Sheet>

        {/* Payment Terms Modal */}
        <Sheet
          size="small"
          height="flexible"
          open={bookingState.paymentTermsActive && windowSize.width > MOBILE_WIDTH}
          closable
          title={"Payment Terms and Conditions"}
          cancelText={"Close"}
          okText={"Accept"}
          onOk={() => updateBookingState({ paymentTermsActive: false, acceptTerms: true })}
          onCancel={() => updateBookingState({ paymentTermsActive: false })}
          backDropCancel={false}
        >
          <Markdown markdownText={clientPortalStore?.teeSheetSettings?.payment_terms} />
        </Sheet>

        <Panel
          open={bookingState.paymentTermsActive && windowSize.width <= MOBILE_WIDTH}
          onClose={() => updateBookingState({ paymentTermsActive: false })}
          title={`${clientPortalStore?.selectedFacility?.long_name} Terms and Conditions`}
          animate
          primaryAction={{
            onClick: () => updateBookingState({ paymentTermsActive: false, acceptTerms: true }),
            label: "Accept",
          }}
          secondaryAction={{
            onClick: () => updateBookingState({ paymentTermsActive: false }),
            label: "Close",
          }}
        >
          <Markdown markdownText={clientPortalStore?.teeSheetSettings?.payment_terms} />
          <br />
          <Markdown markdownText={clientPortalStore?.teeSheetSettings?.booking_terms} />
        </Panel>

        {/* Add playing partner Modal */}
        <Panel
          open={bookingState.addPlayerModalVisible && windowSize.width < MOBILE_WIDTH}
          onClose={displayAddPlayerModal}
          title={"Add Playing Partners"}
          animate
          primaryAction={{
            onClick: () => handleCustomerSelection(bookingState.selectedNewPlayer?.id, bookingState.selectedNewPlayer),
            label: "Add Player",
            disabled: bookingState.selectedNewPlayer ? false : true,
          }}
          secondaryAction={{
            onClick: displayAddPlayerModal,
            label: "Cancel",
          }}
        >
          {bookingState.selectedNewPlayer ? (
            <GolferCard
              closable
              removeGolfer={() => removeNewPlayerSelection()}
              name={bookingState.selectedNewPlayer?.full_name}
              email={bookingState.selectedNewPlayer?.email}
              bookingEngineCard={true}
            />
          ) : (
            <>
              <div className="client-portal-booking-search-player-label-container">
                <p className="text-sm text-subdued">Search for public players using an email or phone number</p>
                <p className="text-sm text-subdued">Search for members using their name, email or phone number</p>
              </div>
              <Select
                showSearch
                onSearch={(query: string) => handleCustomerSearch(query, "newPlayerQuery")}
                onChange={(id: string, friendObject: ICustomerFriend) => handleNewPlayerSelection(id, friendObject)}
                allowClear
                searchValue={bookingState.newPlayerQuery}
                searching={bookingState.searching}
                placeholder={"Search..."}
                noData={
                  <div className="no-players-found">
                    <FontAwesomeIcon icon={["fas", "users-slash"]} fixedWidth size="1x" />
                    <span>{"No player found.."}</span>
                  </div>
                }
              >
                {bookingState.customerSearchResult?.map((friend, index) => {
                  return (
                    <Option key={index} value={friend.id} extraValues={friend} name={friend?.full_name}>
                      <span>
                        {friend.full_name} - {friend?.customer_type[0]?.title}
                      </span>
                    </Option>
                  );
                })}
              </Select>
            </>
          )}
        </Panel>

        <Sheet
          open={bookingState.addPlayerModalVisible && windowSize.width > MOBILE_WIDTH}
          size="small"
          height="flexible"
          closable
          title={"Add New Playing Partner"}
          onCancel={displayAddPlayerModal}
          onOk={() => handleCustomerSelection(bookingState.selectedNewPlayer?.id, bookingState.selectedNewPlayer)}
          cancelText={"Cancel"}
          okText={"Add Player"}
          okDisabled={bookingState.selectedNewPlayer ? false : true}
          overflow
        >
          {bookingState.selectedNewPlayer ? (
            <GolferCard
              closable
              removeGolfer={() => removeNewPlayerSelection()}
              name={bookingState.selectedNewPlayer?.full_name}
              email={bookingState.selectedNewPlayer?.email}
              bookingEngineCard={true}
            />
          ) : (
            <>
              <div className="client-portal-booking-search-player-label-container">
                <p className="text-sm text-subdued">Search for public players using an email or phone number</p>
                <p className="text-sm text-subdued">Search for members using their name, email or phone number</p>
              </div>
              <Select
                showSearch
                onSearch={(query: string) => handleCustomerSearch(query, "newPlayerQuery")}
                onChange={(id: string, friendObject: ICustomerFriend) => handleNewPlayerSelection(id, friendObject)}
                allowClear
                searchValue={bookingState.newPlayerQuery}
                searching={bookingState.searching}
                placeholder={"Search..."}
                noData={
                  <div className="no-players-found">
                    <FontAwesomeIcon icon={["fas", "users-slash"]} fixedWidth size="1x" />
                    <span>{"No player found.."}</span>
                  </div>
                }
              >
                {bookingState.customerSearchResult?.map((friend, index) => {
                  return (
                    <Option key={index} value={friend.id} extraValues={friend} name={friend?.full_name}>
                      <span>
                        {friend.full_name} - {friend?.customer_type[0]?.title}
                      </span>
                    </Option>
                  );
                })}
              </Select>
            </>
          )}
        </Sheet>

        {/* Add friends modal */}
        <Sheet
          size="small"
          height="flexible"
          open={addFriendState?.modalVisible}
          closable
          title={"Add Playing Partners"}
          okText={"Confirm and Book"}
          onOk={handleAddFriends}
          onCancel={() =>
            setAddFriendState(prevState => ({
              ...prevState,
              friendsToAdd: [],
              modalVisible: false,
              selectedPlayerIds: [],
            }))
          }
          backDropCancel={false}
          stacked
        >
          <div className="client-portal-booking-add-playing-partners">
            <p className="confirmation-text">
              Would you like to add the following golfers to your playing partners list?
            </p>
            {addFriendState?.friendsToAdd?.map((participant: ICustomerFriend, index) => {
              return (
                <div
                  key={index}
                  className="add-player-container"
                  onClick={() => handleAddPlayerCheckbox(participant?.id)}
                >
                  <div className="add-player-checkbox">
                    <Checkbox
                      size="small"
                      checked={addFriendState?.selectedPlayerIds?.includes(participant?.id) ?? false}
                      onChange={() => handleAddPlayerCheckbox(participant?.id)}
                    />
                  </div>
                  <div className="player-image-container">
                    <div className="client-portal-profile-default-image">
                      <p>
                        {participant?.first_name?.charAt(0)?.toUpperCase()}
                        {participant?.last_name?.charAt(0)?.toUpperCase()}
                      </p>
                    </div>
                  </div>
                  <div className="player-info-conatiner">
                    <p className="player-name">{participant?.full_name}</p>
                    <p className="confirmation-text">{participant?.email}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </Sheet>

        <Popup
          open={prepaidWarning?.open}
          type="info"
          title={prepaidWarning?.title}
          description={prepaidWarning?.description}
          onCancel={() => setPrepaidWarning({ open: false, title: "", description: "" })}
          onOk={checkFriendsToAdd}
        />
      </>
    )
  );
}
