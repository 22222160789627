import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import RightClickMenu from "components/rightClickMenu/RightClickMenu";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { capitalize } from "helpers/Helpers";
import "./powerCartCard.scss";

interface IProductCardProps {
  powerCart: any;
  editing: boolean;
  onDelete: (id: number) => void;
  onEdit: (id: number) => void;
  handleSetStatus: (id: number, state: string) => void;
}

export interface IProductCardItem {
  id: number;
}

export default function PowerCartCard(props: IProductCardProps) {
  const { powerCart, editing, onDelete, onEdit, handleSetStatus } = props;

  function getStatusClassName(status: string) {
    switch (status) {
      case "available":
        return "power-cart-icon-available";
      case "occupied":
        return "power-cart-icon-occupied";
      case "reserved":
        return "power-cart-icon-reserved";
      case "maintenance":
        return "power-cart-icon-maintenance";
      case "broken":
        return "power-cart-icon-broken";
      default:
        return "power-cart-icon";
    }
  }

  return (
    <div className="align-left" onClick={() => onEdit(powerCart.id)}>
      <RightClickMenu
        sections={[
          [
            {
              icon: "circle-check",
              iconColor: "#4CA30D",
              title: "Set to Available",
              action: () => {
                handleSetStatus(powerCart.id, "available");
              },
            },
            {
              icon: "circle-dot",
              iconColor: "#2D4675",
              title: "Set to Occupied",
              action: () => {
                handleSetStatus(powerCart.id, "occupied");
              },
            },
            {
              icon: "circle-dot",
              iconColor: "#98A2B3",
              title: "Set to Reserved",
              action: () => {
                handleSetStatus(powerCart.id, "reserved");
              },
            },
            {
              icon: "circle-xmark",
              iconColor: "#F79009",
              title: "Set to Maintenance",
              action: () => {
                handleSetStatus(powerCart.id, "maintenance");
              },
            },
            {
              icon: "circle-xmark",
              iconColor: "#AA0504",
              title: "Set to Broken",
              action: () => {
                handleSetStatus(powerCart.id, "broken");
              },
            },
          ],
          [
            {
              icon: "pencil",
              title: "Edit",
              action: () => {
                onEdit(powerCart.id);
              },
            },
          ],
          [
            {
              icon: "trash",
              title: "Delete",
              action: () => {
                onDelete(powerCart.id);
              },
            },
          ],
        ]}
      >
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="leftMenuToolTip" className="power-cart-card-tooltip">
              {capitalize(powerCart.status)}
            </Tooltip>
          }
        >
          <div className="power-cart-card-container">
            <div
              className="power-cart-card"
              style={{
                opacity: 1,
              }}
            >
              <img
                src={
                  powerCart.type === "electric"
                    ? "/public/images/powercart-electric.svg"
                    : "/public/images/powercart.svg"
                }
                className={getStatusClassName(powerCart.status)}
              />
              <div>
                <div className="power-cart-card-title">{powerCart.name}</div>
              </div>
            </div>
          </div>
        </OverlayTrigger>
      </RightClickMenu>
    </div>
  );
}
