import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import axios, { CancelToken } from "axios";
import { StatusCode } from "api/protocols";
import { GetTicketStub, PutTicketStubRedeem } from "api/rpc/2024-04/facilityAdmin/ticket/stub";

import { showError, showSuccess } from "redux/actions/ui";
import { IStub } from "redux/reducers/models/ticket";
import { valueToString } from "helpers/Helpers";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import { Badge } from "components/badge/Badge";
import Search from "components/search/Search";
import DataTable from "../customer/tabs/houseAccounts/DataTable";

import "pages/secure/facility/product/TicketStub.scss";

interface IFilterState {
  search: string;
}

/**
 * By default, ticket stubs are not loaded unless search string is given
 * */
export default function TicketStubs() {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();

  const [stubs, setStubs] = useState({
    data: [] as IStub[],
    reloadData: false, // loadTicketStubs with cancel token
  });

  const [filters, setFilters] = useState<IFilterState>({
    search: "",
  });

  // Reload data on filter(s) change
  useEffect(() => {
    const source = axios.CancelToken.source();
    if (filters.search.length !== 0) {
      void loadTicketStubs(source.token);
    } else {
      setStubs(prev => ({ ...prev, data: [] }));
    }
    return () => source.cancel();
  }, [filters.search]);

  // Manual reload of data
  useEffect(() => {
    const source = axios.CancelToken.source();
    if (stubs.reloadData) {
      void loadTicketStubs(source.token);
    }
    return () => source.cancel();
  }, [stubs.reloadData]);

  async function loadTicketStubs(token?: CancelToken) {
    if (stubs.data !== undefined) {
      setStubs(prev => ({ ...prev, data: undefined }));
    }

    const res = await GetTicketStub(
      {
        search: filters.search,
      },
      token ? false : true,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      // Dispatch any messages on !OK response
    }

    setStubs(prev => ({ data: res.data, reloadData: false }));
  }

  async function redeemTicketStub(code: string) {
    const putRedeemRes = await PutTicketStubRedeem(code, true);

    if (putRedeemRes.status !== StatusCode.OK) {
      dispatch(showError("Error redeeming ticket stub")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Redeem Ticket Stub Successful")); // TODO: Translation
    setStubs(prev => ({ ...prev, reloadData: true }));
  }

  return (
    <Page
      title={t("secure.facility.product.ticket_stubs.001")}
      breadcrumbs={[{ label: "Back to Tickets", /* TODO: Translation */ url: "/admin/tickets" }]}
      narrow
    >
      <div className="flex-grow mb-4">
        <Search
          historyKey={"facility-ticket-stub-search"}
          searchCallback={searchString => setFilters(prev => ({ ...prev, search: searchString }))}
          placeholder={t("secure.facility.product.ticket_stubs.002")}
        />
      </div>

      <DataTable columns={[]} hideHeader loading={stubs.data === undefined}>
        {stubs.data?.map(stub => (
          <tr
            key={stub.id}
            className="clickable"
            onClick={() => history.push("/admin/tickets/stubs/" + String(stub.id))}
          >
            <td>
              <p className="table-cell-lead-text">{stub.code}</p>
            </td>
            <td>
              {stub.expiry_date === null ? (
                <span>No Expiry</span> /* TODO: Translation */
              ) : (
                <span>
                  {t("secure.facility.product.ticket_stubs.003")}{" "}
                  {moment.utc(stub.expiry_date).local().format("MMMM DD, YYYY")}
                </span>
              )}
            </td>
            <td>
              <Badge type="gray" size="small" iconLeft icon="circle" iconStyle="fas">
                {valueToString(stub.status, "capitalize")}
              </Badge>
            </td>
            <td onClick={e => e.stopPropagation()}>
              {stub.status === "valid" ? (
                <button onClick={() => redeemTicketStub(stub.code)}>
                  {t("secure.facility.product.ticket_stubs.004")}
                </button>
              ) : null}
            </td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
