import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavigationDropdownNew } from "components/navigationDropdownNew/NavigationDropdownNew";
import {
  EditInputDragItemTypes,
  EditInputRows,
  IEditInputDropItem,
} from "hooks/editInputDragAndDrop/useDropEditInputLayout/useDropEditInputLayout";
import React, { ReactNode, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDrag } from "react-dnd";
import "./draggableEditInput.scss";

export interface ISelectedEditInput {
  id: number;
  editModalOpen: boolean;
  deletePopupOpen: boolean;
}

interface IDraggableEditInputProps<Input extends IEditInputDropItem> {
  item: Input;
  rows: EditInputRows<Input>;
  setSelectedEditInput: React.Dispatch<React.SetStateAction<ISelectedEditInput>>;
  children: ReactNode;
}

export default function DraggableEditInput<Input extends IEditInputDropItem>(props: IDraggableEditInputProps<Input>) {
  const { item, rows, setSelectedEditInput, children } = props;

  const [optionsOverlayVisible, setOptionsOverlayVisible] = useState<boolean>(false);
  const [optionsOverlayVisibleDelay, setOptionsOverlayVisibleDelay] = useState<number>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const [{ isDragging }, drag, preview] = useDrag(() => {
    return {
      item,
      type: EditInputDragItemTypes.INPUT,
      collect: monitor => ({
        isDragging: !!monitor.isDragging(),
      }),
    };
  }, [rows]);

  function enterOptionsHandle() {
    setOptionsOverlayVisibleDelay(750);
  }

  function hideHandleOverlay() {
    setOptionsOverlayVisibleDelay(null);
    setOptionsOverlayVisible(false);
  }

  useEffect(() => {
    if (!optionsOverlayVisibleDelay) {
      return;
    }

    const interval = setInterval(() => {
      setOptionsOverlayVisible(true);
    }, optionsOverlayVisibleDelay);

    return () => clearInterval(interval);
  }, [optionsOverlayVisibleDelay]);

  return (
    <div
      ref={preview}
      style={{
        opacity: isDragging ? 0.5 : 1,
      }}
      className="draggable-edit-input"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children}
      {isHovered && (
        <div className="draggable-edit-input-options">
          <NavigationDropdownNew
            showPlainTextLabel
            leftAlign
            label={
              <OverlayTrigger
                placement="bottom"
                show={optionsOverlayVisible}
                overlay={
                  <Tooltip id={`draggable-edit-input-option-tooltip-${item.id}`}>
                    <div>
                      <b>Drag</b> to move
                    </div>
                    <div>
                      <b>Click</b> to open menu
                    </div>
                  </Tooltip>
                }
              >
                <div
                  ref={drag}
                  className="draggable-edit-input-options-handle"
                  onMouseEnter={enterOptionsHandle}
                  onMouseLeave={hideHandleOverlay}
                  onMouseDown={hideHandleOverlay}
                  style={{
                    cursor: isDragging ? "default" : "grab",
                  }}
                >
                  <FontAwesomeIcon
                    className="draggable-edit-input-options-handle-icon"
                    size="sm"
                    icon={["far", "grip-vertical"]}
                  />
                </div>
              </OverlayTrigger>
            }
            sections={[
              [
                {
                  label: "Edit",
                  icon: "pen-to-square",
                  onClick: () =>
                    setSelectedEditInput(prev => ({
                      ...prev,
                      id: item.id,
                      editModalOpen: true,
                    })),
                },
                {
                  label: "Delete",
                  icon: "trash-xmark",
                  onClick: () =>
                    setSelectedEditInput(prev => ({
                      ...prev,
                      id: item.id,
                      deletePopupOpen: true,
                    })),
                },
              ],
            ]}
          />
        </div>
      )}
    </div>
  );
}
