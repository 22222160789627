/* eslint-disable @typescript-eslint/unbound-method */
const apiUrl = process.env.API_BASE_URL;
import { enqueue, dequeue } from "redux/actions/ui";
import axios, { AxiosResponse, CancelToken } from "axios";
import { store } from "index";

export enum APIVersion {
  ver_2021_01 = "2021-01",
  ver_2022_07 = "2022-07",
  ver_2022_09 = "2022-09",
  ver_2024_04 = "2024-04",
}

export enum AdminType {
  MasterAdmin = "master-admin",
  FacilityAdmin = "facility-admin",
  ClientAdmin = "client-admin",
  Customer = "customer",
  Guest = "guest",
}

/**
 *
 * @param adminType AdminType
 * @param endPoint Endpoint string
 * @param apiVersion APIVersion
 * @returns {string} The entire API Url
 * @example APIUrl(AdminType.FacilityAdmin, "/tee-sheet/print", APIVersion.ver_2024_04) returns "/2024-04/facility-admin/tee-sheet/print";
 */
export function APIUrl(
  adminType: AdminType,
  endPoint: string,
  apiVersion: APIVersion = APIVersion.ver_2024_04,
): string {
  return `/${apiVersion}/${adminType}${endPoint}`;
}

const WebAPI = function () {
  return axios.create({
    baseURL: apiUrl,
    withCredentials: true,
    headers: {
      "Api-Key": "12345",
    },
  });
};

const RestAPI = function () {
  return axios.create({
    baseURL: apiUrl + "api",
    withCredentials: true,
    headers: {
      "Api-Key": "12345",
    },
  });
};

export interface IAPIResponse<T = any> {
  status: number;
  data: T;
  message: string;
}

export async function APIGet<T = any>(
  path: string,
  request: any,
  userGlobalLoader?: boolean,
  cancelToken?: CancelToken,
): Promise<IAPIResponse<T>> {
  if (userGlobalLoader) {
    enqueue()(store.dispatch, store.getState);
  }

  let result;

  const res = await RestAPI()
    .get(path, { params: request, cancelToken: cancelToken })
    .catch(error => {
      if (error.response) {
        result = {
          status: error.response.status,
          message: error.response.data.error,
        };
      } else if (error.request) {
        result = {
          status: StatusCode.SERVICE_UNAVAILABLE,
          message: "server no response",
        };
      } else {
        result = {
          status: StatusCode.BAD_REQUEST,
          message: error.message,
        };
      }
    });

  if (res) {
    result = {
      status: res.status,
      data: res.data.data ? res.data.data : res.data,
      message: res.data.message,
    };
  }

  if (userGlobalLoader) {
    dequeue()(store.dispatch, store.getState);
  }

  return result;
}

export async function APIPost<T = any>(path: string, request: any, userGlobalLoader?: boolean) {
  if (userGlobalLoader) {
    enqueue()(store.dispatch, store.getState);
  }

  let result;

  const res = await RestAPI()
    .post(path, request)
    .catch(error => {
      if (error.response) {
        result = {
          status: error.response.status,
          data: error.response.data,
        };
      } else if (error.request) {
        result = {
          status: 503,
          data: "server no response",
        };
      } else {
        result = {
          status: 400,
          data: error.message,
        };
      }
    });

  if (res) {
    result = {
      status: res.status,
      data: res.data,
    };
  }

  if (userGlobalLoader) {
    dequeue()(store.dispatch, store.getState);
  }

  return result as Omit<IAPIResponse<T>, "message">;
}

export async function APIPut<T = any>(
  path: string,
  request: any,
  userGlobalLoader?: boolean,
  cancelToken?: CancelToken,
): Promise<IAPIResponse<T>> {
  if (userGlobalLoader) {
    enqueue()(store.dispatch, store.getState);
  }

  let result;

  const res = await RestAPI()
    .put(path, request, { cancelToken: cancelToken })
    .catch(error => {
      if (error.response) {
        result = {
          status: error.response.status,
          data: error.response.data,
        };
      } else if (error.request) {
        result = {
          status: 503,
          data: "server no response",
        };
      } else {
        result = {
          status: 400,
          data: error.message,
        };
      }
    });

  if (res) {
    result = {
      status: res.status,
      data: res.data.data ? res.data.data : res.data,
      message: res.data.message,
    };
  }

  if (userGlobalLoader) {
    dequeue()(store.dispatch, store.getState);
  }

  return result;
}

export async function APIDelete(path: string, request: any, userGlobalLoader?: boolean) {
  if (userGlobalLoader) {
    enqueue()(store.dispatch, store.getState);
  }

  let result;

  const res = await RestAPI()
    .delete(path, { data: request })
    .catch(error => {
      if (error.response) {
        result = {
          status: error.response.status,
          data: error.response.data,
        };
      } else if (error.request) {
        result = {
          status: 503,
          data: "server no response",
        };
      } else {
        result = {
          status: 400,
          data: error.message,
        };
      }
    });

  if (res) {
    result = {
      status: res.status,
      data: res.data,
    };
  }

  if (userGlobalLoader) {
    dequeue()(store.dispatch, store.getState);
  }

  return result;
}

export async function WebGet(path: string, userGlobalLoader?: boolean) {
  if (userGlobalLoader) {
    enqueue()(store.dispatch, store.getState);
  }

  let result;

  const res = await WebAPI()
    .get(path)
    .catch(error => {
      if (error.response) {
        result = {
          status: error.response.status,
          data: error.response.data,
        };
      } else if (error.request) {
        result = {
          status: 503,
          data: "server no response",
        };
      } else {
        result = {
          status: 400,
          data: error.message,
        };
      }
    });

  if (res) {
    result = {
      status: res.status,
      data: res.data,
    };
  }

  if (userGlobalLoader) {
    dequeue()(store.dispatch, store.getState);
  }

  return result;
}

export async function WebPost(path: string, request: any, userGlobalLoader?: boolean) {
  if (userGlobalLoader) {
    enqueue()(store.dispatch, store.getState);
  }

  let result;

  const res = await WebAPI()
    .post(path, request)
    .catch(error => {
      if (error.response) {
        result = {
          status: error.response.status,
          data: error.response.data,
        };
      } else if (error.request) {
        result = {
          status: 503,
          data: "server no response",
        };
      } else {
        result = {
          status: 400,
          data: error.message,
        };
      }
    });

  if (res) {
    result = {
      status: res.status,
      data: res.data,
    };
  }

  if (userGlobalLoader) {
    dequeue()(store.dispatch, store.getState);
  }

  return result;
}

export async function GetExternal(url: string, userGlobalLoader?: boolean) {
  if (userGlobalLoader) {
    enqueue()(store.dispatch, store.getState);
  }

  const res = await fetch(url, {
    method: "get",
  });

  const data = await res.json();

  if (userGlobalLoader) {
    dequeue()(store.dispatch, store.getState);
  }

  return {
    status: res.status,
    data,
  };
}

export enum StatusCode {
  ACCEPTED = 202,
  BAD_GATEWAY = 502,
  BAD_REQUEST = 400,
  CONFLICT = 409,
  CONTINUE = 100,
  CREATED = 201,
  EXPECTATION_FAILED = 417,
  FAILED_DEPENDENCY = 424,
  FORBIDDEN = 403,
  GATEWAY_TIMEOUT = 504,
  GONE = 410,
  HTTP_VERSION_NOT_SUPPORTED = 505,
  IM_A_TEAPOT = 418,
  INSUFFICIENT_SPACE_ON_RESOURCE = 419,
  INSUFFICIENT_STORAGE = 507,
  INTERNAL_SERVER_ERROR = 500,
  LENGTH_REQUIRED = 411,
  LOCKED = 423,
  METHOD_FAILURE = 420,
  METHOD_NOT_ALLOWED = 405,
  MOVED_PERMANENTLY = 301,
  MOVED_TEMPORARILY = 302,
  MULTI_STATUS = 207,
  MULTIPLE_CHOICES = 300,
  NETWORK_AUTHENTICATION_REQUIRED = 511,
  NO_CONTENT = 204,
  NON_AUTHORITATIVE_INFORMATION = 203,
  NOT_ACCEPTABLE = 406,
  NOT_FOUND = 404,
  NOT_IMPLEMENTED = 501,
  NOT_MODIFIED = 304,
  OK = 200,
  PARTIAL_CONTENT = 206,
  PAYMENT_REQUIRED = 402,
  PERMANENT_REDIRECT = 308,
  PRECONDITION_FAILED = 412,
  PRECONDITION_REQUIRED = 428,
  PROCESSING = 102,
  PROXY_AUTHENTICATION_REQUIRED = 407,
  REQUEST_HEADER_FIELDS_TOO_LARGE = 431,
  REQUEST_TIMEOUT = 408,
  REQUEST_TOO_LONG = 413,
  REQUEST_URI_TOO_LONG = 414,
  REQUESTED_RANGE_NOT_SATISFIABLE = 416,
  RESET_CONTENT = 205,
  SEE_OTHER = 303,
  SERVICE_UNAVAILABLE = 503,
  SWITCHING_PROTOCOLS = 101,
  TEMPORARY_REDIRECT = 307,
  TOO_MANY_REQUESTS = 429,
  UNAUTHORIZED = 401,
  UNAVAILABLE_FOR_LEGAL_REASONS = 451,
  UNPROCESSABLE_ENTITY = 422,
  UNSUPPORTED_MEDIA_TYPE = 415,
  USE_PROXY = 305,
}
