import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../facility/facility.scss";

import { GetDepartments } from "api/rpc/facility";

import Page from "components/page/Page";
import Card from "components/card/Card";
import { StatusCode } from "api/protocols";

import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import "./departments.scss";
import { useAppSelector } from "hooks/redux";

export default function Departments(props: any) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { authStore, facilityStore } = useAppSelector(store => store);

  const [departmentState, setDepartmentTypeState] = useState({
    department: null,
  });

  const departmentIcon: IconProp = ["fas", "circle"];
  const categoryIcon: IconProp = ["fas", "circle"];
  const subcategoryIcon: IconProp = ["far", "circle"];
  const chevronUp: IconProp = ["far", "chevron-up"];
  const chevronDown: IconProp = ["far", "chevron-down"];

  useEffect(() => {
    void loadDepartments();

    return () => {
      setDepartmentTypeState({ department: null });
    };
  }, []);

  async function loadDepartments() {
    const res = await GetDepartments(true);
    if (res.status !== StatusCode.OK) {
      return;
    }

    const departments = res.data;

    {
      departments
        ?.filter((filteredDepart: any) => filteredDepart.type === "department")
        .map((department: any) => {
          department.selected = true;

          if (department.cateogries.length > 0) {
            department.cateogries.map((category: any) => {
              category.selected = true;
            });
          }
        });
    }

    setDepartmentTypeState(prev => ({
      ...prev,
      department: departments,
    }));
  }

  function navigateToNewDepartment() {
    history.push("/admin/settings/departments/new");
  }

  function navigateToEditDepartment(url: string) {
    history.push(url);
  }

  const primaryAction = {
    content: t("secure.facility.settings.departments.departments.001"),
    action: navigateToNewDepartment,
    disabled:
      !authStore?.user?.permissions?.departments_create || !facilityStore?.facility?.permissions?.departments_create,
  };

  function handleDepartmentSelect(department: any, category?: any) {
    const departments = departmentState.department;

    if (category === null || category === undefined) {
      {
        departments
          ?.filter((filteredDepart: any) => filteredDepart.id === department.id)
          .map((department: any) => {
            department.selected = !department.selected;
          });
      }

      setDepartmentTypeState(prev => ({
        ...prev,
        department: departments,
      }));
    } else {
      {
        departments
          ?.filter((filteredDepart: any) => filteredDepart.id === department.id)
          .map((department: any) => {
            department.cateogries
              ?.filter((filteredCategory: any) => filteredCategory.id === category.id)
              .map((category: any) => {
                category.selected = !category.selected;
              });
          });
      }

      setDepartmentTypeState(prev => ({
        ...prev,
        department: departments,
      }));
    }
  }

  return (
    <Page title={t("secure.facility.settings.departments.departments.002")} narrow primaryAction={primaryAction}>
      <Card>
        <Card.Section>
          <div className="flex flex-col mb-4">
            <p className="text-lg text-semibold mb-1">Departments</p>
            <div className="flex">
              <p className="text-md mr-3 text-gray-400">
                Departments <FontAwesomeIcon icon={departmentIcon} style={{ color: "#6888C5" }} />
              </p>
              <p className="text-md mr-3 text-gray-400">
                Categories <FontAwesomeIcon icon={categoryIcon} style={{ color: "#CDD7EC" }} />
              </p>
              <p className="text-md text-gray-400">
                Subcategories <FontAwesomeIcon icon={subcategoryIcon} />
              </p>
            </div>
          </div>

          <div className="departments-container">
            {/* Map through departments */}
            {departmentState.department
              ?.filter((filteredDepart: any) => filteredDepart.type === "department")
              .map((department: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <div key={index} className="department-card">
                      <div
                        style={{
                          cursor:
                            authStore?.user?.permissions?.departments_edit &&
                            facilityStore?.facility?.permissions?.departments_edit
                              ? "pointer"
                              : "default",
                        }}
                        onClick={
                          authStore?.user?.permissions?.departments_edit &&
                          facilityStore?.facility?.permissions?.departments_edit
                            ? () => navigateToEditDepartment("/admin/settings/departments/" + String(department.id))
                            : null
                        }
                      >
                        <p
                          style={{
                            cursor:
                              authStore?.user?.permissions?.departments_edit &&
                              facilityStore?.facility?.permissions?.departments_edit
                                ? "pointer"
                                : "default",
                          }}
                          className="department-title"
                        >
                          {department.title}
                        </p>
                      </div>

                      <div>
                        <p style={{ fontSize: "20px" }} onClick={() => handleDepartmentSelect(department)}>
                          <FontAwesomeIcon icon={department.selected ? chevronDown : chevronUp} />
                        </p>
                      </div>
                    </div>

                    {/* Map through categories */}
                    {department.selected ? (
                      <div>
                        {department.cateogries?.map((category: any, index: number) => {
                          return (
                            <React.Fragment key={index}>
                              <div key={index} className="category-card">
                                <div
                                  style={{
                                    cursor:
                                      authStore?.user?.permissions?.departments_edit &&
                                      facilityStore?.facility?.permissions?.departments_edit
                                        ? "pointer"
                                        : "default",
                                  }}
                                  onClick={
                                    authStore?.user?.permissions?.departments_edit &&
                                    facilityStore?.facility?.permissions?.departments_edit
                                      ? () =>
                                          navigateToEditDepartment("/admin/settings/departments/" + String(category.id))
                                      : null
                                  }
                                >
                                  <p
                                    className="department-title"
                                    style={{
                                      textIndent: "4rem",
                                      cursor:
                                        authStore?.user?.permissions?.departments_edit &&
                                        facilityStore?.facility?.permissions?.departments_edit
                                          ? "pointer"
                                          : "default",
                                    }}
                                  >
                                    {category.title}
                                  </p>
                                </div>

                                <div>
                                  <p
                                    style={{ fontSize: "20px" }}
                                    onClick={() => handleDepartmentSelect(department, category)}
                                  >
                                    <FontAwesomeIcon icon={category.selected ? chevronDown : chevronUp} />
                                  </p>
                                </div>
                              </div>

                              {/* Map through subcategories */}
                              {category.selected ? (
                                <div>
                                  {category.subcategories?.map((subcategory: any, index: number) => {
                                    return (
                                      <div
                                        key={index}
                                        onClick={
                                          authStore?.user?.permissions?.departments_edit &&
                                          facilityStore?.facility?.permissions?.departments_edit
                                            ? () =>
                                                navigateToEditDepartment(
                                                  "/admin/settings/departments/" + String(subcategory.id),
                                                )
                                            : null
                                        }
                                        className="subcategory-card"
                                        style={{
                                          cursor:
                                            authStore?.user?.permissions?.departments_edit &&
                                            facilityStore?.facility?.permissions?.departments_edit
                                              ? "pointer"
                                              : "default",
                                        }}
                                      >
                                        <p
                                          style={{
                                            textIndent: "7rem",
                                            cursor:
                                              authStore?.user?.permissions?.departments_edit &&
                                              facilityStore?.facility?.permissions?.departments_edit
                                                ? "pointer"
                                                : "default",
                                          }}
                                        >
                                          {subcategory.title}
                                        </p>
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : null}
                            </React.Fragment>
                          );
                        })}
                      </div>
                    ) : null}
                  </React.Fragment>
                );
              })}
          </div>
        </Card.Section>
      </Card>
    </Page>
  );
}
