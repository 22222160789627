import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { PostFacility } from "api/rpc/2024-04/clientAdmin/facility/facility";
import { GetCountry, GetProvince } from "api/rpc/country";
import { ICountry, IProvince } from "redux/reducers/models/customer";

import Card from "components/card/Card";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import Page from "components/page/Page";
import SelectNew, { TSelectOption } from "components/select/SelectNew";
import CustomerImage from "elements/customer/CustomerImage";
import moment from "moment";

interface IFacilityState {
  long_name: string;
  full_name: string;
  holes: number;
  address_line_1: string;
  address_line_2: string;
  city: string;
  country_id: number;
  province_id: number;
  postal: string;
  timezone: string;
  phone: string;
  email: string;
  customer_email: string;
  prefix: string;
}

export default function FacilityNew() {
  const history = useHistory();
  const [facilityState, setFacilityState] = useState<IFacilityState>({
    long_name: "",
    full_name: "",
    holes: null,
    address_line_1: "",
    address_line_2: "",
    city: "",
    country_id: null,
    province_id: null,
    postal: "",
    timezone: "",
    phone: "",
    email: "",
    customer_email: "",
    prefix: "",
  });

  const [countries, setCountries] = useState<ICountry[]>([]);
  const [provinces, setProvinces] = useState<IProvince[]>([]);
  const [timezones, setTimezones] = useState<TSelectOption<null>[]>([]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadCountry(source.token);
    return () => source.cancel();
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadProvinces(facilityState.country_id, source.token);
    return () => source.cancel();
  }, [facilityState.country_id]);

  async function loadCountry(token?: CancelToken) {
    if (countries !== undefined) {
      setCountries(undefined);
    }
    const res = await GetCountry({ provinces: true }, token ? false : true, token);
    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      setCountries([]);
      return;
    }

    setCountries(res.data);
  }

  async function loadProvinces(countryId: number, token?: CancelToken) {
    if (provinces !== undefined) {
      setProvinces(undefined);
    }
    const res = await GetProvince({ country_id: countryId }, token ? false : true, token);
    if (token && token.reason) {
      return;
    }

    if (res.status !== StatusCode.OK) {
      setProvinces([]);
      return;
    }

    setProvinces(res.data);
  }

  async function handleCreateFacility() {
    const facilityRes = await PostFacility(facilityState, true);
    if (facilityRes.status !== StatusCode.OK) {
      return;
    }

    history.push("/admin/settings/client-settings/facility-details");
    return;
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setFacilityState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleCountryChange(country_id: number) {
    setFacilityState(prev => ({ ...prev, country_id }));

    const country = countries?.find(country => country.id === country_id);

    if (country) {
      const timezones = moment.tz.zonesForCountry(country.code) ?? [];

      setTimezones(timezones.map(timezone => ({ id: timezone, label: timezone })));
    }
  }

  const primaryAction = {
    content: "Create", // TODO: Translation
    action: handleCreateFacility,
  };

  return (
    <Page
      title="New Facility" // TODO: Translation
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: "Back to Facilities",
          /* TODO: Translation */ url: "/admin/settings/client-settings/facility-details",
        },
      ]}
    >
      <Form>
        <Card title="Basic Information">
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <div className="pt-1">
                  <Input
                    value={facilityState.long_name}
                    label="Long Name" // TODO: Translation
                    id="long_name"
                    onChange={handleInputChange}
                    placeholder="Long Name" // TODO: Translation
                  />
                </div>
                <div className="pt-1">
                  <Input
                    value={facilityState.full_name}
                    label="Full Name" // TODO: Translation
                    id="full_name"
                    onChange={handleInputChange}
                    placeholder="Full Name" // TODO: Translation
                  />
                </div>
                <div className="pt-1">
                  <Input
                    value={facilityState.prefix}
                    label="Prefix"
                    id="prefix"
                    onChange={handleInputChange}
                    placeholder="Prefix"
                  />
                </div>
                <SelectNew
                  id="holes"
                  label="Hole Count"
                  value={facilityState.holes}
                  onChange={value => setFacilityState(prev => ({ ...prev, holes: value }))}
                  options={[9, 18, 27, 36].map(val => ({ id: val, label: val }))}
                  placeholder="Select Holes" // TODO: Translation
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={facilityState.address_line_1}
                  label="Address Line 1" // TODO: Translation
                  id="address_line_1"
                  onChange={handleInputChange}
                  placeholder="111 Rockerfeller Lane"
                />
                <Input
                  value={facilityState.address_line_2}
                  label="Address Line 2" // TODO: Translation
                  id="address_line_2"
                  onChange={handleInputChange}
                  placeholder=""
                />
                <Input
                  value={facilityState.city}
                  label="City" // TODO: Translation
                  id="city"
                  onChange={handleInputChange}
                  placeholder="City" // TODO: Translation
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <SelectNew
                  id="country_id"
                  label="Country" // TODO: Translation
                  value={facilityState.country_id}
                  onChange={handleCountryChange}
                  options={countries?.map(val => ({ id: val.id, label: val.name }))}
                  isLoading={countries === undefined}
                  placeholder="Select Country" // TODO: Translation
                />
                <SelectNew
                  id="province_id"
                  label="Province" // TODO: Translation
                  value={facilityState.province_id}
                  onChange={value => setFacilityState(prev => ({ ...prev, province_id: value }))}
                  options={provinces?.map(val => ({ id: val.id, label: val.name }))}
                  isLoading={provinces === undefined}
                  placeholder="Select Province" // TODO: Translation
                />
                <div className="pt-1">
                  <Input
                    value={facilityState.postal}
                    label="Postal Code" // TODO: Translation
                    id="postal"
                    onChange={handleInputChange}
                    placeholder="Postal" // TODO: Translation
                  />
                </div>
                <SelectNew
                  id="timezone"
                  label="Time Zone"
                  value={facilityState.timezone}
                  onChange={value => setFacilityState(prev => ({ ...prev, timezone: value }))}
                  options={timezones}
                  isLoading={false}
                  placeholder="Select Time Zone"
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Input
                  value={facilityState.phone}
                  label="Phone Number" // TODO: Translation
                  id="phone"
                  onChange={handleInputChange}
                  placeholder="Phone" // TODO: Translation
                />
                <Input
                  value={facilityState.email}
                  label="Contact Email" // TODO: Translation
                  id="email"
                  onChange={handleInputChange}
                  placeholder=""
                />
                <Input
                  value={facilityState.customer_email}
                  label="Customer Email" // TODO: Translation
                  id="customer_email"
                  onChange={handleInputChange}
                  placeholder=""
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>
      </Form>
    </Page>
  );
}
