import { APIGet, APIPost, APIPut, APIDelete, StatusCode } from "api/protocols";
import { IOrderTransaction } from "redux/reducers/models/order";
import { IPostCheckoutRes } from "./cart";

const apiVersion = "2021-01";
const adminType = "facility-admin";
const baseOrderUrl = "/" + apiVersion + "/" + adminType + "/order";

// GET Order
// Retrieve a list of orders for a facility
// extended: boolean
export async function GetOrder(params: string, useGlobalLoader: boolean) {
  return await APIGet(baseOrderUrl + "?" + params, useGlobalLoader);
}

interface IPostOrder {
  customer_id: number;
}

interface IPostOrderRes {
  status: StatusCode;
  data: any;
}

// POST Order
// Create a new order
export async function PostOrder(params: IPostOrder, useGlobalLoader: boolean): Promise<IPostOrderRes> {
  return await APIPost(baseOrderUrl, params, useGlobalLoader);
}

// POST Line Items
// Add a new line to an order
interface IPostLineItemsToOrder {
  order_id: number;
  variant_id: number;
  quantity: number;
}

interface IPostLineItemsToOrderRes {
  status: StatusCode;
  data: any;
}

export async function PostLineItemsToOrder(
  params: IPostLineItemsToOrder,
  useGlobalLoader: boolean,
): Promise<IPostLineItemsToOrderRes> {
  return await APIPost(baseOrderUrl + "/line-item", params, useGlobalLoader);
}

interface IPutOrderComplete {
  order_id: number;
  gift_cards: { cart_line_item_id: number; code: string; pin: number; random: boolean }[];
  register_id?: string;
}

interface IPostRefund {
  order_id?: number;
  refund_line_items?: { line_item_id: number; refund_quantity: number; refund_amount: number }[];
  refund_transactions?: any[];
}

interface IPostCalculateRefund {
  order_id?: number;
  refund_line_items?: { line_item_id: number; refund_quantity: number; refund_amount: number }[];
  refund_transactions?: IOrderTransaction[];
}

// PUT Order
// Update some attributes on an order
export async function PutOrder(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseOrderUrl, params, useGlobalLoader);
}

export async function GetReceipt(
  params: { id: number; tee_time_id?: number; type?: string },
  useGlobalLoader?: boolean,
) {
  return await APIGet(baseOrderUrl + "/receipt", params, useGlobalLoader);
}

export async function PutOrderComplete(params: IPutOrderComplete, useGlobalLoader?: boolean) {
  return await APIPut(baseOrderUrl + "/complete", params, useGlobalLoader);
}

export async function PostRefund(params: IPostRefund, useGlobalLoader: boolean) {
  return await APIPost(baseOrderUrl + "/refund", params, useGlobalLoader);
}

export async function PostCalculateRefund(params: IPostCalculateRefund, useGlobalLoader: boolean) {
  return await APIPost(baseOrderUrl + "/refund/calculate", params, useGlobalLoader);
}
