import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { TLeagueScoresheet } from "redux/reducers/models/league";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/league/scoring";

export type TGetLeagueScoresheet = {
  id?: string | number;
  league_id?: string | number;
  latest?: boolean;
};

export type TPostLeagueScoresheet = {
  league_id: string | number;
  date_played: string;
  name: string;
  holes?: 9 | 18;
  tee_deck_id?: string | number;
  division_front_id: number;
  division_back_id?: number | null;
  playoffs?: boolean;
};

export type TPutLeagueScoresheet = Omit<Partial<TPostLeagueScoresheet>, "league_id"> & {
  id: string | number;
};

//GET League Scoresheets
//Retrieve a list of league scoresheets
export async function GetLeagueScoresheets(
  params: TGetLeagueScoresheet,
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet<TLeagueScoresheet[]>(baseUrl + "/scoresheet", params, useGlobalLoader, token);
}

//POST League Scoresheets
//Create a new league scoresheet
export async function PostLeagueScoresheet(params: TPostLeagueScoresheet, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/scoresheet", params, useGlobalLoader);
}

//PUT League Scoresheets
//Update league scoresheet
export async function PutLeagueScoresheet(params: TPutLeagueScoresheet, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/scoresheet", params, useGlobalLoader);
}

//DELETE League Scoresheet
//Create a new league scoresheet
export async function DeleteLeagueScoresheet(params: { id: string | number }, useGlobalLoader: boolean) {
  return await APIDelete(baseUrl + "/scoresheet", params, useGlobalLoader);
}
