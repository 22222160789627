import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UIActions from "redux/actions/ui";
import * as CustomerCartActions from "redux/actions/customer/cart";
import * as TournamentRegistrationActions from "redux/actions/customer/tournamentRegistration";
import TournamentCheckout from "pages/guest/TournamentRegistration/TournamentCheckout";

const mapStateToProps = (state: any) => {
  return {
    cartStore: { ...state.customerCartStore },
    tournamentRegistrationStore: { ...state.tournamentRegistrationStore },
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    cartActions: bindActionCreators(
      {
        ...CustomerCartActions,
      },
      dispatch,
    ),
    uiActions: bindActionCreators(
      {
        ...UIActions,
      },
      dispatch,
    ),
    tournamentRegistrationActions: bindActionCreators(
      {
        ...TournamentRegistrationActions,
      },
      dispatch,
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TournamentCheckout);
