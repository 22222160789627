import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetTag } from "api/rpc/2024-04/facilityAdmin/client/tags";

import { showError } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";
import { TCustomerTag } from "redux/reducers/models/customer";

export default function CustomerTags() {
  const history = useHistory();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [customerTags, setCustomerTags] = useState<TCustomerTag[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadCustomerTags(source.token);
    return () => source.cancel();
  }, []);

  async function loadCustomerTags(token?: CancelToken) {
    if (customerTags !== undefined) {
      setCustomerTags(undefined);
    }

    const res = await GetTag({ type: "customer" }, token ? false : true, token);
    if (token && token.reason) {
      return;
    }

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error getting tags")); // TODO: Translation
    }

    setCustomerTags(res.status !== StatusCode.OK ? [] : res.data);
  }

  const primaryAction = {
    content: "New Customer Tag", // TODO: Translation
    action: () => history.push("/admin/settings/customer_tags/new"),
  };

  return (
    <Page
      title={"Customer Tags"} // TODO: Translation
      subtitle={"Manage customer tags"} // TODO: Translation
      narrow
      primaryAction={primaryAction}
    >
      <DataTable
        columns={[
          { label: "Label", width: "25%" }, // TODO: Translation
          { label: "Description", width: "50%" }, // TODO: Translation
          { label: "Colour", width: "25%" }, // TODO: Translation
        ]}
        loading={customerTags === undefined}
      >
        {customerTags?.map(tag => (
          <tr
            key={tag.id}
            className="clickable"
            onClick={() => history.push("/admin/settings/customer_tags/" + String(tag.id))}
          >
            <td className="table-cell-text-lead">{tag.label}</td>
            <td>{tag.description}</td>
            <td>
              <FontAwesomeIcon icon={["fas", "circle"]} color={tag.colour} />
            </td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
