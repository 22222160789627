import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import axios, { CancelToken } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { StatusCode } from "api/protocols";
import { GetCustomerType } from "api/rpc/2024-04/clientAdmin/customer/type";

import { showError } from "redux/actions/ui";
import { TCustomerType } from "redux/reducers/models/customer";
import { useAppDispatch } from "hooks/redux";
import { valueToString } from "helpers/Helpers";

import Page from "components/page/Page";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import Sheet from "components/sheet/Sheet";
import Card from "components/card/Card";
import CustomerImage from "elements/customer/CustomerImage";
import { DeleteLogo, GetClient, PostLogo } from "api/rpc/clientAdmin/client";
import { IClient } from "redux/reducers/models/client";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";

export default function Details() {
  const history = useHistory();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const [clientState, setClientState] = useState<IClient>(null);

  useEffect(() => {
    void loadClient();
  }, []);

  async function loadClient() {
    const clientRes = await GetClient(null, true);

    if (clientRes.status !== StatusCode.OK) {
      console.log("Error loading client");
      return;
    }

    setClientState(clientRes.data);
  }

  async function saveCustomerImage(imageFile: File) {
    const formData = new FormData();

    formData.append("logo_image", imageFile);
    const clientRes = await PostLogo(formData, true);
    if (clientRes.status !== StatusCode.OK) {
      dispatch(showError("Error uploading logo"));
      return;
    }

    setClientState(clientRes.data);
  }

  async function deleteCustomerImage() {
    const clientRes = await DeleteLogo(null, true);

    if (clientRes.status !== StatusCode.OK) {
      dispatch(showError("Error deleting logo"));
    }

    setClientState(clientRes.data);
  }

  return (
    <Page title="Client Details">
      <Card>
        <Card.Section title={"Basic Information"}>
          <FormLayout>
            <FormLayout.Group>
              <Input value={clientState?.long_name} label="Long Name" readOnly disabled />
              <Input value={clientState?.address_line_1} label="Address" readOnly disabled />
              <Input value={clientState?.city} label="City" readOnly disabled />
            </FormLayout.Group>
            <FormLayout.Group>
              <Input value={clientState?.province_name} label="Province" readOnly disabled />
              <Input value={clientState?.country_name} label="Country" readOnly disabled />
              <Input value={clientState?.postal} label="Postal Code" readOnly disabled />
            </FormLayout.Group>
            <FormLayout.Group>
              <Input value={clientState?.phone} label="Phone Number" readOnly disabled />
              <Input value={clientState?.website_url} label="Website" readOnly disabled />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
        <Card.Section title="Client Logo">
          <CustomerImage
            imageSource={clientState?.logo_source_url}
            saveProfileImage={imageFile => saveCustomerImage(imageFile)}
            deleteProfileImage={deleteCustomerImage}
          />
        </Card.Section>
      </Card>
    </Page>
  );
}
