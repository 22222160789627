export interface IOrderOptions {
  label: string;
  description?: string;
  url: string;
  icon?: any;
}

const orderOptions: IOrderOptions[] = [
  {
    label: "Orders",
    description: "List of Orders",
    url: "/admin/order", //Temporary route
  },
  {
    label: "Rain Checks",
    description: "List of Rain Checks",
    url: "/admin/rainchecks",
  },
  {
    label: "Gift Cards",
    description: "List of Gift Cards",
    url: "/admin/giftcards",
  },
];

export default orderOptions;
