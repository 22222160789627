import React, { useEffect, useRef, useState } from "react";
import "./multipleActionDropdown.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { flattenDeep } from "lodash";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { MOBILE_WIDTH } from "helpers/ScreenSizes";
import { IconName } from "@fortawesome/fontawesome-svg-core";

type HandlerOption = {
  type: "handler";
  label: string;
  disabled?: boolean;
  icon?: IconName;
  disableAutoClose?: boolean;
  handler: () => void;
};

type HandlerOptionState = {
  type: "handler";
};

type ConditionalOption = {
  type: "conditional";
  label: string;
  active: boolean;
  setActive: (active: boolean) => void;
  disabled?: boolean;
  subOptions: Array<HandlerOption>;
  icon?: IconName;
};

type ConditionalOptionState = {
  type: "conditional";
  subOptions: Array<HandlerOptionState>;
};

type CategoryOption = {
  type: "category";
  label: string;
  subOptions: DropdownOptions;
  icon?: IconName;
};

type CategoryOptionState = {
  type: "category";
  subOptions: Array<DropdownOptionState>;
  open: boolean;
};

type DropdownOption = HandlerOption | ConditionalOption | CategoryOption;
type DropdownOptions = Array<DropdownOption>;

type DropdownOptionState = HandlerOptionState | ConditionalOptionState | CategoryOptionState;
type DropdownOptionStates = Array<DropdownOptionState>;

export interface IMultipleActionDropdownProps {
  children: React.ReactNode;
  alignment: "left" | "right";
  options: DropdownOptions;
  disabled?: boolean;
}

interface IMultipleActionDropdownState {
  dropdownContainerRef: React.MutableRefObject<HTMLDivElement>;
  isDropdownOpen: boolean;
  optionStates: DropdownOptionStates;
}

export default function MultipleActionDropdown(props: IMultipleActionDropdownProps) {
  const { children, options, alignment, disabled = false } = props;

  const prevOptions = useRef<DropdownOptions>(null);

  const windowSize = useWindowSize();

  const [multipleActionDropdownState, setMultipleActionDropdownState] = useState<IMultipleActionDropdownState>({
    dropdownContainerRef: useRef(null),
    isDropdownOpen: false,
    optionStates: null,
  });

  function handleOutsideDropdownContainerClick(e: MouseEvent | TouchEvent) {
    if (
      !multipleActionDropdownState.dropdownContainerRef.current ||
      multipleActionDropdownState.dropdownContainerRef.current.contains(e.target as Node)
    ) {
      return;
    }

    setMultipleActionDropdownState(prev => ({ ...prev, isDropdownOpen: false }));
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideDropdownContainerClick);
    document.addEventListener("touchstart", handleOutsideDropdownContainerClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideDropdownContainerClick);
      document.removeEventListener("touchstart", handleOutsideDropdownContainerClick);
    };
  }, []);

  useEffect(() => {
    if (options && hasOptionsStructureChanged(prevOptions.current, options)) {
      setOptionsStateFromOptions(options);
      prevOptions.current = { ...options };
    }
  }, [options]);

  /* Compares the structure of the options prop versus the previous options prop.
     If the type of an option has been changed or a new option has been added the optionStates need to be recreated
     so that the options and optionStates are in sync with eachother.
  */
  function hasOptionsStructureChanged(prevOptions: DropdownOptions, options: DropdownOptions) {
    function hasOptionStructureChanged(prevOption: DropdownOption, option: DropdownOption): boolean[] {
      if (!prevOption?.type || option.type !== prevOption.type) {
        return [true];
      } else {
        if (option.type === "handler") {
          return [false];
        } else if (option.type === "conditional") {
          return flattenDeep([
            false,
            ...option.subOptions.map((subOption, index) =>
              hasOptionStructureChanged((prevOption as ConditionalOption).subOptions?.[index], subOption),
            ),
          ]);
        } else if (option.type === "category") {
          return flattenDeep([
            false,
            ...option.subOptions.map((subOption, index) =>
              hasOptionStructureChanged((prevOption as CategoryOption).subOptions?.[index], subOption),
            ),
          ]);
        }
      }
    }

    const changeChecks = flattenDeep(
      options.map((option, index) => hasOptionStructureChanged(prevOptions?.[index], option)),
    );
    const hasChanged = changeChecks.some(check => check === true);

    return hasChanged;
  }

  function setOptionsStateFromOptions(options: DropdownOptions) {
    function getOptionStateFromOption(option: DropdownOption): DropdownOptionState {
      if (option.type === "handler") {
        return { type: "handler" };
      } else if (option.type === "conditional") {
        return {
          type: "conditional",
          subOptions: option.subOptions.map(getOptionStateFromOption) as HandlerOptionState[],
        };
      } else if (option.type === "category") {
        return { type: "category", subOptions: option.subOptions.map(getOptionStateFromOption), open: false };
      }
    }

    if (!options) {
      setMultipleActionDropdownState(prev => ({ ...prev, optionStates: null }));
    } else {
      const optionStates = options.map(getOptionStateFromOption);

      setMultipleActionDropdownState(prev => ({ ...prev, optionStates: optionStates }));
    }
  }

  function handleToggleCategoryOption(targetCategoryOptionState: CategoryOptionState) {
    function toggleCategoryOption(currentOptionState: DropdownOptionState): DropdownOptionState {
      if (currentOptionState === targetCategoryOptionState) {
        if (targetCategoryOptionState.open === false) {
          return { ...targetCategoryOptionState, open: true };
        } else {
          const collapseAllCategories = (optionState: DropdownOptionState): DropdownOptionState => {
            if (optionState.type !== "category") {
              return optionState;
            } else {
              return { ...optionState, open: false, subOptions: optionState.subOptions.map(collapseAllCategories) };
            }
          };

          return {
            ...targetCategoryOptionState,
            open: false,
            subOptions: targetCategoryOptionState.subOptions.map(collapseAllCategories),
          };
        }
      } else {
        if (currentOptionState.type !== "category") {
          return currentOptionState;
        } else {
          return { ...currentOptionState, subOptions: currentOptionState.subOptions.map(toggleCategoryOption) };
        }
      }
    }

    const optionStates = multipleActionDropdownState.optionStates.map(toggleCategoryOption);

    setMultipleActionDropdownState(prev => ({ ...prev, optionStates: optionStates }));
  }

  function handleDropdownContainerClick() {
    if (!disabled) {
      setMultipleActionDropdownState(prev => ({
        ...prev,
        isDropdownOpen: !prev.isDropdownOpen,
      }));
    }
  }

  function handlerClick(handlerOption: HandlerOption) {
    if (!handlerOption) {
      return;
    }

    if (handlerOption.handler) {
      handlerOption.handler();
    }

    if (!handlerOption.disableAutoClose) {
      setMultipleActionDropdownState(prev => ({
        ...prev,
        isDropdownOpen: false,
      }));
    }
  }

  function renderDropdownOption(option: DropdownOption, optionState: DropdownOptionState, indentLevel: number) {
    if (!option || !optionState) {
      return null;
    } else if (option.type === "handler") {
      return (
        <button
          type="button"
          className="dropdown-handler-option"
          style={{ paddingLeft: `${indentLevel / (windowSize.width <= MOBILE_WIDTH ? 2 : 1)}rem` }}
          disabled={option.disabled}
          onClick={() => handlerClick(option)}
        >
          {option.icon ? (
            <div className="multiple-action-dropdown-icon-container">
              <div>
                <FontAwesomeIcon icon={["far", option.icon]} />
              </div>

              {option.label}
            </div>
          ) : (
            <div>{option.label}</div>
          )}
        </button>
      );
    } else if (option.type === "conditional") {
      const conditionalOptionState = optionState as ConditionalOptionState;

      return (
        <div className="dropdown-conditional-option-container">
          <button
            type="button"
            className={classNames("dropdown-conditional-option", {
              "dropdown-conditional-option-active": option.active,
            })}
            style={{ paddingLeft: `${indentLevel / (windowSize.width <= MOBILE_WIDTH ? 2 : 1)}rem` }}
            disabled={option.disabled}
            onClick={() => option.setActive(!option.active)}
          >
            <div>{option.label}</div>
            {option.active ? (
              <FontAwesomeIcon className="dropdown-conditional-option-icon" icon={["far", "square-check"]} />
            ) : (
              <FontAwesomeIcon className="dropdown-conditional-option-icon" icon={["far", "square"]} />
            )}
          </button>

          {option.active &&
            option.subOptions?.map((subOption, index) => {
              return (
                <React.Fragment key={index}>
                  {renderDropdownOption(
                    { ...subOption, disabled: option.disabled ? true : subOption.disabled },
                    conditionalOptionState.subOptions[index],
                    indentLevel,
                  )}
                </React.Fragment>
              );
            })}
        </div>
      );
    } else if (option.type === "category") {
      const categoryOptionState = optionState as CategoryOptionState;

      return (
        <div className="dropdown-category-option-container">
          <button
            type="button"
            className={classNames("dropdown-category-option", {
              "dropdown-category-option-open": categoryOptionState.open,
            })}
            style={{ paddingLeft: `${indentLevel / (windowSize.width <= MOBILE_WIDTH ? 2 : 1)}rem` }}
            onClick={() => handleToggleCategoryOption(categoryOptionState)}
          >
            <div>{option.label}</div>
            {categoryOptionState.open ? (
              <FontAwesomeIcon className="dropdown-category-option-icon" icon={["far", "chevron-up"]} />
            ) : (
              <FontAwesomeIcon className="dropdown-category-option-icon" icon={["far", "chevron-down"]} />
            )}
          </button>

          {categoryOptionState.open &&
            option.subOptions?.map((subOption, index) => {
              if (subOption.type === "handler") {
                return (
                  <React.Fragment key={index}>
                    {renderDropdownOption(subOption, categoryOptionState.subOptions[index], indentLevel + 1)}
                  </React.Fragment>
                );
              } else if (subOption.type === "conditional") {
                return (
                  <React.Fragment key={index}>
                    {renderDropdownOption(subOption, categoryOptionState.subOptions[index], indentLevel + 1)}
                  </React.Fragment>
                );
              } else if (subOption.type === "category") {
                return (
                  <React.Fragment key={index}>
                    {renderDropdownOption(subOption, categoryOptionState.subOptions[index], indentLevel + 1)}
                  </React.Fragment>
                );
              }
            })}
        </div>
      );
    }
  }

  return (
    <div
      ref={multipleActionDropdownState.dropdownContainerRef}
      className="multiple-action-dropdown-container"
      onClick={handleDropdownContainerClick}
    >
      {children}

      <div
        className={classNames("multiple-action-dropdown", {
          "multiple-action-dropdown-left-align": alignment === "left",
          "multiple-action-dropdown-right-align": alignment === "right",
        })}
        style={{
          display: multipleActionDropdownState.isDropdownOpen ? "block" : "none",
        }}
        onClick={e => e.stopPropagation()}
      >
        <div className="multiple-action-dropdown-options">
          {multipleActionDropdownState.optionStates &&
            options?.map((option, index) => {
              return (
                <div key={index}>
                  <div>{renderDropdownOption(option, multipleActionDropdownState.optionStates[index], 1)}</div>

                  {index < options.length - 1 && <hr className="multiple-action-dropdown-option-divider" />}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
