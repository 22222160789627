import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "master-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/client/form/input";

export type FormInputTypes = "input" | "select" | "checkbox" | "text";

export type FormInput = {
  form_id: number;
  id: number;
  label: string;
  required: boolean;
  type: FormInputTypes;
  help_text: string;
  row: number;
  column: number;
  values: string[] | null;
};

interface IFormInputRes<T> {
  status: number;
  data: T | string;
  message?: string;
}

type GetFormInputParams = {
  form_id?: number;
  client_id: number;
};

export type PostFormInputParams = {
  form_id: number;
  label: string;
  help_text: string;
  type: FormInputTypes;
  required?: boolean; // backend defaults to true if not given
};

export type PutFormInputParams = {
  input_id: number;
  label?: string;
  help_text?: string;
  type?: FormInputTypes;
  values?: string[];
  required?: boolean;
  client_id: number;
};

type DeleteFormInputParams = { id: number; client_id: number };

export type PutFormInputPosition = {
  id: number;
  row: number;
  column: number;
};

type PutFormInputPositionParams = {
  inputs: PutFormInputPosition[];
  client_id: number;
};

// GET Forms
export async function GetFormInput(
  params: GetFormInputParams,
  useGlobalLoader = true,
  token?: CancelToken,
): Promise<IFormInputRes<FormInput[]>> {
  return await APIGet(baseUrl, params, useGlobalLoader, token);
}

// POST Form
export async function PostFormInput(
  params: PostFormInputParams,
  useGlobalLoader = true,
): Promise<IFormInputRes<FormInput>> {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

// PUT Forms
export async function PutFormInput(
  params: PutFormInputParams,
  useGlobalLoader = true,
): Promise<IFormInputRes<FormInput>> {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

// PUT Forms
export async function DeleteFormInput(
  params: DeleteFormInputParams,
  useGlobalLoader = true,
): Promise<IFormInputRes<any>> {
  return await APIDelete(baseUrl, params, useGlobalLoader);
}

export async function PutFormInputPositions(
  params: PutFormInputPositionParams,
  useGlobalLoader = true,
): Promise<IFormInputRes<any>> {
  return await APIPut(baseUrl + "/position", params, useGlobalLoader);
}
