import { APIGet, APIPost, APIPut, APIDelete, StatusCode } from "api/protocols";
import { CancelToken } from "axios";
import { ICartTransaction } from "redux/reducers/models/cart";
import { IOrder } from "redux/reducers/models/order";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/payment/gift-card";

interface IGetGiftCard {
  order_id?: number;
  code?: string;
  customer_id?: number;
  search?: string;
  filters?: Array<Record<string, any>>;
  view_permission?: boolean;
}

interface IPrintGiftCard {
  last4: string;
  id: number;
}

interface IDisableGiftCard {
  id: number;
}

interface IPutGiftCard {
  code: string;
  note?: string;
  customer_id?: number;
}

interface IPutGiftCardEmail {
  gift_card_id: number;
  customer_email: string;
}

interface IGetGiftCardRes {
  status: StatusCode;
  data?: Array<IGiftCard>;
}

export interface IGiftCard {
  admin_id: number;
  balance: number;
  client_id: number;
  code: string;
  currency: string;
  customer_id: number;
  disabled_at: string;
  expires_at: string;
  id: number;
  last4: string;
  line_item_id: number;
  note: string;
  order_id: number;
  pin: string;
  status: string;
}

export interface ITransaction {
  id: number;
  order_id: number;
  order: IOrder;
}

export interface IGiftCardLog {
  admin_user_id: number;
  change: number;
  /** Date string */
  created_at: string;
  /** Date string */
  deleted_at: string | null;
  gift_card_id: number;
  id: number;
  log_description: string;
  log_type: string;
  new_balance: number;
  previous_balance: number;
  source_order_transaction_id: number;
  transaction: ITransaction;
  updated_at: string;
}

export async function GetGiftCardValidate(params: { code: string; reload: number }, useGlobalLoader: boolean) {
  return await APIGet(baseUrl + "/validate", params, useGlobalLoader);
}

export async function GetGiftCard(params: IGetGiftCard, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<IGiftCard[]>(baseUrl, params, useGlobalLoader, token);
}

export async function PrintGiftCard(params: IPrintGiftCard, useGlobalLoader: boolean) {
  return await APIGet(baseUrl + "/print", params, useGlobalLoader);
}

export async function PutDisableGiftCard(params: IDisableGiftCard, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/disable", params, useGlobalLoader);
}

export async function PutGiftCard(params: IPutGiftCard, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

export async function PutGiftCardEmail(params: IPutGiftCardEmail, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/email", params, useGlobalLoader);
}

export async function GetGiftCardHistory(
  params: { gift_card_id: number },
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet<IGiftCardLog[]>(baseUrl + "/log", params, useGlobalLoader, token);
}

export async function PrintGiftCardLog(params: IPrintGiftCard, useGlobalLoader: boolean) {
  return await APIGet(baseUrl + "/statement", params, useGlobalLoader);
}

/**
 * Including parent_gift_card_id in gift_card_ids array will produce a card with mismatched values.
 */
export async function MergeGiftCards(
  params: { parent_gift_card_id: number; gift_card_ids: number[] },
  useGlobalLoader: boolean,
) {
  return await APIPut(baseUrl + "/merge", params, useGlobalLoader);
}

export async function ImportGiftCards(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/import", params, useGlobalLoader);
}
