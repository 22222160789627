import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Page from "components/page/Page";
import { StatusCode } from "api/protocols";
import { IBookingEngineState } from "redux/reducers/bookingEngine";
import { IBookingEngineActions } from "redux/actions/actionTypes";
import { IUIActions } from "redux/actions/ui";
import "./Reservations.scss";
import { GetBookingParticipant } from "api/rpc/2024-04/customer/booking";
import { IBooking } from "redux/reducers/models/teetime";
import { capitalize, convertTime } from "helpers/Helpers";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Callout from "components/callout/Callout";
import { useTranslation } from "react-i18next";
import { Badge } from "components/badge/Badge";
import Card from "components/card/Card";

interface IProps {
  bookingEngineStore: IBookingEngineState;
  bookingEngineActions: IBookingEngineActions;
  uiActions: IUIActions;
}
interface IParams {
  facilityShortName: string;
  bookingEngineHandle: string;
}

interface IState {
  bookings: Array<IBooking>;
  limit: number;
  offset: number;
}

export default function Reservations(props: IProps) {
  const { bookingEngineStore, bookingEngineActions, uiActions } = props;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { facilityShortName, bookingEngineHandle } = useParams<IParams>();
  const [state, setState] = useState<IState>({
    bookings: [],
    limit: 15,
    offset: 0,
  });

  useEffect(() => {
    if (bookingEngineStore?.facilityClientId) {
      void getBookings(state.offset);
    }
  }, [bookingEngineStore?.facilityClientId]);

  async function getBookings(offset: number) {
    const bookingRes = await GetBookingParticipant(
      { client_id: bookingEngineStore?.facilityClientId, extended_booking: true, offset, limit: state.limit },
      true,
    );
    if (bookingRes.status !== StatusCode.OK) {
      uiActions.showError(bookingRes.message);
      return;
    } else if (bookingRes?.data?.length === 0 && offset > 0) {
      const updateOffset = offset - state.limit;
      setState(prevState => ({ ...prevState, offset: updateOffset > 0 ? updateOffset : 0 }));
      return;
    }

    setState(prevState => ({ ...prevState, bookings: bookingRes.data }));
    window.scrollTo({ top: 0, left: 0 });
  }

  function viewBooking(booking: IBooking) {
    history.push(`/booking-engine/${facilityShortName}/${bookingEngineHandle}/profile/reservations/${booking.id}`);
  }

  async function handlePreviousBookingList() {
    const newOffSet = state.offset - state.limit;
    if (newOffSet >= 0) {
      setState(prevState => ({ ...prevState, offset: newOffSet }));
      await getBookings(newOffSet);
    }
  }

  async function handleNextBookingList() {
    const newOffSet = state.offset + state.limit;

    setState(prevState => ({ ...prevState, offset: newOffSet }));
    await getBookings(newOffSet);
  }

  return (
    <div className="booking-engine-reservations">
      <Page full title={t("guest.customer.customer_nav_menu.reservations.010")}>
        <>
          {state.bookings?.length > 0 ? (
            <>
              <Card>
                <Card.Section table="true">
                  <table className="ui-table ui-table-clickable reservations-booking-table">
                    <thead>
                      <tr>
                        <th>{t("guest.customer.customer_nav_menu.reservations.001")}</th>
                        <th>{t("guest.customer.customer_nav_menu.reservations.002")}</th>
                        <th>{t("guest.customer.customer_nav_menu.reservations.003")}</th>
                        <th>{t("guest.customer.customer_nav_menu.reservations.004")}</th>
                        <th>{t("guest.customer.customer_nav_menu.reservations.005")}</th>
                        <th>{t("guest.customer.customer_nav_menu.reservations.006")}</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody className="reservations-table-body">
                      {state.bookings?.map((booking, bookingIndex) => {
                        return (
                          <tr key={bookingIndex} onClick={() => viewBooking(booking?.booking)}>
                            <td>{moment(`${booking?.tee_time?.date}`).format("LL")}</td>
                            <td>
                              {booking?.tee_time?.course?.long_name === booking?.tee_time?.facility?.long_name ? (
                                <p className="reservations-booking-table-data-text">
                                  {booking?.tee_time?.facility?.long_name}
                                </p>
                              ) : (
                                <p className="reservations-booking-table-data-text">
                                  {" "}
                                  {booking?.tee_time?.facility?.long_name} {booking?.tee_time?.course?.long_name}
                                </p>
                              )}
                            </td>
                            <td>{booking?.holes}</td>
                            <td>{booking?.quantity}</td>
                            <td>{booking?.tee_time?.start_time ? convertTime(booking?.tee_time?.start_time) : ""}</td>
                            <td>{capitalize(booking?.status)}</td>
                            <td>
                              {booking?.booking?.order_id ? (
                                <Badge size="small" type="success">
                                  {t("guest.customer.customer_nav_menu.reservations.011")}
                                </Badge>
                              ) : null}
                            </td>
                            <td>
                              {booking?.booking?.booking_fee_order_id ? (
                                <Badge size="small" type="success">
                                  {t("guest.customer.customer_nav_menu.reservations.012")}
                                </Badge>
                              ) : null}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Card.Section>
              </Card>
              <div className="reservations-nav-button-group">
                <button className="reservations-nav-buttons" onClick={handlePreviousBookingList}>
                  <FontAwesomeIcon icon={"chevron-left"} /> {t("guest.customer.customer_nav_menu.reservations.007")}
                </button>
                <button className="reservations-nav-buttons" onClick={handleNextBookingList}>
                  {t("guest.customer.customer_nav_menu.reservations.008")} <FontAwesomeIcon icon={"chevron-right"} />
                </button>
              </div>
            </>
          ) : (
            <Callout title={t("guest.customer.customer_nav_menu.reservations.009")} content="" type="info" />
          )}
        </>
      </Page>
    </div>
  );
}
