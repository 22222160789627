import React, { useEffect, useState } from "react";
import "./reservationsLogin.scss";
import { useHistory, useParams } from "react-router";
import { UserLogin } from "api/rpc";
import { StatusCode } from "api/protocols";
import Input from "components/form/input";
import { ButtonNew } from "components/buttonNew";
import { IUIActions } from "redux/actions/ui";
import { IReservationActions } from "redux/actions/reservations";
import { IReservationState } from "redux/reducers/reservations";

interface ILoginState {
  email: string;
  password: string;
}

interface IProps {
  uiActions: IUIActions;
  reservationActions: IReservationActions;
  reservationStore: IReservationState;
}
interface IParams {
  facilityShortName: string;
  moduleHandle: string;
}

export default function ReservationsLogin(props: IProps) {
  const history = useHistory();
  const { facilityShortName, moduleHandle } = useParams<IParams>();
  const { uiActions, reservationActions, reservationStore } = props;
  const [loginState, setLoginState] = useState<ILoginState>({
    email: "",
    password: "",
  });

  const queryString = window.location.search;

  const locationId = Number(new URLSearchParams(queryString).get("location_id"));
  const date = new URLSearchParams(queryString).get("date");
  const startTime = new URLSearchParams(queryString).get("start_time");

  function handleEnterKeydown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      void handleLogin();
    }
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;
    setLoginState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleRegistration() {
    if (!locationId || !date || !startTime) {
      history.push(`/${facilityShortName}/${moduleHandle}/register`);
    } else {
      history.push(
        `/${facilityShortName}/${moduleHandle}/register?start_time=${startTime}&date=${date}&location_id=${locationId}`,
      );
    }
  }

  async function handleLogin() {
    const params = {
      email: loginState.email,
      password: loginState.password,
    };

    const loginRes = await UserLogin(params, true);

    if (loginRes.status !== StatusCode.OK) {
      uiActions.showError("Invalid Credentials, please try again");
      return;
    }

    reservationActions.update({ active_user: loginRes?.data?.user });
    uiActions.showSuccess("Logged in successfully");

    if (!locationId || !date || !startTime) {
      history.push(`/${facilityShortName}/${moduleHandle}`);
    } else {
      history.push(
        `/${facilityShortName}/${moduleHandle}/booking?start_time=${startTime}&date=${date}&location_id=${locationId}`,
      );
    }
  }

  function handleForgotPassword() {
    window.open(`/${facilityShortName}/${moduleHandle}/forgot`);
  }

  return (
    <>
      <div className="reservations-login">
        <div className="reservations-login-header-container">
          <div className="reservations-login-header-container-centered">
            <div className="reservations-login-header-container-inner">
              <img src="../../../public/images/Tee-On.png" alt="Tee-On Logo" />
            </div>
          </div>
        </div>

        <div className="reservations-login-container">
          <div className="reservations-login-container-inner">
            <div className="reservations-login-container-group">
              <div className="reservations-login-text-group">
                <p className="sign-in-text">Sign In</p>
                <br />
                <p className="sign-in-info">Please sign into an account to continue the reservation</p>
              </div>

              <div className="reservations-login-input-group">
                <label className="reservations-login-input-label" htmlFor="username">
                  Email Address
                </label>
                <Input
                  value={loginState.email}
                  onChange={handleInputChange}
                  type="email"
                  labelHidden
                  onKeyDown={handleEnterKeydown}
                  id="email"
                />
              </div>

              <div className="reservations-login-input-group">
                <label className="reservations-login-input-label" htmlFor="password">
                  Password
                </label>
                <Input
                  value={loginState.password}
                  onChange={handleInputChange}
                  type="password"
                  labelHidden
                  onKeyDown={handleEnterKeydown}
                  id="password"
                />
              </div>

              <div className="reservations-login-forgot-password">
                <ButtonNew type="text" onClick={handleForgotPassword}>
                  Forgot Password?
                </ButtonNew>
              </div>
            </div>

            <div>
              <ButtonNew type="primary" block onClick={handleLogin} className="reservations-login-button">
                SIGN IN
              </ButtonNew>
            </div>
            <div>
              <ButtonNew type="default" block onClick={handleRegistration}>
                REGISTER
              </ButtonNew>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
