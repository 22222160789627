import React, { useState, useContext, useEffect } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomDropdownContext, ICustomerDropdownState } from "./CustomDropdown";
import "./customDropdown.scss";

interface IOptionProps {
  value: string | number;
  children: React.ReactNode;
}

interface IDropdownStore {
  state: ICustomerDropdownState;
  updateState: (newState: Partial<ICustomerDropdownState>) => void;
  onChange: (value: number | string) => void;
}

export default function CustomDropdownOption(props: IOptionProps) {
  const { children, value } = props;

  const { state, updateState, onChange } = useContext<IDropdownStore>(CustomDropdownContext);

  function handleClick(e: React.MouseEvent<HTMLLIElement>) {
    e.stopPropagation();
    void onChange(value);
    updateState({ value: value, dropDownOpen: false });
  }

  return (
    <li onClick={e => handleClick(e)} className="custom-dropdown-option">
      {children}
      {state.value === value && <FontAwesomeIcon className="custom-dropdown-option-check" icon={["far", "check"]} />}
    </li>
  );
}
