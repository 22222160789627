import { IUser, IUserPermissions } from "./models/user";
import { AuthActionTypes } from "redux/actions/actionTypes";

export interface IAuthState {
  user: IUser | Partial<IUser>;
  initialized: boolean;
  loaded: boolean;
}

export interface IAuthAction {
  type: AuthActionTypes;
  payload?: any;
}

const initialState: IAuthState = {
  user: {},
  initialized: false,
  loaded: false,
};

export default function AuthReducer(state = initialState, action: IAuthAction) {
  switch (action.type) {
    case AuthActionTypes.USER_LOGIN:
      return {
        ...state,
        user: action.payload as IUser,
      };
    case AuthActionTypes.USER_LOGOUT:
      return {
        ...state,
        user: {},
        initialized: false,
      };

    case AuthActionTypes.USER_INITIALIZE:
      return {
        ...state,
        initialized: action.payload as boolean,
        loaded: true,
      };

    case AuthActionTypes.USER_PERMISSIONS_CHANGE:
      return {
        ...state,
        user: { ...state.user, permissions: { ...state.user.permissions, ...(action.payload as IUserPermissions) } },
      };

    default:
      return state;
  }
}
