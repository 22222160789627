import { APIGet, APIPut } from "api/protocols";
import { IOrder } from "pages/guest/models/order";

const apiVersion = "2022-09";
const adminType = "guest";
const baseOrderUrl = "/" + apiVersion + "/" + adminType + "/order";

interface IGetOrder {
  token: string;
}

interface IGetOrderResponse {
  status: number;
  data: IOrder[];
  message: string;
}

export interface IGiftCard {
  product_id: number;
  cart_line_item_id: number;
  code: string;
  pin: string;
  random: boolean;
  balance: number;
  reload: number;
}

interface IPutCompleteOrder {
  token: string;
  gift_cards?: Array<IGiftCard>;
}

interface ICompleteOrder {
  id: number;
  client_id: number;
  long_name: string;
  full_name: string;
  short_name: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  province_name: string;
  province_code: string;
  country_name: string;
  country_code: string;
  postal: string;
  phone: string;
  enabled_currencies: string;
  customer_email: string;
  primary_locale: string;
  logo_source: string;
  emblem_source_url: string;
}

interface IPutCompleteOrderResponse {
  data: ICompleteOrder;
  status: number;
}

export async function GetOrder(params: IGetOrder, useGlobalLoader: boolean): Promise<IGetOrderResponse> {
  return await APIGet(baseOrderUrl, params, useGlobalLoader);
}

export async function PutCompleteOrder(
  params: IPutCompleteOrder,
  useGlobalLoader: boolean,
): Promise<IPutCompleteOrderResponse> {
  return await APIPut(baseOrderUrl + "/complete", params, useGlobalLoader);
}
