import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { TLeagueTeam } from "redux/reducers/models/league";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseLeagueUrl = "/" + apiVersion + "/" + adminType + "/league/scoring";

export type TGetLeagueTeams = {
  id?: string | number;
  league_id?: string | number;
  league_division_id?: string | number;
};

export type TPostLeagueTeams = {
  league_id: string | number;
  league_division_id: string | number;
  name: string;
};
export type TPutLeagueTeams = {
  id: string | number;
  league_division_id?: string | number;
  name?: string;
};

export type TPostTeamParticipant = {
  team_id: string | number;
  /** Pass in all participant ids, even if already included */
  participant_ids: Array<string | number>;
};

//GET League Teams
export async function GetLeagueTeams(params: TGetLeagueTeams, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<TLeagueTeam[]>(baseLeagueUrl + "/team", params, useGlobalLoader, token);
}

export async function PostLeagueTeam(params: TPostLeagueTeams, useGlobalLoader: boolean) {
  return await APIPost(baseLeagueUrl + "/team", params, useGlobalLoader);
}

export async function PutLeagueTeams(params: TPutLeagueTeams, useGlobalLoader: boolean) {
  return await APIPut(baseLeagueUrl + "/team", params, useGlobalLoader);
}

export async function DeleteLeagueTeam(params: { id: string | number }, useGlobalLoader: boolean) {
  return await APIDelete(baseLeagueUrl + "/team", params, useGlobalLoader);
}

//GET Team Participant
export async function GetLeagueTeamPlayers(params: { team_id: string | number }, useGlobalLoader: boolean) {
  return await APIGet(baseLeagueUrl + "/team/participant", params, useGlobalLoader);
}

//PUT Team Participant
export async function PostLeagueTeamPlayers(params: TPostTeamParticipant, useGlobalLoader: boolean) {
  return await APIPost(baseLeagueUrl + "/team/participant", params, useGlobalLoader);
}

export async function GetAllLeagueTeamParticipants(
  params: { league_id: string | number; division_id: string | number },
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet<{ league_team_id: number; league_participant_id: number }[]>(
    baseLeagueUrl + "/team/participant/all",
    params,
    useGlobalLoader,
    token,
  );
}

export async function GetLeagueTeamScores(
  params: { scoresheet_id: string | number; format_id: string | number },
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseLeagueUrl + "/scoresheet/team", params, useGlobalLoader, token);
}

//PUT Team Participant
// export async function DeleteLeagueTeamParticipant(
//   params: { team_id: string | number; participant_id: string | number },
//   useGlobalLoader: boolean,
// ) {
//   return await APIDelete(baseLeagueUrl + "/team/participant", params, useGlobalLoader);
// }
