import React, { createContext, useContext, useState, createElement } from "react";
import { ConfigContext } from "../config-provider";
import classNames from "classnames";
import "./style.scss";

export interface IGeneratorProps {
  suffixCls: string;
  tagName: "header" | "footer" | "main" | "section";
  displayName: string;
}

export interface IBasicProps extends React.HTMLAttributes<HTMLDivElement> {
  prefixCls?: string;
  hasSider?: boolean;
}

export interface ILayoutContextProps {
  siderHook: {
    addSider: (id: string) => void;
    removeSider: (id: string) => void;
  };
}
export const LayoutContext = createContext<ILayoutContextProps>({
  siderHook: {
    addSider: () => null,
    removeSider: () => null,
  },
});

interface IBasicPropsWithTagName extends IBasicProps {
  tagName: "header" | "footer" | "main" | "section";
}

function generator({ suffixCls, tagName, displayName }: IGeneratorProps) {
  return (BasicComponent: any) => {
    const Adapter: React.FC<IBasicProps> = props => {
      const { getPrefixCls } = useContext(ConfigContext);
      const { prefixCls: customizePrefixCls } = props;
      const prefixCls = getPrefixCls(suffixCls, customizePrefixCls);

      return <BasicComponent prefixCls={prefixCls} tagName={tagName} {...props} />;
    };
    Adapter.displayName = displayName;
    return Adapter;
  };
}

const Basic = (props: IBasicPropsWithTagName) => {
  const { prefixCls, className, children, tagName, ...others } = props;
  const classString = classNames(prefixCls, className);
  return createElement(tagName, { className: classString, ...others }, children);
};

const BasicLayout: React.FC<IBasicPropsWithTagName> = props => {
  const { direction } = useContext(ConfigContext);

  const [siders, setSiders] = useState<string[]>([]);

  const { prefixCls, className, children, hasSider, tagName: Tag, ...others } = props;

  const classString = classNames(
    prefixCls,
    {
      [`${prefixCls}-has-sider`]: typeof hasSider === "boolean" ? hasSider : siders.length > 0,
      [`${prefixCls}-rtl`]: direction === "rtl",
    },
    className,
  );

  return (
    <LayoutContext.Provider
      value={{
        siderHook: {
          addSider: (id: string) => {
            setSiders(prev => [...prev, id]);
          },
          removeSider: (id: string) => {
            setSiders(prev => prev.filter(currentId => currentId !== id));
          },
        },
      }}
    >
      <Tag className={classString} {...others}>
        {children}
      </Tag>
    </LayoutContext.Provider>
  );
};

const Layout = generator({
  suffixCls: "layout",
  tagName: "section",
  displayName: "Layout",
})(BasicLayout);

const Header = generator({
  suffixCls: "layout-header",
  tagName: "header",
  displayName: "Header",
})(Basic);

const Footer = generator({
  suffixCls: "layout-footer",
  tagName: "footer",
  displayName: "Footer",
})(Basic);

const Content = generator({
  suffixCls: "layout-content",
  tagName: "main",
  displayName: "Content",
})(Basic);

export { Header, Footer, Content };

export default Layout;
