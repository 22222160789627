import { APIGet, APIPost, APIPut, APIDelete, StatusCode, IAPIResponse } from "api/protocols";
import { CancelToken } from "axios";
const apiVersion = "2024-04";
const baseCustomerUrl = "/" + apiVersion + "/customer";

interface IPutCartParams {
  cart_token?: string;
  holes: number;
  quantity: number;
  power_cart_quantity: number;
  tee_time_id: number;
  booking_engine_id?: number;
  customer_ids?: Array<number>;
}

interface IGetCart {
  id?: number;
  facility_id?: number;
  token?: string;
}

export async function GetCart(params: IGetCart, useGlobalLoader: boolean) {
  return await APIGet(baseCustomerUrl + "/cart", params, useGlobalLoader);
}

export async function PutCart(params: IPutCartParams, useGlobalLoader: boolean, cancelToken?: CancelToken) {
  return await APIPut(baseCustomerUrl + "/tee-time/booking/cart", params, useGlobalLoader, cancelToken);
}
