import React, { useState } from "react";

import { formatDate } from "helpers/Helpers";

import CalendarHeader, { TViewState } from "./display/header/CalendarHeader";
import { CalendarMonth } from "./display/CalendarMonth";
import { CalendarAgenda } from "./display/CalendarAgenda";
import { CalendarWeek } from "./display/CalendarWeek";
import Spin from "components/spin/spin";

import "./calendar.scss";

type TCalendar = {
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
  loading?: boolean;
  /**
   * @param {number} dateOfMonth number associated with a given grid cell.
   * @param {string} formattedDate formatted date string of entire date value.  Used to sync data to individual date cell.
   * @returns {JSX.Element} JSX will be rendered at the dateOfMonth cell within the grid.
   * @example
   * renderGridItem={(dateOfmonth, formattedDate) => {
   *   const data = state.filter(val => formatDate(val.date) === formattedDate);
   *   return (
   *       <div key={dateOfMonth} className='grid-item'>
   *           { ... Custom data found at specific date ... }
   *       </div>
   *   )
   * }}
   */
  renderGridItem: (dateOfMonth: number, formattedDate: string) => JSX.Element;
  //   renderAgendaItem?: (dateOfMonth: number, formattedDate: string) => JSX.Element;
  //   renderWeekItem?: (dateOfMonth: number, formattedDate: string) => JSX.Element;
};

export default function Calendar(props: TCalendar) {
  const { selectedDate, setSelectedDate, loading, renderGridItem /* renderAgendaItem, */ /* renderWeekItem */ } = props;
  const [viewState, setViewState] = useState<TViewState>("month");

  return (
    <div className="ui-calendar" style={{ position: "relative", overflow: "hidden", backgroundColor: "white" }}>
      <CalendarHeader
        selectedDate={selectedDate}
        onDateChange={date => setSelectedDate(date)}
        viewState={viewState}
        setViewState={setViewState}
      />

      <CalendarData
        viewState={loading && loading ? undefined : viewState}
        selectedDate={selectedDate}
        renderGridItem={i => renderGridItem(i, dateFromDateOfMonth(selectedDate, i))}
        // renderAgendaItem={i => renderAgendaItem(i, dateFromDateOfMonth(selectedDate, i))}
        // renderWeekItem={i => renderWeekItem(i, dateFromDateOfMonth(selectedDate, i))}
      />
    </div>
  );
}

/**
 * Handles what view to render out
 * ** props.viewState currently is not changing from 'month' **
 */
function CalendarData(props: {
  selectedDate: Date;
  viewState: "week" | "month" | "agenda" | undefined | null;
  renderGridItem: (dayOfMonth: number) => JSX.Element;
  /** TODO: Add agenda layout */
  renderAgendaItem?: (dayOfMonth: number) => JSX.Element;
  /** TODO: Add week layout */
  renderWeekItem?: (dayOfMonth: number) => JSX.Element;
}) {
  if (props.viewState === undefined) {
    return (
      <div className="ui-calendar-spinner">
        <Spin />
      </div>
    );
  }
  if (props.viewState === null) {
    return <></>;
  }
  if (props.viewState === "agenda") {
    return <CalendarAgenda selectedDate={props.selectedDate} renderAgendaItem={props.renderAgendaItem} />;
  }
  if (props.viewState === "month") {
    return <CalendarMonth selectedDate={props.selectedDate} renderGridItem={props.renderGridItem} />;
  }
  if (props.viewState === "week") {
    return <CalendarWeek selectedDate={props.selectedDate} renderWeekItem={props.renderWeekItem} />;
  }
  return null;
}

/** Generate a formatted date given a selectedDate & date of the month. */
export function dateFromDateOfMonth(date: Date, dateOfMonth: number) {
  const year = date.getFullYear();
  const month = date.getMonth();

  const returnDateObj = new Date(year, month, dateOfMonth);
  return formatDate(returnDateObj);
}
