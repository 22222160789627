import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseLeagueUrl = "/" + apiVersion + "/" + adminType + "/league/scoring/round";

interface IGetRound {
  scoresheet_id: number;
  round_id: number;
  league_id: number;
  id: number;
}

//GET League Round
export async function GetRound(params: Partial<IGetRound>, useGlobalLoader: boolean) {
  return await APIGet(baseLeagueUrl, params, useGlobalLoader);
}

interface IPostRound {
  scoresheet_id: number;
  customer_id: number;
  date_played: Date;
  facility_id: number;

  tee_deck_id: number;
  course_id: number;
  division_front_id: number;
  division_back_id: number;
  type: string;
  holes: number;
  points_awarded: number;
  format: string;
  token: string;
}

// POST League Round
export async function PostRound(params: Partial<IPostRound>, useGlobalLoader: boolean) {
  return await APIPost(baseLeagueUrl, params, useGlobalLoader);
}

export interface IPutRound {
  id: any;
  round_id: number;
  tee_deck_id: number;
  course_id: number;
  division_front_id: number;
  division_back_id: number;
  hole_scores: any;
  round_handicap: number;
  attestor_id: number;
  attested: boolean;
  played_alone: boolean;
  submitted: boolean;
  points_bonus: number;
  points_participation: number;
  // league_id: number;
}

//PUT Round
export async function PutRound(params: Partial<IPutRound>, useGlobalLoader: boolean) {
  return await APIPut(baseLeagueUrl, params, useGlobalLoader);
}

interface IDeleteRound {
  round_id: number;
}

//DELETE Round
export async function DeleteRound(params: IDeleteRound, useGlobalLoader: boolean) {
  return await APIDelete(baseLeagueUrl, params, useGlobalLoader);
}

interface IPrintScoresheet {
  format_id?: number;
  scoresheet_id: number;
}

// GET Print league scoresheet
// Print a league scoresheet
export async function PrintScoresheet(params: IPrintScoresheet, useGlobalLoader: boolean) {
  return await APIGet(baseLeagueUrl + "/print", params, useGlobalLoader);
}

interface IGetRingerBoard {
  league_id: number;
}

// GET Ringer board
export async function GetRingerBoard(params: IGetRingerBoard, useGlobalLoader: boolean) {
  return await APIGet(baseLeagueUrl + "/ringer-board", params, useGlobalLoader);
}
