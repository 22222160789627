import React, { useEffect, useState } from "react";
import "./teeSheetBookingGraph.scss";
import { ISlot, ITeeTime } from "redux/reducers/models/teetime";
import { groupBy } from "lodash";

interface IGraphTeeTime {
  slotsBooked: number;
  blockedType: string;
  isEvent: boolean;
}

interface IGraphTeeTimeHour {
  hour: number;
  teeTimes: IGraphTeeTime[];
}

interface ITeeSheetBookingGraphProps {
  teeTimes: ITeeTime[];
  divisionId: number;
  courseId: number;
}

export default function TeeSheetBookingGraph(props: ITeeSheetBookingGraphProps) {
  const SINGLE_BAR_HEIGHT = 9;
  const MINIMUM_PLOT_AREA_HEIGHT = SINGLE_BAR_HEIGHT * 4;

  const { teeTimes, divisionId, courseId } = props;

  const [graphTeeTimeHours, setGraphTeeTimeHours] = useState<IGraphTeeTimeHour[]>([]);

  useEffect(() => {
    if (teeTimes?.length > 0 && divisionId && courseId && divisionId !== -1) {
      setGraphTeeTimeHoursFromTeeTimes();
    } else {
      setGraphTeeTimeHours([]);
    }
  }, [teeTimes, divisionId, courseId]);

  function setGraphTeeTimeHoursFromTeeTimes() {
    const groupedTeeTimeHours = groupBy(teeTimes, teeTime => {
      const splitStartTime = teeTime.start_time?.split(":");
      const hour = Number(splitStartTime?.[0]);

      return hour;
    });

    const countBookedSlots = (slots: ISlot[]) => {
      return slots.reduce((accumulator, currentSlot) => accumulator + (currentSlot.status === "booked" ? 1 : 0), 0);
    };

    const updatedGraphTeeTimeHours: IGraphTeeTimeHour[] = Object.keys(groupedTeeTimeHours).map(hour => ({
      hour: Number(hour),
      teeTimes: groupedTeeTimeHours[hour]
        ?.filter(teeTime => teeTime.division_id === divisionId && teeTime.course_id === courseId)
        ?.map(teeTime => ({
          slotsBooked: countBookedSlots(teeTime.slots),
          blockedType: teeTime.blocked_type,
          isEvent: !!teeTime.tournament_id || !!teeTime.league_id,
        })),
    }));

    const validGraphTeeTimeHours = updatedGraphTeeTimeHours.filter(
      graphTeeTimeHour => graphTeeTimeHour.teeTimes?.length > 0,
    );

    setGraphTeeTimeHours(validGraphTeeTimeHours);
  }

  function getHourLabelText(hour: number) {
    if (hour <= 12) {
      return String(hour);
    } else if (hour === 0) {
      return "12";
    } else {
      return String(hour % 12);
    }
  }

  function getGraphBarStyles(teeTime: IGraphTeeTime) {
    if (teeTime.blockedType === "blocked") {
      return {
        height: "100%",
        backgroundColor: "grey",
      };
    } else {
      let barColor = "red";

      if (teeTime.isEvent) {
        barColor = "blue";
      } else if (teeTime.blockedType === "crossover") {
        barColor = "pink";
      }

      return {
        height: `${teeTime.slotsBooked * SINGLE_BAR_HEIGHT}px`,
        ...(teeTime.slotsBooked === 0 ? { border: "none" } : {}),
        backgroundColor: barColor,
      };
    }
  }

  return (
    <div
      className="tee-sheet-booking-graph-container"
      style={{
        display: (!graphTeeTimeHours || graphTeeTimeHours.length === 0) && "none",
      }}
    >
      <div className="tee-sheet-booking-graph">
        <div className="tee-sheet-booking-graph-plot-area" style={{ minHeight: `${MINIMUM_PLOT_AREA_HEIGHT}px` }}>
          {graphTeeTimeHours?.map(graphHour => {
            return (
              <div key={graphHour.hour} className="tee-sheet-booking-graph-hour">
                {graphHour.teeTimes.map((teeTime, index) => {
                  return <div key={index} className="tee-sheet-booking-graph-bar" style={getGraphBarStyles(teeTime)} />;
                })}
                <div className="tee-sheet-booking-graph-hour-label">{getHourLabelText(graphHour.hour)}</div>
                <div className="tee-sheet-booking-graph-hour-tick" />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
