import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { useGlobalFilter } from "react-table";
import { ILeagueRegistrationFee } from "redux/reducers/models/league";

const apiVersion = "2021-01";
const adminType = "facility-admin";
const baseLeagueUrl = "/" + apiVersion + "/" + adminType + "/league";
const updatedApiVersion = "2022-09";

const updatedBaseLeagueUrl = "/" + updatedApiVersion + "/" + adminType + "/league";

// GET League
// Retrieve a list of leagues
export async function GetLeague(params: any, useGlobalLoader: boolean) {
  return await APIGet(updatedBaseLeagueUrl, params, useGlobalLoader);
}

//GET Players
//Retrieve a list of players in a specific league
export async function GetLeaguePlayers(params: any, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseLeagueUrl + "/participant", params, useGlobalLoader, token);
}

//GET League Scoresheets
//Retrieve a list of league scoresheets
export async function GetLeagueScoresheets(params: any, useGlobalLoader: boolean) {
  return await APIGet(updatedBaseLeagueUrl + "/scoring/scoresheet", params, useGlobalLoader);
}

//PUT League Scoresheets
//Update league scoresheet
export async function UpdateLeagueScoresheet(params: any, useGlobalLoader: boolean) {
  return await APIPut(updatedBaseLeagueUrl + "/scoring/scoresheet", params, useGlobalLoader);
}

//POST League Scoresheets
//Create a new league scoresheet
export async function PostLeagueScoresheet(params: any, useGlobalLoader: boolean) {
  return await APIPost(updatedBaseLeagueUrl + "/scoring/scoresheet", params, useGlobalLoader);
}

//POST New Player
//Add new player to leage
export async function AddPlayerToLeague(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseLeagueUrl + "/participant", params, useGlobalLoader);
}

// POST League
// Create a new league
export async function PostLeague(params: any, useGlobalLoader: boolean) {
  return await APIPost(updatedBaseLeagueUrl, params, useGlobalLoader);
}

// PUT League
// Update an existing league
export async function PutLeague(params: any, useGlobalLoader: boolean) {
  return await APIPut(updatedBaseLeagueUrl, params, useGlobalLoader);
}

interface IGetLeagueRegistrationFees {
  league_id: number;
}

interface IGetLeagueRegistrationFeesResponse {
  data: ILeagueRegistrationFee[];
  status: number;
}

export async function GetLeagueRegistrationFees(
  params: IGetLeagueRegistrationFees,
  useGlobalLoader: boolean,
): Promise<IGetLeagueRegistrationFeesResponse> {
  return await APIGet(updatedBaseLeagueUrl + "/registration-fee", params, useGlobalLoader);
}

interface IPostLeagueRegistrationFee {
  variant_id: number;
  league_id: number;
  players: number;
  description: string;
}

interface IPostLeagueRegistrationFeeResponse {
  data: ILeagueRegistrationFee;
  status: number;
}

export async function PostLeagueRegistrationFee(
  params: IPostLeagueRegistrationFee,
  useGlobalLoader: boolean,
): Promise<IPostLeagueRegistrationFeeResponse> {
  return await APIPost(updatedBaseLeagueUrl + "/registration-fee", params, useGlobalLoader);
}

interface IDeleteLeagueRegistrationFee {
  registration_fee_id: number;
}

export async function DeleteLeagueRegistrationFee(params: IDeleteLeagueRegistrationFee, useGlobalLoader: boolean) {
  return await APIDelete(updatedBaseLeagueUrl + "/registration-fee", params, useGlobalLoader);
}

// Post League Teams
interface IPostLeagueTeamRes {
  data: IPostLeagueTeam;
  status: number;
}
interface IPostLeagueTeam {
  league_id: number;
  name: string;
}

export async function PostLeagueTeamNew(
  params: IPostLeagueTeam,
  useGlobalLoader: boolean,
): Promise<IPostLeagueTeamRes> {
  return await APIPost(updatedBaseLeagueUrl + "/scoring/team", params, useGlobalLoader);
}

// POST League Flights
interface IPostLeagueFlightRes {
  data: IPostLeagueFlight;
  status: number;
}

interface IPostLeagueFlight {
  league_id: number;
  name: string;
}

export async function PostLeagueFlight(
  params: IPostLeagueFlight,
  useGlobalLoader: boolean,
): Promise<IPostLeagueFlightRes> {
  return await APIPost(updatedBaseLeagueUrl + "/scoring/flight", params, useGlobalLoader);
}

//GET League Teams
export async function GetLeagueTeams(params: any, useGlobalLoader: boolean) {
  return await APIGet(updatedBaseLeagueUrl + "/scoring/team", params, useGlobalLoader);
}

//GET League Flights
export async function GetLeagueFlights(params: any, useGlobalLoader: boolean) {
  return await APIGet(updatedBaseLeagueUrl + "/scoring/flight", params, useGlobalLoader);
}
