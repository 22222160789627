import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { TFacilityForm, TVariantForm } from "redux/reducers/models/facility";

const apiVersion = "2024-04";
const adminType = "master-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/client/form";

type TGetFacilityForm = {
  id?: string | number;
  variant_ids?: Array<number>;
  client_id: number;
};

type TPostFacilityForm = {
  title: string;
};

type TPutFacilityForm = {
  id: string | number;
  title?: string;
};

type TPutVariantForm = {
  variant_id: string | number;
  form_id: string | number;
};

export type PostFacilityFormParams = {
  title: string;
  client_id: number;
};

export type PutFacilityFormParams = {
  id: number;
  title?: string;
  client_id: number;
};

// GET Forms
export async function GetFacilityForm(params: TGetFacilityForm, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<TFacilityForm[]>(baseUrl, params, useGlobalLoader, token);
}

// POST Form
export async function PostFacilityForm(params: TPostFacilityForm, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

// PUT Forms
export async function PutFacilityForm(params: TPutFacilityForm, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

export async function AddFormToVariant(params: TPutVariantForm, useGlobalLoader: true) {
  return await APIPut(baseUrl + "/variant", params, useGlobalLoader);
}
