import React, { useLayoutEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Markdown from "components/markdown/Markdown";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./textEditor.scss";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

interface ITextEditorProps {
  markdownText: string;
  markdownTextOnChange: (arg0: string) => void;
  label?: string;
  readOnly?: boolean;
  error?: boolean;
}

interface ITextEditor {
  writeOptionSelected: boolean;
  markdownRef: React.MutableRefObject<HTMLTextAreaElement>;
  markdownSelection: { time: number; selectionStart: number; selectionEnd?: number };
}

interface IMarkdownListType {
  type: string;
  syntax: string;
  regex: RegExp;
}

interface IMarkdownListTypes {
  unorderedList: IMarkdownListType;
  orderedList: IMarkdownListType;
}

enum MarkdownHeadingSyntax {
  H1 = "# ",
  H2 = "## ",
  H3 = "### ",
}

export default function TextEditor(props: ITextEditorProps) {
  const [textEditorState, setTextEditorState] = useState<ITextEditor>({
    writeOptionSelected: true,
    markdownRef: useRef<HTMLTextAreaElement>(null),
    markdownSelection: { time: new Date().getTime(), selectionStart: 0 },
  });

  const markdownListTypes: IMarkdownListTypes = {
    unorderedList: { type: "unorderedList", syntax: "- ", regex: /^- / },
    orderedList: { type: "orderedList", syntax: "1. ", regex: /^(\d+)\. / },
  };
  const { t, i18n } = useTranslation();

  function handleMarkdownTextChange(text: string) {
    if (!props.readOnly) {
      props.markdownTextOnChange(text);
    }
  }

  useLayoutEffect(() => {
    if (!props.readOnly) {
      let selectionStart = textEditorState.markdownSelection.selectionStart;
      let selectionEnd =
        textEditorState.markdownSelection.selectionEnd ?? textEditorState.markdownSelection.selectionStart;

      selectionStart = selectionStart < 0 ? 0 : selectionStart;
      selectionEnd = selectionEnd < 0 ? 0 : selectionEnd;

      if (textEditorState.markdownRef?.current) {
        textEditorState.markdownRef.current.selectionStart = selectionStart;
        textEditorState.markdownRef.current.selectionEnd = selectionEnd;
      }
    }
  }, [textEditorState.markdownSelection]);

  function handleViewOptionChange(viewOption: boolean) {
    setTextEditorState(prevState => {
      return { ...prevState, writeOptionSelected: viewOption };
    });
  }

  function handleEditorContentChange(event: any) {
    const markdownText = event?.target?.value;

    if (markdownText === undefined) {
      return;
    }

    handleMarkdownTextChange(markdownText);
  }

  function getStartOfLinePosition(selection: number) {
    const lineNumber = props.markdownText.substring(0, selection).split("\n").length;
    let startOfLinePosition = 0;

    for (let i = 0; i < lineNumber - 1; i++) {
      startOfLinePosition = props.markdownText.indexOf("\n", startOfLinePosition) + 1;
    }

    return startOfLinePosition;
  }

  function getCurrentLineString(selection: number) {
    const startOfLinePosition = getStartOfLinePosition(selection);
    let startOfNextLinePosition = props.markdownText.indexOf("\n", startOfLinePosition);
    if (startOfNextLinePosition === -1) {
      startOfNextLinePosition = props.markdownText.length;
    }
    const currentLineString = props.markdownText.substring(startOfLinePosition, startOfNextLinePosition);
    return currentLineString;
  }

  function getPreviousLineString(selection: number) {
    const startOfLinePosition = getStartOfLinePosition(selection);
    let startOfPreviousLine = startOfLinePosition - 1;

    while (
      props.markdownText[startOfPreviousLine - 1] !== undefined &&
      props.markdownText[startOfPreviousLine - 1] !== "\n"
    ) {
      startOfPreviousLine--;
    }

    const previousLine = props.markdownText.substring(startOfPreviousLine, startOfLinePosition);
    return previousLine;
  }

  function handleEditorContentKeydown(event: any) {
    const key = event?.key;
    const ctrlKeySelected = event?.ctrlKey;

    if (key === undefined) {
      return;
    }

    if (ctrlKeySelected && key === "b") {
      event.preventDefault();
      addBoldText();
    } else if (ctrlKeySelected && key === "i") {
      event.preventDefault();
      addItalicText();
    } else if (ctrlKeySelected && key === "1") {
      event.preventDefault();
      addList(markdownListTypes.unorderedList);
    } else if (ctrlKeySelected && key === "2") {
      event.preventDefault();
      addList(markdownListTypes.orderedList);
    } else if (key === "Enter") {
      const selectionStart = textEditorState?.markdownRef?.current?.selectionStart;
      const startOfLinePosition = getStartOfLinePosition(selectionStart);
      const currentLineString = getCurrentLineString(selectionStart);

      const markdownListTypeValues: IMarkdownListType[] = Object.values(markdownListTypes);
      let markdownListTypeMatched: IMarkdownListType = undefined;

      let orderedNumber: string = undefined;
      let currentOrderedNumberSyntax: string = undefined;
      let nextOrderedNumberSyntax: string = undefined;

      markdownListTypeValues.map((markdownListType: IMarkdownListType) => {
        if (markdownListType.regex.test(currentLineString)) {
          markdownListTypeMatched = markdownListType;
        }
      });

      if (markdownListTypeMatched?.type === "orderedList") {
        orderedNumber = markdownListTypeMatched.regex.exec(currentLineString)[1];
        currentOrderedNumberSyntax = String(parseInt(orderedNumber)) + ". ";
        nextOrderedNumberSyntax = String(parseInt(orderedNumber) + 1) + ". ";
      }

      const currentSyntaxLength = !orderedNumber
        ? markdownListTypeMatched?.syntax?.length
        : currentOrderedNumberSyntax.length;
      const currentLineAfterSyntax = currentLineString.substring(currentSyntaxLength);

      if (markdownListTypeMatched && selectionStart >= startOfLinePosition + currentSyntaxLength) {
        event.preventDefault();

        if (currentLineAfterSyntax.trim() === "") {
          const updatedMarkdownText =
            props.markdownText.substring(0, startOfLinePosition) +
            props.markdownText.substring(startOfLinePosition + currentSyntaxLength);

          handleMarkdownTextChange(updatedMarkdownText);
          setTextEditorState(prevState => {
            return {
              ...prevState,
              markdownSelection: { time: new Date().getTime(), selectionStart: startOfLinePosition },
            };
          });
        } else {
          let updatedMarkdownText = "";

          if (markdownListTypeMatched.type === "unorderedList") {
            updatedMarkdownText =
              props.markdownText.substring(0, selectionStart) +
              "\n" +
              markdownListTypeMatched.syntax +
              props.markdownText.substring(selectionStart);

            handleMarkdownTextChange(updatedMarkdownText);
            setTextEditorState(prevState => {
              return {
                ...prevState,
                markdownSelection: {
                  time: new Date().getTime(),
                  selectionStart: selectionStart + markdownListTypeMatched.syntax.length + 1,
                },
              };
            });
          } else if (markdownListTypeMatched.type === "orderedList") {
            updatedMarkdownText =
              props.markdownText.substring(0, selectionStart) +
              "\n" +
              nextOrderedNumberSyntax +
              props.markdownText.substring(selectionStart);

            handleMarkdownTextChange(updatedMarkdownText);
            setTextEditorState(prevState => {
              return {
                ...prevState,
                markdownSelection: {
                  time: new Date().getTime(),
                  selectionStart: selectionStart + nextOrderedNumberSyntax.length + 1,
                },
              };
            });
          }
        }
      }
    }
  }

  function addHeading(syntax: string) {
    const selectionStart = textEditorState?.markdownRef?.current?.selectionStart;
    const syntaxLength = syntax.length;
    let headingStart = selectionStart;

    if (selectionStart === undefined) {
      return;
    }

    while (
      props.markdownText[headingStart - 1] !== " " &&
      props.markdownText[headingStart - 1] !== undefined &&
      props.markdownText[headingStart - 1] !== "\n"
    ) {
      headingStart--;
    }

    const updatedMarkdownText =
      props.markdownText.substring(0, headingStart) + syntax + props.markdownText.substring(headingStart);

    handleMarkdownTextChange(updatedMarkdownText);
    setTextEditorState(prevState => {
      return {
        ...prevState,
        markdownSelection: {
          time: new Date().getTime(),
          selectionStart: selectionStart + syntaxLength,
        },
      };
    });

    textEditorState.markdownRef.current.focus();
  }

  function addInlineStyle(syntax: string) {
    const selectionStart = textEditorState?.markdownRef?.current?.selectionStart;
    const selectionEnd = textEditorState?.markdownRef?.current?.selectionEnd;
    const syntaxLength = syntax.length;
    let updatedMarkdownText = "";

    if (selectionStart === undefined || selectionEnd === undefined) {
      return;
    }

    if (selectionStart === selectionEnd) {
      let styleStart = selectionStart;
      let styleEnd = selectionStart;

      while (
        props.markdownText[styleStart - 1] !== " " &&
        props.markdownText[styleStart - 1] !== undefined &&
        props.markdownText[styleStart - 1] !== "\n"
      ) {
        styleStart--;
      }
      while (
        props.markdownText[styleEnd] !== " " &&
        props.markdownText[styleEnd] !== undefined &&
        props.markdownText[styleEnd] !== "\n"
      ) {
        styleEnd++;
      }

      const potentialStartingSyntax = props.markdownText.substring(styleStart, styleStart + syntaxLength);
      const potentialEndingSyntax = props.markdownText.substring(styleEnd - syntaxLength, styleEnd);

      if (potentialStartingSyntax === syntax && potentialEndingSyntax === syntax) {
        updatedMarkdownText =
          props.markdownText.substring(0, styleStart) +
          props.markdownText.substring(styleStart + syntaxLength, styleEnd - syntaxLength) +
          props.markdownText.substring(styleEnd);

        handleMarkdownTextChange(updatedMarkdownText);
        setTextEditorState(prevState => {
          return {
            ...prevState,
            markdownSelection: {
              time: new Date().getTime(),
              selectionStart: Math.min(
                styleEnd - syntaxLength * 2,
                Math.max(styleStart, selectionStart - syntaxLength),
              ),
            },
          };
        });
      } else {
        updatedMarkdownText =
          props.markdownText.substring(0, styleStart) +
          syntax +
          props.markdownText.substring(styleStart, styleEnd) +
          syntax +
          props.markdownText.substring(styleEnd);

        handleMarkdownTextChange(updatedMarkdownText);
        setTextEditorState(prevState => {
          return {
            ...prevState,
            markdownSelection: {
              time: new Date().getTime(),
              selectionStart: selectionStart + syntaxLength,
            },
          };
        });
      }
    } else {
      const potentialOuterStartingIndex = selectionStart - syntaxLength;
      const potentialOuterEndingIndex = selectionEnd + syntaxLength;
      const potentialOuterStartingSyntax = props.markdownText.substring(potentialOuterStartingIndex, selectionStart);
      const potentialOuterEndingSyntax = props.markdownText.substring(selectionEnd, potentialOuterEndingIndex);

      const potentialInnerStartingIndex = selectionStart + syntaxLength;
      const potentialInnerEndingIndex = selectionEnd - syntaxLength;
      const potentialInnerStartingSyntax = props.markdownText.substring(selectionStart, potentialInnerStartingIndex);
      const potentialInnerEndingSyntax = props.markdownText.substring(potentialInnerEndingIndex, selectionEnd);

      if (potentialOuterStartingSyntax === syntax && potentialOuterEndingSyntax === syntax) {
        updatedMarkdownText =
          props.markdownText.substring(0, potentialOuterStartingIndex) +
          props.markdownText.substring(selectionStart, selectionEnd) +
          props.markdownText.substring(potentialOuterEndingIndex);

        handleMarkdownTextChange(updatedMarkdownText);
        setTextEditorState(prevState => {
          return {
            ...prevState,
            markdownSelection: {
              time: new Date().getTime(),
              selectionStart: selectionStart - syntaxLength,
              selectionEnd: selectionEnd - syntaxLength,
            },
          };
        });
      } else if (potentialInnerStartingSyntax === syntax && potentialInnerEndingSyntax === syntax) {
        updatedMarkdownText =
          props.markdownText.substring(0, selectionStart) +
          props.markdownText.substring(potentialInnerStartingIndex, potentialInnerEndingIndex) +
          props.markdownText.substring(selectionEnd);

        handleMarkdownTextChange(updatedMarkdownText);
        setTextEditorState(prevState => {
          return {
            ...prevState,
            markdownSelection: {
              time: new Date().getTime(),
              selectionStart: selectionStart,
              selectionEnd: selectionEnd - syntaxLength * 2,
            },
          };
        });
      } else {
        updatedMarkdownText =
          props.markdownText.substring(0, selectionStart) +
          syntax +
          props.markdownText.substring(selectionStart, selectionEnd) +
          syntax +
          props.markdownText.substring(selectionEnd);

        handleMarkdownTextChange(updatedMarkdownText);
        setTextEditorState(prevState => {
          return {
            ...prevState,
            markdownSelection: {
              time: new Date().getTime(),
              selectionStart: selectionStart + syntaxLength,
              selectionEnd: selectionEnd + syntaxLength,
            },
          };
        });
      }
    }

    textEditorState.markdownRef.current.focus();
  }

  function addBoldText() {
    addInlineStyle("**");
  }

  function addItalicText() {
    addInlineStyle("_");
  }

  function addList(listType: IMarkdownListType) {
    const selectionStart = textEditorState?.markdownRef?.current?.selectionStart;
    const syntax = listType.syntax;
    const syntaxLength = syntax.length;
    const markdownListTypeValues: IMarkdownListType[] = Object.values(markdownListTypes);
    let markdownListTypeMatched: IMarkdownListType = undefined;
    let previousLineIsAList = false;
    let updatedMarkdownText = "";

    if (selectionStart === undefined) {
      return;
    }

    const startOfLinePosition = getStartOfLinePosition(selectionStart);
    const currentLineString = getCurrentLineString(selectionStart);
    const previousLineString = getPreviousLineString(selectionStart);

    markdownListTypeValues.map((markdownListType: IMarkdownListType) => {
      if (markdownListType.regex.test(currentLineString)) {
        markdownListTypeMatched = markdownListType;
      }
    });

    if (markdownListTypeValues.some(markdownListType => markdownListType.regex.test(previousLineString))) {
      previousLineIsAList = true;
    }

    if (markdownListTypeMatched) {
      let orderedNumber: string = undefined;
      let currentOrderedNumberSyntax: string = undefined;

      if (markdownListTypeMatched.type === "orderedList") {
        orderedNumber = markdownListTypeMatched.regex.exec(currentLineString)[1];
        currentOrderedNumberSyntax = String(parseInt(orderedNumber)) + ". ";
      }

      const existingSyntaxLength = !orderedNumber
        ? markdownListTypeMatched.syntax.length
        : currentOrderedNumberSyntax.length;

      updatedMarkdownText =
        props.markdownText.substring(0, startOfLinePosition) +
        props.markdownText.substring(startOfLinePosition + existingSyntaxLength);

      handleMarkdownTextChange(updatedMarkdownText);
      setTextEditorState(prevState => {
        return {
          ...prevState,
          markdownSelection: {
            time: new Date().getTime(),
            selectionStart: Math.max(startOfLinePosition, selectionStart - existingSyntaxLength),
          },
        };
      });
    } else {
      updatedMarkdownText =
        props.markdownText.substring(0, startOfLinePosition) +
        (previousLineIsAList ? "\n" : "") +
        syntax +
        props.markdownText.substring(startOfLinePosition);

      handleMarkdownTextChange(updatedMarkdownText);
      setTextEditorState(prevState => {
        return {
          ...prevState,
          markdownSelection: {
            time: new Date().getTime(),
            selectionStart: selectionStart + syntaxLength + (previousLineIsAList ? 1 : 0),
          },
        };
      });
    }

    textEditorState.markdownRef.current.focus();
  }

  return (
    <div>
      {props.label && <span className="text-editor-label">{props.label}</span>}
      <div
        className={classNames("text-editor", { "text-editor-error": props.error })}
        style={props.label ? { marginTop: "2px" } : {}}
      >
        <div className="text-editor-header">
          <div>
            <button
              type="button"
              className={`text-editor-view-option ${
                textEditorState.writeOptionSelected ? "text-editor-view-option-active" : ""
              }`}
              onClick={() => handleViewOptionChange(true)}
            >
              {t("components.text_editor.text_editor.001")}
            </button>
            <button
              type="button"
              className={`text-editor-view-option ${
                !textEditorState.writeOptionSelected ? "text-editor-view-option-active" : ""
              }`}
              onClick={() => handleViewOptionChange(false)}
            >
              {t("components.text_editor.text_editor.002")}
            </button>
          </div>
          {textEditorState.writeOptionSelected ? (
            <div>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="headingTooltip">{`${t("components.text_editor.text_editor.003")}`}</Tooltip>}
              >
                <button
                  type="button"
                  className="text-editor-action"
                  onClick={() => addHeading(MarkdownHeadingSyntax.H1)}
                >
                  <FontAwesomeIcon icon={["fas", "h1"]} />
                </button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="headingTooltip">{`${t("components.text_editor.text_editor.004")}`}</Tooltip>}
              >
                <button
                  type="button"
                  className="text-editor-action"
                  onClick={() => addHeading(MarkdownHeadingSyntax.H2)}
                >
                  <FontAwesomeIcon icon={["fas", "h2"]} />
                </button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="headingTooltip">{`${t("components.text_editor.text_editor.005")}`}</Tooltip>}
              >
                <button
                  type="button"
                  className="text-editor-action"
                  onClick={() => addHeading(MarkdownHeadingSyntax.H3)}
                >
                  <FontAwesomeIcon icon={["fas", "h3"]} />
                </button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="boldTooltip">{`${t("components.text_editor.text_editor.006")}`}</Tooltip>}
              >
                <button type="button" className="text-editor-action" onClick={() => addBoldText()}>
                  <FontAwesomeIcon icon={["fas", "bold"]} />
                </button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="italicTooltip">{`${t("components.text_editor.text_editor.007")}`}</Tooltip>}
              >
                <button type="button" className="text-editor-action" onClick={() => addItalicText()}>
                  <FontAwesomeIcon icon={["fas", "italic"]} />
                </button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="unorderedListTooltip">{`${t("components.text_editor.text_editor.008")}`}</Tooltip>
                }
              >
                <button
                  type="button"
                  className="text-editor-action"
                  onClick={() => addList(markdownListTypes.unorderedList)}
                >
                  <FontAwesomeIcon icon={["fas", "list-ul"]} />
                </button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="orderedListTooltip">{`${t("components.text_editor.text_editor.009")}`}</Tooltip>}
              >
                <button
                  type="button"
                  className="text-editor-action"
                  onClick={() => addList(markdownListTypes.orderedList)}
                >
                  <FontAwesomeIcon icon={["fas", "list-ol"]} />
                </button>
              </OverlayTrigger>
            </div>
          ) : null}
        </div>
        <div className="text-editor-main">
          {textEditorState.writeOptionSelected ? (
            <textarea
              ref={textEditorState.markdownRef}
              className="text-editor-content"
              placeholder={t("components.text_editor.text_editor.011")}
              value={props.markdownText}
              onChange={handleEditorContentChange}
              onKeyDown={handleEditorContentKeydown}
              readOnly={props.readOnly}
            />
          ) : (
            <div className="text-editor-markdown-container">
              {props.markdownText === "" ? (
                <div>{t("components.text_editor.text_editor.010")}</div>
              ) : (
                <Markdown markdownText={props.markdownText} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
