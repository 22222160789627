import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StatusCode } from "api/protocols";
import Card from "components/card/Card";
import Page from "components/page/Page";
import Sheet from "components/sheet/Sheet";
import Checkbox from "components/form/checkbox/Checkbox";
import Input from "components/form/input/Input";
import "./inventory.scss";
import { IUIActions, showError, showSuccess } from "redux/actions/ui";
import {
  GetInventoryLog,
  GetInventoryRecord,
  PutInventoryRecord,
} from "api/rpc/2024-04/facilityAdmin/product/inventory";
import { useParams } from "react-router";
import moment from "moment";
import { Select } from "components/select/index";
import { PutVariantLabels } from "api/rpc/2022-09/facilityAdmin/product/product";
import { useAppDispatch } from "hooks/redux";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";
import axios, { CancelToken } from "axios";
import useModal from "hooks/modals/useModal";
import { TProductVendor } from "redux/reducers/models/product";
import { GetVendor } from "api/rpc/2024-04/facilityAdmin/product/vendor";
import { isEqualWith, isNull } from "lodash";
import ReactDOM from "react-dom";
import Icon from "components/icon/Icon";

type TPrintModalTemplates = {
  id: number;
  title: string;
};

interface IVendorState {
  vendors: Array<TProductVendor>;
  searchedVendors: Array<TProductVendor>;
  searching: boolean;
  vendorQuery: string;
  vendor: TProductVendor;
  packing_slip_number: string;
}

export default function InventoryLog() {
  const { id } = useParams<{ id: string }>();
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();

  const [record, setRecord] = useState<Record<string, any>>(undefined);
  const [state, setState] = useState({
    inventory: undefined as Record<string, any>,
    selectedItems: [] as boolean[],
  });

  const { state: printModal, updateModal } = useModal({
    clearOnClose: false,
    templates: [{ id: 5160, title: "Avery 5160" }] as TPrintModalTemplates[], //This will be changed when we load templates
    selectedTemplateId: 5160,
    offset: 0,
  });

  const [vendorState, setVendorState] = useState<IVendorState>({
    vendorQuery: "",
    searching: false,
    vendors: [],
    searchedVendors: [],
    vendor: null,
    packing_slip_number: "",
  });

  const [vendorStateBeforeChange, setVendorStateBeforeChanges] = useState<TProductVendor>(undefined);
  const [packingSlipBeforeChange, setPackingSlipBeforeChanges] = useState(undefined);

  const { Option } = Select;

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadInventoryRecord(Number(id), source.token); // Ony using `processed_at` & `admin_user_full_name`
    void loadInventoryLog(Number(id), source.token);
    return () => source.cancel();
  }, [id]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = global.setTimeout(() => {
        void searchVendors(vendorState.vendorQuery, source.token);
      }, 300);
    }
    return () => {
      source.cancel();
      mounted = false;
      clearTimeout(timeoutId);
      setVendorState(prevState => ({ ...prevState, searchedVendors: [], searching: false, vendor: null }));
    };
  }, [vendorState.vendorQuery]);

  async function searchVendors(vendorQuery: string, token: CancelToken) {
    if (vendorQuery === "") {
      setVendorState(prevState => ({ ...prevState, searchedVendors: [], searching: false }));
      return;
    }

    setVendorState(prevState => ({ ...prevState, searching: true }));
    const vendorRes = await GetVendor({ search: vendorState.vendorQuery }, false, token);
    if (token && token.reason) {
      return;
    }
    if (vendorRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.inventory.inventory_log.018")));
      return;
    }
    setVendorState(prevState => ({ ...prevState, searchedVendors: vendorRes?.data, searching: false }));
  }

  async function loadInventoryRecord(id: number, token?: CancelToken) {
    const inventoryRes = await GetInventoryRecord({ id }, token ? false : true, token);

    console.log(inventoryRes);
    if (token && token.reason) {
      return;
    }
    if (inventoryRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.inventory.inventory_log.001")));
      return;
    }

    setRecord(inventoryRes.data);

    const vendorRes = await GetVendor(null, true);
    if (vendorRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.inventory.inventory_log.006")));
      return;
    }

    let vendor: TProductVendor = null;
    const vendorId = inventoryRes?.data?.vendor_id;
    if (vendorId) {
      vendor = vendorRes?.data?.find(vendor => vendor?.id === vendorId);
    }

    ReactDOM.unstable_batchedUpdates(() => {
      setVendorState(prevState => ({
        ...prevState,
        vendors: vendorRes.data,
        vendor: vendor ?? null,
        packing_slip_number: inventoryRes.data.packing_slip_number,
      }));
    });
  }

  async function loadInventoryLog(inventory_record_id: number, token?: CancelToken) {
    if (state.inventory !== undefined) {
      setState(prev => ({ ...prev, inventory: undefined, selectedItems: [] }));
    }

    const inventoryRes = await GetInventoryLog({ inventory_record_id }, token ? false : true, token);

    console.log(inventoryRes);
    if (token && token.reason) {
      return;
    }
    if (inventoryRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.inventory.inventory_log.002")));
      setState(prev => ({ ...prev, inventory: [], selectedItems: [] }));
      return;
    }

    setState({
      inventory: inventoryRes.data,
      selectedItems: new Array(inventoryRes.data.length).fill(true),
    });
  }

  async function handlePrint() {
    updateModal({ isOpen: false });

    const variants = state.inventory
      .filter((data: any, index: number) => state.selectedItems[index])
      .map((variant: any) => ({
        variant_id: variant.inventory_item.variant_id,
        quantity: variant.change,
      }));

    const getVariantLabelsResponse = await PutVariantLabels(
      {
        offset: printModal.offset,
        variants: variants,
        template_id: printModal.selectedTemplateId,
      },
      true,
    );

    if (getVariantLabelsResponse.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.inventory.inventory_log.007")));
      return;
    }

    window.open().document.write(getVariantLabelsResponse.data);
  }

  function handleCloseModal() {
    updateModal({
      isOpen: false,
      offset: 0,
      selectedTemplateId: 5160,
    });
    setState(prev => ({ ...prev, selectedItems: new Array(prev.selectedItems.length).fill(true) }));
  }

  function handleCheckBoxChange(index: number) {
    const updatedCheckBoxes = state.selectedItems;
    updatedCheckBoxes[index] = !updatedCheckBoxes[index];
    setState(prev => ({ ...prev, selectedItems: updatedCheckBoxes }));
  }
  const primaryAction = {
    content: t("secure.facility.settings.inventory.inventory_log.008"),
    action: () => updateModal({ isOpen: true }),
  };

  function unsavedChangesExist() {
    if (vendorStateBeforeChange === undefined) {
      if (vendorState?.vendors?.length > 0) {
        setVendorStateBeforeChanges(vendorState?.vendor);
      }
      return false;
    }

    if (packingSlipBeforeChange === undefined) {
      if (vendorState) {
        setPackingSlipBeforeChanges(vendorState?.packing_slip_number);
      }
      return false;
    }

    return (
      !isEqualWith(vendorStateBeforeChange, vendorState?.vendor, (originalValue, newValue) => {
        if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
          return true;
        }
      }) ||
      !isEqualWith(packingSlipBeforeChange, vendorState?.packing_slip_number, (originalValue, newValue) => {
        if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
          return true;
        }
      })
    );
  }

  function cancelUnsavedChanges() {
    setVendorState(prevState => ({
      ...prevState,
      vendor: vendorStateBeforeChange,
      packing_slip_number: packingSlipBeforeChange,
    }));
  }

  async function updateInventoryRecord() {
    const inventoryRes = await PutInventoryRecord(
      {
        id: Number(id),
        vendor_id: vendorState?.vendor?.id ?? null,
        packing_slip_number: vendorState.packing_slip_number,
      },
      true,
    );
    if (inventoryRes?.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.inventory.inventory_log.009")));
      return;
    }
    dispatch(showSuccess(t("secure.facility.settings.inventory.inventory_log.010")));
    ReactDOM.unstable_batchedUpdates(() => {
      setVendorStateBeforeChanges(vendorState?.vendor);
      setPackingSlipBeforeChanges(vendorState.packing_slip_number);
    });
  }

  function displayVendors() {
    if ((vendorState?.searching && vendorState?.vendorQuery) || vendorState?.searchedVendors?.length > 0) {
      return [...vendorState?.searchedVendors];
    }
    return [...vendorState?.vendors];
  }

  function handleVendorChange(value: number, vendor: TProductVendor) {
    setVendorState(prevState => ({ ...prevState, vendor }));
  }

  function handlePackingSlipChange(event: any) {
    const value = event?.target?.value;

    if (value === undefined) {
      return;
    }

    setVendorState(prevState => ({ ...prevState, packing_slip_number: value }));
  }

  return (
    <Page
      title={record ? `${moment(record.processed_at?.split(" ")[0]).format("LL")}` : "Loading.."}
      subtitle={record ? `${record.admin_user_full_name as string}` : ""}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.inventory.inventory_log.003"),
          url: "/admin/settings/inventory",
        },
      ]}
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: updateInventoryRecord,
        onCancel: cancelUnsavedChanges,
      }}
    >
      <Card>
        <Card.Section>
          {vendorState?.vendor ? (
            <>
              <div>
                <p className="inventory-vendor-label">{t("secure.facility.settings.inventory.inventory_log.011")}</p>
              </div>
              <div className="vendor-name">
                <div>{vendorState?.vendor?.title}</div>
                <button
                  onClick={() =>
                    setVendorState(prevState => ({
                      ...prevState,
                      vendor: null,
                      searchedVendors: [],
                      vendorQuery: "",
                    }))
                  }
                >
                  <Icon style="far" icon="times" />
                </button>
              </div>
            </>
          ) : (
            <Select
              usePortal
              label={"Vendor"}
              onChange={(value: number, vendor: TProductVendor) => handleVendorChange(value, vendor)}
              showSearch
              searchValue={vendorState.vendorQuery}
              searching={vendorState.searching}
              onSearch={(query: string) => setVendorState(prevState => ({ ...prevState, vendorQuery: query }))}
              showDropDownOnFocus
              placeholder={t("secure.facility.settings.inventory.inventory_log.012")}
            >
              {displayVendors()?.map((vendor, index) => {
                return (
                  <Option key={index} value={vendor?.id} extraValues={vendor}>
                    {vendor?.title}
                  </Option>
                );
              })}
            </Select>
          )}

          <div className="mt-4">
            <Input
              value={vendorState.packing_slip_number}
              id="packing_slip_number"
              label={t("secure.facility.settings.inventory.inventory_log.013")}
              placeholder="012345"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePackingSlipChange(e)}
            />
          </div>
        </Card.Section>
        <Card.Section table="true">
          <DataTable
            columns={[
              { label: t("secure.facility.settings.inventory.inventory_log.004") },
              { label: t("secure.facility.settings.inventory.inventory_log.005") },
            ]}
            loading={state.inventory === undefined}
          >
            {state.inventory?.map((inventoryRecord: Record<string, any>, index: number) => {
              return (
                <tr key={index}>
                  <td className="table-cell-lead-with-support-text">
                    <p className="table-cell-lead-text">{inventoryRecord?.inventory_item?.variant?.product?.title}</p>
                    {inventoryRecord?.inventory_item?.variant?.product?.title !==
                      inventoryRecord?.inventory_item?.variant?.title && (
                      <p className="table-cell-support-text">{inventoryRecord?.inventory_item?.variant?.title}</p>
                    )}
                  </td>
                  <td>{inventoryRecord?.change}</td>
                </tr>
              );
            })}
          </DataTable>
        </Card.Section>
      </Card>
      <Sheet
        size="small"
        open={printModal.isOpen}
        okText={t("secure.facility.settings.inventory.inventory_log.014")}
        title={t("secure.facility.settings.inventory.inventory_log.008")}
        onCancel={handleCloseModal}
        onOk={handlePrint}
        okDisabled={state.selectedItems.every(bool => !bool) || printModal.selectedTemplateId === -1}
        closable
        stacked
      >
        <Card>
          <Card.Section>
            <Select
              label={t("secure.facility.settings.inventory.inventory_log.015")}
              className="flex justify-center align-center w-full h-10 position-relative z-20 text-black text-medium appearance-none border-none focus:outline-none placeholder-gray-200 overflow"
              placeholder="Label Template"
              defaultValue={printModal.templates[0].id}
              onChange={(id: number) => updateModal({ selectedTemplateId: id })}
            >
              <Select.Option value={-1}>None Selected</Select.Option>
              {printModal.templates.length > 0 &&
                printModal.templates.map(template => {
                  return (
                    <Select.Option key={template.id} value={template.id}>
                      {template.title}
                    </Select.Option>
                  );
                })}
            </Select>
            <div style={{ margin: "10px" }} />
            <Input
              label={t("secure.facility.settings.inventory.inventory_log.017")}
              value={printModal.offset}
              onChange={(event: any) => updateModal({ offset: event.target.value })}
              type="number"
            />
          </Card.Section>
          <Card.Section>
            {state.inventory?.map((inventoryRecord: Record<string, any>, index: number) => {
              return (
                <div key={index}>
                  <div className="flex flex-row gap-4 p-2 border-b border-gray-200">
                    <div className="text-regular">
                      <Checkbox
                        size={"medium"}
                        checked={state.selectedItems[index]}
                        onChange={() => handleCheckBoxChange(index)}
                      />
                    </div>
                    <div className="table-cell-lead-text">
                      {inventoryRecord?.inventory_item?.variant?.product?.title}
                    </div>
                    {inventoryRecord?.inventory_item?.variant?.product?.title !==
                      inventoryRecord?.inventory_item?.variant?.title && (
                      <p className="table-cell-support-text">{inventoryRecord?.inventory_item?.variant?.title}</p>
                    )}
                  </div>
                </div>
              );
            })}
          </Card.Section>
        </Card>
      </Sheet>
    </Page>
  );
}
