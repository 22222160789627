import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { update } from "redux/actions/bookingEngine";
import { dequeue, enqueue, showError } from "redux/actions/ui";
import { ITeeTime } from "redux/reducers/models/teetime";
import { StatusCode } from "api/protocols";
import { UserActive } from "api/rpc";
import {
  GetBookingEngine,
  GetCustomerTeeTimes,
  GetGuestTeeTimes,
  LockTeeTimeById,
  GetTeeTime, // Authorized customer
  GetTeeTimeGuest,
  INotification,
  GetCustomerNotifications,
  GetGuestNotifications,
  GetBookingCategoryCheck,
  IGustCheckInsRes,
  ICheckIns,
} from "api/rpc/2022-09/guest/bookingEngine";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { convertTime, formatDateFromString } from "helpers/Helpers";

import Callout from "components/callout/Callout";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import { CustomerTeeTimeCard } from "components/customerTeeTimeCard/CustomerTeeTimeCard";
import Radio from "components/radio";
import DateBar from "elements/teesheet/DateBar";
import { CustomerNavigation } from "elements/customer/CustomerNavigation";

import "pages/guest/Customer/CustomerTeeTimeList.scss";
import { GetCustomerTeeSheetSettings, IGetFacilityTeeSheetSettingsRes } from "api/rpc/2022-09/customer/facility";
import { GetGuestTeeSheetSettings } from "api/rpc/2022-09/guest/facility";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { GetCustomerCheckIns } from "api/rpc/2024-04/customer/teesheet";
import { GetGuestCheckIns } from "api/rpc/2024-04/guest/teesheet";

interface ITeeTimeCard {
  id: number;
  time: string;
  convertedTime: string;
  prepaid: boolean;
  quantity_remaining: number;
  holes: number[];
  cart_rule: string;
  nine_hole_price: number;
  eighteen_hole_price: number;
  courseName?: string;
  date: string;
  position: number;
  divisionTitle?: string;
  blocked_type?: unknown;
  startingHole?: string;
  course_id: number;
}

interface IFilterState {
  selectedDate: Date;
  selectedPlayers: string;
  selectedHoles: string;
  selectedCart: string;
  selectedTeeTimeCards: ITeeTimeCard[];
  selectedCourseName: string;
  selectedCourseAddressLine1: string;
  selectedCourseAddressLine2: string;
  selectedCourseLogo: string;
  selectedFacilityId: number;
  courses: any[];
  loginRequired: number;
  selectedUserFirstname: string;
  selectedCurrentActiveUser: boolean;
  selectedHeaderBackground: string;
  notifications: INotification[];
  teeTimeOffset: number;
  teeTimeLimit: number;
  bookingWindowTitle: string;
  enable_cart_bookings: boolean;
  checkIns: Array<ICheckIns>;
  // daysInAdvance: number;
}

interface IBookingState {
  defaultDate: Date;
}

interface IParams {
  bookingEngineHandle: string;
}

export function CustomerTeeTimeList() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const bookingEngineStore = useAppSelector(store => store.bookingEngineStore);
  const dispatch = useAppDispatch();

  const { bookingEngineHandle } = useParams<IParams>();
  const tempGolfCourseName = bookingEngineHandle.split("-");
  const golfCourseName = tempGolfCourseName[0];
  const handle = bookingEngineHandle;
  //Backend counts Sunday as day 7
  const [filterDayState, setFilterDayState] = useState<number>(moment().day() === 0 ? 7 : moment().day());

  const [filterState, setFilterState] = useState<IFilterState>({
    selectedDate: new Date(),
    selectedPlayers: "any",
    selectedHoles: "any",
    selectedCart: "any",
    selectedTeeTimeCards: [],
    selectedCourseName: "",
    selectedCourseAddressLine1: "",
    selectedCourseAddressLine2: "",
    selectedCourseLogo: "",
    selectedFacilityId: null,
    courses: [],
    loginRequired: null,
    selectedUserFirstname: "",
    selectedCurrentActiveUser: null,
    selectedHeaderBackground: "https://www.foresightgolf.net/images/template/banner-cypresswood.jpg",
    notifications: [],
    teeTimeOffset: 0,
    teeTimeLimit: 30,
    bookingWindowTitle: null,
    enable_cart_bookings: null,
    checkIns: [],
    // daysInAdvance: undefined,
  });

  const playerOptions = [
    { value: "1", title: "1", disabled: bookingEngineStore?.singleBookings === "none" ? true : false },
    { value: "2", title: "2", disabled: false },
    { value: "3", title: "3", disabled: false },
    { value: "4", title: "4", disabled: false },
    { value: "any", title: t("guest.customer.customer_tee_time_list.017"), disabled: false },
  ];

  const holeOptions = [
    { value: "18", title: "18" },
    { value: "9", title: "9" },
    { value: "any", title: t("guest.customer.customer_tee_time_list.018") },
  ];

  const cartOptions = [
    { value: "yes", title: t("guest.customer.customer_tee_time_list.019") },
    { value: "no", title: t("guest.customer.customer_tee_time_list.020") },
    { value: "any", title: t("guest.customer.customer_tee_time_list.021") },
  ];

  useEffect(() => {
    void getBookingEngine();
  }, []);

  useEffect(() => {
    if (filterState.checkIns && !!bookingEngineStore?.display_online_pricing) {
      const teeTimeCards = [...filterState.selectedTeeTimeCards];
      teeTimeCards?.forEach((card, index) => {
        let eighteenHolePrice: number;
        let nineHolePrice: number;
        filterState?.checkIns?.every(checkIn => {
          if (eighteenHolePrice && nineHolePrice) {
            return false;
          } else {
            const startTime = moment(checkIn?.pricing_sheet?.start_time, "HH:mm");
            const endTime = moment(checkIn?.pricing_sheet?.end_time, "HH:mm");
            const checkInCourseId = checkIn?.pricing_sheet?.course_id;
            if (
              (moment(card?.time, "HH:mm").isBetween(startTime, endTime) ||
                moment(card?.time, "HH:mm").isSame(startTime) ||
                moment(card?.time, "HH:mm").isSame(endTime)) &&
              checkHolesSelected(checkIn?.holes) &&
              checkInCourseId === card?.course_id
            ) {
              if (checkIn?.holes === 18 && card?.holes?.includes(18) && !eighteenHolePrice) {
                const priceOverride = checkIn?.variant?.inventory_levels?.find(
                  level => level?.facility_id === bookingEngineStore?.selectedFacilityId,
                )?.price_override;
                eighteenHolePrice = priceOverride ?? checkIn?.variant?.price;
              } else if (checkIn?.holes === 9 && card?.holes?.includes(9) && !nineHolePrice) {
                const priceOverride = checkIn?.variant?.inventory_levels?.find(
                  level => level?.facility_id === bookingEngineStore?.selectedFacilityId,
                )?.price_override;
                nineHolePrice = priceOverride ?? checkIn?.variant?.price;
              }
            }
            return true;
          }
        });
        teeTimeCards[index] = {
          ...card,
          eighteen_hole_price: eighteenHolePrice ? eighteenHolePrice : undefined,
          nine_hole_price: nineHolePrice ? nineHolePrice : undefined,
        };
      });
      updateFilterState({ selectedTeeTimeCards: teeTimeCards });
    }
  }, [filterState.selectedHoles]);

  function checkHolesSelected(holes: number) {
    switch (filterState.selectedHoles) {
      case "18":
        if (holes === 18) {
          return true;
        }
        break;
      case "9":
        if (holes === 9) {
          return true;
        }
        break;
      case "any":
        return true;
    }
    return false;
  }

  function sendToNextPage(address: string) {
    history.push(address);
  }

  //Get Booking Engine - Determine if login is required
  async function getBookingEngine() {
    const params = {
      handle: bookingEngineHandle,
    };

    let currentActiveUser = false;
    let customerId = null;

    dispatch(enqueue());

    const activeUserRes = await UserActive(false);

    if (activeUserRes.status === StatusCode.OK) {
      customerId = activeUserRes.data.id;
      currentActiveUser = true;
    }

    const bookingEngineRes = await GetBookingEngine(params, false);
    if (bookingEngineRes.status !== StatusCode.OK) {
      dispatch(dequeue());
      dispatch(showError(t("guest.customer.customer_tee_time_list.001")));
      return;
    }

    const fullAddress = `${bookingEngineRes.data[0]?.facility?.address_line_1}, ${bookingEngineRes.data[0]?.facility?.city},
      ${bookingEngineRes.data[0]?.facility?.province_name}, ${bookingEngineRes.data[0]?.facility?.postal}`;

    updateFilterState({
      selectedCourseName: bookingEngineRes.data[0]?.facility.long_name,
      selectedCourseAddressLine1: bookingEngineRes.data[0]?.facility.address_line_1,
      selectedCourseAddressLine2: bookingEngineRes.data[0]?.facility.address_line_2,
      selectedCourseLogo: bookingEngineRes.data[0]?.facility.logo_source,
      selectedFacilityId: bookingEngineRes.data[0].facility_id,
      courses: bookingEngineRes.data[0]?.courses,
      loginRequired: bookingEngineRes.data[0].requires_login,
      bookingWindowTitle: bookingEngineRes.data[0]?.booking_window?.title,
      // daysInAdvance:
      //   bookingEngineRes.data[0]?.booking_window?.days_in_advance_end > 9
      //     ? 9
      //     : bookingEngineRes.data[0]?.booking_window?.days_in_advance_end,
    });

    dispatch(
      update({
        selectedCourseLogo: bookingEngineRes.data[0].facility.logo_source,
        selectedHeaderBackground: filterState.selectedHeaderBackground,
        selectedCourseFacility: bookingEngineRes.data[0].facility.long_name,
        selectedCourseLongName: bookingEngineRes.data[0].facility.long_name,
        selectedLoginRequired: bookingEngineRes.data[0].requires_login,
        selectedFacilityId: bookingEngineRes.data[0].facility_id,
        selectedBookingEngineId: bookingEngineRes.data[0].id,
        activeCustomerId: customerId,
        enabled_payment_methods: bookingEngineRes.data[0].enabled_payment_methods,
        courseLogo: bookingEngineRes.data[0]?.facility.logo_source,
        selectedFullAddress: fullAddress,
        facilityClientId: bookingEngineRes?.data[0]?.facility?.client_id,
      }),
    );

    const loginRequired = bookingEngineRes.data[0].requires_login;
    const dayOfWeekNumber = moment(filterState.selectedDate).day() === 0 ? 7 : moment(filterState.selectedDate).day();

    if (currentActiveUser) {
      updateFilterState({ selectedUserFirstname: activeUserRes.data.first_name, selectedCurrentActiveUser: true });
      dispatch(
        update({
          selectedUserFirstName: activeUserRes.data.first_name,
          userFullName: activeUserRes.data.full_name,
        }),
      );
    } else {
      updateFilterState({ selectedCurrentActiveUser: false });
    }

    if (currentActiveUser) {
      if (loginRequired) {
        const bookingCategoryCheck = await GetBookingCategoryCheck(
          { booking_engine_id: bookingEngineRes?.data[0]?.id },
          false,
        );
        if (bookingCategoryCheck.status !== StatusCode.OK) {
          dispatch(showError(t("guest.customer.customer_tee_time_list.002")));
          sendToNextPage(`/booking-engine/${golfCourseName}`);
        }
      }

      await loadTeeInformation(
        filterState.selectedDate,
        true,
        bookingEngineRes.data[0]?.courses.length,
        bookingEngineRes.data[0].facility_id,
        filterState.teeTimeOffset,
        filterState.teeTimeLimit,
        dayOfWeekNumber,
      );
    } else if (!currentActiveUser && loginRequired !== 1) {
      await loadTeeInformation(
        filterState.selectedDate,
        false,
        bookingEngineRes.data[0]?.courses.length,
        bookingEngineRes.data[0].facility_id,
        filterState.teeTimeOffset,
        filterState.teeTimeLimit,
        dayOfWeekNumber,
      );
    } else if (!currentActiveUser && loginRequired === 1) {
      const address = "/booking-engine/" + golfCourseName + "/" + handle + "/login?public=no";
      dispatch(dequeue());
      sendToNextPage(address);
      return;
    }

    dispatch(dequeue());
  }

  function updateFilterState(newFilterState: Partial<IFilterState>) {
    setFilterState(cur => {
      return { ...cur, ...newFilterState };
    });
  }

  function handleSelectedDateChange(selectedDate: Date) {
    //Backend counts Sunday as day 7
    const dayOfWeekNumber = moment(selectedDate).day() === 0 ? 7 : moment(selectedDate).day();
    setFilterDayState(dayOfWeekNumber);
    updateFilterState({ selectedDate: selectedDate });
    if (filterState.selectedCurrentActiveUser !== null) {
      updateFilterState({ teeTimeOffset: 0 });
      if (filterState.selectedCurrentActiveUser) {
        void loadTeeInformation(
          selectedDate,
          true,
          filterState.courses.length,
          filterState.selectedFacilityId,
          0,
          filterState.teeTimeLimit,
          dayOfWeekNumber,
        );
      } else {
        void loadTeeInformation(
          selectedDate,
          false,
          filterState.courses.length,
          filterState.selectedFacilityId,
          0,
          filterState.teeTimeLimit,
          dayOfWeekNumber,
        );
      }
    }
  }

  function invalidTeeTime(teeTime: ITeeTime) {
    return (
      teeTime?.divisions_available?.length === 1 && teeTime?.divisions_available[0] === 2 && !teeTime?.turn_tee_time_id
    );
  }

  // loads the notifications for the selected date
  async function loadNotifications(date: Date, isPrivate: boolean, facilityId: number) {
    let notificationResponse;
    const currentDateString = date.toLocaleDateString("en-CA");

    if (isPrivate) {
      // Members || Private Api Call
      notificationResponse = await GetCustomerNotifications(
        {
          date: currentDateString,
          facility_id: facilityId,
        },
        false,
      );
    } else {
      //Guest || Pubic Api call
      notificationResponse = await GetGuestNotifications(
        {
          date: currentDateString,
          facility_id: facilityId,
        },
        false,
      );
    }

    if (notificationResponse?.status === StatusCode.OK && notificationResponse?.data?.length > 0) {
      updateFilterState({ notifications: notificationResponse.data });
    } else {
      updateFilterState({ notifications: [] });
    }
  }

  //Loads the Tee Time Information from API Response
  async function loadTeeInformation(
    date: Date,
    isPrivate: boolean,
    courses: number,
    facilityId: number,
    offset: number,
    limit: number,
    dayOfWeekNumber: number,
    // defaultDate: Date,
  ) {
    const params = {
      date: date,
      booking_engine_handle: bookingEngineHandle,
      offset: offset,
      limit: limit,
    };

    let teeInformationResponse;
    let teeSheetSettingsRes: IGetFacilityTeeSheetSettingsRes;
    let checkInConfigsRes: IGustCheckInsRes;

    dispatch(enqueue());

    if (isPrivate) {
      //Members / Private Api Call
      teeSheetSettingsRes = await GetCustomerTeeSheetSettings({ facility_id: facilityId }, false);
      if (teeSheetSettingsRes.status !== StatusCode.OK) {
        dispatch(showError(t("guest.customer.customer_tee_time_list.022")));
      }

      if (teeSheetSettingsRes?.data?.display_online_pricing) {
        checkInConfigsRes = await GetCustomerCheckIns(
          { facility_id: facilityId, day_of_week_number: dayOfWeekNumber },
          false,
        );
        if (checkInConfigsRes.status !== StatusCode.OK) {
          dispatch(showError("Error getting pricing"));
        }
      }

      teeInformationResponse = await GetCustomerTeeTimes(params, false);
    } else {
      //Guest API call
      teeSheetSettingsRes = await GetGuestTeeSheetSettings({ facility_id: facilityId }, false);
      if (teeSheetSettingsRes.status !== StatusCode.OK) {
        dispatch(showError(t("guest.customer.customer_tee_time_list.022")));
      }

      if (teeSheetSettingsRes?.data?.display_online_pricing) {
        checkInConfigsRes = await GetGuestCheckIns(
          { facility_id: facilityId, day_of_week_number: dayOfWeekNumber },
          false,
        );
        if (checkInConfigsRes.status !== StatusCode.OK) {
          dispatch(showError("Error getting pricing"));
        }
      }

      teeInformationResponse = await GetGuestTeeTimes(params, false);
    }

    if (teeSheetSettingsRes?.status === StatusCode.OK) {
      dispatch(
        update({
          singleBookings: teeSheetSettingsRes?.data?.single_bookings,
          twosomeBookings: teeSheetSettingsRes?.data?.twosome_bookings,
          threesomeBookings: teeSheetSettingsRes?.data?.threesome_bookings,
          foursomeBookings: teeSheetSettingsRes?.data?.foursome_bookings,
          paymentTerms: teeSheetSettingsRes?.data?.payment_terms,
          bookingTerms: teeSheetSettingsRes?.data?.booking_terms,
          enable_cart_bookings: Boolean(teeSheetSettingsRes?.data?.enable_cart_bookings),
          display_online_pricing: teeSheetSettingsRes?.data?.display_online_pricing,
        }),
      );
    }

    dispatch(dequeue());
    updateFilterState({ enable_cart_bookings: !!teeSheetSettingsRes?.data?.enable_cart_bookings });

    if (teeInformationResponse.status !== StatusCode.OK) {
      dispatch(showError(t("guest.customer.customer_tee_time_list.013")));
      updateFilterState({ selectedTeeTimeCards: [] });
      updateFilterState({ teeTimeOffset: 0 });
    } else if (offset > 0 && teeInformationResponse.data.length === 0) {
      const newTeeTimeOffset = offset - limit;
      updateFilterState({ teeTimeOffset: newTeeTimeOffset > 0 ? newTeeTimeOffset : 0 });
    } else {
      const teetimeCards: ITeeTimeCard[] = [];
      const checkInsData = checkInConfigsRes?.data ? [...checkInConfigsRes?.data] : [];

      for (let i = 0; i < teeInformationResponse.data.length; i++) {
        if (invalidTeeTime(teeInformationResponse.data[i])) {
          continue;
        }
        const teetimeData = teeInformationResponse.data[i];
        const startTime = convertTime(teetimeData.start_time);

        const holes = teetimeData.divisions_available.map(divisionAvailable => {
          if (divisionAvailable === 1) {
            return 9;
          } else if (divisionAvailable === 2) {
            return 18;
          }
        });

        let eighteenHolePrice: number;
        let nineHolePrice: number;
        checkInsData?.every(checkIn => {
          if (eighteenHolePrice && nineHolePrice) {
            return false;
          } else {
            const startTime = moment(checkIn?.pricing_sheet?.start_time, "HH:mm");
            const endTime = moment(checkIn?.pricing_sheet?.end_time, "HH:mm");
            const checkInCourseId = checkIn?.pricing_sheet?.course_id;
            if (
              (moment(teetimeData?.start_time, "HH:mm").isBetween(startTime, endTime) ||
                moment(teetimeData?.start_time, "HH:mm").isSame(startTime) ||
                moment(teetimeData?.start_time, "HH:mm").isSame(endTime)) &&
              checkHolesSelected(checkIn?.holes) &&
              checkInCourseId === teetimeData?.course_id
            ) {
              if (checkIn?.holes === 18 && holes?.includes(18) && !eighteenHolePrice) {
                const priceOverride = checkIn?.variant?.inventory_levels?.find(
                  level => level?.facility_id === facilityId,
                )?.price_override;
                eighteenHolePrice = priceOverride ?? checkIn?.variant?.price;
              } else if (checkIn?.holes === 9 && holes?.includes(9) && !nineHolePrice) {
                const priceOverride = checkIn?.variant?.inventory_levels?.find(
                  level => level?.facility_id === facilityId,
                )?.price_override;
                nineHolePrice = priceOverride ?? checkIn?.variant?.price;
              }
            }
            return true;
          }
        });

        if (teetimeData.quantity_remaining >= 1) {
          const teetimeCard: ITeeTimeCard = {
            id: teetimeData.id,
            time: teetimeData.start_time,
            convertedTime: startTime,
            prepaid: teeInformationResponse.data[i].pre_paid_required,
            quantity_remaining: teetimeData.quantity_remaining,
            holes: holes,
            cart_rule: teetimeData.cart_rule,
            eighteen_hole_price:
              eighteenHolePrice && !!teeSheetSettingsRes?.data?.display_online_pricing ? eighteenHolePrice : undefined,
            nine_hole_price:
              nineHolePrice && !!teeSheetSettingsRes?.data?.display_online_pricing ? nineHolePrice : undefined,
            courseName: courses > 1 ? teetimeData.course_full_name : undefined,
            date: teetimeData.date,
            position: teetimeData.position,
            divisionTitle: teetimeData.division_title,
            blocked_type: teetimeData.blocked_type,
            startingHole: teetimeData.start_hole,
            course_id: teetimeData?.course_id,
          };

          teetimeCards.push(teetimeCard);
        }
      }

      teetimeCards.sort(
        (currentTeetimeCard, nextTeetimeCard) => currentTeetimeCard.position - nextTeetimeCard.position,
      );
      updateFilterState({ selectedTeeTimeCards: teetimeCards, checkIns: checkInsData });
      window.scrollTo({ top: 0, left: 0 });
    }

    // load notifications after Get TeeTimes call completes
    void loadNotifications(date, isPrivate, facilityId);
  }

  async function CheckTeeTime(date: string, teeTimeId: number, courseName: string) {
    const params = {
      date: date,
      id: teeTimeId,
      booking_engine_handle: bookingEngineHandle,
      turn_tee_time: true,
      extended: true,
    };

    // If user is active, load auth route for tee time
    let teeInformationByIdRes;
    if (filterState.selectedCurrentActiveUser) {
      teeInformationByIdRes = await GetTeeTime(params, true);
    } else {
      teeInformationByIdRes = await GetTeeTimeGuest(params, true);
    }

    if (teeInformationByIdRes.status === 410) {
      dispatch(showError(t("guest.customer.customer_tee_time_list.003")));
      return;
    }

    if (teeInformationByIdRes.status === 411) {
      dispatch(showError(t("guest.customer.customer_tee_time_list.004")));
      return;
    }

    if (teeInformationByIdRes.status !== StatusCode.OK) {
      dispatch(showError(t("guest.customer.customer_tee_time_list.005")));
      return;
    }

    if (teeInformationByIdRes.data[0].quantity_remaining === 0) {
      dispatch(showError(t("guest.customer.customer_tee_time_list.006")));
      return;
    }

    const tempId = String(teeTimeId);
    const tempDate = String(date);

    const tempStartTime = convertTime(teeInformationByIdRes.data[0].start_time);
    console.log(tempStartTime);

    const tempFormatDate = formatDateFromString(tempDate);

    let courseNameString;
    let courseDisplayName;

    if (courseName === undefined) {
      courseNameString = "";
      courseDisplayName = teeInformationByIdRes.data[0].facility.long_name;
    } else {
      courseNameString = courseName;
      courseDisplayName = String(teeInformationByIdRes.data[0].facility.long_name) + " - " + courseNameString;
    }

    dispatch(
      update({
        selectedDate: teeInformationByIdRes.data[0].date,
        selectedAddress: teeInformationByIdRes.data[0].facility.address_line_1,
        selectedTeeTimeId: teeInformationByIdRes.data[0].id,
        selectedCity: teeInformationByIdRes.data[0].facility.city,
        selectedCourseLongName: courseDisplayName,
        selectedCourseName: courseNameString,
        selectedPostalCode: teeInformationByIdRes.data[0].facility.postal,
        selectedProvince: teeInformationByIdRes.data[0].facility.province_name,
        selectedGolferSpotsRemaining: teeInformationByIdRes.data[0].quantity_remaining,
        selectedStartHole: teeInformationByIdRes.data[0].start_hole,
        selectedStartTime: tempStartTime,
        selectedFacilityId: teeInformationByIdRes.data[0].facility.id,
        selectedFormattedDate: tempFormatDate,
        facilityClientId: teeInformationByIdRes.data[0].facility.client_id,
        creditCardRequired: teeInformationByIdRes.data[0].credit_card_required,
        prepaidRequired: teeInformationByIdRes.data[0].pre_paid_required,
        cart_rule: teeInformationByIdRes.data[0].cart_rule,
        divisionsAvailable: teeInformationByIdRes.data[0].divisions_available,
        turnTeeTime: teeInformationByIdRes.data[0].turn_tee_time,
        selectedTeeTime: teeInformationByIdRes.data[0],
      }),
    );

    if (filterState.selectedCurrentActiveUser) {
      //If not null - User has access to their own locked teetime
      if (teeInformationByIdRes.data[0].lock !== null) {
        const tempCurrentDate = new Date().toUTCString();
        const currentTempTime = new Date(tempCurrentDate);
        const currentTime = new Date(currentTempTime).getTime();

        const tempString = String(teeInformationByIdRes.data[0].lock.start_time) + "Z";
        console.log(tempString);

        const lockStartDate = new Date(tempString);
        console.log(lockStartDate);

        const tempExpiryString = String(teeInformationByIdRes.data[0].lock.expire_time) + "Z";
        const lockoutTempExpiry = new Date(tempExpiryString);

        const lockStartTime = lockStartDate.getTime();
        const lockoutExpiryTime = lockoutTempExpiry.getTime();

        const lockoutDuration = lockoutExpiryTime - currentTime;

        dispatch(
          update({
            selectedLockoutDuration: lockoutDuration,
            selectedLockoutStartTime: lockStartTime,
            selectedLockoutExpireTime: teeInformationByIdRes.data[0].lock.expire_time,
          }),
        );

        const holeSelectionAddress =
          "/booking-engine/" + golfCourseName + "/" + handle + "/holes" + "?id=" + tempId + "&date=" + tempDate;

        sendToNextPage(holeSelectionAddress);
      } else {
        const bookingLockRes = await LockTeeTimeById({ tee_time_id: teeInformationByIdRes.data[0].id }, true);
        console.log(bookingLockRes);

        if (bookingLockRes.status !== StatusCode.OK) {
          dispatch(showError(bookingLockRes.data.message));
          console.log(bookingLockRes.data.message);
          return;
        }

        if (bookingLockRes.data.message === "Tee time already locked by user") {
          dispatch(showError(t("guest.customer.customer_tee_time_list.007")));
          console.log("Booking is locked by another user");
          return;
        }

        const tempCurrentDate = new Date().toUTCString();
        const currentTempTime = new Date(tempCurrentDate);
        const currentTime = new Date(currentTempTime).getTime();

        const tempString = String(bookingLockRes.data.start_time) + "Z";
        console.log(tempString);

        const lockStartDate = new Date(tempString);
        console.log(lockStartDate);

        const tempExpiryString = String(bookingLockRes.data.expire_time) + "Z";
        const lockoutTempExpiry = new Date(tempExpiryString);

        const lockStartTime = lockStartDate.getTime();
        const lockoutExpiryTime = lockoutTempExpiry.getTime();

        const lockoutDuration = lockoutExpiryTime - currentTime;

        dispatch(
          update({
            selectedLockoutDuration: lockoutDuration,
            selectedLockoutStartTime: lockStartTime,
            selectedLockoutExpireTime: bookingLockRes.data.expire_time,
          }),
        );

        const holeSelectionAddress =
          "/booking-engine/" + golfCourseName + "/" + handle + "/holes" + "?id=" + tempId + "&date=" + tempDate;

        sendToNextPage(holeSelectionAddress);
      }
    } else {
      const loginAddress =
        "/booking-engine/" + golfCourseName + "/" + handle + "/Login" + "?id=" + tempId + "&date=" + tempDate;

      sendToNextPage(loginAddress);
    }
  }

  function filterTeeTimeCards(teeTimeCards: ITeeTimeCard[]) {
    return teeTimeCards.filter(teeTimeCard => {
      if (bookingEngineStore?.singleBookings === "allow_within_group" && parseInt(filterState.selectedPlayers) === 1) {
        return teeTimeCard.quantity_remaining < 4;
      } else {
        return (
          (filterState.selectedPlayers === "any" ||
            teeTimeCard.quantity_remaining >= parseInt(filterState.selectedPlayers)) &&
          (filterState.selectedHoles === "any" || teeTimeCard.holes.includes(parseInt(filterState.selectedHoles))) &&
          (filterState.selectedCart === "any" ||
            ((teeTimeCard.cart_rule === "none" || teeTimeCard.cart_rule === "optional") &&
              filterState.selectedCart === "no") ||
            ((teeTimeCard.cart_rule === "required" || teeTimeCard.cart_rule === "optional") &&
              filterState.selectedCart === "yes")) &&
          (bookingEngineStore?.singleBookings === "allow" ||
            bookingEngineStore?.singleBookings === "allow_within_group" ||
            (bookingEngineStore?.singleBookings === "none" && teeTimeCard?.quantity_remaining >= 2))
        );
      }
    });
  }

  async function handleNavigateToPreviousTeeTimePage() {
    const newTeeTimeOffset = filterState.teeTimeOffset - filterState.teeTimeLimit;

    if (newTeeTimeOffset >= 0) {
      updateFilterState({ teeTimeOffset: newTeeTimeOffset });
      await loadTeeInformation(
        filterState.selectedDate,
        filterState.selectedCurrentActiveUser,
        filterState.courses.length,
        filterState.selectedFacilityId,
        newTeeTimeOffset,
        filterState.teeTimeLimit,
        filterDayState,
      );
    }
  }

  async function handleNavigateToNextTeeTimePage() {
    const newTeeTimeOffset = filterState.teeTimeOffset + filterState.teeTimeLimit;

    updateFilterState({ teeTimeOffset: newTeeTimeOffset });
    await loadTeeInformation(
      filterState.selectedDate,
      filterState.selectedCurrentActiveUser,
      filterState.courses.length,
      filterState.selectedFacilityId,
      newTeeTimeOffset,
      filterState.teeTimeLimit,
      filterDayState,
    );
  }

  return (
    <div className="cttl-page-container">
      <CustomerNavigation
        activeUser={filterState.selectedCurrentActiveUser}
        userFirstName={filterState.selectedUserFirstname}
        weather={undefined}
      />
      <div className="cttl-wide-section-container">
        <div className="cttl-wide-section">
          <div className="cttl-course-information-section">
            <div>
              <img
                style={{ visibility: filterState.selectedCourseLogo ? "visible" : "hidden" }}
                className="cttl-course-logo"
                src={filterState.selectedCourseLogo}
              />
            </div>
            <div>
              <div className="cttl-course-name">{filterState.selectedCourseName}</div>
              <div className="cttl-course-secondary-information">{filterState.selectedCourseAddressLine1}</div>
              {filterState.selectedCourseAddressLine2 && (
                <div className="cttl-course-secondary-information">{filterState.selectedCourseAddressLine2}</div>
              )}
              {filterState.bookingWindowTitle && (
                <div className="cttl-course-secondary-information">{filterState.bookingWindowTitle}</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="cttl-narrow-section-container">
        <div className="cttl-narrow-section">
          <div className="cttl-date-selection">
            <div className="cttl-date-selector">
              <DatePickerInput
                months={1}
                position="left"
                startingDate={filterState.selectedDate}
                setStartingDate={handleSelectedDateChange}
              />
            </div>
            <div className="cttl-date-bar">
              <DateBar numberOfDays={8} selectedDate={filterState.selectedDate} onClick={handleSelectedDateChange} />
            </div>
          </div>
        </div>
      </div>
      <div className="cttl-wide-section-container">
        <div className="cttl-wide-section">
          <div className="cttl-main-sections">
            <div className="cttl-filter-section">
              <div className="cttl-filters">
                <div>
                  <div className="cttl-filter-header">{t("guest.customer.customer_tee_time_list.008")}</div>
                  <Radio.Group
                    name="selectedPlayers"
                    onChange={selectedPlayers => updateFilterState({ selectedPlayers: selectedPlayers as string })}
                    value={filterState.selectedPlayers}
                  >
                    {playerOptions.map(player => {
                      return (
                        <Radio.Button key={`players_${player.value}`} value={player.value} disabled={player?.disabled}>
                          {player.title}
                        </Radio.Button>
                      );
                    })}
                  </Radio.Group>
                </div>
                <div>
                  <div className="cttl-filter-header">{t("guest.customer.customer_tee_time_list.009")}</div>
                  <Radio.Group
                    name="selectedHoles"
                    onChange={(selectedHoles: string) => updateFilterState({ selectedHoles: selectedHoles })}
                    value={filterState.selectedHoles}
                  >
                    {holeOptions.map(hole => {
                      return (
                        <Radio.Button key={`holes_${hole.value}`} value={hole.value}>
                          <span>{hole.title}</span>
                          {hole.value !== "any" ? (
                            <FontAwesomeIcon className="cttl-filter-golf-icon" icon={["far", "golf-flag-hole"]} />
                          ) : null}
                        </Radio.Button>
                      );
                    })}
                  </Radio.Group>
                </div>

                {filterState.enable_cart_bookings ? (
                  <div>
                    <div className="cttl-filter-header">{t("guest.customer.customer_tee_time_list.010")}</div>
                    <Radio.Group
                      name="selectedCart"
                      onChange={(selectedCart: string) => updateFilterState({ selectedCart: selectedCart })}
                      value={filterState.selectedCart}
                    >
                      {cartOptions.map(cartOption => {
                        return (
                          <Radio.Button key={`cart_${cartOption.value}`} value={cartOption.value}>
                            {cartOption.title}
                          </Radio.Button>
                        );
                      })}
                    </Radio.Group>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="cttl-tee-time-section">
              <div>
                {filterState.notifications.map((notification, index) => {
                  return (
                    <Callout key={index} type="info" title={notification.subject} content={notification.content} />
                  );
                })}
                {filterState.selectedTeeTimeCards.length === 0 && (
                  <Callout
                    type="error"
                    title={t("guest.customer.customer_tee_time_list.011")}
                    content={t("guest.customer.customer_tee_time_list.012")}
                  />
                )}
                {filterState.selectedTeeTimeCards.length !== 0 &&
                  filterTeeTimeCards(filterState.selectedTeeTimeCards).length === 0 && (
                    <Callout
                      type="error"
                      title={t("guest.customer.customer_tee_time_list.013")}
                      content={t("guest.customer.customer_tee_time_list.014")}
                    />
                  )}
              </div>
              <div className="cttl-tee-times">
                {filterTeeTimeCards(filterState.selectedTeeTimeCards).map((teetime, index) => {
                  return (
                    <div key={index}>
                      <CustomerTeeTimeCard
                        time={teetime.convertedTime}
                        isPrepaid={teetime.prepaid}
                        isShotgun={teetime.blocked_type === "shotgun"}
                        spotsRemaining={teetime.quantity_remaining}
                        holes={teetime.holes}
                        cartRule={filterState.enable_cart_bookings ? teetime.cart_rule : "none"}
                        eighteenHolePrice={teetime.eighteen_hole_price}
                        nineHolePrice={teetime.nine_hole_price}
                        onClick={() => CheckTeeTime(teetime.date, teetime.id, teetime.courseName)}
                        courseName={filterState.courses?.length > 1 ? teetime.courseName : null}
                        divisionTitle={teetime.divisionTitle}
                        singleBookings={bookingEngineStore?.singleBookings}
                        startingHole={teetime.startingHole}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="cttl-tee-time-page-navigation">
                <button
                  className="cttl-tee-time-page-navigation-button-previous"
                  onClick={() => handleNavigateToPreviousTeeTimePage()}
                >
                  <FontAwesomeIcon icon={"chevron-left"} /> {t("guest.customer.customer_tee_time_list.015")}
                </button>
                <button
                  className="cttl-tee-time-page-navigation-button-next"
                  onClick={() => handleNavigateToNextTeeTimePage()}
                >
                  {t("guest.customer.customer_tee_time_list.016")} <FontAwesomeIcon icon={"chevron-right"} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
