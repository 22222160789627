export interface IOnlineStoreAction {
  type: string;
  payload: Partial<IOnlineStoreState>;
}

export interface IActiveFormResponse {
  id: number;
}

export interface IOnlineStoreState {
  productSearchQuery: string;
  activeFormResponses: IActiveFormResponse[];
}

const initialState: IOnlineStoreState = {
  productSearchQuery: "",
  activeFormResponses: null,
};

export default function OnlineStoreReducer(state = initialState, action: IOnlineStoreAction) {
  switch (action.type) {
    case "onlineStore.update":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
