import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetTeeDecks } from "api/rpc/2024-04/facilityAdmin/facility/teedeck";

import { showError } from "redux/actions/ui";
import { ITeeDeck } from "redux/reducers/models/facility";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import Page from "components/page/Page";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";

export default function TeeDecks() {
  const history = useHistory();
  const { t } = useTranslation();

  const facilityStore = useAppSelector(store => store.facilityStore);
  const dispatch = useAppDispatch();

  const [teeDecks, setTeeDecks] = useState<ITeeDeck[]>(undefined);

  const courseOptions = facilityStore.facility?.courses.map(course => {
    return { label: course.full_name, value: course.id };
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadTeeDecks(source.token);
    return () => source.cancel();
  }, []);

  async function loadTeeDecks(token?: CancelToken) {
    if (teeDecks !== undefined) {
      setTeeDecks(undefined);
    }

    const res = await GetTeeDecks(null, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading TeeDecks.")); // TODO: Translation
      return;
    }

    setTeeDecks(res.status !== StatusCode.OK ? [] : res.data);
  }

  const primaryAction = {
    content: t("secure.facility.settings.facility.tee_decks.001"),
    action: () => history.push("/admin/settings/tee_deck/new"),
  };

  return (
    <Page title={t("secure.facility.settings.facility.tee_decks.002")} narrow primaryAction={primaryAction}>
      <DataTable
        columns={[
          { label: t("secure.facility.settings.facility.tee_decks.003"), width: "50%" },
          { label: courseOptions?.length > 1 ? t("secure.facility.settings.facility.tee_decks.004") : "" },
        ]}
        loading={teeDecks === undefined}
      >
        {teeDecks?.map(deck => (
          <tr
            key={deck.id}
            className="clickable"
            onClick={() => history.push("/admin/settings/tee_deck/" + String(deck.id))}
          >
            <td>{deck.title}</td>
            <td>
              {courseOptions?.length > 1 && courseOptions.find(course => course.value === deck.course_id)?.label}
            </td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
