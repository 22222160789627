import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";

const apiVersion = "2021-01";
const adminType = "client-admin";
const baseTournamentUrl = "/" + apiVersion + "/" + adminType + "/tournament";

// GET Tournament
// Retrieve a list of tournaments
export async function GetTournament(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseTournamentUrl, params, useGlobalLoader);
}

// GET Tournament Players
// Retrieve a list of tournament players
export async function GetTournamentPlayers(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseTournamentUrl + "/participant", params, useGlobalLoader);
}

//PUT ADD Player
// Add player to tournament
export async function AddPlayerToTournament(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseTournamentUrl + "/participant", params, useGlobalLoader);
}

// POST Tournament
// Create a new tournament
export async function PostTournament(body: any, useGlobalLoader: boolean) {
  return await APIPost(baseTournamentUrl, body, useGlobalLoader);
}

// PUT Tournament
// Update an existing tournament
export async function PutTournament(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseTournamentUrl, params, useGlobalLoader);
}
