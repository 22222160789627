import React, { useEffect, useState } from "react";

import { IBookingEngineState } from "redux/reducers/bookingEngine";
import { IBookingEngineActions } from "redux/actions/actionTypes";
import { IUIActions } from "redux/actions/ui";
import { UserActive } from "api/rpc";
import { GetBookingEngine } from "api/rpc/2022-09/guest/bookingEngine";
import { useHistory, useParams } from "react-router-dom";
import { StatusCode } from "api/protocols";
import { FilterMenu } from "components/filterMenu/FilterMenu";
import "./UserMenuNav.scss";
import { CustomerNavigation } from "elements/customer/CustomerNavigation";
import Reservations from "containers/bookingEngine/reservations";
import SideMenu from "components/sideMenu/SideMenu";
import playerProfileOptions from "./playerProfileOptions";
import playerProfileRoutes from "./playerProfileRoutes";
import { useTranslation } from "react-i18next";

interface IProps {
  bookingEngineStore: IBookingEngineState;
  bookingEngineActions: IBookingEngineActions;
  uiActions: IUIActions;
}

interface IParams {
  bookingEngineHandle: string;
  facilityShortName: string;
}

export default function UserMenuNav(props: IProps) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { bookingEngineStore, bookingEngineActions, uiActions } = props;
  const { bookingEngineHandle, facilityShortName } = useParams<IParams>();
  const [activeUser, setActiveUser] = useState(false);

  useEffect(() => {
    void getBookingEngine();
  }, []);

  async function getBookingEngine() {
    const params = {
      handle: bookingEngineHandle,
    };

    if (bookingEngineStore.activeCustomerId == null) {
      let customerId = null;

      const activeUserRes = await UserActive(true);

      if (activeUserRes.status === StatusCode.OK) {
        customerId = activeUserRes.data.id;
        setActiveUser(true);
        bookingEngineActions.update({
          selectedUserFirstName: activeUserRes.data.first_name,
          userFullName: activeUserRes.data.full_name,
          activeCustomerId: customerId,
        });
      } else {
        //User is not logged in
        setActiveUser(false);
        uiActions.showError(t("guest.customer.customer_nav_menu.user_menu_nav.001"));
        const address = "/booking-engine/" + facilityShortName + "/" + bookingEngineHandle;
        history.push(address);
      }
    } else {
      setActiveUser(true);
    }

    const bookingEngineRes = await GetBookingEngine(params, true);

    if (bookingEngineRes.status !== StatusCode.OK) {
      alert(t("guest.customer.customer_nav_menu.user_menu_nav.002"));
      return;
    }

    bookingEngineActions.update({
      selectedCourseLogo: bookingEngineRes.data[0].facility.logo_source,
      selectedCourseFacility: bookingEngineRes.data[0].facility.long_name,
      selectedCourseLongName: bookingEngineRes.data[0].facility.long_name,
      selectedLoginRequired: bookingEngineRes.data[0].requires_login,
      selectedFacilityId: bookingEngineRes.data[0].facility_id,
      selectedBookingEngineId: bookingEngineRes.data[0].id,
      selectedBookingTerms: bookingEngineRes.data[0].booking_terms,
      paymentTerms: bookingEngineRes.data[0].payment_terms,
      enabled_payment_methods: bookingEngineRes.data[0].enabled_payment_methods,
      courseLogo: bookingEngineRes.data[0]?.facility.logo_source,
      facilityClientId: bookingEngineRes.data[0].facility?.client_id,
    });
  }

  return (
    <>
      {activeUser && (
        <>
          <CustomerNavigation
            //uiActions={uiActions}
            activeUser={activeUser}
            userFirstName={bookingEngineStore.selectedUserFirstName}
            weather={undefined}
          />
          <div className="user-menu-nav">
            <div className="user-menu-nav-menu">
              <SideMenu
                title={t("guest.customer.customer_nav_menu.user_menu_nav.003")}
                options={playerProfileOptions(t)}
                optionsRoutes={playerProfileRoutes}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}
