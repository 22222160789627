import React from "react";
import { LocaleCurrency } from "helpers/Locale";
import "./cartTotalsNew.scss";
import Tip from "components/tip/Tip";
import { ICart, ICartTaxLine } from "redux/reducers/models/cart";
import { isEmpty } from "helpers/Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IOrder } from "redux/reducers/models/order";
interface IProps {
  subTotal: number;
  total: number;
  taxLines: Array<ICartTaxLine>;
  handleTipTeeSheet?: (tipAmount: number) => void;
  totalTip?: number;
  currency?: string;
  order?: IOrder;
  cart?: ICart;
}

export default function CartTotalsNew(props: IProps) {
  const { currency, subTotal, total, taxLines, totalTip, handleTipTeeSheet, order, cart } = props;

  return (
    <div className="cart-totals-new carts-totals-new-margin-top">
      <div className="cart-total-row">
        <span className="cart-total-title">Subtotal</span>
        <span className="cart-total-title">
          {subTotal != null ? (
            <LocaleCurrency currency={currency ?? "cad"} amount={subTotal} />
          ) : (
            <FontAwesomeIcon className="cart-totals-new-spinner" size="1x" icon={["fad", "spinner-third"]} spin fade />
          )}
        </span>
      </div>

      <div>
        {taxLines &&
          taxLines?.map(tax_line => {
            return (
              <div key={tax_line.title} className="cart-total-row">
                <span className="cart-total-title">
                  {tax_line.title} <span className="cart-total-subtitle">{(tax_line.rate * 100).toFixed(2)}%</span>
                </span>
                <span className="cart-total-title">
                  <LocaleCurrency currency={currency ?? "cad"} amount={tax_line.price} />
                </span>
              </div>
            );
          })}
      </div>

      <div className="cart-total-row">
        {totalTip > 0 || !!props?.order || cart?.status === "void" ? (
          <span className="cart-total-title">Tip</span>
        ) : (
          <span></span>
        )}
        {totalTip != null ? (
          <span className="cart-total-title">
            <Tip handleTipTeeSheet={handleTipTeeSheet} totalTipAmount={totalTip} totalCartPrice={total} order={order} />
          </span>
        ) : (
          <FontAwesomeIcon className="cart-totals-new-spinner" size="1x" icon={["fad", "spinner-third"]} spin fade />
        )}
      </div>

      <div className="cart-total-row carts-totals-new-margin-top">
        <span className="cart-total-title cart-total-title-bold">Total</span>
        <span className="cart-total-title cart-total-title-bold">
          {total != null ? (
            <LocaleCurrency currency={currency ?? "cad"} amount={total} />
          ) : (
            <FontAwesomeIcon className="cart-totals-new-spinner" size="1x" icon={["fad", "spinner-third"]} spin fade />
          )}
        </span>
      </div>
    </div>
  );
}
