import { CancelToken } from "axios";
import { APIDelete, APIGet, APIPost, APIPut, IAPIResponse } from "api/protocols";
import { ICustomer } from "redux/reducers/models/customer";
import { IFacility } from "redux/reducers/models/facility";

const apiVersion = "2024-04";
const adminType = "client-admin";
const baseRainCheckUrl = "/" + apiVersion + "/" + adminType + "/tee-sheet/rain-check";

interface IEmailRainCheck {
  code?: string;
  id?: number;
  customer_email: string;
}

interface IGetRainCheck {
  id?: number;
  customer_id?: number;
  code?: string;
  offset?: number;
  limit?: number;
}

export interface IRainCheck {
  id: number;
  last4: string;
  balance: number;
  currency: string;
  facility_id: number;
  client_id: number;
  customer_id: number;
  status: string;
  order_id: number;
  expires_at: string;
  note: string;
  admin_id: number;
  created_at: string;
  customer: ICustomer;
  facility?: IFacility;
}

interface IGetRainCheckResponse extends IAPIResponse {
  data: IRainCheck[];
}

export async function GetRainChecks(
  params: IGetRainCheck,
  useGlobalLoader: boolean,
  token?: CancelToken,
): Promise<IGetRainCheckResponse> {
  return await APIGet(baseRainCheckUrl, params, useGlobalLoader, token);
}

export async function GetRainCheckReceipt(params: { id: number }, useGlobalLoader: boolean) {
  return await APIGet(baseRainCheckUrl + "/receipt", params, useGlobalLoader);
}

export async function EmailRainCheckReceipt(params: IEmailRainCheck, useGlobalLoader: boolean) {
  return await APIPut(baseRainCheckUrl + "/email", params, useGlobalLoader);
}
