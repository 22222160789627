import { APIPost, APIPut } from "api/protocols";

const apiVersion = "2022-09";
const adminType = "guest";

const baseTransactionUrl = "/" + apiVersion + "/" + adminType + "/order/transaction";

export interface IPostTransaction {
  processing_type: string;
  order_token: string;
  kind: string;
  source: string;
  amount: number;
  payment_method: string;
  payment_method_id?: number;
}

export interface IPutCapture {
  payment_intent_id: string;
  transaction_id: number;
}

export async function PostTransaction(params: IPostTransaction, useGlobalLoader: boolean) {
  return await APIPost(baseTransactionUrl, params, useGlobalLoader);
}

export async function PutCapture(params: IPutCapture, useGlobalLoader: boolean) {
  return await APIPut(baseTransactionUrl + "/capture", params, useGlobalLoader);
}
