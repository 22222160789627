import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { GetDepartments } from "api/rpc/2024-04/masterAdmin/facility/department";

import Page from "components/page/Page";
import Card from "components/card/Card";
import { StatusCode } from "api/protocols";

import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import "./departments.scss";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { showError, showSuccess } from "redux/actions/ui";

export default function Departments(props: any) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { masterFacilityStore } = useAppSelector(store => store);
  const dispatch = useAppDispatch();
  const [departmentState, setDepartmentTypeState] = useState({
    department: null,
  });

  const departmentIcon: IconProp = ["fas", "circle"];
  const categoryIcon: IconProp = ["fas", "circle"];
  const subcategoryIcon: IconProp = ["far", "circle"];
  const chevronUp: IconProp = ["far", "chevron-up"];
  const chevronDown: IconProp = ["far", "chevron-down"];

  useEffect(() => {
    void loadDepartments();

    return () => {
      setDepartmentTypeState({ department: null });
    };
  }, [masterFacilityStore.facility]);

  async function loadDepartments() {
    const res = await GetDepartments({ client_id: masterFacilityStore.facility?.client_id }, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error laoding departments"));
      return;
    }

    const departments = res.data;

    {
      departments
        ?.filter((filteredDepart: any) => filteredDepart.type === "department")
        .map((department: any) => {
          department.selected = true;

          if (department.cateogries.length > 0) {
            department.cateogries.map((category: any) => {
              category.selected = true;
            });
          }
        });
    }

    setDepartmentTypeState(prev => ({
      ...prev,
      department: departments,
    }));
  }

  function navigateToNewDepartment() {
    history.push("/admin/facility/settings/departments/new");
  }

  function navigateToEditDepartment(url: string) {
    history.push(url);
  }

  const primaryAction = {
    content: t("secure.facility.settings.departments.departments.001"),
    action: navigateToNewDepartment,
    disabled: !masterFacilityStore.facility,
  };

  function handleDepartmentSelect(department: any, category?: any) {
    const departments = departmentState.department;

    if (category === null || category === undefined) {
      {
        departments
          ?.filter((filteredDepart: any) => filteredDepart.id === department.id)
          .map((department: any) => {
            department.selected = !department.selected;
          });
      }

      setDepartmentTypeState(prev => ({
        ...prev,
        department: departments,
      }));
    } else {
      {
        departments
          ?.filter((filteredDepart: any) => filteredDepart.id === department.id)
          .map((department: any) => {
            department.cateogries
              ?.filter((filteredCategory: any) => filteredCategory.id === category.id)
              .map((category: any) => {
                category.selected = !category.selected;
              });
          });
      }

      setDepartmentTypeState(prev => ({
        ...prev,
        department: departments,
      }));
    }
  }

  return (
    <Page
      title={t("secure.facility.settings.departments.departments.002")}
      subtitle={masterFacilityStore.facility ? masterFacilityStore.facility.long_name : "No Facility Selected"}
      narrow
      primaryAction={primaryAction}
    >
      {masterFacilityStore.facility && (
        <Card>
          <Card.Section>
            <div className="flex flex-col mb-4">
              <p className="text-lg text-semibold mb-1">Departments</p>
              <div className="flex">
                <p className="text-md mr-3 text-gray-400">
                  Departments <FontAwesomeIcon icon={departmentIcon} style={{ color: "#6888C5" }} />
                </p>
                <p className="text-md mr-3 text-gray-400">
                  Categories <FontAwesomeIcon icon={categoryIcon} style={{ color: "#CDD7EC" }} />
                </p>
                <p className="text-md text-gray-400">
                  Subcategories <FontAwesomeIcon icon={subcategoryIcon} />
                </p>
              </div>
            </div>

            <div className="departments-container">
              {/* Map through departments */}
              {departmentState.department
                ?.filter((filteredDepart: any) => filteredDepart.type === "department")
                .map((department: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <div key={index} className="department-card">
                        <div
                          onClick={() =>
                            navigateToEditDepartment("/admin/facility/settings/departments/" + String(department.id))
                          }
                        >
                          <p className="department-title">{department.title}</p>
                        </div>

                        <div>
                          <p style={{ fontSize: "20px" }} onClick={() => handleDepartmentSelect(department)}>
                            <FontAwesomeIcon icon={department.selected ? chevronDown : chevronUp} />
                          </p>
                        </div>
                      </div>

                      {/* Map through categories */}
                      {department.selected ? (
                        <div>
                          {department.cateogries?.map((category: any, index: number) => {
                            return (
                              <React.Fragment key={index}>
                                <div key={index} className="category-card">
                                  <div
                                    onClick={() =>
                                      navigateToEditDepartment(
                                        "/admin/facility/settings/departments/" + String(category.id),
                                      )
                                    }
                                  >
                                    <p className="department-title" style={{ textIndent: "4rem" }}>
                                      {category.title}
                                    </p>
                                  </div>

                                  <div>
                                    <p
                                      style={{ fontSize: "20px" }}
                                      onClick={() => handleDepartmentSelect(department, category)}
                                    >
                                      <FontAwesomeIcon icon={category.selected ? chevronDown : chevronUp} />
                                    </p>
                                  </div>
                                </div>

                                {/* Map through subcategories */}
                                {category.selected ? (
                                  <div>
                                    {category.subcategories?.map((subcategory: any, index: number) => {
                                      return (
                                        <div
                                          key={index}
                                          onClick={() =>
                                            navigateToEditDepartment(
                                              "/admin/facility/settings/departments/" + String(subcategory.id),
                                            )
                                          }
                                          className="subcategory-card"
                                        >
                                          <p style={{ textIndent: "7rem" }}>{subcategory.title}</p>
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : null}
                              </React.Fragment>
                            );
                          })}
                        </div>
                      ) : null}
                    </React.Fragment>
                  );
                })}
            </div>
          </Card.Section>
        </Card>
      )}
    </Page>
  );
}
