import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { ButtonNew as Button } from "components/buttonNew";
import Icon from "components/icon/Icon";

import "components/form/form.scss";
import "components/form/select/select.scss";

interface ISelectProps {
  label?: string;
  value?: string | number;
  name?: string;
  onChange?: (event: any) => void;
  disabled?: string;
  options?: {
    label?: string;
    value?: string | number;
    prefix?: string;
  }[];
  labelInline?: boolean;
  labelHidden?: boolean;
  showOptionLabel?: boolean;
  selected?: any;
  id?: any;
}

const Select: React.FC<ISelectProps> = props => {
  const { label, value, name, onChange, disabled, options, labelInline, labelHidden, selected, id, showOptionLabel } =
    props;

  function findOptionLabel() {
    for (let i = 0; i < options.length; i++) {
      let convertedValue = value;
      if (typeof value === "string" && typeof options[i].value === "number") {
        convertedValue = parseInt(value);
      } else if (typeof value === "number" && typeof options[i].value === "string") {
        convertedValue = value.toString();
      }
      if (options[i].value === convertedValue) {
        return options[i].label;
      }
    }
    return "";
  }

  return (
    <div className="ui-select ui-select-field">
      {!labelHidden ? (
        <div className="ui-select-label_container">
          <div className="ui-select-label">
            <label style={{ marginBottom: 0 }}>{label}</label>
          </div>
        </div>
      ) : null}

      <div
        className={classNames("ui-select_wrapper", {
          "ui-select_wrapper-disabled": disabled,
        })}
      >
        <select
          className="w-full h-full top-0 left-0 z-30 opacity-0 absolute text-black appearance-none border-none focus:outline-none placeholder-gray-200"
          aria-invalid="false"
          name={name}
          value={value}
          onChange={onChange}
          id={id}
        >
          {options ? (
            <>
              {options.map((option, index: number) => (
                <option value={option.value} key={index}>
                  {option.label}
                </option>
              ))}
            </>
          ) : null}
        </select>
        <div className="w-full h-10 z-20 flex text-md border-none relative items-center p-2 pl-5">
          {labelInline ? <span className="text-base font-medium mr-2">{label}</span> : null}

          {/* <span className="ui-select_selected-prefix"><FontAwesomeIcon icon={['fas', 'caret-up']} /></span> */}
          <span className="flex-1">{showOptionLabel && !!options && !!value ? findOptionLabel() : value}</span>
          <span className="block w-6 h-6 text-black">
            <Icon style="fas" icon="sort-down" />
          </span>
        </div>
        <div className="z-10 top-0 left-0 right-0 bottom-0 border border-gray-200 bg-white rounded absolute"></div>
      </div>
    </div>
  );
};

export default Select;
