import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";

const apiVersion = "2021-01";
const adminType = "facility-admin";
const baseProductUrl = "/" + apiVersion + "/" + adminType + "/vendor";

// GET Vendors
export async function GetVendor(params: string, useGlobalLoader: boolean) {
  return await APIGet(baseProductUrl + "?" + params, useGlobalLoader);
}
