import React, { useState, useEffect } from "react";

import { valueToString } from "helpers/Helpers";

import Layout from "components/layout/index";
import { useHistory, Link } from "react-router-dom";

import Page from "components/page/Page";
import Card from "components/card/Card";
import { ButtonNew as Button } from "components/buttonNew";

import Category from "elements/settings/Category";
import { StatusCode } from "api/protocols";

import { useTranslation } from "react-i18next";
import { GetCardReaders } from "api/rpc/2024-04/facilityAdmin/payment/teeonPayments/reader";
import axios, { CancelToken, CancelTokenSource } from "axios";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";

export default function CardReaders(props: any) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  function navigateToNewCardReader() {
    history.push("/admin/settings/card_reader/new");
  }

  const primaryAction = {
    content: t("secure.facility.settings.card_reader.card_readers.001"),
    action: navigateToNewCardReader,
  };

  function navigateTo(url: string) {
    history.push(url);
  }

  const [state, setState] = useState({
    cardReaders: [],
  });

  // Load information need for page
  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadCardReaders(source.token);

    return () => source.cancel();
  }, []);

  async function loadCardReaders(token: CancelToken) {
    const res = await GetCardReaders(undefined, false, token);

    if (res.status === StatusCode.OK) {
      setState(prevState => ({ ...prevState, cardReaders: res.data }));
    }
  }

  return (
    <Page
      title={t("secure.facility.settings.card_reader.card_readers.002")}
      subtitle={t("secure.facility.settings.card_reader.card_readers.003")}
      narrow
      primaryAction={primaryAction}
    >
      <Card>
        <Card.Section table="true">
          <DataTable
            columns={[
              { label: t("secure.facility.settings.card_reader.card_readers.004") },
              { label: t("secure.facility.settings.card_reader.card_readers.005") },
              { label: t("secure.facility.settings.card_reader.card_readers.006") },
            ]}
            loading={state.cardReaders.length === 0}
          >
            {state.cardReaders
              ? state.cardReaders.map((reader: any, index: number) => (
                  <tr key={index} onClick={() => navigateTo("/admin/settings/card_reader/" + String(reader.id))}>
                    <td className="table-cell-text-lead">{reader.label}</td>
                    <td>{valueToString(reader.device_type)}</td>
                    <td>{reader.serial_number}</td>
                  </tr>
                ))
              : null}
          </DataTable>
        </Card.Section>
      </Card>
    </Page>
  );
}
