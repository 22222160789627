import React, { useState, useEffect } from "react";
import axios, { CancelToken } from "axios";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { StatusCode } from "api/protocols";
import { GetRegisterGroups } from "api/rpc/2022-09/facilityAdmin/facility/register/group";

import Page from "components/page/Page";
import { IRegisterGroup } from "../../Admin";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";
import { IPrimaryPageAction } from "components/page/PageActions";
import { useAppDispatch } from "hooks/redux";
import { showError } from "redux/actions/ui";

export default function Groups() {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();

  const [registerGroups, setRegisterGroups] = useState<IRegisterGroup[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadRegisterGroups(source.token);
    return () => source.cancel();
  }, []);

  async function loadRegisterGroups(token?: CancelToken) {
    if (registerGroups !== undefined) {
      setRegisterGroups(undefined);
    }

    const { status, data } = await GetRegisterGroups(undefined, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (status !== StatusCode.OK) {
      dispatch(showError("Error loading register groups."));
    }

    setRegisterGroups(status !== StatusCode.OK ? [] : data);
  }

  const primaryAction: IPrimaryPageAction = {
    content: t("secure.facility.settings.register.groups.008"),
    // content: t("secure.facility.settings.register.groups.001"),
    action: () => history.push("/admin/settings/register-group/new"),
  };

  return (
    <Page
      title={t("secure.facility.settings.register.groups.002")}
      subtitle={t("secure.facility.settings.register.groups.003")}
      narrow
      primaryAction={primaryAction}
    >
      <hr className="my-8" />
      <DataTable
        columns={[
          { label: t("secure.facility.settings.register.groups.004"), width: "35%" },
          { label: t("secure.facility.settings.register.groups.005"), width: "35%" },
          { label: t("secure.facility.settings.register.groups.006"), width: "30%" },
        ]}
        loading={registerGroups === undefined}
      >
        {registerGroups?.map(group => (
          <tr
            key={group.id}
            className="clickable"
            onClick={() => history.push("/admin/settings/register-group/" + String(group.id))}
          >
            <td>{group.title}</td>
            <td>
              <p>
                {group.registers.length} {t("secure.facility.settings.register.groups.007")}
              </p>
            </td>
            <td>{group.tipping_type_readable}</td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
