import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetTournamentTeams } from "api/rpc/2024-04/facilityAdmin/tournament/scoring/teams";

import { showError } from "redux/actions/ui";
import { TTournamentTeam } from "redux/reducers/models/tournament";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

export default function TournamentTeams() {
  const history = useHistory();
  const { tournamentId } = useParams<{ tournamentId: string }>();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [teams, setTeams] = useState<TTournamentTeam[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadTournamentTeams(Number(tournamentId), source.token);
    return () => source.cancel();
  }, [tournamentId]);

  async function loadTournamentTeams(tournament_id: number, token?: CancelToken) {
    if (teams !== undefined) {
      setTeams(undefined);
    }
    const res = await GetTournamentTeams({ tournament_id }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error getting tournament teams.")); // TODO: Translation
    }

    setTeams(res.status !== StatusCode.OK ? [] : res.data);
  }

  const primaryAction = {
    content: "New Team", // TODO: Translation
    action: () => history.push(`/admin/tournament/${tournamentId}/teams/new`),
  };

  return (
    <Page
      title={"Tournament Teams"} // TODO: Translation
      narrow
      primaryAction={primaryAction}
    >
      <DataTable columns={[{ label: "Name" } /* TODO: Translation */]} loading={teams === undefined}>
        {teams?.map(team => (
          <tr
            key={team.id}
            className="clickable"
            onClick={() => history.push(`/admin/tournament/${tournamentId}/teams/${String(team.id)}/edit`)}
          >
            <td>{team?.name}</td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
