import React, { useEffect, useState } from "react";
import axios, { CancelToken } from "axios";
import { useParams } from "react-router";
import { useAppDispatch } from "hooks/redux";

import { GetTvView, GetTvViewSlide } from "api/rpc/2024-04/facilityAdmin/league/tvView/tvView";
import { GetLeagueRound, GetLeagueRoundHoleScores } from "api/rpc/2024-04/facilityAdmin/league/scoring/round";
import { GetLeagueTeamScores } from "api/rpc/2024-04/facilityAdmin/league/scoring/teams";
import { GetLeagueCompetitionFormat } from "api/rpc/2024-04/facilityAdmin/league/scoring/format";
import { StatusCode } from "api/protocols";

import { showError } from "redux/actions/ui";

import Page from "components/page/Page";
import Slideshow from "components/slideshow/Slideshow";
import Spin from "components/spin/spin";
import { IndividualScoresTable } from "../scoresheets/results/tables/IndividualScoresTable";
import { TeamScoresTable } from "../scoresheets/results/tables/TeamScoresTable";
import { SkinsTable } from "../scoresheets/results/tables/SkinsTable";
import {
  TLeagueTvView,
  TLeagueTvViewSlide,
  TLeagueRoundFormat,
  TEventRound,
  TLeagueTeamScores,
} from "redux/reducers/models/league";

import "./tvView.scss";

export default function LeagueTvView() {
  const { leagueId, viewId } = useParams<{ leagueId: string; viewId: string }>();
  const dispatch = useAppDispatch();

  const [view, setView] = useState<TLeagueTvView>(undefined);
  const [slides, setSlides] = useState<TLeagueTvViewSlide[]>(undefined);
  const [currentSlide, setCurrentSlide] = useState<TLeagueTvViewSlide>(undefined);
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
  const [currentFormat, setCurrentFormat] = useState<TLeagueRoundFormat>(undefined);
  const [currentRounds, setCurrentRounds] = useState<TEventRound[]>(undefined);
  const [currentTeamScores, setCurrentTeamScores] = useState<TLeagueTeamScores[]>(undefined);
  const [currentHoleScores, setCurrentHoleScores] = useState<TEventRound[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadTvView(source.token);
    return () => source.cancel();
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (currentSlide) {
      void loadSlideInfo(source.token);
    }
    return () => source.cancel();
  }, [currentSlide]);

  async function loadTvView(token?: CancelToken) {
    setSlides(undefined);

    const viewRes = await GetTvView({ id: viewId }, true, token);

    if (token && token.reason) {
      return;
    }
    if (viewRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading TV view")); // TODO: Translation
      return;
    }

    const slidesRes = await GetTvViewSlide({ tv_view_id: viewId }, true, token);

    if (slidesRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading TV view slides")); // TODO: Translation
      return;
    }

    setView(viewRes.data[0]);
    setCurrentSlide(slidesRes.data[0]);
    setSlides(slidesRes.data);
  }

  async function loadSlideInfo(token?: CancelToken) {
    const formatRes = await GetLeagueCompetitionFormat(
      { id: currentSlide.scoresheet_format_id, scoresheet: true },
      false,
      token,
    );

    if (formatRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading format")); // TODO: Translation
      return;
    }

    const format = formatRes.data[0];

    setCurrentFormat(format);

    if (format.format === "skins") {
      const roundsRes = await GetLeagueRound({ league_id: Number(leagueId), format_id: format.id }, false, token);

      if (roundsRes.status !== StatusCode.OK) {
        dispatch(showError("Error loading rounds")); // TODO: Translation
        return;
      }

      setCurrentRounds(roundsRes.data);

      const holeScoreRes = await GetLeagueRoundHoleScores({ format_id: format.id }, false, token);

      if (holeScoreRes.status !== StatusCode.OK) {
        dispatch(showError("Error loading hole scores")); // TODO: Translation
        return;
      }

      setCurrentHoleScores(holeScoreRes.data);
    } else if (format.organization === "individual") {
      const roundsRes = await GetLeagueRound({ league_id: Number(leagueId), format_id: format.id }, false, token);

      if (roundsRes.status !== StatusCode.OK) {
        dispatch(showError("Error loading rounds")); // TODO: Translation
        return;
      }

      setCurrentRounds(roundsRes.data);
    } else if (format.organization === "team") {
      const scoreRes = await GetLeagueTeamScores(
        { scoresheet_id: format.league_scoresheet_id, format_id: format.id },
        false,
        token,
      );

      if (scoreRes.status !== StatusCode.OK) {
        dispatch(showError("Error loading team scores")); // TODO: Translation
        return;
      }

      setCurrentTeamScores(scoreRes.data);
    }
  }

  function handleNavigation(direction: number) {
    if (!slides) {
      return;
    }

    setCurrentFormat(undefined);
    setCurrentRounds(undefined);
    setCurrentTeamScores(undefined);
    setCurrentHoleScores(undefined);

    let index = currentSlideIndex + direction;

    if (index < 0) {
      index = slides.length - 1;
    } else if (index === slides.length) {
      index = 0;
    }

    setCurrentSlideIndex(index);
    setCurrentSlide(slides[index]);
  }

  return (
    <Page>
      <div className="tv-view-header">
        <div className="tv-view-header-image-container">
          <img className="tv-view-header-image" src={view?.header_image_left_source} />
        </div>
        <div>
          <div>{currentFormat?.title}</div>
          <div className="tv-view-header-subtitle">{currentFormat?.scoresheet?.date_played}</div>
        </div>
        <div className="tv-view-header-image-container-right">
          <img className="tv-view-header-image" src={view?.header_image_right} />
        </div>
      </div>
      <Slideshow
        content={
          <div>
            {currentFormat ? (
              <div>
                {currentFormat.organization === "individual" && currentFormat.format !== "skins" && (
                  <IndividualScoresTable
                    format={currentFormat}
                    rounds={currentRounds}
                    flights={[]}
                    holeScoreRound={null}
                    selectedRoundId={null}
                    onClick={() => {}}
                  />
                )}
                {currentFormat.organization === "individual" && currentFormat.format === "skins" && (
                  <div>
                    {currentHoleScores ? (
                      <SkinsTable
                        format={currentFormat}
                        holeScoreRounds={currentHoleScores}
                        rounds={currentRounds}
                        defaultListView
                      />
                    ) : (
                      <div style={{ height: "24px" }}>
                        <Spin />
                      </div>
                    )}
                  </div>
                )}
                {currentFormat.organization === "team" && (
                  <TeamScoresTable
                    format={currentFormat}
                    teamScores={currentTeamScores}
                    rounds={null}
                    formatRounds={null}
                    selectedRoundId={null}
                    onClick={() => {}}
                  />
                )}
              </div>
            ) : (
              <span style={{ display: "flex", justifyContent: "center", margin: "auto" }}>
                <Spin />
              </span>
            )}
          </div>
        }
        slides={slides}
        selectedSlide={currentSlideIndex}
        onNavigateLeft={() => handleNavigation(-1)}
        onNavigateRight={() => handleNavigation(1)}
        autoPlay={view?.auto_play}
        interval={view?.auto_play_interval * 1000}
        onAutoPlay={() => handleNavigation(1)}
      />
    </Page>
  );
}
