import React, { useMemo, useState } from "react";
import Sheet from "components/sheet/Sheet";
import Portal from "elements/Portal";
import CheckboxBlock from "pages/secure/facility/settings/forms/elements/CheckboxBlock";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "hooks/redux";
import TimePick from "components/timePick/TimePick";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import { Select } from "components/select/index";
import { ICourse, IFacility } from "redux/reducers/models/facility";

import "./printTeesheetModal.scss";
import NewCheckboxGroup from "components/newCheckboxGroup/NewCheckboxGroup";

export interface IPrintTeesheet {
  startTime: string;
  endTime: string;
  date: Date;
  fontSize: number;
  selectedDivisionIds: number[];
  selectedCourseIds: number[];
  showCustomerType: boolean;
  showGuest: boolean;
  showBagStorage: boolean;
}

type TPrintTeesheetModal = {
  state: { isOpen: boolean } & IPrintTeesheet;
  update: (newState: IPrintTeesheet) => void;
  close: () => void;
  onOk: () => void;
  facility: IFacility;
};

export default function PrintTeesheetModal(props: TPrintTeesheetModal) {
  const { t } = useTranslation();
  const { state, update, close, onOk, facility } = props;

  function handleDateChange(selectedDate: Date) {
    update({ ...state, date: selectedDate });
  }

  function handleDivisionCheckboxChange(divisionId: any) {
    update({
      ...state,
      selectedDivisionIds: state.selectedDivisionIds.some(val => val === divisionId)
        ? state.selectedDivisionIds.filter(val => val !== divisionId)
        : [...state.selectedDivisionIds, divisionId],
    });
  }

  function handleCourseCheckboxChange(course: ICourse) {
    update({
      ...state,
      selectedCourseIds: state.selectedCourseIds.some(val => val === course.id)
        ? state.selectedCourseIds.filter(val => val !== course.id)
        : [...state.selectedCourseIds, course.id],

      selectedDivisionIds: !state.selectedCourseIds.includes(course?.id)
        ? state.selectedDivisionIds.some(val => val === course?.divisions[0]?.id)
          ? state.selectedDivisionIds.filter(val => val !== course?.divisions[0]?.id)
          : [...state.selectedDivisionIds, course?.divisions[0]?.id]
        : !state.selectedCourseIds.some(val => val === course.id)
        ? state.selectedDivisionIds
        : state.selectedDivisionIds.filter(id => !course.divisions.some(division => division.id === id)),
    });
  }

  return (
    <Portal isMounted={state.isOpen}>
      <Sheet
        open={state.isOpen}
        closable
        size="medium"
        cancelText={t("elements.tee_sheet.statistic_bar.016")}
        okText={t("elements.tee_sheet.statistic_bar.017")}
        title={t("elements.tee_sheet.statistic_bar.018")}
        onCancel={close}
        onOk={onOk}
      >
        <div className="mt-4 mb-4 flex gap-6">
          <div className="w-full flex flex-col justify-between">
            <h1 className="text-lg text-semibold"> {t("elements.tee_sheet.statistic_bar.019")}</h1>
            <div className="flex mt-3 gap-3">
              <div className="w-full">
                <TimePick
                  label={t("elements.tee_sheet.statistic_bar.027")}
                  value={state.startTime}
                  onChange={timeString => update({ ...state, startTime: timeString })}
                  status={state.startTime > state.endTime ? "warning" : undefined}
                  size="large"
                />
              </div>

              <div className="w-full">
                <TimePick
                  label={t("elements.tee_sheet.statistic_bar.028")}
                  value={state.endTime}
                  onChange={timeString => update({ ...state, endTime: timeString })}
                  status={state.startTime > state.endTime ? "warning" : undefined}
                  align="right"
                  size="large"
                />
              </div>
            </div>
          </div>

          <div className="w-full">
            <div>
              <DatePickerInput
                months={1}
                position="right"
                startingDate={state.date}
                setStartingDate={handleDateChange}
              />
            </div>

            <div className="mt-3">
              <Select
                label="Font Size"
                onChange={(value: number) => update({ ...state, fontSize: value })}
                defaultValue={8}
              >
                {[8, 10, 12, 14, 16].map(value => (
                  <Select.Option key={value} value={value}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
        </div>

        <div className="print-modal-container">
          <div>
            {facility && (
              <h1 className="text-lg text-semibold mb-2">
                {facility?.courses.length === 1
                  ? t("elements.tee_sheet.statistic_bar.020")
                  : t("elements.tee_sheet.statistic_bar.022")}
              </h1>
            )}
            <div className="print-modal-option-container">
              {facility && facility.courses.length === 1
                ? facility.courses[0].divisions.map(division => (
                    <CheckboxBlock
                      key={division.id}
                      fullWidth
                      label={t("elements.tee_sheet.statistic_bar.021") + String(division.title)}
                      checked={state.selectedDivisionIds.includes(division.id)}
                      onChange={() =>
                        update({
                          ...state,
                          selectedDivisionIds: state.selectedDivisionIds.some(val => val === division.id)
                            ? state.selectedDivisionIds.filter(val => val !== division.id)
                            : [...state.selectedDivisionIds, division.id],
                        })
                      }
                    />
                  ))
                : facility?.courses.map(course => (
                    <div key={course.id} style={{ minWidth: "200px" }}>
                      <NewCheckboxGroup
                        key={course.id}
                        label={course.full_name}
                        checked={state.selectedCourseIds.includes(course.id)}
                        onChange={handleCourseCheckboxChange}
                        onSecondaryChange={handleDivisionCheckboxChange}
                        secondaryContent={course?.divisions}
                        selectedSecondaryContent={state.selectedDivisionIds}
                        primaryContent={course}
                        selectedPrimaryContent={state.selectedCourseIds}
                      />
                    </div>
                  ))}
            </div>

            <h1 className="mt-4 mb-2">{t("elements.tee_sheet.statistic_bar.023")}</h1>
            <div className="print-modal-option-container">
              <CheckboxBlock
                label={t("elements.tee_sheet.statistic_bar.024")}
                onChange={() => update({ ...state, showCustomerType: !state.showCustomerType })}
                checked={state.showCustomerType}
              />
              <CheckboxBlock
                label={t("elements.tee_sheet.statistic_bar.025")}
                onChange={() => update({ ...state, showGuest: !state.showGuest })}
                checked={state.showGuest}
              />
              <CheckboxBlock
                label={t("elements.tee_sheet.statistic_bar.026")}
                onChange={() => update({ ...state, showBagStorage: !state.showBagStorage })}
                checked={state.showBagStorage}
              />
            </div>
          </div>
        </div>
      </Sheet>
    </Portal>
  );
}
