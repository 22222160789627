import { APIGet, APIPost, APIPut, APIDelete, IAPIResponse } from "api/protocols";
import { CancelToken } from "axios";
import { formatDate } from "helpers/Helpers";
import { ITeeTime } from "redux/reducers/models/teetime";

const apiVersion = "2024-04";
const baseUrl = "/" + apiVersion + "/customer";

interface IGetTeeTimes {
  facility_id: number;
  date: Date | string;
  id?: number;
  extended?: boolean;
  turn_tee_time?: boolean;
  league_id?: number;
}

interface IGetTeeTimeCustomerResponse extends IAPIResponse {
  data: ITeeTime[];
}

//GET Customer bookings
export async function GetTeeTimesCustomer(
  params: IGetTeeTimes,
  useGlobalLoader: boolean,
): Promise<IGetTeeTimeCustomerResponse> {
  const convertedParams = {
    ...params,
    date: typeof params?.date === "string" ? params?.date : formatDate(params?.date)?.toString(),
  };
  return await APIGet(baseUrl + "/tee-time", convertedParams, useGlobalLoader);
}
