import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/product/modifier";

export type TAttachModifier = {
  product_id: number;
  modifier_group_id: number;
  required?: boolean;
};

export type TDetachModifier = {
  product_id: number;
  modifier_group_id: number;
};

/** Attach a modifier group to product. */
export async function AttachModifierGroup(params: TAttachModifier, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

/** Remove the modifier group from a product. */
export async function DetachModifierGroup(params: TDetachModifier, useGlobalLoader: boolean) {
  return await APIDelete(baseUrl, params, useGlobalLoader);
}
