import FacilityStaffEdit from "pages/secure/facility/settings/facility/FacilityStaffEdit";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UIActions from "redux/actions/ui";
import * as FacilityActions from "redux/actions/facility";
import * as AuthActions from "redux/actions/auth";

const mapDispatchToProps = (dispatch: any) => {
  return {
    facilityActions: bindActionCreators(
      {
        ...FacilityActions,
      },
      dispatch,
    ),
    uiActions: bindActionCreators(
      {
        ...UIActions,
      },
      dispatch,
    ),
    authActions: bindActionCreators(
      {
        ...AuthActions,
      },
      dispatch,
    ),
  };
};

const mapStateToProps = (state: any) => {
  return {
    facilityStore: { ...state.facilityStore },
    uiStore: { ...state.uiStore },
    authStore: { ...state.authStore },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FacilityStaffEdit);
