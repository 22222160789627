import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { TCustomerTag, TTagType } from "redux/reducers/models/customer";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseTagUrl = "/" + apiVersion + "/" + adminType + "/client/tag";

type TGetTag = {
  id?: number;
  customer_id?: number;
  type?: TTagType; // "customer" | "product"
};

type TPostTag = {
  type?: TTagType; // "customer" | "product"
  label?: string;
  description?: string;
  colour?: string;
};

//Get tags
export async function GetTag(params: TGetTag, useGlobalLoader?: boolean, token?: CancelToken) {
  return await APIGet<TCustomerTag[]>(baseTagUrl, params, useGlobalLoader, token);
}

//Update tags
export async function UpdateTag(params: TGetTag, useGlobalLoader?: boolean, token?: CancelToken) {
  return await APIPut(baseTagUrl, params, useGlobalLoader, token);
}

//Post tag
export async function PostTag(params: TPostTag, useGlobalLoader?: boolean) {
  return await APIPost(baseTagUrl, params, useGlobalLoader);
}

//Delete tag
export async function DeleteTag(params: TPostTag, useGlobalLoader?: boolean) {
  return await APIDelete(baseTagUrl, params, useGlobalLoader);
}
