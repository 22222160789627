import React, { useEffect, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetRainChecks, TRainCheckGet } from "api/rpc/clientAdmin/payment/rainCheck";
import { IRainCheck } from "api/rpc/facilityAdmin/payment/rainCheck";

import { showError } from "redux/actions/ui";

import { useAppDispatch } from "hooks/redux";
import { LocaleCurrency } from "helpers/Locale";
import { capitalize } from "helpers/Helpers";

import Page from "components/page/Page";
import Search from "components/search/Search";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

import "pages/secure/facility/order/order.scss";

export default function Orders() {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const [rainChecks, setRainChecks] = useState<IRainCheck[]>(undefined);
  const [filters, setFilters] = useState<TRainCheckGet>({
    code: "", // Search param needs to exactly match
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadRainChecks(source.token);

    return () => source.cancel();
  }, [filters]);

  async function loadRainChecks(token?: CancelToken) {
    if (rainChecks !== undefined) {
      setRainChecks(undefined);
    }
    const raincheckRes = await GetRainChecks(filters, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (raincheckRes.status !== StatusCode.OK) {
      dispatch(showError(typeof raincheckRes.data === "string" ? raincheckRes.data : "Error loading tickets."));
    }
    setRainChecks(raincheckRes.status === StatusCode.OK ? raincheckRes.data : []);
  }

  return (
    <Page title="Rain Checks" narrow>
      <div className="flex-grow mb-4">
        <Search
          historyKey={"ticket_table_search"}
          searchCallback={searchValue => setFilters(prev => ({ ...prev, code: searchValue }))}
          placeholder={"Search by code..."} // TODO: Translation
        />
      </div>

      <DataTable
        columns={[
          { label: "Pin", width: "30%" }, //TODO: Translation
          { label: "Balance", width: "30%" }, //TODO: Translation
          { label: "Status", width: "20%" }, //TODO: Translation
          { label: "Expiry", width: "20%" }, //TODO: Translation
        ]}
        loading={rainChecks === undefined}
      >
        {rainChecks?.map(raincheck => (
          <tr key={raincheck.id}>
            <td>
              <span>•••• ••••</span> {raincheck.last4}
            </td>
            <td>
              <LocaleCurrency currency="cad" amount={raincheck.balance} />
            </td>
            <td>{capitalize(raincheck.status)}</td>
            <td>
              {raincheck.expires_at ? (
                <p>{moment.utc(raincheck.expires_at).local().format("MMMM DD, YYYY")}</p>
              ) : (
                <p>No Expiry</p>
              )}
            </td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
