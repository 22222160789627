import React, { useEffect, useState } from "react";
import Card from "components/card/Card";
import Page from "components/page/Page";
import { useHistory, useParams } from "react-router";
import { ICustomer } from "redux/reducers/models/customer";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";
import "./viewCustomerSegment.scss";
import { sortObj } from "helpers/Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SelectCustomers from "components/selectCustomers/SelectCustomers";
import {
  GetCustomerSegmentCustomers,
  GetCustomerSegments,
  ICustomerSegment,
  PutCustomerSegmentCustomers,
} from "api/rpc/2024-04/facilityAdmin/customer/segment";
import axios, { CancelToken } from "axios";
import { useAppDispatch } from "hooks/redux";
import { showError, showSuccess } from "redux/actions/ui";
import { StatusCode } from "api/protocols";
import Input from "components/form/input";
import ReactDOM from "react-dom";

interface IParams {
  segmentId: string;
}

interface ICustomerState {
  modalOpen: boolean;
  customers: Array<ICustomer>;
  offset: number;
  customerSearch: string;
}

interface IState {
  segment: ICustomerSegment;
}

const CUSTOMER_LIMIT = 50;

export default function ViewCustomerSegment() {
  const { segmentId } = useParams<IParams>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [customerState, setCustomerState] = useState<ICustomerState>({
    modalOpen: false,
    customers: undefined,
    offset: 0,
    customerSearch: "",
  });
  const [state, setState] = useState<IState>({
    segment: undefined,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (segmentId) {
      void getCustomerSegment(source.token);
    } else {
      dispatch(showError("Error getting segment"));
      void navigateRoute("/admin/marketing");
    }
    return () => source.cancel();
  }, []);

  async function getCustomerSegment(cancelToken: CancelToken) {
    //loader
    if (state?.segment !== undefined) {
      setState(prevState => ({ ...prevState, segment: undefined }));
    }

    const [segmentRes, customerRes] = await Promise.all([
      GetCustomerSegments({ id: Number(segmentId), extended: true }, false, cancelToken),
      GetCustomerSegmentCustomers({ segment_id: Number(segmentId) }, false, cancelToken),
    ]);

    if (segmentRes?.status !== StatusCode.OK || customerRes?.status !== StatusCode.OK) {
      if (cancelToken && cancelToken.reason) {
        return;
      }
      dispatch(showError("Error getting segment"));
      void navigateRoute("/admin/marketing");
      return;
    }

    const sortedCustomers = sortCustomers(customerRes?.data);

    ReactDOM.unstable_batchedUpdates(() => {
      setState(prevState => ({ ...prevState, segment: segmentRes?.data[0] }));
      setCustomerState(prevState => ({ ...prevState, customers: sortedCustomers }));
    });
  }

  async function handleAddCustomers(customers: Array<ICustomer>) {
    if (customers?.length > 0) {
      //loader
      if (customerState?.customers !== undefined) {
        setCustomerState(prevState => ({ ...prevState, customers: undefined }));
      }
      const customerIds = customers?.map(customer => customer?.id);
      setCustomerState(prevState => ({ ...prevState, modalOpen: false }));
      const res = await PutCustomerSegmentCustomers(
        { segment_id: Number(segmentId), customer_ids: customerIds },
        false,
      );
      if (res?.status !== StatusCode.OK) {
        dispatch(showError("Error adding customers"));
        setCustomerState(prevState => ({ ...prevState, customers: [] }));
        return;
      }
      const customerRes = await GetCustomerSegmentCustomers({ segment_id: Number(segmentId) }, false);
      if (customerRes?.status !== StatusCode.OK) {
        dispatch(showError("Error getting customers"));
        setCustomerState(prevState => ({ ...prevState, customers: [] }));
        return;
      }
      const sortedCustomers = sortCustomers(customerRes?.data);
      dispatch(showSuccess("Successfully added customers to segment"));
      setCustomerState(prevState => ({ ...prevState, modalOpen: false, customers: sortedCustomers }));
    }
  }

  //Sort customers by last name
  function sortCustomers(customers: Array<ICustomer>) {
    const sortedCustomers = [...customers]?.sort((a, b) =>
      a.last_name < b.last_name ? -1 : a.last_name > b.last_name ? 1 : 0,
    );
    return sortedCustomers;
  }

  function navigateRoute(url: string, params?: ICustomerSegment) {
    if (params) {
      history.push(url, {
        params: {
          segment: params,
        },
      });
    } else {
      history.push(url);
    }
  }

  return (
    <Page
      narrow
      title={state?.segment ? state?.segment?.title : "Loading..."}
      subtitle={state?.segment ? state?.segment?.description : ""}
      primaryAction={{
        content: "Add Customers",
        action: () => setCustomerState(prevState => ({ ...prevState, modalOpen: true })),
        disabled: !state?.segment || !customerState?.customers,
      }}
      breadcrumbs={[{ prefix: true, label: "Back to Customer Segments", url: "/admin/marketing" }]}
      multipleActionDropdownAction={{
        label: "Options",
        dropdownProps: {
          alignment: "right",
          options: [
            {
              type: "handler",
              label: "Edit",
              icon: "pen-to-square",
              handler: () => navigateRoute(`/admin/marketing/customer-segments/edit/${segmentId}`, state.segment),
              disabled: !state?.segment,
            },
            {
              type: "handler",
              label: "Export",
              icon: "file-export",
              handler: undefined,
              disabled: !state?.segment,
            },
          ],
        },
      }}
    >
      <Card>
        <Card.Section title="Tags">
          <DataTable columns={[{ label: "Label" }, { label: "Description" }, { label: "" }]} loading={!state?.segment}>
            {state?.segment?.tags?.map((tag, index) => (
              <tr key={index}>
                <td>{tag?.label}</td>
                <td>{tag?.description}</td>
                <td>
                  <FontAwesomeIcon icon={["fas", "circle"]} size="1x" color={tag?.colour} />
                </td>
              </tr>
            ))}
          </DataTable>
        </Card.Section>
      </Card>

      <Card title="Customers">
        <Card.Section>
          <Input
            placeholder="Search customers..."
            labelHidden
            value={customerState?.customerSearch}
            onChange={e => setCustomerState(prevState => ({ ...prevState, customerSearch: e.target.value }))}
          />
          <br />
          <DataTable<ICustomer>
            sort={
              (key, direction) =>
                setCustomerState(prev => ({
                  ...prev,
                  customers: sortObj(customerState.customers ?? [], key, direction),
                }))
              //Sort by last name
            }
            loading={!customerState?.customers}
            columns={[{ id: "last_name", label: "Customer" }, { label: "Email" }, { label: "Phone" }]}
            footer={{
              tableLimit: CUSTOMER_LIMIT,
              tableOffset: customerState?.offset,
              disableNextOffset: !customerState?.customers || !(customerState?.customers?.length === CUSTOMER_LIMIT),
              handleTableOffset: direction =>
                setCustomerState(prev => ({
                  ...prev,
                  offset: direction === "prev" ? prev.offset - CUSTOMER_LIMIT : prev.offset + CUSTOMER_LIMIT,
                })),
            }}
          >
            {customerState?.customers &&
              customerState?.customers
                ?.filter(filteredCustomer =>
                  (filteredCustomer?.first_name + " " + filteredCustomer?.last_name)
                    ?.toLowerCase()
                    ?.includes(customerState?.customerSearch?.toLowerCase()),
                )
                ?.map(customer => (
                  <tr key={customer?.id}>
                    <td>
                      <p>
                        {customer?.first_name} {customer?.last_name}
                      </p>
                    </td>
                    <td>
                      <p>{customer?.email}</p>
                    </td>
                    <td>
                      <p>{customer?.phone}</p>
                    </td>
                  </tr>
                ))}
          </DataTable>
        </Card.Section>
      </Card>

      <SelectCustomers
        open={customerState.modalOpen}
        onClose={() => setCustomerState(prevState => ({ ...prevState, modalOpen: false }))}
        onOk={customers => handleAddCustomers(customers)}
        title="Add Customers"
        okText="Add"
        content={{ title: "Add to Segment", subtitle: "The following customers will be added to this segment:" }}
      />
    </Page>
  );
}
