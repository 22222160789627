import Sheet from "components/sheet/Sheet";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TextField from "components/form/textField/TextField";

interface INoteProps {
  noteSheetVisible: boolean;
  onCancel?: () => void;
  lineItemNote: string;
  onOk?: (priceChangeInfo: IItemActionsState) => void;
}

interface IItemActionsState {
  note: string;
}

function NoteSheet(props: INoteProps) {
  const { t } = useTranslation();

  const [itemActionsState, setItemActionsState] = useState<IItemActionsState>({
    note: "",
  });

  useEffect(() => {
    if (props.noteSheetVisible) {
      setItemActionsState(prevState => ({ ...prevState, note: props.lineItemNote }));
    }
  }, [props.noteSheetVisible]);

  function onOk() {
    void props.onOk({ ...itemActionsState });
  }

  function onCancel() {
    setItemActionsState(prevState => ({ ...prevState, note: "" }));
    props.onCancel();
  }

  function handleLineItemInputChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const { id, value } = e.target;
    setItemActionsState(prevState => ({ ...prevState, [id]: value }));
  }

  return (
    <Sheet
      title={t("components.note_sheet.note_sheet.001")}
      open={props.noteSheetVisible}
      size="small"
      closable
      onCancel={onCancel}
      onOk={onOk}
      okText={t("components.note_sheet.note_sheet.002")}
    >
      <TextField
        value={itemActionsState.note}
        placeholder={t("components.note_sheet.note_sheet.003")}
        id="note"
        onChange={handleLineItemInputChange}
        rows={10}
      />
    </Sheet>
  );
}

export default NoteSheet;
