import { APIGet, APIPost, APIPut, APIDelete, StatusCode } from "api/protocols";
import { CancelToken } from "axios";
import { useGlobalFilter } from "react-table";
import { ICustomer } from "redux/reducers/models/customer";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseMembershipUrl = "/" + apiVersion + "/" + adminType + "/customer/membership";

export type CustomerMembership = {
  client_id: number;
  customer: ICustomer;
  customer_id: number;
  expiry: Date | null;
  home_facility_id: number | null;
  home_facility_long_name: string | null;
  id: number;
  membership_id: number;
  membership_number: string;
  membership_subtitle: string;
  membership_title: string;
  membership_type: "multi-course" | "single-course";
  notes: string | null;
  order_id: number;
  order_name: string;
  season: string | null;
  status: "active" | "expired" | "disabled";
  status_readable: "Active" | "Expired" | "Disabled";
};

export interface IGetCustomerMemberships {
  id: number;
  search: string;
  customer_id: number;
  home_facility_id: number;
  limit: number;
  offset: number;
  parent_membership?: boolean;
}

//GET Memberships
export async function GetCustomerMemberships(
  params: Partial<IGetCustomerMemberships>,
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseMembershipUrl, params, useGlobalLoader, token);
}

export async function PostCustomerMembership(
  params: { customer_id: number; membership_id: number },
  useGlobalLoader: boolean,
) {
  return await APIPost(baseMembershipUrl, params, useGlobalLoader);
}

//DISABLE MEMBERSHIP

export async function DisableMembership(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseMembershipUrl + "/disable", params, useGlobalLoader);
}

//DELETE MEMBERSHIP
export async function DeleteMembership(params: any, useGlobalLoader: boolean) {
  return await APIDelete(baseMembershipUrl, params, useGlobalLoader);
}

// Move Membership
export async function MoveMembership(
  params: { customer_membership_id: number; new_customer_id: number },
  useGlobalLoader: boolean,
) {
  return await APIPut(baseMembershipUrl + "/move", params, useGlobalLoader);
}
