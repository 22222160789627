import React, { ChangeEvent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Page from "components/page/Page";
import {
  GetFacilityAdmin,
  PutDisableFacilityAdmin,
  PutFacilityAdmin,
  PutFacilityAdminPermissions,
  PutReactivateFacilityAdmin,
} from "api/rpc/facilityAdmin/client/admin";
import { StatusCode } from "api/protocols";
import Input from "components/form/input/Input";
import FormLayout from "components/form/FormLayout";
import Card from "components/card/Card";
import Form from "components/form/Form";
import Toggle from "components/form/toggle/Toggle";
import { isEqualWith, isNull } from "lodash";
import { NotificationType } from "components/notificationBar/NotificationBar";
import { IAuthState } from "redux/reducers/auth";
import { IAuthActions } from "redux/actions/auth";
import { IUIActions } from "redux/actions/ui";
import { IFacilityState } from "redux/reducers/facility";
import { isPositiveInteger } from "helpers/Helpers";
import Popup from "components/popup/Popup";
import { PostPasswordResetCode } from "api/rpc/2022-09/facilityAdmin/client/staffPasswordReset";
import { IUserPermissions } from "redux/reducers/models/user";

import { useTranslation } from "react-i18next";
import { Badge } from "components/badge/Badge";
import Icon from "components/icon/Icon";

interface IFacilityStaffEditProps {
  authStore: IAuthState;
  authActions: IAuthActions;
  uiActions: IUIActions;
  facilityStore: IFacilityState;
}

interface IStaffPermissions {
  admin_accounts_disable: boolean;
  admin_permissions_edit: boolean;
  admin_accounts_edit: boolean;
  products_archive: boolean;
  products_create: boolean;
  products_edit: boolean;
  products_edit_cost: boolean;
  products_edit_price: boolean;
  orders_remove_line_item: boolean;
  orders_remove_line_item_after_send: boolean;
  orders_edit_line_item_price: boolean;
  orders_apply_discount: boolean;
  orders_void: boolean;
  reports_access: boolean;
  tee_sheet_credit_card_override: boolean;
  tee_sheet_charge_no_show: boolean;
  orders_edit_user: boolean;
  orders_edit_register: boolean;
  orders_backdate: boolean;
  table_service_table_override: boolean;
  reservations_credit_card_override: boolean;
  merge_customers: boolean;

  cash_outs_create: boolean;
  cash_outs_edit: boolean;
  cash_outs_view_summary: boolean;

  accounts_charge_all: boolean;
  accounts_post_adjustment: boolean;
  accounts_import: boolean;

  memberships_apply_manual: boolean;
  user_edit_customer_type: boolean;

  orders_refund_tip: boolean;
  discounts_create: boolean;
  gift_cards_view: boolean;
  customers_edit_note: boolean;
  bookings_edit_note: boolean;
  gift_cards_import: boolean;

  courses_create: boolean;
  divisions_create: boolean;

  payment_options_create: boolean;
}

interface IStaff {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  username: string;
  state: string;
  quick_code: string;
  permissions: IUserPermissions;
}

export default function FacilityStaffEdit(props: IFacilityStaffEditProps) {
  const { id } = useParams<{ id: string }>();
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const permissions = props?.authStore?.user?.permissions;
  const facilityPermissions = props?.facilityStore?.facility?.permissions;

  const [staffState, setStaffState] = useState<IStaff>(undefined);
  const [staffStateBeforeChanges, setStaffStateBeforeChanges] = useState<IStaff>(undefined);

  const [resetPasswordPopup, setResetPasswordPopup] = useState<boolean>(false);
  const [reactivateAccountPopup, setReactivateAccountPopup] = useState<boolean>(false);

  useEffect(() => {
    void loadFacilityStaff();
  }, []);

  async function loadFacilityStaff() {
    const params = {
      id: id,
      extended: true,
    };
    const staffRes = await GetFacilityAdmin(params, true);

    if (staffRes.status !== StatusCode.OK) {
      props.uiActions.showError(t("secure.facility.settings.facility.facility_staff_edit.001"));
      return;
    }

    setStaffState(prev => ({
      ...prev,
      id: staffRes.data[0].id,
      first_name: staffRes.data[0].first_name ?? "",
      last_name: staffRes.data[0].last_name ?? "",
      email: staffRes.data[0].email ?? "",
      username: staffRes.data[0].username ?? "",
      state: staffRes.data[0].state ?? "",
      quick_code: "",
      permissions: staffRes.data[0].permissions,
    }));
  }

  async function saveStaffDetails() {
    const isEmpty = (x: any) => x === "" || x === null || x === undefined;

    if (!isEmpty(staffState.quick_code) && !isPositiveInteger(staffState.quick_code)) {
      props.uiActions.showError(t("secure.facility.settings.facility.facility_staff_edit.002"));
      return;
    }

    const putFacilityAdminResponse = await PutFacilityAdmin(
      {
        id: staffState.id,
        first_name: staffState.first_name,
        last_name: staffState.last_name,
        email: staffState.email,
        username: staffState.username,
        quick_code: isEmpty(staffState.quick_code) ? undefined : parseInt(staffState.quick_code),
      },
      true,
    );

    if (putFacilityAdminResponse.status !== StatusCode.OK) {
      props.uiActions.showError(t("secure.facility.settings.facility.facility_staff_edit.003"));
      return;
    }

    const putFacilityAdminPermissionsResponse = await PutFacilityAdminPermissions(
      {
        user_id: parseInt(id),
        ...staffState.permissions,
      },
      true,
    );

    if (putFacilityAdminPermissionsResponse.status !== StatusCode.OK) {
      props.uiActions.showError(t("secure.facility.settings.facility.facility_staff_edit.004"));
      return;
    }

    props.uiActions.showSuccess(t("secure.facility.settings.facility.facility_staff_edit.061"));

    setStaffStateBeforeChanges(staffState);
  }

  async function disableStaffAccount() {
    const putDisableFacilityAdminResponse = await PutDisableFacilityAdmin({ admin_id: staffState.id }, true);

    if (putDisableFacilityAdminResponse.status !== StatusCode.OK) {
      props.uiActions.showError(t("secure.facility.settings.facility.facility_staff_edit.005"));
      return;
    }

    history.push("/admin/settings/staff");
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setStaffState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleToggleChange(event: ChangeEvent<HTMLInputElement>) {
    const id = event?.target?.id;
    const checked = event?.target?.checked;

    if (id === undefined || checked === undefined) {
      return;
    }

    setStaffState(prev => {
      return { ...prev, permissions: { ...prev.permissions, [id]: checked } };
    });
  }

  function handleAccountsEditToggleChange(event: ChangeEvent<HTMLInputElement>) {
    const checked = event?.target?.checked;

    if (checked === undefined) {
      return;
    }

    setStaffState(prev => {
      return {
        ...prev,
        permissions: { ...prev.permissions, admin_accounts_edit: checked, admin_permissions_edit: false },
      };
    });
  }

  function handleProductsEditToggleChange(event: ChangeEvent<HTMLInputElement>) {
    const checked = event?.target?.checked;

    if (checked === undefined) {
      return;
    }

    setStaffState(prev => {
      return {
        ...prev,
        permissions: {
          ...prev.permissions,
          products_edit: checked,
          products_edit_cost: false,
          products_edit_price: false,
        },
      };
    });
  }

  function unsavedChangesExist() {
    if (staffStateBeforeChanges === undefined) {
      if (staffState) {
        setStaffStateBeforeChanges(staffState);
      }
      return false;
    }

    return !isEqualWith(staffStateBeforeChanges, staffState, (originalValue, newValue) => {
      if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
        return true;
      }
    });
  }

  function cancelUnsavedChanges() {
    setStaffState(staffStateBeforeChanges);
  }

  const disableAction = {
    content: t("secure.facility.settings.facility.facility_staff_edit.045"),
    action: disableStaffAccount,
  };

  const disablePermissions =
    permissions &&
    staffState &&
    staffState.state !== "disabled" &&
    permissions.user_id !== staffState.id &&
    permissions.admin_accounts_disable;

  const secondaryActions = [
    {
      content: t("secure.facility.settings.facility.facility_staff_edit.046"),
      action: () => setResetPasswordPopup(true),
    },
    ...(disablePermissions ? [disableAction] : []),
  ];

  async function handlePasswordReset() {
    const resetRes = await PostPasswordResetCode(
      { email: staffStateBeforeChanges.email, facility_id: props.authStore?.user?.facility_id ?? undefined },
      true,
    );
    if (resetRes.status !== StatusCode.OK) {
      props.uiActions.showError(t("secure.facility.settings.facility.facility_staff_edit.047"));
      return;
    }
    setResetPasswordPopup(false);
    props.uiActions.showSuccess(t("secure.facility.settings.facility.facility_staff_edit.048"));
  }

  const primaryAction = { content: "Reactivate Account", action: () => setReactivateAccountPopup(true) };

  async function handleReactivateAccount() {
    const activateRes = await PutReactivateFacilityAdmin({ admin_id: staffState.id }, true);

    if (activateRes.status !== StatusCode.OK) {
      props.uiActions.showError("Error reactivating staff account");
    }

    history.push("/admin/settings/staff");
  }

  return (
    <Page
      title={`${t("secure.facility.settings.facility.facility_staff_edit.007")} ${id}`}
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.facility.facility_staff_edit.008"),
          url: "/admin/settings/staff",
        },
      ]}
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: saveStaffDetails,
        onCancel: cancelUnsavedChanges,
      }}
      primaryAction={staffState?.state === "disabled" ? primaryAction : null}
      secondaryActions={secondaryActions}
    >
      <div className="mb-4">
        {staffState?.state === "disabled" ? (
          <Badge
            type="error"
            size="medium"
            iconLeft={<Icon icon="times" style="far" size="xsmall" />}
            iconSize="small"
            iconStyle="fas"
          >
            Disabled
          </Badge>
        ) : null}
      </div>
      {staffState && staffStateBeforeChanges && (
        <Form>
          <Card>
            <Card.Section>
              <FormLayout>
                <FormLayout.Group>
                  <Input
                    value={staffState.first_name}
                    label={t("secure.facility.settings.facility.facility_staff_edit.010")}
                    id="first_name"
                    onChange={handleInputChange}
                    placeholder={t("secure.facility.settings.facility.facility_staff_edit.011")}
                    disabled={
                      staffState.state === "disabled" ||
                      (permissions?.user_id !== staffState.id && !permissions?.admin_accounts_edit)
                    }
                  />
                  <Input
                    value={staffState.last_name}
                    label={t("secure.facility.settings.facility.facility_staff_edit.012")}
                    id="last_name"
                    onChange={handleInputChange}
                    placeholder={t("secure.facility.settings.facility.facility_staff_edit.013")}
                    disabled={
                      staffState.state === "disabled" ||
                      (permissions?.user_id !== staffState.id && !permissions?.admin_accounts_edit)
                    }
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Input
                    value={staffState.email}
                    label={t("secure.facility.settings.facility.facility_staff_edit.014")}
                    id="email"
                    onChange={handleInputChange}
                    placeholder={t("secure.facility.settings.facility.facility_staff_edit.015")}
                    disabled={
                      staffState.state === "disabled" ||
                      (permissions?.user_id !== staffState.id && !permissions?.admin_accounts_edit)
                    }
                  />
                  <Input
                    value={staffState.username}
                    label={t("secure.facility.settings.facility.facility_staff_edit.016")}
                    id="username"
                    onChange={handleInputChange}
                    placeholder={t("secure.facility.settings.facility.facility_staff_edit.017")}
                    disabled={
                      staffState.state === "disabled" ||
                      (permissions?.user_id !== staffState.id && !permissions?.admin_accounts_edit)
                    }
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  {staffState.state !== "disabled" &&
                    (permissions?.user_id === staffState.id || permissions?.admin_accounts_edit) && (
                      <Input
                        value={staffState.quick_code}
                        label={t("secure.facility.settings.facility.facility_staff_edit.018")}
                        id="quick_code"
                        onChange={handleInputChange}
                        placeholder={t("secure.facility.settings.facility.facility_staff_edit.019")}
                      />
                    )}
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>
          </Card>
          {staffState.state !== "disabled" && permissions?.admin_accounts_edit && permissions?.admin_permissions_edit && (
            <Card title={t("secure.facility.settings.facility.facility_staff_edit.020")}>
              <Card.Section title={t("secure.facility.settings.facility.facility_staff_edit.021")}>
                <FormLayout>
                  <FormLayout.Group>
                    <Toggle
                      labelRight={t("secure.facility.settings.facility.facility_staff_edit.022")}
                      id="admin_accounts_disable"
                      onChange={handleToggleChange}
                      checked={staffState.permissions.admin_accounts_disable}
                      size="medium"
                      disabled={permissions?.user_id === staffState.id}
                    />
                    <div>
                      <Toggle
                        labelRight={t("secure.facility.settings.facility.facility_staff_edit.023")}
                        id="admin_accounts_edit"
                        onChange={handleAccountsEditToggleChange}
                        checked={staffState.permissions.admin_accounts_edit}
                        size="medium"
                        disabled={permissions?.user_id === staffState.id}
                      />
                      {staffState.permissions.admin_accounts_edit && (
                        <div className="ml-3">
                          <Toggle
                            labelRight={t("secure.facility.settings.facility.facility_staff_edit.024")}
                            id="admin_permissions_edit"
                            onChange={handleToggleChange}
                            checked={staffState.permissions.admin_permissions_edit}
                            size="medium"
                            disabled={permissions?.user_id === staffState.id}
                          />
                        </div>
                      )}
                    </div>
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
              <Card.Section title={t("secure.facility.settings.facility.facility_staff_edit.025")}>
                <FormLayout>
                  <FormLayout.Group>
                    <Toggle
                      labelRight={t("secure.facility.settings.facility.facility_staff_edit.026")}
                      id="products_archive"
                      onChange={handleToggleChange}
                      checked={staffState.permissions.products_archive}
                      size="medium"
                      disabled={permissions?.user_id === staffState.id}
                    />
                    <Toggle
                      labelRight={t("secure.facility.settings.facility.facility_staff_edit.027")}
                      id="products_create"
                      onChange={handleToggleChange}
                      checked={staffState.permissions.products_create}
                      size="medium"
                      disabled={permissions?.user_id === staffState.id}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <div>
                      <Toggle
                        labelRight={t("secure.facility.settings.facility.facility_staff_edit.028")}
                        onChange={handleProductsEditToggleChange}
                        checked={staffState.permissions.products_edit}
                        size="medium"
                        disabled={permissions?.user_id === staffState.id}
                      />
                      {staffState.permissions.products_edit && (
                        <div className="ml-3">
                          <Toggle
                            labelRight={t("secure.facility.settings.facility.facility_staff_edit.029")}
                            id="products_edit_cost"
                            onChange={handleToggleChange}
                            checked={staffState.permissions.products_edit_cost}
                            size="medium"
                            disabled={permissions?.user_id === staffState.id}
                          />
                          <Toggle
                            labelRight={t("secure.facility.settings.facility.facility_staff_edit.030")}
                            id="products_edit_price"
                            onChange={handleToggleChange}
                            checked={staffState.permissions.products_edit_price}
                            size="medium"
                            disabled={permissions?.user_id === staffState.id}
                          />
                        </div>
                      )}
                    </div>
                    <Toggle
                      labelRight={"Create Discounts"}
                      id="discounts_create"
                      onChange={handleToggleChange}
                      checked={staffState.permissions.discounts_create}
                      size="medium"
                      disabled={permissions?.user_id === staffState.id}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
              <Card.Section title={t("secure.facility.settings.facility.facility_staff_edit.031")}>
                <FormLayout>
                  <FormLayout.Group>
                    <Toggle
                      labelRight={t("secure.facility.settings.facility.facility_staff_edit.032")}
                      id="orders_edit_line_item_price"
                      onChange={handleToggleChange}
                      checked={staffState.permissions.orders_edit_line_item_price}
                      size="medium"
                      disabled={permissions?.user_id === staffState.id}
                    />
                    <Toggle
                      labelRight={t("secure.facility.settings.facility.facility_staff_edit.033")}
                      id="orders_apply_discount"
                      onChange={handleToggleChange}
                      checked={staffState.permissions.orders_apply_discount}
                      size="medium"
                      disabled={permissions?.user_id === staffState.id}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <div>
                      <Toggle
                        labelRight={t("secure.facility.settings.facility.facility_staff_edit.034")}
                        id="orders_remove_line_item"
                        onChange={handleToggleChange}
                        checked={staffState.permissions.orders_remove_line_item}
                        size="medium"
                        disabled={permissions?.user_id === staffState.id}
                      />
                      {staffState.permissions.orders_remove_line_item && (
                        <div className="ml-3">
                          <Toggle
                            labelRight={t("secure.facility.settings.facility.facility_staff_edit.035")}
                            id="orders_remove_line_item_after_send"
                            onChange={handleToggleChange}
                            checked={staffState.permissions.orders_remove_line_item_after_send}
                            size="medium"
                            disabled={permissions?.user_id === staffState.id}
                          />
                        </div>
                      )}
                    </div>
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
              <Card.Section title={t("secure.facility.settings.facility.facility_staff_edit.036")}>
                <FormLayout>
                  <FormLayout.Group>
                    <Toggle
                      labelRight={t("secure.facility.settings.facility.facility_staff_edit.037")}
                      id="reports_access"
                      onChange={handleToggleChange}
                      checked={staffState.permissions.reports_access}
                      size="medium"
                      disabled={permissions?.user_id === staffState.id}
                    />
                  </FormLayout.Group>
                  {staffState.permissions.reports_access && (
                    <div>
                      <FormLayout.Group>
                        <Toggle
                          labelRight={"Transactions"} // TODO: Translation
                          id="reports_facility_transactions"
                          onChange={handleToggleChange}
                          checked={staffState.permissions.reports_facility_transactions}
                          size="medium"
                          disabled={permissions?.user_id === staffState.id}
                        />
                        <Toggle
                          labelRight={"Scheduled Payments"} // TODO: Translation
                          id="reports_facility_scheduled_payments"
                          onChange={handleToggleChange}
                          checked={staffState.permissions.reports_facility_scheduled_payments}
                          size="medium"
                          disabled={permissions?.user_id === staffState.id}
                        />
                        <Toggle
                          labelRight={"Detailed Sales By Department"} // TODO: Translation
                          id="reports_facility_detailed_sales_by_department"
                          onChange={handleToggleChange}
                          checked={staffState.permissions.reports_facility_detailed_sales_by_department}
                          size="medium"
                          disabled={permissions?.user_id === staffState.id}
                        />
                      </FormLayout.Group>
                      <FormLayout.Group>
                        <Toggle
                          labelRight={"Sales By Accounting Reference"} // TODO: Translation
                          id="reports_facility_sales_by_accounting_reference"
                          onChange={handleToggleChange}
                          checked={staffState.permissions.reports_facility_sales_by_accounting_reference}
                          size="medium"
                          disabled={permissions?.user_id === staffState.id}
                        />
                        <Toggle
                          labelRight={"Inventory Listing By Department"} // TODO: Translation
                          id="reports_facility_inventory_listing_by_department"
                          onChange={handleToggleChange}
                          checked={staffState.permissions.reports_facility_inventory_listing_by_department}
                          size="medium"
                          disabled={permissions?.user_id === staffState.id}
                        />
                        <Toggle
                          labelRight={"Order Exceptions"} // TODO: Translation
                          id="reports_facility_order_exceptions"
                          onChange={handleToggleChange}
                          checked={staffState.permissions.reports_facility_order_exceptions}
                          size="medium"
                          disabled={permissions?.user_id === staffState.id}
                        />
                      </FormLayout.Group>
                      <FormLayout.Group>
                        <Toggle
                          labelRight={"GolfNorth Daily Sales"} // TODO: Translation
                          id="reports_facility_golfnorth_daily_sales"
                          onChange={handleToggleChange}
                          checked={staffState.permissions.reports_facility_golfnorth_daily_sales}
                          size="medium"
                          disabled={permissions?.user_id === staffState.id}
                        />
                        <Toggle
                          labelRight={"Ticket Overview"} // TODO: Translation
                          id="reports_facility_ticket_overview"
                          onChange={handleToggleChange}
                          checked={staffState.permissions.reports_facility_ticket_overview}
                          size="medium"
                          disabled={permissions?.user_id === staffState.id}
                        />
                        <Toggle
                          labelRight={"Aged Accounts Receivable"} // TODO: Translation
                          id="reports_facility_aged_accounts_receivable"
                          onChange={handleToggleChange}
                          checked={staffState.permissions.reports_facility_aged_accounts_receivable}
                          size="medium"
                          disabled={permissions?.user_id === staffState.id}
                        />
                      </FormLayout.Group>
                      <FormLayout.Group>
                        <Toggle
                          labelRight={"Inventory Adjustments"} // TODO: Translation
                          id="reports_facility_inventory_adjustments"
                          onChange={handleToggleChange}
                          checked={staffState.permissions.reports_facility_inventory_adjustments}
                          size="medium"
                          disabled={permissions?.user_id === staffState.id}
                        />
                        <Toggle
                          labelRight={"Gift Card Activity"} // TODO: Translation
                          id="reports_facility_gift_card_activity"
                          onChange={handleToggleChange}
                          checked={staffState.permissions.reports_facility_gift_card_activity}
                          size="medium"
                          disabled={permissions?.user_id === staffState.id}
                        />
                        <Toggle
                          labelRight={"Tickets Redeemed"} // TODO: Translation
                          id="reports_facility_tickets_redeemed"
                          onChange={handleToggleChange}
                          checked={staffState.permissions.reports_facility_tickets_redeemed}
                          size="medium"
                          disabled={permissions?.user_id === staffState.id}
                        />
                      </FormLayout.Group>
                      <FormLayout.Group>
                        <Toggle
                          labelRight={"Tickets"} // TODO: Translation
                          id="reports_facility_tickets"
                          onChange={handleToggleChange}
                          checked={staffState.permissions.reports_facility_tickets}
                          size="medium"
                          disabled={permissions?.user_id === staffState.id}
                        />
                        <Toggle
                          labelRight={"Tickets Expired"} // TODO: Translation
                          id="reports_facility_tickets_expired"
                          onChange={handleToggleChange}
                          checked={staffState.permissions.reports_facility_tickets_expired}
                          size="medium"
                          disabled={permissions?.user_id === staffState.id}
                        />
                        <Toggle
                          labelRight={"Rounds By Customer Type"} // TODO: Translation
                          id="reports_facility_rounds_by_customer_type"
                          onChange={handleToggleChange}
                          checked={staffState.permissions.reports_facility_rounds_by_customer_type}
                          size="medium"
                          disabled={permissions?.user_id === staffState.id}
                        />
                      </FormLayout.Group>
                      <FormLayout.Group>
                        <Toggle
                          labelRight={"Membership Sales"} // TODO: Translation
                          id="reports_facility_membership_sales"
                          onChange={handleToggleChange}
                          checked={staffState.permissions.reports_facility_membership_sales}
                          size="medium"
                          disabled={permissions?.user_id === staffState.id}
                        />
                        <Toggle
                          labelRight={"Player Spend"} // TODO: Translation
                          id="reports_facility_player_spend"
                          onChange={handleToggleChange}
                          checked={staffState.permissions.reports_facility_player_spend}
                          size="medium"
                          disabled={permissions?.user_id === staffState.id}
                        />
                        <Toggle
                          labelRight={"Sales By Department"} // TODO: Translation
                          id="reports_facility_sales_by_department"
                          onChange={handleToggleChange}
                          checked={staffState.permissions.reports_facility_sales_by_department}
                          size="medium"
                          disabled={permissions?.user_id === staffState.id}
                        />
                      </FormLayout.Group>
                      <FormLayout.Group>
                        <Toggle
                          labelRight={"Tee Sheet Utilization"} // TODO: Translation
                          id="reports_facility_tee_sheet_utilization"
                          onChange={handleToggleChange}
                          checked={staffState.permissions.reports_facility_tee_sheet_utilization}
                          size="medium"
                          disabled={permissions?.user_id === staffState.id}
                        />
                        <Toggle
                          labelRight={"Email Activity"} // TODO: Translation
                          id="reports_facility_email_activity"
                          onChange={handleToggleChange}
                          checked={staffState.permissions.reports_facility_email_activity}
                          size="medium"
                          disabled={permissions?.user_id === staffState.id}
                        />
                        <Toggle
                          labelRight={"No Show"} // TODO: Translation
                          id="reports_facility_tee_sheet_no_show"
                          onChange={handleToggleChange}
                          checked={staffState.permissions.reports_facility_tee_sheet_no_show}
                          size="medium"
                          disabled={permissions?.user_id === staffState.id}
                        />
                      </FormLayout.Group>
                    </div>
                  )}
                </FormLayout>
              </Card.Section>
              <Card.Section title={t("secure.facility.settings.facility.facility_staff_edit.038")}>
                <FormLayout>
                  <FormLayout.Group>
                    <Toggle
                      labelRight={t("secure.facility.settings.facility.facility_staff_edit.039")}
                      id="tee_sheet_credit_card_override"
                      onChange={handleToggleChange}
                      checked={staffState.permissions.tee_sheet_credit_card_override}
                      size="medium"
                      disabled={permissions?.user_id === staffState.id}
                    />
                    <Toggle
                      labelRight={"Charge No Show"}
                      id="tee_sheet_charge_no_show"
                      onChange={handleToggleChange}
                      checked={staffState.permissions.tee_sheet_charge_no_show}
                      size="medium"
                      disabled={permissions?.user_id === staffState.id}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
              <Card.Section title={t("secure.facility.settings.facility.facility_staff_edit.040")}>
                <FormLayout>
                  <FormLayout.Group>
                    <Toggle
                      labelRight={t("secure.facility.settings.facility.facility_staff_edit.041")}
                      id="orders_edit_register"
                      onChange={handleToggleChange}
                      checked={staffState.permissions.orders_edit_register}
                      size="medium"
                      disabled={permissions?.user_id === staffState.id}
                    />
                    <Toggle
                      labelRight={t("secure.facility.settings.facility.facility_staff_edit.042")}
                      id="orders_edit_user"
                      onChange={handleToggleChange}
                      checked={staffState.permissions.orders_edit_user}
                      size="medium"
                      disabled={permissions?.user_id === staffState.id}
                    />

                    <Toggle
                      labelRight={"Orders Backdate"}
                      id="orders_backdate"
                      onChange={handleToggleChange}
                      checked={staffState.permissions.orders_backdate}
                      size="medium"
                      disabled={permissions?.user_id === staffState.id}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <Toggle
                      labelRight={"Refund Tip"}
                      id="orders_refund_tip"
                      onChange={handleToggleChange}
                      checked={staffState.permissions.orders_refund_tip}
                      size="medium"
                      disabled={permissions?.user_id === staffState.id}
                    />
                    <Toggle
                      labelRight={"View Gift Card Details"}
                      id="gift_cards_view"
                      onChange={handleToggleChange}
                      checked={staffState.permissions.gift_cards_view}
                      size="medium"
                      disabled={permissions?.user_id === staffState.id}
                    />
                    {!!facilityPermissions?.gift_cards_import && (
                      <Toggle
                        labelRight={"Import Gift Cards"}
                        id="gift_cards_import"
                        onChange={handleToggleChange}
                        checked={staffState.permissions.gift_cards_import}
                        size="medium"
                        disabled={!facilityPermissions?.gift_cards_import || permissions?.user_id === staffState.id}
                      />
                    )}
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
              <Card.Section title={t("secure.facility.settings.facility.facility_staff_edit.043")}>
                <FormLayout>
                  <FormLayout.Group>
                    <Toggle
                      id="table_service_table_override"
                      labelRight={t("secure.facility.settings.facility.facility_staff_edit.044")}
                      onChange={handleToggleChange}
                      checked={staffState.permissions.table_service_table_override}
                      size="medium"
                      disabled={permissions?.user_id === staffState.id}
                    ></Toggle>

                    <Toggle
                      id="orders_void"
                      labelRight={"Void Orders"}
                      onChange={handleToggleChange}
                      checked={staffState.permissions.orders_void}
                      size="medium"
                      disabled={permissions?.user_id === staffState.id}
                    ></Toggle>
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
              <Card.Section title={t("secure.facility.settings.facility.facility_staff_edit.049")}>
                <FormLayout>
                  <FormLayout.Group>
                    <Toggle
                      id="reservations_credit_card_override"
                      labelRight={t("secure.facility.settings.facility.facility_staff_edit.050")}
                      onChange={handleToggleChange}
                      checked={staffState.permissions.reservations_credit_card_override}
                      size="medium"
                      disabled={permissions?.user_id === staffState.id}
                    ></Toggle>
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
              <Card.Section title={t("secure.facility.settings.facility.facility_staff_edit.055")}>
                {" "}
                {/* TODO: Translation */}
                <FormLayout>
                  <FormLayout.Group>
                    <Toggle
                      labelRight={t("secure.facility.settings.facility.facility_staff_edit.056")} //TODO : Translation
                      id="cash_outs_create"
                      onChange={handleToggleChange}
                      checked={staffState.permissions.cash_outs_create}
                      size="medium"
                      disabled={permissions?.user_id === staffState.id}
                    />
                    <Toggle
                      labelRight={t("secure.facility.settings.facility.facility_staff_edit.057")} //TODO : Translation
                      id="cash_outs_edit"
                      onChange={handleToggleChange}
                      checked={staffState.permissions.cash_outs_edit}
                      size="medium"
                      disabled={permissions?.user_id === staffState.id}
                    />
                    <Toggle
                      labelRight={t("secure.facility.settings.facility.facility_staff_edit.058")} //TODO : Translation
                      id="cash_outs_view_summary"
                      onChange={handleToggleChange}
                      checked={staffState.permissions.cash_outs_view_summary}
                      size="medium"
                      disabled={permissions?.user_id === staffState.id}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
              <Card.Section title={t("secure.facility.settings.facility.facility_staff_edit.059")}>
                <FormLayout>
                  <FormLayout.Group>
                    <Toggle
                      labelRight={t("secure.facility.settings.facility.facility_staff_edit.060")} //TODO : Translation
                      id="accounts_charge_all"
                      onChange={handleToggleChange}
                      checked={staffState.permissions.accounts_charge_all}
                      size="medium"
                      disabled={permissions?.user_id === staffState.id}
                    />
                    <Toggle
                      labelRight={"Adjust Account Balance"} //TODO : Translation
                      id="accounts_post_adjustment"
                      onChange={handleToggleChange}
                      checked={staffState.permissions.accounts_post_adjustment}
                      size="medium"
                      disabled={permissions?.user_id === staffState.id}
                    />
                    <Toggle
                      labelRight={"Import Accounts"} //TODO : Translation
                      id="accounts_import"
                      onChange={handleToggleChange}
                      checked={staffState.permissions.accounts_import}
                      size="medium"
                      disabled={!facilityPermissions?.accounts_import || permissions?.user_id === staffState.id}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
              {/* TODO: Translations */}
              <Card.Section title={t("secure.facility.settings.facility.facility_staff_edit.062")}>
                <FormLayout>
                  <FormLayout.Group>
                    <Toggle
                      labelRight={t("secure.facility.settings.facility.facility_staff_edit.063")}
                      id="memberships_apply_manual"
                      onChange={handleToggleChange}
                      checked={staffState.permissions.memberships_apply_manual}
                      size="medium"
                      disabled={permissions?.user_id === staffState.id}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
              <Card.Section title={t("secure.facility.settings.facility.facility_staff_edit.064")}>
                <FormLayout>
                  <FormLayout.Group>
                    <Toggle
                      labelRight={t("secure.facility.settings.facility.facility_staff_edit.065")}
                      id="user_edit_customer_type"
                      onChange={handleToggleChange}
                      checked={staffState.permissions.user_edit_customer_type}
                      size="medium"
                      disabled={permissions?.user_id === staffState.id}
                    />
                    <Toggle
                      labelRight={"Edit Customer Notes"} // TODO: Translation
                      id="customers_edit_note"
                      onChange={handleToggleChange}
                      checked={staffState.permissions.customers_edit_note}
                      size="medium"
                      disabled={permissions?.user_id === staffState.id}
                    />
                    <Toggle
                      labelRight={"Merge Customers"} // TODO: Translation
                      id="merge_customers"
                      onChange={handleToggleChange}
                      checked={staffState.permissions.merge_customers}
                      size="medium"
                      disabled={permissions?.user_id === staffState.id}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
              <Card.Section title="Facility">
                <FormLayout>
                  <FormLayout.Group>
                    <Toggle
                      labelRight={"Create Courses"}
                      id="courses_create"
                      onChange={handleToggleChange}
                      checked={staffState.permissions.courses_create}
                      size="medium"
                      disabled={!facilityPermissions?.courses_create || permissions?.user_id === staffState.id}
                    />
                    <Toggle
                      labelRight={"Create Divisions"}
                      id="divisions_create"
                      onChange={handleToggleChange}
                      checked={staffState.permissions.divisions_create}
                      size="medium"
                      disabled={!facilityPermissions?.divisions_create || permissions?.user_id === staffState.id}
                    />
                    <Toggle
                      labelRight={"Create Payment Methods"}
                      id="payment_options_create"
                      onChange={handleToggleChange}
                      checked={staffState.permissions.payment_options_create}
                      size="medium"
                      disabled={permissions?.user_id === staffState.id}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>

              <Card.Section title="Booking Categories">
                <FormLayout>
                  <FormLayout.Group>
                    <Toggle
                      labelRight={"Create Booking Category"}
                      id="booking_category_create"
                      onChange={handleToggleChange}
                      checked={staffState.permissions?.booking_category_create}
                      size="medium"
                      disabled={permissions?.user_id === staffState.id}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>

              <Card.Section title="Booking Notes">
                <FormLayout>
                  <FormLayout.Group>
                    <Toggle
                      labelRight={"Edit Booking Notes"}
                      id="bookings_edit_note"
                      onChange={handleToggleChange}
                      checked={staffState.permissions?.bookings_edit_note}
                      size="medium"
                      disabled={permissions?.user_id === staffState.id}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>

              <Card.Section title="Bank Accounts">
                <FormLayout>
                  <FormLayout.Group>
                    <Toggle
                      labelRight={"Create Bank Accounts"}
                      id="bank_accounts_create"
                      onChange={handleToggleChange}
                      checked={staffState.permissions?.bank_accounts_create}
                      size="medium"
                      disabled={permissions?.user_id === staffState.id}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>

              <Card.Section title="Tee On Payments">
                <FormLayout>
                  <FormLayout.Group>
                    <Toggle
                      labelRight={"View Persons"}
                      id="tee_on_payments_view_persons"
                      onChange={handleToggleChange}
                      checked={staffState.permissions?.tee_on_payments_view_persons}
                      size="medium"
                      disabled={permissions?.user_id === staffState.id}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
              <Card.Section title="Departments">
                <FormLayout>
                  <FormLayout.Group>
                    <Toggle
                      labelRight={"Create Departments"}
                      id="departments_create"
                      onChange={handleToggleChange}
                      checked={staffState.permissions?.departments_create}
                      size="medium"
                      disabled={permissions?.user_id === staffState.id}
                    />
                    <Toggle
                      labelRight={"Edit Departments"}
                      id="departments_edit"
                      onChange={handleToggleChange}
                      checked={staffState.permissions?.departments_edit}
                      size="medium"
                      disabled={permissions?.user_id === staffState.id}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
            </Card>
          )}
        </Form>
      )}

      <Popup
        open={resetPasswordPopup}
        type="info"
        title={t("secure.facility.settings.facility.facility_staff_edit.051")}
        description={t("secure.facility.settings.facility.facility_staff_edit.052")}
        onOk={handlePasswordReset}
        okText={t("secure.facility.settings.facility.facility_staff_edit.053")}
        cancelText={t("secure.facility.settings.facility.facility_staff_edit.054")}
        onCancel={() => setResetPasswordPopup(false)}
      />

      <Popup
        open={reactivateAccountPopup}
        type="info"
        title="Reactivate Staff Account?"
        description="Are you sure you want to reactivate this staff account?"
        onOk={handleReactivateAccount}
        okText="Activate"
        cancelText="Cancel"
        onCancel={() => setReactivateAccountPopup(false)}
      />
    </Page>
  );
}
