import { APIGet, APIPost, APIPut, APIDelete, StatusCode } from "api/protocols";
import { CancelToken } from "axios";
import { TProductRequirements } from "redux/reducers/models/product";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/client/requirements";

export async function GetProductRequirements(params: null, useGlobalLoader?: boolean, token?: CancelToken) {
  return await APIGet<TProductRequirements | string>(baseUrl + "/product", params, useGlobalLoader, token);
}
