import React, { ChangeEvent, useEffect, useState } from "react";
import axios, { CancelToken } from "axios";
import { TFunction, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { StatusCode } from "api/protocols";
import { AddPlayerToLeague, GetLeaguePlayers } from "api/rpc/facilityAdmin/league/league";
import { GetLeagueOrganizer, PostLeagueOrganizer } from "api/rpc/facilityAdmin/league/organizer";
import { GetCustomer, PostCustomer } from "api/rpc/2022-09/facilityAdmin/customer/customer";

import { ICustomer } from "redux/reducers/models/customer";
import { showError } from "redux/actions/ui";
import { ILeagueParticipant } from "redux/reducers/models/league";

import { customerErrorMessage } from "helpers/Helpers";
import { useAppDispatch } from "hooks/redux";

import FormLayout from "components/form/FormLayout";
import Page from "components/page/Page";
import Sheet from "components/sheet/Sheet";
import Tabs from "components/tabs/Tabs";
import Input from "components/form/input/Input";
import { Select } from "components/select";
import GolferCard from "components/bookingPopUp/golferCard/GolferCard";
import Checkbox from "components/form/checkbox/Checkbox";
import DataTable from "../../../../customer/tabs/houseAccounts/DataTable";
import Portal from "elements/Portal";
import Search from "components/search/Search";

export default function LeagueOrganizers() {
  const { t } = useTranslation();
  const { leagueId } = useParams<{ leagueId: string }>();
  const dispatch = useAppDispatch();
  const { Option } = Select;

  const [organizers, setOrganizers] = useState<ICustomer[]>(undefined);
  const [players, setPlayers] = useState<ICustomer[]>([]);
  const [facilityCustomers, setFacilityCustomers] = useState<ICustomer[]>([]);

  const [newOrganizerModal, setNewOrganizerModal] = useState({
    modalOpen: false,
    addAsParticipant: false,
    tabIndex: 0,
    search: "",
    selectedCustomer: undefined as ICustomer,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  // Load players + organizers on first load
  useEffect(() => {
    const source = axios.CancelToken.source();

    void loadOrganizers(source.token);
    void loadLeaguePlayers(source.token);

    return () => {
      source.cancel();
    };
  }, []);

  // Search facility customers only when search changes
  useEffect(() => {
    const source = axios.CancelToken.source();
    let timeoutId: NodeJS.Timeout = null;

    if (newOrganizerModal.search === newOrganizerModal.selectedCustomer?.full_name) {
      return;
    }
    if (newOrganizerModal.search.length === 0) {
      setFacilityCustomers([]);
      return;
    }

    timeoutId = global.setTimeout(() => {
      void searchFacilityCustomers(newOrganizerModal.search, source.token);
    }, 1000);

    return () => {
      source.cancel();
      clearTimeout(timeoutId);
    };
  }, [newOrganizerModal.search]);

  // GET all league players
  async function loadLeaguePlayers(token?: CancelToken) {
    const params = {
      league_id: leagueId,
    };

    const leaguePlayersRes = await GetLeaguePlayers(params, token ? false : true, token);

    if (leaguePlayersRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading league players.")); //TODO: Translation
      return;
    }

    setPlayers(leaguePlayersRes.data.map((player: ILeagueParticipant, index: number) => player.customer));
  }

  // GET all league organizers
  async function loadOrganizers(token?: CancelToken) {
    if (organizers !== undefined) {
      setOrganizers(undefined);
    }

    const res = await GetLeagueOrganizer({ league_id: Number(leagueId) }, token ? false : true, token);
    if (res.status !== StatusCode.OK || res?.data.length === 0) {
      setOrganizers([]);
      return;
    }

    setOrganizers(res.data.map(val => val.customer));
  }

  // GET facility customer
  async function searchFacilityCustomers(search: string, token?: CancelToken) {
    if (facilityCustomers !== undefined) {
      setFacilityCustomers(undefined);
    }

    const customerRes = await GetCustomer({ search: search }, false, token);

    if (token.reason) {
      return;
    }

    if (customerRes.status !== StatusCode.OK) {
      setFacilityCustomers([]);
      return;
    }

    setFacilityCustomers(customerRes.data);
  }

  // POST new player to facility
  async function saveNewPlayer() {
    const newPlayerRes = await PostCustomer(
      {
        first_name: newOrganizerModal.firstName,
        last_name: newOrganizerModal.lastName,
        email: newOrganizerModal.email || null,
        phone: newOrganizerModal.phone || null,
      },
      true,
    );

    if (newPlayerRes.status !== StatusCode.OK) {
      dispatch(showError(customerErrorMessage(t, newPlayerRes?.data?.message)));
      return;
    }

    // both async funcs lead to adding the player as an organizer
    if (newOrganizerModal.addAsParticipant) {
      void addPlayerToLeague(newPlayerRes.data);
    } else {
      void addUserAsOrganizer(newPlayerRes.data);
    }
  }

  // POST a customer into the league before adding as an organizer.
  async function addPlayerToLeague(customer: ICustomer) {
    const params = {
      league_id: Number(leagueId),
      customer_id: customer.id,
    };

    const res = await AddPlayerToLeague(params, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error adding customer to league"));
      return;
    }

    void addUserAsOrganizer(customer);
  }

  // Add 1 organizer to the league
  async function addUserAsOrganizer(customer: ICustomer) {
    const res = await PostLeagueOrganizer({ league_id: Number(leagueId), user_id: customer.id }, true);

    if (res.status !== StatusCode.OK) {
      return;
    }

    setOrganizers(prev => prev.concat(customer)); //set the organizer without calling APi

    // Close modal
    setNewOrganizerModal({
      modalOpen: false,
      addAsParticipant: false,
      tabIndex: 0,
      search: "",
      selectedCustomer: undefined,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    });
  }

  function handleModalOk(customer?: ICustomer) {
    setNewOrganizerModal(prev => ({ ...prev, search: "" }));

    switch (newOrganizerModal.tabIndex) {
      // Customer will always be present on tab 0
      case 0: {
        if (newOrganizerModal.addAsParticipant) {
          void addPlayerToLeague(customer); // only add customer to league if checkbox checked
        } else {
          void addUserAsOrganizer(customer);
        }
        return;
      }
      case 1: {
        // Save brand new player to the league
        void saveNewPlayer();
        return;
      }
    }
  }
  const primaryAction = {
    content: "Add Organizer",
    action: () => setNewOrganizerModal(prev => ({ ...prev, modalOpen: true })),
  };

  return (
    <Page title={"League Organizers"} primaryAction={primaryAction}>
      <DataTable columns={tableColumns(t)} loading={organizers === undefined}>
        {organizers
          ? organizers.map(player => {
              return (
                <tr key={player.id}>
                  <td>{player.full_name}</td>
                </tr>
              );
            })
          : null}
      </DataTable>

      <Portal isMounted={newOrganizerModal.modalOpen}>
        <Sheet
          title="Add League Organizer" // TODO: Translation
          open={true}
          overflow
          onCancel={() =>
            setNewOrganizerModal({
              modalOpen: false,
              addAsParticipant: false,
              tabIndex: 0,
              selectedCustomer: undefined,
              firstName: "",
              lastName: "",
              email: "",
              phone: "",
              search: "",
            })
          }
          onOk={() => handleModalOk(newOrganizerModal.selectedCustomer)}
          okText={
            newOrganizerModal.tabIndex === 0 ? "Add Organizer" : newOrganizerModal.tabIndex === 1 ? "Add User" : null
          }
          size="small"
          okDisabled={
            (newOrganizerModal.tabIndex === 0 && newOrganizerModal.selectedCustomer === undefined) ||
            (newOrganizerModal.tabIndex === 1 &&
              (newOrganizerModal.firstName === "" ||
                newOrganizerModal.lastName === "" ||
                (newOrganizerModal.email === "" && newOrganizerModal.phone === "")))
          }
        >
          <Tabs
            tabs={modalTabs(t)}
            selected={newOrganizerModal.tabIndex}
            onSelect={index =>
              setNewOrganizerModal(prev => ({ ...prev, tabIndex: index, selectedCustomer: undefined, search: "" }))
            }
          >
            <div style={{ paddingTop: "16px" }}>
              {newOrganizerModal.tabIndex === 0 && (
                <>
                  {newOrganizerModal.selectedCustomer ? (
                    <GolferCard
                      closable
                      removeGolfer={(e: any) =>
                        setNewOrganizerModal(prev => ({ ...prev, selectedCustomer: undefined }))
                      }
                      email={newOrganizerModal.selectedCustomer?.email}
                      name={newOrganizerModal.selectedCustomer?.full_name}
                      memberCode={newOrganizerModal.selectedCustomer?.member_code}
                      customerType={newOrganizerModal.selectedCustomer?.customer_type}
                      phone={newOrganizerModal.selectedCustomer?.phone}
                    />
                  ) : (
                    <Select
                      showSearch
                      placeholder="Search facility customers.." //TODO: Translation
                      onSearch={(val: string) =>
                        setNewOrganizerModal(prev => ({
                          ...prev,
                          search: val,
                          selectedCustomer: val.length === 0 ? undefined : prev.selectedCustomer,
                        }))
                      }
                      searchValue={newOrganizerModal.search}
                      onChange={(id: number, customer: ICustomer) =>
                        setNewOrganizerModal(prev => ({ ...prev, selectedCustomer: customer }))
                      }
                      loading={typeof facilityCustomers === "undefined"}
                      showDropDownOnFocus
                      allowClear
                    >
                      {facilityCustomers ? (
                        facilityCustomers
                          .filter(val => !organizers.find(cust => val.id === cust.id))
                          .map(customer => (
                            <Option key={customer.id} value={customer.id} extraValues={customer}>
                              {customer.full_name}
                            </Option>
                          ))
                      ) : (
                        <Option value={"loading"}></Option>
                      )}
                    </Select>
                  )}

                  <div style={{ display: "flex", justifyContent: "flex-end", paddingTop: "16px" }}>
                    <Checkbox
                      size="medium"
                      label="Add Organizer To League" //TODO: Translation
                      checked={newOrganizerModal.addAsParticipant}
                      onChange={e =>
                        setNewOrganizerModal(prev => ({ ...prev, addAsParticipant: !prev.addAsParticipant }))
                      }
                      disabled={
                        newOrganizerModal.selectedCustomer &&
                        players?.find(val => val.id == newOrganizerModal.selectedCustomer.id)
                          ? true
                          : false
                      }
                    />
                  </div>
                </>
              )}

              {newOrganizerModal.tabIndex === 1 && (
                <FormLayout>
                  <FormLayout.Group>
                    <Input
                      label="First Name"
                      placeholder={"First Name"}
                      value={newOrganizerModal.firstName}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setNewOrganizerModal(prev => ({ ...prev, firstName: e.target.value }))
                      }
                    />
                    <Input
                      label="Last Name"
                      placeholder={"Last Name"}
                      value={newOrganizerModal.lastName}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setNewOrganizerModal(prev => ({ ...prev, lastName: e.target.value }))
                      }
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <Input
                      label="Email"
                      placeholder={"Email"}
                      value={newOrganizerModal.email}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setNewOrganizerModal(prev => ({ ...prev, email: e.target.value }))
                      }
                    />
                    <Input
                      label="Phone Number"
                      placeholder={"Phone Number"}
                      value={newOrganizerModal.phone}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setNewOrganizerModal(prev => ({ ...prev, phone: e.target.value }))
                      }
                    />
                  </FormLayout.Group>
                </FormLayout>
              )}
            </div>
          </Tabs>
        </Sheet>
      </Portal>
    </Page>
  );
}

const tableColumns = (t: TFunction<"translation", undefined>) => {
  return [{ label: "Organizer" }];
};

const modalTabs = (t: TFunction<"translation", undefined>) => {
  return [
    { id: "search-organizer", content: "Search" },
    { id: "new-organizer", content: "New" },
  ];
};
