import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { ITeeDeck } from "redux/reducers/models/facility";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/facility/tax/number";

interface IGetTaxNumber {
  id?: number;
}

interface IPostTaxNumber {
  title: string;
  number: string;
}

interface IPutTaxNumber {
  id: number;
  title: string;
  number: string;
}

interface IDeleteTaxNumber {
  id: number;
}

// GET Tax Number
export async function GetTaxNumbers(params: IGetTaxNumber, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseUrl, params, useGlobalLoader, token);
}

// POST Tax Number
export async function PostTaxNumber(params: IPostTaxNumber, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

// PUT Tax Number
export async function PutTaxNumber(params: IPutTaxNumber, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

// DELETE Tax Number
export async function DeleteTaxNumber(params: IDeleteTaxNumber, useGlobalLoader: boolean) {
  return await APIDelete(baseUrl, params, useGlobalLoader);
}
