import React, { useEffect, useMemo, useState, useCallback } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import Spin from "components/spin/spin";
import { TEventRound, TLeagueFlight, TLeagueRoundFormat } from "redux/reducers/models/league";

interface IIndividualScoresTableProps {
  format: TLeagueRoundFormat;
  rounds: Array<TEventRound>;
  flights: Array<TLeagueFlight>;
  holeScoreRound: TEventRound;
  selectedRoundId: number;
  onClick: (roundId: number) => void;
}

interface IIndividualScoresTableState {
  defaultSorted: boolean;
  rounds: Array<TEventRound>;
  sortByType: string;
  sortByDirection: boolean;
}

export function IndividualScoresTable(props: IIndividualScoresTableProps) {
  const { flights, holeScoreRound, format, selectedRoundId, onClick } = props;

  const [state, setState] = useState<IIndividualScoresTableState>({
    defaultSorted: false,
    rounds: null,
    sortByType: "default",
    sortByDirection: false,
  });

  const [showParticipationPoints, setShowParticipationPoints] = useState<boolean>(true);
  const [showBonusPoints, setShowBonusPoints] = useState<boolean>(true);

  useEffect(() => {
    if (!state.rounds && props.rounds) {
      setState(prevState => ({ ...prevState, rounds: props.rounds }));

      const participationPoints = props.rounds.some(round => round.participation_points > 0);
      const bonusPoints = props.rounds.some(round => round.bonus_points > 0);

      setShowParticipationPoints(participationPoints);
      setShowBonusPoints(bonusPoints);
    }
  }, [props.rounds]);

  useEffect(() => {
    if (!state.defaultSorted && state.rounds) {
      void sortByDefault();
    }
  }, [state.rounds]);

  function roundsTable(flight: TLeagueFlight) {
    return (
      <DataTable
        columns={[
          { label: "Rank", width: "11%" }, // TODO: Translation
          { label: "Player", width: "45%" }, // TODO: Translation
          {
            label: "handicap",
            content: (
              <p onClick={() => sortLeaderboard("handicap", "round_handicap")} className="leaderboard-header">
                {"Handicap"} {/* TODO: Translation */}
                <FontAwesomeIcon
                  style={{ visibility: state?.sortByType === "handicap" ? "visible" : "hidden" }}
                  icon={["far", state?.sortByDirection ? "arrow-down-long" : "arrow-up-long"]}
                />
              </p>
            ),
            width: "11%",
          },
          {
            label: "gross",
            content: (
              <p onClick={() => sortLeaderboard("gross", "gross_score")} className="leaderboard-header">
                {"Gross"} {/* TODO: Translation */}
                <FontAwesomeIcon
                  style={{ visibility: state?.sortByType === "gross" ? "visible" : "hidden" }}
                  icon={["far", state?.sortByDirection ? "arrow-down-long" : "arrow-up-long"]}
                />
              </p>
            ),
            width: "11%",
          },
          ...(format.handicap !== "gross"
            ? [
                {
                  label: "net",
                  content: (
                    <p onClick={() => sortLeaderboard("net", "net_score")} className="leaderboard-header">
                      {"Net"} {/* TODO: Translation */}
                      <FontAwesomeIcon
                        style={{ visibility: state?.sortByType === "net" ? "visible" : "hidden" }}
                        icon={["far", state?.sortByDirection ? "arrow-down-long" : "arrow-up-long"]}
                      />
                    </p>
                  ),
                  width: "11%",
                },
              ]
            : []),
          ...(showBonusPoints
            ? [
                {
                  label: "bonus",
                  content: (
                    <p onClick={() => sortLeaderboard("bonus", "bonus_points")} className="leaderboard-header">
                      {"Bonus"} {/* TODO: Translation */}
                      <FontAwesomeIcon
                        style={{ visibility: state?.sortByType === "bonus" ? "visible" : "hidden" }}
                        icon={["far", state?.sortByDirection ? "arrow-down-long" : "arrow-up-long"]}
                      />
                    </p>
                  ),
                  width: "11%",
                },
              ]
            : []),
          ...(showParticipationPoints
            ? [
                {
                  label: "participation",
                  content: (
                    <p
                      onClick={() => sortLeaderboard("participation", "participation_points")}
                      className="leaderboard-header"
                    >
                      {"Participation"} {/* TODO: Translation */}
                      <FontAwesomeIcon
                        style={{ visibility: state?.sortByType === "participation" ? "visible" : "hidden" }}
                        icon={["far", state?.sortByDirection ? "arrow-down-long" : "arrow-up-long"]}
                      />
                    </p>
                  ),
                  width: "11%",
                },
              ]
            : []),
          ...(format.format !== "stroke_play"
            ? [
                {
                  label: "points",
                  content: (
                    <p onClick={() => sortLeaderboard("points", "points_awarded")} className="leaderboard-header">
                      {"Points"} {/* TODO: Translation */}
                      <FontAwesomeIcon
                        style={{ visibility: state?.sortByType === "points" ? "visible" : "hidden" }}
                        icon={["far", state?.sortByDirection ? "arrow-down-long" : "arrow-up-long"]}
                      />
                    </p>
                  ),
                  width: "11%",
                },
              ]
            : []),
        ]}
        loading={!state.rounds}
      >
        {state.rounds
          ?.filter(
            round =>
              flight === null || flight.participants.find(participant => participant.user_id === round.customer_id),
          )
          ?.map((round, index) => {
            if (round.round_id === selectedRoundId) {
              return (
                <>
                  <tr key={index} onClick={() => onClick(null)} className="league-leaderboard-hide-border">
                    <td>{index + 1}</td>
                    <td>{round.customer}</td>
                    <td>{round.round_handicap}</td>
                    <td>{round.gross_score}</td>
                    {format.handicap !== "gross" && <td>{round.net_score}</td>}
                    {showBonusPoints && <td>{round.bonus_points}</td>}
                    {showParticipationPoints && <td>{round.participation_points}</td>}
                    {format.format !== "stroke_play" && (
                      <td>{format?.handicap === "gross" ? round.gross_points_awarded : round.net_points_awarded}</td>
                    )}
                  </tr>
                  <tr key={index + 10000}>
                    <td colSpan={8}>{displayHoleScores()}</td>
                  </tr>
                </>
              );
            } else {
              return (
                <tr key={index} onClick={() => onClick(round.round_id)}>
                  <td>{index + 1}</td>
                  <td>{round.customer}</td>
                  <td>{round.round_handicap}</td>
                  <td>{round.gross_score}</td>
                  {format.handicap !== "gross" && <td>{round.net_score}</td>}
                  {showBonusPoints && <td>{round.bonus_points}</td>}
                  {showParticipationPoints && <td>{round.participation_points}</td>}
                  {format.format !== "stroke_play" && (
                    <td>{format?.handicap === "gross" ? round.gross_points_awarded : round.net_points_awarded}</td>
                  )}
                </tr>
              );
            }
          })}
      </DataTable>
    );
  }

  function displayHoleScores() {
    return (
      <div className="league-leaderboard">
        {holeScoreRound ? (
          <table
            className={
              format.holes === "18_holes" ? "league-leaderboard-hole-scores" : "league-leaderboard-hole-scores-9-holes"
            }
          >
            <thead>
              <tr>
                <th className="league-leaderboard-title">{""}</th>
                {holeScoreRound.hole_scores.map((score, index: number) => {
                  if (score.hole_number <= 9.1) {
                    return (
                      <th key={index} className="league-leaderboard-score">
                        {score.hole_number === 9.1
                          ? "Out" // TODO: Translation
                          : score.hole_number}
                      </th>
                    );
                  } else if (format.holes === "18_holes" && score.hole_number > 9.1) {
                    return (
                      <th key={index} className="league-leaderboard-score">
                        {score.hole_number === 18.1
                          ? "In" // TODO: Translation
                          : score.hole_number === 18.2
                          ? "Total" // TODO: Translation
                          : score.hole_number}
                      </th>
                    );
                  }
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="league-leaderboard-title">{""}</td>

                {holeScoreRound.hole_scores.map((score, index: number) => {
                  if (score.hole_number <= 9.1) {
                    return (
                      <td key={index} className="league-leaderboard-score">
                        {score.yardage}
                      </td>
                    );
                  } else if (format.holes === "18_holes" && score.hole_number > 9.1) {
                    return (
                      <td key={index} className="league-leaderboard-score">
                        {score.yardage}
                      </td>
                    );
                  }
                })}
              </tr>

              <tr>
                <td className="league-leaderboard-title">{"Handicap" /* TODO: Translation */}</td>

                {holeScoreRound.hole_scores.map((score, index: number) => {
                  if (score.hole_number <= 9.1) {
                    return (
                      <td key={index} className="league-leaderboard-score">
                        {score.handicap}
                      </td>
                    );
                  } else if (format.holes === "18_holes" && score.hole_number > 9.1) {
                    return (
                      <td key={index} className="league-leaderboard-score">
                        {score.handicap}
                      </td>
                    );
                  }
                })}
              </tr>

              <tr>
                <td className="league-leaderboard-title">{"Par" /* TODO: Translation */}</td>

                {holeScoreRound.hole_scores.map((score, index: number) => {
                  if (score.hole_number <= 9.1) {
                    return (
                      <td key={index} className="league-leaderboard-score">
                        {score.par}
                      </td>
                    );
                  } else if (format.holes === "18_holes" && score.hole_number > 9.1) {
                    return (
                      <td key={index} className="league-leaderboard-score">
                        {score.par}
                      </td>
                    );
                  }
                })}
              </tr>

              <tr>
                <td className="league-leaderboard-title">{"Gross" /* TODO: Translation */}</td>

                {holeScoreRound.hole_scores.map((score, index: number) => {
                  if (score.hole_number <= 9.1) {
                    return (
                      <td key={index} className="league-leaderboard-score">
                        {score.hole_number === 9.1 || score.hole_number === 18.1 || score.hole_number === 18.2 ? (
                          <div>{score.gross}</div>
                        ) : (
                          <div
                            className={classNames({
                              "league-leaderboard-score-eagle": score.par - score.gross >= 2,
                              "league-leaderboard-score-birdie": score.par - score.gross === 1,
                              "league-leaderboard-score-par": score.par - score.gross === 0,
                              "league-leaderboard-score-bogey": score.par - score.gross === -1,
                              "league-leaderboard-score-double-bogey": score.par - score.gross <= -2,
                            })}
                          >
                            {score.gross}
                          </div>
                        )}
                      </td>
                    );
                  } else if (format.holes === "18_holes" && score.hole_number > 9.1) {
                    return (
                      <td key={index} className="league-leaderboard-score">
                        {score.hole_number === 9.1 || score.hole_number === 18.1 || score.hole_number === 18.2 ? (
                          <div>{score.gross}</div>
                        ) : (
                          <div
                            className={classNames({
                              "league-leaderboard-score-eagle": score.par - score.gross >= 2,
                              "league-leaderboard-score-birdie": score.par - score.gross === 1,
                              "league-leaderboard-score-par": score.par - score.gross === 0,
                              "league-leaderboard-score-bogey": score.par - score.gross === -1,
                              "league-leaderboard-score-double-bogey": score.par - score.gross <= -2,
                            })}
                          >
                            {score.gross}
                          </div>
                        )}
                      </td>
                    );
                  }
                })}
              </tr>

              <tr>
                <td className="league-leaderboard-title">{"Net" /* TODO: Translation */}</td>

                {holeScoreRound.hole_scores.map((score: any, index: number) => {
                  if (score.hole_number <= 9.1) {
                    return (
                      <td key={index} className="league-leaderboard-score">
                        {score.hole_number === 9.1 || score.hole_number === 18.1 || score.hole_number === 18.2 ? (
                          <div>{score.net}</div>
                        ) : (
                          <div
                            className={classNames({
                              "league-leaderboard-score-eagle": score.par - score.net >= 2,
                              "league-leaderboard-score-birdie": score.par - score.net === 1,
                              "league-leaderboard-score-par": score.par - score.net === 0,
                              "league-leaderboard-score-bogey": score.par - score.net === -1,
                              "league-leaderboard-score-double-bogey": score.par - score.net <= -2,
                            })}
                          >
                            {score.net}
                          </div>
                        )}
                      </td>
                    );
                  } else if (format.holes === "18_holes" && score.hole_number > 9.1) {
                    return (
                      <td key={index} className="league-leaderboard-score">
                        {score.hole_number === 9.1 || score.hole_number === 18.1 || score.hole_number === 18.2 ? (
                          <div>{score.net}</div>
                        ) : (
                          <div
                            className={classNames({
                              "league-leaderboard-score-eagle": score.par - score.net >= 2,
                              "league-leaderboard-score-birdie": score.par - score.net === 1,
                              "league-leaderboard-score-par": score.par - score.net === 0,
                              "league-leaderboard-score-bogey": score.par - score.net === -1,
                              "league-leaderboard-score-double-bogey": score.par - score.net <= -2,
                            })}
                          >
                            {score.net}
                          </div>
                        )}
                      </td>
                    );
                  }
                })}
              </tr>
            </tbody>
          </table>
        ) : (
          <div style={{ height: "24px" }}>
            <Spin />
          </div>
        )}
      </div>
    );
  }

  function sortByDefault() {
    if (!state.rounds) {
      return;
    }
    const currentRounds = [...state.rounds];
    const sortedRounds = currentRounds?.sort((currentRound, nextRound) => {
      if (!currentRound?.gross_score) {
        return 1;
      } else if (!nextRound?.gross_score) {
        return -1;
      } else {
        return currentRound?.gross_score - nextRound?.gross_score;
      }
    });
    setState(prevState => ({
      ...prevState,
      rounds: sortedRounds,
      sortByType: "default",
      sortByDirection: true,
      defaultSorted: true,
    }));
  }

  function sortLeaderboard(
    sortType: "default" | "gross" | "net" | "points" | "handicap" | "participation" | "bonus",
    sortValue: string,
  ) {
    const currentRounds = [...state.rounds];
    let direction: boolean = state.sortByDirection;
    if (state.sortByType !== sortType) {
      direction = true;
    } else if (direction === false) {
      //Descending order, return to default order
      void sortByDefault();
      return;
    } else {
      direction = !direction;
    }

    const sortedRounds = currentRounds.sort((currentRound, nextRound) => {
      //Sort null values last
      if (!currentRound?.[sortValue]) {
        return 1;
      } else if (!nextRound?.[sortValue]) {
        return -1;
      } else if (sortValue === "points") {
        sortValue = format?.handicap === "gross" ? "gross_points_awarded" : "net_points_awarded";
        return direction
          ? currentRound?.[sortValue] - nextRound?.[sortValue]
          : nextRound?.[sortValue] - currentRound?.[sortValue];
      } else {
        return direction
          ? nextRound?.[sortValue] - currentRound?.[sortValue]
          : currentRound?.[sortValue] - nextRound?.[sortValue];
      }
    });
    setState(prevState => ({ ...prevState, rounds: sortedRounds, sortByType: sortType, sortByDirection: direction }));
  }

  return (
    <>
      {flights.length > 0 ? (
        <>
          {flights.map(flight => {
            return (
              <div key={flight.id} className="mt-2">
                <h1 className="league-leaderboard-flight-title">{flight.name}</h1>
                {roundsTable(flight)}
              </div>
            );
          })}
        </>
      ) : (
        <>{roundsTable(null)}</>
      )}
    </>
  );
}
