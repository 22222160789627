import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Sheet from "components/sheet/Sheet";
import Tabs from "components/tabs/Tabs";
import Checkbox from "components/form/checkbox/Checkbox";
import Search from "components/search/Search";
import { GetAccount } from "api/rpc/2022-09/facilityAdmin/client/accounts";
import { StatusCode } from "api/protocols";
import { IUIActions, showError } from "redux/actions/ui";

import { useTranslation } from "react-i18next";
import { useAppDispatch } from "hooks/redux";

interface IProps {
  /**Boolean trigger for loading the customer's accounts */
  loadAccounts: boolean;
  customerId: number;
  onCancel: () => void;
  onOk: (selectedAccount: Record<string, any>) => void;
  /**User permission to allow the user to search and charge any account */
  searchAccountPermission: boolean;
}

interface IState {
  selectedAccount: Record<string, any>;
  accounts: Array<Record<string, any>>;
  searchedAccounts: Array<Record<string, any>>;
  selectedTabIndex: number;
  searchValue: string;
  modalVisible: boolean;
}

export default function AccountsModal(props: IProps) {
  const { loadAccounts, onCancel, onOk, customerId, searchAccountPermission } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [state, setState] = useState<IState>({
    selectedAccount: null,
    accounts: [],
    searchedAccounts: [],
    selectedTabIndex: 0,
    searchValue: null,
    modalVisible: false,
  });

  useEffect(() => {
    if (state.searchValue) {
      void loadSearchAccounts();
    } else {
      setState(prevState => ({ ...prevState, searchedAccounts: [] }));
    }
  }, [state.searchValue]);

  useEffect(() => {
    if (loadAccounts) {
      void loadCustomerAccounts();
    }
  }, [loadAccounts]);

  async function loadSearchAccounts() {
    const accountRes = await GetAccount(
      {
        search: state.searchValue,
        closed: false,
      },
      true,
    );

    if (accountRes.status !== StatusCode.OK) {
      dispatch(showError(t("components.accounts_modal.accounts_modal.001")));
    }

    setState(prevState => ({
      ...prevState,
      searchedAccounts: accountRes.data,
    }));
  }

  async function loadCustomerAccounts() {
    const accountsRes = await GetAccount({ customer_id: customerId, closed: false }, true);
    if (accountsRes.status !== StatusCode.OK) {
      dispatch(showError(t("components.accounts_modal.accounts_modal.002")));
      return;
    }
    if (accountsRes.data.length === 0 && !searchAccountPermission) {
      dispatch(showError(t("components.accounts_modal.accounts_modal.003")));
      void onCancel();
    } else {
      setState(prevState => ({ ...prevState, accounts: accountsRes.data, modalVisible: true }));
    }
  }

  function handleTabChange(selectedTabIndex: number) {
    setState(prevState => ({ ...prevState, selectedTabIndex, selectedAccount: null, searchedAccounts: [] }));
  }

  function handleSelectAccount(account: Record<string, any>) {
    setState(prevState => ({ ...prevState, selectedAccount: account }));
  }

  function handleClose() {
    setState(prevState => ({
      ...prevState,
      selectedAccount: null,
      selectedTabIndex: 0,
      accounts: [],
      modalVisible: false,
      searchValue: null,
      searchedAccounts: [],
    }));
    void onCancel();
  }

  function handleOk() {
    setState(prevState => ({
      ...prevState,
      selectedAccount: null,
      selectedTabIndex: 0,
      accounts: [],
      modalVisible: false,
      searchValue: null,
      searchedAccounts: [],
    }));
    void onOk(state.selectedAccount);
  }

  const accountTabs = [
    {
      id: "customer-accounts",
      content: t("components.accounts_modal.accounts_modal.004"),
    },
    ...(searchAccountPermission
      ? [
          {
            id: "search-accounts",
            content: t("components.accounts_modal.accounts_modal.005"),
          },
        ]
      : []),
  ];

  return (
    <Sheet
      title={t("components.accounts_modal.accounts_modal.006")}
      open={state.modalVisible}
      size="small"
      closable
      onCancel={handleClose}
      onOk={handleOk}
      okText={t("components.accounts_modal.accounts_modal.007")}
      okDisabled={!state.selectedAccount ? true : false}
      stacked
    >
      <Tabs tabs={accountTabs} selected={state.selectedTabIndex} onSelect={handleTabChange} />

      {state.selectedTabIndex === 0 ? (
        <div className="ui-checkbox-group">
          <br />
          {state.accounts?.map((account, i) => {
            return (
              <div
                key={i}
                onClick={() => handleSelectAccount(account)}
                className={classNames("ui-checkbox-group-item", {
                  "ui-checkbox-group-item_selected": state.selectedAccount?.id === account.id,
                })}
              >
                <div className="ui-checkbox-group-item-content">
                  <div className="ui-checkbox-group-item-text">
                    <p className="text-semibold">{account.title}</p>
                    <p>{account?.customer?.full_name}</p>
                    <p>{account.account_number}</p>
                  </div>
                </div>
                <Checkbox
                  size="medium"
                  value={account.id}
                  checked={state.selectedAccount?.id === account.id}
                  onChange={() => handleSelectAccount(account)}
                />
              </div>
            );
          })}
        </div>
      ) : (
        <div>
          <br />
          <Search
            searchCallback={searchValue => setState(prev => ({ ...prev, searchValue }))}
            historyKey="accounts-modal"
            restrictPresetToRoutes={[undefined]}
          />
          <div className="ui-checkbox-group">
            <br />
            {state.searchedAccounts?.map((account, i) => {
              return (
                <div
                  key={i}
                  onClick={() => handleSelectAccount(account)}
                  className={classNames("ui-checkbox-group-item", {
                    "ui-checkbox-group-item_selected": state.selectedAccount?.id === account.id,
                  })}
                >
                  <div className="ui-checkbox-group-item-content">
                    <div className="ui-checkbox-group-item-text">
                      <p className="text-semibold">{account.title}</p>
                      <p>{account?.customer?.full_name}</p>
                      <p>{account.account_number}</p>
                    </div>
                  </div>
                  <Checkbox
                    size="medium"
                    value={account.id}
                    checked={state.selectedAccount?.id === account.id}
                    onChange={() => handleSelectAccount(account)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </Sheet>
  );
}
