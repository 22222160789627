import React, { useEffect, useState, useMemo } from "react";
import { useAppDispatch } from "hooks/redux";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import {
  GetReservationBookingNoteAll,
  PostReservationBookingNote,
  PutCancelReservationBooking,
  PutReservationBooking,
  PutReservationBookingConfirmation,
  PutCopyReservationBooking,
  GetReservationSegment,
} from "api/rpc/facilityAdmin/reservation/reservation";
import { GetCustomer, PostCustomer } from "api/rpc/2022-09/facilityAdmin/customer/customer";
import { GetCustomerPaymentMethod, PostPaymentMethod, PostSetup } from "api/rpc/facilityAdmin/customer";

import Callout from "components/callout/Callout";
import Radio from "components/radio";
import Sheet from "components/sheet/Sheet";
import Tabs from "components/tabs/Tabs";
import { Select } from "components/select/index";
import Spin from "components/spin/spin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GolferCard from "components/bookingPopUp/golferCard/GolferCard";
import CardSection from "components/cardSection/CardSection";
import Checkbox from "components/form/checkbox/Checkbox";
import { ButtonNew as Button } from "components/buttonNew/index";
import Popover from "components/popover/Popover";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import Toggle from "components/form/toggle/Toggle";
import TextField from "components/form/textField/TextField";
import Note from "components/note/Note";
import NewCustomer, { ICustomerInfoState } from "components/newCustomer/NewCustomer";

import moment from "moment";
import { customerErrorMessage, formatDate, valueToString, convertTime } from "helpers/Helpers";
import { IUIActions, showError } from "redux/actions/ui";
import { ILocation, IModule, IReservationBookingNote, ISegment } from "redux/reducers/models/reservations";
import { ICustomer } from "redux/reducers/models/customer";
import { IAuthState } from "redux/reducers/auth";
import classNames from "classnames";

import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";
import { useElements, useStripe } from "@stripe/react-stripe-js";

import "./reservation.scss";
import Portal from "elements/Portal";

interface IProps {
  uiActions: IUIActions;
  authStore: IAuthState;
  date: Date;
  module: IModule;
  segment: ISegment;
  location: ILocation;
  locations: Array<ILocation>;
  open: boolean;
  durations: { [key: string]: Array<number> };
  durationKey: string;
  onSave: () => void;
  onCancel: () => void;
}

interface IBookingState {
  guestsAmount: number;
  durationKey: string;
  durations: { [key: string]: Array<number> };
  selectedTab: number;
  searching: boolean;
  bookingParticipants: Array<ICustomer>;
  guest1: string;
  guest2: string;
  guest3: string;
  guest4: string;
  guest5: string;
  guest6: string;
  guestSearchResult1: Array<ICustomer>;
  guestSearchResult2: Array<ICustomer>;
  guestSearchResult3: Array<ICustomer>;
  guestSearchResult4: Array<ICustomer>;
  guestSearchResult5: Array<ICustomer>;
  guestSearchResult6: Array<ICustomer>;
  selectedCardId: number;
  customerCreditCards: Array<Record<string, any>>;
  elementComplete: {
    cardNumber: boolean;
    cardExpiry: boolean;
    cardCvc: boolean;
  };
  saveCard: boolean;
  addCard: boolean;
  cancelPopoverVisible: boolean;
  confirmationPopoverVisible: boolean;
  addNewNoteVisible: boolean;
  checkInStatus: string;
  financialStatus: string;
  note: string;
  bookingNotes: Array<IReservationBookingNote>;
  [key: string]: any;
  copyPopoverVisible: boolean;
  copy_location_id: number;
  copy_date: Date;
  copy_time: string;
}

interface INewCustomerState {
  newCustomerSheetActive: boolean;
}

interface IAvailableSegment extends ISegment {
  availableHours: number;
}

export default function ReservationBookingModalEdit(props: IProps) {
  const {
    uiActions,
    date,
    module,
    onSave,
    onCancel,
    open,
    durations,
    segment,
    location,
    locations,
    durationKey,
    authStore,
  } = props;
  const MINUTES_PER_SEGMENT = 30;
  const [bookingState, setBookingState] = useState<IBookingState>({
    guestsAmount: 1,
    durationKey: "",
    durations: null,
    selectedTab: 0,
    searching: false,
    bookingParticipants: [],
    guest1: "",
    guest2: "",
    guest3: "",
    guest4: "",
    guest5: "",
    guest6: "",
    guestSearchResult1: [],
    guestSearchResult2: [],
    guestSearchResult3: [],
    guestSearchResult4: [],
    guestSearchResult5: [],
    guestSearchResult6: [],
    selectedCardId: undefined,
    customerCreditCards: [],
    elementComplete: {
      cardNumber: false,
      cardExpiry: false,
      cardCvc: false,
    },
    saveCard: false,
    addCard: false,
    cancelPopoverVisible: false,
    confirmationPopoverVisible: false,
    checkInStatus: "",
    financialStatus: "",
    note: "",
    bookingNotes: [],
    addNewNoteVisible: false,
    copyPopoverVisible: false,
    copy_location_id: 0,
    copy_date: date,
    copy_time: "",
  });

  const [newCustomerState, setNewCustomerState] = useState<INewCustomerState>({
    newCustomerSheetActive: false,
  });

  const [allAvailableSegments, setAllAvailableSegments] = useState([]);
  const availableSegments = useMemo(() => {
    const returnData: Partial<IAvailableSegment>[] = [];

    allAvailableSegments.map((segment: ISegment, index) => {
      if (segment.blocked_type === "open") {
        returnData.push({
          availableHours: getAvailableHours(segment),
          id: segment.id,
          start_time: segment.start_time,
          tournament_id: segment.tournament_id,
          league_id: segment.league_id,
        });
      }
    });

    return returnData;
  }, [allAvailableSegments]);

  const dispatch = useAppDispatch();
  const { Option } = Select;
  const stripe = useStripe();
  const elements = useElements();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (open && segment) {
      void getBookingNotes();
      setBookingState(prevState => ({
        ...prevState,
        guestsAmount: segment?.booking?.quantity,
        selectedCardId: segment?.booking?.customer_payment_method_id ?? undefined,
        durationKey,
        checkInStatus: segment?.booking?.check_in_status,
        financialStatus: segment?.booking?.order_financial_status,
      }));
    }
  }, [open, segment]);

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = setTimeout(() => {
        void search(bookingState.guest1, "guestSearchResult1");
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      setBookingState(prevState => ({ ...prevState, guestSearchResult1: [] }));
    };
  }, [bookingState.guest1]);

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = setTimeout(() => {
        void search(bookingState.guest2, "guestSearchResult2");
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      setBookingState(prevState => ({ ...prevState, guestSearchResult2: [] }));
    };
  }, [bookingState.guest2]);

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = setTimeout(() => {
        void search(bookingState.guest3, "guestSearchResult3");
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      setBookingState(prevState => ({ ...prevState, guestSearchResult3: [] }));
    };
  }, [bookingState.guest3]);

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = setTimeout(() => {
        void search(bookingState.guest4, "guestSearchResult4");
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      setBookingState(prevState => ({ ...prevState, guestSearchResult4: [] }));
    };
  }, [bookingState.guest4]);

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = setTimeout(() => {
        void search(bookingState.guest5, "guestSearchResult5");
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      setBookingState(prevState => ({ ...prevState, guestSearchResult5: [] }));
    };
  }, [bookingState.guest5]);

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = setTimeout(() => {
        void search(bookingState.guest6, "guestSearchResult6");
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      setBookingState(prevState => ({ ...prevState, guestSearchResult6: [] }));
    };
  }, [bookingState.guest6]);

  const search = async (guestSearchQuery: string, guestSearchResult: string) => {
    try {
      if (guestSearchQuery === "") {
        setBookingState(prevState => ({ ...prevState, [guestSearchResult]: [] }));
        return;
      } else {
        setBookingState(prevState => ({ ...prevState, searching: true }));
        const guestResults = await searchCustomers(guestSearchQuery);
        setBookingState(prevState => ({ ...prevState, searching: false, [guestSearchResult]: guestResults }));
      }
    } catch (error) {
      console.log("err", error);
    }
    return;
  };

  useEffect(() => {
    void loadTimes();
  }, [bookingState.copy_date, bookingState.copy_location_id]);

  async function searchCustomers(params: string) {
    const customerRes = await GetCustomer({ search: params }, false);

    if (customerRes.status !== StatusCode.OK) {
      console.log(customerRes?.message);
      return;
    }

    return customerRes?.data;
  }

  async function getBookingNotes() {
    const notesRes = await GetReservationBookingNoteAll({ reservation_booking_id: segment?.booking_id }, true);
    if (notesRes.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.reservation.reservation_booking_modal_edit.039"));
      return;
    }
    setBookingState(prevState => ({ ...prevState, bookingNotes: notesRes.data }));
  }

  async function resendReservationConfirmation() {
    const confirmationRes = await PutReservationBookingConfirmation(
      { reservation_booking_id: segment?.booking_id },
      true,
    );

    if (confirmationRes?.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.reservation.reservation_booking_modal_edit.047"));
      return;
    }

    uiActions.showSuccess(t("secure.facility.reservation.reservation_booking_modal_edit.048"));
    void handleConfirmBookingEmail();
  }

  async function loadTimes() {
    const segmentRes = await GetReservationSegment(
      {
        module_id: module?.id,
        location_id: bookingState.copy_location_id,
        date: moment(bookingState.copy_date).format("YYYY-MM-DD"),
      },
      false,
    );

    if (segmentRes.status !== StatusCode.OK) {
      uiActions.showError("Error loading available times");
      return;
    }

    setAllAvailableSegments(segmentRes.data);
  }

  async function copyBoooking() {
    const copyRes = await PutCopyReservationBooking(
      {
        booking_id: segment?.booking?.id,
        start_time: bookingState?.copy_time,
        date: formatDate(bookingState.copy_date),
        location_id: bookingState.copy_location_id,
      },
      true,
    );

    if (copyRes?.status !== StatusCode.OK) {
      uiActions.showError("Failed to copy booking");
      return;
    }

    void handleCopyBooking();
    void onSave();
    void closeBookingModal();
    uiActions.showSuccess("Booking copied successfully");
  }

  const tabs = [
    {
      id: "booking-details",
      content: t("secure.facility.reservation.reservation_booking_modal_edit.001"),
    },
    {
      id: "credit-card",
      content: t("secure.facility.reservation.reservation_booking_modal_edit.002"),
    },
    {
      id: "notes",
      content: t("secure.facility.reservation.reservation_booking_modal_edit.040"),
    },
  ];

  const secondaryTabs = [
    {
      id: "booking-confirmation",
      icon: "envelope",
      onSelect: handleConfirmBookingEmail,
    },
    {
      id: "booking-copy",
      icon: "copy",
      onSelect: handleCopyBooking,
    },
    {
      id: "booking-delete",
      icon: "trash",
      onSelect: handleCancelBooking,
    },
  ];

  function handleCancelBooking() {
    setBookingState(prevState => ({
      ...prevState,
      cancelPopoverVisible: !prevState?.cancelPopoverVisible,
      confirmationPopoverVisible: false,
      copyPopoverVisible: false,
    }));
  }

  function handleConfirmBookingEmail() {
    setBookingState(prevState => ({
      ...prevState,
      confirmationPopoverVisible: !prevState?.confirmationPopoverVisible,
      cancelPopoverVisible: false,
      copyPopoverVisible: false,
    }));
  }

  function handleCopyBooking() {
    setBookingState(prevState => ({
      ...prevState,
      copyPopoverVisible: !prevState?.copyPopoverVisible,
      cancelPopoverVisible: false,
      confirmationPopoverVisible: false,
    }));
  }

  function syncDateWithState(returnDate: Date) {
    setBookingState(prevState => ({ ...prevState, copy_date: returnDate }));
  }

  function handleTimeChange(incomingValue: string) {
    setBookingState({
      ...bookingState,
      copy_time: incomingValue,
    });
  }

  function getAvailableHours(availableSegment: ISegment) {
    let availableHours = 0;
    const index = allAvailableSegments.findIndex((segment: ISegment) => segment.id === availableSegment.id);

    for (let i = index; i < allAvailableSegments.length; i++) {
      if (allAvailableSegments[i].blocked_type === "open") {
        availableHours++;
      } else {
        i = allAvailableSegments.length;
      }
    }

    return availableHours;
  }

  function handleTabChange(tabIndex: number) {
    if (tabIndex === 0) {
      setBookingState(prevState => ({
        ...prevState,
        elementComplete: {
          cardNumber: false,
          cardExpiry: false,
          cardCvc: false,
        },
      }));
    } else if (tabIndex === 1) {
      void loadCustomerPaymentMethods();
    }

    setBookingState(prevState => ({ ...prevState, selectedTab: tabIndex }));
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const { id, value } = e.target;
    setBookingState(prevState => ({ ...prevState, [id]: value }));
  }

  async function loadCustomerPaymentMethods() {
    const paymentMethodRes = await GetCustomerPaymentMethod({ customer_id: segment?.booking?.customer_id }, true);
    if (paymentMethodRes?.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.reservation.reservation_booking_modal_edit.003"));
      return;
    }
    setBookingState(prevState => ({ ...prevState, customerCreditCards: paymentMethodRes?.data }));
  }

  function handleGuestAmountChange(amount: number) {
    if (amount < bookingState.guestsAmount) {
      void handleRemoveGuestFromQuantityChange(amount);
    }
    setBookingState(prevState => ({ ...prevState, guestsAmount: amount }));
  }

  function handleDropDownChange(value: string | number, id: string) {
    setBookingState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleCustomerSelection(id: number, customer: any) {
    const updatedBookingParticipants = [...bookingState.bookingParticipants];
    updatedBookingParticipants.push(customer);
    setBookingState(prevState => ({
      ...prevState,
      guest1: "",
      guest2: "",
      guest3: "",
      guest4: "",
      guest5: "",
      guest6: "",
      guestSearchResult1: [],
      guestSearchResult2: [],
      guestSearchResult3: [],
      guestSearchResult4: [],
      guestSearchResult5: [],
      guestSearchResult6: [],
      bookingParticipants: updatedBookingParticipants,
    }));
  }

  function handleRemoveGuestFromQuantityChange(newQuantity: number) {
    const updatedBookingParticipants = [...bookingState.bookingParticipants].filter(
      (participant, index) => index + 1 <= newQuantity,
    );
    setBookingState(prevState => ({
      ...prevState,
      bookingParticipants: updatedBookingParticipants,
      guest1: "",
      guest2: "",
      guest3: "",
      guest4: "",
      guest5: "",
      guest6: "",
      guestSearchResult1: [],
      guestSearchResult2: [],
      guestSearchResult3: [],
      guestSearchResult4: [],
      guestSearchResult5: [],
      guestSearchResult6: [],
    }));
  }

  function handleCardSectionChange(
    e: StripeCardNumberElementChangeEvent | StripeCardExpiryElementChangeEvent | StripeCardCvcElementChangeEvent,
  ) {
    if (bookingState.selectedCardId !== undefined) {
      setBookingState(prevState => ({ ...prevState, selectedCardId: undefined }));
    }

    setBookingState(prevState => ({
      ...prevState,
      elementComplete: { ...prevState.elementComplete, [e.elementType]: e.complete },
    }));
  }

  function onSelectCreditCard(id: number) {
    if (id > 0) {
      if (id === bookingState.selectedCardId) {
        // Deselect card
        setBookingState(prevState => ({ ...prevState, selectedCardId: undefined }));
      } else {
        setBookingState(prevState => ({ ...prevState, selectedCardId: id, addCard: false }));
      }
    } else {
      uiActions.showError(t("secure.facility.reservation.reservation_booking_modal_edit.004"));
    }
  }

  async function collectCard(customer_id: number) {
    if (!stripe || !elements) {
      uiActions.showError(t("secure.facility.reservation.reservation_booking_modal_edit.005"));
      return;
    }

    // Loader for length of operation to stop user from clicking
    uiActions.enqueue();
    try {
      let setupCardRes;
      const paymentMethod = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement("cardNumber"),
      });

      console.log(paymentMethod);

      if (paymentMethod.paymentMethod) {
        setupCardRes = await setupCard(paymentMethod.paymentMethod.id, customer_id);
        uiActions.dequeue();
        return {
          customer_payment_method_id: setupCardRes,
        };
      } else {
        uiActions.showError(paymentMethod?.error?.message);
      }
    } catch (e) {
      uiActions.dequeue();
    }

    uiActions.dequeue();
  }

  async function setupCard(payment_method: string, customer_id: number) {
    const setupRes = await PostSetup({ customer_id }, true);

    if (setupRes.status !== StatusCode.OK) {
      uiActions.showError(setupRes?.data?.message);
      return null;
    } else {
      if (payment_method !== "undefined") {
        const stripeRes = await stripe.confirmCardSetup(setupRes?.data?.data?.setup_intent?.client_secret, {
          payment_method: payment_method,
        });

        if (stripeRes.error) {
          uiActions.showError(stripeRes?.error?.message);
        } else {
          const methodRes = await PostPaymentMethod({
            payment_method_id: stripeRes?.setupIntent?.payment_method,
            customer_id: customer_id,
            save_card: bookingState.saveCard,
          });
          if (methodRes.status !== StatusCode.OK) {
            uiActions.showError(methodRes?.data?.message);
            return null;
          } else {
            uiActions.showSuccess(methodRes.data.message);
            setBookingState(prevState => ({ ...prevState, selectedCardId: methodRes?.data?.data?.id }));

            return methodRes?.data?.data?.id as number;
          }
        }
      }
    }
  }

  async function updateBooking() {
    let customerPaymentMethodId = bookingState.selectedCardId;

    //If the new card fields are filled out, set up new payment method and use that payment method
    if (elementsComplete) {
      const newCardRes = await collectCard(bookingState?.bookingParticipants[0]?.id);
      if (newCardRes?.customer_payment_method_id) {
        customerPaymentMethodId = newCardRes?.customer_payment_method_id;
      }
    }

    const bookingRes = await PutReservationBooking(
      {
        id: segment?.booking_id,
        segment_ids: durations[bookingState?.durationKey],
        date: formatDate(date),
        quantity: bookingState?.guestsAmount,
        customer_payment_method_id: customerPaymentMethodId,
        check_in_status: bookingState?.checkInStatus,
        order_financial_status: bookingState?.financialStatus,
      },
      true,
    );

    if (bookingRes?.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.reservation.reservation_booking_modal_edit.006"));
      return;
    }

    void closeBookingModal();
    void onSave();
    uiActions.showSuccess(t("secure.facility.reservation.reservation_booking_modal_edit.007"));
  }

  function closeBookingModal() {
    setBookingState(prevState => ({
      ...prevState,
      guestsAmount: 1,
      selectedTab: 0,
      durationKey: "",
      bookingParticipants: [],
      durations: null,
      guest1: "",
      guest2: "",
      guest3: "",
      guest4: "",
      guest5: "",
      guest6: "",
      guestSearchResult1: [],
      guestSearchResult2: [],
      guestSearchResult3: [],
      guestSearchResult4: [],
      guestSearchResult5: [],
      guestSearchResult6: [],
      customerCreditCards: [],
      selectedCardId: undefined,
      saveCard: false,
      addCard: false,
      cancelPopoverVisible: false,
      checkInStatus: "",
      financialStatus: "",
      note: "",
      bookingNotes: [],
      addNewNoteVisible: false,
    }));
    void onCancel();
  }

  async function confirmCancelBooking() {
    const cancelRes = await PutCancelReservationBooking({ id: segment?.booking_id }, true);
    if (cancelRes?.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.reservation.reservation_booking_modal_edit.008"));
      return;
    }
    uiActions.showSuccess(t("secure.facility.reservation.reservation_booking_modal_edit.009"));
    void closeBookingModal();
    void onSave();
  }

  function closeNewCustomer() {
    setNewCustomerState(prevState => ({
      ...prevState,
      newCustomerSheetActive: false,
    }));
  }

  async function createNewCustomer(customerInfo: ICustomerInfoState) {
    if (
      customerInfo.firstName === "" ||
      customerInfo.lastName === "" ||
      (customerInfo.emailAddress === "" && customerInfo.phoneNumber === "")
    ) {
      uiActions.showError(t("secure.facility.reservation.reservation_booking_modal_edit.050"));
      return;
    }

    const customerRes = await PostCustomer(
      {
        first_name: customerInfo.firstName,
        last_name: customerInfo.lastName,
        phone: customerInfo.phoneNumber ?? null,
        email: customerInfo.emailAddress ?? null,
      },
      true,
    );

    if (customerRes.status !== 200) {
      dispatch(showError(customerErrorMessage(t, customerRes?.data)));
      return;
    }

    uiActions.showSuccess(t("secure.facility.reservation.reservation_booking_modal_edit.051"));
    void handleCustomerSelection(null, customerRes?.data);
    void closeNewCustomer();
  }

  const elementsComplete =
    bookingState.elementComplete.cardNumber === true &&
    bookingState.elementComplete.cardExpiry === true &&
    bookingState.elementComplete.cardCvc === true;

  const creditCardOverride = authStore?.user?.permissions?.reservations_credit_card_override;
  const creditCardNotSelected = !bookingState.selectedCardId && !elementsComplete;

  function handleToggleChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { id } = e.target;
    setBookingState(prevState => ({ ...prevState, [key]: id }));
  }

  async function handleAddBookingNote() {
    const bookingNoteRes = await PostReservationBookingNote(
      { reservation_booking_id: segment?.booking_id, content: bookingState.note },
      true,
    );

    if (bookingNoteRes.status !== StatusCode.OK) {
      uiActions.showError("Error adding booking note");
    }

    setBookingState(prevState => ({ ...prevState, addNewNoteVisible: false, note: "" }));
    void getBookingNotes();
  }

  return (
    <>
      <div className="facility-reservation-booking-modal-overflow">
        <Sheet
          size="medium"
          open={open}
          closable
          title={t("secure.facility.reservation.reservation_booking_modal_edit.010")}
          cancelText={t("secure.facility.reservation.reservation_booking_modal_edit.011")}
          okText={
            !creditCardOverride && segment?.credit_card_required && creditCardNotSelected
              ? t("secure.facility.reservation.reservation_booking_modal_edit.012")
              : t("secure.facility.reservation.reservation_booking_modal_edit.013")
          }
          onOk={
            !creditCardOverride && segment?.credit_card_required && creditCardNotSelected
              ? () => handleTabChange(1)
              : updateBooking
          }
          onCancel={closeBookingModal}
          backDropCancel={false}
          okDisabled={!bookingState?.guestsAmount || !durations ? true : false}
        >
          <div className="reservation-booking-modal">
            <p className="time-text">
              {moment(segment?.booking?.start_time, "hh:mm:ss").format("h:mm A")}
              {` - ${location?.title}`}
            </p>
            <p className="date-text">{moment(date).format("LL")}</p>

            <Tabs
              tabs={tabs}
              selected={bookingState?.selectedTab}
              onSelect={handleTabChange}
              secondaryTabs={secondaryTabs}
            ></Tabs>
            <div className="reservation-booking-cancel-flex-container">
              <div className="reservation-cancel-booking-popover-container">
                <Popover active={bookingState.cancelPopoverVisible}>
                  <div className="reservation-cancel-booking-container">
                    <div className="reservation-cancel-warning-container">
                      <div className="reservation-cancel-warning-outer-circle">
                        <div className="reservation-cancel-warning-inner-circle">
                          <FontAwesomeIcon icon={["fas", "trash-can"]} className="reservation-cancel-warning-icon" />
                        </div>
                      </div>
                    </div>

                    <p className="reservation-cancel-booking-title">{"Delete Booking"}</p>
                    <p className="reservation-cancel-booking-text">
                      {"Are you sure you want to delete this booking? This action cannot be undone"}
                    </p>
                    <hr />
                    <button onClick={confirmCancelBooking} className="reservation-cancel-booking-button">
                      {"Delete"}
                    </button>
                  </div>
                </Popover>
              </div>
              <div className="bm-copy-booking-popover-container">
                <Popover active={bookingState.copyPopoverVisible}>
                  <div className="reservation-booking-copy-container">
                    <p className="reservation-booking-cancel-title">Copy Booking</p> {/* TODO: Translation */}
                    <hr />
                    <div className="bm-copy-booking-select-date">
                      <DatePickerInput
                        months={1}
                        position="left"
                        startingDate={date}
                        disablePastDays
                        setStartingDate={(returnDate: Date) => syncDateWithState(returnDate)}
                      />
                    </div>
                    <div style={{ textAlign: "center", marginTop: "20px", display: "flex", alignItems: "center" }}>
                      <FontAwesomeIcon icon={["far", "location-dot"]} />
                      <Select
                        className="bm-copy-booking-select"
                        onChange={(incomingValue: number) =>
                          setBookingState(prevState => ({ ...prevState, copy_location_id: incomingValue }))
                        }
                        defaultValue={"0"}
                      >
                        <Option value="0">Location</Option> {/* TODO: Translation */}
                        {locations.map((moduleLocation: ILocation, index: number) => {
                          return (
                            <Option key={index} value={moduleLocation.id}>
                              {moduleLocation.title}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: "20px",
                        marginBottom: "20px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FontAwesomeIcon icon={["far", "clock"]} />
                      <Select
                        className="bm-copy-booking-select"
                        onChange={(incomingValue: string) => handleTimeChange(incomingValue)}
                        defaultValue={"0"}
                      >
                        <Option value="0">Time</Option> {/* TODO: Translation */}
                        {durations &&
                          availableSegments
                            ?.filter(
                              (segment: IAvailableSegment) =>
                                segment.availableHours >= durations[bookingState.durationKey]?.length,
                            )
                            ?.map(segment => {
                              return (
                                <Option value={segment.start_time} key={segment.id}>
                                  <span style={{ display: "flex", alignItems: "center", gap: "6px" }}>
                                    {convertTime(segment.start_time)}
                                    {segment.tournament_id && <FontAwesomeIcon icon={["far", "trophy"]} />}
                                    {segment.league_id && <FontAwesomeIcon icon={["far", "users"]} />}
                                  </span>
                                </Option>
                              );
                            })}
                      </Select>
                    </div>
                    <Button type="primary" size="medium" onClick={copyBoooking} block>
                      Copy {/* TODO: Translation */}
                    </Button>
                  </div>
                </Popover>
              </div>
              <Popover active={bookingState.confirmationPopoverVisible}>
                <div className="reservation-booking-cancel-container">
                  {/* Translation Needed */}
                  <p className="reservation-booking-cancel-title">Resend Confirmation</p>
                  <hr />
                  {/* Translation Needed */}
                  <Button type="primary" size="medium" onClick={resendReservationConfirmation} block>
                    Send
                  </Button>
                </div>
              </Popover>
            </div>
            {bookingState?.selectedTab === 0 ? (
              <div className="facility-reservation-booking-modal">
                <div className="booking-modal-options">
                  <div className="booking-modal-flex-grow">
                    <h3>{t("secure.facility.reservation.reservation_booking_modal_edit.016")}</h3>
                    {durations ? (
                      <Select
                        defaultValue={bookingState?.durationKey}
                        onChange={(value: string) => handleDropDownChange(value, "durationKey")}
                      >
                        {Object?.keys(durations)?.map((durationKey, durationIndex) => {
                          return (
                            <Option key={durationIndex} value={durationKey} name={durationKey}>
                              {durationKey}
                            </Option>
                          );
                        })}
                      </Select>
                    ) : (
                      <div className="booking-modal-spinner">
                        <Spin />
                      </div>
                    )}
                  </div>
                  <div className="booking-modal-flex-grow">
                    <h3>{t("secure.facility.reservation.reservation_booking_modal_edit.017")}</h3>
                    <Radio.Group
                      name="guests_amount"
                      onChange={handleGuestAmountChange}
                      value={bookingState.guestsAmount}
                    >
                      {[...Array(6).keys()].map(index => {
                        return (
                          <Radio.Button key={index} value={index + 1}>
                            {index + 1}
                          </Radio.Button>
                        );
                      })}
                    </Radio.Group>
                  </div>
                </div>
                <GolferCard
                  closable={false}
                  name={segment?.booking?.customer?.full_name}
                  email={segment?.booking?.customer?.email}
                  memberCode={segment?.booking?.customer?.member_code}
                  customerType={segment?.booking?.customer?.customer_type}
                  phone={segment?.booking?.customer?.phone}
                />
                <div className="flex-row-container">
                  <div className="flex-col-container">
                    <h3>{t("secure.facility.reservation.reservation_booking_modal_edit.018")}</h3>
                    <Toggle
                      labelRight={t("secure.facility.reservation.reservation_booking_modal_edit.019")}
                      size="small"
                      id="checked"
                      checked={bookingState?.checkInStatus === "checked" ? true : false}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleToggleChange(e, "checkInStatus")}
                    />
                    <Toggle
                      labelRight={t("secure.facility.reservation.reservation_booking_modal_edit.020")}
                      size="small"
                      id="unchecked"
                      checked={bookingState?.checkInStatus === "unchecked" ? true : false}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleToggleChange(e, "checkInStatus")}
                    />
                    <Toggle
                      labelRight={t("secure.facility.reservation.reservation_booking_modal_edit.021")}
                      size="small"
                      id="no_show"
                      checked={bookingState?.checkInStatus === "no_show" ? true : false}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleToggleChange(e, "checkInStatus")}
                    />
                  </div>
                  <div className="border-seperator"></div>
                  <div className="flex-col-container">
                    <h3>{t("secure.facility.reservation.reservation_booking_modal_edit.022")}</h3>
                    <Toggle
                      labelRight={t("secure.facility.reservation.reservation_booking_modal_edit.023")}
                      size="small"
                      id="unpaid"
                      checked={bookingState?.financialStatus === "unpaid" ? true : false}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleToggleChange(e, "financialStatus")}
                    />
                    <Toggle
                      labelRight={t("secure.facility.reservation.reservation_booking_modal_edit.024")}
                      size="small"
                      id="paid"
                      checked={bookingState?.financialStatus === "paid" ? true : false}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleToggleChange(e, "financialStatus")}
                    />
                  </div>
                </div>

                {/* Change array size to 6 later */}
                {/* {[...Array(1).keys()].map(index => {
              if (bookingState.bookingParticipants[index]) {
                return (
                  <GolferCard
                    closable={true}
                    removeGolfer={() => handleRemoveGuest(index)}
                    name={bookingState.bookingParticipants[index]?.full_name}
                    email={bookingState.bookingParticipants[index]?.email}
                    memberCode={bookingState.bookingParticipants[index]?.member_code}
                    customerType={bookingState.bookingParticipants[index]?.customer_type}
                    phone={bookingState.bookingParticipants[index]?.phone}
                    key={index}
                  />
                );
              } else {
                const searchResultKey = "guestSearchResult".concat((index + 1).toString());
                const queryKey = "guest".concat((index + 1).toString());
                return (
                  <Select
                    key={index}
                    label={`Guest ${index + 1}`}
                    showSearch
                    onSearch={(query: string) => handleCustomerSearch(query, queryKey)}
                    onChange={(id: number, customer: ICustomer) => handleCustomerSelection(id, customer)}
                    allowClear
                    searchValue={bookingState[queryKey]}
                    showDropDownOnFocus={true}
                    searching={bookingState.searching}
                    disabled={index + 1 <= bookingState.guestsAmount ? false : true}
                    placeholder="Search customer..."
                  >
                   <div
                      className="ui-select-dropdown-list-item"
                      onClick={() => setNewCustomerState(prevState => ({...prevState, newCustomerSheetActive: true}))}
                    >
                      <p>New Customer</p>
                    </div>
                    {bookingState[searchResultKey].map((customer: ICustomer, customerIndex: number) => {
                      return (
                        <Option
                          key={customerIndex}
                          value={customer?.id}
                          extraValues={customer}
                        >
                          <div className="flex justify-between">
                            <div>
                              <div className="font-semibold text-lg">{customer?.full_name}</div>
                              <div className="text-sm text-gray-500">{customer?.customer_type}</div>
                              <div className="text-sm text-gray-500">{customer?.email}</div>
                              <div className="text-sm text-gray-500">{customer?.phone ? customer?.phone : null}</div>
                            </div>

                            <div className="font-medium text-base text-gray-500 self-end">{customer?.member_code}</div>
                          </div>
                        </Option>
                      );
                    })}
                  </Select>
                );
              }
            })} */}
              </div>
            ) : bookingState?.selectedTab === 1 ? (
              <div style={{ overflowY: "scroll", height: "32rem" }}>
                <div className="flex flex-column">
                  <span className="text-xl font-semibold mb-4 mt-2">
                    {t("secure.facility.reservation.reservation_booking_modal_edit.025")}
                  </span>
                  {bookingState.customerCreditCards?.length >= 1 ? (
                    <div className="ui-checkbox-group">
                      {bookingState.customerCreditCards?.map((paymentMethod: Record<string, any>, index: number) => {
                        return (
                          <div
                            key={index}
                            onClick={() => onSelectCreditCard(paymentMethod.id)}
                            className={classNames("ui-checkbox-group-item", {
                              "ui-checkbox-group-item_selected": paymentMethod.id === bookingState?.selectedCardId,
                            })}
                          >
                            <div className="ui-checkbox-group-item-content">
                              <div className="ui-checkbox-group-item-lead">
                                <FontAwesomeIcon size="1x" icon={["far", "credit-card"]} />
                              </div>
                              <div className="ui-checkbox-group-item-text">
                                <p className="text-sm text-medium text-gray-700">
                                  {valueToString(paymentMethod.brand, "capitalize")}{" "}
                                  {t("secure.facility.reservation.reservation_booking_modal_edit.043")}{" "}
                                  {paymentMethod.last4}
                                </p>
                                <p className="text-sm text-regular text-gray-600">
                                  {t("secure.facility.reservation.reservation_booking_modal_edit.044")}{" "}
                                  {paymentMethod.expiry}
                                </p>
                              </div>
                            </div>
                            <Checkbox
                              size="medium"
                              value={paymentMethod.id}
                              checked={paymentMethod.id === bookingState?.selectedCardId}
                              onChange={() => onSelectCreditCard(paymentMethod.id)}
                            />
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <Callout
                      type="warning"
                      title={"Add a payment method"}
                      content={"Customer does not have any saved payment methods on their account"}
                    />
                  )}
                </div>

                {bookingState.addCard && (
                  <div className="flex flex-column">
                    <span className="text-xl font-semibold mb-4 mt-2">
                      {t("secure.facility.reservation.reservation_booking_modal_edit.028")}
                    </span>
                    {open && <CardSection onChange={handleCardSectionChange} type="one-row" />}
                    <Checkbox
                      label={t("secure.facility.reservation.reservation_booking_modal_edit.029")}
                      size="small"
                      value={bookingState.saveCard}
                      checked={bookingState.saveCard}
                      onChange={() => setBookingState(prevState => ({ ...prevState, saveCard: !prevState.saveCard }))}
                    />
                  </div>
                )}
                <Button
                  type="text"
                  onClick={() => setBookingState(prevState => ({ ...prevState, addCard: !prevState.addCard }))}
                  className="mt-2 mr-auto"
                >
                  <span>
                    {bookingState.addCard === false
                      ? t("secure.facility.reservation.reservation_booking_modal_edit.030")
                      : t("secure.facility.reservation.reservation_booking_modal_edit.031")}
                  </span>
                </Button>
              </div>
            ) : bookingState?.selectedTab === 2 ? (
              <div className="reservation-booking-notes">
                <div className="flex flex-column">
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      style={{ display: "flex", gap: "8px" }}
                      type="text"
                      onClick={() => setBookingState(prevState => ({ ...prevState, addNewNoteVisible: true }))}
                    >
                      <FontAwesomeIcon icon={["fas", "plus"]} />
                      <p>{t("secure.facility.reservation.reservation_booking_modal_edit.041")}</p>
                    </Button>
                  </div>
                  <div className="notes-container mt-4">
                    {bookingState?.bookingNotes?.map((note, index) => {
                      return (
                        <div className="mt-3 mb-3" key={index}>
                          <Note
                            key={index}
                            authorName={note?.author?.full_name}
                            dateString={moment(note?.created_at).format("LLL")}
                            content={note?.content}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </Sheet>
      </div>

      <NewCustomer
        newCustomerSheetActive={newCustomerState.newCustomerSheetActive}
        onCancel={closeNewCustomer}
        onOk={createNewCustomer}
      />

      <Portal isMounted={bookingState.addNewNoteVisible}>
        <Sheet
          title="Note"
          open={bookingState.addNewNoteVisible}
          size="small"
          onCancel={() => setBookingState(prevState => ({ ...prevState, addNewNoteVisible: false, note: "" }))}
          onOk={handleAddBookingNote}
          okDisabled={bookingState.note === ""}
          stacked
        >
          <div className="new-note_container">
            <TextField
              labelHidden
              autoFocus
              id="note"
              placeholder="Notes"
              rows={2}
              value={bookingState.note}
              onChange={handleInputChange}
            ></TextField>
          </div>
        </Sheet>
      </Portal>
    </>
  );
}
