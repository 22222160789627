// import { ICustomer } from "./models/customer";
import { IFacility } from "../models/facility";
// import { IFacility } from "api/rpc/2024-04/clientAdmin/facility/facility";

export interface IClientFacilityStore {
  isLoaded: boolean;
  error?: any;
  facility?: IFacility;
  facilities?: Array<IFacility>;
  weather?: any;
  paymentOptions: any[];
}

export interface IFacilityAction {
  type: string;
  payload: Partial<IClientFacilityStore>;
}

const initialState: IClientFacilityStore = {
  isLoaded: false,
  error: null,
  facility: null,
  facilities: null,
  weather: null,
  paymentOptions: [],
};

export default function FacilityReducer(state = initialState, action: IFacilityAction) {
  switch (action.type) {
    case "facility.update":
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
