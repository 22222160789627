import React, { useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./factorNodeEditMode.scss";
import { useAutoFocusInput } from "./useAutoFocusInput";
import { useDynamicPricingContext } from "../../DynamicPricingContext";
import { areFactorValuesOverlapping } from "../../TeeSheetDynamicPricingEdit";
import { DynamicPricingFactorValue } from "../DynamicPricingFactorGroupNode";

export interface IFactorNumberRangeValueType {
  valueType: "number_range";
  startNumber: number;
  endNumber: number;
}

interface IFactorNodeNumberRangeEditModeProps {
  parentId: string;
  id: string;
  defaultStartNumber: number;
  defaultEndNumber: number;
  onConfirm: (value: IFactorNumberRangeValueType) => void;
  onCancel: () => void;
}

export function isFactorNumberRangeValid(startNumber: number, endNumber: number) {
  return (
    startNumber != null &&
    endNumber != null &&
    startNumber >= 0 &&
    endNumber >= 0 &&
    startNumber % 1 === 0 &&
    endNumber % 1 === 0 &&
    startNumber < endNumber
  );
}

export default function FactorNodeNumberRangeEditMode(props: IFactorNodeNumberRangeEditModeProps) {
  const { parentId, id, defaultStartNumber, defaultEndNumber, onConfirm, onCancel } = props;

  const dynamicPricingContext = useDynamicPricingContext();

  const autoFocusInputRef = useAutoFocusInput();

  const [startNumberInput, setStartNumberInput] = useState<string>(
    defaultStartNumber == null ? "" : String(defaultStartNumber),
  );

  const [endNumberInput, setEndNumberInput] = useState<string>(
    defaultEndNumber == null ? "" : String(defaultEndNumber),
  );

  const startNumber = startNumberInput === "" ? null : Number(startNumberInput);
  const endNumber = endNumberInput === "" ? null : Number(endNumberInput);

  const confirmDisabled = useMemo(() => {
    if (!isFactorNumberRangeValid(startNumber, endNumber)) {
      return true;
    }

    const factorSiblings = dynamicPricingContext.getFactorSiblings(parentId, id);

    const dynamicFactorValue: DynamicPricingFactorValue = {
      valueType: "number_range",
      startNumber,
      endNumber,
    };

    return areFactorValuesOverlapping([...factorSiblings.map(sibling => sibling.data), dynamicFactorValue]);
  }, [startNumber, endNumber]);

  return (
    <div className="factor-node-edit-mode">
      <input
        ref={autoFocusInputRef}
        className="factor-node-edit-mode-input"
        value={startNumberInput}
        onChange={e => setStartNumberInput(e.target.value)}
      />
      -
      <input
        className="factor-node-edit-mode-input"
        value={endNumberInput}
        onChange={e => setEndNumberInput(e.target.value)}
      />
      <button
        type="button"
        className="factor-node-edit-mode-confirm"
        disabled={confirmDisabled}
        onClick={() =>
          onConfirm({
            valueType: "number_range",
            startNumber,
            endNumber,
          })
        }
      >
        <FontAwesomeIcon icon={["fal", "circle-check"]} />
      </button>
      <button type="button" className="factor-node-edit-mode-cancel" onClick={onCancel}>
        <FontAwesomeIcon icon={["fal", "circle-x"]} />
      </button>
    </div>
  );
}
