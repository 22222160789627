import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { ITournamentRegistrationFee, ITournament } from "redux/reducers/models/tournament";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseTournamentUrl = "/" + apiVersion + "/" + adminType + "/tournament";

interface IGetTournament {
  id?: number;
  search?: string;
}

interface IGetTournamentResponse {
  data: ITournament[];
  status: number;
  message: string;
}

// GET Tournament
// Retrieve a list of tournaments
export async function GetTournament(
  params: IGetTournament,
  useGlobalLoader: boolean,
  token?: CancelToken,
): Promise<IGetTournamentResponse> {
  return await APIGet(baseTournamentUrl, params, useGlobalLoader, token);
}

// GET Tournament Players
// Retrieve a list of tournament players
export async function GetTournamentPlayers(params: any, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseTournamentUrl + "/participant", params, useGlobalLoader, token);
}

interface IPostTournament {
  name: string;
  start_time: string;
  date: string;
}

// POST Tournament
// Create a new tournament
export async function PostTournament(params: IPostTournament, useGlobalLoader: boolean) {
  return await APIPost(baseTournamentUrl, params, useGlobalLoader);
}

interface IPutTournament {
  id: number;
  name: string;
  start_time: string;
  date: string;
  description: string;
}

// PUT Tournament
// Update an existing tournament
export async function PutTournament(params: IPutTournament, useGlobalLoader: boolean) {
  return await APIPut(baseTournamentUrl, params, useGlobalLoader);
}

//POST Add Player
// Add player to tournament
export async function AddPlayerToTournament(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseTournamentUrl + "/participant", params, useGlobalLoader);
}

interface IGetTournamentRegistrationFees {
  tournament_id: number;
}

interface IGetTournamentRegistrationFeesResponse {
  data: ITournamentRegistrationFee[];
  status: number;
}

export async function GetTournamentRegistrationFees(
  params: IGetTournamentRegistrationFees,
  useGlobalLoader: boolean,
): Promise<IGetTournamentRegistrationFeesResponse> {
  return await APIGet(baseTournamentUrl + "/registration-fee", params, useGlobalLoader);
}

interface IPostTournamentRegistrationFee {
  variant_id: number;
  tournament_id: number;
  players: number;
  description: string;
}

interface IPostTournamentRegistrationFeeResponse {
  data: ITournamentRegistrationFee;
  status: number;
}

export async function PostTournamentRegistrationFee(
  params: IPostTournamentRegistrationFee,
  useGlobalLoader: boolean,
): Promise<IPostTournamentRegistrationFeeResponse> {
  return await APIPost(baseTournamentUrl + "/registration-fee", params, useGlobalLoader);
}

interface IDeleteTournamentRegistrationFee {
  registration_fee_id: number;
}

export async function DeleteTournamentRegistrationFee(
  params: IDeleteTournamentRegistrationFee,
  useGlobalLoader: boolean,
) {
  return await APIDelete(baseTournamentUrl + "/registration-fee", params, useGlobalLoader);
}
