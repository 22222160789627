import { APIPost, APIPut, StatusCode } from "api/protocols";
import { ICartTransaction } from "redux/reducers/models/cart";
import { IGateway, IKind, IPaymentMethod, ISource } from "redux/reducers/models/transaction";

const apiVersion = "2022-09";
const newApiVersion = "2024-04";
const adminType = "facility-admin";
const baseOrderUrl = "/" + apiVersion + "/" + adminType + "/order/transaction";
const newBaseOrderUrl = "/" + newApiVersion + "/" + adminType + "/order/transaction";

interface IPostTransaction {
  kind: IKind;
  amount: number;
  order_id: number;
  payment_option_id: number;
  gift_card_code?: string;
  gift_card_pin?: string;
  account_id?: number;
  guest_name?: string;
  room_number?: string;
  credit_book_id?: number;
}

export interface IPostTransactionResponse {
  status: StatusCode;
  data: ICartTransaction;
}

export async function PostTransaction(
  params: IPostTransaction,
  useGlobalLoader: boolean,
): Promise<IPostTransactionResponse> {
  return await APIPost(newBaseOrderUrl, params, useGlobalLoader);
}

export async function PutTransaction(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseOrderUrl, params, useGlobalLoader);
}

export async function PutCapture(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseOrderUrl + "/capture", params, useGlobalLoader);
}
export async function PutCancelPaymentIntent(
  params: { payment_intent_id: string | number; transaction_id: number },
  useGlobalLoader: boolean,
) {
  return await APIPut(baseOrderUrl + "/cancel", params, useGlobalLoader);
}
