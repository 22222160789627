import { IAPIResponse, StatusCode } from "api/protocols";
import { PostCart, PostCheckout, PostMerge, PutCart, PutClearCart } from "api/rpc/2022-09/facilityAdmin/cart/cart";
import classNames from "classnames";
import { Badge } from "components/badge/Badge";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { loadActiveTableNew, loadTableCart, setActiveTable } from "redux/actions/cart";
import { dequeue, enqueue, showError, showSuccess } from "redux/actions/ui";
import { ICart, ICartLineItem, IDiscountLines } from "redux/reducers/models/cart";
import "./tableServiceCart.scss";
import "../../tableService.scss";
import CartTotalsNew from "pages/secure/facility/register/registerNew/cartTotalsNew/CartTotalsNew";
import { IOrder } from "redux/reducers/models/order";
import { ButtonNew as Button } from "components/buttonNew";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GetOrder, GetReceipt, PutOrderComplete, PutVoidOrder } from "api/rpc/2024-04/facilityAdmin/order/order";
import { useRegisterContext } from "pages/secure/facility/register/registerNew/RegisterContext";
import { ITableServiceState } from "../NewTableService";
import TableServiceCartLineItems from "./TableServiceCartLineItems";
import EditLineItemModal, { TUpdatedLineItem } from "../../LineItemModals/editLineItemModal/EditLineItemModal";
import { cloneDeep, isEqual } from "lodash";
import { DeleteLineItemToCart, PutLineItemToCart } from "api/rpc/2024-04/facilityAdmin/order/cart/lineItem";
import { DeleteDiscountLine, PostDiscountLine } from "api/rpc/2024-04/facilityAdmin/order/cart/discountLine";
import Popup from "components/popup/Popup";
import Sheet from "components/sheet/Sheet";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import PaymentMethods from "elements/register/cartMenu/PaymentMethods";
import Spin from "components/spin/spin";
import CartTransactions from "elements/register/cartMenu/CartTransactions";
import GiftCardRedeem, { IGiftCardRedeemState } from "components/giftCardRedeem/GiftCardRedeem";
import GiftCardSell, { IGiftCardSellState } from "components/giftcardSell/GiftCardSell";
import { GetGiftCardValidate } from "api/rpc/2024-04/facilityAdmin/payment/giftCard";
import AccountsModal from "components/accountsModal/AccountsModal";
import { IPrizeAccount } from "pages/secure/facility/settings/prizeAccounts/PrizeAccount";
import useModal from "hooks/modals/useModal";
import CreditBookModal from "components/creditBookModal/CreditBookModal";
import { GetRoomOccupants, IOccupant } from "api/rpc/room";
import { isEmpty } from "helpers/Helpers";
import Checkbox from "components/form/checkbox/Checkbox";
import axios, { CancelToken } from "axios";
import { PostTransaction } from "api/rpc/2022-09/facilityAdmin/order/transaction";
import {
  clearReaderDisplay,
  sheetInfo,
  processPayment as stripeProcessPayment,
  setReaderDisplay,
  convertOrderIntoDisplayInfo,
} from "helpers/StripeTerminalWrapper";
import { useStripeTerminalPayment } from "hooks/useStripeTerminalPayment/useStripeTerminalPayment";
import { useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";
import CheckoutForm, { cancelManualCreditPaymentIntent } from "components/checkoutForm/CheckoutForm";
import { IStripePaymentState } from "pages/secure/facility/teesheet/TeeSheet";
import {
  GetTable,
  GetTableReceipt,
  ITable,
  PutCloseTable,
  PutMoveSeats,
  PutTable,
} from "api/rpc/facilityAdmin/tables/tables";
import OrderCompletePopup from "components/orderCompletePopup/OrderCompletePopup";
import ActionModal, { IAction } from "components/actionModal/ActionModal";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import TableServiceSendLineItemsModal from "../../LineItemModals/TableServiceSendLineItemsModal";
import { PostKitchenChit } from "api/rpc/2024-04/facilityAdmin/facility/kitchen/chit";
import { usePOSPrinter } from "hooks/usePOSPrinter/usePOSPrinter";
import PrintChit from "components/printChit/PrintChit";
import { FireKitchenChit } from "api/rpc/2022-09/facilityAdmin/facility/kitchen";
import { Select } from "components/select/index";
import MergeSeats, { IMergeSeatsState } from "components/mergeSeats/MergeSeats";
import EditSeatDetails from "components/editSeatDetails/EditSeatDetails";
import { ICustomer } from "redux/reducers/models/customer";
import { NewBadgeGroup } from "components/badgeGroup/NewBadgeGroup";
import TableServiceKitchenChitModal from "../../TableServiceKitchenChitModal/TableServiceKitchenChitModal";
import MoveSeats, { IMoveSeatsState } from "components/moveSeats/MoveSeats";
import TransferTableSheet, { ITransferTableSheetState } from "components/transferTableSheet/TransferTableSheet";
import { IUser } from "redux/reducers/models/user";
import { GetFacilityAdmin } from "api/rpc/2024-04/facilityAdmin/client/admin/admin";
import TableServiceMoveLineItemsModal from "containers/facility/tableServiceMoveLineItemsModal";
import { isSeatAbleToMoveLineItems } from "../../LineItemModals/TableServiceMoveLineItemsModal";
import SplitLineItemsModal from "../../LineItemModals/splitLineItemsModal/SplitLineItemsModal";
import Divider from "components/divider";
import { PutVoidCarts } from "api/rpc/2024-04/facilityAdmin/order/cart/cart";
import Portal from "elements/Portal";
import LeagueSelectionModal from "components/leagueSelectionModal/LeagueSelectionModal";

type TGiftCardParam = {
  product_id: number;
  cart_line_item_id: number;
  code: string;
  pin: number;
  random: boolean;
  balance: number;
  reload: number;
};

interface IProps {
  selectCourse: (id: number) => void;
  selectedCourseId: number;
  order: IOrder;
  setTableState: React.Dispatch<React.SetStateAction<ITableServiceState>>;
  navigateToTableSelection: () => void;
  cartLoadingMessage: string;
}

interface IActionsState {
  showActionsModal: boolean;
}
interface ILineItemState {
  showEditLineItemModal: boolean;
  selectedLineItem: ICartLineItem;
}

interface IDiscountState {
  selectedDiscount: IDiscountLines;
  showModal: boolean;
  parentLineItemId: number;
}

interface IPaymentState {
  paymentModalVisible: boolean;
  paymentAmount: string;
  paymentOption: Record<string, any>;
  paymentInProgress: boolean;

  clientSecret: string;
  manualCardModalVisible: boolean;

  sellGiftCardsActive: boolean;
  giftCardModalVisible: boolean;
  gift_cards: Array<TGiftCardParam>;

  changeDue: number;
  changeDueVisible: boolean;

  accountsVisible: boolean;
  accounts: Record<string, any>[];
  selectedAccount: Record<string, any>;

  leagueFeesActive: boolean;
  leagueFeeProducts: Array<ICartLineItem>;
  selectedLeagues: Array<number>;

  roomChargeSheetVisible: boolean;
  roomOccupantSearchQuery: string;
  roomOccupantsSearchResults: IOccupant[];
  selectedOccupant: IOccupant;
}

export default function TableServiceCart(props: IProps) {
  const { selectedCourseId, selectCourse, order, setTableState, navigateToTableSelection, cartLoadingMessage } = props;
  const {
    cartStore: { cart, activeTable },
    cartStore,
    facilityStore,
    terminalStore,
    authStore,
  } = useAppSelector(store => store);
  const permissions = authStore?.user?.permissions;
  const dispatch = useAppDispatch();
  const { loadingVariants, lineItemsUpdating, addLineItemUpdating, removeLineItemUpdating, updateRegisterState } =
    useRegisterContext();
  const elements = useElements();
  const { printChits } = usePOSPrinter();
  const { Option } = Select;

  // Display loading seats
  const [tempSeats, setTempSeats] = useState<Array<{ id: number }>>([]);

  const [lineItemState, setLineItemState] = useState<ILineItemState>({
    showEditLineItemModal: false,
    selectedLineItem: null,
  });
  const [discountState, setDiscountState] = useState<IDiscountState>({
    selectedDiscount: null,
    showModal: false,
    parentLineItemId: null,
  });

  const [paymentState, setPaymentState] = useState<IPaymentState>({
    paymentModalVisible: false,
    paymentAmount: "",
    paymentOption: null,
    paymentInProgress: false,

    clientSecret: "",
    manualCardModalVisible: false,

    giftCardModalVisible: false,
    gift_cards: null,
    sellGiftCardsActive: false,

    changeDue: null,
    changeDueVisible: false,

    accountsVisible: false,
    accounts: [],
    selectedAccount: undefined,

    leagueFeesActive: false,
    leagueFeeProducts: [],
    selectedLeagues: null,

    roomChargeSheetVisible: false,
    roomOccupantSearchQuery: "",
    roomOccupantsSearchResults: [],
    selectedOccupant: null,
  });

  const [actionsState, setActionsState] = useState<IActionsState>({
    showActionsModal: false,
  });

  const [manualStripeState, setManualStripeState] = useState({
    clientSecret: "",
    visible: false,
    transaction: undefined,
    elementComplete: {
      cardNumber: false,
      cardExpiry: false,
      cardCvc: false,
    },
  });

  const [submitTrigger, setSubmitTrigger] = useState<boolean>(false);
  const [manualProcessing, setManualProcessing] = useState<boolean>(false);

  const [stripePaymentState, setStripePaymentState] = useState<IStripePaymentState>({
    onCancel: () => {},
    onOk: () => {},
    cancelText: "",
    okText: "",
    sheetVisible: false,
    titleText: "",
    message: "",
    convertedCode: "",
    processing: false,
    success: false,
  });

  //Credit Book payment Method
  const {
    state: modalCreditBook,
    updateModal: updateCreditBook,
    closeModal: closeCreditBook,
  } = useModal<{ selectedCreditBook: IPrizeAccount }>({ selectedCreditBook: null });

  //Order Complete
  const { state: orderComplete, updateModal: updateOrderComplete, closeModal: closeOrderComplete } = useModal();
  //Close Table
  const {
    state: closeTable,
    updateModal: updateCloseTable,
    closeModal: cancelCloseTable,
  } = useModal<{
    seatsOpen: boolean;
    partiallyPaidSeats: boolean;
    voidSeats: boolean;
    cancel_reason: string;
    loadingMessage: string;
  }>({
    seatsOpen: false,
    partiallyPaidSeats: false,
    voidSeats: false,
    cancel_reason: "Server Error",
    loadingMessage: "",
  });

  //Print Chits
  const { state: printChitsState, updateModal: updatePrintChits, closeModal: cancelPrintChits } = useModal();
  //Send Chits
  const { state: sendChits, updateModal: updateSendChits, closeModal: cancelSendChits } = useModal();
  const [forceRefreshChits, setForceRefreshChits] = useState<boolean>(true);

  //Void Seat
  const {
    state: voidSeat,
    updateModal: updateVoidSeat,
    closeModal: closeVoidSeat,
  } = useModal<{ cancel_reason: string }>({ cancel_reason: "Server Error" });

  //Close Seat
  const { state: closeSeat, updateModal: updateCloseSeat, closeModal: closeCloseSeat } = useModal();
  //Clear Seat
  const { state: clearSeat, updateModal: updateClearSeat, closeModal: closeClearSeat } = useModal();
  //Merge Seats
  const { state: mergeSeats, updateModal: updateMergeSeats, closeModal: closeMergeSeats } = useModal();
  //Move Seats
  const { state: moveSeats, updateModal: updateMoveSeats, closeModal: closeMoveSeats } = useModal();
  //Seat Details
  const {
    state: seatDetails,
    updateModal: updateSeatDetails,
    closeModal: closeSeatDetails,
  } = useModal<{ customer: ICustomer; description: string }>({ customer: null, description: "" });

  //View Chits
  const { state: viewChits, updateModal: updateViewChits, closeModal: closeViewChits } = useModal();

  //Move Line Items
  const { state: moveItems, updateModal: updateMoveItems, closeModal: closeMoveItems } = useModal();

  //Split Line Items
  const { state: splitItems, updateModal: updateSplitItems, closeModal: closeSplitItems } = useModal();

  //Transfer Table
  const {
    state: transferTable,
    updateModal: updateTransferTable,
    closeModal: closeTransferTable,
  } = useModal<{ users: Array<IUser> }>({ users: [], clearOnClose: false });

  const {
    StripeTerminalSheet,
    processPayment,
    paymentStatus,
    order: tempStripePaymentOrder,
  } = useStripeTerminalPayment();

  useEffect(() => {
    void loadFacilityUsers();
  }, []);

  useEffect(() => {
    if (cartStore?.activeTable) {
      const closeTable = cartStore?.activeTable?.carts?.some(cart => cart.status === "open");
      if (!closeTable) {
        updateCloseTable({ isOpen: true });
      }
    }
  }, [cartStore?.activeTable]);

  useEffect(() => {
    if ((paymentStatus === "partial_payment" || paymentStatus === "paid") && tempStripePaymentOrder !== null) {
      void updateOrder(tempStripePaymentOrder.id, true);
      if (order.balance > 0) {
        setPaymentState(prevState => ({ ...prevState, paymentAmount: order.balance.toFixed(2) }));
      }
    }
  }, [paymentStatus]);

  useEffect(() => {
    if (order) {
      if (order.balance > 0 && order?.financial_status !== "unpaid") {
        setPaymentState(prevState => ({ ...prevState, paymentAmount: order.balance.toFixed(2) }));
      }
    }
  }, [order]);

  useEffect(() => {
    if (cart) {
      setPaymentState(prevState => ({
        ...prevState,
        paymentAmount:
          order?.balance && order?.financial_status !== "unpaid"
            ? order?.balance?.toFixed(2)
            : cart?.total_price.toFixed(2),
        gift_cards: null,
        selectedLeagues: null,
      }));
    } else {
      setPaymentState(prevState => ({ ...prevState, paymentAmount: "0.00" }));
    }
  }, [cartStore?.cart]);

  useEffect(() => {
    if (paymentState.selectedAccount || modalCreditBook.selectedCreditBook) {
      void handleProcessPayment();
    }
    return () => {
      setPaymentState(prevState => ({ ...prevState, selectedAccount: undefined }));
      updateCreditBook({ selectedCreditBook: null });
    };
  }, [paymentState.selectedAccount, modalCreditBook.selectedCreditBook]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = global.setTimeout(() => {
        void searchForRoomOccupants(mounted, paymentState?.roomOccupantSearchQuery, source.token);
      }, 2000);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      setPaymentState(prevState => ({ ...prevState, roomOccupantsSearchResults: [] }));
      source.cancel("Cancelled");
    };
  }, [paymentState.roomOccupantSearchQuery]);

  //Update reader on cart change
  useEffect(() => {
    if (cartStore?.cart?.line_items && terminalStore?.reader) {
      if (cartStore?.cart?.total_price > 0) {
        void setReaderDisplay(convertOrderIntoDisplayInfo(cartStore?.cart, order));
      }
    }
  }, [cartStore?.cart, terminalStore?.reader]);

  async function loadFacilityUsers() {
    const usersRes = await GetFacilityAdmin(null, false);
    if (usersRes.status !== StatusCode.OK) {
      dispatch(showError("Error getting admin users"));
      return;
    }

    void updateTransferTable({ users: usersRes?.data });
  }

  async function loadRoomOccupants(roomOccupantSearchQuery: string, cancelToken: CancelToken) {
    const roomOccupantsRes = await GetRoomOccupants({ search: roomOccupantSearchQuery }, true, cancelToken);

    if (roomOccupantsRes.status !== StatusCode.OK && roomOccupantsRes.message !== "Cancelled") {
      dispatch(showError(roomOccupantsRes.message));
      return;
    }

    return roomOccupantsRes;
  }

  async function searchForRoomOccupants(mounted: boolean, roomOccupantSearchQuery: string, cancelToken: CancelToken) {
    try {
      if (roomOccupantSearchQuery === "") {
        if (mounted) {
          setPaymentState(prevState => ({ ...prevState, roomOccupantsSearchResults: [] }));
        }
      } else {
        const res = await loadRoomOccupants(roomOccupantSearchQuery, cancelToken);
        if (mounted && res?.status === StatusCode.OK && res?.data) {
          const occupants = res.data.map(occupant => {
            return {
              reservation_number: occupant.reservation_number ?? "",
              room_number: occupant.room_number ?? "",
              res_status: occupant.res_status,
              guest_name: occupant.guest_name ?? "",
              credit_limit: occupant.credit_limit,
            };
          });
          setPaymentState(prevState => ({ ...prevState, roomOccupantsSearchResults: occupants }));
        }
      }
    } catch (error) {
      setPaymentState(prevState => ({ ...prevState }));
    }
    setPaymentState(prevState => ({
      ...prevState,
      selectedOccupant: null,
    }));
  }

  const handleStripeError = (message: string, sheetInfo: sheetInfo) => {
    //set sheet
    setStripePaymentState(prevState => ({
      ...prevState,
      onCancel: () => {
        sheetInfo.onCancel();
        setStripePaymentState(prevState => ({ ...prevState, sheetVisible: false }));
      },
      onOk: () => {
        sheetInfo.onOk();
        setStripePaymentState(prevState => ({
          ...prevState,
          sheetVisible: true,
          onCancel: undefined,
          okText: "Cancel Payment",
          processing: true,
          onOk: async () => {
            setStripePaymentState(prevState => ({
              ...prevState,
              sheetVisible: false,
              onCancel: undefined,
              okText: undefined,
              processing: false,
              onOk: () => {},
              titleText: "",
              success: false,
              message: "",
            }));
            await clearReaderDisplay();
          },
          titleText: "Processing Payment",
          message: "Please wait while the customer finishes the transaction…",
        }));
      },
      cancelText: sheetInfo.cancelText,
      okText: sheetInfo.okText,
      sheetVisible: true,
      titleText: sheetInfo.title,
      message: sheetInfo.message,
      convertedCode: sheetInfo.convertedErrorCode,
      success: false,
      processing: false,
    }));
  };

  const handleStripeSuccess = (message?: string, order_id?: number) => {
    setStripePaymentState(prevState => ({
      ...prevState,
      sheetVisible: true,
      onCancel: undefined,
      okText: "Cancel Payment",
      //resets state
      onOk: () => {
        setStripePaymentState(prevState => ({
          ...prevState,
          sheetVisible: false,
          onCancel: undefined,
          okText: undefined,
          processing: false,
          onOk: () => {},
          titleText: "",
          message: "",
        }));
      },
      processing: false,
      success: true,
      titleText: "Payment Processed",
      message: "Payment Successful",
    }));
    void updateOrder(order_id, true);
    dispatch(showSuccess("Payment successful"));
  };

  function handleSelectSeat(cart: ICart) {
    if (cart) {
      ReactDOM.unstable_batchedUpdates(() => {
        setTableState(prevState => ({
          ...prevState,
          order:
            cart?.order?.financial_status === "partially_paid" || cart?.order?.financial_status === "unpaid"
              ? cart?.order
              : undefined,
        }));
        void dispatch(loadTableCart({ tableCart: cart }, false));
      });
    }
  }

  async function handleAddSeat() {
    if (activeTable) {
      //Display loading seat
      const tempSeatId = [...tempSeats]?.length + 1;
      setTempSeats(prevState => [...prevState, { id: tempSeatId }]);

      //Create new cart (seat)
      const postRes = await PostCart({ type: "tab", table_id: activeTable?.id }, false);
      if (postRes.status !== StatusCode.OK) {
        void dispatch(showError("Error occurred when creating a new seat"));
        return;
      }

      ReactDOM.unstable_batchedUpdates(() => {
        //Add new seat to table
        const updatedTable = { ...activeTable };
        if (updatedTable?.carts?.length === 0 && tempSeats?.length <= 1) {
          // Auto select seat if there were no seats on the table previously
          void dispatch(loadTableCart({ tableCart: postRes?.data }, false));
        }

        //Remove loading seat
        setTempSeats(prevState => prevState?.filter(tempSeat => tempSeat?.id !== tempSeatId));
        //Update table
        updatedTable.carts?.push(postRes?.data);
        void dispatch(setActiveTable(updatedTable));
      });
    }
  }

  async function printReceipt(id: number, type: "cart" | "order") {
    const receiptRes = await GetReceipt({ id, type: type }, true);

    if (receiptRes.status === StatusCode.OK) {
      window.open().document.write(receiptRes.data);
    } else {
      dispatch(showError("Error printing receipt"));
    }
  }

  async function handleUpdateLineItem(updatedLineItem: TUpdatedLineItem) {
    const lineItemId = lineItemState?.selectedLineItem?.id;
    const lineItemParentId = lineItemState?.selectedLineItem?.parent_id;
    const cartId = lineItemState?.selectedLineItem?.cart_id;

    const lineItemPickedKeys = (({ quantity, price, kitchen_meal_id, note }) => ({
      quantity,
      price,
      kitchen_meal_id,
      note,
    }))(lineItemState?.selectedLineItem);
    let lineItemRes: IAPIResponse;
    const params = {
      quantity: Number(updatedLineItem.quantity),
      price: Number(updatedLineItem.price),
      kitchen_meal_id: updatedLineItem.courseId,
      note: updatedLineItem.note,
    };

    setLineItemState(prevState => ({ ...prevState, showEditLineItemModal: false, selectedLineItem: null }));
    // Update line item if a change was made
    if (!isEqual(lineItemPickedKeys, params)) {
      void addLineItemUpdating(lineItemParentId ?? lineItemId);
      lineItemRes = await PutLineItemToCart({ id: lineItemState?.selectedLineItem?.id, ...params }, false);
      void removeLineItemUpdating(lineItemParentId ?? lineItemId);
      if (lineItemRes?.status !== StatusCode.OK) {
        dispatch(showError("Error updating line item"));
        return;
      }
    }

    // Apply discount is discount was selected
    if (updatedLineItem?.discount) {
      void addLineItemUpdating(lineItemParentId ?? lineItemId);
      await handleApplyDiscounts(updatedLineItem, cartId, lineItemId);
      void removeLineItemUpdating(lineItemParentId ?? lineItemId);
    } else {
      //Line item updated and no discount applied, update cart in state
      if (lineItemRes) {
        ReactDOM.unstable_batchedUpdates(() => {
          void dispatch(loadTableCart({ tableCart: lineItemRes?.data }, false));
          void updateActiveTable(lineItemRes?.data);
        });
      } else {
        //Line item not updated and no discount applied, do nothing
        return;
      }
    }

    dispatch(showSuccess("Line item updated successfully"));
  }

  async function handleApplyDiscounts(updatedLineItem: TUpdatedLineItem, cartId: number, lineItemId: number) {
    //Get modifier ids
    const modifierLineItemIds = getModifierLineItemIds(lineItemId);

    const discountRes = await PostDiscountLine(
      {
        discount_id: updatedLineItem?.discount?.id,
        cart_id: cartId,
        cart_line_item_id: lineItemId,
        value: updatedLineItem?.discount?.custom ? Number(updatedLineItem?.customDiscountAmount) : undefined,
        value_type: updatedLineItem?.discount?.custom ? updatedLineItem?.customDiscountType : undefined,
        modifier_line_item_ids:
          modifierLineItemIds?.length > 0 &&
          ((updatedLineItem?.discount?.custom && updatedLineItem?.customDiscountType == "percent") ||
            (!updatedLineItem?.discount?.custom && updatedLineItem?.discount?.value_type === "percent"))
            ? modifierLineItemIds
            : undefined,
      },
      false,
    );

    if (discountRes.status !== StatusCode.OK) {
      dispatch(showError("Error applying discount"));
      return;
    }

    ReactDOM.unstable_batchedUpdates(() => {
      void dispatch(loadTableCart({ tableCart: discountRes?.data }, false));
      void updateActiveTable(discountRes?.data);
    });
  }

  function getModifierLineItemIds(lineItemParentId: number) {
    const cartLineItems = [...cart?.line_items];
    return cartLineItems
      ?.filter(lineItem => lineItem?.parent_id === lineItemParentId)
      ?.map(lineItem => lineItem?.id);
  }

  async function handleRemoveLineItem() {
    const lineItemId = lineItemState?.selectedLineItem?.id;
    const lineItemParentId = lineItemState?.selectedLineItem?.parent_id;
    void addLineItemUpdating(lineItemParentId ?? lineItemId, "delete");
    setLineItemState(prevState => ({ ...prevState, showEditLineItemModal: false, selectedLineItem: null }));
    const lineItemRes = await DeleteLineItemToCart({ id: lineItemId }, false);
    void removeLineItemUpdating(lineItemParentId ?? lineItemId);
    if (lineItemRes?.status !== StatusCode.OK) {
      dispatch(showError("Error removing line item"));
      return;
    }

    ReactDOM.unstable_batchedUpdates(() => {
      void dispatch(loadTableCart({ tableCart: lineItemRes?.data }, false));
      void updateActiveTable(lineItemRes?.data);
    });

    dispatch(showSuccess("Successfully removed line item"));
  }

  function updateActiveTable(updatedCart: ICart) {
    if (activeTable) {
      const updatedActiveTable = { ...activeTable };
      const updatedTable = {
        ...updatedActiveTable,
        carts: activeTable?.carts?.map(tableCart => (tableCart?.id === updatedCart?.id ? updatedCart : tableCart)),
      };
      dispatch(setActiveTable(updatedTable));
    }
  }

  async function removeDiscountLine() {
    const selectedDiscount = { ...discountState?.selectedDiscount };
    //If modifier discount is being removed, show loader for the entire parent item + modifiers
    const selectedParentLineItemId = discountState?.parentLineItemId;

    void addLineItemUpdating(selectedParentLineItemId ?? selectedDiscount?.cart_line_item_id);
    setDiscountState(prevState => ({
      ...prevState,
      showModal: false,
      selectedDiscount: null,
    }));
    const res = await DeleteDiscountLine({ id: selectedDiscount?.id, cart_id: selectedDiscount?.cart_id }, false);
    void removeLineItemUpdating(selectedParentLineItemId ?? selectedDiscount?.cart_line_item_id);

    if (res?.status !== StatusCode.OK) {
      dispatch(showError("Error removing discount line"));
      return;
    }
    ReactDOM.unstable_batchedUpdates(() => {
      void dispatch(loadTableCart({ tableCart: res?.data }, false));
      void updateActiveTable(res?.data);
    });

    dispatch(showSuccess("Discount successfully removed"));
  }

  async function handleAddTip(tipAmount: number) {
    const res = await PutCart({ id: cart?.id, total_tip: tipAmount }, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error adding tip"));
      return;
    }

    ReactDOM.unstable_batchedUpdates(() => {
      void dispatch(loadTableCart({ tableCart: res?.data }, false));
      void updateActiveTable(res?.data);
    });
  }

  function handlePrePaymentChecks() {
    if (cart) {
      if (cart?.status !== "complete" && cart?.status !== "void") {
        if (cart?.line_items?.length > 0) {
          const gift_cards: Array<TGiftCardParam> = [];
          const filteredGiftCards: Array<ICartLineItem> = cartStore?.cart.line_items?.filter(
            line_item => line_item?.product?.type === "Gift Card",
          );

          const filteredLeagueFees = cartStore?.cart.line_items?.filter(
            line_item => line_item?.product?.type === "League Registration Fee",
          );

          if (filteredLeagueFees?.length > 0 && !cartStore?.cart?.customer_id) {
            dispatch(showError("Add customer to the seat to purchase league registration fee"));
            return;
          }

          //Open gift cards modal if cart contains gift cards
          if (filteredGiftCards?.length > 0 || filteredLeagueFees?.length > 0) {
            if (filteredGiftCards.length > 0) {
              filteredGiftCards.forEach(line_item => {
                for (let i = 0; i < line_item?.quantity; i++) {
                  gift_cards.push({
                    product_id: line_item.product_id,
                    cart_line_item_id: line_item.id,
                    code: null,
                    pin: null,
                    random: false,
                    balance: line_item.price,
                    reload: 0,
                  });
                }
              });
              setPaymentState(prevState => ({ ...prevState, gift_cards, sellGiftCardsActive: true }));
            }
            //Check for league registration fees
            if (filteredLeagueFees?.length > 0) {
              setPaymentState(prevState => ({
                ...prevState,
                leagueFeesActive: true,
                leagueFeeProducts: filteredLeagueFees,
              }));
            }
          } else {
            //Go directly to payment modal if no gift cards / league fees in cart
            setPaymentState(prevState => ({
              ...prevState,
              gift_cards: null,
              paymentModalVisible: true,
              leagueFeeProducts: null,
              selectedLeagues: null,
            }));
          }
        } else {
          dispatch(showError("Seat does not contain any items", 2000));
        }
      } else {
        dispatch(showError("Order has already been completed", 2000));
      }
    } else {
      dispatch(showError("Cart error"));
    }
  }

  function handleProcessPaymentCheck() {
    if (paymentState?.paymentOption?.payment_method === "gift_card") {
      setPaymentState(prevState => ({ ...prevState, giftCardModalVisible: true }));
    } else if (paymentState?.paymentOption?.payment_method === "account" && cartStore?.cart?.customer?.id) {
      setPaymentState(prevState => ({ ...prevState, accountsVisible: true }));
    } else if (paymentState?.paymentOption?.payment_method === "credit_book") {
      updateCreditBook({ isOpen: true });
    } else if (
      paymentState?.paymentOption?.payment_method === "room_charge_rdp" ||
      paymentState?.paymentOption?.payment_method === "maestro"
    ) {
      setPaymentState(prevState => ({
        ...prevState,
        roomChargeSheetVisible: true,
        roomOccupantSearchQuery: "",
        roomOccupantsSearchResults: [],
        selectedOccupant: null,
      }));
    } else {
      void handleProcessPayment();
    }
  }

  const handleProcessPayment = async (giftCardInfo?: IGiftCardRedeemState) => {
    dispatch(enqueue());
    setPaymentState(prevState => ({ ...prevState, paymentInProgress: true }));
    const cartTotal = Number(cartStore?.cart?.total_price?.toFixed(2));
    try {
      const cart_id = cartStore?.cart?.id;

      // Checking out produces a new order

      let checkoutRes: { status: any; data: any };
      const register = JSON.parse(localStorage.getItem("register"));
      const registerId = register?.id ? register?.id : undefined;

      let paymentOrder: IOrder;
      if (!order || order?.financial_status === "unpaid" || order?.financial_status === "cancelled") {
        //Void the order if it is unpaid. This situation could happen if the user tries to make a reader payment then decides to cancel the transaction.
        if (order?.financial_status === "unpaid") {
          const voidRes = await PutVoidOrder(
            { order_id: order?.id, notes: "Transaction cancelled", financial_status: "cancelled" },
            false,
          );
          if (voidRes?.status !== StatusCode.OK) {
            dispatch(showError("Error voiding previous order"));
          }
        }
        // Create a new order if order does not exist in state
        checkoutRes = await PostCheckout({ cart_id, register_id: registerId }, false);
        if (checkoutRes?.status !== StatusCode.OK) {
          dispatch(showError("Error checking out"));
          return;
        }
        paymentOrder = checkoutRes?.data?.order;
        setTableState(prevState => ({ ...prevState, order: checkoutRes?.data?.order }));
      } else {
        //Continue order
        paymentOrder = order;
      }

      const finalAmount = Number(paymentState.paymentAmount);

      //Check to see if amount is acceptable
      if (Number.isNaN(finalAmount)) {
        dispatch(dequeue());
        dispatch(showError(`'${paymentState.paymentAmount}' is not a valid amount`));
        setPaymentState(prevState => ({ ...prevState, paymentInProgress: false }));
        return;
      }

      if (
        (finalAmount > cartTotal && paymentState.paymentOption?.payment_method !== "cash") ||
        (finalAmount > order?.balance && paymentState.paymentOption?.payment_method !== "cash") ||
        finalAmount < 0
      ) {
        dispatch(dequeue());
        dispatch(showError(`'${paymentState.paymentAmount}'is outside the value that can be paid`));
        setPaymentState(prevState => ({ ...prevState, paymentInProgress: false }));
        return;
      }

      if ((order || paymentOrder) && !paymentState.paymentInProgress) {
        const order_id = paymentOrder ? paymentOrder?.id : order?.id;

        if (paymentState.paymentOption?.payment_method === "manual_card") {
          const transactionRes = await PostTransaction(
            {
              kind: "sale",
              amount: finalAmount,
              order_id: order_id,
              payment_option_id: paymentState.paymentOption?.id,
            },
            false,
          );

          if (transactionRes.status === StatusCode.OK) {
            setManualStripeState(prevState => ({
              ...prevState,
              transaction: transactionRes.data,
              clientSecret: transactionRes.data.client_secret,
              visible: true,
            }));
          } else {
            dispatch(showError("Error generating transaction"));
            setPaymentState(prevState => ({ ...prevState, paymentInProgress: false }));
            dispatch(dequeue());
            return;
          }
          // Using terminal - display modal
        } else if (paymentState.paymentOption?.payment_method === "card") {
          if (facilityStore.facility.terminal_integration === "server") {
            setPaymentState(prevState => ({ ...prevState, paymentInProgress: true }));
            // If cart already has a tip, skip tipping
            await processPayment(
              order_id,
              paymentState.paymentOption,
              finalAmount,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              Number(cart?.total_tip) > 0 ? true : false,
            );
            setPaymentState(prevState => ({ ...prevState, paymentInProgress: false }));
          } else {
            dispatch(dequeue());
            setStripePaymentState(prevState => ({
              ...prevState,
              sheetVisible: true,
              onCancel: undefined,
              okText: "Cancel Payment",
              processing: true,
              onOk: async () => {
                setStripePaymentState(prevState => ({
                  ...prevState,
                  sheetVisible: false,
                  onCancel: undefined,
                  okText: undefined,
                  processing: false,
                  onOk: () => {},
                  titleText: "",
                  success: false,
                  message: "",
                }));
                await clearReaderDisplay();
              },
              titleText: "Processing Payment",
              message: "Please wait while the customer finishes the transaction…",
            }));

            await stripeProcessPayment(
              order_id,
              finalAmount,
              paymentState.paymentOption,
              handleStripeSuccess,
              handleStripeError,
            );
            dispatch(enqueue());
          }
        } else if (paymentState.paymentOption?.payment_method === "gift_card") {
          const transactionRes = await PostTransaction(
            {
              kind: "sale",
              amount: finalAmount,
              order_id: order_id,
              payment_option_id: paymentState.paymentOption?.id,
              gift_card_code: giftCardInfo.giftCardCode,
              gift_card_pin: giftCardInfo.giftCardPin,
            },
            false,
          );

          if (transactionRes.status === StatusCode.OK) {
            setPaymentState(prevState => ({
              ...prevState,
              giftCardCode: "",
              giftCardPin: "",
              giftCardModalVisible: false,
            }));

            // display success notification
            dispatch(showSuccess("Transaction generated successfully"));

            await updateOrder(transactionRes.data.order_id, true);
          } else {
            dispatch(showError("Error generating transaction"));
            setPaymentState(prevState => ({ ...prevState, paymentInProgress: false }));
            dispatch(dequeue());
            return;
          }
        } else if (paymentState.paymentOption?.payment_method === "account") {
          const transactionRes = await PostTransaction(
            {
              kind: "sale",
              amount: finalAmount,
              order_id: order_id,
              payment_option_id: paymentState.paymentOption.id,
              account_id: paymentState.selectedAccount.id,
            },
            false,
          );

          if (transactionRes?.status !== StatusCode.OK) {
            dispatch(showError("Error generating transaction"));
            setPaymentState(prevState => ({ ...prevState, paymentInProgress: false }));
            dispatch(dequeue());
            return;
          }

          // display success notification
          dispatch(showSuccess("Transaction generated successfully"));

          // Update order and check if there is a remaining balance
          await updateOrder(transactionRes.data.order_id, true);
        } else if (paymentState.paymentOption?.payment_method === "credit_book") {
          const transactionRes = await PostTransaction(
            {
              kind: "sale",
              amount: finalAmount,
              order_id: order_id,
              payment_option_id: paymentState.paymentOption.id,
              credit_book_id: modalCreditBook.selectedCreditBook.id,
            },
            false,
          );

          if (transactionRes?.status !== StatusCode.OK) {
            setPaymentState(prevState => ({ ...prevState, paymentInProgress: false }));
            closeCreditBook();
            dispatch(
              showError(
                typeof transactionRes?.data === "string" ? transactionRes.data : "Error generating transaction",
              ),
            );
            dispatch(dequeue());
            return;
          }

          // display success notification
          dispatch(showSuccess("Transaction generated successfully"));

          // Update order and check if there is a remaining balance
          await updateOrder(transactionRes.data.order_id, true);
        } else if (
          paymentState.paymentOption?.payment_method === "room_charge_rdp" ||
          paymentState.paymentOption?.payment_method === "maestro"
        ) {
          const transactionRes = await PostTransaction(
            {
              kind: "sale",
              amount: finalAmount,
              order_id: order_id,
              payment_option_id: paymentState.paymentOption?.id,
              guest_name: paymentState?.selectedOccupant?.guest_name,
              room_number: paymentState?.selectedOccupant?.room_number,
            },
            false,
          );

          if (transactionRes.status !== StatusCode.OK) {
            dispatch(showError("Error generating transaction"));
            dispatch(dequeue());
            return;
          } else {
            dispatch(showSuccess("Transaction generated successfully"));

            // Update order and check if there is a remaining balance
            await updateOrder(transactionRes.data.order_id, true);

            setPaymentState(prevState => ({
              ...prevState,
              roomChargeSheetVisible: false,
              roomOccupantSearchQuery: "",
              roomOccupantsSearchResults: [],
              selectedOccupant: null,
            }));
          }
        } else {
          let changeDue: number;
          //Display change due for cash payments
          if (paymentState.paymentOption?.payment_method === "cash") {
            if (paymentOrder?.balance && Number(paymentState.paymentAmount) > paymentOrder?.balance) {
              changeDue = Number(paymentState.paymentAmount) - paymentOrder?.balance;
              setPaymentState(prevState => ({
                ...prevState,
                changeDue: changeDue,
                changeDueVisible: true,
              }));
            } else if (Number(paymentState.paymentAmount) > cartTotal) {
              changeDue = Number(paymentState.paymentAmount) - cartTotal;
              setPaymentState(prevState => ({
                ...prevState,
                changeDue: changeDue,
                changeDueVisible: true,
              }));
            }
          }
          const transactionRes = await PostTransaction(
            {
              kind: "sale",
              amount: changeDue ? finalAmount - changeDue : finalAmount,
              order_id: order_id,
              payment_option_id: paymentState.paymentOption?.id,
            },
            false,
          );

          if (transactionRes.status !== StatusCode.OK) {
            dispatch(showError("Error generating transaction"));
            setPaymentState(prevState => ({ ...prevState, paymentInProgress: false }));
            dispatch(dequeue());
            return;
          }

          // display success notification
          dispatch(showSuccess("Transaction generated successfully"));

          // Update order and check if there is a remaining balance
          await updateOrder(transactionRes.data.order_id, true);
        }
      }
    } catch (e) {
      console.log("err:", e);
    }
    setPaymentState(prevState => ({ ...prevState, paymentInProgress: false }));
    dispatch(dequeue());
  };

  async function updateOrder(order_id: number, clearCart: boolean) {
    if (order_id) {
      //Update order
      const orderRes = await GetOrder({ id: order_id, extended: true }, true);
      if (orderRes.status !== StatusCode.OK) {
        dispatch(showError("Error getting order"));
        return;
      }
      setManualProcessing(false);

      const updatedOrder = orderRes.data[0];
      if (updatedOrder.balance <= 0 || updatedOrder.financial_status === "paid") {
        if (clearCart) {
          await completeOrder(order_id);

          if (terminalStore?.reader) {
            void clearReaderDisplay();
          }

          ReactDOM.unstable_batchedUpdates(() => {
            setPaymentState(prevState => ({
              ...prevState,
              paymentModalVisible: false,
              giftCardModalVisible: false,
              selectedAccount: undefined,
              paymentOption: null,
            }));
          });

          //Order completed, display order complete modal
          void updateOrderComplete({ isOpen: true });
        }
      } else {
        if (activeTable) {
          void reloadTable();
        }
        // Still need to process another payment for this order
        ReactDOM.unstable_batchedUpdates(() => {
          setTableState(prevState => ({ ...prevState, order: updatedOrder }));
          setPaymentState(prevState => ({
            ...prevState,
            paymentAmount: updatedOrder?.balance.toFixed(2),
            giftCardModalVisible: false,
          }));
        });
      }
    }
  }

  async function reloadTable() {
    const tablesRes = await GetTable({ id: activeTable?.id, carts: true, orders: true }, false);

    if (tablesRes.status !== StatusCode.OK) {
      dispatch(showError("Error getting table"));
      return;
    }

    const currentCart = tablesRes?.data[0]?.carts?.find(searchedCart => searchedCart?.token === cart?.token);
    if (currentCart) {
      void dispatch(loadTableCart({ tableCart: currentCart }, false));
    }
    void dispatch(setActiveTable(tablesRes?.data[0]));
  }

  async function completeOrder(order_id: number) {
    const register = JSON.parse(localStorage.getItem("register"));
    const registerId = register?.id;
    dispatch(enqueue());
    const completeRes = await PutOrderComplete(
      {
        order_id,
        gift_cards: paymentState.gift_cards,
        register_id: registerId,
        league_ids: paymentState.selectedLeagues,
      },
      false,
    );

    if (completeRes.status !== StatusCode.OK) {
      dispatch(showError("Error completing order"));
      return;
    }
    if (activeTable) {
      await reloadTable();
    }

    dispatch(dequeue());
    dispatch(showSuccess("Order completed"));
    setPaymentState(prevState => ({ ...prevState, gift_cards: null, selectedLeagues: null }));
  }

  function closeOrderCompletePopup() {
    ReactDOM.unstable_batchedUpdates(() => {
      setTableState(prevState => ({ ...prevState, order: undefined }));
      void closeOrderComplete();
    });
  }

  function handlePaymentOptionChange(paymentOption: Record<string, any>) {
    if (paymentOption !== undefined) {
      setPaymentState(prevState => ({
        ...prevState,
        paymentOption: paymentOption,
      }));
    }
  }

  function closePaymentSheet() {
    setPaymentState(prevState => ({
      ...prevState,
      paymentModalVisible: false,
      paymentAmount: order?.balance ? order?.balance?.toFixed(2) : cart?.total_price.toFixed(2),
      paymentOption: null,
    }));
  }

  function cancelGiftCardsAndLeagues() {
    setPaymentState(prevState => ({
      ...prevState,
      sellGiftCardsActive: false,
      gift_cards: null,
      leagueFeesActive: false,
      leagueFeeProducts: null,
      selectedLeagues: null,
    }));
  }

  async function validateGiftCardCode(code: string, reload: number) {
    const validateRes = await GetGiftCardValidate({ code, reload }, true);
    if (validateRes.status !== StatusCode.OK) {
      dispatch(showError(reload ? "Gift card does not exist to reload" : "Gift card already exists with this code"));
      return false;
    }
    return true;
  }

  async function handleAddSellGiftCards(gift_cards: IGiftCardSellState) {
    let validateRes = true;
    //Validate gift card codes
    for (const gift_card of gift_cards.gift_cards) {
      if (!gift_card.random) {
        validateRes = await validateGiftCardCode(gift_card.code, gift_card.reload);
      }
      if (validateRes === false) {
        return;
      }
    }

    if (validateRes) {
      const moveToPaymentMethods = checkToContinuePayment("gift_card");
      setPaymentState(prevState => ({
        ...prevState,
        sellGiftCardsActive: false,
        paymentModalVisible: moveToPaymentMethods,
      }));
      dispatch(showSuccess("Gift cards added successfully"));
    }
  }

  function setSelectedLeagues(leagues: Array<number>) {
    const moveToPaymentMethods = checkToContinuePayment("leagues");
    setPaymentState(prevState => ({
      ...prevState,
      selectedLeagues: leagues,
      leagueFeesActive: false,
      leagueFeeProducts: null,
      paymentModalVisible: moveToPaymentMethods,
    }));
  }

  function checkToContinuePayment(type: "gift_card" | "leagues") {
    switch (type) {
      case "gift_card":
        if (!paymentState.selectedLeagues && paymentState.leagueFeesActive) {
          return false;
        }
        return true;
      case "leagues":
        if (paymentState.sellGiftCardsActive) {
          return false;
        }
        return true;
      default:
        return true;
    }
  }

  function handleCardSectionChange(
    e: StripeCardNumberElementChangeEvent | StripeCardExpiryElementChangeEvent | StripeCardCvcElementChangeEvent,
  ) {
    setManualStripeState(prevState => ({
      ...prevState,
      elementComplete: { ...prevState.elementComplete, [e.elementType]: e.complete },
    }));
  }

  function handleManualProcessing() {
    if (manualProcessing === true) {
      setManualProcessing(false);
    } else {
      setManualProcessing(true);
    }
  }

  function handleOpenCloseTableModal() {
    const seats = [...activeTable?.carts];
    updateCloseTable({
      isOpen: true,
      seatsOpen: seats?.filter(cart => cart?.status === "open")?.every(cart => cart?.line_items?.length === 0),
      partiallyPaidSeats: seats?.some(cart => cart?.order?.financial_status === "partially_paid"),
      voidSeats: seats?.some(cart => cart?.line_items?.length > 0 && cart?.status === "open"),
    });
  }

  async function handleCloseTable() {
    //Cannot close a table that contains partially paid seats
    if (closeTable?.partiallyPaidSeats) {
      dispatch(showError("Cannot close a table with partially paid seats"));
      return;
    }
    //Void open seats that contain line items
    if (closeTable?.voidSeats && permissions?.orders_void) {
      const seatsToVoid = [...cartStore?.activeTable?.carts]
        ?.filter(cart => cart?.status === "open" && cart?.line_items?.length > 0)
        ?.map(cart => cart?.id);
      if (seatsToVoid?.length > 0) {
        void updateCloseTable({ loadingMessage: "Voiding open seats..." });
        const voidRes = await PutVoidCarts({ cart_ids: seatsToVoid, cancel_reason: closeTable?.cancel_reason }, false);
        if (voidRes?.status !== StatusCode.OK) {
          dispatch(showError("Error voiding seats"));
          void updateCloseTable({ loadingMessage: "" });
          return;
        }
      }
    }
    //Close open seats with no line items
    const openSeats = [...cartStore?.activeTable?.carts]
      ?.filter(cart => cart?.status === "open" && cart?.line_items?.length === 0)
      ?.map(cart => cart?.id);
    if (openSeats?.length > 0) {
      void updateCloseTable({ loadingMessage: "Closing open seats..." });
      const responses = await Promise.all(openSeats?.map(cartId => PutCart({ id: cartId, status: "closed" }, false)));
      if (responses?.some(res => res?.status !== StatusCode.OK)) {
        dispatch(showError("Error closing a seat"));
        void updateCloseTable({ loadingMessage: "" });
        return;
      }
    }

    void updateCloseTable({ loadingMessage: "Closing table..." });
    //Close table
    const closeTableRes = await PutCloseTable({ table_id: activeTable?.id }, false);
    if (closeTableRes.status !== StatusCode.OK) {
      dispatch(showError("Error closing table"));
      void updateCloseTable({ loadingMessage: "" });
      return;
    }
    void cancelCloseTable();

    //Check register to auto log out
    const register = JSON.parse(localStorage.getItem("register"));
    if (register && register?.auto_log_out === true) {
      localStorage.setItem("switch_user_after_transaction", "true");
    }

    dispatch(showSuccess("Successfully closed table"));
    void navigateToTableSelection();
  }

  async function handleSendChit(lineItemIds: number[]) {
    setTableState(prevState => ({ ...prevState, cartLoadingMessage: "Sending kitchen chits..." }));
    void cancelSendChits();

    const res = await PostKitchenChit({ table_id: activeTable?.id, line_item_ids: lineItemIds }, false);
    if (res.status !== StatusCode.OK) {
      setTableState(prevState => ({ ...prevState, cartLoadingMessage: "" }));
      dispatch(showError("Error creating kitchen chits"));
      return;
    }
    void printChits(res?.data);

    dispatch(showSuccess("Kitchen chits created successfully"));
    // refresh table and cart
    await reloadTable();

    ReactDOM.unstable_batchedUpdates(() => {
      setTableState(prevState => ({ ...prevState, cartLoadingMessage: "" }));
      setForceRefreshChits(true);
    });
  }

  async function handlePrintReceipt(printChitInfo: { addedLineItems: Array<number> }) {
    const receiptRes = await GetTableReceipt(
      { table_id: activeTable?.id, line_item_ids: printChitInfo?.addedLineItems },
      true,
    );
    if (receiptRes.status !== StatusCode.OK) {
      dispatch(showError("Error printing kitchen chits"));
      return;
    }
    window.open().document.write(receiptRes.data);
    void cancelPrintChits();
  }

  async function sendFireChit() {
    const fireChitRes = await FireKitchenChit({ table_id: activeTable?.id }, true);

    if (fireChitRes.status !== StatusCode.OK) {
      dispatch(showError("Error sending fire chit"));
      return;
    }

    printChits(fireChitRes.data);

    dispatch(showSuccess("Fire chit sent successfully"));
  }

  async function handleSeatCartVoid() {
    if (cartStore?.cart) {
      ReactDOM.unstable_batchedUpdates(() => {
        void closeVoidSeat();
        setTableState(prevState => ({ ...prevState, cartLoadingMessage: "Voiding seat..." }));
      });
      const voidRes = await PutVoidCarts({ cart_ids: [cart?.id], cancel_reason: voidSeat.cancel_reason }, false);
      if (voidRes.status !== StatusCode.OK) {
        dispatch(showError("Error voiding seat"));
        setTableState(prevState => ({ ...prevState, cartLoadingMessage: "" }));
        return;
      }
      let voidedCart = [...voidRes?.data]?.find(voidedCart => voidedCart?.id === cart?.id);
      if (!voidedCart) {
        voidedCart = voidRes?.data[0] ? voidRes?.data[0] : activeTable?.carts[0];
      }
      ReactDOM.unstable_batchedUpdates(() => {
        void dispatch(loadTableCart({ tableCart: voidedCart }, false));
        void updateActiveTable(voidedCart);
        setTableState(prevState => ({ ...prevState, cartLoadingMessage: "" }));
      });

      dispatch(showSuccess("Successfully voided seat"));
    } else {
      dispatch(showError("No seat selected"));
    }
  }

  async function handleSeatCartClose() {
    if (cartStore?.cart) {
      ReactDOM.unstable_batchedUpdates(() => {
        void closeCloseSeat();
        setTableState(prevState => ({ ...prevState, cartLoadingMessage: "Closing seat..." }));
      });
      const closeRes = await PutCart({ id: cartStore?.cart?.id, status: "closed" }, false);
      if (closeRes?.status !== StatusCode.OK) {
        dispatch(showError("Error closing seat"));
        setTableState(prevState => ({ ...prevState, cartLoadingMessage: "" }));
        return;
      }
      setTableState(prevState => ({ ...prevState, cartLoadingMessage: "" }));
      void handleRemoveSeat();
      dispatch(showSuccess("Successfully closed seat"));
    } else {
      dispatch(showError("No seat selected"));
    }
  }

  function handleRemoveSeat() {
    const currentCartId = cartStore.cart?.id;
    const tableCarts = [...cartStore?.activeTable?.carts]?.filter(cart => cart?.id !== currentCartId);
    const table = { ...cartStore.activeTable, carts: tableCarts };
    // If there are still seats available, set the first seat of the table in state
    if (tableCarts?.length > 0) {
      const firstCart = tableCarts[0];
      ReactDOM.unstable_batchedUpdates(() => {
        void dispatch(loadTableCart({ tableCart: firstCart }, false));
        if (firstCart?.order) {
          setTableState(prevState => ({ ...prevState, order: firstCart?.order, cartLoadingMessage: "" }));
        }
      });
    } else {
      // No carts left, set active cart to null
      ReactDOM.unstable_batchedUpdates(() => {
        setTableState(prevState => ({ ...prevState, order: undefined, cartLoadingMessage: "" }));
        void dispatch(loadTableCart({ tableCart: null }, false));
      });
    }
    void dispatch(setActiveTable(table));
  }

  async function handleClearCart() {
    ReactDOM.unstable_batchedUpdates(() => {
      void closeClearSeat();
      setTableState(prevState => ({ ...prevState, cartLoadingMessage: "Clearing Seat..." }));
    });
    const clearRes = await PutClearCart(
      {
        cart_id: cart?.id,
      },
      false,
    );

    if (terminalStore?.reader) {
      void clearReaderDisplay();
    }

    if (clearRes.status !== StatusCode.OK) {
      dispatch(showError("Error clearing seat"));
      setTableState(prevState => ({ ...prevState, cartLoadingMessage: "" }));
      return;
    }

    ReactDOM.unstable_batchedUpdates(() => {
      void dispatch(loadTableCart({ tableCart: clearRes?.data }, false));
      void updateActiveTable(clearRes?.data);
      setTableState(prevState => ({ ...prevState, cartLoadingMessage: "" }));
    });

    dispatch(showSuccess("Successfully cleared seat"));
  }

  async function handleSeatMerge(mergeSeatInfo: IMergeSeatsState) {
    ReactDOM.unstable_batchedUpdates(() => {
      void closeMergeSeats();
      setTableState(prevState => ({ ...prevState, cartLoadingMessage: "Merging Seats..." }));
    });
    const mergeRes = await PostMerge(
      { cart_ids: mergeSeatInfo.selectedMergeSeatIds, table_id: activeTable?.id },
      false,
    );
    if (mergeRes.status !== StatusCode.OK) {
      dispatch(showError("Error merging seats"));
      setTableState(prevState => ({ ...prevState, cartLoadingMessage: "" }));
      return;
    }
    dispatch(showSuccess("Seats merged successfully"));

    //Refresh table
    await dispatch(loadActiveTableNew(cartStore?.activeTable?.id, false));
    ReactDOM.unstable_batchedUpdates(() => {
      void dispatch(loadTableCart({ tableCart: mergeRes?.data }, false));
      setTableState(prevState => ({ ...prevState, cartLoadingMessage: "" }));
    });
  }

  function handleOpenCartDetailsSheet() {
    if (cart?.status === "open" && cart?.order?.financial_status !== "partially_paid") {
      void updateSeatDetails({ isOpen: true, customer: cart?.customer, description: cart?.description ?? "" });
    }
  }

  async function handleUpdateCartDetails(cartDetails: { cartDescription: string; selectedCustomer: ICustomer }) {
    if (cart) {
      ReactDOM.unstable_batchedUpdates(() => {
        void closeSeatDetails();
        setTableState(prevState => ({ ...prevState, cartLoadingMessage: "Updating Seat Details..." }));
      });
      const cartRes = await PutCart(
        {
          id: cartStore?.cart?.id,
          customer_id:
            cartDetails?.selectedCustomer?.id === seatDetails?.customer?.id
              ? undefined
              : cartDetails?.selectedCustomer === null
              ? null
              : cartDetails?.selectedCustomer?.id,
          description:
            cartDetails?.cartDescription === seatDetails?.description ? undefined : cartDetails?.cartDescription,
        },
        false,
      );

      if (cartRes.status !== StatusCode.OK) {
        dispatch(showError("Error updating seat details"));
        setTableState(prevState => ({ ...prevState, cartLoadingMessage: "" }));
        return;
      }

      ReactDOM.unstable_batchedUpdates(() => {
        void dispatch(loadTableCart({ tableCart: cartRes?.data }, false));
        void updateActiveTable(cartRes?.data);
        setTableState(prevState => ({ ...prevState, cartLoadingMessage: "" }));
      });

      dispatch(showSuccess("Successfully updated seat details"));
    }
  }

  async function handleMoveCarts(moveSeatInfo: IMoveSeatsState) {
    if (moveSeatInfo.selectedTableId && moveSeatInfo.selectedSeatIds?.length > 0) {
      ReactDOM.unstable_batchedUpdates(() => {
        void closeMoveSeats();
        setTableState(prevState => ({ ...prevState, cartLoadingMessage: "Moving Seats..." }));
      });
      const register = JSON.parse(localStorage.getItem("register"));
      const moveSeatsRes = await PutMoveSeats(
        {
          table_id: moveSeatInfo.selectedTableId,
          cart_ids: moveSeatInfo.selectedSeatIds,
          register_id: register ? register?.id : undefined,
        },
        false,
      );
      if (moveSeatsRes.status !== StatusCode.OK) {
        dispatch(showError("Error moving seats"));
        setTableState(prevState => ({ ...prevState, cartLoadingMessage: "" }));
        return;
      }
      const tablesRes = await GetTable({ id: cartStore?.activeTable?.id, carts: true, orders: true }, false);
      if (tablesRes.status !== StatusCode.OK) {
        dispatch(showError("Error getting table"));
        setTableState(prevState => ({ ...prevState, cartLoadingMessage: "" }));
        return;
      }
      // If there are still seats available, load the first seat of the table or keep it the same if the active cart wasn't moved
      if (tablesRes?.data[0]?.carts?.length > 0) {
        const firstCart = tablesRes?.data[0]?.carts[0];
        const updatedActiveCart =
          tablesRes?.data[0]?.carts?.find(foundCart => foundCart.id === cart?.id) ?? firstCart;
        ReactDOM.unstable_batchedUpdates(() => {
          void dispatch(loadTableCart({ tableCart: updatedActiveCart }, false));
          if (updatedActiveCart?.order) {
            setTableState(prevState => ({ ...prevState, order: updatedActiveCart?.order }));
          }
        });
      } else {
        ReactDOM.unstable_batchedUpdates(() => {
          void dispatch(loadTableCart({ tableCart: null }, false));
          setTableState(prevState => ({ ...prevState, order: undefined }));
        });
      }
      dispatch(showSuccess("Seats moved successfully"));
      ReactDOM.unstable_batchedUpdates(() => {
        void dispatch(setActiveTable(tablesRes?.data[0]));
        setTableState(prevState => ({ ...prevState, cartLoadingMessage: "" }));
      });
    } else {
      dispatch(showError("Error moving seats"));
    }
  }

  async function handleTransferTable(transferTableInfo: ITransferTableSheetState) {
    if (transferTableInfo?.selectedUserId !== activeTable?.user_id) {
      const transferRes = await PutTable({ id: activeTable?.id, user_id: transferTableInfo?.selectedUserId }, true);

      if (transferRes?.status !== StatusCode.OK) {
        dispatch(showError("Error transferring table"));
        return;
      }
      dispatch(showSuccess("Successfully transferred user to table"));
      const updatedTable = transferRes?.data as unknown as ITable;
      void dispatch(setActiveTable({ ...updatedTable, carts: activeTable?.carts }));
      void updateTransferTable({ isOpen: false });

      //Send user to table selection if they do not have permission to access other users' tables
      if (authStore?.user?.id !== updatedTable?.user_id && !permissions?.table_service_table_override) {
        void navigateToTableSelection();
      }
    }
  }

  async function moveLineItemsRefresh() {
    await reloadTable();
    setTableState(prevState => ({ ...prevState, cartLoadingMessage: "" }));
  }

  async function splitLineItemsRefresh() {
    await reloadTable();
    ReactDOM.unstable_batchedUpdates(() => {
      setLineItemState(prevState => ({ ...prevState, showEditLineItemModal: false, selectedLineItem: null }));
      setTableState(prevState => ({ ...prevState, cartLoadingMessage: "" }));
    });
  }

  function closeSplitLineItems() {
    ReactDOM.unstable_batchedUpdates(() => {
      void closeSplitItems();
      setLineItemState(prevState => ({ ...prevState, showEditLineItemModal: false, selectedLineItem: null }));
    });
  }

  function setSendChitIds() {
    let lineItemIds: Array<number> = [];
    //Defaults selected line items in Send Chit Modal
    if (activeTable?.carts) {
      const carts = [...activeTable?.carts];
      carts?.forEach(cart => {
        if (cart?.status !== "complete" && (!cart?.order || cart?.order?.financial_status === "unpaid")) {
          const lineItemsToAdd = cart?.line_items
            ?.filter(
              filteredLineItem =>
                !filteredLineItem.parent_id &&
                filteredLineItem?.kitchen_status !== "sent" &&
                (filteredLineItem?.product?.meta?.kitchen_location_id ||
                  filteredLineItem?.product?.meta?.kitchen_location_id_2 ||
                  filteredLineItem?.product?.meta?.kitchen_location_id_3),
            )
            ?.map(lineItem => lineItem?.id);
          lineItemIds = [...lineItemIds, ...lineItemsToAdd];
        }
      });
    }
    return lineItemIds;
  }

  const tableActions: IAction[] = [
    {
      icon: "receipt",
      onClick: () => updatePrintChits({ isOpen: true }),
      label: "Print Chit",
      disabled: !cart,
    },
    {
      icon: "fire",
      onClick: () => sendFireChit(),
      label: "Fire Chit",
      disabled: !cart,
    },

    ...(activeTable?.carts?.length > 0
      ? [
          {
            icon: "chair" as IconName,
            onClick: () => updateMoveSeats({ isOpen: true }),
            label: "Move Seats",
            disabled: false,
          },
          {
            icon: "turn-down-right" as IconName,
            onClick: () => updateMoveItems({ isOpen: true }),
            label: "Move Line Items",
            disabled: (cartStore.activeTable?.carts?.filter(isSeatAbleToMoveLineItems) ?? []).length < 2,
          },
        ]
      : []),
    {
      icon: "right-left",
      onClick: () => updateTransferTable({ isOpen: true }),
      label: "Transfer Table",
      disabled: false,
    },
    {
      icon: "xmark",
      onClick: handleOpenCloseTableModal,
      label: "Close Table",
      disabled: false,
    },
  ];

  const seatActions: IAction[] = [
    ...(cart?.line_items.length > 0
      ? [
          {
            icon: "ban" as IconName,
            onClick: () => updateVoidSeat({ isOpen: true }),
            label: "Void Seat",
            disabled: !permissions.orders_void,
          },
        ]
      : [
          {
            icon: "xmark" as IconName,
            onClick: () => updateCloseSeat({ isOpen: true }),
            label: "Close Seat",
            disabled: order?.financial_status === "partially_paid" || !cart ? true : false,
          },
        ]),

    {
      icon: "arrow-rotate-left" as IconName,
      onClick: () => updateClearSeat({ isOpen: true }),
      label: "Clear Seat",
      disabled:
        !cart || order?.financial_status === "partially_paid" || cart?.status === "complete" || cart?.status === "void"
          ? true
          : false,
    },

    {
      icon: "arrows-rotate" as IconName,
      onClick: () => updateMergeSeats({ isOpen: true }),
      label: "Merge Seats",
      disabled:
        !cart ||
        order?.financial_status === "partially_paid" ||
        cart?.status === "complete" ||
        cart?.status === "void" ||
        activeTable?.carts?.filter(
          filterCart =>
            filterCart?.id !== cart?.id &&
            filterCart?.status !== "complete" &&
            filterCart?.status !== "void" &&
            filterCart?.order?.financial_status !== "partially_paid",
        )?.length < 1
          ? true
          : false,
    },

    {
      icon: "file-lines",
      onClick: () => handleOpenCartDetailsSheet(),
      label: "Details",
      disabled:
        !cart || order?.financial_status === "partially_paid" || cart?.status === "complete" || cart?.status === "void",
    },

    {
      icon: "notes",
      onClick: () => updateViewChits({ isOpen: true }),
      label: "All Chits",
      disabled: false,
    },
  ];

  const elementsComplete =
    manualStripeState.elementComplete.cardNumber === true &&
    manualStripeState.elementComplete.cardExpiry === true &&
    manualStripeState.elementComplete.cardCvc === true;

  const cartLoading =
    loadingVariants?.current?.length > 0 || lineItemsUpdating?.current?.length > 0 || !!cartLoadingMessage;

  return (
    <div className="table-service-cart">
      <div className="active-table-seats-container">
        {activeTable?.carts?.map((seatCart, index) => {
          return (
            <button
              key={index}
              disabled={cartLoading || !!cartLoadingMessage}
              onClick={() => handleSelectSeat(seatCart)}
              className={classNames("active-table-seats-button", {
                "active-table-seats-button-selected": seatCart?.token === cart?.token,
                [`active-table-seats-button-${seatCart?.status}`]: seatCart?.status,
                [`active-table-seats-button-${seatCart?.status}-selected`]:
                  seatCart?.status && seatCart?.token === cart?.token,
                [`active-table-seats-button-${seatCart?.order?.financial_status}`]: seatCart?.order?.financial_status,
                [`active-table-seats-button-${seatCart?.order?.financial_status}-selected`]:
                  seatCart?.order?.financial_status && seatCart?.token === cart?.token,
              })}
            >
              {index + 1}
            </button>
          );
        })}

        {/* Loading seats */}
        {tempSeats?.length > 0 &&
          tempSeats?.map((tempSeats, seatIndex) => (
            <button key={seatIndex} className="active-table-seats-button">
              <Spin />
            </button>
          ))}

        {/* Add new seat */}
        <button className="active-table-seats-button" onClick={handleAddSeat} disabled={cartLoading}>
          +
        </button>
      </div>

      <div>
        <NewBadgeGroup
          primaryContent={cart?.customer?.full_name}
          secondaryContent={cart?.description}
          icon={cart?.status === "complete" ? "circle-dollar" : null}
          badgeGroupStyle="tertiary"
          badgeGroupType="default"
          badgeGroupSize="small"
          onClick={cartLoadingMessage ? undefined : () => handleOpenCartDetailsSheet()}
        />
      </div>

      {facilityStore?.kitchenCourses?.length > 0 && (
        <div className="table-service-course-container" style={{ flexShrink: "0" }}>
          {facilityStore?.kitchenCourses?.map((course, index) => {
            return (
              <span
                key={index}
                onClick={() => selectCourse(course?.id)}
                className={classNames("table-service-course-badge", {
                  "table-service-course-badge-selected": selectedCourseId === course?.id,
                })}
              >
                <Badge type={selectedCourseId === course?.id ? "primary" : "gray"} size="medium">
                  {course?.title}
                </Badge>
              </span>
            );
          })}
        </div>
      )}

      <div className="table-service-cart-line-items">
        {!isEmpty(cartLoadingMessage) ? (
          <div style={{ display: "flex", margin: "auto 0", height: "100%" }}>
            <div className="text-center m-auto">
              <div style={{ height: "32px" }}>
                <Spin />
              </div>
              <div>{cartLoadingMessage}</div>
            </div>
          </div>
        ) : (
          <TableServiceCartLineItems
            handleDiscountClick={(discountLine, parentLineItemId) =>
              setDiscountState(prevState => ({
                ...prevState,
                showModal: true,
                selectedDiscount: discountLine,
                parentLineItemId: parentLineItemId ? parentLineItemId : null,
              }))
            }
            handleLineItemClick={
              cart?.status !== "complete" && order?.financial_status !== "partially_paid" && cart?.status !== "void"
                ? item =>
                    setLineItemState(prevState => ({
                      ...prevState,
                      selectedLineItem: item,
                      showEditLineItemModal: true,
                    }))
                : undefined
            }
            lineItems={cart?.line_items}
            discountLines={cart?.discount_lines}
          />
        )}
      </div>

      {cart && isEmpty(cartLoadingMessage) && (
        <div className="table-service-cart-totals">
          <CartTotalsNew
            currency={cart?.currency}
            subTotal={cart?.subtotal_price}
            taxLines={cart?.tax_lines}
            totalTip={cart?.total_tip}
            total={cart?.total_price}
            order={order}
            handleTipTeeSheet={handleAddTip}
            cart={cart}
          />
        </div>
      )}

      {cart?.order && <CartTransactions transactions={cart?.order?.transactions} currency={order?.currency} />}

      <div className="active-table-cart-button-group">
        <Button
          disabled={
            !cart ||
            cart?.line_items?.length === 0 ||
            cart?.status === "complete" ||
            cartLoading ||
            cart?.status === "void"
          }
          block
          size="medium"
          type="primary"
          onClick={handlePrePaymentChecks}
          loading={paymentState?.paymentInProgress}
        >
          Pay
        </Button>
        <div className="active-table-secondary-buttons">
          <Button
            disabled={cartLoading}
            block
            size="medium"
            type="secondary"
            onClick={() => updateSendChits({ isOpen: true })}
          >
            <FontAwesomeIcon className="mr-2" icon={["fal", "paper-plane"]} size="1x" /> Send
          </Button>
          <Button
            block
            size="medium"
            type="secondary"
            disabled={!cart || cartLoading}
            onClick={() => (order ? printReceipt(order?.id, "order") : printReceipt(cart?.id, "cart"))}
          >
            <FontAwesomeIcon className="mr-2" icon={["fal", "receipt"]} size="1x" /> Receipt
          </Button>
          <Button
            disabled={cartLoading}
            block
            size="medium"
            type="secondary"
            onClick={() => setActionsState(prevState => ({ ...prevState, showActionsModal: true }))}
          >
            {" "}
            <FontAwesomeIcon className="mr-2" icon={["fas", "grip-dots"]} size="1x" /> Actions
          </Button>
        </div>
      </div>

      {lineItemState?.selectedLineItem && (
        <EditLineItemModal
          open={lineItemState.showEditLineItemModal}
          lineItem={lineItemState.selectedLineItem}
          onCancel={() =>
            setLineItemState(prevState => ({ ...prevState, showEditLineItemModal: false, selectedLineItem: null }))
          }
          removeLineItem={handleRemoveLineItem}
          update={updatedLineItem => handleUpdateLineItem(updatedLineItem)}
          openSplitItemModal={() => updateSplitItems({ isOpen: true })}
          openModifierModal={lineItem => updateRegisterState({ modifierLineItem: lineItem })}
          courses
        />
      )}

      <Popup
        type="warning"
        open={discountState.showModal}
        title="Remove Discount Line"
        description="Are you sure you want to remove this discount?"
        closable
        onOk={() => {
          void removeDiscountLine();
        }}
        onCancel={() =>
          setDiscountState(prevState => ({
            ...prevState,
            showModal: false,
            selectedDiscount: null,
            parentLineItemId: null,
          }))
        }
        okText="Remove"
      />

      <Popup
        type="warning"
        open={closeTable?.isOpen}
        title="Close Table?"
        description={
          closeTable?.partiallyPaidSeats ||
          !!closeTable?.loadingMessage ||
          (closeTable?.voidSeats && !permissions?.orders_void)
            ? ""
            : "Are you sure you want to close this table?"
        }
        closable
        onOk={handleCloseTable}
        okText="OK"
        cancelText="Cancel"
        onCancel={cancelCloseTable}
        backDropCancel={false}
        cancelDisabled={!!closeTable.loadingMessage}
        okDisabled={
          closeTable?.partiallyPaidSeats ||
          !!closeTable?.loadingMessage ||
          (closeTable?.voidSeats && !permissions?.orders_void)
        }
      >
        {closeTable?.loadingMessage ? (
          <div style={{ display: "flex", height: "100%" }}>
            <div className="text-center m-auto">
              <div style={{ height: "32px" }}>
                <Spin />
              </div>
              <div>{closeTable?.loadingMessage}</div>
            </div>
          </div>
        ) : closeTable?.partiallyPaidSeats ? (
          <div className="mt-4">
            <p>
              <b>There are still partially paid seats open</b>
            </p>
          </div>
        ) : closeTable?.voidSeats && permissions?.orders_void ? (
          <div className="mt-4">
            <p>
              <b>There are still open seats with line items. Void all seats with line items?</b>
            </p>
            <Divider />
            <Select
              label="Reason for Voiding Seats"
              defaultValue={voidSeat.cancel_reason}
              onChange={(value: string) => updateCloseTable({ cancel_reason: value })}
            >
              {cancel_reasons.map((reason, index) => (
                <Option key={index} value={reason.title} name={reason.title}>
                  {reason.title}
                </Option>
              ))}
            </Select>
          </div>
        ) : closeTable?.voidSeats && !permissions?.orders_void ? (
          <div className="mt-4">
            <p>
              <b>There are still open seats with line items</b>
            </p>
          </div>
        ) : closeTable?.seatsOpen ? (
          <div className="mt-4">
            <p>
              <b>There are still open seats. Continue to close table?</b>
            </p>
          </div>
        ) : null}
      </Popup>

      <Popup
        open={clearSeat?.isOpen}
        type="warning"
        title="Clear Seat?"
        description="Clearing a seat is a permanent action. All line items and discounts will removed."
        onOk={handleClearCart}
        okText="Clear"
        cancelText="Cancel"
        onCancel={closeClearSeat}
        backDropCancel={true}
      />

      <Popup
        type="warning"
        open={closeSeat?.isOpen}
        title="Close Seat?"
        description="Are you sure want to close this seat?"
        closable
        onOk={handleSeatCartClose}
        okText="OK"
        backDropCancel
        cancelText="Cancel"
        onCancel={closeCloseSeat}
      />

      <Popup
        type="warning"
        open={voidSeat.isOpen}
        title={"Void Seat"}
        description={""}
        closable
        onOk={handleSeatCartVoid}
        okText={"OK"}
        backDropCancel
        cancelText={"Cancel"}
        onCancel={closeVoidSeat}
        okDisabled={!voidSeat.cancel_reason ? true : false}
      >
        <div>
          <Select
            label="Reason for Voiding Seat"
            defaultValue={voidSeat.cancel_reason}
            onChange={(value: string) => updateVoidSeat({ cancel_reason: value })}
          >
            {cancel_reasons.map((reason, index) => (
              <Option key={index} value={reason.title} name={reason.title}>
                {reason.title}
              </Option>
            ))}
          </Select>
        </div>
      </Popup>

      {stripePaymentState.processing ? (
        <Popup
          type="info"
          open={stripePaymentState.sheetVisible}
          title={"Please Wait..."}
          description={"Customer is completing the transaction"}
          closable
          onCancel={stripePaymentState.onCancel}
          onOk={stripePaymentState.onOk}
          cancelText={stripePaymentState.cancelText}
          okText={stripePaymentState.okText}
          backDropCancel={false}
        />
      ) : (
        <>
          {stripePaymentState.success ? (
            <Popup
              type="success"
              open={stripePaymentState.sheetVisible}
              title={"Payment Complete"}
              description={"Customer has successfully completed their transaction"}
              closable
              onCancel={stripePaymentState.onCancel}
              onOk={stripePaymentState.onOk}
              cancelText={stripePaymentState.cancelText}
              okText={stripePaymentState.okText}
              backDropCancel={false}
            />
          ) : (
            <Popup
              type="error"
              open={stripePaymentState.sheetVisible}
              title={"Payment Error"}
              description={stripePaymentState.message + " - Error Code: " + stripePaymentState.convertedCode}
              closable
              onCancel={stripePaymentState.onCancel}
              onOk={stripePaymentState.onOk}
              cancelText={stripePaymentState.cancelText}
              okText={stripePaymentState.okText}
              backDropCancel={false}
            />
          )}
        </>
      )}

      <StripeTerminalSheet />

      <GiftCardSell
        gift_cards={paymentState.gift_cards}
        sellGiftCardsActive={paymentState.sellGiftCardsActive}
        onOk={handleAddSellGiftCards}
        onCancel={cancelGiftCardsAndLeagues}
      />

      <GiftCardRedeem
        redeemGiftCardsActive={paymentState.giftCardModalVisible}
        onOk={handleProcessPayment}
        onCancel={() =>
          setPaymentState(prevState => ({
            ...prevState,
            giftCardModalVisible: false,
            paymentInProgress: false,
          }))
        }
        cart={cartStore?.cart}
      />

      <LeagueSelectionModal
        open={paymentState.leagueFeesActive}
        leagueFeeProducts={paymentState.leagueFeeProducts}
        onCancel={cancelGiftCardsAndLeagues}
        onOk={setSelectedLeagues}
      />

      <AccountsModal
        loadAccounts={paymentState.accountsVisible}
        onCancel={() =>
          setPaymentState(prevState => ({
            ...prevState,
            accountsVisible: false,
            selectedAccount: undefined,
          }))
        }
        onOk={account =>
          setPaymentState(prevState => ({ ...prevState, accountsVisible: false, selectedAccount: account }))
        }
        customerId={cartStore?.cart?.customer_id}
        searchAccountPermission={permissions?.accounts_charge_all}
      />

      <CreditBookModal
        loadCreditBooks={modalCreditBook.isOpen}
        onCancel={() => closeCreditBook()}
        onOk={(selectedCreditBook: IPrizeAccount) =>
          updateCreditBook({ selectedCreditBook: { ...selectedCreditBook } })
        }
        customerId={cartStore?.cart?.customer?.id}
        // searchAccountPermission={permissions?.credit}
      />

      <ActionModal
        open={actionsState.showActionsModal}
        setOpen={(open: boolean) => setActionsState(prevState => ({ ...prevState, showActionsModal: open }))}
        actionGroups={[tableActions, seatActions]}
      />

      {sendChits?.isOpen && (
        <TableServiceSendLineItemsModal
          open={sendChits?.isOpen}
          selectedIds={setSendChitIds()}
          onOk={lineItemIds => handleSendChit(lineItemIds)}
          onCancel={cancelSendChits}
          activeTable={activeTable}
        />
      )}

      <Portal isMounted={printChitsState?.isOpen}>
        <PrintChit
          printOrderVisible={printChitsState?.isOpen}
          onCancel={cancelPrintChits}
          onOk={handlePrintReceipt}
          activeTable={activeTable}
        />
      </Portal>

      <MergeSeats mergeSeatsVisible={mergeSeats?.isOpen} onCancel={closeMergeSeats} onOk={handleSeatMerge} />

      <EditSeatDetails
        editSeatVisible={seatDetails.isOpen}
        onCancel={closeSeatDetails}
        onOk={handleUpdateCartDetails}
        prevCartDetails={{
          customer: seatDetails.customer,
          description: seatDetails.description,
        }}
      />

      <TransferTableSheet
        modalVisibility={transferTable.isOpen}
        onCancel={() => updateTransferTable({ isOpen: false })}
        onOk={handleTransferTable}
        users={transferTable.users}
      />

      {viewChits?.isOpen && (
        <TableServiceKitchenChitModal
          isVisible={viewChits?.isOpen}
          closeModal={() => closeViewChits()}
          user_id={authStore?.user?.id}
          forceRefresh={forceRefreshChits}
          setForceRefresh={setForceRefreshChits}
        />
      )}

      <MoveSeats addToTableModalVisible={moveSeats?.isOpen} onCancel={closeMoveSeats} onOk={handleMoveCarts} />

      <TableServiceMoveLineItemsModal
        open={moveItems.isOpen}
        seats={activeTable?.carts}
        onCancel={closeMoveItems}
        onRefresh={moveLineItemsRefresh}
        setTableState={setTableState}
      />

      <SplitLineItemsModal
        onCancel={closeSplitLineItems}
        onRefresh={splitLineItemsRefresh}
        open={splitItems?.isOpen}
        setTableState={setTableState}
      />

      <Sheet
        title="Room Charge"
        open={paymentState.roomChargeSheetVisible}
        size="small"
        closable
        onCancel={() =>
          setPaymentState(prevState => ({
            ...prevState,
            roomChargeSheetVisible: false,
            roomOccupantSearchQuery: "",
            roomOccupantsSearchResults: [],
            selectedOccupant: null,
          }))
        }
        onOk={handleProcessPayment}
        okDisabled={isEmpty(paymentState?.selectedOccupant?.guest_name)}
        okText="Charge"
        stacked
      >
        <Input
          label="Room Guest"
          type="search"
          onChange={e => setPaymentState(prevState => ({ ...prevState, roomOccupantSearchQuery: e.target.value }))}
          value={paymentState?.roomOccupantSearchQuery}
        />
        {paymentState?.roomOccupantsSearchResults.map((occupant, index) => {
          return (
            <div key={index} className="flex flex-row justify-between p-3 border-b">
              <Checkbox
                size="small"
                value={occupant.guest_name}
                checked={isEqual(occupant, paymentState?.selectedOccupant)}
                onChange={e =>
                  setPaymentState(prev => ({
                    ...prev,
                    selectedOccupant: cloneDeep(occupant),
                  }))
                }
                label={occupant.guest_name}
              />
            </div>
          );
        })}
      </Sheet>

      <Sheet
        size={"small"}
        closable
        title="Enter Details"
        cancelText="Cancel"
        open={manualStripeState.visible}
        okText="Process"
        okDisabled={!elementsComplete}
        cancelDisabled={manualProcessing}
        backDropCancel={false}
        stacked
        onOk={() => {
          if (!submitTrigger) {
            setSubmitTrigger(!submitTrigger);
          }
        }}
        onCancel={async () => {
          await cancelManualCreditPaymentIntent(
            manualStripeState?.transaction?.payment_intent_id,
            manualStripeState?.transaction?.id,
          );
          if (elements) {
            elements.getElement(CardNumberElement).clear();
            elements.getElement(CardExpiryElement).clear();
            elements.getElement(CardCvcElement).clear();
          }
          setManualStripeState(prevState => ({
            ...prevState,
            visible: false,
            transaction: {},
          }));
        }}
      >
        <CheckoutForm
          onChange={handleCardSectionChange}
          onSubmit={() => handleManualProcessing()}
          trigger={submitTrigger}
          transaction={manualStripeState.transaction}
          onError={(message: string) => {
            setSubmitTrigger(false);
            setManualProcessing(false);
            dispatch(showError(message));
          }}
          onSuccess={async () => {
            // Clean values
            dispatch(showSuccess("Payment successful"));
            setManualStripeState(prevState => ({
              ...prevState,
              visible: false,
              transaction: {},
              elementComplete: {
                cardCvc: false,
                cardExpiry: false,
                cardNumber: false,
              },
            }));
            setSubmitTrigger(false);
            dispatch(enqueue());
            await updateOrder(order?.id, true);
            dispatch(dequeue());
          }}
        />
      </Sheet>

      {paymentState.paymentModalVisible && (
        <Sheet
          open={paymentState.paymentModalVisible}
          size="small"
          closable
          title="Payment"
          onCancel={closePaymentSheet}
          onOk={handleProcessPaymentCheck}
          cancelText="Cancel"
          okText={paymentState.paymentOption?.payment_method === "gift_card" ? "Enter Gift Card" : "Process Payment"}
          backDropCancel={false}
          okDisabled={paymentState.paymentOption === null || paymentState.paymentAmount === "" ? true : false}
          okLoading={paymentState?.paymentInProgress}
          height="flexible"
        >
          <FormLayout>
            <FormLayout.Group>
              <PaymentMethods
                onChange={handlePaymentOptionChange}
                paymentOptions={facilityStore?.paymentOptions}
                cardReaderAvailable={terminalStore?.reader === null ? false : true}
                cart={cart}
                paymentAmount={Number(paymentState.paymentAmount)}
                value={paymentState.paymentOption}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              {order && <CartTransactions transactions={order?.transactions} currency={order?.currency} />}
            </FormLayout.Group>
            {paymentState?.paymentInProgress ? (
              <FormLayout.Group>
                <div className="table-service-loader">
                  <Spin />
                </div>
              </FormLayout.Group>
            ) : null}
            <FormLayout.Group>
              <Input
                prefix="$"
                type="number"
                label="Amount"
                id="paymentAmount"
                value={paymentState.paymentAmount}
                onChange={e => setPaymentState(prevState => ({ ...prevState, paymentAmount: e.target.value }))}
              />
            </FormLayout.Group>
          </FormLayout>
        </Sheet>
      )}

      <Popup
        type="info"
        open={paymentState.changeDueVisible}
        title={`Change due: ${paymentState.changeDue?.toFixed(2)}`}
        description=""
        closable
        onOk={() => setPaymentState(prevState => ({ ...prevState, changeDueVisible: false, changeDue: null }))}
        okText="OK"
        backDropCancel={false}
        stacked
      />

      <OrderCompletePopup open={orderComplete.isOpen} setOpen={closeOrderCompletePopup} order={cart?.order} />
    </div>
  );
}

const cancel_reasons = [
  {
    id: 1,
    title: "Server Error",
  },
  {
    id: 2,
    title: "Not Satisfied",
  },
];
