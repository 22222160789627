import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";

import "./favouritesIcon.scss";
import classNames from "classnames";

type TFavouritesIcon = {
  isFavourite: boolean;
  onFavouriteChange?: () => void;
  animate?: boolean;
  /** default: 1x */
  size?: SizeProp;
  className?: string;
};

export default function FavouritesIcon(props: TFavouritesIcon) {
  const { t } = useTranslation();
  const [animateFavourites, setAnimateFavourites] = useState<boolean>(false);

  const icon = () => {
    if (props.isFavourite || !props.onFavouriteChange) {
      return ["fas", "star"] as IconProp;
    }
    return ["far", "star"] as IconProp;
  };
  return (
    <OverlayTrigger
      placement="auto"
      overlay={
        <Tooltip id="courseFavouritesToolTip">
          {props.isFavourite
            ? "Remove from favourites" // TODO: Translation
            : "Add to favourites"}
        </Tooltip>
      }
    >
      <FontAwesomeIcon
        onClick={props.onFavouriteChange ?? undefined}
        onMouseEnter={() => (props.animate ? setAnimateFavourites(true) : null)}
        onMouseLeave={() => (props.animate ? setAnimateFavourites(false) : null)}
        beatFade={animateFavourites}
        size={props.size ?? "1x"}
        className={classNames("favourites-icon", props.className, { "favourites-icon-favourited": props.isFavourite })}
        icon={["fas", "star"]}
      />
    </OverlayTrigger>
  );
}
