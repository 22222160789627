import { IFloorPlanTable, ITableElement, TableDegreeRotation } from "api/rpc/facilityAdmin/tables/tables";
import React, { useEffect, useRef, useState } from "react";
import { useDrop, XYCoord } from "react-dnd";
import { DEFAULT_TABLE_DIMENSION, ItemTypes } from "../floorPlanEdit/FloorPlanEdit";
import FloorPlanTable, { IFloorPlanTableItem, UpdateDroppedTable } from "../floorPlanTable/FloorPlanTable";
import FloorPlanTableCustomDragLayer from "../floorPlanTableCustomDragLayer/FloorPlanTableCustomDragLayer";
import "./floorPlan.scss";
import { useTranslation } from "react-i18next";

interface IFloorPlanProps {
  tables: IFloorPlanTable[];
  setTables: React.Dispatch<React.SetStateAction<IFloorPlanTable[]>>;
  tableElements: ITableElement[];
  floorPlanId: number;
  gridWidth: number;
  gridHeight: number;
  tablesBoundingRectangles: IFloorPlanBoundingRectangle[];
  setTablesBoundingRectangles: (boundingRectangles: IFloorPlanBoundingRectangle[]) => void;
}

export interface IFloorPlanTableScale {
  widthScale: number;
  heightScale: number;
}

export interface IFloorPlanBoundingRectangle {
  id: string;
  topLeft: XYCoord;
  bottomRight: XYCoord;
}

export interface ITableElementGridDimensions {
  grid_width: number;
  grid_height: number;
}

export default function FloorPlan(props: IFloorPlanProps) {
  const {
    tables,
    setTables,
    tableElements,
    floorPlanId,
    gridWidth,
    gridHeight,
    tablesBoundingRectangles,
    setTablesBoundingRectangles,
  } = props;

  const floorPlanRef = useRef<HTMLDivElement>(null);

  const [floorPlanWidth, setFloorPlanWidth] = useState<number>(0);
  const [floorPlanHeight, setFloorPlanHeight] = useState<number>(0);
  const [gridSquareSideLength, setGridSquareSideLength] = useState<number>(0);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    function handleResize() {
      setFloorPlanWidth(floorPlanRef.current.clientWidth);
    }

    if (floorPlanRef?.current) {
      handleResize();
      window.addEventListener("resize", handleResize);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [floorPlanRef?.current]);

  useEffect(() => {
    if (floorPlanWidth > 0 && gridWidth > 0 && gridHeight > 0) {
      const gridProportion = gridHeight / gridWidth;

      setFloorPlanHeight(floorPlanWidth * gridProportion);
      setGridSquareSideLength(floorPlanWidth / gridWidth);
    }
  }, [floorPlanWidth, gridWidth, gridHeight]);

  function rotateTableElementGridDimensions(
    tableElement: ITableElement,
    degreeRotation?: TableDegreeRotation,
  ): ITableElementGridDimensions {
    const gridWidth = tableElement.grid_width;
    const gridHeight = tableElement.grid_height;

    return {
      grid_width: degreeRotation === undefined || (degreeRotation / 90) % 2 === 0 ? gridWidth : gridHeight,
      grid_height: degreeRotation === undefined || (degreeRotation / 90) % 2 === 0 ? gridHeight : gridWidth,
    };
  }

  function calculateTableScale(tableElement: ITableElement, degreeRotation?: TableDegreeRotation) {
    const tableScale: IFloorPlanTableScale = { widthScale: 1, heightScale: 1 };

    if (gridSquareSideLength && tableElement) {
      const gridDimensions: ITableElementGridDimensions = degreeRotation
        ? rotateTableElementGridDimensions(tableElement, degreeRotation)
        : { grid_width: tableElement.grid_width, grid_height: tableElement.grid_height };

      tableScale.widthScale = (gridDimensions.grid_width * gridSquareSideLength) / DEFAULT_TABLE_DIMENSION;
      tableScale.heightScale = (gridDimensions.grid_height * gridSquareSideLength) / DEFAULT_TABLE_DIMENSION;
    }

    return tableScale;
  }

  function checkIfTableIsOutsideFloorPlan(
    closestGridCoordinate: XYCoord,
    halfTableWidth: number,
    halfTableHeight: number,
  ) {
    return (
      closestGridCoordinate.x - halfTableWidth < 0 ||
      closestGridCoordinate.x + halfTableWidth > gridWidth ||
      closestGridCoordinate.y - halfTableHeight < 0 ||
      closestGridCoordinate.y + halfTableHeight > gridHeight
    );
  }

  function checkIfTableIsIntersectingAnotherTable(tableGridBoundingRectangle: IFloorPlanBoundingRectangle) {
    let tableIntersectingAnotherTable = false;

    for (let i = 0; i < tablesBoundingRectangles.length && !tableIntersectingAnotherTable; i++) {
      const boundingRectangle = tablesBoundingRectangles[i];

      // If we are dropping an optionTable onto a droppedTable or we are dropping a droppedTable onto a different droppedTable
      if (
        (tableGridBoundingRectangle.id === undefined || tableGridBoundingRectangle.id !== boundingRectangle.id) &&
        !(
          tableGridBoundingRectangle.topLeft.x >= boundingRectangle.bottomRight.x ||
          tableGridBoundingRectangle.bottomRight.x <= boundingRectangle.topLeft.x ||
          tableGridBoundingRectangle.topLeft.y >= boundingRectangle.bottomRight.y ||
          tableGridBoundingRectangle.bottomRight.y <= boundingRectangle.topLeft.y
        )
      ) {
        tableIntersectingAnotherTable = true;
      }
    }

    return tableIntersectingAnotherTable;
  }

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.TABLE,
      drop: (item: IFloorPlanTableItem, monitor) => {
        const floorPlanBoundingRectangle = floorPlanRef.current.getBoundingClientRect();
        const floorPlanCoordinate: XYCoord = {
          x: Math.round(floorPlanBoundingRectangle.x),
          y: Math.round(floorPlanBoundingRectangle.y),
        };

        const dragCoordinate: XYCoord = monitor.getClientOffset();

        const newTableViewportDelta: XYCoord = {
          x: dragCoordinate.x - floorPlanCoordinate.x,
          y: dragCoordinate.y - floorPlanCoordinate.y,
        };

        const tableBoundingRectangle = item.tableRef.current.getBoundingClientRect();
        const tableCoordinate: XYCoord = {
          x: Math.round(tableBoundingRectangle.x),
          y: Math.round(tableBoundingRectangle.y),
        };

        const tableInitialDragViewportDelta: XYCoord = {
          x: item.initialDragCoordinate.x - tableCoordinate.x,
          y: item.initialDragCoordinate.y - tableCoordinate.y,
        };

        const currentScrollYPosition = window.scrollY;
        const scrollYOffset = currentScrollYPosition - item.initialDragScrollYPosition;

        let tableScale: IFloorPlanTableScale = { widthScale: 1, heightScale: 1 };

        if (item.optionTable) {
          tableScale = calculateTableScale(item.optionTable.element);
        }

        const newTableCoordinate: XYCoord = {
          x:
            newTableViewportDelta.x - tableInitialDragViewportDelta.x * (item.droppedTable ? 1 : tableScale.widthScale),
          y:
            newTableViewportDelta.y -
            tableInitialDragViewportDelta.y * (item.droppedTable ? 1 : tableScale.heightScale) +
            scrollYOffset * (item?.droppedTable ? 1 : tableScale.heightScale),
        };

        const tableElement = item.droppedTable
          ? tableElements.find(tableElement => tableElement.id === item.droppedTable.table_element_id)
          : item.optionTable
          ? item.optionTable.element
          : undefined;

        if (!tableElement) {
          return;
        }

        const rotatedGridDimensions = rotateTableElementGridDimensions(tableElement, item.droppedTable?.rotation);

        const centerOfTableXPosition =
          newTableCoordinate.x + (rotatedGridDimensions.grid_width * gridSquareSideLength) / 2;
        const centerOfTableYPosition =
          newTableCoordinate.y + (rotatedGridDimensions.grid_height * gridSquareSideLength) / 2;

        const gridXPosition = Math.min(
          Math.max(1, Math.round(centerOfTableXPosition / gridSquareSideLength)),
          gridWidth - 1,
        );
        const gridYPosition = Math.min(
          Math.max(1, Math.round(centerOfTableYPosition / gridSquareSideLength)),
          gridHeight - 1,
        );

        const halfTableWidth = rotatedGridDimensions.grid_width / 2;
        const halfTableHeight = rotatedGridDimensions.grid_height / 2;

        if (
          gridXPosition - halfTableWidth < 0 ||
          gridXPosition + halfTableWidth > gridWidth ||
          gridYPosition - halfTableHeight < 0 ||
          gridYPosition + halfTableHeight > gridHeight
        ) {
          return;
        }

        const tableGridBoundingRectangle: IFloorPlanBoundingRectangle = {
          id: item.droppedTable ? item.droppedTable.id : undefined,
          topLeft: {
            x: gridXPosition - rotatedGridDimensions.grid_width / 2,
            y: gridYPosition - rotatedGridDimensions.grid_height / 2,
          },
          bottomRight: {
            x: gridXPosition + rotatedGridDimensions.grid_width / 2,
            y: gridYPosition + rotatedGridDimensions.grid_height / 2,
          },
        };

        const tableIntersectingAnotherTable = checkIfTableIsIntersectingAnotherTable(tableGridBoundingRectangle);

        if (!tableIntersectingAnotherTable) {
          if (item.droppedTable) {
            setTables(prevTables => {
              return prevTables.map(table => {
                if (item.droppedTable.id === table.id) {
                  return {
                    ...item.droppedTable,
                    x_coordinate: gridXPosition,
                    y_coordinate: gridYPosition,
                  };
                } else {
                  return table;
                }
              });
            });
          } else if (item.optionTable) {
            setTables(prevTables => {
              const newTableId = self.crypto.randomUUID();

              const newTable: IFloorPlanTable = {
                id: newTableId,
                x_coordinate: gridXPosition,
                y_coordinate: gridYPosition,
                seats: item.optionTable.element.seats,
                title: t("secure.facility.settings.floor_plan.floor_plan.floor_plan.001"),
                short_title: "N",
                table_element_id: item.optionTable.element.id,
                floor_plan_id: floorPlanId,
                rotation: 0,
              };

              return [...prevTables, newTable];
            });
          }
        }
      },
      collect: monitor => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [gridSquareSideLength, tablesBoundingRectangles],
  );

  useEffect(() => {
    if (tables) {
      const updatedTablesBoundingRectangles = tables.map(table => {
        const tableElement = tableElements.find(tableElement => tableElement.id === table.table_element_id);

        const rotatedGridDimensions = rotateTableElementGridDimensions(tableElement, table.rotation);

        const halfTableWidth = rotatedGridDimensions.grid_width / 2;
        const halfTableHeight = rotatedGridDimensions.grid_height / 2;

        const tableXCoordinate = table.x_coordinate;
        const tableYCoordinate = table.y_coordinate;

        const boundingRectangle: IFloorPlanBoundingRectangle = {
          id: table.id,
          topLeft: { x: tableXCoordinate - halfTableWidth, y: tableYCoordinate - halfTableHeight },
          bottomRight: { x: tableXCoordinate + halfTableWidth, y: tableYCoordinate + halfTableHeight },
        };

        return boundingRectangle;
      });

      setTablesBoundingRectangles(updatedTablesBoundingRectangles);
    }
  }, [tables]);

  const updateTable: (id: string) => UpdateDroppedTable = (id: string) => {
    const innerUpdateTable = (updatedTable: Partial<IFloorPlanTable>): void => {
      setTables(prevTables =>
        prevTables.map(table => {
          if (table.id === id) {
            return { ...table, ...updatedTable };
          } else {
            return table;
          }
        }),
      );
    };

    return innerUpdateTable;
  };

  const deleteTable: (id: string) => () => void = (id: string) => {
    return () => setTables(prevTables => prevTables.filter(table => table.id !== id));
  };

  function calculateTableXPixelCoordinate(table: IFloorPlanTable) {
    const tableElement = tableElements.find(tableElement => tableElement.id === table.table_element_id);
    if (tableElement) {
      const rotatedGridDimensions = rotateTableElementGridDimensions(tableElement, table.rotation);

      const xPixelCoordinate =
        table.x_coordinate * gridSquareSideLength - (rotatedGridDimensions.grid_width * gridSquareSideLength) / 2;

      return xPixelCoordinate;
    } else {
      return 0;
    }
  }

  function calculateTableYPixelCoordinate(table: IFloorPlanTable) {
    const tableElement = tableElements.find(tableElement => tableElement.id === table.table_element_id);
    if (tableElement) {
      const rotatedGridDimensions = rotateTableElementGridDimensions(tableElement, table.rotation);

      const yPixelCoordinate =
        table.y_coordinate * gridSquareSideLength - (rotatedGridDimensions.grid_height * gridSquareSideLength) / 2;

      return yPixelCoordinate;
    } else {
      return 0;
    }
  }

  function calculateCenterOfTableXPixelOffsetFromTable(table: IFloorPlanTable) {
    const tableElement = tableElements.find(tableElement => tableElement.id === table.table_element_id);
    if (tableElement) {
      return calculateCenterOfTableXPixelOffset(tableElement, table.rotation);
    } else {
      return 0;
    }
  }

  function calculateCenterOfTableXPixelOffset(tableElement: ITableElement, degreeRotation?: TableDegreeRotation) {
    if (tableElement) {
      const rotatedGridDimensions = rotateTableElementGridDimensions(tableElement, degreeRotation);

      return (rotatedGridDimensions.grid_width * gridSquareSideLength) / 2;
    } else {
      return 0;
    }
  }

  function calculateCenterOfTableYPixelOffsetFromTable(table: IFloorPlanTable) {
    const tableElement = tableElements.find(tableElement => tableElement.id === table.table_element_id);
    if (tableElement) {
      return calculateCenterOfTableYPixelOffset(tableElement, table.rotation);
    } else {
      return 0;
    }
  }

  function calculateCenterOfTableYPixelOffset(tableElement: ITableElement, degreeRotation?: TableDegreeRotation) {
    if (tableElement) {
      const rotatedGridDimensions = rotateTableElementGridDimensions(tableElement, degreeRotation);

      return (rotatedGridDimensions.grid_height * gridSquareSideLength) / 2;
    } else {
      return 0;
    }
  }

  function calculateClosestGridCoordinate(
    currentCoordinate: XYCoord,
    tableElement: ITableElement,
    degreeRotation?: TableDegreeRotation,
  ) {
    let centerOfTableXPosition = 0;
    let centerOfTableYPosition = 0;

    if (tableElement) {
      const rotatedGridDimensions = rotateTableElementGridDimensions(tableElement, degreeRotation);

      centerOfTableXPosition = currentCoordinate.x + (rotatedGridDimensions.grid_width * gridSquareSideLength) / 2;
      centerOfTableYPosition = currentCoordinate.y + (rotatedGridDimensions.grid_height * gridSquareSideLength) / 2;
    }

    const gridXPosition = Math.min(
      Math.max(1, Math.round(centerOfTableXPosition / gridSquareSideLength)),
      gridWidth - 1,
    );
    const gridYPosition = Math.min(
      Math.max(1, Math.round(centerOfTableYPosition / gridSquareSideLength)),
      gridHeight - 1,
    );

    return {
      x: gridXPosition,
      y: gridYPosition,
    };
  }

  return (
    <div
      ref={el => {
        drop(el);
        floorPlanRef.current = el;
      }}
      className="floor-plan"
      style={{
        ...(isOver ? { borderColor: "green" } : {}),
        width: "100%",
        height: floorPlanHeight,
        backgroundSize: `${gridSquareSideLength}px ${gridSquareSideLength}px`,
      }}
    >
      {tables.map(table => {
        return (
          <React.Fragment key={`table-${table.id}`}>
            <FloorPlanTable
              droppedTable={table}
              updateDroppedTable={updateTable(table.id)}
              deleteDroppedTable={deleteTable(table.id)}
              tableElements={tableElements}
              calculateTableScale={calculateTableScale}
              xCoordinate={calculateTableXPixelCoordinate(table)}
              yCoordinate={calculateTableYPixelCoordinate(table)}
              xCenterCoordinate={calculateCenterOfTableXPixelOffsetFromTable(table)}
              yCenterCoordinate={calculateCenterOfTableYPixelOffsetFromTable(table)}
              gridSquareSideLength={gridSquareSideLength}
            />
          </React.Fragment>
        );
      })}
      <FloorPlanTableCustomDragLayer
        floorPlanRef={floorPlanRef}
        calculateTableScale={calculateTableScale}
        isOverFloorPlan={isOver}
        calculateClosestGridCoordinate={calculateClosestGridCoordinate}
        tableElements={tableElements}
        checkIfTableIsOutsideFloorPlan={checkIfTableIsOutsideFloorPlan}
        gridSquareSideLength={gridSquareSideLength}
        checkIfTableIsIntersectingAnotherTable={checkIfTableIsIntersectingAnotherTable}
        rotateTableElementGridDimensions={rotateTableElementGridDimensions}
        calculateCenterOfTableXPixelOffset={calculateCenterOfTableXPixelOffset}
        calculateCenterOfTableYPixelOffset={calculateCenterOfTableYPixelOffset}
      />
    </div>
  );
}
