import React, { ChangeEvent, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TFunction, useTranslation } from "react-i18next";

import { showError } from "redux/actions/ui";

import { useAppDispatch } from "hooks/redux";
import { weekdayNames } from "helpers/Helpers";

import Sheet from "components/sheet/Sheet";
import { ButtonNew } from "components/buttonNew";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import { Select } from "components/select/index";
import { Badge } from "components/badge/Badge";
import Portal from "elements/Portal";
import CheckboxBlock from "../elements/CheckboxBlock";
import CustomSelect from "../elements/CustomSelect";
import Popup from "components/popup/Popup";

interface ICustomSelectEdit {
  label: string;
  array: string[];
  helpText: string;
  required: boolean;
  saveInput: (label: string, array: string[], helpText: string, required: boolean) => void;
  editModalOpen: boolean;
  closeEditModal: () => void;
  /** Viewable INPUT on the edit page itself. */
  children: React.ReactNode;
}

/** Structural display wrapper containing edit modal and its state. */
export default function CustomSelectEdit(props: ICustomSelectEdit) {
  const { label, array, helpText = "", required, editModalOpen, closeEditModal } = props;

  const { t } = useTranslation();
  const selectInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  const { Option } = Select;

  const [state, setState] = useState({
    input: "",
    label: label,
    helpText: helpText,
    array: array !== null ? array : [],
    required: required,
  });

  const [editing, setEditing] = useState({
    state: false, // style trigger
    value: null, // value currently being edited
  });

  function addListItem() {
    if (state.input.length === 0 && !editing.state) {
      dispatch(showError(t("secure.facility.settings.forms.custom_edits.custom_select_edit.001")));
      return;
    }

    // if already found, return early
    if (state.array?.find(val => val === state.input)) {
      if (editing.state && editing.value == state.input) {
        setEditing({ state: false, value: null }); // remove edit state
        setState(prev => ({ ...prev, input: "" })); // remove input value
        return;
      }

      dispatch(showError(t("secure.facility.settings.forms.custom_edits.custom_select_edit.002")));
      return;
    }

    // 'switch' the value if editing
    if (editing.state) {
      updateListItem(state.input);
      return;
    }

    // push new value if not editing
    const returnArray = [...state.array];
    returnArray.push(state.input);

    setState(prev => ({ ...prev, input: "", array: returnArray }));
  }

  // replace list item with editing value.  Remove if value.length == 0
  function updateListItem(value: string) {
    if (value.length === 0) {
      removeListItem(editing.value);
      setEditing({ state: false, value: null });
      return;
    }

    const editReturn = state.array.map(val => (val === editing.value ? value : val));

    setState(prev => ({ ...prev, input: "", array: editReturn }));
    setEditing({ state: false, value: null });
  }

  // splice value from select options
  function removeListItem(value: string) {
    const returnArray = [...state.array];

    if (returnArray.includes(value)) {
      returnArray.splice(returnArray.indexOf(value), 1);
      setState(prev => ({ ...prev, array: returnArray }));
    }
  }

  // Click action
  function selectListItem(isSelected: boolean, value: string) {
    if (editing.state) {
      return;
    } // disallow row click if editing a value
    setState(prev => ({ ...prev, input: isSelected ? "" : value }));
  }

  // Handle editing state of the CustomSelect
  function listIconClick(e: React.MouseEvent<HTMLElement, MouseEvent>, value: string, selected: boolean) {
    e.stopPropagation();

    // Default icon functionality
    if (!editing.state && !selected) {
      removeListItem(value);
      return;
    }

    // Set editing state
    if (!editing.state && selected) {
      setEditing({ state: true, value: value });
      return;
    }

    // Remove editing state
    setEditing(prev => ({
      ...prev,
      state: false,
      value: null,
    }));

    setState(prev => ({ ...prev, input: "" }));
  }

  return (
    <div className={`input-view-wrap${required ? " required" : ""}`}>
      {props.children}

      {/* SELECT input modal */}
      <Portal isMounted={editModalOpen}>
        <Sheet
          title={t("secure.facility.settings.forms.custom_edits.custom_select_edit.005")}
          open={editModalOpen}
          onOk={() => props.saveInput(state.label, state.array, state.helpText, state.required)}
          onCancel={() => {
            closeEditModal();
            setState({
              label: label,
              helpText: helpText,
              array: array,
              required: required,
              input: "",
            });
          }}
          size="small"
          okText={t("secure.facility.settings.forms.custom_edits.custom_select_edit.006")}
        >
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  label={t("secure.facility.settings.forms.custom_edits.custom_select_edit.007")}
                  defaultValue={label}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setState(prev => ({ ...prev, label: e.target.value }))
                  }
                  className="input-edit-action"
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Input
                  label={t("secure.facility.settings.forms.custom_edits.custom_select_edit.008")}
                  defaultValue={helpText}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setState(prev => ({ ...prev, helpText: e.target.value }))
                  }
                  className="input-edit-action"
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <CustomSelect
                  label={t("secure.facility.settings.forms.custom_edits.custom_select_edit.009")}
                  value={state.input}
                  onChange={e => setState(prev => ({ ...prev, input: e.target.value }))}
                  suffixClick={() => addListItem()}
                  editing={editing.state}
                  inputRef={selectInputRef}
                  dropdownInline
                >
                  {/* TODO: Add drag and drop functionality to dropdown list */}
                  {state.array?.length > 0
                    ? state.array.map((value: string, index: number) => {
                        const selected =
                          (value === state.input && !editing.state) || (editing.state && value == editing.value);
                        return (
                          <React.Fragment key={value.concat(index.toString())}>
                            {selected ? (
                              <li
                                className={`selected${editing.state ? " editing" : ""}`}
                                onClick={() => selectListItem(selected, value)}
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    fontSize: "18px",
                                  }}
                                >
                                  <span>
                                    {value}
                                    {value === editing.value && (
                                      <>
                                        &nbsp;
                                        <Badge type="warning">
                                          {t("secure.facility.settings.forms.custom_edits.custom_select_edit.010")}
                                        </Badge>
                                      </>
                                    )}
                                  </span>
                                  {editing.state ? (
                                    <ButtonNew
                                      type="text"
                                      size="small"
                                      onClick={e => listIconClick(e, value, selected)}
                                    >
                                      <FontAwesomeIcon icon={["far", "ban"]} />
                                    </ButtonNew>
                                  ) : (
                                    <ButtonNew
                                      type="text"
                                      size="small"
                                      onClick={e => listIconClick(e, value, selected)}
                                    >
                                      <FontAwesomeIcon icon={["far", "edit"]} />
                                    </ButtonNew>
                                  )}
                                </span>
                              </li>
                            ) : (
                              <li
                                className={`${editing.state ? "editing" : ""}`}
                                onClick={() => selectListItem(selected, value)}
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    fontSize: "18px",
                                  }}
                                >
                                  <span>
                                    {value}
                                    {value === editing.value && (
                                      <>
                                        &nbsp;
                                        <Badge type="warning">
                                          {t("secure.facility.settings.forms.custom_edits.custom_select_edit.011")}
                                        </Badge>
                                      </>
                                    )}
                                  </span>
                                  {/* Not selected list item */}
                                  {!editing.state ? (
                                    <ButtonNew
                                      type="text"
                                      size="small"
                                      onClick={e => listIconClick(e, value, selected)}
                                    >
                                      <FontAwesomeIcon icon={["far", "xmark"]} />
                                    </ButtonNew>
                                  ) : null}
                                </span>
                              </li>
                            )}
                          </React.Fragment>
                        );
                      })
                    : null}
                </CustomSelect>
              </FormLayout.Group>
              <FormLayout.Group>
                <div />
                {/* <Select
                  label="Pre-filled choices"
                  defaultValue="empty"
                  onChange={(val: string) => console.log("attach prefill choices ", val)}
                  disabled
                >
                  <Option value="empty">None</Option>
                  {prefillOptions(t).map((val, index) => (
                    <Option key={val.id} value={val.id}>
                      {val.label}
                    </Option>
                  ))}
                </Select> */}
                <CheckboxBlock
                  label={t("secure.facility.settings.forms.custom_edits.custom_select_edit.012")}
                  checked={state.required}
                  className="input-view-required"
                  onChange={() => setState(prev => ({ ...prev, required: !prev.required }))}
                />
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Sheet>
      </Portal>
    </div>
  );
}

const prefillOptions = (t: TFunction<"translation", undefined>) => {
  return [
    {
      id: "days_of_week",
      label: "Days of week",
      value: weekdayNames,
    },
  ];
};
