import { useState } from "react";

export interface IRegisterDate {
  date: Date;
  time: string;
}

export default function useRegisterDates() {
  const [registerOpen, setRegisterOpen] = useState<IRegisterDate>(null);
  const [registerClose, setRegisterClose] = useState<IRegisterDate>(null);

  const [registerDateRangeValid, setRegisterDateRangeValid] = useState<boolean>(true);
  const [registerTimeRangeValid, setRegisterTimeRangeValid] = useState<boolean>(true);

  function isRegisterDateRangeValid(openDate: Date, closeDate: Date) {
    if (!openDate || !closeDate) {
      return true;
    } else {
      const openDateWithoutTime = new Date(openDate.getFullYear(), openDate.getMonth(), openDate.getDate());
      const closeDateWithoutTime = new Date(closeDate.getFullYear(), closeDate.getMonth(), closeDate.getDate());

      return openDateWithoutTime <= closeDateWithoutTime;
    }
  }

  function isRegisterTimeRangeValid(openDate: Date, openTime: string, closeDate: Date, closeTime: string) {
    if (!openDate || !openTime || !closeDate || !closeTime) {
      return true;
    } else {
      const openDateWithoutTime = new Date(openDate.getFullYear(), openDate.getMonth(), openDate.getDate());
      const closeDateWithoutTime = new Date(closeDate.getFullYear(), closeDate.getMonth(), closeDate.getDate());

      if (openDateWithoutTime.getTime() !== closeDateWithoutTime.getTime()) {
        return true;
      } else {
        const openTimeWithoutDate = new Date(`1970-01-01T${openTime}:00Z`);
        const closeTimeWithoutDate = new Date(`1970-01-01T${closeTime}:00Z`);

        return openTimeWithoutDate < closeTimeWithoutDate;
      }
    }
  }

  function updateRegisterOpen(open?: IRegisterDate) {
    if (!open) {
      setRegisterOpen(null);
      setRegisterDateRangeValid(true);
      setRegisterTimeRangeValid(true);
      return;
    }

    const updatedRegisterDateRangeValid = isRegisterDateRangeValid(open.date, registerClose?.date);
    const updatedRegisterTimeRangeValid = isRegisterTimeRangeValid(
      open.date,
      open.time,
      registerClose?.date,
      registerClose?.time,
    );

    setRegisterOpen(prev => ({
      ...prev,
      date: open.date,
      time: open.time,
    }));

    setRegisterDateRangeValid(updatedRegisterDateRangeValid);
    setRegisterTimeRangeValid(updatedRegisterTimeRangeValid);
  }

  function updateRegisterClose(close?: IRegisterDate) {
    if (!close) {
      setRegisterClose(null);
      setRegisterDateRangeValid(true);
      setRegisterTimeRangeValid(true);
      return;
    }

    const updatedRegisterDateRangeValid = isRegisterDateRangeValid(registerOpen?.date, close.date);
    const updatedRegisterTimeRangeValid = isRegisterTimeRangeValid(
      registerOpen?.date,
      registerOpen?.time,
      close.date,
      close.time,
    );

    setRegisterClose(prev => ({
      ...prev,
      date: close.date,
      time: close.time,
    }));

    setRegisterDateRangeValid(updatedRegisterDateRangeValid);
    setRegisterTimeRangeValid(updatedRegisterTimeRangeValid);
  }

  return {
    registerOpen,
    registerClose,
    registerDateRangeValid,
    registerTimeRangeValid,
    updateRegisterOpen,
    updateRegisterClose,
  };
}
