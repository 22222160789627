import { useTranslation } from "react-i18next";
import { CALENDAR_MONTHS } from "components/form/calendarField/helpers";
import { PriceMatchFilter } from "components/dropdown/dropFilterTypes";

// hardcoded filter values
const useOrderFilters = () => {
  const { t, i18n } = useTranslation();

  const dateFilters = [
    {
      id: "",
      label: t("secure.facility.order.order_filters.002"),
    },
  ];

  const financialStatusFilters = [
    {
      id: "paid",
      label: t("secure.facility.order.order_filters.005"),
    },
    {
      id: "partially_paid",
      label: t("secure.facility.order.order_filters.007"),
    },
    {
      id: "refunded",
      label: t("secure.facility.order.order_filters.008"),
    },
    {
      id: "partially_refunded",
      label: t("secure.facility.order.order_filters.021"),
    },
    {
      id: "unpaid",
      label: t("secure.facility.order.order_filters.006"),
    },
    {
      id: "voided",
      label: t("secure.facility.order.order_filters.022"),
    },
    {
      id: "cancelled",
      label: t("secure.facility.order.order_filters.023"),
    },
  ];

  const salesChannelFilters = [
    {
      id: "online_store",
      label: t("secure.facility.order.order_filters.016"),
    },
    {
      id: "pos",
      label: t("secure.facility.order.order_filters.015"),
    },
    {
      id: "booking_engine",
      label: t("secure.facility.order.order_filters.017"),
    },
  ];

  const priceMatchFilters: PriceMatchFilter[] = [
    {
      id: "total_equal_to",
      label: t("secure.facility.order.order_filters.018"),
    },
    {
      id: "total_greater_than",
      label: t("secure.facility.order.order_filters.019"),
    },
    {
      id: "total_less_than",
      label: t("secure.facility.order.order_filters.020"),
    },
  ];

  const creditCardFilters = [
    {
      id: "credit_card",
      label: t("secure.facility.order.order_filters.024"),
    },
  ];

  return {
    dateFilters,
    financialStatusFilters,
    salesChannelFilters,
    priceMatchFilters,
    creditCardFilters,
  };
};

const annualFiltersObj = () => {
  //calendar month obj to determine id value for API call.
  function calendarMonthObjGenerator(apiIdValue: "index" | "value") {
    const tempReturn = Object.keys(CALENDAR_MONTHS).map((month, index) => {
      const idIsIndex = apiIdValue.includes("index");
      return {
        id: idIsIndex ? index + 1 : month.toLowerCase(),
        label: month,
      };
    });
    return tempReturn;
  }

  // return ID can be either the months index or the months value
  const monthFilters = (apiIdValue: "index" | "value") => calendarMonthObjGenerator(apiIdValue);

  const yearFilters = () => {
    const endingYear = new Date().getFullYear() + 2;
    const years = [];
    for (let year = 2021; year <= endingYear; year++) {
      years.push({ id: year, label: String(year) });
    }
    return years;
  };

  return {
    monthFilters,
    yearFilters,
  };
};

export { useOrderFilters, annualFiltersObj };
