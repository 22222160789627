import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TeeSheetTabs from "elements/teesheet/TeeSheetTabs";

import * as FacilityActions from "redux/actions/facility";
import * as UIActions from "redux/actions/ui";
import * as TeeSheetActions from "redux/actions/teesheet";

const mapStateToProps = (state: any) => {
  return {
    facilityStore: { ...state.facilityStore },
    teeSheetStore: { ...state.teeSheetStore },
    authStore: { ...state.authStore },
    uiStore: { ...state.uiStore },
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    facilityActions: bindActionCreators(
      {
        ...FacilityActions,
      },
      dispatch,
    ),
    teeSheetActions: bindActionCreators(
      {
        ...TeeSheetActions,
      },
      dispatch,
    ),
    uiActions: bindActionCreators(
      {
        ...UIActions,
      },
      dispatch,
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeeSheetTabs);
