import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { TPrinter } from "redux/reducers/models/facility";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/facility/printer";

type TPostFacilityPrinter = {
  title: string;
  brand: string;
  ip_address: string;
  port: number;
};

type TPutFacilityPrinter = {
  id: number;
  title: string;
  brand: string;
  ip_address: string;
  port: number;
};

// GET A Single Printer
export async function GetPrinter(params: { id: string | number }, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<TPrinter>(baseUrl, params, useGlobalLoader, token);
}

// GET A Single Printer
export async function GetPrinterAll(params: { id?: string | number }, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<TPrinter[]>(baseUrl + "/all", params, useGlobalLoader, token);
}

// POST Printers
export async function PostPrinter(params: TPostFacilityPrinter, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

// Put Printers
export async function PutPrinter(params: TPutFacilityPrinter, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

// Delete Printer
export async function DeletePrinter(params: { id: string | number }, useGlobalLoader: boolean) {
  return await APIDelete(baseUrl, params, useGlobalLoader);
}
