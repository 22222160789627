import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { IFacility } from "redux/reducers/models/facility";
import { IVariant } from "redux/reducers/models/product";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";

type TFacilityInventoryTableProps = {
  mainFacilityId: number;
  facilities: IFacility[];
  variants: IVariant[];
  showAllFacilities: boolean;
  scrollable?: boolean;
};

export default function FacilityInventoryTable(props: TFacilityInventoryTableProps) {
  const { t } = useTranslation();

  const { mainFacilityId, facilities, variants, showAllFacilities, scrollable = "true" } = props;

  // Organize variant inventory levels with the associated facility name.
  const allInventories = useMemo(() => {
    return facilities.map(value => {
      return {
        id: value.id,
        facilityName: value.full_name,
        inventory: Array.prototype.concat
          .apply(
            [],
            variants.map(variant => {
              const newValue = variant.inventory_item.inventory_levels.filter(val => val.facility_id === value.id);
              return {
                variantTitle: variant.title, // Needed for sort to keep in the proper columns
                quantity: newValue[0]?.quantity_available,
              };
            }),
          )
          .sort((a: { variantTitle: string }, b: { variantTitle: string }) =>
            a.variantTitle > b.variantTitle ? 1 : a.variantTitle < b.variantTitle ? -1 : 0,
          ) as { variantTitle: string; quantity: number }[],
      };
    });
  }, [facilities, variants]);

  const mainInventories = allInventories.filter(val => val.id === mainFacilityId);
  const secondaryInventories = allInventories.filter(val => val.id !== mainFacilityId);

  const tableColumns = [
    "Facility", // TODO: Translation
    ...new Set(variants.map(variant => variant.title).sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))),
  ];

  return (
    <div className="overflow-wrapper">
      <DataTable
        columns={tableColumns.map((val, index) => {
          return {
            label: val,
            width: index === 0 ? "20%" : "auto",
            justify: index !== 0 ? "right" : undefined,
          };
        })}
      >
        {mainInventories.map(display => (
          <tr key={display.id} className={"first-row"}>
            <td>{display.facilityName}</td>
            {display.inventory.map((level, index) => (
              <td key={index} align="right">
                {level.quantity ?? 0}
              </td>
            ))}
          </tr>
        ))}

        {showAllFacilities &&
          secondaryInventories.map(display => (
            <tr key={display.id}>
              <td>{display.facilityName}</td>
              {display.inventory.map((level, index) => (
                <td key={index} align="right">
                  {level.quantity ?? 0}
                </td>
              ))}
            </tr>
          ))}
      </DataTable>
    </div>
  );
}
