import { APIGet, APIPost, APIPut, APIDelete, IAPIResponse } from "api/protocols";
import { IProduct } from "redux/reducers/models/product";

const apiVersion = "2024-04";
const adminType = "master-admin";
const baseFolderUrl = "/" + apiVersion + "/" + adminType + "/product/folder";

export interface IFolder {
  id: number;
  facility_id: number;
  title: string;
  default: boolean;
  register_id: number;
  register_group_id: number;
  register_group_title: string;
  position: number;
  updated_at: string;
  products?: IProduct[];
}

interface IGetFolder {
  id?: number;
  register_group_id?: number;
  facility_id: number;
  extended: boolean;
}

interface IGetFolderResponse extends IAPIResponse {
  data: IFolder[];
}

interface IPostFolder {
  title?: string;
  default?: boolean;
  register_group_id?: number;
  facility_id: number;
}

interface IPostFolderResponse {
  status: number;
  data: IFolder;
}

interface IPutFolder {
  id?: number;
  title?: string;
  default?: boolean;
  register_group_id?: number;
  folders?: Array<{
    id: number;
    position: number;
    register_group_id: number;
  }>;
  facility_id: number;
}

interface IPutFolderResponse extends IAPIResponse {
  data: IFolder | IFolder[];
}

interface IDeleteFolder {
  id: number;
  title?: string;
  default?: boolean;
  register_group_id?: number;
  facility_id: number;
}

interface IDeleteFolderResponse {
  status: number;
  data: IFolder;
}

interface IDuplicateFolder {
  folder_id: number;
  facility_id: number;
}

interface IDuplicateFolderResponse {
  status: number;
  data: {
    facility_id: number;
    title: string;
    description: string;
    deleted_at: string;
    default: boolean;
    register_id: number;
    register_group_id: number;
    updated_at: string;
    created_at: string;
    id: number;
  };
}

export interface IPostFolderProduct {
  id: number;
  position: number;
  button_color: string;
}

interface IPostFolderProducts {
  folder_id?: number;
  products?: IPostFolderProduct[];
  facility_id: number;
  client_id: number;
}

export async function GetFolder(params: IGetFolder, useGlobalLoader: boolean): Promise<IGetFolderResponse> {
  return await APIGet(baseFolderUrl, params, useGlobalLoader);
}

export async function PostFolder(params: IPostFolder, useGlobalLoader: boolean): Promise<IPostFolderResponse> {
  return await APIPost(baseFolderUrl, params, useGlobalLoader);
}

export async function PutFolder(params: IPutFolder, useGlobalLoader: boolean): Promise<IPutFolderResponse> {
  return await APIPut(baseFolderUrl, params, useGlobalLoader);
}

export async function DeleteFolder(params: IDeleteFolder, useGlobalLoader: boolean): Promise<IDeleteFolderResponse> {
  return await APIDelete(baseFolderUrl, params, useGlobalLoader);
}

export async function DuplicateFolder(
  params: IDuplicateFolder,
  useGlobalLoader: boolean,
): Promise<IDuplicateFolderResponse> {
  return await APIPost(baseFolderUrl + "/duplicate", params, useGlobalLoader);
}

export async function PostFolderProducts(params: IPostFolderProducts, useGlobalLoader: boolean) {
  return await APIPost(baseFolderUrl + "/product", params, useGlobalLoader);
}
