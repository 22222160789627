import { IOptions } from "pages/secure/facility/Admin";

/***
Child suboptions can be passed to created futher settings options levels. Add subOptions to
the subOption of the parent as well as prop secondOptionHeader for styling purposes. 

label: string
url: string
subOptions?: Array< {
                    label: string;
                    url: string;
                    subOptions?: Array
                    secondOptionHeader?: boolean; }>
*/

const options: IOptions[] = [
  {
    label: "Client",
  },

  {
    label: "General",
    url: "/admin/settings/client-settings/details",
    subOptions: [
      {
        label: "Customer Types",
        url: "/admin/settings/client-settings/customer_types",
      },
      {
        label: "Details",
        url: "/admin/settings/client-settings/details",
      },
    ],
  },
  {
    label: "Facilities",
    url: "/admin/settings/client-settings/facility",
    subOptions: [
      {
        label: "Details",
        url: "/admin/settings/client-settings/facility-details",
      },
      {
        label: "Permissions",
        url: "/admin/settings/client-settings/facility-permissions",
      },
    ],
  },
  {
    label: "Products",
    url: "/admin/settings/client-settings/products",
    subOptions: [
      {
        label: "Types",
        url: "/admin/settings/client-settings/products/types",
      },
    ],
  },
  {
    label: "Staff Accounts",
    url: "/admin/settings/client-settings/staff",
  },
  {
    label: "Vendors",
    url: "/admin/settings/client-settings/vendors",
  },

  {
    label: "Accounting",
    url: "",
    subOptions: [
      {
        label: "Accounting References",
        url: "/admin/settings/accounting_references",
      },
      {
        label: "Accounts",
        url: "/admin/settings/accounts",
      },
    ],
  },

  {
    label: "Facility",
  },
  {
    label: "Registers",
    url: "/admin/settings/facility-settings/registers",
    subOptions: [
      {
        label: "Cash Out",
        url: "/admin/settings/facility-settings/cash-out",
      },
    ],
  },
  {
    label: "Tee Sheets",
    url: "/admin/settings/facility-settings/tee-sheet",
    subOptions: [
      {
        label: "Pricing Sheets",
        url: "/admin/settings/facility-settings/tee-sheet/pricing-sheet",
      },
      {
        label: "Check In Configuration",
        url: "/admin/settings/facility-settings/tee-sheet-/check-in-config",
      },
    ],
  },
  {
    label: "Payments",
    url: "/admin/settings/facility-settings/payments",
    subOptions: [
      {
        label: "Payouts",
        url: "/admin/settings/facility-settings/payouts",
      },
    ],
  },
];

export default options;
