import React, { useEffect, useState } from "react";
import axios, { CancelToken } from "axios";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { GetProduct as GetFacilityProduct } from "api/rpc/2022-09/facilityAdmin/product/product";
import { GetProduct as GetClientProduct } from "api/rpc/2022-09/clientAdmin/product/product";

import { IProduct } from "redux/reducers/models/product";

import Sheet from "components/sheet/Sheet";
import Spin from "components/spin/spin";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";
import Search from "components/search/Search";
import { useAppDispatch } from "hooks/redux";
import { searchHistoryClear } from "redux/actions/searchBars/searchHistory";
import { dequeue, enqueue } from "redux/actions/ui";

type AttachProductAsComboProps = {
  isVisible: boolean;
  onCancel: () => void;
  onOk: (variantId: number) => void;
  userLevel: "facility" | "client";
  currentlyAddedProducts?: Record<string, any>[];
};

/** Handles Adding a Product to another Product. */
export default function AttachProductAsCombo(props: AttachProductAsComboProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [filterState, setFilterState] = useState({
    search: "",
    sort: "alpha",
  });

  const [allProducts, setAllProducts] = useState<IProduct[]>(null);

  // Define filter presets here
  const [filters, setFilters] = useState({
    search: undefined,
    searching: true,
  });

  // const sortedProducts = allProducts
  //   // ?.filter((product: any) => Boolean(product.title.toLowerCase().includes(filterState.search.toLowerCase())))
  //   ?.sort((firstProd: any, secondProd: any) => {
  //     if (filterState.sort === "alpha") {
  //       if (firstProd.title > secondProd.title) {
  //         return 1;
  //       }
  //       if (firstProd.title < secondProd.title) {
  //         return -1;
  //       }
  //       return 0;
  //     }
  //   });

  useEffect(() => {
    const source = axios.CancelToken.source();

    void loadAllProducts(props.userLevel, source.token);

    return () => {
      source.cancel();
    };
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();
    setAllProducts(null);

    if (filters.search !== undefined) {
      switch (props.userLevel) {
        case "facility": {
          if (filters.search !== undefined) {
            void searchFacilityProducts(source.token);
          }
          break;
        }
        case "client": {
          if (filters.search !== undefined) {
            void searchClientProducts(source.token);
          }

          break;
        }
        default: {
          return;
        }
      }
    }

    setFilters(prevState => ({ ...prevState, searching: false }));

    return () => {
      source.cancel();
    };
  }, [filters.search]);

  async function loadAllProducts(userLevel: "facility" | "client", token: CancelToken) {
    switch (userLevel) {
      case "facility": {
        const { status, message, data } = await GetFacilityProduct(
          { extended: true, extended_variants: true, inventory: true },
          false,
          token,
        );
        if (status === StatusCode.OK) {
          setAllProducts(data);
        }
        break;
      }
      case "client": {
        const { status, message, data } = await GetClientProduct(
          { extended: true, extended_variants: true, inventory: true },
          false,
          token,
        );
        if (status === StatusCode.OK) {
          setAllProducts(data);
        }
        break;
      }
      default: {
        return;
      }
    }

    setFilters(prevState => ({ ...prevState, searching: false }));
  }

  async function searchFacilityProducts(token: CancelToken) {
    const { status, message, data } = await GetFacilityProduct(
      { extended: true, extended_variants: true, inventory: true, search: filters.search },
      false,
      token,
    );

    if (status === StatusCode.OK) {
      setAllProducts(data);
    }
  }

  async function searchClientProducts(token: CancelToken) {
    const { status, message, data } = await GetClientProduct(
      { extended: true, extended_variants: true, inventory: true, search: filters.search },
      false,
      token,
    );

    if (status === StatusCode.OK) {
      setAllProducts(data);
    }
  }

  function onCancel() {
    setFilters(prevState => ({ ...prevState, search: undefined }));
    dispatch(searchHistoryClear("combo-products-search"));
    props.onCancel();
  }

  return (
    <Sheet
      open={props.isVisible}
      title={t("secure.facility.product.product.076")}
      size="medium"
      closable
      onCancel={onCancel}
      cancelText={t("secure.facility.product.product.077")}
      backDropCancel={false}
    >
      <div className="flex-grow mb-4 pt-4">
        <Search
          searchCallback={searchValue => setFilters(prev => ({ ...prev, search: searchValue }))}
          historyKey="combo-products-search"
          placeholder="Search products..."
        />
      </div>

      <DataTable
        columns={[
          { label: "", width: "75%" },
          { label: "", width: "25%" },
        ]}
        hideHeader
        loading={!allProducts}
      >
        {allProducts
          ?.sort((firstProd: any, secondProd: any) => {
            if (filterState.sort === "alpha") {
              if (firstProd.title > secondProd.title) {
                return 1;
              }
              if (firstProd.title < secondProd.title) {
                return -1;
              }
              return 0;
            }
          })
          ?.map((product: IProduct) => {
            const disableClick =
              product.variant_count > 1 || props.currentlyAddedProducts?.some(val => val.product_id === product.id);
            return (
              <React.Fragment key={`product_${product.id}`}>
                {/* Product only clickable if variant_count is 0 */}
                <tr
                  key={product.id}
                  onClick={e => (disableClick ? undefined : props.onOk(product.variants[0].id))}
                  className={`${disableClick ? "" : "clickable"}`}
                >
                  <td>
                    <p style={{ textAlign: "left", fontSize: "14px", fontWeight: 600 }}>{product.title}</p>
                  </td>
                  {product.variants.length <= 1 && !disableClick ? (
                    <td style={{ borderColor: "none" }}>
                      <p>{t("secure.facility.product.product.079")}</p>
                    </td>
                  ) : (
                    <td></td>
                  )}
                </tr>
                {/* If variants present, display as clickable row instead */}
                {product.variants.length > 1
                  ? product.variants.map(variant => (
                      <tr key={variant.id} className="clickable" onClick={e => props.onOk(variant.id)}>
                        <td>
                          <p style={{ fontStyle: "italic", display: "block", marginLeft: "24px" }}>{variant.title}</p>
                        </td>
                        <td style={{ borderColor: "none" }}>
                          <p>{t("secure.facility.product.product.079")}</p>
                        </td>
                      </tr>
                    ))
                  : null}
              </React.Fragment>
            );
          })}
      </DataTable>
    </Sheet>
  );
}
