import { APIGet, APIPost, APIPut, APIDelete, StatusCode } from "api/protocols";
import { CancelToken } from "axios";
import { IOrderTransaction } from "redux/reducers/models/order";

const apiVersion = "2024-04";
const adminType = "client-admin";
const baseOrderUrl = "/" + apiVersion + "/" + adminType + "/order";

interface IGetOrder {
  id?: number;
  search?: string;
  customer_id?: number;
  extended?: boolean;
  refund?: boolean;
  limit?: number;
  offset?: number;
  filters?: Array<Record<string, any>>;
}

// GET Order
// Retrieve a list of orders for a facility
// extended: boolean
export async function GetOrder(params: IGetOrder, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseOrderUrl, params, useGlobalLoader, token);
}

// PUT Order
// Update some attributes on an order
export async function PutOrder(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseOrderUrl, params, useGlobalLoader);
}

export async function GetReceipt(params: { id: number; tee_time_id?: number }, useGlobalLoader?: boolean) {
  return await APIGet(baseOrderUrl + "/receipt", params, useGlobalLoader);
}
