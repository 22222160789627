import { TeesheetActionTypes } from "redux/actions/actionTypes";
import { ISlot, ITeeTime } from "./models/teetime";

export interface ITeeSheetAction {
  type: TeesheetActionTypes;
  payload: Partial<ITeeSheetState>;
}

export interface ITeeSheetState {
  teetimes: ITeeTime[] | null;
  selectedDate: Date;
  selectedMenu: string;
  selectedCart: any;
  selectedTeeTimeSlot: ISlot;
  selectedTeeTime: ITeeTime;
  temporaryBooking: any;
}

const initialState: ITeeSheetState = {
  teetimes: null,
  selectedDate: null,
  selectedMenu: null,
  selectedCart: null,
  selectedTeeTimeSlot: null,
  selectedTeeTime: null,
  temporaryBooking: {},
};

export default function TeeSheetReducer(state = initialState, action: ITeeSheetAction) {
  switch (action.type) {
    case TeesheetActionTypes.LOAD_TEETIMES_SUCCESS_FACILITY:
      return { ...state, ...action.payload };

    case TeesheetActionTypes.SELECT_DATE_FACILITY:
      return { ...state, selectedDate: action.payload };

    case TeesheetActionTypes.UPDATE_SELECTED_MENU_FACILITY:
      return {
        ...state,
        ...action.payload,
      };
    case TeesheetActionTypes.UPDATE_SELECTED_CART_FACILITY:
      return {
        ...state,
        ...action.payload,
      };
    case TeesheetActionTypes.UPDATE_SELECTED_TEETIME_SUCCESS_FACILITY:
      return {
        ...state,
        ...action.payload,
      };
    case TeesheetActionTypes.UPDATE_BOOKING_TEMPORARY_FACILITY:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
