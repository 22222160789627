import { APIDelete, APIGet, APIPost, APIPut } from "api/protocols";
import { CancelToken } from "axios";
import { DynamicPricingFactorValueType } from "pages/secure/facility/settings/teesheets/dynamicPricing/dynamicPricingEdit/dynamicPricingNodes/DynamicPricingFactorGroupNode";
import {
  IDynamicPricingFactor,
  IDynamicPricingGroup,
  IDynamicPricingTemplate,
} from "redux/reducers/models/dynamicPricing";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/tee-sheet/dynamic-pricing";

interface IGetDynamicPricingTemplates {
  id?: number;
}

interface IPostDynamicPricingTemplate {
  title: string;
}

interface IPutDynamicPricingTemplate {
  id: number;
  title: string;
  active: boolean;
}

interface IDeleteDynamicPricingTemplate {
  template_id: number;
}

interface IGetDynamicPricingGroups {
  template_id: number;
  id?: number;
}

export interface INewDynamicPricingGroup {
  title: string;
  token: string;
  order: number;
  value_type: DynamicPricingFactorValueType;
  incoming_branching_limit: number;
}

interface IPostDynamicPricingGroups {
  template_id: number;
  groups: INewDynamicPricingGroup[];
}

interface IGetDynamicPricingFactors {
  template_id: number;
}

export interface IUpdatedDynamicPricingFactor {
  id: number;
  dynamic_pricing_group_id: number;
  dynamic_pricing_group_token: string;
  token: string;
  value_type: DynamicPricingFactorValueType;
  range_start: string;
  range_end: string;
  value: string;
  y_position: number;
  path: string;
}

interface IPutDynamicPricingFactors {
  template_id: number;
  factors: IUpdatedDynamicPricingFactor[];
}

export interface INewDynamicPricingFactor {
  dynamic_pricing_group_id: number;
  dynamic_pricing_group_token: string;
  token: string;
  value_type: DynamicPricingFactorValueType;
  range_start: string;
  range_end: string;
  value: string;
  y_position: number;
  path: string;
}

interface IPostDynamicPricingFactors {
  template_id: number;
  factors: INewDynamicPricingFactor[];
}

interface IDeleteDynamicPricingFactors {
  template_id: number;
  factors: Array<{ id: number }>;
}

export function GetDynamicPricingTemplates(
  params: IGetDynamicPricingTemplates,
  useGlobalLoader: boolean,
  cancelToken?: CancelToken,
) {
  return APIGet<IDynamicPricingTemplate[]>(baseUrl + "/template", params, useGlobalLoader, cancelToken);
}

export function PostDynamicPricingTemplate(params: IPostDynamicPricingTemplate, useGlobalLoader: boolean) {
  return APIPost<IDynamicPricingTemplate>(baseUrl + "/template", params, useGlobalLoader);
}

export function PutDynamicPricingTemplate(params: IPutDynamicPricingTemplate, useGlobalLoader: boolean) {
  return APIPut<IDynamicPricingTemplate>(baseUrl + "/template", params, useGlobalLoader);
}

export function DeleteDynamicPricingTemplate(params: IDeleteDynamicPricingTemplate, useGlobalLoader: boolean) {
  return APIDelete(baseUrl + "/template", params, useGlobalLoader);
}

export function GetDynamicPricingGroups(params: IGetDynamicPricingGroups, useGlobalLoader: boolean) {
  return APIGet<IDynamicPricingGroup[]>(baseUrl + "/group", params, useGlobalLoader);
}

export function PostDynamicPricingGroups(params: IPostDynamicPricingGroups, useGlobalLoader: boolean) {
  return APIPost<IDynamicPricingGroup[]>(baseUrl + "/group", params, useGlobalLoader);
}

export function GetDynamicPricingFactors(params: IGetDynamicPricingFactors, useGlobalLoader: boolean) {
  return APIGet<IDynamicPricingFactor[]>(baseUrl + "/factor", params, useGlobalLoader);
}

export function PutDynamicPricingFactors(params: IPutDynamicPricingFactors, useGlobalLoader: boolean) {
  return APIPut<IDynamicPricingFactor[]>(baseUrl + "/factor", params, useGlobalLoader);
}

export function PostDynamicPricingFactors(params: IPostDynamicPricingFactors, useGlobalLoader: boolean) {
  return APIPost<IDynamicPricingFactor[]>(baseUrl + "/factor", params, useGlobalLoader);
}

export function DeleteDynamicPricingFactors(params: IDeleteDynamicPricingFactors, useGlobalLoader: boolean) {
  return APIDelete(baseUrl + "/factor", params, useGlobalLoader);
}
