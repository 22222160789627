import React, { useEffect, useState, useCallback } from "react";
import { useHistory, Link } from "react-router-dom";
import { useParams } from "react-router";

import { GetLeague } from "api/rpc/facilityAdmin/league/league";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import { ButtonNew as Button } from "components/buttonNew";
import ResourceList from "components/resourceList/ResourceList";
import ResourceItem from "components/resourceList/ResourceItem";
import TextStyle from "components/text/textStyle/TextStyle";
import Stack from "components/stack/Stack";
import Checkbox from "components/form/checkbox/Checkbox";
import { GetTournament } from "api/rpc/clientAdmin/tournament/tournament";

export default function TournamentScores(props: any) {
  const history = useHistory();
  const { tournamentId }: any = useParams();
  console.log(tournamentId);
  const [state, setState] = useState({
    tournament: null,
  });

  useEffect(() => {
    void loadTournament();
  }, []);

  async function loadTournament() {
    const params = {
      id: tournamentId,
    };

    const tournamentRes = await GetTournament(params, false);
    console.log(tournamentRes);
    if (tournamentRes.status === StatusCode.OK) {
      setState(prevState => ({
        ...prevState,
        tournament: tournamentRes.data[0],
      }));
    }
  }

  return (
    <Page title={state.tournament?.name} narrow>
      <Card>Testing Tournament Scores</Card>
    </Page>
  );
}
