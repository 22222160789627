import React, { useEffect, useState } from "react";
import axios, { CancelToken } from "axios";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StatusCode } from "api/protocols";
import { IBooking } from "redux/reducers/models/teetime";
import { capitalize, convertTime, valueToString } from "helpers/Helpers";
import Spin from "components/spin/spin";
import { ButtonNew as Button } from "components/buttonNew";
import { useHistory, useParams } from "react-router";
import { Badge } from "components/badge/Badge";
import { useTranslation } from "react-i18next";
import { ClientPortalNavigation } from "../ClientPortalNavigation";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { showError } from "redux/actions/ui";
import "./clientPortalBookingList.scss";
import Callout from "components/callout/Callout";
import { GetBookingParticipant } from "api/rpc/2024-04/customer/booking";
import { useClientPortal } from "../useClientPortal";

interface IFilterState {
  limit: number;
  offset: number;
}

interface IParams {
  clientShortName: string;
}

export default function ClientPortalBookingsList() {
  const [bookings, setBookings] = useState<IBooking[]>(null);
  const [filter, setFilter] = useState<IFilterState>({
    limit: 15,
    offset: 0,
  });
  const { clientShortName } = useParams<IParams>();
  const dispatch = useAppDispatch();
  const { clientPortalStore } = useAppSelector(store => store);
  const history = useHistory();
  const HOME_URL = useClientPortal().getHomePageUrl();

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (clientPortalStore?.client) {
      void getBookings(source.token);
    } else {
      history.push(HOME_URL);
    }

    return () => {
      source.cancel("Cancelled");
    };
  }, [filter]);

  async function getBookings(token: CancelToken) {
    //display loader
    if (bookings !== null) {
      setBookings(null);
    }
    const bookingsRes = await GetBookingParticipant(
      { ...filter, client_id: clientPortalStore?.client?.id, extended_booking: true },
      false,
      token,
    );

    if (bookingsRes.status !== StatusCode.OK) {
      if (token.reason) {
        return;
      }
      dispatch(showError("Error getting bookings"));
      setBookings([]);
      return;
    }
    setBookings(bookingsRes.data);
  }

  function handleViewBooking(id: string) {
    if (id) {
      window.open(`/tee-on/portal/bookings/${id}/view`);
    }
  }

  return (
    <div className="client-portal-booking-list">
      <ClientPortalNavigation showNavOptions />
      <div className="client-portal-booking-list-container">
        <h1 className="bookings-header-text">Bookings</h1>

        {bookings ? (
          bookings?.length > 0 ? (
            <div className="bookings-body-container">
              {bookings
                .filter(filteredBooking => !!filteredBooking.turn === false)
                .map((booking, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => handleViewBooking(booking?.booking?.id)}
                      className={"bookings-container"}
                    >
                      <div className="course-name">
                        {booking.booking?.tee_time?.facility ? (
                          <span>{booking.booking?.tee_time?.facility?.long_name}</span>
                        ) : (
                          <span>N/A</span>
                        )}
                      </div>

                      <div className="date-time-container">
                        <div className="date-time">{moment(booking.tee_time?.date).format("MMMM DD YYYY")}</div>

                        <div className="date-time">
                          {booking.tee_time ? (
                            <span>{convertTime(booking?.tee_time?.start_time)}</span>
                          ) : (
                            <span>N/A</span>
                          )}
                        </div>
                      </div>

                      <div className="status-container">
                        <div className="bookings-quantity">
                          <FontAwesomeIcon icon={["fas", "user"]} />

                          <span>
                            {booking.booking?.quantity} {booking.booking.quantity === 1 ? "Player" : "Players"}
                          </span>
                        </div>

                        <div className="booking-status-container">
                          {booking.status !== "cancelled" ? (
                            booking?.check_in_status !== "unchecked" && (
                              <Badge size="small" type={handleBadgeType(booking?.check_in_status)}>
                                {valueToString(booking?.check_in_status)}
                              </Badge>
                            )
                          ) : (
                            <Badge size="small" type="error">
                              {valueToString(booking?.booking?.status)}
                            </Badge>
                          )}

                          {!!booking?.booking?.order_id && (
                            <Badge
                              type="success"
                              size="small"
                              iconSize="medium"
                              iconLeft={<FontAwesomeIcon icon={["far", "dollar-sign"]} />}
                            >
                              Pre Paid
                            </Badge>
                          )}
                          {!!booking?.booking?.booking_fee_order_id && (
                            <Badge
                              type="success"
                              size="small"
                              iconSize="medium"
                              iconLeft={<FontAwesomeIcon icon={["far", "dollar-sign"]} />}
                            >
                              Booking Fee Paid
                            </Badge>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            <Callout
              type={"info"}
              title={"No Bookings Found"}
              content="There are no bookings found under this user. Make a new booking to view it here."
            />
          )
        ) : (
          <span style={{ display: "flex", justifyContent: "center" }}>
            <Spin />
          </span>
        )}

        {bookings && bookings?.length > 0 && (
          <div className="bookings-footer">
            <div>
              <Button
                size="small"
                type="secondary"
                onClick={() => setFilter(prev => ({ ...prev, offset: prev.offset - prev.limit }))}
                disabled={!bookings || bookings?.length === 0 || !(filter.limit <= filter.offset)}
              >
                <FontAwesomeIcon icon={"arrow-left"} />
                &nbsp;
                {"Prev"}
              </Button>
            </div>
            <div>
              <Button
                size="small"
                type="secondary"
                onClick={() => setFilter(prev => ({ ...prev, offset: prev.offset + prev.limit }))}
                disabled={!bookings || bookings?.length === 0 || !(bookings?.length === filter.limit)}
              >
                {"Next"}
                &nbsp;
                <FontAwesomeIcon icon={"arrow-right"} />
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function handleBadgeType(checkInStatus: string) {
  switch (checkInStatus) {
    case "checked":
      return "public";
    case "no_show":
      return "error";
    default:
      return "gray";
  }
}
