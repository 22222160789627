import { StatusCode } from "api/protocols";
import { GetFacility } from "api/rpc/guest/facility";
import { GetProducts } from "api/rpc/guest/product";
import ProductList from "containers/OnlineStore/ProductList";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { IFacility } from "../models/facility";
import { IProduct } from "../models/product";
import StoreHeader from "./StoreHeader/StoreHeader";
import { ICartState } from "redux/reducers/customer/cart";
import { ICartActions } from "redux/actions/customer/cart";
import { StorePage } from "./StorePage/StorePage";
import { ICart } from "redux/reducers/customer/models/cart";
import { IUIActions } from "redux/actions/ui";
import { useTranslation } from "react-i18next";

interface IHomePageProps {
  cartStore: ICartState;
  cartActions: ICartActions;
  uiActions: IUIActions;
}

interface IHomePage {
  facility: IFacility;
  products: IProduct[];
  loaded: boolean;
}

export function getOnlineStoreCartTokenName(facilityShortName: string) {
  return `${facilityShortName}_customer_cart_token`;
}

export function getOnlineStoreCartToken(facilityShortName: string) {
  return localStorage.getItem(getOnlineStoreCartTokenName(facilityShortName));
}

export default function HomePage(props: IHomePageProps) {
  const { facilityShortName } = useParams<{ facilityShortName: string }>();
  const { t, i18n } = useTranslation();
  const { cartStore, cartActions, uiActions } = props;

  const [homePageState, setHomePageState] = useState<IHomePage>({
    facility: undefined,
    products: undefined,
    loaded: false,
  });

  useEffect(() => {
    void loadHomePage();
  }, []);

  async function loadHomePage() {
    uiActions.enqueue();

    try {
      const facilityRes = await GetFacility({ short_name: facilityShortName }, false);
      const facilityId = facilityRes?.data?.[0]?.id;

      if (facilityRes.status !== StatusCode.OK || facilityId === undefined) {
        uiActions.showError(t("guest.online_store.home_page.001"));
        uiActions.dequeue();
        return;
      }

      let cart: ICart = undefined;

      if (getOnlineStoreCartToken(facilityShortName)) {
        try {
          const getCartPromise: Promise<ICart> = cartStore?.isLoaded
            ? Promise.resolve(cartStore?.cart)
            : (cartActions.loadCart(
                {
                  facility_id: facilityId,
                  tokenName: getOnlineStoreCartTokenName(facilityShortName),
                  token: getOnlineStoreCartToken(facilityShortName),
                },
                false,
              ) as unknown as Promise<ICart>);

          cart = await getCartPromise;
        } catch {
          uiActions.showError(t("guest.online_store.home_page.002"));
          uiActions.dequeue();
          return;
        }
      }

      if (cart?.status === "complete") {
        cartActions.cartClear({ tokenName: getOnlineStoreCartTokenName(facilityShortName) });
      }

      const productsRes = await GetProducts(
        { sales_channel_type: "online_store", facility_id: facilityId, inventory: true },
        false,
      );

      if (productsRes?.status !== StatusCode.OK) {
        uiActions.showError(t("guest.online_store.home_page.003"));
        uiActions.dequeue();
        return;
      }

      const filteredProducts = productsRes?.data?.filter(product => {
        const foundCollect = product?.collect?.find(collect => collect?.facility_id === facilityId);
        // Filter out the products that cannot be sold
        if (foundCollect && !foundCollect?.cannot_be_sold) {
          return true;
        }
        return false;
      });

      setHomePageState(prevState => {
        return {
          ...prevState,
          products: filteredProducts,
          facility: facilityRes.data[0],
          loaded: true,
        };
      });

      uiActions.dequeue();
    } catch {
      uiActions.dequeue();
    }
  }

  return (
    <>
      {homePageState.loaded ? (
        <div>
          <StoreHeader
            facilityShortName={facilityShortName}
            facilityLogoSource={homePageState.facility.logo_source}
            shoppingBagHasItems={cartStore.cart?.line_items?.length > 0}
          />
          <StorePage>
            <ProductList
              products={homePageState.products}
              facilityShortName={facilityShortName}
              facilityId={homePageState.facility.id}
            />
          </StorePage>
        </div>
      ) : null}
    </>
  );
}
