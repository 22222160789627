import React, { useEffect, useState, useCallback, ChangeEvent } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Input from "components/form/input/Input";
import Toggle from "components/form/toggle/Toggle";
import { Select } from "components/select/index";

import { PostDiscount } from "api/rpc/2024-04/masterAdmin/product/discount";
import { useTranslation } from "react-i18next";
import { StatusCode } from "api/protocols";
import FormLayout from "components/form/FormLayout";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { showError, showSuccess } from "redux/actions/ui";

interface IDiscountState {
  title: string;
  application: any;
  value_type: any;
  value: number;
  custom: boolean;
  tracked: boolean;
}

export default function DiscountNew() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { masterFacilityStore } = useAppSelector(store => store);
  const dispatch = useAppDispatch();
  const [state, setState] = useState<IDiscountState>({
    title: "",
    application: null,
    value_type: null,
    value: 0,
    custom: false,
    tracked: false,
  });

  const { Option } = Select;

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setState({ ...state, [id]: value });
  }

  function handleDropdownChange(value: string) {
    setState({ ...state, value_type: value });
  }

  function handleToggleChange(event: ChangeEvent<HTMLInputElement>) {
    const id = event?.target?.id;
    const checked = event?.target?.checked;

    if (id === undefined || checked === undefined) {
      return;
    }

    setState({ ...state, [id]: checked });
  }

  async function createDiscount() {
    const discountRes = await PostDiscount(
      {
        title: state.title,
        value_type: state.value_type,
        application: "line_item",
        value: state.value,
        custom: state.custom,
        tracked: state.tracked,
        facility_id: masterFacilityStore.facility.id,
      },
      true,
    );

    if (discountRes.status !== StatusCode.OK) {
      dispatch(showError("Can't Create Discount"));
      return;
    }

    dispatch(showSuccess("Discount Created Successfully"));
    history.push("/admin/facility/settings/discount");
  }

  const primaryAction = {
    content: "Create",
    action: createDiscount,
    disabled: !masterFacilityStore.facility,
  };

  return (
    <Page
      title={"New Discount"}
      subtitle={masterFacilityStore.facility ? masterFacilityStore.facility.long_name : "No Facility Selected"}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: "Back to Discounts",
          url: "/admin/facility/settings/discount",
        },
      ]}
    >
      {masterFacilityStore.facility && (
        <Card>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={state.title}
                  label={"Title"}
                  id="title"
                  onChange={handleInputChange}
                  placeholder={"Title"}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Select
                  label="Value Type"
                  className="flex justify-center align-center w-full h-10 position-relative z-20 text-black text-medium appearance-none border-none focus:outline-none placeholder-gray-200"
                  placeholder="Value Type"
                  onChange={(value: any) => handleDropdownChange(value)}
                >
                  <Option value={"percent"}>Percent</Option>
                  <Option value={"fixed_amount"}>Fixed Amount</Option>
                </Select>
                <Input
                  value={state.value}
                  label={"Value"}
                  id="value"
                  onChange={handleInputChange}
                  placeholder={"Value"}
                  type="number"
                  prefix={state.value_type === "percent" ? "%" : "$"}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Toggle
                  labelRight="Customizable"
                  id="custom"
                  onChange={handleToggleChange}
                  checked={state.custom}
                  size="medium"
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>
      )}
    </Page>
  );
}
