import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseLeagueUrl = "/" + apiVersion + "/" + adminType + "/league";

export type TGetLeagueRegistrationFee = {
  id?: string | number;
  league_id?: string | number;
  variant_id?: string | number;
};

export type TPostLeagueRegistrationFee = {
  league_id: string | number;
  variant_id: string | number;
  players?: number;
  type?: string; // defaults to registration_fee
  description?: string;
  price: number;
};

export async function GetLeagueRegistrationFees(
  params: TGetLeagueRegistrationFee,
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseLeagueUrl + "/registration-fee", params, useGlobalLoader, token);
}

export async function PostLeagueRegistrationFee(params: TPostLeagueRegistrationFee, useGlobalLoader: boolean) {
  return await APIPost(baseLeagueUrl + "/registration-fee", params, useGlobalLoader);
}

export async function DeleteLeagueRegistrationFee(
  params: { registration_fee_id: string | number },
  useGlobalLoader: boolean,
) {
  return await APIDelete(baseLeagueUrl + "/registration-fee", params, useGlobalLoader);
}
