import React, { useEffect, useState } from "react";
import classNames from "classnames";
import "components/form/form.scss";

// Available Props
// condensed

// Note: can only fit 5 fields horizontally using 'ui-form-layout_condensed'

const Group = (props: any) => (
  <>
    {props.title ? (
      <div className="ui-form-group-title">
        <p className="text-md text-semibold text-gray-500">{props.title}</p>
      </div>
    ) : null}

    <div
      className="ui-form-layout-group"
      style={{
        alignItems: props.alignStart ? "flex-start" : props.alignCenter ? "center" : null,
        verticalAlign: props.alignBase ? "baseline" : null,
      }}
      role="group"
    >
      {props.children ? (
        <>
          {props.children.length > 1 ? (
            <>
              {props.children.map((child: any, index: number) => (
                <div className="ui-form-layout-group-element" key={index}>
                  {child}
                </div>
              ))}
            </>
          ) : (
            <div className="ui-form-layout-group-element">{props.children}</div>
          )}
        </>
      ) : null}
    </div>
  </>
);

const FormLayout = (props: any) => (
  <div className="ui-form-layout">
    {React.Children.map(props.children, child => {
      return <>{child}</>;
    })}
  </div>
);

FormLayout.displayName = "FormLayout";
Group.displayName = "Group";
FormLayout.Group = Group;
export default FormLayout;
