import React, { useState, useContext, useEffect } from "react";
import { ISelectState, SelectContext } from "./Select";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IOptionProps {
  /** passing in `new-value` does not set the value, and returns empty string as ID argument from Select's onChange */
  value: string | number;
  prefixCls?: string;
  className?: string;
  key?: string | number;
  name?: string;
  extraValues?: any;
  disabled?: boolean;
}

interface ISelectStore {
  state: ISelectState;
  updateState: (newState: Partial<ISelectState>) => void;
  onChange?: (value: any, extraValues?: any) => void;
  focusInput: () => void;
  blurInput: () => void;
}

const Select: React.FC<IOptionProps> = props => {
  const {
    disabled,
    children,
    value,
    extraValues,
    prefixCls = "rc-select-dropdown-list-item",
    className,
    name,
    ...rest
  } = props;

  const selectStore = useContext<ISelectStore>(SelectContext);

  const handleClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.stopPropagation();

    if (value == "new-value") {
      selectStore.updateState({
        value: "",
        search: "",
        dropdownVisible: false,
        extraValues: extraValues,
      });
      selectStore.onChange("");
      selectStore.blurInput();
      return;
    } // Allow a user to add value within Select dropdown

    selectStore.updateState({
      value,
      search: name,
      dropdownVisible: false,
      extraValues: extraValues,
    });
    selectStore.focusInput();
  };

  const cls = classNames(
    prefixCls,
    {
      [`${prefixCls}-selected`]: selectStore.state.value === value,
    },
    className,
  );

  return (
    <li onClick={disabled !== true ? e => handleClick(e) : null} className={cls} {...rest}>
      {children}
      {selectStore.state.value === value && (
        <FontAwesomeIcon className="rc-select-dropdown-check" icon={["far", "check"]} />
      )}
    </li>
  );
};

export default Select;
