import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { ClientPortalNavigation } from "../ClientPortalNavigation";
import "./clientPortalEvents.scss";
import { useClientPortal } from "../useClientPortal";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import Input from "components/form/input";
import classNames from "classnames";
import CustomDropdown from "components/customDropdown/CustomDropdown";
import { clientPortalUpdate } from "redux/actions/clientPortal";
import { IFacility } from "redux/reducers/models/facility";
import axios, { CancelToken } from "axios";
import { GetFacility } from "api/rpc/2024-04/customer/facility";
import { GetFacilityGuest } from "api/rpc/2024-04/guest/facility";
import { IAPIResponse, StatusCode } from "api/protocols";
import { dequeue, enqueue, showError } from "redux/actions/ui";
import { sortFacilities } from "redux/reducers/clientPortal";
import CustomDropdownOption from "components/customDropdown/CustomDropdownOption";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spin from "components/spin/spin";
import { Select } from "components/select/index";
import "../Home/clientPortalHome.scss";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import { ILeague } from "redux/reducers/models/league";
import { ITournament } from "redux/reducers/models/tournament";
import { GetGuestTournament } from "api/rpc/2024-04/guest/tournament/tournament";
import { GetGuestLeague } from "api/rpc/2024-04/guest/league";
import { GetCustomerTournament } from "api/rpc/2024-04/customer/tournament";
import { GetCustomerLeague } from "api/rpc/2024-04/customer/league";
import moment from "moment";
import ReactDOM from "react-dom";
import { UserActive } from "api/rpc";
import { GetClient } from "api/rpc/2024-04/customer/client";
import { GetClientGuest } from "api/rpc/2024-04/guest/client";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { MOBILE_WIDTH } from "helpers/ScreenSizes";
import { convertTime } from "helpers/Helpers";
import MobileEventCard from "./MobileEventCard";

interface IParams {
  facilityShortName: string;
  clientShortName: string;
}

interface IState {
  searchQuery: string;
  facilitySearchQuery: string;
  searchedFacilities: Array<IFacility>;
  years: Array<number>;
  selectedYear: number;
  leagues: Array<ILeague>;
  tournaments: Array<ITournament>;
}

interface IFilterState {
  limit: number;
  offset: number;
}

export default function ClientPortalEvents() {
  const { facilityShortName, clientShortName } = useParams<IParams>();
  const dispatch = useAppDispatch();
  const { clientPortalStore } = useAppSelector(store => store);
  const history = useHistory();
  const [tabSelected, setTabSelected] = useState<number>(0);
  const HOME_URL = useClientPortal().getHomePageUrl();
  const windowSize = useWindowSize();

  const [state, setState] = useState<IState>({
    searchQuery: "",
    facilitySearchQuery: "",
    searchedFacilities: [],
    years: [],
    selectedYear: new Date().getFullYear(),
    leagues: [],
    tournaments: [],
  });
  const [filterState, setFilterState] = useState<IFilterState>({
    limit: 25,
    offset: 0,
  });
  const { Option } = Select;

  useEffect(() => {
    void setYears();
    if (!clientPortalStore?.client) {
      void initialize();
    }
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();
    let timeoutId: NodeJS.Timeout = null;
    if (clientPortalStore?.client) {
      timeoutId = global.setTimeout(() => {
        void loadEvents(source.token);
      }, 500);
    }
    return () => {
      source.cancel("Cancelled");
      clearTimeout(timeoutId);
    };
  }, [
    tabSelected,
    clientPortalStore?.selectedFacility,
    state.selectedYear,
    filterState.offset,
    state.searchQuery,
    clientPortalStore?.client,
  ]);

  // useEffect(() => {
  //   const source = axios.CancelToken.source();
  //   let mounted = true;
  //   let timeoutId: NodeJS.Timeout = null;
  //   if (mounted === true && state?.facilitySearchQuery) {
  //     timeoutId = global.setTimeout(() => {
  //       void searchFacility(source.token);
  //     }, 500);
  //   }

  //   return () => {
  //     mounted = false;
  //     clearTimeout(timeoutId);
  //     setState(prevState => ({ ...prevState, searchedFacilities: [] }));
  //     source.cancel("Cancelled");
  //   };
  // }, [state?.facilitySearchQuery]);

  async function initialize() {
    let currentActiveUser = !!clientPortalStore?.user;
    let selectedClient = clientPortalStore?.client;
    let selectedFacility = clientPortalStore?.selectedFacility;
    let clientRes;
    let facilityRes;
    dispatch(enqueue());
    const activeUserRes = await UserActive(false);
    if (activeUserRes?.status === StatusCode.OK) {
      currentActiveUser = true;
    }

    if (currentActiveUser) {
      clientRes = await GetClient({ short_name: clientShortName }, false);
    } else {
      clientRes = await GetClientGuest({ short_name: clientShortName }, false);
    }
    if (clientRes.status !== StatusCode.OK || clientRes?.data?.length === 0) {
      dispatch(dequeue());
      dispatch(showError("Error loading client"));
      return;
    }
    selectedClient = clientRes?.data[0];
    if (currentActiveUser) {
      facilityRes = await GetFacility({ client_id: selectedClient?.id, order_by: "long_name" }, false);
    } else {
      facilityRes = await GetFacilityGuest({ client_id: selectedClient?.id, order_by: "long_name" }, false);
    }

    if (facilityRes?.status !== StatusCode.OK || facilityRes?.data?.length === 0) {
      dispatch(dequeue());
      dispatch(showError("Error loading facilities"));
      return;
    }

    //Sort by Favourite facilities
    const sortedFacilities = sortFacilities(facilityRes?.data);
    selectedFacility = selectedFacility
      ? sortedFacilities?.find(facility => facility?.id === selectedFacility?.id)
      : facilityShortName
      ? sortedFacilities?.find(facility => facility?.short_name === facilityShortName)
      : sortedFacilities[0];
    if (!selectedFacility && sortedFacilities?.length > 0) {
      selectedFacility = sortedFacilities[0];
    }
    selectedClient = { ...selectedClient, facilities: sortedFacilities };
    dispatch(
      clientPortalUpdate({
        client: selectedClient,
        selectedFacility,
      }),
    );
    dispatch(dequeue());
  }

  async function loadEvents(cancelToken?: CancelToken) {
    let tournamentRes: IAPIResponse<Array<ITournament>>;
    let leagueRes: IAPIResponse<Array<ILeague>>;
    const userActive = !!clientPortalStore?.user;
    // Show Loader
    setState(prevState => ({ ...prevState, leagues: null, tournaments: null }));

    if (tabSelected === 0) {
      //Tournaments
      if (userActive) {
        tournamentRes = await GetCustomerTournament(
          {
            facility_id: clientPortalStore?.selectedFacility?.id,
            year: state.selectedYear,
            search: state.searchQuery ?? undefined,
            ...filterState,
          },
          false,
          cancelToken,
        );
      } else {
        tournamentRes = await GetGuestTournament(
          {
            facility_id: clientPortalStore?.selectedFacility?.id,
            year: state.selectedYear,
            search: state.searchQuery ?? undefined,
            ...filterState,
          },
          false,
          cancelToken,
        );
      }

      if (tournamentRes?.status !== StatusCode.OK) {
        if (cancelToken.reason) {
          return;
        }
        dispatch(showError("Error getting tournaments"));
      }
    } else if (tabSelected === 1) {
      //Leagues
      if (userActive) {
        leagueRes = await GetCustomerLeague(
          {
            facility_id: clientPortalStore?.selectedFacility?.id,
            year: state.selectedYear,
            search: state.searchQuery ?? undefined,
            ...filterState,
          },
          false,
          cancelToken,
        );
      } else {
        leagueRes = await GetGuestLeague(
          {
            facility_id: clientPortalStore?.selectedFacility?.id,
            year: state.selectedYear,
            search: state.searchQuery ?? undefined,
            ...filterState,
          },
          false,
          cancelToken,
        );
      }

      if (leagueRes?.status !== StatusCode.OK) {
        if (cancelToken.reason) {
          return;
        }
        dispatch(showError("Error getting leagues"));
      }
    }

    setState(prevState => ({
      ...prevState,
      leagues: leagueRes?.data ? leagueRes?.data : [],
      tournaments: tournamentRes?.data ? tournamentRes?.data : [],
    }));
  }

  function setYears() {
    //Set array of the past 5 years
    const currentYear = new Date().getFullYear();
    const yearsArray = Array.from(new Array(6), (currentValue: number, index) => currentYear - index);
    setState(prevState => ({ ...prevState, years: yearsArray }));
  }

  const facilities = useMemo(() => {
    if (state?.facilitySearchQuery) {
      return clientPortalStore?.client?.facilities?.filter(
        facility =>
          facility?.long_name.toLowerCase().includes(state?.facilitySearchQuery?.toLowerCase()) ||
          facility?.city.toLowerCase().includes(state?.facilitySearchQuery?.toLowerCase()),
      );
    } else if (clientPortalStore?.client?.facilities) {
      return clientPortalStore?.client?.facilities;
    } else {
      return [];
    }
  }, [state?.searchedFacilities, clientPortalStore?.client?.facilities, state?.facilitySearchQuery]);

  async function searchFacility(cancelToken: CancelToken) {
    let facilityRes;
    //Show loader
    if (state?.searchedFacilities) {
      setState(prevState => ({ ...prevState, searchedFacilities: null }));
    }

    if (clientPortalStore?.user) {
      facilityRes = await GetFacility(
        { search: state?.facilitySearchQuery, order_by: "long_name" },
        false,
        cancelToken,
      );
    } else {
      facilityRes = await GetFacilityGuest(
        { search: state?.facilitySearchQuery, order_by: "long_name" },
        false,
        cancelToken,
      );
    }
    if (facilityRes?.status !== StatusCode.OK) {
      if (cancelToken.reason) {
        return;
      }
      dispatch(showError("Error getting facilities"));
      return;
    }
    //Sort by Favourite facilities
    const sortedFacilities = sortFacilities(facilityRes?.data);
    setState(prevState => ({ ...prevState, searchedFacilities: sortedFacilities }));
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    ReactDOM.unstable_batchedUpdates(() => {
      setState(prevState => ({ ...prevState, [id]: value }));
      setFilterState(prevState => ({ ...prevState, offset: 0 }));
    });
  }

  function handleFacilityChange(id: number) {
    const foundFacility = clientPortalStore?.client?.facilities?.find(facility => facility?.id === id);
    if (foundFacility) {
      ReactDOM.unstable_batchedUpdates(() => {
        dispatch(clientPortalUpdate({ selectedFacility: foundFacility }));
        setFilterState(prevState => ({ ...prevState, offset: 0 }));
      });
    }
  }

  function handleYearChange(year: number) {
    ReactDOM.unstable_batchedUpdates(() => {
      setState(prevState => ({ ...prevState, selectedYear: year }));
      setFilterState(prevState => ({ ...prevState, offset: 0 }));
    });
  }

  function handleTabChange(tabIndex: number) {
    ReactDOM.unstable_batchedUpdates(() => {
      setTabSelected(tabIndex);
      setFilterState(prevState => ({ ...prevState, offset: 0 }));
    });
  }

  const selectedFacilityElement = (
    <div className="events-facility-container">
      {clientPortalStore?.client?.facilities?.length > 1 && clientPortalStore?.selectedFacility?.logo_source && (
        <div>
          <img className="events-home-course-logo" src={clientPortalStore?.selectedFacility?.logo_source} />
        </div>
      )}
      <div>
        <div className="events-home-course-name">{clientPortalStore?.selectedFacility?.long_name}</div>
        {clientPortalStore?.selectedFacility && (
          <div className="events-course-secondary-information">
            {`${clientPortalStore?.selectedFacility?.address_line_1}, ${clientPortalStore?.selectedFacility?.city}, ${clientPortalStore?.selectedFacility?.province_name}, ${clientPortalStore?.selectedFacility?.postal}`}
          </div>
        )}
        {clientPortalStore?.selectedFacility?.address_line_2 && (
          <div className="events-course-secondary-information">
            {clientPortalStore?.selectedFacility?.address_line_2}
          </div>
        )}
      </div>
    </div>
  );

  function navigateToRegisterLink(type: "tournament" | "league", handle: string) {
    type === "tournament"
      ? window.open(`/${type}/${clientPortalStore?.selectedFacility?.short_name}/${handle}`)
      : window.open(`/${type}/${clientPortalStore?.selectedFacility?.short_name}/${handle}/register`);
  }

  function navigateToResultsLink(type: "tournament" | "league", handle: string) {
    window.open(`/${type}/${clientPortalStore?.selectedFacility?.short_name}/${handle}/scoring/season-leaderboard`);
  }

  return (
    <div className="client-portal-events">
      <ClientPortalNavigation showNavOptions />
      <div className="events-tabs-container">
        <div className="events-tabs-inner-container">
          <div className="tabs-container">
            {tabs?.map((tab, tabIndex) => {
              return (
                <div
                  key={tabIndex}
                  className={classNames("events-tab", { "events-tab-selected": tabIndex === tabSelected })}
                  onClick={() => handleTabChange(tabIndex)}
                >
                  {tab}
                </div>
              );
            })}
          </div>
          <Input
            onChange={handleInputChange}
            value={state?.searchQuery}
            id="searchQuery"
            labelHidden
            onSearchClear={() => setState(prevState => ({ ...prevState, searchQuery: "" }))}
            placeholder="Search"
          />
        </div>
      </div>
      <div className="events-options-container">
        {clientPortalStore?.client?.facilities?.length > 1 ? (
          <CustomDropdown
            parentElement={selectedFacilityElement}
            onChange={handleFacilityChange}
            defaultValue={clientPortalStore?.selectedFacility?.id}
            onSearch={searchString => setState(prevState => ({ ...prevState, facilitySearchQuery: searchString }))}
            searchPlacerHolder="Search courses..."
          >
            {facilities ? (
              facilities?.map((facility, index) => {
                return (
                  <CustomDropdownOption value={facility.id} key={index}>
                    <div className="events-facility-option-container">
                      <img className="events-facility-option-facility-logo" src={facility?.logo_source} />
                      <p className="events-facility-option-facility-name">{facility?.long_name}</p>
                      <p className="events-facility-option-facility-address">{facility?.city}</p>
                      {facility?.favourite && (
                        <FontAwesomeIcon
                          size="1x"
                          fixedWidth
                          className="events-facility-option-favourite"
                          icon={["fas", "star"]}
                        />
                      )}
                    </div>
                  </CustomDropdownOption>
                );
              })
            ) : (
              <div className="facility-spinner">
                <Spin />
              </div>
            )}
          </CustomDropdown>
        ) : (
          selectedFacilityElement
        )}
        <div className="events-years-dropdown">
          <Select label="Year" onChange={handleYearChange} defaultValue={state?.selectedYear ?? null}>
            {state?.years?.map((year, index) => {
              return (
                <Option key={index} value={year}>
                  {year}
                </Option>
              );
            })}
          </Select>
        </div>
      </div>

      {windowSize.width > MOBILE_WIDTH ? (
        <div className="events-table">
          {tabSelected === 0 ? (
            // Tournaments
            <DataTable
              loading={!state?.tournaments ? true : false}
              columns={[
                { label: "Competitions" },
                { label: "Date" },
                { label: "Players" },
                { label: "Registration" },
                { label: "Info." },
              ]}
              footer={{
                tableLimit: filterState.limit,
                tableOffset: filterState.offset,
                disableNextOffset: !state.tournaments || !(state.tournaments?.length === filterState.limit),
                handleTableOffset: direction =>
                  setFilterState(prev => ({
                    ...prev,
                    offset: direction === "prev" ? prev.offset - prev.limit : prev.offset + prev.limit,
                  })),
              }}
            >
              {state?.tournaments?.map((tournament, index) => {
                const disableRegister = getTournamentRegisterDateRange(tournament);
                const registerDates = getRegisrationDates(
                  tournament.registration_open_date,
                  tournament.registration_close_date,
                );
                return (
                  <tr key={index}>
                    <td>{tournament?.name}</td>
                    <td>{moment(`${tournament?.date} ${tournament?.start_time}`).format("MMMM DD h:mm A")}</td>
                    <td>
                      <FontAwesomeIcon className="events-players" icon={["fal", "user-group"]} />{" "}
                      {tournament?.total_participant_count}
                      {tournament?.player_limit ? ` / ${tournament?.player_limit}` : ""} Players
                    </td>
                    <td>
                      {!!tournament?.registration_enabled && (
                        <>
                          {registerDates.open_date && registerDates.open_time && (
                            <p className="events-registration">
                              <span>Opens</span> {registerDates.open_date}{" "}
                              {moment(registerDates.open_time, "HH:mm:ss").format("h:mm A")}
                            </p>
                          )}
                          {registerDates.close_date && registerDates.close_time && (
                            <p className="events-registration">
                              <span>Closes</span> {registerDates.close_date}{" "}
                              {moment(registerDates.close_time, "HH:mm:ss").format("h:mm A")}
                            </p>
                          )}
                        </>
                      )}
                    </td>
                    <td>
                      {!!tournament?.registration_enabled && (
                        <button
                          className="events-data-button"
                          disabled={disableRegister}
                          onClick={() => navigateToRegisterLink("tournament", tournament?.handle)}
                        >
                          Register
                        </button>
                      )}
                      {/* <span className="events-data-button">Results</span> */}
                    </td>
                  </tr>
                );
              })}
            </DataTable>
          ) : (
            // Leagues
            <DataTable
              loading={!state?.leagues ? true : false}
              columns={[
                { label: "Competitions" },
                { label: "Week Day" },
                { label: "Players" },
                { label: "Registration" },
                { label: "Info." },
              ]}
              footer={{
                tableLimit: filterState.limit,
                tableOffset: filterState.offset,
                disableNextOffset: !state.leagues || !(state.leagues?.length === filterState.limit),
                handleTableOffset: direction =>
                  setFilterState(prev => ({
                    ...prev,
                    offset: direction === "prev" ? prev.offset - prev.limit : prev.offset + prev.limit,
                  })),
              }}
            >
              {state?.leagues?.map((league, index) => {
                const disableRegister = getLeagueRegisterDateRange(league);
                const registerDates = getRegisrationDates(
                  league.registration_open_date,
                  league.registration_close_date,
                );
                return (
                  <tr key={index}>
                    <td>{league?.name}</td>
                    <td>{league?.week_day}</td>
                    <td>
                      <FontAwesomeIcon className="events-players" icon={["fal", "user-group"]} />{" "}
                      {league?.total_participant_count}
                      {league?.player_limit ? ` / ${league?.player_limit}` : ""} Players
                    </td>
                    <td>
                      {!!league?.registration_open && (
                        <>
                          {registerDates.open_date && registerDates.open_time && (
                            <p className="events-registration">
                              <span>Opens</span> {registerDates.open_date}{" "}
                              {moment(registerDates.open_time, "HH:mm:ss").format("h:mm A")}
                            </p>
                          )}
                          {registerDates.close_date && registerDates.close_time && (
                            <p className="events-registration">
                              <span>Closes</span> {registerDates.close_date}{" "}
                              {moment(registerDates.close_time, "HH:mm:ss").format("h:mm A")}
                            </p>
                          )}
                        </>
                      )}
                    </td>
                    <td>
                      {!!league?.registration_open && (
                        <button
                          style={{ marginRight: "10px" }}
                          className={classNames("events-data-button")}
                          disabled={disableRegister}
                          onClick={() => navigateToRegisterLink("league", league?.handle)}
                        >
                          Register
                        </button>
                      )}
                      {/* <button
                       className="events-data-button"
                       onClick={() => navigateToResultsLink("league", league?.handle)}
                     >
                       Results
                     </button> */}
                    </td>
                  </tr>
                );
              })}
            </DataTable>
          )}
        </div>
      ) : (
        <Fragment>
          {tabSelected === 0 ? (
            // Tournaments

            <div>
              {state?.tournaments ? (
                <Fragment>
                  {state?.tournaments?.map((tournament, index) => {
                    const disableTournamentRegister = getTournamentRegisterDateRange(tournament);
                    const tournamentRegisterDates = getRegisrationDates(
                      tournament.registration_open_date,
                      tournament.registration_close_date,
                    );
                    return (
                      <Fragment key={index}>
                        <MobileEventCard
                          tournament={tournament}
                          disableRegister={disableTournamentRegister}
                          registerDates={tournamentRegisterDates}
                          navigateToRegisterLink={navigateToRegisterLink}
                        />
                      </Fragment>
                    );
                  })}
                </Fragment>
              ) : (
                <div className="td-spinner mt-4">
                  <Spin />
                </div>
              )}

              {/* {state?.tournaments?.map((tournament, index) => {
                const disableTournamentRegister = getTournamentRegisterDateRange(tournament);
                const tournamentRegisterDates = getRegisrationDates(
                  tournament.registration_open_date,
                  tournament.registration_close_date,
                );
                return (
                  <Fragment key={index}>
                    <MobileEventCard
                      tournament={tournament}
                      disableRegister={disableTournamentRegister}
                      registerDates={tournamentRegisterDates}
                      navigateToRegisterLink={navigateToRegisterLink}
                    />
                  </Fragment>
                );
              })} */}
            </div>
          ) : (
            // Leagues
            <div>
              {state?.leagues ? (
                <Fragment>
                  {state?.leagues?.map((league, index) => {
                    const disableLeagueRegister = getLeagueRegisterDateRange(league);
                    const leagueRegisterDates = getRegisrationDates(
                      league.registration_open_date,
                      league.registration_close_date,
                    );
                    return (
                      <Fragment key={index}>
                        <MobileEventCard
                          league={league}
                          disableRegister={disableLeagueRegister}
                          registerDates={leagueRegisterDates}
                          navigateToRegisterLink={navigateToRegisterLink}
                        />
                      </Fragment>
                    );
                  })}
                </Fragment>
              ) : (
                <div className="td-spinner mt-4">
                  <Spin />
                </div>
              )}

              {/* {state?.leagues?.map((league, index) => {
                const disableLeagueRegister = getLeagueRegisterDateRange(league);
                const leagueRegisterDates = getRegisrationDates(
                  league.registration_open_date,
                  league.registration_close_date,
                );
                return (
                  <Fragment key={index}>
                    <MobileEventCard
                      league={league}
                      disableRegister={disableLeagueRegister}
                      registerDates={leagueRegisterDates}
                      navigateToRegisterLink={navigateToRegisterLink}
                    />
                  </Fragment>
                );
              })} */}
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
}

const tabs = ["Tournaments", "Leagues"];

function getLeagueRegisterDateRange(league: ILeague) {
  let disableRegister = false;
  if (league?.registration_open) {
    if (league?.registration_open_date) {
      if (!moment().isSameOrAfter(moment(league?.registration_open_date, "YYYY-MM-DD HH:mm:ss"))) {
        disableRegister = true;
      }
    }
    if (league?.registration_close_date) {
      if (!moment().isSameOrBefore(moment(league?.registration_close_date, "YYYY-MM-DD HH:mm:ss"))) {
        disableRegister = true;
      }
    }
  } else {
    disableRegister = true;
  }
  return disableRegister;
}

function getTournamentRegisterDateRange(tournament: ITournament) {
  let disableRegister = false;
  if (tournament?.registration_enabled) {
    if (tournament?.registration_open_date) {
      if (!moment().isSameOrAfter(moment(tournament?.registration_open_date, "YYYY-MM-DD HH:mm:ss"))) {
        disableRegister = true;
      }
    }
    if (tournament?.registration_close_date) {
      if (!moment().isSameOrBefore(moment(tournament?.registration_close_date, "YYYY-MM-DD HH:mm:ss"))) {
        disableRegister = true;
      }
    }
  } else {
    disableRegister = true;
  }
  return disableRegister;
}

function getRegisrationDates(openDate: string, closeDate: string) {
  let dateObj = { open_date: "", open_time: "", close_date: "", close_time: "" };
  if (openDate) {
    const [open_date, open_time] = openDate?.split(" ");
    dateObj = { ...dateObj, open_time, open_date };
  }
  if (closeDate) {
    const [close_date, close_time] = closeDate?.split(" ");
    dateObj = { ...dateObj, close_time, close_date };
  }
  return dateObj;
}
