import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";

const apiVersion = "2021-01";
const adminType = "facility-admin";

// POST Reader Connection
// Retrieve a connection for connecting to a Stripe Reader
export async function PostConnection(params?: { register_group_id: number }, useGlobalLoader?: boolean) {
  return await APIPost(
    "/" + apiVersion + "/" + adminType + "/payment/tee-on-payment/reader/connection",
    params,
    useGlobalLoader,
  );
}

// POST Card Reader
// Allows a new card reader to be set up for a specific location
export async function PostCardReader(params: { label: string; registration_code: string }, useGlobalLoader?: boolean) {
  return await APIPost("/" + apiVersion + "/" + adminType + "/payment/tee-on-payment/reader", params, useGlobalLoader);
}

// GET Card Reader
// Retrieve a list of card readers for a location
export async function GetCardReaders(params?: { device_type?: string; status?: string }, useGlobalLoader?: boolean) {
  return await APIGet("/" + apiVersion + "/" + adminType + "/payment/tee-on-payment/reader", params, useGlobalLoader);
}

export async function PutCardReaderPayment(
  params?: { external_reader_id: string; payment_intent_id: string; skip_tipping: boolean },
  useGlobalLoader?: boolean,
) {
  return await APIPut("/2024-04/" + adminType + "/payment/tee-on-payment/reader/payment", params, useGlobalLoader);
}

export async function GetCardReaderStatus(params?: { external_reader_id: string }, useGlobalLoader?: boolean) {
  return await APIGet("/2024-04/" + adminType + "/payment/tee-on-payment/reader/status", params, useGlobalLoader);
}

export async function PutCardReaderRefund(
  params?: { external_reader_id: string; payment_intent_id: string; amount: number },
  useGlobalLoader?: boolean,
) {
  return await APIPut("/2022-09/" + adminType + "/payment/tee-on-payment/reader/refund", params, useGlobalLoader);
}
