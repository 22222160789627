import { APIGet, IAPIResponse, StatusCode } from "api/protocols";
import { IClient } from "../customer/client";

const apiVersion = "2022-09";
const adminType = "guest";
const baseClientUrl = "/" + apiVersion + "/" + adminType + "/client";

interface IClientRes extends IAPIResponse {
  data: Array<IClient>;
}

export async function GetClientGuest(
  params: { id?: number; short_name?: string },
  useGlobalLoader: boolean,
): Promise<IClientRes> {
  return await APIGet(baseClientUrl, params, useGlobalLoader);
}
