import Products from "./Products";
import ProductEdit from "./edit/ProductEdit";
import Variant from "./../../../../containers/facility/product/variant";
import NewVariant from "./VariantNew";
import AllTickets from "./Tickets";
import NewTicket from "./TicketNew";
import SingleTicket from "./TicketEdit";
import AllMemberships from "./Memberships";
import SingleMembership from "./Membership";
import MembershipAddOn from "./MembershipAddOns";
import TicketStubs from "./TicketStubs";
import TicketStub from "./TicketStub";
import NewProduct from "./NewProduct";

const productRoutes: { path: string; component: any }[] = [
  // Products
  {
    path: "/admin/productMenu",
    component: Products,
  },
  {
    path: "/admin/product",
    component: Products,
  },
  {
    path: "/admin/product/new",
    component: NewProduct,
  },

  {
    path: "/admin/product/:productId",
    component: ProductEdit,
  },
  {
    path: "/admin/product/:productId/variant/new",
    component: NewVariant,
  },
  {
    path: "/admin/product/:productId/variant/:variantId",
    component: Variant,
  },

  //Tickets
  {
    path: "/admin/tickets",
    component: AllTickets,
  },

  {
    path: "/admin/tickets/new",
    component: NewTicket,
  },
  {
    path: "/admin/tickets/stubs",
    component: TicketStubs,
  },
  {
    path: "/admin/tickets/stubs/:stubId",
    component: TicketStub,
  },
  {
    path: "/admin/tickets/:ticketId",
    component: SingleTicket,
  },

  //Memberships
  {
    path: "/admin/membership",
    component: AllMemberships,
  },
  {
    path: "/admin/membership/:membershipId",
    component: SingleMembership,
  },
  {
    path: "/admin/membership/add-on/:membershipAddOnId",
    component: MembershipAddOn,
  },
];

export default productRoutes;
