import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetLeagueFormatTemplate } from "api/rpc/2024-04/facilityAdmin/league/scoring/formatTemplate";

import { showError } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";
import { valueToString } from "helpers/Helpers";

import Page from "components/page/Page";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import { TLeagueRoundFormatTemplate } from "redux/reducers/models/league";

export default function LeagueFormatTemplates() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { leagueId } = useParams<{ leagueId: string }>();

  const dispatch = useAppDispatch();

  const [templates, setTemplates] = useState<TLeagueRoundFormatTemplate[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadTemplates(source.token);
    return () => source.cancel();
  }, [leagueId]);

  async function loadTemplates(token?: CancelToken) {
    const templateRes = await GetLeagueFormatTemplate({ league_id: leagueId }, false, token);

    if (token && token.reason) {
      return;
    }
    if (templateRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading templates")); // TODO: Translation
      return;
    }

    setTemplates(templateRes.data);
  }

  const primaryAction = {
    content: "New Template", // TODO: Translation
    action: () => history.push(`/admin/league/${leagueId}/league-settings/format-templates/new`),
  };

  return (
    <Page
      title={"Format Templates"} // TODO: Translation
      narrow
      primaryAction={primaryAction}
    >
      <DataTable
        columns={[
          { label: "Name" }, // TODO: Translation
          { label: "Organization" }, // TODO: Translation
          { label: "Format" }, // TODO: Translation
          { label: "Holes" }, // TODO: Translation
        ]}
        loading={templates === undefined}
      >
        {templates?.map(template => (
          <tr
            key={template.id}
            className="clickable"
            onClick={() => history.push(`/admin/league/${leagueId}/league-settings/format-templates/${template.id}`)}
          >
            <td>{template.name}</td>
            <td>{valueToString(template.organization)}</td>
            <td>{valueToString(template.format)}</td>
            <td>{valueToString(template.holes)}</td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
