import { APIGet, APIPost, APIPut, APIDelete, StatusCode } from "api/protocols";
import { CancelToken } from "axios";
import { IProduct } from "redux/reducers/models/product";

const apiVersion = "2022-09";
const adminType = "client-admin";
const baseProductUrl = "/" + apiVersion + "/" + adminType + "/product";

interface IGetProduct {
  id?: number;
  search?: string;
  extended?: boolean;
  extended_variants?: boolean;
  sales_channels?: boolean;
  inventory?: boolean;
  limit?: number;
  offset?: number;
  type?: string;
  types?: any;
  all?: boolean;
  filters?: {
    label: ExtendedClientProductFilterKey;
    value: string[] | number[];
  }[];
}

/** filter params that can be added into the product API call. */
export type ClientProductExtendedFilters = {
  /** Return all the products that have these product types. */
  type: string[];
  /** Return all the products from the department with these ID's. */
  department_id: number[];
  /** Return all the products from the department categories with these ID's. */
  category_id: number[];
  /** Return all the products from the subcategories with these ID's. */
  subcategory_id: number[];
};

export type ExtendedClientProductFilterKey = keyof ClientProductExtendedFilters;

interface IGetProductRes {
  data: IProduct[];
  message: string;
  status: StatusCode;
}

export async function GetProduct(
  params: IGetProduct,
  useGlobalLoader: boolean,
  token?: CancelToken,
): Promise<IGetProductRes> {
  return await APIGet(baseProductUrl, params, useGlobalLoader, token);
}

interface IPutProductAttach {
  product_id: number;
}

interface IProductRes {
  status: number;
  message?: string;
  data: any;
}

// Attach a product to a facility
export async function PutProductAttach(params: IPutProductAttach, useGlobalLoader?: boolean): Promise<IProductRes> {
  return await APIPut(baseProductUrl + "/attach", params, useGlobalLoader);
}

// POST Products
export async function PostProduct(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseProductUrl, params, useGlobalLoader);
}

// PUT Product
export async function PutProduct(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseProductUrl, params, useGlobalLoader);
}

// DELETE Product
export async function DeleteProduct(useGlobalLoader: boolean) {
  return await APIDelete(baseProductUrl, useGlobalLoader);
}

// Archive Product
export async function PutArchiveProduct(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseProductUrl + "/archive", params, useGlobalLoader);
}

// Duplicate Product
export async function DuplicateProduct(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseProductUrl + "/duplicate", params, useGlobalLoader);
}

// GET Variant
interface IGetVariant {
  id?: number;
  product_id?: number;
  extended?: boolean;
  types?: any;
}

export async function GetVariant(params: IGetVariant, useGlobalLoader: boolean) {
  return await APIGet(baseProductUrl + "/variant", params, useGlobalLoader);
}

// PUT Variant
export async function PostVariant(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseProductUrl + "/variant", params, useGlobalLoader);
}

// PUT Variant
export async function PutVariant(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseProductUrl + "/variant", params, useGlobalLoader);
}

// PUT Archive Variant
export async function ArchiveVariant(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseProductUrl + "/variant/archive", params, useGlobalLoader);
}

// Duplicate Variant
export async function DuplicateVariant(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseProductUrl + "/variant/duplicate", params, useGlobalLoader);
}

// GET Product types
export async function GetProductTypesClient(params: { id?: number }, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseProductUrl + "/type", params, useGlobalLoader, token);
}
export async function PostProductTypeClient(params: { title: string }, useGlobalLoader: boolean) {
  return await APIPost(baseProductUrl + "/type", params, useGlobalLoader);
}
export async function PutProductTypeClient(params: { id: number; title: string }, useGlobalLoader: boolean) {
  return await APIPut(baseProductUrl + "/type", params, useGlobalLoader);
}
export async function DeleteProductTypeClient(params: { id: number }, useGlobalLoader: boolean) {
  return await APIDelete(baseProductUrl + "/type", params, useGlobalLoader);
}

interface IGetInventoryLevel {
  variant_id: number;
  extended: boolean;
}

// GET Inventory Levels
export async function GetInventoryLevels(params: IGetInventoryLevel, useGlobalLoader: boolean) {
  return await APIGet(baseProductUrl + "/inventory/level", params, useGlobalLoader);
}

// PUT Update Inventory Level
export async function UpdateInventoryLevels(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseProductUrl + "/inventory/level", params, useGlobalLoader);
}

// POST Add Products to Variant
export async function AddProductToVariant(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseProductUrl + "/combination", params, useGlobalLoader);
}

//GET Retrieve tax lines
const baseTaxLineUrl = "/" + apiVersion + "/" + adminType + "/client/tax-line";

export async function GetTaxLines(useGlobalLoader: boolean) {
  return await APIGet(baseTaxLineUrl, useGlobalLoader);
}

//Attach modifier group to product
export async function AttachModifierGroup(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseProductUrl + "/modifier", params, useGlobalLoader);
}

//Product Options
export async function PostProductOption(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseProductUrl + "/option", params, useGlobalLoader);
}
