import React, { createContext, useEffect, useState, useRef } from "react";
import "./tagSelect.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "components/form/input/Input";
import useOnclickOutside from "hooks/useOnclickOutside/useOnclickOutside";
import Divider from "components/divider";
import { useCustomerContext } from "pages/secure/facility/customer/context/CustomerContext";
import { DeleteCustomerTag, GetCustomerTag, PutCustomerTag } from "api/rpc/2024-04/facilityAdmin/customer/customer";
import { Badge } from "components/badge/Badge";
import { StatusCode } from "api/protocols";
import { showError } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";
import Spin from "components/spin/spin";

export interface ITagSelectProps {
  label?: string;
  tags?: any[];
  attachedTags?: any[];
  fullTagList?: any[];
}

interface ITagSelectState {
  showTags: boolean;
  filterString: string;
  tags: any[];
  fullTagList: any[];
  attachedTags: any[];
}

const TagSelect: React.FC<ITagSelectProps> = props => {
  const { tags, attachedTags, fullTagList } = props;
  const dispatch = useAppDispatch();

  const { state } = useCustomerContext(); // changes seen across customer context
  const [tagsLoading, setTagsLoading] = useState<boolean>(false);

  const [tagSelectState, setTagSelectState] = useState<ITagSelectState>({
    showTags: false,
    filterString: "",
    tags: tags,
    attachedTags: attachedTags,
    fullTagList: [],
  });

  useEffect(() => {
    setTagSelectState(prevState => ({ ...prevState, tags: tags }));
  }, [tags]);

  useEffect(() => {
    setTagSelectState(prevState => ({ ...prevState, attachedTags: attachedTags }));
  }, [attachedTags]);

  useEffect(() => {
    setTagSelectState(prevState => ({ ...prevState, fullTagList: fullTagList }));
  }, [fullTagList]);

  useEffect(() => {
    if (tagSelectState.showTags === false) {
      void addTagsToCustomer();
    }
  }, [tagSelectState.showTags]);

  function tagFilterHandler(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;

    setTagSelectState(prevState => ({ ...prevState, filterString: value }));
  }

  const clickOutsideRef = useOnclickOutside(() => {
    setTagSelectState(prevState => ({ ...prevState, showTags: false, filterString: "" }));
  });

  async function addTagsToCustomer() {
    setTagsLoading(true);

    const tags = tagSelectState.tags.slice();
    const tagsToAttach: any[] = [];
    const allTags = tagSelectState.fullTagList.slice();
    let customerTags: any[] = [];

    for (let i = 0; i < tags.length; i++) {
      if (tags[i].checked === true) {
        tagsToAttach.push(tags[i]);
      }
    }

    if (tagsToAttach.length > 0) {
      //loop through tags to be added

      for (let i = 0; i < tagsToAttach.length; i++) {
        const params = {
          customer_id: state.customer.id,
          tag_id: tagsToAttach[i].id,
        };

        const attachCustomerTagRes = await PutCustomerTag(params, false);

        if (attachCustomerTagRes.status !== StatusCode.OK) {
          dispatch(showError("Error attaching tags"));
          return;
        }
      }

      const customerTagParams = {
        customer_id: state.customer.id,
      };

      const getCustomerTagRes = await GetCustomerTag(customerTagParams, false);

      if (getCustomerTagRes.status !== StatusCode.OK) {
        dispatch(showError("Error getting customer tags"));
        return;
      }

      customerTags = getCustomerTagRes.data.slice();

      for (let i = 0; i < allTags.length; i++) {
        allTags[i].checked = false;

        for (let k = 0; k < getCustomerTagRes.data.length; k++) {
          if (allTags[i].id === getCustomerTagRes.data[k].id) {
            allTags.splice(i, 1);
          }
        }
      }

      setTagSelectState(prevState => ({
        ...prevState,
        tags: allTags,
        attachedTags: customerTags,
      }));
    }
    setTagsLoading(false);
  }

  function handleAddCustomerTag(tag: any, index: number) {
    const tags = tagSelectState.tags.slice();

    for (let i = 0; i < tags.length; i++) {
      if (tags[i].id === tag.id) {
        tags[i].checked = !tags[i].checked;
      }
    }

    setTagSelectState(prevState => ({ ...prevState, tags: tags }));
  }

  async function handleRemoveCustomerTag(tag: any) {
    const params = {
      customer_id: state.customer.id,
      tag_id: tag.id,
    };

    const removeCustomerTagRes = await DeleteCustomerTag(params, true);

    if (removeCustomerTagRes.status !== StatusCode.OK) {
      dispatch(showError("Error deleting tag"));
      return;
    }

    const customerTags = tagSelectState.attachedTags.slice();

    for (let i = 0; i < customerTags.length; i++) {
      if (customerTags[i].id === tag.id) {
        customerTags.splice(i, 1);
      }
    }

    const allTags = tagSelectState.fullTagList.slice();

    for (let i = 0; i < allTags.length; i++) {
      allTags[i].checked = false;
    }

    for (let i = 0; i < allTags.length; i++) {
      for (let k = 0; k < customerTags.length; k++) {
        if (allTags[i].id === customerTags[k].id) {
          allTags.splice(i, 1);
        }
      }
    }

    setTagSelectState(prevState => ({ ...prevState, tags: allTags, attachedTags: customerTags }));
  }

  return (
    <div ref={clickOutsideRef} className="tag-select">
      <div
        className="tag-select_label_container"
        onClick={() => setTagSelectState(prevState => ({ ...prevState, showTags: !tagSelectState.showTags }))}
      >
        <div className="tag-select_label">
          <p>{props.label} </p>
        </div>

        <div>
          <p>
            {" "}
            <FontAwesomeIcon className="tag-select_add_tag_icon" icon={["far", "plus"]} />
          </p>
        </div>
      </div>

      {tagsLoading ? (
        <div className="h-8">
          <Spin className="self-center" />
        </div>
      ) : (
        <div>
          {tagSelectState.showTags ? (
            <div className="tag-select_apply_tags_container">
              <div>
                <p className="tag-select_title">Attach tags to customer</p>
              </div>

              <div className="tag-select_filter_input_container">
                <div style={{ width: "100%" }}>
                  <Input
                    value={tagSelectState.filterString || ""}
                    id="username"
                    onChange={tagFilterHandler}
                    placeholder={"Filter tags"}
                    autoFocus
                    labelHidden
                  />
                </div>
              </div>

              <div className="tag-select_tag_list_container">
                {tagSelectState.tags ? (
                  <div>
                    {tagSelectState.tags
                      .filter((tag: any) => tag.label.toLowerCase().includes(tagSelectState.filterString))
                      .map((tag: any, index: number) => {
                        return (
                          <div
                            key={index}
                            className={
                              tag.checked
                                ? "tag-select_single_tag_container_checked"
                                : "tag-select_single_tag_container"
                            }
                            onClick={() => handleAddCustomerTag(tag, index)}
                          >
                            <div className="tag-select_tag_label">
                              <div className="tag-select_tag_text">
                                <div>
                                  <p className="tag-select_tag_icon">
                                    <FontAwesomeIcon icon={["fas", "circle"]} color={tag.colour} />
                                  </p>
                                </div>

                                <div className="tag-select_tag_label_text_container">
                                  <p className="tag-select_tag_label_text">{tag.label}</p>

                                  <div className="tag-select_tag_description">{tag.description}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}

          <div className="mt-2">
            {tagSelectState.attachedTags ? (
              <div>
                <div className="tag-select_tag_display_container">
                  {tagSelectState.attachedTags.map((tag: any, index: number) => {
                    return (
                      <div key={index}>
                        <Badge outline colour={tag.colour} size="small">
                          <div className="flex gap-3">
                            <div>{tag.label}</div>
                            <div
                              className="tag-select_tag_display_remove_icon"
                              onClick={() => handleRemoveCustomerTag(tag)}
                            >
                              <FontAwesomeIcon icon={["fas", "xmark"]} />
                            </div>
                          </div>
                        </Badge>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="tag-select_tag_display_title">There are no tags assigned</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TagSelect;
