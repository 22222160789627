import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { displayCurrency, roundNumber, displayPercent } from "helpers/Helpers";

import { GetVendor } from "api/rpc/vendor";

import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import { ButtonNew as Button } from "components/buttonNew";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import TextField from "components/form/textField/TextField";
import { Select } from "components/select/";
import Checkbox from "components/form/checkbox/Checkbox";
import _ from "lodash";
import { PutApply } from "api/rpc/teeSheet/teeSheet";

import "pages/secure/facility/product/Product.scss";
import Sheet from "components/sheet/Sheet";
import InputSearch from "components/input/Input";
import { GetTaxLines, PostProduct } from "api/rpc/clientAdmin/product/products";
import { GetAccountingReference, GetAccounts, GetDepartments } from "api/rpc/clientAdmin/facility/facility";
import { GetCustomerType } from "api/rpc/clientAdmin/customerType";
import { TABLET_WIDTH } from "helpers/ScreenSizes";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { TAccountingReference } from "redux/reducers/models/facility";

interface IProductState {
  title: string;
  description: string;
  price: number;
  original_price: number;
  cost: number;
  type: string;
  customer_required: boolean;
  shipping_required: boolean;
  fulfillment_required: boolean;
  vendor_id: number;
  taxable: boolean;
  department: string;
  category: string;
  subcategory: string;
  account_type: string;
  editing: boolean;
  multiple_options: boolean;
  options: any;
  accounting_reference_id: string;
  department_id: string;
  category_id: string;
  subcategory_id: string;
  membership_type: string;
  customer_type_id: number;
  meta: any;
  account_id: number;
  tax_line_ids: any[];
}

interface IVendors {
  client_id: number;
  id: number;
  title: string;
}

interface IDepartment {
  client_id: number;
  id: number;
  title: string;
  parent_id: number;
  type?: string;
}

interface IProductOptionsState {
  types: any[];
  vendors: IVendors[];
  accounts: [];
  customerTypes: [];
  accountingReference: TAccountingReference[];
  departments: IDepartment[];
  categories: IDepartment[];
  subcategories: IDepartment[];
  categoriesToDisplay: IDepartment[];
  subcategoriesToDisplay: IDepartment[];
  chosenDepartmentId: number;
  chosenCategoryId: number;
  chosenSubcategoryId: number;
  chosenAccountingReferenceId: number;
  tax_line_id: number;
  availableTaxLines: any[];
}

interface IComboProductState {
  comboProductsActive?: boolean;
  currentAddedProducts?: IProduct[];
  allProducts?: any;
  productsToUpdate?: Map<any, any>;
}

interface IProduct {
  id?: number;
  title?: string;
  value?: any;
  included?: string;
  quantity?: number;
}

interface IFilterState {
  search: string;
  sort: string;
}

export default function MembershipNew(props: any) {
  const { Option } = Select;

  const history = useHistory();
  const windowSize = useWindowSize();
  const [productState, setProductState] = useState<IProductState>({
    title: "",
    editing: false,
    description: "",
    price: null,
    original_price: null,
    cost: null,
    type: "",
    customer_required: false,
    shipping_required: false,
    fulfillment_required: false,
    vendor_id: null,
    taxable: true,
    department: "",
    category: "",
    subcategory: "",
    account_type: "",
    multiple_options: false,
    options: [],
    accounting_reference_id: "",
    department_id: "",
    category_id: "",
    subcategory_id: "",
    membership_type: "",
    customer_type_id: null,
    meta: {
      membership_type: "",
      customer_type_id: null,
    },
    account_id: null,
    tax_line_ids: [],
  });

  const [productOptionState, setProductOptionState] = useState<IProductOptionsState>({
    types: [
      {
        title: "Membership",
      },
      {
        title: "Membership Add On",
      },
    ],
    vendors: [],
    accounts: [],
    accountingReference: [],
    departments: [],
    categories: [],
    subcategories: [],
    categoriesToDisplay: [],
    subcategoriesToDisplay: [],
    chosenDepartmentId: null,
    chosenAccountingReferenceId: null,
    chosenCategoryId: null,
    chosenSubcategoryId: null,
    tax_line_id: null,
    availableTaxLines: [],
    customerTypes: [],
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    search: "",
    sort: "alpha",
  });

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    console.log(value);
    //Handle Tag Input - Deep Object Value - Ignore ','
    if (id.includes("[")) {
      if (value !== ",") {
        const tempProductState = productState;
        _.set(tempProductState, id, value);
        setProductState(() => ({ ...tempProductState }));
      }
    } else {
      setProductState(prevState => ({ ...prevState, [id]: value }));
    }
  }

  function handleCheckboxChange(event: any) {
    const { id, checked } = event.target;
    setProductState(prevState => ({ ...prevState, [id]: checked }));
  }

  function handleCreateProduct() {
    void createProduct();
  }

  useEffect(() => {
    void loadAccounts();
    void loadCustomerTypes();
    void loadAccountingReference();
    void loadDepartments();
    void loadTaxLines();
  }, []);

  async function loadAccounts() {
    const accountRes = await GetAccounts(true);
    console.log(accountRes);
    if (accountRes.status !== StatusCode.OK) {
      return;
    }

    const accounts = accountRes.data;

    accounts.unshift({
      id: null,
      title: "",
    });

    setProductOptionState(prev => ({
      ...prev,
      accounts: accountRes.data,
    }));
  }

  async function loadCustomerTypes() {
    const customerTypeRes = await GetCustomerType(null, true);
    console.log(customerTypeRes);
    if (customerTypeRes.status !== StatusCode.OK) {
      return;
    }

    const accounts = customerTypeRes.data;

    setProductOptionState(prev => ({
      ...prev,
      customerTypes: customerTypeRes.data,
    }));
  }

  async function loadAccountingReference() {
    const accountingRes = await GetAccountingReference(null, true);
    console.log(accountingRes);
    if (accountingRes.status !== StatusCode.OK) {
      return;
    }

    const accounts = accountingRes.data;

    accounts.unshift({
      id: null,
      title: "",
    });

    setProductOptionState(prev => ({
      ...prev,
      accountingReference: accountingRes.data,
    }));
  }

  async function loadDepartments() {
    const departmentRes = await GetDepartments(true);
    console.log(departmentRes);
    if (departmentRes.status !== StatusCode.OK) {
      return;
    }

    const departments = departmentRes.data;
    departments.unshift({
      id: null,
      title: "",
      type: "department",
    });

    setProductOptionState(prev => ({
      ...prev,
      departments: departmentRes.data,
    }));
  }

  async function createProduct() {
    const tempProductState = productState;

    //Remove blank options
    for (let i = 0; i < tempProductState.options.length; i++) {
      if (tempProductState.options[i].name.trim() === "") {
        tempProductState.options.splice(i, 1);
      } else {
        //Remove blank variants
        for (let j = 0; j < tempProductState.options[i].values.length; j++) {
          if (tempProductState.options[i].values[j].trim() === "") {
            tempProductState.options[i].values.splice(j, 1);
          }
        }
      }
    }

    tempProductState.meta.membership_type = tempProductState.membership_type;
    tempProductState.meta.customer_type_id = tempProductState.customer_type_id;

    const productRes = await PostProduct(tempProductState, true);
    if (productRes.status !== StatusCode.OK) {
      return;
    }

    history.push("/admin/membership");

    return;
  }

  function renderChargeTaxes() {
    return [
      <Checkbox
        key={1}
        id="taxable"
        size="medium"
        value={productState.taxable}
        checked={productState.taxable}
        onChange={handleCheckboxChange}
        label="Charge Taxes"
      />,
      <React.Fragment key={2}>
        {productState.taxable ? (
          <FormLayout.Group>
            <div>
              <Select label="Tax Lines" onChange={(value: any) => handleTaxDropDownChange(value, "tax_line_id")}>
                {productOptionState.availableTaxLines.map((taxLine: any, index: number) => {
                  return (
                    <Option key={index} value={taxLine.id} name={taxLine.name}>
                      {taxLine.name}
                    </Option>
                  );
                })}
              </Select>

              <div>
                {productState.tax_line_ids.map((taxLine: any, index: number) => {
                  let taxName = "";
                  console.log(taxLine);
                  console.log(productOptionState.availableTaxLines);

                  for (let i = 0; i < productOptionState.availableTaxLines.length; i++) {
                    if (taxLine === productOptionState.availableTaxLines[i].id) {
                      taxName = productOptionState.availableTaxLines[i].name;
                    }
                  }

                  return (
                    <div key={index} style={{ display: "flex", justifyContent: "space-between" }}>
                      <p>{taxName}</p>

                      <button onClick={() => removeTaxLine(taxLine)}>X - Remove</button>
                    </div>
                  );
                })}
              </div>
            </div>

            <Button type="default" onClick={handleAddTaxLine}>
              Add Tax
            </Button>
          </FormLayout.Group>
        ) : null}
      </React.Fragment>,
    ];
  }
  async function loadTaxLines() {
    const taxLineRes = await GetTaxLines(true);

    console.log(taxLineRes);

    console.log(taxLineRes);
    if (taxLineRes.status !== StatusCode.OK) {
      return;
    }

    setProductOptionState(prev => ({
      ...prev,
      availableTaxLines: taxLineRes.data,
    }));
  }

  function handleSelectChange(event: any) {
    const { id, value } = event.target;
    console.log(id, value);
    setProductState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleDropDownChange(value: any, property: string) {
    setProductState(prevState => ({ ...prevState, [property]: value }));
  }

  function handleTaxDropDownChange(value: any, property: string) {
    setProductOptionState(prevState => ({ ...prevState, [property]: value }));
  }

  function addOption(e: any) {
    e.preventDefault();
    const options = productState.options;

    // Can only add 3 options
    if (options.length >= 3) {
      return;
    }

    options.push({ name: "", values: [""] });

    setProductState(prevState => ({ ...prevState, options: options }));
    console.log(productState);
  }

  function handleTagClick(optionIndex: number, index: number) {
    const tmpProductState = productState;

    tmpProductState.options[optionIndex].values.splice(index, 1);
    setProductState(() => ({ ...tmpProductState }));
  }

  function handleAddTag(event: any) {
    const { key } = event;
    const { id } = event.target;
    const regex = /^(?!\s,*$).+/g;
    const tmpProductState = productState;
    const arrayId: string = id.substring(0, id.length - 3);

    if (key === "Enter" || key === ",") {
      if (_.get(tmpProductState, id).match(regex) !== null) {
        _.invoke(tmpProductState, `${arrayId}.push`, "");
        setProductState(prevState => ({ ...prevState, ...tmpProductState }));
      }
    }
  }

  const primaryAction = {
    content: "Create",
    action: handleCreateProduct,
  };

  const membershipOptions = [
    {
      id: "single_course",
      title: "Single",
    },
    {
      id: "multi_course",
      title: "Multi-Course",
    },
  ];

  function updateFilterState(newFilterState: Partial<IFilterState>) {
    setFilterState(cur => {
      return { ...cur, ...newFilterState };
    });
  }

  async function handleAddProduct(event: any, ticket: any) {
    //Add product ID to product
  }

  function handleAddTaxLine(event: any) {
    console.log(productOptionState.tax_line_id);

    const tempTaxLineList = productState.tax_line_ids;

    if (tempTaxLineList.includes(productOptionState.tax_line_id)) {
      return;
    }

    tempTaxLineList.push(productOptionState.tax_line_id);

    setProductState(prevState => ({ ...prevState, tax_line_ids: tempTaxLineList }));
  }

  function removeTaxLine(taxLine: any) {
    console.log(taxLine);
    console.log(productState.tax_line_ids);

    const tempTaxLineList = productState.tax_line_ids;

    console.log(tempTaxLineList);

    const taxIndexToRemove = tempTaxLineList.indexOf(taxLine);

    if (taxIndexToRemove > -1) {
      tempTaxLineList.splice(taxIndexToRemove, 1);
    }

    console.log(tempTaxLineList);

    setProductState(prevState => ({ ...prevState, tax_line_ids: tempTaxLineList }));
  }

  return (
    <Page
      title="New Membership"
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[{ label: "Memberships", url: "/admin/membership" }]}
    >
      <Form>
        <Card title="Product Details">
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={productState.title}
                  label="Title"
                  id="title"
                  onChange={handleInputChange}
                  placeholder="Title"
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  value={productState.description || ""}
                  label="Description"
                  id="description"
                  onChange={handleInputChange}
                  placeholder="Description"
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
          <Card.Section title="Pricing">
            <FormLayout>
              <FormLayout.Group>
                <Input
                  prefix="$"
                  value={productState.price || ""}
                  label="Price"
                  id="price"
                  onChange={handleInputChange}
                  placeholder="0.00"
                />
                <Input
                  prefix="$"
                  value={productState.original_price || ""}
                  label="Original Price"
                  id="original_price"
                  onChange={handleInputChange}
                  placeholder="0.00"
                />
                <Input
                  prefix="$"
                  value={productState.cost || ""}
                  label="Cost"
                  id="cost"
                  onChange={handleInputChange}
                  placeholder="0.00"
                />
                <div className="mt-4">
                  <p className="product-profit-margin">
                    {displayPercent(
                      roundNumber(100 * ((productState.price - productState.cost) / productState.price), 0),
                    )}{" "}
                    -- {displayCurrency("cad", productState.price - productState.cost)}
                  </p>
                  <p className="product-profit-margin">Profit Margin</p>
                </div>
              </FormLayout.Group>
              <FormLayout.Group>
                {windowSize.width <= TABLET_WIDTH ? <div>{renderChargeTaxes()}</div> : renderChargeTaxes()}
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>

        <Card title="Variants">
          <Card.Section>
            <Checkbox
              id="multiple_options"
              size="medium"
              value={productState.multiple_options}
              onChange={handleCheckboxChange}
              label="This product has multiple options"
            />
            {productState.multiple_options ? (
              <>
                {productState.options.map((option: any, index: number) => {
                  return (
                    <Card.SubSection key={index}>
                      <FormLayout>
                        <FormLayout.Group>
                          <Input
                            value={option.name || ""}
                            label={`Option ${index + 1}`}
                            id={`[options][${index}][name]`}
                            onChange={handleInputChange}
                            placeholder="Name"
                          />
                          <Input
                            value={option.values[option.values.length === 0 ? 0 : option.values.length - 1]}
                            label="Values"
                            labelHidden
                            id={`[options][${index}][values][${
                              option.values.length === 0 ? 0 : option.values.length - 1
                            }]`}
                            onChange={handleInputChange}
                            onKeyDown={handleAddTag}
                            placeholder="Values"
                            tagType="dark"
                            tags={option.values.slice(0, option.values.length - 1)}
                            onTagClick={(tagIndex: number) => handleTagClick(index, tagIndex)}
                            expandable
                          />
                        </FormLayout.Group>
                      </FormLayout>
                    </Card.SubSection>
                  );
                })}
                {productState.options.length < 3 ? <Button onClick={e => addOption(e)}>Add Option</Button> : null}
              </>
            ) : null}
          </Card.Section>
        </Card>

        <Card title="Options">
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <div>
                  <Select
                    label="Membership Type"
                    onChange={(value: any) => handleDropDownChange(value, "membership_type")}
                  >
                    {membershipOptions.map((type: any, index: number) => {
                      return (
                        <Option key={index} value={type.title} name={type.title}>
                          {type.title}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
                <div>
                  <Select
                    label="Customer Type"
                    onChange={(value: any) => handleDropDownChange(value, "customer_type_id")}
                  >
                    {productOptionState.customerTypes.map((type: any, index: number) => {
                      return (
                        <Option key={index} value={type.id} name={type.title}>
                          {type.title}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>

        <Card title="Logistics">
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <div>
                  <Select label={"Type"} onChange={(value: any) => handleDropDownChange(value, "type")}>
                    {productOptionState.types.map((type: any, index: number) => {
                      return (
                        <Option key={index} value={type.title} name={type.title}>
                          {type.title}
                        </Option>
                      );
                    })}
                  </Select>
                </div>

                <div>
                  <Select
                    label="Accounting Reference"
                    onChange={(value: any) => handleDropDownChange(value, "accounting_reference_id")}
                  >
                    {productOptionState.accountingReference.map((accountingReference: any, index: number) => {
                      return (
                        <Option key={index} value={accountingReference.id} name={accountingReference.title}>
                          {accountingReference.title}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
                <div>
                  <Select label="Accounts" onChange={(value: any) => handleDropDownChange(value, "account_id")}>
                    {productOptionState.accounts.map((account: any, index: number) => {
                      return (
                        <Option key={index} value={account.id} name={account.title}>
                          {account.title}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
          <Card.Section title="Departments">
            <FormLayout>
              <FormLayout.Group>
                <div>
                  <Select label="Department" onChange={(value: any) => handleDropDownChange(value, "department_id")}>
                    {productOptionState.departments
                      .filter(department => department.type === "department")
                      .map((department: any, index: number) => {
                        return (
                          <Option key={index} value={department.id} name={department.title}>
                            {department.title}
                          </Option>
                        );
                      })}
                  </Select>
                </div>

                <div>
                  <Select label="Category" onChange={(value: any) => handleDropDownChange(value, "category_id")}>
                    {productOptionState.departments
                      .filter(
                        department =>
                          department.type == "category" && department.parent_id == Number(productState.department_id),
                      )
                      .map((department: any, index: number) => {
                        return (
                          <Option key={index} value={department.id} name={department.title}>
                            {department.title}
                          </Option>
                        );
                      })}
                  </Select>
                </div>

                <div>
                  <Select label="Subcategory" onChange={(value: any) => handleDropDownChange(value, "subcategory_id")}>
                    {productOptionState.departments
                      .filter(
                        department =>
                          department.type == "subcategory" && department.parent_id == Number(productState.category_id),
                      )
                      .map((department: any, index: number) => {
                        return (
                          <Option key={index} value={department.id} name={department.title}>
                            {department.title}
                          </Option>
                        );
                      })}
                  </Select>
                </div>
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>

        <br />
      </Form>
    </Page>
  );
}
