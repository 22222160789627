import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { GetOrder } from "api/rpc/guest/order";

import { loadFacility, loadTournament } from "redux/actions/customer/tournamentRegistration";
import { dequeue, enqueue, showError } from "redux/actions/ui";
import { displayCurrency } from "helpers/Helpers";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import Card from "components/card/Card";
import Page from "components/page/Page";
import OrderSummary from "components/OrderSummary/OrderSummary";
import PaymentSummary, { IPaymentSummaryData } from "components/PaymentSummary/PaymentSummary";
import "./TournamentConfirmation.scss";
import TournamentHeader from "./TournamentHeader";
import { IOrder } from "../models/order";
import { IFacility } from "../models/facility";

interface ITournamentConfirmationParams {
  facilityShortName: string;
  tournamentHandle: string;
  orderToken: string;
}

export default function TournamentConfirmation() {
  const { t, i18n } = useTranslation();
  const { facilityShortName, tournamentHandle, orderToken } = useParams<ITournamentConfirmationParams>();

  const dispatch = useAppDispatch();
  const { tournamentRegistrationStore } = useAppSelector(store => store);

  const [tournamentConfirmationState, setTournamentConfirmationState] = useState<{ order: IOrder }>({
    order: undefined,
  });

  useEffect(() => {
    void loadOrder();
  }, []);

  async function loadOrder() {
    let facility: IFacility = undefined;

    dispatch(enqueue());

    try {
      const getFacilityPromise: Promise<IFacility> = tournamentRegistrationStore.facility
        ? Promise.resolve(tournamentRegistrationStore.facility)
        : dispatch(loadFacility(facilityShortName, true));

      facility = await getFacilityPromise;
    } catch {
      dispatch(showError(t("guest.tournament_registration.tournament_confirmation.001")));
      dispatch(dequeue());
      return;
    }

    const facilityId = facility.id;

    try {
      const getTournamentPromise = tournamentRegistrationStore.tournament
        ? Promise.resolve(tournamentRegistrationStore.tournament)
        : dispatch(loadTournament(facilityId, tournamentHandle, true));

      await getTournamentPromise;
    } catch {
      dispatch(showError(t("guest.tournament_registration.tournament_confirmation.002")));
      dispatch(dequeue());
      return;
    }

    const getOrderResponse = await GetOrder({ token: orderToken }, true);

    if (getOrderResponse.status !== StatusCode.OK) {
      dispatch(showError(t("guest.tournament_registration.tournament_confirmation.003")));
      dispatch(dequeue());
      return;
    }

    setTournamentConfirmationState(prevState => ({
      ...prevState,
      order: getOrderResponse.data?.[0],
    }));

    dispatch(dequeue());
  }

  function getPaymentSummary() {
    const summaryData: IPaymentSummaryData[] = [
      {
        label: t("guest.tournament_registration.tournament_confirmation.005"),
        values: [
          tournamentConfirmationState.order.customer?.full_name,
          tournamentConfirmationState.order.customer?.email,
        ],
      },
    ];

    if (tournamentConfirmationState.order?.customer_notes) {
      summaryData.push({
        label: t("guest.tournament_registration.tournament_confirmation.007"),
        values: [tournamentConfirmationState.order.customer_notes],
      });
    }

    if (tournamentConfirmationState.order.transactions?.[0]) {
      summaryData.push({
        label: t("guest.tournament_registration.tournament_confirmation.006"),
        values: [
          `•••• ${tournamentConfirmationState.order.transactions?.[0]?.payment_last_4}`,
          displayCurrency("cad", tournamentConfirmationState.order.transactions?.[0]?.amount),
        ],
      });
    }

    return summaryData;
  }

  return (
    <Page>
      <TournamentHeader
        facility={tournamentRegistrationStore.facility}
        tournament={tournamentRegistrationStore.tournament}
      />
      {tournamentRegistrationStore.facility &&
        tournamentRegistrationStore.tournament &&
        tournamentConfirmationState.order && (
          <div>
            <div className="tournament-confirmation-page-sections">
              <div className="tournament-confirmation-information-section">
                <Card>
                  <Card.Section>
                    <h2 className="tournament-confirmation-information-section-title">{`Order ${tournamentConfirmationState.order.name}`}</h2>
                    <h3 className="tournament-confirmation-information-section-subtitle">
                      {`${t("guest.tournament_registration.tournament_confirmation.004")} ${
                        tournamentConfirmationState.order.customer?.first_name
                      }.`}
                    </h3>
                  </Card.Section>
                </Card>
                <Card>
                  <Card.Section>
                    <PaymentSummary summaryData={getPaymentSummary()} />
                  </Card.Section>
                </Card>
              </div>
              <div className="tournament-confirmation-order-summary-section">
                <OrderSummary
                  subtotal={tournamentConfirmationState.order.subtotal_price}
                  taxLines={tournamentConfirmationState.order.tax_lines}
                  discount={tournamentConfirmationState.order.total_discount}
                  total={tournamentConfirmationState.order.total_price}
                  displayLineItemsWithoutToggle={true}
                  lineItems={tournamentConfirmationState.order.line_items.map(lineItem => {
                    return {
                      src: lineItem.product_default_image?.source,
                      productTitle: lineItem.product_title,
                      variantTitle: lineItem.variant_title,
                      quantity: lineItem.quantity,
                      price: lineItem.subtotal_price,
                    };
                  })}
                />
              </div>
            </div>
          </div>
        )}
    </Page>
  );
}
