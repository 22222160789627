import React, { useEffect } from "react";
import { Route, Switch } from "react-router";
import { BrowserRouter, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getBrowserLocales } from "helpers/Locale";

import ProtectedRoute from "routes/ProtectedRoute";
import ClientAdmin from "containers/client/admin";
import FacilityAdmin from "containers/facility/admin";
import MasterAdmin from "pages/secure/master/Admin";

import Dashboard from "pages/secure/facility/dashboard/Dashboard";
import Customers from "pages/secure/facility/customer/main/Customers";
import Register from "pages/secure/facility/register/Register";

import ProductRoutes from "routes/ProductRoutes";
import OrderRoutes from "routes/OrderRoutes";
import EventRoutes from "routes/EventRoutes";
import TournamentRoutes from "routes/TournamentRoutes";
import LeagueRoutes from "routes/LeagueRoutes";

import Landing from "pages/guest/Landing";
import Login from "pages/guest/Login";
import Lookup from "containers/lookup";
import TeeTimeList from "containers/bookingEngine/teetimes";

import { AdminPermission, UserType } from "redux/reducers/models/user";
import { UserActive } from "./api/rpc";
import { StatusCode } from "./api/protocols";

import TeeTimeDetails from "containers/bookingEngine/teetimeDetails";

import TeeTimeGolfer from "containers/bookingEngine/teetimeGolfer";
import TeeTimeCart from "containers/bookingEngine/teetimeCart";
import TeeTimeCheckout from "containers/bookingEngine/teetimeCheckout";
import TeeTimeConfirmation from "containers/bookingEngine/teetimeConfirmation";
import TeeTimeCapture from "containers/bookingEngine/teetimeCapture";
import TeeTimeSignIn from "containers/bookingEngine/teetimeLogin";
import TeeTimeRegister from "containers/bookingEngine/teetimeRegister";
import TeeTimeForgotPassword from "containers/bookingEngine/forgotPassword";
import TeeTimeCustomerProfile from "containers/bookingEngine/customerProfile";
import TeeTimeEditBooking from "containers/bookingEngine/editBooking";
import TeeTimePaymentTerms from "containers/bookingEngine/paymentTerms";
import CustomerBooking from "containers/bookingEngine/customerBooking";

import { IAuthActions, initialize, userLogin } from "redux/actions/auth";
import { IUIActions, closeError, closeSuccess } from "redux/actions/ui";
import { IUIState } from "redux/reducers/ui";
import { IAuthState } from "redux/reducers/auth";
import NewPassword from "pages/guest/Customer/NewPassword";

import UpdatesPage from "pages/guest/Updates/UpdatesPage";
import StoreHomePage from "containers/OnlineStore/HomePage";
import SingleProductPage from "containers/OnlineStore/SingleProduct";
import ViewShoppingBagPage from "containers/OnlineStore/ViewShoppingBag";
import CheckoutPage from "containers/OnlineStore/Checkout";
import FormsPage from "containers/OnlineStore/Forms";
import PaymentPage from "containers/OnlineStore/Payment";
import ConfirmationPage from "containers/OnlineStore/Confirmation";
import { CustomerTeeTimeList } from "pages/guest/Customer/CustomerTeeTimeList";
import customerBookingConfirmation from "containers/bookingEngine/customerBookingConfirmation";
import UserMenuNav from "containers/bookingEngine/userMenuNav";
import Reservations from "containers/reservations/reservations";
import ReservationsLogin from "containers/reservations/reservationsLogin";
import ReservationsRegister from "containers/reservations/reservationsRegister";
import ReservationsForgotPassword from "containers/reservations/reservationsForgotPassword";
import Reservation from "containers/reservations/reservation";
import reservationConfirmation from "containers/reservations/reservationConfirmation";
import LeagueRegister from "containers/League/LeagueRegistration/LeagueRegister";
import LeagueCheckout from "containers/League/LeagueRegistration/LeagueCheckout";
import LeagueConfirmation from "pages/guest/League/LeagueRegistration/LeagueConfirmation";
import { RouteProvider } from "routes/RouteContext";
import TournamentHome from "containers/TournamentRegistration/TournamentHome";
import TournamentCheckout from "containers/TournamentRegistration/TournamentCheckout";
import TournamentConfirmation from "pages/guest/TournamentRegistration/TournamentConfirmation";
import LeagueSeasonLeaderboard from "pages/guest/League/LeagueScoring/LeagueSeasonLeaderboard";
import LeagueSeasonLeaderboardTotals from "pages/guest/League/LeagueScoring/LeagueSeasonLeaderboardTotals";
import LeagueScores from "pages/guest/League/LeagueScoring/LeagueScores";
import LeagueLeaderboard from "pages/guest/League/LeagueScoring/LeagueLeaderboard";
import LeagueHome from "pages/guest/League/LeagueHome/LeagueHome";
import LeagueAnnouncements from "pages/guest/League/LeagueAnnouncements/LeagueAnnouncements";
import Toast from "components/toast/Toast";
import PortalLogin from "pages/guest/Portal/PortalLogin";
import PortalOverview from "pages/guest/Portal/PortalOverview";
import PortalBookings from "pages/guest/Portal/PortalBookings/PortalBookings";
import PortalMembership from "pages/guest/Portal/PortalMembership";
import { GuestPortalProvider } from "pages/guest/Portal/GuestPortalContext";
import { PortalNavigation } from "pages/guest/Portal/Navigation/PortalNavigation";
import ForgotPassword from "elements/ForgotPassword";
import ViewPortalBooking from "pages/guest/Portal/PortalBookings/ViewPortalBooking";
import EditPortalBooking from "pages/guest/Portal/PortalBookings/EditPortalBooking";
import PortalPaymentMethods from "pages/guest/Portal/PortalPaymentMethods";
import { ClientPortalHome } from "pages/guest/Customer/ClientPortal/Home/ClientPortalHome";
import ClientPortalBooking from "pages/guest/Customer/ClientPortal/Booking/ClientPortalBooking";
import ClientPortalBookingConfirmation from "pages/guest/Customer/ClientPortal/Booking/ClientPortalBookingConfirmation";
import PortalRegister from "pages/guest/Portal/PortalRegister";
import PortalTickets from "pages/guest/Portal/PortalTickets";
import ClientPortalBookingsList from "pages/guest/Customer/ClientPortal/BookingList/ClientPortalBookingsList";
import ClientPortalMemberships from "pages/guest/Customer/ClientPortal/Memberships/ClientPortalMemberships";
import PortalReservations from "pages/guest/Portal/PortalReservations/PortalReservations";
import ViewPortalReservation from "pages/guest/Portal/PortalReservations/ViewPortalReservation";
import PortalPreferences from "pages/guest/Portal/PortalPreferences";
import PortalPlayingPartners from "pages/guest/Portal/PortalPlayingPartners";
import PortalCourseList from "pages/guest/Portal/CourseList/PortalCourseList";
import EditPortalReservation from "pages/guest/Portal/PortalReservations/EditPortalReservation";
import PortalFavourites from "pages/guest/Portal/Favourites/PortalFavourites";
import ClientPortalEvents from "pages/guest/Customer/ClientPortal/Events/ClientPortalEvents";
import ClientPortalCalendar from "pages/guest/Customer/ClientPortal/Calendar/ClientPortalCalendar";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import CalendarIFrame from "public/CalendarIFrame";
import CancelWaitTime from "pages/guest/Customer/ClientPortal/WaitList/CancelWaitTime";

const _BrowserRouter = BrowserRouter as any;

const App = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { uiStore, authStore } = useAppSelector(store => store);

  useEffect(() => {
    void checkUserLogin();
  }, []);

  async function checkUserLogin() {
    const activeUserRes = await UserActive(true);

    if (activeUserRes.status === StatusCode.OK) {
      dispatch(userLogin(activeUserRes.data));
      localStorage.setItem("localeLanguage", activeUserRes.data.primary_locale);
      localStorage.setItem("localeLanguageWithCountry", activeUserRes.data.primary_locale_country);

      dispatch(initialize(true));
    } else {
      // Set the locale based on the browser
      const browserLocale = getBrowserLocales();
      localStorage.setItem("localeLanguage", browserLocale[0]);
      localStorage.setItem("localeLanguageWithCountry", browserLocale[1]);

      dispatch(initialize(false));
    }
  }

  let adminLevel;
  let permissionLevel;

  switch (authStore.user.user_type) {
    case UserType.CLIENT_ADMIN:
      adminLevel = ClientAdmin;
      permissionLevel = UserType.CLIENT_ADMIN;
      break;

    case UserType.FACILITY_ADMIN:
      adminLevel = FacilityAdmin;
      permissionLevel = UserType.FACILITY_ADMIN;
      break;

    case UserType.MASTER_ADMIN:
      adminLevel = MasterAdmin;
      permissionLevel = UserType.MASTER_ADMIN;
      break;
  }

  return (
    <React.Fragment>
      <_BrowserRouter>
        <RouteProvider>
          <Switch>
            {/* Standard Customer Admin */}
            {/* <ProtectedRoutepermission={AdminPermission.FACILITY_ADMIN}
              path="/admin/dashboard"
              component={Admin}
              topBarTitle="Dashboard"
            /> */}

            {/* Client Admin */}
            {/* <ProtectedRoute
              path="/admin"
              component={ClientAdmin}
              permission={UserType.CLIENT_ADMIN}
              topBarTitle="Dashboard"
            /> */}

            {/* Facility Admin */}
            <ProtectedRoute path="/admin" component={adminLevel} permission={permissionLevel} topBarTitle="Dashboard" />

            {/* Master Admin */}
            {/* <ProtectedRoute
              permission={AdminPermission.MASTER_ADMIN}
              path="/master-admin/dashboard"
              component={Admin}
              topBarTitle="Dashboard"
            /> */}

            {/* <Route exact path="/tee-on/portal/overview" component={() => <PortalOverview />} />
              <Route exact path="/tee-on/portal/reservations" component={() => <PortalReservations />} /> */}

            {/* Online Store Views */}
            <Route exact path="/online-store/:facilityShortName" component={StoreHomePage} />
            <Route path="/online-store/:facilityShortName/shoppingBag" component={ViewShoppingBagPage} />
            <Route path="/online-store/:facilityShortName/checkout/:scrollLocation?" component={CheckoutPage} />
            <Route path="/online-store/:facilityShortName/forms" component={FormsPage} />
            <Route path="/online-store/:facilityShortName/payment" component={PaymentPage} />
            <Route path="/online-store/:facilityShortName/confirmation/:orderToken" component={ConfirmationPage} />
            <Route path="/online-store/:facilityShortName/product/:productHandle" component={SingleProductPage} />

            {/* League Views */}

            <Route exact path="/league/:facilityShortName/:leagueHandle/home" component={LeagueHome} />
            <Route exact path="/league/:facilityShortName/:leagueHandle/register" component={LeagueRegister} />
            <Route exact path="/league/:facilityShortName/:leagueHandle/register/checkout" component={LeagueCheckout} />
            <Route
              exact
              path="/league/:facilityShortName/:leagueHandle/register/confirmation/:orderToken/"
              component={LeagueConfirmation}
            />
            <Route
              exact
              path="/league/:facilityShortName/:leagueHandle/announcements"
              component={LeagueAnnouncements}
            />
            <Route exact path="/league/:facilityShortName/:leagueHandle/scoring/" component={LeagueScores} />
            <Route
              exact
              path="/league/:facilityShortName/:leagueHandle/scoring/season-leaderboard"
              component={LeagueSeasonLeaderboard}
            />
            <Route
              exact
              path="/league/:facilityShortName/:leagueHandle/scoring/results/:scoresheetId"
              component={LeagueLeaderboard}
            />
            <Route
              exact
              path="/league/:facilityShortName/:leagueHandle/scoring/season-leaderboard/:seasonLeaderboardId"
              component={LeagueSeasonLeaderboardTotals}
            />

            {/* Tournament Views */}

            <Route exact path="/tournament/:facilityShortName/:tournamentHandle/" component={TournamentHome} />
            <Route
              exact
              path="/tournament/:facilityShortName/:tournamentHandle/checkout"
              component={TournamentCheckout}
            />
            <Route
              exact
              path="/tournament/:facilityShortName/:tournamentHandle/:orderToken/confirmation"
              component={TournamentConfirmation}
            />

            {/* -- Booking Engine Views -- */}

            <Route path="/account/reset" component={NewPassword} />

            <Route path="/waitlist/cancel" component={CancelWaitTime} />

            {/* Booking Engine Landing Page */}
            <Route exact path="/booking-engine/:facilityShortName" component={Landing} />

            <Route
              path="/booking-engine/:facilityShortName/:bookingEngineHandle/account/forgot"
              component={TeeTimeForgotPassword}
            />
            <Route
              path="/booking-engine/:facilityShortName/:bookingEngineHandle/confirm"
              component={customerBookingConfirmation}
            />

            <Route path="/booking-engine/:facilityShortName/:bookingEngineHandle/holes" component={CustomerBooking} />
            <Route
              path="/booking-engine/:facilityShortName/:bookingEngineHandle/register"
              component={TeeTimeRegister}
            />
            <Route path="/booking-engine/:facilityShortName/:bookingEngineHandle/login" component={TeeTimeSignIn} />
            <Route path="/booking-engine/:facilityShortName/:bookingEngineHandle/profile" component={UserMenuNav} />
            <Route path="/booking-engine/:facilityShortName/:bookingEngineHandle" component={CustomerTeeTimeList} />
            <Route path="/lookup" component={Lookup} />
            <Route exact path="/login" component={Login} />

            {/* Client Portal */}
            <Route path="/portal/:clientShortName/teetimes/:facilityShortName?" component={ClientPortalHome} />
            <Route path="/portal/:clientShortName/booking" component={ClientPortalBooking} />
            <Route path="/portal/:clientShortName/confirm" component={ClientPortalBookingConfirmation} />
            <Route path="/portal/:clientShortName/bookings-list" component={ClientPortalBookingsList} />
            <Route path="/portal/:clientShortName/memberships" component={ClientPortalMemberships} />
            <Route path="/portal/:clientShortName/events/:facilityShortName?" component={ClientPortalEvents} />
            <Route path="/portal/:clientShortName/calendar" component={ClientPortalCalendar} />

            {/* The same login/register pages are used for Client Portal and Tee On Portal */}
            <Route path="/portal/:clientShortName/login" component={PortalLogin} />
            <Route path="/portal/:clientShortName/register" component={PortalRegister} />
            <Route path="/portal/:clientShortName/forgot" component={ForgotPassword} />

            <Route path="/embed/calendar/:facilityShortName" component={CalendarIFrame} />

            {/* GUEST VIEWS */}
            <Route path="/updates" component={UpdatesPage} />

            <Route path="/tee-on/portal">
              <GuestPortalProvider>
                <Route exact path="/tee-on/portal/login" component={PortalLogin} />
                <Route exact path="/tee-on/portal/register" component={PortalRegister} />

                <Route
                  path={[
                    "/tee-on/portal/overview",
                    "/tee-on/portal/bookings",
                    "/tee-on/portal/bookings/:id/view",
                    "/tee-on/portal/bookings/:id/edit",
                    "/tee-on/portal/payment-methods",
                    "/tee-on/portal/tickets",
                    "/tee-on/portal/reservations",
                    "/tee-on/portal/reservations/:token/view",
                    "/tee-on/portal/playing-partners",
                    "/tee-on/portal/reservations/:token/edit",
                    "/tee-on/portal/memberships",
                    "/tee-on/portal/favourites",
                    "/tee-on/portal/preferences",
                  ]}
                >
                  <PortalNavigation>
                    <Route path="/tee-on/portal/overview" component={PortalOverview} />
                    <Route exact path="/tee-on/portal/bookings" component={PortalBookings} />
                    <Route exact path="/tee-on/portal/bookings/:id/view" component={ViewPortalBooking} />
                    <Route exact path="/tee-on/portal/bookings/:id/edit" component={EditPortalBooking} />
                    <Route exact path="/tee-on/portal/reservations" component={PortalReservations} />
                    <Route exact path="/tee-on/portal/reservations/:token/view" component={ViewPortalReservation} />
                    <Route exact path="/tee-on/portal/reservations/:token/edit" component={EditPortalReservation} />
                    <Route exact path="/tee-on/portal/payment-methods" component={PortalPaymentMethods} />
                    <Route exact path="/tee-on/portal/tickets" component={PortalTickets} />
                    <Route exact path="/tee-on/portal/preferences" component={PortalPreferences} />
                    <Route exact path="/tee-on/portal/playing-partners" component={PortalPlayingPartners} />
                    <Route exact path="/tee-on/portal/memberships" component={PortalMembership} />
                    <Route exact path="/tee-on/portal/favourites" component={PortalFavourites} />
                  </PortalNavigation>
                </Route>
              </GuestPortalProvider>
              <Route path="/tee-on/portal/forgot" component={ForgotPassword} />

              {/* <Redirect to="/tee-on/portal/login" /> */}
            </Route>

            {/* <Route exact path="/tee-on/courses" component={PortalCourseList} /> */}

            {/* Reservations Views */}
            <Route exact path="/:facilityShortName/:moduleHandle" component={Reservations} />
            <Route exact path="/:facilityShortName/:moduleHandle/booking" component={Reservation} />
            <Route exact path="/:facilityShortName/:moduleHandle/confirmation" component={reservationConfirmation} />
            <Route exact path="/:facilityShortName/:moduleHandle/login" component={ReservationsLogin} />
            <Route exact path="/:facilityShortName/:moduleHandle/register" component={ReservationsRegister} />
            <Route exact path="/:facilityShortName/:moduleHandle/forgot" component={ReservationsForgotPassword} />

            <Redirect to="/login" />
          </Switch>

          {/* uiStore SUCCESS + ERROR Messages */}
          <Toast
            type={"error"}
            title={uiStore.errorMessage}
            duration={uiStore.duration}
            onClose={() => dispatch(closeError())}
            mounted={uiStore.showError}
            animated
          />

          <Toast
            type={"success"}
            title={uiStore.successMessage}
            duration={uiStore.duration}
            onClose={() => dispatch(closeSuccess())}
            mounted={uiStore.showSuccess}
            animated
          />
        </RouteProvider>
      </_BrowserRouter>
    </React.Fragment>
  );
};

export default App;
