import React, { useEffect, useState, useRef } from "react";
import axios, { CancelToken } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";
import moment from "moment";

import { deSelectTeeTime, updateTemporaryBooking } from "redux/actions/clientAdmin/teesheet";
import { closeBooking, showError, showSuccess, enqueue, dequeue } from "redux/actions/ui";
import { ICustomer } from "redux/reducers/models/customer";
import { ISlot, ITeeTimeNew } from "redux/reducers/models/teetime";

import { StatusCode } from "api/protocols";
import {
  PostBookingNote,
  IPostBooking,
  PostBooking,
  PutBookingConfirmation,
} from "api/rpc/2024-04/clientAdmin/teesheet/booking";
import {
  PostCustomer,
  GetCustomer,
  GetCustomerPaymentMethod,
  PostPaymentMethod,
  PostSetup,
} from "api/rpc/2024-04/clientAdmin/customer/customer";
import { GetTeeTime } from "api/rpc/2024-04/clientAdmin/teesheet/teeSheet";
import { PostTeeTimeLock, DeleteTeeTimeLocks } from "api/rpc/clientAdmin/teeSheet/teetime";

import { convertTime, customerErrorMessage, delay } from "helpers/Helpers";
import useOutsideAlerter from "hooks/useOutsideAlerter/useOutsideAlerter";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import Sheet from "components/sheet/Sheet";
import Tabs from "components/tabs/Tabs";
import Checkbox from "components/form/checkbox/Checkbox";
import { Select } from "components/select/index";
import GolferCard from "components/bookingPopUp/golferCard/GolferCard";
import Note from "components/bookingPopUp/note/Note";
import TextField from "components/form/textField/TextField";
import Radio from "components/radio";
import { Dropdown } from "components/dropdown/dropdown";
import Popup from "components/popup/Popup";
import NewCustomer, { ICustomerInfoState } from "components/newCustomer/NewCustomer";
import CreditCardCatalogue from "elements/teesheet/creditCard/CreditCardCatalogue";
import CreditCardAdd from "elements/teesheet/creditCard/CreditCardAdd";
import { useTranslation } from "react-i18next";

import "public/scss/teesheet.scss";
import "elements/teesheet/bookingModal.scss";
import Portal from "elements/Portal";
import Spin from "components/spin/spin";

interface IBookingModalNewProps {
  teetimes: ITeeTimeNew[];
  loading: boolean;
  loadingText: string;
  reloadTeeTimes: () => void;
}

interface IMainState {
  [key: string]: any;
  golferSearchQuery1: string;
  golferSearchQuery2: string;
  golferSearchQuery3: string;
  golferSearchQuery4: string;
  golferSearchResult1: (Partial<ICustomer> | ICustomer)[];
  golferSearchResult2: (Partial<ICustomer> | ICustomer)[];
  golferSearchResult3: (Partial<ICustomer> | ICustomer)[];
  golferSearchResult4: (Partial<ICustomer> | ICustomer)[];
  searching: boolean;
}

export interface INewBookingState {
  holes: number;
  quantity: number;
  teeTimeSlots: {
    id: number;
    customer_id: number;
    guest: boolean;
  }[];
  powerCartQuantity: number | string;
  customerSelection: ICustomer[];
  customer: any;
  customers: [];
  golferInitialLoad: any;
  cartInitialLoad: any;
  holesInitialLoad: any;
  saveCard: boolean;
  addCard: boolean;
  notes: any;
  noteDescription: any;
  addNoteVisible: string;
  notesTitleVisible: string;
  updateBookingActive: boolean;
  editBooking: undefined | Record<string, any>;
  paymentMethods: any[];
  selectedPaymentMethod?: number;
  elementComplete: {
    cardNumber: boolean;
    cardExpiry: boolean;
    cardCvc: boolean;
  };
  cardReady: boolean;
  extraPlayersDropdownVisible: boolean;
  extraPlayersSlots: ISlot[][];
  teetimeLockPopupVisible: boolean;
  lockedTeeTime: string;
  extraPlayersQuantity: number;

  holeButtons: boolean[]; // disabled
  playerButtons: boolean[]; // disabled
  cartButtons: boolean[]; // disabled

  bookingWithinHour: boolean;

  bookingPosting: boolean;
}

export interface INewPlayerState {
  newCustomer: ICustomer;
  selectedSlot: ISlot;
  newCustomerSheetActive: boolean;
  currentSearch: string;
}

export interface IBooking {
  id: number;
  tee_time_id: number;
}

export enum StateType {
  MainState = "IMainState",
  NewBookingState = "INewBookingState",
  NewPlayerState = "INewPlayerState",
}

export default function BookingModalNew(props: IBookingModalNewProps) {
  const { Option } = Select;

  const { clientFacilityStore, authStore, clientTeeSheetStore, uiStore } = useAppSelector(state => state);

  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const permissions = authStore.user?.permissions;

  const [state, setState] = useState<IMainState>({
    golferSearchQuery1: "",
    golferSearchQuery2: "",
    golferSearchQuery3: "",
    golferSearchQuery4: "",
    golferSearchResult1: [null],
    golferSearchResult2: [null],
    golferSearchResult3: [null],
    golferSearchResult4: [null],
    searching: false,
  });

  const [newBookingState, setNewBookingState] = useState<INewBookingState>({
    holes: /*18*/ null,
    quantity: /*2*/ null,
    powerCartQuantity: /*0*/ null,
    teeTimeSlots: [],

    customerSelection: [],
    customer: null,
    customers: [],
    golferInitialLoad: null,
    cartInitialLoad: null,
    holesInitialLoad: null,
    notes: [],
    noteDescription: "",
    addNoteVisible: "none",
    notesTitleVisible: "none",
    updateBookingActive: false,
    editBooking: undefined,
    saveCard: false,
    addCard: false,
    paymentMethods: undefined,
    selectedPaymentMethod: undefined,
    elementComplete: {
      cardNumber: false,
      cardExpiry: false,
      cardCvc: false,
    },
    cardReady: false,
    extraPlayersDropdownVisible: false,
    extraPlayersSlots: [],
    teetimeLockPopupVisible: false,
    lockedTeeTime: "",
    extraPlayersQuantity: 0,

    holeButtons: [false, false], // disabled
    playerButtons: [false, false, false, false], // disabled
    cartButtons: [false, false], // disabled

    bookingWithinHour: false,

    bookingPosting: false,
  });

  const [newPlayerState, setNewPlayerState] = useState<INewPlayerState>({
    newCustomer: null,
    selectedSlot: null,
    newCustomerSheetActive: false,
    currentSearch: "",
  });

  const [maxBookingSize, setMaxBookingSize] = useState(16);

  const startingNumber = 5; //UI always shows first 4 as buttons
  const extraPlayers = [...Array(maxBookingSize - startingNumber + 1).keys()].map(index => index + startingNumber);
  const extraPlayersRef = useRef(null);
  useOutsideAlerter(extraPlayersRef, () =>
    updateState<INewBookingState>({ extraPlayersDropdownVisible: false }, StateType.NewBookingState),
  );

  function updateState<T>(newState: Partial<T>, type: StateType) {
    const states = {
      [StateType.MainState]: (newState: Partial<T>) =>
        setState((cur: IMainState) => {
          return { ...cur, ...newState };
        }),
      [StateType.NewBookingState]: (newState: Partial<T>) =>
        setNewBookingState((cur: INewBookingState) => {
          return { ...cur, ...newState };
        }),
      [StateType.NewPlayerState]: (newState: Partial<T>) =>
        setNewPlayerState((cur: INewPlayerState) => {
          return { ...cur, ...newState };
        }),
    };

    states[type](newState);
  }

  const [sendBookingConfirmation, setSendBookingConfirmation] = useState<boolean>(true);

  const postBooking = async (input: IPostBooking) => {
    const bookingRes = await PostBooking(input, false);

    if (bookingRes.status !== StatusCode.OK) {
      dispatch(showError(t("elements.tee_sheet.booking_modal_new.001")));
      return false;
    }

    // Handle booking extra players (quantity > 4)
    for (const teeTime of newBookingState.extraPlayersSlots) {
      const slotsFormatted: { id: number; customer_id: number; guest: boolean }[] = [];
      teeTime.forEach(slot => {
        slotsFormatted.push({
          id: slot.id,
          customer_id: newBookingState.customerSelection[0].id,
          guest: true,
        });
      });
      const extraBookingRes = await PostBooking(
        {
          ...input,
          tee_time_id: teeTime[0].tee_time_id,
          quantity: teeTime.length,
          tee_time_slots: slotsFormatted,
          booking_group_id: bookingRes.data.booking_group_id,
        },
        false,
      );
      if (extraBookingRes.status !== StatusCode.OK) {
        dispatch(showError(t("elements.tee_sheet.booking_modal_new.002")));
        return false;
      }
    }

    // Send group booking email if extra players exist
    if (sendBookingConfirmation) {
      const confirmationRes = await PutBookingConfirmation(
        { booking_group_id: bookingRes.data.booking_group_id },
        false,
      );

      if (confirmationRes.status !== StatusCode.OK) {
        dispatch(showError("Error sending confirmation email"));
        return;
      }
    }

    if (bookingRes.status === StatusCode.OK) {
      dispatch(showSuccess(t("elements.tee_sheet.booking_modal_new.003")));
      return Number(bookingRes.data.id);
    }

    return;
  };

  const bookCustomer = async () => {
    const tee_time_id = clientTeeSheetStore.selectedTeeTime.id;
    const teeTimeSlots = newBookingState.teeTimeSlots;

    const power_cart_quantity =
      newBookingState.powerCartQuantity === "none" ? 0 : (newBookingState.powerCartQuantity as number);

    const bookingQuantity = newBookingState.quantity - newBookingState.extraPlayersQuantity;

    // Handle booking guests
    if (newBookingState.quantity > newBookingState.teeTimeSlots.length) {
      const diff = bookingQuantity - newBookingState.teeTimeSlots.length;
      const remainingSlots = clientTeeSheetStore.selectedTeeTime.slots.filter(
        slot =>
          slot.status === "available" &&
          !newBookingState.teeTimeSlots.some(selectedSlot => slot.id === selectedSlot.id),
      );
      for (let i = 0; i < diff; i++) {
        teeTimeSlots.push({
          id: remainingSlots[i].id,
          customer_id: newBookingState.customerSelection[0].id,
          guest: true,
        });
      }
      updateState<INewBookingState>({ teeTimeSlots }, StateType.NewBookingState);
    }

    const booking: IPostBooking = {
      tee_time_id,
      send_confirmation: false,
      quantity: bookingQuantity,
      holes: newBookingState.holes,
      power_cart_quantity: power_cart_quantity,
      customer_id: newBookingState.customerSelection[0].id,
      tee_time_slots: newBookingState.teeTimeSlots,
      customer_payment_method_id: newBookingState.selectedPaymentMethod,
    };

    // Handle collecting payment method
    if (elementsComplete) {
      const collectCardRes = await collectCard(newBookingState.customerSelection[0].id);
      booking.customer_payment_method_id = collectCardRes.customer_payment_method_id;
    }

    updateState<INewBookingState>({ bookingPosting: true }, StateType.NewBookingState);

    dispatch(updateTemporaryBooking(booking));

    const bookingId = await postBooking(booking);

    if (bookingId !== false) {
      if (newBookingState.noteDescription !== "") {
        const noteInfo = {
          tee_time_booking_id: Number(bookingId),
          content: String(newBookingState.noteDescription),
        };

        const bookingNoteRes = await PostBookingNote(noteInfo, false);

        if (bookingNoteRes.status !== StatusCode.OK) {
          dispatch(showError(bookingNoteRes.data.message));
          return;
        }
      }
    }

    // force refresh, player appears
    props.reloadTeeTimes();

    handleTabChange(0);

    closeNewBooking();
  };

  function closeNewBooking() {
    void deleteTeeTimeLocks();
    dispatch(closeBooking());
    updateState<INewBookingState>(
      {
        updateBookingActive: false,
        customerSelection: [],
        selectedPaymentMethod: undefined,
        elementComplete: {
          cardCvc: false,
          cardExpiry: false,
          cardNumber: false,
        },
        addNoteVisible: "none",
        notesTitleVisible: "none",
        noteDescription: "",
        notes: [],
        quantity: null,
        holes: null,
        powerCartQuantity: null,
        paymentMethods: undefined,
        teeTimeSlots: [],
        addCard: false,
        saveCard: false,
        holeButtons: [false, false], // disabled
        playerButtons: [false, false, false, false], // disabled
        cartButtons: [false, false], // disabled
        extraPlayersDropdownVisible: false,
        extraPlayersSlots: [],
        teetimeLockPopupVisible: false,
        lockedTeeTime: "",
        extraPlayersQuantity: 0,
        bookingPosting: false,
      },
      StateType.NewBookingState,
    );

    // clear search and result
    updateState<IMainState>(
      {
        golferSearchQuery1: "",
        golferSearchQuery2: "",
        golferSearchQuery3: "",
        golferSearchQuery4: "",
        golferSearchResult1: [null],
        golferSearchResult2: [null],
        golferSearchResult3: [null],
        golferSearchResult4: [null],
        searching: false,
      },
      StateType.MainState,
    );

    dispatch(deSelectTeeTime());
    setSelected(0);
  }

  async function deleteTeeTimeLocks() {
    const deleteLocksRes = await DeleteTeeTimeLocks(false);
    if (deleteLocksRes.status !== StatusCode.OK) {
      return;
    }
  }

  const handleCustomerSearch = (query: string, name: string) => {
    // void loadCustomers(query);

    updateState<IMainState>({ [name]: query }, StateType.MainState);
    setNewPlayerState(prevState => ({
      ...prevState,
      currentSearch: query,
    }));
  };

  async function loadCustomers(golferSearchQuery: string, cancelToken: CancelToken) {
    const customerRes = await GetCustomer({ search: golferSearchQuery }, false, cancelToken);

    if (customerRes.status !== StatusCode.OK) {
      return [];
    }

    return customerRes.data as Array<Record<string, any>>;
  }

  const search = async (
    mounted: boolean,
    golferSearchQuery: string,
    golferSearchResult: string,
    cancelToken: CancelToken,
  ) => {
    try {
      if (golferSearchQuery === "") {
        if (mounted) {
          updateState<IMainState>({ [golferSearchResult]: [null] }, StateType.MainState);
        }
        return;
      } else {
        updateState<IMainState>({ searching: true }, StateType.MainState);
        const res = await loadCustomers(golferSearchQuery, cancelToken);
        if (mounted) {
          updateState<IMainState>({ [golferSearchResult]: res, searching: false }, StateType.MainState);
        }
      }
    } catch (error) {
      console.log("err", error);
    }
    return;
  };

  // obtain dynamic value for extraPlayers array value.
  // useEffect(() => {
  //   if (clientFacilityStore.teeSheetSettings !== null) {
  //     setMaxBookingSize(clientFacilityStore.teeSheetSettings.max_booking_size);
  //   }
  // }, [clientFacilityStore.teeSheetSettings]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = global.setTimeout(() => {
        void search(mounted, state.golferSearchQuery1, "golferSearchResult1", source.token);
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      updateState<IMainState>({ golferSearchResult1: [null] }, StateType.MainState);
      source.cancel("Cancelled");
    };
  }, [state.golferSearchQuery1]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = global.setTimeout(() => {
        void search(mounted, state.golferSearchQuery2, "golferSearchResult2", source.token);
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      updateState<IMainState>({ golferSearchResult2: [null] }, StateType.MainState);
      source.cancel("Cancelled");
    };
  }, [state.golferSearchQuery2]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = global.setTimeout(() => {
        void search(mounted, state.golferSearchQuery3, "golferSearchResult3", source.token);
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      updateState<IMainState>({ golferSearchResult3: [null] }, StateType.MainState);
      source.cancel("Cancelled");
    };
  }, [state.golferSearchQuery3]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = global.setTimeout(() => {
        void search(mounted, state.golferSearchQuery4, "golferSearchResult4", source.token);
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      updateState<IMainState>({ golferSearchResult4: [null] }, StateType.MainState);
      source.cancel("Cancelled");
    };
  }, [state.golferSearchQuery4]);

  /**
   * compares tee_time_quantity and turn_tee_time quantity and returns the lower quantity
   * if turn_tee_time_quantity is null turn_tee_time will be returned
   * @param tee_time_quantity
   * @param turn_tee_time_quantity
   * @returns number
   */
  const lowerQuantity = (tee_time_quantity: number, turn_tee_time_quantity: number, selected_holes: number): number => {
    if (turn_tee_time_quantity === null) {
      return tee_time_quantity;
    } else if (selected_holes === 9) {
      return tee_time_quantity;
    } else {
      return Math.min(tee_time_quantity, turn_tee_time_quantity);
    }
  };

  useEffect(() => {
    if (!clientTeeSheetStore.selectedTeeTime) {
      return;
    }

    // disabling options...
    let mounted = true;

    const disableOptions = () => {
      const holeButtons: boolean[] = [true, true];
      let playerButtons: boolean[] = [false, false, false, false];

      const quantity_remaining = lowerQuantity(
        clientTeeSheetStore.selectedTeeTime?.quantity_remaining,
        clientTeeSheetStore.selectedTeeTime?.turn_tee_time?.quantity_remaining,
        newBookingState.holes,
      );

      // disabling number of players based on quantity remaining
      switch (quantity_remaining) {
        case 1:
          playerButtons = playerButtons.map((disabled, i) => {
            if (i === 0) {
              return (disabled = false);
            } else {
              return (disabled = true);
            }
          });
          break;

        case 2:
          playerButtons = playerButtons.map((disabled, i) => {
            if (i === 0 || i === 1) {
              return (disabled = false);
            } else {
              return (disabled = true);
            }
          });
          break;

        case 3:
          playerButtons = playerButtons.map((disabled, i) => {
            if (i !== 3) {
              return (disabled = false);
            } else {
              return (disabled = true);
            }
          });
          break;

        case 4:
          playerButtons = playerButtons.map((disabled, i) => {
            return (disabled = false);
          });
          break;
      }

      let bookingQuantity = newBookingState.quantity;
      if (bookingQuantity > 4) {
        bookingQuantity = bookingQuantity - newBookingState.extraPlayersQuantity;
      }

      // Disabling holes
      clientTeeSheetStore.selectedTeeTime.divisions_available?.forEach((division, index) => {
        // 9 holes available
        if (division === 1) {
          holeButtons[1] = false;
        } else if (division === 2) {
          // Only 9 holes available checks if turn is a league or tournament, unless the turn is also in the same league/tournament
          if (
            (clientTeeSheetStore.selectedTeeTime.divisions_available?.length > 1 &&
              clientTeeSheetStore.selectedTeeTime?.turn_tee_time === null) ||
            clientTeeSheetStore?.selectedTeeTime?.turn_tee_time?.blocked_type === "blocked" ||
            ((clientTeeSheetStore.selectedTeeTime?.turn_tee_time?.league_id ||
              clientTeeSheetStore.selectedTeeTime?.turn_tee_time?.tournament_id) &&
              (clientTeeSheetStore.selectedTeeTime?.league_id !==
                clientTeeSheetStore.selectedTeeTime?.turn_tee_time?.league_id ||
                clientTeeSheetStore.selectedTeeTime?.tournament_id !==
                  clientTeeSheetStore.selectedTeeTime?.turn_tee_time?.tournament_id))
          ) {
            holeButtons[1] = false;
          } else if (clientTeeSheetStore.selectedTeeTime?.turn_tee_time != null) {
            // 18 holes available
            if (clientTeeSheetStore.selectedTeeTime?.turn_tee_time?.quantity_remaining >= bookingQuantity) {
              holeButtons[0] = false;
            } else {
              // Only 9 holes available
              holeButtons[1] = false;
            }
          }
        }
      });

      if (mounted) {
        if (holeButtons[0] && holeButtons[1]) {
          updateState<INewBookingState>({ holeButtons, playerButtons, holes: null }, StateType.NewBookingState);
        } else if (holeButtons[0]) {
          updateState<INewBookingState>({ holeButtons, playerButtons, holes: 9 }, StateType.NewBookingState);
        } else if (holeButtons[1]) {
          updateState<INewBookingState>({ holeButtons, playerButtons, holes: 18 }, StateType.NewBookingState);
        }
        updateState<INewBookingState>({ holeButtons, playerButtons }, StateType.NewBookingState);
      }
    };

    const run = async () => {
      if (uiStore.newBookingActive === true) {
        // TODO: Figure out what's going on here
        // dispatch(enqueue());

        await delay(100);

        disableOptions();

        await delay(1000);

        // dispatch(dequeue());
      }
    };
    void run();

    return () => {
      mounted = false;
    };
  }, [clientTeeSheetStore.selectedTeeTime, newBookingState.holes, uiStore.newBookingActive, newBookingState.quantity]);

  useEffect(() => {
    const maxQuantity = clientTeeSheetStore?.selectedTeeTime?.quantity_remaining;
    if (clientTeeSheetStore.selectedTeeTime?.cart_rule == "required") {
      updateState<INewBookingState>(
        { holes: 18, quantity: maxQuantity, powerCartQuantity: 1 },
        StateType.NewBookingState,
      );
      return;
    }

    updateState<INewBookingState>(
      { holes: 18, quantity: maxQuantity, powerCartQuantity: "none" },
      StateType.NewBookingState,
    );
  }, [uiStore.newBookingActive, clientTeeSheetStore.selectedTeeTime]);

  useEffect(() => {
    const selectedTeeTimeDateTime = new Date(
      moment(`${clientTeeSheetStore.selectedTeeTime?.date}T${clientTeeSheetStore.selectedTeeTime?.start_time}`).format(
        "LLL",
      ),
    );

    const hourBeforeSelectedTeeTimeDateTime = new Date(
      moment(`${clientTeeSheetStore.selectedTeeTime?.date}T${clientTeeSheetStore.selectedTeeTime?.start_time}`).format(
        "LLL",
      ),
    );

    hourBeforeSelectedTeeTimeDateTime.setHours(hourBeforeSelectedTeeTimeDateTime.getHours() - 1);

    const currentTime = moment();

    const bookingWithinHour = currentTime.isBetween(hourBeforeSelectedTeeTimeDateTime, selectedTeeTimeDateTime);

    updateState<INewBookingState>({ bookingWithinHour: bookingWithinHour }, StateType.NewBookingState);
  }, [uiStore.newBookingActive]);

  useEffect(() => {
    if (newBookingState.teeTimeSlots[0]?.customer_id) {
      void loadCustomersPaymentMethods(newBookingState.teeTimeSlots[0]?.customer_id);
    } else if (newBookingState?.editBooking?.customer_id) {
      void loadCustomersPaymentMethods(newBookingState.editBooking.customer_id);
    }

    if (!newBookingState.teeTimeSlots[0]?.customer_id) {
      updateState<INewBookingState>({ paymentMethods: undefined }, StateType.NewBookingState);
    }
  }, [newBookingState.customerSelection]);

  const handleCustomerSelection = (id: number | string, slot: ISlot, newCustomer: Record<string, any>) => {
    if (newBookingState.customerSelection.length === 4) {
      return;
    }

    if (id === "") {
      return;
    } else {
      const selection = !newCustomer
        ? state["golferSearchResult".concat(slot.position.toString())]
            .filter((res: ICustomer) => {
              if (res.id === id) {
                return true;
              } else {
                return false;
              }
            })
            .map((res: ICustomer) => res)
        : [newCustomer];

      const customerSelection = newBookingState.customerSelection;
      customerSelection.splice(slot.position - 1, 0, selection[0]);

      const teeTimeSlots = newBookingState.teeTimeSlots;
      teeTimeSlots.splice(slot.position - 1, 0, { id: slot.id, customer_id: selection[0].id, guest: false });

      updateState({ customerSelection, teeTimeSlots }, StateType.NewBookingState);
    }
  };

  const closeNewCustomer = () => {
    updateState<INewPlayerState>(
      {
        newCustomer: null,
        selectedSlot: null,
        newCustomerSheetActive: false,
      },
      StateType.NewPlayerState,
    );

    updateState<IMainState>(
      { golferSearchQuery1: "", golferSearchQuery2: "", golferSearchQuery3: "", golferSearchQuery4: "" },
      StateType.MainState,
    );
  };

  const addCustomer = async (customerInfo: ICustomerInfoState) => {
    const customer = await createCustomer(customerInfo);
    if (customer) {
      handleCustomerSelection(customer.id, newPlayerState.selectedSlot, customer);
    }
  };

  const createCustomer = async (customerInfo: ICustomerInfoState) => {
    const error =
      customerInfo.firstName === "" ||
      customerInfo.lastName === "" ||
      (customerInfo.emailAddress === "" && customerInfo.phoneNumber === "");

    if (error) {
      return null;
    }

    const customerRes = await PostCustomer(
      {
        first_name: customerInfo.firstName,
        last_name: customerInfo.lastName,
        phone: customerInfo.phoneNumber || null,
        email: customerInfo.emailAddress || null,
        state: "disabled",
      },
      true,
    );

    if (customerRes.status !== 200) {
      dispatch(showError(customerErrorMessage(t, customerRes?.data?.message)));
    }

    if (customerRes.status === 200) {
      updateState<INewPlayerState>({ newCustomer: customerRes.data }, StateType.NewPlayerState);
      void closeNewCustomer();
      dispatch(showSuccess("Customer created successfully")); // TODO: Translation
      return customerRes.data as ICustomer;
    }
    return null;
  };

  const removeGolfer = (value: any, e: any) => {
    // remove golfer by unique id
    const customerSelection = newBookingState.customerSelection.filter((golfer: any) => {
      return golfer !== value;
    });

    const teeTimeSlots = newBookingState.teeTimeSlots.filter((slot: any) => slot.customer_id !== value.id);
    updateState<INewBookingState>({ customerSelection, teeTimeSlots }, StateType.NewBookingState);
  };

  const onSelectCreditCard = (id: number) => {
    if (id > 0) {
      if (id === newBookingState.selectedPaymentMethod) {
        // unselect
        updateState<INewBookingState>({ selectedPaymentMethod: undefined }, StateType.NewBookingState);
      } else {
        updateState<INewBookingState>({ selectedPaymentMethod: id, addCard: false }, StateType.NewBookingState);
      }
    } else {
      dispatch(showError(t("elements.tee_sheet.booking_modal_new.004")));
    }
  };

  const handleNoteInput = (event: any) => {
    updateState<INewBookingState>({ noteDescription: event.target.value }, StateType.NewBookingState);
  };

  const handleAddNote = async () => {
    const tempDate = new Date();
    let tempNotesArr = newBookingState.notes;

    const date = moment(tempDate).format("LLL");

    const dateString = "Added On: " + date;

    const tempNote = {
      dateString: dateString,
      description: newBookingState.noteDescription,
      user: authStore.user.full_name,
    };

    tempNotesArr = tempNotesArr.reverse();

    tempNotesArr.push(tempNote);

    tempNotesArr.reverse();

    updateState<INewBookingState>(
      { notesTitleVisible: "block", notes: tempNotesArr, noteDescription: "" },
      StateType.NewBookingState,
    );

    const noteInfo = {
      tee_time_booking_id: Number(newBookingState.editBooking.id),
      content: String(newBookingState.noteDescription),
    };

    const bookingNoteRes = await PostBookingNote(noteInfo, true);

    (document.getElementById("booking-modal-notes-input") as HTMLInputElement).value = "";
  };

  const loadCustomersPaymentMethods = async (customer_id: number) => {
    const paymentMethodRes = await GetCustomerPaymentMethod({ customer_id }, false);
    if (paymentMethodRes.status === StatusCode.OK) {
      updateState<INewBookingState>({ paymentMethods: paymentMethodRes.data }, StateType.NewBookingState);
    } else {
      dispatch(showError(paymentMethodRes.message));
      updateState<INewBookingState>({ paymentMethods: [] }, StateType.NewBookingState);
    }
  };

  //Function to save card to customer and to booking
  const setupCard = async (payment_method: string, customer_id: number) => {
    const setupRes = await PostSetup({ customer_id }, true);

    if (setupRes.status !== StatusCode.OK) {
      dispatch(showError(setupRes.data.message));
      return { success: false };
    } else {
      if (payment_method !== "undefined") {
        const stripeRes = await stripe.confirmCardSetup(setupRes?.data.setup_intent.client_secret, {
          payment_method: payment_method,
        });

        if (stripeRes.error) {
          dispatch(showError(stripeRes.error.message));
        } else {
          const methodRes = await PostPaymentMethod({
            payment_method_id: stripeRes.setupIntent.payment_method,
            customer_id: customer_id,
            save_card: newBookingState.saveCard,
          });
          if (methodRes.status !== StatusCode.OK) {
            dispatch(showError(methodRes.data.message));
            return { success: false };
          } else {
            dispatch(showSuccess(methodRes.data.message));
            updateState<INewBookingState>({ selectedPaymentMethod: methodRes?.data.id }, StateType.NewBookingState);

            if (methodRes.status !== StatusCode.OK) {
              dispatch(showError(methodRes.data.message));
              return { success: false };
            }

            return {
              customer_payment_method_id: methodRes?.data.id as number,
              success: true,
            };
          }
        }
      }
    }
  };

  //Function to save card
  const stripe = useStripe();
  const elements = useElements();

  const collectCard = async (customer_id: number) => {
    if (!stripe || !elements) {
      dispatch(showError(t("elements.tee_sheet.booking_modal_new.005")));
      return;
    }

    // Loader for length of operation to stop user from clicking
    // TODO: Handle this without global loader
    dispatch(enqueue());

    try {
      let setupCardRes;
      const paymentMethod = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement("cardNumber"),
      });

      if (paymentMethod.paymentMethod) {
        setupCardRes = await setupCard(paymentMethod.paymentMethod.id, customer_id);
        dispatch(dequeue());
        return {
          customer_payment_method_id: setupCardRes.customer_payment_method_id,
        };
      } else {
        dispatch(showError(paymentMethod.error.message));
      }
    } catch (e) {
      dispatch(dequeue());
    }

    dispatch(dequeue());
  };

  // Handle tab change
  const [selected, setSelected] = useState(0);

  const handleTabChange = (selectedTabIndex: number) => {
    setSelected(selectedTabIndex);

    if (selectedTabIndex === 0) {
      updateState<INewBookingState>(
        {
          elementComplete: {
            cardNumber: false,
            cardExpiry: false,
            cardCvc: false,
          },
        },
        StateType.NewBookingState,
      );
    }
  };

  const handleHolesChange = (e: number) => {
    const holes = e;

    updateState<INewBookingState>({ holes }, StateType.NewBookingState);
  };

  const handlePlayersChange = async (e: number) => {
    const quantity = e;

    if (quantity !== -1) {
      //Delete the tee time locks for the other tee times and create one for the current tee time
      if (newBookingState.extraPlayersQuantity !== 0) {
        const deleteTeeTimeLockRes = await DeleteTeeTimeLocks(true);
        if (deleteTeeTimeLockRes.status !== StatusCode.OK) {
          dispatch(showError(deleteTeeTimeLockRes.data.message));
        }
        const postTeeTimeLockRes = await PostTeeTimeLock({ tee_time_id: clientTeeSheetStore.selectedTeeTime.id }, true);
        if (postTeeTimeLockRes.status !== StatusCode.OK) {
          dispatch(showError(postTeeTimeLockRes.data.message));
        }
      }
      // If the new selected quanitity is less than the previous quantity, remove the extra players
      if (quantity < newBookingState.quantity) {
        void handleRemoverGolfers(quantity);
      }
      updateState<INewBookingState>(
        { quantity, extraPlayersSlots: [], extraPlayersQuantity: 0 },
        StateType.NewBookingState,
      );
    }
  };

  const handleRemoverGolfers = (newQuantity: number) => {
    const updatedCustomerSelection = newBookingState.customerSelection.filter(
      (customer, index) => index + 1 <= newQuantity,
    );
    const updatedTeeTimeSlots = newBookingState.teeTimeSlots.filter((slot, index) => index + 1 <= newQuantity);
    updateState<INewBookingState>(
      { customerSelection: updatedCustomerSelection, teeTimeSlots: updatedTeeTimeSlots },
      StateType.NewBookingState,
    );
    updateState<IMainState>(
      { golferSearchQuery1: "", golferSearchQuery2: "", golferSearchQuery3: "", golferSearchQuery4: "" },
      StateType.MainState,
    );
  };

  const handleCartChange = (e: string | number) => {
    const powerCartQuantity = e;
    updateState<INewBookingState>({ powerCartQuantity }, StateType.NewBookingState);
  };

  const elementsComplete =
    newBookingState.elementComplete.cardNumber === true &&
    newBookingState.elementComplete.cardExpiry === true &&
    newBookingState.elementComplete.cardCvc === true;

  const cc_required = clientTeeSheetStore.selectedTeeTime?.credit_card_required;
  const cc_unselected = newBookingState.selectedPaymentMethod === undefined && !elementsComplete;
  const cc_override = permissions?.tee_sheet_credit_card_override;

  const handleCardSectionChange = (
    e: StripeCardNumberElementChangeEvent | StripeCardExpiryElementChangeEvent | StripeCardCvcElementChangeEvent,
  ) => {
    if (newBookingState.selectedPaymentMethod !== undefined) {
      updateState<INewBookingState>({ selectedPaymentMethod: undefined }, StateType.NewBookingState);
    }

    updateState<INewBookingState>(
      {
        elementComplete: { ...newBookingState.elementComplete, [e.elementType]: e.complete },
      },
      StateType.NewBookingState,
    );
  };

  const extraPlayersMenu = () => {
    return (
      <ul className="extra-players-menu">
        {extraPlayers.map(playerNum => {
          return (
            <li
              key={playerNum}
              className="extra-players-menu-item"
              onClick={() => selectExtraPlayersQuantity(playerNum)}
            >
              <span>{playerNum}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  // Organize away from rendering
  const tabs = [
    {
      id: "booking-details",
      content: t("elements.tee_sheet.booking_modal_new.025"),
    },
    {
      id: "credit-card",
      content: t("elements.tee_sheet.booking_modal_new.026"),
    },
    {
      id: "notes",
      content: t("elements.tee_sheet.booking_modal_new.027"),
    },
  ];

  const selectExtraPlayersQuantity = async (quantity: number) => {
    const nextTeeTimeSlots: ISlot[][] = [];
    const tee_time_ids: number[] = [clientTeeSheetStore.selectedTeeTime.id];
    let errorCheck = false;

    let calculateLimit = quantity / 4;
    if (calculateLimit % 1 === 0) {
      calculateLimit -= 1;
    }

    const times = await getNextTeeTimes(Math.floor(calculateLimit));

    // Remove the selected tee times from the nextTeeTimes response
    const nextTeeTimes = times.filter(teetime => teetime.id !== clientTeeSheetStore.selectedTeeTime.id);

    if (nextTeeTimes === null) {
      return;
    }

    //Delete all tee time locks created by the user
    const teeTimeLockDeleteRes = await DeleteTeeTimeLocks(true);
    if (teeTimeLockDeleteRes.status !== StatusCode.OK) {
      return;
    }

    let quantityRemaining = quantity - 4;

    nextTeeTimes.forEach((teeTime, i) => {
      nextTeeTimeSlots.push([]);
      if (teeTime.lock !== null && teeTime.lock.user_id !== authStore.user.id) {
        //Check if the tee times have a lock and if the lock was created by another user
        errorCheck = true;
        updateState<INewBookingState>(
          { teetimeLockPopupVisible: true, lockedTeeTime: convertTime(teeTime.start_time) },
          StateType.NewBookingState,
        );
        return;
      } else if (
        teeTime.slots.some(slot => {
          //Add the required amount of tee time slots
          quantityRemaining > 0 && nextTeeTimeSlots[i].push(slot);
          quantityRemaining -= 1;
          //Check if the tee times contain booked slots
          return slot.status !== "available";
        })
      ) {
        errorCheck = true;
        dispatch(
          showError(
            t("elements.tee_sheet.booking_modal_new.006") +
              convertTime(teeTime.start_time) +
              t("elements.tee_sheet.booking_modal_new.007"),
          ),
        );
        return;
      }
      tee_time_ids.push(teeTime.id);
    });

    //Post tee time locks for the current tee time and the next required tee times
    if (!errorCheck) {
      const teeTimeLockRes = await PostTeeTimeLock({ tee_time_ids }, true);
      if (teeTimeLockRes.status !== StatusCode.OK) {
        errorCheck = true;
      }
    } else if (errorCheck) {
      //If there is an error, we still need to POST tee time lock for the current selected tee time
      const teeTimeLockRes = await PostTeeTimeLock({ tee_time_id: clientTeeSheetStore.selectedTeeTime.id }, true);
      return;
    }
    updateState<INewBookingState>(
      {
        quantity,
        extraPlayersQuantity: quantity - 4,
        extraPlayersDropdownVisible: false,
        extraPlayersSlots: nextTeeTimeSlots,
      },
      StateType.NewBookingState,
    );
  };

  // Get the next tee time's start time
  const getNextTeeTimeStartTime = () => {
    const teeTimeIndex = props.teetimes.findIndex(
      teeTime => teeTime.slots[0].tee_time_id === clientTeeSheetStore.selectedTeeTime.id,
    );
    if (teeTimeIndex !== -1) {
      return props.teetimes[teeTimeIndex + 1]?.start_time;
    }
    return null;
  };

  const getNextTeeTimes = async (limit: number) => {
    const start_time = getNextTeeTimeStartTime();
    //Check if the next tee time is undefined ( if current tee time is the last tee time)
    if (!start_time) {
      dispatch(showError(t("elements.tee_sheet.booking_modal_new.008")));
      return null;
    }
    const teeTimesRes = await GetTeeTime(
      {
        date: clientTeeSheetStore.selectedTeeTime.date,
        blocked_type: "open",
        division_id: clientTeeSheetStore.selectedTeeTime.division_id,
        start_time: clientTeeSheetStore.selectedTeeTime.start_time,
        limit: limit + 1,
        extended: true,
        locks: true,
        course_id: clientTeeSheetStore.selectedTeeTime.course_id,
        facility_id: clientTeeSheetStore?.selectedTeeTime?.facility_id,
      },
      true,
    );
    if (teeTimesRes.status !== StatusCode.OK) {
      dispatch(showError(teeTimesRes.message));
      return null;
    } else if (teeTimesRes.data.length - 1 !== limit) {
      //Check if there are enough tee times left to fill the selected amount of players
      dispatch(showError(t("elements.tee_sheet.booking_modal_new.009")));
      return null;
    }
    return teeTimesRes.data;
  };

  const handleExtraPlayersVisible = () => {
    updateState<INewBookingState>({ extraPlayersDropdownVisible: true }, StateType.NewBookingState);
  };

  const handleClosePopup = () => {
    updateState<INewBookingState>({ teetimeLockPopupVisible: false }, StateType.NewBookingState);
  };

  const toggleNewCustomerSheet = (slot: ISlot) => {
    updateState<INewPlayerState>(
      {
        selectedSlot: slot,
        newCustomerSheetActive: !newPlayerState.newCustomerSheetActive,
      },
      StateType.NewPlayerState,
    );

    openNewCustomerSheet();
  };

  useEffect(() => {
    if (newBookingState.customerSelection[0]?.id) {
      void loadCustomersPaymentMethods(newBookingState.customerSelection[0].id);
    } else {
      updateState<INewBookingState>(
        { selectedPaymentMethod: undefined, paymentMethods: undefined },
        StateType.NewBookingState,
      );
    }
  }, [newBookingState.customerSelection[0]]);

  function openNewCustomerSheet() {
    setNewPlayerState(prevState => ({
      ...prevState,
      newCustomerSheetActive: true,
    }));
  }

  return (
    <div>
      <NewCustomer
        newCustomerSheetActive={newPlayerState.newCustomerSheetActive}
        onCancel={closeNewCustomer}
        onOk={addCustomer}
        searchValue={newPlayerState.currentSearch}
      />

      <Portal isMounted={uiStore.newBookingActive}>
        <Sheet
          open={uiStore.newBookingActive}
          size="medium"
          closable={!newBookingState.bookingPosting}
          title={t("elements.tee_sheet.booking_modal_new.010")}
          height="flexible"
          onCancel={closeNewBooking}
          onOk={
            !cc_override && cc_required && cc_unselected && !newBookingState?.bookingWithinHour
              ? () => handleTabChange(1)
              : bookCustomer
          }
          cancelText={t("elements.tee_sheet.booking_modal_new.011")}
          okText={
            !cc_override && cc_required && cc_unselected && !newBookingState.bookingWithinHour
              ? t("elements.tee_sheet.booking_modal_new.012")
              : t("elements.tee_sheet.booking_modal_new.013")
          }
          okDisabled={
            newBookingState.customerSelection.length === 0 ||
            newBookingState.holes === null ||
            newBookingState.quantity === null ||
            newBookingState.powerCartQuantity === null ||
            newBookingState.bookingPosting
              ? true
              : false
          }
          cancelDisabled={newBookingState.bookingPosting}
          backDropCancel={false}
        >
          {props.loading || newBookingState.bookingPosting ? (
            <div className="text-center">
              <div style={{ height: "32px", overflow: "hidden" }}>
                <Spin />
              </div>
              <div className="mt-2">{props.loading ? props.loadingText : "Creating Booking"}</div>
            </div>
          ) : (
            <>
              <span className="text-primary-grey text-12">
                {moment(
                  `${clientTeeSheetStore.selectedTeeTime?.date}T${clientTeeSheetStore.selectedTeeTime?.start_time}`,
                ).format("LLL")}
              </span>

              <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                {/* Booking Details */}

                {selected === 0 ? (
                  <>
                    <div className="booking-modal">
                      <div className="booking-details">
                        <div className="booking-options">
                          <div className="booking-options_option">
                            <h3>{t("elements.tee_sheet.booking_modal_new.014")}</h3>

                            <Radio.Group name="holes_new" onChange={handleHolesChange} value={newBookingState.holes}>
                              {newBookingState.holeButtons?.map((disabled, i) => {
                                const holes = i === 0 ? 18 : 9;
                                return (
                                  <Radio.Button key={`holes_new_${holes}`} value={holes} disabled={disabled}>
                                    {holes}
                                  </Radio.Button>
                                );
                              })}
                            </Radio.Group>
                          </div>
                          <div className="booking-options_option">
                            <h3>{t("elements.tee_sheet.booking_modal_new.015")}</h3>
                            <div className="flex flex-row">
                              <Radio.Group
                                name="players_new"
                                onChange={handlePlayersChange}
                                value={newBookingState.quantity}
                              >
                                {newBookingState.playerButtons?.map((disabled, i) => {
                                  const players = i + 1;
                                  return (
                                    <Radio.Button key={`players_new_${players}`} value={players} disabled={disabled}>
                                      {players}
                                    </Radio.Button>
                                  );
                                })}

                                {/* Don't display 'More' button if there are booked players for this tee time */}
                                {clientTeeSheetStore.selectedTeeTime?.quantity_remaining >= 4 ? (
                                  <Radio.Button
                                    onClick={handleExtraPlayersVisible}
                                    value={newBookingState.quantity >= 5 ? newBookingState.quantity : -1}
                                  >
                                    {newBookingState.quantity >= 5 ? newBookingState.quantity : "+"}
                                  </Radio.Button>
                                ) : null}
                              </Radio.Group>
                              <Dropdown
                                overlay={extraPlayersMenu()}
                                trigger="boolean"
                                placement="topLeft"
                                className="self-center extra-players-menu"
                                showMenu={newBookingState.extraPlayersDropdownVisible}
                                propRef={extraPlayersRef}
                                outSideClickDisable={true}
                              >
                                <span></span>
                              </Dropdown>
                            </div>
                          </div>
                          <div className="booking-options_option">
                            <h3>{t("elements.tee_sheet.booking_modal_new.016")}</h3>

                            <Radio.Group
                              name="power_carts_new"
                              onChange={handleCartChange}
                              value={newBookingState.powerCartQuantity}
                            >
                              <Radio.Button
                                value={"none"}
                                disabled={clientTeeSheetStore.selectedTeeTime?.cart_rule == "required" ? true : false}
                              >
                                0
                              </Radio.Button>

                              <Radio.Button
                                value={1}
                                disabled={clientTeeSheetStore.selectedTeeTime?.cart_rule == "none" ? true : false}
                              >
                                1
                              </Radio.Button>

                              <Radio.Button
                                value={2}
                                disabled={clientTeeSheetStore.selectedTeeTime?.cart_rule == "none" ? true : false}
                              >
                                2
                              </Radio.Button>
                            </Radio.Group>
                          </div>
                        </div>
                        <div className="booking-customers-list">
                          {newBookingState?.quantity &&
                            clientTeeSheetStore.selectedTeeTime?.slots
                              .filter(slot => slot.status === "available")
                              .map((slot: any, index: number) => {
                                const golferPosition = slot.position.toString();
                                const queryKey = "golferSearchQuery".concat(golferPosition);
                                const searchResultKey = "golferSearchResult".concat(golferPosition);
                                //Get the matching slot that was added to the selection
                                const slotFound = newBookingState.teeTimeSlots.find(
                                  element => element.id === slot.id,
                                );
                                if (slotFound) {
                                  //Get the golfer info from customer selection
                                  const golfer = newBookingState.customerSelection.find(
                                    golfer => golfer.id === slotFound.customer_id,
                                  );
                                  if (golfer != null) {
                                    return (
                                      <GolferCard
                                        closable
                                        removeGolfer={(e: any) => removeGolfer(golfer, e)}
                                        email={golfer?.email}
                                        name={golfer?.full_name}
                                        memberCode={golfer?.member_code}
                                        customerType={golfer?.customer_type}
                                        phone={golfer?.phone}
                                        key={index}
                                      />
                                    );
                                  }
                                } else {
                                  return (
                                    <div key={index} className="booking-player-dropdown">
                                      <Select
                                        label={"Player ".concat(golferPosition)}
                                        placeholder="Golfer"
                                        showSearch
                                        className={`flex justify-center align-center w-full h-10 position-relative z-20 text-black font-medium appearance-none border-none focus:outline-none placeholder-gray-200`}
                                        onSearch={(query: string) => handleCustomerSearch(query, queryKey)}
                                        onChange={(id: string | number, slot: ISlot) =>
                                          handleCustomerSelection(id, slot, null)
                                        }
                                        allowClear
                                        searchValue={state[queryKey]}
                                        showDropDownOnFocus={true}
                                        searching={state.searching}
                                        disabled={index + 1 <= newBookingState.quantity ? false : true}
                                        usePortal
                                        autoFocus={index + 1 === newBookingState.customerSelection?.length + 1}
                                      >
                                        <div
                                          className="ui-select-dropdown-list-item"
                                          onClick={() => toggleNewCustomerSheet(slot)}
                                        >
                                          <p className="text-md">{t("elements.tee_sheet.booking_modal_new.017")}</p>
                                        </div>
                                        {state[searchResultKey].map((golfer: any, i: number) => {
                                          if (golfer !== null && golfer !== undefined) {
                                            return (
                                              <Option key={i} value={golfer.id} extraValues={slot}>
                                                <div className="flex justify-between">
                                                  <div>
                                                    <div className="font-semibold text-lg">{golfer?.full_name}</div>
                                                    <div className="text-sm text-gray-500">{golfer.customer_type}</div>
                                                    <div className="text-sm text-gray-500">{golfer.email}</div>
                                                    <div className="text-sm text-gray-500">
                                                      {golfer.phone ? golfer.phone : null}
                                                    </div>
                                                  </div>

                                                  <div className="font-medium text-base text-gray-500 self-end">
                                                    {golfer.member_code}
                                                  </div>
                                                </div>
                                              </Option>
                                            );
                                          }
                                        })}
                                      </Select>
                                    </div>
                                  );
                                }
                              })}
                        </div>
                        {clientTeeSheetStore.selectedTeeTime?.slots && (
                          <div className="ml-auto">
                            <Checkbox
                              size="small"
                              value={sendBookingConfirmation}
                              checked={sendBookingConfirmation}
                              onChange={e => setSendBookingConfirmation(e.target.checked)}
                              label={t("elements.tee_sheet.booking_modal_new.018")}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                ) : null}

                {selected === 1 ? (
                  <div className="booking-credit-card-tab">
                    <CreditCardCatalogue
                      customerId={newBookingState.teeTimeSlots[0]?.customer_id}
                      creditCards={newBookingState.paymentMethods}
                      selectedCard={newBookingState.selectedPaymentMethod}
                      setSelectedCard={(paymentId: number) => onSelectCreditCard(paymentId)}
                    />
                    <CreditCardAdd
                      isSavingCard={newBookingState.saveCard}
                      setIsSavingCard={() =>
                        updateState<INewBookingState>(
                          { saveCard: !newBookingState.saveCard },
                          StateType.NewBookingState,
                        )
                      }
                      handleStripeInputs={handleCardSectionChange}
                    />
                  </div>
                ) : null}
                {selected === 2 ? (
                  <div>
                    <div>
                      <p className="booking-modal-add-note">{t("elements.tee_sheet.booking_modal_new.019")}</p>
                      <TextField
                        id="booking-modal-notes-input"
                        rows={2}
                        value={newBookingState.noteDescription}
                        onChange={handleNoteInput}
                      ></TextField>
                      <div style={{ textAlign: "right", marginTop: "5px" }}>
                        <button
                          onClick={() =>
                            updateState<INewBookingState>({ noteDescription: "" }, StateType.NewBookingState)
                          }
                          className="booking-modal-clear-note-button"
                        >
                          {t("elements.tee_sheet.booking_modal_new.020")}
                        </button>
                        <button
                          className="booking-modal-add-note-button"
                          id="add-note-button"
                          style={{ display: newBookingState.addNoteVisible }}
                          onClick={handleAddNote}
                          disabled={newBookingState.noteDescription === "" ? true : false}
                        >
                          {t("elements.tee_sheet.booking_modal_new.021")} <FontAwesomeIcon icon="edit" />
                        </button>
                      </div>
                    </div>
                    <br />
                    <div>
                      <p className="booking-modal-add-note" style={{ display: newBookingState.notesTitleVisible }}>
                        {t("elements.tee_sheet.booking_modal_new.022")}
                      </p>

                      {newBookingState.notes.map((props: any, index: number) => {
                        return (
                          <Note
                            key={index}
                            user={props.user}
                            description={props.description}
                            dateString={props.dateString}
                          />
                        );
                      })}
                    </div>
                  </div>
                ) : null}
              </Tabs>
            </>
          )}
        </Sheet>
      </Portal>

      <Popup
        open={newBookingState.teetimeLockPopupVisible}
        type="warning"
        title={t("elements.tee_sheet.booking_modal_new.023")}
        description={"Tee time at " + newBookingState.lockedTeeTime + " is currently being booked by another user."}
        onOk={() => handleClosePopup()}
        okText={t("elements.tee_sheet.booking_modal_new.024")}
        onCancel={() => handleClosePopup()}
        backDropCancel={true}
      />
    </div>
  );
}
