import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { PostTournament } from "api/rpc/2024-04/clientAdmin/tournament/tournament";
import { GetFacility } from "api/rpc/2024-04/clientAdmin/facility/facility";

import { showError, showSuccess } from "redux/actions/ui";
import { IFacility } from "redux/reducers/models/facility";
import { useAppDispatch } from "hooks/redux";
import { formatDate } from "helpers/Helpers";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import TimePick from "components/timePick/TimePick";
import SelectNew from "components/select/SelectNew";
import { Select } from "components/select/index";
import { TFunction, useTranslation } from "react-i18next";
interface ITournamentState {
  name: string;
  start_time: string;
  date: Date | string;
  tournament_type: TTournamentTypes;
  facility_id: number;
}

type TTournamentTypes = "Regular" | "Shotgun" | "Crossover";

const TournamentNew: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const { Option } = Select;

  const [tournamentState, setTournamentState] = useState<ITournamentState>({
    name: "",
    start_time: "12:00",
    date: new Date(),
    tournament_type: "Regular",
    //This needs to be current facility id
    facility_id: null,
  });

  const [filterState, setFilterState] = useState<{ facility_list: IFacility[] }>({
    facility_list: undefined,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadFacilities(source.token);
    return () => source.cancel();
  }, []);

  async function loadFacilities(token?: CancelToken) {
    if (filterState.facility_list !== undefined) {
      setFilterState(prev => ({ ...prev, facility_list: undefined }));
    }
    const facilityRes = await GetFacility(null, token ? false : true, token);
    if (token && token.reason) {
      return;
    }
    if (facilityRes.status !== StatusCode.OK) {
      setFilterState(prev => ({ ...prev, facility_list: [] }));
      return;
    }

    setFilterState(prev => ({ ...prev, facility_list: facilityRes.data }));
  }

  async function createTournament() {
    const tournamentRes = await PostTournament(
      {
        ...tournamentState,
        date: formatDate(tournamentState.date as Date),
      },
      true,
    );

    console.log(tournamentRes);
    if (tournamentRes.status !== StatusCode.OK) {
      dispatch(showError(tournamentRes.data.message ?? "Error creating tournament.")); //TODO: Translation
      return;
    }

    dispatch(showSuccess("Successfully created tournament.")); // TODO: Translation
    history.push("/admin/events-tournament");
  }

  const primaryAction = {
    content: "Create", // TODO: Translation
    action: () => createTournament(),
    disabled: filterState.facility_list === undefined,
  };

  return (
    <Page
      title="New Tournament" // TODO: Translation
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[{ label: "Tournaments", /* TODO: Translation */ url: "/admin/events-tournament" }]}
    >
      <Form>
        <Card title="Tournament Details" /* TODO: Translation*/>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <div style={{ marginTop: "6px" }}>
                  <Input
                    value={tournamentState.name}
                    label="Name" // TODO: Translation
                    id="name"
                    onChange={e => setTournamentState(prev => ({ ...prev, name: e.target.value }))}
                    placeholder="Tournament Name" // TODO: Translation
                  />
                </div>

                <SelectNew
                  id={"facility_select"}
                  label={"Facility"} // TODO: Translation
                  value={tournamentState.facility_id}
                  onChange={id => setTournamentState(prev => ({ ...prev, facility_id: id }))}
                  isLoading={filterState.facility_list === undefined}
                  options={filterState.facility_list?.map(facility => ({
                    id: facility.id,
                    label: facility.long_name,
                  }))}
                />
              </FormLayout.Group>
            </FormLayout>
            <FormLayout>
              <FormLayout.Group>
                <TimePick
                  value={tournamentState.start_time}
                  onChange={timeString =>
                    setTournamentState(prevState => ({ ...prevState, start_time: timeString }))
                  }
                  label={"Time"} // TODO: Translation
                  size="large"
                  positionTop
                />
                <DatePickerInput
                  months={1}
                  label="Date" // TODO: Translation
                  position={"center"}
                  startingDate={tournamentState.date as Date}
                  setStartingDate={selectedDate =>
                    setTournamentState({ ...tournamentState, date: selectedDate as Date })
                  }
                />
              </FormLayout.Group>
            </FormLayout>
            <FormLayout>
              <FormLayout.Group>
                <Select
                  smallDropdownSize
                  onChange={(val: TTournamentTypes) =>
                    setTournamentState(prev => ({ ...prev, tournament_type: val }))
                  }
                  defaultValue={tournamentState.tournament_type}
                  label="Tournament Type"
                >
                  {tournamentTypes(t).map((type, index: number) => {
                    return (
                      <Option key={index} value={type.value} name={type.label}>
                        <span>{type.label}</span>
                      </Option>
                    );
                  })}
                </Select>
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>
      </Form>
    </Page>
  );
};

export const tournamentTypes = (t: TFunction): { label: string; value: TTournamentTypes }[] => {
  return [
    { label: "Regular", value: "Regular" },
    { label: "Shotgun", value: "Shotgun" },
    { label: "Crossover", value: "Crossover" },
  ];
};

export default TournamentNew;
