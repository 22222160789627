import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./supportTab.scss";
import { useTranslation } from "react-i18next";

interface ISupportTabProps {
  children: React.ReactNode;
}

interface ISupportTabState {
  tabRef: React.MutableRefObject<HTMLDivElement>;
  isTabOpen: boolean;
}

export function SupportTab(props: ISupportTabProps) {
  const { children } = props;
  const { t, i18n } = useTranslation();
  const [supportTabState, setSupportTabState] = useState<ISupportTabState>({
    tabRef: useRef<HTMLDivElement>(),
    isTabOpen: false,
  });

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideDropdownClick);
    document.addEventListener("touchstart", handleOutsideDropdownClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideDropdownClick);
      document.removeEventListener("touchstart", handleOutsideDropdownClick);
    };
  }, []);

  function handleOutsideDropdownClick(event: any) {
    if (!supportTabState.tabRef.current || supportTabState.tabRef.current.contains(event.target)) {
      return;
    }

    setSupportTabState(prev => ({ ...prev, isTabOpen: false }));
  }

  function toggleSupportTabVisibility() {
    setSupportTabState(prev => ({ ...prev, isTabOpen: !prev.isTabOpen }));
  }

  return (
    <div ref={supportTabState.tabRef} className="support-tab">
      <OverlayTrigger
        placement="auto"
        overlay={
          supportTabState.isTabOpen ? (
            <div></div>
          ) : (
            <Tooltip id="leftMenuToolTip">{`${t("components.support_tab.support_tab.001")}`}</Tooltip>
          )
        }
      >
        <div onClick={toggleSupportTabVisibility}>{children}</div>
      </OverlayTrigger>
      <div style={{ display: supportTabState.isTabOpen ? "block" : "none" }} className="support-tab-content">
        <div className="support-tab-content-header">{t("components.support_tab.support_tab.002")}</div>
        <hr className="support-tab-content-dividing-line" />
        <div className="support-tab-content-options">
          <div className="contents" onClick={toggleSupportTabVisibility}>
            <a
              href="https://support-cloud.teeon.com/hc/en-us/requests/new"
              target="_blank"
              className="support-tab-content-option"
              rel="noreferrer"
            >
              <FontAwesomeIcon className="support-tab-content-option-icon" icon={["far", "ticket"]} />
              <div>{t("components.support_tab.support_tab.008")}</div>
            </a>
            <a
              href="http://support-cloud.teeon.com"
              target="_blank"
              className="support-tab-content-option"
              rel="noreferrer"
            >
              <FontAwesomeIcon className="support-tab-content-option-icon" icon={["far", "question"]} />
              <div>{t("components.support_tab.support_tab.004")}</div>
            </a>
            <a
              href="https://support-cloud.teeon.com/hc/en-us/categories/24115511477517-New-Features-Enhancements"
              target="_blank"
              className="support-tab-content-option"
              rel="noreferrer"
            >
              <FontAwesomeIcon className="support-tab-content-option-icon" icon={["far", "code-merge"]} />
              <div>Versions</div>
            </a>
            <a href="tel:5194347877" className="support-tab-content-option">
              <FontAwesomeIcon className="support-tab-content-option-icon" icon={["far", "phone"]} />
              <div>{t("components.support_tab.support_tab.005")}</div>
            </a>
            <a href="mailto:support@teeon.com" className="support-tab-content-option">
              <FontAwesomeIcon className="support-tab-content-option-icon" icon={["far", "envelope"]} />
              <div>{t("components.support_tab.support_tab.006")}</div>
            </a>
            <a
              href="https://remote.tee-on.com/"
              target="_blank"
              className="support-tab-content-option"
              rel="noreferrer"
            >
              <FontAwesomeIcon className="support-tab-content-option-icon" icon={["far", "cloud-arrow-up"]} />
              <div>{t("components.support_tab.support_tab.007")}</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
