import { useMemo, useState } from "react";

type TModalState<T> = {
  isOpen: boolean;
  /**
   * Storage value for dynamic Types.
   * Destructured into state callback obj.
   */
  value: T;
};
type TModalDefaultState<T> = T & {
  isOpen?: boolean;
  /**
   * Defaults to true.
   * Clears modal to initialState values passed with hook declaration.
   */
  clearOnClose?: boolean;
};

/** Ensure initial props contain required isOpen */
function normalizeModalProps<T>(state: TModalDefaultState<T>) {
  let normalized = state ? { ...state } : ({ isOpen: false, clearOnClose: true } as TModalDefaultState<T>);
  if (state?.isOpen === undefined) {
    normalized = { ...normalized, isOpen: false };
  }
  if (state?.clearOnClose === undefined) {
    normalized = { ...normalized, clearOnClose: true };
  }
  return normalized;
}

/** Appends isOpen boolean value to the initial state given. */
export default function useModal<T>(initialState?: TModalDefaultState<T>) {
  const { isOpen, clearOnClose, ...initialValues } = normalizeModalProps(initialState);
  const [state, setState] = useState<TModalState<T>>({
    isOpen: isOpen,
    value: initialValues as T,
  });

  const closeModal = () =>
    setState(prev => ({
      isOpen: false,
      value: clearOnClose ? ({ ...initialValues } as T) : prev.value,
    }));

  const updateModal = (newState: Partial<T> & { isOpen?: boolean }) => {
    const { isOpen, ...rest } = newState;
    setState(prev => {
      return {
        ...prev,
        isOpen: isOpen !== undefined ? isOpen : prev.isOpen,
        value: clearOnClose && isOpen === false ? ({ ...initialValues } as T) : ({ ...prev.value, ...rest } as T),
      };
    });
  };

  const memoReturn = useMemo(() => {
    return {
      state: {
        isOpen: state.isOpen,
        ...state.value,
      },
      closeModal,
      updateModal,
    };
  }, [state]);

  return memoReturn;
}
