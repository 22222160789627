import React, { useEffect, useLayoutEffect, useRef, useState, FocusEvent } from "react";
import { isEmpty } from "helpers/Helpers";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./datePickerBlurTitleInput.scss";
import classNames from "classnames";

export interface IDatePickerBlurTitleInputProps {
  value: string;
  containerClassName: string;
  calendarIconClassName: string;
  centeredInputText: boolean;
  rightAlignedCalendar?: boolean;
  error: boolean;
  errorTitle: string;
  blurTitle: string;
  showCalendarIcon: boolean;
  showErrorOnEmptyString: boolean;
  showBlurTitleOnError: boolean;
  onChange: (event: any) => void;
  placeholder?: string;
  revealText?: boolean;
  onFocus?: () => void;
}

interface IDatePickerBlurTitleInputState {
  focused: boolean;
  selectionStart: number;
  selectionEnd: number;
  // ref: React.MutableRefObject<HTMLInputElement>;
}

export default function DatePickerBlurTitleInput(props: IDatePickerBlurTitleInputProps) {
  const {
    value,
    containerClassName,
    calendarIconClassName,
    centeredInputText,
    rightAlignedCalendar,
    error,
    errorTitle,
    blurTitle,
    showCalendarIcon,
    showErrorOnEmptyString,
    showBlurTitleOnError,
    onChange,
    placeholder,
    revealText,
    onFocus,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [state, setState] = useState<IDatePickerBlurTitleInputState>({
    focused: false,
    selectionStart: undefined,
    selectionEnd: undefined,
    // ref: useRef(null),
  });

  const [elementType, setElementType] = useState<"input" | "button">("input");
  const windowSize = useWindowSize();

  useLayoutEffect(() => {
    if (state.focused && inputRef.current) {
      inputRef.current.selectionStart = state.selectionStart;
      inputRef.current.selectionEnd = state.selectionEnd;
    }
  }, [state.focused]);

  /**
   * Determine element to render.
   * Re-runs a second time after setting elementType in order to set previous focus
   * */
  useEffect(() => {
    if (!windowSize || (windowSize && windowSize.width === undefined)) {
      return;
    }

    if (windowSize.width < 1024) {
      if (elementType !== "button") {
        setElementType("button");
        return;
      }
      if (state.focused && buttonRef.current) {
        buttonRef.current.focus();
      }
    }
    if (windowSize.width >= 1024) {
      // Rerender element type and re-run useEffect
      if (elementType !== "input") {
        setElementType("input");
        return;
      }
      // Second run will set the new focus if already focused
      if (state.focused && inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [windowSize, elementType]);

  function handleDisplayValue() {
    if (revealText === undefined ? state.focused : revealText) {
      return value;
    } else if (error) {
      if (!showErrorOnEmptyString && isEmpty(value)) {
        return "";
      } else if (showBlurTitleOnError) {
        return blurTitle;
      } else {
        return errorTitle;
      }
    } else {
      return blurTitle;
    }
  }

  function handleFocus(
    e: FocusEvent<HTMLInputElement> | FocusEvent<HTMLButtonElement> | React.MouseEvent<HTMLButtonElement>,
  ) {
    if (onFocus) {
      onFocus();
    }

    setState(prev => ({
      ...prev,
      focused: true,
      selectionStart: (e as FocusEvent<HTMLInputElement>).target?.selectionStart ?? prev.selectionStart,
      selectionEnd: (e as FocusEvent<HTMLInputElement>).target?.selectionEnd ?? prev.selectionEnd,
    }));
  }

  function handleBlur(e: any) {
    setState(prev => ({
      ...prev,
      focused: false,
    }));
  }

  const hasError = error && (showErrorOnEmptyString ? true : !isEmpty(value));
  const isFocusedError = state.focused && hasError;
  const isError = !state.focused && hasError;
  const isFocused = state.focused && !hasError;

  if (elementType === "input") {
    return (
      <div
        className={classNames(containerClassName, "date-picker-blur-title-input-container", {
          "focused-error": isFocusedError,
          error: isError,
          focused: isFocused,
        })}
        style={{
          justifyContent: centeredInputText && "center",
          flexDirection: rightAlignedCalendar ? "row-reverse" : "row",
        }}
      >
        {showCalendarIcon && <FontAwesomeIcon className={calendarIconClassName} icon={["fal", "calendar"]} />}
        <input
          ref={inputRef}
          className="date-picker-blur-title-input"
          style={{
            width: centeredInputText ? "100px" : "100%",
          }}
          placeholder={placeholder ?? ""}
          value={handleDisplayValue()}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </div>
    );
  }

  if (elementType === "button") {
    return (
      <button
        ref={buttonRef}
        className={classNames(containerClassName, "date-picker-blur-title-button", {
          "focused-error": isFocusedError,
          error: isError,
          focused: isFocused,
        })}
        style={{
          justifyContent: centeredInputText && "center",
        }}
        onClick={e => {
          e.preventDefault();
          handleFocus(e);
        }}
        placeholder={placeholder ?? ""}
        onBlur={handleBlur}
      >
        {showCalendarIcon && <FontAwesomeIcon className={calendarIconClassName} icon={["fal", "calendar"]} />}
        {handleDisplayValue()}
      </button>
    );
  }
}
