import React, { useEffect, useRef } from "react";
import { useFormEditContext } from "../FormEditContext";
import FormEditInputRow from "./FormEditInputRow";
import Callout from "components/callout/Callout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonNew as Button } from "components/buttonNew";
import { INewInputModal } from "../FacilityFormEdit";
import "./formEditInputLayout.scss";
import { useDropEditInputLayout } from "hooks/editInputDragAndDrop/useDropEditInputLayout/useDropEditInputLayout";
import { TFunction, useTranslation } from "react-i18next";

interface IFormEditInputLayoutProps {
  setNewInputModal: (value: React.SetStateAction<INewInputModal>) => void;
}

export default function FormEditInputLayout(props: IFormEditInputLayoutProps) {
  const { setNewInputModal } = props;

  const formEditContext = useFormEditContext();
  const { t, i18n } = useTranslation();
  const formLayoutRef = useRef<HTMLDivElement>(null);
  const formLayoutRowContainerRefs = useRef<HTMLDivElement[]>([]);

  const { drop, customDragLayer } = useDropEditInputLayout(
    formLayoutRef,
    formLayoutRowContainerRefs,
    formEditContext.formInputRowsContainerActions.update,
    formEditContext.formInputRowsContainer.updatedState,
  );

  useEffect(() => {
    if (formEditContext.formInputRowsContainer?.updatedState) {
      const numRows = Object.keys(formEditContext.formInputRowsContainer.updatedState).length;

      formLayoutRowContainerRefs.current = formLayoutRowContainerRefs.current.slice(0, numRows);
    }
  }, [formEditContext.formInputRowsContainer?.updatedState]);

  return (
    <>
      {formEditContext.formInputRowsContainer?.updatedState &&
      Object.keys(formEditContext.formInputRowsContainer.updatedState).length > 0 ? (
        <div className="form-edit-input-layout">
          <div
            ref={el => {
              drop(el);
              formLayoutRef.current = el;
            }}
            className="form-layout-rows"
          >
            {Object.keys(formEditContext.formInputRowsContainer.updatedState).map((row, index) => {
              const formInputs = formEditContext.formInputRowsContainer.updatedState[row];
              const formInputIds = formInputs.map(formInput => formInput.id);
              const formEditInputRowIdentifier = `[${formInputIds.toString()}]`;

              return (
                <div ref={el => (formLayoutRowContainerRefs.current[index] = el)} key={formEditInputRowIdentifier}>
                  <FormEditInputRow formInputRow={row} formInputs={formInputs} />
                </div>
              );
            })}
            {customDragLayer}
          </div>
          <hr className="form-layout-add-input-divider" />
          <Button
            type="text"
            onClick={() => setNewInputModal(prev => ({ ...prev, open: true }))}
            className="form-layout-add-input"
          >
            <FontAwesomeIcon className="form-layout-add-input-icon" size="sm" icon={["far", "plus"]} />
            <span>{t("secure.facility.settings.forms.drag_and_drop_elements.form_edit_input_layout.001")}</span>
          </Button>
        </div>
      ) : (
        <Callout
          type={"info"}
          title={t("secure.facility.settings.forms.drag_and_drop_elements.form_edit_input_layout.002")}
          content={t("secure.facility.settings.forms.drag_and_drop_elements.form_edit_input_layout.003")}
        />
      )}
    </>
  );
}
