import React, { useEffect, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";
import moment from "moment";

import { StatusCode } from "api/protocols";
import {
  CustomerMembership,
  GetCustomerMemberships,
  IGetCustomerMemberships,
} from "api/rpc/2024-04/facilityAdmin/customer/membership";

import { useAppSelector } from "hooks/redux";

import Page from "components/page/Page";
import Search from "components/search/Search";

import DataTable from "../tabs/houseAccounts/DataTable";
import { Badge } from "components/badge/Badge";

const tableColumns = (t: TFunction<"translation", undefined>) => {
  return [
    { label: t("secure.facility.customer.main.memberships_sold.003"), width: "auto" }, // full_name + email
    { label: t("secure.facility.customer.main.memberships_sold.004"), width: "auto" },
    { label: t("secure.facility.customer.main.memberships_sold.005"), width: "auto" },
    { label: t("secure.facility.customer.main.memberships_sold.006"), width: "auto" },
    { label: t("secure.facility.customer.main.memberships_sold.007"), width: "auto" },
    { label: t("secure.facility.customer.main.memberships_sold.008"), width: "auto" },
    { label: t("secure.facility.customer.main.memberships_sold.009"), width: "auto" },
  ];
};

export default function MembershipsSold() {
  const { t } = useTranslation();

  const { facilityStore } = useAppSelector(store => store);

  const [membershipList, setMembershipList] = useState<CustomerMembership[]>(undefined);
  const [filters, setFilters] = useState<Partial<IGetCustomerMemberships>>({
    search: "",
    limit: 50,
    offset: 0,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadMembershipList(source.token);

    return () => {
      source.cancel();
    };
  }, [filters]);

  async function loadMembershipList(token?: CancelToken) {
    if (membershipList !== undefined) {
      setMembershipList(undefined);
    }
    const res = await GetCustomerMemberships(filters, false, token);

    if (token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      setMembershipList([]);
      return;
    }

    setMembershipList(res.data);
  }

  return (
    <Page title={t("secure.facility.customer.main.memberships_sold.001")}>
      <Search
        historyKey={"facility-customer-sold-memberships"}
        searchCallback={(searchValue: string) => setFilters(prev => ({ ...prev, search: searchValue }))}
        placeholder={t("secure.facility.customer.main.memberships_sold.002")}
      />
      <div style={{ paddingTop: "8px" }}>
        <DataTable
          columns={tableColumns(t)}
          footer={{
            tableLimit: filters.limit,
            tableOffset: filters.offset,
            disableNextOffset: !membershipList || !(membershipList?.length === filters.limit),
            handleTableOffset: direction =>
              setFilters(prev => ({
                ...prev,
                offset: direction === "prev" ? prev.offset - prev.limit : prev.offset + prev.limit,
              })),
          }}
          loading={membershipList === undefined}
        >
          {membershipList?.map(membership => (
            <tr key={membership.id}>
              <td>
                <div>{membership.customer?.full_name}</div>
                <div>{membership.customer?.email}</div>
              </td>
              <td>
                {facilityStore.facilities.find(obj => obj.id === membership.home_facility_id) ? (
                  <>{facilityStore.facilities.find(obj => obj.id === membership.home_facility_id).long_name}</>
                ) : (
                  <p className="field-unavailable">N/A</p>
                )}
              </td>
              <td>
                <div>{membership.membership_title}</div>
                <div>{membership.membership_subtitle}</div>
              </td>

              <td>{membership.customer?.member_code ? membership.customer.member_code : ""}</td>
              <td>{membership.season && <Badge type="primary">{membership.season}</Badge>}</td>
              <td>
                <Badge type={generateBadgeType(membership.status)}>{membership.status_readable}</Badge>
              </td>
              <td>
                {membership.expiry ? (
                  moment(membership.expiry).format("MMMM Do, YYYY")
                ) : (
                  <p className="field-unavailable">N/A</p>
                )}
              </td>
            </tr>
          ))}
        </DataTable>
      </div>
    </Page>
  );
}

/** Return badge type from membership.status
 *   -  Easily adjust badge styling in 1 place going forward
 */
function generateBadgeType(status: "active" | "expired" | "disabled") {
  switch (status) {
    case "active":
      return "success";
    case "expired":
      return "warning";
    case "disabled":
      return "error";
    default:
      return "primary";
  }
}
