import React, { useEffect, useMemo, useState } from "react";
import axios, { CancelToken } from "axios";
import { useParams, useHistory } from "react-router";
import { StatusCode } from "api/protocols";
import Card from "components/card/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { loadLeague, loadFacility } from "redux/actions/customer/leagueRegistration";
import { showError } from "redux/actions/ui";
import { ICustomer } from "redux/reducers/models/customer";
import LeagueHeader from "../LeagueHeader/LeagueHeader";
import { ILeague, TLeagueFlight, TLeagueSeasonLeaderboard } from "redux/reducers/models/league";
import { IFacility } from "pages/guest/models/facility";
import "./LeagueSeasonLeaderboard.scss";
import { GetSeasonLeaderboard } from "api/rpc/2024-04/guest/league/scoring/leaderboards";
import Page from "components/page/Page";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import { useTranslation } from "react-i18next";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import { valueToString } from "helpers/Helpers";

interface IParams {
  facilityShortName: string;
  leagueHandle: string;
}

export default function LeagueSeasonLeaderboard() {
  const { facilityShortName, leagueHandle } = useParams<IParams>();
  const uiActions = useAppDispatch();
  const history = useHistory();
  const { leagueRegistrationStore } = useAppSelector(store => store);
  const { t, i18n } = useTranslation();

  const [leaderboards, setLeaderboards] = useState<Array<TLeagueSeasonLeaderboard>>(undefined);

  useEffect(() => {
    if (!leagueRegistrationStore.facility) {
      void uiActions(loadFacility(facilityShortName, true));
    }
  }, []);

  useEffect(() => {
    if (leagueRegistrationStore.facility && !leagueRegistrationStore.league) {
      void uiActions(loadLeague(leagueRegistrationStore.facility.id, leagueHandle, true));
    }

    const source = axios.CancelToken.source();

    void loadLeaderboards(source.token);

    return () => source.cancel();
  }, [leagueRegistrationStore.facility]);

  async function loadLeaderboards(token?: CancelToken) {
    const leaderboardRes = await GetSeasonLeaderboard(
      {
        facility_id: leagueRegistrationStore.facility?.id,
        league_handle: leagueHandle,
      },
      token ? false : true,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (leaderboardRes.status !== StatusCode.OK) {
      uiActions(showError("Error loading leaderboards")); // TODO: Translation
      return;
    }

    setLeaderboards(leaderboardRes.data);
  }

  return (
    <>
      <LeagueHeader facility={leagueRegistrationStore.facility} league={leagueRegistrationStore.league} />
      {leagueRegistrationStore.facility && leagueRegistrationStore.league && (
        <Page title={"Season Leaderboards" /* TODO: Translation */}>
          <DataTable
            columns={[
              { label: "Title" }, // TODO: Translation
              { label: "Division" }, // TODO: Translation
              { label: "Organization" }, // TODO: Translation
            ]}
            loading={leaderboards === undefined}
          >
            {leaderboards?.map(leaderboard => (
              <tr
                key={leaderboard.id}
                onClick={() =>
                  history.push(
                    `/league/${facilityShortName}/${leagueHandle}/scoring/season-leaderboard/${leaderboard.id}`,
                  )
                }
                className="clickable"
              >
                <td>{leaderboard.title}</td>
                <td>{leaderboard.division?.title}</td>
                <td>{valueToString(leaderboard.organization)}</td>
              </tr>
            ))}
          </DataTable>
        </Page>
      )}
    </>
  );
}
