import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { IFacility } from "redux/reducers/models/facility";

const apiVersion = "2024-04";
const adminType = "master-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/product/type";

interface IGetProductType {
  id?: number;
  client_id?: number;
}

interface IPutProductType {
  id: number;
  client_id: number;
  title: string;
}

interface IPostProductType {
  client_id: number;
  title: string;
}

interface IDeleteProductType {
  id: number;
  client_id: number;
}

// GET Product Types
export async function GetProductTypes(params: IGetProductType, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseUrl, params, useGlobalLoader, token);
}

// PUT Product Type
export async function PutProductType(params: IPutProductType, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

// POST Product Type
export async function PostProductType(params: IPostProductType, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

// Delete Product Type
export async function DeleteProductType(params: IDeleteProductType, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}
