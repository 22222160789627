import React, { useEffect, useRef } from "react";

import { delay } from "components/_util/delay";
import Callout, { CalloutProps } from "components/callout/Callout";
import Portal from "elements/Portal";

import "./toast.scss";

type ToastProps = Pick<CalloutProps, "title" | "type"> & {
  onClose: () => void;
  onSave?: () => void;
  duration?: number;
  mounted?: boolean;
  /** Provide 500ms delay before callback functions applied. */
  animated?: boolean;
};

/** Displayed using Portal. */
export default function Toast(props: ToastProps) {
  const ref = useRef<HTMLDivElement>(null);

  const { duration, mounted, animated = false, onClose, onSave, ...rest } = props;
  const animationTime = animated ? 500 : 0;

  useEffect(() => {
    const currentStyle = ref?.current?.style;
    const autoDestroy = async () => {
      await delay(duration - animationTime);
      void handleToastClose(animated, currentStyle);
    };

    if (duration) {
      void autoDestroy();
    }
  }, [duration, animated]);

  // Handle animation before closing
  async function handleToastClose(animated: boolean, currentStyle: CSSStyleDeclaration, saving?: boolean) {
    // Animate fade out during DOM removal
    if (animated && currentStyle) {
      currentStyle.transition = "all 0.5s";
      currentStyle.transform = "translateY(-50%)";
      currentStyle.opacity = "0";

      await delay(animationTime);
    }

    if (saving) {
      onSave();
      return;
    }

    onClose();
  }

  return (
    <Portal isMounted={mounted}>
      <div ref={ref} className={`ui-toast-manager${animated ? " animated" : ""}`}>
        <div className="ui-toast-manager_toast-wrapper">
          <Callout
            toast
            onClose={() => handleToastClose(animated, ref?.current?.style)}
            onSave={props.onSave ? () => handleToastClose(animated, ref?.current?.style, true) : undefined}
            {...(rest as CalloutProps)}
          />
        </div>
      </div>
    </Portal>
  );
}
