import React, { useEffect, useState, useCallback } from "react";
import { useHistory, Link } from "react-router-dom";
import { useParams } from "react-router";
import { StatusCode } from "api/protocols";
import Page from "components/page/Page";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import { Select as SelectNew, Select } from "components/select/";
import { List, isEqualWith, isNull } from "lodash";
import ReactDOM from "react-dom";
import { NotificationType } from "components/notificationBar/NotificationBar";
import { PutTicketStub } from "api/rpc/ticket";
import "pages/secure/facility/product/TicketStub.scss";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import { ICustomer } from "redux/reducers/models/customer";
import { GetCustomer } from "api/rpc/facilityAdmin/customer";
import { PostCustomer } from "api/rpc/customer";
import NewCustomer, { ICustomerInfoState } from "components/newCustomer/NewCustomer";
import Input from "components/form/input/Input";
import { useTranslation } from "react-i18next";
import Sheet from "components/sheet/Sheet";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GolferCard from "components/bookingPopUp/golferCard/GolferCard";
import { GetFacility } from "api/rpc/2022-09/facilityAdmin/facility/facility";
import Markdown from "components/markdown/Markdown";

import { GetTicketStub, GetTicketStubPdf } from "api/rpc/2024-04/facilityAdmin/ticket/ticket";
import { customerErrorMessage } from "helpers/Helpers";
import { useAppDispatch } from "hooks/redux";
import { showError } from "redux/actions/ui";
import CustomerImage from "elements/customer/CustomerImage";

interface ITicketStubState {
  id: number;
  customer_id: number;
  code: string;
  expiry_date_string: string;
  expiry_date: Date;
  last4: number;
  pin: number;
  redemption_product: string;
  source_url: string;
  description: string;
  status: string;
  selectedDate: Date;
  status_id: number;
  showDateSelector: boolean;
  customer: any;
  redeemed_facility_id: number;
  redeemed_at: any;
  facility: any;
  title: string;
  subtitle: string;
  included: string;
  logo_image: any;
  ticket_description: string;
}

interface IChangeCustomerState {
  showChangeCustomer: boolean;
  showNewCustomer: boolean;
  customerQuery: string;
  customerSearching: boolean;
  customerSearchResult: Array<ICustomer>;
  selectedCustomer: ICustomer;
  currentCustomer: any;
}

export default function Single(props: any) {
  const history = useHistory();
  const { stubId }: any = useParams();
  const { t, i18n } = useTranslation();
  const { Option } = Select;

  const urlParams = useParams();
  const dispatch = useAppDispatch();

  const [ticketStubStateBeforeChanges, setTicketStubStateBeforeChanges] = useState<ITicketStubState>(undefined);
  const [ticketStubLoaded, setTicketStubLoaded] = useState<boolean>(false);

  const [ticketStubState, setTicketStubState] = useState({
    id: null,
    customer_id: null,
    code: "",
    expiry_date_string: "",
    expiry_date: new Date(),
    last4: null,
    pin: null,
    redemption_product: "",
    source_url: "",
    description: "",
    status: "",
    selectedDate: null,
    status_id: null,
    showDateSelector: false,
    customer: null,
    redeemed_facility_id: null,
    redeemed_at: null,
    facility: null,
    title: "",
    subtitle: "",
    logo_image: null,
    included: "",
    ticket_description: "",
  });

  const [changeCustomerState, setChangeCustomerState] = useState<IChangeCustomerState>({
    showChangeCustomer: false,
    showNewCustomer: false,
    customerQuery: "",
    customerSearching: false,
    customerSearchResult: [],
    selectedCustomer: null,
    currentCustomer: null,
  });

  useEffect(() => {
    void loadTicketStub();
  }, []);

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = global.setTimeout(() => {
        void changePlayerSearch(mounted, changeCustomerState.customerQuery);
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      setChangeCustomerState(prevState => ({ ...prevState, playerSearchResult: [] }));
    };
  }, [changeCustomerState.customerQuery]);

  async function changePlayerSearch(mounted: boolean, customerSearchQuery: string) {
    try {
      if (customerSearchQuery === "") {
        if (mounted) {
          setChangeCustomerState(prevState => ({ ...prevState, customerSearchResult: [] }));
        }
        return;
      } else {
        setChangeCustomerState(prevState => ({ ...prevState, customerSearching: true }));
        const customerRes = await GetCustomer({ search: customerSearchQuery }, false);
        if (customerRes.status !== StatusCode.OK) {
          setChangeCustomerState(prevState => ({ ...prevState, customerSearching: false, customerSearchResult: [] }));
          return;
        } else if (mounted) {
          setChangeCustomerState(prevState => ({
            ...prevState,
            customerSearching: false,
            customerSearchResult: customerRes.data,
          }));
        }
      }
    } catch (error) {
      console.log("err", error);
    }
    return;
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setTicketStubState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleCheckboxChange(event: any) {
    const { id, checked } = event.target;

    const tempReciprocal = 0;
  }

  async function loadTicketStub() {
    const ticketStubRes = await GetTicketStub({ id: Number(stubId), extended: true }, true);

    if (ticketStubRes.status !== StatusCode.OK) {
      return;
    }

    const ticketStub = ticketStubRes.data[0];

    let currentExpiryDate = new Date();
    let tempDate = "";

    if (ticketStub.ticket.expiry_type === "none") {
      setTicketStubState(prevState => ({ ...prevState, showDateSelector: false }));
    } else {
      setTicketStubState(prevState => ({ ...prevState, showDateSelector: true }));
    }

    if (ticketStub.expiry_date === null) {
      tempDate = new Date().toISOString().slice(0, 10);
    } else {
      // const dateArray = ticketStub.expiry_date.split("-");

      tempDate = moment(ticketStub.expiry_date).format();

      currentExpiryDate = new Date(tempDate);
    }

    let redeemedAtFacility: any = null;

    if (ticketStub.redeemed_facility_id) {
      const facilityRes = await GetFacility({ id: ticketStub.redeemed_facility_id }, true);

      redeemedAtFacility = facilityRes.data[0];
    }

    let customer: ICustomer = null;
    if (ticketStub.customer_id) {
      const currentCustomer = await GetCustomer({ id: ticketStub.customer_id }, true);
      customer = currentCustomer.data?.length === 1 ? currentCustomer.data[0] : null;
    }
    // const currentExpiryDate = new Date(ticketStub.expiry_date);

    ReactDOM.unstable_batchedUpdates(() => {
      setTicketStubState(prev => ({
        ...prev,
        id: Number(ticketStub.id),
        customer_id: ticketStub.customer_id,
        code: ticketStub.code,
        expiry_date: currentExpiryDate,
        expiry_date_string: tempDate,
        last4: ticketStub.last4,
        pin: ticketStub.pin,
        redemption_product: ticketStub.redemption_product,
        source_url: ticketStub.source_url,
        description: ticketStub.description,
        status: String(ticketStub.status),
        selectedDate: currentExpiryDate,
        customer: customer,
        redeemed_facility_id: ticketStub.redeemed_facility_id,
        redeemed_at: ticketStub.redeemed_at,
        facility: redeemedAtFacility,
        included: ticketStub.ticket.included,
        logo_image: ticketStub.ticket.logo_image,
        title: ticketStub.ticket.title,
        subtitle: ticketStub.ticket.subtitle,
        ticket_description: ticketStub.ticket.description,
      }));

      setTicketStubLoaded(true);
    });
  }

  async function saveTicketStub() {
    const res = await PutTicketStub(ticketStubState, true);

    if (res.status !== StatusCode.OK) {
      return;
    }

    const reloadUrl = "/admin/tickets/stubs/" + String(stubId);

    setTicketStubStateBeforeChanges(ticketStubState);

    history.push(reloadUrl);
  }

  function unsavedChangesExist() {
    if (ticketStubStateBeforeChanges === undefined) {
      if (ticketStubLoaded) {
        setTicketStubStateBeforeChanges(ticketStubState);
      }
      return false;
    }

    return !isEqualWith(ticketStubStateBeforeChanges, ticketStubState, (originalValue, newValue) => {
      if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
        return true;
      }
    });
  }

  function cancelUnsavedChanges() {
    setTicketStubState(ticketStubStateBeforeChanges);
  }

  function setExpiryDate(date: Date) {
    setTicketStubState(prevState => ({ ...prevState, expiry_date: date }));
  }

  function handleShowCustomerChange(customer: any) {
    setChangeCustomerState(prevState => ({
      ...prevState,
      showChangeCustomer: true,
      showNewCustomer: false,
      customerQuery: "",
      customerSearchResult: [],
      currentCustomer: customer,
    }));
  }

  function closeCreateNewCustomer() {
    setChangeCustomerState(prevState => ({
      ...prevState,
      showNewCustomer: false,
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
    }));
  }

  function closeChangeCustomer() {
    closeCreateNewCustomer();
    setChangeCustomerState(prevState => ({
      ...prevState,
      showNewCustomer: false,
      currentCustomer: null,
      showChangeCustomer: false,
      customerQuery: "",
      customerSearchResult: [],
      selectedCustomer: null,
    }));
  }

  function changeCustomer() {
    //uiActions.showSuccess(orderCustomerRes.message);
    setTicketStubState(prevState => ({
      ...prevState,
      customer: changeCustomerState.selectedCustomer,
      customer_id: changeCustomerState.selectedCustomer.id,
    }));
    setChangeCustomerState(prevState => ({
      ...prevState,
      currentCustomer: null,
      showChangeCustomer: false,
      customerSearchResult: [],
      customerQuery: "",
      selectedCustomer: null,
    }));
  }

  async function createNewCustomer(customerInfo: ICustomerInfoState) {
    const inputError =
      customerInfo.firstName === "" ||
      customerInfo.lastName === "" ||
      (customerInfo.emailAddress === "" && customerInfo.phoneNumber === "");

    if (inputError) {
      //uiActions.showError("Please fill all fields");
    }

    const customerRes = await PostCustomer(
      {
        first_name: customerInfo.firstName,
        last_name: customerInfo.lastName,
        phone: customerInfo.phoneNumber || null,
        email: customerInfo.emailAddress || null,
        state: "disabled",
      },
      true,
    );

    if (customerRes.status !== StatusCode.OK) {
      dispatch(showError(customerErrorMessage(t, customerRes?.data?.message)));
      return;
    }
    setChangeCustomerState(prevState => ({
      ...prevState,
      showNewCustomer: false,
      selectedCustomer: customerRes.data.data,
    }));
    //uiActions.showSuccess(customerRes.data.message);
  }

  function changePlayerHandleCustomerSearch(query: string) {
    setChangeCustomerState(prevState => ({ ...prevState, customerQuery: query }));
  }

  function handleChangePlayerSelection(id: number, customer: ICustomer) {
    setChangeCustomerState(prevState => ({ ...prevState, selectedCustomer: customer }));
  }

  function openNewCustomerSheet() {
    setChangeCustomerState(prevState => ({
      ...prevState,
      showNewCustomer: true,
    }));
  }

  const statusOptions = [
    {
      title: t("secure.facility.product.ticket_stub.001"),
      value: "valid",
    },
    {
      title: t("secure.facility.product.ticket_stub.002"),
      value: "redeemed",
    },
    {
      title: t("secure.facility.product.ticket_stub.003"),
      value: "void",
    },
    {
      title: t("secure.facility.product.ticket_stub.004"),
      value: "cancelled",
    },
    {
      title: t("secure.facility.product.ticket_stub.005"),
      value: "expired",
    },
  ];

  function handleDropDownChange(value: any, property: string) {
    setTicketStubState(prev => ({ ...prev, [property]: value }));
  }

  async function handleTicketDownload() {
    const ticketStubIds: any = [];
    ticketStubIds.push(ticketStubState.id);

    const params = {
      ticket_stub_ids: ticketStubIds,
    };

    const downloadReceiptRes = await GetTicketStubPdf(params, true);

    if (downloadReceiptRes.status !== StatusCode.OK) {
      dispatch(showError("Error downloading ticket stub"));

      return;
    }

    window.open(downloadReceiptRes.data, "_blank");

    return;
  }

  return (
    ticketStubLoaded && (
      <Page
        title={ticketStubState.code}
        narrow
        splitLayout
        breadcrumbs={[
          { prefix: true, label: t("secure.facility.product.ticket_stub.021"), url: "/admin/tickets/stubs" },
        ]}
        notificationBarProps={{
          isVisible: unsavedChangesExist(),
          onAction: saveTicketStub,
          onCancel: cancelUnsavedChanges,
        }}
        multipleActionDropdownAction={{
          label: "More Options",
          dropdownProps: {
            alignment: "right",
            options: [
              {
                type: "handler",
                label: "Download",
                handler: () => handleTicketDownload(),
                icon: "download",
                // disabled: !productPermissions?.products_edit,
              },
            ],
          },
        }}
      >
        <Page.Section twoThirds>
          <Card title={t("secure.facility.product.ticket_stub.007")}>
            <Card.Section>
              <FormLayout>
                <FormLayout.Group>
                  <div>
                    <Input
                      value={ticketStubState.code}
                      label={t("secure.facility.product.ticket_stub.022")}
                      id="code"
                      onChange={handleInputChange}
                      placeholder={t("secure.facility.product.ticket_stub.022")}
                      readOnly
                    />
                  </div>

                  {ticketStubState.showDateSelector ? (
                    <div id="ticket-date-selector" style={{ marginLeft: "15px" }}>
                      <DatePickerInput
                        label={t("secure.facility.product.ticket_stub.023")}
                        months={1}
                        position="left"
                        startingDate={ticketStubState.selectedDate}
                        setStartingDate={setExpiryDate}
                      />
                    </div>
                  ) : null}
                </FormLayout.Group>

                <FormLayout.Group>
                  <Select
                    label={t("secure.facility.product.ticket_stub.020")}
                    onChange={(value: any) => handleDropDownChange(value, "status")}
                    defaultValue={ticketStubState.status}
                  >
                    {statusOptions.map((options: any, index: number) => {
                      return (
                        <Option key={index} value={options.value} name={options.title}>
                          {options.title}
                        </Option>
                      );
                    })}
                  </Select>
                </FormLayout.Group>
                {ticketStubState.redeemed_at && (
                  <FormLayout.Group>
                    <div>
                      <Input
                        value={ticketStubState?.facility?.long_name}
                        label={t("secure.facility.product.ticket_stub.024")}
                        id="redeemed_at"
                        onChange={handleInputChange}
                        readOnly
                      />
                    </div>
                    <div>
                      <Input
                        value={ticketStubState.redeemed_at}
                        label={t("secure.facility.product.ticket_stub.025")}
                        id="redeemed_on"
                        onChange={handleInputChange}
                        readOnly
                      />
                    </div>
                  </FormLayout.Group>
                )}

                <FormLayout.Group>
                  <div>
                    <p>Description</p>
                    <Markdown markdownText={ticketStubState.description} />
                  </div>
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>

            <Card.Section title="Ticket Details">
              <FormLayout>
                <FormLayout.Group>
                  <div className="ticket-details-container">
                    {ticketStubState.logo_image ? (
                      <div>
                        <p>Ticket Logo</p>
                        <CustomerImage
                          imageSource={ticketStubState.logo_image}
                          saveProfileImage={null}

                          // deleteProfileImage={deleteCustomerImage}
                        />
                      </div>
                    ) : null}

                    <p className="ticket-details-title">{ticketStubState.title}</p>
                    <p className="ticket-details-subtitle">{ticketStubState.subtitle}</p>
                    <p className="ticket-details-included">{ticketStubState.included}</p>
                    <p className="ticket-details-description">{ticketStubState.ticket_description}</p>
                  </div>
                </FormLayout.Group>

                {/* <FormLayout.Group>
                  <Input
                    value={ticketStubState.title}
                    label={"Ticket title"}
                    id="title"
                    onChange={handleInputChange}
                    placeholder={"Title.."}
                    readOnly
                  />

                  <Input
                    value={ticketStubState.subtitle}
                    label={"Ticket subtitle"}
                    id="subtitle"
                    onChange={handleInputChange}
                    placeholder={"Subtitle.."}
                    readOnly
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Input
                    value={ticketStubState.included}
                    label={"Included"}
                    id="included"
                    onChange={handleInputChange}
                    placeholder={"Included.."}
                    readOnly
                  />

                  <Input
                    value={ticketStubState.ticket_description}
                    label={"Ticket description"}
                    id="ticket_description"
                    onChange={handleInputChange}
                    placeholder={"Description.."}
                    readOnly
                  />
                </FormLayout.Group> */}
              </FormLayout>
            </Card.Section>
          </Card>
        </Page.Section>
        <Page.Section oneThird>
          <Card
            title={t("secure.facility.order.order.023")}
            titleActions={[
              {
                action: () => handleShowCustomerChange(ticketStubState.customer),
                content: t("secure.facility.order.order.024"),
              },
            ]}
          >
            <Card.Section>
              <div className="order-customer">
                {ticketStubState.customer ? (
                  <div>
                    <p className="text-lg">{ticketStubState.customer?.full_name}</p>
                    <p className="text-lg">{ticketStubState.customer?.email}</p>
                    <p className="text-lg">{ticketStubState.customer?.phone}</p>
                    <p className="text-lg">{ticketStubState.customer?.customer_type}</p>
                  </div>
                ) : (
                  <div>
                    <p>{t("secure.facility.order.order.025")}</p>
                  </div>
                )}
              </div>
            </Card.Section>
          </Card>
        </Page.Section>

        <Sheet
          title={
            changeCustomerState.showNewCustomer
              ? t("secure.facility.order.order.026")
              : t("secure.facility.order.order.027")
          }
          open={changeCustomerState.showChangeCustomer}
          size="small"
          closable
          onCancel={closeChangeCustomer}
          onOk={changeCustomer}
          okText={t("secure.facility.order.order.028")}
          okDisabled={changeCustomerState.selectedCustomer ? false : true}
          overflow
        >
          {changeCustomerState.showNewCustomer ? (
            <NewCustomer
              newCustomerSheetActive={changeCustomerState.showNewCustomer}
              onCancel={closeCreateNewCustomer}
              onOk={createNewCustomer}
              searchValue={changeCustomerState.customerQuery}
            />
          ) : (
            <>
              {changeCustomerState.currentCustomer !== null ? (
                <div>
                  <span>{t("secure.facility.order.order.035")}</span>

                  <GolferCard
                    email={changeCustomerState.currentCustomer?.email}
                    name={changeCustomerState.currentCustomer?.full_name}
                    memberCode={changeCustomerState.currentCustomer?.member_code}
                    customerType={changeCustomerState.currentCustomer?.customer_type}
                    phone={changeCustomerState.currentCustomer?.phone}
                  />

                  <FontAwesomeIcon
                    className="ml-auto mr-auto mt-4 mb-2 block text-primary-ui-colour"
                    icon={["far", "arrow-circle-down"]}
                    size="2x"
                  />
                </div>
              ) : null}

              <span>{t("secure.facility.order.order.036")}</span>
              {changeCustomerState.selectedCustomer ? (
                <GolferCard
                  closable
                  removeGolfer={() =>
                    setChangeCustomerState(prevState => ({
                      ...prevState,
                      selectedCustomer: null,
                      playerSearchResult: [],
                      customerQuery: "",
                    }))
                  }
                  email={changeCustomerState.selectedCustomer.email}
                  name={changeCustomerState.selectedCustomer.full_name}
                  memberCode={changeCustomerState.selectedCustomer.member_code}
                  customerType={changeCustomerState.selectedCustomer.customer_type}
                  phone={changeCustomerState.selectedCustomer.phone}
                />
              ) : (
                <Select
                  showSearch
                  className={`flex justify-center align-center w-full h-10 position-relative z-20 text-black font-medium appearance-none border-none focus:outline-none placeholder-gray-200`}
                  onSearch={(query: string) => changePlayerHandleCustomerSearch(query)}
                  onChange={(id: number, customer: ICustomer) => handleChangePlayerSelection(id, customer)}
                  placeholder={t("secure.facility.order.order.037")}
                  allowClear
                  searchValue={changeCustomerState.customerQuery}
                  showDropDownOnFocus={true}
                  searching={changeCustomerState.customerSearching}
                >
                  <div
                    className="ui-select-dropdown-list-item"
                    onClick={
                      () => openNewCustomerSheet()
                      // setChangePlayerState(prevState => ({
                      //   ...prevState,
                      //   showNewCustomer: true,
                      // }))
                    }
                  >
                    <p>{t("secure.facility.order.order.038")}</p>
                  </div>
                  {changeCustomerState.customerSearchResult?.map((customer, index) => {
                    return (
                      <Option key={index} value={customer.id} name={customer.full_name} extraValues={customer}>
                        <div className="flex justify-between">
                          <div>
                            <div className="text-semibold text-lg">{customer?.full_name}</div>
                            <div className="text-sm text-gray-500">{customer.customer_type}</div>
                            <div className="text-sm text-gray-500">{customer.email}</div>
                            <div className="text-sm text-gray-500">{customer.phone ? customer.phone : null}</div>
                          </div>

                          <div className="font-medium text-base text-gray-500 self-end">{customer.member_code}</div>
                        </div>
                      </Option>
                    );
                  })}
                </Select>
              )}
            </>
          )}
        </Sheet>
      </Page>
    )
  );
}
