import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { showError, showSuccess } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";

import { StatusCode } from "api/protocols";
import { PostEmailTemplate } from "api/rpc/2024-04/facilityAdmin/facility/emailTemplate";

import Page from "components/page/Page";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import TextEditor from "components/textEditor/textEditor";
import { Select } from "components/select";

interface INewEmailTemplateState {
  title: string;
  subject: string;
  content: string;
  type: "tee_sheet" | "league_announcement";
}

export default function EmailTemplateNew(props: any) {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [newTemplateState, setNewTemplateState] = useState<INewEmailTemplateState>({
    title: "",
    subject: "",
    content: "",
    type: "tee_sheet",
  });

  const templateTypes = [
    { label: "Tee Sheet", value: "tee_sheet" },
    { label: "League Announcement", value: "league_announcement" },
  ];

  async function postTemplate() {
    const templateRes = await PostEmailTemplate(
      {
        title: newTemplateState.title,
        type: newTemplateState.type,
        subject: newTemplateState.subject,
        content: newTemplateState.content,
      },
      true,
    );

    if (templateRes.status !== StatusCode.OK) {
      dispatch(showError("Error creating email template"));
      return;
    }

    dispatch(showSuccess("Email template created successfully"));
    history.push("/admin/settings/email-template");
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setNewTemplateState(prev => ({ ...prev, [id]: value }));
  }

  const primaryAction = {
    content: "Create",
    action: postTemplate,
  };

  return (
    <Page
      title="New Email Template"
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[{ prefix: true, label: "Back to email templates", url: "/admin/settings/email-template" }]}
    >
      <Card>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Input value={newTemplateState.title} label={"Title"} id="title" onChange={handleInputChange} />
              <Input value={newTemplateState.subject} label={"Subject"} id="subject" onChange={handleInputChange} />
            </FormLayout.Group>
            <FormLayout.Group>
              <Select
                defaultValue={newTemplateState.type}
                label="Type" // TODO: Translation
                onChange={value => setNewTemplateState(prev => ({ ...prev, type: value }))}
              >
                {templateTypes.map(type => (
                  <Select.Option key={type.value} value={type.value}>
                    {type.label}
                  </Select.Option>
                ))}
              </Select>
            </FormLayout.Group>
            <FormLayout.Group>
              <TextEditor
                markdownText={newTemplateState.content ?? ""}
                markdownTextOnChange={(newContent: string) =>
                  setNewTemplateState(prev => ({ ...prev, content: newContent }))
                }
                label={"Content"}
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>
    </Page>
  );
}
