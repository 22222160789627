import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StatusCode } from "api/protocols";
import { GetProduct } from "api/rpc/facilityAdmin/product/products";
import {
  GetReservationLocation,
  GetReservationModule,
  GetReservationSegment,
  PutReservationSegment,
  DeleteReservationSegment,
} from "api/rpc/facilityAdmin/reservation/reservation";
import classNames from "classnames";
import { ButtonNew as Button } from "components/buttonNew";
import Callout from "components/callout/Callout";
import Card from "components/card/Card";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import Checkbox from "components/form/checkbox/Checkbox";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import Page from "components/page/Page";
import Sheet from "components/sheet/Sheet";
import { capitalize, formatDate } from "helpers/Helpers";
import { LocaleCurrency } from "helpers/Locale";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useHistory, useParams } from "react-router-dom";
import { IUIActions, showError } from "redux/actions/ui";
import { IProduct, IVariant } from "redux/reducers/models/product";
import { ILocation, IModule, ISegment } from "redux/reducers/models/reservations";
import { Select } from "components/select/index";
import "./editSegments.scss";
import { isEqualWith } from "lodash";
import { NotificationType } from "components/notificationBar/NotificationBar";
import { Badge } from "components/badge/Badge";
import { useTranslation } from "react-i18next";
import Popup from "components/popup/Popup";
import { ITournament } from "redux/reducers/models/tournament";
import { ILeague } from "redux/reducers/models/league";
import Icon from "components/icon/Icon";
import { useAppDispatch } from "hooks/redux";
import { GetTournament } from "api/rpc/2024-04/facilityAdmin/tournament/tournament";
import { GetLeague } from "api/rpc/2024-04/facilityAdmin/league/league";

interface IProps {
  uiActions: IUIActions;
}
interface IState {
  module: IModule;
  locations: Array<ILocation>;
  selectedDate: Date;
  selectedVariant: IVariant;
  selectedProduct: IProduct;
  productSearchQuery: string;
  productModalVisibility: boolean;
  products: Array<IProduct>;
  segments: { [key: string]: Array<ISegment> };
  leagues: Array<Record<string, any>>;
  tournaments: Array<Record<string, any>>;
  deleteSegmentsPopupVisible: boolean;
}

interface IParamState {
  blocked_type: string;
  league_id: number;
  tournament_id: number;
  //pre_paid_required: boolean;
  credit_card_required: boolean;
  web_booking_enabled: boolean;
  variant: IVariant;
  product: IProduct;
}

interface IFilterState {
  tournamentSearchValue: string;
  leagueSearchValue: string;
  tournaments: Array<ITournament>;
  tournamentSearching: boolean;
  selectedTournament: ITournament;
  leagues: Array<ILeague>;
  leagueSearching: boolean;
  selectedLeague: ILeague;
}

export default function EditSegments(props: IProps) {
  const { uiActions } = props;
  const dispatch = useAppDispatch();

  const [state, setState] = useState<IState>({
    module: null,
    locations: [],
    selectedDate: new Date(),
    segments: null,
    selectedVariant: null,
    selectedProduct: null,
    productModalVisibility: false,
    productSearchQuery: "",
    products: [],
    leagues: [],
    tournaments: [],
    deleteSegmentsPopupVisible: false,
  });

  const [editSegments, setEditSegments] = useState<Array<number>>([]);

  const [segments, setSegments] = useState<Array<ISegment>>(undefined);
  const [segmentsBeforeChanges, setSegmentsBeforeChanges] = useState<Array<ISegment>>(undefined);
  const [tournaments, setTournaments] = useState<Array<ITournament>>([]);
  const [leagues, setLeagues] = useState<Array<ILeague>>([]);

  const [paramState, setParamState] = useState<IParamState>({
    blocked_type: "none",
    league_id: -2,
    tournament_id: -2,
    credit_card_required: null,
    web_booking_enabled: null,
    variant: null,
    product: null,
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    tournamentSearchValue: "",
    leagueSearchValue: "",
    tournaments: [],
    tournamentSearching: false,
    selectedTournament: null,
    leagues: [],
    leagueSearching: false,
    selectedLeague: null,
  });

  const [paramStateBeforeChanges, setParamStateBeforeChanges] = useState<IParamState>(undefined);

  const [moduleLoaded, setModuleLoaded] = useState<boolean>(false);
  const [segmentsLoaded, setSegmentsLoaded] = useState<boolean>(false);

  const { moduleId } = useParams<{ moduleId: string }>();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { Option } = Select;

  const blockedTypes = ["none", "open", "blocked"];

  //The id of the last selected segment. Used to determine starting point of shift-click multi-select
  const [selectedSegmentId, setSelectedSegmentId] = useState<number>(null);

  useEffect(() => {
    void getModule();
  }, []);

  useEffect(() => {
    if (state.selectedDate) {
      void getSegments();
    }
  }, [state.selectedDate]);

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (() => {
          try {
            if (state.productSearchQuery === "") {
              if (mounted) {
                setState(prevState => ({ ...prevState, products: [], selectedVariant: null }));
              }
              return;
            } else {
              if (mounted) {
                void loadProducts();
              }
            }
          } catch (error) {
            console.log("Products error", error);
          }
          return;
        })();
      }, 500);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [state.productSearchQuery]);

  async function loadTournaments() {
    try {
      const tournamentRes = await GetTournament(null, true);

      if (tournamentRes.status === StatusCode.OK) {
        setTournaments(tournamentRes.data);
      } else {
        dispatch(showError(tournamentRes.message));
      }
    } catch (e) {
      dispatch(showError(e));
    }
  }

  async function loadLeagues() {
    try {
      const leagueRes = await GetLeague(null, true);
      if (leagueRes.status === StatusCode.OK) {
        setLeagues(leagueRes.data);
      } else {
        dispatch(showError(leagueRes.message));
      }
    } catch (e) {
      dispatch(showError(e));
    }
  }

  async function loadProducts() {
    const productRes = await GetProduct({ search: state.productSearchQuery, extended: true }, true);
    if (productRes.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.reservation.edit_segments.001"));
      return;
    }
    setState(prevState => ({ ...prevState, products: productRes.data }));
  }

  async function getModule() {
    uiActions.enqueue();
    const moduleRes = await GetReservationModule({ id: Number(moduleId) }, false);
    if (moduleRes?.status !== StatusCode.OK) {
      history.push("/admin/settings/reservations/modules");
      uiActions.showError(t("secure.facility.reservation.edit_segments.002"));
      uiActions.dequeue();
      return;
    }
    const locationRes = await GetReservationLocation({ reservation_module_id: Number(moduleId) }, false);
    if (locationRes.status !== StatusCode.OK) {
      history.push("/admin/settings/reservations/modules");
      uiActions.showError(t("secure.facility.reservation.edit_segments.003"));
      uiActions.dequeue();
      return;
    }

    const leaguesRes = await GetLeague(null, false);
    if (leaguesRes.status !== StatusCode.OK) {
      history.push("/admin/settings/reservations/modules");
      uiActions.showError(t("secure.facility.reservation.edit_segments.004"));
      uiActions.dequeue();
    }

    const tournamentRes = await GetTournament(null, false);
    if (tournamentRes.status !== StatusCode.OK) {
      history.push("/admin/settings/reservations/modules");
      uiActions.showError(t("secure.facility.reservation.edit_segments.005"));
      uiActions.dequeue();
    }
    uiActions.dequeue();

    ReactDOM.unstable_batchedUpdates(() => {
      setState(prevState => ({
        ...prevState,
        module: moduleRes.data,
        locations: locationRes?.data,
        //id of -1 sets the selected value to null. id of -2 means no selection has been made, value won't be overridden
        leagues: [{ name: "", id: -2 }, { name: "None", id: -1 }, ...leaguesRes?.data],
        tournaments: [{ name: "", id: -2 }, { name: "None", id: -1 }, ...tournamentRes?.data],
      }));
      setLeagues(leaguesRes.data);
      setTournaments(tournamentRes.data);
      setModuleLoaded(true);
    });
  }

  async function getSegments() {
    const segmentRes = await GetReservationSegment(
      { module_id: Number(moduleId), date: formatDate(state.selectedDate) },
      true,
    );
    if (segmentRes.status !== StatusCode.OK) {
      history.push("/admin/reservation");
      uiActions.showError(t("secure.facility.reservation.edit_segments.006"));
      return;
    }

    const formattedSegments = segmentRes.data?.reduce(
      (formattedSegments: { [key: string]: Array<ISegment> }, currentSegment: ISegment) => {
        return {
          ...formattedSegments,
          [currentSegment.start_time]: formattedSegments[currentSegment.start_time]
            ? [...formattedSegments[currentSegment.start_time], currentSegment]
            : [currentSegment],
        };
      },
      {},
    );
    ReactDOM.unstable_batchedUpdates(() => {
      setState(prevState => ({
        ...prevState,
        segments: formattedSegments,
        selectedVariant: null,
      }));
      //Sort segments by start_time first then location_id
      setSegments(
        segmentRes?.data?.sort(
          (a: ISegment, b: ISegment) => a?.start_time?.localeCompare(b?.start_time) || a?.location_id - b?.location_id,
        ),
      );
      setEditSegments([]);
      setSegmentsBeforeChanges(segmentRes?.data);
      setSegmentsLoaded(true);
    });
  }

  function handleDateChange(date: Date) {
    setState(prevState => ({ ...prevState, selectedDate: date }));
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleSegmentClick(segment_id: number, e: React.MouseEvent) {
    const shiftKeyPressed = e.shiftKey;

    const updatedSelectedSegmentIds = [...editSegments];
    const segmentIndex = updatedSelectedSegmentIds.indexOf(segment_id);
    if (segmentIndex === -1) {
      updatedSelectedSegmentIds.push(segment_id);

      //Shift key not pressed - set state of last selected segment
      if (!shiftKeyPressed) {
        setSelectedSegmentId(segment_id);
      } else {
        //Shift key pressed - set range of segments
        void setSegmentSelectRange(segment_id);
        return;
      }
    } else {
      updatedSelectedSegmentIds.splice(segmentIndex, 1);
      if (updatedSelectedSegmentIds?.length > 0) {
        setSelectedSegmentId(updatedSelectedSegmentIds[updatedSelectedSegmentIds?.length - 1]);
      } else {
        setSelectedSegmentId(null);
      }
    }
    setEditSegments(updatedSelectedSegmentIds);
  }

  function setSegmentSelectRange(currentSegmentId: number) {
    const segmentsArray = [...segments];
    const currentSegmentIndex = segmentsArray?.findIndex(segment => segment?.id === currentSegmentId);
    const prevSegmentIndex = segmentsArray?.findIndex(segment => segment?.id === selectedSegmentId);

    if (currentSegmentIndex !== -1 && prevSegmentIndex !== -1) {
      //Only select segments in the same location
      const locationId = segmentsArray[prevSegmentIndex]?.location_id;
      let segmentRange: Array<number>;
      if (currentSegmentIndex > prevSegmentIndex) {
        segmentRange = segmentsArray
          ?.filter(filteredSegment => filteredSegment.booking_id === null)
          .slice(prevSegmentIndex, currentSegmentIndex + 1)
          ?.filter(filteredSegment => filteredSegment?.location_id === locationId)
          ?.map(segment => segment?.id);
      } else {
        segmentRange = segmentsArray
          ?.filter(filteredSegment => filteredSegment.booking_id === null)
          .slice(currentSegmentIndex, prevSegmentIndex + 1)
          ?.reverse()
          ?.filter(filteredSegment => filteredSegment?.location_id === locationId)
          ?.map(segment => segment?.id);
      }
      setEditSegments(prevState => [...prevState, ...segmentRange]);
    } else {
      setSelectedSegmentId(null);
      void deselectAllSegments();
    }
  }

  function closeProductModal() {
    setState(prevState => ({
      ...prevState,
      productModalVisibility: false,
      selectedVariant: null,
      selectedProduct: null,
    }));
  }

  function openProductModal() {
    setState(prevState => ({ ...prevState, productModalVisibility: true }));
  }

  function selectAllSegments() {
    const allSegmentIds = segments?.map(segment => segment?.id);
    setEditSegments(allSegmentIds);
  }

  function deselectAllSegments() {
    ReactDOM.unstable_batchedUpdates(() => {
      setEditSegments([]);
      setSelectedSegmentId(null);
    });
  }

  function selectVariant(variant: IVariant, product: IProduct) {
    if (state.selectedVariant && state.selectedVariant?.id === variant?.id) {
      setState(prevState => ({ ...prevState, selectedVariant: null, selectedProduct: null }));
    } else {
      setState(prevState => ({ ...prevState, selectedVariant: variant, selectedProduct: product }));
    }
  }

  function attachVariant() {
    ReactDOM.unstable_batchedUpdates(() => {
      setParamState(prevState => ({
        ...prevState,
        variant: state?.selectedVariant,
        product: state?.selectedProduct,
      }));
      setState(prevState => ({
        ...prevState,
        productModalVisibility: false,
        selectedVariant: null,
        selectedProduct: null,
      }));
    });
  }

  function detachVariant() {
    setParamState(prevState => ({ ...prevState, variant: null, product: null }));
  }

  function handleDropDownChange(value: string, id: string) {
    setParamState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleClearParams() {
    setParamState(paramStateBeforeChanges);
  }

  function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, checked, value } = event.target;

    if (!checked) {
      setParamState(prevState => ({ ...prevState, [id]: null }));
    } else {
      setParamState(prevState => ({ ...prevState, [id]: value === "true" ? checked : !checked }));
    }
  }

  function handleApplyButton() {
    const updatedSegments = [...segments];
    editSegments?.forEach(segmentId => {
      const currentSegmentIndex = segments?.findIndex(segment => segment?.id === segmentId);
      const currentSegment = segments[currentSegmentIndex];

      if (currentSegmentIndex !== -1) {
        const updatedBlocks = [...currentSegment?.blocks];
        updatedBlocks[0] = {
          ...updatedBlocks[0],
          product_title: paramState?.product?.title,
          variant_title: paramState?.variant?.title,
          product_variant_id: paramState?.variant?.id,
          variant_price: paramState?.variant?.price,
        };

        let updatedCurrentSegment: any = {
          ...currentSegment,
          blocked_type: paramState?.blocked_type !== "none" ? paramState?.blocked_type : currentSegment?.blocked_type,
          league_id:
            paramState?.league_id >= 1
              ? paramState?.league_id
              : paramState?.league_id === -1
              ? null
              : currentSegment?.league_id,
          tournament_id:
            paramState?.tournament_id >= 1
              ? paramState?.tournament_id
              : paramState?.tournament_id === -1
              ? null
              : currentSegment?.tournament_id,
          credit_card_required:
            paramState?.credit_card_required != null
              ? paramState?.credit_card_required
              : currentSegment?.credit_card_required,
          web_booking_enabled:
            paramState?.web_booking_enabled != null
              ? paramState?.web_booking_enabled
              : currentSegment?.web_booking_enabled,
          blocks: paramState?.variant ? updatedBlocks : currentSegment?.blocks,
        };

        if (paramState?.variant) {
          updatedCurrentSegment = {
            ...updatedCurrentSegment,
            variant_id: paramState?.variant?.id,
          };
        }

        updatedSegments[currentSegmentIndex] = updatedCurrentSegment;
      }
    });

    const formattedSegments = updatedSegments?.reduce(
      (formattedSegments: { [key: string]: Array<ISegment> }, currentSegment: ISegment) => {
        return {
          ...formattedSegments,
          [currentSegment.start_time]: formattedSegments[currentSegment.start_time]
            ? [...formattedSegments[currentSegment.start_time], currentSegment]
            : [currentSegment],
        };
      },
      {},
    );

    ReactDOM.unstable_batchedUpdates(() => {
      setParamState(paramStateBeforeChanges);
      setEditSegments([]);
      setSegments(prevState => (updatedSegments?.length > 0 ? updatedSegments : prevState));
      setState(prevState => ({ ...prevState, segments: formattedSegments }));
    });
    void removeSelectedTournament();
    void removeSelectedLeague();
  }

  async function deleteSegments() {
    const deleteRes = await DeleteReservationSegment({ segment_ids: editSegments }, true);

    if (deleteRes.status !== StatusCode.OK) {
      uiActions.showError("Error deleting segment");
      return;
    }
    setEditSegments([]);
    setState(prevState => ({ ...prevState, deleteSegmentsPopupVisible: false }));
    void getSegments();
  }

  function unsavedChangesExist() {
    if (segmentsBeforeChanges === undefined || paramStateBeforeChanges === undefined) {
      if (moduleLoaded && segmentsLoaded) {
        setSegmentsBeforeChanges(segments);
        setParamStateBeforeChanges(paramState);
      }
      return false;
    }

    return !isEqualWith(segments, segmentsBeforeChanges);
  }

  function cancelUnsavedChanges() {
    const formattedSegments = segmentsBeforeChanges?.reduce(
      (formattedSegments: { [key: string]: Array<ISegment> }, currentSegment: ISegment) => {
        return {
          ...formattedSegments,
          [currentSegment.start_time]: formattedSegments[currentSegment.start_time]
            ? [...formattedSegments[currentSegment.start_time], currentSegment]
            : [currentSegment],
        };
      },
      {},
    );
    ReactDOM.unstable_batchedUpdates(() => {
      setSegments(segmentsBeforeChanges);
      setState(prevState => ({ ...prevState, segments: formattedSegments }));
    });
  }

  async function saveSegments() {
    const changedSegments = segments?.filter((segment, index) => !isEqualWith(segment, segmentsBeforeChanges[index]));
    if (changedSegments?.length > 0) {
      const segmentRes = await PutReservationSegment({ segments: changedSegments }, true);
      if (segmentRes?.status !== StatusCode.OK) {
        uiActions.showError(t("secure.facility.reservation.edit_segments.007"));
        return;
      }
      uiActions.showSuccess(t("secure.facility.reservation.edit_segments.008"));
      void getSegments();
    }
  }

  function getToSegmentsConfig() {
    history.push(`/admin/settings/reservations/module/${moduleId}`);
  }

  function removeSelectedTournament() {
    setFilterState(prevState => ({
      ...prevState,
      selectedTournament: null,
      tournaments: [],
      tournamentSearching: false,
      tournamentSearchValue: "",
    }));
    setParamState(prevState => ({ ...prevState, tournament_id: -2 }));
  }

  function removeSelectedLeague() {
    setFilterState(prevState => ({
      ...prevState,
      selectedLeague: null,
      leagues: [],
      leagueSearching: false,
      leagueSearchValue: "",
    }));
    setParamState(prevState => ({ ...prevState, league_id: -2 }));
  }

  function displayLeagues() {
    if (filterState?.leagues && filterState?.leagues?.length > 0 && filterState?.leagueSearchValue !== "") {
      return filterState?.leagues;
    } else {
      return leagues;
    }
  }

  function handleTournamentChange(value: any, id: string, tournament: ITournament) {
    setParamState(prevState => ({ ...prevState, [id]: tournament.id }));
    setFilterState(prevState => ({ ...prevState, tournamentSearchValue: "", selectedTournament: tournament }));
  }

  function handleLeagueChange(value: any, id: string, league: ILeague) {
    setParamState(prevState => ({ ...prevState, [id]: league.id }));
    setFilterState(prevState => ({ ...prevState, leagueSearchValue: "", selectedLeague: league }));
  }

  function displayTournaments() {
    if (filterState?.tournaments && filterState?.tournaments?.length > 0 && filterState?.tournamentSearchValue !== "") {
      return filterState?.tournaments;
    } else {
      return tournaments;
    }
  }

  async function loadSearchedTournaments(search: string) {
    const params = {
      search: search,
    };

    const tournamentRes = await GetTournament(params, false);

    if (tournamentRes.status !== StatusCode.OK) {
      dispatch(showError(tournamentRes.message));
      return [];
    }

    return tournamentRes.data;
  }

  async function loadSearchedLeagues(search: string) {
    const params = {
      search: search,
    };

    const leagueRes = await GetLeague(params, false);

    if (leagueRes.status !== StatusCode.OK) {
      dispatch(showError(leagueRes.message));

      return [];
    }

    return leagueRes.data;
  }

  // Search Tournaments
  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (async () => {
          if (filterState.tournamentSearchValue !== "") {
            try {
              setFilterState(prevState => ({ ...prevState, tournamentSearching: true }));
              const tournaments = await loadSearchedTournaments(filterState.tournamentSearchValue);
              if (mounted) {
                setFilterState(prevState => ({ ...prevState, tournaments: tournaments, tournamentSearching: false }));
              }
            } catch (error) {
              console.log("err", error);
            }
            return;
          } else {
            setFilterState(prevState => ({ ...prevState, tournaments: [], tournamentSearching: false }));
          }
        })();
      }, 1000);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [filterState.tournamentSearchValue]);

  //Load Leagues
  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (async () => {
          if (filterState.leagueSearchValue !== "") {
            try {
              setFilterState(prevState => ({ ...prevState, leagueSearching: true }));
              const leagues = await loadSearchedLeagues(filterState.leagueSearchValue);
              if (mounted) {
                setFilterState(prevState => ({ ...prevState, leagues: leagues, leagueSearching: false }));
              }
            } catch (error) {
              console.log("err", error);
            }
            return;
          } else {
            setFilterState(prevState => ({ ...prevState, leagues: [], leagueSearching: false }));
          }
        })();
      }, 1000);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [filterState.leagueSearchValue]);

  return (
    <>
      <Page
        title={state?.module?.title ?? ""}
        breadcrumbs={[
          { prefix: true, label: t("secure.facility.reservation.edit_segments.009"), url: "/admin/reservation" },
        ]}
        notificationBarProps={{
          isVisible: unsavedChangesExist(),
          onAction: saveSegments,
          onCancel: cancelUnsavedChanges,
        }}
      >
        <Card>
          <Card.Section title={t("secure.facility.reservation.edit_segments.011")}>
            <div className="edit-segments-button-group">
              <div className="edit-segments-date-container">
                <label className="edit-segment-label">{t("secure.facility.reservation.edit_segments.012")}</label>
                <DatePickerInput
                  months={1}
                  position="left"
                  startingDate={state.selectedDate}
                  setStartingDate={handleDateChange}
                />
              </div>

              <Button size="medium" type="default" onClick={deselectAllSegments}>
                {t("secure.facility.reservation.edit_segments.013")}
              </Button>
              <Button size="medium" type="default" onClick={selectAllSegments}>
                {t("secure.facility.reservation.edit_segments.014")}
              </Button>
              <Button size="medium" type="default" onClick={handleClearParams}>
                {t("secure.facility.reservation.edit_segments.015")}
              </Button>
              <Button
                size="medium"
                type="default"
                onClick={() => setState(prevState => ({ ...prevState, deleteSegmentsPopupVisible: true }))}
              >
                {"Delete Selected"}
              </Button>
              <Button size="medium" type="primary" onClick={handleApplyButton}>
                {t("secure.facility.reservation.edit_segments.016")}
              </Button>
            </div>

            <Form>
              <FormLayout>
                <FormLayout.Group>
                  <div>
                    <label className="edit-segment-label">{t("secure.facility.reservation.edit_segments.017")}</label>
                    <Select
                      onChange={(value: string) => handleDropDownChange(value, "blocked_type")}
                      className="edit-segments-blocked-type-dropdown"
                      defaultValue={paramState?.blocked_type}
                    >
                      {blockedTypes?.map((blocked_type, index) => {
                        return (
                          <Option key={index} value={blocked_type}>
                            {blocked_type !== "none" ? capitalize(blocked_type) : ""}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>

                  {filterState.selectedLeague ? (
                    <div style={{ marginTop: "5px" }}>
                      <div>
                        <p className="event-label">
                          {" "}
                          {t("secure.facility.reservation.edit_segments.018")}{" "}
                          <FontAwesomeIcon size="1x" icon={["far", "users"]} />
                        </p>
                      </div>

                      <div className="selected-container">
                        <div className="event-name">
                          <div>{filterState.selectedLeague.name}</div>

                          <div>
                            <button className="" onClick={removeSelectedLeague}>
                              <Icon style="far" icon="times" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <label className="edit-segment-label">
                        {t("secure.facility.reservation.edit_segments.018")}{" "}
                        <FontAwesomeIcon size="1x" icon={["far", "users"]} />
                      </label>
                      <Select
                        showSearch
                        onSearch={(query: string) =>
                          setFilterState(prevState => ({ ...prevState, leagueSearchValue: query }))
                        }
                        onChange={(value: any, league: ILeague) => handleLeagueChange(value, "league_id", league)}
                        placeholder={"Search leagues..."}
                        allowClear
                        searchValue={filterState.leagueSearchValue}
                        showDropDownOnFocus={true}
                        searching={filterState.leagueSearching}
                      >
                        <Option value={-1} extraValues={{ name: "None", id: -1 }}>
                          <div className="text-semibold text-lg">{"None"}</div>
                        </Option>
                        {displayLeagues()?.map((league: any, index: number) => {
                          return (
                            <Option value={league?.id} key={index} extraValues={league}>
                              <div className="text-semibold text-lg">{league.name}</div>
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  )}

                  {filterState.selectedTournament ? (
                    <div style={{ marginTop: "5px" }}>
                      <div>
                        <p className="event-label">
                          {" "}
                          {t("secure.facility.reservation.edit_segments.019")}{" "}
                          <FontAwesomeIcon size="1x" icon={["far", "trophy"]} />
                        </p>
                      </div>

                      <div className="selected-container">
                        <div className="event-name">
                          <div>{filterState.selectedTournament.name}</div>

                          <div>
                            <button className="" onClick={removeSelectedTournament}>
                              <Icon style="far" icon="times" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <label className="edit-segment-label">
                        {t("secure.facility.reservation.edit_segments.019")}{" "}
                        <FontAwesomeIcon size="1x" icon={["far", "trophy"]} />
                      </label>
                      <Select
                        showSearch
                        onSearch={(query: string) =>
                          setFilterState(prevState => ({ ...prevState, tournamentSearchValue: query }))
                        }
                        onChange={(value: any, tournament: ITournament) =>
                          handleTournamentChange(value, "tournament_id", tournament)
                        }
                        placeholder={"Search tournaments..."}
                        allowClear
                        searchValue={filterState.tournamentSearchValue}
                        showDropDownOnFocus={true}
                        searching={filterState.tournamentSearching}
                        className="edit-segments-blocked-type-dropdown"
                      >
                        <Option value={-1} extraValues={{ name: "None", id: -1 }}>
                          <div className="text-semibold text-lg">{"None"}</div>
                        </Option>

                        {displayTournaments()
                          ?.filter(tournament => !moment(tournament.date).isBefore(moment(), "day"))
                          .map((tournament: any, index: number) => {
                            return (
                              <Option key={index} value={tournament.id} name={tournament.name} extraValues={tournament}>
                                <div className="flex items-center">
                                  <span>{tournament.name}</span>
                                  <span className="shotgun-tournament-date">
                                    {moment(tournament.date).format("LL")}
                                  </span>
                                </div>
                              </Option>
                            );
                          })}

                        <Option disabled value={-2} key={-2} name={""}>
                          <div>
                            <hr style={{ margin: "0px" }} />
                          </div>
                        </Option>

                        {displayTournaments()
                          ?.filter(tournament => moment(tournament.date).isBefore(moment(), "day"))
                          .map((tournament: any, index: number) => {
                            return (
                              <Option key={index} value={tournament.id} name={tournament.name} extraValues={tournament}>
                                <div className="flex items-center">
                                  <span>{tournament.name}</span>
                                  <span className="shotgun-tournament-date">
                                    {moment(tournament.date).format("LL")}
                                  </span>
                                </div>
                              </Option>
                            );
                          })}
                      </Select>
                    </div>
                  )}
                </FormLayout.Group>
              </FormLayout>
            </Form>

            <div className="edit-segments-button-group">
              <Button size="medium" type="default" onClick={openProductModal}>
                {t("secure.facility.reservation.edit_segments.020")}
              </Button>
              {paramState?.product && paramState?.variant && (
                <div className="edit-segments-variant-badge-container">
                  <Badge type="primary">
                    <span>
                      {paramState?.product?.preferred_title
                        ? paramState?.product?.preferred_title
                        : paramState?.product?.title}{" "}
                      {paramState?.variant?.title !== paramState?.product?.title
                        ? ` - ${paramState?.variant?.title}`
                        : ""}{" "}
                      <LocaleCurrency amount={paramState?.variant?.price} />
                    </span>
                  </Badge>
                  <FontAwesomeIcon
                    size="1x"
                    icon={["far", "xmark"]}
                    onClick={detachVariant}
                    className="edit-segments-remove-variant"
                  />
                </div>
              )}
            </div>

            <div className="edit-segments-button-group">
              <div>
                <label className="edit-segment-label">
                  {t("secure.facility.reservation.edit_segments.021")}{" "}
                  <FontAwesomeIcon size="1x" icon={["far", "credit-card"]} />
                </label>
                <Checkbox
                  id="credit_card_required"
                  size="medium"
                  value="true"
                  checked={paramState.credit_card_required !== null && paramState.credit_card_required}
                  onChange={handleCheckboxChange}
                  label={t("secure.facility.reservation.edit_segments.022")}
                  circle
                />
                <Checkbox
                  id="credit_card_required"
                  size="medium"
                  value="false"
                  checked={paramState.credit_card_required !== null && !paramState.credit_card_required}
                  onChange={handleCheckboxChange}
                  label={t("secure.facility.reservation.edit_segments.023")}
                  circle
                />
              </div>
              <div>
                <label className="edit-segment-label">
                  {t("secure.facility.reservation.edit_segments.024")}{" "}
                  <FontAwesomeIcon size="1x" icon={["far", "earth-americas"]} />
                </label>
                <Checkbox
                  id="web_booking_enabled"
                  size="medium"
                  value="true"
                  checked={paramState.web_booking_enabled !== null && paramState.web_booking_enabled}
                  onChange={handleCheckboxChange}
                  label={t("secure.facility.reservation.edit_segments.025")}
                  circle
                />
                <Checkbox
                  id="web_booking_enabled"
                  size="medium"
                  value="false"
                  checked={paramState.web_booking_enabled !== null && !paramState.web_booking_enabled}
                  onChange={handleCheckboxChange}
                  label={t("secure.facility.reservation.edit_segments.026")}
                  circle
                />
              </div>
            </div>

            {state.segments && Object.keys(state.segments)?.length > 0 ? (
              <div className="edit-segments">
                <div className="segments-container">
                  <table className="ui-table ui-table-condensed ui-table-separated-horizontal segments-table">
                    <thead>
                      <tr className="segments-table-header">
                        <td className="segments-table-times-header">
                          {t("secure.facility.reservation.edit_segments.027")}
                        </td>
                        {state?.locations?.map((location, locationIndex) => {
                          return <td key={locationIndex}>{location.title}</td>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(state?.segments)?.map((segmentKey, segmentKeyIndex) => {
                        return (
                          <tr key={segmentKeyIndex}>
                            <td className="time-text">{moment(segmentKey, "hh:mm:ss").format("h:mm A")}</td>
                            {state?.locations?.map((location, locationIndex) => {
                              const segment = state?.segments[segmentKey]?.find(
                                searchSegment => searchSegment?.location_id === location?.id,
                              );
                              if (
                                (segment?.blocked_type === "open" || segment?.blocked_type === "open_reserved") &&
                                segment?.booking_id === null
                              ) {
                                return (
                                  <td
                                    key={locationIndex}
                                    className={classNames("segment-available", {
                                      "segment-selected": editSegments.includes(segment?.id),
                                      "segment-league": segment?.league_id !== null,
                                      "segment-tournament": segment?.tournament_id !== null,
                                    })}
                                    onClick={(e: React.MouseEvent) => handleSegmentClick(segment?.id, e)}
                                  >
                                    <div className="segment-container">
                                      <p className="blocked-type-text">{capitalize(segment?.blocked_type)}</p>
                                      {segment?.blocks[0]?.product_variant_id && (
                                        <div className="variant-group">
                                          <p className="variant-title">{segment?.blocks[0]?.product_title}</p>
                                          {segment?.blocks[0]?.product_title !== segment?.blocks[0]?.variant_title && (
                                            <p className="variant-title">{segment?.blocks[0]?.variant_title}</p>
                                          )}
                                          <LocaleCurrency
                                            style={{ fontSize: "0.7rem" }}
                                            amount={segment?.blocks[0]?.variant_price}
                                          />
                                        </div>
                                      )}
                                      <div className="segment-options-container">
                                        {segment?.league_id && <FontAwesomeIcon size="1x" icon={["far", "users"]} />}
                                        {segment?.tournament_id && (
                                          <FontAwesomeIcon size="1x" icon={["far", "trophy"]} />
                                        )}
                                        {segment?.credit_card_required && (
                                          <FontAwesomeIcon size="1x" icon={["far", "credit-card"]} />
                                        )}
                                        {segment?.web_booking_enabled && (
                                          <FontAwesomeIcon size="1x" icon={["far", "earth-americas"]} />
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                );
                              } else if (segment?.blocked_type === "blocked") {
                                return (
                                  <td
                                    key={locationIndex}
                                    className={classNames("segment-disabled", {
                                      "segment-selected": editSegments.includes(segment?.id),
                                      "segment-league": segment?.league_id !== null,
                                      "segment-tournament": segment?.tournament_id !== null,
                                    })}
                                    onClick={(e: React.MouseEvent) => handleSegmentClick(segment?.id, e)}
                                  >
                                    <div className="segment-container">
                                      <p className="blocked-type-text">{capitalize(segment?.blocked_type)}</p>
                                      {segment?.blocks[0]?.product_variant_id && (
                                        <div className="variant-group">
                                          <p className="variant-title">{segment?.blocks[0]?.product_title}</p>
                                          {segment?.blocks[0]?.product_title !== segment?.blocks[0]?.variant_title && (
                                            <p className="variant-title">{segment?.blocks[0]?.variant_title}</p>
                                          )}
                                          <LocaleCurrency
                                            style={{ fontSize: "0.7rem" }}
                                            amount={segment?.blocks[0]?.variant_price}
                                          />
                                        </div>
                                      )}
                                      <div className="segment-options-container">
                                        {segment?.league_id && <FontAwesomeIcon size="1x" icon={["far", "users"]} />}
                                        {segment?.tournament_id && (
                                          <FontAwesomeIcon size="1x" icon={["far", "trophy"]} />
                                        )}
                                        {segment?.credit_card_required && (
                                          <FontAwesomeIcon size="1x" icon={["far", "credit-card"]} />
                                        )}
                                        {segment?.web_booking_enabled && (
                                          <FontAwesomeIcon size="1x" icon={["far", "earth-americas"]} />
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                );
                              } else if (segment?.blocked_type === "booked" && segment?.booking_id) {
                                return (
                                  <td
                                    key={locationIndex}
                                    className={classNames("segment-disabled", {
                                      "segment-selected": editSegments.includes(segment?.id),
                                      "segment-league": segment?.league_id !== null,
                                      "segment-tournament": segment?.tournament_id !== null,
                                    })}
                                  >
                                    <div className="segment-container">
                                      <p className="blocked-type-text">{capitalize(segment?.blocked_type)}</p>
                                      {segment?.blocks[0]?.product_variant_id && (
                                        <div className="variant-group">
                                          <p className="variant-title">{segment?.blocks[0]?.product_title}</p>
                                          {segment?.blocks[0]?.product_title !== segment?.blocks[0]?.variant_title && (
                                            <p className="variant-title">{segment?.blocks[0]?.variant_title}</p>
                                          )}
                                          <LocaleCurrency
                                            style={{ fontSize: "0.7rem" }}
                                            amount={segment?.blocks[0]?.variant_price}
                                          />
                                        </div>
                                      )}
                                      <div className="segment-options-container">
                                        {segment?.league_id && <FontAwesomeIcon size="1x" icon={["far", "users"]} />}
                                        {segment?.tournament_id && (
                                          <FontAwesomeIcon size="1x" icon={["far", "trophy"]} />
                                        )}
                                        {segment?.credit_card_required && (
                                          <FontAwesomeIcon size="1x" icon={["far", "credit-card"]} />
                                        )}
                                        {segment?.web_booking_enabled && (
                                          <FontAwesomeIcon size="1x" icon={["far", "earth-americas"]} />
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                );
                              } else {
                                return (
                                  <td
                                    key={locationIndex}
                                    className={classNames("segment-unavailable", {
                                      "segment-selected": editSegments.includes(segment?.id),
                                    })}
                                  ></td>
                                );
                              }
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <Callout
                actions={{ primary: { label: "Go to Segments Configuration", onClick: getToSegmentsConfig } }}
                type="error"
                title={t("secure.facility.reservation.edit_segments.028")}
                content={t("secure.facility.reservation.edit_segments.029")}
              />
            )}
          </Card.Section>
        </Card>
      </Page>
      <Sheet
        closable
        onCancel={closeProductModal}
        size="medium"
        open={state.productModalVisibility}
        cancelText={t("secure.facility.reservation.edit_segments.030")}
        okText={t("secure.facility.reservation.edit_segments.031")}
        title={t("secure.facility.reservation.edit_segments.032")}
        onOk={attachVariant}
        okDisabled={!state.selectedVariant ? true : false}
      >
        <Input
          value={state.productSearchQuery}
          onChange={handleInputChange}
          type="search"
          id={"productSearchQuery"}
          placeholder={t("secure.facility.reservation.edit_segments.033")}
        />

        {state.products?.length > 0 ? (
          <>
            <div className="edit-segments-product-header">
              <div className="edit-segments-product-title">{t("secure.facility.reservation.edit_segments.034")}</div>
              <div className="edit-segments-price-title">{t("secure.facility.reservation.edit_segments.035")}</div>
            </div>
            <div className="edit-segments-products-container">
              {state.products?.map((product, productIndex) => {
                return (
                  <div
                    key={productIndex}
                    onClick={
                      product?.variant_count === 1 ? () => selectVariant(product?.variants[0], product) : undefined
                    }
                    className={classNames("edit-segments-product-list-item", {
                      "edit-segments-product-list-item-hover": product?.variant_count === 1,
                      "edit-segments-product-list-item-selected":
                        product?.variant_count === 1 && product?.variants[0]?.id === state.selectedVariant?.id
                          ? true
                          : false,
                    })}
                  >
                    <div className="font-normal">
                      {product?.preferred_title ? product?.preferred_title : product?.title}
                    </div>
                    {product?.variant_count === 1 && <LocaleCurrency amount={product?.variants[0]?.price} />}
                    {product?.variant_count > 1 && (
                      <>
                        <div className="edit-segments-dashed-border"></div>
                        {product?.variants?.map((variant, variantIndex) => {
                          return (
                            <div
                              key={variantIndex}
                              onClick={() => selectVariant(variant, product)}
                              className={classNames(
                                "edit-segments-variant-list-item edit-segments-product-list-item-hover",
                                {
                                  "edit-segments-product-list-item-selected": state.selectedVariant?.id === variant?.id,
                                },
                              )}
                            >
                              <div className="font-normal">{variant?.title}</div>
                              <LocaleCurrency amount={variant?.price} />
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <div className="pt-2">
            <Callout
              type="info"
              title={t("secure.facility.reservation.edit_segments.036")}
              content={t("secure.facility.reservation.edit_segments.037")}
            />
          </div>
        )}
      </Sheet>

      <Popup
        open={state.deleteSegmentsPopupVisible}
        type={"warning"}
        title={t("secure.facility.reservation.edit_segments.038")}
        description={t("secure.facility.reservation.edit_segments.039")}
        closable
        cancelText={t("secure.facility.reservation.edit_segments.040")}
        okText={t("secure.facility.reservation.edit_segments.041")}
        onCancel={() => setState(prevState => ({ ...prevState, deleteSegmentsPopupVisible: false }))}
        onOk={deleteSegments}
      />
    </>
  );
}
