import React, { ChangeEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { UserLogin, UserActive, FetchCSRFCookie } from "api/rpc";

import { initialize, userLogin } from "redux/actions/auth";
import { showError } from "redux/actions/ui";
import { UserType } from "redux/reducers/models/user";
import { useAppDispatch } from "hooks/redux";
import useKeyPress from "../../hooks/events/useKeyPress";

import Input from "components/input/Input";
import { ButtonNew as Button } from "components/buttonNew/index";

interface ILoginState {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const enterPressed = useKeyPress(["Enter"]);

  const [loginState, setLoginState] = useState<ILoginState>({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (enterPressed) {
      void handleLogin();
    }
  }, [enterPressed]);

  async function handleLogin(e?: React.MouseEvent<HTMLElement, MouseEvent>) {
    if (e) {
      e.preventDefault();
    }
    const fetchXSRFTokenRes = await FetchCSRFCookie(true);

    if (fetchXSRFTokenRes.status !== 204) {
      return;
    }

    if (loginState.email == "" || loginState.password == "") {
      // covers all falsey cases? (null, undefined, ...)
      dispatch(showError("Username and/or password cannot be blank.")); // TODO: Translation
      return;
    }

    const loginRes = await UserLogin(
      {
        email: loginState.email,
        password: loginState.password,
        admin: true,
      },
      true,
    );

    if (loginRes.status === 401) {
      dispatch(showError("Username or password is incorrect")); // TODO: Translation
    }

    if (loginRes.status !== StatusCode.OK) {
      dispatch(showError("Invalid username or password")); // TODO: Translation
      return;
    }

    if (loginRes.data.user.user_type === UserType.STANDARD_CUSTOMER) {
      dispatch(showError(t("guest.login.001")));
      return;
    } else {
      const activeUserRes = await UserActive(true);
      if (activeUserRes.status === StatusCode.OK) {
        dispatch(userLogin(activeUserRes.data));
        dispatch(initialize(true));
        history.push("/admin");
      } else {
        dispatch(showError("Error logging in as user")); // TODO: Translation
      }
    }
  }

  return (
    <div>
      <div className="login-main-content login-left">
        <img className="login-logo" src="../../../public/images/Tee-On.png" alt="Tee-On Logo" />
        <div className="login-container">
          <div className="login-content">
            <div className="login-content--header">
              <p>
                <span>{t("guest.login.002")}</span>
              </p>
              <p className="text-subdued">{t("guest.login.003")}</p>
            </div>
            <div className="login-content--form">
              <label>{t("guest.login.004")}</label>
              <Input
                className="w-full mb-4"
                placeholder="Email" // TODO: Translation
                name="email"
                value={loginState.email}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setLoginState(prev => ({ ...prev, email: e.target.value }))
                }
              />

              <label>{t("guest.login.005")}</label>
              <Input
                className="w-full mb-4"
                placeholder="Password" // TODO: Translation
                name="password"
                type="password"
                value={loginState.password}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setLoginState(prev => ({ ...prev, password: e.target.value }))
                }
              />

              <Button type="primary" onClick={e => handleLogin(e)} block>
                {t("guest.login.006")}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="login-main-content login-right"></div>
    </div>
  );
};

export default Login;
