import React, { useEffect, useState } from "react";
import axios, { CancelToken } from "axios";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { StatusCode } from "api/protocols";
import { GetBookingParticipant } from "api/rpc/2024-04/clientAdmin/teesheet/booking";

import { showError } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";
import { convertTime, valueToString } from "helpers/Helpers";

import { useCustomerClientContext } from "../../context/CustomerClientContext";
import SelectNew from "components/select/SelectNew";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import { Badge } from "components/badge/Badge";

import "./customerReservations.scss";

const TABLE_LIMIT = 10;

export default function CustomerReservations() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { state, updateState } = useCustomerClientContext();

  const [filters, setFilters] = useState({
    year: "2024",
    offset: 0,
  });

  // Table OFFSET for booking history
  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadBooking(state.customer.id, source.token);
    return () => {
      source.cancel("Customer Reservations unmounted.");
    };
  }, [state.customer.id, filters.year, filters.offset]);

  async function loadBooking(customerId: number, token?: CancelToken) {
    if (state.bookings !== null) {
      updateState({ bookings: null });
    }
    const bookingRes = await GetBookingParticipant(
      {
        customer_id: customerId,
        extended_booking: true,
        limit: TABLE_LIMIT,
        ...filters,
      },
      token ? false : true,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (bookingRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.customer.tabs.reservation.customer_reservations.016")));
      updateState({ bookings: [] });
      return;
    }

    updateState({ bookings: bookingRes.data });
  }

  return (
    <>
      <div>
        {state.bookingCounts.length !== 0 ? (
          <div className={"booking-stats-general"}>
            <div className="customer-reservation_overview_container">
              <div className={`customer-stats-group-desktop`}>
                <div className="customer-reservation_stats_container">
                  <span className="customer-stat">
                    <p className="stat-title">Bookings</p> {/* TODO: Translation */}
                    <p className="stat-number">
                      {state.bookingCounts?.find((count: any) => count.year === filters.year)?.count}
                    </p>
                  </span>
                  <span className="customer-stat">
                    <p className="stat-title">No Shows</p> {/* TODO: Translation */}
                    <p className="stat-number stat-number_warning">
                      {state.bookingCounts?.find((count: any) => count.year === filters.year)?.no_shows}
                    </p>
                  </span>
                  <span className="customer-stat">
                    <p className="stat-title">Weather</p> {/* TODO: Translation */}
                    <p className="stat-number stat-number_warning">
                      {state.bookingCounts?.find((count: any) => count.year === filters.year)?.weather_no_shows}
                    </p>
                  </span>
                </div>
              </div>

              <div>
                <SelectNew
                  id="filter_years"
                  value={filters.year}
                  onChange={year => setFilters(prev => ({ ...prev, year: year, offset: 0 }))}
                  options={state.bookingCounts?.map(val => ({ id: val.year, label: val.year }))}
                  isLoading={state.bookingCounts === null}
                  // disabled={state.accountStatements === null || state.accountTransactions === null}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <h1 className="customer-reservation-header">Reservation History</h1> {/* TODO: Translation */}
      <DataTable
        columns={[
          { label: "Confirmation No." }, // TODO: Translation
          { label: "Tee Time" }, // TODO: Translation
          { label: "Booked On" }, // TODO: Translation
          { label: "Course" }, // TODO: Translation
          { label: "Golfers" }, // TODO: Translation
          { label: "Status" }, // TODO: Translation
        ]}
        loading={state.bookings === null}
        footer={{
          tableLimit: TABLE_LIMIT,
          tableOffset: filters.offset,
          disableNextOffset: !state.bookings || !(state.bookings?.length === TABLE_LIMIT),
          handleTableOffset: direction =>
            setFilters(prev => ({
              ...prev,
              offset: direction === "prev" ? prev.offset - TABLE_LIMIT : prev.offset + TABLE_LIMIT,
            })),
        }}
      >
        {state.bookings
          ?.filter(val => !!val.turn === false)
          .map(booking => (
            <tr key={booking.id}>
              <td>{booking.booking.confirmation_number}</td>

              {booking.tee_time ? (
                <td>
                  <span>{moment(booking.tee_time.date).format("MMMM DD YYYY")}</span>
                  <span> at {convertTime(booking.tee_time.start_time)}</span>
                </td>
              ) : (
                <td>N/A</td>
              )}

              {booking.created_at ? (
                <td>
                  <span>{moment(booking.created_at).format("MMMM DD YYYY, h:mm A")}</span>
                </td>
              ) : (
                <td>N/A</td>
              )}

              {booking.tee_time ? <td>{booking.tee_time.course.long_name}</td> : <td>N/A</td>}

              <td>{booking.booking.quantity}</td>

              <td>{generateStatusBadge(booking.status === "complete" ? booking.check_in_status : booking.status)}</td>
            </tr>
          ))}
      </DataTable>
    </>
  );
}

function generateStatusBadge(status: string) {
  switch (status) {
    case "checked":
      return <Badge type="success">{valueToString(status, "capitalize")}</Badge>;
    case "unchecked":
      return <Badge type="warning">{valueToString(status, "capitalize")}</Badge>;
    case "cancelled":
      return <Badge type="error">{valueToString(status, "capitalize")}</Badge>;
    case "no_show":
      return <Badge type="error">{valueToString(status, "capitalize")}</Badge>;
    default:
      return <Badge type="gray">{valueToString(status, "capitalize")}</Badge>;
  }
}
