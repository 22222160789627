import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { ITournament, TTournamentScoresheet } from "redux/reducers/models/tournament";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseTournamentUrl = "/" + apiVersion + "/" + adminType + "/tournament/scoring/scoresheet";

export type TGetTournamentScoresheet = {
  id?: string | number;
  tournament_id?: string | number;
};

export type TPostTournamentScoresheet = {
  tournament_id: string | number;
  date_played: string;
  name: string;
  holes?: 9 | 18; // default = tournament scoring_settings holes
  tee_deck_id?: string | number; // default = tournament scoring_settings tee_deck_id
  division_front_id: string | number;
  division_back_id: string | number;
  playoffs?: boolean; // default = 0
};

export type TPutTournamentScoresheet = {
  id: string | number;
  name?: string;
  date_played?: string;
  holes?: 9 | 18;
  tee_deck_id?: string | number;
  division_front_id?: string | number;
  division_back_id?: string | number;
  playoffs?: boolean;
};

// GET Tournament
// Retrieve a list of tournaments
export async function GetTournamentScoresheet(
  params: TGetTournamentScoresheet,
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet<TTournamentScoresheet[]>(baseTournamentUrl, params, useGlobalLoader, token);
}

// POST Tournament
// Create a new tournament
export async function PostTournamentScoresheet(params: TPostTournamentScoresheet, useGlobalLoader: boolean) {
  return await APIPost(baseTournamentUrl, params, useGlobalLoader);
}

// PUT Tournament
// Update an existing tournament
export async function PutTournamentScoresheet(params: TPutTournamentScoresheet, useGlobalLoader: boolean) {
  return await APIPut<ITournament>(baseTournamentUrl, params, useGlobalLoader);
}

// PUT Tournament
// Update an existing tournament
export async function DeleteTournamentScoresheet(params: { id: string | number }, useGlobalLoader: boolean) {
  return await APIDelete(baseTournamentUrl, params, useGlobalLoader);
}
