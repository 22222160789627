import { APIGet, APIPost, APIPut, APIDelete, StatusCode, IAPIResponse } from "api/protocols";
import { formatDate } from "helpers/Helpers";
import { IBooking, ITeeTime } from "redux/reducers/models/teetime";
import { CancelToken } from "axios";
import { IBookingEngine, IBookingEngineGuest } from "redux/reducers/bookingEngine";
import { CustomerTypeResStructure } from "../facilityAdmin/customer/type";
import { IVariant } from "pages/guest/models/product";

const apiVersion = "2022-09";
const newApiVersion = "2024-04";
const baseGuestUrl = "/" + apiVersion + "/guest";
const newBaseGuestUrl = "/" + newApiVersion + "/guest";
const baseCustomerUrl = "/" + apiVersion + "/customer";
const newBaseCustomerUrl = "/" + newApiVersion + "/customer";

interface IGetBookingEngine {
  handle?: string;
  facility_id?: number;
}

interface IGetBookingEngineRes {
  data: Array<IBookingEngine>;
  message: string;
  status: StatusCode;
}

interface IGetGuestTeeTimes {
  date: Date;
  booking_engine_handle: string;
  offset?: number;
  limit?: number;
}

interface IGetGuestTeeTimesResponse extends IAPIResponse {
  data: ITeeTime[];
}

interface IGetCustomerTeeTimes {
  date: Date;
  booking_engine_handle: string;
  offset?: number;
  limit?: number;
}

interface IGetCustomerTeeTimesResponse extends IAPIResponse {
  data: ITeeTime[];
}

interface IGetNotifications {
  date: string;
  facility_id: number;
}

export interface INotification {
  id: number;
  tee_sheet_id: string;
  date?: string;
  subject: string;
  content: string;
  type: string;
  user_id: number;
}

interface IGetNotificationsResponse extends IAPIResponse {
  data: INotification[];
}

interface IGetTeeTimeParams {
  id?: number;
  booking_engine_handle?: string;
  date?: string;
  turn_tee_time?: boolean;
  extended?: boolean;
}

interface IPostTeeTimeLock {
  tee_time_id: number;
}

interface IPostBookingParams {
  tee_time_id: number;
  holes: number;
  quantity: number;
  power_cart_quantity: number;
  customer_payment_method_id: number;
  accept_payment_terms: boolean;
  cart_token: string;
  order_id?: number;
  booking_fee_order_id: number;
  lock_quantity?: boolean;
  lock_holes?: boolean;
  lock_power_cart_quantity?: boolean;
  customer_ids: Array<number>;
}

interface IPostCartParams {
  cart_token?: string;
  holes: number;
  quantity: number;
  power_cart_quantity: number;
  tee_time_id: number;
  customer_ids: Array<number>;
}

interface IPutCancelBookingParams {
  token: string;
}

interface IGetBooking {
  token?: string;
  extended?: boolean;
  turn_tee_time?: true;
  limit?: number;
  offset?: number;
}

interface IGetBookingResponse extends IAPIResponse {
  data: IBooking[];
}

interface IGetCart {
  id?: number;
  facility_id?: number;
  token?: string;
}

interface ICheckInsParams {
  facility_id: number;
  day_of_week_number: number;
}

export interface ICheckIns {
  created_at: string;
  customer_type: CustomerTypeResStructure;
  customer_type_id: number;
  facility_id: number;
  holes: number;
  id: number;
  pricing_sheet: {
    application: string;
    course_id: number;
    created_at: string;
    day_of_week: string;
    day_of_week_number: number;
    end_time: string;
    start_time: string;
    facility_id: number;
    id: number;
    title: string;
    updated_at: string;
  };
  variant_id: string;
  variant: IVariant;
}

export interface IGustCheckInsRes extends IAPIResponse {
  data: ICheckIns[];
}

interface IPostGuestAlpha {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  start_dates: string[];
  start_time: string;
  end_time: string;
  quantity: number;
  holes: number;
  facility_id: number;
}

export interface IPostGuestAlphaRes {
  status: number;
  data: unknown;
}

interface IPostCustomerAlpha {
  start_dates: string[];
  start_time: string;
  end_time: string;
  quantity: number;
  holes: number;
  facility_id: number;
}

export interface IPostCustomerAlphaRes {
  status: number;
  data: unknown;
}

//GET Booking engine information
export async function GetBookingEngine(params: IGetBookingEngine, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<IBookingEngineGuest[]>(
    baseGuestUrl + "/tee-sheet/booking-engine",
    params,
    useGlobalLoader,
    token,
  );
}

//GET Guest Tee Times
export async function GetGuestTeeTimes(
  params: IGetGuestTeeTimes,
  useGlobalLoader: boolean,
): Promise<IGetGuestTeeTimesResponse> {
  const chosenDate = formatDate(params.date).toString();

  const tempParams = {
    date: chosenDate,
    booking_engine_handle: params.booking_engine_handle,
    offset: params.offset,
    limit: params.limit,
  };

  return await APIGet(baseGuestUrl + "/tee-time", tempParams, useGlobalLoader);
}

//GET Customer Tee Times
export async function GetCustomerTeeTimes(
  params: IGetCustomerTeeTimes,
  useGlobalLoader: boolean,
): Promise<IGetCustomerTeeTimesResponse> {
  const chosenDate = formatDate(params.date).toString();

  const tempParams = {
    date: chosenDate,
    booking_engine_handle: params.booking_engine_handle,
    offset: params.offset,
    limit: params.limit,
  };

  return await APIGet(baseCustomerUrl + "/tee-time", tempParams, useGlobalLoader);
}

// Get tee times for authorized users
export async function GetTeeTime(params: IGetTeeTimeParams, useGlobalLoader: boolean) {
  return await APIGet(baseCustomerUrl + "/tee-time", params, useGlobalLoader);
}

export async function GetTeeTimeGuest(params: IGetTeeTimeParams, useGlobalLoader: boolean) {
  return await APIGet(baseGuestUrl + "/tee-time", params, useGlobalLoader);
}

// GET Customer Notification
export async function GetCustomerNotifications(
  params: IGetNotifications,
  useGlobalLoader: boolean,
): Promise<IGetNotificationsResponse> {
  return await APIGet(baseCustomerUrl + "/tee-sheet/notification", params, useGlobalLoader);
}

// GET Guest Notification
export async function GetGuestNotifications(
  params: IGetNotifications,
  useGlobalLoader: boolean,
): Promise<IGetNotificationsResponse> {
  return await APIGet(baseGuestUrl + "/tee-sheet/notification", params, useGlobalLoader);
}

//PUT Update Bookings
export async function UpdateBooking(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseCustomerUrl + "/tee-time/booking", params, useGlobalLoader);
}

interface ICreateAccount {
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  password: string;
}

//POST  Create New Account
export async function CreateAccount(params: ICreateAccount, useGlobalLoader: boolean) {
  return await APIPost(baseCustomerUrl + "/auth/register", params, useGlobalLoader);
}

//POST Lock Booking 5 Mins
export async function LockTeeTimeById(params: IPostTeeTimeLock, useGlobalLoader: boolean) {
  return await APIPost(baseCustomerUrl + "/tee-time/lock", params, useGlobalLoader);
}

export async function DeleteLockTeeTime(useGlobalLoader: boolean) {
  return await APIDelete(baseCustomerUrl + "/tee-time/lock", {}, useGlobalLoader);
}

//Get Cart
export async function GetCart(params: IGetCart, useGlobalLoader: boolean) {
  return await APIGet(baseCustomerUrl + "/cart", params, useGlobalLoader);
}

// GET Customer booking
export async function GetBooking(
  params: IGetBooking,
  useGlobalLoader: boolean,
  token?: CancelToken,
): Promise<IGetBookingResponse> {
  return await APIGet(baseCustomerUrl + "/tee-time/booking", params, useGlobalLoader, token);
}

// GET Customer Booking Participant
export async function GetBookingParticipant(params: any, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseCustomerUrl + "/tee-time/booking/participant", params, useGlobalLoader, token);
}

// POST Customer booking
export async function PostBooking(params: IPostBookingParams, useGlobalLoader: boolean) {
  return await APIPost(baseCustomerUrl + "/tee-time/booking", params, useGlobalLoader);
}

export async function PostCart(params: IPostCartParams, useGlobalLoader: boolean) {
  return await APIPost(baseCustomerUrl + "/tee-time/booking/cart", params, useGlobalLoader);
}

export async function PutCart(params: IPostCartParams, useGlobalLoader: boolean, cancelToken: CancelToken) {
  return await APIPut(newBaseCustomerUrl + "/tee-time/booking/cart", params, useGlobalLoader, cancelToken);
}

export async function PutCancelBooking(params: IPutCancelBookingParams, useGlobalLoader: boolean) {
  return await APIPut(baseCustomerUrl + "/tee-time/booking/cancel", params, useGlobalLoader);
}

export async function GetGuestCheckIns(params: ICheckInsParams, useGlobalLoader: boolean): Promise<IGustCheckInsRes> {
  return await APIGet(baseGuestUrl + "/tee-sheet/check-in", params, useGlobalLoader);
}

export async function GetCustomerCheckIns(
  params: ICheckInsParams,
  useGlobalLoader: boolean,
): Promise<IGustCheckInsRes> {
  return await APIGet(baseCustomerUrl + "/tee-sheet/check-in", params, useGlobalLoader);
}

export async function GetBookingCategoryCheck(params: { booking_engine_id: number }, useGlobalLoader: boolean) {
  return await APIGet(baseCustomerUrl + "/booking-engine/booking-category-check", params, useGlobalLoader);
}

export async function PostGuestAlpha(params: IPostGuestAlpha, useGlobalLoader: boolean): Promise<IPostGuestAlphaRes> {
  return await APIPost(newBaseGuestUrl + "/tee-sheet/alpha", params, useGlobalLoader);
}

export async function PostCustomerAlpha(
  params: IPostCustomerAlpha,
  useGlobalLoader: boolean,
): Promise<IPostCustomerAlphaRes> {
  return await APIPost(newBaseCustomerUrl + "/tee-sheet/alpha", params, useGlobalLoader);
}
