import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { ICustomer } from "redux/reducers/models/customer";
import { IGateway, IKind, IPaymentMethod, IProcessingType, ISource } from "redux/reducers/models/transaction";

const apiVersion = "2022-09";
const adminType = "client-admin";
const baseCustomerUrl = "/" + apiVersion + "/" + adminType + "/customer";

interface IGetCustomer {
  id?: number;
  search?: string;
  state?: string;
  "address-lines"?: boolean;
  "default-address-line"?: boolean;
  memberships?: boolean;
  limit?: number;
  offset?: number;
}

interface IPostCustomer {
  id?: number;
  first_name?: string;
  last_name?: string;
  phone?: string;
  email?: string;
  member_number?: number;
  state?: string;
}

interface ICustomerRes {
  status: number;
  message?: string;
  data: any;
}

export async function GetCustomer(params: IGetCustomer, useGlobalLoader?: boolean, token?: CancelToken) {
  return await APIGet<ICustomer[]>(baseCustomerUrl, params, useGlobalLoader, token);
}

export async function PostCustomer(params: IPostCustomer, useGlobalLoader: boolean): Promise<ICustomerRes> {
  return await APIPost(baseCustomerUrl, params, useGlobalLoader);
}

export async function PutCustomer(params: any, useGlobalLoader: boolean): Promise<ICustomerRes> {
  return await APIPut(baseCustomerUrl, params, useGlobalLoader);
}

export async function DeleteCustomer(params: { id: number }, useGlobalLoader: boolean): Promise<ICustomerRes> {
  return await APIDelete(baseCustomerUrl, params, useGlobalLoader);
}

//Get Customer Notes
export async function GetCustomerNotes(params: any, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseCustomerUrl + "/note", params, useGlobalLoader, token);
}

// Upload Customer Profile Image
export async function UploadCustomerProfileImage(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseCustomerUrl + "/profile-image", params, useGlobalLoader);
}

// Delete Customer Profile Image
export async function DeleteCustomerProfileImage(params: any, useGlobalLoader: boolean) {
  return await APIDelete(baseCustomerUrl + "/profile-image", params, useGlobalLoader);
}

//POST Customer Notes
export async function PostCustomerNote(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseCustomerUrl + "/note", params, useGlobalLoader);
}

export async function PostCustomerImport(params: FormData, useGlobalLoader: boolean): Promise<ICustomerRes> {
  return await APIPost(baseCustomerUrl + "/import", params, useGlobalLoader);
}

export async function SendPasswordReset(params: { email: string }, useGlobalLoader: boolean) {
  return await APIPost(baseCustomerUrl + "/reset", params, useGlobalLoader);
}

export async function GetCustomerType(
  params: { id?: number; application?: "green_fee" | "power_cart" },
  useGlobalLoader?: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseCustomerUrl + "/type", params, useGlobalLoader, token);
}

export async function PutMergeCustomers(
  params: { parent_id: number; child_id: number },
  useGlobalLoader: boolean,
): Promise<ICustomerRes> {
  return await APIPut(baseCustomerUrl + "/merge", params, useGlobalLoader);
}
