import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useHistory } from "react-router-dom";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetKitchenMeals, IKitchenMeal } from "api/rpc/2022-09/facilityAdmin/facility/kitchen";

import { showError } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";
import Page from "components/page/Page";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";

export interface IKitchenCourse {
  id: number;
  facility_id: number;
  position: number;
  title: string;
}

export default function KitchenCourse() {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();

  const [kitchenMeals, setKitchenMeals] = useState<IKitchenMeal[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void getCourses(source.token);
    return () => source.cancel();
  }, []);

  async function getCourses(token?: CancelToken) {
    if (kitchenMeals !== undefined) {
      setKitchenMeals(undefined);
    }
    const res = await GetKitchenMeals(null, token ? false : true, token);
    if (token && token.reason) {
      return;
    }

    if (res?.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.kitchen_course.kitchen_course.001")));
    }

    setKitchenMeals(res.status !== StatusCode.OK ? [] : res.data);
  }

  const primaryAction = {
    content: t("secure.facility.settings.kitchen_course.kitchen_course.002"),
    action: () => history.push("/admin/settings/courses/new"),
  };

  return (
    <Page title={t("secure.facility.settings.kitchen_course.kitchen_course.003")} narrow primaryAction={primaryAction}>
      <DataTable
        columns={[{ label: t("secure.facility.settings.kitchen_course.kitchen_course.004") }]}
        loading={kitchenMeals === undefined}
      >
        {kitchenMeals?.map(course => {
          return (
            <tr
              key={course.id}
              className="clickable"
              onClick={() => history.push("/admin/settings/courses/" + String(course?.id))}
            >
              <td>{course?.title}</td>
            </tr>
          );
        })}
      </DataTable>
    </Page>
  );
}
