import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ITableElement, TableDegreeRotation } from "api/rpc/facilityAdmin/tables/tables";
import React, { useEffect, useState } from "react";
import { DragLayerMonitor, useDragLayer, XYCoord } from "react-dnd";
import { IFloorPlanBoundingRectangle, IFloorPlanTableScale, ITableElementGridDimensions } from "../floorPlan/FloorPlan";
import { DEFAULT_TABLE_DIMENSION } from "../floorPlanEdit/FloorPlanEdit";
import { IFloorPlanTableItem } from "../floorPlanTable/FloorPlanTable";
import "./floorPlanTableCustomDragLayer.scss";
import { useTranslation } from "react-i18next";

interface IFloorPlanTableCustomDragLayerProps {
  floorPlanRef: React.MutableRefObject<HTMLDivElement>;
  calculateTableScale: (tableElement: ITableElement, degreeRotation?: TableDegreeRotation) => IFloorPlanTableScale;
  isOverFloorPlan: boolean;
  calculateClosestGridCoordinate: (
    currentCoordinate: XYCoord,
    tableElement: ITableElement,
    degreeRotation?: TableDegreeRotation,
  ) => XYCoord;
  tableElements: ITableElement[];
  checkIfTableIsOutsideFloorPlan: (
    closestGridCoordinate: XYCoord,
    halfTableWidth: number,
    halfTableHeight: number,
  ) => boolean;
  gridSquareSideLength: number;
  checkIfTableIsIntersectingAnotherTable: (tableGridBoundingRectangle: IFloorPlanBoundingRectangle) => boolean;
  rotateTableElementGridDimensions: (
    tableElement: ITableElement,
    degreeRotation: TableDegreeRotation,
  ) => ITableElementGridDimensions;
  calculateCenterOfTableXPixelOffset: (tableElement: ITableElement, degreeRotation: TableDegreeRotation) => number;
  calculateCenterOfTableYPixelOffset: (tableElement: ITableElement, degreeRotation: TableDegreeRotation) => number;
}

export default function FloorPlanTableCustomDragLayer(props: IFloorPlanTableCustomDragLayerProps) {
  const {
    floorPlanRef,
    calculateTableScale,
    isOverFloorPlan,
    calculateClosestGridCoordinate,
    tableElements,
    checkIfTableIsOutsideFloorPlan,
    gridSquareSideLength,
    checkIfTableIsIntersectingAnotherTable,
    rotateTableElementGridDimensions,
    calculateCenterOfTableXPixelOffset,
    calculateCenterOfTableYPixelOffset,
  } = props;

  const [currentCoordinate, setCurrentCoordinate] = useState<XYCoord>(null);
  const [currentScrollY, setCurrentScrollY] = useState<number>(0);
  const [currentTableElement, setCurrentTableElement] = useState<ITableElement>(undefined);
  const [closestGridCoordinate, setClosestGridCoordinate] = useState<XYCoord>(null);
  const [closestGridCoordinateIsValid, setClosestGridCoordinateIsValid] = useState<boolean>(null);
  const { t, i18n } = useTranslation();

  const { isDragging, clientOffset, item } = useDragLayer((monitor: DragLayerMonitor) => {
    return {
      isDragging: monitor.isDragging(),
      clientOffset: monitor.getClientOffset(),
      item: monitor.getItem<IFloorPlanTableItem>(),
    };
  });

  useEffect(() => {
    function handleScroll() {
      setCurrentScrollY(window.scrollY);
    }

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (item) {
      let tableElement: ITableElement = undefined;
      if (item.optionTable) {
        tableElement = item.optionTable.element;
      } else if (item.droppedTable) {
        const droppedTableElement = tableElements.find(
          tableElement => tableElement.id === item.droppedTable.table_element_id,
        );

        tableElement = droppedTableElement;
      }

      setCurrentTableElement(tableElement);
    }
  }, [item]);

  useEffect(() => {
    if (clientOffset) {
      if (item && (item.optionTable || item.droppedTable)) {
        const floorPlanBoundingRectangle = floorPlanRef.current.getBoundingClientRect();
        const floorPlanCoordinate: XYCoord = {
          x: Math.round(floorPlanBoundingRectangle.x),
          y: Math.round(floorPlanBoundingRectangle.y),
        };

        const dragCoordinate: XYCoord = clientOffset;

        const newTableViewportDelta: XYCoord = {
          x: dragCoordinate.x - floorPlanCoordinate.x,
          y: dragCoordinate.y - floorPlanCoordinate.y,
        };

        const tableBoundingRectangle = item.tableRef.current.getBoundingClientRect();
        const tableCoordinate: XYCoord = {
          x: Math.round(tableBoundingRectangle.x),
          y: Math.round(tableBoundingRectangle.y),
        };

        const tableInitialDragViewportDelta: XYCoord = {
          x: item.initialDragCoordinate.x - tableCoordinate.x,
          y: item.initialDragCoordinate.y - tableCoordinate.y,
        };

        const scrollYOffset = currentScrollY - item.initialDragScrollYPosition;

        let tableScale: IFloorPlanTableScale = { widthScale: 1, heightScale: 1 };

        if (item.optionTable) {
          tableScale = calculateTableScale(item.optionTable.element);
        }

        const newTableCoordinate: XYCoord = {
          x:
            newTableViewportDelta.x -
            tableInitialDragViewportDelta.x * (item?.droppedTable ? 1 : tableScale.widthScale),
          y:
            newTableViewportDelta.y -
            tableInitialDragViewportDelta.y * (item?.droppedTable ? 1 : tableScale.heightScale) +
            scrollYOffset * (item?.droppedTable ? 1 : tableScale.heightScale),
        };

        setCurrentCoordinate(newTableCoordinate);
      } else {
        setCurrentCoordinate(null);
      }
    } else {
      setCurrentCoordinate(null);
    }
  }, [clientOffset, currentScrollY, item]);

  useEffect(() => {
    if (currentCoordinate) {
      const newClosestGridCoordinate: XYCoord = calculateClosestGridCoordinate(
        currentCoordinate,
        currentTableElement,
        item?.droppedTable?.rotation,
      );

      if (newClosestGridCoordinate) {
        setClosestGridCoordinate(newClosestGridCoordinate);
      }
    }
  }, [currentCoordinate, currentTableElement]);

  useEffect(() => {
    if (closestGridCoordinate) {
      if (!currentTableElement) {
        setClosestGridCoordinateIsValid(false);
      } else {
        const rotatedGridDimensions = rotateTableElementGridDimensions(
          currentTableElement,
          item?.droppedTable?.rotation,
        );

        const halfTableWidth = rotatedGridDimensions.grid_width / 2;
        const halfTableHeight = rotatedGridDimensions.grid_height / 2;
        const isInFloorPlan = !checkIfTableIsOutsideFloorPlan(closestGridCoordinate, halfTableWidth, halfTableHeight);

        if (!isInFloorPlan) {
          setClosestGridCoordinateIsValid(false);
        } else {
          const tableGridBoundingRectangle: IFloorPlanBoundingRectangle = {
            id: item.droppedTable ? item.droppedTable.id : undefined,
            topLeft: {
              x: closestGridCoordinate.x - rotatedGridDimensions.grid_width / 2,
              y: closestGridCoordinate.y - rotatedGridDimensions.grid_height / 2,
            },
            bottomRight: {
              x: closestGridCoordinate.x + rotatedGridDimensions.grid_width / 2,
              y: closestGridCoordinate.y + rotatedGridDimensions.grid_height / 2,
            },
          };

          const tableIsIntersectingAnotherTable = checkIfTableIsIntersectingAnotherTable(tableGridBoundingRectangle);

          setClosestGridCoordinateIsValid(!tableIsIntersectingAnotherTable);
        }
      }
    }
  }, [closestGridCoordinate, currentTableElement]);

  return isDragging && currentCoordinate ? (
    <>
      <div
        className="floor-plan-customer-drag-layer-table"
        style={{
          left: currentCoordinate.x,
          top: currentCoordinate.y,
          width: `${
            DEFAULT_TABLE_DIMENSION * calculateTableScale(currentTableElement, item?.droppedTable?.rotation).widthScale
          }px`,
          height: `${
            DEFAULT_TABLE_DIMENSION * calculateTableScale(currentTableElement, item?.droppedTable?.rotation).heightScale
          }px`,
          border: !isOverFloorPlan ? "none" : closestGridCoordinateIsValid ? "1px dashed blue" : "1px solid red",
        }}
      >
        <img
          src={currentTableElement?.image_src ?? ""}
          style={{
            position: "absolute",
            left: calculateCenterOfTableXPixelOffset(currentTableElement, item?.droppedTable?.rotation),
            top: calculateCenterOfTableYPixelOffset(currentTableElement, item?.droppedTable?.rotation),
            transform: `translate(-50%, -50%) rotate(${item?.droppedTable?.rotation ?? 0}deg)`,
            transformOrigin: "center",
            width: `${DEFAULT_TABLE_DIMENSION * calculateTableScale(currentTableElement, undefined).widthScale}px`,
            height: `${DEFAULT_TABLE_DIMENSION * calculateTableScale(currentTableElement, undefined).heightScale}px`,
          }}
        />
      </div>
      {isOverFloorPlan && closestGridCoordinateIsValid && (
        <div
          className="floor-plan-custom-drag-layer-closest-grid-position-container"
          style={{
            left: closestGridCoordinate?.x * gridSquareSideLength,
            top: closestGridCoordinate?.y * gridSquareSideLength,
          }}
        >
          <FontAwesomeIcon
            className="floor-plan-custom-drag-layer-closest-grid-position"
            style={{
              fontSize: `${Math.max(18, Math.round(gridSquareSideLength))}px`,
            }}
            icon={["fal", "plus"]}
          />
        </div>
      )}
    </>
  ) : null;
}
