import { isEqualWith, isNull } from "lodash";
import { TFunction } from "react-i18next";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faCcMastercard, faCcVisa, faCcDiscover, faCcDinersClub, faCcAmex } from "@fortawesome/free-brands-svg-icons";
import { faCreditCard } from "@fortawesome/pro-regular-svg-icons";
import { TProductEdit } from "pages/secure/facility/product/edit/useEditProduct";

export const displayCurrency = (currency: string, amount: number): string | null => {
  if (amount == null) {
    return "";
  }
  if (currency === "cad" || currency === "usd") {
    const rounded = Number(amount).toFixed(2);
    const fixedAmount = rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return "$" + String(fixedAmount);
  }

  return null;
};

export const roundNumber = (amount: any, digits: number) => {
  const convertedAmount = Number(amount);
  return convertedAmount.toFixed(digits);
};

/**
 * Provide accurate Decimal Rounding || Flooring || Ceiling to a specific number.
 * - Treats floats like decimals, fixing any binary rounding issues.
 * - decimalAdjust("round", 39.425, 1) === 39.4
 * - decimalAdjust("round", 39.425, 2) === 39.43
 * - decimalAdjust("round", 1262.48, -2) === 1300
 * @param {string} type - Math function to be applied to the given number.
 * @param {number} value - The number being rounded to a specific number of fractional digits.
 * @param {number} [precision=2] - The fractional digit precision being rounded too.
 * Passing a negative number will round places to the left of the decimal.
 */
// https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
export const decimalAdjust = (type: "round" | "ceil" | "floor" | "trunc", value: number, precision = 2): number => {
  // Handle negative values when rounding
  if (type === "round" && value < 0) {
    return -decimalAdjust(type, -value, precision);
  }

  const shift = (num: number, exponent: number) => {
    const val = (num.toString() + "e").split("e");
    return +(val[0] + "e" + (val[1] + (exponent || 0).toString()));
  };

  const adjusted = shift(value, +precision);
  return shift(Math[type](adjusted), -precision);
};

export const displayPercent = (amount: number | string, precision = 2) => {
  amount = typeof amount === "string" ? Number(amount) * 100 : amount * 100;
  return String(decimalAdjust("round", amount, precision)) + "%";
  // return String(amount) + "%";
};

export const formatDate = (date: Date) => {
  //1969-12-31
  const d = new Date(date);
  let month = "" + String(d.getMonth() + 1);
  let day = "" + String(d.getDate());
  const year = d.getFullYear();

  if (month.length < 2) {
    month = "0" + month;
  }
  if (day.length < 2) {
    day = "0" + day;
  }

  return [year, month, day].join("-");
};

export const convertTime = (startTime: any, omitPeriod?: boolean) => {
  const tempHours = startTime.split(":");
  let hours = tempHours[0];
  const minutes = tempHours[1];
  let startTimeString = "";

  if (hours < 12) {
    hours = Number(hours);
    startTimeString = String(hours) + ":" + String(minutes) + (omitPeriod ? "" : " AM");
    //startTimeString = startTime + " AM";
  } else {
    hours = hours - 12;

    if (hours === 0) {
      hours = 12;
    }

    startTimeString = String(hours) + ":" + String(minutes) + (omitPeriod ? "" : " PM");
  }

  return startTimeString;
};

export const timeToNumber = () => {
  const curr = new Date();
  let hour = `${curr.getHours()}`;
  let min = `${curr.getMinutes()}`;

  if (hour.length < 2) {
    hour = "0" + hour;
  }

  if (min.length < 2) {
    min = "0" + min;
  }

  const parsedTime = parseInt(`${hour}${min}`);

  return parsedTime;
};

export const capitalize = (str: string) => {
  if (typeof str === "string") {
    return str.replace("_", "").charAt(0).toUpperCase() + str.slice(1);
  } else {
    return "";
  }
};

export function capitalizeWords(string: string) {
  const stringArray = string.toLowerCase().split(" ");
  stringArray.forEach((word, index) => (stringArray[index] = word.charAt(0).toUpperCase() + word.substring(1)));
  return stringArray.join(" ");
}

export const uppercase = (str: string) => {
  if (typeof str === "string") {
    return str.toUpperCase();
  } else {
    return "";
  }
};

export const valueToString = (str: string, style?: string) => {
  if (str === undefined || str === null) {
    return;
  }

  const result = str.replace(/_/g, " ");
  if (style === "uppercase") {
    return result.toUpperCase();
  } else {
    return result.replace(/(^\w|\s\w)/g, (c: string) => c.toUpperCase());
  }
};

export const delay = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export function isNumeric(str: string) {
  return !Number.isNaN(Number.parseFloat(str)) && Number.isFinite(Number.parseFloat(str));
}

export function isPositiveInteger(str: string) {
  const positiveIntegerRegex = /^[1-9][0-9]*$/;
  return positiveIntegerRegex.test(str);
}

export function isWholeNumber(str: string) {
  const wholeNumberRegex = /^0$|^[1-9][0-9]*$/;
  return wholeNumberRegex.test(str);
}

export const isEmpty = (x: any) => x === undefined || x === null || x === "";

/**
 * @param {string} date Formatted string: YYYY-MM-DD
 * @returns {string} Formatted string
 */
export function formatDateFromString(date: string) {
  const tempDateArray = date.split("-");

  const tempYear: number = parseInt(tempDateArray[0]);
  const tempMonth: number = parseInt(tempDateArray[1]) - 1;
  const tempDay: number = parseInt(tempDateArray[2]);

  //build the full date
  const d = new Date(tempYear, tempMonth, tempDay);

  const weekday = d.toLocaleString("en-us", { weekday: "long" });
  const month = d.toLocaleString("en-us", { month: "long" });
  const day = d.toLocaleString("en-us", { day: "numeric" });
  const year = d.toLocaleString("en-us", { year: "numeric" });

  const dateString = weekday + " " + month + " " + day + ", " + year;
  return dateString;
}

export const monthNames = (t: TFunction<"translation", undefined>) => [
  `${t("helpers.helpers.005")}`, //January
  `${t("helpers.helpers.006")}`, //February
  `${t("helpers.helpers.007")}`, //March
  `${t("helpers.helpers.008")}`, //April
  `${t("helpers.helpers.009")}`, //May
  `${t("helpers.helpers.010")}`, //June
  `${t("helpers.helpers.011")}`, //July
  `${t("helpers.helpers.012")}`, //August
  `${t("helpers.helpers.013")}`, //September
  `${t("helpers.helpers.014")}`, //October
  `${t("helpers.helpers.015")}`, //November
  `${t("helpers.helpers.016")}`, //December
];

export const weekdayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

/**
 * Returns a new sorted obj.
 *   - Type passed in is the type returned as an array.
 */
export function sortObj<T>(obj: T[], key: keyof T, direction: "asc" | "desc" = "asc"): T[] {
  return [...obj].sort((a, b) => {
    if (a[key] > b[key]) {
      return direction === "asc" ? 1 : -1;
    }
    if (a[key] < b[key]) {
      return direction === "asc" ? -1 : 1;
    }
    return 0;
  });
}

/**
 * Given obj keys, determine if the value at the specific key is empty.
 *  - Used across app to validate keys required before calling POST.
 *  - Uses isEmpty helper function.
 *  - Treats -1 values as empty.  Generally -1 = 'empty' inputs
 */
export function validateRequired(
  obj: TProductEdit,
  requiredKeys: Array<keyof TProductEdit | "cost_with_track_inventory">,
): boolean {
  const emptyRequiredKeys = requiredKeys.filter(
    key =>
      isEmpty(obj[key as keyof TProductEdit]) ||
      (typeof obj[key as keyof TProductEdit] == "number" && (obj[key as keyof TProductEdit] as any) == -1) ||
      (typeof obj[key as keyof TProductEdit] === "number" && !numberIsValid(obj[key as keyof TProductEdit] as any)),
  );

  if (emptyRequiredKeys.length === 0) {
    return true;
  }
  // if track_inventory true, only allow save IF product cost is present.
  if (emptyRequiredKeys.includes("cost_with_track_inventory") && !obj.track_inventory) {
    return true;
  } else {
    if (!isEmpty(obj.cost)) {
      return true;
    }
  }

  return false;
}

/**
 * @param requirementObj Base object representing required inputs, and if being required is active.
 * @param splitBy string value removed from the requirementObj keys.  Backend returns the keys structured like "${splitBy}required_key"
 * @returns
 *    - generateRequiredKeys<TPostProduct>({ product_title: false, product_cost: true, product_subtitle: true }, "product_");
 *    - returns [ "cost", "subtitle" ]
 */
export function generateRequiredKeys<T = Record<string, boolean>>(
  requirementObj: Record<string, boolean>,
  splitBy: string,
) {
  if (!requirementObj) {
    return [];
  }

  const activeRequirements = Object.keys(requirementObj)
    .map(key => {
      if (requirementObj[key]) {
        return key;
      }
      if (key.includes("cost_with_track_inventory")) {
        return key;
      }
    })
    .filter(val => val !== undefined);

  return activeRequirements.map(key => key.split(splitBy).pop() as keyof T & "cost_with_track_inventory");
}

export function customerErrorMessage(t: TFunction<"translation", undefined>, message: string) {
  switch (message) {
    case "Facility does not exist":
      return `${t("helpers.helpers.001")}`;
    case "Client does not exist":
      return `${t("helpers.helpers.002")}`;
    case "Customer already exists":
      return `${t("helpers.helpers.003")}`;
    case "A phone number or email is required":
      return `${t("helpers.helpers.004")}`;
    default:
      return message;
  }
}

export function unsavedChangesExist<T>(
  wanted: T,
  current: T,
  setCurrent?: React.Dispatch<React.SetStateAction<T>>,
  loaded = true,
) {
  if (isEmpty(current)) {
    if (loaded && setCurrent) {
      setCurrent(current);
    }
    return false;
  }

  return !isEqualWith(current, wanted, (originalValue, newValue) => {
    if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
      return true;
    }
  });
}

/**
 * A function to compare if two arrays have the same elements regardless of their order
 *   - ie (TRUE)
 *   - a = [ 1, 2, 3 ]
 *   - b = [ 2, 1, 3 ]
 * */
export function ignoreOrderCompare<T>(a: T[], b: T[]) {
  if (isEmpty(a) || isEmpty(b) || a?.length !== b?.length) {
    return false;
  }

  const elements = new Set([...a, ...b]);
  for (const x of elements) {
    const count1 = a.filter(e => e === x).length;
    const count2 = b.filter(e => e === x).length;
    if (count1 !== count2) {
      return false;
    }
  }

  return true;
}

export function getLanguage() {
  const selectedLanguage = localStorage.getItem("i18nextLng");
  switch (selectedLanguage) {
    case "fr":
      return "French";
    case "frCA":
      return "French";
    case "en":
      return "English";
    default:
      return "English";
  }
}

export function getCreditCardIcon(brand: string) {
  let iconName: IconDefinition = null;
  switch (brand) {
    case "visa":
      iconName = faCcVisa;
      break;

    case "amex":
      iconName = faCcAmex;
      break;

    case "mastercard":
      iconName = faCcMastercard;
      break;

    case "discover":
      iconName = faCcDiscover;
      break;

    case "diners":
      iconName = faCcDinersClub;
      break;

    default:
      iconName = faCreditCard;
      break;
  }
  return iconName;
}

/**
 * Update the state passed given an input onChange event.
 * ID is required on the input.
 *
 * @param event  onChange event from the Input or TextField element.
 * @param state  state object being affected by the input.
 * @param setState state setter dispatch.
 *
 * Note: type of the value depends how to handle the updated value.
 * - string = event.target.value
 * - number = parseInt(event.target.value)
 * - boolean = event.target.checked
 */
export function handleChangeEventInput<T extends Record<string, any>>(
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  state: T,
  setState: React.Dispatch<React.SetStateAction<T>>,
) {
  const { id, value } = event.target;
  const valueType = typeof state[id as keyof T];

  if (!valueType) {
    console.warn(`Check ID prop on Input:  \n${id} was not found.`, state);
    return;
  }

  setState(prev => ({
    ...prev,
    [id]:
      valueType === "number"
        ? parseInt(value)
        : valueType === "boolean"
        ? (event.target as HTMLInputElement).checked
        : value,
  }));
}

/**
 * Helper to check value is a number and greater than 0 if it is required.
 * @param value string or number value to check validity
 * @param optional null | undefined | value.length == 0 also returns true.
 * */
export function numberIsValid(value: string | number, optional?: boolean) {
  const price = Number(value);

  // Optional value
  if (optional) {
    if (value === null || value === undefined || String(value).length === 0) {
      return true;
    } else {
      return !isNaN(price) && price >= 0;
    }
  }

  // Required value
  return value !== null && value !== undefined && value !== "" && !isNaN(price) && price >= 0;
}

/** Given a valid date, return the time of that date as a string. */
export function generateTime(date: Date): string {
  if (date) {
    const dateTimeArray = new Date(date).toTimeString().split(" ");
    const timeArray = dateTimeArray[0].split(":");

    return timeArray[0] + ":" + timeArray[1];
  }

  return null;
}

/**
 * Generate nested array to allow simple grid display.
 * @param {T} array Value being mapped into a grid.
 * @param {number} newRowOn Number of columns wanted for the grid.
 * @example
 *   {arrayAsGrid(myColorArray, 2).map((row, rowIndex) => (
 *      <div 
            key={rowIndex} 
            style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
        >
            {row.map((color: string) => (
                <p>{color}</p>
            ))}
 *      </div>
 *   ))}
*/
export const arrayAsGrid = <T>(array: T[], newRowOn: number) => {
  const arr = array.slice();
  const chunks = [];
  while (arr.length) {
    chunks.push(arr.splice(0, newRowOn));
  }
  return chunks;
};

/**
 * Returns a string of `1fr` dependant on length of columnCount param
 * @example
 *   arrayAsColumnTemplate(3) = '1fr 1fr 1fr'
 */
export const arrayAsColumnTemplate = (columnCount: number) => {
  const temp: string[] = new Array(columnCount).fill("1fr");
  const frameString = temp.toString().replace(/,/g, " ");

  return frameString;
};
