import ScheduledPayments from "./pages/ScheduledPayments";
import AccountAdjustments from "./pages/AccountAdjustments";
import AgedAccountsReceivable from "./pages/AgedAccountsReceivable";
import DetailedSalesByDepartment from "./pages/DetailedSalesByDepartment";
import MembershipSales from "./pages/MembershipSales";
import TeeSheetUtilization from "./pages/TeeSheetUtilization";
import OrderExceptions from "./pages/OrderExceptions";
import TournamentsNotInvoiced from "./pages/TournamentsNotInvoiced";
import Deposits from "./pages/Deposits";
import Invoices from "./pages/Invoices";
import PrizeAccounts from "./pages/PrizeAccounts";
import GolfNorthDailySales from "./pages/GolfNorthDailySales";
import HospitalityEventsNotInvoiced from "./pages/HospitalityEventsNotInvoiced";
import InventoryAdjustments from "./pages/InventoryAdjustments";
import InventoryListingByDepartment from "./pages/InventoryListingByDepartment";

const reportRoutes: { path: string; component: any }[] = [
  {
    path: "/admin/report/scheduled-payments",
    component: ScheduledPayments,
  },
  {
    path: "/admin/report/account-adjustments",
    component: AccountAdjustments,
  },
  {
    path: "/admin/report/aged-accounts-receivable",
    component: AgedAccountsReceivable,
  },
  {
    path: "/admin/report/detailed-sales-by-department",
    component: DetailedSalesByDepartment,
  },
  {
    path: "/admin/report/membership-sales",
    component: MembershipSales,
  },
  {
    path: "/admin/report/tee-sheet-utilization",
    component: TeeSheetUtilization,
  },
  {
    path: "/admin/report/order-exceptions",
    component: OrderExceptions,
  },
  {
    path: "/admin/report/tournaments-not-invoiced",
    component: TournamentsNotInvoiced,
  },
  {
    path: "/admin/report/deposits",
    component: Deposits,
  },
  {
    path: "/admin/report/invoices",
    component: Invoices,
  },
  {
    path: "/admin/report/prize-accounts",
    component: PrizeAccounts,
  },
  {
    path: "/admin/report/prize-accounts",
    component: PrizeAccounts,
  },
  {
    path: "/admin/report/golfnorth-daily-sales",
    component: GolfNorthDailySales,
  },
  {
    path: "/admin/report/hospitality-events-not-invoiced",
    component: HospitalityEventsNotInvoiced,
  },
  {
    path: "/admin/report/inventory-adjustments",
    component: InventoryAdjustments,
  },
  {
    path: "/admin/report/inventory-listing-by-department",
    component: InventoryListingByDepartment,
  },
  {
    path: "/admin/report/revenue-summary",
    component: DetailedSalesByDepartment,
  },
];

export default reportRoutes;
