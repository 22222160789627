import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { StatusCode } from "api/protocols";
import {
  GetCustomerNotes,
  PostCustomerNote,
  DeleteCustomerNote,
  PutCustomerNote,
} from "api/rpc/2022-09/facilityAdmin/customer/customer";
import { useCustomerContext } from "../../context/CustomerContext";
import { TCustomerNote } from "../../context/contextTypes";

import { useAppSelector } from "hooks/redux";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { MOBILE_WIDTH } from "helpers/ScreenSizes";

import { ButtonNew as Button } from "components/buttonNew";
import Popup from "components/popup/Popup";
import Sheet from "components/sheet/Sheet";
import TextField from "components/form/textField/TextField";
import CustomerNoteModalNew from "elements/customer/CustomerNoteModalNew";
import Portal from "elements/Portal";

import "./CustomerNotes.scss";

export default function CustomerNotes() {
  const { t } = useTranslation();
  const { state, updateState } = useCustomerContext();
  const authStore = useAppSelector(store => store.authStore);

  const [addNotePopupVisible, setAddNotePopupVisible] = useState(false);
  const [deleteModal, setDeleteModal] = useState({
    visible: false,
    id: null,
  });
  const [editModal, setEditModal] = useState({
    visible: false,
    note: null as TCustomerNote,
  });

  const windowSize = useWindowSize();

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (state.notes === null) {
      void loadCustomerNotes(state.customer.id, false, source.token);
    }

    return () => {
      source.cancel();
    };
  }, [state.customer.id]);

  async function loadCustomerNotes(customerId: number, globalLoader?: boolean, token?: CancelToken) {
    const customerNotesRes = await GetCustomerNotes(
      {
        customer_id: customerId,
      },
      globalLoader,
      token,
    );

    if (customerNotesRes.status !== StatusCode.OK) {
      return;
    }

    console.log("cust notes", customerNotesRes.data); //debug

    updateState({ notes: customerNotesRes.data });
  }

  async function handleAddNewNote(note: string) {
    const params = {
      customer_id: state.customer.id,
      content: note,
    };

    const newNoteRes = await PostCustomerNote(params, true);

    if (newNoteRes.status !== StatusCode.OK) {
      return;
    }

    setAddNotePopupVisible(false);
    void loadCustomerNotes(state.customer.id, true);
  }

  async function deleteNote(id: number) {
    const noteRes = await DeleteCustomerNote({ id: id }, true);

    if (noteRes.status !== StatusCode.OK) {
      return;
    }

    setDeleteModal({ id: null, visible: false });
    updateState({ notes: state.notes.filter((val: any) => val.id !== id) });
  }

  // Use state with API call
  async function editNote() {
    const noteRes = await PutCustomerNote({ id: editModal.note.id, content: editModal.note.content }, true);

    if (noteRes.status !== StatusCode.OK) {
      return;
    }

    setEditModal({ note: null, visible: false });
    updateState({
      notes: state.notes.map(note => {
        if (note.id === editModal.note.id) {
          return noteRes.data as TCustomerNote;
        }
        return note;
      }),
    });
  }

  return (
    <div>
      <div className="customer-notes-header">
        <h1 className="ui-customer_page-header">{t("secure.facility.customer.tabs.note.customer_notes.003")}</h1>
        <div>
          <Button onClick={() => setAddNotePopupVisible(true)} type="secondary" size="medium">
            <FontAwesomeIcon className="mr-2" icon={["far", "plus"]} />
            <span>
              {windowSize.width > MOBILE_WIDTH
                ? t("secure.facility.customer.tabs.note.customer_notes.002")
                : t("secure.facility.customer.tabs.note.customer_notes.004")}
            </span>
          </Button>
        </div>
      </div>

      <hr className="customer-notes-header-divider" />

      <div className="customer-notes-notes">
        {state.notes?.map(note => {
          return (
            <div key={note.id} className="flex justify-between customer-notes-note">
              <div>
                <div className="customer-notes-note-user">
                  <FontAwesomeIcon icon={["far", "user"]} />
                  <div>{note.author?.full_name ?? ""}</div>
                </div>
                <div className="customer-notes-note-content">{note.content}</div>
                <div className="customer-notes-note-date">
                  {moment.utc(note.created_at).local().format("MMMM DD, YYYY")}
                </div>
              </div>
              <div className="customer-notes-note-actions">
                {(authStore.user?.id === note.admin_user_id || !!authStore.user?.permissions?.customers_edit_note) && (
                  <span style={{ width: "85px" }}>
                    <Button
                      className="cursor-pointer"
                      type="text"
                      onClick={() => setEditModal(prev => ({ ...prev, visible: true, note: note }))}
                    >
                      {"Edit"}
                    </Button>
                  </span>
                )}

                {!!authStore.user?.permissions?.customers_edit_note && (
                  <span style={{ width: "85px" }}>
                    <Button
                      type="text"
                      className="cursor-pointer"
                      onClick={() => setDeleteModal({ visible: true, id: note.id })}
                    >
                      {"Delete"}
                    </Button>
                  </span>
                )}
              </div>
            </div>
          );
        })}
      </div>

      {/* CREATE NOTE MODAL */}
      <CustomerNoteModalNew
        isVisible={addNotePopupVisible}
        onClose={() => setAddNotePopupVisible(false)}
        onOk={(newNote: string) => handleAddNewNote(newNote)}
      />

      {/* DELETE NOTE POPUP */}
      <Popup
        type="warning"
        open={deleteModal.visible}
        title={"Delete Note?"}
        description={"Are you sure you want to delete this note?"}
        closable
        onCancel={() => setDeleteModal({ id: null, visible: false })}
        onOk={() => deleteNote(deleteModal.id)}
        cancelText={"Cancel"}
        okText={"Delete"}
      />

      <Portal isMounted={editModal.visible}>
        <Sheet
          open={editModal.visible}
          size="small"
          onCancel={() => setEditModal({ visible: false, note: null })}
          onOk={() => editNote()}
          title={`${editModal.note?.author.full_name}'s note on ${state.customer?.full_name}`}
        >
          <TextField
            label="Note"
            value={editModal.note ? editModal.note.content : ""}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              setEditModal(prev => ({
                ...prev,
                note: {
                  ...prev.note,
                  content: e.target.value,
                },
              }))
            }
          />
        </Sheet>
      </Portal>
    </div>
  );
}
