import React from "react";
import "./LeagueTitle.scss";

interface ILeagueTitleProps {
  className?: string;
  leagueName: string;
  facilityName: string;
}

export default function LeagueTitle(props: ILeagueTitleProps) {
  const { className, leagueName, facilityName } = props;

  return (
    <div className={"league-title" + (className ? ` ${className}` : "")}>
      <div className="league-name">{leagueName ?? ""}</div>
      <div className="facility-name">{facilityName ?? ""}</div>
    </div>
  );
}
