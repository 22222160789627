import React, { useEffect, useRef, useState } from "react";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./rightClickMenu.scss";
import classNames from "classnames";

interface IMenuAction {
  icon: IconName;
  iconColor?: string;
  title: string;
  disabled?: boolean;
  action: () => void;
}

interface IRightClickMenuProps {
  children: React.ReactNode;
  sections: Array<Array<IMenuAction>>;
  informationSection?: Array<string | JSX.Element>;
  fullHeightWidth?: boolean;
}

interface IMousePosition {
  x: number;
  y: number;
}

interface IRightClickMenuState {
  menuContainerRef: React.MutableRefObject<HTMLDivElement>;
  menuRef: React.MutableRefObject<HTMLDivElement>;
  open: boolean;
  mousePosition: IMousePosition;
}

export default function RightClickMenu(props: IRightClickMenuProps) {
  const { children, sections, informationSection, fullHeightWidth } = props;

  const RIGHT_CLICK_MENU_CONTAINER_CLASS_NAME = "right-click-menu-container";

  const [state, setState] = useState<IRightClickMenuState>({
    menuContainerRef: useRef(null),
    menuRef: useRef(null),
    open: false,
    mousePosition: {
      x: 0,
      y: 0,
    },
  });

  function closeMenu() {
    setState(prev => ({
      ...prev,
      open: false,
      mousePosition: {
        x: 0,
        y: 0,
      },
    }));
  }

  function handleClick(e: MouseEvent) {
    if (!state.menuContainerRef?.current) {
      return;
    }

    const target: Node = e.target as Node;

    const rightClicked = e.button === 2;

    if (rightClicked && !state.menuRef?.current) {
      const clickedInsideMenuContainer = state.menuContainerRef.current.contains(target);
      if (clickedInsideMenuContainer) {
        setState(prev => ({
          ...prev,
          open: true,
          mousePosition: {
            x: e.clientX - state.menuContainerRef.current.getBoundingClientRect().x,
            y: e.clientY - state.menuContainerRef.current.getBoundingClientRect().y,
          },
        }));
      }
    }

    const rightClickMenuContainers = Array.from(
      state.menuContainerRef.current.querySelectorAll(`.${RIGHT_CLICK_MENU_CONTAINER_CLASS_NAME}`),
    );
    const clickedNestedRightClickMenu = rightClickMenuContainers.some(container => container.contains(target));

    const clickedInsideMenuContainer = state.menuContainerRef.current.contains(target);
    const clickedInsideMenu = state.menuRef.current?.contains(target);
    const clickedInEmptyContainerSpace = clickedInsideMenuContainer && !clickedInsideMenu;

    if (clickedNestedRightClickMenu || !clickedInsideMenuContainer || (clickedInEmptyContainerSpace && !rightClicked)) {
      closeMenu();
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  function handleAction(menuAction: IMenuAction) {
    if (menuAction?.action) {
      menuAction.action();
    }

    closeMenu();
  }

  return (
    <div
      ref={state.menuContainerRef}
      className={classNames(RIGHT_CLICK_MENU_CONTAINER_CLASS_NAME, {
        "right-click-menu-container_full": fullHeightWidth,
      })}
      // className={RIGHT_CLICK_MENU_CONTAINER_CLASS_NAME}
      onContextMenu={e => e.preventDefault()}
    >
      {children}
      {state.open && (
        <div
          ref={state.menuRef}
          className="right-click-menu"
          style={{
            display: state.open ? "block" : "none",
            left: state.mousePosition.x,
            top: state.mousePosition.y,
          }}
          onClick={e => e.stopPropagation()}
          // Disabled dragging
          draggable="true"
          onDragStart={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {sections?.map((section, sectionIndex) => {
            return (
              <React.Fragment key={sectionIndex}>
                <div className="right-click-menu-section">
                  {section?.map((menuAction, actionIndex) => {
                    return (
                      <button
                        key={actionIndex}
                        onClick={() => handleAction(menuAction)}
                        className="right-click-menu-section-action"
                        disabled={menuAction.disabled}
                      >
                        <FontAwesomeIcon
                          className="right-click-menu-section-action-icon"
                          color={menuAction.iconColor}
                          icon={["far", menuAction.icon]}
                        />
                        <div>{menuAction.title}</div>
                      </button>
                    );
                  })}
                </div>
                {sectionIndex < sections.length - 1 && <hr className="right-click-menu-section-divider" />}
              </React.Fragment>
            );
          })}
          {informationSection?.length > 0 && (
            <>
              {sections?.length > 0 && <hr className="right-click-menu-section-divider" />}

              <div className="right-click-menu-information-section">
                {informationSection.map((information, index) => {
                  return <div key={index}>{information}</div>;
                })}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
