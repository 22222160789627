import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import axios, { CancelToken } from "axios";
import { useParams, useHistory } from "react-router";
import { useAppDispatch } from "hooks/redux";
import { showError, showSuccess } from "redux/actions/ui";
import useModal from "hooks/modals/useModal";
import { valueToString } from "helpers/Helpers";
import { cloneDeep, isEqualWith, isNull, omit } from "lodash";
import moment from "moment";

import {
  GetInvoice,
  PutInvoice,
  GetInvoiceLineItem,
  PostInvoiceLineItem,
  PutInvoiceLineItem,
  DeleteInvoiceLineItem,
  GetDownloadInvoice,
  PutFinalizeInvoice,
  PutVoidInvoice,
  PostDuplicateInvoice,
  PutApplyDeposit,
  GetInvoiceTransaction,
} from "api/rpc/2024-04/facilityAdmin/order/invoice";
import { GetCustomer } from "api/rpc/2024-04/facilityAdmin/customer/customer";
import { GetTournament } from "api/rpc/2024-04/facilityAdmin/tournament/tournament";
import { GetEvent } from "api/rpc/2024-04/facilityAdmin/event/hospitalityEvent";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Sheet from "components/sheet/Sheet";
import Input from "components/form/input/Input";
import { Select } from "components/select/index";
import ProductSelect from "components/productModal/ProductSelect";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import OrderSummaryLineItem from "components/bookingEngine/orderSummaryLineItem/OrderSummaryLineItem";
import GolferCard from "components/bookingPopUp/golferCard/GolferCard";
import Popup from "components/popup/Popup";
import DepositCallout from "components/depositCallout/DepositCallout";
import { ButtonNew as Button } from "components/buttonNew/index";
import { Badge } from "components/badge/Badge";
import { NavigationDropdownNew } from "components/navigationDropdownNew/NavigationDropdownNew";
import InvoicePaymentModal from "./InvoicePaymentModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IInvoice, IInvoiceLineItem, IInvoiceTransaction } from "redux/reducers/models/order";
import { IProduct, IVariant } from "redux/reducers/models/product";
import { IAddress, ICustomer } from "redux/reducers/models/customer";
import { loadPaymentOptions } from "redux/actions/facility";
import Portal from "elements/Portal";

import "./invoice.scss";

interface IFilterState {
  eventQuery: string;
  selectedEvent: any;
  eventSearching: boolean;
}

interface IChangeCustomerState {
  showChangeCustomer: boolean;
  showNewCustomer: boolean;
  customerQuery: string;
  customerSearching: boolean;
  customerSearchResult: Array<ICustomer>;
  selectedCustomer: ICustomer;
  currentCustomer: any;
}

interface IExtendedInvoiceLineItem extends IInvoiceLineItem {
  viewNote: boolean;
}

interface IRemoveLineItemState {
  modalVisible: boolean;
  itemId: number;
}

export default function InvoiceEdit() {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { Option } = Select;

  const [invoice, setInvoice] = useState<IInvoice>(undefined);
  const [parentInvoice, setParentInvoice] = useState<IInvoice>(undefined);
  const [invoiceBeforeChanges, setInvoiceBeforeChanges] = useState<IInvoice>(undefined);
  const [invoiceLineItems, setInvoiceLineItems] = useState<Array<IExtendedInvoiceLineItem>>(undefined);
  const [invoiceLineItemsBeforeChanges, setInvoiceLineItemsBeforeChanges] =
    useState<Array<IExtendedInvoiceLineItem>>(undefined);
  const [events, setEvents] = useState<Array<any>>(undefined);
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  const [finalizePopupVisible, setFinalizePopupVisible] = useState(false);

  const [filterState, setFilterState] = useState<IFilterState>({
    eventQuery: "",
    selectedEvent: null,
    eventSearching: false,
  });

  const [changePlayerState, setChangePlayerState] = useState<IChangeCustomerState>({
    showChangeCustomer: false,
    showNewCustomer: false,
    customerQuery: "",
    customerSearching: false,
    customerSearchResult: [],
    selectedCustomer: null,
    currentCustomer: null,
  });

  const [removeLineItemState, setRemoveLineItemState] = useState<IRemoveLineItemState>({
    modalVisible: false,
    itemId: null,
  });

  const {
    state: lineItemModal,
    updateModal: updateLineItemModal,
    closeModal: closeLineItemModal,
  } = useModal({ search: "", selectedVariants: [], offset: 0, limit: 50, amountPage: false });

  const {
    state: applyDepositPopup,
    updateModal: updateApplyDepositPopup,
    closeModal: closeApplyDepositPopup,
  } = useModal({ depositId: null });

  const {
    state: voidInvoicePopup,
    closeModal: closeVoidInvoicePopup,
    updateModal: updateVoidInvoicePopup,
  } = useModal();

  const {
    state: duplicateInvoicePopup,
    closeModal: closeDuplicateInvoicePopup,
    updateModal: updateDuplicateInvoicePopup,
  } = useModal();

  const {
    state: billingAddressModal,
    closeModal: closeBillingAddressModal,
    updateModal: updateBillingAddressModal,
  } = useModal({
    selectedAddress: null,
  });

  const {
    state: shippingAddressModal,
    closeModal: closeShippingAddressModal,
    updateModal: updateShippingAddressModal,
  } = useModal({
    selectedAddress: null,
  });

  useEffect(() => {
    void loadInvoice();
    void dispatch(loadPaymentOptions());
  }, []);

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = global.setTimeout(() => {
        void changePlayerSearch(mounted, changePlayerState.customerQuery);
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      setChangePlayerState(prevState => ({ ...prevState, playerSearchResult: [] }));
    };
  }, [changePlayerState.customerQuery]);

  useEffect(() => {
    if (!invoice || (!invoice?.tournament_id && !invoice.hospitality_event_id)) {
      const source = axios.CancelToken.source();
      void loadEvents(source.token);
      return () => {
        source.cancel();
      };
    }
  }, [filterState.eventQuery]);

  async function changePlayerSearch(mounted: boolean, customerSearchQuery: string) {
    try {
      if (customerSearchQuery === "") {
        if (mounted) {
          setChangePlayerState(prevState => ({ ...prevState, customerSearchResult: [] }));
        }
        return;
      } else {
        setChangePlayerState(prevState => ({ ...prevState, customerSearching: true }));
        const customerRes = await GetCustomer({ search: customerSearchQuery, "address-lines": true }, false);
        if (customerRes.status !== StatusCode.OK) {
          setChangePlayerState(prevState => ({ ...prevState, customerSearching: false, customerSearchResult: [] }));
          return;
        } else if (mounted) {
          setChangePlayerState(prevState => ({
            ...prevState,
            customerSearching: false,
            customerSearchResult: customerRes.data,
          }));
        }
      }
    } catch (error) {
      console.log("err", error);
    }
    return;
  }

  async function loadInvoice() {
    const invoiceRes = await GetInvoice(
      {
        id: Number(id),
        extended: true,
      },
      true,
    );

    if (invoiceRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading invoice")); // TODO: Translation
      return;
    }

    // If invoice has a parent invoice load it
    if (invoiceRes.data[0].parent_id) {
      const parentRes = await GetInvoice({ id: invoiceRes.data[0].parent_id }, true);

      if (invoiceRes.status !== StatusCode.OK) {
        dispatch(showError("Error loading parent invoice")); // TODO: Translation
      } else {
        setParentInvoice(parentRes.data[0]);
      }
    }

    if (invoiceRes.data[0].tournament_id) {
      const tournamentRes = await GetTournament({ id: invoiceRes.data[0].tournament_id }, true);

      if (tournamentRes.status !== StatusCode.OK) {
        dispatch(showError("Error loading tournament")); // TODO: Translation
        return;
      }

      invoiceRes.data[0].tournament = tournamentRes.data[0];

      const depositRes = await GetInvoiceTransaction({ tournament_id: tournamentRes.data[0].id }, true);

      if (depositRes.status !== StatusCode.OK) {
        dispatch(showError("Error loading tournament deposits")); // TODO: Translation
        return;
      }

      invoiceRes.data[0].tournament.deposits = determineRefundedTransactions(depositRes.data);
    } else if (invoiceRes.data[0].hospitality_event_id) {
      const eventRes = await GetEvent({ id: invoiceRes.data[0].hospitality_event_id }, true);

      if (eventRes.status !== StatusCode.OK) {
        dispatch(showError("Error loading hospitality event")); // TODO: Translation
        return;
      }

      invoiceRes.data[0].hospitality_event = eventRes.data[0];

      const depositRes = await GetInvoiceTransaction({ hospitality_event_id: eventRes.data[0].id }, true);

      if (depositRes.status !== StatusCode.OK) {
        dispatch(showError("Error loading hospitality event deposits")); // TODO: Translation
        return;
      }

      invoiceRes.data[0].hospitality_event.deposits = determineRefundedTransactions(depositRes.data);
    }

    const lineItemRes = await GetInvoiceLineItem({ invoice_id: invoiceRes.data[0].id }, true);

    const lineItemResWithViewNote = lineItemRes.data.map((lineItem: IInvoiceLineItem) => {
      return { ...lineItem, viewNote: lineItem.note ? true : false };
    });

    const customerRes = await GetCustomer(
      {
        id: invoiceRes.data[0].customer_id,
        "address-lines": true,
      },
      false,
    );

    if (customerRes.data) {
      invoiceRes.data[0].customer = customerRes.data[0];
    }

    if (lineItemRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading invoice line items")); // TODO: Translation
      return;
    }

    ReactDOM.unstable_batchedUpdates(() => {
      setInvoice(invoiceRes.data[0]);
      setInvoiceBeforeChanges(invoiceRes.data[0]);
      setInvoiceLineItems(lineItemResWithViewNote);
      setInvoiceLineItemsBeforeChanges(lineItemRes.data);
      setFilterState(prevState => ({ ...prevState, customerQuery: invoiceRes.data[0].customer?.full_name }));
    });
  }

  async function saveInvoice() {
    if (
      !isEqualWith(invoiceBeforeChanges, invoice, (originalValue, newValue) => {
        if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
          return true;
        }
      })
    ) {
      const invoiceRes = await PutInvoice(
        {
          invoice_id: id,
          tournament_id: invoice.tournament ? invoice?.tournament_id : null,
          hospitality_event_id: invoice.hospitality_event ? invoice?.hospitality_event_id : null,
          customer_id: invoice?.customer_id,
          date: invoice?.date,
          invoice_due: invoice?.invoice_due,
          customer_billing_address_id: invoice?.customer_billing_address_id,
          customer_shipping_address_id: invoice?.customer_shipping_address_id,
        },
        true,
      );

      if (invoiceRes.status !== StatusCode.OK) {
        dispatch(showError("Error updating invoice"));
        return;
      }
    }

    for (let i = 0; i < invoiceLineItems.length; i++) {
      if (
        !isEqualWith(invoiceLineItemsBeforeChanges[i], invoiceLineItems[i], (originalValue, newValue) => {
          if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
            return true;
          }
        })
      ) {
        const lineItemRes = await PutInvoiceLineItem(
          {
            id: invoiceLineItems[i].id,
            quantity: invoiceLineItems[i].quantity,
            price: invoiceLineItems[i].price,
            note: invoiceLineItems[i].note,
          },
          true,
        );

        if (lineItemRes.status !== StatusCode.OK) {
          dispatch(showError("Error updating line item")); // TODO: Translation
          return;
        }
      }
    }

    dispatch(showSuccess("Invoice saved successfully")); // TODO: Translation
    void loadInvoice();
  }

  async function loadEvents(token: CancelToken) {
    let tournaments: Array<any> = [];
    let events: Array<any> = [];
    setEvents(null);

    const tournamentRes = await GetTournament({ search: filterState.eventQuery }, false, token);

    if (token && token.reason) {
      return;
    }
    if (tournamentRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading tournaments")); // TODO: Translation
    } else {
      tournaments = tournamentRes.data.map(tournament => ({ ...tournament, event_type: "tournament" }));
    }

    const eventRes = await GetEvent({ search: filterState.eventQuery }, false, token);

    if (token && token.reason) {
      return;
    }
    if (eventRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading events")); // TODO: Translation
    } else {
      events = eventRes.data.map(event => ({ ...event, event_type: "hospitality" }));
    }

    const allEvents = tournaments.concat(events);

    setEvents(allEvents);
  }

  async function downloadInvoice() {
    const invoiceRes = await GetDownloadInvoice({ invoice_id: Number(id) }, true);

    if (invoiceRes.status !== StatusCode.OK) {
      dispatch(showError("Error downloading invoice")); // TODO: Translation
      return;
    }

    window.open(invoiceRes.data);
  }

  async function postLineItem() {
    const selectedVariantIds: Array<number> = [];

    lineItemModal.selectedVariants.forEach(product => {
      product.variants.forEach((variant: IVariant) => {
        selectedVariantIds.push(variant.id);
      });
    });

    for (let i = 0; i < selectedVariantIds.length; i++) {
      const lineItemRes = await PostInvoiceLineItem(
        {
          invoice_id: Number(id),
          variant_id: selectedVariantIds[i],
          quantity: 1,
        },
        true,
      );

      if (lineItemRes.status !== StatusCode.OK) {
        dispatch(showError("Error posting line item")); // TODO: Translation
        return;
      }
    }

    dispatch(showSuccess("Line items added successfully")); // TODO: Translation
    void loadInvoice();
    updateLineItemModal({ isOpen: false, selectedVariants: [] });
  }

  async function removeLineItem(id: number) {
    const removeRes = await DeleteInvoiceLineItem({ id: id }, true);

    if (removeRes.status !== StatusCode.OK) {
      dispatch(showError("Error removing line item")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Line item removed successfully")); // TODO: Translation
    setRemoveLineItemState({ modalVisible: false, itemId: null });
    void loadInvoice();
  }

  async function finalizeInvoice() {
    const filteredGiftCards: any[] = invoiceLineItems?.filter(
      (line_item: Record<string, any>) => line_item?.product?.type === "Gift Card",
    );

    const gift_cards: any[] = [];

    if (filteredGiftCards?.length > 0) {
      //Check for gift cards
      if (filteredGiftCards.length > 0) {
        filteredGiftCards.forEach((line_item: Record<string, any>, index: number) => {
          for (let i = 0; i < line_item?.quantity; i++) {
            gift_cards.push({
              product_id: line_item.product_id,
              cart_line_item_id: line_item.id,
              code: null,
              pin: null,
              random: true,
              balance: line_item.price,
              reload: 0,
            });
          }
        });
      }
    }

    const params = {
      invoice_id: Number(id),
      gift_cards: gift_cards,
    };

    const finalizeRes = await PutFinalizeInvoice(params, true);

    if (finalizeRes.status !== StatusCode.OK) {
      dispatch(showError("Error finalizing invoice")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Invoice finalized successfully")); // TODO: Translation
    setFinalizePopupVisible(false);
    history.push("/admin/invoices/" + id);
  }

  async function applyDeposit(depositId: number) {
    if (!depositId) {
      dispatch(showError("No deposit selected")); // TODO: Translation
      return;
    }

    const applyRes = await PutApplyDeposit(
      {
        invoice_id: Number(id),
        deposit_id: depositId,
      },
      true,
    );

    if (applyRes.status !== StatusCode.OK) {
      dispatch(showError("Error applying deposit")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Deposit applied successfully")); // TODO: Translation
    updateApplyDepositPopup({ isOpen: false, depositId: null });
    void loadInvoice();
  }

  async function voidInvoice() {
    const voidRes = await PutVoidInvoice({ invoice_id: Number(id) }, true);

    if (voidRes.status !== StatusCode.OK) {
      dispatch(showError("Error voiding invoice")); // TODO: Translation
      return;
    }

    void loadInvoice();
    updateVoidInvoicePopup({ isOpen: false });
  }

  async function duplicateInvoice() {
    const duplicateRes = await PostDuplicateInvoice({ invoice_id: Number(id) }, true);

    if (duplicateRes.status !== StatusCode.OK) {
      dispatch(showError("Error duplicating invoice")); // TODO: Translation
      return;
    }

    updateDuplicateInvoicePopup({ isOpen: false });
    dispatch(showSuccess("Invoice duplicated successfully")); // TODO: Translation
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>, lineItemIndex: number) {
    const { id, value } = e.target;

    let updatedLineItem = cloneDeep(invoiceLineItems?.[lineItemIndex]);

    if (!updatedLineItem) {
      return;
    }

    updatedLineItem = {
      ...updatedLineItem,
      [id]: value,
    };

    const updatedInvoiceLineItems = cloneDeep(invoiceLineItems);

    updatedInvoiceLineItems[lineItemIndex] = updatedLineItem;

    setInvoiceLineItems(updatedInvoiceLineItems);
  }

  function toggleAddNote(lineItemIndex: number, checked: boolean) {
    let updatedLineItem = cloneDeep(invoiceLineItems?.[lineItemIndex]);

    updatedLineItem = {
      ...updatedLineItem,
      viewNote: checked,
    };

    const updatedInvoiceLineItems = cloneDeep(invoiceLineItems);

    updatedInvoiceLineItems[lineItemIndex] = updatedLineItem;

    setInvoiceLineItems(updatedInvoiceLineItems);
  }

  function setInvoiceDate(date: Date) {
    const updatedInvoice = cloneDeep(invoice);

    updatedInvoice.date = moment(date).format("YYYY-MM-DD");

    setInvoice(updatedInvoice);
  }

  function setInvoiceDue(value: string) {
    const updatedInvoice = cloneDeep(invoice);

    updatedInvoice.invoice_due = value;

    setInvoice(updatedInvoice);
  }

  function handleProductSelect(products: Array<IProduct>) {
    const selectedProducts = [...products];
    selectedProducts?.forEach((product, productIndex) => {
      product?.variants?.forEach((variant, variantIndex) => {
        if (variant?.quantity == null) {
          selectedProducts[productIndex].variants[variantIndex] = { ...variant, quantity: 1 };
        }
      });
    });
    updateLineItemModal({ selectedVariants: selectedProducts });
  }

  function handleShowCustomerChange(customer: any) {
    if (invoice?.financial_status !== "draft") {
      return;
    }

    setChangePlayerState(prevState => ({
      ...prevState,
      showChangeCustomer: true,
      showNewCustomer: false,
      customerQuery: "",
      customerSearchResult: [],
      currentCustomer: customer,
    }));
  }

  function closeChangePlayer() {
    setChangePlayerState(prevState => ({
      ...prevState,
      showNewCustomer: false,
      currentCustomer: null,
      showChangeCustomer: false,
      customerQuery: "",
      customerSearchResult: [],
      selectedCustomer: null,
    }));
  }

  function changeCustomer() {
    const updatedInvoice = cloneDeep(invoice);

    updatedInvoice.customer_id = changePlayerState.selectedCustomer.id;
    updatedInvoice.customer = changePlayerState.selectedCustomer;

    ReactDOM.unstable_batchedUpdates(() => {
      setInvoice(updatedInvoice);

      setChangePlayerState(prevState => ({
        ...prevState,
        currentCustomer: null,
        showChangeCustomer: false,
        customerSearchResult: [],
        customerQuery: "",
        selectedCustomer: null,
      }));
    });
  }

  function changePlayerHandleCustomerSearch(query: string) {
    setChangePlayerState(prevState => ({ ...prevState, customerQuery: query }));
  }

  function handleChangePlayerSelection(id: number, customer: ICustomer) {
    setChangePlayerState(prevState => ({ ...prevState, selectedCustomer: customer }));
  }

  function handleEditEvent() {
    const updatedInvoice = cloneDeep(invoice);

    updatedInvoice.tournament = null;
    updatedInvoice.tournament_id = null;
    updatedInvoice.hospitality_event = null;
    updatedInvoice.hospitality_event_id = null;

    setInvoice(updatedInvoice);
  }

  function handleEventChange(event?: any) {
    const updatedInvoice = cloneDeep(invoice);

    if (event.event_type === "tournament") {
      updatedInvoice.tournament_id = event.id;
      updatedInvoice.tournament = event;
    } else if (event.event_type === "hospitality") {
      updatedInvoice.hospitality_event_id = event.id;
      updatedInvoice.hospitality_event = event;
    }

    ReactDOM.unstable_batchedUpdates(() => {
      setInvoice(updatedInvoice);

      setFilterState(prevState => ({
        ...prevState,
        tournamentQuery: "",
        selectedTournament: null,
      }));
    });
  }

  function updateBillingAddress() {
    setInvoice({
      ...invoice,
      billing_address: billingAddressModal.selectedAddress,
      customer_billing_address_id: billingAddressModal.selectedAddress.id,
    });
    updateBillingAddressModal({ isOpen: false, selectedAddress: null });
  }

  function updateShippingAddress() {
    setInvoice({
      ...invoice,
      shipping_address: shippingAddressModal.selectedAddress,
      customer_shipping_address_id: shippingAddressModal.selectedAddress.id,
    });
    updateShippingAddressModal({ isOpen: false, selectedAddress: null });
  }

  function navigateToParentInvoice() {
    const url =
      parentInvoice?.financial_status === "draft"
        ? `/admin/invoices/${parentInvoice.id}/edit`
        : `/admin/invoices/${parentInvoice.id}`;

    history.push(url);
  }

  function unsavedChangesExist() {
    const ignoreViewNotes = invoiceLineItems?.map(({ viewNote, ...lineItem }) => lineItem);
    const ignoreViewNotesBeforeChanges = invoiceLineItemsBeforeChanges?.map(({ viewNote, ...lineItem }) => lineItem);

    return (
      !isEqualWith(invoiceBeforeChanges, invoice, (originalValue, newValue) => {
        if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
          return true;
        }
      }) ||
      !isEqualWith(ignoreViewNotesBeforeChanges, ignoreViewNotes, (originalValue, newValue, key) => {
        if (isNull(originalValue) && isNull(newValue)) {
          return true;
        }
      })
    );
  }

  function getDepositsSum() {
    if (!invoice?.transactions) {
      return;
    }

    let sum = 0;

    for (let i = 0; i < invoice?.transactions.length; i++) {
      if (invoice?.transactions[i].kind === "refund") {
        sum -= invoice?.transactions[i].amount;
      } else {
        sum += invoice?.transactions[i].amount;
      }
    }

    return sum;
  }

  /** Refunded transaction based off parent_id of the transaction == the currentTransactionId */
  function determineRefundedTransactions(transactions: IInvoiceTransaction[]): IInvoiceTransaction[] {
    const filteredTransactions = transactions.filter(
      (transaction: Record<string, any>) => transaction?.kind !== "deposit_authorization",
    );
    const refundedTransactions = transactions.filter(
      (transaction: Record<string, any>) => transaction?.parent_id && transaction?.kind === "refund",
    );

    const markRefunds = [...filteredTransactions]?.reduce(
      (markedRefunds, currentTransaction, index) => {
        const refunded = refundedTransactions?.some(
          (transaction: Record<string, any>) => transaction?.parent_id === currentTransaction?.id,
        );

        // Adding in 'refunded' param to HouseAccountTransactionType
        if (refunded) {
          markedRefunds[index] = { ...markedRefunds[index], refunded: true };
        } else {
          markedRefunds[index] = { ...markedRefunds[index], refunded: false };
        }
        return markedRefunds;
      },
      [...filteredTransactions],
    );

    return markRefunds;
  }

  function cancelUnsavedChanges() {
    setInvoice(invoiceBeforeChanges);
    setInvoiceLineItems(invoiceLineItemsBeforeChanges);
  }

  return (
    <>
      <Page
        title={`Invoice ${invoice?.name}`} // TODO: Translation
        titleMetadata={generateStatusBadge(invoice?.financial_status)}
        breadcrumbs={[{ prefix: true, label: "Back to invoices", url: "/admin/invoices" }]} // TODO: Translation
        splitLayout
        multipleActionDropdownAction={{
          label: "Options",
          dropdownProps: {
            alignment: "right",
            options: [
              {
                type: "handler",
                label: "Make Deposit", // TODO: Translation
                icon: "money-bill",
                handler: () => setPaymentModalVisible(true),
              },
              {
                type: "handler",
                label: "Download", // TODO: Translation
                icon: "download",
                handler: () => downloadInvoice(),
              },
              {
                type: "handler",
                label: "Finalize", // TODO: Translation
                icon: "check",
                handler: () => setFinalizePopupVisible(true),
                disabled: invoice?.financial_status !== "draft",
              },
              {
                type: "handler",
                label: "Void", // TODO: Translation
                icon: "ban",
                handler: () => updateVoidInvoicePopup({ isOpen: true }),
                disabled: invoice?.financial_status === "paid" || invoice?.financial_status === "partially_paid",
              },
              {
                type: "handler",
                label: "Duplicate", // TODO: Translation
                icon: "copy",
                handler: () => updateDuplicateInvoicePopup({ isOpen: true }),
                disabled: invoice?.financial_status === "void",
              },
            ],
          },
        }}
        notificationBarProps={{
          isVisible: unsavedChangesExist(),
          onAction: saveInvoice,
          onCancel: cancelUnsavedChanges,
        }}
      >
        <Page.Section twoThirds>
          <Card
            title="Customer"
            titleActions={[
              {
                action: () => handleShowCustomerChange(invoice?.customer),
                content: "Edit", // TODO: Translation
              },
            ]}
          >
            <Card.Section>
              {invoice?.customer ? (
                <div className="flex justify-between">
                  <div className="flex-1">
                    <p className="text-md text-semibold">{invoice?.customer?.full_name}</p>
                    <p className="text-md">{invoice?.customer?.email}</p>
                    <p className="text-md">{invoice?.customer?.phone}</p>
                  </div>
                  <div className="flex-1 flex justify-between gap-2">
                    {invoice?.billing_address ? (
                      <div className="flex justify-between gap-1">
                        <div>
                          <p className="text-md text-semibold">{"Billing Address" /* TODO: Translation */}</p>
                          <p className="text-md">{invoice?.billing_address?.address_line_1}</p>
                          {...invoice?.billing_address?.address_line_2
                            ? [
                                <p className="text-md" key="address-line-2">
                                  {invoice?.billing_address?.address_line_2}
                                </p>,
                              ]
                            : []}
                          <p className="text-md">{`${invoice?.billing_address?.city}, ${invoice?.billing_address?.province_code}`}</p>
                          <p className="text-md">{invoice?.billing_address?.postal}</p>
                        </div>
                        <FontAwesomeIcon
                          className="mt-1"
                          icon={["far", "pencil"]}
                          onClick={() => updateBillingAddressModal({ isOpen: true })}
                          size="1x"
                        />
                      </div>
                    ) : (
                      <div className="flex justify-between">
                        <div>
                          <p className="text-md text-semibold">{"Billing Address" /* TODO: Translation */}</p>
                          <p className="text-md">{"None Selected"}</p>
                        </div>
                        <FontAwesomeIcon
                          className="mt-1"
                          icon={["far", "pencil"]}
                          onClick={() => updateBillingAddressModal({ isOpen: true })}
                          size="1x"
                        />
                      </div>
                    )}
                    {invoice?.shipping_address ? (
                      <div className="flex justify-between gap-1">
                        <div>
                          <p className="text-md text-semibold">{"Shipping Address" /* TODO: Translation */}</p>
                          <p className="text-md">{invoice?.shipping_address?.address_line_1}</p>
                          {...invoice?.shipping_address?.address_line_2
                            ? [
                                <p className="text-md" key="address-line-2">
                                  {invoice?.shipping_address?.address_line_2}
                                </p>,
                              ]
                            : []}
                          <p className="text-md">{`${invoice?.shipping_address?.city}, ${invoice?.shipping_address?.province_code}`}</p>
                          <p className="text-md">{invoice?.shipping_address?.postal}</p>
                        </div>
                        <FontAwesomeIcon
                          className="mt-1"
                          icon={["far", "pencil"]}
                          onClick={() => updateShippingAddressModal({ isOpen: true })}
                          size="1x"
                        />
                      </div>
                    ) : (
                      <div className="flex justify-between">
                        <div>
                          <p className="text-md text-semibold">{"Shipping Address" /* TODO: Translation */}</p>
                          <p className="text-md">{"None Selected"}</p>
                        </div>
                        <FontAwesomeIcon
                          className="mt-1"
                          icon={["far", "pencil"]}
                          onClick={() => updateShippingAddressModal({ isOpen: true })}
                          size="1x"
                        />
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  <p className="text-lg">{"No Customer Selected" /* TODO: Translation */}</p>
                </div>
              )}
            </Card.Section>
          </Card>
          <Card
            title="Items" // TODO: Translation
            titleActions={[
              {
                content: "Add Items", // TODO: Translation
                action: () => updateLineItemModal({ isOpen: true }),
                disabled: invoice?.financial_status !== "draft",
              },
            ]}
          >
            <Card.Section>
              {invoiceLineItems?.map((lineItem, index) => (
                <Card.SubSection key={lineItem.id}>
                  <div className="invoice-line-item">
                    <div>
                      <p className="text-lg text-semibold">{lineItem.product_title}</p>
                      {lineItem.product_title !== lineItem.variant_title ? (
                        <p className="text-sm text-gray-500">{lineItem.variant_title}</p>
                      ) : null}
                      {lineItem.locked ? <Badge type="error">{"Locked" /* TODO: Translation */}</Badge> : null}
                    </div>
                    <div className="invoice-line-item-inputs">
                      <Input
                        value={lineItem?.quantity}
                        label={"Quantity"} // TODO: Translation
                        id={"quantity"}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, index)}
                        type="number"
                        min={1}
                        disabled={lineItem?.locked}
                      />
                      <Input
                        value={lineItem?.price}
                        label={"Price"} // TODO: Translation
                        id={"price"}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, index)}
                        type="number"
                        prefix={"$"}
                        min={0}
                        disabled={lineItem?.locked}
                      />
                      <div className="text-right mt-3">
                        <p className="text-lg text-semibold">
                          {Intl.NumberFormat("en-CA", { style: "currency", currency: "CAD" }).format(
                            Number(lineItem?.price * lineItem?.quantity),
                          )}
                        </p>
                      </div>
                      <div className="mt-3">
                        <NavigationDropdownNew
                          showPlainTextLabel
                          rightAlign
                          label={<FontAwesomeIcon icon={["fas", "ellipsis-vertical"]} />}
                          sections={[
                            [
                              {
                                label: "Remove", // TODO: Translation
                                icon: "x",
                                onClick: () => setRemoveLineItemState({ modalVisible: true, itemId: lineItem.id }),
                                disabled: lineItem.locked,
                              },
                              {
                                label: lineItem.viewNote
                                  ? "Hide Note" // TODO: Translation
                                  : lineItem.note
                                  ? "Edit Note" // TODO: Translation
                                  : "Add Note", // TODO: Translation
                                icon: "note",
                                onClick: lineItem.viewNote
                                  ? () => toggleAddNote(index, false)
                                  : () => toggleAddNote(index, true),
                              },
                            ],
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                  {lineItem.viewNote && (
                    <Input
                      value={lineItem?.note}
                      id={"note"}
                      placeholder={"Description"} // TODO: Translation
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, index)}
                    />
                  )}
                </Card.SubSection>
              ))}
            </Card.Section>
          </Card>
        </Page.Section>
        <Page.Section oneThird>
          <Card title={"Details"}>
            <Card.Section>
              <Card.SubSection>
                <div className="invoice-details">
                  <DatePickerInput
                    label="Invoice Date" // TODO: Translation
                    months={1}
                    position="center"
                    startingDate={new Date(moment(invoice?.date).format())}
                    setStartingDate={setInvoiceDate}
                  />
                  <Select
                    label="Invoice Due" // TODO: Translation
                    onChange={(value: string) => setInvoiceDue(value)}
                    defaultValue={invoice?.invoice_due}
                  >
                    <Option key={0} value={"on_receipt"}>
                      {"On Receipt" /* TODO: Translation */}
                    </Option>
                    <Option key={1} value={"net_30"}>
                      {"Net 30" /* TODO: Translation */}
                    </Option>
                    <Option key={2} value={"net_60"}>
                      {"Net 60" /* TODO: Translation */}
                    </Option>
                  </Select>
                  <Input
                    label={"Due Date"} // TODO: Translation
                    value={invoice?.due_date}
                    id={"due_date"}
                    readOnly
                  />
                </div>
              </Card.SubSection>
              {invoice?.parent_id && (
                <Card.SubSection>
                  <div className="flex justify-between">
                    <p className="text-lg text-semibold mt-1">{"Parent Invoice" /* TODO: Translation */}</p>
                    <Button type="tertiary" size="small" onClick={() => navigateToParentInvoice()}>
                      {"View" /* TODO: Translation */}
                    </Button>
                  </div>
                  <div>
                    <p className="text-md text-semibold">{parentInvoice?.name}</p>
                  </div>
                </Card.SubSection>
              )}
              <Card.SubSection>
                <div className="invoice-details">
                  <div className="flex justify-between">
                    <p className="text-lg text-semibold mt-1">{"Event" /* TODO: Translation */}</p>
                    <Button type="tertiary" size="small" onClick={() => handleEditEvent()}>
                      {"Edit" /* TODO: Translation */}
                    </Button>
                  </div>
                  {invoice?.tournament || invoice?.hospitality_event ? (
                    <div>
                      {invoice?.tournament ? (
                        <div>
                          <p className="text-md text-semibold">{invoice?.tournament?.name}</p>
                          <p className="text-md">{`${invoice?.tournament?.total_participant_count}/${Number(
                            invoice?.tournament?.player_limit,
                          )} Players`}</p>{" "}
                          {/* TODO: Translation */}
                          <p className="text-md">{invoice?.tournament?.date}</p>
                          {invoice?.tournament?.deposits ? (
                            <div>
                              {invoice?.tournament?.deposits
                                ?.filter(deposit => deposit.invoice_id === invoice?.id || deposit.invoice_id === null)
                                ?.filter(deposit => !deposit.refunded && deposit.kind !== "refund")
                                ?.map((deposit, index) => {
                                  return (
                                    <DepositCallout
                                      key={deposit?.id}
                                      type={"info"}
                                      title={`Deposit ${index + 1}`}
                                      content={new Intl.NumberFormat("en-CA", {
                                        style: "currency",
                                        currency: invoice?.currency,
                                      }).format(deposit?.amount)}
                                      actions={{
                                        primary: {
                                          label: deposit?.invoice_id ? "Applied" : "Apply",
                                          onClick: () =>
                                            updateApplyDepositPopup({ isOpen: true, depositId: deposit?.id }),
                                          disabled: deposit?.invoice_id !== null,
                                        },
                                      }}
                                      applied={deposit?.invoice_id !== null}
                                    />
                                  );
                                })}
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <div>
                          <p className="text-md text-semibold">{invoice?.hospitality_event?.name}</p>
                          <p className="text-md">{invoice?.hospitality_event?.date}</p>
                          {invoice?.hospitality_event?.deposits ? (
                            <div className="mt-2">
                              {invoice?.hospitality_event?.deposits
                                ?.filter(deposit => deposit.invoice_id === invoice?.id || deposit.invoice_id === null)
                                ?.filter(deposit => !deposit.refunded && deposit.kind !== "refund")
                                ?.map((deposit, index) => {
                                  return (
                                    <DepositCallout
                                      key={deposit?.id}
                                      type={"info"}
                                      title={`Deposit ${index + 1}`}
                                      content={new Intl.NumberFormat("en-CA", {
                                        style: "currency",
                                        currency: invoice?.currency,
                                      }).format(deposit?.amount)}
                                      actions={{
                                        primary: {
                                          label: deposit?.invoice_id ? "Applied" : "Apply",
                                          onClick: () =>
                                            updateApplyDepositPopup({ isOpen: true, depositId: deposit?.id }),
                                          disabled: deposit?.invoice_id !== null,
                                        },
                                      }}
                                      applied={deposit?.invoice_id !== null}
                                    />
                                  );
                                })}
                            </div>
                          ) : null}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <Select
                        showSearch
                        onSearch={(query: string) =>
                          setFilterState(prevState => ({ ...prevState, eventQuery: query }))
                        }
                        onChange={(value: any, event: any) => handleEventChange(event)}
                        placeholder={"Search events..."} // TODO: Translation
                        allowClear
                        searchValue={filterState.eventQuery}
                        showDropDownOnFocus={true}
                        searching={!events}
                      >
                        <Option value={-1} extraValues={{ name: "None", id: -1 } /* TODO: Translation */}>
                          <div className="text-semibold text-lg">None</div>
                        </Option>
                        {events?.map((event: any, index: number) => {
                          return (
                            <Option value={event?.id} extraValues={event} key={index}>
                              <div className="text-semibold text-lg">{event.name}</div>
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  )}
                </div>
              </Card.SubSection>
              <Card.SubSection>
                <p className="text-lg text-semibold color-gray">{"Invoice Summary" /* TODO: Translation */}</p>
                <OrderSummaryLineItem
                  lineItem={"Subtotal" /* TODO: Translation */}
                  itemValue={Intl.NumberFormat("en-CA", { style: "currency", currency: "CAD" }).format(
                    Number(invoice?.subtotal_price),
                  )}
                />
                {invoice?.tax_lines?.map((line, index) => {
                  return (
                    <OrderSummaryLineItem
                      key={index}
                      lineItem={line.title}
                      itemValue={Intl.NumberFormat("en-CA", { style: "currency", currency: "CAD" }).format(
                        Number(line?.price),
                      )}
                    />
                  );
                })}
                {invoice?.total_tip > 0 ? (
                  <OrderSummaryLineItem
                    lineItem={"Tip" /* TODO: Translation */}
                    itemValue={Intl.NumberFormat("en-CA", { style: "currency", currency: "CAD" }).format(
                      Number(invoice?.total_tip),
                    )}
                  />
                ) : null}
                <OrderSummaryLineItem
                  lineItem={"Total" /* TODO: Translation */}
                  itemValue={Intl.NumberFormat("en-CA", { style: "currency", currency: "CAD" }).format(
                    Number(invoice?.total_price),
                  )}
                />

                {invoice?.transactions.length > 0 && (
                  <div className="mt-2 mb-2">
                    {invoice.transactions.map((deposit, index) => {
                      return (
                        <OrderSummaryLineItem
                          key={index}
                          lineItem={
                            `Deposit ${deposit.kind === "refund" ? "Refund " : ""}${
                              deposit.kind !== "refund" ? index + 1 : ""
                            }` /* TODO: Translation */
                          }
                          itemValue={Intl.NumberFormat("en-CA", {
                            style: "currency",
                            currency: invoice?.currency,
                          }).format(deposit.kind === "refund" ? Number(deposit.amount) : Number(deposit.amount) * -1)}
                        />
                      );
                    })}
                  </div>
                )}

                <OrderSummaryLineItem
                  lineItem={"Balance" /* TODO: Translation */}
                  itemValue={Intl.NumberFormat("en-CA", { style: "currency", currency: "CAD" }).format(
                    Number(invoice?.total_price) - getDepositsSum(),
                  )}
                />
              </Card.SubSection>
            </Card.Section>
          </Card>
        </Page.Section>
      </Page>

      {/* Add Item Modal */}
      <Sheet
        open={lineItemModal.isOpen}
        title="Search Products" // TODO: Translation
        size="medium"
        onCancel={closeLineItemModal}
        cancelText="Close" // TODO: Translation
        onOk={postLineItem}
        okText="Add Items" // TODO: Translation
      >
        <ProductSelect
          selectedProducts={lineItemModal.selectedVariants}
          onChange={handleProductSelect}
          search
          getProductsParams={{ extended_variants: true }}
          limit={50}
        />
      </Sheet>

      {/* Change Customer Modal */}
      <Sheet
        title="Change Customer" // TODO: Translation
        open={changePlayerState.showChangeCustomer}
        size="small"
        closable
        onCancel={closeChangePlayer}
        onOk={changeCustomer}
        okText={"Confirm"} // TODO: Translation
        okDisabled={changePlayerState.selectedCustomer ? false : true}
        overflow
      >
        <>
          {changePlayerState.currentCustomer !== null ? (
            <div>
              <span>{"Previous Customer" /* TODO: Translation */}</span>

              <GolferCard
                email={changePlayerState.currentCustomer?.email}
                name={changePlayerState.currentCustomer?.full_name}
                memberCode={changePlayerState.currentCustomer?.member_code}
                customerType={changePlayerState.currentCustomer?.customer_type}
                phone={changePlayerState.currentCustomer?.phone}
              />

              <FontAwesomeIcon
                className="ml-auto mr-auto mt-4 mb-2 block text-primary-ui-colour"
                icon={["far", "arrow-circle-down"]}
                size="2x"
              />
            </div>
          ) : null}

          <span>{"New Customer" /* TODO: Translation */}</span>
          {changePlayerState.selectedCustomer ? (
            <GolferCard
              closable
              removeGolfer={() =>
                setChangePlayerState(prevState => ({
                  ...prevState,
                  selectedCustomer: null,
                  playerSearchResult: [],
                  customerQuery: "",
                }))
              }
              email={changePlayerState.selectedCustomer.email}
              name={changePlayerState.selectedCustomer.full_name}
              memberCode={changePlayerState.selectedCustomer.member_code}
              customerType={changePlayerState.selectedCustomer.customer_type}
              phone={changePlayerState.selectedCustomer.phone}
            />
          ) : (
            <Select
              showSearch
              className={`flex justify-center align-center w-full h-10 position-relative z-20 text-black font-medium appearance-none border-none focus:outline-none placeholder-gray-200`}
              onSearch={(query: string) => changePlayerHandleCustomerSearch(query)}
              onChange={(id: number, customer: ICustomer) => handleChangePlayerSelection(id, customer)}
              placeholder={"Search Player..." /* TODO: Translation */}
              allowClear
              searchValue={changePlayerState.customerQuery}
              showDropDownOnFocus={true}
              searching={changePlayerState.customerSearching}
            >
              {changePlayerState.customerSearchResult?.map((customer, index) => {
                return (
                  <Option key={index} value={customer.id} name={customer.full_name} extraValues={customer}>
                    <div className="flex justify-between">
                      <div>
                        <div className="text-semibold text-lg">{customer?.full_name}</div>
                        <div className="text-sm text-gray-500">{customer.customer_type}</div>
                        <div className="text-sm text-gray-500">{customer.email}</div>
                        <div className="text-sm text-gray-500">{customer.phone ? customer.phone : null}</div>
                      </div>

                      <div className="font-medium text-base text-gray-500 self-end">{customer.member_code}</div>
                    </div>
                  </Option>
                );
              })}
            </Select>
          )}
        </>
      </Sheet>

      {invoice && paymentModalVisible && (
        <InvoicePaymentModal
          paymentModalVisible={paymentModalVisible}
          invoice={invoice}
          paymentMethods={null} // TODO: Load current customer payment methods
          onOk={() => loadInvoice()}
          closePaymentModal={() => setPaymentModalVisible(false)}
        />
      )}

      <Popup
        open={removeLineItemState.modalVisible}
        title="Remove Item?" // TODO: Translation
        description="Are you sure you want to remove this item from the invoice?" // TODO: Translation
        type="warning"
        onCancel={() => setRemoveLineItemState({ modalVisible: false, itemId: null })}
        onOk={() => removeLineItem(removeLineItemState.itemId)}
      />

      <Popup
        open={finalizePopupVisible}
        title="Finalize Invoice?" // TODO: Translation
        description="Are you sure you want to finalize this invoice? The invoice will no longer be editable." // TODO: Translation
        type="warning"
        onCancel={() => setFinalizePopupVisible(false)}
        onOk={() => finalizeInvoice()}
      />

      <Popup
        open={applyDepositPopup.isOpen}
        title="Apply Deposit?" // TODO: Translation
        description="Are you sure you want to apply this deposit? This deposit will no longer be appliable to any other invoices." // TODO: Translation
        type="warning"
        onCancel={() => closeApplyDepositPopup()}
        onOk={() => applyDeposit(applyDepositPopup.depositId)}
      />

      <Portal isMounted={voidInvoicePopup.isOpen}>
        <Popup
          open={voidInvoicePopup.isOpen}
          title="Void Invoice" // TODO: Translation
          description="Are you sure you want to void this invoice?" // TODO: Translation
          onOk={voidInvoice}
          okText="Void" // TODO: Translation
          onCancel={closeVoidInvoicePopup}
          type="warning"
        />
      </Portal>

      <Portal isMounted={duplicateInvoicePopup.isOpen}>
        <Popup
          open={duplicateInvoicePopup.isOpen}
          title="Duplicate Invoice" // TODO: Translation
          description="Are you sure you want to duplicate this invoice?" // TODO: Translation
          onOk={duplicateInvoice}
          okText="Duplicate" // TODO: Translation
          onCancel={closeDuplicateInvoicePopup}
          type="warning"
        />
      </Portal>

      <Portal isMounted={billingAddressModal.isOpen}>
        <Sheet
          open={billingAddressModal.isOpen}
          title="Update Billing Address" // TODO: Translation
          onOk={updateBillingAddress}
          okText="Update" // TODO: Translation
          onCancel={closeBillingAddressModal}
          size="small"
          overflow
          closable
        >
          <Select
            label={"Customer Billing Address"} // TODO: Translation
            onChange={(value: number, extraValues: IAddress) =>
              updateBillingAddressModal({ selectedAddress: extraValues })
            }
            showDropDownOnFocus={true}
            disabled={!invoice?.customer_id}
            defaultValue={invoice?.customer_billing_address_id}
          >
            {invoice?.customer?.address_lines?.map((address: any, index: number) => {
              return (
                <Option value={address?.id} extraValues={address} key={index}>
                  <div className="text-semibold text-lg">
                    {address.address_line_1}
                    <span className="text-sm">{` ${String(address.city)}, ${String(address.province_name)}`}</span>
                  </div>
                </Option>
              );
            })}
          </Select>
        </Sheet>
      </Portal>

      <Portal isMounted={shippingAddressModal.isOpen}>
        <Sheet
          open={shippingAddressModal.isOpen}
          title="Update Shipping Address" // TODO: Translation
          onOk={updateShippingAddress}
          okText="Update" // TODO: Translation
          onCancel={closeShippingAddressModal}
          size="small"
          overflow
          closable
        >
          <Select
            label={"Customer Shipping Address"} // TODO: Translation
            onChange={(value: number, extraValues: IAddress) =>
              updateShippingAddressModal({ selectedAddress: extraValues })
            }
            showDropDownOnFocus={true}
            disabled={!invoice?.customer_id}
            defaultValue={invoice?.customer_shipping_address_id}
          >
            {invoice?.customer?.address_lines?.map((address: any, index: number) => {
              return (
                <Option value={address?.id} extraValues={address} key={index} name={address.address_line_1}>
                  <div className="text-semibold text-lg">
                    {address.address_line_1}
                    <span className="text-sm">{` ${String(address.city)}, ${String(address.province_name)}`}</span>
                  </div>
                </Option>
              );
            })}
          </Select>
        </Sheet>
      </Portal>
    </>
  );
}

function generateStatusBadge(status: string) {
  switch (status) {
    case "draft":
      return <Badge type="gray">{valueToString(status, "capitalize")}</Badge>;
    case "open":
      return <Badge type="primary">{valueToString(status, "capitalize")}</Badge>;
    case "paid":
      return <Badge type="success">{valueToString(status, "capitalize")}</Badge>;
    case "partially_paid":
      return <Badge type="warning">{valueToString(status, "capitalize")}</Badge>;
    case "voided":
      return <Badge type="error">{valueToString(status, "capitalize")}</Badge>;
    default:
      return <Badge type="gray">{valueToString(status, "capitalize")}</Badge>;
  }
}
