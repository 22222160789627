import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import Page from "components/page/Page";
import Card from "components/card/Card";
import { IPrimaryPageAction } from "components/page/PageActions";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";
import axios, { CancelToken } from "axios";
import { GetInventoryTransfer, TInventoryTransfer } from "api/rpc/2024-04/facilityAdmin/product/inventoryTransfer";
import { StatusCode } from "api/protocols";
import { showError } from "redux/actions/ui";
import moment from "moment";
import { Badge } from "components/badge/Badge";
import { valueToString } from "helpers/Helpers";
import { getTransferBadgeType } from "./ViewInventoryTransfer";

interface IInventoryState {
  inventoryTransfers: Array<TInventoryTransfer>;
}

interface IFilterState {
  limit: number;
  offset: number;
}

export default function InventoryTransfers() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { facilityStore } = useAppSelector(store => store);
  const [inventoryState, setInventoryState] = useState<IInventoryState>({
    inventoryTransfers: [],
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    limit: 50,
    offset: 0,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadInventoryTransfers(source.token);
    return () => {
      source.cancel();
    };
  }, [filterState.offset]);

  async function loadInventoryTransfers(cancelToken: CancelToken) {
    // show loader
    if (inventoryState?.inventoryTransfers !== undefined) {
      setInventoryState(prevState => ({ ...prevState, inventoryTransfers: undefined }));
    }

    const res = await GetInventoryTransfer({ ...filterState }, false, cancelToken);
    if (res.status !== StatusCode.OK) {
      if (cancelToken.reason) {
        return;
      }
      dispatch(showError("Error getting inventory transfers"));
    }
    setInventoryState(prevState => ({
      ...prevState,
      inventoryTransfers: res.status === StatusCode.OK ? res?.data : [],
    }));
  }

  function navigateToViewPage(id: number) {
    history.push(`/admin/settings/transfer/${id}/view`);
  }

  const primaryAction: IPrimaryPageAction = {
    content: "New Transfer",
    action: () => history.push("/admin/settings/transfer/new"),
  };

  return (
    <Page title="Inventory Transfers" primaryAction={primaryAction}>
      <DataTable
        columns={[
          { label: "" },
          { label: "Status" },
          { label: "Sent To" },
          { label: "Created By" },
          { label: "Date Created" },
          { label: "Date Submitted" },
        ]}
        loading={inventoryState.inventoryTransfers === undefined}
        footer={{
          tableLimit: filterState.limit,
          tableOffset: filterState.offset,
          disableNextOffset:
            !inventoryState.inventoryTransfers || !(inventoryState.inventoryTransfers?.length === filterState.limit),
          handleTableOffset: direction =>
            setFilterState(prev => ({
              ...prev,
              offset: direction === "prev" ? prev.offset - prev.limit : prev.offset + prev.limit,
            })),
        }}
      >
        {inventoryState?.inventoryTransfers?.map((transfer, index) => {
          const destinationOut = facilityStore?.facility?.id === transfer?.facility_id;
          return (
            <tr key={index} onClick={() => navigateToViewPage(transfer.id)} className="clickable">
              <td>
                <Badge
                  type={destinationOut ? "warning" : "success"}
                  iconRight
                  icon={destinationOut ? "arrow-turn-down-right" : "arrow-turn-down-left"}
                >
                  {destinationOut ? "Out" : "In"}
                </Badge>
              </td>
              <td>
                <Badge type={getTransferBadgeType(transfer?.status)}>{valueToString(transfer?.status)}</Badge>
              </td>
              <td>{transfer.destination_facility_long_name}</td>
              <td>{transfer?.created_by_full_name}</td>
              <td>{moment(transfer.date_created, "YYYY-MM-DD h:mm:ss").format("LL")}</td>
              <td>{transfer?.date_submitted && moment(transfer?.date_submitted, "YYYY-MM-DD h:mm:ss").format("LL")}</td>
            </tr>
          );
        })}
      </DataTable>
    </Page>
  );
}
