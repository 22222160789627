import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetClientAdmin, IClientStaff } from "api/rpc/2022-09/clientAdmin/client/admin";

import { showError } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import { Badge } from "components/badge/Badge";
import Icon from "components/icon/Icon";
import DropFilter from "components/dropdown/DropFilter";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

type TClientStaffFilters = {
  accountState: Set<"active" | "disabled">;
};

export default function ClientStaff() {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [staffFilters, setStaffFilters] = useState<TClientStaffFilters>({
    accountState: new Set(["active"]),
  });

  const [adminData, setAdminData] = useState<IClientStaff[]>(undefined);

  /* adminData filtered to display specific accounts based off staffFilters.accountState */
  const filteredAdmins = useMemo(() => {
    if (adminData === undefined) {
      return undefined;
    } else {
      const activeFilters = [...staffFilters.accountState];
      return adminData.filter(admin => {
        return activeFilters.includes(admin.state as "active" | "disabled");
      });
    }
  }, [adminData, staffFilters.accountState]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadClientAdmin(source.token);
    return () => source.cancel();
  }, []);

  async function loadClientAdmin(token?: CancelToken) {
    if (adminData !== undefined) {
      setAdminData(undefined);
    }
    const res = await GetClientAdmin(null, token ? false : true, token);
    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading staff accounts.")); // TODO: Translation
    }

    setAdminData(res.status !== StatusCode.OK ? [] : res.data);
  }

  function handleApplyFilters(filterReturn: Record<string, any>[]) {
    const filterIds = filterReturn.map(filter => filter.id as "active" | "disabled");
    setStaffFilters({
      ...staffFilters,
      accountState: new Set([...filterIds]),
    });
  }

  const primaryAction = {
    content: "New Admin", // TODO: Translation
    action: () => history.push("/admin/settings/client-settings/staff/new"),
  };

  return (
    <Page
      title="Staff Accounts" // TODO: Translation
      subtitle="Manage who has access to the parent account" // TODO: Translation
      narrow
      primaryAction={primaryAction}
    >
      <div className="flex flex-row flex-wrap gap-4 pb-4">
        <DropFilter
          filterType="Checkbox"
          title="Account State" // TODO: Translation
          filterData={[
            { id: "active", label: "Active" }, // TODO: Translation
            { id: "disabled", label: "Disabled" }, // TODO: Translation
          ]}
          filterIdPropFromData="id"
          filterLabelPropFromData="label"
          applyFilters={filterReturn => handleApplyFilters(filterReturn)}
          defaultCheckboxes={[...staffFilters.accountState]}
          leftAligned
        />
      </div>
      <DataTable
        columns={[
          { label: "Name" }, // TODO: Translation
          { label: "Username" }, // TODO: Translation
          { label: "Email" }, // TODO: Translation
          { label: "" }, // active vs disabled
        ]}
        loading={adminData === undefined}
      >
        {filteredAdmins?.map(staff => (
          <tr
            key={staff.id}
            className="clickable"
            onClick={() => history.push(`/admin/settings/client-settings/staff/edit/${staff.id}`)}
          >
            <td>{staff.full_name}</td>
            <td>{staff.username}</td>
            <td>{staff.email}</td>
            <td>
              {staff.state === "active" || staff.state === null ? (
                <Badge
                  type="success"
                  size="medium"
                  iconLeft={<Icon icon="check" style="far" size="xsmall" />}
                  iconSize="small"
                  iconStyle="fas"
                >
                  Active {/* TODO: Translation */}
                </Badge>
              ) : null}
              {staff.state === "disabled" ? (
                <Badge
                  type="error"
                  size="medium"
                  iconLeft={<Icon icon="times" style="far" size="xsmall" />}
                  iconSize="small"
                  iconStyle="fas"
                >
                  Disabled {/* TODO: Translation */}
                </Badge>
              ) : null}
            </td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
