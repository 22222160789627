import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDrop } from "react-dnd";
import { IVariantCardItem } from "../variantCard/VariantCard";
import { VariantCardsItemTypes } from "../VariantCards";
import "./variantCardSlot.scss";

interface IVariantCardSlotProps {
  position: number;
  isPositionInASelectedRow: (position: number) => boolean;
  selectEmptyCard: (event: any, position: number) => void;
  onChangeVariantPosition: (id: number, position: number) => void;
}

export default function VariantCardSlot(props: IVariantCardSlotProps) {
  const { position, isPositionInASelectedRow, selectEmptyCard, onChangeVariantPosition } = props;

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: VariantCardsItemTypes.VARIANT_CARD,
      drop: (item: IVariantCardItem, monitor) => {
        onChangeVariantPosition(item.id, position);
      },
      collect: monitor => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [],
  );

  return (
    <div
      ref={drop}
      className="variant-card-container"
      style={{
        ...(isOver ? { border: "1px solid lightgreen", borderRadius: "3px" } : {}),
      }}
    >
      <div
        style={{ ...(isPositionInASelectedRow(position) ? { backgroundColor: "blue" } : {}) }}
        onClick={e => selectEmptyCard(e, position)}
        className="variant-card-slot"
      >
        <FontAwesomeIcon className="variant-card-slot-add-variant-icon" icon={["far", "plus"]} />
      </div>
    </div>
  );
}
