import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { UserLogin, UserActive, FetchCSRFCookie } from "api/rpc";
import { StatusCode } from "api/protocols";

import Form from "components/form/Form";
import TextField from "components/form/textField/TextField";
import { AdminPermission, UserType } from "redux/reducers/models/user";
import { IAuthState } from "redux/reducers/auth";
import { IAuthActions } from "redux/actions/auth";
import Input from "components/input/Input";
import { ButtonNew as Button } from "components/buttonNew/index";
import FacilityAdmin from "containers/facility/admin";
import { IUIActions } from "redux/actions/ui";

interface ILoginProps {
  authStore: IAuthState;
  authActions: IAuthActions;
  changeView: (registration: boolean) => void;
  uiActions: IUIActions;
}

interface ILoginState {
  email: string;
  password: string;
  status: string;
}

const Login: React.FC<ILoginProps> = props => {
  const { authStore, authActions, uiActions } = props;

  // clientadmin@clubhousegolf.ca
  // email: "facilityadmin@clubhousegolf.ca",
  // password: "clubhouse2015",

  const history = useHistory();

  const [loginState, setLoginState] = useState<ILoginState>({
    email: "",
    password: "",
    status: "",
  });

  async function handleLogin(e: any) {
    e.preventDefault();

    const fetchXSRFTokenRes = await FetchCSRFCookie(true);

    console.log("step 1", fetchXSRFTokenRes);

    if (fetchXSRFTokenRes.status !== 204) {
      setLoginState({ ...loginState, status: "Login error.. Fetch XSRF token failed" });
      return;
    }

    //TODO: handle these via the UI before sending:
    if (loginState.email == "" || loginState.password == "") {
      // covers all falsey cases? (null, undefined, ...)
      setLoginState({ ...loginState, status: "email/password empty" });
      return;
    }

    const loginRes = await UserLogin({ email: loginState.email, password: loginState.password }, true);

    console.log("step 2", loginRes);

    if (loginRes.status !== StatusCode.OK) {
      setLoginState({ ...loginState, status: `Error` });
      return;
    }

    if (loginRes.data.user.user_type === UserType.STANDARD_CUSTOMER) {
      uiActions.showError("User type not allowed");
      return;
    } else {
      const activeUserRes = await UserActive(true);

      console.log("step 3", activeUserRes.data);

      if (activeUserRes.status === StatusCode.OK) {
        authActions.userLogin(activeUserRes.data);

        authActions.initialize(true);

        history.push("/admin");
      } else {
        setLoginState({ ...loginState, status: `Error` });
      }
    }
  }

  type inputChangeEvent = <E extends HTMLInputElement>(e: React.ChangeEvent<E>) => void;

  const handleChange: inputChangeEvent = e => {
    setLoginState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className="flex flex-wrap p-4">
      <div className="login-container">
        <div className="login-content">
          <div className="login-content--header">
            <img className="login-logo" src="../../../public/images/Tee-On.png" alt="Tee-On Logo" />
            <p>
              <span>Login</span> to access your course admin
            </p>
          </div>
          <div className="login-content--form">
            <label>Email Address</label>
            <Input
              className="w-full mb-4"
              placeholder="Password"
              name="password"
              value={loginState.password}
              onChange={handleChange}
            />

            <Button type="primary" onClick={e => handleLogin(e)}>
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
