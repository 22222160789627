import Transactions from "./pages/Transactions";
import ScheduledPayments from "./pages/ScheduledPayments";
import DetailedSalesByDepartment from "./pages/DetailedSalesByDepartment";
import SalesByAccountingReference from "./pages/SalesByAccountingReference";
import InventoryListingByDepartment from "./pages/InventoryListingByDepartment";
import OrderExceptions from "./pages/OrderExceptions";
import TeeSheetUtilization from "./pages/TeeSheetUtilization";
import EmailActivity from "./pages/EmailActivity";
import GolfNorthDailySales from "./pages/GolfNorthDailySales";
import TicketOverview from "./pages/TicketOverview";
import AgedAccountsReceivable from "./pages/AgedAccountsReceivable";
import InventoryAdjustments from "./pages/InventoryAdjustments";
import GiftCardActivity from "./pages/GiftCardActivity";
import TicketsRedeemed from "./pages/TicketsRedeemed";
import Tickets from "./pages/Tickets";
import TicketsExpired from "./pages/TicketsExpired";
import RoundsByCustomerType from "./pages/RoundsByCustomerType";
import MembershipSales from "./pages/MembershipSales";
import NoShow from "./pages/NoShow";
import PlayerSpend from "./pages/PlayerSpend";
import SalesByDepartment from "./pages/SalesByDepartment";
import AccountsReceivable from "./pages/AccountsReceivable";
import Deposits from "./pages/Deposits";
import ItemSalesHistory from "./pages/ItemSalesHistory";
import Tournaments from "./pages/Tournaments";
import TournamentsNotInvoiced from "./pages/TournamentsNotInvoiced";
import Invoices from "./pages/Invoices";
import PrizeAccounts from "./pages/PrizeAccounts";
import DailySales from "./pages/DailySales";
import TeeSheetOverview from "./pages/TeeSheetOverview";
import HospitalityEventsNotInvoiced from "./pages/HospitalityEventsNotInvoiced";

const reportRoutes: { path: string; component: any }[] = [
  {
    path: "/admin/report/transactions",
    component: Transactions,
  },
  {
    path: "/admin/report/scheduled-payments",
    component: ScheduledPayments,
  },
  {
    path: "/admin/report/detailed-sales-by-department",
    component: DetailedSalesByDepartment,
  },
  {
    path: "/admin/report/sales-by-accounting-reference",
    component: SalesByAccountingReference,
  },
  {
    path: "/admin/report/inventory-listing-by-department",
    component: InventoryListingByDepartment,
  },
  {
    path: "/admin/report/order-exceptions",
    component: OrderExceptions,
  },
  {
    path: "/admin/report/tee-sheet-utilization",
    component: TeeSheetUtilization,
  },
  {
    path: "/admin/report/email-activity",
    component: EmailActivity,
  },
  {
    path: "/admin/report/golfnorth-daily-sales",
    component: GolfNorthDailySales,
  },
  {
    path: "/admin/report/ticket-overview",
    component: TicketOverview,
  },
  {
    path: "/admin/report/aged-accounts-receivable",
    component: AgedAccountsReceivable,
  },
  {
    path: "/admin/report/inventory-adjustments",
    component: InventoryAdjustments,
  },
  {
    path: "/admin/report/gift-card-activity",
    component: GiftCardActivity,
  },
  {
    path: "/admin/report/tickets-redeemed",
    component: TicketsRedeemed,
  },
  {
    path: "/admin/report/tickets",
    component: Tickets,
  },
  {
    path: "/admin/report/tickets-expired",
    component: TicketsExpired,
  },
  {
    path: "/admin/report/rounds-by-customer-type",
    component: RoundsByCustomerType,
  },
  {
    path: "/admin/report/membership-sales",
    component: MembershipSales,
  },
  {
    path: "/admin/report/tee-sheet-no-show",
    component: NoShow,
  },
  {
    path: "/admin/report/player-spend",
    component: PlayerSpend,
  },
  {
    path: "/admin/report/sales-by-department",
    component: SalesByDepartment,
  },
  {
    path: "/admin/report/accounts-receivable",
    component: AccountsReceivable,
  },
  {
    path: "/admin/report/deposits",
    component: Deposits,
  },
  {
    path: "/admin/report/item-sales-history",
    component: ItemSalesHistory,
  },
  {
    path: "/admin/report/tournaments",
    component: Tournaments,
  },
  {
    path: "/admin/report/events-not-invoiced",
    component: TournamentsNotInvoiced,
  },
  {
    path: "/admin/report/invoices",
    component: Invoices,
  },
  {
    path: "/admin/report/prize-accounts",
    component: PrizeAccounts,
  },
  {
    path: "/admin/report/daily-sales",
    component: DailySales,
  },
  {
    path: "/admin/report/tee-sheet-overview",
    component: TeeSheetOverview,
  },
  {
    path: "/admin/report/hospitality-events-not-invoiced",
    component: HospitalityEventsNotInvoiced,
  },
];

export default reportRoutes;
