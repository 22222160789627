import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDOM from "react-dom";

import {
  GetGiftCard,
  GetGiftCardHistory,
  IHistory,
  PrintGiftCard,
  PrintGiftCardLog,
  PutDisableGiftCard,
  PutGiftCard,
} from "api/rpc/2024-04/clientAdmin/payment/giftCard";
import { GetCustomer, PostCustomer } from "api/rpc/2022-09/clientAdmin/customer/customer";
import { GetOrder } from "api/rpc/2022-09/clientAdmin/order/order";
import { StatusCode } from "api/protocols";

import { capitalize, customerErrorMessage, uppercase, isEmpty } from "helpers/Helpers";
import { LocaleCurrency } from "helpers/Locale";

import { Badge } from "components/badge/Badge";
import Card from "components/card/Card";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Page from "components/page/Page";
import Sheet from "components/sheet/Sheet";
import { IUIActions } from "redux/actions/ui";
import { ICustomer } from "redux/reducers/models/customer";
import { Select } from "components/select/index";
import GolferCard from "components/bookingPopUp/golferCard/GolferCard";
import Input from "components/form/input";
import { IOrder } from "redux/reducers/models/order";
import Popup from "components/popup/Popup";
import NewCustomer, { ICustomerInfoState } from "components/newCustomer/NewCustomer";

interface IGiftCardState {
  giftCard: {
    admin_id: number;
    balance: number;
    client_id: number;
    currency: string;
    customer_id: number;
    disabled_at: string;
    expires_at: string;
    id: number;
    last4: string;
    line_item_id: number;
    note: string;
    order_id: number;
    pin: string;
    status: string;
    code: string;
  };
  customer: ICustomer;
  order: IOrder;
  history: Array<IHistory>;
  showChangeCustomerModal: boolean;
  customerQuery: string;
  selectedCustomer: ICustomer;
  customerSearching: boolean;
  customerSearchResult: Array<ICustomer>;
  showDisableModal: boolean;
}

interface INewCustomerState {
  showNewCustomerModal: boolean;
}

interface IProps {
  uiActions: IUIActions;
}

interface IInputField {
  isDirty: boolean;
  isValid: boolean;
}

export default function GiftCard(props: IProps) {
  const { uiActions } = props;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { giftCardId } = useParams<{ giftCardId: string }>();
  const { Option } = Select;
  const [state, setState] = useState<IGiftCardState>({
    giftCard: null,
    customer: null,
    order: null,
    history: [],
    showChangeCustomerModal: false,
    customerQuery: "",
    selectedCustomer: null,
    customerSearching: false,
    customerSearchResult: [],
    showDisableModal: false,
  });

  const [newCustomerState, setNewCustomerState] = useState<INewCustomerState>({
    showNewCustomerModal: false,
  });

  useEffect(() => {
    void loadGiftCard();
    // void loadHistory(state.giftCard.id);
  }, []);

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (async () => {
          if (state.customerQuery !== "") {
            try {
              setState(prevState => ({ ...prevState, customerSearching: true }));
              const customers = await searchCustomer();
              if (mounted) {
                setState(prevState => ({ ...prevState, customerSearchResult: customers, customerSearching: false }));
              }
            } catch (error) {
              console.log("err", error);
            }
            return;
          } else {
            setState(prevState => ({ ...prevState, customerSearchResult: [], customerSearching: false }));
          }
        })();
      }, 1000);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [state.customerQuery]);

  // function updateState(newState: Partial<IState>) {
  //   setState((cur: IState) => {
  //     return { ...cur, ...newState };
  //   });
  // }

  async function searchCustomer() {
    const customerRes = await GetCustomer({ search: state.customerQuery }, false);
    if (customerRes.status !== StatusCode.OK) {
      return [];
    }
    return customerRes.data;
  }

  async function loadGiftCard() {
    uiActions.enqueue();

    const giftCardRes = await GetGiftCard({ id: Number(giftCardId) }, false);
    if (giftCardRes.status !== StatusCode.OK || giftCardRes?.data?.length === 0) {
      uiActions.showError("Error getting gift cards");
      history.push("/admin/giftcards");
      uiActions.dequeue();
      return;
    }
    const giftCard = giftCardRes.data[0];

    if (giftCard?.customer_id) {
      await loadCustomer(giftCard?.customer_id);
    }

    if (giftCard?.order_id) {
      await loadOrder(giftCard?.order_id);
    }

    const historyRes = await loadHistory(giftCard.id);
    uiActions.dequeue();
    setState(prevState => ({ ...prevState, giftCard: giftCardRes.data[0] }));
  }

  async function loadHistory(gift_card_id: number) {
    const params = {
      gift_card_id: gift_card_id,
    };
    const historyRes = await GetGiftCardHistory(params, true);
    if (historyRes.status !== StatusCode.OK) {
      uiActions.showError("Error retrieving gift card transaction history");
      return;
    }
    const sortedHistory = historyRes?.data?.sort((prev, next) =>
      new Date(prev.updated_at) < new Date(next.updated_at)
        ? 1
        : new Date(prev.updated_at) > new Date(next.updated_at)
        ? -1
        : 0,
    );
    setState(prev => ({ ...prev, history: sortedHistory }));
  }

  async function loadCustomer(customer_id: number) {
    const customerRes = await GetCustomer({ id: customer_id }, false);
    if (customerRes.status !== StatusCode.OK) {
      uiActions.showError("Error getting customers");
      return;
    }
    setState(prevState => ({ ...prevState, customer: customerRes.data[0] }));
  }

  async function loadOrder(orderId: number) {
    const orderRes = await GetOrder({ id: orderId }, false);
    if (orderRes.status !== StatusCode.OK) {
      uiActions.showError("Error getting orders");
      return;
    }
    setState(prevState => ({ ...prevState, order: orderRes.data[0] }));
  }

  function closeChangeCustomerModal() {
    setState(prevState => ({
      ...prevState,
      showChangeCustomerModal: false,
      customerQuery: "",
      customerSearchResult: [],
      selectedCustomer: null,
    }));
  }

  function handleCustomerSearch(query: string) {
    setState(prevState => ({ ...prevState, customerQuery: query }));
  }

  function handleCustomerSelection(id: number, customer: ICustomer) {
    setState(prevState => ({ ...prevState, selectedCustomer: customer }));
  }

  function newCustomerInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setNewCustomerState(prevState => ({ ...prevState, [id]: value }));
  }

  async function createNewCustomer(customerInfo: ICustomerInfoState) {
    if (
      !customerInfo.firstName ||
      !customerInfo.lastName ||
      (!customerInfo.emailAddress && !customerInfo.phoneNumber)
    ) {
      uiActions.showError("First name, last name and email / phone required");
    }

    const customerRes = await PostCustomer(
      {
        first_name: customerInfo.firstName,
        last_name: customerInfo.lastName,
        phone: customerInfo.phoneNumber ?? null,
        email: customerInfo.emailAddress ?? null,
      },
      true,
    );

    if (customerRes.status !== StatusCode.OK) {
      uiActions.showError(customerErrorMessage(t, customerRes?.data?.message));
      return;
    }
    ReactDOM.unstable_batchedUpdates(() => {
      setNewCustomerState(prevState => ({
        ...prevState,
        showNewCustomerModal: false,
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
      }));
      setState(prevState => ({ ...prevState, selectedCustomer: customerRes.data }));
    });
    uiActions.showSuccess("Successfully created customer");
  }

  async function handleGiftCardPrint() {
    const printRes = await PrintGiftCard({ id: state.giftCard?.id }, true);
    if (printRes.status !== StatusCode.OK) {
      uiActions.showError("Error printing gift card");
      return;
    }
    window.open().document.write(printRes.data);
  }

  function navigateToOrder() {
    if (state.giftCard?.order_id) {
      history.push("/admin/order/" + String(state.giftCard?.order_id));
    }
  }

  function navigateToOrderTransaction(order_id: number) {
    history.push("/admin/order/" + String(order_id));
  }

  async function assignCustomer() {
    const updateGiftCardRes = await PutGiftCard(
      { id: Number(giftCardId), customer_id: state.selectedCustomer?.id, note: "hey" },
      true,
    );
    if (updateGiftCardRes.status !== StatusCode.OK) {
      uiActions.showError("Error assigning customer");
      return;
    }
    uiActions.showSuccess("Successfully assigned");
    void closeChangeCustomerModal();
    await loadGiftCard();
  }

  async function disableGiftCard() {
    const disableRes = await PutDisableGiftCard({ id: state?.giftCard?.id }, true);
    if (disableRes.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.order.gift_card.010"));
      return;
    }
    uiActions.showSuccess(t("secure.facility.order.gift_card.011"));
    setState(prevState => ({ ...prevState, showDisableModal: false }));
    await loadGiftCard();
  }

  function openNewCustomerSheet() {
    setNewCustomerState(prevState => ({
      ...prevState,
      showNewCustomerModal: true,
    }));
  }

  async function handleGiftCardLogPrint() {
    const printRes = await PrintGiftCardLog({ gift_card_id: state.giftCard?.id }, true);
    if (printRes.status !== StatusCode.OK) {
      uiActions.showError("Error printing gift card log");
      return;
    }
    window.open().document.write(printRes.data);
  }

  return (
    <>
      <Page
        narrow
        splitLayout
        title={t("secure.facility.order.gift_card.012")}
        breadcrumbs={[{ prefix: true, label: t("secure.facility.order.gift_card.015"), url: "/admin/giftcards" }]}
        multipleActionDropdownAction={{
          label: "Options",
          dropdownProps: {
            alignment: "right",
            options: [
              {
                type: "handler",
                label: t("secure.facility.order.gift_card.013"),
                icon: "print",
                handler: () => handleGiftCardPrint(),
                disabled: state?.giftCard?.status === "disabled" || state?.giftCard?.disabled_at ? true : false,
              },
              {
                type: "handler",
                label: "Print Log" /** TODO: Translations */,
                icon: "print-magnifying-glass",
                handler: () => handleGiftCardLogPrint(),
              },
              {
                type: "handler",
                label: t("secure.facility.order.gift_card.014"),
                icon: "ban",
                handler: () => setState(prevState => ({ ...prevState, showDisableModal: true })),
                disabled: state?.giftCard?.status === "disabled",
              },
              // {
              //   type: "handler",
              //   label: "Email" /** TODO: Translations */,
              //   icon: "envelope",
              //   handler: () =>
              //     updateEmailModal({ isOpen: true, emailInput: state.customer?.email }),
              //   disabled: state?.giftCard?.status === "disabled",
              // },
            ],
          },
        }}
      >
        {state?.giftCard && (
          <>
            <Page.Section twoThirds>
              {/* Gift Card Details Card*/}
              <Card title={t("secure.facility.order.gift_card.016")}>
                <Card.Section>
                  <div className="orders-giftcard">
                    <div className="giftcard-column">
                      <div className="giftcard-row">
                        <p className="giftcard-details-title">{t("secure.facility.order.gift_card.017")}</p>
                        <p className="giftcard-details-value">
                          <LocaleCurrency currency={state.giftCard?.currency} amount={state.giftCard?.balance} />
                        </p>
                      </div>
                      <div className="giftcard-row">
                        <p className="giftcard-details-title">{t("secure.facility.order.gift_card.018")}</p>
                        <p className="giftcard-details-value">{uppercase(state.giftCard?.currency)}</p>
                      </div>
                      <div className="giftcard-row">
                        <p className="giftcard-details-title">{t("secure.facility.order.gift_card.019")}</p>
                        <Badge size="small" type="success">
                          {capitalize(state.giftCard?.status)}
                        </Badge>
                      </div>
                      {state?.giftCard?.disabled_at && (
                        <div className="giftcard-row">
                          <p className="giftcard-details-title">{t("secure.facility.order.gift_card.020")}</p>
                          <p className="giftcard-details-value">
                            {moment.utc(state?.giftCard?.disabled_at).local().format("LLL")}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </Card.Section>
              </Card>

              <Card title="Recent Transactions">
                {/* Transaction Card */}
                <div className="transaction-history">
                  {state.history?.map((transaction: IHistory, index: number) => {
                    let title = "";
                    switch (transaction.log_type) {
                      case "print":
                        title = "Receipt printed for gift card";
                        break;
                      case "merged_to":
                        title = transaction.log_description;
                        break;
                      case "merged_from":
                        title = transaction.log_description;
                        break;
                      case "disabled":
                        title = "Gift card was disabled";
                        break;
                      default:
                        title = `Order # ${transaction?.transaction?.order?.number ?? ""}`;
                        break;
                    }
                    return (
                      <Card.Section
                        title={title}
                        key={index}
                        sectionTitleActions={
                          transaction?.log_type === "credit" || transaction?.log_type === "debit"
                            ? [
                                {
                                  action: () => navigateToOrderTransaction(transaction?.transaction?.order_id),
                                  content: t("secure.facility.order.gift_card.024"),
                                },
                              ]
                            : []
                        }
                      >
                        <div className="column">
                          {transaction?.log_type !== "print" && transaction?.log_type !== "disabled" && (
                            <span
                              className={classNames("column-data", {
                                "transaction-debit": transaction?.log_type === "debit",
                                "transaction-credit": transaction?.log_type === "credit",
                              })}
                            >
                              {transaction?.log_type === "debit" ? "-" : transaction?.log_type === "credit" ? "+" : ""}
                              <LocaleCurrency currency="cad" amount={transaction?.change ?? ""} />
                            </span>
                          )}
                          <span> {moment(transaction?.updated_at).format("LLL")}</span>
                        </div>
                        <span className="thin-separator"></span>
                      </Card.Section>
                    );
                  })}
                </div>

                {/* End Transaction Card*/}
              </Card>

              {/* End Gift Card Details Card*/}
            </Page.Section>

            <Page.Section oneThird>
              <Card
                title={t("secure.facility.order.gift_card.021")}
                titleActions={
                  state?.giftCard?.status === "disabled" || state?.giftCard?.disabled_at
                    ? undefined
                    : [
                        {
                          action: () => setState(prevState => ({ ...prevState, showChangeCustomerModal: true })),
                          content: t("secure.facility.order.gift_card.022"),
                        },
                      ]
                }
              >
                {/* Customer Card */}
                <Card.Section>
                  {state.customer ? (
                    <div>
                      <p className="text-lg">{state?.customer?.full_name}</p>
                      <p className="text-lg">{state?.customer?.email}</p>
                      <p className="text-lg">{state?.customer?.phone}</p>
                      <p className="text-lg">{state?.customer?.customer_type}</p>
                    </div>
                  ) : (
                    <p>{t("secure.facility.order.gift_card.023")}</p>
                  )}
                </Card.Section>
                {/* End Customer Card */}
              </Card>

              {state?.order && (
                <Card
                  title={`${t("secure.facility.order.gift_card.041")} ${state.order?.name}`}
                  titleActions={[
                    {
                      action: navigateToOrder,
                      content: t("secure.facility.order.gift_card.024"),
                    },
                  ]}
                >
                  {/* Gift Card Order Card */}
                  <Card.Section>
                    <div className="orders-giftcard">
                      <div className="giftcard-column">
                        <div className="giftcard-row">
                          <p className="giftcard-details-title">{t("secure.facility.order.gift_card.025")}</p>
                          <Badge size="small" type="success">
                            {capitalize(state?.order?.financial_status)}
                          </Badge>
                        </div>
                        <div className="giftcard-row">
                          <p className="giftcard-details-title">{t("secure.facility.order.gift_card.026")}</p>
                          <LocaleCurrency currency={state?.order?.currency} amount={state?.order?.total_price} />
                        </div>
                      </div>
                    </div>
                  </Card.Section>
                  {/* Gift Card Order Card End */}
                </Card>
              )}
            </Page.Section>
          </>
        )}
      </Page>

      {/* Golfer Card */}
      <div className="orders-giftcard-container-overflow">
        <Sheet
          title={t("secure.facility.order.gift_card.027")}
          open={state.showChangeCustomerModal}
          size="small"
          closable
          onCancel={closeChangeCustomerModal}
          onOk={assignCustomer}
          okText={t("secure.facility.order.gift_card.028")}
          okDisabled={!state.selectedCustomer ? true : false}
        >
          {state.customer !== null ? (
            <div>
              <span>{t("secure.facility.order.gift_card.029")}</span>
              <GolferCard
                email={state.customer?.email}
                name={state.customer?.full_name}
                memberCode={state.customer?.member_code}
                customerType={state.customer?.customer_type}
                phone={state.customer?.phone}
              />

              <FontAwesomeIcon className="orders-giftcard-arrow-icon" icon={["far", "arrow-circle-down"]} size="2x" />
            </div>
          ) : null}
          {state.selectedCustomer ? (
            <GolferCard
              closable
              removeGolfer={() =>
                setState(prevState => ({
                  ...prevState,
                  selectedCustomer: null,
                  customerSearchResult: [],
                  customerQuery: "",
                }))
              }
              email={state?.selectedCustomer?.email}
              name={state?.selectedCustomer?.full_name}
              memberCode={state?.selectedCustomer?.member_code}
              customerType={state?.selectedCustomer?.customer_type}
              phone={state?.selectedCustomer?.phone}
            />
          ) : (
            <Select
              showSearch
              onSearch={(query: string) => handleCustomerSearch(query)}
              onChange={(id: number, customer: ICustomer) => handleCustomerSelection(id, customer)}
              placeholder={t("secure.facility.order.gift_card.030")}
              allowClear
              searchValue={state.customerQuery}
              showDropDownOnFocus={true}
              searching={state.customerSearching}
            >
              <div className="ui-select-dropdown-list-item" onClick={() => openNewCustomerSheet()}>
                <p>{t("secure.facility.order.gift_card.031")}</p>
              </div>
              {state.customerSearchResult?.map((customer, index) => {
                return (
                  <Option key={index} value={customer.id} name={customer.full_name} extraValues={customer}>
                    <div className="flex justify-between">
                      <div>
                        <div className="text-semibold text-lg">{customer?.full_name}</div>
                        <div className="text-sm text-gray-500">{customer.customer_type}</div>
                        <div className="text-sm text-gray-500">{customer.email}</div>
                        <div className="text-sm text-gray-500">{customer.phone ? customer.phone : null}</div>
                      </div>

                      <div className="font-medium text-base text-gray-500 self-end">{customer.member_code}</div>
                    </div>
                  </Option>
                );
              })}
            </Select>
          )}
        </Sheet>

        <NewCustomer
          newCustomerSheetActive={newCustomerState.showNewCustomerModal}
          onCancel={() =>
            setNewCustomerState(prevState => ({
              ...prevState,
              showNewCustomerModal: false,
            }))
          }
          onOk={createNewCustomer}
          searchValue={state.customerQuery}
        />
      </div>
      {/* End Golfer Card */}

      <Popup
        type="warning"
        open={state.showDisableModal}
        title={t("secure.facility.order.gift_card.038")}
        description={t("secure.facility.order.gift_card.039")}
        closable
        onOk={disableGiftCard}
        onCancel={() => setState(prevState => ({ ...prevState, showDisableModal: false }))}
        okText={t("secure.facility.order.gift_card.040")}
      />
    </>
  );
}
