import {
  DeleteLineItemToCart,
  GetCart,
  PostCart,
  PostLineItemToCart,
  PutLineItemToCart,
  PutCart,
  IPutCart,
} from "api/rpc/2024-04/guest/cart";

import { StatusCode } from "api/protocols";
import { ICart } from "redux/reducers/customer/models/cart";

interface ILoadCartAction {
  tokenName: string;
  id?: number;
  token?: string;
  facility_id?: number;
}

interface IPostCartAction {
  tokenName: string;
  facility_id: number;
}

interface ICartClear {
  tokenName: string;
}

export function loadCart(params: ILoadCartAction, useGlobalLoader: boolean): (dispatch: any) => Promise<ICart> {
  return async (dispatch: any) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      GetCart(
        { id: params.id, token: params.token, facility_id: params.facility_id, sales_channel: "online_store" },
        useGlobalLoader,
      ).then(res => {
        if (res.status !== StatusCode.OK) {
          reject(undefined);
        } else {
          const cart = res.data;
          localStorage.setItem(params.tokenName, cart.token);

          dispatch({
            type: "cart.update",
            payload: {
              isLoaded: true,
              cart: cart,
            },
          });

          resolve(cart);
        }
      });
    });
  };
}

export function postCart(params: IPostCartAction, useGlobalLoader: boolean): (dispatch: any) => Promise<ICart> {
  return async (dispatch: any) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      PostCart({ facility_id: params.facility_id, sales_channel: "online_store" }, useGlobalLoader).then(res => {
        if (res.status !== StatusCode.OK) {
          reject(undefined);
        } else {
          const cart = res.data;
          localStorage.setItem(params.tokenName, cart.token);

          dispatch({
            type: "cart.update",
            payload: {
              isLoaded: true,
              cart: cart,
            },
          });

          resolve(cart);
        }
      });
    });
  };
}

export function postLineItem(
  cartToken: string,
  variantId: number,
  quantity: number,
  useGlobalLoader: boolean,
  tournamentRegistrationFeeId?: number,
  leagueRegistrationFeeId?: number,
): (dispatch: any) => Promise<ICart> {
  const params = {
    cart_token: cartToken,
    variant_id: variantId,
    quantity: quantity,
    tournament_registration_fee_id: tournamentRegistrationFeeId,
    league_registration_fee_id: leagueRegistrationFeeId,
  };

  return async (dispatch: any) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      PostLineItemToCart(params, useGlobalLoader).then(res => {
        if (res.status !== StatusCode.OK) {
          reject(undefined);
        } else {
          dispatch({
            type: "cart.update",
            payload: {
              isLoaded: true,
              cart: res.data,
            },
          });

          resolve(res.data);
        }
      });
    });
  };
}

export function putCart(params: IPutCart, useGlobalLoader: boolean): (dispatch: any) => Promise<ICart> {
  return async (dispatch: any) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      PutCart(params, useGlobalLoader).then(res => {
        if (res.status !== StatusCode.OK) {
          reject(undefined);
        } else {
          dispatch({
            type: "cart.update",
            payload: {
              isLoaded: true,
              cart: res.data,
            },
          });

          resolve(res.data);
        }
      });
    });
  };
}

export function putLineItem(
  lineItemId: number,
  cartToken: string,
  quantity: number,
  price: number,
  useGlobalLoader: boolean,
) {
  const params = {
    id: lineItemId,
    cart_token: cartToken,
    quantity: quantity,
    price: price,
  };

  return async (dispatch: any) => {
    const res = await PutLineItemToCart(params, useGlobalLoader);
    if (res.status !== StatusCode.OK) {
      return;
    }

    const cart = res.data;

    dispatch({
      type: "cart.update",
      payload: {
        isLoaded: true,
        cart: cart,
      },
    });
  };
}

export function deleteLineItem(
  lineItemId: number,
  cartToken: string,
  useGlobalLoader: boolean,
): (dispatch: any) => Promise<ICart> {
  const params = {
    id: lineItemId,
    cart_token: cartToken,
  };

  return async (dispatch: any) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      DeleteLineItemToCart(params, useGlobalLoader).then(res => {
        if (res.status !== StatusCode.OK) {
          reject(undefined);
        } else {
          const cart = res.data;

          dispatch({
            type: "cart.update",
            payload: {
              isLoaded: true,
              cart: cart,
            },
          });

          resolve(cart);
        }
      });
    });
  };
}

export function cartClear(params: ICartClear) {
  return (dispatch: any) => {
    localStorage.removeItem(params.tokenName);

    dispatch({
      type: "cart.clear",
      payload: {
        isLoaded: false,
        cart: null,
      },
    });
  };
}

export interface ICartActions {
  loadCart: typeof loadCart;
  postCart: typeof postCart;
  postLineItem: typeof postLineItem;
  putCart: typeof putCart;
  putLineItem: typeof putLineItem;
  deleteLineItem: typeof deleteLineItem;
  cartClear: typeof cartClear;
}
