import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetCustomer } from "api/rpc/2024-04/masterAdmin/customer/customer";
import { showError, showSuccess } from "redux/actions/ui";

import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import Card from "components/card/Card";
import NewCustomer, { ICustomerInfoState } from "components/newCustomer/NewCustomer";
import Search from "components/search/Search";
import MasterCustomerTable from "../MasterCustomerTable";

import "elements/customer/customer.scss";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { MOBILE_WIDTH } from "helpers/ScreenSizes";
import { ButtonNew as Button } from "components/buttonNew";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { customerErrorMessage } from "helpers/Helpers";

interface IFilterState {
  search: string;
  limit: number;
  offset: number;
}

interface INewCustomerModal {
  active: boolean;
}

export default function Customers() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const windowSize = useWindowSize();

  const [customers, setCustomers] = useState([]);
  const [filter, setFilter] = useState<IFilterState>({
    search: "",
    limit: 50,
    offset: 0,
  });

  const [newCustomerState, setNewCustomerState] = useState<INewCustomerModal>({
    active: false,
  });

  // Search Products
  useEffect(() => {
    const source = axios.CancelToken.source();

    void loadCustomers(source.token);

    return () => {
      source.cancel();
    };
  }, [filter.search, filter.offset]);

  async function loadCustomers(cancelToken?: CancelToken) {
    const customerRes = await GetCustomer(
      {
        "default-address-line": true,
        limit: filter.limit,
        offset: filter.offset,
        search: filter.search,
      },
      true,
    );

    if (customerRes.status !== StatusCode.OK) {
      return;
    }

    setCustomers(customerRes.data);
  }

  function navigateToCustomerProfile(customerId: number) {
    history.push("/admin/customers/" + String(customerId) + "/profile");
  }

  function createCustomer(customerInfo: ICustomerInfoState) {
    /*const error =
      customerInfo.firstName === "" ||
      customerInfo.lastName === "" ||
      (customerInfo.emailAddress === "" && customerInfo.phoneNumber === "");

    if (error) {
      return;
    }

    const customerRes = await PostCustomer(
      {
        first_name: customerInfo.firstName,
        last_name: customerInfo.lastName,
        phone: customerInfo.phoneNumber || null,
        email: customerInfo.emailAddress || null,
      },
      true,
    );

    if (customerRes.status !== StatusCode.OK) {
      dispatch(showError(customerErrorMessage(t, customerRes?.data?.message)));
      return;
    }

    dispatch(showSuccess(t("secure.facility.customer.main.customers.011")));
    closeNewCustomerSheet();
    void loadCustomers(); */
  }

  function closeNewCustomerSheet() {
    setNewCustomerState(prev => ({
      ...prev,
      active: false,
    }));
  }

  function handleTableNavigation(direction: "prev" | "next") {
    switch (direction) {
      case "prev": {
        setFilter(prev => ({ ...prev, offset: filter.offset - filter.limit }));
        break;
      }
      case "next": {
        setFilter(prev => ({ ...prev, offset: filter.offset + filter.limit }));
        break;
      }
      default:
        return;
    }
  }

  const addCustomerAction = {
    content: (
      <div>
        <FontAwesomeIcon className="mr-2" icon={["far", "plus"]} />
        {"Add User"}
      </div>
    ),
    action: () => setNewCustomerState(prev => ({ ...prev, active: true })),
  };

  return (
    <>
      <Page title={"Users"} primaryAction={windowSize.width > MOBILE_WIDTH ? addCustomerAction : undefined}>
        <div className="flex-grow mb-3.5">
          <Search
            placeholder={t("secure.facility.customer.main.customers.003")}
            historyKey={"search_customers_table"}
            searchCallback={searchValue => setFilter(prev => ({ ...prev, search: searchValue }))}
          />
        </div>
        <div className="customers-add-customer-actions-mobile">
          <Button type="primary" size="medium" onClick={addCustomerAction.action}>
            {addCustomerAction.content}
          </Button>
        </div>

        <MasterCustomerTable
          customers={customers}
          onClick={(customer: Record<string, any>) => navigateToCustomerProfile(customer.id)}
          tableLimit={filter.limit}
          tableOffset={filter.offset}
          handlePagination={direction => handleTableNavigation(direction)}
        />
      </Page>

      <NewCustomer
        newCustomerSheetActive={newCustomerState.active}
        onCancel={() =>
          setNewCustomerState(prev => ({
            ...prev,
            active: false,
          }))
        }
        onOk={createCustomer}
        searchValue={filter.search}
      />
    </>
  );
}
