export function filterHistoryCreate(groupKey: string) {
  return (dispatch: any) => {
    dispatch({
      type: "history.create",
      payload: {
        groupKey: groupKey,
        value: [],
      },
    });
  };
}

export function filterHistoryClear(groupKey: string) {
  return (dispatch: any) => {
    dispatch({
      type: "history.clear",
      payload: {
        groupKey: groupKey,
        value: [],
      },
    });
  };
}

export function filterHistoryAddItem(groupKey: string, filterValue: any) {
  return (dispatch: any) => {
    dispatch({
      type: "history.add",
      payload: {
        groupKey: groupKey,
        value: filterValue,
      },
    });
  };
}

export function filterHistoryRemoveItem(groupKey: string, filterValue: any) {
  return (dispatch: any) => {
    dispatch({
      type: "history.remove",
      payload: {
        groupKey: groupKey,
        value: filterValue,
      },
    });
  };
}

export function filterHistoryUpdateFilter(groupKey: string, filterValue: any) {
  return (dispatch: any) => {
    dispatch({
      type: "history.update",
      payload: {
        groupKey: groupKey,
        value: filterValue,
      },
    });
  };
}

export interface IFilterHistoryActions {
  filterHistoryCreate: typeof filterHistoryCreate;
  filterHistoryClear: typeof filterHistoryClear;
  filterHistoryAddItem: typeof filterHistoryAddItem;
  filterHistoryRemoveItem: typeof filterHistoryRemoveItem;
  filterHistoryUpdateFilter: typeof filterHistoryUpdateFilter;
}
