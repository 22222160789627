import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./folders.scss";
import { GetFolder, IFolder, PutFolder } from "api/rpc/2022-09/facilityAdmin/product/folder";
import Page from "components/page/Page";
import Card from "components/card/Card";
import { StatusCode } from "api/protocols";
import { IUIActions } from "redux/actions/ui";
import { useTranslation } from "react-i18next";
import { groupBy, isEqual } from "lodash";
import { DragDropContext, Draggable, Droppable, DropResult, ResponderProvided } from "react-beautiful-dnd";
import Toggle from "components/form/toggle/Toggle";
import { NotificationType } from "components/notificationBar/NotificationBar";

interface IFoldersEditProps {
  uiActions: IUIActions;
}

interface IRegisterGroupEdit {
  id: number;
  title: string;
  folders: IFolder[];
}

interface IFolderState {
  registerGroups: IRegisterGroupEdit[];
  registerGroupsBeforeChanges: IRegisterGroupEdit[];
  dragAndDropEnabled: boolean;
}

export const PRODUCT_FOLDERS_KEY = "product_folders";

export default function Folders(props: IFoldersEditProps) {
  const { uiActions } = props;

  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [folderState, setFolderState] = useState<IFolderState>({
    registerGroups: [],
    registerGroupsBeforeChanges: undefined,
    dragAndDropEnabled: false,
  });

  useEffect(() => {
    void loadFolders();
  }, []);

  function foldersToRegisterGroups(folders: IFolder[]) {
    const foldersByRegisterGroup = groupBy(folders, folder => folder.register_group_id);

    const registerGroups: IRegisterGroupEdit[] = Object.keys(foldersByRegisterGroup)
      .filter(key => !isNaN(Number(key)))
      .map(key => {
        const id = Number(key);

        return {
          id: id,
          title: foldersByRegisterGroup[key][0].register_group_title,
          folders: foldersByRegisterGroup[key].sort(
            (prevFolder, nextFolder) => prevFolder.position - nextFolder.position,
          ),
        };
      })
      .sort((prevRegisterGroup, nextRegisterGroup) => {
        if (prevRegisterGroup.title < nextRegisterGroup.title) {
          return -1;
        } else if (prevRegisterGroup.title > nextRegisterGroup.title) {
          return 1;
        } else {
          return 0;
        }
      });

    setFolderState(prev => ({ ...prev, registerGroups, registerGroupsBeforeChanges: registerGroups }));
  }

  async function loadFolders() {
    const res = await GetFolder(null, true);

    if (res.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.settings.folders.folders.007"));
      return;
    }

    const folders = res.data;

    foldersToRegisterGroups(folders);
  }

  function handleOnDragEnd(result: DropResult, provided: ResponderProvided) {
    if (!result.destination?.droppableId) {
      return;
    } else if (result.source.droppableId !== result.destination.droppableId) {
      const sourceRegisterGroupIndex = Number(result.source.droppableId);
      const destinationRegisterGroupIndex = Number(result.destination.droppableId);
      const draggableId = Number(result.draggableId);
      const updatedPosition = result.destination.index;

      const sourceRegisterGroupFolders = folderState.registerGroups[sourceRegisterGroupIndex].folders;
      const destinationRegisterGroupFolders = folderState.registerGroups[destinationRegisterGroupIndex].folders;
      const destinationRegisterGroup = folderState.registerGroups[destinationRegisterGroupIndex];
      const draggingFolder = sourceRegisterGroupFolders.find(folder => folder.id === draggableId);
      const notDraggingSourceFolders = sourceRegisterGroupFolders.filter(folder => folder.id !== draggableId);

      const destinationFolderIndexAtUpdatedPosition = updatedPosition - 1;

      const updatedDestinationRegisterGroupFolders: IFolder[] = [];

      const destinationFoldersBeforeUpdatedPosition: IFolder[] = [...destinationRegisterGroupFolders]
        .splice(0, destinationFolderIndexAtUpdatedPosition)
        .map((folder, index) => ({ ...folder, position: index + 1 }));

      updatedDestinationRegisterGroupFolders.push(...destinationFoldersBeforeUpdatedPosition);

      updatedDestinationRegisterGroupFolders.push({
        ...draggingFolder,
        register_group_id: destinationRegisterGroup.id,
        register_group_title: destinationRegisterGroup.title,
        position: updatedDestinationRegisterGroupFolders.length + 1,
      });

      const destinationFoldersAfterUpdatedPosition: IFolder[] = [...destinationRegisterGroupFolders]
        .splice(destinationFolderIndexAtUpdatedPosition)
        .map((folder, index) => ({ ...folder, position: updatedDestinationRegisterGroupFolders.length + index + 1 }));

      updatedDestinationRegisterGroupFolders.push(...destinationFoldersAfterUpdatedPosition);

      setFolderState(prev => ({
        ...prev,
        registerGroups: prev.registerGroups.map((registerGroup, index) => {
          if (index === sourceRegisterGroupIndex) {
            return {
              ...registerGroup,
              folders: notDraggingSourceFolders.map((folder, index) => ({ ...folder, position: index + 1 })),
            };
          } else if (index === destinationRegisterGroupIndex) {
            return {
              ...registerGroup,
              folders: updatedDestinationRegisterGroupFolders,
            };
          } else {
            return registerGroup;
          }
        }),
      }));
    } else if (result.source.droppableId === result.destination.droppableId) {
      const registerGroupIndex = Number(result.destination.droppableId);
      const draggableId = Number(result.draggableId);
      const updatedPosition = result.destination.index;

      const existingRegisterGroupFolders = folderState.registerGroups[registerGroupIndex].folders;
      const draggingFolder = existingRegisterGroupFolders.find(folder => folder.id === draggableId);
      const notDraggingFolders = existingRegisterGroupFolders.filter(folder => folder.id !== draggableId);

      if (draggingFolder.position === updatedPosition) {
        return;
      }

      const existingFolderIndexAtUpdatedPosition = updatedPosition - 1;

      const updatedRegisterGroupFolders: IFolder[] = [];

      const foldersBeforeUpdatedPosition: IFolder[] = [...notDraggingFolders]
        .splice(0, existingFolderIndexAtUpdatedPosition)
        .map((folder, index) => ({ ...folder, position: index + 1 }));

      updatedRegisterGroupFolders.push(...foldersBeforeUpdatedPosition);

      updatedRegisterGroupFolders.push({ ...draggingFolder, position: updatedRegisterGroupFolders.length + 1 });

      const foldersAfterUpdatedPosition: IFolder[] = [...notDraggingFolders]
        .splice(existingFolderIndexAtUpdatedPosition)
        .map((folder, index) => ({ ...folder, position: updatedRegisterGroupFolders.length + index + 1 }));

      updatedRegisterGroupFolders.push(...foldersAfterUpdatedPosition);

      setFolderState(prev => ({
        ...prev,
        registerGroups: prev.registerGroups.map((registerGroup, index) => {
          if (index === registerGroupIndex) {
            return {
              ...registerGroup,
              folders: updatedRegisterGroupFolders,
            };
          } else {
            return registerGroup;
          }
        }),
      }));
    }
  }

  function determineRegisterGroupDroppableStyle(snapshot: any) {
    if (!folderState.dragAndDropEnabled) {
      return undefined;
    } else if (snapshot.isDraggingOver) {
      return "lightgreen";
    } else {
      return undefined;
    }
  }

  function unsavedChangesExist() {
    if (folderState.registerGroupsBeforeChanges === undefined) {
      return false;
    }

    return !isEqual(folderState.registerGroups, folderState.registerGroupsBeforeChanges);
  }

  function cancelUnsavedChanges() {
    setFolderState(prev => ({ ...prev, registerGroups: folderState.registerGroupsBeforeChanges }));
  }

  async function saveFolders() {
    const folders = folderState.registerGroups
      .map(registerGroup =>
        registerGroup.folders.map(folder => ({
          id: folder.id,
          position: folder.position,
          register_group_id: folder.register_group_id,
        })),
      )
      .reduce((prev, next) => [...prev, ...next], []);

    const putFoldersResponse = await PutFolder({ folders }, true);

    if (putFoldersResponse.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.settings.folders.folders.008"));
      return;
    }

    if (Array.isArray(putFoldersResponse.data)) {
      foldersToRegisterGroups(putFoldersResponse.data);
    }
  }

  function navigateToNewFolder() {
    history.push("/admin/settings/folders/new");
  }

  function navigateToEditFolder(url: string) {
    history.push(url);
  }

  function refreshFolderStorage() {
    localStorage.removeItem(PRODUCT_FOLDERS_KEY);
    uiActions.showSuccess(t("secure.facility.settings.folders.folders.006"));
  }

  const primaryAction = {
    content: t("secure.facility.settings.folders.folders.001"),
    action: navigateToNewFolder,
  };

  const refreshFolderStorageAction = {
    content: t("secure.facility.settings.folders.folders.005"),
    action: refreshFolderStorage,
  };

  return (
    <Page
      title={t("secure.facility.settings.folders.folders.002")}
      narrow
      primaryAction={primaryAction}
      secondaryActions={[refreshFolderStorageAction]}
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: saveFolders,
        onCancel: cancelUnsavedChanges,
      }}
    >
      <div className="mb-3">
        <Toggle
          label={t("secure.facility.settings.folders.folders.010")}
          checked={folderState.dragAndDropEnabled}
          onChange={() => setFolderState(prev => ({ ...prev, dragAndDropEnabled: !prev.dragAndDropEnabled }))}
        />
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        {folderState.registerGroups?.map((registerGroup, index) => {
          return (
            <Card key={index}>
              <Card.Section title={registerGroup.title}>
                <Droppable droppableId={String(index)} isDropDisabled={!folderState.dragAndDropEnabled}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      className="register-group-folders"
                      style={{ backgroundColor: determineRegisterGroupDroppableStyle(snapshot) }}
                      {...provided.droppableProps}
                    >
                      {registerGroup.folders
                        ?.sort((prev, next) => prev.position - next.position)
                        ?.map(folder => {
                          return (
                            <Draggable key={folder.id} draggableId={String(folder.id)} index={folder.position}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  className={`register-group-folder${
                                    folderState.dragAndDropEnabled ? "" : "-editable"
                                  }`}
                                  onClick={
                                    folderState.dragAndDropEnabled
                                      ? () => {}
                                      : () => navigateToEditFolder("/admin/settings/folders/" + String(folder.id))
                                  }
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {folder.title}
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Card.Section>
            </Card>
          );
        })}
      </DragDropContext>
    </Page>
  );
}
