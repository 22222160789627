import React, { useEffect, useState, useCallback } from "react";
import { useHistory, Link } from "react-router-dom";
import { useParams } from "react-router";
import { displayCurrency, roundNumber, displayPercent } from "helpers/Helpers";

import { GetProduct, PutProduct, GetProductTypes, GetInventoryLevels } from "api/rpc/clientAdmin/product/products";
import { GetVendor } from "api/rpc/vendor";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import { ButtonNew as Button } from "components/buttonNew";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import TextField from "components/form/textField/TextField";
import Select from "components/form/select/Select";
import Checkbox from "components/form/checkbox/Checkbox";

import { Select as SelectNew } from "components/select/";

import ResourceList from "components/resourceList/ResourceList";
import ResourceItem from "components/resourceList/ResourceItem";
import TextStyle from "components/text/textStyle/TextStyle";
import Stack from "components/stack/Stack";
import Sheet from "components/sheet/Sheet";
import { faLoveseat } from "@fortawesome/pro-light-svg-icons";
import "pages/secure/facility/product/Product.scss";
import { List, isEqualWith, isNull } from "lodash";
import ReactDOM from "react-dom";
import { NotificationType } from "components/notificationBar/NotificationBar";
import { GetMembershipAddOns, UpdateMembershipAddOn } from "api/rpc/clientAdmin/membership";

interface IMembershipAddOnState {
  id: number;
  title: string;
}

interface IProductState {
  id: number;
  title: string;
  description: string;
  price: number;
  original_price: number;
  cost: number;
  taxable: boolean;
  type: string;
  accounting_reference: string;
  customer_required: boolean;
  shipping_required: boolean;
  fulfillment_required: boolean;
  vendor_id: number;
  vendor_title: string;
  department: string;
  category: string;
  subcategory: string;
  account_type: string;
  editing: boolean;
  variant_count: number;
  variants: any;
  sku: string;
  barcode: string;
  tracked: boolean;
  inventory_levels: any;
  department_id: number;
  category_id: number;
  subcategory_id: number;
  accounting_reference_id: number;
  membership_type: string;
  meta: any;
}

export default function Single(props: any) {
  const { Option } = SelectNew;
  const history = useHistory();
  const { membershipAddOnId }: any = useParams();

  const [productState, setProductState] = useState<IProductState>({
    id: null,
    title: "",
    type: "",
    description: "",
    variant_count: null,
    variants: null,
    price: null,
    original_price: null,
    cost: null,
    taxable: false,
    customer_required: false,
    shipping_required: false,
    fulfillment_required: false,
    vendor_id: null,
    vendor_title: "",
    department: "",
    category: "",
    subcategory: "",
    account_type: "",
    editing: false,
    sku: "",
    barcode: "",
    tracked: false,
    inventory_levels: null,
    department_id: null,
    category_id: null,
    subcategory_id: null,
    accounting_reference_id: null,
    accounting_reference: "",
    membership_type: "",
    meta: {
      membership_type: "",
    },
  });

  const [membershipStateBeforeChanges, setMembershipStateBeforeChanges] = useState<IMembershipAddOnState>(undefined);
  const [membershipLoaded, setMembershipLoaded] = useState<boolean>(false);
  const [inventoryLevelLoaded, setInventoryLevelLoaded] = useState<boolean>(false);

  const [productOptionState, setProductOptionState] = useState({
    types: [],
    vendors: [],
    accountingReference: [],
    departments: [],
  });

  const [membershipAddOnState, setMembershipAddOnState] = useState({
    id: null,
    title: "",
  });

  useEffect(() => {
    void loadMembershipAddOn();
  }, []);

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setMembershipAddOnState(prevState => ({ ...prevState, [id]: value }));
  }

  async function loadMembershipAddOn() {
    const membershipAddOnRes = await GetMembershipAddOns({ id: membershipAddOnId }, true);

    console.log(membershipAddOnRes);

    if (membershipAddOnRes.status !== StatusCode.OK) {
      return;
    }

    const productRes = await GetProduct(
      { id: membershipAddOnRes.data[0].product_id, extended: true, extended_variants: true, inventory: true },
      true,
    );

    if (productRes.status !== StatusCode.OK) {
      return;
    }

    console.log(productRes);

    const product = productRes.data[0];
    const variant = product.variants[0];
    const membershipAddOn = membershipAddOnRes.data[0];

    const variantCountIsOne = product.variant_count == 1;
    if (variantCountIsOne) {
      void loadInventoryLevels(variant.id);
    }

    // console.log("Product:", productRes.data[0]);

    // const tempTicketList: ITicket[] = [];

    // const tempTicketMap = new Map();

    // console.log(product);

    ReactDOM.unstable_batchedUpdates(() => {
      setMembershipAddOnState(prev => ({
        ...prev,
        id: membershipAddOn.id,
        title: membershipAddOn.title,
      }));

      //   setProductState((prev) => ({
      //     ...prev,
      //     id: product.id,
      //     title: product.title,
      //     description: product.description,
      //     type: product.type,
      //     vendor_title: product.vendor_title,
      //     variant_count: product.variant_count,
      //     customer_required: product.customer_required,
      //     shipping_required: product.shipping_required,
      //     fulfillment_required: product.fulfillment_required,
      //     variants: product.variants,
      //     price: variant.price,
      //     original_price: variant.original_price,
      //     cost: variant.cost,
      //     taxable: variant.taxable,
      //     sku: variant.sku,
      //     barcode: variant.barcode,
      //     tracked: variant.tracked,
      //     // accounting_reference_id: product.accounting_reference_id,
      //     department_id: product.department_id,
      //     category_id: product.category_id,
      //     subcategory_id: product.subcategory_id,
      //     vendor_id: product.vendor_id,
      //     membership_type: membership.type,
      //     reciprocal: membership.reciprocal,
      //   }));

      setMembershipLoaded(true);
      if (!variantCountIsOne) {
        setInventoryLevelLoaded(true);
      }
    });
  }

  async function loadInventoryLevels(variantId: number) {
    const query = {
      variant_id: variantId,
      extended: true,
    };

    const inventoryRes = await GetInventoryLevels(query, true);

    if (inventoryRes.status !== StatusCode.OK) {
      return;
    }

    ReactDOM.unstable_batchedUpdates(() => {
      setProductState(prev => ({
        ...prev,
        inventory_levels: inventoryRes.data,
      }));
      setInventoryLevelLoaded(true);
    });
  }

  async function saveMembershipAddOn() {
    console.log("Membership Add On State", membershipAddOnState);

    const membershipAddOnRes = await UpdateMembershipAddOn(membershipAddOnState, true);

    console.log(membershipAddOnRes);
    if (membershipAddOnRes.status !== StatusCode.OK) {
      return;
    }

    setMembershipStateBeforeChanges(membershipAddOnState);

    return;
  }

  function unsavedChangesExist() {
    if (membershipStateBeforeChanges === undefined) {
      if (membershipLoaded && inventoryLevelLoaded) {
        setMembershipStateBeforeChanges(membershipAddOnState);
      }
      return false;
    }

    return !isEqualWith(membershipStateBeforeChanges, membershipAddOnState, (originalValue, newValue) => {
      if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
        return true;
      }
    });
  }

  function cancelUnsavedChanges() {
    setMembershipAddOnState(membershipStateBeforeChanges);
  }

  const membershipOptions = [
    {
      id: "single_course",
      title: "Single",
    },
    {
      id: "multi_course",
      title: "Multi-Course",
    },
  ];

  return (
    membershipLoaded &&
    inventoryLevelLoaded && (
      <Page
        title={membershipAddOnState.title}
        narrow
        notificationBarProps={{
          isVisible: unsavedChangesExist(),
          onAction: saveMembershipAddOn,
          onCancel: cancelUnsavedChanges,
        }}
      >
        <Card title="Membership Options">
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={membershipAddOnState.title}
                  label="Title"
                  id="title"
                  onChange={handleInputChange}
                  placeholder="Title"
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>
      </Page>
    )
  );
}
