import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios, { CancelToken } from "axios";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { GetDiscount } from "api/rpc/2024-04/facilityAdmin/discount/discount";
import { IDiscount } from "api/rpc/2022-09/facilityAdmin/product/discount";

import { showError } from "redux/actions/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { displayCurrency, displayPercent } from "helpers/Helpers";

import Page from "components/page/Page";
import { Badge } from "components/badge/Badge";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";

export default function Discounts() {
  const history = useHistory();
  const { t } = useTranslation();

  const authStore = useAppSelector(store => store.authStore);
  const dispatch = useAppDispatch();

  const [discounts, setDiscounts] = useState<IDiscount[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadLineItemDiscounts(source.token);
    return () => source.cancel();
  }, []);

  async function loadLineItemDiscounts(token?: CancelToken) {
    const res = await GetDiscount({ application: "line_item" }, false, token);

    console.log(res);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.discount.discounts.001")));
    }

    setDiscounts(res.status !== StatusCode.OK ? [] : res.data);
  }

  const primaryAction = {
    content: t("secure.facility.settings.discount.discounts.002"),
    action: () => history.push("/admin/settings/discount/new"),
    disabled: !authStore.user?.permissions.discounts_create,
  };

  return (
    <Page
      title={t("secure.facility.settings.discount.discounts.003")}
      subtitle={t("secure.facility.settings.discount.discounts.004")}
      narrow
      primaryAction={primaryAction}
    >
      <DataTable
        columns={[
          { label: t("secure.facility.settings.discount.discounts.005"), width: "75%" },
          { label: "Value", width: "12.5%" }, // TODO: Translation
          { label: "", width: "12.5%" }, // discount.custom
        ]}
        loading={discounts === undefined}
      >
        {discounts?.map(discount => (
          <tr
            key={discount.id}
            className="clickable"
            onClick={() => history.push(`/admin/settings/discount/${discount.id}`)}
          >
            <td>{discount.title}</td>
            <td>
              {discount.value_type === "percent"
                ? String(discount.value) + "%"
                : displayCurrency("cad", discount.value)}
            </td>
            <td>
              {discount.custom ? (
                <Badge size="medium" type="success">
                  Custom {/* TODO: Translation */}
                </Badge>
              ) : null}
            </td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
