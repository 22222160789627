import { APIGet, APIPost, APIPut, IAPIResponse } from "api/protocols";
import { CancelToken } from "axios";
import { ITeeTime } from "redux/reducers/models/teetime";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseTemplateUrl = "/" + apiVersion + "/" + adminType + "/tee-sheet/template";

export interface ITeeSheetTemplate {
  id: number;
  facility_id: number;
  course_id: number;
  name: string;
  format: string;
  start_time: string;
  end_time: string;
  holes: number;
  interval: number;
  turn_time: number;
  tee_times: ITeeTime[];
  version: number;
  description: string;
}

interface IGetTeeSheetTemplate {
  id: number;
  extended?: boolean;
}

interface IGetTeeSheetTemplateResponse extends IAPIResponse {
  data: ITeeSheetTemplate[];
}

interface IPostTeeSheetTemplate {
  course_id: number;
  holes: number;
  name: string;
  format: string;
  start_time: string;
  end_time: string;
  interval: number;
  turn_time: number;
}

export interface ITeeTimeBookingCategoryUpdate extends ITeeTime {
  booking_category_ids?: number[];
}

interface IPutTeeSheetTemplate {
  id: number;
  name: string;
  tee_times: ITeeTimeBookingCategoryUpdate[];
  description: string;
}

// GET Tee Sheet Template
export async function GetTeeSheetTemplate(
  params: IGetTeeSheetTemplate,
  useGlobalLoader: boolean,
  token?: CancelToken,
): Promise<IGetTeeSheetTemplateResponse> {
  return await APIGet(baseTemplateUrl, params, useGlobalLoader, token);
}

// DELETE Shotgun Template
export async function RemoveShotgunTemplate(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseTemplateUrl + "/shotgun/remove", params, useGlobalLoader);
}

export async function PostTeeSheetTemplate(params: IPostTeeSheetTemplate, useGlobalLoader: boolean) {
  return await APIPost(baseTemplateUrl, params, useGlobalLoader);
}

export async function PutTeeSheetTemplate(params: IPutTeeSheetTemplate, useGlobalLoader: boolean) {
  return await APIPut(baseTemplateUrl, params, useGlobalLoader);
}
