import { StatusCode } from "api/protocols";
import { GetFacility } from "api/rpc/guest/facility";
import { IFacility } from "pages/guest/models/facility";
import { AppDispatch } from "index";
import { GetLeague } from "api/rpc/guest/league";
import { ISelectedLeagueRegistrationFee } from "redux/reducers/customer/leagueRegistration";
import { ILeague } from "redux/reducers/models/league";

export function loadFacility(
  facilityShortName: string,
  useGlobalLoader: boolean,
): (dispatch: any) => Promise<IFacility> {
  return async (dispatch: AppDispatch) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      GetFacility({ short_name: facilityShortName }, useGlobalLoader).then(facilityRes => {
        const facilityId = facilityRes?.data?.[0]?.id;

        if (facilityRes.status !== StatusCode.OK || facilityId === undefined) {
          reject(undefined);
        } else {
          const facility = facilityRes.data[0];

          dispatch({
            type: "leagueRegistration.update",
            payload: {
              facility,
            },
          });

          resolve(facility);
        }
      });
    });
  };
}

export function loadLeague(
  facilityId: number,
  handle: string,
  useGlobalLoader: boolean,
): (dispatch: any) => Promise<ILeague> {
  return async (dispatch: AppDispatch) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      GetLeague({ facility_id: facilityId, handle: handle }, useGlobalLoader).then(leagueRes => {
        if (leagueRes.status !== StatusCode.OK) {
          reject(undefined);
        } else {
          const league = leagueRes.data?.[0];

          dispatch({
            type: "leagueRegistration.update",
            payload: {
              league,
            },
          });

          resolve(league);
        }
      });
    });
  };
}

export function clearLeague() {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: "leagueRegistration.update",
      payload: {
        league: null,
      },
    });
  };
}

export function updateActiveRegistrationFees(activeRegistrationFees: ISelectedLeagueRegistrationFee[]) {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: "leagueRegistration.update",
      payload: {
        activeRegistrationFees,
      },
    });
  };
}

export interface ILeagueRegistrationActions {
  loadFacility: typeof loadFacility;
  loadLeague: typeof loadLeague;
  clearLeague: typeof clearLeague;
  updateActiveRegistrationFees: typeof updateActiveRegistrationFees;
}
