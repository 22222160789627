import React, { ReactChild, ReactChildren } from "react";
import Card from "components/card/Card";

import "./eventScorecard.scss";
import { ButtonNew } from "components/buttonNew";
import { useTranslation } from "react-i18next";
import { ITournamentParticipant } from "redux/reducers/models/tournament";
import { StatusCode } from "api/protocols";
import { useAppDispatch } from "hooks/redux";
import { showError } from "redux/actions/ui";

type TEventScorecard = {
  participant: ITournamentParticipant;
  scorecardOpen: boolean;
  toggleScorecard: (participantId: number) => void;
  createRound: (customerId: number) => void;
};

/** Displays custom card heading.  Children are the card content underneith. */
export default function EventScorecard(props: TEventScorecard & { children?: JSX.Element | JSX.Element[] }) {
  const { t } = useTranslation();
  const { participant } = props;

  const dispatch = useAppDispatch();

  return (
    <Card>
      <div className="event-scorecard-heading">
        <p className="scorecard-title">{participant?.customer.full_name}</p>

        {!participant.round ? (
          <ButtonNew type="primary" size="small" onClick={e => props.createRound(participant?.user_id)}>
            {t("secure.facility.league.league_scoresheet.011")}
          </ButtonNew>
        ) : null}

        {participant.round ? (
          <div className="round-statistics">
            {/* Display round statistics if card is open */}
            {props.scorecardOpen ? (
              <>
                <p>
                  <span>{t("secure.facility.league.league_scoresheet.006")}</span>
                  {participant.round.round_handicap}
                </p>
                <p>
                  <span>{t("secure.facility.league.league_scoresheet.007")}</span>
                  {participant.round.net_score}
                </p>
                <p>
                  <span>{t("secure.facility.league.league_scoresheet.008")}</span>
                  {participant.round.gross_score}
                </p>
                <p>
                  <span>{t("secure.facility.league.league_scoresheet.009")}</span>
                  {participant.round.points_awarded}
                </p>
              </>
            ) : null}
            <ButtonNew type="primary" size="small" onClick={() => props.toggleScorecard(participant.id)}>
              {t("secure.facility.league.league_scoresheet.010")}
            </ButtonNew>
          </div>
        ) : null}
      </div>

      <div>
        {props.children &&
          React.Children.map(props.children, child => React.cloneElement(child, { style: { ...child.props.style } }))}
      </div>
    </Card>
  );
}
