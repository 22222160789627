import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Reservations from "pages/guest/Reservations/Reservations";
import * as UIActions from "redux/actions/ui";
import * as IReservationActions from "redux/actions/reservations";

const mapDispatchToProps = (dispatch: any) => {
  return {
    uiActions: bindActionCreators(
      {
        ...UIActions,
      },
      dispatch,
    ),
    reservationActions: bindActionCreators(
      {
        ...IReservationActions,
      },
      dispatch,
    ),
  };
};

const mapStateToProps = (state: any) => {
  return {
    uiStore: { ...state.uiStore },
    reservationStore: { ...state.reservationStore },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reservations);
