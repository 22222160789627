import React, { useEffect, useState } from "react";
import { ButtonNew as Button } from "components/buttonNew";
import Layout from "components/layout/index";
import { useHistory, Link } from "react-router-dom";

import Page from "components/page/Page";
import Card from "components/card/Card";

import Category from "elements/settings/Category";
import { StatusCode } from "api/protocols";
import FormLayout from "components/form/FormLayout";
import Form from "components/form/Form";
import Input from "components/form/input/Input";
import { Select } from "components/select/index";
import { GetCardReaders } from "api/rpc/facilityAdmin/payment/teeOnPayments/reader";

import { useTranslation, Trans } from "react-i18next";
import { PostRegisterGroup } from "api/rpc/2024-04/facilityAdmin/facility/register/group";
import { useAppDispatch } from "hooks/redux";
import { showError, showSuccess } from "redux/actions/ui";

interface IProps {
  uiActions: any;
}

export default function GroupNew(props: IProps) {
  const { uiActions } = props;
  const { Option } = Select;
  const dispatch = useAppDispatch();

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const primaryAction = {
    content: t("secure.facility.settings.register.group_new.001"),
    action: createRegisterGroup,
  };

  const [state, setState] = useState({
    groups: [],
    readers: [],
  });

  const [registerGroupState, setRegisterGroupState] = useState({
    register_group_id: null,
    default_card_reader_id: null,
    title: "",
    tipping_type: "percent",
    tipping_type_options: [
      {
        label: t("secure.facility.settings.register.group_new.002"),
        value: "none",
      },
      {
        label: t("secure.facility.settings.register.group_new.003"),
        value: "percent",
      },
      {
        label: t("secure.facility.settings.register.group_new.004"),
        value: "fixed_amount",
      },
    ],
  });

  // Load information for pag
  useEffect(() => {}, []);

  async function createRegisterGroup() {
    console.log(registerGroupState);
    const res = await PostRegisterGroup(
      {
        title: registerGroupState.title,
        tipping_type: registerGroupState.tipping_type,
      },
      true,
    );

    if (res.status === StatusCode.OK) {
      dispatch(showSuccess(t("secure.facility.settings.register.group_new.011")));
      history.push("/admin/settings/register-group");
    } else {
      dispatch(showError(t("secure.facility.settings.register.group_new.012")));
    }

    return;
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setRegisterGroupState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleDropDownChange(value: any, property: string) {
    setRegisterGroupState(prevState => ({ ...prevState, [property]: value }));
  }

  return (
    <Page
      title={t("secure.facility.settings.register.group_new.005")}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        // { label: t("secure.facility.settings.register.group_new.006"), url: "/admin/settings" },
        {
          prefix: true,
          label: t("secure.facility.settings.register.group_new.007"),
          url: "/admin/settings/register-group",
        },
      ]}
    >
      <Card>
        <Card.Section>
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={registerGroupState.title ? registerGroupState.title : ""}
                  label={t("secure.facility.settings.register.group_new.008")}
                  id="title"
                  onChange={handleInputChange}
                  placeholder={t("secure.facility.settings.register.group_new.009")}
                />
                <Select
                  label={t("secure.facility.settings.register.group_new.010")}
                  className={`flex justify-center align-center w-full h-10 position-relative z-20 text-black font-medium appearance-none border-none focus:outline-none placeholder-gray-200`}
                  onChange={(value: any) => handleDropDownChange(value, "tipping_type")}
                >
                  {registerGroupState.tipping_type_options.map((option: any) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
      </Card>
    </Page>
  );
}
