import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";

import { showError, showSuccess } from "redux/actions/ui";
import { TTeesheetPricingSheet } from "redux/reducers/models/teesheet";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { convertTime, weekdayNames } from "helpers/Helpers";
import useModal from "hooks/modals/useModal";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Sheet from "components/sheet/Sheet";
import { Badge } from "components/badge/Badge";
import Input from "components/form/input/Input";
import { Select } from "components/select/index";
import Checkbox from "components/form/checkbox/Checkbox";
import Callout from "components/callout/Callout";
import TimePick from "components/timePick/TimePick";
import FormLayout from "components/form/FormLayout";
import SelectNew from "components/select/SelectNew";
import { Day, DayTab, DayValue } from "pages/secure/facility/settings/teesheets/pricingSheets/TeeSheetPricingSheets";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

import "./pricingSheets.scss";

import {
  GetPricingSheet,
  PostDuplicatePricingSheet,
  PostPricingSheet,
} from "api/rpc/2024-04/clientAdmin/teesheet/pricingSheet";

interface IFIlterState {
  selectAllPricingSheets: boolean;
}

export default function PricingSheets() {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const clientFacilityStore = useAppSelector(store => store.clientFacilityStore);
  const dispatch = useAppDispatch();

  const courses = clientFacilityStore.facility?.courses ?? [];

  const [pricingSheets, setPricingSheets] = useState<TTeesheetPricingSheet[]>(undefined);
  const [filterState, setFilterState] = useState<IFIlterState>({
    selectAllPricingSheets: false,
  });
  const [selectedPricingSheetIds, setSelectedPricingSheetIds] = useState<number[]>([]);

  const {
    state: duplicateModal,
    updateModal: updateDuplicateModal,
    closeModal: closeDuplicateModal,
  } = useModal({ selectedFacilityId: null as number, selectedDayOfWeek: "" });

  const {
    state: newSheetModal,
    updateModal: updateNewSheetModal,
    closeModal: closeNewSheetModal,
  } = useModal({
    startTime: "",
    endTime: "",
    title: "",
    application: "",
    selectedDayOfWeek: "",
    courseId: null as number,
  });

  const [filterDayState, setFilterDayState] = useState<DayValue>(
    window.sessionStorage.getItem("retainedSelectedDay") === null
      ? Day.Monday
      : (Number(window.sessionStorage.getItem("retainedSelectedDay")) as DayValue),
  );

  useEffect(() => {
    window.sessionStorage.setItem("retainedSelectedDay", String(filterDayState));
  }, [filterDayState]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (clientFacilityStore?.facility) {
      void loadPricingSheets(source.token);
    }
    return () => source.cancel();
  }, [clientFacilityStore?.facility]);

  async function loadPricingSheets(token?: CancelToken) {
    if (pricingSheets !== undefined) {
      setPricingSheets(undefined);
    }
    const res = await GetPricingSheet({ facility_id: clientFacilityStore?.facility?.id }, token ? false : true, token);
    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading pricing sheets")); // TODO: Translation
    }

    setPricingSheets(res.status !== StatusCode.OK ? [] : res.data);
  }

  async function addPricingSheet() {
    const courseId = newSheetModal.courseId ? newSheetModal.courseId : clientFacilityStore?.facility?.courses[0]?.id;

    const pricingSheetRes = await PostPricingSheet(
      {
        course_id: courseId,
        facility_id: clientFacilityStore?.facility?.id,
        title: newSheetModal.title,
        day_of_week: newSheetModal.selectedDayOfWeek,
        start_time: newSheetModal.startTime,
        end_time: newSheetModal.endTime,
        application: newSheetModal.application,
      },
      true,
    );
    if (pricingSheetRes.status !== StatusCode.OK) {
      return;
    }

    updateNewSheetModal({ isOpen: false });
    void loadPricingSheets();

    return;
  }

  async function handleDuplicatePricingSheets() {
    const duplicateRes = await PostDuplicatePricingSheet(
      {
        pricing_sheet_ids: selectedPricingSheetIds,
        facility_id: duplicateModal.selectedFacilityId,
        day_of_week: duplicateModal.selectedDayOfWeek,
      },
      true,
    );
    if (duplicateRes.status !== StatusCode.OK) {
      dispatch(showError("Error duplicating pricing sheets")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Successfully duplicated pricing sheets")); // TODO: Translation

    if (duplicateModal?.selectedFacilityId === clientFacilityStore?.facility?.id) {
      void loadPricingSheets();
    }
    closeDuplicateModal();
    setSelectedPricingSheetIds([]);
  }

  function handleCheckboxToggle(pricingSheetId: number) {
    const foundIndex = selectedPricingSheetIds.indexOf(pricingSheetId);
    if (foundIndex === -1) {
      setSelectedPricingSheetIds(prev => [...prev, pricingSheetId]);
    } else {
      setSelectedPricingSheetIds(prev => prev.filter(val => val !== pricingSheetId));
    }
  }

  function handleSelectAllCheckboxChange(event: any) {
    const { id, checked, value } = event.target;

    if (!checked) {
      void handleClearClick();
    } else {
      void handleSetAllClick();
    }
  }

  const handleSetAllClick = () => {
    //Select all pricing sheets for the selected day of the week
    const everyPricingSheetId = pricingSheets
      ?.filter(filteredSheet => filteredSheet.day_of_week_number === filterDayState)
      .map(sheet => {
        return sheet?.id;
      });

    setFilterState(prevState => ({ ...prevState, selectAllPricingSheets: true }));
    setSelectedPricingSheetIds(everyPricingSheetId);
  };

  const handleClearClick = () => {
    setFilterState(prevState => ({ ...prevState, selectAllPricingSheets: false }));
    setSelectedPricingSheetIds([]);
  };

  function handleDayClick(value: any) {
    setFilterDayState(value);
    setFilterState(prevState => ({ ...prevState, selectAllPricingSheets: false }));
    setSelectedPricingSheetIds([]);
  }

  function clearSelection() {
    setSelectedPricingSheetIds([]);
    setFilterState(prevState => ({ ...prevState, selectAllPricingSheets: false }));
  }

  const primaryAction = {
    content: "New Pricing Sheet",
    action: () => updateNewSheetModal({ isOpen: true }),
  };

  return (
    <>
      <Page
        title={"Pricing Sheets"}
        subtitle={"Build pricing sheets for tee time bookings"}
        primaryAction={primaryAction}
        secondaryActions={[
          // { content: "Clear Selection", action: () => clearSelection() },
          {
            content: "Duplicate",
            action: () => updateDuplicateModal({ isOpen: true, selectedFacilityId: clientFacilityStore.facility?.id }),
          },
        ]}
      >
        <Card>
          <div className="flex">
            <DayTab
              title={"Monday"}
              value={Day.Monday}
              compare={filterDayState}
              onClick={value => handleDayClick(value)}
            />
            <DayTab
              title={"Tuesday"}
              value={Day.Tuesday}
              compare={filterDayState}
              onClick={value => handleDayClick(value)}
            />
            <DayTab
              title={"Wednesday"}
              value={Day.Wednesday}
              compare={filterDayState}
              onClick={value => handleDayClick(value)}
            />
            <DayTab
              title={"Thursday"}
              value={Day.Thursday}
              compare={filterDayState}
              onClick={value => handleDayClick(value)}
            />
            <DayTab
              title={"Friday"}
              value={Day.Friday}
              compare={filterDayState}
              onClick={value => handleDayClick(value)}
            />
            <DayTab
              title={"Saturday"}
              value={Day.Saturday}
              compare={filterDayState}
              onClick={value => handleDayClick(value)}
            />
            <DayTab
              title={"Sunday"}
              value={Day.Sunday}
              compare={filterDayState}
              onClick={value => handleDayClick(value)}
            />
            <DayTab
              title={"Holiday"}
              value={Day.Holiday}
              compare={filterDayState}
              onClick={value => handleDayClick(value)}
            />
          </div>
          <DataTable
            columns={
              courses.length > 1
                ? [
                    {
                      label: "",
                      width: "5%",
                      content: (
                        <div>
                          <Checkbox
                            id="selectAllPricingSheets"
                            size="medium"
                            checked={filterState.selectAllPricingSheets}
                            onChange={handleSelectAllCheckboxChange}
                            labelHidden
                          />
                        </div>
                      ),
                    },
                    { label: "Title", width: "15%" },
                    { label: "Course", width: "15%" },
                    { label: "Application", width: "15%" },
                    { label: "Start Time", width: "15%" },
                    { label: "End Time", width: "15%" },
                    { label: "Day", width: "20%" },
                  ]
                : [
                    {
                      label: "",
                      width: "5%",
                      content: (
                        <div>
                          <Checkbox
                            id="selectAllPricingSheets"
                            size="medium"
                            checked={filterState.selectAllPricingSheets}
                            onChange={handleSelectAllCheckboxChange}
                            labelHidden
                          />
                        </div>
                      ),
                    },
                    { label: "Title", width: "18%" },
                    { label: "Application", width: "18%" },
                    { label: "Start Time", width: "18%" },
                    { label: "End Time", width: "18%" },
                    { label: "Day", width: "23%" },
                  ]
            }
            loading={pricingSheets === undefined}
          >
            {pricingSheets
              ?.filter(sheet => sheet.day_of_week_number === filterDayState)
              ?.map(pricingSheet => (
                <tr
                  key={pricingSheet.id}
                  onClick={() =>
                    history.push("/admin/settings/facility-settings/tee-sheet/pricing-sheet/" + String(pricingSheet.id))
                  }
                  className="clickable"
                >
                  <td onClick={(e: React.MouseEvent) => e.stopPropagation()}>
                    <Checkbox
                      onChange={() => handleCheckboxToggle(pricingSheet.id)}
                      size="medium"
                      checked={selectedPricingSheetIds?.includes(pricingSheet.id)}
                    />
                  </td>
                  <td>{pricingSheet.title}</td>
                  {courses.length > 1 && <td className="font-normal">{pricingSheet.course?.full_name}</td>}
                  <td>
                    {pricingSheet.application === "green_fee" ? (
                      <Badge size="medium" type="success">
                        Green Fee
                      </Badge>
                    ) : null}
                    {pricingSheet.application === "green_fee_league" ? (
                      <Badge size="medium" type="success">
                        {"League Green Fee"} {/**TODO: Translate */}
                      </Badge>
                    ) : null}
                    {pricingSheet.application === "power_cart" ? (
                      <Badge size="medium" type="gray">
                        Power Cart
                      </Badge>
                    ) : null}

                    {pricingSheet.application === "power_cart_league" ? (
                      <Badge size="medium" type="gray">
                        {"League Power Cart"} {/**TODO: Translate */}
                      </Badge>
                    ) : null}
                    {pricingSheet.application === "no_show" ? (
                      <Badge size="medium" type="error">
                        No Show
                      </Badge>
                    ) : null}
                  </td>
                  <td>{convertTime(pricingSheet.start_time)}</td>
                  <td>{convertTime(pricingSheet.end_time)}</td>
                  <td>{pricingSheet.day_of_week}</td>
                </tr>
              ))}
          </DataTable>
        </Card>
      </Page>

      <Sheet
        open={newSheetModal.isOpen}
        size="small"
        closable
        title={"New Pricing Sheet"}
        onCancel={closeNewSheetModal}
        onOk={() => addPricingSheet()}
        cancelText={"Cancel"}
        okText={"Create"}
        okDisabled={
          !newSheetModal.selectedDayOfWeek ||
          !newSheetModal.title ||
          !newSheetModal.startTime ||
          !newSheetModal.endTime ||
          !newSheetModal.application
            ? true
            : false
        }
        overflow
      >
        <div className="flex flex-col gap-2">
          <FormLayout>
            <FormLayout.Group>
              <Input
                label={"Title"}
                placeholder={"Title"}
                id="title"
                value={newSheetModal.title}
                onChange={e => updateNewSheetModal({ title: e.target.value })}
              />
            </FormLayout.Group>

            <FormLayout.Group>
              <SelectNew
                id="newSheetModal_selectedDay"
                value={newSheetModal.selectedDayOfWeek}
                onChange={value => updateNewSheetModal({ selectedDayOfWeek: value })}
                options={["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday", "Holiday"].map(
                  val => ({ id: val, label: val }),
                )}
                label="Day Of Week"
              />

              <SelectNew
                id="newSheetModal_application"
                value={newSheetModal.application}
                onChange={value => updateNewSheetModal({ application: value })}
                options={[
                  { id: "green_fee", label: "Green Fee" },
                  { id: "power_cart", label: "Power Cart" },
                  { id: "no_show", label: "No Show" },
                  { id: "green_fee_league", label: "Green Fee League" },
                  { id: "power_cart_league", label: "Power Cart League" },
                ]}
                label="Application"
              />
            </FormLayout.Group>

            <FormLayout.Group>
              <div>
                <TimePick
                  value={newSheetModal.startTime}
                  onChange={timeString => updateNewSheetModal({ startTime: timeString })}
                  label={"Start Time"} // TODO: Translation
                  size="large"
                  status={newSheetModal.startTime === undefined ? "warning" : undefined}
                  positionTop
                />
              </div>

              <div>
                <TimePick
                  value={newSheetModal.endTime}
                  onChange={timeString => updateNewSheetModal({ endTime: timeString })}
                  label={"End Time"} // TODO: Translation
                  size="large"
                  status={newSheetModal.endTime === undefined ? "warning" : undefined}
                  positionTop
                />
              </div>
            </FormLayout.Group>

            <FormLayout.Group>
              <div>
                {clientFacilityStore.facility?.courses?.length > 1 && (
                  <SelectNew
                    id="newSheetModal_courseId"
                    value={newSheetModal.courseId}
                    onChange={value => updateNewSheetModal({ courseId: value })}
                    options={clientFacilityStore.facility.courses.map(val => ({ id: val.id, label: val.long_name }))}
                    label="Course"
                  />
                )}
              </div>
            </FormLayout.Group>
          </FormLayout>
        </div>
      </Sheet>

      <Sheet
        open={duplicateModal.isOpen}
        size="small"
        closable
        title={"Duplicate Pricing Sheet"} // TODO: Translation
        onCancel={closeDuplicateModal}
        onOk={handleDuplicatePricingSheets}
        cancelText={"Cancel"}
        okText={"Duplicate"}
        okDisabled={
          !duplicateModal.selectedFacilityId ||
          selectedPricingSheetIds?.length === 0 ||
          !duplicateModal.selectedDayOfWeek
            ? true
            : false
        }
        overflow
      >
        {clientFacilityStore?.facilities && (
          <SelectNew
            id="duplicateModal_facilityId"
            value={duplicateModal.selectedFacilityId}
            onChange={value => updateDuplicateModal({ selectedFacilityId: value })}
            options={clientFacilityStore.facilities?.map(val => ({ id: val.id, label: val.full_name }))}
            label="Facility To Duplicate To"
            className="mb-4"
          />
        )}

        <SelectNew
          id="duplicateModal_selectedDay"
          value={duplicateModal.selectedDayOfWeek}
          onChange={value => updateDuplicateModal({ selectedDayOfWeek: value })}
          options={["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday", "Holiday"].map(
            val => ({ id: val, label: val }),
          )}
          label="Day Of Week"
          className="mb-4"
        />

        {selectedPricingSheetIds?.length === 0 && (
          <Callout
            type="error"
            title="No Pricing Sheets Selected"
            content="Select the check boxes of the pricing sheets you wish to duplicate and try again"
          />
        )}
      </Sheet>
    </>
  );
}
