import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import "./leftSubMenu.scss";
import "components/text/heading/Heading";
import MenuOption from "./MenuOption";
import { IOptions } from "pages/secure/facility/Admin";

interface IProps {
  title: string;
  options: IOptions[];
  onClickTitle?: () => void;
  onClickOption?: () => void;
}

export default function LeftSubMenu(props: IProps) {
  const { title, options, onClickTitle, onClickOption } = props;
  const location = useLocation();
  const [currentUrl, setCurrentUrl] = useState(location.pathname);
  const history = useHistory();

  function updateCurrentRoute(url: string) {
    setCurrentUrl(url);
    if (onClickOption) {
      onClickOption();
    }
    history.push(url);
  }

  useEffect(() => {
    if (options) {
      setCurrentUrl(location.pathname);
    }
  }, [options]);

  return (
    <div className="left-submenu">
      <div className="left-submenu-container">
        <div
          className={onClickTitle !== undefined ? "left-submenu-top-bar-clickable" : "left-submenu-top-bar"}
          onClick={() => (onClickTitle !== undefined ? onClickTitle() : {})}
        >
          <h1 className="left-submenu-heading">{title}</h1>
        </div>
        <div className="left-submenu-options-container">
          <div className="left-submenu-options">
            {options?.map((option: IOptions, index: number) => {
              return (
                <MenuOption
                  key={index}
                  option={option}
                  onClick={updateCurrentRoute}
                  showStyle={currentUrl === option.url ? true : false}
                  currentUrl={currentUrl}
                  level={0}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
