import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios, { CancelToken } from "axios";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { GetShotGunTemplate } from "api/rpc/2024-04/facilityAdmin/teesheet/template";
import { TShotgunTemplate } from "redux/reducers/models/teesheet";

import Page from "components/page/Page";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

import "../../facility/facility.scss";

const currentUrl = "/admin/settings/tee-sheet/shotgun-templates";
export default function ShotGunTemplates() {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [templates, setTemplates] = useState<TShotgunTemplate[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadShotgunTemplates(source.token);
    return () => source.cancel();
  }, []);

  async function loadShotgunTemplates(token?: CancelToken) {
    const res = await GetShotGunTemplate(null, false, token);
    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      setTemplates([]);
      return;
    }

    setTemplates(res.data);
  }

  function navigateToEditShotgunTemplate(id: string | number) {
    history.push("/admin/settings/tee-sheet/shotgun-templates/" + String(id));
  }

  const primaryAction = {
    content: t("secure.facility.settings.tee_sheets.shotgun_template.shotgun_templates.001"),
    action: () => history.push(currentUrl + "/new"),
  };

  return (
    <Page
      title={t("secure.facility.settings.tee_sheets.shotgun_template.shotgun_templates.002")}
      subtitle={t("secure.facility.settings.tee_sheets.shotgun_template.shotgun_templates.003")}
      narrow
      primaryAction={primaryAction}
    >
      <hr className="my-8" />
      <DataTable
        columns={[{ label: t("secure.facility.settings.tee_sheets.shotgun_template.shotgun_templates.002") }]}
        loading={templates === undefined}
      >
        {templates?.map(template => {
          return (
            <tr key={template.id} className="clickable">
              <td onClick={() => navigateToEditShotgunTemplate(template.id)}>{template.title}</td>
            </tr>
          );
        })}
      </DataTable>
    </Page>
  );
}
