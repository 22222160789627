import { useEffect, useState } from "react";

/** Add new keyboard event key codes to check */
type TKeyDownCode = "Enter" | "ShiftLeft" | "ShiftRight" | "Semicolon" | "Comma";

/** Returns true if key matches code argument. On keyup, resets to false. */
export default function useKeyPress(code: TKeyDownCode[]) {
  const [keyPressed, setKeyPressed] = useState(false);

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("keyup", onKeyUp);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("keyup", onKeyUp);
    };
  });

  const onKeyDown = (e: KeyboardEvent) => {
    if (code.includes(e.code as TKeyDownCode)) {
      e.preventDefault();
      setKeyPressed(true);
    }
  };

  const onKeyUp = (e: KeyboardEvent) => {
    if (code.includes(e.code as TKeyDownCode)) {
      setKeyPressed(false);
    }
  };

  return keyPressed;
}
