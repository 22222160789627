import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAppSelector } from "hooks/redux";
import useModal from "hooks/modals/useModal";
import { capitalize, unsavedChangesExist } from "helpers/Helpers";

import { ButtonNew } from "components/buttonNew";
import Panel from "components/panel/Panel";
import AccordionItem from "components/accordion/AccordionItem";
import Checkbox from "components/form/checkbox/Checkbox";
import Spin from "components/spin/spin";
import { IFilterState } from "./ClientPortalHome";
import { holeOptions, cartOptions, playerOptions } from "./teeTimeFilters";

import "./clientPortalTeetimeFilters.scss";

type TClientPortalTeeTimeFilters = {
  isLoading?: boolean;
  filters: TTeeTimeFilterItems;
  updateFilters: (filters: TTeeTimeFilterItems) => void;
  showCartFilters?: boolean;
};

type TTeeTimeFilterItems = Pick<IFilterState, "selectedPlayers" | "selectedHoles" | "selectedCart">;

export default function ClientPortalTeetimeFilters(props: TClientPortalTeeTimeFilters) {
  const { t } = useTranslation();

  const { filters, updateFilters, showCartFilters } = props;
  const clientPortalStore = useAppSelector(store => store.clientPortalStore);

  const { state: panel, updateModal: updatePanel, closeModal: closePanel } = useModal();

  const { state: holesAccordionItem, updateModal: updateHolesAccordionItem } = useModal({
    selectedHoles: null,
    clearOnClose: false,
  });

  const { state: playersAccordionItem, updateModal: updatePlayersAccordionItem } = useModal({
    selectedPlayers: null,
    clearOnClose: false,
  });

  const { state: cartAccordionItem, updateModal: updateCartAccordionItem } = useModal({
    selectedCart: null,
    clearOnClose: false,
  });

  useEffect(() => {
    if (panel.isOpen) {
      updateCartAccordionItem({ selectedCart: filters.selectedCart });
      updateHolesAccordionItem({ selectedHoles: filters.selectedHoles });
      updatePlayersAccordionItem({ selectedPlayers: filters.selectedPlayers });
    }
  }, [panel.isOpen]);

  const filtersHaveChanged =
    unsavedChangesExist(filters.selectedCart, cartAccordionItem.selectedCart) ||
    unsavedChangesExist(filters.selectedHoles, holesAccordionItem.selectedHoles) ||
    unsavedChangesExist(filters.selectedPlayers, playersAccordionItem.selectedPlayers);

  function applyFilters() {
    const temp: TTeeTimeFilterItems = {
      selectedPlayers: playersAccordionItem.selectedPlayers !== "-1" ? playersAccordionItem.selectedPlayers : null,
      selectedHoles: holesAccordionItem.selectedHoles !== "-1" ? holesAccordionItem.selectedHoles : null,
      selectedCart: cartAccordionItem.selectedCart !== "-1" ? cartAccordionItem.selectedCart : null,
    };

    // update the filters
    updateFilters(temp);

    // close accordions & panel
    handleClosePanel();
  }

  /** Close panel & each accordion item */
  function handleClosePanel() {
    closePanel();
    updateHolesAccordionItem({ isOpen: false });
    updatePlayersAccordionItem({ isOpen: false });
    updateCartAccordionItem({ isOpen: false });
  }

  /** Clear all accordion filter values */
  function clearAccordionFilters() {
    updateHolesAccordionItem({
      selectedHoles: holesAccordionItem.selectedHoles ? (filters.selectedHoles ? "-1" : null) : null,
    });
    updatePlayersAccordionItem({
      selectedPlayers: playersAccordionItem.selectedPlayers ? (filters.selectedPlayers ? "-1" : null) : null,
    });
    updateCartAccordionItem({
      selectedCart: cartAccordionItem.selectedCart ? (filters.selectedCart ? "-1" : null) : null,
    });
  }

  function updateAccordionItem(id: keyof typeof filters, value: string) {
    switch (id) {
      case "selectedCart": {
        updateCartAccordionItem({
          selectedCart: cartAccordionItem.selectedCart === value ? (filters.selectedCart ? "-1" : null) : value,
        });
        break;
      }
      case "selectedHoles": {
        updateHolesAccordionItem({
          selectedHoles: holesAccordionItem.selectedHoles === value ? (filters.selectedHoles ? "-1" : null) : value,
        });
        break;
      }
      case "selectedPlayers": {
        updatePlayersAccordionItem({
          selectedPlayers:
            playersAccordionItem.selectedPlayers === value ? (filters.selectedPlayers ? "-1" : null) : value,
        });
        break;
      }
      default:
        return;
    }
  }

  return (
    <>
      <div className="portal-teetime-filter-panel-trigger" onClick={() => updatePanel({ isOpen: true })}>
        <FontAwesomeIcon size="sm" icon={["fas", "bars-filter"]} />
        {props.isLoading && (
          <span className="trigger-count loading">
            <Spin />
          </span>
        )}
        {!props.isLoading && activeFilterCount(filters) > 0 && (
          <span className="trigger-count loaded">
            <p>{activeFilterCount(filters)}</p>
          </span>
        )}
      </div>

      <Panel
        open={panel.isOpen}
        onClose={handleClosePanel}
        animate={0.5}
        pagePercent={95}
        heading={
          <div className="filter-panel-heading">
            <span>
              <FontAwesomeIcon icon={["fal", "filter"]} size="1x" />
            </span>
            <h3 className="text-lg text-semibold">Add Filters</h3> {/* TODO: Translation */}
          </div>
        }
        primaryAction={{
          label: "Apply", // TODO: Translation
          onClick: () => applyFilters(),
          disabled: !filtersHaveChanged,
        }}
        secondaryAction={{
          label: "Clear", // TODO: Translation
          onClick: () => clearAccordionFilters(),
        }}
      >
        <AccordionItem
          title={"Players"} // TODO: Translation
          open={playersAccordionItem.isOpen}
          toggle={() => updatePlayersAccordionItem({ isOpen: !playersAccordionItem.isOpen })}
          // chevronClick={() => updateAccordionItem('selectedPlayers', null)}
          helpText={filters.selectedPlayers}
          relative
        >
          <ul>
            {playerOptions(t, clientPortalStore?.teeSheetSettings?.single_bookings === "none")
              .filter(val => val.value !== "any")
              .map(val => (
                <li
                  key={val.value}
                  className={val.disabled ? "panel-accordion-item disabled" : "panel-accordion-item"}
                  onClick={() => (!val.disabled ? updateAccordionItem("selectedPlayers", val.value) : undefined)}
                >
                  <Checkbox
                    label={val.title}
                    size="medium"
                    checked={playersAccordionItem.selectedPlayers === val.value}
                    onChange={e => e.stopPropagation()}
                    disabled={val.disabled}
                  />
                </li>
              ))}
          </ul>
        </AccordionItem>
        <AccordionItem
          title={"Holes"} // TODO: Translation
          open={holesAccordionItem.isOpen}
          toggle={() => updateHolesAccordionItem({ isOpen: !holesAccordionItem.isOpen })}
          // chevronClick={() => updateAccordionItem('selectedHoles', null)}
          helpText={filters.selectedHoles}
          relative
        >
          <ul>
            {holeOptions
              .filter(val => val.value !== "any")
              .map(val => (
                <li
                  key={val.value}
                  className={val.disabled ? "panel-accordion-item disabled" : "panel-accordion-item"}
                  onClick={() => (!val.disabled ? updateAccordionItem("selectedHoles", val.value) : undefined)}
                >
                  <Checkbox
                    label={val.title}
                    size="medium"
                    checked={holesAccordionItem.selectedHoles === val.value}
                    onChange={e => e.stopPropagation()}
                    disabled={val.disabled}
                  />
                </li>
              ))}
          </ul>
        </AccordionItem>

        {showCartFilters ? (
          <AccordionItem
            title={"Cart"} // TODO: Translation
            open={cartAccordionItem.isOpen}
            toggle={() => updateCartAccordionItem({ isOpen: !cartAccordionItem.isOpen })}
            // chevronClick={() => updateAccordionItem('selectedCart', null)}
            helpText={capitalize(filters.selectedCart)}
            relative
          >
            <ul>
              {cartOptions
                .filter(val => val.value !== "any")
                .map(val => (
                  <li
                    key={val.value}
                    className={val.disabled ? "panel-accordion-item disabled" : "panel-accordion-item"}
                    onClick={() => (!val.disabled ? updateAccordionItem("selectedCart", val.value) : undefined)}
                  >
                    <Checkbox
                      label={val.title}
                      size="medium"
                      checked={cartAccordionItem.selectedCart === val.value}
                      onChange={e => e.stopPropagation()}
                      disabled={val.disabled}
                    />
                  </li>
                ))}
            </ul>
          </AccordionItem>
        ) : null}
      </Panel>
    </>
  );
}

const activeFilterCount = (filters: Pick<IFilterState, "selectedPlayers" | "selectedHoles" | "selectedCart">) => {
  const arr = Array.from(Object.entries(filters), ([key, value]) => value !== null).filter(val => val);
  return arr.length;
};
