import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { StatusCode } from "api/protocols";
import { IBookingEngineState } from "redux/reducers/bookingEngine";
import { IBookingEngineActions } from "redux/actions/actionTypes";
import { IUIActions } from "redux/actions/ui";
import "./Reservations.scss";
import { GetBooking, PutCancelBooking } from "api/rpc/2024-04/customer/booking";
import { IBooking } from "redux/reducers/models/teetime";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonNew } from "components/buttonNew";
import ReactDOM from "react-dom";
import Card from "components/card/Card";
import Popup from "components/popup/Popup";
import { useTranslation } from "react-i18next";
import { Badge } from "components/badge/Badge";

interface IProps {
  bookingEngineStore: IBookingEngineState;
  bookingEngineActions: IBookingEngineActions;
  uiActions: IUIActions;
}
interface IParams {
  facilityShortName: string;
  bookingEngineHandle: string;
  id: string;
}

interface IState {
  selectedBooking: IBooking;
  formattedAddress: string;
}

interface IEditBookingState {
  cancelPopUp: boolean;
  hideEditButton: boolean;
  hideCancelButton: boolean;
  teeTimeNotInPast: boolean;
}

export default function ViewReservation(props: IProps) {
  const { bookingEngineStore, bookingEngineActions, uiActions } = props;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { facilityShortName, bookingEngineHandle, id } = useParams<IParams>();
  const [state, setState] = useState<IState>({
    selectedBooking: null,
    formattedAddress: "",
  });

  const [editBookingState, setEditBookingState] = useState<IEditBookingState>({
    cancelPopUp: false,
    hideEditButton: false,
    hideCancelButton: false,
    teeTimeNotInPast: false,
  });

  // June 6, 2022
  const editDate = new Date("2022-06-06T00:00:00.000000Z").toUTCString();

  useEffect(() => {
    void getBooking();
  }, []);

  async function getBooking() {
    let [hideEditBooking, hideCancelBooking] = [true, true];
    const bookingRes = await GetBooking({ id: Number(id) }, true);
    if (bookingRes.status !== StatusCode.OK) {
      uiActions.showError(bookingRes.message);
      return;
    }

    //Return to reservations page if booking isn't found
    if (bookingRes.data?.length === 0) {
      uiActions.showError(t("guest.customer.customer_nav_menu.view_reservation.001"));
      history.push(`/booking-engine/${facilityShortName}/${bookingEngineHandle}/profile/reservations`);
    }

    if (bookingRes.data[0]?.status !== "cancelled") {
      [hideEditBooking, hideCancelBooking] = checkEditCancelTimes(bookingRes.data[0]);
      void checkTeeTimeInPast(bookingRes?.data[0]);
    }

    const fullAddress = `${bookingRes.data[0]?.tee_time?.facility?.address_line_1}, ${bookingRes.data[0]?.tee_time?.facility?.city},
      ${bookingRes.data[0]?.tee_time?.facility?.province_name}, ${bookingRes.data[0]?.tee_time?.facility?.postal}`;

    ReactDOM.unstable_batchedUpdates(() => {
      setState(prevState => ({ ...prevState, selectedBooking: bookingRes.data[0], formattedAddress: fullAddress }));
      setEditBookingState(prevState => ({
        ...prevState,
        hideCancelButton: hideCancelBooking,
        hideEditButton: hideEditBooking,
      }));
    });
  }

  function checkTeeTimeInPast(booking: IBooking) {
    const bookingDate = booking?.tee_time?.date + " " + booking?.tee_time?.start_time + ":00";
    const currentTime = moment().utc();
    const bookingTime = moment(bookingDate)?.utc();
    const userBooking = booking?.customer_id === bookingEngineStore?.activeCustomerId;
    setEditBookingState(prevState => ({
      ...prevState,
      teeTimeNotInPast: currentTime.isBefore(bookingTime) && userBooking,
    }));
  }

  function checkEditCancelTimes(booking: IBooking) {
    let hideEditButton = false;
    let hideCancelButton = false;
    const completedAtDate = new Date(booking?.completed_at).toUTCString();

    const currentDateUTCString = new Date().toUTCString();

    const teeTimeDateSetUp = new Date(booking?.tee_time?.date + "T" + booking?.tee_time?.start_time);
    //Set date-time based on facility timezone
    const teeTimeDateTimezoneString = new Date(teeTimeDateSetUp).toLocaleString("en-US", {
      timeZone: booking?.tee_time?.facility?.timezone,
    });
    const teeTimeCancelTime = new Date(teeTimeDateTimezoneString);
    const teeTimeEditTime = new Date(teeTimeDateTimezoneString);

    teeTimeCancelTime.setHours(teeTimeCancelTime.getHours() - booking?.tee_sheet_settings?.web_cancellation_hours);
    teeTimeEditTime.setHours(teeTimeEditTime.getHours() - booking?.tee_sheet_settings?.web_edit_hours);

    const teeTimeCancelTimeUTCString = teeTimeCancelTime.toUTCString();
    const teeTimeEditTimeUTCString = teeTimeEditTime.toUTCString();

    if (Date.parse(currentDateUTCString) >= Date.parse(teeTimeCancelTimeUTCString)) {
      hideCancelButton = true;
    }

    if (Date.parse(completedAtDate) <= Date.parse(editDate)) {
      hideEditButton = true;
    } else if (Date.parse(currentDateUTCString) >= Date.parse(teeTimeEditTimeUTCString)) {
      hideEditButton = true;
    }

    //Hide edit/cancel buttons if logged in user did not create the booking
    if (booking?.customer_id !== bookingEngineStore?.activeCustomerId) {
      hideCancelButton = true;
      hideEditButton = true;
    }

    return [hideEditButton, hideCancelButton];
  }

  function backToBookings() {
    history.push(`/booking-engine/${facilityShortName}/${bookingEngineHandle}/profile/reservations`);
  }

  function initiateEditBooking() {
    history.push(
      `/booking-engine/${facilityShortName}/${bookingEngineHandle}/profile/reservations/edit/${state.selectedBooking?.id}`,
    );
  }

  function initiateCancelBooking() {
    setEditBookingState(prevState => ({ ...prevState, cancelPopUp: true }));
  }

  async function cancelBooking() {
    const putCancelRes = await PutCancelBooking({ token: state.selectedBooking?.token }, true);

    if (putCancelRes.status !== StatusCode.OK) {
      uiActions.showError(putCancelRes.data.message);
      setEditBookingState(prevState => ({ ...prevState, cancelPopUp: false }));
      return;
    }

    uiActions.showSuccess(putCancelRes.message);
    history.push(`/booking-engine/${facilityShortName}/${bookingEngineHandle}/profile/reservations`);
  }

  return (
    <div className="booking-engine-reservations">
      {state.selectedBooking && (
        <div className="reservations-view-booking">
          <Card>
            <Card.Section>
              <h1 className="reservations-facility-name">
                {state.selectedBooking?.tee_time?.facility?.long_name}{" "}
                {state.selectedBooking?.tee_time?.course?.long_name !==
                state.selectedBooking?.tee_time?.facility?.long_name
                  ? ` - ${state.selectedBooking?.tee_time?.course?.long_name}`
                  : ""}
              </h1>
              <p className="reservations-address">{state.formattedAddress}</p>
              <div>
                <p className="confirmation-text">
                  Booked By{" "}
                  {
                    <span className="confirmation-number">
                      {state.selectedBooking?.first_name} {state?.selectedBooking?.last_name}
                    </span>
                  }
                </p>
                <p className="confirmation-text">
                  Confirmation Number{" "}
                  {<span className="confirmation-number">{state.selectedBooking?.confirmation_number}</span>}
                </p>
              </div>
              <div className="flex flex-row gap-4 items-center mt-2 mb-4">
                <FontAwesomeIcon className="text-primary-500" icon={["far", "calendar"]} />
                <p className="reservations-date">
                  {moment(
                    `${state.selectedBooking?.tee_time?.date}T${state.selectedBooking?.tee_time?.start_time}`,
                  ).format("LLLL")}
                </p>
                {state?.selectedBooking?.order_id && (
                  <Badge type="primary" size="medium">
                    Pre Paid
                  </Badge>
                )}
                {state?.selectedBooking?.booking_fee_order_id && (
                  <Badge type="primary" size="medium">
                    Booking Fee Paid
                  </Badge>
                )}
              </div>
              <div className="flex flex-row gap-4">
                <div className="reservations-details-container">
                  <FontAwesomeIcon icon={["far", "golf-flag-hole"]} fixedWidth />
                  <p className="reservations-details-container-info">
                    {state.selectedBooking?.holes} {t("guest.customer.customer_nav_menu.view_reservation.002")}
                  </p>
                </div>
                <div className="reservations-details-container">
                  <FontAwesomeIcon icon={["far", "users"]} fixedWidth />
                  <p className="reservations-details-container-info">
                    {state.selectedBooking?.quantity} {t("guest.customer.customer_nav_menu.view_reservation.003")}
                    {state.selectedBooking?.quantity > 1 ? "s" : ""}
                  </p>
                </div>
                <div className="reservations-details-container">
                  <FontAwesomeIcon icon={["far", "car-side"]} fixedWidth />
                  <p className="reservations-details-container-info">
                    {state.selectedBooking?.power_cart_quantity}{" "}
                    {t("guest.customer.customer_nav_menu.view_reservation.004")}
                    {state.selectedBooking?.power_cart_quantity !== 1 ? "s" : ""}
                  </p>
                </div>
              </div>
            </Card.Section>
          </Card>
          <div style={{ margin: "1.2rem 0" }}>
            <Card>
              <Card.Section>
                <div className="reservations-back-to-bookings">
                  <ButtonNew className="w-60" type="default" size="medium" onClick={backToBookings}>
                    {t("guest.customer.customer_nav_menu.view_reservation.005")}
                  </ButtonNew>
                  {!editBookingState.hideCancelButton && (
                    <ButtonNew className="w-60" type="secondary" size="medium" onClick={initiateCancelBooking}>
                      {t("guest.customer.customer_nav_menu.view_reservation.006")}
                    </ButtonNew>
                  )}
                  {editBookingState?.teeTimeNotInPast ? (
                    <ButtonNew className="w-60" type="primary" size="medium" onClick={initiateEditBooking}>
                      {!editBookingState.hideEditButton
                        ? t("guest.customer.customer_nav_menu.view_reservation.007")
                        : t("guest.customer.customer_nav_menu.view_reservation.008")}
                    </ButtonNew>
                  ) : null}
                </div>
              </Card.Section>
            </Card>
          </div>
        </div>
      )}

      <Popup
        onCancel={() => setEditBookingState(prevState => ({ ...prevState, cancelPopUp: false }))}
        onOk={cancelBooking}
        cancelText={t("guest.customer.customer_nav_menu.view_reservation.012")}
        okText={t("guest.customer.customer_nav_menu.view_reservation.009")}
        open={editBookingState.cancelPopUp}
        type="warning"
        title={t("guest.customer.customer_nav_menu.view_reservation.010")}
        description={t("guest.customer.customer_nav_menu.view_reservation.011")}
      />
    </div>
  );
}
