import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { IFacility } from "redux/reducers/models/facility";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/facility";

interface IGetFacilityRes {
  status: number;
  data: IFacility[];
  message: string;
}

interface IPutFacilityRes {
  status: number;
  data: IFacility;
  message: string;
}

type TGetFacility = {
  id?: string | number;
  client_facilities?: boolean;
};
type TPutFacility = {
  address_line_1?: string;
  address_line_2?: string;
  city?: string;
  province_id?: string | number;
  country_id?: string | number;
  postal?: string;
  phone?: string;
  merchant_code?: string;
  logo_source?: string;
  google_analytics_code?: string;
  facebook_pixel_code?: string;
};

// GET Facility
export async function GetFacility(
  params: TGetFacility,
  useGlobalLoader: boolean,
  token?: CancelToken,
): Promise<IGetFacilityRes> {
  return await APIGet(baseUrl, params, useGlobalLoader, token);
}

// PUT Facility
export async function PutFacility(params: TPutFacility, useGlobalLoader: boolean): Promise<IPutFacilityRes> {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

//POST Facility Logo Image
export async function PostFacilityLogo(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/image", params, useGlobalLoader);
}
