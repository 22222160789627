import React, { useEffect, useState } from "react";
import axios, { CancelToken } from "axios";
import { useHistory } from "react-router-dom";

import { StatusCode } from "api/protocols";
import { GetReservationTemplate } from "api/rpc/facilityAdmin/reservation/reservation";

import { showError } from "redux/actions/ui";
import { ITemplate } from "redux/reducers/models/reservations";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";

const CURRENT_URL = "/admin/settings/reservations/templates";
export default function ReservationTemplates() {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [templates, setTemplates] = useState<ITemplate[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadTemplates(source.token);
    return () => source.cancel();
  }, []);

  async function loadTemplates(token?: CancelToken) {
    if (templates !== undefined) {
      setTemplates(undefined);
    }
    const templateRes = await GetReservationTemplate(null, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (templateRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading reservation templates.")); // TODO: Translation
    }

    setTemplates(templateRes.status !== StatusCode.OK ? [] : templateRes.data);
  }

  const primaryAction = {
    content: "New Template", // TODO: Translation
    action: () => history.push(CURRENT_URL + "/new"),
  };

  return (
    <Page narrow title="Reservation Templates" primaryAction={primaryAction}>
      <DataTable
        columns={[
          { label: "Name" }, // TODO: Translation
          { label: "Version" }, // TODO: Translation
          { label: "Module" }, // TODO: Translation
        ]}
        loading={templates === undefined}
      >
        {templates?.map(template => {
          return (
            <tr
              key={template.id}
              onClick={() => history.push(CURRENT_URL + `/${String(template.id)}`)}
              className="clickable"
            >
              <td>{template.name}</td>
              <td>{template.version}</td>
              <td>{template.module.title}</td>
            </tr>
          );
        })}
      </DataTable>
    </Page>
  );
}
