import React, { ReactNode, useEffect, useLayoutEffect, useMemo, useRef } from "react";

import { isEmpty } from "helpers/Helpers";
import AccordionTrigger from "./AccordionTrigger";

import "./accordionItem.scss";

type AccordionItemType<T> = {
  // actions
  toggle?: () => void;
  chevronClick?: () => void;
  leftAction?: ReactNode | Element;

  // content
  title: string;
  helpText?: string;
  badge?: string;

  // styling
  open?: boolean;
  loading?: boolean;
  disabled?: boolean | (() => void);
  relative?: boolean; // relatively position dropdown

  children?: React.ReactNode;
};

export default function AccordionItem<T>(props: AccordionItemType<T>) {
  const { open, toggle } = props;
  const panelRef = useRef<HTMLDivElement>(null);

  const componentDisabled = useMemo(() => {
    return (props.disabled || props.loading) ?? false;
  }, [props.disabled, props.loading]);

  // capture DOM height of the panel before first paint
  useLayoutEffect(() => {
    if (panelRef.current) {
      if (panelRef.current.offsetHeight !== 0) {
        panelRef.current.style.setProperty("--fullHeight", String(panelRef.current.offsetHeight) + "px");
      }
    }
  }, [panelRef.current]);

  // 'ready' class removes height from element.
  useEffect(() => {
    if (panelRef.current) {
      // Capture ASYNC height before adding visiblity to element
      if (panelRef.current.children.length > 0) {
        if (
          isEmpty(panelRef.current.style.getPropertyValue("--fullHeight")) ||
          panelRef.current.style.getPropertyValue("--fullHeight") == "1px"
        ) {
          // preset "1px" if child list does not contain a height
          panelRef.current.style.setProperty(
            "--fullHeight",
            String(panelRef.current.children[0].scrollHeight + 1) + "px",
          );
        }
      }

      if (!open) {
        panelRef.current.classList.add("ready");
      } else {
        panelRef.current.classList.remove("ready");
      }
    }
  }, [open, panelRef, props.loading]);

  function triggerToggle(e: React.MouseEvent<HTMLDivElement | HTMLSpanElement, MouseEvent>) {
    e.stopPropagation();

    if (props.disabled) {
      if (typeof props.disabled !== "boolean") {
        props.disabled();
      }
      return;
    }

    toggle && !componentDisabled && toggle();
  }

  return (
    <div tabIndex={-1} className={`accordion-item${props.disabled ? " disabled" : ""}`}>
      <AccordionTrigger
        open={props.open}
        toggle={e => triggerToggle(e)}
        title={props.title}
        helpText={props.helpText}
        leftAction={props.leftAction}
        badge={props.badge}
        disabled={componentDisabled}
        loading={props.loading}
        chevronClick={props.chevronClick ? () => props.chevronClick() : null}
      />

      {props.relative && (
        <div className={`accordion-panel${open ? " open" : ""}`} ref={panelRef}>
          {props.children}
        </div>
      )}

      {!props.relative && (
        <div className="accordion-panel-overlay">
          <div className={`accordion-panel${open ? " open" : ""}`} ref={panelRef}>
            {props.children}
          </div>
        </div>
      )}
    </div>
  );
}
