import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { ITournamentOrganizer } from "redux/reducers/models/tournament";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const tournamentOrganizerUrl = "/" + apiVersion + "/" + adminType + "/tournament/organizer";

interface IGetTournamentOrganizerRes {
  data: ITournamentOrganizer[];
  status: number;
  message: string;
}
interface IPostTournamentOrganizerRes {
  data: ITournamentOrganizer[];
  status: number;
}
interface IPutTournamentOrganizerRes {
  data: ITournamentOrganizer;
  status: number;
  message: string;
}
interface IDeleteTournamentOrganizerRes {
  data: any;
  status: number;
  message: string;
}

type GetOrganizerParams = {
  id?: number;
  tournament_id?: number;
};
export async function GetTournamentOrganizer(
  params: GetOrganizerParams,
  useGlobalLoader: boolean,
  token?: CancelToken,
): Promise<IGetTournamentOrganizerRes> {
  return await APIGet(tournamentOrganizerUrl, params, useGlobalLoader, token);
}

type PostOrganizerParams = {
  tournament_id: number;
  user_id: number;
};
export async function PostTournamentOrganizer(
  params: PostOrganizerParams,
  useGlobalLoader: boolean,
): Promise<IPostTournamentOrganizerRes> {
  return await APIPost(tournamentOrganizerUrl, params, useGlobalLoader);
}

// type PutOrganizerParams = {

// }

// export async function PutTournamentOrganizer(params: PutOrganizerParams, useGlobalLoader: boolean): Promise<IPutTournamentOrganizerRes> {
//     return await APIGet(tournamentOrganizerUrl, params, useGlobalLoader);
// }

type DeleteOrganizerParams = {
  id: number;
};

export async function DeleteTournamentOrganizer(
  params: DeleteOrganizerParams,
  useGlobalLoader: boolean,
): Promise<IDeleteTournamentOrganizerRes> {
  return await APIGet(tournamentOrganizerUrl, params, useGlobalLoader);
}
