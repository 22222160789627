import { APIGet, APIPut, IAPIResponse } from "api/protocols";

const apiVersion = "2022-09";
const adminType = "client-admin";
const baseCashOutUrl = "/" + apiVersion + "/" + adminType + "/facility/register/shift";

export interface ICashOut {
  id: number;
  register_id: number;
  register_title: string;
  token: string;
  opened_at: string;
  closed_at: string;
  opened_by: string;
  closed_by: number;
  closed_by_full_name: string;
  starting_float: number;
  blind_close: boolean;
  deposit_cash: number;
  deposit_check: number;
  deposit_total: number;
  deposit_bag_number: string;
  notes: string;
  deposit_date: Date;
  deposited_by: number;
}

interface IGetCashOuts {
  id?: number;
  register?: number;
  facility_id?: number;
}

interface IGetCashOutsResponse extends IAPIResponse {
  data: ICashOut[];
}

interface IPutCashOut {
  closed_at: Date;
  deposit_cash: number;
  deposit_check: number;
  deposit_bag_number?: string;
  notes?: string;
  register_id: number;
}

interface IPutCashOutResponse extends IAPIResponse {
  data: ICashOut[];
}

export async function GetCashOuts(params: IGetCashOuts, useGlobalLoader: boolean): Promise<IGetCashOutsResponse> {
  return await APIGet(baseCashOutUrl, params, useGlobalLoader);
}

export async function PutCashOut(params: IPutCashOut, useGlobalLoader: boolean): Promise<IPutCashOutResponse> {
  return await APIPut(baseCashOutUrl + "/close", params, useGlobalLoader);
}

export async function UpdateCashOut(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseCashOutUrl, params, useGlobalLoader);
}

export async function GetCashOutSummary(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseCashOutUrl + "/summary", params, useGlobalLoader);
}
