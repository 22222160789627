import { APIGet, APIPost, APIPut, APIDelete, APIUrl, AdminType } from "api/protocols";
import { CancelToken } from "axios";
import { ITicket } from "redux/reducers/models/ticket";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/ticket";

export type TGetTicket = {
  id?: number;
  customer_id?: number;
  /** Search by title and subtitle */
  search?: string;
  extended?: boolean;
  limit?: number;
  offset?: number;
};

export type TPostTicketBase = {
  id: number;
  title: string;
  subtitle?: string;
  included?: string;
  terms?: string[];
  description?: string;
  logo_image?: string;
  value?: number;
  valid_facilities?: number[];
  product_redemption: any;
};

export type TPostTicketDate = TPostTicketBase & {
  expiry_type?: "date";
  expiry_date: Date;
};

export type TPostTicketDays = TPostTicketBase & {
  expiry_type?: "days";
  expiry_days: number;
};

export type TPostTicketNone = TPostTicketBase & {
  expiry_type?: "none";
};

export type TPostTicket = TPostTicketDate | TPostTicketDays | TPostTicketNone;

type TPostRedemptionProducts = {
  ticket_id: number;
  //Single variant
  variant_id?: number;
  quantity?: number;
  //Multi-variant
  variants?: Array<{
    variant_id: number;
    quantity: number;
  }>;
};

export type TPutTicket = {
  id: number;
};

// GET Tickets
export async function GetTickets(params: TGetTicket, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<ITicket[]>(baseUrl, params, useGlobalLoader, token);
}
// POST Ticket
export async function PostTicket(params: TPostTicket, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}
// PUT Ticket
export async function PutTicket(params: TPutTicket, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

//POST Attach ticket to a product or variant
export async function AttachTicket(
  params: { ticket_id: number; variant_id: number; quantity: number },
  useGlobalLoader: boolean,
) {
  return await APIPost(baseUrl + "/attach", params, useGlobalLoader);
}

//DELETE Detach ticket from a product or variant
export async function DetachTicket(params: { ticket_id: number; variant_id: number }, useGlobalLoader: boolean) {
  return await APIDelete(baseUrl + "/detach", params, useGlobalLoader);
}

export async function PostTicketImage(params: FormData, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/image", params, useGlobalLoader);
}

export async function DeleteTicketImage(params: { id: number }, useGlobalLoader: boolean) {
  return await APIDelete(baseUrl + "/image", params, useGlobalLoader);
}

export async function RemoveRedemptionProduct(params: any, useGlobalLoader: boolean) {
  return await APIDelete(baseUrl + "/redemption/detach", params, useGlobalLoader);
}

interface IGetTicketStub {
  code?: string;
  customer_id?: number;
  id?: number;
  status?: string;
  search?: string;
  extended?: boolean;
}

interface IPutTicketStub {
  code?: string;
  customer_id?: number;
  id?: number;
  status?: string;
  search?: string;
  extended?: boolean;
}
//GET Ticket Stub
export async function GetTicketStub(params: IGetTicketStub, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseUrl + "/stub", params, useGlobalLoader, token);
}

//PUT Ticket Stub
export async function PutTicketStub(params: IPutTicketStub, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/stub", params, useGlobalLoader);
}

interface IGetTicketStubPdf {
  order_id?: number;
  order_line_item_id?: number;
  ticket_stub_ids?: any[];
}

export async function GetTicketStubPdf(params: IGetTicketStubPdf, useGlobalLoader: boolean) {
  return await APIGet(baseUrl + "/stub/pdf", params, useGlobalLoader);
}

// Redeem Ticket Stub
export async function PutRedeem(code: string, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + `/stub/redeem?code=${code}`, null, useGlobalLoader);
}

export async function AddRedemptionProducts(params: TPostRedemptionProducts, useGlobalLoader: boolean) {
  return await APIPost(APIUrl(AdminType.FacilityAdmin, "/ticket/redemption/attach"), params, useGlobalLoader);
}
