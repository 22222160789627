import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "hooks/redux";
import { useParams } from "react-router";

import { GetClient, PutClient } from "api/rpc/2024-04/masterAdmin/client/client";
import { GetCountry, GetProvince } from "api/rpc/2022-09/common/country/country";
import { IFacility } from "redux/reducers/models/facility";
import { IClient } from "redux/reducers/models/client";
import { ICountry, IProvince } from "redux/reducers/models/customer";

import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Input from "components/form/input/Input";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import { Select } from "components/select/";
import { showError, showSuccess } from "redux/actions/ui";

export default function Client(props: any) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { clientId }: any = useParams();
  const { Option } = Select;
  const [clientState, setClientState] = useState<IClient>(undefined);

  const [countryState, setCountryState] = useState<Array<ICountry>>([]);

  useEffect(() => {
    void loadFacility();
  }, []);

  async function loadFacility() {
    const facilityRes = await GetClient({ id: clientId, extended: true }, true);
    if (facilityRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading client"));
      return;
    }

    const countryRes = await GetCountry({ provinces: true }, true);
    if (countryRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading countries"));
      return;
    }

    setClientState(facilityRes.data[0]);
    setCountryState(countryRes.data);
  }

  async function saveFacility() {
    const facilityRes = await PutClient(
      {
        ...clientState,
        id: clientState.id,
      },
      true,
    );

    if (facilityRes.status !== StatusCode.OK) {
      dispatch(showError("Client updating facility"));
      return;
    }

    setClientState(facilityRes.data);
    dispatch(showSuccess("Client updated successfully"));
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setClientState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleDropDownChange(value: any, property: string) {
    setClientState(prev => ({ ...prev, [property]: value }));
  }

  function handleCountryDropDownChange(value: any) {
    const country = countryState?.find(country => country.id === value);
    setClientState(prev => ({
      ...prev,
      country_id: value,
      country_code: country.code,
      country_name: country.name,
    }));
  }

  function handleProvinceDropDownChange(value: any) {
    const province = countryState
      ?.find(country => country.id === clientState.country_id)
      ?.provinces?.find(province => province.id === value);
    if (!province) {
      dispatch(showError("Error changing province"));
      return;
    }
    setClientState(prev => ({
      ...prev,
      province_id: value,
      province_code: province.code,
      province_name: province.name,
    }));
  }

  const primaryAction = {
    content: "Save",
    action: saveFacility,
  };

  const secondaryAction = {
    content: "View Facilities",
    action: () => history.push(`/admin/client/${String(clientId)}/facility`),
  };

  return (
    <Page
      title={clientState?.long_name}
      narrow
      breadcrumbs={[{ prefix: true, label: "Back to clients", url: "/admin/client" }]}
      primaryAction={primaryAction}
      secondaryActions={[secondaryAction]}
    >
      <Card>
        <Card.Section title="Basic Information">
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  label={"Long Name"}
                  id="long_name"
                  value={clientState?.long_name || ""}
                  onChange={handleInputChange}
                />
                <Input
                  label={"Full Name"}
                  id="full_name"
                  value={clientState?.full_name || ""}
                  onChange={handleInputChange}
                />
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
        <Card.Section title="Location Information">
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  label={"Address Line 1"}
                  id="address_line_1"
                  value={clientState?.address_line_1 || ""}
                  onChange={handleInputChange}
                />
                <Input
                  label={"Address Line 2"}
                  id="address_lne_2"
                  value={clientState?.address_line_2 || ""}
                  onChange={handleInputChange}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Input label={"City"} id="city" value={clientState?.city || ""} onChange={handleInputChange} />
                <Input
                  label={"Postal Code"}
                  id="postal"
                  value={clientState?.postal || ""}
                  onChange={handleInputChange}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Select
                  label="Country"
                  onChange={(value: any) => handleCountryDropDownChange(value)}
                  defaultValue={clientState?.country_id}
                >
                  {countryState?.map((country: ICountry, index: number) => {
                    return (
                      <Option key={index} value={country.id}>
                        {country.name}
                      </Option>
                    );
                  })}
                </Select>
                <Select
                  label="Province"
                  onChange={(value: any) => handleProvinceDropDownChange(value)}
                  defaultValue={clientState?.province_id}
                >
                  <Option key={-1} value={-1}>
                    {"None"}
                  </Option>
                  {countryState
                    ?.find(country => country.id === clientState?.country_id)
                    ?.provinces?.map((province: IProvince, index: number) => {
                      return (
                        <Option key={index} value={province.id}>
                          {province.name}
                        </Option>
                      );
                    })}
                </Select>
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
        <Card.Section title="Contact Information">
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  label={"Phone Number"}
                  id="phone"
                  value={clientState?.phone || ""}
                  onChange={handleInputChange}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Input
                  label={"Website URL"}
                  id="website_url"
                  value={clientState?.website_url || ""}
                  onChange={handleInputChange}
                />
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
      </Card>
    </Page>
  );
}
