import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { GetRegisters } from "api/rpc/facilityAdmin/facility/register/register";
import { StatusCode } from "api/protocols";

import Checkbox from "components/form/checkbox/Checkbox";
import Icon from "components/icon/Icon";
import Sheet from "components/sheet/Sheet";
import Spin from "components/spin/spin";

import { IRegister, IRegisterGroup } from "pages/secure/facility/Admin";
import Portal from "elements/Portal";

type RegistersModalProps = {
  open: boolean;
  onClose: (register?: IRegister | undefined) => void;
};

type RegisterModalState = {
  id: number;
  default_folder_id: number;
};

/* Return ID of register if found in localStorage */
function obtainStoredRegister(key: keyof RegisterModalState) {
  const registerJSON = localStorage.getItem("register");
  return registerJSON === null ? null : (JSON.parse(registerJSON)[key] as number);
}

/**
 * Display Facility Registers in a modal for selection. Organized by their RegisterGroups
 * - Default register if found within localStorage.
 * - GetRegisters extended={false} :: populates registers & groups being displayed
 */
export default function RegistersModal(props: RegistersModalProps) {
  const { t } = useTranslation();

  const [registers, setRegisters] = useState<{ registers: IRegister[]; groups: IRegisterGroup[] }>({
    registers: undefined,
    groups: undefined,
  });

  const [state, setState] = useState<RegisterModalState>({
    id: obtainStoredRegister("id"),
    default_folder_id: obtainStoredRegister("default_folder_id"),
  });

  const [refresh, setRefresh] = useState(false);

  // Currently selected localStorage Register
  const selectedRegister = registers.registers?.filter(value => {
    if (value.id === obtainStoredRegister("id")) {
      return true;
    }
  });

  // All other Registers
  const nonSelectedRegisters = registers.registers?.filter(value => {
    if (value.id !== obtainStoredRegister("id") && value.register_group_id) {
      return true;
    }
  });

  // List of register_group ID's
  const uniqueRegisterGroupIds = [...new Set(registers?.groups?.map(value => value?.id))];

  // Reload registers on modal open
  useEffect(() => {
    const source = axios.CancelToken.source();

    if (props.open) {
      void loadRegisters(source.token);
      return;
    }

    return () => {
      source.cancel();
    };
  }, [props.open]);

  // loadRegisters only on Sheet onOk click
  useEffect(() => {
    const source = axios.CancelToken.source();

    if (refresh) {
      void loadRegisters(source.token);
    }

    return () => {
      source.cancel();
    };
  }, [refresh]);

  async function loadRegisters(token: CancelToken) {
    if (registers.registers !== undefined) {
      setRegisters(prev => ({ ...prev, registers: undefined }));
    }

    const res = await GetRegisters({ extended: true }, token ? false : true, token);

    if (res.status === StatusCode.OK && res.data) {
      setRegisters({
        registers: res.data,
        groups: res.data.map((value: IRegister) => value.register_group),
      });

      refresh && setRefresh(false);

      return;
    }

    setRegisters(prev => ({
      ...prev,
      registers: [],
    }));

    refresh && setRefresh(false);
  }

  // Reset State of Modal
  function handleOnClose() {
    setState({ id: obtainStoredRegister("id"), default_folder_id: obtainStoredRegister("default_folder_id") });
    props.onClose();
  }

  function handleRegisterClick(register: IRegister) {
    setState({ id: register.id, default_folder_id: register.default_folder_id });
    props.onClose(register);
  }

  return (
    <Portal isMounted={props.open}>
      <Sheet
        size="small"
        closable
        title={t("secure.facility.admin.021")}
        open={props.open}
        cancelText={t("secure.facility.admin.022")}
        onCancel={() => handleOnClose()}
        // okText={t("secure.facility.admin.023")}
        // onOk={() => props.onClose(registers.registers.find(register => register.id === state.id))}
        okText={"Refresh"}
        onOk={() => setRefresh(true)}
        okDisabled={!registers.registers} // disable 'OK' if loading
      >
        <div className="ui-checkbox-group register-modal">
          {/* Registers Loading */}
          {!registers.registers ? (
            <div style={{ width: "100%", height: "55px" }}>
              <Spin />
            </div>
          ) : (
            <>
              {/* Currently Active Register */}
              {selectedRegister &&
                selectedRegister.map(register => {
                  return (
                    <Fragment key={register.id}>
                      <div>SELECTED REGISTER</div>
                      <div className="selected-register">
                        <div
                          key={register.id}
                          onClick={() => handleRegisterClick(register)}
                          // onClick={() => setState({ id: register.id, default_folder_id: register.default_folder_id })}
                          className={`register-modal-item ui-checkbox-group-item ${
                            state.id === register.id && "ui-checkbox-group-item_selected"
                          }`}
                        >
                          <div className="ui-checkbox-group-item-content">
                            <div className="ui-checkbox-group-item-lead">
                              <Icon size="small" icon="cash-register" style="far" />
                            </div>
                            <div className="ui-checkbox-group-item-text">
                              <p className="text-sm text-medium text-gray-700">{register.title}</p>
                            </div>
                          </div>
                          <Checkbox
                            size="medium"
                            value={register.id}
                            checked={state.id === register?.id}
                            onChange={() =>
                              setState({ id: register.id, default_folder_id: register.default_folder_id })
                            }
                          />
                        </div>
                      </div>
                    </Fragment>
                  );
                })}

              {/* All Other Registers, grouped by register_group */}
              {uniqueRegisterGroupIds &&
                uniqueRegisterGroupIds.map((register_group_id, index) => {
                  return (
                    <Fragment key={register_group_id}>
                      {/* Do not show title if 0 nonSelectedRegisters */}
                      {nonSelectedRegisters.some(register => register.register_group_id === register_group_id) && (
                        <div>{registers.groups?.find(value => value.id === register_group_id).title}</div>
                      )}
                      {/* Display Registers within the associated group */}
                      {nonSelectedRegisters?.map(register => {
                        if (register.register_group_id === register_group_id) {
                          return (
                            <div
                              key={register.id}
                              onClick={() => handleRegisterClick(register)}
                              // onClick={() => setState({ id: register.id, default_folder_id: register.default_folder_id })}
                              className={`register-modal-item ui-checkbox-group-item ${
                                state.id === register.id && "ui-checkbox-group-item_selected"
                              }`}
                            >
                              <div className="ui-checkbox-group-item-content">
                                <div className="ui-checkbox-group-item-lead">
                                  <Icon size="small" icon="cash-register" style="far" />
                                </div>
                                <div className="ui-checkbox-group-item-text">
                                  <p className="text-sm text-medium text-gray-700">{register.title}</p>
                                </div>
                              </div>
                              <Checkbox size="medium" value={register.id} />
                            </div>
                          );
                        }
                      })}
                    </Fragment>
                  );
                })}
            </>
          )}
        </div>
      </Sheet>
    </Portal>
  );
}
