import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { displayCurrency, roundNumber, displayPercent } from "helpers/Helpers";

import { GetProductTypes, GetProduct, GetTaxLines } from "api/rpc/clientAdmin/product/products";
import { GetVendor } from "api/rpc/clientAdmin/vendor";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import { ButtonNew as Button } from "components/buttonNew";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import { Select } from "components/select/";
import Checkbox from "components/form/checkbox/Checkbox";
import _ from "lodash";
import { GetAccountingReference, GetAccounts, GetDepartments } from "api/rpc/clientAdmin/facility/facility";

import "pages/secure/facility/product/Product.scss";
import Sheet from "components/sheet/Sheet";
import InputSearch from "components/input/Input";
import TextEditor from "components/textEditor/textEditor";
import { PostProduct } from "api/rpc/2022-09/clientAdmin/product/product";
import Toggle from "components/form/toggle/Toggle";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { TABLET_WIDTH } from "helpers/ScreenSizes";
import { useAppDispatch } from "hooks/redux";
import { showError } from "redux/actions/ui";
import { useTranslation } from "react-i18next";
import ReactDOM from "react-dom";
import TagInput from "components/tagInput/TagInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TagInputSelect from "components/tagInputSelect/TagInputSelect";
import { TTaxLines } from "redux/reducers/models/facility";

interface IProductState {
  title: string;
  subtitle: string;
  description: string;
  internal_description: string;
  price: number;
  original_price: number;
  price_locked: boolean;
  cost: number;
  type: string;

  customer_required: boolean;
  shipping_required: boolean;
  fulfillment_required: boolean;
  vendor_id: number;
  taxable: boolean;
  department: string;
  category: string;
  subcategory: string;
  account_type: string;
  editing: boolean;
  multiple_options: boolean;
  options: any;
  accounting_reference_id: string;

  department_id: string;
  category_id: string;
  subcategory_id: string;
  membership_type: string;
  meta: any;
  account_id: number;
  tax_line_ids: any[];
  track_inventory: boolean;

  accounting_reference_id_search: ISearchField;
  type_search: ISearchField;
  auto_generate_sku: boolean;
  vendorSearch: ISearchField;
}

interface ITypes {
  client_id: number;
  id: number;
  title: string;
}

interface IVendors {
  client_id: number;
  id: number;
  title: string;
}

interface IAccountingReference {
  client_id: number;
  id: number;
  title: string;
}

interface IDepartment {
  client_id: number;
  id: number;
  title: string;
  parent_id: number;
  type?: string;
}

interface IProductOptionsState {
  types: ITypes[];
  vendors: IVendors[];
  accounts: [];
  accountingReference: IAccountingReference[];
  departments: IDepartment[];
  categories: IDepartment[];
  subcategories: IDepartment[];
  categoriesToDisplay: IDepartment[];
  subcategoriesToDisplay: IDepartment[];
  chosenDepartmentId: number;
  chosenCategoryId: number;
  chosenSubcategoryId: number;
  chosenAccountingReferenceId: number;
  tax_line_id: number;
  availableTaxLines: TTaxLines[];
  allTaxLines: TTaxLines[];
}

interface IComboProductState {
  comboProductsActive?: boolean;
  currentAddedProducts?: IProduct[];
  allProducts?: any;
  productsToUpdate?: Map<any, any>;
}

interface IProduct {
  id?: number;
  title?: string;
  value?: any;
  included?: string;
  quantity?: number;
}

interface IFilterState {
  search: string;
  productTypeSearch: string;
  sort: string;
  add_internal_description: boolean;
  vendorSearch: string;
}

interface ISearchField {
  data: string;
  isDirty: boolean;
  isValid: boolean;
}

export default function ProductNew() {
  const { Option } = Select;

  const history = useHistory();
  const windowSize = useWindowSize();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const [productState, setProductState] = useState<IProductState>({
    title: "",
    subtitle: "",
    editing: false,
    description: "",
    internal_description: "",
    price: null,
    original_price: null,
    price_locked: false,
    cost: null,
    type: "",
    customer_required: false,
    shipping_required: false,
    fulfillment_required: false,
    vendor_id: null,
    taxable: true,
    department: "",
    category: "",
    subcategory: "",
    account_type: "",
    multiple_options: false,
    options: [],
    accounting_reference_id: "",
    department_id: "",
    category_id: "",
    subcategory_id: "",
    membership_type: "",
    meta: {
      membership_type: "",
    },
    account_id: null,
    tax_line_ids: [],
    track_inventory: false,
    type_search: { data: "", isDirty: false, isValid: false },
    accounting_reference_id_search: { data: "", isDirty: false, isValid: false },
    auto_generate_sku: false,
    vendorSearch: { data: "", isDirty: false, isValid: false },
  });

  const [productOptionState, setProductOptionState] = useState<IProductOptionsState>({
    types: [],
    vendors: [],
    accounts: [],
    accountingReference: [],
    departments: [],
    categories: [],
    subcategories: [],
    categoriesToDisplay: [],
    subcategoriesToDisplay: [],
    chosenDepartmentId: null,
    chosenAccountingReferenceId: null,
    chosenCategoryId: null,
    chosenSubcategoryId: null,
    tax_line_id: null,
    availableTaxLines: [],
    allTaxLines: [],
  });

  const [comboProductState, setComboProductState] = useState<IComboProductState>({
    comboProductsActive: false,
    currentAddedProducts: [],
    allProducts: [],
    productsToUpdate: new Map(),
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    search: "",
    productTypeSearch: "",
    sort: "alpha",
    add_internal_description: false,
    vendorSearch: "",
  });

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    console.log(value);
    //Handle Tag Input - Deep Object Value - Ignore ','
    if (id.includes("[")) {
      if (value !== ",") {
        const tempProductState = productState;
        _.set(tempProductState, id, value);
        setProductState(() => ({ ...tempProductState }));
      }
    } else {
      setProductState(prevState => ({ ...prevState, [id]: value }));
    }
  }

  function handleDescriptionChange(description: string) {
    setProductState(prevState => ({ ...prevState, description }));
  }

  function handleCheckboxChange(event: any) {
    const { id, checked } = event.target;
    setProductState(prevState => ({
      ...prevState,
      [id]: checked,
      options: checked ? [{ name: "", values: [""] }] : [],
    }));
  }

  function handleCreateProduct() {
    void createProduct();
  }

  useEffect(() => {
    void loadProductTypes();
    void loadVendors();
    void loadAccounts();
    void loadAccountingReference();
    void loadDepartments();
    void loadTaxLines();
  }, []);

  async function loadProductTypes() {
    const productTypeRes = await GetProductTypes(true);
    if (productTypeRes.status !== StatusCode.OK) {
      return;
    }

    console.log(productTypeRes);

    const products = productTypeRes.data;
    products.unshift({
      id: null,
      title: "",
    });

    setProductOptionState(prev => ({
      ...prev,
      types: productTypeRes.data,
    }));
  }

  async function loadVendors() {
    const vendorRes = await GetVendor(null, true);
    if (vendorRes.status !== StatusCode.OK) {
      return;
    }

    const vendors = vendorRes.data;
    // vendors.unshift({
    //   id: null,
    //   title: "",
    // });

    setProductOptionState(prev => ({
      ...prev,
      vendors: vendorRes.data,
    }));
  }

  async function loadAccounts() {
    const accountRes = await GetAccounts(true);
    console.log(accountRes);
    if (accountRes.status !== StatusCode.OK) {
      return;
    }

    const accounts = accountRes.data;

    accounts.unshift({
      id: null,
      title: "",
    });

    setProductOptionState(prev => ({
      ...prev,
      accounts: accountRes.data,
    }));
  }

  async function loadAccountingReference() {
    const accountingRes = await GetAccountingReference(null, true);
    console.log(accountingRes);
    if (accountingRes.status !== StatusCode.OK) {
      return;
    }

    const accounts = accountingRes.data;

    accounts.unshift({
      id: null,
      title: "",
    });

    setProductOptionState(prev => ({
      ...prev,
      accountingReference: accountingRes.data,
    }));
  }

  async function loadDepartments() {
    const departmentRes = await GetDepartments(true);
    console.log(departmentRes);
    if (departmentRes.status !== StatusCode.OK) {
      return;
    }

    const departments = departmentRes.data;
    departments.unshift({
      id: null,
      title: "",
      type: "department",
    });

    setProductOptionState(prev => ({
      ...prev,
      departments: departmentRes.data,
    }));
  }

  async function createProduct() {
    const tempProductState = productState;

    if (!productState?.type_search?.isValid || !productState?.accounting_reference_id_search?.isValid) {
      setProductState(prevState => ({
        ...prevState,
        type_search: { ...prevState?.type_search, isDirty: true },
        accounting_reference_id_search: { ...prevState?.accounting_reference_id_search, isDirty: true },
      }));

      dispatch(showError("Product Type & Accounting Reference Required"));

      return;
    }

    //Remove blank options
    for (let i = 0; i < tempProductState.options.length; i++) {
      if (tempProductState.options[i].name.trim() === "") {
        tempProductState.options.splice(i, 1);
      } else {
        //Remove blank variants
        for (let j = 0; j < tempProductState.options[i].values.length; j++) {
          if (tempProductState.options[i].values[j].trim() === "") {
            tempProductState.options[i].values.splice(j, 1);
          }
        }
      }
    }

    tempProductState.meta.membership_type = tempProductState.membership_type;

    const productRes = await PostProduct(tempProductState, true);
    if (productRes.status !== StatusCode.OK) {
      if (productRes.status === StatusCode.BAD_REQUEST) {
        dispatch(showError(productRes.data));
      } else {
        dispatch(showError("Error Creating Product"));
      }
      return;
    }

    history.push("/admin/product");

    return;
  }

  async function loadTaxLines() {
    const taxLineRes = await GetTaxLines(true);

    if (taxLineRes.status !== StatusCode.OK) {
      return;
    }

    setProductOptionState(prev => ({
      ...prev,
      availableTaxLines: taxLineRes.data,
      allTaxLines: taxLineRes.data,
    }));
  }

  function handleSelectChange(event: any) {
    const { id, value } = event.target;
    console.log(id, value);
    setProductState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleDropDownChange(value: any, property: string) {
    setProductState(prevState => ({ ...prevState, [property]: value }));

    if (property === "type_search" || property === "accounting_reference_id_search" || property === "vendorSearch") {
      switch (property) {
        case "type_search":
          setProductState(prevState => ({ ...prevState, type: value }));
          if (value === "Modifier") {
            setProductState(prevState => ({ ...prevState, multiple_options: false, options: [] }));
          }

          break;
        case "accounting_reference_id_search":
          setProductState(prevState => ({ ...prevState, accounting_reference_id: value }));

          break;
        case "vendorSearch":
          setProductState(prevState => ({ ...prevState, vendor_id: value }));

          break;
        default:
          break;
      }

      setProductState(prevState => ({
        ...prevState,
        [property]: { ...prevState[property as keyof IProductState], data: value, isValid: true, isDirty: true },
      }));
    } else {
      setProductState(prevState => ({ ...prevState, [property]: value }));
    }
  }

  function handleTaxDropDownChange(value: any, property: string) {
    setProductOptionState(prevState => ({ ...prevState, [property]: value }));
  }

  function addOption(e: any) {
    e.preventDefault();
    const options = productState.options;

    // Can only add 3 options
    if (options.length >= 3) {
      return;
    }

    options.push({ name: "", values: [""] });

    setProductState(prevState => ({ ...prevState, options: options }));
    console.log(productState);
  }

  function handleTagClick(optionIndex: number, index: number) {
    const tmpProductState = productState;

    tmpProductState.options[optionIndex].values.splice(index, 1);
    setProductState(() => ({ ...tmpProductState }));
  }

  function handleAddTag(event: any) {
    const { key } = event;
    const { id } = event.target;
    const regex = /^(?!\s,*$).+/g;
    const tmpProductState = productState;
    const arrayId: string = id.substring(0, id.length - 3);

    if (key === "Enter" || key === ",") {
      if (_.get(tmpProductState, id).match(regex) !== null) {
        _.invoke(tmpProductState, `${arrayId}.push`, "");
        setProductState(prevState => ({ ...prevState, ...tmpProductState }));
      }
    }
  }

  const primaryAction = {
    content: "Create",
    action: handleCreateProduct,
  };

  const membershipOptions = [
    {
      id: "single_course",
      title: "Single",
    },
    {
      id: "multi_course",
      title: "Multi-Course",
    },
  ];

  async function openComboProducts() {
    const productRes = await GetProduct(null, true);

    console.log(productRes);

    if (productRes.status !== StatusCode.OK) {
      return;
    }

    setComboProductState(prev => ({
      ...prev,
      comboProductsActive: true,
      allProducts: productRes.data,
    }));
  }

  function closeComboProduct() {
    setComboProductState(prev => ({
      ...prev,
      comboProductsActive: false,
    }));
  }

  function updateFilterState(newFilterState: Partial<IFilterState>) {
    setFilterState(cur => {
      return { ...cur, ...newFilterState };
    });
  }

  async function handleAddProduct(event: any, ticket: any) {
    //Add product ID to product
  }

  function handleAddTaxLine(event: any) {
    console.log(productOptionState.tax_line_id);

    const tempTaxLineList = productState.tax_line_ids;

    if (tempTaxLineList.includes(productOptionState.tax_line_id)) {
      return;
    }

    tempTaxLineList.push(productOptionState.tax_line_id);

    setProductState(prevState => ({ ...prevState, tax_line_ids: tempTaxLineList }));
  }

  function removeTaxLine(taxLine: any) {
    console.log(taxLine);
    console.log(productState.tax_line_ids);

    const tempTaxLineList = productState.tax_line_ids;

    console.log(tempTaxLineList);

    const taxIndexToRemove = tempTaxLineList.indexOf(taxLine);

    if (taxIndexToRemove > -1) {
      tempTaxLineList.splice(taxIndexToRemove, 1);
    }

    console.log(tempTaxLineList);

    setProductState(prevState => ({ ...prevState, tax_line_ids: tempTaxLineList }));
  }

  const handleProductTypeSearch = (query: string, isValid: boolean) => {
    setFilterState(prevState => ({ ...prevState, productTypeSearch: query }));

    if (query === "") {
      setProductState(prevState => ({ ...prevState, type: "" }));
    }

    setProductState(prevState => ({
      ...prevState,
      type_search: {
        ...prevState["type_search" as keyof IProductState],
        data: query ? prevState?.type_search?.data : null,

        isValid: isValid,
        isDirty: query ? true : prevState?.type_search?.isDirty,
      },
    }));
  };

  const handleVendorSearch = (query: string, isValid: boolean) => {
    setFilterState(prevState => ({ ...prevState, vendorSearch: query }));

    setProductState(prevState => ({
      ...prevState,
      vendorSearch: {
        ...prevState["vendorSearch" as keyof IProductState],
        data: query ? prevState?.vendorSearch?.data : null,

        isValid: isValid,
        isDirty: query ? true : prevState?.vendorSearch?.isDirty,
      },
    }));
  };

  function renderChargeTaxes() {
    return [
      <Checkbox
        key={1}
        id="taxable"
        size="medium"
        value={productState.taxable}
        checked={productState.taxable}
        onChange={handleCheckboxChange}
        label="Charge Taxes"
      />,

      <div key={2}></div>,
      <div key={3} style={{ marginLeft: "-2rem" }}>
        <React.Fragment>
          {productState.taxable ? (
            <FormLayout.Group>
              <div>
                <Select label="Tax Lines" onChange={(value: any) => handleTaxDropDownChange(value, "tax_line_id")}>
                  {productOptionState.availableTaxLines.map((taxLine: any, index: number) => {
                    return (
                      <Option key={index} value={taxLine.id} name={taxLine.name}>
                        {taxLine.name}
                      </Option>
                    );
                  })}
                </Select>

                <div>
                  {productState.tax_line_ids.map((taxLine: any, index: number) => {
                    let taxName = "";
                    console.log(taxLine);
                    console.log(productOptionState.availableTaxLines);

                    for (let i = 0; i < productOptionState.availableTaxLines.length; i++) {
                      if (taxLine === productOptionState.availableTaxLines[i].id) {
                        taxName = productOptionState.availableTaxLines[i].name;
                      }
                    }

                    return (
                      <div key={index} style={{ display: "flex", justifyContent: "space-between", padding: "5px 5px" }}>
                        <p>{taxName}</p>

                        <button onClick={() => removeTaxLine(taxLine)}>X - Remove</button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </FormLayout.Group>
          ) : null}
        </React.Fragment>
      </div>,

      <div key={4} style={{ marginTop: "25px" }}>
        <Button className="" style={{ height: "44px" }} type="default" onClick={handleAddTaxLine}>
          Add Tax
        </Button>
      </div>,

      // <React.Fragment key={2}>
      //   {productState.taxable ? (
      //     <FormLayout.Group>
      //       <div>
      //         <Select label="Tax Lines" onChange={(value: any) => handleTaxDropDownChange(value, "tax_line_id")}>
      //           {productOptionState.availableTaxLines.map((taxLine: any, index: number) => {
      //             return (
      //               <Option key={index} value={taxLine.id} name={taxLine.name}>
      //                 {taxLine.name}
      //               </Option>
      //             );
      //           })}
      //         </Select>

      //         <div>
      //           {productState.tax_line_ids.map((taxLine: any, index: number) => {
      //             let taxName = "";
      //             console.log(taxLine);
      //             console.log(productOptionState.availableTaxLines);

      //             for (let i = 0; i < productOptionState.availableTaxLines.length; i++) {
      //               if (taxLine === productOptionState.availableTaxLines[i].id) {
      //                 taxName = productOptionState.availableTaxLines[i].name;
      //               }
      //             }

      //             return (
      //               <div key={index} style={{ display: "flex", justifyContent: "space-between" }}>
      //                 <p>{taxName}</p>

      //                 <button onClick={() => removeTaxLine(taxLine)}>X - Remove</button>
      //               </div>
      //             );
      //           })}
      //         </div>
      //       </div>

      //       <Button className="" type="default" onClick={handleAddTaxLine}>
      //         Add Tax
      //       </Button>
      //     </FormLayout.Group>
      //   ) : null}
      // </React.Fragment>,
    ];
  }

  const handleAccountingSearch = (query: string, isValid: boolean) => {
    setFilterState(prevState => ({ ...prevState, search: query }));

    if (query === "") {
      setProductState(prevState => ({ ...prevState, accounting_reference_id: "" }));
    }

    setProductState(prevState => ({
      ...prevState,
      accounting_reference_id_search: {
        ...prevState["accounting_reference_id_search" as keyof IProductState],
        data: query ? prevState?.accounting_reference_id_search?.data : null,
        isValid: isValid,
        isDirty: query ? true : prevState?.accounting_reference_id_search?.isDirty,
      },
    }));
  };

  function handleOpenInternalDescription() {
    if (filterState.add_internal_description === true) {
      setFilterState(prevState => ({ ...prevState, add_internal_description: false }));
    } else {
      setFilterState(prevState => ({ ...prevState, add_internal_description: true }));
    }
  }

  function handleInternalDescriptionChange(internal_description: string) {
    setProductState(prevState => ({ ...prevState, internal_description }));
  }

  function handlePricingLockAllChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, checked } = event.target;
    setProductState(prevState => ({ ...prevState, price_locked: checked }));
  }

  function removeVariantOption(index: number) {
    const tempProduct = { ...productState };
    tempProduct.options?.splice(index, 1);
    setProductState(prevState => ({
      ...prevState,
      ...tempProduct,
      multiple_options: tempProduct?.options?.length === 0 ? false : prevState.multiple_options,
    }));
  }

  function handleRemoveTaxLine(tax_line_id: any) {
    const currentLists = [...productOptionState.availableTaxLines];
    const line = productOptionState.allTaxLines.find(val => val.id === tax_line_id);

    currentLists.push(line);

    setProductState(prev => ({
      ...prev,
      tax_line_ids: prev.tax_line_ids.filter(lineId => lineId !== tax_line_id),
    }));
    setProductOptionState(prevState => ({ ...prevState, availableTaxLines: currentLists }));
  }

  function handleTaxDropdown(tax_line_id: number) {
    tax_line_id && !productState.tax_line_ids.includes(tax_line_id)
      ? setProductState(prev => ({ ...prev, tax_line_ids: [...prev.tax_line_ids, tax_line_id] }))
      : undefined;

    const currentLists = [...productOptionState.availableTaxLines];
    const foundIndex = currentLists.findIndex(list => tax_line_id === list.id);

    if (foundIndex !== -1) {
      currentLists.splice(foundIndex, 1);
    }

    setProductOptionState(prevState => ({ ...prevState, availableTaxLines: currentLists }));
  }

  return (
    <Page
      title="New Product"
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[{ label: "Products", url: "/admin/product" }]}
    >
      <Form>
        <Card title="Product Details">
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={productState.title}
                  label="Title"
                  id="title"
                  onChange={handleInputChange}
                  placeholder="Title"
                />
                <Input
                  value={productState.subtitle}
                  label="Subtitle"
                  id="subtitle"
                  onChange={handleInputChange}
                  placeholder="Subtitle"
                />
              </FormLayout.Group>
              {/* <FormLayout.Group>
                <TextEditor
                  markdownText={productState.description || ""}
                  markdownTextOnChange={handleDescriptionChange}
                  label="Description"
                />
              </FormLayout.Group> */}

              <FormLayout.Group>
                <div>
                  <TextEditor
                    markdownText={productState.description || ""}
                    markdownTextOnChange={handleDescriptionChange}
                    label="Description"
                  />

                  <Button type="link" size="small" onClick={handleOpenInternalDescription}>
                    Add Internal Description
                  </Button>
                </div>
              </FormLayout.Group>

              {filterState.add_internal_description ? (
                <FormLayout.Group>
                  <TextEditor
                    markdownText={productState.internal_description ?? ""}
                    markdownTextOnChange={handleInternalDescriptionChange}
                    label={"Internal Description"}
                  />
                </FormLayout.Group>
              ) : null}
            </FormLayout>
          </Card.Section>
          <Card.Section title="Pricing">
            <FormLayout>
              <FormLayout.Group>
                <Input
                  prefix="$"
                  value={productState.price || ""}
                  label="Price"
                  id="price"
                  onChange={handleInputChange}
                  placeholder="0.00"
                />
                <Input
                  prefix="$"
                  value={productState.original_price || ""}
                  label="Original Price"
                  id="original_price"
                  onChange={handleInputChange}
                  placeholder="0.00"
                />
                <Input
                  prefix="$"
                  value={productState.cost || ""}
                  label="Cost"
                  id="cost"
                  onChange={handleInputChange}
                  placeholder="0.00"
                />
                <div className="mt-4">
                  <p className="product-profit-margin">
                    {displayPercent(
                      roundNumber(100 * ((productState.price - productState.cost) / productState.price), 0),
                    )}{" "}
                    -- {displayCurrency("cad", productState.price - productState.cost)}
                  </p>
                  <p className="product-profit-margin">Profit Margin</p>
                </div>
              </FormLayout.Group>
              <FormLayout.Group>
                {/* {windowSize.width <= TABLET_WIDTH ? <div>{renderChargeTaxes()}</div> : renderChargeTaxes()} */}

                <div className="pt-5 pb-3">
                  <div className="edit-tax-lines_title_container">
                    <Checkbox
                      key={1}
                      id="taxable"
                      size="medium"
                      value={productState.taxable}
                      checked={Boolean(productState?.taxable)}
                      onChange={e => setProductState(prev => ({ ...prev, taxable: e.target.checked }))}
                      label={t("secure.facility.product.product_new.017")}
                    />
                  </div>

                  <React.Fragment>
                    {productState.taxable ? (
                      <div className="edit-tax-lines_single_variant_container">
                        <TagInputSelect
                          tags={productState.tax_line_ids.map((taxLine, index: number) => {
                            const line = productOptionState.allTaxLines.find(val => val.id === taxLine);
                            return { name: `${line.name} (${line.percentage}%)`, id: line.id };
                          })}
                          selectOptions={productOptionState.availableTaxLines?.map(line => {
                            return {
                              name: `${line.name} (${line.percentage}%)`,
                              id: line.id,
                            };
                          })}
                          onChange={value => {
                            handleTaxDropdown(Number(value));
                          }}
                          onTagClick={taxLineId => handleRemoveTaxLine(taxLineId)}
                          className="edit-tax-lines_single_variant_container_tag_input"
                          helpText={"Select tax lines to add to this product"} // TODO: Translation
                        />
                      </div>
                    ) : null}
                  </React.Fragment>
                </div>
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>

        <Card title="Pricing Lock">
          <div className="mt-4 ml-4">
            <Toggle
              id={"price_locked"}
              // value={channel.facility_id}
              labelTop={"Lock Pricing - All Facilities"}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePricingLockAllChange(e)}
              checked={productState.price_locked}
              // disabled={!productPermissions?.products_edit}
            ></Toggle>
          </div>
        </Card>

        {productState.type !== "Modifier" ? (
          <Card title="Variants">
            <Card.Section>
              <FormLayout>
                <FormLayout.Group>
                  <Checkbox
                    id="multiple_options"
                    size="medium"
                    value={productState.multiple_options}
                    checked={productState.multiple_options}
                    onChange={handleCheckboxChange}
                    label="This product has multiple options"
                  />
                  <div className="product-add-new-option">
                    <Button
                      onClick={
                        productState.options.length < 3
                          ? e =>
                              setProductState(prev => ({
                                ...prev,
                                options: [...prev.options, { name: "", values: [""] }],
                              }))
                          : undefined
                      }
                      disabled={!productState.multiple_options || productState.options.length > 2}
                    >
                      Add Option
                    </Button>
                  </div>
                </FormLayout.Group>
              </FormLayout>
              {productState.multiple_options ? (
                <>
                  {productState.options.map((option: any, index: number) => {
                    return (
                      <Card.SubSection key={index}>
                        <FormLayout>
                          <FormLayout.Group>
                            <Input
                              value={option.name || ""}
                              label={`Option ${index + 1}`}
                              id={`[options][${index}][name]`}
                              onChange={handleInputChange}
                              placeholder="Name"
                            />

                            <div>
                              <TagInput
                                label={"Values"}
                                id={`[options][${index}][values][${
                                  option.values.length === 0 ? 0 : option.values.length - 1
                                }]`}
                                value={option.values[option.values.length === 0 ? 0 : option.values.length - 1]}
                                onChange={handleInputChange}
                                onKeyDown={handleAddTag}
                                placeholder="Values"
                                tags={option.values.slice(0, option.values.length - 1)}
                                onTagClick={(tagIndex: number) => handleTagClick(index, tagIndex)}
                                helpText="Add multiple tags with ' , ' or Enter."
                              />
                            </div>
                            <FontAwesomeIcon
                              className="ml-auto block mt-8 cursor-pointer"
                              icon={["far", "x"]}
                              onClick={() => removeVariantOption(index)}
                            />
                          </FormLayout.Group>
                        </FormLayout>
                      </Card.SubSection>
                    );
                  })}
                </>
              ) : null}
            </Card.Section>
          </Card>
        ) : null}

        <Card title="Logistics">
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <div>
                  <Select
                    label={"Product Types"}
                    placeholder="Search Product Types.."
                    showSearch
                    className={`flex justify-center align-center w-full h-10 position-relative z-20 text-black font-medium appearance-none border-none focus:outline-none placeholder-gray-200`}
                    onSearch={(query: string) => handleProductTypeSearch(query, query !== "" && query !== null)}
                    onChange={(value: any) => handleDropDownChange(value, "type_search")}
                    allowClear
                    searchValue={filterState.productTypeSearch}
                    showDropDownOnFocus={false}
                    hideDropdown={true}
                    error={productState?.type_search?.isDirty && !productState.type_search.data}
                  >
                    {productOptionState.types
                      .filter(productType =>
                        String(productType.title).toLowerCase().includes(filterState.productTypeSearch.toLowerCase()),
                      )
                      .map((type: any, i: number) => {
                        return (
                          <div key={i}>
                            {filterState.productTypeSearch !== "" ? (
                              <div>
                                <Option key={i} value={type.title} name={type.title}>
                                  <div className="flex justify-between">
                                    <div>
                                      <div className="font-semibold text-lg">{type?.title}</div>
                                    </div>
                                  </div>
                                </Option>
                              </div>
                            ) : null}
                          </div>
                        );
                      })}
                  </Select>
                  {/* <Select label={"Type"} onChange={(value: any) => handleDropDownChange(value, "type")}>
                    {productOptionState.types.map((type: any, index: number) => {
                      return (
                        <Option key={index} value={type.title} name={type.title}>
                          {type.title}
                        </Option>
                      );
                    })}
                  </Select> */}
                </div>

                <div>
                  <Select
                    label={t("secure.facility.product.product.027")}
                    onChange={(value: number) => handleDropDownChange(value, "vendorSearch")}
                    className={`flex justify-center align-center w-full h-10 position-relative z-20 text-black font-medium appearance-none border-none focus:outline-none placeholder-gray-200`}
                    onSearch={(query: string) => handleVendorSearch(query, query !== "" && query !== null)}
                    placeholder={"Search vendors..."}
                    searchValue={filterState.vendorSearch || ""}
                    error={productState?.vendorSearch?.isDirty && !productState.vendorSearch.data}
                    showSearch
                    showDropDownOnFocus
                    hideDropdown
                    allowClear
                  >
                    {productOptionState.vendors
                      .filter(val => val.title?.toLowerCase().includes(filterState.vendorSearch?.toLowerCase()))
                      .map((vendor, index: number) => {
                        return (
                          <Select.Option key={index} value={vendor.id} name={vendor.title}>
                            {vendor.title}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </div>
                <div>
                  <Select label="Accounts" onChange={(value: any) => handleDropDownChange(value, "account_id")}>
                    {productOptionState.accounts.map((account: any, index: number) => {
                      return (
                        <Option key={index} value={account.id} name={account.title}>
                          {account.title}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </FormLayout.Group>
              <br />

              <FormLayout.Group>
                <div>
                  <Select
                    label={"Accounting Reference"}
                    showSearch
                    className={`flex justify-center align-center w-full h-10 position-relative z-20 text-black font-medium appearance-none border-none focus:outline-none placeholder-gray-200`}
                    onSearch={(query: string) => handleAccountingSearch(query, query !== "" && query !== null)}
                    onChange={(value: any) => handleDropDownChange(value, "accounting_reference_id_search")}
                    allowClear
                    searchValue={filterState.search}
                    showDropDownOnFocus={true}
                    error={
                      productState?.accounting_reference_id_search?.isDirty &&
                      !productState.accounting_reference_id_search.data
                    }
                  >
                    {productOptionState.accountingReference
                      .filter(accountingReference =>
                        String(accountingReference.title).toLowerCase().includes(filterState.search.toLowerCase()),
                      )
                      .map((reference: any, i: number) => {
                        return (
                          <div key={i}>
                            {filterState.search !== "" ? (
                              <div>
                                <Option key={i} value={reference.id} name={reference.title}>
                                  <div className="flex justify-between">
                                    <div>
                                      <div className="font-semibold text-lg">{reference?.title}</div>
                                    </div>
                                  </div>
                                </Option>
                              </div>
                            ) : null}
                          </div>
                        );
                      })}
                  </Select>

                  {/* <Select
                    label="Accounting Reference"
                    onChange={(value: any) => handleDropDownChange(value, "accounting_reference_id")}
                  >
                    {productOptionState.accountingReference.map((accountingReference: any, index: number) => {
                      return (
                        <Option key={index} value={accountingReference.id} name={accountingReference.title}>
                          {accountingReference.title}
                        </Option>
                      );
                    })}
                  </Select> */}
                </div>

                <div></div>
                <div></div>
              </FormLayout.Group>
              <br />
              <FormLayout.Group>
                <div>
                  <Checkbox
                    id="customer_required"
                    size="medium"
                    value={productState.customer_required}
                    checked={productState.customer_required}
                    onChange={handleCheckboxChange}
                    label="Customer Required"
                  />
                  <Checkbox
                    id="shipping_required"
                    size="medium"
                    value={productState.shipping_required}
                    checked={productState.shipping_required}
                    onChange={handleCheckboxChange}
                    label="Shipping Required"
                  />
                  {/* <Checkbox
                    id="auto_generate_sku"
                    size="medium"
                    value={productState.auto_generate_sku}
                    checked={Boolean(productState?.auto_generate_sku)}
                    onChange={e =>
                      setProductState(prev => ({ ...prev, auto_generate_sku: e.target.checked ? true : false }))
                    }
                    label={"Auto Generate Sku"}
                  /> */}
                </div>
                <div>
                  <Checkbox
                    id="fulfillment_required"
                    size="medium"
                    value={productState.fulfillment_required}
                    checked={productState.fulfillment_required}
                    onChange={handleCheckboxChange}
                    label="Fulfillment Required"
                  />
                  <Checkbox
                    id="track_inventory"
                    size="medium"
                    value={productState.track_inventory}
                    checked={productState.track_inventory}
                    onChange={handleCheckboxChange}
                    label="Track Inventory"
                  />
                </div>
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
          <Card.Section title="Departments">
            <FormLayout>
              <FormLayout.Group>
                <div>
                  <Select label="Department" onChange={(value: any) => handleDropDownChange(value, "department_id")}>
                    {productOptionState.departments
                      .filter(department => department.type === "department")
                      .map((department: any, index: number) => {
                        return (
                          <Option key={index} value={department.id} name={department.title}>
                            {department.title}
                          </Option>
                        );
                      })}
                  </Select>
                </div>

                <div>
                  <Select label="Category" onChange={(value: any) => handleDropDownChange(value, "category_id")}>
                    {productOptionState.departments
                      .filter(
                        department =>
                          department.type == "category" && department.parent_id == Number(productState.department_id),
                      )
                      .map((department: any, index: number) => {
                        return (
                          <Option key={index} value={department.id} name={department.title}>
                            {department.title}
                          </Option>
                        );
                      })}
                  </Select>
                </div>

                <div>
                  <Select label="Subcategory" onChange={(value: any) => handleDropDownChange(value, "subcategory_id")}>
                    {productOptionState.departments
                      .filter(
                        department =>
                          department.type == "subcategory" && department.parent_id == Number(productState.category_id),
                      )
                      .map((department: any, index: number) => {
                        return (
                          <Option key={index} value={department.id} name={department.title}>
                            {department.title}
                          </Option>
                        );
                      })}
                  </Select>
                </div>
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>

        <br />

        {/* Combo Product Sheet - Handles adding product to product */}
        <Sheet
          open={comboProductState.comboProductsActive}
          title={"Combo Product"}
          size="small"
          closable
          onCancel={closeComboProduct}
          cancelText={"Cancel"}
          backDropCancel={false}
        >
          <div className="ui-register-top-bar py-3 px-2">
            <div>
              <InputSearch
                value={filterState.search}
                onChange={(value: any) => updateFilterState({ search: value.target.value })}
                type="search"
                placeholder="Search for a product..."
                className="ui-register-search flex-grow"
              ></InputSearch>
            </div>
          </div>

          <div className="overflow-auto">
            {comboProductState.allProducts

              .filter((product: any) => Boolean(product.title.toLowerCase().includes(filterState.search.toLowerCase())))
              .sort((firstProd: any, secondProd: any) => {
                if (filterState.search === "alpha") {
                  return firstProd.title > secondProd.title;
                }
              })
              .map((product: any, index: number) => {
                let disabled;
                let cursorOption;
                let productTitle = "";

                console.log(product);

                if (product.vendor !== null) {
                  productTitle = product.vendor.title;
                }

                return (
                  <div key={index}>
                    <br />
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div className="product-display-add-product">
                        <p className="" style={{ textAlign: "center", fontSize: "14px", fontWeight: 600 }}>
                          {product.title}
                        </p>
                        <p className="" style={{ textAlign: "center", fontSize: "14px", fontWeight: 600 }}>
                          {product.type}
                        </p>
                      </div>

                      <button
                        disabled={disabled}
                        style={{ cursor: cursorOption }}
                        id={product.id}
                        onClick={(e: any) => handleAddProduct(e, product)}
                      >
                        Add Product
                      </button>
                    </div>
                  </div>
                );
              })}

            {/* {comboProductState.allProducts?.map((product: any, index: number) => {
let disabled;
let cursorOption;

for (let i = 0; i < comboProductState.currentAddedProducts.length; i++) {
if (product.id === comboProductState.currentAddedProducts[i].id) {
  console.log("Matched id");
  disabled = true;
  cursorOption = "default";
}
}

return (
<div key={index}>
  <div style={{ display: "flex", justifyContent: "space-between" }}>
    <div className="ticket-display-add-ticket">
      <p className="ticket-display-text-sheet-title">{product.title}</p>

      <p className="ticket-display-text-sheet-included">{product.included}</p>
    </div>

    <button
      disabled={disabled}
      style={{ cursor: cursorOption }}
      id={product.id}
      onClick={(e: any) => handleAddTicket(e, product)}
    >
      Add Ticket
    </button>
  </div>

  <br />
</div>
);
})} */}
          </div>
        </Sheet>
        <br />

        {productState.type === "Membership" ? (
          <Card title="Membership Options">
            <Card.Section>
              <FormLayout>
                <FormLayout.Group>
                  <div>
                    <Select
                      label="Membership Type"
                      onChange={(value: any) => handleDropDownChange(value, "membership_type")}
                    >
                      {membershipOptions.map((type: any, index: number) => {
                        return (
                          <Option key={index} value={type.title} name={type.title}>
                            {type.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>
          </Card>
        ) : null}

        {productState.type === "Membership Add On" ? (
          <Card title="Membership Add On Options">
            <Card.Section>
              <FormLayout>
                <FormLayout.Group>
                  <div>
                    <Select
                      label="Membership Type"
                      onChange={(value: any) => handleDropDownChange(value, "membership_type")}
                    >
                      {membershipOptions.map((type: any, index: number) => {
                        return (
                          <Option key={index} value={type.title} name={type.title}>
                            {type.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>
          </Card>
        ) : null}
      </Form>
    </Page>
  );
}
