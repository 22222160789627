import { APIGet, APIPost, APIPut } from "api/protocols";
import { CancelToken } from "axios";
import { IRegister } from "pages/secure/facility/Admin";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseRegisterUrl = "/" + apiVersion + "/" + adminType + "/facility/register";

export interface IGetRegister {
  id?: number;
  extended?: boolean;
}

export async function GetRegisters(params: IGetRegister, useGlobalLoader?: boolean, token?: CancelToken) {
  return await APIGet<IRegister[]>(baseRegisterUrl, params, useGlobalLoader, token);
}

export async function PutRegister(params: any, useGlobablLoader?: boolean) {
  return await APIPut(baseRegisterUrl, params, useGlobablLoader);
}
export async function PostRegister(
  params: { register_group_id?: null | number; default_card_reader_id?: null | number; title: string },
  useGlobablLoader?: boolean,
) {
  return await APIPost(baseRegisterUrl, params, useGlobablLoader);
}
