import React, { useEffect, useState } from "react";

const DEFAULT_URL = "/public/images/flags/";
const DEFAULT_SUFFIX = "svg";

interface IImgProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  suffix?: string;
  baseUrl?: string;
  countryCode: string;
  style?: React.CSSProperties;
  svg?: boolean;
}

export type ReactCountryFlagProps = IImgProps;

export const Flag = ({
  suffix = DEFAULT_SUFFIX,
  baseUrl = DEFAULT_URL,
  countryCode,
  style,
  svg = false,
  ...props
}: ReactCountryFlagProps) => {
  if (typeof countryCode !== "string") {
    return null;
  }

  const flagUrl = `${baseUrl}${countryCode.toLowerCase()}.${suffix}`;

  return (
    <img
      src={flagUrl}
      style={{
        height: "1em",
        ...style,
      }}
    />
  );
};

export default Flag;
