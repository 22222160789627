import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { IProduct, IVariant } from "redux/reducers/models/product";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import Page from "components/page/Page";
import FormLayout from "components/form/FormLayout";
import { Select } from "components/select/index";
import Icon from "components/icon/Icon";
import Card from "components/card/Card";
import { IPrimaryPageAction } from "components/page/PageActions";
import { IFacility } from "redux/reducers/models/facility";
import "./newInventoryTransfer.scss";
import ProductSelect from "components/productModal/ProductSelect";
import Portal from "elements/Portal";
import Popup from "components/popup/Popup";
import ReactDOM from "react-dom";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";
import Input from "components/form/input";
import { ButtonNew as Button } from "components/buttonNew";
import Sheet from "components/sheet/Sheet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios, { CancelToken } from "axios";
import { StatusCode } from "api/protocols";
import { dequeue, enqueue, showError, showSuccess } from "redux/actions/ui";
import { GetProduct } from "api/rpc/2024-04/facilityAdmin/product/product";
import { cloneDeep } from "lodash";
import {
  PostInventoryTransfer,
  PostInventoryTransferVariants,
  PutSubmitInventoryTransfer,
} from "api/rpc/2024-04/facilityAdmin/product/inventoryTransfer";
import { ISplitButtonProps } from "components/splitButton/SplitButton";
import { TSku } from "../inventoryCounts/NewInventoryCount";
import { findSkuProduct } from "../inventoryCounts/inventoryHelpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Spin from "components/spin/spin";

interface IFacilityState {
  facilities: Array<IFacility>;
  selectedFacility: IFacility;
  facilitySearchQuery: string;
}
interface IProductState {
  selectedProducts: Array<IProduct>;
  finalSelectedProducts: Array<IProduct>;
  skusNotFound: Array<TSku>;
  openModal: boolean;
}

interface ISkuState {
  skus: Array<TSku>;
  skuString: string;
}

enum TransferPage {
  FacilitySelection = 0,
  InventorySelection = 1,
  InventoryConfirmation = 2,
}

export default function NewInventoryTransfer() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { Option } = Select;
  const { facilityStore } = useAppSelector(store => store);
  const [pageTitle, setPageTitle] = useState<{ title: string; subtitle: string }>({ title: "", subtitle: "" });

  const [facilityState, setFacilityState] = useState<IFacilityState>({
    facilities: [],
    selectedFacility: null,
    facilitySearchQuery: "",
  });

  const [productState, setProductState] = useState<IProductState>({
    selectedProducts: [],
    finalSelectedProducts: [],
    skusNotFound: [],
    openModal: false,
  });

  const [skuState, setSkuState] = useState<ISkuState>({
    skus: [],
    skuString: "",
  });

  const [variantNotFoundState, setVariantNotFoundState] = useState<{ modalOpen: boolean; sku: string }>({
    modalOpen: false,
    sku: "",
  });

  const [page, setPage] = useState<TransferPage>(TransferPage.FacilitySelection);

  const [savePopupState, setSavePopupState] = useState<boolean>(false);
  const [submitPopupState, setSubmitPopupState] = useState<boolean>(false);

  useEffect(() => {
    if (facilityStore?.facilities?.length > 0 && facilityStore?.facility) {
      //Filter out current selected facility
      const filteredFacilities = [...facilityStore?.facilities]?.filter(
        facility => facility?.id !== facilityStore?.facility?.id,
      );
      setFacilityState(prevState => ({ ...prevState, facilities: filteredFacilities }));
    }
  }, [facilityStore?.facilities, facilityStore?.facility]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (page === TransferPage.FacilitySelection) {
      ReactDOM.unstable_batchedUpdates(() => {
        setSkuState(prevState => ({
          ...prevState,
          skuString: "",
        }));
      });
    } else if (page === TransferPage.InventorySelection) {
      setProductState(prevState => ({ ...prevState, finalSelectedProducts: [], skusNotFound: [] }));
    } else if (page === TransferPage.InventoryConfirmation) {
      setProductState(prevState => ({
        ...prevState,
        finalSelectedProducts: cloneDeep(productState.selectedProducts),
        skusNotFound: cloneDeep(skuState.skus),
      }));
    }
    const pageTitle = generatePageTitle();
    setPageTitle(pageTitle);
    return () => {
      source.cancel();
    };
  }, [page]);

  //Search variants by sku / barcode
  useEffect(() => {
    const source = axios.CancelToken.source();
    const currentSku = skuState?.skuString?.slice();
    const skuIndex = skuState?.skus?.findIndex(sku => sku.sku === currentSku);
    setSkuState(prevState => ({ ...prevState, skuString: "" }));
    if (skuIndex !== -1) {
      const foundSku = skuState.skus[skuIndex];
      // First sku added, search to see if variant exists. Or, sku search hasn't completed yet
      if (foundSku.quantity === 1 || !foundSku?.searched) {
        void findSkuProduct(
          productState.selectedProducts,
          skuState.skus,
          setProductState,
          setSkuState,
          dispatch,
          source.token,
        );
      }
    }
    return () => {
      source.cancel();
    };
  }, [skuState?.skus]);

  function handleEnterKeydown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      void addSku();
    }
  }

  function handleFacilityDropdownChange(value: number, facility: IFacility) {
    setFacilityState(prevState => ({ ...prevState, selectedFacility: facility, facilitySearchQuery: "" }));
  }

  async function handleTransfer(submit = false) {
    dispatch(enqueue());
    const variants: Array<IVariant> = [];
    productState?.finalSelectedProducts?.forEach(product => {
      variants?.push(...[...product?.variants]);
    });
    const formattedVariants = variants?.map(variant => ({ variant_id: variant?.id, quantity: variant?.quantity }));
    setSavePopupState(false);
    const transferRes = await PostInventoryTransfer(
      { destination_facility_id: facilityState.selectedFacility?.id },
      false,
    );
    if (transferRes.status !== StatusCode.OK) {
      dispatch(showError("Error creating inventory transfer"));
      dispatch(dequeue());
      return;
    }
    const variantsRes = await PostInventoryTransferVariants(
      { inventory_transfer_id: transferRes?.data?.id, variants: formattedVariants },
      false,
    );

    if (submit) {
      const submitRes = await PutSubmitInventoryTransfer({ id: Number(transferRes?.data?.id) }, false);
      submitRes.status !== StatusCode.OK
        ? dispatch(showError("Error submitting transfer"))
        : dispatch(showSuccess("Successfully created and submitted inventory transfer"));
    } else {
      dispatch(
        showSuccess(
          `Successfully created inventory transfer${
            variantsRes?.status !== StatusCode.OK ? ". Error adding products to inventory transfer" : ""
          }`,
        ),
      );
    }
    dispatch(dequeue());
    history.push("/admin/settings/transfer");
  }

  function addSku() {
    if (skuState.skuString) {
      const updatedSkus = [...skuState.skus];
      const productIncremented = incrementProductQuantity(skuState.skuString);
      if (!productIncremented) {
        const foundSkuIndex = updatedSkus?.findIndex(skuObj => skuObj.sku === skuState.skuString);
        if (foundSkuIndex !== -1) {
          //Increment quantity of sku found
          const foundSku = updatedSkus[foundSkuIndex];
          updatedSkus.splice(foundSkuIndex, 1, { ...foundSku, quantity: ++foundSku.quantity });
        } else {
          //Add new sku
          const newSku: TSku = { sku: skuState.skuString, quantity: 1, searched: false };
          updatedSkus.push(newSku);
        }
        setSkuState(prevState => ({ ...prevState, skus: updatedSkus }));
      } else {
        setSkuState(prevState => ({ ...prevState, skuString: "" }));
      }
    }
  }

  function incrementProductQuantity(currentSku: string) {
    const products = [...productState?.selectedProducts];
    let productInList = false;
    let variantIndex: number = null;
    const productIndex = products?.findIndex(product => {
      const foundVariantIndex = product?.variants?.findIndex(
        variant => variant?.sku === currentSku || variant?.barcode === currentSku,
      );
      if (foundVariantIndex !== -1) {
        variantIndex = foundVariantIndex;
        return true;
      }
    });
    if (productIndex !== -1 && variantIndex !== -1) {
      // Product already in list, increment the quantity
      const foundVariant = products[productIndex]?.variants[variantIndex];
      products[productIndex].variants[variantIndex] = { ...foundVariant, quantity: ++foundVariant.quantity };
      productInList = true;
      setProductState(prevState => ({ ...prevState, selectedProducts: products }));
    }
    return productInList;
  }

  function handleProductSelect(products: Array<IProduct>) {
    const selectedProducts = [...products];
    selectedProducts?.forEach((product, productIndex) => {
      product?.variants?.forEach((variant, variantIndex) => {
        if (variant?.quantity == null) {
          selectedProducts[productIndex].variants[variantIndex] = { ...variant, quantity: 1 };
        }
      });
    });
    const { updatedSkus, updatedProducts } = updateSkus(selectedProducts);
    ReactDOM.unstable_batchedUpdates(() => {
      setProductState(prevState => ({ ...prevState, selectedProducts: updatedProducts }));
      setSkuState(prevState => ({ ...prevState, skus: updatedSkus }));
    });
  }

  function updateSkus(products: Array<IProduct>) {
    const updatedSkus = [...skuState.skus];
    const updatedProducts = [...products];
    updatedProducts?.forEach((product, productIndex) => {
      product?.variants?.forEach((variant, variantIndex) => {
        const skuIndex = updatedSkus?.findIndex(sku => sku.sku === variant?.sku || sku.sku === variant?.barcode);
        if (skuIndex !== -1) {
          updatedProducts[productIndex].variants[variantIndex] = {
            ...variant,
            quantity: Number(variant.quantity) + Number(updatedSkus[skuIndex]?.quantity),
          };
          updatedSkus.splice(skuIndex, 1);
        }
      });
    });
    return { updatedSkus: updatedSkus, updatedProducts: updatedProducts };
  }

  function handleEnterClick() {
    const skuSearchId = document.getElementById("skuSearchId");
    skuSearchId.focus();
    void addSku();
  }

  function removeSku(index: number) {
    const updatedSkus = [...skuState.skus];
    // Sku cannot be removed if search is in progress
    const allSkusSearched = updatedSkus?.every(sku => sku.searched);
    if (updatedSkus[index] && allSkusSearched) {
      updatedSkus.splice(index, 1);
      setSkuState(prevState => ({ ...prevState, skus: updatedSkus }));
    }
  }

  function removeVariant(
    productIndex: number,
    key: keyof Pick<IProductState, "finalSelectedProducts" | "selectedProducts">,
    variantIndex?: number,
  ) {
    const updatedProducts = [...productState[key]];
    //Product with single variant
    if (variantIndex == null || updatedProducts[productIndex]?.variants?.length === 1) {
      updatedProducts.splice(productIndex, 1);
    } else {
      // Product with multiple variants
      if (updatedProducts[productIndex]?.variants[variantIndex]) {
        updatedProducts[productIndex]?.variants?.splice(variantIndex, 1);
      }
    }
    setProductState(prevState => ({ ...prevState, [key]: updatedProducts }));
  }

  function handleInputChange(
    e: React.ChangeEvent<HTMLInputElement>,
    key: keyof Pick<IProductState, "finalSelectedProducts" | "selectedProducts">,
    productIndex: number,
    variantIndex: number,
  ) {
    const { valueAsNumber } = e.target;
    const updatedProducts = [...productState[key]];
    const updatedVariant = updatedProducts[productIndex].variants[variantIndex];
    updatedProducts[productIndex].variants[variantIndex] = {
      ...updatedVariant,
      quantity: isNaN(valueAsNumber) ? null : valueAsNumber,
    };
    setProductState(prevState => ({ ...prevState, [key]: updatedProducts }));
  }

  function generatePageTitle() {
    switch (page) {
      case TransferPage.FacilitySelection:
        return { title: "Select Facility", subtitle: "Step 1 of 3" };
      case TransferPage.InventorySelection:
        return { title: "Select Inventory", subtitle: "Step 2 of 3" };
      case TransferPage.InventoryConfirmation:
        return { title: "Confirm Selection", subtitle: "Step 3 of 3" };
      default:
        return { title: "New Inventory Transfer", subtitle: "" };
    }
  }

  const disableSave =
    (page === TransferPage.FacilitySelection && !facilityState.selectedFacility) ||
    (page === TransferPage.InventorySelection &&
      (productState?.selectedProducts?.length === 0 ||
        skuState?.skus?.some(sku => !sku.searched) ||
        productState?.selectedProducts?.some(product => product?.variants?.some(variant => !variant.quantity)))) ||
    (page === TransferPage.InventoryConfirmation &&
      (productState.finalSelectedProducts?.length === 0 ||
        productState?.finalSelectedProducts?.some(product =>
          product?.variants?.some(variant => !variant.quantity),
        )));

  const primaryAction: IPrimaryPageAction = {
    content: "Continue",
    action: () => setPage(prevTab => ++prevTab),
    disabled: disableSave,
  };

  const splitButtonAction: ISplitButtonProps = {
    label: "Save",
    type: "primary",
    disabled: disableSave,
    onClick: () => setSavePopupState(true),
    options: [
      {
        label: "Save and Submit",
        onClick: () => setSubmitPopupState(true),
        icon: "circle-check",
        disabled: disableSave,
      },
    ],
  };

  return (
    <Page
      title={pageTitle.title}
      subtitle={pageTitle.subtitle}
      primaryAction={page !== TransferPage.InventoryConfirmation ? primaryAction : undefined}
      secondaryActions={
        page !== TransferPage.FacilitySelection
          ? [{ content: `Back to Step ${page}`, action: () => setPage(prev => --prev) }]
          : []
      }
      splitButtonAction={page === TransferPage.InventoryConfirmation ? splitButtonAction : undefined}
      breadcrumbs={[{ prefix: true, label: "Back to Inventory Transfers", url: "/admin/settings/transfer" }]}
    >
      {page === TransferPage.FacilitySelection && (
        <Card>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                {facilityState.selectedFacility ? (
                  <>
                    <div>
                      <p className="inventory-transfer-label">Send To</p>
                    </div>
                    <div className="inventory-transfer-selected-name">
                      <div>{facilityState.selectedFacility?.long_name}</div>
                      <button
                        onClick={() =>
                          setFacilityState(prevState => ({
                            ...prevState,
                            selectedFacility: null,
                            facilitySearchQuery: "",
                          }))
                        }
                      >
                        <Icon style="far" icon="times" />
                      </button>
                    </div>
                  </>
                ) : (
                  <Select
                    showSearch
                    onSearch={(query: string) =>
                      setFacilityState(prevState => ({ ...prevState, facilitySearchQuery: query }))
                    }
                    showDropDownOnFocus
                    searchValue={facilityState?.facilitySearchQuery}
                    label="Send To"
                    onChange={handleFacilityDropdownChange}
                    placeholder="Search facilities..."
                  >
                    {facilityState?.facilities
                      ?.filter(facility =>
                        facility?.long_name
                          .toLocaleLowerCase()
                          .includes(facilityState?.facilitySearchQuery?.toLocaleLowerCase()),
                      )
                      ?.map((facility, index) => {
                        return (
                          <Option key={index} value={facility?.id} extraValues={facility}>
                            {facility?.long_name}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>
      )}

      {page === TransferPage.InventorySelection && (
        <Card>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  id="skuSearchId"
                  onKeyDown={handleEnterKeydown}
                  value={skuState.skuString}
                  placeholder="Scan Sku / Barcode"
                  onChange={e => setSkuState(prevState => ({ ...prevState, skuString: e.target.value }))}
                  trailingButtons={[
                    <Button key={1} onClick={handleEnterClick} type="secondary">
                      Enter
                    </Button>,
                    <Button
                      key={2}
                      type="primary"
                      size="medium"
                      icon={<FontAwesomeIcon className="ml-2" icon={["far", "magnifying-glass"]} />}
                      onClick={() => setProductState(prevState => ({ ...prevState, openModal: true }))}
                    >
                      Search
                    </Button>,
                  ]}
                />
              </FormLayout.Group>
            </FormLayout>
            <DataTable
              columns={[
                ...(productState?.selectedProducts?.length > 0 ? [{ label: "Title" }, { label: "Vendor" }] : []),
                ...(productState?.selectedProducts?.length > 0
                  ? [{ label: "Sku" }, { label: "Barcode" }]
                  : [{ label: "Sku / Barcode" }]),
                {
                  label: "Quantity",
                  content: (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="headingTooltip">Quantity can be manually adjusted on the next step</Tooltip>
                      }
                    >
                      <div>
                        <span className="inventory-transfer-quantity-label">Quantity </span>
                        <FontAwesomeIcon size="sm" icon={["fas", "square-info"]} />
                      </div>
                    </OverlayTrigger>
                  ),
                },
                { label: "" },
                { label: "" },
              ]}
            >
              {skuState?.skus?.map((sku, index) => {
                return (
                  <tr key={index}>
                    {productState?.selectedProducts?.length > 0 && (
                      <>
                        <td></td>
                        <td></td>
                        <td>{sku?.sku}</td>
                      </>
                    )}
                    <td>{sku?.sku}</td>
                    <td>{sku?.quantity}</td>
                    <td className="text-right">
                      {!sku.searched ? (
                        <div style={{ display: "inline-flex" }}>
                          <Spin />
                        </div>
                      ) : (
                        <OverlayTrigger
                          placement="auto"
                          overlay={<Tooltip id="headingTooltip">Product not found</Tooltip>}
                        >
                          <FontAwesomeIcon
                            className="inventory-transfer-error"
                            size="1x"
                            icon={["fas", "circle-xmark"]}
                            onClick={() => setVariantNotFoundState({ modalOpen: true, sku: sku.sku })}
                          />
                        </OverlayTrigger>
                      )}
                    </td>
                    <td className="text-right">
                      <FontAwesomeIcon
                        onClick={() => removeSku(index)}
                        icon={["far", "trash-can-xmark"]}
                        size="1x"
                        className="cursor-pointer"
                      />
                    </td>
                  </tr>
                );
              })}
              {productState?.selectedProducts?.map((product, productIndex) => {
                return (
                  <React.Fragment key={productIndex}>
                    <tr className="inventory-transfer-product-header">
                      <td>{product?.preferred_title ? product?.preferred_title : product.title}</td>
                      <td>{product.vendor_title}</td>
                      <td>{product?.variant_count === 1 && product?.variants[0]?.sku}</td>
                      <td>{product?.variant_count === 1 && product?.variants[0]?.barcode}</td>
                      <td>{product?.variant_count === 1 && product?.variants[0]?.quantity}</td>
                      <td className="text-right">
                        {product?.variant_count === 1 && (
                          <OverlayTrigger
                            placement="auto"
                            overlay={<Tooltip id="headingTooltip">Product found</Tooltip>}
                          >
                            <FontAwesomeIcon
                              className="inventory-transfer-success"
                              size="1x"
                              icon={["fas", "circle-check"]}
                            />
                          </OverlayTrigger>
                        )}
                      </td>
                      <td className="text-right">
                        {product?.variant_count === 1 && (
                          <FontAwesomeIcon
                            onClick={() => removeVariant(productIndex, "selectedProducts")}
                            icon={["far", "trash-can-xmark"]}
                            size="1x"
                            className="cursor-pointer"
                          />
                        )}
                      </td>
                    </tr>
                    {product?.variant_count > 1 &&
                      product?.variants?.map((variant, variantIndex) => (
                        <tr key={variantIndex}>
                          <td style={{ textIndent: "3rem" }}>{variant?.title}</td>
                          <td></td>
                          <td>{variant?.sku}</td>
                          <td>{variant?.barcode}</td>
                          <td>{variant?.quantity}</td>
                          <td className="text-right">
                            {
                              <OverlayTrigger
                                placement="auto"
                                overlay={<Tooltip id="headingTooltip">Product found</Tooltip>}
                              >
                                <FontAwesomeIcon
                                  className="inventory-transfer-success"
                                  size="1x"
                                  icon={["fas", "circle-check"]}
                                />
                              </OverlayTrigger>
                            }
                          </td>
                          <td className="text-right">
                            <FontAwesomeIcon
                              onClick={() => removeVariant(productIndex, "selectedProducts", variantIndex)}
                              icon={["far", "trash-can-xmark"]}
                              size="1x"
                              className="cursor-pointer"
                            />
                          </td>
                        </tr>
                      ))}
                  </React.Fragment>
                );
              })}
            </DataTable>
          </Card.Section>
        </Card>
      )}

      {page === TransferPage.InventoryConfirmation && (
        <Card>
          <Card.Section>
            <DataTable
              columns={[
                { label: "Title" },
                { label: "Vendor" },
                { label: "Sku" },
                { label: "Barcode" },
                { label: "Quantity" },
                { label: "" },
              ]}
              loading={!productState.finalSelectedProducts}
            >
              {productState?.finalSelectedProducts?.map((product, productIndex) => {
                return (
                  <React.Fragment key={productIndex}>
                    <tr className="inventory-transfer-product-header">
                      <td>{product?.preferred_title ? product?.preferred_title : product.title}</td>
                      <td>{product.vendor_title}</td>
                      <td>{product?.variant_count === 1 && product?.variants[0]?.sku}</td>
                      <td>{product?.variant_count === 1 && product?.variants[0]?.barcode}</td>
                      <td style={{ width: "15%" }}>
                        {product?.variant_count === 1 && (
                          <Input
                            type="number"
                            labelHidden
                            onChange={e => handleInputChange(e, "finalSelectedProducts", productIndex, 0)}
                            value={product?.variants[0]?.quantity ?? ""}
                            placeholder="Quantity"
                          />
                        )}
                      </td>
                      <td>
                        {product?.variant_count === 1 && (
                          <FontAwesomeIcon
                            onClick={() => removeVariant(productIndex, "finalSelectedProducts")}
                            icon={["far", "trash-can-xmark"]}
                            size="1x"
                            className="cursor-pointer"
                          />
                        )}
                      </td>
                    </tr>
                    {product?.variant_count > 1 &&
                      product?.variants?.map((variant, variantIndex) => (
                        <tr key={variantIndex}>
                          <td style={{ textIndent: "3rem" }}>{variant?.title}</td>
                          <td></td>
                          <td>{variant?.sku}</td>
                          <td>{variant?.barcode}</td>
                          <td style={{ width: "15%" }}>
                            <Input
                              type="number"
                              labelHidden
                              onChange={e =>
                                handleInputChange(e, "finalSelectedProducts", productIndex, variantIndex)
                              }
                              value={variant?.quantity ?? ""}
                              placeholder="Quantity"
                            />
                          </td>
                          <td>
                            <FontAwesomeIcon
                              onClick={() => removeVariant(productIndex, "finalSelectedProducts", variantIndex)}
                              icon={["far", "trash-can-xmark"]}
                              size="1x"
                              className="cursor-pointer"
                            />
                          </td>
                        </tr>
                      ))}
                  </React.Fragment>
                );
              })}
            </DataTable>
          </Card.Section>
          {productState?.skusNotFound?.length > 0 && (
            <Card.Section title="Variant Skus / Barcodes Not Found">
              <DataTable columns={[{ label: "Sku / Barcode" }, { label: "Quantity" }]}>
                {productState?.skusNotFound?.map((sku, index) => {
                  return (
                    <tr key={index}>
                      <td>{sku.sku}</td>
                      <td>{sku.quantity}</td>
                    </tr>
                  );
                })}
              </DataTable>
            </Card.Section>
          )}
        </Card>
      )}
      <div className="inventory-transfer-products-modal">
        <Sheet
          open={productState.openModal}
          title="Search Products"
          size="medium"
          onCancel={() => setProductState(prevState => ({ ...prevState, openModal: false }))}
          cancelText="Close"
          closable
          onOk={() => setProductState(prevState => ({ ...prevState, openModal: false }))}
          okText="Confirm"
        >
          <ProductSelect
            selectedProducts={productState.selectedProducts}
            onChange={handleProductSelect}
            search
            getProductsParams={{ track_inventory: true, all: true }}
            variantInput={{ key: "quantity", placeholder: "Quantity" }}
          />
        </Sheet>
      </div>
      <Portal isMounted={savePopupState}>
        <Popup
          open={savePopupState}
          onOk={() => handleTransfer(false)}
          onCancel={() => setSavePopupState(false)}
          type="info"
          okText="Save"
          title={`Save Transfer for ${facilityState?.selectedFacility?.long_name}?`}
          description={`Transfer can be edited and submitted after saving`}
        />
      </Portal>
      <Portal isMounted={submitPopupState}>
        <Popup
          open={submitPopupState}
          onOk={() => handleTransfer(true)}
          onCancel={() => setSubmitPopupState(false)}
          okText="Save and Submit"
          type="info"
          title={`Save and Submit Transfer for ${facilityState?.selectedFacility?.long_name}?`}
          description={`Transfer cannot be edited after submitting`}
        />
      </Portal>
      <Portal isMounted={variantNotFoundState.modalOpen}>
        <Popup
          open={variantNotFoundState.modalOpen}
          type="warning"
          title={"Product Not Found"}
          onOk={() => setVariantNotFoundState({ modalOpen: false, sku: "" })}
          okText="Close"
          description=""
          closable
          backDropCancel
        >
          <p className="mb-2">
            A product corresponding to SKU or barcode {<b>{variantNotFoundState.sku}</b>} was not found.
          </p>
          <p className="mb-2">Please proceed to the products page to update a product with this SKU / barcode.</p>
        </Popup>
      </Portal>
    </Page>
  );
}
