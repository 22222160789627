import { useTranslation, Trans } from "react-i18next";
export default function SettingOptionsTranslation() {
  const { t, i18n } = useTranslation();

  const eventOptions = [
    {
      label: "Leagues",
      url: "/admin/events",
    },
    {
      label: "Tournaments",
      url: "/admin/events-tournament",
    },
  ];

  return eventOptions;
}
