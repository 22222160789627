import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { ICustomer } from "redux/reducers/models/customer";
import { ILeague, ILeagueOrganizer } from "redux/reducers/models/league";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const leagueOrganizerUrl = "/" + apiVersion + "/" + adminType + "/league/organizer";

interface IGetLeagueOrganizerRes {
  data: ILeagueOrganizer[];
  status: number;
  message: string;
}
interface IPostLeagueOrganizerRes {
  data: ILeagueOrganizer[];
  status: number;
}
interface IPutLeagueOrganizerRes {
  data: ILeagueOrganizer;
  status: number;
  message: string;
}
interface IDeleteLeagueOrganizerRes {
  data: any;
  status: number;
  message: string;
}

type GetOrganizerParams = {
  id?: number;
  league_id?: number;
};
export async function GetLeagueOrganizer(
  params: GetOrganizerParams,
  useGlobalLoader: boolean,
  token?: CancelToken,
): Promise<IGetLeagueOrganizerRes> {
  return await APIGet(leagueOrganizerUrl, params, useGlobalLoader, token);
}

type PostOrganizerParams = {
  league_id: number;
  user_id: number;
};
export async function PostLeagueOrganizer(
  params: PostOrganizerParams,
  useGlobalLoader: boolean,
): Promise<IPostLeagueOrganizerRes> {
  return await APIPost(leagueOrganizerUrl, params, useGlobalLoader);
}

// type PutOrganizerParams = {

// }

// export async function PutLeagueOrganizer(params: PutOrganizerParams, useGlobalLoader: boolean): Promise<IPutLeagueOrganizerRes> {
//     return await APIGet(leagueOrganizerUrl, params, useGlobalLoader);
// }

type DeleteOrganizerParams = {
  id: number;
};

export async function DeleteLeagueOrganizer(
  params: DeleteOrganizerParams,
  useGlobalLoader: boolean,
): Promise<IDeleteLeagueOrganizerRes> {
  return await APIGet(leagueOrganizerUrl, params, useGlobalLoader);
}
