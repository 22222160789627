import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import classNames from "classnames";
import "components/page/page.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageActions, {
  IMultipleActionDropdownPageAction,
  IPrimaryPageAction,
  ISecondaryPageAction,
} from "components/page/PageActions";

import ButtonGroup from "components/button/ButtonGroup";
import { ButtonNew as Button } from "components/buttonNew";
import Stack from "components/stack/Stack";
import Icon from "components/icon/Icon";
import NotificationBar, { INotificationBarProps } from "components/notificationBar/NotificationBar";
import { NavigationDropdown } from "components/navigationDropdown/NavigationDropdown";
import Toast from "components/toast/Toast";
import { ISplitButtonProps } from "components/splitButton/SplitButton";

interface IPageProps {
  full?: boolean;
  narrow?: boolean;
  breadcrumbs?: any;
  thumbnail?: any;
  title?: string;
  titleMetadata?: any;
  subtitle?: any;
  primaryAction?: IPrimaryPageAction;
  secondaryActions?: ISecondaryPageAction[];
  multipleActionDropdownAction?: IMultipleActionDropdownPageAction;
  subActions?: any;
  splitLayout?: boolean;
  splitButtonAction?: ISplitButtonProps;
  notificationBarProps?: INotificationBarProps;
  breadcrumbsNavigateParams?: Record<string, any>;
}

const Section = (props: any) => (
  <div
    className={classNames("ui-page-section", {
      "ui-page-section_one-third": props.oneThird,
      "ui-page-section_two-thirds": props.twoThirds,
    })}
  >
    {props.children}
  </div>
);

const Page = (props: IPageProps & { children?: any }) => {
  const history = useHistory();

  function breadCrumbsNavigation(url: string) {
    history.push(url, { params: props?.breadcrumbsNavigateParams });
  }

  return (
    <div
      className={classNames("ui-page", {
        "ui-page_full": props.full,
        "ui-page_narrow": props.narrow,
      })}
    >
      {props.title || props.multipleActionDropdownAction ? (
        <div
          className={classNames("ui-page-header ui-page-header_medium-title", {
            "ui-page-header_no-breadcrumbs": !props.breadcrumbs,
          })}
        >
          <div className="ui-page-header_row">
            {props.breadcrumbs ? (
              <div className="ui-page-header_breadcrumb-wrapper">
                {props.breadcrumbs.map((breadcrumb: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className="text-lg text-gray-400 mr-4 cursor-pointer"
                      onClick={() => breadCrumbsNavigation(breadcrumb.url)}
                    >
                      {breadcrumb.prefix ? (
                        <FontAwesomeIcon icon={["far", "arrow-left"]} className="mr-4 text-gray-400 text-sm" />
                      ) : null}
                      <span>{breadcrumb.label}</span>
                      {index !== props.breadcrumbs.length - 1 ? (
                        <FontAwesomeIcon icon={["far", "arrow-right"]} className="mr-4 text-gray-400 text-sm" />
                      ) : null}
                    </div>
                  );
                })}
              </div>
            ) : null}

            <div className="ui-page-header_title-wrapper">
              <div
                className={classNames("ui-header-title", {
                  "ui-header-title-thumbnail": props.thumbnail,
                })}
              >
                {props.thumbnail ? <>{props.thumbnail}</> : null}

                <div>
                  <div className="flex flex-wrap items-end">
                    <h1 className="display-sm text-semibold">{props.title}</h1>
                    <div className="text-xl text-gray ml-3.5 mb-1.5">{props.titleMetadata}</div>
                  </div>

                  {props.subtitle ? (
                    <div className="ui-page-header_subtitle">
                      <p className="text-xl">{props.subtitle}</p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            {props.primaryAction ||
            props.secondaryActions ||
            props.multipleActionDropdownAction ||
            props.splitButtonAction ? (
              <div className="ui-page-header_right-aligned">
                <div className="w-full flex justify-end">
                  <PageActions
                    primaryAction={props.primaryAction}
                    secondaryActions={props.secondaryActions}
                    multipleActionDropdownAction={props.multipleActionDropdownAction}
                    splitButtonAction={props.splitButtonAction}
                  />
                </div>
              </div>
            ) : null}
          </div>

          <div className="ui-page-sub-actions">
            <ButtonGroup>
              {props.subActions &&
                props.subActions.map((action: any, index: number) => {
                  if (action.type === "dropdown") {
                    return (
                      <Button type="tertiary" key={index}>
                        <NavigationDropdown showPlainTextLabel label={action.label} options={action.options} />
                      </Button>
                    );
                  } else {
                    return (
                      <Button onClick={action.onClick} type={action.type} key={index}>
                        {action.label}
                      </Button>
                    );
                  }
                })}
            </ButtonGroup>
          </div>
        </div>
      ) : null}

      <div
        className={classNames("ui-page_content", {
          "ui-page_split-layout": props.splitLayout,
        })}
      >
        {React.Children?.map(props.children, child => {
          return <>{child}</>;
        })}
      </div>

      {props.notificationBarProps ? (
        <Toast
          mounted={props.notificationBarProps.isVisible}
          title={"Careful, you have unsaved changes on this page!"}
          type={"warning"}
          onClose={props.notificationBarProps.onCancel}
          onSave={props.notificationBarProps.onAction}
        />
      ) : null}
    </div>
  );
};

Section.displayName = "Section";
Page.Section = Section;

export default Page;
