import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetLeagueTeams } from "api/rpc/2024-04/facilityAdmin/league/scoring/teams";

import { showError } from "redux/actions/ui";
import { TLeagueTeam } from "redux/reducers/models/league";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

export default function LeagueTeams() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { leagueId, divisionId } = useParams<{ leagueId: string; divisionId: string }>();

  const dispatch = useAppDispatch();

  const [teams, setTeams] = useState<TLeagueTeam[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadLeagueTeams(divisionId, source.token);
    return () => source.cancel();
  }, [divisionId]);

  async function loadLeagueTeams(divisionId: string | number, token?: CancelToken) {
    if (teams !== undefined) {
      setTeams(undefined);
    }
    const teamRes = await GetLeagueTeams({ league_division_id: divisionId }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (teamRes.status !== StatusCode.OK) {
      dispatch(showError("Error getting league teams.")); // TODO: Translation
    }

    setTeams(teamRes.status !== StatusCode.OK ? [] : teamRes.data);
  }

  const primaryAction = {
    content: "New Team", // TODO: Translation
    action: () => history.push(`/admin/league/${leagueId}/divisions/${divisionId}/teams/new`),
  };

  const secondaryAction = [
    {
      content: "Manage Players", // TODO: Translation
      action: () => history.push(`/admin/league/${leagueId}/divisions/${divisionId}/teams/manage_players`),
    },
  ];

  return (
    <Page
      title={"League Teams"} // TODO: Translation
      narrow
      primaryAction={primaryAction}
      secondaryActions={secondaryAction}
      breadcrumbs={[
        { prefix: true, label: "Divisions", /* TODO: Translation */ url: `/admin/league/${leagueId}/divisions` },
      ]}
    >
      <DataTable columns={[{ label: t("secure.facility.league.leagues.003") }]} loading={teams === undefined}>
        {teams?.map(team => (
          <tr
            key={team.id}
            className="clickable"
            onClick={() => history.push(`/admin/league/${leagueId}/divisions/${divisionId}/teams/${team.id}/edit`)}
          >
            <td>{team.name}</td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
