import React, { useRef, useState } from "react";
import classNames from "classnames";
import Tag from "components/tag/Tag";
// import { Select } from "components/select/";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./tagInputSelect.scss";
import SelectNew from "components/select/SelectNew";

export interface IInputProps {
  className?: string;
  label?: any;
  value?: any;
  onChange?: (value: number | string, extraValue?: any) => void;
  onKeyDown?: any;
  multiLine?: any;
  labelHidden?: boolean;
  placeholder?: any;
  helpText?: any;
  tags?: any;
  onTagClick?: (index: number) => void;
  onFocus?: (e: any) => void;
  name?: string;
  id?: any;
  readOnly?: boolean;
  selectOptions?: any;
}

const TagInputSelect: React.FC<IInputProps> = props => {
  const { className, onChange, onKeyDown, id, ...rest } = props;
  const [inputStyle, setInputStyle] = useState({
    style: "tag-input-select_container",
  });

  const inputRef = useRef(null);

  function handleFocusStyleChange() {
    setInputStyle(prevState => ({ ...prevState, style: "tag-input-select_container_focused" }));
  }
  function handleBlurStyleChange() {
    setInputStyle(prevState => ({ ...prevState, style: "tag-input-select_container" }));
  }

  return (
    <div className={`tag-input-select ${className ? className : ""}`}>
      {!props.labelHidden ? (
        <div className="tag-input-select_label_container">
          <div className="tag-input-select_label">
            <label style={{ marginBottom: 0 }}>{props.label}</label>
          </div>
        </div>
      ) : null}

      <div className={inputStyle.style}>
        <div
          className="tag-input-select_tags_container"
          style={{ paddingBottom: props.tags.length > 0 ? "10px" : "0px" }}
        >
          {props.tags.map((tag: { name: string; id: number }, index: number) => {
            return (
              <div className="tag-input-select_single_tag_container" key={index}>
                <p className="tag-text">{tag.name}</p>

                <div className="flex items-center" onClick={e => props.onTagClick(tag.id)}>
                  <FontAwesomeIcon fixedWidth icon={["far", "xmark"]} className="tag-icon" />
                </div>
              </div>
            );
          })}
        </div>

        <div className="tag-input-select_input_container">
          <SelectNew
            id={"tag_select_input"}
            // className="tag-input-select_input_container_select"
            onChange={(value, option) => props.onChange(value, option)}
            value={null}
            options={props.selectOptions?.map((option: any, index: number) => ({
              id: option.id,
              label: option.name,
              extraValue: option,
            }))}
          />
        </div>
      </div>

      <div>
        <p className="tag-input-select_help_text">{props.helpText}</p>
      </div>
    </div>
  );
};

export default TagInputSelect;
