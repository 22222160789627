import React, { useCallback, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDate } from "helpers/Helpers";

import { GetTeeSheetNotificationsByDate } from "api/rpc/teeSheet/teeSheet";

import { StatusCode } from "api/protocols";

import DateBar from "elements/teesheet/DateBar";
import { CustomerModal } from "elements/teesheet/CustomerModal";
import TeeSheetTabs from "containers/facility/teeSheetTabs";

import Sheet from "components/sheet/Sheet";

import { IUIState } from "redux/reducers/ui";
import { IFacilityState } from "redux/reducers/facility";
import { ITeeSheetState } from "redux/reducers/teesheet";

import "public/scss/teesheet.scss";
import "./styles.scss";
import StatisticsBar from "elements/teesheet/statisticsbar/StatisticsBar";
import { Select } from "components/select/index";

import CheckoutForm from "components/checkoutForm/CheckoutForm";
import Spin from "components/spin/spin";

import { IUIActions } from "redux/actions/ui";
import { ITeeSheetActions } from "redux/actions/teesheet";
import DateSelector from "components/dateSelector/dateSelector";
import BookingModalNew from "elements/teesheet/BookingModalNew";
import { ICart } from "redux/reducers/models/cart";
import { IPaymentOption } from "redux/reducers/models/transaction";

import { ICheckedInPlayer, ICustomer } from "redux/reducers/models/customer";
import { IOrder } from "redux/reducers/models/order";
// import message from "components/message";
import teeSheetToast from "components/teeSheetToast/TeeSheetToast";
import { ITeeTime } from "redux/reducers/models/teetime";
import { IFacilityActions } from "redux/actions/facility";
import DatePickerInput from "components/datePickerInput/DatePickerInput";

import { useTranslation, Trans } from "react-i18next";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { MOBILE_WIDTH, TABLET_WIDTH } from "helpers/ScreenSizes";
import { IDivision } from "redux/reducers/models/facility";
import TeeSheetBookingGraph from "components/teeSheetBookingGraph/TeeSheetBookingGraph";

declare const StripeTerminal: any;

export interface IMainState {
  golferSearchQuery: string;
  golferSearchResult: (ICustomer | Partial<ICustomer>)[];
  holes: string | number;
  players: string | number;
  currentDivision: number;
  currentDivisions: IDivision[];
  currentCourseId: number;
}

export interface INewBookingState {
  newBookingActive: boolean;
  holes: string | number;
  quantity: string | number;
  powerCartQuantity: string | number;
  customerSelection: ICustomer[];
  customer: any;
  customers: [];
  golferInitialLoad: any;
  cartInitialLoad: any;
  holesInitialLoad: any;
  saveCard: boolean;
  addCard: boolean;
  auditTrails: any;
  notes: any;
  noteDescription: any;
  addNoteVisible: string;
  notesTitleVisible: string;
  updateBookingActive: boolean;
  editBooking: undefined | Record<string, any>;
  paymentMethods: any[];
  selectedPaymentMethod?: number;
  cardReady: boolean;
  moveBooking: boolean;
  moveBookingId: string;
  copyBooking: boolean;
  copyBookingId: string;
  copyBookingIdList: any;
  copyBookingFutureDates: any;
  copyBookingDate: string;
  copyBookingTime: string;
  cartButtonOne: boolean;
  cartButtonTwo: boolean;
  cartButtonThree: boolean;
  golferButtonOne: boolean;
  golferButtonTwo: boolean;
  golferButtonThree: boolean;
  golferButtonFour: boolean;
  holesButtonOne: boolean;
  holesButtonTwo: boolean;
  holesButtonThree: boolean;
}
export interface INewPlayerState {
  createPlayerActive: boolean;
  quickNewPlayer: boolean;
  first_name: string;
  last_name: string;
  email_address: string;
  phone_number: string;
}

export interface ICustomerState {
  customerModalOpen: boolean;
  selectedCustomerId: number;
}

export interface ITerminalState {
  online: boolean;
  connecting: boolean;
  reader: any;
}

export interface ICheckInState {
  selectedTeeTimeId: number;
  checkedInPlayers: ICheckedInPlayer[];
  cart: ICart | Partial<ICart>;
  mergedCart: ICart | Partial<ICart>;
  paymentOptions: IPaymentOption[];
  paymentMethod: Partial<IPaymentOption> | null;
  order: IOrder | Partial<IOrder>;
  paymentAmount: number;
  updateLineItemModalOpen: boolean;
  selectedItem: Record<string, any>;
}

export interface IDateSelectorState {
  visible: boolean;
}

interface IProps {
  facilityActions: IFacilityActions;
  facilityStore: IFacilityState;
  uiActions: IUIActions;
  uiStore: IUIState;
  teeSheetStore: ITeeSheetState;
  teeSheetActions: ITeeSheetActions;
  terminalStore: ITerminalState;
}

export interface IStripePaymentState {
  onOk: any;
  onCancel: any;
  okText: string;
  cancelText: string;
  sheetVisible: boolean;
  titleText: string;
  message: string;
  convertedCode: string;
  processing: boolean;
  success: boolean;
}

export enum StateType {
  MainState = "IMainState",
  CustomerState = "ICustomerState",
}

interface IRecipient {
  id?: number;
  checked?: boolean;
  start_time?: string;
}

// interface IRecipientList {
//   recipients?: IRecipient[];
// }

const TeeSheet: React.FC<IProps> = props => {
  const { uiActions, facilityStore, facilityActions, teeSheetStore, teeSheetActions, terminalStore, uiStore } = props;

  const tabletDateBar = useRef<HTMLDivElement>(null);

  const [state, setState] = useState<IMainState>({
    golferSearchQuery: "",
    golferSearchResult: [null],
    holes: null,
    players: null,
    currentDivision: null,
    currentDivisions: null,
    currentCourseId: null,
  });

  const windowSize = useWindowSize();

  const [customerState, setCustomerState] = useState<ICustomerState>({
    selectedCustomerId: null,
    customerModalOpen: false,
  });

  function updateState<T>(newState: Partial<T>, type: StateType) {
    const states = {
      [StateType.MainState]: (newState: Partial<T>) =>
        setState((cur: IMainState) => {
          return { ...cur, ...newState };
        }),
      [StateType.CustomerState]: (newState: Partial<T>) =>
        setCustomerState((cur: ICustomerState) => {
          return { ...cur, ...newState };
        }),
    };

    states[type](newState);
  }

  // Handle gathering current facility weather
  //(09/Jul/21)::(10:34:38) - May need to change how division is saved

  function handleDivisionChange(divisionId: number) {
    updateState<IMainState>({ currentDivision: divisionId }, StateType.MainState);
  }

  function handleCourseChange(courseId: number) {
    let currentCourse = undefined;
    if (facilityStore?.facility?.courses?.length > 0) {
      currentCourse = facilityStore.facility.courses.find(course => course.id === courseId);
    }

    if (currentCourse === undefined) {
      return;
    }

    updateState<IMainState>(
      {
        currentCourseId: currentCourse.id,
        currentDivisions: currentCourse.divisions,
        currentDivision: currentCourse.divisions[0]?.id,
      },
      StateType.MainState,
    );
  }

  useEffect(() => {
    if (facilityStore.facility) {
      facilityActions.loadFacilityWeather(facilityStore.facility.id, true);

      const currentDivision = parseInt(
        localStorage.getItem(`${facilityStore.facility.short_name}_tee_sheet_current_division`),
      );
      const currentCourseId = parseInt(
        localStorage.getItem(`${facilityStore.facility.short_name}_tee_sheet_current_course_id`),
      );
      const currentDivisions = facilityStore.facility.courses.find(
        course => course.id === currentCourseId,
      )?.divisions;

      const localStorageDataIsValid = currentDivision && currentCourseId && currentDivisions;

      updateState<IMainState>(
        {
          currentDivision: localStorageDataIsValid
            ? currentDivision
            : facilityStore.facility.courses[0]?.divisions[0]?.id,
          currentDivisions: localStorageDataIsValid ? currentDivisions : facilityStore.facility.courses[0].divisions,
          currentCourseId: localStorageDataIsValid ? currentCourseId : facilityStore.facility.courses[0].id,
        },
        StateType.MainState,
      );
    }
  }, [facilityStore.facility]);

  useEffect(() => {
    if (state.currentCourseId && state.currentDivision && facilityStore.facility) {
      localStorage.setItem(
        `${facilityStore.facility.short_name}_tee_sheet_current_division`,
        String(state.currentDivision),
      );
      localStorage.setItem(
        `${facilityStore.facility.short_name}_tee_sheet_current_course_id`,
        String(state.currentCourseId),
      );
    }
  }, [state.currentCourseId, state.currentDivision]);

  // Update teetimes on course change -
  useEffect(() => {
    if (state.currentCourseId && state.currentDivision && teeSheetStore.selectedDate) {
      if (state.currentDivision != -1) {
        const limited = facilityStore.teeSheetSettings?.tee_sheet_limited;
        teeSheetActions.loadTeetimes(
          teeSheetStore.selectedDate,
          state.currentCourseId,
          state.currentDivision,
          undefined,
          limited,
        );
      } else {
        const divisions = state.currentDivisions.map((division: IDivision) => division.id);
        const limited = facilityStore.teeSheetSettings?.tee_sheet_limited;
        teeSheetActions.loadTeetimes(teeSheetStore.selectedDate, state.currentCourseId, undefined, divisions, limited);
      }
    }
  }, [state.currentCourseId, state.currentDivision, teeSheetStore.selectedDate]);

  const calculateSlotStatus = useCallback(
    (slotStatus: string) => {
      let count = 0;

      if (!teeSheetStore.teetimes) {
        return count;
      }

      teeSheetStore.teetimes.map(teeTime => {
        if (teeTime.blocked_type === "open" || teeTime.blocked_type === "shotgun") {
          teeTime.slots.map(slot => {
            if (slot.status === slotStatus) {
              count += 1;
            }
          });
        }
      });

      return count;
    },
    [teeSheetStore.teetimes],
  );

  // function calculateTeeSheetSlots(slotStatus: string) {
  //   let count = 0;

  //   for (let t = 0; t < teeSheetStore.teetimes?.length; t++) {
  //     const teetime = teeSheetStore.teetimes[t];
  //     if (teetime.blocked_type === "open" || teetime.blocked_type === "shotgun") {
  //       for (let s = 0; s < teetime.slots.length; s++) {
  //         const slot = teetime.slots[s];
  //         if (slot.status == slotStatus) {
  //           count += 1;
  //         }
  //       }
  //     }
  //   }

  //   return count;
  // }

  const { Option } = Select;
  const { t, i18n } = useTranslation();
  // Stripe Payment Related Functions and Variables

  const [manualStripeState, setManualStripeState] = useState({
    clientSecret: "",
    visible: false,
    transaction: undefined,
  });

  const [stripePaymentState, setStripePaymentState] = useState<IStripePaymentState>({
    onCancel: () => {},
    onOk: () => {},
    cancelText: "",
    okText: "",
    sheetVisible: false,
    titleText: "",
    message: "",
    convertedCode: "",
    processing: false,
    success: false,
  });

  const [submitTrigger, setSubmitTrigger] = useState<boolean>(false);
  const [manualProcessing, setManualProcessing] = useState<boolean>(false);

  function handleManualProcessing() {
    if (manualProcessing === true) {
      setManualProcessing(false);
    } else {
      setManualProcessing(true);
    }
  }

  function toggleCustomerModal(customerId: number) {
    updateState<ICustomerState>(
      { customerModalOpen: !customerState.customerModalOpen, selectedCustomerId: customerId },
      StateType.CustomerState,
    );
  }

  function calculateDateBarNumberOfDaysDesktop() {
    if (windowSize.width > 1400) {
      return 7;
    } else if (windowSize.width > 1200) {
      return 5;
    } else if (windowSize.width > TABLET_WIDTH) {
      return 3;
    } else {
      return 0;
    }
  }

  function calculateDateBarNumberOfDaysTablet() {
    if (windowSize.width <= TABLET_WIDTH) {
      return 7;
    } else {
      return 0;
    }
  }

  function changeTeeSheetDate(selectedDate: Date) {
    teeSheetActions.selectDate(selectedDate);
    // teeSheetActions.loadTeetimes(selectedDate, state.currentCourseId, state.currentDivision); -- Refresh on any value changes
    void loadTeeSheetNotifications(selectedDate);
  }

  async function loadTeeSheetNotifications(date: Date) {
    // Remove all notifications
    teeSheetToast.show([]);

    const formattedDate: string = formatDate(date);
    const teeSheetNotifications = await GetTeeSheetNotificationsByDate({ date: formattedDate }, false);

    if (teeSheetNotifications.status === StatusCode.OK && teeSheetNotifications.data.length !== 0) {
      const toastList: Array<{
        subject: string;
        content: string;
        duration?: number;
        onClose?: () => void;
      }> = teeSheetNotifications.data.map((data: any) => {
        let duration = 10000;
        if (data.type !== "passive") {
          duration = null;
        }
        return {
          subject: data.subject,
          content: data.content,
          duration: duration,
        };
      });
      teeSheetToast.show(toastList);
    }
  }

  const calcualteRecipientList = useCallback(() => {
    const recipients: IRecipient[] = [];

    if (!teeSheetStore.teetimes) {
      return recipients;
    }
    teeSheetStore.teetimes?.map(teeTime => {
      if (teeTime.blocked_type !== "crossover" && teeTime.quantity_remaining !== 4) {
        recipients.push({ id: teeTime.id, checked: false, start_time: teeTime.start_time });
      }
    });

    return recipients;
  }, [teeSheetStore.teetimes]);

  // function calculateTeeSheetRecipients() {
  //   const recipients: IRecipientList[] = [];

  //   for (let t = 0; t < teeSheetStore.teetimes?.length; t++) {
  //     const teetime: any = teeSheetStore.teetimes[t];

  //     if (teetime.blocked_type !== "crossover") {
  //       if (teetime.quantity_remaining !== 4) {
  //         teetime.checked = false;
  //         recipients.push(teetime);
  //       }
  //     }
  //   }

  //   return recipients;
  // }

  //State and functions to handle manual payment
  return (
    <>
      <StatisticsBar client={false} />

      <div className="content-container">
        {/*Sheet to handle manual card payments*/}
        <Sheet
          size="small"
          closable
          title={t("secure.facility.tee_sheet.tee_sheet.001")}
          cancelText={t("secure.facility.tee_sheet.tee_sheet.002")}
          open={manualStripeState.visible}
          okText={t("secure.facility.tee_sheet.tee_sheet.003")}
          okDisabled={manualProcessing}
          cancelDisabled={manualProcessing}
          onOk={() => {
            if (!submitTrigger) {
              setSubmitTrigger(!submitTrigger);
            }
          }}
          onCancel={() => {
            setManualStripeState(prevState => ({ ...prevState, visible: false }));
          }}
        >
          {manualStripeState.visible && (
            <CheckoutForm
              onSubmit={() => handleManualProcessing()}
              trigger={submitTrigger}
              transaction={manualStripeState.transaction}
              onError={(message: string) => {
                uiActions.showError(message);
              }}
              onSuccess={() => {
                // Clean values
                uiActions.showSuccess(t("secure.facility.tee_sheet.tee_sheet.004"));
                setManualStripeState(prevState => ({ ...prevState, visible: false, transaction: {} }));
              }}
            />
          )}
        </Sheet>
        {/* Payment Handler Sheet - Handles successes/errors */}
        <Sheet
          open={stripePaymentState.sheetVisible}
          title={stripePaymentState.titleText}
          closable
          onCancel={stripePaymentState.onCancel}
          onOk={stripePaymentState.onOk}
          cancelText={stripePaymentState.cancelText}
          okText={stripePaymentState.okText}
          backDropCancel={false}
        >
          {stripePaymentState.processing ? (
            // Payment Processing
            <div className="flex flex-column justify-center align-center">
              {stripePaymentState.processing && <Spin className="text-lg" />}
              <p className="flex text-lg font-bold">{t("secure.facility.tee_sheet.tee_sheet.005")}</p>
            </div>
          ) : (
            // Payment Error
            <div className="flex flex-column justify-center align-center">
              {stripePaymentState.success ? (
                <FontAwesomeIcon icon={"check-circle"} color={"#4BB543"} className="text-9xl" />
              ) : (
                <FontAwesomeIcon icon={"exclamation-circle"} color={"#ff0033"} className="text-9xl" />
              )}
              <p className="flex text-lg font-bold">{stripePaymentState.message}</p>
              <p className="flex text-lg font-bold">{stripePaymentState.convertedCode}</p>
            </div>
          )}
        </Sheet>

        <CustomerModal
          open={customerState.customerModalOpen}
          onClose={toggleCustomerModal}
          customerId={customerState.selectedCustomerId}
        />

        <BookingModalNew />

        <div className="flex flex-wrap-reverse flex-row gap-y-3 justify-between items-center mt-4 mb-4">
          <div className="teesheet-date-selection-header">
            <div className="teesheet-date-selection-header-input">
              <DatePickerInput
                months={1}
                position={windowSize.width <= MOBILE_WIDTH ? "center" : "left"}
                centeredInputText={windowSize.width <= MOBILE_WIDTH}
                startingDate={teeSheetStore.selectedDate}
                setStartingDate={changeTeeSheetDate}
              />
            </div>
            {calculateDateBarNumberOfDaysDesktop() > 0 && (
              <DateBar
                numberOfDays={calculateDateBarNumberOfDaysDesktop()}
                onClick={changeTeeSheetDate}
                selectedDate={teeSheetStore.selectedDate}
              />
            )}
          </div>
          <div className="teesheet-filters">
            {state.currentCourseId && state.currentDivision && state.currentDivisions && (
              <>
                {facilityStore?.facility?.courses?.length > 1 && (
                  <div className="teesheet-filter">
                    <Select placeholder="Course" onChange={handleCourseChange} defaultValue={state.currentCourseId}>
                      {facilityStore.facility.courses.map((course, i) => (
                        <Option key={i} value={course.id} name={course.full_name}>
                          <span>{course.full_name}</span>
                        </Option>
                      ))}
                    </Select>
                  </div>
                )}
                <div className="teesheet-filter">
                  <Select placeholder="Division" onChange={handleDivisionChange} defaultValue={state.currentDivision}>
                    {state.currentDivisions.map((division: IDivision, i: number) => (
                      <Option key={i} value={division.id} name={division.title}>
                        <span>{division.title}</span>
                      </Option>
                    ))}
                    <Option value={-1} name="Combined">
                      <span>{t("secure.facility.tee_sheet.tee_sheet.006")}</span>
                    </Option>
                  </Select>
                </div>
              </>
            )}
          </div>
        </div>

        {calculateDateBarNumberOfDaysTablet() > 0 && (
          <div className="relative">
            <div
              className="absolute flex justify-center items-center cursor-pointer text-left"
              style={{
                width: "15px",
                top: "20px",
                left: "-8px",
                display: windowSize.width <= MOBILE_WIDTH ? "block" : "none",
              }}
              onClick={() => (tabletDateBar.current.scrollLeft -= 75)}
            >
              <FontAwesomeIcon icon={"chevron-left"} />
            </div>
            <div className="overflow-x-scroll pb-2 mb-2" ref={tabletDateBar}>
              <DateBar
                numberOfDays={calculateDateBarNumberOfDaysTablet()}
                onClick={changeTeeSheetDate}
                selectedDate={teeSheetStore.selectedDate}
              />
            </div>
            <div
              className="absolute flex justify-center items-center cursor-pointer text-right"
              style={{
                width: "15px",
                top: "20px",
                right: "-8px",
                display: windowSize.width <= MOBILE_WIDTH ? "block" : "none",
              }}
              onClick={() => (tabletDateBar.current.scrollLeft += 75)}
            >
              <FontAwesomeIcon icon={"chevron-right"} />
            </div>
          </div>
        )}

        {facilityStore.teeSheetSettings?.display_booking_graph ? (
          <TeeSheetBookingGraph
            teeTimes={teeSheetStore.teetimes}
            divisionId={state.currentDivision}
            courseId={state.currentCourseId}
          />
        ) : null}

        <TeeSheetTabs
          division_id={state.currentDivision}
          courseDivisions={state.currentDivisions}
          course_id={state.currentCourseId}
        />
      </div>
    </>
  );
};

export default TeeSheet;
