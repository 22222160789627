import React, { useEffect, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router";
import { useAppDispatch } from "hooks/redux";
import useModal from "hooks/modals/useModal";
import axios, { CancelToken } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  GetSeasonLeaderboard,
  PostSeasonLeaderboard,
  TPostSeasonLeaderboard,
} from "api/rpc/2024-04/facilityAdmin/league/scoring/leaderboard";
import { GetLeagueDivision } from "api/rpc/2024-04/facilityAdmin/league/scoring/division";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import Portal from "elements/Portal";
import Sheet from "components/sheet/Sheet";
import Input from "components/form/input";
import { TLeagueSeasonLeaderboard, TLeagueDivision } from "redux/reducers/models/league";
import { showError, showSuccess } from "redux/actions/ui";
import { Select } from "components/select";
import { valueToString } from "helpers/Helpers";

export default function Leaderboards() {
  const { leagueId } = useParams<{ leagueId: string }>();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [leaderboards, setLeaderboards] = useState<Array<TLeagueSeasonLeaderboard>>(undefined);
  const [divisions, setDivisions] = useState<Array<TLeagueDivision>>(undefined);

  const {
    state: newLeaderboardState,
    updateModal,
    closeModal,
  } = useModal<TPostSeasonLeaderboard>({
    league_id: Number(leagueId),
    title: "",
    best_scores: 20,
    organization: "individual",
    league_division_id: null,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();

    void loadLeaderboards(source.token);
    void loadLeagueDivisions(source.token);

    return () => source.cancel();
  }, []);

  async function loadLeaderboards(token?: CancelToken) {
    const leaderboardRes = await GetSeasonLeaderboard({ league_id: leagueId }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (leaderboardRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading leaderboards")); // TODO: Translation
      return;
    }

    setLeaderboards(leaderboardRes.data);
  }

  async function loadLeagueDivisions(token?: CancelToken) {
    const divisionRes = await GetLeagueDivision({ league_id: leagueId }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (divisionRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading divisions")); // TODO: Translation
      return;
    }

    setDivisions(divisionRes.data);
  }

  async function createLeaderboard() {
    const leaderboardRes = await PostSeasonLeaderboard(newLeaderboardState, true);

    if (leaderboardRes.status !== StatusCode.OK) {
      dispatch(showError("Error creating leaderboards")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Leaderboard created successfully")); // TODO: Translation
    void closeModal();
    void loadLeaderboards();
  }

  const primaryAction = {
    content: "Create", // TODO: Translation
    action: () => updateModal({ isOpen: true }),
  };

  return (
    <Page title={"Leaderboards" /* TODO Translation */} primaryAction={primaryAction}>
      <DataTable
        columns={[
          { label: "Title" }, // TODO: Translation
          { label: "Division" }, // TODO: Translation
          { label: "Organization" }, // TODO: Translation
        ]}
        loading={leaderboards === undefined}
      >
        {leaderboards?.map(leaderboard => (
          <tr
            key={leaderboard.id}
            onClick={() => history.push(`/admin/league/${leagueId}/scoring/leaderboards/${leaderboard.id}`)}
            className="clickable"
          >
            <td>{leaderboard.title}</td>
            <td>{leaderboard.division?.title}</td>
            <td>{valueToString(leaderboard.organization)}</td>
          </tr>
        ))}
      </DataTable>

      <Portal isMounted={newLeaderboardState.isOpen}>
        <Sheet
          open={newLeaderboardState.isOpen}
          title="New Leaderboard" // TODO: Translation
          onCancel={closeModal}
          onOk={createLeaderboard}
          okText="Create" // TODO: Translation
          size="small"
          overflow
        >
          <Input
            className="mb-2"
            label={"Title"} // TODO: Translation
            value={newLeaderboardState.title}
            onChange={e => updateModal({ title: e.target.value })}
          />
          <Select
            label="Division" // TODO: Translation
            onChange={(value: number) => updateModal({ league_division_id: value })}
            className="mb-2"
          >
            {divisions?.map(division => (
              <Select.Option key={division.id} value={division.id}>
                {division.title}
              </Select.Option>
            ))}
          </Select>
          <Input
            className="mb-2"
            label={"Scores to Count"} // TODO: Translation
            value={newLeaderboardState.best_scores}
            onChange={e => updateModal({ best_scores: Number(e.target.value) })}
            type="number"
          />
          <Select
            label="Organization" // TODO: Translation
            defaultValue={"individual"}
            onChange={(value: "individual" | "team") => updateModal({ organization: value })}
          >
            <Select.Option value={"individual"}>{"Individual" /* TODO: Translation */}</Select.Option>
            <Select.Option value={"team"}>{"Team" /* TODO: Translation */}</Select.Option>
          </Select>
        </Sheet>
      </Portal>
    </Page>
  );
}
