import React, { useEffect, useMemo, useState, useCallback } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import Spin from "components/spin/spin";
import { TEventRound, TLeagueTeamScores, TLeagueRoundFormat } from "redux/reducers/models/league";

interface ITeamScoresTableProps {
  format: TLeagueRoundFormat;
  teamScores: Array<TLeagueTeamScores>;
  rounds: Array<TEventRound>;
  formatRounds: Array<TEventRound>;
  selectedRoundId: number;
  onClick: (teamId: number) => void;
}

interface ISortState {
  defaultSorted: boolean;
  sortByType: string;
  sortByDirection: boolean;
}

export function TeamScoresTable(props: ITeamScoresTableProps) {
  const { rounds, formatRounds, selectedRoundId, onClick, format } = props;

  const [holeScoreId, setHoleScoreId] = useState<number>(null);

  const [teamScores, setTeamScores] = useState<TLeagueTeamScores[]>(props.teamScores);

  const [state, setState] = useState<ISortState>({
    defaultSorted: false,
    sortByType: "default",
    sortByDirection: false,
  });

  useEffect(() => {
    if (!state.defaultSorted && teamScores) {
      void sortByDefault();
    }
  }, [teamScores]);

  function displayHoleScores() {
    const holeScoreRound = rounds.find(round => round.id === holeScoreId);
    return (
      <div className="league-leaderboard">
        {holeScoreRound ? (
          <table className="league-leaderboard-hole-scores">
            <thead>
              <tr>
                <th className="league-leaderboard-title">{""}</th>
                {holeScoreRound.hole_scores.map((score, index: number) => {
                  if (score.hole_number <= 9.1) {
                    return (
                      <th key={index} className="league-leaderboard-score">
                        {score.hole_number === 9.1
                          ? "Out" // TODO: Translation
                          : score.hole_number}
                      </th>
                    );
                  } else if (score.hole_number > 9.1) {
                    return (
                      <th key={index} className="league-leaderboard-score">
                        {score.hole_number === 18.1
                          ? "In" // TODO: Translation
                          : score.hole_number === 18.2
                          ? "Total" // TODO: Translation
                          : score.hole_number}
                      </th>
                    );
                  }
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="league-leaderboard-title">{""}</td>

                {holeScoreRound.hole_scores.map((score, index: number) => {
                  return (
                    <td key={index} className="league-leaderboard-score">
                      {score.yardage}
                    </td>
                  );
                })}
              </tr>

              <tr>
                <td className="league-leaderboard-title">{"Handicap" /* TODO: Translation */}</td>

                {holeScoreRound.hole_scores.map((score, index: number) => {
                  return (
                    <td key={index} className="league-leaderboard-score">
                      {score.handicap}
                    </td>
                  );
                })}
              </tr>

              <tr>
                <td className="league-leaderboard-title">{"Par" /* TODO: Translation */}</td>

                {holeScoreRound.hole_scores.map((score, index: number) => {
                  return (
                    <td key={index} className="league-leaderboard-score">
                      {score.par}
                    </td>
                  );
                })}
              </tr>

              <tr>
                <td className="league-leaderboard-title">{"Gross" /* TODO: Translation */}</td>

                {holeScoreRound.hole_scores.map((score, index: number) => {
                  return (
                    <td key={index} className="league-leaderboard-score">
                      {score.hole_number === 9.1 || score.hole_number === 18.1 || score.hole_number === 18.2 ? (
                        <div>{score.gross}</div>
                      ) : (
                        <div
                          className={classNames({
                            "league-leaderboard-score-eagle": score.par - score.gross >= 2,
                            "league-leaderboard-score-birdie": score.par - score.gross === 1,
                            "league-leaderboard-score-par": score.par - score.gross === 0,
                            "league-leaderboard-score-bogey": score.par - score.gross === -1,
                            "league-leaderboard-score-double-bogey": score.par - score.gross <= -2,
                          })}
                        >
                          {score.gross}
                        </div>
                      )}
                    </td>
                  );
                })}
              </tr>

              <tr>
                <td className="league-leaderboard-title">{"Net" /* TODO: Translation */}</td>

                {holeScoreRound.hole_scores.map((score: any, index: number) => {
                  return (
                    <td key={index} className="league-leaderboard-score">
                      {score.hole_number === 9.1 || score.hole_number === 18.1 || score.hole_number === 18.2 ? (
                        <div>{score.net}</div>
                      ) : (
                        <div
                          className={classNames({
                            "league-leaderboard-score-eagle": score.par - score.net >= 2,
                            "league-leaderboard-score-birdie": score.par - score.net === 1,
                            "league-leaderboard-score-par": score.par - score.net === 0,
                            "league-leaderboard-score-bogey": score.par - score.net === -1,
                            "league-leaderboard-score-double-bogey": score.par - score.net <= -2,
                          })}
                        >
                          {score.net}
                        </div>
                      )}
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
        ) : (
          <div style={{ height: "24px" }}>
            <Spin />
          </div>
        )}
      </div>
    );
  }

  function displayTeamScores() {
    return (
      <>
        <tr key={"Header"}>
          <td colSpan={format.team_scores === "best_scores" ? 3 : 4}>{"Player"}</td>
          {format.team_scores !== "net_scores" && <td>{"Gross"}</td>}
          {format.team_scores !== "gross_scores" && <td>{"Net"}</td>}
        </tr>
        {rounds ? (
          <>
            {rounds?.map((round, index) => {
              const customer = teamScores
                .find(team => team.team_id === selectedRoundId)
                .team.participants.find(participant => participant.user_id === round.customer_id);
              if (round.id === holeScoreId) {
                return (
                  <>
                    <tr
                      key={index + 1000}
                      onClick={() => setHoleScoreId(null)}
                      className="league-leaderboard-hide-border"
                    >
                      <td colSpan={format.team_scores === "best_scores" ? 3 : 4}>{customer?.customer?.full_name}</td>
                      {format.team_scores !== "net_scores" && (
                        <td
                          className={
                            formatRounds?.find(formatRound => formatRound.round_id === round.id)?.count_gross
                              ? "background-primary-light"
                              : ""
                          }
                        >
                          {round.gross_score}
                        </td>
                      )}
                      {format.team_scores !== "gross_scores" && (
                        <td
                          className={
                            formatRounds?.find(formatRound => formatRound.round_id === round.id)?.count_net
                              ? "background-primary-light"
                              : ""
                          }
                        >
                          {round.net_score}
                        </td>
                      )}
                    </tr>
                    <tr key={index + 10000}>
                      <td colSpan={5}>{displayHoleScores()}</td>
                    </tr>
                  </>
                );
              } else {
                return (
                  <tr key={index + 1000} onClick={() => setHoleScoreId(round.id)}>
                    <td colSpan={format.team_scores === "best_scores" ? 3 : 4}>{customer?.customer?.full_name}</td>
                    {format.team_scores !== "net_scores" && (
                      <td
                        className={
                          formatRounds?.find(formatRound => formatRound.round_id === round.id)?.count_gross
                            ? "background-primary-light"
                            : ""
                        }
                      >
                        {round.gross_score}
                      </td>
                    )}
                    {format.team_scores !== "gross_scores" && (
                      <td
                        className={
                          formatRounds?.find(formatRound => formatRound.round_id === round.id)?.count_net
                            ? "background-primary-light"
                            : ""
                        }
                      >
                        {round.net_score}
                      </td>
                    )}
                  </tr>
                );
              }
            })}
          </>
        ) : (
          <tr key={"Spin"}>
            <td colSpan={5}>
              <div style={{ height: "24px" }}>
                <Spin />
              </div>
            </td>
          </tr>
        )}
      </>
    );
  }

  function sortByDefault() {
    if (!teamScores) {
      return;
    }
    const currentScores = [...teamScores];
    const sortedScores = currentScores?.sort((currentScore, nextScore) => {
      if (!currentScore?.total) {
        return 1;
      } else if (!nextScore?.total) {
        return -1;
      } else {
        return nextScore?.total - currentScore?.total;
      }
    });
    setState(prevState => ({
      ...prevState,
      sortByType: "default",
      sortByDirection: true,
      defaultSorted: true,
    }));
    setTeamScores(sortedScores);
  }

  function sortLeaderboard(
    sortType: "default" | "total_points_bonus" | "total_points_participation" | "total",
    sortValue: string,
  ) {
    const currentScores = [...teamScores];
    let direction: boolean = state.sortByDirection;
    if (state.sortByType !== sortType) {
      direction = true;
    } else if (direction === false) {
      //Descending order, return to default order
      void sortByDefault();
      return;
    } else {
      direction = !direction;
    }

    const sortedScores = currentScores.sort((currentScore, nextScore) => {
      //Sort null values last
      if (!currentScore?.[sortValue]) {
        return 1;
      } else if (!nextScore?.[sortValue]) {
        return -1;
      } else {
        return direction
          ? nextScore?.[sortValue] - currentScore?.[sortValue]
          : currentScore?.[sortValue] - nextScore?.[sortValue];
      }
    });
    setState(prevState => ({ ...prevState, sortByType: sortType, sortByDirection: direction }));
    setTeamScores(sortedScores);
  }

  return (
    <DataTable
      columns={[
        { label: "Rank", width: "15%" }, // TODO: Translation
        { label: "Team", width: "40%" }, // TODO: Translation
        {
          label: "Bonus",
          content: (
            <p
              onClick={() => sortLeaderboard("total_points_bonus", "total_points_bonus")}
              className="leaderboard-header"
            >
              {"Bonus"} {/* TODO: Translation */}
              <FontAwesomeIcon
                style={{ visibility: state?.sortByType === "total_points_bonus" ? "visible" : "hidden" }}
                icon={["far", state?.sortByDirection ? "arrow-down-long" : "arrow-up-long"]}
              />
            </p>
          ),
          width: "15%",
        },
        {
          label: "Participation",
          content: (
            <p
              onClick={() => sortLeaderboard("total_points_participation", "total_points_participation")}
              className="leaderboard-header"
            >
              {"Participation"} {/* TODO: Translation */}
              <FontAwesomeIcon
                style={{ visibility: state?.sortByType === "total_points_participation" ? "visible" : "hidden" }}
                icon={["far", state?.sortByDirection ? "arrow-down-long" : "arrow-up-long"]}
              />
            </p>
          ),
          width: "15%",
        },
        {
          label: "Total",
          content: (
            <p onClick={() => sortLeaderboard("total", "total")} className="leaderboard-header">
              {"Points"} {/* TODO: Translation */}
              <FontAwesomeIcon
                style={{ visibility: state?.sortByType === "total" ? "visible" : "hidden" }}
                icon={["far", state?.sortByDirection ? "arrow-down-long" : "arrow-up-long"]}
              />
            </p>
          ),
          width: "15%",
        },
      ]}
      loading={!teamScores}
    >
      {teamScores?.map((teamScore, index) => {
        if (teamScore.team_id === selectedRoundId) {
          return (
            <>
              <tr key={index} onClick={() => onClick(null)} className="league-leaderboard-hide-border">
                <td>{index + 1}</td>
                <td>{teamScore.team?.name}</td>
                <td>{teamScore.total_points_bonus}</td>
                <td>{teamScore.total_points_participation}</td>
                <td>{teamScore.total}</td>
              </tr>
              {displayTeamScores()}
              {/* <tr key={index + 10000}>
                <td colSpan={5}>{displayHoleScores()}</td>
              </tr> */}
            </>
          );
        } else {
          return (
            <tr key={index} onClick={() => onClick(teamScore.team_id)}>
              <td>{index + 1}</td>
              <td>{teamScore.team?.name}</td>
              <td>{teamScore.total_points_bonus}</td>
              <td>{teamScore.total_points_participation}</td>
              <td>{teamScore.total}</td>
            </tr>
          );
        }
      })}
    </DataTable>
  );
}
