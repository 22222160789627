import React, { useState } from "react";
import { NavLink, useRouteMatch } from "react-router-dom";

import CustomerProfile from "./profile/CustomerProfile";
import CustomerReservations from "./reservations/CustomerReservations";
import CustomerMemberships from "./memberships/CustomerMemberships";
import CustomerItemSales from "./itemSales/CustomerItemSales";
import CustomerHouseAccounts from "./houseAccounts/CustomerHouseAccounts";

import "../../../facility/customer/tabs/customerTabs.scss";
import CustomerTickets from "./tickets/CustomerTickets";
import CustomerNotes from "./notes/CustomerNotes";
import ClientCustomerPaymentMethods from "./paymentMethods/ClientCustomerPaymentMethods";

type TabNavigationType = {
  title: string;
  route: string;
};

/**
 * Handles Tab navigation within Customer.tsx on client-side.
 *     - Add a new tab using this element.
 *
 * @property {string | number} customerId used to generate and define active URL routes.
 * @returns {JSX.Element} A navigation list with the tab content displayed under it.
 */
export default function CustomerClientTabs(props: { customerId: string | number }): React.ReactElement {
  const routeMatch = useRouteMatch();
  const customerTabInfo = generateCustomerTabs(props.customerId);
  const [state, setState] = useState(routeMatch.url);

  return (
    <>
      <ul className="navigation-list">
        {customerTabInfo.map(route => (
          <li key={route.route} className="navigation-list-li">
            <NavLink
              to={route.route}
              key={route.route}
              onClick={() => setState(route.route)}
              className="customer-layout-nav-link"
            >
              {route.title}
            </NavLink>
          </li>
        ))}
      </ul>

      {displayCustomerPage(props.customerId, routeMatch.url)}
    </>
  );
}

/** Define the URL routes for the specific customer.  Returns array for profile navigation. */
const generateCustomerTabs = (customerId: number | string): TabNavigationType[] => {
  if (!customerId) {
    return [];
  }
  const idAsString = typeof customerId === "number" ? customerId.toString() : customerId;
  return [
    {
      title: "Profile",
      route: `/admin/customers/${idAsString}/profile`,
    },
    {
      title: "Reservations",
      route: `/admin/customers/${idAsString}/reservations`,
    },
    {
      title: "Memberships",
      route: `/admin/customers/${idAsString}/membership`,
    },
    {
      title: "Tickets",
      route: `/admin/customers/${idAsString}/tickets`,
    },
    {
      title: "House Accounts",
      route: `/admin/customers/${idAsString}/house-accounts`,
    },
    {
      title: "Payment Methods",
      route: `/admin/customers/${idAsString}/payment-methods`,
    },
    {
      title: "Item Sales",
      route: `/admin/customers/${idAsString}/item-sales`,
    },
    {
      title: "Notes",
      route: `/admin/customers/${idAsString}/notes`,
    },
  ];
};

/** Returns a JSX element based on the given customerID and a defined route URL. */
const displayCustomerPage = (customerId: number | string, routeURL: string): JSX.Element => {
  const idAsString = typeof customerId === "number" ? customerId.toString() : customerId;

  switch (routeURL) {
    case `/admin/customers/${idAsString}/profile`:
      return <CustomerProfile />;
    case `/admin/customers/${idAsString}/reservations`:
      return <CustomerReservations />;
    case `/admin/customers/${idAsString}/membership`:
      return <CustomerMemberships />;
    case `/admin/customers/${idAsString}/tickets`:
      return <CustomerTickets />;
    case `/admin/customers/${idAsString}/house-accounts`:
      return <CustomerHouseAccounts />;
    case `/admin/customers/${idAsString}/payment-methods`:
      return <ClientCustomerPaymentMethods />;
    case `/admin/customers/${idAsString}/item-sales`:
      return <CustomerItemSales />;
    case `/admin/customers/${idAsString}/notes`:
      return <CustomerNotes />;

    // Back-end not built
    // case `/admin/customers/${idAsString}/notes`:
    //   return <CustomerNotes />;
    default:
      return <></>;
  }
};
