import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { TTournamentTeam, TTournamentTeamPlayer } from "redux/reducers/models/tournament";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/tournament/scoring/team";

export type TGetTournamentTeams = {
  id?: string | number;
  tournament_id?: string | number;
};

export type TPostTournamentTeam = {
  tournament_id: string | number;
  name?: string;
};

export type TPutTournamentTeam = {
  tournament_id: string | number;
  name?: string;
};

export async function GetTournamentTeams(params: TGetTournamentTeams, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<TTournamentTeam[]>(baseUrl, params, useGlobalLoader, token);
}
export async function PostTournamentTeam(params: TPostTournamentTeam, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}
export async function PutTournamentTeam(params: TPutTournamentTeam, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

export async function GetTournamentTeamPlayers(
  params: { team_id: string | number },
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet<TTournamentTeamPlayer[]>(baseUrl + "/participant", params, useGlobalLoader, token);
}

export async function PutTournamentTeamPlayers(
  params: { team_id: string | number; participant_ids: number[] },
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIPut(baseUrl + "/participant", params, useGlobalLoader, token);
}
