import { GetFacility } from "api/rpc/2024-04/masterAdmin/facility/facility";
import { LocalStorage } from "api/localstorage";
// import { ICartState } from "reducers/cart";
import { StatusCode } from "api/protocols";
import { GetWeather } from "api/rpc/2024-04/masterAdmin/weather/weather";

export function loadFacilities(params: any, useGlobalLoader: boolean) {
  return async (dispatch: any) => {
    const res = await GetFacility(params, useGlobalLoader);
    if (res.status !== StatusCode.OK) {
      console.log(res);
      return;
    }

    dispatch({
      type: "facility.update",
      payload: {
        isLoaded: true,
        facilities: res.data,
        facility: res.data.length > 1 ? null : res.data[0],
      },
    });
  };
}

export function selectFacility(facility: Record<string, any>) {
  return (dispatch: any) => {
    dispatch({
      type: "facility.update",
      payload: {
        isLoaded: true,
        facility: facility,
      },
    });
  };
}

export function loadFacilityWeather(facility_id: number, useGlobalLoader: boolean) {
  return async (dispatch: any) => {
    const res = await GetWeather(facility_id, useGlobalLoader);

    if (res.status !== StatusCode.OK) {
      console.log(res);
      return;
    }

    // console.log("Facility Weather: ", res.data);

    dispatch({
      type: "facility.update",
      payload: {
        weather: res.data,
      },
    });
  };
}

export function loadAdminFacility(params: any, useGlobalLoader: boolean) {
  return async (dispatch: any) => {
    const res = await GetFacility(params, useGlobalLoader);
    if (res.status !== StatusCode.OK) {
      console.log(res);
      return;
    }

    console.log("Admin Facility: ", res.data[0]);

    dispatch({
      type: "facility.update",
      payload: {
        isLoaded: true,
        facility: res.data[0],
      },
    });
  };
}

export interface IFacilityActions {
  loadFacilities: typeof loadFacilities;
  selectFacility: typeof selectFacility;
}
