import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { TLeagueDivision } from "redux/reducers/models/league";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/league/scoring";

type TGetLeagueDivision = {
  id?: string | number;
  league_id?: string | number;
};

export type TPostLeagueDivision = {
  league_id: string | number;
  title: string;
};

export type TPutLeagueDivision = {
  id: string | number;
  title?: string;
};

export async function GetLeagueDivision(params: TGetLeagueDivision, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<TLeagueDivision[]>(baseUrl + "/division", params, useGlobalLoader, token);
}

export async function PostLeagueDivision(params: TPostLeagueDivision, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/division", params, useGlobalLoader);
}

export async function PutLeagueDivision(params: TPutLeagueDivision, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/division", params, useGlobalLoader);
}

export async function DeleteLeagueDivision(params: { id: string | number }, useGlobalLoader: boolean) {
  return await APIDelete(baseUrl + "/division", params, useGlobalLoader);
}

/*********************
 * League Division Participants
 */

type TPostDivisionParticipant = {
  division_id: string | number;
  /** Pass in all participant ids, even if already included */
  participant_ids: Array<number>;
};

export type TGetAllLeagueDivisionParticipants = {
  league_division_id: number;
  league_participant_id: number;
};

export async function GetLeagueDivisionParticipant(
  params: { division_id: string | number },
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseUrl + "/division/participant", params, useGlobalLoader, token);
}

//PUT League Division Participant
export async function PostLeagueDivisionParticipant(params: TPostDivisionParticipant, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/division/participant", params, useGlobalLoader);
}

/** Returns all division/participant assignments within the league */
export async function GetAllLeagueDivisionParticipants(
  params: { league_id: string | number },
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet<TGetAllLeagueDivisionParticipants[]>(
    baseUrl + "/division/participant/all",
    params,
    useGlobalLoader,
    token,
  );
}
