import React, { ChangeEvent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { showError, showSuccess } from "redux/actions/ui";
import { isPositiveInteger } from "helpers/Helpers";
import { isEqualWith, isNull } from "lodash";

import {
  GetStaffAccounts,
  PutStaffAccount,
  DisableStaffAccount,
  ReactivateStaffAccount,
  PutStaffAccountPermissions,
} from "api/rpc/2024-04/masterAdmin/client/settings/staffAccount";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Input from "components/form/input/Input";
import FormLayout from "components/form/FormLayout";
import Card from "components/card/Card";
import Form from "components/form/Form";
import Toggle from "components/form/toggle/Toggle";
import Popup from "components/popup/Popup";
import { Badge } from "components/badge/Badge";
import Icon from "components/icon/Icon";

interface IStaffPermissions {
  accounts_charge_all: boolean;
  accounts_post_adjustment: boolean;
  admin_accounts_disable: boolean;
  admin_permissions_edit: boolean;
  admin_accounts_edit: boolean;
  bookings_edit_note: boolean;
  cash_outs_create: boolean;
  cash_outs_edit: boolean;
  cash_outs_view_summary: boolean;
  customers_edit_note: boolean;
  discounts_create: boolean;
  gift_cards_import: boolean;
  gift_cards_view: boolean;
  memberships_apply_manual: boolean;
  merge_customers: boolean;
  products_archive: boolean;
  products_create: boolean;
  products_edit: boolean;
  products_edit_cost: boolean;
  products_edit_price: boolean;
  orders_remove_line_item: boolean;
  orders_remove_line_item_after_send: boolean;
  orders_edit_line_item_price: boolean;
  orders_apply_discount: boolean;
  orders_void: boolean;
  orders_refund_tip: boolean;
  reports_access: boolean;
  reservations_credit_card_override: boolean;
  table_service_table_override: boolean;
  tee_sheet_credit_card_override: boolean;
  tee_sheet_charge_no_show: boolean;
  orders_edit_user: boolean;
  orders_edit_register: boolean;
  orders_backdate: boolean;
  user_edit_customer_type: boolean;
}

interface IStaff {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  username: string;
  state: string;
  quick_code: string;
  permissions: IStaffPermissions;
}

export default function StaffAccount() {
  const { adminId } = useParams<{ adminId: string }>();
  const { masterClientStore } = useAppSelector(store => store);
  const dispatch = useAppDispatch();

  const history = useHistory();

  const [staffState, setStaffState] = useState<IStaff>(undefined);
  const [staffStateBeforeChanges, setStaffStateBeforeChanges] = useState<IStaff>(undefined);

  const [resetPasswordPopup, setResetPasswordPopup] = useState<boolean>(false);
  const [reactivateAccountPopup, setReactivateAccountPopup] = useState<boolean>(false);

  useEffect(() => {
    void loadClientStaff();
  }, []);

  async function loadClientStaff() {
    const staffRes = await GetStaffAccounts(
      {
        id: Number(adminId),
      },
      true,
    );

    if (staffRes.status !== StatusCode.OK) {
      dispatch(showError("Error Loading Staff Account"));
      return;
    }

    setStaffState(prev => ({
      ...prev,
      id: staffRes.data[0].id,
      first_name: staffRes.data[0].first_name ?? "",
      last_name: staffRes.data[0].last_name ?? "",
      email: staffRes.data[0].email ?? "",
      username: staffRes.data[0].username ?? "",
      state: staffRes.data[0].state ?? "",
      quick_code: "",
      permissions: {
        accounts_charge_all: staffRes.data[0].permissions.accounts_charge_all,
        accounts_post_adjustment: staffRes.data[0].permissions.accounts_post_adjustment,
        admin_accounts_disable: staffRes.data[0].permissions.admin_accounts_disable,
        admin_permissions_edit: staffRes.data[0].permissions.admin_permissions_edit,
        admin_accounts_edit: staffRes.data[0].permissions.admin_accounts_edit,
        bookings_edit_note: staffRes.data[0].permissions.bookings_edit_note,
        cash_outs_create: staffRes.data[0].permissions.cash_outs_create,
        cash_outs_edit: staffRes.data[0].permissions.cash_outs_edit,
        cash_outs_view_summary: staffRes.data[0].permissions.cash_outs_view_summary,
        customers_edit_note: staffRes.data[0].permissions.customers_edit_note,
        discounts_create: staffRes.data[0].permissions.discounts_create,
        gift_cards_import: staffRes.data[0].permissions.gift_cards_import,
        gift_cards_view: staffRes.data[0].permissions.gift_cards_view,
        memberships_apply_manual: staffRes.data[0].permissions.memberships_apply_manual,
        merge_customers: staffRes.data[0].permissions.merge_customers,
        products_archive: staffRes.data[0].permissions.products_archive,
        products_create: staffRes.data[0].permissions.products_create,
        products_edit: staffRes.data[0].permissions.products_edit,
        products_edit_cost: staffRes.data[0].permissions.products_edit_cost,
        products_edit_price: staffRes.data[0].permissions.products_edit_price,
        orders_remove_line_item: staffRes.data[0].permissions.orders_remove_line_item,
        orders_remove_line_item_after_send: staffRes.data[0].permissions.orders_remove_line_item_after_send,
        orders_edit_line_item_price: staffRes.data[0].permissions.orders_edit_line_item_price,
        orders_apply_discount: staffRes.data[0].permissions.orders_apply_discount,
        orders_void: staffRes.data[0].permissions.orders_void,
        orders_refund_tip: staffRes.data[0].permissions.orders_refund_tip,
        reports_access: staffRes.data[0].permissions.reports_access,
        reservations_credit_card_override: staffRes.data[0].permissions.reservations_credit_card_override,
        table_service_table_override: staffRes.data[0].permissions.table_service_table_override,
        tee_sheet_credit_card_override: staffRes.data[0].permissions.tee_sheet_credit_card_override,
        tee_sheet_charge_no_show: staffRes.data[0].permissions.tee_sheet_charge_no_show,
        orders_edit_user: staffRes.data[0].permissions.orders_edit_user,
        orders_edit_register: staffRes.data[0].permissions.orders_edit_register,
        orders_backdate: staffRes.data[0].permissions.orders_backdate,
        user_edit_customer_type: staffRes.data[0].permissions.user_edit_customer_type,
      },
    }));

    setStaffStateBeforeChanges(staffState);
  }

  async function saveStaffDetails() {
    const isEmpty = (x: any) => x === "" || x === null || x === undefined;

    if (!isEmpty(staffState.quick_code) && !isPositiveInteger(staffState.quick_code)) {
      dispatch(showError("Invalid Quick Code"));
      return;
    }

    const params = {
      id: staffState.id,
      first_name: staffState.first_name,
      last_name: staffState.last_name,
      email: staffState.email,
      username: staffState.username,
      quick_code: isEmpty(staffState.quick_code) ? undefined : parseInt(staffState.quick_code),
    };

    const putFacilityAdminResponse = await PutStaffAccount(params, true);

    if (putFacilityAdminResponse.status !== StatusCode.OK) {
      dispatch(showError("Error Updating Staff Account"));
      return;
    }

    const putFacilityAdminPermissionsResponse = await PutStaffAccountPermissions(
      {
        user_id: parseInt(adminId),
        accounts_charge_all: staffState.permissions.accounts_charge_all,
        accounts_post_adjustment: staffState.permissions.accounts_post_adjustment,
        admin_accounts_disable: staffState.permissions.admin_accounts_disable,
        admin_permissions_edit: staffState.permissions.admin_permissions_edit,
        admin_accounts_edit: staffState.permissions.admin_accounts_edit,
        bookings_edit_note: staffState.permissions.bookings_edit_note,
        cash_outs_create: staffState.permissions.cash_outs_create,
        cash_outs_edit: staffState.permissions.cash_outs_edit,
        cash_outs_view_summary: staffState.permissions.cash_outs_view_summary,
        customers_edit_note: staffState.permissions.customers_edit_note,
        discounts_create: staffState.permissions.discounts_create,
        gift_cards_import: staffState.permissions.gift_cards_import,
        gift_cards_view: staffState.permissions.gift_cards_view,
        memberships_apply_manual: staffState.permissions.memberships_apply_manual,
        merge_customers: staffState.permissions.merge_customers,
        products_archive: staffState.permissions.products_archive,
        products_create: staffState.permissions.products_create,
        products_edit: staffState.permissions.products_edit,
        products_edit_cost: staffState.permissions.products_edit_cost,
        products_edit_price: staffState.permissions.products_edit_price,
        orders_remove_line_item: staffState.permissions.orders_remove_line_item,
        orders_remove_line_item_after_send: staffState.permissions.orders_remove_line_item_after_send,
        orders_edit_line_item_price: staffState.permissions.orders_edit_line_item_price,
        orders_apply_discount: staffState.permissions.orders_apply_discount,
        orders_void: staffState.permissions.orders_void,
        orders_refund_tip: staffState.permissions.orders_refund_tip,
        reports_access: staffState.permissions.reports_access,
        reservations_credit_card_override: staffState.permissions.reservations_credit_card_override,
        table_service_table_override: staffState.permissions.table_service_table_override,
        tee_sheet_credit_card_override: staffState.permissions.tee_sheet_credit_card_override,
        tee_sheet_charge_no_show: staffState.permissions.tee_sheet_charge_no_show,
        orders_edit_user: staffState.permissions.orders_edit_user,
        orders_edit_register: staffState.permissions.orders_edit_register,
        orders_backdate: staffState.permissions.orders_backdate,
        user_edit_customer_type: staffState.permissions.user_edit_customer_type,
      },
      true,
    );

    if (putFacilityAdminPermissionsResponse.status !== StatusCode.OK) {
      dispatch(showError("Error Updating Staff Permissions"));
      return;
    }

    dispatch(showSuccess("Staff Account Updated Successfully"));
    setStaffStateBeforeChanges(staffState);
  }

  async function disableStaffAccount() {
    const putDisableFacilityAdminResponse = await DisableStaffAccount({ admin_id: staffState.id }, true);

    if (putDisableFacilityAdminResponse.status !== StatusCode.OK) {
      dispatch(showError("Error Disabling Staff Account"));
      return;
    }

    dispatch(showSuccess("Staff Account Disabled Successfully"));
    history.push("/admin/client-settings/staff");
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setStaffState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleToggleChange(event: ChangeEvent<HTMLInputElement>) {
    const id = event?.target?.id;
    const checked = event?.target?.checked;

    if (id === undefined || checked === undefined) {
      return;
    }

    setStaffState(prev => {
      return { ...prev, permissions: { ...prev.permissions, [id]: checked } };
    });
  }

  function handleProductsEditToggleChange(event: ChangeEvent<HTMLInputElement>) {
    const checked = event?.target?.checked;

    if (checked === undefined) {
      return;
    }

    setStaffState(prev => {
      return {
        ...prev,
        permissions: {
          ...prev.permissions,
          products_edit: checked,
          products_edit_cost: false,
          products_edit_price: false,
        },
      };
    });
  }

  function unsavedChangesExist() {
    if (staffStateBeforeChanges === undefined) {
      if (staffState) {
        setStaffStateBeforeChanges(staffState);
      }
      return false;
    }

    return !isEqualWith(staffStateBeforeChanges, staffState, (originalValue, newValue) => {
      if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
        return true;
      }
    });
  }

  function cancelUnsavedChanges() {
    setStaffState(staffStateBeforeChanges);
  }

  const disableAction = {
    content: "Disable",
    action: disableStaffAccount,
  };

  const secondaryActions = [...(staffState?.state !== "disabled" ? [disableAction] : [])];

  async function handleReactivateAccount() {
    const activateRes = await ReactivateStaffAccount({ admin_id: staffState.id }, true);

    if (activateRes.status !== StatusCode.OK) {
      dispatch(showError("Error Reactivating Staff Account"));
    }

    dispatch(showError("Staff Account Reactivated Successfully"));
    history.push("/admin/client-settings/staff");
  }

  const primaryAction = { content: "Reactivate Account", action: () => setReactivateAccountPopup(true) };

  return (
    <Page
      title={`Edit Staff Member`}
      subtitle={masterClientStore.client ? masterClientStore.client.full_name : "No Client Selected"}
      narrow
      breadcrumbs={[{ prefix: true, label: "Staff Accounts", url: "/admin/client-settings/staff" }]}
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: saveStaffDetails,
        onCancel: cancelUnsavedChanges,
      }}
      primaryAction={staffState?.state === "disabled" ? primaryAction : null}
      secondaryActions={secondaryActions}
    >
      {masterClientStore.client && (
        <>
          <div className="mb-4">
            {staffState?.state === "disabled" ? (
              <Badge
                type="error"
                size="medium"
                iconLeft={<Icon icon="times" style="far" size="xsmall" />}
                iconSize="small"
                iconStyle="fas"
              >
                Disabled
              </Badge>
            ) : null}
          </div>
          {staffState && staffStateBeforeChanges && (
            <Form>
              <Card>
                <Card.Section>
                  <FormLayout>
                    <FormLayout.Group>
                      <Input
                        value={staffState.first_name}
                        label="First Name"
                        id="first_name"
                        onChange={handleInputChange}
                        placeholder="First Name"
                        disabled={staffState.state === "disabled"}
                      />
                      <Input
                        value={staffState.last_name}
                        label="Last Name"
                        id="last_name"
                        onChange={handleInputChange}
                        placeholder="Last Name"
                        disabled={staffState.state === "disabled"}
                      />
                    </FormLayout.Group>
                    <FormLayout.Group>
                      <Input
                        value={staffState.email}
                        label="Email"
                        id="email"
                        onChange={handleInputChange}
                        placeholder="Email"
                        disabled={staffState.state === "disabled"}
                      />
                      <Input
                        value={staffState.username}
                        label="Username"
                        id="username"
                        onChange={handleInputChange}
                        placeholder="Username"
                        disabled={staffState.state === "disabled"}
                      />
                    </FormLayout.Group>
                  </FormLayout>
                </Card.Section>
              </Card>
              {staffState?.state !== "disabled" && (
                <Card title="Permissions">
                  <Card.Section title={"Staff Accounts"}>
                    <FormLayout>
                      <FormLayout.Group>
                        <Toggle
                          labelRight={"Disable Staff Accounts"}
                          id="admin_accounts_disable"
                          onChange={handleToggleChange}
                          checked={staffState.permissions.admin_accounts_disable}
                          size="medium"
                        />
                        <Toggle
                          labelRight={"Edit Staff Accounts"}
                          id="admin_accounts_edit"
                          onChange={handleToggleChange}
                          checked={staffState.permissions.admin_accounts_edit}
                          size="medium"
                        />
                        {staffState.permissions.admin_accounts_edit && (
                          <div className="ml-3">
                            <Toggle
                              labelRight={"Edit Staff Permissions"}
                              id="admin_permissions_edit"
                              onChange={handleToggleChange}
                              checked={staffState.permissions.admin_permissions_edit}
                              size="medium"
                            />
                          </div>
                        )}
                      </FormLayout.Group>
                    </FormLayout>
                  </Card.Section>
                  <Card.Section title={"Products"}>
                    <FormLayout>
                      <FormLayout.Group>
                        <Toggle
                          labelRight={"Archive Products"}
                          id="products_archive"
                          onChange={handleToggleChange}
                          checked={staffState.permissions.products_archive}
                          size="medium"
                        />
                        <Toggle
                          labelRight={"Create Products"}
                          id="products_create"
                          onChange={handleToggleChange}
                          checked={staffState.permissions.products_create}
                          size="medium"
                        />
                      </FormLayout.Group>
                      <FormLayout.Group>
                        <div>
                          <Toggle
                            labelRight={"Edit Products"}
                            onChange={handleProductsEditToggleChange}
                            checked={staffState.permissions.products_edit}
                            size="medium"
                          />
                          {staffState.permissions.products_edit && (
                            <div className="ml-3">
                              <Toggle
                                labelRight={"Edit Product Cost"}
                                id="products_edit_cost"
                                onChange={handleToggleChange}
                                checked={staffState.permissions.products_edit_cost}
                                size="medium"
                              />
                              <Toggle
                                labelRight={"Edit Product Price"}
                                id="products_edit_price"
                                onChange={handleToggleChange}
                                checked={staffState.permissions.products_edit_price}
                                size="medium"
                              />
                            </div>
                          )}
                        </div>
                        <Toggle
                          labelRight={"Create Discounts"}
                          id="discounts_create"
                          onChange={handleToggleChange}
                          checked={staffState.permissions.discounts_create}
                          size="medium"
                        />
                      </FormLayout.Group>
                    </FormLayout>
                  </Card.Section>
                  <Card.Section title={"Reports"}>
                    <FormLayout>
                      <FormLayout.Group>
                        <Toggle
                          labelRight={"Reports Access"}
                          id="reports_access"
                          onChange={handleToggleChange}
                          checked={staffState.permissions.reports_access}
                          size="medium"
                        />
                      </FormLayout.Group>
                    </FormLayout>
                  </Card.Section>
                  <Card.Section title={"Tee Sheet"}>
                    <FormLayout>
                      <FormLayout.Group>
                        <Toggle
                          labelRight={"Credit Card Override"}
                          id="tee_sheet_credit_card_override"
                          onChange={handleToggleChange}
                          checked={staffState.permissions.tee_sheet_credit_card_override}
                          size="medium"
                        />
                        <Toggle
                          labelRight={"Charge No Show"}
                          id="tee_sheet_charge_no_show"
                          onChange={handleToggleChange}
                          checked={staffState.permissions.tee_sheet_charge_no_show}
                          size="medium"
                        />
                      </FormLayout.Group>
                    </FormLayout>
                  </Card.Section>
                  <Card.Section title="Customers">
                    <FormLayout>
                      <FormLayout.Group>
                        <Toggle
                          labelRight={"Edit Customer Type"}
                          id="user_edit_customer_type"
                          onChange={handleToggleChange}
                          checked={staffState.permissions.user_edit_customer_type}
                          size="medium"
                        />
                        <Toggle
                          labelRight={"Merge Customers"}
                          id="merge_customers"
                          onChange={handleToggleChange}
                          checked={staffState.permissions.merge_customers}
                          size="medium"
                        />
                      </FormLayout.Group>
                    </FormLayout>
                  </Card.Section>
                  <Card.Section title="Orders">
                    <FormLayout>
                      <FormLayout.Group>
                        <Toggle
                          labelRight={"Import Gift Cards"}
                          id="gift_cards_import"
                          onChange={handleToggleChange}
                          checked={staffState.permissions.gift_cards_import}
                          size="medium"
                        />
                      </FormLayout.Group>
                    </FormLayout>
                  </Card.Section>
                </Card>
              )}
            </Form>
          )}

          <Popup
            open={reactivateAccountPopup}
            type="info"
            title="Reactivate Staff Account?"
            description="Are you sure you want to reactivate this staff account?"
            onOk={handleReactivateAccount}
            okText="Activate"
            cancelText="Cancel"
            onCancel={() => setReactivateAccountPopup(false)}
          />
        </>
      )}
    </Page>
  );
}
