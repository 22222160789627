import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { TFacilityForm } from "redux/reducers/models/facility";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/client/form";

type GetFacilityFormParams = {
  id?: number;
  variant_ids?: number[];
};

export type PostFacilityFormParams = {
  title: string;
};

export type PutFacilityFormParams = {
  id: number;
  title?: string;
};

type DeleteFacilityFormParams = {
  id: number;
};

interface IFacilityFormRes<T> {
  status: number;
  data: T;
  message?: string;
}

// GET Forms
export async function GetFacilityForm(
  params: GetFacilityFormParams,
  useGlobalLoader = true,
  token?: CancelToken,
): Promise<IFacilityFormRes<TFacilityForm[]>> {
  return await APIGet(baseUrl, params, useGlobalLoader, token);
}

// POST Form
export async function PostFacilityForm(
  params: PostFacilityFormParams,
  useGlobalLoader = true,
): Promise<IFacilityFormRes<TFacilityForm>> {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

// PUT Forms
export async function PutFacilityForm(
  params: PutFacilityFormParams,
  useGlobalLoader = true,
): Promise<IFacilityFormRes<PutFacilityFormParams>> {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

// PUT Forms
export async function DeleteFacilityForm(
  params: DeleteFacilityFormParams,
  useGlobalLoader = true,
): Promise<IFacilityFormRes<DeleteFacilityFormParams>> {
  return await APIDelete(baseUrl, params, useGlobalLoader);
}

export async function AddFormToVariant(params: any, useGlobalLoader: true) {
  return await APIPut(baseUrl + "/variant", params, useGlobalLoader);
}
