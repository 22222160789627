import { APIGet, APIPost, APIPut, APIDelete, StatusCode, IAPIResponse } from "api/protocols";
import { CancelToken } from "axios";
import { IGreenFee, IItem, IPowerCart, IStub } from "redux/reducers/models/ticket";

const apiVersion = "2022-09";
const adminType = "client-admin";
const baseTicketUrl = "/" + apiVersion + "/" + adminType + "/ticket";
const baseTicketStubUrl = "/2022-09/" + adminType + "/ticket";

export interface ITicket {
  id: number;
  client_id: number;
  title: string;
  subtitle: string;
  included: string;
  description: string;
  expiry_type: string;
  expiry_date: string;
  expiry_days: number;
  logo_image: string;
  value: number;
  archived: boolean;
  valid_facilities: Array<{
    id: number;
    long_name: string;
    full_name: string;
    short_name: string;
    address_line_1: string;
    address_line_2: string;
    city: string;
    province_name: string;
    province_code: string;
    province_id: number;
    country_name: string;
    country_code: string;
    postal: string;
    phone: string;
    enabled_currencies: boolean;
    customer_email: string;
    email: string;
    primary_locale: string;
    logo_source: string;
    emblem_source_url: string;
    holes: number;
    timezone: string;
    stripe_location_id: string;
    permissions: Array<{
      facility_id: number;
      products_create: boolean;
      products_edit: boolean;
      customer_types_create: boolean;
    }>;
  }>;
}

interface IGetTicketRes {
  data: ITicket[];
  message: string;
  status: StatusCode;
}

export type TTicketGet = {
  id?: number;
  customer_id?: number;
  /** Search by title and subtitle */
  search?: string;
  extended?: boolean;
  limit?: number;
  offset?: number;
};

// GET Tickets
export async function GetTickets(
  params: TTicketGet,
  useGlobalLoader: boolean,
  token?: CancelToken,
): Promise<IGetTicketRes> {
  return await APIGet(baseTicketUrl, params, useGlobalLoader, token);
}

// POST Ticket
export async function PostTicket(body: any, useGlobalLoader: boolean) {
  return await APIPost(baseTicketUrl, body, useGlobalLoader);
}

// GET Ticket Validate
interface IGetTicketValidate {
  code?: string;
}

export interface IGetTicketValidateRes extends IAPIResponse {
  data: {
    redemption_products: {
      green_fee: IGreenFee[];
      items: IItem[];
      power_cart: IPowerCart[];
    };
    stub: IStub;
  };
}

export async function GetTicketValidate(
  params: IGetTicketValidate,
  useGlobalLoader: boolean,
): Promise<IGetTicketValidateRes> {
  return await APIGet(baseTicketUrl + "/stub/validate", params, useGlobalLoader);
}

interface IGetTicketStub {
  code?: string;
  customer_id?: number;
  id?: number;
  search?: string;
  extended?: boolean;
}
//GET Ticket Stub
export async function GetTicketStub(params: IGetTicketStub, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseTicketUrl + "/stub", params, useGlobalLoader, token);
}

//PUT Ticket Stub
export async function PutTicketStub(params: IGetTicketStub, useGlobalLoader: boolean) {
  return await APIPut(baseTicketStubUrl + "/stub", params, useGlobalLoader);
}

//PUT Update Tickets
export async function PutTicket(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseTicketUrl, params, useGlobalLoader);
}

// PUT Redeem

export async function PutRedeem(code: string, useGlobalLoader: boolean) {
  return await APIPut(baseTicketUrl + `/stub/redeem?code=${code}`, null, useGlobalLoader);
}

//POST Add Ticket To Product / variant
export async function AddTicketToVariant(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseTicketUrl + "/attach", params, useGlobalLoader);
}

//DELETE Ticket from product / variant
export async function DeleteTicketFromVariant(params: any, useGlobalLoader: boolean) {
  return await APIDelete(baseTicketUrl + "/detach", params, useGlobalLoader);
}

// Download PDF of Ticket Stubs
interface IGetTicketStubPdf {
  order_id?: number;
  order_line_item_id?: number;
  ticket_stub_ids?: any[];
}

export async function GetTicketStubPdf(params: IGetTicketStubPdf, useGlobalLoader: boolean) {
  return await APIGet(baseTicketUrl + "/stub/pdf", params, useGlobalLoader);
}
