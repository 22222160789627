import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Spin from "components/spin/spin";
import { ButtonNew as Button } from "components/buttonNew/index";
import "./dataTable.scss";
import Card from "components/card/Card";

/**
 * - 'clickable' className on < tr /> element enables click-styling.
 * - 'no-border' className on < td /> element removes bottom border from table cell.
 * @param {Array<HeaderType<T>>} columns header obj array to define the table header cells.
 * @param {string} title Title added to the wrapper.
 * @param {React.ReactNode} children rendered as child of < tbody />.  Map through data and display within <tr >'s.
 * @param {string} className optional styling attached to table element.
 * @param {(key: keyof T, direction: "asc" | "desc") => void} sort optional column sort functionality added to table headers.
 * @param {boolean} loading render table spinner instead of the data.
 * @param {{}} footer optionally render table footer.  enables pagination functionality on table.
 */
type DataTableProps<T> = {
  columns: Array<HeaderType<T>>;
  title?: string;
  children?: React.ReactNode;

  className?: string;
  hideHeader?: boolean;
  sort?: (key: keyof T, direction: "asc" | "desc") => void;
  loading?: boolean;
  footer?: {
    tableLimit: number;
    tableOffset: number;
    handleTableOffset: (direction: "prev" | "next") => void;
    disableNextOffset?: boolean;
  };
};

export type HeaderType<T> = {
  // required if wanting column sort
  id?: keyof T | null;
  label: string;
  // If given, takes precedence over rendering the label
  content?: React.FC | JSX.Element;
  // Specify a specific width of the table cells.  Default = "auto"
  width?: string;
};

export default function DataTable<T>(props: DataTableProps<T>): JSX.Element {
  const { columns, footer, children, loading = false, sort, hideHeader = false } = props;
  const [columnSortDirection, setColumnSortDirection] = useState<{ [x: string]: "asc" | "desc" }>({});

  //default sort on first || second column
  useEffect(() => {
    if (!sort) {
      return;
    }
    if (!columns[0].content) {
      sort(columns[0].id, "asc");
      setColumnSortDirection({ [columns[0].id as string]: "asc" });
    } else if (!columns[1]?.content && columns[1]?.id) {
      sort(columns[1].id, "asc");
      setColumnSortDirection({ [columns[1].id as string]: "asc" });
    }
  }, []);

  function handleColumnSort(colProp: any, direction: "asc" | "desc") {
    setColumnSortDirection({ [colProp]: direction });
    sort(colProp, direction);
  }

  return (
    <Card style={{ border: 0 }} title={props.title}>
      <Card.Section table>
        <table className={`data-table ${props.className}`}>
          {/* Render header label | action element. */}
          {!hideHeader ? (
            <thead className="data-thead">
              <tr>
                {columns.map((col, index) => {
                  const identifier = col.id
                    ? (col.id as string)
                    : col.label.length > 0
                    ? col.label.concat("_null")
                    : `empty_col_${index}`;
                  const colName = !col.content ? identifier : col.label.concat("_action");
                  const colProp = !col.content && col.id ? (col.id as string) : col.label; // ex. "processed_at" vs "Processed At"

                  return (
                    <th key={colName} style={{ width: col.width ?? "auto" }}>
                      <div className="th-wrap" style={{ display: "flex", gap: "6px", alignItems: "center" }}>
                        {!col.content ? <p className="table-label">{col.label}</p> : <>{col.content}</>}
                        {/* Only display sort icons if sort callback given. */}
                        {sort && (
                          <>
                            {columnSortDirection[colProp] === "asc" && (
                              <FontAwesomeIcon
                                onClick={() => handleColumnSort(colProp, "desc")}
                                icon={["fas", "arrow-down-long"]}
                                className="sort-arrow-down"
                              />
                            )}
                            {columnSortDirection[colProp] === "desc" && (
                              <FontAwesomeIcon
                                onClick={() => handleColumnSort(colProp, "asc")}
                                icon={["fas", "arrow-up-long"]}
                                className="sort-arrow-up"
                              />
                            )}
                            {!columnSortDirection[colProp] &&
                              !colName?.includes("_action") &&
                              !colName.includes("empty") &&
                              !colName.includes("_null") && (
                                // Undefined direction && Not an action && identifier is not empty or null
                                <FontAwesomeIcon
                                  onClick={() => handleColumnSort(colProp, "asc")}
                                  icon={["far", "arrow-up-arrow-down"]}
                                  className="sort-arrow-both"
                                />
                              )}
                          </>
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
          ) : null}

          {/* Render <Spin /> | children | null */}
          <tbody className={`${!footer ? "no-footer" : ""}${hideHeader ? " header-hidden" : ""}`}>
            {loading ? (
              <tr>
                <td colSpan={columns.length} className="td-spinner">
                  <Spin />
                </td>
              </tr>
            ) : (
              <>{children ? children : null}</>
            )}
          </tbody>

          {/* Enables pagination functionality of table data. */}
          {footer && (
            <tfoot>
              <tr>
                <td colSpan={columns.length > 4 ? 2 : 1}>
                  <Button
                    size="small"
                    type="secondary"
                    onClick={() => {
                      props.footer.handleTableOffset("prev");
                    }}
                    disabled={!(props.footer.tableLimit <= props.footer.tableOffset)}
                  >
                    <FontAwesomeIcon icon={"arrow-left"} />
                    &nbsp;
                    {"Previous"} {/* TODO: Translation */}
                  </Button>
                </td>

                {columns.length - 2 === 0 ? null : (
                  <td colSpan={columns.length > 4 ? columns.length - 4 : columns.length - 2} />
                )}

                <td colSpan={columns.length > 4 ? 2 : 1}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      size="small"
                      type="secondary"
                      onClick={() => {
                        props.footer.handleTableOffset("next");
                      }}
                      disabled={props.footer.disableNextOffset}
                    >
                      {"Next"} {/* TODO: Translation */}
                      &nbsp;
                      <FontAwesomeIcon icon={"arrow-right"} />
                    </Button>
                  </div>
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </Card.Section>
    </Card>
  );
}
