import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetPrinterAll } from "api/rpc/2024-04/facilityAdmin/facility/printer";

import { showError } from "redux/actions/ui";
import { TPrinter } from "redux/reducers/models/facility";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import POSPrinterStatus from "components/posPrinterStatus/POSPrinterStatus";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";

export default function Printers() {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();

  const [printers, setPrinters] = useState<TPrinter[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadPrinters(source.token);
    return () => source.cancel();
  }, []);

  async function loadPrinters(token?: CancelToken) {
    if (printers !== undefined) {
      setPrinters(undefined);
    }

    const res = await GetPrinterAll(null, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error retrieving printers.")); // TODO: Translation
    }

    setPrinters(res.status !== StatusCode.OK ? [] : res.data);
  }

  const primaryAction = {
    content: t("secure.facility.settings.printer.printers.001"),
    action: () => history.push("/admin/settings/printers/new"),
  };

  return (
    <Page
      title={t("secure.facility.settings.printer.printers.002")}
      subtitle={t("secure.facility.settings.printer.printers.003")}
      narrow
      primaryAction={primaryAction}
    >
      <POSPrinterStatus />
      <DataTable
        columns={[
          { label: t("secure.facility.settings.printer.printers.004") },
          { label: t("secure.facility.settings.printer.printers.005") },
          { label: "" }, // ip_address
        ]}
        loading={printers === undefined}
      >
        {printers?.map(printer => (
          <tr
            key={printer.id}
            className="clickable"
            onClick={() => history.push("/admin/settings/printers/" + String(printer.id))}
          >
            <td className="table-cell-text-lead">{printer.title}</td>
            <td>{printer.brand}</td>
            <td>{printer.ip_address}</td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
