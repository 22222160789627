import React, { ChangeEvent, useEffect, useMemo } from "react";
import { TFunction, useTranslation } from "react-i18next";

import { addFilter, applyDefaults, removeFilter } from "redux/actions/filters/filters";
import { ComponentKey, OrderFilterGrouping } from "redux/reducers/filters/types";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import { useFilterAccordionContext } from "components/accordion/Accordion";
import Checkbox from "components/form/checkbox/Checkbox";

import "./filterList.scss";

type FilterList = {
  /** Value provided by FilterAccordionContext takes precedent if present. */
  collection?: ComponentKey;
  defaultValues?: FinancialStatusKey[];
  condensed?: boolean;

  onChange?: (e: ChangeEvent<HTMLInputElement>, value: string) => void;
};

export type FinancialStatusKey =
  | "paid"
  | "partially_paid"
  | "refunded"
  | "partially_refunded"
  | "unpaid"
  | "voided"
  | "cancelled";

const financialStatusFilters = (t: TFunction<"translation", undefined>) => [
  {
    id: "paid",
    label: t("secure.facility.order.order_filters.005"),
  },
  {
    id: "partially_paid",
    label: t("secure.facility.order.order_filters.007"),
  },
  {
    id: "refunded",
    label: t("secure.facility.order.order_filters.008"),
  },
  {
    id: "partially_refunded",
    label: t("secure.facility.order.order_filters.021"),
  },
  {
    id: "unpaid",
    label: t("secure.facility.order.order_filters.006"),
  },
  {
    id: "voided",
    label: t("secure.facility.order.order_filters.022"),
  },
  {
    id: "cancelled",
    label: t("secure.facility.order.order_filters.023"),
  },
];

export default function FinancialStatusFilterList(props: FilterList) {
  const { t } = useTranslation();

  const filters = useAppSelector(store => store.orderPageFilters);
  const dispatch = useAppDispatch();
  const { collectionContext } = useFilterAccordionContext();

  const labelArray = useMemo(() => {
    const active = financialStatusFilters(t).filter(val =>
      filters.some(condition => condition.value.includes(val.id as FinancialStatusKey)),
    );
    return active.map(filter => filter.label);
  }, [filters]);

  // Apply default filters
  useEffect(() => {
    if (!props.defaultValues) {
      return;
    }

    if (!filters.find(group => group.label === "financial_status")) {
      const defaultActive = financialStatusFilters(t).filter(val =>
        props.defaultValues.some(condition => condition === val.id),
      );

      dispatch(
        applyDefaults<OrderFilterGrouping>({
          collectionName: collectionContext ? collectionContext : props.collection,
          key: "financial_status",
          value: props.defaultValues,
          displayLabels: defaultActive.map(filter => filter.label),
        }),
      );
    }
  }, [props.defaultValues]);

  function listItemClick(prevChecked: boolean, id: string) {
    const newFilter = financialStatusFilters(t).filter(val => val.id === id)[0];
    const newLabel = financialStatusFilters(t)
      .filter(val => labelArray.includes(val.label) || val.label === newFilter.label)
      .map(ele => ele.label);

    if (!prevChecked) {
      dispatch(
        addFilter<OrderFilterGrouping>({
          collectionName: collectionContext ? collectionContext : props.collection,
          key: "financial_status",
          value: id,
          displayLabels: newLabel,
        }),
      );
      return;
    }

    // remove filter condition if unchecked
    dispatch(
      removeFilter<OrderFilterGrouping>({
        collectionName: collectionContext ? collectionContext : props.collection,
        key: "financial_status",
        value: id,
        displayLabels: labelArray.filter(val => val !== newFilter.label),
      }),
    );
  }

  return (
    <ul className={`filter-list${props.condensed ? " condensed" : ""}`}>
      {financialStatusFilters(t).map((status, index) => {
        const checked = filters.some(condition => {
          return condition.value.includes(status.id);
        });

        return (
          <li key={index} onClick={() => listItemClick(checked, status.id)}>
            <Checkbox
              checked={checked}
              ariaChecked={checked}
              onChange={(e: ChangeEvent<HTMLInputElement>) => e.stopPropagation()}
              label={status.label}
              size="extra small"
            />
          </li>
        );
      })}
    </ul>
  );
}
