import React, { useEffect, useState } from "react";
import RightClickMenu from "components/rightClickMenu/RightClickMenu";
import { ILocation, ISegment } from "redux/reducers/models/reservations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDrag } from "react-dnd";
import "./bookedSegment.scss";
import classNames from "classnames";
import { BOOKED_SEGMENT_DROP_TYPE, MoveBookingToDropSegmentAction, useDropBookedSegment } from "./useDropBookedSegment";

interface IBookedSegmentProps {
  cellClassNames: string;
  cellContentClassNames: string;
  segment: ISegment;
  startingSegment: ISegment;
  location: ILocation;
  availableDropHours: number;
  bookedHours: number;
  draggingBookingId: number;
  setDraggingBookingId: (bookingId: number) => void;
  moveBookingToDropSegment: MoveBookingToDropSegmentAction;
  openEditBookingModal: (segment: ISegment, location: ILocation) => Promise<void>;
  updateCheckInStatus: (status: string, segment: ISegment) => Promise<void>;
  setBlockedType: (segment: ISegment, status: string) => Promise<void>;
  updateFinancialStatus: (status: string, segment: ISegment) => Promise<void>;
  viewAuditTrail: (segmentId: number) => void;
}

export interface IBookedSegmentItem {
  segment: ISegment;
  startingSegment: ISegment;
  location: ILocation;
  bookedHours: number;
}

export default function BookedSegment(props: IBookedSegmentProps) {
  const {
    cellClassNames,
    cellContentClassNames,
    segment,
    startingSegment,
    location,
    availableDropHours,
    bookedHours,
    draggingBookingId,
    setDraggingBookingId,
    moveBookingToDropSegment,
    openEditBookingModal,
    updateCheckInStatus,
    setBlockedType,
    updateFinancialStatus,
    viewAuditTrail,
  } = props;

  const useDropBookedSegmentState = useDropBookedSegment(
    segment,
    location,
    availableDropHours,
    moveBookingToDropSegment,
  );

  // Set to true before browser drag ghost image is created. isDragging is true after.
  const [dragBeganStarting, setDragBeganStarting] = useState<boolean>(false);

  const [{ isDragging }, drag] = useDrag(() => {
    const item: IBookedSegmentItem = {
      segment,
      startingSegment,
      location,
      bookedHours,
    };

    return {
      item,
      type: BOOKED_SEGMENT_DROP_TYPE,
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    };
  }, [segment, startingSegment, location, bookedHours]);

  useEffect(() => {
    if (isDragging) {
      setDraggingBookingId(segment?.booking_id);
    } else {
      setDraggingBookingId(undefined);
    }
  }, [isDragging]);

  function getBookedSegmentCellStyles() {
    const segmentInDraggedBooking = draggingBookingId === segment.booking_id;

    return {
      ...(dragBeganStarting
        ? {
            borderBottom: "none",
            borderLeft: "none",
            borderTop: "none",
            borderRight: "none",
          }
        : {}),
      ...(segmentInDraggedBooking
        ? {
            borderBottom: "none",
            borderLeft: "none",
            borderTop: "none",
            borderRight: "1px solid black",
            opacity: "0.7",
          }
        : {}),
      ...(segmentInDraggedBooking && useDropBookedSegmentState.isOver
        ? {
            boxShadow: `0px 0px 0px 1px ${useDropBookedSegmentState.canDrop ? "green" : "red"} inset`,
          }
        : {}),
    };
  }

  return (
    <>
      <td
        ref={el => useDropBookedSegmentState.drop(drag(el))}
        className={classNames(cellClassNames, "booked-segment-cell")}
        onClick={() => openEditBookingModal(segment, location)}
        style={getBookedSegmentCellStyles()}
        onDragStart={() => setDragBeganStarting(true)}
        onDragEnd={() => setDragBeganStarting(false)}
      >
        {dragBeganStarting && (
          <div className="booked-segment-cell-booked-hours-badge">{`${bookedHours} hr${
            bookedHours > 1 ? "s" : ""
          }`}</div>
        )}
        <RightClickMenu
          sections={[
            [
              {
                icon: "circle-check",
                title: "Checked In",
                action: () => updateCheckInStatus("checked", segment),
                disabled: segment.booking.check_in_status === "checked",
              },
              {
                icon: "circle",
                title: "Not Checked In",
                action: () => updateCheckInStatus("unchecked", segment),
                disabled: segment.booking.check_in_status === "unchecked",
              },
              {
                icon: "ban",
                title: "No Show",
                action: () => updateCheckInStatus("no_show", segment),
                disabled: segment.booking.check_in_status === "no_show",
              },
            ],
            [
              {
                icon: "circle-dollar",
                title: "Paid",
                action: () => updateFinancialStatus("paid", segment),
                disabled: segment.booking.order_financial_status === "paid",
              },
              {
                icon: "user-slash",
                title: "Unpaid",
                action: () => updateFinancialStatus("unpaid", segment),
                disabled: segment.booking.order_financial_status === "unpaid",
              },
            ],
            [
              {
                icon: "list",
                title: "View Segment Audit Trail",
                action: () => viewAuditTrail(segment.id),
              },
            ],
          ]}
        >
          <div className={cellContentClassNames}>
            <p>{segment?.booking?.customer?.full_name}</p>
            {!dragBeganStarting && (
              <div className="booked-segment-icons-container">
                {segment?.booking?.check_in_status === "checked" ? (
                  <FontAwesomeIcon size="1x" icon={["far", "circle-check"]} />
                ) : segment?.booking?.check_in_status === "no_show" ? (
                  <FontAwesomeIcon size="1x" icon={["far", "ban"]} />
                ) : (
                  <FontAwesomeIcon size="1x" icon={["far", "circle"]} />
                )}
                {segment?.booking?.order_financial_status === "paid" ? (
                  <FontAwesomeIcon size="1x" icon={["far", "circle-dollar"]} />
                ) : null}
                {segment?.booking?.has_notes ? <FontAwesomeIcon size="1x" icon={["far", "notes"]} /> : null}
              </div>
            )}
          </div>
        </RightClickMenu>
      </td>
      {useDropBookedSegmentState.dropConfirmationPopup}
    </>
  );
}
