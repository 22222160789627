import { APIGet } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "guest";
const baseLeagueUrl = "/" + apiVersion + "/" + adminType + "/league/scoring/round";

interface IGetRound {
  scoresheet_id: number;
  round_id: number;
  league_id: string | number;
  id: number;
  format_id: number;
}

//GET League Round
export async function GetRound(params: Partial<IGetRound>, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseLeagueUrl, params, useGlobalLoader, token);
}

// GET Hole Scores
export async function GetLeagueRoundHoleScores(params: any, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseLeagueUrl + "/hole-scores", params, useGlobalLoader, token);
}

export async function GetLeagueTeamScores(params: any, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(
    "/" + apiVersion + "/" + adminType + "/league/scoring/scoresheet/team",
    params,
    useGlobalLoader,
    token,
  );
}
