import React from "react";
import { Select } from "components/select/index";
import DraggableEditInput from "components/draggableEditInput/DraggableEditInput";
import CustomSelectEdit from "pages/secure/facility/settings/forms/customEdits/CustomSelectEdit";
import CustomInputEdit from "pages/secure/facility/settings/forms/customEdits/CustomInputEdit";
import Input from "components/form/input/Input";
import CustomCheckboxEdit from "pages/secure/facility/settings/forms/customEdits/CustomCheckboxEdit";
import Checkbox from "components/form/checkbox/Checkbox";
import CustomTextEdit from "pages/secure/facility/settings/forms/customEdits/CustomTextEdit";
import TextField from "components/form/textField/TextField";
import { ILeagueRegistrationInput } from "api/rpc/2024-04/facilityAdmin/league/registration/inputs";
import { useLeagueRegistrationContext } from "../LeagueRegistrationContext";

interface IProps {
  input: ILeagueRegistrationInput;
}

export default function LeagueRegistrationEditInput(props: IProps) {
  const { Option } = Select;

  const { input } = props;

  const leagueRegistrationContext = useLeagueRegistrationContext();

  return (
    <DraggableEditInput
      item={input}
      rows={leagueRegistrationContext.inputRowsContainer?.updatedState}
      setSelectedEditInput={leagueRegistrationContext.setSelectedInput}
    >
      {input.type === "select" && (
        <CustomSelectEdit
          label={input.label}
          helpText={input.help_text}
          array={input.values}
          required={input.required}
          saveInput={(label, values, helpText, required) =>
            leagueRegistrationContext.handleUpdateInput(input.id, label, helpText, values, required)
          }
          editModalOpen={
            leagueRegistrationContext.selectedInput.id === input.id &&
            leagueRegistrationContext.selectedInput.editModalOpen
          }
          closeEditModal={leagueRegistrationContext.closeInputEditModal}
        >
          <Select label={input.label} helpText={input.help_text}>
            {!input.values || input?.values?.length < 1 ? (
              <Option key={"empty"} value={""}></Option>
            ) : (
              <>
                {input.values.map((value: string, index: number) => (
                  <Option key={index} value={value}>
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontSize: "18px",
                      }}
                    >
                      {value}
                    </span>
                  </Option>
                ))}
              </>
            )}
          </Select>
        </CustomSelectEdit>
      )}

      {input.type === "input" && (
        <CustomInputEdit
          label={input.label}
          helpText={input.help_text}
          required={input.required}
          saveInput={(label, value, helpText, required) =>
            leagueRegistrationContext.handleUpdateInput(input.id, label, helpText, null, required)
          }
          editModalOpen={
            leagueRegistrationContext.selectedInput.id === input.id &&
            leagueRegistrationContext.selectedInput.editModalOpen
          }
          closeEditModal={leagueRegistrationContext.closeInputEditModal}
        >
          <Input id={input.id} label={input.label} helpText={input.help_text} defaultValue={input.values} readOnly />
        </CustomInputEdit>
      )}

      {input.type === "checkbox" && (
        <CustomCheckboxEdit
          label={input.label}
          helpText={input.help_text}
          required={input.required}
          options={input.values}
          saveInput={(label, helpText, required) =>
            leagueRegistrationContext.handleUpdateInput(input.id, label, helpText, null, required)
          }
          editModalOpen={
            leagueRegistrationContext.selectedInput.id === input.id &&
            leagueRegistrationContext.selectedInput.editModalOpen
          }
          closeEditModal={leagueRegistrationContext.closeInputEditModal}
        >
          <Checkbox size="medium" label={input.label} supportText={input.help_text} checked={false} readOnly />
        </CustomCheckboxEdit>
      )}

      {input.type === "text" && (
        <CustomTextEdit
          label={input.label}
          description={input.help_text}
          saveInput={(label: string, description: string) =>
            leagueRegistrationContext.handleUpdateInput(input.id, label, description, null)
          }
          editModalOpen={
            leagueRegistrationContext.selectedInput.id === input.id &&
            leagueRegistrationContext.selectedInput.editModalOpen
          }
          closeEditModal={leagueRegistrationContext.closeInputEditModal}
        >
          <TextField label={input.label} value={input.help_text} readOnly />
        </CustomTextEdit>
      )}
    </DraggableEditInput>
  );
}
