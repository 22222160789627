import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Page from "components/page/Page";
import Card from "components/card/Card";
import Input from "components/form/input/Input";
import { StatusCode } from "api/protocols";
import Toggle from "components/form/toggle/Toggle";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";

import { PostFolder } from "api/rpc/facilityAdmin/folders";
import { Select } from "components/select/index";

import { useTranslation } from "react-i18next";
import { GetRegisterGroups } from "api/rpc/2022-09/facilityAdmin/facility/register/group";

interface IFolderState {
  title: string;
  default: boolean;
  registers: Array<Record<string, any>>;
  register_group_id: number;
}

export default function FoldersNew(props: any) {
  const { Option } = Select;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [folderState, setFolderState] = useState<IFolderState>({
    title: "",
    default: false,
    registers: [],
    register_group_id: null,
  });

  useEffect(() => {
    void loadRegisters();
  }, []);

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setFolderState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleToggleChange(event: any) {
    const { id, checked } = event.target;
    setFolderState(prevState => ({ ...prevState, [id]: checked }));
  }

  async function saveNewFolder() {
    const params = {
      title: folderState.title,
      default: folderState.default,
      register_group_id: folderState.register_group_id,
    };

    const res = await PostFolder(params, true);
    if (res.status !== StatusCode.OK) {
      return;
    }

    console.log(res);
    history.push("/admin/settings/folders");
  }

  async function loadRegisters() {
    const registersRes = await GetRegisterGroups(null, true);
    if (registersRes.status !== StatusCode.OK) {
      console.log(registersRes.message);
      return;
    }
    setFolderState(prevState => ({ ...prevState, registers: registersRes.data }));
  }

  function handleRegisterSelect(register_group_id: number) {
    setFolderState(prevState => ({ ...prevState, register_group_id }));
  }

  const primaryAction = {
    content: t("secure.facility.settings.folders.folders_new.001"),
    action: saveNewFolder,
  };

  return (
    <Page
      title={t("secure.facility.settings.folders.folders_new.002")}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        { prefix: true, label: t("secure.facility.settings.folders.folders_new.003"), url: "/admin/settings/folders" },
      ]}
    >
      <Card>
        <Card.Section>
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={folderState.title}
                  label={t("secure.facility.settings.folders.folders_new.004")}
                  id="title"
                  onChange={handleInputChange}
                  placeholder={t("secure.facility.settings.folders.folders_new.005")}
                  className="mb-2"
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Select
                  label={t("secure.facility.settings.folders.folders_new.006")}
                  onChange={handleRegisterSelect}
                  defaultValue={folderState.registers?.length > 0 ? folderState.registers[0]?.id : null}
                >
                  {folderState.registers?.map((register, index) => {
                    return (
                      <Option value={register?.id} name={register?.title} key={index}>
                        <span>{register?.title}</span>
                      </Option>
                    );
                  })}
                </Select>
              </FormLayout.Group>
              <FormLayout.Group>
                <Toggle
                  label={t("secure.facility.settings.folders.folders_new.007")}
                  id="default"
                  checked={folderState.default}
                  onChange={handleToggleChange}
                />
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
      </Card>
    </Page>
  );
}
