import { APIGet, APIPost, APIPut, IAPIResponse } from "api/protocols";
import { FormInput } from "../facilityAdmin/facility/form/formInput";

const apiVersion = "2022-09";
const adminType = "guest";
const baseUrl = "/" + apiVersion + "/" + adminType + "/client/form";

interface IGetForms {
  client_id: number;
  variant_ids: number[];
  inputs: boolean;
}

export interface IForm {
  id: number;
  client_id: number;
  title: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  inputs: FormInput[];
}

interface IGetFormsRes extends IAPIResponse {
  data: Array<{
    id: number;
    variant_id: number;
    form_id: number;
    created_at: string;
    updated_at: string;
    deleted_at: string;
    form: IForm;
  }>;
}

export interface IPostFormInput {
  form_input_id: number;
  value: string | boolean;
}

interface IPostFormResponse {
  form_id: number;
  client_id: number;
  order_id: number;
  customer_id: number;
  cart_id: number;
  inputs: IPostFormInput[];
}

interface IPostFormResponseRes {
  data: {
    id: number;
    form_id: number;
    customer_id: number;
    order_id: number;
    cart_id: number;
    updated_at: string;
    created_at: string;
    inputs: IFormResponseInput[];
  };
  status: number;
}
export interface IPutFormInput {
  id: number;
  form_input_id: number;
  value: string | boolean;
}

interface IPutFormResponse {
  id: number;
  client_id: number;
  order_id?: number;
  inputs?: IPutFormInput[];
}

interface IPutFormResponseRes {
  data: {
    id: number;
    form_id: number;
    customer_id: number;
    order_id: number;
    cart_id: number;
    created_at: string;
    updated_at: string;
    deleted_at: string;
  };
  status: number;
}

interface IGetFormResponse {
  client_id: number;
  cart_id?: number;
  order_id?: number;
}

export interface IFormResponseInput {
  id: number;
  form_response_id: number;
  form_input_id: number;
  label: string;
  type: string;
  value: string | boolean;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface IFormResponse {
  id: number;
  form_id: number;
  customer_id: number;
  order_id: number;
  cart_id: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  inputs: IFormResponseInput[];
}

interface IGetFormResponseRes extends IAPIResponse {
  data: IFormResponse[];
}

export async function GetForms(params: IGetForms, useGlobalLoader: boolean): Promise<IGetFormsRes> {
  return await APIGet(baseUrl, params, useGlobalLoader);
}

export async function PostFormResponse(
  params: IPostFormResponse,
  useGlobalLoader: boolean,
): Promise<IPostFormResponseRes> {
  return await APIPost(baseUrl + "/response", params, useGlobalLoader);
}

export async function PutFormResponse(
  params: IPutFormResponse,
  useGlobalLoader: boolean,
): Promise<IPutFormResponseRes> {
  return await APIPut(baseUrl + "/response", params, useGlobalLoader);
}

export async function GetFormResponse(
  params: IGetFormResponse,
  useGlobalLoader: boolean,
): Promise<IGetFormResponseRes> {
  return await APIGet(baseUrl + "/response", params, useGlobalLoader);
}
