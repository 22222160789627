import React, { useState, useEffect } from "react";
import FolderItem from "./FolderItem";

import "./folderMenu.scss";

export interface IFolderMenuProps {
  title?: string;
  options: Array<any>;
  //Property name of the objects' title
  optionTitle: string;
  onClick: (option: any, selectedIndex: number) => void;
  defaultSelected?: number;
  iconName?: string;
}

export const FolderMenu: React.FC<IFolderMenuProps> = props => {
  const { title, options, optionTitle, onClick, defaultSelected } = props;
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);

  return (
    <ul className="ui-folder-menu">
      {options
        ?.sort((prev, next) => prev.position - next.position)
        ?.map((option: any, index: number) => {
          return (
            <FolderItem
              key={option.id}
              active={index === selected}
              onClick={() => {
                setSelected(index);
                onClick(option, index);
              }}
              icon={{ icon: ["far", "folder"] }}
              title={option[optionTitle]}
            />
          );
        })}
    </ul>
  );
};
