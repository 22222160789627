import React from "react";
import { TFunction } from "react-i18next";

import Callout from "components/callout/Callout";
import { isEmpty } from "helpers/Helpers";

export default function ProductAvailabilityWarning(props: {
  t: TFunction<"translation", undefined>;
  cannotBeSold: boolean;
  facilityAccess: boolean;
  toggleFacilityAccess: () => void;
}) {
  // if (isEmpty(props.cannotBeSold) || isEmpty(props.facilityAccess)) {
  //   return null;
  // }

  if (props.cannotBeSold) {
    return (
      <Callout
        type="error"
        title="Product cannot be sold"
        content="This product is locked and cannot be sold at your facility."
      />
    );
  }

  if (!props.facilityAccess) {
    return (
      <Callout
        type="warning"
        title={props.t("secure.facility.product.product.006")}
        content={props.t("secure.facility.product.product.007")}
        actions={{
          primary: {
            label: props.t("secure.facility.product.product.008"),
            onClick: props.toggleFacilityAccess,
          },
        }}
      />
    );
  }
  return null;
}
