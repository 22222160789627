import { APIGet, APIPost, APIPut, APIDelete, IAPIResponse } from "api/protocols";
import { CancelToken } from "axios";
import { IBookingCategory } from "redux/reducers/models/teetime";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/customer/type";

export interface IGetCustomerType {
  id?: number;
  application?: string;
  booking_category?: boolean;
}

interface IPostCustomerType {
  title?: string;
  migrate_customer_type_id?: number;
  subtitle?: string;
  configuration?: boolean;
  booking_category_id?: number;
  days_in_advance_start?: number;
  days_in_advance_end?: number;
}

interface IPutCustomerType {
  title?: string;
  subtitle?: string;
  booking_category_id?: number;
  color?: string;
}

interface IDeleteCustomerType {
  id: number;
  migrate_customer_type_id?: number;
}

export interface ICustomerTypeRes {
  status: number;
  data: CustomerTypeResStructure[];
}

export type CustomerTypeResStructure = {
  application: string;
  booking_category: IBookingCategory;
  client_id: number;
  color: string | null;
  default: number;
  default_booking_category: IBookingCategory;
  default_booking_category_id: number;
  full_title: string;
  id: number;
  subtitle: string | null;
  title: string;
};

// GET
export async function GetCustomerType(
  params: IGetCustomerType,
  useGlobalLoader: boolean,
  cancelToken?: CancelToken,
): Promise<ICustomerTypeRes> {
  return await APIGet(baseUrl, params, useGlobalLoader, cancelToken);
}

//Post new customer type
export async function PostCustomerType(
  params: IPostCustomerType,
  useGlobalLoader?: boolean,
): Promise<ICustomerTypeRes> {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

//Edit existing customer type
export async function PutCustomerType(params: IPutCustomerType, useGlobalLoader?: boolean): Promise<ICustomerTypeRes> {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

//Delete and migrate customer type
export async function DeleteCustomerType(
  params: IDeleteCustomerType,
  useGlobalLoader?: boolean,
): Promise<ICustomerTypeRes> {
  return await APIDelete(baseUrl, params, useGlobalLoader);
}
