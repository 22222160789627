import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetRegisters } from "api/rpc/facilityAdmin/facility/register/register";
import { showError } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";

import { IRegister } from "../../Admin";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";
import Page from "components/page/Page";

const URL = "/admin/settings/registers";
export default function Registers() {
  const history = useHistory();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const [registers, setRegisters] = useState<IRegister[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadRegisters(source.token);
    return () => source.cancel();
  }, []);

  async function loadRegisters(token?: CancelToken) {
    if (registers === undefined) {
      setRegisters(undefined);
    }
    const res = await GetRegisters({ extended: true }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading registers"));
    }

    setRegisters(res.status !== StatusCode.OK ? [] : res.data);
  }

  const primaryAction = {
    content: t("secure.facility.settings.register.registers.001"),
    action: () => history.push(URL + "/new"),
  };

  return (
    <Page
      title={t("secure.facility.settings.register.registers.002")}
      subtitle={t("secure.facility.settings.register.registers.003")}
      narrow
      primaryAction={primaryAction}
    >
      <DataTable
        columns={[
          { label: t("secure.facility.settings.register.registers.004"), width: "50%" },
          { label: t("secure.facility.settings.register.registers.005"), width: "50%" },
        ]}
        loading={registers === undefined}
      >
        {registers?.map((register: any, index: number) => {
          return (
            <tr key={index} onClick={() => history.push(URL + "/" + String(register.id))} className="clickable">
              <td>{register.title}</td>
              <td>{register.register_group?.title}</td>
            </tr>
          );
        })}
      </DataTable>
    </Page>
  );
}
