import React, { useEffect, useMemo, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cloneDeep, isEqualWith, range } from "lodash";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { convertTime, formatDate, capitalize, isEmpty } from "helpers/Helpers";

import { dequeue, enqueue, showError, showSuccess } from "redux/actions/ui";
import { selectDate } from "redux/actions/teesheet";
import { IBookingCategory, ITeeTime } from "redux/reducers/models/teetime";

import { GetTeeTime, ITeeTimeBookingCategoryUpdate, PutTeeTime } from "api/rpc/teeSheet/teeSheet";
import { GetBookingCategory } from "api/rpc/facilityAdmin/teesheet/bookingCategory";
import { StatusCode } from "api/protocols";

import DateSelector from "components/dateSelector/dateSelector";
import { NotificationType } from "components/notificationBar/NotificationBar";
import Page from "components/page/Page";
import Card from "components/card/Card";
import { Badge } from "components/badge/Badge";
import FormLayout from "components/form/FormLayout";
import Tabs from "components/tabs/Tabs";
import { Select } from "components/select/index";
import { ButtonNew as Button } from "components/buttonNew";
import Checkbox from "components/form/checkbox/Checkbox";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import Input from "components/form/input/Input";

import Icon from "components/icon/Icon";
import { ITournament } from "redux/reducers/models/tournament";
import { ILeague } from "redux/reducers/models/league";
import { PostTeeTime } from "api/rpc/2022-09/facilityAdmin/teesheet/teeSheet";
import Sheet from "components/sheet/Sheet";
import { IDivision } from "redux/reducers/models/facility";
import moment from "moment";
import useKeyPress from "hooks/events/useKeyPress";
import { blockedTypeOptions, divisionOptions, cartRuleOptions } from "./teeSheetEditOptions";
import axios, { CancelToken } from "axios";
import DataTable from "../customer/tabs/houseAccounts/DataTable";
import { GetLeague } from "api/rpc/2024-04/facilityAdmin/league/league";
import { GetTournament } from "api/rpc/2024-04/facilityAdmin/tournament/tournament";

interface IParamState {
  league_id: number;
  tournament_id: number;
  pre_paid_required: boolean;
  credit_card_required: boolean;
  blocked_type: string;
  cart_rule: string;
  divisions_available: number[];
  booking_category_ids: number[];
  web_booking_enabled: boolean;
}

interface IFilterState {
  tournamentSearchValue: string;
  leagueSearchValue: string;
  tournaments: Array<ITournament>;
  tournamentSearching: boolean;
  selectedTournament: ITournament;
  leagues: Array<ILeague>;
  leagueSearching: boolean;
  selectedLeague: ILeague;
  interval: number;
  showAddTeeTime: boolean;
  selectedTeeTimeId: number;
  selectAllTeetimes: boolean;
}

const intervalOptions = [7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12];

export default function TeeSheetEdit() {
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();
  const { facilityStore, teeSheetStore } = useAppSelector(store => store);

  const shiftPressed: boolean = useKeyPress(["ShiftLeft", "ShiftRight"]);

  const NONE_SELECTION = -1;
  const DEFAULT_NO_SELECTION = -2;

  const [teetimes, setTeeTimes] = useState<ITeeTime[]>(undefined);
  const [editTimes, setEditTimes] = useState<number[]>([]);
  const [tournaments, setTournaments] = useState<Array<ITournament>>([]);
  const [leagues, setLeagues] = useState<Array<ILeague>>([]);
  const [bookingCategories, setBookingCategories] = useState<IBookingCategory[]>([]);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [paramState, setParamState] = useState<IParamState>({
    league_id: DEFAULT_NO_SELECTION,
    tournament_id: DEFAULT_NO_SELECTION,
    pre_paid_required: null,
    credit_card_required: null,
    blocked_type: "none",
    cart_rule: "empty",
    divisions_available: [],
    booking_category_ids: [],
    web_booking_enabled: null,
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    tournamentSearchValue: "",
    leagueSearchValue: "",
    tournaments: [],
    tournamentSearching: false,
    selectedTournament: null,
    leagues: [],
    leagueSearching: false,
    selectedLeague: null,
    interval: 7,
    showAddTeeTime: false,
    selectedTeeTimeId: null,
    selectAllTeetimes: false,
  });

  const [courseIndex, setCourseIndex] = useState<number>(0);

  const [teeTimesBeforeChanges, setTeeTimesBeforeChanges] = useState(undefined);
  const [teeTimesLoaded, setTeeTimesLoaded] = useState<boolean>(false);

  const [currentDivisions, setCurrentDivisions] = useState<IDivision[]>(undefined);

  const [paramStateBeforeChanges, setParamStateBeforeChanges] = useState<IParamState>(undefined);

  const [bookingCategoriesLoaded, setBookingCategoriesLoaded] = useState<boolean>(false);

  const filteredTeeTimes = useMemo(() => {
    const filtered: ITeeTime[][] = [];

    currentDivisions?.forEach(division => {
      const teeTimesByDivision: ITeeTime[] = [];
      teetimes
        ?.filter(teetime => teetime.division_id === division.id)
        .forEach(teetime => {
          teeTimesByDivision.push(teetime);
        });

      filtered.push(teeTimesByDivision);
    });

    return filtered;
  }, [currentDivisions, teetimes]);

  useEffect(() => {
    if (teeSheetStore.selectedDate) {
      void loadTeetimes(teeSheetStore.selectedDate as Date);
    } else {
      dispatch(selectDate(new Date()));
    }
  }, [teeSheetStore.selectedDate]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    void loadTournaments(source.token);
    void loadLeagues(source.token);
    void loadBookingCategories(source.token);

    return () => source.cancel();
  }, []);

  useEffect(() => {
    setCurrentDivisions(facilityStore?.facility?.courses[courseIndex]?.divisions);
  }, [facilityStore, courseIndex]);

  // Search Tournaments
  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (async () => {
          if (filterState.tournamentSearchValue !== "") {
            try {
              setFilterState(prevState => ({ ...prevState, tournamentSearching: true }));
              const tournaments = await loadSearchedTournaments(filterState.tournamentSearchValue);
              if (mounted) {
                setFilterState(prevState => ({ ...prevState, tournaments: tournaments, tournamentSearching: false }));
              }
            } catch (error) {
              console.log("err", error);
            }
            return;
          } else {
            setFilterState(prevState => ({ ...prevState, tournaments: [], tournamentSearching: false }));
          }
        })();
      }, 1000);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [filterState.tournamentSearchValue]);

  // Search Leagues
  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (async () => {
          if (filterState.leagueSearchValue !== "") {
            try {
              setFilterState(prevState => ({ ...prevState, leagueSearching: true }));
              const leagues = await loadSearchedLeagues(filterState.leagueSearchValue);
              if (mounted) {
                setFilterState(prevState => ({ ...prevState, leagues: leagues, leagueSearching: false }));
              }
            } catch (error) {
              console.log("err", error);
            }
            return;
          } else {
            setFilterState(prevState => ({ ...prevState, leagues: [], leagueSearching: false }));
          }
        })();
      }, 1000);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [filterState.leagueSearchValue]);

  async function loadTournaments(token?: CancelToken) {
    try {
      const tournamentRes = await GetTournament(null, true, token);

      if (token && token.reason) {
        return;
      }
      if (tournamentRes.status === StatusCode.OK) {
        setTournaments(tournamentRes.data);
      } else {
        dispatch(showError(tournamentRes.message));
      }
    } catch (e) {
      dispatch(showError(e));
    }
  }

  async function loadSearchedTournaments(search: string) {
    const params = {
      search: search,
    };

    const tournamentRes = await GetTournament(params, false);

    if (tournamentRes.status !== StatusCode.OK) {
      dispatch(showError(tournamentRes.message));
      return [];
    }

    return tournamentRes.data;
  }

  async function loadSearchedLeagues(search: string) {
    const params = {
      search: search,
    };

    const leagueRes = await GetLeague(params, false);

    if (leagueRes.status !== StatusCode.OK) {
      dispatch(showError(leagueRes.message));

      return [];
    }

    return leagueRes.data;
  }

  async function loadLeagues(token?: CancelToken) {
    try {
      const leagueRes = await GetLeague(null, true, token);
      if (token && token.reason) {
        return;
      }
      if (leagueRes.status === StatusCode.OK) {
        setLeagues(leagueRes.data);
      } else {
        dispatch(showError(leagueRes.message));
      }
    } catch (e) {
      dispatch(showError(e));
    }
  }

  async function loadBookingCategories(token?: CancelToken) {
    try {
      const bookingCategoryRes = await GetBookingCategory(null, true, token);
      if (token && token.reason) {
        return;
      }
      if (bookingCategoryRes.status === StatusCode.OK) {
        setBookingCategories(bookingCategoryRes.data);
        setBookingCategoriesLoaded(true);
      } else {
        dispatch(showError(bookingCategoryRes.message));
      }
    } catch (e) {
      dispatch(showError(e));
    }
  }

  async function populateTeeTimesWithLeagueAndTournament(teeTimes: ITeeTime[]) {
    const usedLeagueIds: Set<number> = new Set();
    const usedTournamentIds: Set<number> = new Set();

    teeTimes.forEach(teeTime => {
      if (teeTime.league_id) {
        usedLeagueIds.add(teeTime.league_id);
      }
      if (teeTime.tournament_id) {
        usedTournamentIds.add(teeTime.tournament_id);
      }
    });

    dispatch(enqueue());

    const [usedLeaguesRes, usedTournamentsRes] = await Promise.all([
      GetLeague({ ids: Array.from(usedLeagueIds) }, false),
      GetTournament({ ids: Array.from(usedTournamentIds) }, false),
    ]);

    dispatch(dequeue());

    if (usedLeaguesRes.status !== StatusCode.OK) {
      throw new Error("Error when loading leagues");
    }

    if (usedTournamentsRes.status !== StatusCode.OK) {
      throw new Error("Error when loading tournaments");
    }

    const updatedTeeTimes: ITeeTime[] = teeTimes.map(teeTime => ({
      ...teeTime,
      league:
        teeTime?.league_id != null ? usedLeaguesRes.data.find(league => league?.id === teeTime.league_id) : null,
      tournament:
        teeTime?.tournament_id != null
          ? usedTournamentsRes.data.find(tournament => tournament?.id === teeTime.tournament_id)
          : null,
    }));

    return updatedTeeTimes;
  }

  async function loadTeetimes(date: Date) {
    const formattedDate = formatDate(date);

    const teetimesRes = await GetTeeTime({ date: formattedDate, booking_categories: true, turn_tee_time: true }, true);

    if (teetimesRes.status !== StatusCode.OK) {
      return;
    }

    try {
      const populatedTeeTimes = await populateTeeTimesWithLeagueAndTournament(teetimesRes.data);

      setTeeTimes(populatedTeeTimes);
      setTeeTimesBeforeChanges(populatedTeeTimes);
      setTeeTimesLoaded(true);
      setEditTimes([]);
    } catch (e) {
      dispatch(showError(e.message));
    }
  }

  const handleSetAllClick = () => {
    //Select all tee times for the selected division tab
    const everyTeeTimeId =
      selectedTab !== currentDivisions?.length
        ? teetimes
            ?.filter(filteredTeeTime => filteredTeeTime?.division_id === currentDivisions[selectedTab]?.id)
            ?.map(teetime => {
              return teetime?.id;
            })
        : teetimes.map(teetime => {
            return teetime?.id;
          });

    setEditTimes(everyTeeTimeId);
    setFilterState(prevState => ({ ...prevState, selectAllTeetimes: true }));
  };

  const handleClearClick = () => {
    setEditTimes([]);
    setFilterState(prevState => ({ ...prevState, selectAllTeetimes: false }));
  };

  const handleClearOptions = () => setParamState(paramStateBeforeChanges);

  const handleApplyOptions = () => {
    if (editTimes === undefined || editTimes.length === 0) {
      dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_edit.045")));
      return;
    }

    const selectedBookingCategories: IBookingCategory[] = [];
    const updatedSelectedTeetimes: ITeeTime[] = [];

    paramState.booking_category_ids.map(bookingCategoryId => {
      const selectedBookingCategory = bookingCategories.find(
        bookingCategory => bookingCategory.id === bookingCategoryId,
      );
      if (selectedBookingCategory) {
        selectedBookingCategories.push(selectedBookingCategory);
      }
    });

    editTimes.map(selectedId => {
      const selectedTeetime = teetimes?.find(teetime => teetime?.id === selectedId);

      if (selectedTeetime === undefined) {
        return;
      }

      let league_id: number = null;
      let league: ILeague = null;

      if (paramState?.league_id >= 1) {
        // Selected league id
        league_id = paramState.league_id;

        const filterLeagueMatch = filterState.leagues?.find(league => league.id === league_id);
        const leagueMatch = leagues?.find(league => league.id === league_id);

        if (filterLeagueMatch) {
          league = cloneDeep(filterLeagueMatch);
        } else if (leagueMatch) {
          league = cloneDeep(leagueMatch);
        } else {
          league_id = selectedTeetime.league_id;
          league = selectedTeetime.league;
        }
      } else if (paramState?.league_id === NONE_SELECTION) {
        // None selected
        league_id = null;
        league = null;
      } else {
        // Default no selection value or invalid
        league_id = selectedTeetime.league_id;
        league = selectedTeetime.league;
      }

      let tournament_id: number = null;
      let tournament: ITournament = null;

      if (paramState?.tournament_id >= 1) {
        // Selected tournament id
        tournament_id = paramState.tournament_id;

        const filterTournamentMatch = filterState.tournaments?.find(tournament => tournament.id === tournament_id);
        const tournamentMatch = tournaments?.find(tournament => tournament.id === tournament_id);

        if (filterTournamentMatch) {
          tournament = cloneDeep(filterTournamentMatch);
        } else if (tournamentMatch) {
          tournament = cloneDeep(tournamentMatch);
        } else {
          tournament_id = selectedTeetime.tournament_id;
          tournament = selectedTeetime.tournament;
        }
      } else if (paramState?.tournament_id === NONE_SELECTION) {
        // None selected
        tournament_id = null;
        tournament = null;
      } else {
        // Default no selection value or invalid
        tournament_id = selectedTeetime.tournament_id;
        tournament = selectedTeetime.tournament;
      }

      const updatedSelectedTeetime: ITeeTime = {
        ...selectedTeetime,
        blocked_type: paramState?.blocked_type !== "none" ? paramState?.blocked_type : selectedTeetime?.blocked_type,
        cart_rule: paramState.cart_rule !== "empty" ? paramState.cart_rule : selectedTeetime.cart_rule,
        tournament_id,
        tournament,
        league_id,
        league,
        pre_paid_required: isEmpty(paramState.pre_paid_required)
          ? selectedTeetime.pre_paid_required
          : paramState.pre_paid_required,
        credit_card_required: isEmpty(paramState.credit_card_required)
          ? selectedTeetime.credit_card_required
          : paramState.credit_card_required,
        divisions_available:
          paramState.divisions_available.length === 0
            ? selectedTeetime.divisions_available
            : paramState.divisions_available,
        booking_categories:
          paramState.booking_category_ids.length === 0 ? selectedTeetime.booking_categories : selectedBookingCategories,
        web_booking_enabled: isEmpty(paramState.web_booking_enabled)
          ? selectedTeetime.web_booking_enabled
          : paramState.web_booking_enabled,
      };

      updatedSelectedTeetimes.push(updatedSelectedTeetime);
    });

    const updatedTeetimes = teetimes?.map(originalTeetime => {
      const updatedTeetime = updatedSelectedTeetimes.find(
        updatedTeetime => updatedTeetime?.id === originalTeetime?.id,
      );
      return updatedTeetime ?? originalTeetime;
    });

    for (let i = 0; i < updatedTeetimes.length; i++) {
      if (updatedTeetimes[i].tournament_id != null && updatedTeetimes[i].league_id != null) {
        dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_edit.046")));
        return;
      }
    }

    setParamState(paramStateBeforeChanges);
    setEditTimes([]);
    setTeeTimes(prevState => updatedTeetimes ?? prevState);
    setFilterState(prevState => ({
      ...prevState,
      leagueSearchValue: "",
      tournamentSearchValue: "",
      selectedLeague: null,
      selectedTournament: null,
    }));
  };

  function handleCheckboxChange(event: any) {
    const { id, checked, value } = event.target;

    if (!checked) {
      setParamState(prevState => ({ ...prevState, [id]: null }));
    } else {
      setParamState(prevState => ({ ...prevState, [id]: value === "true" ? checked : !checked }));
    }
  }

  function handleSelectAllCheckboxChange(event: any) {
    const { id, checked, value } = event.target;

    if (!checked) {
      void handleClearClick();
    } else {
      void handleSetAllClick();
    }
  }

  function handleDropDownChange(value: string, id: string) {
    setParamState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleIntervalChange(value: number) {
    setFilterState(prevState => ({ ...prevState, interval: value }));
  }

  function handleTournamentChange(value: any, id: string, tournament: ITournament) {
    setParamState(prevState => ({ ...prevState, [id]: tournament.id }));
    setFilterState(prevState => ({ ...prevState, tournamentSearchValue: "", selectedTournament: tournament }));
  }

  function handleLeagueChange(value: any, id: string, league: ILeague) {
    setParamState(prevState => ({ ...prevState, [id]: league.id }));
    setFilterState(prevState => ({ ...prevState, leagueSearchValue: "", selectedLeague: league }));
  }

  function handleBookingCategoryChange(event: any) {
    const value = parseInt(event?.target?.value);
    if (isNaN(value)) {
      return;
    }

    if (paramState.booking_category_ids.find(bookingCategoryId => bookingCategoryId === value) === undefined) {
      setParamState(prevState => ({
        ...prevState,
        booking_category_ids: [...prevState.booking_category_ids, value],
      }));
    } else {
      const updatedBookingCategoryIds = paramState.booking_category_ids.filter(
        bookingCategoryId => bookingCategoryId !== value,
      );
      setParamState(prevState => ({ ...prevState, booking_category_ids: updatedBookingCategoryIds }));
    }
  }

  function handleDivisionsAvailableChange(event: any) {
    const value = parseInt(event?.target?.value);
    if (isNaN(value)) {
      return;
    }

    if (paramState.divisions_available.find(divisionAvailable => divisionAvailable === value) === undefined) {
      setParamState(prevState => ({
        ...prevState,
        divisions_available: [...prevState.divisions_available, value].sort((a, b) => a - b),
      }));
    } else {
      const updatedDivisionsAvailable = paramState.divisions_available.filter(
        divisionAvailable => divisionAvailable !== value,
      );
      setParamState(prevState => ({
        ...prevState,
        divisions_available: updatedDivisionsAvailable.sort((a, b) => a - b),
      }));
    }
  }

  // User should be able to select multiple with shift press
  const onSelectedTeetimeChange = (newId: number, isShiftPressed: boolean) => {
    if (newId === undefined || isNaN(newId)) {
      return;
    }

    if (!isShiftPressed) {
      if (editTimes.some(id => id === newId)) {
        const updatedSelections = editTimes
          .filter(teetimeId => {
            if (teetimeId === newId) {
              return false;
            } else {
              return true;
            }
          })
          .map(teetimetId => Number(teetimetId));

        setEditTimes(updatedSelections);
      } else {
        setEditTimes(prevState => [...prevState, newId]);
      }
    } else {
      if (!editTimes.some(id => id === newId)) {
        const selectedTeetime = teetimes?.find(teetime => teetime?.id === newId);
        const divisionTeetimes = teetimes?.filter(teetime => teetime?.division_id === selectedTeetime?.division_id);
        const selectedTeetimeDivisionIndex = divisionTeetimes?.findIndex(
          teetime => teetime?.id === selectedTeetime?.id,
        );

        if (
          selectedTeetime === undefined ||
          divisionTeetimes === undefined ||
          selectedTeetimeDivisionIndex === undefined ||
          selectedTeetimeDivisionIndex === -1
        ) {
          return;
        }

        let previousSelectedDivisionIndex = undefined;

        for (let i = selectedTeetimeDivisionIndex - 1; i >= 0 && previousSelectedDivisionIndex === undefined; i--) {
          if (editTimes?.some(id => id === divisionTeetimes[i]?.id)) {
            previousSelectedDivisionIndex = i;
          }
        }

        if (previousSelectedDivisionIndex === undefined) {
          return;
        }

        const selectedDivisionIndexes = range(previousSelectedDivisionIndex + 1, selectedTeetimeDivisionIndex + 1);

        const selectedTeetimes = selectedDivisionIndexes.map(index => {
          return divisionTeetimes?.[index]?.id;
        });

        setEditTimes(prevState => [...prevState, ...selectedTeetimes]);
      }
    }
  };

  function unsavedChangesExist() {
    if (teeTimesBeforeChanges === undefined || paramStateBeforeChanges === undefined) {
      if (teeTimesLoaded && bookingCategoriesLoaded) {
        setTeeTimesBeforeChanges(teetimes);
        setParamStateBeforeChanges(paramState);
      }
      return false;
    }

    return !isEqualWith(teetimes, teeTimesBeforeChanges);
  }

  const saveTeeTimes = async () => {
    const updatedTeetimes: ITeeTime[] = teetimes.filter((teetime, index: number) => {
      return !isEqualWith(teetime, teeTimesBeforeChanges[index]);
    });

    const updatedTeetimesWithBookingCategories: ITeeTimeBookingCategoryUpdate[] = updatedTeetimes.map(
      updatedTeetime => {
        return {
          ...updatedTeetime,
          booking_category_ids: updatedTeetime.booking_categories?.map(bookingCategory => bookingCategory.id) ?? [],
        };
      },
    );

    for (let i = 0; i < updatedTeetimesWithBookingCategories.length; i++) {
      if (
        updatedTeetimesWithBookingCategories[i].tournament_id !== null &&
        updatedTeetimesWithBookingCategories[i].league_id !== null
      ) {
        dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_edit.047")));
        return;
      }
    }

    const templateRes = await PutTeeTime({ tee_times: updatedTeetimesWithBookingCategories }, true);

    if (templateRes.status !== StatusCode.OK) {
      return;
    }

    setTeeTimesBeforeChanges(teetimes);
    setFilterState(prevState => ({
      ...prevState,
      leagueSearchValue: "",
      tournamentSearchValue: "",
      selectedLeague: null,
      selectedTournament: null,
    }));
  };

  const handleCourseChange = async (value: number, courseIndex: { course_index: number }) => {
    if (value && courseIndex?.course_index != null) {
      const formattedDate = formatDate(teeSheetStore.selectedDate as Date);

      const teetimesRes = await GetTeeTime(
        { date: formattedDate, booking_categories: true, course_id: value, turn_tee_time: true },
        true,
      );

      if (teetimesRes.status !== StatusCode.OK) {
        return;
      }

      try {
        const populatedTeeTimes = await populateTeeTimesWithLeagueAndTournament(teetimesRes.data);

        setTeeTimes(populatedTeeTimes);
        setTeeTimesBeforeChanges(populatedTeeTimes);
        setTeeTimesLoaded(true);
        setEditTimes([]);
        setCourseIndex(courseIndex?.course_index);
      } catch (e) {
        dispatch(showError(e.message));
      }
    }
  };

  function removeSelectedTournament() {
    setFilterState(prevState => ({
      ...prevState,
      selectedTournament: null,
      tournaments: [],
      tournamentSearching: false,
    }));
    setParamState(prevState => ({ ...prevState, tournament_id: DEFAULT_NO_SELECTION }));
  }

  function removeSelectedLeague() {
    setFilterState(prevState => ({
      ...prevState,
      selectedLeague: null,
      leagues: [],
      leagueSearching: false,
    }));
    setParamState(prevState => ({ ...prevState, league_id: DEFAULT_NO_SELECTION }));
  }

  function displayTournaments() {
    if (filterState?.tournaments && filterState?.tournaments?.length > 0 && filterState?.tournamentSearchValue !== "") {
      return filterState?.tournaments;
    } else {
      return tournaments;
    }
  }

  function displayLeagues() {
    if (filterState?.leagues && filterState?.leagues?.length > 0 && filterState?.leagueSearchValue !== "") {
      return filterState?.leagues;
    } else {
      return leagues;
    }
  }

  async function createTeeTime() {
    if (filterState?.selectedTeeTimeId) {
      const res = await PostTeeTime(
        { tee_time_id: filterState?.selectedTeeTimeId, interval: filterState.interval },
        true,
      );
      if (res.status !== StatusCode.OK) {
        dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_edit.048")));
        return;
      }
    }
    dispatch(showSuccess(t("secure.facility.tee_sheet.tee_sheet_edit.049")));
    setFilterState(prevState => ({ ...prevState, showAddTeeTime: false, selectedTeeTimeId: null }));
    void loadTeetimes(teeSheetStore.selectedDate as Date);
  }

  const MemoizedTeetimes: JSX.Element = useMemo(() => {
    if (teetimes === undefined || teetimes.length === 0) {
      return <div>{t("secure.facility.tee_sheet.tee_sheet_edit.033")}</div>;
    } else {
      const tabs = currentDivisions?.map((division: any) => {
        return {
          id: `${String(division.title)} - ${String(division.id)}`,
          content: division.title,
        };
      });

      const condensedView = Number(tabs?.length);

      tabs?.push({
        id: "Condensed View",
        content: t("secure.facility.tee_sheet.tee_sheet_edit.050"),
      });

      return (
        <Card>
          <Card.Section>
            {tabs && <Tabs tabs={tabs} selected={selectedTab} onSelect={index => setSelectedTab(index)} />}
          </Card.Section>
          <Card.Section table="true">
            {facilityStore.facility && (
              <div>
                {currentDivisions?.map((division: any, index: number) => {
                  const filteredTimesByDivision = teetimes.filter(
                    teetime => teetime.division_id === division.id && teetime.blocked_type !== "shotgun_disabled",
                  );
                  if (selectedTab === index) {
                    return (
                      <React.Fragment key={index}>
                        <DataTable
                          columns={[
                            {
                              label: t("secure.facility.tee_sheet.tee_sheet_edit.034"),
                              content: (
                                <div className="flex">
                                  <Checkbox
                                    id="selectAllTeetimes"
                                    size="medium"
                                    checked={filterState.selectAllTeetimes}
                                    onChange={handleSelectAllCheckboxChange}
                                    labelHidden
                                  />

                                  <span className="shotgun-template-edit-label">
                                    {"Time"}
                                    {/* TODO: Translation */}
                                  </span>
                                </div>
                              ),
                            },
                            { label: "" },
                            { label: t("secure.facility.tee_sheet.tee_sheet_edit.035") },
                            { label: t("secure.facility.tee_sheet.tee_sheet_edit.036") },
                            { label: "Requirements" }, // TODO: Translation
                            { label: t("secure.facility.tee_sheet.tee_sheet_edit.037") },
                            { label: t("secure.facility.tee_sheet.tee_sheet_edit.038") },
                            { label: t("secure.facility.tee_sheet.tee_sheet_edit.039") },
                            { label: "Web Booking" }, // TODO: Translation
                            { label: "Booked" }, // TODO: Translation
                          ]}
                        >
                          {filteredTimesByDivision?.map(teetime => {
                            const divisionsAvailableLabels: string[] = [];
                            teetime.divisions_available.map(divisionAvailable => {
                              const divisionLabel = divisionOptions(t).find(
                                divisionOption => divisionOption.value === divisionAvailable,
                              )?.label;
                              if (divisionLabel) {
                                divisionsAvailableLabels.push(divisionLabel);
                              }
                            });

                            let bookedPlayers = 0;
                            bookedPlayers = 4 - teetime.quantity_remaining;
                            const openSlots = 4 - bookedPlayers;
                            const bookedPlayersArray: any = [];

                            if (bookedPlayers > 0) {
                              for (let i = 0; i < bookedPlayers; i++) {
                                bookedPlayersArray.push("fas");
                              }
                              for (let i = 0; i < openSlots; i++) {
                                bookedPlayersArray.push("far");
                              }
                            } else {
                              for (let i = 0; i < openSlots; i++) {
                                bookedPlayersArray.push("far");
                              }
                            }

                            return (
                              <tr
                                key={teetime.id}
                                className="clickable prevent_select"
                                onClick={e => {
                                  e.stopPropagation(), onSelectedTeetimeChange(teetime.id, shiftPressed);
                                }}
                              >
                                <td>
                                  <Checkbox
                                    id={`teetime ${teetime.id}`}
                                    size="medium"
                                    checked={editTimes.some(id => id === teetime.id)}
                                    onChange={e => e.stopPropagation()}
                                    label={convertTime(teetime.start_time)}
                                  />
                                </td>

                                <td>
                                  {teetime.blocked_type === "open" ? (
                                    <Badge type="success">{capitalize(teetime.blocked_type)}</Badge>
                                  ) : null}

                                  {teetime.blocked_type === "blocked" ? (
                                    <Badge type="warning">{capitalize(teetime.blocked_type)}</Badge>
                                  ) : null}
                                  {teetime.blocked_type === "crossover" ? <Badge type="gray">{"Turn"}</Badge> : null}

                                  {teetime.blocked_type === "shotgun" ? (
                                    <Badge type="gray">{teetime.start_hole}</Badge>
                                  ) : null}
                                </td>

                                <td>
                                  <Badge type="gray">{capitalize(teetime.cart_rule)}</Badge>
                                </td>
                                <td>
                                  {teetime.tournament ? (
                                    <Badge type="gray" iconLeft icon="trophy" iconStyle="far">
                                      {teetime.tournament.name}
                                    </Badge>
                                  ) : null}
                                  {teetime.league ? (
                                    <Badge type="gray" iconLeft icon="users" iconStyle="far">
                                      {teetime.league.name}
                                    </Badge>
                                  ) : null}
                                </td>
                                <td>
                                  <div>
                                    {teetime.pre_paid_required ? (
                                      <Badge type="warning" iconLeft icon="dollar-sign" iconStyle="far">
                                        {t("secure.facility.tee_sheet.tee_sheet_edit.042")}
                                      </Badge>
                                    ) : null}

                                    {teetime.credit_card_required ? (
                                      <Badge type="error" iconLeft icon="credit-card-front" iconStyle="far">
                                        {t("secure.facility.tee_sheet.tee_sheet_edit.043")}
                                      </Badge>
                                    ) : null}
                                  </div>
                                </td>

                                <td>
                                  {divisionsAvailableLabels.length > 0 ? (
                                    <span>{divisionsAvailableLabels.reduce((prev, next) => prev + ", " + next)}</span>
                                  ) : null}
                                </td>
                                <td>
                                  {teetime.booking_categories && teetime.booking_categories.length > 0 ? (
                                    <span>
                                      {teetime.booking_categories
                                        .map(bookingCategory => bookingCategory.title)
                                        .sort()
                                        .reduce((prev, next) => prev + ", " + next)}
                                    </span>
                                  ) : null}
                                </td>
                                <td>
                                  {teetime.turn_tee_time?.division_title ? (
                                    <p>{teetime.turn_tee_time?.division_title}</p>
                                  ) : null}
                                </td>
                                <td>
                                  {teetime.web_booking_enabled ? (
                                    <span>
                                      <FontAwesomeIcon className="mr-2" icon={["far", "globe"]} />
                                    </span>
                                  ) : null}
                                </td>

                                <td style={{ width: "11%" }}>
                                  {bookedPlayersArray.map((player: any, i: number) => {
                                    return <FontAwesomeIcon className="mr-1" icon={[player, "circle"]} key={i} />;
                                  })}

                                  {/* {bookedPlayers} / 4  */}
                                </td>
                              </tr>
                            );
                          })}
                        </DataTable>
                        <Button
                          onClick={() =>
                            setFilterState(prevState => ({
                              ...prevState,
                              showAddTeeTime: true,
                              selectedTeeTimeId: filteredTimesByDivision[filteredTimesByDivision?.length - 1]?.id,
                            }))
                          }
                          type="text"
                          size="small"
                        >
                          {t("secure.facility.tee_sheet.tee_sheet_edit.054")}
                        </Button>
                      </React.Fragment>
                    );
                  }

                  if (selectedTab === condensedView && selectedTab === index + 1) {
                    return (
                      <div key={index} className="flex justify-between w-full">
                        {filteredTeeTimes.map((teetimeArray: any, index: number) => {
                          return (
                            <div key={index} className="w-full items-center">
                              <div>{teetimeArray[index]?.division_title}</div>

                              <DataTable
                                columns={[
                                  {
                                    label: t("secure.facility.tee_sheet.tee_sheet_edit.055"),
                                    content: (
                                      <div className="flex">
                                        <Checkbox
                                          id="selectAllTeetimes"
                                          size="medium"
                                          checked={filterState.selectAllTeetimes}
                                          onChange={handleSelectAllCheckboxChange}
                                          labelHidden
                                        />

                                        <span className="shotgun-template-edit-label">
                                          {"Time"}
                                          {/* TODO: Translation */}
                                        </span>
                                      </div>
                                    ),
                                  },
                                  { label: "" },
                                  { label: t("secure.facility.tee_sheet.tee_sheet_edit.039") },
                                  { label: t("secure.facility.tee_sheet.tee_sheet_edit.053") },
                                ]}
                              >
                                {teetimeArray?.map((teetime: any, i: number) => {
                                  const divisionsListLabel = currentDivisions?.find(
                                    (division: any) => division.id === teetime.turn_division_id,
                                  )?.title;

                                  const divisionsAvailableLabels: string[] = [];

                                  teetime.divisions_available.map((divisionAvailable: number) => {
                                    const divisionLabel = divisionOptions(t).find(
                                      divisionOption => divisionOption.value === divisionAvailable,
                                    )?.label;
                                    if (divisionLabel) {
                                      divisionsAvailableLabels.push(divisionLabel);
                                    }
                                  });

                                  let bookedPlayers = 0;
                                  bookedPlayers = 4 - teetime.quantity_remaining;
                                  const openSlots = 4 - bookedPlayers;
                                  const bookedPlayersArray: any = [];

                                  if (bookedPlayers > 0) {
                                    for (let i = 0; i < bookedPlayers; i++) {
                                      bookedPlayersArray.push("fas");
                                    }
                                    for (let i = 0; i < openSlots; i++) {
                                      bookedPlayersArray.push("far");
                                    }
                                  } else {
                                    for (let i = 0; i < openSlots; i++) {
                                      bookedPlayersArray.push("far");
                                    }
                                  }

                                  return (
                                    <tr
                                      key={teetime.id}
                                      className="clickable prevent_select"
                                      onClick={e => {
                                        e.stopPropagation(), onSelectedTeetimeChange(teetime.id, shiftPressed);
                                      }}
                                    >
                                      <td>
                                        <Checkbox
                                          id={`teetime ${index}`}
                                          size="medium"
                                          checked={editTimes.some(id => id === teetime.id)}
                                          onChange={e => e.stopPropagation()}
                                          label={convertTime(teetime.start_time)}
                                        />
                                        {i === teetimeArray?.length - 1 && (
                                          <Button
                                            onClick={() =>
                                              setFilterState(prevState => ({
                                                ...prevState,
                                                showAddTeeTime: true,
                                                selectedTeeTimeId: teetime?.id,
                                              }))
                                            }
                                            type="text"
                                            size="small"
                                          >
                                            + Add Tee Time
                                          </Button>
                                        )}
                                      </td>
                                      <td>
                                        {teetime.blocked_type === "open" ? (
                                          <Badge type="success">{capitalize(teetime.blocked_type)}</Badge>
                                        ) : null}

                                        {teetime.blocked_type === "blocked" ? (
                                          <Badge type="warning">{capitalize(teetime.blocked_type)}</Badge>
                                        ) : null}
                                        {teetime.blocked_type === "crossover" ? (
                                          <Badge type="gray">{capitalize(teetime.blocked_type)}</Badge>
                                        ) : null}
                                      </td>
                                      <td>
                                        {teetime.turn_division_id && divisionsListLabel ? (
                                          <span>
                                            <FontAwesomeIcon className="mr-2" icon={["far", "arrow-right"]} />
                                            {divisionsListLabel}
                                          </span>
                                        ) : null}
                                      </td>
                                      <td>
                                        {bookedPlayersArray.map((player: any, i: number) => {
                                          return (
                                            <FontAwesomeIcon
                                              style={{ fontSize: "8px", margin: "1px" }}
                                              icon={[player, "circle"]}
                                              key={i}
                                            />
                                          );
                                        })}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </DataTable>
                            </div>
                          );
                        })}
                      </div>
                    );
                  }
                })}
              </div>
            )}
          </Card.Section>
        </Card>
      );
    }
  }, [paramState.divisions_available, teetimes, editTimes, shiftPressed, selectedTab, courseIndex, currentDivisions]);

  return (
    teeTimesLoaded &&
    bookingCategoriesLoaded && (
      <div>
        <Page
          title={t("secure.facility.tee_sheet.tee_sheet_edit.009")}
          breadcrumbs={[
            { prefix: true, label: t("secure.facility.tee_sheet.tee_sheet_edit.010"), url: "/admin/teesheet" },
          ]}
          notificationBarProps={{
            isVisible: unsavedChangesExist(),
            onAction: saveTeeTimes,
            onCancel: () => setTeeTimes(teeTimesBeforeChanges),
          }}
        >
          <Card title={t("secure.facility.tee_sheet.tee_sheet_edit.012")}>
            <Card.Section>
              <FormLayout>
                <FormLayout.Group>
                  <DatePickerInput
                    startingDate={teeSheetStore.selectedDate as Date}
                    setStartingDate={(date: Date) => dispatch(selectDate(date))}
                    months={1}
                    position="left"
                  />
                  {facilityStore?.facility?.courses?.length > 1 && (
                    <Select
                      label={t("secure.facility.tee_sheet.tee_sheet_edit.013")}
                      onChange={(value: number, courseIndex: { course_index: number }) =>
                        handleCourseChange(value, courseIndex)
                      }
                      defaultValue={facilityStore?.facility?.courses[0]?.id}
                    >
                      {facilityStore?.facility?.courses?.map((course: Record<string, any>, i: number) => {
                        return (
                          <Select.Option
                            key={i}
                            extraValues={{ course_index: i }}
                            value={course.id}
                            name={course.long_name}
                          >
                            <span>{course.long_name}</span>
                          </Select.Option>
                        );
                      })}
                    </Select>
                  )}
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>
          </Card>
          <Card title={t("secure.facility.tee_sheet.tee_sheet_edit.014")}>
            <Card.Section>
              <FormLayout>
                <FormLayout.Group>
                  <div className="flex">
                    <Button onClick={handleSetAllClick}>{t("secure.facility.tee_sheet.tee_sheet_edit.015")}</Button>
                    <Button onClick={() => setEditTimes([])}>
                      {t("secure.facility.tee_sheet.tee_sheet_edit.016")}
                    </Button>
                    <Button onClick={() => setParamState(paramStateBeforeChanges)}>
                      {t("secure.facility.tee_sheet.tee_sheet_edit.017")}
                    </Button>
                    <Button type="primary" onClick={handleApplyOptions}>
                      {t("secure.facility.tee_sheet.tee_sheet_edit.018")}
                    </Button>
                  </div>
                </FormLayout.Group>

                <FormLayout.Group>
                  <Select
                    onChange={(value: string) => setParamState(prevState => ({ ...prevState, blocked_type: value }))}
                    defaultValue={paramState?.blocked_type}
                    label={t("secure.facility.tee_sheet.tee_sheet_edit.019")}
                  >
                    {blockedTypeOptions(t).map((blocked_type, index) => {
                      return (
                        <Select.Option key={index} value={blocked_type.value}>
                          {blocked_type.value !== "none" ? capitalize(blocked_type.label) : ""}
                        </Select.Option>
                      );
                    })}
                  </Select>

                  <Select
                    onChange={(value: string) => setParamState(prevState => ({ ...prevState, cart_rule: value }))}
                    defaultValue={paramState?.cart_rule}
                    label={t("secure.facility.tee_sheet.tee_sheet_edit.020")}
                  >
                    {cartRuleOptions(t).map((cartRuleOption, index) => {
                      return (
                        <Select.Option key={index} value={cartRuleOption.value}>
                          {cartRuleOption.value !== "empty" ? capitalize(cartRuleOption.label) : ""}
                        </Select.Option>
                      );
                    })}
                  </Select>

                  {filterState.selectedTournament ? (
                    <div>
                      <div>
                        <p className="event-label">{t("secure.facility.tee_sheet.tee_sheet_edit.056")}</p>
                      </div>

                      <div className="selected-container">
                        <div className="event-name">
                          <div>{filterState.selectedTournament.name}</div>

                          <div>
                            <button className="" onClick={removeSelectedTournament}>
                              <Icon style="far" icon="times" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <Select
                        showSearch
                        label={t("secure.facility.tee_sheet.tee_sheet_edit.021")}
                        onSearch={(query: string) =>
                          setFilterState(prevState => ({ ...prevState, tournamentSearchValue: query }))
                        }
                        onChange={(value: any, tournament: ITournament) =>
                          handleTournamentChange(value, "tournament_id", tournament)
                        }
                        placeholder={t("secure.facility.tee_sheet.tee_sheet_edit.057")}
                        allowClear
                        searchValue={filterState.tournamentSearchValue}
                        showDropDownOnFocus={true}
                        searching={filterState.tournamentSearching}
                      >
                        <Select.Option
                          value={NONE_SELECTION}
                          extraValues={{ name: t("secure.facility.tee_sheet.tee_sheet_edit.022"), id: NONE_SELECTION }}
                        >
                          <div className="">{t("secure.facility.tee_sheet.tee_sheet_edit.022")}</div>
                        </Select.Option>

                        {displayTournaments()
                          ?.filter(tournament => !moment(tournament.date).isBefore(moment(), "day"))
                          .map((tournament: any, index: number) => {
                            return (
                              <Select.Option
                                key={index}
                                value={tournament.id}
                                name={tournament.name}
                                extraValues={tournament}
                              >
                                <div className="flex items-center">
                                  <span>{tournament.name}</span>
                                  <span className="shotgun-tournament-date">
                                    {moment(tournament.date).format("LL")}
                                  </span>
                                </div>
                              </Select.Option>
                            );
                          })}

                        <Select.Option disabled value={DEFAULT_NO_SELECTION} key={DEFAULT_NO_SELECTION} name={""}>
                          <div>
                            <hr style={{ margin: "0px" }} />
                          </div>
                        </Select.Option>

                        {displayTournaments()
                          ?.filter(tournament => moment(tournament.date).isBefore(moment(), "day"))
                          .map((tournament: any, index: number) => {
                            return (
                              <Select.Option
                                key={index}
                                value={tournament.id}
                                name={tournament.name}
                                extraValues={tournament}
                              >
                                <div className="flex items-center">
                                  <span>{tournament.name}</span>
                                  <span className="shotgun-tournament-date">
                                    {moment(tournament.date).format("LL")}
                                  </span>
                                </div>
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </div>
                  )}

                  {filterState.selectedLeague ? (
                    <div>
                      <div>
                        <p className="event-label">{t("secure.facility.tee_sheet.tee_sheet_edit.058")}</p>
                      </div>

                      <div className="selected-container">
                        <div className="event-name">
                          <div>{filterState.selectedLeague.name}</div>

                          <div>
                            <button className="" onClick={removeSelectedLeague}>
                              <Icon style="far" icon="times" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <Select
                        showSearch
                        label={t("secure.facility.tee_sheet.tee_sheet_edit.023")}
                        onSearch={(query: string) =>
                          setFilterState(prevState => ({ ...prevState, leagueSearchValue: query }))
                        }
                        onChange={(value: any, league: ILeague) => handleLeagueChange(value, "league_id", league)}
                        placeholder={t("secure.facility.tee_sheet.tee_sheet_edit.059")}
                        allowClear
                        searchValue={filterState.leagueSearchValue}
                        showDropDownOnFocus={true}
                        searching={filterState.leagueSearching}
                      >
                        <Select.Option
                          value={NONE_SELECTION}
                          key={NONE_SELECTION}
                          name={"None"}
                          extraValues={{ name: t("secure.facility.tee_sheet.tee_sheet_edit.024"), id: NONE_SELECTION }}
                        >
                          <div>{t("secure.facility.tee_sheet.tee_sheet_edit.024")}</div>
                        </Select.Option>

                        {displayLeagues()
                          ?.filter(
                            league => league.start_date && !moment(league.start_date).isBefore(moment(), "day"),
                          )
                          ?.map((league: any, index: number) => {
                            return (
                              <Select.Option value={league?.id} key={index} extraValues={league}>
                                <div className="">
                                  <span className="">{league.name}</span>
                                  <span className="shotgun-tournament-date">
                                    {moment(league.start_date).format("YYYY")}
                                  </span>
                                </div>
                              </Select.Option>
                            );
                          })}

                        <Select.Option disabled value={DEFAULT_NO_SELECTION} key={DEFAULT_NO_SELECTION} name={""}>
                          <div>
                            <hr style={{ margin: "0px" }} />
                          </div>
                        </Select.Option>

                        {displayLeagues()
                          ?.filter(
                            league => !league.start_date || moment(league.start_date).isBefore(moment(), "day"),
                          )
                          ?.map((league: any, index: number) => {
                            return (
                              <Select.Option value={league?.id} key={index} extraValues={league}>
                                <div className="">
                                  <span className="">{league.name}</span>
                                  {league?.start_date && (
                                    <span className="shotgun-tournament-date">
                                      {moment(league.start_date).format("YYYY")}
                                    </span>
                                  )}
                                </div>
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </div>
                  )}
                </FormLayout.Group>
                <FormLayout.Group>
                  <div className="text-base font-medium text-black block">
                    <label>{t("secure.facility.tee_sheet.tee_sheet_edit.025")}</label>
                    <Checkbox
                      id="pre_paid_required"
                      size="medium"
                      value="true"
                      checked={paramState.pre_paid_required !== null && paramState.pre_paid_required}
                      onChange={handleCheckboxChange}
                      label={t("secure.facility.tee_sheet.tee_sheet_edit.026")}
                      circle
                    />
                    <Checkbox
                      id="pre_paid_required"
                      size="medium"
                      value="false"
                      checked={paramState.pre_paid_required !== null && !paramState.pre_paid_required}
                      onChange={handleCheckboxChange}
                      label={t("secure.facility.tee_sheet.tee_sheet_edit.027")}
                      circle
                    />
                  </div>
                  <div className="text-base font-medium text-black block">
                    <label>{t("secure.facility.tee_sheet.tee_sheet_edit.028")}</label>
                    <Checkbox
                      id="credit_card_required"
                      size="medium"
                      value="true"
                      checked={paramState.credit_card_required !== null && paramState.credit_card_required}
                      onChange={handleCheckboxChange}
                      label={t("secure.facility.tee_sheet.tee_sheet_edit.029")}
                      circle
                    />
                    <Checkbox
                      id="credit_card_required"
                      size="medium"
                      value="false"
                      checked={paramState.credit_card_required !== null && !paramState.credit_card_required}
                      onChange={handleCheckboxChange}
                      label={t("secure.facility.tee_sheet.tee_sheet_edit.030")}
                      circle
                    />
                  </div>
                  <div className="text-base font-medium text-black block">
                    <label>{"Web Booking Enabled"}</label>
                    <Checkbox
                      id="web_booking_enabled"
                      size="small"
                      value="true"
                      checked={paramState.web_booking_enabled !== null && paramState.web_booking_enabled}
                      onChange={handleCheckboxChange}
                      label={t("secure.facility.tee_sheet.tee_sheet_edit.060")}
                      circle
                    />
                    <Checkbox
                      id="web_booking_enabled"
                      size="small"
                      value="false"
                      checked={paramState.web_booking_enabled !== null && !paramState.web_booking_enabled}
                      onChange={handleCheckboxChange}
                      label={t("secure.facility.tee_sheet.tee_sheet_edit.061")}
                      circle
                    />
                  </div>
                  <div>
                    <label>{t("secure.facility.tee_sheet.tee_sheet_edit.031")}</label>
                    {divisionOptions(t).map((divisionOption, index: number) => {
                      return (
                        <Checkbox
                          key={index}
                          size="medium"
                          onChange={handleDivisionsAvailableChange}
                          value={divisionOption.value}
                          checked={
                            paramState.divisions_available.find(
                              divisionsAvailable => divisionsAvailable === divisionOption.value,
                            ) !== undefined
                          }
                          label={divisionOption.label}
                          circle
                        />
                      );
                    })}
                  </div>
                  <div>
                    <label>{t("secure.facility.tee_sheet.tee_sheet_edit.032")}</label>
                    {bookingCategories.map((bookingCategory: IBookingCategory, index: number) => {
                      return (
                        <Checkbox
                          key={index}
                          size="medium"
                          onChange={handleBookingCategoryChange}
                          value={bookingCategory.id}
                          checked={
                            paramState.booking_category_ids.find(
                              bookingCategoryId => bookingCategoryId === bookingCategory.id,
                            ) !== undefined
                          }
                          label={bookingCategory.title}
                          circle
                        />
                      );
                    })}
                  </div>
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>
          </Card>

          <div>{MemoizedTeetimes}</div>
        </Page>

        <Sheet
          open={filterState.showAddTeeTime}
          title={t("secure.facility.tee_sheet.tee_sheet_edit.062")}
          size="small"
          closable
          onCancel={() => setFilterState(prevState => ({ ...prevState, showAddTeeTime: false }))}
          onOk={createTeeTime}
          cancelText={t("secure.facility.tee_sheet.tee_sheet_edit.063")}
          okText={t("secure.facility.tee_sheet.tee_sheet_edit.064")}
          overflow
        >
          <Select
            defaultValue={filterState.interval}
            label={t("secure.facility.tee_sheet.tee_sheet_edit.065")}
            onChange={(value: number) => setFilterState(prevState => ({ ...prevState, interval: value }))}
          >
            {intervalOptions?.map((intervalOption: number, index: number) => {
              return (
                <Select.Option key={index} value={intervalOption}>
                  <span>{intervalOption}</span>
                </Select.Option>
              );
            })}
          </Select>
        </Sheet>
      </div>
    )
  );
}
