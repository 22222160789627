import { APIGet, APIPost, APIPut, APIDelete, StatusCode, IAPIResponse } from "api/protocols";
import { CancelToken } from "axios";
import { IGateway, IKind, IPaymentMethod, IProcessingType, ISource } from "redux/reducers/models/transaction";

const apiVersion = "2022-09";
const adminType = "facility-admin";

const baseCustomerUrl = "/" + apiVersion + "/" + adminType + "/client";

interface IGetCustomerCredit {
  customer_id?: number;
  facility_id?: number;
}

interface ICustomerCreditRes {
  status: number;
  message?: string;
  data: any;
}

interface IPostCreditBook {
  title?: string;
  customer_id?: number;
}

interface IGetCreditTransactionsParams {
  credit_book_id: number;
  customer_id?: number;
  id?: number;
  facility_id?: number;
}

interface IGetCreditTransactions extends IAPIResponse {
  data: Array<ICreditTransactionRes>;
}

export interface ICreditTransactionRes {
  id: number;
  credit_book_id: number;
  facility_id: number;
  client_id: number;
  customer_id: number;
  amount: number;
  currency: string;
  kind: string;
  parent_id: number;
  order_id: number;
  order_number: string;
  order_name: string;
  processed_at_local: string;
  note: string;
}
export async function GetCustomerCredit(
  params: IGetCustomerCredit,
  useGlobalLoader?: boolean,
  cancelToken?: CancelToken,
): Promise<ICustomerCreditRes> {
  return await APIGet(baseCustomerUrl + "/credit-book", params, useGlobalLoader, cancelToken);
}

export async function PostCustomerCreditBook(params: IPostCreditBook, useGlobalLoader: true) {
  return APIPost(baseCustomerUrl + "/credit-book", params, useGlobalLoader);
}

export async function GetCreditBookTransactions(
  params: IGetCreditTransactionsParams,
  useGlobalLoader: true,
): Promise<IGetCreditTransactions> {
  return APIGet(baseCustomerUrl + "/credit-book/transaction", params, useGlobalLoader);
}
