import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { IDepartments } from "redux/reducers/models/product";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/facility/department";

//GET Departments
export async function GetDepartments(useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<IDepartments[]>(baseUrl + "?extended=true", null, useGlobalLoader, token);
}

//POST Departments
export async function PostDepartments(params: IDepartments, useGlobalLoader?: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

//PUT Departments
export async function PutDepartments(params: IDepartments, useGlobalLoader?: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

//DELETE Departments
export async function DeleteDepartments(params: IDepartments, useGlobalLoader?: boolean) {
  return await APIDelete(baseUrl, params, useGlobalLoader);
}
