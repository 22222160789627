import React, { useEffect, useState, useCallback } from "react";
import { useHistory, Link } from "react-router-dom";
import { useParams } from "react-router";

import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import { ButtonNew as Button } from "components/buttonNew";
import ResourceList from "components/resourceList/ResourceList";
import ResourceItem from "components/resourceList/ResourceItem";
import TextStyle from "components/text/textStyle/TextStyle";
import Stack from "components/stack/Stack";
import Checkbox from "components/form/checkbox/Checkbox";
import { GetLeague } from "api/rpc/clientAdmin/league/league";

export default function League(props: any) {
  const history = useHistory();
  const { leagueId }: any = useParams();
  console.log(leagueId);
  const [state, setState] = useState({
    league: null,
  });

  useEffect(() => {
    void loadLeague();
  }, []);

  async function loadLeague() {
    const params = {
      id: leagueId,
    };

    console.log(params);

    const leagueRes = await GetLeague(params, true);
    console.log(leagueRes);
    console.log("test");
    if (leagueRes.status === StatusCode.OK) {
      setState(prevState => ({
        ...prevState,
        league: leagueRes.data[0],
      }));
    }
  }

  return (
    <Page title={state.league?.name} narrow>
      <Card>Testing League</Card>
    </Page>
  );
}
