export function searchHistoryCreate(groupKey: string) {
  return (dispatch: any) => {
    dispatch({
      type: "history.create",
      payload: {
        groupKey: groupKey,
        value: "",
      },
    });
  };
}

export function searchHistoryClear(groupKey: string) {
  return (dispatch: any) => {
    dispatch({
      type: "history.clear",
      payload: {
        groupKey: groupKey,
        value: "",
      },
    });
  };
}

export function searchHistoryAddItem(groupKey: string, searchValue: string) {
  return (dispatch: any) => {
    dispatch({
      type: "history.add",
      payload: {
        groupKey: groupKey,
        value: searchValue,
      },
    });
  };
}

export function searchHistoryRemoveItem(groupKey: string, searchValue: string) {
  return (dispatch: any) => {
    dispatch({
      type: "history.remove",
      payload: {
        groupKey: groupKey,
        value: searchValue,
      },
    });
  };
}

export function searchHistoryUpdateSearch(groupKey: string, searchValue: string) {
  return (dispatch: any) => {
    dispatch({
      type: "history.update",
      payload: {
        groupKey: groupKey,
        value: searchValue,
      },
    });
  };
}

export interface ISearchHistoryActions {
  searchHistoryCreate: typeof searchHistoryCreate;
  searchHistoryClear: typeof searchHistoryClear;
  searchHistoryAddItem: typeof searchHistoryAddItem;
  searchHistoryRemoveItem: typeof searchHistoryRemoveItem;
  searchHistoryUpdateSearch: typeof searchHistoryUpdateSearch;
}
