import React, { useEffect, useRef, useState } from "react";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetGuestFacilityStatus } from "api/rpc/2024-04/guest/facility";
import { DeleteFacilityFavourite, PostFacilityFavourite } from "api/rpc/2024-04/customer/facility";

import { showError, showSuccess } from "redux/actions/ui";
import { clientPortalUpdate } from "redux/actions/clientPortal";
import { TFacilityStatus } from "redux/reducers/models/facility";
import { sortFacilities } from "redux/reducers/clientPortal";

import { formatDate, sortObj } from "helpers/Helpers";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import useModal from "hooks/modals/useModal";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { MOBILE_WIDTH } from "helpers/ScreenSizes";

import { ClientPortalNavigation } from "../ClientPortalNavigation";
import ClientFacilityDropdown from "./ClientFacilityDropdown";
import FacilityStatusInfo from "./FacilityStatusInfo";
import CalendarGridDay from "components/calendar/CalendarGridDay";
import Calendar from "components/calendar/Calendar";
import { Badge } from "components/badge/Badge";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

import "./clientPortalCalendar.scss";

export default function ClientPortalCalendar() {
  const dispatch = useAppDispatch();
  const clientPortalStore = useAppSelector(store => store.clientPortalStore);

  const [calendarDay, setCalendarDay] = useState(new Date());
  const [facilityStatuses, setFacilityStatuses] = useState<TFacilityStatus[]>([]);
  const [statusLoadTrigger, setStatusLoadTrigger] = useState(true);

  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= MOBILE_WIDTH;

  const dayRefs = useRef<HTMLDivElement[]>([]);

  const {
    state: statusModal,
    updateModal: updateStatusModal,
    closeModal: closeStatusModal,
  } = useModal({
    activeDate: null as string,
    ref: null as HTMLDivElement,
    statusData: [] as TFacilityStatus[],
    type: null as "updates" | "status",
  });

  // Reload when selected facility changes
  useEffect(() => {
    const source = axios.CancelToken.source();
    if (clientPortalStore.selectedFacility && statusLoadTrigger) {
      void loadGuestFacilityStatus(clientPortalStore.selectedFacility?.id, calendarDay.getMonth(), source.token);
    }
    return () => source.cancel();
  }, [clientPortalStore.selectedFacility, statusLoadTrigger, calendarDay]);

  async function loadGuestFacilityStatus(facilityId: number, month: number, token?: CancelToken) {
    if (facilityStatuses !== undefined) {
      setFacilityStatuses(undefined);
    }
    const res = await GetGuestFacilityStatus(
      { facility_id: facilityId, month: month + 1 },
      token ? false : true,
      token,
    );
    if (token && token.reason) {
      return;
    }

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading facility details.")); // TODO: Translation
    }

    setFacilityStatuses(res.status !== StatusCode.OK ? [] : res.data);
    if (statusLoadTrigger) {
      setStatusLoadTrigger(false);
    }
  }

  async function handleFavouriteChange(type: "remove" | "add") {
    if (type === "add") {
      const res = await PostFacilityFavourite({ facility_id: clientPortalStore.selectedFacility?.id }, true);
      if (res.status !== StatusCode.OK) {
        dispatch(showError("Error adding course to favourites."));
        return;
      }
    }

    if (type === "remove") {
      const res = await DeleteFacilityFavourite({ facility_id: clientPortalStore.selectedFacility?.id }, true);
      if (res.status !== StatusCode.OK) {
        dispatch(showError("Error removing course from favourites.")); // TODO: Translation
        return;
      }
    }

    dispatch(showSuccess("Successfully updated course favourites.")); // TODO: Translation
    updateCourseFavouritesList();
  }

  function handleFacilityChange(id: number) {
    const foundFacility = clientPortalStore.client?.facilities.find(fac => fac.id === id);
    if (foundFacility) {
      dispatch(clientPortalUpdate({ selectedFacility: foundFacility }));
      setStatusLoadTrigger(true);
    }
  }

  function handleDateChange(date: Date) {
    if (date.getMonth() !== calendarDay.getMonth() || date.getFullYear() !== calendarDay.getFullYear()) {
      setStatusLoadTrigger(true);
    }

    setCalendarDay(date);
  }

  /** Sort the facilities list shown. Favourites first */
  function updateCourseFavouritesList() {
    const updatedFacilities = [...clientPortalStore.client?.facilities];
    const index = updatedFacilities.findIndex(fac => fac.id === clientPortalStore.selectedFacility?.id);

    if (index !== -1) {
      updatedFacilities[index] = { ...updatedFacilities[index], favourite: !updatedFacilities[index].favourite };
      const sorted = sortFacilities([...updatedFacilities]);
      const client = { ...clientPortalStore.client, facilities: sorted };
      dispatch(clientPortalUpdate({ client, selectedFacility: updatedFacilities[index] }));
    } else {
      dispatch(showError("Error updating course favourites list.")); // TODO: Translation
    }
  }

  function handleCalendarCellClick(data: TFacilityStatus[], dateOfMonth: number) {
    updateStatusModal({
      isOpen: true,
      activeDate: data[0].date,
      ref: dayRefs.current[dateOfMonth],
      statusData: sortObj<TFacilityStatus>(data, "label"),
      type: "status",
    });
  }

  return (
    <div>
      <ClientPortalNavigation showNavOptions />
      <div style={{ borderBottom: "1px solid #eaecf0" /* $gray200 */ }}>
        <div style={{ margin: "0 10%", padding: "2.5% 0" }}>
          <ClientFacilityDropdown
            selectedFacility={clientPortalStore?.selectedFacility}
            facilityList={clientPortalStore.client?.facilities}
            onChange={handleFacilityChange}
            handleFavourite={clientPortalStore.user ? type => handleFavouriteChange(type) : undefined}
          />
        </div>
      </div>
      <div style={{ margin: "0 5%", paddingBottom: "5%", paddingTop: "2%" }}>
        <Calendar
          selectedDate={calendarDay}
          setSelectedDate={date => handleDateChange(date)}
          loading={facilityStatuses === undefined}
          renderGridItem={(dateOfMonth: number, formattedDate: string) => {
            const data = facilityStatuses.filter(val => val.date === formattedDate);

            return (
              <CalendarGridDay
                key={formattedDate}
                dayOfMonth={dateOfMonth}
                dayOfMonthClick={() => (isMobile ? handleCalendarCellClick(data, dateOfMonth) : undefined)}
                gridClick={e => setCalendarDay(new Date(formattedDate.replace(/-/g, "/")))}
                selected={formatDate(new Date(formattedDate.replace(/-/g, "/"))) === formatDate(calendarDay)}
                today={formatDate(new Date(formattedDate.replace(/-/g, "/"))) === formatDate(new Date())}
                ref={el => (dayRefs.current[dateOfMonth] = el)}
                mobileView={isMobile}
              >
                {data.length > 0 && (
                  <div
                    className={`calendar-badge-grouping${isMobile ? " mobile" : ""}${
                      statusModal.isOpen && statusModal.activeDate === formattedDate && statusModal.type === "status"
                        ? " active"
                        : ""
                    }`}
                    onClick={e => (isMobile ? handleCalendarCellClick(data, dateOfMonth) : undefined)}
                  >
                    <Badge
                      className={`status-badge event${
                        statusModal.isOpen && statusModal.activeDate === formattedDate && statusModal.type === "status"
                          ? " active"
                          : ""
                      }`}
                      size="small"
                      onClick={e => (!isMobile ? handleCalendarCellClick(data, dateOfMonth) : undefined)}
                    >
                      {isMobile ? "" : "Daily Updates" /* TODO: Translation */}
                    </Badge>
                  </div>
                )}
              </CalendarGridDay>
            );
          }}
          // renderAgendaItem={i => {
          //   return (
          //     <div key={i}></div>
          //   )
          // }}
          // renderWeekItem={i => {
          //   return (
          //     <div key={i}></div>
          //   )
          // }}
        />
      </div>

      <FacilityStatusInfo
        isOpen={statusModal.isOpen}
        close={closeStatusModal}
        dateString={statusModal.activeDate}
        gridRefElement={!isMobile ? statusModal.ref : undefined}
        iFrame={false}
      >
        {statusModal.statusData
          .filter(val => val.label.toLowerCase().includes("note"))
          .map(status => (
            <div key={status.id} className="status-note">
              <p className="note-label">Note</p> {/* TODO: Translation */}
              <p className="note-value">{status.value}</p>
            </div>
          ))}
        <DataTable
          columns={[
            { label: "", width: "50%" },
            { label: "", width: "50%" },
          ]}
          hideHeader
          className="client-portal-calendar"
        >
          {statusModal.statusData
            .filter(val => !val.label.toLowerCase().includes("note"))
            .map((status, index) => (
              <tr key={status.id} className="status-popup-table-row">
                <td style={index === 0 ? { borderTop: "none" } : undefined}>{status.label}</td>
                <td style={index === 0 ? { borderTop: "none" } : undefined}>{status.value}</td>
              </tr>
            ))}
        </DataTable>
      </FacilityStatusInfo>
    </div>
  );
}
