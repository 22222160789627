import { APIGet } from "api/protocols";
import { CancelToken } from "axios";

const adminType = "guest";
const apiVersion = "2024-04";
const baseLeagueUrl = "/" + apiVersion + "/" + adminType + "/league";

interface IGetLeagueScoresheets {
  league_id: string | number;
  facility_id: number;
  latest?: boolean;
}

interface IGetLeagueTeamScores {
  league_id: string | number;
  facility_id: number;
  scoresheet_id: number;
}

interface IGetLeagueAnnouncements {
  league_id: string | number;
  limit?: number;
  token?: CancelToken;
}

export async function GetLeagueScoresheets(
  params: IGetLeagueScoresheets,
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseLeagueUrl + "/scoring/scoresheet", params, useGlobalLoader, token);
}

export async function GetLeagueTeamScores(params: IGetLeagueTeamScores, useGlobalLoader: boolean) {
  return await APIGet(baseLeagueUrl + "/scoring/scoresheet/team", params, useGlobalLoader);
}

export async function GetLeagueFlights(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseLeagueUrl + "/scoring/flight", params, useGlobalLoader);
}

export async function GetLeagueAnnouncements(params: IGetLeagueAnnouncements, useGlobalLoader: boolean) {
  return await APIGet(baseLeagueUrl + "/announcement", params, useGlobalLoader);
}
