import { ReactNode } from "react";
import { createPortal } from "react-dom";

type PortalProps = {
  children: ReactNode;
  /** Value determining if NODE is mounted in portal-root. Defaults to TRUE if not included. */
  isMounted?: boolean;
  /** ID of the parent element.  Portal contents will become a child of this element. */
  parentId?: string;
};

/**
 *  Render children outside root DOM.
 *      - Prevents style re-calculations after a component mounts.
 *      - Used across APP for modals and popups.
 */
export default function Portal({ children, isMounted = true, parentId = "portal-root" }: PortalProps) {
  const mount = document.getElementById(parentId);

  if (isMounted && mount) {
    return createPortal(children, mount);
  }
  return null;
}
