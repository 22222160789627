import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Page from "components/page/Page";
import Card from "components/card/Card";
import Input from "components/form/input/Input";
import { StatusCode } from "api/protocols";
import { PostModifierGroup } from "api/rpc/2024-04/masterAdmin/product/modifierGroups";

import { useTranslation, Trans } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { showError, showSuccess } from "redux/actions/ui";

interface IModifierState {
  title: string;
}

export default function ModifierGroupNew(props: any) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { masterFacilityStore } = useAppSelector(store => store);
  const dispatch = useAppDispatch();
  const [modifierGroupState, setModifierGroupState] = useState<IModifierState>({
    title: "",
  });

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setModifierGroupState(prevState => ({ ...prevState, [id]: value }));
  }

  async function saveNewModifierGroup() {
    const res = await PostModifierGroup(
      {
        title: modifierGroupState.title,
        facility_id: masterFacilityStore.facility?.id,
      },
      true,
    );
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error creating modifier group"));
      return;
    }

    dispatch(showError("Modifier group created succesfully"));
    history.push("/admin/facility/settings/modifier-group");
  }

  const primaryAction = {
    content: t("secure.facility.settings.modifier_group.modifier_group_new.001"),
    action: saveNewModifierGroup,
    disabled: !masterFacilityStore.facility,
  };

  return (
    <Page
      title={t("secure.facility.settings.modifier_group.modifier_group_new.002")}
      subtitle={masterFacilityStore.facility ? masterFacilityStore.facility.long_name : "No Facility Selected"}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.modifier_group.modifier_group_new.003"),
          url: "/admin/facility/settings/modifier-group",
        },
      ]}
    >
      {masterFacilityStore.facility && (
        <Card>
          <Card.Section>
            <Input
              value={modifierGroupState.title}
              label={t("secure.facility.settings.modifier_group.modifier_group_new.004")}
              id="title"
              onChange={handleInputChange}
              placeholder={t("secure.facility.settings.modifier_group.modifier_group_new.005")}
            />
          </Card.Section>
        </Card>
      )}
    </Page>
  );
}
