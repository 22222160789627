import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDrop } from "react-dnd";
import { IProductCardItem } from "../productCard/ProductCard";
import { ProductCardsItemTypes } from "../ProductCards";
import "./productCardSlot.scss";

interface IProductCardSlotProps {
  position: number;
  isPositionInASelectedRow: (position: number) => boolean;
  selectEmptyCard: (event: any, position: number) => void;
  onChangeProductPosition: (id: number, position: number) => void;
}

export default function ProductCardSlot(props: IProductCardSlotProps) {
  const { position, isPositionInASelectedRow, selectEmptyCard, onChangeProductPosition } = props;

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ProductCardsItemTypes.PRODUCT_CARD,
      drop: (item: IProductCardItem, monitor) => {
        onChangeProductPosition(item.id, position);
      },
      collect: monitor => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [],
  );

  return (
    <div
      ref={drop}
      className="product-card-container"
      style={{
        ...(isOver ? { border: "1px solid lightgreen", borderRadius: "3px" } : {}),
      }}
    >
      <div
        style={{ ...(isPositionInASelectedRow(position) ? { backgroundColor: "blue" } : {}) }}
        onClick={e => selectEmptyCard(e, position)}
        className="product-card-slot"
      >
        <FontAwesomeIcon className="product-card-slot-add-product-icon" icon={["far", "plus"]} />
      </div>
    </div>
  );
}
