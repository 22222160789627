import React, { useState, useEffect } from "react";
import axios, { CancelToken } from "axios";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { StatusCode } from "api/protocols";
import { GetPaymentOptions } from "api/rpc/2024-04/facilityAdmin/facility/paymentOption";

import { showError } from "redux/actions/ui";
import { IPaymentOption } from "redux/reducers/models/transaction";
import { valueToString } from "helpers/Helpers";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import { Badge } from "components/badge/Badge";
import Page from "components/page/Page";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";

import "./facility.scss";

export default function FacilityPaymentOptions() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const authStore = useAppSelector(store => store.authStore);
  const dispatch = useAppDispatch();
  const [paymentOptions, setPaymentOptions] = useState<IPaymentOption[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadPaymentOptions(source.token);
    return () => source.cancel();
  }, []);

  async function loadPaymentOptions(token?: CancelToken) {
    if (paymentOptions !== undefined) {
      setPaymentOptions(undefined);
    }

    const res = await GetPaymentOptions(null, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading payment options."));
    }

    setPaymentOptions(res.status !== StatusCode.OK ? [] : res.data);
  }

  function sortByActive(prev: IPaymentOption, next: IPaymentOption) {
    if (prev.active === next.active) {
      return 0;
    } else {
      if (prev.active) {
        return -1;
      } else {
        return 1;
      }
    }
  }

  const primaryAction = {
    content: t("secure.facility.settings.facility.facility_payment_options.001"),
    action: () => history.push("/admin/settings/facility-payment-options/new"),
  };

  return (
    <Page
      title={t("secure.facility.settings.facility.facility_payment_options.002")}
      primaryAction={authStore?.user?.permissions?.payment_options_create ? primaryAction : null}
      narrow
    >
      <DataTable
        columns={[
          { label: t("secure.facility.settings.facility.facility_payment_options.003") },
          { label: t("secure.facility.settings.facility.facility_payment_options.004") },
          { label: "" },
          { label: t("secure.facility.settings.facility.facility_payment_options.006") },
        ]}
        loading={paymentOptions === undefined}
      >
        {paymentOptions?.sort(sortByActive).map((paymentOption, index: number) => {
          return (
            <tr
              key={index}
              className={`${paymentOption.active ? "clickable" : ""}`}
              onClick={
                paymentOption.active
                  ? () => history.push("/admin/settings/facility-payment-options/" + String(paymentOption.id))
                  : null
              }
            >
              <td>{paymentOption.title}</td>
              <td>{valueToString(paymentOption.processing_type, "capitalize")}</td>
              <td>
                <Badge type={paymentOption.active ? "success" : "warning"}>
                  {paymentOption.active ? "Active" : "Inactive"}
                </Badge>
              </td>
              <td>{valueToString(paymentOption.type, "capitalize")}</td>
            </tr>
          );
        })}
      </DataTable>
    </Page>
  );
}
