import { TFunction } from "react-i18next";
import { IOptions } from "../../Admin";

export default function leagueOptions(t: TFunction<"translation", undefined>, leagueId: number): IOptions[] {
  if (isNaN(leagueId)) {
    return [];
  } else {
    return [
      {
        label: t("secure.facility.league.league_menu.001"),
        url: `/admin/league/${leagueId}/home`,
      },
      {
        label: t("secure.facility.league.league_menu.013"),
        url: `/admin/league/${leagueId}/players`,
      },
      {
        label: t("secure.facility.league.league_menu.014"),
        url: `/admin/league/${leagueId}/announcements`,
      },
      {
        label: t("secure.facility.league.league_menu.003"),
        url: `/admin/league/${leagueId}/scores`,
        subOptions: [
          {
            label: t("secure.facility.league.league_menu.015"),
            url: `/admin/league/${leagueId}/rounds`,
          },
          {
            label: t("secure.facility.league.league_menu.020"),
            url: `/admin/league/${leagueId}/divisions`,
          },
          {
            label: t("secure.facility.league.league_menu.010"),
            url: `/admin/league/${leagueId}/scoring/leaderboards`,
          },
          {
            label: t("secure.facility.league.league_menu.016"),
            url: `/admin/league/${leagueId}/scoring/statistics`,
          },
          {
            label: "TV Views", // TODO: Translation
            url: `/admin/league/${leagueId}/scoring/tv-views`,
          },
        ],
      },

      {
        label: t("secure.facility.league.league_menu.005"),
        url: `/admin/league/${leagueId}/league-settings`,
        subOptions: [
          {
            label: t("secure.facility.league.league_menu.017"),
            url: `/admin/league/${leagueId}/league-settings/general`,
          },
          {
            label: t("secure.facility.league.league_menu.018"),
            url: `/admin/league/${leagueId}/league-settings/registration`,
          },
          {
            label: t("secure.facility.league.league_menu.019"),
            url: `/admin/league/${leagueId}/league-settings/organizers`,
          },
          {
            label: "Format Templates",
            url: `/admin/league/${leagueId}/league-settings/format-templates`,
          },
          {
            label: t("secure.facility.league.league_menu.021"),
            url: `/admin/league/${leagueId}/league-settings/teetimes`,
          },
          {
            label: t("secure.facility.league.league_menu.022"),
            url: `/admin/league/${leagueId}/prizeAccounts`,
          },
        ],
      },
    ];
  }
}
