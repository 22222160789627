import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IFacility } from "redux/reducers/models/facility";
import useOutsideAlerter from "hooks/useOutsideAlerter/useOutsideAlerter";

import Input from "components/form/input/Input";
import FavouritesIcon from "./FavouritesIcon";

import "./clientFacilityDropdown.scss";

type TClientFacilityDropdown = {
  selectedFacility?: Partial<IFacility>;
  onChange: (id: string | number) => void;
  facilityList?: IFacility[];
  handleFavourite?: (changeType: "remove" | "add") => void;
};

export default function ClientFacilityDropdown(props: TClientFacilityDropdown) {
  const { selectedFacility, onChange, facilityList, handleFavourite } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [search, setSearch] = useState("");

  const [onHover, setOnHover] = useState<boolean>(false);
  const [scrollText, setScrollText] = useState<boolean>(false);

  const scrollContainerRef = useRef<HTMLDivElement>();
  const pRef = useRef<HTMLParagraphElement>();

  const dropdownRef = useRef<HTMLDivElement>();

  useOutsideAlerter(dropdownRef, () => {
    setDropdownOpen(false);
  });

  useEffect(() => {
    if (pRef.current?.offsetWidth > scrollContainerRef.current?.offsetWidth && onHover) {
      setScrollText(true);
    } else {
      setScrollText(false);
    }
  }, [onHover]);

  function handleClick(e: React.MouseEvent<HTMLLIElement>, facility: IFacility) {
    e.stopPropagation();
    onChange(facility.id);
    setDropdownOpen(false);
  }

  function filterFacility(facility: IFacility, search: string) {
    if (search.length === 0) {
      return true;
    }

    // search long_name || city
    if (
      facility.long_name.toLowerCase().includes(search.toLowerCase()) ||
      facility.city.toLowerCase().includes(search.toLowerCase())
    ) {
      return true;
    }

    return false;
  }

  return (
    <div
      ref={dropdownRef}
      className={`client-portal-facility-dropdown${selectedFacility?.logo_source ? "" : " no-logo"}${
        facilityList === undefined || facilityList?.length === 1 ? " sole" : ""
      }`}
    >
      {selectedFacility ? (
        <div
          ref={scrollContainerRef}
          className="client-portal-selected-facility"
          onClick={() =>
            facilityList !== undefined && facilityList.length > 1 ? setDropdownOpen(!dropdownOpen) : null
          }
          onMouseEnter={() => setOnHover(true)}
          onMouseLeave={() => setOnHover(false)}
          role="button"
        >
          {selectedFacility?.logo_source && (
            <div style={{ backgroundColor: "#fffff", zIndex: "2" }}>
              <img className="client-portal-dropdown-course-logo" src={selectedFacility.logo_source} />
            </div>
          )}
          <div>
            <p className="client-portal-dropdown-course_name">{selectedFacility?.long_name}</p>
            <p
              ref={pRef}
              className={`client-portal-dropdown-secondary_info ${scrollText ? "scroll-text" : ""}`}
            >{`${selectedFacility?.address_line_1}, ${selectedFacility?.city}, ${selectedFacility?.province_name}, ${selectedFacility?.postal}`}</p>
            {selectedFacility?.address_line_2 && (
              <p className="client-portal-dropdown-secondary_info">{selectedFacility.address_line_2}</p>
            )}
          </div>
        </div>
      ) : (
        <div
          ref={scrollContainerRef}
          className="client-portal-selected-facility"
          onClick={() =>
            facilityList !== undefined && facilityList.length > 1 ? setDropdownOpen(!dropdownOpen) : null
          }
          onMouseEnter={() => setOnHover(true)}
          onMouseLeave={() => setOnHover(false)}
        >
          <div>
            <p className="client-portal-dropdown-course_name">No Facility</p>
            <p ref={pRef} className={`client-portal-dropdown-secondary_info ${scrollText ? "scroll-text" : ""}`}>
              Please select a facility to get started. {/* TODO: Translation */}
            </p>
          </div>
        </div>
      )}

      {facilityList && facilityList.length > 1 ? (
        <FontAwesomeIcon
          icon={["far", dropdownOpen ? "chevron-up" : "chevron-down"]}
          className="custom-dropdown-chevron"
          onClick={() => setDropdownOpen(!dropdownOpen)}
        />
      ) : null}

      {handleFavourite && selectedFacility ? (
        <FavouritesIcon
          isFavourite={selectedFacility?.favourite}
          onFavouriteChange={() => props.handleFavourite(selectedFacility?.favourite ? "remove" : "add")}
          className="client-portal-favourites-icon"
          animate
        />
      ) : null}

      <div className={`client-portal-select-container${dropdownOpen ? " open" : ""}`}>
        <div className="client-portal-dropdown-option_search-bar">
          <Input
            id="search"
            labelHidden
            value={search}
            onChange={e => setSearch(e.target.value)}
            onSearchClear={() => setSearch("")}
            placeholder="Search courses..." // TODO: Translation
          />
        </div>
        <ul>
          {facilityList
            ?.filter(facility => filterFacility(facility, search))
            ?.map(facility => (
              <li key={facility.id} onClick={e => handleClick(e, facility)} className="client-portal-dropdown-option">
                <span className="client-portal-dropdown-container">
                  <img className="option-facility-logo" src={facility?.logo_source} />
                  <p className="option-facility-name">{facility.long_name}</p>
                  <p className="option-facility-address">{facility?.city}</p>
                  {facility?.favourite && <FavouritesIcon isFavourite={true} />}
                </span>

                <span>
                  {facility.id === selectedFacility.id ? (
                    <FontAwesomeIcon className="custom-dropdown-option-check" icon={["far", "check"]} />
                  ) : null}
                </span>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}
