import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ReactDOM from "react-dom";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { DeleteDashboard, GetDashboard, PutDashboard } from "api/rpc/clientAdmin/client/dashboard";

import { showError, showSuccess } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";
import useModal from "hooks/modals/useModal";
import { unsavedChangesExist } from "helpers/Helpers";

import Popup from "components/popup/Popup";
import Page from "components/page/Page";
import Card from "components/card/Card";
import Input from "components/form/input/Input";

interface IDashboardState {
  title: string;
  originalTitle: string;
}

export default function DashboardsEdit() {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const dispatch = useAppDispatch();

  const [state, setState] = useState<IDashboardState>({
    title: "",
    originalTitle: "",
  });
  const [beforeChangesState, setBeforeChangesState] = useState<IDashboardState>({
    title: "",
    originalTitle: "",
  });

  const { state: deleteModal, updateModal, closeModal } = useModal();

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadDashboard(id, source.token);
    return () => source.cancel();
  }, [id]);

  async function loadDashboard(dashboardId: string | number, token?: CancelToken) {
    const res = await GetDashboard({ id: Number(dashboardId) }, true, token);
    if (token && token.reason) {
      return;
    }

    if (res.status !== StatusCode.OK) {
      dispatch(showError(res.data?.message));
      history.push("/admin/dashboard/settings");
      return;
    }
    if (res.data?.length > 0) {
      ReactDOM.unstable_batchedUpdates(() => {
        setState(prevState => ({ ...prevState, title: res.data[0]?.title, originalTitle: res.data[0]?.title }));
        setBeforeChangesState(prev => ({ ...prev, title: res.data[0]?.title, originalTitle: res.data[0]?.title }));
      });
    } else {
      dispatch(showError("Dashboard not found")); // TODO: Translation
      history.push("/admin/dashboard/settings");
      return;
    }
  }

  async function updateDashboard() {
    if (!state.title) {
      dispatch(showError("Title required")); // TODO: Translation
      return;
    }
    const res = await PutDashboard({ id: Number(id), title: state.title }, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError(res.data?.message));
      return;
    }
    dispatch(showSuccess(res?.message));
    history.push("/admin/dashboard/settings");
  }

  async function deleteDashboard() {
    const res = await DeleteDashboard({ id: Number(id) }, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError(res.data?.message));
      return;
    }

    dispatch(showSuccess(res.data?.message));
    history.push("/admin/dashboard/settings");
  }

  const primaryAction = {
    content: "Delete", // TODO: Translation
    action: () => updateModal({ isOpen: true }),
  };

  return (
    <Page
      title={"Edit Dashboard:".concat(` ${state.originalTitle ?? ""}`)} // TODO: Translation
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        { prefix: true, label: "Dashboard Settings", /* TODO: Translation */ url: "/admin/dashboard/settings" },
      ]}
      notificationBarProps={{
        isVisible: unsavedChangesExist(state, beforeChangesState),
        onAction: updateDashboard,
        onCancel: () => setState(beforeChangesState),
      }}
    >
      <Card>
        <Card.Section>
          <Input
            value={state.title}
            label="Title" // TODO: Translation
            id="title"
            onChange={e => setState(prevState => ({ ...prevState, title: e.target.value }))}
            placeholder="Title" // TODO: Translation
            className="mb-2"
          />
        </Card.Section>
      </Card>

      <Popup
        open={deleteModal.isOpen}
        type="warning"
        title="Delete Confirmation" // TODO: Translation
        description="Are you sure you want to delete this dashboard?" // TODO: Translation
        okText="Delete" // TODO: Translation
        onOk={deleteDashboard}
        cancelText="Close" // TODO: Translation
        onCancel={closeModal}
      />
    </Page>
  );
}
