import { APIGet, APIPost, APIPut, APIDelete, StatusCode } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2021-01";
const adminType = "client-admin";
const baseRainCheckUrl = "/" + apiVersion + "/" + adminType + "/tee-sheet/rain-check";

export interface IPutCalculateRainCheck {
  order_id: number;
  booking_participant_id: number;
  issue_on_green_fee: boolean;
  issue_on_power_cart: boolean;
  amount: number;
  percent: number;
}

interface IRedeemRainCheck {
  cart_id: number;
  rain_check_code: string;
}

export async function PutCalculateRainCheck(params: IPutCalculateRainCheck, useGlobalLoader: boolean) {
  return await APIPut(baseRainCheckUrl + "/calculate", params, useGlobalLoader);
}

export async function PostIssueRainCheck(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseRainCheckUrl + "/issue", params, useGlobalLoader);
}

export async function PostRedeemRainCheck(params: IRedeemRainCheck, useGlobalLoader: boolean) {
  return await APIPost(baseRainCheckUrl + "/redeem", params, useGlobalLoader);
}

export async function GetRainCheckReceipt(params: { code: number }, useGlobalLoader: boolean) {
  return await APIGet(baseRainCheckUrl + "/receipt", params, useGlobalLoader);
}

export type TRainCheckGet = {
  id?: number;
  facility_id?: number;
  code?: string; // Search value
};
export async function GetRainChecks(params: TRainCheckGet, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseRainCheckUrl, params, useGlobalLoader, token);
}
