import React, { useEffect, useState, useCallback } from "react";
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useAppDispatch } from "hooks/redux";

import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import Checkbox from "components/form/checkbox/Checkbox";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import { Select, Select as SelectNew } from "components/select/";

import "pages/secure/facility/product/Product.scss";
import { List, isEqualWith, isNull } from "lodash";
import ReactDOM from "react-dom";
import { NotificationType } from "components/notificationBar/NotificationBar";
import {
  GetMembership,
  PutMembership,
  UploadMembershipImage,
  RemoveMembershipImage,
} from "api/rpc/2024-04/clientAdmin/membership/membership";
import { GetCustomerType } from "api/rpc/clientAdmin/customerType";
import { formatDate } from "helpers/Helpers";
import CustomerImage from "elements/customer/CustomerImage";
import { showError, showSuccess } from "redux/actions/ui";

interface IMembershipState {
  id: number;
  title: string;
  subtitle: string;
  expiry: string;
  customer_type_id: number | null;
  membership_type: string;
  membership_image: string;
}

interface IImageState {
  membership_logo_image: string;
  membership_card_image: string;
}

export default function Single(props: any) {
  const { Option } = SelectNew;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { membershipId }: any = useParams();
  const dispatch = useAppDispatch();

  const [membershipStateBeforeChanges, setMembershipStateBeforeChanges] = useState<IMembershipState>(undefined);
  const [membershipLoaded, setMembershipLoaded] = useState<boolean>(false);
  const [inventoryLevelLoaded, setInventoryLevelLoaded] = useState<boolean>(false);

  const [renderFlag, setRenderFlag] = useState({
    membershipLoaded: false,
    inventoryLoaded: false,
    hasExpiry: false,
    prevMembershipState: undefined,
  });

  const [membershipState, setMembershipState] = useState<IMembershipState>({
    id: null,
    title: "",
    subtitle: "",
    expiry: "",
    customer_type_id: null,
    membership_type: "",
    membership_image: "",
  });

  const [optionState, setOptionState] = useState({
    customerTypes: [],
  });

  const [filterState, setFilterState] = useState({
    customerTypeSearch: "",
  });

  const [dateState, setDateState] = useState({
    selectedDate: new Date(),
  });

  const [imageState, setImageState] = useState<IImageState>({
    membership_logo_image: "",
    membership_card_image: "",
  });

  useEffect(() => {
    void loadMembership();
  }, [membershipId]);

  useEffect(() => {
    void loadCustomerTypes();
  }, [membershipState.membership_type]);

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setMembershipState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleCheckboxChange(event: any) {
    const { id, checked } = event.target;

    // separate expiry logic to handle date
    if (id === "hasExpiry") {
      setRenderFlag({ ...renderFlag, hasExpiry: checked });

      if (checked && renderFlag.prevMembershipState.expiry === null) {
        setDateState({ ...dateState, selectedDate: new Date() });
        setMembershipState({ ...membershipState, expiry: formatDate(new Date()) });
        return;
      }

      if (checked && renderFlag.prevMembershipState.expiry !== null) {
        const notOffsetDate = new Date((renderFlag.prevMembershipState.expiry as string) + "EST");

        setDateState({ ...dateState, selectedDate: notOffsetDate });
        setMembershipState({ ...membershipState, expiry: renderFlag.prevMembershipState.expiry });
        return;
      }

      if (!checked) {
        setMembershipState({ ...membershipState, expiry: null });
        setDateState({ ...dateState, selectedDate: new Date() }); //"forgets" any date changes
        return;
      }
    }

    setMembershipState(prevState => ({ ...prevState, [id]: checked }));
  }

  function handleDateSelector(selectedDate: Date) {
    setMembershipState({ ...membershipState, expiry: formatDate(selectedDate) });
    setDateState({ ...dateState, selectedDate: selectedDate });
  }

  async function loadCustomerTypes() {
    const customerTypeRes = await GetCustomerType(
      { application: membershipState.membership_type === "membership" ? "green_fee" : "power_cart" },
      true,
    );

    if (customerTypeRes.status !== StatusCode.OK) {
      return;
    }

    const defaultCustomerType = customerTypeRes?.data?.find(
      (customerType: { id: number }) => customerType.id === membershipState.customer_type_id,
    );

    ReactDOM.unstable_batchedUpdates(() => {
      setOptionState(prev => ({
        ...prev,
        customerTypes: customerTypeRes.data,
      }));
      setFilterState({ customerTypeSearch: defaultCustomerType?.full_title ?? "" });
    });
    setFilterState({ customerTypeSearch: defaultCustomerType?.full_title ?? "" });
  }

  async function loadMembership() {
    const membershipRes = await GetMembership({ id: membershipId }, true);

    const membership = membershipRes.data[0];

    //handle any expiry value
    if (membership.expiry !== null) {
      const notOffsetDate = new Date(membership.expiry as string);
      setDateState({
        ...dateState,
        selectedDate: notOffsetDate,
      });
    }

    ReactDOM.unstable_batchedUpdates(() => {
      setMembershipState(prev => ({
        ...prev,
        id: membership.id,
        title: membership.title,
        subtitle: membership.subtitle,
        expiry: membership.expiry,
        customer_type_id: membership.customer_type_id,
        membership_type: membership.membership_type,
      }));

      setImageState({
        ...imageState,
        membership_logo_image: membership.logo_image_source,
        membership_card_image: membership.card_image_source,
      });

      setMembershipLoaded(true);
    });

    setRenderFlag({ ...renderFlag, membershipLoaded: true, hasExpiry: membership.expiry ? true : false });
  }

  async function saveMembership() {
    const membershipRes = await PutMembership(membershipState, true);

    if (membershipRes.status !== StatusCode.OK) {
      return;
    }

    setMembershipStateBeforeChanges(membershipState);
    setRenderFlag({ ...renderFlag, prevMembershipState: membershipState });
    return;
  }

  function unsavedChangesExist() {
    const { prevMembershipState, membershipLoaded } = renderFlag;
    if (prevMembershipState === undefined) {
      if (membershipLoaded) {
        setRenderFlag({ ...renderFlag, prevMembershipState: membershipState });
      }
      return false;
    }

    return !isEqualWith(prevMembershipState, membershipState, (originalValue, newValue) => {
      if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
        return true;
      }
    });
  }

  function cancelUnsavedChanges() {
    setMembershipState(renderFlag.prevMembershipState);

    //only necessary with expiry possibly NULL
    const prevExpiryVal: string = renderFlag.prevMembershipState.expiry;
    if (prevExpiryVal === null) {
      setRenderFlag({ ...renderFlag, hasExpiry: false });
    } else {
      //"reset" expiry on page
      setRenderFlag({ ...renderFlag, hasExpiry: true });

      const notOffsetDate = new Date(prevExpiryVal);
      setDateState({ ...dateState, selectedDate: notOffsetDate });
    }
  }

  async function saveMembershipLogoImage(imageFile: File) {
    const formData = new FormData();

    formData.append("image", imageFile);
    formData.append("id", membershipState.id.toString());
    formData.append("type", "logo");

    const imageRes = await UploadMembershipImage(formData, true);
    if (imageRes.status !== StatusCode.OK) {
      return;
    }

    setImageState({ ...imageState, membership_logo_image: imageRes.data.logo_image_source });
  }

  async function saveMembershipCardImage(imageFile: File) {
    const formData = new FormData();

    formData.append("image", imageFile);
    formData.append("id", membershipState.id.toString());
    formData.append("type", "card");

    const imageRes = await UploadMembershipImage(formData, true);
    if (imageRes.status !== StatusCode.OK) {
      return;
    }

    setImageState({ ...imageState, membership_card_image: imageRes.data.card_image_source });
  }

  async function deleteMembershipImage(imageType: "logo" | "card") {
    const deleteRes = await RemoveMembershipImage(
      {
        id: membershipState.id,
        type: imageType,
      },
      true,
    );

    if (deleteRes.status !== StatusCode.OK) {
      dispatch(showError("Error deleting membership image")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Membership image deleted successfully")); // TODO: Translation
    setImageState({
      ...imageState,
      membership_card_image: deleteRes.data.card_image_source,
      membership_logo_image: deleteRes.data.logo_image_source,
    });
  }

  return (
    <Page
      title={membershipState.title}
      breadcrumbs={[{ label: "Back To Memberships", url: "/admin/membership" }]} //translation required
      narrow
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: saveMembership,
        onCancel: cancelUnsavedChanges,
      }}
    >
      <Card title={t("secure.facility.product.membership.002")}>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Input
                value={membershipState.title}
                label={t("secure.facility.product.membership.003")}
                id="title"
                onChange={handleInputChange}
                placeholder={t("secure.facility.product.membership.004")}
              />
              <Input
                value={membershipState.subtitle}
                label={t("secure.facility.product.membership.005")}
                id="subtitle"
                onChange={handleInputChange}
                placeholder={t("secure.facility.product.membership.006")}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <Select
                label={t("secure.facility.product.membership.007")}
                placeholder={t("secure.facility.product.membership.008")}
                showSearch
                className={`flex justify-center align-center w-full h-10 position-relative z-20 text-black font-medium appearance-none border-none focus:outline-none placeholder-gray-200`}
                onSearch={(query: string) => setFilterState({ ...filterState, customerTypeSearch: query })}
                onChange={(value: number) => setMembershipState({ ...membershipState, customer_type_id: value })}
                allowClear
                searchValue={filterState.customerTypeSearch}
                showDropDownOnFocus={true}
                hideDropdown={true}
              >
                {optionState.customerTypes
                  .filter(custType =>
                    String(custType.full_title).toLowerCase().includes(filterState.customerTypeSearch.toLowerCase()),
                  )
                  .map((type: any, i: number) => {
                    return (
                      <div key={i}>
                        <div>
                          <Option key={i} value={type.id} name={type.full_title}>
                            {type?.full_title}
                          </Option>
                        </div>
                      </div>
                    );
                  })}
              </Select>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  height: "100%",
                  minHeight: "70px",
                }}
              >
                {/* translations needed */}
                <Checkbox
                  id="hasExpiry"
                  size="medium"
                  value={renderFlag.hasExpiry}
                  checked={renderFlag.hasExpiry}
                  onChange={handleCheckboxChange}
                  label={"Expires"} //translation required
                />
              </div>

              {renderFlag.hasExpiry && (
                <div id="membership-date-selector" style={{ marginLeft: "15px" }}>
                  <span>Expires On</span> {/* translation required */}
                  <DatePickerInput
                    months={1}
                    position={"center"}
                    startingDate={dateState.selectedDate}
                    setStartingDate={handleDateSelector}
                  />
                </div>
              )}
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>

      <Card>
        <Card.Section
          title="Logo" // TODO: Translation
          subtitle="Upload a membership logo that will be visible on membership pages for the customer" // TODO: Translation
        >
          <div className="stats_bar-picture">
            <CustomerImage
              imageSource={imageState.membership_logo_image}
              saveProfileImage={imageFile => saveMembershipLogoImage(imageFile)}
              deleteProfileImage={() => deleteMembershipImage("logo")}
            />
          </div>
        </Card.Section>
        <Card.Section
          title="Card Image" // TODO: Translation
          subtitle="Upload a card image that will be visible to the customer when viewing their digital membership card" // TODO: Translation
        >
          <div className="stats_bar-picture">
            <CustomerImage
              imageSource={imageState.membership_card_image}
              saveProfileImage={imageFile => saveMembershipCardImage(imageFile)}
              deleteProfileImage={() => deleteMembershipImage("card")}
            />
          </div>
        </Card.Section>
      </Card>
    </Page>
  );
}
