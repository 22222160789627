import React, { useEffect, useState } from "react";
import classNames from "classnames";
import "components/innerLayout/innerLayout.scss";

import TextContainer from "components/text/textContainer/TextContainer";
import Heading from "components/text/heading/Heading";

const Section = (props: any) => (
  <div
    className={classNames("ui-layout-section", {
      "ui-layout-section_secondary": props.secondary,
      "ui-layout-section_half": props.half,
      "ui-layout-section_third": props.third,
    })}
  >
    {props.children}
  </div>
);

const AnnotedSection = (props: any) => (
  <div className="ui-layout-section-annotated">
    <div className="ui-layout-section-annotated_wrapper">
      <div className="ui-layout-section-annotated_description">
        <p className="text-xl text-semibold">{props.title}</p>
        <p className="text-md text-regular text-gray-500">{props.description}</p>
      </div>
      <div className="ui-layout-section-annotated_content">
        <div>{props.children}</div>
      </div>
    </div>
  </div>
);

const InnerLayout = (props: any) => <div className="ui-layout">{props.children}</div>;

InnerLayout.Section = Section;
InnerLayout.AnnotedSection = AnnotedSection;
export default InnerLayout;
