import { APIGet, APIPost, APIPut, APIDelete, APIUrl, AdminType } from "api/protocols";
import { CancelToken } from "axios";
import { TProductType } from "redux/reducers/models/product";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/product";

export type TPostProductType = { title: string };
export type TPutProductType = { id: number; title: string };

// GET Product types
export async function GetProductTypes(params: { id?: number }, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<TProductType[]>(APIUrl(AdminType.FacilityAdmin, "/product/type"), params, useGlobalLoader, token);
}
export async function PostProductType(params: TPostProductType, useGlobalLoader: boolean) {
  return await APIPost(APIUrl(AdminType.FacilityAdmin, "/product/type"), params, useGlobalLoader);
}

export async function PutProductType(params: TPutProductType, useGlobalLoader: boolean) {
  return await APIPut(APIUrl(AdminType.FacilityAdmin, "/product/type"), params, useGlobalLoader);
}
export async function DeleteProductType(params: { id: number }, useGlobalLoader: boolean) {
  return await APIDelete(APIUrl(AdminType.FacilityAdmin, "/product/type"), params, useGlobalLoader);
}
