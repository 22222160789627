import React, { MouseEvent, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ButtonNew } from "components/buttonNew";
import Popup from "components/popup/Popup";
import Portal from "elements/Portal";

import "./note.scss";

type TNoteBase = {
  authorName: string;
  authorImg?: any;
  content: string;
  dateString: string;
  limitWidth?: boolean;
  onRemove?: (e: MouseEvent<SVGSVGElement, globalThis.MouseEvent>) => void;
};

type TNote = TNoteBase & {
  quickNote?: false;
  authorEmail?: never;
  onEdit?: (e: MouseEvent<HTMLDivElement>) => void;
  title?: string;
};

type TQuickNoteProps = TNoteBase & {
  quickNote?: true;
  authorEmail?: string;
  onEdit?: never;
  title?: never;
};

// quickNote prop determines different optional props of the component
export type TNoteProps = TNote | TQuickNoteProps;

export default function Note(props: TNoteProps) {
  const [removeModal, setRemoveModal] = useState({
    open: false,
    event: null as MouseEvent<SVGSVGElement, globalThis.MouseEvent>,
  });

  useEffect(() => {
    if (removeModal.open) {
      setRemoveModal({ open: false, event: null });
    }
  }, [props.onRemove]);

  const { quickNote = false } = props;

  function removeNote() {
    props.onRemove(removeModal.event);
    setRemoveModal({ open: false, event: null });
  }

  return (
    <>
      {!quickNote ? (
        <div className="ui-note">
          <div className="ui-note-content">
            <div className="ui-note-top">
              {props.title && <p className="title">{props.title}</p>}
              <p className="date">{props.dateString}</p>
            </div>
            <p className="ui-note-description">{props.content}</p>
            <div className="ui-note-bottom">
              <FontAwesomeIcon icon={["far", "user-alt"]} size="lg" />
              &nbsp;<p>{props.authorName}</p>
              {/* TODO: Build out user img display instead of always displaying icon */}
              {/* {!props.authorImg ? (
                              <>
                                  <FontAwesomeIcon icon="user-alt" size="lg" /> &nbsp; <p>{props.author?.full_name}</p>
                              </>
                          ) : null} */}
            </div>
          </div>
          <div className="ui-note-action">
            {props.onEdit && (
              <ButtonNew type="text" onClick={props.onEdit}>
                {"Edit"}
              </ButtonNew>
            )}
            <span style={{ minWidth: "28px" }}>
              {props.onRemove && (
                <FontAwesomeIcon icon="x" color="grey" onClick={e => setRemoveModal({ open: true, event: e })} />
              )}
            </span>
          </div>
        </div>
      ) : (
        <div className="ui-quick-note">
          <div className="ui-note-img">
            <FontAwesomeIcon icon={["far", "user-alt"]} size="lg" />
            {/* TODO: Build out user img display instead of always displaying icon */}
            {/* {!props.authorImg ? (
                      <>
                          <FontAwesomeIcon icon={["far", "user-alt"]} size="lg" />
                      </>
                  ) : null} */}
          </div>
          <div className="ui-note-description">
            <p className={props.limitWidth ? "ui-note-description-limit_width" : "ui-note-description"}>
              {props.authorName} <span className="tint">posted</span> {props.content}
            </p>
            {props.authorEmail && <p className="email">{props.authorEmail}</p>}
          </div>

          <div className="ui-note-action">
            {props.onRemove && (
              <FontAwesomeIcon icon="x" color="grey" onClick={e => setRemoveModal({ open: true, event: e })} />
            )}
          </div>
        </div>
      )}

      <Portal isMounted={removeModal.open}>
        {/* DELETE NOTE POPUP */}
        <Popup
          type="warning"
          open={removeModal.open}
          title={"Delete Note"}
          description={"You are about to permanently remove this note.  Are you sure?"}
          closable
          onCancel={() => setRemoveModal({ open: false, event: null })}
          onOk={() => removeNote()}
          cancelText={"Cancel"}
          okText={"Delete"}
        />
      </Portal>
    </>
  );
}
