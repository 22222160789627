import React, { useEffect, useState, ChangeEvent } from "react";
import ReactDOM from "react-dom";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import moment from "moment";

import { displayCurrency, roundNumber, displayPercent, capitalize, isEmpty } from "helpers/Helpers";

// API
import { StatusCode } from "api/protocols";
import {
  GetProductTypes,
  AddProductToVariant,
  GetTaxLines,
  AttachModifierGroup,
  PutProductAttach,
  PutArchiveProduct,
} from "api/rpc/clientAdmin/product/products";

import {
  PutProduct,
  PostProductOption,
  PostVariant,
  DuplicateProduct,
} from "api/rpc/2022-09/clientAdmin/product/product";

import { AddTicketToVariant, DeleteTicketFromVariant, GetTickets } from "api/rpc/clientAdmin/ticket";
import {
  GetAccountingReference,
  GetAccounts,
  GetDepartments,
  GetSalesChannels,
  UpdateSalesChannel,
} from "api/rpc/clientAdmin/facility/facility";
import { GetVendor } from "api/rpc/clientAdmin/vendor";

// Components
import Page from "components/page/Page";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import Checkbox from "components/form/checkbox/Checkbox";
import { Select } from "components/select/";
import Sheet from "components/sheet/Sheet";
import { forEach, isEqual, isEqualWith, isNull, sortedIndex } from "lodash";
import Toggle from "components/form/toggle/Toggle";
import ProductDateSelector from "components/productDateSelector/productDateSelector";
import { ButtonNew as Button } from "components/buttonNew";
import Popup from "components/popup/Popup";
import Tabs from "components/tabs/Tabs";
import TextEditor from "components/textEditor/textEditor";
import TimePick from "components/timePick/TimePick";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

// Redux
import { IAuthState } from "redux/reducers/auth";
import { IUIState } from "redux/reducers/ui";
import { IUIActions, showError } from "redux/actions/ui";

// CSS
import "pages/secure/facility/product/Product.scss";
import "./product.scss";
import UpdateProductAsComboModal from "pages/secure/facility/product/modals/UpdateProductAsComboModal";
import Portal from "elements/Portal";
import AttachProductAsComboModal from "pages/secure/facility/product/modals/AttachProductAsComboModal";
import {
  GetInventoryLevels,
  GetProduct,
  UpdateComboProduct,
  UpdateInventoryLevels,
} from "api/rpc/2024-04/clientAdmin/product/product";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IVariant, TComboVariants } from "redux/reducers/models/product";
import { Badge } from "components/badge/Badge";
import useModal from "hooks/modals/useModal";
import { useDispatch } from "react-redux";
import product from "containers/client/product";
import TagInputSelect from "components/tagInputSelect/TagInputSelect";
import { IFacility, TTaxLines } from "redux/reducers/models/facility";

interface IProductState {
  id: number;
  title: string;
  subtitle: string;
  description: string;
  internal_description: string;
  price: number;
  price_locked: boolean;
  cannot_be_sold: boolean;
  original_price: number;
  cost: number;
  taxable: boolean;
  type: string;
  customer_required: boolean;
  shipping_required: boolean;
  fulfillment_required: boolean;
  vendor_id: number;
  vendor_title: string;
  department: string;
  category: string;
  subcategory: string;
  account_type: string;
  editing: boolean;
  variant_count: number;
  variants: IVariant[];
  sku: string;
  barcode: string;
  tracked: boolean;
  inventory_levels: IInventoryLevel[];
  department_id: number;
  category_id: number;
  subcategory_id: number;
  accounting_reference_id: number;
  membership_type: string;
  meta: any;
  account_id: number;
  online_store: boolean;
  tax_line_ids: number[];
  facilityAccess: boolean;

  available_on: string;
  unavailable_on: string;
  available_on_toggle: boolean;
  unavailable_on_toggle: boolean;
  available_on_time: string;
  unavailable_on_time: string;
  available_on_date: string;
  unavailable_on_date: string;
  isLoaded: boolean;
  track_inventory: boolean;
}

interface IComboProductState {
  comboProductsActive?: boolean;
  currentAddedProducts?: TComboVariants[];
  allProducts?: any;
  productsToUpdate?: Map<any, any>;
  updateComboProductsOpen: boolean;
  selectedComboVariant: TComboVariants;
  product: any;
}

interface IProduct {
  id?: number;
  title?: string;
  value?: any;
  included?: string;
  quantity?: number;
  sales_channels?: any[];
}

interface IAddTicketState {
  addTicketActive?: boolean;
  currentAddedTickets?: ITicket[];
  allTickets?: ITicket[];
  ticketsToUpdate?: Map<any, any>;
}

interface ITicket {
  id?: number;
  title?: string;
  value?: any;
  included?: string;
  quantity?: number;
}

interface IInventoryLevel {
  id: number;
  facility_id: number;
  cannot_be_sold: boolean;
  cost_override: boolean;
  facility: IFacility;
  has_access: boolean;
  inventory_item: IInventoryItem;
  inventory_item_id: number;
  price_locked: boolean;
  price_override: number;
  quantity_available: boolean;
  quantity_receiving: boolean;
  tax_lines?: ITaxLines[];
}

interface IInventoryItem {
  barcode: string;
  id: number;
  inventory_level: Omit<IInventoryLevel, "facility" | "inventory_item" | "tax_lines">;
  tracked: boolean;
  variant_id: number;
}

interface ITaxLines {
  client_id: number;
  id: number;
  name: string;
  percentage: number;
  province_id: number;
  tax: number;
  type: string;
}

interface IFilterState {
  id: number;
  search: string;
  productTypeSearch: string;
  vendorSearch: string;
  sort: string;
  timeZone: string;
  bookedSlots: any;
  openSlots: any;
  selectedTeesheet: any;
  selectedOrder: any;
  selectedBookingParticipant: any;
  selectedTeeTime: any;
  add_internal_description: boolean;
  openNewVariant: boolean;
  productOption: string;
  optionValue: any;
  optionPrice: any;
  selectAllFacilities: boolean;
}

interface IAvailableOnState {
  selectedDate: Date;
  bookedSlots: any;
  openSlots: any;
  selectedTeesheet: any;
  selectedOrder: any;
  selectedBookingParticipant: any;
  selectedTeeTime: any;
}

interface IAvailableUntilState {
  selectedDate: Date;
  bookedSlots: any;
  openSlots: any;
  selectedTeesheet: any;
  selectedOrder: any;
  selectedBookingParticipant: any;
  selectedTeeTime: any;
}

interface IFacilityState {
  sales_channels: ISalesChannel[];
}

interface ISalesChannel {
  id: number;
  facility_id: number;
  title: number;
  isChecked: boolean;
}

interface IProps {
  authStore: IAuthState;
  uiStore: IUIState;
  uiActions: IUIActions;
}

interface IUpdateInventoryState {
  id: number;
  updateInventoryActive: boolean;
  newQuantity: number;
}

interface ITaxState {
  updateTaxLinesActive: boolean;
  selectedInventoryLevelIndex: number;
  availableTaxLines: Record<string, any>[];
  selectedTaxLines: ITaxLines[];
  selectedTaxLineIds: number[];
  selectedFacilityID: number;
  selectedInventoryLevel: IInventoryLevel;
}

interface IUpdateAllPricesState {
  displayPricesModal: boolean;
  newPrice: string;
  type: "prices" | "costs" | "";
}

export default function Single(props: IProps) {
  const { authStore, uiActions, uiStore } = props;
  const { Option } = Select;
  const history = useHistory();
  const { productId }: any = useParams();
  const productPermissions = authStore?.user?.permissions;
  const dispatch = useDispatch();

  const [productState, setProductState] = useState<IProductState>({
    id: null,
    title: "",
    subtitle: "",
    type: "",
    description: "",
    internal_description: "",
    variant_count: null,
    variants: null,
    price: null,
    original_price: null,
    cost: null,
    taxable: false,
    customer_required: false,
    shipping_required: false,
    fulfillment_required: false,
    vendor_id: null,
    vendor_title: "",
    department: "",
    category: "",
    subcategory: "",
    account_type: "",
    editing: false,
    sku: "",
    barcode: "",
    tracked: false,
    inventory_levels: null,
    department_id: null,
    category_id: null,
    subcategory_id: null,
    accounting_reference_id: null,
    membership_type: "",
    meta: {
      membership_type: "",
    },
    account_id: null,
    online_store: null,
    // available_on: "",
    // unavailable_on: "",
    available_on: null,
    unavailable_on: null,
    facilityAccess: false,

    //Temp Available On / Off Date Values
    available_on_toggle: false,
    unavailable_on_toggle: false,
    available_on_time: "",
    available_on_date: "",
    unavailable_on_time: "",
    unavailable_on_date: "",
    isLoaded: false,

    tax_line_ids: [],
    price_locked: null,
    cannot_be_sold: null,

    track_inventory: false,
  });

  const [productStateBeforeChanges, setProductStateBeforeChanges] = useState<IProductState>(undefined);
  const [productLoaded, setProductLoaded] = useState<boolean>(false);
  const [inventoryLevelLoaded, setInventoryLevelLoaded] = useState<boolean>(false);
  const [updatePrices, setUpdatePrices] = useState<IUpdateAllPricesState>({
    displayPricesModal: false,
    newPrice: "",
    type: "",
  });

  const [productOptionState, setProductOptionState] = useState({
    types: [],
    vendors: [],
    accounts: [],
    accountingReference: [],
    departments: [],
    variants: [],
  });

  const [addTicketState, setAddTicketState] = useState<IAddTicketState>({
    addTicketActive: false,
    currentAddedTickets: [],
    allTickets: [],
    ticketsToUpdate: new Map(),
  });

  const [comboProductState, setComboProductState] = useState<IComboProductState>({
    comboProductsActive: false,
    currentAddedProducts: [],
    allProducts: [],
    productsToUpdate: new Map(),
    updateComboProductsOpen: false,
    selectedComboVariant: null,
    product: null,
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    id: null,
    search: "",
    productTypeSearch: "",
    vendorSearch: "",
    sort: "alpha",
    timeZone: "",
    selectedTeesheet: 54,
    bookedSlots: 0,
    openSlots: 0,
    selectedOrder: null,
    selectedTeeTime: null,
    selectedBookingParticipant: null,
    add_internal_description: false,
    openNewVariant: false,
    productOption: "",
    optionPrice: null as string,
    optionValue: null as string,
    selectAllFacilities: false,
  });

  const [facilityState, setFacilityState] = useState<IFacilityState>({
    sales_channels: [],
  });

  const [availableOnState, setAvailableOnState] = useState<IAvailableOnState>({
    selectedDate: null,
    selectedTeesheet: 54,
    bookedSlots: 0,
    openSlots: 0,
    selectedOrder: null,
    selectedTeeTime: null,
    selectedBookingParticipant: null,
  });

  const [availableOnStateBeforeChanges, setAvailableOnStateBeforeChanges] = useState<IAvailableUntilState>(undefined);
  const [availableOnStateLoaded, setAvailableOnStateLoaded] = useState<boolean>(false);

  const [archiveProductOpen, setArchiveProductOpen] = useState<boolean>(false);
  const [duplicateProductOpen, setDuplicateProductOpen] = useState<boolean>(false);
  const [taxModal, setTaxModal] = useState<boolean>(false);

  const [availableUntilState, setAvailableUntilState] = useState<IAvailableUntilState>({
    selectedDate: null,
    selectedTeesheet: 54,
    bookedSlots: 0,
    openSlots: 0,
    selectedOrder: null,
    selectedTeeTime: null,
    selectedBookingParticipant: null,
  });

  const [availableUntilStateBeforeChanges, setAvailableUntilStateBeforeChanges] =
    useState<IAvailableUntilState>(undefined);
  const [availableUntilStateLoaded, setAvailableUntilStateLoaded] = useState<boolean>(false);

  const [inventoryState, setInventoryState] = useState<IUpdateInventoryState>({
    id: null,
    updateInventoryActive: false,
    newQuantity: null,
  });

  const [taxState, setTaxState] = useState<ITaxState>({
    updateTaxLinesActive: false,
    availableTaxLines: [],
    selectedInventoryLevelIndex: null,
    selectedTaxLines: null,
    selectedTaxLineIds: null,
    selectedFacilityID: null,
    selectedInventoryLevel: null,
  });

  const [logisticsCard, setLogisticsCard] = useState({
    vendorSearch: null as string,
  });

  const defaultAccountingReference = productOptionState.accountingReference.find(
    (ref: Record<string, any>) => ref?.id == productState.accounting_reference_id,
  );

  const dataTableColumns = ["", "Price", "Cost", "Quantity"];

  useEffect(() => {
    void loadProduct();
    void loadProductTypes();
    void loadVendors();
    void loadAccounts();
    void loadAccountingReference();
    void loadDepartments();
    void loadTaxLines();
  }, []);

  useEffect(() => {
    if (productState?.vendor_id) {
      const vendor = productOptionState.vendors.find(value => value.id === productState.vendor_id);

      setLogisticsCard(prev => ({ ...prev, vendorSearch: vendor.title }));
    }
  }, [productState?.vendor_id]);

  //preset the accounting reference search value
  useEffect(() => {
    if (defaultAccountingReference === undefined) {
      return;
    }
    setFilterState({
      ...filterState,
      search: defaultAccountingReference.title,
    });
  }, [defaultAccountingReference]);

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setProductState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleDescriptionChange(description: string) {
    setProductState(prevState => ({ ...prevState, description }));
  }

  function priceIsValid(value: string | number) {
    const price = Number(value);
    return value !== null && value !== undefined && value !== "" && !isNaN(price) && price >= 0;
  }

  function optionalPriceIsValid(value: string | number) {
    if (value === null || value === undefined || value === "") {
      return true;
    } else {
      const price = Number(value);
      return !isNaN(price) && price >= 0;
    }
  }

  function resetToDefaultPriceValue(event: any, optional: boolean) {
    const { id, value } = event.target;

    if (optional ? !optionalPriceIsValid(value) : !priceIsValid(value)) {
      setProductState(prevState => ({ ...prevState, [id]: productStateBeforeChanges[id as keyof IProductState] }));
    }
  }

  function handleCheckboxChange(event: any) {
    const { id, checked } = event.target;
    setProductState(prevState => ({ ...prevState, [id]: checked }));
  }

  async function loadProduct() {
    const timeZone = moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).zoneAbbr();

    const productRes = await GetProduct(
      //sales_channels: true <-- add later
      { id: productId, extended: true, extended_variants: true, inventory: true, all: true },
      true,
    );

    if (productRes.status !== StatusCode.OK) {
      uiActions.showError(productRes.message);
      return;
    }

    const product = productRes.data[0];
    const variant = product.variants[0];

    const inventoryLevels = await loadInventoryLevels(variant.id);

    const tempTicketList: ITicket[] = [];
    const tempComboProductList = [];

    const tempTicketMap = new Map();

    if (product.variant_count === 1) {
      for (let i = 0; i < product.variant_count; i++) {
        for (let k = 0; k < product.variants[i].tickets?.length; k++) {
          const tempTicket = {
            id: variant.tickets[k].id,
            title: variant.tickets[k].title,
            value: variant.tickets[k].value,
            included: variant.tickets[k].included,
            quantity: variant.tickets[k].quantity,
          };

          tempTicketList.push(tempTicket);

          tempTicketMap.set(variant.tickets[k].id, variant.tickets[k].quantity);
        }
      }
    }

    const params = {};
    const salesChannelRes = await GetSalesChannels(params, true);

    if (salesChannelRes.status !== StatusCode.OK) {
      uiActions.showError(salesChannelRes.data.message);
      return;
    }

    const salesChannelsList: any = [];

    let availableOnToggle = false;
    let availableOnDate: Date;
    let availableOnTime = "";
    let tempAvailableOnDate = "";
    let unavailableOnToggle = false;
    let unavailableOnDate: Date;
    let unavailableOnTime = "";
    let tempUnavailableOnDate = "";
    let currentAvailableOnDate = "";
    let currentUnavailableOnDate = "";

    if (product.available_on !== null) {
      //Set Toggle To Active
      availableOnToggle = true;

      //Get Current available_on Date & Time Value
      currentAvailableOnDate = String(product.available_on);

      //Get rid of the hours, minutes, seconds, milleseconds from available_on
      availableOnDate = new Date(currentAvailableOnDate);
      availableOnDate.setHours(0, 0, 0, 0);

      //Get Current available_on_date Value
      tempAvailableOnDate = availableOnDate.toLocaleDateString();

      //Get Current available_on_time Value
      const tempAvailableOnTime = new Date(product.available_on).toTimeString().split(" ");
      const availableOnTimeSplit = tempAvailableOnTime[0].split(":");
      availableOnTime = availableOnTimeSplit[0] + ":" + availableOnTimeSplit[1];
    }

    if (product.unavailable_on !== null) {
      //Set Toggle To Active
      unavailableOnToggle = true;

      //Get curren unavailable_on Date & Time Value
      currentUnavailableOnDate = String(product.unavailable_on);

      //Get rid of the hours, minutes, seconds, milleseconds from available_on
      unavailableOnDate = new Date(currentUnavailableOnDate);
      unavailableOnDate.setHours(0, 0, 0, 0);

      //Get current unavailable_on_date
      tempUnavailableOnDate = unavailableOnDate.toLocaleDateString();

      //Get current unavailable_on_time Value
      const tempUnvailableOnTime = new Date(product.unavailable_on).toTimeString().split(" ");
      const unavailableOnTimeSplit = tempUnvailableOnTime[0].split(":");
      unavailableOnTime = unavailableOnTimeSplit[0] + ":" + unavailableOnTimeSplit[1];
    }

    ReactDOM.unstable_batchedUpdates(() => {
      setProductState(prev => ({
        ...prev,
        id: product.id,
        title: product.title,
        subtitle: product.subtitle,
        description: product.description,
        internal_description: product.internal_description,
        type: product.type,
        vendor_title: product.vendor_title,
        variant_count: product.variant_count,
        customer_required: product.customer_required,
        shipping_required: product.shipping_required,
        fulfillment_required: product.fulfillment_required,
        variants: product.variants,
        price: variant.price,
        price_locked: product.price_locked,
        cannot_be_sold: product.cannot_be_sold,
        original_price: variant.original_price,
        cost: variant.cost,
        taxable: variant.taxable,
        sku: variant.sku,
        barcode: variant.barcode,
        tracked: variant.inventory_item.tracked,
        accounting_reference_id: product.accounting_reference_id as number,
        department_id: product.department_id,
        category_id: product.category_id,
        subcategory_id: product.subcategory_id,
        vendor_id: product.vendor_id,
        account_id: product.account_id,
        facilityAccess: product.facility_access,

        available_on: currentAvailableOnDate,
        unavailable_on: currentUnavailableOnDate,
        available_on_toggle: availableOnToggle,
        unavailable_on_toggle: unavailableOnToggle,
        available_on_time: availableOnTime,
        unavailable_on_time: unavailableOnTime,
        available_on_date: tempAvailableOnDate,
        unavailable_on_date: tempUnavailableOnDate,
        track_inventory: !!product.track_inventory,
      }));
    });

    setProductOptionState(prev => ({
      ...prev,
      variants: product.variants,
    }));

    setAddTicketState(prev => ({
      ...prev,
      currentAddedTickets: tempTicketList,
      ticketsToUpdate: tempTicketMap,
    }));

    setComboProductState(prev => ({
      ...prev,
      currentAddedProducts: product.variants[0].combination_variants,
      product: product,
    }));

    setFilterState(prevState => ({
      ...prevState,
      timeZone: timeZone,
      selectedDate: tempAvailableOnDate,
      productTypeSearch: product?.type,
      search: product.accounting_reference?.title ?? "",
    }));

    setFacilityState(prev => ({
      ...prev,
      sales_channels: salesChannelsList,
    }));

    setAvailableOnState(prev => ({
      ...prev,
      selectedDate: availableOnDate,
    }));
    setAvailableOnStateLoaded(true);

    setAvailableUntilState(prev => ({
      ...prev,
      selectedDate: unavailableOnDate,
    }));
    setAvailableUntilStateLoaded(true);

    setProductLoaded(true);
  }

  function handleAvailableOnClear(id: "available_on_time" | "unavailable_on_time") {
    if (id === "available_on_time") {
      setProductState(prevState => ({
        ...prevState,
        available_on: null,
        available_on_date: null,
        available_on_time: null,
      }));
      setAvailableOnState(prevState => ({
        ...prevState,
        selectedDate: availableOnStateBeforeChanges.selectedDate ?? new Date(),
      }));
      return;
    }
    if (id === "unavailable_on_time") {
      setProductState(prevState => ({
        ...prevState,
        unavailable_on: null,
        unavailable_on_date: null,
        unavailable_on_time: null,
      }));
      setAvailableUntilState(prevState => ({
        ...prevState,
        selectedDate: availableUntilStateBeforeChanges.selectedDate ?? new Date(),
      }));
      return;
    }
  }

  function handleTimeAvailability(
    id: keyof Pick<IProductState, "available_on_time" | "unavailable_on_time">,
    value: string,
  ) {
    if (value === null || value === "") {
      handleAvailableOnClear(id);
      return;
    }
    if (value === undefined) {
      setProductState(prev => ({ ...prev, [id]: null }));
      return;
    }

    // if same date ... handle productState differently
    if (productState.available_on_date === productState.unavailable_on_date) {
      const valueDate = new Date(`${productState[id]} ${String(value)}`);

      if (id === "available_on_time") {
        const unavailableOnDate = new Date(productState.unavailable_on_date + " " + productState.unavailable_on_time);
        if (valueDate < unavailableOnDate) {
          setProductState(prevState => ({ ...prevState, [id]: value }));
        }
      } else if (id === "unavailable_on_time") {
        const availableOnDate = new Date(productState.available_on_date + " " + productState.available_on_time);
        if (valueDate > availableOnDate) {
          setProductState(prevState => ({ ...prevState, [id]: value }));
        }
      }
      return;
    }

    setProductState(prevState => ({ ...prevState, [id]: value }));
  }

  async function loadAccountingReference() {
    const accountingRes = await GetAccountingReference(null, true);
    if (accountingRes.status !== StatusCode.OK) {
      uiActions.showError(accountingRes.data.message);
      return;
    }

    const accounts = accountingRes.data;
    accounts.unshift({
      id: "",
      title: "None",
    });

    setProductOptionState(prev => ({
      ...prev,
      accountingReference: accounts,
    }));
  }

  async function loadAccounts() {
    const accountRes = await GetAccounts(true);
    if (accountRes.status !== StatusCode.OK) {
      uiActions.showError(accountRes.data.message);
      return;
    }

    const accounts = accountRes.data;

    accounts.unshift({
      id: "",
      title: "None",
    });

    setProductOptionState(prev => ({
      ...prev,
      accounts: accountRes.data,
    }));
  }

  async function loadDepartments() {
    const departmentRes = await GetDepartments(true);
    if (departmentRes.status !== StatusCode.OK) {
      uiActions.showError(departmentRes.data.message);
      return;
    }

    const department = departmentRes.data;
    department.unshift({
      id: null,
      title: "None",
      type: "department",
    });

    setProductOptionState(prev => ({
      ...prev,
      departments: department,
    }));
  }

  async function loadProductTypes() {
    const productTypeRes = await GetProductTypes(true);
    if (productTypeRes.status !== StatusCode.OK) {
      uiActions.showError(productTypeRes.data.message);
      return;
    }

    const productType = productTypeRes.data;

    setProductOptionState(prev => ({
      ...prev,
      types: productType,
    }));
  }

  async function loadVendors() {
    const vendorRes = await GetVendor(null, true);
    if (vendorRes.status !== StatusCode.OK) {
      uiActions.showError(vendorRes.data.message);
      return;
    }

    const vendors = vendorRes.data;
    // vendors.unshift({
    //   id: "",
    //   title: "None",
    // });

    setProductOptionState(prev => ({
      ...prev,
      vendors: vendors,
    }));
  }

  async function loadInventoryLevels(variantId: number) {
    const inventoryRes = await GetInventoryLevels(
      {
        variant_id: variantId,
        extended: true,
      },
      true,
    );

    if (inventoryRes.status !== StatusCode.OK) {
      uiActions.showError(inventoryRes.data.message);
      return null;
    }

    const sortedInventoryLevels = inventoryRes.data?.sort(
      (firstLevel: Record<string, any>, secondLevel: Record<string, any>) => {
        if (firstLevel?.facility?.long_name < secondLevel?.facility?.long_name) {
          return -1;
        } else if (firstLevel?.facility?.long_name > secondLevel?.facility?.long_name) {
          return 1;
        } else {
          return 0;
        }
      },
    );

    let facilityAccessCount = 0;
    inventoryRes.data?.map((inventory_level: any, index: number) => {
      if (inventory_level.has_access) {
        facilityAccessCount++;
      }
    });

    ReactDOM.unstable_batchedUpdates(() => {
      setProductState(prev => ({
        ...prev,
        inventory_levels: sortedInventoryLevels,
      }));
      setInventoryLevelLoaded(true);
      setFilterState(prev => ({
        ...prev,
        selectAllFacilities: sortedInventoryLevels?.length === facilityAccessCount ? true : false,
      }));
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return sortedInventoryLevels;
  }

  async function loadTaxLines() {
    const taxLinesRes = await GetTaxLines(true);

    if (taxLinesRes.status !== StatusCode.OK) {
      uiActions.showError(taxLinesRes.data.message);
      return;
    }
    setTaxState(prevState => ({ ...prevState, availableTaxLines: taxLinesRes.data }));
  }

  async function saveProduct() {
    const tempProductState = { ...productState };

    let tempOnDateTime = null;
    let tempUntilDateTime = null;

    if (!isEmpty(productState.available_on_date) && !isEmpty(productState.available_on_time)) {
      const tempOnDateString = productState.available_on_date + " " + productState.available_on_time;
      tempOnDateTime = new Date(tempOnDateString).toISOString();
    }

    if (!isEmpty(productState.unavailable_on_date) && !isEmpty(productState.unavailable_on_time)) {
      const tempUntilDateString = productState.unavailable_on_date + " " + productState.unavailable_on_time;
      tempUntilDateTime = new Date(tempUntilDateString).toISOString();
    }

    tempProductState.available_on = tempOnDateTime;
    tempProductState.unavailable_on = tempUntilDateTime;

    for (let i = 0; i < addTicketState.currentAddedTickets.length; i++) {
      const tempValue = addTicketState.ticketsToUpdate.get(addTicketState.currentAddedTickets[i].id);

      if (addTicketState.currentAddedTickets[i].quantity !== tempValue) {
        const params = {
          ticket_id: Number(addTicketState.currentAddedTickets[i].id),
          variant_id: Number(productState.variants[0].id),
          quantity: Number(tempValue),
        };

        const addTicketToVariantRes = await AddTicketToVariant(params, true);
        if (addTicketToVariantRes.status !== StatusCode.OK) {
          uiActions.showError(addTicketToVariantRes.data.message);
          return;
        }
      }
    }

    const foo = !isEqual({ ...productState.inventory_levels }, { ...productStateBeforeChanges.inventory_levels });
    console.log("Before and After differences exist is ", foo);

    if (!isEqual({ ...productState.inventory_levels }, { ...productStateBeforeChanges.inventory_levels })) {
      console.log("Inventory Levels should update");
      // const params: TPutProductInventory = {
      //   variant_id: variant_id ?? product.variants[0].id,
      //   quantity_available: quantity,
      //   tax_line_ids: tax_line_ids,
      // };
      productState.inventory_levels.forEach(inventory_level => ({}));
      const inventoryLevelRes = await UpdateInventoryLevels({ inventory_levels: productState.inventory_levels }, true);

      if (inventoryLevelRes.status !== StatusCode.OK) {
        uiActions.showError(inventoryLevelRes.data.message);
        return;
      }

      const inventoryLevels = await loadInventoryLevels(productState.variants[0]?.id);
      if (inventoryLevels != null) {
        //Update previous product state to include the new inventory levels
        setProductStateBeforeChanges(prevState => ({ ...prevState, inventory_levels: inventoryLevels }));
      }

      closeUpdateInventory();
    }

    const productRes = await PutProduct(tempProductState, true);
    if (productRes.status !== StatusCode.OK) {
      uiActions.showError(productRes.data.message);
      return;
    }

    setProductState(tempProductState);
    setProductStateBeforeChanges(tempProductState);
    setAvailableOnStateBeforeChanges(availableOnState);
    setAvailableUntilStateBeforeChanges(availableUntilState);

    // uiActions.showSuccess(productRes.data);

    return;
  }

  async function attachProductToFacility() {
    const res = await PutProductAttach({ product_id: productId }, true);
    if (res.status !== StatusCode.OK) {
      uiActions.showError(res.data.message);
      return;
    }

    setProductState(prevState => ({ ...prevState, facilityAccess: true }));

    return;
  }

  async function archiveProduct() {
    const res = await PutArchiveProduct({ id: productId }, true);
    if (res.status !== StatusCode.OK) {
      return;
    }
    setArchiveProductOpen(false);
    history.push("/admin/product/");
  }

  async function duplicateProduct() {
    const res = await DuplicateProduct({ product_id: productId }, true);

    if (res.status !== StatusCode.OK) {
      props.uiActions.showError("Failed to get the duplicate product");

      return;
    }

    setDuplicateProductOpen(false);

    history.push("/admin/product/");
  }

  function handleSelectChange(event: any) {
    const { id, value } = event.target;

    if (id === "department_id") {
      setProductState(prevState => ({ ...prevState, [id]: value, category_id: null, subcategory_id: null }));
    } else if (id === "category_id") {
      setProductState(prevState => ({ ...prevState, [id]: value, subcategory_id: null }));
    } else {
      setProductState(prevState => ({ ...prevState, [id]: value }));
    }
  }

  function handleDropDownChange(value: any, property: string) {
    setProductState(prev => ({ ...prev, [property]: value }));
  }

  function navigateToNewVariant() {
    const varLink: string = "/admin/product/" + String(productId) + "/variant/new";
    history.push(varLink);
  }

  function navigateToVariant(url: string) {
    history.push(url);
  }

  async function loadTickets() {
    const ticketRes = await GetTickets("", false);
    if (ticketRes.status === StatusCode.OK) {
      setAddTicketState(prevState => ({
        ...prevState,
        allTickets: ticketRes.data,
      }));
    }
  }

  async function openAddTicket() {
    const ticketRes = await GetTickets("", true);
    if (ticketRes.status !== StatusCode.OK) {
      uiActions.showError(ticketRes.data.message);
      return;
    }

    const tempTicketList: ITicket[] = [];

    for (let i = 0; i < ticketRes.data.length; i++) {
      const ticket = {
        id: ticketRes.data[i].id,
        title: ticketRes.data[i].title,
        included: ticketRes.data[i].included,
        value: ticketRes.data[i].value,
        quantity: 1,
      };

      tempTicketList.push(ticket);
    }

    setAddTicketState(prev => ({
      ...prev,
      addTicketActive: true,
      allTickets: tempTicketList,
    }));
  }

  function closeAddTicket() {
    setAddTicketState(prev => ({
      ...prev,
      addTicketActive: false,
    }));
  }

  async function handleAddTicket(event: any, ticket: any) {
    //Add ticket ID to product

    const params = {
      ticket_id: Number(ticket.id),
      variant_id: Number(productState.variants[0].id),
      quantity: 1,
    };

    const addTicketToVariantRes = await AddTicketToVariant(params, true);
    if (addTicketToVariantRes.status !== StatusCode.OK) {
      uiActions.showError(addTicketToVariantRes.data.message);
      return;
    }

    closeAddTicket();

    //Reload Product

    void loadProduct();
  }

  async function handleDeleteTicket(event: any, ticket: any) {
    const params = {
      ticket_id: Number(ticket.id),
      variant_id: Number(productState.variants[0].id),
    };

    const deleteTicketRes = await DeleteTicketFromVariant(params, true);
    if (deleteTicketRes.status !== StatusCode.OK) {
      uiActions.showError(deleteTicketRes.data.message);
      return;
    }

    void loadProduct();
  }

  function handleQuantityChange(event: any, ticket: any) {
    const newQuantity = Number(event.target.value);
    const tempTicketsToUpdate = addTicketState.ticketsToUpdate;

    tempTicketsToUpdate.set(ticket.id, newQuantity);

    setAddTicketState(prev => ({
      ...prev,
      ticketsToUpdate: tempTicketsToUpdate,
    }));
  }

  function unavailableDateIsValid() {
    if (productState?.unavailable_on === null) {
      return true;
    }
    if (productState?.unavailable_on.length === 0) {
      return true;
    }

    const wantedUnAvailableOn = new Date(`${productState?.unavailable_on_date} ${productState?.unavailable_on_time}`);
    const currentUnAvailableOn = new Date(
      `${productStateBeforeChanges?.unavailable_on_date} ${productStateBeforeChanges?.unavailable_on_time}`,
    );

    if (
      productState.unavailable_on_time === null ||
      (productState.unavailable_on.length > 0 &&
        JSON.parse(JSON.stringify(wantedUnAvailableOn)) == JSON.parse(JSON.stringify(currentUnAvailableOn)))
    ) {
      return false;
    } else {
      return true;
    }
  }

  /*
    - available_on date could previously be set to a date before today and be a valid date.
    - available_on date can not be updated to date value before the current date.
    - <DatePick /> component `status` param visually indicates this edge case when applicable
  */
  function availableOnDateIsValid() {
    if (productState?.available_on === null) {
      return true;
    }
    if (productState?.available_on.length === 0) {
      return true;
    }

    const wantedAvailableOn = new Date(`${productState?.available_on_date} ${productState?.available_on_time}`);
    const currentAvailableOn = new Date(
      `${productStateBeforeChanges?.available_on_date} ${productStateBeforeChanges?.available_on_time}`,
    );

    if (
      productState.available_on_time === null ||
      (productState.available_on.length > 0 &&
        wantedAvailableOn < new Date() &&
        JSON.parse(JSON.stringify(wantedAvailableOn)) != JSON.parse(JSON.stringify(currentAvailableOn)))
    ) {
      return false;
    } else {
      return true;
    }
  }

  function unsavedChangesExist() {
    if (
      productStateBeforeChanges === undefined ||
      availableOnStateBeforeChanges === undefined ||
      availableUntilStateBeforeChanges === undefined
    ) {
      if (productLoaded && inventoryLevelLoaded && availableOnStateLoaded && availableUntilStateLoaded) {
        setProductStateBeforeChanges(productState);
        setAvailableOnStateBeforeChanges(availableOnState);
        setAvailableUntilStateBeforeChanges(availableUntilState);
      }
      return false;
    }

    return (
      (availableOnDateIsValid() &&
        unavailableDateIsValid() &&
        !isEqualWith(productStateBeforeChanges, productState, (originalValue, newValue) => {
          if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
            return true;
          }
        })) ||
      (availableOnDateIsValid() &&
        unavailableDateIsValid() &&
        !isEqualWith(availableOnStateBeforeChanges, availableOnState, (originalValue, newValue) => {
          if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
            return true;
          }
        })) ||
      (availableOnDateIsValid() &&
        unavailableDateIsValid() &&
        !isEqualWith(availableUntilStateBeforeChanges, availableUntilState, (originalValue, newValue) => {
          if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
            return true;
          }
        }))
    );
  }

  function cancelUnsavedChanges() {
    setProductState(productStateBeforeChanges);
    setAvailableOnState(availableOnStateBeforeChanges);
    setAvailableUntilState(availableUntilStateBeforeChanges);
  }

  function closeComboProduct() {
    setComboProductState(prev => ({
      ...prev,
      comboProductsActive: false,
    }));
  }

  function updateFilterState(newFilterState: Partial<IFilterState>) {
    setFilterState(cur => {
      return { ...cur, ...newFilterState };
    });
  }

  async function handleAddProduct(newId: number) {
    //Add ticket ID to product
    const tempArray: number[] = [];

    for (let i = 0; i < comboProductState.currentAddedProducts?.length; i++) {
      tempArray.push(comboProductState.currentAddedProducts[i].id);
    }

    tempArray.push(newId);

    const params = {
      base_variant_id: productState.variants[0].id,
      variant_ids: tempArray,
    };

    const addProductToVariantRes = await AddProductToVariant(params, true);
    if (addProductToVariantRes.status !== StatusCode.OK) {
      uiActions.showError(addProductToVariantRes.data.message);
      return;
    }

    //Reload Product
    void loadProduct();
    closeComboProduct();
  }

  async function handleDeleteProduct(event: any, product: any) {
    const newComboArray = [];

    for (let i = 0; i < comboProductState.currentAddedProducts.length; i++) {
      if (product.id !== comboProductState.currentAddedProducts[i].id) {
        newComboArray.push(comboProductState.currentAddedProducts[i].id);
      }
    }

    const params = {
      base_variant_id: productState.variants[0].id,
      variant_ids: newComboArray,
    };

    const deleteProductComboRes = await AddProductToVariant(params, true);
    if (deleteProductComboRes.status !== StatusCode.OK) {
      uiActions.showError(deleteProductComboRes.data.message);
      return;
    }

    void loadProduct();
  }

  const handleAvailableOnDateSelectorChange = (selectedDate: Date) => {
    const tempDate = new Date(selectedDate).toLocaleDateString();
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    if (selectedDate < currentDate) {
      return;
    }

    if (availableUntilState.selectedDate) {
      const availableSelectedDateAndTime = new Date(
        selectedDate.toLocaleDateString() + " " + productState.available_on_time,
      );
      const unavailableSelectedDateAndTime = new Date(
        availableUntilState.selectedDate.toLocaleDateString() + " " + productState.unavailable_on_time,
      );
      if (availableSelectedDateAndTime > unavailableSelectedDateAndTime) {
        const monthAfterSelectedDate = new Date(selectedDate.getTime());
        monthAfterSelectedDate.setMonth(monthAfterSelectedDate.getMonth() + 1);

        setAvailableUntilState(prevState => ({ ...prevState, selectedDate: monthAfterSelectedDate }));
        setProductState(prevState => ({
          ...prevState,
          unavailable_on_date: monthAfterSelectedDate.toLocaleDateString(),
        }));
      }
    }

    setAvailableOnState(prevState => ({ ...prevState, selectedDate: selectedDate }));
    setProductState(prevState => ({ ...prevState, available_on_date: tempDate }));
  };

  const handleAvailableUntilDateSelectorChange = (selectedDate: Date) => {
    const tempDate = new Date(selectedDate).toLocaleDateString();
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    if (availableOnState.selectedDate) {
      const unavailableSelectedDateAndTime = new Date(
        selectedDate.toLocaleDateString() + " " + productState.unavailable_on_time,
      );
      const availableSelectedDateAndTime = new Date(
        availableOnState.selectedDate.toLocaleDateString() + " " + productState.available_on_time,
      );
      if (unavailableSelectedDateAndTime < availableSelectedDateAndTime) {
        return;
      }
    }

    setAvailableUntilState(prevState => ({ ...prevState, selectedDate: selectedDate }));
    setProductState(prevState => ({ ...prevState, unavailable_on_date: tempDate }));
  };

  async function handleToggleChange(event: ChangeEvent<HTMLInputElement>) {
    const { id, checked, value } = event.target;
    const tempSalesChannelList = facilityState.sales_channels;

    if (checked === true) {
      //Add Sales Channel To Product

      const params = {
        sales_channel_id: id,
        remove: 0,
        product_id: productId,
      };

      const updateSalesChannelRes = await UpdateSalesChannel(params, true);

      if (updateSalesChannelRes.status !== StatusCode.OK) {
        uiActions.showError(updateSalesChannelRes.data.message);
        return;
      }
    } else if (checked === false) {
      //Remove Sales Channel From Product

      //Uncheck checkbox
      for (let i = 0; i < tempSalesChannelList.length; i++) {
        if (Number(tempSalesChannelList[i].id) === Number(id)) {
          tempSalesChannelList[i].isChecked = null;
        }
      }

      const params = {
        sales_channel_id: id,
        remove: 1,
        product_id: productId,
      };

      const updateSalesChannelRes = await UpdateSalesChannel(params, true);

      if (updateSalesChannelRes.status !== StatusCode.OK) {
        uiActions.showError(updateSalesChannelRes.data.message);
        return;
      }
    }

    setFacilityState(prev => ({
      ...prev,
      sales_channels: tempSalesChannelList,
    }));
  }

  function handleAvailableOnUntilToggle(event: ChangeEvent<HTMLInputElement>) {
    const { id, checked, value } = event.target;
    if (id === "available_on_toggle" && !checked) {
      setProductState(prevState => ({
        ...prevState,
        [id]: checked,
        available_on: null,
        available_on_date: null,
        available_on_time: null,
      }));
      setAvailableOnState(prevState => ({
        ...prevState,
        selectedDate: availableOnStateBeforeChanges.selectedDate,
      }));
      return;
    }

    if (id === "unavailable_on_toggle" && !checked) {
      setProductState(prevState => ({
        ...prevState,
        [id]: checked,
        unavailable_on: null,
        unavailable_on_date: null,
        unavailable_on_time: null,
      }));
      setAvailableUntilState(prevState => ({
        ...prevState,
        selectedDate: availableUntilStateBeforeChanges.selectedDate,
      }));
      return;
    }

    if (id === "available_on_toggle" && checked) {
      // try to re-apply previous date and return early
      if (productStateBeforeChanges.available_on) {
        setProductState(prev => ({
          ...prev,
          [id]: checked,
          available_on: productStateBeforeChanges.available_on,
          available_on_date: productStateBeforeChanges.available_on_date,
          available_on_time: productStateBeforeChanges.available_on_time,
        }));
        setAvailableOnState(prevState => ({
          ...prevState,
          selectedDate: availableOnStateBeforeChanges.selectedDate,
        }));
        return;
      } else {
        // Populate with new date
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        const tempDefaultAvailableOnTimeSplit = new Date().toTimeString().split(" ");
        const defaultAvailableOnTimeSplit = tempDefaultAvailableOnTimeSplit[0].split(":");
        const defaultAvailableOnTime =
          defaultAvailableOnTimeSplit[0] + ":" + String(Number(defaultAvailableOnTimeSplit[1]) + 1);
        const currentUnvailableOnDate = new Date(
          productState.unavailable_on_date + " " + productState.unavailable_on_time,
        );

        // adjust available_on date
        if (availableUntilState.selectedDate && currentUnvailableOnDate < new Date()) {
          const unavailableOnDate = new Date();
          unavailableOnDate.setMonth(unavailableOnDate.getMonth() + 1);
          unavailableOnDate.setHours(0, 0, 0, 0);

          setProductState(prevState => ({
            ...prevState,
            [id]: checked,
            unavailable_on: String(unavailableOnDate),
            unavailable_on_date: unavailableOnDate.toLocaleDateString(),
          }));
          setAvailableUntilState(prevState => ({
            ...prevState,
            selectedDate: unavailableOnDate,
          }));
        } else {
          setProductState(prevState => ({
            ...prevState,
            [id]: checked,
            available_on: String(currentDate),
            available_on_date: currentDate.toLocaleDateString(),
            available_on_time: defaultAvailableOnTime,
          }));
          setAvailableOnState(prevState => ({
            ...prevState,
            selectedDate: currentDate,
          }));
        }
        return;
      }
    }

    if (id === "unavailable_on_toggle" && checked) {
      // try to re-apply previous date and return early
      if (productStateBeforeChanges.unavailable_on) {
        setProductState(prev => ({
          ...prev,
          [id]: checked,
          unavailable_on: productStateBeforeChanges.unavailable_on,
          unavailable_on_date: productStateBeforeChanges.unavailable_on_date,
          unavailable_on_time: productStateBeforeChanges.unavailable_on_time,
        }));
        setAvailableUntilState(prevState => ({
          ...prevState,
          selectedDate: availableUntilStateBeforeChanges.selectedDate,
        }));
        return;
      } else {
        // Populate with new date
        const nextDate = new Date();
        nextDate.setMonth(nextDate.getMonth() + 1);
        nextDate.setHours(0, 0, 0, 0);

        // Get current unavailable_on_time value
        const tempDefaultUnavailableOnTimeSplit = new Date().toTimeString().split(" ");
        const defaultUnavailableOnTimeSplit = tempDefaultUnavailableOnTimeSplit[0].split(":");
        const defaultUnavailableOnTime = defaultUnavailableOnTimeSplit[0] + ":" + defaultUnavailableOnTimeSplit[1];
        const currentAvailableOnDate = new Date(productState.available_on_date + " " + productState.available_on_time);

        let dateToChangeTo: Date = null;

        if (availableOnState.selectedDate && currentAvailableOnDate > nextDate) {
          dateToChangeTo = new Date(currentAvailableOnDate.getTime());
          dateToChangeTo.setMonth(dateToChangeTo.getMonth() + 1);
        } else {
          dateToChangeTo = nextDate;
        }

        setProductState(prevState => ({
          ...prevState,
          [id]: checked,
          unavailable_on: String(dateToChangeTo),
          unavailable_on_date: dateToChangeTo.toLocaleDateString(),
          unavailable_on_time: defaultUnavailableOnTime,
        }));
        setAvailableUntilState(prevState => ({
          ...prevState,
          selectedDate: dateToChangeTo,
        }));
        return;
      }
    }
  }

  function handleInventoryInputChange(event: React.ChangeEvent<HTMLInputElement>, index: number) {
    const { id, value } = event.target;
    const tempInventoryLevels = [...productState.inventory_levels];
    tempInventoryLevels[index] = { ...tempInventoryLevels[index], [id]: value };
    setProductState(prevState => ({ ...prevState, inventory_levels: tempInventoryLevels }));
  }

  function handleInventoryAccessChange(event: React.ChangeEvent<HTMLInputElement>, index: number) {
    const { id, checked } = event.target;
    const tempInventoryLevels = [...productState.inventory_levels];
    tempInventoryLevels[index] = { ...tempInventoryLevels[index], [id]: checked };
    setProductState(prevState => ({ ...prevState, inventory_levels: tempInventoryLevels }));
  }

  function handleSelectAllFacilities(event: React.ChangeEvent<HTMLInputElement>) {
    const { checked } = event.target;

    const tempAccess = [...productState.inventory_levels];
    for (let i = 0; i < tempAccess?.length; i++) {
      tempAccess[i] = { ...tempAccess[i], has_access: checked };
    }

    setProductState(prevState => ({ ...prevState, inventory_levels: tempAccess }));
    setFilterState(prevState => ({ ...prevState, selectAllFacilities: checked }));
  }

  function initiateUpdateInventory(inventory_level: Record<string, any>) {
    setInventoryState(prevState => ({
      ...prevState,
      updateInventoryActive: true,
      id: inventory_level.id,
      newQuantity: inventory_level.quantity_available,
    }));
  }

  function handleInventoryQuantityChange(event: any) {
    setInventoryState(prevState => ({ ...prevState, newQuantity: event.target.value }));
  }

  function closeUpdateInventory() {
    setInventoryState(prevState => ({ ...prevState, updateInventoryActive: false, newQuantity: 0, id: null }));
  }

  function handlePricingLockStoreChange(event: React.ChangeEvent<HTMLInputElement>, index: number) {
    const { id, checked } = event.target;
    const tempInventoryLevels = [...productState.inventory_levels];
    tempInventoryLevels[index] = { ...tempInventoryLevels[index], [id]: checked };
    setProductState(prevState => ({ ...prevState, inventory_levels: tempInventoryLevels }));
  }

  function handleProductNotSoldStoreChange(event: React.ChangeEvent<HTMLInputElement>, index: number) {
    const { id, checked } = event.target;
    const tempInventoryLevels = [...productState.inventory_levels];
    tempInventoryLevels[index] = { ...tempInventoryLevels[index], [id]: checked };
    setProductState(prevState => ({ ...prevState, inventory_levels: tempInventoryLevels }));
  }

  function handlePricingLockAllChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, checked } = event.target;
    const inventoryLevels = [...productState.inventory_levels];
    for (let i = 0; i < inventoryLevels?.length; i++) {
      inventoryLevels[i] = { ...inventoryLevels[i], price_locked: checked };
    }

    setProductState(prevState => ({
      ...prevState,
      inventory_levels: inventoryLevels,
      price_locked: checked,
    }));
  }

  function handleProductNotSoldAllChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, checked } = event.target;
    const inventoryLevels = [...productState.inventory_levels];
    for (let i = 0; i < inventoryLevels?.length; i++) {
      inventoryLevels[i] = { ...inventoryLevels[i], cannot_be_sold: checked };
    }

    setProductState(prevState => ({
      ...prevState,
      inventory_levels: inventoryLevels,
      cannot_be_sold: checked,
    }));
  }

  const tabs = [
    {
      id: "update-invtentory",
      content: "Update Inventory",
    },
    {
      id: "update-inventory-log",
      content: "Update Inventory Log",
    },
  ];

  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (selectedTabIndex: number) => {
    setSelectedTab(selectedTabIndex);
  };

  async function completeUpdateInventory() {
    const params = {
      id: inventoryState.id,
      variant_id: productState.variants[0]?.id,
      quantity_available: inventoryState.newQuantity,
    };

    const updateInventoryRes = await UpdateInventoryLevels(params, true);
    if (updateInventoryRes.status !== StatusCode.OK) {
      uiActions.showError(updateInventoryRes.data.message);
      return;
    }

    closeUpdateInventory();

    //Force refresh
    const inventoryLevels = await loadInventoryLevels(productState.variants[0]?.id);
    if (inventoryLevels != null) {
      //Update previous product state to include the new inventory levels
      setProductStateBeforeChanges(prevState => ({ ...prevState, inventory_levels: inventoryLevels }));
    }
  }

  function closeUpdateTaxes() {
    setTaxState(prevState => ({
      ...prevState,
      selectedInventoryLevelIndex: null,
      updateTaxLinesActive: false,
      selectedTaxLines: null,
      selectedTaxLineIds: null,
    }));
  }

  function initiateUpdateTaxes(tax_lines: ITaxLines[], inventory_level: IInventoryLevel, index: number) {
    const taxLineIds: number[] = [];
    console.log("Tax_lines ", tax_lines, "inventory_level ", inventory_level, "index ", index);
    tax_lines.forEach(taxLine => {
      taxLineIds.push(taxLine.id);
    });
    setTaxState(prevState => ({
      ...prevState,
      selectedInventoryLevel: { ...inventory_level },
      selectedInventoryLevelIndex: index,
      updateTaxLinesActive: true,
      selectedTaxLines: [...tax_lines],
      selectedTaxLineIds: taxLineIds,
    }));
  }

  function handleProductTypeSearch(query: string) {
    setFilterState(prevState => ({ ...prevState, productTypeSearch: query }));
  }

  function displayUpdateAllPrices(type: "costs" | "prices" | "") {
    setUpdatePrices(prevState => ({ ...prevState, displayPricesModal: true, type }));
  }

  function handleAllPricesInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    setUpdatePrices(prevState => ({ ...prevState, newPrice: value }));
  }

  function updateAllPriceCost() {
    if (isNaN(Number(updatePrices.newPrice)) || updatePrices.newPrice === "") {
      uiActions.showError("Enter a valid number");
      return;
    }
    const id = updatePrices.type === "prices" ? "price_override" : "cost_override";
    const updateInventoryLevels = [...productState.inventory_levels];

    for (let i = 0; i < updateInventoryLevels?.length; i++) {
      updateInventoryLevels[i] = { ...updateInventoryLevels[i], [id]: updatePrices.newPrice };
    }
    ReactDOM.unstable_batchedUpdates(() => {
      setProductState(prevState => ({ ...prevState, inventory_levels: updateInventoryLevels }));
      setUpdatePrices(prevState => ({ ...prevState, displayPricesModal: false, newPrice: "", type: "" }));
    });
  }

  function handleOpenInternalDescription() {
    if (filterState.add_internal_description === true) {
      setFilterState(prevState => ({ ...prevState, add_internal_description: false }));
    } else {
      setFilterState(prevState => ({ ...prevState, add_internal_description: true }));
    }
  }

  function handleRemoveTaxLine(taxLineId: number) {
    let currentTaxIds = taxState.selectedInventoryLevel.tax_lines.map((line: ITaxLines) => Number(line.id));
    currentTaxIds = currentTaxIds.filter((id: number) => id !== taxLineId);

    let currentTaxLines = taxState.selectedTaxLines;
    currentTaxLines = currentTaxLines.filter(taxLine => taxLine.id !== taxLineId);

    setTaxState(prevState => ({
      ...prevState,
      selectedInventoryLevel: {
        ...prevState.selectedInventoryLevel,
        tax_lines: currentTaxLines,
        tax_line_ids: currentTaxIds,
      },
      selectedTaxLines: currentTaxLines,
    }));
  }

  function handleTaxDropdown(tax_line_id: number, tax_line: any) {
    //Update selected tax lines
    //Filter out based on current 'allIds' values
    const allTaxLines = taxState.selectedTaxLines;
    allTaxLines.push(tax_line);

    const allTaxLineIds = taxState.selectedTaxLineIds;
    allTaxLineIds.push(tax_line_id);
    setTaxState(prevState => ({
      ...prevState,
      selectedInventoryLevel: {
        ...prevState.selectedInventoryLevel,
        tax_lines: allTaxLines,
        tax_line_ids: allTaxLineIds,
      },
      selectedTaxLines: allTaxLines,
    }));
  }

  function updateTaxLines() {
    const allInventoryLevels = [...productState.inventory_levels];
    allInventoryLevels[taxState.selectedInventoryLevelIndex] = taxState.selectedInventoryLevel;

    setProductState(prevState => ({
      ...prevState,
      inventory_levels: allInventoryLevels,
      taxLineIds: taxState.selectedTaxLineIds,
    }));
    setTaxState(prevState => ({
      ...prevState,
      availableTaxLines: prevState.availableTaxLines,
      selectedFacilityID: null,
      selectedInventoryLevel: null,
      selectedInventoryLevelIndex: null,
      selectedTaxLineIds: null,
      selectedTaxLines: null,
      updateTaxLinesActive: false,
    }));
  }

  console.log("Current Product State", productState, "Product State Before Changes", productStateBeforeChanges);
  function handleInternalDescriptionChange(internal_description: string) {
    setProductState(prevState => ({ ...prevState, internal_description }));
  }

  function handleAccountReferenceChangeOnSearch(query: string) {
    if (query.length === 0) {
      setProductState({ ...productState, accounting_reference_id: null });
    }
    setFilterState({ ...filterState, search: query });
  }

  function handleNewVariant() {
    setFilterState(prevState => ({ ...prevState, openNewVariant: !filterState.openNewVariant }));
  }

  async function handleAddNewProductOption() {
    const params = {
      name: filterState.productOption,
      product_id: Number(productId),
    };

    const postOptionRes = await PostProductOption(params, true);

    if (postOptionRes.status !== StatusCode.OK) {
      if (postOptionRes.status === StatusCode.BAD_REQUEST) {
        props.uiActions.showError(postOptionRes.data);
      } else {
        props.uiActions.showError("Error Creating Option");
      }
      return;
    }

    const variantParams = {
      product_id: Number(productId),
      option1: filterState.optionValue,
      price: filterState.optionPrice,
    };

    const variantRes = await PostVariant(variantParams, true);

    if (variantRes.status === StatusCode.OK) {
      const variant = variantRes.data;

      const varLink: string = "/admin/product/" + String(productId) + "/variant/" + String(variant.id);

      history.push(varLink);
    } else {
      if (variantRes.status === StatusCode.BAD_REQUEST) {
        props.uiActions.showError(variantRes.data);
      } else {
        props.uiActions.showError("Error Creating Variant");
      }
    }
  }

  function handleProductOptionInput(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setFilterState(prevState => ({ ...prevState, [id]: value }));
  }

  function submitKeyboardEvent(event: KeyboardEvent) {
    if (event.key === "Enter" || event.key === "NumpadEnter") {
      void updateAllPriceCost();
    }
  }

  function logisticsCardSearch(key: keyof { vendorSearch: string }, query: string) {
    // handle the search query
    setLogisticsCard(prev => ({ ...prev, [key]: query }));

    // If search is cleared, remove the product param
    if (logisticsCard[key] !== null && query?.length === 0) {
      switch (key) {
        case "vendorSearch": {
          setProductState(prev => ({ ...prev, vendor_id: undefined }));
          break;
        }
        default:
          return;
      }
    }
  }

  /** TODO: Connect to backend || updateComboProduct function within useEditProduct hook */
  function handleUpdateComboProductModalOk(updateId: number, cost: string, price: string) {
    const params = {
      base_variant_id: comboProductState?.product?.variants[0].id,
      variant_id: updateId,
      cost: cost,
      price: price,
    };

    void updateComboProduct(params);
    setComboProductState(prevState => ({ ...prevState, updateComboProductsOpen: false, selectedComboVariant: null }));
  }

  /* Always refreshes product on success */
  async function updateComboProduct(params: {
    base_variant_id: number;
    variant_id: number;
    cost: string;
    price: string;
  }) {
    const res = await UpdateComboProduct(params, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error updating combo product.")); // TODO: Translation
      return;
    }

    void loadProduct();
  }

  function updateEditComboProductModal(newState: any) {
    setComboProductState(prevState => ({ ...prevState, selectedComboVariant: newState.selectedComboVariant }));
  }

  return (
    productLoaded &&
    inventoryLevelLoaded && (
      <>
        <Page
          title={productState.title}
          subtitle={productState.subtitle}
          splitLayout
          breadcrumbs={[{ label: "Back To Products", url: "/admin/product" }]}
          multipleActionDropdownAction={{
            label: "More Options",
            dropdownProps: {
              alignment: "right",
              options: [
                {
                  type: "handler",
                  label: "Archive",
                  handler: () => setArchiveProductOpen(true),
                  disabled: !productPermissions?.products_archive,
                },
                {
                  type: "handler",
                  label: "Duplicate",
                  handler: () => setDuplicateProductOpen(true),
                  disabled: !productPermissions?.products_edit,
                },
              ],
            },
          }}
          notificationBarProps={{
            isVisible: unsavedChangesExist(),
            onAction: saveProduct,
            onCancel: cancelUnsavedChanges,
          }}
        >
          <Page.Section twoThirds>
            <Card title="Product Details">
              <Card.Section>
                <FormLayout>
                  <FormLayout.Group>
                    <Input
                      value={productState.title ?? ""}
                      label="Title"
                      id="title"
                      onChange={handleInputChange}
                      placeholder="Title"
                      readOnly={!productPermissions?.products_edit}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <Input
                      value={productState.subtitle ?? ""}
                      label="Subtitle"
                      id="subtitle"
                      onChange={handleInputChange}
                      placeholder="Subtitle"
                      readOnly={!productPermissions?.products_edit}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <div>
                      <TextEditor
                        markdownText={productState.description ?? ""}
                        markdownTextOnChange={handleDescriptionChange}
                        label="Description"
                        readOnly={!productPermissions?.products_edit}
                      />

                      <Button type="link" size="small" onClick={handleOpenInternalDescription}>
                        Add Internal Description
                      </Button>
                    </div>
                  </FormLayout.Group>

                  {filterState.add_internal_description ? (
                    <FormLayout.Group>
                      <TextEditor
                        markdownText={productState.internal_description ?? ""}
                        markdownTextOnChange={handleInternalDescriptionChange}
                        label={"Internal Description"}
                        readOnly={!productPermissions?.products_edit}
                      />
                    </FormLayout.Group>
                  ) : null}
                </FormLayout>
              </Card.Section>
            </Card>

            {productState.variant_count == 1 ? (
              <Card
                title="Pricing"
                titleActions={
                  productPermissions?.products_edit
                    ? [{ content: "Add Variant", action: () => handleNewVariant() }]
                    : undefined
                }
                collapsable
              >
                <Card.Section title="Pricing">
                  <FormLayout>
                    <FormLayout.Group>
                      <Input
                        className={!priceIsValid(productState.price) ? "border-2 border-red-600" : ""}
                        prefix="$"
                        value={productState.price ?? ""}
                        label="Price"
                        id="price"
                        onChange={handleInputChange}
                        onBlur={(e: any) => resetToDefaultPriceValue(e, false)}
                        placeholder="0.00"
                        readOnly={!productPermissions?.products_edit || !productPermissions?.products_edit_price}
                      />
                      <Input
                        className={!optionalPriceIsValid(productState.original_price) ? "border-2 border-red-600" : ""}
                        prefix="$"
                        value={productState.original_price ?? ""}
                        label="Original Price"
                        id="original_price"
                        onChange={handleInputChange}
                        onBlur={(e: any) => resetToDefaultPriceValue(e, true)}
                        placeholder="0.00"
                        readOnly={!productPermissions?.products_edit || !productPermissions?.products_edit_price}
                      />
                      <Input
                        className={!optionalPriceIsValid(productState.cost) ? "border-2 border-red-600" : ""}
                        prefix="$"
                        value={productState.cost ?? ""}
                        label="Cost"
                        id="cost"
                        onChange={handleInputChange}
                        onBlur={(e: any) => resetToDefaultPriceValue(e, true)}
                        placeholder="0.00"
                        readOnly={!productPermissions?.products_edit || !productPermissions?.products_edit_cost}
                      />
                      <div className="mt-4">
                        <p className="text-medium">
                          {displayPercent(
                            roundNumber((productState.price - productState.cost) / productState.price, 2),
                          )}{" "}
                          -- {displayCurrency("cad", productState.price - productState.cost)}
                        </p>
                        <p>Profit Margin</p>
                      </div>
                    </FormLayout.Group>
                  </FormLayout>
                </Card.Section>
              </Card>
            ) : null}

            {productState.variant_count > 1 ? (
              <Card
                title="Variants"
                titleActions={
                  productPermissions?.products_edit
                    ? [{ content: "Add Variant", action: () => navigateToNewVariant() }]
                    : undefined
                }
                collapsable
              >
                <Card.Section table="true">
                  <table className="ui-table ui-table-clickable">
                    <tbody>
                      {productState.variants.map((variant: any, index: number) => {
                        return (
                          <tr
                            key={index}
                            onClick={() =>
                              navigateToVariant(
                                "/admin/product/" + String(productId) + "/variant/" + String(variant.id),
                              )
                            }
                          >
                            <td>{variant.title}</td>
                            <td>${variant.price}</td>
                            <td className="text-right">
                              <Button
                                type="link"
                                size="small"
                                onClick={() =>
                                  navigateToVariant(
                                    "/admin/product/" + String(productId) + "/variant/" + String(variant.id),
                                  )
                                }
                              >
                                Edit
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Card.Section>
              </Card>
            ) : null}

            <Card title="Logistics" collapsable>
              <Card.Section>
                <FormLayout>
                  <FormLayout.Group>
                    <Select
                      label={"Product Types"}
                      placeholder="Search Product Types.."
                      showSearch
                      className={`flex justify-center align-center w-full h-10 position-relative z-20 text-black font-medium appearance-none border-none focus:outline-none placeholder-gray-200`}
                      onSearch={(query: string) => handleProductTypeSearch(query)}
                      onChange={(value: any) => handleDropDownChange(value, "type")}
                      allowClear={productPermissions?.products_edit}
                      searchValue={filterState.productTypeSearch}
                      showDropDownOnFocus={false}
                      hideDropdown={true}
                      disabled={!productPermissions?.products_edit}
                    >
                      {productOptionState.types
                        .filter(productType =>
                          String(productType.title).toLowerCase().includes(filterState.productTypeSearch.toLowerCase()),
                        )
                        .map((type: any, i: number) => {
                          return (
                            <div key={i}>
                              {filterState.productTypeSearch !== "" ? (
                                <div>
                                  <Option key={i} value={type.title} name={type.title}>
                                    <div className="flex justify-between">
                                      <div>
                                        <div className="font-semibold text-lg">{type?.title}</div>
                                      </div>
                                    </div>
                                  </Option>
                                </div>
                              ) : null}
                            </div>
                          );
                        })}
                    </Select>

                    <Select
                      label={"Vendor"}
                      onChange={(value: number) => setProductState(prev => ({ ...prev, vendor_id: value }))}
                      className={`flex justify-center align-center w-full h-10 position-relative z-20 text-black font-medium appearance-none border-none focus:outline-none placeholder-gray-200`}
                      onSearch={(query: string) => logisticsCardSearch("vendorSearch", query)}
                      placeholder={"Search vendors..."}
                      searchValue={logisticsCard.vendorSearch || ""}
                      // defaultValue={product?.vendor_id}
                      // disabled={readOnly}
                      // error={!validated("vendor_id") && attempted}
                      showSearch
                      showDropDownOnFocus
                      hideDropdown
                      allowClear
                    >
                      <Select.Option key={"none"} value={undefined} name="none">
                        None
                      </Select.Option>
                      {productOptionState.vendors
                        .filter(val => val.title?.toLowerCase().includes(logisticsCard.vendorSearch?.toLowerCase()))
                        .map((vendor, index: number) => {
                          return (
                            <Select.Option key={index} value={vendor.id} name={vendor.title}>
                              {vendor.title}
                            </Select.Option>
                          );
                        })}
                    </Select>

                    <Select
                      label="Accounts"
                      onChange={(value: any) => handleDropDownChange(value, "account_id")}
                      defaultValue={productState.account_id}
                      disabled={!productPermissions?.products_edit}
                    >
                      {productOptionState.accounts.map((account: any, index: number) => {
                        return (
                          <Option key={index} value={account.id} name={account.title}>
                            {account.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <Select
                      label={"Accounting Reference"}
                      showSearch
                      className={`flex justify-center align-center w-full h-10 position-relative z-20 text-black font-medium appearance-none border-none focus:outline-none placeholder-gray-200`}
                      onSearch={(query: string) => handleAccountReferenceChangeOnSearch(query)}
                      onChange={(value: any) => setProductState({ ...productState, accounting_reference_id: value })}
                      allowClear={productPermissions?.products_edit}
                      searchValue={filterState.search}
                      showDropDownOnFocus={false}
                      defaultValue={defaultAccountingReference?.id}
                      hideDropdown={true}
                      disabled={!productPermissions?.products_edit}
                    >
                      {productOptionState.accountingReference
                        .filter(accountingReference =>
                          String(accountingReference.title).toLowerCase().includes(filterState.search.toLowerCase()),
                        )
                        .map((reference: any, i: number) => {
                          return (
                            <div key={i}>
                              {filterState.search !== "" ? (
                                <div>
                                  <Option key={i} value={reference.id} name={reference.title}>
                                    <div className="flex justify-between">
                                      <div className="font-semibold text-lg">{reference?.title}</div>
                                    </div>
                                  </Option>
                                </div>
                              ) : null}
                            </div>
                          );
                        })}
                    </Select>
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <Checkbox
                      id="customer_required"
                      size="medium"
                      value={productState.customer_required}
                      checked={productState.customer_required}
                      onChange={handleCheckboxChange}
                      label="Customer Required"
                      disabled={!productPermissions?.products_edit}
                    />
                    <Checkbox
                      id="shipping_required"
                      size="medium"
                      value={productState.shipping_required}
                      checked={productState.shipping_required}
                      onChange={handleCheckboxChange}
                      label="Shipping Required"
                      disabled={!productPermissions?.products_edit}
                    />
                    <Checkbox
                      id="fulfillment_required"
                      size="medium"
                      value={productState.fulfillment_required}
                      checked={productState.fulfillment_required}
                      onChange={handleCheckboxChange}
                      label="Fulfillment Required"
                      disabled={!productPermissions?.products_edit}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
              <Card.Section title="Departments">
                <FormLayout>
                  <FormLayout.Group>
                    <Select
                      label="Department"
                      onChange={(value: any) => handleDropDownChange(value, "department_id")}
                      defaultValue={productState.department_id}
                      disabled={!productPermissions?.products_edit}
                    >
                      {productOptionState.departments
                        .filter(department => department.type === "department")
                        .map((department: any, index: number) => {
                          return (
                            <Option key={index} value={department.id} name={department.title}>
                              {department.title}
                            </Option>
                          );
                        })}
                    </Select>
                    <Select
                      label="Category"
                      onChange={(value: any) => handleDropDownChange(value, "category_id")}
                      defaultValue={productState.category_id}
                      disabled={!productPermissions?.products_edit}
                    >
                      {productOptionState.departments
                        .filter(
                          department =>
                            department.type == "category" && department.parent_id == productState.department_id,
                        )
                        .map((department: any, index: number) => {
                          return (
                            <Option key={index} value={department.id} name={department.title}>
                              {department.title}
                            </Option>
                          );
                        })}
                    </Select>
                    <Select
                      label="Subcategory"
                      onChange={(value: any) => handleDropDownChange(value, "subcategory_id")}
                      defaultValue={productState.subcategory_id}
                      disabled={!productPermissions?.products_edit}
                    >
                      {productOptionState.departments
                        .filter(
                          department =>
                            department.type == "subcategory" && department.parent_id == productState.category_id,
                        )
                        .map((department: any, index: number) => {
                          return (
                            <Option key={index} value={department.id} name={department.title}>
                              {department.title}
                            </Option>
                          );
                        })}
                    </Select>
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
            </Card>

            {productOptionState.types.find(type => type?.title === productState.type)?.title === "Combo" &&
            productState.variant_count == 1 ? (
              <Card
                title="Combo Products"
                titleActions={[
                  {
                    content: "Add Product",
                    action: () => setComboProductState(prev => ({ ...prev, comboProductsActive: true })),
                    disabled: !authStore.user?.permissions?.products_edit,
                  },
                ]}
              >
                <Card.Section>
                  <div className="product-edit-combo-table">
                    <DataTable
                      columns={[
                        { label: "Product Title", width: "35%" }, // TODO: Translation
                        { label: "Price", width: "20%" }, // TODO: Translation
                        { label: "Cost", width: "20%" }, // TODO: Translation
                        { label: "Type", width: "15%" }, // TODO: Translation
                        { label: "", width: "5%" }, // Remove column
                        { label: "", width: "5%" }, // Edit column
                      ]}
                    >
                      {comboProductState.currentAddedProducts?.map(combo => (
                        <tr key={combo.id}>
                          <td>
                            <p>{combo.product.title}</p>
                            <p className="text-sm text-gray-400">{combo.title}</p>
                          </td>
                          <td>
                            {combo?.combo_pivot?.price
                              ? displayCurrency("cad", parseFloat(combo?.combo_pivot.price))
                              : ""}
                          </td>
                          <td>
                            {combo?.combo_pivot?.cost
                              ? displayCurrency("cad", parseFloat(combo?.combo_pivot.cost))
                              : ""}
                          </td>
                          <td>
                            <Badge>{combo.product.type}</Badge>
                          </td>
                          <td>
                            <Button
                              type="text"
                              onClick={(e: any) => {
                                if (productPermissions?.products_edit) {
                                  void handleDeleteProduct(e, combo);
                                }
                              }}
                              disabled={!authStore.user?.permissions?.products_edit}
                            >
                              <FontAwesomeIcon icon={{ prefix: "fad", iconName: "trash" }} />
                            </Button>
                          </td>
                          <td>
                            <Button
                              type="text"
                              onClick={() =>
                                setComboProductState(prev => ({
                                  ...prev,
                                  updateComboProductsOpen: true,
                                  selectedComboVariant: combo,
                                }))
                              }
                              disabled={!authStore.user?.permissions?.products_edit}
                            >
                              <FontAwesomeIcon icon={{ prefix: "fad", iconName: "pen-to-square" }} />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </DataTable>
                  </div>
                </Card.Section>
              </Card>
            ) : null}

            {productOptionState.types.find(type => type?.title === productState.type)?.title === "Ticket" &&
            productState.variant_count == 1 ? (
              <Card title="Tickets" collapsable>
                <Card.Section>
                  <FormLayout>
                    <FormLayout.Group>
                      <div>
                        {addTicketState.currentAddedTickets?.map((ticket: any, index: number) => {
                          return (
                            <div key={index}>
                              <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div className="ticket-display-add-ticket-card">
                                  <p className="ticket-display-text-title">{ticket.title}</p>

                                  <p className="ticket-display-text-included">{ticket.included}</p>
                                </div>
                                <Input
                                  onChange={(e: any) => handleQuantityChange(e, ticket)}
                                  value={addTicketState.ticketsToUpdate.get(ticket.id) || ""}
                                  type="number"
                                  disabled={!productPermissions?.products_edit}
                                />
                                &nbsp;&nbsp;
                                <button
                                  id={ticket.id}
                                  onClick={(e: any) => {
                                    if (productPermissions?.products_edit) {
                                      void handleDeleteTicket(e, ticket);
                                    }
                                  }}
                                >
                                  X - Remove
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="add-ticket-button-container">
                        <button
                          className="add-ticket-button"
                          onClick={() => {
                            if (productPermissions?.products_edit) {
                              void openAddTicket();
                            }
                          }}
                        >
                          Add Ticket
                        </button>
                      </div>
                    </FormLayout.Group>
                  </FormLayout>
                </Card.Section>
              </Card>
            ) : null}

            <Card title="Inventory" collapsable>
              {productState.variant_count === 1 ? (
                <Card.Section>
                  <FormLayout>
                    <FormLayout.Group>
                      <Input
                        value={productState.barcode || ""}
                        label="Barcode"
                        id="barcode"
                        onChange={handleInputChange}
                        disabled={!productPermissions?.products_edit}
                      />
                      <Input
                        value={productState.sku || ""}
                        label="Sku"
                        id="sku"
                        onChange={handleInputChange}
                        disabled={!productPermissions?.products_edit}
                      />
                      <div className="product-inventory-track">
                        <Checkbox
                          id="track_inventory"
                          size="medium"
                          value={productState.track_inventory}
                          checked={productState.track_inventory}
                          onChange={handleCheckboxChange}
                          label="Track Inventory"
                          disabled={!productPermissions?.products_edit}
                        />
                      </div>
                    </FormLayout.Group>
                  </FormLayout>
                </Card.Section>
              ) : (
                <Card.Section>
                  <Checkbox
                    id="track_inventory"
                    size="medium"
                    value={productState.track_inventory}
                    checked={productState.track_inventory}
                    onChange={handleCheckboxChange}
                    label="Track Inventory"
                    disabled={!productPermissions?.products_edit}
                  />
                </Card.Section>
              )}
              <Card.Section
                title={"Courses"}
                subtitle={"What courses have access to sell this product"}
                sectionTitleActions={
                  productState.variant_count === 1
                    ? [
                        { content: "Update All Prices", action: () => displayUpdateAllPrices("prices") },
                        { content: "Update All Costs", action: () => displayUpdateAllPrices("costs") },
                      ]
                    : null
                }
              >
                <DataTable
                  columns={[
                    {
                      label: "All Facilities",
                      content: (
                        <Checkbox
                          size="small"
                          label="All Facilities"
                          checked={filterState.selectAllFacilities}
                          onChange={handleSelectAllFacilities}
                        />
                      ),
                    },
                    ...(productState?.variant_count === 1
                      ? [
                          { label: "Price", width: "30%" },
                          { label: "Cost", width: "30%" },
                          { label: "Qty", width: "10%" },
                          { label: "", width: "5%" },
                        ]
                      : []),
                  ]}
                  loading={productState === undefined}
                >
                  {productState.inventory_levels?.map((level: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td>
                          <Checkbox
                            id="has_access"
                            size="medium"
                            value={level.has_access || false}
                            checked={level.has_access || false}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInventoryAccessChange(e, index)}
                            label={level.facility.long_name}
                            disabled={!productPermissions?.products_edit}
                          />
                        </td>
                        {productState.variant_count === 1 ? (
                          <>
                            <td>
                              <Input
                                value={level?.price_override || ""}
                                id="price_override"
                                labelHidden
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  handleInventoryInputChange(e, index)
                                }
                                prefix="$"
                                type="number"
                                disabled={!productPermissions?.products_edit}
                              />
                            </td>
                            <td>
                              <Input
                                value={level?.cost_override || ""}
                                id="cost_override"
                                labelHidden
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  handleInventoryInputChange(e, index)
                                }
                                prefix="$"
                                type="number"
                                disabled={!productPermissions?.products_edit}
                              />
                            </td>
                            <td className="product-inventory-level-table-data-text">{level.quantity_available || 0}</td>
                            <td
                              className="product-inventory-level-table-button"
                              onClick={() => initiateUpdateInventory(level)}
                            >
                              Adjust
                            </td>
                          </>
                        ) : null}
                      </tr>
                    );
                  })}
                </DataTable>
              </Card.Section>
            </Card>

            {console.log("Prouduct state", productState)}
            {productState.variant_count === 1 ? (
              <Card title="Taxes" collapsable defaultCollapsed>
                <Card.Section>
                  <FormLayout>
                    <FormLayout.Group>
                      <Checkbox
                        id="taxable"
                        size="medium"
                        value={productState.taxable}
                        checked={productState.taxable}
                        onChange={handleCheckboxChange}
                        label="Charge Taxes"
                        disabled={!productPermissions?.products_edit}
                      />
                    </FormLayout.Group>
                  </FormLayout>
                </Card.Section>

                <DataTable
                  columns={[
                    { label: "Facility", width: "60%" },
                    { label: "Tax Rates", width: "30%" },
                    { label: "", width: "10%" },
                  ]}
                  loading={taxState === undefined}
                >
                  {productState.inventory_levels?.map((level: IInventoryLevel, index: number) => {
                    return (
                      <tr key={index}>
                        <td>{level.facility.long_name}</td>
                        <td>
                          {level.tax_lines?.map((tax_line: Record<string, any>, taxIndex: number) => {
                            return <p key={taxIndex}>{tax_line.name} </p>;
                          })}
                        </td>
                        <td>
                          <a onClick={() => initiateUpdateTaxes(level.tax_lines, level, index)}>Edit</a>
                        </td>
                      </tr>
                    );
                  })}
                </DataTable>
              </Card>
            ) : null}

            <Card title="Pricing / Product Lock" collapsable>
              <Card.Section>
                <FormLayout>
                  <FormLayout.Group>
                    <Toggle
                      id={"price_locked"}
                      labelTop={"Lock Pricing"}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePricingLockAllChange(e)}
                      checked={productState.price_locked}
                      disabled={!productPermissions?.products_edit}
                    ></Toggle>

                    <Toggle
                      id={"cannot_be_sold"}
                      labelTop={"Cannot Be Sold"}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleProductNotSoldAllChange(e)}
                      checked={productState.cannot_be_sold}
                      disabled={!productPermissions?.products_edit}
                    ></Toggle>
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
              <Card.Section table="true">
                <table className="ui-table ui-table-condensed">
                  <thead>
                    <tr>
                      <th>Facility</th>
                      <th>Lock Pricing</th>
                      <th>Cannot be Sold</th>
                    </tr>
                  </thead>

                  <tbody>
                    {productState.inventory_levels?.map((level: any, index: number) => {
                      return (
                        <tr key={index}>
                          <>
                            <td>{level.facility.long_name}</td>

                            <td>
                              <Toggle
                                id="price_locked"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  handlePricingLockStoreChange(e, index)
                                }
                                checked={level.price_locked}
                                disabled={!productPermissions?.products_edit}
                              ></Toggle>
                            </td>

                            <td>
                              {level.has_access && (
                                <Toggle
                                  id="cannot_be_sold"
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    handleProductNotSoldStoreChange(e, index)
                                  }
                                  checked={level.cannot_be_sold}
                                  disabled={!productPermissions?.products_edit}
                                ></Toggle>
                              )}
                            </td>
                          </>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Card.Section>
            </Card>
          </Page.Section>

          <Page.Section oneThird>
            <Card
              title="Sales Channels"
              // collapsable={{
              //   open: cardStates.salesChannels,
              //   setOpen: () => setCardStates(prev => ({ ...prev, salesChannels: !cardStates.salesChannels })),
              // }}
            >
              <Card.Section>
                <FormLayout>
                  <FormLayout.Group>
                    <div>
                      <Toggle
                        id="available_on_toggle"
                        labelTop="Set Available On Date / Time"
                        onChange={handleAvailableOnUntilToggle}
                        checked={productState.available_on_toggle}
                        disabled={!productPermissions?.products_edit}
                      ></Toggle>
                    </div>
                  </FormLayout.Group>

                  <FormLayout.Group>
                    {productState.available_on_toggle ? (
                      <div>
                        <div style={{ width: "75%" }}>
                          {/* <label htmlFor="available_on_date">Available On</label> */}
                          <div id="available_on_date">
                            <DatePickerInput
                              months={1}
                              position={"left"}
                              label="Available On"
                              startingDate={availableOnState.selectedDate}
                              setStartingDate={(selectedDate: any) => {
                                if (productPermissions?.products_edit) {
                                  handleAvailableOnDateSelectorChange(selectedDate);
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: "10px",
                            width: "75%",
                            alignItems: "center",
                            marginBottom: "20px",
                          }}
                        >
                          <TimePick
                            value={productState.available_on_time}
                            onChange={timeString => handleTimeAvailability("available_on_time", timeString)}
                            disabled={!productPermissions?.products_edit}
                            align="right"
                            status={!availableOnDateIsValid() ? "error" : undefined}
                            // status={new Date(`${productState?.available_on_date} ${productState?.available_on_time}`) < new Date() ? "error" : undefined}
                          />

                          <label style={{ marginLeft: "5px", marginBottom: "0px" }} htmlFor="">
                            {filterState.timeZone}
                          </label>
                        </div>
                      </div>
                    ) : null}
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <div>
                      <Toggle
                        id="unavailable_on_toggle"
                        labelTop="Set Available Until Date / Time"
                        onChange={handleAvailableOnUntilToggle}
                        checked={productState.unavailable_on_toggle}
                        disabled={!productPermissions?.products_edit}
                      ></Toggle>
                    </div>
                  </FormLayout.Group>

                  <FormLayout.Group>
                    {productState.unavailable_on_toggle ? (
                      <div>
                        <div style={{ width: "75%" }}>
                          {/* <label htmlFor="unavailable_on_date">Available Until</label> */}
                          <div id="unavailable_on_date">
                            <DatePickerInput
                              months={1}
                              position={"left"}
                              label={"Available Until"}
                              startingDate={availableUntilState.selectedDate}
                              setStartingDate={(selectedDate: any) => {
                                if (productPermissions?.products_edit) {
                                  handleAvailableUntilDateSelectorChange(selectedDate);
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div style={{ display: "flex", marginTop: "10px", width: "75%", alignItems: "center" }}>
                          <TimePick
                            value={productState.unavailable_on_time}
                            onChange={timeString => handleTimeAvailability("unavailable_on_time", timeString)}
                            disabled={!productPermissions?.products_edit}
                            align="right"
                            status={
                              new Date(`${productState.unavailable_on_date} ${productState.unavailable_on_time}`) <
                                new Date(`${productState.available_on_date} ${productState.available_on_time}`) ||
                              productState.unavailable_on_time === null
                                ? "error"
                                : undefined
                            }
                          />

                          <label style={{ marginLeft: "5px", marginBottom: "0px" }} htmlFor="">
                            {filterState.timeZone}
                          </label>
                        </div>
                      </div>
                    ) : null}
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
            </Card>
          </Page.Section>
        </Page>

        <Popup
          okText="Continue"
          onCancel={() => setArchiveProductOpen(false)}
          onOk={archiveProduct}
          title="Archive Product"
          type="warning"
          description="Archiving a product removes it from being sold by facilities. It is still accessible in reporting and
          can be restored. Are you sure you want to archive this product?"
          open={archiveProductOpen}
        />

        <Popup
          okText="Duplicate"
          onCancel={() => setDuplicateProductOpen(false)}
          onOk={duplicateProduct}
          title={"Duplicate Product"}
          type="warning"
          description={"Are you sure you want to duplicate this product?"}
          open={duplicateProductOpen}
        />

        {/* Combo Product Sheet - Handles adding product to product */}

        <Portal isMounted={comboProductState.comboProductsActive}>
          <AttachProductAsComboModal
            isVisible={comboProductState.comboProductsActive}
            currentlyAddedProducts={comboProductState.currentAddedProducts}
            userLevel={"client"}
            onCancel={closeComboProduct}
            onOk={productId => handleAddProduct(productId)}
          />
        </Portal>

        {/* Add Ticket Sheet - Handles adding ticket to product */}
        <Sheet
          open={addTicketState.addTicketActive}
          title={"Add Ticket"}
          size="small"
          closable
          onCancel={closeAddTicket}
          onOk={closeAddTicket}
          cancelText={"Cancel"}
          backDropCancel={false}
        >
          <hr />
          <div>
            {addTicketState.allTickets?.map((ticket: any, index: number) => {
              let disabled;
              let cursorOption;

              for (let i = 0; i < addTicketState.currentAddedTickets.length; i++) {
                if (ticket.id === addTicketState.currentAddedTickets[i].id) {
                  disabled = true;
                  cursorOption = "default";
                }
              }

              return (
                <div key={index}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div className="ticket-display-add-ticket">
                      <p className="ticket-display-text-sheet-title">{ticket.title}</p>

                      <p className="ticket-display-text-sheet-included">{ticket.included}</p>
                    </div>

                    <button
                      disabled={disabled}
                      style={{ cursor: cursorOption }}
                      id={ticket.id}
                      onClick={(e: any) => handleAddTicket(e, ticket)}
                    >
                      Add Ticket
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </Sheet>

        <Sheet
          open={inventoryState.updateInventoryActive}
          size="small"
          closable
          title={"Update Inventory"}
          onOk={completeUpdateInventory}
          cancelText={"Cancel"}
          okText={"Update Inventory ✓"}
          onCancel={closeUpdateInventory}
        >
          <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange}>
            {selectedTab === 0 ? (
              <>
                <Input
                  label="New Quantity"
                  value={inventoryState.newQuantity || ""}
                  type="number"
                  id="newQuantity"
                  onChange={handleInventoryQuantityChange}
                  disabled={!productPermissions?.products_edit}
                />
              </>
            ) : null}
            {selectedTab === 1 ? <div className="flex flex-column">Inventory Log</div> : null}
          </Tabs>
        </Sheet>

        {/* Update the price or cost for all facilities */}
        <Sheet
          open={updatePrices.displayPricesModal}
          title={`Update All ${capitalize(updatePrices.type)}`}
          size="small"
          closable
          onCancel={() => setUpdatePrices(prevState => ({ ...prevState, displayPricesModal: false, newPrice: "" }))}
          onOk={updateAllPriceCost}
          cancelText={"Cancel"}
          backDropCancel={false}
        >
          <Input
            value={updatePrices.newPrice}
            labelHidden
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAllPricesInputChange(e)}
            prefix="$"
            type="number"
            disabled={!productPermissions?.products_edit}
            onKeyDown={submitKeyboardEvent}
          />
        </Sheet>

        <Sheet
          open={filterState.openNewVariant}
          title={"New Variant Product Option"}
          size="small"
          closable
          onCancel={() => setFilterState(prevState => ({ ...prevState, openNewVariant: false }))}
          onOk={handleAddNewProductOption}
          cancelText={"Cancel"}
          backDropCancel={false}
        >
          <div className="mt-4">
            <Input
              value={filterState.productOption}
              label={"Enter Product Option"}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleProductOptionInput(e)}
              type="text"
              id={"productOption"}
            />
          </div>

          <div className="mt-4">
            <Input
              value={filterState.optionValue || ""}
              label={filterState.productOption}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleProductOptionInput(e)}
              type="text"
              id={"optionValue"}
            />
          </div>

          <div className="mt-4">
            <Input
              className={priceIsValid(filterState.optionPrice ?? "") ? "border-2 border-red-600" : ""}
              prefix="$"
              value={filterState.optionPrice || ""}
              label={"Enter Product Price"}
              id="optionPrice"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleProductOptionInput(e)}
              placeholder=""
              // readOnly={!productPermissions?.products_edit || !productPermissions?.products_edit_cost}
            />
          </div>
        </Sheet>

        <Portal isMounted={comboProductState.updateComboProductsOpen}>
          <UpdateProductAsComboModal
            isVisible={comboProductState.updateComboProductsOpen}
            onCancel={() =>
              setComboProductState(prevState => ({
                ...prevState,
                updateComboProductsOpen: false,
                selectedComboVariant: null,
              }))
            }
            onOk={(comboId, cost, price) => handleUpdateComboProductModalOk(comboId, cost, price)}
            combo={comboProductState.selectedComboVariant}
            handleUpdates={updateEditComboProductModal}
          />
        </Portal>

        <Portal isMounted={taxState.updateTaxLinesActive}>
          <Sheet
            open={taxState.updateTaxLinesActive}
            title={"Taxes"}
            size="medium"
            closable
            onOk={updateTaxLines}
            onCancel={closeUpdateTaxes}
            cancelText={"Cancel"}
            backDropCancel={true}
          >
            <div className="edit-tax-lines_container">
              <div className="edit-tax-lines_title_container">
                <p className="edit-tax-lines_title">{productState.variants[0].title}</p>
                <Checkbox
                  id="taxable"
                  size="medium"
                  value={productState.variants[0]?.taxable}
                  checked={productState.variants[0]?.taxable}
                  onChange={e =>
                    setProductState(prev => ({
                      ...prev,
                      variants: prev.variants.map((vari, checkBoxIndex) => {
                        return { ...vari, taxable: !vari.taxable };
                      }),
                    }))
                  }
                  label={"Charge Taxes"}
                  disabled={!authStore.user?.permissions?.products_edit}
                />
              </div>

              <div className="edit-tax-lines_single_variant_container">
                <TagInputSelect
                  tags={taxState.selectedTaxLines?.filter(lines =>
                    taxState.selectedInventoryLevel?.tax_lines?.map((line: ITaxLines) => {
                      return { name: `${lines.name} (${lines.percentage}%)`, id: lines.id };
                    }),
                  )}
                  selectOptions={taxState.availableTaxLines
                    ?.filter(
                      (line: TTaxLines) =>
                        taxState.selectedTaxLines?.findIndex(
                          (taxLine: { id: string | number }) => Number(taxLine.id) === Number(line.id),
                        ) < 0,
                    )
                    ?.map((line: TTaxLines) => {
                      return {
                        name: `${line.name} (${line.percentage}%)`,
                        id: line.id,
                        extraValue: line,
                      };
                    })}
                  onChange={(value, line) => {
                    void handleTaxDropdown(Number(value), line);
                  }}
                  onTagClick={taxLineId => handleRemoveTaxLine(taxLineId)}
                  className="edit-tax-lines_single_variant_container_tag_input"
                  helpText={"Select tax lines to add to this variant"} // TODO: Translation
                />
              </div>
            </div>
          </Sheet>
        </Portal>
      </>
    )
  );
}
