import { IOptions } from "components/sideMenu/SideMenu";
import { useTranslation, Trans } from "react-i18next";

/***
Child suboptions can be passed to created futher settings options levels. Add subOptions to
the subOption of the parent as well as prop secondOptionHeader for styling purposes. 

label: string
url: string
subOptions?: Array< {
                    label: string;
                    url: string;
                    subOptions?: Array
                    secondOptionHeader?: boolean; }>
*/

export default function SettingOptionsTranslation() {
  const { t, i18n } = useTranslation();

  const options: IOptions[] = [
    {
      label: t("secure.facility.settings.settings_options.001"),
      url: "/admin/settings", //Temporary route
      subOptions: [
        {
          label: t("secure.facility.settings.settings_options.003"),
          url: "/admin/settings/language",
        },
      ],
    },
    {
      label: t("secure.facility.settings.settings_options.004"),
      url: "/admin/settings/products", //Temporary route
      subOptions: [
        {
          label: t("secure.facility.settings.settings_options.005"),
          url: "/admin/settings/folders",
        },
        {
          label: t("secure.facility.settings.settings_options.006"),
          url: "/admin/settings/discount",
        },
        {
          label: t("secure.facility.settings.settings_options.007"),
          url: "/admin/settings/modifier_group",
        },
        {
          label: t("secure.facility.settings.settings_options.008"),
          url: "/admin/settings/departments",
        },
        {
          label: t("secure.facility.settings.settings_options.052"),
          url: "/admin/settings/forms",
        },
        {
          label: t("secure.facility.settings.settings_options.053"),
          url: "/admin/settings/vendors",
        },
        {
          label: t("secure.facility.settings.settings_options.054"),
          url: "/admin/settings/types",
        },
        {
          label: "Groups", //TODO: Translate
          url: "/admin/settings/groups",
        },
        {
          label: t("secure.facility.settings.settings_options.057"),
          secondOptionHeader: true,
          url: "/admin/settings/inventory",
          subOptions: [
            {
              label: t("secure.facility.settings.settings_options.058"),
              url: "/admin/settings/inventory",
            },
            {
              label: t("secure.facility.settings.settings_options.059"),
              url: "/admin/settings/transfer",
            },
            {
              label: t("secure.facility.settings.settings_options.068"),
              url: "/admin/settings/counts",
            },
            {
              label: t("secure.facility.settings.settings_options.069"),
              url: "/admin/settings/labels",
            },
          ],
        },
      ],
    },
    {
      label: t("secure.facility.settings.settings_options.009"),
      url: "",
      subOptions: [
        {
          label: t("secure.facility.settings.settings_options.010"),
          url: "/admin/settings/details",
        },
        {
          label: t("secure.facility.settings.settings_options.060"),
          url: "/admin/settings/course",
        },
        {
          label: t("secure.facility.settings.settings_options.048"),
          url: "/admin/settings/division",
        },
        {
          label: t("secure.facility.settings.settings_options.049"),
          url: "/admin/settings/tee_deck",
        },
        {
          label: t("secure.facility.settings.settings_options.011"),
          url: "/admin/settings/staff",
        },
        {
          label: t("secure.facility.settings.settings_options.046"),
          url: "/admin/settings/printers",
        },

        {
          label: t("secure.facility.settings.settings_options.070"),
          url: "/admin/settings/email-template",
        },
      ],
    },

    {
      label: t("secure.facility.settings.settings_options.061"),
      url: "",
      subOptions: [
        {
          label: t("secure.facility.settings.settings_options.062"),
          url: "/admin/settings/customer_tags",
        },
        {
          label: t("secure.facility.settings.settings_options.002"),
          url: "/admin/settings/customer_types",
        },
      ],
    },

    {
      label: t("secure.facility.settings.settings_options.012"),
      url: "/admin/settings/register",
      subOptions: [
        {
          label: t("secure.facility.settings.settings_options.013"),
          url: "/admin/settings/registers",
        },
        {
          label: t("secure.facility.settings.settings_options.014"),
          url: "/admin/settings/register-group",
        },
        {
          label: t("secure.facility.settings.settings_options.015"),
          url: "/admin/settings/cash-out",
        },

        {
          label: t("secure.facility.settings.settings_options.043"),
          url: "/admin/settings/shifts",
        },
      ],
    },

    {
      label: t("secure.facility.settings.settings_options.016"),
      url: "/admin/settings/table-service",
      subOptions: [
        // {
        //   label: t("secure.facility.settings.settings_options.017"),
        //   url: "/admin/settings/tables",
        // },
        {
          label: t("secure.facility.settings.settings_options.018"),
          url: "/admin/settings/floor-plan",
        },
        {
          label: t("secure.facility.settings.settings_options.019"),
          url: "/admin/settings/kitchen-location",
        },
        {
          label: t("secure.facility.settings.settings_options.050"),
          url: "/admin/settings/courses",
        },

        {
          label: t("secure.facility.settings.settings_options.042"),
          url: "/admin/kds", //Temporary route
        },
      ],
    },

    {
      label: t("secure.facility.settings.settings_options.020"),
      url: "/admin/settings/tee-sheet",
      subOptions: [
        {
          label: t("secure.facility.settings.settings_options.021"),
          url: "/admin/settings/tee-sheet/general",
        },
        {
          label: t("secure.facility.settings.settings_options.022"),
          url: "/admin/settings/tee-sheet/booking-engine",
        },
        {
          label: t("secure.facility.settings.settings_options.055"),
          url: "/admin/settings/tee-sheet/booking-fees",
        },
        {
          label: t("secure.facility.settings.settings_options.023"),
          url: "/admin/settings/tee-sheet/template",
        },
        {
          label: t("secure.facility.settings.settings_options.063"),
          url: "/admin/settings/tee-sheet/calendar",
        },
        {
          label: t("secure.facility.settings.settings_options.025"),
          url: "/admin/settings/tee-sheet/pricing-sheet",
        },
        {
          label: t("secure.facility.settings.settings_options.026"),
          url: "/admin/settings/tee-sheet/check-in-config",
        },
        {
          label: t("secure.facility.settings.settings_options.027"),
          url: "/admin/settings/tee-sheet/shotgun-templates",
        },
        {
          label: t("secure.facility.settings.settings_options.028"),
          url: "/admin/settings/tee-sheet/customer-type-config",
        },
        {
          label: t("secure.facility.settings.settings_options.029"),
          url: "/admin/settings/tee-sheet/notification",
        },
        {
          label: t("secure.facility.settings.settings_options.040"),
          url: "/admin/settings/cart-waiver",
        },
        {
          label: t("secure.facility.settings.settings_options.064"),
          url: "/admin/settings/cart-inventory",
        },
        {
          label: t("secure.facility.settings.settings_options.065"),
          url: "/admin/settings/tee-sheet/booking-categories",
        },
      ],
    },

    {
      label: t("secure.facility.settings.settings_options.030"),
      url: "",
      subOptions: [
        {
          label: t("secure.facility.settings.settings_options.031"),
          url: "/admin/settings/payments/manage",
        },
        {
          label: t("secure.facility.settings.settings_options.032"),
          url: "/admin/settings/card_reader",
        },
        {
          label: t("secure.facility.settings.settings_options.033"),
          url: "/admin/settings/payouts",
        },
        {
          label: t("secure.facility.settings.settings_options.034"),
          url: "/admin/settings/bank_accounts",
        },
        {
          label: t("secure.facility.settings.settings_options.035"),
          url: "/admin/settings/facility-payment-options",
        },
      ],
    },

    {
      label: t("secure.facility.settings.settings_options.036"),
      url: "",
      subOptions: [
        {
          label: t("secure.facility.settings.settings_options.067"),
          url: "/admin/settings/taxes",
        },

        {
          label: t("secure.facility.settings.settings_options.038"),
          url: "/admin/settings/accounting_references",
        },

        {
          label: t("secure.facility.settings.settings_options.039"),
          url: "/admin/settings/accounts",
        },
        {
          label: t("secure.facility.settings.settings_options.051"),
          url: "/admin/settings/prizeAccounts",
        },
      ],
    },
    // {
    //   label: t("secure.facility.settings.settings_options.041"),
    //   url: "/admin/settings/dashboards",
    // },

    {
      label: t("secure.facility.settings.settings_options.044"),
      url: "/admin/settings/reservations",

      subOptions: [
        {
          label: t("secure.facility.settings.settings_options.045"),
          url: "/admin/settings/reservations/modules",
        },
        {
          label: t("secure.facility.settings.settings_options.056"),
          url: "/admin/settings/reservations/templates",
        },
      ],
    },
  ];

  return options;
}
