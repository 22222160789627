// import { ICustomer } from "./models/customer";

export interface IClientState {
  isLoaded: boolean;
  error?: any;
  client?: any;
}

export interface IClientAction {
  type: string;
  payload: any;
}

const initialState: IClientState = {
  isLoaded: false,
  error: null,
  client: null,
};

export default function ClientReducer(state = initialState, action: IClientAction) {
  switch (action.type) {
    case "client.update":
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
