import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetBookingEngine } from "api/rpc/2022-09/facilityAdmin/teesheet/bookingEngine";

import { showError } from "redux/actions/ui";
import { IBookingEngine } from "redux/reducers/bookingEngine";
import { convertTime } from "helpers/Helpers";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import { Badge } from "components/badge/Badge";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

export default function BookingEngines() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [bookingEngines, setBookingEngines] = useState<IBookingEngine[]>(undefined);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadBookingEngines(source.token);
    return () => source.cancel();
  }, []);

  async function loadBookingEngines(token?: CancelToken) {
    if (bookingEngines !== undefined) {
      setBookingEngines(undefined);
    }

    const res = await GetBookingEngine({ extended: true }, token ? false : true);
    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading booking engines.")); // TODO: Translation
    }

    setBookingEngines(res.status !== StatusCode.OK ? [] : res.data);
  }

  return (
    <Page title={t("secure.facility.settings.tee_sheets.booking_engines.002")} narrow>
      <DataTable
        columns={[
          { label: t("secure.facility.settings.tee_sheets.booking_engines.003") },
          { label: t("secure.facility.settings.tee_sheets.booking_engines.004") },
          { label: t("secure.facility.settings.tee_sheets.booking_engines.005") },
          { label: t("secure.facility.settings.tee_sheets.booking_engines.006") },
          { label: t("secure.facility.settings.tee_sheets.booking_engines.009") },
        ]}
        loading={bookingEngines === undefined}
      >
        {bookingEngines?.map(engine => (
          <tr
            key={engine.id}
            className="clickable"
            onClick={() => history.push(`/admin/settings/tee-sheet/booking-engine/${engine.id}`)}
          >
            <td>{engine.title}</td>
            <td>
              {engine.booking_window.days_in_advance_start}{" "}
              {t("secure.facility.settings.tee_sheets.booking_engines.007")} -{" "}
              {engine.booking_window.days_in_advance_end} {t("secure.facility.settings.tee_sheets.booking_engines.008")}
            </td>
            <td>{convertTime(engine.booking_window.open_time)}</td>
            <td>{engine.booking_window.open_structure}</td>
            <td className="flex flex-wrap gap-2">
              {engine?.booking_categories?.map(category => (
                <Badge key={category.id} type="gray" size="small">
                  {category.title}
                </Badge>
              ))}
            </td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
