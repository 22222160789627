import Card from "components/card/Card";
import React, { useEffect, useState } from "react";
import "./Payment.scss";
import { useHistory, useParams } from "react-router-dom";
import { ScrollLocation } from "./Checkout";
import { ICartState } from "redux/reducers/customer/cart";
import { ICartActions } from "redux/actions/customer/cart";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { IUIActions } from "redux/actions/ui";
import { PostCheckout } from "api/rpc/guest/cart";
import { StatusCode } from "api/protocols";
import { PostTransaction, PutCapture } from "api/rpc/guest/transaction";
import { GetFacility } from "api/rpc/guest/facility";
import { IFacility } from "../models/facility";
import { StorePage } from "./StorePage/StorePage";
import { IGiftCard, PutCompleteOrder } from "api/rpc/guest/order";
import OrderSummary from "components/OrderSummary/OrderSummary";
import PaymentSummary from "components/PaymentSummary/PaymentSummary";
import { ICart } from "redux/reducers/customer/models/cart";
import { getOnlineStoreCartToken, getOnlineStoreCartTokenName } from "./HomePage";
import { useTranslation } from "react-i18next";
import { IActiveFormResponse, IOnlineStoreState } from "redux/reducers/customer/onlineStore";
import { IOnlineStoreActions } from "redux/actions/customer/onlineStore";
import { GetFormResponse, GetForms, IForm, IFormResponse, PutFormResponse } from "api/rpc/2022-09/guest/form";
import { cloneDeep, flatten, groupBy, range } from "lodash";
import { ButtonNew as Button } from "components/buttonNew";

interface IPaymentProps {
  onlineStoreStore: IOnlineStoreState;
  onlineStoreActions: IOnlineStoreActions;
  cartStore: ICartState;
  cartActions: ICartActions;
  uiActions: IUIActions;
}

interface IPaymentInformation {
  facility: IFacility;
  cardElementComplete: boolean;
}

interface IOrderPreviousAttempt {
  id: number;
  token: string;
  amount: number;
}

export default function Payment(props: IPaymentProps) {
  const { facilityShortName } = useParams<{ facilityShortName: string }>();

  const { onlineStoreStore, onlineStoreActions, cartStore, cartActions, uiActions } = props;

  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [paymentInformation, setPaymentInformation] = useState<IPaymentInformation>({
    facility: undefined,
    cardElementComplete: false,
  });

  const [orderPreviousAttempt, setOrderPreviousAttempt] = useState<IOrderPreviousAttempt>({
    id: undefined,
    token: undefined,
    amount: undefined,
  });

  const [paymentInProgress, setPaymentInProgress] = useState<boolean>(false);

  const [giftCards, setGiftCards] = useState<Array<IGiftCard>>(null);

  function navigateToHomePage() {
    history.push(`/online-store/${facilityShortName}`);
  }

  function navigateToConfirmation(orderToken: string) {
    history.push(`/online-store/${facilityShortName}/confirmation/${orderToken}`);
  }

  function navigateToCheckout() {
    history.push(`/online-store/${facilityShortName}/checkout`);
  }

  function navigateToCheckoutLocation(location: ScrollLocation) {
    history.push(`/online-store/${facilityShortName}/checkout/${location}`);
  }

  function navigateToForms() {
    history.push(`/online-store/${facilityShortName}/forms`);
  }

  function onBeforeUnload(event: any) {
    if (cartStore.cart.status === "complete") {
      event.preventDefault();
      return (event.returnValue = "");
    }
  }

  useEffect(() => {
    window.addEventListener("beforeunload", onBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [cartStore.cart]);

  useEffect(() => {
    void loadPayment();
  }, []);

  async function loadPayment() {
    uiActions.enqueue();

    try {
      const facilityRes = await GetFacility({ short_name: facilityShortName }, false);
      const facilityId = facilityRes?.data?.[0]?.id;

      if (facilityRes.status !== StatusCode.OK || facilityId === undefined) {
        uiActions.dequeue();
        uiActions.showError(t("guest.online_store.payment.013"));
        return;
      }

      const facility = facilityRes.data[0];

      let cart: ICart = undefined;

      if (getOnlineStoreCartToken(facilityShortName)) {
        try {
          const getCartPromise: Promise<ICart> = cartStore?.isLoaded
            ? Promise.resolve(cartStore?.cart)
            : (cartActions.loadCart(
                {
                  facility_id: facilityId,
                  tokenName: getOnlineStoreCartTokenName(facilityShortName),
                  token: getOnlineStoreCartToken(facilityShortName),
                },
                false,
              ) as unknown as Promise<ICart>);

          cart = await getCartPromise;
        } catch {
          uiActions.dequeue();
          uiActions.showError(t("guest.online_store.payment.014"));
          return;
        }
      } else {
        uiActions.dequeue();
        navigateToHomePage();
        return;
      }

      if (cart === undefined || cart.line_items.length === 0) {
        uiActions.dequeue();
        navigateToHomePage();
        return;
      } else if (cart.status === "complete") {
        uiActions.dequeue();
        cancelTransaction();
        return;
      } else if (!cart.customer || !cart.billing_address_line) {
        uiActions.dequeue();
        navigateToCheckout();
        return;
      }

      void handleSetGiftCards(cart);

      if (!onlineStoreStore.activeFormResponses) {
        const variant_ids = cart.line_items.map(lineItem => lineItem.variant_id);
        const getFormsRes = await GetForms({ client_id: facility.client_id, variant_ids, inputs: true }, false);

        if (getFormsRes.status !== StatusCode.OK) {
          uiActions.dequeue();
          uiActions.showError(t("guest.online_store.payment.015"));
          return;
        }

        const getFormResponseRes = await GetFormResponse({ client_id: facility.client_id, cart_id: cart.id }, false);

        if (getFormResponseRes.status !== StatusCode.OK) {
          uiActions.dequeue();
          uiActions.showError(t("guest.online_store.payment.016"));
          return;
        }

        const forms: IForm[] = [];

        const formsForEachVariant = groupBy(getFormsRes.data, data => data.variant_id);

        for (const variantId in formsForEachVariant) {
          const variantForms = formsForEachVariant[variantId].map(variantFormContainer => variantFormContainer.form);

          const associatedLineItem = cart.line_items.find(lineItem => lineItem.variant_id === Number(variantId));

          if (associatedLineItem) {
            const duplicatedForms = range(0, associatedLineItem.quantity).map(() => cloneDeep(variantForms));
            const flattenedDuplicatedForms = flatten(duplicatedForms);
            forms.push(...flattenedDuplicatedForms);
          }
        }

        const formResponses = getFormResponseRes.data;
        const activeFormResponses: IActiveFormResponse[] = [];

        if (forms.length === 0) {
          onlineStoreActions.updateActiveFormResponses([]);
        } else {
          forms.forEach(form => {
            const formResponseIndex = formResponses.findIndex(formResponse => formResponse.form_id === form.id);

            if (formResponseIndex === -1) {
              uiActions.dequeue();
              uiActions.showError(t("guest.online_store.payment.017"));
              navigateToForms();
              return;
            }

            const formResponse: IFormResponse = formResponses.splice(formResponseIndex, 1)[0];
            activeFormResponses.push({ id: formResponse.id });
          });

          onlineStoreActions.updateActiveFormResponses(activeFormResponses);
        }
      }

      setPaymentInformation(prevState => ({
        ...prevState,
        facility,
      }));

      uiActions.dequeue();
    } catch {
      uiActions.dequeue();
    }
  }

  function handleSetGiftCards(cart: ICart) {
    const giftCards: Array<IGiftCard> = [];
    //Filter out line items to get gift cards only
    const filteredGiftCards = cart?.line_items?.filter(
      (filteredLineItem: Record<string, any>) => filteredLineItem?.product?.type === "Gift Card",
    );
    if (filteredGiftCards?.length > 0) {
      filteredGiftCards?.forEach(giftCard => {
        for (let i = 0; i < giftCard?.quantity; i++) {
          giftCards?.push({
            product_id: giftCard.product_id,
            cart_line_item_id: giftCard.id,
            code: null,
            pin: null,
            random: true,
            balance: giftCard.price,
            reload: 0,
          });
        }
      });
      setGiftCards(giftCards);
    }
  }

  const handleCardElementChange = (e: any) => {
    setPaymentInformation(prevState => ({ ...prevState, cardElementComplete: e.complete }));
  };

  function cancelTransaction() {
    cartActions.cartClear({ tokenName: getOnlineStoreCartTokenName(facilityShortName) });
    navigateToHomePage();
  }

  function handleCancelTransactionOnClick() {
    if (cartStore.cart.status === "complete") {
      cancelTransaction();
    } else if (onlineStoreStore.activeFormResponses?.length > 0) {
      navigateToForms();
    } else {
      navigateToCheckout();
    }
  }

  async function reviewYourOrder() {
    setPaymentInProgress(true);
    function reviewYourOrderError(errorMessage: string) {
      uiActions.dequeue();
      uiActions.showError(errorMessage);
      cartActions.loadCart(
        {
          facility_id: paymentInformation.facility.id,
          tokenName: getOnlineStoreCartTokenName(facilityShortName),
          token: getOnlineStoreCartToken(facilityShortName),
        },
        true,
      );
    }

    if (paymentInformation.cardElementComplete) {
      uiActions.enqueue();

      try {
        let orderId = orderPreviousAttempt.id;
        let orderToken = orderPreviousAttempt.token;
        let orderAmount = orderPreviousAttempt.amount;

        if (!orderId || !orderToken || !orderAmount) {
          const postCheckoutResponse = await PostCheckout({ cart_token: cartStore.cart.token }, false);
          const order = postCheckoutResponse.data?.order;

          if (postCheckoutResponse.status !== StatusCode.OK) {
            reviewYourOrderError(t("guest.online_store.payment.001"));
            setPaymentInProgress(false);
            return;
          }

          if (order?.id === undefined || order?.token === undefined || order?.token === null) {
            reviewYourOrderError(t("guest.online_store.payment.002"));
            setPaymentInProgress(false);
            return;
          }

          orderToken = order.token;
          orderAmount = order.total_price;
          orderId = order.id;

          setOrderPreviousAttempt(prev => ({
            ...prev,
            token: orderToken,
            amount: orderAmount,
            id: orderId,
          }));
        }

        try {
          await Promise.all(
            onlineStoreStore.activeFormResponses?.map(async activeFormResponse => {
              const putFormResponse = await PutFormResponse(
                { id: activeFormResponse.id, client_id: paymentInformation.facility.client_id, order_id: orderId },
                false,
              );

              if (putFormResponse.status !== StatusCode.OK) {
                throw new Error();
              }
            }),
          );
        } catch {
          reviewYourOrderError(t("guest.online_store.payment.018"));
          setPaymentInProgress(false);
          return;
        }

        const postTransactionResponse = await PostTransaction(
          {
            processing_type: "online",
            order_token: orderToken,
            kind: "authorization",
            source: "online",
            amount: orderAmount,
            payment_method: "card",
          },
          false,
        );

        const clientSecret = postTransactionResponse.data?.client_secret;

        if (postTransactionResponse.status !== StatusCode.OK || clientSecret === undefined || clientSecret === null) {
          reviewYourOrderError(t("guest.online_store.payment.003"));
          setPaymentInProgress(false);
          return;
        }

        if (!stripe || !elements) {
          reviewYourOrderError(t("guest.online_store.payment.004"));
          setPaymentInProgress(false);
          return;
        }

        const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
          },
        });

        if (stripeError) {
          reviewYourOrderError(stripeError.message);
          setPaymentInProgress(false);
          return;
        }

        const putCaptureResponse = await PutCapture(
          { payment_intent_id: paymentIntent.id, transaction_id: postTransactionResponse.data?.id },
          false,
        );

        if (putCaptureResponse.status !== StatusCode.OK) {
          reviewYourOrderError(t("guest.online_store.payment.005"));
          setPaymentInProgress(false);
          return;
        }

        const putCompleteOrder = await PutCompleteOrder({ token: orderToken, gift_cards: giftCards }, false);

        if (putCompleteOrder.status !== StatusCode.OK) {
          reviewYourOrderError(t("guest.online_store.payment.006"));
          setPaymentInProgress(false);
          return;
        }

        cartActions.cartClear({ tokenName: getOnlineStoreCartTokenName(facilityShortName) });
        onlineStoreActions.updateActiveFormResponses(null);

        uiActions.dequeue();
        navigateToConfirmation(orderToken);
      } catch {
        uiActions.dequeue();
        setPaymentInProgress(false);
      }
    } else {
      uiActions.showError(t("guest.online_store.payment.007"));
    }
    setPaymentInProgress(false);
  }

  function renderOrderSummary(togglableVisibility: boolean) {
    return (
      <OrderSummary
        subtotal={cartStore.cart.subtotal_price}
        taxLines={cartStore.cart.tax_lines}
        discount={cartStore.cart.total_discount}
        total={cartStore.cart.total_price}
        togglableVisibility={togglableVisibility}
        lineItems={cartStore.cart.line_items.map(lineItem => {
          return {
            src: lineItem.product_default_image?.source,
            productTitle: lineItem.product_title,
            variantTitle: lineItem.variant_title,
            quantity: lineItem.quantity,
            price: lineItem.subtotal_price,
          };
        })}
      />
    );
  }

  return (
    <div>
      {paymentInformation.facility &&
        onlineStoreStore.activeFormResponses?.length !== undefined &&
        cartStore.isLoaded &&
        cartStore.cart &&
        cartStore.cart.line_items.length > 0 &&
        cartStore.cart.customer &&
        cartStore.cart.billing_address_line && (
          <main>
            <StorePage>
              <div className="payment-page-sections">
                <section className="payment-order-summary-mobile-section">{renderOrderSummary(true)}</section>
                <section className="payment-information-section">
                  <Card>
                    <Card.Section>
                      <PaymentSummary
                        summaryData={[
                          {
                            label: t("guest.online_store.payment.008"),
                            values: [cartStore.cart.customer?.email],
                            editValues: () => navigateToCheckoutLocation("email"),
                          },
                          {
                            label: t("guest.online_store.payment.009"),
                            values: [
                              (cartStore.cart.billing_address_line?.first_name ?? "") +
                                " " +
                                (cartStore.cart.billing_address_line?.last_name ?? ""),
                              cartStore.cart.billing_address_line?.address_line_1 ?? "",
                              cartStore.cart.billing_address_line?.address_line_2 ?? "",
                              (cartStore.cart.billing_address_line?.city
                                ? cartStore.cart.billing_address_line?.city + ", "
                                : "") + (cartStore.cart.billing_address_line?.postal ?? ""),
                            ],
                            editValues: () => navigateToCheckoutLocation("address"),
                          },
                          ...(cartStore.cart?.customer_notes
                            ? [
                                {
                                  label: t("guest.online_store.payment.019"),
                                  values: [cartStore.cart.customer_notes],
                                  editValues: () => navigateToCheckoutLocation("notes"),
                                },
                              ]
                            : []),
                          ...(onlineStoreStore.activeFormResponses?.length > 0
                            ? [
                                {
                                  label: t("guest.online_store.payment.020"),
                                  values: [
                                    `${onlineStoreStore.activeFormResponses.length} ${
                                      onlineStoreStore.activeFormResponses.length === 1 ? "form" : "forms"
                                    } completed`,
                                  ],
                                },
                              ]
                            : []),
                        ]}
                        disableEdit={cartStore.cart.status === "complete"}
                      />
                    </Card.Section>
                  </Card>
                  <Card>
                    <Card.Section>
                      <div className="payment-information-section-card-title">
                        {t("guest.online_store.payment.010")}
                      </div>
                      <CardElement onChange={handleCardElementChange} options={{ hidePostalCode: true }} />
                    </Card.Section>
                  </Card>
                  <div className="payment-information-section-actions">
                    <Button block type="secondary" size="medium" onClick={handleCancelTransactionOnClick}>
                      {t("guest.online_store.payment.011")}
                    </Button>
                    <Button block type="primary" size="medium" onClick={reviewYourOrder} loading={paymentInProgress}>
                      {" "}
                      {t("guest.online_store.payment.012")}
                    </Button>
                  </div>
                </section>
                <section className="payment-order-summary-desktop-section">{renderOrderSummary(false)}</section>
              </div>
            </StorePage>
          </main>
        )}
    </div>
  );
}
