import React, { memo, useMemo } from "react";

import "./calendarMonth.scss";
import { MOBILE_WIDTH } from "helpers/ScreenSizes";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";

/**
 * Render out month-like grid structure display.
 * Returns render function to display customized JSX inside each grid item for specified month.
 */
export const CalendarMonth = memo(
  (props: { selectedDate: Date; renderGridItem: (dateOfMonth: number) => JSX.Element }) => {
    const { selectedDate, renderGridItem } = props;

    const windowSize = useWindowSize();
    const isMobile = windowSize.width <= MOBILE_WIDTH;

    const daysInSelectedMonth = useMemo(() => {
      if (selectedDate) {
        return 32 - new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 32).getDate();
      }
    }, [selectedDate]);

    const previousMonthDays = (date: Date) => {
      return new Date(date.getFullYear(), date.getMonth()).getDay();
    };
    const nextMonthDays = (date: Date) => {
      return 7 * 6 - (previousMonthDays(date) + daysInSelectedMonth);
    };

    return (
      <div className={`ui-calendar-grid-container${isMobile ? " mobile" : ""}`}>
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day, i) => {
          return (
            <div key={i} className="day-title">
              <p>{day}</p>
            </div>
          );
        })}

        {/* ENDING DAYS FROM PREV MONTH */}
        {Array.from(Array(previousMonthDays(selectedDate)).keys()).map(i => {
          return <div key={i} className="grid-item"></div>;
        })}

        {/* CALENDAR INFO */}
        {Array.from(Array(daysInSelectedMonth).keys()).map(i => {
          return renderGridItem(i + 1);
        })}

        {/* STARTING DAYS IN NEXT MONTH */}
        {Array.from(Array(nextMonthDays(selectedDate)).keys()).map(i => {
          return <div key={i} className="grid-item"></div>;
        })}
      </div>
    );
  },
);

CalendarMonth.displayName = "CalendarMonth";
