import React, { useEffect, useState } from "react";
import axios, { CancelToken } from "axios";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { StatusCode } from "api/protocols";
import { IBooking } from "redux/reducers/models/teetime";

import { capitalize, convertTime, valueToString } from "helpers/Helpers";

import Spin from "components/spin/spin";
import { ButtonNew as Button } from "components/buttonNew";

import "./portalBookings.scss";
import { useHistory } from "react-router";
import { Badge } from "components/badge/Badge";
import { useTranslation } from "react-i18next";
import { useGuestPortalContext } from "../GuestPortalContext";
import Callout from "components/callout/Callout";
import { GetBookingParticipant } from "api/rpc/2024-04/customer/booking";

type PortalReservationFilters = {
  limit: number;
  offset: number;
  extended_booking: boolean;
};

interface IFilterState {
  search: string;
}

export default function PortalBookings() {
  const { portalState } = useGuestPortalContext();
  const [bookings, setBookings] = useState<IBooking[]>([]);
  const [filter, setFilter] = useState<PortalReservationFilters>({
    limit: 15,
    offset: 0,
    extended_booking: true,
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    search: "",
  });

  const history = useHistory();
  const { t, i18n } = useTranslation();
  // Load bookings on first mount
  useEffect(() => {
    const source = axios.CancelToken.source();
    void getGuestBookings(source.token);

    return () => {
      source.cancel();
    };
  }, [filter]);

  async function getGuestBookings(token: CancelToken) {
    if (bookings !== null) {
      setBookings(null); // loader
    }

    const guestBookingRes = await GetBookingParticipant({ ...filter }, false, token);

    if (guestBookingRes.status === StatusCode.OK) {
      setBookings(guestBookingRes.data);
    } else {
      setBookings([]);
    }
  }

  function handleViewBooking(id: string) {
    if (id) {
      history.push(`/tee-on/portal/bookings/${id}/view`);
    }
  }

  return (
    <div className="guest-bookings-page-container">
      <div className="guest-bookings-header">
        <h1 className="guest-bookings-header-text">{t("guest.portal.portal_reservations.001")}</h1>
      </div>

      {bookings ? (
        <div className="guest-bookings-body-container">
          {bookings?.length > 0 ? (
            bookings
              .filter(reservation => !!reservation.turn === false)
              .map((booking, rowIndex) => {
                return (
                  <div
                    key={rowIndex}
                    onClick={() => handleViewBooking(booking?.booking?.id)}
                    className={"guest-bookings-container booking-border"}
                  >
                    <div className="guest-bookings-course">
                      {booking.tee_time ? <span>{booking.tee_time.course.long_name}</span> : <span>N/A</span>}
                    </div>

                    <div className="guest-bookings-date-time-container">
                      <div className="guest-bookings-date-time">
                        {moment(booking.tee_time?.date).format("MMMM DD YYYY")}
                      </div>

                      <div className="guest-bookings-date-time">
                        {booking.tee_time ? <span>{convertTime(booking.tee_time?.start_time)}</span> : <span>N/A</span>}
                      </div>
                    </div>

                    <div className="guest-bookings-quantity-status-container">
                      <div className="guest-bookings-quantity">
                        <FontAwesomeIcon icon={["fas", "user"]} />

                        <span>
                          {booking.booking?.quantity} {booking.booking.quantity === 1 ? "Player" : "Players"}
                        </span>
                      </div>

                      <div className="guest-bookings-status">
                        {booking.status !== "cancelled" ? (
                          booking.check_in_status !== "unchecked" && (
                            <Badge size="small" type={returnBadgeType(booking.check_in_status)}>
                              {valueToString(booking.check_in_status)}
                            </Badge>
                          )
                        ) : (
                          <Badge size="small" type="error">
                            {valueToString(booking.booking.status)}
                          </Badge>
                        )}
                        {!!booking?.booking?.order_id && (
                          <Badge
                            type="success"
                            size="small"
                            iconSize="medium"
                            iconLeft={<FontAwesomeIcon icon={["far", "dollar-sign"]} />}
                          >
                            Pre Paid
                          </Badge>
                        )}
                        {!!booking?.booking?.booking_fee_order_id && (
                          <Badge
                            type="success"
                            size="small"
                            iconSize="medium"
                            iconLeft={<FontAwesomeIcon icon={["far", "dollar-sign"]} />}
                          >
                            Booking Fee Paid
                          </Badge>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
          ) : (
            <Callout type="info" title="No Bookings Found" content="There are no bookings made under this account" />
          )}
        </div>
      ) : (
        <span style={{ display: "flex", justifyContent: "center" }}>
          <Spin />
        </span>
      )}

      <div className="guest-bookings-footer">
        <div>
          <Button
            size="small"
            type="secondary"
            onClick={() => setFilter(prev => ({ ...prev, offset: prev.offset - prev.limit }))}
            disabled={!bookings || !(filter.limit <= filter.offset)}
          >
            <FontAwesomeIcon icon={"arrow-left"} />
            &nbsp;
            {t("guest.portal.portal_reservations.010")}
          </Button>
        </div>
        <div>
          <Button
            size="small"
            type="secondary"
            onClick={() => setFilter(prev => ({ ...prev, offset: prev.offset + prev.limit }))}
            disabled={!(bookings?.length === filter.limit)}
          >
            {t("guest.portal.portal_reservations.011")}
            &nbsp;
            <FontAwesomeIcon icon={"arrow-right"} />
          </Button>
        </div>
      </div>
    </div>
  );
}

function returnBadgeType(checkInStatus: string) {
  switch (checkInStatus) {
    case "checked":
      return "public";
    case "no_show":
      return "error";
    default:
      return "gray";
  }
}
