import { APIGet, APIPost, APIPut, APIDelete, StatusCode } from "api/protocols";
import { IPutMoveLineItems } from "api/rpc/2022-09/facilityAdmin/cart/cart";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/cart/line-item";

export type TPostLineItemToCart = {
  cart_id: number;
  variants: Array<{
    variant_id: string | number;
    quantity: number;
    parent_id?: number;
    kitchen_meal_id?: number;
    prepaid_required?: boolean;
  }>;
};

export type TPutLineItemToCart = {
  id: string | number;
  quantity?: number;
  price?: number;
  note?: string;
  fulfillment_status?: "fulfilled" | "unfulfilled" | "hold" | "void";
  kitchen_meal_id?: string | number;
};

export async function PostLineItemToCart(params: TPostLineItemToCart, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

export async function PutLineItemToCart(params: TPutLineItemToCart, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

export async function DeleteLineItemToCart(params: { id: number }, useGlobalLoader: boolean) {
  return await APIDelete(baseUrl, params, useGlobalLoader);
}

/** Standalone endpoint to only allow certain users to access it */
export async function PutPrice(params: { id: string | number; price: number }, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/price", params, useGlobalLoader);
}

export async function PutMoveLineItems(params: IPutMoveLineItems, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/move", params, useGlobalLoader);
}

export async function PutCartVariants(params: TPostLineItemToCart, useGlobalLoader: boolean, cancelToken: CancelToken) {
  return await APIPut("/2024-04/facility-admin/cart/variants", params, useGlobalLoader, cancelToken);
}
