import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dequeue, enqueue, showError, showSuccess } from "redux/actions/ui";
import { ITeeTime } from "redux/reducers/models/teetime";
import { IAPIResponse, StatusCode } from "api/protocols";
import { UserActive, UserLogout } from "api/rpc";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { convertTime, formatDateFromString } from "helpers/Helpers";

import Callout from "components/callout/Callout";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import { CustomerTeeTimeCard } from "components/customerTeeTimeCard/CustomerTeeTimeCard";
import Radio from "components/radio";
import DateBar from "elements/teesheet/DateBar";
import "./clientPortalHome.scss";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { clientPortalUpdate } from "redux/actions/clientPortal";
import { ClientPortalNavigation } from "../ClientPortalNavigation";
import { Select } from "components/select/index";
import CustomDropdown from "components/customDropdown/CustomDropdown";
import CustomDropdownOption from "components/customDropdown/CustomDropdownOption";
import ReactDOM from "react-dom";
import { GetTeeTimesCustomer } from "api/rpc/2022-09/customer/teetime";
import { GetTeeTimesGuest } from "api/rpc/2022-09/guest/teetime";
import { IFacility } from "redux/reducers/models/facility";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { sortFacilities } from "redux/reducers/clientPortal";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { MOBILE_WIDTH, TABLET_WIDTH } from "helpers/ScreenSizes";
import { ButtonNew as Button } from "components/buttonNew";
import { Badge } from "components/badge/Badge";
import {
  DeleteFacilityFavourite,
  GetCustomerTeeSheetSettings,
  GetFacility,
  PostFacilityFavourite,
} from "api/rpc/2024-04/customer/facility";
import { GetFacilityGuest, GetGuestTeeSheetSettings } from "api/rpc/2024-04/guest/facility";
import { NavigationDropdownNew } from "components/navigationDropdownNew/NavigationDropdownNew";
import axios, { CancelToken } from "axios";
import Spin from "components/spin/spin";
import { GetClient } from "api/rpc/2024-04/customer/client";
import { GetClientGuest } from "api/rpc/2024-04/guest/client";
import {
  GetCustomerCheckIns,
  GetCustomerNotifications,
  ICheckIns,
  INotification,
} from "api/rpc/2024-04/customer/teesheet";
import { GetGuestCheckIns, GetGuestNotifications } from "api/rpc/2024-04/guest/teesheet";
import { LockTeeTime } from "api/rpc/2024-04/customer/teetime";
import TeeTimeWaitlist from "components/teeTimeWaitlist/TeeTimeWaitlist";
import { useClientPortal } from "../useClientPortal";
import classNames from "classnames";
import { GetCustomerLeague } from "api/rpc/2024-04/customer/league";
import { IFacilityTeeSheetSettings } from "api/rpc/2022-09/customer/facility";
import Popup from "components/popup/Popup";
import Input from "components/input/Input";
import ClientPortalTeetimeFilters from "./ClientPortalTeetimeFilters";
import { holeOptions, cartOptions, playerOptions } from "./teeTimeFilters";

interface ITeeTimeCard {
  id: number;
  convertedTime: string;
  time: string;
  prepaid: boolean;
  quantity_remaining: number;
  holes: number[];
  cart_rule: string;
  nine_hole_price: number;
  eighteen_hole_price: number;
  courseName?: string;
  date: string;
  position: number;
  divisionTitle?: string;
  blocked_type?: unknown;
  startingHole?: string;
  leagueId: number;
  course_id: number;
}

export interface IFilterState {
  selectedDate: Date;
  selectedPlayers: string;
  selectedHoles: string;
  selectedCart: string;
  selectedTeeTimeCards: ITeeTimeCard[];
  notifications: INotification[];
  teeTimeOffset: number;
  teeTimeLimit: number;
  enable_cart_bookings: boolean;
  checkIns: Array<ICheckIns>;
  facilitySearchQuery: string;
  searchedFacilities: Array<IFacility>;
  adminUserLoggedIn: boolean;
}

interface IParams {
  clientShortName: string;
  facilityShortName: string;
}

interface ILeagueState {
  leagues: Array<{ id: string | number; name: string }>;
  selectedLeagueId: string | number;
}

export function ClientPortalHome() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const clientPortalStore = useAppSelector(store => store.clientPortalStore);
  const dispatch = useAppDispatch();
  const { clientShortName, facilityShortName } = useParams<IParams>();
  const { getURLParams } = useClientPortal();
  const { date, waitlist_reference_id } = getURLParams();
  const HOME_URL = useClientPortal().getHomePageUrl();

  const windowSize = useWindowSize();
  //Backend counts Sunday as day 7
  const [filterDayState, setFilterDayState] = useState<number>(
    moment(date ?? null).day() === 0 ? 7 : moment(date ?? null).day(),
  );
  const { Option } = Select;
  const [animateFavourites, setAnimateFavourites] = useState<boolean>(false);
  const [leagueState, setLeagueState] = useState<ILeagueState>({
    /**On default, -1 as the league id is selected, which means all tee times will be loaded */
    leagues: [{ id: -1, name: "All Tee Times" }],
    selectedLeagueId: -1,
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    selectedDate: date ? new Date(date.concat(`T${moment().format("hh:mm")}`)) : new Date(),
    selectedPlayers: null,
    selectedHoles: null,
    selectedCart: null,
    selectedTeeTimeCards: [],
    notifications: [],
    teeTimeOffset: 0,
    teeTimeLimit: 30,

    enable_cart_bookings: null,
    checkIns: [],
    facilitySearchQuery: "",
    searchedFacilities: [],
    adminUserLoggedIn: false,
  });

  useEffect(() => {
    void getClient();
  }, []);

  // useEffect(() => {
  //   const source = axios.CancelToken.source();
  //   let mounted = true;
  //   let timeoutId: NodeJS.Timeout = null;
  //   if (mounted === true && filterState?.facilitySearchQuery) {
  //     timeoutId = global.setTimeout(() => {
  //       void searchFacility(source.token);
  //     }, 500);
  //   }

  //   return () => {
  //     mounted = false;
  //     clearTimeout(timeoutId);
  //     updateFilterState({ searchedFacilities: [] });
  //     source.cancel("Cancelled");
  //   };
  // }, [filterState?.facilitySearchQuery]);

  useEffect(() => {
    if (filterState.checkIns && !!clientPortalStore?.teeSheetSettings?.display_online_pricing) {
      const teeTimeCards = [...filterState.selectedTeeTimeCards];
      teeTimeCards?.forEach((card, index) => {
        let eighteenHolePrice: number;
        let nineHolePrice: number;
        filterState?.checkIns?.every(checkIn => {
          if (eighteenHolePrice && nineHolePrice) {
            return false;
          } else {
            const startTime = moment(checkIn?.pricing_sheet?.start_time, "HH:mm");
            const endTime = moment(checkIn?.pricing_sheet?.end_time, "HH:mm");
            const checkInCourseId = checkIn?.pricing_sheet?.course_id;
            if (
              (moment(card?.time, "HH:mm").isBetween(startTime, endTime) ||
                moment(card?.time, "HH:mm").isSame(startTime) ||
                moment(card?.time, "HH:mm").isSame(endTime)) &&
              checkHolesSelected(checkIn?.holes) &&
              checkInCourseId === card?.course_id
            ) {
              if (checkIn?.holes === 18 && card?.holes?.includes(18) && !eighteenHolePrice) {
                const priceOverride = checkIn?.variant?.inventory_levels?.find(
                  level => level?.facility_id === clientPortalStore?.selectedFacility?.id,
                )?.price_override;
                eighteenHolePrice = priceOverride ?? checkIn?.variant?.price;
              } else if (checkIn?.holes === 9 && card?.holes?.includes(9) && !nineHolePrice) {
                const priceOverride = checkIn?.variant?.inventory_levels?.find(
                  level => level?.facility_id === clientPortalStore?.selectedFacility?.id,
                )?.price_override;
                nineHolePrice = priceOverride ?? checkIn?.variant?.price;
              }
            }
            return true;
          }
        });
        teeTimeCards[index] = {
          ...card,
          eighteen_hole_price: eighteenHolePrice ? eighteenHolePrice : undefined,
          nine_hole_price: nineHolePrice ? nineHolePrice : undefined,
        };
      });
      updateFilterState({ selectedTeeTimeCards: teeTimeCards });
    }
  }, [filterState.selectedHoles]);

  function checkHolesSelected(holes: number) {
    switch (filterState.selectedHoles) {
      case "18":
        if (holes === 18) {
          return true;
        }
        break;
      case "9":
        if (holes === 9) {
          return true;
        }
        break;
      case "any":
      case null:
        return true;
    }
    return false;
  }

  function sendToNextPage(address: string) {
    history.push(address);
  }

  async function searchFacility(cancelToken: CancelToken) {
    let facilityRes;
    //Show loader
    if (filterState?.searchedFacilities) {
      updateFilterState({ searchedFacilities: null });
    }

    if (clientPortalStore?.user) {
      facilityRes = await GetFacility(
        { search: filterState?.facilitySearchQuery, order_by: "long_name", client_id: clientPortalStore.client?.id },
        false,
        cancelToken,
      );
    } else {
      facilityRes = await GetFacilityGuest(
        { search: filterState?.facilitySearchQuery, order_by: "long_name", client_id: clientPortalStore.client?.id },
        false,
        cancelToken,
      );
    }
    if (facilityRes?.status !== StatusCode.OK) {
      if (facilityRes?.message === "Cancelled") {
        return;
      }
      dispatch(showError("Error getting facilities"));
      return;
    }
    //Sort by Favourite facilities
    const sortedFacilities = sortFacilities(facilityRes?.data);
    updateFilterState({ searchedFacilities: sortedFacilities });
  }

  const facilities = useMemo(() => {
    if (filterState?.facilitySearchQuery) {
      return clientPortalStore?.client?.facilities?.filter(
        facility =>
          facility?.long_name.toLowerCase().includes(filterState?.facilitySearchQuery?.toLowerCase()) ||
          facility?.city.toLowerCase().includes(filterState?.facilitySearchQuery?.toLowerCase()),
      );
    } else if (clientPortalStore?.client?.facilities) {
      return clientPortalStore?.client?.facilities;
    } else {
      return [];
    }
  }, [filterState?.searchedFacilities, clientPortalStore?.client?.facilities, filterState.facilitySearchQuery]);

  async function getClient() {
    let currentActiveUser = !!clientPortalStore?.user;
    let selectedClient = clientPortalStore?.client;
    let selectedFacility = clientPortalStore?.selectedFacility;

    dispatch(enqueue());

    const activeUserRes = await UserActive(false);
    if (activeUserRes?.status === StatusCode.OK) {
      currentActiveUser = true;
    }

    if (activeUserRes?.data?.user_type === 2 || activeUserRes?.data?.user_type === 3) {
      setFilterState(prevState => ({ ...prevState, adminUserLoggedIn: true }));
      dispatch(dequeue());

      return;
    }

    if (
      !clientPortalStore?.client ||
      !clientPortalStore?.selectedFacility ||
      (clientPortalStore?.selectedFacility?.favourite === undefined && currentActiveUser)
    ) {
      let clientRes;
      let facilityRes;
      if (currentActiveUser) {
        currentActiveUser = true;
        clientRes = await GetClient({ short_name: clientShortName }, false);
      } else {
        clientRes = await GetClientGuest({ short_name: clientShortName }, false);
      }
      if (clientRes.status !== StatusCode.OK || clientRes?.data?.length === 0) {
        dispatch(dequeue());
        dispatch(showError("Error loading client"));
        return;
      }
      selectedClient = clientRes?.data[0];
      if (currentActiveUser) {
        facilityRes = await GetFacility({ client_id: selectedClient?.id, order_by: "long_name" }, false);
      } else {
        facilityRes = await GetFacilityGuest({ client_id: selectedClient?.id, order_by: "long_name" }, false);
      }

      if (facilityRes?.status !== StatusCode.OK || facilityRes?.data?.length === 0) {
        dispatch(dequeue());
        dispatch(showError("Error loading facilities"));
        return;
      }

      //Sort by Favourite facilities
      const sortedFacilities = sortFacilities(facilityRes?.data);

      selectedFacility = selectedFacility
        ? sortedFacilities?.find(facility => facility?.id === selectedFacility?.id)
        : facilityShortName
        ? sortedFacilities?.find(facility => facility?.short_name === facilityShortName)
        : sortedFacilities[0];
      if (!selectedFacility && sortedFacilities?.length > 0) {
        selectedFacility = sortedFacilities[0];
      }
      selectedClient = { ...selectedClient, facilities: sortedFacilities };
      dispatch(
        clientPortalUpdate({
          client: selectedClient,
          selectedFacility,
          waitlist_reference_id: waitlist_reference_id ?? null,
        }),
      );
    }

    ReactDOM.unstable_batchedUpdates(() => {
      dispatch(dequeue());
      dispatch(
        clientPortalUpdate({
          user: currentActiveUser ? activeUserRes?.data : undefined,
        }),
      );
    });
    await checkLoginRequired(selectedFacility, currentActiveUser);
  }

  async function checkLoginRequired(facility: Partial<IFacility>, currentActiveUser = false) {
    const dayOfWeekNumber = moment(filterState.selectedDate).day() === 0 ? 7 : moment(filterState.selectedDate).day();

    if (currentActiveUser) {
      await loadTeeInformation(
        filterState.selectedDate,
        true,
        facility?.courses?.length,
        facility?.id,
        filterState.teeTimeOffset,
        filterState.teeTimeLimit,
        dayOfWeekNumber,
        true,
        true,
      );
    } else {
      await loadTeeInformation(
        filterState.selectedDate,
        false,
        facility?.courses?.length,
        facility?.id,
        filterState.teeTimeOffset,
        filterState.teeTimeLimit,
        dayOfWeekNumber,
        true,
        true,
      );
    }
  }

  function updateFilterState(newFilterState: Partial<IFilterState>) {
    setFilterState(cur => {
      return { ...cur, ...newFilterState };
    });
  }

  function handleSelectedDateChange(selectedDate: Date) {
    //Backend counts Sunday as day 7
    const dayOfWeekNumber = moment(selectedDate).day() === 0 ? 7 : moment(selectedDate).day();
    setFilterDayState(dayOfWeekNumber);
    updateFilterState({ selectedDate: selectedDate });
    if (clientPortalStore?.user !== null) {
      updateFilterState({ teeTimeOffset: 0 });
      void loadTeeInformation(
        selectedDate,
        clientPortalStore?.user ? true : false,
        clientPortalStore?.selectedFacility?.courses?.length,
        clientPortalStore?.selectedFacility?.id,
        0,
        filterState.teeTimeLimit,
        dayOfWeekNumber,
        false,
        false,
      );
    }
  }

  function invalidTeeTime(teeTime: ITeeTime) {
    return (
      teeTime?.divisions_available?.length === 1 && teeTime?.divisions_available[0] === 2 && !teeTime?.turn_tee_time_id
    );
  }

  // loads the notifications for the selected date
  async function loadNotifications(date: Date, isPrivate: boolean, facilityId: number) {
    let notificationResponse;
    const currentDateString = date.toLocaleDateString("en-CA");

    if (isPrivate) {
      // Members || Private Api Call
      notificationResponse = await GetCustomerNotifications(
        {
          date: currentDateString,
          facility_id: facilityId,
        },
        false,
      );
    } else {
      //Guest || Pubic Api call
      notificationResponse = await GetGuestNotifications(
        {
          date: currentDateString,
          facility_id: facilityId,
        },
        false,
      );
    }

    if (notificationResponse?.status === StatusCode.OK && notificationResponse?.data?.length > 0) {
      updateFilterState({ notifications: notificationResponse.data });
    } else {
      updateFilterState({ notifications: [] });
    }
  }

  //Loads the Tee Time Information from API Response
  async function loadTeeInformation(
    date: Date,
    isPrivate: boolean,
    courses: number,
    facilityId: number,
    offset: number,
    limit: number,
    dayOfWeekNumber: number,
    loadSettings: boolean,
    resetLeague: boolean,
    // defaultDate: Date,
  ) {
    let teeInformationResponse;
    let teeSheetSettings = clientPortalStore?.teeSheetSettings;
    let checkInConfigsRes: IAPIResponse<Array<ICheckIns>>;

    dispatch(enqueue());

    if (isPrivate) {
      if (loadSettings || !teeSheetSettings) {
        //Members / Private Api Call
        const teeSheetSettingsRes = await GetCustomerTeeSheetSettings({ facility_id: facilityId }, false);
        if (teeSheetSettingsRes.status !== StatusCode.OK) {
          dispatch(showError("Error getting tee sheet settings"));
        }
        teeSheetSettings = teeSheetSettingsRes.data;
        dispatch(
          clientPortalUpdate({
            teeSheetSettings: teeSheetSettings,
          }),
        );
      }

      if (teeSheetSettings?.display_online_pricing) {
        checkInConfigsRes = await GetCustomerCheckIns(
          { facility_id: facilityId, day_of_week_number: dayOfWeekNumber },
          false,
        );
        if (checkInConfigsRes.status !== StatusCode.OK) {
          dispatch(showError("Error getting pricing"));
        }
      }

      if (resetLeague) {
        const leagueRes = await GetCustomerLeague(
          { facility_id: facilityId, year: date.getFullYear(), user_id: clientPortalStore?.user?.id ?? 1 },
          false,
        );
        if (leagueRes.status !== StatusCode.OK) {
          dispatch(showError("Error getting leagues"));
        } else if (leagueRes.status === StatusCode.OK) {
          const updatedLeagues = [
            { id: -1, name: "All Tee Times" },
            ...leagueRes?.data?.map(league => ({ id: league.id, name: league.name })),
          ];
          setLeagueState({
            leagues: updatedLeagues,
            selectedLeagueId: -1,
          });
        }
      }

      teeInformationResponse = await GetTeeTimesCustomer(
        {
          facility_id: facilityId,
          date: date,
          extended: true,
          turn_tee_time: true,
          league_id:
            Number(leagueState.selectedLeagueId) <= 0 || resetLeague ? undefined : Number(leagueState.selectedLeagueId),
        },
        false,
      );
    } else {
      if (loadSettings || !teeSheetSettings) {
        //Guest API call
        const teeSheetSettingsRes = await GetGuestTeeSheetSettings({ facility_id: facilityId }, false);
        if (teeSheetSettingsRes.status !== StatusCode.OK) {
          dispatch(showError("Error getting tee sheet settings"));
        }
        teeSheetSettings = teeSheetSettingsRes.data;
        dispatch(
          clientPortalUpdate({
            teeSheetSettings: teeSheetSettings,
          }),
        );
      }

      if (teeSheetSettings?.display_online_pricing) {
        checkInConfigsRes = await GetGuestCheckIns(
          { facility_id: facilityId, day_of_week_number: dayOfWeekNumber },
          false,
        );
        if (checkInConfigsRes.status !== StatusCode.OK) {
          dispatch(showError("Error getting pricing"));
        }
      }

      teeInformationResponse = await GetTeeTimesGuest(
        { facility_id: facilityId, date: date, extended: true, turn_tee_time: true },
        false,
      );
    }

    dispatch(dequeue());
    updateFilterState({ enable_cart_bookings: !!teeSheetSettings?.enable_cart_bookings });

    void updateTeeTimes(
      teeInformationResponse,
      offset,
      limit,
      checkInConfigsRes,
      teeSheetSettings,
      facilityId,
      courses,
    );
    // load notifications after Get TeeTimes call completes
    void loadNotifications(date, isPrivate, facilityId);
  }

  function updateTeeTimes(
    teeInformationResponse: IAPIResponse<Array<ITeeTime>>,
    offset: number,
    limit: number,
    checkInConfigsRes: IAPIResponse<Array<ICheckIns>>,
    teeSheetSettings: IFacilityTeeSheetSettings,
    facilityId: number,
    courses: number,
  ) {
    if (teeInformationResponse.status !== StatusCode.OK) {
      dispatch(showError("No Tee Times"));
      updateFilterState({ selectedTeeTimeCards: [] });
      updateFilterState({ teeTimeOffset: 0 });
    } else if (offset > 0 && teeInformationResponse.data.length === 0) {
      const newTeeTimeOffset = offset - limit;
      updateFilterState({ teeTimeOffset: newTeeTimeOffset > 0 ? newTeeTimeOffset : 0 });
    } else {
      const teetimeCards: ITeeTimeCard[] = [];
      const checkInsData = checkInConfigsRes?.data ? [...checkInConfigsRes?.data] : [];

      for (let i = 0; i < teeInformationResponse.data.length; i++) {
        if (invalidTeeTime(teeInformationResponse.data[i])) {
          continue;
        }
        const teetimeData = teeInformationResponse.data[i];
        const startTime = convertTime(teetimeData.start_time);

        const holes = teetimeData.divisions_available.map(divisionAvailable => {
          if (divisionAvailable === 1) {
            return 9;
          } else if (divisionAvailable === 2) {
            return 18;
          }
        });

        let eighteenHolePrice: number;
        let nineHolePrice: number;
        checkInsData?.every(checkIn => {
          if (eighteenHolePrice && nineHolePrice) {
            return false;
          } else {
            const startTime = moment(checkIn?.pricing_sheet?.start_time, "HH:mm");
            const endTime = moment(checkIn?.pricing_sheet?.end_time, "HH:mm");
            const checkInCourseId = checkIn?.pricing_sheet?.course_id;
            if (
              (moment(teetimeData?.start_time, "HH:mm").isBetween(startTime, endTime) ||
                moment(teetimeData?.start_time, "HH:mm").isSame(startTime) ||
                moment(teetimeData?.start_time, "HH:mm").isSame(endTime)) &&
              checkHolesSelected(checkIn?.holes) &&
              checkInCourseId === teetimeData?.course_id
            ) {
              if (checkIn?.holes === 18 && holes?.includes(18) && !eighteenHolePrice) {
                const priceOverride = checkIn?.variant?.inventory_levels?.find(
                  level => level?.facility_id === facilityId,
                )?.price_override;
                eighteenHolePrice = priceOverride ?? checkIn?.variant?.price;
              } else if (checkIn?.holes === 9 && holes?.includes(9) && !nineHolePrice) {
                const priceOverride = checkIn?.variant?.inventory_levels?.find(
                  level => level?.facility_id === facilityId,
                )?.price_override;
                nineHolePrice = priceOverride ?? checkIn?.variant?.price;
              }
            }
            return true;
          }
        });

        if (teetimeData.quantity_remaining >= 1) {
          const teetimeCard: ITeeTimeCard = {
            id: teetimeData.id,
            convertedTime: startTime,
            time: teetimeData.start_time,
            prepaid: teeInformationResponse.data[i].pre_paid_required,
            quantity_remaining: teetimeData.quantity_remaining,
            holes: holes,
            cart_rule: teetimeData.cart_rule,
            eighteen_hole_price:
              eighteenHolePrice && !!teeSheetSettings?.display_online_pricing ? eighteenHolePrice : undefined,
            nine_hole_price: nineHolePrice && !!teeSheetSettings?.display_online_pricing ? nineHolePrice : undefined,
            courseName: courses > 1 ? teetimeData.course_full_name : undefined,
            date: teetimeData.date,
            position: teetimeData.position,
            divisionTitle: teetimeData.division_title,
            blocked_type: teetimeData.blocked_type,
            startingHole: teetimeData.start_hole,
            leagueId: teetimeData.league_id,
            course_id: teetimeData?.course_id,
          };

          teetimeCards.push(teetimeCard);
        }
      }

      teetimeCards.sort(
        (currentTeetimeCard, nextTeetimeCard) => currentTeetimeCard.position - nextTeetimeCard.position,
      );
      updateFilterState({ selectedTeeTimeCards: teetimeCards, checkIns: checkInsData });
      window.scrollTo({ top: 0, left: 0 });
    }
  }

  async function CheckTeeTime(date: string, teeTimeId: number, courseName: string) {
    const params = {
      date: date,
      id: teeTimeId,
      facility_id: clientPortalStore?.selectedFacility?.id,
      turn_tee_time: true,
      extended: true,
      league_id: Number(leagueState.selectedLeagueId) > 0 ? Number(leagueState.selectedLeagueId) : undefined,
    };

    // If user is active, load auth route for tee time
    let teeInformationByIdRes;
    if (clientPortalStore?.user) {
      teeInformationByIdRes = await GetTeeTimesCustomer(params, true);
    } else {
      teeInformationByIdRes = await GetTeeTimesGuest(params, true);
    }

    if (teeInformationByIdRes.status === 410) {
      dispatch(showError("Tee time is currently being booked by another player"));
      return;
    }

    if (teeInformationByIdRes.status === 411) {
      dispatch(showError("Cannot book a tee time in the past"));
      return;
    }

    if (teeInformationByIdRes.status !== StatusCode.OK) {
      dispatch(showError("Error retrieving tee time"));
      return;
    }

    if (teeInformationByIdRes.data[0].quantity_remaining === 0) {
      dispatch(showError("Tee time has no spots available, please choose another"));
      return;
    }

    const tempId = String(teeTimeId);
    const tempDate = String(date);

    const tempStartTime = convertTime(teeInformationByIdRes.data[0].start_time);

    const tempFormatDate = formatDateFromString(tempDate);

    const selectedTeeTime = {
      ...teeInformationByIdRes?.data[0],
      formattedStartTime: tempStartTime,
      formattedDate: tempFormatDate,
    };

    dispatch(
      clientPortalUpdate({
        selectedTeeTime: selectedTeeTime,
      }),
    );

    if (clientPortalStore?.user) {
      //If not null - User has access to their own locked teetime
      if (teeInformationByIdRes.data[0].lock !== null) {
        const tempCurrentDate = new Date().toUTCString();
        const currentTempTime = new Date(tempCurrentDate);
        const currentTime = new Date(currentTempTime).getTime();

        const tempString = String(teeInformationByIdRes.data[0].lock.start_time) + "Z";

        const lockStartDate = new Date(tempString);

        const tempExpiryString = String(teeInformationByIdRes.data[0].lock.expire_time) + "Z";
        const lockoutTempExpiry = new Date(tempExpiryString);

        const lockStartTime = lockStartDate.getTime();
        const lockoutExpiryTime = lockoutTempExpiry.getTime();

        const lockoutDuration = lockoutExpiryTime - currentTime;

        dispatch(
          clientPortalUpdate({
            selectedTeeTimeLock: {
              lockoutDuration: lockoutDuration,
              lockStartTime: lockStartTime,
              expireTime: teeInformationByIdRes.data[0].lock.expire_time,
            },
          }),
        );

        const holeSelectionAddress = `/portal/${clientShortName}/booking/id?=${tempId}&date=${tempDate}`;
        sendToNextPage(holeSelectionAddress);
      } else {
        const bookingLockRes = await LockTeeTime({ tee_time_id: teeInformationByIdRes.data[0].id }, true);

        if (bookingLockRes.status !== StatusCode.OK) {
          dispatch(showError(bookingLockRes.data.message));
          return;
        }

        if (bookingLockRes.data.message === "Tee time already locked by user") {
          dispatch(showError("Booking is locked by another user, please try again in a few minutes.."));
          console.log("Booking is locked by another user");
          return;
        }

        const tempCurrentDate = new Date().toUTCString();
        const currentTempTime = new Date(tempCurrentDate);
        const currentTime = new Date(currentTempTime).getTime();

        const tempString = String(bookingLockRes.data.start_time) + "Z";

        const lockStartDate = new Date(tempString);

        const tempExpiryString = String(bookingLockRes.data.expire_time) + "Z";
        const lockoutTempExpiry = new Date(tempExpiryString);

        const lockStartTime = lockStartDate.getTime();
        const lockoutExpiryTime = lockoutTempExpiry.getTime();

        const lockoutDuration = lockoutExpiryTime - currentTime;

        dispatch(
          clientPortalUpdate({
            selectedTeeTimeLock: {
              lockoutDuration: lockoutDuration,
              lockStartTime: lockStartTime,
              expireTime: bookingLockRes.data.expire_time,
            },
          }),
        );

        const bookingAddress = `/portal/${clientShortName}/booking/id?=${tempId}&date=${tempDate}`;
        sendToNextPage(bookingAddress);
      }
    } else {
      const loginAddress = `/portal/${clientShortName}/login?id=${tempId}&date=${tempDate}&facility=${clientPortalStore?.selectedFacility?.short_name}`;
      sendToNextPage(loginAddress);
    }
  }

  function filterTeeTimeCards(teeTimeCards: ITeeTimeCard[]) {
    return teeTimeCards.filter(teeTimeCard => {
      if (
        clientPortalStore?.teeSheetSettings?.single_bookings === "allow_within_group" &&
        parseInt(filterState?.selectedPlayers) === 1
      ) {
        return teeTimeCard.quantity_remaining < 4;
      } else {
        return (
          (filterState.selectedPlayers === null ||
            teeTimeCard.quantity_remaining >= parseInt(filterState.selectedPlayers)) &&
          (filterState.selectedHoles === null || teeTimeCard.holes.includes(parseInt(filterState.selectedHoles))) &&
          (filterState.selectedCart === null ||
            ((teeTimeCard.cart_rule === "none" || teeTimeCard.cart_rule === "optional") &&
              filterState.selectedCart === "no") ||
            ((teeTimeCard.cart_rule === "required" || teeTimeCard.cart_rule === "optional") &&
              filterState.selectedCart === "yes")) &&
          (clientPortalStore?.teeSheetSettings?.single_bookings === "allow" ||
            clientPortalStore?.teeSheetSettings?.single_bookings === "allow_within_group" ||
            (clientPortalStore?.teeSheetSettings?.single_bookings === "none" && teeTimeCard?.quantity_remaining >= 2))
        );
      }
    });
  }

  async function handleNavigateToPreviousTeeTimePage() {
    const newTeeTimeOffset = filterState.teeTimeOffset - filterState.teeTimeLimit;

    if (newTeeTimeOffset >= 0) {
      updateFilterState({ teeTimeOffset: newTeeTimeOffset });
      await loadTeeInformation(
        filterState.selectedDate,
        !!clientPortalStore?.user,
        clientPortalStore?.selectedFacility?.courses?.length,
        clientPortalStore?.selectedFacility?.id,
        newTeeTimeOffset,
        filterState.teeTimeLimit,
        filterDayState,
        false,
        false,
      );
    }
  }

  async function handleNavigateToNextTeeTimePage() {
    const newTeeTimeOffset = filterState.teeTimeOffset + filterState.teeTimeLimit;

    updateFilterState({ teeTimeOffset: newTeeTimeOffset });
    await loadTeeInformation(
      filterState.selectedDate,
      !!clientPortalStore?.user,
      clientPortalStore?.selectedFacility?.courses?.length,
      clientPortalStore?.selectedFacility?.id,
      newTeeTimeOffset,
      filterState.teeTimeLimit,
      filterDayState,
      false,
      false,
    );
  }

  async function handleFacilityChange(id: number) {
    const foundFacility = clientPortalStore?.client?.facilities?.find(facility => facility?.id === id);
    ReactDOM.unstable_batchedUpdates(() => {
      setFilterState(prevState => ({ ...prevState, selectedTeeTimeCards: [] }));
    });
    if (foundFacility) {
      setFilterState(prevState => ({
        ...prevState,
        selectedHoles: null,
        selectedCart: null,
        selectedPlayers: null,
      }));
      dispatch(clientPortalUpdate({ selectedFacility: foundFacility }));
      await checkLoginRequired(foundFacility, !!clientPortalStore?.user);
    }
  }

  const selectedFacilityElement = (
    <div className="client-portal-home-course-information-section">
      {clientPortalStore?.client?.facilities?.length > 1 && clientPortalStore?.selectedFacility?.logo_source && (
        <div>
          <img className="client-portal-home-course-logo" src={clientPortalStore?.selectedFacility?.logo_source} />
        </div>
      )}
      <div>
        <div className="client-portal-home-course-name">{clientPortalStore?.selectedFacility?.long_name}</div>
        {clientPortalStore?.selectedFacility && (
          <div className="client-portal-home-course-secondary-information">
            {`${clientPortalStore?.selectedFacility?.address_line_1}, ${clientPortalStore?.selectedFacility?.city}, ${clientPortalStore?.selectedFacility?.province_name}, ${clientPortalStore?.selectedFacility?.postal}`}
          </div>
        )}
        {clientPortalStore?.selectedFacility?.address_line_2 && (
          <div className="client-portal-home-course-secondary-information">
            {clientPortalStore?.selectedFacility?.address_line_2}
          </div>
        )}
      </div>
    </div>
  );

  async function handleAddToFavourites() {
    const favRes = await PostFacilityFavourite({ facility_id: clientPortalStore?.selectedFacility?.id }, true);
    if (favRes?.status !== StatusCode.OK) {
      dispatch(showError("Error adding course to favourites"));
      return;
    }
    void updateFavouriteStatus();
    dispatch(showSuccess("Successfully added course to favourites"));
  }

  async function handleRemoveFromFavourites() {
    const favRes = await DeleteFacilityFavourite({ facility_id: clientPortalStore?.selectedFacility?.id }, true);
    if (favRes?.status !== StatusCode.OK) {
      dispatch(showError("Error removing course from favourites"));
      return;
    }
    void updateFavouriteStatus();
    dispatch(showSuccess("Successfully removed course from favourites"));
  }

  function updateFavouriteStatus() {
    const updatedFacilities = [...clientPortalStore?.client?.facilities];
    const foundIndex = updatedFacilities?.findIndex(
      facility => facility?.id === clientPortalStore?.selectedFacility?.id,
    );
    if (foundIndex !== -1) {
      updatedFacilities[foundIndex] = {
        ...updatedFacilities[foundIndex],
        favourite: !updatedFacilities[foundIndex]?.favourite,
      };
      const sortedFacilities = sortFacilities([...updatedFacilities]);
      const client = { ...clientPortalStore?.client, facilities: sortedFacilities };
      dispatch(clientPortalUpdate({ client, selectedFacility: updatedFacilities[foundIndex] }));
    } else {
      dispatch(showError("Error updating course favourites"));
    }
  }

  function handleFacilitySearch(query: string) {
    setFilterState(prevState => ({ ...prevState, facilitySearchQuery: query }));
  }

  async function handleLeagueSelect(leagueId: string | number) {
    let checkInConfigsRes: IAPIResponse<Array<ICheckIns>>;
    const dayOfWeekNumber = moment(filterState.selectedDate).day() === 0 ? 7 : moment(filterState.selectedDate).day();
    setLeagueState(prevState => ({ ...prevState, selectedLeagueId: leagueId }));
    dispatch(enqueue());
    if (clientPortalStore?.teeSheetSettings?.display_online_pricing) {
      checkInConfigsRes = await GetCustomerCheckIns(
        { facility_id: clientPortalStore?.selectedFacility?.id, day_of_week_number: dayOfWeekNumber },
        false,
      );
      if (checkInConfigsRes.status !== StatusCode.OK) {
        dispatch(showError("Error getting pricing"));
      }
    }
    const teeInformationResponse = await GetTeeTimesCustomer(
      {
        facility_id: clientPortalStore?.selectedFacility?.id,
        date: filterState?.selectedDate,
        extended: true,
        turn_tee_time: true,
        league_id: leagueId === -1 ? undefined : Number(leagueId),
      },
      false,
    );
    if (teeInformationResponse?.status !== StatusCode.OK) {
      dispatch(showError("Error getting tee times"));
      dispatch(dequeue());
      return;
    }
    void updateTeeTimes(
      teeInformationResponse,
      filterState.teeTimeOffset,
      filterState.teeTimeLimit,
      checkInConfigsRes,
      clientPortalStore?.teeSheetSettings,
      clientPortalStore?.selectedFacility?.id,
      clientPortalStore?.selectedFacility?.courses?.length,
    );
    dispatch(dequeue());
  }

  async function logoutUser() {
    const logoutRes = await UserLogout(true);

    if (logoutRes.status !== StatusCode.OK) {
      dispatch(showError("Error logging out"));
    }

    window.location.reload();
  }

  return (
    <div className="client-portal-home-page-container">
      <ClientPortalNavigation showNavOptions />
      <div className="client-portal-home-wide-section-container">
        <div className="client-portal-home-wide-section">
          <div className="client-portal-home-facility-container">
            {clientPortalStore?.client?.facilities?.length > 1 ? (
              <>
                <div className="client-portal-home-facility-dropdown">
                  <CustomDropdown
                    parentElement={selectedFacilityElement}
                    onChange={handleFacilityChange}
                    defaultValue={clientPortalStore?.selectedFacility?.id}
                    onSearch={handleFacilitySearch}
                    searchPlacerHolder="Search courses..."
                  >
                    {facilities ? (
                      facilities?.map((facility, index) => {
                        return (
                          <CustomDropdownOption value={facility.id} key={index}>
                            <div className="client-portal-facility-option-container">
                              <img
                                className="client-portal-facility-option-facility-logo"
                                src={facility?.logo_source}
                              />
                              <p className="client-portal-facility-option-facility-name">{facility?.long_name}</p>
                              {windowSize.width > MOBILE_WIDTH ? (
                                <p className="client-portal-facility-option-facility-address">{facility?.city}</p>
                              ) : null}
                              {facility?.favourite && (
                                <FontAwesomeIcon
                                  size="1x"
                                  fixedWidth
                                  className="client-portal-facility-option-favourite"
                                  icon={["fas", "star"]}
                                />
                              )}
                            </div>
                          </CustomDropdownOption>
                        );
                      })
                    ) : (
                      <div className="facility-spinner">
                        <Spin />
                      </div>
                    )}
                  </CustomDropdown>
                </div>
                {clientPortalStore?.user && clientPortalStore?.client?.facilities?.length > 1 && (
                  <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip id="courseFavouritesToolTip">
                        {clientPortalStore?.selectedFacility?.favourite
                          ? "Remove from favourites"
                          : "Add to favourites"}
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon
                      onClick={
                        clientPortalStore?.selectedFacility?.favourite
                          ? () => handleRemoveFromFavourites()
                          : () => handleAddToFavourites()
                      }
                      onMouseEnter={() => setAnimateFavourites(true)}
                      onMouseLeave={() => setAnimateFavourites(false)}
                      beatFade={animateFavourites}
                      className={classNames("client-portal-favourites-icon", {
                        "client-portal-favourites-icon-favourited": clientPortalStore?.selectedFacility?.favourite,
                      })}
                      icon={["fas", "star"]}
                    />
                  </OverlayTrigger>
                )}
              </>
            ) : (
              selectedFacilityElement
            )}
          </div>
        </div>
      </div>
      {clientPortalStore?.user && leagueState?.leagues?.length > 1 && (
        <div className="client-portal-home-tabs-parent-container">
          <div className="client-portal-home-tabs-narrow-section">
            <div className="client-portal-home-tabs-container">
              {leagueState?.leagues?.map((league, leagueIndex) => {
                return (
                  <div
                    key={leagueIndex}
                    className={classNames("events-tab", {
                      "events-tab-selected": league?.id === leagueState?.selectedLeagueId,
                    })}
                    onClick={() => handleLeagueSelect(league?.id)}
                  >
                    {league?.name}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      <div className="client-portal-home-narrow-section-container">
        <div className="client-portal-home-narrow-section">
          <div className="client-portal-home-date-selection">
            <div className="client-portal-home-date-selector">
              <DatePickerInput
                months={1}
                position="left"
                startingDate={filterState.selectedDate}
                setStartingDate={handleSelectedDateChange}
                disablePastDays
              />
              {windowSize.width <= MOBILE_WIDTH && (
                <ClientPortalTeetimeFilters
                  filters={{
                    selectedCart: filterState.selectedCart,
                    selectedHoles: filterState.selectedHoles,
                    selectedPlayers: filterState.selectedPlayers,
                  }}
                  updateFilters={filters => updateFilterState({ ...filters })}
                  showCartFilters={filterState.enable_cart_bookings}
                />
              )}
            </div>
            <div className="client-portal-home-date-bar">
              <DateBar numberOfDays={8} selectedDate={filterState.selectedDate} onClick={handleSelectedDateChange} />
            </div>
          </div>
        </div>
      </div>
      <div className="client-portal-home-wide-section-container">
        <div className="client-portal-home-wide-section client-portal-home-tee-time-section">
          <div className="client-portal-home-main-sections">
            {/* Mobile Filters */}
            {/* <div className="client-portal-home-filter-section-mobile">
              <NavigationDropdownNew
                showPlainTextLabel
                leftAlign
                label={
                  <Badge
                    type="primary"
                    iconSize="small"
                    iconLeft={<FontAwesomeIcon icon={["fas", "user"]} />}
                    size="small"
                  >
                    Players: {filterState?.selectedPlayers}{" "}
                    <FontAwesomeIcon style={{ marginLeft: "5px" }} icon={["fas", "chevron-down"]} />
                  </Badge>
                }
                sections={[
                  [
                    {
                      label: "1",
                      onClick: () => updateFilterState({ selectedPlayers: "1" }),
                    },
                  ],
                  [
                    {
                      label: "2",
                      onClick: () => updateFilterState({ selectedPlayers: "2" }),
                    },
                  ],
                  [
                    {
                      label: "3",
                      onClick: () => updateFilterState({ selectedPlayers: "3" }),
                    },
                  ],
                  [
                    {
                      label: "4",
                      onClick: () => updateFilterState({ selectedPlayers: "4" }),
                    },
                  ],
                  [
                    {
                      label: "Any",
                      onClick: () => updateFilterState({ selectedPlayers: "any" }),
                    },
                  ],
                ]}
              />
              <NavigationDropdownNew
                showPlainTextLabel
                label={
                  <Badge
                    type="primary"
                    iconSize="small"
                    iconLeft={<FontAwesomeIcon icon={["fas", "golf-flag-hole"]} />}
                    size="small"
                  >
                    Holes: {filterState?.selectedHoles}{" "}
                    <FontAwesomeIcon style={{ marginLeft: "5px" }} icon={["fas", "chevron-down"]} />
                  </Badge>
                }
                sections={[
                  [
                    {
                      label: "18",
                      onClick: () => updateFilterState({ selectedHoles: "18" }),
                    },
                  ],
                  [
                    {
                      label: "9",
                      onClick: () => updateFilterState({ selectedHoles: "9" }),
                    },
                  ],
                  [
                    {
                      label: "Any",
                      onClick: () => updateFilterState({ selectedHoles: "any" }),
                    },
                  ],
                ]}
              />
              {filterState.enable_cart_bookings && (
                <NavigationDropdownNew
                  showPlainTextLabel
                  rightAlign
                  label={
                    <Badge
                      type="primary"
                      iconSize="small"
                      iconLeft={<FontAwesomeIcon icon={["fas", "car-side"]} />}
                      size="small"
                    >
                      Cart: {filterState?.selectedCart}{" "}
                      <FontAwesomeIcon style={{ marginLeft: "5px" }} icon={["fas", "chevron-down"]} />
                    </Badge>
                  }
                  sections={[
                    [
                      {
                        label: "Yes",
                        onClick: () => updateFilterState({ selectedCart: "yes" }),
                      },
                    ],
                    [
                      {
                        label: "No",
                        onClick: () => updateFilterState({ selectedCart: "no" }),
                      },
                    ],
                    [
                      {
                        label: "Any",
                        onClick: () => updateFilterState({ selectedCart: "any" }),
                      },
                    ],
                  ]}
                />
              )}
            </div> */}
            {/* Desktop filters */}
            <div className="client-portal-home-filter-section">
              <div className="client-portal-home-filters">
                <div>
                  <div className="client-portal-home-filter-header">{"Number of Players"}</div>
                  <Radio.Group
                    name="selectedPlayers"
                    onChange={(selectedPlayers: string) =>
                      updateFilterState({ selectedPlayers: selectedPlayers === "any" ? null : selectedPlayers })
                    }
                    value={filterState.selectedPlayers ?? "any"}
                  >
                    {playerOptions(t).map(player => {
                      return (
                        <Radio.Button key={`players_${player.value}`} value={player.value} disabled={player?.disabled}>
                          {player.title}
                        </Radio.Button>
                      );
                    })}
                  </Radio.Group>
                </div>
                <div>
                  <div className="client-portal-home-filter-header">{"Holes"}</div>
                  <Radio.Group
                    name="selectedHoles"
                    onChange={(selectedHoles: string) =>
                      updateFilterState({ selectedHoles: selectedHoles === "any" ? null : selectedHoles })
                    }
                    value={filterState.selectedHoles ?? "any"}
                  >
                    {holeOptions.map(hole => {
                      return (
                        <Radio.Button key={`holes_${hole.value}`} value={hole.value}>
                          <span>{hole.title}</span>
                          {hole.value !== "any" ? (
                            <FontAwesomeIcon
                              className="client-portal-home-filter-golf-icon"
                              icon={["far", "golf-flag-hole"]}
                            />
                          ) : null}
                        </Radio.Button>
                      );
                    })}
                  </Radio.Group>
                </div>

                {filterState.enable_cart_bookings ? (
                  <div>
                    <div className="client-portal-home-filter-header">{"Cart"}</div>
                    <Radio.Group
                      name="selectedCart"
                      onChange={(selectedCart: string) =>
                        updateFilterState({ selectedCart: selectedCart === "any" ? null : selectedCart })
                      }
                      value={filterState.selectedCart ?? "any"}
                    >
                      {cartOptions.map(cartOption => {
                        return (
                          <Radio.Button key={`cart_${cartOption.value}`} value={cartOption.value}>
                            {cartOption.title}
                          </Radio.Button>
                        );
                      })}
                    </Radio.Group>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="client-portal-home-tee-time-section">
              <div>
                {filterState.notifications.map((notification, index) => {
                  return (
                    <Callout key={index} type="info" title={notification.subject} content={notification.content} />
                  );
                })}
                {filterState.selectedTeeTimeCards.length === 0 && (
                  <Callout type="error" title={"No Tee Times"} content={"There are no tee times for this day"} />
                )}
                {filterState.selectedTeeTimeCards.length !== 0 &&
                  filterTeeTimeCards(filterState.selectedTeeTimeCards).length === 0 && (
                    <Callout
                      type="error"
                      title={"No Tee Times"}
                      content={"There are no tee times that match the selected filters"}
                    />
                  )}
              </div>
              <div className="client-portal-home-tee-times">
                {filterTeeTimeCards(filterState.selectedTeeTimeCards).map((teetime, index) => {
                  let courseName;

                  if (clientPortalStore?.selectedFacility?.courses.length > 1) {
                    courseName = "Course: " + teetime.courseName;
                  }

                  return (
                    <div key={index}>
                      <CustomerTeeTimeCard
                        time={teetime.convertedTime}
                        isPrepaid={teetime.prepaid}
                        isShotgun={teetime.blocked_type === "shotgun"}
                        spotsRemaining={teetime.quantity_remaining}
                        holes={teetime.holes}
                        cartRule={filterState.enable_cart_bookings ? teetime.cart_rule : "none"}
                        eighteenHolePrice={teetime.eighteen_hole_price}
                        nineHolePrice={teetime.nine_hole_price}
                        onClick={() => CheckTeeTime(teetime.date, teetime.id, teetime.courseName)}
                        courseName={clientPortalStore?.selectedFacility?.courses?.length > 1 ? courseName : null}
                        divisionTitle={teetime.divisionTitle}
                        singleBookings={clientPortalStore?.teeSheetSettings?.single_bookings}
                        startingHole={teetime.startingHole}
                        isLeague={!!teetime.leagueId}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="client-portal-home-tee-time-page-navigation">
                <button
                  className="client-portal-home-tee-time-page-navigation-button-previous"
                  onClick={() => handleNavigateToPreviousTeeTimePage()}
                >
                  <FontAwesomeIcon icon={"chevron-left"} /> {"Prev"}
                </button>
                <button
                  className="client-portal-home-tee-time-page-navigation-button-next"
                  onClick={() => handleNavigateToNextTeeTimePage()}
                >
                  {"Next"} <FontAwesomeIcon icon={"chevron-right"} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TeeTimeWaitlist
        visible={!!clientPortalStore?.teeSheetSettings?.booking_engine_display_waitlist}
        courseLogo={clientPortalStore?.selectedFacility?.logo_source}
        courseName={clientPortalStore?.selectedFacility?.full_name}
        loginUrl={`/portal/${clientShortName}/login`}
        courseAddressLine={clientPortalStore?.selectedFacility?.address_line_1}
        facilityId={clientPortalStore?.selectedFacility?.id}
        activeUser={!!clientPortalStore?.user}
      />

      <Popup
        open={filterState.adminUserLoggedIn}
        type="warning"
        title="Admin Account Detected"
        description="Looks like you are logged into an admin account. Click logout below to view the tee times"
        onOk={logoutUser}
        okText="Logout"
      />
    </div>
  );
}
