import InternalInput, { IInputProps } from "./Input";
import Password from "./Password";
import "./input.scss";

interface ICompoundedComponent extends React.ForwardRefExoticComponent<IInputProps> {
  Password: typeof Password;
}

const Input = InternalInput as ICompoundedComponent;

Input.Password = Password;

export default Input;
