import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";

import { IUIActions, showSuccess } from "redux/actions/ui";
import { IFacilityActions } from "redux/actions/facility";
import { IFacilityState } from "redux/reducers/facility";

import { IPutTeeSheetSettings } from "api/rpc/2022-09/facilityAdmin/facility/settings";

import { useAppDispatch, useAppSelector } from "hooks/redux";

import Toggle from "components/form/toggle/Toggle";
import Page from "components/page/Page";
import Card from "components/card/Card";
import Input from "components/form/input/Input";
import FormLayout from "components/form/FormLayout";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import TextEditor from "components/textEditor/textEditor";
import { Select } from "components/select/index";
import { ButtonNew as Button } from "components/buttonNew";

import "./teeSheetGeneral.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { handleChangeEventInput } from "helpers/Helpers";
import { isEqualWith } from "lodash";
import ReactDOM from "react-dom";

interface ITeeSheetGeneralProps {
  facilityActions: IFacilityActions;
  uiActions: IUIActions;
}
export default function TeeSheetGeneral(props: ITeeSheetGeneralProps) {
  const { t, i18n } = useTranslation();
  const { facilityActions, uiActions } = props;
  const dispatch = useAppDispatch();

  const facilityStore = useAppSelector(state => state.facilityStore);
  const clientState = useAppSelector(state => state);
  const { Option } = Select;

  const [stateBeforeChanges, setStateBeforeChanges] = useState(undefined);
  // const [cardReaderLoaded, setCardReaderLoaded] = useState<Boolean>(false);

  const [state, setState] = useState<IPutTeeSheetSettings>({
    web_cancellation_hours: null,
    web_edit_hours: null,
    enable_web_booking: false,
    max_booking_size: 16,
    display_customer_type: false,
    booking_terms: "",
    payment_terms: "",
    single_bookings: "",
    twosome_bookings: "",
    threesome_bookings: "",
    foursome_bookings: "",
    enable_cart_bookings: null,
    display_online_pricing: false,
    power_cart_maintenance_time: 0,
    power_cart_nine_hole_time: 0,
    power_cart_eighteen_hole_time: 0,
    track_cart_numbers: false,
    display_booking_graph: false,
  });

  const [dates, setDates] = useState({
    open_date: new Date(),
    close_date: new Date(),
  });

  const [dateBeforeChanges, setDateBeforeChanges] = useState(undefined);

  const [link, setLink] = useState({
    clientShortName: "",
    facilityShortName: "",
  });

  useEffect(() => {
    setLink(prevState => ({
      ...prevState,
      facilityShortName: facilityStore?.facility?.short_name,
      clientShortName: facilityStore?.facility?.client?.short_name,
    }));
  }, [facilityStore?.facility]);

  // useEffect(() => {
  //   setLink((prevState) => ({
  //     ...prevState,
  //     facilityShortName: facilityStore?.facility?.short_name,
  //     clientShortName: facilityStore?.facility?.client?.short_name,
  //   }));
  // }, [facilityStore?.facility]);

  //stop unnecessary re-assignment when facilityStore changes
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    const settings = facilityStore.teeSheetSettings;

    if (settings && !isLoaded) {
      setState({
        web_cancellation_hours: settings.web_cancellation_hours,
        web_edit_hours: settings.web_edit_hours,
        enable_web_booking: !!settings.enable_web_booking,
        max_booking_size: settings.max_booking_size,
        display_customer_type: !!settings.display_customer_type,
        booking_terms: settings.booking_terms,
        payment_terms: settings.payment_terms,
        single_bookings: settings.single_bookings,
        twosome_bookings: settings.twosome_bookings,
        threesome_bookings: settings.threesome_bookings,
        foursome_bookings: settings.foursome_bookings,
        enable_cart_bookings: !!settings.enable_cart_bookings,
        display_online_pricing: !!settings.display_online_pricing,
        power_cart_maintenance_time: settings.power_cart_maintenance_time,
        power_cart_nine_hole_time: settings.power_cart_nine_hole_time,
        power_cart_eighteen_hole_time: settings.power_cart_eighteen_hole_time,
        track_cart_numbers: !!settings.track_cart_numbers,
        display_booking_graph: !!settings.display_booking_graph,
      });

      if (settings.open_date && settings.close_date) {
        const splitOpenDate: string[] = settings.open_date.split("-");
        const splitCloseDate: string[] = settings.close_date.split("-");
        setDates({
          open_date: new Date(parseInt(splitOpenDate[0]), parseInt(splitOpenDate[1]) - 1, parseInt(splitOpenDate[2])),
          close_date: new Date(
            parseInt(splitCloseDate[0]),
            parseInt(splitCloseDate[1]) - 1,
            parseInt(splitCloseDate[2]),
          ),
        });
      }

      setIsLoaded(true);
    }
  }, [facilityStore.teeSheetSettings]);

  function saveTeeSheetSettings() {
    if (dates.close_date < dates.open_date) {
      uiActions.showError(t("secure.facility.settings.tee_sheets.tee_sheet_general.010"));
    }

    // aritrary booking size limits
    if (state.max_booking_size > 64) {
      uiActions.showError(t("secure.facility.settings.tee_sheets.tee_sheet_general.011"));
      return;
    } else if (state.max_booking_size < 5) {
      uiActions.showError(t("secure.facility.settings.tee_sheets.tee_sheet_general.012"));
      return;
    }

    const putParams: IPutTeeSheetSettings = {
      web_cancellation_hours: state.web_cancellation_hours,
      web_edit_hours: state.web_edit_hours,
      enable_web_booking: state.enable_web_booking,
      enable_cart_bookings: state.enable_cart_bookings,
      max_booking_size: state.max_booking_size,
      open_date: dates.open_date.toISOString().slice(0, 10),
      close_date: dates.close_date.toISOString().slice(0, 10),
      display_customer_type: state.display_customer_type,
      booking_terms: state.booking_terms,
      payment_terms: state.payment_terms,
      single_bookings: state.single_bookings,
      twosome_bookings: state.twosome_bookings,
      threesome_bookings: state.threesome_bookings,
      foursome_bookings: state.foursome_bookings,
      display_online_pricing: state.display_online_pricing,
      power_cart_maintenance_time: state.power_cart_maintenance_time,
      power_cart_nine_hole_time: state.power_cart_nine_hole_time,
      power_cart_eighteen_hole_time: state.power_cart_eighteen_hole_time,
      track_cart_numbers: state.track_cart_numbers,
      display_booking_graph: state.display_booking_graph,
    };

    ReactDOM.unstable_batchedUpdates(() => {
      setStateBeforeChanges(state);
      void facilityActions.updateFacilityTeeSheetSettings(putParams, true);
    });
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setState(prevState => ({ ...prevState, [id]: value === "" ? 0 : Number(value) }));
  }

  const primaryAction = {
    content: t("secure.facility.settings.tee_sheets.tee_sheet_general.001"),
    action: saveTeeSheetSettings,
  };

  function handleToggleChange(event: any) {
    const { id, checked } = event.target;
    setState(prevState => ({ ...prevState, [id]: checked }));
  }

  function handleBookingTermsChange(terms: string) {
    setState(prevState => ({ ...prevState, booking_terms: terms }));
  }

  function handlePaymentTermsChange(terms: string) {
    setState(prevState => ({ ...prevState, payment_terms: terms }));
  }

  function handleDropDownChange(value: string, key: string) {
    setState(prevState => ({ ...prevState, [key]: value }));
  }

  const dropdownOptions = [
    { label: "Allow", value: "allow" },
    { label: "None", value: "none" },
    { label: "Allow within group", value: "allow_within_group" },
  ];

  async function writeTextToClipBoard(link: string) {
    await navigator.clipboard.writeText(link);
    dispatch(showSuccess(t("secure.facility.tournament.tournament.003")));
  }

  function unsavedChangesExist() {
    if (stateBeforeChanges === undefined) {
      if (isLoaded) {
        setStateBeforeChanges(state);
        setDateBeforeChanges(dates);
      }
      return false;
    }

    return !isEqualWith(stateBeforeChanges, state) || !isEqualWith(dateBeforeChanges, dates);
  }

  function cancelUnsavedChanges() {
    setState(stateBeforeChanges);
    setDates(dateBeforeChanges);
  }

  return (
    <Page
      title={t("secure.facility.settings.tee_sheets.tee_sheet_general.002")}
      narrow
      primaryAction={primaryAction}
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: saveTeeSheetSettings,
        onCancel: cancelUnsavedChanges,
      }}
    >
      <Card>
        <Card.Section>
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Toggle
                  labelTop={t("secure.facility.settings.tee_sheets.tee_sheet_general.003")}
                  id="enable_web_booking"
                  checked={!!state.enable_web_booking || false}
                  onChange={handleToggleChange}
                />
                <Toggle
                  labelTop={"Enable Cart Bookings"}
                  id="enable_cart_bookings"
                  checked={!!state.enable_cart_bookings || false}
                  onChange={handleToggleChange}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Toggle
                  labelTop={t("secure.facility.settings.tee_sheets.tee_sheet_general.016")}
                  id="display_customer_type"
                  checked={!!state.display_customer_type || false}
                  onChange={handleToggleChange}
                />
                <Toggle
                  labelTop={"Display Online Pricing"}
                  id="display_online_pricing"
                  checked={!!state.display_online_pricing || false}
                  onChange={handleToggleChange}
                />
                <Toggle
                  labelTop={"Display Booking Graph"}
                  id="display_booking_graph"
                  checked={!!state.display_booking_graph || false}
                  onChange={handleToggleChange}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Toggle
                  labelTop={"Track Cart Numbers"}
                  id="track_cart_numbers"
                  checked={!!state.track_cart_numbers || false}
                  onChange={handleToggleChange}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Input
                  value={state.web_cancellation_hours || ""}
                  label={t("secure.facility.settings.tee_sheets.tee_sheet_general.004")}
                  id="web_cancellation_hours"
                  helpText={t("secure.facility.settings.tee_sheets.tee_sheet_general.005")}
                  onChange={handleInputChange}
                  suffix="hours"
                  placeholder={t("secure.facility.settings.tee_sheets.tee_sheet_general.006")}
                />
                <Input
                  value={state.web_edit_hours || ""}
                  label={t("secure.facility.settings.tee_sheets.tee_sheet_general.007")}
                  id="web_edit_hours"
                  helpText={t("secure.facility.settings.tee_sheets.tee_sheet_general.008")}
                  onChange={handleInputChange}
                  suffix="hours"
                  placeholder={t("secure.facility.settings.tee_sheets.tee_sheet_general.009")}
                />
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
        <Card.Section>
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <div className="open-close-date">
                  <div>
                    <span className="label-span">
                      {t("secure.facility.settings.tee_sheets.tee_sheet_general.013")} {/* TODO: Translation */}
                    </span>
                    <DatePickerInput
                      startingDate={dates.open_date}
                      setStartingDate={(date: Date) => setDates({ ...dates, open_date: date })}
                      months={1}
                      position="left"
                    />
                  </div>
                </div>
                <div className="open-close-date">
                  <div>
                    <span className="label-span">
                      {t("secure.facility.settings.tee_sheets.tee_sheet_general.014")}
                      {/* TODO: Translation */}
                    </span>
                    <DatePickerInput
                      startingDate={dates.close_date}
                      setStartingDate={(date: Date) => setDates({ ...dates, close_date: date })}
                      months={1}
                      position="right"
                    />
                  </div>
                </div>
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
        <Card.Section>
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  label={t("secure.facility.settings.tee_sheets.tee_sheet_general.015")} // TODO: Translation
                  type="number"
                  id="max_booking_size"
                  min={5} //necessary because of UI layout
                  value={state.max_booking_size}
                  onChange={handleInputChange}
                />
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
        <Card.Section>
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Select
                  label={"Single Bookings"}
                  defaultValue={state.single_bookings}
                  onChange={(value: string) => handleDropDownChange(value, "single_bookings")}
                >
                  {dropdownOptions.map((option, index) => (
                    <Option key={index} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
                {/* <Select
                  label={"Twosome Bookings"}
                  defaultValue={state.twosome_bookings}
                  onChange={(value: string) => handleDropDownChange(value, "twosome_bookings")}
                >
                  {dropdownOptions.map((option, index) => (
                    <Option key={index} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select> */}
              </FormLayout.Group>
              {/* <FormLayout.Group>
                <Select
                  label={"Threesome Bookings"}
                  defaultValue={state.threesome_bookings}
                  onChange={(value: string) => handleDropDownChange(value, "threesome_bookings")}
                >
                  {dropdownOptions.map((option, index) => (
                    <Option key={index} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
                <Select
                  label={"Foursome Bookings"}
                  defaultValue={state.foursome_bookings}
                  onChange={(value: string) => handleDropDownChange(value, "foursome_bookings")}
                >
                  {dropdownOptions.map((option, index) => (
                    <Option key={index} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </FormLayout.Group> */}
            </FormLayout>
          </Form>
        </Card.Section>
        <Card.Section>
          <TextEditor
            markdownText={state.booking_terms ?? ""}
            markdownTextOnChange={handleBookingTermsChange}
            label={t("secure.facility.settings.tee_sheets.tee_sheet_general.017")}
          />
          <TextEditor
            markdownText={state.payment_terms ?? ""}
            markdownTextOnChange={handlePaymentTermsChange}
            label={t("secure.facility.settings.tee_sheets.tee_sheet_general.018")}
          />
        </Card.Section>
        <Card.Section>
          <span className="label-span">
            {"Power Cart Settings"} {/* TODO: Translation */}
          </span>
          <FormLayout>
            <FormLayout.Group>
              <Input
                label={"Maintenance Time"} // TODO: Translation
                type="number"
                id="power_cart_maintenance_time"
                value={state.power_cart_maintenance_time === null ? 0 : state.power_cart_maintenance_time}
                onChange={handleInputChange}
                suffix="Minutes"
              />
              <Input
                label={"9 Hole Time"} // TODO: Translation
                type="number"
                id="power_cart_nine_hole_time"
                value={state.power_cart_nine_hole_time === null ? 0 : state.power_cart_nine_hole_time}
                onChange={handleInputChange}
                suffix="Minutes"
              />
              <Input
                label={"18 Hole Time"} // TODO: Translation
                type="number"
                id="power_cart_eighteen_hole_time"
                value={state.power_cart_eighteen_hole_time === null ? 0 : state.power_cart_eighteen_hole_time}
                onChange={handleInputChange}
                suffix="Minutes"
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>

        <Card.Section title={"Links"}>
          <Input
            disabled={true}
            value={window.origin + `/portal/${link?.clientShortName}/teetimes/${link?.facilityShortName}`}
            id="portal_link"
            onChange={e => handleChangeEventInput(e, link, setLink)}
            trailingButtons={[
              <Button
                key={1}
                onClick={() =>
                  writeTextToClipBoard(
                    window.origin + `/portal/${link?.clientShortName}/teetimes/${link?.facilityShortName}`,
                  )
                }
              >
                {" "}
                <FontAwesomeIcon icon={["far", "copy"]} style={{ marginRight: "5px" }} /> Copy {/* TODO: Translation */}
              </Button>,
            ]}
          />
          <Input
            disabled={true}
            value={window.origin + "/tee-on/portal/login"}
            id="portal_login_link"
            onChange={e => handleChangeEventInput(e, link, setLink)}
            trailingButtons={[
              <Button key={1} onClick={() => writeTextToClipBoard(window.origin + "/tee-on/portal/login")}>
                {" "}
                <FontAwesomeIcon icon={["far", "copy"]} style={{ marginRight: "5px" }} /> Copy {/* TODO: Translation */}
              </Button>,
            ]}
          />
        </Card.Section>
      </Card>
    </Page>
  );
}
