import React, { Dispatch, useRef, useMemo, useState, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { monthNames } from "helpers/Helpers";
import useOutsideAlerter from "hooks/useOutsideAlerter/useOutsideAlerter";

import DateChangePanel from "./DateChangePanel";

type TCalendarHeader = {
  selectedDate: Date;
  onDateChange: (date: Date) => void;
  viewState: TViewState;
  setViewState: Dispatch<React.SetStateAction<TViewState>>;
};

export type TViewState = "week" | "month" | "agenda" | undefined | null;

/** Handles Calendar date selection */
export default function CalendarHeader(props: TCalendarHeader) {
  const { t } = useTranslation();
  const { selectedDate, onDateChange, viewState, setViewState } = props;

  const datePanelRef = useRef<HTMLDivElement>(null);
  const calendarHeadingRef = useRef<HTMLDivElement>(null);

  const [dateChangePanelOpen, setDateChangePanelOpen] = useState(false);

  const [panelPosition, setPanelPosition] = useState({
    open: null,
    closed: null,
  });

  const months = useMemo(() => {
    return monthNames(t).map((monthName, index) => {
      const month = {
        id: index + 1,
        month: monthName,
      };
      return month;
    });
  }, []);

  const years = useMemo(() => {
    const returnYearArray: { id: number; year: number }[] = [];
    let startingYear = new Date().getFullYear() - 5;
    const endingYear = new Date().getFullYear() + 5;

    for (let i = startingYear; i <= endingYear; i++) {
      const year = {
        id: startingYear,
        year: startingYear,
      };
      returnYearArray.push(year);
      startingYear++;
    }

    return returnYearArray;
  }, []);

  useLayoutEffect(() => {
    setPanelPosition({
      open: calendarHeadingRef.current ? calendarHeadingRef.current.clientHeight : null,
      closed: datePanelRef.current ? -datePanelRef.current.clientHeight : null,
    });
  }, []);

  useOutsideAlerter(calendarHeadingRef, () => {
    setDateChangePanelOpen(false);
  });

  function handleMonthAdjustment(indexedMonth: number) {
    const newDate = new Date(selectedDate.getFullYear(), indexedMonth);
    newDate.setMonth(newDate.getMonth());
    newDate.setDate(selectedDate.getDate());
    onDateChange(newDate);
  }

  return (
    <div ref={calendarHeadingRef} className="ui-calendar-wrapper">
      <div className="ui-calendar-quick-select">
        <button
          className="ui-calendar-navigation-left"
          onClick={e => handleMonthAdjustment(selectedDate?.getMonth() - 1)}
          disabled={!selectedDate}
        >
          <FontAwesomeIcon icon={["far", "chevron-left"]} />
        </button>

        <div
          className={`calendar-display${viewState === null ? " disabled" : ""}`}
          onClick={() => (viewState !== null ? setDateChangePanelOpen(prev => !prev) : undefined)}
        >
          <p>{months.filter(val => val.id === selectedDate?.getMonth() + 1)[0]?.month}</p>
          <p>{years.filter(val => val.id === selectedDate?.getFullYear())[0]?.year}</p>
        </div>

        <button
          className="ui-calendar-navigation-right"
          onClick={e => handleMonthAdjustment(selectedDate?.getMonth() + 1)}
          disabled={!selectedDate}
        >
          <FontAwesomeIcon icon={["far", "chevron-right"]} />
        </button>
      </div>

      <DateChangePanel
        ref={datePanelRef}
        topPosition={dateChangePanelOpen ? panelPosition.open : panelPosition.closed}
        closePanel={() => setDateChangePanelOpen(false)}
        selectedDate={selectedDate}
        setSelectedDate={onDateChange}
      />
    </div>
  );
}
