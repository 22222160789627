import React, { ChangeEvent, useEffect, useMemo } from "react";
import { TFunction, useTranslation } from "react-i18next";

import { addFilter, applyDefaults, removeFilter } from "redux/actions/filters/filters";
import { ComponentKey, OrderFilterGrouping } from "redux/reducers/filters/types";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useFilterAccordionContext } from "components/accordion/Accordion";
import Checkbox from "components/form/checkbox/Checkbox";

import "./filterList.scss";

type FilterList = {
  /** Value provided by FilterAccordionContext takes precedent if present. */
  collection?: ComponentKey;
  defaultValues?: SalesChannelKey[];
  condensed?: boolean;
};

export type SalesChannelKey = "online_store" | "pos" | "booking_engine";

const salesChannelFilters = (t: TFunction<"translation", undefined>) => [
  {
    id: "online_store",
    label: t("secure.facility.order.order_filters.016"),
  },
  {
    id: "pos",
    label: t("secure.facility.order.order_filters.015"),
  },
  {
    id: "booking_engine",
    label: t("secure.facility.order.order_filters.017"),
  },
];

export default function SalesChannelFilterList(props: FilterList) {
  const { t } = useTranslation();

  const filters = useAppSelector(store => store.orderPageFilters);
  const dispatch = useAppDispatch();
  const { collectionContext } = useFilterAccordionContext();

  const labelArray = useMemo(() => {
    const active = salesChannelFilters(t).filter(val =>
      filters.some(condition => condition.value.includes(val.id)),
    );
    return active.map(filter => filter.label);
  }, [filters]);

  // Apply default filters
  useEffect(() => {
    if (props.defaultValues && !filters.find(group => group.label === "sales_channels")) {
      const defaultActive = salesChannelFilters(t).filter(val =>
        props.defaultValues.some(condition => condition === val.id),
      );

      dispatch(
        applyDefaults<OrderFilterGrouping>({
          collectionName: collectionContext ? collectionContext : props.collection,
          key: "sales_channels",
          value: props.defaultValues,
          displayLabels: defaultActive.map(active => active.label),
        }),
      );
    }
  }, [props.defaultValues]);

  function listItemClick(prevChecked: boolean, id: string) {
    const newFilter = salesChannelFilters(t).filter(val => val.id === id)[0];
    const newLabel = salesChannelFilters(t)
      .filter(val => labelArray.includes(val.label) || val.label === newFilter.label)
      .map(ele => ele.label);

    if (!prevChecked) {
      dispatch(
        addFilter<OrderFilterGrouping>({
          collectionName: collectionContext ? collectionContext : props.collection,
          key: "sales_channels",
          value: id,
          displayLabels: newLabel,
        }),
      );
      return;
    }

    // remove filter condition if unchecked
    dispatch(
      removeFilter<OrderFilterGrouping>({
        collectionName: collectionContext ? collectionContext : props.collection,
        key: "sales_channels",
        value: id,
        displayLabels: labelArray.filter(val => val !== newFilter.label),
      }),
    );
  }

  return (
    <ul className={`filter-list${props.condensed ? " condensed" : ""}`}>
      {salesChannelFilters(t).map((channel, index) => {
        const checked = filters.some(condition => {
          return condition.value.includes(channel.id);
        });

        return (
          <li key={index} onClick={() => listItemClick(checked, channel.id)}>
            <Checkbox
              checked={checked}
              ariaChecked={checked}
              onChange={(e: ChangeEvent<HTMLInputElement>) => e.stopPropagation()}
              label={channel.label}
              size="small"
            />
          </li>
        );
      })}
    </ul>
  );
}
