import React, { useMemo, useState } from "react";

import { clearFilter } from "redux/actions/filters/filters";
import { DBFilterContext, isPMFilter } from "redux/reducers/filters/filters";
import { FilterKey } from "redux/reducers/filters/types";

import { useAppDispatch, useAppSelector } from "hooks/redux";

import { IRegister } from "pages/secure/facility/Admin";
import { IPaymentOption } from "redux/reducers/models/transaction";
import { IFacilityStaff } from "redux/reducers/models/facility";

import Accordion, { AccordionProps } from "components/accordion/Accordion";
import AccordionItem from "components/accordion/AccordionItem";
import CreditCardFilter from "components/filters/accordion/CreditCardFilter";
import RegistersFilterList from "components/filters/accordion/RegistersFilterList";
import PaymentTypeFilterList from "components/filters/accordion/PaymentTypeFilterList";
import FinancialStatusFilterList from "components/filters/accordion/FinancialStatusFilterList";
import FacilityStaffFilterList from "components/filters/accordion/FacilityStaffFilterList";
import SalesChannelFilterList from "components/filters/accordion/SalesChannelFilterList";
import PriceMatchFilterList from "components/filters/accordion/PriceMatchFilterList";

type OrderFilterAccordionProps = Omit<AccordionProps, "collection" | "applyAction" | "clearAction"> &
  OrderFilterGroups & {
    applyAllAction?: (filterSnapshot: DBFilterContext[]) => void;
    clearAllAction?: (filterSnapshot: DBFilterContext[]) => void;
  };

type OrderFilterGroups = {
  date?: boolean;
  paymentStatus?: boolean;
  staff?: IFacilityStaff[]; // async
  registers?: IRegister[]; // async
  channels?: boolean;
  priceMatch?: boolean;
  creditCard?: boolean;
  paymentType?: IPaymentOption[]; //async
};

export default function OrderFilterAccordion(props: OrderFilterAccordionProps) {
  const filters = useAppSelector(store => store.orderPageFilters);
  const dispatch = useAppDispatch();

  const {
    applyAllAction,
    clearAllAction,

    //OrderFiltergroups
    date = false,
    paymentStatus = false,
    staff,
    registers,
    channels = false,
    priceMatch = false,
    creditCard = false,
    paymentType,

    // AccordionProps
    activeFilters,
    isLoading,
    rightAligned = false,
    allFiltersAreApplied = false,
  } = props;

  const [openIndex, setOpenIndex] = useState<{ [x in keyof OrderFilterGroups]: boolean }>({
    date: false,
    paymentStatus: false,
    staff: false,
    registers: false,
    channels: false,
    priceMatch: false,
    creditCard: false,
    paymentType: false,
  });

  // Most current display strings of each filter group
  const activeFinancialStatusString = useMemo(() => {
    if (!props.activeFilters) {
      return "";
    }
    if (props.activeFilters.some(ele => ele.label === "financial_status")) {
      return props.activeFilters.filter(value => value.label === "financial_status")[0].normalizedString;
    }
    return "";
  }, [props.activeFilters]);

  const activeSalesChannelString = useMemo(() => {
    if (!props.activeFilters) {
      return "";
    }
    if (props.activeFilters.some(ele => ele.label === "sales_channels")) {
      return props.activeFilters.filter(value => value.label === "sales_channels")[0].normalizedString;
    }
    return "";
  }, [props.activeFilters]);

  const activePriceMatchString = useMemo(() => {
    if (!props.activeFilters) {
      return "";
    }

    if (
      props.activeFilters.some(
        ele =>
          ele.label === "total_equal_to" || ele.label === "total_greater_than" || ele.label === "total_less_than",
      )
    ) {
      console.log(
        "hmmm",
        props.activeFilters
          .filter(
            value =>
              value.label === "total_equal_to" ||
              value.label === "total_greater_than" ||
              value.label === "total_less_than",
          )
          .filter(ele => ele.value.length !== 0),
      );

      return props.activeFilters
        .filter(
          value =>
            value.label === "total_equal_to" ||
            value.label === "total_greater_than" ||
            value.label === "total_less_than",
        )
        .filter(ele => ele.value.length !== 0)
        .map(value => {
          return `${value.normalizedString} $${value.value[0] as string}`;
        })[0];
    }

    return "";
  }, [props.activeFilters]);

  const activeStaffString = useMemo(() => {
    if (!props.activeFilters || props.isLoading) {
      return "";
    }
    if (props.activeFilters.some(ele => ele.label === "user_ids")) {
      return props.activeFilters.filter(value => value.label === "user_ids")[0].normalizedString;
    }
    return "";
  }, [props.activeFilters, props.isLoading]);

  const activeRegisterString = useMemo(() => {
    if (!props.activeFilters || props.isLoading) {
      return "";
    }
    if (props.activeFilters.some(ele => ele.label === "register_ids")) {
      return props.activeFilters.filter(value => value.label === "register_ids")[0].normalizedString;
    }
    return "";
  }, [props.activeFilters, props.isLoading]);

  const activePaymentTypeString = useMemo(() => {
    if (!props.activeFilters || props.isLoading) {
      return "";
    }
    if (props.activeFilters.some(ele => ele.label === "payment_type")) {
      return props.activeFilters.filter(value => value.label === "payment_type")[0].normalizedString;
    }
    return "";
  }, [props.activeFilters, props.isLoading]);

  const activeCreditCardString = useMemo(() => {
    if (!props.activeFilters) {
      return "";
    }
    if (props.activeFilters.some(ele => ele.label === "credit_card")) {
      return props.activeFilters.filter(value => value.label === "credit_card")[0].normalizedString;
    }
    return "";
  }, [props.activeFilters]);

  function allFiltersApply() {
    closeAllAccordions();
    props.applyAllAction && applyAllAction(filters);
  }

  function allFiltersClear() {
    dispatch(clearFilter({ collectionName: "orders" }));
    closeAllAccordions();

    // remove unused filters
    const filterSnapshot: DBFilterContext[] = props.activeFilters
      .filter(grouping => grouping.value.length !== 0)
      .map(condition => {
        return {
          ...condition,
          value: [],
          normalizedString: "",
        };
      });

    props.clearAllAction && clearAllAction(filterSnapshot);
  }

  function clearFilterGroup(key: FilterKey, index: keyof OrderFilterGroups) {
    dispatch(clearFilter({ collectionName: "orders", key: key }));

    // Close accordion if open
    if (openIndex[index]) {
      setOpenIndex(prev => ({ ...prev, [index]: false }));
    }

    // Return early if trying to apply empty values
    if (
      (filters.some(condition => condition.label === key) &&
        filters.find(ele => ele.label === key).value.length === 0) ||
      !filters.some(condition => condition.label === key)
    ) {
      return;
    }

    const filterSnapshot = props.activeFilters
      .filter(grouping => grouping.value.length !== 0)
      .map(condition => {
        if (condition.label === key) {
          return {
            ...condition,
            value: [],
            normalizedString: "",
          };
        } else {
          return condition;
        }
      });

    props.clearAllAction(filterSnapshot);
  }

  function closeAllAccordions() {
    setOpenIndex({
      date: false,
      paymentStatus: false,
      staff: false,
      registers: false,
      channels: false,
      priceMatch: false,
      creditCard: false,
      paymentType: false,
    });
  }

  const activePriceMatchKey = () => {
    const activeGroups = filters.filter(group => isPMFilter(group.label));
    return activeGroups.filter(ele => ele.value.length > 0)[0].label;
  };

  return (
    <Accordion
      collection={"orders"}
      activeFilters={activeFilters ? activeFilters : []}
      applyAction={() => allFiltersApply()}
      clearAction={() => allFiltersClear()}
      allFiltersAreApplied={allFiltersAreApplied}
      rightAligned={rightAligned}
      isLoading={isLoading}
    >
      {/* {date ? (
        <AccordionItem
          title={"Date"}
          open={openIndex.date}
          // helpText={props.activeFilters?.filter(collection => collection.label === "date").length > 0 ? filters.filter(collection => collection.label === "date")[0].normalizedString : ""}
          toggle={() => setOpenIndex(prev => ({ ...prev, date: !openIndex.date }))}
          relative
        ></AccordionItem>
      ) : null} */}

      {paymentStatus ? (
        <AccordionItem
          title={"Payment Status"}
          open={openIndex.paymentStatus}
          toggle={() => setOpenIndex(prev => ({ ...prev, paymentStatus: !openIndex.paymentStatus }))}
          helpText={activeFinancialStatusString}
          chevronClick={() => clearFilterGroup("financial_status", "paymentStatus")}
          relative
        >
          <FinancialStatusFilterList collection="orders" />
        </AccordionItem>
      ) : null}

      {staff || staff === undefined ? (
        <AccordionItem
          title="Staff"
          open={openIndex.staff}
          toggle={() => setOpenIndex(prev => ({ ...prev, staff: !openIndex.staff }))}
          helpText={activeStaffString}
          chevronClick={() => clearFilterGroup("user_ids", "staff")}
          loading={staff === undefined ? true : false}
          relative
        >
          <FacilityStaffFilterList collection="orders" staff={staff} />
        </AccordionItem>
      ) : null}

      {registers || registers === undefined ? (
        <AccordionItem
          title="Registers"
          open={openIndex.registers}
          toggle={() => setOpenIndex(prev => ({ ...prev, registers: !openIndex.registers }))}
          helpText={activeRegisterString}
          chevronClick={() => clearFilterGroup("register_ids", "registers")}
          loading={registers === undefined ? true : false}
          relative
        >
          <RegistersFilterList collection="orders" registers={registers} />
        </AccordionItem>
      ) : null}

      {channels ? (
        <AccordionItem
          title="Channel"
          open={openIndex.channels}
          toggle={() => setOpenIndex(prev => ({ ...prev, channels: !openIndex.channels }))}
          helpText={activeSalesChannelString}
          chevronClick={() => clearFilterGroup("sales_channels", "channels")}
          relative
        >
          <SalesChannelFilterList collection="orders" />
        </AccordionItem>
      ) : null}

      {priceMatch ? (
        <AccordionItem
          title="Price Match"
          open={openIndex.priceMatch}
          toggle={() => setOpenIndex(prev => ({ ...prev, priceMatch: !openIndex.priceMatch }))}
          helpText={activePriceMatchString}
          chevronClick={() => clearFilterGroup(activePriceMatchKey(), "priceMatch")}
          relative
        >
          <PriceMatchFilterList collection="orders" />
        </AccordionItem>
      ) : null}

      {creditCard ? (
        <AccordionItem
          title="Credit Card"
          open={openIndex.creditCard}
          toggle={() => setOpenIndex(prev => ({ ...prev, creditCard: !openIndex.creditCard }))}
          helpText={activeCreditCardString}
          chevronClick={() => clearFilterGroup("credit_card", "creditCard")}
          relative
        >
          <CreditCardFilter collection={"orders"} />
        </AccordionItem>
      ) : null}

      {paymentType || paymentType === undefined ? (
        <AccordionItem
          title="Payment Type"
          open={openIndex.paymentType}
          toggle={() => setOpenIndex(prev => ({ ...prev, paymentType: !openIndex.paymentType }))}
          helpText={activePaymentTypeString}
          chevronClick={() => clearFilterGroup("payment_type", "paymentType")}
          loading={paymentType === undefined ? true : false}
          relative
        >
          <PaymentTypeFilterList collection={"orders"} payments={paymentType} />
        </AccordionItem>
      ) : null}
    </Accordion>
  );
}
