import React, { useEffect, useState } from "react";
import { LocaleCurrency } from "helpers/Locale";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/pro-regular-svg-icons";

import "elements/register/register.scss";
import { capitalize } from "helpers/Helpers";

interface IProps {
  currency?: string;
  transactions?: any;
}

export default function CartTransactions(props: IProps) {
  return (
    <div className="flex-1 flex-row mt-4">
      {props.transactions?.map((transaction: any, index: number) => {
        return (
          <div key={index} className="flex flex-row mb-1">
            <span>{capitalize(transaction.payment_type)}</span>
            <span className="ml-auto self-end">
              <LocaleCurrency currency="cad" amount={transaction.amount} />
            </span>
          </div>
        );
      })}
    </div>
  );
}
