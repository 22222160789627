import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";

import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Input from "components/form/input/Input";
import { Select } from "components/select/";
import FormLayout from "components/form/FormLayout";
import Card from "components/card/Card";
import Form from "components/form/Form";
import Toggle from "components/form/toggle/Toggle";

import { isEqualWith, isNull } from "lodash";
import { NotificationType } from "components/notificationBar/NotificationBar";
import { IAuthState } from "redux/reducers/auth";
import { IAuthActions } from "redux/actions/auth";
import { IUIActions } from "redux/actions/ui";
import { isPositiveInteger } from "helpers/Helpers";
import { PostCourse, THoleNumbers } from "api/rpc/2024-04/facilityAdmin/facility/course";

interface ICourseProps {
  authStore: IAuthState;
  authActions: IAuthActions;
  uiActions: IUIActions;
}

interface ICourse {
  long_name: string;
  full_name: string;
  holes: THoleNumbers;
  scoring_only: boolean;
}

export default function CourseNew(props: ICourseProps) {
  const { t, i18n } = useTranslation();
  const { Option } = Select;
  const history = useHistory();

  const [courseState, setCourseState] = useState<ICourse>({
    long_name: "",
    full_name: "",
    holes: null,
    scoring_only: true,
  });

  const [courseStateBeforeChanges, setCourseStateBeforeChanges] = useState<ICourse>(undefined);

  const holeOptions = [6, 9, 12, 18, 27, 36, 54, 72];

  async function createCourse() {
    const postCourseRes = await PostCourse(
      {
        long_name: courseState.long_name,
        full_name: courseState.full_name,
        holes: courseState.holes,
        scoring_only: courseState.scoring_only,
      },
      true,
    );

    if (postCourseRes.status !== StatusCode.OK) {
      props.uiActions.showError("Error creating course");
      return;
    }

    // Go back to page
    history.push("/admin/settings/course");
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setCourseState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleDropDownChange(value: any, property: string) {
    setCourseState(prev => ({ ...prev, [property]: value }));
  }

  function handleToggleChange(event: any) {
    const { id, checked } = event.target;
    setCourseState(prevState => ({ ...prevState, [id]: checked }));
  }

  function unsavedChangesExist() {
    if (courseStateBeforeChanges === undefined) {
      if (courseState) {
        setCourseStateBeforeChanges(courseState);
      }
      return false;
    }

    return !isEqualWith(courseStateBeforeChanges, courseState, (originalValue, newValue) => {
      if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
        return true;
      }
    });
  }

  function cancelUnsavedChanges() {
    setCourseState(courseStateBeforeChanges);
  }

  return (
    <Page
      title="New Course"
      narrow
      breadcrumbs={[
        {
          prefix: true,
          label: "Courses",
          url: "/admin/settings/course",
        },
      ]}
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: createCourse,
        onCancel: cancelUnsavedChanges,
      }}
    >
      {courseState && courseStateBeforeChanges && (
        <Form>
          <Card>
            <Card.Section>
              <FormLayout>
                <FormLayout.Group>
                  <Input value={courseState.long_name} label="Long Name" id="long_name" onChange={handleInputChange} />
                  <Input value={courseState.full_name} label="Full Name" id="full_name" onChange={handleInputChange} />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Select
                    label="Holes"
                    onChange={(value: any) => handleDropDownChange(value, "holes")}
                    defaultValue={18}
                  >
                    {holeOptions.map((holeOption: number, index: number) => {
                      return (
                        <Option key={index} value={holeOption}>
                          {holeOption}
                        </Option>
                      );
                    })}
                  </Select>
                  <div />
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>
          </Card>
        </Form>
      )}
    </Page>
  );
}
