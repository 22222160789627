import { AppDispatch } from "index";
import { IActiveFormResponse } from "redux/reducers/customer/onlineStore";

export function updateProductSearchQuery(productSearchQuery: string) {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: "onlineStore.update",
      payload: {
        productSearchQuery,
      },
    });
  };
}

export function updateActiveFormResponses(activeFormResponses: IActiveFormResponse[]) {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: "onlineStore.update",
      payload: {
        activeFormResponses,
      },
    });
  };
}

export interface IOnlineStoreActions {
  updateProductSearchQuery: typeof updateProductSearchQuery;
  updateActiveFormResponses: typeof updateActiveFormResponses;
}
