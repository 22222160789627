import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";
import ReactDOM from "react-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import validator from "validator";
import classNames from "classnames";

import { StatusCode } from "api/protocols";
import { GetCustomer, PostCustomer } from "api/rpc/2022-09/facilityAdmin/customer/customer";
import {
  GetGiftCard,
  GetGiftCardHistory,
  IGiftCardLog,
  PrintGiftCard,
  PutDisableGiftCard,
  PutGiftCard,
  PrintGiftCardLog,
  PutGiftCardEmail,
  IGiftCard,
} from "api/rpc/2024-04/facilityAdmin/payment/giftCard";
import { GetOrder } from "api/rpc/2022-09/facilityAdmin/order/order";

import { showError, showSuccess } from "redux/actions/ui";
import { ICustomer } from "redux/reducers/models/customer";
import { IOrder } from "redux/reducers/models/order";
import { capitalize, customerErrorMessage, isEmpty, uppercase } from "helpers/Helpers";
import { LocaleCurrency } from "helpers/Locale";
import { useAppDispatch } from "hooks/redux";

import { Badge } from "components/badge/Badge";
import Card from "components/card/Card";
import Page from "components/page/Page";
import Sheet from "components/sheet/Sheet";
import { Select } from "components/select/index";
import GolferCard from "components/bookingPopUp/golferCard/GolferCard";
import Input from "components/form/input";
import Popup from "components/popup/Popup";
import NewCustomer, { ICustomerInfoState } from "components/newCustomer/NewCustomer";
import Spin from "components/spin/spin";

import "./giftCard.scss";

interface IGiftCardState {
  showChangeCustomerModal: boolean;
  customerQuery: string;
  selectedCustomer: ICustomer;
  customerSearching: boolean;
  customerSearchResult: Array<ICustomer>;
  showDisableModal: boolean;
  showEmailModal: boolean;
  emailInput: string;
  emailAddressValid: IInputField;
}

interface INewCustomerState {
  showNewCustomerModal: boolean;
}

interface IInputField {
  isDirty: boolean;
  isValid: boolean;
}

export default function GiftCard() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { giftCardCode } = useParams<{ giftCardCode: string }>();

  const dispatch = useAppDispatch();

  const { Option } = Select;

  const [giftCard, setGiftCard] = useState<IGiftCard>(undefined);
  const [giftCardLogs, setGiftCardLogs] = useState<IGiftCardLog[]>([]); // loading spinner
  const [customer, setCustomer] = useState<ICustomer>(undefined);
  const [order, setOrder] = useState<IOrder>(undefined);

  const [state, setState] = useState<IGiftCardState>({
    showChangeCustomerModal: false,
    customerQuery: "",
    selectedCustomer: null,
    customerSearching: false,
    customerSearchResult: [],
    showDisableModal: false,
    showEmailModal: false,
    emailInput: "",
    emailAddressValid: { isDirty: false, isValid: false },
  });

  const [newCustomerState, setNewCustomerState] = useState<{ showNewCustomerModal: boolean }>({
    showNewCustomerModal: false,
  });

  // load original gift card data
  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadGiftCard(giftCardCode, source.token);
    return () => source.cancel();
  }, [giftCardCode]);

  // load async data associated too the loaded gift card
  useEffect(() => {
    const source = axios.CancelToken.source();
    if (!giftCard) {
      return;
    }

    if (giftCard.customer_id) {
      void loadCustomer(giftCard.customer_id, source.token);
    }

    if (giftCard.order_id) {
      void loadOrder(giftCard.order_id, source.token);
    }

    void loadHistory(giftCard.id, source.token);

    return () => source.cancel();
  }, [giftCard?.id, giftCard?.customer_id, giftCard?.order_id]);

  // Search customer when customer modal is open
  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (async () => {
          if (state.customerQuery !== "") {
            try {
              setState(prevState => ({ ...prevState, customerSearching: true }));
              const customers = await searchCustomer();
              if (mounted) {
                setState(prevState => ({ ...prevState, customerSearchResult: customers, customerSearching: false }));
              }
            } catch (error) {
              console.log("err", error);
            }
            return;
          } else {
            setState(prevState => ({ ...prevState, customerSearchResult: [], customerSearching: false }));
          }
        })();
      }, 1000);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [state.customerQuery]);

  async function loadGiftCard(code: string, token?: CancelToken) {
    const res = await GetGiftCard({ code: code }, token ? false : true, token);
    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK || res.data.length === 0) {
      dispatch(showError(t("secure.facility.order.gift_card.001")));
      history.push("/admin/giftcards");
      return;
    }

    setGiftCard(res.data[0]);
  }

  async function loadHistory(gift_card_id: number, token?: CancelToken) {
    if (giftCardLogs !== undefined) {
      setGiftCardLogs(undefined);
    }
    const historyRes = await GetGiftCardHistory({ gift_card_id }, token ? false : true, token);
    if (token && token.reason) {
      return;
    }
    if (historyRes.status !== StatusCode.OK) {
      dispatch(showError("Error retrieving gift card transaction history")); // TODO: Translation
      setGiftCardLogs([]);
      return;
    }

    const sortedHistory = historyRes.data?.sort((prev, next) =>
      new Date(prev.updated_at) < new Date(next.updated_at)
        ? 1
        : new Date(prev.updated_at) > new Date(next.updated_at)
        ? -1
        : 0,
    );

    setGiftCardLogs(sortedHistory);
  }

  async function loadCustomer(customer_id: number, token?: CancelToken) {
    const customerRes = await GetCustomer({ id: customer_id }, false, token);
    if (token && token.reason) {
      return;
    }
    if (customerRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.order.gift_card.002")));
      return;
    }

    setCustomer(customerRes.data[0]);
  }

  async function loadOrder(orderId: number, token?: CancelToken) {
    const orderRes = await GetOrder({ id: orderId }, false, token);
    if (token && token.reason) {
      return;
    }
    if (orderRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.order.gift_card.003")));
      return;
    }

    setOrder(orderRes.data[0]);
  }

  async function searchCustomer() {
    const customerRes = await GetCustomer({ search: state.customerQuery }, false);
    if (customerRes.status !== StatusCode.OK) {
      return [];
    }
    return customerRes.data;
  }

  async function createNewCustomer(customerInfo: ICustomerInfoState) {
    if (
      !customerInfo.firstName ||
      !customerInfo.lastName ||
      (!customerInfo.emailAddress && !customerInfo.phoneNumber)
    ) {
      dispatch(showError(t("secure.facility.order.gift_card.004")));
    }

    const customerRes = await PostCustomer(
      {
        first_name: customerInfo.firstName,
        last_name: customerInfo.lastName,
        phone: customerInfo.phoneNumber ?? null,
        email: customerInfo.emailAddress ?? null,
      },
      true,
    );

    if (customerRes.status !== StatusCode.OK) {
      dispatch(showError(customerErrorMessage(t, customerRes?.data?.message)));
      return;
    }
    ReactDOM.unstable_batchedUpdates(() => {
      setNewCustomerState(prevState => ({
        ...prevState,
        showNewCustomerModal: false,
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
      }));
      setState(prevState => ({ ...prevState, selectedCustomer: customerRes.data }));
    });
    dispatch(showSuccess(t("secure.facility.order.gift_card.006")));
  }

  async function handleGiftCardPrint() {
    const printRes = await PrintGiftCard({ id: giftCard?.id, last4: giftCard?.last4 }, true);
    if (printRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.order.gift_card.007")));
      return;
    }
    window.open().document.write(printRes.data);
  }

  async function assignCustomer() {
    const updateGiftCardRes = await PutGiftCard(
      { code: giftCardCode, customer_id: state.selectedCustomer?.id, note: "hey" },
      true,
    );
    if (updateGiftCardRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.order.gift_card.008")));
      return;
    }
    dispatch(showSuccess(t("secure.facility.order.gift_card.009")));
    void closeChangeCustomerModal();

    await loadGiftCard(giftCardCode);
  }

  async function disableGiftCard() {
    const disableRes = await PutDisableGiftCard({ id: giftCard.id }, true);
    if (disableRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.order.gift_card.010")));
      return;
    }

    dispatch(showSuccess(t("secure.facility.order.gift_card.011")));
    setState(prevState => ({ ...prevState, showDisableModal: false }));
    await loadGiftCard(giftCardCode);
  }

  async function handleEmailConfirm() {
    const updateRes = await PutGiftCardEmail({ gift_card_id: giftCard.id, customer_email: state.emailInput }, true);
    if (updateRes.status !== StatusCode.OK) {
      dispatch(showError("Error updating gift card")); // TODO: Translations
      return;
    }

    dispatch(showSuccess("Gift Card emailed successfully")); // TODO: Translations
    setState(prevState => ({ ...prevState, showEmailModal: false }));
  }

  async function handleGiftCardLogPrint() {
    const printRes = await PrintGiftCardLog({ id: giftCard.id, last4: giftCard.last4 }, true);
    if (printRes.status !== StatusCode.OK) {
      dispatch(showError("Error printing gift card log")); // TODO: Translation
      return;
    }
    window.open().document.write(printRes.data);
  }

  function closeChangeCustomerModal() {
    setState(prevState => ({
      ...prevState,
      showChangeCustomerModal: false,
      customerQuery: "",
      customerSearchResult: [],
      selectedCustomer: null,
    }));
  }

  function closeEmailModal() {
    setState(prevState => ({
      ...prevState,
      showEmailModal: false,
    }));
  }

  function openNewCustomerSheet() {
    setNewCustomerState(prevState => ({
      ...prevState,
      showNewCustomerModal: true,
    }));
  }

  function handleEmailInput(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    console.log("target", e.target);
    setState(prevState => ({
      ...prevState,
      emailInput: value,
      emailAddressValid: id === "emailAddress" ? isEmailValid(value) : prevState.emailAddressValid,
    }));
  }

  function isEmailValid(email: string) {
    return email === "" ? { isDirty: false, isValid: false } : { isDirty: true, isValid: validator.isEmail(email) };
  }

  return (
    <>
      <Page
        narrow
        splitLayout
        title={t("secure.facility.order.gift_card.012")}
        subtitle={giftCardCode}
        breadcrumbs={[{ prefix: true, label: t("secure.facility.order.gift_card.015"), url: "/admin/giftcards" }]}
        multipleActionDropdownAction={{
          label: "Options" /** TODO: Translations */,
          dropdownProps: {
            alignment: "right",
            options: [
              {
                type: "handler",
                label: t("secure.facility.order.gift_card.013"),
                icon: "print",
                handler: () => handleGiftCardPrint(),
                disabled: giftCard?.status === "disabled" || giftCard?.disabled_at ? true : false,
              },
              {
                type: "handler",
                label: "Print Log" /** TODO: Translations */,
                icon: "print-magnifying-glass",
                handler: () => handleGiftCardLogPrint(),
              },
              {
                type: "handler",
                label: t("secure.facility.order.gift_card.014"),
                icon: "ban",
                handler: () => setState(prevState => ({ ...prevState, showDisableModal: true })),
                disabled: giftCard?.status === "disabled",
              },
              {
                type: "handler",
                label: "Email" /** TODO: Translations */,
                icon: "envelope",
                handler: () =>
                  setState(prevState => ({ ...prevState, emailInput: customer?.email, showEmailModal: true })),
                disabled: giftCard?.status === "disabled",
              },
            ],
          },
        }}
      >
        {giftCard && (
          <>
            <Page.Section twoThirds>
              {/* Gift Card Details Card*/}
              <Card title={t("secure.facility.order.gift_card.016")}>
                <Card.Section>
                  <div className="orders-giftcard">
                    <div className="giftcard-column">
                      <div className="giftcard-row">
                        <p className="giftcard-details-title">{t("secure.facility.order.gift_card.017")}</p>
                        <p className="giftcard-details-value">
                          <LocaleCurrency currency={giftCard?.currency} amount={giftCard?.balance} />
                        </p>
                      </div>
                      <div className="giftcard-row">
                        <p className="giftcard-details-title">{t("secure.facility.order.gift_card.018")}</p>
                        <p className="giftcard-details-value">{uppercase(giftCard?.currency)}</p>
                      </div>
                      <div className="giftcard-row">
                        <p className="giftcard-details-title">{t("secure.facility.order.gift_card.019")}</p>
                        <Badge size="small" type="success">
                          {capitalize(giftCard?.status)}
                        </Badge>
                      </div>
                      {giftCard?.disabled_at && (
                        <div className="giftcard-row">
                          <p className="giftcard-details-title">{t("secure.facility.order.gift_card.020")}</p>
                          <p className="giftcard-details-value">
                            {moment.utc(giftCard?.disabled_at).local().format("LLL")}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </Card.Section>
              </Card>

              <Card title="Recent Transactions">
                {/* Transaction Card */}

                {!giftCardLogs ? (
                  <Card.Section>
                    <div style={{ overflow: "hidden", height: "50px" }}>
                      <Spin />
                    </div>
                  </Card.Section>
                ) : (
                  giftCardLogs?.map((transaction, index: number) => {
                    let title = "";
                    switch (transaction.log_type) {
                      case "print":
                        title = "Receipt printed for gift card"; // TODO: Translation
                        break;
                      case "merged_to":
                        title = transaction.log_description;
                        break;
                      case "merged_from":
                        title = transaction.log_description;
                        break;
                      case "disabled":
                        title = "Gift card was disabled"; // TODO: Translation
                        break;
                      default: // TODO: Translation
                        title = `Order # ${transaction.transaction?.order?.number ?? ""}`;
                        break;
                    }
                    return (
                      <Card.Section
                        title={title}
                        key={index}
                        sectionTitleActions={
                          transaction?.log_type === "credit" || transaction?.log_type === "debit"
                            ? [
                                {
                                  action: () =>
                                    history.push("/admin/order/" + String(transaction?.transaction?.order_id)),
                                  content: t("secure.facility.order.gift_card.024"),
                                },
                              ]
                            : []
                        }
                      >
                        <div className="column">
                          {transaction?.log_type !== "print" && transaction?.log_type !== "disabled" && (
                            <span
                              className={classNames("column-data", {
                                "transaction-debit": transaction?.log_type === "debit",
                                "transaction-credit": transaction?.log_type === "credit",
                              })}
                            >
                              {transaction?.log_type === "debit" ? "-" : transaction?.log_type === "credit" ? "+" : ""}
                              <LocaleCurrency currency="cad" amount={transaction?.change ?? ""} />
                            </span>
                          )}
                          <span> {moment(transaction?.updated_at).format("LLL")}</span>
                        </div>
                        <span className="thin-separator"></span>
                      </Card.Section>
                    );
                  })
                )}

                {/* End Transaction Card*/}
              </Card>
            </Page.Section>

            <Page.Section oneThird>
              <Card
                title={t("secure.facility.order.gift_card.021")}
                titleActions={
                  giftCard?.status === "disabled" || giftCard?.disabled_at
                    ? undefined
                    : [
                        {
                          action: () => setState(prevState => ({ ...prevState, showChangeCustomerModal: true })),
                          content: t("secure.facility.order.gift_card.022"),
                        },
                      ]
                }
              >
                {/* Customer Card */}
                <Card.Section>
                  {customer ? (
                    <div>
                      <p className="text-lg">{customer?.full_name}</p>
                      <p className="text-lg">{customer?.email}</p>
                      <p className="text-lg">{customer?.phone}</p>
                      <p className="text-lg">{customer?.customer_type}</p>
                    </div>
                  ) : (
                    <p>{t("secure.facility.order.gift_card.023")}</p>
                  )}
                </Card.Section>
                {/* End Customer Card */}
              </Card>

              {order && (
                <Card
                  title={`${t("secure.facility.order.gift_card.041")} ${order.name}`}
                  titleActions={[
                    {
                      action: () =>
                        giftCard.order_id ? history.push("/admin/order/" + String(giftCard.order_id)) : {},
                      content: t("secure.facility.order.gift_card.024"),
                    },
                  ]}
                >
                  {/* Gift Card Order Card */}
                  <Card.Section>
                    <div className="orders-giftcard">
                      <div className="giftcard-column">
                        <div className="giftcard-row">
                          <p className="giftcard-details-title">{t("secure.facility.order.gift_card.025")}</p>
                          <Badge size="small" type="success">
                            {capitalize(order.financial_status)}
                          </Badge>
                        </div>
                        <div className="giftcard-row">
                          <p className="giftcard-details-title">{t("secure.facility.order.gift_card.026")}</p>
                          <LocaleCurrency currency={order?.currency} amount={order?.total_price} />
                        </div>
                      </div>
                    </div>
                  </Card.Section>
                  {/* Gift Card Order Card End */}
                </Card>
              )}
            </Page.Section>
          </>
        )}
      </Page>

      {/* Golfer Card */}
      <Sheet
        title={t("secure.facility.order.gift_card.027")}
        open={state.showChangeCustomerModal}
        size="small"
        closable
        onCancel={closeChangeCustomerModal}
        onOk={assignCustomer}
        okText={t("secure.facility.order.gift_card.028")}
        overflow
        okDisabled={!state.selectedCustomer ? true : false}
      >
        {customer !== null ? (
          <div>
            <span>{t("secure.facility.order.gift_card.029")}</span>
            <GolferCard
              email={customer?.email}
              name={customer?.full_name}
              memberCode={customer?.member_code}
              customerType={customer?.customer_type}
              phone={customer?.phone}
            />

            <FontAwesomeIcon className="orders-giftcard-arrow-icon" icon={["far", "arrow-circle-down"]} size="2x" />
          </div>
        ) : null}
        {state.selectedCustomer ? (
          <GolferCard
            closable
            removeGolfer={() =>
              setState(prevState => ({
                ...prevState,
                selectedCustomer: null,
                customerSearchResult: [],
                customerQuery: "",
              }))
            }
            email={state?.selectedCustomer?.email}
            name={state?.selectedCustomer?.full_name}
            memberCode={state?.selectedCustomer?.member_code}
            customerType={state?.selectedCustomer?.customer_type}
            phone={state?.selectedCustomer?.phone}
          />
        ) : (
          <Select
            showSearch
            onSearch={(query: string) => setState(prevState => ({ ...prevState, customerQuery: query }))}
            onChange={(id: number, customer: ICustomer) =>
              setState(prevState => ({ ...prevState, selectedCustomer: customer }))
            }
            placeholder={t("secure.facility.order.gift_card.030")}
            allowClear
            searchValue={state.customerQuery}
            showDropDownOnFocus={true}
            searching={state.customerSearching}
          >
            <div className="ui-select-dropdown-list-item" onClick={() => openNewCustomerSheet()}>
              <p>{t("secure.facility.order.gift_card.031")}</p>
            </div>
            {state.customerSearchResult?.map((customer, index) => {
              return (
                <Option key={index} value={customer.id} name={customer.full_name} extraValues={customer}>
                  <div className="flex justify-between">
                    <div>
                      <div className="text-semibold text-lg">{customer?.full_name}</div>
                      <div className="text-sm text-gray-500">{customer.customer_type}</div>
                      <div className="text-sm text-gray-500">{customer.email}</div>
                      <div className="text-sm text-gray-500">{customer.phone ? customer.phone : null}</div>
                    </div>

                    <div className="font-medium text-base text-gray-500 self-end">{customer.member_code}</div>
                  </div>
                </Option>
              );
            })}
          </Select>
        )}
      </Sheet>

      <NewCustomer
        newCustomerSheetActive={newCustomerState.showNewCustomerModal}
        onCancel={() =>
          setNewCustomerState(prevState => ({
            ...prevState,
            showNewCustomerModal: false,
          }))
        }
        onOk={createNewCustomer}
        searchValue={state.customerQuery}
      />
      {/* End Golfer Card */}

      <Popup
        type="warning"
        open={state.showDisableModal}
        title={t("secure.facility.order.gift_card.038")}
        description={t("secure.facility.order.gift_card.039")}
        closable
        onOk={disableGiftCard}
        onCancel={() => setState(prevState => ({ ...prevState, showDisableModal: false }))}
        okText={t("secure.facility.order.gift_card.040")}
      />

      <Sheet
        title="Email"
        open={state.showEmailModal}
        size="small"
        closable
        onCancel={closeEmailModal}
        onOk={handleEmailConfirm}
        okText={"Send"} /** TODO: Translations */
        okDisabled={
          (state?.emailAddressValid?.isDirty && !state?.emailAddressValid?.isValid) || isEmpty(state?.emailInput)
        }
      >
        <Input
          value={state?.emailInput || ""}
          placeholder="User Email" /** TODO: Translations */
          id="emailAddress"
          onChange={handleEmailInput}
          error={state?.emailAddressValid?.isDirty && !state?.emailAddressValid?.isValid}
        />
      </Sheet>
    </>
  );
}
