import React, { useEffect, useState } from "react";
import classNames from "classnames";
import "components/popover/popover.scss";
import "components/tooltip/tooltip.scss";

const Tooltip = (props: any) => (
  <div className="ui-tooltip">
    <div>{props.children}</div>
    <div className="ui-positioned-overlay">
      <div
        className={classNames("ui-tooltip-overlay", {
          "ui-tooltip-overlay--open": props.active,
        })}
      >
        <div className="ui-tooltip-overlay_content">{props.content}</div>
      </div>
    </div>
  </div>
);

export default Tooltip;
