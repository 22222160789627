import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useOnclickOutside from "hooks/useOnclickOutside/useOnclickOutside";
import useDebounce from "hooks/events/useDebounce";
import Spin from "components/spin/spin";

import "./selectNew.scss";

export type TSelectOption<K> = {
  /** must be unique to the object. */
  id: string | number;
  label: string | number;
  extraValue?: K;
  /** add disable styling to list-item */
  disabled?: boolean;
};

type TSelectNewMainProps<T, K> = {
  /** Connects label to input value. */
  id: string;
  /** Hides right-side icon and their actions. */
  disabled?: boolean;
  /** Displayed strictly with searchable Select component. */
  placeholder?: string;
  /**
   * Allow clearing the value using right-icon action callback. Rendered on input hover.
   * - Single-select component clears search & returns callback.
   * - Multi-select component clears search & returns early (no callback) if search is present.
   */
  onClear?: () => void;
  /** Dropdown values to display and select from. */
  options: TSelectOption<K>[];
  /** Opt-in to manually apply UI style to children of <li> elements found in dropdown. */
  renderOption?: (option: TSelectOption<K>) => JSX.Element;
  /** Dropdown spinner to denote loading dropdown data. */
  isLoading?: boolean;
  /** Manipulate the overall dropdown size. */
  dropdownSize?: "small";
  /**
   * Generate searchbar for select dropdown values.
   * - Input of `type=text` instead of `type=button`.
   * - Searches for match against option label.
   * - number passed is wanted search debounce on user input.  Default = 1s.
   * - Single-select component onBlur sets search string to selected value if found.
   */
  searchable?: boolean | number;
  /** Render out label element for component. Placed above the Select element itself. */
  label?: string;
  /** Render out help text element for component.  Placed below the Select element itself. */
  helpText?: string;
  className?: string;
};

type TSingleSelectNewProps<T = string | number, K = any> = {
  /**
   * Denotes a single-select component.
   * - Currently selected value found within dropdown.
   * - return value is infered from data type of this value.
   * @type {string | number}
   */
  value: T;
  multiValue?: never;
  onChange: (value: T, extraValue: K) => void;
};

type TMultiSelectProps<T = string | number, K = any> = {
  value?: never;
  /**
   * Denotes a multi-select component.
   * - Currently selected values found within dropdown.
   * - return value is infered from primitive data type found in array.
   * @type {string[] | number[]}
   *
   */
  multiValue: Array<T>;
  onChange: (value: T, extraValue: K) => void;
};

export type TSelectNewProps<T, K> = TSelectNewMainProps<T, K> & (TSingleSelectNewProps<T, K> | TMultiSelectProps<T, K>);

/** Passing both `value` & `multiValue` prop will throw a typescript error.
 * - Single-Select functionality - use value
 * - Multi-Select functionality - use multiValue
 * */
export default function SelectNew<T extends string | number = string | number, K = any>(props: TSelectNewProps<T, K>) {
  const {
    id,
    value,
    multiValue,
    onChange,
    disabled,
    placeholder,
    searchable = false,
    onClear,
    options = [],
    renderOption = null,
    isLoading = false,
    dropdownSize,
    helpText,
    label,
    className,
  } = props;

  // Single-select component sets search value to currently selected value when user clicks outside.
  const selectRef = useOnclickOutside(() => {
    setDropdownOpen(prev => false);

    // reset search value to actual value if given
    const currentValue = options.find(val => val.id === value);
    if (currentValue) {
      if (search !== currentValue.label?.toString()) {
        setSearch(currentValue.label.toString());
      }
    } else {
      setSearch("");
    }
  });

  const inputRef = useRef<HTMLInputElement>(null);
  const iconRef = useRef(null);

  const [hovered, setHovered] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(
    search,
    typeof searchable !== undefined && typeof searchable === "number" ? searchable : 1000,
  );

  /** With async options passed, ensure search string is sync'd with value */
  useEffect(() => {
    if (value === null && search.length > 0) {
      setSearch("");
      return;
    }

    if (options.some(val => val.id === value)) {
      const searchStart = options.find(val => val.id === value);
      setSearch(searchStart.label as string);
    }
  }, [value, options]);

  const onValueSelect = useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>, id: string | number, extraValue: K = undefined) => {
      event.stopPropagation();
      event.preventDefault();
      onChange(id as T, extraValue);

      const searchLabel = options.find(val => val.id === id)?.label;

      // single-select values
      if (typeof multiValue === "undefined") {
        if (value !== id) {
          setSearch(String(searchLabel));
          setDropdownOpen(false);
        }
      }

      // multi-select values
      if (typeof value === "undefined") {
        return;
      }
    },
    [value, multiValue, options],
  );

  /** Prioritize clearing strictly search value if one is given.  Clearing without a search clears selected values. */
  const onValueClear = useCallback(() => {
    // Prioritize search clear only if present & multiSelect
    if (search.length > 0 && typeof value === "undefined") {
      setSearch("");
      return;
    }

    // clear selected values
    if (search.length > 0) {
      setSearch("");
    }

    onClear();
  }, [value, multiValue, options, search]);

  function onSearch(e: ChangeEvent<HTMLInputElement>) {
    setSearch(e.target.value);

    // auto-select dropdown value if matches search string ??
    // if(options.some(val => val.label.toString().toLowerCase() === e.target.value.toLowerCase())) {
    //     const selectedFromSearch = options.find(val => val.label.toString().toLowerCase() === e.target.value.toLowerCase());
    //     onChange(selectedFromSearch.id as T, selectedFromSearch.extraValue);
    // }
  }

  return (
    <div className={`ui-select_element ${className ?? ""}`} onClick={e => inputRef.current?.focus()}>
      <label htmlFor={`input-element-search_${id}`}>{label}</label>
      <div
        ref={selectRef}
        className="ui-select_content"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {searchable && (
          <span className="ui-select_left_icon">
            <FontAwesomeIcon icon={["far", "magnifying-glass"]} />
          </span>
        )}

        {searchable ? (
          <input
            id={`input-element-search_${id}`}
            ref={inputRef}
            className={`ui-input_element searchable`}
            type="text"
            value={search}
            onChange={onSearch}
            onClick={e => setDropdownOpen(() => true)}
            disabled={disabled}
            aria-disabled={disabled}
            placeholder={placeholder}
            autoComplete="off"
          />
        ) : (
          <input
            id={`input-element-btn_${id}`}
            ref={inputRef}
            className={`ui-input_element${
              !options.some(val => val.id === value) && placeholder ? " placeholder" : ""
            }`}
            type="button"
            value={
              !options.some(val => val.id === value)
                ? placeholder ?? ""
                : options.find(val => val.id === value).label
            }
            onClick={e => setDropdownOpen(prev => !prev)}
            disabled={disabled}
            aria-disabled={disabled}
          />
        )}
        {!disabled ? (
          <span ref={iconRef} className={`ui-select_right_icon`}>
            <RightIcon
              clearable={onClear && hovered && (!!value || search.length > 0 || multiValue?.length > 0)}
              dropdownOpen={dropdownOpen}
              clearClick={onValueClear}
              carrotClick={() => setDropdownOpen(prev => (searchable ? true : !prev))}
              hideCarrots={searchable as boolean}
            />
          </span>
        ) : null}
      </div>

      <div ref={selectRef} className={`ui-select_dropdown ${dropdownSize ?? ""}${dropdownOpen ? " open" : ""}`}>
        <ul id="ui-rc-select_new_dropdown">
          {isLoading ? (
            <li className="ui-select_dropdown_item ui-select_dropdown_spinner">
              <Spin />
            </li>
          ) : (
            options
              .filter(option =>
                options.some(val => val.label.toString() === debouncedSearch) ||
                debouncedSearch.length === 0 ||
                !searchable
                  ? true
                  : String(option.label).toLowerCase().includes(debouncedSearch.toLowerCase()),
              )
              .map(option => {
                const chosenValue = value ? value : multiValue;
                const singleAndSelected =
                  option.id === (chosenValue as string | number) || option.label === (chosenValue as string | number);
                const multiAndSelect =
                  Array.isArray(chosenValue) &&
                  (chosenValue.some(val => val === option.id) || chosenValue.some(val => val === option.label));

                return (
                  <li
                    key={option.id}
                    onClick={e => (!option.disabled ? onValueSelect(e, option.id, option?.extraValue) : null)}
                    className={`ui-select_dropdown_item${singleAndSelected || multiAndSelect ? " selected" : ""}${
                      option.disabled ? " disabled" : ""
                    }`}
                    aria-disabled={option.disabled}
                  >
                    {renderOption ? (
                      renderOption(option)
                    ) : (
                      <>
                        <p className="ui-select_dropdown_text">{option.label}</p>
                        {(singleAndSelected || multiAndSelect) && (
                          <FontAwesomeIcon className="ui-select_dropdown_check" icon={["far", "check"]} />
                        )}
                      </>
                    )}
                  </li>
                );
              })
          )}
        </ul>
      </div>

      {helpText && <span className="ui-select_help_text">{helpText}</span>}
    </div>
  );
}

function RightIcon(props: {
  clearable: boolean;
  dropdownOpen: boolean;
  clearClick: () => void;
  carrotClick: () => void;
  hideCarrots: boolean;
}) {
  const { clearable, dropdownOpen, clearClick, carrotClick, hideCarrots } = props;

  function handleClearClick(e: React.MouseEvent<SVGSVGElement, MouseEvent>) {
    e.stopPropagation();
    clearClick();
  }
  function handleCarrotClick(e: React.MouseEvent<SVGSVGElement, MouseEvent>) {
    e.stopPropagation();
    carrotClick();
  }

  if (clearable) {
    return <FontAwesomeIcon icon={["far", "times"]} size="1x" onClick={handleClearClick} />;
  }

  return (
    <FontAwesomeIcon
      icon={["fas", dropdownOpen ? "chevron-up" : "chevron-down"]}
      onClick={handleCarrotClick}
      className={hideCarrots ? "hidden" : ""}
    />
  );
}
