// import { ICustomer } from "./models/customer";

import { reader } from "helpers/StripeTerminalWrapper";

export interface ITerminalState {
  isLoaded: boolean;
  error: string;
  status: string;
  reader?: reader;
  readers: reader[];
  reconnectionAttempts: number;
}

export interface ITerminalAction {
  type: string;
  payload: any;
}

const initialState: ITerminalState = {
  isLoaded: false,
  error: "",
  reader: null,
  status: "Not connected",
  readers: [],
  reconnectionAttempts: 0,
};

export default function TerminalReducer(state = initialState, action: ITerminalAction) {
  switch (action.type) {
    case "terminal.update":
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        ...state,
        ...(action.payload as ITerminalState),
      };
    default:
      return state;
  }
}
