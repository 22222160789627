import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import { PostTeeTime } from "api/rpc/2024-04/facilityAdmin/teesheet/teeSheet";
import { StatusCode } from "api/protocols";

import { showSuccess, showError } from "redux/actions/ui";

import Sheet from "components/sheet/Sheet";
import TimePick from "components/timePick/TimePick";
import Input from "components/form/input";

import { ITeeTimeNew } from "redux/reducers/models/teetime";

interface IAddTeeTimeModalProps {
  open: boolean;
  teetime: ITeeTimeNew;
  onOk: () => void;
  onCancel: () => void;
}

interface IAddTeeTimeModalState {
  start_hole: string;
  start_time: string;
}

export default function AddTeeTimeModal(props: IAddTeeTimeModalProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { open, teetime, onOk, onCancel } = props;

  const [state, setState] = useState<IAddTeeTimeModalState>({
    start_hole: teetime.slots[0].start_hole,
    start_time: teetime.start_time,
  });

  async function addTeeTime() {
    const params = {
      tee_time_id: teetime.slots[0].tee_time_id,
      start_hole: state.start_hole,
      start_time: state.start_time,
    };

    const addRes = await PostTeeTime(params, true);

    if (addRes.status !== StatusCode.OK) {
      dispatch(showError("Error adding tee time")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Successfully added tee time")); // TODO: Translation

    setState(prevState => ({ ...prevState, start_hole: "", addTeeTimePopup: false }));

    onOk();
  }

  return (
    <Sheet
      open={open}
      stacked
      size="x-small"
      title={t("elements.tee_sheet.tee_sheet_tabs.131")}
      onOk={addTeeTime}
      okText={t("elements.tee_sheet.tee_sheet_tabs.132")}
      onCancel={onCancel}
      backDropCancel={true}
      overflow
    >
      <Input
        value={state.start_hole}
        label={t("elements.tee_sheet.tee_sheet_tabs.133")}
        id="start_hole"
        onChange={e => setState(prevState => ({ ...prevState, start_hole: e.target.value }))}
        className="mb-2"
      />
      <TimePick
        value={state.start_time}
        onChange={timeString => setState(prevState => ({ ...prevState, start_time: timeString }))}
        label={t("elements.tee_sheet.tee_sheet_tabs.134")}
        size="large"
      />
    </Sheet>
  );
}
