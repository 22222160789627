import React, { useEffect, useState } from "react";
import "components/form/toggle/toggle.scss";
import classNames from "classnames";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { TABLET_WIDTH } from "helpers/ScreenSizes";

type ToggleSize = "small" | "medium";

interface IProps {
  id?: string;
  label?: string;
  labelSupport?: string;
  labelTop?: string;
  labelRight?: string;
  rounded?: boolean;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  value?: string | number;
  disabled?: boolean;
  size?: ToggleSize;
}

export default function Toggle(props: IProps) {
  const windowSize = useWindowSize();
  const [toggleSize, setToggleSize] = useState<ToggleSize>(undefined);

  useEffect(() => {
    if (windowSize?.width > TABLET_WIDTH) {
      setToggleSize(props.size);
    } else {
      setToggleSize("small");
    }
  }, [windowSize, props.size]);

  return (
    <div>
      {props.labelTop && (
        <div className="flex flex-wrap justify-between mb-1 items-end">
          <div className="text-base font-medium text-black block">
            <label
              className={classNames("ui-toggle-label", {
                "ui-toggle-label-small": toggleSize === "small",
                "ui-toggle-label-medium": toggleSize === "medium",
              })}
            >
              {props.labelTop}
            </label>
            {props.labelSupport && (
              <p
                className={classNames("ui-toggle-label-support", {
                  "ui-toggle-label-support-small": toggleSize === "small",
                  "ui-toggle-label-support-medium": toggleSize === "medium",
                })}
              >
                {props.labelSupport}
              </p>
            )}
          </div>
        </div>
      )}

      {props.label && (
        <div className="inline-block" style={{ marginRight: "0.5rem" }}>
          <div className="text-base font-medium text-black block">
            <label
              className={classNames("ui-toggle-label", {
                "ui-toggle-label-small": toggleSize === "small",
                "ui-toggle-label-medium": toggleSize === "medium",
              })}
            >
              {props.label}
            </label>
            {props.labelSupport && (
              <p
                className={classNames("ui-toggle-label-support", {
                  "ui-toggle-label-support-small": toggleSize === "small",
                  "ui-toggle-label-support-medium": toggleSize === "medium",
                })}
              >
                {props.labelSupport}
              </p>
            )}
          </div>
        </div>
      )}

      <label
        className={`ui-toggle
        ${toggleSize === "small" ? " ui-toggle-small " : " ui-toggle-medium "}
        ${props.labelRight ? " inline-block mb-2" : ""}
      `}
      >
        <input
          id={props.id}
          type="checkbox"
          checked={props.checked}
          onChange={props.onChange}
          value={props.value}
          name={props.name}
          disabled={props.disabled}
          className="ui-toggle-input"
        />

        <div
          className={`ui-toggle-slider ${
            toggleSize === "small" ? "ui-toggle-slider-small" : "ui-toggle-slider-medium"
          }`}
        ></div>
      </label>

      {props.labelRight && (
        <div className="inline-block" style={{ marginLeft: "1rem" }}>
          <div className="text-base font-medium text-black block">
            <label
              className={classNames("ui-toggle-label", {
                "ui-toggle-label-small": toggleSize === "small",
                "ui-toggle-label-medium": toggleSize === "medium",
              })}
            >
              {props.labelRight}
            </label>
            {props.labelSupport && (
              <p
                className={classNames("ui-toggle-label-support", {
                  "ui-toggle-label-support-small": toggleSize === "small",
                  "ui-toggle-label-support-medium": toggleSize === "medium",
                })}
              >
                {props.labelSupport}
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
