import { IFacility } from "pages/guest/models/facility";
import { ITournament, ITournamentRegistrationFee } from "../models/tournament";

export interface ITournamentRegistrationAction {
  type: string;
  payload: Partial<ITournamentRegistrationState>;
}

export interface ISelectedTournamentRegistrationFee extends ITournamentRegistrationFee {
  quantity: number;
}

export interface ITournamentRegistrationState {
  facility: IFacility;
  tournament: ITournament;
  activeRegistrationFees: ISelectedTournamentRegistrationFee[];
}

const initialState: ITournamentRegistrationState = {
  facility: null,
  tournament: null,
  activeRegistrationFees: null,
};

export default function TournamentRegistrationReducer(state = initialState, action: ITournamentRegistrationAction) {
  switch (action.type) {
    case "tournamentRegistration.update":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
