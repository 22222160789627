import Input from "components/form/input/Input";
import Sheet from "components/sheet/Sheet";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import validator from "validator";
import { ButtonNew as Button } from "components/buttonNew";
import "./NewCustomer.scss";

interface ICustomerProps {
  newCustomerSheetActive: boolean;
  onCancel?: () => void;
  onOk?: (customerInfo: ICustomerInfoState) => void;
  searchValue?: string;
}

interface IInputField {
  isDirty: boolean;
  isValid: boolean;
}

export interface ICustomerInfoState {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  emailAddressValid: IInputField;
}

function NewCustomer(props: ICustomerProps) {
  const { t, i18n } = useTranslation();
  const windowSize = useWindowSize();
  const [state, setState] = useState<ICustomerInfoState>({
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    emailAddressValid: { isDirty: false, isValid: false },
  });

  useEffect(() => {
    if (props.newCustomerSheetActive && props.searchValue) {
      if (props.searchValue.includes("@")) {
        setState(prevState => ({
          ...prevState,
          emailAddress: props.searchValue,
          emailAddressValid: { isDirty: true, isValid: validator.isEmail(props.searchValue) },
        }));
      } else if (validator.isNumeric(props.searchValue)) {
        setState(prevState => ({ ...prevState, phoneNumber: props.searchValue }));
      } else if (props.searchValue.includes(" ")) {
        const fullName = props.searchValue.split(" ");
        if (validator.isAlpha(fullName[0])) {
          setState(prevState => ({ ...prevState, firstName: fullName[0] }));
        }
        if (validator.isAlpha(fullName[1])) {
          setState(prevState => ({ ...prevState, lastName: fullName[1] }));
        }
      } else if (validator.isAlpha(props.searchValue)) {
        setState(prevState => ({ ...prevState, firstName: props.searchValue }));
      }
    } else {
      setState(prevState => ({
        ...prevState,
        firstName: "",
        lastName: "",
        phoneNumber: "",
        emailAddress: "",
        emailAddressValid: { isDirty: false, isValid: false },
      }));
    }
  }, [props.newCustomerSheetActive, props.searchValue]);

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [id]: value,
      emailAddressValid: id === "emailAddress" ? setEmailValidState(value) : prevState.emailAddressValid,
    }));
  }

  function setEmailValidState(email: string) {
    return email === "" ? { isDirty: false, isValid: false } : { isDirty: true, isValid: validator.isEmail(email) };
  }

  function onOk() {
    void props.onOk({ ...state });
  }

  function onClear() {
    setState(prevState => ({
      ...prevState,
      firstName: "",
      lastName: "",
      phoneNumber: "",
      emailAddress: "",
      emailAddressValid: { isDirty: false, isValid: false },
    }));
  }

  function onCancel() {
    onClear();
    props.onCancel();
  }

  return (
    <Sheet
      open={props.newCustomerSheetActive}
      size="small"
      closable
      stacked
      height="flexible"
      title={t("components.new_customer.new_customer.001")}
      onCancel={onCancel}
      onOk={onOk}
      cancelText={t("components.new_customer.new_customer.002")}
      okText={t("components.new_customer.new_customer.003")}
      okDisabled={
        state.firstName === "" ||
        state.lastName === "" ||
        (!state.emailAddressValid?.isValid && state.phoneNumber === "") ||
        (state.emailAddressValid?.isDirty && !state.emailAddressValid?.isValid && state.phoneNumber?.length > 0)
      }
    >
      <Button onClick={onClear}>{t("components.new_customer.new_customer.008")}</Button>
      <div className="new-customer-fields">
        <Input
          label={t("components.new_customer.new_customer.004")}
          placeholder={t("components.new_customer.new_customer.004")}
          id="firstName"
          value={state.firstName}
          onChange={handleInputChange}
        />
        <Input
          label={t("components.new_customer.new_customer.005")}
          placeholder={t("components.new_customer.new_customer.005")}
          id="lastName"
          value={state.lastName}
          onChange={handleInputChange}
        />
        <Input
          label={t("components.new_customer.new_customer.006")}
          placeholder={t("components.new_customer.new_customer.006")}
          id="emailAddress"
          value={state.emailAddress}
          onChange={handleInputChange}
          error={state?.emailAddressValid?.isDirty && !state?.emailAddressValid?.isValid}
        />
        <Input
          label={t("components.new_customer.new_customer.007")}
          placeholder={t("components.new_customer.new_customer.007")}
          id="phoneNumber"
          value={state.phoneNumber}
          onChange={handleInputChange}
        />
      </div>
    </Sheet>
  );
}

export default NewCustomer;
