import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { useAppDispatch } from "hooks/redux";
import { showSuccess, showError } from "redux/actions/ui";

import {
  GetPowerCarts,
  PutPowerCart,
  DeletePowerCart,
} from "api/rpc/2022-09/facilityAdmin/facility/powercart/powercart";

import Page from "components/page/Page";
import Input from "components/form/input/Input";
import { StatusCode } from "api/protocols";
import PowerCartCard from "components/powerCartCards/PowerCartCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Popup from "components/popup/Popup";
import { ButtonNew as Button } from "components/buttonNew";
import Sheet from "components/sheet/Sheet";
import FormLayout from "components/form/FormLayout";
import { Select } from "components/select/index";
import { capitalize } from "helpers/Helpers";

import "./cartInventory.scss";

interface IPowerCart {
  id: number;
  facility_id: number;
  name: string;
  status: string;
  brand: string;
  type: string;
}

interface ICartInventoryState {
  availableCarts: IPowerCart[];
  maintenanceCarts: IPowerCart[];
  editing: boolean;
  deletePopupVisible: boolean;
  currentCartId: number;
}

interface IEditCartModalState {
  status: string;
  name: string;
  brand: string;
  type: string;
  modalVisible: boolean;
}

interface IRenameModalState {
  modalVisible: boolean;
  name: string;
}

export default function CartInventory() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { Option } = Select;

  const limit = 36;

  const [cartInventoryState, setCartInventoryState] = useState<ICartInventoryState>({
    availableCarts: null,
    maintenanceCarts: null,
    editing: false,
    deletePopupVisible: false,
    currentCartId: null,
  });

  const [editCartState, setEditCartState] = useState<IEditCartModalState>({
    status: "",
    name: "",
    brand: "",
    type: "",
    modalVisible: false,
  });

  const [renameModalState, setRenameModalState] = useState<IRenameModalState>({
    modalVisible: false,
    name: "",
  });

  const cartStatuses = ["available", "reserved", "occupied", "maintenance", "broken"];

  useEffect(() => {
    void loadCarts();
  }, []);

  function navigateToAddInventory() {
    history.push("/admin/settings/cart-inventory/new");
  }

  const primaryAction = {
    content: "Add Inventory",
    action: navigateToAddInventory,
  };

  async function loadCarts() {
    const powerCartRes = await GetPowerCarts({}, true);

    if (powerCartRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading carts"));
      return;
    }

    setCartInventoryState(prevState => ({ ...prevState, availableCarts: powerCartRes.data }));
  }

  async function deleteCart() {
    const deleteRes = await DeletePowerCart({ id: cartInventoryState.currentCartId }, true);

    if (deleteRes.status !== StatusCode.OK) {
      dispatch(showError("Error deleting cart"));
      return;
    }

    dispatch(showSuccess("Cart deleted successfully"));

    void loadCarts();
    setCartInventoryState(prevState => ({ ...prevState, deletePopupVisible: false }));
  }

  function handleDelete(cartId: number) {
    setEditCartState(prevState => ({ ...prevState, modalVisible: false }));
    setCartInventoryState(prevState => ({ ...prevState, currentCartId: cartId, deletePopupVisible: true }));
  }

  async function handleChangeStatus(id: number, status: string) {
    const powerCartRes = await PutPowerCart({ id: id, status: status }, true);

    if (powerCartRes.status !== StatusCode.OK) {
      dispatch(showError("Error updating cart status"));
      return;
    }

    dispatch(showSuccess("Cart status updated successfully"));

    void loadCarts();
  }

  async function updateCart() {
    const powerCartRes = await PutPowerCart(
      {
        id: cartInventoryState.currentCartId,
        name: editCartState.name,
        brand: editCartState.brand,
        status: editCartState.status,
        type: editCartState.type,
      },
      true,
    );

    if (powerCartRes.status !== StatusCode.OK) {
      dispatch(showError("Error updating cart status"));
      return;
    }

    dispatch(showSuccess("Cart status updated successfully"));

    void loadCarts();
    setEditCartState(prevState => ({ ...prevState, modalVisible: false }));
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setEditCartState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleDropDownChange(value: any, property: string) {
    setEditCartState(prevState => ({ ...prevState, [property]: value }));
  }

  function handleCartClick(id: number) {
    const cart = cartInventoryState.availableCarts.find(cart => cart.id === id);
    setCartInventoryState(prevState => ({ ...prevState, currentCartId: id }));
    setEditCartState(prevState => ({
      ...prevState,
      modalVisible: true,
      name: cart.name,
      status: cart.status,
      brand: cart.brand,
      type: cart.type,
    }));
  }

  return (
    <Page title="Cart Inventory" subtitle="Edit details about the cart inventory" primaryAction={primaryAction}>
      <Page.Section>
        <div className="cart-inventory-header mb-4 mt-4 flex justify-between">
          <span>Available</span>
        </div>
        <div className="power-cart-cards">
          {cartInventoryState.availableCarts
            ?.filter((cart: IPowerCart) => cart.status !== "maintenance" && cart.status !== "broken")
            .map((cart: IPowerCart, index: number) => {
              return (
                <PowerCartCard
                  key={index}
                  powerCart={cart}
                  editing={cartInventoryState.editing}
                  onDelete={handleDelete}
                  onEdit={handleCartClick}
                  handleSetStatus={handleChangeStatus}
                />
              );
            })}
        </div>
      </Page.Section>
      <Page.Section>
        <div className="cart-inventory-header mb-4 mt-4">
          <span>Out of Service</span>
        </div>
        <div className="power-cart-cards">
          {cartInventoryState.availableCarts
            ?.filter((cart: IPowerCart) => cart.status == "maintenance" || cart.status == "broken")
            .map((cart: IPowerCart, index: number) => {
              return (
                <PowerCartCard
                  key={index}
                  powerCart={cart}
                  editing={cartInventoryState.editing}
                  onDelete={handleDelete}
                  onEdit={handleCartClick}
                  handleSetStatus={handleChangeStatus}
                />
              );
            })}
        </div>
      </Page.Section>

      <Sheet
        title="Edit Cart"
        open={editCartState.modalVisible}
        size="small"
        stacked
        closable
        overflow
        onCancel={() => setEditCartState(prevState => ({ ...prevState, modalVisible: false, name: "", status: "" }))}
        onOk={updateCart}
        okText="Update"
      >
        <FormLayout>
          <FormLayout.Group>
            <Input label="Name" value={editCartState.name} id="name" onChange={handleInputChange} />
            <Input label="Brand" value={editCartState.brand} id="brand" onChange={handleInputChange} />
          </FormLayout.Group>
          <FormLayout.Group>
            <Select
              defaultValue={editCartState.status}
              label={"Status"}
              onChange={(value: any) => handleDropDownChange(value, "status")}
            >
              {cartStatuses.map((status: string, index: number) => {
                return (
                  <Option key={index} name={status} value={status}>
                    <span>{capitalize(status)}</span>
                  </Option>
                );
              })}
            </Select>
            <Select
              defaultValue={editCartState.type}
              label={"Type"}
              onChange={(value: any) => handleDropDownChange(value, "type")}
            >
              <Option key={0} name={"gas"} value={"gas"}>
                <span>Gas</span>
              </Option>
              <Option key={1} name={"electric"} value={"electric"}>
                <span>Electric</span>
              </Option>
            </Select>
          </FormLayout.Group>
        </FormLayout>
      </Sheet>

      <Popup
        type="warning"
        open={cartInventoryState.deletePopupVisible}
        title={"Delete Cart?"}
        description={"Are you sure you want to delete this cart? This cannot be undone."}
        closable
        onCancel={() => setCartInventoryState(prevState => ({ ...prevState, deletePopupVisible: false }))}
        onOk={deleteCart}
        cancelText={"Cancel"}
        okText={"Delete"}
      />
    </Page>
  );
}
