import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useOnclickOutside from "hooks/useOnclickOutside/useOnclickOutside";
import Icon from "components/icon/Icon";

import "./customSelect.scss";
import { TFunction, useTranslation } from "react-i18next";

type CustomDropdownProps = {
  label?: string;
  positionTop?: boolean; // TODO: Attach this to the style
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  suffixClick: () => void;
  inputRef: React.MutableRefObject<HTMLInputElement>;
  editing?: boolean;
  /** children are wrapped in a < ul /> element. */
  children?: React.ReactNode;
  toolTip?: { title: string; description: string; position: "left" | "center" | "right" };
  dropdownInline?: boolean;
};

/** Input element capable of saving and editing an array displayed as its children. */
export default function CustomSelect(props: CustomDropdownProps) {
  const {
    value,
    onChange,
    label,
    positionTop = false, // TODO: Add styling
    children,
    suffixClick,
    inputRef,
    editing = false,
    toolTip,
    dropdownInline = false,
  } = props;

  const iconRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLUListElement>(null);
  const outsideClickRef = useOnclickOutside(e => {
    setFocused(false);
  });

  const [focused, setFocused] = useState(false);
  const { t } = useTranslation();
  function handleFocus() {
    setFocused(true);
    inputRef.current.focus();
  }

  function handleBlur() {
    if (dropdownRef.current) {
      return;
    }
    setFocused(false);
  }

  function iconClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    // event.stopPropagation();
    event.preventDefault();
    suffixClick();
    inputRef.current.focus();
  }

  function keyDownEvent(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      event.preventDefault();
      suffixClick();
    }
  }

  return (
    <div ref={outsideClickRef} className="custom_dropdown-wrap" onClick={() => handleFocus()}>
      {/* OPTIONAL: Input Label */}
      {label && <label htmlFor="custom-drop-input">{label}</label>}

      {/* Visual Input with border */}
      <div className={`custom_dropdown${focused ? " focused" : ""}`}>
        <input
          id="custom-drop-input"
          aria-describedby="help-text"
          ref={inputRef}
          value={value}
          tabIndex={0}
          className="custom_dropdown-input"
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={onChange}
          onKeyDown={keyDownEvent}
        />

        {editing ? (
          <button ref={iconRef} className="custom_dropdown-text" onClick={e => iconClick(e)}>
            {t("secure.facility.settings.forms.elements.custom_select.001")}
          </button>
        ) : (
          <button ref={iconRef} className="custom_dropdown-icon" onClick={e => iconClick(e)}>
            <FontAwesomeIcon icon={["fas", "plus"]} />
          </button>
        )}

        {children && focused && !dropdownInline && (
          <ul ref={dropdownRef} className={`dropdown-list${!focused ? " focused" : ""}`} style={{ top: "50px" }}>
            {children}
          </ul>
        )}
      </div>

      {/* OPTIONAL: Help Text */}
      {toolTip && (
        <div id="help-text">
          {t("secure.facility.settings.forms.elements.custom_select.002")}
          <Icon icon={"question-circle"} style={"far"} size="small" tooltip={toolTip} />
        </div>
      )}

      {children && dropdownInline && (
        <ul ref={dropdownRef} className={`dropdown-list${!focused ? " focused " : " "}inlined`} style={{ top: "50px" }}>
          {children}
        </ul>
      )}
    </div>
  );
}
