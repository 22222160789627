import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Refund from "pages/secure/facility/order/Refund";
import * as UIActions from "redux/actions/ui";

const mapDispatchToProps = (dispatch: any) => {
  return {
    uiActions: bindActionCreators(
      {
        ...UIActions,
      },
      dispatch,
    ),
  };
};

const mapStateToProps = (state: any) => {
  const { authStore } = state;

  return {
    terminalStore: { ...state.terminalStore },
    authStore: { ...authStore },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Refund);
