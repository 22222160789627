import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IFolder } from "api/rpc/2022-09/facilityAdmin/product/folder";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import "./tableServiceFoldersMenu.scss";
import { IUIActions } from "redux/actions/ui";
import { PRODUCT_FOLDERS_KEY } from "../settings/folders/Folders";
import { useTranslation, Trans } from "react-i18next";
import TableServiceFolderItem from "./TableServiceFolderItem";

interface IProps {
  uiActions: IUIActions;
  folders: IFolder[];
  selectedFolder: IFolder;
  setSelectedFolder: (selectedFolder: IFolder) => void;
}

export default function TableServiceFoldersMenu(props: IProps) {
  const { uiActions, folders, selectedFolder, setSelectedFolder } = props;

  const { t, i18n } = useTranslation();

  function refreshFolderStorage() {
    localStorage.removeItem(PRODUCT_FOLDERS_KEY);

    if (selectedFolder) {
      setSelectedFolder(selectedFolder);
    }

    uiActions.showSuccess(t("secure.facility.table_service.table_service_folders_menu.001"));
  }

  return (
    <ul className="table-service-folders-menu">
      {folders
        ?.sort((prev, next) => prev.position - next.position)
        ?.map((folder: any, index: number) => {
          return (
            <TableServiceFolderItem
              key={index}
              active={folder?.id === selectedFolder?.id}
              onClick={() => setSelectedFolder(folder)}
              icon={{ icon: ["far", "folder"] }}
              title={folder?.title}
            />
          );
        })}
    </ul>
  );
}
