import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { IFacility } from "redux/reducers/models/facility";

const apiVersion = "2024-04";
const adminType = "master-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/customer";

export async function GetCustomer(params: any, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseUrl, params, useGlobalLoader, token);
}

export async function PostCustomer(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

interface IGetCustomerPaymentMethodParams {
  id?: number;
  customer_id?: number;
  client_id: number;
}

export async function GetCustomerPaymentMethod(params: IGetCustomerPaymentMethodParams, useGlobalLoader?: boolean) {
  return await APIGet(baseUrl + "/payment-method", params, useGlobalLoader);
}
