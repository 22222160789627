import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as BookingEngineActions from "redux/actions/bookingEngine";
import TeeTimeForgotPassword from "pages/guest/Customer/ForgotPassword";
import { AppDispatch } from "index";

const mapStateToProps = (state: any) => {
  return {
    bookingEngineStore: { ...state.bookingEngineStore },
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    bookingEngineActions: bindActionCreators(
      {
        ...BookingEngineActions,
      },
      dispatch,
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeeTimeForgotPassword);
