import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AccountingReferenceType } from "../../context/contextTypes";

import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import Sheet from "components/sheet/Sheet";
import { Select as SelectNew, Select } from "components/select/";
import { ITournament } from "redux/reducers/models/tournament";
import Icon from "components/icon/Icon";

import { StatusCode } from "api/protocols";
import { useAppDispatch } from "hooks/redux";
import { showError } from "redux/actions/ui";
import { GetTournament } from "api/rpc/facilityAdmin/tournament/tournament";
import Checkbox from "components/form/checkbox/Checkbox";

export type EditHouseAccountState = {
  title: string;
  limit: string;
  account_number: string;
  accounting_reference_id: number;
  tournament_id: number;
  global: boolean;
};

type EditCustomerHouseAccountModalProps = {
  accountingReferenceTypes: AccountingReferenceType[] | null;
  isVisible: boolean;
  onClose: () => void;
  editAccount: Partial<EditHouseAccountState>;
  setEditAccount: React.Dispatch<React.SetStateAction<Partial<EditHouseAccountState>>>;
  onOk: (editedHouseAccount: any) => void;
};

interface IFilterState {
  tournaments: Array<ITournament>;
  tournamentQuery: string;
  tournamentSearching: boolean;
  selectedTournament: ITournament;
}

export function EditCustomerHouseAccountModal(props: EditCustomerHouseAccountModalProps) {
  const { t } = useTranslation();
  const { Option } = SelectNew;
  const dispatch = useAppDispatch();

  const [tournaments, setTournaments] = useState<Array<ITournament>>([]);

  const { isVisible, accountingReferenceTypes, editAccount, setEditAccount, onClose, onOk } = props;

  useEffect(() => {
    void loadTournaments();
  }, []);

  useEffect(() => {
    assignDefaultTournament();
  }, [props.isVisible]);

  function handleChange(event: any) {
    const id = event?.target?.id;
    const value = event?.target?.value;

    if (id === undefined || value === undefined) {
      return;
    }

    setEditAccount({ ...editAccount, [id]: value });
  }

  const [filterState, setFilterState] = useState<IFilterState>({
    tournamentQuery: "",
    tournamentSearching: false,
    selectedTournament: null,
    tournaments: [],
  });

  function handleTournamentChange(value: any, id: string, tournament: ITournament) {
    if (tournament) {
      setEditAccount(prevState => ({ ...prevState, [id]: tournament.id === -1 ? null : tournament?.id }));
      setFilterState(prevState => ({ ...prevState, tournamentQuery: "", selectedTournament: tournament }));
    }
  }

  function removeSelectedTournament() {
    setFilterState(prevState => ({
      ...prevState,
      selectedTournament: null,
      tournaments: [],
      tournamentSearching: false,
    }));
    setEditAccount(prevState => ({ ...prevState, tournament_id: null }));
  }

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (async () => {
          if (filterState.tournamentQuery !== "") {
            try {
              setFilterState(prevState => ({ ...prevState, tournamentSearching: true }));
              const tournaments = await loadSearchedTournaments(filterState.tournamentQuery);
              if (mounted) {
                setFilterState(prevState => ({ ...prevState, tournaments: tournaments, tournamentSearching: false }));
              }
            } catch (error) {
              console.log("err", error);
            }
            return;
          } else {
            setFilterState(prevState => ({ ...prevState, tournaments: [], tournamentSearching: false }));
          }
        })();
      }, 1000);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [filterState.tournamentQuery]);

  async function loadSearchedTournaments(search: string) {
    const params = {
      search: search,
    };

    const tournamentRes = await GetTournament(params, false);

    if (tournamentRes.status !== StatusCode.OK) {
      dispatch(showError(tournamentRes.message));
      return [];
    }

    return tournamentRes.data;
  }

  function displayTournaments() {
    if (filterState?.tournaments && filterState?.tournaments?.length > 0 && filterState?.tournamentQuery !== "") {
      return filterState?.tournaments;
    } else {
      return tournaments;
    }
  }

  async function loadTournaments() {
    try {
      const tournamentRes = await GetTournament(null, true);

      if (tournamentRes.status === StatusCode.OK) {
        setTournaments(tournamentRes.data);
      } else {
        dispatch(showError(tournamentRes.message));
      }
    } catch (e) {
      dispatch(showError(e));
    }
  }

  function assignDefaultTournament() {
    let currentTournament: ITournament;

    for (let i = 0; i < tournaments?.length; i++) {
      if (tournaments[i].id === editAccount.tournament_id) {
        currentTournament = tournaments[i];
      }
    }

    setFilterState(prevState => ({ ...prevState, selectedTournament: currentTournament }));
  }

  return (
    <Sheet
      open={isVisible}
      size="medium"
      height="flexible"
      closable
      title={t("secure.facility.customer.tabs.house_accounts.edit_customer_house_account_modal.001")}
      onCancel={() => onClose()}
      onOk={() => onOk(editAccount)}
      cancelText={t("secure.facility.customer.tabs.house_accounts.edit_customer_house_account_modal.002")}
      okText={t("secure.facility.customer.tabs.house_accounts.edit_customer_house_account_modal.003")}
      overflow
    >
      <Form>
        <FormLayout>
          <FormLayout.Group>
            <Input
              value={editAccount.title}
              label={t("secure.facility.customer.tabs.house_accounts.edit_customer_house_account_modal.004")}
              id="title"
              onChange={handleChange}
            />

            <Input
              value={editAccount.account_number}
              id="account_number"
              label={t("secure.facility.customer.tabs.house_accounts.edit_customer_house_account_modal.005")}
              onChange={handleChange}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <Input
              value={editAccount.limit}
              label={t("secure.facility.customer.tabs.house_accounts.edit_customer_house_account_modal.006")}
              id="limit"
              onChange={handleChange}
            />
            {accountingReferenceTypes && (
              <SelectNew
                label={t("secure.facility.customer.tabs.house_accounts.edit_customer_house_account_modal.007")}
                defaultValue={editAccount.accounting_reference_id}
                onChange={(value: number) => setEditAccount({ ...editAccount, accounting_reference_id: value })}
              >
                {accountingReferenceTypes.map((type, index: number) => {
                  return (
                    <Option key={index} value={type.value} name={type.label}>
                      <span>{type.label}</span>
                    </Option>
                  );
                })}
              </SelectNew>
            )}
          </FormLayout.Group>

          <FormLayout.Group>
            {filterState.selectedTournament ? (
              <div>
                <div>
                  <p className="event-label">
                    {t("secure.facility.customer.tabs.house_accounts.edit_customer_house_account_modal.008")}
                  </p>
                </div>

                <div className="selected-container">
                  <div className="event-name">
                    <div>{filterState.selectedTournament.name}</div>

                    <div>
                      <button className="" onClick={removeSelectedTournament}>
                        <Icon style="far" icon="times" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <Select
                  showSearch
                  label={t("secure.facility.customer.tabs.house_accounts.edit_customer_house_account_modal.009")}
                  onSearch={(query: string) =>
                    setFilterState(prevState => ({ ...prevState, tournamentQuery: query }))
                  }
                  onChange={(value: any, tournament: ITournament) =>
                    handleTournamentChange(value, "tournament_id", tournament)
                  }
                  placeholder={t("secure.facility.customer.tabs.house_accounts.edit_customer_house_account_modal.010")}
                  allowClear
                  searchValue={filterState.tournamentQuery}
                  showDropDownOnFocus={true}
                  searching={filterState.tournamentSearching}
                >
                  <Option value={-1} extraValues={{ name: "None", id: -1 }}>
                    <div className="text-semibold text-lg">
                      {t("secure.facility.customer.tabs.house_accounts.edit_customer_house_account_modal.011")}
                    </div>
                  </Option>
                  {displayTournaments()?.map((tournament: any, index: number) => {
                    return (
                      <Option value={tournament?.id} extraValues={tournament} key={index}>
                        <div className="text-semibold text-lg">{tournament.name}</div>
                      </Option>
                    );
                  })}
                </Select>
              </div>
            )}
          </FormLayout.Group>
          <FormLayout.Group>
            <Checkbox
              id="global"
              size="medium"
              value={editAccount.global}
              checked={editAccount.global}
              onChange={({ target }) =>
                setEditAccount({ ...editAccount, [target.id]: target.value === "true" ? false : true })
              }
              label={t("secure.facility.customer.tabs.house_accounts.new_customer_house_account_modal.009")}
            ></Checkbox>
          </FormLayout.Group>
        </FormLayout>
      </Form>
    </Sheet>
  );
}
