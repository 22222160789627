import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import {
  GetCustomerType,
  PutCustomerTypeConfiguration,
  TPutCustomerTypeConfig,
} from "api/rpc/2024-04/facilityAdmin/customer/type";
import { GetBookingCategories } from "api/rpc/2024-04/facilityAdmin/teesheet/bookingCategory";

import { showError, showSuccess } from "redux/actions/ui";
import { TCustomerType, TTeesheetCustomerTypeConfig } from "redux/reducers/models/facility";
import { IBookingCategory } from "redux/reducers/models/teetime";
import { useAppDispatch } from "hooks/redux";
import { unsavedChangesExist } from "helpers/Helpers";

import Page from "components/page/Page";
import { Select } from "components/select/index";
import Input from "components/form/input/Input";
import TimePick from "components/timePick/TimePick";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import { isEqualWith } from "lodash";
import Search from "components/search/Search";

export default function TeeSheetCustomerType() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [customerTypeState, setCustomerTypeState] = useState<TCustomerType[]>(undefined);
  const [customerTypeStateBeforeChanges, setCustomerTypeStateBeforeChanges] = useState<TCustomerType[]>(undefined);

  const [typeQuery, setTypeQuery] = useState<string>("");

  const [bookingCategoryState, setBookingCategoryState] = useState<IBookingCategory[]>([]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadBookingCategories(source.token);
    return () => source.cancel();
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadCustomerTypes(source.token);
    return () => source.cancel();
  }, [typeQuery]);

  async function loadCustomerTypes(token?: CancelToken) {
    setCustomerTypeState(undefined);
    setCustomerTypeStateBeforeChanges(undefined);

    const res = await GetCustomerType(
      {
        search: typeQuery,
        application: "green_fee",
        configuration: true,
      },
      token ? false : true,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      return;
    }

    setCustomerTypeState(
      res.data.map(type => ({
        ...type,
        configuration: { ...type.configuration, booking_category_id: type.configuration.booking_category_id ?? -1 },
      })),
    );
    setCustomerTypeStateBeforeChanges(
      res.data.map(type => ({
        ...type,
        configuration: { ...type.configuration, booking_category_id: type.configuration.booking_category_id ?? -1 },
      })),
    );
  }

  async function loadBookingCategories(token?: CancelToken) {
    const res = await GetBookingCategories(null, token ? false : true, token);
    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      return;
    }

    setBookingCategoryState(res.data);
  }

  async function saveCustomerTypes() {
    /**
     * Allow users to understand when their changes will be properly saved.
     * - Single API call handles every type ('table-row') update.
     * - API call does not return an error if specific type is missing a required field.
     */
    if (activeRowError(customerTypeState, customerTypeStateBeforeChanges)) {
      dispatch(showError("Please fill in the missing inputs.")); // TODO: Translation
      return;
    }

    const params: TPutCustomerTypeConfig[] = customerTypeState.map(stateType => {
      return {
        id: stateType.configuration.id,
        booking_category_id:
          stateType.configuration?.booking_category_id !== -1 ? stateType.configuration?.booking_category_id : null,
        days_in_advance_start: stateType.configuration?.days_in_advance_start
          ? stateType.configuration.days_in_advance_start.toString()
          : "0",
        days_in_advance_end: stateType.configuration?.days_in_advance_end
          ? stateType.configuration.days_in_advance_end.toString()
          : "0",
        open_time:
          stateType.configuration?.open_time && stateType.configuration.open_time.length !== 0
            ? stateType.configuration.open_time
            : null,
        max_current_bookings:
          stateType.configuration.max_current_bookings !== null
            ? Number(stateType.configuration?.max_current_bookings)
            : null, // TODO: Handle 'Unlimited text input if necessary
        max_daily_bookings:
          stateType.configuration.max_daily_bookings !== null
            ? Number(stateType.configuration?.max_daily_bookings)
            : null, // TODO: Handle 'Unlimited text input if necessary
      };
    });

    const res = await PutCustomerTypeConfiguration({ configurations: params }, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error saving customer type configurations.")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Successfully saved customer type configurations.")); // TODO: Translation
    setCustomerTypeStateBeforeChanges(customerTypeState);
  }

  // typeIndex required because customerTypeState is array of objects we want changed
  function handleConfigurationChangeEvent(config: { id: string; value: string }, typeIndex: number) {
    const { id, value } = config;

    setCustomerTypeState(prev =>
      prev.map((type, index) => {
        if (index === typeIndex) {
          return {
            ...type,
            configuration: {
              ...type.configuration,
              [id as keyof TTeesheetCustomerTypeConfig]: value.length === 0 ? null : value,
            },
          };
        }
        return { ...type, configuration: { ...type.configuration } };
      }),
    );
  }

  return (
    <Page
      title={t("secure.facility.settings.tee_sheets.tee_sheet_customer_type.001")}
      notificationBarProps={{
        isVisible: unsavedChangesExist(customerTypeState, customerTypeStateBeforeChanges),
        onAction: () => saveCustomerTypes(),
        onCancel: () => setCustomerTypeState(customerTypeStateBeforeChanges),
      }}
    >
      <div className="mb-4">
        <Search
          placeholder="Search customer types..." // TODO: Translation
          searchCallback={searchValue => setTypeQuery(searchValue)}
          historyKey="customer-type-config-query"
        />
      </div>
      <DataTable
        columns={[
          { label: t("secure.facility.settings.tee_sheets.tee_sheet_customer_type.003"), width: "20%" },
          { label: t("secure.facility.settings.tee_sheets.tee_sheet_customer_type.004"), width: "20%" },
          { label: t("secure.facility.settings.tee_sheets.tee_sheet_customer_type.005"), width: "10%" },
          { label: t("secure.facility.settings.tee_sheets.tee_sheet_customer_type.006"), width: "10%" },
          { label: "Open Time", width: "20%" }, // TODO: Translation
          { label: "Max Current Bookings", width: "10%" }, // TODO: Translation
          { label: "Max Daily Bookings", width: "10%" }, // TODO: Translation
        ]}
        loading={!customerTypeState}
      >
        {customerTypeState?.map((type, typeIndex) => (
          <tr key={typeIndex}>
            <td>
              <p>{type.title}</p>
              <p>{type.subtitle}</p>
            </td>
            <td>
              <Select
                onChange={(value: any) =>
                  handleConfigurationChangeEvent({ id: "booking_category_id", value: value }, typeIndex)
                }
                defaultValue={type.configuration?.booking_category_id}
                dropDownPositionTop={typeIndex + 1 === customerTypeState?.length && typeIndex !== 0}
                error={
                  !isEqualWith(
                    type.configuration,
                    customerTypeStateBeforeChanges?.find(preservedType => preservedType.id === type.id)
                      ?.configuration,
                  ) && type.configuration?.booking_category_id === -1
                }
              >
                <Select.Option value={-1}>None {/* TODO: Translation */}</Select.Option>
                {bookingCategoryState.map((bookingCategory, bookingCategoryIndex) => {
                  return (
                    <Select.Option key={bookingCategoryIndex} value={bookingCategory.id}>
                      {bookingCategory.title}
                    </Select.Option>
                  );
                })}
              </Select>
            </td>
            <td>
              <Input
                className="-mt-4"
                value={type.configuration.days_in_advance_start ?? ""}
                id="days_in_advance_start"
                onChange={e => handleConfigurationChangeEvent({ id: e.target.id, value: e.target.value }, typeIndex)}
                error={
                  !isEqualWith(
                    type.configuration,
                    customerTypeStateBeforeChanges?.find(preservedType => preservedType.id === type.id)
                      ?.configuration,
                  ) &&
                  type.configuration?.days_in_advance_start !== 0 &&
                  !type.configuration?.days_in_advance_start
                }
              />
            </td>
            <td>
              <Input
                className="-mt-4"
                value={type.configuration.days_in_advance_end ?? ""}
                id="days_in_advance_end"
                onChange={e => handleConfigurationChangeEvent({ id: e.target.id, value: e.target.value }, typeIndex)}
                error={
                  !isEqualWith(
                    type.configuration,
                    customerTypeStateBeforeChanges?.find(preservedType => preservedType.id === type.id)
                      ?.configuration,
                  ) &&
                  type.configuration?.days_in_advance_end !== 0 &&
                  !type.configuration?.days_in_advance_end
                }
              />
            </td>
            <td>
              <TimePick
                value={type.configuration.open_time}
                onChange={timeString =>
                  handleConfigurationChangeEvent({ id: "open_time", value: timeString }, typeIndex)
                }
                size="large"
                align="right"
                positionTop={typeIndex + 1 === customerTypeState?.length && typeIndex !== 0}
              />
            </td>
            <td>
              <Input
                id="max_current_bookings"
                value={
                  type.configuration?.max_current_bookings !== null ? type.configuration?.max_current_bookings : null
                }
                onChange={e => handleConfigurationChangeEvent({ id: e.target.id, value: e.target.value }, typeIndex)}
                className="-mt-4"
              />
            </td>
            <td>
              <Input
                id="max_daily_bookings"
                value={type.configuration?.max_daily_bookings !== null ? type.configuration?.max_daily_bookings : null}
                onChange={e => handleConfigurationChangeEvent({ id: e.target.id, value: e.target.value }, typeIndex)}
                className="-mt-4"
              />
            </td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}

/** Checks for change to have been made to that row & if the row itself has valid inputs. */
function activeRowError(current: TCustomerType[], preserved: TCustomerType[]) {
  const invalidRows: number[] = [];

  current.map(type => {
    const changesMade = !isEqualWith(
      type.configuration,
      preserved.find(preservedType => preservedType.id === type.id)?.configuration,
    );

    const valid = validRow(type.id, current);

    if (changesMade && !valid) {
      invalidRows.push(type.id);
    }
  });

  if (invalidRows.length === 0) {
    return false;
  }
  return true;
}

/**
 * Checking For:
 * - booking_category_id
 * - days_in_advance_start
 * - days_in_advance_end
 */
const validRow = (typeId: number, state: TCustomerType[]) => {
  const rowsNotValid: number[] = [];
  const chosenType = state.find(value => value.id === typeId);

  // validate config inputs...
  if (
    chosenType &&
    (chosenType?.configuration.booking_category_id === -1 ||
      (chosenType?.configuration.days_in_advance_start !== 0 && !chosenType?.configuration.days_in_advance_start) ||
      (chosenType?.configuration.days_in_advance_end !== 0 && !chosenType?.configuration.days_in_advance_end))
  ) {
    rowsNotValid.push(chosenType.id);
  }

  if (rowsNotValid.length > 0) {
    return false;
  }

  return true;
};
