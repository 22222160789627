import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import * as authActions from "actions/auth";
import Settings from "pages/secure/client/settings/Settings";

const mapDispatchToProps = (dispatch: any) => {
  return {
    //   authActions: bindActionCreators(
    //     {
    //       ...authActions,
    //     },
    //     dispatch,
    //   ),
  };
};

const mapStateToProps = (state: any) => {
  // const { authStore } = state;
  return {
    //   authStore: { ...authStore },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
