import { APIGet, APIPost, APIPut, APIDelete, StatusCode } from "api/protocols";
import { CancelToken } from "axios";
import { DBFilter } from "redux/reducers/filters/filters";

const apiVersion = "2022-09";
const adminType = "client-admin";
const baseMembershipUrl = "/" + apiVersion + "/" + adminType + "/membership";

//MEMBERSHIPS
export type TMembershipGetClient = {
  id?: number;
  /** Search by title and subtitle */
  search?: string;
  filters?: DBFilter[]; // Can be used to filter multiple membership_types
  membership_type?: number;
  privileges?: boolean;
};

//GET Memberships
export async function GetMemberships(params: TMembershipGetClient, useGlobalLoader?: boolean, token?: CancelToken) {
  return await APIGet(baseMembershipUrl, params, useGlobalLoader, token);
}

//GET Specific Membership
export async function GetMembershipById(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseMembershipUrl, params, useGlobalLoader);
}

//PUT Update Memmbership
export async function UpdateMembership(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseMembershipUrl, params, useGlobalLoader);
}

//MEMBERSHIP ADD ON

//GET Membership Add Ons
export async function GetMembershipAddOns(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseMembershipUrl + "/add-on", params, useGlobalLoader);
}

//PUT Update Memmbership ADd On
export async function UpdateMembershipAddOn(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseMembershipUrl + "/add-on", params, useGlobalLoader);
}
