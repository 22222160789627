import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { StatusCode } from "api/protocols";

import { GetFacilityForm } from "api/rpc/2022-09/facilityAdmin/facility/form/form";
import { TFacilityForm } from "redux/reducers/models/facility";
import Page from "components/page/Page";
import "pages/secure/facility/order/order.scss";
import { useTranslation } from "react-i18next";
import "pages/secure/facility/order/giftCard.scss";
import Search from "components/search/Search";
import DataTable from "../customer/tabs/houseAccounts/DataTable";
import moment from "moment";
import axios, { CancelToken } from "axios";

export default function OrderForms(props: any) {
  const history = useHistory();

  const { uiActions } = props;
  const { t, i18n } = useTranslation();

  const [formState, setFormState] = useState<TFacilityForm[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadForms(source.token);

    return () => {
      source.cancel();
    };
  }, []);

  function navigateToItem(id: number) {
    history.push("/admin/forms/" + String(id));
  }

  async function loadForms(token: CancelToken) {
    const formRes = await GetFacilityForm({}, false, token);

    if (formRes.status !== StatusCode.OK) {
      uiActions.showError("There was an error loading forms");
    }

    setFormState(formRes.data);
  }

  return (
    <>
      <Page title={"Forms"} narrow>
        <DataTable columns={[{ label: "Title" }, { label: "Created Date" }]} loading={formState === undefined}>
          {formState?.map((form, index) => {
            return (
              <tr key={index} onClick={() => navigateToItem(form.id)} className="clickable">
                <td>{form?.title}</td>
                <td>{form?.created_at ? moment(form?.created_at).format("LLL") : null}</td>
              </tr>
            );
          })}
        </DataTable>
      </Page>
    </>
  );
}
