import { APIGet, APIPost, APIPut, APIDelete, IAPIResponse } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const baseCustomerUrl = "/" + apiVersion + "/customer/ticket";

export async function GetCustomerTickets(params: { id?: number }, useGlobalLoader: boolean) {
  return await APIGet(baseCustomerUrl, params, useGlobalLoader);
}

export async function PrintCustomerTicket(params: { ticket_id: number; stub_id?: number }, useGlobalLoader: boolean) {
  return await APIGet(baseCustomerUrl + "/download", params, useGlobalLoader);
}
