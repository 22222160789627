import React, { useEffect, useState, useCallback, useRef, Fragment } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { GetCustomer, PutCustomer } from "api/rpc/facilityAdmin/customer";
import { StatusCode } from "api/protocols";

import Sheet from "components/sheet/Sheet";
import Tabs from "components/tabs/Tabs";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import TextField from "components/form/textField/TextField";
import { useTranslation } from "react-i18next";

export interface ICustomerModal {
  open: boolean;
  onClose: (customerId: number) => void;
  customerId?: number;
}

export function CustomerModal(props: ICustomerModal) {
  const [selected, setSelected] = useState(0);
  const [customerState, setCustomerState] = useState({
    id: null,
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
  });

  useEffect(() => {
    setCustomerState(prev => ({
      ...prev,
      id: null,
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
    }));

    // void loadCustomer();
  }, [props.customerId]);
  const { t, i18n } = useTranslation();
  async function loadCustomer() {
    const customerRes = await GetCustomer({ id: props.customerId }, true);
    if (customerRes.status !== StatusCode.OK) {
      return;
    }

    const customer = customerRes.data[0];
    if (customer) {
      setCustomerState(prev => ({
        ...prev,
        id: customer.id,
        first_name: customer.first_name,
        last_name: customer.last_name,
        email: customer.email,
        phone: customer.phone,
      }));
    }
  }

  async function saveCustomer() {
    const customerRes = await PutCustomer(
      {
        id: customerState.id,
        first_name: customerState.first_name,
        last_name: customerState.last_name,
        email: customerState.email,
        phone: customerState.phone,
      },
      true,
    );

    void props.onClose(props.customerId);
  }

  function closeSheet() {
    void props.onClose(props.customerId);
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setCustomerState(prevState => ({ ...prevState, [id]: value }));
  }

  const handleTabChange = (selectedTabIndex: number) => {
    setSelected(selectedTabIndex);
  };

  const tabs = [
    {
      content: "Details",
    },
    {
      content: "Bookings",
    },
  ];

  return (
    <Sheet
      open={props.open}
      size="medium"
      closable
      title={t("elements.tee_sheet.customer_modal.001")}
      onCancel={closeSheet}
      onOk={saveCustomer}
      cancelText={t("elements.tee_sheet.customer_modal.002")}
      okText={t("elements.tee_sheet.customer_modal.003")}
    >
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
        {selected === 0 ? (
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={customerState.first_name || ""}
                  label={t("elements.tee_sheet.customer_modal.004")}
                  id="first_name"
                  onChange={handleInputChange}
                  placeholder={t("elements.tee_sheet.customer_modal.005")}
                />
                <Input
                  value={customerState.last_name || ""}
                  label={t("elements.tee_sheet.customer_modal.006")}
                  id="last_name"
                  onChange={handleInputChange}
                  placeholder={t("elements.tee_sheet.customer_modal.007")}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Input
                  value={customerState.email || ""}
                  label={t("elements.tee_sheet.customer_modal.008")}
                  id="email"
                  onChange={handleInputChange}
                  placeholder={t("elements.tee_sheet.customer_modal.009")}
                />
                <Input
                  value={customerState.phone || ""}
                  label={t("elements.tee_sheet.customer_modal.010")}
                  id="phone"
                  onChange={handleInputChange}
                  placeholder={t("elements.tee_sheet.customer_modal.011")}
                />
              </FormLayout.Group>
            </FormLayout>
          </Form>
        ) : null}
        {selected === 1 ? <p>{t("elements.tee_sheet.customer_modal.012")}e</p> : null}
      </Tabs>
    </Sheet>
  );
}
