import React, { useEffect, useState } from "react";
import { LocaleCurrency } from "helpers/Locale";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/pro-regular-svg-icons";

import { ICartLineItem } from "redux/reducers/models/cart";
import { GetProduct } from "api/rpc/clientAdmin/product/products";

import "elements/register/register.scss";
import products from "containers/client/products";
import { useTranslation } from "react-i18next";

interface IProps {
  lineItems?: Array<Record<string, any>>;
  modifierGroups?: any;
  handleLineItemClick?: (line: Record<string, any>) => void;
  darkStyle?: boolean;
}

interface IMainState {
  products: any;
}

interface IModifierGroupsState {
  [key: string]: ICartLineItem[];
}

export default function CartLineItems(props: IProps) {
  const { t } = useTranslation();
  const [modifierGroups, setModifierGroups] = useState<IModifierGroupsState>({});

  const [state, setState] = useState<IMainState>({
    products: null,
  });

  useEffect(() => {
    void groupModifiers();
  }, [props.lineItems]);

  function groupModifiers() {
    const groups = props.lineItems?.reduce(
      (prevModifierGroups: { [key: string]: Array<ICartLineItem> }, lineItem: any) => {
        if (lineItem.parent_id !== null) {
          return {
            ...prevModifierGroups,
            [lineItem.parent_id]: [...(prevModifierGroups[lineItem.parent_id] || []), lineItem],
          };
        } else {
          return prevModifierGroups;
        }
      },
      {},
    );

    setModifierGroups(groups);
    console.log("mod groups", modifierGroups);
  }

  return (
    <>
      {props.lineItems?.map(line_item => {
        if (line_item.parent_id === null) {
          return (
            <div key={line_item.id} className="ui-register-line-items">
              <div key={String(line_item.id)} className="ui-register-line-item">
                <div
                  className={
                    "flex w-full items-center " +
                    (props.handleLineItemClick && props?.darkStyle
                      ? "hover:bg-gray-500 cursor-pointer"
                      : props.handleLineItemClick
                      ? "hover:bg-gray-100 cursor-pointer"
                      : "")
                  }
                  style={{ height: line_item.product_title === line_item.variant_title && "3rem" }}
                  onClick={props.handleLineItemClick ? () => props.handleLineItemClick(line_item) : undefined}
                >
                  <div className="line-item-quantity">
                    <span className="text-md text-subdued">{line_item.quantity}</span>
                  </div>
                  <div className="line-item-titles">
                    <p className="text-md">
                      {line_item.preferred_title ? line_item.preferred_title : line_item.product_title}
                    </p>
                    {line_item.product_title !== line_item.variant_title &&
                      line_item.preferred_title !== line_item.variant_title && (
                        <p className="text-sm text-subdued"> {line_item.variant_title}</p>
                      )}
                  </div>
                  <span className="text-semibold text-lg ml-auto">
                    <LocaleCurrency currency="cad" amount={line_item.subtotal_price} />
                  </span>
                </div>
                {modifierGroups &&
                  line_item.id.toString() in modifierGroups &&
                  modifierGroups[line_item.id.toString()].map((modifier: any) => {
                    return (
                      <div
                        className={
                          "items-center flex w-full " +
                          (props.handleLineItemClick && props?.darkStyle
                            ? "hover:bg-gray-500 cursor-pointer"
                            : props.handleLineItemClick
                            ? "hover:bg-gray-100 cursor-pointer"
                            : "")
                        }
                        style={{ height: modifier.product_title === modifier.variant_title && "3rem" }}
                        key={modifier.id}
                        onClick={props.handleLineItemClick ? () => props.handleLineItemClick(modifier) : undefined}
                      >
                        <p className="mx-6">+</p>
                        <div className="line-item-quantity">
                          <span className="text-md text-subdued">{modifier.quantity}</span>
                        </div>
                        <div className="line-item-titles">
                          <p className="text-md">
                            {modifier?.preferred_title ? modifier?.preferred_title : modifier?.product_title}
                          </p>
                          {modifier.product_title !== modifier.variant_title && (
                            <p className="text-sm text-subdued">{modifier.variant_title}</p>
                          )}
                        </div>
                        <span className="text-semibold text-lg ml-auto">
                          <LocaleCurrency currency="cad" amount={modifier.subtotal_price} />
                        </span>
                      </div>
                    );
                  })}

                {line_item.note && (
                  <>
                    <div
                      className={`font-semibold line-item-action table-service-table-menu-content-line-item-primary-double`}
                      // onClick={() => handleQuickOpenNoteSheet(line_item)
                      // cartStore.cart?.status !== "complete" &&
                      // cartStore?.cart?.status !== "void" &&
                      // paymentState?.order?.financial_status !== "partially_paid"
                      //   ? () => handleQuickOpenNoteSheet(lineItem, cartStore.cart)
                      //   : undefined
                      // }
                    >
                      <div>{"Note"}</div>
                      <div className="table-service-table-menu-content-line-item-action-connector">
                        <hr className="table-service-table-menu-content-line-item-pointer" />
                      </div>
                    </div>
                    <div />

                    <div className="text-subdued table-service-table-menu-content-line-item-primary-note">
                      {line_item.note}
                      <div className="table-service-table-menu-content-line-item-connector" style={{ left: "-22px" }} />
                    </div>
                  </>
                )}
              </div>
            </div>
          );
        }
      })}
    </>
  );
}
