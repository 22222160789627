import { APIGet, APIPost, APIPut, APIDelete, IAPIResponse } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/product/inventory/count";

export type TInventoryCount = {
  created_at: string;
  created_by: number;
  created_by_full_name: string;
  date_created: string;
  date_submitted: string;
  deleted_at: string;
  facility_id: number;
  id: number;
  name: string;
  number: number;
  status: string;
  submitted_by: number;
  updated_at: string;
};

export type TInventoryCountVariants = {
  adjustment_processed: number;
  adjustment_processed_at: string;
  barcode: string;
  created_at: string;
  deleted_at: string;
  id: number;
  inventory_count_id: number;
  product_id: number;
  product_subtitle: string;
  product_title: string;
  product_vendor_title: string;
  quantity_available_at_submission: number;
  quantity_counted: number;
  quantity_difference: number;
  quantity_sold: number;
  sku: string;
  updated_at: string;
  variant_id: number;
  variant_title: string;
};

export async function GetInventoryCount(
  params: { id?: number; offset?: number; limit?: number },
  useGlobalLoader: boolean,
  token?: CancelToken,
): Promise<IAPIResponse<Array<TInventoryCount>>> {
  return await APIGet(baseUrl, params, useGlobalLoader, token);
}

export async function PostInventoryCount(
  useGlobalLoader: boolean,
): Promise<Omit<IAPIResponse<TInventoryCount>, "message">> {
  return await APIPost(baseUrl, null, useGlobalLoader);
}

export async function GetInventoryCountVariants(
  params: { inventory_count_id?: number },
  useGlobalLoader: boolean,
  token?: CancelToken,
): Promise<IAPIResponse<Array<TInventoryCountVariants>>> {
  return await APIGet(baseUrl + "/variant", params, useGlobalLoader, token);
}

export async function PostInventoryCountVariants(
  params: { inventory_count_id: number; variants: Array<{ variant_id: number; quantity_counted: number }> },
  useGlobalLoader: boolean,
): Promise<Omit<IAPIResponse<TInventoryCount>, "message">> {
  return await APIPost(baseUrl + "/variant", params, useGlobalLoader);
}

export async function DeleteInventoryCountVariants(
  params: { inventory_count_id: number; variants: Array<number> },
  useGlobalLoader: boolean,
) {
  return await APIDelete(baseUrl + "/variant", params, useGlobalLoader);
}

export async function PutSubmitInventoryCount(params: { inventory_count_id: number }, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/submit", params, useGlobalLoader);
}

export async function PutExportInventoryCount(params: { inventory_count_id: number }, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/export", params, useGlobalLoader);
}

export async function PutVoidInventoryCount(params: { inventory_count_id: number }, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/void", params, useGlobalLoader);
}

export async function PutDownloadInventoryCount(params: { inventory_count_id: number }, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/download", params, useGlobalLoader);
}
