import React, { useEffect, useState } from "react";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetFacility, PutFacilityPermissions } from "api/rpc/2022-09/clientAdmin/facility/facility";

import { showSuccess } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Toggle from "components/form/toggle/Toggle";

interface IFacilityPermissionState {
  facilities: any; // TODO: Typesafety
}

export default function FacilityPermissions() {
  const dispatch = useAppDispatch();

  const [facilityPermissionState, setFacilityPermissionState] = useState<IFacilityPermissionState>({
    facilities: [],
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadFacilities(source.token);
    return () => source.cancel();
  }, []);

  async function loadFacilities(token?: CancelToken) {
    const facilityRes = await GetFacility({ extended: true, permissions: true }, true, token);

    if (facilityRes.status !== StatusCode.OK) {
      return;
    }

    setFacilityPermissionState(prev => ({
      ...prev,
      facilities: facilityRes.data,
    }));
  }

  function handlePermissionsChange(event: React.ChangeEvent<HTMLInputElement>, index: number) {
    const { id, checked } = event.target;
    const tempFacilities = [...facilityPermissionState.facilities];

    console.log(tempFacilities);

    tempFacilities[index].permissions = { ...tempFacilities[index].permissions, [id]: checked };
    setFacilityPermissionState(prevState => ({ ...prevState, facilities: tempFacilities }));
  }

  const primaryAction = {
    content: "Save", // TODO: Translation
    action: savePermissions,
  };

  async function savePermissions() {
    const tempPermissions = [];

    for (let i = 0; i < facilityPermissionState.facilities.length; i++) {
      tempPermissions.push(facilityPermissionState.facilities[i].permissions);
    }

    const params = {
      facility_permissions: tempPermissions,
    };

    const facilityPermissionsRes = await PutFacilityPermissions(params, true);

    if (facilityPermissionsRes.status !== StatusCode.OK) {
      return;
    }

    dispatch(showSuccess("Successfully saved facility permissions.")); // TODO: Translation
  }

  return (
    <Page
      title="Facility Permissions" // TODO: Translation
      subtitle=""
      primaryAction={primaryAction}
    >
      {facilityPermissionState.facilities.map((facility: any, index: number) => {
        return (
          <Card key={index} title={facility.long_name} collapsable defaultCollapsed>
            <Card.Section>
              <FormLayout>
                <FormLayout.Group className="mt-4">
                  <Toggle
                    size="medium"
                    id="products_create"
                    labelTop="Create Products" // TODO: Translation
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePermissionsChange(e, index)}
                    checked={facility.permissions?.products_create}
                  ></Toggle>

                  <Toggle
                    size="medium"
                    id="customer_types_create"
                    labelTop="Create Customer Types" // TODO: Translation
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePermissionsChange(e, index)}
                    checked={facility.permissions?.customer_types_create}
                  ></Toggle>

                  <Toggle
                    size="medium"
                    id="gift_cards_import"
                    labelTop="Import Gift Cards" // TODO: Translation
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePermissionsChange(e, index)}
                    checked={facility.permissions?.gift_cards_import}
                  ></Toggle>
                </FormLayout.Group>
                <FormLayout.Group>
                  <Toggle
                    size="medium"
                    id="courses_create"
                    labelTop="Create Courses" // TODO: Translation
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePermissionsChange(e, index)}
                    checked={facility.permissions?.courses_create}
                  ></Toggle>

                  <Toggle
                    size="medium"
                    id="divisions_create"
                    labelTop="Create Divisions" // TODO: Translation
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePermissionsChange(e, index)}
                    checked={facility.permissions?.divisions_create}
                  ></Toggle>

                  <Toggle
                    size="medium"
                    id="accounts_import"
                    labelTop="Import Accounts" // TODO: Translation
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePermissionsChange(e, index)}
                    checked={facility.permissions?.accounts_import}
                  ></Toggle>
                </FormLayout.Group>
                <FormLayout.Group>
                  <Toggle
                    labelTop={"Create Departments"}
                    id="departments_create"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePermissionsChange(e, index)}
                    checked={facility.permissions?.departments_create}
                    size="medium"
                    // disabled={permissions?.user_id === staffState.id}
                  />
                  <Toggle
                    labelTop={"Edit Departments"}
                    id="departments_edit"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePermissionsChange(e, index)}
                    checked={facility.permissions?.departments_edit}
                    size="medium"
                    // disabled={permissions?.user_id === staffState.id}
                  />
                  <div></div>
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>
          </Card>
        );
      })}
    </Page>
  );
}
