import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import StoreHeader from "./StoreHeader/StoreHeader";
import "./SingleProduct.scss";
import { GetProducts } from "api/rpc/guest/product";
import { GetFacility } from "api/rpc/guest/facility";
import { StatusCode } from "api/protocols";
import { IProduct, IProductImage, IVariant } from "../models/product";
import { displayCurrency } from "helpers/Helpers";
import { ICartState } from "redux/reducers/customer/cart";
import { ICartActions } from "redux/actions/customer/cart";
import { IUIActions } from "redux/actions/ui";
import { IFacility } from "../models/facility";
import { ICart } from "redux/reducers/customer/models/cart";
import { StorePage } from "./StorePage/StorePage";
import Markdown from "components/markdown/Markdown";
import { useTranslation } from "react-i18next";
import { getOnlineStoreCartToken, getOnlineStoreCartTokenName } from "./HomePage";

interface ISingleProductPageProps {
  cartStore: ICartState;
  cartActions: ICartActions;
  uiActions: IUIActions;
}

interface ISelectedOption {
  id: number;
  position: number;
  value: string;
}

interface ISingleProductPage {
  facility: IFacility;
  product: IProduct;
  selectedOptions: ISelectedOption[];
  selectedVariant: IVariant;
  loaded: boolean;
  productImageSelected: number;
}

export default function SingleProduct(props: ISingleProductPageProps) {
  const { facilityShortName, productHandle } = useParams<{ facilityShortName: string; productHandle: string }>();

  const { cartStore, cartActions, uiActions } = props;

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const MAX_LINE_ITEM_QUANTITY = 15;

  const [singleProductPageState, setSingleProductPageState] = useState<ISingleProductPage>({
    facility: undefined,
    product: undefined,
    selectedOptions: undefined,
    selectedVariant: undefined,
    loaded: false,
    productImageSelected: 0,
  });

  useEffect(() => {
    void loadSingleProduct();
  }, []);

  console.log("%cSingleProduct.tsx line:63 singleProductPageState", "color: #007acc;", singleProductPageState);

  async function loadSingleProduct() {
    uiActions.enqueue();

    try {
      const facilityRes = await GetFacility({ short_name: facilityShortName }, false);
      const facilityId = facilityRes?.data?.[0]?.id;

      if (facilityRes.status !== StatusCode.OK || facilityId === undefined) {
        uiActions.showError(t("guest.online_store.single_product.001"));
        uiActions.dequeue();
        return;
      }

      let cart: ICart = undefined;

      if (getOnlineStoreCartToken(facilityShortName)) {
        try {
          const getCartPromise: Promise<ICart> = cartStore?.isLoaded
            ? Promise.resolve(cartStore?.cart)
            : (cartActions.loadCart(
                {
                  facility_id: facilityId,
                  tokenName: getOnlineStoreCartTokenName(facilityShortName),
                  token: getOnlineStoreCartToken(facilityShortName),
                },
                false,
              ) as unknown as Promise<ICart>);

          cart = await getCartPromise;
        } catch {
          uiActions.showError(t("guest.online_store.single_product.002"));
          uiActions.dequeue();
          return;
        }
      }

      if (cart?.status === "complete") {
        cartActions.cartClear({ tokenName: getOnlineStoreCartTokenName(facilityShortName) });
      }

      const productsRes = await GetProducts(
        { sales_channel_type: "online_store", facility_id: facilityId, handle: productHandle, inventory: true },
        false,
      );

      if (productsRes.status !== StatusCode.OK) {
        uiActions.showError(t("guest.online_store.single_product.003"));
        uiActions.dequeue();
        return;
      }

      const productData = {
        ...productsRes.data?.[0],
        options: productsRes.data?.[0].options?.sort(
          (previousOption, nextOption) => previousOption.position - nextOption.position,
        ),
      };

      const selectedOptions: ISelectedOption[] = [];

      if (productData) {
        productData.options.map(option => {
          selectedOptions.push({ id: option.id, position: option.position, value: option.values?.[0] });
        });
      }

      setSingleProductPageState(prevState => {
        return {
          ...prevState,
          facility: facilityRes.data[0],
          product: productData,
          selectedOptions: selectedOptions,
          loaded: true,
        };
      });

      uiActions.dequeue();
    } catch {
      uiActions.dequeue();
    }
  }

  useEffect(() => {
    if (singleProductPageState.selectedOptions) {
      const option1 = singleProductPageState.selectedOptions[0]?.value ?? null;
      const option2 = singleProductPageState.selectedOptions[1]?.value ?? null;
      const option3 = singleProductPageState.selectedOptions[2]?.value ?? null;

      const selectedVariant = singleProductPageState.product.variants.find(variant => {
        return option1 === variant.option1 && option2 === variant.option2 && option3 === variant.option3;
      });

      //Check if variant cannot be sold
      if (selectedVariant) {
        const variantCannotBeSold = selectedVariant?.collect?.some(
          collect => collect?.facility_id === singleProductPageState?.facility?.id && collect?.cannot_be_sold,
        );
        if (variantCannotBeSold) {
          setSingleProductPageState(prevState => {
            return { ...prevState, selectedVariant: undefined };
          });
          return;
        }
      }

      setSingleProductPageState(prevState => {
        return { ...prevState, selectedVariant: selectedVariant };
      });
    }
  }, [singleProductPageState.selectedOptions]);

  function navigateToShoppingBag() {
    history.push(`/online-store/${facilityShortName}/shoppingBag`);
  }

  function getVariantPrice(variant: IVariant) {
    if (!variant) {
      return "Not Available";
    }

    const priceOverride = variant.inventory_item?.inventory_levels?.find(
      inventoryLevel => inventoryLevel?.facility_id === singleProductPageState.facility?.id,
    )?.price_override;

    const price = priceOverride ?? variant.price;

    return !!price || price === 0 ? displayCurrency("cad", price) : "Not Available";
  }

  function addToBag() {
    if (singleProductPageState.selectedVariant) {
      uiActions.enqueue();

      try {
        const postCartPromise: Promise<ICart> = cartStore.isLoaded
          ? Promise.resolve(cartStore.cart)
          : (cartActions.postCart(
              {
                tokenName: getOnlineStoreCartTokenName(facilityShortName),
                facility_id: singleProductPageState.facility.id,
              },
              false,
            ) as unknown as Promise<ICart>);

        postCartPromise
          .then(cart => {
            const existingLineItem = cartStore.cart?.line_items?.find(
              lineItem => lineItem.variant_id === singleProductPageState.selectedVariant.id,
            );

            if (existingLineItem?.quantity >= MAX_LINE_ITEM_QUANTITY) {
              return Promise.resolve(undefined);
            } else {
              const postLineItemPromise: Promise<ICart> = cartActions.postLineItem(
                cart.token,
                singleProductPageState.selectedVariant.id,
                1,
                false,
              ) as unknown as Promise<ICart>;

              return postLineItemPromise;
            }
          })
          .then(() => {
            uiActions.dequeue();
            navigateToShoppingBag();
          })
          .catch(() => {
            uiActions.dequeue();
            uiActions.showError(t("guest.online_store.single_product.004"));
          });
      } catch {
        uiActions.dequeue();
      }
    }
  }

  function handleSelectedOptionValueChange(id: number, value: string) {
    if (id === undefined || value === undefined) {
      return;
    }

    setSingleProductPageState(prevState => {
      return {
        ...prevState,
        selectedOptions: prevState.selectedOptions.map(selectedOption => {
          if (selectedOption.id === id) {
            return { ...selectedOption, value: value };
          } else {
            return selectedOption;
          }
        }),
      };
    });
  }

  return (
    <>
      {singleProductPageState.loaded ? (
        <div>
          <StoreHeader
            facilityShortName={facilityShortName}
            facilityLogoSource={singleProductPageState.facility.logo_source}
            shoppingBagHasItems={cartStore.cart?.line_items?.length > 0}
          />
          <StorePage>
            {singleProductPageState.product ? (
              <div className="single-product-page">
                <div className="product-image-container">
                  <div className="product-image-center-alignment">
                    <img
                      className="product-image"
                      src={singleProductPageState.product.images?.[singleProductPageState.productImageSelected]?.source}
                    />
                  </div>
                  <div className="product-image-selection">
                    {singleProductPageState.product.images.map((productImage: IProductImage, index: number) => {
                      return (
                        <img
                          key={index}
                          className={`product-image-option ${
                            singleProductPageState.productImageSelected === index ? "product-image-active" : ""
                          }`}
                          src={productImage.source}
                          onClick={() =>
                            setSingleProductPageState(prevState => {
                              return { ...prevState, productImageSelected: index };
                            })
                          }
                        />
                      );
                    })}
                  </div>
                </div>
                <div className="product-content-container">
                  <div className="product-title">{singleProductPageState.product.title}</div>
                  <div className="product-price">{getVariantPrice(singleProductPageState.selectedVariant)}</div>
                  {singleProductPageState.product.options.map(option => {
                    const selectedOption = singleProductPageState.selectedOptions.find(
                      selectedOption => selectedOption.id === option.id,
                    );

                    return (
                      <div key={option.id} className="product-option-container">
                        <div className="product-option-label-container">
                          <span className="product-option-label">{option.name}</span>
                          <span className="product-option-selection">{selectedOption?.value}</span>
                        </div>
                        {option.values.map((value, index) => {
                          return (
                            <button
                              key={index}
                              className={`product-option ${
                                selectedOption?.value === value ? "product-option-selected" : ""
                              }`}
                              onClick={() => {
                                handleSelectedOptionValueChange(selectedOption?.id, value);
                              }}
                            >
                              {value}
                            </button>
                          );
                        })}
                      </div>
                    );
                  })}
                  <button className="product-add-to-bag" onClick={addToBag}>
                    {t("guest.online_store.single_product.005")}
                  </button>
                  {(singleProductPageState?.product?.description?.length > 0 ||
                    singleProductPageState?.selectedVariant?.description?.length > 0) && (
                    <div className="product-description-container">
                      <div className="product-description-label">{t("guest.online_store.single_product.006")}</div>
                      {singleProductPageState?.product?.description?.length > 0 && (
                        <div className="product-description">
                          <Markdown markdownText={singleProductPageState.product.description} />
                        </div>
                      )}
                      {singleProductPageState?.selectedVariant?.description?.length > 0 && (
                        <div className="product-description">
                          <Markdown markdownText={singleProductPageState.selectedVariant.description} />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </StorePage>
        </div>
      ) : null}
    </>
  );
}
