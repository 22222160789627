import { APIGet, APIPost, APIPut } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/client/admin";

type TPutFacilityAdmin = {
  id: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  username?: string;
  quick_code?: number;
};

type TPutFacilityAdminPermissions = {
  user_id: number;
  accounts_charge_all: boolean;
  accounts_post_adjustment: boolean;
  admin_accounts_disable: boolean;
  admin_permissions_edit: boolean;
  admin_accounts_edit: boolean;
  products_archive: boolean;
  products_create: boolean;
  products_edit: boolean;
  products_edit_cost: boolean;
  products_edit_price: boolean;
  orders_remove_line_item: boolean;
  orders_remove_line_item_after_send: boolean;
  orders_edit_line_item_price: boolean;
  orders_apply_discount: boolean;
  orders_void: boolean;
  orders_refund_tip: boolean;
  reports_access: boolean;
  tee_sheet_credit_card_override: boolean;
  tee_sheet_charge_no_show: boolean;
  orders_edit_user: boolean;
  orders_edit_register: boolean;
  orders_backdate: boolean;
  table_service_table_override: boolean;
  reservations_credit_card_override: boolean;
  merge_customers: boolean;

  cash_outs_create: boolean;
  cash_outs_edit: boolean;
  cash_outs_view_summary: boolean;

  memberships_apply_manual: boolean;
  user_edit_customer_type: boolean;
  discounts_create: boolean;
  gift_cards_view: boolean;
  customers_edit_note: boolean;
  bookings_edit_note: boolean;
  gift_cards_import: boolean;

  courses_create: boolean;
  divisions_create: boolean;
};

export async function GetFacilityAdmin(params: any, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseUrl, params, useGlobalLoader, token);
}

export async function PostFacilityAdmin(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

export async function PutFacilityAdmin(params: TPutFacilityAdmin, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

export async function PutFacilityAdminPermissions(params: TPutFacilityAdminPermissions, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/permission", params, useGlobalLoader);
}

export async function PutDisableFacilityAdmin(params: { admin_id: number }, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/disable", params, useGlobalLoader);
}

export async function PutReactivateFacilityAdmin(params: { admin_id: number }, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/reactivate", params, useGlobalLoader);
}
