import { APIPost } from "api/protocols";

const apiVersion = "2022-09";
const adminType = "guest";
const baseCustomerUrl = "/" + apiVersion + "/" + adminType + "/customer";

interface IPostCustomer {
  first_name: string;
  last_name: string;
  email: string;
}

interface ICustomer {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  full_name: string;
  member_code: string;
  customer_type: string;
  customer_type_id: number;
  cart_customer_type: string;
  cart_customer_type_id: number;
  member_number: number;
  phone: string;
  state: string;
  initials: string;
}

interface IPostCustomerResponse {
  data: ICustomer;
  status: number;
}

interface IPostAddressLine {
  customer_email: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  country_id: number;
  province_id: number;
  postal: string;
  phone: string;
}

interface IAddressLine {
  id: number;
  customer_id: number;
  default: boolean;
  first_name: string;
  last_name: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  province_id: number;
  province_code: string;
  province_name: string;
  country_id: number;
  country_code: string;
  postal: string;
  phone: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

interface IPostAddressLineResponse {
  data: IAddressLine;
  status: number;
}

export async function PostCustomer(params: IPostCustomer, useGlobalLoader: boolean): Promise<IPostCustomerResponse> {
  return await APIPost(baseCustomerUrl, params, useGlobalLoader);
}

export async function PostAddressLine(
  params: IPostAddressLine,
  useGlobalLoader: boolean,
): Promise<IPostAddressLineResponse> {
  return await APIPost(baseCustomerUrl + "/address-line", params, useGlobalLoader);
}
