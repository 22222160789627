import React, { useEffect, useRef, useState } from "react";
import { IFacility } from "../models/facility";
import TransactionalHeader from "./TransactionalHeader/TransactionalHeader";
import { useHistory, useParams } from "react-router-dom";
import { ICartState } from "redux/reducers/customer/cart";
import { ICartActions } from "redux/actions/customer/cart";
import { IUIActions } from "redux/actions/ui";
import { StorePage } from "./StorePage/StorePage";
import { GetFacility } from "api/rpc/guest/facility";
import { StatusCode } from "api/protocols";
import { ICart } from "redux/reducers/customer/models/cart";
import { getOnlineStoreCartToken, getOnlineStoreCartTokenName } from "./HomePage";
import {
  GetFormResponse,
  GetForms,
  IForm,
  IFormResponse,
  IPostFormInput,
  IPutFormInput,
  PostFormResponse,
  PutFormResponse,
} from "api/rpc/2022-09/guest/form";
import Checkbox from "components/form/checkbox/Checkbox";
import Form from "components/form/Form";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import { Select } from "components/select/index";
import Input from "components/form/input/Input";
import "./Forms.scss";
import OrderSummary from "components/OrderSummary/OrderSummary";
import { cloneDeep, flatten, groupBy, range } from "lodash";
import { isEmpty } from "helpers/Helpers";
import { IActiveFormResponse, IOnlineStoreState } from "redux/reducers/customer/onlineStore";
import { IOnlineStoreActions } from "redux/actions/customer/onlineStore";
import { useTranslation } from "react-i18next";
import Callout from "components/callout/Callout";

interface IFormsState {
  facility: IFacility;
  forms: IForm[];
  formsData: FormsData;
}

interface IFormsProps {
  onlineStoreStore: IOnlineStoreState;
  onlineStoreActions: IOnlineStoreActions;
  cartStore: ICartState;
  cartActions: ICartActions;
  uiActions: IUIActions;
}

type FormDatumInput = {
  formResponseInputId: number;
  formInputId: number;
  isValid: boolean;
  isDirty: boolean;
} & ({ type: "select"; value: string } | { type: "input"; value: string } | { type: "checkbox"; value: boolean });

type FormDatum = {
  formResponseId: number;
  inputs: FormDatumInput[];
};

type FormsData = FormDatum[];

export default function Forms(props: IFormsProps) {
  const { facilityShortName } = useParams<{ facilityShortName: string }>();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const formRefs = useRef([]);
  const { onlineStoreStore, onlineStoreActions, cartStore, cartActions, uiActions } = props;
  const { Option } = Select;

  const [formsState, setFormsState] = useState<IFormsState>({
    facility: null,
    forms: [],
    formsData: null,
  });

  function navigateToHomePage() {
    history.push(`/online-store/${facilityShortName}`);
  }

  function navigateToCheckout() {
    history.push(`/online-store/${facilityShortName}/checkout`);
  }

  function navigateToPayment() {
    history.push(`/online-store/${facilityShortName}/payment`);
  }

  function cancelTransaction() {
    cartActions.cartClear({ tokenName: getOnlineStoreCartTokenName(facilityShortName) });
    navigateToHomePage();
  }

  useEffect(() => {
    void loadForms();
  }, []);

  function isInputValueValid(required: boolean, value: string | boolean) {
    return !required || (required && !!value);
  }

  async function loadForms() {
    uiActions.enqueue();

    try {
      onlineStoreActions.updateActiveFormResponses(null);
      const facilityRes = await GetFacility({ short_name: facilityShortName }, false);
      const facilityId = facilityRes?.data?.[0]?.id;

      if (facilityRes.status !== StatusCode.OK || facilityId === undefined) {
        uiActions.showError(t("guest.online_store.forms.001"));
        uiActions.dequeue();
        navigateToHomePage();
        return;
      }

      const facility = facilityRes.data[0];

      let cart: ICart = undefined;

      if (getOnlineStoreCartToken(facilityShortName)) {
        try {
          const getCartPromise: Promise<ICart> = cartStore?.isLoaded
            ? Promise.resolve(cartStore?.cart)
            : (cartActions.loadCart(
                {
                  facility_id: facilityId,
                  tokenName: getOnlineStoreCartTokenName(facilityShortName),
                  token: getOnlineStoreCartToken(facilityShortName),
                },
                false,
              ) as unknown as Promise<ICart>);

          cart = await getCartPromise;
        } catch {
          uiActions.showError(t("guest.online_store.forms.002"));
          uiActions.dequeue();
          navigateToHomePage();
          return;
        }
      } else {
        uiActions.dequeue();
        navigateToHomePage();
        return;
      }

      if (cart === undefined || cart.line_items.length === 0) {
        uiActions.dequeue();
        navigateToHomePage();
        return;
      } else if (cart.status === "complete") {
        uiActions.dequeue();
        cancelTransaction();
        return;
      } else if (!cart.customer || !cart.billing_address_line) {
        uiActions.dequeue();
        navigateToCheckout();
        return;
      }

      const variant_ids = cart.line_items.map(lineItem => lineItem.variant_id);
      const formsRes = await GetForms({ client_id: facility.client_id, variant_ids, inputs: true }, false);

      if (formsRes.status !== StatusCode.OK) {
        uiActions.showError(t("guest.online_store.forms.003"));
        uiActions.dequeue();
        navigateToCheckout();
        return;
      }

      if (formsRes.data.length === 0) {
        uiActions.dequeue();
        navigateToPayment();
        return;
      }

      const getFormsResponse = await GetFormResponse({ client_id: facility.client_id, cart_id: cart.id }, false);

      if (formsRes.status !== StatusCode.OK) {
        uiActions.showError(t("guest.online_store.forms.004"));
        uiActions.dequeue();
        navigateToCheckout();
        return;
      }

      const forms: IForm[] = [];

      const formsForEachVariant = groupBy(formsRes.data, data => data.variant_id);

      for (const variantId in formsForEachVariant) {
        const variantForms = formsForEachVariant[variantId].map(variantFormContainer => variantFormContainer.form);

        const associatedLineItem = cart.line_items.find(lineItem => lineItem.variant_id === Number(variantId));

        if (associatedLineItem) {
          const duplicatedForms = range(0, associatedLineItem.quantity).map(() => cloneDeep(variantForms));
          const flattenedDuplicatedForms = flatten(duplicatedForms);
          forms.push(...flattenedDuplicatedForms);
        }
      }

      forms.forEach(form => {
        form.inputs.sort((prevInput, nextInput) => {
          const prevInputRow = prevInput.row;
          const nextInputRow = nextInput.row;

          if (prevInputRow < nextInputRow) {
            return -1;
          } else if (prevInputRow > nextInputRow) {
            return 1;
          } else {
            return prevInput.column - nextInput.column;
          }
        });
      });

      const formResponsesSelection = cloneDeep(getFormsResponse.data);

      const formsData: FormsData = [];

      forms.forEach(form => {
        const formResponseIndex = formResponsesSelection.findIndex(formResponse => formResponse.form_id === form.id);
        let formResponse: IFormResponse = null;

        if (formResponseIndex !== -1) {
          formResponse = formResponsesSelection.splice(formResponseIndex, 1)[0];
        }

        const formDatum: FormDatum = {
          formResponseId: formResponse?.id,
          inputs: [],
        };

        form.inputs.forEach(input => {
          let formInputValue: FormDatumInput = undefined;

          const formResponseInput = formResponse?.inputs?.find(
            formResponseInput => formResponseInput.form_input_id === input.id,
          );

          if (input.type === "select") {
            formInputValue = {
              formResponseInputId: formResponseInput?.id,
              formInputId: input.id,
              isValid: isInputValueValid(input.required, ""),
              isDirty: false,
              type: "select",
              value: "",
            };
          } else if (input.type === "input") {
            formInputValue = {
              formResponseInputId: formResponseInput?.id,
              formInputId: input.id,
              isValid: isInputValueValid(input.required, ""),
              isDirty: false,
              type: "input",
              value: "",
            };
          } else if (input.type === "checkbox") {
            formInputValue = {
              formResponseInputId: formResponseInput?.id,
              formInputId: input.id,
              isValid: isInputValueValid(input.required, false),
              isDirty: false,
              type: "checkbox",
              value: false,
            };
          }

          if (formInputValue) {
            formDatum.inputs.push(formInputValue);
          }
        });

        formsData.push(formDatum);
      });

      setFormsState(prev => ({
        ...prev,
        facility,
        forms,
        formsData: formsData,
      }));

      uiActions.dequeue();
    } catch {
      uiActions.dequeue();
    }
  }

  async function updateForms() {
    try {
      const updatedFormsData = cloneDeep(formsState.formsData);

      updatedFormsData.forEach(formsData => {
        formsData.inputs.forEach(input => {
          input.isDirty = true;
        });
      });

      setFormsState(prev => ({ ...prev, formsData: updatedFormsData }));

      for (let i = 0; i < updatedFormsData.length; i++) {
        const formData = updatedFormsData[i];

        const invalidInputExists = formData.inputs.some(input => !input.isValid);

        if (invalidInputExists) {
          formRefs?.current?.[i].scrollIntoView({ behavior: "smooth", block: "start" });
          return;
        }
      }

      uiActions.enqueue();

      for (let i = 0; i < updatedFormsData.length; i++) {
        const form = formsState.forms[i];
        const formDatum = updatedFormsData[i];

        if (!formDatum.formResponseId) {
          const postFormInputs: IPostFormInput[] = [];

          formDatum.inputs.forEach(formInput => {
            const postFormInput: IPostFormInput = { form_input_id: formInput.formInputId, value: formInput.value };
            postFormInputs.push(postFormInput);
          });

          const postFormResponse = await PostFormResponse(
            {
              form_id: form.id,
              client_id: form.client_id,
              order_id: null,
              customer_id: cartStore.cart?.customer_id,
              cart_id: cartStore.cart?.id,
              inputs: postFormInputs,
            },
            false,
          );

          if (postFormResponse.status !== StatusCode.OK) {
            uiActions.dequeue();
            uiActions.showError(t("guest.online_store.forms.005"));
            setFormsState(prev => ({ ...prev, formsData: updatedFormsData }));
            return;
          }

          // If an error occurs in another request, the form should be PUT not POST on the second attempt
          // So here it needs to be updated with the post form response values

          formDatum.formResponseId = postFormResponse.data.id;

          formDatum.inputs.map(input => {
            input.formResponseInputId = postFormResponse.data.inputs.find(
              formResponseInput => formResponseInput.form_input_id === input.formInputId,
            )?.id;
          });
        } else {
          const putFormInputs: IPutFormInput[] = [];

          formDatum.inputs.forEach(formInput => {
            const putFormInput: IPutFormInput = {
              id: formInput.formResponseInputId,
              form_input_id: formInput.formInputId,
              value: formInput.value,
            };
            putFormInputs.push(putFormInput);
          });

          const putFormResponse = await PutFormResponse(
            {
              id: formDatum.formResponseId,
              client_id: form.client_id,
              inputs: putFormInputs,
            },
            false,
          );

          if (putFormResponse.status !== StatusCode.OK) {
            uiActions.dequeue();
            uiActions.showError(t("guest.online_store.forms.006"));
            setFormsState(prev => ({ ...prev, formsData: updatedFormsData }));
            return;
          }
        }
      }

      const activeFormResponses: IActiveFormResponse[] =
        updatedFormsData?.map(formData => ({ id: formData.formResponseId })) ?? [];

      onlineStoreActions.updateActiveFormResponses(activeFormResponses);

      uiActions.dequeue();
      navigateToPayment();
    } catch {
      uiActions.dequeue();
    }
  }

  function handleFormDataChange(formIndex: number, input: FormDatumInput) {
    const updatedFormInputs: FormDatumInput[] = cloneDeep(formsState.formsData[formIndex].inputs);

    const isRequired = formsState.forms[formIndex].inputs.find(
      formInput => formInput.id === input.formInputId,
    )?.required;
    const inputToChangeIndex = updatedFormInputs.findIndex(formInput => formInput.formInputId === input.formInputId);

    if (inputToChangeIndex !== -1) {
      updatedFormInputs[inputToChangeIndex] = {
        ...input,
        isValid: isInputValueValid(isRequired, input.value),
        isDirty: true,
      };
    }

    const updatedFormsData: FormsData = cloneDeep(formsState.formsData);
    updatedFormsData[formIndex].inputs = updatedFormInputs;

    setFormsState(prev => ({ ...prev, formsData: updatedFormsData }));
  }

  function renderOrderSummary(togglableVisibility: boolean) {
    return (
      <OrderSummary
        subtotal={cartStore.cart.subtotal_price}
        taxLines={cartStore.cart.tax_lines}
        discount={cartStore.cart.total_discount}
        total={cartStore.cart.total_price}
        togglableVisibility={togglableVisibility}
        lineItems={cartStore.cart.line_items.map(lineItem => {
          return {
            src: lineItem.product_default_image?.source,
            productTitle: lineItem.product_title,
            variantTitle: lineItem.variant_title,
            quantity: lineItem.quantity,
            price: lineItem.subtotal_price,
          };
        })}
      />
    );
  }

  function getFormRowRange(form: IForm) {
    if (!form?.inputs) {
      return [];
    }

    let maxRow = 0;

    form.inputs.forEach(formInput => {
      if (formInput.row > maxRow) {
        maxRow = formInput.row;
      }
    });

    return range(1, maxRow + 1, 1);
  }

  return (
    <div>
      {formsState.facility && (
        <>
          <header>
            <TransactionalHeader
              facilityShortName={facilityShortName}
              facilityLogoSource={formsState.facility.logo_source}
              shoppingBagHasItems={cartStore?.cart?.line_items?.length > 0}
            />
          </header>
          <main>
            <StorePage>
              {cartStore.isLoaded && cartStore.cart && (
                <div className="forms-page-sections">
                  <section className="forms-order-summary-mobile-section">{renderOrderSummary(false)}</section>
                  <section className="forms-information-section">
                    {formsState.forms?.map((form, index) => {
                      return (
                        <Card key={index}>
                          <div ref={el => (formRefs.current[index] = el)}>
                            <Card.Section>
                              <div className="forms-information-section-card-title">{form.title}</div>
                              <Form>
                                <FormLayout>
                                  {getFormRowRange(form).map(row => {
                                    const rowFormInputs = form.inputs.filter(formInput => formInput.row === row);

                                    return (
                                      <FormLayout.Group key={row} alignStart>
                                        {rowFormInputs?.map(input => {
                                          const formDataInput = formsState.formsData?.[index]?.inputs?.find(
                                            formDataInput => formDataInput.formInputId === input.id,
                                          );

                                          if (input.type === "text") {
                                            return (
                                              <div key={input.id}>
                                                <div className="forms-information-section-text-input-title">
                                                  {input.label}
                                                </div>
                                                <div className="forms-information-section-text-input-content">
                                                  {input.help_text}
                                                </div>
                                              </div>
                                            );
                                          } else if (!formDataInput) {
                                            return null;
                                          } else if (input.type === "select") {
                                            return (
                                              <div key={input.id} className={input.required ? " required" : ""}>
                                                <Select
                                                  label={input.label}
                                                  onChange={(value: string) => {
                                                    if (value !== formDataInput.value) {
                                                      handleFormDataChange(index, {
                                                        ...formDataInput,
                                                        type: "select",
                                                        value,
                                                      });
                                                    }
                                                  }}
                                                  defaultValue={formDataInput.value as string}
                                                  error={!formDataInput.isValid && formDataInput.isDirty}
                                                  helpText={input.help_text}
                                                >
                                                  {Array.isArray(input.values) &&
                                                    input.values.map((value, index) => {
                                                      return (
                                                        <Option key={index} value={value} name={value}>
                                                          {value}
                                                        </Option>
                                                      );
                                                    })}
                                                </Select>
                                              </div>
                                            );
                                          } else if (input.type === "input") {
                                            return (
                                              <div key={input.id} className={input.required ? " required" : ""}>
                                                <Input
                                                  label={input.label}
                                                  value={formDataInput.value as string}
                                                  error={!formDataInput.isValid && formDataInput.isDirty}
                                                  onChange={(e: any) =>
                                                    handleFormDataChange(index, {
                                                      ...formDataInput,
                                                      type: "input",
                                                      value: e.target.value,
                                                    })
                                                  }
                                                  helpText={input.help_text}
                                                />
                                              </div>
                                            );
                                          } else if (input.type === "checkbox") {
                                            return (
                                              <div key={input.id} className={input.required ? " required" : ""}>
                                                <Checkbox
                                                  size="medium"
                                                  label={input.label}
                                                  checked={formDataInput.value as boolean}
                                                  error={!formDataInput.isValid && formDataInput.isDirty}
                                                  onChange={(e: any) =>
                                                    handleFormDataChange(index, {
                                                      ...formDataInput,
                                                      type: "checkbox",
                                                      value: e.target.checked,
                                                    })
                                                  }
                                                />
                                              </div>
                                            );
                                          }
                                        })}
                                      </FormLayout.Group>
                                    );
                                  })}
                                </FormLayout>
                              </Form>
                            </Card.Section>
                          </div>
                        </Card>
                      );
                    })}
                    <div className="forms-information-section-modify-forms-warning">
                      <Callout
                        type="warning"
                        title={t("guest.online_store.forms.007")}
                        content={t("guest.online_store.forms.008")}
                      />
                    </div>
                    <div className="forms-information-section-next-step-container">
                      <button className="forms-information-section-next-step" onClick={updateForms}>
                        {t("guest.online_store.forms.009")}
                      </button>
                    </div>
                  </section>
                  <section className="forms-order-summary-desktop-section">{renderOrderSummary(false)}</section>
                </div>
              )}
            </StorePage>
          </main>
        </>
      )}
    </div>
  );
}
