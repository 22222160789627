import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { StatusCode } from "api/protocols";
import { GetCustomer } from "api/rpc/facilityAdmin/customer";
import { GetLeague } from "api/rpc/2022-09/facilityAdmin/league/league";
import {
  GetParticipant,
  DeleteLeagueParticipant,
  PostParticipant,
  PutParticipant,
  PutExportParticipants,
  ImportLeaguePlayers,
  CopyLeagueParticipants,
} from "api/rpc/2024-04/facilityAdmin/league/participant";

import { showError, showSuccess } from "redux/actions/ui";
import { ILeague, ILeagueParticipant } from "redux/reducers/models/league";

import { useAppDispatch } from "hooks/redux";
import { valueToString } from "helpers/Helpers";

import Page from "components/page/Page";
import Card from "components/card/Card";
import { Badge } from "components/badge/Badge";
import Sheet from "components/sheet/Sheet";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import Tabs from "components/tabs/Tabs";
import Search from "components/search/Search";
import { Select } from "components/select/index";
import Icon from "components/icon/Icon";

import "./LeaguePlayers.scss";
import { searchHistoryUpdateSearch } from "redux/actions/searchBars/searchHistory";
import { ButtonNew as Button } from "components/buttonNew";
import { NavigationDropdownNew } from "components/navigationDropdownNew/NavigationDropdownNew";
import Callout from "components/callout/Callout";

interface ISortBy {
  /**Column to sort by. Default sorts the data by gross in ascending order */
  type: "default" | "registration_date";
  /**Ascending is true, Descending is false*/
  direction: boolean;
}

interface IMainState {
  leaguePlayers: ILeagueParticipant[];
  search: string;
  customers: IPlayer[];
  sortBy: ISortBy;
  league: any;
  playerQuery: string;
}

interface IPlayer {
  customer: IPlayerInfo;
  order_financial_status: string;
  created_at: string;
}

interface IPlayerInfo {
  full_name: string;
  email: string;
  phone: string;
}

interface INewPlayerState {
  first_name: string;
  last_name: string;
  email_address: string;
  phone_number: string;
  newPlayerSheetActive: boolean;
}

interface IImportState {
  modalVisible: boolean;
  selectedFile: File;
}

interface ICopyPlayerState {
  showCopyPlayers: boolean;
  selectedLeague: any;
  leagueSearch: string;
  leagues: Array<ILeague>;
  leagueSearching: boolean;
}

export default function LeaguePlayers(props: any) {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const { leagueId }: any = useParams();
  const fileUploadRef = useRef(null);
  const { Option } = Select;

  const [state, setState] = useState<IMainState>({
    leaguePlayers: [],
    search: undefined,
    customers: [],
    sortBy: { type: "default", direction: true },
    league: null,
    playerQuery: "",
  });

  const [newPlayerState, setNewPlayerState] = useState<INewPlayerState>({
    first_name: "",
    last_name: "",
    email_address: "",
    phone_number: "",
    newPlayerSheetActive: false,
  });

  const [importState, setImportState] = useState<IImportState>({
    modalVisible: false,
    selectedFile: null,
  });

  const [copyPlayerState, setCopyPlayerState] = useState<ICopyPlayerState>({
    showCopyPlayers: false,
    selectedLeague: null,
    leagueSearch: "",
    leagues: [],
    leagueSearching: false,
  });

  const [leagues, setLeagues] = useState<Array<ILeague>>([]);
  const [selected, setSelected] = useState(0); // Handle tab change

  useEffect(() => {
    void loadParticipants();
    void loadLeague();
  }, []);

  useEffect(() => {
    // debounced due to <Search /> callback
    if (state.search !== undefined) {
      void loadCustomers(state.search);
    }
  }, [state.search]);

  async function loadParticipants() {
    const params = {
      league_id: leagueId,
    };

    const leaguePlayersRes = await GetParticipant(params, true);

    if (leaguePlayersRes.status === StatusCode.OK) {
      setState(prevState => ({
        ...prevState,
        leaguePlayers: leaguePlayersRes.data,
      }));
    }
  }

  async function loadLeague() {
    const leagueRes = await GetLeague(
      {
        id: leagueId,
      },
      true,
    );

    if (leagueRes.status === StatusCode.OK) {
      setState(prevState => ({
        ...prevState,
        league: leagueRes.data[0],
      }));
    }
  }

  function createPlayerInputChange(e: any) {
    const id = e.target.id;
    const value = e.target.value;

    setNewPlayerState(prev => ({ ...prev, [id]: value }));
  }

  function closeNewPlayerSheet() {
    setNewPlayerState(prev => ({
      ...prev,
      newPlayerSheetActive: false,
      first_name: "",
      last_name: "",
      email_address: "",
      phone_number: "",
    }));

    setState(prevState => ({ ...prevState, search: "" }));
    dispatch(searchHistoryUpdateSearch("add_league_participant", ""));

    setSelected(0);
  }

  async function createPlayer() {
    const params = {
      league_id: Number(leagueId),
      first_name: newPlayerState.first_name,
      last_name: newPlayerState.last_name,
      email: newPlayerState.email_address,
      phone_number: newPlayerState.phone_number,
    };

    const addPlayerToLeagueRes = await PostParticipant(params, true);
    if (addPlayerToLeagueRes.status === StatusCode.OK) {
      void closeNewPlayerSheet();
      void loadParticipants();
    }
  }

  async function loadCustomers(searchQuery: string) {
    if (searchQuery !== undefined) {
      if (searchQuery !== "") {
        const customerRes = await GetCustomer({ search: searchQuery }, true);
        if (customerRes.status !== StatusCode.OK) {
          return;
        }

        setState(prev => ({ ...prev, customers: customerRes.data }));
      } else {
        setState(prev => ({ ...prev, customers: [] }));
      }
    }
  }

  async function updatePlayerFinancialStatus(event: React.MouseEvent<HTMLElement, MouseEvent>, player: any) {
    event.stopPropagation();

    const wantedStatus = player.order_financial_status == "paid" || null ? "unpaid" : "paid";

    const params = {
      id: player.id,
      order_financial_status: wantedStatus,
    };

    // Update player within the League
    const updatePlayerInLeagueRes = await PutParticipant(params, true);
    if (updatePlayerInLeagueRes.status !== StatusCode.OK) {
      return;
    }

    void loadParticipants(); // refresh the table
  }

  async function deleteLeagueParticipant(participant_id: number) {
    const deleteParticipantRes = await DeleteLeagueParticipant({ participant_id: participant_id }, true);

    if (deleteParticipantRes.status === StatusCode.OK) {
      dispatch(showSuccess("Successfully deleted the participant."));
      void loadParticipants(); // refresh the table
    } else {
      dispatch(showError("Something went wrong deleting the league participant."));
    }
  }

  const tabs = [
    {
      id: "search-player",
      content: t("secure.facility.league.players.002"),
    },
    {
      id: "add-player",
      content: t("secure.facility.league.players.003"),
    },
  ];

  async function addNewPlayer(customer: any) {
    const params = {
      league_id: leagueId,
      customer_id: Number(customer.id),
    };

    console.log(params);

    const addPlayerToLeagueRes = await PostParticipant(params, true);
    if (addPlayerToLeagueRes.status === StatusCode.OK) {
      closeNewPlayerSheet();
      void loadParticipants();
    }
  }

  function sortByDefault() {
    const players = [...state.leaguePlayers];
    const sortedPlayers = players?.sort((currentPlayer, nextPlayer) => {
      if (!currentPlayer?.id) {
        return 1;
      } else if (!nextPlayer?.id) {
        return -1;
      } else {
        return currentPlayer?.id - nextPlayer?.id;
      }
    });
    setState(prevState => ({
      ...prevState,
      leaguePlayers: sortedPlayers,
      sortBy: { type: "default", direction: true },
    }));
  }

  function sortLeaguePlayers(sortType: "default" | "registration_date", dateValue: "created_at" | "updated_at") {
    const players = [...state.leaguePlayers];
    let direction: boolean = state?.sortBy?.direction;
    if (state?.sortBy?.type !== sortType) {
      direction = true;
    } else if (direction === false) {
      //Descending order, return to default order
      void sortByDefault();
      return;
    } else {
      direction = !direction;
    }

    const sortedPlayers = players.sort((currentPlayer, nextPlayer) => {
      const currentDate = new Date(currentPlayer?.[dateValue as keyof typeof currentPlayer] as unknown as Date);
      const nextDate = new Date(nextPlayer?.[dateValue as keyof typeof nextPlayer] as unknown as Date);

      // Sort null values last
      if (!currentPlayer?.[dateValue as keyof typeof currentPlayer]) {
        return 1;
      } else if (!nextPlayer?.[dateValue as keyof typeof nextPlayer] === null) {
        return -1;
      } else if (sortType === "registration_date") {
        return direction ? nextDate.valueOf() - currentDate.valueOf() : currentDate.valueOf() - nextDate.valueOf();
      }

      return 1;
    });

    setState(prevState => ({ ...prevState, leaguePlayers: sortedPlayers, sortBy: { type: sortType, direction } }));
  }

  async function exportLeaguePlayers() {
    const exportRes = await PutExportParticipants({ league_id: leagueId, type: "csv" }, true);

    if (exportRes.status !== StatusCode.OK) {
      dispatch(showError("Error exporting players"));
      return;
    }

    window.open(exportRes?.data, "_blank").focus();
  }

  const primaryAction = {
    content: t("secure.facility.league.players.001"),
    action: () => setNewPlayerState(prev => ({ ...prev, newPlayerSheetActive: true })),
  };

  const secondaryAction = {
    content: "Export",
    action: exportLeaguePlayers,
  };

  function handleFileSelect(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files;
    if (file && file.length > 0) {
      setImportState(prevState => ({ ...prevState, selectedFile: file[0] }));
    }
  }

  async function importLeaguePlayers() {
    const formData = new FormData();

    formData.append("file", importState.selectedFile);
    formData.append("league_id", leagueId);

    const importRes = await ImportLeaguePlayers(formData, true);

    if (importRes.status !== StatusCode.OK) {
      dispatch(showError("Error importing league players"));
    }

    fileUploadRef.current.value = "";
    setImportState(prevState => ({ ...prevState, selectedFile: null, modalVisible: false }));
    void loadParticipants();
  }

  async function copyPlayer() {
    const params = {
      to_league_id: copyPlayerState.selectedLeague.id,
      from_league_id: leagueId,
    };

    const copyRes = await CopyLeagueParticipants(params, true);

    if (copyRes.status !== StatusCode.OK) {
      dispatch(showError("Error copying players"));
      return;
    }

    dispatch(showSuccess("Successfully copied players"));

    setCopyPlayerState(prevState => ({ ...prevState, showCopyPlayers: false, selectedLeague: null }));
  }

  function displayLeagues() {
    if (copyPlayerState?.leagues && copyPlayerState?.leagues?.length > 0 && copyPlayerState?.leagueSearch !== "") {
      return copyPlayerState?.leagues;
    } else {
      return leagues;
    }
  }

  function displayPlayers() {
    if (state?.playerQuery !== "") {
      return state.leaguePlayers?.filter(player =>
        player.customer.full_name.toLowerCase().includes(state.playerQuery.toLowerCase()),
      );
    } else {
      return state.leaguePlayers;
    }
  }

  async function loadSearchedLeagues(search: string) {
    const params = {
      search: search,
    };

    const leagueRes = await GetLeague(params, false);

    if (leagueRes.status !== StatusCode.OK) {
      dispatch(showError(leagueRes.message));

      return [];
    }

    return leagueRes.data;
  }

  function handleLeagueChange(value: any, id: string, league: ILeague) {
    setCopyPlayerState(prevState => ({ ...prevState, leagueSearch: "", selectedLeague: league }));
  }

  function removeSelectedLeague() {
    setCopyPlayerState(prevState => ({
      ...prevState,
      selectedLeague: null,
      leagues: [],
      leagueSearching: false,
    }));
  }

  //Load Leagues
  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (async () => {
          if (copyPlayerState.leagueSearch !== "") {
            try {
              setCopyPlayerState(prevState => ({ ...prevState, leagueSearching: true }));
              const leagues = await loadSearchedLeagues(copyPlayerState.leagueSearch);
              if (mounted) {
                setCopyPlayerState(prevState => ({ ...prevState, leagues: leagues, leagueSearching: false }));
              }
            } catch (error) {
              console.log("err", error);
            }
            return;
          } else {
            setCopyPlayerState(prevState => ({ ...prevState, leagues: [], leagueSearching: false }));
          }
        })();
      }, 1000);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [copyPlayerState.leagueSearch]);

  function handleViewOrderClick(orderId: number) {
    console.log("View order");
    window.open("/admin/order/" + String(orderId));
  }

  return (
    <Page
      title={t("secure.facility.league.players.004")}
      primaryAction={primaryAction}
      // secondaryActions={[secondaryAction]}
      multipleActionDropdownAction={{
        label: "Options",
        dropdownProps: {
          alignment: "right",
          options: [
            {
              type: "handler",
              label: t("secure.facility.league.players.001"),
              handler: () => setNewPlayerState(prev => ({ ...prev, newPlayerSheetActive: true })),
            },
            {
              type: "handler",
              label: "Import",
              handler: () => setImportState(prevState => ({ ...prevState, modalVisible: true })),
            },
            {
              type: "handler",
              label: "Export",
              handler: exportLeaguePlayers,
            },
            {
              type: "handler",
              label: "Download Template",
              handler: () => {},
            },
            {
              type: "handler",
              label: "Copy Players",
              handler: () => setCopyPlayerState(prevState => ({ ...prevState, showCopyPlayers: true })),
            },
          ],
        },
      }}
    >
      <Card>
        <Card.Section>
          <div className="flex">
            <div className="player-count-container">
              <p className="player-counts">{t("secure.facility.league.players.018")}</p>
              <p className="player-counts">{state.leaguePlayers?.length}</p>
            </div>

            <div className="player-count-container">
              <p className="player-counts">{t("secure.facility.league.players.019")} </p>
              <p className="player-counts">{state.league?.total_paid_participant_count} </p>
            </div>
          </div>
        </Card.Section>

        <Card.Section>
          <div className="mb-2">
            <Search
              placeholder="Search League Players..." // TODO: Translation
              searchCallback={searchValue => setState(prevState => ({ ...prevState, playerQuery: searchValue }))}
              historyKey="league-players-player-query"
            />
          </div>
          <table className="ui-table league-players">
            <thead>
              <tr>
                <th>{t("secure.facility.league.players.005")}</th>
                <th>{t("secure.facility.league.players.006")}</th>
                <th>{t("secure.facility.league.players.007")}</th>
                <th>{"Player Type"}</th>

                <th>{t("secure.facility.league.players.008")}</th>
                <th>
                  <p onClick={() => sortLeaguePlayers("registration_date", "created_at")} className="players-header">
                    {t("secure.facility.league.players.009")}{" "}
                    <FontAwesomeIcon
                      style={{ visibility: state?.sortBy?.type === "registration_date" ? "visible" : "hidden" }}
                      icon={["far", state?.sortBy?.direction ? "arrow-down-long" : "arrow-up-long"]}
                    />
                  </p>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {displayPlayers().map((player, index: number) => {
                return (
                  <tr key={player.id}>
                    <td>{player.customer.full_name}</td>
                    <td>{player.customer.email}</td>
                    <td>{player.customer.phone}</td>
                    <td>{player.customer.customer_type}</td>

                    <td>
                      {player.order_financial_status ? (
                        <>
                          {player.order_financial_status === "paid" ? (
                            <span style={{ cursor: "pointer" }}>
                              <Badge
                                type="success"
                                size="medium"
                                iconLeft
                                icon="check"
                                iconStyle="fas"
                                onClick={e => updatePlayerFinancialStatus(e, player)}
                              >
                                {valueToString(player.order_financial_status, "capitalize")}
                              </Badge>
                            </span>
                          ) : (
                            <span style={{ cursor: "pointer" }}>
                              <Badge
                                type="gray"
                                size="medium"
                                iconLeft
                                icon="circle"
                                iconStyle="fas"
                                onClick={e => updatePlayerFinancialStatus(e, player)}
                              >
                                {valueToString(player.order_financial_status, "capitalize")}
                              </Badge>
                            </span>
                          )}
                        </>
                      ) : (
                        <span style={{ cursor: "pointer" }}>
                          <Badge
                            type="gray"
                            size="medium"
                            iconLeft
                            icon="circle"
                            iconStyle="fas"
                            onClick={e => updatePlayerFinancialStatus(e, player)}
                          >
                            {t("secure.facility.league.players.020")}
                          </Badge>
                        </span>
                      )}
                    </td>

                    <td>
                      <span>{moment.utc(player.created_at).local().format("MMM DD, YYYY")}</span>
                    </td>
                    <td>
                      <NavigationDropdownNew
                        showPlainTextLabel
                        rightAlign
                        label={<FontAwesomeIcon icon={["far", "chevron-down"]} />}
                        sections={[
                          [
                            {
                              icon: "ban",
                              label: "Remove",
                              onClick: () => deleteLeagueParticipant(player.id),
                            },
                            {
                              label: "View Order",
                              icon: "magnifying-glass",
                              onClick: () => handleViewOrderClick(player.order_id),
                              disabled: player.order_id === null,
                            },
                          ],
                        ]}
                      />

                      {/* <MultipleActionDropdown
                        alignment={"right"}
                        options={[
                          {
                            type: "handler",
                            label: "Remove",
                            handler: () => deleteLeagueParticipant(player.id),
                          },
                        ]}
                      >
                        <Button size="medium" type="secondary">
                          {"Options"}
                        </Button>
                      </MultipleActionDropdown> */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Card.Section>
      </Card>

      <Sheet
        open={newPlayerState.newPlayerSheetActive}
        size="small"
        closable
        stacked
        title={t("secure.facility.league.players.010")}
        onCancel={closeNewPlayerSheet}
        onOk={createPlayer}
        cancelText={t("secure.facility.league.players.011")}
        okText={t("secure.facility.league.players.012")}
        okDisabled={
          newPlayerState.first_name === "" ||
          newPlayerState.last_name === "" ||
          (newPlayerState.email_address === "" && newPlayerState.phone_number === "")
        }
      >
        <Tabs tabs={tabs} selected={selected} onSelect={selectedTabIndex => setSelected(selectedTabIndex)}>
          {selected === 0 ? (
            <>
              <div className="flex-grow mb-4 mt-2">
                <Search
                  historyKey={"add_league_participant"}
                  searchCallback={searchValue => setState(prev => ({ ...prev, search: searchValue }))}
                  placeholder={t("secure.facility.league.players.013")}
                />
              </div>

              <div className="flex-grow mb-4">
                <div>
                  {state.customers?.map((customer: any, index: number) => {
                    return (
                      <div
                        key={index}
                        className="flex justify-between league-customer-results"
                        onClick={() => addNewPlayer(customer)}
                      >
                        <div>
                          <div className="text-lg text-semibold">{customer?.full_name}</div>
                          <div className="text-sm text-gray-500">{customer.email}</div>
                          <div className="text-sm text-gray-500">{customer.phone ? customer.phone : null}</div>
                          <div className="text-sm text-gray-500">
                            {customer.customer_type ? customer.customer_type : null}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          ) : null}

          {selected === 1 ? (
            <FormLayout>
              <FormLayout.Group>
                <Input
                  id="first_name"
                  label={t("secure.facility.league.players.014")}
                  value={newPlayerState.first_name}
                  onChange={createPlayerInputChange}
                />
                <Input
                  id="last_name"
                  label={t("secure.facility.league.players.015")}
                  value={newPlayerState.last_name}
                  onChange={createPlayerInputChange}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Input
                  id="email_address"
                  label={t("secure.facility.league.players.016")}
                  value={newPlayerState.email_address}
                  onChange={createPlayerInputChange}
                />
                <Input
                  label={t("secure.facility.league.players.017")}
                  id="phone_number"
                  value={newPlayerState.phone_number}
                  onChange={createPlayerInputChange}
                />
              </FormLayout.Group>
            </FormLayout>
          ) : null}
        </Tabs>
      </Sheet>

      <Sheet
        title="Select File"
        open={importState.modalVisible}
        size="small"
        closable
        onCancel={() => setImportState(prevState => ({ ...prevState, modalVisible: false }))}
        onOk={importLeaguePlayers}
        okText="Import"
        okDisabled={!importState.selectedFile ? true : false}
      >
        <Callout
          type="info"
          title={!importState.selectedFile ? "No file selected" : "Selected File"}
          content={!importState.selectedFile ? "Select a file to continue" : importState.selectedFile.name}
        />

        <div className="customer-import-container mt-2">
          <input
            ref={fileUploadRef}
            className="customer-import-input"
            type="file"
            accept=".csv"
            onChange={handleFileSelect}
          />
          <FontAwesomeIcon className="customer-import-icon" icon={["far", "upload"]} />
        </div>
      </Sheet>

      <Sheet
        open={copyPlayerState.showCopyPlayers}
        size="small"
        closable
        stacked
        title={"Select League"}
        onCancel={() => setCopyPlayerState(prevState => ({ ...prevState, showCopyPlayers: false, league: null }))}
        onOk={copyPlayer}
        cancelText={"Cancel"}
        okText={"Copy"}
        okDisabled={false}
      >
        <div style={{ height: "250px" }}>
          {copyPlayerState.selectedLeague ? (
            <div>
              <div>
                <p className="event-label">Leagues</p>
              </div>

              <div className="selected-container">
                <div className="event-name">
                  <div>{copyPlayerState.selectedLeague.name}</div>

                  <div>
                    <button className="" onClick={removeSelectedLeague}>
                      <Icon style="far" icon="times" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <Select
                showSearch
                label={"Leagues"}
                onSearch={(query: string) => setCopyPlayerState(prevState => ({ ...prevState, leagueSearch: query }))}
                onChange={(value: any, league: ILeague) => handleLeagueChange(value, "selectedleague", league)}
                placeholder={"Search leagues..."}
                allowClear
                searchValue={copyPlayerState.leagueSearch}
                showDropDownOnFocus={true}
                searching={copyPlayerState.leagueSearching}
              >
                {displayLeagues()?.map((league: any, index: number) => {
                  return (
                    <Option value={league?.id} key={index} extraValues={league}>
                      <div className="text-semibold text-lg">{league.name}</div>
                    </Option>
                  );
                })}
              </Select>
            </div>
          )}
        </div>
      </Sheet>
    </Page>
  );
}
