import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./UpdatesPage.scss";
import { GetVersionUpdates, IVersion } from "api/rpc/version";
import { StatusCode } from "api/protocols";

interface IUpdatesPageState {
  versions: IVersion[];
}

export default function UpdatesPage(props: any) {
  const [updatesPageState, setUpdatesPageState] = useState<IUpdatesPageState>({
    versions: [],
  });

  useEffect(() => {
    void loadVersionUpdates();
  }, []);

  async function loadVersionUpdates() {
    const getVersionUpdatesResponse = await GetVersionUpdates(true);

    if (getVersionUpdatesResponse.status !== StatusCode.OK) {
      return;
    }

    setUpdatesPageState(prev => ({ ...prev, versions: getVersionUpdatesResponse.data }));
  }

  function displayFormattedDate(dateString: string) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-ca", {
      month: "short",
      day: "numeric",
      year: "numeric",
      timeZone: "UTC",
    });
    return formattedDate;
  }

  return (
    <div className="updates-page">
      <img className="updates-page-tee-on-logo" src="../../../../public/images/Tee-On.png"></img>
      <h1 className="updates-page-title">Releases</h1>
      <div className="updates-page-versions">
        {updatesPageState.versions.map((version, index) => {
          return (
            <React.Fragment key={`${index} fragment`}>
              <div key={`${index} version`} className="updates-page-version">
                <div className="updates-page-version-title-section">
                  <div className="updates-page-version-title-container">
                    <h2 className="updates-page-version-title">{`Version ${version.version_number}`}</h2>
                    <div className="updates-page-version-date">{`Released ${displayFormattedDate(
                      version.date_released,
                    )}`}</div>
                  </div>
                </div>
                <div className="updates-page-version-updates-section">
                  <div className="updates-page-version-updates">
                    <div className="updates-page-version-updates-version-title-container">
                      <h2 className="updates-page-version-title">{`Version ${version.version_number}`}</h2>
                      <div className="updates-page-version-date">{`Released ${version.date_released}`}</div>
                    </div>
                    {version.starred_primary_updates?.map((starred_primary_update, index) => {
                      return (
                        <div
                          key={`starred_primary_update ${index}`}
                          className="updates-page-version-updates-primary-container"
                        >
                          <div className="updates-page-version-updates-primary-icon">
                            <FontAwesomeIcon icon={["fas", "star"]} />
                          </div>
                          <div className="updates-page-version-updates-primary-title">
                            {starred_primary_update.title}
                          </div>
                          {starred_primary_update.content && (
                            <>
                              <div className="updates-page-version-updates-primary-title"> &#8226; </div>
                              <div className="updates-page-version-updates-primary-content">
                                {starred_primary_update.content}
                              </div>
                            </>
                          )}
                        </div>
                      );
                    })}
                    {version.primary_updates?.map((primary_update, index) => {
                      return (
                        <div key={`primary_update ${index}`} className="updates-page-version-updates-primary-container">
                          <div className="updates-page-version-updates-primary-title">{primary_update.title}</div>
                          {primary_update.content && (
                            <>
                              <div className="updates-page-version-updates-primary-title"> &#8226; </div>
                              <div className="updates-page-version-updates-primary-content">
                                {primary_update.content}
                              </div>
                            </>
                          )}
                        </div>
                      );
                    })}
                    {version.whats_new?.length > 0 && (
                      <div>
                        <h3 className="updates-page-version-updates-secondary-title">New Features</h3>
                        <ul className="updates-page-version-updates-secondary-list">
                          {version.whats_new.map((improvement, index) => {
                            return <li key={`whats_improved ${version.version_number} ${index}`}>{improvement}</li>;
                          })}
                        </ul>
                      </div>
                    )}
                    {version.whats_improved?.length > 0 && (
                      <div>
                        <h3 className="updates-page-version-updates-secondary-title">Improvements</h3>
                        <ul className="updates-page-version-updates-secondary-list">
                          {version.whats_improved.map((improvement, index) => {
                            return <li key={`whats_improved ${version.version_number} ${index}`}>{improvement}</li>;
                          })}
                        </ul>
                      </div>
                    )}
                    {version.whats_changed?.length > 0 && (
                      <div>
                        <h3 className="updates-page-version-updates-secondary-title">Changes</h3>
                        <ul className="updates-page-version-updates-secondary-list">
                          {version.whats_changed.map((change, index) => {
                            return <li key={`whats_changed ${version.version_number} ${index}`}>{change}</li>;
                          })}
                        </ul>
                      </div>
                    )}
                    {version.whats_fixed?.length > 0 && (
                      <div>
                        <h3 className="updates-page-version-updates-secondary-title">Bug Fixes</h3>
                        <ul className="updates-page-version-updates-secondary-list">
                          {version.whats_fixed.map((fix, index) => {
                            return <li key={`whats_fixed ${version.version_number} ${index}`}>{fix}</li>;
                          })}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {index < updatesPageState.versions.length - 1 && <hr key={`${index} horizontal line`} />}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}
