import { combineReducers } from "redux";
import UIReducer from "./ui";
import AuthReducer from "./auth";
import TeeSheetReducer from "./teesheet";
import CartReducer from "./cart";
import OrderReducer from "./order";
import CustomerReducer from "./customer";
import LeagueReducer from "./league";
import TournamentReducer from "./tournament";
import FacilityReducer from "./facility";
import ClientReducer from "./client";
import TerminalReducer from "./facilityAdmin/terminal";
import BookingEngineReducer from "./bookingEngine";
import ClientTeeSheetReducer from "./clientAdmin/teesheet";
import ClientFacilityReducer from "./clientAdmin/facility";
import CustomerCartReducer from "./customer/cart";
import OnlineStoreReducer from "./customer/onlineStore";
import ReservationsReducer from "./reservations";
import SearchHistoryReducer from "./searchBars/searchHistory";
import FilterHistoryReducer from "./filters/filterHistory";
import TeeSheetProductReducer from "./facilityAdmin/teeSheetProducts";
import MasterClientReducer from "./masterAdmin/client";
import MasterFacilityReducer from "./masterAdmin/facility";
import MasterTeeSheetReducer from "./masterAdmin/teesheet";
import ClientPortalReducer from "./clientPortal";
import { DBFilter, createFilterReducer } from "./filters/filters";
import TournamentRegistrationReducer from "./customer/tournamentRegistration";
import LeagueRegistrationReducer from "./customer/leagueRegistration";

export default combineReducers({
  uiStore: UIReducer,
  authStore: AuthReducer,
  teeSheetStore: TeeSheetReducer,
  cartStore: CartReducer,
  orderStore: OrderReducer,
  customerStore: CustomerReducer,
  leagueStore: LeagueReducer,
  tournamentStore: TournamentReducer,
  facilityStore: FacilityReducer,
  clientStore: ClientReducer,
  terminalStore: TerminalReducer,
  bookingEngineStore: BookingEngineReducer,
  //Client Admin
  clientTeeSheetStore: ClientTeeSheetReducer,
  clientFacilityStore: ClientFacilityReducer,
  //Master Admin
  masterClientStore: MasterClientReducer,
  masterFacilityStore: MasterFacilityReducer,
  masterTeeSheetStore: MasterTeeSheetReducer,
  //Customer
  customerCartStore: CustomerCartReducer,
  onlineStoreStore: OnlineStoreReducer,
  reservationStore: ReservationsReducer,
  searchHistoryStore: SearchHistoryReducer,
  filterHistoryStore: FilterHistoryReducer,
  teeSheetProductStore: TeeSheetProductReducer,
  clientPortalStore: ClientPortalReducer,
  tournamentRegistrationStore: TournamentRegistrationReducer,
  leagueRegistrationStore: LeagueRegistrationReducer,

  // APPLICATION FILTER collections
  orderPageFilters: createFilterReducer<DBFilter[]>("orders"),
  productPageFilters: createFilterReducer<DBFilter[]>("products"),
  membershipPageFilters: createFilterReducer<DBFilter[]>("memberships"),
});
