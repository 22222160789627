import React from "react";

import { useHistory } from "react-router-dom";
import { valueToString } from "helpers/Helpers";
import classNames from "classnames";

import BaseReportTable from "./BaseReportTable";

import "components/reportTable/reportTable.scss";

interface IProps {
  data: Array<Record<string, any>>;
  columns: Array<Record<string, any>>;
  description: string;
  compileDate: string;
}

export default function AgedAccountsReceivableTable(props: IProps) {
  const history = useHistory();

  function formatDisplay(type: string, value: string) {
    let newValue = value !== null && value !== undefined ? value : "";

    if (!isNaN(Number(newValue)) && newValue !== "") {
      switch (type) {
        case "currency":
          newValue = new Intl.NumberFormat("en-CA", { style: "currency", currency: "CAD" }).format(Number(value));
          break;
        case "decimal":
          newValue = Number(value).toFixed(2);
          break;
        case "percentage":
          newValue = `${Number(value) * 100}%`;
          break;
        default:
          break;
      }
    } else if (type === "string" && newValue !== "") {
      newValue = valueToString(value).replace(/(^\w|\s\w)/g, c => c.toUpperCase());
    }

    return newValue;
  }

  // Format cell data based on column
  function cellData(columnId: string, cellValue: string, displayType: string) {
    return formatDisplay(displayType, cellValue);
  }

  // Add on click events based on column
  function cellOnClick(cell: any) {
    if (!cell) {
      return null;
    }
    switch (cell.column.id) {
      case "title":
        return cell.row.original.id
          ? history.push(`/admin/settings/accounts/overview/${String(cell.row.original.id)}`)
          : null;
      case "customer":
        return cell.row.original.customer_id
          ? history.push(`/admin/customers/${String(cell.row.original.customer_id)}/profile`)
          : null;
      default:
        return null;
    }
  }

  function cellClassNames(cell: any, isRowLocked: boolean) {
    return classNames({
      "report-table-row-locked": isRowLocked,
      "report-table-cell-clickable": cell.column.id === "title" || cell.column.id === "customer",
    });
  }

  // Add footer data based on column
  function footerData(columnId: string) {
    switch (columnId) {
      case "facility":
        return getRowCount();
      default:
        return null;
    }
  }

  function getTotal(columnId: string, type: string): number | string {
    let total = 0;
    props.data.forEach(row => {
      if (row[columnId]) {
        total += Number(row[columnId]);
      }
    });
    if (type === "currency") {
      return new Intl.NumberFormat("en-CA", { style: "currency", currency: "CAD" }).format(total);
    } else {
      return total;
    }
  }

  function getRowCount() {
    return `Count: ${props.data.length}`;
  }

  return (
    <BaseReportTable
      data={props.data}
      columns={props.columns}
      description={props.description}
      compileDate={props.compileDate}
      cellOnClick={cellOnClick}
      cellData={cellData}
      cellClassNames={cellClassNames}
      footerData={footerData}
      expanding={false}
    />
  );
}
