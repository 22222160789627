import { APIGet, IAPIResponse } from "api/protocols";

const apiVersion = "2021-01";
const adminType = "common";
const baseVersionUrl = "/" + apiVersion + "/" + adminType + "/version";

export interface IPrimaryUpdate {
  title: string;
  content?: string;
}

export interface IVersion {
  version_number: string;
  date_released: string;
  starred_primary_updates?: IPrimaryUpdate[];
  primary_updates?: IPrimaryUpdate[];
  whats_new?: string[];
  whats_improved?: string[];
  whats_changed?: string[];
  whats_fixed?: string[];
}

interface IGetVersionUpdatesResponse extends IAPIResponse {
  data: IVersion[];
}

export async function GetVersionUpdates(useGlobalLoader: boolean): Promise<IGetVersionUpdatesResponse> {
  return await APIGet(baseVersionUrl + "/update", {}, useGlobalLoader);
}
