import Sheet from "components/sheet/Sheet";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonNew as Button } from "components/buttonNew";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isNumeric } from "helpers/Helpers";
import Input from "components/form/input/Input";

interface IChangePriceProps {
  priceSheetVisible: boolean;
  selectedLineItem: any;
  onCancel?: () => void;
  onOk?: (priceChangeInfo: IItemActionsState) => void;
}

interface IItemActionsState {
  price: string;
}

function PriceSheet(props: IChangePriceProps) {
  const { t, i18n } = useTranslation();

  const [itemActionsState, setItemActionsState] = useState<IItemActionsState>({
    price: "",
  });

  useEffect(() => {
    if (props.selectedLineItem && props.priceSheetVisible) {
      setItemActionsState(prevState => ({
        ...prevState,
        price: props.selectedLineItem?.price,
      }));
    }
  }, [props.selectedLineItem, props.priceSheetVisible]);

  function onOk() {
    void props.onOk({ ...itemActionsState });
  }

  function onCancel() {
    setItemActionsState(prevState => ({ ...prevState, price: "" }));
    props.onCancel();
  }

  function handleLineItemInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setItemActionsState(prevState => ({ ...prevState, [id]: value }));
  }

  return (
    <Sheet
      closable
      size="small"
      open={props.priceSheetVisible}
      title={t("components.price_sheet.price_sheet.001")}
      onCancel={onCancel}
      onOk={onOk}
      okText={t("components.price_sheet.price_sheet.002")}
      cancelText={t("components.price_sheet.price_sheet.003")}
      okDisabled={!props.selectedLineItem?.price || !isNumeric(props.selectedLineItem?.price) ? true : false}
    >
      <div className="flex flex-col mb-4">
        <span className="text-lg">{props?.selectedLineItem?.product_title}</span>
        {props?.selectedLineItem?.product_title !== props?.selectedLineItem?.variant_title && (
          <span className="text-sm text-gray-300">{props?.selectedLineItem?.variant_title}</span>
        )}
      </div>
      <Input
        prefix="$"
        label={t("components.price_sheet.price_sheet.004")}
        placeholder={t("components.price_sheet.price_sheet.005")}
        value={itemActionsState.price || ""}
        id="price"
        onChange={handleLineItemInputChange}
        type="number"
        disabled={props.selectedLineItem?.product?.price_locked}
      />
    </Sheet>
  );
}

export default PriceSheet;
