import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { TTeesheetPricingSheet } from "redux/reducers/models/teesheet";

const apiVersion = "2022-09";
const adminType = "client-admin";
const basePricingSheetUrl = "/" + apiVersion + "/" + adminType + "/tee-sheet/pricing-sheet";
// const basePricingSheetProductUrl = "/" + apiVersion + "/" + adminType + "/tee-sheet/pricing-sheet/product";
interface IPricingSheetProductRes {
  data: any;
  status: number;
}

interface IGetPricingSheet {
  id?: string;
  facility_id: number;
  day_of_week_number?: number;
  extended?: boolean;
}

interface IPostPricingSheet {
  title: string;
  day_of_week: string;
  start_time: string;
  end_time: string;
  course_id: number;
  application: string;
  facility_id: number;
}

interface IPricingSheetRes {
  status: number;
  data: any;
}

interface IDuplicatePricingSheet {
  facility_id: number;
  day_of_week?: string;
  pricing_sheet_ids: Array<number>;
}

interface IPostPricingSheetProduct {
  facility_id: number;
  pricing_sheet_id: number;
  variant_ids: Array<number>;
}

interface IDeletePricingSheetProduct {
  pricing_sheet_id: number;
  variant_id: Array<number>;
}

interface IPutPricingSheet {
  id: number;
  title: string;
  start_time: string;
  end_time: string;
  day_of_week: string;
}

export type TTeeSheetApplication = "green_fee" | "power_cart" | "no_show";

export async function GetPricingSheet(params: IGetPricingSheet, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<TTeesheetPricingSheet[]>(basePricingSheetUrl, params, useGlobalLoader, token);
}

export async function PostPricingSheet(params: IPostPricingSheet, useGlobalLoader: boolean): Promise<IPricingSheetRes> {
  return await APIPost(basePricingSheetUrl, params, useGlobalLoader);
}

export async function PutPricingSheet(params: IPutPricingSheet, useGlobalLoader: boolean): Promise<IPricingSheetRes> {
  return await APIPut(basePricingSheetUrl, params, useGlobalLoader);
}

export async function DeletePricingSheet(params: { id: number }, useGlobalLoader: boolean): Promise<IPricingSheetRes> {
  return await APIDelete(basePricingSheetUrl, params, useGlobalLoader);
}

export async function PostPricingSheetProduct(
  params: IPostPricingSheetProduct,
  useGlobalLoader: boolean,
): Promise<IPricingSheetRes> {
  return await APIPost(basePricingSheetUrl + "/product", params, useGlobalLoader);
}

export async function PostDuplicatePricingSheet(
  params: IDuplicatePricingSheet,
  useGlobalLoader: boolean,
): Promise<IPricingSheetRes> {
  return await APIPost(basePricingSheetUrl + "/duplicate", params, useGlobalLoader);
}

export async function DeletePricingSheetProduct(
  params: IDeletePricingSheetProduct,
  useGlobalLoader: boolean,
): Promise<IPricingSheetProductRes> {
  return await APIDelete(basePricingSheetUrl + "/product", params, useGlobalLoader);
}
