import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { ButtonNew as Button } from "components/buttonNew";
import "components/popup/popup.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Title } from "components/typography";
import FeatureIcon from "components/icon/FeatureIcon";
import Icon from "components/icon/Icon";
import Spin from "components/spin/spin";

interface IPopupProps {
  open: boolean;
  type: "success" | "warning" | "error" | "info" | "delete";
  title: string;
  description: string;
  aligned?: "left" | "center";
  stacked?: boolean;
  closable?: boolean;
  onCancel?: any;
  onOk?: any;
  cancelText?: string;
  okText?: string;
  okDisabled?: boolean;
  cancelDisabled?: boolean;
  backDropCancel?: boolean;
  children?: any;
  darkStyle?: boolean;
  loading?: boolean;
}

const Popup: React.FC<IPopupProps> = props => {
  const {
    type,
    title,
    description,
    closable,
    open = false,
    onCancel,
    onOk,
    cancelText = "Cancel",
    okText = "Ok",
    stacked,
    children,
    loading,
  } = props;

  const [visible, setVisible] = useState(open);

  useEffect(() => {
    let mounted = true;

    if (open) {
      if (mounted) {
        setVisible(true);
      }
    } else {
      if (mounted) {
        setVisible(false);
      }
    }

    return () => {
      mounted = false;
    };
  }, [open]);

  return (
    <div
      className={classNames({
        "ui-popup_container--closed": !visible,
      })}
    >
      <div className={classNames("ui-popup_container", { "ui-popup_container-stacked": stacked })}>
        <div className="ui-popup">
          {props.onCancel ? (
            <div className="ui-popup-close-button">
              <Button type="close" size="large" onClick={props.onCancel}>
                <Icon icon="times" style="far" size="medium" />
              </Button>
            </div>
          ) : null}

          <div
            className={classNames("ui-popup-content", {
              [`ui-popup-content-${props.aligned}`]: props.aligned,
            })}
          >
            <div className="ui-popup-icon">
              {loading ? (
                <div style={{ marginTop: "10px", height: "48px", width: "48px", overflow: "hidden" }}>
                  <Spin />
                </div>
              ) : (
                <div>
                  {props.type === "success" ? (
                    <FeatureIcon type="success" theme="light-circle-outline" size="large" icon="check" />
                  ) : null}
                  {props.type === "info" ? (
                    <FeatureIcon type="gray" theme="light-circle-outline" size="large" icon="question-circle" />
                  ) : null}
                  {props.type === "error" ? (
                    <FeatureIcon type="error" theme="light-circle-outline" size="large" icon="exclamation-triangle" />
                  ) : null}
                  {props.type === "delete" ? (
                    <FeatureIcon type="error" theme="light-circle-outline" size="large" icon="trash" />
                  ) : null}
                  {props.type === "warning" ? (
                    <FeatureIcon type="warning" theme="light-circle-outline" size="large" icon="exclamation" />
                  ) : null}
                </div>
              )}
            </div>

            <p className="text-lg text-semibold">{props.title}</p>
            {props.description ? <p className="text-sm text-regular">{props.description}</p> : null}
            {props.children}
          </div>

          <div className="ui-popup-footer">
            {props.onCancel ? (
              <Button
                className="ui-popup-footer-close-button"
                type="default"
                block
                onClick={onCancel}
                disabled={props.cancelDisabled}
              >
                {cancelText}
              </Button>
            ) : null}
            <Button type={"primary"} block onClick={onOk} disabled={props.okDisabled}>
              {okText}
            </Button>
          </div>
        </div>
      </div>
      <div
        className={classNames("ui-backdrop", { "ui-backdrop-stacked": stacked })}
        onClick={props.backDropCancel !== undefined && props.backDropCancel === false ? undefined : onCancel}
      ></div>
    </div>
  );
};

export default Popup;
