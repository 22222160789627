import React, { useEffect, useState } from "react";
import axios, { CancelToken } from "axios";
import classNames from "classnames";

import { delay } from "helpers/Helpers";

import { ButtonNew } from "components/buttonNew";
import Icon from "components/icon/Icon";

import "./panel.scss";

type TPanelAction = {
  onClick: () => void;
  label: string;
  disabled?: boolean;
};

type TPanelPropsBase = {
  open: boolean;
  onClose: (e: React.MouseEvent<HTMLElement>) => void;
  /**
   * Element in top-left of the panel.  Justified with the close button along the top.
   */
  panelId?: string | JSX.Element;
  /** Opt-in animation of the sheet on open/close.  Starts/ends at the bottom of the page.
   * - typeof number: transition duration in seconds
   * - typeof boolean: defaults duration to 0.35s
   */
  animate?: boolean | number;
  /**
   * How much of the page the panel will overtake.
   * - Maximum of 100
   * - Defaults to 75
   */
  pagePercent?: number;
  /** Adjust zIndex of panel to allow closing top-most panel when clicking on lower panel.
   * - Works with 1 stacked panel.
   */
  stacked?: boolean;
  primaryAction?: TPanelAction;
  secondaryAction?: TPanelAction;
  children?: any;
};

type TPanelPropsPrimitive = {
  title?: string;
  subtitle?: string;
  heading?: never;
};
type TPanelPropsHeading = {
  title?: never;
  subtitle?: never;
  heading?: JSX.Element;
};

type TPanelProps = TPanelPropsBase & (TPanelPropsPrimitive | TPanelPropsHeading);
const DEFAULT_DELAY = 0.35;

/** Modal-like structure that transitions from the bottom of the page.  Replicating react-native mobile panels.
 * - width of panel spans the entire page.
 */
export default function Panel(props: TPanelProps) {
  /** Delayed state change for animation effects */
  const [mounted, setMounted] = useState(props.open);

  const topPos = `${100 - (props.pagePercent ?? 75)}%`;
  const delayInSeconds: number | undefined = props.animate
    ? typeof props.animate === "number"
      ? props.animate
      : DEFAULT_DELAY
    : undefined;

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (!source.token.reason) {
      void delayedMount(props.open, delayInSeconds, source.token);
    }

    return () => source.cancel();
  }, [props.open, delayInSeconds]);

  /** Applies a delay to un-mounting for animation purposes. */
  async function delayedMount(beingMounted: boolean, delayInSeconds: number | undefined, token: CancelToken) {
    if (delayInSeconds !== undefined && !beingMounted) {
      await delay(delayInSeconds);

      if (!token.reason) {
        setMounted(beingMounted);
      }
      return;
    }

    setMounted(beingMounted);
  }

  return (
    <div
      className={classNames({
        "ui-panel_container--closed": !delayInSeconds && !props.open,
        "ui-panel_container--animated": delayInSeconds && props.open,
        "ui-panel_container--animated--closed": delayInSeconds && !props.open,
        "ui-panel_container--stacked": props.stacked,
      })}
      style={{ visibility: delayInSeconds ? (mounted ? "visible" : "hidden") : "visible" }}
    >
      <div
        className="ui-panel_container"
        style={
          delayInSeconds && open
            ? { transitionDuration: `${delayInSeconds}s`, top: topPos }
            : open
            ? { top: topPos }
            : { top: "100%" }
        }
      >
        <div className="ui-panel">
          {props.panelId ? (
            <div className="ui-panel_left-action">
              {typeof props.panelId === "string" ? (
                <p className="text-sm text-regular text-gray-600">{props.panelId}</p>
              ) : null}
              {typeof props.panelId !== "string" ? props.panelId : null}
            </div>
          ) : null}
          <div className="ui-panel_close-button">
            <ButtonNew type="close" size="large" onClick={props.onClose}>
              <Icon icon="times" style="far" size="medium" />
            </ButtonNew>
          </div>
          {/* Either | Or, but not both */}
          <div className="ui-panel_header" style={{ padding: props.panelId ? "60px 24px 0px" : "16px 24px 0 24px" }}>
            {props.heading ? props.heading : null}
            {props.title || props.subtitle ? (
              <span className="primitive-header">
                {props.title && <p className="text-xl text-semibold">{props.title}</p>}
                {props.subtitle && <p className="text-sm text-regular text-gray-600">{props.subtitle}</p>}
              </span>
            ) : null}
          </div>
          {props.children ? <div className={"ui-panel_content"}>{props.children}</div> : null}
          {props.primaryAction || props.secondaryAction ? (
            <div className="ui-panel_footer">
              {props.primaryAction && (
                <ButtonNew
                  className="panel-action primary"
                  type="primary"
                  onClick={props.primaryAction.onClick}
                  disabled={props.primaryAction.disabled}
                >
                  {props.primaryAction.label}
                </ButtonNew>
              )}
              {props.secondaryAction && (
                <ButtonNew
                  className="panel-action secondary"
                  type="secondary"
                  onClick={props.secondaryAction.onClick}
                  disabled={props.secondaryAction.disabled}
                >
                  {props.secondaryAction.label}
                </ButtonNew>
              )}
            </div>
          ) : null}
        </div>
      </div>

      {/* Backdrop */}
      <div
        className={"ui-panel-backdrop"}
        style={delayInSeconds && mounted ? { visibility: "visible" } : undefined}
        onClick={props.onClose}
      />
    </div>
  );
}
