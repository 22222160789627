import { APIGet, APIPost, APIPut, APIDelete, IAPIResponse, StatusCode } from "api/protocols";
import { CancelToken } from "axios";
import { ISlot, ITeeTime, ITeeTimeNew } from "redux/reducers/models/teetime";

const apiVersion = "2024-04";
const adminType = "master-admin";
const baseTeeSheetUrl = "/" + apiVersion + "/" + adminType + "/tee-sheet";

// GET Tee Sheet

interface IGetTeeSheets {
  id?: number;
  facility_id?: number;
  course_id?: number;
  date?: string;
}

export interface ITeeSheet {
  id: number;
  facility_id: number;
  course_id: number;
  date: string;
  day_of_week: string;
  week_number: number;
  format: string;
  tee_sheet_template_title: string;
  tee_sheet_version: string;
}

interface IGetTeeSheetsRes extends IAPIResponse {
  data: ITeeSheet[];
}

export async function GetTeeSheets(params: IGetTeeSheets, useGlobalLoader: boolean): Promise<IGetTeeSheetsRes> {
  return await APIGet(baseTeeSheetUrl, params, useGlobalLoader);
}

// GET Tee Times

interface IGetTeeTime {
  id?: number;
  facility_id?: number;
  tee_sheet_id?: number;
  course_id?: number;
  division_id?: number;
  division_ids?: number[];
  date?: string;
  start_hole?: number;
  start_time?: string;
  end_time?: Partial<Date>;
  upcoming?: boolean;
  extended?: boolean;
  "extended-bookings"?: boolean;
  locks?: boolean;
  booking_fee?: boolean;
  booking_categories?: boolean;
  blocked_type?: string;
  limit?: number;
  events?: boolean;
  turn_tee_time?: boolean;
  limited?: boolean;
}

export interface IGetTeeTimeRes extends IAPIResponse {
  data: Array<ITeeTime>;
}

export interface IGetTeeTimeLimitedRes extends IAPIResponse {
  data: Array<ITeeTimeNew>;
}

export async function GetTeeTime(
  params: IGetTeeTime,
  useGlobalLoader: boolean,
  token?: CancelToken,
): Promise<IGetTeeTimeRes> {
  return await APIGet(baseTeeSheetUrl + "/tee-time", params, useGlobalLoader, token);
}

export async function GetTeeTimeLimited(
  params: IGetTeeTime,
  useGlobalLoader: boolean,
  token?: CancelToken,
): Promise<IGetTeeTimeLimitedRes> {
  return await APIGet(baseTeeSheetUrl + "/tee-time/limited", params, useGlobalLoader, token);
}

interface IPutApply {
  date?: string;
  template_id?: number;
  tee_sheet_ids?: number[];
}

interface IPutApplyRes {
  status: StatusCode;
  data: any;
}

// Apply Template to Tee Sheet
export async function PutApply(params: IPutApply, useGlobalLoader: boolean): Promise<IPutApplyRes> {
  return await APIPut(baseTeeSheetUrl + "/apply", params, useGlobalLoader);
}

interface IPutTeeTime {
  tee_time_ids: number[];
  league_id: number;
  tournament_id: number;
  pre_paid_required: boolean;
  blocked_type: string;
}
// Put Tee time changes
export async function PutTeeTime(params: IPutTeeTime, useGlobalLoader: boolean) {
  return await APIPut(baseTeeSheetUrl + "/tee-time", params, useGlobalLoader);
}

interface IPostTeeTimeLock {
  tee_time_id?: number;
  tee_time_ids?: number[];
}
export async function PostTeeTimeLock(params: IPostTeeTimeLock, useGlobalLoader: boolean) {
  return await APIPost(baseTeeSheetUrl + "/tee-time/lock", params, useGlobalLoader);
}

//Delete all tee time locks created by the user
export async function DeleteTeeTimeLocks(useGlobalLoader: boolean) {
  return await APIDelete(baseTeeSheetUrl + "/tee-time/lock", null, useGlobalLoader);
}

export async function PostEmailTeeSheet(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseTeeSheetUrl + "/notification/email", params, useGlobalLoader);
}

//Get TeeSheet Audit Trails
// Get Booking Audit Trails
export async function GetTeeTimeAuditTrails(
  params: { tee_time_id: number },
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseTeeSheetUrl + "/tee-time/audit", params, useGlobalLoader, token);
}

// Get Tee Sheet Print
// Formatted version of the tee sheet for printing purposes
export async function GetTeeSheetPrint(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseTeeSheetUrl + "/print", params, useGlobalLoader);
}

interface IPostTeeTimeSqueeze {
  tee_time_id: number;
}

interface IPostTeeTimeSqueezeRes {
  data: {
    data: {
      blocked_type: any;
      course_id: number;
      date: string;
      division_id: number;
      divisions_allowed: number;
      facility_id: number;
      id: number;
      league_id: number;
      position: number;
      quantity_remaining: number;
      size: number;
      start_hole: string;
      start_time: string;
      tee_sheet_id: number;
      tournament_id: number;
      turn_course_id: number;
      turn_division_id: number;
      turn_tee_time_id: number;
    };
    message: string;
  };
  status: number;
}

export async function PostTeeTimeSqueeze(
  params: IPostTeeTimeSqueeze,
  useGlobalLoader: boolean,
): Promise<IPostTeeTimeSqueezeRes> {
  return await APIPost(baseTeeSheetUrl + "/tee-time/squeeze", params, useGlobalLoader);
}

interface IGetTeeSheetNotifications {
  facility_id: number;
  date: string;
}

export async function GetTeeSheetNotifications(
  params: IGetTeeSheetNotifications,
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseTeeSheetUrl + "/notification", params, useGlobalLoader, token);
}

export async function GetCheckIns(useGlobalLoader: boolean) {
  return APIGet(baseTeeSheetUrl + "/check-in", useGlobalLoader);
}

interface IGetPricingSheets {
  id?: number;
  extended?: boolean;
}

export async function GetPricingSheets(params: IGetPricingSheets, useGlobalLoader: boolean) {
  return APIGet(baseTeeSheetUrl + "/pricing-sheet", params, useGlobalLoader);
}

export interface IPutCheckIns {
  id: number;
  customer_type_id: number;
  pricing_sheet_id: number;
  variant_id: number;
  holes: number;
}

export interface IConfiguration {
  configurations: IPutCheckIns[];
}

interface IGetTeeTimePricing extends IAPIResponse {
  data: string;
  message: string;
  status: number;
}

export async function PutCheckIns(params: IConfiguration, useGlobalLoader: boolean) {
  return await APIPut(baseTeeSheetUrl + "/check-in", params, useGlobalLoader);
}

export async function GetTeeTimePricing(
  params: any,
  useGlobalLoader: boolean,
  token?: CancelToken,
): Promise<IGetTeeTimePricing> {
  return await APIGet(baseTeeSheetUrl + "/tee-time/pricing", params, useGlobalLoader);
}

export async function PostTeeTime(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseTeeSheetUrl + "/tee-time", params, useGlobalLoader);
}

export async function DeleteTeeTime(params: any, useGlobalLoader: boolean) {
  return await APIDelete(baseTeeSheetUrl + "/tee-time", params, useGlobalLoader);
}

interface IGetPrintCartSigns {
  date: string;
  tournament_id?: number;
  league_id?: number;
  facility_id: number | string;
}

// Get Print Cart Signs
// Print cart signs for tee times with the provided tournament id
export async function GetPrintCartSigns(params: IGetPrintCartSigns, useGlobalLoader: boolean) {
  return await APIGet(baseTeeSheetUrl + "/print-cart-signs", params, useGlobalLoader);
}

export async function GetTeeTimeBookingCategory(
  params: { date: string; facility_id: number | string },
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseTeeSheetUrl + "/tee-time/booking-category", params, useGlobalLoader, token);
}
