import { APIGet, APIPost, APIPut } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseCartUrl = "/" + apiVersion + "/" + adminType + "/facility/kitchen";

interface IGetKitchenLocation {
  id: number;
}

export async function GetKitchenLocations(params?: IGetKitchenLocation, useGlobalLoader?: boolean) {
  return await APIGet(baseCartUrl + "/location", params, useGlobalLoader);
}

export async function PutKitchenLocation(params: any, useGlobalLoader?: boolean) {
  return await APIPut(baseCartUrl + "/location", params, useGlobalLoader);
}

interface IGetKitchenLocationChits {
  kitchen_location_ids?: Array<number>;
  kitchen_location_id?: number;
  table_id?: number;
  extended?: boolean;
  start_date?: string;
  end_date?: string;
  user_id?: number;
}

interface IPostKitchenReceipt {
  table_id: number;
  line_item_ids: Array<number>;
}

interface IPutKitchenChit {
  id: number;
  status: string;
}

export async function GetKitchenLocationChits(
  params?: IGetKitchenLocationChits,
  useGlobalLoader?: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseCartUrl + "/chit", params, useGlobalLoader, token);
}

export async function PostKitchenChit(params: IPostKitchenReceipt, useGlobalLoader: boolean) {
  return await APIPost(baseCartUrl + "/chit", params, useGlobalLoader);
}

export async function PutKitchenChit(params: IPutKitchenChit, useGlobalLoader?: boolean) {
  return await APIPut(baseCartUrl + "/chit", params, useGlobalLoader);
}
