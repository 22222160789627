export const dictionary: { [key: string]: any } = {
  // =============================== //
  // ==== Dashboard Dictionary ===== //
  DASH_1001: {
    en: "Hello World",
    fr: "Hello World French",
  },
  DASH_1002: {
    en: "",
  },
  DASH_1003: {
    en: "",
  },
  DASH_1004: {
    en: "",
  },
  DASH_1005: {
    en: "",
  },
  DASH_1006: {
    en: "",
  },
  DASH_1007: {
    en: "",
  },
  DASH_1008: {
    en: "",
  },
  DASH_1009: {
    en: "",
  },
  DASH_1010: {
    en: "",
  },
  // =============================== //
  // =============================== //

  // =============================== //
  // ==== Tee Sheet Dictionary ===== //
  TEESH_1001: {
    en: "",
  },
  TEESH_1002: {
    en: "",
  },
  TEESH_1003: {
    en: "",
  },
  TEESH_1004: {
    en: "",
  },
  TEESH_1005: {
    en: "",
  },
  TEESH_1006: {
    en: "",
  },
  TEESH_1007: {
    en: "",
  },
  TEESH_1008: {
    en: "",
  },
  TEESH_1009: {
    en: "",
  },
  TEESH_1010: {
    en: "",
  },
  // =============================== //
  // =============================== //
};
