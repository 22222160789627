import React, { useEffect } from "react";
import Input from "components/form/input/Input";
import "./inputModal.scss";
import classNames from "classnames";

interface IInputModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  value: string;
  setValue: (value: string) => void;
  type?: string;
  placeHolder?: string;
  quickCode?: string;
  backDropCancel?: boolean;
  closable?: boolean;
  onCancel?: any;
}

export default function InputModal(props: IInputModalProps) {
  const { open, setOpen, value, setValue, type, placeHolder, quickCode, onCancel } = props;

  useEffect(() => {
    document.addEventListener("keydown", handleQuickCodePress);

    return () => {
      document.removeEventListener("keydown", handleQuickCodePress);
    };
  }, [open]);

  function handleQuickCodePress(event: any) {
    const key = event?.key;
    const ctrlKeySelected = event?.ctrlKey;

    if (key === undefined) {
      return;
    }

    if (ctrlKeySelected && key === quickCode) {
      event.preventDefault();
      setOpen(!open);
    }
  }

  function handleBlur() {
    setOpen(value?.length > 0);
  }

  function handleEnter(event: any) {
    if (event.key === "Enter") {
      setOpen(false);
    }
  }

  return (
    <>
      {open && (
        <>
          <div className="input-modal-container">
            <Input
              value={value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
              autoFocus
              type={type}
              placeholder={placeHolder}
              onBlur={handleBlur}
              onKeyDown={handleEnter}
            />
          </div>
          <div
            className={classNames("ui-sheet-backdrop", {})}
            onClick={props.backDropCancel !== undefined && props.backDropCancel === false ? undefined : onCancel}
          />
        </>
      )}
    </>
  );
}
