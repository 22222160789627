import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { ILeague } from "redux/reducers/models/league";

const apiVersion = "2022-09";
const adminType = "client-admin";
const baseLeagueUrl = "/" + apiVersion + "/" + adminType + "/league";

interface IGetLeague {
  facility_id: number;
  year: number;
  offset: number;
  limit: number;
}

// GET League
// Retrieve a list of leagues
export async function GetLeague(params: Partial<IGetLeague>, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<ILeague[]>(baseLeagueUrl, params, useGlobalLoader, token);
}

// POST League
// Create a new league
export async function PostLeague(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseLeagueUrl, params, useGlobalLoader);
}

// PUT League
// Update an existing league
export async function PutLeague(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseLeagueUrl, params, useGlobalLoader);
}

//GET Players
//Retrieve a list of players in a specific league
export async function GetLeaguePlayers(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseLeagueUrl + "/participant", params, useGlobalLoader);
}

//POST New Player
//Add new player to leage
export async function AddPlayerToLeague(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseLeagueUrl + "/participant", params, useGlobalLoader);
}
