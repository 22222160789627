import { APIGet, APIPost, APIPut, APIDelete, APIUrl, AdminType } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/facility/payout";

type TGetPayout = {
  id?: string | number;
  month?: string | Date | number;
  year?: string | Date | number;
  limit?: number;
  offset?: number;
};

export async function GetPayout(params: TGetPayout, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(APIUrl(AdminType.FacilityAdmin, "/facility/payout"), params, useGlobalLoader, token);
}

type TGetPayoutTransactions = {
  payout_id: string | number;
  month?: string | Date | number;
  year?: string | Date | number;
  limit?: number;
  offset?: number;
};

export async function GetPayoutTransactions(
  params: TGetPayoutTransactions,
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(APIUrl(AdminType.FacilityAdmin, "/facility/payout/transaction"), params, useGlobalLoader, token);
}
