import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import axios, { CancelToken } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Avatar from "components/avatar/Avatar";
import Search from "components/search/Search";
import { ButtonNew as Button } from "components/buttonNew";
import { Badge } from "components/badge/Badge";
import { Flag } from "components/flag/Flag";
import { IFacility } from "redux/reducers/models/facility";
import { GetFacility } from "api/rpc/2024-04/masterAdmin/facility/facility";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { showError } from "redux/actions/ui";

import { IMasterFacilityState } from "redux/reducers/masterAdmin/facility";
import { IFacilityActions } from "redux/actions/masterAdmin/facility";

interface IFacilitiesProps {
  masterFacilityStore: IMasterFacilityState;
  facilityActions: IFacilityActions;
}

interface IFacilitiesState {
  facilities: Array<IFacility>;
  search: string;
  limit: number;
  offset: number;
}

const TABLE_COLUMN_COUNT = 6;

export default function Facilities(props: IFacilitiesProps) {
  const dispatch = useAppDispatch();
  const { masterClientStore } = useAppSelector(store => store);
  const history = useHistory();
  const { clientId }: any = useParams();

  const { masterFacilityStore, facilityActions } = props;

  const [state, setState] = useState<IFacilitiesState>({
    facilities: [],
    search: "",
    limit: 25,
    offset: 0,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (state.search !== undefined) {
      void loadFacilities(source.token);
    }

    return () => {
      source.cancel();
    };
  }, [state.search, state.offset, masterClientStore.client]);

  async function loadFacilities(token?: CancelToken) {
    const facilityRes = await GetFacility(
      {
        client_id: masterClientStore?.client?.id,
        search: state.search,
        offset: state.offset,
        limit: state.limit,
      },
      true,
      token,
    );

    if (facilityRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading facilities"));
      return;
    }

    setState(prevState => ({ ...prevState, facilities: facilityRes.data }));
  }

  function navigateToFacility(url: string, facility: IFacility) {
    facilityActions.selectFacility(facility);
    history.push(url);
  }

  function navigateToNewFacility() {
    history.push(`/admin/facility/new`);
  }

  const primaryAction = {
    content: "New Facility",
    action: navigateToNewFacility,
  };

  return (
    <Page title="Facilities" primaryAction={primaryAction}>
      <div className="flex-grow mb-4 pt-4">
        <Search
          searchCallback={searchValue => setState(prevState => ({ ...prevState, search: searchValue, offset: 0 }))}
          historyKey="master-facilities"
        />
      </div>
      <Card>
        <Card.Section table="true">
          <table className="ui-table ui-table-clickable">
            <thead>
              <tr>
                <th>Name</th>
                <th>Address</th>
                <th>City</th>
                <th>Postal</th>
                <th>Province</th>
                <th>Country</th>
              </tr>
            </thead>
            <tbody>
              {state.facilities ? (
                <>
                  {state.facilities.map((facility: IFacility, index: number) => {
                    return (
                      <tr
                        key={index}
                        onClick={() => navigateToFacility(`/admin/facility/${String(facility.id)}`, facility)}
                      >
                        <td className="table-cell-lead-avatar">
                          <Avatar source={facility.logo_source} size="medium" />
                          <p className="table-cell-lead-text">{facility.long_name}</p>
                        </td>
                        <td>{facility.address_line_1}</td>
                        <td>{facility.city}</td>
                        <td>{facility.postal}</td>
                        <td>{facility.province_name}</td>
                        <td>
                          <Badge type="gray" iconSize="medium" iconLeft={<Flag countryCode={facility.country_code} />}>
                            {facility.country_name}
                          </Badge>
                        </td>
                      </tr>
                    );
                  })}
                </>
              ) : null}
            </tbody>

            <tfoot>
              <tr>
                <td colSpan={2}>
                  <Button
                    size="small"
                    type="secondary"
                    onClick={() => setState(prevState => ({ ...prevState, offset: prevState.offset - state.limit }))}
                    disabled={!(state.limit <= state.offset)}
                  >
                    <FontAwesomeIcon icon={"arrow-left"} />
                    &nbsp;
                    {"Previous"}
                  </Button>
                </td>

                <td colSpan={TABLE_COLUMN_COUNT - 4} />

                <td colSpan={2}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      size="small"
                      type="secondary"
                      onClick={() =>
                        setState(prevState => ({ ...prevState, offset: prevState.offset + state.limit }))
                      }
                      disabled={!(state.facilities.length === state.limit)}
                    >
                      {"Next"}
                      &nbsp;
                      <FontAwesomeIcon icon={"arrow-right"} />
                    </Button>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </Card.Section>
      </Card>
    </Page>
  );
}
