import { APIGet, APIPost, APIPut, APIDelete, IAPIResponse } from "api/protocols";
import { CancelToken } from "axios";
import { TTeeSheetApplication } from "api/rpc/2022-09/clientAdmin/teeSheets/pricingSheet";
import { TCustomerType } from "redux/reducers/models/customer";

const apiVersion = "2024-04";
const adminType = "client-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/customer/type";

export type TGetCustomerType = {
  id?: string | number;
  default?: boolean;
  application?: string;
  /** Search by customer_type.title */
  search?: string;
  booking_category?: boolean;
};

export type TPostCustomerType = {
  title: string;
  subtitle?: string;
  application: Omit<TTeeSheetApplication, "no_show">;
  default_booking_category_id?: number;
  max_current_bookings?: number;
};

export type TPutCustomerType = {
  id: string | number;
  title?: string;
  subtitle?: string | null;
  color?: string | null;
  default_booking_category_id?: number | null;
  max_current_bookings?: number | null;
};

export type TDeleteCustomerType = {
  id: string | number;
  migrate_customer_type_id?: number;
};

// GET
export async function GetCustomerType(params: TGetCustomerType, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<TCustomerType | TCustomerType[]>(baseUrl, params, useGlobalLoader, token);
}

//Post new customer type
export async function PostCustomerType(params: TPostCustomerType, useGlobalLoader?: boolean) {
  return await APIPost<TCustomerType>(baseUrl, params, useGlobalLoader);
}

//Edit existing customer type
export async function PutCustomerType(params: TPutCustomerType, useGlobalLoader?: boolean) {
  return await APIPut<TCustomerType[]>(baseUrl, params, useGlobalLoader);
}

//Delete and migrate customer type
export async function DeleteCustomerType(params: TDeleteCustomerType, useGlobalLoader?: boolean) {
  return await APIDelete(baseUrl, params, useGlobalLoader);
}
