import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/league/scoring";

export type TGetSeasonLeaderboard = {
  league_id: string | number;
  id?: string | number;
};

export type TPostSeasonLeaderboard = {
  league_id: string | number;
  title: string;
  best_scores: number;
  organization: "individual" | "team";
  league_division_id: number;
};

export type TPutSeasonLeaderboard = {
  season_leaderboard_id: string | number;
  title: string;
  best_scores: number;
};

// export type TPutSeasonLeaderboard = {
//   league_id: string | number;
// };

// export type TPrintSeasonLeaderboard = {
//   league_id?: string | number | null;
// };

export async function GetSeasonLeaderboard(
  params: TGetSeasonLeaderboard,
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseUrl + "/season-leaderboard", params, useGlobalLoader, token);
}

export async function PostSeasonLeaderboard(params: TPostSeasonLeaderboard, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/season-leaderboard", params, useGlobalLoader);
}

export async function PutSeasonLeaderboard(params: TPutSeasonLeaderboard, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/season-leaderboard", params, useGlobalLoader);
}

export async function DeleteSeasonLeaderboard(params: { season_leaderboard_id: string }, useGlobalLoader: boolean) {
  return await APIDelete(baseUrl + "/season-leaderboard", params, useGlobalLoader);
}

export async function GetSeasonLeaderboardTotals(
  params: { season_leaderboard_id: string | number },
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseUrl + "/season-leaderboard/totals", params, useGlobalLoader, token);
}

export async function GetSeasonLeaderboardPrint(
  params: { season_leaderboard_id: string | number },
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseUrl + "/season-leaderboard/print", params, useGlobalLoader, token);
}

// export async function PutUpdateSeasonLeaderboard(params: TPutSeasonLeaderboard, useGlobalLoader: boolean) {
//   return await APIPut(baseUrl + "/season-leaderboard", params, useGlobalLoader);
// }

// // PUT Order of Merit
// export async function PrintSeasonLeaderBoard(params: TPrintSeasonLeaderboard, useGlobalLoader: boolean) {
//   return await APIGet(baseUrl + "/season-leaderboard", params, useGlobalLoader);
// }
