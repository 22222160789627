import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";

const apiVersion = "2024-04";
const adminType = "client-admin";
const baseFacilityUrl = "/" + apiVersion + "/" + adminType + "/client";

// GET Client
export async function GetClient(params: string, useGlobalLoader: boolean) {
  return await APIGet(baseFacilityUrl, params, useGlobalLoader);
}

export async function PostLogo(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseFacilityUrl + "/logo", params, useGlobalLoader);
}

export async function DeleteLogo(params: null, useGlobalLoader: boolean) {
  return await APIDelete(baseFacilityUrl + "/logo", params, useGlobalLoader);
}
