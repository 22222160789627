import { APIGet, APIPost, APIPut, APIDelete, StatusCode } from "api/protocols";
import { ExtendedClientProductFilterKey } from "api/rpc/2022-09/clientAdmin/product/product";
import { CancelToken } from "axios";
import { IProduct } from "redux/reducers/models/product";

const apiVersion = "2024-04";
const adminType = "client-admin";
const baseProductUrl = "/" + apiVersion + "/" + adminType + "/product";

interface IGetProduct {
  id?: number;
  search?: string;
  extended?: boolean;
  extended_variants?: boolean;
  sales_channels?: boolean;
  inventory?: boolean;
  limit?: number;
  offset?: number;
  type?: string;
  types?: any;
  all?: boolean;
  filters?: {
    label: ExtendedClientProductFilterKey;
    value: string[] | number[];
  }[];
}

export type TGetProduct = {
  all?: boolean;
  id?: string | number;
  ids?: Array<number>;
  search?: string;
  filters?: {
    label: ExtendedClientProductFilterKey;
    value: string[] | number[];
  }[];
  type?: string;
  types?: string[];
  track_inventory?: boolean;
  folder_id?: string | number;
  variants?: boolean;
  /** This is a taxing call to make. */
  extended?: boolean;
  extended_variants?: boolean;
  inventory?: boolean;
  sales_channels?: boolean;
  modifier_groups?: boolean;
  /** Default: 50 */
  limit?: number;
  /** Default: 0 */
  offset?: number;
  /** Default: 0 */
  archived?: 0 | 1;
  vendor_id?: number;
  skus?: Array<string>;
};

export async function GetProduct(params: TGetProduct, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<IProduct[]>(baseProductUrl, params, useGlobalLoader, token);
}

// Archive Product
export async function PutArchiveProduct(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseProductUrl + "/archive", params, useGlobalLoader);
}

/** Update a product combo price | cost */
export async function UpdateComboProduct(
  params: { base_variant_id: number; variant_id: number; price: string; cost: string },
  useGlobalLoader: boolean,
) {
  return await APIPost(baseProductUrl + "/combination", params, useGlobalLoader);
}

export async function UpdateInventoryLevels(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseProductUrl + "/inventory/level", params, useGlobalLoader);
}

export async function GetInventoryLevels(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseProductUrl + "/inventory/level", params, useGlobalLoader);
}
