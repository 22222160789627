import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./factorNodeEditMode.scss";
import { useAutoFocusInput } from "./useAutoFocusInput";

export interface IFactorPercentVariationValueType {
  valueType: "percent_variation";
  percentVariation: number;
}

interface IFactorNodePercentVariationEditMode {
  defaultPercentVariation: number;
  onConfirm: (value: IFactorPercentVariationValueType) => void;
  onCancel: () => void;
}

export function isFactorPercentVariationValid(percentVariation: number) {
  return percentVariation != null && percentVariation >= -100 && percentVariation <= 100 && percentVariation % 1 === 0;
}

export default function FactorNodePercentVariationEditMode(props: IFactorNodePercentVariationEditMode) {
  const { defaultPercentVariation, onConfirm, onCancel } = props;

  const autoFocusInputRef = useAutoFocusInput();

  const [percentVariationInput, setPercentVariationInput] = useState<string>(
    defaultPercentVariation == null ? "" : String(defaultPercentVariation),
  );

  const percentVariation = percentVariationInput === "" ? null : Number(percentVariationInput);

  return (
    <div className="factor-node-edit-mode">
      <div>
        <input
          ref={autoFocusInputRef}
          className="factor-node-edit-mode-input"
          value={percentVariationInput}
          onChange={e => setPercentVariationInput(e.target.value)}
        />
        %
      </div>
      <button
        type="button"
        className="factor-node-edit-mode-confirm"
        disabled={!isFactorPercentVariationValid(percentVariation)}
        onClick={() =>
          onConfirm({
            valueType: "percent_variation",
            percentVariation,
          })
        }
      >
        <FontAwesomeIcon icon={["fal", "circle-check"]} />
      </button>
      <button type="button" className="factor-node-edit-mode-cancel" onClick={onCancel}>
        <FontAwesomeIcon icon={["fal", "circle-x"]} />
      </button>
    </div>
  );
}
