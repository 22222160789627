import { APIGet } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "guest";
const baseLeagueUrl = "/" + apiVersion + "/" + adminType + "/league";

interface IGetSeasonLeaderboard {
  league_handle: string | number;
  facility_id: string | number;
  id?: string | number;
}

export async function GetSeasonLeaderboard(
  params: IGetSeasonLeaderboard,
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseLeagueUrl + "/scoring/season-leaderboard", params, useGlobalLoader, token);
}

export async function GetSeasonLeaderboardTotals(
  params: { season_leaderboard_id: number | string },
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseLeagueUrl + "/scoring/season-leaderboard/totals", params, useGlobalLoader, token);
}
