import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useHistory, useParams, Prompt } from "react-router";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStripe } from "@stripe/react-stripe-js";
import { dequeue, enqueue, initiateEditBooking, showError, showSuccess } from "redux/actions/ui";
import { loadDiscountOptions, loadPaymentOptions } from "redux/actions/facility";
import { selectTeeTime, selectTeeTimeSuccess } from "redux/actions/teesheet";
import { updateTeeSheetProducts } from "redux/actions/facilityAdmin/teeSheetProducts";
import { IProduct, IVariant } from "redux/reducers/models/product";
import { ICustomer } from "redux/reducers/models/customer";
import { ICart } from "redux/reducers/models/cart";
import { IOrder } from "redux/reducers/models/order";
import { IBooking, IBookingParticipant, ISlot, ITeeTime } from "redux/reducers/models/teetime";
import { StatusCode } from "api/protocols";
import { PutBookingParticipant, PutBookingParticipantNoShow } from "api/rpc/teeSheet/booking";
import { GetCartWaiver } from "api/rpc/2024-04/facilityAdmin/teesheet/booking";
import {
  PostIssueRainCheck,
  PutCalculateRainCheck,
  PostRedeemRainCheck,
} from "api/rpc/facilityAdmin/payment/rainCheck";
import { PostCustomer } from "api/rpc/customer";
import { GetTeeTimeLock, PostTeeTimeLock } from "api/rpc/facilityAdmin/teesheet/teetime";
import { GetCustomer, GetCustomerPaymentMethod } from "api/rpc/facilityAdmin/customer";
import { PostLineItemsToOrder, PostOrder } from "api/rpc/2022-09/facilityAdmin/order/order";
import {
  GetTicketValidate,
  IGetTicketValidateRes,
  PutRedeem,
  GetTicketStub,
} from "api/rpc/2022-09/facilityAdmin/ticket/ticket";
import { PutTicketStubRedeemMultiple } from "api/rpc/2024-04/facilityAdmin/ticket/stub";
import { PostCart } from "api/rpc/2022-09/facilityAdmin/cart/cart";
import {
  DeleteLineItemToCart,
  PostLineItemToCart,
  PutLineItemToCart,
} from "api/rpc/2024-04/facilityAdmin/order/cart/lineItem";
import { PostTransaction, PutCapture } from "api/rpc/2022-09/facilityAdmin/order/transaction";
import {
  GetBooking,
  PutCancelParticipant,
  PutCheckParticipantAll,
} from "api/rpc/2022-09/facilityAdmin/teesheet/booking";
import { convertTime, customerErrorMessage } from "helpers/Helpers";
import { LocaleCurrency } from "helpers/Locale";
import Products, { IProductState } from "elements/register/Products";

import Input from "components/form/input/Input";
import Sheet from "components/sheet/Sheet";
import Divider from "components/divider";
import Callout from "components/callout/Callout";
import Checkbox from "components/form/checkbox/Checkbox";
import { Select } from "components/select/index";
import { ButtonNew as Button } from "components/buttonNew/index";
import ButtonGroup from "components/button/ButtonGroup";
import Tabs from "components/tabs/Tabs";
import Popup from "components/popup/Popup";
import GolferCard from "components/bookingPopUp/golferCard/GolferCard";
import OrderCompletePopup from "components/orderCompletePopup/OrderCompletePopup";
import { Badge } from "components/badge/Badge";
import NewCustomer from "components/newCustomer/NewCustomer";
import "../styles.scss";
import axios, { CancelToken } from "axios";
import Markdown from "components/markdown/Markdown";
import Spin from "components/spin/spin";
import RainCheckCompletePopup from "components/rainCheckCompletePopup/RainCheckCompletePopup";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { NavigationDropdownNew } from "components/navigationDropdownNew/NavigationDropdownNew";
import Portal from "elements/Portal";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";
import CartMenuNew from "../../register/registerNew/CartMenuNew";
import { TParticipantPaidFees, useRegisterContext } from "../../register/registerNew/RegisterContext";
import NewTeeSheetBookingDisplay from "./NewTeeSheetBookingDisplay";
import { GetProduct } from "api/rpc/2024-04/facilityAdmin/product/product";
import { GetTeeTime } from "api/rpc/2024-04/facilityAdmin/teesheet/teeSheet";
import BookingModalEdit from "../newTeeSheet/modals/BookingModalEdit";
import useModal from "hooks/modals/useModal";
import FormLayout from "components/form/FormLayout";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IStub, ITicket } from "redux/reducers/models/ticket";
import { TSku } from "../../settings/inventoryCounts/NewInventoryCount";
import { cloneDeep } from "lodash";
import ChangeCustomer from "components/changeCustomer/ChangeCustomer";

export const enum OrderProgress {
  NEW_ORDER,
  CREATING_CART,
  ADDING_FEES,
  ORDER_IN_PROGRESS,
}

export interface IOrderProgess {
  orderStep: OrderProgress;
}
interface ICheckInState {
  cart: ICart;
  order: IOrder;
  redeemTicketActive: boolean;
  redeemTicketSelected: number;
  redeem_ticket_code: string;
  ticketValidSuccess: boolean;
  ticketValidWarning: boolean;
  availableTickets: any;
  getTicketValidateData: IGetTicketValidateRes["data"] | Partial<IGetTicketValidateRes["data"]>;
  cancelOrderActive: boolean;
  manualCheckInVisible: boolean;
  manualCheckInSlot: ISlot;
  ticketRedeemSuccess: boolean;
  selectedTickets: IStub[];
  selectedCustomerTickets: IStub[];
}
interface IMainState {
  selectedBooking: IBooking;
  teeTimeLock: boolean;
  teeTimeCancelledPopupVisible: boolean;
  clearCartActive: boolean;
  powerCartModalVisible: boolean;
}
interface IAddItemState {
  greenFeeActive: boolean;
  powerCartFeeActive: boolean;
  itemSearchResult: IProduct[];
  participant_id: number;
  tee_time_id: number;
  greenfeeSearchQuery: string;
  cartSearchQuery: string;
  foundGreenFees: IProduct[];
  foundCartItems: IProduct[];
  productState: IProductState;
  selectedSlot: ISlot;
  participantFeeLoadingMessage: string;
  selectedProductType: "Green Fee" | "Power Cart";
}
interface INoShowState {
  noShowActive: boolean;
  bookingParticipantIds: Array<number>;
  quantity: number;
  greenFeeProductID: number;
  greenFeeProductTitle: string;
  greenFeeProductPrice: number;
  paymentMethodID: string;
  customerID: number;
  teeTimeBookingId: number;
  greenFeeProducts: IProduct[];
  noPaymentMethodModal: boolean;
}

interface IRainCheckState {
  rainCheckActive: boolean;
  rainCheckCompleteActive: boolean;
  rainCheck: any;
  checkBoxes: boolean[];
  selectedBookingParticipantId: number;
  selectedBookingParticipant: any;
  continueBtn: boolean;
  holes: number;
  completedHoles: string;
  percent: number;
  amount: number;
  calculate: number;
  orderId: number;
  issue_on_green_fee: boolean;
  issue_on_power_cart: boolean;

  redeemRainCheckActive: boolean;
  redeemRainCheckCode: string;
  okDisabled: boolean;
}
interface IGroupPayState {
  groupPayActive: boolean;
  groupPayTeeTimes: ITeeTime[];
  playerSearch: string;
}

type FeeStatusType = {
  [id: number]: {
    greenFeeChecked: boolean;
    powerCartChecked: boolean;
  };
};

interface IChangePlayerState {
  showChangePlayer: boolean;
  showNewPlayer: boolean;
  customerQuery: string;
  customerSearching: boolean;
  playerSearchResult: Array<ICustomer>;
  selectedCustomer: ICustomer;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  bookingParticipant: IBookingParticipant;
}

interface IOrderComplete {
  isOpen: boolean;
  clearOnClose: boolean;
  loading: boolean;
  transactionComplete: boolean;
}

export interface ICustomerInfoState {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
}

interface ITicketState {
  tickets: Array<TSku>;
  ticketString: string;
}

export enum StateType {
  MainState = "IMainState",
  AddItemState = "IAddItemState",
  CheckInState = "ICheckInState",
  OrderProgress = "IOrderProgess",
  NoShowState = "INoShowState",
  RainCheckState = "IRainCheckState",
  GroupPayState = "IGroupPayState",
  OrderComplete = "OrderComplete",
}

const NewTeeSheetDetails: React.FC = () => {
  const { teetimeId } = useParams<{ teetimeId: string }>();
  const stripe = useStripe();
  const { t, i18n } = useTranslation();
  const { teeSheetStore, uiStore, facilityStore, teeSheetProductStore } = useAppSelector(store => store);
  const { Option } = Select;
  const {
    loadingVariants,
    addLoadingVariant,
    removeLoadingVariant,
    addLineItemUpdating,
    removeLineItemUpdating,
    lineItemsUpdating,
    participantPaidFees,
    updateParticipantPaidFees,
    initializeParticipantPaidFees,
  } = useRegisterContext();

  const history = useHistory();
  const dispatch = useAppDispatch();

  const [orderProgress, setOrderProgress] = useState<IOrderProgess>({
    orderStep: OrderProgress.NEW_ORDER,
  });

  const [noShow, setNoShow] = useState<INoShowState>({
    noShowActive: false,
    bookingParticipantIds: [],
    quantity: 0,
    greenFeeProductID: null,
    greenFeeProductTitle: "",
    greenFeeProductPrice: null,
    paymentMethodID: "",
    customerID: null,
    teeTimeBookingId: null,
    greenFeeProducts: [],
    noPaymentMethodModal: false,
  });

  const [addItemState, setAddItemState] = useState<IAddItemState>({
    greenFeeActive: false,
    powerCartFeeActive: false,
    itemSearchResult: [],
    participant_id: null,
    tee_time_id: null,
    greenfeeSearchQuery: "",
    cartSearchQuery: "",
    foundGreenFees: [],
    foundCartItems: [],
    productState: {
      selectedProduct: undefined,
      variants: [],
      variantSheetOpen: false,
    },
    selectedSlot: null,
    participantFeeLoadingMessage: null,
    selectedProductType: null,
  });

  const [foundGreenFees, setFoundGreenFees] = useState<IProduct[]>(addItemState.foundGreenFees);
  const [foundCartItems, setFoundCartItems] = useState<IProduct[]>(addItemState.foundCartItems);

  const [checkInState, setCheckInState] = useState<ICheckInState>({
    cart: null,
    order: undefined,
    redeemTicketActive: false,
    redeemTicketSelected: 0,
    redeem_ticket_code: "",
    ticketValidSuccess: false,
    ticketValidWarning: false,
    getTicketValidateData: {},
    availableTickets: [],
    cancelOrderActive: false,
    manualCheckInVisible: false,
    manualCheckInSlot: null,
    ticketRedeemSuccess: false,
    selectedTickets: [],
    selectedCustomerTickets: [],
  });

  const [state, setState] = useState<IMainState>({
    selectedBooking: null,
    teeTimeLock: false,
    teeTimeCancelledPopupVisible: false,
    clearCartActive: false,
    powerCartModalVisible: false,
  });

  const [rainCheckState, setRainCheckState] = useState<IRainCheckState>({
    //Issue Rain Check
    rainCheckActive: false,
    rainCheckCompleteActive: false,
    rainCheck: null,
    checkBoxes: new Array(4).fill(false),
    selectedBookingParticipantId: null,
    selectedBookingParticipant: null,
    continueBtn: false,
    holes: null,
    completedHoles: "",
    percent: null,
    amount: null,
    calculate: null,
    orderId: null,
    issue_on_green_fee: false,
    issue_on_power_cart: false,

    //Redeem Rain Check
    redeemRainCheckActive: false,
    redeemRainCheckCode: "",
    okDisabled: true,
  });

  const [groupPayState, setGroupPayState] = useState<IGroupPayState>({
    groupPayActive: false,
    groupPayTeeTimes: null,
    playerSearch: "",
  });

  const [changePlayerState, setChangePlayerState] = useState<IChangePlayerState>({
    showChangePlayer: false,
    showNewPlayer: false,
    customerQuery: "",
    customerSearching: false,
    playerSearchResult: [],
    selectedCustomer: null,
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    bookingParticipant: null,
  });

  const [orderCompletePopup, setOrderCompletePopup] = useState<IOrderComplete>({
    isOpen: false,
    clearOnClose: true,
    loading: false,
    transactionComplete: false,
  });

  const {
    state: editBookingModal,
    updateModal: updateEditBookingModal,
    closeModal: closeEditBookingModal,
  } = useModal({
    clearOnClose: true,
    selectedTeeTime: null,
    selectedBooking: null,
    teeTimeLock: false,
    loading: false,
    loadingText: "",
    postingLock: false,
  });

  const [ticketState, setTicketState] = useState<ITicketState>({
    tickets: [],
    ticketString: "",
  });

  useEffect(() => {
    //Initialize the tee time
    void refreshTeeTime(true, true);

    //Show warning dialog box when browser is refreshed
    function handleBeforeUnload(event: BeforeUnloadEvent) {
      event.preventDefault();
      event.returnValue = "";
      return "";
    }
    //Initialize payment methods
    if (!facilityStore?.paymentOptions || facilityStore?.paymentOptions?.length === 0) {
      void dispatch(loadPaymentOptions());
    }
    //Initialize discount options
    if (facilityStore?.discountOptions?.length === 0) {
      void dispatch(loadDiscountOptions());
    }

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  useEffect(() => {
    // If there are no bookings in the selected tee time, return to the tee sheet
    if (teeSheetStore.selectedTeeTime && teeSheetStore.selectedTeeTime?.quantity_remaining === 4) {
      history.push(`/admin/teesheet`);
    }
  }, [teeSheetStore.selectedTeeTime]);

  //Group Pay Tee Times
  useEffect(() => {
    const source = axios.CancelToken.source();
    if (groupPayState.groupPayActive) {
      void loadGroupPayTeeTimes(source.token);
    }
    return () => source.cancel();
  }, [groupPayState.groupPayActive]);

  function updateState<T>(newState: Partial<T>, type: StateType) {
    const states = {
      [StateType.MainState]: (newState: Partial<T>) =>
        setState((cur: IMainState) => {
          return { ...cur, ...newState };
        }),
      [StateType.AddItemState]: (newState: Partial<T>) =>
        setAddItemState((cur: IAddItemState) => {
          return { ...cur, ...newState };
        }),
      [StateType.CheckInState]: (newState: Partial<T>) =>
        setCheckInState((cur: ICheckInState) => {
          return { ...cur, ...newState };
        }),
      [StateType.OrderProgress]: (newState: Partial<T>) =>
        setOrderProgress((cur: IOrderProgess) => {
          return { ...cur, ...newState };
        }),

      [StateType.NoShowState]: (newState: Partial<T>) =>
        setNoShow((cur: INoShowState) => {
          return { ...cur, ...newState };
        }),
      [StateType.RainCheckState]: (newState: Partial<T>) =>
        setRainCheckState((cur: IRainCheckState) => {
          return { ...cur, ...newState };
        }),
      [StateType.GroupPayState]: (newState: Partial<T>) =>
        setGroupPayState((cur: IGroupPayState) => {
          return { ...cur, ...newState };
        }),
      [StateType.OrderComplete]: (newState: Partial<T>) =>
        setOrderCompletePopup((cur: IOrderComplete) => {
          return { ...cur, ...newState };
        }),
    };

    states[type](newState);
  }

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (addItemState?.greenFeeActive || addItemState?.powerCartFeeActive) {
      void openSelectParticipantFee(addItemState?.selectedSlot, addItemState?.selectedProductType, source.token);
    }
    return () => {
      source.cancel();
    };
  }, [addItemState?.greenFeeActive, addItemState?.powerCartFeeActive]);

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = global.setTimeout(() => {
        void changePlayerSearch(mounted, changePlayerState.customerQuery);
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      setChangePlayerState(prevState => ({ ...prevState, playerSearchResult: [] }));
    };
  }, [changePlayerState.customerQuery]);

  useEffect(() => {
    if (!uiStore.editBookingActive) {
      updateState<IMainState>({ teeTimeLock: false }, StateType.MainState);
    }
  }, [uiStore.editBookingActive]);

  useEffect(() => {
    if (checkInState?.order) {
      //Check in players once order is completed
      if (checkInState?.order?.balance <= 0 || checkInState?.order?.financial_status === "paid") {
        void handleCheckParticipants(checkInState?.order);
      }
    }
  }, [checkInState?.order]);

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (async () => {
          try {
            if (
              (rainCheckState.completedHoles === "" && rainCheckState.amount === null) ||
              rainCheckState.orderId === null
            ) {
              if (mounted) {
                updateState<IRainCheckState>({ calculate: null }, StateType.RainCheckState);
              }
              return;
            } else {
              const calcRes = await PutCalculateRainCheck(
                {
                  order_id: rainCheckState.orderId,
                  booking_participant_id: rainCheckState.selectedBookingParticipantId,
                  issue_on_green_fee: rainCheckState.issue_on_green_fee,
                  issue_on_power_cart: rainCheckState.issue_on_power_cart,
                  amount: rainCheckState.amount,
                  percent: rainCheckState.percent,
                },
                true,
              );
              if (calcRes.status !== StatusCode.OK) {
                return;
              }
              if (mounted) {
                updateState<IRainCheckState>({ calculate: calcRes.data, okDisabled: false }, StateType.RainCheckState);
              }
            }
          } catch (error) {
            console.log("err", error);
          }
          return;
        })();
      }, 1000);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [rainCheckState.percent, rainCheckState.amount]);

  async function handleEditBooking(booking: IBooking, newPlayerAdded?: boolean) {
    const teeTime = teeSheetStore?.selectedTeeTime;
    // Show edit booking modal while data is loading
    dispatch(initiateEditBooking());

    // Load booking
    updateEditBookingModal({ isOpen: true, loading: true, loadingText: "Loading booking..." });

    const bookingRes = await GetBooking({ id: booking?.id }, false);

    //Check if selected booking has been cancelled by another user
    if (bookingRes.status !== StatusCode.OK || bookingRes.data[0]?.status === "cancelled") {
      void refreshTeeTime(true, true);
      dispatch(showError(t("elements.tee_sheet.tee_sheet_tabs.063")));
      return;
    }

    updateEditBookingModal({ loadingText: "Checking tee time availability...", postingLock: true });

    const postLockRes = await PostTeeTimeLock({ tee_time_id: teeTime?.id }, false);
    if (postLockRes.status !== StatusCode.OK) {
      return;
    }

    // Tee time is locked by another user
    if (postLockRes.status !== StatusCode.OK) {
      updateEditBookingModal({
        selectedBooking: null,
        selectedTeeTime: null,
        loading: false,
        postingLock: false,
        teeTimeLock: false,
      });
      dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.005")));
    }

    updateEditBookingModal({
      selectedBooking: {
        ...bookingRes.data[0],
        quantity: newPlayerAdded ? (bookingRes.data[0].quantity += 1) : bookingRes.data[0].quantity,
      },
      selectedTeeTime: teeTime,
      loading: false,
      postingLock: false,
    });
  }

  async function handleRemoveFee(
    slot: ISlot,
    variantId: number,
    type: "green_fee" | "power_cart_fee",
    event: React.MouseEvent<Element, MouseEvent>,
  ) {
    if (event) {
      event.stopPropagation();
    }

    let currentParticipant = [...participantPaidFees?.current]?.find(
      participant => participant?.booking_participant_id === slot?.booking_participant?.id,
    );

    if (
      (checkInState?.cart && type === "green_fee" && currentParticipant?.green_fee) ||
      (type === "power_cart_fee" && currentParticipant?.power_cart_fee)
    ) {
      //Find line item with matching variant id in cart
      const foundItem = [...checkInState?.cart?.line_items]?.find(
        lineItem =>
          lineItem.variant_id === variantId &&
          !lineItemsUpdating?.current?.some(updatingItem => updatingItem?.id === lineItem?.id),
      );
      if (foundItem) {
        currentParticipant = { ...currentParticipant, [`${type}_loading`]: true };
        void updateParticipantPaidFees(currentParticipant);
        void addLineItemUpdating(foundItem?.id, "delete");
        updateState<IOrderProgess>({ orderStep: OrderProgress.ADDING_FEES }, StateType.OrderProgress);
        let updateRes;
        //Decrement line item quantity if quantity > 1
        if (foundItem?.quantity > 1) {
          updateRes = await PutLineItemToCart({ id: foundItem?.id, quantity: foundItem?.quantity - 1 }, false);
        } else {
          //Remove line item if line item quantity is 1
          updateRes = await DeleteLineItemToCart({ id: foundItem?.id }, false);
        }

        currentParticipant = [...participantPaidFees?.current]?.find(
          participant => participant?.booking_participant_id === slot?.booking_participant?.id,
        );
        if (updateRes?.status !== StatusCode.OK) {
          dispatch(showError("Error removing fee"));
          currentParticipant = { ...currentParticipant, [`${type}_loading`]: false };
        } else {
          //Set fee & loading status to false
          currentParticipant = { ...currentParticipant, [`${type}`]: false, [`${type}_loading`]: false };
          updateState<ICheckInState>({ cart: updateRes?.data }, StateType.CheckInState);
        }
        void removeLineItemUpdating(foundItem?.id);
      } else {
        //Item not found, still set fee value to false
        currentParticipant = { ...currentParticipant, [`${type}`]: false, [`${type}_loading`]: false };
      }
      void updateParticipantPaidFees(currentParticipant);
      updateState<IOrderProgess>({ orderStep: OrderProgress.ORDER_IN_PROGRESS }, StateType.OrderProgress);
    }
  }

  async function handleAddFee(
    slot: ISlot,
    greenFee: { id: number; productTitle: string; variantTitle: string },
    powerCartFee: { id: number; productTitle: string; variantTitle: string },
    event: React.MouseEvent<Element, MouseEvent>,
  ) {
    if (event) {
      event.stopPropagation();
    }

    let currentParticipant = [...participantPaidFees?.current]?.find(
      participant => participant?.booking_participant_id === slot?.booking_participant?.id,
    );

    if (!currentParticipant) {
      //Participant not found, add to the array
      const newParticipant = {
        booking_participant_id: slot?.booking_participant?.id,
        green_fee: slot?.booking_participant?.green_fee_paid,
        power_cart_fee: slot?.booking_participant?.power_cart_paid,
        green_fee_loading: false,
        power_cart_fee_loading: false,
      };
      const currentFees = [...participantPaidFees?.current, newParticipant];
      void initializeParticipantPaidFees(currentFees);
      currentParticipant = newParticipant;
    }

    if (currentParticipant) {
      //Arrow >> button is clicked but both the green fee product and power cart fee product aren't set
      if (
        greenFee &&
        powerCartFee &&
        !slot?.booking_participant?.green_fee_variant_id &&
        !slot?.booking_participant?.power_cart_variant_id
      ) {
        dispatch(showError("Green fee and power cart products are not set"));
        return;
      }
      let cart = checkInState?.cart ? checkInState?.cart : null;

      const variants = [];

      //Set green fee variant & show green fee loader
      if (
        greenFee &&
        !currentParticipant?.green_fee &&
        greenFee?.id !== null &&
        !slot?.booking_participant?.green_fee_paid
      ) {
        variants.push({
          variant_id: greenFee?.id,
          quantity: 1,
          title: greenFee?.variantTitle,
          product_title: greenFee?.productTitle,
        });
        currentParticipant = { ...currentParticipant, green_fee: true, green_fee_loading: true };
        void updateParticipantPaidFees(currentParticipant);
      }

      //Set power cart variant & show power cart loader
      if (
        powerCartFee &&
        !currentParticipant?.power_cart_fee &&
        powerCartFee?.id !== null &&
        !slot?.booking_participant?.power_cart_paid
      ) {
        variants.push({
          variant_id: powerCartFee?.id,
          quantity: 1,
          title: powerCartFee?.variantTitle,
          product_title: powerCartFee?.productTitle,
        });
        currentParticipant = { ...currentParticipant, power_cart_fee: true, power_cart_fee_loading: true };
        void updateParticipantPaidFees(currentParticipant);
      }

      //Create a cart if one is not in state
      if ((!cart || orderProgress.orderStep === OrderProgress.NEW_ORDER) && variants?.length > 0) {
        updateState<IOrderProgess>({ orderStep: OrderProgress.CREATING_CART }, StateType.OrderProgress);
        const postCartRes = await PostCart(
          { customer_id: !slot.booking_participant?.guest ? slot.booking_participant.customer_id : null },
          false,
        );
        if (postCartRes?.status !== StatusCode.OK) {
          dispatch(showError("Error creating cart"));
          void updateParticipantPaidFees({
            ...currentParticipant,
            power_cart_fee: false,
            green_fee: false,
            green_fee_loading: false,
            power_cart_fee_loading: false,
          });
          updateState<IOrderProgess>({ orderStep: OrderProgress.NEW_ORDER }, StateType.OrderProgress);
          return;
        }
        cart = postCartRes?.data;
        // Save cart to state
        updateState<ICheckInState>({ cart: postCartRes?.data, order: undefined }, StateType.CheckInState);
      }

      //Add variant(s) to cart
      if (variants?.length > 0) {
        //Display loading variants in cart
        variants?.forEach(variant => {
          void addLoadingVariant(
            { id: variant?.variant_id, title: variant?.title, quantity: variant?.quantity } as IVariant,
            { title: variant?.product_title } as IProduct,
          );
        });
        updateState<IOrderProgess>({ orderStep: OrderProgress.ADDING_FEES }, StateType.OrderProgress);
        const lineItemRes = await PostLineItemToCart(
          {
            cart_id: cart?.id,
            variants: variants,
          },
          false,
        );
        //Remove loading variants in cart
        variants?.forEach(variant => {
          void removeLoadingVariant(variant?.variant_id);
        });
        if (lineItemRes?.status !== StatusCode.OK) {
          dispatch(showError("Error adding line item to cart"));
          void updateParticipantPaidFees({
            ...currentParticipant,
            green_fee: greenFee ? false : currentParticipant?.green_fee,
            power_cart_fee: powerCartFee ? false : currentParticipant?.power_cart_fee,
            green_fee_loading: false,
            power_cart_fee_loading: false,
          });
          return;
        }

        currentParticipant = [...participantPaidFees?.current]?.find(
          participant => participant?.booking_participant_id === slot?.booking_participant?.id,
        );
        //Remove loader on green fee / power cart fee
        if (greenFee && currentParticipant) {
          currentParticipant = { ...currentParticipant, green_fee_loading: false };
          void updateParticipantPaidFees(currentParticipant);
        }
        if (powerCartFee && currentParticipant) {
          currentParticipant = { ...currentParticipant, power_cart_fee_loading: false };
          void updateParticipantPaidFees(currentParticipant);
        }

        ReactDOM.unstable_batchedUpdates(() => {
          updateState<ICheckInState>({ cart: lineItemRes?.data }, StateType.CheckInState);
          updateState<IOrderProgess>({ orderStep: OrderProgress.ORDER_IN_PROGRESS }, StateType.OrderProgress);
        });
      }
    }
  }

  function initiateSelectParticipantFee(
    event: React.MouseEvent<Element, MouseEvent>,
    slot: ISlot,
    product_type: "Green Fee" | "Power Cart",
    green_fee_added?: boolean,
    power_cart_fee_added?: boolean,
    orderStarted?: boolean,
  ) {
    event?.stopPropagation();
    if (
      (green_fee_added && power_cart_fee_added) ||
      (product_type === "Green Fee" && (slot.booking_participant.green_fee_paid || green_fee_added)) ||
      (product_type === "Power Cart" && (slot.booking_participant.power_cart_paid || power_cart_fee_added)) ||
      orderStarted ||
      orderProgress.orderStep === OrderProgress.CREATING_CART
    ) {
      return;
    }
    const active_type = product_type === "Green Fee" ? "greenFeeActive" : "powerCartFeeActive";
    updateState<IAddItemState>(
      {
        [active_type]: true,
        selectedSlot: slot,
        selectedProductType: product_type,
      },
      StateType.AddItemState,
    );
  }

  const openSelectParticipantFee = async (
    slot: ISlot,
    product_type: "Green Fee" | "Power Cart",
    cancelToken?: CancelToken,
  ) => {
    let active_type: string;
    let active_store: any;
    let active_store_string: string;

    if (product_type === "Green Fee") {
      active_type = "greenFeeActive";
      active_store = teeSheetProductStore.greenFeeItems;
      active_store_string = "greenFeeItems";
    }

    if (product_type === "Power Cart") {
      active_type = "powerCartFeeActive";
      active_store = teeSheetProductStore.powerCartItems;
      active_store_string = "powerCartItems";
    }

    let items = active_store;
    if (active_store === null) {
      // Get Products if redux store is empty
      updateState<IAddItemState>(
        { participantFeeLoadingMessage: `Loading ${product_type} products...`, [active_type]: true },
        StateType.AddItemState,
      );
      items = await GetProduct({ type: product_type, extended: true }, false, cancelToken);
      updateState<IAddItemState>({ participantFeeLoadingMessage: null }, StateType.AddItemState);
    }

    if (items?.status === StatusCode.OK) {
      const participant_id = slot?.tee_time_booking_participant_id;
      const tee_time_id = slot?.tee_time_id;
      updateState<IAddItemState>(
        { itemSearchResult: items.data, participant_id, tee_time_id, selectedSlot: slot, [active_type]: true },
        StateType.AddItemState,
      );
      dispatch(updateTeeSheetProducts(active_store_string, items));
    } else {
      void cancelAddItem();
      if (cancelToken && cancelToken?.reason) {
        return;
      }
      dispatch(showError("Error getting products"));
    }
  };

  const cancelAddItem = () => {
    updateState<IAddItemState>(
      {
        itemSearchResult: [],
        greenFeeActive: false,
        powerCartFeeActive: false,
        participant_id: null,
        tee_time_id: null,
        selectedProductType: null,
        productState: {
          selectedProduct: undefined,
          variants: [],
          variantSheetOpen: false,
        },
      },
      StateType.AddItemState,
    );
  };

  async function handleAddQuickItem(variant: IVariant, product_type: "Green Fee" | "Power Cart", product?: IProduct) {
    const addItem = { ...addItemState };
    const participantId = addItem?.participant_id;
    let addToCart = false;

    //Add item to cart if tee time date is today
    if (moment(teeSheetStore?.selectedTeeTime?.date).isSame(moment(), "date")) {
      addToCart = true;
      if (!checkInState?.cart || orderProgress.orderStep === OrderProgress.NEW_ORDER) {
        //Set order progress to disable adding more items until the cart is created
        updateState<IOrderProgess>({ orderStep: OrderProgress.CREATING_CART }, StateType.OrderProgress);
      }
    }

    let green_fee_variant_id;
    let power_cart_variant_id;

    if (product_type === "Green Fee") {
      green_fee_variant_id = variant?.id;
    }

    if (product_type === "Power Cart") {
      power_cart_variant_id = variant?.id;
    }

    //Close and clear modal
    void cancelAddItem();

    let currentParticipant = [...participantPaidFees?.current]?.find(
      participant => participant?.booking_participant_id === participantId,
    );

    //Add loader to participant fee
    if (currentParticipant) {
      void updateParticipantPaidFees({
        ...currentParticipant,
        green_fee_loading: product_type === "Green Fee" ? true : currentParticipant?.green_fee_loading,
        power_cart_fee_loading: product_type === "Power Cart" ? true : currentParticipant?.power_cart_fee_loading,
      });
    }
    const putBookingParticipantRes = await PutBookingParticipant(
      { id: participantId, green_fee_variant_id, power_cart_variant_id },
      false,
    );

    currentParticipant = [...participantPaidFees?.current]?.find(
      participant => participant?.booking_participant_id === participantId,
    );

    //Remove loader from participant fee
    if (currentParticipant) {
      void updateParticipantPaidFees({
        ...currentParticipant,
        green_fee_loading: product_type === "Green Fee" ? false : currentParticipant?.green_fee_loading,
        power_cart_fee_loading: product_type === "Power Cart" ? false : currentParticipant?.power_cart_fee_loading,
      });
    }

    if (putBookingParticipantRes.status === StatusCode.OK) {
      //If teetime is today, add Fee to cart
      if (addToCart) {
        const productTitle = product
          ? product?.preferred_title
            ? product?.preferred_title
            : product?.title
          : addItem?.productState?.selectedProduct?.preferred_title
          ? addItem?.productState?.selectedProduct?.preferred_title
          : addItem?.productState?.selectedProduct?.title;
        if (product_type === "Power Cart") {
          void handleAddFee(
            addItem?.selectedSlot,
            null,
            { id: variant?.id, variantTitle: variant?.title, productTitle: productTitle },
            null,
          );
        } else if (product_type === "Green Fee") {
          void handleAddFee(
            addItem?.selectedSlot,
            { id: variant?.id, variantTitle: variant?.title, productTitle: productTitle },
            null,
            null,
          );
        }
      }

      // Refresh tee time
      void dispatch(selectTeeTime(addItem?.tee_time_id, false, false));

      // display success message
      dispatch(showSuccess(t("secure.facility.tee_sheet.tee_sheet_booking_details.144")));
    } else {
      currentParticipant = [...participantPaidFees?.current]?.find(
        participant => participant?.booking_participant_id === participantId,
      );

      //Remove loader from participant fee
      if (currentParticipant) {
        void updateParticipantPaidFees({
          ...currentParticipant,
          green_fee_loading: product_type === "Green Fee" ? false : currentParticipant?.green_fee_loading,
          power_cart_fee_loading: product_type === "Power Cart" ? false : currentParticipant?.power_cart_fee_loading,
        });
      }
      dispatch(showError(`Error updating participant ${product_type}`));
    }
  }

  const handleDeleteOrder = () => {
    void resetParticipantFees(teeSheetStore?.selectedTeeTime);
    ReactDOM.unstable_batchedUpdates(() => {
      updateState<ICheckInState>({ order: undefined, cart: null }, StateType.CheckInState);
      updateState<IOrderProgess>({ orderStep: OrderProgress.NEW_ORDER }, StateType.OrderProgress);
      updateState<IMainState>({ clearCartActive: false }, StateType.MainState);
    });
  };

  const handleRainCheckHolesInput = (event: any) => {
    const { id } = event.target;
    let { value } = event.target;
    let percentValue: number;
    if (value !== "") {
      value = Math.min(rainCheckState.holes, parseInt(value));
      percentValue = 1 - value / rainCheckState.holes;
      updateState<IRainCheckState>({ [id]: value.toString(), percent: percentValue }, StateType.RainCheckState);
      return;
    }
    updateState<IRainCheckState>({ [id]: value, percent: null }, StateType.RainCheckState);
  };

  const handleRainCheckAmountInput = (event: any) => {
    const { id } = event.target;
    let { value } = event.target;
    value = value === "" ? null : parseFloat(value);
    updateState<IRainCheckState>({ [id]: value }, StateType.RainCheckState);
  };

  const handleRainCheckbox = (position: number, slot: ISlot) => {
    const updatedCheckboxes = new Array(4).fill(false);
    updatedCheckboxes[position] = !updatedCheckboxes[position];

    updateState<IRainCheckState>(
      {
        checkBoxes: updatedCheckboxes,
        selectedBookingParticipantId: slot.booking_participant.id,
        selectedBookingParticipant: slot.booking_participant,
        okDisabled: false,
        holes: slot.booking.holes,
        orderId: slot.booking_participant.order_id,
        issue_on_green_fee:
          slot.booking_participant.green_fee_variant_id !== null && slot.booking_participant.green_fee_paid
            ? true
            : false,
        issue_on_power_cart:
          slot.booking_participant.power_cart_variant_id !== null && slot.booking_participant.power_cart_paid
            ? true
            : false,
      },
      StateType.RainCheckState,
    );
  };

  const issueRainCheck = async () => {
    //Continue button

    if (!rainCheckState.continueBtn) {
      updateState<IRainCheckState>(
        {
          continueBtn: !rainCheckState.continueBtn,
          amount: null,
          completedHoles: "",
          percent: null,
          calculate: null,
          okDisabled: true,
        },
        StateType.RainCheckState,
      );
    } else {
      const rainCheckRes = await PostIssueRainCheck(
        {
          order_id: rainCheckState.orderId,
          booking_participant_id: rainCheckState.selectedBookingParticipantId,
          issue_on_green_fee: rainCheckState.issue_on_green_fee,
          issue_on_power_cart: rainCheckState.issue_on_power_cart,
          amount: rainCheckState.calculate,
        },
        true,
      );

      if (rainCheckRes.status !== StatusCode.OK) {
        dispatch(showError(rainCheckRes.data));
        return;
      }
      dispatch(showSuccess("Rain Check Issued"));

      updateState<IRainCheckState>(
        {
          rainCheck: rainCheckRes.data,
          rainCheckCompleteActive: true,
          rainCheckActive: false,
          checkBoxes: new Array(4).fill(false),
          continueBtn: false,
          selectedBookingParticipantId: null,
        },
        StateType.RainCheckState,
      );
    }
  };

  const cancelRainCheck = () => {
    if (rainCheckState.continueBtn) {
      console.log("back to first page of modal");
      updateState<IRainCheckState>(
        {
          continueBtn: !rainCheckState.continueBtn,
          amount: null,
          completedHoles: "",
          percent: null,
          calculate: null,
          okDisabled: false,
        },
        StateType.RainCheckState,
      );
    } else {
      updateState<IRainCheckState>(
        {
          rainCheckActive: false,
          selectedBookingParticipantId: null,
          checkBoxes: new Array(4).fill(false),
          continueBtn: false,
          holes: null,
          calculate: null,
          percent: null,
          amount: null,
          completedHoles: "",
        },
        StateType.RainCheckState,
      );
    }
  };

  const handleRedeemRainCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    updateState<IRainCheckState>({ [id]: value }, StateType.RainCheckState);
  };

  const closeRedeemRainCheck = () => {
    updateState<IRainCheckState>({ redeemRainCheckActive: false, redeemRainCheckCode: "" }, StateType.RainCheckState);
  };

  const handleRedeemRainCheck = async () => {
    if (checkInState.cart?.id) {
      const redeemRes = await PostRedeemRainCheck(
        { cart_id: checkInState.cart.id, rain_check_code: rainCheckState.redeemRainCheckCode },
        true,
      );
      if (redeemRes.status !== StatusCode.OK) {
        dispatch(showError(redeemRes.data));
        return;
      }
      dispatch(showSuccess(t("secure.facility.tee_sheet.tee_sheet_booking_details.181")));
      updateState<ICheckInState>({ cart: redeemRes.data }, StateType.CheckInState);
      closeRedeemRainCheck();
    } else {
      return;
    }
  };

  const handleNoShowCheckbox = (selectedId: number) => {
    const foundIndex = noShow.bookingParticipantIds.indexOf(selectedId);

    if (foundIndex === -1) {
      updateState<INoShowState>(
        {
          bookingParticipantIds: [...noShow.bookingParticipantIds, selectedId],
          quantity: noShow.quantity + 1,
        },
        StateType.NoShowState,
      );
    } else {
      updateState<INoShowState>(
        {
          bookingParticipantIds: noShow.bookingParticipantIds.filter(val => val !== selectedId),
          quantity: noShow.quantity - 1,
        },
        StateType.NoShowState,
      );
    }
  };

  const handleNoShowDropDownChange = (value: any, extraValues: any) => {
    updateState<INoShowState>(
      { greenFeeProductID: value, greenFeeProductTitle: extraValues.title, greenFeeProductPrice: extraValues.price },
      StateType.NoShowState,
    );
  };

  //Get customer's Stripe payment method id
  const handleCustomerPaymentMethod = async (slot: ISlot) => {
    if (slot?.booking?.customer_payment_method_id === null || slot?.booking?.customer_id === null) {
      updateState<INoShowState>({ paymentMethodID: null }, StateType.NoShowState);
      return false;
    }
    const paymentMethodRes = await GetCustomerPaymentMethod(
      { id: slot?.booking?.customer_payment_method_id, customer_id: slot?.booking?.customer_id },
      true,
    );

    if (paymentMethodRes.data.length > 0) {
      updateState<INoShowState>(
        { paymentMethodID: paymentMethodRes.data[0].stripe_payment_method_id },
        StateType.NoShowState,
      );
    }
    return true;
  };

  // Confirm Stripe payment intent
  const confirmCardPaymentNoShow = async (
    clientSecret: string,
    paymentMethodID: string,
    trans_id: number,
    orderId: number,
  ) => {
    if (!stripe) {
      return;
    }

    dispatch(enqueue());
    const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: paymentMethodID,
    });
    dispatch(dequeue());

    if (stripeError) {
      dispatch(showError(stripeError.message));
      return;
    }

    //Capture the payment
    const captureRes = await PutCapture({ payment_intent_id: paymentIntent.id, transaction_id: trans_id }, true);

    if (captureRes.status !== StatusCode.OK) {
      dispatch(showError(captureRes.message));
      return;
    }

    for (const bookingParticipantId of noShow.bookingParticipantIds) {
      const putCustomerRes = await PutBookingParticipant(
        { id: bookingParticipantId, no_show_paid: true, order_id: orderId },
        true,
      );
      if (putCustomerRes.status !== StatusCode.OK) {
        dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.150")));
      }
    }

    void dispatch(selectTeeTime(parseInt(teetimeId), true));
    dispatch(showSuccess(t("secure.facility.tee_sheet.tee_sheet_booking_details.170")));
    updateState<INoShowState>(
      { noShowActive: false, bookingParticipantIds: [], quantity: 0, paymentMethodID: null },
      StateType.NoShowState,
    );
  };

  //Charging the customer for no show is handled here
  const handleNoShowCharge = () => {
    if (noShow.quantity === 0 || noShow.greenFeeProductID === null || noShow.customerID === null) {
      return;
    }

    void (async () => {
      try {
        //Create order
        const postOrderRes = await PostOrder({ customer_id: noShow.customerID }, true);
        if (postOrderRes.status === StatusCode.OK) {
          try {
            //Create line item order
            const postLineItemOrderRes = await PostLineItemsToOrder(
              {
                order_id: postOrderRes.data.id,
                variant_id: noShow.greenFeeProductID,
                quantity: noShow.quantity,
              },
              true,
            );
            if (postLineItemOrderRes.status === StatusCode.OK) {
              try {
                //Create transaction
                const postTransactionRes = await PostTransaction(
                  {
                    kind: "sale",
                    amount: postLineItemOrderRes.data.total_price,
                    order_id: postOrderRes.data.id,
                    payment_option_id: facilityStore.paymentOptions?.find(
                      paymentOption => paymentOption.payment_method === "manual_card",
                    )?.id,
                  },
                  true,
                );
                if (postTransactionRes.status === StatusCode.OK) {
                  //Confirm the payment intent after succesfully creating a transaction
                  void confirmCardPaymentNoShow(
                    postTransactionRes.data.client_secret,
                    noShow.paymentMethodID,
                    postTransactionRes.data.id,
                    postOrderRes.data.id,
                  );
                } else {
                  dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.007")));
                  return;
                }
              } catch (e) {
                dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.007")));
                console.log(e);
              }
            }
          } catch (e) {
            dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.008")));
            console.log(e);
          }
        }
      } catch (e) {
        dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.009")));
        console.log(e);
      }
    })();
  };

  const handleCancelBookingParticipant = async (slot: ISlot) => {
    try {
      const putCancelRes = await PutCancelParticipant({ id: slot.booking_participant.id }, true);

      if (putCancelRes.status === StatusCode.OK) {
        void dispatch(selectTeeTime(parseInt(teetimeId), true));
        dispatch(showSuccess("Successfully cancelled participant"));
      }

      if (putCancelRes.status !== StatusCode.OK) {
        dispatch(showError(putCancelRes.data.message));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleNoShowBookingParticipant = async (slot: ISlot, type: "charge" | "defer" | "weather") => {
    const id = slot.tee_time_booking_participant_id;

    try {
      const putBookingParticipantRes = await PutBookingParticipantNoShow(
        {
          id,
          no_show_type: type,
        },
        true,
      );

      if (putBookingParticipantRes.status === StatusCode.OK) {
        void dispatch(selectTeeTime(parseInt(teetimeId), true)); //refreshes the page
        dispatch(showSuccess(t("secure.facility.tee_sheet.tee_sheet_booking_details.010") + type.replace(/_/g, " ")));
      } else {
        dispatch(
          showError(
            typeof putBookingParticipantRes?.data === "string"
              ? putBookingParticipantRes?.data
              : t("secure.facility.tee_sheet.tee_sheet_booking_details.139") + type.replace(/_/g, " "),
          ),
        );
      }
    } catch (e) {
      console.log("handleNoShow ERR:", e);
    }
  };

  async function handleManualCheckIn() {
    const checkInRes = await PutBookingParticipant(
      {
        id: checkInState.manualCheckInSlot.booking_participant.id,
        check_in_status: "checked",
      },
      true,
    );

    if (checkInRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.173")));
    }

    updateState<ICheckInState>(
      {
        manualCheckInVisible: false,
        manualCheckInSlot: null,
      },
      StateType.CheckInState,
    );

    void refreshTeeTime();
  }

  async function handleChargeNoShowModal(slot: ISlot) {
    //Get customer's payment method
    const paymentMethodOnBooking = await handleCustomerPaymentMethod(slot);
    if (!paymentMethodOnBooking) {
      updateState<INoShowState>(
        {
          noPaymentMethodModal: true,
        },
        StateType.NoShowState,
      );
      return;
    }
    if (noShow.greenFeeProducts?.length === 0) {
      void (await loadGreenFeeProducts());
    }

    updateState<INoShowState>(
      {
        noShowActive: true,
        teeTimeBookingId: slot.booking_participant.tee_time_booking_id,
        customerID: slot.booking.customer_id,
      },
      StateType.NoShowState,
    );
    void dispatch(loadPaymentOptions());
  }

  const handleInitiateRedeemTicket = async (slot: ISlot) => {
    const participant_id = slot.tee_time_booking_participant_id;

    const ticketRes = await GetTicketStub(
      {
        customer_id: slot.booking_participant.customer_id,
        status: "valid",
        extended: true,
      },
      true,
    );
    ReactDOM.unstable_batchedUpdates(() => {
      updateState<IMainState>({ selectedBooking: slot?.booking }, StateType.MainState);
      updateState<ICheckInState>(
        { redeemTicketActive: true, availableTickets: ticketRes.data },
        StateType.CheckInState,
      );
      updateState<IAddItemState>({ participant_id }, StateType.AddItemState);
    });
  };

  const handleTicketRedeem = async (code: string) => {
    const getTicketValidateRes = await GetTicketValidate({ code }, true);

    if (getTicketValidateRes.status === StatusCode.OK) {
      const getTicketValidateData = getTicketValidateRes.data;
      updateState<ICheckInState>(
        {
          getTicketValidateData,
          ticketValidSuccess: true,
        },
        StateType.CheckInState,
      );
    }

    if (getTicketValidateRes.status === StatusCode.BAD_REQUEST) {
      updateState<ICheckInState>({ ticketValidWarning: true }, StateType.CheckInState);
    }
    void handleCloseSearchTicket();
  };

  const handleSearchTicket = async () => {
    let codes: string[] = [];

    // Use searched for codes
    if (checkInState.redeemTicketSelected === 0) {
      codes = checkInState.selectedTickets
        ?.filter(ticket => ticket.status === "valid")
        ?.map(ticket => {
          return ticket.code;
        });
    }

    // Use customer codes
    if (checkInState.redeemTicketSelected === 1) {
      codes = checkInState.selectedCustomerTickets
        ?.filter(ticket => ticket.status === "valid")
        ?.map(ticket => {
          return ticket.code;
        });
    }

    if (codes.length < 1) {
      dispatch(showError("There are no valid tickets to redeem"));
      return;
    }

    const redeemTicketsRes = await PutTicketStubRedeemMultiple({ codes: codes }, true);

    if (redeemTicketsRes.status !== StatusCode.OK) {
      dispatch(showError("Error redeeming ticket stubs"));
      return;
    }

    const selectedBookingParticipantIndex = teeSheetStore?.selectedTeeTime?.slots?.findIndex(
      slot => slot?.booking_participant?.id == addItemState?.participant_id,
    );

    const redeemedStubs = redeemTicketsRes.data;

    const bookingSlots = [...teeSheetStore?.selectedTeeTime?.slots];
    //Order the slots so that the next participant after the current one has the ticket applied, then continue from the first participant in the booking
    const slotsOrderUpdate = [
      ...bookingSlots.slice(selectedBookingParticipantIndex, bookingSlots?.length),
      ...bookingSlots.slice(0, selectedBookingParticipantIndex),
    ];
    //Filter out the participants that have already paid and remove the slots that are not in the current booking
    const filteredSlots = slotsOrderUpdate?.filter(
      slot =>
        slot.status === "booked" &&
        slot?.booking_participant?.order_financial_status !== "paid" &&
        slot?.booking?.id === state.selectedBooking?.id,
    );

    //Set the participant ids in an array
    const participantIds = filteredSlots?.map(slot => slot?.booking_participant?.id);

    for (let i = 0; i < redeemedStubs.length; i++) {
      const greenFeeProduct = redeemedStubs[i].redemption_product?.green_fee || null;
      const powerCartProduct = redeemedStubs[i].redemption_product?.power_cart || null;

      const greenFeeQuantity = greenFeeProduct?.pivot?.quantity;
      const powerCartQuantity = powerCartProduct?.pivot?.quantity;

      if (
        ((greenFeeProduct && greenFeeQuantity >= 1) || (powerCartProduct && powerCartQuantity >= 1)) &&
        participantIds?.length > 0
      ) {
        //Get the max quantity between the green fee & power cart
        const higherQuantity =
          greenFeeQuantity && powerCartQuantity
            ? Math.max(greenFeeQuantity, powerCartQuantity)
            : greenFeeQuantity
            ? greenFeeQuantity
            : powerCartQuantity;
        let currentGreenFeeAmount = greenFeeQuantity;
        let currentPowerCartAmount = powerCartQuantity;
        for (const i of [...Array(higherQuantity).keys()]) {
          const putBookingParticipantRes = await PutBookingParticipant(
            {
              id: participantIds[0],
              green_fee_variant_id:
                greenFeeProduct && currentGreenFeeAmount >= 1 ? greenFeeProduct?.pivot?.variant_id : undefined,
              power_cart_variant_id:
                powerCartProduct && currentPowerCartAmount >= 1 ? powerCartProduct?.pivot?.variant_id : undefined,
            },
            true,
          );
          if (putBookingParticipantRes.status !== StatusCode.OK) {
            dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.182")));
            return;
          }
          //Remove the first booking participant in the array to move onto the next participant
          participantIds.shift();
          //Decrement the green fee / power cart quantity
          currentGreenFeeAmount -= 1;
          currentPowerCartAmount -= 1;
          //If the max quantity between the green fee / power cart is reached or there are no participants left to apply the ticket,
          //break out of the loop and redeem the ticket and close the modal
          if (i === higherQuantity - 1 || participantIds?.length === 0) {
            break;
          }
        }
      }
    }

    dispatch(showSuccess(`${codes.length > 1 ? "Tickets" : "Ticket"} redeemed successfully`));

    void closeRedeemTicketModal();

    handleCloseSearchTicket();

    updateState<ICheckInState>(
      {
        ticketRedeemSuccess: true,
      },
      StateType.CheckInState,
    );

    // Reload the tee times
    void dispatch(selectTeeTime(parseInt(teetimeId), true));
  };

  function closeRedeemTicketModal() {
    ReactDOM.unstable_batchedUpdates(() => {
      updateState<ICheckInState>(
        {
          ticketValidSuccess: false,
          redeem_ticket_code: "",
          getTicketValidateData: {},
          ticketRedeemSuccess: false,
        },
        StateType.CheckInState,
      );

      updateState<IAddItemState>(
        {
          participant_id: null,
        },
        StateType.AddItemState,
      );

      updateState<ICheckInState>(
        {
          ticketRedeemSuccess: true,
        },
        StateType.CheckInState,
      );
    });
  }

  const handleTicketValidSuccess = async () => {
    const selectedBookingParticipantIndex = teeSheetStore?.selectedTeeTime?.slots?.findIndex(
      slot => slot?.booking_participant?.id == addItemState?.participant_id,
    );
    if (selectedBookingParticipantIndex === -1) {
      console.log("Cannot find selected booking participant slot");
      return;
    }

    const bookingSlots = [...teeSheetStore?.selectedTeeTime?.slots];
    //Order the slots so that the next participant after the current one has the ticket applied, then continue from the first participant in the booking
    const slotsOrderUpdate = [
      ...bookingSlots.slice(selectedBookingParticipantIndex, bookingSlots?.length),
      ...bookingSlots.slice(0, selectedBookingParticipantIndex),
    ];
    //Filter out the participants that have already paid and remove the slots that are not in the current booking
    const filteredSlots = slotsOrderUpdate?.filter(
      slot =>
        slot.status === "booked" &&
        slot?.booking_participant?.order_financial_status !== "paid" &&
        slot?.booking?.id === state.selectedBooking?.id,
    );

    //Set the participant ids in an array
    const participantIds = filteredSlots?.map(slot => slot?.booking_participant?.id);

    const greenFeeProduct = checkInState.getTicketValidateData.redemption_products?.green_fee || null;
    const powerCartProduct = checkInState.getTicketValidateData.redemption_products?.power_cart || null;

    const greenFeeQuantity = greenFeeProduct?.pivot?.quantity;
    const powerCartQuantity = powerCartProduct?.pivot?.quantity;

    if (
      ((greenFeeProduct && greenFeeQuantity >= 1) || (powerCartProduct && powerCartQuantity >= 1)) &&
      participantIds?.length > 0
    ) {
      //Get the max quantity between the green fee & power cart
      const higherQuantity =
        greenFeeQuantity && powerCartQuantity
          ? Math.max(greenFeeQuantity, powerCartQuantity)
          : greenFeeQuantity
          ? greenFeeQuantity
          : powerCartQuantity;
      let currentGreenFeeAmount = greenFeeQuantity;
      let currentPowerCartAmount = powerCartQuantity;
      for (const i of [...Array(higherQuantity).keys()]) {
        const putBookingParticipantRes = await PutBookingParticipant(
          {
            id: participantIds[0],
            green_fee_variant_id:
              greenFeeProduct && currentGreenFeeAmount >= 1 ? greenFeeProduct?.pivot?.variant_id : undefined,
            power_cart_variant_id:
              powerCartProduct && currentPowerCartAmount >= 1 ? powerCartProduct?.pivot?.variant_id : undefined,
          },
          true,
        );
        if (putBookingParticipantRes.status !== StatusCode.OK) {
          dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.182")));
          return;
        }
        //Remove the first booking participant in the array to move onto the next participant
        participantIds.shift();
        //Decrement the green fee / power cart quantity
        currentGreenFeeAmount -= 1;
        currentPowerCartAmount -= 1;
        //If the max quantity between the green fee / power cart is reached or there are no participants left to apply the ticket,
        //break out of the loop and redeem the ticket and close the modal
        if (i === higherQuantity - 1 || participantIds?.length === 0) {
          break;
        }
      }
    }

    //Redeem ticket even if there is no green fee / power cart returned on the ticket
    //Some facilities like to redeem the ticket then manually apply the prepaid items
    const putRedeemRes = await PutRedeem(checkInState.getTicketValidateData.stub.code, true);
    if (putRedeemRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.162")));
    } else {
      dispatch(showSuccess(t("secure.facility.tee_sheet.tee_sheet_booking_details.161")));
    }

    void closeRedeemTicketModal();

    handleCloseSearchTicket();

    // Reload the tee times
    void dispatch(selectTeeTime(parseInt(teetimeId), true));
  };

  const handleTicketValidWarning = () => {
    // handle warning
    ReactDOM.unstable_batchedUpdates(() => {
      updateState<IMainState>({ selectedBooking: null }, StateType.MainState);
      updateState<ICheckInState>({ ticketValidWarning: false }, StateType.CheckInState);
    });
  };

  const handleCloseSearchTicket = () => {
    updateState<ICheckInState>(
      {
        redeemTicketActive: false,
        redeem_ticket_code: "",
        redeemTicketSelected: 0,
        selectedTickets: [],
      },
      StateType.CheckInState,
    );

    setTicketState(prevState => ({ ...prevState, tickets: [], ticketString: "" }));
  };

  function handleChargeNoShowModalClose() {
    updateState<INoShowState>(
      {
        noShowActive: false,
        bookingParticipantIds: [],
        paymentMethodID: null,
        quantity: 0,
      },
      StateType.NoShowState,
    );
  }

  async function loadGreenFeeProducts() {
    const items = await GetProduct({ type: "No Show Green Fee", extended: true }, true);
    if (items.status !== StatusCode.OK) {
      dispatch(showError(items.message));
      return;
    }
    updateState<INoShowState>({ greenFeeProducts: items.data }, StateType.NoShowState);
  }

  function handleChangePlayerSelection(id: number, customer: ICustomer) {
    setChangePlayerState(prevState => ({ ...prevState, selectedCustomer: customer }));
  }

  function handleShowChangePlayer(bookingParticipant: IBookingParticipant) {
    setChangePlayerState(prevState => ({
      ...prevState,
      showChangePlayer: true,
      customerQuery: "",
      playerSearchResult: [],
      bookingParticipant,
    }));
  }

  function changePlayerHandleCustomerSearch(query: string) {
    setChangePlayerState(prevState => ({ ...prevState, customerQuery: query }));
  }

  async function changeSelectedPlayer() {
    const bookingParticipantRes = await PutBookingParticipant(
      { id: changePlayerState.bookingParticipant.id, customer_id: changePlayerState.selectedCustomer.id },
      true,
    );
    if (bookingParticipantRes.status !== StatusCode.OK) {
      dispatch(showError(bookingParticipantRes.message));
      return;
    }
    dispatch(showSuccess("Successfully changed player"));
    setChangePlayerState(prevState => ({
      ...prevState,
      bookingParticipant: null,
      showChangePlayer: false,
      playerSearchResult: [],
      customerQuery: "",
      selectedCustomer: null,
    }));
    void dispatch(selectTeeTime(parseInt(teetimeId), true));
  }

  function closeChangePlayer() {
    closeCreateNewCustomer();
    setChangePlayerState(prevState => ({
      ...prevState,
      showNewPlayer: false,
      bookingParticipant: null,
      showChangePlayer: false,
      customerQuery: "",
      playerSearchResult: [],
      selectedCustomer: null,
    }));
  }

  function closeCreateNewCustomer() {
    setChangePlayerState(prevState => ({
      ...prevState,
      showNewPlayer: false,
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
    }));
  }

  async function createNewCustomer(customerInfo: ICustomerInfoState) {
    const inputError =
      customerInfo.firstName === "" ||
      customerInfo.lastName === "" ||
      (customerInfo.emailAddress === "" && customerInfo.phoneNumber === "");

    if (inputError) {
      dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.011")));
    }

    const customerRes = await PostCustomer(
      {
        first_name: customerInfo.firstName,
        last_name: customerInfo.lastName,
        phone: customerInfo.phoneNumber || null,
        email: customerInfo.emailAddress || null,
        state: "disabled",
      },
      true,
    );

    if (customerRes.status !== StatusCode.OK) {
      dispatch(showError(customerErrorMessage(t, customerRes?.data?.message)));
      return;
    }
    setChangePlayerState(prevState => ({
      ...prevState,
      showNewPlayer: false,
      selectedCustomer: customerRes.data.data,
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
    }));

    dispatch(showSuccess(customerRes.data.message));
  }

  async function changePlayerSearch(mounted: boolean, golferSearchQuery: string) {
    try {
      if (golferSearchQuery === "") {
        if (mounted) {
          setChangePlayerState(prevState => ({ ...prevState, playerSearchResult: [] }));
        }
        return;
      } else {
        setChangePlayerState(prevState => ({ ...prevState, customerSearching: true }));
        const customerRes = await GetCustomer({ search: golferSearchQuery }, false);
        if (customerRes.status !== StatusCode.OK) {
          setChangePlayerState(prevState => ({ ...prevState, customerSearching: false, playerSearchResult: [] }));
          return;
        } else if (mounted) {
          setChangePlayerState(prevState => ({
            ...prevState,
            customerSearching: false,
            playerSearchResult: customerRes.data,
          }));
        }
      }
    } catch (error) {
      console.log("err", error);
    }
    return;
  }

  const getDateTime = () => {
    const date = teeSheetStore.selectedTeeTime.date.split("-").join("/");
    const dateTime = new Date(date + ", " + teeSheetStore.selectedTeeTime.start_time);
    //Get the time 30 minutes before the current start time
    dateTime.setMinutes(dateTime.getMinutes() - 30);
    let hours = dateTime.getHours().toString();
    if (hours.length === 1) {
      hours = "0" + hours;
    }
    const adjustedTime = hours + ":" + dateTime.getMinutes().toString();
    return [date, adjustedTime];
  };

  const loadGroupPayTeeTimes = async (cancelToken: CancelToken) => {
    if (teeSheetStore.selectedTeeTime) {
      const dateTime = getDateTime();
      const teeTimesRes = await GetTeeTime(
        { date: dateTime[0], start_time: dateTime[1], extended: true },
        false,
        cancelToken,
      );
      if (teeTimesRes.status !== StatusCode.OK) {
        if (cancelToken && cancelToken.reason) {
          return;
        }
        dispatch(showError(teeTimesRes.message));
        return;
      }
      const teeTimes = teeTimesRes.data;
      const groupPayTeeTimes = teeTimes.filter(filteredTeeTime => {
        if (filteredTeeTime?.blocked_type === "crossover") {
          return false;
        } else {
          //Only return bookings that contain booking participants
          const validTeeTime = filteredTeeTime.slots.some(
            slot => slot.status === "booked" && slot.booking_participant.check_in_status === "unchecked",
          );
          return validTeeTime && filteredTeeTime.start_time !== teeSheetStore.selectedTeeTime.start_time;
        }
      });
      updateState<IGroupPayState>({ groupPayTeeTimes }, StateType.GroupPayState);
    }
  };

  async function refreshTeeTime(refreshParticipantFees?: boolean, globalLoader?: boolean) {
    const teetimeRes = await GetTeeTime(
      {
        id: Number(teetimeId),
        locks: true,
        extended: true,
        "extended-bookings": true,
        turn_tee_time: true,
      },
      !!globalLoader,
    );

    if (teetimeRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.183")));
      updateState<IOrderComplete>({ loading: false }, StateType.OrderComplete);
      return;
    }

    const updatedTeeTime = teetimeRes.data[0];
    dispatch(selectTeeTimeSuccess(updatedTeeTime));

    //Reset the participant paid fees values
    if (refreshParticipantFees) {
      void resetParticipantFees(teetimeRes?.data[0]);
      updateState<IOrderComplete>(
        {
          loading: false,
        },
        StateType.OrderComplete,
      );
    }
  }

  const handleBackToCheckIn = () => {
    ReactDOM.unstable_batchedUpdates(() => {
      updateState<IOrderProgess>({ orderStep: OrderProgress.NEW_ORDER }, StateType.OrderProgress);
      updateState<ICheckInState>(
        {
          order: null,
          cart: null,
        },
        StateType.CheckInState,
      );
      updateState<IOrderComplete>(
        {
          isOpen: false,
          clearOnClose: true,
          loading: false,
          transactionComplete: false,
        },
        StateType.OrderComplete,
      );
    });
  };

  async function loadCartWaiver(customer_id: number) {
    const waiverRes = await GetCartWaiver(
      { customer_id: customer_id, tee_time_id: teeSheetStore.selectedTeeTime.id },
      true,
    );

    if (waiverRes.status === StatusCode.OK) {
      window.open().document.write(waiverRes.data);
    } else {
      dispatch(showError("Cart waiver has not been created")); // TODO: Translation
    }
  }

  function onCancelAddGreenFeeModal() {
    updateState<IAddItemState>({ greenFeeActive: false }, StateType.AddItemState);
    void cancelAddItem();
  }

  function onCancelAddPowerCartModal() {
    updateState<IAddItemState>({ powerCartFeeActive: false }, StateType.AddItemState);
    void cancelAddItem();
  }

  function onClickProduct(product: IProduct, product_type: "Green Fee" | "Power Cart") {
    const variants = product?.variants?.filter(variant => variant?.facility_access === true);

    if (variants?.length > 1) {
      updateState<IAddItemState>(
        {
          productState: {
            ...addItemState.productState,
            variantSheetOpen: true,
            variants,
            selectedProduct: product,
          },
        },
        StateType.AddItemState,
      );
    } else {
      if (variants?.[0]) {
        void handleAddQuickItem(variants[0], product_type, product);
      } else {
        dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.187")));
      }
    }
  }

  function onCancelAddVariantModal() {
    updateState<IAddItemState>(
      {
        productState: {
          variantSheetOpen: false,
          variants: [],
          selectedProduct: undefined,
        },
      },
      StateType.AddItemState,
    );
  }

  async function addAllGroupPay(slots: Array<ISlot>) {
    const filteredSlots = slots?.filter(slot => slot?.booking_participant?.check_in_status === "unchecked");
    const newParticipants: TParticipantPaidFees = [];
    //Add participants to fees list if the participants are not already in the list
    filteredSlots?.forEach(slot => {
      const foundParticipant = participantPaidFees?.current?.find(
        participant => participant?.booking_participant_id === slot?.booking_participant?.id,
      );
      if (!foundParticipant) {
        newParticipants.push({
          booking_participant_id: slot?.booking_participant?.id,
          green_fee: slot?.booking_participant?.green_fee_paid,
          power_cart_fee: slot?.booking_participant?.power_cart_paid,
          green_fee_loading: false,
          power_cart_fee_loading: false,
        });
      }
    });
    //Initialize participant fees
    if (newParticipants?.length > 0) {
      const currentFees = [...participantPaidFees?.current, ...newParticipants];
      void initializeParticipantPaidFees(currentFees);
    }

    const { greenFeeCounts, powerCartCounts, feeList } = determineFeeCounts(filteredSlots);
    const variants = [...greenFeeCounts, ...powerCartCounts];

    if (variants?.length > 0 && checkInState?.cart) {
      //Set participant fees spinners
      participantPaidFees?.current?.forEach(participant => {
        const foundParticipant = filteredSlots?.find(
          slot => slot?.booking_participant?.id === participant?.booking_participant_id,
        );
        if (foundParticipant) {
          void updateParticipantPaidFees({
            ...participant,
            green_fee_loading: participant?.green_fee
              ? false
              : !!foundParticipant?.booking_participant?.green_fee_variant_id,
            power_cart_fee_loading: participant?.power_cart_fee
              ? false
              : !!foundParticipant?.booking_participant?.power_cart_variant_id,
          });
        }
      });

      updateState<IOrderProgess>({ orderStep: OrderProgress.ADDING_FEES }, StateType.OrderProgress);
      //Display loading variants in cart
      variants?.forEach(variant => {
        void addLoadingVariant(
          { id: Number(variant?.variantId), title: variant?.title, quantity: variant?.quantity } as IVariant,
          { title: variant?.productTitle } as IProduct,
        );
      });
      //Set variants param
      const variantsParam = variants?.map(item => ({ variant_id: item?.variantId, quantity: item?.quantity }));

      const postLineItemRes = await PostLineItemToCart(
        {
          cart_id: checkInState.cart.id,
          variants: variantsParam,
        },
        false,
      );
      //Remove loading variants in cart
      variants?.forEach(variant => {
        void removeLoadingVariant(Number(variant?.variantId));
      });

      if (postLineItemRes.status !== StatusCode.OK) {
        dispatch(showError("Error adding items to cart"));
        participantPaidFees?.current?.forEach(participant => {
          void updateParticipantPaidFees({
            ...participant,
            green_fee_loading: false,
            power_cart_fee_loading: false,
            green_fee: participant?.green_fee,
            power_cart_fee: participant?.power_cart_fee,
          });
        });
        updateState<IOrderProgess>({ orderStep: OrderProgress.ORDER_IN_PROGRESS }, StateType.OrderProgress);
        return;
      }

      //Set participant fees
      participantPaidFees?.current?.forEach(participant => {
        const currentParticipant = feeList?.find(
          participantFees => Number(participantFees?.participant_id) === participant?.booking_participant_id,
        );
        if (currentParticipant) {
          void updateParticipantPaidFees({
            ...participant,
            green_fee_loading: false,
            power_cart_fee_loading: false,
            green_fee:
              currentParticipant?.greenFeeChecked !== undefined
                ? currentParticipant?.greenFeeChecked
                : participant?.green_fee,
            power_cart_fee:
              currentParticipant?.powerCartChecked !== undefined
                ? currentParticipant?.powerCartChecked
                : participant?.power_cart_fee,
          });
        }
      });

      //Save cart to state
      ReactDOM.unstable_batchedUpdates(() => {
        updateState<ICheckInState>({ cart: postLineItemRes?.data }, StateType.CheckInState);
        updateState<IOrderProgess>({ orderStep: OrderProgress.ORDER_IN_PROGRESS }, StateType.OrderProgress);
      });
    }
  }

  // Map through slots to return green fees and power cart fees
  function determineFeeCounts(slots: ISlot[]) {
    const greenFeeVariants: Array<{ variantId: number; title: string; productTitle: string; quantity: number }> = [];
    const powerCartVariants: Array<{ variantId: number; title: string; productTitle: string; quantity: number }> = [];
    const participantFeeStatus: FeeStatusType = {};

    slots.map((slot, index) => {
      if (!slot.booking_participant) {
        return;
      }

      const greenFeeAdded = participantPaidFees?.current?.some(item => {
        if (item?.booking_participant_id === slot?.booking_participant?.id) {
          return item.green_fee === true || item?.green_fee_loading;
        }
      });
      const powerCartFeeAdded = participantPaidFees?.current?.some(item => {
        if (item?.booking_participant_id === slot?.booking_participant?.id) {
          return item.power_cart_fee === true || item?.power_cart_fee_loading;
        }
      });

      // Not paid, not previously added green fee
      if (
        !slot.booking_participant.green_fee_paid &&
        !greenFeeAdded &&
        slot.booking_participant.green_fee_product_title
      ) {
        greenFeeVariants.push({
          variantId: slot.booking_participant?.green_fee_variant_id,
          title: slot.booking_participant?.green_fee_variant_title,
          productTitle: slot.booking_participant?.green_fee_product_title,
          quantity: 1,
        });
        //Set participant fee status
        participantFeeStatus[slot.booking_participant.id] = {
          ...participantFeeStatus[slot.booking_participant.id],
          greenFeeChecked: true,
        };
      }

      // Not paid, not previously added power cart fee
      if (
        !slot.booking_participant.power_cart_paid &&
        !powerCartFeeAdded &&
        slot.booking_participant.power_cart_product_title
      ) {
        powerCartVariants.push({
          variantId: slot.booking_participant?.power_cart_variant_id,
          title: slot.booking_participant?.power_cart_variant_title,
          productTitle: slot.booking_participant?.power_cart_product_title,
          quantity: 1,
        });

        participantFeeStatus[slot.booking_participant.id] = {
          ...participantFeeStatus[slot.booking_participant.id],
          powerCartChecked: true,
        };
      }
    });

    const feeStatusObj = Object.entries(participantFeeStatus).map(key => {
      return {
        participant_id: key[0],
        greenFeeChecked: key[1].greenFeeChecked,
        powerCartChecked: key[1].powerCartChecked,
      };
    });

    return {
      greenFeeCounts: greenFeeVariants,
      powerCartCounts: powerCartVariants,
      feeList: feeStatusObj,
    };
  }

  async function createCart() {
    if (!checkInState.cart?.id || orderProgress.orderStep === OrderProgress.NEW_ORDER) {
      updateState<IOrderProgess>({ orderStep: OrderProgress.CREATING_CART }, StateType.OrderProgress);
      const postCartRes = await PostCart(null, false);
      if (postCartRes.status === StatusCode.OK) {
        ReactDOM.unstable_batchedUpdates(() => {
          updateState<ICheckInState>({ cart: postCartRes.data, order: undefined }, StateType.CheckInState);
          updateState<IOrderProgess>(
            {
              orderStep: OrderProgress.ORDER_IN_PROGRESS,
            },
            StateType.OrderProgress,
          );
        });
      } else {
        dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.189")));
        updateState<IOrderProgess>({ orderStep: OrderProgress.NEW_ORDER }, StateType.OrderProgress);
      }
    }
  }

  function togglePowerCartVisible(visible: boolean) {
    updateState<IMainState>({ powerCartModalVisible: visible }, StateType.MainState);
  }

  const tabs = [
    {
      id: "redeem-ticket-scan",
      content: t("secure.facility.tee_sheet.tee_sheet_booking_details.040"),
    },
    {
      id: "redeem-ticker-available",
      content: t("secure.facility.tee_sheet.tee_sheet_booking_details.041"),
    },
  ];

  const handleSearchTicketTabChange = (value: number) => {
    updateState<ICheckInState>({ redeemTicketSelected: value }, StateType.CheckInState);
  };

  function handleTeeTimeCancelledPopupRefresh() {
    void dispatch(selectTeeTime(teeSheetStore.selectedTeeTime.id, false));
    updateState<IMainState>({ teeTimeCancelledPopupVisible: false }, StateType.MainState);
  }

  function filterGreenFees(event: any) {
    const keyword = event.target.value;

    if (keyword !== "") {
      const results = addItemState.itemSearchResult.filter(item => {
        //return item.title.toLowerCase().startsWith(keyword.toLowerCase());

        return item.title.toLowerCase().includes(keyword.toLowerCase());
      });

      setFoundGreenFees(results);
    } else {
      // If the text field is empty, remove from list
      setFoundGreenFees([]);
    }

    updateState<IAddItemState>({ greenfeeSearchQuery: keyword }, StateType.AddItemState);
  }

  function filterCartItems(event: any) {
    const keyword = event.target.value;

    if (keyword !== "") {
      const results = addItemState.itemSearchResult.filter(item => {
        //return item.title.toLowerCase().startsWith(keyword.toLowerCase());

        return item.title.toLowerCase().includes(keyword.toLowerCase());
      });

      setFoundCartItems(results);
    } else {
      // If the text field is empty, remove from list
      setFoundCartItems([]);
    }

    updateState<IAddItemState>({ cartSearchQuery: keyword }, StateType.AddItemState);
  }

  function alphaSortProducts(products: IProduct[]) {
    const productsCopy: IProduct[] = [...products];

    productsCopy.sort((firstProd: IProduct, secondProd: IProduct) => {
      if (firstProd?.title < secondProd?.title) {
        return -1;
      } else if (firstProd?.title > secondProd?.title) {
        return 1;
      } else {
        return 0;
      }
    });

    return productsCopy;
  }

  async function handleAddAllFees(slots: ISlot[], type: "green_fees" | "power_carts") {
    const { greenFeeCounts, powerCartCounts, feeList } = determineFeeCounts(slots);
    const customerId = !slots[0].booking_participant?.guest ? slots[0]?.booking_participant?.customer_id : null;
    let cart = checkInState?.cart ? checkInState?.cart : null;

    if (
      orderProgress?.orderStep === OrderProgress.CREATING_CART ||
      orderProgress?.orderStep === OrderProgress.ADDING_FEES ||
      (greenFeeCounts.length === 0 && powerCartCounts.length === 0)
    ) {
      return;
    }
    //Set participant fees loaders
    participantPaidFees?.current?.forEach(participant => {
      const currentParticipant = feeList?.find(
        participantFees => Number(participantFees?.participant_id) === participant?.booking_participant_id,
      );
      if (currentParticipant) {
        void updateParticipantPaidFees({
          ...participant,
          green_fee_loading:
            type === "green_fees" ? currentParticipant?.greenFeeChecked : participant?.green_fee_loading,
          power_cart_fee_loading:
            type === "power_carts" ? currentParticipant?.powerCartChecked : participant?.power_cart_fee_loading,
        });
      }
    });

    if (!cart || orderProgress.orderStep === OrderProgress.NEW_ORDER) {
      //Cart doesn't exist, create a cart first
      updateState<IOrderProgess>({ orderStep: OrderProgress.CREATING_CART }, StateType.OrderProgress);
      const postCartRes = await PostCart({ customer_id: customerId }, false);

      if (postCartRes?.status !== StatusCode.OK) {
        dispatch(showError("Error creating cart"));
        participantPaidFees?.current?.forEach(participant => {
          const currentParticipant = feeList?.find(
            participantFees => Number(participantFees?.participant_id) === participant?.booking_participant_id,
          );
          if (currentParticipant) {
            void updateParticipantPaidFees({
              ...participant,
              green_fee_loading: false,
              power_cart_fee_loading: false,
              green_fee: false,
              power_cart_fee: false,
            });
          }
        });
        updateState<IOrderProgess>({ orderStep: OrderProgress.NEW_ORDER }, StateType.OrderProgress);
        return;
      }
      cart = postCartRes?.data;
      // Save cart to state
      updateState<ICheckInState>({ cart: postCartRes?.data, order: undefined }, StateType.CheckInState);
    }

    const variants = type === "green_fees" ? [...greenFeeCounts] : [...powerCartCounts];
    if (variants?.length > 0 && cart) {
      //Display loading variants in cart
      variants?.forEach(variant => {
        void addLoadingVariant(
          { id: Number(variant?.variantId), title: variant?.title, quantity: variant?.quantity } as IVariant,
          { title: variant?.productTitle } as IProduct,
        );
      });
      updateState<IOrderProgess>({ orderStep: OrderProgress.ADDING_FEES }, StateType.OrderProgress);
      const variantsParam = variants?.map(item => ({ variant_id: item?.variantId, quantity: item?.quantity }));
      const lineItemRes = await PostLineItemToCart(
        {
          cart_id: cart?.id,
          variants: variantsParam,
        },
        false,
      );
      //Remove loading variants in cart
      variants?.forEach(variant => {
        void removeLoadingVariant(Number(variant?.variantId));
      });

      if (lineItemRes?.status !== StatusCode.OK) {
        dispatch(showError("Error adding line item to cart"));
        participantPaidFees?.current?.forEach(participant => {
          const currentParticipant = feeList?.find(
            participantFees => Number(participantFees?.participant_id) === participant?.booking_participant_id,
          );
          if (currentParticipant) {
            void updateParticipantPaidFees({
              ...participant,
              green_fee_loading: false,
              power_cart_fee_loading: false,
              green_fee: false,
              power_cart_fee: false,
            });
          }
        });
        updateState<IOrderProgess>({ orderStep: OrderProgress.NEW_ORDER }, StateType.OrderProgress);
        return;
      }

      //Set participant fees
      participantPaidFees?.current?.forEach(participant => {
        const currentParticipant = feeList?.find(
          participantFees => Number(participantFees?.participant_id) === participant?.booking_participant_id,
        );
        if (currentParticipant) {
          void updateParticipantPaidFees({
            ...participant,
            green_fee_loading: false,
            power_cart_fee_loading: false,
            green_fee:
              type === "green_fees" && currentParticipant?.greenFeeChecked !== undefined
                ? currentParticipant?.greenFeeChecked
                : participant?.green_fee,
            power_cart_fee:
              type === "power_carts" && currentParticipant?.powerCartChecked !== undefined
                ? currentParticipant?.powerCartChecked
                : participant?.power_cart_fee,
          });
        }
      });

      //Save cart to state
      ReactDOM.unstable_batchedUpdates(() => {
        updateState<ICheckInState>({ cart: lineItemRes?.data }, StateType.CheckInState);
        updateState<IOrderProgess>({ orderStep: OrderProgress.ORDER_IN_PROGRESS }, StateType.OrderProgress);
      });
    } else {
      if (cart) {
        updateState<IOrderProgess>({ orderStep: OrderProgress.ORDER_IN_PROGRESS }, StateType.OrderProgress);
      } else {
        updateState<IOrderProgess>({ orderStep: OrderProgress.NEW_ORDER }, StateType.OrderProgress);
      }
    }
  }

  async function handleCheckParticipants(order: IOrder) {
    const participantsToCheckIn = [...participantPaidFees?.current]
      ?.filter(participant => participant?.green_fee || participant?.power_cart_fee)
      ?.map(participant => ({
        booking_participant_id: participant?.booking_participant_id,
        green_fee_paid: participant?.green_fee,
        power_cart_paid: participant?.power_cart_fee,
      }));

    if (order && participantsToCheckIn?.length > 0) {
      const checkInRes = await PutCheckParticipantAll(
        { booking_participants: participantsToCheckIn, order_id: order?.id },
        false,
      );
      if (checkInRes?.status !== StatusCode?.OK) {
        dispatch(showError("Error checking in players"));
        return;
      }
      void refreshTeeTime(true);
    }
  }

  function resetParticipantFees(teeTime: ITeeTime) {
    const participants = { ...teeTime }?.slots
      ?.map(slot =>
        slot?.booking_participant
          ? {
              booking_participant_id: slot?.booking_participant?.id,
              green_fee: slot?.booking_participant?.green_fee_paid ? undefined : false,
              power_cart_fee: slot?.booking_participant?.power_cart_paid ? undefined : false,
              green_fee_loading: false,
              power_cart_fee_loading: false,
            }
          : null,
      )
      ?.filter?.(filteredSlot => filteredSlot != null);
    void initializeParticipantPaidFees(participants);
  }

  function handleCloseRedeemTicket() {
    void closeRedeemTicketModal();
    handleCloseSearchTicket();

    // Reload the tee times
    void dispatch(selectTeeTime(parseInt(teetimeId), true));
  }

  function handleTicketSearch() {
    // void closeRedeemTicketModal();
    // handleCloseSearchTicket();

    updateState<ICheckInState>({ redeemTicketActive: true, ticketRedeemSuccess: false }, StateType.CheckInState);
  }

  function handleEnterKeydown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      void addTicket();
    }
  }

  function addTicket() {
    if (ticketState.ticketString) {
      const updatedTickets = [...ticketState.tickets];
      const productIncremented = incrementProductQuantity(ticketState.ticketString);
      if (!productIncremented) {
        const foundSkuIndex = updatedTickets?.findIndex(skuObj => skuObj.sku === ticketState.ticketString);
        if (foundSkuIndex !== -1) {
          //Increment quantity of sku found
          const foundSku = updatedTickets[foundSkuIndex];
          updatedTickets.splice(foundSkuIndex, 1, { ...foundSku, quantity: ++foundSku.quantity });
        } else {
          //Add new sku
          const newSku: TSku = { sku: ticketState.ticketString, quantity: 1, searched: false };
          updatedTickets.push(newSku);
        }
        setTicketState(prevState => ({ ...prevState, tickets: updatedTickets }));
      } else {
        setTicketState(prevState => ({ ...prevState, ticketString: "" }));
      }
    }
  }

  function incrementProductQuantity(currentSku: string) {
    const tickets = [...checkInState?.selectedTickets];
    const productInList = false;
    const variantIndex: number = null;
    const ticketIndex = tickets?.findIndex(ticket => {
      // const foundTicketIndex = product?.variants?.findIndex(
      //   variant => variant?.sku === currentSku || variant?.barcode === currentSku,
      // );
      // if (foundVariantIndex !== -1) {
      //   variantIndex = foundVariantIndex;
      //   return true;
      // }
    });
    // if (productIndex !== -1 && variantIndex !== -1) {
    //   // Product already in list, increment the quantity
    //   const foundVariant = products[productIndex]?.variants[variantIndex];
    //   products[productIndex].variants[variantIndex] = { ...foundVariant, quantity: ++foundVariant.quantity };
    //   productInList = true;
    //   setProductState(prevState => ({ ...prevState, selectedProducts: products }));
    // }
    return productInList;
  }

  function handleEnterClick() {
    const skuSearchId = document.getElementById("ticketSearchId");
    skuSearchId.focus();
    void addTicket();
  }

  //For searching tickets by sku
  useEffect(() => {
    const source = axios.CancelToken.source();
    const currentTicket = ticketState?.ticketString?.slice();
    const skuIndex = ticketState?.tickets?.findIndex(sku => sku.sku === currentTicket);
    // setTicketState((prevState) => ({ ...prevState, ticketString: "" }));
    if (skuIndex !== -1) {
      const foundSku = ticketState.tickets[skuIndex];
      // First sku added, search to see if ticket exists
      if (foundSku.quantity === 1 || !foundSku?.searched) {
        setTicketState(prevState => ({ ...prevState, ticketString: "" }));
        void validateTicket(currentTicket);
      }
    }
    return () => {
      source.cancel();
    };
  }, [ticketState?.tickets]);

  async function validateTicket(code: string) {
    const source = axios.CancelToken.source();

    const updatedTickets = cloneDeep(ticketState.tickets);
    //Filter out skus that have already been searched
    const skus = updatedTickets?.filter(filteredSku => !filteredSku?.searched)?.map(sku => sku.sku);
    if (skus.length === 0) {
      console.log("Ticket already searched");
      return;
    }

    const getTicketValidateRes = await GetTicketValidate({ code }, false);

    if (getTicketValidateRes.status !== StatusCode.OK) {
      if (source.token?.reason) {
        return;
      }
    } else if (getTicketValidateRes.data) {
      const ticketStub = getTicketValidateRes?.data?.stub;

      const foundTicketIndex = updatedTickets?.findIndex(sku => sku.sku === ticketStub?.code);

      if (foundTicketIndex !== -1) {
        updatedTickets.splice(foundTicketIndex, 1);
      }

      const updatedSelectedTickets = [...checkInState.selectedTickets];
      const selectedTicketIndex = updatedSelectedTickets?.findIndex(
        currentTicket => currentTicket.id === ticketStub.id,
      );
      if (selectedTicketIndex !== -1) {
        // Ticket already in state
      } else {
        //Product not in state, add it to the array
        updatedSelectedTickets.push(ticketStub);
      }

      setCheckInState(prevState => ({ ...prevState, selectedTickets: updatedSelectedTickets }));
    }

    updatedTickets?.forEach((sku, index) => {
      updatedTickets[index] = { ...updatedTickets[index], searched: true };
    });

    setTicketState(prevState => ({ ...prevState, tickets: updatedTickets }));
  }

  function removeTicketSku(index: number) {
    const updatedSkus = [...ticketState.tickets];
    // Sku cannot be removed if search is in progress
    const allSkusSearched = updatedSkus?.every(sku => sku.searched);
    if (updatedSkus[index] && allSkusSearched) {
      updatedSkus.splice(index, 1);
      setTicketState(prevState => ({ ...prevState, tickets: updatedSkus }));
    }
  }

  function removeTicket(ticketIndex: number) {
    const updatedTickets = [...checkInState?.selectedTickets];
    updatedTickets.splice(ticketIndex, 1);

    setCheckInState(prevState => ({
      ...prevState,
      selectedTickets: updatedTickets,
    }));
  }

  function selectCustomerTicket(index: number, checked: boolean) {
    const updatedTickets = cloneDeep(checkInState.selectedCustomerTickets);

    const ticketToAdd = checkInState.availableTickets[index];

    if (checked) {
      updatedTickets.push(ticketToAdd);
    } else {
      const ticketIndex = updatedTickets.findIndex(stub => stub.id === ticketToAdd.id);

      if (ticketIndex >= 0) {
        updatedTickets.splice(ticketIndex, 1);
      }
    }

    setCheckInState(prevState => ({ ...prevState, selectedCustomerTickets: updatedTickets }));
  }

  function getStubStatus(stub: IStub) {
    switch (stub.status) {
      case "valid":
        return (
          <OverlayTrigger placement="auto" overlay={<Tooltip id="headingTooltip">Ticket validated</Tooltip>}>
            <FontAwesomeIcon className="inventory-counts-success" size="1x" icon={["fas", "circle-check"]} />
          </OverlayTrigger>
        );
      case "redeemed":
        return (
          <OverlayTrigger
            placement="auto"
            overlay={<Tooltip id="headingTooltip">Ticket has already been redeemed</Tooltip>}
          >
            <FontAwesomeIcon className="inventory-counts-error" size="1x" icon={["fas", "circle-xmark"]} />
          </OverlayTrigger>
        );
      case "void":
        return (
          <OverlayTrigger placement="auto" overlay={<Tooltip id="headingTooltip">Ticket has been voided</Tooltip>}>
            <FontAwesomeIcon className="inventory-counts-error" size="1x" icon={["fas", "circle-xmark"]} />
          </OverlayTrigger>
        );
      case "expired":
        return (
          <OverlayTrigger placement="auto" overlay={<Tooltip id="headingTooltip">Ticket is expired</Tooltip>}>
            <FontAwesomeIcon className="inventory-counts-error" size="1x" icon={["fas", "circle-xmark"]} />
          </OverlayTrigger>
        );
    }
  }

  console.log(
    "%csrc/pages/secure/facility/teesheet/newTeeSheetDetails/NewTeeSheetDetails.tsx:2376 groupPayState",
    "color: #007acc;",
    groupPayState,
  );

  return (
    <>
      <div className="booking-details-parent-container ">
        <div className="booking-details-container">
          <div className="mt-23 flex justify-between">
            <Button type="text" className="ml-2" onClick={() => history.push(`/admin/teesheet`)}>
              <FontAwesomeIcon icon={["fal", "long-arrow-left"]} className="mr-1" />
              {t("secure.facility.tee_sheet.tee_sheet_booking_details.042")}
            </Button>

            {!moment(teeSheetStore.selectedTeeTime?.date).isSameOrBefore(moment(), "date") ? (
              <div className="mr-4 h-12">
                <Callout type="warning" title="Caution: Checking in future tee time" content="" />
              </div>
            ) : null}
          </div>

          <div className="mx-4 flex justify-between mt-10">
            <div className="flex flex-column justify-center">
              <span className="text-semibold display-xs">
                {teeSheetStore.selectedTeeTime?.start_time
                  ? convertTime(teeSheetStore.selectedTeeTime?.start_time)
                  : null}
              </span>

              <span className="text-primary-grey text-m">
                {moment(`${teeSheetStore.selectedTeeTime?.date}`).format("MMMM Do, YYYY")}
              </span>

              <span className="text-primary-grey text-m">
                {teeSheetStore.selectedTeeTime?.division_title}
                <FontAwesomeIcon icon={["far", "long-arrow-right"]} className="mr-1 ml-1" />
                {teeSheetStore.selectedTeeTime?.turn_division_title}
              </span>
            </div>
            <ButtonGroup>
              <NavigationDropdownNew
                label={"Options"}
                rightAlign
                sections={[
                  [
                    {
                      label: t("secure.facility.tee_sheet.tee_sheet_booking_details.043"),
                      onClick: () => updateState<IRainCheckState>({ rainCheckActive: true }, StateType.RainCheckState),
                      icon: "umbrella",
                      disabled: !!checkInState.order,
                    },
                    {
                      label: t("secure.facility.tee_sheet.tee_sheet_booking_details.044"),
                      onClick: () =>
                        updateState<IRainCheckState>({ redeemRainCheckActive: true }, StateType.RainCheckState),
                      icon: "ticket",
                      disabled: !!checkInState.order,
                    },
                  ],
                  [
                    {
                      label: t("secure.facility.tee_sheet.tee_sheet_booking_details.045"),
                      onClick: () => updateState<IGroupPayState>({ groupPayActive: true }, StateType.GroupPayState),
                      icon: "user-group",
                      disabled: !!checkInState.order,
                    },
                  ],
                  [
                    {
                      label: "Power Cart Numbers",
                      onClick: () => togglePowerCartVisible(true),
                      icon: "cars",
                      disabled: !!checkInState.order,
                    },
                  ],
                  [
                    {
                      label: "Add All Green Fees",
                      onClick: () => handleAddAllFees(teeSheetStore.selectedTeeTime.slots, "green_fees"),
                      icon: "dollar-sign",
                      disabled:
                        !!checkInState.order ||
                        orderProgress?.orderStep === OrderProgress.CREATING_CART ||
                        orderProgress?.orderStep === OrderProgress.ADDING_FEES,
                    },
                    {
                      label: "Add All Carts",
                      onClick: () => handleAddAllFees(teeSheetStore.selectedTeeTime.slots, "power_carts"),
                      icon: "car-side",
                      disabled:
                        !!checkInState.order ||
                        orderProgress?.orderStep === OrderProgress.CREATING_CART ||
                        orderProgress?.orderStep === OrderProgress.ADDING_FEES,
                    },
                  ],
                ]}
              />
            </ButtonGroup>
          </div>

          <NewTeeSheetBookingDisplay
            initiateEditBookingHandler={handleEditBooking}
            checkInHandler={slot =>
              updateState<ICheckInState>(
                { manualCheckInVisible: true, manualCheckInSlot: slot },
                StateType.CheckInState,
              )
            }
            noShowBookingHandler={handleNoShowBookingParticipant}
            chargeNoShowHandler={handleChargeNoShowModal}
            showChangePlayerHandler={handleShowChangePlayer}
            cancelBookingParticipantHandler={handleCancelBookingParticipant}
            loadCartWaiverHandler={loadCartWaiver}
            initiateRedeemTicketHandler={handleInitiateRedeemTicket}
            initiateAddFeeHandler={initiateSelectParticipantFee}
            order={checkInState.order}
            powerCartModalVisible={state.powerCartModalVisible}
            togglePowerCartModalVisible={togglePowerCartVisible}
            orderProgress={orderProgress}
            addFeeHandler={handleAddFee}
            removeFeeHandler={handleRemoveFee}
          />
        </div>

        {orderProgress.orderStep === OrderProgress.NEW_ORDER ? (
          <div className="teesheet-details-order p-4 flex flex-column items-center justify-center">
            <div className="teesheet-details-order_new_order_icon_container">
              <FontAwesomeIcon icon={["fat", "file-circle-plus"]} className="self-center" />
            </div>

            <div className="teesheet-details-order_new_order_title">
              {t("secure.facility.tee_sheet.tee_sheet_booking_details.022")}
            </div>

            <div className="teesheet-details-order_new_order_description">
              {t("secure.facility.tee_sheet.tee_sheet_booking_details.023")}
            </div>
          </div>
        ) : (
          <div className="booking-details-cart-container">
            <CartMenuNew
              dataOrigin={{
                stateCart: checkInState?.cart,
                updateState: updateState,
                clearStateCart: handleDeleteOrder,
              }}
            />
          </div>
        )}
      </div>

      <Sheet
        open={addItemState.greenFeeActive}
        size="medium"
        closable
        backDropCancel={false}
        title={t("secure.facility.tee_sheet.tee_sheet_booking_details.046")}
        onCancel={onCancelAddGreenFeeModal}
        cancelText={t("secure.facility.tee_sheet.tee_sheet_booking_details.193")}
        okText={t("secure.facility.tee_sheet.tee_sheet_booking_details.140")}
      >
        {addItemState?.participantFeeLoadingMessage ? (
          <div style={{ display: "flex", height: "100%", margin: "auto" }}>
            <div className="text-center m-auto">
              <div style={{ height: "32px" }}>
                <Spin />
              </div>
              <div>{addItemState?.participantFeeLoadingMessage}</div>
            </div>
          </div>
        ) : (
          <>
            <Input
              type="search"
              onChange={filterGreenFees}
              placeholder={t("secure.facility.tee_sheet.tee_sheet_booking_details.048")}
              value={addItemState.greenfeeSearchQuery}
              autoComplete="off"
            />
            <div className="overflow-auto mt-4">
              <Products
                products={alphaSortProducts(
                  foundGreenFees.length > 0
                    ? foundGreenFees
                    : addItemState.itemSearchResult.length > 0
                    ? addItemState.itemSearchResult
                    : [],
                )}
                useGridPositions={false}
                productState={addItemState.productState}
                onClick={product => onClickProduct(product, "Green Fee")}
                onCancel={onCancelAddVariantModal}
                onConfirmVariant={variant => handleAddQuickItem(variant as IVariant, "Green Fee")}
              />
            </div>
          </>
        )}
      </Sheet>

      <Sheet
        open={addItemState.powerCartFeeActive}
        size="medium"
        closable
        title={t("secure.facility.tee_sheet.tee_sheet_booking_details.049")}
        onCancel={onCancelAddPowerCartModal}
        backDropCancel={false}
        cancelText={t("secure.facility.tee_sheet.tee_sheet_booking_details.193")}
        okText={t("secure.facility.tee_sheet.tee_sheet_booking_details.051")}
      >
        {addItemState?.participantFeeLoadingMessage ? (
          <div style={{ display: "flex", height: "100%", margin: "auto" }}>
            <div className="text-center m-auto">
              <div style={{ height: "32px" }}>
                <Spin />
              </div>
              <div>{addItemState?.participantFeeLoadingMessage}</div>
            </div>
          </div>
        ) : (
          <>
            <Input
              type="search"
              onChange={filterCartItems}
              placeholder={t("secure.facility.tee_sheet.tee_sheet_booking_details.052")}
              value={addItemState.cartSearchQuery}
              autoComplete="off"
            />
            <div className="overflow-auto mt-4">
              <Products
                products={alphaSortProducts(
                  foundCartItems.length > 0
                    ? foundCartItems
                    : addItemState.itemSearchResult.length > 0
                    ? addItemState.itemSearchResult
                    : [],
                )}
                useGridPositions={false}
                productState={addItemState.productState}
                onClick={product => onClickProduct(product, "Power Cart")}
                onCancel={onCancelAddVariantModal}
                onConfirmVariant={variant => handleAddQuickItem(variant as IVariant, "Power Cart")}
              />
            </div>
          </>
        )}
      </Sheet>

      <Sheet
        open={noShow.noShowActive}
        size="medium"
        closable
        title={t("secure.facility.tee_sheet.tee_sheet_booking_details.055")}
        onCancel={handleChargeNoShowModalClose}
        onOk={handleNoShowCharge}
        cancelText={t("secure.facility.tee_sheet.tee_sheet_booking_details.056")}
        okText={t("secure.facility.tee_sheet.tee_sheet_booking_details.057")}
        okDisabled={
          noShow.paymentMethodID === null || !noShow.greenFeeProductID || noShow.quantity === 0 ? true : false
        }
        overflow
      >
        <div className="h-full flex flex-col">
          <DataTable
            columns={[
              { label: t("secure.facility.tee_sheet.tee_sheet_booking_details.058"), width: "5%" },
              { label: t("secure.facility.tee_sheet.tee_sheet_booking_details.059"), width: "95%" },
            ]}
          >
            {teeSheetStore.selectedTeeTime?.slots
              ?.filter(
                (filteredSlot: ISlot) =>
                  filteredSlot?.booking_participant?.no_show === true &&
                  filteredSlot?.booking_participant.tee_time_booking_id === noShow.teeTimeBookingId &&
                  !filteredSlot?.booking_participant?.no_show_paid,
              )
              .map((slot: ISlot) => {
                return (
                  <tr
                    key={slot.id}
                    className="clickable"
                    onClick={() => handleNoShowCheckbox(slot.booking_participant?.id)}
                  >
                    <td>
                      <Checkbox
                        size={"medium"}
                        checked={noShow.bookingParticipantIds.some(id => id === slot?.booking_participant?.id)}
                        onChange={e => e.stopPropagation()}
                      />
                    </td>
                    <td>{slot.booking_participant.full_name}</td>
                  </tr>
                );
              })}
          </DataTable>

          <div className="mt-4">
            <Select
              label={t("secure.facility.tee_sheet.tee_sheet_booking_details.060")}
              onChange={(value: any, extraValues: any) => handleNoShowDropDownChange(value, extraValues)}
              defaultValue={noShow.greenFeeProductID}
            >
              {noShow.greenFeeProducts.length >= 1 &&
                noShow.greenFeeProducts?.map(item => {
                  const productTitle = item?.title;
                  const preferredTitle = item?.preferred_title;
                  return (
                    item.variants &&
                    item.variants
                      .filter(variantFiltered => variantFiltered.price > 0)
                      .map((variant, i) => {
                        return (
                          <Option
                            key={i}
                            value={variant.id}
                            name={variant.title}
                            extraValues={{ title: variant.title, price: variant.price }}
                          >
                            <div>
                              <span>{preferredTitle ? preferredTitle : productTitle} </span>
                              {productTitle !== variant.title && (
                                <span className="text-subdued text-sm">{variant.title}</span>
                              )}
                              <span>
                                {" "}
                                - <LocaleCurrency currency="cad" amount={variant.price} />
                              </span>
                            </div>
                          </Option>
                        );
                      })
                  );
                })}
            </Select>
          </div>
          <div className="mt-auto text-lg">
            <ul>
              <li className="flex justify-end">
                <span className="mr-4 text-medium">{noShow.greenFeeProductTitle}</span>
                <LocaleCurrency currency="cad" amount={noShow.greenFeeProductPrice ?? 0} />
              </li>
              <li className="flex justify-end">
                <span className="mr-4 text-medium">{t("secure.facility.tee_sheet.tee_sheet_booking_details.063")}</span>
                <span>{noShow.quantity}</span>
              </li>
              <li className="flex justify-end text-bold">
                <span className="mr-4">{t("secure.facility.tee_sheet.tee_sheet_booking_details.064")}</span>
                <LocaleCurrency currency="cad" amount={noShow.quantity * noShow.greenFeeProductPrice} />
              </li>
            </ul>
          </div>
        </div>
      </Sheet>

      <Popup
        open={noShow.noPaymentMethodModal}
        type="info"
        title={t("secure.facility.tee_sheet.tee_sheet_booking_details.061")}
        description={t("secure.facility.tee_sheet.tee_sheet_booking_details.062")}
        onOk={() => updateState<INoShowState>({ noPaymentMethodModal: false }, StateType.NoShowState)}
      />

      <Popup
        open={checkInState.manualCheckInVisible}
        closable
        type="info"
        title={t("secure.facility.tee_sheet.tee_sheet_booking_details.174")}
        description={t("secure.facility.tee_sheet.tee_sheet_booking_details.175")}
        onOk={handleManualCheckIn}
        onCancel={() =>
          updateState<ICheckInState>({ manualCheckInVisible: false, manualCheckInSlot: null }, StateType.CheckInState)
        }
      />

      <Sheet
        open={rainCheckState.rainCheckActive}
        size="small"
        closable
        title={t("secure.facility.tee_sheet.tee_sheet_booking_details.065")}
        onCancel={cancelRainCheck}
        onOk={issueRainCheck}
        cancelText={!rainCheckState.continueBtn ? t("secure.facility.tee_sheet.tee_sheet_booking_details.066") : "Back"}
        okText={!rainCheckState.continueBtn ? "Continue" : t("secure.facility.tee_sheet.tee_sheet_booking_details.067")}
        okDisabled={rainCheckState.selectedBookingParticipantId === null || rainCheckState.okDisabled ? true : false}
      >
        <div className="h-full">
          {!rainCheckState.continueBtn ? (
            <div>
              {teeSheetStore.selectedTeeTime?.slots?.some(
                slot =>
                  slot?.booking_participant?.check_in_status === "checked" &&
                  slot?.booking_participant?.order_financial_status === "paid",
              ) ? (
                <DataTable
                  columns={[
                    { label: t("secure.facility.tee_sheet.tee_sheet_booking_details.068"), width: "5%" },
                    { label: t("secure.facility.tee_sheet.tee_sheet_booking_details.069"), width: "95%" },
                  ]}
                >
                  {teeSheetStore.selectedTeeTime?.slots
                    ?.filter(
                      filteredSlot =>
                        filteredSlot?.booking_participant?.check_in_status === "checked" &&
                        filteredSlot?.booking_participant?.order_financial_status === "paid",
                    )
                    .map((slot, index) => {
                      return (
                        <tr key={slot.id} className="clickable" onClick={() => handleRainCheckbox(index, slot)}>
                          <td>
                            <Checkbox
                              size={"small"}
                              checked={rainCheckState.checkBoxes[index]}
                              onChange={e => e.stopPropagation()}
                            />
                          </td>
                          <td> {slot?.booking_participant?.full_name} </td>
                        </tr>
                      );
                    })}
                </DataTable>
              ) : (
                <Callout
                  type="info"
                  title="No Paid Fees" // TODO: Translation
                  content="Complete a check in on this booking to create a rain check" // TODO: Translation
                />
              )}
            </div>
          ) : (
            <>
              <p className="issue-raincheck-amount">
                {t("secure.facility.tee_sheet.tee_sheet_booking_details.072")}
                <LocaleCurrency
                  style={{ fontSize: "1.2rem" }}
                  currency="cad"
                  amount={typeof rainCheckState.calculate === "object" ? 0 : rainCheckState.calculate ?? 0}
                />
              </p>

              <div className="mb-4">
                <Input
                  id="completedHoles"
                  value={rainCheckState.completedHoles}
                  type="number"
                  label={t("secure.facility.tee_sheet.tee_sheet_booking_details.070")}
                  suffix={"/ " + String(rainCheckState.holes) + " holes"}
                  onChange={handleRainCheckHolesInput}
                />
              </div>

              <div className="mb-4">
                <Input
                  id="amount"
                  label={t("secure.facility.tee_sheet.tee_sheet_booking_details.071")}
                  type="number"
                  onChange={handleRainCheckAmountInput}
                />
              </div>
            </>
          )}
        </div>
      </Sheet>

      <RainCheckCompletePopup
        open={rainCheckState.rainCheckCompleteActive}
        setClose={() => setRainCheckState(prevState => ({ ...prevState, rainCheckCompleteActive: false }))}
        rainCheck={rainCheckState.rainCheck}
        bookingParticipant={rainCheckState?.selectedBookingParticipant}
      />

      <ChangeCustomer
        title="Change Player"
        currentCustomerTitle="Previous Golfer"
        newCustomerTitle="New Golfer"
        okText={t("secure.facility.tee_sheet.tee_sheet_booking_details.077")}
        open={changePlayerState.showChangePlayer}
        changeCustomerState={changePlayerState}
        searching={changePlayerState.customerSearching}
        searchResults={changePlayerState.playerSearchResult}
        newCustomerActive={changePlayerState.showNewPlayer}
        onCancel={closeChangePlayer}
        onOk={changeSelectedPlayer}
        handleSearchQueryChange={changePlayerHandleCustomerSearch}
        handleCustomerSelection={handleChangePlayerSelection}
        handleRemoveSelectedCustomer={() =>
          setChangePlayerState(prevState => ({
            ...prevState,
            selectedCustomer: null,
            playerSearchResult: [],
            customerQuery: "",
          }))
        }
        handleCreateNewCustomer={createNewCustomer}
        handleCloseCreateNewCustomer={closeCreateNewCustomer}
        handleNewCustomerActive={() => setChangePlayerState(prevState => ({ ...prevState, showNewPlayer: true }))}
      />

      {/* <Portal isMounted={changePlayerState.showChangePlayer}>
        <Sheet
          title={
            changePlayerState.showNewPlayer
              ? t("secure.facility.tee_sheet.tee_sheet_booking_details.075")
              : t("secure.facility.tee_sheet.tee_sheet_booking_details.076")
          }
          open={changePlayerState.showChangePlayer}
          size="small"
          closable
          onCancel={closeChangePlayer}
          onOk={changeSelectedPlayer}
          okText={t("secure.facility.tee_sheet.tee_sheet_booking_details.077")}
          okDisabled={changePlayerState.selectedCustomer ? false : true}
          overflow
        >
          {changePlayerState.showNewPlayer ? (
            <NewCustomer
              newCustomerSheetActive={changePlayerState.showNewPlayer}
              onCancel={closeCreateNewCustomer}
              onOk={createNewCustomer}
              searchValue={changePlayerState.customerQuery}
            />
          ) : (
            <>
              <span>{t("secure.facility.tee_sheet.tee_sheet_booking_details.084")}</span>
              <GolferCard
                email={changePlayerState.bookingParticipant?.customer.email}
                name={changePlayerState.bookingParticipant?.full_name}
                memberCode={changePlayerState.bookingParticipant?.customer.member_code}
                customerType={changePlayerState.bookingParticipant?.customer.customer_type}
                phone={changePlayerState.bookingParticipant?.customer.phone}
              />

              <FontAwesomeIcon
                className="ml-auto mr-auto mt-4 mb-2 block text-primary-500"
                icon={["far", "arrow-circle-down"]}
                size="2x"
              />

              <span>{t("secure.facility.tee_sheet.tee_sheet_booking_details.085")}</span>
              {changePlayerState.selectedCustomer ? (
                <GolferCard
                  closable
                  removeGolfer={() =>
                    setChangePlayerState(prevState => ({
                      ...prevState,
                      selectedCustomer: null,
                      playerSearchResult: [],
                      customerQuery: "",
                    }))
                  }
                  email={changePlayerState.selectedCustomer.email}
                  name={changePlayerState.selectedCustomer.full_name}
                  memberCode={changePlayerState.selectedCustomer.member_code}
                  customerType={changePlayerState.selectedCustomer.customer_type}
                  phone={changePlayerState.selectedCustomer.phone}
                />
              ) : (
                <Select
                  showSearch
                  className={`flex justify-center align-center w-full h-10 position-relative z-20 text-black text-medium appearance-none border-none focus:outline-none placeholder-gray-200`}
                  onSearch={(query: string) => changePlayerHandleCustomerSearch(query)}
                  onChange={(id: number, customer: ICustomer) => handleChangePlayerSelection(id, customer)}
                  placeholder={t("secure.facility.tee_sheet.tee_sheet_booking_details.086")}
                  allowClear
                  searchValue={changePlayerState.customerQuery}
                  showDropDownOnFocus={true}
                  searching={changePlayerState.customerSearching}
                  autoFocus={changePlayerState.showChangePlayer}
                >
                  <div
                    className="ui-select-dropdown-list-item"
                    onClick={() => setChangePlayerState(prevState => ({ ...prevState, showNewPlayer: true }))}
                  >
                    <p>{t("secure.facility.tee_sheet.tee_sheet_booking_details.087")}</p>
                  </div>
                  {changePlayerState.playerSearchResult?.map((player, index) => {
                    return (
                      <Option key={index} value={player.id} name={player.full_name} extraValues={player}>
                        <div className="flex justify-between">
                          <div>
                            <div className="text-semibold text-lg">{player?.full_name}</div>
                            <div className="text-sm text-gray-500">{player.customer_type}</div>
                            <div className="text-sm text-gray-500">{player.email}</div>
                            <div className="text-sm text-gray-500">{player.phone ? player.phone : null}</div>
                          </div>

                          <div className="text-medium text-base text-gray-500 self-end">{player.member_code}</div>
                        </div>
                      </Option>
                    );
                  })}
                </Select>
              )}
            </>
          )}
        </Sheet>
      </Portal> */}

      <Sheet
        open={rainCheckState.redeemRainCheckActive}
        size="small"
        closable
        title={t("secure.facility.tee_sheet.tee_sheet_booking_details.088")}
        onCancel={closeRedeemRainCheck}
        onOk={handleRedeemRainCheck}
        cancelText={t("secure.facility.tee_sheet.tee_sheet_booking_details.089")}
        okText={t("secure.facility.tee_sheet.tee_sheet_booking_details.090")}
        okDisabled={rainCheckState.redeemRainCheckCode === "" || !checkInState.cart?.id}
      >
        {checkInState.cart?.id ? (
          <Input
            label={t("secure.facility.tee_sheet.tee_sheet_booking_details.091")}
            value={rainCheckState.redeemRainCheckCode}
            id="redeemRainCheckCode"
            placeholder={t("secure.facility.tee_sheet.tee_sheet_booking_details.092")}
            onChange={handleRedeemRainCheckChange}
          />
        ) : (
          <Callout
            title={t("secure.facility.tee_sheet.tee_sheet_booking_details.093")}
            content={t("secure.facility.tee_sheet.tee_sheet_booking_details.094")}
            type="info"
          />
        )}
      </Sheet>

      <Portal isMounted={checkInState.redeemTicketActive}>
        <Sheet
          title={t("secure.facility.tee_sheet.tee_sheet_booking_details.099")}
          open={checkInState.redeemTicketActive}
          size="medium"
          closable
          onCancel={handleCloseSearchTicket}
          onOk={handleSearchTicket}
          okText={"Redeem"}
          // okText={t("secure.facility.tee_sheet.tee_sheet_booking_details.100")}
          okDisabled={
            (checkInState.selectedTickets.length <= 0 && checkInState.redeemTicketSelected === 0) ||
            (checkInState.selectedCustomerTickets.length <= 0 && checkInState.redeemTicketSelected === 1)
          }
        >
          <Tabs tabs={tabs} selected={checkInState.redeemTicketSelected} onSelect={handleSearchTicketTabChange}>
            {checkInState.redeemTicketSelected === 0 && (
              <>
                <FormLayout>
                  <FormLayout.Group>
                    <Input
                      id="ticketSearchId"
                      onKeyDown={handleEnterKeydown}
                      value={ticketState.ticketString}
                      placeholder="Scan Ticket / Barcode"
                      onChange={e => setTicketState(prevState => ({ ...prevState, ticketString: e.target.value }))}
                      trailingButtons={[
                        <Button key={1} onClick={handleEnterClick} type="secondary">
                          Enter
                        </Button>,
                      ]}
                    />
                  </FormLayout.Group>
                </FormLayout>

                <DataTable
                  columns={[
                    ...(checkInState?.selectedTickets?.length > 0 ? [{ label: "Title" }] : []),
                    ...(checkInState?.selectedTickets?.length > 0
                      ? [{ label: "Ticket #" }, { label: "Barcode" }]
                      : [{ label: "Ticket # / Barcode" }]),
                    {
                      label: "Quantity",
                    },
                    { label: "" },
                    { label: "" },
                  ]}
                >
                  {ticketState?.tickets?.map((sku, index) => {
                    return (
                      <tr key={index}>
                        {checkInState?.selectedTickets?.length > 0 && (
                          <>
                            <td></td>

                            <td>{sku?.sku}</td>
                          </>
                        )}
                        <td>{sku?.sku}</td>
                        <td>{sku?.quantity}</td>
                        <td className="text-right">
                          {!sku.searched ? (
                            <div style={{ display: "inline-flex" }}>
                              <Spin />
                            </div>
                          ) : (
                            <OverlayTrigger
                              placement="auto"
                              overlay={<Tooltip id="headingTooltip">Invalid Ticket</Tooltip>}
                            >
                              <FontAwesomeIcon
                                className="inventory-counts-error"
                                size="1x"
                                icon={["fas", "circle-xmark"]}
                                // onClick={() => setVariantNotFoundState({ modalOpen: true, sku: sku.sku })}
                              />
                            </OverlayTrigger>
                          )}
                        </td>
                        <td className="text-right">
                          <FontAwesomeIcon
                            onClick={() => removeTicketSku(index)}
                            icon={["far", "trash-can-xmark"]}
                            size="1x"
                            className="cursor-pointer"
                          />
                        </td>
                      </tr>
                    );
                  })}

                  {checkInState?.selectedTickets?.map((ticketStub, ticketIndex) => {
                    return (
                      <React.Fragment key={ticketIndex}>
                        <tr className="inventory-counts-product-header">
                          <td>{ticketStub.ticket.title}</td>
                          <td>{ticketStub.code}</td>
                          <td>{ticketStub.code}</td>

                          <td onClick={e => e.stopPropagation()}>1</td>
                          <td className="text-right">{getStubStatus(ticketStub)}</td>
                          <td className="text-right">
                            <FontAwesomeIcon
                              onClick={() => removeTicket(ticketIndex)}
                              icon={["far", "trash-can-xmark"]}
                              size="1x"
                              className="cursor-pointer"
                            />
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </DataTable>
              </>
            )}

            {checkInState.redeemTicketSelected === 1 && (
              <>
                <DataTable
                  className="mt-3"
                  columns={[{ label: "Title" }, { label: "Number" }, { label: "Expires" }, { label: "" }]}
                >
                  {checkInState.availableTickets.map((ticketStub: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td style={{ padding: "8px 24px" }}>
                          <p className="text-xs">
                            {ticketStub.ticket?.title}{" "}
                            {ticketStub.ticket?.subtitle ? "- " + String(ticketStub.ticket.subtitle) : null}
                          </p>

                          <p className="text-xs">{ticketStub.ticket?.included}</p>
                        </td>

                        <td>
                          <p className="text-xs">{ticketStub.code}</p>
                        </td>

                        <td>
                          <p className="text-xs">{ticketStub.expiry_date ? ticketStub.expiry : "No Expiry"}</p>
                        </td>

                        <td>
                          <Checkbox
                            size={"medium"}
                            checked={checkInState.selectedCustomerTickets?.some(stub => stub?.id === ticketStub.id)}
                            onChange={e => selectCustomerTicket(index, e.target.checked)}
                          />
                        </td>

                        {/* <td className="text-xs">
                          {ticketStub.status === "valid" ? (
                            <Button type="link" size="xsmall" onClick={() => handleTicketRedeem(ticketStub.code)}>
                              {t("secure.facility.tee_sheet.tee_sheet_booking_details.102")}
                            </Button>
                          ) : null}
                        </td> */}
                      </tr>
                    );
                  })}
                </DataTable>
              </>
            )}
          </Tabs>
        </Sheet>
      </Portal>

      <Popup
        open={state.clearCartActive}
        type="warning"
        title={t("secure.facility.tee_sheet.tee_sheet_booking_details.154")}
        description={t("secure.facility.tee_sheet.tee_sheet_booking_details.155")}
        onOk={handleDeleteOrder}
        okText={t("secure.facility.tee_sheet.tee_sheet_booking_details.156")}
        cancelText={t("secure.facility.tee_sheet.tee_sheet_booking_details.157")}
        onCancel={() => {
          setState(prevState => ({ ...prevState, clearCartActive: false }));
        }}
        backDropCancel={true}
      />

      <Popup
        open={checkInState.ticketValidSuccess}
        type={checkInState.getTicketValidateData?.stub?.status !== "valid" ? "info" : "success"}
        title={
          checkInState.getTicketValidateData?.stub?.status === "redeemed"
            ? t("secure.facility.tee_sheet.tee_sheet_booking_details.163")
            : checkInState.getTicketValidateData?.stub?.status === "expired"
            ? t("secure.facility.tee_sheet.tee_sheet_booking_details.164")
            : checkInState.getTicketValidateData?.stub?.status === "void"
            ? t("secure.facility.tee_sheet.tee_sheet_booking_details.165")
            : t("secure.facility.tee_sheet.tee_sheet_booking_details.108")
        }
        description={
          checkInState.getTicketValidateData?.stub?.status === "redeemed"
            ? t("secure.facility.tee_sheet.tee_sheet_booking_details.166")
            : checkInState.getTicketValidateData?.stub?.status === "expired"
            ? t("secure.facility.tee_sheet.tee_sheet_booking_details.167")
            : checkInState.getTicketValidateData?.stub?.status === "void"
            ? t("secure.facility.tee_sheet.tee_sheet_booking_details.168")
            : t("secure.facility.tee_sheet.tee_sheet_booking_details.108")
        }
        onOk={handleTicketValidSuccess}
        okText="Redeem"
        okDisabled={checkInState.getTicketValidateData?.stub?.status !== "valid" ? true : false}
        onCancel={() =>
          updateState<ICheckInState>(
            {
              ticketValidSuccess: false,
              redeem_ticket_code: "",
              getTicketValidateData: {},
            },
            StateType.CheckInState,
          )
        }
      >
        <p>{checkInState.getTicketValidateData.stub?.ticket?.title}</p>
        <p>{checkInState.getTicketValidateData.stub?.ticket?.included}</p>
        <p>
          {checkInState.getTicketValidateData.stub?.expiry_date ? (
            <span>
              {t("secure.facility.tee_sheet.tee_sheet_booking_details.109")}{" "}
              {checkInState.getTicketValidateData.stub?.expiry_date}
            </span>
          ) : (
            <span>{t("secure.facility.tee_sheet.tee_sheet_booking_details.110")}</span>
          )}
        </p>
        {checkInState.getTicketValidateData.stub?.description && (
          <Markdown markdownText={checkInState.getTicketValidateData.stub.description} />
        )}
      </Popup>

      <Popup
        open={checkInState.ticketValidWarning}
        type="warning"
        title={t("secure.facility.tee_sheet.tee_sheet_booking_details.111")}
        description=" "
        onOk={handleTicketValidWarning}
      />

      {/* Tee time cancelled pop up */}
      <Popup
        open={state.teeTimeCancelledPopupVisible}
        type="warning"
        title={t("secure.facility.tee_sheet.tee_sheet_booking_details.112")}
        description={t("secure.facility.tee_sheet.tee_sheet_booking_details.113")}
        onOk={handleTeeTimeCancelledPopupRefresh}
        okText={t("secure.facility.tee_sheet.tee_sheet_booking_details.114")}
        onCancel={handleTeeTimeCancelledPopupRefresh}
        backDropCancel={false}
      />

      {/* Group Pay Modal */}
      <div className="group-pay-modal-ok-button">
        <Sheet
          title={t("secure.facility.tee_sheet.tee_sheet_booking_details.134")}
          open={groupPayState.groupPayActive}
          size="medium"
          closable
          onCancel={() =>
            updateState<IGroupPayState>({ groupPayActive: false, groupPayTeeTimes: null }, StateType.GroupPayState)
          }
          cancelText={t("secure.facility.tee_sheet.tee_sheet_booking_details.135")}
        >
          {!groupPayState?.groupPayTeeTimes ? (
            <div style={{ display: "flex", height: "100%", margin: "auto" }}>
              <div className="text-center m-auto">
                <div style={{ height: "32px" }}>
                  <Spin />
                </div>
                <div>Loading tee times...</div>
              </div>
            </div>
          ) : groupPayState?.groupPayTeeTimes?.length === 0 ? (
            <Callout type="info" title="No Bookings Found" content="No available bookings were found" />
          ) : (
            <>
              <div className="group-pay-player-search">
                <Input
                  value={groupPayState.playerSearch}
                  onChange={(value: any) =>
                    updateState<IGroupPayState>({ playerSearch: value.target.value }, StateType.GroupPayState)
                  }
                  type="search"
                  placeholder={t("secure.facility.tee_sheet.tee_sheet_booking_details.136")}
                />
              </div>
              <div>
                {groupPayState.groupPayTeeTimes?.map((teeTime, index) => {
                  if (
                    teeTime.slots.some(slot =>
                      slot.booking_participant?.full_name
                        .toLowerCase()
                        .includes(groupPayState.playerSearch.toLowerCase()),
                    )
                  ) {
                    return (
                      <div key={index}>
                        <div className="group-pay-time-container">
                          <p className="text-semibold text-xl mb-2">{convertTime(teeTime.start_time)}</p>
                          {!checkInState?.cart ? (
                            <Button
                              type="text"
                              size="medium"
                              onClick={createCart}
                              loading={orderProgress.orderStep === OrderProgress.CREATING_CART}
                            >
                              {"Create Cart"}
                            </Button>
                          ) : (
                            <Button type="text" size="medium" onClick={() => addAllGroupPay(teeTime?.slots)}>
                              {"Add All"}
                            </Button>
                          )}
                        </div>

                        {teeTime.slots
                          ?.filter(
                            filteredSlot =>
                              filteredSlot.booking_participant?.check_in_status === "unchecked" &&
                              filteredSlot.booking_participant.full_name
                                .toLowerCase()
                                .includes(groupPayState.playerSearch.toLowerCase()),
                          )
                          .map((slot, slotIndex) => {
                            const currentParticipant = participantPaidFees?.current.find(
                              participant => participant?.booking_participant_id === slot?.booking_participant?.id,
                            );
                            const green_fee_added = currentParticipant?.green_fee;
                            const power_cart_fee_added = currentParticipant?.power_cart_fee;

                            const green_fee_setup = !(slot.booking_participant?.green_fee_product_title === null);
                            const power_cart_fee_setup = !(slot.booking_participant?.power_cart_product_title === null);

                            return (
                              <div
                                key={slotIndex}
                                className={classNames(
                                  "rounded-xl",
                                  "border",
                                  "border-gray-400",
                                  "p-2",
                                  "flex",
                                  "items-center",
                                  "w-100",
                                  "justify-between",
                                  "mb-2",
                                )}
                              >
                                <p className="text-semibold text-lg">
                                  {slot.booking_participant?.full_name} {slot.booking_participant.guest && "- Guest"}
                                </p>
                                <div className="group-pay-items-container booking-display-new">
                                  {slot?.booking_participant?.green_fee_paid ? (
                                    <Badge type="success" size="small">
                                      {t("secure.facility.tee_sheet.tee_sheet_booking_details.197")}
                                    </Badge>
                                  ) : (
                                    <div className="button-group">
                                      <div className="flex flex-col ml-4 mr-2">
                                        <p className="text-semibold">
                                          {green_fee_setup
                                            ? slot.booking_participant?.green_fee_product_title
                                            : "No Green Fee Set Up"}{" "}
                                        </p>
                                        <p className="text-semibold text-xs text-gray-400">
                                          {slot.booking_participant.green_fee_variant_title}
                                        </p>
                                      </div>

                                      <Button
                                        type="primary"
                                        size="medium"
                                        className={classNames("ml-auto", {
                                          "booking-details-add-button": !green_fee_added,
                                        })}
                                        icon={
                                          currentParticipant?.green_fee_loading ? (
                                            <FontAwesomeIcon size="1x" icon={["fad", "spinner-third"]} spin fade />
                                          ) : green_fee_added ? (
                                            <FontAwesomeIcon icon={["fal", "times"]} />
                                          ) : (
                                            <FontAwesomeIcon icon={["fal", "arrow-right-long"]} />
                                          )
                                        }
                                        onClick={e =>
                                          !green_fee_added
                                            ? handleAddFee(
                                                slot,
                                                {
                                                  id: slot?.booking_participant?.green_fee_variant_id,
                                                  variantTitle: slot?.booking_participant?.green_fee_variant_title,
                                                  productTitle: slot?.booking_participant?.green_fee_product_title,
                                                },
                                                null,
                                                e,
                                              )
                                            : handleRemoveFee(
                                                slot,
                                                slot?.booking_participant?.green_fee_variant_id,
                                                "green_fee",
                                                e,
                                              )
                                        }
                                        disabled={
                                          slot?.booking_participant?.green_fee_paid ||
                                          !slot?.booking_participant?.green_fee_variant_id ||
                                          currentParticipant?.green_fee_loading ||
                                          orderProgress.orderStep === OrderProgress.CREATING_CART ||
                                          !!checkInState?.order
                                        }
                                      />
                                    </div>
                                  )}
                                  {slot?.booking_participant?.power_cart_paid ? (
                                    <Badge type="success" size="small">
                                      {t("secure.facility.tee_sheet.tee_sheet_booking_details.198")}
                                    </Badge>
                                  ) : (
                                    <div className="button-group">
                                      <p className="ml-4 mr-2 text-semibold">
                                        {power_cart_fee_setup
                                          ? slot.booking_participant?.power_cart_product_title
                                          : "No Power Cart Fee Set Up"}
                                      </p>
                                      <Button
                                        type="primary"
                                        size="medium"
                                        className={classNames("ml-auto", {
                                          "booking-details-add-button": !power_cart_fee_added,
                                        })}
                                        icon={
                                          currentParticipant?.power_cart_fee_loading ? (
                                            <FontAwesomeIcon size="1x" icon={["fad", "spinner-third"]} spin fade />
                                          ) : power_cart_fee_added ? (
                                            <FontAwesomeIcon icon={["fal", "times"]} />
                                          ) : (
                                            <FontAwesomeIcon icon={["fal", "arrow-right-long"]} />
                                          )
                                        }
                                        onClick={e =>
                                          !power_cart_fee_added
                                            ? handleAddFee(
                                                slot,
                                                null,
                                                {
                                                  id: slot?.booking_participant?.power_cart_variant_id,
                                                  variantTitle: slot?.booking_participant?.power_cart_variant_title,
                                                  productTitle: slot?.booking_participant?.power_cart_product_title,
                                                },
                                                e,
                                              )
                                            : handleRemoveFee(
                                                slot,
                                                slot?.booking_participant?.power_cart_variant_id,
                                                "power_cart_fee",
                                                e,
                                              )
                                        }
                                        disabled={
                                          slot?.booking_participant?.power_cart_paid ||
                                          !slot?.booking_participant?.power_cart_variant_id ||
                                          currentParticipant?.power_cart_fee_loading ||
                                          orderProgress.orderStep === OrderProgress.CREATING_CART ||
                                          !!checkInState?.order
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        <Divider className="mt-4 mb-2" />
                      </div>
                    );
                  }
                })}
              </div>
            </>
          )}
        </Sheet>
      </div>

      <Portal isMounted={editBookingModal.isOpen}>
        <BookingModalEdit
          selectedBooking={editBookingModal.selectedBooking}
          teeTimeLock={editBookingModal.teeTimeLock}
          refreshTeeTime={true}
          loading={editBookingModal.loading}
          loadingText={editBookingModal.loadingText}
          reloadTeeTimes={() => refreshTeeTime(true, false)}
        />
      </Portal>

      <OrderCompletePopup
        open={orderCompletePopup.isOpen}
        setOpen={handleBackToCheckIn}
        order={checkInState.order}
        tee_time_id={Number(teetimeId)}
        returnable={true}
        disableDuringLoad
        loading={orderCompletePopup.loading}
        transactionComplete={orderCompletePopup.transactionComplete}
        teeSheetCheckIn
      />

      <Prompt
        when={!!checkInState.order && !orderCompletePopup?.isOpen}
        message={location =>
          "The current order has not been completed. This will result in a partially paid order. Are you sure you want to continue?"
        }
      />
    </>
  );
};

export default NewTeeSheetDetails;
