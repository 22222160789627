import { APIGet, APIPost, APIPut, APIDelete, APIUrl, AdminType, APIVersion } from "api/protocols";
import { CancelToken } from "axios";
import { TAccountingReference } from "redux/reducers/models/facility";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/facility/accounting";

export type TGetAccountingReference = {
  id?: string | number;
  group?: string;
  search?: string;
};

export type TPostAccountingReference = {
  title?: string;
  reference_number?: string;
};

export type TPutAccountingReference = {
  id: string | number;
  title?: string;
  reference_number?: string;
};

export type TDeleteAccountingReference = {
  id: string | number;
  /** Required if products are attached to the `id` that is being deleted */
  migrate_accounting_reference_id?: string | number;
};

// GET Accounting Reference
export async function GetAccountingReference(
  params: TGetAccountingReference,
  useGlobalLoader?: boolean,
  token?: CancelToken,
) {
  return await APIGet<TAccountingReference[]>(
    APIUrl(AdminType.FacilityAdmin, "/facility/accounting"),
    params,
    useGlobalLoader,
    token,
  );
}

// POST Accounting Reference
export async function PostAccountingReference(params: TPostAccountingReference, useGlobalLoader?: boolean) {
  return await APIPost(APIUrl(AdminType.FacilityAdmin, "/facility/accounting"), params, useGlobalLoader);
}

// PUT Accounting Reference
export async function PutAccountingReference(params: TPutAccountingReference, useGlobalLoader?: boolean) {
  return await APIPut(APIUrl(AdminType.FacilityAdmin, "/facility/accounting"), params, useGlobalLoader);
}

// DELETE Accounting Reference
export async function DeleteAccountingReference(params: TDeleteAccountingReference, useGlobalLoader?: boolean) {
  return await APIDelete(APIUrl(AdminType.FacilityAdmin, "/facility/accounting"), params, useGlobalLoader);
}
