import React, { useEffect, useState, useCallback } from "react";
import { useHistory, Link } from "react-router-dom";
import { useParams } from "react-router";

import { GetLeague, GetLeagueScoresheets, PostLeagueScoresheet } from "api/rpc/facilityAdmin/league/league";
import { GetSkinsSheet, PutSkinsSheet, GetSkinsRound } from "api/rpc/2022-09/facilityAdmin/league/scoring/skins";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import { useTranslation } from "react-i18next";
import { Select } from "components/select/";
import { GetDivisions } from "api/rpc/facility";
import { GetRound } from "api/rpc/2022-09/facilityAdmin/league/scoring/round";
import { GetParticipant } from "api/rpc/2022-09/facilityAdmin/league/participant";
import { IUIActions } from "redux/actions/ui";
import { ILeagueParticipant } from "redux/reducers/models/league";

interface ILeagueSkinSheet {
  created_at: string;
  deleted_at: string;
  division_back_id: number;
  division_front_id: number;
  id: number;
  score: string;
  scoresheet_id: number;
  title: string;
  updated_at: string;
}

interface ILeagueSkinsState {
  title: string;
  scoresheet_id: number;
  score: string;
  division_front_id: number;
  division_back_id: number;
  skinsSheet: ILeagueSkinSheet;
  divisions: any;
  rounds: any;
  skinsResults: any;
}

interface IProps {
  uiActions: IUIActions;
}

interface IParams {
  leagueId: string;
  scoresheetId: string;
  skinsheetId: string;
}

export default function LeagueSkin(props: IProps) {
  const { Option } = Select;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { leagueId } = useParams<IParams>();
  const { scoresheetId }: any = useParams();
  const { skinsheetId } = useParams<IParams>();

  const { uiActions } = props;

  const [leagueSkinsState, setLeagueSkinsState] = useState<ILeagueSkinsState>({
    title: "",
    scoresheet_id: Number(scoresheetId),
    score: "",
    division_front_id: -1,
    division_back_id: -1,
    skinsSheet: null,
    divisions: [],
    rounds: [],
    skinsResults: [],
  });

  useEffect(() => {
    void loadDivisions();
    void loadSkinsSheets();
    void loadRounds();
  }, []);

  async function loadSkinsSheets() {
    const skinsSheetsRes = await GetSkinsSheet(
      {
        id: skinsheetId,
      },
      true,
    );

    if (skinsSheetsRes.status !== StatusCode.OK) {
      return;
    }

    setLeagueSkinsState(prevState => ({
      ...prevState,
      skinsSheet: skinsSheetsRes.data,
      title: skinsSheetsRes.data[0].title,
      score: skinsSheetsRes.data[0].score,
      division_front_id: skinsSheetsRes.data[0].division_front_id,
      division_back_id: skinsSheetsRes.data[0].division_back_id,
    }));
  }

  async function loadDivisions() {
    const divisionsRes = await GetDivisions(null, true);

    if (divisionsRes.status !== StatusCode.OK) {
      return;
    }

    setLeagueSkinsState(prevState => ({ ...prevState, divisions: divisionsRes.data }));
  }

  async function loadRounds() {
    const leaguePlayersRes = await GetParticipant(
      {
        league_id: leagueId,
      },
      true,
    );

    const leaguePlayers: Array<ILeagueParticipant & { index?: number }> = leaguePlayersRes.data.map((player, index) => {
      return {
        ...player,
        index: index,
      };
    });

    const skinsRoundsRes = await GetSkinsRound({ skins_sheet_id: skinsheetId }, true);

    const playerRound = skinsRoundsRes.data.rounds;

    for (let i = 0; i < leaguePlayers.length; i++) {
      for (let k = 0; k < playerRound.length; k++) {
        if (leaguePlayers[i].user_id === playerRound[k].customer_id) {
          playerRound[k].hole_scores = playerRound[k].hole_scores.slice(0, playerRound[k].hole_scores.length - 1);

          leaguePlayers[i].round = playerRound[k];
        }
      }
    }

    const filteredPlayers = leaguePlayers.filter(player => player.round);

    if (leaguePlayersRes.status === StatusCode.OK) {
      setLeagueSkinsState(prevState => ({
        ...prevState,
        rounds: filteredPlayers,
        skinsResults: skinsRoundsRes.data.best_scores,
      }));
    }
  }

  function handleDropDownChange(value: any, property: string) {
    console.log(value, property);
    setLeagueSkinsState(prevState => ({ ...prevState, [property]: value }));
  }

  return (
    <Page
      title={leagueSkinsState.title}
      narrow
      breadcrumbs={[
        {
          prefix: true,
          label: "Back to skins sheets",
          url: "/admin/league/" + String(leagueId) + "/scoring/scoresheet/" + String(scoresheetId) + "/skins",
        },
      ]}
    >
      <Card title={"Rounds"}>
        <table className="ui-table">
          <thead>
            <tr>
              <th></th>
              {leagueSkinsState.rounds[0] &&
                leagueSkinsState.rounds[0].round.hole_scores.map((score: any, index: number) => {
                  return (
                    <th key={index} className="text-center">
                      {score.hole_number}
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {leagueSkinsState.rounds ? (
              <>
                {leagueSkinsState.rounds.map((round: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{round.customer.full_name}</td>
                      {round.round &&
                        round.round.hole_scores.map((score: any, index: number) => {
                          return leagueSkinsState.score === "gross" ? (
                            <td
                              className={
                                leagueSkinsState.skinsResults[index] === score.gross
                                  ? "text-center background-primary-light"
                                  : "text-center"
                              }
                            >
                              {score.gross}
                            </td>
                          ) : (
                            <td
                              className={
                                leagueSkinsState.skinsResults[index] === score.net
                                  ? "text-center background-primary-light"
                                  : "text-center"
                              }
                            >
                              {score.net}
                            </td>
                          );
                        })}
                    </tr>
                  );
                })}
              </>
            ) : null}
          </tbody>
        </table>
      </Card>
    </Page>
  );
}
