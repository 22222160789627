import { IClient } from "api/rpc/2022-09/customer/client";
import { IFacilityTeeSheetSettings } from "api/rpc/2022-09/customer/facility";
import { IBookingEngine } from "./bookingEngine";
import { IFacility } from "./models/facility";
import { IBooking, ITeeTime } from "redux/reducers/models/teetime";
import { IUser, IUserActive } from "./models/user";
import { ICart } from "./models/cart";

interface IExtendedTeeTime extends ITeeTime {
  formattedStartTime?: string;
  formattedDate?: string;
}

interface ITeeTimeLock {
  lockoutDuration: number;
  lockStartTime: number;
  expireTime: string;
}
interface IExtendedBooking extends Partial<IBooking> {
  paymentMethodId?: number;
  cart?: ICart;
}

export interface IClientPortalState {
  client: IClient;
  user: IUserActive;
  selectedFacility: Partial<IFacility>;
  teeSheetSettings: IFacilityTeeSheetSettings;
  selectedTeeTime: IExtendedTeeTime;
  selectedTeeTimeLock: ITeeTimeLock;
  postedBooking: IExtendedBooking;
  waitlist_reference_id: string;
}

export interface IClientPortalAction {
  type: string;
  payload: Partial<IClientPortalState>;
}

const initialState: IClientPortalState = {
  client: null,
  user: null,
  selectedFacility: null,
  teeSheetSettings: null,
  selectedTeeTime: null,
  selectedTeeTimeLock: null,
  postedBooking: null,
  waitlist_reference_id: null,
};

export function sortFacilities(facilities: Array<IFacility>) {
  if (facilities) {
    const sortedFacilities = facilities
      ?.sort((prevFacility, nextFacility) => prevFacility?.long_name?.localeCompare(nextFacility?.long_name))
      ?.sort((prevFacility, nextFacilty) => Number(nextFacilty?.favourite) - Number(prevFacility?.favourite));
    return sortedFacilities;
  }
  return [];
}

export default function ClientPortalReducer(state = initialState, action: IClientPortalAction) {
  switch (action.type) {
    case "clientPortal.update":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
