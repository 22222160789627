import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/facility/payment-option";

export type TGetPaymentOption = {
  id?: string | number;
  source?: string | number;
  processing_type?: string;
  location?: string;
  active?: boolean;
};

export type TPostPaymentOption = {
  title: string;
  source: "online" | "in_person";
  accounting_reference_id: string | number;
};

export type TPutPaymentOption = {
  id: string | number;
  title: string | number;
  accounting_reference_id: string | number;
};

export async function GetPaymentOptions(params?: TGetPaymentOption, useGlobalLoader?: boolean, token?: CancelToken) {
  return await APIGet(baseUrl, params, useGlobalLoader, token);
}

export async function PostPaymentOptions(params?: TPostPaymentOption, useGlobalLoader?: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

export async function PutPaymentOptions(params: TPutPaymentOption, useGlobalLoader?: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

export async function DeactivatePaymentOption(params: { id: string | number }, useGlobalLoader?: boolean) {
  return await APIPut(baseUrl + "/deactivate", params, useGlobalLoader);
}
