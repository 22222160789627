import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetCustomer, PostCustomer } from "api/rpc/2024-04/facilityAdmin/customer/customer";
import {
  GetCustomer as GetClientCustomer,
  PostCustomer as PostClientCustomer,
} from "api/rpc/2024-04/clientAdmin/customer/customer";

import { showError, showSuccess } from "redux/actions/ui";
import { ICustomer } from "redux/reducers/models/customer";
import { useAppDispatch } from "hooks/redux";
import useModal from "hooks/modals/useModal";
import { customerErrorMessage, delay } from "helpers/Helpers";

import Icon from "components/icon/Icon";
import NewCustomer, { ICustomerInfoState } from "components/newCustomer/NewCustomer";
import { Select } from "components/select";

type TSelectCustomerProps = {
  onCustomerChange: (customer: ICustomer) => void;
  permissionLevel: "facility" | "client";
  /** MS to wait before calling API.  default = 300 */
  searchDelay?: number;
  /** Toggles functionality for adding a new customer */
  newCustomer?: boolean;
  /**Place holder text in search input. Default = "Search..." */
  placeholder?: string;
  /**Use portal for the Select component. Use if customer search is within a modal */
  usePortal?: boolean;
  /**Clear the search string on customer selection */
  clearOnSelection?: boolean;
  /**Filter out customers that match the key value in the filterArray*/
  filter?: {
    key: keyof ICustomer;
    filterArray: Array<string | number>;
  };
  /**Auto focus search input */
  autoFocus?: boolean;
};

export default function SelectCustomer(props: TSelectCustomerProps) {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [searchString, setSearchString] = useState("");
  const [customerList, setCustomerList] = useState<ICustomer[]>([]);

  const { state, updateModal, closeModal } = useModal({ customerName: "" });

  useEffect(() => {
    const source = axios.CancelToken.source();

    const delayedLoadCustomers = async (searchString: string, token?: CancelToken) => {
      await delay(props.searchDelay ?? 300);
      void searchCustomers(searchString, token);
    };

    if (searchString.length > 0) {
      void delayedLoadCustomers(searchString, source.token);
    } else {
      setCustomerList([]);
    }

    return () => source.cancel();
  }, [searchString]);

  async function searchCustomers(searchString: string, token?: CancelToken) {
    if (customerList !== undefined) {
      setCustomerList(undefined);
    }

    const res = await GetCustomer({ search: searchString }, token ? false : true, token);
    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      setCustomerList([]);
      return;
    }
    let customers = res?.data;

    if (props.filter) {
      customers = res?.data?.filter(
        (customer: ICustomer) =>
          !props?.filter?.filterArray?.some(filterKey => filterKey === customer[props.filter?.key]),
      );
    }

    setCustomerList(customers);
  }

  async function createCustomer(tempCustomer: ICustomerInfoState) {
    const error =
      tempCustomer.firstName === "" ||
      tempCustomer.lastName === "" ||
      (tempCustomer.emailAddress === "" && tempCustomer.phoneNumber === "");

    if (error) {
      dispatch(showError("Missing required customer information.")); // TODO: Translation
      return;
    }

    let res;
    if (props.permissionLevel === "facility") {
      res = await PostCustomer(
        {
          first_name: tempCustomer.firstName,
          last_name: tempCustomer.lastName,
          phone: tempCustomer.phoneNumber || null,
          email: tempCustomer.emailAddress || null,
        },
        true,
      );
    }
    if (props.permissionLevel === "client") {
      res = await PostClientCustomer(
        {
          first_name: tempCustomer.firstName,
          last_name: tempCustomer.lastName,
          phone: tempCustomer.phoneNumber || null,
          email: tempCustomer.emailAddress || null,
        },
        true,
      );
    }

    if (res.status !== StatusCode.OK) {
      dispatch(showError(customerErrorMessage(t, res?.data?.message)));
      return;
    }

    dispatch(showSuccess("Successfully added new customer")); // TODO: Translation
    props.onCustomerChange(res.data);
    closeModal();
  }

  function handleCustomerSelection(id: string | number) {
    if (id === null) {
      return;
    } else if (id === undefined || id === "") {
      updateModal({ isOpen: true, customerName: searchString });
      /** closes <Select /> dropdown on modal open */
      setSearchString("");
    } else {
      const selectedCustomer = customerList.find(customer => customer.id === id);
      if (selectedCustomer) {
        props.onCustomerChange(selectedCustomer);
        if (props.clearOnSelection) {
          setSearchString("");
        }
      }
    }
  }

  return (
    <>
      <Select
        showSearch
        showDropDownOnFocus
        className="flex justify-center align-center w-full h-10 position-relative z-20 text-black text-md appearance-none border-none focus:outline-none placeholder-gray-200 mb-2"
        onSearch={(query: string) => setSearchString(query)}
        onChange={(id: string | number) => handleCustomerSelection(id)}
        allowClear
        searchValue={searchString}
        searching={customerList === undefined}
        placeholder={props.placeholder ?? "Search..."}
        usePortal={props.usePortal}
        autoFocus={props.autoFocus}
        noData={
          <div className="px-4">
            <div className="border-b flex items-center p-4 border-b mb-4">
              <Icon icon="users-slash" style="fas" className="m-0" />
              <span className="ml-3 italic">{t("elements.register.cart_menu.cart_menu.029")}</span>
            </div>
            {props.newCustomer ? (
              <div
                className="flex h-16 items-center text-semibold text-lg cursor-pointer"
                onClick={() => handleCustomerSelection("")}
              >
                New Customer {/* TODO: Translation */}
              </div>
            ) : null}
          </div>
        }
      >
        {searchString.length === 0 || customerList === undefined ? (
          <Select.Option value={"new-value"} className="flex h-16 items-center text-semibold text-lg">
            {"New Customer"} {/* TODO: Translation */}
          </Select.Option>
        ) : null}
        {customerList && customerList.length > 0 ? (
          <>
            {props.newCustomer ? (
              <Select.Option value={"new-value"} className="flex h-16 items-center text-semibold text-lg">
                {"New Customer"} {/* TODO: Translation */}
              </Select.Option>
            ) : null}
            {customerList?.map((customer: Partial<ICustomer>) => {
              if (customer !== null && customer !== undefined) {
                return (
                  <Select.Option key={customer?.id} value={customer?.id}>
                    <div style={{ display: "grid" }}>
                      <span className="text-semibold text-lg">{customer?.full_name}</span>
                      <span className="text-sm text-gray-500">{customer?.email}</span>
                      <span className="text-sm text-gray-500">{customer?.phone}</span>
                    </div>
                  </Select.Option>
                );
              }
            })}
          </>
        ) : null}
      </Select>

      <NewCustomer
        newCustomerSheetActive={state.isOpen}
        onCancel={() => closeModal()}
        onOk={createCustomer}
        searchValue={state.customerName}
      />
    </>
  );
}
