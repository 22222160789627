import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDrag } from "react-dnd";
import { IProduct } from "redux/reducers/models/product";
import { ProductCardsItemTypes } from "../ProductCards";
import "./productCard.scss";

interface IProductCardProps {
  product: IProduct;
  isSelected: boolean;
  isPositionInASelectedRow: (position: number) => boolean;
  selectProduct: (event: any, productId: number) => void;
  onRemoveProduct: (id: number) => void;
}

export interface IProductCardItem {
  id: number;
}

export default function ProductCard(props: IProductCardProps) {
  const { product, isSelected, isPositionInASelectedRow, selectProduct, onRemoveProduct } = props;

  const item: IProductCardItem = {
    id: product.id,
  };

  const [{ isDragging }, drag] = useDrag(() => {
    return {
      item: item,
      type: ProductCardsItemTypes.PRODUCT_CARD,
      collect: monitor => ({
        isDragging: !!monitor.isDragging(),
      }),
    };
  }, []);

  return (
    <div className="product-card-container" ref={drag}>
      <div
        className="product-card"
        style={{
          ...(isPositionInASelectedRow(product.folder_position)
            ? { backgroundColor: "blue" }
            : product.meta?.button_color
            ? { backgroundColor: product.meta.button_color }
            : {}),
          opacity: isSelected ? 0.7 : 1,
          cursor: isDragging ? "grabbing" : "grab",
        }}
        onClick={e => selectProduct(e, product.id)}
      >
        <div>
          <div className="product-card-title">{product.preferred_title ? product.preferred_title : product.title}</div>
          <div className="product-card-vendor">{product.vendor_title}</div>
        </div>
        <FontAwesomeIcon
          onClick={() => onRemoveProduct(product.id)}
          className="product-card-remove-icon"
          icon={["far", "xmark"]}
        />
      </div>
    </div>
  );
}
