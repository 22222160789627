import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Spin from "components/spin/spin";
import "./sponsorList.scss";

export interface ISponsorshipImage {
  created_at: Date;
  deleted_at: Date | null;
  id: number;
  league_id: number;
  source: string;
  source_path: string;
  updated_at: string | null;
}

type SponsorListProps = {
  sponsors: ISponsorshipImage[] | { id: number; source: string }[] | undefined;
  /** Displays remove button in the top-right corner of each sponsor card. */
  onRemove?: (sponsorId: number) => void;
  /** Displays start input card allowing user to add an image file. */
  onNew?: (image: File) => void;
  /** Text displayed above the input icon if onNew props is passed. */
  uploadLabel?: string;
};

export default function SponsorList(props: SponsorListProps) {
  // only called if props.onNew is defined
  function imageUploadOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files;
    if (file && file.length > 0) {
      props.onNew(file[0]);
    }

    event.target.value = "";
  }

  function handleOnClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, sponsorId: number) {
    event.preventDefault();
    props.onRemove(sponsorId);
  }

  return (
    <ul className="sponsor-grid">
      {props.onNew && (
        <li className="img-wrap img-wrap-new grid-square" key={`image-new-upload`}>
          <div className="new-upload">
            {props.uploadLabel && <p>{props.uploadLabel}</p>}
            <FontAwesomeIcon icon={"upload"} size="lg" />
            <input className="image-upload_input" type="file" accept="image/*" onChange={imageUploadOnChange} />
          </div>
        </li>
      )}
      {props.sponsors ? (
        <>
          {props.sponsors.map(sponsor => {
            return (
              <li key={sponsor.id} className="img-wrap grid-square">
                <img src={sponsor.source} alt={"Unavailable Image"} />
                {props.onRemove && (
                  <div className="img-cancel">
                    <button onClick={e => handleOnClick(e, sponsor.id)}>X</button>
                  </div>
                )}
              </li>
            );
          })}
        </>
      ) : (
        <li key="sponsor-showcase-spinner" className="img-wrap img-wrap-new grid-square">
          <div className="new-upload showcase-spinner">
            <Spin />
          </div>
        </li>
      )}
    </ul>
  );
}
