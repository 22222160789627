import React, { useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showError } from "redux/actions/ui";
import { StatusCode } from "api/protocols";
import { UserLogout } from "api/rpc";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import "./clientPortalNavigation.scss";
import "./Home/clientPortalHome.scss";
import classNames from "classnames";
import useOutsideAlerter from "hooks/useOutsideAlerter/useOutsideAlerter";
import { MOBILE_WIDTH } from "helpers/ScreenSizes";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { useClientPortal } from "./useClientPortal";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import MetaPixel from "components/metaPixel/MetaPixel";
import GoogleAnalytics from "components/googleAnalytics/GoogleAnalytics";

interface IParams {
  clientShortName: string;
}

interface IState {
  profileDetailsOpen: boolean;
  clientDetailsOpen: boolean;
}

interface IProps {
  showNavOptions?: boolean;
}

export function ClientPortalNavigation(props: IProps) {
  const { showNavOptions } = props;
  const dispatch = useAppDispatch();
  const { clientShortName } = useParams<IParams>();
  const clientPortalStore = useAppSelector(store => store.clientPortalStore);
  const history = useHistory();
  const profileContainer = useRef<HTMLDivElement>();
  const clientContainer = useRef<HTMLDivElement>();
  const windowSize = useWindowSize();
  const HOME_URL = useClientPortal().getHomePageUrl();

  const [state, setState] = useState<IState>({
    profileDetailsOpen: false,
    clientDetailsOpen: false,
  });

  function sendToNextPage(address: string) {
    history.push(address);
  }

  async function logoutUser() {
    const logoutRes = await UserLogout(true);

    if (logoutRes.status !== StatusCode.OK) {
      dispatch(showError("Error logging out"));
    }

    window.location.href = HOME_URL;
  }

  function sendToLogin() {
    sendToNextPage(`/portal/${clientShortName}/login`);
  }

  function sendToPage(address: string, openNewTab = false) {
    if (window.location.search.includes(address)) {
      return;
    } else if (openNewTab) {
      window.open(address);
      return;
    }
    history.push(address);
  }

  useOutsideAlerter(profileContainer, () => {
    setState(prevState => ({ ...prevState, profileDetailsOpen: false }));
  });

  useOutsideAlerter(clientContainer, () => {
    setState(prevState => ({ ...prevState, clientDetailsOpen: false }));
  });

  // Show facility logo if client only has one facility
  const displayClientLogo =
    clientPortalStore?.client?.facilities?.length === 1
      ? clientPortalStore?.client?.facilities[0]?.logo_source
      : clientPortalStore?.client?.logo_source_url;

  return (
    <div className="client-portal-navigation">
      {/* <MetaPixel pixelID={clientPortalStore?.selectedFacility?.facebook_pixel_code ?? undefined} /> */}
      <GoogleAnalytics
        title={
          clientPortalStore?.client?.long_name ? `${clientPortalStore?.client?.long_name} Portal` : "Tee On Portal"
        }
        tagID={clientPortalStore?.selectedFacility?.google_analytics_code ?? undefined}
      />
      <div className="parent-container">
        <div className="options-container">
          {windowSize.width <= MOBILE_WIDTH && (state.clientDetailsOpen || state.profileDetailsOpen) && (
            <div
              className="client-dropdown-backdrop"
              onClick={() =>
                setState(prevState => ({ ...prevState, clientDetailsOpen: false, profileDetailsOpen: false }))
              }
            ></div>
          )}
          <div
            className="user-info-parent-container client-dropdown-container"
            ref={clientContainer}
            onClick={
              windowSize.width <= MOBILE_WIDTH && showNavOptions
                ? () => setState(prevState => ({ ...prevState, clientDetailsOpen: true }))
                : undefined
            }
          >
            <div className="client-logo-container">
              <img className="client-logo" src={displayClientLogo} alt="Client logo" />
            </div>
            {windowSize.width <= MOBILE_WIDTH &&
              showNavOptions &&
              (state.clientDetailsOpen ? (
                <FontAwesomeIcon icon={["fal", "chevron-up"]} />
              ) : (
                <FontAwesomeIcon icon={["fal", "chevron-down"]} />
              ))}
            <div
              className={classNames("profile-dropdown-container", {
                "display-user-info-dropdown": state.clientDetailsOpen,
              })}
            >
              <div className="dropdown-image-container">
                <div className="client-logo-container">
                  <img className="client-logo" src={displayClientLogo} alt="Client logo" />
                </div>
                <FontAwesomeIcon
                  onClick={e => {
                    e.stopPropagation();
                    setState(prevState => ({ ...prevState, clientDetailsOpen: false }));
                  }}
                  icon={["far", "xmark"]}
                  className="profile-close-button"
                  fixedWidth
                />
              </div>
              <div onClick={() => sendToPage(HOME_URL)} className="profile-action">
                <FontAwesomeIcon fixedWidth className="profile-action-icon" icon={["far", "golf-flag-hole"]} />
                <span>Tee Times</span>
              </div>
              <div
                onClick={() => sendToPage(`/online-store/${clientPortalStore?.selectedFacility?.short_name}`, true)}
                className="profile-action"
              >
                <FontAwesomeIcon fixedWidth className="profile-action-icon" icon={["far", "cart-shopping"]} />
                <span>Store</span>
              </div>
              {clientPortalStore?.user && (
                <>
                  <div
                    onClick={() => sendToPage(`/portal/${clientShortName}/bookings-list`)}
                    className="profile-action"
                  >
                    <FontAwesomeIcon fixedWidth className="profile-action-icon" icon={["far", "calendar"]} />
                    <span>Bookings</span>
                  </div>
                  <div onClick={() => sendToPage(`/portal/${clientShortName}/memberships`)} className="profile-action">
                    <FontAwesomeIcon fixedWidth className="profile-action-icon" icon={["far", "id-card-clip"]} />
                    <span>Memberships</span>
                  </div>
                </>
              )}
              <div onClick={() => sendToPage(`/portal/${clientShortName}/events`)} className="profile-action">
                <FontAwesomeIcon fixedWidth className="profile-action-icon" icon={["far", "trophy"]} />
                <span>Events</span>
              </div>
              <div onClick={() => sendToPage(`/portal/${clientShortName}/calendar`)} className="profile-action">
                <FontAwesomeIcon fixedWidth className="profile-action-icon" icon={["far", "calendars"]} />
                <span>Calendar</span>
              </div>
            </div>
          </div>
          <div
            className={classNames("options-container options-bar", {
              "options-hide": !showNavOptions || windowSize.width <= MOBILE_WIDTH,
            })}
          >
            <NavLink to={HOME_URL} activeClassName="option-button-active">
              Tee Times
            </NavLink>
            <NavLink
              to={`/online-store/${clientPortalStore?.selectedFacility?.short_name}`}
              activeClassName="option-button-active"
              target="_blank"
            >
              Store
            </NavLink>
            {clientPortalStore?.user && (
              <>
                <NavLink to={`/portal/${clientShortName}/bookings-list`} activeClassName="option-button-active">
                  Bookings
                </NavLink>
                <NavLink to={`/portal/${clientShortName}/memberships`} activeClassName="option-button-active">
                  Memberships
                </NavLink>
              </>
            )}
            <NavLink to={`/portal/${clientShortName}/events`} activeClassName="option-button-active">
              Events
            </NavLink>
            <NavLink to={`/portal/${clientShortName}/calendar`} activeClassName="option-button-active">
              Calendar
            </NavLink>
          </div>
        </div>

        {clientPortalStore?.user ? (
          <div
            className="user-info-parent-container"
            ref={profileContainer}
            onClick={() => setState(prevState => ({ ...prevState, profileDetailsOpen: true }))}
          >
            <p className="user-info-name">
              Hi, {clientPortalStore?.user?.first_name}{" "}
              {clientPortalStore?.user?.last_name
                ? `${clientPortalStore?.user?.last_name?.charAt(0)?.toUpperCase()}.`
                : ""}
            </p>
            <div className="profile-image-container">
              {clientPortalStore?.user?.profile_image_source ? (
                <img
                  className="profile-image"
                  src={clientPortalStore?.user?.profile_image_source}
                  alt="User profile image"
                />
              ) : (
                <div className="client-portal-profile-default-image">
                  <p>
                    {clientPortalStore?.user?.first_name?.charAt(0)?.toUpperCase()}
                    {clientPortalStore?.user?.last_name?.charAt(0)?.toUpperCase()}
                  </p>
                </div>
              )}
            </div>

            <div
              className={classNames("profile-dropdown-container", {
                "display-user-info-dropdown": state.profileDetailsOpen,
              })}
            >
              <FontAwesomeIcon
                onClick={e => {
                  e.stopPropagation();
                  setState(prevState => ({ ...prevState, profileDetailsOpen: false }));
                }}
                icon={["far", "xmark"]}
                className="profile-close-button"
              />
              <div className="profile-user-info-container">
                <div className="profile-image-container">
                  {clientPortalStore?.user?.profile_image_source ? (
                    <img
                      className="profile-image"
                      src={clientPortalStore?.user?.profile_image_source}
                      alt="User profile image"
                    />
                  ) : (
                    <div className="client-portal-profile-default-image">
                      <p>
                        {clientPortalStore?.user?.first_name?.charAt(0)?.toUpperCase()}
                        {clientPortalStore?.user?.last_name?.charAt(0)?.toUpperCase()}
                      </p>
                    </div>
                  )}
                </div>
                <div>
                  <p className="profile-user-name">Hello, {`${clientPortalStore?.user?.full_name}`}</p>
                  <p className="profile-user-email">{clientPortalStore?.user?.email}</p>
                </div>
              </div>
              <div
                className="profile-actions-container"
                onClick={e => {
                  e.stopPropagation();
                  setState(prevState => ({ ...prevState, profileDetailsOpen: false }));
                }}
              >
                <div onClick={() => sendToPage("/tee-on/portal/overview", true)} className="profile-action">
                  <FontAwesomeIcon className="profile-action-icon" icon={["far", "user"]} />
                  <span>Account Overview</span>
                </div>
                <div onClick={logoutUser} className="profile-action">
                  <FontAwesomeIcon className="profile-action-icon" icon={["far", "right-from-bracket"]} />
                  <span>Logout</span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div onClick={sendToLogin}>
            <p className="client-portal-login-button">
              Login <FontAwesomeIcon icon={["fal", "arrow-right-long"]} />
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
