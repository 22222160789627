import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { GetTournament } from "api/rpc/facilityAdmin/tournament/tournament";
import { GetFacility } from "api/rpc/2022-09/facilityAdmin/facility/facility";
import { PutTournament } from "api/rpc/2022-09/facilityAdmin/tournament/tournament";

import { showError, showSuccess } from "redux/actions/ui";
import { ITournament } from "redux/reducers/models/tournament";
import { IFacility } from "redux/reducers/models/facility";
import { useAppDispatch } from "hooks/redux";

import { ButtonNew as Button } from "components/buttonNew";
import Card from "components/card/Card";
import Page from "components/page/Page";
import TextField from "components/form/textField/TextField";

import "./Tournament.scss";

interface ITournamentState {
  tournament: ITournament;
  facility: IFacility;
  tee_sheet_note: string;
}

export default function Tournament() {
  const { tournamentId } = useParams<{ tournamentId: string }>();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const [state, setState] = useState<ITournamentState>({
    tournament: undefined,
    facility: undefined,
    tee_sheet_note: "",
  });

  useEffect(() => {
    void loadTournament();
  }, []);

  async function loadTournament() {
    const numericTournamentId = Number(tournamentId);
    const tournamentRes = await GetTournament({ id: numericTournamentId }, false);

    if (tournamentRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.tournament.tournament.001")));
      return;
    }

    const tournament = tournamentRes.data[0];

    setState(prevState => ({
      ...prevState,
      tournament,
      tee_sheet_note: tournament?.tee_sheet_note ? tournament?.tee_sheet_note : "",
    }));

    const facilityRes = await GetFacility({ id: tournament.facility_id }, true);

    if (facilityRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.tournament.tournament.002")));
      return;
    }

    const facility = facilityRes.data?.[0];

    if (facilityRes.status === StatusCode.OK) {
      setState(prevState => ({
        ...prevState,
        facility,
      }));
    }
  }

  function handleNoteInputChange(event: any) {
    const { id, value } = event.target;
    setState(prev => ({
      ...prev,
      [id]: value,
    }));
  }

  async function handleSaveNote() {
    const params = {
      id: tournamentId,
      tee_sheet_note: state.tee_sheet_note,
    };

    const tournamentRes = await PutTournament(params, true);

    if (tournamentRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.tournament.tournament.006")));
      return;
    }
    dispatch(showSuccess(t("secure.facility.tournament.tournament.007"))); // TODO: Translation
  }

  return state.tournament ? (
    <Page title={state.tournament.name} narrow>
      {state.facility && <></>}
    </Page>
  ) : (
    <div />
  );
}
