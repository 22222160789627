import { ITournament } from "./models/tournament";

export interface ITournamentState {
  isLoaded: boolean;
  error?: any;
  tournament: ITournament;
}

export interface ITournamentAction {
  type: string;
  payload: any;
}

const initialState: ITournamentState = {
  isLoaded: false,
  error: null,
  tournament: null,
};

export default function TournamentReducer(state = initialState, action: ITournamentAction) {
  switch (action.type) {
    case "tournament.update":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
