import { useState, useEffect } from "react";

export interface IWindowSize {
  width: number;
  height: number;
}

export function useWindowSize(): IWindowSize {
  const [windowSize, setWindowSize] = useState<IWindowSize>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function handleResize() {
    setWindowSize(prev => ({
      ...prev,
      width: window.innerWidth,
      height: window.innerHeight,
    }));
  }

  return windowSize;
}
