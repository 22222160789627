import TeeSheetSettings from "./teesheets/TeeSheetSettings";
import TeeSheetCalendar from "./teesheets/calendar/TeeSheetCalendar";
import TeeSheetGeneral from "containers/facility/settings/teesheets/teeSheetGeneral";

import TeeSheetPricingSheet from "./teesheets/pricingSheets/TeeSheetPricingSheet";
import TeeSheetPricingSheets from "./teesheets/pricingSheets/TeeSheetPricingSheets";
import TeeSheetTemplates from "./teesheets/templates/TeeSheetTemplates";
import TeeSheetBookingEngines from "./teesheets/bookingEngines/BookingEngines";
import TeeSheetBookingEngine from "./teesheets/bookingEngines/BookingEngine";
import TeeSheetTemplateNew from "./teesheets/templates/TeeSheetTemplateNew";
import TeeSheetTemplate from "../../../../../src/containers/facility/settings/teesheets/TeeSheetTemplate";
import TeeSheetNotifications from "./teesheets/notifications/TeeSheetNotifications";
import TeeSheetNotificationNew from "./teesheets/notifications/TeeSheetNotificationNew";
import TeeSheetNotification from "./teesheets/notifications/TeeSheetNotification";
import CartWaiver from "./teesheets/cartWaiver/CartWaiver";
import CardReaders from "./cardReader/CardReaders";
import CardReaderNew from "../../../../../src/containers/facility/settings/cardReader/cardReaderNew";
import CardReader from "./cardReader/CardReader";
import TeeSheetCustomerType from "./teesheets/customerTypeConfig/TeeSheetCustomerType";
import Registers from "./register/Registers";
import RegisterNew from "./register/RegisterNew";
import Register from "./register/Register";
import RegisterGroups from "./register/Groups";
import RegisterGroup from "containers/facility/registerGroup";
import RegisterGroupNew from "./register/GroupNew";
import TeeSheetCheckIn from "containers/facility/settings/teesheets/teeSheetCheckIn";
import Details from "./facility/FacilityEdit";
import CartInventory from "./teesheets/CartInventory";
import NewCartInventory from "./teesheets/NewCartInventory";
import BookingCategories from "./teesheets/BookingCategories";
import EmailTemplates from "./facility/emailTemplates/EmailTemplates";
import EmailTemplateNew from "./facility/emailTemplates/EmailTemplateNew";
import EmailTemplateEdit from "./facility/emailTemplates/EmailTemplateEdit";

import Courses from "./facility/Courses";
import CourseNew from "./facility/CourseNew";
import CourseEdit from "./facility/CourseEdit";

import Divisions from "./facility/Divisions";
import Division from "./facility/Division";
import DivisionNew from "./facility/DivisionNew";

import TeeDecks from "./facility/TeeDecks";
import TeeDeck from "containers/facility/settings/facility/teeDeckEdit";
import TeeDeckNew from "containers/facility/settings/facility/teeDeckNew";

import Staff from "./facility/FacilityStaff";
import StaffEdit from "../../../../containers/facility/settings/facility/facilityStaffEdit";
import StaffNew from "./facility/FacilityStaffNew";

import PaymentManage from "./payments/PaymentManage";
import TeeOnPayments from "./payments/TeeOnPayments";

import Printers from "./printer/Printers";
import Printer from "../../../../../src/containers/facility/settings/printers/printer";
import PrinterNew from "../../../../../src/containers/facility/settings/printers/printerNew";

import PaymentOptions from "./facility/FacilityPaymentOptions";
import PaymentOptionsNew from "./facility/FacilityPaymentOptionsNew";
import PaymentOptionsEdit from "./facility/FacilityPaymentOptionsEdit";
import CustomerTypes from "./customertypes/CustomerTypes";
import CustomerTypesNew from "./customertypes/CustomerTypesNew";
import CustomerTypesEdit from "./customertypes/CustomerTypesEdit";
import BankAccounts from "./bankAccounts/BankAccounts";
import BankAccountNew from "./bankAccounts/BankAccountNew";
import BankAccountDetails from "./bankAccounts/BankAccountDetails";

import Discounts from "./discount/Discounts";
import NewDiscount from "../../../../containers/facility/settings/discount/discountNew";
import EditDiscount from "../../../../containers/facility/settings/discount/discountEdit";
// import CashOutNew from "../../../../containers/facility/settings/cashOut/cashOutNew";
// import CashOutEdit from "../../../../containers/facility/settings/cashOut/cashOutEdit";

import Departments from "./departments/Departments";
import DepartmentsNew from "./departments/DepartmentsNew";
import DepartmentsEdit from "./departments/DepartmentsEdit";
import Payouts from "./payouts/Payouts";
import PayoutsInfo from "./payouts/PayoutsInfo";
import AccountingReferences from "./accountingReferences/AccountingReferences";
import AccountingReferencesNew from "./accountingReferences/AccountingReferencesNew";
import AccountingReferencesEdit from "./accountingReferences/AccountingReferencesEdit";
import Accounts from "./accounts/Accounts";
import AccountsNew from "./accounts/AccountNew";
import AccountEdit from "./accounts/AccountEdit";
import AccountOverview from "./accounts/AccountOverview";
import Folders from "../../../../containers/facility/settings/folders/folders";
import FoldersNew from "./folders/FoldersNew";
import FoldersEdit from "../../../../containers/facility/settings/folders/foldersEdit";
import ShotgunTemplates from "./teesheets/ShotGunTemplate/ShotGunTemplates";
import ShotGunTemplateNew from "../../../../containers/facility/settings/teesheets/shotGunTemplateNew";
import ShotGunTemplateEdit from "./teesheets/ShotGunTemplate/ShotGunTemplateEdit";
import TaxLines from "./taxes/taxes";
import TaxLinesNew from "./taxes/taxLinesNew";
import TaxNumbersEdit from "./taxes/taxNumbersEdit";
import TaxNumbersNew from "./taxes/taxNumbersNew";

import TaxLinesEdit from "./taxes/taxLinesEdit";
import ModifierGroup from "./modifierGroup/ModifierGroup";
import ModifierGroupNew from "./modifierGroup/ModifierGroupNew";
import ModifierGroupEdit from "../../../../containers/facility/settings/modifierGroups/modifierGroupEdit";
import Language from "./language/Language";
// import Tables from "./tables/Tables";

import { IRoutes } from "components/sideMenu/SideMenu";
// import TablesNew from "./tables/TablesNew";
// import TablesEdit from "./tables/TablesEdit";
import KitchenLocations from "./kitchen/KitchenLocations";
import KitchenLocation from "./kitchen/KitchenLocation";
import Shifts from "./shifts/Shifts";
import FloorPlanEdit from "../../../../../src/containers/facility/settings/floorPlan/floorPlanEdit";
import FloorPlanNew from "../../../../../src/containers/facility/settings/floorPlan/floorPlanNew";
import FloorPlans from "./floorPlan/floorPlans/FloorPlans";
import Dashboards from "./dashboards/Dashboards";
import DashboardsNew from "./dashboards/DashboardsNew";
import DashboardsEdit from "./dashboards/DashboardsEdit";
import TilesNew from "./dashboards/Tiles/TilesNew";
import TilesEdit from "./dashboards/Tiles/TilesEdit";
import Modules from "./reservations/Modules";
import ConfigSegment from "./reservations/ConfigSegment";
import ReservationTemplates from "./reservations/ReservationTemplates";
import ReservationTemplateNew from "./reservations/ReservationTemplateNew";
import ReservationTemplateEdit from "./reservations/ReservationTemplateEdit";
import CashOuts from "./cashOuts/CashOuts";
import CashOutNew from "../../../../containers/facility/settings/cashOut/cashOutNew";
import CashOutEdit from "../../../../containers/facility/settings/cashOut/cashOutEdit";
import Inventory from "./inventory/Inventory";
import InventoryLog from "./inventory/InventoryLog";
import NewReceiving from "./inventory/NewReceiving";
import KitchenCourse from "./kitchenCourse/KitchenCourse";
import KitchenCourseNew from "./kitchenCourse/KitchenCourseNew";
import KitchenCourseEdit from "./kitchenCourse/KitchenCourseEdit";
import FacilityForms from "./forms/FacilityForms";
import FacilityFormEdit from "./forms/FacilityFormEdit";
import PrizeAccounts from "./prizeAccounts/PrizeAccounts";
import PrizeAccount from "./prizeAccounts/PrizeAccount";
import PrizeAccountView from "./prizeAccounts/PrizeAccountView";
import PrizeAccountNew from "./prizeAccounts/PrizeAccountNew";
import BookingFee from "./teesheets/bookingFees/BookingFee.tsx";
import BookingFees from "./teesheets/bookingFees/BookingFees";
import Vendors from "./vendors/Vendors";
import VendorsNew from "./vendors/VendorsNew";
import VendorsEdit from "./vendors/VendorsEdit";
import ProductTypes from "./productTypes/ProductTypes";
import ProductTypeEdit from "./productTypes/ProductTypeEdit";
import ProductTypeNew from "./productTypes/ProductTypeNew";
import CustomerTags from "./customerTags/CustomerTags";
import CustomerTagsNew from "./customerTags/CustomerTagsNew";
import EditCustomerTags from "./customerTags/EditCustomerTags";
import InventoryTransfers from "./inventoryTransfer/InventoryTransfers";
import NewInventoryTransfer from "./inventoryTransfer/NewInventoryTransfer";
import ViewInventoryTransfer from "./inventoryTransfer/ViewInventoryTransfer";
import EditInventoryTransfer from "./inventoryTransfer/EditInventoryTransfer";
import InventoryCounts from "./inventoryCounts/InventoryCounts";
import NewInventoryCount from "./inventoryCounts/NewInventoryCount";
import ViewInventoryCount from "./inventoryCounts/ViewInventoryCount";
import InventoryLabels from "./inventoryLabels/InventoryLabels";
import TeeSheetDynamicPricing from "./teesheets/dynamicPricing/dynamicPricing/TeeSheetDynamicPricing";
import TeeSheetDynamicPricingEdit from "./teesheets/dynamicPricing/dynamicPricingEdit/TeeSheetDynamicPricingEdit";
import TeeSheetDynamicPricingNew from "./teesheets/dynamicPricing/dynamicPricingNew/TeeSheetDynamicPricingNew";
import EditInventoryCount from "./inventoryCounts/EditInventoryCount";
import ProductGroups from "./productGroups/ProductGroups";
import ProductGroupNew from "./productGroups/ProductGroupNew";

const routes: IRoutes[] = [
  // Tee Sheets
  {
    path: "/admin/settings/tee-sheet",
    component: TeeSheetSettings,
  },

  {
    path: "/admin/settings/tee-sheet/general",
    component: TeeSheetGeneral,
  },
  {
    path: "/admin/settings/tee-sheet/calendar",
    component: TeeSheetCalendar,
  },
  {
    path: "/admin/settings/tee-sheet/template",
    component: TeeSheetTemplates,
  },
  {
    path: "/admin/settings/tee-sheet/template/new",
    component: TeeSheetTemplateNew,
  },
  {
    path: "/admin/settings/tee-sheet/template/:templateId",
    component: TeeSheetTemplate,
  },
  {
    path: "/admin/settings/tee-sheet/pricing-sheet",
    component: TeeSheetPricingSheets,
  },
  {
    path: "/admin/settings/tee-sheet/pricing-sheet/:pricingSheetId",
    component: TeeSheetPricingSheet,
  },
  {
    path: "/admin/settings/tee-sheet/check-in-config",
    component: TeeSheetCheckIn,
  },
  {
    path: "/admin/settings/tee-sheet/shotgun-templates",
    component: ShotgunTemplates,
  },
  {
    path: "/admin/settings/tee-sheet/shotgun-templates/new",
    component: ShotGunTemplateNew,
  },
  {
    path: "/admin/settings/tee-sheet/shotgun-templates/:id",
    component: ShotGunTemplateEdit,
  },
  {
    path: "/admin/settings/tee-sheet/customer-type-config",
    component: TeeSheetCustomerType,
  },
  {
    path: "/admin/settings/tee-sheet/booking-engine",
    component: TeeSheetBookingEngines,
  },
  {
    path: "/admin/settings/tee-sheet/booking-engine/:bookingEngineId",
    component: TeeSheetBookingEngine,
  },
  {
    path: "/admin/settings/tee-sheet/notification",
    component: TeeSheetNotifications,
  },
  {
    path: "/admin/settings/tee-sheet/notification/new",
    component: TeeSheetNotificationNew,
  },
  {
    path: "/admin/settings/tee-sheet/notification/:notificationId",
    component: TeeSheetNotification,
  },
  {
    path: "/admin/settings/tee-sheet/booking-fees",
    component: BookingFees,
  },
  {
    path: "/admin/settings/tee-sheet/booking-fee/:feeId",
    component: BookingFee,
  },
  {
    path: "/admin/settings/tee-sheet/booking-categories",
    component: BookingCategories,
  },
  {
    path: "/admin/settings/email-template",
    component: EmailTemplates,
  },
  {
    path: "/admin/settings/email-template/new",
    component: EmailTemplateNew,
  },
  {
    path: "/admin/settings/email-template/:templateId",
    component: EmailTemplateEdit,
  },
  {
    path: "/admin/settings/tee-sheet/dynamic-pricing",
    component: TeeSheetDynamicPricing,
  },
  {
    path: "/admin/settings/tee-sheet/dynamic-pricing/new",
    component: TeeSheetDynamicPricingNew,
  },
  {
    path: "/admin/settings/tee-sheet/dynamic-pricing/:templateId",
    component: TeeSheetDynamicPricingEdit,
  },

  // Registers
  {
    path: "/admin/settings/registers",
    component: Registers,
  },
  {
    path: "/admin/settings/registers/new",
    component: RegisterNew,
  },
  {
    path: "/admin/settings/registers/:registerId",
    component: Register,
  },
  {
    path: "/admin/settings/register-group",
    component: RegisterGroups,
  },
  {
    path: "/admin/settings/register-group/new",
    component: RegisterGroupNew,
  },
  {
    path: "/admin/settings/register-group/:registerGroupId",
    component: RegisterGroup,
  },
  // Cash Out
  {
    path: "/admin/settings/cash-out",
    component: CashOuts,
  },
  {
    path: "/admin/settings/cash-out/new",
    component: CashOutNew,
  },
  {
    path: "/admin/settings/cash-out/:cashOutId",
    component: CashOutEdit,
  },
  // Card Readers
  {
    path: "/admin/settings/card_reader",
    component: CardReaders,
  },
  {
    path: "/admin/settings/card_reader/new",
    component: CardReaderNew,
  },
  {
    path: "/admin/settings/card_reader/:id",
    component: CardReader,
  },

  // Facility Details
  {
    path: "/admin/settings/details",
    component: Details,
  },

  // Facility Courses
  {
    path: "/admin/settings/course",
    component: Courses,
  },
  {
    path: "/admin/settings/course/new",
    component: CourseNew,
  },
  {
    path: "/admin/settings/course/:courseId",
    component: CourseEdit,
  },

  // Facility Divisions
  {
    path: "/admin/settings/division",
    component: Divisions,
  },
  {
    path: "/admin/settings/division/new",
    component: DivisionNew,
  },
  {
    path: "/admin/settings/division/:divisionId",
    component: Division,
  },

  // Facility Tee Decks
  {
    path: "/admin/settings/tee_deck",
    component: TeeDecks,
  },
  {
    path: "/admin/settings/tee_deck/new",
    component: TeeDeckNew,
  },
  {
    path: "/admin/settings/tee_deck/:teeDeckId",
    component: TeeDeck,
  },

  // Printers
  {
    path: "/admin/settings/printers",
    component: Printers,
  },
  {
    path: "/admin/settings/printers/new",
    component: PrinterNew,
  },
  {
    path: "/admin/settings/printers/:printerId",
    component: Printer,
  },

  // Staff Accounts
  {
    path: "/admin/settings/staff",
    component: Staff,
  },
  {
    path: "/admin/settings/staff_new",
    component: StaffNew,
  },
  {
    path: "/admin/settings/staff_edit/:id",
    component: StaffEdit,
  },

  // Facility Payment Options
  {
    path: "/admin/settings/facility-payment-options",
    component: PaymentOptions,
  },
  {
    path: "/admin/settings/facility-payment-options/new",
    component: PaymentOptionsNew,
  },
  {
    path: "/admin/settings/facility-payment-options/:paymentOptionId",
    component: PaymentOptionsEdit,
  },

  //Customer Types
  {
    path: "/admin/settings/customer_types",
    component: CustomerTypes,
  },
  {
    path: "/admin/settings/customer_types/new",
    component: CustomerTypesNew,
  },
  {
    path: "/admin/settings/customer_types/:id",
    component: CustomerTypesEdit,
  },

  //Taxes
  {
    path: "/admin/settings/taxes",
    component: TaxLines,
  },

  {
    path: "/admin/settings/taxes/line/new",
    component: TaxLinesNew,
  },

  {
    path: "/admin/settings/taxes/line/:id",
    component: TaxLinesEdit,
  },

  {
    path: "/admin/settings/taxes/number/new",
    component: TaxNumbersNew,
  },

  {
    path: "/admin/settings/taxes/number/:id",
    component: TaxNumbersEdit,
  },

  // Bank Accounts
  {
    path: "/admin/settings/bank_accounts",
    component: BankAccounts,
  },
  {
    path: "/admin/settings/bank_accounts/new",
    component: BankAccountNew,
  },
  {
    path: "/admin/settings/bank_accounts/:bankAccountId",
    component: BankAccountDetails,
  },

  // Payment Setup
  {
    path: "/admin/settings/payments/manage",
    component: PaymentManage,
  },
  {
    path: "/admin/settings/payments/manage/tee-on-payments",
    component: TeeOnPayments,
  },

  // Discounts
  {
    path: "/admin/settings/discount",
    component: Discounts,
  },
  {
    path: "/admin/settings/discount/new",
    component: NewDiscount,
  },
  {
    path: "/admin/settings/discount/:id",
    component: EditDiscount,
  },

  //Departments
  {
    path: "/admin/settings/departments",
    component: Departments,
  },

  {
    path: "/admin/settings/departments/new",
    component: DepartmentsNew,
  },
  {
    path: "/admin/settings/departments/:id",
    component: DepartmentsEdit,
  },

  //Payouts
  {
    path: "/admin/settings/payouts",
    component: Payouts,
  },
  {
    path: "/admin/settings/payouts/:id",
    component: PayoutsInfo,
  },

  //Accounting References
  {
    path: "/admin/settings/accounting_references",
    component: AccountingReferences,
  },
  {
    path: "/admin/settings/accounting_references/new",
    component: AccountingReferencesNew,
  },
  {
    path: "/admin/settings/accounting_references/:id",
    component: AccountingReferencesEdit,
  },

  // Accounts
  {
    path: "/admin/settings/accounts",
    component: Accounts,
  },
  {
    path: "/admin/settings/accounts/new",
    component: AccountsNew,
  },
  {
    path: "/admin/settings/accounts/edit/:accountId",
    component: AccountEdit,
  },
  {
    path: "/admin/settings/accounts/overview/:accountId",
    component: AccountOverview,
  },

  //Folders
  {
    path: "/admin/settings/folders",
    component: Folders,
  },
  {
    path: "/admin/settings/folders/new",
    component: FoldersNew,
  },
  {
    path: "/admin/settings/folders/:id",
    component: FoldersEdit,
  },

  // Cart Inventory
  {
    path: "/admin/settings/cart-inventory",
    component: CartInventory,
  },
  {
    path: "/admin/settings/cart-inventory/new",
    component: NewCartInventory,
  },

  //Vendors
  {
    path: "/admin/settings/vendors",
    component: Vendors,
  },
  {
    path: "/admin/settings/vendors/new",
    component: VendorsNew,
  },
  {
    path: "/admin/settings/vendors/:id",
    component: VendorsEdit,
  },

  // Cart Waiver
  {
    path: "/admin/settings/cart-waiver",
    component: CartWaiver,
  },

  //Modifier Groups
  {
    path: "/admin/settings/modifier_group",
    component: ModifierGroup,
  },
  {
    path: "/admin/settings/modifier_group/new",
    component: ModifierGroupNew,
  },
  {
    path: "/admin/settings/modifier_group/:id",
    component: ModifierGroupEdit,
  },

  // Language
  {
    path: "/admin/settings/language/",
    component: Language,
  },

  //Tables
  // {
  //   path: "/admin/settings/tables",
  //   component: Tables,
  // },
  // {
  //   path: "/admin/settings/tables/new",
  //   component: TablesNew,
  // },
  // {
  //   path: "/admin/settings/tables/:id",
  //   component: TablesEdit,
  // },

  // Floor Plan
  {
    path: "/admin/settings/floor-plan",
    component: FloorPlans,
  },
  {
    path: "/admin/settings/floor-plan/new",
    component: FloorPlanNew,
  },
  {
    path: "/admin/settings/floor-plan/:floorPlanId",
    component: FloorPlanEdit,
  },
  //Kitchen
  {
    path: "/admin/settings/kitchen-location",
    component: KitchenLocations,
  },
  {
    path: "/admin/settings/kitchen-location/:locationId",
    component: KitchenLocation,
  },

  //Courses
  {
    path: "/admin/settings/courses",
    component: KitchenCourse,
  },

  {
    path: "/admin/settings/courses/new",
    component: KitchenCourseNew,
  },

  {
    path: "/admin/settings/courses/:id",
    component: KitchenCourseEdit,
  },

  //Shifts
  {
    path: "/admin/settings/shifts",
    component: Shifts,
  },
  //Dashboards
  {
    path: "/admin/settings/dashboards",
    component: Dashboards,
  },
  {
    path: "/admin/settings/dashboards/new",
    component: DashboardsNew,
  },
  {
    path: "/admin/settings/dashboards/:id",
    component: DashboardsEdit,
  },

  //Dashboard Tiles
  {
    path: "/admin/settings/tiles/new",
    component: TilesNew,
  },
  {
    path: "/admin/settings/tiles/:id",
    component: TilesEdit,
  },
  {
    path: "/admin/settings/reservations/modules",
    component: Modules,
  },
  {
    path: "/admin/settings/reservations/module/:id",
    component: ConfigSegment,
  },
  {
    path: "/admin/settings/reservations/templates",
    component: ReservationTemplates,
  },
  {
    path: "/admin/settings/reservations/templates/new",
    component: ReservationTemplateNew,
  },
  {
    path: "/admin/settings/reservations/templates/:templateId",
    component: ReservationTemplateEdit,
  },

  //Inventory - Receiving
  {
    path: "/admin/settings/inventory",
    component: Inventory,
  },

  {
    path: "/admin/settings/inventory/new",
    component: NewReceiving,
  },

  {
    path: "/admin/settings/inventory/:id",
    component: InventoryLog,
  },
  //Inventory Transfer
  {
    path: "/admin/settings/transfer",
    component: InventoryTransfers,
  },

  {
    path: "/admin/settings/transfer/new",
    component: NewInventoryTransfer,
  },

  {
    path: "/admin/settings/transfer/:id/view",
    component: ViewInventoryTransfer,
  },

  {
    path: "/admin/settings/transfer/:id/edit",
    component: EditInventoryTransfer,
  },

  //Inventory Counts
  {
    path: "/admin/settings/counts",
    component: InventoryCounts,
  },

  {
    path: "/admin/settings/counts/new/:id",
    component: NewInventoryCount,
  },

  {
    path: "/admin/settings/counts/view/:id",
    component: ViewInventoryCount,
  },

  {
    path: "/admin/settings/counts/edit/:id",
    component: EditInventoryCount,
  },

  //Inventory - Labels
  {
    path: "/admin/settings/labels",
    component: InventoryLabels,
  },

  {
    path: "/admin/settings/forms",
    component: FacilityForms,
  },
  {
    path: "/admin/settings/forms/:id",
    component: FacilityFormEdit,
  },

  {
    path: "/admin/settings/types",
    component: ProductTypes,
  },
  {
    path: "/admin/settings/types/new",
    component: ProductTypeNew,
  },
  {
    path: "/admin/settings/types/:id",
    component: ProductTypeEdit,
  },

  //Groups
  {
    path: "/admin/settings/groups",
    component: ProductGroups,
  },
  {
    path: "/admin/settings/groups/new",
    component: ProductGroupNew,
  },
  //Prize Accounts
  {
    path: "/admin/settings/prizeAccounts",
    component: PrizeAccounts,
  },
  {
    path: "/admin/settings/prizeAccounts/view/:id",
    component: PrizeAccountView,
  },
  {
    path: "/admin/settings/prizeAccounts/edit/:id",
    component: PrizeAccount,
  },

  {
    path: "/admin/settings/prizeAccounts/new",
    component: PrizeAccountNew,
  },

  //Customer Tags
  {
    path: "/admin/settings/customer_tags",
    component: CustomerTags,
  },
  {
    path: "/admin/settings/customer_tags/new",
    component: CustomerTagsNew,
  },
  {
    path: "/admin/settings/customer_tags/:id",
    component: EditCustomerTags,
  },
];

export default routes;
