import React, { useState } from "react";
import classNames from "classnames";

interface IRowProps {
  prefixCls?: string;
  className?: string;
  row: string | number;
  onClick?: any;
  key?: any;
}

const Row: React.FC<IRowProps> = (props) => {
  const { children, prefixCls = "teesheet-card-row", className, row, ...rest } = props;

  const cls = classNames(prefixCls, className);

  const clonedChildren = React.Children.map(children, (child, i) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { row });
    }
  });

  return (
    <div className={cls} {...rest}>
      {clonedChildren}
    </div>
  );
};

export default Row;
