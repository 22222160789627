import React, { useEffect, useRef, useState } from "react";
import { useAppSelector, useAppDispatch } from "hooks/redux";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import {
  GetTeeTimeLimited,
  GetTeeSheetNotifications,
  GetTeeTimeBookingCategory,
} from "api/rpc/2024-04/masterAdmin/teesheet/teeSheet";
import { StatusCode } from "api/protocols";

import { MOBILE_WIDTH, TABLET_WIDTH } from "helpers/ScreenSizes";
import { formatDate } from "helpers/Helpers";

import { selectDate } from "redux/actions/masterAdmin/teesheet";
import { showError } from "redux/actions/ui";
import { loadFacilityWeather, loadAdminFacility } from "redux/actions/masterAdmin/facility";

import NewStatisticsBar from "./elements/NewStatisticsBar";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import DateBar from "elements/teesheet/DateBar";
import SelectNew from "components/select/SelectNew";
import NewTeeSheetTabs from "./NewTeeSheetTabs";
import Callout from "components/callout/Callout";
import Spin from "components/spin/spin";
import { Select } from "components/select/index";
import teeSheetToast from "components/teeSheetToast/TeeSheetToast";

import { INotification } from "api/rpc/2024-04/customer/teesheet";
import { ICourse, IDivision, IFacility } from "redux/reducers/models/facility";
import { ITeeTimeNew, ITeeTimeBookingCategory } from "redux/reducers/models/teetime";

// import "../styles.scss";

interface INewTeeSheetState {
  reloadTeeTimes: boolean;
  notifications: INotification[];
  currentCourseId: number;
  currentDivisions: IDivision[];
  currentDivisionId: number;
  currentFacilityId: number;
}

export default function NewTeeSheet() {
  const windowSize = useWindowSize();
  const { masterClientStore, masterFacilityStore, masterTeeSheetStore } = useAppSelector(store => store);

  console.log("Selected Date", masterTeeSheetStore.selectedDate);

  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const tabletDateBar = useRef<HTMLDivElement>(null);

  const [teeTimes, setTeeTimes] = useState<ITeeTimeNew[]>(undefined);

  const [teeTimeBookingCategories, setTeeTimeBookingCategories] = useState<ITeeTimeBookingCategory[][]>(undefined);

  const [state, setState] = useState<INewTeeSheetState>({
    reloadTeeTimes: false,
    notifications: null,
    currentCourseId: null,
    currentDivisions: null,
    currentDivisionId: null,
    currentFacilityId: null,
  });

  useEffect(() => {
    if (masterFacilityStore.facility) {
      setState(prevState => ({
        ...prevState,
        currentDivisionId: masterFacilityStore.facility?.courses[0]?.divisions[0]?.id,
        currentDivisions: masterFacilityStore.facility?.courses[0]?.divisions,
        currentCourseId: masterFacilityStore.facility?.courses[0]?.id,
        currentFacilityId: masterFacilityStore.facility?.id,
      }));
    }
  }, [masterFacilityStore.facility]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadTeeSheetNotifications(
      masterTeeSheetStore.selectedDate as Date,
      masterFacilityStore.facility?.id,
      source.token,
    );
    return () => source.cancel();
  }, [masterTeeSheetStore.selectedDate]);

  // Update teetimes on course, division, or date change
  useEffect(() => {
    const source = axios.CancelToken.source();
    if (state.currentCourseId && state.currentDivisionId && masterTeeSheetStore.selectedDate) {
      if (state.currentDivisionId != -1) {
        void loadTeeTimes(state.currentDivisionId, undefined, source.token);
      } else {
        const divisions = state.currentDivisions.map((division: IDivision) => division.id);
        void loadTeeTimes(undefined, divisions, source.token);
      }
    }
    void loadTeeTimeBookingCategories(source.token);
    return () => source.cancel();
  }, [state.currentFacilityId, state.currentCourseId, state.currentDivisionId, masterTeeSheetStore.selectedDate]);

  function handleCourseChange(courseId: number) {
    let currentCourse: ICourse = undefined;
    if (masterFacilityStore?.facility?.courses?.length > 0) {
      currentCourse = masterFacilityStore.facility.courses.find(course => course.id === courseId);
    }

    if (currentCourse === undefined) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      currentCourseId: currentCourse.id,
      currentDivisions: currentCourse.divisions,
      currentDivisionId: currentCourse.divisions[0]?.id,
    }));
  }

  async function loadTeeTimes(division_id: number, division_ids: number[], token?: CancelToken) {
    setTeeTimes(undefined);

    const formattedDate = formatDate(masterTeeSheetStore.selectedDate as Date);

    const teetimesRes = await GetTeeTimeLimited(
      {
        facility_id: state.currentFacilityId,
        date: formattedDate,
        course_id: state.currentCourseId,
        division_id,
        division_ids,
      },
      token ? false : true,
      token,
    );

    if (token && token?.reason) {
      return;
    }

    if (teetimesRes.status !== StatusCode.OK) {
      return;
    }

    if (teetimesRes.status === StatusCode.OK) {
      setTeeTimes(teetimesRes.data);
      return;
    }
  }

  async function loadTeeTimeBookingCategories(token?: CancelToken) {
    const formattedDate = formatDate(masterTeeSheetStore.selectedDate as Date);

    const categoryRes = await GetTeeTimeBookingCategory(
      { date: formattedDate, facility_id: state.currentFacilityId },
      false,
      token,
    );

    if (token && token?.reason) {
      return;
    }

    if (categoryRes.status !== StatusCode.OK) {
      return;
    }

    setTeeTimeBookingCategories(categoryRes.data);
  }

  async function loadTeeSheetNotifications(date: Date, facility_id: number, token?: CancelToken) {
    teeSheetToast.show([]);

    const formattedDate: string = formatDate(date);
    const notificationRes = await GetTeeSheetNotifications(
      { facility_id: facility_id, date: formattedDate },
      false,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (notificationRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading tee sheet notifications")); // TODO: Translation
      return;
    }

    if (notificationRes.status === StatusCode.OK && notificationRes.data?.length !== 0) {
      const toastList: Array<{
        subject: string;
        content: string;
        duration?: number;
        onClose?: () => void;
      }> = notificationRes.data?.map((data: any) => {
        let duration = 10000;
        if (data.type !== "passive") {
          duration = null;
        }
        return {
          subject: data.subject,
          content: data.content,
          duration: duration,
        };
      });
      teeSheetToast.show(toastList);
    }

    setState(prevState => ({ ...prevState, notifications: notificationRes.data }));
  }

  function handleFacilityChange(facilityId: number) {
    void dispatch(loadAdminFacility({ id: facilityId, tee_sheet_settings: true }, false));
    void dispatch(loadFacilityWeather(state.currentFacilityId, true));
    let currentFacility: IFacility = undefined;
    if (masterFacilityStore?.facilities?.length > 0) {
      currentFacility = masterFacilityStore.facilities.find(facility => facility.id === facilityId);
    }

    if (facilityId === undefined) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      currentCourseId: currentFacility.courses[0]?.id,
      currentDivisions: currentFacility.courses[0]?.divisions,
      currentDivisionId: currentFacility.courses[0]?.divisions[0]?.id,
      currentFacilityId: facilityId,
    }));
  }

  function calculateDateBarNumberOfDaysDesktop() {
    if (windowSize.width > 1400) {
      return 7;
    } else if (windowSize.width > 1200) {
      return 5;
    } else if (windowSize.width > TABLET_WIDTH) {
      return 3;
    } else {
      return 0;
    }
  }

  function calculateDateBarNumberOfDaysTablet() {
    if (windowSize.width <= TABLET_WIDTH) {
      return 7;
    } else {
      return 0;
    }
  }

  function changeTeeSheetDate(selectedDate: Date) {
    setTeeTimes(undefined);
    console.log("Selected Date in Modal", selectedDate);
    dispatch(selectDate(selectedDate));
  }

  return (
    <>
      {masterFacilityStore.facility ? (
        <>
          <NewStatisticsBar teetimes={teeTimes} client={true} />

          <div className="content-container">
            {/* DESKTOP DATE SELECTOR */}
            <div className="flex flex-wrap-reverse flex-row gap-y-3 justify-between items-center mt-4 mb-4">
              <div className="teesheet-date-selection-header">
                <div className="teesheet-date-selection-header-input">
                  <DatePickerInput
                    months={1}
                    position={windowSize.width <= MOBILE_WIDTH ? "center" : "left"}
                    centeredInputText={windowSize.width <= MOBILE_WIDTH}
                    startingDate={masterTeeSheetStore.selectedDate as Date}
                    setStartingDate={changeTeeSheetDate}
                  />
                </div>
                {calculateDateBarNumberOfDaysDesktop() > 0 && (
                  <DateBar
                    numberOfDays={calculateDateBarNumberOfDaysDesktop()}
                    onClick={changeTeeSheetDate}
                    selectedDate={masterTeeSheetStore.selectedDate as Date}
                  />
                )}
              </div>
              <div className="teesheet-filters">
                {masterFacilityStore.facility &&
                  state.currentFacilityId &&
                  state.currentCourseId &&
                  state.currentDivisionId &&
                  state.currentDivisions && (
                    <>
                      {masterFacilityStore.facility.courses?.length > 1 && (
                        <div className="teesheet-filter">
                          <Select
                            placeholder="Course"
                            onChange={handleCourseChange}
                            defaultValue={state.currentCourseId}
                          >
                            {masterFacilityStore.facility.courses
                              .filter(course => course.scoring_only === false)
                              ?.map((course: any, i: any) => (
                                <Select.Option key={i} value={course.id} name={course.full_name}>
                                  <span>{course.full_name}</span>
                                </Select.Option>
                              ))}
                          </Select>
                        </div>
                      )}

                      <div className="teesheet-filter">
                        <Select
                          placeholder="Division"
                          onChange={val => setState(prevState => ({ ...prevState, currentDivisionId: val }))}
                          defaultValue={state.currentDivisionId}
                        >
                          {state.currentDivisions.map((division: any, i: any) => (
                            <Select.Option key={i} value={division.id} name={division.title}>
                              <span>{division.title}</span>
                            </Select.Option>
                          ))}
                          <Select.Option value={-1} name="Combined">
                            <span>Combined</span>
                          </Select.Option>
                        </Select>
                      </div>
                    </>
                  )}
              </div>
            </div>

            {/* TABLET DATE SELECTOR */}
            {calculateDateBarNumberOfDaysTablet() > 0 && (
              <div className="relative">
                <div
                  className="absolute flex justify-center items-center cursor-pointer text-left"
                  style={{
                    width: "15px",
                    top: "20px",
                    left: "-8px",
                    display: windowSize.width <= MOBILE_WIDTH ? "block" : "none",
                  }}
                  onClick={() => (tabletDateBar.current.scrollLeft -= 75)}
                >
                  <FontAwesomeIcon icon={"chevron-left"} />
                </div>
                <div className="overflow-x-scroll pb-2 mb-2" ref={tabletDateBar}>
                  <DateBar
                    numberOfDays={calculateDateBarNumberOfDaysTablet()}
                    onClick={changeTeeSheetDate}
                    selectedDate={masterTeeSheetStore.selectedDate as Date}
                  />
                </div>
                <div
                  className="absolute flex justify-center items-center cursor-pointer text-right"
                  style={{
                    width: "15px",
                    top: "20px",
                    right: "-8px",
                    display: windowSize.width <= MOBILE_WIDTH ? "block" : "none",
                  }}
                  onClick={() => (tabletDateBar.current.scrollLeft += 75)}
                >
                  <FontAwesomeIcon icon={"chevron-right"} />
                </div>
              </div>
            )}

            {!teeTimes ? (
              <div style={{ height: "24px", overflow: "hidden" }}>
                <Spin />
              </div>
            ) : (
              <>
                {teeTimes.length > 0 ? (
                  <NewTeeSheetTabs
                    teetimes={teeTimes}
                    teetimeBookingCategories={teeTimeBookingCategories}
                    divisions={state.currentDivisions}
                    reloadTeeTimes={() => {
                      const source = axios.CancelToken.source();
                      if (state.currentCourseId && state.currentDivisionId && masterTeeSheetStore.selectedDate) {
                        if (state.currentDivisionId != -1) {
                          void loadTeeTimes(state.currentDivisionId, undefined, source.token);
                        } else {
                          const divisions = state.currentDivisions.map((division: IDivision) => division.id);
                          void loadTeeTimes(undefined, divisions, source.token);
                        }
                      }
                      return () => source.cancel();
                    }}
                  />
                ) : (
                  <Callout
                    type="error"
                    title={t("elements.tee_sheet.tee_sheet_tabs.020")}
                    content={t("elements.tee_sheet.tee_sheet_tabs.021")}
                  />
                )}
              </>
            )}
          </div>
        </>
      ) : (
        <Callout type="error" title={"No Facility Selected"} content={"Please select a facility to view tee sheet"} />
      )}
    </>
  );
}
