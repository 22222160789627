import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useRef, useEffect, cloneElement } from "react";
import "./filterMenu.scss";

export interface IFilterMenuProps {
  title?: string;
  options: Array<any>;
  //Property name of the objects' title
  optionTitle: string;
  onClick: (option: any, selectedIndex: number) => void;
  defaultSelected?: number;
  iconName?: string;
  darkStyle?: boolean;
}

interface IFilterMenuState {
  selected: number;
}

export const FilterMenu: React.FC<IFilterMenuProps> = props => {
  const { title, options, optionTitle, onClick, defaultSelected } = props;

  const [state, setState] = useState({
    selected: defaultSelected ?? 0,
  });

  useEffect(() => {
    updateState({ selected: defaultSelected });
  }, [defaultSelected]);

  function updateState(newState: Partial<IFilterMenuState>) {
    setState((cur: IFilterMenuState) => {
      return { ...cur, ...newState };
    });
  }

  return (
    <>
      {props.title && <h1 className="ui-filter-menu-header">{title}</h1>}
      <ul className="ui-filter-menu">
        {options
          ?.sort((prev, next) => prev.position - next.position)
          ?.map((option: any, index: number) => {
            return (
              <li
                key={index}
                className={
                  "ui-filter-menu-option " +
                  (props?.darkStyle ? "ui-filter-menu-option-dark-style " : "") +
                  (index === state.selected
                    ? !props?.darkStyle
                      ? "ui-filter-menu-option-selected "
                      : "ui-filter-menu-option-selected-dark-style "
                    : "")
                }
                onClick={() => {
                  updateState({ selected: index });
                  onClick(option, index);
                }}
              >
                {option?.icon && (
                  <FontAwesomeIcon icon={["far", option?.icon]} fixedWidth style={{ marginRight: "20px" }} />
                )}
                <span>{option[optionTitle]}</span>
              </li>
            );
          })}
      </ul>
    </>
  );
};
