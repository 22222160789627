import React, { useEffect, useState } from "react";
import axios, { CancelToken } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";

import { StatusCode } from "api/protocols";
import { PostCheckout, PutCart } from "api/rpc/2022-09/facilityAdmin/cart/cart";
import { PostTransaction } from "api/rpc/2022-09/facilityAdmin/order/transaction";
import { PutOrderComplete, GetReceipt, GetOrder, PutVoidOrder } from "api/rpc/2022-09/facilityAdmin/order/order";
import { GetCustomer } from "api/rpc/2022-09/facilityAdmin/customer/customer";
import { GetGiftCardValidate } from "api/rpc/2022-09/facilityAdmin/payment/giftCard";
import { PostKitchenChit } from "api/rpc/2022-09/facilityAdmin/facility/kitchen";
import { usePOSPrinter } from "hooks/usePOSPrinter/usePOSPrinter";
import { GetRoomOccupants, IOccupant } from "api/rpc/room";
import { ICustomer } from "redux/reducers/models/customer";
import { ICart, ICartLineItem, IDiscountLines } from "redux/reducers/models/cart";

import {
  clearReaderDisplay,
  sheetInfo,
  processPayment as stripeProcessPayment,
  setReaderDisplay,
  convertOrderIntoDisplayInfo,
} from "helpers/StripeTerminalWrapper";
import { isEmpty } from "helpers/Helpers";
import { useStripeTerminalPayment } from "hooks/useStripeTerminalPayment/useStripeTerminalPayment";

import { IStripePaymentState } from "pages/secure/facility/teesheet/TeeSheet";
import PaymentMethods from "elements/register/cartMenu/PaymentMethods";
import CartTransactions from "elements/register/cartMenu/CartTransactions";

import Sheet from "components/sheet/Sheet";
import Popup from "components/popup/Popup";

import Divider from "components/divider";
import { ButtonNew as Button } from "components/buttonNew";
import { Select } from "components/select/index";
import CheckoutForm, { cancelManualCreditPaymentIntent } from "components/checkoutForm/CheckoutForm";
import Spin from "components/spin/spin";
import Input from "components/form/input/Input";
import Checkbox from "components/form/checkbox/Checkbox";
import OrderCompletePopup from "components/orderCompletePopup/OrderCompletePopup";

import "elements/teesheet/menu/teesheetMenu.scss";
import "elements/register/register.scss";
import GiftCardRedeem, { IGiftCardRedeemState } from "components/giftCardRedeem/GiftCardRedeem";
import GiftCardSell from "components/giftcardSell/GiftCardSell";
import AccountsModal from "components/accountsModal/AccountsModal";

import { useTranslation } from "react-i18next";
import LeagueSelectionModal from "components/leagueSelectionModal/LeagueSelectionModal";
import { IOrder } from "redux/reducers/models/order";
import { loadTableCart, postCart, putCustomer } from "redux/actions/cart";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { dequeue, enqueue, showError, showSuccess } from "redux/actions/ui";
import CreditBookModal from "components/creditBookModal/CreditBookModal";
import { IPrizeAccount } from "pages/secure/facility/settings/prizeAccounts/PrizeAccount";
import { cloneDeep, isEqual } from "lodash";
import SelectCustomer from "elements/register/SelectCustomer";
import CartLineItemsNew from "pages/secure/facility/register/registerNew/cartLineItemsNew/CartLineItemsNew";
import { useRegisterContext } from "./RegisterContext";
import UpdateLineItemModalTemp, { IUpdateLineItemModalState } from "./UpdateLineItemModalTemp";
import { DeleteDiscountLine } from "api/rpc/2024-04/facilityAdmin/order/cart/discountLine";
import ReactDOM from "react-dom";
import CartTotalsNew from "./cartTotalsNew/CartTotalsNew";
import { OrderProgress, StateType } from "../../teesheet/newTeeSheetDetails/NewTeeSheetDetails";
import Portal from "elements/Portal";
import { FolderMenu } from "components/folderMenu/FolderMenu";
import ProductsNew from "./ProductsNew";
import useLocalStorage from "hooks/useLocalStorage";
import { IRegisterGroup } from "../../Admin";
import { GetFolder, IFolder } from "api/rpc/2022-09/facilityAdmin/product/folder";
import { PRODUCT_FOLDERS_KEY } from "../../settings/folders/Folders";
import { IProduct } from "redux/reducers/models/product";
import Icon from "components/icon/Icon";
import { GetVariant } from "api/rpc/2024-04/facilityAdmin/product/variant";
import { GetProduct } from "api/rpc/2024-04/facilityAdmin/product/product";
import { PostLineItemToCart } from "api/rpc/2024-04/facilityAdmin/order/cart/lineItem";

interface IMainState {
  page: "cart" | "methods" | "receipt";
  paymentOption: Record<string, any>;
  order: undefined | Record<string, any>;
  serverPaymentSheetVisible: boolean;
  clearCartConfirmVisible: boolean;
  paymentAmount: number;
  customerSearchQuery: string;
  customerSearchResults: (Partial<ICustomer> | ICustomer)[];
  giftCardActive: boolean;
  sellGiftCardsActive: boolean;
  gift_cards: {
    cart_line_item_id: number;
    code: string;
    pin: number;
    random: boolean;
    balance: number;
    reload: number;
  }[];
  editCartLoading: boolean;
  accountsVisible: boolean;
  selectedAccount: Record<string, any>;
  creditBookVisible: boolean;
  selectedCreditBook: IPrizeAccount;
  searchingCustomer: boolean;
  changeDue: number;
  changePopUpVisible: boolean;
  roomChargeSheetVisible: boolean;
  roomOccupantSearchQuery: string;
  roomOccupantsSearchResults: IOccupant[];
  selectedOccupant: IOccupant;
  closeTableActive: boolean;
  orderCompletePopup: boolean;
  showCancelOrder: boolean;
  leagueFeesActive: boolean;
  leagueFeeProducts: Array<ICartLineItem>;
  selectedLeagues: Array<number>;
  paymentInProgress: boolean;
}

//Tee Sheet Details
type TStateOrigin = {
  stateCart: ICart;
  clearStateCart: () => void;
  updateState: <T>(newState: Partial<T>, type: StateType) => void;
};

//Register
type TReduxOrigin = {
  setRegisterState?: (newMainState: Partial<any>) => void;
  setFilterState?: (newMainState: Partial<any>) => void;
  unPaidOrder?: IOrder;
};

type TDataOrigin = TReduxOrigin | TStateOrigin;

interface IProps {
  dataOrigin: TDataOrigin;
}
interface ICancelDiscountState {
  selectedDiscount: IDiscountLines;
  showCancelDiscount: boolean;
  parentLineItemId: number;
}

interface IAddItemState {
  modalVisible: boolean;
  folders: Array<IFolder>;
  selectedFolder: IFolder;

  products: Array<IProduct>;
  productSearch: string;
  productsLoadingMessage: string;
  barcodeSearch: boolean;
}

export default function CartMenuNew(props: IProps) {
  const elements = useElements();
  const { dataOrigin } = props;
  const { cartStore, authStore, facilityStore, terminalStore } = useAppSelector(store => store);
  //Register
  const { setRegisterState, setFilterState, unPaidOrder } = dataOrigin as TReduxOrigin;
  //Tee Sheet Details
  const { stateCart, updateState, clearStateCart } = dataOrigin as TStateOrigin;

  const reduxCart = cartStore?.cart;
  const cart = isStateCart(dataOrigin) ? stateCart : reduxCart;

  const registerGroup = useLocalStorage<IRegisterGroup>("register_group");
  const defaultFolderId = useLocalStorage<number>("default_folder_id");

  const reader = terminalStore?.reader;
  const permissions = authStore?.user?.permissions;
  const { Option } = Select;
  const dispatch = useAppDispatch();
  const { printChits } = usePOSPrinter();
  const {
    updateRegisterState,
    loadingVariants,
    addLineItemUpdating,
    removeLineItemUpdating,
    lineItemsUpdating,
    addLoadingVariant,
    removeLoadingVariant,
  } = useRegisterContext();

  const { t, i18n } = useTranslation();
  const [submitTrigger, setSubmitTrigger] = useState<boolean>(false);
  const [manualProcessing, setManualProcessing] = useState<boolean>(false);
  const [lineItemState, setLineItemState] = useState<IUpdateLineItemModalState>({
    lineItem: undefined,
    inputQuantity: "",
    inputPrice: "",
    inputNote: "",
    isModalVisible: false,
  });

  const [state, setState] = useState<IMainState>({
    page: "cart",
    paymentOption: null,
    order: undefined,
    clearCartConfirmVisible: false,
    paymentAmount: 0,
    customerSearchQuery: "",
    customerSearchResults: [null],
    giftCardActive: false,
    sellGiftCardsActive: false,
    gift_cards: null,
    editCartLoading: false,
    accountsVisible: false,
    selectedAccount: undefined,
    creditBookVisible: false,
    selectedCreditBook: undefined,
    searchingCustomer: false,
    changeDue: null,
    changePopUpVisible: false,
    roomChargeSheetVisible: false,
    roomOccupantSearchQuery: "",
    roomOccupantsSearchResults: [],
    selectedOccupant: null,
    closeTableActive: false,
    serverPaymentSheetVisible: false,
    orderCompletePopup: false,
    showCancelOrder: false,
    leagueFeesActive: false,
    leagueFeeProducts: null,
    selectedLeagues: null,
    paymentInProgress: false,
  });

  const [addItemState, setAddItemState] = useState<IAddItemState>({
    modalVisible: false,
    folders: null,
    selectedFolder: null,

    products: null,
    productSearch: null,
    productsLoadingMessage: null,
    barcodeSearch: false,
  });

  const [cancelDiscountState, setCancelDiscountState] = useState<ICancelDiscountState>({
    selectedDiscount: null,
    showCancelDiscount: false,
    parentLineItemId: null,
  });

  const handlePaymentOptionChange = (paymentOption: Record<string, any>) => {
    if (paymentOption !== undefined) {
      setState(prevState => ({
        ...prevState,
        paymentOption: paymentOption,
      }));
    }
  };

  // Stripe Payment Related Functions and Variables
  const [manualStripeState, setManualStripeState] = useState({
    clientSecret: "",
    visible: false,
    transaction: undefined,
    elementComplete: {
      cardNumber: false,
      cardExpiry: false,
      cardCvc: false,
    },
  });

  const [stripePaymentState, setStripePaymentState] = useState<IStripePaymentState>({
    onCancel: () => {},
    onOk: () => {},
    cancelText: "",
    okText: "",
    sheetVisible: false,
    titleText: "",
    message: "",
    convertedCode: "",
    processing: false,
    success: false,
  });

  const navigateBack = () => {
    if (state.page === "methods") {
      setState(prevState => ({ ...prevState, page: "cart", gift_cards: null, paymentMethod: null }));
    } else if (state.page === "cart") {
      //Close ?
    } else if (state.page === "receipt") {
      setState(prevState => ({ ...prevState, page: "cart", gift_cards: null, paymentMethod: null }));
    }
  };

  useEffect(() => {
    if (unPaidOrder) {
      setState(prevState => ({ ...prevState, order: unPaidOrder, page: "methods" }));
    }
  }, [unPaidOrder]);

  //Display receipt on complete order
  useEffect(() => {
    if (state?.order) {
      // Reset for another
      if (state.order.balance > 0) {
        setState(prevState => ({ ...prevState, paymentAmount: state.order.balance.toFixed(2) }));
      }
    }
  }, [state?.order]);

  //Update reader on cart change
  useEffect(() => {
    if (cart?.line_items && reader) {
      if (cart.total_price > 0) {
        void setReaderDisplay(convertOrderIntoDisplayInfo(cart, state.order));
      }
    }
  }, [cart, reader, cart?.tax_lines]);

  //Set default paymentAmount
  useEffect(() => {
    if (cart) {
      setState(prevState => ({ ...prevState, paymentAmount: Number(cart.total_price?.toFixed(2)) }));
    }
  }, [cart]);

  //Return to cart page and reset gift cards when cart is switched
  useEffect(() => {
    if (cart && state.page !== "receipt") {
      setState(prevState => ({ ...prevState, page: "cart", gift_cards: null, selectedLeagues: null }));
    }
  }, [cart?.id]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (registerGroup && isStateCart(dataOrigin)) {
      void loadFolders(registerGroup.id, source.token);
    }
    return () => source.cancel();
  }, [registerGroup]);

  // Used for searching all products
  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (() => {
          try {
            if (addItemState?.productSearch === "") {
              if (mounted) {
                setAddItemState(prevState => ({ ...prevState, products: [] }));
              }
              return;
            } else {
              if (mounted) {
                void loadProducts(source.token);
              }
            }
          } catch (error) {
            console.log("err", error);
          }
          return;
        })();
      }, 500);
    };
    if (isStateCart(dataOrigin)) {
      void search();
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      source.cancel();
    };
  }, [addItemState?.productSearch]);

  async function handleClearCart() {
    localStorage.removeItem("register_cart_token");
    //Remove cart from cartStore
    void dispatch(loadTableCart({ tableCart: null }, false));
    await dispatch(postCart(null, false));
    dispatch(showSuccess(t("elements.register.cart_menu.cart_menu.001")));
    ReactDOM.unstable_batchedUpdates(() => {
      setRegisterState({ order: undefined, unPaidOrder: undefined });
      setFilterState({ search: "" });
      setState(prevState => ({
        ...prevState,
        customerSearchQuery: "",
        customerSearchResults: [],
      }));
    });
    void clearReaderDisplay();
  }

  async function loadCustomers(customerSearchQuery: string, cancelToken: CancelToken) {
    const customerRes = await GetCustomer({ search: customerSearchQuery }, false, cancelToken);

    if (customerRes.status !== StatusCode.OK) {
      return [];
    }

    return customerRes.data as Array<Record<string, any>>;
  }

  async function searchForCustomers(mounted: boolean, customerSearchQuery: string, cancelToken: CancelToken) {
    try {
      if (customerSearchQuery === "") {
        if (mounted) {
          setState(prevState => ({ ...prevState, customerSearchResults: [null], searchingCustomer: false }));
        }
        return;
      } else {
        setState(prevState => ({ ...prevState, searchingCustomer: true }));
        const res = await loadCustomers(customerSearchQuery, cancelToken);
        if (mounted) {
          setState(prevState => ({ ...prevState, customerSearchResults: res, searchingCustomer: false }));
        }
      }
    } catch (error) {
      setState(prevState => ({ ...prevState }));
    }
    return;
  }

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = global.setTimeout(() => {
        void searchForCustomers(mounted, state.customerSearchQuery, source.token);
      }, 300);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      setState(prevState => ({ ...prevState, customerSearchResults: [null], searchingCustomer: false }));
      source.cancel("Cancelled");
    };
  }, [state.customerSearchQuery]);

  async function loadRoomOccupants(roomOccupantSearchQuery: string, cancelToken: CancelToken) {
    const roomOccupantsRes = await GetRoomOccupants({ search: roomOccupantSearchQuery }, true, cancelToken);

    if (roomOccupantsRes.status !== StatusCode.OK && roomOccupantsRes.message !== "Cancelled") {
      dispatch(showError(roomOccupantsRes.message));
      return;
    }

    return roomOccupantsRes;
  }

  async function searchForRoomOccupants(mounted: boolean, roomOccupantSearchQuery: string, cancelToken: CancelToken) {
    try {
      if (roomOccupantSearchQuery === "") {
        if (mounted) {
          setState(prevState => ({ ...prevState, roomOccupantsSearchResults: [] }));
        }
      } else {
        const res = await loadRoomOccupants(roomOccupantSearchQuery, cancelToken);
        if (mounted && res?.status === StatusCode.OK && res?.data) {
          const occupants = res.data.map(occupant => {
            return {
              reservation_number: occupant.reservation_number ?? "",
              room_number: occupant.room_number ?? "",
              res_status: occupant.res_status,
              guest_name: occupant.guest_name ?? "",
              credit_limit: occupant.credit_limit,
            };
          });
          setState(prevState => ({ ...prevState, roomOccupantsSearchResults: occupants }));
        }
      }
    } catch (error) {
      setState(prevState => ({ ...prevState }));
    }
    setState(prevState => ({
      ...prevState,
      selectedOccupant: null,
    }));
  }

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = global.setTimeout(() => {
        void searchForRoomOccupants(mounted, state.roomOccupantSearchQuery, source.token);
      }, 2000);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      setState(prevState => ({ ...prevState, roomOccupantsSearchResults: [] }));
      source.cancel("Cancelled");
    };
  }, [state.roomOccupantSearchQuery]);

  async function completeOrder(order_id: number, showLoader = true) {
    const register = JSON.parse(localStorage.getItem("register"));
    const registerId = register?.id;

    const completeRes = await PutOrderComplete(
      { order_id, gift_cards: state.gift_cards, register_id: registerId, league_ids: state.selectedLeagues },
      showLoader,
    );

    if (completeRes.status !== StatusCode.OK) {
      dispatch(showError("Order Failed"));
      return;
    }
    dispatch(showSuccess("Order Completed"));
    ReactDOM.unstable_batchedUpdates(() => {
      if (!isStateCart(dataOrigin)) {
        setFilterState({ search: "" });
      }
      setState(prevState => ({
        ...prevState,
        customerSearchQuery: "",
        customerSearchResults: [],
        gift_cards: null,
        selectedLeagues: null,
      }));
    });
  }

  async function loadReceipt(order_id: number) {
    const receiptRes = await GetReceipt({ id: order_id }, true);

    if (receiptRes.status === StatusCode.OK) {
      window.open().document.write(receiptRes.data);
    } else {
      dispatch(showError("Loading receipt failed"));
    }
  }

  async function clearCartAsync(order: any) {
    await completeOrder(order.id, !isStateCart(dataOrigin));

    // clear the cart token from local storage
    localStorage.removeItem("register_cart_token");

    if (reader) {
      void clearReaderDisplay();
    }

    ReactDOM.unstable_batchedUpdates(() => {
      if (isStateCart(dataOrigin)) {
        updateState({ order: order }, StateType.CheckInState);
      }
      setState(prevState => ({
        ...prevState,
        order: order,
        paymentAmount: 0,
        page: "receipt",
        selectedAccount: undefined,
        paymentOption: null,
      }));
    });

    const register = JSON.parse(localStorage.getItem("register"));

    //check register to send kitchen chit
    if (register?.send_kitchen) {
      await sendKitchenChits();
    }

    //Check register to auto log out
    if (register && register?.auto_log_out === true) {
      localStorage.setItem("switch_user_after_transaction", "true");
    }

    //Order completed, display order action bar
    ReactDOM.unstable_batchedUpdates(() => {
      if (!isStateCart(dataOrigin)) {
        setRegisterState({ unPaidOrder: null });
        setState(prevState => ({ ...prevState, orderCompletePopup: true }));
      }
    });
  }

  async function updateOrder(order_id: number, clearCart: boolean) {
    const displayLoader = !isStateCart(dataOrigin);
    if (order_id) {
      //Update order
      const orderRes = await GetOrder({ id: order_id, extended: true }, displayLoader);
      if (orderRes.status !== StatusCode.OK) {
        dispatch(showError("Loading order failed"));
        return;
      }
      setManualProcessing(false);

      const order = orderRes.data[0];
      if (order.balance <= 0 || order.financial_status === "paid") {
        if (clearCart) {
          await clearCartAsync(order);
        }
      } else {
        // Still need to process another payment for this order
        ReactDOM.unstable_batchedUpdates(() => {
          setState(prevState => ({
            ...prevState,
            order: order,
            paymentAmount: order.balance.toFixed(2),
            page: "methods",
          }));
          if (isStateCart(dataOrigin)) {
            updateState({ order: order }, StateType.CheckInState);
            updateState(
              {
                isOpen: false,
                clearOnClose: true,
                loading: false,
                transactionComplete: false,
              },
              StateType.OrderComplete,
            );
          }
        });
      }
    }
  }

  async function sendKitchenChits() {
    const lineItemIds = cart?.line_items
      ?.filter(
        filteredLineItem =>
          !filteredLineItem?.parent_id &&
          (filteredLineItem.product?.meta?.kitchen_location_id ||
            filteredLineItem.product?.meta?.kitchen_location_id_2 ||
            filteredLineItem.product?.meta?.kitchen_location_id_3),
      )
      ?.map(lineItem => lineItem?.id);

    if (lineItemIds?.length > 0) {
      const chitRes = await PostKitchenChit({ line_item_ids: lineItemIds }, false);

      if (chitRes.status !== StatusCode.OK) {
        dispatch(showError(t("elements.register.cart_menu.cart_menu.003")));
        return;
      }

      void printChits(chitRes.data);
    }
  }

  function changePaymentAmount(e: any) {
    const value = e.target.value;

    setState(prevState => ({ ...prevState, paymentAmount: value }));
  }

  function handleManualProcessing() {
    if (manualProcessing === true) {
      setManualProcessing(false);
    } else {
      setManualProcessing(true);
    }
  }

  const elementsComplete =
    manualStripeState.elementComplete.cardNumber === true &&
    manualStripeState.elementComplete.cardExpiry === true &&
    manualStripeState.elementComplete.cardCvc === true;

  const {
    StripeTerminalSheet,
    processPayment,
    paymentStatus,
    order: tempStripePaymentOrder,
  } = useStripeTerminalPayment();

  const handleProcessPayment = async (giftCardInfo?: IGiftCardRedeemState) => {
    //Show loader on register
    if (!isStateCart(dataOrigin)) {
      dispatch(enqueue());
    }
    setState(prevState => ({ ...prevState, paymentInProgress: true }));
    const cartTotalPrice = Number(cart.total_price?.toFixed(2));
    try {
      const cart_id = cart.id;

      // Checking out produces a new order

      let checkoutRes: { status: any; data: any };

      // Create a new order if order does not exist in state
      if (!state?.order) {
        const register = JSON.parse(localStorage.getItem("register"));
        const registerId = register?.id;

        //Disable add fee buttons while the order is being created
        if (isStateCart(dataOrigin)) {
          updateState(
            {
              orderStep: OrderProgress.CREATING_CART,
            },
            StateType.OrderProgress,
          );
        }

        checkoutRes = await PostCheckout({ cart_id, register_id: registerId }, false);
      }

      const finalAmount = Number(state.paymentAmount);

      //Check to see if amount is acceptable
      if (Number.isNaN(finalAmount)) {
        void dequeueLoader();
        dispatch(showError(`'${state.paymentAmount} ${t("elements.register.cart_menu.cart_menu.004")} `));
        setState(prevState => ({ ...prevState, paymentInProgress: false }));
        return;
      }

      if (
        (finalAmount > cartTotalPrice && state.paymentOption?.payment_method !== "cash") ||
        (finalAmount < 0 && state.paymentOption?.payment_method !== "account")
      ) {
        void dequeueLoader();
        dispatch(showError(`'${state.paymentAmount} ${t("elements.register.cart_menu.cart_menu.005")} `));
        setState(prevState => ({ ...prevState, paymentInProgress: false }));
        return;
      }

      let showOrderCompletePopup = false;
      if (
        isStateCart(dataOrigin) &&
        ((!state?.order && finalAmount >= checkoutRes?.data?.order?.total_price) ||
          (state?.order && finalAmount >= state?.order?.balance) ||
          (state?.order && !state?.order?.balance && finalAmount >= state?.order?.total_price))
      ) {
        showOrderCompletePopup = true;
      }

      if (checkoutRes?.status === StatusCode.OK || state?.order) {
        if (checkoutRes) {
          ReactDOM.unstable_batchedUpdates(() => {
            setState(prevState => ({ ...prevState, order: checkoutRes.data.order }));
            if (!isStateCart(dataOrigin)) {
              setRegisterState({ order: checkoutRes.data.order });
            }
            if (isStateCart(dataOrigin)) {
              ReactDOM.unstable_batchedUpdates(() => {
                updateState(
                  {
                    orderStep: OrderProgress.ORDER_IN_PROGRESS,
                  },
                  StateType.OrderProgress,
                );
                updateState({ order: checkoutRes?.data?.order }, StateType.CheckInState);
              });
            }
          });
        }

        const order_id = checkoutRes ? checkoutRes.data.order.id : state?.order.id;

        if (state.paymentOption?.payment_method === "manual_card") {
          const transactionRes = await PostTransaction(
            {
              kind: "sale",
              amount: finalAmount,
              order_id: order_id,
              payment_option_id: state.paymentOption?.id,
            },
            false,
          );

          if (transactionRes.status === StatusCode.OK) {
            setManualStripeState(prevState => ({
              ...prevState,
              transaction: transactionRes.data,
              clientSecret: transactionRes.data.client_secret,
              visible: true,
            }));
          } else {
            void dequeueLoader();
            dispatch(showError(t("elements.register.cart_menu.cart_menu.006")));
            setState(prevState => ({ ...prevState, paymentInProgress: false }));
            if (isStateCart(dataOrigin)) {
              updateState(
                {
                  isOpen: false,
                  clearOnClose: true,
                  loading: false,
                  transactionComplete: false,
                },
                StateType.OrderComplete,
              );
            }
            return;
          }
          // Using terminal - display modal
        } else if (state.paymentOption?.payment_method === "card") {
          void dequeueLoader();

          if (facilityStore?.facility.terminal_integration === "server") {
            setState(prevState => ({ ...prevState, serverPaymentSheetVisible: true }));
            // If cart already has a tip, skip tipping
            await processPayment(
              order_id,
              state.paymentOption,
              finalAmount,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              cart?.total_tip > 0 ? true : false,
            );
            setState(prevState => ({ ...prevState, paymentInProgress: false }));
          } else {
            setStripePaymentState(prevState => ({
              ...prevState,
              sheetVisible: true,
              onCancel: undefined,
              okText: t("elements.register.cart_menu.cart_menu.007"),
              processing: true,
              onOk: async () => {
                setStripePaymentState(prevState => ({
                  ...prevState,
                  sheetVisible: false,
                  onCancel: undefined,
                  okText: undefined,
                  processing: false,
                  onOk: () => {},
                  titleText: "",
                  success: false,
                  message: "",
                }));
                await clearReaderDisplay();
              },
              titleText: t("elements.register.cart_menu.cart_menu.008"),
              message: t("elements.register.cart_menu.cart_menu.009"),
            }));

            await stripeProcessPayment(
              order_id,
              finalAmount,
              state.paymentOption,
              handleStripeSuccess,
              handleStripeError,
            );
          }
          if (!isStateCart(dataOrigin)) {
            dispatch(enqueue());
          }
        } else if (state.paymentOption?.payment_method === "gift_card") {
          const transactionRes = await PostTransaction(
            {
              kind: "sale",
              amount: finalAmount,
              order_id: order_id,
              payment_option_id: state.paymentOption?.id,
              gift_card_code: giftCardInfo.giftCardCode,
              gift_card_pin: giftCardInfo.giftCardPin,
            },
            false,
          );

          if (transactionRes.status === StatusCode.OK) {
            setState(prevState => ({
              ...prevState,
              giftCardActive: false,
            }));

            // display success notification
            dispatch(showSuccess(t("elements.register.cart_menu.cart_menu.010")));

            if (isStateCart(dataOrigin)) {
              // If gift card balnce wasn't enough to cover transaction don't show order complete popup
              if (transactionRes.data?.amount < finalAmount) {
                showOrderCompletePopup = false;
              }
              if (showOrderCompletePopup) {
                updateState({ loading: true, isOpen: true }, StateType.OrderComplete);
              }
            }

            await updateOrder(transactionRes.data.order_id, true);
            //Handle successful payment
          } else {
            dispatch(showError(t("elements.register.cart_menu.cart_menu.006")));
            void dequeueLoader();
            setState(prevState => ({ ...prevState, paymentInProgress: false }));
            if (isStateCart(dataOrigin)) {
              updateState(
                {
                  isOpen: false,
                  clearOnClose: true,
                  loading: false,
                  transactionComplete: false,
                },
                StateType.OrderComplete,
              );
            }
            return;
          }
        } else if (state.paymentOption?.payment_method === "account") {
          // display success notification
          const transactionRes = await PostTransaction(
            {
              kind: "sale",
              amount: finalAmount,
              order_id: order_id,
              payment_option_id: state.paymentOption?.id,
              account_id: state.selectedAccount.id,
            },
            false,
          );

          if (transactionRes?.status !== StatusCode.OK) {
            dispatch(showError(t("elements.register.cart_menu.cart_menu.006")));
            setState(prevState => ({ ...prevState, paymentInProgress: false }));
            void dequeueLoader();
            return;
          }

          // display success notification
          dispatch(showSuccess(t("elements.register.cart_menu.cart_menu.010")));

          if (showOrderCompletePopup && isStateCart(dataOrigin)) {
            updateState({ loading: true, isOpen: true }, StateType.OrderComplete);
          }

          // Update order and check if there is a remaining balance
          await updateOrder(transactionRes.data.order_id, true);
        } else if (state.paymentOption?.payment_method === "credit_book") {
          // display success notification
          const transactionRes = await PostTransaction(
            {
              kind: "sale",
              amount: finalAmount,
              order_id: order_id,
              payment_option_id: state.paymentOption?.id,
              credit_book_id: state.selectedCreditBook.id,
            },
            false,
          );

          if (transactionRes?.status !== StatusCode.OK) {
            dispatch(showError(t("elements.register.cart_menu.cart_menu.006")));
            setState(prevState => ({ ...prevState, paymentInProgress: false }));
            void dequeueLoader();
            if (isStateCart(dataOrigin)) {
              updateState(
                {
                  isOpen: false,
                  clearOnClose: true,
                  loading: false,
                  transactionComplete: false,
                },
                StateType.OrderComplete,
              );
            }
            return;
          }

          // display success notification
          dispatch(showSuccess(t("elements.register.cart_menu.cart_menu.010")));

          if (showOrderCompletePopup && isStateCart(dataOrigin)) {
            updateState({ loading: true, isOpen: true }, StateType.OrderComplete);
          }

          // Update order and check if there is a remaining balance
          await updateOrder(transactionRes.data.order_id, true);
        } else if (
          state.paymentOption?.payment_method === "room_charge_rdp" ||
          state.paymentOption?.payment_method === "maestro"
        ) {
          const transactionRes = await PostTransaction(
            {
              kind: "sale",
              amount: finalAmount,
              order_id: order_id,
              payment_option_id: state.paymentOption?.id,
              guest_name: state.selectedOccupant?.guest_name,
              room_number: state.selectedOccupant?.room_number,
            },
            false,
          );

          if (transactionRes.status !== StatusCode.OK) {
            dispatch(showError(t("elements.register.cart_menu.cart_menu.006")));
            void dequeueLoader();
            setState(prevState => ({ ...prevState, paymentInProgress: false }));
            if (isStateCart(dataOrigin)) {
              updateState(
                {
                  isOpen: false,
                  clearOnClose: true,
                  loading: false,
                  transactionComplete: false,
                },
                StateType.OrderComplete,
              );
            }
            return;
          } else {
            dispatch(showSuccess(t("elements.register.cart_menu.cart_menu.010")));

            if (showOrderCompletePopup && isStateCart(dataOrigin)) {
              updateState({ loading: true, isOpen: true }, StateType.OrderComplete);
            }

            // Update order and check if there is a remaining balance
            await updateOrder(transactionRes.data.order_id, true);

            closeRoomChargeSheet();
          }
        } else {
          let changeDue: number;

          if (showOrderCompletePopup && isStateCart(dataOrigin)) {
            updateState({ loading: true, isOpen: true }, StateType.OrderComplete);
          }

          //Display change due for cash payments
          if (state.paymentOption?.payment_method === "cash") {
            if (state?.order?.balance && Number(state.paymentAmount) > state.order?.balance) {
              changeDue = Number(state.paymentAmount) - state.order?.balance;
              setState(prevState => ({
                ...prevState,
                changeDue: changeDue,
                changePopUpVisible: true,
              }));
            } else if (Number(state.paymentAmount) > cartTotalPrice) {
              changeDue = Number(state.paymentAmount) - cartTotalPrice;
              setState(prevState => ({
                ...prevState,
                changeDue: changeDue,
                changePopUpVisible: true,
              }));
            }
          }
          const transactionRes = await PostTransaction(
            {
              kind: "sale",
              amount: changeDue ? finalAmount - changeDue : finalAmount,
              order_id: order_id,
              payment_option_id: state.paymentOption?.id,
            },
            false,
          );

          if (transactionRes?.status !== StatusCode.OK) {
            dispatch(showError(t("elements.register.cart_menu.cart_menu.006")));
            void dequeueLoader();
            setState(prevState => ({ ...prevState, paymentInProgress: false }));
            if (isStateCart(dataOrigin)) {
              updateState(
                {
                  isOpen: false,
                  clearOnClose: true,
                  loading: false,
                  transactionComplete: false,
                },
                StateType.OrderComplete,
              );
            }
            return;
          }

          // display success notification
          dispatch(showSuccess(t("elements.register.cart_menu.cart_menu.010")));

          // Update order and check if there is a remaining balance
          await updateOrder(transactionRes.data.order_id, true);

          //Handle successful payment
        }

        if (showOrderCompletePopup && isStateCart(dataOrigin)) {
          updateState({ transactionComplete: true }, StateType.OrderComplete);
        }
      }
    } catch (e) {
      if (isStateCart(dataOrigin)) {
        updateState(
          {
            isOpen: false,
            clearOnClose: true,
            loading: false,
            transactionComplete: false,
          },
          StateType.OrderComplete,
        );
      }
      console.log("err:", e);
    }
    setState(prevState => ({ ...prevState, paymentInProgress: false }));
    void dequeueLoader();
  };

  function dequeueLoader() {
    if (!isStateCart(dataOrigin)) {
      dispatch(dequeue());
    }
  }

  const handleStripeError = (message: string, sheetInfo: sheetInfo) => {
    //set sheet
    setStripePaymentState(prevState => ({
      ...prevState,
      onCancel: () => {
        sheetInfo.onCancel();
        setStripePaymentState(prevState => ({ ...prevState, sheetVisible: false }));
      },
      onOk: () => {
        sheetInfo.onOk();
        setStripePaymentState(prevState => ({
          ...prevState,
          sheetVisible: true,
          onCancel: undefined,
          okText: t("elements.register.cart_menu.cart_menu.007"),
          processing: true,
          onOk: async () => {
            setStripePaymentState(prevState => ({
              ...prevState,
              sheetVisible: false,
              onCancel: undefined,
              okText: undefined,
              processing: false,
              onOk: () => {},
              titleText: "",
              success: false,
              message: "",
            }));
            await clearReaderDisplay();
          },
          titleText: t("elements.register.cart_menu.cart_menu.008"),
          message: t("elements.register.cart_menu.cart_menu.009"),
        }));
      },
      cancelText: sheetInfo.cancelText,
      okText: sheetInfo.okText,
      sheetVisible: true,
      titleText: sheetInfo.title,
      message: sheetInfo.message,
      convertedCode: sheetInfo.convertedErrorCode,
      success: false,
      processing: false,
    }));
  };

  const handleStripeSuccess = (message?: string, order_id?: number) => {
    setStripePaymentState(prevState => ({
      ...prevState,
      sheetVisible: true,
      onCancel: undefined,
      okText: t("elements.register.cart_menu.cart_menu.011"),
      //resets state
      onOk: () => {
        setStripePaymentState(prevState => ({
          ...prevState,
          sheetVisible: false,
          onCancel: undefined,
          okText: undefined,
          processing: false,
          onOk: () => {},
          titleText: "",
          message: "",
        }));
      },
      processing: false,
      success: true,
      titleText: t("elements.register.cart_menu.cart_menu.012"),
      message: t("elements.register.cart_menu.cart_menu.013"),
    }));
    void updateOrder(order_id, true);
    setState(prevState => ({ ...prevState, page: "receipt" }));
    dispatch(showSuccess(t("elements.register.cart_menu.cart_menu.013")));
  };

  const handleLineItemClick = (lineItem: ICartLineItem) => {
    setLineItemState({
      ...lineItemState,
      isModalVisible: true,
      lineItem: lineItem,
      inputQuantity: String(lineItem.quantity),
      inputPrice: String(lineItem.price),
      inputNote: lineItem.note ? lineItem.note : "",
    });
  };

  const removeDiscountLine = async () => {
    const selectedDiscount = { ...cancelDiscountState.selectedDiscount };
    //If modifier discount is being removed, show loader for the entire parent item + modifiers
    const selectedParentLineItemId = cancelDiscountState?.parentLineItemId;

    void addLineItemUpdating(selectedParentLineItemId ?? selectedDiscount?.cart_line_item_id);
    setCancelDiscountState(prevState => ({
      ...prevState,
      showCancelDiscount: false,
      selectedDiscount: null,
    }));
    const res = await DeleteDiscountLine({ id: selectedDiscount?.id, cart_id: selectedDiscount?.cart_id }, false);
    void removeLineItemUpdating(selectedParentLineItemId ?? selectedDiscount?.cart_line_item_id);

    if (res?.status !== StatusCode.OK) {
      dispatch(showError(t("elements.register.cart_menu.cart_menu.018")));
      return;
    }

    if (isStateCart(dataOrigin)) {
      void updateState({ cart: res?.data }, StateType.CheckInState);
    } else {
      void dispatch(loadTableCart({ tableCart: res?.data }, false));
    }

    dispatch(showSuccess("Discount successfully removed"));
  };

  const handleCustomerSearch = (query: string) => {
    setState(prevState => ({ ...prevState, customerSearchQuery: query }));
  };

  const handleCustomerSelection = async (id: string | number) => {
    if (cart?.id) {
      setState(prevState => ({ ...prevState, editCartLoading: true }));
      if (isStateCart(dataOrigin)) {
        const res = await PutCart({ id: cart?.id, customer_id: id }, false);
        if (res?.status !== StatusCode.OK) {
          dispatch(showError("Error adding customer to cart"));
        } else {
          updateState({ cart: res?.data }, StateType.CheckInState);
        }
      } else {
        await dispatch(putCustomer(cart.id, parseInt(id.toString()), false));
      }
      setState(prevState => ({ ...prevState, editCartLoading: false }));
    }
  };

  const removeCustomerFromCart = async () => {
    if (cart?.id) {
      setState(prevState => ({ ...prevState, editCartLoading: true }));
      if (isStateCart(dataOrigin)) {
        const res = await PutCart({ id: cart?.id, customer_id: null }, false);
        if (res?.status !== StatusCode.OK) {
          dispatch(showError("Error removing customer from cart"));
        } else {
          updateState({ cart: res?.data }, StateType.CheckInState);
        }
      } else {
        await dispatch(putCustomer(cart?.id, null, false));
      }
      setState(prevState => ({ ...prevState, editCartLoading: false }));
      handleCustomerSearch("");
    }
  };

  const initiateAddGiftCard = () => {
    setState(prevState => ({ ...prevState, giftCardActive: true }));
  };
  const handleCancelAddGiftCard = () => {
    setState(prevState => ({ ...prevState, giftCardActive: false, gift_card_code: "", gift_card_pin: "" }));
  };

  // Check for specific products like gift cards or green fees, or if a customer is required
  const checkProducts = () => {
    const gift_cards: any[] = [];
    const filteredGiftCards: any[] = cart.line_items?.filter(
      (line_item: Record<string, any>) => line_item?.product?.type === "Gift Card",
    );
    const filteredLeagueFees = cart.line_items?.filter(
      (line_item: Record<string, any>) => line_item?.product?.type === "League Registration Fee",
    );
    const customerRequiredFees = cart.line_items?.filter(
      (line_item: Record<string, any>) => line_item?.product?.customer_required as boolean,
    );

    if (filteredLeagueFees?.length > 0 && !cart?.customer_id) {
      dispatch(showError(t("elements.register.cart_menu.cart_menu.078")));
      return;
    }

    // Check if cart contains a product that has the customer_required flag
    if (customerRequiredFees?.length > 0 && !cart?.customer_id) {
      dispatch(showError(t("elements.register.cart_menu.cart_menu.079")));
      return;
    }

    //Open gift cards modal if cart contains gift cards
    if (filteredGiftCards?.length > 0 || filteredLeagueFees?.length > 0) {
      //Check for gift cards
      if (filteredGiftCards.length > 0) {
        filteredGiftCards.forEach((line_item: Record<string, any>, index: number) => {
          for (let i = 0; i < line_item?.quantity; i++) {
            gift_cards.push({
              product_id: line_item.product_id,
              cart_line_item_id: line_item.id,
              code: null,
              pin: null,
              random: false,
              balance: line_item.price,
              reload: 0,
            });
          }
        });
        setState(prevState => ({ ...prevState, gift_cards, sellGiftCardsActive: true }));
      }
      //Check for league registration fees
      if (filteredLeagueFees?.length > 0) {
        setState(prevState => ({ ...prevState, leagueFeesActive: true, leagueFeeProducts: filteredLeagueFees }));
      }
    } else {
      //Go directly to payment methods page if no gift cards / league fees in cart
      setState(prevState => ({
        ...prevState,
        page: "methods",
        gift_cards: null,
        leagueFeeProducts: null,
        selectedLeagues: null,
      }));
    }
  };

  const setSelectedLeagues = (leagues: Array<number>) => {
    const moveToPaymentMethods = checkToContinuePayment("leagues");
    setState(prevState => ({
      ...prevState,
      selectedLeagues: leagues,
      page: moveToPaymentMethods ? "methods" : "cart",
      leagueFeesActive: false,
      leagueFeeProducts: null,
    }));
  };

  function cancelGiftCardsAndLeagues() {
    setState(prevState => ({
      ...prevState,
      page: "cart",
      sellGiftCardsActive: false,
      gift_cards: null,
      leagueFeesActive: false,
      leagueFeeProducts: null,
      selectedLeagues: null,
    }));
  }

  const validateGiftCardCode = async (code: string, reload: number) => {
    const validateRes = await GetGiftCardValidate({ code, reload }, true);
    if (validateRes.status !== StatusCode.OK) {
      dispatch(showError(t("elements.register.cart_menu.cart_menu.019")));
      return false;
    }
    return true;
  };

  const handleAddSellGiftCards = async () => {
    let validateRes = true;
    //Validate gift card codes
    for (const gift_card of state.gift_cards) {
      if (!gift_card.random) {
        validateRes = await validateGiftCardCode(gift_card.code, gift_card.reload);
      }
      if (validateRes === false) {
        return;
      }
    }

    if (validateRes) {
      const moveToPaymentMethods = checkToContinuePayment("gift_card");
      setState(prevState => ({
        ...prevState,
        page: moveToPaymentMethods ? "methods" : "cart",
        sellGiftCardsActive: false,
      }));
      dispatch(showSuccess(t("elements.register.cart_menu.cart_menu.020")));
    }
  };

  const checkToContinuePayment = (type: "gift_card" | "leagues") => {
    switch (type) {
      case "gift_card":
        if (!state.selectedLeagues && state.leagueFeesActive) {
          return false;
        }
        return true;
      case "leagues":
        if (state.sellGiftCardsActive) {
          return false;
        }
        return true;
      default:
        return true;
    }
  };

  useEffect(() => {
    if (state.selectedAccount || state.selectedCreditBook) {
      void handleProcessPayment();
    }
    return () => {
      setState(prevState => ({ ...prevState, selectedAccount: undefined, selectedCreditBook: undefined }));
    };
  }, [state.selectedAccount, state.selectedCreditBook]);

  useEffect(() => {
    if (paymentStatus === "completed" && tempStripePaymentOrder === null) {
      void clearCartAsync(state.order);
      setState(prevState => ({ ...prevState, serverPaymentSheetVisible: false }));
    }

    if ((paymentStatus === "partial_payment" || paymentStatus === "paid") && tempStripePaymentOrder !== null) {
      //Show order complete modal on tee sheet details
      if (paymentStatus === "paid" && isStateCart(dataOrigin)) {
        updateState(
          {
            isOpen: true,
            loading: true,
          },
          StateType.OrderComplete,
        );
      }
      setState(prevState => ({
        ...prevState,
        order: tempStripePaymentOrder,
        paymentAmount: tempStripePaymentOrder.balance.toFixed(2),
        page: "methods",
      }));
      setState(prevState => ({ ...prevState, serverPaymentSheetVisible: false }));
    }
  }, [paymentStatus, tempStripePaymentOrder, state.order]);

  function closeAccountsModal() {
    setState(prevState => ({
      ...prevState,
      accountsVisible: false,
      selectedAccount: undefined,
    }));
  }
  function closeCreditBookModal() {
    setState(prev => ({ ...prev, creditBookVisible: false, selectedCreditBook: undefined }));
  }

  function handleAccountsOk(selectedAccount: Record<string, any>) {
    setState(prevState => ({ ...prevState, accountsVisible: false, selectedAccount }));
  }

  function handleCreditBooksOk(selectedCreditBook: IPrizeAccount) {
    setState(prevState => ({ ...prevState, creditBookVisible: false, selectedCreditBook }));
  }

  const defaultRoomChargeSheetState: Partial<IMainState> = {
    roomOccupantSearchQuery: "",
    roomOccupantsSearchResults: [],
    selectedOccupant: null,
  };

  function initiateRoomChargeSheet() {
    setState(prevState => ({
      ...prevState,
      roomChargeSheetVisible: true,
      ...defaultRoomChargeSheetState,
    }));
  }

  function closeRoomChargeSheet() {
    setState(prevState => ({
      ...prevState,
      roomChargeSheetVisible: false,
      ...defaultRoomChargeSheetState,
    }));
  }

  function handleOccupantSearchQueryChange(event: any) {
    setState(prevState => ({ ...prevState, roomOccupantSearchQuery: event.target.value }));
  }

  function handleProcessPaymentCheck() {
    console.log(state.paymentOption);
    console.log("orderID??", cart);

    if (state.paymentOption?.payment_method === "gift_card") {
      void initiateAddGiftCard();
    } else if (state.paymentOption?.payment_method === "account" && cart.customer?.id) {
      setState(prevState => ({ ...prevState, accountsVisible: true }));
    } else if (state.paymentOption?.payment_method === "credit_book") {
      setState(prevState => ({ ...prevState, creditBookVisible: true }));
    } else if (
      state.paymentOption?.payment_method === "room_charge_rdp" ||
      state.paymentOption?.payment_method === "maestro"
    ) {
      initiateRoomChargeSheet();
    } else {
      void handleProcessPayment();
    }
  }

  function handleCardSectionChange(
    e: StripeCardNumberElementChangeEvent | StripeCardExpiryElementChangeEvent | StripeCardCvcElementChangeEvent,
  ) {
    setManualStripeState(prevState => ({
      ...prevState,
      elementComplete: { ...prevState.elementComplete, [e.elementType]: e.complete },
    }));
  }

  async function handlePrintReceiptButton() {
    const receiptRes = await GetReceipt({ id: cart?.id, type: "cart" }, true);
    if (receiptRes.status !== StatusCode.OK) {
      dispatch(showError("Loading receipt failed"));
      return;
    }
    window.open().document.write(receiptRes.data);
  }

  async function closeOrderCompletePopup(open: boolean) {
    if (isStateCart(dataOrigin)) {
      //Reset state cart
      ReactDOM.unstable_batchedUpdates(() => {
        updateState({ cart: null, order: null }, StateType.CheckInState);
        updateState({ orderStep: OrderProgress.NEW_ORDER }, StateType.OrderProgress);
        updateState(
          {
            isOpen: false,
            clearOnClose: true,
            loading: false,
            transactionComplete: false,
          },
          StateType.OrderComplete,
        );
      });
    } else {
      // Create another empty redux cart
      dispatch(enqueue());
      await dispatch(postCart(null, false));
      dispatch(dequeue());
      setRegisterState({ order: undefined, unPaidOrder: undefined });
    }
    setState(prevState => ({ ...prevState, orderCompletePopup: open, page: "cart", order: undefined }));
  }

  async function cancelOrder() {
    const cancelRes = await PutVoidOrder(
      { order_id: state?.order?.id, notes: "Transaction cancelled", financial_status: "cancelled" },
      true,
    );

    if (cancelRes.status !== StatusCode.OK) {
      dispatch(showError("Error cancelling order"));
      return;
    }
    dispatch(showSuccess("Successfully cancelled order"));
    void navigateBack();
    ReactDOM.unstable_batchedUpdates(() => {
      setState(prevState => ({
        ...prevState,
        order: undefined,
        showCancelOrder: false,
        paymentAmount: cart ? Number(cart.total_price.toFixed(2)) : prevState?.paymentAmount,
      }));
      if (!isStateCart(dataOrigin)) {
        setRegisterState({ order: undefined, unPaidOrder: undefined });
      }
      if (isStateCart(dataOrigin)) {
        updateState({ order: undefined }, StateType.CheckInState);
        updateState(
          {
            isOpen: false,
            clearOnClose: true,
            loading: false,
            transactionComplete: false,
          },
          StateType.OrderComplete,
        );
      }
    });
  }

  async function loadFolders(registerGroupId: number, token?: CancelToken) {
    const folderRes = await GetFolder({ register_group_id: registerGroupId, extended: false }, false, token);

    if (token && token.reason) {
      return;
    }
    if (folderRes.status !== StatusCode.OK) {
      if (token && token?.reason) {
        return;
      }
      setAddItemState(prevState => ({ ...prevState, folders: [] }));
      dispatch(showError(t("secure.facility.register.register.008")));
      return;
    }

    const folders = folderRes.data;

    // Get default folder for the selected Register or the default set for the facility
    let selectedFolder: IFolder = undefined;

    if (defaultFolderId > 0) {
      selectedFolder = folders.find(folder => folder.id === defaultFolderId);
    } else {
      selectedFolder = folders.find(folder => folder.default === true);
    }

    if (!selectedFolder) {
      selectedFolder = folders?.[0];
    }

    setAddItemState(prevState => ({ ...prevState, folders: folders }));

    if (selectedFolder) {
      void handleChangeSelectedFolder(selectedFolder);
    }
  }

  async function handleChangeSelectedFolder(selectedFolder: IFolder) {
    if (selectedFolder?.id === undefined) {
      return;
    }

    const storedProductFolders: IFolder[] = JSON.parse(localStorage.getItem(PRODUCT_FOLDERS_KEY)) ?? [];
    const storedProductFolder = storedProductFolders.find(productFolder => productFolder?.id === selectedFolder.id);

    const selectedFolderDate = new Date(selectedFolder.updated_at);
    const storedProductFolderDate = new Date(storedProductFolder?.updated_at);

    const storedProductFolderIsValid =
      !isNaN(selectedFolderDate?.getTime()) &&
      !isNaN(storedProductFolderDate?.getTime()) &&
      storedProductFolderDate.getTime() >= selectedFolderDate.getTime();

    if (storedProductFolderIsValid) {
      setAddItemState(prevState => ({ ...prevState, selectedFolder: storedProductFolder }));
    } else {
      const folderRes = await GetFolder({ id: selectedFolder.id, extended: true }, true);

      if (folderRes.status !== StatusCode.OK || folderRes.data?.length === undefined || folderRes.data.length === 0) {
        dispatch(showError(t("secure.facility.register.register.011")));
        return;
      }

      const newSelectedFolder = folderRes.data[0];

      if (!storedProductFolder) {
        const updatedStoredProductFolders = [...storedProductFolders, newSelectedFolder];
        localStorage.setItem(PRODUCT_FOLDERS_KEY, JSON.stringify(updatedStoredProductFolders));
      } else {
        const updatedStoredProductFolders = storedProductFolders.map(productFolder => {
          if (productFolder.id === newSelectedFolder.id) {
            return newSelectedFolder;
          } else {
            return productFolder;
          }
        });

        localStorage.setItem(PRODUCT_FOLDERS_KEY, JSON.stringify(updatedStoredProductFolders));
      }

      setAddItemState(prevState => ({ ...prevState, selectedFolder: newSelectedFolder }));
    }
  }

  function renderProducts() {
    if (addItemState?.productSearch && addItemState?.products) {
      const products = addItemState?.products?.sort((firstProd, secondProd) => {
        if (firstProd.title < secondProd.title) {
          return -1;
        } else if (firstProd.title > secondProd.title) {
          return 1;
        } else {
          return 0;
        }
      }) as [];
      return { useGridPositions: false, products };
    } else {
      const products = addItemState?.selectedFolder?.products ?? ([] as []);
      return { useGridPositions: true, products };
    }
  }

  function refreshFolderStorage() {
    localStorage.removeItem(PRODUCT_FOLDERS_KEY);

    if (addItemState?.selectedFolder) {
      void handleChangeSelectedFolder(addItemState?.selectedFolder);
    }
    dispatch(showSuccess(t("secure.facility.register.register.012")));
  }

  async function loadProducts(cancelToken: CancelToken) {
    setAddItemState(prevState => ({ ...prevState, productsLoadingMessage: "Products loading..." }));
    //Search products
    if (!addItemState?.barcodeSearch) {
      const productRes = await GetProduct({ extended: true, search: addItemState?.productSearch }, false, cancelToken);
      setAddItemState(prevState => ({ ...prevState, productsLoadingMessage: null }));
      if (productRes.status === StatusCode.OK) {
        setAddItemState(prevState => ({ ...prevState, products: productRes?.data }));
      } else {
        if (cancelToken && cancelToken?.reason) {
          return;
        }
        dispatch(showError("Error searching products"));
      }
    } else {
      //Cannot scan and add item to cart if an order is already in progress (partial payment made)
      if (state?.order) {
        dispatch(showError("Order in progress. Start a new order to add item to cart"));
        setAddItemState(prevState => ({ ...prevState, productsLoadingMessage: null }));
        return;
      }
      //Search variant by barcode and add to cart
      const res = await GetVariant({ extended: true, barcode: addItemState?.productSearch }, false, cancelToken);
      setAddItemState(prevState => ({ ...prevState, productsLoadingMessage: "Adding line item to cart..." }));
      if (res.status === StatusCode.OK) {
        const variants = res?.data?.filter(variant => variant.facility_access === true);

        if (variants.length == 1) {
          const variant = variants[0];
          void addLoadingVariant(variant, variant?.product);
          const updatedCart = await PostLineItemToCart(
            { cart_id: cart?.id, variants: [{ variant_id: variant?.id, quantity: 1, parent_id: null }] },
            false,
          );
          setAddItemState(prevState => ({ ...prevState, productsLoadingMessage: null }));
          void removeLoadingVariant(variant?.id);
          if (!updatedCart) {
            dispatch(showError("Error adding line item to cart. Please try again"));
            return;
          }
          ReactDOM.unstable_batchedUpdates(() => {
            setAddItemState(prevState => ({ ...prevState, productSearch: "" }));
            updateState({ cart: updatedCart?.data }, StateType.CheckInState);
          });
        } else {
          setAddItemState(prevState => ({ ...prevState, productsLoadingMessage: null }));
          dispatch(showError("Item not found"));
        }
        setAddItemState(prevState => ({ ...prevState, products: [] }));
      } else {
        setAddItemState(prevState => ({ ...prevState, productsLoadingMessage: null }));
        if (cancelToken?.reason) {
          return;
        }
        dispatch(showError("Error searching products"));
      }
    }
  }

  function handleBarcodeSearchClick() {
    const productSearchId = document.getElementById("checkInProductId");
    productSearchId.focus();
    setAddItemState(prevState => ({ ...prevState, barcodeSearch: !prevState?.barcodeSearch }));
  }

  async function handleAddTip(totalTip: number) {
    const cartRes = await PutCart({ id: cart?.id, total_tip: totalTip }, true);

    if (cartRes.status !== StatusCode.OK) {
      dispatch(showError("Error adding tip"));
      return;
    }

    void updateState({ cart: cartRes?.data }, StateType.CheckInState);
  }

  const cartLoading = loadingVariants?.current?.length > 0 || lineItemsUpdating?.current?.length > 0;

  const renderContext = () => {
    if (state.page === "methods") {
      return (
        <div className="flex flex-1 flex-col">
          {state?.order ? (
            <Button
              onClick={() => setState(prevState => ({ ...prevState, showCancelOrder: true }))}
              type="text"
              className="p-0"
            >
              <div className="flex items-center gap-2">
                <FontAwesomeIcon icon={["far", "long-arrow-left"]} />
                <span>{t("elements.register.cart_menu.cart_menu.022")}</span>
              </div>
            </Button>
          ) : (
            <Button onClick={navigateBack} type="text" className="p-0">
              <div className="flex items-center gap-2">
                <FontAwesomeIcon icon={["far", "long-arrow-left"]} />
                <span>{t("elements.register.cart_menu.cart_menu.023")}</span>
              </div>
            </Button>
          )}
          <div className="flex flex-1 flex-col h-full overflow-y-scroll no-scrollbar">
            <CartTotalsNew
              currency={cart?.currency}
              subTotal={cart?.subtotal_price}
              taxLines={cart?.tax_lines}
              totalTip={cart?.total_tip}
              total={cart?.total_price}
              order={state?.order as IOrder}
              handleTipTeeSheet={isStateCart(dataOrigin) ? tip => handleAddTip(tip) : undefined}
            />

            <CartTransactions transactions={state?.order?.transactions} currency={state?.order?.currency} />

            <Divider />

            <PaymentMethods
              onChange={handlePaymentOptionChange}
              paymentOptions={facilityStore?.paymentOptions}
              cardReaderAvailable={reader === null ? false : true}
              cart={cart}
              paymentAmount={state.paymentAmount}
            />
          </div>

          <div className="mt-auto">
            <div className="flex flex-row justify-end align-center mt-4">
              <Input
                label={t("elements.register.cart_menu.cart_menu.024")}
                prefix="$"
                placeholder={String(state.paymentAmount)}
                onChange={changePaymentAmount}
                value={String(state.paymentAmount)}
              />
            </div>
            <Divider />

            <div className="mb-3">
              {state?.paymentInProgress ? (
                <div className="flex justify-center">
                  <div className="h-8 w-8">
                    <Spin className="admin-header-spinner" />
                  </div>
                </div>
              ) : (
                <Button
                  type="primary"
                  block
                  disabled={!state.paymentOption?.payment_method}
                  onClick={handleProcessPaymentCheck}
                >
                  {state.paymentOption?.payment_method === "gift_card"
                    ? t("elements.register.cart_menu.cart_menu.053")
                    : t("elements.register.cart_menu.cart_menu.077")}
                </Button>
              )}
            </div>
          </div>
        </div>
      );
    }

    if (state.page === "receipt") {
      return (
        <div className="flex flex-1 flex-col">
          <span className="mr-auto text-2xl text-semibold">
            {t("elements.register.cart_menu.cart_menu.080")} {state?.order?.name}
          </span>

          <CartLineItemsNew lineItems={cart?.line_items} discountLines={cart?.discount_lines} />

          <div className="mt-auto">
            <div className="flex">
              <div className="flex ml-auto flex-col w-64">
                <CartTotalsNew
                  currency={state?.order?.currency}
                  subTotal={state?.order?.subtotal_price}
                  taxLines={state?.order?.tax_lines}
                  totalTip={state?.order?.total_tip}
                  total={state?.order?.total_price}
                />

                <CartTransactions transactions={state?.order?.transactions} currency={state?.order?.currency} />
              </div>
            </div>
          </div>
          <Divider />

          {state?.order.balance <= 0 ? (
            <>
              <div className="mb-3">
                <Button
                  type="primary"
                  block
                  icon={<FontAwesomeIcon icon="long-arrow-right" />}
                  onClick={() => setState(prevState => ({ ...prevState, page: "cart", order: undefined }))}
                >
                  {t("elements.register.cart_menu.cart_menu.025")}
                </Button>
              </div>
              <div>
                <Button type="default" block onClick={() => loadReceipt(state.order.id)}>
                  <span>{t("elements.register.cart_menu.cart_menu.026")}</span>
                </Button>
              </div>
            </>
          ) : (
            <div className="mb-3">
              <Button
                type="primary"
                block
                disabled={!state.paymentOption?.payment_method}
                onClick={handleProcessPaymentCheck}
              >
                {state.paymentOption?.payment_method === "gift_card" ? "Enter Gift Card" : "Process Payment"}
              </Button>
            </div>
          )}
        </div>
      );
    }

    if (state.page === "cart") {
      return (
        // <div className="flex flex-1 flex-col">
        <>
          <div className="ui-register-line-items">
            {cart && (
              <>
                {state?.editCartLoading ? (
                  <div style={{ height: "32px" }}>
                    <Spin />
                  </div>
                ) : cart.customer ? (
                  <div>
                    <div className="ui-register-cart-new-parent-container">
                      <div className="ui-register-cart-new-image-container">
                        <FontAwesomeIcon icon={["far", "user"]} className="customer-image" />
                      </div>
                      <div>
                        <p className="ui-register-cart-new-customer-title">{cart?.customer?.full_name}</p>
                        <p className="ui-register-cart-new-customer-subtitle">{cart?.customer?.email}</p>
                        {cart?.customer?.customer_type && (
                          <p className="ui-register-cart-new-customer-subtitle">{cart?.customer?.customer_type}</p>
                        )}
                      </div>
                      <div className="ml-auto">
                        <Button
                          className="ui-register-cart-new-customer-remove"
                          onClick={() => removeCustomerFromCart()}
                          size="medium"
                          type="close"
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                    <hr className="mb-2"></hr>
                  </div>
                ) : (
                  <SelectCustomer
                    onCustomerChange={customer => handleCustomerSelection(customer.id)}
                    permissionLevel={"facility"}
                    newCustomer
                  />
                )}
              </>
            )}
          </div>

          {isStateCart(dataOrigin) && cart && (
            <Button
              size="medium"
              type="secondary"
              block
              onClick={() => setAddItemState(prevState => ({ ...prevState, modalVisible: true }))}
              disabled={!cart || !!state?.order}
            >
              Add Items +
            </Button>
          )}

          {!cart ? (
            <div style={{ display: "flex", margin: "auto 0" }}>
              <div className="text-center m-auto">
                <div style={{ height: "32px" }}>
                  <Spin />
                </div>
                <div>Preparing cart...</div>
              </div>
            </div>
          ) : null}

          <div className="overflow-y-auto">
            <CartLineItemsNew
              lineItems={cart?.line_items}
              handleLineItemClick={
                cart?.status === "complete" ? undefined : lineItem => handleLineItemClick(lineItem)
              }
              discountLines={cart?.discount_lines}
              handleDiscountClick={(discountLine, parentLineItemId) =>
                setCancelDiscountState(prevState => ({
                  ...prevState,
                  showCancelDiscount: true,
                  selectedDiscount: discountLine,
                  parentLineItemId: parentLineItemId ? parentLineItemId : null,
                }))
              }
            />
          </div>

          <div className="mt-auto">
            <CartTotalsNew
              currency={cart?.currency}
              subTotal={cart?.subtotal_price}
              taxLines={cart?.tax_lines}
              totalTip={cart?.total_tip}
              total={cart?.total_price}
              order={state?.order as IOrder}
              handleTipTeeSheet={isStateCart(dataOrigin) ? tip => handleAddTip(tip) : undefined}
            />

            <Divider />
            <div className="mb-3">
              <Button
                type="primary"
                disabled={
                  !cart ||
                  cart?.line_items?.length === 0 ||
                  cart?.status === "complete" ||
                  cartLoading ||
                  state?.editCartLoading
                    ? true
                    : false
                }
                block
                icon={cart?.status !== "complete" ? <FontAwesomeIcon icon="long-arrow-right" /> : undefined}
                onClick={checkProducts}
              >
                {cart?.status === "complete"
                  ? t("elements.register.cart_menu.cart_menu.075")
                  : t("elements.register.cart_menu.cart_menu.076")}
              </Button>
            </div>

            <div className="flex flex-row gap-2">
              <Button type="default" block onClick={handlePrintReceiptButton} disabled={cartLoading}>
                <span>{t("elements.register.cart_menu.cart_menu.026")}</span>
              </Button>
              <Button
                type="default"
                block
                disabled={cartLoading}
                onClick={() => {
                  setState(prevState => ({ ...prevState, clearCartConfirmVisible: true }));
                }}
              >
                <span>{t("elements.register.cart_menu.cart_menu.030")}</span>
              </Button>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div className="ui-register-menu_content">
        {/* Pop up to clear a cart */}
        <Popup
          open={state.clearCartConfirmVisible}
          type="warning"
          title={t("elements.register.cart_menu.cart_menu.031")}
          description={t("elements.register.cart_menu.cart_menu.032")}
          onOk={() => {
            if (isStateCart(dataOrigin)) {
              void clearStateCart();
            } else {
              void handleClearCart();
            }
            setState(prevState => ({
              ...prevState,
              clearCartConfirmVisible: false,
              gift_cards: null,
              selectedLeagues: null,
              order: undefined,
            }));
          }}
          okText={t("elements.register.cart_menu.cart_menu.033")}
          cancelText={t("elements.register.cart_menu.cart_menu.081")}
          onCancel={() => {
            setState(prevState => ({ ...prevState, clearCartConfirmVisible: false }));
          }}
          backDropCancel={true}
        />

        {/* Payment Handler Sheet - Handles successes/errors */}
        <>
          {stripePaymentState.processing ? (
            <Popup
              type="info"
              open={stripePaymentState.sheetVisible}
              title={t("elements.register.cart_menu.cart_menu.034")}
              description={t("elements.register.cart_menu.cart_menu.035")}
              closable
              onCancel={stripePaymentState.onCancel}
              onOk={stripePaymentState.onOk}
              cancelText={stripePaymentState.cancelText}
              okText={stripePaymentState.okText}
              backDropCancel={false}
            />
          ) : (
            <>
              {stripePaymentState.success ? (
                <Popup
                  type="success"
                  open={stripePaymentState.sheetVisible}
                  title={t("elements.register.cart_menu.cart_menu.036")}
                  description={t("elements.register.cart_menu.cart_menu.037")}
                  closable
                  onCancel={stripePaymentState.onCancel}
                  onOk={stripePaymentState.onOk}
                  cancelText={stripePaymentState.cancelText}
                  okText={stripePaymentState.okText}
                  backDropCancel={false}
                />
              ) : (
                <Popup
                  type="error"
                  open={stripePaymentState.sheetVisible}
                  title={t("elements.register.cart_menu.cart_menu.038")}
                  description={
                    stripePaymentState.message +
                    t("elements.register.cart_menu.cart_menu.039") +
                    stripePaymentState.convertedCode
                  }
                  closable
                  onCancel={stripePaymentState.onCancel}
                  onOk={stripePaymentState.onOk}
                  cancelText={stripePaymentState.cancelText}
                  okText={stripePaymentState.okText}
                  backDropCancel={false}
                />
              )}
            </>
          )}
        </>

        {/* Manual Credit Card Sheet*/}
        <Sheet
          size={"small"}
          closable
          title={t("elements.register.cart_menu.cart_menu.040")}
          cancelText={"Cancel"}
          open={manualStripeState.visible}
          okText={t("elements.register.cart_menu.cart_menu.041")}
          okDisabled={!elementsComplete}
          cancelDisabled={manualProcessing}
          backDropCancel={false}
          onOk={() => {
            if (!submitTrigger) {
              setSubmitTrigger(!submitTrigger);
            }
          }}
          onCancel={async () => {
            await cancelManualCreditPaymentIntent(
              manualStripeState?.transaction?.payment_intent_id,
              manualStripeState?.transaction?.id,
            );
            if (elements) {
              elements.getElement(CardNumberElement).clear();
              elements.getElement(CardExpiryElement).clear();
              elements.getElement(CardCvcElement).clear();
            }
            setManualStripeState(prevState => ({
              ...prevState,
              visible: false,
              transaction: {},
            }));
          }}
        >
          <CheckoutForm
            onChange={handleCardSectionChange}
            onSubmit={() => handleManualProcessing()}
            trigger={submitTrigger}
            transaction={manualStripeState.transaction}
            onError={(message: string) => {
              setSubmitTrigger(false);
              setManualProcessing(false);
              dispatch(showError(message));
            }}
            onSuccess={async () => {
              // Clean values
              dispatch(showSuccess(t("elements.register.cart_menu.cart_menu.042")));
              setManualStripeState(prevState => ({
                ...prevState,
                visible: false,
                transaction: {},
                elementComplete: {
                  cardCvc: false,
                  cardExpiry: false,
                  cardNumber: false,
                },
              }));
              setSubmitTrigger(false);
              if (isStateCart(dataOrigin)) {
                updateState({ isOpen: true, transactionComplete: true }, StateType.OrderComplete);
              }
              dispatch(enqueue());
              await updateOrder(state.order.id, true);
              dispatch(dequeue());
            }}
          />
        </Sheet>
        {/** Seperate Payment for Stripe used for server payments - uses hook that should be updated to handle network based payments */}
        <StripeTerminalSheet />

        {/** Sheet to handle line-item quantity */}
        {lineItemState.lineItem && (
          <UpdateLineItemModalTemp
            lineItemState={lineItemState}
            cart={cart}
            setLineItemState={setLineItemState}
            permissions={permissions}
            discounts={facilityStore?.discountOptions}
            dataOrigin={isStateCart(dataOrigin) ? "state" : "redux"}
            openModifiersSheet={lineItem => {
              if (isStateCart(dataOrigin)) {
                setAddItemState(prevState => ({ ...prevState, modalVisible: true }));
              }
              updateRegisterState({ modifierLineItem: lineItem });
            }}
            modalActions={{
              onOk: cart => updateState({ cart }, StateType.CheckInState),
            }}
          />
        )}

        <GiftCardRedeem
          redeemGiftCardsActive={state.giftCardActive}
          cart={cart}
          onCancel={handleCancelAddGiftCard}
          onOk={handleProcessPayment}
        />

        <GiftCardSell
          gift_cards={state.gift_cards}
          sellGiftCardsActive={state.sellGiftCardsActive}
          onOk={handleAddSellGiftCards}
          onCancel={cancelGiftCardsAndLeagues}
        />

        <AccountsModal
          loadAccounts={state.accountsVisible}
          onCancel={closeAccountsModal}
          onOk={handleAccountsOk}
          customerId={cart?.customer?.id}
          searchAccountPermission={permissions?.accounts_charge_all}
        />

        <CreditBookModal
          loadCreditBooks={state.creditBookVisible}
          onCancel={closeCreditBookModal}
          onOk={handleCreditBooksOk}
          customerId={cart?.customer?.id}
          // searchAccountPermission={permissions?.credit}
        />

        <LeagueSelectionModal
          open={state.leagueFeesActive}
          leagueFeeProducts={state.leagueFeeProducts}
          onCancel={cancelGiftCardsAndLeagues}
          onOk={setSelectedLeagues}
        />

        <Sheet
          title={t("elements.register.cart_menu.cart_menu.064")}
          open={state.roomChargeSheetVisible}
          size="small"
          closable
          onCancel={closeRoomChargeSheet}
          onOk={handleProcessPayment}
          okDisabled={isEmpty(state.selectedOccupant?.guest_name)}
          okText={t("elements.register.cart_menu.cart_menu.065")}
        >
          <Input
            label={t("elements.register.cart_menu.cart_menu.066")}
            helpText={t("elements.register.cart_menu.cart_menu.067")}
            type="search"
            onChange={handleOccupantSearchQueryChange}
            value={state.roomOccupantSearchQuery}
          />
          {state.roomOccupantsSearchResults.map((occupant, index) => {
            return (
              <div key={index} className="flex flex-row justify-between p-3 border-b">
                <Checkbox
                  size="small"
                  checked={isEqual(occupant, state.selectedOccupant)}
                  onChange={e =>
                    setState(prev => ({
                      ...prev,
                      selectedOccupant: cloneDeep(occupant),
                    }))
                  }
                  label={occupant.guest_name}
                />
              </div>
            );
          })}
        </Sheet>

        {/* Add items modal used on Tee Sheet Details  */}
        <Portal isMounted={addItemState?.modalVisible}>
          <Sheet
            title="Add Items"
            open={addItemState?.modalVisible}
            size="large"
            closable
            onCancel={() => setAddItemState(prevState => ({ ...prevState, modalVisible: false, productSearch: "" }))}
            cancelText="Close"
          >
            <div className="ui-register">
              <div className="ui-register-folders_container">
                <FolderMenu
                  options={addItemState?.folders}
                  optionTitle="title"
                  defaultSelected={addItemState?.folders?.findIndex(
                    folder => folder?.id === addItemState?.selectedFolder?.id,
                  )}
                  onClick={(option, selectedIndex) => handleChangeSelectedFolder(option)}
                />
              </div>
              <div className="ui-register-new-products-container">
                <div className="ui-register-new-products-content">
                  <div className="ui-register-top-bar">
                    <div className="flex justify-between mb-4">
                      <div className="flex-grow">
                        <Input
                          id="checkInProductId"
                          value={addItemState?.productSearch ?? ""}
                          onChange={value =>
                            setAddItemState(prevState => ({ ...prevState, productSearch: value.target.value }))
                          }
                          type="search"
                          placeholder="Search for a product..."
                          labelHidden
                          trailingButtons={[
                            <Button
                              key={1}
                              type="secondary"
                              active={addItemState?.barcodeSearch}
                              onClick={handleBarcodeSearchClick}
                            >
                              <Icon style="far" icon="barcode-read" size="medium" />
                            </Button>,
                            <Button key={2} type="secondary" onClick={refreshFolderStorage}>
                              <Icon style="far" icon="refresh" size="medium" />
                            </Button>,
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={"product-content"}>
                    <ProductsNew
                      {...renderProducts()}
                      disabled={!cart || cart?.status === "complete" || state.order ? true : false}
                      cart={cart}
                      selectedFolder={addItemState?.selectedFolder}
                      productsLoadingMessage={addItemState?.productsLoadingMessage}
                      updateStateCart={updateState}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Sheet>
        </Portal>

        <Popup
          type="warning"
          open={state.showCancelOrder}
          title={t("elements.register.cart_menu.cart_menu.068")}
          description={t("elements.register.cart_menu.cart_menu.069")}
          closable
          onOk={cancelOrder}
          onCancel={() => setState(prevState => ({ ...prevState, showCancelOrder: false }))}
          okText={t("elements.register.cart_menu.cart_menu.063")}
        />

        <Popup
          type="info"
          open={state.changePopUpVisible}
          title={`${t("elements.register.cart_menu.cart_menu.070")} ${state.changeDue?.toFixed(2)}`}
          description=""
          closable
          onOk={() => setState(prevState => ({ ...prevState, changePopUpVisible: false, changeDue: null }))}
          okText={t("elements.register.cart_menu.cart_menu.071")}
          backDropCancel={false}
          stacked
        />

        <Popup
          type="warning"
          open={cancelDiscountState.showCancelDiscount}
          title={t("elements.register.cart_menu.cart_menu.082")}
          description={t("elements.register.cart_menu.cart_menu.083")}
          closable
          onOk={() => {
            void removeDiscountLine();
          }}
          onCancel={() =>
            setCancelDiscountState(prevState => ({
              ...prevState,
              showCancelDiscount: false,
              selectedDiscount: null,
              parentLineItemId: null,
            }))
          }
          okText={t("elements.register.cart_menu.cart_menu.084")}
        />
        {renderContext()}
      </div>

      <OrderCompletePopup
        open={state.orderCompletePopup}
        setOpen={(open: boolean) => closeOrderCompletePopup(open)}
        order={state.order}
      />
    </>
  );
}

function isStateCart(dataOrigin: TDataOrigin): dataOrigin is TStateOrigin {
  const keys = Object.keys(dataOrigin);
  const isStateCart = keys.find(key => key === "stateCart");
  return isStateCart !== undefined;
}
