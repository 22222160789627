import React, { useCallback, useEffect, useState } from "react";
import axios, { CancelToken } from "axios";
import classNames from "classnames";

import { IBookingCategory } from "redux/reducers/models/teetime";
import { LocaleCurrency } from "helpers/Locale";

import Spin from "components/spin/spin";
import Portal from "elements/Portal";

import "./teetimetooltip.scss";
import "components/popover/popover.scss";

interface IProps {
  disable?: boolean;
  cardRequired?: boolean;
  prepaidRequired?: boolean;
  bookingCategories?: IBookingCategory[];
  /** Delay before onMouseEnter callback runs.  Default=750ms. */
  delay?: number;
  /** Callback function run after delay. */
  onMouseEnter?: (token: CancelToken) => Promise<ITeeTimeToolTipPricing | undefined>;
  parentId: number;
  children?: React.ReactNode;
}

export interface ITeeTimeToolTipPricing {
  booking_fee: string | undefined;
  green_fees: Record<string, any>[];
  booking_categories: IBookingCategory[];
}

const TeeTimeToolTip = (props: IProps) => {
  const [active, setActive] = useState(false);
  const [pricing, setPricing] = useState<ITeeTimeToolTipPricing | undefined>({
    booking_fee: undefined,
    green_fees: [],
    booking_categories: [],
  });

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    const source = axios.CancelToken.source();

    if (!props.disable) {
      if (active) {
        timeout = setTimeout(() => {
          if (pricing === undefined || pricing?.booking_fee === undefined) {
            void getPricingSetter(source.token);
          }
        }, props.delay || 750);
      }
    }

    return () => {
      source.cancel();
      clearTimeout(timeout);
    };
  }, [active, props.disable]);

  const getPricingSetter = async (token?: CancelToken) => {
    if (pricing !== undefined) {
      setPricing(undefined);
    }

    const pricingData = await props.onMouseEnter(token);

    if (typeof pricingData === "undefined" || (token && token.reason)) {
      setPricing({
        booking_fee: undefined,
        green_fees: [],
        booking_categories: [],
      });
    } else {
      setPricing(pricingData);
    }
  };

  const toggleActive = useCallback(
    (force?: boolean | undefined) => {
      const setTo = typeof force === "boolean" ? force : !active;
      if (active === setTo) {
        return;
      }
      setActive(prev => setTo);
    },
    [active],
  );

  return (
    <div
      className="ui-tee-time-tooltip"
      onMouseEnter={() => toggleActive(true)}
      onMouseLeave={() => toggleActive(false)}
      onMouseDown={() => toggleActive(false)}
    >
      <div>{props.children}</div>
      <div id={`tee_time_${props.parentId}`} className="ui-tee-time-positioned-overlay">
        <Portal isMounted={active} parentId={`tee_time_${props.parentId}`}>
          <div
            className={classNames("ui-tee-time-tooltip-overlay", {
              "ui-tee-time-tooltip-overlay-open": active,
            })}
          >
            <div className="ui-tee-time-tooltip-overlay_content">
              {pricing === undefined ? (
                <>
                  <div className="ui-tee-time-tooltip-products_container">
                    <div className="ui-tee-time-tooltip-rates">
                      <p className="ui-tee-time-tooltip-title_text_rates mr-4">Rates</p>
                    </div>
                    <div style={{ height: "30px", overflow: "hidden" }}>
                      <Spin />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="ui-tee-time-tooltip-products_container">
                    {pricing.green_fees && pricing.green_fees.length > 0 ? (
                      <div className="ui-tee-time-tooltip-rates">
                        <p className="ui-tee-time-tooltip-title_text_rates mr-4">Rates</p>
                        {pricing.booking_fee && (
                          <p className="ui-tee-time-tooltip-small_text">
                            Booking Fee: <LocaleCurrency amount={pricing.booking_fee} />
                          </p>
                        )}
                      </div>
                    ) : (
                      <div className="ui-tee-time-tooltip-rates">
                        <p className="ui-tee-time-tooltip-title_text_rates mr-4">Rates Not Available</p>
                      </div>
                    )}

                    {pricing.green_fees.map((product, index: number) => {
                      return (
                        <div key={index} className="ui-tee-time-tooltip-products">
                          <div className="flex">
                            <p className="ui-tee-time-tooltip-title_text w-4 mr-4" style={{ color: "#999999" }}>
                              {product?.holes}
                            </p>
                            <div className="ui-tee-time-tooltip-product_titles">
                              <p className="ui-tee-time-tooltip-title_text truncate">{product.product?.title}</p>
                              <p className="ui-tee-time-tooltip-small_text truncate">{product.title}</p>
                            </div>
                          </div>
                          <LocaleCurrency
                            style={{ color: "#3D5FA0", fontSize: "0.8rem", fontWeight: "600" }}
                            amount={product.price}
                          />
                        </div>
                      );
                    })}

                    {(!!props.cardRequired || !!props.prepaidRequired) && (
                      <div style={{ marginTop: "1rem" }}>
                        {props.cardRequired ? (
                          <p className="ui-tee-time-tooltip-small_text">*Credit card required</p>
                        ) : null}

                        {props.prepaidRequired ? (
                          <p className="ui-tee-time-tooltip-small_text">*Prepaid required</p>
                        ) : null}
                      </div>
                    )}
                  </div>
                  <div className="ui-tee-time-tooltip-info_container">
                    {pricing.booking_categories.map(bookingCategory => {
                      return (
                        <p key={bookingCategory.id} className="ui-tee-time-tooltip-title_text">
                          <span>{bookingCategory.title}</span>
                        </p>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        </Portal>
      </div>
    </div>
  );
};

export default TeeTimeToolTip;
