import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ReactDOM from "react-dom";
import { useTranslation, Trans } from "react-i18next";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-common-types";
import { useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";

import { StatusCode } from "api/protocols";
import {
  GetTable,
  GetTableReceipt,
  ITable,
  PutCloseTable,
  PutMoveSeats,
  PutTable,
} from "api/rpc/facilityAdmin/tables/tables";
import { PostKitchenChit } from "api/rpc/facilityAdmin/facility/kitchen/kitchen";
import { GetRoomOccupants, IOccupant } from "api/rpc/room";
import { GetFacilityAdmin } from "api/rpc/facilityAdmin/client/admin";
import { GetModifierGroup, IModifierGroup } from "api/rpc/facilityAdmin/modifierGroups";
import { PostTransaction } from "api/rpc/2022-09/facilityAdmin/order/transaction";
import {
  PostCheckout,
  PostMerge,
  PostLineItemToCart,
  PutCart,
  PutClearCart,
  PutLineItemSplit,
  DeleteLineItemToCart,
  PostCart,
} from "api/rpc/2022-09/facilityAdmin/cart/cart";
import { PutLineItemToCart } from "api/rpc/2024-04/facilityAdmin/order/cart/lineItem";
import { GetOrder, PutOrderComplete, GetReceipt, PutVoidOrder } from "api/rpc/2022-09/facilityAdmin/order/order";
import { GetAccount } from "api/rpc/2022-09/facilityAdmin/client/accounts";
import { GetGiftCardValidate } from "api/rpc/2022-09/facilityAdmin/payment/giftCard";
import { DeleteDiscountLine, IDiscount, PostDiscountLine } from "api/rpc/2022-09/facilityAdmin/product/discount";
import { GetCustomer } from "api/rpc/2022-09/facilityAdmin/customer/customer";
import { GetProduct, GetVariant } from "api/rpc/2022-09/facilityAdmin/product/product";
import { GetFolder, IFolder } from "api/rpc/2022-09/facilityAdmin/product/folder";
import { FireKitchenChit } from "api/rpc/2022-09/facilityAdmin/facility/kitchen";

import { IUIActions, closeBooking } from "redux/actions/ui";
import { IFacilityActions } from "redux/actions/facility";
import { ICartActions } from "redux/actions/cart";
import { ICartState } from "redux/reducers/cart";
import { IFacilityState } from "redux/reducers/facility";
import { IUser, IUserPermissions } from "redux/reducers/models/user";
import { IProduct, IVariant } from "redux/reducers/models/product";
import { ICart, ICartLineItem } from "redux/reducers/models/cart";
import { IAuthState } from "redux/reducers/auth";

import {
  clearReaderDisplay,
  sheetInfo,
  processPayment as stripeProcessPayment,
  setReaderDisplay,
  convertOrderIntoDisplayInfo,
} from "helpers/StripeTerminalWrapper";
import { LocaleCurrency } from "helpers/Locale";
import { capitalize, isEmpty, isNumeric, isWholeNumber } from "helpers/Helpers";
import { useStripeTerminalPayment } from "hooks/useStripeTerminalPayment/useStripeTerminalPayment";
import { IChit, usePOSPrinter } from "hooks/usePOSPrinter/usePOSPrinter";

import PaymentMethods from "elements/register/cartMenu/PaymentMethods";
import CartTransactions from "elements/register/cartMenu/CartTransactions";
import Products, { IProductState } from "elements/register/Products";

import TableServiceMoveLineItemsModal from "containers/facility/tableServiceMoveLineItemsModal";
import TextField from "components/form/textField/TextField";
import Radio from "components/radio";
import Sheet from "components/sheet/Sheet";
import Checkbox from "components/form/checkbox/Checkbox";
import { Select } from "components/select/index";
import { ButtonNew as Button } from "components/buttonNew";
import Input from "components/form/input/Input";
import Callout from "components/callout/Callout";
import Popup from "components/popup/Popup";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import GiftCardSell, { IGiftCardSellState } from "components/giftcardSell/GiftCardSell";
import Spin from "components/spin/spin";
import CheckoutForm, { cancelManualCreditPaymentIntent } from "components/checkoutForm/CheckoutForm";
import DiscountLineItemModal from "components/discountLineItemModal/DiscountLineItemModal";
import InputModal from "components/inputModal/InputModal";
import ActionModal, { IAction } from "components/actionModal/ActionModal";
import { Badge } from "components/badge/Badge";
import SelectModifierSheet from "components/selectModifierSheet/SelectModifierSheet";
import OrderCompletePopup from "components/orderCompletePopup/OrderCompletePopup";
import GiftCardRedeem, { IGiftCardRedeemState } from "components/giftCardRedeem/GiftCardRedeem";
import AccountsModal from "components/accountsModal/AccountsModal";
import LeagueSelectionModal from "components/leagueSelectionModal/LeagueSelectionModal";

import { IStripePaymentState } from "../teesheet/TeeSheet";
import { isSeatAbleToMoveLineItems } from "./LineItemModals/TableServiceMoveLineItemsModal";
import TableServiceKitchenChitModal from "./TableServiceKitchenChitModal/TableServiceKitchenChitModal";
import { IRegisterGroup } from "../Admin";

import "./tableService.scss";
import { IKitchenCourse } from "../settings/kitchenCourse/KitchenCourse";
import TableServiceSendLineItemsModal from "./LineItemModals/TableServiceSendLineItemsModal";
import axios, { CancelToken } from "axios";
import TableServiceFoldersMenu from "containers/facility/tableServiceFoldersMenu";
import PriceSheet from "components/priceSheet/PriceSheet";
import ChangeQuantity from "components/changeQuantity/ChangeQuantity";
import MergeSeats, { IMergeSeatsState } from "components/mergeSeats/MergeSeats";
import MoveSeats, { IMoveSeatsState } from "components/moveSeats/MoveSeats";
import EditSeatDetails from "components/editSeatDetails/EditSeatDetails";
import PrintChit from "components/printChit/PrintChit";
import { PRODUCT_FOLDERS_KEY } from "../settings/folders/Folders";
import NoteSheet from "components/noteSheet/NoteSheet";
import TransferTableSheet, { ITransferTableSheetState } from "components/transferTableSheet/TransferTableSheet";
import CreditBookModal from "components/creditBookModal/CreditBookModal";
import useModal from "hooks/modals/useModal";
import { IPrizeAccount } from "../settings/prizeAccounts/PrizeAccount";
import CourseChange from "components/courseChange/CourseChange";
import { cloneDeep, isEqual } from "lodash";
import { NewBadgeGroup } from "components/badgeGroup/NewBadgeGroup";

export interface IMainTableServiceState {
  products: IProduct[];
  folders: IFolder[];
  selectedFolder: IFolder;
  activeCart: ICart;
  closeTableActive: boolean;
  closeSeatActive: boolean;
  voidSeatActive: boolean;
  clearSeatActive: boolean;
  addSeat: boolean;
  addToTableModalVisible: boolean;
  moveLineItemsModalVisible: boolean;
  cancelReason: string;
  seatsOpen: boolean;

  tipSheetVisible: boolean;
  tipAmount: string;
  tipPercent: number;
  mergeSeatsSheetVisible: boolean;
  roomChargeSheetVisible: boolean;
  roomOccupantSearchQuery: string;
  roomOccupantsSearchResults: IOccupant[];
  selectedOccupant: IOccupant;
  showProductSearchModal: boolean;
  showLineItemActionsModal: boolean;
  showActionsModal: boolean;
  showKitchenChitBar: boolean;
  orderActionBarActive: boolean;

  //Courses
  /** The selected course to add a line item under */
  selectedCourseId: number;
  kitchenCourses: Array<IKitchenCourse | Partial<IKitchenCourse>>;
  /** Visibility for the Change Course Modal */
  coursesSheet: boolean;
  /** The selected course in the Change Course modal */
  selectedCoursetoChange: number;
}

export interface IFilterState {
  search: string;
  sort: string;
}

interface ITerminalState {
  online: boolean;
  connecting: boolean;
  reader: any;
}

interface ISendOrderState {
  printOrderVisible: boolean;
  sendOrderVisible: boolean;
  addedLineItems: Array<number>;
  activeTable: ITable;
}

interface IPaymentState {
  paymentModalVisible: boolean;
  paymentAmount: string;
  paymentOption: Record<string, any>;
  paymentInProgress: boolean;
  clientSecret: string;
  manualCardModalVisible: boolean;
  giftCardModalVisible: boolean;
  order: undefined | Record<string, any>;
  gift_cards: {
    cart_line_item_id: number;
    code: string;
    pin: number;
    random: boolean;
    balance: number;
    reload: number;
  }[];
  changeDue: number;
  changeDueVisible: boolean;
  accountsVisible: boolean;
  accounts: Record<string, any>[];
  selectedAccount: Record<string, any>;
  sellGiftCardsActive: boolean;
  leagueFeesActive: boolean;
  leagueFeeProducts: Array<ICartLineItem>;
  selectedLeagues: Array<number>;
}

interface IItemActionsState {
  selectedLineItem: ICartLineItem;
  quantitySheetVisible: boolean;
  quantitySelected: number;
  removeItemSheetVisible: boolean;
  discountSheetVisible: boolean;
  priceSheetVisible: boolean;
  price: string;
  discountIdSelected: number;
  discountSelected: IDiscount;
  splitLineItemVisible: boolean;
  selectedSplitLineItemIds: Array<number>;
  selectedSplitCartIds: Array<number>;
  modifiersSheetVisible: boolean;
  allModifierGroups: IModifierGroup[];
  deleteModifierOnCancel: boolean;
  selectedModifierGroupVariants: { [key: string]: Record<string, any>[] };
  addedModifierGroups: IModifierGroup[];
  cartDetailsSheetVisible: boolean;
  prevSelectedCustomer: Record<string, any>;
  prevCartDescription: string;
  noteSheetVisible: boolean;
  note: string;
}

interface ITableModifiersState {
  //Structure:
  //{cart_token: {line_item_parent_id: [...lineitems]}}
  [key: string]: { [key: string]: ICartLineItem[] };
}

interface IProps {
  uiActions: IUIActions;
  facilityActions: IFacilityActions;
  cartStore: ICartState;
  cartActions: ICartActions;
  facilityStore: IFacilityState;
  terminalStore: ITerminalState;
  authStore: IAuthState;
}

interface ITransferTableState {
  modalVisibility: boolean;
  users: Array<IUser>;
}

interface ICourseChangeState {
  selectedCourseToChange: number;
}

export default function TableService(props: IProps) {
  const { uiActions, facilityActions, facilityStore, cartStore, cartActions, terminalStore, authStore } = props;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { Option } = Select;
  const permissions = props.authStore?.user?.permissions;

  const register_group: IRegisterGroup = JSON.parse(localStorage.getItem("register_group"));

  const elements = useElements();

  const {
    state: modalCreditBook,
    updateModal: updateCreditBook,
    closeModal: closeCreditBook,
  } = useModal<{ selectedCreditBook: IPrizeAccount }>({ selectedCreditBook: null });

  const [state, setState] = useState<IMainTableServiceState>({
    products: null,
    folders: [],
    selectedFolder: null,
    activeCart: null,
    closeTableActive: false,
    closeSeatActive: false,
    voidSeatActive: false,
    clearSeatActive: false,
    addSeat: false,
    addToTableModalVisible: false,
    moveLineItemsModalVisible: false,
    cancelReason: "Server Error",
    seatsOpen: false,

    tipSheetVisible: false,
    tipAmount: "",
    tipPercent: null,
    mergeSeatsSheetVisible: false,
    roomChargeSheetVisible: false,
    roomOccupantSearchQuery: "",
    roomOccupantsSearchResults: [],
    selectedOccupant: null,
    showProductSearchModal: false,
    showLineItemActionsModal: false,
    showActionsModal: false,
    showKitchenChitBar: false,
    orderActionBarActive: false,

    //Courses
    selectedCourseId: null,
    kitchenCourses: [],

    coursesSheet: false,
    selectedCoursetoChange: null,
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    search: "",
    sort: "alpha",
  });

  const [productState, setProductState] = useState<IProductState>({
    selectedProduct: undefined,

    variants: [],
    variantSheetOpen: false,
  });

  const [sendOrderState, setSendOrderState] = useState<ISendOrderState>({
    printOrderVisible: false,
    addedLineItems: [],
    sendOrderVisible: false,
    activeTable: null,
  });

  const [paymentState, setPaymentState] = useState<IPaymentState>({
    paymentModalVisible: false,
    paymentAmount: "",
    paymentOption: null,
    paymentInProgress: false,
    clientSecret: "",
    manualCardModalVisible: false,
    giftCardModalVisible: false,
    order: undefined,
    gift_cards: null,
    sellGiftCardsActive: false,
    changeDue: null,
    changeDueVisible: false,
    accountsVisible: false,
    accounts: [],
    selectedAccount: undefined,
    leagueFeesActive: false,
    leagueFeeProducts: [],
    selectedLeagues: null,
  });

  const [itemActionsState, setItemActionsState] = useState<IItemActionsState>({
    selectedLineItem: null,

    quantitySheetVisible: false,
    quantitySelected: null,

    removeItemSheetVisible: false,

    priceSheetVisible: false,
    price: "",

    discountSheetVisible: false,
    discountIdSelected: -1,
    discountSelected: null,

    splitLineItemVisible: false,
    selectedSplitLineItemIds: [],
    selectedSplitCartIds: [],

    addedModifierGroups: null,
    modifiersSheetVisible: false,
    allModifierGroups: [],
    selectedModifierGroupVariants: {},
    deleteModifierOnCancel: false,

    cartDetailsSheetVisible: false,
    prevSelectedCustomer: null,
    prevCartDescription: "",

    noteSheetVisible: false,
    note: "",
  });

  const [modifierGroups, setModifierGroups] = useState<ITableModifiersState>({});

  const [manualStripeState, setManualStripeState] = useState({
    clientSecret: "",
    visible: false,
    transaction: undefined,
    elementComplete: {
      cardNumber: false,
      cardExpiry: false,
      cardCvc: false,
    },
  });

  const [stripePaymentState, setStripePaymentState] = useState<IStripePaymentState>({
    onCancel: () => {},
    onOk: () => {},
    cancelText: "",
    okText: "",
    sheetVisible: false,
    titleText: "",
    message: "",
    convertedCode: "",
    processing: false,
    success: false,
  });

  const [submitTrigger, setSubmitTrigger] = useState<boolean>(false);

  const [manualProcessing, setManualProcessing] = useState<boolean>(false);

  const [forceRefreshChits, setForceRefreshChits] = useState<boolean>(true);

  const [kitchenChitModalOpen, setKitchenChitModalOpen] = useState<boolean>(false);

  const {
    StripeTerminalSheet,
    processPayment,
    paymentStatus,
    order: tempStripePaymentOrder,
  } = useStripeTerminalPayment();

  const { printChits } = usePOSPrinter();

  const [transferTableState, setTransferTableState] = useState<ITransferTableState>({
    modalVisibility: false,
    users: [],
  });

  const [removeDiscountState, setRemoveDiscountState] = useState<{
    modalVisible: boolean;
    discountId: number;
    cartId: number;
  }>({
    modalVisible: false,
    discountId: null,
    cartId: null,
  });
  const [applyDiscount, setApplyDiscount] = useState<boolean>(false);

  useEffect(() => {
    if (!state?.activeCart && cartStore?.activeTable?.carts?.[0]) {
      setState(prevState => ({ ...prevState, activeCart: cartStore.activeTable.carts[0] }));
    }
  }, [state?.activeCart]);

  useEffect(() => {
    //If there is no active table selected, return to table selection page
    if (!cartStore?.activeTable) {
      void backToTableSelection();
    } else {
      void loadFirstSeatCart();
      void loadFolders();
      void loadFacilityUsers();
      void setKitchenCourses();
      if (facilityStore?.paymentOptions?.length === 0) {
        void facilityActions.loadPaymentOptions();
      }
      if (facilityStore?.discountOptions?.length === 0) {
        void facilityActions.loadDiscountOptions();
      }
    }
  }, []);

  useEffect(() => {
    if ((paymentStatus === "partial_payment" || paymentStatus === "paid") && tempStripePaymentOrder !== null) {
      void updateOrder(tempStripePaymentOrder.id, true);
      if (paymentState.order.balance > 0) {
        setPaymentState(prevState => ({ ...prevState, paymentAmount: paymentState.order.balance.toFixed(2) }));
      }
    }
  }, [paymentStatus]);

  useEffect(() => {
    if (paymentState?.order) {
      if (paymentState.order.balance > 0 && paymentState?.order?.financial_status !== "unpaid") {
        setPaymentState(prevState => ({ ...prevState, paymentAmount: paymentState.order.balance.toFixed(2) }));
      }
    }
  }, [paymentState?.order]);

  useEffect(() => {
    if (cartStore.cart) {
      setPaymentState(prevState => ({
        ...prevState,
        paymentAmount:
          paymentState?.order?.balance && paymentState?.order?.financial_status !== "unpaid"
            ? paymentState?.order?.balance?.toFixed(2)
            : cartStore?.cart?.total_price.toFixed(2),
        gift_cards: null,
        selectedLeagues: null,
      }));
    } else {
      setPaymentState(prevState => ({ ...prevState, paymentAmount: "0.00" }));
    }
  }, [cartStore?.cart]);

  useEffect(() => {
    if (paymentState.selectedAccount || modalCreditBook.selectedCreditBook) {
      void handleProcessPayment();
    }
    return () => {
      setPaymentState(prevState => ({ ...prevState, selectedAccount: undefined }));
      updateCreditBook({ selectedCreditBook: null });
    };
  }, [paymentState.selectedAccount, modalCreditBook.selectedCreditBook]);

  //Update reader on cart change
  useEffect(() => {
    if (cartStore?.cart?.line_items && terminalStore?.reader) {
      if (cartStore?.cart?.total_price > 0) {
        void setReaderDisplay(convertOrderIntoDisplayInfo(cartStore?.cart, paymentState.order));
      }
    }
  }, [cartStore?.cart, terminalStore?.reader]);

  useEffect(() => {
    if (cartStore?.activeTable) {
      const closeTable = cartStore?.activeTable?.carts?.some(cart => cart.status === "open");
      if (!closeTable) {
        setState(prevState => ({ ...prevState, closeTableActive: true }));
      }
      void setTableModifierGroups();
    }
  }, [cartStore?.activeTable]);

  useEffect(() => {
    if (itemActionsState.addedModifierGroups) {
      void handleOpenModifiersSheetAddItem();
    }
  }, [itemActionsState.addedModifierGroups]);

  useEffect(() => {
    if (state.addSeat) {
      setState(prevState => ({ ...prevState, activeCart: cartStore.cart, addSeat: false }));
    }
  }, [state.addSeat]);

  // Used for searching all products
  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (async () => {
          try {
            if (filterState.search === "") {
              if (mounted) {
                setState(prevState => ({ ...prevState, products: [] }));
              }
              return;
            } else {
              const res = await loadProducts();
              if (mounted) {
                setState(prevState => ({ ...prevState, products: res }));
              }
            }
          } catch (error) {
            console.log("err", error);
          }
          return;
        })();
      }, 500);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [filterState.search]);

  useEffect(() => {
    if (productState.variants.length > 1) {
      if (!productState.variantSheetOpen) {
        setProductState(prevState => ({ ...prevState, variantSheetOpen: true }));
      }
    }
  }, [productState.variants]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = global.setTimeout(() => {
        void searchForRoomOccupants(mounted, state.roomOccupantSearchQuery, source.token);
      }, 2000);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      setState(prevState => ({ ...prevState, roomOccupantsSearchResults: [] }));
      source.cancel("Cancelled");
    };
  }, [state.roomOccupantSearchQuery]);

  useEffect(() => {
    if (applyDiscount) {
      void filterModifiers();
    }
  }, [applyDiscount]);

  const elementsComplete =
    manualStripeState.elementComplete.cardNumber === true &&
    manualStripeState.elementComplete.cardExpiry === true &&
    manualStripeState.elementComplete.cardCvc === true;

  function setKitchenCourses() {
    const kitchenCourses = [...facilityStore?.kitchenCourses, { id: -1, title: "No Course" }];
    setState(prevState => ({ ...prevState, kitchenCourses }));
  }

  async function loadRoomOccupants(roomOccupantSearchQuery: string, cancelToken: CancelToken) {
    const roomOccupantsRes = await GetRoomOccupants({ search: roomOccupantSearchQuery }, true, cancelToken);

    if (roomOccupantsRes.status !== StatusCode.OK && roomOccupantsRes.message !== "Cancelled") {
      uiActions.showError(roomOccupantsRes.message);
      return;
    }

    return roomOccupantsRes;
  }

  async function searchForRoomOccupants(mounted: boolean, roomOccupantSearchQuery: string, cancelToken: CancelToken) {
    try {
      if (roomOccupantSearchQuery === "") {
        if (mounted) {
          setState(prevState => ({ ...prevState, roomOccupantsSearchResults: [] }));
        }
      } else {
        const res = await loadRoomOccupants(roomOccupantSearchQuery, cancelToken);
        if (mounted && res?.status === StatusCode.OK && res?.data) {
          const occupants = res.data.map(occupant => {
            return {
              reservation_number: occupant.reservation_number ?? "",
              room_number: occupant.room_number ?? "",
              res_status: occupant.res_status,
              guest_name: occupant.guest_name ?? "",
              credit_limit: occupant.credit_limit,
            };
          });
          setState(prevState => ({ ...prevState, roomOccupantsSearchResults: occupants }));
        }
      }
    } catch (error) {
      setState(prevState => ({ ...prevState }));
    }
    setState(prevState => ({
      ...prevState,
      selectedOccupant: null,
    }));
  }

  async function loadCustomers(customerQuery: string) {
    const customerRes = await GetCustomer({ search: customerQuery }, false);

    if (customerRes.status !== StatusCode.OK || !customerRes?.data) {
      uiActions.showError(customerRes.message);
      return [];
    }

    return customerRes?.data as Array<Record<string, any>>;
  }

  function loadFirstSeatCart() {
    if (cartStore?.activeTable?.carts?.length > 0) {
      void cartActions.loadTableCart({ tableCart: cartStore?.activeTable?.carts[0] }, false);
      setPaymentState(prevState => ({ ...prevState, order: cartStore?.activeTable?.carts[0]?.order }));
    }
  }

  async function loadProducts() {
    const productRes = await GetProduct({ extended: true, search: filterState.search }, true);
    if (productRes.status !== StatusCode.OK) {
      uiActions.showError(productRes.message);
      return [];
    }

    return productRes.data;
  }

  async function loadFacilityUsers() {
    const usersRes = await GetFacilityAdmin(null, false);
    if (usersRes.status !== StatusCode.OK) {
      uiActions.showError("Error getting admin users");
      return;
    }

    setTransferTableState(prevState => ({
      ...prevState,
      users: usersRes.data,
    }));
  }

  async function handleChangeSelectedFolder(selectedFolder: IFolder) {
    if (selectedFolder?.id === undefined) {
      return;
    }

    const storedProductFolders: IFolder[] = JSON.parse(localStorage.getItem(PRODUCT_FOLDERS_KEY)) ?? [];
    const storedProductFolder = storedProductFolders.find(productFolder => productFolder?.id === selectedFolder.id);

    const selectedFolderDate = new Date(selectedFolder.updated_at);
    const storedProductFolderDate = new Date(storedProductFolder?.updated_at);

    const storedProductFolderIsValid =
      !isNaN(selectedFolderDate?.getTime()) &&
      !isNaN(storedProductFolderDate?.getTime()) &&
      storedProductFolderDate.getTime() >= selectedFolderDate.getTime();

    if (storedProductFolderIsValid) {
      setState(prev => ({ ...prev, selectedFolder: storedProductFolder }));
    } else {
      const folderRes = await GetFolder({ id: selectedFolder.id, extended: true }, true);

      if (folderRes.status !== StatusCode.OK || folderRes.data?.length === undefined || folderRes.data.length === 0) {
        uiActions.showError(t("secure.facility.table_service.table_service.001"));
        return;
      }

      const newSelectedFolder = folderRes.data[0];

      if (!storedProductFolder) {
        const updatedStoredProductFolders = [...storedProductFolders, newSelectedFolder];
        localStorage.setItem(PRODUCT_FOLDERS_KEY, JSON.stringify(updatedStoredProductFolders));
      } else {
        const updatedStoredProductFolders = storedProductFolders.map(productFolder => {
          if (productFolder.id === newSelectedFolder.id) {
            return newSelectedFolder;
          } else {
            return productFolder;
          }
        });

        localStorage.setItem(PRODUCT_FOLDERS_KEY, JSON.stringify(updatedStoredProductFolders));
      }

      setState(prev => ({ ...prev, selectedFolder: newSelectedFolder }));
    }
  }

  async function loadFolders() {
    const register = JSON.parse(localStorage.getItem("register"));

    if (!register) {
      uiActions.showError(t("secure.facility.table_service.table_service.002"));
      return;
    }

    const folderRes = await GetFolder({ register_group_id: register.register_group_id, extended: false }, true);

    if (folderRes.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.table_service.table_service.003"));
      return;
    }

    const folders = folderRes.data;

    // Get default folder for the selected Register or the default set for the facility
    const defaultFolderId = Number(localStorage.getItem("default_folder_id"));
    let selectedFolder: IFolder = undefined;
    if (defaultFolderId > 0) {
      selectedFolder = folders.find((folder: IFolder) => folder.id === defaultFolderId);
    } else {
      selectedFolder = folders.find((folder: IFolder) => folder.default === true);
    }

    if (!selectedFolder) {
      selectedFolder = folders?.[0];
    }

    setState(prevState => ({
      ...prevState,
      folders: folders,
    }));

    if (selectedFolder) {
      void handleChangeSelectedFolder(selectedFolder);
    }
  }

  function renderProducts() {
    if (filterState.search && state.products) {
      const products = state.products?.sort((firstProd: IProduct, secondProd: IProduct) => {
        if (filterState.sort === "alpha") {
          if (firstProd.title < secondProd.title) {
            return -1;
          } else if (firstProd.title > secondProd.title) {
            return 1;
          } else {
            return 0;
          }
        }
      }) as [];
      return { useGridPositions: false, products };
    } else {
      const products = state.selectedFolder?.products ?? ([] as []);
      return { useGridPositions: true, products };
    }
  }

  async function selectProduct(product: Record<string, any>) {
    setProductState(prevState => ({
      ...prevState,
      selectedProduct: product,
    }));

    let variants: Array<IVariant>;

    // Check for variant objects in local storage
    if (product?.variants) {
      variants = product?.variants?.filter((variant: Record<string, any>) => variant?.facility_access === true);
    } else {
      // Make API call if no variants available in local storage
      const res = await GetVariant({ product_id: product.id, extended: true }, true);
      if (res?.status !== StatusCode.OK) {
        uiActions.showError(t("secure.facility.table_service.table_service.004"));
        return;
      }
      variants = res?.data?.filter((variant: Record<string, any>) => variant?.facility_access === true);
    }
    if (variants?.length > 1) {
      setProductState(prevState => ({ ...prevState, variants: variants }));
    } else {
      // Automatically add product to cart if single variant
      if (variants[0]) {
        await onConfirmVariant(variants[0], product);
      } else {
        uiActions.showError(t("secure.facility.table_service.table_service.005"));
      }
    }
  }

  async function onConfirmVariant(variant: Record<string, any>, product?: Record<string, any>) {
    if (variant === undefined) {
      uiActions.showError(t("secure.facility.table_service.table_service.006")); //inform user of TypeError from Promise
      return;
    }
    if (cartStore.cart?.status === "complete") {
      uiActions.showError(t("secure.facility.table_service.table_service.007"));
      return;
    }
    uiActions.enqueue();

    const selectedProduct = product ? product : productState?.selectedProduct;

    const res = await PostLineItemToCart(
      {
        cart_id: cartStore.cart?.id,
        variant_id: variant?.id,
        parent_id: null,
        quantity: 1,
        kitchen_meal_id: state?.selectedCourseId,
      },
      false,
    );

    void cartActions.loadTableCart({ tableCart: res?.data }, false);
    void setUpdatedCartOnTable(res?.data);

    onCancel();
    if (res && res?.status === StatusCode.BAD_REQUEST) {
      uiActions.showError(t("secure.facility.table_service.table_service.008"));
      uiActions.dequeue();
      return;
    }

    // Check if the product has modifiers
    if (selectedProduct?.has_modifiers) {
      const postedLineItem = res?.data?.line_items[res?.data?.line_items?.length - 1];

      const modifierGroupResponse = await GetModifierGroup({ product_id: variant.product_id, extended: true }, true);

      if (modifierGroupResponse.status !== StatusCode.OK) {
        uiActions.showError(modifierGroupResponse.message);
        return;
      }

      // If the product has modifiers, open the modifier groups modal
      modifierGroupResponse.data?.every((modifierGroup: IModifierGroup) => {
        setItemActionsState(prevState => ({
          ...prevState,
          addedModifierGroups: modifierGroupResponse.data,
          selectedLineItem: postedLineItem ?? undefined,
        }));
        return false;
      });
    }
    uiActions.dequeue();
  }

  function onCancel() {
    setProductState(prevState => ({
      ...prevState,
      variantSheetOpen: false,
      variants: [],
      selectedProduct: undefined,
    }));
  }

  async function handlePrintReceipt(printChitInfo: ISendOrderState) {
    const receiptRes = await GetTableReceipt(
      { table_id: cartStore?.activeTable?.id, line_item_ids: printChitInfo.addedLineItems },
      true,
    );
    if (receiptRes.status !== StatusCode.OK) {
      uiActions.showError(receiptRes.message);
      return;
    }
    window.open().document.write(receiptRes.data);
    setSendOrderState(prevState => ({
      ...prevState,
      printOrderVisible: false,
      addedLineItems: [],
      activeTable: null,
    }));
  }

  async function handleSendChit(lineItemIds: number[]) {
    const receiptRes = await PostKitchenChit(
      { table_id: cartStore?.activeTable?.id, line_item_ids: lineItemIds },
      true,
    );
    if (receiptRes.status !== StatusCode.OK) {
      uiActions.showError("Error creating kitchen chits");
      return;
    }
    void printChits(receiptRes.data);
    uiActions.showSuccess(t("secure.facility.table_service.table_service.009"));

    ReactDOM.unstable_batchedUpdates(() => {
      setSendOrderState(prevState => ({
        ...prevState,
        sendOrderVisible: false,
        addedLineItems: [],
        activeTable: null,
      }));
      setForceRefreshChits(true);
    });

    // refresh table and cart
    void cartActions.loadTableCart({ token: cartStore?.cart?.token }, false);
    void cartActions.loadActiveTableNew(cartStore.cart?.table_id, true);
  }

  // function handleResendButton(cartIndex: number, lineItemIndex: number) {
  //   const updatedTable = { ...sendOrderState.activeTable };
  //   if (updatedTable.carts[cartIndex].line_items[lineItemIndex]) {
  //     updatedTable.carts[cartIndex].line_items[lineItemIndex] = {
  //       ...updatedTable.carts[cartIndex]?.line_items[lineItemIndex],
  //       kitchen_chit_id: null,
  //     };
  //     setSendOrderState(prevState => ({ ...prevState, selectedTable: updatedTable }));
  //   } else {
  //     console.log("Index out of bounds error");
  //   }
  // }

  function handleSeatChange(selectedCart: ICart) {
    void cartActions.loadTableCart({ tableCart: selectedCart }, false);
    ReactDOM.unstable_batchedUpdates(() => {
      setPaymentState(prevState => ({
        ...prevState,
        order:
          selectedCart?.order?.financial_status === "partially_paid" ||
          selectedCart?.order?.financial_status === "unpaid"
            ? selectedCart?.order
            : undefined,
      }));
      setState(prevState => ({ ...prevState, activeCart: selectedCart }));
    });
  }

  function handleCheckboxChange(lineItemId: number) {
    const lineItems = [...sendOrderState.addedLineItems];
    const foundIndex = lineItems?.indexOf(lineItemId);
    if (foundIndex === -1) {
      lineItems.push(lineItemId);
    } else {
      lineItems.splice(foundIndex, 1);
    }
    setSendOrderState(prevState => ({ ...prevState, addedLineItems: lineItems }));
  }

  function handleSelectAllCheckboxChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { checked } = e.target;
    const lineItems: number[] = [];
    if (checked) {
      cartStore?.activeTable?.carts?.forEach((cart: Record<string, any>) => {
        cart?.line_items?.forEach((lineItem: ICartLineItem) => {
          if (cart?.status !== "complete" && !lineItem?.parent_id) {
            lineItems.push(lineItem.id);
          }
        });
      });
    }

    setSendOrderState(prevState => ({ ...prevState, addedLineItems: lineItems }));
  }

  async function handleCloseTable() {
    const closeTableRes = await PutCloseTable({ table_id: cartStore?.activeTable?.id }, true);
    if (closeTableRes.status !== StatusCode.OK) {
      uiActions.showError(closeTableRes.message);
      return;
    }

    //Check register to auto log out
    const register = JSON.parse(localStorage.getItem("register"));
    if (register && register?.auto_log_out === true) {
      localStorage.setItem("switch_user_after_transaction", "true");
    }

    uiActions.showSuccess(t("secure.facility.table_service.table_service.010"));
    void backToTableSelection();
  }

  async function handleAddSeat() {
    const postRes = await PostCart({ type: "tab", table_id: cartStore?.activeTable?.id }, true);
    if (postRes.status !== StatusCode.OK) {
      uiActions.showError("Error occurred when creating a new seat");
      return;
    }

    const updatedTable = { ...cartStore?.activeTable };
    updatedTable.carts?.push(postRes?.data);

    void cartActions.loadTableCart({ tableCart: postRes?.data }, false);
    void cartActions.setActiveTable(updatedTable);

    setState(prevState => ({ ...prevState, addSeat: true }));
  }

  function backToTableSelection() {
    void clearReaderDisplay();
    history.push("/admin/table-selection");
  }

  function closePaymentSheet() {
    setPaymentState(prevState => ({
      ...prevState,
      paymentModalVisible: false,
      paymentAmount: paymentState?.order?.balance
        ? paymentState?.order?.balance?.toFixed(2)
        : cartStore?.cart?.total_price.toFixed(2),
      paymentOption: null,
    }));
  }

  function handlePaymentOptionChange(paymentOption: Record<string, any>) {
    if (paymentOption !== undefined) {
      setPaymentState(prevState => ({
        ...prevState,
        paymentOption: paymentOption,
      }));
    }
  }

  function handlePaymentInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setPaymentState(prevState => ({ ...prevState, [id]: value }));
  }

  function openGiftCardSheet() {
    setPaymentState(prevState => ({ ...prevState, giftCardModalVisible: true }));
  }

  async function loadReceipt(order_id: number, type: string) {
    const receiptRes = await GetReceipt({ id: order_id, type: type }, true);

    if (receiptRes.status === StatusCode.OK) {
      window.open().document.write(receiptRes.data);
    } else {
      uiActions.showError(receiptRes.message);
    }
  }

  async function completeOrder(order_id: number) {
    const register = JSON.parse(localStorage.getItem("register"));
    const registerId = register?.id;
    const completeRes = await PutOrderComplete(
      {
        order_id,
        gift_cards: paymentState.gift_cards,
        register_id: registerId,
        league_ids: paymentState.selectedLeagues,
      },
      true,
    );

    if (completeRes.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.table_service.table_service.232"));
      return;
    }
    uiActions.showSuccess(t("secure.facility.table_service.table_service.233"));
    setPaymentState(prevState => ({ ...prevState, gift_cards: null, selectedLeagues: null }));
  }

  async function updateOrder(order_id: number, clearCart: boolean) {
    if (order_id) {
      //Update order
      const orderRes = await GetOrder({ id: order_id, extended: true }, true);
      if (orderRes.status !== StatusCode.OK) {
        uiActions.showError(orderRes.message);
        return;
      }
      setManualProcessing(false);

      const order = orderRes.data[0];
      if (order.balance <= 0 || order.financial_status === "paid") {
        if (clearCart) {
          await completeOrder(order_id);

          //Check if table is active
          if (cartStore?.activeTable) {
            const tablesRes = await GetTable({ id: cartStore?.activeTable?.id, carts: true, orders: true }, false);

            if (tablesRes.status !== StatusCode.OK) {
              uiActions.showError(t("secure.facility.table_service.table_service.214"));
              return;
            }

            const currentCart = tablesRes?.data[0]?.carts?.find(cart => cart?.token === cartStore?.cart?.token);
            if (currentCart) {
              void cartActions.loadTableCart({ tableCart: currentCart }, false);
            }
          }
          void cartActions.loadActiveTableNew(cartStore?.activeTable?.id, true);

          if (terminalStore?.reader) {
            void clearReaderDisplay();
          }

          ReactDOM.unstable_batchedUpdates(() => {
            setPaymentState(prevState => ({
              ...prevState,
              paymentModalVisible: false,
              order: undefined,
              giftCardModalVisible: false,
              selectedAccount: undefined,
              paymentOption: null,
            }));
          });

          //Order completed, display order action bar
          setState(prevState => ({ ...prevState, orderActionBarActive: true }));
        }
      } else {
        void cartActions.loadActiveTableNew(cartStore?.activeTable?.id, false);
        // Still need to process another payment for this order
        setPaymentState(prevState => ({
          ...prevState,
          order: order,
          paymentAmount: order.balance.toFixed(2),
          giftCardModalVisible: false,
        }));
      }
    }
  }

  const handleProcessPayment = async (giftCardInfo?: IGiftCardRedeemState) => {
    uiActions.enqueue();
    setPaymentState(prevState => ({ ...prevState, paymentInProgress: true }));
    const cartTotal = Number(cartStore?.cart?.total_price?.toFixed(2));
    try {
      const cart_id = cartStore?.cart?.id;

      // Checking out produces a new order

      let checkoutRes: { status: any; data: any };
      const register = JSON.parse(localStorage.getItem("register"));
      const registerId = register?.id ? register?.id : undefined;

      let order: Record<string, any>;
      if (
        !paymentState?.order ||
        paymentState?.order?.financial_status === "unpaid" ||
        paymentState?.order?.financial_status === "cancelled"
      ) {
        //Void the order if it is unpaid. This situation could happen if the user tries to make a reader payment then decides to cancel the transaction.
        if (paymentState?.order?.financial_status === "unpaid") {
          const voidRes = await PutVoidOrder(
            { order_id: paymentState?.order?.id, notes: "Transaction cancelled", financial_status: "cancelled" },
            false,
          );
          if (voidRes?.status !== StatusCode.OK) {
            uiActions.showError("Error voiding previous order");
          }
        }
        // Create a new order if order does not exist in state
        checkoutRes = await PostCheckout({ cart_id, register_id: registerId }, false);
        order = checkoutRes.data.order;
        setPaymentState(prevState => ({ ...prevState, order: checkoutRes.data.order }));
      } else if (cartStore?.cart?.order_id && !paymentState.order) {
        //Get partially paid order
        const orderRes = await GetOrder({ id: cartStore?.cart?.order_id, extended: true }, false);
        if (orderRes?.status !== StatusCode.OK) {
          uiActions.showError("Error getting order");
          uiActions.dequeue();
          setPaymentState(prevState => ({ ...prevState, paymentInProgress: false }));
          return;
        }
        order = orderRes.data[0];
        setPaymentState(prevState => ({ ...prevState, order: orderRes.data[0] }));
      } else {
        //Continue order
        order = paymentState?.order;
      }

      const finalAmount = Number(paymentState.paymentAmount);

      //Check to see if amount is acceptable
      if (Number.isNaN(finalAmount)) {
        uiActions.dequeue();
        uiActions.showError(`'${paymentState.paymentAmount}'${t("secure.facility.table_service.table_service.011")} `);
        setPaymentState(prevState => ({ ...prevState, paymentInProgress: false }));
        return;
      }

      if (
        (finalAmount > cartTotal && paymentState.paymentOption?.payment_method !== "cash") ||
        (finalAmount > order?.balance && paymentState.paymentOption?.payment_method !== "cash") ||
        finalAmount < 0
      ) {
        uiActions.dequeue();
        uiActions.showError(`'${paymentState.paymentAmount}'${t("secure.facility.table_service.table_service.012")} `);
        setPaymentState(prevState => ({ ...prevState, paymentInProgress: false }));
        return;
      }

      if ((paymentState?.order || order) && !paymentState.paymentInProgress) {
        const order_id = order ? order?.id : paymentState?.order?.id;

        if (paymentState.paymentOption?.payment_method === "manual_card") {
          const transactionRes = await PostTransaction(
            {
              kind: "sale",
              amount: finalAmount,
              order_id: order_id,
              payment_option_id: paymentState.paymentOption?.id,
            },
            false,
          );

          if (transactionRes.status === StatusCode.OK) {
            setManualStripeState(prevState => ({
              ...prevState,
              transaction: transactionRes.data,
              clientSecret: transactionRes.data.client_secret,
              visible: true,
            }));
          } else {
            uiActions.showError("Error generating transaction");
            setPaymentState(prevState => ({ ...prevState, paymentInProgress: false }));
            uiActions.dequeue();
            return;
          }
          // Using terminal - display modal
        } else if (paymentState.paymentOption?.payment_method === "card") {
          if (props.facilityStore.facility.terminal_integration === "server") {
            // If cart already has a tip, skip tipping
            await processPayment(
              order_id,
              paymentState.paymentOption,
              finalAmount,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              Number(cartStore?.cart?.total_tip) > 0 ? true : false,
            );
          } else {
            uiActions.dequeue();
            setStripePaymentState(prevState => ({
              ...prevState,
              sheetVisible: true,
              onCancel: undefined,
              okText: t("secure.facility.table_service.table_service.013"),
              processing: true,
              onOk: async () => {
                setStripePaymentState(prevState => ({
                  ...prevState,
                  sheetVisible: false,
                  onCancel: undefined,
                  okText: undefined,
                  processing: false,
                  onOk: () => {},
                  titleText: "",
                  success: false,
                  message: "",
                }));
                await clearReaderDisplay();
              },
              titleText: t("secure.facility.table_service.table_service.014"),
              message: t("secure.facility.table_service.table_service.015"),
            }));

            await stripeProcessPayment(
              order_id,
              finalAmount,
              paymentState.paymentOption,
              handleStripeSuccess,
              handleStripeError,
            );
            uiActions.enqueue();
          }
        } else if (paymentState.paymentOption?.payment_method === "gift_card") {
          const transactionRes = await PostTransaction(
            {
              kind: "sale",
              amount: finalAmount,
              order_id: order_id,
              payment_option_id: paymentState.paymentOption?.id,
              gift_card_code: giftCardInfo.giftCardCode,
              gift_card_pin: giftCardInfo.giftCardPin,
            },
            false,
          );

          if (transactionRes.status === StatusCode.OK) {
            setPaymentState(prevState => ({
              ...prevState,
              giftCardCode: "",
              giftCardPin: "",
              giftCardModalVisible: false,
            }));

            // display success notification
            uiActions.showSuccess("Transaction generated successfully");

            await updateOrder(transactionRes.data.order_id, true);
          } else {
            uiActions.showError("Error generating transaction");
            setPaymentState(prevState => ({ ...prevState, paymentInProgress: false }));
            uiActions.dequeue();
            return;
          }
        } else if (paymentState.paymentOption?.payment_method === "account") {
          const transactionRes = await PostTransaction(
            {
              kind: "sale",
              amount: finalAmount,
              order_id: order_id,
              payment_option_id: paymentState.paymentOption.id,
              account_id: paymentState.selectedAccount.id,
            },
            false,
          );

          if (transactionRes?.status !== StatusCode.OK) {
            uiActions.showError("Error generating transaction");
            setPaymentState(prevState => ({ ...prevState, paymentInProgress: false }));
            uiActions.dequeue();
            return;
          }

          // display success notification
          uiActions.showSuccess("Transaction generated successfully");

          // Update order and check if there is a remaining balance
          await updateOrder(transactionRes.data.order_id, true);
        } else if (paymentState.paymentOption?.payment_method === "credit_book") {
          const transactionRes = await PostTransaction(
            {
              kind: "sale",
              amount: finalAmount,
              order_id: order_id,
              payment_option_id: paymentState.paymentOption.id,
              credit_book_id: modalCreditBook.selectedCreditBook.id,
            },
            false,
          );

          if (transactionRes?.status !== StatusCode.OK) {
            setPaymentState(prevState => ({ ...prevState, paymentInProgress: false }));
            closeCreditBook();
            uiActions.showError(
              typeof transactionRes?.data === "string" ? transactionRes.data : "Error generating transaction",
            );
            uiActions.dequeue();
            return;
          }

          // display success notification
          uiActions.showSuccess("Transaction generated successfully");

          // Update order and check if there is a remaining balance
          await updateOrder(transactionRes.data.order_id, true);
        } else if (
          paymentState.paymentOption?.payment_method === "room_charge_rdp" ||
          paymentState.paymentOption?.payment_method === "maestro"
        ) {
          const transactionRes = await PostTransaction(
            {
              kind: "sale",
              amount: finalAmount,
              order_id: order_id,
              payment_option_id: paymentState.paymentOption?.id,
              guest_name: state.selectedOccupant?.guest_name,
              room_number: state.selectedOccupant?.room_number,
            },
            false,
          );

          if (transactionRes.status !== StatusCode.OK) {
            uiActions.showError("Error generating transaction");
            uiActions.dequeue();
            return;
          } else {
            uiActions.showSuccess("Transaction generated successfully");

            // Update order and check if there is a remaining balance
            await updateOrder(transactionRes.data.order_id, true);

            closeRoomChargeSheet();
          }
        } else {
          let changeDue: number;
          //Display change due for cash payments
          if (paymentState.paymentOption?.payment_method === "cash") {
            if (order?.balance && Number(paymentState.paymentAmount) > order?.balance) {
              changeDue = Number(paymentState.paymentAmount) - order?.balance;
              setPaymentState(prevState => ({
                ...prevState,
                changeDue: changeDue,
                changeDueVisible: true,
              }));
            } else if (Number(paymentState.paymentAmount) > cartTotal) {
              changeDue = Number(paymentState.paymentAmount) - cartTotal;
              setPaymentState(prevState => ({
                ...prevState,
                changeDue: changeDue,
                changeDueVisible: true,
              }));
            }
          }
          const transactionRes = await PostTransaction(
            {
              kind: "sale",
              amount: changeDue ? finalAmount - changeDue : finalAmount,
              order_id: order_id,
              payment_option_id: paymentState.paymentOption?.id,
            },
            false,
          );

          if (transactionRes.status !== StatusCode.OK) {
            uiActions.showError("Error generating transaction");
            setPaymentState(prevState => ({ ...prevState, paymentInProgress: false }));
            uiActions.dequeue();
            return;
          }

          // display success notification
          uiActions.showSuccess("Transaction generated successfully");

          // Update order and check if there is a remaining balance
          await updateOrder(transactionRes.data.order_id, true);
        }
      }
    } catch (e) {
      console.log("err:", e);
    }
    setPaymentState(prevState => ({ ...prevState, paymentInProgress: false }));
    uiActions.dequeue();
  };

  function handleCardSectionChange(
    e: StripeCardNumberElementChangeEvent | StripeCardExpiryElementChangeEvent | StripeCardCvcElementChangeEvent,
  ) {
    setManualStripeState(prevState => ({
      ...prevState,
      elementComplete: { ...prevState.elementComplete, [e.elementType]: e.complete },
    }));
  }

  function handleManualProcessing() {
    if (manualProcessing === true) {
      setManualProcessing(false);
    } else {
      setManualProcessing(true);
    }
  }

  const handleStripeError = (message: string, sheetInfo: sheetInfo) => {
    //set sheet
    setStripePaymentState(prevState => ({
      ...prevState,
      onCancel: () => {
        sheetInfo.onCancel();
        setStripePaymentState(prevState => ({ ...prevState, sheetVisible: false }));
      },
      onOk: () => {
        sheetInfo.onOk();
        setStripePaymentState(prevState => ({
          ...prevState,
          sheetVisible: true,
          onCancel: undefined,
          okText: t("secure.facility.table_service.table_service.215"),
          processing: true,
          onOk: async () => {
            setStripePaymentState(prevState => ({
              ...prevState,
              sheetVisible: false,
              onCancel: undefined,
              okText: undefined,
              processing: false,
              onOk: () => {},
              titleText: "",
              success: false,
              message: "",
            }));
            await clearReaderDisplay();
          },
          titleText: t("secure.facility.table_service.table_service.016"),
          message: t("secure.facility.table_service.table_service.017"),
        }));
      },
      cancelText: sheetInfo.cancelText,
      okText: sheetInfo.okText,
      sheetVisible: true,
      titleText: sheetInfo.title,
      message: sheetInfo.message,
      convertedCode: sheetInfo.convertedErrorCode,
      success: false,
      processing: false,
    }));
  };

  const handleStripeSuccess = (message?: string, order_id?: number) => {
    setStripePaymentState(prevState => ({
      ...prevState,
      sheetVisible: true,
      onCancel: undefined,
      okText: t("secure.facility.table_service.table_service.216"),
      //resets state
      onOk: () => {
        setStripePaymentState(prevState => ({
          ...prevState,
          sheetVisible: false,
          onCancel: undefined,
          okText: undefined,
          processing: false,
          onOk: () => {},
          titleText: "",
          message: "",
        }));
      },
      processing: false,
      success: true,
      titleText: t("secure.facility.table_service.table_service.018"),
      message: t("secure.facility.table_service.table_service.019"),
    }));
    void updateOrder(order_id, true);
    setState(prevState => ({ ...prevState, page: "receipt" }));
    uiActions.showSuccess(t("secure.facility.table_service.table_service.020"));
  };

  function closeAccountsModal() {
    setPaymentState(prevState => ({
      ...prevState,
      accountsVisible: false,
      selectedAccount: undefined,
    }));
  }

  function handleAccountsOk(selectedAccount: Record<string, any>) {
    setPaymentState(prevState => ({ ...prevState, accountsVisible: false, selectedAccount }));
  }

  function handleProcessPaymentCheck() {
    if (paymentState?.paymentOption?.payment_method === "gift_card") {
      void openGiftCardSheet();
    } else if (paymentState?.paymentOption?.payment_method === "account" && cartStore?.cart?.customer?.id) {
      setPaymentState(prevState => ({ ...prevState, accountsVisible: true }));
    } else if (paymentState?.paymentOption?.payment_method === "credit_book") {
      updateCreditBook({ isOpen: true });
    } else if (
      paymentState?.paymentOption?.payment_method === "room_charge_rdp" ||
      paymentState?.paymentOption?.payment_method === "maestro"
    ) {
      initiateRoomChargeSheet();
    } else {
      void handleProcessPayment();
    }
  }

  const validateGiftCardCode = async (code: string, reload: number) => {
    const validateRes = await GetGiftCardValidate({ code, reload }, true);
    if (validateRes.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.table_service.table_service.022"));
      return false;
    }
    return true;
  };

  const handleAddSellGiftCards = async (gift_cards: IGiftCardSellState) => {
    let validateRes = true;
    //Validate gift card codes
    for (const gift_card of gift_cards.gift_cards) {
      if (!gift_card.random) {
        validateRes = await validateGiftCardCode(gift_card.code, gift_card.reload);
      }
      if (validateRes === false) {
        return;
      }
    }

    if (validateRes) {
      const moveToPaymentMethods = checkToContinuePayment("gift_card");
      setPaymentState(prevState => ({
        ...prevState,
        sellGiftCardsActive: false,
        paymentModalVisible: moveToPaymentMethods,
      }));
      uiActions.showSuccess(t("secure.facility.table_service.table_service.023"));
    }
  };

  const checkGiftCards = () => {
    if (cartStore?.cart) {
      if (cartStore?.cart?.status !== "complete" && cartStore?.cart?.status !== "void") {
        if (cartStore?.cart?.line_items?.length > 0) {
          const gift_cards: any[] = [];
          const filteredGiftCards: any[] = cartStore?.cart.line_items?.filter(
            (line_item: Record<string, any>) => line_item?.product?.type === "Gift Card",
          );

          const filteredLeagueFees = cartStore?.cart.line_items?.filter(
            (line_item: Record<string, any>) => line_item?.product?.type === "League Registration Fee",
          );

          if (filteredLeagueFees?.length > 0 && !cartStore?.cart?.customer_id) {
            props.uiActions.showError(t("secure.facility.table_service.table_service.231"));
            return;
          }

          //Open gift cards modal if cart contains gift cards
          if (filteredGiftCards?.length > 0 || filteredLeagueFees?.length > 0) {
            if (filteredGiftCards.length > 0) {
              filteredGiftCards.forEach((line_item: Record<string, any>, index: number) => {
                for (let i = 0; i < line_item?.quantity; i++) {
                  gift_cards.push({
                    product_id: line_item.product_id,
                    cart_line_item_id: line_item.id,
                    code: null,
                    pin: null,
                    random: false,
                    balance: line_item.price,
                    reload: 0,
                  });
                }
              });
              setPaymentState(prevState => ({ ...prevState, gift_cards, sellGiftCardsActive: true }));
            }
            //Check for league registration fees
            if (filteredLeagueFees?.length > 0) {
              setPaymentState(prevState => ({
                ...prevState,
                leagueFeesActive: true,
                leagueFeeProducts: filteredLeagueFees,
              }));
            }
          } else {
            //Go directly to payment modal if no gift cards / league fees in cart
            setPaymentState(prevState => ({
              ...prevState,
              gift_cards: null,
              paymentModalVisible: true,
              leagueFeeProducts: null,
              selectedLeagues: null,
            }));
          }
        } else {
          uiActions.showError(t("secure.facility.table_service.table_service.024"), 2000);
        }
      } else {
        uiActions.showError(t("secure.facility.table_service.table_service.025"), 2000);
      }
    } else {
      uiActions.showError(t("secure.facility.table_service.table_service.026"));
    }
  };

  function setSelectedLeagues(leagues: Array<number>) {
    const moveToPaymentMethods = checkToContinuePayment("leagues");
    setPaymentState(prevState => ({
      ...prevState,
      selectedLeagues: leagues,
      leagueFeesActive: false,
      leagueFeeProducts: null,
      paymentModalVisible: moveToPaymentMethods,
    }));
  }

  function cancelGiftCardsAndLeagues() {
    setPaymentState(prevState => ({
      ...prevState,
      sellGiftCardsActive: false,
      gift_cards: null,
      leagueFeesActive: false,
      leagueFeeProducts: null,
      selectedLeagues: null,
    }));
  }

  function checkToContinuePayment(type: "gift_card" | "leagues") {
    switch (type) {
      case "gift_card":
        if (!paymentState.selectedLeagues && paymentState.leagueFeesActive) {
          return false;
        }
        return true;
      case "leagues":
        if (paymentState.sellGiftCardsActive) {
          return false;
        }
        return true;
      default:
        return true;
    }
  }

  async function handleSeatCartClose() {
    if (cartStore?.cart) {
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await cartActions.putCart({ id: cartStore?.cart?.id, status: "closed" }, true);
      if (cartStore.activeTable?.id) {
        await handleTableSeatSelection();
      }
      uiActions.showSuccess(t("secure.facility.table_service.table_service.027"));
    } else {
      uiActions.showError(t("secure.facility.table_service.table_service.028"));
    }
  }

  async function handleSeatCartVoid() {
    if (cartStore?.cart) {
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await cartActions.putVoid({ cart_id: cartStore?.cart?.id, cancel_reason: state.cancelReason }, true);
      if (cartStore.activeTable?.id) {
        await handleTableSeatSelection();
      }
      uiActions.showSuccess(t("secure.facility.table_service.table_service.027"));
    } else {
      uiActions.showError(t("secure.facility.table_service.table_service.028"));
    }
  }

  async function handleTableSeatSelection() {
    const tablesRes = await GetTable({ id: cartStore?.activeTable?.id, carts: true, orders: true }, true);

    if (tablesRes.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.table_service.table_service.029"));
      return;
    }
    // If there are still seats available, load the first seat of the table
    if (tablesRes?.data[0]?.carts?.length > 0) {
      const firstCart = tablesRes?.data[0]?.carts[0];
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await cartActions.loadTableCart({ tableCart: firstCart }, true);
      setState(prevState => ({ ...prevState, activeCart: firstCart, closeSeatActive: false, voidSeatActive: false }));
      if (firstCart?.order) {
        setPaymentState(prevState => ({ ...prevState, order: firstCart?.order }));
      }
    } else {
      void cartActions.loadTableCart({ tableCart: null }, false);
      ReactDOM.unstable_batchedUpdates(() => {
        setState(prevState => ({ ...prevState, activeCart: null, closeSeatActive: false, voidSeatActive: false }));
        setPaymentState(prevState => ({ ...prevState, order: undefined }));
      });
    }

    if (tablesRes?.data?.length > 0) {
      void cartActions.setActiveTable(tablesRes?.data[0]);
    }
  }

  async function handleMoveCarts(moveSeatInfo: IMoveSeatsState) {
    if (moveSeatInfo.selectedTableId && moveSeatInfo.selectedSeatIds?.length > 0) {
      const register = JSON.parse(localStorage.getItem("register"));

      uiActions.enqueue();

      const moveSeatsRes = await PutMoveSeats(
        {
          table_id: moveSeatInfo.selectedTableId,
          cart_ids: moveSeatInfo.selectedSeatIds,
          register_id: register ? register?.id : undefined,
        },
        false,
      );

      if (moveSeatsRes.status !== StatusCode.OK) {
        uiActions.showError(t("secure.facility.table_service.table_service.030"));
        uiActions.dequeue();
        return;
      }

      const tablesRes = await GetTable({ id: cartStore?.activeTable?.id, carts: true, orders: true }, false);
      if (tablesRes.status !== StatusCode.OK) {
        uiActions.showError(t("secure.facility.table_service.table_service.031"));
        return;
      }
      // If there are still seats available, load the first seat of the table or keep it the same if the active table wasn't moved
      if (tablesRes?.data[0]?.carts?.length > 0) {
        const firstCart = tablesRes?.data[0]?.carts[0];
        const updatedActiveCart =
          tablesRes?.data[0]?.carts?.find(cart => cart.id === state.activeCart.id) ?? firstCart;
        void cartActions.loadTableCart({ tableCart: updatedActiveCart }, false);
        setState(prevState => ({ ...prevState, activeCart: updatedActiveCart }));
        if (updatedActiveCart?.order) {
          setPaymentState(prevState => ({ ...prevState, order: updatedActiveCart?.order }));
        }
      } else {
        void cartActions.loadTableCart({ tableCart: null }, false);
        ReactDOM.unstable_batchedUpdates(() => {
          setState(prevState => ({ ...prevState, activeCart: null }));
          setPaymentState(prevState => ({ ...prevState, order: undefined }));
        });
      }

      uiActions.showSuccess(t("secure.facility.table_service.table_service.032"));
      setState(prevState => ({ ...prevState, selectedTableId: undefined, addToTableModalVisible: false }));

      uiActions.dequeue();
      void cartActions.loadActiveTableNew(cartStore?.activeTable?.id, false);
    } else {
      uiActions.showError(t("secure.facility.table_service.table_service.033"));
    }
  }

  function handleDiscountSelectChange(value: any, id: string, discount: IDiscount) {
    setItemActionsState(prevState => ({ ...prevState, [id]: value, discountSelected: discount }));
  }

  async function handleClearCart() {
    const clearRes = await PutClearCart(
      {
        cart_id: cartStore?.cart?.id,
      },
      true,
    );

    if (terminalStore?.reader) {
      void clearReaderDisplay();
    }

    if (clearRes.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.table_service.table_service.034"));
      return;
    }

    void cartActions.loadTableCart({ tableCart: clearRes?.data }, false);
    void setUpdatedCartOnTable(clearRes?.data);

    uiActions.showSuccess("Successfully cleared seat");
  }

  function handleOpenQuantitySheet() {
    if (itemActionsState.selectedLineItem) {
      setItemActionsState(prevState => ({
        ...prevState,
        quantitySheetVisible: true,
        quantitySelected: prevState.selectedLineItem.quantity,
      }));
    } else {
      uiActions.showError(t("secure.facility.table_service.table_service.036"), 2000);
    }
  }

  function handleOpenRemoveLineItemSheet() {
    if (itemActionsState.selectedLineItem) {
      setItemActionsState(prevState => ({
        ...prevState,
        removeItemSheetVisible: true,
      }));
    } else {
      uiActions.showError(t("secure.facility.table_service.table_service.037"), 2000);
    }
  }

  function handleOpenPriceSheet() {
    if (itemActionsState.selectedLineItem) {
      setItemActionsState(prevState => ({
        ...prevState,
        priceSheetVisible: true,
        price: prevState.selectedLineItem.price.toString(),
      }));
    } else {
      uiActions.showError(t("secure.facility.table_service.table_service.038"), 2000);
    }
  }

  function handleOpenDiscountSheet() {
    if (itemActionsState.selectedLineItem) {
      setItemActionsState(prevState => ({
        ...prevState,
        discountIdSelected: -1,
        discountSheetVisible: true,
      }));
    } else {
      uiActions.showError(t("secure.facility.table_service.table_service.039"), 2000);
    }
  }

  function handleOpenModifiersSheetAddItem() {
    if (itemActionsState.addedModifierGroups) {
      const select = itemActionsState.selectedModifierGroupVariants;

      //Preselect the default modifier groups
      itemActionsState.addedModifierGroups.forEach((modifier: IModifierGroup) => {
        select[modifier.id.toString()] = [];
        modifier.variants.forEach((variant: Record<string, any>) => {
          if (variant.modifier_group.default) {
            select[modifier.id.toString()].push(variant);
          }
        });
      });
      setItemActionsState(prevState => ({
        ...prevState,
        selectedModifierGroupVariants: select,
        allModifierGroups: itemActionsState.addedModifierGroups,
        modifiersSheetVisible: true,
        deleteModifierOnCancel: true,
      }));
    } else {
      uiActions.showError(t("secure.facility.table_service.table_service.040"), 2000);
    }
  }

  async function handleOpenModifiersSheet() {
    if (itemActionsState.selectedLineItem) {
      if (itemActionsState.selectedLineItem?.product?.has_modifiers) {
        const modifierGroupResponse = await GetModifierGroup(
          { product_id: itemActionsState.selectedLineItem?.product_id, extended: true },
          true,
        );

        if (modifierGroupResponse.status !== StatusCode.OK) {
          uiActions.showError(modifierGroupResponse.message);
          return;
        }

        const select = itemActionsState.selectedModifierGroupVariants;

        //Preselect the default modifier groups
        modifierGroupResponse.data?.forEach((modifier: IModifierGroup) => {
          select[modifier.id.toString()] = [];
          modifier.variants.forEach((variant: Record<string, any>) => {
            if (variant.modifier_group.default) {
              select[modifier.id.toString()].push(variant);
            }
          });
        });

        setItemActionsState(prevState => ({
          ...prevState,
          selectedModifierGroupVariants: select,
          allModifierGroups: modifierGroupResponse.data,
          modifiersSheetVisible: true,
        }));
      } else {
        uiActions.showError(t("secure.facility.table_service.table_service.041"), 2000);
      }
    } else {
      uiActions.showError(t("secure.facility.table_service.table_service.217"), 2000);
    }
  }

  function addModifierVariant(modifierGroup: IModifierGroup, variant: IVariant, selected: boolean) {
    const select = itemActionsState.selectedModifierGroupVariants;
    if (modifierGroup.multi_select) {
      if (selected && select[modifierGroup.id.toString()].length >= 1) {
        if (modifierGroup.required && select[modifierGroup.id.toString()].length === 1) {
          return;
        }
        const selectedGroup = select[modifierGroup.id.toString()];
        select[modifierGroup.id.toString()] = selectedGroup.filter(filtered => filtered.id !== variant.id);
      } else {
        select[modifierGroup.id.toString()].push(variant);
      }
    } else {
      select[modifierGroup.id.toString()] = [variant];
    }

    setState(prevState => ({ ...prevState, selectedModifierGroupVariants: select }));
  }

  async function handleCloseModifierGroups(closeButtonSelected: boolean) {
    let lineItemRes;
    if (itemActionsState.deleteModifierOnCancel && closeButtonSelected) {
      lineItemRes = await DeleteLineItemToCart({ id: itemActionsState.selectedLineItem?.id }, true);
      if (lineItemRes?.status !== StatusCode.OK) {
        uiActions.showError("Error deleting product from cart");
        void cartActions.loadActiveTableNew(cartStore.activeTable?.id, true);
        return;
      }
      void cartActions.loadTableCart({ tableCart: lineItemRes?.data }, false);
      void setUpdatedCartOnTable(lineItemRes?.data);
    }

    setItemActionsState(prevState => ({
      ...prevState,
      modifiersSheetVisible: false,
      allModifierGroups: [],
      selectedModifierGroupVariants: {},
      deleteModifierOnCancel: false,
      addedModifierItem: null,
    }));
  }

  async function onModifiersOk() {
    const allSelectedVariants = [];

    for (const key in itemActionsState.selectedModifierGroupVariants) {
      allSelectedVariants.push(...itemActionsState.selectedModifierGroupVariants[key]);
    }

    uiActions.enqueue();

    let modifierRes: any;

    for (const product of allSelectedVariants) {
      modifierRes = await PostLineItemToCart(
        {
          cart_id: cartStore.cart?.id,
          variant_id: product.id,
          parent_id: itemActionsState.selectedLineItem?.id,
          quantity: 1,
          kitchen_meal_id: state?.selectedCourseId,
        },
        false,
      );

      if (modifierRes && modifierRes?.status === StatusCode.BAD_REQUEST) {
        uiActions.showError(t("secure.facility.table_service.table_service.042"));
        uiActions.dequeue();
        return;
      }
    }
    uiActions.showSuccess(t("secure.facility.table_service.table_service.043"));
    uiActions.dequeue();
    if (modifierRes?.data) {
      void cartActions.loadTableCart({ tableCart: modifierRes?.data }, false);
      void setUpdatedCartOnTable(modifierRes?.data);
    }
    setApplyDiscount(true);
    void handleCloseModifierGroups(false);
  }

  function disableSelectingModifiers(lineItem: Record<string, any>) {
    if (lineItem && cartStore?.cart?.line_items) {
      const requiredModifierGroups = itemActionsState.allModifierGroups.filter(
        modifierGroup => modifierGroup.required,
      );

      const lineItemModifiers = cartStore?.cart?.line_items.filter((cartLineItem: ICartLineItem) => {
        return cartLineItem.id !== lineItem.id && lineItem.id === cartLineItem.parent_id;
      });

      const modalRequiredModifierGroups: IModifierGroup[] = requiredModifierGroups.filter(modifierGroup => {
        return !modifierGroup.variants?.some(variant =>
          lineItemModifiers.some((lineItem: ICartLineItem) => lineItem.variant_id === variant.id),
        );
      });

      const allRequiredModifiersSelected = modalRequiredModifierGroups.every(modifierGroup => {
        return itemActionsState.selectedModifierGroupVariants[modifierGroup.id.toString()]?.length > 0;
      });

      return !allRequiredModifiersSelected;
    } else {
      return true;
    }
  }

  function handleTipInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setState(prevState => ({ ...prevState, [id]: value, tipPercent: null }));
  }

  function onTipPercentChange(tipPercent: number) {
    const calculateTip = (cartStore?.cart?.total_price - cartStore?.cart?.total_tip) * tipPercent;
    let valueOfTip = tipPercent;
    if (register_group?.tipping_type === "percent" || register_group?.tipping_type === "none") {
      valueOfTip = Math.floor(calculateTip * 100) / 100;
    }
    setState(prevState => ({ ...prevState, tipAmount: valueOfTip.toString(), tipPercent }));
  }

  function handleOpenTipSheet() {
    if (cartStore?.cart?.status !== "complete") {
      setState(prevState => ({
        ...prevState,
        tipSheetVisible: true,
        tipAmount: cartStore?.cart?.total_tip.toString(),
        tipPercent: null,
      }));
    } else {
      uiActions.showError(t("secure.facility.table_service.table_service.044"), 2000);
    }
  }

  function handleOpenSplitItemSheet() {
    if (cartStore?.cart?.status !== "complete") {
      setItemActionsState(prevState => ({
        ...prevState,
        splitLineItemVisible: true,
        selectedSplitLineItemIds: [],
        selectedSplitCartIds: [],
      }));
    } else {
      uiActions.showError(t("secure.facility.table_service.table_service.045"), 2000);
    }
  }

  function handleOpenCartDetailsSheet() {
    if (cartStore?.cart?.status !== "complete") {
      setItemActionsState(prevState => ({
        ...prevState,
        cartDetailsSheetVisible: true,
        prevSelectedCustomer: cartStore?.cart?.customer,
        prevCartDescription: cartStore?.cart?.description ?? "",
      }));
    } else {
      uiActions.showError(t("secure.facility.table_service.table_service.047"), 2000);
    }
  }

  function handleOpenNoteSheet() {
    if (itemActionsState.selectedLineItem) {
      if (!itemActionsState.selectedLineItem.parent_id) {
        setItemActionsState(prevState => ({
          ...prevState,
          noteSheetVisible: true,
          note: prevState.selectedLineItem?.note ? prevState.selectedLineItem?.note : "",
        }));
      } else {
        uiActions.showError(t("secure.facility.table_service.table_service.048"), 2000);
      }
    } else {
      uiActions.showError(t("secure.facility.table_service.table_service.049"), 2000);
    }
  }

  async function handleUpdateLineItemQuantity(changeQuantityInfo: IItemActionsState) {
    const lineItemRes = await PutLineItemToCart(
      { id: itemActionsState.selectedLineItem?.id, quantity: changeQuantityInfo.quantitySelected },
      true,
    );

    if (lineItemRes && lineItemRes?.status === StatusCode.BAD_REQUEST) {
      uiActions.showError(t("secure.facility.table_service.table_service.218"));
      return;
    }

    void cartActions.loadTableCart({ tableCart: lineItemRes?.data }, false);
    void setUpdatedCartOnTable(lineItemRes?.data);

    setItemActionsState(prevState => ({
      ...prevState,
      quantitySelected: null,
      quantitySheetVisible: false,
    }));

    uiActions.showSuccess(t("secure.facility.table_service.table_service.219"));
  }

  async function handleRemoveLineItem() {
    const lineItemRes = await DeleteLineItemToCart({ id: itemActionsState.selectedLineItem?.id }, true);

    if (lineItemRes && lineItemRes?.status === StatusCode.BAD_REQUEST) {
      uiActions.showError(t("secure.facility.table_service.table_service.054"));
      return;
    }

    void cartActions.loadTableCart({ tableCart: lineItemRes?.data }, false);
    void setUpdatedCartOnTable(lineItemRes?.data);

    setItemActionsState(prevState => ({
      ...prevState,
      removeItemSheetVisible: false,
    }));
    uiActions.showSuccess(t("secure.facility.table_service.table_service.055"));
  }

  function handleLineItemInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setItemActionsState(prevState => ({ ...prevState, [id]: value }));
  }

  async function handleApplyDiscount(customAmount?: string, typeFixed?: boolean) {
    const type = typeFixed !== undefined ? (typeFixed ? "fixed_amount" : "percent") : undefined;
    const modifiers = countModifiers();

    let discountRes = await PostDiscountLine(
      {
        cart_id: itemActionsState.selectedLineItem?.cart_id,
        discount_id: itemActionsState.discountIdSelected,
        cart_line_item_id: itemActionsState.selectedLineItem?.id,
        value: customAmount ? Number(customAmount) : undefined,
        value_type: type,
      },
      true,
    );

    if (discountRes.status !== StatusCode.OK) {
      uiActions.showError("Error applying discount");
      return;
    }

    if (modifiers.length > 0 && (type == "percent" || itemActionsState?.discountSelected?.value_type === "percent")) {
      for (const modifier of modifiers) {
        if (modifier.price > 0) {
          discountRes = await PostDiscountLine(
            {
              discount_id: itemActionsState.discountIdSelected,
              cart_id: itemActionsState.selectedLineItem?.cart_id,
              cart_line_item_id: modifier.id,
              value: customAmount ? Number(customAmount) : undefined,
              value_type: type,
            },
            true,
          );

          if (discountRes.status === StatusCode.BAD_REQUEST) {
            uiActions.showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.020"));
          }
        }
      }
    }

    void cartActions.loadTableCart({ tableCart: discountRes?.data }, false);
    void setUpdatedCartOnTable(discountRes?.data);

    uiActions.showSuccess(t("secure.facility.table_service.table_service.056"));
    setItemActionsState(prevState => ({
      ...prevState,
      discountSheetVisible: false,
      discountIdSelected: -1,
      discountSelected: null,
    }));
  }

  async function handleUpdatePrice(priceChangeInfo: IItemActionsState) {
    if (isNumeric(itemActionsState.price)) {
      const lineItemRes = await PutLineItemToCart(
        { id: itemActionsState.selectedLineItem?.id, price: Number(priceChangeInfo.price) },
        true,
      );

      if (lineItemRes && lineItemRes?.status === StatusCode.BAD_REQUEST) {
        uiActions.showError(t("secure.facility.table_service.table_service.057"));
        return;
      }

      void cartActions.loadTableCart({ tableCart: lineItemRes?.data }, false);
      void setUpdatedCartOnTable(lineItemRes?.data);

      setItemActionsState(prevState => ({
        ...prevState,
        price: "",
        priceSheetVisible: false,
      }));
      uiActions.showSuccess(t("secure.facility.table_service.table_service.058"));
    } else {
      uiActions.showError(t("secure.facility.table_service.table_service.059"));
      return;
    }
  }

  function handleSplitLineItemCheckboxChange(lineItemId: number) {
    const updatedLineitemIds = [...itemActionsState.selectedSplitLineItemIds];
    const foundIndex = updatedLineitemIds?.indexOf(lineItemId);
    if (foundIndex === -1) {
      updatedLineitemIds.push(lineItemId);
    } else {
      updatedLineitemIds.splice(foundIndex, 1);
    }
    setItemActionsState(prevState => ({ ...prevState, selectedSplitLineItemIds: updatedLineitemIds }));
  }

  function handleSplitCartCheckboxChange(cartId: number) {
    const carts = [...itemActionsState.selectedSplitCartIds];
    const foundIndex = carts?.indexOf(cartId);
    if (foundIndex === -1) {
      carts.push(cartId);
    } else {
      carts.splice(foundIndex, 1);
    }
    setItemActionsState(prevState => ({ ...prevState, selectedSplitCartIds: carts }));
  }

  async function handleLineItemSplit() {
    const cart_ids = [...itemActionsState.selectedSplitCartIds];
    //Add current cart's id to the front of the array
    cart_ids.unshift(cartStore?.cart?.id);

    console.log(cart_ids);

    if (cart_ids.includes(0)) {
      console.log("Create new seat");

      const postRes = await PostCart({ type: "tab", table_id: cartStore?.activeTable?.id }, true);
      if (postRes.status !== StatusCode.OK) {
        uiActions.showError("Error occurred when creating a new seat");
        return;
      }

      console.log(postRes);

      const updatedTable = { ...cartStore?.activeTable };
      updatedTable.carts?.push(postRes?.data);

      console.log(updatedTable);

      cart_ids.splice(1, 1);

      cart_ids.push(postRes?.data.id);

      // void cartActions.loadTableCart({ tableCart: postRes?.data }, false);
      // void cartActions.setActiveTable(updatedTable);

      // setState((prevState) => ({ ...prevState, addSeat: true }));
    }

    console.log(cart_ids);

    uiActions.enqueue();
    const splitRes = await PutLineItemSplit(
      {
        cart_ids,
        line_item_ids: itemActionsState.selectedSplitLineItemIds,
      },
      false,
    );
    if (splitRes.status !== StatusCode.OK) {
      uiActions.showError("Error splitting items");
      uiActions.dequeue();
      return;
    }
    uiActions.showSuccess("Successfully split items");
    setItemActionsState(prevState => ({
      ...prevState,
      splitLineItemVisible: false,
      selectedSplitCartIds: [],
      selectedSplitLineItemIds: [],
    }));

    // Refresh current cart and active table
    void cartActions.loadTableCart({ token: cartStore?.cart?.token }, false);
    uiActions.dequeue();
    void cartActions.loadActiveTableNew(cartStore.activeTable?.id, false);
  }

  function handleSelectLineItem(lineItem: ICartLineItem, cart: ICart) {
    console.log("handleSelecteLineItem", lineItem);
    console.log("cart", cart);

    setItemActionsState(prevState => ({ ...prevState, selectedLineItem: lineItem }));
    if (cartStore?.cart?.id !== cart?.id) {
      void cartActions.loadTableCart({ tableCart: cart }, false);
    }
  }

  function setTableModifierGroups() {
    const tableModifierGroups: any = {};
    cartStore?.activeTable?.carts?.forEach(cart => {
      const groups = cart?.line_items?.reduce(
        (prevModifierGroups: { [key: string]: Array<ICartLineItem> }, lineItem: any) => {
          if (lineItem.parent_id !== null) {
            return {
              ...prevModifierGroups,
              [lineItem.parent_id]: [...(prevModifierGroups[lineItem.parent_id] || []), lineItem],
            };
          } else {
            return prevModifierGroups;
          }
        },
        {},
      );
      tableModifierGroups[cart.token] = groups;
    });
    setModifierGroups(tableModifierGroups);
  }

  async function handleUpdateCartDetails(cartDetails: any) {
    if (!cartStore?.cart) {
      uiActions.showError(t("secure.facility.table_service.table_service.061"));
      return;
    } else {
      let customerId;
      if (cartDetails?.selectedCustomer == null) {
        customerId = null;
      } else if (cartDetails?.selectedCustomer?.id === itemActionsState?.prevSelectedCustomer?.id) {
        customerId = undefined;
      } else {
        customerId = cartDetails?.selectedCustomer?.id;
      }

      const cartRes = await PutCart(
        {
          id: cartStore?.cart?.id,
          customer_id: customerId,
          description:
            cartDetails?.cartDescription === itemActionsState?.prevCartDescription
              ? undefined
              : cartDetails?.cartDescription,
        },
        true,
      );

      if (cartRes.status !== StatusCode.OK) {
        uiActions.showError("Error updating seat details");
        return;
      }

      void cartActions.loadTableCart({ tableCart: cartRes?.data }, false);
      void setUpdatedCartOnTable(cartRes?.data);

      uiActions.showSuccess("Successfully updated seat details");
      setItemActionsState(prevState => ({
        ...prevState,
        prevSelectedCustomer: null,
        cartDetailsSheetVisible: false,
        prevCartDescription: "",
      }));
    }
  }

  async function handleAddTip() {
    if (!cartStore?.cart || cartStore?.cart?.status === "complete") {
      uiActions.showError(t("secure.facility.table_service.table_service.062"));
      return;
    } else {
      const cartRes = await PutCart(
        {
          id: cartStore?.cart?.id,
          total_tip: Number(state.tipAmount),
        },
        true,
      );

      if (cartRes.status !== StatusCode.OK) {
        uiActions.showError("Error adding tip");
        return;
      }

      void cartActions.loadTableCart({ tableCart: cartRes?.data }, false);
      void setUpdatedCartOnTable(cartRes?.data);

      setState(prevState => ({ ...prevState, tipAmount: "", tipPercent: null, tipSheetVisible: false }));
      uiActions.showSuccess("Successfully added tip");
    }
  }

  async function handleSeatMerge(mergeSeatInfo: IMergeSeatsState) {
    const mergeRes = await PostMerge(
      { cart_ids: mergeSeatInfo.selectedMergeSeatIds, table_id: cartStore?.activeTable?.id },
      true,
    );
    if (mergeRes.status !== StatusCode.OK) {
      uiActions.showError("Error merging seats");
      return;
    }

    void cartActions.loadTableCart({ tableCart: mergeRes?.data }, false);
    void cartActions.loadActiveTableNew(cartStore?.activeTable?.id, true);

    uiActions.showSuccess("Seats merged successfully");
    setState(prevState => ({
      ...prevState,
      mergeSeatsSheetVisible: false,
      activeCart: mergeRes?.data,
    }));
  }

  function initiateRoomChargeSheet() {
    setState(prevState => ({
      ...prevState,
      roomChargeSheetVisible: true,
      roomOccupantSearchQuery: "",
      roomOccupantsSearchResults: [],
      selectedOccupant: null,
    }));
  }

  function closeRoomChargeSheet() {
    setState(prevState => ({
      ...prevState,
      roomChargeSheetVisible: false,
      roomOccupantSearchQuery: "",
      roomOccupantsSearchResults: [],
      selectedOccupant: null,
    }));
  }

  function handleOccupantSearchQueryChange(event: React.ChangeEvent<HTMLInputElement>) {
    setState(prevState => ({ ...prevState, roomOccupantSearchQuery: event.target.value }));
  }

  async function handleAddNote(noteInfo: IItemActionsState) {
    const lineItemRes = await PutLineItemToCart(
      { id: itemActionsState.selectedLineItem?.id, note: noteInfo?.note },
      true,
    );

    if (lineItemRes && lineItemRes?.status === StatusCode.BAD_REQUEST) {
      uiActions.showError(t("secure.facility.table_service.table_service.063"));
      return;
    }

    void cartActions.loadTableCart({ tableCart: lineItemRes?.data }, false);
    void setUpdatedCartOnTable(lineItemRes?.data);

    setItemActionsState(prevState => ({
      ...prevState,
      note: "",
      noteSheetVisible: false,
    }));
    uiActions.showSuccess(t("secure.facility.table_service.table_service.064"));
  }

  function handleQuickOpenNoteSheet(lineItem: ICartLineItem, cart: ICart) {
    if (cartStore?.cart?.id !== cart?.id) {
      void cartActions.loadTableCart({ tableCart: cart }, false);
    }
    setItemActionsState(prevState => ({
      ...prevState,
      selectedLineItem: lineItem,
      noteSheetVisible: true,
      note: lineItem?.note ? lineItem?.note : "",
    }));
  }

  // function displayKitchenChitBar(showKitchenChitBar: boolean) {
  //   const topBar = document.getElementById("top-bar");
  //   const kitchenChitBar = document.getElementById("kitchen-chit-bar");
  //   const tableServiceContainer = document.getElementById("main-table-service-container");
  //   if (topBar && kitchenChitBar && tableServiceContainer) {
  //     if (!showKitchenChitBar) {
  //       kitchenChitBar.style.display = "none";
  //       tableServiceContainer.style.height = `calc(100vh - ${topBar.offsetHeight}px)`;
  //     } else {
  //       kitchenChitBar.style.display = "block";
  //       tableServiceContainer.style.height = `calc(100vh - ${topBar.offsetHeight + kitchenChitBar.offsetHeight}px)`;
  //     }
  //   }
  // }

  function handlePrintKitchen() {
    setSendOrderState(prevState => ({
      ...prevState,
      printOrderVisible: true,
      activeTable: cartStore?.activeTable,
    }));
  }

  function handleShowLineItemActionModal(lineItem: ICartLineItem, cart: ICart) {
    if (cart?.status !== "complete") {
      handleSelectLineItem(lineItem, cart);

      setState(prevState => ({
        ...prevState,
        showLineItemActionsModal: true,
      }));
    }
  }

  function handleShowActionsModal() {
    setState(prevState => ({
      ...prevState,
      showActionsModal: true,
    }));
  }

  function handleOpenMoveLineItemsModal() {
    setState(prevState => ({
      ...prevState,
      moveLineItemsModalVisible: true,
    }));
  }

  function handleCancelMoveLineItemsModal() {
    setState(prevState => ({
      ...prevState,
      moveLineItemsModalVisible: false,
    }));
  }

  function handleRefreshAfterSaveMoveLineItems() {
    uiActions.enqueue();
    void cartActions.loadTableCart({ token: cartStore?.cart?.token }, false);
    void cartActions.loadActiveTableNew(cartStore.activeTable?.id, false);
    handleCancelMoveLineItemsModal();
    uiActions.dequeue();
  }

  function checkForDiscounts(parent_id: number) {
    const cartDiscounts = cartStore.cart.discount_lines;
    const discounts: Array<Record<string, any>> = [];

    if (cartDiscounts.length > 0) {
      cartDiscounts.forEach((discount: Record<string, any>) => {
        if (discount.cart_line_item_id === parent_id && discount?.value_type === "percent") {
          discounts.push(discount);
        }
      });
    }
    return discounts;
  }

  async function filterModifiers() {
    setApplyDiscount(false);
    const cartLineItems = cartStore.cart?.line_items;
    console.log("Cart Item", cartStore.cart);
    const parentLineItemID = itemActionsState.selectedLineItem.id;
    const discounts = checkForDiscounts(parentLineItemID);

    let cart: any;
    if (cartLineItems.length > 0) {
      for (const line_item of cartLineItems) {
        if (
          line_item.parent_id == parentLineItemID &&
          !cartStore?.cart.discount_lines?.some(
            (discount_line: Record<string, any>) => discount_line?.cart_line_item_id === line_item?.id,
          )
        ) {
          console.log("Discounts Length", discounts.length);
          for (let i = 0; i < discounts.length; i++) {
            const discount = discounts[i];
            if (discount.value_type == "percent" && line_item.price > 0) {
              const res = await PostDiscountLine(
                {
                  discount_id: discount.discount_id,
                  cart_id: line_item.cart_id,
                  cart_line_item_id: line_item.id,
                  value: discount.value ? Number(discount.value) : undefined,
                  value_type: discount.value_type,
                },
                true,
              );

              cart = res;
              if (res.status !== StatusCode.OK) {
                uiActions.showError("Error adding discount line");
              }
            }
          }
        }
      }
    }
    if (cart?.data) {
      void cartActions.loadTableCart({ tableCart: cart?.data }, false);
      void setUpdatedCartOnTable(cart?.data);
    }
  }

  function countModifiers() {
    const cartLineItems = cartStore.cart?.line_items;
    const parentLineItemID = itemActionsState.selectedLineItem.id;
    const modifiers: Array<ICartLineItem> = [];

    if (cartLineItems.length > 0) {
      cartLineItems.forEach((line_item, index) => {
        if (line_item.parent_id == parentLineItemID) {
          modifiers.push(line_item);
        }
      });
    }

    return modifiers;
  }

  function handleCoursesSheet() {
    if (itemActionsState.selectedLineItem?.parent_id) {
      uiActions.showError("Cannot change course for modifiers");
      return;
    }
    setState(prevState => ({ ...prevState, coursesSheet: true }));
  }

  function handleCourseSelection(courseId: number) {
    setState(prevState => ({ ...prevState, selectedCoursetoChange: courseId }));
  }

  function setSendChitIds() {
    let lineItemIds: Array<number> = [];
    if (cartStore?.activeTable?.carts) {
      //Defaults selected line items in Send Chit Modal
      const carts = [...cartStore?.activeTable?.carts];
      carts?.forEach(cart => {
        if (cart?.status !== "complete" && (!cart?.order || cart?.order?.financial_status === "unpaid")) {
          const lineItemsToAdd = cart?.line_items
            ?.filter(
              filteredLineItem =>
                !filteredLineItem.parent_id &&
                filteredLineItem?.kitchen_status !== "sent" &&
                (filteredLineItem?.product?.meta?.kitchen_location_id ||
                  filteredLineItem?.product?.meta?.kitchen_location_id_2 ||
                  filteredLineItem?.product?.meta?.kitchen_location_id_3),
            )
            ?.map(lineItem => lineItem?.id);
          lineItemIds = [...lineItemIds, ...lineItemsToAdd];
        }
      });
    }
    return lineItemIds;
  }

  async function handleCourseChange(courseChangeInfo: ICourseChangeState) {
    //Selected course of id: -1 will set the kitchen_meal_id as null (No Course)
    const courseChangeRes = await PutLineItemToCart(
      {
        id: itemActionsState?.selectedLineItem?.id,
        kitchen_meal_id:
          courseChangeInfo.selectedCourseToChange === -1 ? null : courseChangeInfo.selectedCourseToChange,
      },
      true,
    );
    if (courseChangeRes?.status !== StatusCode.OK) {
      uiActions.showError("Error changing course");
      return;
    }

    void cartActions.loadTableCart({ tableCart: courseChangeRes?.data }, false);
    void setUpdatedCartOnTable(courseChangeRes?.data);

    uiActions.showSuccess("Successfully changed course");
    setState(prevState => ({ ...prevState, selectedCoursetoChange: null, coursesSheet: false }));
  }
  // const lineItemActions = (permissions: IUserPermissions, selectedLineItem: ICartLineItem) => {
  //   return [
  //     {
  //       icon: "plus",
  //       onClick: handleOpenQuantitySheet,
  //       label: t("secure.facility.table_service.table_service.220"),
  //       disabled: selectedLineItem?.kitchen_status === "sent",
  //     },
  //     {
  //       icon: "xmark",
  //       onClick: handleOpenRemoveLineItemSheet,
  //       label: t("secure.facility.table_service.table_service.065"),
  //       disabled: !(
  //         permissions?.orders_remove_line_item ||
  //         (permissions?.orders_remove_line_item_after_send && !itemActionsState.selectedLineItem?.kitchen_chit_id)
  //       ),
  //     },
  //     {
  //       icon: "dollar-sign",
  //       onClick: handleOpenPriceSheet,
  //       label: t("secure.facility.table_service.table_service.066"),
  //       disabled: !permissions?.orders_edit_line_item_price,
  //     },
  //     {
  //       icon: "percent",
  //       onClick: handleOpenDiscountSheet,
  //       label: t("secure.facility.table_service.table_service.067"),
  //       disabled: !permissions?.orders_apply_discount,
  //     },
  //     {
  //       icon: "salt-shaker",
  //       onClick: handleOpenModifiersSheet,
  //       label: t("secure.facility.table_service.table_service.068"),
  //       disabled: !!!selectedLineItem?.product?.has_modifiers,
  //     },
  //     {
  //       icon: "split",
  //       onClick: handleOpenSplitItemSheet,
  //       label: t("secure.facility.table_service.table_service.069"),
  //       disabled: false,
  //     },
  //     {
  //       icon: "note",
  //       onClick: handleOpenNoteSheet,
  //       label: t("secure.facility.table_service.table_service.071"),
  //       disabled: false,
  //     },
  //   ] as IAction[]
  // };

  const lineItemActions: IAction[] = [
    {
      icon: "plus",
      onClick: handleOpenQuantitySheet,
      label: t("secure.facility.table_service.table_service.220"),
      disabled: itemActionsState.selectedLineItem?.kitchen_status === "sent",
    },
    {
      icon: "xmark",
      onClick: handleOpenRemoveLineItemSheet,
      label: t("secure.facility.table_service.table_service.065"),
      disabled: !(
        permissions?.orders_remove_line_item ||
        (permissions?.orders_remove_line_item_after_send && !itemActionsState.selectedLineItem?.kitchen_chit_id)
      ),
    },
    {
      icon: "dollar-sign",
      onClick: handleOpenPriceSheet,
      label: t("secure.facility.table_service.table_service.066"),
      disabled: !permissions?.orders_edit_line_item_price,
    },
    {
      icon: "percent",
      onClick: handleOpenDiscountSheet,
      label: t("secure.facility.table_service.table_service.067"),
      disabled: !permissions?.orders_apply_discount,
    },
    {
      icon: "salt-shaker",
      onClick: handleOpenModifiersSheet,
      label: t("secure.facility.table_service.table_service.068"),
      disabled: !itemActionsState.selectedLineItem?.product?.has_modifiers,
    },
    {
      icon: "split",
      onClick: handleOpenSplitItemSheet,
      label: t("secure.facility.table_service.table_service.069"),
      disabled: false,
    },
    {
      icon: "note",
      onClick: handleOpenNoteSheet,
      label: t("secure.facility.table_service.table_service.071"),
      disabled: false,
    },
    ...(facilityStore?.kitchenCourses?.length > 0
      ? [
          {
            icon: "utensils" as IconName,
            onClick: handleCoursesSheet,
            label: "Change Course",
            disabled: false,
          },
        ]
      : []),
  ];

  const tableActions: IAction[] = [
    {
      icon: "eye",
      onClick: handlePrintKitchen,
      label: t("secure.facility.table_service.table_service.073"),
      disabled: false,
    },
    {
      icon: "fire",
      onClick: sendFireChit,
      label: "Fire Chit",
      disabled: false,
    },

    ...(cartStore?.activeTable?.carts?.length > 0
      ? [
          {
            icon: "chair" as IconName,
            onClick: () => setState(prevState => ({ ...prevState, addToTableModalVisible: true })),
            label: t("secure.facility.table_service.table_service.074"),
            disabled: false,
          },
        ]
      : []),
    {
      icon: "arrows-left-right",
      onClick: () => setTransferTableState(prevState => ({ ...prevState, modalVisibility: true })),
      label: "Transfer Table",
      disabled: false,
    },
    ...(cartStore?.cart?.line_items.length > 0
      ? [
          {
            icon: "ban" as IconName,
            onClick: () => setState(prevState => ({ ...prevState, voidSeatActive: true })),
            label: "Void Seat",
            disabled: !permissions.orders_void,
          },
        ]
      : [
          {
            icon: "ban" as IconName,
            onClick: () => setState(prevState => ({ ...prevState, closeSeatActive: true })),
            label: t("secure.facility.table_service.table_service.077"),
            disabled: paymentState?.order?.financial_status === "partially_paid" ? true : false,
          },
        ]),
    {
      icon: "xmark",
      onClick: handleOpenCloseTable,
      label: t("secure.facility.table_service.table_service.075"),
      disabled: false,
    },
  ];

  const seatActions: IAction[] = [
    {
      icon: "turn-down-right" as IconName,
      onClick: handleOpenMoveLineItemsModal,
      label: "Move Line Items", // TODO: Translation
      disabled: (cartStore.activeTable?.carts?.filter(isSeatAbleToMoveLineItems) ?? []).length < 2,
    },
    ...(cartStore?.cart?.status !== "complete" && cartStore?.cart?.status !== "void"
      ? [
          {
            icon: "arrow-rotate-left" as IconName,
            onClick: () => setState(prevState => ({ ...prevState, clearSeatActive: true })),
            label: t("secure.facility.table_service.table_service.078"),
            disabled: paymentState?.order?.financial_status === "partially_paid" ? true : false,
          },
        ]
      : []),

    ...(cartStore?.cart?.status !== "complete" && cartStore?.cart?.status !== "void"
      ? [
          {
            icon: "arrows-rotate" as IconName,
            onClick: () =>
              setState(prevState => ({
                ...prevState,
                mergeSeatsSheetVisible: true,
              })),
            label: t("secure.facility.table_service.table_service.079"),
            disabled: paymentState?.order?.financial_status === "partially_paid" ? true : false,
          },
        ]
      : []),

    {
      icon: "file-lines",
      onClick: handleOpenCartDetailsSheet,
      label: t("secure.facility.table_service.table_service.080"),
      disabled: false,
    },
    {
      icon: "copy",
      onClick: () => setKitchenChitModalOpen(true),
      label: "All Chits",
      disabled: false,
    },
  ];

  async function handleTransferTable(transferTableInfo: ITransferTableSheetState) {
    if (transferTableInfo?.selectedUserId !== cartStore?.activeTable?.user_id) {
      const transferRes = await PutTable(
        { id: cartStore?.activeTable?.id, user_id: transferTableInfo?.selectedUserId },
        true,
      );
      if (transferRes?.status !== StatusCode.OK) {
        uiActions.showError(t("secure.facility.table_service.table_service.226"));
        return;
      }

      const updatedTable = transferRes?.data as any;
      void cartActions.setActiveTable({ ...updatedTable, carts: cartStore?.activeTable?.carts });
      uiActions.showSuccess(t("secure.facility.table_service.table_service.227"));
      setTransferTableState(prevState => ({
        ...prevState,
        modalVisibility: false,
      }));

      //Send user to table selection if they do not have permission to access other users' tables
      if (authStore?.user?.id !== updatedTable?.user_id && !permissions?.table_service_table_override) {
        void backToTableSelection();
      }
    }
  }

  const cancel_reasons = [
    {
      id: 1,
      title: "Server Error",
      value: 1,
    },
    {
      id: 2,
      title: "Not Satisfied",
      value: 2,
    },
  ];

  function handleCancelReasonChange(cancel_reason: string) {
    setState(prevState => ({ ...prevState, cancelReason: cancel_reason }));
  }

  function handleOpenCloseTable() {
    let seatsOpen = false;
    const seats = cartStore?.activeTable?.carts;

    if (seats.length > 0) {
      for (let i = 0; i < seats.length; i++) {
        if (seats[i].status === "open") {
          seatsOpen = true;
        }
      }
    }

    setState(prevState => ({ ...prevState, closeTableActive: true, seatsOpen: seatsOpen }));
  }

  function closeOrderCompletePopup(open: boolean) {
    ReactDOM.unstable_batchedUpdates(() => {
      setState(prevState => ({ ...prevState, orderActionBarActive: open }));
      setPaymentState(prevState => ({ ...prevState, order: undefined }));
    });
  }

  async function sendFireChit() {
    const params = {
      table_id: cartStore?.activeTable?.id,
    };

    const fireChitRes = await FireKitchenChit(params, true);

    if (fireChitRes.status !== StatusCode.OK) {
      uiActions.showError(fireChitRes.data.message);
      return;
    }

    console.log(fireChitRes);

    printChits(fireChitRes.data as IChit[]);

    uiActions.showSuccess("Fire chit sent successfully");
  }

  const handleDiscountCancel = async () => {
    const res = await DeleteDiscountLine(
      { id: removeDiscountState.discountId, cart_id: removeDiscountState.cartId },
      true,
    );

    if (res && res?.status !== StatusCode.OK) {
      props.uiActions.showError("Error removing discount"); // TODO: Translation
      return;
    }

    props.uiActions.showSuccess("Discount successfully removed"); // TODO: Translation

    setRemoveDiscountState({
      modalVisible: false,
      cartId: null,
      discountId: null,
    });

    void cartActions.loadTableCart({ tableCart: res?.data }, false);
    void setUpdatedCartOnTable(res?.data);
  };

  function setUpdatedCartOnTable(updatedCart: ICart) {
    const table = { ...cartStore?.activeTable };
    const tableCarts = table?.carts;
    const cartIndex = tableCarts?.findIndex(cart => cart?.id === updatedCart?.id);
    if (cartIndex === -1) {
      uiActions.showError("Cart not found, refreshing table");
      void cartActions.loadActiveTableNew(cartStore.cart?.table_id, true);
    } else {
      tableCarts[cartIndex] = { ...updatedCart };
      void cartActions.setActiveTable(table);
    }
  }

  function selectCourse(id: number) {
    setState(prevState => ({ ...prevState, selectedCourseId: state?.selectedCourseId === id ? null : id }));
  }

  return (
    <>
      <div id="top-bar" className="table-service-top-bar-container">
        <div className="table-service-top-bar-left">
          <div className="table-action_container">
            <div className="table-action" onClick={backToTableSelection}>
              <span className="table-action-icon">
                <FontAwesomeIcon icon={["far", "arrow-left-long"]} size="sm" fixedWidth />
              </span>
              <p>{t("secure.facility.table_service.table_service.081")}</p>
            </div>
          </div>
        </div>
        <div className="table-service-top-bar-center">
          <div>
            <div className="font-semibold text-lg">{cartStore?.activeTable?.title}</div>
            <div>{cartStore?.activeTable?.user_full_name}</div>
          </div>
        </div>
        <div className="table-service-top-bar-right">
          <FontAwesomeIcon
            onClick={() => setState(prevState => ({ ...prevState, showProductSearchModal: true }))}
            className="table-service-search-icon"
            fixedWidth
            size="2x"
            icon={["far", "magnifying-glass"]}
          />
          <div className="flex flex-row gap-4 items-center">
            <div>
              <div className="font-semibold text-md">{authStore.user?.full_name}</div>
            </div>
            <div className="table-service-top-bar-right-icon-container">
              <div className="table-service-top-bar-right-icon">{authStore?.user?.first_name.charAt(0)}</div>
            </div>
          </div>
        </div>
      </div>
      <div id="main-table-service-container" className="table-service-container">
        <div className="table-service-folders-container-vertical">
          <TableServiceFoldersMenu
            selectedFolder={state.selectedFolder}
            setSelectedFolder={handleChangeSelectedFolder}
            folders={state.folders}
          />
        </div>
        <div className="table-service-products-container">
          <div className="overflow-auto p-3 table-service-tablet_container">
            <Products
              {...renderProducts()}
              productState={productState}
              onClick={selectProduct}
              onCancel={onCancel}
              onConfirmVariant={onConfirmVariant}
              disabled={
                !cartStore?.cart ||
                cartStore?.cart?.status === "complete" ||
                paymentState?.order?.financial_status === "partially_paid" ||
                cartStore?.cart?.status === "void"
              }
            />
            <div className="table-service-folders-container-horizontal">
              <TableServiceFoldersMenu
                selectedFolder={state.selectedFolder}
                setSelectedFolder={handleChangeSelectedFolder}
                folders={state.folders}
              />
            </div>
          </div>
        </div>
        <div className="table-service-table-menu-container">
          <div className="table-service-table-menu-seats-bar">
            {/* Future filter feature can use this view */}
            {/*<div
              className={`table-service-table-menu-seats-bar-table${
                state.activeCart?.token === "table" ? " selected-seat-bar-item" : ""
              }`}
              onClick={() => handleSeatChange({ token: "table" })}
            >
              <div className="font-semibold text-lg table-service-table-menu-seats-bar-table-title">
                {cartStore.activeTable?.title}
              </div>
              <div className="table-service-table-menu-seats-bar-table-user-name">
                {cartStore?.activeTable?.user_full_name}
              </div>
            </div>*/}
            {cartStore.activeTable?.carts?.map((cart, cartIndex) => {
              return (
                <div
                  key={`table_seats_${cart.token}`}
                  className={classNames("table-service-table-menu-seats-bar-seat", {
                    "selected-seat-bar-item": state.activeCart?.token === cart.token,
                    "selected-seat-bar-item-paid table-service-table-menu-seats-bar-seat-paid":
                      cart?.status === "complete",
                    "selected-seat-bar-item-void table-service-table-menu-seats-bar-seat-void": cart?.status === "void",
                    "selected-seat-bar-item-partially-paid table-service-table-menu-seats-bar-seat-partially-paid":
                      cart?.order?.financial_status === "partially_paid",
                  })}
                  onClick={() => handleSeatChange(cart)}
                >
                  {cartIndex + 1}
                </div>
              );
            })}
            <div className={"table-service-table-menu-seats-bar-seat"} onClick={() => handleAddSeat()}>
              +
            </div>
          </div>
          <div className="table-service-table-menu-content hide-scroll-bars">
            {cartStore?.activeTable?.carts.length === 0 && (
              <div className="text-black">
                <Callout
                  title={t("secure.facility.table_service.table_service.082")}
                  content={t("secure.facility.table_service.table_service.083")}
                  type="info"
                />
              </div>
            )}
            {(cartStore?.cart?.customer || cartStore?.cart?.description || cartStore?.cart?.status === "complete") && (
              <div className="table-service-table-menu-content-seat-information">
                <NewBadgeGroup
                  primaryContent={cartStore?.cart?.customer?.full_name}
                  secondaryContent={cartStore?.cart?.description}
                  icon={cartStore?.cart?.status === "complete" ? "circle-dollar" : null}
                  badgeGroupStyle="tertiary"
                  badgeGroupType="default"
                  badgeGroupSize="small"
                  // rightAlign
                  onClick={handleOpenCartDetailsSheet}
                  // iconClick={handleBadgeGroupClick}
                />
              </div>
            )}
            {facilityStore?.kitchenCourses?.length > 0 && (
              <div className="table-service-course-container">
                {facilityStore?.kitchenCourses?.map((course, index) => {
                  return (
                    <span
                      key={index}
                      onClick={() => selectCourse(course?.id)}
                      className={classNames("table-service-course-badge", {
                        "table-service-course-badge-selected": state?.selectedCourseId === course?.id,
                      })}
                    >
                      <Badge type={state?.selectedCourseId === course?.id ? "primary" : "gray"} size="medium">
                        {course?.title}
                      </Badge>
                    </span>
                  );
                })}
              </div>
            )}

            {cartStore?.activeTable?.carts.length > 0 &&
              (!cartStore.cart?.line_items || cartStore.cart.line_items.length < 1) && (
                <div className="table-service-table-menu-content-no-items">
                  {t("secure.facility.table_service.table_service.085")}
                </div>
              )}
            <div className="table-service-table-menu-content-line-items">
              {/* Future filter feature can use this view */}
              {/*state.activeCart?.token === "table" ? (
                <>
                  {cartStore?.activeTable?.carts?.map((cart, cartIndex) => {
                    return (
                      <React.Fragment key={cartIndex}>
                        {cart.line_items?.map(lineItem => {
                          if (lineItem.parent_id === null) {
                            const hasModifiers =
                              modifierGroups[cart.token] && lineItem.id.toString() in modifierGroups[cart.token];
                            const hasNote = lineItem?.note?.length > 0;
                            const hasMultipleLines = hasModifiers || hasNote;

                            return (
                              <div
                                key={String(lineItem.id)}
                                className={`table-service-table-menu-content-line-item${
                                  hasMultipleLines ? "-connected" : ""
                                }`}
                              >
                                <div
                                  className={`font-semibold line-item-action table-service-table-menu-content-line-item-primary-double${
                                    hasMultipleLines ? "-connected" : ""
                                  }`}
                                  style={
                                    cart?.status === "complete" ? { backgroundColor: "white", cursor: "default" } : {}
                                  }
                                  onClick={
                                    cart?.status !== "complete"
                                      ? () => handleShowLineItemActionModal(lineItem, cart)
                                      : undefined
                                  }
                                >
                                  <div>
                                    <div>
                                      {lineItem?.product?.preferred_title
                                        ? lineItem.product.preferred_title
                                        : lineItem?.product_title}
                                    </div>
                                    {lineItem?.product_title !== lineItem?.variant_title && (
                                      <div className="text-subdued text-sm">{lineItem?.variant_title}</div>
                                    )}
                                  </div>
                                  <div className="table-service-table-menu-content-line-item-primary-double-seat">
                                    S{cartIndex + 1}
                                  </div>
                                  <div className="table-service-table-menu-content-line-item-quantity">
                                    {lineItem?.quantity}
                                  </div>
                                </div>
                                <div
                                  className={`table-service-table-menu-content-line-item-tertiary${
                                    hasMultipleLines ? "-double-connected" : ""
                                  }`}
                                >
                                  <LocaleCurrency currency={cart?.currency} amount={lineItem?.subtotal_price} />
                                </div>

                                {hasModifiers && (
                                  <>
                                    <div className="table-service-table-menu-content-line-item-primary-double">
                                      <div>{t("secure.facility.table_service.table_service.086")}</div>
                                      <div className="table-service-table-menu-content-line-item-connector">
                                        <hr className="table-service-table-menu-content-line-item-pointer" />
                                      </div>
                                    </div>
                                    <div className="text-subdued table-service-table-menu-content-line-item-tertiary">
                                      <LocaleCurrency
                                        currency={cartStore.cart?.currency}
                                        amount={modifierGroups[cart.token][lineItem.id.toString()]
                                          .map(modifier => modifier.subtotal_price)
                                          .reduce((prev, current) => prev + current, 0)}
                                        style={{ color: "#999999" }}
                                      />
                                    </div>

                                    {modifierGroups[cart.token][lineItem.id.toString()].map((modifier: any) => {
                                      return (
                                        <React.Fragment key={modifier.id}>
                                          <div
                                            className="text-subdued line-item-action table-service-table-menu-content-line-item-primary-extra"
                                            style={
                                              cart?.status === "complete"
                                                ? { backgroundColor: "white", cursor: "default" }
                                                : {}
                                            }
                                            onClick={
                                              cart?.status !== "complete"
                                                ? () => handleShowLineItemActionModal(modifier, cart)
                                                : undefined
                                            }
                                          >
                                            <div>
                                              <div>
                                                {modifier?.product?.preferred_title
                                                  ? modifier?.product?.preferred_title
                                                  : modifier.product_title}
                                              </div>
                                              {modifier?.product_title !== modifier?.variant_title && (
                                                <div className="text-sm">{modifier?.variant_title}</div>
                                              )}
                                            </div>
                                            <div className="table-service-table-menu-content-line-item-action-connector" />
                                          </div>
                                          <div className="text-subdued table-service-table-menu-content-line-item-secondary-extra">
                                            {modifier.quantity}
                                          </div>
                                          <div className="text-subdued table-service-table-menu-content-line-item-tertiary-extra">
                                            <LocaleCurrency
                                              currency={cartStore.cart?.currency}
                                              amount={modifier.subtotal_price}
                                              style={{ color: "#999999" }}
                                            />
                                          </div>
                                        </React.Fragment>
                                      );
                                    })}
                                  </>
                                )}

                                {hasNote && (
                                  <>
                                    <div
                                      className={`font-semibold line-item-action table-service-table-menu-content-line-item-primary-double`}
                                      style={
                                        cart?.status === "complete"
                                          ? { backgroundColor: "white", cursor: "default" }
                                          : {}
                                      }
                                      onClick={
                                        cart?.status !== "complete"
                                          ? () => handleQuickOpenNoteSheet(lineItem, cart)
                                          : undefined
                                      }
                                    >
                                      <div>{t("secure.facility.table_service.table_service.087")}</div>
                                      <div className="table-service-table-menu-content-line-item-action-connector">
                                        <hr className="table-service-table-menu-content-line-item-pointer" />
                                      </div>
                                    </div>
                                    <div />

                                    <div className="text-subdued table-service-table-menu-content-line-item-primary-note">
                                      {lineItem.note}
                                      <div className="table-service-table-menu-content-line-item-connector" />
                                    </div>
                                  </>
                                )}
                              </div>
                            );
                          }
                        })}
                      </React.Fragment>
                    );
                  })}
                </>
              */}
              {cartStore?.cart?.line_items &&
                state?.kitchenCourses?.map((course, index) => {
                  let currentCourseLineItems;
                  if (course?.id === -1) {
                    currentCourseLineItems = [...cartStore?.cart?.line_items]?.filter(
                      filteredLineItem => !filteredLineItem?.kitchen_meal_id,
                    );
                  } else {
                    currentCourseLineItems = [...cartStore?.cart?.line_items]?.filter(
                      filteredLineItem => filteredLineItem?.kitchen_meal_id === course?.id,
                    );
                  }

                  if (currentCourseLineItems?.length > 0) {
                    return (
                      <div key={index}>
                        {facilityStore?.kitchenCourses?.length > 0 && (
                          <p className="table-service-course-title">{course?.title}</p>
                        )}
                        {currentCourseLineItems?.map(lineItem => {
                          if (lineItem.parent_id === null) {
                            const hasModifiers =
                              modifierGroups[cartStore.cart.token] &&
                              lineItem.id.toString() in modifierGroups[cartStore.cart.token];
                            const hasNote = lineItem?.note?.length > 0;
                            const hasMultipleLines = hasModifiers || hasNote;

                            return (
                              <div
                                key={String(lineItem.id)}
                                className={`table-service-table-menu-content-line-item${
                                  hasMultipleLines ? "-connected" : ""
                                }`}
                              >
                                <div
                                  className={`font-semibold line-item-action table-service-table-menu-content-line-item-primary-double${
                                    hasMultipleLines ? "-connected" : ""
                                  }`}
                                  style={
                                    cartStore.cart?.status === "complete" ||
                                    cartStore?.cart?.status === "void" ||
                                    paymentState?.order?.financial_status === "partially_paid"
                                      ? { backgroundColor: "white", cursor: "default" }
                                      : {}
                                  }
                                  onClick={
                                    cartStore.cart?.status !== "complete" &&
                                    paymentState?.order?.financial_status !== "partially_paid" &&
                                    cartStore?.cart?.status !== "void"
                                      ? () => handleShowLineItemActionModal(lineItem, cartStore.cart)
                                      : undefined
                                  }
                                >
                                  <div>
                                    <div className="text-md ">
                                      {lineItem?.product?.preferred_title
                                        ? lineItem.product.preferred_title
                                        : lineItem?.product_title}
                                    </div>
                                    {lineItem?.product_title !== lineItem?.variant_title && (
                                      <div className="text-subdued text-sm">{lineItem?.variant_title}</div>
                                    )}
                                  </div>
                                  <div className="table-service-table-menu-content-line-item-quantity text-md text-semibold">
                                    {lineItem?.quantity}
                                  </div>
                                </div>
                                <div
                                  className={`text-md text-semibold table-service-table-menu-content-line-item-tertiary${
                                    hasMultipleLines ? "-double-connected" : ""
                                  }`}
                                >
                                  <LocaleCurrency
                                    currency={cartStore.cart?.currency}
                                    amount={lineItem?.subtotal_price}
                                  />
                                </div>

                                {hasModifiers && (
                                  <>
                                    <div className="table-service-table-menu-content-line-item-primary-double">
                                      <div>{t("secure.facility.table_service.table_service.088")}</div>
                                      <div className="table-service-table-menu-content-line-item-connector">
                                        <hr className="table-service-table-menu-content-line-item-pointer" />
                                      </div>
                                    </div>
                                    <div className="text-subdued table-service-table-menu-content-line-item-tertiary">
                                      {/* <LocaleCurrency
                                        currency={cartStore.cart?.currency}
                                        amount={modifierGroups[cartStore.cart.token][lineItem.id.toString()]
                                          .map(modifier => modifier.subtotal_price)
                                          .reduce((prev, current) => prev + current, 0)}
                                        style={{ color: "#999999" }}
                                      /> */}
                                    </div>

                                    {modifierGroups[cartStore.cart.token][lineItem.id.toString()].map(
                                      (modifier: any) => {
                                        return (
                                          <React.Fragment key={modifier.id}>
                                            <div
                                              className="text-subdued line-item-action table-service-table-menu-content-line-item-primary-extra"
                                              style={
                                                cartStore.cart?.status === "complete" ||
                                                cartStore?.cart?.status === "void" ||
                                                paymentState?.order?.financial_status === "partially_paid"
                                                  ? { backgroundColor: "white", cursor: "default" }
                                                  : {}
                                              }
                                              onClick={
                                                cartStore.cart?.status !== "complete" &&
                                                paymentState?.order?.financial_status !== "partially_paid" &&
                                                cartStore?.cart?.status !== "void"
                                                  ? () => handleShowLineItemActionModal(modifier, cartStore.cart)
                                                  : undefined
                                              }
                                            >
                                              <div>
                                                <div>
                                                  {modifier?.product?.preferred_title
                                                    ? modifier?.product?.preferred_title
                                                    : modifier.product_title}
                                                </div>
                                                {modifier?.product_title !== modifier?.variant_title && (
                                                  <div className="text-sm">{modifier?.variant_title}</div>
                                                )}
                                              </div>
                                              <div className="table-service-table-menu-content-line-item-action-connector" />
                                            </div>
                                            <div className="text-subdued table-service-table-menu-content-line-item-secondary-extra">
                                              {modifier.quantity}
                                            </div>
                                            <div className="text-subdued table-service-table-menu-content-line-item-tertiary-extra">
                                              <LocaleCurrency
                                                currency={cartStore.cart?.currency}
                                                amount={modifier.subtotal_price}
                                                style={{ color: "#999999" }}
                                              />
                                            </div>
                                          </React.Fragment>
                                        );
                                      },
                                    )}
                                  </>
                                )}

                                {hasNote && (
                                  <>
                                    <div
                                      className={`font-semibold line-item-action table-service-table-menu-content-line-item-primary-double`}
                                      style={
                                        cartStore.cart?.status === "complete" ||
                                        cartStore?.cart?.status === "void" ||
                                        paymentState?.order?.financial_status === "partially_paid"
                                          ? { backgroundColor: "white", cursor: "default" }
                                          : {}
                                      }
                                      onClick={
                                        cartStore.cart?.status !== "complete" &&
                                        cartStore?.cart?.status !== "void" &&
                                        paymentState?.order?.financial_status !== "partially_paid"
                                          ? () => handleQuickOpenNoteSheet(lineItem, cartStore.cart)
                                          : undefined
                                      }
                                    >
                                      <div>{t("secure.facility.table_service.table_service.089")}</div>
                                      <div className="table-service-table-menu-content-line-item-action-connector">
                                        <hr className="table-service-table-menu-content-line-item-pointer" />
                                      </div>
                                    </div>
                                    <div />

                                    <div className="text-subdued table-service-table-menu-content-line-item-primary-note">
                                      {lineItem.note}
                                      <div className="table-service-table-menu-content-line-item-connector" />
                                    </div>
                                  </>
                                )}
                              </div>
                            );
                          }
                        })}
                      </div>
                    );
                  }
                })}
            </div>
          </div>
          <div className="table-service-table-menu-order">
            <div className="table-service-table-menu-order-actions">
              <button
                type="button"
                className="table-service-table-menu-order-actions-action"
                onClick={() => {
                  console.log(cartStore.activeTable);
                  setSendOrderState(prevState => ({
                    ...prevState,
                    sendOrderVisible: true,
                    activeTable: cartStore?.activeTable,
                  }));
                }}
              >
                <FontAwesomeIcon
                  icon={["far", "fork-knife"]}
                  fixedWidth
                  className="table-service-table-menu-order-actions-action-icon"
                />
                <div>{t("secure.facility.table_service.table_service.228")}</div>
              </button>

              <button
                type="button"
                className="table-service-table-menu-order-actions-action"
                onClick={
                  cartStore?.cart?.order
                    ? () => loadReceipt(cartStore.cart?.order?.id, "order")
                    : () => loadReceipt(cartStore.cart?.id, "cart")
                }
              >
                <FontAwesomeIcon
                  icon={["far", "receipt"]}
                  fixedWidth
                  className="table-service-table-menu-order-actions-action-icon"
                />
                <div>{t("secure.facility.table_service.table_service.229")}</div>
              </button>
              <button
                type="button"
                className="table-service-table-menu-order-actions-action"
                onClick={handleShowActionsModal}
              >
                <FontAwesomeIcon
                  icon={["far", "grip-dots"]}
                  fixedWidth
                  className="table-service-table-menu-order-actions-action-icon"
                />
                <div>{t("secure.facility.table_service.table_service.230")}</div>
              </button>
            </div>
            <div className="table-service-table-menu-order-summary">
              {cartStore?.cart?.discount_lines?.map((discountLine: Record<string, any>, index) => {
                return (
                  <div
                    key={index}
                    className="table-service-table-menu-order-summary-detail-action"
                    style={
                      cartStore?.cart?.status === "complete" ||
                      paymentState?.order?.financial_status === "partially_paid" ||
                      cartStore?.cart?.status === "void"
                        ? { backgroundColor: "white", cursor: "default" }
                        : {}
                    }
                    onClick={
                      cartStore?.cart?.status !== "complete" &&
                      paymentState?.order?.financial_status !== "partially_paid" &&
                      cartStore?.cart?.status !== "void"
                        ? () =>
                            cartStore?.cart?.status !== "complete" &&
                            setRemoveDiscountState({
                              modalVisible: true,
                              cartId: discountLine.cart_id,
                              discountId: discountLine.id,
                            })
                        : undefined
                    }
                  >
                    <div className="text-md text-semibold">{discountLine?.title}</div>
                    <div className="text-md text-semibold">
                      <LocaleCurrency
                        className="text-md text-semibold"
                        currency={cartStore.cart?.currency}
                        amount={discountLine?.amount}
                      />
                    </div>
                  </div>
                );
              })}
              <div className="table-service-table-menu-order-summary-detail">
                <div className="text-md text-semibold">{t("secure.facility.table_service.table_service.090")}</div>

                <div className="text-md text-semibold">
                  <LocaleCurrency
                    className="text-md text-semibold"
                    currency={cartStore.cart?.currency}
                    amount={cartStore.cart?.subtotal_price}
                  />
                </div>
              </div>
              {cartStore.cart?.tax_lines.map((taxLine, index) => {
                return (
                  <div key={index} className="table-service-table-menu-order-summary-detail">
                    <div className="text-md text-semibold">
                      {taxLine.title} <span>{(taxLine.rate * 100).toFixed(2)}%</span>
                    </div>

                    <div className="text-md text-semibold">
                      <LocaleCurrency
                        className="text-md text-semibold"
                        currency={cartStore.cart?.currency}
                        amount={taxLine.price}
                      />
                    </div>
                  </div>
                );
              })}
              <div
                className="table-service-table-menu-order-summary-detail-action"
                style={
                  cartStore?.cart?.status === "complete" ||
                  paymentState?.order?.financial_status === "partially_paid" ||
                  cartStore?.cart?.status === "void"
                    ? { backgroundColor: "white", cursor: "default" }
                    : {}
                }
                onClick={
                  cartStore?.cart?.status !== "complete" &&
                  paymentState?.order?.financial_status !== "partially_paid" &&
                  cartStore?.cart?.status !== "void"
                    ? () => handleOpenTipSheet()
                    : undefined
                }
              >
                <div className="text-md text-semibold">{t("secure.facility.table_service.table_service.091")}</div>
                <div className="text-md text-semibold">
                  <LocaleCurrency
                    className="text-md text-semibold"
                    currency={cartStore.cart?.currency}
                    amount={cartStore.cart?.total_tip}
                  />
                </div>
              </div>
              <div className="table-service-table-menu-order-summary-detail">
                <div className="text-md text-semibold">{t("secure.facility.table_service.table_service.092")}</div>
                <div className="text-md text-semibold">
                  <LocaleCurrency
                    className="text-md text-semibold"
                    currency={cartStore.cart?.currency}
                    amount={cartStore.cart?.total_price}
                  />
                </div>
              </div>
              {cartStore?.cart?.order &&
                cartStore?.cart?.order?.transactions?.map((transaction, transactionIndex) => {
                  return (
                    <div key={transactionIndex} className="table-service-table-menu-order-summary-detail">
                      <div className="text-md text-semibold">{capitalize(transaction.payment_type)}</div>
                      <div className="text-md text-semibold">
                        <LocaleCurrency
                          className="text-md text-semibold"
                          currency={cartStore.cart?.currency}
                          amount={transaction.amount}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
            <button type="button" className="table-service-table-menu-order-pay" onClick={checkGiftCards}>
              <div>{t("secure.facility.table_service.table_service.223")}</div>
            </button>
          </div>
        </div>
      </div>

      {kitchenChitModalOpen && (
        <TableServiceKitchenChitModal
          isVisible={kitchenChitModalOpen}
          closeModal={() => setKitchenChitModalOpen(false)}
          user_id={authStore?.user?.id}
          forceRefresh={forceRefreshChits}
          setForceRefresh={setForceRefreshChits}
        />
      )}

      <InputModal
        open={state.showProductSearchModal}
        setOpen={(open: boolean) => setState(prevState => ({ ...prevState, showProductSearchModal: open }))}
        value={filterState.search}
        setValue={(value: string) => setFilterState(prevState => ({ ...prevState, search: value }))}
        type="search"
        placeHolder={t("secure.facility.table_service.table_service.099")}
        quickCode="s"
        closable
        backDropCancel
        onCancel={() => setState(prevState => ({ ...prevState, showProductSearchModal: false }))}
      />

      <ActionModal
        open={state.showLineItemActionsModal}
        setOpen={(open: boolean) => setState(prevState => ({ ...prevState, showLineItemActionsModal: open }))}
        actionGroups={[lineItemActions]}
      />

      <ActionModal
        open={state.showActionsModal}
        setOpen={(open: boolean) => setState(prevState => ({ ...prevState, showActionsModal: open }))}
        actionGroups={[tableActions, seatActions]}
      />

      {state.tipSheetVisible && (
        <Sheet
          closable
          size="small"
          open={state.tipSheetVisible}
          title={t("secure.facility.table_service.table_service.100")}
          onCancel={() =>
            setState(prevState => ({ ...prevState, tipSheetVisible: false, tipAmount: "", tipPercent: null }))
          }
          onOk={handleAddTip}
          okText={t("secure.facility.table_service.table_service.101")}
          cancelText={t("secure.facility.table_service.table_service.102")}
          okDisabled={isNaN(Number(state.tipAmount)) || Number(state.tipAmount) < 0 ? true : false}
        >
          <Input
            label={t("secure.facility.table_service.table_service.103")}
            type="number"
            id="tipAmount"
            value={state.tipAmount}
            onChange={handleTipInputChange}
            autoFocus
          />
          <div className="mt-4 p-4">
            <Radio.Group name="tip" onChange={onTipPercentChange} value={state.tipPercent}>
              {register_group?.tipping_type === "percent" ? (
                <>
                  <Radio.Button value={register_group?.tip_percentage_low / 100}>
                    {`${register_group?.tip_percentage_low}%`}{" "}
                  </Radio.Button>
                  <Radio.Button value={register_group?.tip_percentage_mid / 100}>
                    {`${register_group?.tip_percentage_mid}%`}{" "}
                  </Radio.Button>
                  <Radio.Button value={register_group?.tip_percentage_high / 100}>
                    {`${register_group?.tip_percentage_high}%`}{" "}
                  </Radio.Button>
                </>
              ) : register_group?.tipping_type === "fixed_amount" ? (
                <>
                  <Radio.Button value={register_group?.tip_fixed_amount_low}>
                    {`$${register_group?.tip_fixed_amount_low}`}{" "}
                  </Radio.Button>
                  <Radio.Button value={register_group?.tip_fixed_amount_mid}>
                    {`$${register_group?.tip_fixed_amount_mid}`}{" "}
                  </Radio.Button>
                  <Radio.Button value={register_group?.tip_fixed_amount_high}>
                    {`$${register_group?.tip_fixed_amount_high}`}{" "}
                  </Radio.Button>
                </>
              ) : (
                <>
                  <Radio.Button value={0.1}>{"10%"} </Radio.Button>
                  <Radio.Button value={0.15}>{"15%"} </Radio.Button>
                  <Radio.Button value={0.2}>{"20%"} </Radio.Button>
                </>
              )}
            </Radio.Group>
          </div>
        </Sheet>
      )}

      <ChangeQuantity
        quantitySheetVisible={itemActionsState.quantitySheetVisible}
        quantitySelected={itemActionsState.quantitySelected}
        selectedLineItem={itemActionsState.selectedLineItem}
        onOk={handleUpdateLineItemQuantity}
        onCancel={() =>
          setItemActionsState(prevState => ({
            ...prevState,
            quantitySelected: null,
            quantitySheetVisible: false,
            selectedLineItem: null,
          }))
        }
      />

      <StripeTerminalSheet />

      <PriceSheet
        priceSheetVisible={itemActionsState.priceSheetVisible}
        onCancel={() =>
          setItemActionsState(prevState => ({
            ...prevState,
            price: "",
            priceSheetVisible: false,
            discountIdSelected: -1,
          }))
        }
        onOk={handleUpdatePrice}
        selectedLineItem={itemActionsState.selectedLineItem}
      />

      <DiscountLineItemModal
        open={itemActionsState.discountSheetVisible}
        title={t("secure.facility.table_service.table_service.112")}
        onCancel={() =>
          setItemActionsState(prevState => ({
            ...prevState,
            discountSheetVisible: false,
            discountIdSelected: -1,
            discountSelected: null,
          }))
        }
        onOk={handleApplyDiscount}
        onChange={(value: any, discount: IDiscount) =>
          handleDiscountSelectChange(value, "discountIdSelected", discount)
        }
        okDisabled={!itemActionsState.discountIdSelected || itemActionsState.discountIdSelected < 0}
        discounts={facilityStore.discountOptions}
        selectedDiscount={itemActionsState.discountSelected}
      />

      <Sheet
        title={t("secure.facility.table_service.table_service.113")}
        open={itemActionsState.splitLineItemVisible}
        size="medium"
        closable
        onCancel={() =>
          setItemActionsState(prevState => ({
            ...prevState,
            splitLineItemVisible: false,
            selectedSplitLineItemIds: [],
            selectedSplitCartIds: [],
          }))
        }
        onOk={handleLineItemSplit}
        okText={t("secure.facility.table_service.table_service.114")}
        okDisabled={
          itemActionsState.selectedSplitCartIds?.length < 1 || itemActionsState.selectedSplitLineItemIds?.length < 1
            ? true
            : false
        }
      >
        <div className="flex gap-20">
          <div>
            <span className="text-xl">{t("secure.facility.table_service.table_service.115")}</span>
            <div className="border-t border-gray-200">
              {cartStore?.cart?.line_items?.map((lineItem: Record<string, any>, lineItemIndex: number) => {
                //Don't display modifiers
                if (!lineItem.parent_id) {
                  if (lineItem?.quantity < 1) {
                    return (
                      <div
                        key={lineItemIndex}
                        className="flex p-2 items-center justify-between bg-gray-600 gap-4 cursor-not-allowed"
                      >
                        <div className="flex items-center pointer-events-none">
                          <Checkbox
                            onChange={() => handleSplitLineItemCheckboxChange(lineItem.id)}
                            size="small"
                            checked={false}
                            disabled={true}
                          />
                          <span className="font-medium text-subdued mr-2">{lineItem.quantity}</span>
                          {lineItem.product_title === lineItem.variant_title ? (
                            <span>
                              {lineItem?.product?.preferred_title
                                ? lineItem?.product?.preferred_title
                                : lineItem?.product_title}
                            </span>
                          ) : (
                            <span className="w-60">
                              {lineItem?.product?.preferred_title
                                ? lineItem?.product?.preferred_title
                                : lineItem?.product_title}
                              <span className="text-subdued"> - {lineItem.variant_title}</span>
                            </span>
                          )}
                        </div>
                        <LocaleCurrency currency={cartStore?.cart?.currency} amount={lineItem.subtotal_price} />
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={lineItemIndex}
                        onClick={() => handleSplitLineItemCheckboxChange(lineItem.id)}
                        className="flex p-2 items-center justify-between cursor-pointer gap-4 table-service-hover"
                      >
                        <div className="flex items-center pointer-events-none">
                          <Checkbox
                            onChange={() => handleSplitLineItemCheckboxChange(lineItem.id)}
                            size="small"
                            checked={itemActionsState.selectedSplitLineItemIds.includes(lineItem.id)}
                          />
                          <span className="font-medium text-subdued mr-4">{lineItem.quantity}</span>
                          {lineItem.product_title === lineItem.variant_title ? (
                            <span>
                              {lineItem?.product?.preferred_title
                                ? lineItem?.product?.preferred_title
                                : lineItem?.product_title}
                            </span>
                          ) : (
                            <span className="w-60">
                              {lineItem?.product?.preferred_title
                                ? lineItem?.product?.preferred_title
                                : lineItem?.product_title}
                              <span className="text-subdued"> - {lineItem.variant_title}</span>
                            </span>
                          )}
                        </div>
                        <LocaleCurrency currency={cartStore?.cart?.currency} amount={lineItem.subtotal_price} />
                      </div>
                    );
                  }
                }
              })}
            </div>
          </div>
          <div className="flex-1">
            <span className="text-xl">{t("secure.facility.table_service.table_service.116")}</span>
            <div className="border-t border-gray-200">
              {cartStore.activeTable?.carts?.length === 1 ? (
                <div
                  onClick={() => handleSplitCartCheckboxChange(0)}
                  className="flex p-2 items-center justify-between cursor-pointer gap-4 table-service-hover"
                >
                  <div className="flex items-center pointer-events-none">
                    <Checkbox
                      onChange={() => handleSplitCartCheckboxChange(0)}
                      size="small"
                      checked={itemActionsState.selectedSplitCartIds.includes(0)}
                    />
                    <span className="ml-2">Temporary Seat</span>
                  </div>
                  <LocaleCurrency amount={0.0} />
                </div>
              ) : null}

              {cartStore.activeTable?.carts.map((cart: Record<string, any>, cartIndex: number) => {
                if (
                  cart?.id !== cartStore?.cart?.id &&
                  cart.status !== "complete" &&
                  cart?.status !== "void" &&
                  cart?.order?.financial_status !== "partially_paid"
                ) {
                  return (
                    <div
                      key={cartIndex}
                      onClick={() => handleSplitCartCheckboxChange(cart?.id)}
                      className="flex p-2 items-center justify-between cursor-pointer gap-4 table-service-hover"
                    >
                      <div className="flex items-center pointer-events-none">
                        <Checkbox
                          onChange={() => handleSplitCartCheckboxChange(cart?.id)}
                          size="small"
                          checked={itemActionsState.selectedSplitCartIds.includes(cart?.id)}
                        />
                        <span className="ml-2">
                          {t("secure.facility.table_service.table_service.117")}
                          {cartIndex + 1}
                        </span>
                      </div>
                      <LocaleCurrency currency={cart?.currency} amount={cart?.total_price} />
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </Sheet>

      <SelectModifierSheet
        open={itemActionsState.modifiersSheetVisible}
        onCancel={() => handleCloseModifierGroups(true)}
        onOk={onModifiersOk}
        okDisabled={disableSelectingModifiers(itemActionsState.selectedLineItem)}
        allModifierGroups={itemActionsState.allModifierGroups}
        selectedModifierGroupVariants={itemActionsState.selectedModifierGroupVariants}
        addModifierVariant={addModifierVariant}
      />

      <PrintChit
        printOrderVisible={sendOrderState.printOrderVisible}
        onCancel={() =>
          setSendOrderState(prevState => ({
            ...prevState,
            printOrderVisible: false,
            addedLineItems: [],
            activeTable: null,
          }))
        }
        onOk={handlePrintReceipt}
        activeTable={sendOrderState.activeTable}
      />

      {sendOrderState.sendOrderVisible && (
        <TableServiceSendLineItemsModal
          open={sendOrderState.sendOrderVisible}
          selectedIds={setSendChitIds()}
          onOk={lineItemIds => handleSendChit(lineItemIds)}
          onCancel={() => setSendOrderState(prev => ({ ...prev, sendOrderVisible: false }))}
          activeTable={sendOrderState.activeTable}
        />
      )}

      <EditSeatDetails
        editSeatVisible={itemActionsState.cartDetailsSheetVisible}
        onCancel={() =>
          setItemActionsState(prevState => ({
            ...prevState,
            prevCartDescription: "",
            cartDetailsSheetVisible: false,
            prevSelectedCustomer: null,
          }))
        }
        onOk={handleUpdateCartDetails}
        prevCartDetails={{
          customer: itemActionsState.prevSelectedCustomer,
          description: itemActionsState?.prevCartDescription,
        }}
      />

      {paymentState.paymentModalVisible && (
        <Sheet
          open={paymentState.paymentModalVisible}
          size="small"
          closable
          title={t("secure.facility.table_service.table_service.147")}
          onCancel={closePaymentSheet}
          onOk={handleProcessPaymentCheck}
          cancelText={t("secure.facility.table_service.table_service.148")}
          okText={
            paymentState.paymentOption?.payment_method === "gift_card"
              ? t("secure.facility.table_service.table_service.149")
              : t("secure.facility.table_service.table_service.150")
          }
          backDropCancel={false}
          okDisabled={
            paymentState.paymentOption === null || paymentState.paymentAmount === "" || paymentState.paymentInProgress
              ? true
              : false
          }
          height="flexible"
        >
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <PaymentMethods
                  onChange={handlePaymentOptionChange}
                  paymentOptions={facilityStore?.paymentOptions}
                  cardReaderAvailable={terminalStore?.reader === null ? false : true}
                  cart={cartStore?.cart}
                  paymentAmount={Number(paymentState.paymentAmount)}
                  value={paymentState.paymentOption}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                {paymentState?.order && (
                  <CartTransactions
                    transactions={paymentState?.order?.transactions}
                    currency={paymentState?.order?.currency}
                  />
                )}
              </FormLayout.Group>
              {paymentState?.paymentInProgress ? (
                <FormLayout.Group>
                  <div className="table-service-loader">
                    <Spin />
                  </div>
                </FormLayout.Group>
              ) : null}
              <FormLayout.Group>
                <Input
                  prefix="$"
                  type="number"
                  label={t("secure.facility.table_service.table_service.151")}
                  id="paymentAmount"
                  value={paymentState.paymentAmount}
                  onChange={handlePaymentInputChange}
                />
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Sheet>
      )}

      <GiftCardSell
        gift_cards={paymentState.gift_cards}
        sellGiftCardsActive={paymentState.sellGiftCardsActive}
        onOk={handleAddSellGiftCards}
        onCancel={cancelGiftCardsAndLeagues}
      />

      <GiftCardRedeem
        redeemGiftCardsActive={paymentState.giftCardModalVisible}
        onOk={handleProcessPayment}
        onCancel={() =>
          setPaymentState(prevState => ({
            ...prevState,
            giftCardModalVisible: false,
            paymentInProgress: false,
          }))
        }
        cart={props.cartStore?.cart}
      />

      <LeagueSelectionModal
        open={paymentState.leagueFeesActive}
        leagueFeeProducts={paymentState.leagueFeeProducts}
        onCancel={cancelGiftCardsAndLeagues}
        onOk={setSelectedLeagues}
      />

      <Sheet
        size={"small"}
        closable
        title={t("secure.facility.table_service.table_service.166")}
        cancelText={t("secure.facility.table_service.table_service.167")}
        open={manualStripeState.visible}
        okText={t("secure.facility.table_service.table_service.168")}
        okDisabled={!elementsComplete}
        cancelDisabled={manualProcessing}
        backDropCancel={false}
        stacked
        onOk={() => {
          if (!submitTrigger) {
            setSubmitTrigger(!submitTrigger);
          }
        }}
        onCancel={async () => {
          await cancelManualCreditPaymentIntent(
            manualStripeState?.transaction?.payment_intent_id,
            manualStripeState?.transaction?.id,
          );
          if (elements) {
            elements.getElement(CardNumberElement).clear();
            elements.getElement(CardExpiryElement).clear();
            elements.getElement(CardCvcElement).clear();
          }
          setManualStripeState(prevState => ({
            ...prevState,
            visible: false,
            transaction: {},
          }));
        }}
      >
        <CheckoutForm
          onChange={handleCardSectionChange}
          onSubmit={() => handleManualProcessing()}
          trigger={submitTrigger}
          transaction={manualStripeState.transaction}
          onError={(message: string) => {
            setSubmitTrigger(false);
            setManualProcessing(false);
            uiActions.showError(message);
          }}
          onSuccess={async () => {
            // Clean values
            uiActions.showSuccess(t("secure.facility.table_service.table_service.169"));
            setManualStripeState(prevState => ({
              ...prevState,
              visible: false,
              transaction: {},
              elementComplete: {
                cardCvc: false,
                cardExpiry: false,
                cardNumber: false,
              },
            }));
            setSubmitTrigger(false);
            uiActions.enqueue();
            await updateOrder(paymentState?.order?.id, true);
            uiActions.dequeue();
          }}
        />
      </Sheet>

      <AccountsModal
        loadAccounts={paymentState.accountsVisible}
        onCancel={closeAccountsModal}
        onOk={handleAccountsOk}
        customerId={cartStore?.cart?.customer_id}
        searchAccountPermission={permissions?.accounts_charge_all}
      />

      <CreditBookModal
        loadCreditBooks={modalCreditBook.isOpen}
        onCancel={() => closeCreditBook()}
        onOk={(selectedCreditBook: IPrizeAccount) =>
          updateCreditBook({ selectedCreditBook: { ...selectedCreditBook } })
        }
        customerId={cartStore?.cart?.customer?.id}
        // searchAccountPermission={permissions?.credit}
      />

      <MoveSeats
        addToTableModalVisible={state.addToTableModalVisible}
        onCancel={() =>
          setState(prevState => ({
            ...prevState,
            addToTableModalVisible: false,
          }))
        }
        onOk={handleMoveCarts}
      />

      <TableServiceMoveLineItemsModal
        open={state.moveLineItemsModalVisible}
        seats={cartStore.activeTable?.carts}
        onCancel={handleCancelMoveLineItemsModal}
        onRefresh={handleRefreshAfterSaveMoveLineItems}
      />

      <MergeSeats
        mergeSeatsVisible={state.mergeSeatsSheetVisible}
        onCancel={() =>
          setState(prevState => ({
            ...prevState,
            mergeSeatsSheetVisible: false,
          }))
        }
        onOk={handleSeatMerge}
      />

      <CourseChange
        itemActionsState={itemActionsState}
        openCourseChange={state.coursesSheet}
        kitchenCourses={state.kitchenCourses}
        onOk={handleCourseChange}
        onCancel={() =>
          setState(prevState => ({
            ...prevState,
            coursesSheet: false,
            selectedCoursetoChange: null,
          }))
        }
      />

      <Sheet
        title={t("secure.facility.table_service.table_service.183")}
        open={state.roomChargeSheetVisible}
        size="small"
        closable
        onCancel={closeRoomChargeSheet}
        onOk={handleProcessPayment}
        okDisabled={isEmpty(state.selectedOccupant?.guest_name)}
        okText={t("secure.facility.table_service.table_service.184")}
        stacked
      >
        <Input
          label="Room Guest"
          type="search"
          onChange={handleOccupantSearchQueryChange}
          value={state.roomOccupantSearchQuery}
        />
        {state.roomOccupantsSearchResults.map((occupant, index) => {
          return (
            <div key={index} className="flex flex-row justify-between p-3 border-b">
              <Checkbox
                size="small"
                value={occupant.guest_name}
                checked={isEqual(occupant, state.selectedOccupant)}
                onChange={e =>
                  setState(prev => ({
                    ...prev,
                    selectedOccupant: cloneDeep(occupant),
                  }))
                }
                label={occupant.guest_name}
              />
            </div>
          );
        })}
      </Sheet>

      <NoteSheet
        noteSheetVisible={itemActionsState.noteSheetVisible}
        onCancel={() => setItemActionsState(prevState => ({ ...prevState, note: "", noteSheetVisible: false }))}
        lineItemNote={itemActionsState?.note ?? ""}
        onOk={handleAddNote}
      />

      <TransferTableSheet
        modalVisibility={transferTableState.modalVisibility}
        onCancel={() =>
          setTransferTableState(prevState => ({
            ...prevState,
            modalVisibility: false,
          }))
        }
        onOk={handleTransferTable}
        users={transferTableState.users}
      />

      <Popup
        open={itemActionsState.removeItemSheetVisible}
        type="warning"
        title={t("secure.facility.table_service.table_service.190")}
        description={t("secure.facility.table_service.table_service.191")}
        onOk={handleRemoveLineItem}
        okText={t("secure.facility.table_service.table_service.192")}
        cancelText={t("secure.facility.table_service.table_service.193")}
        onCancel={() => {
          setItemActionsState(prevState => ({ ...prevState, removeItemSheetVisible: false }));
        }}
        backDropCancel={true}
      />

      <Popup
        open={state.clearSeatActive}
        type="warning"
        title={t("secure.facility.table_service.table_service.194")}
        description={t("secure.facility.table_service.table_service.195")}
        onOk={() => {
          void handleClearCart();
          setState(prevState => ({ ...prevState, clearSeatActive: false }));
        }}
        okText={t("secure.facility.table_service.table_service.196")}
        cancelText={t("secure.facility.table_service.table_service.197")}
        onCancel={() => {
          setState(prevState => ({ ...prevState, clearSeatActive: false }));
        }}
        backDropCancel={true}
      />

      <Popup
        type="warning"
        open={state.closeSeatActive}
        title={t("secure.facility.table_service.table_service.198")}
        description={t("secure.facility.table_service.table_service.199")}
        closable
        onOk={handleSeatCartClose}
        okText={t("secure.facility.table_service.table_service.200")}
        backDropCancel
        cancelText={t("secure.facility.table_service.table_service.201")}
        onCancel={() => setState(prevState => ({ ...prevState, closeSeatActive: false }))}
      />

      <Popup
        type="warning"
        open={state.voidSeatActive}
        title={"Void Seat"}
        description={""}
        closable
        onOk={handleSeatCartVoid}
        okText={"OK"}
        backDropCancel
        cancelText={"Cancel"}
        onCancel={() => setState(prevState => ({ ...prevState, voidSeatActive: false }))}
        okDisabled={!state.cancelReason ? true : false}
      >
        <div>
          <Select
            label="Reason for Voiding Seat"
            defaultValue={"Server Error"}
            onChange={(value: string) => handleCancelReasonChange(value)}
          >
            {cancel_reasons.map((reason, index) => (
              <Option key={index} value={reason.title} name={reason.title}>
                {reason.title}
              </Option>
            ))}
          </Select>
        </div>
      </Popup>

      <Popup
        type="info"
        open={paymentState.changeDueVisible}
        title={`${t("secure.facility.table_service.table_service.202")}${paymentState.changeDue?.toFixed(2)}`}
        description=""
        closable
        onOk={() => setPaymentState(prevState => ({ ...prevState, changeDueVisible: false, changeDue: null }))}
        okText={t("secure.facility.table_service.table_service.203")}
        backDropCancel={false}
        stacked
      />

      <Popup
        type="warning"
        open={state.closeTableActive}
        title={t("secure.facility.table_service.table_service.204")}
        description={t("secure.facility.table_service.table_service.205")}
        closable
        onOk={handleCloseTable}
        okText={t("secure.facility.table_service.table_service.206")}
        cancelText={t("secure.facility.table_service.table_service.207")}
        onCancel={() => setState(prevState => ({ ...prevState, closeTableActive: false, seatsOpen: false }))}
        backDropCancel={false}
        okDisabled={state.seatsOpen}
      >
        {state.seatsOpen ? <div className="mt-4">There are still open seats</div> : null}
      </Popup>

      <OrderCompletePopup
        open={state.orderActionBarActive}
        setOpen={closeOrderCompletePopup}
        order={cartStore?.cart?.order}
      />

      {stripePaymentState.processing ? (
        <Popup
          type="info"
          open={stripePaymentState.sheetVisible}
          title={t("secure.facility.table_service.table_service.208")}
          description={t("secure.facility.table_service.table_service.209")}
          closable
          onCancel={stripePaymentState.onCancel}
          onOk={stripePaymentState.onOk}
          cancelText={stripePaymentState.cancelText}
          okText={stripePaymentState.okText}
          backDropCancel={false}
        />
      ) : (
        <>
          {stripePaymentState.success ? (
            <Popup
              type="success"
              open={stripePaymentState.sheetVisible}
              title={t("secure.facility.table_service.table_service.210")}
              description={t("secure.facility.table_service.table_service.211")}
              closable
              onCancel={stripePaymentState.onCancel}
              onOk={stripePaymentState.onOk}
              cancelText={stripePaymentState.cancelText}
              okText={stripePaymentState.okText}
              backDropCancel={false}
            />
          ) : (
            <Popup
              type="error"
              open={stripePaymentState.sheetVisible}
              title={t("secure.facility.table_service.table_service.212")}
              description={
                stripePaymentState.message +
                t("secure.facility.table_service.table_service.213") +
                stripePaymentState.convertedCode
              }
              closable
              onCancel={stripePaymentState.onCancel}
              onOk={stripePaymentState.onOk}
              cancelText={stripePaymentState.cancelText}
              okText={stripePaymentState.okText}
              backDropCancel={false}
            />
          )}
        </>
      )}

      <Popup
        type="warning"
        open={removeDiscountState.modalVisible}
        title={"Remove Discount Line"} // TODO: Translation
        description={"Are you sure you want to remove this discount?"} //TODO: Translation
        closable
        onOk={() => {
          void handleDiscountCancel();
        }}
        onCancel={() =>
          setRemoveDiscountState(prev => ({ ...prev, modalVisible: false, discountId: null, cartId: null }))
        }
        okText={"Remove"} // TODO: Translation
      />
    </>
  );
}
