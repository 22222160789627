import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useTranslation, Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { StatusCode } from "api/protocols";
import {
  DeletePricingSheet,
  GetPricingSheet,
  PostPricingSheetProduct,
  PutPricingSheet,
  DeletePricingSheetProduct,
  CopyPricingSheetProducts,
} from "api/rpc/2024-04/clientAdmin/teesheet/pricingSheet";
import { GetVariant } from "api/rpc/2022-09/clientAdmin/product/product";

import { Badge } from "components/badge/Badge";
import { showError, showSuccess } from "redux/actions/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import Checkbox from "components/form/checkbox/Checkbox";
import { ButtonNew as Button } from "components/buttonNew/index";
import Page from "components/page/Page";
import Card from "components/card/Card";
import Sheet from "components/sheet/Sheet";
import Input from "components/form/input/Input";
import { Select } from "components/select/index";
import FormLayout from "components/form/FormLayout";
import Popup from "components/popup/Popup";
import TimePick from "components/timePick/TimePick";

import "./pricingSheets.scss";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import ProductSelect from "components/productModal/ProductSelect";
import { IProduct, IVariant } from "redux/reducers/models/product";
import product from "containers/facility/product";
import { Day, DayTab, DayValue } from "pages/secure/facility/settings/teesheets/pricingSheets/TeeSheetPricingSheets";
import { convertTime } from "helpers/Helpers";

interface IPricingSheetState {
  id?: number;
  title?: string;
  course_id?: number;
  prevTitle?: string;
  start_time: string;
  end_time: string;
  day_of_week: string;
  deleteModalVisibility: boolean;
}

interface IPricingSheetList {
  // pricingSheets: Array<IPricingSheetList>;
  pricing_list: Array<IPricingSheetState>;
  selected_pricing_list: Array<number>;
  selectAllDelete: boolean;
}

interface IProductState {
  products: IProduct;
  variants: Array<Partial<IVariant>>;
  title: string;
  addProductVisibility: boolean;
  removeProductsVisibility: boolean;
  copyProductsVisibility: boolean;
  addedVariants: Array<Partial<IVariant>>;
  selectedVariants: Array<number>;
  selectedProducts: Array<IProduct>;
}

export default function PricingSheet() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { pricingSheetId }: any = useParams();
  const mounted = useRef(false);

  const clientFacilityStore = useAppSelector(store => store.clientFacilityStore);
  const dispatch = useAppDispatch();

  const { Option } = Select;
  const [filterDayState, setFilterDayState] = useState<DayValue>(Day.Monday);

  const [pricingSheetState, setPricingSheetState] = useState<IPricingSheetState>({
    id: undefined,
    title: "",
    course_id: undefined,
    prevTitle: "",
    start_time: "",
    end_time: "",
    day_of_week: "",
    deleteModalVisibility: false,
  });

  const [pricingSheetListState, setPricingSheetListState] = useState<IPricingSheetList>({
    pricing_list: [],
    selected_pricing_list: [],
    selectAllDelete: false,
  });

  const [productState, setProductState] = useState<IProductState>({
    products: null,
    variants: null,
    title: "",
    addProductVisibility: false,
    removeProductsVisibility: false,
    copyProductsVisibility: false,
    addedVariants: [],
    selectedVariants: [],
    selectedProducts: [],
  });

  const [filterState, setFilterState] = useState("");

  useEffect(() => {
    void loadPricingSheet();

    return () => {
      setPricingSheetState({
        id: undefined,
        title: "",
        course_id: undefined,
        prevTitle: "",
        start_time: "",
        end_time: "",
        day_of_week: "",
        deleteModalVisibility: false,
      });

      setProductState({
        products: null,
        variants: null,
        title: "",
        addProductVisibility: false,
        removeProductsVisibility: false,
        copyProductsVisibility: false,
        addedVariants: [],
        selectedVariants: [],
        selectedProducts: [],
      });
    };
  }, []);

  useEffect(() => {
    if (mounted.current) {
      history.push("/admin/settings/facility-settings/tee-sheet/pricing-sheet");
    } else {
      mounted.current = true;
    }
  }, [clientFacilityStore?.facility]);

  async function loadPricingSheet() {
    const pricingSheetRes = await GetPricingSheet(
      { id: pricingSheetId, facility_id: clientFacilityStore?.facility?.id, extended: true },
      true,
    );
    if (pricingSheetRes.status !== StatusCode.OK) {
      history.push("/admin/settings/facility-settings/tee-sheet/pricing-sheet");
      return;
    }

    // const variantRes = await GetVariant(
    //   { types: ["Green Fee", "Power Cart", "No Show Green Fee"], extended: true },
    //   true,
    // );
    // if (variantRes.status !== StatusCode.OK) {
    //   return;
    // }

    setPricingSheetState(prevState => ({
      ...prevState,
      id: pricingSheetRes.data[0].id,
      title: pricingSheetRes.data[0].title,
      course_id: pricingSheetRes.data[0].course_id,
      start_time: pricingSheetRes.data[0].start_time,
      end_time: pricingSheetRes.data[0].end_time,
      day_of_week: pricingSheetRes.data[0].day_of_week,
      prevTitle: pricingSheetRes.data[0].title,
      // products
    }));

    // const checkedVariants = new Array(variantRes.data.length).fill(false);
    // const pricingSheetVariants = pricingSheetRes.data[0].variants;
    // pricingSheetVariants.forEach((variant: IVariant) => {
    //   checkedVariants[variant.id - 1] = true;
    // });

    setProductState(prevState => ({
      ...prevState,
      // variants: variantRes.data,
      title: pricingSheetRes.data[0].title,
      addedVariants: pricingSheetRes.data[0].variants,
    }));
  }

  async function savePricingSheet() {
    //Add variants
    const variant_ids: number[] = [];
    productState.addedVariants?.forEach(variant => {
      variant_ids.push(variant.id);
    });

    const res = await PostPricingSheetProduct(
      {
        pricing_sheet_id: Number(pricingSheetId),
        variant_ids,
        facility_id: clientFacilityStore?.facility?.id,
      },
      true,
    );

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error adding products"));
      return;
    }

    //Update pricing sheet info
    const editRes = await PutPricingSheet(
      {
        id: Number(pricingSheetId),
        title: pricingSheetState.title,
        start_time: pricingSheetState.start_time,
        end_time: pricingSheetState.end_time,
        day_of_week: pricingSheetState.day_of_week,
      },
      true,
    );

    if (editRes.status !== StatusCode.OK) {
      dispatch(showError("Error updating pricing sheet"));
      return;
    }

    dispatch(showSuccess("Sucessfully updated pricing sheets"));

    history.push("/admin/settings/facility-settings/tee-sheet/pricing-sheet");
  }

  const primaryAction = {
    content: "Save",
    action: savePricingSheet,
  };

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;
    setPricingSheetState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleSelectChange(value: string, id: string) {
    setPricingSheetState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleSelectVariant(variant_id: number) {
    const selectedDeleteVariant = [...productState.selectedVariants];
    const foundIndex = selectedDeleteVariant.indexOf(variant_id);
    const everyVariantID = productState.addedVariants.length;

    if (foundIndex === -1) {
      selectedDeleteVariant.push(variant_id);
    } else {
      selectedDeleteVariant.splice(foundIndex, 1);
    }

    selectedDeleteVariant.length === everyVariantID
      ? setPricingSheetListState(prevState => ({ ...prevState, selectAllDelete: true }))
      : setPricingSheetListState(prevState => ({ ...prevState, selectAllDelete: false }));

    setProductState(prevState => ({ ...prevState, selectedVariants: selectedDeleteVariant }));
  }

  function handleCheckboxClick(sheet_id: number) {
    const pricingSheetList = [...pricingSheetListState.selected_pricing_list];
    const foundIndex = pricingSheetList.indexOf(sheet_id);

    if (foundIndex === -1) {
      pricingSheetList.push(sheet_id);
    } else {
      pricingSheetList.splice(foundIndex, 1);
    }
    setPricingSheetListState(prevState => ({ ...prevState, selected_pricing_list: pricingSheetList }));
  }

  function handleAddProductsVisibility() {
    setProductState(prevState => ({ ...prevState, addProductVisibility: !prevState.addProductVisibility }));
  }

  function handleDeleteProductsVisibility() {
    setProductState(prevState => ({ ...prevState, removeProductsVisibility: !prevState.removeProductsVisibility }));
  }

  function handleCopyProductsVisibility() {
    if (!productState.copyProductsVisibility && pricingSheetListState.pricing_list.length <= 0) {
      void loadAllPricingSheets();
    }
    setProductState(prevState => ({ ...prevState, copyProductsVisibility: !prevState.copyProductsVisibility }));
  }

  const addProductsAction = {
    content: "Add Products",
    action: handleAddProductsVisibility,
  };

  function handleFilterChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setFilterState(value);
  }

  function selectAllProducts() {
    const everyVariantID = productState.addedVariants.map(variants => {
      return variants?.id;
    });

    !pricingSheetListState.selectAllDelete
      ? setProductState(prevState => ({ ...prevState, selectedVariants: everyVariantID }))
      : setProductState(prevState => ({ ...prevState, selectedVariants: [] }));

    setPricingSheetListState(prevState => ({ ...prevState, selectAllDelete: !prevState.selectAllDelete }));
  }

  async function postPricingSheetProduct() {
    const variantIds: Array<number> = [];
    productState?.selectedProducts?.forEach(product => {
      variantIds.push(...product?.variants?.map(variant => variant.id));
    });

    if (variantIds?.length <= 0) {
      dispatch(showError("A product must be selected")); // TODO: Translation
      return;
    }
    const postRes = await PostPricingSheetProduct(
      {
        pricing_sheet_id: pricingSheetId,
        variant_ids: variantIds,
        facility_id: clientFacilityStore?.facility?.id,
      },
      true,
    );

    if (postRes.status !== StatusCode.OK) {
      dispatch(showError(postRes.data.message));
    }

    setProductState(prevState => ({ ...prevState, selectedProducts: [] }));
    void loadPricingSheet();
    void handleAddProductsVisibility();
  }

  const deletePricingSheet = {
    content: "Delete",
    action: () => setPricingSheetState(prevState => ({ ...prevState, deleteModalVisibility: true })),
  };

  async function deleteProducts() {
    if (productState.selectedVariants.length === 0) {
      dispatch(showError("Products must be selected to delete"));
      return;
    }
    const res = await DeletePricingSheetProduct(
      {
        pricing_sheet_id: Number(pricingSheetId),
        variant_id: productState.selectedVariants,
      },
      true,
    );

    if (res.status !== StatusCode.OK) {
      dispatch(showError(res.data.message));
      return;
    }

    setProductState(prevState => ({ ...prevState, selectedVariants: [] }));
    void loadPricingSheet();
    void handleDeleteProductsVisibility();
  }

  async function handleDeletePricingSheet() {
    const deleteRes = await DeletePricingSheet({ id: Number(pricingSheetId) }, true);
    if (deleteRes.status !== StatusCode.OK) {
      dispatch(showError("Error deleting pricing sheet"));
      return;
    }
    dispatch(showSuccess("Successfully deleted pricing sheet"));
    history.push("/admin/settings/facility-settings/tee-sheet/pricing-sheet");
  }

  async function copyProductsToPricingSheet() {
    const postRes = await CopyPricingSheetProducts(
      {
        facility_id: clientFacilityStore.facility.id,
        pricing_sheet_ids: pricingSheetListState.selected_pricing_list,
        variant_ids: productState.selectedVariants,
      },
      true,
    );

    if (postRes.status !== StatusCode.OK) {
      dispatch(showError("Error copying products to pricing sheet")); // TODO: Translation
      return;
    }
    void handleCopyProductsVisibility();
    dispatch(showSuccess("Products successfully copied")); // TODO: Translation
  }

  async function loadAllPricingSheets() {
    const pricingSheetRes = await GetPricingSheet({ facility_id: clientFacilityStore?.facility?.id }, false);

    setPricingSheetListState(prevState => ({
      ...prevState,
      pricing_list: pricingSheetRes.data,
    }));
  }

  console.log("pricingSheetListState", pricingSheetListState);
  return (
    <>
      <Page
        title={pricingSheetState.prevTitle}
        narrow
        primaryAction={primaryAction}
        secondaryActions={[deletePricingSheet]}
        breadcrumbs={[
          {
            prefix: true,
            label: "Back to Pricing Sheets",
            url: "/admin/settings/facility-settings/tee-sheet/pricing-sheet",
          },
        ]}
      >
        <Card>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={pricingSheetState.title}
                  label={"Title"}
                  id="title"
                  onChange={handleInputChange}
                  placeholder={"Title"}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                {clientFacilityStore.facility?.courses?.length > 1 && (
                  <Select
                    onChange={(value: string) => handleSelectChange(value, "course_id")}
                    label={"Course"}
                    defaultValue={pricingSheetState.course_id}
                    allowClear
                  >
                    {clientFacilityStore.facility?.courses?.map((course: Record<string, any>, index: number) => {
                      return (
                        <Option key={index} value={course.id}>
                          {course.long_name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </FormLayout.Group>
              <FormLayout.Group>
                <Select
                  onChange={(value: string) => handleSelectChange(value, "day_of_week")}
                  label={"Day of Week"}
                  defaultValue={pricingSheetState.day_of_week}
                >
                  <Option value="Monday">{"Monday"}</Option>
                  <Option value="Tuesday">{"Tuesday"}</Option>
                  <Option value="Wednesday">{"Wednesday"}</Option>
                  <Option value="Thursday">{"Thursday"}</Option>
                  <Option value="Friday">{"Friday"}</Option>
                  <Option value="Saturday">{"Saturday"}</Option>
                  <Option value="Sunday">{"Sunday"}</Option>
                  <Option value="Holiday">{"Holiday"}</Option>
                </Select>
              </FormLayout.Group>
            </FormLayout>
            <FormLayout>
              <FormLayout.Group>
                <TimePick
                  value={pricingSheetState.start_time}
                  onChange={timeString => setPricingSheetState(prev => ({ ...prev, start_time: timeString }))}
                  label={"Start Time"} // TODO: Translation
                  size="large"
                  status={pricingSheetState.start_time === undefined ? "warning" : undefined}
                />
                <TimePick
                  value={pricingSheetState.end_time}
                  onChange={timeString => setPricingSheetState(prev => ({ ...prev, end_time: timeString }))}
                  label={"End Time"} // TODO: Translation
                  size="large"
                  status={pricingSheetState.end_time === undefined ? "warning" : undefined}
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
          <Card.Section>
            <div className="header">
              <div className="title-header">Products</div>
              <div className="btn-header">
                <Button className="ml-2" type="secondary" size="xsmall" onClick={handleAddProductsVisibility}>
                  <FontAwesomeIcon icon={["fas", "plus"]} className="mr-2" /> <span>Add</span>
                </Button>

                <Button className="ml-2" type="secondary" size="xsmall" onClick={handleDeleteProductsVisibility}>
                  <FontAwesomeIcon icon={["far", "trash-can"]} className="mr-2" /> <span>Remove</span>
                </Button>

                <Button className="ml-2" type="secondary" size="xsmall" onClick={handleCopyProductsVisibility}>
                  <FontAwesomeIcon icon={["fas", "copy"]} className="mr-2" /> <span>Copy</span>
                </Button>
              </div>
            </div>

            <DataTable
              columns={[
                {
                  label: "",
                  content: (
                    <div className="flex">
                      <Checkbox
                        size="small"
                        onChange={selectAllProducts}
                        checked={pricingSheetListState.selectAllDelete}
                      />
                    </div>
                  ),
                },
                { label: "Product Title" },
                { label: "Variant Title" },
                { label: "Type" },
              ]}
            >
              {productState.addedVariants?.map((variant: any, index: number) => {
                return (
                  <tr onClick={() => handleSelectVariant(variant.id)} key={index}>
                    <td onClick={(e: React.MouseEvent) => e.stopPropagation()} className="table-cell-narrow">
                      <Checkbox
                        onChange={() => handleSelectVariant(variant.id)}
                        size="small"
                        checked={productState?.selectedVariants?.includes(variant.id) ? true : false}
                      />
                    </td>
                    <td>{variant.product.title}</td>
                    <td>{variant.title}</td>
                    <td>{variant.product.type}</td>
                  </tr>
                );
              })}
            </DataTable>
          </Card.Section>
        </Card>
      </Page>

      <Sheet
        closable
        onCancel={handleAddProductsVisibility}
        size="medium"
        open={productState.addProductVisibility}
        cancelText={"Close"}
        okText={"Add"}
        title={"Add Products"}
        onOk={postPricingSheetProduct}
      >
        <div className="overflow-y-scroll">
          <ProductSelect
            selectedProducts={productState.selectedProducts}
            limit={50}
            search
            onChange={products => setProductState(prevState => ({ ...prevState, selectedProducts: products }))}
            getProductsParams={{ types: ["Green Fee", "Power Cart"] }}
          />
        </div>
      </Sheet>

      <Sheet
        closable
        title={"Copy Products to Pricing Sheet"}
        size="medium"
        open={productState.copyProductsVisibility}
        cancelText={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheet.025")}
        onCancel={handleCopyProductsVisibility}
        okText={"Continue"}
        onOk={copyProductsToPricingSheet}
      >
        <Card>
          <div className="flex">
            <DayTab
              title={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.025")}
              value={Day.Monday}
              compare={filterDayState}
              onClick={value => setFilterDayState(value)}
            />
            <DayTab
              title={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.026")}
              value={Day.Tuesday}
              compare={filterDayState}
              onClick={value => setFilterDayState(value)}
            />
            <DayTab
              title={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.027")}
              value={Day.Wednesday}
              compare={filterDayState}
              onClick={value => setFilterDayState(value)}
            />
            <DayTab
              title={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.028")}
              value={Day.Thursday}
              compare={filterDayState}
              onClick={value => setFilterDayState(value)}
            />
            <DayTab
              title={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.029")}
              value={Day.Friday}
              compare={filterDayState}
              onClick={value => setFilterDayState(value)}
            />
            <DayTab
              title={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.030")}
              value={Day.Saturday}
              compare={filterDayState}
              onClick={value => setFilterDayState(value)}
            />
            <DayTab
              title={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.031")}
              value={Day.Sunday}
              compare={filterDayState}
              onClick={value => setFilterDayState(value)}
            />
            <DayTab
              title={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.032")}
              value={Day.Holiday}
              compare={filterDayState}
              onClick={value => setFilterDayState(value)}
            />
          </div>
          <table className="ui-table ui-table-clickable">
            <thead>
              <tr>
                <th></th>
                <th>{t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.033")}</th>
                <th>{t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.034")}</th>
                <th>{t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.035")}</th>
                <th>{t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.036")}</th>
                <th>{t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.037")}</th>
                <th>{t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.038")}</th>
              </tr>
            </thead>
            <tbody>
              {pricingSheetListState.pricing_list ? (
                <>
                  {pricingSheetListState.pricing_list.map((pricingSheet: any, index: number) => {
                    return pricingSheet.day_of_week_number === filterDayState ? (
                      <tr key={index} onClick={() => handleCheckboxClick(pricingSheet.id)}>
                        <td onClick={(e: React.MouseEvent) => e.stopPropagation()} className="table-cell-narrow">
                          <Checkbox
                            onChange={() => handleCheckboxClick(pricingSheet.id)}
                            size="medium"
                            checked={pricingSheetListState?.selected_pricing_list?.includes(pricingSheet.id)}
                          />
                        </td>
                        <td>{pricingSheet.title}</td>
                        <td>{pricingSheet.course?.full_name}</td>
                        <td>
                          {pricingSheet.application === "green_fee" ? (
                            <Badge size="medium" type="success">
                              {t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.039")}
                            </Badge>
                          ) : null}
                          {pricingSheet.application === "power_cart" ? (
                            <Badge size="medium" type="success">
                              {t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.040")}
                            </Badge>
                          ) : null}
                        </td>
                        <td>{convertTime(pricingSheet.start_time)}</td>
                        <td>{convertTime(pricingSheet.end_time)}</td>
                        <td>{pricingSheet.day_of_week}</td>
                      </tr>
                    ) : null;
                  })}
                </>
              ) : null}
            </tbody>
          </table>
        </Card>
      </Sheet>

      {/* Remove Products Sheet */}
      <Popup
        open={productState.removeProductsVisibility}
        type="warning"
        title="Remove Products"
        description={"Are you sure you want to remove the pricing sheet products?"}
        onOk={() => deleteProducts()}
        okText="Delete"
        onCancel={() => handleDeleteProductsVisibility()}
        backDropCancel={true}
      />

      <Popup
        open={pricingSheetState.deleteModalVisibility}
        type="warning"
        title="Delete Pricing Sheet?"
        description="Are you sure you want to delete this pricing sheet?"
        onCancel={() => setPricingSheetState(prevState => ({ ...prevState, deleteModalVisibility: false }))}
        onOk={handleDeletePricingSheet}
      />
    </>
  );
}
