import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { StatusCode } from "api/protocols";
import { GetValidateReset, PutResetPassword } from "api/rpc/2022-09/passwordReset";
import { GetFacility } from "api/rpc/guest/facility";

import { showError } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";

import Popup from "components/popup/Popup";
import Input from "components/form/input";
import { ButtonNew as Button } from "components/buttonNew";

import "pages/guest/Customer/NewPassword.scss";

export interface ISignInInfo {
  password: string;
  confirmPassword: string;
  resetCodeValid: boolean;
  resetPasswordSuccess: boolean;
}

const NewPassword = () => {
  const dispatch = useAppDispatch();

  //GET Email and Reset Code From URL
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const email = urlParams.get("email");
  const code = urlParams.get("code");
  const facilityShortName = urlParams.get("shortname");

  const [signInInfo, setSignInInfo] = useState<ISignInInfo>({
    password: "",
    confirmPassword: "",
    resetCodeValid: null,
    resetPasswordSuccess: false,
  });

  const [facility, setFacility] = useState<Record<string, any>>(null);
  const [validatorState, setValidatorState] = useState<{ passwordLength: boolean; passwordMatch: boolean }>({
    passwordLength: false,
    passwordMatch: false,
  });

  useEffect(() => {
    const validateResetCode = async () => {
      const res = await GetValidateReset(
        {
          email,
          code,
        },
        true,
      );

      if (res.status === StatusCode.OK) {
        updateSignInInfo({ resetCodeValid: true });
      } else {
        updateSignInInfo({ resetCodeValid: false });
      }
      await getFacility();
    };

    void validateResetCode();
  }, []);

  async function getFacility() {
    if (facilityShortName) {
      const facilityRes = await GetFacility({ short_name: facilityShortName }, true);
      if (facilityRes.status !== StatusCode.OK) {
        dispatch(showError("Error loading facility")); // TODO: Translation
        return;
      }
      setFacility(facilityRes?.data[0]);
    }
  }

  function handleEnterKeydown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      void sendNewPassword();
    }
  }

  function updateSignInInfo(newFilterState: Partial<ISignInInfo>) {
    setSignInInfo(cur => {
      return { ...cur, ...newFilterState };
    });
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    updateSignInInfo({ [name]: value });
  }

  async function sendNewPassword() {
    if (!signInInfo.password || !signInInfo.confirmPassword) {
      dispatch(showError("Please fill out all fields")); // TODO: Translation
      return;
    }

    const params = {
      email,
      password: signInInfo.password,
      code,
    };

    const invalidPasswordLength = signInInfo.password?.length < 8;
    const invalidPasswordMatch = signInInfo.password !== signInInfo.confirmPassword;

    if (invalidPasswordLength || invalidPasswordMatch) {
      setValidatorState(prevState => ({
        ...prevState,
        passwordLength: invalidPasswordLength,
        passwordMatch: invalidPasswordMatch,
      }));
      return;
    }

    const resetPasswordRes = await PutResetPassword(params, true);

    if (resetPasswordRes.status === StatusCode.OK) {
      updateSignInInfo({
        resetPasswordSuccess: true,
      });
    } else {
      dispatch(showError("Error resetting password")); // TODO: Translation
      return;
    }
  }

  if (signInInfo.resetCodeValid !== null) {
    return (
      <div className="newpassword-box">
        <div className="newpassword-login-container">
          <div className="newpassword-sign-in-input-div">
            {facility?.logo_source && <img className="facility-logo" src={facility?.logo_source} alt="Facility Logo" />}
            <div className="newpassword-sign-in-text">
              <p className="sign-in-p">Account Recovery</p> {/* TODO: Translation */}
              {signInInfo.resetCodeValid ? (
                <>
                  <br />
                  <p className="sign-in-info-p">Please enter your new password for</p>
                  <p className="sign-in-info-p font-bold">{email}</p>
                </>
              ) : (
                <p className="newpassword-invalid-text">Invalid code or email</p>
              )}
            </div>
            <br />

            {validatorState.passwordLength || validatorState.passwordMatch ? (
              <div className="error-container">
                {validatorState.passwordLength && (
                  <div className="error-container-row">
                    <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="error-container-icon" />
                    <p>Password must be at least 8 characters long</p> {/* TODO: Translation */}
                  </div>
                )}
                {validatorState.passwordMatch && (
                  <div className="error-container-row">
                    <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="error-container-icon" />
                    <p>Passwords do not match</p> {/* TODO: Translation */}
                  </div>
                )}
              </div>
            ) : null}

            {signInInfo.resetCodeValid && (
              <div className="newpassword-sign-in-input-div">
                <Input.Password
                  value={signInInfo.password}
                  onChange={handleInputChange}
                  name="password"
                  onKeyDown={handleEnterKeydown}
                  label="Password" // TODO: Translation
                />
                <br />
                <Input.Password
                  value={signInInfo.confirmPassword}
                  onChange={handleInputChange}
                  name="confirmPassword"
                  onKeyDown={handleEnterKeydown}
                  label="Confirm Password" // TODO: Translation
                />
              </div>
            )}
          </div>
          <br />
          <br />

          {signInInfo.resetCodeValid && (
            <Button block onClick={sendNewPassword} type="primary" size="medium">
              UPDATE PASSWORD {/* TODO: Translation */}
            </Button>
          )}
        </div>

        <Popup
          open={signInInfo.resetPasswordSuccess}
          type="success"
          title="Password Reset Successfully" // TODO: Translation
          description="Please return to the login screen to login with your new password" // TODO: Translation
          onOk={() => window.close()}
          okText="Close"
          backDropCancel={false}
        />
      </div>
    );
  } else {
    return null;
  }
};

export default NewPassword;
