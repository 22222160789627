import React from "react";
import "./PaymentSummary.scss";

export interface IPaymentSummaryData {
  label: string | JSX.Element;
  values: string[];
  editValues?: () => void;
}

interface IPaymentSummaryProps {
  summaryData: IPaymentSummaryData[];
  disableEdit?: boolean;
}

export default function PaymentSummary(props: IPaymentSummaryProps) {
  const { summaryData, disableEdit } = props;

  return (
    <div className="payment-summary">
      {summaryData.map((summary, index) => {
        return (
          <React.Fragment key={index}>
            <div className="payment-summary-detail">
              <div className="payment-summary-content-container">
                <div className="payment-summary-label">{summary.label}</div>
                <div className="payment-summary-value-container">
                  {summary?.values
                    ? summary.values.map((value, index) => {
                        return value ? (
                          <div key={index} className="payment-summary-value">
                            {value}
                          </div>
                        ) : (
                          <div key={index} />
                        );
                      })
                    : null}
                </div>
              </div>
              {summary.editValues ? (
                <div>
                  <button className="payment-summary-edit" onClick={summary.editValues} disabled={disableEdit}>
                    Edit
                  </button>
                </div>
              ) : (
                <div />
              )}
            </div>
            {index !== summaryData.length - 1 && <hr className="payment-summary-dividing-line" />}
          </React.Fragment>
        );
      })}
    </div>
  );
}
