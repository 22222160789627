// import { LocalStorage } from "api/localstorage";
// import { ICartState } from "reducers/cart";
import { StatusCode } from "api/protocols";
import { formatDate } from "helpers/Helpers";
import { TeesheetActionTypes } from "../actionTypes";
import { AppDispatch } from "index";
import { ITeeTime } from "redux/reducers/models/teetime";
import { IGetTeeTimeRes } from "api/rpc/2022-09/clientAdmin/teeSheets/teeSheet";
import { GetTeeTime } from "api/rpc/2024-04/clientAdmin/teesheet/teeSheet";

export function selectSideMenu(menu: string) {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: TeesheetActionTypes.UPDATE_SELECTED_MENU_CLIENT,
      payload: {
        selectedMenu: menu,
      },
    });
  };
}

const loadTeeTimesSuccess = (teetimes: IGetTeeTimeRes) => {
  return {
    type: TeesheetActionTypes.LOAD_TEETIMES_SUCCESS_CLIENT,
    payload: teetimes.data,
  };
};

const loadTeetimesError = (error: unknown) => {
  console.log("Error loading teetimes", error);
  return {
    type: TeesheetActionTypes.LOAD_TEETIMES_ERROR_CLIENT,
  };
};

export const loadTeetimes = (date: Date, facility: number, limited?: boolean) => async (dispatch: AppDispatch) => {
  const formattedDate = formatDate(date);
  try {
    const teetimesRes = await GetTeeTime({ date: formattedDate, facility_id: facility, extended: true, limited }, true);

    if (teetimesRes.status !== StatusCode.OK) {
      return;
    }

    return dispatch(loadTeeTimesSuccess(teetimesRes));
  } catch (e) {
    return dispatch(loadTeetimesError(e));
  }
};

/**
 * will store selected date to teesheetStore
 * @param date
 */
export const selectDate = (date: Date) => (dispatch: AppDispatch) => {
  return dispatch({
    type: TeesheetActionTypes.SELECT_DATE_CLIENT,
    payload: date,
  });
};

// Retrieve tee time to make sure it is still available
export const selectTeeTimeSuccess = (teetime: ITeeTime) => {
  return {
    type: TeesheetActionTypes.UPDATE_SELECTED_TEETIME_SUCCESS_CLIENT,
    payload: { selectedTeeTime: teetime },
  };
};

const selectTeeTimeError = (error: unknown) => {
  console.log("Error selecting teetime", error);
  return {
    type: TeesheetActionTypes.UPDATE_SELECTED_TEETIME_ERROR_CLIENT,
  };
};

export const selectTeeTime =
  (teetimeId?: number, facility?: number, extended?: boolean, useGlobalLoader = true) =>
  async (dispatch: AppDispatch) => {
    try {
      const teetimeRes = await GetTeeTime(
        {
          id: teetimeId,
          facility_id: facility,
          extended: extended,
          "extended-bookings": extended,
          locks: true,
          turn_tee_time: true,
        },
        useGlobalLoader,
      );

      const teetime = teetimeRes.data[0];

      // Create a new tee time lock to hold the tee time
      // if (teetime.lock === null) {
      //   const lockRes = await PostTeeTimeLock({ tee_time_id: teetime.id }, false);
      //   teetime.lock = lockRes.data;
      // }

      return dispatch(selectTeeTimeSuccess(teetime));
    } catch (e) {
      return dispatch(selectTeeTimeError(e));
    }
  };

export function updateTemporaryBooking(temporaryBooking: any) {
  console.log("Update temporary booking");

  return (dispatch: AppDispatch) => {
    dispatch({
      type: TeesheetActionTypes.UPDATE_BOOKING_TEMPORARY_CLIENT,
      payload: {
        temporaryBooking: temporaryBooking,
      },
    });
  };
}

export function deSelectTeeTime() {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: TeesheetActionTypes.UPDATE_SELECTED_TEETIME_SUCCESS_CLIENT,
      payload: { selectedTeeTime: null },
    });
  };
}

export interface ITeeSheetActions {
  selectSideMenu: typeof selectSideMenu;
  selectTeeTime: typeof selectTeeTime;
  updateTemporaryBooking: typeof updateTemporaryBooking;
  loadTeetimes: typeof loadTeetimes;
  selectDate: typeof selectDate;
  deSelectTeeTime: typeof deSelectTeeTime;
  selectTeeTimeSuccess: typeof selectTeeTimeSuccess;
}
