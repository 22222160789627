import { APIGet, APIPost, APIPut, APIDelete, APIUrl, AdminType } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/product";

export type TPostProductGroup = { title: string; type: string; parent_id?: number };

// GET Product Groups
export async function GetProductGroups(params: { id?: number }, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(APIUrl(AdminType.FacilityAdmin, "/product/group"), params, useGlobalLoader, token);
}
export async function PostProductGroup(params: TPostProductGroup, useGlobalLoader: boolean) {
  return await APIPost(APIUrl(AdminType.FacilityAdmin, "/product/group"), params, useGlobalLoader);
}
