import React, { useState, useContext } from "react";
import classNames from "classnames";
import { GroupContext } from "./Group";

interface IContainerProps {
  prefixCls?: string;
  className?: string;
  row?: string;
}

const Container: React.FC<IContainerProps> = props => {
  const {
    children,
    prefixCls = "teesheet-card-container grid gap-4 auto-cols-auto grid-cols-4",
    className,
    row,
  } = props;

  const groupStore = useContext(GroupContext);

  const clonedChildren = React.Children.map(children, (child, i) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { row });
    }
  });

  const cls = classNames(
    prefixCls,
    {
      [`${prefixCls}-selected`]: groupStore.state.row === row,
    },
    className,
  );

  return <div className={cls}>{clonedChildren}</div>;
};

export default Container;
