import React, { useEffect, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router";
import { useAppDispatch } from "hooks/redux";
import useModal from "hooks/modals/useModal";
import axios, { CancelToken } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  GetSeasonLeaderboard,
  GetSeasonLeaderboardTotals,
  GetSeasonLeaderboardPrint,
  PutSeasonLeaderboard,
  TPutSeasonLeaderboard,
  DeleteSeasonLeaderboard,
} from "api/rpc/2024-04/facilityAdmin/league/scoring/leaderboard";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import Portal from "elements/Portal";
import Sheet from "components/sheet/Sheet";
import Input from "components/form/input";
import { Select } from "components/select";
import Popup from "components/popup/Popup";
import { TLeagueSeasonLeaderboard, TLeagueSeasonLeaderboardTotal } from "redux/reducers/models/league";
import { showError, showSuccess } from "redux/actions/ui";

interface ILeaderboardTotalsState {
  defaultSorted: boolean;
  sortByType: string;
  sortByDirection: boolean;
}

export default function LeaderboardTotals() {
  const { leagueId, leaderboardId } = useParams<{ leagueId: string; leaderboardId: string }>();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [state, setState] = useState<ILeaderboardTotalsState>({
    defaultSorted: false,
    sortByType: "default",
    sortByDirection: false,
  });

  const [leaderboard, setLeaderboard] = useState<TLeagueSeasonLeaderboard>(undefined);
  const [totals, setTotals] = useState<Array<TLeagueSeasonLeaderboardTotal>>(undefined);

  const {
    state: editModal,
    updateModal: updateEditModal,
    closeModal: closeEditModal,
  } = useModal<TPutSeasonLeaderboard>({
    season_leaderboard_id: leaderboardId,
    title: "",
    best_scores: null,
  });

  const { state: deleteModal, updateModal: updateDeleteModal, closeModal: closeDeleteModal } = useModal();

  useEffect(() => {
    const source = axios.CancelToken.source();

    void loadLeaderboard(source.token);
    void loadLeaderboardTotals(source.token);

    return () => source.cancel();
  }, []);

  useEffect(() => {
    if (!state.defaultSorted && totals) {
      void sortByDefault();
    }
  }, [totals]);

  async function loadLeaderboard(token?: CancelToken) {
    const leaderboardRes = await GetSeasonLeaderboard(
      { league_id: leagueId, id: leaderboardId },
      token ? false : true,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (leaderboardRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading leaderboard")); // TODO: Translation
      return;
    }

    setLeaderboard(leaderboardRes.data[0]);
    updateEditModal({
      title: leaderboardRes.data[0].title,
      best_scores: leaderboardRes.data[0].best_scores,
    });
  }

  async function loadLeaderboardTotals(token?: CancelToken) {
    const totalRes = await GetSeasonLeaderboardTotals(
      { season_leaderboard_id: leaderboardId },
      token ? false : true,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (totalRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading leaderboard totals")); // TODO: Translation
      return;
    }

    setTotals(totalRes.data);
  }

  async function printLeaderboard() {
    const printRes = await GetSeasonLeaderboardPrint({ season_leaderboard_id: leaderboardId }, true);

    if (printRes.status === StatusCode.OK) {
      window.open().document.write(printRes.data);
    } else {
      dispatch(showError("Error printing season leaderboard")); // TODO: Translation
      return;
    }
  }

  async function updateSeasonLeaderboard() {
    const updateRes = await PutSeasonLeaderboard(
      {
        season_leaderboard_id: leaderboardId,
        title: editModal.title,
        best_scores: editModal.best_scores,
      },
      true,
    );

    if (updateRes.status !== StatusCode.OK) {
      dispatch(showError("Error updating season leaderboard")); // TODO: Translation
      return;
    }

    closeEditModal();
    dispatch(showSuccess("Season leaderboard updated successfully")); // TODO: Translation
    void loadLeaderboard();
  }

  async function deleteSeasonLeaderboard() {
    const deleteRes = await DeleteSeasonLeaderboard(
      {
        season_leaderboard_id: leaderboardId,
      },
      true,
    );

    if (deleteRes.status !== StatusCode.OK) {
      dispatch(showError("Error deleting season leaderboard")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Season leaderboard deleted successfully")); // TODO: Translation
    history.push(`/admin/league/${leagueId}/scoring/leaderboards`);
  }

  function sortLeaderboard(sortType: "default" | "bonus" | "participation" | "total" | "overall", sortValue: string) {
    const currentTotals = [...totals];
    let direction: boolean = state.sortByDirection;
    if (state.sortByType !== sortType) {
      direction = true;
    } else if (direction === false) {
      //Descending order, return to default order
      void sortByDefault();
      return;
    } else {
      direction = !direction;
    }

    const sortedTotals = currentTotals.sort((currentTotal, nextTotal) => {
      //Sort null values last
      if (!currentTotal?.[sortValue]) {
        return 1;
      } else if (!nextTotal?.[sortValue]) {
        return -1;
      } else if (sortType == "overall") {
        const currentPlayerPoints = currentTotal?.total + currentTotal?.bonus + currentTotal?.participation;
        const nextPlayerPoints = nextTotal?.total + nextTotal?.bonus + nextTotal?.participation;
        return direction ? nextPlayerPoints - currentPlayerPoints : currentPlayerPoints - nextPlayerPoints;
      } else {
        return direction
          ? nextTotal?.[sortValue] - currentTotal?.[sortValue]
          : currentTotal?.[sortValue] - nextTotal?.[sortValue];
      }
    });
    setState(prevState => ({ ...prevState, sortByType: sortType, sortByDirection: direction }));
    setTotals(sortedTotals);
  }

  function sortByDefault() {
    if (!totals) {
      return;
    }
    const currentTotals = [...totals];
    const sortedTotals = currentTotals?.sort((currentTotal, nextTotal) => {
      if (!currentTotal?.total) {
        return 1;
      } else if (!nextTotal?.total) {
        return -1;
      } else {
        const currentPlayerPoints = currentTotal?.total + currentTotal?.bonus + currentTotal?.participation;
        const nextPlayerPoints = nextTotal?.total + nextTotal?.bonus + nextTotal?.participation;
        return nextPlayerPoints - currentPlayerPoints;
      }
    });

    setState(prevState => ({
      ...prevState,
      sortByType: "overall",
      sortByDirection: true,
      defaultSorted: true,
    }));

    setTotals(sortedTotals);
  }

  const primaryAction = {
    content: "Print", // TODO: Translation
    action: printLeaderboard,
  };

  return (
    <Page
      title={leaderboard?.title ?? ""}
      breadcrumbs={[
        {
          prefix: true,
          label: "Leaderboards", // TODO: Translation
          url: `/admin/league/${leagueId}/scoring/leaderboards`,
        },
      ]}
      primaryAction={primaryAction}
      multipleActionDropdownAction={{
        label: "Options",
        dropdownProps: {
          alignment: "right",
          options: [
            {
              type: "handler",
              label: "Edit",
              handler: () => updateEditModal({ isOpen: true }),
              icon: "pencil",
            },
            {
              type: "handler",
              label: "Delete",
              handler: () => updateDeleteModal({ isOpen: true }),
              icon: "trash",
            },
          ],
        },
      }}
    >
      <DataTable
        columns={[
          { label: "Rank", width: "5%" }, // TODO: Translation
          { label: leaderboard?.organization === "team" ? "Team" : "Player" }, // TODO: Translation
          {
            label: "bonus",
            content: (
              <p onClick={() => sortLeaderboard("bonus", "bonus")} className="leaderboard-header">
                {"Bonus"} {/* TODO: Translation */}
                <FontAwesomeIcon
                  style={{ visibility: state?.sortByType === "bonus" ? "visible" : "hidden" }}
                  icon={["far", state?.sortByDirection ? "arrow-down-long" : "arrow-up-long"]}
                />
              </p>
            ),
            width: "11%",
          },
          {
            label: "participation",
            content: (
              <p onClick={() => sortLeaderboard("participation", "participation")} className="leaderboard-header">
                {"Participation"} {/* TODO: Translation */}
                <FontAwesomeIcon
                  style={{ visibility: state?.sortByType === "participation" ? "visible" : "hidden" }}
                  icon={["far", state?.sortByDirection ? "arrow-down-long" : "arrow-up-long"]}
                />
              </p>
            ),
            width: "11%",
          },
          {
            label: "season",
            content: (
              <p onClick={() => sortLeaderboard("total", "total")} className="leaderboard-header">
                {"Season"} {/* TODO: Translation */}
                <FontAwesomeIcon
                  style={{ visibility: state?.sortByType === "total" ? "visible" : "hidden" }}
                  icon={["far", state?.sortByDirection ? "arrow-down-long" : "arrow-up-long"]}
                />
              </p>
            ),
            width: "11%",
          },
          {
            label: "overall",
            content: (
              <p onClick={() => sortLeaderboard("overall", "overall")} className="leaderboard-header">
                {"Total"} {/* TODO: Translation */}
                <FontAwesomeIcon
                  style={{ visibility: state?.sortByType === "overall" ? "visible" : "hidden" }}
                  icon={["far", state?.sortByDirection ? "arrow-down-long" : "arrow-up-long"]}
                />
              </p>
            ),
            width: "11%",
          },
        ]}
        loading={totals === undefined}
      >
        {totals?.map((total, index) => (
          <tr key={total.id}>
            <td>{index + 1}</td>
            <td>{total.customer ? total.customer.full_name : total.team?.name}</td>
            <td>{total.bonus}</td>
            <td>{total.participation}</td>
            <td>{total.total}</td>
            <td>{total.total + total.participation + total.bonus}</td>
          </tr>
        ))}
      </DataTable>

      <Portal isMounted={editModal.isOpen}>
        <Sheet
          open={editModal.isOpen}
          title="Edit Leaderboard" // TODO: Translation
          onCancel={closeEditModal}
          onOk={updateSeasonLeaderboard}
          okText="Update" // TODO: Translation
          size="small"
          overflow
        >
          <Input
            className="mb-2"
            label={"Title"} // TODO: Translation
            value={editModal.title}
            onChange={e => updateEditModal({ title: e.target.value })}
          />
          <Input
            className="mb-2"
            label={"Scores to Count"} // TODO: Translation
            value={editModal.best_scores}
            onChange={e => updateEditModal({ best_scores: Number(e.target.value) })}
            type="number"
          />
        </Sheet>
      </Portal>

      <Portal isMounted={deleteModal.isOpen}>
        <Popup
          open={deleteModal.isOpen}
          title="Delete Leaderboard" // TODO: Translation
          description="Are you sure you want to delete this leaderboard" // TODO: Translation
          type="warning"
          onOk={deleteSeasonLeaderboard}
          okText="Delete" // TODO: Translation
          onCancel={closeDeleteModal}
        />
      </Portal>
    </Page>
  );
}
