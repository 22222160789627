import { AdminType, APIDelete, APIPost, APIUrl } from "api/protocols";
import { ICart } from "redux/reducers/models/cart";

interface IPostDiscountLine {
  discount_id: number;
  cart_id: number;
  cart_line_item_id?: number;
  value_type?: "percent" | "fixed_amount";
  value?: number;
  modifier_line_item_ids?: Array<number>;
}
interface IDeleteDiscountLine {
  id: number;
  cart_id: number;
}

export async function PostDiscountLine(params: IPostDiscountLine, useGlobalLoader: boolean) {
  return await APIPost<ICart>(APIUrl(AdminType.FacilityAdmin, "/cart/discount"), params, useGlobalLoader);
}

export async function DeleteDiscountLine(param: IDeleteDiscountLine, useGlobalLoader: boolean) {
  return await APIDelete(APIUrl(AdminType.FacilityAdmin, "/cart/discount"), param, useGlobalLoader);
}
