import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory, useParams } from "react-router";
import { CreateAccount, LockTeeTimeById } from "api/rpc/2022-09/guest/bookingEngine";
import { UserLogin } from "api/rpc";
import { StatusCode } from "api/protocols";
import { convertTime, formatDateFromString } from "helpers/Helpers";
import FormLayout from "components/form/FormLayout";
import Form from "components/form/Form";
import Input from "components/form/input/index";
import validator from "validator";
import { showError, showSuccess } from "redux/actions/ui";
import { PostPasswordResetCode } from "api/rpc/2022-09/passwordReset";
import { useTranslation } from "react-i18next";
import "./portalRegister.scss";
import { ButtonNew as Button } from "components/buttonNew";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { GetFacilityGuest, GetGuestTeeSheetSettings } from "api/rpc/2022-09/guest/facility";
import { GetClientGuest } from "api/rpc/2022-09/guest/client";
import { clientPortalUpdate } from "redux/actions/clientPortal";
import { GetTeeTimesGuest } from "api/rpc/2022-09/guest/teetime";
import { useGuestPortalContext } from "./GuestPortalContext";
import { useClientPortal } from "../Customer/ClientPortal/useClientPortal";

export interface IRegisterInfo {
  email: string;
  password: string;
  confirmPassword: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
}

export interface IFilterState {
  invalidEmail: boolean;
  invalidFirstName: boolean;
  invalidLastName: boolean;
  invalidPhoneNumber: boolean;
  invalidPassword: boolean;
  invalidPasswordMatch: boolean;
  emailInUse: boolean;
}
interface IParams {
  clientShortName: string;
}

export default function PortalRegister(props: any) {
  const { clientShortName } = useParams<IParams>();
  const clientPortalStore = useAppSelector(store => store.clientPortalStore);
  const dispatch = useAppDispatch();
  const clientPortal = window.location.href.includes(`/portal/${clientShortName}/register`);
  const { updatePortalState } = useGuestPortalContext();
  const HOME_URL = useClientPortal().getHomePageUrl();

  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [registerInfo, setRegisterInfo] = useState<IRegisterInfo>({
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    firstName: "",
    lastName: "",
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    invalidEmail: false,
    invalidFirstName: false,
    invalidLastName: false,
    invalidPhoneNumber: false,
    invalidPassword: false,
    invalidPasswordMatch: false,
    emailInUse: false,
  });

  function updateFilterState(newFilterState: Partial<IFilterState>) {
    setFilterState(cur => {
      return { ...cur, ...newFilterState };
    });
  }

  function sendToNextPage(address: string) {
    history.push(address);
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value, name } = event.target;
    setRegisterInfo(prevState => ({ ...prevState, [name]: value }));
  }

  async function createAccount() {
    const params = {
      first_name: registerInfo.firstName ?? "",
      last_name: registerInfo.lastName ?? "",
      email: registerInfo.email ?? "",
      password: registerInfo.password ?? "",
      phone: registerInfo.phoneNumber ?? "",
    };

    if (
      params.first_name === "" ||
      params.last_name === "" ||
      params.email === "" ||
      params.password === "" ||
      params.phone === ""
    ) {
      dispatch(showError("Please fill out all fields to register"));
      return;
    }

    const invalidFirstName = !validator.isAlpha(params.first_name);
    const invalidLastName = !validator.isAlpha(params.last_name);
    const invalidEmail = !validator.isEmail(params.email);
    const invalidPhoneNumber = !validator.isMobilePhone(params.phone, "en-CA");
    const invalidPassword = params.password.length < 8;
    const invalidPasswordMatch = registerInfo.password !== registerInfo.confirmPassword;

    updateFilterState({
      invalidFirstName: invalidFirstName,
      invalidLastName: invalidLastName,
      invalidEmail: invalidEmail,
      invalidPhoneNumber: invalidPhoneNumber,
      invalidPassword: invalidPassword,
      invalidPasswordMatch: invalidPasswordMatch,
    });

    if (
      invalidFirstName ||
      invalidLastName ||
      invalidEmail ||
      invalidPhoneNumber ||
      invalidPassword ||
      invalidPasswordMatch
    ) {
      if (invalidEmail) {
        updateFilterState({ emailInUse: false });
      }
      return;
    }

    const accountCreationRes = await CreateAccount(params, true);

    if (accountCreationRes.status !== StatusCode.OK) {
      if (accountCreationRes.data === "The email has already been taken.") {
        updateFilterState({ emailInUse: true });
      } else {
        dispatch(showError(accountCreationRes.data));
      }

      return;
    }
    dispatch(showSuccess("Account created successfully"));
    updateFilterState({ emailInUse: false });
    await confirmSignIn(params);
  }

  //Confirm sign in success / Check Tee Time Availability
  async function confirmSignIn(registerInfo: Partial<IRegisterInfo>) {
    const username = registerInfo.email;
    const password = registerInfo.password;

    const params = {
      email: username,
      password: password,
    };

    //Validate user login
    const loginRes = await UserLogin(params, true);

    if (loginRes.status !== StatusCode.OK) {
      dispatch(showError("Error signing in. Please try again"));
      if (clientPortal) {
        void sendToNextPage(HOME_URL);
      } else {
        void sendToNextPage(`/tee-on/portal/login`);
      }
      return;
    }

    dispatch(clientPortalUpdate({ user: loginRes.data?.user }));

    //Send to tee-on portal account overview
    if (!clientPortal) {
      updatePortalState({ guest: loginRes.data?.user });
      void sendToNextPage(`/tee-on/portal/overview`);
      return;
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const date = urlParams.get("date");
    const teeTimeId = urlParams.get("id");
    const facilityShortName = urlParams.get("facility");

    //Tee time not previously selected, send user to home page
    if (!date || !date || !teeTimeId || !facilityShortName) {
      void sendToNextPage(HOME_URL);
      return;
    }

    let client = clientPortalStore?.client;
    let facility = clientPortalStore.selectedFacility;
    let foundTeeTime = clientPortalStore?.selectedTeeTime;
    let teeSheetSettings = clientPortalStore?.teeSheetSettings;

    if (!client) {
      const clientRes = await GetClientGuest({ short_name: clientShortName }, true);
      if (clientRes.status !== StatusCode.OK || clientRes?.data?.length === 0) {
        dispatch(showError("Error loading client"));
        return;
      }
      client = clientRes.data[0];
      dispatch(clientPortalUpdate({ client: client }));
    }

    if (!facility) {
      const foundFacility = client?.facilities?.find(facility => facility?.short_name === facilityShortName);
      if (foundFacility) {
        facility = foundFacility;
        dispatch(clientPortalUpdate({ selectedFacility: foundFacility }));
      }
    }

    if (!teeSheetSettings) {
      const settingsRes = await GetGuestTeeSheetSettings({ facility_id: facility?.id }, true);
      if (settingsRes.status !== StatusCode.OK) {
        dispatch(showError("Error getting tee sheet settings"));
        return;
      }
      teeSheetSettings = settingsRes.data;
      dispatch(clientPortalUpdate({ teeSheetSettings }));
    }

    const teeTimeParams = {
      id: Number(teeTimeId),
      date: date,
      facility_id: facility?.id,
      extended: true,
      turn_tee_time: true,
    };

    if (!foundTeeTime) {
      const teeTimeRes = await GetTeeTimesGuest(teeTimeParams, true);

      if (teeTimeRes.status !== StatusCode.OK) {
        dispatch(showError("Error getting selected tee time"));
        history.push(HOME_URL);
        return;
      }

      if (teeTimeRes?.data?.length === 0) {
        dispatch(showError("Tee time is unavailable, please choose another"));
        history.push(HOME_URL);
        return;
      }
      const formattedStartTime = convertTime(teeTimeRes.data[0]?.start_time);
      const formattedDate = formatDateFromString(teeTimeRes.data[0]?.date);
      foundTeeTime = { ...teeTimeRes.data[0], formattedDate, formattedStartTime };
    }

    if (foundTeeTime?.id === Number(teeTimeId)) {
      //If not null - User has access to their own locked teetime
      if (foundTeeTime?.lock !== null) {
        const tempCurrentDate = new Date().toUTCString();
        const currentTempTime = new Date(tempCurrentDate);
        const currentTime = new Date(currentTempTime).getTime();

        const tempString = String(foundTeeTime?.lock?.start_time) + "Z";

        const lockStartDate = new Date(tempString);

        const tempExpiryString = String(foundTeeTime?.lock?.expire_time) + "Z";
        const lockoutTempExpiry = new Date(tempExpiryString);

        const lockStartTime = lockStartDate.getTime();
        const lockoutExpiryTime = lockoutTempExpiry.getTime();

        const lockoutDuration = lockoutExpiryTime - currentTime;

        dispatch(
          clientPortalUpdate({
            selectedTeeTimeLock: {
              lockoutDuration: lockoutDuration,
              lockStartTime: lockStartTime,
              expireTime: foundTeeTime?.lock?.expire_time,
            },
            selectedTeeTime: foundTeeTime,
          }),
        );

        history.push(`/portal/${clientShortName}/booking/id?=${teeTimeId}&date=${date}`);
      } else {
        const bookingLockRes = await LockTeeTimeById({ tee_time_id: foundTeeTime?.id }, true);

        if (bookingLockRes.status !== StatusCode.OK) {
          dispatch(showError("Error locking tee time"));
          return;
        }

        //Locked out message
        const bookingLockMessage = "Tee time already locked by user";
        const bookingLockResMessage = bookingLockRes.data.message;

        if (bookingLockMessage === bookingLockResMessage) {
          dispatch(showError("Booking is locked by another user, please try again in a few minutes.."));
          return;
        }

        const tempCurrentDate = new Date().toUTCString();
        const currentTempTime = new Date(tempCurrentDate);
        const currentTime = new Date(currentTempTime).getTime();

        const tempString = String(bookingLockRes?.data?.start_time) + "Z";
        const lockStartDate = new Date(tempString);

        const tempExpiryString = String(bookingLockRes?.data?.expire_time) + "Z";
        const lockoutTempExpiry = new Date(tempExpiryString);

        const lockStartTime = lockStartDate.getTime();
        const lockoutExpiryTime = lockoutTempExpiry.getTime();

        const lockoutDuration = lockoutExpiryTime - currentTime;

        dispatch(
          clientPortalUpdate({
            selectedTeeTimeLock: {
              lockoutDuration: lockoutDuration,
              lockStartTime: lockStartTime,
              expireTime: bookingLockRes?.data?.expire_time,
            },
            selectedTeeTime: foundTeeTime,
          }),
        );

        history.push(`/portal/${clientShortName}/booking/id?=${teeTimeId}&date=${date}`);
      }
    } else {
      dispatch(showError("Tee time is unavailable, please choose another"));
      history.push(HOME_URL);
      return;
    }
  }

  function sendToLogin() {
    //Client Portal
    if (clientPortal) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const date = urlParams.get("date");
      const teeTimeId = urlParams.get("id");
      const facility = urlParams.get("facility");
      if (date && teeTimeId && facility) {
        void sendToNextPage(`/portal/${clientShortName}/login?id=${teeTimeId}&date=${date}&facility=${facility}`);
      } else {
        void sendToNextPage(`/portal/${clientShortName}/login`);
      }
    } else {
      //Tee On Portal
      void sendToNextPage(`/tee-on/portal/login`);
    }
  }

  async function sendPasswordRecovery() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const facilityShortName = urlParams.get("facility");

    if (registerInfo.email === undefined || !validator.isEmail(registerInfo.email)) {
      dispatch(showError("Please enter a valid email for recovery"));
      return;
    }

    let facilityId = clientPortalStore?.selectedFacility?.id;
    if (!facilityId && facilityShortName) {
      const facilityRes = await GetFacilityGuest({ short_name: facilityShortName }, true);
      if (facilityRes.status !== StatusCode.OK) {
        dispatch(showError("Error getting facility"));
        return;
      }
      facilityId = facilityRes?.data[0]?.id;
    }

    const params = {
      email: registerInfo.email,
      facility_id: facilityId,
    };

    const resetRes = await PostPasswordResetCode(params, true);

    if (resetRes.status !== StatusCode.OK) {
      if (typeof resetRes?.data === "string") {
        dispatch(showError(resetRes.data));
      } else {
        dispatch(showError("Error sending password reset code"));
      }
      return;
    }

    dispatch(showSuccess("Email Invite Sent"));
    void sendToLogin();
  }

  return (
    <>
      <div className="client-portal-login">
        <div className="client-portal-login-header-container">
          <div className="client-portal-login-header-container-centered">
            <div className="client-portal-login-header-container-inner">
              <img
                src={
                  clientPortal && clientPortalStore?.client?.logo_source_url
                    ? clientPortalStore?.client?.logo_source_url
                    : "/public/images/Tee-On.png"
                }
                alt="Client logo"
              />
            </div>
          </div>
        </div>

        <div className="client-portal-login-container">
          <div className="client-portal-login-container-inner">
            <div className="client-portal-login-container-group">
              <div className="client-portal-login-text-group">
                <p className="sign-in-text">Create Account</p>
                <p className="sign-in-info">Please sign in or create an account to book a tee time</p>
              </div>

              {filterState.invalidFirstName ||
              filterState.invalidLastName ||
              filterState.invalidEmail ||
              filterState.invalidPhoneNumber ||
              filterState.invalidPassword ||
              filterState.invalidPasswordMatch ||
              filterState.emailInUse ? (
                <div className="register-error">
                  {filterState.invalidFirstName && (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ display: "flex" }}>
                        <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="register-triangle-icon" />
                      </div>
                      <p>{"First name cannot contain numbers"}</p>
                    </div>
                  )}
                  {filterState.invalidLastName && (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ display: "flex" }}>
                        <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="register-triangle-icon" />
                      </div>
                      <p>{"Last name cannot contain numbers"}</p>
                    </div>
                  )}
                  {filterState.invalidEmail && (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ display: "flex" }}>
                        <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="register-triangle-icon" />
                      </div>
                      <p>{"Email is not in the correct format"}</p>
                    </div>
                  )}
                  {filterState.invalidPhoneNumber && (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ display: "flex" }}>
                        <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="register-triangle-icon" />
                      </div>
                      <p>{"Phone number is not in the correct format"}</p>
                    </div>
                  )}
                  {filterState.invalidPassword && (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ display: "flex" }}>
                        <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="register-triangle-icon" />
                      </div>
                      <p>{"Password must be at least 8 characters long"}</p>
                    </div>
                  )}

                  {filterState.invalidPasswordMatch && (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ display: "flex" }}>
                        <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="register-triangle-icon" />
                      </div>
                      <p>Passwords do not match</p>
                    </div>
                  )}

                  {filterState.emailInUse && (
                    <div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ display: "flex" }}>
                          <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="register-triangle-icon" />
                        </div>
                        <p>
                          {"It looks like you already have an account. Please click the"} &apos;{"Resend Invite"}
                          &apos;{" "}
                          {"button below and check your inbox and junk folder to set a password for your account."}
                        </p>
                      </div>

                      <button onClick={sendPasswordRecovery} className="register-email-resend-invite">
                        {"RESEND INVITE"} <FontAwesomeIcon icon={["fal", "paper-plane"]} />
                      </button>
                    </div>
                  )}
                </div>
              ) : null}

              <Form>
                <FormLayout>
                  <FormLayout.Group>
                    <Input
                      value={registerInfo.firstName}
                      label={"First Name"}
                      name="firstName"
                      onChange={handleInputChange}
                    />
                    <Input
                      value={registerInfo.lastName}
                      label={"Last Name"}
                      name="lastName"
                      onChange={handleInputChange}
                    />
                  </FormLayout.Group>

                  <FormLayout.Group>
                    <Input
                      value={registerInfo.email}
                      label={"Email Address"}
                      name="email"
                      onChange={handleInputChange}
                    />
                  </FormLayout.Group>

                  <FormLayout.Group>
                    <Input
                      value={registerInfo.phoneNumber}
                      label={"Phone Number"}
                      name="phoneNumber"
                      onChange={handleInputChange}
                    />
                  </FormLayout.Group>

                  <FormLayout.Group>
                    <Input.Password
                      label={"Password"}
                      value={registerInfo.password}
                      name="password"
                      onChange={handleInputChange}
                    />
                  </FormLayout.Group>

                  <FormLayout.Group>
                    <Input.Password
                      label={"Confirm Password"}
                      value={registerInfo.confirmPassword}
                      name="confirmPassword"
                      onChange={handleInputChange}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Form>
            </div>

            <Button type="primary" block onClick={createAccount}>
              CREATE ACCOUNT
            </Button>

            <div className="client-portal-login-footer">
              <p className="footer-text">Already have an account?</p>
              <p className="login-button" onClick={sendToLogin}>
                Log in
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
