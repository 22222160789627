import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import axios, { CancelToken } from "axios";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Input from "components/form/input/Input";
import Sheet from "components/sheet/Sheet";
import { GetCustomerTypes } from "api/rpc/2024-04/masterAdmin/client/settings/customerType";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { showError, showSuccess } from "redux/actions/ui";
import { TCustomerType } from "redux/reducers/models/facility";
import { valueToString } from "helpers/Helpers";

interface ICustomerTypesState {
  types: Array<TCustomerType>;
}

export default function CustomerTypes(props: any) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { masterClientStore } = useAppSelector(store => store);

  const [state, setState] = useState<ICustomerTypesState>({
    types: [],
  });

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (masterClientStore.client) {
      void loadCustomerTypes(source.token);
    }

    return () => {
      source.cancel();
    };
  }, [masterClientStore.client]);

  async function loadCustomerTypes(token?: CancelToken) {
    const typeRes = await GetCustomerTypes(
      {
        client_id: masterClientStore.client?.id,
      },
      true,
      token,
    );

    if (typeRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading customer types"));
      return;
    }

    setState(prevState => ({ ...prevState, types: typeRes.data }));
  }

  const primaryAction = {
    content: "New Customer Type",
    action: () => history.push("/admin/client-settings/customer-type/new"),
    disabled: !masterClientStore.client,
  };

  return (
    <Page
      title="Customer Types"
      subtitle={masterClientStore.client ? masterClientStore.client.full_name : "No Client Selected"}
      narrow
      primaryAction={primaryAction}
    >
      {masterClientStore.client && (
        <Card>
          <Card.Section table="true">
            <table className="ui-table ui-table-clickable">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Subtitle</th>
                  <th>Application</th>
                  <th>Default Booking Category</th>
                </tr>
              </thead>
              <tbody>
                {state.types ? (
                  <>
                    {state.types.map((type: TCustomerType, index: number) => {
                      return (
                        <tr key={index} onClick={() => history.push(`/admin/client-settings/customer-type/${type.id}`)}>
                          <td>
                            <p className="table-cell-lead-text">{type.title}</p>
                          </td>
                          <td>
                            <p className="table-cell-lead-text">{type.subtitle}</p>
                          </td>
                          <td>
                            <p className="table-cell-lead-text">{valueToString(type.application)}</p>
                          </td>
                          <td>
                            <p className="table-cell-lead-text">{type.default_booking_category?.title}</p>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : null}
              </tbody>
            </table>
          </Card.Section>
        </Card>
      )}
    </Page>
  );
}
