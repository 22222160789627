import React, { useEffect, useState } from "react";

import { StatusCode } from "api/protocols";
import {
  GetCustomer,
  PutCustomer,
  PutMergeCustomers,
  UploadCustomerProfileImage,
  DeleteCustomerProfileImage,
} from "api/rpc/2022-09/facilityAdmin/customer/customer";
import { ICustomer } from "redux/reducers/models/customer";

import { useCustomerContext } from "../context/CustomerContext";

import CustomerTabs from "../tabs/CustomerTabs";
import CustomerImage from "elements/customer/CustomerImage";

import "./customer.scss";
import { ButtonNew as Button } from "components/buttonNew";
import Sheet from "components/sheet/Sheet";
import GolferCard from "components/bookingPopUp/golferCard/GolferCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select } from "components/select/index";
import axios, { CancelToken } from "axios";
import Popup from "components/popup/Popup";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { showError, showSuccess } from "redux/actions/ui";
import { useTranslation } from "react-i18next";

/**
 * Customer Profile 'landing' page.
 *   - Wrapped in a Customer Context to handle persistent data across Tab navigation.
 *   - Only renders after the context has loaded and set the context state.
 */
export default function Customer() {
  const { state, updateState, loadCustomer } = useCustomerContext();

  // console.log("Stateeeeeee", state);
  const { Option } = Select;
  const dispatch = useAppDispatch();
  const { authStore } = useAppSelector(store => store);
  const { t, i18n } = useTranslation();

  const [selectedYear, setSelectedYear] = useState<string>(null);

  async function saveCustomerImage(imageFile: File) {
    const formData = new FormData();

    formData.append("profile_image", imageFile);
    formData.append("id", state.customer.id.toString());

    const imageRes = await UploadCustomerProfileImage(formData, true);
    if (imageRes.status !== StatusCode.OK) {
      return;
    }

    console.log(imageRes.data); //debug

    const tempCustomer: ICustomer = { ...state.customer, ...imageRes.data };
    updateState({ customer: tempCustomer });
  }

  async function deleteCustomerImage() {
    const imageRes = await DeleteCustomerProfileImage({ id: state.customer.id }, true);
    if (imageRes.status !== StatusCode.OK) {
      return;
    }

    console.log("imageUpdate", imageRes.data); //debug

    const tempCustomer: ICustomer = { ...state.customer, ...imageRes.data };
    updateState({ customer: tempCustomer });
  }

  function renderCustomerStatsGroup(screenSize: "desktop" | "mobile") {
    if (state.bookingCounts.length <= 0) {
      return null;
    }
    return (
      <div className={"booking-stats-general"}>
        <div className={`customer-stats-group-${screenSize}`}>
          <Select
            className={screenSize !== "mobile" ? "mb-4" : null}
            defaultValue={"2023"}
            onChange={(count: any) => setSelectedYear(count)}
            //disabled={state.accountStatements === null || state.accountTransactions === null}
          >
            {state.bookingCounts?.map(count => {
              return (
                <Option key={count.year} value={count.year} name={count.year}>
                  {count.year}
                </Option>
              );
            })}
          </Select>
        </div>
        <div className={`customer-stats-group-${screenSize}`}>
          <span className="customer-stat">
            <p className="stat-number">
              {state.bookingCounts?.find((count: any) => count.year === selectedYear)?.count}
            </p>
            <p className="stat-title">Bookings</p>
          </span>
          <span className="customer-stat">
            <p className="stat-number stat-number_warning">
              {state.bookingCounts?.find((count: any) => count.year === selectedYear)?.no_shows}
            </p>
            <p className="stat-title">No Shows</p>
          </span>
          <span className="customer-stat">
            <p className="stat-number stat-number_warning">
              {state.bookingCounts?.find((count: any) => count.year === selectedYear)?.weather_no_shows}
            </p>
            <p className="stat-title">Weather</p>
          </span>
          {/* <span className="customer-stat">
            <p className="stat-number">{state.bookingCount.no_show_weather}</p>
            <p className="stat-title">Rain Checks</p>
          </span> */}
          {screenSize === "desktop" && <span className="customer-stats-group-desktop-empty-space" />}
        </div>
      </div>
    );
  }

  return (
    <>
      {state.customer && (
        <>
          <div>
            {/* Customer STATS */}
            <div className="customer_stats_bar">
              <div className="stats_bar-picture">
                <CustomerImage
                  imageSource={state.customer.profile_image_source}
                  saveProfileImage={imageFile => saveCustomerImage(imageFile)}
                  deleteProfileImage={deleteCustomerImage}
                />
              </div>

              <div className="stats-general">
                <div className="customer-name-container">
                  <h1 className="display-md text-semibold">{state.customer.full_name}</h1>
                  {/* {authStore?.user?.permissions?.merge_customers ? (
                    <Button
                      size="medium"
                      type="text"
                      onClick={() => setMergeState(prevState => ({ ...prevState, showMergeModal: true }))}
                    >
                      {t("secure.facility.customer.main.customer.005")}
                    </Button>
                  ) : null} */}
                </div>

                <span className="customer-types">
                  <p className="customer-membership">{state.customer.customer_type}</p>
                  <p className="customer-membership">{state.customer.cart_customer_type}</p>
                </span>

                <div className="customer-contact">
                  {state.customer.email ? (
                    <div>{state.customer.email}</div>
                  ) : (
                    <div className="field-unavailable">No Email On File</div>
                  )}
                  <div className="customer-contact-phone-number">
                    {state.customer.phone ? (
                      <p>{state.customer.phone}</p>
                    ) : (
                      <p className="field-unavailable">No Phone Number</p>
                    )}
                  </div>
                </div>
              </div>
              {renderCustomerStatsGroup("desktop")}
            </div>
            {renderCustomerStatsGroup("mobile")}
          </div>
          <CustomerTabs customerId={state.customer.id} />
        </>
      )}
    </>
  );
}
