import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

import "../facility/facility.scss";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Flag from "components/flag/Flag";
import { Select } from "components/select/index";
import { StatusCode } from "api/protocols";

export default function Language(props: any) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { Option } = Select;

  const lngs: { [index: string]: any } = {
    en: { nativeName: "English", countryCode: "ca" },
    frCA: { nativeName: "French", countryCode: "ca" },
    fr: { nativeName: "French", countryCode: "fr" },
  };

  function handleDropDownChange(value: string) {
    void i18n.changeLanguage(value);
  }

  return (
    <Page title={t("secure.facility.settings.language.language.001")} narrow>
      <Card>
        <Card.Section>
          <Select
            label={t("secure.facility.settings.language.language.002")}
            defaultValue={i18n.resolvedLanguage}
            onChange={handleDropDownChange}
          >
            {Object.keys(lngs).map((lng: any) => (
              <Option key={lng} value={lng}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: "1rem" }}>
                    <Flag countryCode={lngs[lng].countryCode} />
                  </span>
                  <span>{lngs[lng].nativeName}</span>
                </div>
              </Option>
            ))}
          </Select>
        </Card.Section>
      </Card>
    </Page>
  );
}
