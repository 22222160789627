import { APIGet, APIPost, APIPut, APIDelete, IAPIResponse, StatusCode } from "api/protocols";
import { CancelToken } from "axios";
import { IFacility } from "redux/reducers/models/facility";

const apiVersion = "2024-04";
const adminType = "customer";
const baseFacilityUrl = "/" + apiVersion + "/" + adminType + "/facility";

export interface IFacilityTeeSheetSettings {
  booking_terms: string;
  close_date: string;
  enable_web_booking: 1 | 0;
  enable_cart_bookings: 1 | 0;
  facility_id: number;
  max_booking_size: number;
  open_date: string;
  payment_terms: string;
  web_cancellation_hours: number;
  web_edit_hours: number;
  single_bookings: BookingPlayerType;
  twosome_bookings: BookingPlayerType;
  threesome_bookings: BookingPlayerType;
  foursome_bookings: BookingPlayerType;
  display_online_pricing: number;
  booking_engine_display_waitlist: boolean;
}

export type BookingPlayerType = "allow" | "none" | "allow_within_group";

export interface IGetFacilityTeeSheetSettingsRes {
  status: StatusCode;
  data: IFacilityTeeSheetSettings;
}

export interface IGetFacilityRes extends IAPIResponse {
  data: Array<IFacility>;
}

export async function GetCustomerTeeSheetSettings(
  params: { facility_id: number },
  useGlobalLoader: boolean,
): Promise<IGetFacilityTeeSheetSettingsRes> {
  return await APIGet(baseFacilityUrl + "/settings/tee-sheet", params, useGlobalLoader);
}

export async function GetFacility(
  params: { id?: number; client_id?: number; search?: string; order_by?: string; extended?: boolean },
  useGlobalLoader: boolean,
  cancelToken?: CancelToken,
): Promise<IGetFacilityRes> {
  return await APIGet(baseFacilityUrl, params, useGlobalLoader, cancelToken);
}

export async function PostFacilityFavourite(params: { facility_id: number }, useGlobalLoader: boolean) {
  return await APIPost(baseFacilityUrl + "/favourite", params, useGlobalLoader);
}

export async function DeleteFacilityFavourite(params: { facility_id: number }, useGlobalLoader: boolean) {
  return await APIDelete(baseFacilityUrl + "/favourite", params, useGlobalLoader);
}

//GET Customer Favourite Facilities
export async function GetCustomerFacilityFavourites(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseFacilityUrl + "/favourite", params, useGlobalLoader);
}

//DELETE Customer favourite facility
export async function DeleteCustomerFacilityFavourite(params: any, useGlobalLoader: boolean) {
  return await APIDelete(baseFacilityUrl + "/favourite", params, useGlobalLoader);
}
