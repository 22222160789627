import { IRadioProps } from "./interface";
import InternalRadio from "./radio";
import Button from "./radioButton";
import Group from "./group";
import "./style.scss";

export interface IRadioComposition extends IRadioProps {
  Group: typeof Group;
  Button: typeof Button;
}

const Radio = InternalRadio as unknown as IRadioComposition;

Radio.Group = Group;
Radio.Button = Button;

export default Radio;
