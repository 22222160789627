import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ICountry } from "redux/reducers/models/customer";

import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Checkbox from "components/form/checkbox/Checkbox";
import { Select } from "components/select/index";
import Sheet from "components/sheet/Sheet";
import Input from "components/form/input/Input";

export interface IAddressLineState {
  addressLine1: string;
  addressLine2: string;
  city: string;
  postal: string;
  phone: string;
  country_id: number;
  province_id: number;
  default: boolean;
}

type CustomerAddressModalProps = {
  isVisible: boolean;
  countries: ICountry[];
  onCancel: () => void;
  onOk: (addressLines: IAddressLineState) => void;
};

export default function CustomerAddressModal(props: CustomerAddressModalProps) {
  const { t, i18n } = useTranslation();
  const { isVisible, countries } = props;

  const { Option } = Select;

  const [addressLineState, setAddressLineState] = useState<IAddressLineState>({
    addressLine1: "",
    addressLine2: "",
    city: "",
    postal: "",
    phone: "",
    country_id: countries[0]?.id ?? null,
    province_id: countries[0]?.provinces[0]?.id ?? null,
    default: false,
  });

  function handleOnCancel() {
    setAddressLineState(prevState => ({
      ...prevState,
      addressLine1: "",
      addressLine2: "",
      city: "",
      postal: "",
      phone: "",
      country_id: countries[0]?.id ?? null,
      province_id: countries[0]?.provinces[0]?.id ?? null,
      default: false,
    }));

    props.onCancel();
  }

  return (
    <div className="customer-new-address-lines-overflow">
      <Sheet
        closable
        size="medium"
        open={isVisible}
        title={t("elements.customer.profile.customer_address_modal.001")}
        onCancel={handleOnCancel}
        onOk={() => props.onOk(addressLineState)}
        okText={t("elements.customer.profile.customer_address_modal.002")}
        cancelText={t("elements.customer.profile.customer_address_modal.003")}
        okDisabled={
          !addressLineState.addressLine1 ||
          !addressLineState.country_id ||
          !addressLineState.province_id ||
          !addressLineState?.postal
            ? true
            : false
        }
      >
        <Form>
          <FormLayout>
            <FormLayout.Group>
              <Input
                value={addressLineState?.addressLine1 || ""}
                label={t("elements.customer.profile.customer_address_modal.004")}
                id="addressLine1"
                onChange={({ target }: { target: EventTarget & HTMLInputElement }) =>
                  setAddressLineState({ ...addressLineState, [target.id]: target.value })
                }
              />

              <Input
                value={addressLineState?.addressLine2 || ""}
                label={t("elements.customer.profile.customer_address_modal.005")}
                id="addressLine2"
                onChange={({ target }: { target: EventTarget & HTMLInputElement }) =>
                  setAddressLineState({ ...addressLineState, [target.id]: target.value })
                }
              />
            </FormLayout.Group>

            <FormLayout.Group>
              <Input
                value={addressLineState?.city || ""}
                label={t("elements.customer.profile.customer_address_modal.006")}
                id="city"
                onChange={({ target }: { target: EventTarget & HTMLInputElement }) =>
                  setAddressLineState({ ...addressLineState, [target.id]: target.value })
                }
              />

              <Input
                value={addressLineState?.postal || ""}
                label={t("elements.customer.profile.customer_address_modal.007")}
                id="postal"
                onChange={({ target }: { target: EventTarget & HTMLInputElement }) =>
                  setAddressLineState({ ...addressLineState, [target.id]: target.value })
                }
              />

              <Input
                value={addressLineState?.phone || ""}
                label={t("elements.customer.profile.customer_address_modal.008")}
                id="phone"
                onChange={({ target }: { target: EventTarget & HTMLInputElement }) =>
                  setAddressLineState({ ...addressLineState, [target.id]: target.value })
                }
              />
            </FormLayout.Group>

            <FormLayout.Group>
              <Select
                label={t("elements.customer.profile.customer_address_modal.009")}
                onChange={(value: number) => setAddressLineState({ ...addressLineState, country_id: value })}
                defaultValue={addressLineState?.country_id}
              >
                {countries ? (
                  countries?.map((country, index) => {
                    return (
                      <Option key={index} value={country.id} name={country.name}>
                        {country.name}
                      </Option>
                    );
                  })
                ) : (
                  <></>
                )}
              </Select>

              {countries?.some(country => country?.id === addressLineState?.country_id) && (
                <Select
                  label={t("elements.customer.profile.customer_address_modal.010")}
                  onChange={(value: number) => setAddressLineState({ ...addressLineState, province_id: value })}
                  defaultValue={addressLineState?.province_id}
                >
                  {countries
                    ?.find(country => country?.id === addressLineState?.country_id)
                    ?.provinces?.map((province: Record<string, any>, index: number) => {
                      return (
                        <Option key={index} value={province?.id} name={province?.name}>
                          {province?.name}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </FormLayout.Group>
            <FormLayout.Group>
              <Checkbox
                label={t("elements.customer.profile.customer_address_modal.011")}
                size="medium"
                onChange={({ target }: { target: EventTarget & HTMLInputElement }) =>
                  setAddressLineState({ ...addressLineState, [target.id]: target.checked })
                }
                checked={addressLineState.default}
                id="default"
              />
            </FormLayout.Group>
          </FormLayout>
        </Form>
      </Sheet>
    </div>
  );
}
