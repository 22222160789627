import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { showError, showSuccess } from "redux/actions/ui";

import Page from "components/page/Page";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Form from "components/form/Form";
import Input from "components/form/input/Input";
import { Select } from "components/select/index";
import ColorPicker from "components/colorpicker/color-picker";
import { StatusCode } from "api/protocols";

import { GetCustomerTypes, PutCustomerType } from "api/rpc/2024-04/masterAdmin/client/settings/customerType";
import { TCustomerType } from "redux/reducers/models/customer";
import { GetBookingCategories } from "api/rpc/2024-04/masterAdmin/client/settings/bookingCategory";
import { IBookingCategory } from "redux/reducers/models/teetime";

import { useTranslation } from "react-i18next";

interface IApplicationOption {
  label: string;
  value: string;
}

interface ICustomerTypeState {
  customerType: TCustomerType;
  title: string;
  application: string;
  application_options: Array<IApplicationOption>;
  default_booking_category_id: number;
  categories: Array<IBookingCategory>;
  subtitle: string;
  color: string;
}

export default function CustomerType(props: any) {
  const history = useHistory();
  const { typeId }: any = useParams();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const { Option } = Select;
  const { masterClientStore } = useAppSelector(store => store);

  const [customerTypeState, setCustomerTypeState] = useState<ICustomerTypeState>({
    customerType: null,
    title: "",
    application: "",
    application_options: [
      {
        label: "Green Fee",
        value: "green_fee",
      },
      {
        label: "Power Cart",
        value: "power_cart",
      },
    ],
    default_booking_category_id: null,
    categories: [],
    subtitle: "",
    color: "",
  });

  useEffect(() => {
    if (masterClientStore.client) {
      void loadCustomerType();
      void loadBookingCategories();
    }
  }, [masterClientStore.client]);

  async function loadBookingCategories() {
    const categoryRes = await GetBookingCategories(
      {
        client_id: masterClientStore.client?.id,
      },
      true,
    );

    if (categoryRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading booking categories"));
      return;
    }

    setCustomerTypeState(prevState => ({ ...prevState, categories: categoryRes.data }));
  }

  async function loadCustomerType() {
    const typeRes = await GetCustomerTypes(
      {
        client_id: masterClientStore.client?.id,
        id: Number(typeId),
      },
      true,
    );

    if (typeRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading customer type"));
      return;
    }

    setCustomerTypeState(prevState => ({
      ...prevState,
      customerType: typeRes.data[0],
      title: typeRes.data[0].title,
      subtitle: typeRes.data[0].subtitle,
      application: typeRes.data[0].application,
      default_booking_category_id: typeRes.data[0].default_booking_category_id,
      color: typeRes.data[0].color,
    }));
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setCustomerTypeState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleDropDownChange(value: any, property: string) {
    setCustomerTypeState(prevState => ({ ...prevState, [property]: value }));
  }

  function handleColorPick(value: string) {
    setCustomerTypeState(prevState => ({
      ...prevState,
      color: value,
    }));
  }

  async function updateCustomerType() {
    const typeRes = await PutCustomerType(
      {
        id: Number(typeId),
        client_id: masterClientStore.client?.id,
        ...customerTypeState,
      },
      true,
    );

    if (typeRes.status !== StatusCode.OK) {
      dispatch(showError("Error updating customer type"));
      return;
    }

    dispatch(showError("Customer type updated successfully"));
    history.push("/admin/client-settings/customer-type");
  }

  const primaryAction = {
    content: "Update",
    action: updateCustomerType,
    disabled: !masterClientStore.client,
  };

  return (
    <Page
      title={"Edit Customer Type"}
      subtitle={masterClientStore.client ? masterClientStore.client.full_name : "No Client Selected"}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: "Back to customer types",
          url: "/admin/client-settings/customer-type",
        },
      ]}
    >
      {masterClientStore.client && (
        <>
          <Card>
            <Card.Section>
              <Form>
                <FormLayout>
                  <FormLayout.Group>
                    <Input value={customerTypeState.title} label={"Title"} id="title" onChange={handleInputChange} />
                    <Input
                      value={customerTypeState.subtitle}
                      label={"Subtitle"}
                      id="subtitle"
                      onChange={handleInputChange}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <Select
                      label={"Application"}
                      onChange={(value: any) => handleDropDownChange(value, "application")}
                      defaultValue={customerTypeState.application}
                    >
                      {customerTypeState.application_options.map((option: IApplicationOption, index: number) => (
                        <Option key={index} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                    <Select
                      label={"Default Booking Category"}
                      onChange={(value: any) => handleDropDownChange(value, "default_booking_category_id")}
                      defaultValue={customerTypeState.default_booking_category_id}
                    >
                      {customerTypeState.categories.map((category: IBookingCategory, index: number) => (
                        <Option key={index} value={category.id}>
                          {category.title}
                        </Option>
                      ))}
                    </Select>
                  </FormLayout.Group>
                </FormLayout>
              </Form>
            </Card.Section>
          </Card>
          <Card title="Tee Sheet Color" subtitle="Select a color for this customer type to use on the tee sheet">
            <Card.Section>
              <ColorPicker colorValue={customerTypeState.color} onClick={handleColorPick} />
            </Card.Section>
          </Card>
        </>
      )}
    </Page>
  );
}
