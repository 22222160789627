import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "master-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/product/vendor";

interface IGetVendor {
  id?: number;
  client_id?: number;
}

interface IPutVendor {
  id: number;
  client_id: number;
  title: string;
}

interface IPostVendor {
  client_id: number;
  title: string;
}

interface IDeleteVendor {
  id: number;
  client_id: number;
}

// GET Product Types
export async function GetVendors(params: IGetVendor, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseUrl, params, useGlobalLoader, token);
}

// PUT Product Type
export async function PutVendor(params: IPutVendor, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

// POST Product Type
export async function PostVendor(params: IPostVendor, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

// Delete Product Type
export async function DeleteVendor(params: IDeleteVendor, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}
