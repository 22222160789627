import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RegisterGroup from "../../pages/secure/facility/settings/register/Group";
import * as UIActions from "redux/actions/ui";

const mapStateToProps = (state: any) => {
  const { authStore, facilityStore } = state;
  return {
    authStore: { ...authStore },
    facilityStore: { ...facilityStore },
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    uiActions: bindActionCreators(
      {
        ...UIActions,
      },
      dispatch,
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterGroup);
