import React, { ChangeEvent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { IAuthState } from "redux/reducers/auth";
import { IAuthActions } from "redux/actions/auth";
import { IUIActions } from "redux/actions/ui";
import { isPositiveInteger } from "helpers/Helpers";
import { isEqualWith, isNull } from "lodash";

import {
  GetClientAdmin,
  PutDisableClientAdmin,
  PutClientAdmin,
  PutClientAdminPermissions,
  PutReactivateClientAdmin,
} from "api/rpc/2024-04/clientAdmin/client/admin";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Input from "components/form/input/Input";
import FormLayout from "components/form/FormLayout";
import Card from "components/card/Card";
import Form from "components/form/Form";
import Toggle from "components/form/toggle/Toggle";
import { NotificationType } from "components/notificationBar/NotificationBar";
import Popup from "components/popup/Popup";
import { PostPasswordResetCode } from "api/rpc/2022-09/clientAdmin/client/staffPasswordReset";
import { Badge } from "components/badge/Badge";
import Icon from "components/icon/Icon";
import { IUserPermissions } from "redux/reducers/models/user";

interface IFacilityStaffEditProps {
  authStore: IAuthState;
  authActions: IAuthActions;
  uiActions: IUIActions;
}

interface IStaff {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  username: string;
  state: string;
  quick_code: string;
  permissions: IUserPermissions;
}

export default function FacilityStaffEdit(props: IFacilityStaffEditProps) {
  const { id } = useParams<{ id: string }>();
  console.log(id);

  const history = useHistory();

  const permissions = props?.authStore?.user?.permissions;

  const [staffState, setStaffState] = useState<IStaff>(undefined);
  const [staffStateBeforeChanges, setStaffStateBeforeChanges] = useState<IStaff>(undefined);

  const [resetPasswordPopup, setResetPasswordPopup] = useState<boolean>(false);
  const [reactivateAccountPopup, setReactivateAccountPopup] = useState<boolean>(false);

  useEffect(() => {
    void loadClientStaff();
  }, []);

  async function loadClientStaff() {
    const params = {
      id: id,
      extended: true,
    };
    const staffRes = await GetClientAdmin(params, true);

    console.log(staffRes);

    if (staffRes.status !== StatusCode.OK) {
      props.uiActions.showError("Error when loading staff member");
      return;
    }

    setStaffState(prev => ({
      ...prev,
      id: staffRes.data[0].id,
      first_name: staffRes.data[0].first_name ?? "",
      last_name: staffRes.data[0].last_name ?? "",
      email: staffRes.data[0].email ?? "",
      username: staffRes.data[0].username ?? "",
      state: staffRes.data[0].state ?? "",
      quick_code: "",
      permissions: staffRes.data[0].permissions,
    }));

    setStaffStateBeforeChanges(staffState);
  }

  async function saveStaffDetails() {
    const isEmpty = (x: any) => x === "" || x === null || x === undefined;

    if (!isEmpty(staffState.quick_code) && !isPositiveInteger(staffState.quick_code)) {
      props.uiActions.showError("Quick code must be a positive integer");
      return;
    }

    const params = {
      id: staffState.id,
      first_name: staffState.first_name,
      last_name: staffState.last_name,
      email: staffState.email,
      username: staffState.username,
      quick_code: isEmpty(staffState.quick_code) ? undefined : parseInt(staffState.quick_code),
    };

    const putFacilityAdminResponse = await PutClientAdmin(params, true);

    if (putFacilityAdminResponse.status !== StatusCode.OK) {
      props.uiActions.showError("Error when updating staff member");
      return;
    }

    const putFacilityAdminPermissionsResponse = await PutClientAdminPermissions(
      {
        user_id: parseInt(id),
        ...staffState.permissions,
      },
      true,
    );

    if (putFacilityAdminPermissionsResponse.status !== StatusCode.OK) {
      props.uiActions.showError("Error updating staff account permissions");
      return;
    }

    props.uiActions.showSuccess("Staff account saved successfully");
    setStaffStateBeforeChanges(staffState);
  }

  async function disableStaffAccount() {
    const putDisableFacilityAdminResponse = await PutDisableClientAdmin({ admin_id: staffState.id }, true);

    if (putDisableFacilityAdminResponse.status !== StatusCode.OK) {
      props.uiActions.showError("Failed to disable user");
      return;
    }

    history.push("/admin/settings/client/staff");
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setStaffState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleToggleChange(event: ChangeEvent<HTMLInputElement>) {
    const id = event?.target?.id;
    const checked = event?.target?.checked;

    if (id === undefined || checked === undefined) {
      return;
    }

    setStaffState(prev => {
      return { ...prev, permissions: { ...prev.permissions, [id]: checked } };
    });
  }

  function handleAccountsEditToggleChange(event: ChangeEvent<HTMLInputElement>) {
    const checked = event?.target?.checked;

    if (checked === undefined) {
      return;
    }

    setStaffState(prev => {
      return {
        ...prev,
        permissions: { ...prev.permissions, admin_accounts_edit: checked, admin_permissions_edit: false },
      };
    });
  }

  function handleProductsEditToggleChange(event: ChangeEvent<HTMLInputElement>) {
    const checked = event?.target?.checked;

    if (checked === undefined) {
      return;
    }

    setStaffState(prev => {
      return {
        ...prev,
        permissions: {
          ...prev.permissions,
          products_edit: checked,
          products_edit_cost: false,
          products_edit_price: false,
        },
      };
    });
  }

  function unsavedChangesExist() {
    if (staffStateBeforeChanges === undefined) {
      if (staffState) {
        setStaffStateBeforeChanges(staffState);
      }
      return false;
    }

    return !isEqualWith(staffStateBeforeChanges, staffState, (originalValue, newValue) => {
      if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
        return true;
      }
    });
  }

  function cancelUnsavedChanges() {
    setStaffState(staffStateBeforeChanges);
  }

  const disablePermissions =
    permissions &&
    staffState &&
    staffState.state !== "disabled" &&
    permissions.user_id !== staffState.id &&
    permissions.admin_accounts_disable;

  const disableAction = {
    content: "Disable",
    action: disableStaffAccount,
  };

  const secondaryActions = [
    { content: "Reset Password", action: () => setResetPasswordPopup(true) },
    ...(disablePermissions ? [disableAction] : []),
  ];

  async function handlePasswordReset() {
    const resetRes = await PostPasswordResetCode({ email: staffStateBeforeChanges.email }, true);
    if (resetRes.status !== StatusCode.OK) {
      props.uiActions.showError("Error sending password reset email");
      return;
    }
    setResetPasswordPopup(false);
    props.uiActions.showSuccess("Email Sent Successfully");
  }

  async function handleReactivateAccount() {
    const activateRes = await PutReactivateClientAdmin({ admin_id: staffState.id }, true);

    if (activateRes.status !== StatusCode.OK) {
      props.uiActions.showError("Error reactivating staff account");
    }

    history.push("/admin/settings/client-settings/staff");
  }

  const primaryAction = { content: "Reactivate Account", action: () => setReactivateAccountPopup(true) };

  return (
    <Page
      title={`Edit Staff Member`}
      narrow
      breadcrumbs={[{ prefix: true, label: "Staff Accounts", url: "/admin/settings/client-settings/staff" }]}
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: saveStaffDetails,
        onCancel: cancelUnsavedChanges,
      }}
      primaryAction={staffState?.state === "disabled" ? primaryAction : null}
      secondaryActions={secondaryActions}
    >
      <div className="mb-4">
        {staffState?.state === "disabled" ? (
          <Badge
            type="error"
            size="medium"
            iconLeft={<Icon icon="times" style="far" size="xsmall" />}
            iconSize="small"
            iconStyle="fas"
          >
            Disabled
          </Badge>
        ) : null}
      </div>
      {staffState && staffStateBeforeChanges && (
        <Form>
          <Card>
            <Card.Section>
              <FormLayout>
                <FormLayout.Group>
                  <Input
                    value={staffState.first_name}
                    label="First Name"
                    id="first_name"
                    onChange={handleInputChange}
                    placeholder="First Name"
                    disabled={
                      staffState.state === "disabled" ||
                      (permissions?.user_id !== staffState.id && !permissions?.admin_accounts_edit)
                    }
                  />
                  <Input
                    value={staffState.last_name}
                    label="Last Name"
                    id="last_name"
                    onChange={handleInputChange}
                    placeholder="Last Name"
                    disabled={
                      staffState.state === "disabled" ||
                      (permissions?.user_id !== staffState.id && !permissions?.admin_accounts_edit)
                    }
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Input
                    value={staffState.email}
                    label="Email"
                    id="email"
                    onChange={handleInputChange}
                    placeholder="Email"
                    disabled={
                      staffState.state === "disabled" ||
                      (permissions?.user_id !== staffState.id && !permissions?.admin_accounts_edit)
                    }
                  />
                  <Input
                    value={staffState.username}
                    label="Username"
                    id="username"
                    onChange={handleInputChange}
                    placeholder="Username"
                    disabled={
                      staffState.state === "disabled" ||
                      (permissions?.user_id !== staffState.id && !permissions?.admin_accounts_edit)
                    }
                  />
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>
          </Card>
          {staffState?.state !== "disabled" &&
            permissions?.user_id !== staffState.id &&
            permissions?.admin_accounts_edit &&
            permissions?.admin_permissions_edit && (
              <Card title="Permissions">
                <Card.Section title={"Products"}>
                  <FormLayout>
                    <FormLayout.Group>
                      <Toggle
                        labelRight={"Archive Products"}
                        id="products_archive"
                        onChange={handleToggleChange}
                        checked={staffState.permissions.products_archive}
                        size="medium"
                      />
                      <Toggle
                        labelRight={"Create Products"}
                        id="products_create"
                        onChange={handleToggleChange}
                        checked={staffState.permissions.products_create}
                        size="medium"
                      />
                    </FormLayout.Group>
                    <FormLayout.Group>
                      <div>
                        <Toggle
                          labelRight={"Edit Products"}
                          onChange={handleProductsEditToggleChange}
                          checked={staffState.permissions.products_edit}
                          size="medium"
                        />
                        {staffState.permissions.products_edit && (
                          <div className="ml-3">
                            <Toggle
                              labelRight={"Edit Product Cost"}
                              id="products_edit_cost"
                              onChange={handleToggleChange}
                              checked={staffState.permissions.products_edit_cost}
                              size="medium"
                            />
                            <Toggle
                              labelRight={"Edit Product Price"}
                              id="products_edit_price"
                              onChange={handleToggleChange}
                              checked={staffState.permissions.products_edit_price}
                              size="medium"
                            />
                          </div>
                        )}
                      </div>
                    </FormLayout.Group>
                  </FormLayout>
                </Card.Section>
                <Card.Section title={"Reports"}>
                  <FormLayout>
                    <FormLayout.Group>
                      <Toggle
                        labelRight={"Reports Access"}
                        id="reports_access"
                        onChange={handleToggleChange}
                        checked={staffState.permissions.reports_access}
                        size="medium"
                      />
                    </FormLayout.Group>
                    {staffState.permissions.reports_access && (
                      <div>
                        <FormLayout.Group>
                          <Toggle
                            labelRight={"Scheduled Payments"} // TODO: Translation
                            id="reports_client_scheduled_payments"
                            onChange={handleToggleChange}
                            checked={staffState.permissions.reports_client_scheduled_payments}
                            size="medium"
                            disabled={permissions?.user_id === staffState.id}
                          />
                          <Toggle
                            labelRight={"Account Adjustments"} // TODO: Translation
                            id="reports_client_account_adjustments"
                            onChange={handleToggleChange}
                            checked={staffState.permissions.reports_client_account_adjustments}
                            size="medium"
                            disabled={permissions?.user_id === staffState.id}
                          />
                          <Toggle
                            labelRight={"Aged Accounts Receivable"} // TODO: Translation
                            id="reports_client_aged_accounts_receivable"
                            onChange={handleToggleChange}
                            checked={staffState.permissions.reports_client_aged_accounts_receivable}
                            size="medium"
                            disabled={permissions?.user_id === staffState.id}
                          />
                        </FormLayout.Group>
                        <FormLayout.Group>
                          <Toggle
                            labelRight={"Detailed Sales By Department"} // TODO: Translation
                            id="reports_client_detailed_sales_by_department"
                            onChange={handleToggleChange}
                            checked={staffState.permissions.reports_client_detailed_sales_by_department}
                            size="medium"
                            disabled={permissions?.user_id === staffState.id}
                          />
                          <Toggle
                            labelRight={"Membership Sales"} // TODO: Translation
                            id="reports_client_membership_sales"
                            onChange={handleToggleChange}
                            checked={staffState.permissions.reports_client_membership_sales}
                            size="medium"
                            disabled={permissions?.user_id === staffState.id}
                          />
                          <Toggle
                            labelRight={"Order Exceptions"} // TODO: Translation
                            id="reports_client_order_exceptions"
                            onChange={handleToggleChange}
                            checked={staffState.permissions.reports_client_order_exceptions}
                            size="medium"
                            disabled={permissions?.user_id === staffState.id}
                          />
                        </FormLayout.Group>
                        <FormLayout.Group>
                          <Toggle
                            labelRight={"Tee Sheet Utilization"} // TODO: Translation
                            id="reports_client_tee_sheet_utilization"
                            onChange={handleToggleChange}
                            checked={staffState.permissions.reports_client_tee_sheet_utilization}
                            size="medium"
                            disabled={permissions?.user_id === staffState.id}
                          />
                        </FormLayout.Group>
                      </div>
                    )}
                  </FormLayout>
                </Card.Section>
                <Card.Section title={"Tee Sheet"}>
                  <FormLayout>
                    <FormLayout.Group>
                      <Toggle
                        labelRight={"Credit Card Override"}
                        id="tee_sheet_credit_card_override"
                        onChange={handleToggleChange}
                        checked={staffState.permissions.tee_sheet_credit_card_override}
                        size="medium"
                      />
                    </FormLayout.Group>
                  </FormLayout>
                </Card.Section>
                <Card.Section title="Customers">
                  <FormLayout>
                    <FormLayout.Group>
                      <Toggle
                        labelRight={"Edit Customer Type"}
                        id="user_edit_customer_type"
                        onChange={handleToggleChange}
                        checked={staffState.permissions.user_edit_customer_type}
                        size="medium"
                      />
                    </FormLayout.Group>
                  </FormLayout>
                </Card.Section>
                <Card.Section title="Orders">
                  <FormLayout>
                    <FormLayout.Group>
                      <Toggle
                        labelRight={"Import Gift Cards"}
                        id="gift_cards_import"
                        onChange={handleToggleChange}
                        checked={staffState.permissions.gift_cards_import}
                        size="medium"
                      />
                    </FormLayout.Group>
                  </FormLayout>
                </Card.Section>
              </Card>
            )}
        </Form>
      )}

      <Popup
        open={resetPasswordPopup}
        type="info"
        title="Reset Account Password?"
        description="An email will be sent to reset your account password."
        onOk={handlePasswordReset}
        okText="Send"
        cancelText="Cancel"
        onCancel={() => setResetPasswordPopup(false)}
      />
      <Popup
        open={reactivateAccountPopup}
        type="info"
        title="Reactivate Staff Account?"
        description="Are you sure you want to reactivate this staff account?"
        onOk={handleReactivateAccount}
        okText="Activate"
        cancelText="Cancel"
        onCancel={() => setReactivateAccountPopup(false)}
      />
    </Page>
  );
}
