import React, { useEffect, useState } from "react";
import { RouteProps, useHistory, useParams } from "react-router";

import { StatusCode } from "api/protocols";
import { dequeue, enqueue, showError, showSuccess } from "redux/actions/ui";

// import { useGuestPortalContext } from "./GuestPortalContext";
import { useAppDispatch } from "hooks/redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IFacility } from "pages/guest/models/facility";
import "./portalFavourites.scss";
import Popup from "components/popup/Popup";
import Input from "components/form/input";
import Callout from "components/callout/Callout";
import { clientPortalUpdate } from "redux/actions/clientPortal";
import {
  DeleteCustomerFacilityFavourite,
  GetCustomerFacilityFavourites,
  GetFacility,
} from "api/rpc/2024-04/customer/facility";
import { sortFacilities } from "redux/reducers/clientPortal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { MOBILE_WIDTH } from "helpers/ScreenSizes";
import { GetClient } from "api/rpc/2024-04/customer/client";
import BottomSheet from "components/bottomSheet/BottomSheet";

interface IFilterState {
  removeFavouriteOpen: boolean;
  search: string;
  courseToRemove: IFacility;
  favouriteCourses: IFacility[];
}

export default function PortalFavourites(props: RouteProps) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [animateIndex, setAnimateIndex] = useState<number>(null);
  const windowSize = useWindowSize();

  const [filterState, setFilterState] = useState<IFilterState>({
    favouriteCourses: null,
    courseToRemove: null,
    removeFavouriteOpen: false,
    search: "",
  });

  useEffect(() => {
    void loadCourses();
  }, []);

  async function loadCourses() {
    const coursesRes = await GetCustomerFacilityFavourites(null, true);
    if (coursesRes.status !== StatusCode.OK) {
      dispatch(showError("Error getting facility information"));
      return;
    }

    setFilterState(prevState => ({
      ...prevState,

      favouriteCourses: coursesRes.data,
    }));
  }

  function handleRemoveFavourite(e: React.MouseEvent<HTMLDivElement>, course: IFacility) {
    e.stopPropagation();
    setFilterState(prevState => ({ ...prevState, courseToRemove: course, removeFavouriteOpen: true }));
  }

  async function removeFavourite() {
    const params = {
      facility_id: filterState?.courseToRemove?.id,
    };

    const deleteRes = await DeleteCustomerFacilityFavourite(params, true);

    if (deleteRes.status !== StatusCode.OK) {
      dispatch(showError("Error removing course from favourites"));
      return;
    }

    dispatch(showSuccess("Successfully removed course from favourites"));

    setFilterState(prevState => ({ ...prevState, removeFavouriteOpen: false, courseToRemove: null }));
    void loadCourses();
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;
    setFilterState(prevState => ({ ...prevState, [id]: value }));
  }

  async function navigateToClientPortal(facility: IFacility, clientId: number) {
    dispatch(enqueue());
    const clientRes = await GetClient({ id: clientId }, false);
    if (clientRes?.status !== StatusCode.OK) {
      dispatch(showError("Error getting client"));
      dispatch(dequeue());
      return;
    }
    const facilityRes = await GetFacility({ client_id: clientId }, false);
    dispatch(dequeue());
    if (facilityRes?.status !== StatusCode.OK) {
      dispatch(showError("Error getting facility"));
      return;
    }
    const sortedFacilities = sortFacilities([...facilityRes?.data]);
    const client = clientRes?.data[0];
    const selectedClient = { ...client, facilities: sortedFacilities };
    dispatch(clientPortalUpdate({ client: selectedClient, selectedFacility: facility }));
    history.push(`/portal/${client?.short_name}/teetimes/${facility?.short_name}`);
  }

  return (
    <>
      <div className="portal-favourites-heading_container">
        <h1 className="portal-favourites-heading">Favourites</h1>
        {filterState?.favouriteCourses && filterState?.favouriteCourses?.length > 0 && (
          <div className="portal-favourites-search">
            <Input
              value={filterState.search}
              placeholder={"Search favourites.."}
              onChange={handleInputChange}
              type="text"
              labelHidden
              id="search"
            />
          </div>
        )}
      </div>

      {filterState?.favouriteCourses &&
        (filterState?.favouriteCourses?.length > 0 ? (
          <div className="portal-favourites-courses_container">
            {filterState.favouriteCourses
              ?.filter(course => course.long_name.toLowerCase().includes(filterState.search.toLowerCase()))
              .map((course, index: number) => {
                return windowSize.width > MOBILE_WIDTH ? (
                  <div
                    key={index}
                    className="portal-favourites-course_container"
                    onClick={() => navigateToClientPortal(course, course?.client_id)}
                  >
                    <div className="portal-favourites-course_image">
                      <img src={course?.logo_source} alt={course.long_name} />

                      <div
                        className="portal-favourites-heart_container"
                        onClick={e => handleRemoveFavourite(e, course)}
                      >
                        <OverlayTrigger
                          placement="auto"
                          overlay={<Tooltip id="courseFavouritesToolTip">Remove from favourites</Tooltip>}
                        >
                          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <FontAwesomeIcon
                              onMouseEnter={() => setAnimateIndex(index)}
                              onMouseLeave={() => setAnimateIndex(null)}
                              beatFade={animateIndex === index}
                              icon={["fas", "star"]}
                              className="portal-favourites-inner_heart"
                            />
                          </div>
                        </OverlayTrigger>
                      </div>
                    </div>

                    <div className="portal-favourites-course_full_name">{course?.city}</div>

                    <div className="flex justify-between items-center">
                      <div className="portal-favourites-course_long_name">{course.long_name}</div>
                      <div className="">
                        <FontAwesomeIcon icon={["fas", "arrow-up-right"]} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    key={index}
                    className="portal-favourites-course_container-mobile"
                    onClick={() => navigateToClientPortal(course, course?.client_id)}
                  >
                    <div className="portal-favourites-course_container-mobile course-name-group">
                      <div className="portal-favourites-course_image-container-mobile">
                        <img src={course?.logo_source} alt={course.long_name} />
                      </div>
                      <div className="portal-favourites-course-info-mobile">
                        <p className="course-name portal-favourites-text-overflow">{course.long_name}</p>
                        <p className="course-address portal-favourites-text-overflow">
                          {course?.address_line_1}, {course?.city}, {course?.province_code}
                        </p>
                      </div>
                    </div>

                    <FontAwesomeIcon size="1x" icon={["far", "angle-right"]} />
                  </div>
                );
              })}
          </div>
        ) : (
          <Callout type="info" title="No favourites" content="You do not have any courses added to your favourites" />
        ))}

      <Popup
        open={filterState.removeFavouriteOpen && windowSize.width > MOBILE_WIDTH}
        type="warning"
        title={"Remove From Favourites?"}
        description={`Are you sure you want to remove ${filterState?.courseToRemove?.long_name} from your favourites?`}
        onOk={removeFavourite}
        okText={"Remove"}
        onCancel={() =>
          setFilterState(prevState => ({ ...prevState, removeFavouriteOpen: false, courseToRemove: null }))
        }
        backDropCancel={true}
      />

      <BottomSheet
        open={filterState.removeFavouriteOpen && windowSize.width <= MOBILE_WIDTH}
        onClose={() =>
          setFilterState(prevState => ({ ...prevState, removeFavouriteOpen: false, courseToRemove: null }))
        }
        type="warning"
        content={`Are you sure you want to remove ${filterState?.courseToRemove?.long_name} from your favourites?`}
        primaryAction={{ label: "Remove", onClick: removeFavourite }}
      />
    </>
  );
}
