import { useEffect, useMemo, useState } from "react";
import axios, { CancelToken } from "axios";
import { useHistory } from "react-router";

import { StatusCode } from "api/protocols";
import { GetTaxLine } from "api/rpc/2024-04/facilityAdmin/client/taxLines";
import { GetAccountingReference } from "api/rpc/2024-04/facilityAdmin/facility/accounting";
import { GetDepartments } from "api/rpc/2024-04/facilityAdmin/facility/department";
import { GetKitchenLocations } from "api/rpc/2024-04/facilityAdmin/facility/kitchen/location";
import { GetSalesChannels, UpdateSalesChannel } from "api/rpc/2024-04/facilityAdmin/facility/salesChannel";
import { GetModifierGroup } from "api/rpc/2024-04/facilityAdmin/product/modifier/modifierGroup";
import {
  AddProductToVariant,
  DuplicateProduct,
  GetProduct,
  PostProductOption,
  PutProduct,
  PutProductAttach,
  PutProductDetach,
  RemoveProductFromVariant,
  TPutProduct,
  UpdateComboProduct,
} from "api/rpc/2024-04/facilityAdmin/product/product";
import { PostProductImage, DeleteProductImage } from "api/rpc/2024-04/facilityAdmin/product/image";
import { GetProductTypes } from "api/rpc/2024-04/facilityAdmin/product/type";
import { GetVendor } from "api/rpc/2024-04/facilityAdmin/product/vendor";
import {
  PostVariant,
  PutVariant,
  PutVariantLabels,
  PutVariantsFacilityAccess,
  TPutVariantFacilityAccess,
  TPutVariantLabel,
} from "api/rpc/2024-04/facilityAdmin/product/variant";
import { AttachTicket, DetachTicket, GetTickets, TGetTicket } from "api/rpc/2024-04/facilityAdmin/ticket/ticket";
import { ITicket } from "api/rpc/2022-09/facilityAdmin/ticket/ticket";
import { TPutProductInventory, UpdateInventoryLevels } from "api/rpc/2024-04/facilityAdmin/product/inventory";
import { AttachModifierGroup, DetachModifierGroup } from "api/rpc/2024-04/facilityAdmin/product/modifier/modifier";
import { AddFormToVariant, GetFacilityForm } from "api/rpc/2024-04/facilityAdmin/client/form";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { generateRequiredKeys, generateTime, validateRequired } from "helpers/Helpers";
import { showError, showSuccess } from "redux/actions/ui";
import {
  TAccountingReference,
  TFacilityForm,
  TModifierGroup,
  TModifierGroupExtended,
  TTaxLines,
  TVariantForm,
} from "redux/reducers/models/facility";
import { TKitchenLocation } from "redux/reducers/models/kitchen";
import {
  IDepartmentBase,
  IProduct,
  IProductImage,
  IProductTicket,
  ISalesChannel,
  IVariant,
  TComboVariants,
  TProductAccountingReference,
  TProductType,
  TProductVendor,
} from "redux/reducers/models/product";
import { GetProvince } from "api/rpc/2022-09/common/country/country";
import { IProvince } from "redux/reducers/models/customer";

export type TProductEdit = TPutProduct & {
  facility_access: boolean;
  cannot_be_sold: boolean;
  variants: IVariant[];
  sales_channels: ISalesChannel[];
  available_on_time: string;
  unavailable_on_time: string;
};

type TProductVariantFormState = {
  variant: IVariant;
  forms: TVariantForm[];
};

const defaultProduct: IProduct & {
  available_on: string;
  available_on_time: string;
  unavailable_on_time: string;
  unavailable_on: string;
} = {
  account_id: 0,
  accounting_reference: undefined as TProductAccountingReference,
  accounting_reference_id: 0,
  applicable_taxes: "facility",
  available_on: undefined,
  available_on_time: null,
  button_color: null,
  cannot_be_sold: false,
  category: null,
  category_id: -1,
  client_id: 0,
  customer_required: false,
  default_image: undefined,
  department: undefined,
  department_id: -1,
  description: null,
  facility_access: false,
  fulfillment_required: false,
  gift_card: false,
  handle: "",
  has_modifiers: false, // BOOLEAN
  modifier_groups: [],
  id: 0,
  images: undefined,
  internal_description: null,
  kitchen_location_id: -1,
  meta: {
    product_id: undefined,
    facility_id: undefined,
    has_modifiers: false,
    kitchen_location_id: -1,
    kitchen_location_id_2: -1,
    kitchen_location_id_3: -1,
    preferred_title: "",
    button_color: "",
    price_locked: false,
  },
  options: [],
  preferred_title: "",
  price_locked: false,
  sales_channels: [],
  shipping_required: false,
  subcategory_id: -1,
  subtitle: "",
  tags: [],
  title: "",
  track_inventory: false,
  type: "",
  unavailable_on: undefined,
  unavailable_on_time: null,
  variant_count: 0,
  variants: [],
  vendor: { id: 0, title: "" } as TProductVendor,
  vendor_id: undefined,
  vendor_title: undefined,
};

export default function useEditProduct(id: string | number) {
  const history = useHistory();

  const facilityStore = useAppSelector(store => store.facilityStore);
  const dispatch = useAppDispatch();

  const [attempted, setAttempted] = useState(false);
  const [product, setProduct] = useState<TProductEdit>(undefined);
  const [productPreserved, setProductPreserved] = useState<TProductEdit>(undefined);

  // Async data strictly with a MODAL
  const [labelVariants, setLabelVariants] = useState<Pick<IVariant, "id" | "quantity" | "position" | "title">[]>([]); // Print Label modal
  const [productImages, setProductImages] = useState<IProductImage[]>([]);
  const [comboProducts, setComboProducts] = useState<TComboVariants[]>([]);
  const [productModifiers, setProductModifiers] = useState<TModifierGroup[]>([]);
  const [modifierGroups, setModifierGroups] = useState<TModifierGroupExtended[]>([]);

  const [availableAtDate, setAvailableAtDate] = useState(null as Date);
  const [availableUntilDate, setAvailableUntilDate] = useState(null as Date);

  const [accountingReferences, setAccountingRefereces] = useState<TAccountingReference[]>([]);
  const [salesChannels, setSalesChannels] = useState<Array<ISalesChannel & { checked: boolean }>>([]);
  const [departments, setDepartments] = useState<IDepartmentBase[]>([]);
  const [vendors, setVendors] = useState<TProductVendor[]>([]);
  const [tickets, setTickets] = useState<ITicket[]>(undefined);
  const [productTypes, setProductTypes] = useState<TProductType[]>([]);
  const [taxlines, setTaxlines] = useState<Array<TTaxLines & { province_code: string }>>([]);
  const [kitchenLocations, setKitchenLocations] = useState<TKitchenLocation[]>([]);
  const [forms, setForms] = useState({
    all: [] as TFacilityForm[],
    variants: undefined as TProductVariantFormState[],
  });

  const [provinces, setProvinces] = useState<IProvince[]>([]);

  // memoized required keys of an IProduct.  Determined by facilityStore,validationNewProduct.required if found
  const requiredKeys = useMemo(() => {
    if (
      facilityStore.validationNewProduct.required !== undefined &&
      typeof facilityStore.validationNewProduct.required !== "string"
    ) {
      const { client_id, created_at, deleted_at, id, updated_at, ...rest } =
        facilityStore.validationNewProduct.required;
      return generateRequiredKeys<Omit<TPutProduct, "id">>(rest as Record<string, boolean>, "product_");
    }
    return [];
  }, [facilityStore.validationNewProduct.required]);

  // Toggle active sales channels after product loaded
  useEffect(() => {
    if (product === undefined) {
      return;
    }

    // Sales Channels
    if (!salesChannels.every(channel => !channel.checked)) {
      return;
    }
    setSalesChannels(
      salesChannels.map((channel, index) => {
        if (product.sales_channels.find(val => val.id === channel.id)) {
          return {
            ...channel,
            checked: true,
          };
        } else {
          return { ...channel };
        }
      }),
    );
  }, [product]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    void loadAllModifierGroups(source.token); // response does not contain a product_id.  Therefore 2 calls on load
    void loadAccountingRefereces(source.token);
    void loadSalesChannels(source.token);
    void loadDepartments(source.token);
    void loadVendors(source.token);
    void loadProductTypes(source.token);
    void loadKitchenLocations(source.token);
    void loadFacilityForms(source.token);

    return () => source.cancel();
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadProduct(id, source.token);
    void loadModifierGroup(Number(id), source.token); // response does not contain a product_id.  Therefore 2 calls on load
    return () => source.cancel();
  }, [id]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (facilityStore.facility) {
      void loadTaxLines(source.token);
    }

    return () => source.cancel();
  }, [facilityStore.facility]);

  async function loadProduct(id: string | number, token?: CancelToken) {
    const res = await GetProduct(
      { id: id, extended: true, extended_variants: true, inventory: true, sales_channels: true, all: true },
      token ? false : true,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading product."));
      setProduct(defaultProduct);
      return;
    }

    const normalizedProduct: TProductEdit = {
      id,
      facility_access: res.data[0].facility_access,
      cannot_be_sold: Boolean(res.data[0].cannot_be_sold),

      title: res.data[0].title,
      subtitle: res.data[0].subtitle,
      preferred_title: res.data[0].preferred_title,
      description: res.data[0].description,
      internal_description: res.data[0].internal_description,

      meta: {
        ...res.data[0].meta,
        kitchen_location_id: res.data[0].meta?.kitchen_location_id ?? -1,
        kitchen_location_id_2: res.data[0].meta?.kitchen_location_id_2 ?? -1,
        kitchen_location_id_3: res.data[0].meta?.kitchen_location_id_3 ?? -1,
        button_color: res.data[0].meta?.button_color,
      },

      type: res.data[0].type,
      vendor_id: res.data[0].vendor_id ?? undefined,
      accounting_reference_id: res.data[0].accounting_reference_id ?? undefined,
      customer_required: res.data[0].customer_required ?? false,
      shipping_required: res.data[0].shipping_required ?? false,
      fulfillment_required: res.data[0].fulfillment_required ?? false,

      department_id: res.data[0].department_id ?? -1,
      category_id: res.data[0].category_id ?? -1,
      subcategory_id: res.data[0].subcategory_id ?? -1,

      track_inventory: res.data[0].track_inventory ? Boolean(res.data[0].track_inventory) : false,

      variants: res.data[0].variants,

      // Only displayed on page with a single variant
      price: res.data[0].variants[0].price,
      original_price: res.data[0].variants[0].original_price,
      cost: res.data[0].variants[0].cost,
      barcode: res.data[0].variants[0].barcode,
      sku: res.data[0].variants[0].sku,

      sales_channels: res.data[0].sales_channels ?? [],

      available_on: res.data[0].available_on ? new Date(res.data[0].available_on).toLocaleDateString() : null,
      available_on_time: generateTime(res.data[0].available_on),
      unavailable_on: res.data[0].unavailable_on ? new Date(res.data[0].unavailable_on).toLocaleDateString() : null,
      unavailable_on_time: generateTime(res.data[0].unavailable_on),
    };

    setProduct(normalizedProduct);
    setProductPreserved(normalizedProduct);

    setLabelVariants(
      res.data[0].variants.map(variant => ({
        id: variant.id,
        quantity: 0,
        position: variant.position,
        title: variant.title,
      })),
    );

    // Seperate from products state because its its own API call away from unsavedChangesExist bar
    setProductImages(res.data[0].images);
    setComboProducts(res.data[0].variants.length === 0 ? [] : res.data[0].variants[0].combination_variants);

    // handle dates
    if (res.data[0].available_on) {
      setAvailableAtDate(new Date(res.data[0].available_on));
    }
    if (res.data[0].unavailable_on) {
      setAvailableUntilDate(new Date(res.data[0].unavailable_on));
    }
  }

  /** Load only modifier groups attached to the product. */
  async function loadModifierGroup(product_id: number, token?: CancelToken) {
    const res = await GetModifierGroup({ product_id }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      return;
    }

    setProductModifiers(res.data as TModifierGroup[]);
  }

  /** Load all modifier groups with their attached variants. */
  async function loadAllModifierGroups(token?: CancelToken) {
    const res = await GetModifierGroup({ extended: true }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      return;
    }

    setModifierGroups(res.data as TModifierGroupExtended[]);
  }

  async function loadAccountingRefereces(token?: CancelToken) {
    const res = await GetAccountingReference(null, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      return;
    }

    setAccountingRefereces(res.data);
  }

  async function loadSalesChannels(token?: CancelToken) {
    const res = await GetSalesChannels(null, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      return;
    }

    setSalesChannels((res.data as ISalesChannel[]).map(channel => ({ ...channel, checked: false })));
  }

  async function loadDepartments(token: CancelToken) {
    const res = await GetDepartments(token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      return;
    }

    setDepartments(res.data);
  }

  async function loadVendors(token: CancelToken) {
    const res = await GetVendor(null, false, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      return;
    }

    setVendors(res.data);
  }

  async function loadProductTypes(token: CancelToken) {
    const res = await GetProductTypes(null, false, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      return;
    }

    setProductTypes(res.data);
  }

  async function loadTaxLines(token?: CancelToken) {
    const res = await GetTaxLine(null, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      return;
    }

    const provinceRes = await GetProvince({ country_id: facilityStore.facility.country_id }, false, token);
    if (provinceRes.status !== StatusCode.OK) {
      return;
    }
    const taxLines: Array<TTaxLines & { province_code: string }> = [];
    res.data.forEach(taxLine => {
      const foundProvinceCode = provinceRes.data.find((province: IProvince) => province.id === taxLine.province_id);
      if (foundProvinceCode) {
        taxLines.push({ ...taxLine, province_code: foundProvinceCode.code });
      }
    });

    setProvinces(provinceRes.data);
    setTaxlines(taxLines);
  }

  async function loadKitchenLocations(token?: CancelToken) {
    const res = await GetKitchenLocations(null, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      return;
    }

    setKitchenLocations(res.data as TKitchenLocation[]);
  }

  /** Sets tickets to undefined while API call completes */
  async function loadTickets(search: string, token?: CancelToken) {
    if (tickets !== undefined) {
      setTickets(undefined);
    }

    const params: TGetTicket = {
      search: search ?? null,
    };

    const res = await GetTickets(params, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      setTickets([]);
      return;
    }

    setTickets(res.data);
  }

  async function loadFacilityForms(token?: CancelToken) {
    const res = await GetFacilityForm(null, token ? false : true, token);
    if (token && token?.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading facility forms.")); // TODO: Translation
      return;
    }

    setForms(prev => ({ ...prev, all: res.data as TFacilityForm[] }));
  }

  /** Sets forms.variants to undefined while API call completes */
  async function loadVariantForms(params: { variant_ids: Array<number> }, token?: CancelToken) {
    if (forms.variants !== undefined) {
      setForms(prev => ({ ...prev, variants: undefined }));
    }

    const res = await GetFacilityForm(params, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading variant forms.")); // TODO: Translation
    }

    let variantsWithForms = [
      ...product.variants.map(variant => ({
        variant: variant,
        forms: [] as TVariantForm[],
      })),
    ];

    for (let i = 0; i < variantsWithForms.length; i++) {
      for (let k = 0; k < res.data.length; k++) {
        if (variantsWithForms[i].variant.id === (res.data as TVariantForm[])[k].variant_id) {
          variantsWithForms[i].forms.push((res.data as TVariantForm[])[k]);
        }
      }
    }

    variantsWithForms = variantsWithForms.filter(val => val.forms.length > 0);
    setForms(prev => ({ ...prev, variants: res.status !== StatusCode.OK ? [] : variantsWithForms }));
  }

  // PUT product & PUT Variants' Facility-Access (void saveVariantFacilityAccess)
  async function saveProduct(params: TPutProduct & { facility_access?: boolean; cannot_be_sold: boolean }) {
    // Ensure validation check passes
    const validParams = validateRequired(product, requiredKeys);
    if (!validParams) {
      setAttempted(true); // Toggle UI errors to user
      dispatch(showError("Please fill in all required inputs.")); // TODO: Translation
      return;
    }

    const res = await PutProduct(params, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error updating product."));
      return;
    }

    // udpate states
    setProduct(prev => ({
      ...prev,
      ...params,
      available_on: prev.available_on, // params contain wrong date format
      unavailable_on: prev.unavailable_on, // params contain wrong date format
      meta: {
        ...prev.meta,
        cannot_be_sold: params.meta.cannot_be_sold,
      },
    }));
    setProductPreserved(prev => ({
      ...prev,
      ...params,
      available_on: product.available_on,
      unavailable_on: product.unavailable_on,
      available_on_time: product.available_on_time,
      unavailable_on_time: product.unavailable_on_time,
      meta: {
        ...product.meta,
        cannot_be_sold: params.meta.cannot_be_sold,
      },
    }));

    const updatedVariantFacilityAccess = product.variants.filter(variant => {
      const pastVariant = productPreserved.variants.find(prev => prev.id === variant.id);
      if (pastVariant === undefined) {
        return false;
      }
      return variant.facility_access !== pastVariant.facility_access;
    });

    // Check for secondary API calls needed
    if (
      updatedVariantFacilityAccess.length > 0 ||
      (product.variants.length === 1 && product.type.toLowerCase() === "ticket")
    ) {
      // Update variant facility access'
      if (updatedVariantFacilityAccess.length > 0) {
        void saveVariantFacilityAccess(
          {
            variants: updatedVariantFacilityAccess.map(vari => ({
              id: vari.id,
              facility_access: vari.facility_access,
            })),
          },
          true,
        );
      }

      // Check all tickets on page, update if quantity has changed
      if (product.variants.length === 1 && product.type.toLowerCase() === "ticket") {
        product.variants[0].tickets?.map(prodTick => {
          const foundTicket = productPreserved.variants[0].tickets.find(val => val.id == prodTick.id);
          if (foundTicket.quantity !== prodTick.quantity) {
            void addTicket(prodTick.id, prodTick.quantity, true);
          }
        });
      }

      return;
    } else {
      // Just needed product call
      dispatch(showSuccess("Successfully updated product."));
      setAttempted(false);
    }
  }

  async function addTicket(ticket_id: number, quantity?: number, chained?: boolean, refreshProduct?: boolean) {
    const params = {
      ticket_id,
      variant_id: product.variants[0].id,
      quantity: quantity ?? 1,
    };
    const res = await AttachTicket(params, true);

    if (res.status !== StatusCode.OK) {
      dispatch(
        showError(
          chained
            ? "Successfully updated product, but an error occured saving the tickets."
            : "Error updating tickets.",
        ),
      );
      setAttempted(true);
      return;
    }

    if (chained) {
      setAttempted(false);
    }

    if (refreshProduct) {
      void loadProduct(id);
    }
  }

  async function saveVariant(variant: IVariant, chained?: boolean) {
    const res = await PutVariant(variant, true);

    if (res.status !== StatusCode.OK) {
      dispatch(
        showError(
          chained
            ? "Successfully updated product, but an error occured saving the variant(s)."
            : "Error saving variant(s).",
        ),
      );
      setAttempted(true);
      return;
    }

    if (chained) {
      dispatch(showSuccess("Successfully updated product."));
      setAttempted(false);
    }
  }

  async function saveVariantFacilityAccess(variants: TPutVariantFacilityAccess, chained: boolean) {
    const res = await PutVariantsFacilityAccess(variants, true);

    if (res.status !== StatusCode.OK) {
      dispatch(
        showError(
          chained
            ? "Successfully updated product, but an error occured saving the variants' facility access."
            : "Error saving variants' facility access.",
        ),
      );
      setAttempted(true);
      return;
    }

    if (chained) {
      dispatch(showSuccess("Successfully updated product."));
      setAttempted(false);
    }
  }

  /** Navigate to products page on success */
  async function attachProductToFacility() {
    const res = await PutProductAttach({ product_id: id }, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error attaching product to facility inventory."));
      return;
    }

    dispatch(showSuccess("Succesfully attached product to the facility inventory.")); //TODO: Translation
    history.push("/admin/product/");
  }

  /** Navigate to products page on success */
  async function detachProductFromfacility() {
    const res = await PutProductDetach({ product_ids: [Number(id)] }, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error detaching the product."));
      return;
    }

    dispatch(showSuccess("Succesfully detached product.")); //TODO: Translation
    history.push("/admin/product/");
  }

  /** Navigate to products page on success */
  async function duplicateProduct() {
    const res = await DuplicateProduct({ product_id: Number(id) }, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Failed to duplicate the product")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Successfully duplicated the product.")); // TODO: Translation
    history.push("/admin/product/");
  }

  /** Opens new window */
  async function printLabels(offset: string) {
    const wantedVariants = labelVariants
      .filter(val => val.quantity)
      ?.map(updatedVariant => ({ variant_id: updatedVariant.id, quantity: Number(updatedVariant.quantity) }));
    const params: TPutVariantLabel = {
      offset: offset.length === 0 ? 0 : parseInt(offset),
      variants: wantedVariants,
    };

    const res = await PutVariantLabels(params, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Failed to get the variant labels."));
      return;
    }

    // open in new window
    window.open().document.write(res.data);
  }

  /** Navigates to newly created variant edit page on success */
  async function addNewProductOption(params: { name: string; value: string; price: number }) {
    const productOptionParam = { name: params.name, product_id: product.id };
    const res = await PostProductOption(productOptionParam, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError(StatusCode.BAD_REQUEST && typeof res.data === "string" ? res.data : "Error Creating Option.")); // TODO: Translation
      return;
    }

    const variantParam = {
      product_id: product.id,
      option1: params.value,
      price: params.price,
    };

    const variantRes = await PostVariant(variantParam, true);

    if (variantRes.status !== StatusCode.OK) {
      dispatch(
        showError(
          StatusCode.BAD_REQUEST && typeof variantRes.data
            ? variantRes.data
            : "Successfully created option, but an error occured saving the product variant.",
        ),
      );
      return;
    }

    dispatch(showSuccess("Successfully created product option."));
    history.push("/admin/product/" + String(product.id) + "/variant/" + String(variantRes.data.id));
  }

  async function uploadProductImage(imageFile: File) {
    const formData = new FormData();

    formData.append("product_id", String(id));
    formData.append("image", imageFile);

    const imageRes = await PostProductImage(formData, true);
    if (imageRes.status !== StatusCode.OK) {
      dispatch(showError("Error uploading image."));
      return;
    }

    // Always refresh
    void loadProduct(id);
  }

  async function deleteProductImage(id: number, refreshProduct?: boolean) {
    const imageRes = await DeleteProductImage({ id }, true);
    if (imageRes.status !== StatusCode.OK) {
      dispatch(showError("Error removing image."));
      return;
    }

    if (!refreshProduct) {
      setProductImages(productImages.filter(image => image.id !== id));
    } else {
      void loadProduct(id);
    }
  }

  /* Always refreshes product on success */
  async function addComboProduct(params: { base_variant_id: number; variant_id: number }) {
    const res = await AddProductToVariant(params, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error adding combo product.")); // TODO: Translation
      return;
    }

    void loadProduct(id);
  }

  async function removeProductFromCombo(comboVariant: TComboVariants, refreshProduct?: boolean) {
    const params = {
      base_variant_id: product.variants[0]?.id,
      variant_id: comboVariant.id,
    };

    const res = await RemoveProductFromVariant(params, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error removing the product."));
      return;
    }

    if (!refreshProduct) {
      setComboProducts(comboProducts.filter(combo => combo.id !== comboVariant.id));
    } else {
      void loadProduct(id);
    }
  }

  /* Always refreshes product on success */
  async function updateComboProduct(params: {
    base_variant_id: number;
    variant_id: number;
    cost: string;
    price: string;
  }) {
    const res = await UpdateComboProduct(params, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error updating combo product.")); // TODO: Translation
      return;
    }

    void loadProduct(id);
  }

  async function removeTicket(ticket: IProductTicket, refreshProduct?: boolean) {
    const params = {
      ticket_id: ticket.id,
      variant_id: product.variants[0].id,
    };

    const res = await DetachTicket(params, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error removing the product."));
      return;
    }

    if (!refreshProduct) {
      setProduct(prev => ({
        ...prev,
        variants: prev.variants.map(variant => ({
          ...variant,
          tickets: variant.tickets.filter(tick => tick.id !== ticket.id),
        })),
      }));
      setProductPreserved(prev => ({
        ...prev,
        variants: prev.variants.map(variant => ({
          ...variant,
          tickets: variant.tickets.filter(tick => tick.id !== ticket.id),
        })),
      }));
    } else {
      void loadProduct(id);
    }
  }

  async function updateInventory(args: {
    variant_id?: number;
    quantity?: number;
    tax_line_ids?: Array<string | number>;
    refreshProduct?: boolean;
  }) {
    const { variant_id, quantity = undefined, tax_line_ids = undefined, refreshProduct } = args;

    const params: TPutProductInventory = {
      variant_id: variant_id ?? product.variants[0].id,
      quantity_available: quantity,
      tax_line_ids: tax_line_ids,
    };
    const res = await UpdateInventoryLevels(params, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error updating inventory quantity."));
      return;
    }

    if (refreshProduct) {
      void loadProduct(id);
    }
    dispatch(showSuccess("Successfully updated inventory quantity"));
  }

  /** refreshUi will call loadModifierGroup after successful update. */
  async function attachModifierGroupToProduct(
    params: { modifier_group_id: number; required: boolean },
    refreshUi?: boolean,
  ) {
    const res = await AttachModifierGroup({ product_id: Number(id), ...params }, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error attaching modifier group to product."));
      return;
    }

    if (refreshUi) {
      void loadModifierGroup(Number(id));
    }
  }

  async function detachModifierGroupFromProduct(modifier_group_id: number) {
    const res = await DetachModifierGroup({ product_id: Number(id), modifier_group_id }, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error detaching the modifier group."));
      return;
    }

    setProductModifiers(productModifiers.filter(group => group.id !== modifier_group_id));
  }

  /** Pre-toggle the <Toggle /> element before calling endpoint for better UX. */
  async function updateSalesChannel(params: { sales_channel_id: number; remove: boolean }) {
    const successfulState = salesChannels.map(channel => {
      if (channel.id === params.sales_channel_id) {
        return {
          ...channel,
          checked: !channel.checked,
        };
      } else {
        return channel;
      }
    });
    setSalesChannels(successfulState);

    // ASYNC CALL
    const updateParams = {
      product_id: id,
      sales_channel_id: params.sales_channel_id,
      remove: params.remove,
    };

    const res = await UpdateSalesChannel(updateParams, true);

    if (res.status !== StatusCode.OK) {
      // Revert state back and inform user
      dispatch(showError("Something went wrong updating the product sales channels.")); // TODO: Translation

      setSalesChannels(
        successfulState.map(channel => {
          if (channel.id === params.sales_channel_id) {
            return {
              ...channel,
              checked: !channel.checked,
            };
          } else {
            return channel;
          }
        }),
      );

      return;
    }
  }

  async function saveFormToVariant(params: { variant_id: string | number; form_id: string | number }) {
    const res = await AddFormToVariant(params, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error adding form to variant.")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Successfully added form to variant.")); // TODO: Translation
    void loadVariantForms({ variant_ids: product.variants.map(variant => variant.id) });
  }

  /**
   * Validate specific key param.
   * - Used in JSX to help validate specific error styling
   */
  function validated(key: keyof TPutProduct | "cost_with_track_inventory") {
    if (requiredKeys.length === 0) {
      return true;
    }

    if ((requiredKeys as unknown as [keyof TPutProduct | "cost_with_track_inventory"]).includes(key)) {
      return validateRequired(product, [key]);
    }
    return true;
  }

  return {
    product,
    setProduct,
    productPreserved,

    labelVariants,
    setLabelVariants,

    salesChannels,
    setSalesChannels,

    availableAtDate,
    setAvailableAtDate,

    availableUntilDate,
    setAvailableUntilDate,

    forms,
    setForms,

    comboProducts,
    productModifiers,

    productImages,
    modifierGroups,
    accountingReferences,
    departments,
    vendors,
    tickets,
    productTypes,
    taxlines,
    kitchenLocations,

    /** Validation::  Boolean to determine if PUT called once. */
    attempted,
    /** Validation::  Function to validate individual params inline with JSX */
    validated,

    saveProduct,
    /** Redirects user if successful */
    attachProductToFacility,
    /** Redirects user if successful */
    detachProductFromfacility,
    duplicateProduct,
    printLabels,
    uploadProductImage,
    deleteProductImage,
    addComboProduct,
    removeProductFromCombo,
    updateComboProduct,
    /** tickets = undefined while API call completes. */
    loadTickets,
    loadVariantForms,
    addTicket,
    removeTicket,
    updateInventory,
    attachModifierGroupToProduct,
    detachModifierGroupFromProduct,
    updateSalesChannel,
    saveFormToVariant,

    /** POST ProductOption & POST Variant call.  Redirect user if successful */
    addNewProductOption,
  };
}
