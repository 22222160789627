import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { TEventRound } from "redux/reducers/models/league";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/league/scoring/round";

export type TGetLeagueRound = {
  league_id: number;
  id?: number;
  scoresheet_id?: number;
  round_id?: number;
  format_id?: number;
  /** Return round skin sheets with it */
  skins_sheets?: boolean;
  user_id?: number | string;
};

export type TPostLeagueRound = {
  scoresheet_id: string | number;
  customer_id: string | number;
  holes: string | number;
};

export type TFormatRoundData = {
  id: number;
  bonus_points: number;
  participation_points: number;
};

/** Same structure as TPutTournamentRound */
export type TPutLeagueRound = {
  id: string | number;
  tee_deck_id?: number;
  course_id?: number;
  holes?: 9 | 18; // default = 18
  division_front_id?: number;
  division_back_id?: number;
  hole_scores?: any;
  round_handicap?: number;
  handicap_allowance?: number;
  attestor_id?: number;
  attested?: boolean;
  played_alone?: boolean;
  submitted?: boolean;
  points_bonus?: number;
  points_participation?: number;
  format_round_data?: TFormatRoundData[];
};

export type TGetLeagueHoleScores = {
  format_id: number;
  round_id?: number;
  team_id?: number;
};

interface IPrintRounds {
  format_id?: number;
  scoresheet_id: number;
}

//GET League Round
export async function GetLeagueRound(params: TGetLeagueRound, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseUrl, params, useGlobalLoader, token);
}

// POST League Round
export async function PostLeagueRound(params: TPostLeagueRound, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

//PUT Round
export async function PutLeagueRound(params: TPutLeagueRound, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

//DELETE Round
export async function DeleteLeagueRound(params: { round_id: number }, useGlobalLoader: boolean) {
  return await APIDelete(baseUrl, params, useGlobalLoader);
}

//GET League Round
export async function GetLeagueRoundHoleScores(
  params: TGetLeagueHoleScores,
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet<TEventRound[]>(baseUrl + "/hole-scores", params, useGlobalLoader, token);
}

// GET Print league scoresheet
export async function PrintRounds(params: IPrintRounds, useGlobalLoader: boolean) {
  return await APIGet(baseUrl + "/print", params, useGlobalLoader);
}
