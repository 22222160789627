import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import { useAppDispatch } from "hooks/redux";
import axios, { CancelToken } from "axios";

import { GetTvView } from "api/rpc/2024-04/facilityAdmin/league/tvView/tvView";
import { StatusCode } from "api/protocols";

import { showError, showSuccess } from "redux/actions/ui";

import Page from "components/page/Page";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import { TLeagueTvView } from "redux/reducers/models/league";

export default function LeagueTvViews(token?: CancelToken) {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { leagueId } = useParams<{ leagueId: string }>();

  const [views, setViews] = useState<TLeagueTvView[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadTvViews(source.token);
    return () => source.cancel();
  }, []);

  async function loadTvViews(token?: CancelToken) {
    const viewRes = await GetTvView({ league_id: leagueId }, false, token);

    if (token && token.reason) {
      return;
    }

    if (viewRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading TV views")); // TODO: Translation
      return;
    }

    setViews(viewRes.data);
  }

  const primaryAction = {
    content: "New", // TODO: Translation
    action: () => history.push(`/admin/league/${leagueId}/scoring/tv-views/new`),
  };

  return (
    <Page
      title="TV Views" // TODO: Translation
      primaryAction={primaryAction}
    >
      <DataTable
        columns={[
          { label: "Title" }, // TODO: Translation
        ]}
        loading={!views}
      >
        {views?.map(view => (
          <tr
            className="clickable"
            key={view.id}
            onClick={() => history.push(`/admin/league/${leagueId}/scoring/tv-views/${view.id}/edit`)}
          >
            <td>{view.title}</td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
