import React, { useEffect, useRef, useState } from "react";
import "./actionBar.scss";
import { IconName } from "@fortawesome/fontawesome-common-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

interface IPrimaryAction {
  icon: IconName;
  title: string;
  action: (ids: number[]) => void;
}

interface ISecondaryAction {
  icon: IconName;
  title: string;
  action: (ids: number[]) => void;
  disabled?: boolean;
}

interface IActionBarProps {
  children: React.ReactNode;
  visible: boolean;
  selectedItemIds: number[];
  clearSelectedItemIds: () => void;
  itemSingularTitle: string;
  itemPluralTitle: string;
  primaryActions?: IPrimaryAction[];
  secondaryActionSections?: ISecondaryAction[][];
}

interface IActionBarState {
  secondaryActionsDropdownOpen: boolean;
  secondaryActionsDropdownContainerRef: React.MutableRefObject<HTMLDivElement>;
  lastSecondaryActionHovered: boolean;
}

export default function ActionBar(props: IActionBarProps) {
  const {
    children,
    visible,
    selectedItemIds,
    clearSelectedItemIds,
    itemSingularTitle,
    itemPluralTitle,
    primaryActions,
    secondaryActionSections,
  } = props;

  const [actionBarState, setActionBarState] = useState<IActionBarState>({
    secondaryActionsDropdownOpen: false,
    secondaryActionsDropdownContainerRef: useRef<HTMLDivElement>(),
    lastSecondaryActionHovered: false,
  });

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideSecondaryActionsDropdownClick);
    document.addEventListener("touchstart", handleOutsideSecondaryActionsDropdownClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideSecondaryActionsDropdownClick);
      document.removeEventListener("touchstart", handleOutsideSecondaryActionsDropdownClick);
    };
  }, []);

  function handleOutsideSecondaryActionsDropdownClick(event: any) {
    if (
      !actionBarState.secondaryActionsDropdownContainerRef.current ||
      actionBarState.secondaryActionsDropdownContainerRef.current.contains(event.target)
    ) {
      return;
    }

    setActionBarState(prev => ({ ...prev, secondaryActionsDropdownOpen: false }));
  }

  function handleToggleSecondaryActionsDropdown() {
    setActionBarState(prev => ({ ...prev, secondaryActionsDropdownOpen: !prev.secondaryActionsDropdownOpen }));
  }

  function handleSecondaryAction(secondaryAction: ISecondaryAction) {
    secondaryAction?.action(selectedItemIds);

    setActionBarState(prev => ({ ...prev, secondaryActionsDropdownOpen: false }));
  }

  function handleLastSecondaryActionHovered(lastSecondaryActionHovered: boolean) {
    setActionBarState(prev => ({ ...prev, lastSecondaryActionHovered }));
  }

  return (
    <div className="action-bar-container">
      {children}

      <div className="action-bar" style={{ display: visible ? "block" : "none" }}>
        <div className="action-bar-sections">
          <div className="action-bar-selection-section">
            <FontAwesomeIcon
              className="action-bar-selection-section-clear-icon"
              icon={["far", "times"]}
              onClick={clearSelectedItemIds}
            />
            <div>
              {`${selectedItemIds?.length ?? 0} ${selectedItemIds?.length === 1 ? itemSingularTitle : itemPluralTitle}`}
            </div>
          </div>
          {primaryActions?.length > 0 && (
            <>
              <div className="action-bar-section-divider">
                <div className="action-bar-section-divider-vertical-line" />
              </div>
              <div className="action-bar-primary-actions-section">
                {primaryActions.map((primaryAction, index) => {
                  return (
                    <button
                      key={index}
                      type="button"
                      className="action-bar-primary-action"
                      onClick={() => primaryAction.action(selectedItemIds)}
                    >
                      <FontAwesomeIcon className="action-bar-primary-action-icon" icon={["fas", primaryAction.icon]} />
                      <div>{primaryAction.title}</div>
                    </button>
                  );
                })}
              </div>
            </>
          )}
          {secondaryActionSections?.length > 0 && (
            <>
              <div className="action-bar-section-divider">
                <div className="action-bar-section-divider-vertical-line" />
              </div>
              <div
                className="action-bar-secondary-actions-section"
                ref={actionBarState.secondaryActionsDropdownContainerRef}
              >
                <button
                  type="button"
                  className="action-bar-secondary-actions-dropbutton"
                  onClick={handleToggleSecondaryActionsDropdown}
                >
                  <FontAwesomeIcon
                    className="action-bar-secondary-actions-dropbutton-icon"
                    icon={["fas", "ellipsis"]}
                  />
                  <div>More</div>
                </button>

                <div
                  className="action-bar-secondary-actions-dropdown"
                  style={{ display: actionBarState.secondaryActionsDropdownOpen ? "block" : "none" }}
                  onClick={e => e.stopPropagation()}
                >
                  {secondaryActionSections.map((secondaryActions, sectionIndex) => {
                    return (
                      <div key={sectionIndex} className="action-bar-secondary-actions-dropdown-section">
                        <div className="action-bar-secondary-actions">
                          {secondaryActions.map((secondaryAction, actionIndex) => {
                            const isFirstAction = sectionIndex === 0 && actionIndex === 0;
                            const isLastAction =
                              sectionIndex === secondaryActionSections.length - 1 &&
                              actionIndex === secondaryActions.length - 1;

                            return (
                              <button
                                key={actionIndex}
                                type="button"
                                className={classNames(
                                  "action-bar-secondary-action",
                                  { "action-bar-secondary-action-first-action": isFirstAction },
                                  { "action-bar-secondary-action-last-action": isLastAction },
                                )}
                                onClick={() => handleSecondaryAction(secondaryAction)}
                                disabled={secondaryAction.disabled}
                                onMouseOver={isLastAction ? () => handleLastSecondaryActionHovered(true) : undefined}
                                onMouseOut={isLastAction ? () => handleLastSecondaryActionHovered(false) : undefined}
                              >
                                <FontAwesomeIcon icon={["fas", secondaryAction.icon]} />
                                <div>{secondaryAction.title}</div>
                              </button>
                            );
                          })}
                        </div>
                        {sectionIndex < secondaryActionSections.length - 1 && (
                          <hr className="action-bar-secondary-actions-dropdown-section-divider" />
                        )}
                      </div>
                    );
                  })}
                  <div className="action-bar-dropdown-triangle">
                    <div className="action-bar-dropdown-arrow-down">
                      <div
                        className={classNames("action-bar-dropdown-arrow-down-inner", {
                          "action-bar-dropdown-arrow-down-inner-hovered": actionBarState.lastSecondaryActionHovered,
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
