import React, { useEffect, useState } from "react";
import { LocaleCurrency } from "helpers/Locale";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/pro-regular-svg-icons";

import "elements/register/register.scss";
import Tip from "components/tip/Tip";
import { ButtonNew as Button } from "components/buttonNew";
import { ICart } from "redux/reducers/models/cart";
import { useTranslation } from "react-i18next";
import { IOrder } from "redux/reducers/models/order";

interface IProps {
  currency?: string;
  subtotal_price?: number;
  tax_lines?: any;
  total_tip?: number;
  total_price?: number;
  add_tip?: number;
  handleTipTeeSheet?: (tipAmount: number) => void;
  darkStyle?: boolean;
  order?: IOrder;
}

export default function CartTotals(props: IProps) {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div className="flex flex-row mt-4 mb-1">
        <span className="text-lg text-semibold">{t("elements.register.cart_menu.cart_totals.001")}</span>
        <span className="ml-auto text-lg text-semibold">
          <LocaleCurrency currency={props.currency} amount={props.subtotal_price} />
        </span>
      </div>

      <div className="flex-1 flex-row">
        {props.tax_lines &&
          props.tax_lines.map((tax_line: Record<string, any>) => {
            return (
              <div key={tax_line.title} className="flex flex-row mb-1">
                <span className="text-lg text-semibold">
                  {tax_line.title} <span className="text-subdued">{(tax_line.rate * 100).toFixed(2)}%</span>
                </span>
                <span className="ml-auto self-end text-lg text-semibold">
                  <LocaleCurrency currency="cad" amount={tax_line.price} />
                </span>
              </div>
            );
          })}
      </div>

      <div className="flex flex-row mb-1">
        {(props.total_tip > 0 || !!props?.order) && (
          <span className="text-lg text-semibold">{t("elements.register.cart_menu.cart_totals.002")}</span>
        )}
        <span className="ml-auto text-lg text-semibold">
          <Tip
            handleTipTeeSheet={props.handleTipTeeSheet}
            totalTipAmount={props.total_tip}
            totalCartPrice={props.total_price}
            darkStyle={props?.darkStyle ? true : false}
            order={props?.order}
          />
        </span>
      </div>

      <div className="flex justify-end flex-row">
        <span className="font-bold text-lg">{t("elements.register.cart_menu.cart_totals.003")}</span>
        <span className="ml-auto font-bold text-lg">
          <LocaleCurrency currency="cad" amount={props.total_price} />
        </span>
      </div>
    </div>
  );
}
