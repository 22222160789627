import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonNew as Button } from "components/buttonNew";
import { useTranslation } from "react-i18next";
import "elements/register/register.scss";
import Card from "components/card/Card";
import { Badge } from "components/badge/Badge";

interface IProps {
  customers: Record<string, any>[];
  onClick: (customer: Record<string, any>) => void;
  tableLimit: number;
  tableOffset: number;
  handlePagination: (direction: "prev" | "next") => void;
}

export default function MasterCustomerTable(props: IProps) {
  const { t, i18n } = useTranslation();

  function userTypeBadge(type: number) {
    switch (type) {
      case 1:
        return (
          <Badge type="gray">
            <span>
              <FontAwesomeIcon className="mr-2" icon={["fas", "user"]} />
              {"Customer"}
            </span>
          </Badge>
        );
      case 3:
        return (
          <Badge type="gray">
            <span>
              <FontAwesomeIcon className="mr-2" icon={["fas", "user-helmet-safety"]} />
              {"Facility Admin"}
            </span>
          </Badge>
        );
      case 2:
        return (
          <Badge type="gray">
            <span>
              <FontAwesomeIcon className="mr-2" icon={["fas", "user-tie"]} />
              {"Client Admin"}
            </span>
          </Badge>
        );
      case 4:
        return (
          <Badge type="gray">
            <span>
              <FontAwesomeIcon className="mr-2" icon={["fas", "user-secret"]} />
              {"Master Admin"}
            </span>
          </Badge>
        );
      default:
        return (
          <Badge type="gray">
            <span>{type}</span>
          </Badge>
        );
    }
  }

  return (
    <div>
      <Card>
        <Card.Section table="true">
          <table className="ui-table ui-table-clickable">
            <thead>
              <tr>
                <th>{t("elements.customer.customer_table.001")}</th>
                <th>{t("elements.customer.customer_table.003")}</th>
                <th>{t("elements.customer.customer_table.004")}</th>
                <th>{"Type"}</th>
              </tr>
            </thead>
            <tbody>
              {props.customers?.map((customer: Record<string, any>, index: number) => {
                return (
                  <tr key={index} onClick={() => props.onClick(customer)}>
                    <td>
                      <div>{customer.full_name}</div>
                      <div>{customer.email}</div>
                    </td>
                    <td>
                      {customer.phone ? (
                        customer.phone
                      ) : (
                        <span className="field-unavailable">{t("elements.customer.customer_table.006")}</span>
                      )}
                    </td>
                    <td>
                      {customer.default_address_line ? (
                        <>
                          {customer.default_address_line.city}, {customer.default_address_line.province_code}
                        </>
                      ) : (
                        <span className="field-unavailable">{t("elements.customer.customer_table.007")}</span>
                      )}
                    </td>
                    <td>{userTypeBadge(customer.user_type)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Card.Section>
      </Card>

      <div className="flex justify-between mt-3">
        <div>
          {props.tableLimit <= props.tableOffset && (
            <Button size="small" type="secondary" onClick={() => props.handlePagination("prev")}>
              <FontAwesomeIcon icon={"arrow-left"} />
              &nbsp;
              {t("elements.customer.customer_table.008")}
            </Button>
          )}
        </div>

        <div>
          {props.customers.length === props.tableLimit && (
            <Button size="small" type="secondary" onClick={() => props.handlePagination("next")}>
              <FontAwesomeIcon icon={"arrow-right"} />
              &nbsp;
              {t("elements.customer.customer_table.009")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
