import { APIGet, APIPost, APIPut, APIDelete, StatusCode, IAPIResponse, APIUrl, AdminType } from "api/protocols";
import { CancelToken } from "axios";
import { TProductVendor } from "redux/reducers/models/product";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/product/vendor";

export type TGetVendors = {
  id?: string | number;
  /**
   * - search by title
   * - minimum length: > 2
   */
  search?: string;
};

interface IGetVendor {
  id: number;
  search?: string;
}

interface IPostVendor {
  title: string;
}

interface IPutVendor {
  id: number;
  title: string;
}

interface IVendorRes {
  data: TProductVendor[];
  status: StatusCode;
}

interface IPostVendorRes {
  data: TProductVendor[];
  status: StatusCode;
}

export async function GetVendor(params: TGetVendors, useGlobalLoader?: boolean, token?: CancelToken) {
  return await APIGet<TProductVendor[]>(
    APIUrl(AdminType.FacilityAdmin, "/product/vendor"),
    params,
    useGlobalLoader,
    token,
  );
}

export async function PostVendor(params: IPostVendor, useGlobalLoader?: boolean): Promise<IPostVendorRes> {
  return await APIPost(APIUrl(AdminType.FacilityAdmin, "/product/vendor"), params, useGlobalLoader);
}

export async function PutVendor(params: IPutVendor, useGlobalLoader?: boolean) {
  return await APIPut(APIUrl(AdminType.FacilityAdmin, "/product/vendor"), params, useGlobalLoader);
}
