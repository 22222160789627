import React from "react";
import { useHistory, useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { showError } from "redux/actions/ui";
import { StatusCode } from "api/protocols";
import { UserLogout } from "api/rpc";
import { useAppDispatch } from "hooks/redux";
import { NavigationDropdown } from "components/navigationDropdown/NavigationDropdown";
import Weather from "components/weather/Weather";
import "./CustomerNavigation.scss";
import { useTranslation } from "react-i18next";

interface ICustomerNavigationProps {
  activeUser: boolean;
  userFirstName: string;
  weather: any;
}

interface IParams {
  bookingEngineHandle: string;
}

export function CustomerNavigation(props: ICustomerNavigationProps) {
  const { activeUser, userFirstName, weather } = props;
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const { bookingEngineHandle } = useParams<IParams>();
  const tempGolfCourseName = bookingEngineHandle.split("-");
  const golfCourseName = String(tempGolfCourseName[0]);
  const handle = String(bookingEngineHandle);

  const history = useHistory();

  function sendToNextPage(address: any) {
    history.push(address);
  }

  async function logoutUser() {
    const logoutRes = await UserLogout(true);

    if (logoutRes.status !== StatusCode.OK) {
      dispatch(showError(t("elements.customer.customer_navigation.001")));
      return;
    }

    sendToNextPage("/booking-engine/" + golfCourseName + "/" + handle);
    window.location.href = "/booking-engine/" + golfCourseName + "/" + handle;
  }

  function sendUserToLogin() {
    sendToNextPage("/booking-engine/" + golfCourseName + "/" + handle + "/login");
  }

  function sendUserToAccountOverview() {
    const address = "/booking-engine/" + golfCourseName + "/" + handle + "/profile/overview";
    window.open(address);
  }

  function sendUserToReservations() {
    const address = "/booking-engine/" + golfCourseName + "/" + handle + "/profile/reservations";
    window.open(address);
  }

  return (
    <div className="customer-narrow-centered-position-container">
      <div className="customer-narrow-centered-position">
        <div className="customer-navigation-bar">
          <div>
            <img className="customer-navigation-tee-on-logo" src="/public/images/Tee-On.png"></img>
          </div>
          <div>
            {weather && (
              <div className={activeUser ? "mr-5" : ""}>
                <Weather weather={weather} />
              </div>
            )}
            {activeUser ? (
              <NavigationDropdown
                showPlainTextLabel
                label={
                  <span>
                    <span className="customer-navigation-dropdown-label">{`${t(
                      "elements.customer.customer_navigation.006",
                    )}, ${userFirstName}`}</span>
                    <FontAwesomeIcon icon={["fal", "bars"]} />
                  </span>
                }
                options={[
                  { label: t("elements.customer.customer_navigation.002"), onClick: () => sendUserToAccountOverview() },
                  { label: t("elements.customer.customer_navigation.003"), onClick: () => sendUserToReservations() },
                  { label: t("elements.customer.customer_navigation.004"), onClick: () => logoutUser() },
                ]}
              />
            ) : (
              <div>
                <button onClick={sendUserToLogin} className="customer-navigation-login-button">
                  <span className="customer-navigation-login-label">
                    {t("elements.customer.customer_navigation.005")}
                  </span>
                  <FontAwesomeIcon icon={["fal", "arrow-right-long"]} />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
