import React, { useEffect, useRef, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DroppableStateSnapshot,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { ICart, ICartLineItem } from "redux/reducers/models/cart";
import { ButtonNew as Button } from "components/buttonNew";
import "./tableServiceMoveLineItemsModal.scss";
import { intersection, isEqual, range } from "lodash";
import { StatusCode } from "api/protocols";
import Callout from "components/callout/Callout";
import { IUIActions, showSuccess } from "redux/actions/ui";
import { IMoveLineItem } from "api/rpc/2022-09/facilityAdmin/cart/cart";
import { useTranslation, Trans } from "react-i18next";
import { PutMoveLineItems } from "api/rpc/2024-04/facilityAdmin/order/cart/lineItem";
import { ITableServiceState } from "../newTableService/NewTableService";
import { useAppDispatch } from "hooks/redux";

interface IMovableSeatLineItem {
  id: number;
  moveIds: number[];
  cartId: number;
  previousCartId: number;
  preferredTitle: string;
  productTitle: string;
  variantTitle: string;
  originalQuantity: number;
  modifiers: any;
}

interface IMovableSeat {
  id: number;
  allMoveIds: number[];
  seatNumber: number;
  lineItems: IMovableSeatLineItem[];
  customer: any;
}

interface ITableServiceMoveLineItemsModalProps {
  uiActions: IUIActions;
  open: boolean;
  seats: ICart[];
  onCancel: () => void;
  onRefresh: () => void;
  setTableState?: React.Dispatch<React.SetStateAction<ITableServiceState>>;
}

interface ITableServiceMoveLineItemsModalState {
  modalRef: React.MutableRefObject<HTMLDivElement>;
  windowWidth: number;
  tempMoveLineItemsSeats: IMovableSeat[];
  tempMoveLineItemsSeatsBeforeChanges: IMovableSeat[];
}

export function isSeatAbleToMoveLineItems(seat: ICart) {
  return seat.status !== "complete" && seat.status !== "void" && seat.order?.financial_status !== "partially_paid";
}

export default function TableServiceMoveLineItemsModal(props: ITableServiceMoveLineItemsModalProps) {
  const { uiActions, open, seats, onCancel, onRefresh, setTableState } = props;
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const [state, setState] = useState<ITableServiceMoveLineItemsModalState>({
    modalRef: useRef<HTMLDivElement>(null),
    windowWidth: undefined,
    tempMoveLineItemsSeats: undefined,
    tempMoveLineItemsSeatsBeforeChanges: undefined,
  });

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideModalClick);
    document.addEventListener("touchstart", handleOutsideModalClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideModalClick);
      document.removeEventListener("touchstart", handleOutsideModalClick);
    };
  }, []);

  useEffect(() => {
    function handleResize() {
      setState(prev => ({
        ...prev,
        windowWidth: window.innerWidth,
      }));
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function initializeTempMoveLineItemsSeats() {
    const updatedTempMoveLineItemsSeats: IMovableSeat[] = [];

    let currentId = 1;

    for (let i = 0; i < seats.length; i++) {
      const currentSeat: ICart = seats[i];
      const updatedLineItems: IMovableSeatLineItem[] = [];

      if (!isSeatAbleToMoveLineItems(currentSeat)) {
        continue;
      }

      for (let j = 0; j < currentSeat.line_items.length; j++) {
        const currentLineItem: ICartLineItem = currentSeat.line_items[j];
        const endOfIdRange = currentId + (currentLineItem.quantity % 1 === 0 ? currentLineItem.quantity : 1);

        if (currentLineItem.parent_id) {
          continue;
        }

        updatedLineItems.push({
          id: currentLineItem.id,
          moveIds: range(currentId, endOfIdRange),
          cartId: currentLineItem.cart_id,
          previousCartId: currentLineItem.cart_id,
          preferredTitle: currentLineItem.product?.preferred_title,
          productTitle: currentLineItem.product_title,
          variantTitle: currentLineItem.variant_title,
          originalQuantity: currentLineItem.quantity,
          modifiers: currentLineItem.children_line_items ? currentLineItem.children_line_items : null,
        });

        currentId = endOfIdRange;
      }

      const allMoveIds = updatedLineItems
        .map(lineItem => lineItem.moveIds)
        .reduce((prev, next) => [...prev, ...next], []);

      updatedTempMoveLineItemsSeats.push({
        allMoveIds,
        id: currentSeat.id,
        seatNumber: i + 1,
        lineItems: updatedLineItems,
        customer: currentSeat?.customer,
      });
    }

    setState(prev => ({
      ...prev,
      tempMoveLineItemsSeats: updatedTempMoveLineItemsSeats,
      tempMoveLineItemsSeatsBeforeChanges: updatedTempMoveLineItemsSeats,
    }));
  }

  useEffect(() => {
    if (open) {
      initializeTempMoveLineItemsSeats();
    } else {
      setState(prev => ({
        ...prev,
        tempMoveLineItemsSeats: undefined,
        tempMoveLineItemsSeatsBeforeChanges: undefined,
      }));
    }
  }, [open]);

  function calculateHorizontalModalOffset() {
    if (state.windowWidth && state.tempMoveLineItemsSeats?.length > 1) {
      let seatsLength = state.tempMoveLineItemsSeats?.length;

      if (seatsLength > 7) {
        seatsLength = 7;
      }

      const result = state.windowWidth - state.windowWidth * (seatsLength / 8);

      return result;
    } else {
      return 0;
    }
  }

  function handleReset() {
    setState(prev => ({
      ...prev,
      tempMoveLineItemsSeats: state.tempMoveLineItemsSeatsBeforeChanges,
    }));
  }

  async function handleSave() {
    const updatedMoveLineItems: IMoveLineItem[] = [];

    if (setTableState) {
      void onCancel();
      setTableState(prevState => ({ ...prevState, cartLoadingMessage: "Moving line items..." }));
    }

    for (let i = 0; i < state.tempMoveLineItemsSeats.length; i++) {
      const currentSeat = state.tempMoveLineItemsSeats[i];

      for (let j = 0; j < currentSeat.lineItems.length; j++) {
        const currentLineItem = currentSeat.lineItems[j];
        const isSplitLineItem = currentLineItem.originalQuantity % 1 !== 0;
        const isSingleLineItem = currentLineItem.originalQuantity === 1;
        const isMultipleLineItem = !isSplitLineItem && currentLineItem.originalQuantity > 1;

        const addUpdatedMoveLineItem = (quantity: number, child_line_item: boolean) => {
          updatedMoveLineItems.push({
            id: currentLineItem.id,
            cart_id: currentLineItem.cartId,
            previous_cart_id: currentLineItem.previousCartId,
            quantity,
            child_line_item,
          });
        };
        // Single and Split line items don't need to be deleted because they will be moved
        if (currentLineItem.moveIds.length === 0 && isMultipleLineItem) {
          addUpdatedMoveLineItem(0, false);
        } else if (
          currentLineItem.moveIds.length > 0 &&
          currentLineItem.moveIds.length === intersection(currentSeat.allMoveIds, currentLineItem.moveIds).length
        ) {
          // Only source changes that are specified are quantity changes for Multiple line items
          // because other changes are moves
          if (isMultipleLineItem && currentLineItem.originalQuantity !== currentLineItem.moveIds.length) {
            addUpdatedMoveLineItem(currentLineItem.moveIds.length, false);
          }
        } else if (
          currentLineItem.moveIds.length > 0 &&
          currentLineItem.moveIds.length !== intersection(currentSeat.allMoveIds, currentLineItem.moveIds).length
        ) {
          if (isMultipleLineItem) {
            addUpdatedMoveLineItem(1, true);
          } else if (isSingleLineItem) {
            addUpdatedMoveLineItem(1, false);
          } else if (isSplitLineItem) {
            addUpdatedMoveLineItem(currentLineItem.originalQuantity, false);
          }
        }
      }
    }

    const putMoveLineItems = await PutMoveLineItems({ line_items: updatedMoveLineItems }, setTableState ? false : true);

    if (putMoveLineItems.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.table_service.table_service_move.table_service_move.001"));
      if (setTableState) {
        setTableState(prevState => ({ ...prevState, cartLoadingMessage: "" }));
      }
      return;
    }
    dispatch(showSuccess("Moved line items successfully"));

    onRefresh();
  }

  function handleOutsideModalClick(event: any) {
    if (!state.modalRef.current || state.modalRef.current.contains(event.target)) {
      return;
    }

    onCancel();
  }

  function handleMoveLineItemsOnDragEnd(result: DropResult, provided: ResponderProvided) {
    if (!result.destination?.droppableId) {
      return;
    } else if (result.source.droppableId !== result.destination.droppableId) {
      const sourceSeatIndex = Number(result.source.droppableId);
      const destinationSeatIndex = Number(result.destination.droppableId);
      const draggableId = Number(result.draggableId);

      if (!state.tempMoveLineItemsSeats) {
        return;
      }

      const sourceSeat = state.tempMoveLineItemsSeats[sourceSeatIndex];
      const sourceSeatBeforeChanges = state.tempMoveLineItemsSeatsBeforeChanges[sourceSeatIndex];
      const destinationSeat = state.tempMoveLineItemsSeats[destinationSeatIndex];
      const destinationSeatBeforeChanges = state.tempMoveLineItemsSeatsBeforeChanges[destinationSeatIndex];
      const draggingLineItem = sourceSeat?.lineItems.find(lineItem => lineItem.moveIds.includes(draggableId));

      if (!draggingLineItem) {
        return;
      }

      const updatedSeats = state.tempMoveLineItemsSeats.map(seat => {
        if (seat.id === sourceSeat.id) {
          const originalLineItem: IMovableSeatLineItem = sourceSeatBeforeChanges.lineItems.find(lineItem =>
            lineItem.moveIds.includes(draggableId),
          );

          if (originalLineItem) {
            const updatedSeat: IMovableSeat = {
              ...sourceSeat,
              lineItems: sourceSeat?.lineItems?.map(lineItem => {
                if (lineItem.moveIds.includes(draggableId)) {
                  return {
                    ...lineItem,
                    moveIds: lineItem.moveIds.filter(moveId => moveId !== draggableId),
                  };
                } else {
                  return lineItem;
                }
              }),
            };

            return updatedSeat;
          } else {
            const updatedSeat: IMovableSeat = {
              ...sourceSeat,
              lineItems: sourceSeat.lineItems.filter(lineItem => !lineItem.moveIds.includes(draggableId)),
            };

            return updatedSeat;
          }
        } else if (seat.id === destinationSeat.id) {
          const originalLineItem: IMovableSeatLineItem = destinationSeatBeforeChanges.lineItems.find(lineItem =>
            lineItem.moveIds.includes(draggableId),
          );

          if (originalLineItem) {
            const updatedSeat: IMovableSeat = {
              ...destinationSeat,
              lineItems: destinationSeat.lineItems.map(lineItem => {
                if (lineItem.id === originalLineItem.id) {
                  const updatedIds = [...lineItem.moveIds, draggableId].sort();

                  return {
                    ...lineItem,
                    moveIds: updatedIds,
                  };
                } else {
                  return lineItem;
                }
              }),
            };

            return updatedSeat;
          } else {
            const updatedSeat: IMovableSeat = {
              ...destinationSeat,
              lineItems: [
                ...destinationSeat?.lineItems,
                {
                  ...draggingLineItem,
                  moveIds: [draggableId],
                  cartId: seat.id,
                },
              ],
            };

            return updatedSeat;
          }
        } else {
          return seat;
        }
      });

      setState(prev => ({ ...prev, tempMoveLineItemsSeats: updatedSeats }));
    }
  }

  function determineMoveLineItemDroppableStyle(snapshot: DroppableStateSnapshot) {
    if (snapshot.isDraggingOver) {
      return "lightgreen";
    } else {
      return undefined;
    }
  }

  return (
    <>
      {open && state.tempMoveLineItemsSeats && state.tempMoveLineItemsSeatsBeforeChanges && (
        <>
          <div
            ref={state.modalRef}
            className="table-service-move-line-items-modal"
            style={{
              left: `${calculateHorizontalModalOffset() / 2}px`,
              right: `${calculateHorizontalModalOffset() / 2}px`,
            }}
          >
            <div className="table-service-move-line-items-modal-header">
              {t("secure.facility.table_service.table_service_move.table_service_move.002")}
            </div>
            <DragDropContext onDragEnd={handleMoveLineItemsOnDragEnd}>
              <div className="table-service-move-line-items-modal-table-container">
                <table className="table-service-move-line-items-modal-table">
                  <thead className="table-service-move-line-items-modal-table-header-section">
                    <tr>
                      {state.tempMoveLineItemsSeats.map((seat: IMovableSeat, seatIndex: number) => {
                        return (
                          <th key={seatIndex} className="table-service-move-line-items-modal-table-header">
                            {t("secure.facility.table_service.table_service_move.table_service_move.003")}
                            {seat.seatNumber}

                            <p style={{ fontSize: "12px", color: "#999999", height: "18px" }}>
                              {seat?.customer?.full_name}
                            </p>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {state.tempMoveLineItemsSeats.map((seat: IMovableSeat, seatIndex: number) => {
                        return (
                          <td key={seatIndex} className="table-service-move-line-items-table-content">
                            <Droppable droppableId={String(seatIndex)}>
                              {(provided, snapshot) => {
                                let lineItemIndexCount = 0;

                                return (
                                  <div
                                    ref={provided.innerRef}
                                    className="table-service-move-line-items-table-content-line-item-container"
                                    style={{
                                      backgroundColor: determineMoveLineItemDroppableStyle(snapshot),
                                    }}
                                    {...provided.droppableProps}
                                  >
                                    {seat.lineItems?.map((lineItem, index) => {
                                      return (
                                        <React.Fragment key={String(lineItem?.moveIds) + "-" + String(lineItem?.id)}>
                                          {lineItem?.moveIds?.map(id => {
                                            lineItemIndexCount++;

                                            return (
                                              <Draggable
                                                key={String(id)}
                                                draggableId={String(id)}
                                                index={lineItemIndexCount}
                                              >
                                                {(provided, snapshot) => {
                                                  return (
                                                    <div
                                                      ref={provided.innerRef}
                                                      className="table-service-move-line-items-table-content-line-item"
                                                      {...provided.draggableProps}
                                                      {...provided.dragHandleProps}
                                                    >
                                                      <div className="table-service-move-line-items-table-content-line-item-title">
                                                        {lineItem?.preferredTitle
                                                          ? lineItem.preferredTitle
                                                          : lineItem.productTitle}
                                                      </div>
                                                      {lineItem.productTitle !== lineItem.variantTitle && (
                                                        <div className="table-service-move-line-items-table-content-line-item-subtitle">
                                                          {lineItem.variantTitle}
                                                        </div>
                                                      )}

                                                      {lineItem.modifiers && (
                                                        <div>
                                                          {lineItem.modifiers.map((modifier: any) => {
                                                            return (
                                                              <div
                                                                key={modifier.id}
                                                                className="text-subdued line-item-action table-service-table-menu-content-line-item-primary-extra"
                                                              >
                                                                <div className="ml-2">
                                                                  <div>
                                                                    {modifier?.product?.preferred_title
                                                                      ? modifier?.product?.preferred_title
                                                                      : modifier.product_title}
                                                                  </div>
                                                                  {modifier?.product_title !==
                                                                    modifier?.variant_title && (
                                                                    <div className="text-sm">
                                                                      {modifier?.variant_title}
                                                                    </div>
                                                                  )}
                                                                </div>
                                                              </div>
                                                            );
                                                          })}
                                                        </div>
                                                      )}
                                                    </div>
                                                  );
                                                }}
                                              </Draggable>
                                            );
                                          })}
                                        </React.Fragment>
                                      );
                                    })}
                                    {provided.placeholder}
                                  </div>
                                );
                              }}
                            </Droppable>
                          </td>
                        );
                      })}
                    </tr>
                  </tbody>
                </table>
              </div>
            </DragDropContext>
            <div className="table-service-move-line-items-modal-footer">
              <div className="table-service-move-line-items-modal-footer-secondary-actions">
                <Button type="default" onClick={onCancel}>
                  {t("secure.facility.table_service.table_service_move.table_service_move.004")}
                </Button>
                <Button type="default" onClick={handleReset}>
                  {t("secure.facility.table_service.table_service_move.table_service_move.005")}
                </Button>
              </div>
              <Button
                type="primary"
                onClick={handleSave}
                disabled={isEqual(state.tempMoveLineItemsSeats, state.tempMoveLineItemsSeatsBeforeChanges)}
              >
                {t("secure.facility.table_service.table_service_move.table_service_move.006")}
              </Button>
            </div>
          </div>
          <div className="table-service-move-line-items-modal-backdrop" />
        </>
      )}
    </>
  );
}
