import { APIGet, APIPost, APIPut } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2022-09";
const newApiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/client/account";
const newBaseUrl = "/" + newApiVersion + "/" + adminType + "/client/account";
export interface IGetAccount {
  id?: number;
  customer_id?: number;
  search?: string;
  closed?: boolean;
  limit?: number;
  offset?: number;
}

interface IPostAccountScheduledPayment {
  account_id: number;
  payment_method_id: number;
  amount: number;
  process_date: string;
  charge_balance: boolean;
  reoccur: number;
  frequency: string;
  note: string;
}

interface IPutAccountScheduledPayment {
  id: number;
  payment_method_id: number;
  amount: number;
  process_date: string;
  charge_balance: boolean;
  note: string;
}

export async function GetAccount(params?: IGetAccount, useGlobalLoader?: boolean) {
  return await APIGet(newBaseUrl, params, useGlobalLoader);
}

//GET AccountTransactions
//Get a list of account transactions
export type TAccountTransactionsGetParams = {
  account_id: number;
};
export async function GetAccountTransactions(
  params: TAccountTransactionsGetParams,
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseUrl + "/transaction", params, useGlobalLoader, token);
}

export interface IPostAccount {
  title: string;
  currency: string;
  global: boolean;
  account_number: string;
  limit: number;
  customer_id?: number;
  accounting_reference_id?: number;
}

export async function PostAccount(params: IPostAccount, useGlobalLoader?: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

export interface IPutAccount {
  id: number;
  title: string;
  account_number: string;
  limit: number;
  accounting_reference_id: number;
  customer_id?: number;
  tournament_id?: number;
  global?: boolean;
}

export async function PutAccount(params: IPutAccount, useGlobalLoader?: boolean) {
  return await APIPut(newBaseUrl, params, useGlobalLoader);
}

export async function PostAccountTransactionRefund(params: any, useGlobalLoader?: boolean) {
  return await APIPost(baseUrl + "/transaction/refund", params, useGlobalLoader);
}

export async function PutAccountBalanceRefresh(params: { account_id: number }, useGlobalLoader?: boolean) {
  return await APIPut(baseUrl + "/update", params, useGlobalLoader);
}

export async function GetAccountScheduledPayments(
  params: { account_id: number },
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseUrl + "/scheduled-payment", params, useGlobalLoader, token);
}
export async function PostAccountScheduledPayment(params: IPostAccountScheduledPayment, useGlobalLoader: boolean) {
  return await APIPost(newBaseUrl + "/scheduled-payment", params, useGlobalLoader);
}

export async function PutAccountScheduledPayment(params: IPutAccountScheduledPayment, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/scheduled-payment", params, useGlobalLoader);
}

export async function CancelAccountScheduledPayment(params: { id: number }, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/scheduled-payment/cancel", params, useGlobalLoader);
}

export async function CloseHouseAccount(params: { account_id: number }, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/close", params, useGlobalLoader);
}
export async function ReopenHouseAccount(params: { account_id: number }, useGlobalLoader: boolean) {
  return await APIPut(newBaseUrl + "/close", params, useGlobalLoader);
}

export interface IPostAdjustAccount {
  account_id: number;
  amount: number;
  note: string;
}

export async function PostAdjustAccount(params: IPostAdjustAccount, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/transaction/adjustment", params, useGlobalLoader);
}

export async function PostImportAccounts(params: FormData, useGlobalLoader: boolean) {
  return APIPost(newBaseUrl + "/import", params, useGlobalLoader);
}
