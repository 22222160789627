import React, { useEffect, useState } from "react";
import classNames from "classnames";
import moment, { duration } from "moment";
import { StatusCode } from "api/protocols";
import {
  GetReservationLocation,
  GetReservationModuleAll,
  GetReservationSegment,
  MoveReservationBooking,
  PostReservationEmail,
  PutReservationBooking,
  GetSegmentAuditTrail,
  PutReservationSegment,
  DeleteReservationSegment,
  GetPrintReservation,
} from "api/rpc/facilityAdmin/reservation/reservation";
import Callout from "components/callout/Callout";
import { FilterMenu } from "components/filterMenu/FilterMenu";
import Page from "components/page/Page";
import DateBar from "elements/teesheet/DateBar";
import { formatDate, displayPercent, roundNumber, capitalize } from "helpers/Helpers";

import { IUIActions } from "redux/actions/ui";
import { ILocation, IModule, ISegment, IReservationBooking } from "redux/reducers/models/reservations";
import "./reservation.scss";
import ReservationBookingModalNew from "./ReservationBookingModalNew";
import ReservationBookingModalEdit from "./ReservationBookingModalEdit";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { IAuthState } from "redux/reducers/auth";
import { useHistory } from "react-router";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { SMALL_DESKTOP_WIDTH, TABLET_WIDTH } from "helpers/ScreenSizes";
import { Form } from "react-bootstrap";
import FormLayout from "components/form/FormLayout";
import Sheet from "components/sheet/Sheet";
import Input from "components/form/input/Input";
import Tabs from "components/tabs/Tabs";
import Checkbox from "components/form/checkbox/Checkbox";
import TextEditor from "components/textEditor/textEditor";
import { formatMarkdownBreaks } from "components/markdown/Markdown";
import BookedSegment from "./reservationDragAndDropElements/BookedSegment";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import OpenSegment from "./reservationDragAndDropElements/OpenSegment";
import RightClickMenu from "components/rightClickMenu/RightClickMenu";
import AuditTrailNote from "components/bookingPopUp/auditTrailNote/AuditTrailNote";
import Popup from "components/popup/Popup";
import Spin from "components/spin/spin";

interface IExtendedSegment extends ISegment {
  checked: boolean;
}

interface IState {
  modules: Array<IModule>;
  selectedModule: IModule;
  selectedDate: Date;
  selectedLocation: ILocation;
  selectedSegment: ISegment;
  durations: { [key: string]: Array<number> };
  durationKey: string;
  bookingSegments: Array<ISegment>;
  locations: Array<ILocation>;
  segments: { [key: string]: Array<ISegment> };
  bookedSegments: Array<ISegment>;
  uniqueBookedSegments: Array<IExtendedSegment>;
  newBookingActive: boolean;
  editBookingActive: boolean;
  draggingBookingId: number;
  auditTrails: any;
  auditTrailModalVisible: boolean;
  auditTrailsLoading: boolean;
  deleteSegmentPopupVisible: boolean;
  segmentId: number;
}

interface IEmailState {
  modalVisible: boolean;
  subject: string;
  content: string;
  recipients: number[];
  selectAllRecipients: boolean;
}

interface IRecipient {
  id?: number;
  checked?: boolean;
}

interface IProps {
  uiActions: IUIActions;
  authStore: IAuthState;
}
export default function Reservation(props: IProps) {
  const { uiActions, authStore } = props;
  const { t, i18n } = useTranslation();

  const MINUTES_PER_SEGMENT = 30;

  const [state, setState] = useState<IState>({
    modules: [],
    locations: [],
    segments: null,
    bookedSegments: null,
    uniqueBookedSegments: null,
    selectedModule: null,
    selectedDate: new Date(),
    selectedLocation: null,
    selectedSegment: null,
    durations: null,
    durationKey: "",
    bookingSegments: null,
    newBookingActive: false,
    editBookingActive: false,
    draggingBookingId: null,
    auditTrails: null,
    auditTrailModalVisible: false,
    auditTrailsLoading: false,
    deleteSegmentPopupVisible: false,
    segmentId: null,
  });

  const [emailState, setEmailState] = useState<IEmailState>({
    modalVisible: false,
    subject: "",
    content: "",
    recipients: [],
    selectAllRecipients: false,
  });

  const history = useHistory();

  const windowSize = useWindowSize();

  useEffect(() => {
    void loadModules();
  }, []);

  useEffect(() => {
    if (state.selectedModule) {
      void loadModuleChange();
    }
  }, [state.selectedModule]);

  useEffect(() => {
    if (state.selectedModule) {
      void loadSegments();
    }
  }, [state.selectedDate]);

  async function loadModules() {
    const moduleRes = await GetReservationModuleAll(null, true);
    if (moduleRes.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.reservation.reservation.001"));
      return;
    }
    setState(prevState => ({ ...prevState, modules: moduleRes.data, selectedModule: moduleRes?.data[0] }));
  }

  async function loadModuleChange() {
    uiActions.enqueue();
    const locationRes = await GetReservationLocation({ facility_id: state?.selectedModule?.facility_id }, false);
    if (locationRes.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.reservation.reservation.002"));
      uiActions.dequeue();
      return;
    }

    const segmentRes = await GetReservationSegment(
      { module_id: state?.selectedModule?.id, date: moment(state.selectedDate).format("YYYY-MM-DD"), extended: true },
      false,
    );
    if (segmentRes.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.reservation.reservation.003"));
      uiActions.dequeue();
      return;
    }
    uiActions.dequeue();

    const formattedSegments = segmentRes.data?.reduce(
      (formattedSegments: { [key: string]: Array<ISegment> }, currentSegment: ISegment) => {
        return {
          ...formattedSegments,
          [currentSegment.start_time]: formattedSegments[currentSegment.start_time]
            ? [...formattedSegments[currentSegment.start_time], currentSegment]
            : [currentSegment],
        };
      },
      {},
    );

    const bookedSegments = segmentRes.data?.filter((segment: ISegment) => segment.blocked_type === "booked");

    //filter out duplicate bookings
    const seenIds = new Set();
    const uniqueBookedSegments = bookedSegments.filter((segment: ISegment) => {
      const duplicateBooking = seenIds.has(segment.booking_id);
      seenIds.add(segment.booking_id);
      return !duplicateBooking;
    });

    setState(prevState => ({
      ...prevState,
      locations: locationRes?.data,
      segments: formattedSegments,
      bookedSegments: bookedSegments,
      uniqueBookedSegments: uniqueBookedSegments,
    }));
  }

  async function loadSegments(useGlobalLoader = true) {
    const segmentRes = await GetReservationSegment(
      { module_id: state?.selectedModule?.id, date: moment(state.selectedDate).format("YYYY-MM-DD"), extended: true },
      useGlobalLoader,
    );
    if (segmentRes.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.reservation.reservation.004"));
      return;
    }
    const formattedSegments = segmentRes.data?.reduce(
      (formattedSegments: { [key: string]: Array<ISegment> }, currentSegment: ISegment) => {
        return {
          ...formattedSegments,
          [currentSegment.start_time]: formattedSegments[currentSegment.start_time]
            ? [...formattedSegments[currentSegment.start_time], currentSegment]
            : [currentSegment],
        };
      },
      {},
    );

    const bookedSegments = segmentRes.data?.filter((segment: ISegment) => segment.blocked_type === "booked");

    //filter out duplicate bookings
    const seenIds = new Set();
    const uniqueBookedSegments = bookedSegments.filter((segment: ISegment) => {
      const duplicateBooking = seenIds.has(segment.booking_id);
      seenIds.add(segment.booking_id);
      return !duplicateBooking;
    });

    setState(prevState => ({
      ...prevState,
      segments: formattedSegments,
      bookedSegments: bookedSegments,
      uniqueBookedSegments: uniqueBookedSegments,
    }));
  }

  async function loadSegmentAuditTrail(segmentId: number) {
    setState(prevState => ({
      ...prevState,
      auditTrailModalVisible: true,
      auditTrails: null,
      auditTrailsLoading: true,
    }));

    const auditRes = await GetSegmentAuditTrail({ segment_id: segmentId }, false);

    if (auditRes.status !== StatusCode.OK) {
      uiActions.showError("Error loading audit trail");
      return;
    }

    setState(prevState => ({
      ...prevState,
      auditTrails: auditRes.data,
      auditTrailsLoading: false,
    }));
  }

  async function updateCheckInStatus(status: string, segment: ISegment) {
    const segmentIds = state.bookedSegments
      .filter(bookedSegment => bookedSegment.booking_id === segment.booking_id)
      .map(bookedSegment => bookedSegment.id);

    const bookingRes = await PutReservationBooking(
      {
        id: segment.booking_id,
        segment_ids: segmentIds,
        date: segment.date,
        quantity: segment.booking.quantity,
        customer_payment_method_id: segment.booking.customer_payment_method_id,
        check_in_status: status,
      },
      true,
    );

    if (bookingRes.status !== StatusCode.OK) {
      uiActions.showError("Error updating booking");
      return;
    }

    void loadSegments();
  }

  async function updateFinancialStatus(status: string, segment: ISegment) {
    const segmentIds = state.bookedSegments
      .filter(bookedSegment => bookedSegment.booking_id === segment.booking_id)
      .map(bookedSegment => bookedSegment.id);

    const bookingRes = await PutReservationBooking(
      {
        id: segment.booking_id,
        segment_ids: segmentIds,
        date: segment.date,
        quantity: segment.booking.quantity,
        customer_payment_method_id: segment.booking.customer_payment_method_id,
        order_financial_status: status,
      },
      true,
    );

    if (bookingRes.status !== StatusCode.OK) {
      uiActions.showError("Error updating booking");
      return;
    }

    void loadSegments();
  }

  function handleFilterMenuChange(module: IModule) {
    setState(prevState => ({ ...prevState, selectedModule: module }));
  }

  function handleDateChange(selectedDate: Date) {
    setState(prevState => ({ ...prevState, selectedDate }));
  }

  async function openBookingModal(segment: ISegment, location: ILocation) {
    const segmentsRes = await GetReservationSegment(
      {
        module_id: state?.selectedModule?.id,
        date: segment?.date,
        start_time: segment?.start_time,
      },
      true,
    );
    if (segmentsRes?.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.reservation.reservation.005"));
      return;
    }

    setState(prevState => ({
      ...prevState,
      selectedLocation: location,
      selectedSegment: segment,
      bookingSegments: segmentsRes.data,
      newBookingActive: true,
    }));
  }

  async function openEditBookingModal(segment: ISegment, location: ILocation) {
    const segmentsRes = await GetReservationSegment(
      {
        module_id: state?.selectedModule?.id,
        date: segment?.booking?.date,
        start_time: segment?.booking?.start_time,
        location_id: location?.id,
      },
      true,
    );
    if (segmentsRes?.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.reservation.reservation.006"));
      return;
    }

    void setDurationsEdit(segmentsRes?.data, segment?.booking_id);
    setState(prevState => ({ ...prevState, selectedLocation: location, selectedSegment: segment }));
  }

  function setDurationsEdit(segments: Array<ISegment>, booking_id: number) {
    //We can only select segments from index 0-9 for 0.5 - 5 hours with 0.5 hour increments
    const max = Math.min(10, segments?.length);
    let currentKey = "";
    let minutes = 0;
    let hours = 0;

    const durations: { [key: string]: Array<number> } = {};

    for (let i = 0; i < max; i++) {
      minutes += MINUTES_PER_SEGMENT;
      if (
        (segments[i]?.blocked_type === "open" && segments[i]?.booking_id === null) ||
        (segments[i]?.blocked_type === "booked" && segments[i]?.booking_id === booking_id)
      ) {
        hours = minutes / 60;
        const timeKey = `${hours} ${hours > 1 ? "Hours" : "Hour"}`;
        //Set the durations object keys as the hours and the segment ids array as the value
        durations[timeKey] = segments
          ?.filter((filteredSegment: ISegment, index: number) => index <= i)
          ?.map((segment: ISegment) => segment?.id);
        if (segments[i]?.blocked_type === "booked" && segments[i]?.booking_id === booking_id) {
          currentKey = timeKey;
        }
      } else {
        break;
      }
    }

    //If no duration times are available to select, close the booking modal
    if (Object.keys(durations)?.length === 0) {
      uiActions.showError(t("secure.facility.reservation.reservation.009"));
      setState(prevState => ({
        ...prevState,
        selectedLocation: null,
        selectedSegment: null,
        editBookingActive: false,
      }));
      return;
    }

    setState(prevState => ({ ...prevState, durations, durationKey: currentKey, editBookingActive: true }));
  }

  function isDateBarVisible() {
    return windowSize.width > SMALL_DESKTOP_WIDTH;
  }

  function closeNewBooking() {
    setState(prevState => ({
      ...prevState,
      durations: null,
      selectedLocation: null,
      selectedSegment: null,
      newBookingActive: false,
    }));
  }

  function closeEditBooking() {
    setState(prevState => ({
      ...prevState,
      durations: null,
      selectedLocation: null,
      selectedSegment: null,
      durationKey: "",
      editBookingActive: false,
    }));
  }

  function toSegmentsEditPage() {
    history.push(`/admin/reservation/edit/${state.selectedModule?.id}`);
  }

  function navigateToSegmentConfiguration() {
    history.push(`/admin/settings/reservations/module/${state.selectedModule.id}`);
  }

  function handleOpenEmailModal() {
    setEmailState(prevState => ({ ...prevState, modalVisible: true }));
  }

  const tabs = [
    {
      id: "email-1",
      content: t("secure.facility.reservation.reservation.017"),
      panelID: "email-content-1",
    },

    {
      id: "recipients-1",
      content: t("secure.facility.reservation.reservation.018"),
      panelID: "recipients-content-1",
    },
  ];

  // Handle tab change
  const [selected, setSelected] = useState(0);
  const handleTabChange = (selectedTabIndex: number) => {
    setSelected(selectedTabIndex);
  };

  async function emailBookings() {
    const formattedDate = formatDate(state.selectedDate);

    const teeSheetRes = await PostReservationEmail(
      {
        date: formattedDate,
        subject: emailState.subject,
        content: formatMarkdownBreaks(emailState.content),
        booking_ids: emailState.recipients,
      },
      true,
    );

    if (teeSheetRes.status === StatusCode.OK) {
      uiActions.showSuccess(t("secure.facility.reservation.reservation.019"));
      setEmailState(prevState => ({
        ...prevState,
        subject: "",
        content: "",
        recipients: [],
        modalVisible: false,
        selectAllRecipients: false,
      }));
    } else {
      uiActions.showError(t("secure.facility.reservation.reservation.020"));
      return;
    }
  }

  function handleCheckboxChange(event: any, recipient_group: IExtendedSegment) {
    const chosenRecipients = emailState.recipients;

    const recipientList = state.uniqueBookedSegments.map((recipient, i) => {
      if (recipient.booking.id === recipient_group.booking.id) {
        if (recipient.checked) {
          const index = chosenRecipients.indexOf(recipient_group.id);
          if (index > -1) {
            chosenRecipients.splice(index, 1);
          }

          return { ...recipient, checked: false };
        } else {
          chosenRecipients.push(recipient_group.booking.id);

          return { ...recipient, checked: true };
        }
      }

      return recipient;
    });

    setEmailState(prevState => ({
      ...prevState,
      recipients: chosenRecipients,
    }));
  }

  function handleChangeEmailSubject(e: React.ChangeEvent<HTMLInputElement>) {
    setEmailState(prevState => ({ ...prevState, subject: e.target.value }));
  }

  function handleChangeEmailContent(value: string) {
    setEmailState(prevState => ({ ...prevState, content: value }));
  }

  function handleSelectAllCheckboxChange(event: any) {
    const { id, checked } = event.target;

    const chosenRecipients = emailState.recipients;

    if (checked) {
      const recipientList = state.uniqueBookedSegments.map((segment: ISegment, i: number) => {
        chosenRecipients.push(segment.booking_id);
      });

      const filteredRecipients = [...new Set(chosenRecipients)];

      setEmailState(prevState => ({
        ...prevState,
        recipients: filteredRecipients,
        selectAllRecipients: checked,
      }));
    } else {
      setEmailState(prevState => ({
        ...prevState,
        recipients: [],
        selectAllRecipients: checked,
      }));
    }
  }

  function renderFilterMenu(screenState: "desktop" | "tablet") {
    const visible =
      (screenState === "desktop" && windowSize.width > TABLET_WIDTH) ||
      (screenState === "tablet" && windowSize.width <= TABLET_WIDTH);

    return (
      <div
        style={{
          display: visible ? "block" : "none",
          padding: screenState === "desktop" ? "1.6rem" : "0",
          marginBottom: screenState === "tablet" ? "20px" : "0",
        }}
      >
        <FilterMenu
          title={t("secure.facility.reservation.reservation.011")}
          options={state.modules}
          optionTitle="title"
          defaultSelected={0}
          onClick={(option, selectedIndex) => handleFilterMenuChange(option)}
        />
      </div>
    );
  }

  const primaryAction = {
    content: t("secure.facility.reservation.reservation.010"),
    action: toSegmentsEditPage,
  };

  const secondaryAction = {
    content: t("secure.facility.reservation.reservation.017"),
    action: handleOpenEmailModal,
  };

  async function setBlockedType(segment: any, type: string) {
    const changedSegments: ISegment[] = [];
    const changedSegment = { ...segment };

    changedSegment.blocked_type = type;
    changedSegments.push(changedSegment);

    const segmentRes = await PutReservationSegment({ segments: changedSegments }, true);

    if (segmentRes.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.reservation.reservation.023"));
      return;
    }

    void loadSegments();
  }

  async function updateWebBooking(segment: any) {
    const changedSegments: ISegment[] = [];
    const changedSegment = { ...segment };

    changedSegment.web_booking_enabled = !segment.web_booking_enabled;
    changedSegments.push(changedSegment);

    const segmentRes = await PutReservationSegment({ segments: changedSegments }, true);

    if (segmentRes.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.reservation.reservation.024"));
      return;
    }

    void loadSegments();
  }
  async function updateCreditCardRequired(segment: any) {
    const changedSegments: ISegment[] = [];
    const changedSegment = { ...segment };

    changedSegment.credit_card_required = !segment.credit_card_required;
    changedSegments.push(changedSegment);

    const segmentRes = await PutReservationSegment({ segments: changedSegments }, true);

    if (segmentRes.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.reservation.reservation.025"));
      return;
    }

    void loadSegments();
  }

  function handleShowDeletePopup(segment: any) {
    setState(state => ({
      ...state,
      deleteSegmentPopupVisible: true,
      segmentId: segment.id,
    }));
  }

  async function deleteSegment() {
    const deleteRes = await DeleteReservationSegment({ segment_ids: [state.segmentId] }, true);

    if (deleteRes.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.reservation.reservation.026"));
      return;
    }

    void loadSegments();
    setState(state => ({
      ...state,
      deleteSegmentPopupVisible: false,
      segmentId: null,
    }));
  }

  function setDraggingBookingId(bookingId: number) {
    setState(state => ({
      ...state,
      draggingBookingId: bookingId,
    }));
  }

  function getAvailableDropHoursOfDropSegment(dropSegment: ISegment, dropLocation: ILocation) {
    let availableDropMinutes = 0;
    let reachedDropSegment = false;

    if (!dropSegment || !dropLocation) {
      return 0;
    } else {
      for (const segmentKey in state.segments) {
        const segment: ISegment = state.segments[segmentKey]?.find(
          searchSegment => searchSegment?.location_id === dropLocation?.id,
        );

        if (!segment) {
          continue;
        }

        if (segment?.id === dropSegment.id) {
          reachedDropSegment = true;
        }

        if (reachedDropSegment) {
          if (
            segment.blocked_type !== "open" &&
            !(segment.blocked_type === "booked" && segment.booking_id === state.draggingBookingId)
          ) {
            break;
          } else {
            availableDropMinutes += MINUTES_PER_SEGMENT;
          }
        }
      }
    }

    const availableDropHours = availableDropMinutes / 60;

    return availableDropHours;
  }

  function getStartingBookedSegment(bookedSegment: ISegment, location: ILocation) {
    if (bookedSegment?.blocked_type !== "booked" || !bookedSegment?.booking || !state?.segments) {
      return undefined;
    } else {
      for (const segmentKey in state.segments) {
        const segment: ISegment = state.segments[segmentKey]?.find(
          searchSegment => searchSegment?.location_id === location?.id,
        );

        if (segment?.booking?.id === bookedSegment.booking.id) {
          return segment;
        }
      }

      return undefined;
    }
  }

  function getBookedHoursOfBookedSegment(bookedSegment: ISegment, location: ILocation) {
    let bookedMinutes = 0;

    if (bookedSegment?.blocked_type !== "booked" || !bookedSegment?.booking || !state?.segments) {
      return 0;
    } else {
      for (const segmentKey in state.segments) {
        const segment: ISegment = state.segments[segmentKey]?.find(
          searchSegment => searchSegment?.location_id === location?.id,
        );

        if (segment?.booking?.id === bookedSegment.booking.id) {
          bookedMinutes += MINUTES_PER_SEGMENT;
        }
      }

      const bookedHours = bookedMinutes / 60;

      return bookedHours;
    }
  }

  function getDropSegmentIdsToMove(dropSegment: ISegment, dropLocation: ILocation, bookedHours: number) {
    let bookedMinutes = bookedHours * 60;
    let dropSegmentReached = false;
    const dropSegmentIds = [];

    if (!dropSegment || !dropLocation || !bookedHours) {
      return [];
    } else {
      for (const segmentKey in state.segments) {
        const segment: ISegment = state.segments[segmentKey]?.find(
          searchSegment => searchSegment?.location_id === dropLocation?.id,
        );

        if (segment?.id === dropSegment.id) {
          dropSegmentReached = true;
        }

        if (dropSegmentReached) {
          bookedMinutes -= MINUTES_PER_SEGMENT;
          dropSegmentIds.push(segment?.id);

          if (bookedMinutes <= 0) {
            break;
          }
        }
      }
      return dropSegmentIds;
    }
  }

  async function moveBookingToDropSegment(
    dropSegment: ISegment,
    dropSegmentLocation: ILocation,
    bookedHours: number,
    bookingId: number,
  ) {
    const dropSegmentIds = getDropSegmentIdsToMove(dropSegment, dropSegmentLocation, bookedHours);

    uiActions.enqueue();

    const moveReservationBookingResponse = await MoveReservationBooking(
      {
        booking_id: bookingId,
        location_id: dropSegmentLocation?.id,
        date: dropSegment?.date,
        segment_ids: dropSegmentIds,
      },
      false,
    );

    if (moveReservationBookingResponse.status !== StatusCode.OK) {
      uiActions.showError("Error moving booking");
      uiActions.dequeue();
      return;
    }

    await loadSegments(false);

    uiActions.dequeue();
  }

  function viewAuditTrail(segmentId: number) {
    void loadSegmentAuditTrail(segmentId);
  }

  async function handlePrintReservation() {
    const formattedDate = formatDate(state.selectedDate);

    const params = {
      date: formattedDate,
      module_id: state.selectedModule.id,
    };

    const printRes = await GetPrintReservation(params, true);

    if (printRes.status === StatusCode.OK) {
      window.open().document.write(printRes.data);
    } else {
      uiActions.showError("Error printing teesheet");
      return;
    }
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="facility-reservation">
        {renderFilterMenu("desktop")}
        <div className="facility-reservation-content">
          <Page
            title={state?.selectedModule?.title ?? ""}
            primaryAction={primaryAction}
            // secondaryActions={[secondaryAction]}
            multipleActionDropdownAction={{
              label: "Options",
              dropdownProps: {
                alignment: "right",
                options: [
                  {
                    icon: "envelope",
                    type: "handler",
                    label: "Email",
                    handler: () => handleOpenEmailModal(),
                  },
                  {
                    icon: "print",
                    type: "handler",
                    label: "Print",
                    handler: () => handlePrintReservation(),
                  },
                ],
              },
            }}
          >
            {renderFilterMenu("tablet")}
            <div className="date-bar-row">
              <DatePickerInput
                months={1}
                position="left"
                startingDate={state.selectedDate}
                setStartingDate={handleDateChange}
              />
              <div className="date-bar">
                {isDateBarVisible() && (
                  <DateBar numberOfDays={7} selectedDate={state.selectedDate} onClick={handleDateChange} />
                )}
              </div>
            </div>
            {state.selectedModule && state.locations && state.segments && Object.keys(state.segments)?.length > 0 ? (
              <div className="segments-container">
                <table className="ui-table ui-table-condensed ui-table-separated-horizontal segments-table">
                  <thead>
                    <tr>
                      <td>{t("secure.facility.reservation.reservation.012")}</td>
                      {state?.locations?.map((location, locationIndex) => {
                        return <td key={locationIndex}>{location.title}</td>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(state?.segments)?.map((segmentKey, segmentKeyIndex) => {
                      return (
                        <tr key={segmentKeyIndex}>
                          <td className="text-bold">{moment(segmentKey, "hh:mm:ss").format("h:mm A")}</td>
                          {state?.locations?.map((location, locationIndex) => {
                            const segment: ISegment = state?.segments[segmentKey]?.find(
                              searchSegment => searchSegment?.location_id === location?.id,
                            );

                            if (segment?.blocked_type === "open" && segment?.booking_id === null) {
                              return (
                                <OpenSegment
                                  key={locationIndex}
                                  cellClassNames={classNames("segment-available", {
                                    "segment-league": segment?.league_id !== null,
                                    "segment-tournament": segment?.tournament_id !== null,
                                  })}
                                  cellContentClassName="segment-container"
                                  segment={segment}
                                  location={location}
                                  availableDropHours={getAvailableDropHoursOfDropSegment(segment, location)}
                                  moveBookingToDropSegment={moveBookingToDropSegment}
                                  openBookingModal={openBookingModal}
                                  setBlockedType={setBlockedType}
                                  updateCreditCardRequired={updateCreditCardRequired}
                                  updateWebBooking={updateWebBooking}
                                  deleteSegment={handleShowDeletePopup}
                                  viewAuditTrail={viewAuditTrail}
                                />
                              );
                            } else if (segment?.blocked_type === "open_reserved") {
                              return (
                                <td
                                  key={locationIndex}
                                  className={classNames("segment-available", {
                                    "segment-league": segment?.league_id !== null,
                                    "segment-tournament": segment?.tournament_id !== null,
                                  })}
                                >
                                  <div className="segment-right-click-menu">
                                    <RightClickMenu
                                      sections={[
                                        [
                                          {
                                            icon: "circle",
                                            title: t("secure.facility.reservation.reservation.027"),

                                            action: () => setBlockedType(segment, "open"),
                                            disabled: String(segment.blocked_type) === "open",
                                          },
                                          {
                                            icon: "ban",
                                            title: t("secure.facility.reservation.reservation.028"),
                                            action: () => setBlockedType(segment, "blocked"),

                                            disabled: String(segment.blocked_type) === "blocked",
                                          },
                                        ],
                                        [
                                          {
                                            icon: "globe",
                                            title: segment.web_booking_enabled
                                              ? t("secure.facility.reservation.reservation.029")
                                              : t("secure.facility.reservation.reservation.030"),
                                            action: () => updateWebBooking(segment),
                                          },
                                          {
                                            icon: "credit-card",
                                            title: segment.credit_card_required
                                              ? t("secure.facility.reservation.reservation.031")
                                              : t("secure.facility.reservation.reservation.032"),
                                            action: () => updateCreditCardRequired(segment),
                                          },
                                        ],
                                        [
                                          {
                                            icon: "trash",
                                            title: t("secure.facility.reservation.reservation.033"),
                                            action: () => handleShowDeletePopup(segment),
                                          },
                                        ],
                                      ]}
                                    >
                                      <div className="segment-container">{capitalize(segment?.blocked_type)}</div>
                                    </RightClickMenu>
                                  </div>
                                </td>
                              );
                            } else if (segment?.blocked_type === "blocked") {
                              return (
                                <td
                                  key={locationIndex}
                                  className={classNames("segment-disabled", {
                                    "segment-league": segment?.league_id !== null,
                                    "segment-tournament": segment?.tournament_id !== null,
                                  })}
                                >
                                  <div className="segment-right-click-menu">
                                    <RightClickMenu
                                      sections={[
                                        [
                                          {
                                            icon: "circle",
                                            title: t("secure.facility.reservation.reservation.027"),

                                            action: () => setBlockedType(segment, "open"),
                                            disabled: String(segment.blocked_type) === "open",
                                          },
                                          {
                                            icon: "ban",
                                            title: t("secure.facility.reservation.reservation.028"),
                                            action: () => setBlockedType(segment, "blocked"),

                                            disabled: String(segment.blocked_type) === "blocked",
                                          },
                                        ],
                                        [
                                          {
                                            icon: "globe",
                                            title: segment.web_booking_enabled
                                              ? t("secure.facility.reservation.reservation.029")
                                              : t("secure.facility.reservation.reservation.030"),
                                            action: () => updateWebBooking(segment),
                                          },
                                          {
                                            icon: "credit-card",
                                            title: segment.credit_card_required
                                              ? t("secure.facility.reservation.reservation.031")
                                              : t("secure.facility.reservation.reservation.032"),
                                            action: () => updateCreditCardRequired(segment),
                                          },
                                        ],
                                        [
                                          {
                                            icon: "trash",
                                            title: t("secure.facility.reservation.reservation.033"),
                                            action: () => handleShowDeletePopup(segment),
                                          },
                                        ],
                                      ]}
                                    >
                                      <div className="segment-container">{capitalize(segment?.blocked_type)}</div>
                                    </RightClickMenu>
                                  </div>
                                </td>
                              );
                            } else if (segment?.blocked_type === "booked" && segment?.booking_id) {
                              return (
                                <BookedSegment
                                  key={locationIndex}
                                  cellClassNames={classNames("segment-disabled segment-booked", {
                                    "segment-league": segment?.league_id !== null,
                                    "segment-tournament": segment?.tournament_id !== null,
                                    "segment-no-show": segment?.booking?.check_in_status === "no_show",
                                    "segment-paid": segment?.booking?.order_financial_status === "paid",
                                    "segment-checked":
                                      segment?.booking?.check_in_status === "checked" &&
                                      segment?.booking?.order_financial_status !== "paid",
                                  })}
                                  cellContentClassNames="segment-container"
                                  segment={segment}
                                  startingSegment={getStartingBookedSegment(segment, location)}
                                  location={location}
                                  availableDropHours={getAvailableDropHoursOfDropSegment(segment, location)}
                                  bookedHours={getBookedHoursOfBookedSegment(segment, location)}
                                  draggingBookingId={state.draggingBookingId}
                                  setDraggingBookingId={setDraggingBookingId}
                                  moveBookingToDropSegment={moveBookingToDropSegment}
                                  openEditBookingModal={openEditBookingModal}
                                  updateCheckInStatus={updateCheckInStatus}
                                  updateFinancialStatus={updateFinancialStatus}
                                  viewAuditTrail={viewAuditTrail}
                                  setBlockedType={setBlockedType}
                                />
                              );
                            } else {
                              return <td key={locationIndex} className="segment-unavailable"></td>;
                            }
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <Callout
                actions={{
                  primary: {
                    label: t("secure.facility.reservation.reservation.034"),
                    onClick: navigateToSegmentConfiguration,
                  },
                }} //Translations Moises
                type="error"
                title={t("secure.facility.reservation.reservation.015")}
                content={t("secure.facility.reservation.reservation.016")}
              />
            )}
          </Page>
        </div>
      </div>

      <ReservationBookingModalNew
        uiActions={uiActions}
        authStore={authStore}
        date={state.selectedDate}
        module={state.selectedModule}
        selectedSegment={state.selectedSegment}
        selectedLocation={state.selectedLocation}
        segments={state.bookingSegments}
        locations={state.locations}
        onSave={loadSegments}
        onCancel={closeNewBooking}
        open={state.newBookingActive}
      />

      <ReservationBookingModalEdit
        uiActions={uiActions}
        authStore={authStore}
        date={state.selectedDate}
        module={state.selectedModule}
        segment={state.selectedSegment}
        location={state.selectedLocation}
        locations={state.locations}
        open={state.editBookingActive}
        durations={state.durations}
        durationKey={state.durationKey}
        onSave={loadSegments}
        onCancel={closeEditBooking}
      />

      <Sheet
        closable
        onCancel={() => setEmailState(prevState => ({ ...prevState, modalVisible: false }))}
        size="medium"
        open={emailState.modalVisible}
        cancelText={t("elements.tee_sheet.statistic_bar.007")}
        okText={t("elements.tee_sheet.statistic_bar.008")}
        title={t("elements.tee_sheet.statistic_bar.009")}
        onOk={() => emailBookings()}
      >
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          {/* Booking Details */}
          {selected === 0 ? (
            <div className="mt-4 flex flex-col gap-2">
              <Input
                type="text"
                label={t("elements.tee_sheet.statistic_bar.010")}
                onChange={handleChangeEmailSubject}
                value={emailState.subject}
              />
              <TextEditor
                label={t("elements.tee_sheet.statistic_bar.012")}
                markdownText={emailState.content ?? ""}
                markdownTextOnChange={handleChangeEmailContent}
              />
            </div>
          ) : null}
          {selected === 1 ? (
            <Form>
              <FormLayout>
                <FormLayout.Group>
                  <div className="mt-4 mb-1">
                    <Checkbox
                      id={"select-all"}
                      size="medium"
                      checked={emailState.selectAllRecipients}
                      onChange={handleSelectAllCheckboxChange}
                      label={t("secure.facility.reservation.reservation.021")}
                    />
                  </div>
                </FormLayout.Group>
                {!emailState.selectAllRecipients && (
                  <>
                    <FormLayout.Group>
                      <span>{t("secure.facility.reservation.reservation.022")}</span>
                    </FormLayout.Group>
                    <FormLayout.Group>
                      <div>
                        {state.uniqueBookedSegments.map((recipient, i) => {
                          return (
                            <div key={i}>
                              <Checkbox
                                id={String(recipient.booking.id)}
                                size="large"
                                checked={recipient.checked}
                                onChange={e => handleCheckboxChange(e, recipient)}
                                label={`${moment(recipient.start_time, "hh:mm:ss").format("h:mm A")} (${
                                  recipient.booking.customer.full_name
                                })`}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </FormLayout.Group>
                  </>
                )}
              </FormLayout>
            </Form>
          ) : null}
        </Tabs>
      </Sheet>

      <Sheet
        open={state.auditTrailModalVisible}
        size="medium"
        closable
        title={"Audit Trail"}
        onCancel={() => setState(state => ({ ...state, auditTrailModalVisible: false }))}
        onOk={""}
        cancelText={t("elements.tee_sheet.tee_sheet_tabs.033")}
        okText={t("elements.tee_sheet.tee_sheet_tabs.034")}
        overflow
      >
        {!state.auditTrailsLoading ? (
          <div>
            {state.auditTrails?.length > 0 ? (
              state.auditTrails.map((audit: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <AuditTrailNote
                      title={audit.title}
                      author={audit.author}
                      actions={audit.actions}
                      action={audit.action}
                      timestamp={audit.created_at}
                      last={index + 1 === state.auditTrails.length ? true : false}
                    />
                  </React.Fragment>
                );
              })
            ) : (
              <span>{"No audit trail available"}</span>
            )}
          </div>
        ) : (
          <div style={{ height: "25px", width: "25px" }}>
            <Spin />
          </div>
        )}
      </Sheet>

      <Popup
        open={state.deleteSegmentPopupVisible}
        type={"warning"}
        title={t("secure.facility.reservation.reservation.035")}
        description={t("secure.facility.reservation.reservation.036")}
        closable
        cancelText={t("secure.facility.reservation.reservation.037")}
        okText={t("secure.facility.reservation.reservation.038")}
        onCancel={() => setState(prevState => ({ ...prevState, deleteSegmentPopupVisible: false }))}
        onOk={deleteSegment}
      />
    </DndProvider>
  );
}
