import * as React from "react";
import InternalSelect, { ISelectProps } from "./Select";
import Option from "./Option";

interface ISelectType extends React.FC<ISelectProps> {
  Option: typeof Option;
}

export const Select = InternalSelect as ISelectType;

Select.Option = Option;

export default Select;
