import React from "react";
import classNames from "classnames";
import { IRadioProps } from "./interface";

const InternalRadio: React.FC<IRadioProps> = props => {
  const { children, prefixCls = "rc-radio", className } = props;

  const radioClassName = classNames(prefixCls, className);

  return <div className={radioClassName}>{children}</div>;
};

export default InternalRadio;
