import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useState } from "react";
import "./factorNodeEditMode.scss";
import { useAutoFocusInput } from "./useAutoFocusInput";
import { useDynamicPricingContext } from "../../DynamicPricingContext";
import { DynamicPricingFactorValue } from "../DynamicPricingFactorGroupNode";
import { areFactorValuesOverlapping } from "../../TeeSheetDynamicPricingEdit";

export interface IFactorTimeValue {
  hours: number;
  minutes: number;
}

export interface IFactorTimeRangeValueType {
  valueType: "time_range";
  startTime: IFactorTimeValue;
  endTime: IFactorTimeValue;
}

interface IFactorNodeTimeRangeEditMode {
  parentId: string;
  id: string;
  defaultStartTime: IFactorTimeValue;
  defaultEndTime: IFactorTimeValue;
  onConfirm: (value: IFactorTimeRangeValueType) => void;
  onCancel: () => void;
}

export function factorTimeToMinutes(time: IFactorTimeValue) {
  return time.hours * 60 + time.minutes;
}

export function isFactorTimeRangeValid(startTime: IFactorTimeValue, endTime: IFactorTimeValue) {
  if (
    startTime?.hours == null ||
    startTime?.minutes == null ||
    endTime?.hours == null ||
    endTime?.minutes == null ||
    startTime.hours < 0 ||
    startTime.hours > 23 ||
    startTime.minutes < 0 ||
    startTime.minutes > 59 ||
    endTime.hours < 0 ||
    endTime.hours > 23 ||
    endTime.minutes < 0 ||
    endTime.minutes > 59
  ) {
    return false;
  }

  const startTimeMinutes = factorTimeToMinutes(startTime);
  const endTimeMinutes = factorTimeToMinutes(endTime);

  return startTimeMinutes < endTimeMinutes;
}

export function convertFactorTimeTo24HourTimeString(time: IFactorTimeValue): string {
  const hours = time.hours < 10 ? `0${time.hours}` : `${time.hours}`;
  const minutes = time.minutes < 10 ? `0${time.minutes}` : `${time.minutes}`;

  return `${hours}:${minutes}`;
}

export function convert24HourTimeStringToFactorTime(timeString: string): IFactorTimeValue {
  const timeStringRegex = /^(2[0-3]|[01][0-9]):([0-5][0-9])$/;

  const factorTimeParts = timeStringRegex.exec(timeString);

  if (!factorTimeParts) {
    return null;
  }

  return {
    hours: Number(factorTimeParts[1]),
    minutes: Number(factorTimeParts[2]),
  };
}

export default function FactorNodeTimeRangeEditMode(props: IFactorNodeTimeRangeEditMode) {
  const { parentId, id, defaultStartTime, defaultEndTime, onConfirm, onCancel } = props;

  const dynamicPricingContext = useDynamicPricingContext();

  const autoFocusInputRef = useAutoFocusInput();

  const [startTimeInput, setStartTimeInput] = useState<string>(
    defaultStartTime == null ? "" : convertFactorTimeTo24HourTimeString(defaultStartTime),
  );

  const [endTimeInput, setEndTimeInput] = useState<string>(
    defaultEndTime == null ? "" : convertFactorTimeTo24HourTimeString(defaultEndTime),
  );

  const startTime = convert24HourTimeStringToFactorTime(startTimeInput);
  const endTime = convert24HourTimeStringToFactorTime(endTimeInput);

  const confirmDisabled = useMemo(() => {
    if (!isFactorTimeRangeValid(startTime, endTime)) {
      return true;
    }

    const factorSiblings = dynamicPricingContext.getFactorSiblings(parentId, id);

    const dynamicFactorValue: DynamicPricingFactorValue = {
      valueType: "time_range",
      startTime,
      endTime,
    };

    return areFactorValuesOverlapping([...factorSiblings.map(sibling => sibling.data), dynamicFactorValue]);
  }, [startTime, endTime]);

  return (
    <div className="factor-node-edit-mode">
      <input
        ref={autoFocusInputRef}
        className="factor-node-edit-mode-time-range-input"
        type="time"
        value={startTimeInput}
        onChange={e => setStartTimeInput(e.target.value)}
      />
      -
      <input
        className="factor-node-edit-mode-time-range-input"
        type="time"
        value={endTimeInput}
        onChange={e => setEndTimeInput(e.target.value)}
      />
      <button
        type="button"
        className="factor-node-edit-mode-confirm"
        disabled={confirmDisabled}
        onClick={() =>
          onConfirm({
            valueType: "time_range",
            startTime,
            endTime,
          })
        }
      >
        <FontAwesomeIcon icon={["fal", "circle-check"]} />
      </button>
      <button type="button" className="factor-node-edit-mode-cancel" onClick={onCancel}>
        <FontAwesomeIcon icon={["fal", "circle-x"]} />
      </button>
    </div>
  );
}
