import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetBookingCategory } from "api/rpc/2024-04/clientAdmin/teesheet/bookingEngine/category";
import {
  DeleteCustomerType,
  GetCustomerType,
  PutCustomerType,
  TPutCustomerType,
} from "api/rpc/2024-04/clientAdmin/customer/type";
import { TTeeSheetApplication } from "api/rpc/2022-09/clientAdmin/teeSheets/pricingSheet";

import { showError, showSuccess } from "redux/actions/ui";
import { TCustomerType } from "redux/reducers/models/customer";
import { IBookingCategory } from "redux/reducers/models/teetime";

import useModal from "hooks/modals/useModal";
import { handleChangeEventInput } from "helpers/Helpers";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import Sheet from "components/sheet/Sheet";
import ColorPicker from "components/colorpicker/color-picker";
import { Select } from "components/select";
import Portal from "elements/Portal";

export default function CustomerTypeEdit() {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const [pageTitle, setPageTitle] = useState("");
  const [type, setType] = useState<TPutCustomerType & { application: Omit<TTeeSheetApplication, "no_show"> }>({
    id: Number(id),
    title: "",
    subtitle: "",
    color: null,
    default_booking_category_id: -1,
    application: null,
  });

  const [bookingCategories, setBookingCategories] = useState<IBookingCategory[]>([]);

  const {
    state: deleteModal,
    updateModal,
    closeModal,
  } = useModal({ migrateId: null, types: [] as TCustomerType[], clearOnClose: false });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadCustomerTypes(id, source.token);
    void loadBookingCategories(source.token);
    return () => source.cancel();
  }, [id]);

  async function loadCustomerTypes(typeId: string | number, token?: CancelToken) {
    const res = await GetCustomerType(null, true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      return;
    }

    updateModal({ types: res.data as TCustomerType[] });

    const editType = (res.data as TCustomerType[]).find(type => type.id === Number(typeId));
    setType(prev => ({
      ...prev,
      title: editType.title,
      subtitle: editType.subtitle ?? "",
      color: editType.color,
      default_booking_category_id: editType.default_booking_category_id ?? -1,
      application: editType.application,
    }));
    setPageTitle(editType.full_title);
  }

  async function loadBookingCategories(token?: CancelToken) {
    const res = await GetBookingCategory(null, token ? false : true, token);
    console.log(res); //debug

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      return;
    }

    setBookingCategories(res.data);
  }

  async function saveCustomerType() {
    const res = await PutCustomerType(
      {
        ...type,
        default_booking_category_id: type.default_booking_category_id === -1 ? null : type.default_booking_category_id,
      },
      true,
    );
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error updating customer type.")); // TODO: Translation
      return;
    }

    history.push("/admin/settings/client-settings/customer_types");
  }

  async function removeCustomerType(migrateId: number) {
    if (deleteModal.migrateId === undefined) {
      return;
    }

    const res = await DeleteCustomerType({ id: id, migrate_customer_type_id: migrateId }, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error removing customer type."));
      return;
    }

    dispatch(showSuccess("Successfully removed the customer type."));
    history.push("/admin/settings/client-settings/customer_types");
  }

  const primaryAction = {
    content: t("secure.facility.settings.customer_type.customer_type_edit.001"),
    action: () => saveCustomerType(),
  };

  const secondaryAction = {
    content: t("secure.facility.settings.customer_type.customer_type_edit.002"),
    action: () => updateModal({ isOpen: true }),
  };

  return (
    <>
      <Page
        title={t("secure.facility.settings.customer_type.customer_type_edit.003") + pageTitle}
        narrow
        primaryAction={primaryAction}
        secondaryActions={[secondaryAction]}
        breadcrumbs={[
          {
            prefix: true,
            label: t("secure.facility.settings.customer_type.customer_type_edit.004"),
            url: "/admin/settings/client-settings/customer_types",
          },
        ]}
      >
        <Card>
          <Card.Section>
            <Form>
              <FormLayout>
                <FormLayout.Group>
                  <Input
                    value={type.title}
                    label={t("secure.facility.settings.customer_type.customer_type_edit.005")}
                    id="title"
                    onChange={e => handleChangeEventInput(e, type, setType)}
                    placeholder={t("secure.facility.settings.customer_type.customer_type_edit.006")}
                  />
                  <Input
                    value={type.subtitle || ""}
                    label={t("secure.facility.settings.customer_type.customer_type_edit.007")}
                    id="subtitle"
                    onChange={e => handleChangeEventInput(e, type, setType)}
                    placeholder={t("secure.facility.settings.customer_type.customer_type_edit.008")}
                  />
                </FormLayout.Group>
                {type.application === "green_fee" && (
                  <FormLayout.Group>
                    <Select
                      label={t("secure.facility.settings.customer_type.customer_type_edit.014")}
                      onChange={(id: number) => setType(prev => ({ ...prev, default_booking_category_id: id }))}
                      defaultValue={type.default_booking_category_id}
                    >
                      <Select.Option value={-1}>None</Select.Option>
                      {bookingCategories?.map((category, index: number) => {
                        return (
                          <Select.Option key={index} value={category.id} name={category.title}>
                            {category.title}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </FormLayout.Group>
                )}
              </FormLayout>
            </Form>
          </Card.Section>
        </Card>
        <Card
          title="Tee Sheet Color" //TODO: Translation
          subtitle="Select a color for this customer type to use on the tee sheet." //TODO: Translation
        >
          <Card.Section>
            <ColorPicker
              colorValue={type.color}
              onClick={color => setType(prev => ({ ...prev, color: prev.color === color ? null : color }))}
            />
          </Card.Section>
        </Card>
      </Page>

      <Portal isMounted={deleteModal.isOpen}>
        <Sheet
          closable
          onCancel={() => updateModal({ isOpen: false, migrateId: null })}
          size="small"
          open={deleteModal.isOpen}
          cancelText={t("secure.facility.settings.customer_type.customer_type_edit.009")}
          okText={t("secure.facility.settings.customer_type.customer_type_edit.010")}
          title={t("secure.facility.settings.customer_type.customer_type_edit.011")}
          onOk={() => removeCustomerType(deleteModal.migrateId)}
          okDisabled={deleteModal.migrateId === null}
          overflow
        >
          <div className="text-left p-3">
            <Select
              label={t("secure.facility.settings.customer_type.customer_type_edit.012")}
              onChange={(migrateId: number) => updateModal({ migrateId: migrateId })}
              placeholder={t("secure.facility.settings.customer_type.customer_type_edit.013")}
              defaultValue={deleteModal.migrateId}
            >
              {deleteModal.types
                ?.filter(filterType => filterType.id !== type.id)
                .map((type, index: number) => (
                  <Select.Option key={index} value={type.id} name={type.title}>
                    {type.title}
                  </Select.Option>
                ))}
            </Select>
          </div>
        </Sheet>
      </Portal>
    </>
  );
}
