import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { StatusCode } from "api/protocols";
import { GetEvent, PutEvent } from "api/rpc/2024-04/facilityAdmin/event/hospitalityEvent";
import { GetFacility } from "api/rpc/2024-04/facilityAdmin/facility/facility";
import { GetInvoiceTransaction, GetInvoiceTransactionReceipt } from "api/rpc/2024-04/facilityAdmin/order/invoice";

import { showError, showSuccess } from "redux/actions/ui";
import { IEvent } from "redux/reducers/models/event";
import { IFacility } from "redux/reducers/models/facility";
import { useAppDispatch } from "hooks/redux";

import Card from "components/card/Card";
import Page from "components/page/Page";
import Input from "components/form/input/Input";
import FormLayout from "components/form/FormLayout";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";
import TextEditor from "components/textEditor/textEditor";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import { LocaleCurrency } from "helpers/Locale";
import { NavigationDropdownNew } from "components/navigationDropdownNew/NavigationDropdownNew";
import { Badge } from "components/badge/Badge";

export default function HospitalityEvent() {
  const { eventId } = useParams<{ eventId: string }>();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const [event, setEvent] = useState<IEvent>(null);
  const [facility, setFacility] = useState<IFacility>(null);

  useEffect(() => {
    void loadEvent();
  }, []);

  async function loadEvent() {
    const numericEventId = Number(eventId);
    const eventRes = await GetEvent({ id: numericEventId }, false);

    if (eventRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.hospitality.hospitality_event.001")));
      return;
    }

    const event = eventRes.data[0];

    const depositRes = await GetInvoiceTransaction({ hospitality_event_id: event.id }, true);

    if (depositRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.hospitality.hospitality_event.002")));
      return;
    }

    event.deposits = depositRes.data;

    setEvent(event);

    const facilityRes = await GetFacility({ id: event.facility_id }, true);

    if (facilityRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.hospitality.hospitality_event.003")));
      return;
    }

    const facility = facilityRes.data?.[0];

    if (facilityRes.status === StatusCode.OK) {
      setFacility(facility);
    }
  }

  async function updateEvent() {
    const params = {
      id: event.id,
      name: event.name,
      date: event.date,
      description: event.description,
    };

    const eventRes = await PutEvent(params, true);

    if (eventRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.hospitality.hospitality_event.012")));
      return;
    }

    dispatch(showSuccess(t("secure.facility.hospitality.hospitality_event.013")));
    setEvent(eventRes.data);
  }

  async function getDepositReceipt(transactionId: number) {
    const res = await GetInvoiceTransactionReceipt({ transaction_id: transactionId }, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error printing deposit receipt")); // TODO: Translation
      return;
    }

    window.open().document.write(res.data);
  }

  const primaryAction = {
    content: t("secure.facility.hospitality.hospitality_event.014"),
    action: updateEvent,
  };

  return (
    <Page
      title={event?.name}
      narrow
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.hospitality.hospitality_event.004"),
          url: "/admin/events-hospitality",
        },
      ]}
      primaryAction={primaryAction}
    >
      <Card title={t("secure.facility.hospitality.hospitality_event.005")}>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Input
                label={t("secure.facility.hospitality.hospitality_event.006")}
                value={event?.name ?? ""}
                onChange={e => setEvent(prev => ({ ...prev, name: e.target.value }))}
              />
              <DatePickerInput
                label={t("secure.facility.hospitality.hospitality_event.007")}
                startingDate={new Date(moment(event?.date).format())}
                setStartingDate={(date: Date) =>
                  setEvent(prev => ({ ...prev, date: moment(date).format("YYYY-MM-DD") }))
                }
                months={1}
                position="left"
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextEditor
                markdownText={event?.description ?? ""}
                markdownTextOnChange={description => setEvent(prev => ({ ...prev, description: description }))}
                label={t("secure.facility.hospitality.hospitality_event.015")}
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>

      {event?.deposits?.length >= 1 && (
        <Card title={t("secure.facility.hospitality.hospitality_event.008")}>
          <Card.Section>
            <DataTable
              columns={[
                { label: t("secure.facility.hospitality.hospitality_event.009"), width: "25%" },
                { label: t("secure.facility.hospitality.hospitality_event.010"), width: "20%" },
                { label: "Status", width: "10%" }, // TODO: Translation
                { label: t("secure.facility.hospitality.hospitality_event.011"), width: "40%" },
                { label: "", width: "5%" },
              ]}
              loading={event.deposits === undefined}
            >
              {event.deposits?.map((deposit, index) => (
                <tr key={index}>
                  <td>{moment(deposit.processed_at_local).format("YYYY-MM-DD")}</td>
                  <td>
                    <LocaleCurrency currency={deposit.currency} amount={deposit.amount} />
                  </td>
                  <td>{statusBadge(deposit.invoice_id)}</td>
                  <td>{deposit.note}</td>
                  <td>
                    <NavigationDropdownNew
                      showPlainTextLabel
                      rightAlign
                      label={<FontAwesomeIcon icon={["far", "chevron-down"]} />}
                      sections={[
                        [
                          {
                            icon: "print",
                            label: "Print Receipt",
                            onClick: () => getDepositReceipt(deposit.id),
                          },
                        ],
                      ]}
                    />
                  </td>
                </tr>
              ))}
            </DataTable>
          </Card.Section>
        </Card>
      )}
    </Page>
  );
}

const statusBadge = (invoice_id: number) => {
  switch (invoice_id) {
    case null:
      return <Badge type="success">{"Available"}</Badge>;
    default:
      return <Badge type="gray">{"Applied"}</Badge>;
  }
};
