import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";
import { cloneDeep } from "lodash";
import useModal from "hooks/modals/useModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { StatusCode } from "api/protocols";
import {
  GetLeagueStatistics,
  TLeagueStatisticsResStructure,
  PutLeagueStatistics,
  PostMissingLeagueStatistics,
  PutLoadPreviousHandicaps,
} from "api/rpc/2024-04/facilityAdmin/league/scoring/statistics";
import { GetLeagueRound } from "api/rpc/2024-04/facilityAdmin/league/scoring/round";
import { GetLeague } from "api/rpc/2024-04/facilityAdmin/league/league";

import { showError, showSuccess } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";
import { sortObj } from "helpers/Helpers";

import Page from "components/page/Page";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import Input from "components/form/input";
import Search from "components/search/Search";
import { Badge } from "components/badge/Badge";
import Portal from "elements/Portal";
import Sheet from "components/sheet/Sheet";
import { Select } from "components/select/";
import { ILeague, TEventRound } from "redux/reducers/models/league";
import Spin from "components/spin/spin";

import "./leagueStatistics.scss";

interface ILeagueStatisticsState {
  playerQuery: string;
  selectedPlayerId: number;
}

export default function LeagueStatistics() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { leagueId } = useParams<{ leagueId: string }>();
  const dispatch = useAppDispatch();

  const [leagues, setLeagues] = useState<ILeague[]>(undefined);

  const [leagueStatisticsState, setLeagueStatisticsState] = useState<ILeagueStatisticsState>({
    playerQuery: "",
    selectedPlayerId: null,
  });

  const [playerStatistics, setPlayerStatistics] =
    useState<Array<TLeagueStatisticsResStructure & { last_name: string }>>(undefined);

  const [selectedPlayerRounds, setSelectedPlayerRounds] = useState<TEventRound[]>(undefined);

  const {
    state: loadHandicapsModal,
    updateModal: updateLoadHandicapsModal,
    closeModal: closeLoadhandicapsModal,
  } = useModal({
    leagueQuery: "",
    previous_league_id: null,
    selectedLeague: null,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadLeagueStatistics(leagueId, source.token);
    return () => source.cancel();
  }, [leagueId]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadLeagues(source.token);
    return () => source.cancel();
  }, [loadHandicapsModal.leagueQuery]);

  async function loadLeagues(token: CancelToken) {
    const leagueRes = await GetLeague({ search: loadHandicapsModal.leagueQuery }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (leagueRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading leagues.")); // TODO: Translation
      return;
    }

    setLeagues(leagueRes.data);
  }

  async function loadLeagueStatistics(league_id: string | number, token?: CancelToken) {
    if (playerStatistics !== undefined) {
      setPlayerStatistics(undefined);
    }
    const statisticsRes = await GetLeagueStatistics({ league_id }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (statisticsRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading league statistics.")); // TODO: Translation
      return;
    }

    const sortedPlayers = statisticsRes.data
      .sort((a, b) => (a.customer.last_name < b.customer.last_name ? -1 : 1))
      .map(player => ({
        ...player,
        last_name: player.customer.last_name, // required for DataTable sort callback
      }));

    setPlayerStatistics(statisticsRes.status !== StatusCode.OK ? [] : sortedPlayers);
  }

  async function loadPlayerRounds(userId: string | number, token?: CancelToken) {
    setSelectedPlayerRounds(undefined);

    const roundRes = await GetLeagueRound({ league_id: Number(leagueId), user_id: userId }, false, token);

    if (token && token.reason) {
      return;
    }
    if (roundRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading player rounds.")); // TODO: Translation
      return;
    }

    setSelectedPlayerRounds(roundRes.data);
  }

  async function updateStatistics() {
    const statisticsRes = await PutLeagueStatistics(
      {
        league_id: Number(leagueId),
        statistics: playerStatistics,
      },
      true,
    );

    if (statisticsRes.status !== StatusCode.OK) {
      dispatch(showError("Error updating league statistics.")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("League statistics updated successfully")); // TODO: Translation
    void loadLeagueStatistics(leagueId);
  }

  async function createMissingLeagueStatistics() {
    const statisticsRes = await PostMissingLeagueStatistics(
      {
        league_id: Number(leagueId),
      },
      true,
    );

    if (statisticsRes.status !== StatusCode.OK) {
      dispatch(showError("Error creating missing league statistics.")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Missing league statistics created successfully")); // TODO: Translation
    void loadLeagueStatistics(leagueId);
  }

  async function loadPreviousHandicaps() {
    if (!loadHandicapsModal.previous_league_id) {
      dispatch(showError("No league selected")); // TODO: Translation
      return;
    }

    const handicapRes = await PutLoadPreviousHandicaps(
      {
        league_id: Number(leagueId),
        previous_league_id: loadHandicapsModal.previous_league_id,
      },
      true,
    );

    if (handicapRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading previous handicaps")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Previous handicaps loaded successfully")); // TODO: Translation
    closeLoadhandicapsModal();
    void loadLeagueStatistics(leagueId);
  }

  function handleInitialHandicapChange(value: string, playerId: number) {
    const tempStatistics = cloneDeep(playerStatistics);

    tempStatistics.find(player => player.id === playerId).initial_handicap_index = Number(value);
    tempStatistics.find(player => player.id === playerId).use_initial_handicap = true;

    setPlayerStatistics(tempStatistics);
  }

  function displayStatistics() {
    if (leagueStatisticsState?.playerQuery !== "") {
      return playerStatistics?.filter(statistic =>
        statistic.customer.full_name.toLowerCase().includes(leagueStatisticsState.playerQuery.toLowerCase()),
      );
    } else {
      return playerStatistics;
    }
  }

  const primaryAction = {
    content: "Save", // TODO: Translation
    action: updateStatistics,
  };

  return (
    <Page
      title="League Statistics" /* TODO: Translation */
      primaryAction={primaryAction}
      multipleActionDropdownAction={{
        label: "Options", // TODO: Translation
        dropdownProps: {
          alignment: "right",
          options: [
            {
              type: "handler",
              label: "Create Missing Statistics", // TODO: Translation
              handler: () => createMissingLeagueStatistics(),
            },
            {
              type: "handler",
              label: "Load Previous Handicaps", // TODO: Translation
              handler: () => updateLoadHandicapsModal({ isOpen: true }),
            },
          ],
        },
      }}
    >
      <div className="mb-2">
        <Search
          placeholder="Search League Players..." // TODO: Translation
          searchCallback={searchValue =>
            setLeagueStatisticsState(prevState => ({ ...prevState, playerQuery: searchValue }))
          }
          historyKey="league-statistics-player-query"
        />
      </div>
      <DataTable<TLeagueStatisticsResStructure>
        columns={[
          { id: "last_name", label: "Player Name" }, // TODO: Translation
          { label: "Handicap" }, // TODO: Translation
          { label: "Initial Handicap", width: "10%" }, // TODO: Translation
          { label: "", width: "5%" }, // TODO: Translation
        ]}
        sort={(key, direction) => playerStatistics && setPlayerStatistics(prev => sortObj(prev, key, direction))}
        loading={playerStatistics === undefined}
      >
        {displayStatistics()?.map((player, index) => {
          return (
            <>
              <tr key={player.id}>
                <td
                  onClick={() => {
                    void loadPlayerRounds(player.user_id);
                    setLeagueStatisticsState(prevState => ({
                      ...prevState,
                      selectedPlayerId:
                        leagueStatisticsState.selectedPlayerId === player.user_id ? null : player.user_id,
                    }));
                  }}
                  className="clickable"
                >
                  {player.customer.full_name}
                </td>
                <td>{player.handicap_index}</td>
                <td>
                  <Input
                    labelHidden
                    id="initial_handicap_index"
                    type="number"
                    value={player.initial_handicap_index}
                    onChange={e => handleInitialHandicapChange(e.target.value, player.id)}
                  />
                </td>
                <td>
                  {player.use_initial_handicap ? (
                    <Badge type="success">{"Using Initial Handicap" /* TODO: Translation */}</Badge>
                  ) : null}
                </td>
              </tr>
              {player.user_id === leagueStatisticsState.selectedPlayerId && (
                <>
                  <tr className="league-statistics-hide-border">
                    <td>{"Round Name" /* TODO: Translation */}</td>
                    <td>{"Gross Score/Gross Points" /* TODO: Translation */}</td>
                    <td>{"Net Score/Net Points" /* TODO: Translation */}</td>
                    <td></td>
                  </tr>
                  {selectedPlayerRounds ? (
                    <>
                      {selectedPlayerRounds?.map(round => (
                        <tr key={round.id}>
                          <td>{round.name}</td>
                          <td>{`${round.gross_score}/${round.gross_points_awarded}`}</td>
                          <td>{`${round.net_score}/${round.net_points_awarded}`}</td>
                          <td></td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr key={"Spin"}>
                      <td colSpan={4}>
                        <div style={{ height: "24px" }}>
                          <Spin />
                        </div>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </>
          );
        })}
      </DataTable>

      <Portal isMounted={loadHandicapsModal.isOpen}>
        <Sheet
          title="Load Previous Handicaps"
          open={loadHandicapsModal.isOpen}
          onOk={loadPreviousHandicaps}
          onCancel={closeLoadhandicapsModal}
          size="small"
          overflow
        >
          {loadHandicapsModal.previous_league_id ? (
            <div style={{ marginTop: "5px" }}>
              <div>
                <p className="event-label"> {"League" /* TODO: Translation */} </p>
              </div>

              <div className="selected-container">
                <div className="event-name">
                  <div>{loadHandicapsModal.selectedLeague.name}</div>

                  <div>
                    <button
                      className=""
                      onClick={() => updateLoadHandicapsModal({ previous_league_id: null, selectedLeague: null })}
                    >
                      <FontAwesomeIcon size="1x" icon={["far", "xmark"]} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Select
              placeholder="Search Leagues..." // TODO: Translation
              label="League" // TODO: Translation
              onChange={(value, extraValues) =>
                updateLoadHandicapsModal({ previous_league_id: value, selectedLeague: extraValues })
              }
              showSearch
              onSearch={(value: string) => updateLoadHandicapsModal({ leagueQuery: value })}
              showDropDownOnFocus
            >
              {leagues
                ?.filter(league => league.id !== Number(leagueId))
                ?.map(league => (
                  <Select.Option key={league.id} value={league.id} extraValues={league}>
                    {league.name}
                  </Select.Option>
                ))}
            </Select>
          )}
        </Sheet>
      </Portal>
    </Page>
  );
}
