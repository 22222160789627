import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StatusCode } from "api/protocols";
import { UserLogout } from "api/rpc";
import { NavigationDropdown } from "components/navigationDropdown/NavigationDropdown";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { IUIActions } from "redux/actions/ui";
import "./reservationsNavigation.scss";

interface ICustomerNavigationProps {
  uiActions: IUIActions;
  activeUser: boolean;
  userFirstName: string;
}

interface IParams {
  facilityShortName: string;
  moduleHandle: string;
}

export function ReservationsNavigation(props: ICustomerNavigationProps) {
  const { activeUser, userFirstName, uiActions } = props;

  const { facilityShortName, moduleHandle } = useParams<IParams>();

  const history = useHistory();

  async function logoutUser() {
    const logoutRes = await UserLogout(true);

    if (logoutRes.status !== StatusCode.OK) {
      uiActions.showError("Error Logging Out");
      return;
    }

    history.push(`/${facilityShortName}/${moduleHandle}`);
    window.location.href = `/${facilityShortName}/${moduleHandle}`;
  }

  function sendUserToLogin() {
    history.push(`/${facilityShortName}/${moduleHandle}/login`);
  }

  return (
    <div className="reservations-navigation-container">
      <div className="reservations-navigation-container-centered">
        <div className="reservations-navigation-bar">
          <div>
            <img className="reservations-navigation-tee-on-logo" src="../../../../public/images/Tee-On.png"></img>
          </div>
          <div>
            {activeUser ? (
              <div className="reservations-navigation-dropdown">
                <NavigationDropdown
                  showPlainTextLabel
                  label={
                    <span>
                      <span className="reservations-navigation-login-button-label">{`Hi, ${userFirstName}`}</span>
                      <FontAwesomeIcon icon={["fal", "bars"]} />
                    </span>
                  }
                  options={[{ label: "Logout", onClick: () => logoutUser() }]}
                />
              </div>
            ) : (
              <div>
                <button onClick={sendUserToLogin} className="reservations-navigation-login-button">
                  <span className="reservations-navigation-login-button-label">Login</span>
                  <FontAwesomeIcon icon={["fal", "arrow-right-long"]} />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
