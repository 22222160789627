import { APIGet, APIPost, APIPut, APIDelete, StatusCode, IAPIResponse } from "api/protocols";
import { CancelToken } from "axios";
import { useGlobalFilter } from "react-table";
import { ICustomer } from "redux/reducers/models/customer";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseRainCheckUrl = "/" + apiVersion + "/" + adminType + "/tee-sheet/rain-check";

interface IPutCalculateRainCheck {
  order_id: number;
  booking_participant_id: number;
  issue_on_green_fee: boolean;
  issue_on_power_cart: boolean;
  amount: number;
  percent: number;
}

interface IRedeemRainCheck {
  cart_id: number;
  rain_check_code: string;
}

interface IGetRainCheck {
  id?: number;
  customer_id?: number;
  code?: string;
  offset?: number;
  limit?: number;
}

export interface IRainCheck {
  id: number;
  last4: string;
  balance: number;
  currency: string;
  facility_id: number;
  client_id: number;
  customer_id: number;
  status: string;
  order_id: number;
  expires_at: string;
  note: string;
  admin_id: number;
  created_at: string;
  customer: ICustomer;
}

interface IGetRainCheckResponse extends IAPIResponse {
  data: IRainCheck[];
}

interface IPutRainCheck {
  id: number;
  customer_id?: number;
  status?: string;
}

interface IEmailRainCheck {
  code: string;
  customer_email: string;
}

export async function PutCalculateRainCheck(params: IPutCalculateRainCheck, useGlobalLoader: boolean) {
  return await APIPut(baseRainCheckUrl + "/calculate", params, useGlobalLoader);
}

export async function PostIssueRainCheck(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseRainCheckUrl + "/issue", params, useGlobalLoader);
}

export async function PostRedeemRainCheck(params: IRedeemRainCheck, useGlobalLoader: boolean) {
  return await APIPost(baseRainCheckUrl + "/redeem", params, useGlobalLoader);
}

export async function GetRainCheckReceipt(params: { code: number }, useGlobalLoader: boolean) {
  return await APIGet(baseRainCheckUrl + "/receipt", params, useGlobalLoader);
}

export async function EmailRainCheckReceipt(params: IEmailRainCheck, useGlobalLoader: boolean) {
  return await APIPut(baseRainCheckUrl + "/email", params, useGlobalLoader);
}

export async function GetRainChecks(
  params: IGetRainCheck,
  useGlobalLoader: boolean,
  token?: CancelToken,
): Promise<IGetRainCheckResponse> {
  return await APIGet(baseRainCheckUrl, params, useGlobalLoader, token);
}

export async function PutRainCheck(params: IPutRainCheck, useGlobalLoader: boolean) {
  return await APIPut(baseRainCheckUrl, params, useGlobalLoader);
}
