import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";

const apiVersion = "2022-09";
const adminType = "client-admin";
const baseCustomerUrl = "/" + apiVersion + "/" + adminType + "/customer/type";

interface ICustomerType {
  id?: number;
  title?: string;
  migrate_customer_type_id?: number;
}

interface IGetCustomerType {
  application: string;
}

interface ICustomerTypeRes {
  status: number;
  data: any;
}

//Get customer types
export async function GetCustomerType(params: IGetCustomerType, useGlobalLoader?: boolean): Promise<ICustomerTypeRes> {
  return await APIGet(baseCustomerUrl, params, useGlobalLoader);
}

//Post new customer type
export async function PostCustomerType(params: ICustomerType, useGlobalLoader?: boolean): Promise<ICustomerTypeRes> {
  return await APIPost(baseCustomerUrl, params, useGlobalLoader);
}

//Edit existing customer type
export async function PutCustomerType(params: ICustomerType, useGlobalLoader?: boolean): Promise<ICustomerTypeRes> {
  return await APIPut(baseCustomerUrl, params, useGlobalLoader);
}

//Delete and migrate customer type
export async function DeleteCustomerType(params: ICustomerType, useGlobalLoader?: boolean): Promise<ICustomerTypeRes> {
  return await APIDelete(baseCustomerUrl, params, useGlobalLoader);
}
