import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { GetModifierGroup } from "api/rpc/2024-04/masterAdmin/product/modifierGroups";

import Page from "components/page/Page";
import Card from "components/card/Card";
import { StatusCode } from "api/protocols";

import { useTranslation, Trans } from "react-i18next";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { showError, showSuccess } from "redux/actions/ui";

export default function ModifierGroup(props: any) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { masterFacilityStore } = useAppSelector(store => store);
  const dispatch = useAppDispatch();
  const [modifiersState, setModifiersState] = useState({
    modifierGroups: null,
  });

  useEffect(() => {
    void loadModifierGroups();

    return () => {
      setModifiersState({ modifierGroups: null });
    };
  }, [masterFacilityStore.facility]);

  async function loadModifierGroups() {
    const res = await GetModifierGroup({ facility_id: masterFacilityStore.facility?.id }, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading modifer groups"));
      return;
    }

    setModifiersState(prev => ({
      ...prev,
      modifierGroups: res.data,
    }));
  }

  function navigateToNewModifierGroup() {
    history.push("/admin/facility/settings/modifier-group/new");
  }

  function navigateToEditModifierGroup(url: string) {
    history.push(url);
  }

  const primaryAction = {
    content: t("secure.facility.settings.modifier_group.modifier_group.001"),
    action: navigateToNewModifierGroup,
    disabled: !masterFacilityStore.facility,
  };

  return (
    <Page
      title={"Modifier Groups"}
      narrow
      primaryAction={primaryAction}
      subtitle={masterFacilityStore.facility ? masterFacilityStore.facility.long_name : "No Facility Selected"}
    >
      {masterFacilityStore.facility && (
        <Card>
          <Card.Section table="true">
            <table className="ui-table ui-table-clickable">
              <thead>
                <tr>
                  <th>{t("secure.facility.settings.modifier_group.modifier_group.003")}</th>
                </tr>
              </thead>
              <tbody>
                {modifiersState.modifierGroups?.map((modifierGroup: any, index: any) => {
                  return (
                    <tr
                      key={index}
                      onClick={() =>
                        navigateToEditModifierGroup(
                          "/admin/facility/settings/modifier-group/" + String(modifierGroup.id),
                        )
                      }
                    >
                      <td>{modifierGroup.title}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Card.Section>
        </Card>
      )}
    </Page>
  );
}
