import { APIGet, APIPost, APIPut, APIDelete, StatusCode, IAPIResponse } from "api/protocols";
const apiVersion = "2022-09";
const adminType = "guest";

const baseUrl = "/" + apiVersion + "/" + adminType + "/auth";

interface IPasswordReset {
  email: string;
  code: string;
  password: string;
}

interface IValidateReset {
  email: string;
  code: string;
}

interface IPasswordResetCode {
  email: string;
  facility_id?: number;
  client_short_name?: string;
}

export async function GetValidateReset(params: IValidateReset, useGlobalLoader: boolean) {
  return await APIGet(baseUrl + "/validate-reset", params, useGlobalLoader);
}

export async function PutResetPassword(params: IPasswordReset, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/reset", params, useGlobalLoader);
}

export async function PostPasswordResetCode(params: IPasswordResetCode, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/reset-code", params, useGlobalLoader);
}
