import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";

const apiVersion = "2022-09";
const adminType = "client-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/facility";

interface IGetPayouts {
  id?: number;
  facility_id?: number;
}

interface IGetTransactions {
  facility_id: number;
  payout_id: number;
}

export async function GetPayout(params: IGetPayouts, useGlobalLoader: boolean) {
  return await APIGet(baseUrl + "/payout", params, useGlobalLoader);
}

export async function GetTransactions(params: IGetTransactions, useGlobalLoader: boolean) {
  return await APIGet(baseUrl + "/payout/transaction", params, useGlobalLoader);
}
