import { IconName, IconPrefix } from "@fortawesome/fontawesome-common-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import "./actionModal.scss";

export interface IAction {
  icon: IconName;
  iconPrefix?: IconPrefix;
  onClick: () => void;
  label: string;
  disabled: boolean;
}

interface IActionModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  actionGroups: IAction[][];
}

export default function ActionModal(props: IActionModalProps) {
  const { open, setOpen, actionGroups } = props;

  const modalRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideModalClick);
    document.addEventListener("touchstart", handleOutsideModalClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideModalClick);
      document.removeEventListener("touchstart", handleOutsideModalClick);
    };
  }, []);

  function handleOutsideModalClick(event: any) {
    if (!modalRef.current || modalRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  }

  function handleOnClick(onClick: () => void) {
    ReactDOM.unstable_batchedUpdates(() => {
      onClick();
      setOpen(false);
    });
  }

  return (
    <>
      {open && (
        <>
          <div ref={modalRef} className="action-modal">
            {actionGroups?.map((actions, index) => {
              return (
                <div key={index} className="action-modal-action-group">
                  {actions?.map((action, index) => {
                    return (
                      <button
                        key={index}
                        type="button"
                        className="action-modal-action-group-action"
                        onClick={() => handleOnClick(action.onClick)}
                        disabled={action.disabled}
                      >
                        <FontAwesomeIcon
                          className="action-modal-action-group-action-icon"
                          icon={[action.iconPrefix ? action.iconPrefix : "far", action.icon]}
                        />
                        <p className="action-modal-action-group-action-label">{action.label}</p>
                      </button>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div className="action-modal-backdrop" />
        </>
      )}
    </>
  );
}
