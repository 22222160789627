import React, { useState, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { showError, showSuccess } from "redux/actions/ui";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";
import { displayCurrency, uppercase } from "helpers/Helpers";

import { StatusCode } from "api/protocols";
import { GetAccount, TGetAccount, PostImportAccounts } from "api/rpc/2024-04/facilityAdmin/client/accounts/accounts";

import classNames from "classnames";
import Card from "components/card/Card";
import Page from "components/page/Page";
import { Badge } from "components/badge/Badge";
import { ButtonNew as Button } from "components/buttonNew";
import Input from "components/input/Input";
import FileImport from "components/fileImport/FileImport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Search from "components/search/Search";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";
import { HouseAccountType } from "../../customer/context/contextTypes";

import "../facility/facility.scss";

const TABLE_LIMIT = 50;
export default function Accounts() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { authStore, facilityStore } = useAppSelector(store => store);

  const [importModalVisible, setImportModalVisible] = useState<boolean>(false);

  const permissions = authStore?.user?.permissions;
  const facilityPermissions = facilityStore?.facility?.permissions;

  const [accounts, setAccounts] = useState<HouseAccountType[]>(undefined);

  // Back-end Filters.  Changes made triggers API call.
  const [filters, setFilters] = useState<TGetAccount>({
    search: "",
    offset: 0,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadAccounts(filters, source.token);
    return () => source.cancel();
  }, [filters.search, filters.offset]);

  async function loadAccounts(filters: TGetAccount, token?: CancelToken) {
    if (accounts !== undefined) {
      setAccounts(undefined);
    }
    const params: TGetAccount = {
      ...filters,
      limit: TABLE_LIMIT,
    };
    const res = await GetAccount(params, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading accounts"));
    }

    setAccounts(res.status !== StatusCode.OK ? [] : res.data);
  }

  async function importAccounts(selectedFile: File) {
    const formData = new FormData();

    formData.append("file", selectedFile);
    const importRes = await PostImportAccounts(formData, true);

    if (importRes.status !== StatusCode.OK) {
      dispatch(showError("Error importing accounts")); // TODO: Translation
    }

    dispatch(showSuccess("Accounts imported successfully")); // TODO: Translation
    setImportModalVisible(false);
    void loadAccounts(filters);
  }

  function navigateToNewAccount() {
    history.push("/admin/settings/accounts/new");
  }

  function navigateToAccountOverview(url: string) {
    history.push(url);
  }

  function handleTableNavigation(direction: "prev" | "next") {
    switch (direction) {
      case "prev": {
        setFilters({ ...filters, offset: filters.offset - TABLE_LIMIT });
        return;
      }
      case "next": {
        setFilters({ ...filters, offset: filters.offset + TABLE_LIMIT });
        return;
      }
      default:
        return;
    }
  }

  return (
    <Page
      title={t("secure.facility.settings.accounts.accounts.002")}
      multipleActionDropdownAction={{
        label: "Options",
        dropdownProps: {
          alignment: "right",
          options: [
            {
              type: "handler",
              label: "New Account", // TODO: Translation
              icon: "plus",
              handler: navigateToNewAccount,
            },
            {
              type: "handler",
              label: "Import", // TODO: Translation
              icon: "file-import",
              handler: () => setImportModalVisible(true),
              disabled: !permissions?.accounts_import || !facilityPermissions?.accounts_import,
            },
          ],
        },
      }}
      narrow
    >
      <div className="mb-4 w-full">
        <Search
          historyKey="account_settings"
          searchCallback={searchValue =>
            setFilters(prevState => ({ ...prevState, search: searchValue, offset: 0 }))
          }
          placeholder={t("secure.facility.settings.accounts.accounts.009")}
        />
      </div>
      <DataTable
        className="table-accounts_page"
        columns={[
          { label: t("secure.facility.settings.accounts.accounts.003") },
          { label: t("secure.facility.settings.accounts.accounts.004") },
          { label: t("secure.facility.settings.accounts.accounts.005") },
          { label: t("secure.facility.settings.accounts.accounts.006") },
          { label: t("secure.facility.settings.accounts.accounts.007") },
          { label: "" },
          { label: "" },
        ]}
        loading={accounts === undefined}
        footer={{
          tableLimit: TABLE_LIMIT,
          tableOffset: filters.offset,
          handleTableOffset: direction => handleTableNavigation(direction),
          disableNextOffset: !accounts || !(accounts?.length === TABLE_LIMIT),
        }}
      >
        {accounts?.map(account => (
          <tr
            key={account.id}
            onClick={() => history.push("/admin/settings/accounts/overview/" + String(account.id))}
            className="clickable"
          >
            <td>
              {account.customer ? <p className="table-cell-lead-text">{account.customer?.full_name}</p> : null}
              <p className={account.customer ? "table-cell-support-text" : "table-cell-lead-text"}>{account.title}</p>
            </td>
            <td>{account.account_number}</td>
            <td>{uppercase(account.currency)}</td>
            <td>{displayCurrency(account.currency, account.balance)}</td>
            <td>{displayCurrency(account.currency, Number(account.limit))}</td>
            <td>
              {account.global ? (
                <Badge type="gray" size="medium">
                  {t("secure.facility.settings.accounts.accounts.008")}
                </Badge>
              ) : null}
            </td>
            <td>
              {account.closed ? (
                // TODO: Translation
                <Badge type="error" size="medium">
                  {"Closed"}
                </Badge>
              ) : null}
            </td>
          </tr>
        ))}
      </DataTable>

      <FileImport onCancel={() => setImportModalVisible(false)} onOk={importAccounts} open={importModalVisible} />
    </Page>
  );
}
