import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { roundNumber, displayPercent, capitalize, customerErrorMessage } from "helpers/Helpers";
import moment from "moment";

import { GetOrder, GetReceipt, PutOrder } from "api/rpc/2024-04/masterAdmin/order/order";
import { GetCustomer, PostCustomer } from "api/rpc/2024-04/masterAdmin/customer/customer";
import { StatusCode } from "api/protocols";

import { IOrder, IOrderLineItem, IOrderTransaction } from "redux/reducers/models/order";

import "pages/secure/facility/order/order.scss";
import Page from "components/page/Page";
import Card from "components/card/Card";

import { LocaleCurrency } from "helpers/Locale";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Sheet from "components/sheet/Sheet";
import GolferCard from "components/bookingPopUp/golferCard/GolferCard";
import { Select } from "components/select/index";
import { ICustomer } from "redux/reducers/models/customer";

import NewCustomer, { ICustomerInfoState } from "components/newCustomer/NewCustomer";
import { t } from "i18next";
import { showError } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";

export interface IMainState {
  orders: any;
}

interface IProps {
  order: IOrder;
  line_items: IOrderLineItem[];
  transactions: IOrderTransaction;
}

interface IChangeCustomerState {
  showChangeCustomer: boolean;
  showNewCustomer: boolean;
  customerQuery: string;
  customerSearching: boolean;
  customerSearchResult: Array<ICustomer>;
  selectedCustomer: ICustomer;
  currentCustomer: any;
}

export default function Order(props: any) {
  const history = useHistory();
  const { orderId } = useParams<{ orderId: string }>();

  const [orderState, setOrderState] = useState<IProps>({
    order: null,
    line_items: [],
    transactions: null,
  });

  const { Option } = Select;
  const dispatch = useAppDispatch();

  const { teeSheetActions, uiActions } = props;

  const [changePlayerState, setChangePlayerState] = useState<IChangeCustomerState>({
    showChangeCustomer: false,
    showNewCustomer: false,
    customerQuery: "",
    customerSearching: false,
    customerSearchResult: [],
    selectedCustomer: null,
    currentCustomer: null,
  });

  useEffect(() => {
    void loadOrder();
  }, []);

  async function loadOrder() {
    const orderRes = await GetOrder({ id: Number(orderId), extended: true }, true);

    if (orderRes.status !== StatusCode.OK) {
      return;
    }

    const order = orderRes.data[0];

    const lineItems = order.line_items.filter((lineItem: { product_type: string }) => {
      return lineItem.product_type !== "Modifier";
    });
    const modifiers = order.line_items.filter((lineItem: { product_type: string }) => {
      return lineItem.product_type === "Modifier";
    });

    for (let i = 0; i < lineItems?.length; i++) {
      const lineItemModifiers = [];

      for (let k = 0; k < modifiers.length; k++) {
        if (lineItems[i].id === modifiers[k].parent_id) {
          lineItemModifiers.push(modifiers[k]);
        }
      }

      lineItems[i].modifiers = lineItemModifiers.length !== 0 ? lineItemModifiers : null;
    }

    setOrderState(prevState => ({
      ...prevState,
      order: order,
      line_items: lineItems,
      transactions: order.transactions,
    }));
  }

  async function handlePrintReceipt() {
    const receiptRes = await GetReceipt({ id: Number(orderId) }, true);

    if (receiptRes.status === StatusCode.OK) {
      window.open().document.write(receiptRes.data);
    } else {
      props.uiActions.showError(receiptRes.message);
    }
  }

  function handleBackToOrders() {
    history.push("/admin/order");
  }

  function handleOrderPrint() {
    console.log("Order Print Clicked");
  }
  function handleOrderRefund() {
    history.push("/admin/order/" + String(orderId) + "/refund");
  }
  function handleOrderDropdown() {
    console.log("Order Dropdown Clicked");
  }
  function handleItemClick(itemId: string) {
    console.log("Item Clicked");
  }

  function backToOrders() {
    history.push("/admin/order");
  }

  function editCustomerDetails() {
    //history.push("/admin/customer/" + String(customerState.id) + "/edit");
  }

  async function createNewCustomer(customerInfo: ICustomerInfoState) {
    const inputError =
      customerInfo.firstName === "" ||
      customerInfo.lastName === "" ||
      (customerInfo.emailAddress === "" && customerInfo.phoneNumber === "");

    if (inputError) {
      //uiActions.showError("Please fill all fields");
    }

    const customerRes = await PostCustomer(
      {
        first_name: customerInfo.firstName,
        last_name: customerInfo.lastName,
        phone: customerInfo.phoneNumber || null,
        email: customerInfo.emailAddress || null,
        state: "disabled",
      },
      true,
    );

    if (customerRes.status !== StatusCode.OK) {
      dispatch(showError(customerErrorMessage(t, customerRes?.data?.message)));
      return;
    }
    setChangePlayerState(prevState => ({
      ...prevState,
      showNewCustomer: false,
      selectedCustomer: customerRes.data,
    }));
    //uiActions.showSuccess(customerRes.data.message);
  }

  function editCustomerAddress() {}

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    console.log(event);
  }

  function actionTest() {
    console.log("action test");
  }

  function closeChangePlayer() {
    closeCreateNewCustomer();
    setChangePlayerState(prevState => ({
      ...prevState,
      showNewCustomer: false,
      currentCustomer: null,
      showChangeCustomer: false,
      customerQuery: "",
      customerSearchResult: [],
      selectedCustomer: null,
    }));
  }

  function closeCreateNewCustomer() {
    setChangePlayerState(prevState => ({
      ...prevState,
      showNewCustomer: false,
    }));
  }

  async function changeSelectedPlayer() {
    console.log(changePlayerState);
    console.log(orderState);

    const orderCustomerRes = await PutOrder(
      { id: orderState.order.id, customer_id: changePlayerState.selectedCustomer.id },
      true,
    );

    console.log(orderCustomerRes);

    if (orderCustomerRes.status !== StatusCode.OK) {
      uiActions.showError(orderCustomerRes.message);
      return;
    }

    //uiActions.showSuccess(orderCustomerRes.message);

    setChangePlayerState(prevState => ({
      ...prevState,
      currentCustomer: null,
      showChangeCustomer: false,
      customerSearchResult: [],
      customerQuery: "",
      selectedCustomer: null,
    }));

    void loadOrder();
  }

  function changePlayerHandleCustomerSearch(query: string) {
    setChangePlayerState(prevState => ({ ...prevState, customerQuery: query }));
  }

  function handleChangePlayerSelection(id: number, customer: ICustomer) {
    setChangePlayerState(prevState => ({ ...prevState, selectedCustomer: customer }));
  }

  function createNewPlayerInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setChangePlayerState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleShowCustomerChange(customer: any) {
    setChangePlayerState(prevState => ({
      ...prevState,
      showChangeCustomer: true,
      showNewCustomer: false,
      customerQuery: "",
      customerSearchResult: [],
      currentCustomer: customer,
    }));
  }

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = global.setTimeout(() => {
        void changePlayerSearch(mounted, changePlayerState.customerQuery);
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      setChangePlayerState(prevState => ({ ...prevState, playerSearchResult: [] }));
    };
  }, [changePlayerState.customerQuery]);

  async function changePlayerSearch(mounted: boolean, customerSearchQuery: string) {
    try {
      if (customerSearchQuery === "") {
        if (mounted) {
          setChangePlayerState(prevState => ({ ...prevState, customerSearchResult: [] }));
        }
        return;
      } else {
        setChangePlayerState(prevState => ({ ...prevState, customerSearching: true }));
        const customerRes = await GetCustomer({ search: customerSearchQuery }, false);
        if (customerRes.status !== StatusCode.OK) {
          setChangePlayerState(prevState => ({ ...prevState, customerSearching: false, customerSearchResult: [] }));
          return;
        } else if (mounted) {
          setChangePlayerState(prevState => ({
            ...prevState,
            customerSearching: false,
            customerSearchResult: customerRes.data,
          }));
        }
      }
    } catch (error) {
      console.log("err", error);
    }
    return;
  }

  function openNewCustomerSheet() {
    setChangePlayerState(prevState => ({
      ...prevState,
      showNewCustomer: true,
    }));
  }

  function orderProcessInfo() {
    let info =
      moment.utc(orderState.order?.processed_at).local().format("MMMM DD, YYYY") +
      " at " +
      moment.utc(orderState.order?.processed_at).local().format("h:mm A") +
      " by ";
    if (orderState.order?.sales_channel === "online_store") {
      info = info + orderState.order?.customer?.full_name + " from Online Store";
    } else if (orderState?.order?.sales_channel === "booking_engine") {
      info = info + orderState.order?.customer?.full_name + " from Booking Engine";
    } else {
      info = info + orderState.order?.admin_user?.full_name + " on " + orderState.order?.register?.title;
    }

    return info;
  }

  return (
    <>
      <Page
        narrow
        splitLayout
        title={"Order " + orderState.order?.name}
        subtitle={orderProcessInfo()}
        breadcrumbs={[{ prefix: true, label: "Back to Orders", url: "/admin/order" }]}
        subActions={[
          { label: "Receipt", icon: null, type: "text", onClick: handlePrintReceipt },
          // { label: "Refund", icon: null, type: "text", onClick: handleOrderRefund },
        ]}
      >
        <Page.Section twoThirds>
          <Card title="Items">
            <Card.Section>
              {orderState.order?.line_items &&
                orderState.line_items?.map((item: any, index: number) => {
                  return (
                    <Card.SubSection key={index}>
                      <div className="order-line-item">
                        <div>
                          <p className="text-lg text-semibold">{item.product_title}</p>
                          {item.product_title !== item.variant_title ? (
                            <p className="text-sm text-gray-500">{item.variant_title}</p>
                          ) : null}
                          {/* {item.product_type === "Ticket" ? (
                            <Button type="link" onClick={() => handleTicketDownload(item.id)}>
                              {t("secure.facility.order.order.017")}
                            </Button>
                          ) : null} */}
                        </div>
                        <div className="text-right">
                          <p className="text-lg text-gray-500">
                            {item.quantity} x{" "}
                            <LocaleCurrency currency={orderState.order?.currency} amount={item.price} />
                          </p>
                        </div>
                        <div className="text-right">
                          <p className="text-lg text-semibold">
                            <LocaleCurrency currency={orderState.order?.currency} amount={item.price * item.quantity} />
                          </p>
                        </div>
                      </div>

                      {item.modifiers ? (
                        <div>
                          {item.modifiers.map((modifier: any, index: number) => {
                            return (
                              <div key={index} className="order-line-item">
                                <div style={{ color: "#475467", marginLeft: "15px" }}>{modifier.product_title}</div>
                                <div className="text-right">
                                  <p className="text-lg text-gray-500">
                                    {modifier.quantity} x{" "}
                                    <LocaleCurrency currency={orderState.order?.currency} amount={modifier.price} />
                                  </p>
                                </div>
                                <div className="text-right">
                                  <p className="text-lg text-semibold">
                                    <LocaleCurrency
                                      currency={orderState.order?.currency}
                                      amount={modifier.price * modifier.quantity}
                                    />
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : null}

                      {orderState.order?.discount_lines?.map((discount: any, index: number) => {
                        if (discount.order_line_item_id === item.id) {
                          return (
                            <div key={index} className="text-right">
                              <p className="text-lg text-gray-500">
                                {discount.title} -{" "}
                                <LocaleCurrency currency={orderState.order?.currency} amount={discount.amount} />
                              </p>
                            </div>
                          );
                        }
                      })}
                      {item.total_discount !== 0 && (
                        <div className="text-right">
                          <p className="text-lg text-semibold">
                            <LocaleCurrency currency={orderState.order?.currency} amount={item.subtotal_price} />
                          </p>
                        </div>
                      )}
                    </Card.SubSection>
                  );
                })}
            </Card.Section>
            <Card.Section>
              <div className="order-totals_container">
                <div className="order-totals">
                  <div className="order-totals_row ui-grid ui-grid-2">
                    <span>Subtotal</span>
                    <LocaleCurrency currency="cad" amount={orderState.order?.subtotal_price} />
                  </div>
                  <>
                    {orderState.order?.tax_lines &&
                      orderState.order.tax_lines.map((tax_line: Record<string, any>, index: number) => {
                        return (
                          <div key={index} className="order-totals_row ui-grid ui-grid-2">
                            <span>
                              {tax_line.title} <span className="text-subdued">{displayPercent(tax_line.rate)}</span>
                            </span>
                            <LocaleCurrency currency="cad" amount={tax_line.price} />
                          </div>
                        );
                      })}
                  </>

                  {orderState.order?.total_tip > 0 ? (
                    <div className="order-totals_row ui-grid ui-grid-2">
                      <span>Tip</span>
                      <LocaleCurrency currency="cad" amount={orderState.order?.total_tip} />
                    </div>
                  ) : null}

                  <div className="order-totals_row ui-grid ui-grid-2 text-bold text-large">
                    <span className="">Total</span>
                    <LocaleCurrency currency="cad" amount={orderState.order?.total_price} />
                  </div>

                  {orderState.order?.transactions &&
                    orderState.order.transactions.map((transaction: any, index: number) => {
                      return (
                        <div key={index} className="order-totals_row ui-grid ui-grid-2">
                          <span>
                            {capitalize(transaction.payment_type)}
                            {transaction.payment_last_4 ? (
                              <span className="text-subdued ml-4">•••• {transaction.payment_last_4}</span>
                            ) : null}
                          </span>
                          <div>
                            {transaction?.kind === "refund" ? <span>- </span> : null}
                            <LocaleCurrency currency="cad" amount={transaction.amount} />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </Card.Section>
          </Card>
          {orderState.order?.customer_notes && (
            <Card title="Customer Notes">
              <Card.Section>
                <div className="text-md">{orderState.order.customer_notes}</div>
              </Card.Section>
            </Card>
          )}
        </Page.Section>
        <Page.Section oneThird>
          <Card
            title="Customer"
            titleActions={[
              {
                action: () => handleShowCustomerChange(orderState.order?.customer),
                content: "Edit",
              },
            ]}
          >
            <Card.Section>
              <div className="order-customer">
                {orderState.order?.customer ? (
                  <div>
                    <p>{orderState.order?.customer?.full_name}</p>
                    <p>{orderState.order?.customer?.email}</p>
                    <p>{orderState.order?.customer?.phone}</p>
                    <p>{orderState.order?.customer?.customer_type}</p>
                  </div>
                ) : (
                  <div>
                    <p>No Customer</p>
                  </div>
                )}
              </div>
            </Card.Section>
          </Card>
          <Card title="Location">
            <div className="order-customer">
              {orderState.order?.client ? (
                <div>
                  <p className="ml-4">{orderState.order?.client?.long_name}</p>
                </div>
              ) : (
                <div>
                  <p>No Client</p>
                </div>
              )}
              {orderState.order?.facility ? (
                <div>
                  <p className="ml-4">{orderState.order?.facility?.long_name}</p>
                </div>
              ) : (
                <div>
                  <p>No Facility</p>
                </div>
              )}
            </div>
          </Card>
        </Page.Section>
      </Page>

      <Sheet
        title={changePlayerState.showNewCustomer ? "New Customer" : "Change Custommer"}
        open={changePlayerState.showChangeCustomer}
        size="small"
        closable
        onCancel={closeChangePlayer}
        onOk={changeSelectedPlayer}
        okText="Confirm"
        okDisabled={changePlayerState.selectedCustomer ? false : true}
        overflow
      >
        {changePlayerState.showNewCustomer ? (
          <NewCustomer
            newCustomerSheetActive={changePlayerState.showNewCustomer}
            onCancel={closeCreateNewCustomer}
            onOk={createNewCustomer}
            searchValue={changePlayerState.customerQuery}
          />
        ) : (
          <>
            {changePlayerState.currentCustomer !== null ? (
              <div>
                <span>Previous Customer</span>

                <GolferCard
                  email={changePlayerState.currentCustomer?.email}
                  name={changePlayerState.currentCustomer?.full_name}
                  memberCode={changePlayerState.currentCustomer?.member_code}
                  customerType={changePlayerState.currentCustomer?.customer_type}
                  phone={changePlayerState.currentCustomer?.phone}
                />

                <FontAwesomeIcon
                  className="ml-auto mr-auto mt-4 mb-2 block"
                  icon={["far", "arrow-circle-down"]}
                  size="2x"
                  color="#3D5FA0"
                />
              </div>
            ) : null}

            <span>New Customer</span>
            {changePlayerState.selectedCustomer ? (
              <GolferCard
                closable
                removeGolfer={() =>
                  setChangePlayerState(prevState => ({
                    ...prevState,
                    selectedCustomer: null,
                    playerSearchResult: [],
                    customerQuery: "",
                  }))
                }
                email={changePlayerState.selectedCustomer.email}
                name={changePlayerState.selectedCustomer.full_name}
                memberCode={changePlayerState.selectedCustomer.member_code}
                customerType={changePlayerState.selectedCustomer.customer_type}
                phone={changePlayerState.selectedCustomer.phone}
              />
            ) : (
              <Select
                showSearch
                className={`flex justify-center align-center w-full h-10 position-relative z-20 text-black font-medium appearance-none border-none focus:outline-none placeholder-gray-200`}
                onSearch={(query: string) => changePlayerHandleCustomerSearch(query)}
                onChange={(id: number, customer: ICustomer) => handleChangePlayerSelection(id, customer)}
                placeholder="Search player..."
                allowClear
                searchValue={changePlayerState.customerQuery}
                showDropDownOnFocus={true}
                searching={changePlayerState.customerSearching}
              >
                <div className="ui-select-dropdown-list-item" onClick={() => openNewCustomerSheet()}>
                  <p>New Customer</p>
                </div>
                {changePlayerState.customerSearchResult?.map((customer, index) => {
                  return (
                    <Option key={index} value={customer.id} name={customer.full_name} extraValues={customer}>
                      <div className="flex justify-between">
                        <div>
                          <div className="font-semibold text-lg">{customer?.full_name}</div>
                          <div className="text-sm text-gray-500">{customer.customer_type}</div>
                          <div className="text-sm text-gray-500">{customer.email}</div>
                          <div className="text-sm text-gray-500">{customer.phone ? customer.phone : null}</div>
                        </div>

                        <div className="font-medium text-base text-gray-500 self-end">{customer.member_code}</div>
                      </div>
                    </Option>
                  );
                })}
              </Select>
            )}
          </>
        )}
      </Sheet>
    </>
  );
}
