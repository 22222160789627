import { APIGet, APIPost, APIPut, APIDelete, StatusCode } from "api/protocols";
import { CancelToken } from "axios";
import { IGateway, IKind, IPaymentMethod, IProcessingType, ISource } from "redux/reducers/models/transaction";
import { ICustomer } from "redux/reducers/models/customer";

const apiVersion = "2022-09";
const newApiVersion = "2024-04";
const adminType = "facility-admin";

const baseCustomerUrl = "/" + apiVersion + "/" + adminType + "/customer";
const baseHouseAccountUrl = "/" + apiVersion + "/" + adminType + "/client";
const newBaseHouseAccountUrl = "/" + newApiVersion + "/" + adminType + "/client";
const houseAccountPaymentUrl = "/" + apiVersion + "/" + adminType + "/client/account/transaction";

interface IGetCustomer {
  id?: number;
  search?: string;
  state?: string;
  "address-lines"?: boolean;
  "default-address-line"?: boolean;
  memberships?: boolean;
  limit?: number;
  offset?: number;
}

interface IPostCustomer {
  id?: number;
  first_name?: string;
  last_name?: string;
  phone?: string;
  email?: string;
  member_number?: number;
}

interface ICustomerRes {
  status: number;
  message?: string;
  data: any;
}

// GET Cart
// Retrieve a list of customers
export async function GetCustomer(params: IGetCustomer, useGlobalLoader?: boolean, cancelToken?: CancelToken) {
  return await APIGet<ICustomer[]>(baseCustomerUrl, params, useGlobalLoader, cancelToken);
}

export async function PostCustomer(params: IPostCustomer, useGlobalLoader: boolean): Promise<ICustomerRes> {
  return await APIPost(baseCustomerUrl, params, useGlobalLoader);
}

export async function PutCustomer(params: any, useGlobalLoader: boolean): Promise<ICustomerRes> {
  return await APIPut(baseCustomerUrl, params, useGlobalLoader);
}

export async function DeleteCustomer(params: { id: number }, useGlobalLoader: boolean): Promise<ICustomerRes> {
  return await APIDelete(baseCustomerUrl, params, useGlobalLoader);
}

// Upload Customer Profile Image
export async function UploadCustomerProfileImage(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseCustomerUrl + "/profile-image", params, useGlobalLoader);
}

// Delete Customer Profile Image
export async function DeleteCustomerProfileImage(params: any, useGlobalLoader: boolean) {
  return await APIDelete(baseCustomerUrl + "/profile-image", params, useGlobalLoader);
}

//Get Customer Notes
export async function GetCustomerNotes(params: { customer_id: number }, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseCustomerUrl + "/note", params, useGlobalLoader, token);
}

//POST Customer Notes
export async function PostCustomerNote(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseCustomerUrl + "/note", params, useGlobalLoader);
}

//PUT Customer Note
export async function PutCustomerNote(params: { id: number; content: string }, useGlobalLoader: boolean) {
  return await APIPut(baseCustomerUrl + "/note", params, useGlobalLoader);
}

//Delete Customer Note
export async function DeleteCustomerNote(params: { id: number }, useGlobalLoader: boolean) {
  return await APIDelete(baseCustomerUrl + "/note", params, useGlobalLoader);
}

export async function SendPasswordReset(params: { email: string }, useGlobalLoader: boolean) {
  return await APIPost(baseCustomerUrl + "/reset", params, useGlobalLoader);
}

interface IDownloadCustomerAccountStatements {
  account_statement_id: number;
  account_id: number;
  start_date: Date;
  end_date: Date;
}

export async function DownloadCustomerAccountStatements(
  params: Partial<IDownloadCustomerAccountStatements>,
  useGlobalLoader: boolean,
) {
  return await APIGet(baseHouseAccountUrl + "/account/statement/download", params, useGlobalLoader);
}

export async function GetCustomerHouseAccounts(
  params: { customer_id: number; closed?: boolean },
  useGlobalLoader?: boolean,
  token?: CancelToken,
): Promise<ICustomerRes> {
  return await APIGet(newBaseHouseAccountUrl + "/account", params, useGlobalLoader, token);
}

export async function GetAccountTransactionReceipt(
  params: { account_transaction_id: number },
  useGlobalLoader: boolean,
) {
  return await APIGet(houseAccountPaymentUrl + "/receipt", params, useGlobalLoader);
}

export async function GetCustomerStatements(
  params: { id?: number; account_id?: number },
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseHouseAccountUrl + "/account/statement", params, useGlobalLoader, token);
}

export async function PutMergeCustomers(
  params: { parent_id: number; child_id: number },
  useGlobalLoader: boolean,
): Promise<ICustomerRes> {
  return await APIPut(baseCustomerUrl + "/merge", params, useGlobalLoader);
}

export async function SendWelcomeEmail(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseCustomerUrl + "/welcome", params, useGlobalLoader);
}
