import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import classnames from "classnames";
import "./password.scss";
import useOnclickOutside from "hooks/useOnclickOutside/useOnclickOutside";

interface IPasswordProps {
  className?: string;
  placeholder?: string;
  value?: string | number | readonly string[];
  label?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  labelStyle?: React.CSSProperties;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const Password: React.FC<IPasswordProps> = props => {
  const { placeholder, className, value, onChange, label, name, labelStyle } = props;

  const [hidePassword, setHidePassword] = useState<boolean>(true);
  const [focus, setFocus] = useState<boolean>(false);

  const handleHidePassword = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    setFocus(true);
    setHidePassword(!hidePassword);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    }
  };

  const wrapperCls = classnames("rc-input-wrapper", {
    "rc-input-wrapper-focused": focus,
    "rc-input-accomodate-label": label,
  });

  const iconCls = classnames(
    "rc-input-icon",
    {
      "rc-input-icon-eye-slash": hidePassword === true,
      "rc-input-icon-eye": hidePassword === false,
    },
    className,
  );

  const handleFocus = () => {
    setFocus(true);
  };
  const handleBlur = () => {
    setFocus(false);
  };

  const inputRef = useRef(null);

  const handleWrapperClick = () => {
    handleFocus();

    if (inputRef) {
      inputRef.current.focus();
    }
  };

  const wrapperRef = useOnclickOutside(() => {
    setFocus(false);
  });

  const iconMouseDown = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    // Prevent focused state lost
    e.preventDefault();
  };
  return (
    <span className={wrapperCls} onClick={handleWrapperClick} ref={wrapperRef}>
      {label && (
        <label className="rc-input-label" htmlFor={name} style={labelStyle}>
          {label}
        </label>
      )}
      <input
        className="rc-input"
        placeholder={placeholder}
        formAction="click"
        type={hidePassword ? "password" : "text"}
        value={value}
        onChange={handleInputChange}
        onFocus={handleFocus}
        ref={inputRef}
        name={name}
        onBlur={handleBlur}
        onKeyDown={props.onKeyDown}
      />

      <span onMouseDown={e => iconMouseDown(e)} onClick={e => handleHidePassword(e)} className={iconCls}>
        {hidePassword ? <FontAwesomeIcon icon={["far", "eye-slash"]} /> : <FontAwesomeIcon icon={["far", "eye"]} />}
      </span>
    </span>
  );
};

export default Password;
