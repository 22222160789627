import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { IFacility } from "redux/reducers/models/facility";

const apiVersion = "2024-04";
const adminType = "master-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/facility";

interface IGetFacility {
  id?: number;
  search?: string;
  limit?: number;
  offset?: number;
  client_id?: number;
  permissions?: boolean;
}

interface IPutFacility extends IFacility {
  facility_id: number;
}

interface IPostFacility extends IFacility {
  client_id: number;
  courses: any;
}

// GET Facility
export async function GetFacility(params: IGetFacility, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseUrl, params, useGlobalLoader, token);
}

// PUT Facility
export async function PutFacility(params: IPutFacility, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

// POST Facility
export async function PostFacility(params: IPostFacility, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

interface IPutFacilityPermissions {
  client_id: number;
  facility_permissions: Array<{
    courses_create: boolean;
    customer_types_create: boolean;
    divisions_create: boolean;
    facility_id: number;
    gift_cards_import: boolean;
    products_create: boolean;
    products_edit: boolean;
  }>;
}

// PUT Facility Permissions
export async function PutFacilityPermissions(params: IPutFacilityPermissions, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/permission", params, useGlobalLoader);
}
