import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonNew as Button } from "components/buttonNew";
import "./monthPicker.scss";
import { monthNames } from "helpers/Helpers";
import useOutsideAlerter from "hooks/useOutsideAlerter/useOutsideAlerter";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

interface IProps {
  /**Function that runs when the apply button is selected. The YYYY-MM string is the argument passed to this function*/
  onApply: (date: string) => void;
  /**Optional prop that appends the day to the end of the YYYY-MM string*/
  day?: number;
  /**Optional prop that displays label text above the Month Picker input */
  label?: string;
  /**Optional prop for setting the year range. Years will be generated by adding and subtracting this number by the starting year. On default this value is 100 */
  yearRange?: number;
  /**Optional prop for setting the initial selected year. On default this value is the current year*/
  startYear?: number;
  /**Optional prop for setting the initial selected month. Must be a number between 1-12. On default this value is the current month */
  startMonth?: number;
}

interface IState {
  years: Array<string>;
  months: Array<IMonth>;
  selectedMonth: IMonth;
  selectedYear: string;
  monthPickerOpen: boolean;
  page: "month" | "year";
}

interface IMonth {
  id: number;
  label: string;
  fullLabel: string;
}

const DEFAULT_YEAR_RANGE = 100;
const DEFAULT_INITIAL_YEAR = new Date().getFullYear();
const DEFAULT_INITIAL_MONTH = new Date().getMonth() + 1;

/**
 * Component that allows user to select a year and month. Returns a string formatted YYYY-MM
 * @param {IProps} props
 * @param {(date: string) => void} props.onApply Callback that runs when the apply button is selected. The YYYY-MM string is the argument passed to this function
 * @param {number} props.day Appends the day to the end of the YYYY-MM string. Format of returned string is YYYY-MM-DD `(optional)`
 * @param {string} props.label Displays label text above the Month Picker input `(optional)`
 * @param {number} props.yearRange For setting the year range. Years with be generated by adding and subtracting this number by the starting year. On default this value is 100. `(optional)`
 * @param {number} props.startYear Sets the initial selected year. On default this value is the current year `(optional)`
 * @param {number} props.startMonth Sets the initial selected month. Must be a number between 1-12. On default this value is the current month `(optional)`
 *
 * @example return: "2023-03"
 * @returns {JSX.Element}
 */
export default function MonthPicker(props: IProps): JSX.Element {
  const {
    onApply,
    day,
    label,
    yearRange = DEFAULT_YEAR_RANGE,
    startYear = DEFAULT_INITIAL_YEAR,
    startMonth = DEFAULT_INITIAL_MONTH,
  } = props;
  const { t, i18n } = useTranslation();
  const [state, setState] = useState<IState>({
    years: [],
    months: [],
    selectedMonth: null,
    selectedYear: "",
    monthPickerOpen: false,
    page: "month",
  });
  const monthPickerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    void setInitialValues();
  }, []);

  const yearRef = useCallback(
    element => {
      if (state.monthPickerOpen) {
        if (element !== null && element?.id === state?.selectedYear) {
          element?.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }
    },
    [state.monthPickerOpen, state.page],
  );

  const monthsRef = useCallback(
    element => {
      if (state.monthPickerOpen) {
        if (element !== null && element?.id === state?.selectedMonth?.id?.toString()) {
          element?.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }
    },
    [state.monthPickerOpen, state.selectedMonth, state.page],
  );

  const years = useMemo(() => {
    return state?.years?.map((year, index) => {
      return (
        <div
          key={index}
          id={year}
          ref={yearRef}
          onClick={() => setState(prevState => ({ ...prevState, selectedYear: year, page: "month" }))}
          className={classNames("month-picker-grid-container", {
            "month-picker-grid-container-selected": year === state.selectedYear,
          })}
        >
          <p className="month-picker-inner-grid-container">{year}</p>
        </div>
      );
    });
  }, [state.years, state.selectedYear, yearRef]);

  function setInitialValues() {
    const startingMonth = startMonth >= 1 && startMonth <= 12 ? startMonth : DEFAULT_INITIAL_MONTH;
    let years = state.years;
    let months = state.months;
    if (years?.length < 1 || months?.length < 1) {
      years = generateYears(yearRange, startYear);
      months = generateMonths();
    }

    const currentMonth = months?.find(month => month.id === startingMonth);
    const currentYear = startYear.toString();
    setState(prevState => ({
      ...prevState,
      years: years,
      months: months,
      selectedMonth: currentMonth,
      selectedYear: currentYear,
      page: "month",
    }));
  }

  function generateMonths() {
    const months: Array<IMonth> = [];
    monthNames(t)?.forEach((month, index) => {
      months.push({ id: index + 1, label: month.slice(0, 3), fullLabel: month });
    });
    return months;
  }

  function handleOpenMonthPicker() {
    setState(prevState => ({ ...prevState, monthPickerOpen: true }));
  }

  function handleCloseMonthPicker(e: React.MouseEvent) {
    e.stopPropagation();
    setState(prevState => ({ ...prevState, monthPickerOpen: false }));
  }

  function selectCurrentDate(type: "month" | "year") {
    const currentDate = new Date();
    if (type === "month") {
      const currentMonth = state?.months?.find(month => month.id === currentDate.getMonth() + 1);
      setState(prevState => ({ ...prevState, selectedMonth: currentMonth }));
    } else if (type === "year") {
      const currentYear = currentDate?.getFullYear();
      setState(prevState => ({ ...prevState, selectedYear: currentYear.toString(), page: "month" }));
    }
  }

  function handleApplyButton(e: React.MouseEvent) {
    e.stopPropagation();
    const month = state.selectedMonth?.id?.toString()?.padStart(2, "0");
    const dateString = `${state.selectedYear}-${month}${day ? "-".concat(day.toString()) : ""}`;
    void onApply(dateString);
    setState(prevState => ({ ...prevState, monthPickerOpen: false }));
  }

  useOutsideAlerter(monthPickerRef, () => {
    setState(prevState => ({ ...prevState, monthPickerOpen: false }));
  });

  return (
    <div className="month-picker">
      <div>
        {label && <div className="month-picker-label">{label}</div>}
        <div onClick={handleOpenMonthPicker} ref={monthPickerRef} tabIndex={0} className="month-picker-input-container">
          <FontAwesomeIcon icon={["far", "calendar"]} size="1x" className="calendar-icon" />
          <p className="date-year-text">{state.selectedMonth?.fullLabel}</p>
          <p className="date-year-text">{state.selectedYear}</p>

          <div
            style={{
              display: state.monthPickerOpen ? "flex" : "none",
              maxHeight: `${window.innerHeight - monthPickerRef?.current?.getBoundingClientRect().bottom}px`,
            }}
            className="month-picker-popup"
          >
            <div className="month-picker-top-bar">
              <div
                onClick={
                  state?.page === "month" ? () => setState(prevState => ({ ...prevState, page: "year" })) : undefined
                }
                className="month-picker-year-button"
              >
                <FontAwesomeIcon
                  className="year-button"
                  icon={["far", "chevron-left"]}
                  size="1x"
                  style={{ visibility: state.page === "year" ? "hidden" : undefined }}
                />
                <p className="date-year-text">{state?.selectedYear}</p>
              </div>
              {state.page === "month" ? (
                <p onClick={() => selectCurrentDate("month")} className="month-picker-today-button">
                  This Month
                </p>
              ) : (
                <p onClick={() => selectCurrentDate("year")} className="month-picker-today-button">
                  This Year
                </p>
              )}
            </div>

            <div className="month-picker-grid" style={{ display: state.page === "month" ? "grid" : "none" }}>
              {state.months?.map((month, index) => {
                return (
                  <div
                    key={index}
                    id={month.id?.toString()}
                    ref={monthsRef}
                    onClick={() => setState(prevState => ({ ...prevState, selectedMonth: month }))}
                    className={classNames("month-picker-grid-container", {
                      "month-picker-grid-container-selected": month.id === state.selectedMonth?.id,
                    })}
                  >
                    <p className="month-picker-inner-grid-container">{month?.label}</p>
                  </div>
                );
              })}
            </div>
            <div className="month-picker-grid" style={{ display: state.page === "year" ? "grid" : "none" }}>
              {years}
            </div>

            <div className="month-picker-bottom-bar">
              <Button size="medium" block type="secondary" onClick={handleCloseMonthPicker}>
                Cancel
              </Button>
              <Button
                size="medium"
                block
                type="primary"
                onClick={handleApplyButton}
                disabled={!state.selectedMonth || !state.selectedYear || state.page === "year"}
              >
                Apply
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function generateYears(
  yearRange: number = DEFAULT_YEAR_RANGE,
  initialYear: number = DEFAULT_INITIAL_YEAR,
): Array<string> {
  const years = [];
  let startYear = initialYear - yearRange;
  const endYear = initialYear + yearRange;
  for (let i = startYear; i <= endYear; i++) {
    years.push(startYear.toString());
    startYear++;
  }
  return years;
}
