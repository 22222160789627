import { ComponentKey, FilterKey, FilterActionType } from "./types";
import { FilterValue, IFilterAction } from "redux/actions/filters/filters";

// define filter conditions type: field, and values
export type FilterCondition = [FilterKey, FilterValue[]];

export type DBFilter = {
  label: FilterKey;
  value: FilterValue[];
};
export type DBFilterContext = DBFilter & {
  normalizedString: string;
};

export const isPMFilter = (label: FilterKey) =>
  label === "total_equal_to" || label === "total_greater_than" || label === "total_less_than";

const initialState: DBFilterContext[] = [];

export function FilterReducer<T>(state = initialState, action: IFilterAction<T>): DBFilterContext[] {
  switch (action.type) {
    case FilterActionType.ADD: {
      const group = state.filter(group => group.label === (action.payload.groupKey as unknown as string));

      if (group.length === 0) {
        console.log(action.payload);

        return [
          ...state,
          {
            label: action.payload.groupKey,
            value: [action.payload.value] as FilterValue[],
            normalizedString: action.payload.labelArray.toString().replace(/,/g, ", "),
          },
        ] as DBFilterContext[];
      }

      return state.map(condition => {
        if (condition.label === (action.payload.groupKey as unknown as string)) {
          console.log("found condition", condition);

          return {
            ...condition,
            value: condition.value.concat([action.payload.value] as FilterValue[]),
            normalizedString: action.payload.labelArray.toString().replace(/,/g, ", "),
          };
        } else {
          return condition;
        }
      });
    }

    // Removing last value clears the filter group
    case FilterActionType.REMOVE: {
      const noFilters = state.some(
        condition =>
          condition.label === (action.payload.groupKey as unknown as string) &&
          condition.value.filter(val => val !== (action.payload.value as FilterValue)).length === 0,
      );

      if (noFilters) {
        // return state.filter(condition => condition.label !== action.payload.groupKey as unknown as string);
        return state.map(condition => {
          if (condition.label === (action.payload.groupKey as unknown as string)) {
            return { ...condition, value: [], normalizedString: "" };
          } else {
            return condition;
          }
        });
      }

      return state.map(condition => {
        if (condition.label === (action.payload.groupKey as unknown as string)) {
          return {
            ...condition,
            value: condition.value.filter(val => val !== (action.payload.value as FilterValue)),
            normalizedString: action.payload.labelArray.toString().replace(/,/g, ", "),
          };
        } else {
          return condition;
        }
      });
    }

    // redux action casts groupKey as undefined if not given
    case FilterActionType.CLEAR: {
      if (action.payload.groupKey !== undefined) {
        return state.map(condition => {
          if (condition.label === (action.payload.groupKey as unknown as string)) {
            return { ...condition, value: [], normalizedString: "" };
          } else {
            return condition;
          }
        });
      }

      return state.map(condition => {
        return { ...condition, value: [], normalizedString: "" };
      });
    }

    // Apply array of strings to the filter group if not already found
    case FilterActionType.DEFAULTS: {
      const group = state.filter(group => group.label === (action.payload.groupKey as unknown as string));

      if (group.length === 0) {
        return [
          ...state,
          {
            label: action.payload.groupKey,
            value: action.payload.value as FilterValue[],
            normalizedString: action.payload.labelArray.toString().replace(/,/g, ", "),
          },
        ] as DBFilterContext[];
      }

      return state;
    }

    case FilterActionType.UPDATE_PM: {
      const group = state.filter(group => group.label === (action.payload.groupKey as unknown as FilterKey));

      // Append a new price match group
      if (group.length === 0) {
        return [
          ...state.map(condition => {
            if (isPMFilter(condition.label)) {
              // Clear non-selected price match groups
              return {
                ...condition,
                value: [],
              };
            }
            return condition;
          }),
          {
            label: action.payload.groupKey as unknown as FilterKey,
            value: action.payload.value as FilterValue[],
            normalizedString: action.payload.labelArray.toString().replace(/,/g, ", "),
          },
        ] as DBFilterContext[];
      }

      // Update existing price match group
      return state.map(condition => {
        if (condition.label === group[0].label) {
          return {
            ...condition,
            label: action.payload.groupKey as unknown as FilterKey,
            value: action.payload.value as FilterValue[],
            normalizedString: action.payload.labelArray.toString().replace(/,/g, ", "),
          };
        } else if (isPMFilter(condition.label)) {
          // Clear non-selected price match groups
          return {
            ...condition,
            value: [],
          };
        }
        return condition;
      });
    }

    // Replace the value and display string
    case FilterActionType.UPDATE_CC: {
      const group = state.filter(group => group.label === (action.payload.groupKey as unknown as FilterKey));

      // Append a new price match group
      if (group.length === 0) {
        return [
          ...state.map(condition => {
            return condition;
          }),
          {
            label: action.payload.groupKey as unknown as FilterKey,
            value: action.payload.value as FilterValue[],
            normalizedString: action.payload.labelArray.toString(),
          },
        ] as DBFilterContext[];
      }

      // Update existing price match group
      return state.map(condition => {
        if (condition.label === group[0].label) {
          return {
            ...condition,
            value: action.payload.value as FilterValue[],
            normalizedString: action.payload.labelArray.toString(),
          };
        }

        return condition;
      });
    }
  }
}

// HoC reducer separate filters into different collections
export function createFilterReducer<T>(collectionName: ComponentKey) {
  return (state = initialState, action: IFilterAction<T>) => {
    const { name } = action;
    const isInitialized = state === undefined;

    if (name !== collectionName && !isInitialized) {
      return state;
    }
    return FilterReducer(state, action);
  };
}
