import React, { Fragment, useEffect, useRef, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { filterHistoryCreate, filterHistoryUpdateFilter } from "redux/actions/filters/filterHistory";

import useOutsideAlerter from "hooks/useOutsideAlerter/useOutsideAlerter";
import { useOrderFilters } from "hooks/useOrderFilters/useDropFilters";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { displayCurrency, isEmpty } from "helpers/Helpers";
import useModal from "hooks/modals/useModal";

import { DropFilterType, PriceMatchState } from "./dropFilterTypes";
import Input from "components/form/input/Input";
import Checkbox from "components/form/checkbox/Checkbox";
import { ButtonNew as Button } from "components/buttonNew/";
import { Select } from "components/select/index";
import DatePickerInput from "components/datePickerInput/DatePickerInput";

import "./dropFilter.scss";

const DropFilter = (props: DropFilterType) => {
  const { t } = useTranslation();

  const { save, historyKey } = props;

  const dispatch = useAppDispatch();
  const filterHistoryStore = useAppSelector(store => store.filterHistoryStore);
  const { state: dropdown, updateModal: updateDropdown, closeModal: closeDropdown } = useModal();

  const ref = useRef<HTMLDivElement>(null);
  useOutsideAlerter(ref, () => {
    closeDropdown();
  });

  const [activeFilterString, setActiveFilterString] = useState("");
  const [hasNotMounted, setHasNotMounted] = useState(true);

  const [checkboxState, setCheckboxState] = useState<boolean[]>([]);
  const [creditCardFilterState, setCreditCardFilterState] = useState<string>("");

  const [date, setDate] = useState<Date>(new Date());
  const [dateString, setDateString] = useState<string>("");

  //contains the most current return data of the component for Checkbox and Date
  const selectedData = props.filterData?.filter((data, index) => {
    if (props.filterType === "Checkbox") {
      return checkboxState[index];
    }
    if (props.filterType === "Date" || props.filterType === "Month") {
      // applying dateString in handleApply function
      const dateDataStruct = {
        ...data,
        [props.filterIdPropFromData]: "",
      };

      return [dateDataStruct];
    }
  });

  //if filter should save, create the storage
  useEffect(() => {
    if (save && filterHistoryStore[historyKey] === undefined) {
      dispatch(filterHistoryCreate(historyKey));
    }
  }, [historyKey]);

  //determine default checkbox values
  useEffect(() => {
    if (props.filterType === "Checkbox" && props.filterData !== null && hasNotMounted) {
      let defaultBoxes: boolean[] = props.filterData.map((data, index) => {
        if (
          props.defaultCheckboxes?.includes(data[props.filterIdPropFromData]) ||
          props.defaultCheckboxes?.includes(data[props.filterLabelPropFromData]) ||
          (save && filterHistoryStore[historyKey] !== undefined && filterHistoryStore[historyKey].currentFilter[index])
        ) {
          return true;
        } else {
          return false;
        }
      });

      //check for restrain and truthy values
      const truthCount = defaultBoxes.filter(Boolean).length;
      if (props.restrain && truthCount > 1) {
        defaultBoxes = defaultBoxes.fill(false);
        console.log(`Check restrain values on ${props.title} dropdown.`); //debug
      }

      //determine wanted data on default
      const presetData = props.filterData.filter((data, index) => defaultBoxes[index]);

      setCheckboxState(defaultBoxes);
      updateFilterString({ selectedCheckboxData: presetData });
      setHasNotMounted(false);
      if (props.updateFilters) {
        void props.updateFilters(presetData);
      }
    } else if (
      props.filterType === "Date" &&
      props.filterData !== null &&
      hasNotMounted &&
      filterHistoryStore[historyKey] !== undefined
    ) {
      const dateString = filterHistoryStore[historyKey].currentFilter as string;

      setDateString(dateString);
      setDate(dateString.length === 0 ? new Date() : new Date(dateString.replace(/-/g, `/`)));

      updateFilterString({ selectedDate: dateString });
      setHasNotMounted(false);
    } else if (
      props.filterType === "Credit Card" &&
      props.filterData !== null &&
      hasNotMounted &&
      !isEmpty(filterHistoryStore[historyKey]?.currentFilter?.[0])
    ) {
      const currentFilter = filterHistoryStore[historyKey].currentFilter[0];
      setCreditCardFilterState(currentFilter);
      updateFilterString({ creditCardFilter: currentFilter });
      setHasNotMounted(false);
    }
  }, [props.filterData]);

  function handleCheckboxChange(index: number) {
    const tempBoxes = [...checkboxState];

    //narrow the typing for typescript
    if (props.filterType === "Checkbox" && props.restrain) {
      tempBoxes.fill(false);
    }
    tempBoxes[index] = !tempBoxes[index];

    setCheckboxState(tempBoxes);
    dispatch(
      filterHistoryUpdateFilter(
        historyKey,
        props.filterData
          .filter((data, index) => tempBoxes[index])
          .map((val: Record<string, any>) => val[props.filterIdPropFromData] as string | number),
      ),
    );

    //allow access for cases with multiple DropFilters on 1 page
    if (props.filterType === "Checkbox" && props.updateFilters) {
      const filterReturn = props.filterData.filter((data, index) => tempBoxes[index]);
      props.updateFilters(filterReturn);
    }
  }

  // handles both filterType 'Date' and 'Month'
  function handleDateChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    dispatch(filterHistoryUpdateFilter(historyKey, value));
    setDateString(value);
  }

  function handlePriceMatchChange(key: "id" | "value", value: string, title?: string) {
    const derivedInput = key !== "id" ? Math.max(0, Number(value)) : value;

    const updatedState = {
      ...props.filterData[0],
      [key]: value !== "" ? derivedInput : "",
      label: title ? title : props.filterData[0].label,
    };

    props.filterType === "Price Match" && props.updateFilters(updatedState);
  }

  function handleSVGClear(event: React.MouseEvent<SVGSVGElement>) {
    event.stopPropagation();

    //handle state reset regardless of the state being used
    unstable_batchedUpdates(() => {
      setDateString("");
      setDate(new Date());
      setCreditCardFilterState("");
      setCheckboxState(new Array(props.filterData.length).fill(false));
      setActiveFilterString("");
      props.filterType === "Price Match" &&
        props.updateFilters({
          ...props.filterData[0],
          id: "total_greater_than_or_equal_to",
          label: t("components.dropdown.drop_filter.001"), // TODO: Translation
          value: "",
        });
      closeDropdown();
    });

    dispatch(filterHistoryUpdateFilter(historyKey, []));

    //make callback
    console.log("--> returning", []); //debug
    void props.applyFilters([]);
  }

  function handleApply() {
    closeDropdown();

    let filterString = updateFilterString();

    if (props.filterType === "Checkbox") {
      filterString = filterString.replace(/,/g, ", "); //for Checkbox cases
    }
    if (props.filterType === "Price Match") {
      filterString = filterString.replace(/,/g, " "); //for Checkbox cases
    }

    // handle date return structure
    const returnValue = selectedData;

    if (props.filterType === "Date" || props.filterType === "Month") {
      returnValue[0] = {
        ...returnValue[0],
        [props.filterIdPropFromData]: dateString.toString(),
      };

      dispatch(filterHistoryUpdateFilter(historyKey, dateString));
    }

    // handle price match return structure
    if (props.filterType === "Price Match") {
      returnValue[0] = props.filterData[0];
    }

    if (props.filterType === "Credit Card") {
      returnValue[0] = {
        [props.filterIdPropFromData]: creditCardFilterState,
      };
      dispatch(filterHistoryUpdateFilter(historyKey, [creditCardFilterState]));
    }

    //no string update == no wanted filter change
    if (filterString !== activeFilterString) {
      console.log("--> returning", returnValue); //debug
      void props.applyFilters(returnValue);
    }
  }

  // display formatted string with checkbox and price match values
  // display the actual date value, not the label
  // return updated filterString to allow for comparison
  const updateFilterString = (optionalFilterData?: {
    selectedCheckboxData?: Record<string, any>[];
    creditCardFilter?: string;
    selectedDate?: string;
  }) => {
    const returnArray: Array<string> = [];

    switch (props.filterType) {
      case "Checkbox": {
        const checkboxData = optionalFilterData?.selectedCheckboxData
          ? optionalFilterData.selectedCheckboxData
          : selectedData;

        checkboxData.forEach((data, index) => {
          returnArray[index] =
            props.subtitle && data.subtitle
              ? String(data[props.filterLabelPropFromData]) + " - " + String(data.subtitle)
              : data[props.filterLabelPropFromData];
        });
        setActiveFilterString(returnArray.toString().replace(/,/g, ", ")); //format
        break;
      }
      case "Date" || "Month": {
        console.log("aaaaa", optionalFilterData, dateString);
        const dateData = optionalFilterData?.selectedDate ? optionalFilterData.selectedDate : dateString;
        returnArray[0] = dateData;

        setActiveFilterString(returnArray.toString());
        break;
      }
      case "Price Match": {
        returnArray.push(props.filterData[0].label, displayCurrency("cad", props.filterData[0].value));
        setActiveFilterString(returnArray.toString().replace(/,/g, " "));
        break;
      }
      case "Credit Card": {
        returnArray.push(
          `••••${optionalFilterData?.creditCardFilter ? optionalFilterData.creditCardFilter : creditCardFilterState}`,
        );
        setActiveFilterString(returnArray.toString());
      }
    }

    return returnArray.toString();
  };

  const handleDatePickerChange = (selectedDate: Date) => {
    const dateArray = moment(selectedDate, "YYYY-MM-DD").format().split("T");
    const filterDate = dateArray[0];

    setDateString(filterDate);
    setDate(selectedDate);
  };

  function handleCreditCardFilterChange(e: any) {
    setCreditCardFilterState(e.target.value);
  }

  function validateCreditCardFilter(creditCardFilter: string) {
    if (creditCardFilter?.length !== 4) {
      return false;
    } else {
      const chars = creditCardFilter.split("");
      const allCharsAreDigits = chars.every(char => /^\d$/.test(char));

      return allCharsAreDigits;
    }
  }

  return (
    <div ref={ref} className="filter-drop">
      <div
        onClick={props.disableDropdown ? null : () => updateDropdown({ isOpen: !dropdown.isOpen })}
        className={`filter-drop-button${props.disableDropdown ? " btn-disabled" : ""}`}
        style={{
          height: props.dropdownSize ? props.dropdownSize : null,
          backgroundColor: props.darkMode ? "#d5dbe1" : null, //dropFilter.scss hover color
        }}
      >
        {activeFilterString.length !== 0 ? (
          <>
            <span className="filter-drop-label">
              {`${props.title} | `}
              <span className="filter-drop-label-filter">{activeFilterString} </span>
            </span>
            <span className="filter-drop-icon">
              <FontAwesomeIcon
                className="filter-drop-icon-nav"
                style={{ fontSize: "12px" }}
                icon={["fas", "circle-xmark"]}
                onClick={props.disableDropdown ? null : event => handleSVGClear(event)}
              />
            </span>
          </>
        ) : (
          <>
            <span className="filter-drop-label">{props.title}</span>
            <span className="filter-drop-icon">
              <FontAwesomeIcon
                className="filter-drop-icon-nav"
                style={{ fontSize: "12px" }}
                icon={["fas", "circle-plus"]}
              />
            </span>
          </>
        )}
      </div>

      <>
        <div
          className={classNames("filter-drop-content-container", {
            "filter-drop-content-container-left-aligned": props.leftAligned,
            "filter-drop-content-container-right-aligned": props.rightAligned,
          })}
          style={{ display: dropdown.isOpen ? "block" : "none" }}
        >
          <div
            className={`
            drop-triangle 
            ${props.rightAligned ? "triangle-right-aligned" : null}
            ${props.leftAligned ? "triangle-left-aligned" : null}
          `}
          >
            <div className="drop-triangle-arrow">
              <div className="drop-triangle-arrow-inner" />
            </div>
          </div>

          <div className="filter-drop-buttongroup">
            <p className="text-md text-semibold">
              {t("components.dropdown.drop_filter.002")} {props.title}
            </p>
          </div>
          <div className="filter-drop-content-display">
            {props.filterType === "Checkbox" && props.filterData && (
              <CheckboxSelect
                data={props.filterData}
                dataLabel={props.filterLabelPropFromData}
                dataValue={props.filterIdPropFromData}
                handleCheckbox={handleCheckboxChange}
                checkboxValues={checkboxState}
                subtitle={props.subtitle}
              />
            )}

            {props.filterType === "Date" && (
              <DatePickerInput
                months={1}
                position="left"
                startingDate={date}
                setStartingDate={handleDatePickerChange}
              />
            )}

            {props.filterType === "Month" && (
              <DateSelect dateStates={[dateString]} handleDateChange={handleDateChange} type="month" />
            )}

            {props.filterType === "Price Match" && (
              <PriceMatchSelect
                priceMatchState={props.filterData[0] as PriceMatchState}
                handleInputChange={(key, value, label) => handlePriceMatchChange(key, value, label)}
              />
            )}

            {props.filterType === "Credit Card" && (
              <Input
                labelHidden
                placeholder={t("components.dropdown.drop_filter.004")}
                value={creditCardFilterState}
                onChange={handleCreditCardFilterChange}
              />
            )}
          </div>
          <div className="filter-drop-footer">
            <Button
              onClick={handleApply}
              disabled={
                (props.filterType === "Price Match" &&
                  (isEmpty(props.filterData[0].id) ||
                    isEmpty(props.filterData[0].value) || // ensure both inputs chosen before applying.
                    (props.filterData[0].id.includes("less") && props.filterData[0].value === 0))) || // Can't search for less than 0.
                (props.filterType === "Credit Card" && !validateCreditCardFilter(creditCardFilterState))
              }
              type="primary"
              size="small"
              block
            >
              {t("components.dropdown.drop_filter.003")}
            </Button>
          </div>
        </div>
      </>
    </div>
  );
};

const CheckboxSelect = (props: {
  data: Record<string, any>[];
  dataLabel: string;
  dataValue: string;
  subtitle?: boolean;
  handleCheckbox: (index: number) => void;
  checkboxValues: Array<boolean>;
}) => {
  return (
    <>
      {props.data.map((option, index) => {
        return (
          props.checkboxValues.length > 0 && (
            <Checkbox
              key={index}
              fullWidth
              size="medium"
              label={option[props.dataLabel]}
              value={option[props.dataValue]}
              onChange={() => props.handleCheckbox(index)}
              id={option[props.dataValue]}
              name={option[props.dataValue]}
              checked={props.checkboxValues[index]}
              subtitle={props.subtitle ? option.subtitle : ""}
            />
          )
        );
      })}
    </>
  );
};

const DateSelect = (props: {
  dateStates: string[];
  handleDateChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type: "date" | "month";
}) => {
  return (
    <>
      {props.dateStates.map((date, index) => {
        return <Input key={index} type={props.type} onChange={props.handleDateChange} labelHidden value={date} />;
      })}
    </>
  );
};

const PriceMatchSelect = (props: {
  handleInputChange: (key: "id" | "value", value: string, label?: string) => void;
  priceMatchState: PriceMatchState;
}) => {
  const { Option } = Select;
  const priceMatchFilters = useOrderFilters().priceMatchFilters;

  return (
    <>
      <Select
        defaultValue={props.priceMatchState.id}
        onChange={(descriptor: string, label: string) => props.handleInputChange("id", descriptor, label)}
      >
        {priceMatchFilters.map((filter, index: number) => {
          return (
            <Fragment key={filter.id}>
              <Option value={filter.id} extraValues={filter.label}>
                {filter.label}
              </Option>
            </Fragment>
          );
        })}
      </Select>

      <Input
        type="number"
        value={props.priceMatchState.value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.handleInputChange("value", event.target.value)}
      />
    </>
  );
};

export default DropFilter;
