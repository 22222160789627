import React, { useState, useRef, useEffect } from "react";
import axios, { CancelToken } from "axios";

import { GetCustomer } from "api/rpc/2024-04/facilityAdmin/customer/customer";
import { StatusCode } from "api/protocols";

import Sheet from "components/sheet/Sheet";
import Callout from "components/callout/Callout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select } from "components/select/index";
import { ICustomer } from "redux/reducers/models/customer";
import GolferCard from "components/bookingPopUp/golferCard/GolferCard";

import "components/fileImport/fileImport.scss";

interface IFileSelectProps {
  open: boolean;
  onOk: (selectedFile: File, customer_id: number) => void;
  onCancel: () => void;
}

export default function ImportDrawSheetModal(props: IFileSelectProps) {
  const fileUploadRef = useRef(null);

  const [selectedFile, setSelectedFile] = useState<File>(null);
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomer>(null);
  const [customers, setCustomers] = useState<ICustomer[]>(undefined);
  const [customerQuery, setCustomerQuery] = useState<string>("");

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadCustomers(customerQuery, source.token);
    return () => source.cancel();
  }, [customerQuery]);

  async function loadCustomers(customerSearchQuery: string, cancelToken: CancelToken) {
    setCustomers(undefined);

    const customerRes = await GetCustomer({ search: customerSearchQuery }, false, cancelToken);

    if (customerRes.status !== StatusCode.OK) {
      return;
    }

    setCustomers(customerRes.data);
  }

  function handleFileSelect(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files;
    if (file && file.length > 0) {
      setSelectedFile(file[0]);
    }
  }

  function onOk() {
    props.onOk(selectedFile, selectedCustomer?.id);

    fileUploadRef.current.value = "";
    setSelectedFile(null);
  }

  return (
    <Sheet
      title="Import Draw Sheet" // TODO: Translation
      open={props.open}
      size="small"
      closable
      onCancel={props.onCancel}
      onOk={onOk}
      okText="Import" // TODO: Translation
      okDisabled={!selectedFile ? true : false}
      overflow
    >
      <Callout
        type="info"
        title={!selectedFile ? "No file selected" : "Selected File"} // TODO: Translation
        content={!selectedFile ? "Select a file to continue" : selectedFile.name} // TODO: Translation
      />

      <div className="file-import-container mt-2 mb-2">
        <input
          ref={fileUploadRef}
          className="file-import-input"
          type="file"
          accept=".csv"
          onChange={handleFileSelect}
        />
        <FontAwesomeIcon className="file-import-icon" icon={["far", "upload"]} />
      </div>

      {selectedCustomer ? (
        <GolferCard
          closable
          removeGolfer={(e: any) => setSelectedCustomer(null)}
          email={selectedCustomer?.email}
          name={selectedCustomer?.full_name}
          memberCode={selectedCustomer?.member_code}
          customerType={selectedCustomer?.customer_type}
          phone={selectedCustomer?.phone}
        />
      ) : (
        <Select
          label="Customer"
          onChange={(value: string, extraValues: ICustomer) => setSelectedCustomer(extraValues)}
          showSearch
          searchValue={customerQuery}
          onSearch={(search: string) => setCustomerQuery(search)}
          searching={customers === undefined}
          placeholder="Search customers..." // TODO: Translation
        >
          {customers?.map((customer, index) => (
            <Select.Option value={customer.id} key={customer.id} extraValues={customer}>
              <div className="flex justify-between">
                <div>
                  <div className="font-semibold text-lg">{customer?.full_name}</div>
                  <div className="text-sm text-gray-500">{customer.customer_type}</div>
                  <div className="text-sm text-gray-500">{customer.email}</div>
                  <div className="text-sm text-gray-500">{customer.phone ? customer.phone : null}</div>
                </div>

                <div className="font-medium text-base text-gray-500 self-end">{customer.member_code}</div>
              </div>
            </Select.Option>
          ))}
        </Select>
      )}
    </Sheet>
  );
}
