import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import { StatusCode } from "api/protocols";
import { GetTournament, PutTournament } from "api/rpc/2022-09/clientAdmin/tournament/tournament";

import { ITournament } from "redux/reducers/models/tournament";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import TextField from "components/form/textField/TextField";
import { ButtonNew as Button } from "components/buttonNew";
import { IUIActions, showError, showSuccess } from "redux/actions/ui";
import { useDispatch } from "react-redux";

interface ITournamentProps {
  uiActions: IUIActions;
}

interface ITournamentState {
  tournament: ITournament;
  name: string;
  start_time: string;
  date: string;
  tee_sheet_note: string;
}

export default function Tournament(props: ITournamentProps) {
  const { tournamentId }: any = useParams();
  const [tournament, setTournament] = useState<ITournamentState>({
    tournament: null,
    name: "",
    start_time: "",
    date: "",
    tee_sheet_note: "",
  });
  const { uiActions } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    void loadTournament();
  }, [tournamentId]);

  async function loadTournament() {
    const params = {
      id: tournamentId,
      extended: true,
    };

    const tournamentRes = await GetTournament(params, true);

    console.log(tournamentRes); //debug

    const tournament = tournamentRes?.data[0];

    if (tournamentRes.status === StatusCode.OK && tournament) {
      setTournament(prevState => ({
        ...prevState,
        tournament: tournament,
        name: tournament?.name,
        start_time: tournament?.start_time,
        date: tournament?.date,
        tee_sheet_note: tournament?.tee_sheet_note,
      }));
    }
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    console.log(id, value);
    setTournament(prevState => ({ ...prevState, [id]: value }));
  }

  function handleNoteInputChange(event: any) {
    const { id, value } = event.target;
    setTournament(prev => ({
      ...prev,
      [id]: value,
    }));
  }

  async function handleSaveNote() {
    const params = {
      id: tournamentId,
      tee_sheet_note: tournament.tee_sheet_note,
    };

    const tournamentRes = await PutTournament(params, true);

    if (tournamentRes.status !== StatusCode.OK) {
      dispatch(showError("Error adding note to tournament"));
      return;
    }
    dispatch(showSuccess("Tournament note saved successfully"));
  }

  return (
    <Page title={tournament?.name} narrow>
      <Form>
        <Card title="Tournament Details">
          {" "}
          {/* // TODO: Translation */}
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={tournament.name ?? ""}
                  label="Name" // TODO: Translation
                  id="name"
                  onChange={handleInputChange}
                  placeholder="Name"
                  disabled // This Component currently does not have a POST button
                />
              </FormLayout.Group>
            </FormLayout>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={tournament.start_time ?? ""}
                  label="Time" // TODO: Translation
                  id="start_time"
                  onChange={handleInputChange}
                  placeholder="Time"
                  disabled // This Component currently does not have a POST button
                />
                <Input
                  value={tournament.date ?? ""}
                  label="Date"
                  id="date"
                  onChange={handleInputChange}
                  placeholder="Date"
                  disabled // This Component currently does not have a POST button
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>

        <Card title={"Tournament Notes"}>
          <Card.Section>
            <TextField
              value={tournament.tee_sheet_note}
              label={"Tournament Note"}
              id="tee_sheet_note"
              onChange={handleNoteInputChange}
              maxLength={150}
              showCharacterCount
            />

            <div className="flex justify-end mt-3" style={{ marginRight: "33px" }}>
              <Button type="primary" onClick={handleSaveNote}>
                Save Note
              </Button>
            </div>
          </Card.Section>
        </Card>
      </Form>
    </Page>
  );
}
