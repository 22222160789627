import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { GetTournament } from "api/rpc/2024-04/facilityAdmin/tournament/tournament";

import { showError, showSuccess } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import Card from "components/card/Card";
import { Select } from "components/select/index";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import { GetTeeDecks } from "api/rpc/2024-04/facilityAdmin/facility/teedeck";
import axios, { CancelToken } from "axios";
import { ITeeDeck } from "redux/reducers/models/facility";
import { ITournament } from "redux/reducers/models/tournament";
import {
  GetTournamentScoreSettings,
  PutTournamentScoreSettings,
  TPutTournamentScoreSettings,
} from "api/rpc/2024-04/facilityAdmin/tournament/scoring/scoreSettings";
import {
  TEventCompetition,
  TEventCompetitionStrokePlay,
  TFoursomeCompetition,
  TPairCompetition,
  TPlayerCompetition,
  TTeamCompetition,
} from "redux/reducers/models/league";
import {
  competitionOptions,
  competitionOptionsStrokePlay,
  formatOptions,
  handicapFormatOptions,
  holesOptions,
} from "./options";
import { handleChangeEventInput } from "helpers/Helpers";

interface ITournamentSettings {
  format: string;
  competition: string;
  holes: number;
  tee_deck_id: number;
  handicap_format: string;
  handicap_allowance: number;
  best_of_order_of_merit: number;
  default_participation_points: number;
  point_allocation: any;
  tournament_id: number;
}

type TTournamentSettingsCompetition = TPlayerCompetition | TTeamCompetition | TPairCompetition | TFoursomeCompetition;

export default function TournamentScoreSettings() {
  const { t, i18n } = useTranslation();
  const { tournamentId } = useParams<{ tournamentId: string }>();

  const dispatch = useAppDispatch();

  const [attempted, setAttempted] = useState(false);
  const [scoreSettings, setScoreSettings] = useState<TPutTournamentScoreSettings>({
    tournament_id: tournamentId,
    format: "",
    competition: "none" as TTournamentSettingsCompetition,
    holes: 9,
    tee_deck_id: 0,
    handicap_format: undefined,
    handicap_allowance: 0,
  });

  const [teeDecks, setTeeDecks] = useState<ITeeDeck[]>([]);
  const [tournamentName, setTournamentName] = useState<string>("");

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadTeeDecks(source.token);
    return () => source.cancel();
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadTournament(Number(tournamentId), source.token);
    void loadTournamentScoringSettings(Number(tournamentId), source.token);
    return () => source.cancel();
  }, [tournamentId]);

  async function loadTournamentScoringSettings(tournament_id: number, token?: CancelToken) {
    const res = await GetTournamentScoreSettings({ tournament_id }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      return;
    }

    setScoreSettings({
      tournament_id: tournamentId,
      format: res.data.format,
      competition: res.data.competition ?? ("none" as TTournamentSettingsCompetition),
      holes: res.data.holes,
      tee_deck_id: res.data.tee_deck_id,
      handicap_format: res.data.handicap_format,
      handicap_allowance: res.data.handicap_allowance ?? 0,
    });
  }

  async function loadTournament(id: number, token?: CancelToken) {
    const res = await GetTournament({ id }, true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      return;
    }

    setTournamentName(res.data[0].name);
  }

  async function loadTeeDecks(token?: CancelToken) {
    const res = await GetTeeDecks(null, true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      return;
    }

    setTeeDecks(res.data);
  }

  async function updateScoringSettings() {
    // Input validation catch before API call
    if (!scoreSettings.format || scoreSettings.competition === ("none" as TTournamentSettingsCompetition)) {
      setAttempted(true);
      return;
    }

    // Set the handicap allowance to decimal percent for backend
    const params: TPutTournamentScoreSettings = {
      ...scoreSettings,
      handicap_allowance: scoreSettings.handicap_allowance / 100,
      competition:
        scoreSettings.competition === ("none" as TTournamentSettingsCompetition) ? null : scoreSettings.competition,
    };

    const scoreSettingsRes = await PutTournamentScoreSettings(params, true);

    if (scoreSettingsRes.status !== StatusCode.OK) {
      dispatch(showError("Error updating settings."));
      setAttempted(true);
      return;
    }

    dispatch(showSuccess("Settings updated successfully"));
    setAttempted(false);
  }

  const primaryAction = {
    content: "Update Settings", // TODO: Translation
    action: updateScoringSettings,
  };

  return (
    <Page title={tournamentName} primaryAction={primaryAction} narrow>
      {tournamentName && (
        <Card title={"Options"}>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Select
                  label={"Format"} // TODO: Translation
                  onChange={(value: string) =>
                    setScoreSettings(prev => ({
                      ...prev,
                      format: value,
                      competition: "none" as TTournamentSettingsCompetition,
                    }))
                  }
                  defaultValue={scoreSettings?.format}
                  error={attempted && !scoreSettings.format}
                >
                  {formatOptions(t).map((option, index: number) => {
                    return (
                      <Select.Option key={index} value={option.id} name={option.title}>
                        {option.title}
                      </Select.Option>
                    );
                  })}
                </Select>

                <Select
                  label={"Competition"} // TODO: Translation
                  onChange={(value: TTournamentSettingsCompetition) =>
                    setScoreSettings(prev => ({ ...prev, competition: value }))
                  }
                  defaultValue={scoreSettings?.competition}
                  error={attempted && scoreSettings.competition === ("none" as TTournamentSettingsCompetition)}
                >
                  <Select.Option value={"none"}>{"None Selected"}</Select.Option>
                  {competitionOptions(t)
                    .filter(val => (scoreSettings.format === "stroke_play" ? !val.id.includes("team") : val))
                    .map((option, index: number) => {
                      return (
                        <Select.Option key={index} value={option.id} name={option.title}>
                          {option.title}
                        </Select.Option>
                      );
                    })}
                </Select>

                <Select
                  label={"Holes"} // TODO: Translation
                  onChange={(value: 9 | 18) => setScoreSettings(prev => ({ ...prev, holes: value }))}
                  defaultValue={scoreSettings.holes}
                >
                  {holesOptions(t).map((option, index: number) => {
                    return (
                      <Select.Option key={index} value={option.id} name={option.title}>
                        {option.title}
                      </Select.Option>
                    );
                  })}
                </Select>
              </FormLayout.Group>
            </FormLayout>
            <FormLayout>
              <FormLayout.Group>
                <Select
                  label={"Handicap Format"} // TODO: Translation
                  onChange={(value: "gross" | "net") =>
                    setScoreSettings(prev => ({ ...prev, handicap_format: value }))
                  }
                  defaultValue={scoreSettings.handicap_format}
                  error={attempted && !scoreSettings.handicap_format}
                >
                  {handicapFormatOptions(t).map((option, index: number) => {
                    return (
                      <Select.Option key={index} value={option.id} name={option.title}>
                        {option.title}
                      </Select.Option>
                    );
                  })}
                </Select>

                <Input
                  value={scoreSettings.handicap_allowance ?? "0"}
                  label={"Handicap Allowance"} // TODO: Translation
                  id="handicap_allowance"
                  onChange={e => handleChangeEventInput(e, scoreSettings, setScoreSettings)}
                  type="number"
                  min={0}
                  max={100}
                  suffix="%"
                />
              </FormLayout.Group>
            </FormLayout>
            <FormLayout>
              <FormLayout.Group>
                <Select
                  label={"Tee Deck"} // TODO: Translation
                  onChange={(value: number) => setScoreSettings(prev => ({ ...prev, tee_deck_id: value }))}
                  defaultValue={scoreSettings.tee_deck_id}
                  error={attempted && !scoreSettings.tee_deck_id}
                >
                  {teeDecks.map(teeDeck => {
                    return (
                      <Select.Option key={teeDeck.id} value={teeDeck.id} name={teeDeck.title}>
                        {teeDeck.title}
                      </Select.Option>
                    );
                  })}
                </Select>
              </FormLayout.Group>
            </FormLayout>
            <FormLayout>
              <FormLayout.Group>{/* <label htmlFor="">Point Allocation</label> */}</FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>
      )}
    </Page>
  );
}
