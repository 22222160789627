import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { ICountry, IProvince } from "redux/reducers/models/customer";
import { CancelToken } from "axios";

const apiVersion = "2022-09";
const adminType = "common";
const baseUrl = "/" + apiVersion + "/" + adminType + "/country";

interface IGetCountry {
  id?: number;
  search?: string;
  provinces?: boolean;
}

interface IGetProvince {
  id?: number;
  country_id?: number;
  search?: string;
}

// GET Country
export async function GetCountry(params: IGetCountry, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<ICountry[]>(baseUrl, params, useGlobalLoader, token);
}

// GET Provinces
export async function GetProvince(params: IGetProvince, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<IProvince[]>(baseUrl + "/province", params, useGlobalLoader, token);
}
