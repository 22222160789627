import ClientCashOutEdit from "pages/secure/client/settings/cashouts/CashOutEdit";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UIActions from "redux/actions/ui";
import * as ClientActions from "redux/actions/client";

const mapDispatchToProps = (dispatch: any) => {
  return {
    uiActions: bindActionCreators(
      {
        ...UIActions,
      },
      dispatch,
    ),
    clientActions: bindActionCreators(
      {
        ...ClientActions,
      },
      dispatch,
    ),
  };
};

const mapStateToProps = (state: any) => {
  return {
    permissions: { ...state.authStore.user.permissions },

    clientFacilityStore: { ...state.clientFacilityStore },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientCashOutEdit);
