import React, { useEffect, useState } from "react";
import Page from "components/page/Page";
import DataTable from "../customer/tabs/houseAccounts/DataTable";
import Portal from "elements/Portal";
import Sheet from "components/sheet/Sheet";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input";
import { useHistory } from "react-router";
import Card from "components/card/Card";
import { TCustomerTag } from "redux/reducers/models/customer";
import { GetTag } from "api/rpc/2024-04/facilityAdmin/client/tags";
import { StatusCode } from "api/protocols";
import { useAppDispatch } from "hooks/redux";
import { dequeue, enqueue, showError, showSuccess } from "redux/actions/ui";
import { ButtonNew as Button } from "components/buttonNew";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Checkbox from "components/form/checkbox/Checkbox";
import axios, { CancelToken } from "axios";
import { PostCustomerSegment, PutCustomerSegmentTags } from "api/rpc/2024-04/facilityAdmin/customer/segment";

interface IState {
  title: string;
  description: string;
  tags: Array<TCustomerTag>;
  tagsEdit: Array<TCustomerTag>;
  selectedTags: Array<TCustomerTag>;
  tagsModalOpen: boolean;
  tagSearch: string;
  showError: boolean;
}

export default function CustomerSegments() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [state, setState] = useState<IState>({
    title: "",
    description: "",
    tags: undefined,
    tagsEdit: [],
    selectedTags: [],
    tagsModalOpen: false,
    tagSearch: "",
    showError: false,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadCustomerTags(source.token);
    return () => {
      source.cancel();
    };
  }, []);

  async function loadCustomerTags(cancelToken: CancelToken) {
    const res = await GetTag({ type: "customer" }, false, cancelToken);
    if (res.status !== StatusCode.OK) {
      if (cancelToken && cancelToken.reason) {
        return;
      }
      setState(prevState => ({ ...prevState, tags: [] }));
      dispatch(showError("Error getting tags"));
      return;
    }
    setState(prevState => ({ ...prevState, tags: [...res.data] }));
  }

  function handleSelectTag(tag: TCustomerTag) {
    let editTags = [...state.tagsEdit];
    const tagIndex = editTags?.findIndex(foundTag => foundTag.id === tag.id);
    if (tagIndex !== -1) {
      editTags = editTags?.filter(filteredTag => filteredTag.id !== tag.id);
    } else {
      editTags = [...editTags, tag];
    }
    setState(prevState => ({ ...prevState, tagsEdit: editTags, tagSearch: "" }));
  }

  function handleAddTags() {
    const updatedTags = [...state.selectedTags, ...state.tagsEdit];
    setState(prevState => ({ ...prevState, selectedTags: updatedTags, tagsEdit: [], tagsModalOpen: false }));
  }

  async function saveCustomerSegment() {
    dispatch(enqueue());
    if (!state?.title || !state?.description) {
      setState(prevState => ({ ...prevState, showError: true }));
      dispatch(dequeue());
      dispatch(showError("Title and description fields required"));
      return;
    }
    const postRes = await PostCustomerSegment({ title: state.title, description: state.description }, false);
    if (postRes.status !== StatusCode.OK) {
      dispatch(dequeue());
      dispatch(showError("Error creating customer segment"));
      return;
    }

    if (state.selectedTags?.length > 0 && postRes?.data?.id) {
      const tagIds = state?.selectedTags?.map(tag => tag?.id);
      if (tagIds?.length > 0) {
        const tagsRes = await PutCustomerSegmentTags({ segment_id: postRes?.data?.id, tag_ids: tagIds }, false);
        if (tagsRes?.status !== StatusCode.OK) {
          dispatch(showError("Error adding customer tags"));
        }
      }
    }
    dispatch(dequeue());
    dispatch(showSuccess("Successfully created customer segment"));
    history.push("/admin/marketing");
  }

  return (
    <Page
      narrow
      title="New Customer Segment"
      primaryAction={{
        content: "Save",
        action: () => saveCustomerSegment(),
      }}
      breadcrumbs={[{ prefix: true, label: "Back to Customer Segments", url: "/admin/marketing" }]}
    >
      <Card>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Input
                label={"Title"}
                placeholder="Title"
                value={state.title}
                onChange={e => setState(prevState => ({ ...prevState, title: e.target.value }))}
                error={!state?.title && state.showError}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <Input
                label={"Description"}
                placeholder="Description"
                value={state.description}
                onChange={e => setState(prevState => ({ ...prevState, description: e.target.value }))}
                error={!state?.description && state.showError}
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
        <Card.Section title="Tags">
          <Button
            className="ml-auto mb-2"
            size="xsmall"
            type="secondary"
            onClick={() => setState(prevState => ({ ...prevState, tagsModalOpen: true }))}
            icon={<FontAwesomeIcon icon={["far", "plus"]} />}
          >
            Add Tags
          </Button>
          <DataTable
            columns={[{ label: "Label" }, { label: "Description" }, { label: "" }, { label: "", justify: "right" }]}
          >
            {state.selectedTags?.map((tag, index) => {
              return (
                <tr key={index}>
                  <td>{tag.label}</td>
                  <td>{tag?.description}</td>
                  <td>
                    <FontAwesomeIcon icon={["fas", "circle"]} size="1x" color={tag.colour} />
                  </td>
                  <td>
                    {
                      <FontAwesomeIcon
                        onClick={() =>
                          setState(prevState => ({
                            ...prevState,
                            selectedTags: prevState.selectedTags?.filter(filteredTag => filteredTag.id !== tag.id),
                          }))
                        }
                        className="cursor-pointer float-right"
                        icon={["far", "trash-can"]}
                      />
                    }
                  </td>
                </tr>
              );
            })}
          </DataTable>
        </Card.Section>
      </Card>

      <Portal isMounted={state.tagsModalOpen}>
        <Sheet
          open={state.tagsModalOpen}
          onCancel={() =>
            setState(prevState => ({ ...prevState, tagsModalOpen: false, tagsEdit: [], tagSearch: "" }))
          }
          onOk={handleAddTags}
          okText="Select"
          size="small"
          closable
          title="Add Tags"
          okDisabled={state.tagsEdit?.length === 0}
        >
          <Input
            onSearchClear={() => setState(prevState => ({ ...prevState, tagSearch: "" }))}
            onChange={e => setState(prevState => ({ ...prevState, tagSearch: e.target.value }))}
            placeholder="Search tags..."
            autoFocus
            value={state.tagSearch}
          />
          <br />
          <DataTable
            columns={[{ label: "" }, { label: "Label" }, { label: "Description" }, { label: "" }]}
            loading={!state.tags}
          >
            {state?.tags &&
              state.tags
                ?.filter(
                  filteredTag =>
                    !state.selectedTags?.some(foundTag => foundTag.id === filteredTag.id) &&
                    filteredTag?.label?.toLowerCase()?.includes(state.tagSearch?.toLowerCase()),
                )
                ?.map(tag => {
                  return (
                    <tr key={tag?.id} className="clickable" onClick={e => handleSelectTag(tag)}>
                      <td onClick={e => e.stopPropagation()}>
                        <Checkbox
                          size="small"
                          onChange={e => handleSelectTag(tag)}
                          checked={state.tagsEdit.some(editTag => editTag.id === tag.id)}
                        />
                      </td>
                      <td>{tag.label}</td>
                      <td>{tag?.description}</td>
                      <td>
                        <FontAwesomeIcon icon={["fas", "circle"]} size="1x" color={tag.colour} />
                      </td>
                    </tr>
                  );
                })}
          </DataTable>
        </Sheet>
      </Portal>
    </Page>
  );
}
