import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useParams } from "react-router";
import useModal from "hooks/modals/useModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import { PostFacility } from "api/rpc/2024-04/masterAdmin/facility/facility";
import { GetCountry, GetProvince } from "api/rpc/2022-09/common/country/country";
import { ICourse, IFacility } from "redux/reducers/models/facility";
import { ICountry, IProvince } from "redux/reducers/models/customer";

import { StatusCode } from "api/protocols";

import { valueToString } from "helpers/Helpers";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Input from "components/form/input/Input";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import { Select } from "components/select/";
import { showError, showSuccess } from "redux/actions/ui";
import Toggle from "components/form/toggle/Toggle";
import Divider from "components/divider";
import { ButtonNew as Button } from "components/buttonNew";
import Portal from "elements/Portal";
import Popup from "components/popup/Popup";
import { IClient } from "redux/reducers/models/client";
import Icon from "components/icon/Icon";
import TimePick from "components/timePick/TimePick";

interface IDivisionState {
  title: string;
  holes: number;
}

interface ICourseState {
  long_name: string;
  full_name: string;
  holes: number;
  divisions: IDivisionState[];
}

interface ICreateFacilityState extends IFacility {
  online_store: boolean;
  closed: boolean;
  create_client: boolean;
  create_templates: boolean;
  start_time: string;
  end_time: string;
}

interface IFilterState {
  clientQuery: string;
  clientSearching: boolean;
  selectedClient: IClient;
  clients: IClient[];
  customizeCourses: boolean;
  timezoneQuery: string;
  selectedTimezone: string;
}

export default function FacilityNew(props: any) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { facilityId, clientId }: any = useParams();
  const { Option } = Select;
  const { masterClientStore } = useAppSelector(store => store);
  const timeZones = moment.tz.names();

  const [facilityState, setFacilityState] = useState<ICreateFacilityState>(undefined);

  const default9HoleCourseState = [
    {
      long_name: "Course 1",
      full_name: "Course 1",
      holes: 9,
      divisions: [
        {
          title: "Front",
          holes: 9,
        },
      ],
    },
  ];

  const default18HoleCourseState = [
    {
      long_name: "Course 1",
      full_name: "Course 1",
      holes: 18,
      divisions: [
        {
          title: "Front",
          holes: 9,
        },
        {
          title: "Back",
          holes: 9,
        },
      ],
    },
  ];

  const default27HoleCourseState = [
    {
      long_name: "Course 1",
      full_name: "Course 1",
      holes: 27,
      divisions: [
        {
          title: "Division 1",
          holes: 9,
        },
        {
          title: "Division 2",
          holes: 9,
        },
        {
          title: "Division 3",
          holes: 9,
        },
      ],
    },
  ];

  const default36HoleCourseState = [
    {
      long_name: "Course 1",
      full_name: "Course 1",
      holes: 18,
      divisions: [
        {
          title: "Front",
          holes: 9,
        },
        {
          title: "Back",
          holes: 9,
        },
      ],
    },
    {
      long_name: "Course 2",
      full_name: "Course 2",
      holes: 18,
      divisions: [
        {
          title: "Front",
          holes: 9,
        },
        {
          title: "Back",
          holes: 9,
        },
      ],
    },
  ];

  const [courseState, setCourseState] = useState<ICourseState[]>([
    {
      long_name: "Course 1",
      full_name: "Course 1",
      holes: 18,
      divisions: [
        {
          title: "Front",
          holes: 9,
        },
        {
          title: "Back",
          holes: 9,
        },
      ],
    },
  ]);

  const {
    state: warningsPopup,
    updateModal: updateWarningsPopup,
    closeModal: closeWarningPopup,
  } = useModal({
    warnings: [] as string[],
  });

  const [countryState, setCountryState] = useState<Array<ICountry>>([]);

  const [filterState, setFilterState] = useState<IFilterState>({
    clientQuery: "",
    clientSearching: false,
    selectedClient: null,
    clients: [],
    customizeCourses: false,
    timezoneQuery: "",
    selectedTimezone: null,
  });

  useEffect(() => {
    void loadCountries();
  }, []);

  useEffect(() => {
    const filteredClients = masterClientStore?.clients?.filter((client: IClient) =>
      client.long_name?.toLocaleLowerCase().includes(filterState.clientQuery.toLocaleLowerCase()),
    );

    setFilterState(prevState => ({ ...prevState, clients: filteredClients }));
  }, [filterState.clientQuery]);

  useEffect(() => {
    handleFacilityHolesChange();
  }, [facilityState?.holes]);

  async function loadCountries() {
    const countryRes = await GetCountry({ provinces: true }, true);
    if (countryRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading countries"));
      return;
    }

    setCountryState(countryRes.data);
  }

  async function saveFacility() {
    const updatedCourseState = [...courseState];

    if (!filterState.customizeCourses && facilityState.holes === 18) {
      updatedCourseState[0].long_name = facilityState.long_name;
      updatedCourseState[0].full_name = facilityState.full_name;
    }

    const facilityRes = await PostFacility(
      {
        ...facilityState,
        courses: updatedCourseState,
        client_id: Number(masterClientStore.client?.id),
      },
      true,
    );

    if (facilityRes.status !== StatusCode.OK) {
      dispatch(showError("Error creating facility"));
      return;
    }

    setFacilityState(facilityRes.data);
    dispatch(showSuccess("Facility created successfully"));
    history.push("/admin/facility");
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setFacilityState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleCourseInputChange(courseIndex: number, event: any) {
    const updatedCourses = [...courseState];
    const { id, value } = event.target;

    updatedCourses[courseIndex] = { ...updatedCourses[courseIndex], [id]: value };

    setCourseState(updatedCourses);
  }

  function handleDivisionInputChange(courseIndex: number, divisionIndex: number, event: any) {
    const updatedCourses = [...courseState];
    const { id, value } = event.target;

    updatedCourses[courseIndex].divisions[divisionIndex] = {
      ...updatedCourses[courseIndex].divisions[divisionIndex],
      [id]: value,
    };

    setCourseState(updatedCourses);
  }

  function handleToggleChange(event: any) {
    const { id, checked } = event.target;
    setFacilityState(prevState => ({ ...prevState, [id]: checked }));
  }

  function handleCountryDropDownChange(value: any) {
    const country = countryState?.find(country => country.id === value);
    setFacilityState(prev => ({
      ...prev,
      country_id: value,
      country_code: country.code,
      country_name: country.name,
    }));
  }

  function handleProvinceDropDownChange(value: any) {
    const province = countryState
      ?.find(country => country.id === facilityState.country_id)
      ?.provinces?.find(province => province.id === value);
    if (!province) {
      dispatch(showError("Error changing province"));
      return;
    }
    setFacilityState(prev => ({
      ...prev,
      province_id: value,
      province_code: province.code,
      province_name: province.name,
    }));
  }

  function addCourse() {
    const courses = [...courseState];

    courses.push({
      long_name: `Course ${courses.length + 1}`,
      full_name: `Course ${courses.length + 1}`,
      holes: 18,
      divisions: [
        {
          title: "Front",
          holes: 9,
        },
        {
          title: "Back",
          holes: 9,
        },
      ],
    });

    setCourseState(courses);
  }

  function removeCourse() {
    const courses = [...courseState];

    courses.pop();

    setCourseState(courses);
  }

  function addDivision(courseIndex: number) {
    const updatedCourses = [...courseState];

    updatedCourses[courseIndex].divisions?.push({
      title: "New Division",
      holes: 9,
    });

    setCourseState(updatedCourses);
  }

  function removeDivision(courseIndex: number) {
    const updatedCourses = [...courseState];

    updatedCourses[courseIndex].divisions?.pop();

    setCourseState(updatedCourses);
  }

  function handleFacilityHolesChange() {
    switch (Number(facilityState?.holes)) {
      case 9:
        setCourseState(default9HoleCourseState);
        setFilterState(prevState => ({ ...prevState, customizeCourses: false }));
        break;
      case 18:
        setCourseState(default18HoleCourseState);
        setFilterState(prevState => ({ ...prevState, customizeCourses: false }));
        break;
      case 27:
        setCourseState(default27HoleCourseState);
        setFilterState(prevState => ({ ...prevState, customizeCourses: false }));
        break;
      case 36:
        setCourseState(default36HoleCourseState);
        setFilterState(prevState => ({ ...prevState, customizeCourses: true }));
        break;
      default:
        if (!Number(facilityState?.holes)) {
          setCourseState(default18HoleCourseState);
        } else if (Number(facilityState?.holes) <= 9) {
          setCourseState(default9HoleCourseState);
        } else if (Number(facilityState?.holes) <= 18) {
          setCourseState(default18HoleCourseState);
        } else if (Number(facilityState?.holes) <= 27) {
          setCourseState(default27HoleCourseState);
        } else {
          setCourseState(default36HoleCourseState);
        }
        setFilterState(prevState => ({ ...prevState, customizeCourses: true }));
        break;
    }
  }

  function checkForWarnings() {
    if (!facilityState) {
      dispatch(showError("No information has been entered"));
      return;
    }

    let warningsExist = false;
    const totalCourseHoles = courseState.reduce((sum, course) => sum + Number(course.holes), 0);

    if (Number(facilityState.holes) !== totalCourseHoles) {
      updateWarningsPopup({ warnings: [...warningsPopup.warnings, "Total course holes do not match facility holes"] });
      warningsExist = true;
    }

    const courseCount = courseState.length;
    for (let i = 0; i < courseCount; i++) {
      const totalDivisionHoles = courseState[i].divisions.reduce((sum, division) => sum + Number(division.holes), 0);

      if (Number(courseState[i].holes) !== totalDivisionHoles) {
        updateWarningsPopup({
          warnings: [
            ...warningsPopup.warnings,
            `Total division holes do not match course holes for ${courseState[i].long_name}`,
          ],
        });
        warningsExist = true;
      }
    }

    console.log("Checked Warnings");

    if (warningsExist) {
      updateWarningsPopup({ isOpen: true });
    } else {
      void saveFacility();
    }
  }

  function selectClient(client: IClient) {
    setFacilityState(prevState => ({ ...prevState, client_id: client?.id }));
    setFilterState(prevState => ({ ...prevState, selectedClient: client }));
  }

  function removeClient() {
    setFacilityState(prevState => ({ ...prevState, client_id: null }));
    setFilterState(prevState => ({ ...prevState, selectedClient: null }));
  }

  function displayClients(): IClient[] {
    if (masterClientStore?.clients && masterClientStore?.clients?.length > 0 && filterState?.clientQuery !== "") {
      return filterState?.clients;
    } else {
      return masterClientStore?.clients as IClient[];
    }
  }

  const primaryAction = {
    content: "Create",
    action: checkForWarnings,
  };

  return (
    <Page
      title={"New Facility"}
      narrow
      breadcrumbs={[{ prefix: true, label: "Back to facilities", url: `/admin/facility` }]}
      primaryAction={primaryAction}
    >
      <Card>
        <Card.Section title="Basic Information">
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  label={"Long Name"}
                  id="long_name"
                  value={facilityState?.long_name || ""}
                  onChange={handleInputChange}
                />
                <Toggle
                  id={"closed"}
                  labelRight={"Go Live"}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleToggleChange(e)}
                  checked={facilityState?.closed}
                ></Toggle>
              </FormLayout.Group>
              <FormLayout.Group>
                <Input
                  label={"Short Name"}
                  id="full_name"
                  value={facilityState?.full_name || ""}
                  onChange={handleInputChange}
                />
                <Input label={"Code"} id="code" value={facilityState?.code || ""} onChange={handleInputChange} />
                <Input label={"Prefix"} id="prefix" value={facilityState?.prefix || ""} onChange={handleInputChange} />
                <Input
                  label={"Holes"}
                  id="holes"
                  value={facilityState?.holes || ""}
                  onChange={handleInputChange}
                  type={"number"}
                />
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
        <Card.Section title="Client">
          <Toggle
            id={"create_client"}
            labelRight={"Create Client"}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleToggleChange(e)}
            checked={facilityState?.create_client}
            disabled={!!filterState?.selectedClient}
          ></Toggle>
          <div className="mt-2 mb-2">{"-OR-"}</div>
          {filterState.selectedClient ? (
            <div>
              <div>
                <p className="event-label">{"Attach to Client"}</p>
              </div>

              <div className="selected-container">
                <div className="event-name">
                  <div>{filterState.selectedClient.long_name}</div>

                  <div>
                    <button className="" onClick={removeClient}>
                      <Icon style="far" icon="times" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Select
              label="Attach to Client"
              onChange={(value: any, extraValue: any) => selectClient(extraValue)}
              defaultValue={facilityState?.country_id}
              disabled={facilityState?.create_client}
              showSearch
              showDropDownOnFocus
              onSearch={(value: string) => setFilterState(prevState => ({ ...prevState, clientQuery: value }))}
            >
              {displayClients()?.map((client: IClient, index: number) => {
                return (
                  <Option key={index} value={client.id} extraValues={client}>
                    {client.long_name}
                  </Option>
                );
              })}
            </Select>
          )}
        </Card.Section>
        <Card.Section title="Location Information">
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  label={"Address Line 1"}
                  id="address_line_1"
                  value={facilityState?.address_line_1 || ""}
                  onChange={handleInputChange}
                />
                <Input
                  label={"Address Line 2"}
                  id="address_lne_2"
                  value={facilityState?.address_line_2 || ""}
                  onChange={handleInputChange}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Input label={"City"} id="city" value={facilityState?.city || ""} onChange={handleInputChange} />
                <Input
                  label={"Postal Code"}
                  id="postal"
                  value={facilityState?.postal || ""}
                  onChange={handleInputChange}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Select
                  label="Country"
                  onChange={(value: any) => handleCountryDropDownChange(value)}
                  defaultValue={facilityState?.country_id}
                >
                  {countryState?.map((country: ICountry, index: number) => {
                    return (
                      <Option key={index} value={country.id}>
                        {country.name}
                      </Option>
                    );
                  })}
                </Select>
                <Select
                  label="Province"
                  onChange={(value: any) => handleProvinceDropDownChange(value)}
                  defaultValue={facilityState?.province_id}
                >
                  <Option key={-1} value={-1}>
                    {"None"}
                  </Option>
                  {countryState
                    ?.find(country => country.id === facilityState?.country_id)
                    ?.provinces?.map((province: IProvince, index: number) => {
                      return (
                        <Option key={index} value={province.id}>
                          {province.name}
                        </Option>
                      );
                    })}
                </Select>
                {filterState.selectedTimezone ? (
                  <div>
                    <div>
                      <p className="event-label">{"Time Zone"}</p>
                    </div>

                    <div className="selected-container">
                      <div className="event-name">
                        <div>{valueToString(filterState.selectedTimezone)}</div>

                        <div>
                          <button
                            className=""
                            onClick={() => {
                              setFacilityState(prevState => ({ ...prevState, timezone: null }));
                              setFilterState(prevState => ({ ...prevState, selectedTimezone: null }));
                            }}
                          >
                            <Icon style="far" icon="times" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Select
                    label="Time Zone"
                    onChange={(value: string) => {
                      setFacilityState(prevState => ({ ...prevState, timezone: value }));
                      setFilterState(prevState => ({ ...prevState, selectedTimezone: value }));
                    }}
                    showSearch
                    showDropDownOnFocus
                    placeholder="Search time zones..."
                    onSearch={(value: string) =>
                      setFilterState(prevState => ({ ...prevState, timezoneQuery: value }))
                    }
                  >
                    {timeZones
                      ?.filter(zone =>
                        valueToString(zone).toLocaleLowerCase().includes(filterState.timezoneQuery.toLocaleLowerCase()),
                      )
                      ?.map((zone: string, index: number) => {
                        return (
                          <Option key={index} value={zone}>
                            {valueToString(zone)}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
        <Card.Section title="Contact Information">
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  label={"Phone Number"}
                  id="phone"
                  value={facilityState?.phone || ""}
                  onChange={handleInputChange}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Input label={"Email"} id="email" value={facilityState?.email || ""} onChange={handleInputChange} />
                <Input
                  label={"Customer Email"}
                  id="customer_email"
                  value={facilityState?.customer_email || ""}
                  onChange={handleInputChange}
                />
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
        <Card.Section title="Modules">
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Toggle
                  id={"module_tee_sheet"}
                  labelRight={"Tee Sheet"}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleToggleChange(e)}
                  checked={facilityState?.module_tee_sheet}
                ></Toggle>
              </FormLayout.Group>
              <FormLayout.Group>
                <Toggle
                  id={"module_reservations"}
                  labelRight={"Reservations"}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleToggleChange(e)}
                  checked={facilityState?.module_reservations}
                ></Toggle>
              </FormLayout.Group>
              <FormLayout.Group>
                <Toggle
                  id={"module_alpha"}
                  labelRight={"Waitlist"}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleToggleChange(e)}
                  checked={facilityState?.module_alpha}
                ></Toggle>
              </FormLayout.Group>
              <FormLayout.Group>
                <Toggle
                  id={"online_store"}
                  labelRight={"Online Store"}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleToggleChange(e)}
                  checked={facilityState?.online_store}
                ></Toggle>
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
        <Card.Section title={"Layout"}>
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Toggle
                  id={"customize_courses"}
                  labelRight={"Customize Courses"}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFilterState(prevState => ({ ...prevState, customizeCourses: e.target.checked }))
                  }
                  checked={filterState?.customizeCourses}
                ></Toggle>
              </FormLayout.Group>
              {filterState?.customizeCourses ? (
                <FormLayout.Group>
                  <div>
                    <div className="flex justify-between">
                      <div className="font-semibold">Courses</div>
                      <div className="flex">
                        <Button type="secondary" onClick={removeCourse} disabled={courseState.length <= 1}>
                          <FontAwesomeIcon icon={["far", "minus"]} />
                        </Button>
                        <Button type="primary" onClick={addCourse}>
                          <FontAwesomeIcon icon={["far", "plus"]} />
                        </Button>
                      </div>
                    </div>
                    <div>
                      {courseState?.map((course, index) => (
                        <div key={index}>
                          <FormLayout>
                            <FormLayout.Group>
                              <Input
                                label={"Long Name"}
                                id="long_name"
                                value={course.long_name}
                                onChange={e => handleCourseInputChange(index, e)}
                              />
                            </FormLayout.Group>
                            <FormLayout.Group>
                              <Input
                                label={"Short Name"}
                                id="full_name"
                                value={course.full_name}
                                onChange={e => handleCourseInputChange(index, e)}
                              />
                              <Input
                                label={"Holes"}
                                id="holes"
                                value={course.holes}
                                onChange={e => handleCourseInputChange(index, e)}
                                type={"number"}
                              />
                            </FormLayout.Group>
                          </FormLayout>
                          <div className="text-md font-semibold">{"Divisions"}</div>
                          <FormLayout>
                            {course.divisions.map((division, divisionIndex) => (
                              <FormLayout.Group key={divisionIndex}>
                                <Input
                                  label={"Title"}
                                  id="title"
                                  value={division.title}
                                  onChange={e => handleDivisionInputChange(index, divisionIndex, e)}
                                />
                                <Input
                                  label={"Holes"}
                                  id="holes"
                                  value={division.holes}
                                  onChange={e => handleDivisionInputChange(index, divisionIndex, e)}
                                  type={"number"}
                                />
                              </FormLayout.Group>
                            ))}
                          </FormLayout>
                          <div className="flex">
                            <Button
                              type="secondary"
                              onClick={() => removeDivision(index)}
                              disabled={course.divisions?.length <= 1}
                            >
                              <FontAwesomeIcon icon={["far", "minus"]} />
                            </Button>
                            <Button type="primary" onClick={() => addDivision(index)}>
                              <FontAwesomeIcon icon={["far", "plus"]} />
                            </Button>
                          </div>
                          {courseState.length > 1 ? <Divider /> : null}
                        </div>
                      ))}
                    </div>
                  </div>
                </FormLayout.Group>
              ) : null}
              <FormLayout.Group>
                <Toggle
                  id={"create_templates"}
                  labelRight={"Create Default Tee Sheet Templates"}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFacilityState(prevState => ({ ...prevState, create_templates: e.target.checked }))
                  }
                  checked={facilityState?.create_templates}
                ></Toggle>
              </FormLayout.Group>
              {facilityState?.create_templates ? (
                <FormLayout.Group>
                  <TimePick
                    value={facilityState?.start_time}
                    onChange={timeString =>
                      setFacilityState(prevState => ({ ...prevState, start_time: timeString }))
                    }
                    label={"Start Time"}
                    size="large"
                  />
                  <TimePick
                    value={facilityState?.end_time}
                    onChange={timeString => setFacilityState(prevState => ({ ...prevState, end_time: timeString }))}
                    label={"End Time"}
                    size="large"
                  />
                </FormLayout.Group>
              ) : null}
            </FormLayout>
          </Form>
        </Card.Section>
      </Card>

      <Portal isMounted={warningsPopup.isOpen}>
        <Popup
          open={warningsPopup.isOpen}
          type="warning"
          title="Warning"
          description={`There are ${warningsPopup.warnings.length} warnings:`}
          okText="Continue"
          onOk={saveFacility}
          onCancel={closeWarningPopup}
        >
          <div>
            {warningsPopup.warnings?.map((warning, index) => (
              <div key={index}>{`• ${warning}`}</div>
            ))}
          </div>
          <div>{"Are you sure you want to continue?"}</div>
        </Popup>
      </Portal>
    </Page>
  );
}
