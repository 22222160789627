import React, { useEffect, useState } from "react";
import axios, { CancelToken } from "axios";
import { useHistory } from "react-router";
import moment from "moment";

import { useAppDispatch } from "hooks/redux";
import { showError, showSuccess } from "redux/actions/ui";
import { capitalize } from "helpers/Helpers";

import { PostInvoice } from "api/rpc/2024-04/facilityAdmin/order/invoice";
import { GetCustomer } from "api/rpc/2024-04/facilityAdmin/customer/customer";
import { GetTournament } from "api/rpc/2024-04/facilityAdmin/tournament/tournament";
import { GetEvent } from "api/rpc/2024-04/facilityAdmin/event/hospitalityEvent";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Icon from "components/icon/Icon";
import { Select } from "components/select/index";
import { ICustomer } from "redux/reducers/models/customer";
import { ITournament } from "redux/reducers/models/tournament";
import DatePickerInput from "components/datePickerInput/DatePickerInput";

interface IInvoiceState {
  customer_id: number;
  customer_billing_address_id: number;
  customer_shipping_address_id: number;
  date: Date;
  selected_event: any;
  invoice_due: string;
}

interface IFilterState {
  eventQuery: string;
  selectedEvent: ITournament;
  eventSearching: boolean;
  customerQuery: string;
  selectedCustomer: ICustomer;
  customerSearching: boolean;
}

export default function InvoiceNew() {
  const { Option } = Select;
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [events, setEvents] = useState<Array<any>>(undefined);
  const [customers, setCustomers] = useState<Array<ICustomer>>([]);

  const [invoiceState, setInvoiceState] = useState<IInvoiceState>({
    customer_id: null,
    customer_billing_address_id: null,
    customer_shipping_address_id: null,
    date: new Date(),
    selected_event: null,
    invoice_due: null,
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    eventQuery: "",
    selectedEvent: null,
    eventSearching: false,
    customerQuery: "",
    selectedCustomer: null,
    customerSearching: false,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadEvents(source.token);
    return () => {
      source.cancel();
    };
  }, [filterState.eventQuery]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadCustomers(source.token);
    return () => {
      source.cancel();
    };
  }, [filterState.customerQuery]);

  async function loadEvents(token: CancelToken) {
    let tournaments: Array<any> = [];
    let events: Array<any> = [];
    setEvents(null);

    const tournamentRes = await GetTournament({ search: filterState.eventQuery }, false, token);

    if (token && token.reason) {
      return;
    }
    if (tournamentRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading tournaments")); // TODO: Translation
    } else {
      tournaments = tournamentRes.data.map(tournament => ({ ...tournament, event_type: "tournament" }));
    }

    const eventRes = await GetEvent({ search: filterState.eventQuery }, false, token);

    if (token && token.reason) {
      return;
    }
    if (eventRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading events")); // TODO: Translation
    } else {
      events = eventRes.data.map(event => ({ ...event, event_type: "hospitality" }));
    }

    const allEvents = tournaments.concat(events);

    setEvents(allEvents);
  }

  async function loadCustomers(token?: CancelToken) {
    setFilterState(prevState => ({ ...prevState, customerSearching: true }));
    setCustomers(undefined);

    const customerRes = await GetCustomer(
      {
        search: filterState.customerQuery,
        "address-lines": true,
      },
      false,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (customerRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading customers")); // TODO: Translation
      return;
    }

    setCustomers(customerRes.data);
    setFilterState(prevState => ({ ...prevState, customerSearching: false }));
  }

  async function createInvoice() {
    console.log("Selected Event", invoiceState?.selected_event);
    const invoiceRes = await PostInvoice(
      {
        customer_id: invoiceState?.customer_id,
        customer_billing_address_id:
          invoiceState?.customer_billing_address_id === -1 ? null : invoiceState?.customer_billing_address_id,
        customer_shipping_address_id:
          invoiceState?.customer_shipping_address_id === -1 ? null : invoiceState?.customer_shipping_address_id,
        date: invoiceState?.date.toISOString().slice(0, 10),
        tournament_id:
          invoiceState?.selected_event?.event_type === "tournament" ? invoiceState?.selected_event?.id : null,
        hospitality_event_id:
          invoiceState?.selected_event?.event_type === "hospitality" ? invoiceState?.selected_event?.id : null,
        invoice_due: invoiceState?.invoice_due,
      },
      true,
    );

    if (invoiceRes.status !== StatusCode.OK) {
      dispatch(showError("Error creating invoice")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Invoice created successfully"));
    history.push(`/admin/invoices/${String(invoiceRes.data.id)}/edit`);
  }

  function handleEventChange(event?: any) {
    if (event) {
      setInvoiceState(prevState => ({ ...prevState, selected_event: event }));
      setFilterState(prevState => ({
        ...prevState,
        eventQuery: "",
        selectedEvent: event,
      }));
    }
  }

  function handleCustomerChange(value: any, id: string, customer?: ICustomer) {
    if (customer) {
      setInvoiceState(prevState => ({ ...prevState, [id]: customer.id === -1 ? null : customer.id }));
      setFilterState(prevState => ({ ...prevState, customerQuery: customer.full_name, selectedCustomer: customer }));
    }
  }

  function handleDatePickerChange(selectedDate: Date) {
    setInvoiceState(prevState => ({ ...prevState, date: selectedDate }));
  }

  function handleDropDownChange(value: any, property: string) {
    setInvoiceState(prev => ({ ...prev, [property]: value }));
  }

  const primaryAction = {
    content: "Create", // TODO: Translation
    action: createInvoice,
  };

  return (
    <Page
      title="New Invoice" // TODO: Translation
      primaryAction={primaryAction}
      breadcrumbs={[{ prefix: true, label: "Invoices", url: "/admin/invoices" }]} // TODO: Translation
    >
      <Card>
        <Card.Section title="Invoice Details" /* TODO: Translation */>
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <DatePickerInput
                  label="Date"
                  months={1}
                  position="left"
                  startingDate={invoiceState.date}
                  setStartingDate={handleDatePickerChange}
                />
                <Select
                  label="Invoice Due" // TODO: Translation
                  onChange={(value: string) => setInvoiceState(prevState => ({ ...prevState, invoice_due: value }))}
                >
                  <Option key={0} value={"on_receipt"}>
                    {"On Receipt" /* TODO: Translation */}
                  </Option>
                  <Option key={1} value={"net_30"}>
                    {"Net 30" /* TODO: Translation */}
                  </Option>
                  <Option key={2} value={"net_60"}>
                    {"Net 60" /* TODO: Translation */}
                  </Option>
                </Select>
              </FormLayout.Group>
              <FormLayout.Group>
                {invoiceState.selected_event ? (
                  <div>
                    <div>
                      <p className="event-label">Event</p> {/* TODO: Translation */}
                    </div>

                    <div className="selected-container">
                      <div className="event-name">
                        <div>{invoiceState.selected_event.name}</div>

                        <div>
                          <button
                            className=""
                            onClick={() => setInvoiceState(prevState => ({ ...prevState, selected_event: null }))}
                          >
                            <Icon style="far" icon="times" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Select
                    showSearch
                    label={"Event"} // TODO: Translation
                    onSearch={(query: string) => setFilterState(prevState => ({ ...prevState, eventQuery: query }))}
                    onChange={(value: any, event: any) => handleEventChange(event)}
                    placeholder={"Search events..."} // TODO: Translation
                    allowClear
                    searchValue={filterState.eventQuery}
                    showDropDownOnFocus={true}
                    searching={!events}
                  >
                    <Option value={-1} extraValues={{ name: "None", id: -1 } /* TODO: Translation */}>
                      <div className="text-semibold text-lg">None</div>
                    </Option>
                    {events?.map((event: any, index: number) => {
                      return (
                        <Option value={event?.id} extraValues={event} key={index}>
                          {`${String(event.name)} (${String(capitalize(event.event_type))})`}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
        <Card.Section title="Customer Information" /* TODO: Translation */>
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Select
                  showSearch
                  label={"Customer"} // TODO: Translation
                  onSearch={(query: string) => setFilterState(prevState => ({ ...prevState, customerQuery: query }))}
                  onChange={(value: any, customer: ICustomer) => handleCustomerChange(value, "customer_id", customer)}
                  placeholder={"Search customers..."} // TODO: Translation
                  allowClear
                  searchValue={filterState.customerQuery}
                  showDropDownOnFocus={true}
                  searching={filterState.customerSearching}
                >
                  {customers?.map((customer: ICustomer, index: number) => {
                    return (
                      <Option value={customer?.id} extraValues={customer} key={index}>
                        <div className="text-semibold text-lg">{customer.full_name}</div>
                        <div className="text-sm">{customer.email}</div>
                      </Option>
                    );
                  })}
                </Select>
                <div />
              </FormLayout.Group>
              <FormLayout.Group>
                <Select
                  label={"Customer Billing Address"} // TODO: Translation
                  searchValue=""
                  onChange={(value: any) => handleDropDownChange(value, "customer_billing_address_id")}
                  showDropDownOnFocus={true}
                  disabled={!invoiceState.customer_id}
                >
                  <Option value={-1} extraValues={{ name: "None", id: -1 } /* TODO: Translation */}>
                    <div className="text-semibold text-lg">None</div>
                  </Option>
                  {filterState.selectedCustomer?.address_lines?.map((address: any, index: number) => {
                    return (
                      <Option value={address?.id} key={index}>
                        <div className="text-semibold text-lg">
                          {address.address_line_1}
                          <span className="text-sm">{` ${String(address.city)}, ${String(
                            address.province_name,
                          )}`}</span>
                        </div>
                      </Option>
                    );
                  })}
                </Select>
                <Select
                  label={"Customer Shipping Address"} // TODO: Translation
                  onChange={(value: any) => handleDropDownChange(value, "customer_shipping_address_id")}
                  showDropDownOnFocus={true}
                  disabled={!invoiceState.customer_id}
                >
                  <Option value={-1} extraValues={{ name: "None", id: -1 } /* TODO: Translation */}>
                    <div className="text-semibold text-lg">None</div>
                  </Option>
                  {filterState.selectedCustomer?.address_lines?.map((address: any, index: number) => {
                    return (
                      <Option value={address?.id} key={index}>
                        <div className="text-semibold text-lg">
                          {address.address_line_1}
                          <span className="text-sm">{` ${String(address.city)}, ${String(
                            address.province_name,
                          )}`}</span>
                        </div>
                      </Option>
                    );
                  })}
                </Select>
              </FormLayout.Group>
              <FormLayout.Group>
                <div />
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
      </Card>
    </Page>
  );
}
