import React, { ChangeEvent, useEffect, useState } from "react";
import { useHistory } from "react-router";
import axios, { CancelToken } from "axios";
import { TFunction, useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { GetFacilityForm, PostFacilityForm, PostFacilityFormParams } from "api/rpc/2024-04/masterAdmin/client/form";
import { TFacilityForm } from "redux/reducers/models/facility";

import { showError } from "redux/actions/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import Page from "components/page/Page";
import Sheet from "components/sheet/Sheet";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input";
import Form from "components/form/Form";
import Portal from "elements/Portal";
import DataTable, { HeaderType } from "pages/secure/master/customer/tabs/houseAccounts/DataTable";

const tableColumns = (t: TFunction<"translation", undefined>): HeaderType<unknown>[] => {
  return [{ label: "Title", width: "100%" }];
};

export default function FacilityForm() {
  const history = useHistory();
  const { masterFacilityStore } = useAppSelector(store => store);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [forms, setForms] = useState<TFacilityForm[]>(undefined);
  const [newFormModal, setNewFormModal] = useState<Partial<PostFacilityFormParams> & { open: boolean }>({
    open: false,
    title: "",
    client_id: masterFacilityStore.facility?.client_id,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();

    void updateFacilityForms(source.token);

    return () => {
      source.cancel();
    };
  }, [masterFacilityStore.facility]);

  async function updateFacilityForms(token?: CancelToken) {
    if (typeof forms !== "undefined") {
      setForms(undefined);
    }
    const res = await GetFacilityForm({ client_id: masterFacilityStore.facility?.client_id }, false, token);

    if (res.status !== StatusCode.OK && !token?.reason) {
      setForms([]);
      dispatch(showError(t("secure.facility.settings.forms.facility_forms.001")));
      return;
    }

    if (token && token.reason) {
      return;
    }

    setForms(res.data);
    setNewFormModal({
      open: false,
      title: "",
      client_id: masterFacilityStore.facility?.client_id,
    });
  }

  async function handleNewForm() {
    const deepClone: PostFacilityFormParams & {
      open: boolean;
    } = JSON.parse(JSON.stringify(newFormModal));

    // remove 'open' param
    const params = (({ open, ...rest }) => rest)(deepClone);

    // create the form
    const res = await PostFacilityForm(params, true);

    // close modal
    handleModalClose();

    // refresh all forms
    void updateFacilityForms();
  }

  function handleModalClose() {
    // reset form with modal close
    setNewFormModal({
      open: false,
      title: "",
    });
  }

  return (
    <Page
      title={t("secure.facility.settings.forms.facility_forms.002")}
      primaryAction={{
        content: t("secure.facility.settings.forms.facility_forms.003"),
        action: () => setNewFormModal(prev => ({ ...prev, open: !newFormModal.open })),
        disabled: !masterFacilityStore.facility,
      }}
      subtitle={masterFacilityStore.facility ? masterFacilityStore.facility.long_name : "No Facility Selected"}
    >
      {masterFacilityStore.facility && (
        <DataTable columns={tableColumns(t)} loading={forms === undefined}>
          {forms?.map(form => (
            <tr
              key={form.id}
              className="clickable"
              onClick={() => history.push({ pathname: `/admin/facility/settings/forms/${form.id}` })}
            >
              <td>{form.title}</td>
            </tr>
          ))}
        </DataTable>
      )}

      <Portal isMounted={newFormModal.open}>
        <Sheet
          open={true}
          size="small"
          onCancel={() => handleModalClose()}
          okText={t("secure.facility.settings.forms.facility_forms.004")}
          onOk={() => handleNewForm()}
        >
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  id="title"
                  value={newFormModal.title}
                  label={t("secure.facility.settings.forms.facility_forms.005")}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setNewFormModal(prev => ({ ...prev, title: e.target.value }))
                  }
                />
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Sheet>
      </Portal>
    </Page>
  );
}
