import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import Page from "components/page/Page";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Form from "components/form/Form";
import Input from "components/form/input/Input";
import { Select } from "components/select/index";
import { StatusCode } from "api/protocols";

import { useTranslation } from "react-i18next";
import { PostCustomerType } from "api/rpc/2024-04/facilityAdmin/customer/type";
interface ICustomerTypeState {
  title: string;
  application: string;
  application_options: any;
  subtitle: string;
}

export default function CustomerTypesNew(props: any) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { Option } = Select;

  const [customerTypeState, setCustomerTypeState] = useState<ICustomerTypeState>({
    title: "",
    application: "",
    application_options: [
      {
        label: t("secure.facility.settings.customer_type.customer_type_new.001"),
        value: "green_fee",
      },
      {
        label: t("secure.facility.settings.customer_type.customer_type_new.002"),
        value: "power_cart",
      },
    ],
    subtitle: "",
  });

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setCustomerTypeState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleDropDownChange(value: any, property: string) {
    setCustomerTypeState(prevState => ({ ...prevState, [property]: value }));
  }

  async function saveNewCustomerType() {
    console.log(customerTypeState);
    const res = await PostCustomerType(customerTypeState, true);
    if (res.status !== StatusCode.OK) {
      return;
    }

    console.log(res);
    history.push("/admin/settings/customer_types");
  }

  const primaryAction = {
    content: t("secure.facility.settings.customer_type.customer_type_new.003"),
    action: saveNewCustomerType,
  };

  return (
    <Page
      title={t("secure.facility.settings.customer_type.customer_type_new.004")}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.customer_type.customer_type_new.005"),
          url: "/admin/settings/customer_types",
        },
      ]}
    >
      <Card>
        <Card.Section>
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={customerTypeState.title}
                  label={t("secure.facility.settings.customer_type.customer_type_new.006")}
                  id="title"
                  onChange={handleInputChange}
                  placeholder={t("secure.facility.settings.customer_type.customer_type_new.007")}
                />
                <Input
                  value={customerTypeState.subtitle}
                  label={t("secure.facility.settings.customer_type.customer_type_new.008")}
                  id="subtitle"
                  onChange={handleInputChange}
                  placeholder={t("secure.facility.settings.customer_type.customer_type_new.009")}
                />
                <Select
                  label={t("secure.facility.settings.customer_type.customer_type_new.010")}
                  className={`flex justify-center align-center w-full h-10 position-relative z-20 text-black font-medium appearance-none border-none focus:outline-none placeholder-gray-200`}
                  onChange={(value: any) => handleDropDownChange(value, "application")}
                >
                  {customerTypeState.application_options.map((option: any) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
      </Card>
    </Page>
  );
}
