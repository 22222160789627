import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { isEqualWith, isNull, update } from "lodash";

import { StatusCode } from "api/protocols";

import { IUIActions } from "redux/actions/ui";
import { IUserPermissions } from "redux/reducers/models/user";

import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Page from "components/page/Page";
import Input from "components/form/input/Input";
import TextField from "components/form/textField/TextField";
import { NotificationType } from "components/notificationBar/NotificationBar";
import { Select } from "components/select/index";
import { formatDate, isNumeric } from "helpers/Helpers";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import { ButtonNew as Button } from "components/buttonNew";
import moment from "moment";
import Icon from "components/icon/Icon";
import { GetRegisters } from "api/rpc/2022-09/clientAdmin/register/register";
import { IRegister } from "../../Admin";
import { IClientFacilityStore } from "redux/reducers/clientAdmin/facility";
import { GetFacilityAdmin } from "api/rpc/2022-09/clientAdmin/facility/facility";
import { GetCashOutSummary, GetCashOuts, ICashOut, UpdateCashOut } from "api/rpc/2024-04/clientAdmin/register/cashout";

interface ICashOutEditProps {
  uiActions: IUIActions;
  permissions: IUserPermissions;
  clientFacilityStore: IClientFacilityStore;
}

interface ICashOutEditState {
  cashOut: ICashOut;
  cashOutBeforeChanges: ICashOut;
  cashOutLoaded: boolean;
  cashOutClosedDate: string;
  depositCashInput: string;
  depositCheckInput: string;
  registers: IRegister[];
  deposit_date: Date;
  deposited_by: number;
  closed_at: Date;
}

interface IParams {
  cashOutId: string;
}

interface IFilterState {
  adminSearch: string;
  searchResults: any[];
  deposit_date?: Date;
  showDepositInfo: boolean;
  showDepositInfoButton: boolean;
}

export default function ClientCashOutEdit(props: ICashOutEditProps) {
  const { cashOutId } = useParams<IParams>();

  const { uiActions, clientFacilityStore, permissions } = props;

  const { Option } = Select;

  const { t, i18n } = useTranslation();

  const [cashOutEditState, setCashOutEditState] = useState<ICashOutEditState>({
    cashOut: undefined,
    cashOutBeforeChanges: undefined,
    cashOutLoaded: false,
    cashOutClosedDate: "",
    depositCashInput: "",
    depositCheckInput: "",
    registers: [],
    deposit_date: new Date(),
    deposited_by: null,
    closed_at: new Date(),
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    adminSearch: "",
    searchResults: [],
    showDepositInfo: false,
    showDepositInfoButton: false,
  });

  useEffect(() => {
    void loadCashOut();
  }, []);

  // useEffect(() => {
  //   void loadRegisters();
  //   void loadAdminUsers();
  // }, [clientFacilityStore.facility?.id]);

  async function loadCashOut() {
    const getCashOutsResponse = await GetCashOuts({ id: parseInt(cashOutId) }, true);

    if (getCashOutsResponse.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.settings.cash_outs.cash_out_edit.001"));
      return;
    }

    const cashOut = getCashOutsResponse.data?.[0];
    let cashOutClosedDate = "";

    if (cashOut) {
      const closedDate = new Date(cashOut.closed_at + "Z");
      cashOutClosedDate = closedDate?.toLocaleDateString() ?? "";
    }

    let tempDateString = "";
    let tempDate = new Date();
    let showDepositInfo = false;
    let showDepositInfoButton = true;

    if (cashOut.deposit_date !== null && cashOut.deposit_date !== undefined) {
      tempDateString = moment(cashOut.deposit_date).format();
      tempDate = new Date(tempDateString);
      showDepositInfo = true;
      showDepositInfoButton = false;
    }

    setCashOutEditState(prev => ({
      ...prev,
      cashOut: cashOut,
      cashOutLoaded: true,
      cashOutClosedDate: cashOutClosedDate,
      depositCashInput: String(cashOut?.deposit_cash),
      depositCheckInput: String(cashOut?.deposit_check),
      deposited_by: cashOut.deposited_by,
      deposit_date: tempDate,
    }));

    setFilterState(prevState => ({
      ...prevState,
      deposit_date: tempDate,
      showDepositInfo: showDepositInfo,
      showDepositInfoButton: showDepositInfoButton,
    }));
  }

  async function loadRegisters() {
    if (clientFacilityStore.facility !== null) {
      const getRegistersResponse = await GetRegisters({ facility_id: clientFacilityStore.facility.id }, true);

      console.log(getRegistersResponse);

      if (getRegistersResponse.status !== StatusCode.OK) {
        uiActions.showError(t("secure.facility.settings.cash_outs.cash_out_edit.002"));
        return;
      }

      setCashOutEditState(prevState => ({ ...prevState, registers: getRegistersResponse.data }));
    }
  }

  async function loadAdminUsers() {
    if (clientFacilityStore.facility !== null) {
      console.log(clientFacilityStore);
      const getFacilityAdminResponse = await GetFacilityAdmin({ facility_id: clientFacilityStore.facility.id }, true);

      console.log(getFacilityAdminResponse);

      if (getFacilityAdminResponse.status !== StatusCode.OK) {
        uiActions.showError(t("secure.facility.settings.cash_outs.cash_out_edit.002"));
        return;
      }

      const defaultSearch = "";

      //   if (cashOutEditState.cashOut.deposited_by !== null && cashOutEditState.deposited_by !== undefined) {
      //     for (let i = 0; i < getFacilityAdminResponse.data.length; i++) {
      //       if (cashOutEditState.cashOut.deposited_by === getFacilityAdminResponse.data[i].id) {
      //         defaultSearch = getFacilityAdminResponse.data[i].full_name;
      //       }
      //     }
      //   }

      setFilterState(prevState => ({ ...prevState, searchResults: getFacilityAdminResponse.data }));
    }
  }

  function handleValidatedCashOutChange(event: any, cashOutChange: Partial<ICashOut>) {
    const { id, value } = event.target;
    setCashOutEditState(prev => ({
      ...prev,
      [id]: value,
      cashOut: {
        ...prev.cashOut,
        ...cashOutChange,
      },
    }));
  }

  function handleCashOutInputChange(event: any) {
    const { id, value } = event.target;
    setCashOutEditState(prev => ({
      ...prev,
      cashOut: {
        ...prev.cashOut,
        [id]: value,
      },
    }));
  }

  function handleCashOutRegisterChange(register_id: number) {
    setCashOutEditState(prev => ({
      ...prev,
      cashOut: {
        ...prev.cashOut,
        register_id,
      },
    }));
  }

  function unsavedChangesExist() {
    if (cashOutEditState.cashOutBeforeChanges === undefined) {
      if (cashOutEditState.cashOutLoaded) {
        setCashOutEditState(prev => ({
          ...prev,
          cashOutBeforeChanges: prev.cashOut,
        }));
      }

      return false;
    }

    return !isEqualWith(cashOutEditState.cashOutBeforeChanges, cashOutEditState.cashOut, (originalValue, newValue) => {
      if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
        return true;
      }
    });
  }

  function cancelUnsavedChanges() {
    setCashOutEditState(prev => ({
      ...prev,
      cashOut: prev.cashOutBeforeChanges,
      depositCashInput: String(prev.cashOutBeforeChanges.deposit_cash),
      depositCheckInput: String(prev.cashOutBeforeChanges.deposit_check),
      deposit_date: filterState.deposit_date,
    }));

    if (filterState.showDepositInfoButton) {
      setFilterState(prevState => ({ ...prevState, showDepositInfo: false, adminSearch: "" }));
    }
  }

  async function printCashOutSummary() {
    const res = await GetCashOutSummary(
      {
        register_shift_id: cashOutId,
      },
      true,
    );

    if (res.status === StatusCode.OK) {
      window.open().document.write(res.data);
    } else {
      uiActions.showError("Error getting summary"); //TODO: Translation
    }
  }

  function saveCashout() {
    void updateCashOut();
  }

  const secondaryAction = {
    content: "Print Summary", // TODO: Translation
    action: printCashOutSummary,
  };

  const primaryAction = {
    content: "Save", // TODO: Translation
    action: saveCashout,
  };

  function handleDateSelector(deposit_date: Date) {
    setCashOutEditState(prev => ({
      ...prev,
      cashOut: {
        ...prev.cashOut,
        deposit_date,
      },

      deposit_date: deposit_date,
    }));
  }

  async function updateCashOut() {
    let params;

    if (!filterState.showDepositInfo) {
      params = {
        id: parseInt(cashOutId),
        deposit_cash: parseFloat(cashOutEditState.depositCashInput),
        deposit_check: parseFloat(cashOutEditState.depositCheckInput),
        deposit_bag_number: cashOutEditState.cashOut.deposit_bag_number,
        notes: cashOutEditState.cashOut.notes,
        register_id: cashOutEditState.cashOut.register_id,
        closed_at: formatDate(cashOutEditState.closed_at),
      };
    } else {
      params = {
        deposit_date: formatDate(cashOutEditState.deposit_date),
        id: parseInt(cashOutId),
        deposit_cash: parseFloat(cashOutEditState.depositCashInput),
        deposit_check: parseFloat(cashOutEditState.depositCheckInput),
        deposit_bag_number: cashOutEditState.cashOut.deposit_bag_number,
        notes: cashOutEditState.cashOut.notes,
        register_id: cashOutEditState.cashOut.register_id,
        closed_at: formatDate(cashOutEditState.closed_at),
      };
    }

    const updateRes = await UpdateCashOut(params, true);

    if (updateRes.status !== StatusCode.OK) {
      uiActions.showError(updateRes.message);
      return;
    }

    const cashOut = updateRes.data;

    let cashOutClosedDate = "";

    if (cashOut) {
      const closedDate = new Date(String(cashOut.closed_at) + "Z");
      cashOutClosedDate = closedDate?.toLocaleDateString() ?? "";
    }

    let tempDateString = "";
    let tempDate = new Date();

    if (cashOut.deposit_date !== null && cashOut.deposit_date !== undefined) {
      tempDateString = moment(cashOut.deposit_date).format();
      tempDate = new Date(tempDateString);
    }

    setFilterState(prevState => ({ ...prevState, deposit_date: cashOutEditState.cashOut.deposit_date }));
    setCashOutEditState(prev => ({
      ...prev,
      cashOut: cashOut,
      cashOutBeforeChanges: cashOut,
      cashOutClosedDate: cashOutClosedDate,
      depositCashInput: String(cashOut?.deposit_cash),
      depositCheckInput: String(cashOut?.deposit_check),
      deposited_by: cashOut.deposited_by,
      deposit_date: tempDate,
    }));
  }

  function handleCashOutDepositByChange(deposited_by: number) {
    setCashOutEditState(prev => ({
      ...prev,
      cashOut: {
        ...prev.cashOut,
        deposited_by,
      },
    }));
  }

  const handleAdminSearch = (query: string) => {
    setFilterState(prevState => ({ ...prevState, adminSearch: query }));
  };

  function handleAddDepositDetails() {
    if (filterState.showDepositInfo) {
      setFilterState(prevState => ({ ...prevState, showDepositInfo: false }));
      setCashOutEditState(prev => ({
        ...prev,
        cashOut: {
          ...prev.cashOut,
          deposit_date: null,
        },
        deposit_date: filterState.deposit_date,
      }));
    } else {
      setFilterState(prevState => ({ ...prevState, showDepositInfo: true }));
      setCashOutEditState(prev => ({
        ...prev,
        cashOut: {
          ...prev.cashOut,
          deposit_date: cashOutEditState.deposit_date,
        },
      }));
    }
  }

  return (
    <Page
      title={`Cash Out ${cashOutEditState.cashOutClosedDate}`}
      narrow
      primaryAction={permissions.cash_outs_view_summary && primaryAction}
      // secondaryActions={[secondaryAction]}
      breadcrumbs={[
        {
          prefix: true,
          label: "back to Cash Outs",
          url: "/admin/settings/facility-settings/cash-out",
        },
      ]}
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        // notificationType: NotificationType.UnsavedChanges,
        // actionContent: "Save",
        onAction: saveCashout,
        onCancel: cancelUnsavedChanges,
      }}
    >
      {cashOutEditState.cashOut && cashOutEditState.cashOutLoaded && (
        <>
          <Card>
            <Card.Section>
              <FormLayout>
                <FormLayout.Group>
                  <Input
                    label={"Closed by"}
                    onChange={() => {
                      return;
                    }}
                    value={cashOutEditState.cashOut.closed_by_full_name}
                    disabled={!permissions.cash_outs_edit}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Input
                    value={cashOutEditState.depositCashInput}
                    label={t("secure.facility.settings.cash_outs.cash_out_edit.007")}
                    id="depositCashInput"
                    onChange={(e: any) =>
                      handleValidatedCashOutChange(
                        e,
                        isNumeric(e.target.value) ? { deposit_cash: parseFloat(e.target.value) } : {},
                      )
                    }
                    error={!isNumeric(cashOutEditState.depositCashInput)}
                    disabled={!permissions.cash_outs_edit}
                  />
                  <Input
                    value={cashOutEditState.depositCheckInput}
                    label={t("secure.facility.settings.cash_outs.cash_out_edit.008")}
                    id="depositCheckInput"
                    onChange={(e: any) =>
                      handleValidatedCashOutChange(
                        e,
                        isNumeric(e.target.value) ? { deposit_check: parseFloat(e.target.value) } : {},
                      )
                    }
                    error={!isNumeric(cashOutEditState.depositCheckInput)}
                    disabled={!permissions.cash_outs_edit}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Input
                    value={cashOutEditState.cashOut.deposit_bag_number}
                    label={t("secure.facility.settings.cash_outs.cash_out_edit.009")}
                    id="deposit_bag_number"
                    onChange={handleCashOutInputChange}
                    disabled={!permissions.cash_outs_edit}
                  />

                  <Input
                    value={cashOutEditState.cashOut.register_title}
                    label={t("secure.facility.settings.cash_outs.cash_out_edit.010")}
                    id="register"
                    // onChange={handleCashOutInputChange}
                    disabled={true}
                  />
                  {/* <Select
                    label={t("secure.facility.settings.cash_outs.cash_out_edit.010")}
                    onChange={handleCashOutRegisterChange}
                    defaultValue={"Register Test"}
                    disabled={true}
                    
                  >
                    {cashOutEditState.registers?.map((register, index) => {
                      return (
                        <Option key={index} value={register.id}>
                          {register.title}
                        </Option>
                      );
                    })}
                  </Select> */}
                </FormLayout.Group>
                <FormLayout.Group>
                  <TextField
                    value={cashOutEditState.cashOut.notes}
                    label={t("secure.facility.settings.cash_outs.cash_out_edit.011")}
                    id="notes"
                    onChange={handleCashOutInputChange}
                    disabled={!permissions.cash_outs_edit}
                  />
                </FormLayout.Group>

                {filterState.showDepositInfoButton ? (
                  <FormLayout.Group>
                    <Button size="medium" type="link" onClick={handleAddDepositDetails}>
                      {"Add Deposit Details"}
                    </Button>
                  </FormLayout.Group>
                ) : null}
              </FormLayout>
            </Card.Section>
          </Card>

          {filterState.showDepositInfo ? (
            <Card title="Deposit">
              <Card.Section>
                <FormLayout>
                  <FormLayout.Group>
                    <div className="flex align-items-end h-full">
                      <DatePickerInput
                        months={1}
                        position={"left"}
                        startingDate={cashOutEditState.deposit_date}
                        setStartingDate={handleDateSelector}
                      />
                    </div>

                    <Input
                      value={cashOutEditState.cashOut.closed_by_full_name}
                      label={"Admin Users"}
                      id="admin_user"
                      // onChange={handleCashOutInputChange}
                      disabled={true}
                    />

                    {/* <Select
                      showSearch
                      onSearch={(query: string) => handleAdminSearch(query)}
                      onChange={(id: number) => handleCashOutDepositByChange(id)}
                      allowClear
                      searchValue={filterState.adminSearch}
                      label="Admin Users"
                      dropDownPositionTop={false}
                      placeholder="Users"
                      defaultValue={"Test Name"}
                      disabled={true}
                      noData={
                        <div className="px-4">
                          <div className="border-b flex items-center p-4 border-b mb-4">
                            <Icon icon="users-slash" style="fas" className="m-0" />
                            <span className="ml-3 italic">No user found...</span>
                          </div>
                        </div>
                      }
                    >
                      {filterState.searchResults
                        .filter((result) =>
                          result.full_name.toLowerCase().includes(filterState.adminSearch.toLowerCase()),
                        )
                        .map((user: any) => {
                          if (user !== null && user !== undefined) {
                            return (
                              <Option key={user?.id} value={user?.id} name={user?.full_name}>
                                <div className="flex justify-between">
                                  <div>
                                    <div className="text-semibold text-lg">{user?.full_name}</div>
                                    <div className="text-sm text-gray-500">{user?.email}</div>
                                  </div>
                                </div>
                              </Option>
                            );
                          }
                        })}
                    </Select> */}
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
            </Card>
          ) : null}
        </>
      )}
    </Page>
  );
}
