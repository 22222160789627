import React, { useEffect, useMemo, useRef, useState } from "react";

import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { valueToString } from "helpers/Helpers";
import { MOBILE_WIDTH } from "helpers/ScreenSizes";
import classNames from "classnames";

import { Column, ColumnInstance, useExpanded, useGroupBy, useTable } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TableFilterDropdown from "components/tableFilterDropdown/TableFilterDropdown";

import "components/reportTable/reportTable.scss";

interface IProps {
  data: Array<Record<string, any>>;
  columns: Array<Record<string, any>>;
  description: string;
  compileDate: string;
  cellOnClick: (cell: any) => void;
  cellData: (columnId: string, cellValue: string, displayType: string, cell?: any) => void;
  rowClassNames?: (row: any) => void;
  cellClassNames: (cell: any, isRowLocked: boolean) => void;
  footerData: (columnId: string) => void;
  expanding: boolean;
  expandingColumn?: string;
}

interface ILockedColumn {
  id: string;
  offset: number;
}

export default function BaseReportTable(props: IProps) {
  const [columnsState, setColumnsState] = useState<ColumnInstance<Record<string, any>>[]>([]);
  const [lockedColumns, setLockedColumns] = useState<ILockedColumn[]>([]);
  const tableColumnsRef = useRef<HTMLTableRowElement>(null);
  const [tableColumnsWidth, setTableColumnsWidth] = useState<number>(undefined);
  const windowSize = useWindowSize();

  const { cellOnClick, cellData, rowClassNames, cellClassNames, footerData } = props;

  function formatDisplay(type: string, value: string) {
    let newValue = value !== null && value !== undefined ? value : "";

    if (!isNaN(Number(newValue)) && newValue !== "") {
      switch (type) {
        case "currency":
          newValue = new Intl.NumberFormat("en-CA", { style: "currency", currency: "CAD" }).format(Number(value));
          break;
        case "decimal":
          newValue = Number(value).toFixed(2);
          break;
        case "percentage":
          newValue = `${Number(value) * 100}%`;
          break;
        default:
          break;
      }
    } else if (type === "string" && newValue !== "") {
      newValue = valueToString(value).replace(/(^\w|\s\w)/g, c => c.toUpperCase());
    }

    return newValue;
  }

  //Set columns and subcolumns
  function getColumns(colArray: Array<Record<string, any>>) {
    const columnsArray: Column<Record<string, any>>[] =
      colArray?.length > 0
        ? colArray?.map(col => {
            const columnId = col.id.toString();
            return {
              Header:
                columnId === props.expandingColumn
                  ? ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }: any) => (
                      <span {...getToggleAllRowsExpandedProps()}>
                        {col.display}
                        {isAllRowsExpanded ? (
                          <FontAwesomeIcon fixedWidth icon={["far", "chevron-down"]} />
                        ) : (
                          <FontAwesomeIcon fixedWidth icon={["far", "chevron-right"]} />
                        )}
                      </span>
                    )
                  : col.display ?? "",
              id: columnId,
              locked: col.locked,
              accessor: col.subColumns ? undefined : columnId,
              columns: !col.subColumns ? undefined : getColumns(col.subColumns),
              show_with_parent: col.show_with_parent,
              display_type: col?.type,
              aggregate: col?.aggregate ? col?.aggregate : undefined,
              Aggregated:
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                ({ value }: any) => `${formatDisplay(col.type, value)} ${col?.aggregateText ? col?.aggregateText : ""}`,
              disableGroupBy: !col?.canGroup,
            };
          })
        : [];
    return columnsArray;
  }

  const reportsTableData = useMemo(() => props.data, [props.data]);

  const reportsDataColumns: any = useMemo(() => [...getColumns(props.columns)], [props.columns]);

  // initialState: {hiddenColumns: ["number"]} <- column ids fill this array
  const tableInstance = useTable({ columns: reportsDataColumns, data: reportsTableData }, useGroupBy, useExpanded);
  const { getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, prepareRow, allColumns } = tableInstance;

  useEffect(() => {
    const filteredColumns = allColumns?.filter(column => column.id !== "expander");
    setColumnsState(filteredColumns);
  }, [allColumns]);

  useEffect(() => {
    if (!tableColumnsRef?.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      if (tableColumnsRef?.current) {
        let tableColumnsWidth = 0;

        for (let i = 0; i < tableColumnsRef.current.cells.length; i++) {
          tableColumnsWidth += tableColumnsRef.current.cells[i].getBoundingClientRect().width;
        }

        setTableColumnsWidth(tableColumnsWidth);
      }
    });

    resizeObserver.observe(tableColumnsRef.current);
    return () => resizeObserver.disconnect();
  }, [tableColumnsRef?.current]);

  useEffect(() => {
    if (tableColumnsRef.current) {
      const lockedHeaders: Array<{ id: string; locked: boolean; index: number }> = headerGroups?.[
        headerGroups.length - 1
      ]?.headers
        ?.map((header: any, index: number) => ({ id: header.id, locked: header.locked || header.isGrouped, index }))
        ?.filter(header => header.locked);

      const lockedColumns: ILockedColumn[] = [];
      let offset = 0;

      lockedHeaders.forEach(lockedHeader => {
        lockedColumns.push({ id: lockedHeader.id, offset });
        offset += tableColumnsRef.current.cells[lockedHeader.index].getBoundingClientRect().width;
      });

      setLockedColumns(lockedColumns);
    }
  }, [headerGroups, tableColumnsWidth]);

  //Hide subcolumns based on the header's show_with_parent value
  function handleColumnClick(column: any) {
    column?.headers?.forEach((header: any) => {
      if (header?.show_with_parent === false) {
        header.toggleHidden();
      }
    });
  }

  function checkLockedColumn(lockedColumn: any) {
    return (
      lockedColumn !== undefined &&
      lockedColumn?.offset !== undefined &&
      (windowSize.width > MOBILE_WIDTH ||
        lockedColumn.id === lockedColumns?.[0]?.id ||
        lockedColumn.id === lockedColumns?.[1]?.id)
    );
  }

  return props.data && props.data?.length > 0 ? (
    <>
      <div className="report-table-filter-button-container">
        <TableFilterDropdown columns={columnsState} tableInstance={tableInstance} />
      </div>

      <div className="report-table-container">
        <table {...getTableProps()} className="report-table-dynamic">
          <thead>
            {headerGroups.map((headerGroup, headerGroupIndex) => (
              <tr
                key={headerGroupIndex}
                {...headerGroup.getHeaderGroupProps()}
                ref={headerGroupIndex === headerGroups.length - 1 ? tableColumnsRef : null}
                className="report-table-header"
              >
                {headerGroup.headers.map((column: any, columnIndex) => {
                  const lockedColumn = lockedColumns?.find(lockedColumn => lockedColumn?.id === column?.id);
                  const isColumnLocked = checkLockedColumn(lockedColumn);

                  if (column?.columns) {
                    return (
                      <td key={columnIndex} {...column.getHeaderProps()} onClick={() => handleColumnClick(column)}>
                        {column.render("Header")}
                      </td>
                    );
                  } else {
                    return (
                      <td
                        key={columnIndex}
                        {...column.getHeaderProps()}
                        style={isColumnLocked ? { left: lockedColumn.offset } : {}}
                        className={classNames({
                          "report-table-column-locked": isColumnLocked,
                        })}
                      >
                        <div className={column.display_type === "currency" ? "report-table-cell-currency" : ""}>
                          {column.canGroupBy ? (
                            // If the column can be grouped, let's add a toggle
                            <span {...column.getGroupByToggleProps()}>
                              {column.isGrouped ? (
                                <FontAwesomeIcon className="mr-1" fixedWidth icon={["far", "octagon-minus"]} />
                              ) : (
                                <FontAwesomeIcon className="mr-1" fixedWidth icon={["far", "octagon-plus"]} />
                              )}
                            </span>
                          ) : null}
                          {column.render("Header")}
                        </div>
                      </td>
                    );
                  }
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row: any, indx: number) => {
              prepareRow(row);
              return (
                <tr
                  key={indx}
                  {...row.getRowProps()}
                  className={rowClassNames ? rowClassNames(row) : "report-table-row"}
                >
                  {row.cells.map((cell: any, index: number) => {
                    const lockedColumn = lockedColumns?.find(lockedColumn => lockedColumn?.id === cell?.column?.id);
                    const isRowLocked = checkLockedColumn(lockedColumn);

                    return (
                      <td
                        key={index}
                        {...cell.getCellProps()}
                        {...(row.canExpand && props.expanding && cell.column.id === props.expandingColumn
                          ? row.getToggleRowExpandedProps()
                          : null)}
                        style={{
                          left: isRowLocked ? lockedColumn.offset : "auto",
                          cursor: row.canExpand && cell.column.id === props.expandingColumn ? "pointer" : "auto",
                        }}
                        className={cellClassNames(cell, isRowLocked)}
                        {...(!props.expanding ? { onClick: () => cellOnClick(cell) } : {})}
                      >
                        <div className={cell.column.display_type === "currency" ? "report-table-cell-currency" : ""}>
                          {cell.column.id === props.expandingColumn && (
                            <span
                              {...row.getToggleRowExpandedProps({
                                style: {
                                  paddingLeft: `${row.depth * 1.1}rem`,
                                },
                              })}
                            />
                          )}
                          {cell.isGrouped && (
                            <span {...row.getToggleRowExpandedProps()}>
                              {row.isExpanded ? (
                                <FontAwesomeIcon fixedWidth icon={["far", "chevron-down"]} />
                              ) : (
                                <FontAwesomeIcon fixedWidth icon={["far", "chevron-right"]} />
                              )}
                            </span>
                          )}
                          {(!props.expanding || !row.canExpand || cell.column.id !== props.expandingColumn) &&
                            (cell.isAggregated
                              ? cell.render("Aggregated")
                              : cellData(cell.column.id, cell.value, cell?.column?.display_type, cell))}
                          {cell.isGrouped && `(${String(row.subRows.length)})`}
                          {props.expanding && row.canExpand && cell.column.id === props.expandingColumn ? (
                            <span>
                              {cell.isAggregated
                                ? cell.render("Aggregated")
                                : cellData(cell.column.id, cell.value, cell?.column?.display_type, cell)}
                              {row.isExpanded ? (
                                <FontAwesomeIcon fixedWidth icon={["far", "chevron-down"]} />
                              ) : (
                                <FontAwesomeIcon fixedWidth icon={["far", "chevron-right"]} />
                              )}
                            </span>
                          ) : null}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          <tfoot className="table-footer">
            {footerGroups.map((footerGroup, footerGroupIndex) => (
              <tr
                key={footerGroupIndex}
                {...footerGroup.getHeaderGroupProps()}
                ref={footerGroupIndex === headerGroups.length - 1 ? tableColumnsRef : null}
                className="report-table-footer"
              >
                {footerGroup.headers.map((column: any, columnIndex) => {
                  const lockedColumn = lockedColumns?.find(lockedColumn => lockedColumn?.id === column?.id);
                  const isColumnLocked = checkLockedColumn(lockedColumn);

                  return (
                    <td
                      key={columnIndex}
                      style={isColumnLocked ? { left: lockedColumn.offset } : {}}
                      {...column.getFooterProps()}
                      className={classNames({
                        "report-table-footer-locked": isColumnLocked,
                        "report-table-cell-currency": column.display_type === "currency",
                      })}
                    >
                      {footerData(column.id)}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>
    </>
  ) : (
    <div></div>
  );
}
