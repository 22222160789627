import React, { useState, ChangeEvent, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { StatusCode } from "api/protocols";
import { showError } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";
import axios, { CancelToken } from "axios";
import Page from "components/page/Page";
import Card from "components/card/Card";
import Input from "components/form/input/Input";
import { GetProductGroups, PostProductGroup, TPostProductGroup } from "api/rpc/2024-04/facilityAdmin/product/groups";
import { Select } from "components/select/index";

interface IFilterState {
  parentQuery: string;
  parentSearching: boolean;
  productGroups: any;
}

interface IGroup {
  title: string;
  type: string;
  parent_id: number;
}

export default function ProductGroupNew() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { Option } = Select;

  const [group, setGroup] = useState<IGroup>({
    title: "",
    type: "",
    parent_id: null,
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    parentQuery: "",
    parentSearching: false,
    productGroups: [],
  });

  const types = [
    {
      title: "Group",
      value: "group",
    },
    {
      title: "SubGroup",
      value: "sub_group",
    },
  ];

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadProductGroups(source.token);
    return () => source.cancel();
  }, []);

  async function loadProductGroups(token?: CancelToken) {
    if (filterState.productGroups !== undefined) {
      setFilterState(prevState => ({ ...prevState, productGroups: undefined }));
    }

    const res = await GetProductGroups(null, token ? false : true, token);

    if (res.status !== StatusCode.OK) {
      setFilterState(prevState => ({ ...prevState, productGroups: [] }));

      return;
    }

    setFilterState(prevState => ({ ...prevState, productGroups: res.data }));
  }

  async function addProductGroup() {
    const params: TPostProductGroup = {
      title: group.title,
      type: group.type,
      parent_id: group.parent_id,
    };

    const res = await PostProductGroup(params, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError(res.data ? res.data : "There was an error adding the new product group."));
      return;
    }

    history.push("/admin/settings/groups");
  }

  function handleDropDownChange(value: string) {
    setGroup(prevState => ({ ...prevState, type: value }));
  }

  function handleParentChange(value: string) {
    setGroup(prevState => ({ ...prevState, parent_id: Number(value) }));
  }

  return (
    <Page
      title="New Product Group"
      breadcrumbs={[{ prefix: true, label: "Groups", url: "/admin/settings/groups" }]}
      primaryAction={{
        action: () => addProductGroup(),
        content: "Save",
        disabled: group.title.length === 0 || group.type.length === 0,
      }}
    >
      <Card>
        <Card.Section>
          <div className="mb-3">
            <Input
              label="Title"
              value={group.title}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setGroup(prev => ({ ...prev, title: e.target.value }))}
            />
          </div>

          <Select label={"Type"} onChange={handleDropDownChange} defaultValue={group.type}>
            {types.map((type: any, index: any) => {
              return (
                <Option key={index} value={type.value} name={type.title}>
                  <div style={{ display: "flex", alignItems: "center" }}>{type.title}</div>
                </Option>
              );
            })}
          </Select>

          {group.type?.toLocaleLowerCase() === "sub_group" ? (
            <div className="mt-3 mb-3">
              <Select
                showSearch
                label={"Parent Group"}
                onSearch={(query: string) => setFilterState(prevState => ({ ...prevState, parentQuery: query }))}
                onChange={(value: any) => handleParentChange(value)}
                placeholder={"Parent Group"}
                allowClear
                searchValue={filterState.parentQuery}
                showDropDownOnFocus={true}
                searching={filterState.parentSearching}
              >
                {filterState.productGroups
                  ?.filter(
                    (group: { title: string; type: string }) =>
                      group.title.toLocaleLowerCase().includes(filterState.parentQuery.toLocaleLowerCase()) &&
                      group.type === "group",
                  )
                  .map((group: any, index: number) => {
                    return (
                      <Select.Option key={index} value={group.id} name={group.title}>
                        <div className="flex items-center">{group.title}</div>
                      </Select.Option>
                    );
                  })}
              </Select>
            </div>
          ) : null}
        </Card.Section>
      </Card>
    </Page>
  );
}
