import { TFunction } from "react-i18next";
import { IOptions } from "../Admin";

export default function leagueOptions(t: TFunction<"translation", undefined>, leagueId: number): IOptions[] {
  if (isNaN(leagueId)) {
    return [];
  } else {
    return [
      {
        label: "Home",
        url: `/admin/league/${leagueId}/home`,
      },
      {
        label: "Roster",
        url: `/admin/league/${leagueId}/players`,
      },
      // {
      //   label: "Announcements",
      //   url: `/admin/league/${leagueId}/announcements`,
      // },
      {
        label: "Scoring",
        url: `/admin/league/${leagueId}/scores`,
        // subOptions: [
        //   {
        //     label: t("secure.facility.league.league_menu.004"),
        //     url: `/admin/league/${leagueId}/scoring`,
        //   },
        //   {
        //     label: "Teams",
        //     url: `/admin/league/${leagueId}/teams`,
        //   },
        //   {
        //     label: "Flights",
        //     url: `/admin/league/${leagueId}/flights`,
        //   },
        //   {
        //     label: "Leaderboard",
        //     url: `/admin/league/${leagueId}/scoring/season-leaderboard`,
        //   },
        //   {
        //     label: "Order of Merit",
        //     url: `/admin/league/${leagueId}/scoring/order-of-merit`,
        //   },
        // ],
      },
      {
        label: "Settings",
        url: `/admin/league/${leagueId}/league-settings`,
        subOptions: [
          {
            label: "General",
            url: `/admin/league/${leagueId}/league-settings/general`,
          },
          // {
          //   label: t("secure.facility.league.league_menu.006"),
          //   url: `/admin/league/${leagueId}/league-settings/scoring`,
          // },
          {
            label: "Registration",
            url: `/admin/league/${leagueId}/league-settings/registration-fees`,
          },
        ],
      },
    ];
  }
}
