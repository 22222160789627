import { StatusCode } from "api/protocols";
import { GetLeague, GetLeagueRegistrationFees, IRegistrationFee } from "api/rpc/2022-09/facilityAdmin/league/league";
import classNames from "classnames";
import Checkbox from "components/form/checkbox/Checkbox";
import Sheet from "components/sheet/Sheet";
import { useAppDispatch } from "hooks/redux";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { dequeue, enqueue, showError } from "redux/actions/ui";
import { ICartLineItem } from "redux/reducers/models/cart";
import { ILeague } from "redux/reducers/models/league";
import { ButtonNew as Button } from "components/buttonNew";

interface ILeagueSelection {
  lineItem: ICartLineItem;
  leagues: Array<IRegistrationFee>;
}

interface IProps {
  open: boolean;
  onCancel: () => void;
  onOk: (selectedLeagues: Array<string | number>) => void;
  leagueFeeProducts: Array<ICartLineItem>;
}

interface IState {
  selectedLeagues: Map<number, ILeague>;
  leagues: Array<ILeagueSelection>;
}

export default function LeagueSelectionModal(props: IProps) {
  const { t, i18n } = useTranslation();
  const { open, onCancel, onOk, leagueFeeProducts } = props;
  const dispatch = useAppDispatch();

  const [state, setState] = useState<IState>({
    selectedLeagues: new Map(),
    leagues: [],
  });

  useEffect(() => {
    if (leagueFeeProducts && leagueFeeProducts?.length > 0) {
      void loadLeagues();
    } else {
      setState(prevState => ({ ...prevState, selectedLeagues: new Map(), leagues: [] }));
    }
  }, [leagueFeeProducts]);

  async function loadLeagues() {
    const leagues: Array<ILeagueSelection> = [];

    dispatch(enqueue());
    for (const lineItem of leagueFeeProducts) {
      const leagueRes = await GetLeagueRegistrationFees({ variant_id: lineItem?.variant_id }, false);
      if (leagueRes.status !== StatusCode.OK) {
        dispatch(showError(t("components.league_selection_modal.league_selection_modal.001")));
        dispatch(dequeue());
        return;
      }
      leagues.push({ lineItem: lineItem, leagues: leagueRes.data });
    }

    dispatch(dequeue());
    setState(prevState => ({ ...prevState, leagues }));
  }

  function handleCancel() {
    void onCancel();
    setState(prevState => ({ ...prevState, selectedLeagues: new Map(), leagues: [] }));
  }

  function handleOk() {
    const selectedLeagues = Array.from(state.selectedLeagues, league => league[1]?.id);

    if (state?.selectedLeagues?.size === 0) {
      void onOk(null);
      setState(prevState => ({ ...prevState, selectedLeagues: new Map(), leagues: [] }));
    } else {
      void onOk(selectedLeagues);
      setState(prevState => ({ ...prevState, selectedLeagues: new Map(), leagues: [] }));
    }
  }

  function handleSelectLeague(league: ILeague, index: number) {
    const selectedLeagues = new Map(state.selectedLeagues);
    if (selectedLeagues?.get(index)?.id === league?.id) {
      selectedLeagues?.delete(index);
    } else {
      selectedLeagues.set(index, league);
    }
    setState(prevState => ({ ...prevState, selectedLeagues }));
  }

  return (
    <Sheet
      title={t("components.league_selection_modal.league_selection_modal.002")}
      open={open}
      size="small"
      closable
      stacked
      onCancel={handleCancel}
      onOk={handleOk}
      okText={
        state?.selectedLeagues?.size === 0 ? "Skip" : t("components.league_selection_modal.league_selection_modal.003")
      }
    >
      <div className="ui-checkbox-group">
        {state?.leagues?.map((league, leagueIndex) => {
          return (
            <React.Fragment key={leagueIndex}>
              <div className="mb-2 flex justify-between">
                <p>
                  {league?.lineItem?.product?.preferred_title
                    ? league?.lineItem?.product?.preferred_title
                    : league?.lineItem?.product?.title}
                </p>
                {league?.lineItem?.product_title !== league?.lineItem?.variant_title && (
                  <p className="text-xs">{league?.lineItem?.variant_title}</p>
                )}
              </div>
              {league?.leagues?.map((leagueFee, leagueFeeIndex) => {
                return (
                  <div
                    key={leagueFeeIndex}
                    onClick={() => handleSelectLeague(leagueFee?.league, leagueIndex)}
                    className={classNames("ui-checkbox-group-item", {
                      "ui-checkbox-group-item_selected":
                        state.selectedLeagues.get(leagueIndex)?.id === leagueFee?.league_id,
                    })}
                  >
                    <div className="ui-checkbox-group-item-content">
                      <div className="ui-checkbox-group-item-text">
                        <p className="text-sm text-medium text-gray-700">{leagueFee?.league?.name}</p>
                      </div>
                    </div>
                    <Checkbox
                      size="medium"
                      value={leagueFee.league_id}
                      checked={state?.selectedLeagues.get(leagueIndex)?.id === leagueFee?.league_id}
                    />
                  </div>
                );
              })}
              <br />
            </React.Fragment>
          );
        })}
      </div>
    </Sheet>
  );
}
