import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { GetDepartments, GetFacilityNew } from "api/rpc/facility";
import { GetRegisters } from "api/rpc/facilityAdmin/facility/register/register";
import { GetFacilityAdmin } from "api/rpc/facilityAdmin/client/admin";
import { GetBaseTile, GetDashboard, PostTile } from "api/rpc/facilityAdmin/client/dashboard";

import { showError, showSuccess } from "redux/actions/ui";
import { capitalize } from "helpers/Helpers";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Input from "components/form/input/Input";
import { Select } from "components/select/index";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import FilterDropdown from "components/filterDropDown/FilterDropDown";
import Toggle from "components/form/toggle/Toggle";
import { IDisplayOptions } from "pages/secure/facility/dashboard/Chart";

interface ITileState {
  title: string;
  dashboard_id: number;
  base_tile_id: number;
  dashboards: Array<Record<string, any>>;
  base_tiles: Array<Record<string, any>>;
  selectedBaseTile: Record<string, any>;
  options: Array<{ name: string; type: string; options?: Array<string | number> }>;
  facility_ids: Array<number>;
  timezones: Array<string>;
  timezone: string;
  all_facility_ids: Array<Record<string, any>>;
  all_register_ids: Array<Record<string, any>>;
  all_user_ids: Array<Record<string, any>>;
  all_department_ids: Array<Record<string, any>>;
  //Display Options
  hide_legend: boolean;
  legend_title: string;
  legend_position: "top" | "bottom" | "left" | "right";
  [key: string]: any;
}

export default function TilesNew() {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();

  const [state, setState] = useState<ITileState>({
    title: "",
    dashboard_id: null,
    base_tile_id: null,
    dashboards: [],
    base_tiles: [],
    selectedBaseTile: null,
    options: [],
    facility_ids: [],
    all_facility_ids: [],
    timezones: [],
    timezone: "",
    all_register_ids: [],
    all_user_ids: [],
    all_department_ids: [],
    //Display Options
    hide_legend: true,
    legend_title: "",
    legend_position: "top",
  });
  const { Option } = Select;

  useEffect(() => {
    void loadDashboards();
    void loadBaseTiles();
    void getFacilities();
    void getRegisters();
    void getAdmins();
    void getDepartments();
  }, []);

  async function getFacilities() {
    const res = await GetFacilityNew(null, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError(res?.message));
      history.push("/admin/settings/dashboards");
      return;
    }
    setState(prevState => ({
      ...prevState,
      facility_ids: [res.data[0]?.id],
      all_facility_ids: res.data,
      timezones: [res.data[0]?.timezone],
      timezone: res.data[0]?.timezone,
    }));
  }

  async function getDepartments() {
    const res = await GetDepartments(true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError(res?.message));
      history.push("/admin/settings/dashboards");
      return;
    }
    const departments = res?.data?.filter((department: Record<string, any>) => department?.type === "department");
    setState(prevState => ({
      ...prevState,
      all_department_ids: departments,
    }));
  }

  async function getAdmins() {
    const res = await GetFacilityAdmin(null, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError(res?.message));
      history.push("/admin/settings/dashboards");
      return;
    }
    setState(prevState => ({
      ...prevState,
      all_user_ids: res.data,
    }));
  }

  async function getRegisters() {
    const res = await GetRegisters(null, true);
    if (res.status !== StatusCode.OK) {
      dispatch(
        showError(
          typeof res.data === "string" ? res.data : t("secure.facility.settings.dashboards.tiles.tiles_new.023"),
        ),
      );
      return;
    }
    setState(prevState => ({
      ...prevState,
      all_register_ids: res.data,
    }));
  }

  async function loadDashboards() {
    const res = await GetDashboard(null, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError(res.data?.message));
      history.push("/admin/settings/dashboards");
      return;
    }
    setState(prevState => ({ ...prevState, dashboards: res.data, dashboard_id: res.data[0]?.id }));
  }

  async function loadBaseTiles() {
    const res = await GetBaseTile(null, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError(res.data?.message));
      history.push("/admin/settings/dashboards");
      return;
    }

    setState(prevState => ({
      ...prevState,
      base_tiles: res.data,
      base_tile_id: res.data[0]?.id,
      selectedBaseTile: res.data[0],
      options: res.data[0]?.settings,
    }));
  }

  async function saveNewTile() {
    let missingOption = false;
    const validate = validateSelection();
    if (!validate) {
      return;
    }

    const options: { [key: string]: any } = {};
    state.options?.forEach(option => {
      if (!state[option.name]) {
        dispatch(showError(t("secure.facility.settings.dashboards.tiles.tiles_new.004") + option.name));
        missingOption = true;
        return;
      } else if (Array.isArray(state[option.name]) && state[option.name]?.length === 0) {
        dispatch(showError(t("secure.facility.settings.dashboards.tiles.tiles_new.005") + option.name + " selections")); // TODO: Translation
        missingOption = true;
        return;
      } else if (option.type === "integer") {
        options[option.name] = Number(state[option.name]);
      } else {
        options[option.name] = state[option.name];
      }
    });

    if (missingOption) {
      return;
    }

    const display_options: IDisplayOptions = {
      hide_legend: state.hide_legend,
      legend_title: state.legend_title,
      legend_position: state.legend_position,
    };

    const res = await PostTile(
      {
        title: state.title,
        dashboard_id: state.dashboard_id,
        base_tile_id: state.base_tile_id,
        settings: JSON.stringify(options),
        display_options: JSON.stringify(display_options),
      },
      true,
    );
    if (res.status !== StatusCode.OK) {
      dispatch(showError(res.data?.message));
      return;
    }

    dispatch(showSuccess(t("secure.facility.settings.dashboards.tiles.tiles_new.006")));
    history.push("/admin/settings/dashboards");
  }

  function validateSelection() {
    let validate = false;
    if (!state.title) {
      dispatch(showError(t("secure.facility.settings.dashboards.tiles.tiles_new.001")));
    } else if (!state.dashboard_id) {
      dispatch(showError(t("secure.facility.settings.dashboards.tiles.tiles_new.002")));
    } else if (!state.base_tile_id) {
      dispatch(showError(t("secure.facility.settings.dashboards.tiles.tiles_new.003")));
    } else {
      validate = true;
    }

    return validate;
  }

  function handleDropdownChange(value: number | string, id: string) {
    setState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;
    setState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleBaseTileDropdownChange(value: number, baseTile: Record<string, any>) {
    if (value && baseTile) {
      setState(prevState => ({
        ...prevState,
        base_tile_id: value,
        selectedBaseTile: baseTile,
        options: baseTile?.settings,
      }));
    }
  }

  function onCheckBoxChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value, checked, id } = e.target;
    let updateSelectedValues: any[] = [];
    if (state[id]) {
      updateSelectedValues = [...state[id]];
    }
    if (checked) {
      updateSelectedValues.push(Number(value));
    } else {
      const removeIndex = updateSelectedValues?.findIndex(valueId => valueId === Number(value));
      if (removeIndex !== -1) {
        updateSelectedValues.splice(removeIndex, 1);
      }
    }
    setState(prevState => ({ ...prevState, [id]: updateSelectedValues }));
  }

  function handleAddAll(id: string) {
    const updateSelectedValues: any[] = [];
    state[`all_${id}`]?.forEach((value: Record<string, any>) => {
      updateSelectedValues.push(value?.id);
    });
    setState(prevState => ({
      ...prevState,
      [id]: updateSelectedValues,
    }));
  }

  const primaryAction = {
    content: t("secure.facility.settings.dashboards.tiles.tiles_new.007"),
    action: saveNewTile,
  };

  return (
    <Page
      title={t("secure.facility.settings.dashboards.tiles.tiles_new.008")}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.dashboards.tiles.tiles_new.009"),
          url: "/admin/settings/dashboards",
        },
      ]}
    >
      <Card>
        <Card.Section>
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={state.title}
                  label={t("secure.facility.settings.dashboards.tiles.tiles_new.010")}
                  id="title"
                  onChange={handleInputChange}
                  placeholder={t("secure.facility.settings.dashboards.tiles.tiles_new.011")}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Select
                  label={t("secure.facility.settings.dashboards.tiles.tiles_new.012")}
                  onChange={(value: number) => handleDropdownChange(value, "dashboard_id")}
                  defaultValue={state.dashboard_id || null}
                >
                  {state.dashboards?.map((dashboard, index) => {
                    return (
                      <Option value={dashboard?.id} name={dashboard?.title} key={index}>
                        <span>{dashboard?.title}</span>
                      </Option>
                    );
                  })}
                </Select>

                <Select
                  label={t("secure.facility.settings.dashboards.tiles.tiles_new.013")}
                  onChange={(value: number, extraValues: Record<string, any>) =>
                    handleBaseTileDropdownChange(value, extraValues)
                  }
                  defaultValue={state.base_tile_id || null}
                >
                  {state.base_tiles?.map((baseTile, index) => {
                    return (
                      <Option extraValues={baseTile} value={baseTile?.id} name={baseTile?.title} key={index}>
                        <span>{baseTile?.title}</span>
                      </Option>
                    );
                  })}
                </Select>
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
        <Card.Section title={t("secure.facility.settings.dashboards.tiles.tiles_new.014")}>
          <div className="flex flex-row flex-wrap gap-8 items-center">
            <Toggle
              labelTop={t("secure.facility.settings.dashboards.tiles.tiles_new.015")}
              onChange={e => setState(prevState => ({ ...prevState, hide_legend: e.target.checked }))}
              id="hide_legend"
              checked={state.hide_legend}
            />
            <Input
              disabled={state.hide_legend}
              value={state.legend_title}
              id="legend_title"
              onChange={handleInputChange}
              label={t("secure.facility.settings.dashboards.tiles.tiles_new.016")}
            />
            <Select
              label={t("secure.facility.settings.dashboards.tiles.tiles_new.017")}
              onChange={(value: string) => handleDropdownChange(value, "legend_position")}
              defaultValue={"top"}
              disabled={state.hide_legend}
            >
              <Option value="top" name="top">
                <span>{t("secure.facility.settings.dashboards.tiles.tiles_new.018")}</span>
              </Option>
              <Option value="bottom" name="bottom">
                <span>{t("secure.facility.settings.dashboards.tiles.tiles_new.019")}</span>
              </Option>
              <Option value="left" name="left">
                <span>{t("secure.facility.settings.dashboards.tiles.tiles_new.020")}</span>
              </Option>
              <Option value="right" name="right">
                <span>{t("secure.facility.settings.dashboards.tiles.tiles_new.021")}</span>
              </Option>
            </Select>
          </div>
        </Card.Section>
        {state.options?.length > 0 && state.selectedBaseTile && (
          <Card.Section title={t("secure.facility.settings.dashboards.tiles.tiles_new.022")}>
            <div className="flex flex-row flex-wrap gap-4 items-center">
              {state.options?.map((option, index) => {
                if (option.type === "integer_array" && option.name !== "facility_ids") {
                  return (
                    <div key={index} className="mt-4">
                      <FilterDropdown
                        label={capitalize(option.name)}
                        data={state[`all_${option.name}`]}
                        dataLabelProperty={option.name === "user_ids" ? "full_name" : "title"}
                        checkBoxId={option.name}
                        dataValueProperty="id"
                        onCheckBoxChange={onCheckBoxChange}
                        onClear={() => setState(prevState => ({ ...prevState, [option.name]: [] }))}
                        onAddAll={() => handleAddAll(option.name)}
                        iconName="chevron-down"
                      />
                    </div>
                  );
                } else if (option.type === "string" && option.name === "timezone") {
                  return (
                    <Select
                      key={index}
                      label={capitalize(option.name)}
                      onChange={(value: string) => handleDropdownChange(value, option.name)}
                      defaultValue={state[option.name] || null}
                      className="w-52"
                    >
                      {state.timezones?.map((timezone, timezoneIndex) => {
                        return (
                          <Option value={timezone} name={timezone} key={timezoneIndex}>
                            <span>{timezone}</span>
                          </Option>
                        );
                      })}
                    </Select>
                  );
                } else if (option.type === "string" && option?.options) {
                  return (
                    <Select
                      key={index}
                      label={capitalize(option.name)}
                      onChange={(value: string) => handleDropdownChange(value, option.name)}
                      defaultValue={state[option.name] || null}
                      className="w-52"
                    >
                      {option.options?.map((optionValue, optionIndex) => {
                        return (
                          <Option value={optionValue} name={optionValue?.toString()} key={optionIndex}>
                            <span>{optionValue}</span>
                          </Option>
                        );
                      })}
                    </Select>
                  );
                } else if (option.type === "integer") {
                  if (option.name.includes("_id")) {
                    return (
                      <Select
                        key={index}
                        label={capitalize(option.name)}
                        onChange={(value: number) => handleDropdownChange(value, option.name)}
                        defaultValue={state[option.name] || null}
                        className="w-52"
                      >
                        {state[`all_${option.name}s`]?.map((value: Record<string, any>, valueIndex: number) => {
                          if (option.name === "facility_id") {
                            return (
                              <Option value={value.id} name={value.long_name} key={valueIndex}>
                                <span>{value.long_name}</span>
                              </Option>
                            );
                          } else {
                            return (
                              <Option value={value.id} name={value.title} key={valueIndex}>
                                <span>{value.title}</span>
                              </Option>
                            );
                          }
                        })}
                      </Select>
                    );
                  } else {
                    return (
                      <Input
                        key={index}
                        label={capitalize(option.name)}
                        value={state[option.name] || ""}
                        id={option.name}
                        onChange={handleInputChange}
                        placeholder={capitalize(option.name)}
                        type="number"
                      />
                    );
                  }
                } else if (option.type === "string") {
                  return (
                    <Input
                      key={index}
                      label={capitalize(option.name)}
                      value={state[option.name] || ""}
                      id={option.name}
                      onChange={handleInputChange}
                      placeholder={capitalize(option.name)}
                    />
                  );
                }
              })}
            </div>
          </Card.Section>
        )}
      </Card>
    </Page>
  );
}
