import { IAPIResponse, StatusCode } from "api/protocols";
import {
  GetReaderPaymentStatus,
  PutCancelServerReaderPayment,
} from "api/rpc/2022-09/facilityAdmin/payment/TeeOnPayments/reader";
import { GetOrder } from "api/rpc/facilityAdmin/order/order";
import {
  GetCardReaderStatus,
  PutCardReaderPayment,
  PutCardReaderRefund,
} from "api/rpc/facilityAdmin/payment/teeOnPayments/reader";
import Sheet from "components/sheet/Sheet";
import { store } from "index";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { IOrder, IInvoiceTransaction } from "redux/reducers/models/order";
import ProgressSteps, { IProgressStep } from "components/progressSteps/ProgressSteps";
import {
  IAccountTransaction,
  IPostAccountTransactionResponse,
  PostAccountTransaction,
  PutAccountTransactionCapture,
  PutCancelAccountTransaction,
} from "api/rpc/facilityAdmin/customer";
import {
  IPostTransactionResponse,
  PostTransaction,
  PutCancelPaymentIntent,
  PutCapture,
} from "api/rpc/2022-09/facilityAdmin/order/transaction";
import {
  PostInvoiceTransaction,
  PutCaptureInvoiceTransaction,
  PutCancelInvoiceTransaction,
} from "api/rpc/2024-04/facilityAdmin/order/invoice";
import { useAppDispatch } from "hooks/redux";
import { dequeue, enqueue, showError } from "redux/actions/ui";
import Popup from "components/popup/Popup";
import { ICartTransaction } from "redux/reducers/models/cart";
import ReactDOM from "react-dom";

export interface IStripeReaderStatus {
  id: number;
  facility_id: number;
  label: string;
  location: string;
  external_gateway: null;
  external_reader_id: string;
  serial_number: string;
  device_sw_version: string;
  device_type: string;
  status: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  external_status: string;
  external_action: {
    failure_code: null | string;
    failure_message: null | string;
    process_payment_intent: {
      payment_intent: string;
    };
    status: "online" | "in_progress" | "succeeded";
    type: string;
  };
}

interface IPaymentError {
  error: string;
  error_message: string;
}

type PaymentStatus =
  | "failed"
  | "awaiting"
  | "canceling"
  | "canceled"
  | "completed"
  | "in_progress"
  | "confirming"
  | "check_order"
  | "paid"
  | "partial_payment"
  | "retry";

type Transaction = IAccountTransaction | ICartTransaction | IInvoiceTransaction;

export function useStripeTerminalPayment() {
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>("awaiting");
  const [paymentError, setPaymentError] = useState<IPaymentError>(null);
  const [transaction, setTransaction] = useState<Transaction>(null);
  const [order, setOrder] = useState(null);
  const [sheetVisible, setSheetVisible] = useState<boolean>(false);
  const [refundSheetVisible, setRefundSheetVisible] = useState<boolean>(false);
  const _paymentStatus = useRef<string>();
  const _transaction = useRef<Transaction>(null);
  const _APIPollFlag = useRef(false);
  const dispatch = useAppDispatch();
  const [paymentStatusSheetVisible, setPaymentStatusSheetVisible] = useState<boolean>(false);
  const [cancellingState, setCancellingState] = useState<boolean>(false);

  const _disableCancel = useCallback(() => {
    let _disabled = false;

    switch (paymentStatus) {
      case "failed":
        _disabled = false;
        break;
      case "awaiting":
        _disabled = true;
        break;
      case "canceling":
        _disabled = true;
        break;
      case "canceled":
        _disabled = true;
        break;
      case "completed":
        _disabled = true;
        break;

      case "in_progress":
        _disabled = false;
        break;

      case "confirming":
        _disabled = true;
        break;
      case "check_order":
        _disabled = true;
        break;
      case "paid":
        _disabled = true;
        break;
      case "partial_payment":
        _disabled = true;
        break;
      case "retry":
        _disabled = true;
        break;
      default:
    }

    return _disabled;
  }, [paymentStatus]);

  const _disableRetry = useCallback(() => {
    let _disabled = false;

    switch (paymentStatus) {
      case "failed":
        _disabled = false;
        break;
      case "awaiting":
        _disabled = true;
        break;
      case "canceling":
        _disabled = true;
        break;
      case "canceled":
        _disabled = true;
        break;
      case "completed":
        _disabled = true;
        break;

      case "in_progress":
        _disabled = true;
        break;

      case "confirming":
        _disabled = true;
        break;
      case "check_order":
        _disabled = true;
        break;
      case "paid":
        _disabled = true;
        break;
      case "partial_payment":
        _disabled = true;
        break;
      case "retry":
        _disabled = true;
        break;
      default:
    }

    return _disabled;
  }, [paymentStatus]);

  const paymentSteps: IProgressStep[] = [
    {
      statuses: ["awaiting", "retry"],
      title: "Generating transaction",
      description: "Gathering information to create a new transaction.",
    },
    {
      statuses: ["in_progress", "canceling", "canceled", "update_status", "failed"],
      title: "Waiting for Customer",
      description: "Please wait while the customer completes their payment.",
    },
    {
      statuses: ["confirming", "check_order"],
      title: "Finalizing Transaction",
      description: "Capturing and charging the payment method.",
    },
  ];

  // Type guard for account transactions
  function isAccountTransaction(transaction: Transaction): transaction is IAccountTransaction {
    const keys = Object.keys(transaction);
    const hasAccountId = keys.find(key => key === "account_id");

    return hasAccountId !== undefined;
  }

  // Type guard for invoice transactions
  function isInvoiceTransaction(transaction: Transaction): transaction is IInvoiceTransaction {
    const keys = Object.keys(transaction);
    const hasInvoiceId = keys.find(key => key === "invoice_id");

    return hasInvoiceId !== undefined;
  }

  const refundSteps: IProgressStep[] = [
    {
      statuses: ["awaiting"],
      title: "Generating refund request",
      description: "Gathering information to create a new refund request",
    },
    {
      statuses: ["in_progress", "canceling", "canceled", "update_status", "failed"],
      title: "Waiting for Customer",
      description: "Please wait while the customer completes their refund.",
    },
    {
      statuses: ["completed"],
      title: "Finalizing Refund",
      description: "Completing the customer's refund",
    },
  ];

  const finishedStatuses: PaymentStatus[] = ["partial_payment", "paid", "completed"];
  _paymentStatus.current = paymentStatus;
  _transaction.current = transaction;

  const pollTerminal = async () => {
    const reader = store.getState().terminalStore?.reader;

    //Check if reader exists
    if (reader) {
      if (_paymentStatus.current !== "canceling" && _paymentStatus.current !== "canceled") {
        const res = await GetCardReaderStatus({ external_reader_id: reader.id });
        if (res.status === StatusCode.OK) {
          const status = res.data?.external_action ? res.data.external_action.status : res.data.status;
          const errorMessage = res.data?.external_action?.failure_message
            ? res.data?.external_action?.failure_message
            : "Reader status error";
          setPaymentStatus(status);
          if (status === "failed") {
            setPaymentError({ error: res.data?.external_action, error_message: errorMessage });
            _APIPollFlag.current = false;
            setPaymentStatusSheetVisible(true);
          }
          if (status === "succeeded") {
            setPaymentStatus("confirming");
            const paymentStatusRes = await getPaymentIntentStatus();
            if (
              paymentStatusRes?.data?.status === "requires_capture" ||
              paymentStatusRes?.data?.status === "succeeded"
            ) {
              await capturePayment();
            } else {
              ReactDOM.unstable_batchedUpdates(() => {
                setPaymentStatus("failed");
                setPaymentError({
                  error: res.data?.external_action,
                  error_message: "Error with payment. Please try again.",
                });
              });
              _APIPollFlag.current = false;
            }
          } else {
            if (_APIPollFlag.current === true) {
              await pollTerminal();
            }
          }
        }
      } else {
        console.log(paymentStatus);
        _APIPollFlag.current = false;
      }
    }
  };

  async function getPaymentIntentStatus() {
    const paymentStatusRes = await GetReaderPaymentStatus(
      { payment_intent_id: _transaction?.current?.payment_intent_id },
      false,
    );
    if (
      _paymentStatus.current !== "awaiting" &&
      paymentStatusRes?.status === StatusCode.OK &&
      paymentStatusRes?.data?.status === "processing"
    ) {
      await getPaymentIntentStatus();
    } else if (paymentStatusRes?.status === StatusCode.OK) {
      return paymentStatusRes;
    } else {
      dispatch(showError("Error getting payment intent status"));
      _APIPollFlag.current === false;
      return undefined;
    }
  }

  const capturePayment = async () => {
    if (_transaction.current !== null) {
      setPaymentStatus("confirming");

      const accountExists =
        isAccountTransaction(_transaction.current) &&
        _transaction.current.account_id !== undefined &&
        _transaction.current !== null;

      const invoiceExists =
        isInvoiceTransaction(_transaction.current) &&
        _transaction.current.invoice_id !== undefined &&
        _transaction.current !== null;

      let captureRes: IAPIResponse = undefined;

      if (accountExists) {
        captureRes = await PutAccountTransactionCapture(
          {
            payment_intent_id: (_transaction.current as IAccountTransaction).payment_intent_id,
            transaction_id: _transaction.current.id,
          },
          true,
        );
      } else if (invoiceExists) {
        captureRes = await PutCaptureInvoiceTransaction(
          {
            payment_intent_id: (_transaction.current as IInvoiceTransaction).payment_intent_id,
            transaction_id: _transaction.current.id,
          },
          true,
        );
      } else {
        captureRes = await PutCapture(
          {
            payment_intent_id: _transaction.current.payment_intent_id,
            transaction_id: _transaction.current.id,
          },
          true,
        );
      }

      if (captureRes?.status !== StatusCode.OK) {
        ReactDOM.unstable_batchedUpdates(() => {
          setPaymentError({ error: "capture_fail", error_message: "An issue occured capturing the payment" });
          setPaymentStatus("failed");
        });
      } else {
        if (accountExists || invoiceExists) {
          setPaymentStatus("completed");
        } else {
          const order = await updateOrder(captureRes.data.order_id);

          // Check Error
          if (order !== null) {
            setPaymentStatus("completed");
          }
        }
        setPaymentStatusSheetVisible(false);
        setTransaction(null);
      }
    }
  };

  const pollTerminalRefund = async (): Promise<string> => {
    const reader = store.getState().terminalStore?.reader;
    if (reader) {
      if (_paymentStatus.current !== "canceling" && _paymentStatus.current !== "canceled") {
        const res = await GetCardReaderStatus({ external_reader_id: reader.id });

        if (res.status === StatusCode.OK) {
          const status = res.data?.external_action ? res.data.external_action.status : res.data.status;
          if (status === "failed") {
            setPaymentError({ error: res.data?.external_action, error_message: "Reader status error" });
            _APIPollFlag.current = false;
          }
          if (status === "succeeded") {
            setPaymentStatus("completed");
            return "success";
          } else {
            if (_APIPollFlag.current === true) {
              return await pollTerminalRefund();
            }
          }
        }
      } else {
        _APIPollFlag.current = false;
      }
    }
  };

  const getPaymentStatus = async () => {
    const paymentStatusRes = await GetReaderPaymentStatus(
      { payment_intent_id: _transaction?.current?.payment_intent_id },
      true,
    );
    if (paymentStatusRes?.status === StatusCode.OK && paymentStatusRes?.data?.status === "requires_capture") {
      await capturePayment();
    } else {
      void dispatch(showError("Payment not received, please restart transaction"));
    }
  };

  const clearError = () => {
    setPaymentError(null);
    setPaymentStatus("awaiting");
  };

  const updateOrder = async (order_id: number): Promise<IOrder | null> => {
    setPaymentStatus("check_order");
    const orderRes = await GetOrder({ id: Number(order_id), extended: true }, true);

    if (orderRes.status !== StatusCode.OK) {
      setPaymentError({ error: "order_fail", error_message: "An error occurred updating the order." });
    } else {
      setOrder(orderRes.data[0]);
      if (orderRes.data[0].balance <= 0) {
        setPaymentStatus("paid");
        setOrder(null);
      } else {
        setPaymentStatus("partial_payment");
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return orderRes.data[0].balance;
    }

    return null;
  };

  const processPayment = async (
    order_account_id?: number,
    paymentOption?: Record<string, any>,
    finalAmount?: number,
    account?: boolean, // Using enum would allow for more payment types via terminal
    invoice?: boolean,
    league_id?: number,
    tournament_id?: number,
    hospitality_event_id?: number,
    skip_tipping?: boolean,
  ) => {
    const reader = store.getState().terminalStore?.reader;
    const facility = store.getState().facilityStore?.facility;

    setSheetVisible(true);

    if (reader && facility) {
      // Check facility for process type then process accordingly
      let transactionRes: {
        data: Transaction;
        status: number;
      };

      // Check if user is attempting to retry payment process; if so, skip to payment capture
      if (_paymentStatus.current !== "retry") {
        if (account) {
          // Account Payment
          const response = await PostAccountTransaction(
            {
              kind: "account_payment",
              amount: finalAmount,
              account_id: order_account_id,
              payment_option_id: paymentOption?.id,
            },
            false,
          );

          // Capture response
          transactionRes = {
            data: response.data,
            status: response.status,
          };
        } else if (invoice) {
          // Invoice Payment
          const response = await PostInvoiceTransaction(
            {
              kind: order_account_id ? "invoice_payment" : "deposit",
              amount: finalAmount,
              invoice_id: order_account_id,
              payment_option_id: paymentOption?.id,
              league_id: league_id,
              tournament_id: tournament_id,
              hospitality_event_id: hospitality_event_id,
            },
            false,
          );

          // Capture response
          transactionRes = {
            data: response.data,
            status: response.status,
          };
        } else {
          // Regular payment
          const response = await PostTransaction(
            {
              kind: "sale",
              amount: finalAmount,
              order_id: order_account_id,
              payment_option_id: paymentOption?.id,
            },
            false,
          );

          // Capture response
          transactionRes = {
            data: response.data,
            status: response.status,
          };
        }
      }

      // Check if transaction status is okay or if user is trying to retry payment
      if (
        transactionRes?.status === StatusCode.OK ||
        (_paymentStatus.current === "retry" && transaction !== undefined)
      ) {
        let resReaderPayment: IAPIResponse = undefined;
        let transactionData: Transaction;

        if (_paymentStatus.current === "retry") {
          transactionData = transaction;
        } else {
          transactionData = transactionRes.data;
          setTransaction(transactionData);
        }

        // Capture payment
        resReaderPayment = await PutCardReaderPayment({
          external_reader_id: reader.id,
          payment_intent_id: String(transactionData.payment_intent_id),
          skip_tipping: skip_tipping,
        });

        // Error checking
        if (resReaderPayment?.status !== StatusCode.OK) {
          setPaymentError({
            error: "reader_fail",
            error_message: "An error occoured sending the transaction to the reader.",
          });
          setPaymentStatus("failed");
        } else {
          _APIPollFlag.current = true;
          setPaymentStatus("in_progress");
          void pollTerminal();
        }
      } else {
        setPaymentStatus("failed");
        setPaymentError({ error: "transaction_fail", error_message: "An error occoured updating the transaction." });
      }

      // Get the sever id no the stripe id - There is no nice way to get an id at the moment - HARDCODED
    }
  };

  const processRefund = async (amount: number, payment_intent_id: string) => {
    const reader = store.getState().terminalStore?.reader;
    const facility = store.getState().facilityStore?.facility;

    setRefundSheetVisible(true);

    if (reader && facility) {
      const readerRefundRes = await PutCardReaderRefund({ external_reader_id: reader.id, payment_intent_id, amount });
      if (readerRefundRes?.status !== StatusCode.OK) {
        setPaymentStatus("failed");
        setPaymentError({
          error: "reader_fail",
          error_message: "An error occured sending the refund request to the reader.",
        });
        const res = await PutCancelServerReaderPayment({ external_reader_id: reader.id }, false);
        _APIPollFlag.current = false;
      } else {
        _APIPollFlag.current = true;
        setPaymentStatus("in_progress");
        return await pollTerminalRefund();
      }
    }
  };

  const toggleStripeTerminalSheet = (set?: boolean) => {
    if (set) {
      setSheetVisible(set);
    } else {
      setSheetVisible(prev => !prev);
    }
  };

  const getProgressError = () => {
    if (paymentStatus === "canceled") {
      return "Transaction canceled";
    } else if (paymentStatus === "canceling") {
      return "Canceling transaction";
    } else {
      return paymentError?.error_message;
    }
  };

  const StripeTerminalSheet = () => {
    return (
      <>
        <Sheet
          stacked
          closable
          size="small"
          open={sheetVisible}
          okText={"Cancel Payment"}
          cancelText="Retry Payment"
          okDisabled={_disableCancel()}
          cancelDisabled={_disableRetry()}
          onOk={async () => {
            // void cancelPayment();
            if (
              paymentStatus === "awaiting" ||
              paymentStatus === "in_progress" ||
              paymentStatus === "failed" ||
              paymentStatus === "retry"
            ) {
              console.log("Cancel Transaction");
              await cancelPayment();
            }
            clearError();
            // _APIPollFlag.current = false;
            setSheetVisible(false);
          }}
          okLoading={cancellingState}
          onCancel={async () => {
            await retryPayment();
          }}
          overflow
        >
          <ProgressSteps
            currentStatus={paymentStatus}
            progressSteps={paymentSteps}
            finishedStatuses={finishedStatuses}
            error={getProgressError()}
          />
        </Sheet>
        <Popup
          stacked
          open={paymentStatusSheetVisible}
          backDropCancel={false}
          okText="Check Status"
          cancelText="Close"
          onOk={getPaymentStatus}
          onCancel={() => setPaymentStatusSheetVisible(false)}
          type="info"
          title={"Payment Status Error"}
          description={"Would you like to manually check the status?"}
        />
      </>
    );
  };

  const StripeTerminalRefundSheet = () => {
    return (
      <Sheet
        stacked
        closable
        size="small"
        open={refundSheetVisible}
        title={"Process Interac Refund"}
        okText={"Close"}
        cancelText={"Cancel"}
        okDisabled={paymentError ? false : paymentStatus === "completed" ? false : true}
        cancelDisabled={_disableCancel()}
        onOk={() => {
          void clearError();
          if (paymentStatus !== "awaiting" && paymentStatus !== "failed") {
            void cancelRefund();
          }
          setRefundSheetVisible(false);
        }}
        onCancel={async () => {
          await cancelRefund();
        }}
      >
        <ProgressSteps
          currentStatus={paymentStatus}
          progressSteps={refundSteps}
          finishedStatuses={finishedStatuses}
          error={getProgressError()}
        />
      </Sheet>
    );
  };

  const cancelPayment = async () => {
    const reader = store.getState().terminalStore?.reader;

    if (
      _paymentStatus.current === "in_progress" ||
      _paymentStatus.current === "failed" ||
      _paymentStatus.current === "retry"
    ) {
      const statusRes = await GetReaderPaymentStatus(
        { payment_intent_id: _transaction.current?.payment_intent_id },
        true,
      );
      if (statusRes.status !== StatusCode.OK) {
        console.log("Checking payment status failed");
      } else if (statusRes.data.status === "succeeded") {
        // Automatically capture payment if status is actually succeeded
        await capturePayment();
        return;
      }
      _APIPollFlag.current = false;
      setPaymentStatus("canceling");
      setCancellingState(true);
      dispatch(enqueue());
      const res = await PutCancelServerReaderPayment({ external_reader_id: reader.id }, false);

      if (res.status === StatusCode.OK) {
        const accountTransaction =
          isAccountTransaction(_transaction.current) &&
          _transaction.current.account_id !== undefined &&
          _transaction.current !== null;
        const invoiceTransaction =
          isInvoiceTransaction(_transaction.current) &&
          _transaction.current.invoice_id !== undefined &&
          _transaction.current !== null;
        let cancelIntentRes;
        if (accountTransaction) {
          cancelIntentRes = await PutCancelAccountTransaction(
            { payment_intent_id: _transaction.current?.payment_intent_id, transaction_id: _transaction.current?.id },
            false,
          );
        } else if (invoiceTransaction) {
          cancelIntentRes = await PutCancelInvoiceTransaction(
            { payment_intent_id: _transaction.current?.payment_intent_id, transaction_id: _transaction.current?.id },
            false,
          );
        } else {
          cancelIntentRes = await PutCancelPaymentIntent(
            { payment_intent_id: _transaction.current?.payment_intent_id, transaction_id: _transaction.current?.id },
            false,
          );
        }

        if (cancelIntentRes?.status !== StatusCode.OK) {
          dispatch(showError("An error occured while trying to cancel the payment"));
        }

        ReactDOM.unstable_batchedUpdates(() => {
          setTransaction(null);
          setPaymentStatus("canceled");
          setSheetVisible(false);
        });
      } else {
        setPaymentStatus("failed");
        setPaymentError({
          error: "cancel_fail",
          error_message: "An error occured while trying to cancel the payment.",
        });
      }
      dispatch(dequeue());
      setCancellingState(false);
    }
  };

  const retryPayment = async () => {
    if (_paymentStatus.current === "failed") {
      const statusRes = await GetReaderPaymentStatus(
        { payment_intent_id: _transaction.current?.payment_intent_id },
        true,
      );
      if (statusRes.status !== StatusCode.OK) {
        console.log("Checking payment status failed");
      } else if (statusRes.data.status === "succeeded" || statusRes.data.status === "requires_capture") {
        // Automatically capture payment if status is actually succeeded
        await capturePayment();
        return;
      }
      setPaymentError(null);
      _APIPollFlag.current = true;
      setPaymentStatus("retry");
      _paymentStatus.current = "retry";
      await processPayment();
    }
  };

  const cancelRefund = async () => {
    const reader = store.getState().terminalStore?.reader;

    if (_paymentStatus.current === "in_progress") {
      setPaymentStatus("canceling");
      const res = await PutCancelServerReaderPayment({ external_reader_id: reader.id }, false);
      _APIPollFlag.current = false;

      if (res.status === StatusCode.OK) {
        setPaymentStatus("canceled");
        setRefundSheetVisible(false);
        void clearError();
      } else {
        setPaymentStatus("failed");
        setPaymentError({
          error: "cancel_fail",
          error_message: "An error occured while trying to cancel the payment.",
        });
      }
    }
  };

  useEffect(() => {
    console.log("Payment status", paymentStatus, transaction, order);

    if (paymentStatus === "completed") {
      setPaymentStatus("awaiting");
      setTransaction(null);
      setOrder(null);
      setSheetVisible(false);
      setRefundSheetVisible(false);
      setPaymentError(null);
    }
  }, [transaction, paymentStatus, order]);

  return {
    StripeTerminalSheet,
    StripeTerminalRefundSheet,
    processPayment,
    processRefund,
    clearError,
    cancelPayment,
    toggleStripeTerminalSheet,
    paymentStatus,
    paymentError,
    order,
  };
}
