import * as InternalBookingEngineActions from "./bookingEngine";

export type IBookingEngineActions = typeof InternalBookingEngineActions;

export enum AuthActionTypes {
  USER_LOGIN = "USER_LOGIN",
  USER_LOGOUT = "USER_LOGOUT",
  USER_INITIALIZE = "USER_INITIALIZE",
  USER_PERMISSIONS_CHANGE = "USER_PERMISSIONS_CHANGE",
}

export enum TeesheetActionTypes {
  LOAD_TEETIMES_SUCCESS_FACILITY = "LOAD_TEETIMES_SUCCESS_FACILITY",
  LOAD_TEETIMES_ERROR_FACILITY = "LOAD_TEETIMES_ERROR_FACILITY",
  SELECT_DATE_FACILITY = "SELECT_DATE_FACILITY",

  LOAD_TEETIMES_SUCCESS_CLIENT = "LOAD_TEETIMES_SUCCESS_CLIENT",
  LOAD_TEETIMES_ERROR_CLIENT = "LOAD_TEETIMES_ERROR_CLIENT",
  SELECT_DATE_CLIENT = "SELECT_DATE_CLIENT",

  LOAD_TEETIMES_SUCCESS_MASTER = "LOAD_TEETIMES_SUCCESS_MASTER",
  LOAD_TEETIMES_ERROR_MASTER = "LOAD_TEETIMES_ERROR_MASTER",
  SELECT_DATE_MASTER = "SELECT_DATE_MASTER",

  UPDATE_SELECTED_MENU_FACILITY = "UPDATE_SELECTED_MENU_FACILITY",
  UPDATE_SELECTED_CART_FACILITY = "UPDATE_SELECTED_CART_FACILITY",
  UPDATE_SELECTED_TEETIME_SUCCESS_FACILITY = "UPDATE_SELECTED_TEETIME_SUCCESS_FACILITY",
  UPDATE_SELECTED_TEETIME_ERROR_FACILITY = "UPDATE_SELECTED_TEETIME_ERROR_FACILITY",
  UPDATE_BOOKING_TEMPORARY_FACILITY = "UPDATE_BOOKING_TEMPORARY_FACILITY",

  UPDATE_SELECTED_MENU_CLIENT = "UPDATE_SELECTED_MENU_CLIENT",
  UPDATE_SELECTED_CART_CLIENT = "UPDATE_SELECTED_CART_CLIENT",
  UPDATE_SELECTED_TEETIME_SUCCESS_CLIENT = "UPDATE_SELECTED_TEETIME_SUCCESS_CLIENT",
  UPDATE_SELECTED_TEETIME_ERROR_CLIENT = "UPDATE_SELECTED_TEETIME_ERROR_CLIENT",
  UPDATE_BOOKING_TEMPORARY_CLIENT = "UPDATE_BOOKING_TEMPORARY_CLIENT",

  UPDATE_SELECTED_MENU_MASTER = "UPDATE_SELECTED_MENU_MASTER",
  UPDATE_SELECTED_CART_MASTER = "UPDATE_SELECTED_CART_MASTER",
  UPDATE_SELECTED_TEETIME_SUCCESS_MASTER = "UPDATE_SELECTED_TEETIME_SUCCESS_MASTER",
  UPDATE_SELECTED_TEETIME_ERROR_MASTER = "UPDATE_SELECTED_TEETIME_ERROR_MASTER",
  UPDATE_BOOKING_TEMPORARY_MASTER = "UPDATE_BOOKING_TEMPORARY_MASTER",
}

export enum BookingEngineActionTypes {
  UPDATE = "UPDATE",
}
