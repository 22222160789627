import { APIGet, APIPost, APIPut, APIDelete, APIUrl, AdminType } from "api/protocols";
import { CancelToken } from "axios";
import { TBankAccountExternal, TBankAccountType } from "redux/reducers/models/facility";

const apiVersion = "2024-04";
const adminType = "facility-admin";

export type TGetExternalBankAccount = {
  id?: string | number;
};

export type TPostExternalBankAccount = {
  type: TBankAccountType;
  currency: string;
  currency_default: boolean;
  account_number: string;
  routing_number: string;
  //country = facility.country_code
};

export async function GetBankAccount(params: TGetExternalBankAccount, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<TBankAccountExternal[]>(
    APIUrl(AdminType.FacilityAdmin, "/facility/external-account"),
    params,
    useGlobalLoader,
    token,
  );
}

export async function PostBankAccount(params: TPostExternalBankAccount, useGlobalLoader: boolean) {
  return await APIPost(APIUrl(AdminType.FacilityAdmin, "/facility/external-account"), params, useGlobalLoader);
}

/** Must be authorized & cannot be a default bank account. */
export async function DeleteBankAccount(params: { id: string | number }, useGlobalLoader: boolean) {
  return await APIDelete(APIUrl(AdminType.FacilityAdmin, "/facility/external-account"), params, useGlobalLoader);
}
