import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Clients from "pages/secure/master/client/Clients";
import * as ClientActions from "redux/actions/masterAdmin/client";
import * as FacilityActions from "redux/actions/masterAdmin/facility";

const mapDispatchToProps = (dispatch: any) => {
  return {
    clientActions: bindActionCreators(
      {
        ...ClientActions,
      },
      dispatch,
    ),
  };
};

const mapStateToProps = (state: any) => {
  const { masterClientStore } = state;
  return {
    masterClientStore: { ...masterClientStore },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
