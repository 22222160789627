import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetOrder } from "api/rpc/2022-09/clientAdmin/order/order";
import { IOrder } from "redux/reducers/models/order";

import { useOrderFilters } from "hooks/useOrderFilters/useDropFilters";
import { useCustomerClientContext } from "../../context/CustomerClientContext";

import Card from "components/card/Card";
import Search from "components/search/Search";
import OrdersTable from "components/orderTable/OrdersTable";

import { IOrderFilterStateClient } from "pages/secure/client/order/Orders";
import OrderFiltersClient from "pages/secure/client/order/OrderFiltersClient";

import "../../../../facility/customer/tabs/itemSales/customerItemSales.scss";

type CustomerItemFilters = {
  search: string;
  limit: number;
  offset: number;
};

export default function CustomerItemSales() {
  const { t } = useTranslation();

  const { state } = useCustomerClientContext();
  const orderFilters = useOrderFilters();

  const [orders, setOrders] = useState<IOrder[]>(undefined);
  const [filters, setFilters] = useState<CustomerItemFilters>({
    search: "",
    limit: 50,
    offset: 0,
  });

  const [filterState, setFilterState] = useState<IOrderFilterStateClient>({
    all: undefined,
    date: [],
    financial_status: [],
    sales_channels: [],
    facility_ids: [],
    credit_card: [],
  });

  // Reload orders after search || offset changes.
  useEffect(() => {
    if (filterState.all === undefined) {
      return;
    }

    const source = axios.CancelToken.source();
    void loadOrders(source.token);

    return () => {
      source.cancel();
    };
  }, [filters, filterState.all]);

  async function loadOrders(token?: CancelToken) {
    if (orders !== undefined) {
      setOrders(undefined);
    }

    const orderRes = await GetOrder(
      {
        customer_id: state.customer.id,
        ...filters,
        filters: filterState.all?.length > 0 ? filterState.all : undefined,
      },
      false,
      token,
    );

    if (token.reason) {
      setOrders(undefined); // front-end refresh
      return;
    }

    if (orderRes.status !== StatusCode.OK) {
      setOrders([]);
    } else {
      setOrders(orderRes.data);
    }
  }

  return (
    <div className="mt-2 mb-4">
      <h1 className="ui-customer_page-header ui-customer-item-sales-header">Item Sales</h1>
      <OrderFiltersClient
        filters={filterState}
        setFilters={setFilterState}
        filterGroups={{
          date: orderFilters.dateFilters,
          financial: orderFilters.financialStatusFilters,
          salesChannels: orderFilters.salesChannelFilters,
          facilities: state.facilityFilters,
          creditCard: orderFilters.creditCardFilters,
        }}
        historyKey="client_customer_order_filters"
        dropFilterHistoryKeyPrefix="client_customer_orders"
      />
      <div className="flex-grow mb-4 mt-4">
        <Search
          historyKey={"customer-item-sales-search-client"}
          searchCallback={searchValue => setFilters({ ...filters, offset: 0, search: searchValue })}
          placeholder="Search"
        />
      </div>
      <Card>
        <Card.Section table="true">
          <OrdersTable
            orders={orders}
            tableLimit={filters.limit}
            tableOffset={filters.offset}
            handleTableOffset={direction =>
              setFilters(prev => ({
                ...prev,
                offset: direction === "prev" ? prev.offset - prev.limit : prev.offset + prev.limit,
              }))
            }
          />
        </Card.Section>
      </Card>
    </div>
  );
}
