import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { IFacility } from "redux/reducers/models/facility";

const apiVersion = "2024-04";
const adminType = "master-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/client";

interface IGetClient {
  id?: number;
  extended?: boolean;
  search?: string;
  limit?: number;
  offset?: number;
}

// GET Client
export async function GetClient(params: IGetClient, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseUrl, params, useGlobalLoader, token);
}

// PUT Client
export async function PutClient(params: any, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIPut(baseUrl, params, useGlobalLoader, token);
}

// POST Client
export async function PostClient(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}
