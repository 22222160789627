import React, { useEffect, useState } from "react";
import { IFacilityActions } from "redux/actions/clientAdmin/facility";
import { IUIActions } from "redux/actions/ui";
import { IClientState } from "redux/reducers/client";
import { IClientFacilityStore } from "redux/reducers/clientAdmin/facility";
import { Select } from "components/select/index";
import { IFacility } from "redux/reducers/models/facility";
import "./clientSettingsHeader.scss";

interface IProps {
  uiActions: IUIActions;
  clientFacilityStore: IClientFacilityStore;
  facilityActions: IFacilityActions;
  clientStore: IClientState;
  children: React.ReactNode;
}

export default function ClientSettingsHeader(props: IProps) {
  const { uiActions, clientFacilityStore, facilityActions, clientStore, children } = props;
  const { Option } = Select;

  function handleFacilityChange(facilityId: number, facility: Record<string, any>) {
    if (facility) {
      facilityActions.setAdminFacility(facility);
    }
  }

  return (
    <>
      <div className="client-settings-header">
        {clientFacilityStore?.facilities && (
          <div className="client-settings-header-dropdown">
            <Select
              placeholder="Facility"
              onChange={handleFacilityChange}
              defaultValue={clientFacilityStore?.facility?.id}
            >
              {clientFacilityStore?.facilities
                ?.sort((a: IFacility, b: IFacility) => a.full_name.localeCompare(b.full_name))
                ?.map((facility: any, i: any) => {
                  return (
                    <Option key={i} value={facility.id} name={facility.full_name} extraValues={facility}>
                      <span>{facility.long_name}</span>
                    </Option>
                  );
                })}
            </Select>
          </div>
        )}
      </div>
      <div>{children}</div>
    </>
  );
}
