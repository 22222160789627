import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { StatusCode } from "api/protocols";
import { PostCustomerType, TPostCustomerType } from "api/rpc/2024-04/clientAdmin/customer/type";

import { showError, showSuccess } from "redux/actions/ui";
import { handleChangeEventInput } from "helpers/Helpers";
import { useAppDispatch } from "hooks/redux";

import Card from "components/card/Card";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import Page from "components/page/Page";
import { Select } from "components/select/index";

export default function CustomerTypesNew() {
  const history = useHistory();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [type, setType] = useState<TPostCustomerType>({
    title: "",
    subtitle: "",
    application: "",
  });

  const applicationOptions = [
    {
      label: t("secure.facility.settings.customer_type.customer_type_new.001"),
      value: "green_fee",
    },
    {
      label: t("secure.facility.settings.customer_type.customer_type_new.002"),
      value: "power_cart",
    },
  ];

  async function saveNewCustomerType() {
    const res = await PostCustomerType(type, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error creating new customer type."));
      return;
    }

    dispatch(showSuccess("Successfully created customer type."));
    history.push("/admin/settings/client-settings/customer_types");
  }

  const primaryAction = {
    content: t("secure.facility.settings.customer_type.customer_type_new.003"),
    action: () => saveNewCustomerType(),
  };

  return (
    <Page
      title={t("secure.facility.settings.customer_type.customer_type_new.004")}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.customer_type.customer_type_new.005"),
          url: "/admin/settings/client-settings/customer_types",
        },
      ]}
    >
      <Card>
        <Card.Section>
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  id="title"
                  value={type.title}
                  label={t("secure.facility.settings.customer_type.customer_type_new.006")}
                  onChange={e => handleChangeEventInput(e, type, setType)}
                  placeholder={t("secure.facility.settings.customer_type.customer_type_new.007")}
                />
                <Input
                  id="subtitle"
                  value={type.subtitle}
                  label={t("secure.facility.settings.customer_type.customer_type_new.008")}
                  onChange={e => handleChangeEventInput(e, type, setType)}
                  placeholder={t("secure.facility.settings.customer_type.customer_type_new.009")}
                />
                <Select
                  label={t("secure.facility.settings.customer_type.customer_type_new.010")}
                  className={`flex justify-center align-center w-full h-10 position-relative z-20 text-black font-medium appearance-none border-none focus:outline-none placeholder-gray-200`}
                  onChange={(value: string) => setType(prev => ({ ...prev, application: value }))}
                >
                  {applicationOptions.map(option => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
      </Card>
    </Page>
  );
}
