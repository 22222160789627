import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  searchHistoryAddItem,
  searchHistoryCreate,
  searchHistoryRemoveItem,
  searchHistoryUpdateSearch,
} from "redux/actions/searchBars/searchHistory";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import useOutsideAlerter from "hooks/useOutsideAlerter/useOutsideAlerter";
import { useRouteContext } from "routes/RouteContext";
import Input from "components/form/input/Input";
import { useTranslation } from "react-i18next";

import "./search.scss";

interface ISearchProps {
  historyKey: string;
  /** searchValue returned after automatic debounced time */
  searchCallback: (searchValue: string) => void;
  showDropdown?: boolean;
  restrictPresetToRoutes?: Array<string>;
  placeholder?: string;
  inputDisabled?: boolean | false;
  /** In milliseconds */
  debounceTime?: number;
  /** Display element left of input. */
  leftIcon?: any;
}

/**
 * Directly manipulate search value with::  dispatch(searchHistoryAction(historyKey, value))
 * @param historyKey String value to organize redux store.  Necessary to organize redux search history across multiple components.  Should be unique.
 * @param searchCallback Async callback function to handle API calls using the input string after a debounce time.
 * @param showDropdown Optional boolean value determining if search history dropdown is shown.
 * @param restrictPresetToRoutes Optional string array used to determine a restricted route(s) for the search preset value
 * @param placeholder Optional string for input placeholder value
 * @param inputDisabled Optional boolean value to determine when input is disabled
 * @param debounceTime Optional number value representing the millisecond delay before callback value.  Defaults to 0.75ms
 */
export default function Search(props: ISearchProps) {
  const {
    historyKey,
    searchCallback,
    showDropdown,
    restrictPresetToRoutes,
    placeholder,
    inputDisabled,
    debounceTime = 750,
    leftIcon,
  } = props;
  const searchHistoryStore = useAppSelector(store => store.searchHistoryStore);
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState(false);

  const history = useHistory();
  const route = useRouteContext();
  const [searchTrigger, setSearchTrigger] = useState(false);

  useOutsideAlerter(dropdownRef, () => {
    setVisible(false);
  });

  //initialize redux storage with historyKey prop
  useEffect(() => {
    if (searchHistoryStore[historyKey] === undefined) {
      dispatch(searchHistoryCreate(historyKey));
    }
  }, [historyKey]);

  //determine what initial value to display on input
  useEffect(() => {
    if (route.navigatedTo !== history.location.pathname) {
      return;
    }

    const routeCheck = restrictPresetToRoutes
      ?.map(routeString => {
        if (route.navigatedFrom.includes(routeString)) {
          return route;
        } else {
          undefined;
        }
      })
      .filter(item => item !== undefined);

    //restrictPresetToRoutes defined, but no routes were found
    if (routeCheck && routeCheck.length === 0) {
      dispatch(searchHistoryUpdateSearch(historyKey, ""));
    }

    setSearchTrigger(true);
  }, [route]);

  //search side-effect debounce
  useEffect(() => {
    if (searchHistoryStore[historyKey] === undefined || !searchTrigger) {
      return;
    }

    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (() => {
          try {
            if (mounted) {
              const currentSearch = searchHistoryStore[historyKey].currentSearch;

              // callback function and update redux
              void searchCallback(currentSearch);
              dispatch(searchHistoryAddItem(historyKey, currentSearch));
            }
          } catch (error) {
            console.log("searchError", error);
          }
          return;
        })();
      }, debounceTime);
    };

    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [searchHistoryStore[historyKey]?.currentSearch, searchTrigger]);

  //search using history item
  function handleSearchHistoryClick(prevValue: string) {
    dispatch(searchHistoryUpdateSearch(historyKey, prevValue));
    setVisible(false);
  }

  return (
    <div ref={dropdownRef} className="search">
      <ul>
        <li tabIndex={1}>
          {searchHistoryStore[historyKey] && (
            <>
              <Input
                onFocus={() => setVisible(true)}
                value={searchHistoryStore[historyKey].currentSearch}
                onChange={(event: any) => dispatch(searchHistoryUpdateSearch(historyKey, event.target.value))}
                type="search"
                placeholder={placeholder && placeholder.length > 0 ? placeholder : t("components.search.search.001")}
                disabled={inputDisabled}
                prefix={leftIcon ? leftIcon : null}
              />
              {visible && showDropdown ? (
                <div className="bar_structure">
                  <SearchHistory
                    previousSearches={Array.from(searchHistoryStore[historyKey].history)}
                    onHistorySelect={prevValue => handleSearchHistoryClick(prevValue)}
                    onHistoryDelete={historyValue => dispatch(searchHistoryRemoveItem(historyKey, historyValue))}
                  />
                </div>
              ) : null}
            </>
          )}
        </li>
      </ul>
    </div>
  );
}

const SearchHistory = (props: {
  previousSearches: Array<string>;
  onHistorySelect: (prevValue: string) => void;
  onHistoryDelete: (item: string) => void;
}) => {
  const { previousSearches, onHistorySelect, onHistoryDelete } = props;

  function handleOnHistoryDelete(event: React.MouseEvent<SVGSVGElement, MouseEvent>) {
    event.stopPropagation();
    void onHistoryDelete(event.currentTarget.id);
  }

  return (
    <ul className="search_history">
      {previousSearches.map((prevValue, index: number) => {
        // history set should not display empty values
        return (
          prevValue.length !== 0 && (
            <li key={index} className="search_history-wrap" onClick={() => onHistorySelect(prevValue)}>
              <p>{prevValue}</p>
              <FontAwesomeIcon
                id={prevValue}
                icon={["fal", "times"]}
                className="close_icon"
                onClick={event => handleOnHistoryDelete(event)}
              />
            </li>
          )
        );
      })}
    </ul>
  );
};
