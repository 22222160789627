import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { showError, showSuccess } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";

import { StatusCode } from "api/protocols";
import {
  GetEmailTemplate,
  PutEmailTemplate,
  DeleteEmailTemplate,
} from "api/rpc/2024-04/facilityAdmin/facility/emailTemplate";

import Page from "components/page/Page";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import TextEditor from "components/textEditor/textEditor";
import Popup from "components/popup/Popup";
import { Select } from "components/select";

interface IEditEmailTemplateState {
  title: string;
  type: "tee_sheet" | "league_announcement";
  subject: string;
  content: string;
  deletePopupVisible: boolean;
}

export default function EmailTemplateEdit(props: any) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { templateId } = useParams<{ templateId: string }>();

  const [editTemplateState, setEditTemplateState] = useState<IEditEmailTemplateState>({
    title: "",
    type: undefined,
    subject: "",
    content: "",
    deletePopupVisible: false,
  });

  const templateTypes = [
    { label: "Tee Sheet", value: "tee_sheet" },
    { label: "League Announcement", value: "league_announcement" },
  ];

  useEffect(() => {
    void loadTemplate();
  }, []);

  async function loadTemplate() {
    const templateRes = await GetEmailTemplate({ id: Number(templateId) }, true);

    if (templateRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading email template")); // TODO: Translation
      return;
    }

    setEditTemplateState(prev => ({
      ...prev,
      title: templateRes.data[0].title,
      type: templateRes.data[0].type,
      subject: templateRes.data[0].subject,
      content: templateRes.data[0].content,
    }));
  }

  async function updateTemplate() {
    const templateRes = await PutEmailTemplate(
      {
        id: Number(templateId),
        title: editTemplateState.title,
        type: editTemplateState.type,
        subject: editTemplateState.subject,
        content: editTemplateState.content,
      },
      true,
    );

    if (templateRes.status !== StatusCode.OK) {
      dispatch(showError("Error updating email template")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Email template updated successfully")); // TODO: Translation
    history.push("/admin/settings/email-template");
  }

  async function deleteTemplate() {
    const templateRes = await DeleteEmailTemplate(
      {
        id: Number(templateId),
      },
      true,
    );

    if (templateRes.status !== StatusCode.OK) {
      dispatch(showError("Error deleting email template")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Email template deleted successfully")); // TODO: Translation
    history.push("/admin/settings/tee-sheet/email-template");
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setEditTemplateState(prev => ({ ...prev, [id]: value }));
  }

  const primaryAction = {
    content: "Update", // TODO: Translation
    action: updateTemplate,
  };

  const secondaryActions = [
    {
      content: "Delete", // TODO: Translation
      action: () => setEditTemplateState(prev => ({ ...prev, deletePopupVisible: true })),
    },
  ];

  return (
    <Page
      title="Edit Email Template" // TODO: Translation
      subtitle={editTemplateState.title}
      narrow
      primaryAction={primaryAction}
      secondaryActions={secondaryActions}
      breadcrumbs={[
        {
          prefix: true,
          label: "Email templates" /* TODO: Translation */,
          url: "/admin/settings/email-template",
        },
      ]}
    >
      <Card>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Input
                value={editTemplateState.title}
                label={"Title" /* TODO: Translation */}
                id="title"
                onChange={handleInputChange}
              />
              <Input
                value={editTemplateState.subject}
                label={"Subject" /* TODO: Translation */}
                id="subject"
                onChange={handleInputChange}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <Select
                defaultValue={editTemplateState.type}
                label="Type" // TODO: Translation
                onChange={value => setEditTemplateState(prev => ({ ...prev, type: value }))}
              >
                {templateTypes.map(type => (
                  <Select.Option key={type.value} value={type.value}>
                    {type.label}
                  </Select.Option>
                ))}
              </Select>
            </FormLayout.Group>
            <FormLayout.Group>
              <TextEditor
                markdownText={editTemplateState.content ?? ""}
                markdownTextOnChange={(newContent: string) =>
                  setEditTemplateState(prev => ({ ...prev, content: newContent }))
                }
                label={"Content" /* TODO: Translation */}
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>

      <Popup
        open={editTemplateState.deletePopupVisible}
        type="warning"
        title="Delete Email Template?" // TODO: Translation
        description={"Are you sure you want to delete this email template? This cannot be undone."} // TODO: Translation
        onOk={deleteTemplate}
        onCancel={() => setEditTemplateState(prev => ({ ...prev, deletePopupVisible: false }))}
        okText="Delete" // TODO: Translation
      />
    </Page>
  );
}
