import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { IVariantModifier } from "pages/secure/facility/settings/modifierGroup/ModifierGroupEdit";

const apiVersion = "2024-04";
const adminType = "master-admin";
const baseModifierGroupUrl = "/" + apiVersion + "/" + adminType + "/product/modifier-group";

export interface IModifierGroup {
  id?: number;
  title?: string;
  variants?: IVariantModifier[];
  multi_select?: boolean;
  required?: boolean;
  facility_id: number;
}

interface IModifierGroupVariants {
  modifier_group_id?: number;
  variants?: IVariantModifier[];
  facility_id: number;
  client_id: number;
}

export async function GetModifierGroup(
  params: { id?: number; product_id?: number; extended?: boolean; facility_id: number },
  useGlobalLoader: boolean,
) {
  return await APIGet(baseModifierGroupUrl, params, useGlobalLoader);
}

export async function PostModifierGroup(params: IModifierGroup, useGlobalLoader: boolean) {
  return await APIPost(baseModifierGroupUrl, params, useGlobalLoader);
}

export async function PutModifierGroup(params: IModifierGroup, useGlobalLoader: boolean) {
  return await APIPut(baseModifierGroupUrl, params, useGlobalLoader);
}

export async function DeleteModifierGroup(params: IModifierGroup, useGlobalLoader: boolean) {
  return await APIDelete(baseModifierGroupUrl, params, useGlobalLoader);
}

export async function PostModifierGroupVariants(params: IModifierGroupVariants, useGlobalLoader: boolean) {
  return await APIPost(baseModifierGroupUrl + "/variant", params, useGlobalLoader);
}
