import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "./Checkout.scss";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import { Select } from "components/select/index";
import TransactionHeader from "./TransactionalHeader/TransactionalHeader";
import { ICartState } from "redux/reducers/customer/cart";
import { ICartActions } from "redux/actions/customer/cart";
import { PostAddressLine, PostCustomer } from "api/rpc/guest/customer";
import { StatusCode } from "api/protocols";
import { GetCountry } from "api/rpc/country";
import { IUIActions } from "redux/actions/ui";
import { GetFacility } from "api/rpc/guest/facility";
import { IFacility } from "../models/facility";
import { StorePage } from "./StorePage/StorePage";
import validator from "validator";
import { ICart } from "redux/reducers/customer/models/cart";
import OrderSummary from "components/OrderSummary/OrderSummary";
import { useTranslation } from "react-i18next";
import { getOnlineStoreCartToken, getOnlineStoreCartTokenName } from "./HomePage";
import { ICountry, IProvince } from "redux/reducers/models/customer";

interface ICheckoutProps {
  cartStore: ICartState;
  cartActions: ICartActions;
  uiActions: IUIActions;
}

interface ICheckoutInformationField {
  data: string;
  isDirty: boolean;
  isValid: boolean;
  ref: React.MutableRefObject<HTMLElement>;
}

interface ICheckoutInformation {
  countries: ICountry[];
  provinces: IProvince[];
  facility: IFacility;
  firstNameField: ICheckoutInformationField;
  lastNameField: ICheckoutInformationField;
  emailAddressField: ICheckoutInformationField;
  phoneNumberField: ICheckoutInformationField;
  addressLine1Field: ICheckoutInformationField;
  addressLine2: string;
  cityField: ICheckoutInformationField;
  country_id: number;
  province_id: number;
  postalCodeField: ICheckoutInformationField;
  customerNotes: string;
  customerNotesRef: React.MutableRefObject<HTMLElement>;
}

export type ScrollLocation = "email" | "address" | "notes";

function isScrollLocation(value: string): value is ScrollLocation {
  return ["email", "address", "notes"].includes(value);
}

export default function Checkout(props: ICheckoutProps) {
  const { facilityShortName, scrollLocation } = useParams<{ facilityShortName: string; scrollLocation: string }>();

  const { cartStore, cartActions, uiActions } = props;

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { Option } = Select;

  const [checkoutInformation, setCheckoutInformation] = useState<ICheckoutInformation>({
    countries: undefined,
    provinces: undefined,
    facility: undefined,
    firstNameField: { data: "", isDirty: false, isValid: false, ref: useRef<HTMLElement>(null) },
    lastNameField: { data: "", isDirty: false, isValid: false, ref: useRef<HTMLElement>(null) },
    emailAddressField: { data: "", isDirty: false, isValid: false, ref: useRef<HTMLElement>(null) },
    phoneNumberField: { data: "", isDirty: false, isValid: false, ref: useRef<HTMLElement>(null) },
    addressLine1Field: { data: "", isDirty: false, isValid: false, ref: useRef<HTMLElement>(null) },
    addressLine2: "",
    cityField: { data: "", isDirty: false, isValid: false, ref: useRef<HTMLElement>(null) },
    country_id: undefined,
    province_id: undefined,
    postalCodeField: { data: "", isDirty: false, isValid: false, ref: useRef<HTMLElement>(null) },
    customerNotes: "",
    customerNotesRef: useRef<HTMLElement>(null),
  });

  function navigateToHomePage() {
    history.push(`/online-store/${facilityShortName}`);
  }

  function navigateToForms() {
    history.push(`/online-store/${facilityShortName}/forms`);
  }

  function scrollToLocation(location: ScrollLocation) {
    switch (location) {
      case "email":
        checkoutInformation?.emailAddressField?.ref?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
        break;
      case "address":
        checkoutInformation?.addressLine1Field?.ref?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
        break;
      case "notes":
        checkoutInformation?.customerNotesRef?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
        break;
    }
  }

  useEffect(() => {
    void loadCheckout();
  }, []);

  async function loadCheckout() {
    uiActions.enqueue();

    try {
      const facilityRes = await GetFacility({ short_name: facilityShortName }, false);
      const facilityId = facilityRes?.data?.[0]?.id;

      if (facilityRes.status !== StatusCode.OK || facilityId === undefined) {
        uiActions.showError(t("guest.online_store.checkout.018"));
        uiActions.dequeue();
        navigateToHomePage();
        return;
      }

      let cart: ICart = undefined;

      if (getOnlineStoreCartToken(facilityShortName)) {
        try {
          const getCartPromise: Promise<ICart> = cartStore?.isLoaded
            ? Promise.resolve(cartStore?.cart)
            : (cartActions.loadCart(
                {
                  facility_id: facilityId,
                  tokenName: getOnlineStoreCartTokenName(facilityShortName),
                  token: getOnlineStoreCartToken(facilityShortName),
                },
                false,
              ) as unknown as Promise<ICart>);

          cart = await getCartPromise;
        } catch {
          uiActions.showError(t("guest.online_store.checkout.019"));
          uiActions.dequeue();
          navigateToHomePage();
          return;
        }
      } else {
        uiActions.dequeue();
        navigateToHomePage();
        return;
      }

      if (cart === undefined || cart.line_items.length === 0) {
        uiActions.dequeue();
        navigateToHomePage();
        return;
      } else if (cart.status === "complete") {
        uiActions.dequeue();
        cancelTransaction();
        return;
      }

      const customerExists = Boolean(cart.customer);
      const billingAddressLineExists = Boolean(cart.billing_address_line);

      const countryRes = await GetCountry({ provinces: true }, false);

      if (countryRes.status !== StatusCode.OK) {
        uiActions.showError(t("guest.online_store.checkout.001"));
        uiActions.dequeue();
        return;
      }

      setCheckoutInformation(prev => ({
        ...prev,
        facility: facilityRes.data[0],
        countries: countryRes.data,
        provinces: countryRes.data?.[0]?.provinces ?? [],

        firstNameField: customerExists
          ? {
              ...prev.firstNameField,
              data: cart.customer.first_name,
              isValid: requiredValueExists(cart.customer.first_name),
            }
          : prev.firstNameField,
        lastNameField: customerExists
          ? {
              ...prev.lastNameField,
              data: cart.customer.last_name,
              isValid: requiredValueExists(cart.customer.last_name),
            }
          : prev.lastNameField,
        emailAddressField: customerExists
          ? {
              ...prev.emailAddressField,
              data: cart.customer.email,
              isValid: emailIsValid(cart.customer.email),
            }
          : prev.emailAddressField,

        phoneNumberField: billingAddressLineExists
          ? {
              ...prev.phoneNumberField,
              data: cart.billing_address_line.phone,
              isValid: phoneNumberIsValid(cart.billing_address_line.phone),
            }
          : prev.phoneNumberField,
        addressLine1Field: billingAddressLineExists
          ? {
              ...prev.addressLine1Field,
              data: cart.billing_address_line.address_line_1,
              isValid: requiredValueExists(cart.billing_address_line.address_line_1),
            }
          : prev.addressLine1Field,

        addressLine2: billingAddressLineExists ? cart.billing_address_line.address_line_2 ?? "" : prev.addressLine2,
        country_id: billingAddressLineExists ? cart.billing_address_line.country_id : facilityRes.data[0].country_id,
        province_id: billingAddressLineExists ? cart.billing_address_line.province_id : facilityRes.data[0].province_id,

        cityField: billingAddressLineExists
          ? {
              ...prev.cityField,
              data: cart.billing_address_line.city,
              isValid: requiredValueExists(cart.billing_address_line.city),
            }
          : prev.cityField,
        postalCodeField: billingAddressLineExists
          ? {
              ...prev.postalCodeField,
              data: cart.billing_address_line.postal,
              isValid: requiredValueExists(cart.billing_address_line.postal),
            }
          : prev.postalCodeField,
        customerNotes: cart.customer_notes ?? prev.customerNotes,
      }));

      if (isScrollLocation(scrollLocation)) {
        scrollToLocation(scrollLocation);
      }

      uiActions.dequeue();
    } catch {
      uiActions.dequeue();
    }
  }

  function cancelTransaction() {
    cartActions.cartClear({ tokenName: getOnlineStoreCartTokenName(facilityShortName) });
    navigateToHomePage();
  }

  function handleInputFieldChange(id: string, value: string, isValid: boolean) {
    if (id === undefined || value === undefined || isValid === undefined) {
      return;
    }

    setCheckoutInformation(previousCheckoutInformation => {
      return {
        ...previousCheckoutInformation,
        [id]: {
          ...(previousCheckoutInformation[id as keyof ICheckoutInformation] as Record<string, any>),
          data: value,
          isValid: isValid,
          isDirty: true,
        },
      };
    });
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setCheckoutInformation(prev => ({ ...prev, [id]: value }));
  }

  function handleOptionalInputChange(id: string, value: string) {
    if (id === undefined || value === undefined) {
      return;
    }

    setCheckoutInformation(previousCheckoutInformation => {
      return {
        ...previousCheckoutInformation,
        [id]: value,
      };
    });
  }

  function handleSelectChange(id: string, value: number) {
    if (id === undefined || value === undefined) {
      return;
    }

    setCheckoutInformation(previousCheckoutInformation => ({ ...previousCheckoutInformation, [id]: value }));
  }

  function handleCountryChange(value: number) {
    const nextCountry = checkoutInformation.countries.find(country => country.id === value);
    setCheckoutInformation(previousCheckoutInformation => ({
      ...previousCheckoutInformation,
      country_id: value,
      provinces: nextCountry.provinces ?? [],
      province_id: nextCountry.provinces?.[0]?.id,
    }));
  }

  function requiredValueExists(value: string) {
    return value !== "" && value !== undefined;
  }

  function emailIsValid(email: string) {
    if (email === undefined || email === null) {
      return false;
    } else {
      return validator.isEmail(email);
    }
  }

  function phoneNumberIsValid(phoneNumber: string) {
    return validator.isMobilePhone(phoneNumber, "en-CA");
  }

  async function completeCheckout() {
    uiActions.enqueue();

    try {
      const postCustomerResponse = await PostCustomer(
        {
          first_name: checkoutInformation.firstNameField.data,
          last_name: checkoutInformation.lastNameField.data,
          email: checkoutInformation.emailAddressField.data,
        },
        false,
      );

      if (postCustomerResponse.status !== StatusCode.OK) {
        uiActions.dequeue();
        uiActions.showError(t("guest.online_store.checkout.002"));
        return;
      }

      const postAddressLineResponse = await PostAddressLine(
        {
          customer_email: postCustomerResponse.data.email,
          address_line_1: checkoutInformation.addressLine1Field.data,
          address_line_2: checkoutInformation.addressLine2,
          city: checkoutInformation.cityField.data,
          country_id: checkoutInformation.country_id,
          province_id: checkoutInformation.province_id,
          postal: checkoutInformation.postalCodeField.data,
          phone: checkoutInformation.phoneNumberField.data,
        },
        false,
      );

      if (postAddressLineResponse.status !== StatusCode.OK) {
        uiActions.dequeue();
        uiActions.showError(t("guest.online_store.checkout.003"));
        return;
      }

      const putCartActionPromise: Promise<ICart> = cartActions.putCart(
        {
          cart_token: cartStore.cart.token,
          customer_billing_address_id: postAddressLineResponse.data.id,
          customer_email: checkoutInformation.emailAddressField.data,
          customer_notes: checkoutInformation.customerNotes,
        },
        false,
      ) as unknown as Promise<ICart>;

      putCartActionPromise
        .then(() => {
          uiActions.dequeue();
          navigateToForms();
        })
        .catch(() => {
          uiActions.dequeue();
          uiActions.showError(t("guest.online_store.checkout.004"));
        });
    } catch {
      uiActions.dequeue();
    }
  }

  function goToNextStep() {
    if (
      checkoutInformation.firstNameField.isValid &&
      checkoutInformation.lastNameField.isValid &&
      checkoutInformation.emailAddressField.isValid &&
      checkoutInformation.phoneNumberField.isValid &&
      checkoutInformation.addressLine1Field.isValid &&
      checkoutInformation.cityField.isValid &&
      checkoutInformation.country_id &&
      checkoutInformation.province_id &&
      checkoutInformation.postalCodeField.isValid
    ) {
      void completeCheckout();
    } else {
      setCheckoutInformation(previousCheckoutInformation => ({
        ...previousCheckoutInformation,
        firstNameField: { ...previousCheckoutInformation.firstNameField, isDirty: true },
        lastNameField: { ...previousCheckoutInformation.lastNameField, isDirty: true },
        emailAddressField: { ...previousCheckoutInformation.emailAddressField, isDirty: true },
        phoneNumberField: { ...previousCheckoutInformation.phoneNumberField, isDirty: true },
        addressLine1Field: { ...previousCheckoutInformation.addressLine1Field, isDirty: true },
        cityField: { ...previousCheckoutInformation.cityField, isDirty: true },
        postalCodeField: { ...previousCheckoutInformation.postalCodeField, isDirty: true },
      }));

      const activeFields = [
        checkoutInformation.firstNameField,
        checkoutInformation.lastNameField,
        checkoutInformation.emailAddressField,
        checkoutInformation.phoneNumberField,
        checkoutInformation.addressLine1Field,
        checkoutInformation.cityField,
        checkoutInformation.postalCodeField,
      ];

      const invalidField = activeFields.find(field => !field.isValid);

      if (invalidField) {
        invalidField.ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }

  function renderOrderSummary(togglableVisibility: boolean) {
    return (
      <OrderSummary
        subtotal={cartStore.cart.subtotal_price}
        taxLines={cartStore.cart.tax_lines}
        discount={cartStore.cart.total_discount}
        total={cartStore.cart.total_price}
        togglableVisibility={togglableVisibility}
        lineItems={cartStore.cart.line_items.map(lineItem => {
          return {
            src: lineItem.product_default_image?.source,
            productTitle: lineItem.product_title,
            variantTitle: lineItem.variant_title,
            quantity: lineItem.quantity,
            price: lineItem.subtotal_price,
          };
        })}
      />
    );
  }

  return (
    <div>
      {checkoutInformation.facility && (
        <>
          <header>
            <TransactionHeader
              facilityShortName={facilityShortName}
              facilityLogoSource={checkoutInformation.facility.logo_source}
              shoppingBagHasItems={cartStore.cart?.line_items?.length > 0}
            />
          </header>
          <main>
            <StorePage>
              {cartStore.isLoaded && cartStore.cart && checkoutInformation.countries && (
                <div className="checkout-page-sections">
                  <section className="checkout-order-summary-mobile-section">{renderOrderSummary(true)}</section>
                  <section className="checkout-information-section">
                    <Card>
                      <Card.Section>
                        <div className="checkout-information-section-card-title">
                          {t("guest.online_store.checkout.005")}
                        </div>
                        <FormLayout>
                          <FormLayout.Group>
                            <span ref={checkoutInformation.firstNameField.ref}>
                              <Input
                                error={
                                  checkoutInformation.firstNameField.isDirty &&
                                  !checkoutInformation.firstNameField.isValid
                                }
                                value={checkoutInformation.firstNameField.data}
                                label={t("guest.online_store.checkout.006")}
                                onChange={(event: any) =>
                                  handleInputFieldChange(
                                    "firstNameField",
                                    event?.target?.value,
                                    requiredValueExists(event?.target?.value),
                                  )
                                }
                              />
                            </span>
                            <span ref={checkoutInformation.lastNameField.ref}>
                              <Input
                                error={
                                  checkoutInformation.lastNameField.isDirty &&
                                  !checkoutInformation.lastNameField.isValid
                                }
                                value={checkoutInformation.lastNameField.data}
                                label={t("guest.online_store.checkout.007")}
                                onChange={(event: any) =>
                                  handleInputFieldChange(
                                    "lastNameField",
                                    event?.target?.value,
                                    requiredValueExists(event?.target?.value),
                                  )
                                }
                              />
                            </span>
                          </FormLayout.Group>
                          <FormLayout.Group>
                            <span ref={checkoutInformation.emailAddressField.ref}>
                              <Input
                                error={
                                  checkoutInformation.emailAddressField.isDirty &&
                                  !checkoutInformation.emailAddressField.isValid
                                }
                                value={checkoutInformation.emailAddressField.data}
                                label={t("guest.online_store.checkout.008")}
                                onChange={(event: any) =>
                                  handleInputFieldChange(
                                    "emailAddressField",
                                    event?.target?.value,
                                    emailIsValid(event?.target?.value),
                                  )
                                }
                              />
                            </span>
                            <span ref={checkoutInformation.phoneNumberField.ref}>
                              <Input
                                error={
                                  checkoutInformation.phoneNumberField.isDirty &&
                                  !checkoutInformation.phoneNumberField.isValid
                                }
                                value={checkoutInformation.phoneNumberField.data}
                                label={t("guest.online_store.checkout.009")}
                                onChange={(event: any) =>
                                  handleInputFieldChange(
                                    "phoneNumberField",
                                    event?.target?.value,
                                    phoneNumberIsValid(event?.target?.value),
                                  )
                                }
                              />
                            </span>
                          </FormLayout.Group>
                        </FormLayout>
                      </Card.Section>
                    </Card>
                    <Card>
                      <Card.Section>
                        <div className="checkout-information-section-card-title">
                          {t("guest.online_store.checkout.010")}
                        </div>
                        <FormLayout>
                          <FormLayout.Group>
                            <span ref={checkoutInformation.addressLine1Field.ref}>
                              <Input
                                error={
                                  checkoutInformation.addressLine1Field.isDirty &&
                                  !checkoutInformation.addressLine1Field.isValid
                                }
                                value={checkoutInformation.addressLine1Field.data}
                                label={t("guest.online_store.checkout.011")}
                                onChange={(event: any) =>
                                  handleInputFieldChange(
                                    "addressLine1Field",
                                    event?.target?.value,
                                    requiredValueExists(event?.target?.value),
                                  )
                                }
                              />
                            </span>
                            <Input
                              value={checkoutInformation.addressLine2}
                              label={t("guest.online_store.checkout.012")}
                              onChange={(event: any) => handleOptionalInputChange("addressLine2", event?.target?.value)}
                            />
                          </FormLayout.Group>
                          <FormLayout.Group>
                            <Select
                              label={t("guest.online_store.checkout.013")}
                              onChange={(value: number) => {
                                handleCountryChange(value);
                              }}
                              defaultValue={checkoutInformation.country_id}
                              smallDropdownSize={true}
                            >
                              {checkoutInformation.countries.map((country: ICountry, index: number) => {
                                return (
                                  <Option key={index} value={country.id} name={country.name}>
                                    {country.name}
                                  </Option>
                                );
                              })}
                            </Select>
                            <Select
                              label={t("guest.online_store.checkout.014")}
                              onChange={(value: number) => {
                                handleSelectChange("province_id", value);
                              }}
                              defaultValue={checkoutInformation.province_id}
                              smallDropdownSize={true}
                            >
                              {checkoutInformation.provinces?.map((province: IProvince, index: number) => {
                                return (
                                  <Option key={index} value={province.id} name={province.name}>
                                    {province.name}
                                  </Option>
                                );
                              })}
                            </Select>
                          </FormLayout.Group>
                          <FormLayout.Group>
                            <span ref={checkoutInformation.cityField.ref}>
                              <Input
                                error={checkoutInformation.cityField.isDirty && !checkoutInformation.cityField.isValid}
                                value={checkoutInformation.cityField.data}
                                label={t("guest.online_store.checkout.015")}
                                onChange={(event: any) =>
                                  handleInputFieldChange(
                                    "cityField",
                                    event?.target?.value,
                                    requiredValueExists(event?.target?.value),
                                  )
                                }
                              />
                            </span>
                            <span ref={checkoutInformation.postalCodeField.ref}>
                              <Input
                                error={
                                  checkoutInformation.postalCodeField.isDirty &&
                                  !checkoutInformation.postalCodeField.isValid
                                }
                                value={checkoutInformation.postalCodeField.data}
                                label={t("guest.online_store.checkout.016")}
                                onChange={(event: any) =>
                                  handleInputFieldChange(
                                    "postalCodeField",
                                    event?.target?.value,
                                    requiredValueExists(event?.target?.value),
                                  )
                                }
                              />
                            </span>
                          </FormLayout.Group>
                        </FormLayout>
                      </Card.Section>
                    </Card>
                    <Card>
                      <Card.Section>
                        <div className="checkout-information-section-card-title">
                          {t("guest.online_store.checkout.020")}
                        </div>
                        <span ref={checkoutInformation.customerNotesRef}>
                          <Input
                            label={t("guest.online_store.checkout.021")}
                            id="customerNotes"
                            value={checkoutInformation.customerNotes}
                            onChange={handleInputChange}
                          />
                        </span>
                      </Card.Section>
                    </Card>
                    <div className="checkout-information-section-next-step-container">
                      <button className="checkout-information-section-next-step" onClick={goToNextStep}>
                        {t("guest.online_store.checkout.017")}
                      </button>
                    </div>
                  </section>
                  <section className="checkout-order-summary-desktop-section">{renderOrderSummary(false)}</section>
                </div>
              )}
            </StorePage>
          </main>
        </>
      )}
    </div>
  );
}
