import React, { ReactNode, isValidElement, useEffect, useRef, useState } from "react";
import { Badge } from "components/badge/Badge";
import Spin from "components/spin/spin";
import PlusMinus from "./PlusMinus";

type AccordionTriggerProps<T> = {
  open?: boolean;
  toggle?: (e: React.MouseEvent<HTMLDivElement | HTMLSpanElement, MouseEvent>) => void;
  chevronClick?: () => void;

  title: string;
  helpText?: string;
  leftAction?: ReactNode | Element;
  badge?: string;
  disabled?: boolean | ((id?: T) => void);
  loading?: boolean;
};

export default function AccordionTrigger<T>(props: AccordionTriggerProps<T>) {
  const { open = false } = props;
  const pRef = useRef<HTMLParagraphElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  const [scrollText, setScrollText] = useState<boolean>(false);
  const [chevronHover, setChevronHover] = useState<boolean>(false);
  const [scrollHover, setScrollHover] = useState<boolean>(false);

  // Scroll animation on helpText overflow
  useEffect(() => {
    if (pRef.current?.offsetWidth > scrollRef.current?.offsetWidth && scrollHover) {
      setScrollText(true);
    } else {
      setScrollText(false);
    }
  }, [scrollHover]);

  // leftIcon || { content } || badge || PlusMinus
  const template = `${props.leftAction ? "auto" : ""} 1fr${props.badge ? " auto" : ""} auto`;
  return (
    <div
      aria-expanded={open}
      className="acc-trigger"
      style={{ gridTemplateColumns: template, borderRadius: open ? "6px 6px 0 0" : "6px" }}
      onClick={e => props.toggle(e)}
    >
      {isValidElement(props?.leftAction) ? <>{props.leftAction}</> : null}

      <div
        className="content-wrap"
        ref={scrollRef}
        onMouseEnter={() => setScrollHover(true)}
        onMouseLeave={() => setScrollHover(false)}
      >
        <p className="label">{props.title}</p>
        {props.helpText ? (
          <p className={`help-text${scrollText ? " scroll-text" : ""}`} ref={pRef}>
            {props.helpText}
          </p>
        ) : null}
      </div>
      {props.badge ? <Badge size="medium">{props.badge}</Badge> : null}

      {props.loading ? (
        <div className="chevron">
          <span>
            <Spin />
          </span>
        </div>
      ) : (
        <div
          className="chevron"
          onMouseEnter={() => setChevronHover(true)}
          onMouseLeave={() => setChevronHover(false)}
          onClick={e => e.stopPropagation()}
        >
          <PlusMinus
            active={open}
            onClick={e => (props.chevronClick ? props.chevronClick() : props.toggle(e))}
            hovered={props.chevronClick && chevronHover}
          />
        </div>
      )}
    </div>
  );
}
