export function updateTeeSheetProducts(productType: string, items: any) {
  return (dispatch: any) => {
    dispatch({
      type: "update.teeSheetProducts",
      payload: {
        [productType]: items,
      },
    });
  };
}

export interface ITeeSheetProductActions {
  updateTeeSheetProducts: typeof updateTeeSheetProducts;
}
