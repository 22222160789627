import React, { ChangeEvent, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { showError } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";

import FormLayout from "components/form/FormLayout";
import Form from "components/form/Form";
import Input from "components/form/input/Input";
import { ButtonNew } from "components/buttonNew";
import Sheet from "components/sheet/Sheet";
import Checkbox from "components/form/checkbox/Checkbox";
import Portal from "elements/Portal";
import CheckboxBlock from "../elements/CheckboxBlock";
import Popup from "components/popup/Popup";
import { useTranslation } from "react-i18next";
interface ICustomCheckboxEdit {
  label: string;
  helpText: string;
  required: boolean;
  options: string[];
  saveInput: (label: string, helpText: string, required: boolean) => void;
  editModalOpen: boolean;
  closeEditModal: () => void;
  /** Viewable INPUT on the edit page itself. */
  children: React.ReactNode;
}

/** Structural display wrapper containing edit modal and its state. */
export default function CustomCheckboxEdit(props: ICustomCheckboxEdit) {
  const { label, helpText, required = false, options, editModalOpen, closeEditModal } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const checkboxInputRef = useRef<HTMLInputElement>(null);

  const [state, setState] = useState({
    input: "",
    array: options !== null ? options : [],
    label: label,
    helpText: helpText,
    required: required,
    columnCount: 1, // Not connected
  });

  const [editing, setEditing] = useState({
    state: false, // style trigger
    value: null, // value currently being edited
  });

  function addListItem() {
    console.log(state);
    console.log(editing);

    if (state.input.length === 0 && !editing.state) {
      dispatch(showError(t("secure.facility.settings.forms.custom_edits.custom_checkbox_edit.001")));
      return;
    }

    // if found, return early
    if (state.array?.find(val => val === state.input)) {
      if (editing.state && editing.value === state.input) {
        setEditing({ state: false, value: "" }); // remove edit state
        setState(prev => ({ ...prev, input: "" })); // remove input value
        return;
      }

      dispatch(showError(t("secure.facility.settings.forms.custom_edits.custom_checkbox_edit.002")));
      return;
    }

    // if editing, 'switch' values
    if (editing.state) {
      updateListItem(state.input);
      return;
    }

    // add value
    const returnArray = [...state.array];
    returnArray.push(state.input);

    setState(prev => ({ ...prev, input: "", array: returnArray }));
  }

  // replace list item with editing value.  Remove if value.length == 0
  function updateListItem(value: string) {
    if (value.length === 0) {
      removeListItem(editing.value);
      setEditing({ state: false, value: null });
      return;
    }

    const editReturn = state.array.map(val => (val === editing.value ? value : val));

    setState(prev => ({ ...prev, input: "", array: editReturn }));
    setEditing({ state: false, value: null });
  }

  // splice value from select options
  function removeListItem(value: string) {
    const returnArray = [...state.array];

    if (returnArray.includes(value)) {
      returnArray.splice(returnArray.indexOf(value), 1);
      setState(prev => ({ ...prev, array: returnArray }));
    }
  }

  function selectListItem(isSelected: boolean, value: string) {
    if (editing.state) {
      return;
    } // disallow row click if editing a value
    setState(prev => ({ ...prev, input: isSelected ? "" : value }));
  }

  function listIconClick(e: React.MouseEvent<HTMLElement, MouseEvent>, value: string, selected: boolean) {
    e.stopPropagation();

    // Default icon functionality
    if (!editing.state && !selected) {
      removeListItem(value);
      return;
    }

    // Set editing state
    if (!editing.state && selected) {
      setEditing({ state: true, value: value });
      return;
    }

    // Remove editing state
    setEditing(prev => ({
      ...prev,
      state: false,
      value: null,
    }));

    setState(prev => ({ ...prev, input: "" }));
  }

  function closeModal() {
    closeEditModal();
    setState({
      input: "",
      label: label,
      helpText: helpText,
      array: options,
      required: required,
      columnCount: 1,
    });
  }

  return (
    <div className={`input-view-wrap${required ? " required" : ""}`}>
      {props.children}

      <Portal isMounted={editModalOpen}>
        <Sheet
          open={editModalOpen}
          title={t("secure.facility.settings.forms.custom_edits.custom_checkbox_edit.005")}
          onOk={() => props.saveInput(state.label, state.helpText, state.required)}
          onCancel={() => closeModal()}
          okText={t("secure.facility.settings.forms.custom_edits.custom_checkbox_edit.006")}
          size="medium"
        >
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  label={t("secure.facility.settings.forms.custom_edits.custom_checkbox_edit.007")}
                  defaultValue={label}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setState(prev => ({ ...prev, label: e.target.value }))
                  }
                  className="input-edit-action"
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Input
                  label={t("secure.facility.settings.forms.custom_edits.custom_checkbox_edit.008")}
                  defaultValue={helpText}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setState(prev => ({ ...prev, helpText: e.target.value }))
                  }
                  className="input-edit-action"
                />
              </FormLayout.Group>

              <FormLayout.Group>
                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "18px" }}>
                  <div />
                  <CheckboxBlock
                    label={t("secure.facility.settings.forms.custom_edits.custom_checkbox_edit.009")}
                    checked={state.required}
                    onChange={() => setState(prev => ({ ...prev, required: !prev.required }))}
                  />
                  {/* <CheckboxBlock
                    label={"Allow multi-select"}
                    checked={false}
                    onChange={() => console.warn("connect to state")}
                    disabled
                  />
                  <CheckboxBlock
                    label={"Use 'Other' field"}
                    supportText={"The last answer choice will contain a text input."}
                    checked={false}
                    onChange={() => console.warn("connect to state")}
                    disabled
                  />
                  <CheckboxBlock
                    label={"Use N/A choice"}
                    supportText={"The last answer choice will prevent multi-select."}
                    checked={false}
                    onChange={() => console.warn("connect to state")}
                    disabled
                  />
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "18px" }}>
                  <p style={{ gridColumnStart: "1", gridColumnEnd: "-1" }}>Presentation</p>

                  <Checkbox label="Single Column" circle size="small" disabled />
                  <Checkbox label="Grid" circle size="small" disabled />

                  <div style={{ gridColumnStart: "2", gridColumnEnd: "2" }}>
                    <Input label={"Columns"} defaultValue={state.columnCount} disabled />
                  </div> */}
                </div>
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Sheet>
      </Portal>
    </div>
  );
}
