import React, { useEffect, useState } from "react";
import axios, { CancelToken } from "axios";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { GetGiftCard, IGiftCard, ImportGiftCards } from "api/rpc/2022-09/facilityAdmin/payment/giftCard";

import { showError, showSuccess } from "redux/actions/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import useModal from "hooks/modals/useModal";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Callout from "components/callout/Callout";
import DropFilter from "components/dropdown/DropFilter";
import GiftCardsTable from "./GiftCardsTable";
import Search from "components/search/Search";
import FileImport from "components/fileImport/FileImport";

import "pages/secure/facility/order/order.scss";
import "pages/secure/facility/order/giftCard.scss";

interface IFilterState {
  search: string;
  all: Array<Record<string, any>>;
  status: string[];
  openBalanceCheck: boolean;
  detailedSearch: string;
  viewDetailedInfo: boolean;
}

export default function GiftCards() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { authStore, facilityStore } = useAppSelector(store => store);

  const permissions = authStore.user?.permissions;
  const facilityPermissions = facilityStore.facility?.permissions;

  const [giftCards, setGiftCards] = useState<IGiftCard[]>(undefined);

  const [filterState, setFilterState] = useState<IFilterState>({
    search: "",
    all: undefined,
    status: [],
    openBalanceCheck: false,
    detailedSearch: null,
    viewDetailedInfo: false,
  });

  const { state: fileImportModal, updateModal, closeModal } = useModal();

  const statusFilters = [
    {
      id: "valid",
      label: "Valid", // TODO: Translation
    },
    {
      id: "disabled",
      label: "Disabled", // TODO: Translation
    },
  ];

  // Search giftcards
  useEffect(() => {
    if (filterState === undefined) {
      return;
    }

    const source = axios.CancelToken.source();
    void loadGiftCards(source.token);

    return () => {
      source.cancel();
    };
  }, [filterState.search, filterState.all]);

  async function loadGiftCards(token: CancelToken) {
    const giftcardRes = permissions.gift_cards_view
      ? await GetGiftCard({ search: filterState.search, filters: filterState.all }, false, token)
      : await GetGiftCard({ code: filterState.search, filters: filterState.all }, false, token);

    if (token && token.reason) {
      return;
    }
    if (giftcardRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.order.gift_cards.004")));
    }

    // const giftCards = giftcardRes.data.filter((variant: any) => variant.facility_access === true);

    setGiftCards(giftcardRes.status !== StatusCode.OK ? [] : giftcardRes.data);
  }

  async function importGiftCards(selectedFile: File) {
    const formData = new FormData();
    formData.append("file", selectedFile);

    const importRes = await ImportGiftCards(formData, true);

    if (importRes.status !== StatusCode.OK) {
      dispatch(showError("Error importing gift cards")); // TODO: Translation
      closeModal();
      return;
    }

    dispatch(showSuccess("Gift cards imported successfully")); // TODO: Translation
    closeModal();
  }

  function handleClearFilter(groupId: string) {
    const allFilters: Record<string, any> = filterState.all;

    const removeFilter = allFilters.filter((filter: { label: string }) => filter?.label !== groupId);
    setFilterState(prev => ({
      ...prev,
      [groupId]: [],
      all: removeFilter,
    }));
  }

  function handleApplyFilters(filterReturn: Array<Record<string, any>>, dataGroup: string) {
    if (filterReturn.length === 0) {
      handleClearFilter(dataGroup);
      return;
    }

    const filterValues = filterReturn.map(filter => filter.id as string | number);
    const activeFilterGroup = filterState.all ? filterState.all.filter(group => group.label !== dataGroup) : [];

    activeFilterGroup.push({
      label: dataGroup,
      value: filterValues,
    });

    setFilterState({ ...filterState, all: activeFilterGroup, [dataGroup]: filterValues });
  }

  return (
    <>
      <Page
        title={t("secure.facility.order.gift_cards.002")}
        narrow
        multipleActionDropdownAction={{
          label: "Options", // TODO: Translation
          dropdownProps: {
            alignment: "right",
            options: [
              {
                type: "handler",
                label: "Import", // TODO: Translation
                handler: () => updateModal({ isOpen: true }),
                disabled: !permissions?.gift_cards_import || !facilityPermissions?.gift_cards_import,
              },
              {
                type: "handler",
                label: "Download Template", // TODO: Translation
                handler: () => {},
              },
            ],
          },
        }}
      >
        <div className="flex flex-row flex-wrap gap-4 mb-4">
          <DropFilter
            key="status"
            title={"Status"} // TODO: Translation
            filterData={statusFilters}
            filterLabelPropFromData="label"
            filterIdPropFromData="id"
            filterType="Checkbox"
            applyFilters={(filterReturn: Record<string, any>[]) => handleApplyFilters(filterReturn, "status")}
            leftAligned
            disableDropdown={!permissions.gift_cards_view}
          />
        </div>
        <div className="flex-grow mb-4">
          <Search
            historyKey={"facility_gift_cards_search"}
            searchCallback={searchValue => {
              setFilterState(prevState => ({ ...prevState, search: searchValue }));
            }}
            placeholder={"Search for gift cards..."} // TODO: Translation
          />
        </div>

        {!permissions.gift_cards_view && (
          <Card>
            <Callout
              type="info"
              title={"View Gift Cards Permission is Off"} // TODO: Translation
              content={"Entire gift card code is required to search"} // TODO: Translation
            />
          </Card>
        )}

        <Card>
          <Card.Section table="true">
            <GiftCardsTable giftCards={giftCards} viewPermission={permissions.gift_cards_view} />
          </Card.Section>
        </Card>
      </Page>

      <FileImport onCancel={closeModal} onOk={importGiftCards} open={fileImportModal.isOpen} />
    </>
  );
}
