import React, { useEffect, useState } from "react";
import axios, { CancelToken } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";

import { IAPIResponse, StatusCode } from "api/protocols";
import { PostCheckout } from "api/rpc/2022-09/facilityAdmin/cart/cart";
import { PostTransaction } from "api/rpc/2022-09/facilityAdmin/order/transaction";
import { PutOrderComplete, GetReceipt, GetOrder, PutVoidOrder } from "api/rpc/2022-09/facilityAdmin/order/order";
import { GetCustomer } from "api/rpc/2022-09/facilityAdmin/customer/customer";
import { GetGiftCardValidate } from "api/rpc/2022-09/facilityAdmin/payment/giftCard";
import { PostKitchenChit } from "api/rpc/2022-09/facilityAdmin/facility/kitchen";
import { usePOSPrinter } from "hooks/usePOSPrinter/usePOSPrinter";
import { GetRoomOccupants, IOccupant } from "api/rpc/room";
import { GetModifierGroup, IModifierGroup } from "api/rpc/facilityAdmin/modifierGroups";
import { PostDiscountLine, DeleteDiscountLine } from "api/rpc/2022-09/facilityAdmin/product/discount";
import { ICustomer } from "redux/reducers/models/customer";
import { ICartLineItem } from "redux/reducers/models/cart";
import { IVariant } from "redux/reducers/models/product";

import {
  clearReaderDisplay,
  sheetInfo,
  processPayment as stripeProcessPayment,
  setReaderDisplay,
  convertOrderIntoDisplayInfo,
} from "helpers/StripeTerminalWrapper";
import { isEmpty } from "helpers/Helpers";
import { useStripeTerminalPayment } from "hooks/useStripeTerminalPayment/useStripeTerminalPayment";

import { IStripePaymentState } from "pages/secure/facility/teesheet/TeeSheet";
import PaymentMethods from "elements/register/cartMenu/PaymentMethods";
import CartTotals from "elements/register/cartMenu/CartTotals";
import CartLineItems from "elements/register/cartMenu/CartLineItems";
import CartDiscountLines from "elements/register/cartMenu/CartDiscountLines";
import CartTransactions from "elements/register/cartMenu/CartTransactions";

import Sheet from "components/sheet/Sheet";
import Popup from "components/popup/Popup";

import Divider from "components/divider";
import { ButtonNew as Button } from "components/buttonNew";
import { Select } from "components/select/index";
import CheckoutForm, { cancelManualCreditPaymentIntent } from "components/checkoutForm/CheckoutForm";
import Spin from "components/spin/spin";
import Input from "components/form/input/Input";
import Checkbox from "components/form/checkbox/Checkbox";
import SelectModifierSheet from "components/selectModifierSheet/SelectModifierSheet";
import OrderCompletePopup from "components/orderCompletePopup/OrderCompletePopup";

import UpdateLineItemModal, {
  ILineItem,
  IUpdateLineItemModalState,
} from "pages/secure/facility/teesheet/UpdateLineItemModal";

import "elements/teesheet/menu/teesheetMenu.scss";
import "elements/register/register.scss";
import GiftCardRedeem, { IGiftCardRedeemState } from "components/giftCardRedeem/GiftCardRedeem";
import GiftCardSell from "components/giftcardSell/GiftCardSell";
import AccountsModal from "components/accountsModal/AccountsModal";

import { useTranslation } from "react-i18next";
import LeagueSelectionModal from "components/leagueSelectionModal/LeagueSelectionModal";
import { IOrder } from "redux/reducers/models/order";
import { deleteLineItem, loadCart, postCart, postLineItem, putCustomer } from "redux/actions/cart";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { dequeue, enqueue, showError, showSuccess } from "redux/actions/ui";
import CreditBookModal from "components/creditBookModal/CreditBookModal";
import { IPrizeAccount } from "pages/secure/facility/settings/prizeAccounts/PrizeAccount";
import { cloneDeep, isEqual } from "lodash";
import SelectCustomer from "./SelectCustomer";

interface IMainState {
  page: "cart" | "methods" | "receipt";
  paymentOption: Record<string, any>;
  order: undefined | Record<string, any>;
  serverPaymentSheetVisible: boolean;
  applyLineItemDiscountModal: boolean;
  inLineDiscounts: any;
  clearCartConfirmVisible: boolean;
  paymentAmount: number;
  discountLine: Record<string, any>;
  selectedDiscountId: number;
  customerSearchQuery: string;
  customerSearchResults: (Partial<ICustomer> | ICustomer)[];
  giftCardActive: boolean;
  sellGiftCardsActive: boolean;
  gift_cards: {
    cart_line_item_id: number;
    code: string;
    pin: number;
    random: boolean;
    balance: number;
    reload: number;
  }[];
  modifierGroups: { [key: string]: ICartLineItem[] };
  modifierGroupsSheetOpen: boolean;
  allModifierGroups: IModifierGroup[];
  selectedModifierGroupVariants: { [key: string]: Record<string, any>[] };
  showCustomerSearch: boolean;
  accountsVisible: boolean;
  selectedAccount: Record<string, any>;
  creditBookVisible: boolean;
  selectedCreditBook: IPrizeAccount;
  searchingCustomer: boolean;
  changeDue: number;
  changePopUpVisible: boolean;
  roomChargeSheetVisible: boolean;
  roomOccupantSearchQuery: string;
  roomOccupantsSearchResults: IOccupant[];
  selectedOccupant: IOccupant;
  closeTableActive: boolean;
  orderActionBarActive: boolean;
  showCancelOrder: boolean;
  leagueFeesActive: boolean;
  leagueFeeProducts: Array<ICartLineItem>;
  selectedLeagues: Array<number>;
  paymentInProgress: boolean;
}

interface IProps {
  onSuccess?: () => any;
  modifierItem: Record<string, any>;
  setRegisterState: (newMainState: Partial<any>) => void;
  setFilterState: (newMainState: Partial<any>) => void;
  unPaidOrder: IOrder;
}
interface ICancelDiscountState {
  selectedDiscountID: any;
  cartID: number;
  showCancelDiscount: boolean;
}

export default function CartMenu(props: IProps) {
  const elements = useElements();
  const { cartStore, authStore, facilityStore, terminalStore } = useAppSelector(store => store);
  const cart = cartStore?.cart;
  const reader = terminalStore?.reader;
  const permissions = authStore?.user?.permissions;
  const { Option } = Select;
  const dispatch = useAppDispatch();
  const { printChits } = usePOSPrinter();

  const { t, i18n } = useTranslation();
  const [submitTrigger, setSubmitTrigger] = useState<boolean>(false);
  const [manualProcessing, setManualProcessing] = useState<boolean>(false);
  const [applyDiscount, setApplyDiscount] = useState<boolean>(false);
  const [lineItemState, setLineItemState] = useState<IUpdateLineItemModalState>({
    lineItem: undefined,
    inputQuantity: "",
    inputPrice: "",
    inputNote: "",
    isModalVisible: false,
  });

  const [state, setState] = useState<IMainState>({
    page: "cart",
    paymentOption: null,
    order: undefined,
    applyLineItemDiscountModal: false,
    inLineDiscounts: [],
    discountLine: undefined,
    clearCartConfirmVisible: false,
    paymentAmount: 0,
    selectedDiscountId: -1,
    customerSearchQuery: "",
    customerSearchResults: [null],
    giftCardActive: false,
    sellGiftCardsActive: false,
    gift_cards: null,
    modifierGroups: {},
    modifierGroupsSheetOpen: false,
    allModifierGroups: [],
    selectedModifierGroupVariants: {},
    showCustomerSearch: false,
    accountsVisible: false,
    selectedAccount: undefined,
    creditBookVisible: false,
    selectedCreditBook: undefined,
    searchingCustomer: false,
    changeDue: null,
    changePopUpVisible: false,
    roomChargeSheetVisible: false,
    roomOccupantSearchQuery: "",
    roomOccupantsSearchResults: [],
    selectedOccupant: null,
    closeTableActive: false,
    serverPaymentSheetVisible: false,
    orderActionBarActive: false,
    showCancelOrder: false,
    leagueFeesActive: false,
    leagueFeeProducts: null,
    selectedLeagues: null,
    paymentInProgress: false,
  });

  const [cancelDiscountState, setCancelDiscountState] = useState<ICancelDiscountState>({
    selectedDiscountID: undefined,
    cartID: undefined,
    showCancelDiscount: false,
  });

  const handlePaymentOptionChange = (paymentOption: Record<string, any>) => {
    if (paymentOption !== undefined) {
      setState(prevState => ({
        ...prevState,
        paymentOption: paymentOption,
      }));
    }
  };

  // Stripe Payment Related Functions and Variables
  const [manualStripeState, setManualStripeState] = useState({
    clientSecret: "",
    visible: false,
    transaction: undefined,
    elementComplete: {
      cardNumber: false,
      cardExpiry: false,
      cardCvc: false,
    },
  });

  const [stripePaymentState, setStripePaymentState] = useState<IStripePaymentState>({
    onCancel: () => {},
    onOk: () => {},
    cancelText: "",
    okText: "",
    sheetVisible: false,
    titleText: "",
    message: "",
    convertedCode: "",
    processing: false,
    success: false,
  });

  const navigateBack = () => {
    if (state.page === "methods") {
      setState(prevState => ({ ...prevState, page: "cart", gift_cards: null, paymentMethod: null }));
    } else if (state.page === "cart") {
      //Close ?
    } else if (state.page === "receipt") {
      setState(prevState => ({ ...prevState, page: "cart", gift_cards: null, paymentMethod: null }));
    }
  };

  const handlePaymentMethodChange = (value: string) => {
    if (value != undefined) {
      setState(prevState => ({
        ...prevState,
        paymentMethod: value,
      }));
    }
  };

  useEffect(() => {
    if (props.unPaidOrder) {
      setState(prevState => ({ ...prevState, order: props.unPaidOrder, page: "methods" }));
    }
  }, [props.unPaidOrder]);

  //Display receipt on complete order
  useEffect(() => {
    if (state?.order) {
      // Reset for another
      if (state.order.balance > 0) {
        setState(prevState => ({ ...prevState, paymentAmount: state.order.balance.toFixed(2) }));
      }
    }
  }, [state?.order]);

  //Update reader on cart change
  useEffect(() => {
    if (cart?.line_items && reader) {
      if (cart.total_price > 0) {
        void setReaderDisplay(convertOrderIntoDisplayInfo(cart, state.order));
      }
    }
  }, [cart, reader, cart?.tax_lines]);

  //Set default paymentAmount
  useEffect(() => {
    if (cart) {
      setState(prevState => ({ ...prevState, paymentAmount: Number(cart.total_price?.toFixed(2)) }));
    }
  }, [cart]);

  //Return to cart page and reset gift cards when cart is switched
  useEffect(() => {
    if (cart && state.page !== "receipt") {
      setState(prevState => ({ ...prevState, page: "cart", gift_cards: null, selectedLeagues: null }));
    }
  }, [cart?.id]);

  useEffect(() => {
    // Group modifier cart line items by parent_id
    const groups = cart?.line_items.reduce(
      (prevModifierGroups: { [key: string]: Array<ICartLineItem> }, lineItem: any) => {
        if (lineItem.parent_id !== null) {
          return {
            ...prevModifierGroups,
            [lineItem.parent_id]: [...(prevModifierGroups[lineItem.parent_id] || []), lineItem],
          };
        } else {
          return prevModifierGroups;
        }
      },
      {},
    );
    setState(prevState => ({ ...prevState, modifierGroups: groups }));
  }, [cart?.line_items]);

  useEffect(() => {
    if (props.modifierItem) {
      const lineItemsLength = cart?.line_items.length;
      if (lineItemsLength > 0) {
        const lineItem = cart?.line_items[lineItemsLength - 1];

        setLineItemState({ ...lineItemState, lineItem: lineItem as unknown as ILineItem });
        // setState(prevState => ({ ...prevState, lineItem: cart?.line_items[lineItemsLength - 1] }));
        void openModifierGroups(props.modifierItem.product_id);
      }
    }
  }, [props.modifierItem]);

  async function handleClearCart() {
    localStorage.removeItem("register_cart_token");
    dispatch(enqueue());
    await dispatch(postCart(null, false));
    dispatch(dequeue());
    dispatch(showSuccess(t("elements.register.cart_menu.cart_menu.001")));
    props.setRegisterState({ unPaidOrder: null });
    props.setFilterState({ search: "" });
    setState(prevState => ({
      ...prevState,
      customerSearchQuery: "",
      customerSearchResults: [],
      showCustomerSearch: false,
    }));

    void clearReaderDisplay();
  }

  async function loadCustomers(customerSearchQuery: string, cancelToken: CancelToken) {
    const customerRes = await GetCustomer({ search: customerSearchQuery }, false, cancelToken);

    if (customerRes.status !== StatusCode.OK) {
      return [];
    }

    return customerRes.data as Array<Record<string, any>>;
  }

  async function searchForCustomers(mounted: boolean, customerSearchQuery: string, cancelToken: CancelToken) {
    try {
      if (customerSearchQuery === "") {
        if (mounted) {
          setState(prevState => ({ ...prevState, customerSearchResults: [null], searchingCustomer: false }));
        }
        return;
      } else {
        setState(prevState => ({ ...prevState, searchingCustomer: true }));
        const res = await loadCustomers(customerSearchQuery, cancelToken);
        if (mounted) {
          setState(prevState => ({ ...prevState, customerSearchResults: res, searchingCustomer: false }));
        }
      }
    } catch (error) {
      setState(prevState => ({ ...prevState }));
    }
    return;
  }

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = global.setTimeout(() => {
        void searchForCustomers(mounted, state.customerSearchQuery, source.token);
      }, 300);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      setState(prevState => ({ ...prevState, customerSearchResults: [null], searchingCustomer: false }));
      source.cancel("Cancelled");
    };
  }, [state.customerSearchQuery]);

  async function loadRoomOccupants(roomOccupantSearchQuery: string, cancelToken: CancelToken) {
    const roomOccupantsRes = await GetRoomOccupants({ search: roomOccupantSearchQuery }, true, cancelToken);

    if (roomOccupantsRes.status !== StatusCode.OK && roomOccupantsRes.message !== "Cancelled") {
      dispatch(showError(roomOccupantsRes.message));
      return;
    }

    return roomOccupantsRes;
  }

  async function searchForRoomOccupants(mounted: boolean, roomOccupantSearchQuery: string, cancelToken: CancelToken) {
    try {
      if (roomOccupantSearchQuery === "") {
        if (mounted) {
          setState(prevState => ({ ...prevState, roomOccupantsSearchResults: [] }));
        }
      } else {
        const res = await loadRoomOccupants(roomOccupantSearchQuery, cancelToken);
        if (mounted && res?.status === StatusCode.OK && res?.data) {
          const occupants = res.data.map(occupant => {
            return {
              reservation_number: occupant.reservation_number ?? "",
              room_number: occupant.room_number ?? "",
              res_status: occupant.res_status,
              guest_name: occupant.guest_name ?? "",
              credit_limit: occupant.credit_limit,
            };
          });
          setState(prevState => ({ ...prevState, roomOccupantsSearchResults: occupants }));
        }
      }
    } catch (error) {
      setState(prevState => ({ ...prevState }));
    }
    setState(prevState => ({
      ...prevState,
      selectedOccupant: null,
    }));
  }

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = global.setTimeout(() => {
        void searchForRoomOccupants(mounted, state.roomOccupantSearchQuery, source.token);
      }, 2000);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      setState(prevState => ({ ...prevState, roomOccupantsSearchResults: [] }));
      source.cancel("Cancelled");
    };
  }, [state.roomOccupantSearchQuery]);

  async function completeOrder(order_id: number) {
    const register = JSON.parse(localStorage.getItem("register"));
    const registerId = register?.id;

    const completeRes = await PutOrderComplete(
      { order_id, gift_cards: state.gift_cards, register_id: registerId, league_ids: state.selectedLeagues },
      true,
    );

    if (completeRes.status !== StatusCode.OK) {
      dispatch(showError("Order Failed"));
      return;
    }
    dispatch(showSuccess("Order Completed"));
    setState(prevState => ({ ...prevState, gift_cards: null, selectedLeagues: null }));
    props.setFilterState({ search: null });
    setState(prevState => ({
      ...prevState,
      customerSearchQuery: "",
      customerSearchResults: [],
      showCustomerSearch: false,
    }));
  }

  async function loadReceipt(order_id: number) {
    const receiptRes = await GetReceipt({ id: order_id }, true);

    if (receiptRes.status === StatusCode.OK) {
      window.open().document.write(receiptRes.data);
    } else {
      dispatch(showError("Loading receipt failed"));
    }
  }

  async function clearCartAsync(order: any) {
    await completeOrder(order.id);

    // clear the cart token from local storage
    localStorage.removeItem("register_cart_token");

    if (reader) {
      void clearReaderDisplay();
    }

    setState(prevState => ({
      ...prevState,
      order: order,
      paymentAmount: 0,
      page: "receipt",
      selectedAccount: undefined,
      paymentOption: null,
    }));

    const register = JSON.parse(localStorage.getItem("register"));

    //check register to send kitchen chit
    if (register?.send_kitchen) {
      await sendKitchenChits();
    }

    //Check register to auto log out
    if (register && register?.auto_log_out === true) {
      localStorage.setItem("switch_user_after_transaction", "true");
    }

    //console.log(state);

    //void loadReceipt(order.id);
    //Order completed, display order action bar
    props.setRegisterState({ unPaidOrder: null });
    setState(prevState => ({ ...prevState, orderActionBarActive: true }));
  }

  async function updateOrder(order_id: number, clearCart: boolean) {
    if (order_id) {
      //Update order
      const orderRes = await GetOrder({ id: order_id, extended: true }, true);
      if (orderRes.status !== StatusCode.OK) {
        dispatch(showError("Loading order failed"));
        return;
      }
      setManualProcessing(false);

      const order = orderRes.data[0];
      if (order.balance <= 0 || order.financial_status === "paid") {
        if (clearCart) {
          await clearCartAsync(order);
        }
      } else {
        // Still need to process another payment for this order

        setState(prevState => ({
          ...prevState,
          order: order,
          paymentAmount: order.balance.toFixed(2),
          page: "methods",
        }));
      }
    }
  }

  async function sendKitchenChits() {
    const lineItemIds = cart?.line_items
      ?.filter(
        filteredLineItem =>
          !filteredLineItem?.parent_id &&
          (filteredLineItem.product?.meta?.kitchen_location_id ||
            filteredLineItem.product?.meta?.kitchen_location_id_2 ||
            filteredLineItem.product?.meta?.kitchen_location_id_3),
      )
      ?.map(lineItem => lineItem?.id);

    if (lineItemIds?.length > 0) {
      const chitRes = await PostKitchenChit({ line_item_ids: lineItemIds }, false);

      if (chitRes.status !== StatusCode.OK) {
        dispatch(showError(t("elements.register.cart_menu.cart_menu.003")));
        return;
      }

      void printChits(chitRes.data);
    }
  }

  function changePaymentAmount(e: any) {
    const value = e.target.value;

    setState(prevState => ({ ...prevState, paymentAmount: value }));
  }

  function handleManualProcessing() {
    if (manualProcessing === true) {
      setManualProcessing(false);
    } else {
      setManualProcessing(true);
    }
  }

  const elementsComplete =
    manualStripeState.elementComplete.cardNumber === true &&
    manualStripeState.elementComplete.cardExpiry === true &&
    manualStripeState.elementComplete.cardCvc === true;

  const {
    StripeTerminalSheet,
    processPayment,
    paymentStatus,
    order: tempStripePaymentOrder,
  } = useStripeTerminalPayment();

  const handleProcessPayment = async (giftCardInfo?: IGiftCardRedeemState) => {
    dispatch(enqueue());
    setState(prevState => ({ ...prevState, paymentInProgress: true }));
    const cartTotalPrice = Number(cart.total_price?.toFixed(2));
    try {
      const cart_id = cart.id;

      // Checking out produces a new order

      let checkoutRes: { status: any; data: any };

      // Create a new order if order does not exist in state
      if (!state?.order) {
        const register = JSON.parse(localStorage.getItem("register"));
        const registerId = register?.id;

        checkoutRes = await PostCheckout({ cart_id, register_id: registerId }, false);
      }

      const finalAmount = Number(state.paymentAmount);

      //Check to see if amount is acceptable
      if (Number.isNaN(finalAmount)) {
        dispatch(dequeue());
        dispatch(showError(`'${state.paymentAmount} ${t("elements.register.cart_menu.cart_menu.004")} `));
        setState(prevState => ({ ...prevState, paymentInProgress: false }));
        return;
      }

      if (
        (finalAmount > cartTotalPrice && state.paymentOption?.payment_method !== "cash") ||
        (finalAmount < 0 && state.paymentOption?.payment_method !== "account")
      ) {
        dispatch(dequeue());
        dispatch(showError(`'${state.paymentAmount} ${t("elements.register.cart_menu.cart_menu.005")} `));
        setState(prevState => ({ ...prevState, paymentInProgress: false }));
        return;
      }

      if (checkoutRes?.status === StatusCode.OK || state?.order) {
        if (checkoutRes) {
          setState(prevState => ({ ...prevState, order: checkoutRes.data.order }));
          props.setRegisterState({ order: checkoutRes.data.order });
        }

        const order_id = checkoutRes ? checkoutRes.data.order.id : state?.order.id;

        if (state.paymentOption?.payment_method === "manual_card") {
          const transactionRes = await PostTransaction(
            {
              kind: "sale",
              amount: finalAmount,
              order_id: order_id,
              payment_option_id: state.paymentOption?.id,
            },
            false,
          );

          if (transactionRes.status === StatusCode.OK) {
            setManualStripeState(prevState => ({
              ...prevState,
              transaction: transactionRes.data,
              clientSecret: transactionRes.data.client_secret,
              visible: true,
            }));
          } else {
            dispatch(dequeue());
            dispatch(showError(t("elements.register.cart_menu.cart_menu.006")));
            setState(prevState => ({ ...prevState, paymentInProgress: false }));
            return;
          }
          // Using terminal - display modal
        } else if (state.paymentOption?.payment_method === "card") {
          dispatch(dequeue());

          if (facilityStore?.facility.terminal_integration === "server") {
            setState(prevState => ({ ...prevState, serverPaymentSheetVisible: true }));
            await processPayment(order_id, state.paymentOption, finalAmount);
            setState(prevState => ({ ...prevState, paymentInProgress: false }));
          } else {
            setStripePaymentState(prevState => ({
              ...prevState,
              sheetVisible: true,
              onCancel: undefined,
              okText: t("elements.register.cart_menu.cart_menu.007"),
              processing: true,
              onOk: async () => {
                setStripePaymentState(prevState => ({
                  ...prevState,
                  sheetVisible: false,
                  onCancel: undefined,
                  okText: undefined,
                  processing: false,
                  onOk: () => {},
                  titleText: "",
                  success: false,
                  message: "",
                }));
                await clearReaderDisplay();
              },
              titleText: t("elements.register.cart_menu.cart_menu.008"),
              message: t("elements.register.cart_menu.cart_menu.009"),
            }));

            await stripeProcessPayment(
              order_id,
              finalAmount,
              state.paymentOption,
              handleStripeSuccess,
              handleStripeError,
            );
          }

          dispatch(enqueue());
        } else if (state.paymentOption?.payment_method === "gift_card") {
          const transactionRes = await PostTransaction(
            {
              kind: "sale",
              amount: finalAmount,
              order_id: order_id,
              payment_option_id: state.paymentOption?.id,
              gift_card_code: giftCardInfo.giftCardCode,
              gift_card_pin: giftCardInfo.giftCardPin,
            },
            false,
          );

          if (transactionRes.status === StatusCode.OK) {
            setState(prevState => ({
              ...prevState,
              giftCardActive: false,
            }));

            // display success notification
            dispatch(showSuccess(t("elements.register.cart_menu.cart_menu.010")));

            await updateOrder(transactionRes.data.order_id, true);
            //Handle successful payment
          } else {
            dispatch(showError(t("elements.register.cart_menu.cart_menu.006")));
            dispatch(dequeue());
            setState(prevState => ({ ...prevState, paymentInProgress: false }));
            return;
          }
        } else if (state.paymentOption?.payment_method === "account") {
          // display success notification
          const transactionRes = await PostTransaction(
            {
              kind: "sale",
              amount: finalAmount,
              order_id: order_id,
              payment_option_id: state.paymentOption?.id,
              account_id: state.selectedAccount.id,
            },
            false,
          );

          if (transactionRes?.status !== StatusCode.OK) {
            dispatch(showError(t("elements.register.cart_menu.cart_menu.006")));
            setState(prevState => ({ ...prevState, paymentInProgress: false }));
            dispatch(dequeue());
            return;
          }

          // display success notification
          dispatch(showSuccess(t("elements.register.cart_menu.cart_menu.010")));
          // Update order and check if there is a remaining balance
          await updateOrder(transactionRes.data.order_id, true);
        } else if (state.paymentOption?.payment_method === "credit_book") {
          // display success notification
          const transactionRes = await PostTransaction(
            {
              kind: "sale",
              amount: finalAmount,
              order_id: order_id,
              payment_option_id: state.paymentOption?.id,
              credit_book_id: state.selectedCreditBook.id,
            },
            false,
          );

          if (transactionRes?.status !== StatusCode.OK) {
            dispatch(showError(t("elements.register.cart_menu.cart_menu.006")));
            setState(prevState => ({ ...prevState, paymentInProgress: false }));
            dispatch(dequeue());
            return;
          }

          // display success notification
          dispatch(showSuccess(t("elements.register.cart_menu.cart_menu.010")));

          // Update order and check if there is a remaining balance
          await updateOrder(transactionRes.data.order_id, true);
        } else if (
          state.paymentOption?.payment_method === "room_charge_rdp" ||
          state.paymentOption?.payment_method === "maestro"
        ) {
          const transactionRes = await PostTransaction(
            {
              kind: "sale",
              amount: finalAmount,
              order_id: order_id,
              payment_option_id: state.paymentOption?.id,
              guest_name: state.selectedOccupant?.guest_name,
              room_number: state.selectedOccupant?.room_number,
            },
            false,
          );

          if (transactionRes.status !== StatusCode.OK) {
            dispatch(showError(t("elements.register.cart_menu.cart_menu.006")));
            dispatch(dequeue());
            setState(prevState => ({ ...prevState, paymentInProgress: false }));
            return;
          } else {
            dispatch(showSuccess(t("elements.register.cart_menu.cart_menu.010")));

            // Update order and check if there is a remaining balance
            await updateOrder(transactionRes.data.order_id, true);

            closeRoomChargeSheet();
          }
        } else {
          let changeDue: number;
          //Display change due for cash payments
          if (state.paymentOption?.payment_method === "cash") {
            if (state?.order?.balance && Number(state.paymentAmount) > state.order?.balance) {
              changeDue = Number(state.paymentAmount) - state.order?.balance;
              setState(prevState => ({
                ...prevState,
                changeDue: changeDue,
                changePopUpVisible: true,
              }));
            } else if (Number(state.paymentAmount) > cartTotalPrice) {
              changeDue = Number(state.paymentAmount) - cartTotalPrice;
              setState(prevState => ({
                ...prevState,
                changeDue: changeDue,
                changePopUpVisible: true,
              }));
            }
          }
          console.log("?", state.paymentOption);
          const transactionRes = await PostTransaction(
            {
              kind: "sale",
              amount: changeDue ? finalAmount - changeDue : finalAmount,
              order_id: order_id,
              payment_option_id: state.paymentOption?.id,
            },
            false,
          );

          if (transactionRes?.status !== StatusCode.OK) {
            dispatch(showError(t("elements.register.cart_menu.cart_menu.006")));
            dispatch(dequeue());
            setState(prevState => ({ ...prevState, paymentInProgress: false }));
            return;
          }

          // display success notification
          dispatch(showSuccess(t("elements.register.cart_menu.cart_menu.010")));

          // Update order and check if there is a remaining balance
          await updateOrder(transactionRes.data.order_id, true);

          //Handle successful payment
        }
      }
    } catch (e) {
      console.log("err:", e);
    }
    setState(prevState => ({ ...prevState, paymentInProgress: false }));
    dispatch(dequeue());
  };

  const handleStripeError = (message: string, sheetInfo: sheetInfo) => {
    //set sheet
    setStripePaymentState(prevState => ({
      ...prevState,
      onCancel: () => {
        sheetInfo.onCancel();
        setStripePaymentState(prevState => ({ ...prevState, sheetVisible: false }));
      },
      onOk: () => {
        sheetInfo.onOk();
        setStripePaymentState(prevState => ({
          ...prevState,
          sheetVisible: true,
          onCancel: undefined,
          okText: t("elements.register.cart_menu.cart_menu.007"),
          processing: true,
          onOk: async () => {
            setStripePaymentState(prevState => ({
              ...prevState,
              sheetVisible: false,
              onCancel: undefined,
              okText: undefined,
              processing: false,
              onOk: () => {},
              titleText: "",
              success: false,
              message: "",
            }));
            await clearReaderDisplay();
          },
          titleText: t("elements.register.cart_menu.cart_menu.008"),
          message: t("elements.register.cart_menu.cart_menu.009"),
        }));
      },
      cancelText: sheetInfo.cancelText,
      okText: sheetInfo.okText,
      sheetVisible: true,
      titleText: sheetInfo.title,
      message: sheetInfo.message,
      convertedCode: sheetInfo.convertedErrorCode,
      success: false,
      processing: false,
    }));
  };

  const handleStripeSuccess = (message?: string, order_id?: number) => {
    setStripePaymentState(prevState => ({
      ...prevState,
      sheetVisible: true,
      onCancel: undefined,
      okText: t("elements.register.cart_menu.cart_menu.011"),
      //resets state
      onOk: () => {
        setStripePaymentState(prevState => ({
          ...prevState,
          sheetVisible: false,
          onCancel: undefined,
          okText: undefined,
          processing: false,
          onOk: () => {},
          titleText: "",
          message: "",
        }));
      },
      processing: false,
      success: true,
      titleText: t("elements.register.cart_menu.cart_menu.012"),
      message: t("elements.register.cart_menu.cart_menu.013"),
    }));
    void updateOrder(order_id, true);
    setState(prevState => ({ ...prevState, page: "receipt" }));
    dispatch(showSuccess(t("elements.register.cart_menu.cart_menu.013")));
  };

  const handleLineItemClick = (lineItem: ILineItem) => {
    setLineItemState({
      ...lineItemState,
      isModalVisible: true,
      lineItem: lineItem,
      inputQuantity: String(lineItem.quantity),
      inputPrice: String(lineItem.price),
      inputNote: lineItem.note ? lineItem.note : "",
    });

    // setState(prevState => ({
    //   ...prevState,
    //   lineItemSheetVisible: true,
    //   lineItem: lineItem,
    //   quantity: String(lineItem.quantity),
    //   price: lineItem.price,
    //   note: lineItem.note ? lineItem.note : "",
    // }));
  };

  const handleCloseModifierGroups = async (deleteItem: boolean) => {
    if (props.modifierItem) {
      if (deleteItem) {
        await dispatch(deleteLineItem(lineItemState.lineItem.id, true));
      }
      props.setRegisterState({ modifierItem: null });
    }
    setState(prevState => ({
      ...prevState,
      modifierGroupsSheetOpen: false,
      allModifierGroups: [],
      selectedModifierGroupVariants: {},
    }));

    setLineItemState({
      ...lineItemState,
      isModalVisible: false,
      lineItem: undefined,
      inputQuantity: "",
      inputPrice: "",
      inputNote: "",
    });
  };

  function checkForDiscounts(parent_id: number) {
    const cartDiscounts = cart.discount_lines;
    const selectedLineItemParentID = lineItemState.lineItem.id;
    const discounts: Array<Record<string, any>> = [];

    if (cartDiscounts.length > 0) {
      cartDiscounts.forEach((discount: Record<string, any>) => {
        if (discount.cart_line_item_id === selectedLineItemParentID && discount?.value_type === "percent") {
          discounts.push(discount);
        }
      });
    }
    return discounts;
  }

  useEffect(() => {
    if (applyDiscount) {
      void filterModifiers();
    }
  }),
    [applyDiscount];

  async function filterModifiers() {
    setApplyDiscount(false);
    const cartLineItems = cart?.line_items;
    const parentLineItemID = lineItemState.lineItem.id;
    const discounts = checkForDiscounts(parentLineItemID);

    let cartRes: Omit<IAPIResponse, "message">;
    if (cartLineItems.length > 0) {
      for (const line_item of cartLineItems) {
        if (
          line_item.parent_id == parentLineItemID &&
          !cart?.discount_lines?.some(
            (discount_line: Record<string, any>) => discount_line?.cart_line_item_id === line_item?.id,
          ) &&
          line_item.price > 0
        ) {
          for (let i = 0; i < discounts.length; i++) {
            const discount = discounts[i];
            const res = await PostDiscountLine(
              {
                discount_id: discount.discount_id,
                cart_id: line_item.cart_id,
                cart_line_item_id: line_item.id,
                value: discount.value ? Number(discount.value) : undefined,
                value_type: discount.value_type,
              },
              true,
            );

            cartRes = res;
            if (res.status !== StatusCode.OK) {
              dispatch(showError("Error adding discount line"));
            }
          }
        }
      }
    }

    if (cartRes?.data !== undefined) {
      void dispatch(loadCart({ id: lineItemState.lineItem.cart_id }, true));
    }
  }

  const onModifiersOk = async (lineItemId: number) => {
    const allSelectedVariants = [];

    for (const key in state.selectedModifierGroupVariants) {
      allSelectedVariants.push(...state.selectedModifierGroupVariants[key]);
    }

    for (const product of allSelectedVariants) {
      const modifierRes = await dispatch(postLineItem(cart.id, product.id, lineItemId, 1, true));
      if (modifierRes && modifierRes?.status === StatusCode.BAD_REQUEST) {
        dispatch(showError(t("elements.register.cart_menu.cart_menu.014")));
        await dispatch(postCart(null, false));
        return;
      }
    }
    setApplyDiscount(true);
    void handleCloseModifierGroups(false);
  };

  function disableSelectingModifiers(lineItem: Record<string, any>) {
    if (lineItem && cart?.line_items) {
      const requiredModifierGroups = state.allModifierGroups.filter(modifierGroup => modifierGroup.required);

      const lineItemModifiers = cart?.line_items.filter((cartLineItem: ICartLineItem) => {
        return cartLineItem.id !== lineItem.id && lineItem.id === cartLineItem.parent_id;
      });

      const modalRequiredModifierGroups: IModifierGroup[] = requiredModifierGroups.filter(modifierGroup => {
        return !modifierGroup.variants?.some(variant =>
          lineItemModifiers.some((lineItem: ICartLineItem) => lineItem.variant_id === variant.id),
        );
      });

      const allRequiredModifiersSelected = modalRequiredModifierGroups.every(modifierGroup => {
        return state.selectedModifierGroupVariants[modifierGroup.id.toString()]?.length > 0;
      });

      return !allRequiredModifiersSelected;
    } else {
      return true;
    }
  }

  function addModifierVariant(modifierGroup: IModifierGroup, variant: IVariant, selected: boolean) {
    const select = state.selectedModifierGroupVariants;
    if (modifierGroup.multi_select) {
      if (selected && select[modifierGroup.id.toString()].length >= 1) {
        if (modifierGroup.required && select[modifierGroup.id.toString()].length === 1) {
          return;
        }
        const selectedGroup = select[modifierGroup.id.toString()];
        select[modifierGroup.id.toString()] = selectedGroup.filter(filtered => filtered.id !== variant.id);
      } else {
        select[modifierGroup.id.toString()].push(variant);
      }
    } else {
      select[modifierGroup.id.toString()] = [variant];
    }

    setState(prevState => ({ ...prevState, selectedModifierGroupVariants: select }));
  }

  const openModifierGroups = async (productId: number) => {
    const modifierGroupResponse = await GetModifierGroup({ product_id: productId, extended: true }, true);

    if (modifierGroupResponse.status !== StatusCode.OK) {
      dispatch(showError("Loading modifier group failed"));
      return;
    }

    const select = state.selectedModifierGroupVariants;

    //Preselect the default modifier groups
    modifierGroupResponse.data?.forEach((modifier: IModifierGroup) => {
      select[modifier.id.toString()] = [];
      modifier.variants.forEach((variant: Record<string, any>) => {
        if (variant.modifier_group.default) {
          select[modifier.id.toString()].push(variant);
        }
      });
    });

    setState(prevState => ({
      ...prevState,
      selectedModifierGroupVariants: select,
      modifierGroupsSheetOpen: true,
      allModifierGroups: modifierGroupResponse.data,
    }));
  };

  const handleDiscountLineClick = (discountLine: Record<string, any>) => {
    setCancelDiscountState(prevState => ({
      ...prevState,
      selectedDiscountID: discountLine.id,
      cartID: discountLine.cart_id,
      showCancelDiscount: true,
    }));
  };

  const handleDiscountCancel = async () => {
    const res = await DeleteDiscountLine(
      { id: cancelDiscountState.selectedDiscountID, cart_id: cancelDiscountState.cartID },
      true,
    );

    if (res && res?.status === StatusCode.BAD_REQUEST) {
      dispatch(showError(t("elements.register.cart_menu.cart_menu.018")));
      return;
    }

    dispatch(showSuccess("Discount successfully removed"));
    setCancelDiscountState(prevState => ({
      ...prevState,
      showCancelDiscount: false,
    }));

    await dispatch(loadCart({ id: cartStore.cart.id }, true));
  };

  const handleCustomerSearch = (query: string) => {
    setState(prevState => ({ ...prevState, customerSearchQuery: query }));
  };

  const handleShowCustomerSearchChange = (showCustomerSearch: boolean) => {
    setState(prevState => ({ ...prevState, showCustomerSearch: showCustomerSearch }));
  };

  const handleCustomerSelection = (id: string | number) => {
    if (cart?.id) {
      void dispatch(putCustomer(cart.id, parseInt(id.toString()), true));
    }
    // if (id === undefined || id === null || id === "") {
    //   return;
    // } else {
    //   const selectedCustomer = state.customerSearchResults.find(customer => customer?.id === id);

    //   console.log(selectedCustomer);
    //   if (selectedCustomer && props?.cart?.id) {
    //     void props.cartActions.putCustomer(cart.id, parseInt(id.toString()), true);
    //   }
    // }
  };

  const removeCustomerFromCart = () => {
    if (cart?.id) {
      void dispatch(putCustomer(cart.id, null, true));
      handleShowCustomerSearchChange(false);
      handleCustomerSearch("");
    }
  };

  const initiateAddGiftCard = () => {
    setState(prevState => ({ ...prevState, giftCardActive: true }));
  };
  const handleCancelAddGiftCard = () => {
    setState(prevState => ({ ...prevState, giftCardActive: false, gift_card_code: "", gift_card_pin: "" }));
  };

  // Check for specific products like gift cards or green fees, or if a customer is required
  const checkProducts = () => {
    const gift_cards: any[] = [];
    const filteredGiftCards: any[] = cart.line_items?.filter(
      (line_item: Record<string, any>) => line_item?.product?.type === "Gift Card",
    );
    const filteredLeagueFees = cart.line_items?.filter(
      (line_item: Record<string, any>) => line_item?.product?.type === "League Registration Fee",
    );
    const customerRequiredFees = cart.line_items?.filter(
      (line_item: Record<string, any>) => line_item?.product?.customer_required as boolean,
    );

    if (filteredLeagueFees?.length > 0 && !cart?.customer_id) {
      dispatch(showError(t("elements.register.cart_menu.cart_menu.078")));
      return;
    }

    // Check if cart contains a product that has the customer_required flag
    if (customerRequiredFees?.length > 0 && !cart?.customer_id) {
      dispatch(showError(t("elements.register.cart_menu.cart_menu.079")));
      return;
    }

    //Open gift cards modal if cart contains gift cards
    if (filteredGiftCards?.length > 0 || filteredLeagueFees?.length > 0) {
      //Check for gift cards
      if (filteredGiftCards.length > 0) {
        filteredGiftCards.forEach((line_item: Record<string, any>, index: number) => {
          for (let i = 0; i < line_item?.quantity; i++) {
            gift_cards.push({
              product_id: line_item.product_id,
              cart_line_item_id: line_item.id,
              code: null,
              pin: null,
              random: false,
              balance: line_item.price,
              reload: 0,
            });
          }
        });
        setState(prevState => ({ ...prevState, gift_cards, sellGiftCardsActive: true }));
      }
      //Check for league registration fees
      if (filteredLeagueFees?.length > 0) {
        setState(prevState => ({ ...prevState, leagueFeesActive: true, leagueFeeProducts: filteredLeagueFees }));
      }
    } else {
      //Go directly to payment methods page if no gift cards / league fees in cart
      setState(prevState => ({
        ...prevState,
        page: "methods",
        gift_cards: null,
        leagueFeeProducts: null,
        selectedLeagues: null,
      }));
    }
  };

  const setSelectedLeagues = (leagues: Array<number>) => {
    const moveToPaymentMethods = checkToContinuePayment("leagues");
    setState(prevState => ({
      ...prevState,
      selectedLeagues: leagues,
      page: moveToPaymentMethods ? "methods" : "cart",
      leagueFeesActive: false,
      leagueFeeProducts: null,
    }));
  };

  function cancelGiftCardsAndLeagues() {
    setState(prevState => ({
      ...prevState,
      page: "cart",
      sellGiftCardsActive: false,
      gift_cards: null,
      leagueFeesActive: false,
      leagueFeeProducts: null,
      selectedLeagues: null,
    }));
  }

  const validateGiftCardCode = async (code: string, reload: number) => {
    const validateRes = await GetGiftCardValidate({ code, reload }, true);
    if (validateRes.status !== StatusCode.OK) {
      dispatch(showError(t("elements.register.cart_menu.cart_menu.019")));
      return false;
    }
    return true;
  };

  const handleAddSellGiftCards = async () => {
    let validateRes = true;
    //Validate gift card codes
    for (const gift_card of state.gift_cards) {
      if (!gift_card.random) {
        validateRes = await validateGiftCardCode(gift_card.code, gift_card.reload);
      }
      if (validateRes === false) {
        return;
      }
    }

    if (validateRes) {
      const moveToPaymentMethods = checkToContinuePayment("gift_card");
      setState(prevState => ({
        ...prevState,
        page: moveToPaymentMethods ? "methods" : "cart",
        sellGiftCardsActive: false,
      }));
      dispatch(showSuccess(t("elements.register.cart_menu.cart_menu.020")));
    }
  };

  const checkToContinuePayment = (type: "gift_card" | "leagues") => {
    switch (type) {
      case "gift_card":
        if (!state.selectedLeagues && state.leagueFeesActive) {
          return false;
        }
        return true;
      case "leagues":
        if (state.sellGiftCardsActive) {
          return false;
        }
        return true;
      default:
        return true;
    }
  };

  const handleCloseSellGiftCards = () => {
    setState(prevState => ({ ...prevState, sellGiftCardsActive: false, gift_cards: null }));
  };

  //Check every card to see if either the fields are filled or the random checkbox is checked
  const sellGiftCardFieldsEmpty = !state.gift_cards?.every(card =>
    Object.values(card).every(cardAttr => {
      if (card.random === true) {
        return true;
      }
      return cardAttr != null;
    }),
  );
  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setState(prevState => ({ ...prevState, [id]: value }));
  }

  useEffect(() => {
    if (state.selectedAccount || state.selectedCreditBook) {
      void handleProcessPayment();
    }
    return () => {
      setState(prevState => ({ ...prevState, selectedAccount: undefined, selectedCreditBook: undefined }));
    };
  }, [state.selectedAccount, state.selectedCreditBook]);

  useEffect(() => {
    if (paymentStatus === "completed" && tempStripePaymentOrder === null) {
      void clearCartAsync(state.order);
      setState(prevState => ({ ...prevState, serverPaymentSheetVisible: false }));
    }

    if ((paymentStatus === "partial_payment" || paymentStatus === "paid") && tempStripePaymentOrder !== null) {
      setState(prevState => ({
        ...prevState,
        order: tempStripePaymentOrder,
        paymentAmount: tempStripePaymentOrder.balance.toFixed(2),
        page: "methods",
      }));
      setState(prevState => ({ ...prevState, serverPaymentSheetVisible: false }));
    }
  }, [paymentStatus, tempStripePaymentOrder, state.order]);

  function closeAccountsModal() {
    setState(prevState => ({
      ...prevState,
      accountsVisible: false,
      selectedAccount: undefined,
    }));
  }
  function closeCreditBookModal() {
    setState(prev => ({ ...prev, creditBookVisible: false, selectedCreditBook: undefined }));
  }

  function handleAccountsOk(selectedAccount: Record<string, any>) {
    setState(prevState => ({ ...prevState, accountsVisible: false, selectedAccount }));
  }

  function handleCreditBooksOk(selectedCreditBook: IPrizeAccount) {
    setState(prevState => ({ ...prevState, creditBookVisible: false, selectedCreditBook }));
  }

  const defaultRoomChargeSheetState: Partial<IMainState> = {
    roomOccupantSearchQuery: "",
    roomOccupantsSearchResults: [],
    selectedOccupant: null,
  };

  function initiateRoomChargeSheet() {
    setState(prevState => ({
      ...prevState,
      roomChargeSheetVisible: true,
      ...defaultRoomChargeSheetState,
    }));
  }

  function closeRoomChargeSheet() {
    setState(prevState => ({
      ...prevState,
      roomChargeSheetVisible: false,
      ...defaultRoomChargeSheetState,
    }));
  }

  function handleOccupantSearchQueryChange(event: any) {
    setState(prevState => ({ ...prevState, roomOccupantSearchQuery: event.target.value }));
  }

  function handleProcessPaymentCheck() {
    console.log(state.paymentOption);
    console.log("orderID??", cart);

    if (state.paymentOption?.payment_method === "gift_card") {
      void initiateAddGiftCard();
    } else if (state.paymentOption?.payment_method === "account" && cart.customer?.id) {
      setState(prevState => ({ ...prevState, accountsVisible: true }));
    } else if (state.paymentOption?.payment_method === "credit_book") {
      setState(prevState => ({ ...prevState, creditBookVisible: true }));
    } else if (
      state.paymentOption?.payment_method === "room_charge_rdp" ||
      state.paymentOption?.payment_method === "maestro"
    ) {
      initiateRoomChargeSheet();
    } else {
      void handleProcessPayment();
    }
  }

  function handleCardSectionChange(
    e: StripeCardNumberElementChangeEvent | StripeCardExpiryElementChangeEvent | StripeCardCvcElementChangeEvent,
  ) {
    setManualStripeState(prevState => ({
      ...prevState,
      elementComplete: { ...prevState.elementComplete, [e.elementType]: e.complete },
    }));
  }

  async function handlePrintReceiptButton() {
    const receiptRes = await GetReceipt({ id: cart?.id, type: "cart" }, true);
    if (receiptRes.status !== StatusCode.OK) {
      dispatch(showError("Loading receipt failed"));
      return;
    }
    window.open().document.write(receiptRes.data);
  }

  async function closeOrderCompletePopup(open: boolean) {
    // Create another empty cart
    dispatch(enqueue());
    await dispatch(postCart(null, false));
    dispatch(dequeue());
    setState(prevState => ({ ...prevState, orderActionBarActive: open, page: "cart", order: undefined }));
    props.setRegisterState({ order: undefined });
  }

  async function cancelOrder() {
    const cancelRes = await PutVoidOrder(
      { order_id: state?.order?.id, notes: "Transaction cancelled", financial_status: "cancelled" },
      true,
    );

    if (cancelRes.status !== StatusCode.OK) {
      dispatch(showError("Error cancelling order"));
      return;
    }
    dispatch(showSuccess("Successfully cancelled order"));
    void navigateBack();
    setState(prevState => ({
      ...prevState,
      order: undefined,
      showCancelOrder: false,
      paymentAmount: cart ? Number(cart.total_price.toFixed(2)) : prevState?.paymentAmount,
    }));
    props.setRegisterState({ order: undefined });
  }

  const renderContext = () => {
    if (state.page === "methods") {
      return (
        <div className="flex flex-1 flex-col">
          {state?.order ? (
            <Button
              onClick={() => setState(prevState => ({ ...prevState, showCancelOrder: true }))}
              type="text"
              className="p-0"
            >
              <div className="flex items-center gap-2">
                <FontAwesomeIcon icon={["far", "long-arrow-left"]} />
                <span>{t("elements.register.cart_menu.cart_menu.022")}</span>
              </div>
            </Button>
          ) : (
            <Button onClick={navigateBack} type="text" className="p-0">
              <div className="flex items-center gap-2">
                <FontAwesomeIcon icon={["far", "long-arrow-left"]} />
                <span>{t("elements.register.cart_menu.cart_menu.023")}</span>
              </div>
            </Button>
          )}
          <div className="flex flex-1 flex-col h-full overflow-y-scroll no-scrollbar">
            <CartDiscountLines currency={cart?.currency} discountLines={cart?.discount_lines} />

            <CartTotals
              currency={cart?.currency}
              subtotal_price={cart?.subtotal_price}
              tax_lines={cart?.tax_lines}
              total_tip={cart?.total_tip}
              total_price={cart?.total_price}
              order={state?.order as IOrder}
            />

            <CartTransactions transactions={state?.order?.transactions} currency={state?.order?.currency} />

            <Divider />

            <PaymentMethods
              onChange={handlePaymentOptionChange}
              paymentOptions={facilityStore?.paymentOptions}
              cardReaderAvailable={reader === null ? false : true}
              cart={cart}
              paymentAmount={state.paymentAmount}
            />
          </div>

          <div className="mt-auto">
            <div className="flex flex-row justify-end align-center mt-4">
              <Input
                label={t("elements.register.cart_menu.cart_menu.024")}
                prefix="$"
                placeholder={String(state.paymentAmount)}
                onChange={changePaymentAmount}
                value={String(state.paymentAmount)}
              />
            </div>
            <Divider />

            <div className="mb-3">
              {state?.paymentInProgress ? (
                <div className="flex justify-center">
                  <div className="h-8 w-8">
                    <Spin className="admin-header-spinner" />
                  </div>
                </div>
              ) : (
                <Button
                  type="primary"
                  block
                  disabled={!state.paymentOption?.payment_method}
                  onClick={handleProcessPaymentCheck}
                >
                  {state.paymentOption?.payment_method === "gift_card"
                    ? t("elements.register.cart_menu.cart_menu.053")
                    : t("elements.register.cart_menu.cart_menu.077")}
                </Button>
              )}
            </div>
          </div>
        </div>
      );
    }

    if (state.page === "receipt") {
      return (
        <div className="flex flex-1 flex-col">
          <span className="mr-auto text-2xl text-semibold">
            {t("elements.register.cart_menu.cart_menu.080")} {state?.order?.name}
          </span>

          <CartLineItems lineItems={state?.order.line_items} handleLineItemClick={undefined} />

          <div className="mt-auto">
            <div className="flex">
              <div className="flex ml-auto flex-col w-64">
                <CartDiscountLines currency={state?.order.currency} discountLines={state?.order.discount_lines} />

                <CartTotals
                  currency={state?.order?.currency}
                  subtotal_price={state?.order?.subtotal_price}
                  tax_lines={state?.order?.tax_lines}
                  total_tip={state?.order?.total_tip}
                  total_price={state?.order?.total_price}
                  order={state.order as IOrder}
                />

                <CartTransactions transactions={state?.order?.transactions} currency={state?.order?.currency} />
              </div>
            </div>
          </div>
          <Divider />

          {state?.order.balance <= 0 ? (
            <>
              <div className="mb-3">
                <Button
                  type="primary"
                  block
                  icon={<FontAwesomeIcon icon="long-arrow-right" />}
                  onClick={() => setState(prevState => ({ ...prevState, page: "cart", order: undefined }))}
                >
                  {t("elements.register.cart_menu.cart_menu.025")}
                </Button>
              </div>
              <div>
                <Button type="default" block onClick={() => loadReceipt(state.order.id)}>
                  <span>{t("elements.register.cart_menu.cart_menu.026")}</span>
                </Button>
              </div>
            </>
          ) : (
            <div className="mb-3">
              <Button
                type="primary"
                block
                disabled={!state.paymentOption?.payment_method}
                onClick={handleProcessPaymentCheck}
              >
                {state.paymentOption?.payment_method === "gift_card" ? "Enter Gift Card" : "Process Payment"}
              </Button>
            </div>
          )}
        </div>
      );
    }

    if (state.page === "cart") {
      return (
        <div className="flex flex-1 flex-col">
          <div className="ui-register-line-items">
            {cart && (
              <>
                {cart.customer ? (
                  <div>
                    <div className="flex flex-row justify-start">
                      <div className="mr-3 mt-2">
                        <FontAwesomeIcon icon={["far", "user"]} className="customer-image" />
                      </div>
                      <div>
                        <p className="text-md">{cart.customer.full_name}</p>
                        <p className="text-sm text-subdued">{cart.customer.email}</p>
                      </div>
                      <div className="ml-auto">
                        <a
                          style={{ marginRight: "0.8rem" }}
                          className="cursor-pointer text-lg"
                          onClick={() => removeCustomerFromCart()}
                        >
                          {t("elements.register.cart_menu.cart_menu.027")}
                        </a>
                      </div>
                    </div>
                    <hr className="mb-2"></hr>
                  </div>
                ) : !state.showCustomerSearch ? (
                  <div className="w-full text-center mb-1">
                    {cart?.status !== "complete" && (
                      <a
                        className="cursor-pointer text-lg text-semibold"
                        onClick={() => handleShowCustomerSearchChange(true)}
                      >
                        {t("elements.register.cart_menu.cart_menu.028")}
                      </a>
                    )}
                  </div>
                ) : (
                  <SelectCustomer
                    onCustomerChange={customer => handleCustomerSelection(customer.id)}
                    permissionLevel={"facility"}
                    newCustomer
                  />
                )}
              </>
            )}
          </div>

          {!cart ? (
            <div className="flex justify-end">
              <div className="h-8 w-8">
                <Spin className="admin-header-spinner" />
              </div>
            </div>
          ) : null}

          <CartLineItems
            lineItems={cart?.line_items}
            handleLineItemClick={
              cart?.status === "complete" ? undefined : lineItem => handleLineItemClick(lineItem as ILineItem)
            }
          />

          <div className="mt-auto">
            <CartDiscountLines
              currency={cart?.currency}
              discountLines={cart?.discount_lines}
              handleDiscountCancelClick={
                cart?.status === "complete" ? undefined : discountLine => handleDiscountLineClick(discountLine)
              }
            />

            <CartTotals
              currency={cart?.currency}
              subtotal_price={cart?.subtotal_price}
              tax_lines={cart?.tax_lines}
              total_tip={cart?.total_tip}
              total_price={cart?.total_price}
              order={state?.order as IOrder}
            />

            <Divider />
            <div className="mb-3">
              <Button
                type="primary"
                disabled={cart?.line_items?.length === 0 || cart?.status === "complete" ? true : false}
                block
                icon={cart?.status !== "complete" ? <FontAwesomeIcon icon="long-arrow-right" /> : undefined}
                onClick={checkProducts}
              >
                {cart?.status === "complete"
                  ? t("elements.register.cart_menu.cart_menu.075")
                  : t("elements.register.cart_menu.cart_menu.076")}
              </Button>
            </div>

            <div className="flex flex-row gap-2">
              <Button type="default" block onClick={handlePrintReceiptButton}>
                <span>{t("elements.register.cart_menu.cart_menu.026")}</span>
              </Button>
              {cart?.status !== "complete" ? (
                <Button
                  type="default"
                  block
                  disabled={cart?.status === "complete" ? true : false}
                  onClick={() => {
                    setState(prevState => ({ ...prevState, clearCartConfirmVisible: true }));
                  }}
                >
                  <span>{t("elements.register.cart_menu.cart_menu.030")}</span>
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div className="ui-register-menu_content">
        {/* Pop up to clear a cart */}
        <Popup
          open={state.clearCartConfirmVisible}
          type="warning"
          title={t("elements.register.cart_menu.cart_menu.031")}
          description={t("elements.register.cart_menu.cart_menu.032")}
          onOk={() => {
            void handleClearCart();
            setState(prevState => ({
              ...prevState,
              clearCartConfirmVisible: false,
              gift_cards: null,
              selectedLeagues: null,
              order: undefined,
            }));
          }}
          okText={t("elements.register.cart_menu.cart_menu.033")}
          cancelText={t("elements.register.cart_menu.cart_menu.081")}
          onCancel={() => {
            setState(prevState => ({ ...prevState, clearCartConfirmVisible: false }));
          }}
          backDropCancel={true}
        />

        {/* Payment Handler Sheet - Handles successes/errors */}
        <>
          {stripePaymentState.processing ? (
            <Popup
              type="info"
              open={stripePaymentState.sheetVisible}
              title={t("elements.register.cart_menu.cart_menu.034")}
              description={t("elements.register.cart_menu.cart_menu.035")}
              closable
              onCancel={stripePaymentState.onCancel}
              onOk={stripePaymentState.onOk}
              cancelText={stripePaymentState.cancelText}
              okText={stripePaymentState.okText}
              backDropCancel={false}
            />
          ) : (
            <>
              {stripePaymentState.success ? (
                <Popup
                  type="success"
                  open={stripePaymentState.sheetVisible}
                  title={t("elements.register.cart_menu.cart_menu.036")}
                  description={t("elements.register.cart_menu.cart_menu.037")}
                  closable
                  onCancel={stripePaymentState.onCancel}
                  onOk={stripePaymentState.onOk}
                  cancelText={stripePaymentState.cancelText}
                  okText={stripePaymentState.okText}
                  backDropCancel={false}
                />
              ) : (
                <Popup
                  type="error"
                  open={stripePaymentState.sheetVisible}
                  title={t("elements.register.cart_menu.cart_menu.038")}
                  description={
                    stripePaymentState.message +
                    t("elements.register.cart_menu.cart_menu.039") +
                    stripePaymentState.convertedCode
                  }
                  closable
                  onCancel={stripePaymentState.onCancel}
                  onOk={stripePaymentState.onOk}
                  cancelText={stripePaymentState.cancelText}
                  okText={stripePaymentState.okText}
                  backDropCancel={false}
                />
              )}
            </>
          )}
        </>

        {/* Manual Credit Card Sheet*/}
        <Sheet
          size={"small"}
          closable
          title={t("elements.register.cart_menu.cart_menu.040")}
          cancelText={"Cancel"}
          open={manualStripeState.visible}
          okText={t("elements.register.cart_menu.cart_menu.041")}
          okDisabled={!elementsComplete}
          cancelDisabled={manualProcessing}
          backDropCancel={false}
          onOk={() => {
            if (!submitTrigger) {
              setSubmitTrigger(!submitTrigger);
            }
          }}
          onCancel={async () => {
            await cancelManualCreditPaymentIntent(
              manualStripeState?.transaction?.payment_intent_id,
              manualStripeState?.transaction?.id,
            );
            if (elements) {
              elements.getElement(CardNumberElement).clear();
              elements.getElement(CardExpiryElement).clear();
              elements.getElement(CardCvcElement).clear();
            }
            setManualStripeState(prevState => ({
              ...prevState,
              visible: false,
              transaction: {},
            }));
          }}
        >
          <CheckoutForm
            onChange={handleCardSectionChange}
            onSubmit={() => handleManualProcessing()}
            trigger={submitTrigger}
            transaction={manualStripeState.transaction}
            onError={(message: string) => {
              setSubmitTrigger(false);
              setManualProcessing(false);
              dispatch(showError(message));
            }}
            onSuccess={async () => {
              // Clean values
              dispatch(showSuccess(t("elements.register.cart_menu.cart_menu.042")));
              setManualStripeState(prevState => ({
                ...prevState,
                visible: false,
                transaction: {},
                elementComplete: {
                  cardCvc: false,
                  cardExpiry: false,
                  cardNumber: false,
                },
              }));
              setSubmitTrigger(false);
              dispatch(enqueue());
              await updateOrder(state.order.id, true);
              dispatch(dequeue());
            }}
          />
        </Sheet>
        {/** Seperate Payment for Stripe used for server payments - uses hook that should be updated to handle network based payments */}
        <StripeTerminalSheet />

        {/** Sheet to handle line-item quantity */}
        {lineItemState.lineItem && (
          <UpdateLineItemModal
            lineItemState={lineItemState}
            cart={cart}
            setLineItemState={setLineItemState}
            permissions={permissions}
            discounts={facilityStore?.discountOptions}
            dataOrigin="redux"
            openModifiersSheet={openModifierGroups}
            modalActions={{
              onCancel: () => setLineItemState({ ...lineItemState, lineItem: null, isModalVisible: false }),
            }}
          />
        )}

        <SelectModifierSheet
          open={state.modifierGroupsSheetOpen}
          onCancel={() => handleCloseModifierGroups(true)}
          onOk={() => onModifiersOk(lineItemState.lineItem.id)}
          okDisabled={disableSelectingModifiers(lineItemState.lineItem)}
          allModifierGroups={state.allModifierGroups}
          selectedModifierGroupVariants={state.selectedModifierGroupVariants}
          addModifierVariant={addModifierVariant}
        />

        <GiftCardRedeem
          redeemGiftCardsActive={state.giftCardActive}
          cart={cartStore?.cart}
          onCancel={handleCancelAddGiftCard}
          onOk={handleProcessPayment}
        />

        <GiftCardSell
          gift_cards={state.gift_cards}
          sellGiftCardsActive={state.sellGiftCardsActive}
          onOk={handleAddSellGiftCards}
          onCancel={cancelGiftCardsAndLeagues}
        />

        <AccountsModal
          loadAccounts={state.accountsVisible}
          onCancel={closeAccountsModal}
          onOk={handleAccountsOk}
          customerId={cart?.customer?.id}
          searchAccountPermission={permissions?.accounts_charge_all}
        />

        <CreditBookModal
          loadCreditBooks={state.creditBookVisible}
          onCancel={closeCreditBookModal}
          onOk={handleCreditBooksOk}
          customerId={cart?.customer?.id}
          // searchAccountPermission={permissions?.credit}
        />

        <LeagueSelectionModal
          open={state.leagueFeesActive}
          leagueFeeProducts={state.leagueFeeProducts}
          onCancel={cancelGiftCardsAndLeagues}
          onOk={setSelectedLeagues}
        />

        <Sheet
          title={t("elements.register.cart_menu.cart_menu.064")}
          open={state.roomChargeSheetVisible}
          size="small"
          closable
          onCancel={closeRoomChargeSheet}
          onOk={handleProcessPayment}
          okDisabled={isEmpty(state.selectedOccupant?.guest_name)}
          okText={t("elements.register.cart_menu.cart_menu.065")}
        >
          <Input
            label={t("elements.register.cart_menu.cart_menu.066")}
            helpText={t("elements.register.cart_menu.cart_menu.067")}
            type="search"
            onChange={handleOccupantSearchQueryChange}
            value={state.roomOccupantSearchQuery}
          />
          {state.roomOccupantsSearchResults.map((occupant, index) => {
            return (
              <div key={index} className="flex flex-row justify-between p-3 border-b">
                <Checkbox
                  size="small"
                  checked={isEqual(occupant, state.selectedOccupant)}
                  onChange={e =>
                    setState(prev => ({
                      ...prev,
                      selectedOccupant: cloneDeep(occupant),
                    }))
                  }
                  label={occupant.guest_name}
                />
              </div>
            );
          })}
        </Sheet>

        <Popup
          type="warning"
          open={state.showCancelOrder}
          title={t("elements.register.cart_menu.cart_menu.068")}
          description={t("elements.register.cart_menu.cart_menu.069")}
          closable
          onOk={cancelOrder}
          onCancel={() => setState(prevState => ({ ...prevState, showCancelOrder: false }))}
          okText={t("elements.register.cart_menu.cart_menu.063")}
        />

        <Popup
          type="info"
          open={state.changePopUpVisible}
          title={`${t("elements.register.cart_menu.cart_menu.070")} ${state.changeDue?.toFixed(2)}`}
          description=""
          closable
          onOk={() => setState(prevState => ({ ...prevState, changePopUpVisible: false, changeDue: null }))}
          okText={t("elements.register.cart_menu.cart_menu.071")}
          backDropCancel={false}
          stacked
        />

        <Popup
          type="warning"
          open={cancelDiscountState.showCancelDiscount}
          title={t("elements.register.cart_menu.cart_menu.082")}
          description={t("elements.register.cart_menu.cart_menu.083")}
          closable
          onOk={() => {
            void handleDiscountCancel();
          }}
          onCancel={() => setCancelDiscountState(prevState => ({ ...prevState, showCancelDiscount: false }))}
          okText={t("elements.register.cart_menu.cart_menu.084")}
        />
        {renderContext()}
      </div>

      <OrderCompletePopup
        open={state.orderActionBarActive}
        setOpen={(open: boolean) => closeOrderCompletePopup(open)}
        order={state.order}
      />
    </>
  );
}
