import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Redux
import { IAuthState } from "redux/reducers/auth";
import { IUIActions } from "redux/actions/ui";
import { IFacilityState } from "redux/reducers/facility";

import { StatusCode } from "api/protocols";
import { GetAccountingReference } from "api/rpc/facility";
import { AddTicketToVariant, DeleteTicketFromVariant } from "api/rpc/ticket";
import { GetTickets } from "api/rpc/2022-09/facilityAdmin/ticket/ticket";
import {
  GetVariant,
  PutVariant,
  GetInventoryLevels,
  UpdateInventoryLevels,
} from "api/rpc/facilityAdmin/product/products";
import { ArchiveVariant } from "api/rpc/2024-04/facilityAdmin/product/variant";

import { displayPercent } from "helpers/Helpers";
import { LocaleCurrency } from "helpers/Locale";

import Page from "components/page/Page";
import Card from "components/card/Card";
import { ButtonNew as Button } from "components/buttonNew";
import FormLayout from "components/form/FormLayout";
import Checkbox from "components/form/checkbox/Checkbox";
import Input from "components/form/input/Input";
import { Select } from "components/select/";
import TextStyle from "components/text/textStyle/TextStyle";
import Stack from "components/stack/Stack";
import Sheet from "components/sheet/Sheet";
import Tabs from "components/tabs/Tabs";

import "pages/secure/facility/product/Variant.scss";
import Popup from "components/popup/Popup";
import Toggle from "components/form/toggle/Toggle";
import TextField from "components/form/textField/TextField";
import { DuplicateProduct, DuplicateVariant } from "api/rpc/2022-09/facilityAdmin/product/product";
import { isEqualWith, isNull } from "lodash";

interface IVariantState {
  id: number;
  title: string;
  option1: string;
  option2: string;
  option3: string;
  price: number;
  original_price: number;
  cost: number;
  taxable: boolean;
  barcode: string;
  sku: string;
  editing: boolean;
  product: any;
  inventory_levels: any;
  quantity_available: number;
  [key: string]: any;
  showTickets: boolean;
  accounting_reference_id: number;
  cannot_be_sold: boolean;
  meta?: {
    variant_id: number;
    cannot_be_sold?: number;
    facility_id?: number;
  };
  description: string;
  tickets?: IVariantTicket[];
}

interface IVariantTicket {
  id: number;
  title: string;
  value: any;
  included: string;
  quantity: number;
}

interface ISingleProps {
  authStore: IAuthState;
  uiActions: IUIActions;
  facilityStore: IFacilityState;
}

interface IUpdateInventoryState {
  updateInventoryActive: boolean;
  newQuantity: number;
}

interface IOption {
  id: number;
  name: string;
  value: string;
  product_id: number;
}

interface IAddTicketState {
  addTicketActive: boolean;
  currentAddedTickets: ITicket[];
  allTickets: any;
  ticketsToUpdate?: Map<any, any>;
  ticketSearch: string;
}

interface ITicket {
  id: number;
  title: string;
  subtitle: string;
  value: any;
  included: string;
  quantity: number;
}

interface IAccountingReference {
  client_id: number;
  id: number;
  title: string;
}

export default function Single(props: any) {
  const { Option } = Select;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { productId, variantId }: any = useParams();
  const productPermissions = props?.authStore?.user?.permissions;
  const facilityPermissions = props?.facilityStore?.facility?.permissions;

  const [reloadVariant, setReloadVariant] = useState<boolean>(false);

  const initialSelectedState = 0;
  const [selected, setSelected] = useState(initialSelectedState);

  const [accountingReferences, setAccountingReferences] = useState<IAccountingReference[]>([]);
  const [duplicateVariantOpen, setDuplicateVariantOpen] = useState<boolean>(false);

  const [variantStateBeforeChanges, setVariantStateBeforeChanges] = useState<IVariantState>(undefined);
  const [ticketStateBeforeChanges, setTicketStateBeforeChanges] = useState<IAddTicketState>(undefined);
  const [variantLoaded, setVariantLoaded] = useState<boolean>(false);
  const [ticketsLoaded, setTicketsLoaded] = useState<boolean>(false);

  const initialVariantState: IVariantState = {
    id: null,
    title: "",
    option1: "",
    option2: "",
    option3: "",
    price: 0,
    original_price: 0,
    cost: 0,
    taxable: false,
    barcode: "",
    sku: "",
    editing: false,
    product: null,
    inventory_levels: null,
    optionList: [],
    quantity_available: null,
    showTickets: false,
    accounting_reference_id: null,
    cannot_be_sold: false,

    meta: {
      variant_id: null,
      cannot_be_sold: null,
      facility_id: null,
    },
    description: "",
  };
  const [variantState, setVariantState] = useState<IVariantState>(initialVariantState);

  const initialInventoryState: IUpdateInventoryState = {
    updateInventoryActive: false,
    newQuantity: null,
  };

  const [inventoryState, setInventoryState] = useState<IUpdateInventoryState>(initialInventoryState);

  const initialAddTicketState: IAddTicketState = {
    addTicketActive: false,
    currentAddedTickets: [],
    allTickets: [],
    ticketsToUpdate: new Map(),
    ticketSearch: "",
  };
  const [addTicketState, setAddTicketState] = useState<IAddTicketState>(initialAddTicketState);

  const [filterState, setFilterState] = useState({
    search: "",
    showAccountingReference: false,
    showDescription: false,
  });

  const defaultAccountingReference = accountingReferences.find(
    (ref: Record<string, any>) => ref?.id == variantState.accounting_reference_id,
  );

  useEffect(() => {
    ReactDOM.unstable_batchedUpdates(() => {
      setSelected(initialSelectedState);
      setVariantState(initialVariantState);
      setInventoryState(initialInventoryState);
      setAddTicketState(initialAddTicketState);
      setReloadVariant(true);
    });
  }, [variantId]);

  useEffect(() => {
    if (reloadVariant) {
      void loadVariant();
      void loadInventoryLevels();
      void loadAccountingReference();
      setReloadVariant(false);
    }
  }, [reloadVariant]);

  //preset the accounting reference search value
  useEffect(() => {
    if (defaultAccountingReference === undefined) {
      return;
    }

    setFilterState({
      ...filterState,
      search: defaultAccountingReference.title,
    });
  }, [defaultAccountingReference]);

  async function loadInventoryLevels() {
    const inventoryRes = await GetInventoryLevels(
      {
        variant_id: variantId,
        extended: true,
      },
      true,
    );

    if (inventoryRes.status !== StatusCode.OK) {
      return;
    }

    const inventory = inventoryRes.data;

    setVariantState(prev => ({
      ...prev,
      inventory_levels: inventory,
    }));
  }

  async function loadVariant() {
    const variantRes = await GetVariant({ id: variantId, extended: true }, true);
    if (variantRes.status !== StatusCode.OK) {
      return;
    }

    const variant = variantRes.data[0];

    let showTicket = false;

    if (variant.product.type === "Ticket") {
      showTicket = true;
    }

    const tempTicketList: ITicket[] = [];
    const tempTicketsToUpdate = new Map();

    for (let i = 0; i < variant.tickets.length; i++) {
      const tempTicket = {
        id: variant.tickets[i].id,
        title: variant.tickets[i].title,
        subtitle: variant.tickets[i].subtitle,
        value: variant.tickets[i].value,
        included: variant.tickets[i].included,
        quantity: variant.tickets[i].quantity,
      };

      tempTicketList.push(tempTicket);
      tempTicketsToUpdate.set(variant.tickets[i].id, variant.tickets[i].quantity);
    }

    if (variant.description && variant.description.length > 0) {
      setFilterState(prevState => ({ ...prevState, showDescription: true }));
    }

    setVariantState(prev => ({
      ...prev,
      id: variant.id,
      title: variant.title,
      option1: variant.option1,
      option2: variant.option2,
      option3: variant.option3,
      price: variant.price,
      original_price: variant.original_price === null ? 0 : variant.original_price,
      cost: variant.cost,
      taxable: variant.taxable,
      barcode: variant.barcode === null ? "" : variant.barcode,
      sku: variant.sku === null ? "" : variant.sku,
      product: variant.product,
      quantity_available:
        variant.inventory_item.inventory_level.quantity_available === null
          ? 0
          : variant.inventory_item.inventory_level.quantity_available,
      showTickets: showTicket,
      accounting_reference_id: variant.accounting_reference_id,
      cannot_be_sold: variant.cannot_be_sold,
      meta: {
        cannot_be_sold: variant.meta.cannot_be_sold,
        variant_id: variant.id,
        facility_id: variant.meta.facility_id,
      },
      description: variant.description,
      tickets: variant.tickets,
    }));

    setAddTicketState(prev => ({
      ...prev,
      currentAddedTickets: tempTicketList,
      ticketsToUpdate: tempTicketsToUpdate,
    }));

    setVariantStateBeforeChanges(undefined);
    setTicketStateBeforeChanges(undefined);

    setTicketsLoaded(true);
    setVariantLoaded(true);
  }

  //loads dropdown AFTER the variantState has been loaded
  async function loadAccountingReference() {
    const accountingRes = await GetAccountingReference(null, false);
    if (accountingRes.status !== StatusCode.OK) {
      return;
    }

    //I do not believe we are using accounts variable after this ?
    const accounts = accountingRes.data;
    accounts.unshift({
      id: null,
      title: "",
    });

    setAccountingReferences(accountingRes.data);
  }

  function variantNavigationOptions() {
    const productId = variantState.product?.id;

    if (productId === undefined || productId === null || variantState.product.variants === undefined) {
      return [];
    }

    const currentVariantIndex: number = variantState.product.variants.findIndex(
      (variant: any) => variant.id === variantState.id,
    );

    if (currentVariantIndex === -1) {
      return [];
    }

    const variantNavigationOptionList = [];

    if (currentVariantIndex - 1 >= 0) {
      variantNavigationOptionList.push({
        content: <FontAwesomeIcon className="variant-navigation-arrow" icon={["far", "arrow-left"]} />,
        action: () => navigateToVariant(productId, variantState.product.variants[currentVariantIndex - 1].id),
      });
    }

    if (currentVariantIndex + 1 < variantState.product.variants.length) {
      variantNavigationOptionList.push({
        content: <FontAwesomeIcon className="variant-navigation-arrow" icon={["far", "arrow-right"]} />,
        action: () => navigateToVariant(productId, variantState.product.variants[currentVariantIndex + 1].id),
      });
    }

    return variantNavigationOptionList;
  }

  function navigateToVariant(productId: number, variantId: number) {
    const url = "/admin/product/" + String(productId) + "/variant/" + String(variantId);
    history.push(url);
  }

  async function saveVariant() {
    for (let i = 0; i < variantState.tickets?.length; i++) {
      const ticket = variantState.tickets[i];

      const addTicketToVariantRes = await AddTicketToVariant(
        {
          ticket_id: Number(ticket.id),
          variant_id: Number(variantState.id),
          quantity: Number(ticket.quantity),
        },
        true,
      );

      if (addTicketToVariantRes.status !== StatusCode.OK) {
        // uiActions.showError(addTicketToVariantRes.data.message);
        return;
      }
    }

    const variantRes = await PutVariant(variantState, true);
    if (variantRes.status !== StatusCode.OK) {
      props.uiActions.showError("Error updating variant"); // TODO: Translation
      return;
    }

    // setVariantState(variantRes.data);
    setVariantStateBeforeChanges(variantState);
    props.uiActions.showSuccess("Variant updated successfully"); // TODO: Translation

    return;
  }

  async function DeleteVariant() {
    const archiveRes = await ArchiveVariant({ id: variantId }, true);

    if (archiveRes.status !== StatusCode.OK) {
      props.uiActions.showError("Error archiving variant"); // TODO: Translation
      return;
    }

    props.uiActions.showSuccess("Variant archived successfully"); // TODO: Translation
    history.push("/admin/product/" + String(productId));
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setVariantState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleCheckboxChange(event: any) {
    const { id, checked } = event.target;
    setVariantState(prevState => ({ ...prevState, [id]: checked }));
  }

  const primaryAction = {
    content: t("secure.facility.product.variant.001"),
    action: () => console.log(addTicketState, ticketStateBeforeChanges),
    // action: () => saveVariant(),
  };

  function updateFilterStateBoolean(property: string) {
    setFilterState(prevState => ({ ...prevState, [property]: true }));
  }

  function openAddTicket() {
    setAddTicketState(prev => ({
      ...prev,
      addTicketActive: true,
    }));
  }

  function closeAddTicket() {
    setAddTicketState(prev => ({
      ...prev,
      addTicketActive: false,
      ticketSearch: "",
    }));
  }

  async function handleAddTicket(event: any, ticket: any) {
    //Add ticket ID to product?
    const params = {
      ticket_id: Number(ticket.id),
      variant_id: Number(variantState.id),
      quantity: 1,
    };

    const addTicketToVariantRes = await AddTicketToVariant(params, true);
    if (addTicketToVariantRes.status !== StatusCode.OK) {
      return;
    }

    closeAddTicket();

    //Reload Product
    void loadVariant();
  }

  async function handleDeleteTicket(event: any, ticket: any) {
    const params = {
      ticket_id: Number(ticket.id),
      variant_id: Number(variantState.id),
    };

    const deleteTicketRes = await DeleteTicketFromVariant(params, true);
    if (deleteTicketRes.status !== StatusCode.OK) {
      return;
    }

    void loadVariant();
  }

  function handleTicketQuantityChange(event: any, ticket: any) {
    const newQuantity = Number(event.target.value);
    const newState = variantState.tickets?.map((obj: IVariantTicket) => {
      if (obj.id === ticket.id) {
        return { ...obj, quantity: newQuantity };
      }

      return obj;
    });

    setVariantState(prevState => ({
      ...prevState,
      tickets: newState,
    }));
  }

  // function handleTicketQuantityChange(event: any, ticket: any) {
  //   const newQuantity = event.target.value;
  //   const tempTicketsToUpdate = addTicketState.ticketsToUpdate;

  //   tempTicketsToUpdate.set(ticket.id, newQuantity);

  //   setAddTicketState((prev) => ({
  //     ...prev,
  //     ticketsToUpdate: tempTicketsToUpdate,
  //   }));
  // }

  const tabs = [
    {
      id: "update-invtentory",
      content: t("secure.facility.product.variant.002"),
    },
    {
      id: "update-inventory-log",
      content: t("secure.facility.product.variant.003"),
    },
  ];

  function initiateUpdateInventory() {
    setInventoryState(prevState => ({ ...prevState, updateInventoryActive: true }));
  }

  function handleQuantityChange(event: any) {
    setInventoryState(prevState => ({ ...prevState, newQuantity: event.target.value }));
  }

  function closeUpdateInventory() {
    setInventoryState(prevState => ({ ...prevState, updateInventoryActive: false, newQuantity: 0 }));
  }

  // Handle tab change
  const handleTabChange = (selectedTabIndex: number) => {
    setSelected(selectedTabIndex);
  };

  async function completeUpdateInventory() {
    const params = {
      variant_id: variantState.id,
      quantity_available: inventoryState.newQuantity,
    };

    const updateInventoryRes = await UpdateInventoryLevels(params, true);
    if (updateInventoryRes.status !== StatusCode.OK) {
      return;
    }

    closeUpdateInventory();

    //Force refresh
    void loadVariant();
    void loadInventoryLevels();
  }

  // Search Tickets
  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (async () => {
          if (addTicketState.ticketSearch !== null) {
            try {
              const res = await loadTickets(addTicketState.ticketSearch);
              console.log("----> ticketRes", res);
              if (mounted) {
                setAddTicketState(prevState => ({ ...prevState, allTickets: res }));
              }
            } catch (error) {
              console.log("err", error);
            }
            return;
          }
        })();
      }, 1000);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [addTicketState.ticketSearch]);

  async function loadTickets(search: any) {
    const ticketRes = await GetTickets({ search: search }, true);

    if (ticketRes.status === StatusCode.OK) {
      setAddTicketState(prevState => ({
        ...prevState,
        allTickets: ticketRes.data,
      }));

      return ticketRes.data;
    }

    return [];
  }

  function handleSelectOnSearch(query: string) {
    if (query.length === 0) {
      setVariantState({ ...variantState, accounting_reference_id: null });
    }

    setFilterState({ ...filterState, search: query });
  }

  async function duplicateVariant() {
    const res = await DuplicateVariant({ variant_id: variantId }, true);

    if (res.status !== StatusCode.OK) {
      props.uiActions.showError("Failed to get the duplicate variant");

      return;
    }

    setDuplicateVariantOpen(false);
    history.push("/admin/product/" + String(productId));
  }

  function handleToggleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, checked } = event.target;

    setVariantState(prevState => ({ ...prevState, meta: { ...prevState.meta, [id]: Number(checked) } }));
  }

  function handleShowDescription() {
    if (filterState.showDescription) {
      setFilterState(prevState => ({ ...prevState, showDescription: false }));
      setVariantState(prevState => ({ ...prevState, description: "" }));
    } else {
      setFilterState(prevState => ({ ...prevState, showDescription: true }));
    }
  }

  function unsavedChangesExist() {
    if (variantStateBeforeChanges === undefined) {
      if (variantLoaded) {
        setVariantStateBeforeChanges(variantState);
      }
      return false;
    }

    if (ticketStateBeforeChanges === undefined) {
      if (ticketsLoaded) {
        setTicketStateBeforeChanges(addTicketState);
      }
      return false;
    }

    return (
      !isEqualWith(variantStateBeforeChanges, variantState, (originalValue, newValue) => {
        if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
          return true;
        }
      }) ||
      !isEqualWith(
        ticketStateBeforeChanges.ticketsToUpdate,
        addTicketState.ticketsToUpdate,
        (originalValue, newValue) => {
          if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
            return true;
          }
        },
      )
    );
  }

  function cancelUnsavedChanges() {
    setVariantState(variantStateBeforeChanges);
    setAddTicketState(ticketStateBeforeChanges);
  }

  return (
    <>
      <Page
        title={variantState.title}
        subtitle={String(variantState.product?.title) + " - " + String(variantState.product?.subtitle)}
        narrow
        // primaryAction={primaryAction}
        secondaryActions={variantNavigationOptions()}
        multipleActionDropdownAction={{
          label: "Options",
          dropdownProps: {
            alignment: "right",
            options: [
              {
                type: "handler",
                label: "Archive",
                handler: () => DeleteVariant(),
                disabled: !productPermissions?.products_edit || !facilityPermissions?.products_edit,
                icon: "trash",
              },
            ],
          },
        }}
        breadcrumbs={[
          {
            prefix: true,
            label: t("secure.facility.product.variant.004"),
            url: "/admin/product/" + String(variantState.product?.id),
          },
        ]}
        notificationBarProps={{
          isVisible: unsavedChangesExist(),
          onAction: saveVariant,
          onCancel: cancelUnsavedChanges,
        }}
      >
        <Page.Section twoThirds>
          <Card
            title={t("secure.facility.product.variant.005")}
            titleActions={[
              {
                content: filterState.showDescription
                  ? t("secure.facility.product.variant.030")
                  : t("secure.facility.product.variant.031"),
                action: handleShowDescription,
              },
            ]}
          >
            <Card.Section>
              <FormLayout>
                <FormLayout.Group>
                  {variantState.product?.options?.map((option: IOption, index: number) => {
                    const optionName = `option${index + 1}`;
                    const label =
                      variantState.product.options.length === 1
                        ? t("secure.facility.product.variant.006")
                        : option.name;

                    return (
                      <div key={index}>
                        <Input
                          label={label}
                          value={variantState[optionName]}
                          type="text"
                          id={`option${index + 1}`}
                          onChange={handleInputChange}
                          readOnly={!productPermissions?.products_edit || !facilityPermissions?.products_edit}
                        />
                      </div>
                    );
                  })}
                </FormLayout.Group>

                <FormLayout.Group>
                  <div>
                    {filterState.showDescription ? (
                      <TextField
                        value={variantState.description || ""}
                        label={t("secure.facility.product.variant.032")}
                        id="description"
                        onChange={handleInputChange}
                        placeholder={t("secure.facility.product.variant.033")}
                      />
                    ) : null}
                  </div>
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>
            <Card.Section>
              <FormLayout>
                <FormLayout.Group>
                  <Input
                    prefix="$"
                    value={variantState.price}
                    label={t("secure.facility.product.variant.007")}
                    id="price"
                    onChange={handleInputChange}
                    placeholder="0.00"
                    readOnly={
                      !productPermissions?.products_edit ||
                      !productPermissions?.products_edit_price ||
                      (!variantState.product?.meta && variantState.product?.price_locked) ||
                      (variantState.product?.meta && variantState.product?.meta?.price_locked)
                    }
                  />
                  <Input
                    prefix="$"
                    value={variantState.original_price}
                    label={t("secure.facility.product.variant.008")}
                    id="original_price"
                    onChange={handleInputChange}
                    placeholder="0.00"
                    readOnly={
                      !productPermissions?.products_edit ||
                      !productPermissions?.products_edit_price ||
                      (!variantState.product?.meta && variantState.product?.price_locked) ||
                      (variantState.product?.meta && variantState.product?.meta?.price_locked)
                    }
                  />
                  <Input
                    prefix="$"
                    value={variantState.cost}
                    label={t("secure.facility.product.variant.009")}
                    id="cost"
                    onChange={handleInputChange}
                    placeholder="0.00"
                    readOnly={
                      !productPermissions?.products_edit ||
                      !productPermissions?.products_edit_cost ||
                      (!variantState.product?.meta && variantState.product?.price_locked) ||
                      (variantState.product?.meta && variantState.product?.meta?.price_locked)
                    }
                  />
                  <div className="mt-4">
                    <p className="variant-profit-margin">
                      <span className="text-semibold">
                        {displayPercent((variantState.price - variantState.cost) / variantState.price)}
                      </span>
                      <span className="text-regular text-gray-500"> -- </span>
                      <span className="text-semibold">
                        <LocaleCurrency currency="cad" amount={variantState.price - variantState.cost} />
                      </span>
                    </p>
                    <p className="variant-profit-margin text-gray-500">{t("secure.facility.product.variant.010")}</p>
                  </div>
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>
          </Card>

          {variantState.showTickets && (
            <Card
              title={t("secure.facility.product.variant.011")}
              titleActions={[{ content: t("secure.facility.product.variant.012"), action: openAddTicket }]}
            >
              <Card.Section>
                <div className="ui-checkbox-group">
                  {variantState.tickets?.map((ticket: any, index: number) => {
                    return (
                      <div key={index} className="ui-checkbox-group-item">
                        <div className="ui-checkbox-group-item-content">
                          <div className="ui-checkbox-group-item-text">
                            <p className="text-sm text-medium text-gray-700">{ticket.title}</p>
                            <p className="text-xs text-regular text-gray-500">{ticket.subtitle}</p>
                            <p className="text-xs text-regular text-gray-500">{ticket.included}</p>
                            <Button type="link" size="small" onClick={(e: any) => handleDeleteTicket(e, ticket)}>
                              {t("secure.facility.product.variant.013")}
                            </Button>
                          </div>
                        </div>
                        <Input
                          onChange={(e: any) => handleTicketQuantityChange(e, ticket)}
                          value={ticket.quantity}
                          type="number"
                          label=""
                        />
                      </div>
                    );
                  })}
                </div>
              </Card.Section>
            </Card>
          )}

          <Card
            title={t("secure.facility.product.variant.014")}
            titleActions={[
              {
                action: () => updateFilterStateBoolean("showAccountingReference"),
                content: t("secure.facility.product.variant.034"),
              },
            ]}
          >
            <Card.Section>
              <FormLayout>
                <FormLayout.Group>
                  <Input
                    value={variantState.barcode}
                    label={t("secure.facility.product.variant.015")}
                    id="barcode"
                    onChange={handleInputChange}
                    disabled={!productPermissions?.products_edit || !facilityPermissions?.products_edit}
                  />
                  <Input
                    value={variantState.sku}
                    label={t("secure.facility.product.variant.016")}
                    id="sku"
                    onChange={handleInputChange}
                    disabled={!productPermissions?.products_edit || !facilityPermissions?.products_edit}
                  />
                </FormLayout.Group>
                {filterState.showAccountingReference && (
                  <FormLayout.Group>
                    <Select
                      label={t("secure.facility.product.variant.035")} //TODO: Translation
                      defaultValue={defaultAccountingReference.id}
                      showSearch
                      className={`flex justify-center align-center w-full h-10 position-relative z-20 text-black font-medium appearance-none border-none focus:outline-none placeholder-gray-200`}
                      onSearch={(query: string) => handleSelectOnSearch(query)}
                      onChange={(value: any) => setVariantState({ ...variantState, accounting_reference_id: value })}
                      allowClear
                      searchValue={filterState.search}
                      showDropDownOnFocus={true}
                      disabled={!productPermissions?.products_edit || !facilityPermissions?.products_edit}
                    >
                      {accountingReferences
                        .filter(accountingReference =>
                          String(accountingReference.title).toLowerCase().includes(filterState.search.toLowerCase()),
                        )
                        .map((reference: any, index: number) => {
                          return (
                            <div key={index}>
                              {filterState.search !== "" ? (
                                <Option key={index} value={reference.id} name={reference.title}>
                                  <div>
                                    <div className="font-semibold text-lg">{reference?.title}</div>
                                  </div>
                                </Option>
                              ) : null}
                            </div>
                          );
                        })}
                    </Select>
                  </FormLayout.Group>
                )}
              </FormLayout>
            </Card.Section>
          </Card>

          <Card
            title={t("secure.facility.product.variant.018")}
            titleActions={
              productPermissions?.products_edit
                ? [{ content: t("secure.facility.product.variant.019"), action: initiateUpdateInventory }]
                : null
            }
          >
            <Card.Section>
              <FormLayout>
                <FormLayout.Group>
                  <p>
                    {t("secure.facility.product.variant.020")} {variantState.quantity_available}
                  </p>
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>
            <Card.Section>
              {variantState.inventory_levels?.map((level: any, index: number) => {
                return (
                  <Card.SubSection key={index}>
                    <Stack>
                      <Stack.Item fill>
                        <h3>
                          <TextStyle>{level.facility?.long_name}</TextStyle>
                        </h3>
                      </Stack.Item>
                      <Stack.Item>
                        <p>
                          {level?.quantity_available || 0} {t("secure.facility.product.variant.021")}
                        </p>
                      </Stack.Item>
                    </Stack>
                  </Card.SubSection>
                );
              })}
            </Card.Section>

            <Card.Section>
              <Toggle
                id={"cannot_be_sold"}
                labelTop={"Cannot be sold"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleToggleChange(e)}
                checked={variantState?.meta?.cannot_be_sold === 0 ? false : true}
                disabled={variantState?.cannot_be_sold}
                labelSupport={
                  variantState.cannot_be_sold ? "Client has locked this item variant so it can no longer be sold" : ""
                }
              ></Toggle>
            </Card.Section>
          </Card>

          {/* <Card title={"Duplicate Variant"}>
            <Card.Section>
              <Button type="primary" size="small" onClick={() => setDuplicateVariantOpen(true)}>
                Duplicate Variant
              </Button>
            </Card.Section>
          </Card> */}
        </Page.Section>
        <Page.Section oneThird></Page.Section>
      </Page>

      <Popup
        okText="Duplicate"
        onCancel={() => setDuplicateVariantOpen(false)}
        onOk={duplicateVariant}
        title="Duplicate Variant"
        type="warning"
        description="Are you sure you want to duplicate this variant?"
        open={duplicateVariantOpen}
      />

      {/* Add Ticket Sheet - Handles adding ticket to product */}
      <Sheet
        open={addTicketState.addTicketActive}
        title={t("secure.facility.product.variant.022")}
        size="small"
        closable
        onCancel={closeAddTicket}
        cancelText={t("secure.facility.product.variant.023")}
        backDropCancel={false}
      >
        <div className="ui-checkbox-group">
          <div className="mb-4 w-full">
            <Input
              value={addTicketState.ticketSearch}
              onChange={(value: any) =>
                setAddTicketState(prevState => ({ ...prevState, ticketSearch: value.target.value }))
              }
              type="search"
              placeholder={t("secure.facility.product.variant.029")}
            ></Input>
          </div>

          {addTicketState.allTickets?.map((ticket: any, index: number) => {
            return (
              <div key={index} onClick={(e: any) => handleAddTicket(e, ticket)} className="ui-checkbox-group-item">
                <div className="ui-checkbox-group-item-content">
                  <div className="ui-checkbox-group-item-text">
                    <p className="text-sm text-medium text-gray-700">{ticket.title}</p>
                    <p className="text-xs text-regular text-gray-500">{ticket.subtitle}</p>
                    <p className="text-sm text-regular text-gray-600">{ticket.included}</p>
                  </div>
                </div>
                <Checkbox
                  size="medium"
                  value={ticket.id}
                  checked={false}
                  onChange={(e: any) => handleAddTicket(e, ticket)}
                />
              </div>
            );
          })}
        </div>
      </Sheet>

      <Sheet
        open={inventoryState.updateInventoryActive}
        size="small"
        closable
        title={t("secure.facility.product.variant.024")}
        onOk={completeUpdateInventory}
        cancelText={t("secure.facility.product.variant.025")}
        okText={t("secure.facility.product.variant.026")}
        onCancel={closeUpdateInventory}
      >
        <Tabs tabs={tabs} selected={0} onSelect={handleTabChange}>
          {selected === 0 ? (
            <>
              <div className="booking-modal">
                <div className="booking-details">
                  <div className="booking-options">
                    <div className="booking-options_option">
                      <Input
                        label={t("secure.facility.product.variant.027")}
                        value={inventoryState.newQuantity || ""}
                        type="number"
                        id="newQuantity"
                        onChange={handleQuantityChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {selected === 1 ? <div className="flex flex-column">{t("secure.facility.product.variant.028")}</div> : null}
        </Tabs>
      </Sheet>
    </>
  );
}
