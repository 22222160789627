import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { ISalesChannel } from "redux/reducers/models/product";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/facility/sales-channel";

type TUpdateSalesChannel = {
  sales_channel_id: string | number;
  product_id: string | number;
  remove?: boolean; // Remove the product from SalesChannel if included
};

//GET Sales Channels
//Retrieve a list of sales channels for the facility
export async function GetSalesChannels(params: { id: string | number }, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<ISalesChannel | ISalesChannel[]>(baseUrl, params, useGlobalLoader, token);
}

//PUT Update Sales Channel
//Adds or removes a product from sales channel
export async function UpdateSalesChannel(params: TUpdateSalesChannel, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/product", params, useGlobalLoader);
}
