import React, { useState, useRef, useEffect, cloneElement } from "react";
import useOutsideAlerter from "hooks/useOutsideAlerter/useOutsideAlerter";
import { delay } from "components/_util/delay";
import classNames from "classnames";
import "./style.scss";

type Placements = "topLeft" | "topCenter" | "topRight" | "bottomLeft" | "bottomCenter" | "bottomRight";

type OverlayFunc = () => React.ReactElement;

export interface IDropDownProps {
  trigger: "click" | "hover" | "contextMenu" | "boolean";
  overlay: React.ReactElement | OverlayFunc;
  disabled?: boolean;
  className?: string;
  placement?: Placements;
  showMenu?: boolean;
  propRef?: React.RefObject<HTMLElement>;
  outSideClickDisable?: boolean;
}

type Align = {
  offset: [number, number];
};

export const Dropdown: React.FC<IDropDownProps> = (props) => {
  const {
    className,
    children,
    overlay,
    disabled,
    trigger,
    placement = "bottomLeft",
    showMenu,
    propRef,
    outSideClickDisable,
  } = props;

  const [visible, setVisible] = useState(false);

  const [align, setAlign] = useState<Align>({
    offset: [null, null],
  });

  const ref = useRef(null);

  const overlayContainerRef = useRef(null);

  useEffect(() => {
    setVisible(showMenu);
  }, [showMenu]);

  useEffect(() => {
    let mounted = true;

    if (ref.current && overlayContainerRef.current) {
      const clientHeight: number = ref.current.children[0].clientHeight;

      if (placement === "bottomLeft") {
        const top = clientHeight + 10;
        const left = 0;
        if (mounted) {
          setAlign({ offset: [top, left] });
        }
      }

      if (placement === "bottomRight") {
        const top = clientHeight + 10;
        const right = 0;
        if (mounted) {
          setAlign({ offset: [top, right] });
        }
      }

      if (placement === "bottomCenter") {
        const top = clientHeight + 10;
        const clientWidth: number = ref.current.children[0].clientWidth;

        const overlayContainerClientWidth: number = overlayContainerRef.current.children[0].clientWidth;

        const left = -(overlayContainerClientWidth / 2 - clientWidth / 2);

        if (mounted) {
          setAlign({ offset: [top, left] });
        }
      }

      if (placement === "topLeft") {
        const bottom = clientHeight + 10;
        const left = 0;
        if (mounted) {
          setAlign({ offset: [bottom, left] });
        }
      }

      if (placement === "topRight") {
        const bottom = clientHeight + 10;
        const right = 0;
        if (mounted) {
          setAlign({ offset: [bottom, right] });
        }
      }

      if (placement === "topCenter") {
        const bottom = clientHeight + 10;
        const clientWidth: number = ref.current.children[0].clientWidth;

        const overlayContainerClientWidth: number = overlayContainerRef.current.children[0].clientWidth;

        const left = -(overlayContainerClientWidth / 2 - clientWidth / 2);

        if (mounted) {
          setAlign({ offset: [bottom, left] });
        }
      }
    }

    return () => {
      mounted = false;
    };
  }, [visible]);

  const onTriggerClick = () => {
    setVisible(!visible);
  };

  // ensures only one child or throws an error
  const child = React.Children.only(children) as React.ReactElement<any>;

  let dropdownTrigger;

  if (trigger === "click") {
    dropdownTrigger = cloneElement(child, { onClick: onTriggerClick });
  }

  useEffect(() => {
    let mounted = true;

    // used to handle typing trigger
    // if (trigger[0].length >= 1 && visible === false) {
    //   if (mounted) {
    //     setVisible(true);
    //   }
    // }

    // if (trigger[0].length === 0 && visible === true) {
    //   if (mounted) {
    //     setVisible(false);
    //   }
    // }

    return () => {
      mounted = false;
    };
  }, [trigger]);

  const dropdownClassName = classNames("rc-dropdown");

  const overlayContainerClassName = classNames(
    "overlay-container",
    { "overlay-container-visible": visible },
    className,
  );

  useOutsideAlerter(ref, () => !outSideClickDisable && setVisible(false));

  let placementStyle;

  switch (placement) {
    case "bottomLeft":
      placementStyle = {
        top: align.offset[0],
        left: align.offset[1],
      };
      break;

    case "bottomRight":
      placementStyle = {
        top: align.offset[0],
        right: align.offset[1],
      };
      break;

    case "bottomCenter":
      placementStyle = {
        top: align.offset[0],
        left: align.offset[1],
      };
      break;

    case "topLeft":
      placementStyle = {
        bottom: align.offset[0],
        left: align.offset[1],
      };
      break;

    case "topRight":
      placementStyle = {
        bottom: align.offset[0],
        right: align.offset[1],
      };
      break;

    case "topCenter":
      placementStyle = {
        bottom: align.offset[0],
        left: align.offset[1],
      };
      break;
  }

  const delayedClose = async () => {
    await delay(500);
    setVisible(false);
  };

  return (
    <div className={dropdownClassName} ref={propRef ? propRef : ref}>
      {trigger === "click" ? dropdownTrigger : child}
      {visible && (
        <div
          className={overlayContainerClassName}
          style={placementStyle}
          onClick={delayedClose}
          ref={overlayContainerRef}
        >
          {overlay}
        </div>
      )}
    </div>
  );
};
