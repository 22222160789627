import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import Page from "components/page/Page";
import Card from "components/card/Card";
import { IPrimaryPageAction } from "components/page/PageActions";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";
import axios, { CancelToken } from "axios";
import { StatusCode } from "api/protocols";
import { dequeue, enqueue, showError, showSuccess } from "redux/actions/ui";
import moment from "moment";
import Spin from "components/spin/spin";
import "./viewInventoryCount.scss";
import FormLayout from "components/form/FormLayout";
import Portal from "elements/Portal";
import Popup from "components/popup/Popup";
import { Badge } from "components/badge/Badge";
import { valueToString } from "helpers/Helpers";
import ReactDOM from "react-dom";
import {
  GetInventoryCount,
  GetInventoryCountVariants,
  PutDownloadInventoryCount,
  PutExportInventoryCount,
  PutSubmitInventoryCount,
  PutVoidInventoryCount,
  TInventoryCount,
  TInventoryCountVariants,
} from "api/rpc/2024-04/facilityAdmin/product/inventoryCount";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IVariant } from "redux/reducers/models/product";

interface IInventoryProduct {
  id: number;
  title: string;
  vendor_title: string;
  variants: Array<Partial<IVariant & { quantity_difference: number }>>;
}
interface IInventoryState {
  inventoryCount: TInventoryCount;
  inventoryCountVariants: Array<TInventoryCountVariants>;
  products: Array<IInventoryProduct>;
}

interface IParams {
  id: string;
}

export default function ViewInventoryCount() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { id } = useParams<IParams>();
  const [submitPopupState, setSubmitPopupState] = useState<boolean>(false);
  const [voidPopupState, setVoidPopupState] = useState<boolean>(false);
  const [inventoryState, setInventoryState] = useState<IInventoryState>({
    inventoryCount: undefined,
    inventoryCountVariants: undefined,
    products: undefined,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadInventoryCount(source.token);
    return () => {
      source.cancel();
    };
  }, []);

  async function loadInventoryCount(cancelToken?: CancelToken) {
    let products: Array<IInventoryProduct> = [];
    // show loader
    if (inventoryState?.inventoryCount !== undefined) {
      setInventoryState(prevState => ({ ...prevState, inventoryTransfers: undefined }));
    }
    const inventoryTransferRes = await GetInventoryCount({ id: Number(id) }, false, cancelToken);
    if (inventoryTransferRes.status !== StatusCode.OK) {
      if (cancelToken?.reason) {
        return;
      }
      dispatch(showError("Error getting inventory transfer"));
    }
    const variantsRes = await GetInventoryCountVariants({ inventory_count_id: Number(id) }, false, cancelToken);
    if (variantsRes.status !== StatusCode.OK) {
      if (cancelToken?.reason) {
        return;
      }
      dispatch(showError("Error getting inventory transfer products"));
    } else {
      products = variantsRes?.data?.reduce((productsArray: Array<IInventoryProduct>, variant) => {
        const updatedProducts = [...productsArray];
        const productIndex = productsArray?.findIndex(product => product?.id === variant?.product_id);
        const variantObject = {
          id: variant?.variant_id,
          title: variant?.variant_title,
          quantity: variant?.quantity_counted,
          sku: variant?.sku,
          barcode: variant?.barcode,
          quantity_difference: variant?.quantity_difference,
        };

        if (productIndex === -1) {
          updatedProducts.push({
            id: variant.product_id,
            title: variant.product_title,
            vendor_title: variant?.product_vendor_title,
            variants: [variantObject],
          });
        } else {
          updatedProducts[productIndex]?.variants?.push(variantObject);
          updatedProducts[productIndex] = { ...updatedProducts[productIndex] };
        }
        return updatedProducts;
      }, []);
    }
    setInventoryState(prevState => ({
      ...prevState,
      inventoryCount: inventoryTransferRes.status === StatusCode.OK ? inventoryTransferRes?.data[0] : null,
      inventoryCountVariants: variantsRes?.status === StatusCode.OK ? variantsRes?.data : [],
      products: products,
    }));
  }

  async function handleSubmit() {
    dispatch(enqueue());
    const submitRes = await PutSubmitInventoryCount({ inventory_count_id: Number(id) }, false);
    if (submitRes.status !== StatusCode.OK) {
      dispatch(showError("Error submitting transfer"));
      dispatch(dequeue());
      return;
    }
    dispatch(showSuccess("Successfully submitted transfer"));
    await handleReload();
    dispatch(dequeue());
  }

  async function handleReload() {
    setSubmitPopupState(false);
    await loadInventoryCount();
  }

  async function handleVoidCount() {
    const res = await PutVoidInventoryCount({ inventory_count_id: Number(id) }, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error voiding inventory count"));
      return;
    }
    dispatch(showSuccess("Successfully voided inventory count draft"));
    history.push("/admin/settings/counts");
  }

  async function handleDownloadCount() {
    const res = await PutDownloadInventoryCount({ inventory_count_id: Number(id) }, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error downloading inventory count"));
      return;
    }

    window.open(res?.data, "_blank");
  }

  async function handleExportCount() {
    const res = await PutExportInventoryCount({ inventory_count_id: Number(id) }, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error exporting inventory count"));
      return;
    }
    const exportLink = document.createElement("a");
    exportLink.setAttribute("href", "data:text/csv;charset=utf-8,".concat(encodeURI(res?.data)));
    exportLink.setAttribute(
      "download",
      `Inventory_Count_${inventoryState?.inventoryCount?.name?.toUpperCase()}_${moment(
        inventoryState?.inventoryCount?.date_created ?? new Date(),
      )
        .format("YYYY-MM-DD")
        .toString()}`
        .replace(/ /g, "_")
        .toUpperCase()
        .concat(".csv"),
    );
    exportLink.click();
  }

  const editAction: IPrimaryPageAction = {
    content: "Edit",
    action: () =>
      history.push(`/admin/settings/counts/edit/${id}`, {
        params: {
          inventoryCount: inventoryState?.inventoryCount,
          products: inventoryState?.products,
        },
      }),
    disabled:
      !inventoryState?.inventoryCount ||
      !!inventoryState?.inventoryCount?.date_submitted ||
      inventoryState?.inventoryCount?.status === "void",
  };

  return (
    <Page
      title={`Count: ${!inventoryState?.inventoryCount ? "" : inventoryState?.inventoryCount?.name}`}
      titleMetadata={
        !inventoryState?.inventoryCount ? undefined : (
          <>
            <Badge size="medium" type={getCountsBadgeType(inventoryState?.inventoryCount?.status)}>
              {valueToString(inventoryState?.inventoryCount?.status)}
            </Badge>
          </>
        )
      }
      primaryAction={editAction}
      multipleActionDropdownAction={{
        label: "Options",
        dropdownProps: {
          alignment: "right",
          options: [
            {
              type: "handler",
              label: "Submit",
              icon: "circle-check",
              handler: () => setSubmitPopupState(true),
              disabled:
                !inventoryState?.inventoryCount ||
                !!inventoryState?.inventoryCount?.date_submitted ||
                inventoryState?.inventoryCount?.status === "void",
            },
            // {
            //   type: "handler",
            //   label: "Print",
            //   icon: "print",
            //   handler: () => handleDownloadCount(),
            //   disabled: !inventoryState?.inventoryCount,
            // },
            {
              type: "handler",
              label: "Export",
              icon: "file-export",
              handler: () => handleExportCount(),
              disabled: !inventoryState?.inventoryCount,
            },
            {
              type: "handler",
              label: "Void",
              icon: "circle-xmark",
              handler: () => setVoidPopupState(true),
              disabled: !inventoryState?.inventoryCount || inventoryState?.inventoryCount?.status !== "draft",
            },
          ],
        },
      }}
      splitLayout
      breadcrumbs={[{ prefix: true, label: "Back to Inventory Counts", url: "/admin/settings/counts" }]}
    >
      {inventoryState?.inventoryCount === undefined ? (
        <span style={{ display: "flex", justifyContent: "center", margin: "auto" }}>
          <Spin />
        </span>
      ) : (
        <>
          <Page.Section twoThirds>
            <DataTable
              columns={[
                { label: "Title" },
                { label: "Vendor" },
                { label: "Sku" },
                { label: "Quantity Counted" },
                { label: "Quantity Difference" },
              ]}
              loading={inventoryState?.products === undefined}
            >
              {inventoryState?.products?.map((product, productIndex) => {
                const singleVariant = product?.variants?.length === 1 && product?.title === product?.variants[0]?.title;
                return (
                  <React.Fragment key={productIndex}>
                    <tr className="view-inventory-count-product-header">
                      <td>{product?.title}</td>
                      <td>{product?.vendor_title}</td>
                      <td>{singleVariant && product?.variants[0]?.sku}</td>
                      <td>{singleVariant && product?.variants[0]?.quantity}</td>
                      <td>
                        {singleVariant && (
                          <Badge
                            size="medium"
                            iconLeft={getDifferenceIcon(product?.variants[0]?.quantity_difference)}
                            type={getDifferenceBadgeType(product?.variants[0]?.quantity_difference)}
                          >
                            {Math.abs(product?.variants[0]?.quantity_difference)}
                          </Badge>
                        )}
                      </td>
                    </tr>
                    {!singleVariant &&
                      product?.variants?.map((variant, variantIndex) => (
                        <tr key={variantIndex}>
                          <td style={{ textIndent: "3rem" }}>{variant?.title}</td>
                          <td></td>
                          <td>{variant?.sku}</td>
                          <td>{variant?.quantity}</td>
                          <td>
                            <Badge
                              size="medium"
                              iconLeft={getDifferenceIcon(variant?.quantity_difference)}
                              type={getDifferenceBadgeType(variant?.quantity_difference)}
                            >
                              {Math.abs(variant?.quantity_difference)}
                            </Badge>
                          </td>
                        </tr>
                      ))}
                  </React.Fragment>
                );
              })}
            </DataTable>
          </Page.Section>
          <Page.Section oneThird>
            <Card>
              <Card.Section title="Transfer Details">
                <FormLayout>
                  <FormLayout.Group>
                    <div className="view-inventory-count-info-container">
                      <p className="title">Created By</p>
                      <p>{inventoryState.inventoryCount?.created_by_full_name}</p>
                    </div>
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <div className="view-inventory-count-info-container">
                      <p className="title">Date Created</p>
                      <p>{moment(inventoryState.inventoryCount?.date_created, "YYYY-MM-DD h:mm:ss").format("LL")}</p>
                    </div>
                    <div className="view-inventory-count-info-container" title="Date Submitted">
                      <p className="title">Date Submitted</p>
                      <p>
                        {inventoryState.inventoryCount?.date_submitted &&
                          moment(inventoryState.inventoryCount?.date_submitted, "YYYY-MM-DD h:mm:ss").format("LL")}
                      </p>
                    </div>
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
            </Card>
          </Page.Section>
        </>
      )}
      <Portal isMounted={submitPopupState}>
        <Popup
          open={submitPopupState}
          onOk={handleSubmit}
          onCancel={() => setSubmitPopupState(false)}
          okText="Submit"
          type="info"
          title={"Submit Inventory Count?"}
          description={
            "The product inventory counts will be adjusted based on the difference values. Count cannot be edited after submitting."
          }
        />
      </Portal>

      <Portal isMounted={voidPopupState}>
        <Popup
          open={voidPopupState}
          onOk={handleVoidCount}
          onCancel={() => setVoidPopupState(false)}
          okText="Void"
          type="info"
          title={"Void Inventory Count Draft?"}
          description={"Inventory count draft will be voided. This cannot be undone."}
        />
      </Portal>
    </Page>
  );
}

export function getCountsBadgeType(status: string) {
  switch (status) {
    case "draft":
      return "gray";
    case "complete":
      return "success";
    case "void":
      return "error";
    default:
      return "gray";
  }
}

function getDifferenceBadgeType(quantity: number) {
  return quantity > 0 ? "success" : quantity < 0 ? "error" : "gray";
}

function getDifferenceIcon(quantity: number) {
  return quantity > 0 ? (
    <FontAwesomeIcon size="1x" icon={["far", "arrow-up"]} />
  ) : quantity < 0 ? (
    <FontAwesomeIcon size="1x" icon={["far", "arrow-down"]} />
  ) : undefined;
}
