import { AppDispatch } from "index";
import { IReservationState } from "redux/reducers/reservations";

export const update = (field: Partial<IReservationState>) => (dispatch: AppDispatch) => {
  dispatch({
    type: "reservation.update",
    payload: {
      ...field,
    },
  });
};

export interface IReservationActions {
  update: typeof update;
}
