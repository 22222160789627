import Sheet from "components/sheet/Sheet";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "components/form/checkbox/Checkbox";
import { ICart, ICartLineItem } from "redux/reducers/models/cart";
import { LocaleCurrency } from "helpers/Locale";
import Callout from "components/callout/Callout";
import { useAppSelector } from "hooks/redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import "./printChit.scss";
import ReactDOM from "react-dom";

interface IChangePriceProps {
  printOrderVisible: boolean;
  activeTable: any;
  onCancel?: () => void;
  onOk?: (priceChangeInfo: ISendOrderState) => void;
}

interface ISendOrderState {
  addedLineItems: Array<number>;
}

interface IFilterState {
  carts: Array<ICart & { checked?: boolean }>;
}

function PrintChit(props: IChangePriceProps) {
  const { t, i18n } = useTranslation();
  const { cartStore } = useAppSelector(store => store);

  const [sendOrderState, setSendOrderState] = useState<ISendOrderState>({
    addedLineItems: [],
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    carts: [],
  });

  useEffect(() => {
    if (props.printOrderVisible) {
      const carts = [...cartStore?.activeTable?.carts]?.map((cart: ICart & { checked?: boolean }) => ({
        ...cart,
        checked: true,
      }));
      setFilterState(prevState => ({ ...prevState, carts: carts }));
    } else {
      setFilterState(prevState => ({ ...prevState, carts: [] }));
    }
  }, [props.printOrderVisible]);

  function onOk() {
    void props.onOk({ ...sendOrderState });
    const updatedCarts = [...filterState?.carts].map(cart => ({ ...cart, checked: false }));
    ReactDOM.unstable_batchedUpdates(() => {
      setFilterState(prevState => ({ ...prevState, carts: updatedCarts }));
      setSendOrderState(prevState => ({
        ...prevState,
        addedLineItems: [],
      }));
    });
  }

  function onCancel() {
    const updatedCarts = [...filterState?.carts].map(cart => ({ ...cart, checked: false }));
    ReactDOM.unstable_batchedUpdates(() => {
      setFilterState(prevState => ({ ...prevState, carts: updatedCarts }));
      setSendOrderState(prevState => ({
        ...prevState,
        addedLineItems: [],
      }));
    });
    props.onCancel();
  }

  function handleCheckboxChange(lineItemId: number) {
    const lineItems = [...sendOrderState.addedLineItems];
    const foundIndex = lineItems?.indexOf(lineItemId);
    if (foundIndex === -1) {
      lineItems.push(lineItemId);
    } else {
      lineItems.splice(foundIndex, 1);
    }
    setSendOrderState(prevState => ({ ...prevState, addedLineItems: lineItems }));
  }

  function handleSelectAllCheckboxChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { checked } = e.target;
    const lineItems: number[] = [];
    if (checked) {
      filterState?.carts?.forEach((cart: Record<string, any>) => {
        cart?.line_items?.forEach((lineItem: ICartLineItem) => {
          if (cart?.status !== "complete" && !lineItem?.parent_id) {
            lineItems.push(lineItem.id);
          }
        });
      });
    }

    setSendOrderState(prevState => ({ ...prevState, addedLineItems: lineItems }));
  }

  function handleShowSeat(index: number) {
    const carts = [...filterState.carts]?.map((cart, cartIndex) =>
      index === cartIndex ? { ...cart, checked: !cart?.checked } : { ...cart },
    );
    setFilterState(prevState => ({ ...prevState, carts: carts }));
  }

  return (
    <Sheet
      title={t("components.print_chit.print_chit.001")}
      open={props.printOrderVisible}
      size="medium"
      closable
      onCancel={onCancel}
      onOk={onOk}
      okText={t("components.print_chit.print_chit.002")}
      okDisabled={sendOrderState.addedLineItems?.length < 1 ? true : false}
    >
      {props?.activeTable?.carts?.length > 0 ? (
        <>
          <div className="select-all-container">
            <Checkbox
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSelectAllCheckboxChange(e)}
              size="small"
              label={`${t("components.print_chit.print_chit.003")}`}
            />
          </div>
          {filterState?.carts?.map((cart: any, cartIndex: number) => {
            if (cart.status !== "complete") {
              return (
                <div key={cartIndex} className="print-chit">
                  <div className="print-chit_table_title_container" onClick={() => handleShowSeat(cartIndex)}>
                    <p className="print-chit_table_title">
                      {t("components.print_chit.print_chit.004")}
                      {cartIndex + 1}
                    </p>

                    <div className="print-chit_icon_container">
                      <FontAwesomeIcon
                        className="navigation-dropdown-new-content-section-option-icon"
                        icon={["far", cart.checked ? "chevron-up" : "chevron-down"]}
                      />
                    </div>
                  </div>

                  {cart.checked ? (
                    <div className="pl-3 pr-3">
                      {cart.line_items?.map((lineItem: any, lineItemIndex: number) => {
                        //Don't display modifiers
                        if (!lineItem.parent_id) {
                          return (
                            <div
                              key={lineItemIndex}
                              onClick={() => handleCheckboxChange(lineItem.id)}
                              className="flex p-2 items-center justify-between cursor-pointer table-service-hover"
                            >
                              <div className="flex items-center pointer-events-none">
                                <Checkbox
                                  onChange={() => handleCheckboxChange(lineItem.id)}
                                  size="small"
                                  checked={sendOrderState.addedLineItems.includes(lineItem.id)}
                                />

                                <div className="ml-2">
                                  <div>
                                    <span className="print-chit_line_item_title">{lineItem.quantity}</span>
                                    {lineItem.product_title === lineItem.variant_title ? (
                                      <span className="print-chit_line_item_title">{lineItem.product_title}</span>
                                    ) : (
                                      <span className="print-chit_line_item_title">
                                        {lineItem.product_title}
                                        <span className="text-subdued"> - {lineItem.variant_title}</span>
                                      </span>
                                    )}
                                  </div>

                                  <div>
                                    <span className="print-chit_line_item_note">{lineItem.note}</span>
                                  </div>
                                </div>
                              </div>

                              <div className="print-chit_line_item_price">
                                <LocaleCurrency currency={cart?.currency} amount={lineItem.subtotal_price} />
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  ) : null}
                </div>
              );
            }
          })}
        </>
      ) : (
        <div className="text-black">
          <Callout
            title={t("components.print_chit.print_chit.005")}
            content={t("components.print_chit.print_chit.006")}
            type="info"
          />
        </div>
      )}
    </Sheet>
  );
}

export default PrintChit;
