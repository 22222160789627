import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import axios, { CancelToken } from "axios";
import moment from "moment";

import { StatusCode } from "api/protocols";
import { ICountry, IProvince } from "redux/reducers/models/customer";
import {
  DeleteFacilityImage,
  GetFacility,
  PostFacilityImage,
  PutFacility,
} from "api/rpc/2024-04/clientAdmin/facility/facility";
import { GetCountry, GetProvince } from "api/rpc/2022-09/common/country/country";

import { showError, showSuccess } from "redux/actions/ui";
import { IFacility } from "redux/reducers/models/facility";
import { useAppDispatch } from "hooks/redux";
import { unsavedChangesExist } from "helpers/Helpers";

import Page from "components/page/Page";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import SelectNew from "components/select/SelectNew";
import CustomerImage from "elements/customer/CustomerImage";

const facilityBase = {
  id: null as number,
  long_name: "",
  short_name: "",
  holes: 0,

  address_line_1: "",
  address_line_2: "",
  city: "",

  country_name: "",
  province_name: "",
  postal: "",

  phone: "",
  email: "",
  customer_email: "",

  timezone: "",
  logo_source: "",
};

export default function FacilityEdit() {
  const history = useHistory();
  const { facilityId } = useParams<{ facilityId: string }>();

  const dispatch = useAppDispatch();

  const timeZones = moment.tz.zonesForCountry("CA");

  const [countries, setCountries] = useState<ICountry[]>([]);
  const [provinces, setProvinces] = useState<IProvince[]>([]);

  const [facilityState, setFacilityState] = useState<Partial<IFacility>>(facilityBase);
  const [stateBefore, setStateBefore] = useState<Partial<IFacility>>(facilityBase);

  // Seperate to remove unsavedChanges bar after logo update
  const [facilityLogoSource, setFacilityLogoSource] = useState(null);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadFacility(facilityId, source.token);
    return () => source.cancel();
  }, [facilityId]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadCountry(source.token);
    return () => source.cancel();
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadProvinces(facilityState.country_id, source.token);
    return () => source.cancel();
  }, [facilityState.country_id]);

  async function loadFacility(facilityId: string | number, token?: CancelToken) {
    const facilityRes = await GetFacility({ id: Number(facilityId), extended: true }, true, token);
    if (token && token.reason) {
      return;
    }
    if (facilityRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading facility details.")); // TODO: Translation
      history.push("/admin/settings/client-settings/facility-details");
      return;
    }

    // Seperate from unsavedChanges bar
    const { logo_source, ...data } = facilityRes.data[0];

    setFacilityState(data);
    setStateBefore(data);
    setFacilityLogoSource(logo_source);
  }

  async function loadCountry(token?: CancelToken) {
    if (countries !== undefined) {
      setCountries(undefined);
    }
    const res = await GetCountry({ provinces: true }, token ? false : true, token);
    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      setCountries([]);
      return;
    }

    setCountries(res.data);
  }

  async function loadProvinces(countryId: number, token?: CancelToken) {
    if (provinces !== undefined) {
      setProvinces(undefined);
    }
    const res = await GetProvince({ country_id: countryId }, token ? false : true, token);
    if (token && token.reason) {
      return;
    }

    if (res.status !== StatusCode.OK) {
      setProvinces([]);
      return;
    }

    setProvinces(res.data);
  }

  async function saveFacility(params: Partial<IFacility>) {
    const facilityRes = await PutFacility(params, true);
    if (facilityRes.status !== StatusCode.OK) {
      return;
    }

    dispatch(showSuccess("Successfully updated facility.")); // TODO: Translation
    setStateBefore(prev => ({ ...prev, ...params }));
  }

  async function saveFacilityImage(imageFile: File) {
    const formData = new FormData();
    formData.append("logo_image", imageFile);
    formData.append("facility_id", facilityId);

    const imageRes = await PostFacilityImage(formData, true);
    if (imageRes.status !== StatusCode.OK) {
      dispatch(showError("Error uploading image")); // TODO: Translation
      return;
    }
    console.log(imageRes.data);

    dispatch(showSuccess("Image uploaded successfully")); // TODO: Translation

    setFacilityLogoSource(imageRes.data.logo_source);
  }

  async function removeFacilityImage() {
    const imageRes = await DeleteFacilityImage({ facility_id: facilityId }, true);
    if (imageRes.status !== StatusCode.OK) {
      dispatch(showError("Error removing facility image")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Image removed successfully")); // TODO: Translation
    setFacilityLogoSource(null);
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setFacilityState(prevState => ({ ...prevState, [id]: value }));
  }

  return (
    <Page
      title={stateBefore.long_name}
      narrow
      notificationBarProps={{
        isVisible: unsavedChangesExist(facilityState, stateBefore),
        onAction: () => saveFacility(facilityState),
        onCancel: () => setFacilityState(stateBefore),
      }}
      breadcrumbs={[
        {
          prefix: true,
          label: "Back to Facilities" /* TODO: Translation */,
          url: "/admin/settings/client-settings/facility-details",
        },
      ]}
    >
      <Card title="Basic Information" /* TODO: Translation */>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group alignBase>
              <div className="pt-1">
                <Input
                  value={facilityState.long_name}
                  label="Long Name" // TODO: Translation
                  id="long_name"
                  onChange={handleInputChange}
                  placeholder={facilityState.long_name}
                />
              </div>
              <div className="pt-1">
                <Input
                  value={facilityState.short_name}
                  label="Short Name" // TODO: Translation
                  id="short_name"
                  onChange={handleInputChange}
                  placeholder={facilityState.short_name}
                />
              </div>
              <SelectNew
                label="Facility Holes" // TODO: Translation
                id="facility_holes"
                onChange={val => setFacilityState(prev => ({ ...prev, holes: val }))}
                value={facilityState.holes}
                options={[9, 18, 27, 36].map(val => ({ id: val, label: val }))}
                placeholder="Select Holes" // TODO: Translation
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Input
                value={facilityState.address_line_1}
                label="Address Line 1" // TODO: Translation
                id="address_line_1"
                onChange={handleInputChange}
                placeholder="111 Rockerfeller Lane"
              />
              <Input
                value={facilityState.address_line_2 ?? ""}
                label="Address Line 2" // TODO: Translation
                id="address_line_2"
                onChange={handleInputChange}
                placeholder=""
              />
              <Input
                value={facilityState.city}
                label="City" // TODO: Translation
                id="city"
                onChange={handleInputChange}
                placeholder="City" // TODO: Translation
              />
            </FormLayout.Group>
            <FormLayout.Group alignBase>
              <SelectNew
                id="country_id"
                label="Country" // TODO: Translation
                value={facilityState.country_id}
                onChange={value => setFacilityState(prev => ({ ...prev, country_id: value }))}
                options={countries?.map(val => ({ id: val.id, label: val.name }))}
                isLoading={countries === undefined}
                placeholder="Select Country" // TODO: Translation
              />

              <SelectNew
                id="province_id"
                label="Province" // TODO: Translation
                value={facilityState.province_id}
                onChange={value => setFacilityState(prev => ({ ...prev, province_id: value }))}
                options={provinces?.map(val => ({ id: val.id, label: val.name }))}
                isLoading={provinces === undefined}
                placeholder="Select Province" // TODO: Translation
              />
              <div className="pt-1">
                <Input
                  value={facilityState.postal}
                  label="Postal" // TODO: Translation
                  id="postal"
                  onChange={handleInputChange}
                  placeholder="Postal" // TODO: Translation
                />
              </div>
            </FormLayout.Group>
            <FormLayout.Group>
              <Input
                value={facilityState.phone}
                label="Facility Phone" // TODO: Translation
                id="phone"
                onChange={handleInputChange}
                placeholder={facilityState.phone}
              />
              <Input
                value={facilityState.email}
                label="Facility Contact Email" // TODO: Translation
                id="email"
                onChange={handleInputChange}
                placeholder={facilityState.email}
              />
              <Input
                value={facilityState.customer_email}
                label="Customer Email" // TODO: Translation
                id="customer_email"
                onChange={handleInputChange}
                placeholder={facilityState.customer_email}
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
        <Card.Section>
          <SelectNew
            label="Timezone" // TODO: Translation
            id="timezone"
            onChange={val => setFacilityState(prev => ({ ...prev, timezone: val }))}
            value={facilityState.timezone}
            options={timeZones.map(val => ({ id: val, label: val }))}
          />
        </Card.Section>
        <Card.Section title="Facility Logo" /* TODO: Translation*/>
          <CustomerImage
            imageSource={facilityLogoSource}
            saveProfileImage={imageFile => saveFacilityImage(imageFile)}
            deleteProfileImage={removeFacilityImage}
          />
        </Card.Section>
      </Card>
    </Page>
  );
}
