import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import axios, { CancelToken } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Avatar from "components/avatar/Avatar";
import Search from "components/search/Search";
import { ButtonNew as Button } from "components/buttonNew";
import { Badge } from "components/badge/Badge";
import { Flag } from "components/flag/Flag";
import { IClient } from "redux/reducers/models/client";
import { GetClient } from "api/rpc/2024-04/masterAdmin/client/client";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { showError } from "redux/actions/ui";

import { IClientState } from "redux/reducers/masterAdmin/client";
import { IClientActions } from "redux/actions/masterAdmin/client";

interface IClientsProps {
  masterClientStore: IClientState;
  clientActions: IClientActions;
}

interface IClientsState {
  clients: Array<IClient>;
  search: string;
  limit: number;
  offset: number;
}

const TABLE_COLUMN_COUNT = 6;

export default function Clients(props: IClientsProps) {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { masterClientStore, clientActions } = props;

  const [state, setState] = useState<IClientsState>({
    clients: [],
    search: "",
    limit: 25,
    offset: 0,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (state.search !== undefined) {
      void loadClients(source.token);
    }

    return () => {
      source.cancel();
    };
  }, [state.search, state.offset]);

  async function loadClients(token?: CancelToken) {
    const clientRes = await GetClient(
      {
        search: state.search,
        offset: state.offset,
        limit: state.limit,
      },
      true,
      token,
    );

    if (clientRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading clients"));
      return;
    }

    setState(prevState => ({ ...prevState, clients: clientRes.data }));
  }

  function navigateToClient(url: string, client: IClient) {
    clientActions.selectClient(client);
    history.push(url);
  }

  const primaryAction = {
    content: "New Client",
    action: () => history.push("/admin/client/new"),
  };

  return (
    <Page title="Clients" primaryAction={primaryAction}>
      <div className="flex-grow mb-4 pt-4">
        <Search
          searchCallback={searchValue => setState(prevState => ({ ...prevState, search: searchValue, offset: 0 }))}
          historyKey="master-facilities"
        />
      </div>
      <Card>
        <Card.Section table="true">
          <table className="ui-table ui-table-clickable">
            <thead>
              <tr>
                <th>Name</th>
                <th>Address</th>
                <th>City</th>
                <th>Postal</th>
                <th>Province</th>
                <th>Country</th>
              </tr>
            </thead>
            <tbody>
              {state.clients ? (
                <>
                  {state.clients.map((client: IClient, index: number) => {
                    return (
                      <tr key={index} onClick={() => navigateToClient("/admin/client/" + String(client.id), client)}>
                        <td className="table-cell-lead-avatar">
                          <Avatar source={client.logo_source_url} size="medium" />
                          <p className="table-cell-lead-text">{client.long_name}</p>
                        </td>
                        <td>{client.address_line_1}</td>
                        <td>{client.city}</td>
                        <td>{client.postal}</td>
                        <td>{client.province_name}</td>
                        <td>
                          <Badge type="gray" iconSize="medium" iconLeft={<Flag countryCode={client.country_code} />}>
                            {client.country_name}
                          </Badge>
                        </td>
                      </tr>
                    );
                  })}
                </>
              ) : null}
            </tbody>

            <tfoot>
              <tr>
                <td colSpan={2}>
                  <Button
                    size="small"
                    type="secondary"
                    onClick={() => setState(prevState => ({ ...prevState, offset: prevState.offset - state.limit }))}
                    disabled={!(state.limit <= state.offset)}
                  >
                    <FontAwesomeIcon icon={"arrow-left"} />
                    &nbsp;
                    {"Previous"}
                  </Button>
                </td>

                <td colSpan={TABLE_COLUMN_COUNT - 4} />

                <td colSpan={2}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      size="small"
                      type="secondary"
                      onClick={() =>
                        setState(prevState => ({ ...prevState, offset: prevState.offset + state.limit }))
                      }
                      disabled={!(state.clients.length === state.limit)}
                    >
                      {"Next"}
                      &nbsp;
                      <FontAwesomeIcon icon={"arrow-right"} />
                    </Button>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </Card.Section>
      </Card>
    </Page>
  );
}
