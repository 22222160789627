import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios, { CancelToken } from "axios";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { GetVendor } from "api/rpc/2024-04/facilityAdmin/product/vendor";

import { showError } from "redux/actions/ui";
import { TProductVendor } from "redux/reducers/models/product";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";

export default function Vendors() {
  const history = useHistory();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [vendors, setVendors] = useState<TProductVendor[]>();

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadVendors(source.token);
    return () => source.cancel();
  }, []);

  async function loadVendors(token?: CancelToken) {
    const res = await GetVendor(null, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.vendors.vendors.001")));
    }

    setVendors(res.status !== StatusCode.OK ? [] : res.data);
  }

  const primaryAction = {
    content: t("secure.facility.settings.vendors.vendors.002"),
    action: () => history.push("/admin/settings/vendors/new"),
  };

  return (
    <Page title={t("secure.facility.settings.vendors.vendors.003")} narrow primaryAction={primaryAction}>
      <DataTable
        columns={[{ label: t("secure.facility.settings.vendors.vendors.004") }]}
        loading={vendors === undefined}
      >
        {vendors?.map(vendor => (
          <tr
            key={vendor.id}
            onClick={() => history.push("/admin/settings/vendors/" + String(vendor.id))}
            className="clickable"
          >
            <td>{vendor.title}</td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
