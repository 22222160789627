import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonNew as Button } from "components/buttonNew";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input";
import TextField from "components/form/textField/TextField";
import Sheet from "components/sheet/Sheet";
import { isEmpty } from "helpers/Helpers";
import { useAppSelector } from "hooks/redux";
import React, { useCallback, useEffect, useState } from "react";
import { ICartLineItem } from "redux/reducers/models/cart";
import { Select } from "components/select/index";
import "./editLineItemModal.scss";
import { IDiscount } from "api/rpc/2022-09/facilityAdmin/product/discount";
import Checkbox from "components/form/checkbox/Checkbox";
import ReactDOM from "react-dom";

export type TUpdatedLineItem = IEditState;

interface IProps {
  /**Boolean that determines if the modal is displayed*/
  open: boolean;
  /**The selected line item to be edited */
  lineItem: ICartLineItem;
  /**Function that runs when the cancel button is selected */
  onCancel: () => void;
  /**Function that runs when the 'Delete Item' button is selected */
  removeLineItem: () => Promise<void>;
  /**Function that runs when the 'Update' button is selected. The updated lineitem values are passed to this function */
  update: (updatedLineItem: TUpdatedLineItem) => Promise<void>;
  /**Function that runs when the 'Split Item' button is selected `(optional)` */
  openSplitItemModal?: () => void;
  /**Function that runs when the 'Modifier' button is selected */
  openModifierModal: (lineItem: ICartLineItem) => void;
  /**Boolean that determines if the 'Courses' dropdown is displayed `(optional)` */
  courses?: boolean;
}

interface IEditState {
  quantity: string;
  price: string;
  note: string;
  courseId: number;
  discount: Partial<IDiscount>;
  customDiscountType: "fixed_amount" | "percent";
  customDiscountAmount: string;
}

/**
 * Component that allows user to edit the quantity, price, note, course and discount of a line item. As well as options to edit modifiers, split item and delete the item.
 * @param {IProps} props
 * @param {boolean} props.open Boolean that determines if the modal is displayed
 * @param {ICartLineItem} props.lineItem The selected line item to be edited
 * @param {() => void} props.onCancel Function that runs when the cancel button is selected
 * @param {() => Promise<void>} props.removeLineItem Function that runs when the 'Delete Item' button is selected
 * @param {(updatedLineItem: TUpdatedLineItem) => Promise<void>} props.update Function that runs when the update button is selected. The updated lineitem values are passed to this function
 * @param {() => void} props.openSplitItemModal Function that runs when the 'Split Item' button is selected `(optional)`
 * @param {() => void} props.openModifierModal Function that runs when the 'Modifier' button is selected
 * @param {boolean} props.courses Boolean that determines if the 'Courses' dropdown is displayed `(optional)`
 *
 */
export default function EditLineItemModal(props: IProps) {
  const { open, lineItem, onCancel, removeLineItem, update, openSplitItemModal, openModifierModal, courses } = props;
  const [editState, setEditState] = useState<IEditState>({
    quantity: null,
    price: null,
    note: null,
    courseId: null,
    discount: { id: -1, title: "None" },
    customDiscountType: "fixed_amount",
    customDiscountAmount: "",
  });
  const { Option } = Select;

  const permissions = useAppSelector(store => store?.authStore?.user?.permissions);
  const facilityStore = useAppSelector(store => store.facilityStore);

  useEffect(() => {
    if (open && lineItem) {
      setEditState({
        quantity: String(lineItem?.quantity),
        price: String(lineItem?.price?.toFixed(2)),
        note: lineItem?.note,
        courseId: lineItem.kitchen_meal_id ?? -1,
        discount: null,
        customDiscountType: "fixed_amount",
        customDiscountAmount: "",
      });
    } else {
      setEditState({
        quantity: null,
        price: null,
        note: null,
        courseId: null,
        discount: { id: -1, title: "None" },
        customDiscountType: "fixed_amount",
        customDiscountAmount: "",
      });
    }
  }, [open, lineItem]);

  const customDiscountRef = useCallback(element => {
    if (element !== null) {
      element?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, []);

  function updateQuantity(type: "increment" | "decrement") {
    let currentQuantity = Number(editState?.quantity);
    if (type === "increment") {
      currentQuantity = !isEmpty(editState?.quantity) ? ++currentQuantity : 1;
    } else {
      currentQuantity = !isEmpty(editState?.quantity) ? (currentQuantity > 1 ? --currentQuantity : currentQuantity) : 1;
    }
    setEditState(prevState => ({ ...prevState, quantity: String(currentQuantity) }));
  }

  function quantityOnBlur() {
    const currentQuantity = Number(editState?.quantity);
    if (currentQuantity <= 0 || isEmpty(editState?.quantity)) {
      setEditState(prevState => ({ ...prevState, quantity: String(1) }));
    }
  }

  function priceOnBlur() {
    const currentPrice = Number(editState?.price);
    setEditState(prevState => ({
      ...prevState,
      price: currentPrice < 0 || isEmpty(editState?.price) ? String(0) : String(currentPrice?.toFixed(2)),
    }));
  }

  function openModifiers(lineItem: ICartLineItem) {
    void onCancel();
    void openModifierModal(lineItem);
  }

  const disableRemoveLineItem = !(
    permissions?.orders_remove_line_item ||
    (permissions?.orders_remove_line_item_after_send && !lineItem?.kitchen_chit_id)
  );

  return (
    <Sheet
      title="Update Item"
      open={open}
      size="medium"
      onCancel={() => onCancel()}
      onOk={() =>
        update({
          ...editState,
          discount: editState.discount?.id === -1 ? null : editState?.discount,
          courseId: editState?.courseId === -1 ? null : editState?.courseId,
        })
      }
      okText="Update"
      closable
      okDisabled={editState.discount?.custom && !editState.customDiscountAmount}
    >
      <div className="edit-line-item-modal">
        <div className="edit-item-header-container">
          <div className="edit-item-title-container">
            <h1>{lineItem?.preferred_title ? lineItem?.preferred_title : lineItem?.product_title}</h1>
            {lineItem?.product_title !== lineItem?.variant_title && <p>{lineItem?.variant_title}</p>}
          </div>
          <div className="edit-item-icons-container">
            {openSplitItemModal && !lineItem?.parent_id && (
              <Button
                onClick={() => openSplitItemModal()}
                type="text"
                size="medium"
                style={{ color: "#3D5FA0" }}
                disabled={!!lineItem?.parent_id}
              >
                <FontAwesomeIcon size="1x" icon={["far", "split"]} className="mr-2" /> Split Item
              </Button>
            )}
            {!lineItem?.parent_id && !!lineItem?.product?.has_modifiers && (
              <Button
                onClick={() => openModifiers(lineItem)}
                type="text"
                size="medium"
                style={{ color: "#3D5FA0" }}
                disabled={!!lineItem?.parent_id || !lineItem?.product?.has_modifiers}
              >
                <FontAwesomeIcon size="1x" icon={["far", "salt-shaker"]} className="mr-2" /> Modify
              </Button>
            )}
            <Button type="error-outline" size="medium" disabled={disableRemoveLineItem} onClick={removeLineItem}>
              <FontAwesomeIcon size="1x" icon={["far", "trash-can"]} className="mr-2" /> Delete Item
            </Button>
          </div>
        </div>

        <FormLayout>
          <FormLayout.Group>
            <Input
              label="Quantity"
              placeholder="Quantity"
              value={editState.quantity ?? ""}
              type="number"
              min={1}
              className="edit-item-quantity-input"
              onBlur={quantityOnBlur}
              onChange={e => setEditState(prevState => ({ ...prevState, quantity: e.target.value }))}
              disabled={lineItem?.kitchen_status === "sent"}
              leadingButtons={[
                <Button
                  key={1}
                  type="secondary"
                  onClick={() => updateQuantity("decrement")}
                  disabled={lineItem?.kitchen_status === "sent"}
                >
                  <FontAwesomeIcon icon={["far", "minus"]} size="1x" />
                </Button>,
              ]}
              trailingButtons={[
                <Button
                  key={2}
                  type="secondary"
                  onClick={() => updateQuantity("increment")}
                  disabled={lineItem?.kitchen_status === "sent"}
                >
                  <FontAwesomeIcon icon={["far", "plus"]} size="1x" />
                </Button>,
              ]}
            />
            <Input
              label="Price"
              placeholder="Price"
              type="number"
              value={editState?.price ?? ""}
              onChange={e => setEditState(prevState => ({ ...prevState, price: e.target.value }))}
              onBlur={priceOnBlur}
              disabled={!permissions?.orders_edit_line_item_price || lineItem?.product?.price_locked}
              prefix="$"
            />
          </FormLayout.Group>
          {!lineItem?.parent_id && (
            <FormLayout.Group>
              <TextField
                label="Note"
                placeholder="Notes"
                value={editState?.note ?? ""}
                onChange={e => setEditState(prevState => ({ ...prevState, note: e.target.value }))}
              />
            </FormLayout.Group>
          )}
          {facilityStore?.kitchenCourses?.length > 0 && !lineItem?.parent_id && courses && (
            <FormLayout.Group>
              <Select
                label="Course"
                defaultValue={lineItem?.kitchen_meal_id ?? -1}
                onChange={value => setEditState(prev => ({ ...prev, courseId: value }))}
                usePortal
              >
                {[
                  { id: -1, title: "No Course" },
                  ...facilityStore?.kitchenCourses?.sort((a, b) => a.title.localeCompare(b.title)),
                ]?.map(course => {
                  return (
                    <Option key={course?.id} value={course?.id}>
                      {course?.title}
                    </Option>
                  );
                })}
              </Select>
            </FormLayout.Group>
          )}
          <FormLayout.Group>
            <Select
              label="Discount"
              onChange={(id, discount) => setEditState(prev => ({ ...prev, discount }))}
              usePortal
              disabled={!permissions?.orders_apply_discount}
              defaultValue={-1}
            >
              {[{ id: -1, title: "None", application: "line_item" }, ...facilityStore?.discountOptions]
                ?.filter((filteredDiscount: IDiscount) => filteredDiscount?.application === "line_item")
                ?.map((discount: IDiscount) => {
                  return (
                    <Option key={discount?.id} value={discount?.id} extraValues={discount}>
                      {discount?.title}
                    </Option>
                  );
                })}
            </Select>
          </FormLayout.Group>
          {editState?.discount && editState?.discount?.custom && (
            <FormLayout.Group alignCenter>
              <Input
                label={editState.customDiscountType === "fixed_amount" ? "Fixed Amount" : "Percent"}
                value={editState.customDiscountAmount}
                prefix={editState.customDiscountType === "fixed_amount" ? "$" : undefined}
                suffix={editState.customDiscountType === "percent" ? "%" : undefined}
                type="number"
                onChange={e => setEditState(prevState => ({ ...prevState, customDiscountAmount: e.target.value }))}
              />
              <div className="edit-item-custom-discount" ref={customDiscountRef}>
                <Checkbox
                  label="Fixed Amount"
                  size="medium"
                  circle
                  fullWidth
                  checked={editState.customDiscountType === "fixed_amount"}
                  onChange={() => setEditState(prevState => ({ ...prevState, customDiscountType: "fixed_amount" }))}
                />
                <Checkbox
                  label="Percent"
                  size="medium"
                  circle
                  fullWidth
                  checked={editState.customDiscountType === "percent"}
                  onChange={() => setEditState(prevState => ({ ...prevState, customDiscountType: "percent" }))}
                />
              </div>
            </FormLayout.Group>
          )}
        </FormLayout>
      </div>
    </Sheet>
  );
}
