import { APIGet, APIPost, APIPut, APIDelete, StatusCode } from "api/protocols";
import { IProduct } from "redux/reducers/models/product";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/facility/power-cart";

interface IGetPowerCart {
  id?: number;
  status?: string;
  offset?: number;
  limit?: number;
}

// GET Power carts
export async function GetPowerCarts(params: IGetPowerCart, useGlobalLoader: boolean) {
  return await APIGet(baseUrl, params, useGlobalLoader);
}

interface IPostPowerCart {
  amount: number;
  name: string;
  brand: string;
  type: string;
  starting_number: number;
}

// POST Power carts
export async function PostPowerCarts(params: IPostPowerCart, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

interface IPutPowerCart {
  id: number;
  name?: string;
  brand?: string;
  status?: string;
  type?: string;
}

// PUT Power carts
export async function PutPowerCart(params: IPutPowerCart, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

interface IDeletePowerCart {
  id: number;
}

// DELETE Power carts
export async function DeletePowerCart(params: IDeletePowerCart, useGlobalLoader: boolean) {
  return await APIDelete(baseUrl, params, useGlobalLoader);
}
