import Sheet from "components/sheet/Sheet";
import React, { useState } from "react";
import { Select } from "components/select/index";
import { IDiscount } from "api/rpc/2022-09/facilityAdmin/product/discount";
import Input from "components/form/input";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Checkbox from "components/form/checkbox/Checkbox";
import { useTranslation } from "react-i18next";

interface IDiscountLineItemModalProps {
  open: boolean;
  title: string;
  onCancel: () => void;
  onOk: (customAmount?: string, typeFixed?: boolean) => void;
  onChange: (id: number, discount: IDiscount) => void;
  okDisabled: boolean;
  discounts: IDiscount[];
  selectedDiscount?: IDiscount;
}
interface IState {
  customAmount: string;
  /**typeFixed = true to display fixed amount input, else display percent input */
  typeFixed: boolean;
}

export default function DiscountLineItemModal(props: IDiscountLineItemModalProps) {
  const { open, title, onCancel, onOk, onChange, okDisabled, discounts, selectedDiscount } = props;
  const [state, setState] = useState<IState>({
    customAmount: "",
    typeFixed: true,
  });

  const { Option } = Select;
  const { t, i18n } = useTranslation();

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    const { id, value } = e.target;
    setState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;
    setState(prevState => ({ ...prevState, [id]: value === "true" ? true : false }));
  }

  function handleOnCancel() {
    setState(prevState => ({ ...prevState, customAmount: "", typeFixed: true }));
    void onCancel();
  }

  function handleOnOk() {
    if (selectedDiscount?.custom) {
      void onOk(state.customAmount, state.typeFixed);
    } else {
      void onOk();
    }

    setState(prevState => ({ ...prevState, customAmount: "", typeFixed: true }));
  }

  // Stop Enter key from refreshing page
  function handleKeyDown(e: KeyboardEvent) {
    if (e.key === "Enter") {
      e.preventDefault();
      handleOnOk();
    }
  }

  const fieldEmpty = selectedDiscount?.custom === true && state.customAmount === "" ? true : false;

  return (
    <>
      {open && (
        <Sheet
          size="small"
          title={title}
          open={open}
          onCancel={handleOnCancel}
          onOk={handleOnOk}
          okText={t("components.discount_line_item_modal.discount_line_item_modal.001")}
          closable
          cancelText={t("components.discount_line_item_modal.discount_line_item_modal.002")}
          okDisabled={okDisabled || fieldEmpty}
          stacked
          overflow
        >
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Select
                  label={t("components.discount_line_item_modal.discount_line_item_modal.003")}
                  className="flex justify-center align-center w-full h-10 position-relative z-20 text-black text-medium appearance-none border-none focus:outline-none placeholder-gray-200"
                  placeholder={t("components.discount_line_item_modal.discount_line_item_modal.004")}
                  onChange={onChange}
                >
                  {discounts?.length > 0 &&
                    discounts
                      .filter(discount => discount.application === "line_item")
                      .map(discount => {
                        return (
                          <Option key={discount.id} value={discount.id} extraValues={discount}>
                            {discount.title}
                          </Option>
                        );
                      })}
                </Select>
              </FormLayout.Group>
              {selectedDiscount?.custom && (
                <FormLayout.Group>
                  <div className="flex flex-col gap-2">
                    <Checkbox
                      id="typeFixed"
                      size="medium"
                      value="true"
                      checked={state.typeFixed}
                      onChange={handleCheckboxChange}
                      label={t("components.discount_line_item_modal.discount_line_item_modal.005")}
                      circle
                    />
                    <Checkbox
                      id="typeFixed"
                      size="medium"
                      value="false"
                      checked={!state.typeFixed}
                      onChange={handleCheckboxChange}
                      label={t("components.discount_line_item_modal.discount_line_item_modal.006")}
                      circle
                    />
                  </div>
                  {state.typeFixed ? (
                    <Input
                      type="number"
                      label={t("components.discount_line_item_modal.discount_line_item_modal.007")}
                      prefix="$"
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                      id="customAmount"
                      value={state.customAmount}
                      placeholder="0.00"
                    />
                  ) : (
                    <Input
                      type="number"
                      label={t("components.discount_line_item_modal.discount_line_item_modal.008")}
                      suffix="%"
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                      id="customAmount"
                      value={state.customAmount}
                      placeholder="0"
                    />
                  )}
                </FormLayout.Group>
              )}
            </FormLayout>
          </Form>
        </Sheet>
      )}
    </>
  );
}
