import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { TModifierGroup, TModifierGroupExtended } from "redux/reducers/models/facility";
import { IVariantModifier } from "pages/secure/facility/settings/modifierGroup/ModifierGroupEdit";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/product/modifier-group";

export type TGetModifierGroup = {
  id?: string | number;
  product_id?: number;
  /** Returns attached variants */
  extended?: boolean;
};

export type TPostModifierGroup = {
  title: string;
  multi_select?: boolean; // Defaults to false
  required?: boolean; // Defaults to false
};

export type TPutModifierGroup = {
  id: string | number;
  title?: string;
  multi_select?: boolean;
  required?: boolean;
};

export type TPostModifierGroupVariant = {
  modifier_group_id?: number;
  variants?: IVariantModifier[];
};

export async function GetModifierGroup(params: TGetModifierGroup, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<TModifierGroup[] | TModifierGroupExtended[]>(baseUrl, params, useGlobalLoader, token);
}

export async function PostModifierGroup(params: TPostModifierGroup, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

export async function PutModifierGroup(params: TPutModifierGroup, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

export async function DeleteModifierGroup(params: { id: string | number }, useGlobalLoader: boolean) {
  return await APIDelete(baseUrl, params, useGlobalLoader);
}

/** Add array of existing variants to a modifier group */
export async function PostModifierGroupVariants(params: TPostModifierGroupVariant, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/variant", params, useGlobalLoader);
}
