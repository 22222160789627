import { ComponentKey, FilterActionType, FilterKey } from "redux/reducers/filters/types";

export interface IFilterAction<T> {
  type: FilterActionType;
  name: ComponentKey;
  payload: {
    groupKey: T;
    value: FilterValue | FilterValue[];
    labelArray?: string[];
  };
}

export type FilterValue = string | number | boolean | Date;
export type FilterDispatchType = <T>(action: IFilterAction<T>) => void;

/** Add single filter value to group */
export function addFilter<T extends string>(props: {
  collectionName: ComponentKey;
  key: T;
  value: FilterValue;
  displayLabels: string[];
}) {
  const { collectionName, key, value, displayLabels } = props;
  return (dispatch: FilterDispatchType) => {
    dispatch({
      type: FilterActionType.ADD,
      name: collectionName,
      payload: {
        groupKey: key,
        value: value,
        labelArray: displayLabels,
      },
    });
  };
}

/** Remove single filter value to group */
export function removeFilter<T extends string>(props: {
  collectionName: ComponentKey;
  key: T;
  value: FilterValue;
  displayLabels: string[];
}) {
  const { collectionName, key, value, displayLabels } = props;
  return (dispatch: FilterDispatchType) => {
    dispatch({
      type: FilterActionType.REMOVE,
      name: collectionName,
      payload: {
        groupKey: key,
        value: value,
        labelArray: displayLabels,
      },
    });
  };
}

/** Remove all filter values */
export function clearFilter<T extends FilterKey>(props: { collectionName: ComponentKey; key?: T }) {
  const { collectionName, key } = props;
  return (dispatch: FilterDispatchType) => {
    dispatch({
      type: FilterActionType.CLEAR,
      name: collectionName,
      payload: {
        groupKey: key ?? undefined, // undefined = clear all filters in every collection
        value: [],
      },
    });
  };
}

/** Add [] of filter values to non-existent group */
export function applyDefaults<T extends string>(props: {
  collectionName: ComponentKey;
  key: T;
  value: FilterValue[];
  displayLabels: string[];
}) {
  const { collectionName, key, value, displayLabels } = props;
  return (dispatch: FilterDispatchType) => {
    dispatch({
      type: FilterActionType.DEFAULTS,
      name: collectionName,
      payload: {
        groupKey: key,
        value: value,
        labelArray: displayLabels,
      },
    });
  };
}

export function updatePriceMatchFilter<T extends string>(props: {
  collectionName: ComponentKey;
  groupKey: T;
  value: FilterValue[];
  displayLabels: string[];
}) {
  const { collectionName, groupKey, value, displayLabels } = props;
  return (dispatch: FilterDispatchType) => {
    dispatch({
      type: FilterActionType.UPDATE_PM,
      name: collectionName,
      payload: {
        groupKey: groupKey,
        value: value,
        labelArray: displayLabels,
      },
    });
  };
}

export function updateCreditCardFilter<T extends string>(props: {
  collectionName: ComponentKey;
  groupKey: T;
  value: FilterValue[];
  displayLabels: string[];
}) {
  const { collectionName, groupKey, value, displayLabels } = props;
  return (dispatch: FilterDispatchType) => {
    dispatch({
      type: FilterActionType.UPDATE_CC,
      name: collectionName,
      payload: {
        groupKey: groupKey,
        value: value,
        labelArray: displayLabels,
      },
    });
  };
}
