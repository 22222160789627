import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetTicketStub, PutRedeem } from "api/rpc/2022-09/facilityAdmin/ticket/ticket";
import {
  GetCustomerMemberships,
  DeleteMembership,
  DisableMembership,
  PostCustomerMembership,
  MoveMembership,
} from "api/rpc/2024-04/facilityAdmin/customer/membership";

import { GetCustomer } from "api/rpc/facilityAdmin/customer";
import { showError } from "redux/actions/ui";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { capitalize } from "helpers/Helpers";
import { useCustomerContext } from "../../context/CustomerContext";
import { CustomerMembershipsType } from "../../context/contextTypes";

import Spin from "components/spin/spin";
import { ButtonNew as Button } from "components/buttonNew";
import NewCustomerMembershipModal from "elements/customer/membership/NewCustomerMembershipModal";
import TicketStubDisplay from "elements/customer/membership/TicketStubDisplay";
import MembershipDisplay from "elements/customer/membership/MembershipDisplay";
import NewCustomer, { ICustomerInfoState } from "components/newCustomer/NewCustomer";
import Sheet from "components/sheet/Sheet";
import GolferCard from "components/bookingPopUp/golferCard/GolferCard";
import { Select } from "components/select/index";
import { ICustomer } from "redux/reducers/models/customer";

import "./CustomerMemberships.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Popup from "components/popup/Popup";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { MOBILE_WIDTH } from "helpers/ScreenSizes";

interface IDisableMembershipState {
  showDisableMembership: boolean;
  membershipId: number;
}

interface IDeleteMembershipState {
  showDeleteMembership: boolean;
  membershipIdToDelete: number;
}

interface IMoveMembershipState {
  showMoveMembership: boolean;
  showWarning: boolean;
  membershipId: number;
  customerQuery: string;
  customerSearching: boolean;
  customerSearchResult: Array<ICustomer>;
  selectedCustomer: ICustomer;
  currentCustomer: ICustomer;
}

export default function CustomerMemberships() {
  const { t } = useTranslation();

  const { Option } = Select;

  const { state, updateState } = useCustomerContext();

  const [disableState, updateDisableState] = useState<IDisableMembershipState>({
    showDisableMembership: false,
    membershipId: null,
  });

  const [deleteState, updateDeleteState] = useState<IDeleteMembershipState>({
    showDeleteMembership: false,
    membershipIdToDelete: null,
  });

  const [moveState, updateMoveState] = useState<IMoveMembershipState>({
    showMoveMembership: false,
    showWarning: false,
    membershipId: null,
    customerQuery: "",
    customerSearching: false,
    customerSearchResult: null,
    selectedCustomer: null,
    currentCustomer: state.customer,
  });

  const dispatch = useAppDispatch();
  const authStore = useAppSelector(state => state.authStore);

  const permissions = authStore.user?.permissions;
  const windowSize = useWindowSize();

  const activeMemberships = state.memberships?.filter(membership => membership.status === "active");
  const pastMemberships = state.memberships?.filter(membership => membership.status !== "active");

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (state.memberships === null) {
      void loadCustomerMemberships(state.customer.id, source.token);
    }

    if (state.ticketStubs === null) {
      void loadTicketStubs(state.customer.id, source.token);
    }

    return () => {
      source.cancel("Customer Memberships unmounted.");
    };
  }, [state.customer.id]);

  async function loadCustomerMemberships(customerId: number, token?: CancelToken, useGlobalLoader?: boolean) {
    const membershipRes = await GetCustomerMemberships(
      { customer_id: customerId, parent_membership: true },
      useGlobalLoader ?? false,
      token,
    );
    if (membershipRes.status !== StatusCode.OK) {
      console.log("Error obtaining customer memberships: ", token.reason.message);
      return;
    }

    updateState({ memberships: membershipRes.data });
  }

  async function loadTicketStubs(customerId: number, token?: CancelToken, useGlobalLoader?: boolean) {
    const params = {
      customer_id: customerId,
      extended: true,
    };

    const ticketStubRes = await GetTicketStub(params, useGlobalLoader ?? false, token);
    if (ticketStubRes.status !== StatusCode.OK) {
      return;
    }

    const tempTicketStubsList: any = [];

    const sortedTicketStubs = ticketStubRes.data.sort((a: any, b: any) => {
      return a.ticket_id - b.ticket_id;
    });

    const uniqueTicketIds: any = [...new Set(sortedTicketStubs.map((item: { ticket_id: number }) => item.ticket_id))];

    uniqueTicketIds.map((ticketId: number, index: number) => {
      const tempSortedTicket: any[] = [];

      sortedTicketStubs.map((sortedStub: any, innerIndex: number) => {
        if (sortedStub.ticket_id === ticketId) {
          tempSortedTicket.push(sortedStub);
        }
      });

      tempTicketStubsList.push(tempSortedTicket);
    });

    console.log("ticketStubsList", tempTicketStubsList); //debug

    updateState({ ticketStubs: tempTicketStubsList });
  }

  async function postMembershipToCustomer(customerId: number, selectedMembershipId: number) {
    const res = await PostCustomerMembership(
      {
        customer_id: customerId,
        membership_id: selectedMembershipId,
      },
      true,
    );

    if (res.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.customer.tabs.memberships.customer_memberships.001")));
      return;
    }
    void loadCustomerMemberships(state.customer.id, null, true);
  }

  // async function redeemTicketStub(code: string) {
  //   const putRedeemRes = await PutRedeem(code, true);

  //   if (putRedeemRes.status !== StatusCode.OK) {
  //     console.log("Error redeeming ticket");
  //     return;
  //   }

  //   await loadTicketStubs(state.customer.id, null, true);
  // }

  async function handleDisableMembership() {
    const disableMembershipRes = await DisableMembership({ membership_id: disableState.membershipId }, true);

    if (disableMembershipRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.customer.tabs.memberships.customer_memberships.005")));
      updateDisableState(prevState => ({ ...prevState, showDisableMembership: false, membershipId: null }));

      return;
    }

    updateDisableState(prevState => ({ ...prevState, showDisableMembership: false, membershipId: null }));

    const source = axios.CancelToken.source();
    void loadCustomerMemberships(state.customer.id, source.token, true);
  }

  function handleDisableMembershipPopup(membership_id: number) {
    updateDisableState(prevState => ({ ...prevState, showDisableMembership: true, membershipId: membership_id }));
  }

  function handleDeleteMembershipPopup(membership_id: number) {
    updateDeleteState(prevState => ({
      ...prevState,
      showDeleteMembership: true,
      membershipIdToDelete: membership_id,
    }));
  }

  async function handleDeleteMembership() {
    const deleteMembershipRes = await DeleteMembership({ id: deleteState.membershipIdToDelete }, true);

    if (deleteMembershipRes.status !== StatusCode.OK) {
      dispatch(showError("Error deleting membership"));
      updateDeleteState(prevState => ({ ...prevState, showDeleteMembership: false, membershipIdToDelete: null }));

      return;
    }

    updateDeleteState(prevState => ({ ...prevState, showDeleteMembership: false, membershipIdToDelete: null }));

    const source = axios.CancelToken.source();
    void loadCustomerMemberships(state.customer.id, source.token, true);
  }

  async function handleMoveMembership() {
    const moveMembershipRes = await MoveMembership(
      {
        customer_membership_id: moveState.membershipId,
        new_customer_id: moveState.selectedCustomer.id,
      },
      true,
    );

    if (moveMembershipRes.status !== StatusCode.OK) {
      dispatch(showError("Error moving membership"));
      return;
    }

    updateMoveState(prevState => ({
      ...prevState,
      showMoveMembership: false,
      membershipId: null,
      showWarning: false,
      selectedCustomer: null,
      customerQuery: "",
    }));

    updateState({
      customer: { ...state.customer, ...moveMembershipRes.data.customer },
    });

    const source = axios.CancelToken.source();
    void loadCustomerMemberships(state.customer.id, source.token, true);
  }

  function handleMoveMembershipPopup(membership_id: number) {
    updateMoveState(prevState => ({ ...prevState, showMoveMembership: true, membershipId: membership_id }));
  }

  function changePlayerHandleCustomerSearch(query: string) {
    updateMoveState(prevState => ({ ...prevState, customerQuery: query }));
  }

  function handleChangePlayerSelection(id: number, customer: ICustomer) {
    updateMoveState(prevState => ({ ...prevState, selectedCustomer: customer }));
  }

  function handleCancelMoveMembership() {
    updateMoveState(prevState => ({
      ...prevState,
      showMoveMembership: false,
      membershipId: null,
      showWarning: false,
      selectedCustomer: null,
      customerQuery: "",
    }));
  }

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = global.setTimeout(() => {
        void changePlayerSearch(mounted, moveState.customerQuery);
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      updateMoveState(prevState => ({ ...prevState, playerSearchResult: [] }));
    };
  }, [moveState.customerQuery]);

  async function changePlayerSearch(mounted: boolean, customerSearchQuery: string) {
    try {
      if (customerSearchQuery === "") {
        if (mounted) {
          updateMoveState(prevState => ({ ...prevState, customerSearchResult: [] }));
        }
        return;
      } else {
        updateMoveState(prevState => ({ ...prevState, customerSearching: true }));
        const customerRes = await GetCustomer({ search: customerSearchQuery }, false);
        if (customerRes.status !== StatusCode.OK) {
          updateMoveState(prevState => ({ ...prevState, customerSearching: false, customerSearchResult: [] }));
          return;
        } else if (mounted) {
          updateMoveState(prevState => ({
            ...prevState,
            customerSearching: false,
            customerSearchResult: customerRes.data,
          }));
        }
      }
    } catch (error) {
      console.log("err", error);
    }
    return;
  }

  /** Handles -- MembershipDisplay || Empty Card || Spinner -- rendering */
  const renderMembershipCards = (memberships: CustomerMembershipsType[], expired: boolean) => {
    return (
      <>
        {memberships ? (
          <>
            {memberships.length > 0 ? (
              memberships.map(membership => {
                console.log(membership);
                return (
                  <div key={membership.id.toString() + "_active"} style={{ marginBottom: "14px" }}>
                    <MembershipDisplay
                      id={membership.id}
                      title={membership.membership_title}
                      subtitle={membership?.membership_subtitle}
                      type={membership?.membership_type}
                      expiry={membership.expiry}
                      parent_membership={membership.parent_membership}
                      status={membership.status}
                      status_readable={membership.status_readable}
                      disableMembership={handleDisableMembershipPopup}
                      deleteMembership={handleDeleteMembershipPopup}
                      moveMembership={handleMoveMembershipPopup}
                    />

                    {/* <div>
                      <Button onClick={() => handleDisableMembership(membership.id)} type="primary">
                        Disable
                      </Button>
                    </div> */}
                  </div>
                );
              })
            ) : (
              <div key="card_empty" style={{ marginBottom: "14px" }}>
                <MembershipDisplay
                  id={0}
                  title={`${t("secure.facility.customer.tabs.memberships.customer_memberships.006")}`}
                  subtitle={`${t("secure.facility.customer.tabs.memberships.customer_memberships.007")}`}
                  emptyCard
                />
              </div>
            )}
          </>
        ) : (
          <div className="spinner">
            <span style={{ width: "50px" }}>
              <Spin />
            </span>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="flex">
      <div className="membership-tab-content">
        <div className="membership-tab-content-header">
          <h1 className="ui-customer_page-header">
            {t("secure.facility.customer.tabs.memberships.customer_memberships.003")}
          </h1>
          <div>
            {!!permissions.memberships_apply_manual && (
              <NewCustomerMembershipModal
                applyMembershipToCustomer={selectedMembershipId =>
                  postMembershipToCustomer(state.customer.id, selectedMembershipId)
                }
                adminType="facility"
              >
                {/* onClick handled inside parent */}
                <Button type="secondary" size="medium">
                  <FontAwesomeIcon className="mr-2" icon={["far", "plus"]} />
                  <span>{windowSize.width > MOBILE_WIDTH ? "Add membership" : "Add"}</span>
                </Button>
              </NewCustomerMembershipModal>
            )}
          </div>
        </div>

        {renderMembershipCards(activeMemberships, false)}

        <h2 className="ui-customer_page-header customer-memberships-past-memberships">
          {t("secure.facility.customer.tabs.memberships.customer_memberships.004")}
        </h2>

        {renderMembershipCards(pastMemberships, true)}

        {/* <TicketStubDisplay
            ticketStubs={state.ticketStubs}
            redeemTicketStub={ticketCode => redeemTicketStub(ticketCode)}
          /> */}
        <Popup
          open={disableState.showDisableMembership}
          type="warning"
          title={t("secure.facility.customer.tabs.memberships.customer_memberships.008")}
          description={t("secure.facility.customer.tabs.memberships.customer_memberships.009")}
          onOk={handleDisableMembership}
          okText={t("secure.facility.customer.tabs.memberships.customer_memberships.010")}
          cancelText={t("secure.facility.customer.tabs.memberships.customer_memberships.011")}
          onCancel={() =>
            updateDisableState(prevState => ({ ...prevState, showDisableMembership: false, membershipId: null }))
          }
        />

        <Popup
          open={deleteState.showDeleteMembership}
          type="warning"
          title={"Delete Membership"}
          description={"Are you sure you want to delete this membership? This cannot be undone"}
          onOk={handleDeleteMembership}
          okText={"Delete"}
          cancelText={"Cancel"}
          onCancel={() =>
            updateDeleteState(prevState => ({
              ...prevState,
              showDeleteMembership: false,
              membershipIdToDelete: null,
            }))
          }
        />

        <Sheet
          title={"Move Membership"}
          open={moveState.showMoveMembership}
          size="small"
          closable
          onCancel={handleCancelMoveMembership}
          onOk={() => updateMoveState(prevState => ({ ...prevState, showWarning: true, showMoveMembership: false }))}
          okText={"Move"}
          okDisabled={moveState.selectedCustomer ? false : true}
          overflow
        >
          {moveState.currentCustomer !== null ? (
            <div>
              <span>{t("secure.facility.order.order.035")}</span>

              <GolferCard
                email={moveState.currentCustomer?.email}
                name={moveState.currentCustomer?.full_name}
                memberCode={moveState.currentCustomer?.member_code}
                customerType={moveState.currentCustomer?.customer_type}
                phone={moveState.currentCustomer?.phone}
              />

              <FontAwesomeIcon
                className="ml-auto mr-auto mt-4 mb-2 block text-primary-ui-colour"
                icon={["far", "arrow-circle-down"]}
                size="2x"
              />
            </div>
          ) : null}

          <span>{t("secure.facility.order.order.036")}</span>
          {moveState.selectedCustomer ? (
            <GolferCard
              closable
              removeGolfer={() =>
                updateMoveState(prevState => ({
                  ...prevState,
                  selectedCustomer: null,
                  playerSearchResult: [],
                  customerQuery: "",
                }))
              }
              email={moveState.selectedCustomer.email}
              name={moveState.selectedCustomer.full_name}
              memberCode={moveState.selectedCustomer.member_code}
              customerType={moveState.selectedCustomer.customer_type}
              phone={moveState.selectedCustomer.phone}
            />
          ) : (
            <Select
              showSearch
              className={`flex justify-center align-center w-full h-10 position-relative z-20 text-black font-medium appearance-none border-none focus:outline-none placeholder-gray-200`}
              onSearch={(query: string) => changePlayerHandleCustomerSearch(query)}
              onChange={(id: number, customer: ICustomer) => handleChangePlayerSelection(id, customer)}
              placeholder={t("secure.facility.order.order.037")}
              allowClear
              searchValue={moveState.customerQuery}
              showDropDownOnFocus={true}
              searching={moveState.customerSearching}
            >
              <></>
              {moveState.customerSearchResult?.map((customer, index) => {
                return (
                  <Option key={index} value={customer.id} name={customer.full_name} extraValues={customer}>
                    <div className="flex justify-between">
                      <div>
                        <div className="text-semibold text-lg">{customer?.full_name}</div>
                        <div className="text-sm text-gray-500">{customer.customer_type}</div>
                        <div className="text-sm text-gray-500">{customer.email}</div>
                        <div className="text-sm text-gray-500">{customer.phone ? customer.phone : null}</div>
                      </div>

                      <div className="font-medium text-base text-gray-500 self-end">{customer.member_code}</div>
                    </div>
                  </Option>
                );
              })}
            </Select>
          )}
        </Sheet>

        <Popup
          open={moveState.showWarning}
          type="warning"
          title={"Move Membership?"}
          description={"Are you sure you want to move this membership? This will reset the current customer's type"}
          onOk={handleMoveMembership}
          okText={"Move"}
          cancelText={"Cancel"}
          onCancel={handleCancelMoveMembership}
        />
      </div>
    </div>
  );
}
