import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios, { CancelToken } from "axios";
import { useTranslation, Trans } from "react-i18next";

import { StatusCode } from "api/protocols";
import { GetPayout, TFacilityPayout } from "api/rpc/2024-04/clientAdmin/facility/payouts";

import { showError } from "redux/actions/ui";
import { displayCurrency, valueToString } from "helpers/Helpers";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { annualFiltersObj } from "hooks/useOrderFilters/useDropFilters";

import Page from "components/page/Page";
import { Badge } from "components/badge/Badge";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import DropFilter from "components/dropdown/DropFilter";
import Card from "components/card/Card";
import { THIS_MONTH, THIS_YEAR } from "components/form/calendarField/helpers";

export default function Payouts() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const payoutFilters = annualFiltersObj();

  const dispatch = useAppDispatch();
  const clientFacilityStore = useAppSelector(store => store.clientFacilityStore);

  const [payouts, setPayouts] = useState<TFacilityPayout[]>(undefined);

  const [filterState, setFilterState] = useState({
    month: THIS_MONTH,
    year: THIS_YEAR,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (clientFacilityStore.facility) {
      void loadPayouts(clientFacilityStore?.facility?.id, source.token);
    }

    return () => {
      source.cancel();
    };
  }, [clientFacilityStore.facility, filterState.month, filterState.year]);

  async function loadPayouts(facilityId: string | number, token?: CancelToken) {
    if (payouts !== undefined) {
      setPayouts(undefined);
    }

    const res = await GetPayout(
      { facility_id: facilityId, month: filterState.month, year: filterState.year },
      token ? false : true,
      token,
    );
    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error getting payouts")); // TODO: Translation
    }

    setPayouts(res.status !== StatusCode.OK ? [] : res.data);
  }

  function handleApplyFilter(filterReturn: Record<string, any>[], type: string) {
    const tempReturn = filterReturn[0]; //works because of 'restrain' prop on dropfilter

    if (tempReturn === undefined) {
      setFilterState({ ...filterState, [type]: undefined });
      return;
    }

    setFilterState({ ...filterState, [type]: tempReturn.id });
  }

  return (
    <Page
      title={"Payouts"} // TODO: Translation
      subtitle={"Manage payouts"} // TODO: Translation
    >
      <div className="flex flex-row flex-wrap gap-4 pb-4">
        <DropFilter
          key="months"
          title="Month"
          filterData={payoutFilters.monthFilters("index")}
          filterLabelPropFromData="label"
          filterIdPropFromData="id"
          filterType="Checkbox"
          applyFilters={filterReturn => handleApplyFilter(filterReturn, "month")}
          defaultCheckboxes={[filterState.month]}
          restrain
          leftAligned
        />

        <DropFilter
          key="year"
          title="Year"
          filterData={payoutFilters.yearFilters()}
          filterLabelPropFromData="label"
          filterIdPropFromData="id"
          filterType="Checkbox"
          applyFilters={filterReturn => handleApplyFilter(filterReturn, "year")}
          defaultCheckboxes={[filterState.year]}
          restrain
        />
      </div>

      <Card>
        <Card.Section table="true">
          <DataTable
            columns={[
              { label: "Date" }, // TODO: Translation
              { label: "Status" }, // TODO: Translation
              { label: "Currency" }, // TODO: Translation
              { label: "Gross" }, // TODO: Translation
              { label: "Fee" }, // TODO: Translation
              { label: "Net" }, // TODO: Translation
              { label: "Process Date" }, // TODO: Translation
              { label: "Authorization" }, // TODO: Translation
            ]}
            loading={payouts === undefined}
          >
            {payouts?.map(payout => (
              <tr
                key={payout.id}
                className="clickable"
                onClick={() => history.push(`/admin/settings/facility-settings/payouts/${payout.id}`)}
              >
                <td>{payout.statement_description}</td>
                <td>
                  <Badge type="gray">{valueToString(payout.status, "capitalize")}</Badge>
                </td>
                <td>{valueToString(payout.currency, "uppercase")}</td>
                <td>{displayCurrency(payout.currency, payout.gross)}</td>
                <td>{displayCurrency(payout.currency, payout.fee)}</td>
                <td>{displayCurrency(payout.currency, payout.net)}</td>
                <td>{payout.process_date}</td>
                <td>{payout.authorization}</td>
              </tr>
            ))}
          </DataTable>
        </Card.Section>
      </Card>
    </Page>
  );
}
