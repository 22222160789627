import { APIGet, APIPost, APIPut, APIDelete, IAPIResponse, StatusCode } from "api/protocols";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/tee-sheet";

interface IGetPricingSheets {
  id?: number;
  extended?: boolean;
  day_of_week_number?: number;
}

export interface IPutCheckIns {
  id: number;
  customer_type_id: number;
  pricing_sheet_id: number;
  variant_id: number;
  holes: number;
}

interface IGetCheckIns {
  facility_id?: number;
  day_of_week_number?: number;
  application: Array<string>;
  customer_type_id: number;
  limit?: number;
  no_variant?: number;
}

interface IConfiguration {
  configurations: IPutCheckIns[];
}

interface IPutCopyCheckIns {
  check_in_ids: Array<number>;
  course_id?: number;
  day_of_week?: string;
  select_all_day_of_week?: string;
}

export async function GetCheckIns(params: IGetCheckIns, useGlobalLoader: boolean) {
  return APIGet(baseUrl + "/check-in", params, useGlobalLoader);
}

export async function GetPricingSheets(params: IGetPricingSheets, useGlobalLoader: boolean) {
  return APIGet(baseUrl + "/pricing-sheet", params, useGlobalLoader);
}

export async function PutCheckIns(params: IConfiguration, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/check-in", params, useGlobalLoader);
}

export async function PutCopyCheckIns(params: IPutCopyCheckIns, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/check-in/copy", params, useGlobalLoader);
}
