import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios, { CancelToken } from "axios";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { GetCreditBook } from "api/rpc/2022-09/facilityAdmin/client/creditBook";
import { GetTournament } from "api/rpc/2024-04/facilityAdmin/tournament/tournament";
import { GetLeague } from "api/rpc/2024-04/facilityAdmin/league/league";

import { showError } from "redux/actions/ui";
import { ITournament } from "redux/reducers/models/tournament";
import { ILeague } from "redux/reducers/models/league";

import { useAppDispatch } from "hooks/redux";
import { displayCurrency } from "helpers/Helpers";

import Page from "components/page/Page";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";
import { IPrizeAccount } from "./PrizeAccount";

export default function PrizeAccounts() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const [prizeAccounts, setPrizeAccounts] = useState<IPrizeAccount[]>(undefined);

  const [leagues, setLeagues] = useState<Array<ILeague>>([]);
  const [tournaments, setTournaments] = useState<Array<ITournament>>([]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    void loadPrizeAccounts(source.token);

    return () => source.cancel();
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (prizeAccounts) {
      void loadTournaments(source.token);
      void loadLeagues(source.token);
    }
    return () => source.cancel();
  }, [prizeAccounts]);

  async function loadPrizeAccounts(token?: CancelToken) {
    if (prizeAccounts !== undefined) {
      setPrizeAccounts(undefined);
    }
    const { status, data } = await GetCreditBook({ type: "prize_account" }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.prize_accounts.prize_accounts.001")));
    }

    setPrizeAccounts(status !== StatusCode.OK ? [] : data);
  }

  async function loadTournaments(token?: CancelToken) {
    if (!prizeAccounts) {
      return;
    }

    const tournamentIds = prizeAccounts
      ?.filter(prizeAccount => prizeAccount?.tournament_id)
      ?.map(prizeAccount => {
        if (prizeAccount.tournament_id) {
          return prizeAccount.tournament_id;
        }
      });

    const { status, data } = await GetTournament({ ids: [...new Set(tournamentIds)] }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (status !== StatusCode.OK) {
      dispatch(showError("Error loading tournaments"));
      return;
    }

    setTournaments(data);
  }

  async function loadLeagues(token?: CancelToken) {
    if (!prizeAccounts) {
      return;
    }

    const leagueIds = prizeAccounts
      ?.filter(prizeAccount => prizeAccount?.league_id)
      ?.map(prizeAccount => {
        if (prizeAccount.league_id) {
          return prizeAccount.league_id;
        }
      });

    const { status, data } = await GetLeague({ ids: [...new Set(leagueIds)] as number[] }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (status !== StatusCode.OK) {
      dispatch(showError("Error loading leagues"));
      return;
    }

    setLeagues(data);
  }

  const primaryAction = {
    content: t("secure.facility.settings.prize_accounts.prize_accounts.002"),
    action: () => history.push("/admin/settings/prizeAccounts/new"),
  };

  return (
    <>
      <Page
        title={t("secure.facility.settings.prize_accounts.prize_accounts.003")}
        primaryAction={primaryAction}
        narrow
      >
        <DataTable
          columns={[
            { label: t("secure.facility.settings.prize_accounts.prize_accounts.004") },
            { label: t("secure.facility.settings.prize_accounts.prize_accounts.005") },
            { label: t("secure.facility.settings.prize_accounts.prize_accounts.006") },
          ]}
          loading={prizeAccounts === undefined}
        >
          {prizeAccounts?.map(account => {
            const prizeTournament = tournaments.filter(tourny => tourny.id === account.tournament_id);
            const prizeLeague = leagues.filter(league => league.id === account.league_id);

            return (
              <tr
                key={account.id}
                onClick={() => history.push("/admin/settings/prizeAccounts/view/" + String(account.id))}
                className="clickable"
              >
                <td>
                  <p>{account.title}</p>
                </td>
                <td>
                  <p>{prizeTournament[0] ? prizeTournament[0].name : prizeLeague[0] ? prizeLeague[0].name : ""}</p>
                </td>

                <td>
                  <p>{displayCurrency("cad", account.balance)}</p>
                </td>
              </tr>
            );
          })}
        </DataTable>
      </Page>
    </>
  );
}
