import { APIGet, APIPost, APIPut, APIDelete, APIUrl, AdminType } from "api/protocols";
import { CancelToken } from "axios";
import { TFacilityStatus, TFacilityStatusInput } from "redux/reducers/models/facility";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/facility/status";

export type TGetFacilityStatus = {
  date?: string;
  month?: string;
};

export type TPostFacilityStatus = {
  /** YYYY-MM-DD */
  date: string;
  input_data: Array<{ status_input_id: number; value: string }>;
};

// GET Facility Statuses
export async function GetFacilityStatus(params: TGetFacilityStatus, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<TFacilityStatus[]>(baseUrl, params, useGlobalLoader, token);
}

// POST Facility Statuses
export async function PostFacilityStatus(params: TPostFacilityStatus, useGlobalLoader: boolean) {
  return await APIPost(APIUrl(AdminType.FacilityAdmin, "/facility/status"), params, useGlobalLoader);
}

// GET Facility Status Inputs
export async function GetFacilityStatusInputs(params: any, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<TFacilityStatusInput[]>(baseUrl + "/input", params, useGlobalLoader, token);
}
