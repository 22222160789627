import { StatusCode } from "api/protocols";
import { reader } from "helpers/StripeTerminalWrapper";

// Set the terminal after receiving connection token
export function setError(error: string, useGlobalLoader: boolean) {
  return (dispatch: any) => {
    dispatch({
      type: "terminal.update",
      payload: {
        error: error,
      },
    });
  };
}

export function setStatus(status: string, useGlobalLoader: boolean) {
  return (dispatch: any) => {
    dispatch({
      type: "terminal.update",
      payload: {
        status: status,
      },
    });
  };
}

export function removeReader(useGlobalLoader: boolean) {
  return (dispatch: any) => {
    dispatch({
      type: "terminal.update",
      payload: {
        isLoaded: true,
        reader: null,
      },
    });
  };
}

export function setReader(reader: reader, useGlobalLoader: boolean) {
  return (dispatch: any) => {
    dispatch({
      type: "terminal.update",
      payload: {
        isLoaded: true,
        reader: reader,
      },
    });
  };
}

export function setReaderArray(readers: reader[], useGlobalLoader: boolean) {
  return (dispatch: any) => {
    dispatch({
      type: "terminal.update",
      payload: {
        readers: readers,
      },
    });
  };
}

export function setReconnectionAttempts(attempts: number, useGlobalLoader: boolean) {
  return (dispatch: any) => {
    dispatch({
      type: "terminal.update",
      payload: {
        reconnectionAttempts: attempts,
      },
    });
  };
}

export interface ITerminalActions {
  setError: typeof setError;
  setReader: typeof setReader;
  removeReader: typeof removeReader;
  setReaderArray: typeof setReaderArray;
  setReconnectionAttempts: typeof setReconnectionAttempts;
  setStatus: typeof setStatus;
}
