import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { IBookingCategory } from "redux/reducers/models/teetime";

const apiVersion = "2021-01";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/tee-sheet/booking-engine/category";

interface IGetBookingCategory {
  id?: string;
  extended?: boolean;
}

interface IBookingCategoryRes {
  status: number;
  data: IBookingCategory[];
  message: string;
}

export async function GetBookingCategory(
  params: IGetBookingCategory,
  useGlobalLoader: boolean,
  token?: CancelToken,
): Promise<IBookingCategoryRes> {
  return await APIGet(baseUrl, params, useGlobalLoader, token);
}
