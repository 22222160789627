import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Chart, Line } from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import "./chart.scss";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  TimeScale,
  BarElement,
  ArcElement,
  RadialLinearScale,
} from "chart.js";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  BarElement,
  ArcElement,
  RadialLinearScale,
);

interface IAxisOptions {
  title: string;
  scale: "linear" | "logarithmic" | "category" | "time" | "timeseries" | "radialLinear";
  range?: Array<number>;
}

export interface IDisplayOptions {
  hide_legend: boolean;
  legend_title: string;
  legend_position: "top" | "bottom" | "left" | "right";
}

interface IProps {
  rawData: Record<string, any>;
  chartType: "bar" | "bubble" | "doughnut" | "line" | "pie" | "polarArea" | "radar" | "scatter";
  title: string;
  primaryAxisOptions: IAxisOptions;
  secondaryAxisOptions: IAxisOptions;
  displayOptions?: IDisplayOptions;
}

export default function DashboardChart(props: IProps) {
  const { rawData, displayOptions } = props;

  function setLocalTime(seriesData: Record<string, any>) {
    return seriesData?.map((data: Record<string, any>) => ({
      ...data,
      primary: moment.utc(data?.primary).local().format("YYYY-MM-DD HH:mm:ss"),
    })) as Array<Record<string, any>>;
  }

  function formatDataArray() {
    const newArray: Array<Record<string, any>> = [];

    rawData?.forEach((series: Record<string, any>) => {
      newArray.push({
        ...series,
        borderColor: series?.color,
        backgroundColor: series?.color,
        data: props?.primaryAxisOptions?.scale === "time" ? setLocalTime(series?.data) : series?.data,
      });
    });
    return newArray;
  }

  const data: { datasets: Array<any> } = { datasets: useMemo(() => formatDataArray(), [rawData]) };

  const options: ChartOptions = {
    //responsive: true,
    plugins: {
      legend: {
        position: displayOptions?.legend_position ?? "top",
        display: !displayOptions?.hide_legend ?? false,
        align: "center",
        title: {
          display: true,
          text: displayOptions?.legend_title ?? "",
        },
      },
      title: {
        display: true,
        text: props?.title,
        //position: "top"
      },
    },
    parsing: {
      xAxisKey: "primary",
      yAxisKey: "secondary",
    },
    scales: {
      x:
        props?.primaryAxisOptions?.scale === "time"
          ? {
              suggestedMin: props?.primaryAxisOptions?.range
                ? moment.utc(props?.primaryAxisOptions?.range[0]).local().format("YYYY-MM-DD HH:mm:ss")
                : undefined,
              suggestedMax: props?.primaryAxisOptions?.range
                ? moment.utc(props?.primaryAxisOptions?.range[1]).local().format("YYYY-MM-DD HH:mm:ss")
                : undefined,
              title: {
                display: true,
                text: props?.primaryAxisOptions?.title,
              },
              type: "time",
              //   time: {
              //     unit: 'hour',
              //     displayFormats: {
              //         'hour': 'MMM d h:mm a'
              //     },
              // },
            }
          : {
              suggestedMin: props?.primaryAxisOptions?.range ? props?.primaryAxisOptions?.range[0] : undefined,
              suggestedMax: props?.primaryAxisOptions?.range
                ? props?.primaryAxisOptions?.range[props?.primaryAxisOptions?.range?.length - 1]
                : undefined,
              title: {
                display: true,
                text: props?.primaryAxisOptions?.title,
              },
              type: props?.primaryAxisOptions?.scale,
            },

      y: {
        suggestedMin: props?.secondaryAxisOptions?.range ? props?.secondaryAxisOptions?.range[0] : undefined,
        suggestedMax: props?.secondaryAxisOptions?.range
          ? props?.secondaryAxisOptions?.range[props?.secondaryAxisOptions?.range?.length - 1]
          : undefined,
        title: {
          display: true,
          text: props?.secondaryAxisOptions?.title,
        },
        type: props?.secondaryAxisOptions?.scale,
        // ticks:{
        //   stepSize: 25,
        // }
      },
    },
  };

  return (
    <div>
      <div className="dashboard-chart-outer-container">
        <div className="dashboard-chart-container-size">
          <div className="dashboard-chart-inner-container">
            <Chart type={props?.chartType} data={data} options={options} />
          </div>
        </div>
      </div>
    </div>
  );
}
