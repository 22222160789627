import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { GetFacility } from "api/rpc/2022-09/clientAdmin/facility/facility";
import DropFilter from "components/dropdown/DropFilter";
import { DropFilterCheckboxOptionals } from "components/dropdown/dropFilterTypes";

type FacilityDropFilterProps = DropFilterCheckboxOptionals & {
  /** callback value to inform parent when ASYNC data is loaded */
  dataLoaded: () => void;
  /** When 'Apply' is clicked, returns all ID's of selected facilities */
  handleApply: (facility_ids: Array<number>) => void;
};

export default function FacilityDropFilter(props: FacilityDropFilterProps) {
  const { t, i18n } = useTranslation();
  const [facilityData, setFacilityData] = useState(null);
  const { dataLoaded, handleApply } = props;

  useEffect(() => {
    let mounted = true;
    const loadFacilities = async () => {
      const { status, data } = await GetFacility(null, false);
      if (status === StatusCode.OK && mounted) {
        setFacilityData(data);
        dataLoaded();
      }
    };

    void loadFacilities();

    return () => {
      mounted = false;
    };
  }, []);

  // Capture filter ID's and apply callback
  function handleApplyFilter(filterReturn: Record<string, any>[]) {
    const tempReturn: number[] = filterReturn.map((value: Record<string, any>, index: number) => {
      return value.id as number;
    });
    handleApply(tempReturn);
  }

  return (
    <DropFilter
      key="facility_ids"
      title={t("components.filters.facility_drop_filter.001")}
      filterData={facilityData}
      filterLabelPropFromData="long_name"
      filterIdPropFromData="id"
      filterType="Checkbox"
      applyFilters={filterReturn => handleApplyFilter(filterReturn)}
      defaultCheckboxes={props.defaultCheckboxes}
      leftAligned={props.leftAligned ?? false}
      rightAligned={props.rightAligned ?? false}
      dropdownSize={props.dropdownSize}
      darkMode={props.darkMode}
      save={props.save}
      historyKey={props.historyKey}
      restrain={props.restrain}
      disableDropdown={props.disableDropdown}
    />
  );
}
