import React, { useEffect, useRef } from "react";
import { useLeagueRegistrationContext } from "../LeagueRegistrationContext";
import { useDropEditInputLayout } from "hooks/editInputDragAndDrop/useDropEditInputLayout/useDropEditInputLayout";
import { ButtonNew as Button } from "components/buttonNew";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Callout from "components/callout/Callout";
import "./leagueRegistrationEditInputLayout.scss";
import LeagueRegistrationEditInputRow from "./LeagueRegistrationEditInputRow";

interface IProps {
  openModal: () => void;
}

export default function LeagueRegistrationEditInputLayout(props: IProps) {
  const leagueRegistrationContext = useLeagueRegistrationContext();

  const leagueLayoutRef = useRef<HTMLDivElement>(null);
  const leagueLayoutRowContainerRefs = useRef<HTMLDivElement[]>([]);

  const { drop, customDragLayer } = useDropEditInputLayout(
    leagueLayoutRef,
    leagueLayoutRowContainerRefs,
    leagueRegistrationContext.inputRowsContainerActions.update,
    leagueRegistrationContext.inputRowsContainer.updatedState,
  );

  useEffect(() => {
    if (leagueRegistrationContext.inputRowsContainer?.updatedState) {
      const numRows = Object.keys(leagueRegistrationContext.inputRowsContainer.updatedState).length;

      leagueLayoutRowContainerRefs.current = leagueLayoutRowContainerRefs.current.slice(0, numRows);
    }
  }, [leagueRegistrationContext.inputRowsContainer?.updatedState]);

  return (
    <>
      {leagueRegistrationContext.inputRowsContainer?.updatedState &&
      Object.keys(leagueRegistrationContext.inputRowsContainer.updatedState).length > 0 ? (
        <div className="league-registration-edit-input-layout">
          <div
            ref={el => {
              drop(el);
              leagueLayoutRef.current = el;
            }}
            className="league-registration-layout-rows"
          >
            {Object.keys(leagueRegistrationContext.inputRowsContainer.updatedState).map((row, index) => {
              const inputs = leagueRegistrationContext.inputRowsContainer.updatedState[row];
              const inputIds = inputs.map(input => input.id);
              const inputRowIdentifier = `[${inputIds.toString()}]`;

              return (
                <div ref={el => (leagueLayoutRowContainerRefs.current[index] = el)} key={inputRowIdentifier}>
                  <LeagueRegistrationEditInputRow inputRow={row} inputs={inputs} />
                </div>
              );
            })}
            {customDragLayer}
          </div>
          <hr className="league-registration-layout-add-input-divider" />
          <Button type="text" onClick={props.openModal} className="league-registration-layout-add-input">
            <FontAwesomeIcon className="league-registration-layout-add-input-icon" size="sm" icon={["far", "plus"]} />
            <span>Add Input</span>
          </Button>
        </div>
      ) : (
        <Callout
          type={"info"}
          title={"0 Registration Inputs."}
          content="You do not currently have any custom inputs for this registration.  Please add a new input to this registration to get started."
        />
      )}
    </>
  );
}
