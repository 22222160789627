import { StatusCode } from "api/protocols";
import { GetGiftCard } from "api/rpc/2022-09/facilityAdmin/payment/giftCard";
import Input from "components/form/input/Input";
import Sheet from "components/sheet/Sheet";
import Tabs from "components/tabs/Tabs";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "../giftCardRedeem/GiftCardRedeem.scss";
import classNames from "classnames";
import Callout from "components/callout/Callout";
import { ICart } from "redux/reducers/models/cart";

interface IGiftCardProps {
  redeemGiftCardsActive: boolean;
  onCancel?: () => void;
  onOk?: (giftCardInfo: IGiftCardRedeemState) => void;
  cart?: ICart;
}

export interface IGiftCardRedeemState {
  giftCardCode: string;
  giftCardPin: string;
}

interface IGiftCardState {
  customer_gift_cards: any[];
  selectedGiftCard: any;
}

function GiftCardRedeem(props: IGiftCardProps) {
  const { t, i18n } = useTranslation();
  const { cart } = props;

  const [selected, setSelected] = useState(0);

  useEffect(() => {
    if (props.redeemGiftCardsActive) {
      if (cart?.customer_id) {
        void loadCustomerGiftCards(cart?.customer_id);
      }
      setSelected(0);
    } else {
      setGiftCardState({ customer_gift_cards: [], selectedGiftCard: null });
    }
  }, [props.redeemGiftCardsActive]);

  const [state, setState] = useState<IGiftCardRedeemState>({
    giftCardCode: "",
    giftCardPin: "",
  });

  const [giftCardState, setGiftCardState] = useState<IGiftCardState>({
    customer_gift_cards: [],
    selectedGiftCard: null,
  });

  function onOk() {
    void props.onOk({ ...state });

    setState(prevState => ({ ...prevState, giftCardCode: "", giftCardPin: "" }));
    setGiftCardState(prevState => ({ ...prevState, selectedGiftCard: null }));
  }

  function onCancel() {
    setState(prevState => ({ ...prevState, giftCardCode: "", giftCardPin: "" }));
    props.onCancel();
  }

  function handlePaymentInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setState(prevState => ({ ...prevState, [id]: value }));
    setGiftCardState(prevState => ({ ...prevState, selectedGiftCard: null }));
  }

  const handleTabChange = (selectedTabIndex: number) => {
    setSelected(selectedTabIndex);
  };

  async function loadCustomerGiftCards(customer_id: any) {
    const giftCardRes = await GetGiftCard({ customer_id: customer_id }, true);

    const giftCards = giftCardRes.data;

    if (giftCardRes.status !== StatusCode.OK) {
      return;
    }

    setGiftCardState(prevState => ({ ...prevState, customer_gift_cards: giftCards }));
  }

  const tabs = [
    {
      id: "gift_cards",
      content: "Customer Gift Cards",
      panelID: "gift-cards-content-1",
    },

    {
      id: "enter_gift_card",
      content: "Enter Gift Card",
      panelID: "gift-card-content-2",
    },
  ];

  function handleGiftCardSelect(gift_card: any) {
    setGiftCardState(prevState => ({ ...prevState, selectedGiftCard: gift_card }));

    setState(prevState => ({
      ...prevState,
      giftCardCode: gift_card.code,
      giftCardPin: gift_card.pin,
    }));
  }

  return (
    props.redeemGiftCardsActive && (
      <Sheet
        title={"Enter Gift Card"}
        open={props.redeemGiftCardsActive}
        size="small"
        closable
        stacked
        onCancel={onCancel}
        onOk={onOk}
        okText={t("secure.facility.table_service.table_service.160")}
        cancelText={t("secure.facility.table_service.table_service.161")}
        okDisabled={!state.giftCardCode || !state.giftCardPin ? true : false}
      >
        {cart?.customer_id ? (
          <div>
            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
              {selected === 0 ? (
                <>
                  {giftCardState?.customer_gift_cards?.length > 0 ? (
                    giftCardState?.customer_gift_cards
                      .filter(giftCard => giftCard?.balance !== 0 && giftCard?.status === "valid")
                      .map((gift_card: any, index: number) => {
                        return (
                          <div
                            key={index}
                            className={
                              gift_card.id === giftCardState?.selectedGiftCard?.id
                                ? "customer-selected-gift-card"
                                : "customer-gift-card-container"
                            }
                            onClick={() => handleGiftCardSelect(gift_card)}
                          >
                            <p> Code: {gift_card.code}</p>
                            <p> Balance: ${gift_card.balance}</p>
                          </div>
                        );
                      })
                  ) : (
                    <Callout
                      type="info"
                      title={"No Gift Cards Found"}
                      content={"There are no gift cards saved to this customer"}
                    />
                  )}
                  <div></div>
                </>
              ) : null}
              {selected === 1 ? (
                <div className="mt-3">
                  <Input
                    className="mb-4"
                    value={state.giftCardCode ?? ""}
                    placeholder={"Gift Card Code"}
                    id="giftCardCode"
                    onChange={handlePaymentInputChange}
                    label={"Gift Card Code"}
                    autoFocus
                  />

                  <Input
                    value={state.giftCardPin ?? ""}
                    placeholder={"Gift Card Pin"}
                    id="giftCardPin"
                    onChange={handlePaymentInputChange}
                    label={"Gift Card Pin"}
                  />
                </div>
              ) : null}
            </Tabs>
          </div>
        ) : (
          <div>
            <div className="mt-3">
              <Input
                className="mb-4"
                value={state.giftCardCode ?? ""}
                placeholder={"Gift Card Code"}
                id="giftCardCode"
                onChange={handlePaymentInputChange}
                label={"Gift Card Code"}
                autoFocus
              />

              <Input
                value={state.giftCardPin ?? ""}
                placeholder={"Gift Card Pin"}
                id="giftCardPin"
                onChange={handlePaymentInputChange}
                label={"Gift Card Pin"}
              />
            </div>
          </div>
        )}
      </Sheet>
    )
  );
}

export default GiftCardRedeem;
