import { APIGet, APIPost, APIPut, APIDelete, StatusCode, IAPIResponse } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2022-09";
const adminType = "client-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/product/vendor";

export interface IVendor {
  id: number;
  title: string;
}
interface IGetVendor {
  id?: number;
  search?: string;
}

interface IPostVendor {
  title: string;
}

interface IPutVendor {
  id: number;
  title: string;
}

interface IVendorRes {
  data: IVendor[];
  status: StatusCode;
}

interface IPostVendorRes {
  data: IVendor[];
  status: StatusCode;
}

export async function GetVendor(
  params: IGetVendor,
  useGlobalLoader?: boolean,
  cancelToken?: CancelToken,
): Promise<IVendorRes> {
  return await APIGet(baseUrl, params, useGlobalLoader, cancelToken);
}

export async function PostVendor(params: IPostVendor, useGlobalLoader?: boolean): Promise<IPostVendorRes> {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

export async function PutVendor(params: IPutVendor, useGlobalLoader?: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}
