import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetProduct } from "api/rpc/2024-04/facilityAdmin/product/product";
import {
  DeleteTournamentRegistrationFee,
  PostTournamentRegistrationFee,
} from "api/rpc/2024-04/facilityAdmin/tournament/registration/registration";

import { showError } from "redux/actions/ui";
import { ITournamentRegistrationFee } from "redux/reducers/models/tournament";
import { IProduct, IVariant } from "redux/reducers/models/product";
import { useAppDispatch } from "hooks/redux";
import useModal from "hooks/modals/useModal";
import { displayCurrency } from "helpers/Helpers";
import classNames from "classnames";

import Card from "components/card/Card";
import Popup from "components/popup/Popup";
import Sheet from "components/sheet/Sheet";
import Input from "components/form/input/Input";
import Search from "components/search/Search";
import { Select } from "components/select";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import Portal from "elements/Portal";
import FormLayout from "components/form/FormLayout";
import RegistrationFeeInputs from "./RegistrationFeeInputs";

import "./registrationFeeTab.scss";

interface ITournamentRegistrationFeeTabProps {
  tournamentId: number;
  fees: ITournamentRegistrationFee[];
  addons: ITournamentRegistrationFee[];
  setUpdatedFees: (fees: ITournamentRegistrationFee[], loadTrigger: boolean) => void;
  setUpdatedAddons: (addons: ITournamentRegistrationFee[], loadTrigger: boolean) => void;
}

export default function RegistrationFeeTab(props: ITournamentRegistrationFeeTabProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [products, setProducts] = useState<IProduct[]>(undefined);

  const {
    state: removeFeeModal,
    updateModal: updateRemoveFeeModal,
    closeModal: closeRemoveFeeModal,
  } = useModal({ feeId: null });

  const {
    state: removeAddonModal,
    updateModal: updateRemoveAddonModal,
    closeModal: closeRemoveAddonModal,
  } = useModal({ addonId: null });

  const {
    state: newFeeModal,
    updateModal: updateNewFeeModal,
    closeModal: closeNewFeeModal,
  } = useModal({
    playerCount: 1,
    description: "",
    price: null,
    selectedVariant: undefined as IVariant,
    search: "",
    pageTwo: false,
  });

  const {
    state: newAddOnModal,
    updateModal: updateNewAddOnModal,
    closeModal: closeNewAddOnModal,
  } = useModal({
    playerCount: 1,
    description: "",
    price: null,
    selectedVariant: undefined as IVariant,
    search: "",
    pageTwo: false,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadProducts(newFeeModal.search, source.token);
    return () => source.cancel();
  }, [newFeeModal.search]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadProducts(newAddOnModal.search, source.token);
    return () => source.cancel();
  }, [newAddOnModal.search]);

  async function loadProducts(search?: string, token?: CancelToken) {
    if (products !== undefined) {
      setProducts(undefined);
    }

    const res = await GetProduct({ search: search, extended_variants: true }, token ? false : true, token);
    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading products."));
    }

    setProducts(res.status !== StatusCode.OK ? [] : res.data);
  }

  /** Close removeFeeModal when successful */
  async function deleteTournamentRegistrationFee(registration_fee_id: string | number) {
    const deleteTournamentRegistrationFeeResponse = await DeleteTournamentRegistrationFee(
      { registration_fee_id: registration_fee_id },
      true,
    );

    if (deleteTournamentRegistrationFeeResponse.status !== StatusCode.OK) {
      dispatch(showError("Error deleting registration fee."));
      return;
    }

    props.setUpdatedFees(
      props.fees.filter(fee => fee.id !== registration_fee_id),
      true,
    );
    closeRemoveFeeModal();
  }

  /** Close removeAddonModal when successful */
  async function deleteTournamentRegistrationAddon(registration_addon_id: string | number) {
    const deleteTournamentRegistrationAddonResponse = await DeleteTournamentRegistrationFee(
      { registration_fee_id: registration_addon_id },
      true,
    );

    if (deleteTournamentRegistrationAddonResponse.status !== StatusCode.OK) {
      dispatch(showError("Error deleting registration add on."));
      return;
    }

    props.setUpdatedAddons(
      props.addons.filter(fee => fee.id !== registration_addon_id),
      true,
    );

    closeRemoveAddonModal();
  }

  async function handleAddNewFee() {
    if (newFeeModal.selectedVariant?.id === undefined) {
      dispatch(showError("No registration fee is selected"));
      return;
    }

    const res = await PostTournamentRegistrationFee(
      {
        type: "registration_fee",
        tournament_id: props.tournamentId,
        variant_id: newFeeModal.selectedVariant.id,
        players: newFeeModal.playerCount,
        description: newFeeModal.description,
        price: newFeeModal.price,
      },
      true,
    );

    if (res.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.league.tournament_registration_fees_new.004")));
      return;
    }

    props.setUpdatedFees(props.fees, true);
    closeNewFeeModal();
  }

  async function handleAddNewAddOn() {
    if (newAddOnModal.selectedVariant?.id === undefined) {
      dispatch(showError("No add on fee is selected"));
      return;
    }

    const res = await PostTournamentRegistrationFee(
      {
        type: "add_on",
        tournament_id: props.tournamentId,
        variant_id: newAddOnModal.selectedVariant.id,
        players: newAddOnModal.playerCount,
        description: newAddOnModal.description,
        price: newAddOnModal.price,
      },
      true,
    );

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error adding the new registration fee."));
      return;
    }

    props.setUpdatedFees(props.fees, true);
    closeNewAddOnModal();
  }

  return (
    <>
      <Card
        title="Fees"
        titleActions={[{ content: "Add Fee", action: () => updateNewFeeModal({ isOpen: true }) }]}
        style={{ marginTop: "8px" }}
      >
        <Card.Section>
          <DataTable
            columns={[
              { label: "Title" },
              { label: "Description" },
              { label: "Price" },
              { label: "Players" },
              { label: "Delete" },
            ]}
            loading={props.fees === undefined}
          >
            {props.fees?.map(fee => (
              <tr key={fee.id}>
                <td>
                  <p>
                    {fee.product_title === fee.variant_title
                      ? fee.product_title
                      : fee.product_title + " - " + fee.variant_title}
                  </p>

                  <p className="text-sm text-subdued">{fee.product.subtitle}</p>
                </td>
                <td>{fee.description}</td>
                <td>{displayCurrency("cad", fee.price ? fee.price : fee.variant.price)}</td>
                <td>{fee.players}</td>
                <td>
                  <FontAwesomeIcon
                    onClick={() => updateRemoveFeeModal({ isOpen: true, feeId: fee.id })}
                    className="text-lg cursor-pointer py-2"
                    icon={["far", "trash"]}
                  />
                </td>
              </tr>
            ))}
          </DataTable>
        </Card.Section>
      </Card>

      <Card
        title="Add Ons"
        titleActions={[{ content: "Add Add On", action: () => updateNewAddOnModal({ isOpen: true }) }]}
        style={{ marginTop: "8px" }}
      >
        <Card.Section>
          <DataTable
            columns={[{ label: "Title" }, { label: "Description" }, { label: "Price" }, { label: "Delete" }]}
            loading={props.addons === undefined}
          >
            {props.addons?.map(addon => (
              <tr key={addon.id}>
                <td>
                  <p>
                    {addon.product_title === addon.variant_title
                      ? addon.product_title
                      : addon.product_title + " - " + addon.variant_title}
                  </p>

                  <p className="text-sm text-subdued">{addon.product.subtitle}</p>
                </td>
                <td>{addon.description}</td>
                <td>{displayCurrency("cad", addon.price ? addon.price : addon.variant.price)}</td>
                <td>
                  <FontAwesomeIcon
                    onClick={() => updateRemoveAddonModal({ isOpen: true, addonId: addon.id })}
                    className="text-lg cursor-pointer py-2"
                    icon={["far", "trash"]}
                  />
                </td>
              </tr>
            ))}
          </DataTable>
        </Card.Section>
      </Card>

      <RegistrationFeeInputs tournamentId={props.tournamentId} />

      <Portal isMounted={removeFeeModal.isOpen}>
        <Popup
          type="warning"
          open={removeFeeModal.isOpen}
          title={"Warning"}
          description={"You are about to remove this booking fee from the tournament."}
          closable
          onOk={() => deleteTournamentRegistrationFee(removeFeeModal.feeId)}
          onCancel={closeRemoveFeeModal}
        />
      </Portal>

      <Portal isMounted={removeAddonModal.isOpen}>
        <Popup
          type="warning"
          open={removeAddonModal.isOpen}
          title={"Warning"}
          description={"You are about to remove this addon from the tournament."}
          closable
          onOk={() => deleteTournamentRegistrationAddon(removeAddonModal.addonId)}
          onCancel={closeRemoveAddonModal}
        />
      </Portal>

      <Portal isMounted={newFeeModal.isOpen}>
        <Sheet
          size="medium"
          open={newFeeModal.isOpen}
          title="New Fee"
          okText={!newFeeModal.pageTwo ? "Next" : "Confirm"}
          onOk={!newFeeModal.pageTwo ? null : () => handleAddNewFee()}
          cancelText={!newFeeModal.pageTwo ? "Cancel" : "Back"}
          onCancel={
            !newFeeModal.pageTwo
              ? () => closeNewFeeModal()
              : () =>
                  updateNewFeeModal({
                    pageTwo: false,
                    selectedVariant: undefined,
                    playerCount: 1,
                    description: "",
                    price: null,
                  })
          }
          backDropCancel
          okDisabled={newFeeModal.selectedVariant === undefined}
        >
          {/* Initial Page.  Select chosen product */}
          {!newFeeModal.pageTwo ? (
            <>
              <div className="product-search-bar">
                <Search
                  searchCallback={searchValue => updateNewFeeModal({ search: searchValue })}
                  historyKey="registration-fees-fee"
                />
              </div>
              <DataTable
                columns={[
                  { label: "", width: "50%" },
                  { label: "", width: "30%" },
                  { label: "", width: "20%" },
                ]}
                loading={products === undefined}
                hideHeader
              >
                {products
                  ?.filter(
                    item =>
                      !item.variants.every(variant => props.fees?.some(fee => fee.variant_id === variant.id)),
                  )
                  ?.map(item => (
                    <React.Fragment key={item.id}>
                      <tr
                        onClick={
                          item.variant_count === 1
                            ? () => updateNewFeeModal({ selectedVariant: { ...item.variants[0] }, pageTwo: true })
                            : undefined
                        }
                        className={item.variant_count === 1 ? "clickable" : null}
                      >
                        <td>
                          <p>{item.title}</p>
                          {item.subtitle ? <p>{item.subtitle}</p> : null}
                        </td>
                        <td>{item?.vendor_title}</td>
                        <td>{item.type}</td>
                      </tr>
                      {item.variant_count > 1 &&
                        item.variants
                          .filter(variant => !props.fees?.some(fee => fee.variant_id === variant.id))
                          .map(variant => (
                            <tr
                              key={`product_variant_${variant.id}`}
                              onClick={() => updateNewFeeModal({ selectedVariant: { ...variant }, pageTwo: true })}
                              className="clickable"
                            >
                              <td style={{ textIndent: "3rem" }}>{variant.title}</td>
                              <td />
                              <td />
                            </tr>
                          ))}
                    </React.Fragment>
                  ))}
              </DataTable>
            </>
          ) : null}

          {/* Page 2.  Player count & description */}
          {newFeeModal.pageTwo ? (
            <Card>
              <Card.Section title={newFeeModal.selectedVariant?.title}>
                <FormLayout>
                  <FormLayout.Group>
                    <Select
                      label={"Number of Players"}
                      onChange={(playerCount: number) => updateNewFeeModal({ playerCount: playerCount })}
                      defaultValue={newFeeModal.playerCount}
                    >
                      {[1, 2, 3, 4].map((option, index) => {
                        return (
                          <Select.Option key={index} value={option}>
                            {option}
                          </Select.Option>
                        );
                      })}
                    </Select>
                    <Input
                      id="price"
                      label={"Price"}
                      value={newFeeModal.price}
                      onChange={e => updateNewFeeModal({ price: Number(e.target.value) })}
                      type="number"
                      prefix={"$"}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <Input
                      id="description"
                      label={"Description"}
                      value={newFeeModal.description}
                      onChange={e => updateNewFeeModal({ description: e.target.value })}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
            </Card>
          ) : null}
        </Sheet>
      </Portal>

      <Portal isMounted={newAddOnModal.isOpen}>
        <Sheet
          size="medium"
          open={newAddOnModal.isOpen}
          title="New Add On"
          okText={!newAddOnModal.pageTwo ? "Next" : "Confirm"}
          onOk={!newAddOnModal.pageTwo ? null : () => handleAddNewAddOn()}
          cancelText={!newAddOnModal.pageTwo ? "Cancel" : "Back"}
          onCancel={
            !newAddOnModal.pageTwo
              ? () => closeNewAddOnModal()
              : () =>
                  updateNewAddOnModal({
                    pageTwo: false,
                    selectedVariant: undefined,
                    playerCount: 1,
                    description: "",
                    price: null,
                  })
          }
          backDropCancel
          okDisabled={newAddOnModal.selectedVariant === undefined}
        >
          {/* Initial Page.  Select chosen product */}
          {!newAddOnModal.pageTwo ? (
            <>
              <div className="fee-product-search-container">
                <div className="product-search-bar">
                  <Search
                    searchCallback={searchValue => updateNewAddOnModal({ search: searchValue })}
                    historyKey="registration-fees-add-on"
                  />
                </div>
              </div>
              <div className="fee-products-container">
                <DataTable
                  columns={[
                    { label: "", width: "50%" },
                    { label: "", width: "30%" },
                    { label: "", width: "20%" },
                  ]}
                  loading={products === undefined}
                  hideHeader
                >
                  {products
                    ?.filter(
                      item =>
                        !item.variants.every(variant =>
                          props.addons?.some(addon => addon.variant_id === variant.id),
                        ),
                    )
                    ?.map(item => (
                      <React.Fragment key={item.id}>
                        <tr
                          onClick={
                            item.variant_count === 1
                              ? () => updateNewAddOnModal({ selectedVariant: { ...item.variants[0] }, pageTwo: true })
                              : undefined
                          }
                          className={classNames({
                            clickable: item.variant_count === 1,
                            "addon-selected-variant":
                              newAddOnModal.selectedVariant &&
                              newAddOnModal.selectedVariant?.id === item.variants[0]?.id,
                          })}
                        >
                          <td>
                            <p>{item.title}</p>
                            {item.subtitle ? <p>{item.subtitle}</p> : null}
                          </td>
                          <td>{item?.vendor_title}</td>
                          <td>{item.type}</td>
                        </tr>
                        {item.variant_count > 1 &&
                          item.variants
                            .filter(variant => !props.addons?.some(addon => addon.variant_id === variant.id))
                            .map(variant => (
                              <tr
                                key={`product_variant_${variant.id}`}
                                onClick={() => updateNewAddOnModal({ selectedVariant: { ...variant }, pageTwo: true })}
                                className={classNames("clickable", {
                                  "addon-selected-variant":
                                    newAddOnModal.selectedVariant && newAddOnModal.selectedVariant?.id === variant.id,
                                })}
                              >
                                <td style={{ textIndent: "3rem" }}>{variant.title}</td>
                                <td />
                                <td />
                              </tr>
                            ))}
                      </React.Fragment>
                    ))}
                </DataTable>
              </div>
            </>
          ) : null}

          {/* Page 2.  Player count & description */}
          {newAddOnModal.pageTwo ? (
            <Card>
              <Card.Section title={newAddOnModal.selectedVariant?.title}>
                <FormLayout>
                  <FormLayout.Group>
                    <Input
                      id="price"
                      label={"Price"}
                      value={newAddOnModal.price}
                      onChange={e => updateNewAddOnModal({ price: Number(e.target.value) })}
                      type="number"
                      prefix={"$"}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <Input
                      id="description"
                      label={"Description"}
                      value={newAddOnModal.description}
                      onChange={e => updateNewAddOnModal({ description: e.target.value })}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
            </Card>
          ) : null}
        </Sheet>
      </Portal>
    </>
  );
}
