import React, { memo } from "react";

export const CalendarWeek = memo(
  (props: { selectedDate: Date; renderWeekItem: (dayOfMonth: number) => JSX.Element }) => {
    console.log("rerender week");
    return <div className="ui-calendar-week"></div>;
  },
);

CalendarWeek.displayName = "CalendarWeek";
