import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { TFunction, useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { StatusCode } from "api/protocols";
import { GetParticipant } from "api/rpc/2024-04/facilityAdmin/league/participant";
import {
  GetAllLeagueDivisionParticipants,
  GetLeagueDivision,
  PostLeagueDivisionParticipant,
} from "api/rpc/2024-04/facilityAdmin/league/scoring/division";

import { showError, showSuccess } from "redux/actions/ui";
import { searchHistoryUpdateSearch } from "redux/actions/searchBars/searchHistory";
import { ILeagueParticipant, TLeagueDivision } from "redux/reducers/models/league";
import { useAppDispatch } from "hooks/redux";
import { ignoreOrderCompare } from "helpers/Helpers";

import Page from "components/page/Page";
import Card from "components/card/Card";
import { Select } from "components/select";
import FormLayout from "components/form/FormLayout";
import Spin from "components/spin/spin";
import Tabs from "components/tabs/Tabs";
import Search from "components/search/Search";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

import "./teams/leagueTeamManagePlayers.scss";

export default function LeagueDivisionsManagePlayers() {
  const { leagueId, divisionId } = useParams<{ leagueId: string; divisionId: string }>();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [leagueDivisions, setLeagueDivisions] = useState<TLeagueDivision[]>([]);
  const [leaguePlayers, setLeaguePlayers] = useState<ILeagueParticipant[]>([]);

  const [activeDivisionPlayerIds, setActiveDivisionPlayerIds] =
    useState<{ league_division_id: number; league_participant_id: Array<number> }[]>(undefined);

  const [state, setState] = useState({
    tabIndex: 0,
    divisionId: parseInt(divisionId),
    search: "",
  });

  const [selectedIds, setSelectedIds] = useState({
    active: [] as Array<number>,
    preserved: [] as Array<number>,
  });

  // Always return list of divisions with associated players given the leagueDivisions & leaguePlayers & selectedIds.active
  // Auto updates with temporarily selected ID's in order to move player on UI
  const playersInDivisions = useMemo(() => {
    if (leagueDivisions.length === 0) {
      return [];
    }

    const divisionIds = leagueDivisions.map(division => division.id);

    return divisionIds.map(id => {
      return {
        league_division_id: id,
        players: leaguePlayers.filter(player => selectedIds.active.includes(player.id)),
      };
    });
  }, [leagueDivisions, leaguePlayers, state.divisionId, selectedIds.active]);

  // Return players that are not currently selected or already in the selected division
  const playersWithoutDivision =
    state.divisionId === 0
      ? []
      : leaguePlayers.filter(
          player => !playersInDivisions.some(({ players }) => players.some(val => val === player)),
        );

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadLeagueDivisions(leagueId, source.token);
    void loadAllLeaguePlayers(leagueId, source.token);
    return () => source.cancel();
  }, [leagueId]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (activeDivisionPlayerIds !== undefined) {
      const activeDivisions = activeDivisionPlayerIds.filter(meta => meta.league_division_id === state.divisionId)[0];

      // Reset ID's when divisionId changes
      setSelectedIds(prev => ({
        ...prev,
        active: activeDivisions ? [...activeDivisions.league_participant_id] : [],
        preserved: activeDivisions ? [...activeDivisions.league_participant_id] : [],
      }));
      return;
    }

    // Load the current division participant ID's
    void loadDivisionParticipantIds(leagueId, source.token);
    return () => source.cancel();
  }, [leagueId, divisionId, state.divisionId]);

  async function loadLeagueDivisions(leagueId: string | number, token?: CancelToken) {
    const res = await GetLeagueDivision({ league_id: leagueId }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading league divisions.")); // TODO: Translation
    }

    setLeagueDivisions(res.status !== StatusCode.OK ? [] : res.data);
  }

  async function loadAllLeaguePlayers(leagueId: string | number, token?: CancelToken) {
    const res = await GetParticipant({ league_id: leagueId }, token ? false : true, token);
    if (token && token.reason) {
      return;
    }

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading all league players.")); // TODO: Translation
    }

    setLeaguePlayers(res.status !== StatusCode.OK ? [] : res.data);
  }

  /** Load player ID's associated with the selected division.  The data returned is used to filter out the leaguePlayers state */
  async function loadDivisionParticipantIds(leagueId: string | number, token?: CancelToken) {
    // Remove unsavedChangesBar
    if (selectedIds.active.length !== 0) {
      setSelectedIds(prev => ({ ...prev, active: [], preserved: [] }));
    }

    // page spinner
    if (activeDivisionPlayerIds !== undefined) {
      setActiveDivisionPlayerIds(undefined);
    }

    const res = await GetAllLeagueDivisionParticipants({ league_id: leagueId }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading league participants.")); // TODO: Translation
    }

    // Map through result and structure response
    const map = new Map(
      res.data.map(({ league_division_id, league_participant_id }) => [
        league_division_id,
        { league_division_id, league_participant_id: [] },
      ]),
    );
    for (const { league_division_id, league_participant_id } of res.data) {
      map.get(league_division_id).league_participant_id.push(...[league_participant_id]);
    }

    setActiveDivisionPlayerIds([...map.values()]);

    // preset ID's on page
    const startingDivisionIds = [...map.values()].filter(val => val.league_division_id === state.divisionId)[0];
    if (startingDivisionIds) {
      setSelectedIds(prev => ({
        ...prev,
        active: [...startingDivisionIds.league_participant_id],
        preserved: [...startingDivisionIds.league_participant_id],
      }));
    }
  }

  async function updatePlayersOnDivision(divisionId: string | number, playerIds: Array<number>) {
    const res = await PostLeagueDivisionParticipant({ division_id: divisionId, participant_ids: playerIds }, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error updating players on the division.")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Successfully updated players on the division")); // TODO: Translation
    void loadDivisionParticipantIds(leagueId);
  }

  function handlePlayerToggle(id: number) {
    setSelectedIds(prev => ({
      ...prev,
      active: prev.active.includes(id) ? prev.active.filter(value => value !== id) : [id, ...prev.active],
    }));
  }

  function handleAddAllPlayers() {
    const playerIds = playersWithoutDivision.map(player => {
      return player.id;
    });

    if (playerIds.length < 1) {
      dispatch(showError("No players to add"));
      return;
    }

    setSelectedIds(prev => ({
      ...prev,
      active: prev.active.concat(playerIds),
    }));
  }

  function onSelectChange(value: number) {
    setState(prev => ({ ...prev, divisionId: value, search: "" }));
    dispatch(searchHistoryUpdateSearch("league_divisions_manage_players", ""));
  }

  function onTabChange(index: number) {
    setState(prev => ({ ...prev, tabIndex: index, search: "" }));
    dispatch(searchHistoryUpdateSearch("league_divisions_manage_players", ""));
  }

  const primaryAction = {
    content: "Add All Players",
    action: () => handleAddAllPlayers(),
  };

  return (
    <Page
      title="Manage Division Players" // TODO: Translation
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: "Divisions", // TODO: Translation
          url: `/admin/league/${leagueId}/divisions`,
        },
      ]}
      notificationBarProps={{
        isVisible: !ignoreOrderCompare(selectedIds.active, selectedIds.preserved),
        onAction: () => updatePlayersOnDivision(state.divisionId, selectedIds.active),
        onCancel: () => setSelectedIds(prev => ({ ...prev, active: prev.preserved })),
      }}
    >
      <Card>
        <Card.Section>
          <Select
            label={"League Divisions"} // TODO: Translation
            onChange={(value: number) => onSelectChange(value)}
            defaultValue={state.divisionId}
            disabled={leagueDivisions.length === 0}
          >
            {leagueDivisions.map(division => (
              <Select.Option key={division.id} value={division.id}>
                {division.title}
              </Select.Option>
            ))}
          </Select>
        </Card.Section>
        <Card.Section>
          {activeDivisionPlayerIds !== undefined ? (
            <div className="flex justify-between gap-2">
              <div style={{ marginTop: "14px", minHeight: "500px" }} className="flex-1">
                <DataTable
                  columns={[
                    { label: "Current Players" /* TODO: Translation */, width: "95%" },
                    { label: "", width: "5%" },
                  ]}
                >
                  {playersInDivisions
                    .filter(meta => meta.league_division_id === state.divisionId)
                    .map(meta =>
                      meta.players.map(player => (
                        <tr key={player.id}>
                          <td>
                            <p>{player?.customer.full_name}</p>
                          </td>
                          <td className="clickable">
                            <FontAwesomeIcon
                              icon={["fas", "x"]}
                              onClick={() =>
                                setSelectedIds(prev => ({
                                  ...prev,
                                  active: prev.active.filter(id => id !== player.id),
                                }))
                              }
                            />
                          </td>
                        </tr>
                      )),
                    )}
                </DataTable>
              </div>
              <div className="player_manage-tab_section flex-1">
                <Tabs
                  tabs={manageDivisionTabs(t)}
                  selected={state.tabIndex}
                  onSelect={(value: number) => onTabChange(value)}
                >
                  <div className="league-manage_players-search">
                    <Search
                      historyKey={"league_divisions_manage_players"}
                      searchCallback={searchValue => setState(prev => ({ ...prev, search: searchValue }))}
                      placeholder="Search customer name..." // TODO: Translation
                      debounceTime={0}
                    />
                  </div>
                  {state.tabIndex === 0 && (
                    <DataTable columns={[]} hideHeader className="player_manage-tab_table">
                      {playersWithoutDivision
                        .filter(player =>
                          state.search.length !== 0
                            ? player.customer.full_name.toLowerCase().includes(state.search)
                            : true,
                        )
                        .map(player => (
                          <tr key={player.id} className="clickable" onClick={() => handlePlayerToggle(player.id)}>
                            <td>
                              <p>{player.customer?.full_name}</p>
                            </td>
                          </tr>
                        ))}
                    </DataTable>
                  )}
                </Tabs>
              </div>
            </div>
          ) : (
            <div style={{ height: "25px", overflow: "hidden" }}>
              <Spin />
            </div>
          )}
        </Card.Section>
      </Card>
    </Page>
  );
}

const manageDivisionTabs = (t: TFunction<"translation", undefined>) => [
  {
    id: "non_division_players",
    content: "All League Players", // TODO: Translation
    panelID: "player_manage-content_1",
  },
];
