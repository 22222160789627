import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { isEqualWith, isNull } from "lodash";
import { useTranslation } from "react-i18next";

import { formatDate } from "helpers/Helpers";
import { StatusCode } from "api/protocols";
import {
  GetMembership,
  GetMembershipAddOn,
  PutMembershipAddOn,
} from "api/rpc/2022-09/facilityAdmin/membership/membership";
import { GetCustomerType } from "api/rpc/2022-09/facilityAdmin/customer/type";

import { IMembershipState } from "./Membership";
import Page from "components/page/Page";
import Card from "components/card/Card";
import Input from "components/form/input/Input";
import FormLayout from "components/form/FormLayout";
import Checkbox from "components/form/checkbox/Checkbox";
import { Select, Select as SelectNew } from "components/select/";
import { NotificationType } from "components/notificationBar/NotificationBar";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import "pages/secure/facility/product/Product.scss";
import product from "containers/facility/product";

export default function Single(props: any) {
  const { Option } = SelectNew;
  const { t, i18n } = useTranslation();
  const { membershipAddOnId }: any = useParams();

  const [renderFlag, setRenderFlag] = useState({
    membershipLoaded: false,
    hasExpiry: false,
    prevMembershipAddOnState: undefined,
  });

  const [membershipAddOnState, setMembershipAddOnState] = useState<Partial<IMembershipState>>({
    id: null,
    title: "",
    subtitle: "",
    type: "",
    customer_type_id: null,
    expiry: "",
  });

  const [optionState, setOptionState] = useState({
    customerTypes: [],
  });

  const [filterState, setFilterState] = useState({
    customerTypeSearch: "",
  });

  const [dateState, setDateState] = useState({
    selectedDate: new Date(),
  });

  useEffect(() => {
    void loadCustomerTypes();
    void loadMembershipAddOn();
  }, [membershipAddOnId]);

  async function loadCustomerTypes() {
    const customerTypeRes = await GetCustomerType({ application: "power_cart" }, true);
    if (customerTypeRes.status !== StatusCode.OK) {
      return;
    }

    setOptionState(prev => ({
      ...prev,
      customerTypes: customerTypeRes.data,
    }));
  }
  async function loadMembershipAddOn() {
    const membershipAddOnRes = await GetMembership({ id: membershipAddOnId }, true);
    if (membershipAddOnRes.status !== StatusCode.OK) {
      return;
    }

    console.log(membershipAddOnRes); //returns all membershipAddOns

    membershipAddOnRes.data = membershipAddOnRes.data.filter((membership: any) => membership.id == membershipAddOnId);
    const membership = membershipAddOnRes.data[0];

    //check for expiry values
    if (membership.expiry !== null) {
      const notOffsetDate = new Date((membership.expiry as string) + "EST");

      setDateState({
        ...dateState,
        selectedDate: notOffsetDate,
      });
    }

    //set necessary states
    setMembershipAddOnState({
      id: membership.id,
      title: membership.title,
      subtitle: membership.subtitle || "",
      type: membership.type,
      customer_type_id: membership.customer_type_id,
      customer_type_title: membership.customer_type_title,
      expiry: membership.expiry,
    });

    setFilterState({ customerTypeSearch: membership.customer_type_title || "" });

    setRenderFlag({ ...renderFlag, membershipLoaded: true, hasExpiry: membership.expiry ? true : false });
  }

  async function saveMembershipAddOn() {
    const membershipAddOnRes = await PutMembershipAddOn(membershipAddOnState, true);
    if (membershipAddOnRes.status !== StatusCode.OK) {
      return;
    }

    //reset the editing state of the page
    setRenderFlag({ ...renderFlag, prevMembershipAddOnState: membershipAddOnState });
    setFilterState({ ...filterState, customerTypeSearch: membershipAddOnRes.data.customer_type_title });
    return;
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setMembershipAddOnState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleDropDownChange(value: any, property: string) {
    setMembershipAddOnState(prev => ({ ...prev, [property]: value }));
  }

  function handleFilterSearch(query: string, property: string) {
    setFilterState(prev => ({ ...prev, [property]: query }));
  }

  function handleCheckboxChange(event: any) {
    const { id, checked } = event.target;

    // separate expiry logic to handle date
    if (id === "hasExpiry") {
      setRenderFlag({ ...renderFlag, hasExpiry: checked });

      if (checked && renderFlag.prevMembershipAddOnState.expiry === null) {
        setDateState({ ...dateState, selectedDate: new Date() });
        setMembershipAddOnState({ ...membershipAddOnState, expiry: formatDate(new Date()) });
        return;
      }

      if (checked && renderFlag.prevMembershipAddOnState.expiry !== null) {
        const notOffsetDate = new Date((renderFlag.prevMembershipAddOnState.expiry as string) + "EST");

        setDateState({ ...dateState, selectedDate: notOffsetDate });
        setMembershipAddOnState({ ...membershipAddOnState, expiry: renderFlag.prevMembershipAddOnState.expiry });
        return;
      }

      if (!checked) {
        setMembershipAddOnState({ ...membershipAddOnState, expiry: null });
        setDateState({ ...dateState, selectedDate: new Date() }); //"forgets" any date changes
        return;
      }
    }

    setMembershipAddOnState(prevState => ({ ...prevState, [id]: checked }));
  }

  function handleDateSelector(selectedDate: Date) {
    setMembershipAddOnState({ ...membershipAddOnState, expiry: formatDate(selectedDate) });
    setDateState({ ...dateState, selectedDate: selectedDate });
  }

  function unsavedChangesExist() {
    const { prevMembershipAddOnState, membershipLoaded } = renderFlag;
    if (prevMembershipAddOnState === undefined) {
      if (membershipLoaded) {
        setRenderFlag({ ...renderFlag, prevMembershipAddOnState: membershipAddOnState });
      }
      return false;
    }

    return !isEqualWith(prevMembershipAddOnState, membershipAddOnState, (originalValue, newValue) => {
      if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
        return true;
      }
    });
  }

  function cancelUnsavedChanges() {
    setMembershipAddOnState(renderFlag.prevMembershipAddOnState);

    //only necessary with expiry possibly NULL
    const prevExpiryVal: string = renderFlag.prevMembershipAddOnState.expiry;
    if (prevExpiryVal === null) {
      setRenderFlag({ ...renderFlag, hasExpiry: false });
    } else {
      //"reset" expiry on page
      setRenderFlag({ ...renderFlag, hasExpiry: true });

      const notOffsetDate = new Date(prevExpiryVal + "EST");
      setDateState({ ...dateState, selectedDate: notOffsetDate });
      setMembershipAddOnState({ ...membershipAddOnState, expiry: prevExpiryVal });
    }
  }

  return (
    <Page
      title={membershipAddOnState.title}
      breadcrumbs={[{ label: "Back To Memberships", url: "/admin/membership" }]} //translation required
      narrow
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: saveMembershipAddOn,
        onCancel: cancelUnsavedChanges,
      }}
    >
      <Card title={t("secure.facility.product.membership_add_on.004")}>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Input
                value={membershipAddOnState.title}
                label={t("secure.facility.product.membership_add_on.005")}
                id="title"
                onChange={handleInputChange}
                placeholder={t("secure.facility.product.membership_add_on.006")}
              />
              <Input
                value={membershipAddOnState.subtitle}
                label={"Subtitle"} //translation required
                id="title"
                onChange={handleInputChange}
                placeholder={"Subtitle"} //translation required
              />
            </FormLayout.Group>

            <FormLayout.Group>
              <Select
                label={t("secure.facility.product.membership_add_on.008")}
                placeholder={t("secure.facility.product.membership_add_on.009")}
                showSearch
                className={`flex justify-center align-center w-full h-10 position-relative z-20 text-black font-medium appearance-none border-none focus:outline-none placeholder-gray-200`}
                onSearch={(query: string) => handleFilterSearch(query, "customerTypeSearch")}
                onChange={(value: any) => handleDropDownChange(value, "customer_type_id")}
                allowClear
                searchValue={filterState.customerTypeSearch}
                showDropDownOnFocus={true}
                hideDropdown={true}
              >
                {optionState.customerTypes
                  .filter(custType =>
                    String(custType.title).toLowerCase().includes(filterState.customerTypeSearch.toLowerCase()),
                  )
                  .map((type: any, i: number) => {
                    return (
                      <div key={i}>
                        <div>
                          <Option key={i} value={type.id} name={type.title}>
                            {type?.title}
                          </Option>
                        </div>
                      </div>
                    );
                  })}
              </Select>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  height: "100%",
                  minHeight: "70px",
                }}
              >
                <Checkbox
                  id="hasExpiry"
                  size="medium"
                  value={renderFlag.hasExpiry}
                  checked={renderFlag.hasExpiry}
                  onChange={handleCheckboxChange}
                  label="Expires" //translation required
                />
              </div>

              {renderFlag.hasExpiry && (
                <div id="membership-date-selector" style={{ marginLeft: "15px" }}>
                  <span>Expires On</span> {/* translation required */}
                  <DatePickerInput
                    months={1}
                    position={"center"}
                    startingDate={dateState.selectedDate}
                    setStartingDate={handleDateSelector}
                  />
                </div>
              )}
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>
    </Page>
  );
}
