import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { displayCurrency, roundNumber, displayPercent, formatDate } from "helpers/Helpers";

import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import { ButtonNew as Button } from "components/buttonNew";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import TextField from "components/form/textField/TextField";
import { Select } from "components/select/index";
import Checkbox from "components/form/checkbox/Checkbox";
import { weekdays } from "moment";
import { IFacilityState } from "redux/reducers/facility";
import { GetFacility } from "api/rpc/clientAdmin/facility/facility";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import TimePick from "components/timePick/TimePick";
import { ILeaguePost, PostLeague } from "api/rpc/2024-04/clientAdmin/league/league";
import { showError, showSuccess } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";
import { TWeekDays } from "components/form/calendarField/helpers";

interface ILeagueState {
  name: string;
  week_day: TWeekDays;
  facility_id: number;
  start_time: string;
}

interface IFilterState {
  facility_list: any;
}

interface IProps {
  clientFacilityStore: IFacilityState;
}

export default function LeagueNew(props: any) {
  const { clientFacilityStore } = props;

  const { Option } = Select;
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [leagueState, setLeagueState] = useState<ILeagueState>({
    name: "",
    week_day: "Monday",
    facility_id: 1,
    start_time: "12:00",
  });

  const [leagueTimes, setLeagueTimes] = useState({
    start_time: "12:00",
    start_date: new Date(),
    end_date: new Date(),
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    facility_list: [],
  });

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setLeagueState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleDropDownChange(value: any) {
    setLeagueState(prev => ({ ...prev, week_day: value }));
  }

  function handleFacilityDropDownChange(value: any) {
    setLeagueState(prev => ({ ...prev, facility_id: value }));
  }

  function handleCreateLeague() {
    void createLeague(leagueState);
  }

  const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  const weekDays = daysOfWeek.map((day: any, index: number) => {
    return {
      label: day,
      value: day,
      prefix: "",
    };
  });

  useEffect(() => {
    void loadFacilities();
  }, []);

  async function createLeague(params: ILeaguePost) {
    const leagueParams = {
      ...params,
      start_date: formatDate(leagueTimes.start_date),
      end_date: formatDate(leagueTimes.end_date),
    };

    const leagueRes = await PostLeague(leagueParams, true);
    if (leagueRes.status !== StatusCode.OK) {
      dispatch(showError(typeof leagueRes.data === "string" ? leagueRes.data : "Error creating league."));
      return;
    }

    dispatch(showSuccess("A league was successfully created."));
    history.push("/admin/events");
  }

  const primaryAction = {
    content: "Create",
    action: handleCreateLeague,
  };

  async function loadFacilities() {
    const facilityRes = await GetFacility(null, true);

    if (facilityRes.status !== StatusCode.OK) {
      return;
    }

    setFilterState(prev => ({ ...prev, facility_list: facilityRes.data }));
  }

  function setDate(date: Date, key: string) {
    const formattedDate = formatDate(date);
    setLeagueState(prevState => ({ ...prevState, [key]: formattedDate }));
    setLeagueTimes(prevState => ({ ...prevState, [key]: date }));
  }

  return (
    <Page
      title="New League"
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[{ label: "Leagues", url: "/admin/league" }]}
    >
      <Form>
        <Card title="League Details">
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={leagueState.name}
                  label="Name"
                  id="name"
                  onChange={handleInputChange}
                  placeholder="Name"
                />

                <TimePick
                  label="Start Time"
                  value={leagueTimes.start_time}
                  onChange={timeString => setLeagueState(prev => ({ ...prev, start_time: timeString }))}
                  size="large"
                  align="right"
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Select label="Week Day" onChange={handleDropDownChange} defaultValue={"Monday"}>
                  {weekDays.map((weekday: any, index: number) => {
                    return (
                      <Option key={index} value={weekday.value} name={weekday.label}>
                        <span>{weekday.label}</span>
                      </Option>
                    );
                  })}
                </Select>

                <Select label="Facility" onChange={handleFacilityDropDownChange}>
                  {filterState.facility_list.map((facility: any, index: number) => {
                    return (
                      <Option key={index} value={facility.id} name={facility.long_name}>
                        <span>{facility.long_name}</span>
                      </Option>
                    );
                  })}
                </Select>
              </FormLayout.Group>

              <FormLayout.Group>
                <DatePickerInput
                  label="Start Date"
                  startingDate={leagueTimes.start_date}
                  setStartingDate={(date: Date) => setDate(date, "start_date")}
                  months={1}
                  position="left"
                />

                <DatePickerInput
                  label="End Date"
                  startingDate={leagueTimes.end_date}
                  setStartingDate={(date: Date) => setDate(date, "end_date")}
                  months={1}
                  position="left"
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>
      </Form>
    </Page>
  );
}
