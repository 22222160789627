import { APIGet, APIPost, APIPut, APIDelete, StatusCode } from "api/protocols";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseCartUrl = "/" + apiVersion + "/" + adminType + "/cart";
const baseCartLineItemUrl = "/" + apiVersion + "/" + adminType + "/cart/line-item";
export interface IPostLineItemToCart {
  cart_id: number;
  parent_id?: number;
  note?: string;
  kitchen_meal_id?: number;

  // Add single fee at a time
  variant_id?: number;
  quantity?: number;

  // Add multiple fees at a time.
  greenFeeCounts?: MultipleFeeCounts[];
  powerCartCounts?: MultipleFeeCounts[];
}
type MultipleFeeCounts = {
  variant_id: string;
  quantity: number;
};

export interface IPutLineItemToCart {
  id: number;
  quantity?: number;
  price?: number;
  note?: string;
  kitchen_meal_id?: number;
}

export interface IPutPrice {
  id: number;
  price: number;
}

export interface IDeleteLineItemToCart {
  id: number;
}

export interface IGetCart {
  id?: number;
  token?: string;
  tableCart?: Record<string, any>;
}

interface ISplitLineItem {
  line_item_ids: Array<number>;
  cart_ids: Array<number>;
}

export interface IPostCart {
  type?: string;
  register_id?: number;
  table_id?: number;
  customer_id?: number;
}

// GET Cart
// Retrieve a list of carts
export async function GetCart(params: IGetCart, useGlobalLoader: boolean) {
  return await APIGet(baseCartUrl, params, useGlobalLoader);
}

// POST Cart
// Create a new, empty cart
export async function PostCart(params: IPostCart, useGlobalLoader: boolean) {
  return await APIPost(baseCartUrl, params, useGlobalLoader);
}

// //PUT Cart
export async function PutCart(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseCartUrl, params, useGlobalLoader);
}

// VOID Cart
export async function PutVoid(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseCartUrl + "/void", params, useGlobalLoader);
}

export async function PutPrice(params: IPutPrice, useGlobalLoader: boolean) {
  return await APIPut(baseCartLineItemUrl + "/price", params, useGlobalLoader);
}

export interface IPutClearCart {
  cart_id: number;
}

export async function PutClearCart(params: IPutClearCart, useGlobalLoader: boolean) {
  return await APIPut(baseCartUrl + "/clear", params, useGlobalLoader);
}

export async function PostLineItemToCart(params: IPostLineItemToCart, useGlobalLoader: boolean) {
  return await APIPost(baseCartLineItemUrl, params, useGlobalLoader);
}
export async function PostLineItemToCartTemp(params: IPostLineItemToCart, useGlobalLoader: boolean) {
  return await APIPost(baseCartLineItemUrl + "/bulk", params, useGlobalLoader);
}

export async function PutLineItemToCart(params: IPutLineItemToCart, useGlobalLoader: boolean) {
  return await APIPut(baseCartLineItemUrl, params, useGlobalLoader);
}

export async function DeleteLineItemToCart(params: IDeleteLineItemToCart, useGlobalLoader: boolean) {
  return await APIDelete(baseCartLineItemUrl, params, useGlobalLoader);
}

export async function PutLineItemSplit(params: ISplitLineItem, useGlobalLoader: boolean) {
  return await APIPut(baseCartUrl + "/line-item/split", params, useGlobalLoader);
}

export interface IMoveLineItem {
  id: number;
  cart_id: number;
  previous_cart_id: number;
  quantity: number;
  child_line_item: boolean;
}

export interface IPutMoveLineItems {
  line_items: IMoveLineItem[];
}

export async function PutMoveLineItems(params: IPutMoveLineItems, useGlobalLoader: boolean) {
  return await APIPut(baseCartUrl + "/line-item/move", params, useGlobalLoader);
}

// POST Merge Cart
// Merge 2 or more carts
interface IPostMerge {
  cart_ids: number[];
  table_id?: number;
}

export async function PostMerge(params: IPostMerge, useGlobalLoader: boolean) {
  return await APIPost(baseCartUrl + "/merge", params, useGlobalLoader);
}

// POST Cart Checkout
// Migrate a cart to an order to process payments
interface IPostCheckout {
  cart_id: number;
  register_id: number;
}

export async function PostCheckout(params: IPostCheckout, useGlobalLoader: boolean) {
  return await APIPost(baseCartUrl + "/checkout", params, useGlobalLoader);
}
