import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import "./facility.scss";

import { PostFacilityAdmin } from "api/rpc/facilityAdmin/client/admin";
import { IFacilityActions } from "redux/actions/facility";
import { IUIActions, showError, showSuccess } from "redux/actions/ui";
import { IFacilityState } from "redux/reducers/facility";
import { IUIState } from "redux/reducers/ui";
import Page from "components/page/Page";
import Card from "components/card/Card";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import Select from "components/form/select/Select";
import { StatusCode } from "api/protocols";

import { useTranslation } from "react-i18next";
import { useAppDispatch } from "hooks/redux";
import validator from "validator";

interface IProps {
  facilityActions: IFacilityActions;
  facilityStore: IFacilityState;
  UIActions: IUIActions;
  UIStore: IUIState;
}

interface IStaffAccountInputField {
  data: string;
  isDirty: boolean;
  isValid: boolean;
}

interface IAdminState {
  first_name: IStaffAccountInputField;
  last_name: IStaffAccountInputField;
  email: IStaffAccountInputField;
  password: IStaffAccountInputField;
  username: IStaffAccountInputField;
}

export default function FacilityStaffNew(props: IProps) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [adminState, setAdminState] = useState<IAdminState>({
    first_name: { data: "", isDirty: false, isValid: false },
    last_name: { data: "", isDirty: false, isValid: false },
    email: { data: "", isDirty: false, isValid: false },
    password: { data: "", isDirty: false, isValid: false },
    username: { data: "", isDirty: false, isValid: false },
  });

  const dispatch = useAppDispatch();

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>, isValid: boolean) {
    const { id, value } = event.target;
    setAdminState(prevState => ({
      ...prevState,
      [id]: { ...prevState[id as keyof IAdminState], data: value, isValid, isDirty: true },
    }));
  }

  async function saveNewStaffAdmin() {
    if (
      adminState.first_name?.isValid &&
      adminState?.last_name?.isValid &&
      adminState?.password?.isValid &&
      adminState?.email?.isValid
    ) {
      const params = {
        first_name: adminState?.first_name?.data,
        last_name: adminState?.last_name?.data,
        email: adminState?.email?.data,
        password: adminState?.password?.data,
        username: adminState?.username?.data,
      };

      const res = await PostFacilityAdmin(params, true);
      if (res.status !== StatusCode.OK) {
        dispatch(showError(t("secure.facility.settings.facility.facility_staff_new.014")));
        return;
      }
      dispatch(showSuccess(t("secure.facility.settings.facility.facility_staff_new.015")));
      history.push("/admin/settings/staff");
    } else {
      setAdminState(prevState => ({
        ...prevState,
        first_name: { ...prevState?.first_name, isDirty: true },
        last_name: { ...prevState?.last_name, isDirty: true },
        password: { ...prevState?.password, isDirty: true },
        email: { ...prevState?.email, isDirty: true },
      }));
    }
  }

  const primaryAction = {
    content: t("secure.facility.settings.facility.facility_staff_new.001"),
    action: saveNewStaffAdmin,
  };

  return (
    <Page
      title={t("secure.facility.settings.facility.facility_staff_new.002")}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.facility.facility_staff_new.003"),
          url: "/admin/settings/staff",
        },
      ]}
    >
      <Form>
        <Card>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={adminState.first_name?.data}
                  label={t("secure.facility.settings.facility.facility_staff_new.004")}
                  id="first_name"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(e, e.target.value !== "" && e.target.value != null)
                  }
                  placeholder={t("secure.facility.settings.facility.facility_staff_new.005")}
                  error={adminState?.first_name?.isDirty && !adminState?.first_name?.isValid}
                />
                <Input
                  value={adminState.last_name?.data}
                  label={t("secure.facility.settings.facility.facility_staff_new.006")}
                  id="last_name"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(e, e.target.value !== "" && e.target.value != null)
                  }
                  placeholder={t("secure.facility.settings.facility.facility_staff_new.007")}
                  error={adminState?.last_name?.isDirty && !adminState?.last_name?.isValid}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Input
                  value={adminState.email?.data}
                  label={t("secure.facility.settings.facility.facility_staff_new.008")}
                  id="email"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(
                      e,
                      e.target.value !== "" && e.target.value != null && validator.isEmail(e.target.value),
                    )
                  }
                  placeholder={t("secure.facility.settings.facility.facility_staff_new.009")}
                  error={adminState?.email?.isDirty && !adminState?.email?.isValid}
                />
                <Input
                  value={adminState.password?.data}
                  label={t("secure.facility.settings.facility.facility_staff_new.010")}
                  type="password"
                  id="password"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(e, e.target.value !== "" && e.target.value != null && e.target.value.length >= 5)
                  }
                  placeholder={t("secure.facility.settings.facility.facility_staff_new.011")}
                  error={adminState?.password?.isDirty && !adminState?.password?.isValid}
                  helpText={t("secure.facility.settings.facility.facility_staff_new.016")}
                />
              </FormLayout.Group>

              <FormLayout.Group>
                <Input
                  value={adminState.username?.data}
                  label={t("secure.facility.settings.facility.facility_staff_new.012")}
                  id="username"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(e, e.target.value !== "" && e.target.value != null && e.target.value.length >= 5)
                  }
                  placeholder={t("secure.facility.settings.facility.facility_staff_new.013")}
                  error={adminState?.username?.isDirty && !adminState?.username?.isValid}
                  helpText={t("secure.facility.settings.facility.facility_staff_new.017")}
                />

                <div></div>
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>
      </Form>
    </Page>
  );
}
