import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.scss";
import { forceVisible } from "react-lazyload";

interface IProps {
  prefixCls?: string;
  className?: string;
  icon?: React.ReactNode;
  closeIcon?: React.ReactNode;
  closable?: boolean;
  onClose?: (e: React.MouseEvent<HTMLElement>) => void;
  visible?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

export const TagNew: React.FC<IProps> = props => {
  const { prefixCls = "rc-tag", className, icon, children, closable, closeIcon, onClose, ...rest } = props;

  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if ("visible" in props) {
      setVisible(props.visible);
    }
  }, [props.visible]);

  const tagClassName = classNames(
    prefixCls,
    {
      [`${prefixCls}-with-icon`]: icon,
      [`${prefixCls}-hidden`]: !visible,
      [`${prefixCls}-clickable`]: props.onClick,
      [`${prefixCls}-disabled`]: props.disabled,
    },
    className,
  );

  const iconNode = icon || null;

  const handleCloseClick = (e: React.MouseEvent<HTMLElement>) => {
    onClose?.(e);

    // if (!('visible' in props)) {
    //   setVisible(false);
    // }
  };

  const renderCloseIcon = () => {
    if (closable) {
      return closeIcon ? (
        <span className={`${prefixCls}-close-icon`} onClick={handleCloseClick}>
          {closeIcon}
        </span>
      ) : (
        <span className={`${prefixCls}-close-icon`} onClick={handleCloseClick}>
          <FontAwesomeIcon icon={["far", "times"]} />
        </span>
      );
    }
    return null;
  };

  const renderTag = () => {
    return (
      <div className={tagClassName} {...rest}>
        {iconNode}
        <span>{children}</span>
        {renderCloseIcon()}
      </div>
    );
  };

  return renderTag();
};

export default TagNew;
