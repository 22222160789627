import NavigationBar from "containers/OnlineStore/NavigationBar";
import React, { useState } from "react";
import AccountBar from "./AccountBar";
import AnnouncementBar from "./AnnouncementBar";
import "./StoreHeader.scss";

interface IStoreHeaderProps {
  announcementMessage?: JSX.Element;
  facilityShortName: string;
  facilityLogoSource: string;
  shoppingBagHasItems: boolean;
}

export default function StoreHeader(props: IStoreHeaderProps) {
  const { announcementMessage, facilityShortName } = props;

  const [announcementBarIsVisible, setAnnouncementBarIsVisible] = useState<boolean>(true);

  return (
    <div className="online-store-header">
      {/*
      {announcementMessage && (
        <AnnouncementBar
          isVisible={announcementBarIsVisible}
          message={announcementMessage}
          onClose={() => setAnnouncementBarIsVisible(false)}
        />
      )}
      <AccountBar />
      */}
      <NavigationBar
        facilityShortName={facilityShortName}
        facilityLogoSource={props.facilityLogoSource}
        shoppingBagHasItems={props.shoppingBagHasItems}
      />
    </div>
  );
}
