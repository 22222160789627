import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { searchHistoryUpdateSearch } from "redux/actions/searchBars/searchHistory";
import { IProduct, IVariant } from "redux/reducers/models/product";
import { LocaleCurrency } from "helpers/Locale";
import { useAppDispatch } from "hooks/redux";

import Sheet from "components/sheet/Sheet";
import Spin from "components/spin/spin";
import Search from "components/search/Search";
import { ButtonNew } from "components/buttonNew";
import ButtonGroup from "components/button/ButtonGroup";
import Portal from "elements/Portal";

export type TProductModal = {
  /** should be obtained with a useModal union */
  isOpen?: boolean;
  /** Trigger loading spinner */
  isLoading?: boolean;
  search: string;
  products: IProduct[];
  selectedProduct: IProduct;
  selectedVariant: IVariant;
};

type TBookingFeeProductsModal = {
  state: TProductModal;
  updateState: (newState: Partial<TProductModal>) => void;
  onOk: (selectedProduct: IProduct, selectedVariant: IVariant) => void;
  defaultProduct?: IProduct;
  defaultVariant?: IVariant;
  okDisabled?: boolean;
  navigateToNewProduct?: (e: React.MouseEvent<HTMLElement>) => void;
};

export default function BookingFeeProductsModal(props: TBookingFeeProductsModal) {
  const { t } = useTranslation();
  const { state, updateState } = props;

  const dispatch = useAppDispatch();

  // set default values on open
  useEffect(() => {
    if (state.isOpen) {
      updateState({ selectedProduct: props.defaultProduct, selectedVariant: props?.defaultVariant });
    }
  }, [state.isOpen]);

  function handleOk() {
    if (props.defaultVariant && props.defaultVariant.id == state.selectedVariant.id) {
      updateState({ isOpen: false, search: "" });
      return;
    }

    props.onOk(state.selectedProduct, state.selectedVariant);
    updateState({ isOpen: false, selectedProduct: null, selectedVariant: null, search: "" });
  }

  /** If variantId found -- remove. */
  function selectVariant(product: IProduct, variant: IVariant) {
    if (state.selectedVariant?.id === variant.id) {
      updateState({ selectedProduct: null, selectedVariant: null });
    } else {
      updateState({ selectedProduct: product, selectedVariant: variant });
    }
  }

  return (
    <Portal isMounted={state.isOpen}>
      <Sheet
        open={state.isOpen}
        size="small"
        onCancel={() => updateState({ isOpen: false, selectedProduct: null, selectedVariant: null, search: "" })}
        onOk={() => handleOk()}
        okDisabled={props.okDisabled ? props.okDisabled : !state.selectedVariant}
        stacked
      >
        <Search
          historyKey={"teesheet_booking_fee__product_search"}
          searchCallback={searchValue =>
            updateState({ search: state.search !== searchValue ? searchValue : state.search })
          }
          placeholder={t("secure.facility.settings.tee_sheets.booking_engine.057")}
        />
        <div className="booking-engine-product-header">
          <div className="booking-engine-product-title">
            {t("secure.facility.settings.tee_sheets.booking_engine.058")}
          </div>
          <div className="booking-engine-price-title">
            {t("secure.facility.settings.tee_sheets.booking_engine.059")}
          </div>
        </div>
        <div className="booking-engine-products-container">
          {state.isLoading ? (
            // LOADING state
            <div className="booking-engine-product-list-item">
              <div style={{ height: "24px" }}>
                <Spin />
              </div>
            </div>
          ) : state.products?.length === 0 ? (
            // NO-PRODUCT state
            <div className="booking-engine-product-list-empty">
              <span className="inquiry-icon">
                <FontAwesomeIcon icon="magnifying-glass" />
              </span>
              <p className="text-md text-semibold">No Products Found</p>
              <span>
                <p className="text-sm text-regular">{`Your search '${state.search}' did not match a product already created in the system.`}</p>
                <p className="text-sm text-regular">
                  Please try again with a different search, or create a new product.
                </p>
              </span>
              <ButtonGroup>
                <ButtonNew
                  size="medium"
                  onClick={() => dispatch(searchHistoryUpdateSearch("teesheet_booking_fee__product_search", ""))}
                  disabled={state.search?.length === 0}
                >
                  Clear Search
                </ButtonNew>
                <ButtonNew
                  size="medium"
                  type="primary"
                  onClick={e =>
                    props.navigateToNewProduct
                      ? props.navigateToNewProduct(e)
                      : console.warn("navigateToNewProduct param is needed to access this action.")
                  }
                >
                  <FontAwesomeIcon icon="plus" />
                  &nbsp; New Product
                </ButtonNew>
              </ButtonGroup>
            </div>
          ) : (
            state.products?.map(product => (
              // DISPLAY state
              <div
                key={product.id}
                onClick={product.variant_count !== 1 ? undefined : () => selectVariant(product, product.variants[0])}
                className={classNames("booking-engine-product-list-item", {
                  "booking-engine-product-list-item-hover": product.variant_count === 1,
                  "booking-engine-product-list-item-selected":
                    product.variant_count === 1 && product?.variants[0]?.id === state.selectedVariant?.id
                      ? true
                      : false,
                })}
              >
                <div>
                  <p className="font-normal">{product?.preferred_title ? product?.preferred_title : product?.title}</p>
                </div>

                {product?.variant_count === 1 && <LocaleCurrency amount={product?.variants[0]?.price} />}
                {product?.variant_count > 1 && (
                  <>
                    <div className="booking-engine-dashed-border"></div>
                    {product.variants?.map(variant => {
                      return (
                        <div
                          key={variant.id}
                          onClick={() => selectVariant(product, variant)}
                          className={classNames(
                            "booking-engine-variant-list-item booking-engine-product-list-item-hover",
                            {
                              "booking-engine-product-list-item-selected": state.selectedVariant?.id === variant?.id,
                            },
                          )}
                        >
                          <div className="font-normal">{variant?.title}</div>
                          <LocaleCurrency amount={variant?.price} />
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            ))
          )}
        </div>
      </Sheet>
    </Portal>
  );
}
