import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { ITeeDeck } from "redux/reducers/models/facility";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/facility/tee-deck";

export type TGetTeeDeck = {
  id?: string | number;
  course_id?: string | number;
};

// export type TPostTeeDeck = {};

// export type TPutTeeDeck = {};

// GET TeeDeck
export async function GetTeeDecks(params: TGetTeeDeck, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<ITeeDeck[]>(baseUrl, params, useGlobalLoader, token);
}

// POST TeeDeck
export async function PostTeeDeck(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

// PUT TeeDeck
export async function PutTeeDeck(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}
