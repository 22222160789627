import React, { ChangeEvent, useEffect, useMemo } from "react";
import { TFunction, useTranslation } from "react-i18next";

import { FilterValue, addFilter, applyDefaults, removeFilter } from "redux/actions/filters/filters";
import { ComponentKey, OrderFilterGrouping } from "redux/reducers/filters/types";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useFilterAccordionContext } from "components/accordion/Accordion";
import Checkbox from "components/form/checkbox/Checkbox";

import "./filterList.scss";
import { IRegister } from "pages/secure/facility/Admin";

type FilterList = {
  registers: IRegister[];

  /** Value provided by FilterAccordionContext takes precedent if present. */
  collection?: ComponentKey;
  defaultValues?: any[];
  condensed?: boolean;
};

export default function RegistersFilterList(props: FilterList) {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(store => store.orderPageFilters);
  const { collectionContext } = useFilterAccordionContext();

  const labelArray = useMemo(() => {
    if (props.registers) {
      const active = props.registers.filter(val => filters.some(condition => condition.value.includes(val.id)));
      return active.map(filter => filter.title);
    }
    return [];
  }, [filters, props.registers]);

  useEffect(() => {
    if (!props.registers || props.registers === undefined || !props.defaultValues) {
      return;
    }

    if (!filters.find(group => group.label === "register_ids")) {
      const defaultActive = props.registers.filter(register =>
        props.defaultValues.some(valId => valId === register.id),
      );

      dispatch(
        applyDefaults<OrderFilterGrouping>({
          collectionName: collectionContext ? collectionContext : props.collection,
          key: "register_ids",
          value: defaultActive.map(register => register.id) as FilterValue[],
          displayLabels: defaultActive.map(register => register.title),
        }),
      );
    }
  }, [props.defaultValues, props.registers]);

  function listItemClick(prevChecked: boolean, id: number) {
    const newFilter = props.registers.filter(val => val.id === id)[0];
    const newLabel = props.registers
      .filter(val => labelArray.includes(val.title) || val.title === newFilter.title)
      .map(ele => ele.title);

    if (!prevChecked) {
      dispatch(
        addFilter<OrderFilterGrouping>({
          collectionName: collectionContext ? collectionContext : props.collection,
          key: "register_ids",
          value: id,
          displayLabels: newLabel,
        }),
      );
      return;
    }

    // remove filter condition if unchecked
    dispatch(
      removeFilter<OrderFilterGrouping>({
        collectionName: collectionContext ? collectionContext : props.collection,
        key: "register_ids",
        value: id,
        displayLabels: labelArray.filter(val => val !== newFilter.title),
      }),
    );
  }

  if (props.registers === undefined) {
    return null;
  } else {
    return (
      <ul className={`filter-list${props.condensed ? " condensed" : ""}`}>
        {props.registers.map((register, index) => {
          const checked = filters.some(condition => {
            return condition.value.includes(register.id);
          });

          return (
            <li key={index} onClick={() => listItemClick(checked, register.id)}>
              <Checkbox
                checked={checked}
                ariaChecked={checked}
                onChange={(e: ChangeEvent<HTMLInputElement>) => e.stopPropagation()}
                label={register.title}
                size="small"
              />
            </li>
          );
        })}
      </ul>
    );
  }
}
