import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";

export interface IRouteContext {
  navigatedTo: string;
  navigatedFrom: string;
}

const defaultState: IRouteContext = {
  navigatedTo: "",
  navigatedFrom: "",
};

const RouteContext = createContext<IRouteContext>(defaultState);
export const useRouteContext = () => useContext(RouteContext);

export const RouteProvider = (props: { children: any }) => {
  const location = useLocation();
  const [routeState, setRouteState] = useState<IRouteContext>({
    navigatedTo: "",
    navigatedFrom: null,
  });

  useEffect(() => {
    const updatedRouteState: IRouteContext = {
      ...routeState,
      navigatedTo: location.pathname,
      navigatedFrom: routeState.navigatedTo,
    };

    //do not update if location is the same
    if (updatedRouteState.navigatedTo === updatedRouteState.navigatedFrom) {
      return;
    }

    setRouteState(updatedRouteState);
  }, [location]);

  return <RouteContext.Provider value={routeState}>{props.children}</RouteContext.Provider>;
};
