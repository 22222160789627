import { FormInput } from "api/rpc/2022-09/facilityAdmin/facility/form/formInput";
import React from "react";
import CustomSelectEdit from "../customEdits/CustomSelectEdit";
import { Select } from "components/select/index";
import CustomInputEdit from "../customEdits/CustomInputEdit";
import Input from "components/form/input/Input";
import CustomCheckboxEdit from "../customEdits/CustomCheckboxEdit";
import Checkbox from "components/form/checkbox/Checkbox";
import CustomTextEdit from "../customEdits/CustomTextEdit";
import TextField from "components/form/textField/TextField";
import { useFormEditContext } from "../FormEditContext";
import DraggableEditInput from "components/draggableEditInput/DraggableEditInput";
import { getLanguage } from "helpers/Helpers";
import { useTranslation } from "react-i18next";

interface IFormEditInputProps {
  formInput: FormInput;
}

export default function FormEditInput(props: IFormEditInputProps) {
  const { Option } = Select;

  const { formInput } = props;

  const formEditContext = useFormEditContext();
  const { t, i18n } = useTranslation();

  return (
    <DraggableEditInput
      item={formInput}
      rows={formEditContext.formInputRowsContainer?.updatedState}
      setSelectedEditInput={formEditContext.setSelectedFormInput}
    >
      {formInput.type === "select" && (
        <CustomSelectEdit
          label={formInput.label}
          helpText={formInput.help_text}
          array={formInput.values}
          required={formInput.required}
          saveInput={(label, values, helpText, required) =>
            formEditContext.handleUpdateInput(formInput.id, label, helpText, values, required)
          }
          editModalOpen={
            formEditContext.selectedFormInput.id === formInput.id && formEditContext.selectedFormInput.editModalOpen
          }
          closeEditModal={formEditContext.closeFormInputEditModal}
        >
          <Select label={formInput.label} helpText={formInput.help_text}>
            {!formInput.values || formInput?.values?.length < 1 ? (
              <Option key={"empty"} value={""}></Option>
            ) : (
              <>
                {formInput.values.map((value: string, index) => (
                  <Option key={index} value={value}>
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontSize: "18px",
                      }}
                    >
                      {value}
                    </span>
                  </Option>
                ))}
              </>
            )}
          </Select>
        </CustomSelectEdit>
      )}

      {formInput.type === "input" && (
        <CustomInputEdit
          label={formInput.label}
          helpText={formInput.help_text}
          required={formInput.required}
          saveInput={(label, value, helpText, required) =>
            formEditContext.handleUpdateInput(formInput.id, label, helpText, null, required)
          }
          editModalOpen={
            formEditContext.selectedFormInput.id === formInput.id && formEditContext.selectedFormInput.editModalOpen
          }
          closeEditModal={formEditContext.closeFormInputEditModal}
        >
          <Input
            id={formInput.id}
            label={formInput.label}
            helpText={formInput.help_text}
            defaultValue={formInput.values}
            readOnly
          />
        </CustomInputEdit>
      )}

      {formInput.type === "checkbox" && (
        <CustomCheckboxEdit
          label={formInput.label}
          helpText={formInput.help_text}
          required={formInput.required}
          options={formInput.values}
          saveInput={(label, helpText, required) =>
            formEditContext.handleUpdateInput(formInput.id, label, helpText, null, required)
          }
          editModalOpen={
            formEditContext.selectedFormInput.id === formInput.id && formEditContext.selectedFormInput.editModalOpen
          }
          closeEditModal={formEditContext.closeFormInputEditModal}
        >
          <Checkbox size="medium" label={formInput.label} supportText={formInput.help_text} checked={false} readOnly />
        </CustomCheckboxEdit>
      )}

      {formInput.type === "text" && (
        <CustomTextEdit
          label={formInput.label}
          description={formInput.help_text}
          saveInput={(label: string, description: string) =>
            formEditContext.handleUpdateInput(formInput.id, label, description, null)
          }
          editModalOpen={
            formEditContext.selectedFormInput.id === formInput.id && formEditContext.selectedFormInput.editModalOpen
          }
          closeEditModal={formEditContext.closeFormInputEditModal}
        >
          <TextField label={formInput.label} value={formInput.help_text} readOnly />
        </CustomTextEdit>
      )}
    </DraggableEditInput>
  );
}
