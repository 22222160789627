import React, { useEffect, useRef } from "react";
import { Node, NodeProps } from "reactflow";
import { useDynamicPricingContext } from "../DynamicPricingContext";
import { IFactorNumberRangeValueType } from "./factorNodeEditModes/FactorNodeNumberRangeEditMode";
import { IFactorTimeRangeValueType } from "./factorNodeEditModes/FactorNodeTimeRangeEditMode";
import { IFactorPercentRangeValueType } from "./factorNodeEditModes/FactorNodePercentRangeEditMode";
import { IFactorPercentVariationValueType } from "./factorNodeEditModes/FactorNodePercentVariationEditMode";
import { DYNAMIC_PRICING_FACTOR_NODE_CLASS_NAME } from "./DynamicPricingFactorNode";
import "./dynamicPricingFactorGroupNode.scss";

export const DYNAMIC_PRICING_FACTOR_GROUP_NODE = "DYNAMIC_PRICING_FACTOR_GROUP_NODE";
export const DYNAMIC_PRICING_FACTOR_GROUP_NODE_CLASS_NAME = "dynamic-pricing-factor-group-node";
export const DYNAMIC_PRICING_FACTOR_GROUP_VARIATION_ID = "variations";
export const DYNAMIC_PRICING_FACTOR_GROUP_NODE_WIDTH = 325;
export const DYNAMIC_PRICING_FACTOR_GROUP_VARIATION_WIDTH = 100;

export type DynamicPricingFactorValue =
  | IFactorNumberRangeValueType
  | IFactorTimeRangeValueType
  | IFactorPercentRangeValueType
  | IFactorPercentVariationValueType;

type ExtractValueType<T> = T extends { valueType: infer U } ? U : never;
export type DynamicPricingFactorValueType = ExtractValueType<DynamicPricingFactorValue>;

export interface IDynamicPricingFactorGroupNodeData {
  primaryKey: number;
  title: string;
  valueType: DynamicPricingFactorValueType;
  order: number;
  nextGroupId: string;
  incomingBranchingLimit: number;
}

export interface IDynamicPricingFactorGroupNode extends Node {
  type: typeof DYNAMIC_PRICING_FACTOR_GROUP_NODE;
  data: IDynamicPricingFactorGroupNodeData;
}

export function getDefaultFactorValue(valueType: DynamicPricingFactorValueType): DynamicPricingFactorValue {
  switch (valueType) {
    case "number_range":
      return {
        valueType: "number_range",
        startNumber: null,
        endNumber: null,
      };
    case "time_range":
      return {
        valueType: "time_range",
        startTime: null,
        endTime: null,
      };
    case "percent_range":
      return {
        valueType: "percent_range",
        startPercent: null,
        endPercent: null,
      };
    case "percent_variation":
      return {
        valueType: "percent_variation",
        percentVariation: 0,
      };
  }
}

export default function DynamicPricingFactorGroupNode(props: NodeProps<IDynamicPricingFactorGroupNodeData>) {
  const { id, data } = props;

  const dynamicPricingFactorGroupNodeRef = useRef<HTMLDivElement>();

  const dynamicPricingContext = useDynamicPricingContext();

  const isSelected = dynamicPricingContext.selectedNode?.id === id;

  function handleDocumentMousedown(event: any) {
    if (
      !isSelected ||
      !event?.target ||
      !dynamicPricingFactorGroupNodeRef.current ||
      dynamicPricingFactorGroupNodeRef.current.contains(event.target) ||
      dynamicPricingContext.flowActionPanelRef?.current?.contains(event.target) ||
      ((event.target.closest(`.${DYNAMIC_PRICING_FACTOR_NODE_CLASS_NAME}`) != null ||
        event.target.closest(`.${DYNAMIC_PRICING_FACTOR_GROUP_NODE_CLASS_NAME}`) != null) &&
        event.target.id !== DYNAMIC_PRICING_FACTOR_GROUP_VARIATION_ID)
    ) {
      return;
    }

    dynamicPricingContext.setSelectedNode(null);
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleDocumentMousedown, true);

    return () => {
      document.removeEventListener("mousedown", handleDocumentMousedown, true);
    };
  }, [isSelected]);

  return (
    <div
      ref={dynamicPricingFactorGroupNodeRef}
      id={id}
      className={DYNAMIC_PRICING_FACTOR_GROUP_NODE_CLASS_NAME}
      style={{
        width:
          id === DYNAMIC_PRICING_FACTOR_GROUP_VARIATION_ID
            ? `${DYNAMIC_PRICING_FACTOR_GROUP_VARIATION_WIDTH}px`
            : `${DYNAMIC_PRICING_FACTOR_GROUP_NODE_WIDTH}px`,
        height: `${dynamicPricingContext.groupHeight}px`,
        backgroundColor: id === DYNAMIC_PRICING_FACTOR_GROUP_VARIATION_ID && "initial",
        outline: isSelected && "1px solid black",
      }}
      onClick={e => {
        e.stopPropagation();

        if (id !== DYNAMIC_PRICING_FACTOR_GROUP_VARIATION_ID) {
          if (isSelected) {
            dynamicPricingContext.setSelectedNode(null);
          } else {
            dynamicPricingContext.setSelectedNode({
              id,
              isGroup: true,
              isVariation: false,
            });
          }
        }
      }}
    >
      <div className="dynamic-pricing-factor-group-node-title">{data.title}</div>
    </div>
  );
}
