import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/client/credit-book";

interface IGetCreditBook {
  id?: number | string;
  facility_id?: number;
  customer_id?: number;
  league_id?: string | number;
  tournament_id?: number;
  type?: "credit_book" | "prize_account";
  extended?: boolean;
}

interface IPostCreditBook {
  title: string;
  type?: "credit_book" | "prize_account";
  facility_id?: number;
  customer_id?: number;
  league_id?: string | number;
  tournament_id?: number;
}

interface IPutCreditBook {
  id: number;
  title: string;
  type?: "credit_book" | "prize_account";
  facility_id?: number;
  customer_id?: number;
  league_id?: string | number;
  tournament_id?: number;
}

interface IPutVariant {
  credit_book_id: number;
  variant_id: number;
}

interface IDeleteVariant {
  credit_book_id: number;
  variant_id: number;
}

//Get Credit Book
export async function GetCreditBook(params: IGetCreditBook, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseUrl, params, useGlobalLoader, token);
}

//Post Credit Book
export async function PostCreditBook(params: IPostCreditBook, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

//Put Credit Book
export async function PutCreditBook(params: IPutCreditBook, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

//Put Variant
export async function PutCreditBookVariant(params: IPutVariant, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/variant", params, useGlobalLoader);
}

//Delete Variant
export async function DeleteCreditBookVariant(params: IDeleteVariant, useGlobalLoader: boolean) {
  return await APIDelete(baseUrl + "/variant", params, useGlobalLoader);
}

//Distribute Credit Book Funds
export async function DistributeCreditBook(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/distribute", params, useGlobalLoader);
}

//GET Credit Book Transactions
export async function GetCreditBookTransactions(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseUrl + "/transaction", params, useGlobalLoader);
}
