import React, { ChangeEvent, useEffect, useState } from "react";
import { StatusCode } from "api/protocols";
import { dequeue, enqueue, showError, showSuccess } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";

import {
  GetCustomerPreferences,
  IPreferences,
  PostCustomerPreferences,
  PutCustomerPreferences,
} from "api/rpc/2024-04/customer/customer";

import Page from "components/page/Page";
import PreferenceCard from "components/preferenceCard/PreferenceCard";

import "./portalPreferences.scss";
import axios, { CancelToken } from "axios";
import Callout from "components/callout/Callout";
import Spin from "components/spin/spin";
import ReactDOM from "react-dom";
import { isEqualWith } from "lodash";
import { isEmpty } from "helpers/Helpers";
import { ButtonNew as Button } from "components/buttonNew";
import { GetClient, IClient } from "api/rpc/2024-04/customer/client";

interface IPreferencesState {
  preferences: Array<IPreferences>;
  clients: Array<IClient>;
}

export default function PortalPreferences() {
  const dispatch = useAppDispatch();

  const [preferencesState, setPreferencesState] = useState<IPreferencesState>({
    preferences: null,
    clients: null,
  });

  const [preferencesStateBeforeChanges, setPreferencesStateBeforeChanges] = useState<Array<IPreferences>>(null);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadPreferences(source.token);
    return () => {
      source.cancel("Cancelled");
    };
  }, []);

  async function loadPreferences(cancelToken: CancelToken = null) {
    const prefRes = await GetCustomerPreferences(null, false, cancelToken);
    const clientRes = await GetClient({ collect: true }, false);

    if (prefRes.status !== StatusCode.OK || prefRes?.data?.length < 1 || clientRes?.status !== StatusCode.OK) {
      if (prefRes.message === "Cancelled") {
        return;
      }
      ReactDOM.unstable_batchedUpdates(() => {
        setPreferencesState(prevState => ({
          ...prevState,
          preferences: [],
        }));
        setPreferencesStateBeforeChanges([]);
      });
      dispatch(showError("Error loading preferences"));
      return;
    }

    ReactDOM.unstable_batchedUpdates(() => {
      setPreferencesState(prevState => ({
        ...prevState,
        preferences: prefRes?.data,
        clients: clientRes?.data,
      }));
      setPreferencesStateBeforeChanges(prefRes?.data);
    });
  }

  async function savePreferences() {
    const preferences = [...preferencesState?.preferences];
    dispatch(enqueue());
    for (const [index, preference] of preferences.entries()) {
      if (!isEqualWith(preference, preferencesStateBeforeChanges[index])) {
        const prefRes = await PutCustomerPreferences(
          {
            client_id: preference.client_id,
            marketing_email: preference.marketing_email,
            booking_confirmation_email: preference.booking_confirmation_email,
            booking_reminder_email: preference.booking_reminder_email,
            booking_update_email: preference.booking_update_email,
            booking_cancellation_email: preference.booking_cancellation_email,
          },
          false,
        );

        if (prefRes.status !== StatusCode.OK) {
          dispatch(showError("Error updating preferences"));
          dispatch(dequeue());
          return;
        }
      }
    }
    setPreferencesStateBeforeChanges(preferencesState?.preferences);
    dispatch(dequeue());
    dispatch(showSuccess("Preferences updated sucessfully"));
  }

  function handleToggleChange(event: React.ChangeEvent<HTMLInputElement>, preferenceIndex: number) {
    const { id, checked } = event.target;
    const updatedPreferences = [...preferencesState?.preferences];
    updatedPreferences[preferenceIndex] = { ...updatedPreferences[preferenceIndex], [id]: checked };
    setPreferencesState(prevState => ({ ...prevState, preferences: updatedPreferences }));
  }

  function cancelUnsavedChanges() {
    setPreferencesState(prevState => ({ ...prevState, preferences: preferencesStateBeforeChanges }));
  }

  function unsavedChangesExist() {
    if (preferencesStateBeforeChanges === null) {
      return false;
    }

    return !isEqualWith(preferencesState?.preferences, preferencesStateBeforeChanges, (originalValue, newValue) => {
      if (isEmpty(originalValue) && isEmpty(newValue)) {
        return true;
      }
    });
  }

  async function createPreferences(clientId: number) {
    const prefRes = await PostCustomerPreferences({ client_id: clientId }, true);
    if (prefRes.status !== StatusCode.OK) {
      dispatch(showError("Error creating preferences"));
      return;
    }
    ReactDOM.unstable_batchedUpdates(() => {
      setPreferencesState(prevState => ({ ...prevState, preferences: null, clients: null }));
      setPreferencesStateBeforeChanges(null);
    });
    dispatch(showSuccess("Successfully created preferences"));
    await loadPreferences();
  }

  const primaryAction = {
    content: "Save Preferences",
    action: savePreferences,
    disabled: !unsavedChangesExist(),
  };

  return (
    <Page
      title="Preferences"
      primaryAction={primaryAction}
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: savePreferences,
        onCancel: cancelUnsavedChanges,
      }}
    >
      <div>
        <div>
          <span className="portal-preferences-header">Notifications Settings</span>
          <p className="text-subdued">
            {
              "We may still send you important notifications about your account outside of your normal notification settings"
            }
          </p>
        </div>
        {preferencesState?.clients ? (
          preferencesState?.clients?.length > 0 ? (
            preferencesState?.clients?.map((client, index) => {
              const preference = preferencesState?.preferences?.find(
                preference => preference?.client_id === client.id,
              );
              return (
                <div key={index} className="portal-preferences-container">
                  {client?.logo_source_url ? (
                    <img
                      className="portal-preferences-client-logo"
                      src={client?.logo_source_url}
                      alt={`${client?.long_name} Logo`}
                    />
                  ) : (
                    <p className="portal-preferences-client-name">{client?.long_name}</p>
                  )}
                  {preference ? (
                    <>
                      <div className="portal-preferences-divider" />
                      <PreferenceCard
                        title="Marketing Notifications"
                        description="Information about upcoming promotions"
                        handleToggleChange={e => handleToggleChange(e, index)}
                        options={[
                          {
                            label: "Email",
                            id: "marketing_email",
                            checked: preference.marketing_email,
                          },
                        ]}
                      />
                      <PreferenceCard
                        title="Booking Confirmations"
                        description="Notifications when you create a new booking"
                        handleToggleChange={e => handleToggleChange(e, index)}
                        options={[
                          {
                            label: "Email",
                            id: "booking_confirmation_email",
                            checked: preference.booking_confirmation_email,
                          },
                        ]}
                      />
                      <PreferenceCard
                        title="Booking Reminders"
                        description="Notifications when you have an upcoming booking"
                        handleToggleChange={e => handleToggleChange(e, index)}
                        options={[
                          {
                            label: "Email",
                            id: "booking_reminder_email",
                            checked: preference.booking_reminder_email,
                          },
                        ]}
                      />
                      <PreferenceCard
                        title="Booking Updates"
                        description="Notifications when you update an upcoming booking"
                        handleToggleChange={e => handleToggleChange(e, index)}
                        options={[
                          {
                            label: "Email",
                            id: "booking_update_email",
                            checked: preference.booking_update_email,
                          },
                        ]}
                      />
                      <PreferenceCard
                        title="Booking Cancellations"
                        description="Notifications when you cancel an upcoming booking"
                        handleToggleChange={e => handleToggleChange(e, index)}
                        options={[
                          {
                            label: "Email",
                            id: "booking_cancellation_email",
                            checked: preference.booking_cancellation_email,
                          },
                        ]}
                      />
                    </>
                  ) : (
                    <Button size="medium" type="text" onClick={() => createPreferences(client.id)}>
                      Add Preferences
                    </Button>
                  )}
                </div>
              );
            })
          ) : (
            <div>
              <br />
              <Callout
                type="info"
                title="No Preferences found"
                content="There are no preference settings for this account"
              />{" "}
            </div>
          )
        ) : (
          <span style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
            <Spin />
          </span>
        )}
      </div>
    </Page>
  );
}
