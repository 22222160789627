import React, { useEffect, useState } from "react";
import { Link, Route, Switch, Redirect, useHistory, useLocation } from "react-router-dom";
import { useRouteMatch } from "react-router";

// API
import { PostConnection } from "api/rpc/facilityAdmin/payment/teeOnPayments/reader";

import TeeSheet from "containers/client/teesheet";

import NewTeeSheet from "./teesheet/newTeeSheet/NewTeeSheet";
import Dashboard from "./Dashboard";

import ProductMenu from "containers/client/productsMain";

import Reports from "pages/secure/client/reports/Reports"; //needs container
import Report from "containers/client/report";
import reportRoutes from "./reports/ReportRoutes";

import OrderMenu from "containers/client/order";

// import Customers from "containers/customers";
import Register from "containers/facility/register";

import Layout from "components/layout/index";
import Menu from "components/menu/index";
import { Title } from "components/typography/index";
import DateSelector from "components/dateSelector/dateSelector";
import Input from "components/input/Input";
import { ButtonNew as Button, ButtonNew } from "components/buttonNew/index";
import Checkbox from "components/checkbox/index";
import { Select } from "components/select/index";
import Spin from "components/spin/spin";

import Moment from "react-moment";
import moment from "moment";

import { mainMenu } from "routes/menuOptions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "public/scss/admin.scss";
import Divider from "components/divider";

import { GetTeeSheets } from "api/rpc/teeSheet/teeSheet";
import classNames from "classnames";

import { IUIAction, IUIState } from "redux/reducers/ui";
import { IClientState } from "redux/reducers/client";
import { IClientFacilityStore } from "redux/reducers/clientAdmin/facility";

import { IUIActions } from "redux/actions/ui";
import { IAuthActions } from "redux/actions/auth";
import { IAuthState } from "redux/reducers/auth";
import { IFacilityActions } from "redux/actions/clientAdmin/facility";
import { IClientActions } from "redux/actions/client";

import { UserActive, UserLogout } from "../../../api/rpc";
import { StatusCode } from "../../../api/protocols";
import { IconName } from "@fortawesome/fontawesome-svg-core";

import Sheet from "components/sheet/Sheet";
import kitchenDisplaySystem from "containers/facility/kitchen/kitchenDisplaySystem";

import Leagues from "./league/Leagues";
import LeagueNew from "pages/secure/client/league/LeagueNew";
import Dashboards from "./dashboardSettings/Dashboards";
import DashboardsNew from "./dashboardSettings/DashboardsNew";
import TilesNew from "containers/client/tilesNew";
import TilesEdit from "containers/client/tilesEdit";
import DashboardsEdit from "./dashboardSettings/DashboardsEdit";

import Settings from "containers/client/settings/settings";

import Tournaments from "./tournament/Tournaments";
import TournamentNew from "pages/secure/client/tournament/TournamentNew";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import LeftSubMenu from "components/leftMenu/LeftSubMenu";
import ReactDOM from "react-dom";

//Settings Submenu
import settingsOptions from "./settings/settingsOptions";
import settingsRoutes from "./settings/settingsRoutes";

//Order SubMenu
import orderOptions from "./order/OrderOptions";
import orderRoutes from "./order/OrderRoutes";

//Product Submenu
import productOptions from "./product/productOptions";
import productRoutes from "./product/productRoutes";

//Customer Submenu
import customerOptions from "./customer/customerOptions";

//Facilities Submenu
import ClientSettingsHeader from "containers/client/settings/clientSettingsHeader";
import { SupportTab } from "components/supportTab/SupportTab";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { MOBILE_WIDTH } from "helpers/ScreenSizes";
import Customers from "./customer/main/Customers";
import CustomerClientProvider from "./customer/context/CustomerClientProvider";
import tournamentOptions from "./tournament/TournamentOptions";
import { useTranslation } from "react-i18next";
import Tournament from "./tournament/Tournament";
import TournamentPlayers from "./tournament/TournamentPlayers";
import TournamentScores from "./tournament/TournamentScores";
import TournamentSettingsGeneral from "./tournament/settings/General";
import TournamentRegistrationFees from "./tournament/settings/Registration";
import eventOptions from "./events/EventOptions";
import leagueOptions from "./league/LeagueOptions";
import League from "./league/League";
import LeaguePlayers from "./league/LeaguePlayers";
import LeagueScores from "./league/LeagueScores";
import LeagueSettings from "./league/settings/Settings";
import LeagueGeneralSettings from "./league/settings/General";
import LeagueRegistrationSettings from "./league/settings/Registration";
import MembershipsSold from "./customer/main/MembershipsSold";

export interface ISubOptions {
  label: string;
  url: string;
  icon?: IconName;
}

export interface IOptions {
  label: string;
  url?: string;
  icon?: IconName;
  subOptions?: ISubOptions[];
  secondOptionHeader?: boolean;
}

interface IProps {
  authActions: IAuthActions;
  clientActions: IClientActions;
  clientStore: IClientState;
  facilityActions: IFacilityActions;
  clientFacilityStore: IClientFacilityStore;
  uiStore: IUIState;
  uiActions: IUIActions;
  authStore: IAuthState;
}

export interface IRegister {
  id: number;
  facility_id: number;
  title: string;
  default_card_reader_id: number;
  register_group_id: number;
}

interface IPath {
  name: string;
  title: string;
  icon: IconName;
  options?: Array<IOptions>;
}

interface IRouteMatch {
  leagueId: string;
  tournamentId: string;
}

const ClientAdmin: React.FC<IProps> = props => {
  const history = useHistory();

  const baseLeagueUrl = "/admin/league/:leagueId";
  const leagueId = parseInt(useRouteMatch<IRouteMatch>(baseLeagueUrl)?.params?.leagueId ?? "");
  const baseTournamentUrl = "/admin/tournament/:tournamentId";
  const tournamentId = parseInt(useRouteMatch<IRouteMatch>(baseTournamentUrl)?.params?.tournamentId ?? "");
  const { t, i18n } = useTranslation();

  const {
    uiStore,
    clientActions,
    clientStore,
    clientFacilityStore,
    facilityActions,
    authActions,
    authStore,
    uiActions,
  } = props;

  useEffect(() => {
    void facilityActions.loadAdminFacility(null, false);
    void facilityActions.loadAllAdminFacilities(false);
    void clientActions.loadClient(null, false);
  }, []);

  useEffect(() => {
    console.log("Auth Store", authStore);
    console.log("Client Store", clientStore);
  }, [authStore]);

  const { Option } = Select;

  const location = useLocation();

  const { Sider, Header, Content, Footer } = Layout;

  const [collapsed, setCollapsed] = useState(false);
  const [currentRoute, setCurrentRoute] = useState(undefined);
  const [currentPath, setCurrentPath] = useState<IPath>(undefined);
  const [mobileLeftMenuVisible, setMobileLeftMenuVisible] = useState<boolean>(false);
  const windowSize = useWindowSize();
  const permissions = authStore?.user?.permissions;

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  const [value, setValue] = useState<any>();

  const adminContentCls = classNames("ui-main_content", {
    "no-scroll": location.pathname === "/admin/teesheet",
    "ui-main_content-teesheet": location.pathname === "/admin/teesheet",
  });

  async function logoutUser() {
    const logoutRes = await UserLogout(true);
    console.log(logoutRes);
    if (logoutRes.status !== StatusCode.OK) {
      authActions.userLogout();
    }
    if (logoutRes.status === StatusCode.OK) {
      history.push("/");
    }
  }

  const reportPath: { name: string; title: string; icon: IconName } = {
    name: "report",
    title: "Reports",
    icon: "file-chart-line",
  };

  const paths: { name: string; title: string; icon: IconName; hidden?: boolean; options?: Array<IOptions> }[] = [
    // { name: "dashboard", title: "Dashboards", icon: "chart-line" },
    { name: "teesheet", title: "TeeSheet", icon: "stream" },
    { name: "order", title: "Orders", icon: "box-open", options: orderOptions },
    { name: "product", title: "Products", icon: "tag", options: productOptions },
    { name: "customers", title: "Customers", icon: "user", options: customerOptions },
    // { name: "facility", title: "Facilities", icon: "house", options: facilityOptions },
    { name: "events", title: t("secure.facility.admin.012"), icon: "trophy", options: eventOptions() },
    {
      name: "league",
      title: "Leagues",
      icon: "trophy",
      hidden: true,
      options: leagueOptions(t, leagueId),
    },
    {
      name: "tournament",
      title: "Tournaments",
      icon: "trophy",
      hidden: true,
      options: tournamentOptions(t, tournamentId),
    },
    { name: "settings", title: "Settings", icon: "cog", options: settingsOptions },
    ...(permissions?.reports_access ? [reportPath] : []),
  ];

  useEffect(() => {
    const routeName = history.location.pathname.split("/");
    if (currentRoute === undefined) {
      setCurrentRoute(routeName[routeName.length - 1]);
    }
    let foundPath = paths?.find(path => path.name === routeName?.find(name => name === path.name));
    if (!foundPath) {
      foundPath = paths?.find(path =>
        path?.options?.some(option =>
          option?.url
            ?.split("/")
            ?.some(
              optionPath => optionPath && optionPath !== "admin" && routeName?.some(name => name === optionPath),
            ),
        ),
      );
    }
    setCurrentPath(foundPath);
  }, [currentRoute, location]);

  const handleMenuItemClick = (path: IPath) => {
    if (mobileLeftMenuVisible && windowSize.width <= MOBILE_WIDTH && (!path?.options || path.options.length === 0)) {
      setMobileLeftMenuVisible(false);
    }

    ReactDOM.unstable_batchedUpdates(() => {
      setCurrentRoute(path.name);
      setCurrentPath(path);
    });
  };

  const [displayReaders, setDisplayReaders] = useState<boolean>(false);

  return (
    <>
      <Layout>
        {mobileLeftMenuVisible && (!currentPath?.options || currentPath.options.length === 0) && (
          <div className="ui-layout_left-nav-mobile">
            <div className="ui-left-nav_container">
              <div className="ui-left-nav_content">
                <div className="ui-left-nav-logo">
                  <img className="mb-4" src="../../../public/images/tee_on_menu_logo.png" alt="Tee-On Logo" />
                </div>
                <div className="ui-left-nav-menu">
                  <Menu>
                    {paths.map((path, i) => {
                      if (!path.hidden) {
                        return (
                          <Link key={i} to={`/admin/${path.name}`}>
                            <Menu.Item
                              selected={currentRoute === path.name ? true : false}
                              onClick={() => handleMenuItemClick(path)}
                              fullWidthIcon
                              icon={
                                <div className="flex gap-1 items-center justify-between px-2 w-full">
                                  <div className="flex gap-1 items-center">
                                    <div className="w-6">
                                      <FontAwesomeIcon icon={["far", path.icon]} />
                                    </div>
                                    <div>{path.title}</div>
                                  </div>
                                  <FontAwesomeIcon className="mr-1" icon={["far", "chevron-right"]} />
                                </div>
                              }
                            ></Menu.Item>
                          </Link>
                        );
                      }
                    })}
                  </Menu>
                  <Menu className="mb-4">
                    <SupportTab>
                      <Menu.Item
                        className="self-center cursor-pointer"
                        fullWidthIcon
                        icon={
                          <div className="flex gap-1 items-center justify-between px-2 w-full">
                            <div className="flex gap-1 items-center">
                              <div className="w-6">
                                <FontAwesomeIcon icon={["far", "circle-info"]} />
                              </div>
                              <div>Support</div>
                            </div>
                          </div>
                        }
                      ></Menu.Item>
                    </SupportTab>
                    <Menu.Item
                      className="self-center cursor-pointer"
                      onClick={logoutUser}
                      fullWidthIcon
                      icon={
                        <div className="flex gap-1 items-center justify-between px-2 w-full">
                          <div className="flex gap-1 items-center">
                            <div className="w-6">
                              <FontAwesomeIcon style={{ transform: "scaleX(-1)" }} icon={["far", "sign-out"]} />
                            </div>
                            <div>Logout</div>
                          </div>
                        </div>
                      }
                    ></Menu.Item>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
        )}

        {mobileLeftMenuVisible && currentPath?.options?.length > 0 && (
          <div className="ui-layout_left-nav-icons-mobile">
            <div className="ui-left-nav_container">
              <div className="ui-left-nav_content">
                <div className="ui-left-nav-logo">
                  <img src="../../../public/images/tee_on_menu_logo.png" alt="Tee-On Logo" />
                </div>
                <div className="ui-left-nav-menu">
                  <Menu>
                    {paths.map((path, i) => {
                      if (!path.hidden) {
                        return (
                          <Link key={i} to={`/admin/${path.name}`}>
                            <Menu.Item
                              selected={currentRoute === path.name ? true : false}
                              onClick={() => handleMenuItemClick(path)}
                              icon={<FontAwesomeIcon icon={["far", path.icon]} />}
                            ></Menu.Item>
                          </Link>
                        );
                      }
                    })}
                  </Menu>
                  <Menu className="mb-4">
                    <SupportTab>
                      <Menu.Item
                        className="self-center cursor-pointer"
                        icon={<FontAwesomeIcon icon={["far", "circle-info"]} />}
                      />
                    </SupportTab>
                    <Menu.Item
                      onClick={logoutUser}
                      className="self-center cursor-pointer"
                      icon={<FontAwesomeIcon style={{ transform: "scaleX(-1)" }} icon={["far", "sign-out"]} />}
                    />
                  </Menu>
                </div>
              </div>
            </div>
          </div>
        )}

        {mobileLeftMenuVisible && (
          <>
            <div
              onClick={() => setMobileLeftMenuVisible(prev => !prev)}
              className="ui-layout_left-nav-mobile-backdrop"
            />
            <FontAwesomeIcon
              onClick={() => setMobileLeftMenuVisible(prev => !prev)}
              className="ui-layout_left-nav-mobile-backdrop-close-button"
              icon={["far", "xmark"]}
            />
          </>
        )}

        <div
          className={classNames("ui-layout_left-submenu-mobile", {
            "ui-layout_left-submenu-mobile-hide": mobileLeftMenuVisible && currentPath?.options ? false : true,
          })}
        >
          <LeftSubMenu
            title={currentPath?.title}
            options={currentPath?.options}
            onClickOption={() => setMobileLeftMenuVisible(false)}
          />
        </div>

        <div className="ui-layout_left-nav-desktop">
          <div className="ui-left-nav_container">
            <div className="ui-left-nav_content">
              <div className="ui-left-nav-logo">
                <img src="../../../public/images/tee_on_menu_logo.png" alt="Tee-On Logo" />
              </div>
              <div className="ui-left-nav-menu">
                <Menu>
                  {paths.map((path, i) => {
                    if (!path.hidden) {
                      return (
                        <Link key={i} to={`/admin/${path.name}`}>
                          <OverlayTrigger
                            placement="auto"
                            overlay={<Tooltip id="leftMenuToolTip">{path.title}</Tooltip>}
                          >
                            <Menu.Item
                              selected={currentRoute === path.name ? true : false}
                              onClick={() => handleMenuItemClick(path)}
                              icon={<FontAwesomeIcon icon={["far", path.icon]} />}
                            ></Menu.Item>
                          </OverlayTrigger>
                        </Link>
                      );
                    }
                  })}
                </Menu>
                <Menu className="mb-4">
                  <SupportTab>
                    <Menu.Item
                      className="self-center cursor-pointer"
                      icon={<FontAwesomeIcon icon={["far", "circle-info"]} />}
                    />
                  </SupportTab>
                  <OverlayTrigger placement="auto" overlay={<Tooltip id="leftMenuToolTip">Logout</Tooltip>}>
                    <Menu.Item
                      onClick={logoutUser}
                      className="self-center cursor-pointer"
                      icon={<FontAwesomeIcon style={{ transform: "scaleX(-1)" }} icon={["far", "sign-out"]} />}
                    />
                  </OverlayTrigger>
                </Menu>
              </div>
            </div>
          </div>
        </div>

        <div
          className={classNames("ui-layout_left-submenu-desktop", {
            "ui-layout_left-submenu-desktop-hide": currentPath?.options ? false : true,
          })}
        >
          <LeftSubMenu title={currentPath?.title} options={currentPath?.options} />
        </div>

        <div
          className="ui-main"
          style={mobileLeftMenuVisible && windowSize.width <= MOBILE_WIDTH ? { filter: "blur(5px)" } : {}}
        >
          <div className="ui-header">
            <div className="ui-header_container">
              <div className="ui-header-content_mobile">
                <div />
                <div className="header-content-image-container">
                  <img className="header-content-image" src="../../../public/images/Tee-On.png" />
                </div>
                <div className="header-menu-icon" onClick={() => setMobileLeftMenuVisible(prev => !prev)}>
                  <FontAwesomeIcon icon={["far", "bars"]} />
                </div>
              </div>
              <div className="ui-header-content_desktop">
                <div className="facility-details">
                  <h2 className="h2">{clientStore?.client?.long_name}</h2>
                </div>
                <div className="admin-details">
                  <div className="admin-current-active-user">
                    <div>{authStore?.user?.full_name}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="ui-main_container">
            <div className={adminContentCls}>
              <Switch>
                {/* Temporarily disable dashboard route */}
                {/* <Route exact path="/admin/dashboard" component={Dashboard} /> */}

                {/* Settings routes */}
                <Route path="/admin/settings" component={Settings} exact />

                {window.location.href?.includes("/admin/settings/facility-settings") ? (
                  <ClientSettingsHeader>
                    {settingsRoutes?.map((route, index) => {
                      return <Route key={index} path={route.path} component={route.component} exact />;
                    })}
                  </ClientSettingsHeader>
                ) : (
                  settingsRoutes?.map((route, index) => {
                    return <Route key={index} path={route.path} component={route.component} exact />;
                  })
                )}

                {/* Order routes */}
                {orderRoutes?.map((route, index) => {
                  return <Route key={index} path={route.path} component={route.component} exact />;
                })}

                {/* Product routes */}
                {productRoutes?.map((route, index) => {
                  return <Route key={index} path={route.path} component={route.component} exact />;
                })}

                {/* Facility routes */}
                {/* {facilityRoutes?.map((route, index) => {
                  return <Route key={index} path={route.path} component={route.component} exact />;
                })} */}

                {/* Customer routes */}
                <Route exact path="/admin/customers" component={Customers} />
                <Route exact path="/admin/customers-public" component={Customers} />
                <Route exact path="/admin/customers-member" component={MembershipsSold} />
                <Route exact path="/admin/customers/:customerId/profile" component={CustomerClientProvider} />
                <Route exact path="/admin/customers/:customerId/reservations" component={CustomerClientProvider} />
                <Route exact path="/admin/customers/:customerId/membership" component={CustomerClientProvider} />
                <Route exact path="/admin/customers/:customerId/tickets" component={CustomerClientProvider} />
                <Route exact path="/admin/customers/:customerId/notes" component={CustomerClientProvider} />
                <Route exact path="/admin/customers/:customerId/item-sales" component={CustomerClientProvider} />
                <Route exact path="/admin/customers/:customerId/payment-methods" component={CustomerClientProvider} />
                <Route exact path="/admin/customers/:customerId/house-accounts" component={CustomerClientProvider} />
                {/* <Route exact path="/admin/customers/:customerId/profile" component={Customer} />
                <Route exact path="/admin/customers/:customerId/reservations" component={Customer} />
                <Route exact path="/admin/customers/:customerId/membership" component={Customer} />
                <Route exact path="/admin/customers/:customerId/houseAccounts" component={Customer} />
                <Route exact path="/admin/customers/:customerId/billing" component={Customer} />
                <Route exact path="/admin/customers/:customerId/payment-methods" component={Customer} />
                <Route exact path="/admin/customers/:customerId/item-sales" component={Customer} /> */}

                {/* {customerRoutes?.map((route, index) => {
                  return <Route key={index} path={route.path} component={route.component} exact />;
                })} */}

                <Route exact path="/admin/teesheet" component={NewTeeSheet} />

                <Route exact path="/admin/events" component={Leagues} />
                <Route exact path="/admin/events/new" component={LeagueNew} />
                <Route exact path={baseLeagueUrl + "/home"} component={League} />
                <Route exact path={baseLeagueUrl + "/players"} component={LeaguePlayers} />
                <Route exact path={baseLeagueUrl + "/scores"} component={LeagueScores} />
                <Route exact path={baseLeagueUrl + "/league-settings"} component={LeagueSettings} />
                <Route exact path={baseLeagueUrl + "/league-settings/general"} component={LeagueGeneralSettings} />
                <Route
                  exact
                  path={baseLeagueUrl + "/league-settings/registration-fees"}
                  component={LeagueRegistrationSettings}
                />

                <Route exact path="/admin/events-tournament" component={Tournaments} />
                <Route exact path="/admin/events-tournament/new" component={TournamentNew} />
                <Route exact path={baseTournamentUrl + "/home"} component={Tournament} />
                <Route exact path={baseTournamentUrl + "/players"} component={TournamentPlayers} />
                <Route exact path={baseTournamentUrl + "/scores"} component={TournamentScores} />
                <Route
                  exact
                  path={baseTournamentUrl + "/tournament-settings/general"}
                  component={TournamentSettingsGeneral}
                />
                <Route
                  exact
                  path={baseTournamentUrl + "/tournament-settings/registration-fees"}
                  component={TournamentRegistrationFees}
                />
                {/* <Route
                    exact
                    path={baseTournamentUrl + "/tournament-settings/registration-fees/new"}
                    component={TournamentRegistrationFeesNew}
                  /> */}

                {permissions?.reports_access && [
                  <Route exact path="/admin/report" key="/admin/report" component={Reports} />,
                  reportRoutes?.map((route, index) => {
                    return <Route key={index} path={route.path} component={route.component} exact />;
                  }),
                  <Route exact path="/admin/report/:id" key="/admin/report/:id" component={Report} />,
                ]}

                {/* 
                <Route exact path="/admin/league/new" component={LeagueNew} />

                <Route exact path="/admin/league/menu/:leagueId/home" component={LeagueMenu} />
                <Route exact path="/admin/league/menu/:leagueId/players" component={LeagueMenu} /> */}

                {/* Temporary dashboard route */}
                <Route exact path="/admin/dashboard-beta" component={Dashboard} />
                <Route exact path="/admin/dashboard/settings" component={Dashboards} />
                <Route exact path="/admin/dashboard/settings/new" component={DashboardsNew} />
                <Route exact path="/admin/dashboard/settings/:id" component={DashboardsEdit} />
                <Route exact path="/admin/dashboard/settings/tiles/new" component={TilesNew} />
                <Route exact path="/admin/dashboard/settings/tiles/:id" component={TilesEdit} />

                <Route exact path="/admin/kds" component={kitchenDisplaySystem} />

                <Redirect to="/admin/dashboard" />
              </Switch>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ClientAdmin;
