import React, { ChangeEvent, useEffect, useState } from "react";

import { StatusCode } from "api/protocols";
import { dequeue, enqueue, showError, showSuccess } from "redux/actions/ui";

import { useGuestPortalContext } from "./GuestPortalContext";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import Spin from "components/spin/spin";
import "./Navigation/portalNavigation.scss";
import "./portalPaymentMethods.scss";
import { capitalize, getCreditCardIcon } from "helpers/Helpers";
import { ButtonNew as Button } from "components/buttonNew";
import Popup from "components/popup/Popup";
import Callout from "components/callout/Callout";
import Sheet from "components/sheet/Sheet";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import CardSection from "components/cardSection/CardSection";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DeleteCustomerPaymentMethod,
  GetCustomerPaymentMethods,
  ICustomerPaymentMethod,
  PostPaymentMethod,
  PostSetup,
} from "api/rpc/2024-04/customer/paymentMethod";
import axios, { CancelToken } from "axios";
import Panel from "components/panel/Panel";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { MOBILE_WIDTH } from "helpers/ScreenSizes";
import BottomSheet from "components/bottomSheet/BottomSheet";
interface IState {
  paymentMethods: Array<ICustomerPaymentMethod>;
  deletePopupVisible: boolean;
  selectedPaymentMethodId: number;
  newCardVisible: boolean;
  elementComplete: {
    cardNumber: boolean;
    cardExpiry: boolean;
    cardCvc: boolean;
  };
}

export default function PortalPaymentMethods() {
  const dispatch = useAppDispatch();
  const { portalState } = useGuestPortalContext();
  const [state, setState] = useState<IState>({
    paymentMethods: null,
    deletePopupVisible: false,
    newCardVisible: false,
    selectedPaymentMethodId: null,
    elementComplete: {
      cardNumber: false,
      cardExpiry: false,
      cardCvc: false,
    },
  });
  const windowSize = useWindowSize();

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadPaymentMethods(source.token);

    return () => {
      source.cancel("Cancelled");
    };
  }, []);

  async function loadPaymentMethods(cancelToken?: CancelToken) {
    const paymentMethodRes = await GetCustomerPaymentMethods({ type: ["card"] }, false, cancelToken);
    if (paymentMethodRes?.status !== StatusCode.OK) {
      if (paymentMethodRes.message === "Cancelled") {
        return;
      }
      dispatch(showError("Error getting payment methods"));
      setState(prevState => ({ ...prevState, paymentMethods: [] }));
      return;
    }
    setState(prevState => ({ ...prevState, paymentMethods: paymentMethodRes?.data?.card }));
  }

  async function deletePaymentMethod() {
    if (state.selectedPaymentMethodId) {
      const deleteRes = await DeleteCustomerPaymentMethod({ id: state.selectedPaymentMethodId }, true);
      if (deleteRes?.status !== StatusCode.OK) {
        dispatch(showError(typeof deleteRes?.data === "string" ? deleteRes.data : "Error deleting payment method"));
        return;
      }
      dispatch(showSuccess("Successfully deleted payment method"));
      setState(prevState => ({
        ...prevState,
        deletePopupVisible: false,
        selectedPaymentMethodId: null,
        paymentMethods: null,
      }));
      void loadPaymentMethods();
    }
  }

  function handleCardSectionChange(
    e: StripeCardNumberElementChangeEvent | StripeCardExpiryElementChangeEvent | StripeCardCvcElementChangeEvent,
  ) {
    setState(prevState => ({
      ...prevState,
      elementComplete: { ...state.elementComplete, [e.elementType]: e.complete },
    }));
  }

  function closeNewCardModal() {
    elements.getElement(CardNumberElement).clear();
    elements.getElement(CardExpiryElement).clear();
    elements.getElement(CardCvcElement).clear();
    setState(prevState => ({ ...prevState, newCardVisible: false }));
  }

  async function handleAddNewCard() {
    if (!stripe || !elements) {
      dispatch(showError("Error with payment method setup"));
      return;
    }
    dispatch(enqueue());
    const stripePaymentMethodRes = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement("cardNumber"),
    });

    if (stripePaymentMethodRes.paymentMethod) {
      const setupRes = await PostSetup(false);

      if (setupRes.status !== StatusCode.OK) {
        dispatch(showError("Error setting up payment method"));
      }
      if (stripePaymentMethodRes?.paymentMethod?.id !== "undefined" && setupRes.status === StatusCode.OK) {
        const stripeConfirmCardRes = await stripe.confirmCardSetup(setupRes.data.setup_intent.client_secret, {
          payment_method: stripePaymentMethodRes.paymentMethod.id,
        });
        if (stripeConfirmCardRes.error) {
          dispatch(showError(stripeConfirmCardRes.error.message));
        } else {
          const postPaymentRes = await PostPaymentMethod(
            {
              payment_method_id: stripeConfirmCardRes.setupIntent.payment_method,
              save_card: true,
            },
            false,
          );
          if (postPaymentRes.status !== StatusCode.OK) {
            dispatch(showError("Error creating payment method"));
          } else {
            dispatch(showSuccess("Successfully added new card"));
            setState(prevState => ({
              ...prevState,
              paymentMethods: null,
            }));
            void closeNewCardModal();
            void loadPaymentMethods();
          }
        }
      }
    } else {
      dispatch(showError(stripePaymentMethodRes.error.message));
    }

    dispatch(dequeue());
  }

  const primaryAction = {
    content: "Add Credit Card",
    action: () => setState(prevState => ({ ...prevState, newCardVisible: true })),
  };

  // Check if card inputs are filled
  const elementsComplete =
    state.elementComplete.cardNumber === true &&
    state.elementComplete.cardExpiry === true &&
    state.elementComplete.cardCvc === true;

  return (
    <>
      <Page title="Payment Methods" full secondaryActions={[primaryAction]}>
        {state?.paymentMethods ? (
          state?.paymentMethods?.length > 0 ? (
            <div>
              {state.paymentMethods?.map((paymentMethod, index) => {
                const iconName = getCreditCardIcon(paymentMethod?.brand);
                return (
                  <div key={index} className="portal-payment-method-container">
                    <div className="portal-payment-method-credit-card-container">
                      <div className="portal-payment-method-credit-card-icon">
                        <FontAwesomeIcon icon={iconName} />
                      </div>

                      <div className="flex-column">
                        <p className="payment-subtext">
                          <span className="card-number">•••• •••• ••••</span> {paymentMethod?.last4}
                        </p>
                        <p className="payment-expiry-text">
                          Expires On: {String(paymentMethod?.exp_month).padStart(2, "0")}/{paymentMethod?.exp_year}
                        </p>
                      </div>
                    </div>

                    {/* Hide delete button for now until the delete endpoint gets added */}
                    <div className="flex-column">
                      <div className="flex-row">
                        <FontAwesomeIcon icon={["far", "trash-can"]} />
                        <Button
                          size="small"
                          type="text"
                          onClick={() =>
                            setState(prevState => ({
                              ...prevState,
                              deletePopupVisible: true,
                              selectedPaymentMethodId: paymentMethod?.id,
                            }))
                          }
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <Callout type="info" title="No Saved Payment Methods" content="You do not have any saved payment methods" />
          )
        ) : (
          <div className="portal-spinner">
            <span>
              <Spin />
            </span>
          </div>
        )}
      </Page>

      {/* Add new credit card modal */}
      <Panel
        open={state.newCardVisible && windowSize.width < MOBILE_WIDTH}
        onClose={closeNewCardModal}
        title={"Add Card"}
        animate
        primaryAction={{
          onClick: handleAddNewCard,
          label: "Add Card",
          disabled: !elementsComplete ? true : false,
        }}
        secondaryAction={{
          onClick: closeNewCardModal,
          label: "Cancel",
        }}
      >
        <div>
          <Form>
            <FormLayout>
              <FormLayout.Group>
                {windowSize.width < MOBILE_WIDTH && <CardSection onChange={handleCardSectionChange} type="one-row" />}
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </div>
      </Panel>

      <Sheet
        size="small"
        height="flexible"
        open={state.newCardVisible && windowSize.width > MOBILE_WIDTH}
        closable
        title={"Add New Credit Card"}
        cancelText={"Cancel"}
        okText={"Add Card"}
        onOk={handleAddNewCard}
        okDisabled={!elementsComplete ? true : false}
        onCancel={closeNewCardModal}
        backDropCancel={false}
      >
        <div>
          <Form>
            <FormLayout>
              <FormLayout.Group>
                {windowSize.width > MOBILE_WIDTH && <CardSection onChange={handleCardSectionChange} type="one-row" />}
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </div>
      </Sheet>

      <Popup
        open={state.deletePopupVisible && windowSize.width > MOBILE_WIDTH}
        onCancel={() =>
          setState(prevState => ({ ...prevState, deletePopupVisible: false, selectedPaymentMethodId: null }))
        }
        onOk={deletePaymentMethod}
        type="warning"
        title="Delete Payment Method?"
        description="Are you sure you want to delete the selected payment method?"
      />

      <BottomSheet
        open={state.deletePopupVisible && windowSize.width <= MOBILE_WIDTH}
        onClose={() =>
          setState(prevState => ({ ...prevState, deletePopupVisible: false, selectedPaymentMethodId: null }))
        }
        type="warning"
        content="Are you sure you want to delete the selected payment method?"
        primaryAction={{ label: "Delete", onClick: deletePaymentMethod }}
      />
    </>
  );
}
