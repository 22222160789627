import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import {
  TEventCompetition,
  TEventCompetitionStrokePlay,
  TFoursomeCompetition,
  TPairCompetition,
  TPlayerCompetition,
  TTeamCompetition,
} from "redux/reducers/models/league";
import { TTournamentSettings } from "redux/reducers/models/tournament";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseTournamentUrl = "/" + apiVersion + "/" + adminType + "/tournament/scoring";

export type TPutTournamentScoreSettings = {
  tournament_id: string | number;
  format: string;
  competition: TPlayerCompetition | TTeamCompetition | TPairCompetition | TFoursomeCompetition;
  holes: 9 | 18;
  tee_deck_id: number;
  handicap_format: "gross" | "net";
  handicap_allowance?: number;
  point_allocation?: number;
  best_of_team?: number;
  locked?: boolean;
};

// Scoring Settings
export async function GetTournamentScoreSettings(
  params: { tournament_id: string | number },
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return APIGet<TTournamentSettings>(baseTournamentUrl + "/settings", params, useGlobalLoader, token);
}

export async function PutTournamentScoreSettings(params: TPutTournamentScoreSettings, useGlobalLoader: boolean) {
  return APIPut(baseTournamentUrl + "/settings", params, useGlobalLoader);
}
