import { APIPut } from "api/protocols";

const apiVersion = "2021-01";
const adminType = "facility-admin";
const baseCartUrl = "/" + apiVersion + "/" + adminType + "/cart";

interface IPutCustomerCart {
  id: number;
  customer_id: number;
}

export async function PutCustomerCart(params: IPutCustomerCart, useGlobalLoader: boolean) {
  return await APIPut(baseCartUrl, params, useGlobalLoader);
}
