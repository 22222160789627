import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Input from "components/form/input/Input";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import { StatusCode } from "api/protocols";
import { useTranslation } from "react-i18next";
import { PostAccountingReference } from "api/rpc/2024-04/clientAdmin/client/accountingReference";

interface IAccountingReferenceState {
  title: string;
  reference_number: "";
}

export default function AccountingReferencesNew(props: any) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [accRefState, setAccRefState] = useState<IAccountingReferenceState>({
    title: "",
    reference_number: "",
  });

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setAccRefState(prevState => ({ ...prevState, [id]: value }));
  }

  async function saveNewAccountingRef() {
    console.log(accRefState);
    const res = await PostAccountingReference(accRefState, true);
    if (res.status !== StatusCode.OK) {
      return;
    }

    console.log(res);
    history.push("/admin/settings/accounting_references");
  }

  const primaryAction = {
    content: "Save", //TODO: Translate
    action: saveNewAccountingRef,
  };

  return (
    <Page
      title={"New Accounting Reference"} //TODO: Translate
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: "Accounting References", //TODO: Translate
          url: "/admin/settings/accounting_references",
        },
      ]}
    >
      <Form>
        <Card>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={accRefState.title}
                  label={"Title"} //TODO: Translate
                  id="title"
                  onChange={handleInputChange}
                  placeholder={"Title"} //TODO: Translate
                />
                <Input
                  value={accRefState.reference_number}
                  label={"Accounting Reference"} //TODO: Translate
                  id="reference_number"
                  onChange={handleInputChange}
                  placeholder={"Accounting Reference"} //TODO: Translate
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>
      </Form>
    </Page>
  );
}
