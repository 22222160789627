import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { StatusCode } from "api/protocols";
import Page from "components/page/Page";
import Card from "components/card/Card";
import { ButtonNew as Button } from "components/buttonNew";
import { IFacility } from "pages/guest/models/facility";
import { ILeague, TLeagueScoresheet } from "redux/reducers/models/league";
import { GetFacility } from "api/rpc/guest/facility";
import { GetLeague } from "api/rpc/guest/league";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { loadLeague, loadFacility } from "redux/actions/customer/leagueRegistration";
import { showError } from "redux/actions/ui";
import { GetLeagueScoresheets } from "api/rpc/2024-04/guest/league/league";
import LeagueHeader from "../LeagueHeader/LeagueHeader";
import { useTranslation } from "react-i18next";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import axios, { CancelToken } from "axios";

interface ILeagueScoresParams {
  facilityShortName: string;
  leagueHandle: string;
}

export default function LeagueScores() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { leagueRegistrationStore } = useAppSelector(store => store);
  const { facilityShortName, leagueHandle } = useParams<ILeagueScoresParams>();

  const [scoresheets, setScoresheets] = useState<TLeagueScoresheet[]>(undefined);

  useEffect(() => {
    if (!leagueRegistrationStore.facility) {
      void dispatch(loadFacility(facilityShortName, true));
    }
  }, []);

  useEffect(() => {
    if (leagueRegistrationStore.facility && !leagueRegistrationStore.league) {
      void dispatch(loadLeague(1, leagueHandle, true));
    }
  }, [leagueRegistrationStore.facility]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (leagueRegistrationStore.facility && leagueRegistrationStore.league) {
      void loadScoresheets(source.token);
    }
    return () => source.cancel();
  }, [leagueRegistrationStore.league]);

  async function loadScoresheets(token?: CancelToken) {
    const res = await GetLeagueScoresheets(
      {
        facility_id: leagueRegistrationStore.facility?.id,
        league_id: leagueRegistrationStore.league?.id,
      },
      token ? false : true,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading league scoresheets")); // TODO: Translation
      return;
    }

    setScoresheets(res.data);
  }

  function navigateToItem(pathEnding: string) {
    history.push(`/league/${facilityShortName}/${leagueHandle}/scoring/${pathEnding}`);
  }

  return (
    <>
      <LeagueHeader facility={leagueRegistrationStore.facility} league={leagueRegistrationStore.league} />
      {leagueRegistrationStore.facility && leagueRegistrationStore.league && (
        <Page title={"Scoresheets" /* TODO: Translation */}>
          <DataTable
            columns={[{ label: "Name" }, { label: "Date" }, { label: "Rounds Played" }]}
            loading={scoresheets === undefined}
          >
            {scoresheets?.map(scoresheet => (
              <tr key={scoresheet.id} onClick={() => navigateToItem(`results/${String(scoresheet.id)}`)}>
                <td>{scoresheet.name}</td>
                <td>{scoresheet.date_played}</td>
                <td>{scoresheet.participation}</td>
              </tr>
            ))}
          </DataTable>
        </Page>
      )}
    </>
  );
}
