import { useEffect, useRef } from "react";
import { useNodesInitialized } from "reactflow";

export function useAutoFocusInput() {
  const autoFocusInputRef = useRef<HTMLInputElement>(null);

  const nodesInitialized = useNodesInitialized();

  useEffect(() => {
    if (autoFocusInputRef?.current && nodesInitialized) {
      autoFocusInputRef.current.focus();
    }
  }, [autoFocusInputRef?.current, nodesInitialized]);

  return autoFocusInputRef;
}
