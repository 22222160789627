export interface ISearchHistory {
  [key: string]: {
    history: Set<string>;
    currentSearch: string;
  };
}

export interface ISearchHistoryAction {
  type: string;
  payload: {
    groupKey: string;
    value?: string;
  };
}
const initialState: ISearchHistory = {};

export default function SearchHistoryReducer(state = initialState, action: ISearchHistoryAction) {
  switch (action.type) {
    case "history.create":
      return {
        ...state,
        [action.payload.groupKey]: {
          history: new Set<string>(),
          currentSearch: action.payload.value,
        },
      };

    case "history.clear":
      return {
        ...state,
        [action.payload.groupKey]: {
          history: new Set<string>(),
          currentSearch: "",
        },
      };

    case "history.add": {
      const historyAdded = new Set(state[action.payload.groupKey].history);
      historyAdded.add(action.payload.value);
      return {
        ...state,
        [action.payload.groupKey]: {
          ...state[action.payload.groupKey],
          history: historyAdded,
          currentSearch: action.payload.value,
        },
      };
    }
    case "history.remove": {
      const historyDeleted = new Set(state[action.payload.groupKey].history);
      historyDeleted.delete(action.payload.value);
      return {
        ...state,
        [action.payload.groupKey]: {
          ...state[action.payload.groupKey],
          history: historyDeleted,
          currentSearch: state[action.payload.groupKey].currentSearch,
        },
      };
    }

    case "history.update":
      return {
        ...state,
        [action.payload.groupKey]: {
          ...state[action.payload.groupKey],
          currentSearch: action.payload.value,
        },
      };
    default:
      return state;
  }
}
