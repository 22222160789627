import { StatusCode } from "api/protocols";
import { GetProduct } from "api/rpc/2024-04/facilityAdmin/product/product";
import { CancelToken } from "axios";
import { cloneDeep } from "lodash";
import { AnyAction, Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { showError } from "redux/actions/ui";
import { IProduct } from "redux/reducers/models/product";
import { TSku } from "./NewInventoryCount";

/**
 *
 * @description Async function for searching products by SKU / Barcode and setting the product / skus states accordingly
 * @param products Array of inventory products selected
 * @param skusArray Array of skus to be searched
 * @param setProductLoad Set state function for displaying the spinners when calling the API
 * @param setProductState Set state function for setting the products in state
 * @param setSkuState  Set state function for setting the skus in state
 * @param dispatch
 * @param cancelToken API cancel token
 * @returns
 */
export async function findSkuProduct(
  products: Array<IProduct>,
  skusArray: Array<TSku>,
  setProductState: React.Dispatch<React.SetStateAction<{ selectedProducts: Array<IProduct> }>>,
  setSkuState: React.Dispatch<React.SetStateAction<{ skus: Array<TSku> }>>,
  dispatch: Dispatch<AnyAction> & ThunkDispatch<any, undefined, AnyAction>,
  cancelToken: CancelToken,
) {
  let updatedSelectedProducts = cloneDeep(products);
  const updatedSkus = cloneDeep(skusArray);
  //Filter out skus that have already been searched
  const skus = updatedSkus?.filter(filteredSku => !filteredSku?.searched)?.map(sku => sku.sku);
  if (skus.length === 0) {
    return;
  }

  const res = await GetProduct({ skus: skus, all: true, variants: true }, false, cancelToken);
  if (res.status !== StatusCode.OK) {
    if (cancelToken?.reason) {
      return;
    }
    dispatch(showError("Error searching products"));
    return;
  } else if (res.data && res?.data?.length > 0) {
    //Add found products to products list and remove the skus from skus list

    const skuProducts = [...res?.data];
    skuProducts?.forEach((product, productIndex) => {
      // Filter out variants that do not have matching skus/barcodes
      const filteredVariants = product?.variants?.filter(filteredVariant =>
        updatedSkus?.some(sku => sku.sku === filteredVariant?.sku || sku.sku === filteredVariant?.barcode),
      );
      filteredVariants?.forEach((variant, variantIndex) => {
        const foundSkuIndex = updatedSkus?.findIndex(sku => sku.sku === variant?.sku || sku.sku === variant?.barcode);
        //Variant with matching sku/barcode found, update the variant quantity and remove sku from skus list
        if (foundSkuIndex !== -1) {
          filteredVariants[variantIndex] = { ...variant, quantity: updatedSkus[foundSkuIndex].quantity };
          updatedSkus.splice(foundSkuIndex, 1);
        }
      });
      skuProducts[productIndex].variants = filteredVariants;
    });

    // Format the products
    updatedSelectedProducts = [...skuProducts, ...updatedSelectedProducts];
    const formattedProducts = updatedSelectedProducts?.reduce((productsArray: Array<IProduct>, product) => {
      const updatedProducts = [...productsArray];
      const productIndex = productsArray?.findIndex(currentProduct => currentProduct.id === product.id);
      if (productIndex !== -1) {
        // Product already in state, update its variants to include the ones returned from the API response
        updatedProducts[productIndex].variants = [...updatedProducts[productIndex].variants, ...product.variants];
      } else {
        //Product not in state, add it to the array
        updatedProducts.push(product);
      }
      return updatedProducts;
    }, []);
    setProductState(prevState => ({ ...prevState, selectedProducts: formattedProducts }));
  }
  //Skus not found, set searched status to true
  updatedSkus?.forEach((sku, index) => {
    updatedSkus[index] = { ...updatedSkus[index], searched: true };
  });
  setSkuState(prevState => ({ ...prevState, skus: updatedSkus }));
}
