import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { IFacility } from "redux/reducers/models/facility";

const apiVersion = "2024-04";
const adminType = "master-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/client/tee-sheet/booking-category";

interface IGetBookingCategory {
  id?: number;
  client_id?: number;
}

interface IPutBookingCategory {
  category_id: number;
  title: string;
}

interface IPostBookingCategory {
  client_id: number;
  title: string;
}

// GET Booking Categories
export async function GetBookingCategories(params: IGetBookingCategory, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseUrl, params, useGlobalLoader, token);
}

// PUT Booking Category
export async function PutBookingCategory(params: IPutBookingCategory, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

// POST Booking Category
export async function PostBookingCategory(params: IPostBookingCategory, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}
