import React, { useEffect, useState } from "react";

import { isPositiveInteger } from "helpers/Helpers";
import { isEqual } from "lodash";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";

import { IFloorPlan, PostFloorPlan } from "api/rpc/facilityAdmin/tables/tables";
import { StatusCode } from "api/protocols";

import Card from "components/card/Card";
import Page from "components/page/Page";
import Input from "components/form/input/Input";
import FormLayout from "components/form/FormLayout";
import { ButtonNew as Button } from "components/buttonNew/";
import { NotificationType } from "components/notificationBar/NotificationBar";

import { IUIActions } from "redux/actions/ui";

import "./floorPlanNew.scss";

interface IFloorPlanEditProps {
  uiActions: IUIActions;
}

interface IFloorPlanNewState {
  title: string;
  gridWidth: number;
  gridHeight: number;
}

export default function FloorPlanNew(props: IFloorPlanEditProps) {
  const { uiActions } = props;

  const { t, i18n } = useTranslation();

  const [floorPlanNewState, setFloorPlanNewState] = useState<IFloorPlanNewState>({
    title: "",
    gridWidth: 30,
    gridHeight: 30,
  });

  function isEmpty(value: string) {
    return value === "" || value === undefined || value === null;
  }

  async function handleSaveFloorPlan() {
    if (isEmpty(floorPlanNewState.title)) {
      uiActions.showError(t("secure.facility.settings.floor_plan.floor_plan_new.floor_plan_new.001"));
      return;
    }

    const newFloorPlanRes = await PostFloorPlan(
      {
        title: floorPlanNewState.title,
        grid_width: floorPlanNewState.gridWidth,
        grid_height: floorPlanNewState.gridHeight,
      },
      true,
    );

    if (newFloorPlanRes.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.settings.floor_plan.floor_plan_new.floor_plan_new.002"));
      return;
    }

    history.back();
  }

  const primaryAction = {
    content: t("secure.facility.settings.floor_plan.floor_plan_new.floor_plan_new.003"),
    action: handleSaveFloorPlan,
  };

  return (
    <Page
      title={t("secure.facility.settings.floor_plan.floor_plan_new.floor_plan_new.004")}
      narrow
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.floor_plan.floor_plan_new.floor_plan_new.005"),
          url: "/admin/settings/floor-plan",
        },
      ]}
      primaryAction={primaryAction}
    >
      <div>
        <Card>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  label={t("secure.facility.settings.floor_plan.floor_plan_new.floor_plan_new.006")}
                  value={floorPlanNewState.title}
                  onChange={(e: any) => setFloorPlanNewState(prev => ({ ...prev, title: e.target.value }))}
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>
      </div>
    </Page>
  );
}
