import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { StatusCode } from "api/protocols";

import { showError } from "redux/actions/ui";
import {
  TLeagueFlight,
  TLeagueTeamScores,
  TLeagueRoundFormat,
  TEventRound,
  TLeagueDivision,
} from "redux/reducers/models/league";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { loadLeague, loadFacility } from "redux/actions/customer/leagueRegistration";

import Page from "components/page/Page";
import { GetLeagueCompetitionFormat } from "api/rpc/2024-04/guest/league/scoring/format";
import { GetLeagueDivision } from "api/rpc/2024-04/guest/league/scoring/division";
import LeagueHeader from "../LeagueHeader/LeagueHeader";
import { GetRound, GetLeagueTeamScores, GetLeagueRoundHoleScores } from "api/rpc/2024-04/guest/league/scoring/round";
import FormLayout from "components/form/FormLayout";
import Tabs, { GenericTab } from "components/tabs/Tabs";
import { IndividualScoresTable } from "pages/secure/facility/events/league/scoring/scoresheets/results/tables/IndividualScoresTable";
import { TeamScoresTable } from "pages/secure/facility/events/league/scoring/scoresheets/results/tables/TeamScoresTable";
import { SkinsTable } from "pages/secure/facility/events/league/scoring/scoresheets/results/tables/SkinsTable";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";
import Spin from "components/spin/spin";
import { Select } from "components/select/";
import Callout from "components/callout/Callout";

interface ILeagueLeaderboardParams {
  scoresheetId: string;
  leagueHandle: string;
  facilityShortName: string;
}

interface ISortBy {
  /**Column to sort by. Default sorts the data by gross in ascending order */
  type: "default" | "gross" | "net" | "pts" | "overall";
  /**Ascending is true, Descending is false*/
  direction: boolean;
}

interface IState {
  rounds: Array<TEventRound>;
  teamScores: Array<TLeagueTeamScores>;
  divisions: Array<TLeagueDivision>;
  flights: Array<TLeagueFlight>;
  currentFlightId: number;
  sortBy: ISortBy;
  currentFormat: TLeagueRoundFormat;
  selectedRoundId: number;
  holeScoreRounds: Array<TEventRound>;
  selectedDivisionId: number;
}

export default function LeagueLeaderboard(props: any) {
  const { scoresheetId, leagueHandle, facilityShortName } = useParams<ILeagueLeaderboardParams>();
  const dispatch = useAppDispatch();
  const { leagueRegistrationStore } = useAppSelector(store => store);
  const { t, i18n } = useTranslation();

  const [formats, setFormats] = useState<TLeagueRoundFormat[]>(undefined);
  const [tabs, setTabs] = useState<GenericTab[]>([]);

  const [divisions, setDivisions] = useState<TLeagueDivision[]>(undefined);
  const [selectedDivisionId, setSelectedDivisionId] = useState<number>(undefined);

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [selectedFormat, setSelectedFormat] = useState<TLeagueRoundFormat>(undefined);

  const [rounds, setRounds] = useState<TEventRound[]>(undefined);
  const [holeScoreRounds, setHoleScoreRounds] = useState<TEventRound[]>(undefined);
  const [selectedRoundId, setSelectedRoundId] = useState<number>(undefined);
  const [teamScores, setTeamScores] = useState<TLeagueTeamScores[]>(undefined);

  useEffect(() => {
    if (!leagueRegistrationStore.facility) {
      void dispatch(loadFacility(facilityShortName, true));
    }
  }, []);

  useEffect(() => {
    if (leagueRegistrationStore.facility && !leagueRegistrationStore.league) {
      void dispatch(loadLeague(leagueRegistrationStore.facility.id, leagueHandle, true));
    }
    const source = axios.CancelToken.source();
    void loadDivisions(source.token);
    return () => source.cancel();
  }, [leagueRegistrationStore.facility]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadFormats(source.token);
    return () => source.cancel();
  }, [selectedDivisionId]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (selectedFormat?.organization === "team") {
      void loadTeamScores(source.token);
    } else {
      void loadRounds(source.token);
    }
    if (selectedFormat?.format === "skins") {
      void loadRoundHoleScores(source.token);
    }
    return () => source.cancel();
  }, [selectedFormat]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (selectedRoundId) {
      void loadRoundHoleScores(source.token);
    }
    return () => source.cancel();
  }, [selectedRoundId]);

  async function loadDivisions(token?: CancelToken) {
    const divisionsRes = await GetLeagueDivision(
      {
        facility_id: leagueRegistrationStore.facility?.id,
        league_handle: leagueHandle,
      },
      true,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (divisionsRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading divisions"));
      return;
    }

    setDivisions(divisionsRes.data);
  }

  async function loadFormats(token: CancelToken) {
    if (!selectedDivisionId) {
      return;
    }

    const res = await GetLeagueCompetitionFormat(
      {
        league_division_id: selectedDivisionId,
        league_scoresheet_id: scoresheetId,
      },
      token ? false : true,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading scoresheet formats")); // TODO: Translation
      return;
    }

    const formatTabs = res.data.map((format: TLeagueRoundFormat) => {
      return {
        content: format.title,
      };
    });

    setTabs(formatTabs);
    setSelectedFormat(res.data[0]);
    setFormats(res.data);
  }

  async function loadRounds(token?: CancelToken) {
    if (!selectedFormat) {
      return;
    }

    const roundsRes = await GetRound(
      {
        scoresheet_id: Number(scoresheetId),
        format_id: selectedFormat.id,
      },
      false,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (roundsRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.league.league_leaderboard.001")));
      return;
    }

    const sortedRounds = roundsRes.data?.sort((currentRound: any, nextRound: any) => {
      if (!currentRound?.round?.gross_score) {
        return 1;
      } else if (!nextRound?.round?.gross_score) {
        return -1;
      } else {
        return currentRound?.round?.gross_score - nextRound?.round?.gross_score;
      }
    });

    setRounds(sortedRounds);
  }

  async function loadTeamScores(token?: CancelToken) {
    if (!selectedFormat) {
      return;
    }

    const scoreRes = await GetLeagueTeamScores(
      {
        scoresheet_id: Number(scoresheetId),
        format_id: selectedFormat.id,
      },
      false,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (scoreRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading team scores"));
      return;
    }

    setTeamScores(scoreRes.data);
  }

  async function loadRoundHoleScores(token?: CancelToken) {
    if (!selectedFormat) {
      return;
    }

    const holeScoreRes = await GetLeagueRoundHoleScores(
      {
        format_id: selectedFormat.id,
        round_id:
          selectedFormat.organization === "individual" && selectedFormat.format !== "skins" ? selectedRoundId : null,
        team_id: selectedFormat.organization === "team" && selectedFormat.format !== "skins" ? selectedRoundId : null,
      },
      false,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (holeScoreRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading round hole scores")); // TODO: Translation
      return;
    }

    if (selectedFormat.organization === "team" && selectedFormat.format !== "skins") {
      void loadRounds(token);
    }

    setHoleScoreRounds(holeScoreRes.data);
  }

  function handleTabChange(selectedTabIndex: number) {
    if (selectedTabIndex === selectedTab) {
      return;
    }

    const format = formats[selectedTabIndex];

    setHoleScoreRounds(null);
    setSelectedRoundId(null);
    setRounds(null);
    setTeamScores(null);

    setSelectedFormat(format);
    setSelectedTab(selectedTabIndex);
  }

  function handleClickRound(roundId: number) {
    setHoleScoreRounds(null);
    setSelectedRoundId(roundId);
  }

  return (
    <>
      <LeagueHeader facility={leagueRegistrationStore.facility} league={leagueRegistrationStore.league} />
      {leagueRegistrationStore.facility && leagueRegistrationStore.league && (
        <Page
          title={"Weekly Leaderboards"} // TODO: Translation
          breadcrumbs={[
            {
              prefix: true,
              label: "Scoresheets", // TODO: Translation
              url: `/league/${facilityShortName}/${leagueHandle}/scoring`,
            },
          ]}
        >
          {divisions && (
            <FormLayout>
              <FormLayout.Group>
                <Select
                  label="Division"
                  onChange={(value: number) => {
                    setSelectedTab(0);
                    setSelectedDivisionId(value);
                    setHoleScoreRounds(null);
                    setSelectedRoundId(null);
                    setRounds(null);
                    setTeamScores(null);
                  }}
                  defaultValue={divisions[0]?.id}
                >
                  {divisions?.map(division => (
                    <Select.Option key={division.id} value={division.id}>
                      {division.title}
                    </Select.Option>
                  ))}
                </Select>
                <div />
                <div />
              </FormLayout.Group>
            </FormLayout>
          )}
          {formats?.length > 0 ? (
            <>
              <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange}></Tabs>
              {selectedFormat?.format !== "skins" &&
                (selectedFormat?.organization === String("individual") ? (
                  <>
                    {rounds ? (
                      <IndividualScoresTable
                        format={selectedFormat}
                        rounds={rounds}
                        flights={[]}
                        holeScoreRound={holeScoreRounds ? holeScoreRounds[0] : null}
                        selectedRoundId={selectedRoundId}
                        onClick={handleClickRound}
                      />
                    ) : (
                      <div style={{ height: "24px" }}>
                        <Spin />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {teamScores ? (
                      <TeamScoresTable
                        format={selectedFormat}
                        teamScores={teamScores}
                        rounds={holeScoreRounds}
                        formatRounds={rounds}
                        selectedRoundId={selectedRoundId}
                        onClick={handleClickRound}
                      />
                    ) : (
                      <div style={{ height: "24px" }}>
                        <Spin />
                      </div>
                    )}
                  </>
                ))}
              {selectedFormat?.format === "skins" && (
                <div>
                  {holeScoreRounds ? (
                    <SkinsTable format={selectedFormat} holeScoreRounds={holeScoreRounds} rounds={rounds} />
                  ) : (
                    <div style={{ height: "24px" }}>
                      <Spin />
                    </div>
                  )}
                </div>
              )}
            </>
          ) : (
            <Callout
              type="info"
              title="No Formats"
              content="There are no formats for the selected division" /* TODO: Translation */
            />
          )}
        </Page>
      )}
    </>
  );
}
