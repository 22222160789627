import React, { useEffect, useState } from "react";
import "./reservationsLogin.scss";
import { useHistory, useParams } from "react-router";
import { UserLogin } from "api/rpc";
import { StatusCode } from "api/protocols";
import Input from "components/form/input/index";
import { ButtonNew } from "components/buttonNew";
import { IUIActions } from "redux/actions/ui";
import { CreateAccount } from "api/rpc/bookingEngine/bookingEngine";
import validator from "validator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IReservationState } from "redux/reducers/reservations";
import { IReservationActions } from "redux/actions/reservations";
import { PostPasswordResetCode } from "api/rpc/2022-09/passwordReset";

interface IRegisterState {
  email: string;
  password: string;
  phone: string;
  first_name: string;
  last_name: string;
}

interface IValidatorState {
  email: boolean;
  password: boolean;
  phone: boolean;
  first_name: boolean;
  last_name: boolean;
  emailInUse: boolean;
}

interface IProps {
  uiActions: IUIActions;
  reservationStore: IReservationState;
  reservationActions: IReservationActions;
}
interface IParams {
  facilityShortName: string;
  moduleHandle: string;
}

export default function ReservationsRegister(props: IProps) {
  const history = useHistory();
  const { facilityShortName, moduleHandle } = useParams<IParams>();
  const { uiActions, reservationStore, reservationActions } = props;

  const queryString = window.location.search;

  const locationId = Number(new URLSearchParams(queryString).get("location_id"));
  const date = new URLSearchParams(queryString).get("date");
  const startTime = new URLSearchParams(queryString).get("start_time");

  const [registerState, setRegisterState] = useState<IRegisterState>({
    email: "",
    password: "",
    phone: "",
    first_name: "",
    last_name: "",
  });

  const [validatorState, setValidatorState] = useState<IValidatorState>({
    email: false,
    password: false,
    phone: false,
    first_name: false,
    last_name: false,
    emailInUse: false,
  });

  function handleEnterKeydown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      void handleRegistration();
    }
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setRegisterState(prevState => ({ ...prevState, [name]: value }));
  }

  function handleNavigateToLogin() {
    if (!locationId || !date || !startTime) {
      history.push(`/${facilityShortName}/${moduleHandle}/login`);
    } else {
      history.push(
        `/${facilityShortName}/${moduleHandle}/login?start_time=${startTime}&date=${date}&location_id=${locationId}`,
      );
    }
  }

  async function handleRegistration() {
    const params = {
      first_name: registerState.first_name,
      last_name: registerState.last_name,
      phone: registerState.phone,
      email: registerState.email,
      password: registerState.password,
    };

    if (
      !registerState.first_name ||
      !registerState.last_name ||
      !registerState.phone ||
      !registerState.email ||
      !registerState.password
    ) {
      uiActions.showError("Please fill out all fields");
      return;
    }

    const invalidFirstName = !validator.isAlpha(params.first_name);
    const invalidLastName = !validator.isAlpha(params.last_name);
    const invalidEmail = !validator.isEmail(params.email);
    const invalidPhoneNumber = !validator.isMobilePhone(params.phone, "en-CA");
    const invalidPassword = params.password.length < 8;

    setValidatorState(prevState => ({
      ...prevState,
      first_name: invalidFirstName,
      last_name: invalidLastName,
      email: invalidEmail,
      phone: invalidPhoneNumber,
      password: invalidPassword,
    }));

    if (invalidFirstName || invalidLastName || invalidEmail || invalidPhoneNumber || invalidPassword) {
      if (invalidEmail) {
        setValidatorState(prevState => ({ ...prevState, emailInUse: false }));
      }
      return;
    }

    const registerRes = await CreateAccount(params, true);

    if (registerRes.status !== StatusCode.OK) {
      if (registerRes.data?.message === "The email has already been taken.") {
        setValidatorState(prevState => ({ ...prevState, emailInUse: true }));
      } else {
        uiActions.showError(registerRes.data?.message);
      }
      return;
    }

    void handleLogin();
  }

  async function handleLogin() {
    const params = {
      email: registerState.email,
      password: registerState.password,
    };

    const loginRes = await UserLogin(params, true);

    if (loginRes.status !== StatusCode.OK) {
      uiActions.showError("Invalid Credentials, please try again");
      return;
    }

    reservationActions.update({ active_user: loginRes?.data?.user });
    uiActions.showSuccess("Logged in successfully");

    if (!locationId || !date || !startTime) {
      history.push(`/${facilityShortName}/${moduleHandle}`);
    } else {
      history.push(
        `/${facilityShortName}/${moduleHandle}/booking?start_time=${startTime}&date=${date}&location_id=${locationId}`,
      );
    }
  }

  async function handlePasswordReset() {
    if (registerState.email === undefined || !validator.isEmail(registerState.email)) {
      uiActions.showError("Please enter a valid email for recovery");
      return;
    }

    const resetRes = await PostPasswordResetCode(
      { email: registerState.email, facility_id: reservationStore?.module?.facility_id ?? undefined },
      true,
    );

    if (resetRes.status !== StatusCode.OK) {
      props.uiActions.showError(resetRes.data.message);
      return;
    }
    uiActions.showSuccess("Email invite sent successfully!");

    history.push(`/${facilityShortName}/${moduleHandle}/login`);
  }

  return (
    <>
      <div className="reservations-login">
        <div className="reservations-login-header-container">
          <div className="reservations-login-header-container-centered">
            <div className="reservations-login-header-container-inner">
              <img src="../../../public/images/Tee-On.png" alt="Tee-On Logo" />
            </div>
          </div>
        </div>

        <div className="reservations-login-container">
          <div className="reservations-login-container-inner">
            <div className="reservations-login-container-group">
              <div className="reservations-login-text-group">
                <p className="sign-in-text">Create Account</p>
                <br />
                <p className="sign-in-info">Please sign in or create an account to continue with this reservation</p>
              </div>

              {validatorState.first_name ||
              validatorState.last_name ||
              validatorState.email ||
              validatorState.phone ||
              validatorState.password ||
              validatorState.emailInUse ? (
                <div className="reservations-register-error-container">
                  {validatorState.first_name && (
                    <div className="flex flex-row items-center mb-2">
                      <div style={{ display: "flex" }}>
                        <FontAwesomeIcon
                          icon={["fas", "exclamation-triangle"]}
                          className="reservations-register-icon"
                        />
                      </div>
                      <p>First name cannot contain numbers</p>
                    </div>
                  )}
                  {validatorState.last_name && (
                    <div className="flex flex-row items-center mb-2">
                      <div style={{ display: "flex" }}>
                        <FontAwesomeIcon
                          icon={["fas", "exclamation-triangle"]}
                          className="reservations-register-icon"
                        />
                      </div>
                      <p>Last name cannot contain numbers</p>
                    </div>
                  )}
                  {validatorState.email && (
                    <div className="flex flex-row items-center mb-2">
                      <div style={{ display: "flex" }}>
                        <FontAwesomeIcon
                          icon={["fas", "exclamation-triangle"]}
                          className="reservations-register-icon"
                        />
                      </div>
                      <p>Email is not in the correct format</p>
                    </div>
                  )}
                  {validatorState.phone && (
                    <div className="flex flex-row items-center mb-2">
                      <div style={{ display: "flex" }}>
                        <FontAwesomeIcon
                          icon={["fas", "exclamation-triangle"]}
                          className="reservations-register-icon"
                        />
                      </div>
                      <p>Phone number is not in the correct format</p>
                    </div>
                  )}
                  {validatorState.password && (
                    <div className="flex flex-row items-center mb-2">
                      <div style={{ display: "flex" }}>
                        <FontAwesomeIcon
                          icon={["fas", "exclamation-triangle"]}
                          className="reservations-register-icon"
                        />
                      </div>
                      <p>Password must be at least 8 characters long</p>
                    </div>
                  )}
                  {validatorState.emailInUse && (
                    <div>
                      <div className="flex flex-row items-center mb-2">
                        <div style={{ display: "flex" }}>
                          <FontAwesomeIcon
                            icon={["fas", "exclamation-triangle"]}
                            className="reservations-register-icon"
                          />
                        </div>
                        <p>
                          It looks like an account has already been created for you. Please click the &apos;Resend
                          Invite&apos; below and check your inbox and junk folder to set a password for your account.
                        </p>
                      </div>

                      <button onClick={handlePasswordReset} className="reservations-register-resend-invite">
                        RESEND INVITE{" "}
                        <FontAwesomeIcon icon={["fal", "paper-plane"]} className="reservations-register-icon" />
                      </button>
                    </div>
                  )}
                </div>
              ) : null}

              <div className="reservations-register-name-group">
                <div className="reservations-login-input-group width-100">
                  <label className="reservations-login-input-label" htmlFor="first_name">
                    First Name
                  </label>
                  <Input
                    value={registerState.first_name}
                    onChange={handleInputChange}
                    type="text"
                    labelHidden
                    onKeyDown={handleEnterKeydown}
                    name="first_name"
                  />
                </div>
                <div className="reservations-login-input-group width-100">
                  <label className="reservations-login-input-label" htmlFor="last_name">
                    Last Name
                  </label>
                  <Input
                    value={registerState.last_name}
                    onChange={handleInputChange}
                    type="text"
                    labelHidden
                    onKeyDown={handleEnterKeydown}
                    name="last_name"
                  />
                </div>
              </div>

              <div className="reservations-login-input-group">
                <label className="reservations-login-input-label" htmlFor="username">
                  Email Address
                </label>
                <Input
                  value={registerState.email}
                  onChange={handleInputChange}
                  type="email"
                  labelHidden
                  onKeyDown={handleEnterKeydown}
                  name="email"
                />
              </div>

              <div className="reservations-login-input-group">
                <label className="reservations-login-input-label" htmlFor="phone_number">
                  Phone Number
                </label>
                <Input
                  value={registerState.phone}
                  onChange={handleInputChange}
                  labelHidden
                  onKeyDown={handleEnterKeydown}
                  name="phone"
                />
              </div>

              <div className="reservations-login-input-group">
                <label className="reservations-login-input-label" htmlFor="password">
                  Password
                </label>
                <Input.Password
                  value={registerState.password}
                  onChange={handleInputChange}
                  name="password"
                  onKeyDown={handleEnterKeydown}
                />
              </div>
            </div>

            <div>
              <ButtonNew type="primary" block onClick={handleRegistration} className="reservations-register-button">
                CREATE ACCOUNT
              </ButtonNew>
            </div>

            <div className="reservations-register-login-group">
              <p className="reservations-register-text">Already have an account?</p>
              <p
                className="reservations-register-login-text reservations-register-text"
                onClick={handleNavigateToLogin}
              >
                Log In
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
