import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useHistory, useParams } from "react-router";
import "pages/guest/Customer/TeeTimeLogin.scss";
import { UserLogin } from "api/rpc";
import { StatusCode } from "api/protocols";
import { GetTeeTime, GetTeeTimeGuest, GetBookingEngine, LockTeeTimeById } from "api/rpc/2022-09/guest/bookingEngine";
import { IBookingEngineState } from "redux/reducers/bookingEngine";
import { IBookingEngineActions } from "redux/actions/actionTypes";
import { convertTime } from "helpers/Helpers";
import Input from "components/form/input/Input";
import { useTranslation } from "react-i18next";

export interface ISignInInfo {
  email: string;
  password: string;
  state: string;
  signInActive: boolean;
}

export interface IFilterState {
  selectedCourseLogo: any;
  selectedCourseName: any;
  selectedHeaderBackground: any;
  weather: any;
}

interface ITeeTimeSignIn {
  bookingEngineStore: IBookingEngineState;
  bookingEngineActions: IBookingEngineActions;
}

interface IParams {
  bookingEngineHandle: string;
}

const TeeTimeSignIn: React.FC<ITeeTimeSignIn> = props => {
  const { bookingEngineHandle } = useParams<IParams>();
  const { bookingEngineStore, bookingEngineActions } = props;

  console.log(bookingEngineStore);

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const tempGolfCourseName = bookingEngineHandle.split("-");
  const golfCourseName = String(tempGolfCourseName[0]);
  const handle = String(bookingEngineHandle);

  const [signInInfo, setSignInInfo] = useState<ISignInInfo>({
    email: "",
    password: "",
    state: "",
    signInActive: false,
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    selectedCourseName: bookingEngineStore.selectedCourseLongName,
    selectedCourseLogo: bookingEngineStore.selectedCourseLogo,
    selectedHeaderBackground: "https://www.foresightgolf.net/images/template/banner-cypresswood.jpg",
    weather: null,
  });

  useEffect(() => {
    if (
      bookingEngineStore.selectedAddress === "" &&
      bookingEngineStore.selectedDate === "" &&
      bookingEngineStore.selectedLoginRequired === null &&
      bookingEngineStore.selectedTeeTimeId === ""
    ) {
      document.addEventListener("keydown", logKey);

      void getBookingEngine();

      //Fill in state with missing info lost on refresh
    } else {
      console.log("Data in store");
      updateFilterState({
        selectedCourseName: bookingEngineStore.selectedCourseLongName,
        selectedCourseLogo: bookingEngineStore.selectedCourseLogo,
      });
      document.addEventListener("keydown", logKey);
    }
  }, []);

  function logKey(e: KeyboardEvent) {
    if (e.code === "Enter") {
      document.getElementById("submit-button").click();
    }
  }

  async function getBookingEngine() {
    const params = {
      handle: bookingEngineHandle,
    };

    console.log(params);

    const bookingEngineRes = await GetBookingEngine(params, true);
    console.log(bookingEngineRes);

    if (bookingEngineRes.status !== StatusCode.OK) {
      alert(bookingEngineRes.message);
      return;
    }

    console.log(bookingEngineRes);

    const fullAddress = `${bookingEngineRes.data[0]?.facility?.address_line_1}, ${bookingEngineRes.data[0]?.facility?.city},
    ${bookingEngineRes.data[0]?.facility?.province_name}, ${bookingEngineRes.data[0]?.facility?.postal}`;

    updateFilterState({
      selectedCourseName: bookingEngineRes.data[0].facility.long_name,
      selectedCourseLogo: bookingEngineRes.data[0].facility.logo_source,
    });

    console.log(bookingEngineRes.data[0].long_name);

    bookingEngineActions.update({
      selectedCourseLogo: bookingEngineRes.data[0].facility.logo_source,
      selectedHeaderBackground: filterState.selectedHeaderBackground,
      selectedCourseFacility: bookingEngineRes.data[0].facility.long_name,
      selectedCourseLongName: bookingEngineRes.data[0].facility.long_name,
      selectedLoginRequired: bookingEngineRes.data[0].requires_login,
      selectedFacilityId: bookingEngineRes.data[0].facility_id,
      selectedBookingEngineId: bookingEngineRes.data[0].id,
      selectedBookingTerms: bookingEngineRes.data[0].booking_terms,
      paymentTerms: bookingEngineRes.data[0].payment_terms,
      activeCustomerId: null,
      enabled_payment_methods: bookingEngineRes.data[0].enabled_payment_methods,
      courseLogo: bookingEngineRes.data[0]?.facility.logo_source,
      selectedFullAddress: fullAddress,
    });
  }

  function updateSignInInfo(newFilterState: Partial<ISignInInfo>) {
    setSignInInfo(cur => {
      return { ...cur, ...newFilterState };
    });
  }

  function updateFilterState(newFilterState: Partial<IFilterState>) {
    setFilterState(cur => {
      return { ...cur, ...newFilterState };
    });
  }

  //Updates login email as user types
  function updateEmail(event: React.ChangeEvent<HTMLInputElement>) {
    updateSignInInfo({ email: event.target.value });
  }

  //Updates login email as user types
  function updatePassword(event: React.ChangeEvent<HTMLInputElement>) {
    updateSignInInfo({ password: event.target.value });
  }

  function sendToNextPage(address: string) {
    history.push(address);
  }

  //Function to format date to readable string
  function formatDateString(date: any) {
    const tempDateArray = date.split("-");

    //Get tee time day
    const tempDay = tempDateArray[2];

    //Get tee time year
    const tempYear = tempDateArray[0];

    //Get tee time month
    const tempMonth = tempDateArray[1] - 1;

    //Get full date for tee time
    const d = new Date(tempYear, tempMonth, tempDay);

    const month = d.toLocaleString("en-us", { month: "long" });

    const day = d.toLocaleString("en-us", { day: "numeric" });

    const year = d.toLocaleString("en-us", { year: "numeric" });

    const weekday = d.toLocaleString("en-us", { weekday: "long" });

    const dateString = weekday + " " + month + " " + day + ", " + year;

    return dateString;
  }

  function sendToRegistration() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const date = urlParams.get("date");
    const teeTimeId = urlParams.get("id");

    const registrationAddress =
      "/booking-engine/" + golfCourseName + "/" + handle + "/register?id=" + teeTimeId + "&date=" + date;

    sendToNextPage(registrationAddress);
  }

  //Confirm sign in success / Check Tee Time Availability
  async function confirmSignIn() {
    const username = signInInfo.email;
    const password = signInInfo.password;

    //Validate user login
    console.log(username, password);

    const params = {
      email: username,
      password: password,
    };

    const loginRes = await UserLogin(params, true);

    if (loginRes.status !== StatusCode.OK) {
      updateSignInInfo({ state: t("guest.customer.tee_time_login.001"), signInActive: false });
      alert(t("guest.customer.tee_time_login.002"));
      return;
    }

    console.log(loginRes);

    updateSignInInfo({ signInActive: true });
    bookingEngineActions.update({
      selectedUserFirstName: loginRes.data.user.first_name,
      userFullName: loginRes.data.user.full_name,
      selectedUserLastName: loginRes.data.user.last_name,
      selectedUserEmail: loginRes.data.user.email,
      selectedUserPhone: loginRes.data.user.phone,
    });

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const date = urlParams.get("date");
    const teeTimeId = urlParams.get("id");
    const teetimeSelectionAddress = "/booking-engine/" + golfCourseName + "/" + handle;

    if (date === null || teeTimeId === null) {
      sendToNextPage(teetimeSelectionAddress);
      return;
    }

    const teeInformationByIdRes = await GetTeeTime(
      {
        id: Number(teeTimeId),
        date: date,
        booking_engine_handle: bookingEngineHandle,
        turn_tee_time: true,
        extended: true,
      },
      true,
    );

    if (teeInformationByIdRes.status !== StatusCode.OK) {
      alert(t("guest.customer.tee_time_login.003"));
      return;
    }

    if (teeInformationByIdRes.data.length === 0) {
      alert(t("guest.customer.tee_time_login.004"));

      sendToNextPage(teetimeSelectionAddress);
      return;
    }

    //Check if Tee Time Id chosen by user matches available Tee Times Id
    if (teeInformationByIdRes.data[0].id === Number(teeTimeId)) {
      console.log("Tee Time Is Available");

      const tempId = String(teeTimeId);
      const tempDate = String(date);

      const tempStartTime = convertTime(teeInformationByIdRes.data[0].start_time);
      console.log(tempStartTime);

      const tempFormatDate = formatDateString(tempDate);

      bookingEngineActions.update({
        selectedDate: teeInformationByIdRes.data[0].date,
        selectedAddress: teeInformationByIdRes.data[0].facility.address_line_1,
        selectedTeeTimeId: teeInformationByIdRes.data[0].id,
        selectedCity: teeInformationByIdRes.data[0].facility.city,
        selectedCourseName: teeInformationByIdRes.data[0].facility.full_name,
        selectedCourseLongName: teeInformationByIdRes.data[0].facility.long_name,
        selectedPostalCode: teeInformationByIdRes.data[0].facility.postal,
        selectedProvince: teeInformationByIdRes.data[0].facility.province_name,
        selectedGolferSpotsRemaining: teeInformationByIdRes.data[0].quantity_remaining,
        selectedStartHole: teeInformationByIdRes.data[0].start_hole,
        selectedStartTime: tempStartTime,
        selectedFacilityId: teeInformationByIdRes.data[0].facility.id,
        selectedFormattedDate: tempFormatDate,
        facilityClientId: teeInformationByIdRes.data[0].facility.client_id,
        creditCardRequired: teeInformationByIdRes.data[0].credit_card_required,
        prepaidRequired: teeInformationByIdRes.data[0].pre_paid_required,
        activeCustomerId: loginRes.data.user.id,
        cart_rule: teeInformationByIdRes.data[0].cart_rule,
        divisionsAvailable: teeInformationByIdRes.data[0].divisions_available,
        turnTeeTime: teeInformationByIdRes.data[0].turn_tee_time,
      });

      console.log(bookingEngineStore);

      //If not null - User has access to locked teetime
      if (teeInformationByIdRes.data[0].lock !== null) {
        console.log(teeInformationByIdRes.data[0].lock);

        const tempCurrentDate = new Date().toUTCString();
        const currentTempTime = new Date(tempCurrentDate);
        const currentTime = new Date(currentTempTime).getTime();

        const tempString = String(teeInformationByIdRes.data[0].lock.start_time) + "Z";
        console.log(tempString);

        const lockStartDate = new Date(tempString);
        console.log(lockStartDate);

        const tempExpiryString = String(teeInformationByIdRes.data[0].lock.expire_time) + "Z";
        const lockoutTempExpiry = new Date(tempExpiryString);

        const lockStartTime = lockStartDate.getTime();
        const lockoutExpiryTime = lockoutTempExpiry.getTime();

        const lockoutDuration = lockoutExpiryTime - currentTime;

        bookingEngineActions.update({
          selectedLockoutDuration: lockoutDuration,
          selectedLockoutStartTime: lockStartTime,
          selectedLockoutExpireTime: teeInformationByIdRes.data[0].lock.expire_time,
        });

        const holeSelectionAddress =
          "/booking-engine/" + golfCourseName + "/" + handle + "/holes" + "?id=" + tempId + "&date=" + tempDate;

        sendToNextPage(holeSelectionAddress);
      } else {
        const bookingLockRes = await LockTeeTimeById({ tee_time_id: teeInformationByIdRes.data[0].id }, true);
        console.log(bookingLockRes);

        if (bookingLockRes.status !== StatusCode.OK) {
          alert(t("guest.customer.tee_time_login.005"));
          console.log("Error with booking locking");
          return;
        }

        //Locked out message
        const bookingLockMessage = t("guest.customer.tee_time_login.006");
        const bookingLockResMessage = bookingLockRes.data.message;

        if (bookingLockMessage === bookingLockResMessage) {
          alert(t("guest.customer.tee_time_login.007"));
          console.log("Booking is locked by another user");
          return;
        }

        const tempCurrentDate = new Date().toUTCString();
        const currentTempTime = new Date(tempCurrentDate);
        const currentTime = new Date(currentTempTime).getTime();

        const tempString = String(bookingLockRes.data.start_time) + "Z";
        console.log(tempString);

        const lockStartDate = new Date(tempString);
        console.log(lockStartDate);

        const tempExpiryString = String(bookingLockRes.data.expire_time) + "Z";
        const lockoutTempExpiry = new Date(tempExpiryString);

        const lockStartTime = lockStartDate.getTime();
        const lockoutExpiryTime = lockoutTempExpiry.getTime();

        const lockoutDuration = lockoutExpiryTime - currentTime;

        bookingEngineActions.update({
          selectedLockoutDuration: lockoutDuration,
          selectedLockoutStartTime: lockStartTime,
          selectedLockoutExpireTime: bookingLockRes.data.expire_time,
        });

        const holeSelectionAddress =
          "/booking-engine/" + golfCourseName + "/" + handle + "/holes" + "?id=" + tempId + "&date=" + tempDate;

        sendToNextPage(holeSelectionAddress);
      }
    } else {
      console.log("Tee Time Unavailable");
      alert(t("guest.customer.tee_time_login.008"));

      const teetimeSelectionAddress = "/booking-engine/" + golfCourseName + "/" + handle;

      sendToNextPage(teetimeSelectionAddress);
    }
  }

  function sendToForgotPassword() {
    const registrationAddress = "/booking-engine/" + golfCourseName + "/" + handle + "/account/forgot";

    window.open(registrationAddress);
  }

  return (
    <div>
      <div className="ttl-header-container">
        <span className="ttl-bars-icon-div">
          <img className="ttl-tee-on-logo" src="../../../public/images/Tee-On.png" alt="Tee-On Logo" />
        </span>
        <span>
          <p style={{ fontSize: "22px", fontWeight: 700, fontStyle: "italic", marginLeft: "22px" }}>
            {filterState.selectedCourseName}
          </p>
        </span>
      </div>

      <br />

      <div className="tt-login-container">
        <div className="ttl-sign-in-text">
          <p className="sign-in-p">{t("guest.customer.tee_time_login.009")}</p>
          <p className="sign-in-info-p">{t("guest.customer.tee_time_login.010")}</p>
        </div>

        <div className="tt-sign-in-email-password-container">
          <Input label={t("guest.customer.tee_time_login.014")} value={signInInfo.email} onChange={updateEmail} />
          <Input
            label={t("guest.customer.tee_time_login.015")}
            value={signInInfo.password}
            onChange={updatePassword}
            type="password"
          />
        </div>

        <div className="forgot-password-container">
          <button className="forgot-password-button" onClick={sendToForgotPassword}>
            {t("guest.customer.tee_time_login.011")}
          </button>
        </div>

        <div className="sign-in-register-container">
          <button id="submit-button" className="sign-in-button" onClick={confirmSignIn}>
            {t("guest.customer.tee_time_login.012")}
          </button>
          <button className="register-button" onClick={sendToRegistration}>
            {t("guest.customer.tee_time_login.013")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TeeTimeSignIn;
