export interface ITeeSheetProductState {
  greenFeeItems: any;
  powerCartItems: any;
}

export interface ITeeSheetProductAction {
  type: string;
  payload: ITeeSheetProductState;
}

const initialState: ITeeSheetProductState = {
  greenFeeItems: null,
  powerCartItems: null,
};

export default function TeeSheetProductReducer(state = initialState, action: ITeeSheetProductAction) {
  switch (action.type) {
    case "update.teeSheetProducts":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
