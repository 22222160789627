import { StatusCode } from "api/protocols";
import { GetFacility } from "api/rpc/guest/facility";
import { GetTournament } from "api/rpc/guest/tournament";
import { IFacility } from "pages/guest/models/facility";
import { AppDispatch } from "index";
import { ISelectedTournamentRegistrationFee } from "redux/reducers/customer/tournamentRegistration";
import { ITournament } from "redux/reducers/models/tournament";

export function loadFacility(
  facilityShortName: string,
  useGlobalLoader: boolean,
): (dispatch: any) => Promise<IFacility> {
  return async (dispatch: AppDispatch) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      GetFacility({ short_name: facilityShortName }, useGlobalLoader).then(facilityRes => {
        const facilityId = facilityRes?.data?.[0]?.id;

        if (facilityRes.status !== StatusCode.OK || facilityId === undefined) {
          reject(undefined);
        } else {
          const facility = facilityRes.data[0];

          dispatch({
            type: "tournamentRegistration.update",
            payload: {
              facility,
            },
          });

          resolve(facility);
        }
      });
    });
  };
}

export function loadTournament(facilityId: number, handle: string, useGlobalLoader: boolean) {
  return async (dispatch: AppDispatch) => {
    return new Promise((resolve: (value: ITournament) => void, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      GetTournament({ facility_id: facilityId, handle: handle }, useGlobalLoader).then(tournamentRes => {
        if (tournamentRes.status !== StatusCode.OK) {
          reject(undefined);
        } else {
          const tournament = tournamentRes.data?.[0];

          dispatch({
            type: "tournamentRegistration.update",
            payload: {
              tournament,
            },
          });

          resolve(tournament);
        }
      });
    });
  };
}

export function clearTournament() {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: "tournamentRegistration.update",
      payload: {
        tourament: null,
      },
    });
  };
}

export function updateActiveRegistrationFees(activeRegistrationFees: ISelectedTournamentRegistrationFee[]) {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: "tournamentRegistration.update",
      payload: {
        activeRegistrationFees,
      },
    });
  };
}

export interface ITournamentRegistrationActions {
  loadFacility: typeof loadFacility;
  loadTournament: typeof loadTournament;
  clearTournament: typeof clearTournament;
  updateActiveRegistrationFees: typeof updateActiveRegistrationFees;
}
