import { APIGet, APIPost, APIPut, APIDelete, StatusCode } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseTvViewUrl = "/" + apiVersion + "/" + adminType + "/league/scoring/tv";

interface IGetTvView {
  id?: string | number;
  league_id?: string | number;
}

export type TPostTvView = {
  league_id: string | number;
  title: string;
};

export type TPutTvView = {
  id: string | number;
  title: string;
  auto_play: boolean;
  auto_play_interval: number;
};

interface IGetTvViewSlide {
  id?: string | number;
  tv_view_id?: string | number;
}

export type TPostTvViewSlide = {
  tv_view_id: string | number;
  type: string;
  league_scoresheet_id?: string | number;
  scoresheet_format_id: string | number;
};

//GET Tv View
export async function GetTvView(params: IGetTvView, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseTvViewUrl, params, useGlobalLoader, token);
}

//POST Tv View
export async function PostTvView(params: TPostTvView, useGlobalLoader: boolean) {
  return await APIPost(baseTvViewUrl, params, useGlobalLoader);
}

//PUT Tv View
export async function PutTvView(params: TPutTvView, useGlobalLoader: boolean) {
  return await APIPut(baseTvViewUrl, params, useGlobalLoader);
}

//DELETE Tv View
export async function DeleteTvView(params: any, useGlobalLoader: boolean) {
  return await APIDelete(baseTvViewUrl, params, useGlobalLoader);
}

//GET Tv View Slide
export async function GetTvViewSlide(params: IGetTvViewSlide, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseTvViewUrl + "/slide", params, useGlobalLoader, token);
}

//POST Tv View Slide
export async function PostTvViewSlide(params: TPostTvViewSlide, useGlobalLoader: boolean) {
  return await APIPost(baseTvViewUrl + "/slide", params, useGlobalLoader);
}

//DELETE Tv View Slide
export async function DeleteTvViewSlide(params: any, useGlobalLoader: boolean) {
  return await APIDelete(baseTvViewUrl + "/slide", params, useGlobalLoader);
}

//POST Tv View Image
export async function PostTvViewHeaderImage(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseTvViewUrl + "/image", params, useGlobalLoader);
}

//DELETE Tv View Image
export async function DeleteTvViewHeaderImage(params: any, useGlobalLoader: boolean) {
  return await APIDelete(baseTvViewUrl + "/image", params, useGlobalLoader);
}
