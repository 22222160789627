import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { delay } from "helpers/Helpers";

import { ButtonNew as Button } from "components/buttonNew";
import Icon from "components/icon/Icon";
import "components/sheet/sheet.scss";

interface ISheetProps {
  size?: "x-small" | "small" | "medium" | "large" | "wide";
  open?: boolean;
  closable?: boolean;
  stacked?: boolean;
  onCancel?: any;
  onOk?: any;
  title?: string;
  supportText?: string;
  height?: "flexible";
  cancelText?: string;
  okText?: string;
  okLoading?: boolean;
  footer?: null;
  okDisabled?: boolean;
  cancelDisabled?: boolean;
  hideCancelButton?: boolean;
  backDropCancel?: boolean;
  Tabs?: any;
  darkStyle?: boolean;
  overflow?: boolean;
  onSecondary?: any;
  secondaryText?: string;
  /** Opt-in animation of the sheet on open/close.  Starts/ends at the bottom of the page.
   * - typeof number: transition duration in seconds
   * - typeof boolean: defaults duration to 0.35s
   */
  animate?: boolean | number;
  secondaryLoading?: boolean;
  secondaryDisabled?: boolean;
}

const Sheet: React.FC<ISheetProps> = props => {
  const { t, i18n } = useTranslation();
  const {
    closable,
    stacked,
    open = false,
    size,
    onCancel,
    onOk,
    title,
    supportText,
    height,
    cancelText = t("components.sheet.sheet.001"),
    okText = t("components.sheet.sheet.002"),
    okLoading,
    footer,
    overflow,
    animate,
    secondaryText,
    onSecondary,
    secondaryLoading,
    secondaryDisabled,
  } = props;

  // const [visible, setVisible] = useState(open);

  /** Delayed state change for animation effects */
  const [mounted, setMounted] = useState(open);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (open) {
      if (!source.token.reason) {
        void delayedUnmount(true, animate, source.token);
      }
    } else {
      if (!source.token.reason) {
        void delayedUnmount(false, animate, source.token);
      }
    }

    return () => source.cancel();
  }, [open, animate]);

  /** Applies a delay to the `mounted` state for animation purposes. */
  async function delayedUnmount(
    beingMounted: boolean,
    delayInSeconds: number | boolean | undefined,
    token: CancelToken,
  ) {
    if (delayInSeconds && !beingMounted) {
      await delay(typeof delayInSeconds === "number" ? delayInSeconds * 1000 : 0.35 * 1000);
      if (!token.reason) {
        setMounted(beingMounted);
      }
      return;
    }

    setMounted(beingMounted);
  }

  return (
    <div
      className={classNames({
        "ui-sheet_container--closed": !animate && !open,
        "ui-sheet_container--animated": animate,
        "ui-sheet_container--animated-closed": animate && !open,
      })}
      style={{ visibility: animate ? (animate && mounted ? "visible" : "hidden") : "visible" }}
    >
      <div
        className={classNames("ui-sheet_container", {
          "ui-sheet_container--stacked": stacked,
        })}
        style={animate && typeof animate === "number" && open ? { transitionDuration: `${animate}s` } : null}
      >
        <div
          className={classNames("ui-sheet ui-sheet-column", {
            "ui-sheet--x-small": size === "x-small",
            "ui-sheet--small": size === "small",
            "ui-sheet--medium": size === "medium",
            "ui-sheet--large": size === "large",
            "ui-sheet--wide": size === "wide",
            "ui-sheet--stacked": stacked,
            "ui-sheet--flexible": height === "flexible",
          })}
        >
          {closable && (
            <div className="ui-popup-close-button">
              <Button type="close" size="large" onClick={onCancel} disabled={props.cancelDisabled}>
                <Icon icon="times" style="far" size="medium" />
              </Button>
            </div>
          )}

          <div className="ui-sheet-header">
            {title && <div className="text-lg text-semibold">{title}</div>}
            {supportText && <div className="text-sm text-regular text-gray-600">{supportText}</div>}
          </div>

          <div
            className={classNames("ui-sheet-content", {
              "ui-sheet-content--overflow": overflow,
            })}
          >
            {props.children}
          </div>

          {footer !== null && (
            <div className="ui-sheet-footer">
              <div className="ui-sheet-footer-action">
                {!props.hideCancelButton && (
                  <Button
                    type="default"
                    onClick={onCancel}
                    disabled={props.cancelDisabled}
                    darkStyle={props.darkStyle ? true : false}
                  >
                    {cancelText}
                  </Button>
                )}
              </div>
              <div className="ui-sheet-footer-actions">
                {onSecondary && (
                  <div className="ui-sheet-footer-action">
                    <Button
                      type="secondary"
                      onClick={onSecondary}
                      disabled={secondaryDisabled}
                      darkStyle={props.darkStyle ? true : false}
                      loading={secondaryLoading}
                    >
                      {secondaryText}
                    </Button>
                  </div>
                )}
                {onOk && (
                  <div className="ui-sheet-footer-action">
                    <Button
                      type="primary"
                      onClick={onOk}
                      disabled={props.okDisabled}
                      darkStyle={props.darkStyle ? true : false}
                      loading={okLoading}
                    >
                      {okText}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className={classNames("ui-sheet-backdrop", {
          "ui-sheet-backdrop--stacked": stacked,
          "ui-sheet-backdrop-darkstyle": props.darkStyle,
        })}
        onClick={props.backDropCancel !== undefined && props.backDropCancel === false ? undefined : onCancel}
      ></div>
    </div>
  );
};

export default Sheet;
