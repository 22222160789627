import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
const apiVersion = "2022-09";
const adminType = "facility-admin";
const basePricingSheetProductUrl = "/" + apiVersion + "/" + adminType + "/tee-sheet/pricing-sheet/product";

interface IPricingSheetProductRes {
  data: any;
  status: number;
}
interface IDeletePricingSheetProduct {
  pricing_sheet_id: number;
  variant_id: Array<number>;
}

interface IPostPricingSheetProduct {
  pricing_sheet_id: number;
  variant_ids: Array<number>;
}

interface ICopyPricingSheetProducts {
  pricing_sheet_ids: Array<number>;
  variant_ids: Array<number>;
}
export async function PostPricingSheetProduct(
  params: IPostPricingSheetProduct,
  useGlobalLoader: boolean,
): Promise<IPricingSheetProductRes> {
  return await APIPost(basePricingSheetProductUrl, params, useGlobalLoader);
}

export async function DeletePricingSheetProduct(
  params: IDeletePricingSheetProduct,
  useGlobalLoader: boolean,
): Promise<IPricingSheetProductRes> {
  return await APIDelete(basePricingSheetProductUrl, params, useGlobalLoader);
}

export async function CopyPricingSheetProducts(
  params: ICopyPricingSheetProducts,
  useGlobalLoader: boolean,
): Promise<IPricingSheetProductRes> {
  return await APIPost(basePricingSheetProductUrl + "/copy", params, useGlobalLoader);
}
