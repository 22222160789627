import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import axios, { CancelToken } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { StatusCode } from "api/protocols";
import { GetCustomerType } from "api/rpc/2024-04/clientAdmin/customer/type";

import { showError } from "redux/actions/ui";
import { TCustomerType } from "redux/reducers/models/customer";
import { useAppDispatch } from "hooks/redux";
import { valueToString } from "helpers/Helpers";

import Page from "components/page/Page";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

export default function CustomerTypes() {
  const history = useHistory();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const [types, setTypes] = useState<TCustomerType[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadCustomerTypes(source.token);
    return () => source.cancel();
  }, []);

  async function loadCustomerTypes(token?: CancelToken) {
    const res = await GetCustomerType({ booking_category: true }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading customer types."));
    }

    setTypes(res.status !== StatusCode.OK ? [] : (res.data as TCustomerType[]));
  }

  const primaryAction = {
    content: t("secure.facility.settings.customer_type.customer_type.001"),
    action: () => history.push("/admin/settings/client-settings/customer_types/new"),
  };

  return (
    <Page
      title={t("secure.facility.settings.customer_type.customer_type.002")}
      subtitle={t("secure.facility.settings.customer_type.customer_type.003")}
      narrow
      primaryAction={primaryAction}
    >
      <DataTable
        columns={[
          { label: t("secure.facility.settings.customer_type.customer_type.004"), width: "22%" },
          { label: t("secure.facility.settings.customer_type.customer_type.009"), width: "22%" },
          { label: t("secure.facility.settings.customer_type.customer_type.005"), width: "22%" },
          { label: "", width: "12%" }, // color
          { label: t("secure.facility.settings.customer_type.customer_type.007"), width: "22%" },
        ]}
        loading={types === undefined}
      >
        {types?.map(type => (
          <tr
            key={type.id}
            className="clickable"
            onClick={() => history.push("/admin/settings/client-settings/customer_types/" + String(type.id))}
          >
            <td>{type.title}</td>
            <td>{type.subtitle}</td>
            <td>{valueToString(type.application as string, "capitalize")}</td>
            <td>
              {type.application === "green_fee" && type?.color && (
                <FontAwesomeIcon icon={["fas", "circle"]} color={type.color} />
              )}
            </td>
            <td>{type.default_booking_category?.title}</td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
