import { APIGet, APIPost, APIPut } from "api/protocols";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseAccountUrl = "/" + apiVersion + "/" + adminType + "/facility/settings";

export interface IGetTeeSheetSettings {
  id: number;
}

export interface IPutTeeSheetSettings {
  web_cancellation_hours: number;
  web_edit_hours: number;
  enable_web_booking: boolean;
  enable_cart_bookings: boolean;
  max_booking_size?: number;
  open_date?: string | null;
  close_date?: string | null;
  display_customer_type: boolean;
  booking_terms: string;
  payment_terms: string;
  single_bookings: string;
  twosome_bookings: string;
  threesome_bookings: string;
  foursome_bookings: string;
  tee_sheet_limited?: boolean;
  tee_sheet_new?: boolean;
  display_online_pricing: boolean;
  power_cart_maintenance_time: number;
  power_cart_nine_hole_time: number;
  power_cart_eighteen_hole_time: number;
  track_cart_numbers: boolean;
  display_booking_graph: boolean;
}

export interface IPutPrinterSettings {
  print_server_ip: null | string;
  print_server_port: null | number;
}

export async function GetFacilityTeeSheetSettings(params?: IGetTeeSheetSettings, useGlobalLoader?: boolean) {
  return await APIGet(baseAccountUrl + "/tee-sheet", params, useGlobalLoader);
}

export async function PutFacilityTeeSheetSettings(params: IPutTeeSheetSettings, useGlobalLoader?: boolean) {
  return await APIPut(baseAccountUrl + "/tee-sheet", params, useGlobalLoader);
}

export async function PutFacilityPrinterSettings(params: IPutPrinterSettings, useGlobalLoader?: boolean) {
  return await APIPut(baseAccountUrl + "/print", params, useGlobalLoader);
}

export async function GetFacilityPrinterSettings(useGlobalLoader?: boolean) {
  return await APIGet(baseAccountUrl + "/print", undefined, useGlobalLoader);
}
