import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetTournamentScoresheet } from "api/rpc/2024-04/facilityAdmin/tournament/scoring/scoresheet";
import { GetTournamentParticipant } from "api/rpc/2022-09/facilityAdmin/tournament/participant";
import { GetTeeDecks } from "api/rpc/2024-04/facilityAdmin/facility/teedeck";
import {
  DeleteTournamentRound,
  GetTournamentRound,
  PostTournamentRound,
  PutTournamentRound,
  TPutTournamentRound,
} from "api/rpc/2024-04/facilityAdmin/tournament/scoring/round";
import { GetDivision } from "api/rpc/2024-04/facilityAdmin/facility/division";

import { showError, showSuccess } from "redux/actions/ui";
import { ITournamentParticipant, TTournamentScoresheet } from "redux/reducers/models/tournament";
import { IDivision, ITeeDeck } from "redux/reducers/models/facility";

import { useAppDispatch } from "hooks/redux";
import useModal from "hooks/modals/useModal";
import { handleChangeEventInput } from "helpers/Helpers";

import Card from "components/card/Card";
import Page from "components/page/Page";
import Input from "components/form/input/Input";
import EventScorecard from "./EventScorecard";
import Popup from "components/popup/Popup";
import Spin from "components/spin/spin";
import FormLayout from "components/form/FormLayout";
import { Select } from "components/select";
import { ButtonNew } from "components/buttonNew";
import Checkbox from "components/form/checkbox/Checkbox";
import Portal from "elements/Portal";

export default function TournamentScoresheet() {
  const history = useHistory();
  const { t } = useTranslation();
  const { tournamentId, scoresheetId } = useParams<{ tournamentId: string; scoresheetId: string }>();

  const dispatch = useAppDispatch();

  const [scoresheet, setScoresheet] = useState<TTournamentScoresheet>(undefined); // ONLY USING `name` & `holes`
  const [participants, setParticipants] =
    useState<Array<ITournamentParticipant & { open_scorecard?: boolean; round?: Record<string, any> }>>(undefined);

  const [teedecks, setTeedecks] = useState<ITeeDeck[]>([]);
  const [divisions, setDivisions] = useState<IDivision[]>([]);
  const [skinSheets, setSkinSheets] = useState<Record<string, any>[]>([]);

  const [filterState, setFilterState] = useState({
    search: "",
    selectedRound: null,
    selectedSkins: [] as Array<number>,
  });

  const { state: deleteRoundModal, updateModal, closeModal } = useModal<{ id: number }>({ id: null });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadTeeDecks(source.token);
    void loadDivisions(source.token);
    return () => source.cancel();
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadScoresheet(scoresheetId, source.token);
    // void loadSkinSheets(scoresheetId, source.token);
    return () => source.cancel();
  }, [scoresheetId]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadTournamentPlayers(tournamentId, source.token);
    return () => source.cancel();
  }, [tournamentId]);

  async function loadScoresheet(id: string | number, token?: CancelToken) {
    const res = await GetTournamentScoresheet({ id: id }, token ? false : true, token);
    console.log("scoresheet ", res); //DEBUG

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      setScoresheet({
        name: "",
        id: -1,
        tournament_id: -1,
        date_played: null,
        division_front_id: null,
        division_back_id: null,
        tee_deck_id: null,
        holes: null,
        playoffs: false,
      });
      dispatch(showError("Error loading scoresheet."));
      return;
    }

    setScoresheet(prev => ({ ...prev, ...res.data[0] }));
  }

  async function loadTournamentPlayers(id: string | number, token?: CancelToken) {
    const res = await GetTournamentParticipant({ tournament_id: id }, token ? false : true, token);
    if (token && token.reason) {
      return;
    }

    if (res.status !== StatusCode.OK) {
      setParticipants([]);
      return;
    }

    const roundRes = await GetTournamentRound(
      { scoresheet_id: scoresheetId, tournament_id: tournamentId },
      token ? false : true,
      token,
    );
    if (token && token.reason) {
      return;
    }

    const normalizedParticipants = res.data.map(player => {
      return {
        ...player,
        open_scorecard: false,
      } as ITournamentParticipant & { open_scorecard?: boolean };
    });

    for (let i = 0; i < normalizedParticipants.length; i++) {
      for (let j = 0; j < roundRes.data.length; j++) {
        if (normalizedParticipants[i].user_id === roundRes.data[j].round.customer_id) {
          normalizedParticipants[i].round = roundRes.data[j].round;
        }
      }
    }

    // players with their rounds param attached
    setParticipants(normalizedParticipants);
  }

  async function loadTeeDecks(token?: CancelToken) {
    const res = await GetTeeDecks(null, token ? false : true, token);
    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      return;
    }

    setTeedecks(res.data);
  }

  async function loadDivisions(token?: CancelToken) {
    const res = await GetDivision(null, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      return;
    }

    setDivisions(res.data);
  }

  // async function loadSkinSheets(scoresheet_id: number, token?: CancelToken) {
  //   const res = await GetSkinsSheet({ scoresheet_id }, token ? false : true, token);
  //   if(token && token.reason) { return }
  //   if(res.status !== StatusCode.OK) { return }

  //   setSkinSheets(res.data);
  // }

  async function createRound(customer_id: string | number) {
    const res = await PostTournamentRound({ customer_id, scoresheet_id: scoresheetId }, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Failed to create round for player")); // TODO: Translation
      return;
    }

    //reload page
    dispatch(showSuccess("Round created successfully")); // TODO: Translation
    void loadTournamentPlayers(tournamentId);
  }

  async function deletePlayerRound(round_id: number) {
    const res = await DeleteTournamentRound({ round_id }, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Failed to delete round."));
      return;
    }

    closeModal();
    dispatch(showSuccess("Round deleted successfully."));
    void loadTournamentPlayers(tournamentId);
  }

  async function updateRound(player: ITournamentParticipant) {
    const holeScores: { [index: number]: number } = {};

    player.round.hole_scores.forEach((score, index) => {
      if (score.hole_number > 9.1) {
        holeScores[index] = score.gross;
      } else {
        holeScores[index + 1] = score.gross;
      }
    });

    const allHoleScoresEntered = player.round.hole_scores.every((score: any) => score.gross !== null);

    const params: TPutTournamentRound = {
      id: player.round.id,
      hole_scores: holeScores,
      round_handicap: player.round.round_handicap,
      division_front_id: player.round.division_front_id,
      division_back_id: player.round.division_back_id,
      tee_deck_id: player.round.tee_deck_id,
      submitted: allHoleScoresEntered,
      points_bonus: player.round.points_bonus,
      points_participation: player.round.points_participation,
    };

    console.log("params ...", params); // DEBUG

    const res = await PutTournamentRound(params, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.league.league_scoresheet.002")));
      return;
    }

    dispatch(showSuccess("Round saved successfully")); // TODO: Translation

    const updated = participants.map(participant => {
      if (participant.id === player.id) {
        return { ...player, round: { ...res.data } };
      } else {
        return participant;
      }
    });

    // UPDATE SkinSheets
    // filterState.selectedSkins.forEach(id => {
    //   const skinsRes = PutSkinsSheet({ skins_sheet_id: id });
    // });

    setParticipants(updated);
    setFilterState(prev => ({ ...prev, selectedSkins: [] }));
  }

  function toggleScorecard(participantId: string | number) {
    setParticipants(
      participants.map(player => {
        if (player.id === Number(participantId)) {
          return { ...player, open_scorecard: !player.open_scorecard };
        } else {
          return { ...player };
        }
      }),
    );
  }

  function handleCellInputChange(event: React.ChangeEvent<HTMLInputElement>, playerId: number, scoreIndex: number) {
    const { value } = event.target;
    let nineHoleSum = 0;
    let eighteenHoleSum = 0;

    const players = [...participants];

    const playerIndex = players.findIndex(player => player.id == playerId);

    console.log("Players Id", players);
    console.log("playerIndex", playerIndex);

    //Set the new gross value for the selected input
    players[playerIndex].round.hole_scores[scoreIndex] = {
      ...players[playerIndex].round.hole_scores[scoreIndex],
      gross: isNaN(Number(value)) ? 1 : Number(value),
    };

    //Set the 9 hole sum
    const updateNineHoleSumIndex = players[playerIndex].round.hole_scores?.findIndex(
      (score: any) => score?.hole_number === 9.1,
    );

    if (updateNineHoleSumIndex !== -1) {
      for (let i = updateNineHoleSumIndex - 1; i >= 0; i--) {
        nineHoleSum += players[playerIndex].round.hole_scores[i].gross
          ? players[playerIndex].round.hole_scores[i].gross
          : 0;
      }
      players[playerIndex].round.hole_scores[updateNineHoleSumIndex] = {
        ...players[playerIndex].round.hole_scores[updateNineHoleSumIndex],
        gross: nineHoleSum,
      };
    }

    //Set the 18 hole sum
    const updateEighteenHoleSumIndex = players[playerIndex].round.hole_scores?.findIndex(
      (score: any) => score?.hole_number === 18.1,
    );
    if (updateEighteenHoleSumIndex !== -1 && updateNineHoleSumIndex !== -1) {
      for (let i = updateEighteenHoleSumIndex - 1; i > updateNineHoleSumIndex; i--) {
        eighteenHoleSum += players[playerIndex].round.hole_scores[i].gross
          ? players[playerIndex].round.hole_scores[i].gross
          : 0;
      }
      players[playerIndex].round.hole_scores[updateEighteenHoleSumIndex] = {
        ...players[playerIndex].round.hole_scores[updateEighteenHoleSumIndex],
        gross: eighteenHoleSum,
      };
    }

    //Set the total sum
    const updateTotalSum = players[playerIndex].round.hole_scores?.findIndex(
      (score: any) => score?.hole_number === 18.2,
    );
    if (updateTotalSum !== -1) {
      players[playerIndex].round.hole_scores[updateTotalSum] = {
        ...players[playerIndex].round.hole_scores[updateTotalSum],
        gross: nineHoleSum + eighteenHoleSum,
      };
    }

    setParticipants(players);
    // setLeagueScoresheetState(prevState => ({ ...prevState, players: players }));
  }

  function handleDropDownChange(value: number, property: string, player: any) {
    const players = participants;

    for (let i = 0; i < players.length; i++) {
      if (players[i]?.id === player?.id) {
        switch (property) {
          case "division_front_id":
            players[i].round.division_front_id = value;
            break;
          case "division_back_id":
            players[i].round.division_back_id = value;
            break;
          case "tee_deck_id":
            players[i].round.tee_deck_id = value;
            break;

          default:
            break;
        }
      }
    }

    setParticipants(players);
    // setLeagueScoresheetState(prev => ({ ...prev, players: players }));
  }

  function handleCheckboxChange(id: number) {
    let updated = [...filterState.selectedSkins];
    if (filterState.selectedSkins.includes(id)) {
      updated.filter(val => val !== id);
    } else {
      updated = [...updated, id];
    }

    setFilterState(prev => ({ ...prev, selectedSkins: updated }));
  }

  function handleInputChange(event: ChangeEvent<HTMLInputElement>, player: ITournamentParticipant) {
    const { id, value } = event.target;
    const valueIsNumber = !isNaN(Number(value)); //ensure string input is a number

    const updated = participants.map(participant => {
      if (participant.id === player.id && valueIsNumber) {
        return {
          ...participant,
          round: {
            ...participant.round,
            [id]: Number(value),
          },
        };
      }
      return participant;
    });

    setParticipants(updated);
  }

  return (
    <Page
      title={scoresheet ? `Scoresheet: ${scoresheet.name}` : "Loading.."}
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.league.league_scoresheet.003"),
          url: "/admin/tournament/" + tournamentId + "/scoring",
        },
      ]}
    >
      <div className="flex mr-4 mb-4">
        <button
          id="option_edit"
          className="flex scoresheet-option"
          onClick={() =>
            history.push("/admin/tournament/" + tournamentId + "/scoring/scoresheet/" + scoresheetId + "/edit")
          }
        >
          <FontAwesomeIcon icon={"hashtag"} color={"#98A2B3"} /> &nbsp;{" "}
          {t("secure.facility.league.league_scoresheet.004")}
        </button>
      </div>
      <div className="mb-4">
        <Input
          id="search"
          value={filterState.search}
          onChange={e => handleChangeEventInput(e, filterState, setFilterState)}
          type="search"
          placeholder={t("secure.facility.league.league_scoresheet.005")}
        />
      </div>

      {participants ? (
        participants
          .filter(player => player.customer?.full_name.toLowerCase().includes(filterState.search.toLowerCase()))
          .map((player, index) => (
            <div key={index}>
              <EventScorecard
                participant={player}
                scorecardOpen={player.open_scorecard}
                toggleScorecard={() => toggleScorecard(player.id)}
                createRound={() => createRound(player.user_id)}
              >
                {player.open_scorecard && (
                  <>
                    <Card.Section>
                      <div>{t("secure.facility.league.league_scoresheet.012")}</div>
                      <div style={{ overflowX: "scroll" }}>
                        <table className="scoresheet-scorecard cell-spacing w-full">
                          <tbody>
                            <tr>
                              <th className="text-left">{t("secure.facility.league.league_scoresheet.013")}</th>

                              {player.round.hole_scores.map((score: any, index: number) => {
                                if (score.hole_number <= 9.1) {
                                  return (
                                    <th key={index} className="text-center">
                                      {score.hole_number === 9.1
                                        ? t("secure.facility.league.league_scoresheet.041")
                                        : score.hole_number}
                                    </th>
                                  );
                                }
                              })}
                            </tr>
                            <tr>
                              <td className="text-left">{player.round.tee_deck_name}</td>

                              {player.round.hole_scores.map((score: any, index: number) => {
                                if (score.hole_number <= 9.1) {
                                  return (
                                    <td key={index} className="text-center">
                                      {score.yardage}
                                    </td>
                                  );
                                }
                              })}
                            </tr>
                            <tr>
                              <td className="text-left">{t("secure.facility.league.league_scoresheet.014")}</td>

                              {player.round.hole_scores.map((score: any, index: number) => {
                                if (score.hole_number <= 9.1) {
                                  return (
                                    <td key={index} className="text-center">
                                      {score.handicap}
                                    </td>
                                  );
                                }
                              })}
                            </tr>
                            <tr>
                              <td className="text-left">{t("secure.facility.league.league_scoresheet.015")}</td>

                              {player.round.hole_scores.map((score: any, index: number) => {
                                if (score.hole_number <= 9.1) {
                                  return (
                                    <td key={index} className="text-center">
                                      {score.par}
                                    </td>
                                  );
                                }
                              })}
                            </tr>

                            <tr>
                              <td className="text-left">{t("secure.facility.league.league_scoresheet.016")}</td>

                              {player.round.hole_scores.map((score: any, scoreIndex: number) => {
                                if (score.hole_number <= 9.1) {
                                  return (
                                    <td key={scoreIndex} className="text-center">
                                      <input
                                        className="cell-input text-center"
                                        type="text"
                                        onChange={e => handleCellInputChange(e, player.id, scoreIndex)}
                                        value={score.gross ?? ""}
                                        disabled={!Number.isInteger(score.hole_number)} // Cannot Edit Hole #'s   9.1 || 18.1 || 18.2
                                      />
                                    </td>
                                  );
                                }
                              })}
                            </tr>

                            <tr>
                              <td className="text-left">{t("secure.facility.league.league_scoresheet.017")}</td>

                              {player.round.hole_scores.map((score: any, index: number) => {
                                if (score.hole_number <= 9.1) {
                                  return (
                                    <td key={index} className="text-center">
                                      {score.net}
                                    </td>
                                  );
                                }
                              })}
                            </tr>

                            {player.round.holes === 18 && (
                              <>
                                <tr>
                                  <th className="text-left">{t("secure.facility.league.league_scoresheet.013")}</th>

                                  {player.round.hole_scores.map((score: any, index: number) => {
                                    if (score.hole_number >= 10) {
                                      const title =
                                        score.hole_number === 18.1
                                          ? t("secure.facility.league.league_scoresheet.042")
                                          : score.hole_number === 18.2
                                          ? t("secure.facility.league.league_scoresheet.043")
                                          : score.hole_number;
                                      return (
                                        <th key={index} className="text-center">
                                          {title}
                                        </th>
                                      );
                                    }
                                  })}
                                </tr>
                                <tr>
                                  <td className="text-left">{player.round.tee_deck_name}</td>

                                  {player.round.hole_scores.map((score: any, index: number) => {
                                    if (score.hole_number >= 10) {
                                      return (
                                        <td key={index} className="text-center">
                                          {score.yardage}
                                        </td>
                                      );
                                    }
                                  })}
                                </tr>
                                <tr>
                                  <td className="text-left">{t("secure.facility.league.league_scoresheet.014")}</td>

                                  {player.round.hole_scores.map((score: any, index: number) => {
                                    if (score.hole_number >= 10) {
                                      return (
                                        <td key={index} className="text-center">
                                          {score.handicap}
                                        </td>
                                      );
                                    }
                                  })}
                                </tr>
                                <tr>
                                  <td className="text-left">{t("secure.facility.league.league_scoresheet.015")}</td>

                                  {player.round.hole_scores.map((score: any, index: number) => {
                                    if (score.hole_number >= 10) {
                                      return (
                                        <td key={index} className="text-center">
                                          {score.par}
                                        </td>
                                      );
                                    }
                                  })}
                                </tr>

                                <tr>
                                  <td className="text-left">{t("secure.facility.league.league_scoresheet.016")}</td>

                                  {player.round.hole_scores.map((score: any, scoreIndex: number) => {
                                    if (score.hole_number >= 10) {
                                      return (
                                        <td key={scoreIndex} className="text-center">
                                          <input
                                            className="cell-input text-center"
                                            type="text"
                                            onChange={e => handleCellInputChange(e, player.id, scoreIndex)}
                                            value={score.gross ?? ""}
                                            disabled={!Number.isInteger(score.hole_number)} // Cannot Edit Hole #'s   9.1 || 18.1 || 18.2
                                          />
                                        </td>
                                      );
                                    }
                                  })}
                                </tr>

                                <tr>
                                  <td className="text-left">{t("secure.facility.league.league_scoresheet.017")}</td>

                                  {player.round.hole_scores.map((score: any, index: number) => {
                                    if (score.hole_number >= 10) {
                                      return (
                                        <td key={index} className="text-center">
                                          {score.net}
                                        </td>
                                      );
                                    }
                                  })}
                                </tr>
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </Card.Section>

                    <Card.Section>
                      <div className="flex justify-between" style={{ alignItems: "center" }}>
                        <div>
                          <FontAwesomeIcon icon={"circle-check"} color={"#AA0504"} /> &nbsp;{" "}
                          {t("secure.facility.league.league_scoresheet.018")}
                        </div>
                        <div className="flex">
                          <ButtonNew
                            onClick={() => updateModal({ isOpen: true, id: player.round.id })}
                            type="primary"
                            size="small"
                          >
                            {t("secure.facility.league.league_scoresheet.020")}
                          </ButtonNew>
                          <ButtonNew onClick={e => updateRound(player)} type="primary" size="small">
                            {t("secure.facility.league.league_scoresheet.021")}
                          </ButtonNew>
                        </div>
                      </div>
                    </Card.Section>

                    <Card.Section>
                      <FormLayout>
                        <FormLayout.Group>
                          <Input
                            value={player.round.points_bonus.toString()}
                            label="Bonus Points"
                            id="points_bonus"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event, player)}
                          />

                          <Input
                            value={player.round.points_participation.toString()}
                            label="Participation Points"
                            id="points_participation"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event, player)}
                          />

                          <Input
                            value={player.round.round_handicap.toString() ?? ""}
                            label="Edit Handicap"
                            id="round_handicap"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event, player)}
                          />
                        </FormLayout.Group>
                      </FormLayout>
                    </Card.Section>

                    <Card.Section>
                      <FormLayout>
                        <FormLayout.Group>
                          <Select
                            label={"Front Division"}
                            onChange={(value: any) => handleDropDownChange(value, "division_front_id", player)}
                            defaultValue={player.round.division_front_id}
                            dropDownPositionTop
                            //   disabled={!productPermissions?.products_edit}
                          >
                            {divisions.map((division, index: number) => {
                              return (
                                <Select.Option key={index} value={division.id} name={division.title}>
                                  {division.title}
                                </Select.Option>
                              );
                            })}
                          </Select>

                          <Select
                            label={t("secure.facility.league.league_scoresheet.023")}
                            onChange={(value: number) => handleDropDownChange(value, "tee_deck_id", player)}
                            defaultValue={player.round.tee_deck_id}
                            dropDownPositionTop
                          >
                            {teedecks.map((teeDeck, index: number) => {
                              return (
                                <Select.Option key={index} value={teeDeck.id} name={teeDeck.title}>
                                  {teeDeck.title}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </FormLayout.Group>

                        {scoresheet?.holes === 18 ? (
                          <FormLayout.Group>
                            <Select
                              label={"Back Division"} // TODO: Translation
                              onChange={(value: any) => handleDropDownChange(value, "division_back_id", player)}
                              defaultValue={player.round.division_back_id}
                              //   disabled={!productPermissions?.products_edit}
                            >
                              {divisions.map((division, index: number) => {
                                return (
                                  <Select.Option key={index} value={division.id} name={division.title}>
                                    {division.title}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </FormLayout.Group>
                        ) : null}
                      </FormLayout>
                    </Card.Section>

                    {skinSheets.length > 0 ? (
                      <Card.Section>
                        <FormLayout>
                          <FormLayout.Group>
                            {skinSheets.map((skin: any, index: number) => {
                              return (
                                <Checkbox
                                  key={index}
                                  label={skin.title}
                                  value={filterState.selectedSkins.includes(skin.id)}
                                  fullWidth
                                  size="medium"
                                  onChange={() => handleCheckboxChange(skin.id)}
                                />
                              );
                            })}
                          </FormLayout.Group>
                        </FormLayout>
                      </Card.Section>
                    ) : null}
                  </>
                )}
              </EventScorecard>
            </div>
          ))
      ) : (
        <Card style={{ height: "72px", padding: "1rem" }}>
          <Spin />
        </Card>
      )}

      <Portal isMounted={deleteRoundModal.isOpen}>
        <Popup
          open={deleteRoundModal.isOpen}
          type="warning"
          title="Delete Round?"
          description="Are you sure you want to delete this round?" // TODO: Translation
          onCancel={() => closeModal()}
          onOk={() => deletePlayerRound(deleteRoundModal.id)}
          okText="Delete"
        />
      </Portal>
    </Page>
  );
}
