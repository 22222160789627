import { IOptions } from "pages/secure/facility/Admin";

const options: IOptions[] = [
  {
    label: "Client",
  },

  {
    label: "Booking Categories",
    url: "/admin/settings/booking-category",
  },
  {
    label: "Customer Types",
    url: "/admin/settings/customer-type",
  },
  {
    label: "Product Types",
    url: "/admin/settings/product-type",
  },
  {
    label: "Vendors",
    url: "/admin/settings/vendor",
  },
  {
    label: "Staff Accounts",
    url: "/admin/settings/staff",
  },
  {
    label: "Facility Permissions",
    url: "/admin/settings/facility-permissions",
  },

  {
    label: "Facility",
  },
  {
    label: "Folders",
    url: "/admin/settings/folders",
  },
  {
    label: "Discounts",
    url: "/admin/settings/discount",
  },
  {
    label: "Modifier Groups",
    url: "/admin/settings/modifier-group",
  },
  {
    label: "Departments",
    url: "/admin/settings/departments",
  },
  {
    label: "Forms",
    url: "/admin/settings/forms",
  },
  {
    label: "Courses",
    url: "/admin/settings/course",
  },
  {
    label: "Divisions",
    url: "/admin/settings/division",
  },
];

export default options;
