import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetScoringSettings } from "api/rpc/2024-04/facilityAdmin/league/scoring/settings";
import { GetLeagueScoresheets } from "api/rpc/2024-04/facilityAdmin/league/scoring/scoresheet";

import { showError } from "redux/actions/ui";
import { TLeagueScoresheet } from "redux/reducers/models/league";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

export default function LeagueRounds() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { leagueId } = useParams<{ leagueId: string }>();

  const dispatch = useAppDispatch();

  const [leagueScoresheets, setLeagueScoresheets] = useState<TLeagueScoresheet[]>(undefined);
  const [newScoresheetLocked, setNewScoresheetLocked] = useState(false);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadLeague(leagueId, source.token);
    void loadLeagueScoringSettings(leagueId, source.token);
    return () => source.cancel();
  }, [leagueId]);

  async function loadLeague(leagueId: string | number, token?: CancelToken) {
    if (leagueScoresheets !== undefined) {
      setLeagueScoresheets(undefined);
    }
    const leagueRes = await GetLeagueScoresheets({ league_id: leagueId }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (leagueRes.status !== StatusCode.OK) {
      dispatch(showError("Error getting league scoresheets.")); // TODO: Translation
    }

    setLeagueScoresheets(leagueRes.status !== StatusCode.OK ? [] : leagueRes.data);
  }

  // Strictly setting locked param from return
  async function loadLeagueScoringSettings(id: string | number, token?: CancelToken) {
    const res = await GetScoringSettings({ league_id: id }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error getting the scoring settings.")); // TODO: Translation
    }

    setNewScoresheetLocked(res.status !== StatusCode.OK ? false : Boolean(res.data.locked ?? 0));
  }

  const primaryAction = {
    content: "New Round", // TODO: Translation
    action: () => history.push(`/admin/league/${leagueId}/rounds/scoresheet/new`),
  };

  return (
    <Page
      title={"Rounds"} // TODO: Translation
      narrow
      primaryAction={primaryAction}
    >
      <DataTable
        columns={[
          { label: t("secure.facility.league.league_scores.003") },
          { label: t("secure.facility.league.league_scores.004") },
          { label: "Rounds Played" }, // TODO: Translation
          // { label: "" },
          // { label: "" },
        ]}
        loading={leagueScoresheets === undefined}
      >
        {leagueScoresheets?.map(scoresheet => (
          <tr
            key={scoresheet.id}
            className="clickable"
            onClick={() => history.push(`/admin/league/${leagueId}/rounds/scoresheet/${scoresheet.id}/overview`)}
          >
            <td>{scoresheet.name}</td>
            <td>{scoresheet.date_played}</td>
            <td>{scoresheet.participation}</td>
            {/* <td>
              <Button
                size="small"
                type="text"
                onClick={() => history.push(`/admin/league/${leagueId}/scoring/scoresheet/${String(scoresheet.id)}`)}
              >
                Details
              </Button>
            </td>
            <td>
              <Button
                size="small"
                type="text"
                onClick={() => history.push(`/admin/league/${leagueId}/scoring/results/${String(scoresheet.id)}`)}
              >
                Results
              </Button>
            </td> */}
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
