import { APIGet, APIPost, APIPut } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/facility/kitchen";

export type TGetKitchenChitNoRange = {
  id?: number;
  kitchen_location_id?: number;
  kitchen_location_ids?: Array<number>; // return kitchen chits from 2+ locations
  date?: Date; // sent_at value
  user_id?: number;
  start_date?: never;
  end_date?: never;
};
export type TGetKitchenChitRange = {
  id?: number;
  kitchen_location_id?: number;
  kitchen_location_ids?: Array<number>; // return kitchen chits from 2+ locations
  date?: Date; // sent_at value
  user_id?: number;
  start_date: Date;
  end_date: Date;
};

export type TPostKitchenChit = {
  line_item_ids: Array<number>;
  /**
   * Chits being created for carts require table_id.
   * - If no table_id, uses type='register' table for quick sales
   * */
  table_id?: number;
};

export type TPutKitchenChit = {
  id: number;
  status?: string;
};

export async function GetKitchenChits(
  params: TGetKitchenChitNoRange | TGetKitchenChitRange,
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseUrl + "/chit", params, useGlobalLoader, token);
}

export async function PostKitchenChit(params: TPostKitchenChit, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/chit", params, useGlobalLoader);
}

export async function PutKitchenChit(params: TPutKitchenChit, useGlobalLoader?: boolean) {
  return await APIPut(baseUrl + "/chit", params, useGlobalLoader);
}
