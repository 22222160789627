import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetCashOuts, ICashOut } from "api/rpc/2024-04/clientAdmin/register/cashout";

import { showError } from "redux/actions/ui";
import { displayCurrency } from "helpers/Helpers";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import Page from "components/page/Page";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import { THIS_MONTH, THIS_YEAR } from "components/form/calendarField/helpers";
import MonthDropFilter from "components/filters/MonthDropFilter";
import YearDropFilter from "components/filters/YearDropFilter";
import RegisterDropFilter from "components/filters/RegisterDropFilter";

export default function CashOuts() {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();
  const clientFacilityStore = useAppSelector(store => store.clientFacilityStore);

  const [cashOuts, setCashOuts] = useState<ICashOut[]>(undefined);

  const [filters, setFilters] = useState({
    year: THIS_YEAR,
    month: THIS_MONTH,
    register_ids: [],
  });

  const [asyncDropFiltersLoaded, setAsyncDropFiltersLoaded] = useState(false);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (clientFacilityStore?.facility && asyncDropFiltersLoaded) {
      void loadCashOuts(clientFacilityStore?.facility.id, source.token);
    }

    return () => source.cancel();
  }, [clientFacilityStore?.facility, asyncDropFiltersLoaded, filters]);

  async function loadCashOuts(facilityId: string | number, token?: CancelToken) {
    if (cashOuts !== undefined) {
      setCashOuts(undefined);
    }
    const res = await GetCashOuts(
      {
        facility_id: facilityId,
        filters: [
          { label: "register", value: filters.register_ids },
          { label: "year", value: filters.year ? [filters.year] : [] },
          { label: "month", value: filters.month ? [filters.month] : [] },
        ],
      },
      token ? false : true,
      token,
    );

    if (token && token.reason) {
      return;
    }

    if (res.status !== StatusCode.OK || res.data === undefined) {
      dispatch(showError("Failed to load cash outs")); // TODO: Translation
      setCashOuts([]);
      return;
    }

    const sortedCashOuts = res.data.sort((prevCashOut, nextCashOut) => {
      const prevCashOutCloseDate = new Date(prevCashOut.closed_at + "Z");
      const nextCashOutCloseDate = new Date(nextCashOut.closed_at + "Z");

      return nextCashOutCloseDate.getTime() - prevCashOutCloseDate.getTime();
    });

    setCashOuts(sortedCashOuts);
  }

  const primaryAction = {
    content: "New Cash Out", // TODO: Translation
    action: () => history.push("/admin/settings/facility-settings/cash-out/new"),
  };

  return (
    <Page
      title={"Cash Outs"} // TODO: Translation
      subtitle={"Manage cash outs"} // TODO: Translation
      // primaryAction={permissions.cash_outs_create && primaryAction}
    >
      <div className="flex flex-row flex-wrap gap-4 pb-4">
        <MonthDropFilter
          handleApply={month => setFilters(prev => ({ ...prev, month: month }))}
          monthIdValue="index"
          defaultCheckboxes={[filters.month]}
          leftAligned
        />

        <YearDropFilter
          handleApply={year => setFilters(prev => ({ ...prev, year: year }))}
          defaultCheckboxes={[filters.year]}
        />

        <RegisterDropFilter
          dataLoaded={() => setAsyncDropFiltersLoaded(true)}
          handleApply={selectedRegisterIds => setFilters(prev => ({ ...prev, register_ids: selectedRegisterIds }))}
          defaultCheckboxes={filters.register_ids}
          userLevel={"client"}
          facility_id={clientFacilityStore.facility?.id}
          disableDropdown={!asyncDropFiltersLoaded}
        />
      </div>

      <DataTable
        columns={[
          { label: "Date" }, // TODO: Translation
          { label: "Register" }, // TODO: Translation
          { label: "Deposit Cash" }, // TODO: Translation
          { label: "Deposit Check" }, // TODO: Translation
          { label: "Staff" }, // TODO: Translation
          { label: "Bag Number" }, // TODO: Translation
          { label: "Created At" }, // TODO: Translation
          { label: "Deposited On" }, // TODO: Translation
        ]}
        loading={cashOuts === undefined}
      >
        {cashOuts?.map(cashout => {
          const closedDate = new Date(cashout.closed_at + "Z");
          let depositDate;

          if (cashout.deposit_date === null || undefined) {
            depositDate = "";
          } else {
            depositDate = moment.utc(cashout.deposit_date).format("MMM DD, YYYY");
          }

          return (
            <tr
              key={cashout.id}
              className="clickable"
              onClick={() => history.push(`/admin/settings/facility-settings/cash-out/edit/${cashout.id}`)}
            >
              <td>{moment.utc(closedDate).local().format("MMM DD, YYYY")}</td>
              <td>{cashout.register_title}</td>
              <td>{displayCurrency("cad", cashout.deposit_cash)}</td>
              <td>{displayCurrency("cad", cashout.deposit_check)}</td>
              <td>{cashout.closed_by_full_name}</td>
              <td>{cashout.deposit_bag_number}</td>
              <td>
                <span>{moment.utc(cashout.created_at).local().format("MMM DD, YYYY")} at </span>
                <span>{moment.utc(cashout.created_at).local().format("h:mm A")}</span>
              </td>
              <td>{depositDate}</td>
            </tr>
          );
        })}
      </DataTable>
    </Page>
  );
}
