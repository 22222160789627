import { createContext, useContext } from "react";
import { DynamicPricingFactorValue } from "./dynamicPricingNodes/DynamicPricingFactorGroupNode";
import { IDynamicPricingSelectedNode } from "./TeeSheetDynamicPricingEdit";
import { IDynamicPricingFactorNode } from "./dynamicPricingNodes/DynamicPricingFactorNode";

interface IDynamicPricingContext {
  deleteFactor: (id: string) => void;
  updateFactor: (id: string, value: DynamicPricingFactorValue) => void;
  deleteGroupFactors: (id: string) => void;
  groupHeight: number;
  selectedNode: IDynamicPricingSelectedNode;
  setSelectedNode: (updatedSelectedNode: IDynamicPricingSelectedNode) => void;
  flowActionPanelRef: React.MutableRefObject<HTMLDivElement>;
  getFactorSiblings: (parentId: string, id: string) => IDynamicPricingFactorNode[];
}

const defaultDynamicPricingContext: IDynamicPricingContext = {
  deleteFactor: undefined,
  updateFactor: undefined,
  deleteGroupFactors: undefined,
  groupHeight: undefined,
  selectedNode: undefined,
  setSelectedNode: undefined,
  flowActionPanelRef: undefined,
  getFactorSiblings: undefined,
};

export const DynamicPricingContext = createContext<IDynamicPricingContext>(defaultDynamicPricingContext);
export const useDynamicPricingContext = () => useContext(DynamicPricingContext);
