import React, { useState, useEffect } from "react";
import CardSection from "components/cardSection/CardSection";
import { ButtonNew as Button } from "components/buttonNew";
import { PutCancelPaymentIntent, PutCapture } from "api/rpc/2022-09/facilityAdmin/order/transaction";
import { StatusCode } from "api/protocols";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";
import {
  useStripe,
  useElements,
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { PutAccountTransactionCapture, PutCancelAccountTransaction } from "api/rpc/facilityAdmin/customer";
import { PutCaptureInvoiceTransaction, PutCancelInvoiceTransaction } from "api/rpc/2024-04/facilityAdmin/order/invoice";

interface IProps {
  accountTransaction?: boolean;
  invoiceTransaction?: boolean;
  onSuccess?: any;
  onError?: any;
  onSubmit?: any;
  transaction: any;
  trigger: boolean;
  onChange?: (
    e: StripeCardNumberElementChangeEvent | StripeCardExpiryElementChangeEvent | StripeCardCvcElementChangeEvent,
  ) => void;
}

export interface IBillingDetailsState {
  name: string;
}

export async function cancelManualCreditPaymentIntent(
  paymentIntentId: string,
  transactionId: number,
  accountPayment = false,
  invoicePayment = false,
) {
  let cancelIntentRes;
  if (accountPayment) {
    //Cancel account transaction
    cancelIntentRes = await PutCancelAccountTransaction(
      { payment_intent_id: paymentIntentId, transaction_id: transactionId },
      true,
    );
  } else if (invoicePayment) {
    //Cancel accoinvoiceunt transaction
    cancelIntentRes = await PutCancelInvoiceTransaction(
      { payment_intent_id: paymentIntentId, transaction_id: transactionId },
      true,
    );
  } else {
    //Cancel order transaction
    cancelIntentRes = await PutCancelPaymentIntent(
      { payment_intent_id: paymentIntentId, transaction_id: transactionId },
      true,
    );
  }
  if (cancelIntentRes?.status !== StatusCode.OK) {
    console.log("Error cancelling payment intent");
    return false;
  }
  return true;
}

export default function CheckoutForm(props: IProps) {
  const [billingState, setBillingState] = useState<IBillingDetailsState>();
  const stripe = useStripe();
  const elements = useElements();
  const { transaction, onSuccess, onError, onSubmit, trigger } = props;

  //(05/Aug/21)::(09:15:54) - TODO - Is a ref needed to access this function?

  //Handle submit trigger
  useEffect(() => {
    if (trigger) {
      void handleSubmit();
    }
  }, [trigger]);

  async function handleSubmit() {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (transaction.client_secret.length !== 0) {
      if (onSubmit) {
        onSubmit();
      }
      const result = await stripe.confirmCardPayment(transaction.client_secret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
        },
      });

      if (result.error) {
        // Show error to your customer (e.g., insufficient funds)
        if (onError) {
          onError(result.error.message);
        }
      } else {
        // The payment has been processed!
        elements.getElement(CardNumberElement).clear();
        elements.getElement(CardExpiryElement).clear();
        elements.getElement(CardCvcElement).clear();
        // Capture
        if (result.paymentIntent.status === "requires_capture") {
          let captureResult;
          if (props?.accountTransaction) {
            captureResult = await PutAccountTransactionCapture(
              { transaction_id: transaction.id, payment_intent_id: result.paymentIntent.id },
              true,
            );
          } else if (props?.invoiceTransaction) {
            captureResult = await PutCaptureInvoiceTransaction(
              { transaction_id: transaction.id, payment_intent_id: result.paymentIntent.id },
              true,
            );
          } else {
            captureResult = await PutCapture(
              { payment_intent_id: result.paymentIntent.id, transaction_id: transaction.id },
              true,
            );
          }

          if (captureResult.status === StatusCode.OK) {
            if (onSuccess) {
              onSuccess();
            }
          } else {
            if (onError) {
              onError(captureResult.data.message);
            }
          }

          if (onSubmit) {
            onSubmit();
          }
        }
      }
    }
  }

  return (
    <div className="flex flex-column">
      <CardSection onChange={props?.onChange} type="two-row" />
    </div>
  );
}
