import { TFunction } from "react-i18next";

const blockedTypeOptions = (t: TFunction<"translation", undefined>) => [
  { label: "None", value: "none" }, // TODO: Translation
  { label: t("secure.facility.tee_sheet.tee_sheet_edit.001"), value: "open" },
  { label: t("secure.facility.tee_sheet.tee_sheet_edit.002"), value: "blocked" },
  { label: t("secure.facility.tee_sheet.tee_sheet_edit.003"), value: "crossover" },
];

const divisionOptions = (t: TFunction<"translation", undefined>) => [
  { label: t("secure.facility.tee_sheet.tee_sheet_edit.004"), value: 1 },
  { label: t("secure.facility.tee_sheet.tee_sheet_edit.005"), value: 2 },
];

const cartRuleOptions = (t: TFunction<"translation", undefined>) => [
  { label: "Empty", value: "empty" }, // TODO: Translation
  { label: t("secure.facility.tee_sheet.tee_sheet_edit.006"), value: "none" },
  { label: t("secure.facility.tee_sheet.tee_sheet_edit.007"), value: "optional" },
  { label: t("secure.facility.tee_sheet.tee_sheet_edit.008"), value: "required" },
];

export { blockedTypeOptions, divisionOptions, cartRuleOptions };
