import { APIGet, APIPost, APIPut, APIDelete, StatusCode, APIUrl, AdminType } from "api/protocols";
import { CancelToken } from "axios";
import { IInvoice } from "redux/reducers/models/order";

const adminType = "facility-admin";
const version = "2024-04";
const baseUrl = "/" + version + "/" + adminType + "/invoice";

export interface IGetInvoice {
  id?: number;
  offset?: number;
  limit?: number;
  extended?: boolean;
  search?: string;
}

interface IPostInvoice {
  customer_id: number;
  customer_billing_address_id: number;
  customer_shipping_address_id: number;
  date: string;
  tournament_id?: number;
  hospitality_event_id?: number;
  invoice_due: string;
}

interface IPutInvoice {
  invoice_id: number | string;
  customer_id: number;
  tournament_id?: number;
  hospitality_event_id?: number;
  date: string;
  invoice_due: string;
  customer_billing_address_id: number;
  customer_shipping_address_id: number;
}

interface IGetInvoiceLineItem {
  invoice_id: number;
}

interface IPostInvoiceLineItem {
  invoice_id: number;
  variant_id: number;
  quantity: number;
}

interface IPutInvoiceLineItem {
  id: number;
  quantity: number;
  price: number;
  note: string;
}

interface IPostInvoiceTransaction {
  invoice_id?: number;
  amount: number;
  payment_option_id: number;
  kind: string;
  league_id?: number;
  tournament_id?: number;
  hospitality_event_id?: number;
  gift_card_code?: string;
  gift_card_pin?: string;
  credit_book_id?: number | string;
}

interface IPutCaptureInvoiceTransaction {
  payment_intent_id: string;
  transaction_id: number;
}

interface IPutCancelInvoiceTransaction {
  payment_intent_id: string | number;
  transaction_id: number;
}

interface IPutApplyDeposit {
  invoice_id: number;
  deposit_id: number;
}

export interface IGetInvoiceTransaction {
  offset?: number;
  limit?: number;
  invoice_id?: number;
  kind?: string;
  tournament_id?: number;
  hospitality_event_id?: number;
  league_id?: number;
  all_deposits?: boolean;
}

export async function GetInvoice(params: IGetInvoice, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseUrl, params, useGlobalLoader, token);
}

export async function PostInvoice(params: IPostInvoice, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

export async function PutInvoice(params: IPutInvoice, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

export async function GetInvoiceLineItem(params: IGetInvoiceLineItem, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseUrl + "/line-item", params, useGlobalLoader, token);
}

export async function PostInvoiceLineItem(params: IPostInvoiceLineItem, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/line-item", params, useGlobalLoader);
}

export async function PutInvoiceLineItem(params: IPutInvoiceLineItem, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIPut(baseUrl + "/line-item", params, useGlobalLoader, token);
}

export async function DeleteInvoiceLineItem(params: { id: number }, useGlobalLoader: boolean) {
  return await APIDelete(baseUrl + "/line-item", params, useGlobalLoader);
}

export async function GetDownloadInvoice(params: { invoice_id: number }, useGlobalLoader: boolean) {
  return await APIGet(baseUrl + "/download", params, useGlobalLoader);
}

interface IPutFinalizeInvoice {
  invoice_id: number;
  gift_cards: { cart_line_item_id: number; code: string; pin: number; random: boolean }[];
  register_id?: number;
  league_ids?: Array<number>;
}

export async function PutFinalizeInvoice(params: IPutFinalizeInvoice, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/finalize", params, useGlobalLoader);
}

export async function PutVoidInvoice(params: { invoice_id: number }, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/void", params, useGlobalLoader);
}

export async function PostDuplicateInvoice(
  params: { invoice_id: number; adjustment?: boolean },
  useGlobalLoader: boolean,
) {
  return await APIPost(baseUrl + "/duplicate", params, useGlobalLoader);
}

export async function GetInvoiceTransaction(
  params: IGetInvoiceTransaction,
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(APIUrl(AdminType.FacilityAdmin, "/invoice/transaction"), params, useGlobalLoader, token);
}

export async function PostInvoiceTransaction(params: IPostInvoiceTransaction, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/transaction", params, useGlobalLoader);
}

export async function PutCaptureInvoiceTransaction(params: IPutCaptureInvoiceTransaction, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/transaction/capture", params, useGlobalLoader);
}

export async function PutCancelInvoiceTransaction(params: IPutCancelInvoiceTransaction, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/transaction/cancel", params, useGlobalLoader);
}

export async function GetInvoiceTransactionReceipt(params: { transaction_id: number }, useGlobalLoader: boolean) {
  return await APIGet(APIUrl(AdminType.FacilityAdmin, "/invoice/transaction/receipt"), params, useGlobalLoader);
}

export async function PostInvoiceTransactionRefund(params: { transaction_id: number }, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/transaction/refund", params, useGlobalLoader);
}

export async function PutApplyDeposit(params: IPutApplyDeposit, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/transaction/apply", params, useGlobalLoader);
}
