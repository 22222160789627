import React, { useEffect, useState } from "react";
import moment from "moment";

import { GetReport, GetReportRun } from "api/rpc/2024-04/clientAdmin/report/report";
import { GetFacility, GetDepartments } from "api/rpc/clientAdmin/facility/facility";
import { GetVendor } from "api/rpc/clientAdmin/vendor";
import { GetRegisters } from "api/rpc/2024-04/clientAdmin/register/register";

import { StatusCode } from "api/protocols";

import { useLocation } from "react-router-dom";
import { formatDate } from "helpers/Helpers";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { MOBILE_WIDTH, TABLET_WIDTH } from "helpers/ScreenSizes";

import Page from "components/page/Page";
import Card from "components/card/Card";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import FilterDropdown from "components/filterDropDown/FilterDropDown";
import DetailedSalesByDepartmentTable from "../tables/DetailedSalesByDepartmentTable";
import { IConfiguration } from "api/rpc/clientAdmin/client/report";
import { IRegister } from "../../Admin";
import { TDepartment } from "redux/reducers/models/product";

import "../report.scss";

interface IReportState {
  id: number;
  title: string;
  code: string;
  dateRangeStart: Date;
  dateRangeEnd: Date;
  singleDate: Date;
  configurations: Array<IConfiguration>;
  date_range: Array<string>;
  date: string;
  facilityOptions: Array<{ label: string; onClick: () => void }>;
  facility_ids: Array<number>;
  vendor_ids: Array<number>;
  allRegisters: IRegister[];
  register_ids: Array<number>;
  allDepartments: TDepartment[];
  department_ids: number[];
  category_ids: number[];
  subcategory_ids: number[];
  [key: string]: any;
}

interface IReportDataState {
  headings: any;
  rows: any;
  columns: Array<Record<string, any>>;
  tableDisplay: boolean;
  description: string;
  compileDate: string;
}

export default function DetailedSalesByDepartment(props: any) {
  const windowSize = useWindowSize();
  const location = useLocation();
  const routeName = location.pathname.split("/");
  const code = routeName[routeName.length - 1];

  function formatDateRange(startingDate: Date, endingDate: Date) {
    return [formatDate(startingDate), formatDate(endingDate) + " 23:59:00"];
  }

  const today = new Date();

  const [state, setState] = useState<IReportState>({
    dateRangeStart: today,
    dateRangeEnd: today,
    singleDate: today,
    id: null,
    title: "",
    code: "",
    configurations: [],
    date_range: formatDateRange(today, today),
    date: formatDate(today),
    facilityOptions: [],
    facility_ids: [],
    vendor_ids: [],
    allRegisters: null,
    register_ids: [],
    allDepartments: null,
    department_ids: null,
    category_ids: null,
    subcategory_ids: null,
  });

  const [reportData, setReportData] = useState<IReportDataState>({
    headings: [],
    rows: [],
    columns: [],
    tableDisplay: false,
    description: "",
    compileDate: "",
  });

  useEffect(() => {
    void loadReport();
    void getFacilities();
    void getVendors();
    void getRegisters();
    void getDepartments();
  }, []);

  useEffect(() => {
    if (state.facility_ids?.length === 1) {
      void getRegisters();
    } else {
      setState(prevState => ({ ...prevState, register_ids: [] }));
    }
  }, [state.facility_ids]);

  async function loadReport() {
    const reportRes = await GetReport({ code: code }, true);
    if (reportRes.status !== StatusCode.OK) {
      return;
    }

    const report = reportRes.data[0];
    const sortedConfigurations = [...report.configurations].sort((prev, next) => prev.position - next.position);

    setState(prev => ({
      ...prev,
      id: report.id,
      title: report.title,
      code: report.code,
      configurations: sortedConfigurations,
    }));
  }

  async function getReport() {
    const reportRes = await GetReportRun(
      {
        report_id: state.id,
        date_range: state.date_range,
        facility_ids: state.facility_ids,
        vendor_ids: state.vendor_ids,
        register_ids: state.register_ids,
        department_ids: state.department_ids,
        category_ids: state.category_ids,
        subcategory_ids: state.subcategory_ids,
      },
      true,
    );

    if (reportRes.status !== StatusCode.OK) {
      return;
    }

    const compileDate = reportRes.data?.compiled_at ? reportRes.data?.compiled_at.concat("Z") : "";

    setReportData(prev => ({
      ...prev,
      rows: reportRes.data.rows,
      columns: reportRes.data.columns,
      description: reportRes.data?.description ?? "",
      compileDate,
    }));

    return;
  }

  async function getDownload() {
    const segmentsRes = await GetReportRun(
      {
        report_id: state.id,
        type: "csv",
        date_range: state.date_range,
        facility_ids: state.facility_ids,
        vendor_ids: state.vendor_ids,
        register_ids: state.register_ids,
        department_ids: state.department_ids,
        category_ids: state.category_ids,
        subcategory_ids: state.subcategory_ids,
      },
      true,
    );

    // Do we need a translation for this?
    if (segmentsRes.status !== StatusCode.OK) {
      props.uiActions.showError("Error exporting transaction report");
      return;
    }

    const reportExport = document.createElement("a");
    reportExport.href = "data:text/csv;charset=utf-8," + encodeURI(segmentsRes.data);
    reportExport.target = "_blank";
    reportExport.download = `${state.title}_${moment(new Date()).format("YYYY-MM-DD").toString()}`
      .replace(/ /g, "_")
      .toLowerCase();
    reportExport.click();
  }

  async function getPrint() {
    const printRes = await GetReportRun(
      {
        report_id: state.id,
        type: "print",
        date_range: state.date_range,
        facility_ids: state.facility_ids,
        vendor_ids: state.vendor_ids,
        register_ids: state.register_ids,
        department_ids: state.department_ids,
        category_ids: state.category_ids,
        subcategory_ids: state.subcategory_ids,
      },
      true,
    );

    // Do we need a translation for this?
    if (printRes.status !== StatusCode.OK) {
      props.uiActions.showError("Error printing report");
      return;
    }

    window.open().document.write(printRes.data);
  }

  async function getFacilities() {
    const facilityRes = await GetFacility(null, true);

    if (facilityRes.status !== StatusCode.OK) {
      console.log(facilityRes.message);
      return;
    }

    facilityRes.data.sort((prev, next) => prev.long_name.localeCompare(next.long_name));

    setState(prevState => ({ ...prevState, allFacilities: facilityRes.data }));
  }

  async function getVendors() {
    const vendorRes = await GetVendor(null, true);
    if (vendorRes.status !== StatusCode.OK) {
      console.log(vendorRes.message);
      return;
    }
    setState(prevState => ({ ...prevState, allVendors: vendorRes.data }));
  }

  async function getRegisters() {
    const registerRes = await GetRegisters({ facility_id: state.facility_ids[0] }, false);
    if (registerRes.status !== StatusCode.OK) {
      props.uiActions.showError("Error loading registers");
      return;
    }
    setState(prevState => ({ ...prevState, allRegisters: registerRes.data }));
  }

  async function getDepartments() {
    const departmentRes = await GetDepartments(false);
    if (departmentRes.status !== StatusCode.OK) {
      props.uiActions.showError("Error loading departments");
      return;
    }
    setState(prevState => ({ ...prevState, allDepartments: departmentRes.data }));
  }

  function updateDateRange(updatedStartingDate: Date, updatedEndingDate: Date) {
    if (updatedStartingDate && updatedEndingDate) {
      const date_range = formatDateRange(updatedStartingDate, updatedEndingDate);
      setState(prevState => ({ ...prevState, date_range }));
    }
  }

  useEffect(() => {
    if (state.dateRangeStart && state.dateRangeEnd) {
      const date_range = formatDateRange(state.dateRangeStart, state.dateRangeEnd);
      setState(prevState => ({ ...prevState, date_range }));
    }
  }, [state.dateRangeStart, state.dateRangeEnd]);

  function setDateRangeStart(date: Date) {
    setState(prevState => ({
      ...prevState,
      dateRangeStart: date,
    }));
  }

  function setDateRangeEnd(date: Date) {
    setState(prevState => ({
      ...prevState,
      dateRangeEnd: date,
    }));
  }

  function onCheckBoxChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value, checked, id } = e.target;
    let updateSelectedValues: any[] = [];
    if (state[id]) {
      updateSelectedValues = [...state[id]];
    }
    if (checked) {
      updateSelectedValues.push(Number(value));
    } else {
      const removeIndex = updateSelectedValues?.findIndex(valueId => valueId === Number(value));
      if (removeIndex !== -1) {
        updateSelectedValues.splice(removeIndex, 1);
      }
    }
    setState(prevState => ({ ...prevState, [id]: updateSelectedValues }));
  }

  function handleRemoveAll(id: string) {
    setState(prevState => ({ ...prevState, [id]: [] }));
    if (id === "department_ids") {
      setState(prevState => ({ ...prevState, category_ids: [], subcategory_ids: [] }));
    }
    if (id === "category_ids") {
      setState(prevState => ({ ...prevState, subcategory_ids: [] }));
    }
  }

  function handleAddAllFacilities(id: string) {
    const updatedSelectedFacilities: any[] = [];
    state.allFacilities?.forEach((facility: any) => {
      updatedSelectedFacilities.push(facility?.id);
    });
    setState(prevState => ({
      ...prevState,
      [id]: updatedSelectedFacilities,
    }));
  }

  function handleAddAllVendors(id: string) {
    const updatedSelectedVendors: any[] = [];
    state.allVendors?.forEach((vendor: any) => {
      updatedSelectedVendors.push(vendor?.id);
    });
    setState(prevState => ({
      ...prevState,
      [id]: updatedSelectedVendors,
    }));
  }

  function handleAddAllRegisters(id: string) {
    const updatedSelectedRegisters: number[] = [];
    state.allRegisters?.forEach(register => {
      updatedSelectedRegisters.push(register?.id);
    });
    setState(prevState => ({
      ...prevState,
      [id]: updatedSelectedRegisters,
    }));
  }

  function handleAddAllDepartments(id: string) {
    const updatedSelectedDepartments: number[] = [];
    state.allDepartments
      ?.filter(department => department.type === "department")
      .forEach(department => {
        updatedSelectedDepartments.push(department?.id);
      });
    setState(prevState => ({
      ...prevState,
      [id]: updatedSelectedDepartments,
    }));
  }

  function handleAddAllCategories(id: string) {
    const updatedSelectedCategories: number[] = [];
    state.allDepartments
      ?.filter(department => department.type === "category")
      .forEach(category => {
        updatedSelectedCategories.push(category?.id);
      });
    setState(prevState => ({
      ...prevState,
      [id]: updatedSelectedCategories,
    }));
  }

  function handleAddAllSubcategories(id: string) {
    const updatedSelectedSubcategories: number[] = [];
    state.allDepartments
      ?.filter(department => department.type === "subcategory")
      .forEach(subcategory => {
        updatedSelectedSubcategories.push(subcategory?.id);
      });
    setState(prevState => ({
      ...prevState,
      [id]: updatedSelectedSubcategories,
    }));
  }

  const primaryAction = {
    content: "Run",
    action: getReport,
  };

  const secondaryActions = {
    content: "Export",
    action: getDownload,
  };

  return (
    <Page
      title="Detailed Sales By Department Report"
      primaryAction={primaryAction}
      multipleActionDropdownAction={{
        label: "Options",
        dropdownProps: {
          alignment: "right",
          options: [
            {
              type: "handler",
              label: "Export",
              handler: () => getDownload(),
              icon: "file-arrow-down",
            },
            {
              type: "handler",
              label: "Print",
              handler: () => getPrint(),
              icon: "print",
            },
          ],
        },
      }}
      breadcrumbs={[{ prefix: true, label: "Back to Reports", url: "/admin/report" }]}
    >
      <Card>
        <Card.Section>
          <div className="report-filters-container">
            <DatePickerInput
              months={windowSize.width > TABLET_WIDTH ? 2 : 1}
              position="left"
              showQuickOptions={windowSize.width > MOBILE_WIDTH}
              startingDate={state.dateRangeStart}
              setStartingDate={setDateRangeStart}
              endingDate={state.dateRangeEnd}
              setEndingDate={setDateRangeEnd}
            />
            <FilterDropdown
              label={"Facilities"}
              data={state.allFacilities}
              dataLabelProperty={"long_name"}
              checkBoxId={"facility_ids"}
              dataValueProperty="id"
              onCheckBoxChange={onCheckBoxChange}
              onClear={() => handleRemoveAll("facility_ids")}
              onAddAll={() => handleAddAllFacilities("facility_ids")}
              iconName="chevron-down"
              leftAligned={windowSize.width <= MOBILE_WIDTH}
            />
            <FilterDropdown
              label={"Vendors"}
              data={state.allVendors}
              dataLabelProperty={"title"}
              checkBoxId={"vendor_ids"}
              dataValueProperty="id"
              onCheckBoxChange={onCheckBoxChange}
              onClear={() => handleRemoveAll("vendor_ids")}
              onAddAll={() => handleAddAllVendors("vendor_ids")}
              iconName="chevron-down"
              leftAligned={windowSize.width <= MOBILE_WIDTH}
            />
            <FilterDropdown
              label={"Departments"}
              data={state.allDepartments?.filter(department => department.type === "department")}
              dataLabelProperty={"title"}
              checkBoxId={"department_ids"}
              dataValueProperty="id"
              onCheckBoxChange={onCheckBoxChange}
              onClear={() => handleRemoveAll("department_ids")}
              onAddAll={() => handleAddAllDepartments("department_ids")}
              iconName="chevron-down"
              leftAligned={windowSize.width <= MOBILE_WIDTH}
            />
            {state.department_ids?.length > 0 ? (
              <FilterDropdown
                label={"Categories"}
                data={state.allDepartments
                  ?.filter(department => department.type === "category")
                  ?.filter(category => state.department_ids?.includes(category.parent_id))}
                dataLabelProperty={"title"}
                checkBoxId={"category_ids"}
                dataValueProperty="id"
                onCheckBoxChange={onCheckBoxChange}
                onClear={() => handleRemoveAll("category_ids")}
                onAddAll={() => handleAddAllCategories("category_ids")}
                iconName="chevron-down"
                leftAligned={windowSize.width <= MOBILE_WIDTH}
              />
            ) : null}
            {state.category_ids?.length > 0 ? (
              <FilterDropdown
                label={"Subcategories"}
                data={state.allDepartments
                  ?.filter(department => department.type === "subcategory")
                  ?.filter(subcategory => state.category_ids?.includes(subcategory.parent_id))}
                dataLabelProperty={"title"}
                checkBoxId={"subcategory_ids"}
                dataValueProperty="id"
                onCheckBoxChange={onCheckBoxChange}
                onClear={() => handleRemoveAll("subcategory_ids")}
                onAddAll={() => handleAddAllSubcategories("subcategory_ids")}
                iconName="chevron-down"
                leftAligned={windowSize.width <= MOBILE_WIDTH}
              />
            ) : null}
            {/* {state.facility_ids.length === 1 && (
              <FilterDropdown
                label={"Registers"}
                data={state.allRegisters}
                dataLabelProperty={"title"}
                checkBoxId={"register_ids"}
                dataValueProperty="id"
                onCheckBoxChange={onCheckBoxChange}
                onClear={() => handleRemoveAll("register_ids")}
                onAddAll={() => handleAddAllRegisters("register_ids")}
                iconName="chevron-down"
                leftAligned={windowSize.width <= MOBILE_WIDTH}
              />
            )} */}
          </div>
        </Card.Section>
      </Card>
      <div className="dynamic-table-container">
        <DetailedSalesByDepartmentTable
          data={reportData.rows}
          columns={reportData.columns}
          description={reportData.description}
          compileDate={reportData.compileDate}
        />
      </div>
    </Page>
  );
}
