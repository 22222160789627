import React, { ChangeEvent, useEffect, useMemo } from "react";

import { FilterValue, addFilter, applyDefaults, removeFilter } from "redux/actions/filters/filters";
import { ComponentKey, OrderFilterGrouping } from "redux/reducers/filters/types";
import { IFacilityStaff } from "redux/reducers/models/facility";

import { useAppDispatch, useAppSelector } from "hooks/redux";

import { useFilterAccordionContext } from "components/accordion/Accordion";
import Checkbox from "components/form/checkbox/Checkbox";

type FilterList = {
  staff: IFacilityStaff[];

  /** Value provided by FilterAccordionContext takes precedent if present. */
  collection?: ComponentKey;
  defaultValues?: number[];
  condensed?: boolean;
};

export default function FacilityStaffFilterList(props: FilterList) {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(store => store.orderPageFilters);
  const { collectionContext } = useFilterAccordionContext();

  const labelArray = useMemo(() => {
    if (props.staff) {
      const active = props.staff.filter(val => filters.some(condition => condition.value.includes(val.id)));
      return active.map(filter => filter.full_name);
    }

    return [];
  }, [filters, props.staff]);

  useEffect(() => {
    if (!props.staff || props.staff === undefined) {
      return;
    }

    if (props.defaultValues && !filters.find(group => group.label === "user_ids")) {
      const defaultActiveStaff = props.staff.filter(staff => props.defaultValues.some(valId => valId == staff.id));

      dispatch(
        applyDefaults<OrderFilterGrouping>({
          collectionName: collectionContext ? collectionContext : props.collection,
          key: "user_ids",
          value: defaultActiveStaff.map(staff => staff.id) as FilterValue[],
          displayLabels: defaultActiveStaff.map(staff => staff.full_name),
        }),
      );
    } else {
      dispatch(
        applyDefaults<OrderFilterGrouping>({
          collectionName: collectionContext ? collectionContext : props.collection,
          key: "user_ids",
          value: [] as FilterValue[],
          displayLabels: [""],
        }),
      );
    }
  }, [props.defaultValues, props.staff]);

  function listItemClick(prevChecked: boolean, id: number) {
    const newFilter = props.staff.filter(val => val.id === id)[0];
    const newLabel = props.staff
      .filter(val => labelArray.includes(val.full_name) || val.full_name === newFilter.full_name)
      .map(ele => ele.full_name);

    if (!prevChecked) {
      dispatch(
        addFilter<OrderFilterGrouping>({
          collectionName: collectionContext ? collectionContext : props.collection,
          key: "user_ids",
          value: id,
          displayLabels: newLabel,
        }),
      );
      return;
    }

    // remove filter condition if unchecked
    dispatch(
      removeFilter<OrderFilterGrouping>({
        collectionName: collectionContext ? collectionContext : props.collection,
        key: "user_ids",
        value: id,
        displayLabels: labelArray.filter(val => val !== newFilter.full_name),
      }),
    );
  }

  if (props.staff !== undefined) {
    return (
      <ul className={`filter-list${props.condensed ? " condensed" : ""}`}>
        {props.staff.map((staff, index) => {
          const checked = filters.some(condition => {
            return condition.value.includes(staff.id);
          });

          return (
            <li key={index} onClick={() => listItemClick(checked, staff.id)}>
              <Checkbox
                checked={checked}
                ariaChecked={checked}
                onChange={(e: ChangeEvent<HTMLInputElement>) => e.stopPropagation()}
                label={staff.full_name}
                size="small"
              />
            </li>
          );
        })}
      </ul>
    );
  }
  return null;
}
