import React from "react";
import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";
import "./cardSection.scss";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";

interface IProps {
  type: "one-row" | "two-row";
  onChange?: (
    e: StripeCardNumberElementChangeEvent | StripeCardExpiryElementChangeEvent | StripeCardCvcElementChangeEvent,
  ) => void;
}
function CardSection(props: IProps) {
  if (props.type === "two-row") {
    return (
      <div className="">
        <div className="ui-card-number">
          <span className="font-medium text-gray-500 text-sm">Card Number</span>
          <div className="border border-gray-500 rounded py-2 px-2 FormRow">
            <CardNumberElement
              onReady={(ele) => {
                ele.clear();
              }}
              onChange={(e) => props.onChange(e)}
              className=".StripeElement .StripeElement--focus .StripeElement--invalid .StripeElement--webkit-autofill"
            />
          </div>
        </div>
        <div className="">
          <div className="">
            <span className="font-medium text-gray-500 text-sm">Expiry</span>
            <div className="border border-gray-500 rounded py-2 px-2 FormRow">
              <CardExpiryElement
                onReady={(ele) => {
                  ele.clear();
                }}
                onChange={(e) => props.onChange(e)}
                className=".StripeElement .StripeElement--focus .StripeElement--invalid .StripeElement--webkit-autofill"
              />
            </div>
          </div>
          <div className="">
            <span className="font-medium text-gray-500 text-sm">CVC</span>
            <div className="border border-gray-500 rounded py-2 px-2 FormRow">
              <CardCvcElement
                onReady={(ele) => {
                  ele.clear();
                }}
                onChange={(e) => props.onChange(e)}
                className=".StripeElement .StripeElement--focus .StripeElement--invalid .StripeElement--webkit-autofill"
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="ui-credit-card">
        <div className="ui-credit-card_input ui-card-number_one-half">
          <span className="font-medium text-gray-500 text-sm">Card Number</span>
          <div className="flex flex-1 border border-gray-500 rounded FormRow">
            <CardNumberElement
              onReady={(ele) => {
                ele.clear();
              }}
              onChange={(e) => props.onChange(e)}
              className="w-full .StripeElement .StripeElement--focus .StripeElement--invalid .StripeElement--webkit-autofill"
            />
          </div>
        </div>
        <div className="ui-credit-card_input ui-card-number_one-quarter">
          <span className="font-medium text-gray-500 text-sm">Expiry</span>
          <div className="border border-gray-500 rounded FormRow">
            <CardExpiryElement
              onReady={(ele) => {
                ele.clear();
              }}
              onChange={(e) => props.onChange(e)}
              className="w-full .StripeElement .StripeElement--focus .StripeElement--invalid .StripeElement--webkit-autofill"
            />
          </div>
        </div>
        <div className="ui-credit-card_input ui-card-number_one-quarter">
          <span className="font-medium text-gray-500 text-sm">CVC</span>
          <div className="w-1/8 border border-gray-500 rounded FormRow">
            <CardCvcElement
              onReady={(ele) => {
                ele.clear();
              }}
              onChange={(e) => props.onChange(e)}
              className="w-full .StripeElement .StripeElement--focus .StripeElement--invalid .StripeElement--webkit-autofill"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CardSection;
