import { APIGet, APIPost, APIPut } from "api/protocols";
import { IUserPermissions } from "redux/reducers/models/user";

const apiVersion = "2024-04";
const adminType = "client-admin";
const baseAdminUrl = "/" + apiVersion + "/" + adminType + "/client/admin";

export interface IClientStaff {
  client_id: number;
  email: string;
  facility_id: number;
  first_name: string;
  full_name: string;
  id: number;
  last_name: string;
  permissions: IUserPermissions;
  state: string | null;
  user_type: number;
  username: string | null;
}

interface IPutFacilityAdmin {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  username: string;
  quick_code: number;
}

interface IPutFacilityAdminPermissions {
  user_id: number;
  accounts_charge_all: boolean;
  admin_accounts_disable: boolean;
  admin_permissions_edit: boolean;
  admin_accounts_edit: boolean;
  products_archive: boolean;
  products_create: boolean;
  products_edit: boolean;
  products_edit_cost: boolean;
  products_edit_price: boolean;
  orders_remove_line_item: boolean;
  orders_remove_line_item_after_send: boolean;
  orders_edit_line_item_price: boolean;
  orders_apply_discount: boolean;
  reports_access: boolean;
  tee_sheet_credit_card_override: boolean;
  tee_sheet_charge_no_show: boolean;
  orders_edit_user: boolean;
  orders_edit_register: boolean;
  orders_backdate: boolean;
  table_service_table_override: boolean;
  reservations_credit_card_override: boolean;

  cash_outs_create: boolean;
  cash_outs_edit: boolean;
  cash_outs_view_summary: boolean;

  memberships_apply_manual: boolean;
  user_edit_customer_type: boolean;
  gift_cards_import: boolean;
}

interface IPutDisableFacilityAdmin {
  admin_id: number;
}

interface IPutReactivateFacilityAdmin {
  admin_id: number;
}

export async function GetClientAdmin(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseAdminUrl, params, useGlobalLoader);
}

export async function PostClientAdmin(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseAdminUrl, params, useGlobalLoader);
}

export async function PutClientAdmin(params: IPutFacilityAdmin, useGlobalLoader: boolean) {
  return await APIPut(baseAdminUrl, params, useGlobalLoader);
}

export async function PutClientAdminPermissions(params: IPutFacilityAdminPermissions, useGlobalLoader: boolean) {
  return await APIPut(baseAdminUrl + "/permission", params, useGlobalLoader);
}

export async function PutDisableClientAdmin(params: IPutDisableFacilityAdmin, useGlobalLoader: boolean) {
  return await APIPut(baseAdminUrl + "/disable", params, useGlobalLoader);
}

export async function PutReactivateClientAdmin(params: IPutReactivateFacilityAdmin, useGlobalLoader: boolean) {
  return await APIPut(baseAdminUrl + "/reactivate", params, useGlobalLoader);
}
