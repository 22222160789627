import { ICustomer } from "./models/customer";

export interface ICustomerState {
  isLoaded: boolean;
  error?: any;
  customer?: ICustomer;
}

export interface ICustomerAction {
  type: string;
  payload: any;
}

const initialState: ICustomerState = {
  isLoaded: false,
  error: null,
  customer: null,
};

export default function CustomerReducer(state = initialState, action: ICustomerAction) {
  switch (action.type) {
    case "update.customer":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
