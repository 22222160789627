import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { ILeague } from "redux/reducers/models/league";

import Page from "components/page/Page";
import { THIS_YEAR } from "components/form/calendarField/helpers";
import Search from "components/search/Search";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";
import YearDropFilter from "components/filters/YearDropFilter";
import { GetLeague } from "api/rpc/2024-04/facilityAdmin/league/league";
import moment from "moment";

export default function Leagues() {
  const history = useHistory();
  const { t } = useTranslation();

  const [leagues, setLeagues] = useState<ILeague[]>(undefined);
  const [filterState, setFilterState] = useState({
    year: THIS_YEAR,
    search: "",
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadLeagues(source.token);

    return () => {
      source.cancel();
    };
  }, [filterState]);

  async function loadLeagues(token?: CancelToken) {
    if (leagues !== undefined) {
      setLeagues(undefined);
    }
    const leagueRes = await GetLeague(filterState, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (leagueRes.status !== StatusCode.OK) {
      setLeagues([]);
      return;
    }

    setLeagues(leagueRes.data);
  }

  const primaryAction = {
    content: t("secure.facility.league.leagues.001"),
    action: () => history.push("/admin/events/new"),
  };

  return (
    <Page title={t("secure.facility.league.leagues.002")} narrow primaryAction={primaryAction}>
      <div className="flex flex-row flex-wrap gap-4 pb-4">
        <YearDropFilter
          handleApply={year => setFilterState({ ...filterState, year: year })}
          defaultCheckboxes={[filterState.year]}
          leftAligned
        />
      </div>
      <div className="pb-4">
        <Search
          historyKey={"facility-leagues-search"}
          searchCallback={searchValue => {
            if (searchValue.length === 0 || searchValue.length > 2) {
              setFilterState(prev => ({ ...prev, search: searchValue }));
            }
          }}
          placeholder={t("secure.facility.league.leagues.007")}
        />
      </div>
      <DataTable
        columns={[
          { label: t("secure.facility.league.leagues.003"), width: "40%" },
          { label: t("secure.facility.league.leagues.004"), width: "30%" },
          { label: t("secure.facility.league.leagues.005"), width: "10%" },
          { label: "Start Date", width: "10%" }, // TODO: Translation
          { label: "End Date", width: "10%" }, // TODO: Translation
        ]}
        loading={leagues === undefined}
      >
        {leagues?.map(league => (
          <tr
            key={league.id}
            onClick={() => history.push("/admin/league/" + String(league.id) + "/home")}
            className="clickable"
          >
            <td>{league.name}</td>
            <td>{league.week_day}</td>
            <td>
              {league.total_participant_count} {""}
            </td>
            <td>{league.start_date !== null ? moment(league.start_date, "YYYY-MM-DD").format("LL") : ""}</td>
            <td>{league.end_date !== null ? moment(league.end_date, "YYYY-MM-DD").format("LL") : ""}</td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
