import React from "react";
import { useTranslation } from "react-i18next";

import { TComboVariants } from "redux/reducers/models/product";

import Sheet from "components/sheet/Sheet";
import Input from "components/form/input/Input";
import FormLayout from "components/form/FormLayout";

type UpdateProductAsComboProps = {
  isVisible: boolean;
  onCancel: () => void;
  onOk: (comboId: number, cost: string, price: string) => void;
  combo: TComboVariants;
  handleUpdates: (newState: Partial<{ selectedComboVariant: TComboVariants }>) => void;
};

export default function UpdateProductAsComboModal(props: UpdateProductAsComboProps) {
  const { t } = useTranslation();

  return (
    <Sheet
      open={props.isVisible}
      title={`Update '${props.combo.product.title}' Combo Product`} // TODO: Translation
      size="small"
      closable
      onCancel={() => props.onCancel()}
      cancelText={t("secure.facility.product.product.077")}
      backDropCancel={false}
      onOk={() => props.onOk(props.combo.id, props.combo.combo_pivot.cost, props.combo.combo_pivot.price)}
      okText="Save Combo Product" // TODO: Translation
    >
      <FormLayout>
        <FormLayout.Group>
          <Input
            id={"price"}
            label={"Price"} // TODO: Translation
            value={props.combo?.combo_pivot.price || ""}
            type={"number"}
            onChange={e =>
              props.handleUpdates({
                selectedComboVariant: {
                  ...props.combo,
                  combo_pivot: {
                    ...props.combo.combo_pivot,
                    price: e.target.value,
                  },
                },
              })
            }
            placeholder="0.00"
            prefix="$"
          />

          <Input
            id={"cost"}
            label={"Cost"} // TODO: Translation
            value={props.combo?.combo_pivot.cost || ""}
            type={"number"}
            onChange={e =>
              props.handleUpdates({
                selectedComboVariant: {
                  ...props.combo,
                  combo_pivot: {
                    ...props.combo.combo_pivot,
                    cost: e.target.value,
                  },
                },
              })
            }
            placeholder="0.00"
            prefix="$"
          />
        </FormLayout.Group>
      </FormLayout>
    </Sheet>
  );
}
