import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAppDispatch } from "hooks/redux";
import { showSuccess } from "redux/actions/ui";

import Input from "components/form/input/Input";
import { ButtonNew as Button } from "components/buttonNew";

interface ICopyLinkProps {
  label: string;
  link: string;
}

const CopyLink = (props: ICopyLinkProps) => {
  const dispatch = useAppDispatch();

  async function writeTextToClipBoard(link: string) {
    await navigator.clipboard.writeText(link);
    dispatch(showSuccess("Copied link to clipboard")); // TODO: Translation
  }

  return (
    <Input
      label={props.label}
      disabled={true}
      value={props.link}
      trailingButtons={[
        <Button key={2} onClick={() => writeTextToClipBoard(props.link)}>
          {" "}
          <FontAwesomeIcon icon={["far", "copy"]} style={{ marginRight: "5px" }} /> Copy {/* TODO: Translation */}{" "}
        </Button>,
      ]}
    />
  );
};

export default CopyLink;
