import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Checkbox from "components/form/checkbox/Checkbox";
import Sheet from "components/sheet/Sheet";
import Input from "components/form/input/Input";
import { Select as SelectNew } from "components/select/";
import { AccountingReferenceType, HouseAccountType } from "../../context/contextTypes";

type NewCustomerCreditModalProps = {
  customerId: number;
  isVisible: boolean;
  onClose: () => void;
  onOk: (title: string) => void;
};

export default function NewCustomerCreditModal(props: NewCustomerCreditModalProps) {
  const { customerId, isVisible, onClose } = props;

  const { t } = useTranslation();
  const { Option } = SelectNew;

  const [customerCredit, setHouseAccount] = useState({
    title: "",
  });

  function handleCloseModal() {
    setHouseAccount(prevState => ({
      ...prevState,
      title: "",
    }));

    onClose();
  }

  function handleChange(event: any) {
    const id = event?.target?.id;
    const value = event?.target?.value;

    if (id === undefined || value === undefined) {
      return;
    }

    setHouseAccount(prevState => ({ ...prevState, [id]: value }));
  }

  function handleOnOk(title: string) {
    props.onOk(title);
    handleCloseModal();
  }

  return (
    <Sheet
      open={isVisible}
      size="medium"
      height="flexible"
      closable
      title={t("secure.facility.customer.tabs.credit.new_customer_credit_modal.001")}
      onCancel={() => handleCloseModal()}
      onOk={() => handleOnOk(customerCredit.title)}
      cancelText={t("secure.facility.customer.tabs.credit.new_customer_credit_modal.002")}
      okText={t("secure.facility.customer.tabs.credit.new_customer_credit_modal.003")}
      okDisabled={customerCredit.title === "" ? true : false}
    >
      <Form>
        <FormLayout>
          <FormLayout.Group>
            <Input
              value={customerCredit.title}
              label={t("secure.facility.customer.tabs.credit.new_customer_credit_modal.004")}
              id="title"
              placeholder={t("secure.facility.customer.tabs.credit.new_customer_credit_modal.005")}
              onChange={handleChange}
            />
          </FormLayout.Group>
        </FormLayout>
      </Form>
    </Sheet>
  );
}
