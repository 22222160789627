import { AppDispatch } from "index";
import { IClientPortalState } from "redux/reducers/clientPortal";

export const clientPortalUpdate = (field: Partial<IClientPortalState>) => (dispatch: AppDispatch) => {
  dispatch({
    type: "clientPortal.update",
    payload: {
      ...field,
    },
  });
};

export interface IClientPortalActions {
  update: typeof clientPortalUpdate;
}
