import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import {
  PostTournamentFlights,
  TPostTournamentFlight,
} from "api/rpc/2024-04/facilityAdmin/tournament/scoring/flights/flights";
import { showError, showSuccess } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";
import { handleChangeEventInput } from "helpers/Helpers";

import { IPrimaryPageAction } from "components/page/PageActions";
import Page from "components/page/Page";
import Card from "components/card/Card";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";

export default function TournamentFlightNew() {
  const history = useHistory();
  const { tournamentId } = useParams<{ tournamentId: string }>();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [flight, setFlight] = useState<TPostTournamentFlight>({
    name: "",
  });

  async function createFlight(updateState: TPostTournamentFlight) {
    const params: TPostTournamentFlight = {
      tournament_id: tournamentId,
      ...updateState,
    };

    const res = await PostTournamentFlights(params, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError(typeof res.data === "string" ? res.data : "Error creating new flight")); // TODO: Translation
      return;
    }
    dispatch(showSuccess("Successfully created new flight")); // TODO: Translation
    history.push(`/admin/tournament/${tournamentId}/flights`);
  }

  const primaryAction: IPrimaryPageAction = {
    content: "Create Flight", // TODO: Translation
    action: () => createFlight(flight),
    disabled: !flight.name,
  };

  return (
    <Page
      title={"New Flight"} // TODO: Translation
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        { prefix: true, label: "Flights", /* TODO: Translation */ url: `/admin/tournament/${tournamentId}/flights` },
      ]}
    >
      <Form>
        <Card title={"Flight Details"} /* TODO: Translation */>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  id="name"
                  value={flight.name}
                  onChange={e => handleChangeEventInput(e, flight, setFlight)}
                  label={t("secure.facility.league.new.012")}
                  placeholder={t("secure.facility.league.new.012")}
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>
      </Form>
    </Page>
  );
}
