import React, { useEffect, useState } from "react";
import { StatusCode } from "api/protocols";
import Card from "components/card/Card";
import Input from "components/form/input/Input";
import Page from "components/page/Page";
import { useAppDispatch } from "hooks/redux";
import { useHistory } from "react-router";
import { showError, showSuccess } from "redux/actions/ui";
import { PostKitchenMeal } from "api/rpc/2022-09/facilityAdmin/facility/kitchen";
import { useTranslation, Trans } from "react-i18next";
import { loadFacilityKitchenCourses } from "redux/actions/facility";
interface IState {
  title: string;
}

export default function KitchenMealNew(props: any) {
  const [state, setState] = useState<IState>({
    title: "",
  });

  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setState(prevState => ({ ...prevState, [id]: value }));
  }

  async function createNewCourse() {
    const saveMealRes = await PostKitchenMeal({ title: state?.title }, true);
    if (saveMealRes?.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.kitchen_course.kitchen_course_new.001")));
      return;
    }
    dispatch(showSuccess(t("secure.facility.settings.kitchen_course.kitchen_course_new.002")));
    //Reload courses in redux
    await dispatch(loadFacilityKitchenCourses(null, true));
    history.push("/admin/settings/courses");
  }

  const primaryAction = {
    content: t("secure.facility.settings.kitchen_course.kitchen_course_new.003"),
    action: createNewCourse,
  };

  return (
    <Page
      title={t("secure.facility.settings.kitchen_course.kitchen_course_new.004")}
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.kitchen_course.kitchen_course_new.005"),
          url: "/admin/settings/courses",
        },
      ]}
      primaryAction={primaryAction}
      narrow
    >
      <Card>
        <Card.Section>
          <Input
            label={t("secure.facility.settings.kitchen_course.kitchen_course_new.006")}
            placeholder={t("secure.facility.settings.kitchen_course.kitchen_course_new.007")}
            id="title"
            value={state?.title ?? ""}
            onChange={handleInputChange}
          />
        </Card.Section>
      </Card>
    </Page>
  );
}
