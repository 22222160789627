import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
//import "../facility/facility.scss";

import Page from "components/page/Page";
import Card from "components/card/Card";
import { StatusCode } from "api/protocols";
import { GetDashboard } from "api/rpc/clientAdmin/client/dashboard";

export default function Dashboards(props: any) {
  const history = useHistory();
  const [state, setState] = useState({
    dashboards: null,
  });

  useEffect(() => {
    void loadDashboards();

    return () => {
      setState({ dashboards: null });
    };
  }, []);

  async function loadDashboards() {
    const res = await GetDashboard(null, true);
    if (res.status !== StatusCode.OK) {
      console.log(res.message);
      return;
    }

    setState(prev => ({
      ...prev,
      dashboards: res.data,
    }));
  }

  function navigateToNewDashboard() {
    history.push("/admin/dashboard/settings/new");
  }

  function navigateToNewTile() {
    history.push("/admin/dashboard/settings/tiles/new");
  }

  function navigateToEditDashboard(url: string) {
    history.push(url);
  }

  const primaryAction = {
    content: "New Dashboard",
    action: navigateToNewDashboard,
  };

  const secondaryAction = {
    content: "New Tile",
    action: navigateToNewTile,
  };

  return (
    <Page
      title="Dashboards"
      subtitle="Manage dashboards and tiles"
      narrow
      primaryAction={primaryAction}
      secondaryActions={[secondaryAction]}
      breadcrumbs={[{ prefix: true, label: "Dashboards", url: "/admin/dashboard-beta" }]}
    >
      <hr className="my-8" />
      <Card>
        <div className="overflow-auto">
          <div className="settings_table-container">
            <table className="settings_staff-table table-fixed">
              <thead>
                <tr className="settings_table-header">
                  <td>Title</td>
                </tr>
              </thead>
              <tbody>
                {state.dashboards?.map((dashboard: Record<string, any>, index: number) => {
                  return (
                    <Fragment key={index}>
                      <tr
                        onClick={() => navigateToEditDashboard("/admin/dashboard/settings/" + String(dashboard?.id))}
                        className="hover:bg-gray-200"
                      >
                        <td>{dashboard?.title}</td>
                      </tr>
                      {dashboard?.tiles?.map((tile: Record<string, any>, tileIndex: number) => {
                        return (
                          <tr
                            key={tileIndex}
                            onClick={() =>
                              navigateToEditDashboard("/admin/dashboard/settings/tiles/" + String(tile?.id))
                            }
                            className="hover:bg-gray-200"
                          >
                            <td style={{ textIndent: "1rem" }}>{tile?.title}</td>
                          </tr>
                        );
                      })}
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Card>
    </Page>
  );
}
