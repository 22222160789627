import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { ButtonNew as Button } from "components/buttonNew";
import Connected from "components/connected/Connected";

import "components/form/form.scss";
import "components/form/textField/textField.scss";

interface IProps {
  label?: string;
  value?: undefined | string | number;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  labelHidden?: boolean;
  labelAction?: any;
  multiLine?: any;
  alignRight?: any;
  placeholder?: string;
  prefix?: any;
  suffix?: any;
  connectedRight?: any;
  connectedLeft?: any;
  helpText?: string;
  error?: any;
  disabled?: boolean;
  maxLength?: number;
  showCharacterCount?: boolean;
  cleanButton?: any;
  cleanButtonClick?: any;
  name?: string;
  id?: string;
  onFocus?: any;
  rows?: number;
  readOnly?: boolean;
  autoFocus?: boolean;
}

const TextField = (props: IProps) => {
  const [charCount, setCharCount] = useState(0);
  const textFieldRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (props.autoFocus) {
      textFieldRef.current.focus();
    }
  }, []);
  return (
    <div className="ui-text-field">
      {!props.labelHidden ? (
        <div className="ui-text-field-label_container">
          <div className="ui-text-field-label">
            <label style={{ marginBottom: 0 }}>{props.label}</label>
          </div>
          {props.labelAction ? (
            <div className="ui-text-field_label-action">
              <Button type="link">{props.labelAction.content}</Button>
            </div>
          ) : null}
        </div>
      ) : null}

      {props.connectedLeft ? <div>{props.connectedLeft}</div> : null}

      <div className="ui-text-field_text-field">
        {props.prefix ? (
          <div className="position-relative z-20 text-gray-200 ml-5 mr-0">
            <span>{props.prefix}</span>
          </div>
        ) : null}
        <textarea
          ref={textFieldRef}
          className=""
          name={props.name}
          value={props.value}
          placeholder={props.placeholder}
          onChange={e => {
            setCharCount(e.target.value.length);
            props.onChange && props.onChange(e);
          }}
          disabled={props.disabled}
          maxLength={props.maxLength}
          onFocus={props.onFocus}
          id={props.id}
          rows={props.rows}
          readOnly={props.readOnly}
        />
        <div>
          {props.showCharacterCount ? (
            <div className="ui-text-field-character-counter">
              <span>
                {charCount}/{props.maxLength}
              </span>
            </div>
          ) : null}
          {props.suffix ? (
            <div className="position-relative z-20 text-gray-200 mr-5 ml-3.5">
              <span>{props.suffix}</span>
            </div>
          ) : null}
        </div>
      </div>

      {props.connectedRight ? <div>{props.connectedRight}</div> : null}

      {props.helpText ? (
        <div className="ui-text-field-help-text">
          <span>{props.helpText}</span>
        </div>
      ) : null}
    </div>
  );
};

export default TextField;
