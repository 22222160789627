import React from "react";

import { useHistory } from "react-router-dom";
import { valueToString } from "helpers/Helpers";
import classNames from "classnames";

import BaseReportTable from "./BaseReportTable";

import "components/reportTable/reportTable.scss";

interface IProps {
  data: Array<Record<string, any>>;
  columns: Array<Record<string, any>>;
  description: string;
  compileDate: string;
}

export default function TeeSheetOverviewTable(props: IProps) {
  const history = useHistory();

  function formatDisplay(type: string, value: string) {
    let newValue = value !== null && value !== undefined ? value : "";

    if (!isNaN(Number(newValue)) && newValue !== "") {
      switch (type) {
        case "currency":
          newValue = new Intl.NumberFormat("en-CA", { style: "currency", currency: "CAD" }).format(Number(value));
          break;
        case "decimal":
          newValue = Number(value).toFixed(2);
          break;
        case "percentage":
          newValue = `${(Number(value) * 100).toFixed(2)}%`;
          break;
        default:
          break;
      }
    }

    return newValue;
  }

  // Format cell data based on column
  function cellData(columnId: string, cellValue: string, displayType: string) {
    switch (columnId) {
      case "order_name":
        return formatDisplay(displayType, `Order ${String(cellValue)}`);
      default:
        return formatDisplay(displayType, cellValue);
    }
  }

  // Add on click events based on column
  function cellOnClick(cell: any) {
    return {};
  }

  function cellClassNames(cell: any, isRowLocked: boolean) {
    return classNames({
      "report-table-row-locked": isRowLocked,
    });
  }

  // Add footer data based on column
  function footerData(columnId: string) {
    switch (columnId) {
      case "total_spots":
      case "total_bookings":
      case "previous_year_total_spots":
      case "previous_year_total_bookings":
        return getTotal(columnId, "number");
      case "utilization":
        return getPercentage("total_bookings", "total_spots");
      case "previous_year_utilization":
        return getPercentage("previous_year_total_bookings", "previous_year_total_spots");
      default:
        return "";
    }
  }

  function getTotal(columnId: string, type: string) {
    let total = 0;
    props.data.forEach(row => {
      if (row[columnId]) {
        total += Number(row[columnId]);
      }
    });
    if (type === "currency") {
      return new Intl.NumberFormat("en-CA", { style: "currency", currency: "CAD" }).format(total);
    } else {
      return total;
    }
  }

  function getPercentage(numeratorColumnId: string, denominatorColumnId: string) {
    const percentage = Number(getTotal(numeratorColumnId, "number")) / Number(getTotal(denominatorColumnId, "number"));
    return `${(percentage * 100).toFixed(2)}%`;
  }

  return (
    <BaseReportTable
      data={props.data}
      columns={props.columns}
      description={props.description}
      compileDate={props.compileDate}
      cellOnClick={cellOnClick}
      cellData={cellData}
      cellClassNames={cellClassNames}
      footerData={footerData}
      expanding={false}
    />
  );
}
