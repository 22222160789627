import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";

const apiVersion = "2024-04";
const adminType = "master-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/facility";

interface IDepartments {
  id?: number;
  title?: string;
  type?: string;
  parent_id?: number;
  client_id: number;
}

//GET Departments
export async function GetDepartments(params: { client_id: number }, useGlobalLoader: boolean) {
  return await APIGet(baseUrl + "/department?extended=true", params, useGlobalLoader);
}

//POST Departments
export async function PostDepartments(params: IDepartments, useGlobalLoader?: boolean) {
  return await APIPost(baseUrl + "/department", params, useGlobalLoader);
}

//PUT Departments
export async function PutDepartments(params: IDepartments, useGlobalLoader?: boolean) {
  return await APIPut(baseUrl + "/department", params, useGlobalLoader);
}

//DELETE Departments
export async function DeleteDepartments(params: IDepartments, useGlobalLoader?: boolean) {
  return await APIDelete(baseUrl + "/department", params, useGlobalLoader);
}
