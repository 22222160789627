import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import Page from "components/page/Page";
import { StatusCode } from "api/protocols";
import Input from "components/form/input/Input";
import Card from "components/card/Card";
import Sheet from "components/sheet/Sheet";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import { useTranslation } from "react-i18next";
import Popup from "components/popup/Popup";
import { isEqualWith } from "lodash";
import {
  DeleteAccountingReference,
  GetAccountingReference,
  PutAccountingReference,
} from "api/rpc/2024-04/facilityAdmin/facility/accounting";
interface IAccountingReferenceState {
  id: number;
  title: string;
  reference_number: string;
}

export default function AccountingReferencesEdit(props: any) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [accRefState, setAccRefState] = useState<IAccountingReferenceState>({
    id: undefined,
    title: "",
    reference_number: "",
  });
  const [prevAccRefState, setPrevAccRefState] = useState<IAccountingReferenceState>(accRefState);
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);

  const [accRefBeforeChanges, setAccRefBeforeChanges] = useState(undefined);
  const [accRefLoaded, setAccRefLoaded] = useState<boolean>(false);

  useEffect(() => {
    void loadAccountingReferences();

    return () => {
      setAccRefState({ id: undefined, title: "", reference_number: "" });
      setPrevAccRefState({ id: undefined, title: "", reference_number: "" });
    };
  }, []);

  async function loadAccountingReferences() {
    const res = await GetAccountingReference(null, true);

    if (res.status !== StatusCode.OK) {
      return;
    }

    const currentRef = res.data.find((accRef: any) => accRef.id === Number(id));

    setAccRefState(prev => ({
      ...prev,
      id: currentRef.id,
      title: currentRef.title,
      reference_number: currentRef.reference_number,
    }));

    setPrevAccRefState(prev => ({
      ...prev,
      id: currentRef.id,
      title: currentRef.title,
      reference_number: currentRef.reference_number,
    }));

    setAccRefLoaded(true);
  }

  async function saveAccountingReference() {
    const res = await PutAccountingReference(accRefState, true);

    if (res.status !== StatusCode.OK) {
      return;
    }

    history.push("/admin/settings/accounting_references");
  }

  async function removeAccountingReference() {
    const res = await DeleteAccountingReference(prevAccRefState, true);

    if (res.status !== StatusCode.OK) {
      return;
    }

    history.push("/admin/settings/accounting_references");
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setAccRefState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleDeleteModalVisibility() {
    setDeleteModalVisibility(!deleteModalVisibility);
  }

  const primaryAction = {
    content: t("secure.facility.settings.accounting_references.accounting_edit.001"),
    action: saveAccountingReference,
  };

  const secondaryAction = {
    content: t("secure.facility.settings.accounting_references.accounting_edit.002"),
    action: handleDeleteModalVisibility,
  };

  function unsavedChangesExist() {
    if (accRefBeforeChanges === undefined) {
      if (accRefLoaded) {
        setAccRefBeforeChanges(accRefState);
      }
      return false;
    }

    return !isEqualWith(accRefBeforeChanges, accRefState);
  }

  function cancelUnsavedChanges() {
    setAccRefState(accRefBeforeChanges);
  }

  return (
    <div>
      <Page
        title={t("secure.facility.settings.accounting_references.accounting_edit.003")}
        narrow
        // primaryAction={primaryAction}
        secondaryActions={[secondaryAction]}
        breadcrumbs={[
          {
            prefix: true,
            label: t("secure.facility.settings.accounting_references.accounting_edit.004"),
            url: "/admin/settings/accounting_references",
          },
        ]}
        notificationBarProps={{
          isVisible: unsavedChangesExist(),
          onAction: saveAccountingReference,
          onCancel: cancelUnsavedChanges,
        }}
      >
        <Form>
          <Card>
            <Card.Section>
              <FormLayout>
                <FormLayout.Group>
                  <Input
                    value={accRefState.title}
                    label={t("secure.facility.settings.accounting_references.accounting_edit.005")}
                    id="title"
                    onChange={handleInputChange}
                    placeholder={t("secure.facility.settings.accounting_references.accounting_edit.006")}
                  />
                  <Input
                    value={accRefState.reference_number}
                    label={t("secure.facility.settings.accounting_references.accounting_edit.007")}
                    id="reference_number"
                    onChange={handleInputChange}
                    placeholder={t("secure.facility.settings.accounting_references.accounting_edit.008")}
                  />
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>
          </Card>
        </Form>
      </Page>

      <Popup
        open={deleteModalVisibility}
        type="warning"
        title={t("secure.facility.settings.accounting_references.accounting_edit.011")}
        description={t("secure.facility.settings.accounting_references.accounting_edit.012")}
        onOk={removeAccountingReference}
        okText={t("secure.facility.settings.accounting_references.accounting_edit.010")}
        onCancel={handleDeleteModalVisibility}
        backDropCancel={true}
      />
    </div>
  );
}
