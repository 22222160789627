import React, { useEffect, useState, useCallback } from "react";
import { useHistory, Link } from "react-router-dom";
import { useParams } from "react-router";

import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import { ButtonNew as Button } from "components/buttonNew";
import ResourceList from "components/resourceList/ResourceList";
import ResourceItem from "components/resourceList/ResourceItem";
import TextStyle from "components/text/textStyle/TextStyle";
import Stack from "components/stack/Stack";
import Checkbox from "components/form/checkbox/Checkbox";
import { Table } from "react-bootstrap";
import DataTable from "components/dataTable/DataTable";
import Sheet from "components/sheet/Sheet";
import FormLayout from "components/form/FormLayout";
import Input from "components/input/Input";
import Tabs from "components/tabs/Tabs";

import "../league/LeaguePlayers.scss";
import { GetCustomer } from "api/rpc/clientAdmin/customer/customer";
import { AddPlayerToLeague, GetLeaguePlayers } from "api/rpc/clientAdmin/league/league";
import { DeleteLeagueParticipant, GetLeague, PutParticipant } from "api/rpc/2024-04/clientAdmin/league/league";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "components/badge/Badge";
import moment from "moment";
import { NavigationDropdownNew } from "components/navigationDropdownNew/NavigationDropdownNew";
import { valueToString } from "helpers/Helpers";
import { useAppDispatch } from "hooks/redux";
import { useTranslation } from "react-i18next";
import { showError, showSuccess } from "redux/actions/ui";

interface ISortBy {
  /**Column to sort by. Default sorts the data by gross in ascending order */
  type: "default" | "registration_date";
  /**Ascending is true, Descending is false*/
  direction: boolean;
}

interface IMainState {
  leaguePlayers: IPlayer[];
  search: string;
  customers: IPlayer[];
  league: any;
  sortBy: ISortBy;
}

interface IPlayer {
  id: number;
  customer: IPlayerInfo;
  order_financial_status: string;
  created_at: string;
}

interface IPlayerInfo {
  full_name: string;
  email: string;
  phone: string;
  customer_type: string;
}

interface INewPlayerState {
  first_name: string;
  last_name: string;
  email_address: string;
  phone_number: string;
  newPlayerSheetActive: boolean;
}

export default function LeaguePlayers(props: any) {
  const history = useHistory();
  const { leagueId }: any = useParams();
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();

  const [state, setState] = useState<IMainState>({
    leaguePlayers: [],
    search: "",
    customers: [],
    league: null,
    sortBy: { type: "default", direction: true },
  });

  const [newPlayerState, setNewPlayerState] = useState<INewPlayerState>({
    first_name: "",
    last_name: "",
    email_address: "",
    phone_number: "",
    newPlayerSheetActive: false,
  });

  useEffect(() => {
    void loadPlayers();
    void loadLeague();
  }, []);

  async function loadPlayers() {
    const params = {
      league_id: leagueId,
    };

    const leaguePlayersRes = await GetLeaguePlayers(params, true);
    if (leaguePlayersRes.status === StatusCode.OK) {
      setState(prevState => ({
        ...prevState,
        leaguePlayers: leaguePlayersRes.data,
      }));
    }
  }

  async function loadLeague() {
    const leagueRes = await GetLeague(
      {
        id: leagueId,
      },
      true,
    );

    if (leagueRes.status === StatusCode.OK) {
      setState(prevState => ({
        ...prevState,
        league: leagueRes.data[0],
      }));
    }
  }

  function showNewPlayerSheet() {
    setNewPlayerState(prev => ({ ...prev, newPlayerSheetActive: true }));
  }

  function handleAddPlayer() {
    void showNewPlayerSheet();
  }

  const primaryAction = {
    content: "Add Player",
    action: handleAddPlayer,
  };

  function createPlayerInputChange(e: any) {
    const id = e.target.id;
    const value = e.target.value;

    setNewPlayerState(prev => ({ ...prev, [id]: value }));
  }

  function closeNewPlayerSheet() {
    setNewPlayerState(prev => ({
      ...prev,
      newPlayerSheetActive: false,
      first_name: "",
      last_name: "",
      email_address: "",
      phone_number: "",
    }));
  }

  async function createPlayer() {
    const params = {
      league_id: Number(leagueId),
      first_name: newPlayerState.first_name,
      last_name: newPlayerState.last_name,
      email: newPlayerState.email_address,
      phone_number: newPlayerState.phone_number,
    };

    const addPlayerToLeagueRes = await AddPlayerToLeague(params, true);
    console.log(addPlayerToLeagueRes);

    if (addPlayerToLeagueRes.status === StatusCode.OK) {
      void closeNewPlayerSheet();
      void loadPlayers();
    }
  }

  async function loadCustomers(searchQuery: string) {
    const customerRes = await GetCustomer({ search: searchQuery }, false);

    console.log("Customers;", customerRes);
    if (customerRes.status !== StatusCode.OK) {
      return;
    }

    return customerRes.data as [];
  }

  const tabs = [
    {
      id: "search-player",
      content: "Search Player",
    },
    {
      id: "add-player",
      content: "Add New Player",
    },
  ];

  // Handle tab change
  const [selected, setSelected] = useState(0);
  const handleTabChange = (selectedTabIndex: number) => {
    setSelected(selectedTabIndex);

    // if (selectedTabIndex === 0) {
    // }

    // if (selectedTabIndex === 1) {
    // }
  };

  const search = async (mounted: boolean, search: string) => {
    try {
      if (search === "") {
        if (mounted) {
          setState(prevState => ({ ...prevState, customers: null }));
        }
        return;
      } else {
        const res = await loadCustomers(search);
        if (mounted) {
          setState(prevState => ({ ...prevState, customers: res }));
        }
      }
    } catch (error) {
      console.log("err", error);
    }
    return;
  };

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = global.setTimeout(() => {
        void search(mounted, state.search);
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);

      setState(prevState => ({ ...prevState, customers: null }));
    };
  }, [state.search]);

  async function addNewPlayer(customer: any) {
    console.log(customer);
    console.log("Add New Player");

    const params = {
      league_id: leagueId,
      customer_id: Number(customer.id),
      //first_name: newPlayerState.first_name,
      //last_name: newPlayerState.last_name,
      //email: customer.email,
      // phone_number: newPlayerState.phone_number,
    };

    console.log(params);

    const addPlayerToLeagueRes = await AddPlayerToLeague(params, true);
    console.log(addPlayerToLeagueRes);

    if (addPlayerToLeagueRes.status === StatusCode.OK) {
      void loadPlayers();
      setNewPlayerState(prev => ({ ...prev, newPlayerSheetActive: false }));
    }
  }

  function sortByDefault() {
    const players = [...state.leaguePlayers];
    const sortedPlayers = players?.sort((currentPlayer, nextPlayer) => {
      if (!currentPlayer?.id) {
        return 1;
      } else if (!nextPlayer?.id) {
        return -1;
      } else {
        return currentPlayer?.id - nextPlayer?.id;
      }
    });
    setState(prevState => ({
      ...prevState,
      leaguePlayers: sortedPlayers,
      sortBy: { type: "default", direction: true },
    }));
  }

  function sortLeaguePlayers(sortType: "default" | "registration_date", dateValue: "created_at" | "updated_at") {
    const players = [...state.leaguePlayers];
    let direction: boolean = state?.sortBy?.direction;
    if (state?.sortBy?.type !== sortType) {
      direction = true;
    } else if (direction === false) {
      //Descending order, return to default order
      void sortByDefault();
      return;
    } else {
      direction = !direction;
    }

    const sortedPlayers = players.sort((currentPlayer, nextPlayer) => {
      const currentDate = new Date(currentPlayer?.[dateValue as keyof typeof currentPlayer] as unknown as Date);
      const nextDate = new Date(nextPlayer?.[dateValue as keyof typeof nextPlayer] as unknown as Date);

      // Sort null values last
      if (!currentPlayer?.[dateValue as keyof typeof currentPlayer]) {
        return 1;
      } else if (!nextPlayer?.[dateValue as keyof typeof nextPlayer] === null) {
        return -1;
      } else if (sortType === "registration_date") {
        return direction ? nextDate.valueOf() - currentDate.valueOf() : currentDate.valueOf() - nextDate.valueOf();
      }

      return 1;
    });

    setState(prevState => ({ ...prevState, leaguePlayers: sortedPlayers, sortBy: { type: sortType, direction } }));
  }

  async function updatePlayerFinancialStatus(event: React.MouseEvent<HTMLElement, MouseEvent>, player: any) {
    event.stopPropagation();

    const wantedStatus = player.order_financial_status == "paid" || null ? "unpaid" : "paid";

    const params = {
      id: player.id,
      order_financial_status: wantedStatus,
    };

    // Update player within the League
    const updatePlayerInLeagueRes = await PutParticipant(params, true);
    if (updatePlayerInLeagueRes.status !== StatusCode.OK) {
      return;
    }

    void loadPlayers(); // refresh the table
  }

  async function deleteLeagueParticipant(participant_id: number) {
    const deleteParticipantRes = await DeleteLeagueParticipant({ participant_id: participant_id }, true);

    if (deleteParticipantRes.status === StatusCode.OK) {
      dispatch(showSuccess("Successfully deleted the participant."));
      void loadPlayers(); // refresh the table
    } else {
      dispatch(showError("Something went wrong deleting the league participant."));
    }
  }

  return (
    <Page title="Player List" primaryAction={primaryAction}>
      <Card>
        <Card.Section>
          <div className="flex">
            <div className="player-count-container">
              <p className="player-counts">{"Total Players:"}</p> {/* TODO: Translate */}
              <p className="player-counts">{state.leaguePlayers?.length}</p>
            </div>

            <div className="player-count-container">
              <p className="player-counts">{"Total Paid:"} </p> {/* TODO: Translate */}
              <p className="player-counts">{state.league?.total_paid_participant_count} </p>
            </div>
          </div>
        </Card.Section>

        <Card.Section>
          <table className="ui-table league-players">
            <thead>
              <tr>
                <th>{"Name"}</th>
                <th>{"Email"}</th>
                <th>{"Phone"}</th>
                <th>{"Player Type"}</th>
                <th>{"Status"}</th>
                <th>
                  <p onClick={() => sortLeaguePlayers("registration_date", "created_at")} className="players-header">
                    {"Register Date"}
                    <FontAwesomeIcon
                      style={{ visibility: state?.sortBy?.type === "registration_date" ? "visible" : "hidden" }}
                      icon={["far", state?.sortBy?.direction ? "arrow-down-long" : "arrow-up-long"]}
                    />
                  </p>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {state.leaguePlayers &&
                state.leaguePlayers.map((player, index: number) => {
                  return (
                    <tr key={player.customer.full_name}>
                      <td>{player.customer.full_name}</td>
                      <td>{player.customer.email}</td>
                      <td>{player.customer.phone}</td>
                      <td>{player.customer.customer_type}</td>
                      <td>
                        {player.order_financial_status ? (
                          <>
                            {player.order_financial_status === "paid" ? (
                              <span>
                                <Badge
                                  type="success"
                                  size="medium"
                                  iconLeft
                                  icon="check"
                                  iconStyle="fas"
                                  // onClick={(e) => updatePlayerFinancialStatus(e, player)}
                                >
                                  {valueToString(player.order_financial_status, "capitalize")}
                                </Badge>
                              </span>
                            ) : (
                              <span>
                                <Badge
                                  type="gray"
                                  size="medium"
                                  iconLeft
                                  icon="circle"
                                  iconStyle="fas"
                                  // onClick={(e) => updatePlayerFinancialStatus(e, player)}
                                >
                                  {valueToString(player.order_financial_status, "capitalize")}
                                </Badge>
                              </span>
                            )}
                          </>
                        ) : (
                          <span>
                            <Badge
                              type="gray"
                              size="medium"
                              iconLeft
                              icon="circle"
                              iconStyle="fas"
                              // onClick={(e) => updatePlayerFinancialStatus(e, player)}
                            >
                              {"Unpaid"} {/* TODO: Translate */}
                            </Badge>
                          </span>
                        )}
                      </td>
                      <td>
                        <span>{moment.utc(player.created_at).local().format("MMM DD, YYYY")}</span>
                      </td>
                      <td>
                        <NavigationDropdownNew
                          showPlainTextLabel
                          rightAlign
                          label={<FontAwesomeIcon icon={["far", "chevron-down"]} />}
                          sections={[
                            [
                              {
                                icon: "ban",
                                label: "Remove",
                                onClick: () => deleteLeagueParticipant(player.id),
                              },
                            ],
                          ]}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </Card.Section>
      </Card>

      <Sheet
        open={newPlayerState.newPlayerSheetActive}
        size="small"
        closable
        stacked
        title={"New Player"}
        onCancel={closeNewPlayerSheet}
        onOk={createPlayer}
        cancelText={"Cancel"}
        okText={"Save"}
        okDisabled={
          newPlayerState.first_name === "" ||
          newPlayerState.last_name === "" ||
          (newPlayerState.email_address === "" && newPlayerState.phone_number === "")
        }
      >
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          {selected === 0 ? (
            <div style={{ marginTop: "20px" }}>
              <div className="flex-grow mb-4">
                <Input
                  value={state.search}
                  onChange={(value: any) => setState(prevState => ({ ...prevState, search: value.target.value }))}
                  type="search"
                  placeholder="Search for a player..."
                ></Input>
              </div>

              <div className="flex-grow mb-4">
                {state.customers?.map((customer: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className="flex justify-between league-customer-results"
                      onClick={() => addNewPlayer(customer)}
                    >
                      <div>
                        <div className="font-semibold text-lg">{customer?.full_name}</div>
                        <div className="text-sm text-gray-500">{customer.email}</div>
                        <div className="text-sm text-gray-500">{customer.phone ? customer.phone : null}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}

          {selected === 1 ? (
            <div style={{ marginTop: "20px" }}>
              <FormLayout>
                <FormLayout.Group>
                  <Input
                    placeholder="First Name"
                    id="first_name"
                    value={newPlayerState.first_name}
                    onChange={createPlayerInputChange}
                  />
                  <Input
                    placeholder="Last Name"
                    id="last_name"
                    value={newPlayerState.last_name}
                    onChange={createPlayerInputChange}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Input
                    placeholder="Email Address"
                    id="email_address"
                    value={newPlayerState.email_address}
                    onChange={createPlayerInputChange}
                  />
                  <Input
                    placeholder="Phone Number"
                    id="phone_number"
                    value={newPlayerState.phone_number}
                    onChange={createPlayerInputChange}
                  />
                </FormLayout.Group>
              </FormLayout>
            </div>
          ) : null}
        </Tabs>
      </Sheet>
    </Page>
  );
}
