import { APIGet, APIPost, APIPut, APIDelete, StatusCode, APIUrl, AdminType } from "api/protocols";
import { CancelToken } from "axios";
import { ILeagueParticipant } from "redux/reducers/models/league";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseLeagueUrl = "/" + apiVersion + "/" + adminType + "/league";

export type TLeagueAnnouncement = {
  admin_user_id: number;
  author: Record<string, any>;
  content: string;
  created_at: Date;
  id: number;
  league_id: number;
  subject: string;
  updated_at: Date | null;
};

type GetLeagueAnnouncementType = {
  league_id: number | string;
  /** Optional param to specify single Announcement to return. */
  id?: number | string;
  limit?: number;
};
//GET Announcement
export async function GetAnnouncement(
  params: GetLeagueAnnouncementType,
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(APIUrl(AdminType.FacilityAdmin, "/league/announcement"), params, useGlobalLoader, token);
}

//POST New Announcement
export async function PostAnnouncement(params: any, useGlobalLoader: boolean) {
  return await APIPost(APIUrl(AdminType.FacilityAdmin, "/league/announcement"), params, useGlobalLoader);
}

// Update Announcement
export async function PutAnnouncement(params: any, useGlobalLoader: boolean) {
  return await APIPut(APIUrl(AdminType.FacilityAdmin, "/league/announcement"), params, useGlobalLoader);
}

// Delete Announcement
export async function DeleteAnnouncement(params: any, useGlobalLoader: boolean) {
  return await APIDelete(APIUrl(AdminType.FacilityAdmin, "/league/announcement"), params, useGlobalLoader);
}

// Resend Announcement
export async function PutResendAnnouncement(params: any, useGlobalLoader: boolean) {
  return await APIPut(APIUrl(AdminType.FacilityAdmin, "/league/announcement/resend"), params, useGlobalLoader);
}
