import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { StatusCode } from "api/protocols";
import moment from "moment";
import classNames from "classnames";
import { capitalize, formatDate } from "helpers/Helpers";
import { LocaleCurrency } from "helpers/Locale";
import ReactDOM from "react-dom";
import { isEqualWith } from "lodash";

import {
  GetReservationTemplate,
  GetReservationLocation,
  PutReservationTemplate,
} from "api/rpc/facilityAdmin/reservation/reservation";
import { GetLeague } from "api/rpc/facilityAdmin/league/league";
import { GetTournament } from "api/rpc/facilityAdmin/tournament/tournament";
import { GetProduct } from "api/rpc/facilityAdmin/product/products";

import Page from "components/page/Page";
import Card from "components/card/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../reservation/editSegments.scss";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import { Select } from "components/select/index";
import { ButtonNew as Button } from "components/buttonNew";
import { ILocation, ISegment, ITemplate } from "redux/reducers/models/reservations";
import { ILeague } from "redux/reducers/models/league";
import { ITournament } from "redux/reducers/models/tournament";
import { IProduct, IVariant } from "redux/reducers/models/product";
import Checkbox from "components/form/checkbox/Checkbox";
import Spin from "components/spin/spin";
import Icon from "components/icon/Icon";
import Sheet from "components/sheet/Sheet";
import Callout from "components/callout/Callout";
import Input from "components/form/input/Input";
import { Badge } from "components/badge/Badge";

interface IEditTemplateState {
  locations: Array<ILocation>;
  template: ITemplate;
  segments: any;
  leagues: Array<ILeague>;
  tournaments: Array<ITournament>;
  tournament_id: number;
  league_id: number;
  blocked_type: string;
  web_booking_enabled: boolean;
  credit_card_required: boolean;
  pre_paid_required: boolean;
  products: Array<IProduct>;
  variant: IVariant;
  product: IProduct;
  productsModalVisible: boolean;
}

interface IFilterState {
  tournamentSearchValue: string;
  tournamentSearching: boolean;
  selectedTournament: ITournament;
  leagueSearchValue: string;
  leagueSearching: boolean;
  selectedLeague: ILeague;
  productSearchValue: string;
  selectedProduct: IProduct;
  selectedVariant: IVariant;
}

export default function ReservationTemplateEdit() {
  const { templateId } = useParams<{ templateId: string }>();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { Option } = Select;

  const [state, setState] = useState<IEditTemplateState>({
    locations: null,
    template: null,
    segments: null,
    leagues: null,
    tournaments: null,
    tournament_id: -1,
    league_id: -1,
    blocked_type: "open",
    web_booking_enabled: false,
    credit_card_required: false,
    pre_paid_required: false,
    products: [],
    variant: null,
    product: null,
    productsModalVisible: false,
  });

  const [filters, setFilters] = useState<IFilterState>({
    tournamentSearchValue: "",
    tournamentSearching: false,
    selectedTournament: null,
    leagueSearchValue: "",
    leagueSearching: false,
    selectedLeague: null,
    productSearchValue: "",
    selectedProduct: null,
    selectedVariant: null,
  });

  const [segments, setSegments] = useState<Array<ISegment>>(undefined);
  const [segmentsBeforeChanges, setSegmentsBeforeChanges] = useState<Array<ISegment>>(undefined);
  const [editSegments, setEditSegments] = useState<Array<number>>([]);

  const [selectedSegmentId, setSelectedSegmentId] = useState<number>(null);

  const blockedTypes = ["open", "blocked"];

  useEffect(() => {
    void loadTemplate();
  }, []);

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (() => {
          try {
            if (filters.productSearchValue === "") {
              if (mounted) {
                setState(prevState => ({ ...prevState, products: [], selectedVariant: null }));
              }
              return;
            } else {
              if (mounted) {
                void loadProducts();
              }
            }
          } catch (error) {
            console.log("Products error", error);
          }
          return;
        })();
      }, 500);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [filters.productSearchValue]);

  async function loadProducts() {
    const productRes = await GetProduct({ search: filters.productSearchValue, extended: true }, true);
    if (productRes.status !== StatusCode.OK) {
      return;
    }
    setState(prevState => ({ ...prevState, products: productRes.data }));
  }

  async function loadTemplate() {
    const templateRes = await GetReservationTemplate({ id: Number(templateId) }, true);

    if (templateRes.status !== StatusCode.OK) {
      return;
    }

    const formattedSegments = templateRes.data[0].segments?.reduce(
      (formattedSegments: { [key: string]: Array<any> }, currentSegment: any) => {
        return {
          ...formattedSegments,
          [currentSegment.start_time]: formattedSegments[currentSegment.start_time]
            ? [...formattedSegments[currentSegment.start_time], currentSegment]
            : [currentSegment],
        };
      },
      {},
    );

    const locationRes = await GetReservationLocation({ reservation_module_id: templateRes.data[0].module_id }, false);
    if (locationRes.status !== StatusCode.OK) {
      return;
    }

    const leaguesRes = await GetLeague(null, false);
    if (leaguesRes.status !== StatusCode.OK) {
      return;
    }

    const tournamentRes = await GetTournament(null, false);
    if (tournamentRes.status !== StatusCode.OK) {
      return;
    }

    const sortedSegments = templateRes.data[0].segments.sort(
      (a: ISegment, b: ISegment) => a?.start_time?.localeCompare(b?.start_time) || a?.location_id - b?.location_id,
    );

    ReactDOM.unstable_batchedUpdates(() => {
      setState(prevState => ({
        ...prevState,
        template: templateRes.data[0],
        locations: locationRes.data,
        segments: formattedSegments,
        leagues: leaguesRes.data,
        tournaments: tournamentRes.data,
      }));
      setSegments(sortedSegments);
      setSegmentsBeforeChanges(sortedSegments);
    });
  }

  async function saveTemplate() {
    const changedSegments = segments?.filter((segment, index) => !isEqualWith(segment, segmentsBeforeChanges[index]));
    if (changedSegments?.length > 0) {
      const segmentRes = await PutReservationTemplate({ segments: changedSegments }, true);
      if (segmentRes?.status !== StatusCode.OK) {
        return;
      }
      void loadTemplate();
    }

    setState(prevState => ({
      ...prevState,
      tournament_id: -1,
      league_id: -1,
      blocked_type: "open",
      web_booking_enabled: false,
      credit_card_required: false,
      pre_paid_required: false,
      product: null,
      variant: null,
    }));
  }

  function handleSegmentClick(segment_id: number, e: React.MouseEvent) {
    const shiftKeyPressed = e.shiftKey;

    const updatedSelectedSegmentIds = [...editSegments];
    const segmentIndex = updatedSelectedSegmentIds.indexOf(segment_id);
    if (segmentIndex === -1) {
      updatedSelectedSegmentIds.push(segment_id);

      //Shift key not pressed - set state of last selected segment
      if (!shiftKeyPressed) {
        setSelectedSegmentId(segment_id);
      } else {
        //Shift key pressed - set range of segments
        void setSegmentSelectRange(segment_id);
        return;
      }
    } else {
      updatedSelectedSegmentIds.splice(segmentIndex, 1);
      if (updatedSelectedSegmentIds?.length > 0) {
        setSelectedSegmentId(updatedSelectedSegmentIds[updatedSelectedSegmentIds?.length - 1]);
      } else {
        setSelectedSegmentId(null);
      }
    }
    setEditSegments(updatedSelectedSegmentIds);
  }

  function setSegmentSelectRange(currentSegmentId: number) {
    const segmentsArray = [...segments];
    const currentSegmentIndex = segmentsArray?.findIndex(segment => segment?.id === currentSegmentId);
    const prevSegmentIndex = segmentsArray?.findIndex(segment => segment?.id === selectedSegmentId);

    if (currentSegmentIndex !== -1 && prevSegmentIndex !== -1) {
      //Only select segments in the same location
      const locationId = segmentsArray[prevSegmentIndex]?.location_id;
      let segmentRange: Array<number>;
      if (currentSegmentIndex > prevSegmentIndex) {
        segmentRange = segmentsArray
          .slice(prevSegmentIndex, currentSegmentIndex + 1)
          ?.filter(filteredSegment => filteredSegment?.location_id === locationId)
          ?.map(segment => Number(segment?.id));
      } else {
        segmentRange = segmentsArray
          .slice(currentSegmentIndex, prevSegmentIndex + 1)
          ?.reverse()
          ?.filter(filteredSegment => filteredSegment?.location_id === locationId)
          ?.map(segment => Number(segment?.id));
      }
      setEditSegments(prevState => [...prevState, ...segmentRange]);
    } else {
      setSelectedSegmentId(null);
      void deselectAllSegments();
    }
  }

  function selectAllSegments() {
    const allSegmentIds = segments?.map(segment => Number(segment?.id));
    setEditSegments(allSegmentIds);
  }

  function deselectAllSegments() {
    ReactDOM.unstable_batchedUpdates(() => {
      setEditSegments([]);
      setSelectedSegmentId(null);
    });
  }

  function handleApplyButton() {
    const updatedSegments = [...segments];
    editSegments?.forEach(segmentId => {
      const currentSegmentIndex = segments?.findIndex(segment => segment?.id === segmentId);
      const currentSegment = segments[currentSegmentIndex];

      if (currentSegmentIndex !== -1) {
        const updatedCurrentSegment: any = {
          ...currentSegment,
          blocked_type: state?.blocked_type !== "none" ? state?.blocked_type : currentSegment?.blocked_type,
          league_id:
            state?.league_id >= 1 ? state?.league_id : state?.league_id === -1 ? null : currentSegment?.league_id,
          tournament_id:
            state?.tournament_id >= 1
              ? state?.tournament_id
              : state?.tournament_id === -1
              ? null
              : currentSegment?.tournament_id,
          credit_card_required:
            state?.credit_card_required != null ? state?.credit_card_required : currentSegment?.credit_card_required,
          web_booking_enabled:
            state?.web_booking_enabled != null ? state?.web_booking_enabled : currentSegment?.web_booking_enabled,
          product_variant_id: state?.variant != null ? state?.variant.id : currentSegment?.product_variant_id,
          variant_title: state?.variant != null ? state?.variant.title : currentSegment?.variant_title,
          product_title: state?.variant != null ? state?.product?.title : currentSegment?.product_title,
          variant_price: state?.variant != null ? state?.variant.price : currentSegment?.variant_price,
        };

        updatedSegments[currentSegmentIndex] = updatedCurrentSegment;
      }
    });

    const formattedSegments = updatedSegments?.reduce(
      (formattedSegments: { [key: string]: Array<ISegment> }, currentSegment: ISegment) => {
        return {
          ...formattedSegments,
          [currentSegment.start_time]: formattedSegments[currentSegment.start_time]
            ? [...formattedSegments[currentSegment.start_time], currentSegment]
            : [currentSegment],
        };
      },
      {},
    );

    setEditSegments([]);
    setSegments(updatedSegments);
    setState(prevState => ({ ...prevState, segments: formattedSegments }));
  }

  function handleDropDownChange(value: string, id: string) {
    setState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleTournamentChange(value: any, id: string, tournament: ITournament) {
    setState(prevState => ({ ...prevState, [id]: tournament?.id }));
    setFilters(prevState => ({ ...prevState, tournamentSearchValue: "", selectedTournament: tournament }));
  }

  function handleLeagueChange(value: any, id: string, league: ILeague) {
    setState(prevState => ({ ...prevState, [id]: league?.id }));
    setFilters(prevState => ({ ...prevState, leagueSearchValue: "", selectedLeague: league }));
  }

  function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, checked, value } = event.target;

    if (!checked) {
      setState(prevState => ({ ...prevState, [id]: null }));
    } else {
      setState(prevState => ({ ...prevState, [id]: value === "true" ? checked : !checked }));
    }
  }

  function displayLeagues() {
    if (filters?.leagueSearchValue !== "") {
      return state?.leagues.filter(league =>
        league.name.toLowerCase().includes(filters.leagueSearchValue.toLowerCase()),
      );
    } else {
      return state?.leagues;
    }
  }

  function displayTournaments() {
    if (filters?.tournamentSearchValue !== "") {
      return state?.tournaments.filter(tournament =>
        tournament.name.toLowerCase().includes(filters.tournamentSearchValue.toLowerCase()),
      );
    } else {
      return state?.tournaments;
    }
  }

  function removeSelectedTournament() {
    setFilters(prevState => ({
      ...prevState,
      selectedTournament: null,
      tournamentSearching: false,
    }));
    setState(prevState => ({ ...prevState, tournament_id: null }));
  }

  function removeSelectedLeague() {
    setFilters(prevState => ({
      ...prevState,
      selectedLeague: null,
      leagueSearching: false,
    }));
    setState(prevState => ({ ...prevState, league_id: null }));
  }

  function unsavedChangesExist() {
    return !isEqualWith(segments, segmentsBeforeChanges);
  }

  function cancelUnsavedChanges() {
    const formattedSegments = segmentsBeforeChanges?.reduce(
      (formattedSegments: { [key: string]: Array<ISegment> }, currentSegment: ISegment) => {
        return {
          ...formattedSegments,
          [currentSegment.start_time]: formattedSegments[currentSegment.start_time]
            ? [...formattedSegments[currentSegment.start_time], currentSegment]
            : [currentSegment],
        };
      },
      {},
    );
    ReactDOM.unstable_batchedUpdates(() => {
      setSegments(segmentsBeforeChanges);
      setState(prevState => ({ ...prevState, segments: formattedSegments }));
    });
  }

  function selectVariant(variant: IVariant, product: IProduct) {
    if (filters.selectedVariant && filters.selectedVariant?.id === variant?.id) {
      setFilters(prevState => ({ ...prevState, selectedVariant: null, selectedProduct: null }));
    } else {
      setFilters(prevState => ({ ...prevState, selectedVariant: variant, selectedProduct: product }));
    }
  }

  function attachVariant() {
    ReactDOM.unstable_batchedUpdates(() => {
      setState(prevState => ({
        ...prevState,
        variant: filters?.selectedVariant,
        product: filters?.selectedProduct,
      }));
      setState(prevState => ({
        ...prevState,
        productModalVisibility: false,
        selectedVariant: null,
        selectedProduct: null,
        productsModalVisible: false,
      }));
    });
  }

  function detachVariant() {
    setState(prevState => ({ ...prevState, variant: null, product: null }));
  }

  function closeProductModal() {
    setState(prevState => ({
      ...prevState,
      productModalVisible: false,
      selectedVariant: null,
      selectedProduct: null,
    }));
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setFilters(prevState => ({ ...prevState, [id]: value }));
  }

  const primaryAction = {
    content: "Save Template",
    action: saveTemplate,
  };

  return (
    <Page
      title="Edit Reservation Template"
      breadcrumbs={[{ prefix: true, label: "Back to Templates", url: "/admin/settings/reservations/templates" }]}
      primaryAction={primaryAction}
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: saveTemplate,
        onCancel: cancelUnsavedChanges,
      }}
    >
      <Card>
        <Card.Section>
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <div className="flex justify-left">
                  <Button size="medium" type="primary" onClick={handleApplyButton}>
                    {"Apply"}
                  </Button>
                  <Button size="medium" type="default" onClick={selectAllSegments}>
                    {"Select All"}
                  </Button>
                  <Button size="medium" type="default" onClick={deselectAllSegments}>
                    {"Unselect All"}
                  </Button>
                </div>
                <div />
              </FormLayout.Group>
              <FormLayout.Group>
                <Select
                  label="Blocked Type"
                  onChange={(value: string) => handleDropDownChange(value, "blocked_type")}
                  className="edit-segments-blocked-type-dropdown"
                  defaultValue={state?.blocked_type}
                >
                  {blockedTypes?.map((blocked_type, index) => {
                    return (
                      <Option key={index} value={blocked_type}>
                        {blocked_type !== "none" ? capitalize(blocked_type) : ""}
                      </Option>
                    );
                  })}
                </Select>
                {filters.selectedLeague ? (
                  <div>
                    <div>
                      <p className="event-label">League</p>
                    </div>

                    <div className="selected-container">
                      <div className="event-name">
                        <div>{filters.selectedLeague.name}</div>

                        <div>
                          <button className="" onClick={removeSelectedLeague}>
                            <Icon style="far" icon="times" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Select
                    label="League"
                    onChange={(value: any, league: ILeague) => handleLeagueChange(value, "league_id", league)}
                    className="edit-segments-blocked-type-dropdown"
                    defaultValue={state?.league_id}
                    placeholder="Search leagues..."
                    showSearch
                    searchValue={filters.leagueSearchValue}
                    showDropDownOnFocus={true}
                    searching={filters.leagueSearching}
                    onSearch={(query: string) =>
                      setFilters(prevState => ({ ...prevState, leagueSearchValue: query }))
                    }
                  >
                    <Option key={-1} value={-1} name={"None"}>
                      {"None"}
                    </Option>
                    {displayLeagues()?.map((league: ILeague, index: number) => {
                      return (
                        <Option key={index} value={league?.id} extraValues={league}>
                          {league?.name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
                {filters.selectedTournament ? (
                  <div>
                    <div>
                      <p className="event-label">Tournament</p>
                    </div>

                    <div className="selected-container">
                      <div className="event-name">
                        <div>{filters.selectedTournament.name}</div>

                        <div>
                          <button className="" onClick={removeSelectedTournament}>
                            <Icon style="far" icon="times" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Select
                    label="Tournament"
                    onChange={(value: string, tournament: ITournament) =>
                      handleTournamentChange(value, "tournament_id", tournament)
                    }
                    className="edit-segments-blocked-type-dropdown"
                    defaultValue={state?.tournament_id}
                    placeholder="Search tournaments..."
                    showSearch
                    searchValue={filters.tournamentSearchValue}
                    showDropDownOnFocus={true}
                    searching={filters.tournamentSearching}
                    onSearch={(query: string) =>
                      setFilters(prevState => ({ ...prevState, tournamentSearchValue: query }))
                    }
                  >
                    <Option key={-1} value={-1} name="None">
                      {"None"}
                    </Option>
                    {displayTournaments()?.map((tournament: ITournament, index: number) => {
                      return (
                        <Option key={index} value={tournament?.id} extraValues={tournament}>
                          {tournament?.name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </FormLayout.Group>
              <FormLayout.Group>
                <div className="edit-segments-button-group">
                  <Button
                    size="medium"
                    type="default"
                    onClick={() =>
                      setState(prevState => ({
                        ...prevState,
                        productsModalVisible: true,
                      }))
                    }
                  >
                    {"Attach Product"}
                  </Button>
                  {state?.product && state?.variant && (
                    <div className="edit-segments-variant-badge-container">
                      <Badge type="primary">
                        <span>
                          {state?.product?.preferred_title ? state?.product?.preferred_title : state?.product?.title}{" "}
                          {state?.variant?.title !== state?.product?.title ? ` - ${state?.variant?.title}` : ""}{" "}
                          <LocaleCurrency amount={state?.variant?.price} />
                        </span>
                      </Badge>
                      <FontAwesomeIcon
                        size="1x"
                        icon={["far", "xmark"]}
                        onClick={detachVariant}
                        className="edit-segments-remove-variant"
                      />
                    </div>
                  )}
                </div>
              </FormLayout.Group>
              <FormLayout.Group>
                <div className="flex justify-between">
                  <div>
                    <label className="edit-segment-label">
                      {"Credit Card Required"} <FontAwesomeIcon size="1x" icon={["far", "credit-card"]} />
                    </label>
                    <Checkbox
                      id="credit_card_required"
                      size="medium"
                      value="true"
                      checked={state.credit_card_required !== null && state.credit_card_required}
                      onChange={handleCheckboxChange}
                      label={"Yes"}
                      circle
                    />
                    <Checkbox
                      id="credit_card_required"
                      size="medium"
                      value="false"
                      checked={state.credit_card_required !== null && !state.credit_card_required}
                      onChange={handleCheckboxChange}
                      label={"No"}
                      circle
                    />
                  </div>
                  <div>
                    <label className="edit-segment-label">
                      {"Web Booking Enabled"} <FontAwesomeIcon size="1x" icon={["far", "earth-americas"]} />
                    </label>
                    <Checkbox
                      id="web_booking_enabled"
                      size="medium"
                      value="true"
                      checked={state.web_booking_enabled !== null && state.web_booking_enabled}
                      onChange={handleCheckboxChange}
                      label={"Yes"}
                      circle
                    />
                    <Checkbox
                      id="web_booking_enabled"
                      size="medium"
                      value="false"
                      checked={state.web_booking_enabled !== null && !state.web_booking_enabled}
                      onChange={handleCheckboxChange}
                      label={"No"}
                      circle
                    />
                  </div>
                </div>
                <div />
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
        <Card.Section>
          <div className="edit-segments">
            <div className="segments-container">
              <table className="ui-table ui-table-condensed ui-table-separated-horizontal segments-table">
                <thead>
                  <tr className="segments-table-header">
                    <td className="segments-table-times-header">
                      {t("secure.facility.reservation.edit_segments.027")}
                    </td>
                    {state?.locations?.map((location: ILocation, locationIndex: number) => {
                      return <td key={locationIndex}>{location.title}</td>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {state.segments ? (
                    Object.keys(state?.segments)?.map((segmentKey, segmentKeyIndex) => {
                      return (
                        <tr key={segmentKeyIndex}>
                          <td className="time-text">{moment(segmentKey, "hh:mm:ss").format("h:mm A")}</td>
                          {state?.locations?.map((location: ILocation, locationIndex: number) => {
                            const segment = state?.segments[segmentKey]?.find(
                              (searchSegment: ISegment) => searchSegment?.location_id === location?.id,
                            );
                            return (
                              <td
                                key={locationIndex}
                                className={classNames("segment-available", {
                                  "segment-selected": editSegments.includes(segment?.id),
                                  "segment-league": segment?.league_id !== null,
                                  "segment-tournament": segment?.tournament_id !== null,
                                })}
                                onClick={(e: React.MouseEvent) => handleSegmentClick(segment?.id, e)}
                              >
                                <div className="segment-container">
                                  <p className="blocked-type-text">{capitalize(segment?.blocked_type)}</p>
                                  {segment?.product_variant_id && (
                                    <div className="variant-group">
                                      <p className="variant-title">{segment?.product_title}</p>
                                      {segment?.product_title !== segment?.variant_title && (
                                        <p className="variant-title">{segment?.variant_title}</p>
                                      )}
                                      <LocaleCurrency style={{ fontSize: "0.7rem" }} amount={segment?.variant_price} />
                                    </div>
                                  )}
                                  <div className="segment-options-container">
                                    {segment?.league_id && <FontAwesomeIcon size="1x" icon={["far", "users"]} />}
                                    {segment?.tournament_id && <FontAwesomeIcon size="1x" icon={["far", "trophy"]} />}
                                    {segment?.credit_card_required && (
                                      <FontAwesomeIcon size="1x" icon={["far", "credit-card"]} />
                                    )}
                                    {segment?.web_booking_enabled && (
                                      <FontAwesomeIcon size="1x" icon={["far", "earth-americas"]} />
                                    )}
                                  </div>
                                </div>
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  ) : (
                    <div
                      style={{ marginTop: "1rem", marginBottom: "1rem", pointerEvents: "none", textAlign: "center" }}
                    >
                      <span style={{ display: "inline-flex", transform: "scale(2)" }}>
                        <Spin />
                      </span>
                    </div>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Card.Section>
      </Card>

      <Sheet
        closable
        onCancel={closeProductModal}
        size="medium"
        open={state.productsModalVisible}
        cancelText={"Close"}
        okText={"Select"}
        title={"Attach Product"}
        onOk={attachVariant}
        okDisabled={!filters.selectedVariant ? true : false}
      >
        <Input
          value={filters.productSearchValue}
          onChange={handleInputChange}
          type="search"
          id={"productSearchValue"}
          placeholder={"Search products..."}
        />

        {state.products?.length > 0 ? (
          <>
            <div className="edit-segments-product-header">
              <div className="edit-segments-product-title">{t("secure.facility.reservation.edit_segments.034")}</div>
              <div className="edit-segments-price-title">{t("secure.facility.reservation.edit_segments.035")}</div>
            </div>
            <div className="edit-segments-products-container">
              {state.products?.map((product, productIndex) => {
                return (
                  <div
                    key={productIndex}
                    onClick={
                      product?.variant_count === 1 ? () => selectVariant(product?.variants[0], product) : undefined
                    }
                    className={classNames("edit-segments-product-list-item", {
                      "edit-segments-product-list-item-hover": product?.variant_count === 1,
                      "edit-segments-product-list-item-selected":
                        product?.variant_count === 1 && product?.variants[0]?.id === filters.selectedVariant?.id
                          ? true
                          : false,
                    })}
                  >
                    <div className="font-normal">
                      {product?.preferred_title ? product?.preferred_title : product?.title}
                    </div>
                    {product?.variant_count === 1 && <LocaleCurrency amount={product?.variants[0]?.price} />}
                    {product?.variant_count > 1 && (
                      <>
                        <div className="edit-segments-dashed-border"></div>
                        {product?.variants?.map((variant, variantIndex) => {
                          return (
                            <div
                              key={variantIndex}
                              onClick={() => selectVariant(variant, product)}
                              className={classNames(
                                "edit-segments-variant-list-item edit-segments-product-list-item-hover",
                                {
                                  "edit-segments-product-list-item-selected":
                                    filters.selectedVariant?.id === variant?.id,
                                },
                              )}
                            >
                              <div className="font-normal">{variant?.title}</div>
                              <LocaleCurrency amount={variant?.price} />
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <div className="pt-2">
            <Callout
              type="info"
              title={t("secure.facility.reservation.edit_segments.036")}
              content={t("secure.facility.reservation.edit_segments.037")}
            />
          </div>
        )}
      </Sheet>
    </Page>
  );
}
