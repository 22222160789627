import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as OnlineStoreActions from "redux/actions/customer/onlineStore";
import * as CustomerCartActions from "redux/actions/customer/cart";
import * as UIActions from "redux/actions/ui";
import Checkout from "pages/guest/OnlineStore/Checkout";

const mapStateToProps = (state: any) => {
  return {
    onlineStoreStore: { ...state.onlineStoreStore },
    cartStore: { ...state.customerCartStore },
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onlineStoreActions: bindActionCreators(
      {
        ...OnlineStoreActions,
      },
      dispatch,
    ),
    cartActions: bindActionCreators(
      {
        ...CustomerCartActions,
      },
      dispatch,
    ),
    uiActions: bindActionCreators(
      {
        ...UIActions,
      },
      dispatch,
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
