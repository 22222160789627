import { IRoutes } from "components/sideMenu/SideMenu";
import AccountOverview from "./AccountOverview";
import Reservations from "containers/bookingEngine/reservations";
import ViewReservation from "containers/bookingEngine/viewReservation";
import EditReservation from "containers/bookingEngine/editReservation";

const routes: IRoutes[] = [
  {
    path: "/booking-engine/:facilityShortName/:bookingEngineHandle/profile/overview",
    component: AccountOverview,
  },

  {
    path: "/booking-engine/:facilityShortName/:bookingEngineHandle/profile/reservations",
    component: Reservations,
  },

  {
    path: "/booking-engine/:facilityShortName/:bookingEngineHandle/profile/reservations/:id",
    component: ViewReservation,
  },

  {
    path: "/booking-engine/:facilityShortName/:bookingEngineHandle/profile/reservations/edit/:id",
    component: EditReservation,
  },
];

export default routes;
