import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as BookingEngineActions from "redux/actions/bookingEngine";
import UserMenuNav from "pages/guest/Customer/CustomerNavMenu/UserMenuNav";
import * as UiActions from "redux/actions/ui";

const mapStateToProps = (state: any) => {
  return {
    bookingEngineStore: { ...state.bookingEngineStore },
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    bookingEngineActions: bindActionCreators(
      {
        ...BookingEngineActions,
      },
      dispatch,
    ),
    uiActions: bindActionCreators(
      {
        ...UiActions,
      },
      dispatch,
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMenuNav);
