import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios, { CancelToken } from "axios";
import { useHistory } from "react-router-dom";

import { StatusCode } from "api/protocols";

import { TProductType } from "redux/reducers/models/product";

import Page from "components/page/Page";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";
import { GetProductTypes } from "api/rpc/2024-04/facilityAdmin/product/type";

export default function ProductTypes() {
  const history = useHistory();
  const [productTypes, setProductTypes] = useState<TProductType[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadProductTypes(source.token);
    return () => source.cancel();
  }, []);

  async function loadProductTypes(token?: CancelToken) {
    if (productTypes !== undefined) {
      setProductTypes(undefined);
    }

    const res = await GetProductTypes(null, token ? false : true, token);

    if (res.status !== StatusCode.OK) {
      setProductTypes([]);
      return;
    }

    setProductTypes(res.data);
  }

  return (
    <Page
      title="Product Types"
      primaryAction={{
        content: "Add Type", // TODO: Translation
        action: () => history.push("/admin/settings/types/new"),
        disabled: productTypes === undefined,
      }}
    >
      <DataTable
        columns={[
          { label: "Type", width: "90%" },
          { label: "", width: "10%" },
        ]}
        loading={productTypes === undefined}
      >
        {productTypes?.map((type, index) => (
          <tr
            key={type.id}
            className={type?.locked ? "" : "clickable"}
            onClick={type?.locked ? null : () => history.push(`/admin/settings/types/${type.id}`)}
          >
            <td>{type.title}</td>
            <td>{type.locked && <FontAwesomeIcon icon={["far", "cancel"]} />}</td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
