import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import { IFacilityActions } from "redux/actions/facility";
import { IUIActions, showError, showSuccess } from "redux/actions/ui";
import { IFacilityState } from "redux/reducers/facility";
import { IUIState } from "redux/reducers/ui";
import Page from "components/page/Page";
import Card from "components/card/Card";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import Select from "components/form/select/Select";
import { StatusCode } from "api/protocols";
import { PostStaffAccount } from "api/rpc/2024-04/masterAdmin/client/settings/staffAccount";
import validator from "validator";

interface IStaffAccountInputField {
  data: string;
  isDirty: boolean;
  isValid: boolean;
}

interface IAdminState {
  first_name: IStaffAccountInputField;
  last_name: IStaffAccountInputField;
  email: IStaffAccountInputField;
  password: IStaffAccountInputField;
  username: IStaffAccountInputField;
}

export default function StaffAccountNew() {
  const history = useHistory();
  const { masterClientStore } = useAppSelector(store => store);
  const [adminState, setAdminState] = useState<IAdminState>({
    first_name: { data: "", isDirty: false, isValid: false },
    last_name: { data: "", isDirty: false, isValid: false },
    email: { data: "", isDirty: false, isValid: false },
    password: { data: "", isDirty: false, isValid: false },
    username: { data: "", isDirty: false, isValid: false },
  });

  const dispatch = useAppDispatch();

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>, isValid: boolean) {
    const { id, value } = event.target;
    setAdminState(prevState => ({
      ...prevState,
      [id]: { ...prevState[id as keyof IAdminState], data: value, isValid, isDirty: true },
    }));
  }

  async function saveNewStaffAdmin() {
    if (
      adminState.first_name?.isValid &&
      adminState?.last_name?.isValid &&
      adminState?.password?.isValid &&
      adminState?.email?.isValid
    ) {
      const params = {
        client_id: masterClientStore?.client?.id,
        user_type: 2,
        first_name: adminState?.first_name?.data,
        last_name: adminState?.last_name?.data,
        email: adminState?.email?.data,
        password: adminState?.password?.data,
        username: adminState?.username?.data,
      };

      const res = await PostStaffAccount(params, true);
      if (res.status !== StatusCode.OK) {
        dispatch(showError("Error creating staff account"));
        return;
      }

      dispatch(showSuccess("Successfully created staff account"));
      history.push("/admin/client-settings/staff");
    } else {
      setAdminState(prevState => ({
        ...prevState,
        first_name: { ...prevState?.first_name, isDirty: true },
        last_name: { ...prevState?.last_name, isDirty: true },
        password: { ...prevState?.password, isDirty: true },
        email: { ...prevState?.email, isDirty: true },
      }));
    }
  }

  const primaryAction = {
    content: "Save",
    action: saveNewStaffAdmin,
    disabled: !masterClientStore.client,
  };

  return (
    <Page
      title="New Staff Account"
      subtitle={masterClientStore.client ? masterClientStore.client.full_name : "No Client Selected"}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[{ prefix: true, label: "Staff Accounts", url: "/admin/client-settings/staff" }]}
    >
      {masterClientStore.client && (
        <Form>
          <Card>
            <Card.Section>
              <FormLayout>
                <FormLayout.Group>
                  <Input
                    value={adminState.first_name?.data}
                    label="First Name"
                    id="first_name"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(e, e.target.value !== "" && e.target.value != null)
                    }
                    placeholder="First Name"
                    error={adminState?.first_name?.isDirty && !adminState?.first_name?.isValid}
                  />
                  <Input
                    value={adminState.last_name?.data}
                    label="Last Name"
                    id="last_name"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(e, e.target.value !== "" && e.target.value != null)
                    }
                    placeholder="Last Name"
                    error={adminState?.last_name?.isDirty && !adminState?.last_name?.isValid}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Input
                    value={adminState.email?.data}
                    label="Email"
                    id="email"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(
                        e,
                        e.target.value !== "" && e.target.value != null && validator.isEmail(e.target.value),
                      )
                    }
                    placeholder="Email"
                    error={adminState?.email?.isDirty && !adminState?.email?.isValid}
                  />
                  <Input
                    value={adminState.password?.data}
                    label="Password"
                    id="password"
                    type="password"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(
                        e,
                        e.target.value !== "" && e.target.value != null && e.target.value.length >= 5,
                      )
                    }
                    placeholder="Password"
                    error={adminState?.password?.isDirty && !adminState?.password?.isValid}
                    helpText={"Password length must be at least 5 characters"}
                  />
                </FormLayout.Group>

                <FormLayout.Group>
                  <Input
                    value={adminState.username?.data}
                    label="Username"
                    id="username"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(
                        e,
                        e.target.value !== "" && e.target.value != null && e.target.value.length >= 5,
                      )
                    }
                    placeholder="Username"
                    error={adminState?.username?.isDirty && !adminState?.username?.isValid}
                    helpText="Username length must be at least 5 characters"
                  />

                  <div></div>
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>
          </Card>
        </Form>
      )}
    </Page>
  );
}
