import React, { useEffect, useState } from "react";
import { StatusCode } from "api/protocols";
import "./clientPortalBooking.scss";
import "./clientPortalBookingConfirmation.scss";
import OrderSummaryLineItem from "components/bookingEngine/orderSummaryLineItem/OrderSummaryLineItem";
import { useHistory, useParams } from "react-router";
import { UserActive } from "api/rpc";
import { dequeue, enqueue, showError } from "redux/actions/ui";
import Card from "components/card/Card";
import { ButtonNew as Button } from "components/buttonNew";
import ReactDOM from "react-dom";
import { LocaleCurrency } from "helpers/Locale";
import { convertTime, formatDateFromString } from "helpers/Helpers";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { ClientPortalNavigation } from "../ClientPortalNavigation";
import { clientPortalUpdate } from "redux/actions/clientPortal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sortFacilities } from "redux/reducers/clientPortal";
import { ITaxLine } from "components/OrderSummary/OrderSummary";
import { GetClient } from "api/rpc/2024-04/customer/client";
import { GetFacility } from "api/rpc/2024-04/customer/facility";
import { GetCart } from "api/rpc/2024-04/cart";
import { GetBooking } from "api/rpc/2024-04/customer/booking";
import { GetCustomerPaymentMethods } from "api/rpc/2024-04/customer/customer";
import { useClientPortal } from "../useClientPortal";

interface IParams {
  clientShortName: string;
}

export default function ClientPortalBookingConfirmation(props: any) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { clientShortName } = useParams<IParams>();
  const clientPortalStore = useAppSelector(store => store.clientPortalStore);
  const dispatch = useAppDispatch();

  const [activeUser, setActiveUser] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [cartState, setCartState] = useState<Record<string, any>>(null);
  const [editBookingState, setEditBookingState] = useState<boolean>(true);
  // June 6, 2022
  const editDate = new Date("2022-06-06T00:00:00.000000Z").toUTCString();

  const queryString = window.location.search;
  const bookingToken = new URLSearchParams(queryString).get("token");
  const facilityShortName = new URLSearchParams(queryString).get("facility");
  const HOME_URL = useClientPortal().getHomePageUrl();

  useEffect(() => {
    void getCustomerLoggedIn();
    void setCart();
    if (clientPortalStore?.postedBooking) {
      void checkBookingEditTime(clientPortalStore?.postedBooking);
    }
    if (
      (clientPortalStore?.selectedTeeTime?.credit_card_required ||
        clientPortalStore?.selectedTeeTime?.pre_paid_required) &&
      clientPortalStore?.postedBooking?.paymentMethodId
    ) {
      void getCustomerPaymentMethod();
    }
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  useEffect(() => {
    if (clientPortalStore?.user !== null && clientPortalStore?.selectedTeeTime === null) {
      //Page was refreshed, get client & booking information
      void getClient();
    }
  }, [clientPortalStore?.user]);

  function setCart() {
    if (clientPortalStore.postedBooking) {
      setCartState(clientPortalStore.postedBooking.cart);
    }
  }

  async function getCustomerLoggedIn() {
    // Check if user is logged in
    if (clientPortalStore?.user === null) {
      dispatch(enqueue());
      const activeUserRes = await UserActive(false);
      dispatch(dequeue());
      if (activeUserRes.status === StatusCode.OK) {
        ReactDOM.unstable_batchedUpdates(() => {
          setActiveUser(true);
          dispatch(clientPortalUpdate({ user: activeUserRes.data }));
        });
      } else {
        //User is not logged in, send them back to the home page
        setActiveUser(false);
        dispatch(showError("User is not logged in. Please log in and try again."));
        void backToTeeTimes();
      }
    } else {
      setActiveUser(true);
    }
  }

  async function getClient() {
    const clientRes = await GetClient({ short_name: clientShortName }, true);
    if (clientRes.status !== StatusCode.OK || clientRes?.data?.length === 0) {
      dispatch(showError("Error getting client"));
      void backToTeeTimes();
      return;
    }

    const facilityRes = await GetFacility({ client_id: clientRes?.data[0]?.id, order_by: "long_name" }, true);
    if (facilityRes?.status !== StatusCode.OK) {
      dispatch(showError("Error getting facilities"));
      void backToTeeTimes();
      return;
    }

    const bookingRes = await GetBooking({ token: bookingToken, extended: true }, true);
    if (bookingRes.status !== StatusCode.OK || !bookingToken) {
      dispatch(showError("Error loading confirmation page."));
      void backToTeeTimes();
      return;
    }

    if (bookingRes.data?.length === 0) {
      dispatch(showError("Error retrieving booking"));
      void backToTeeTimes();
    }

    void checkBookingEditTime(bookingRes.data[0]);

    const teeTime = bookingRes.data[0]?.tee_time;

    const cartRes = await GetCart({ id: bookingRes.data[0]?.cart_id }, true);
    if (cartRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading confirmation page."));
      void backToTeeTimes();
      return;
    }

    let convertedStartTime: string = teeTime.start_time;
    if (teeTime?.start_time) {
      convertedStartTime = convertTime(teeTime.start_time);
    }

    const formatDate = formatDateFromString(teeTime.date);
    const formatTime = convertTime(teeTime?.start_time);
    const sortedFacilities = sortFacilities([...facilityRes?.data]);
    const selectedFacility = sortedFacilities?.find(facility => facility?.short_name === facilityShortName);

    const selectedClient = { ...clientRes?.data[0], facilities: sortedFacilities };

    ReactDOM.unstable_batchedUpdates(() => {
      dispatch(
        clientPortalUpdate({
          client: selectedClient,
          selectedFacility: selectedFacility ? selectedFacility : undefined,
          postedBooking: bookingRes?.data[0],
          selectedTeeTime: {
            ...bookingRes?.data[0]?.tee_time,
            formattedDate: formatDate,
            formattedStartTime: formatTime,
          },
        }),
      );
      setCartState(cartRes?.data[0]);
    });
  }

  function backToTeeTimes() {
    dispatch(clientPortalUpdate({ selectedTeeTime: null, selectedTeeTimeLock: null, postedBooking: null }));
    history.push(HOME_URL);
  }

  function editBooking() {
    const address = `/tee-on/portal/bookings/${clientPortalStore?.postedBooking?.id}/edit`;
    window.open(address);
  }

  async function getCustomerPaymentMethod() {
    const paymentMethodRes = await GetCustomerPaymentMethods(
      { id: clientPortalStore?.postedBooking?.paymentMethodId, client_id: clientPortalStore?.client?.id },
      true,
    );
    if (paymentMethodRes.status !== StatusCode.OK) {
      console.log("Error getting payment method");
      return;
    }

    if (paymentMethodRes.data?.length >= 1) {
      setPaymentMethod(paymentMethodRes.data[0]);
    }
  }

  function checkBookingEditTime(booking: Record<string, any>) {
    const completedAtDate = new Date(booking?.completed_at).toUTCString();

    const currentDateUTCString = new Date().toUTCString();

    const teeTimeDateSetUp = new Date(String(booking?.tee_time?.date) + "T" + String(booking?.tee_time?.start_time));
    //Set date-time based on facility timezone
    const teeTimeDateTimezoneString = new Date(teeTimeDateSetUp).toLocaleString("en-US", {
      timeZone: booking?.tee_time?.facility?.timezone,
    });
    const teeTimeEditTime = new Date(teeTimeDateTimezoneString);

    teeTimeEditTime.setHours(teeTimeEditTime.getHours() - booking?.tee_sheet_settings?.web_edit_hours);

    const teeTimeEditTimeUTCString = teeTimeEditTime.toUTCString();

    if (
      Date.parse(completedAtDate) <= Date.parse(editDate) ||
      Date.parse(currentDateUTCString) >= Date.parse(teeTimeEditTimeUTCString)
    ) {
      setEditBookingState(false);
    }
    return;
  }

  return (
    activeUser &&
    cartState && (
      <>
        <div className="client-portal-booking-background">
          <ClientPortalNavigation />
          <div className="client-portal-bc-facility-container">
            <div className="client-portal-booking-timer-facility-container">
              <span className="client-portal-bc-facility-logo">
                {clientPortalStore?.selectedFacility?.logo_source && (
                  <img
                    className="client-portal-bc-facility-logo"
                    src={clientPortalStore?.selectedFacility?.logo_source}
                    alt={"Facility Logo"}
                  />
                )}
              </span>
              <div className="flex flex-col gap-1 client-portal-bc-text-align-left-responsive">
                <p className="client-portal-booking-timer-course-name">
                  {clientPortalStore?.selectedFacility?.long_name}
                </p>
                <p className="client-portal-booking-timer-address">{`${clientPortalStore?.selectedFacility?.address_line_1}, ${clientPortalStore?.selectedFacility?.city}, ${clientPortalStore?.selectedFacility?.province_name}, ${clientPortalStore?.selectedFacility?.postal}`}</p>
              </div>
            </div>
          </div>

          <div className="client-portal-bc-card-container">
            <Card>
              <Card.Section>
                <div className="client-portal-bc-booked-container client-portal-bc-flex-column">
                  <p className="client-portal-bc-booked-text client-portal-bc-booked-title-text-responsive">
                    {"Your tee time has been booked!"}
                  </p>
                  <p className="client-portal-bc-booked-text-subtext client-portal-bc-booked-sub-text-responsive">
                    {clientPortalStore.postedBooking?.customer?.email &&
                    !clientPortalStore.postedBooking?.customer?.email?.includes("guest.teeon.com") ? (
                      <>
                        We&apos;ll send you a confirmation and receipt to
                        <span className="client-portal-bc-booked-text-confirmation-number client-portal-bc-booked-sub-text-responsive">
                          {" "}
                          {clientPortalStore.postedBooking?.customer?.email}{" "}
                        </span>
                        shortly
                      </>
                    ) : (
                      "We'll send a confirmation and receipt to your email shortly"
                    )}
                  </p>
                  <div className="client-portal-bc-booked-confirmation-container">
                    <p className="client-portal-bc-booked-text-confirmation client-portal-bc-booked-sub-text-responsive">
                      {"Confirmation Number:"}
                    </p>
                    <p className="client-portal-bc-booked-text-confirmation-number client-portal-bc-booked-sub-text-responsive">
                      {clientPortalStore.postedBooking?.confirmation_number}
                    </p>
                  </div>
                </div>
              </Card.Section>
            </Card>
          </div>

          <div className="client-portal-bc-card-container">
            <Card>
              <Card.Section>
                <p className="client-portal-bc-booking-details-card-title">{"Booking Details"}</p>
                <div className="client-portal-bc-booking-details-content">
                  <div className="client-portal-bc-booking-details-container">
                    <p className="client-portal-bc-booking-details-title client-portal-bc-booked-title-text-responsive">
                      {"Booked By"}
                    </p>
                    <div className="client-portal-bc-booking-details-golfer-details client-portal-bc-flex-column client-portal-bc-flex-grow">
                      <p className="client-portal-bc-booking-details-text client-portal-bc-booked-sub-text-responsive">
                        {clientPortalStore.postedBooking?.customer?.full_name ??
                          `${clientPortalStore.postedBooking?.first_name} ${clientPortalStore.postedBooking?.last_name}`}
                      </p>
                      {!clientPortalStore?.postedBooking?.customer?.email?.includes("guest.teeon.com") && (
                        <p className="client-portal-bc-booking-details-text client-portal-bc-booked-sub-text-responsive">
                          {clientPortalStore?.postedBooking?.customer?.email}
                        </p>
                      )}
                      <p className="client-portal-bc-booking-details-text client-portal-bc-booked-sub-text-responsive">
                        {clientPortalStore?.postedBooking?.customer?.phone}
                      </p>
                    </div>
                  </div>

                  <div className="client-portal-bc-booking-details-container">
                    <p className="client-portal-bc-booking-details-title client-portal-bc-booked-title-text-responsive">
                      {"Order Summary"}
                    </p>
                    <div className="client-portal-bc-booking-details-order-summary client-portal-bc-flex-column client-portal-bc-flex-grow">
                      <p className="client-portal-bc-booking-details-text client-portal-bc-booking-details-text-bold client-portal-bc-booked-sub-text-responsive">
                        {clientPortalStore?.selectedFacility?.long_name}
                      </p>
                      <p className="client-portal-bc-booking-details-text client-portal-bc-booked-sub-text-responsive">
                        {clientPortalStore?.selectedTeeTime?.formattedDate} •{" "}
                        {clientPortalStore?.selectedTeeTime?.formattedStartTime}
                      </p>
                      <p className="client-portal-bc-booking-details-text client-portal-bc-booked-sub-text-responsive">
                        {clientPortalStore?.postedBooking?.holes} {"Holes •"}{" "}
                        {clientPortalStore?.postedBooking?.quantity}
                        {clientPortalStore?.postedBooking?.quantity > 1 ? " Golfers " : " Golfer "}•{" "}
                        {clientPortalStore?.postedBooking?.power_cart_quantity === 0
                          ? "Walking"
                          : clientPortalStore?.postedBooking?.power_cart_quantity > 0
                          ? clientPortalStore?.postedBooking?.power_cart_quantity === 1
                            ? `${clientPortalStore?.postedBooking?.power_cart_quantity} Power Cart `
                            : `${clientPortalStore?.postedBooking?.power_cart_quantity} Power Carts `
                          : null}
                      </p>
                      <p className="client-portal-bc-add-players-button" onClick={editBooking}>
                        <FontAwesomeIcon icon={["far", "plus"]} />{" "}
                        {clientPortalStore?.postedBooking?.tee_time?.quantity_remaining === 0
                          ? "Edit Players"
                          : "Add Players"}
                      </p>
                      <div>
                        <p className="client-portal-bc-booking-details-text client-portal-bc-booking-details-text-bold client-portal-bc-booked-sub-text-responsive client-portal-bc-order-line-item">
                          {"Items"}
                        </p>
                        {cartState?.line_items?.map((lineItem: Record<string, any>, index: number) => {
                          return (
                            <div key={index} className="client-portal-bc-order-line-item">
                              <OrderSummaryLineItem
                                lineItemQuantity={lineItem.quantity}
                                lineItem={lineItem.product_title}
                                lineItemVariant={lineItem.variant_title}
                                prepaid={lineItem.pre_paid_required}
                                itemValue={
                                  <LocaleCurrency currency={cartState?.currency} amount={lineItem.subtotal_price} />
                                }
                                className="client-portal-bc-booking-details-text client-portal-bc-booked-sub-text-responsive"
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="client-portal-bc-booking-details-container">
                    <p className="client-portal-bc-booking-details-title client-portal-bc-booked-title-text-responsive">
                      {"Order Total"}
                    </p>
                    <div className="client-portal-bc-flex-column w-60 client-portal-bc-width-auto client-portal-bc-flex-grow">
                      {(clientPortalStore?.selectedTeeTime?.credit_card_required ||
                        clientPortalStore?.selectedTeeTime?.pre_paid_required) &&
                      paymentMethod?.brand &&
                      paymentMethod?.last4 ? (
                        <p className="client-portal-bc-booking-payment-card client-portal-bc-booked-sub-text-responsive">
                          {String(paymentMethod?.brand).charAt(0).toUpperCase() + String(paymentMethod?.brand).slice(1)}{" "}
                          {"ending in"} {paymentMethod?.last4}
                        </p>
                      ) : (
                        <p></p>
                      )}
                      <div className="flex flex-row justify-between">
                        <p className="client-portal-bc-booking-details-text client-portal-bc-booked-sub-text-responsive">
                          {"Subtotal"}
                        </p>
                        <p className="client-portal-bc-booking-details-text client-portal-bc-booked-sub-text-responsive">
                          <LocaleCurrency currency={cartState?.currency} amount={cartState?.subtotal_price} />
                        </p>
                      </div>
                      {cartState?.tax_lines?.map((taxLine: ITaxLine, index: number) => {
                        return (
                          <div key={index} className="flex flex-row justify-between">
                            <span className="client-portal-bc-booking-details-text client-portal-bc-booked-sub-text-responsive">
                              {taxLine?.title}{" "}
                              {taxLine?.rate && (
                                <span className="client-portal-bc-booked-subtitle">{`${taxLine?.rate * 100}%`}</span>
                              )}
                            </span>
                            <p className="client-portal-bc-booking-details-text client-portal-bc-booked-sub-text-responsive">
                              <LocaleCurrency currency={cartState?.currency} amount={taxLine?.price} />
                            </p>
                          </div>
                        );
                      })}
                      <div className="flex flex-row justify-between">
                        <p className="client-portal-bc-booking-details-text client-portal-bc-booked-sub-text-responsive">
                          {"Order Total"}
                        </p>
                        <p className="client-portal-bc-booking-details-text client-portal-bc-booked-sub-text-responsive">
                          <LocaleCurrency currency={cartState?.currency} amount={cartState?.total_price} />
                        </p>
                      </div>
                      <hr />
                      <div className="flex flex-row justify-between">
                        <p className="client-portal-bc-booking-details-text client-portal-bc-booking-details-text-bold-heavy client-portal-bc-booked-sub-text-responsive">
                          {"Total Due at Course"}
                        </p>
                        <p className="client-portal-bc-booking-details-text client-portal-bc-booking-details-text-bold-heavy client-portal-bc-booked-sub-text-responsive">
                          <LocaleCurrency
                            currency={cartState?.currency}
                            amount={
                              cartState?.total_price_due
                                ? cartState?.total_price - cartState?.total_price_due
                                : cartState?.total_price
                            }
                          />
                        </p>
                      </div>
                      {cartState?.total_price_due ? (
                        <div className="flex flex-row justify-between">
                          <p className="client-portal-bc-booking-details-text client-portal-bc-booking-details-text-bold-heavy client-portal-bc-booked-sub-text-responsive">
                            {"Total Paid"}
                          </p>
                          <p className="client-portal-bc-booking-details-text client-portal-bc-booking-details-text-bold-heavy client-portal-bc-booked-sub-text-responsive">
                            <LocaleCurrency currency={cartState?.currency} amount={cartState?.total_price_due} />
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="client-portal-bc-tee-times-button">
                  <Button type="primary" size="medium" onClick={backToTeeTimes}>
                    {"Back to Tee Times"}
                  </Button>
                  {editBookingState && (
                    <Button type="secondary" size="medium" onClick={editBooking}>
                      {"Edit Booking"}
                    </Button>
                  )}
                </div>
              </Card.Section>
            </Card>
          </div>
        </div>
      </>
    )
  );
}
