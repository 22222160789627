import React, { useEffect, useState } from "react";
import classNames from "classnames";
import "components/avatar/avatar.scss";

interface IProps {
  size?: string;
  placeholder?: boolean;
  text?: string;
  statusIcon?: boolean;
  state?: string;
  source?: string;
}

const Avatar = (props: IProps) => (
  <div
    className={classNames("ui-avatar", {
      [`ui-avatar-${props.size}`]: props.size,
    })}
  >
    <img src={props.source && !props.placeholder ? props.source : "/public/images/default_profile_image.svg"} />
  </div>
);

export default Avatar;
