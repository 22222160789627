import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import axios, { CancelToken } from "axios";
import { TFunction, useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { StatusCode } from "api/protocols";
import {
  GetAllLeagueTeamParticipants,
  GetLeagueTeams,
  PostLeagueTeamPlayers,
} from "api/rpc/2024-04/facilityAdmin/league/scoring/teams";
import { GetParticipant } from "api/rpc/2024-04/facilityAdmin/league/participant";

import { showError, showSuccess } from "redux/actions/ui";
import { searchHistoryUpdateSearch } from "redux/actions/searchBars/searchHistory";
import { ILeagueParticipant, TLeagueTeam } from "redux/reducers/models/league";
import { useAppDispatch } from "hooks/redux";
import { ignoreOrderCompare } from "helpers/Helpers";

import Page from "components/page/Page";
import Card from "components/card/Card";
import { Select } from "components/select";
import Tabs from "components/tabs/Tabs";
import FormLayout from "components/form/FormLayout";
import Spin from "components/spin/spin";
import Search from "components/search/Search";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

import "./leagueTeamManagePlayers.scss";

export default function LeaguePlayerManage() {
  const { leagueId, divisionId } = useParams<{ leagueId: string; divisionId: string }>();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [leagueTeams, setLeagueTeams] = useState<TLeagueTeam[]>([]);
  const [leaguePlayers, setLeaguePlayers] = useState<ILeagueParticipant[]>([]);

  const [activeTeamPlayerIds, setActiveTeamPlayerIds] =
    useState<{ league_team_id: number; league_participant_id: Array<number> }[]>(undefined);

  const [state, setState] = useState({
    tabIndex: 0,
    teamId: 0,
    search: "",
  });

  const [selectedIds, setSelectedIds] = useState({
    active: [] as Array<number>,
    preserved: [] as Array<number>,
  });

  // Always return list of teams with associated players given the leagueTeams & leaguePlayers & selectedIds.active
  // Auto updates with temporarily selected ID's in order to move player on UI
  const playersInTeam = useMemo(() => {
    if (leagueTeams.length === 0) {
      return [];
    }

    const teamIds = leagueTeams.map(team => team.id);

    return teamIds.map(id => {
      return {
        league_team_id: id,
        players: leaguePlayers.filter(player => selectedIds.active.includes(player.id)),
      };
    });
  }, [leagueTeams, leaguePlayers, state.teamId, selectedIds.active]);

  // League players whom are not currently found on any team
  const playersWithoutTeam =
    state.teamId === 0
      ? []
      : leaguePlayers.filter(player => !playersInTeam.some(({ players }) => players.some(val => val === player)));

  // League players whom are found on a different team than selected teamId & NOT temporarily selected
  const playersOnOtherTeam =
    state.teamId === 0
      ? []
      : leaguePlayers.filter(
          a =>
            !playersInTeam.some(
              ({ league_team_id, players }) => league_team_id === state.teamId && players.some(val => val === a),
            ) &&
            activeTeamPlayerIds?.some(
              ({ league_team_id, league_participant_id }) =>
                league_team_id !== state.teamId && league_participant_id.includes(a.id),
            ),
        );

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadLeagueTeams(leagueId, source.token);
    void loadTeamPlayers(leagueId, source.token);
    return () => source.cancel();
  }, [leagueId]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (state.teamId === 0) {
      return;
    }

    if (activeTeamPlayerIds !== undefined) {
      const activeDivisionTeam = activeTeamPlayerIds.filter(meta => meta.league_team_id === state.teamId)[0];

      // Reset ID's when teamId changes
      setSelectedIds(prev => ({
        ...prev,
        active: activeDivisionTeam ? activeDivisionTeam.league_participant_id : [],
        preserved: activeDivisionTeam ? activeDivisionTeam.league_participant_id : [],
      }));

      return;
    }

    // Load the current division participant ID's
    void loadTeamParticipantIds(leagueId, divisionId, source.token);
    return () => source.cancel();
  }, [leagueId, divisionId, state.teamId]);

  async function loadLeagueTeams(leagueId: string | number, token?: CancelToken) {
    const teamRes = await GetLeagueTeams(
      { league_id: leagueId, league_division_id: divisionId },
      token ? false : true,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (teamRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading league teams.")); // TODO: Translation
    }

    setLeagueTeams(teamRes.status !== StatusCode.OK ? [] : teamRes.data);

    // preset <Select />
    if (teamRes.data.length > 0) {
      setState(prev => ({ ...prev, teamId: teamRes.data[0].id }));
    }
  }

  async function loadTeamPlayers(leagueId: string | number, token?: CancelToken) {
    const res = await GetParticipant(
      { league_id: leagueId, league_division_id: divisionId },
      token ? false : true,
      token,
    );
    if (token && token.reason) {
      return;
    }

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading league players.")); // TODO: Translation
    }

    setLeaguePlayers(res.status !== StatusCode.OK ? [] : res.data);
  }

  /** Load player ID's associated with the selected team.  The data returned is used to filter out the leaguePlayers state */
  async function loadTeamParticipantIds(leagueId: string | number, divisionId: string | number, token?: CancelToken) {
    // Remove unsavedChangesBar
    setSelectedIds(prev => ({ ...prev, active: [], preserved: [] }));

    // page spinner
    if (activeTeamPlayerIds !== undefined) {
      setActiveTeamPlayerIds(undefined);
    }

    const res = await GetAllLeagueTeamParticipants(
      { league_id: leagueId, division_id: divisionId },
      token ? false : true,
      token,
    );
    if (token && token.reason) {
      return;
    }

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading all league players.")); // TODO: Translation
    }

    // map through result and structure response
    const map = new Map(
      res.data.map(({ league_team_id }) => [league_team_id, { league_team_id, league_participant_id: [] }]),
    );
    for (const { league_team_id, league_participant_id } of res.data) {
      map.get(league_team_id).league_participant_id.push(...[league_participant_id]);
    }

    setActiveTeamPlayerIds([...map.values()]);

    // preset ID's on page
    const startingTeamIds = [...map.values()].filter(val => val.league_team_id === state.teamId)[0];
    setSelectedIds(prev => ({
      ...prev,
      active: startingTeamIds ? startingTeamIds.league_participant_id : [],
      preserved: startingTeamIds ? startingTeamIds.league_participant_id : [],
    }));
  }

  async function updatePlayersOnTeam(teamId: string | number, playerIds: Array<number>) {
    const res = await PostLeagueTeamPlayers({ team_id: teamId, participant_ids: playerIds }, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error updating players on the team.")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Successfully updated players on the team")); // TODO: Translation
    void loadTeamParticipantIds(leagueId, divisionId);
  }

  function handlePlayerToggle(id: number) {
    setSelectedIds(prev => ({
      ...prev,
      active: prev.active.includes(id) ? prev.active.filter(value => value !== id) : [id, ...prev.active],
    }));
  }

  function onSelectChange(value: number) {
    setState(prev => ({ ...prev, teamId: value, search: "" }));
    dispatch(searchHistoryUpdateSearch("league_team_manage_players", ""));
  }

  function onTabChange(index: number) {
    setState(prev => ({ ...prev, tabIndex: index, search: "" }));
    dispatch(searchHistoryUpdateSearch("league_team_manage_players", ""));
  }

  return (
    <Page
      title="Manage Team Players" // TODO: Translation
      breadcrumbs={[
        {
          prefix: true,
          label: "Teams", // TODO: Translation
          url: `/admin/league/${leagueId}/divisions/${divisionId}/teams`,
        },
      ]}
      notificationBarProps={{
        isVisible: !ignoreOrderCompare(selectedIds.active, selectedIds.preserved),
        onAction: () => updatePlayersOnTeam(state.teamId, selectedIds.active),
        onCancel: () => setSelectedIds(prev => ({ ...prev, active: prev.preserved })),
      }}
    >
      <Card>
        <Card.Section>
          <Select
            label={"League Teams"} // TODO: Translation
            onChange={(value: number) => onSelectChange(value)}
            defaultValue={state.teamId}
            disabled={leagueTeams.length === 0}
          >
            {leagueTeams.map(team => (
              <Select.Option key={team.id} value={team.id}>
                {team.name}
              </Select.Option>
            ))}
          </Select>
        </Card.Section>
        <Card.Section>
          {activeTeamPlayerIds !== undefined ? (
            <FormLayout>
              <FormLayout.Group>
                <div style={{ marginTop: "14px", minHeight: "500px" }}>
                  <DataTable
                    columns={[
                      { label: "Current Players" /* TODO: Translation */, width: "95%" },
                      { label: "", width: "5%" },
                    ]}
                  >
                    {playersInTeam
                      .filter(meta => meta.league_team_id === state.teamId)
                      .map(meta =>
                        meta.players.map(player => (
                          <tr key={player.id}>
                            <td>
                              <p>{player.customer?.full_name}</p>
                            </td>
                            <td className="clickable">
                              <FontAwesomeIcon
                                icon={["fas", "x"]}
                                onClick={() =>
                                  setSelectedIds(prev => ({
                                    ...prev,
                                    active: prev.active.filter(id => id !== player.id),
                                  }))
                                }
                              />
                            </td>
                          </tr>
                        )),
                      )}
                  </DataTable>
                </div>
                <div className="player_manage-tab_section">
                  <Tabs
                    tabs={manageTeamTabs(t)}
                    selected={state.tabIndex}
                    onSelect={(value: number) => onTabChange(value)}
                  >
                    <div className="league-manage_players-search">
                      <Search
                        historyKey={"league_team_manage_players"}
                        searchCallback={searchValue => setState(prev => ({ ...prev, search: searchValue }))}
                        placeholder="Search customer name..." // TODO: Translation
                        debounceTime={0}
                      />
                    </div>
                    {state.tabIndex === 0 && (
                      <DataTable columns={[]} hideHeader className="player_manage-tab_table">
                        {playersWithoutTeam
                          .filter(player =>
                            state.search.length !== 0
                              ? player.customer.full_name.toLowerCase().includes(state.search)
                              : true,
                          )
                          .filter(player => !playersOnOtherTeam.includes(player))
                          .map(player => (
                            <tr key={player.id} className="clickable" onClick={() => handlePlayerToggle(player.id)}>
                              <td>
                                <p>{player.customer?.full_name}</p>
                              </td>
                            </tr>
                          ))}
                      </DataTable>
                    )}
                    {state.tabIndex === 1 && (
                      <DataTable columns={[]} hideHeader className="player_manage-tab_table">
                        {playersOnOtherTeam
                          .filter(player =>
                            state.search.length !== 0
                              ? player.customer.full_name.toLowerCase().includes(state.search)
                              : true,
                          )
                          .map(player => (
                            <tr key={player.id}>
                              <td>
                                <p>{player.customer?.full_name}</p>
                              </td>
                            </tr>
                          ))}
                      </DataTable>
                    )}
                  </Tabs>
                </div>
              </FormLayout.Group>
            </FormLayout>
          ) : (
            <div style={{ height: "25px", overflow: "hidden" }}>
              <Spin />
            </div>
          )}
        </Card.Section>
      </Card>
    </Page>
  );
}

const manageTeamTabs = (t: TFunction<"translation", undefined>) => [
  {
    id: "no_team",
    content: "Not On A Team", // TODO: Translation
    panelID: "player_manage-content_1",
  },

  {
    id: "other_team",
    content: "On Another Team", // TODO: Translation
    panelID: "player_manage-content_2",
  },
];
