import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cloneDeep } from "lodash";
import classNames from "classnames";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetDivisions, GetTeeDecks } from "api/rpc/facility";
import { GetParticipant } from "api/rpc/2024-04/facilityAdmin/league/participant";
import { GetScoresheet } from "api/rpc/2022-09/facilityAdmin/league/scoresheet";
import {
  GetLeagueRound,
  PostLeagueRound,
  PutLeagueRound,
  DeleteLeagueRound,
  TPutLeagueRound,
  TFormatRoundData,
} from "api/rpc/2024-04/facilityAdmin/league/scoring/round";
import { GetLeagueCompetitionFormat } from "api/rpc/2024-04/facilityAdmin/league/scoring/format";

import { showError, showSuccess } from "redux/actions/ui";
import { ILeagueParticipant, TEventRound, TLeagueRoundFormat } from "redux/reducers/models/league";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import { Select } from "components/select";
import { ButtonNew as Button } from "components/buttonNew";
import Popup from "components/popup/Popup";
import Checkbox from "components/form/checkbox/Checkbox";
import Callout from "components/callout/Callout";

import "./LeagueScoresheet.scss";

interface ILeagueScoresheetState {
  id: number;
  league_id: number;
  league: any;
  name: string;
  date_played: Date;
  course_id: number;
  division_front_id: number;
  division_back_id: number;
  tee_deck_id: number;
  players: Array<
    ILeagueParticipant & {
      index?: number;
      open_scorecard?: boolean;
      format_round_data?: TFormatRoundData[];
    }
  >;
  holes: any;
}

interface IFilterState {
  divisions: any;
  teeDecks: any;
  formats: Array<TLeagueRoundFormat>;
  search: string;
  openScorecard: boolean;
  deleteRound: boolean;
  selectedRound: Record<string, any>;
}

export default function LeagueScoresheet() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { leagueId, scoresheetId } = useParams<{ leagueId: string; scoresheetId: string }>();
  const inputRef = useRef([]);

  const { Option } = Select;

  const dispatch = useAppDispatch();

  const [leagueScoresheetState, setLeagueScoresheetState] = useState<ILeagueScoresheetState>({
    id: parseInt(scoresheetId),
    league_id: parseInt(leagueId),
    league: null,
    name: "",
    date_played: null,
    course_id: null,
    division_front_id: null,
    division_back_id: null,
    tee_deck_id: null,
    players: undefined,
    holes: null,
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    divisions: [],
    teeDecks: [],
    formats: undefined,
    search: "",
    openScorecard: false,
    deleteRound: false,
    selectedRound: null,
  });

  const [creatingRoundId, setCreatingRoundId] = useState<number>(null);

  useEffect(() => {
    void loadLeague();
    void loadTeeDecks();
    void loadLeagueDivisions();
    void loadLeagueScoresheetPlayers(true);
    void loadFormats();
  }, []);

  async function loadLeague() {
    const scoresheetRes = await GetScoresheet(
      {
        id: scoresheetId,
      },
      true,
    );
    if (scoresheetRes.status !== StatusCode.OK) {
      return;
    }

    const scoresheet = scoresheetRes.data[0];

    setLeagueScoresheetState(prevState => ({
      ...prevState,
      league: scoresheet.league,
      name: scoresheet.name,
      date_played: scoresheet.date_played,
      division_front_id: scoresheet.division_front_id,
      division_back_id: scoresheet.division_back_id,
      tee_deck_id: scoresheet.tee_deck_id,
      course_id: scoresheet.course_id,
      // holes: scoresheet.holes,
    }));
  }

  async function loadFormats() {
    const formatRes = await GetLeagueCompetitionFormat({ league_scoresheet_id: leagueScoresheetState.id }, true);
    if (formatRes.status === StatusCode.OK) {
      setFilterState(prevState => ({ ...prevState, formats: formatRes.data }));

      const eighteenHoles = formatRes.data.filter((format: TLeagueRoundFormat) => format.holes === "18_holes");

      setLeagueScoresheetState(prevState => ({ ...prevState, holes: eighteenHoles.length > 0 ? 18 : 9 }));
    }
  }

  async function loadTeeDecks() {
    const teeDeckRes = await GetTeeDecks(null, true);
    if (teeDeckRes.status === StatusCode.OK) {
      setFilterState(prevState => ({ ...prevState, teeDecks: teeDeckRes.data }));
    }
  }

  async function loadLeagueDivisions() {
    const divisionRes = await GetDivisions(null, true);
    if (divisionRes.status === StatusCode.OK) {
      setFilterState(prevState => ({ ...prevState, divisions: divisionRes.data }));
    }
  }

  async function loadLeagueScoresheetPlayers(useGlobalLoader: boolean) {
    if (!filterState.formats) {
      const formatRes = await GetLeagueCompetitionFormat({ league_scoresheet_id: leagueScoresheetState.id }, true);

      if (formatRes.status === StatusCode.OK) {
        setFilterState(prevState => ({ ...prevState, formats: formatRes.data }));
      }
    }

    let leaguePlayers: Array<
      ILeagueParticipant & { index?: number; open_scorecard?: boolean; format_round_data?: TFormatRoundData[] }
    > = [];

    if (!leagueScoresheetState.players) {
      const leaguePlayersRes = await GetParticipant(
        {
          league_id: leagueId,
        },
        true,
      );

      leaguePlayers = leaguePlayersRes.data.map((player, index) => {
        return {
          ...player,
          index: index,
          open_scorecard: false,
        };
      });
    } else {
      leaguePlayers = leagueScoresheetState.players.map((player, index) => {
        return {
          ...player,
          index: index,
          open_scorecard: false,
          round: null,
          format_round_data: null,
        };
      });
    }

    const getLeagueRoundRes = await GetLeagueRound(
      { scoresheet_id: leagueScoresheetState.id, league_id: leagueScoresheetState.league_id },
      useGlobalLoader,
    );

    const playerRound = getLeagueRoundRes.data;

    for (let i = 0; i < leaguePlayers.length; i++) {
      for (let k = 0; k < playerRound.length; k++) {
        if (leaguePlayers[i].user_id === playerRound[k].round.customer_id) {
          leaguePlayers[i].round = playerRound[k].round;
          leaguePlayers[i].format_round_data = playerRound[k].round.format_rounds.map((format: TEventRound) => {
            return {
              id: format.format_id,
              bonus_points: format.bonus_points,
              participation_points: format.participation_points,
            };
          });
        }
      }
    }

    leaguePlayers = leaguePlayers.sort((playerOne, playerTwo) =>
      playerOne.customer.last_name.localeCompare(playerTwo.customer.last_name),
    );

    if (leaguePlayers) {
      setLeagueScoresheetState(prevState => ({
        ...prevState,
        players: leaguePlayers,
      }));
      setCreatingRoundId(null);
    }
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>, participant: ILeagueParticipant) {
    const { id, value } = event.target;
    const valueIsNumber = !isNaN(Number(value)); //ensure string input is a number

    const updatedPlayers = leagueScoresheetState.players.map((player, index) => {
      if (player.id === participant.id && valueIsNumber) {
        return {
          ...player,
          round: {
            ...player.round,
            [id as keyof TEventRound]: Number(value),
          },
        };
      }
      return player;
    });

    setLeagueScoresheetState(prevState => ({ ...prevState, players: updatedPlayers }));
  }

  function handleCellInputChange(event: React.ChangeEvent<HTMLInputElement>, playerId: number, scoreIndex: number) {
    const { value } = event.target;
    let nineHoleSum = 0;
    let eighteenHoleSum = 0;

    const players = [...leagueScoresheetState.players];

    const playerIndex = players.findIndex(player => player.id === playerId);

    //Set the new gross value for the selected input
    players[playerIndex].round.hole_scores[scoreIndex] = {
      ...players[playerIndex].round.hole_scores[scoreIndex],
      gross: isNaN(Number(value)) ? 1 : Number(value),
    };

    //Set the 9 hole sum
    const updateNineHoleSumIndex = players[playerIndex].round.hole_scores?.findIndex(
      score => score?.hole_number === 9.1,
    );
    if (updateNineHoleSumIndex !== -1) {
      for (let i = updateNineHoleSumIndex - 1; i >= 0; i--) {
        nineHoleSum += players[playerIndex].round.hole_scores[i].gross
          ? players[playerIndex].round.hole_scores[i].gross
          : 0;
      }
      players[playerIndex].round.hole_scores[updateNineHoleSumIndex] = {
        ...players[playerIndex].round.hole_scores[updateNineHoleSumIndex],
        gross: nineHoleSum,
      };
    }

    //Set the 18 hole sum
    const updateEighteenHoleSumIndex = players[playerIndex].round.hole_scores?.findIndex(
      score => score?.hole_number === 18.1,
    );
    if (updateEighteenHoleSumIndex !== -1 && updateNineHoleSumIndex !== -1) {
      for (let i = updateEighteenHoleSumIndex - 1; i > updateNineHoleSumIndex; i--) {
        eighteenHoleSum += players[playerIndex].round.hole_scores[i].gross
          ? players[playerIndex].round.hole_scores[i].gross
          : 0;
      }
      players[playerIndex].round.hole_scores[updateEighteenHoleSumIndex] = {
        ...players[playerIndex].round.hole_scores[updateEighteenHoleSumIndex],
        gross: eighteenHoleSum,
      };
    }

    //Set the total sum
    const updateTotalSum = players[playerIndex].round.hole_scores?.findIndex(score => score?.hole_number === 18.2);
    if (updateTotalSum !== -1) {
      players[playerIndex].round.hole_scores[updateTotalSum] = {
        ...players[playerIndex].round.hole_scores[updateTotalSum],
        gross: nineHoleSum + eighteenHoleSum,
      };
    }

    setLeagueScoresheetState(prevState => ({ ...prevState, players: players }));
  }

  function handleDropDownChange(value: any, property: string, player: any) {
    const players = leagueScoresheetState.players;

    for (let i = 0; i < players.length; i++) {
      if (players[i]?.id === player?.id) {
        switch (property) {
          case "division_front_id":
            players[i].round.division_front_id = value;
            break;
          case "division_back_id":
            players[i].round.division_back_id = value;
            break;
          case "tee_deck_id":
            players[i].round.tee_deck_id = value;
            break;

          default:
            break;
        }
      }
    }

    setLeagueScoresheetState(prev => ({ ...prev, players: players }));
  }

  const openScorecard = (newPlayer: any) => {
    const playersList = leagueScoresheetState.players.map(player => {
      if (player.id === newPlayer.id) {
        if (newPlayer.open_scorecard) {
          return { ...player, open_scorecard: false };
        } else {
          return { ...player, open_scorecard: true };
        }
      }

      return player;
    });

    setLeagueScoresheetState(prevState => ({
      ...prevState,
      players: playersList,
    }));
  };

  async function createPlayerRound(player: any) {
    setCreatingRoundId(player.id);

    const createRoundRes = await PostLeagueRound(
      {
        customer_id: player.user_id,
        scoresheet_id: leagueScoresheetState.id,
        holes: leagueScoresheetState.holes,
      },
      false,
    );

    if (createRoundRes.status !== StatusCode.OK) {
      dispatch(showError("Failed to create round for player")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Round created successfully")); // TODO: Translation
    void loadLeagueScoresheetPlayers(false);
  }

  async function handleRoundSave(player: ILeagueParticipant & { format_round_data?: TFormatRoundData[] }) {
    const playerRound = player.round;

    const holeScores: { [index: number]: number } = {};

    playerRound.hole_scores.forEach((score, index) => {
      if (score.hole_number > 9.1) {
        holeScores[index] = score.gross;
      } else {
        holeScores[index + 1] = score.gross;
      }
    });

    const allHoleScoresEntered = playerRound.hole_scores.every((score: any) => score.gross !== null);

    const params: TPutLeagueRound = {
      id: playerRound.id,
      hole_scores: holeScores,
      round_handicap: playerRound.round_handicap,
      division_front_id: playerRound.division_front_id,
      division_back_id: playerRound.division_back_id,
      tee_deck_id: playerRound.tee_deck_id,
      submitted: allHoleScoresEntered,
      points_bonus: playerRound.points_bonus,
      points_participation: playerRound.points_participation,
      format_round_data: player.format_round_data,
      // league_id: Number(leagueId),
    };

    const { status, data } = await PutLeagueRound(params, true);

    if (status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.league.league_scoresheet.002")));
      return;
    }
    dispatch(showSuccess("Round saved successfully")); // TODO: Translation

    const updatedLeagueParticipants = leagueScoresheetState.players.map(participant => {
      if (participant.id === player.id) {
        return { ...player, open_scorecard: false, round: { ...(data as TEventRound) } };
      } else {
        return participant;
      }
    });

    const updatedScoresheetState: ILeagueScoresheetState = {
      ...leagueScoresheetState,
      players: [...updatedLeagueParticipants],
    };

    setLeagueScoresheetState({ ...updatedScoresheetState });
  }

  async function deletePlayerRound() {
    const deleteRoundRes = await DeleteLeagueRound({ round_id: filterState.selectedRound?.id }, true);

    if (deleteRoundRes.status !== StatusCode.OK) {
      dispatch(showError("Failed to delete round")); // TODO: Translation
      return;
    }

    setFilterState(prevState => ({ ...prevState, selectedRound: null, deleteRound: false }));
    dispatch(showSuccess("Round deleted successfully")); // TODO: Translation
    void loadLeagueScoresheetPlayers(true);
  }

  function handleBonusChange(value: string, playerId: number, formatId: number) {
    const tempPlayers = cloneDeep(leagueScoresheetState.players);
    const idIndex = tempPlayers
      .find(player => player.id === playerId)
      .format_round_data.findIndex(id => id.id === formatId);

    const format = tempPlayers.find(player => player.id === playerId).format_round_data[idIndex];

    tempPlayers.find(player => player.id === playerId).format_round_data[idIndex] = {
      ...format,
      bonus_points: Number(value),
    };

    setLeagueScoresheetState(prevState => ({ ...prevState, players: tempPlayers }));
  }

  function handleParticipationChange(value: string, playerId: number, formatId: number) {
    const tempPlayers = cloneDeep(leagueScoresheetState.players);
    const idIndex = tempPlayers
      .find(player => player.id === playerId)
      .format_round_data.findIndex(id => id.id === formatId);

    const format = tempPlayers.find(player => player.id === playerId).format_round_data[idIndex];

    tempPlayers.find(player => player.id === playerId).format_round_data[idIndex] = {
      ...format,
      participation_points: Number(value),
    };

    setLeagueScoresheetState(prevState => ({ ...prevState, players: tempPlayers }));
  }

  function handleCheckboxChange(playerId: number, formatId: number, defaultParticipation: number) {
    const tempPlayers = cloneDeep(leagueScoresheetState.players);
    const idIndex = tempPlayers
      .find(player => player.id === playerId)
      .format_round_data.findIndex(id => id.id === formatId);

    if (idIndex >= 0) {
      tempPlayers.find(player => player.id === playerId).format_round_data.splice(idIndex, 1);
    } else {
      tempPlayers
        .find(player => player.id === playerId)
        .format_round_data.push({
          id: formatId,
          bonus_points: 0,
          participation_points: defaultParticipation,
        });
    }

    setLeagueScoresheetState(prevState => ({ ...prevState, players: tempPlayers }));
  }

  return (
    <Page
      title={leagueScoresheetState.name}
      breadcrumbs={[
        {
          prefix: true,
          label: "Round Overview", // TODO: Translation
          url: `/admin/league/${leagueId}/rounds/scoresheet/${scoresheetId}/overview`,
        },
      ]}
    >
      <div className="mb-4">
        <Input
          value={filterState.search ?? ""}
          onChange={(value: any) => setFilterState(prevState => ({ ...prevState, search: value.target.value }))}
          type="search"
          placeholder={t("secure.facility.league.league_scoresheet.005")}
        ></Input>
      </div>

      {leagueScoresheetState.players ? (
        <div>
          {leagueScoresheetState.players
            .filter(player =>
              Boolean(player.customer.full_name.toLowerCase().includes(filterState.search.toLowerCase())),
            )
            .map((player, playerIndex: number) => {
              let filteredFormats = null;
              if (player.open_scorecard) {
                filteredFormats = filterState.formats
                  ?.filter(
                    format =>
                      player.divisions.findIndex(division => division.id === format.league_division_id) > -1,
                  )
                  ?.filter(
                    format =>
                      format.organization !== "team" ||
                      player.teams.findIndex(team => team.league_division_id === format.league_division_id) > -1,
                  );
              }
              return (
                <div
                  key={playerIndex}
                  // onClick={() => navigateToItem(currentUrl + "/" + String(scoresheet.id))}
                  className="mb-3"
                >
                  <Card>
                    <div className="flex justify-between player-card">
                      <div className="text-lg">{player.customer.full_name}</div>

                      {/* Add button if there is no round available for player */}

                      {player.round ? (
                        <div>
                          {player.open_scorecard ? (
                            <div className="flex" style={{ alignItems: "center" }}>
                              <p className="text-gray-400 mr-2">
                                <span className="text-10">{t("secure.facility.league.league_scoresheet.006")}</span>
                                {player.round.round_handicap}
                              </p>
                              <p className="text-gray-400 mr-2">
                                <span className="text-10">{t("secure.facility.league.league_scoresheet.007")}</span>
                                {player.round.net_score}
                              </p>
                              <p className="text-gray-400 mr-2">
                                <span className="text-10">{t("secure.facility.league.league_scoresheet.008")}</span>
                                {player.round.gross_score}
                              </p>

                              {player?.round?.submitted ? (
                                <div className="mr-2">
                                  <FontAwesomeIcon icon={"circle-check"} color={"#46B171"} />
                                </div>
                              ) : (
                                <div className="mr-2">
                                  <FontAwesomeIcon icon={"circle-x"} color={"#AA0504"} />
                                </div>
                              )}
                              <Button type="primary" size="small" onClick={() => openScorecard(player)}>
                                X
                              </Button>
                            </div>
                          ) : (
                            <div className="flex" style={{ alignItems: "center" }}>
                              {player?.round?.submitted ? (
                                <div className="mr-2">
                                  <FontAwesomeIcon icon={"circle-check"} color={"#46B171"} />
                                </div>
                              ) : (
                                <div className="mr-2">
                                  <FontAwesomeIcon icon={"circle-x"} color={"#AA0504"} />
                                </div>
                              )}
                              <Button type="primary" size="small" onClick={() => openScorecard(player)}>
                                {t("secure.facility.league.league_scoresheet.010")}
                              </Button>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div>
                          <div className="flex" style={{ alignItems: "center" }}>
                            <Button
                              type="primary"
                              size="small"
                              onClick={() => createPlayerRound(player)}
                              loading={creatingRoundId === player.id}
                            >
                              {t("secure.facility.league.league_scoresheet.011")}
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>

                    <div>
                      {player.open_scorecard ? (
                        <div className={"scoresheet-scorecard_container"}>
                          <div>{t("secure.facility.league.league_scoresheet.012")}</div>
                          <div>
                            <table className="scoresheet-scorecard cell-spacing w-full">
                              <tbody>
                                <tr>
                                  <th className="text-left">{t("secure.facility.league.league_scoresheet.013")}</th>

                                  {player.round.hole_scores.map((score, index: number) => {
                                    if (score.hole_number <= 9.1) {
                                      return (
                                        <th key={index} className="text-center">
                                          {score.hole_number === 9.1
                                            ? t("secure.facility.league.league_scoresheet.041")
                                            : score.hole_number}
                                        </th>
                                      );
                                    }
                                  })}
                                </tr>
                                <tr>
                                  <td className="text-left">{player.round.tee_deck_name}</td>

                                  {player.round.hole_scores.map((score, index: number) => {
                                    if (score.hole_number <= 9.1) {
                                      return (
                                        <td key={index} className="text-center">
                                          {score.yardage}
                                        </td>
                                      );
                                    }
                                  })}
                                </tr>
                                <tr>
                                  <td className="text-left">{t("secure.facility.league.league_scoresheet.014")}</td>

                                  {player.round.hole_scores.map((score, index: number) => {
                                    if (score.hole_number <= 9.1) {
                                      return (
                                        <td key={index} className="text-center">
                                          {score.handicap}
                                        </td>
                                      );
                                    }
                                  })}
                                </tr>
                                <tr>
                                  <td className="text-left">{t("secure.facility.league.league_scoresheet.015")}</td>

                                  {player.round.hole_scores.map((score, index: number) => {
                                    if (score.hole_number <= 9.1) {
                                      return (
                                        <td key={index} className="text-center">
                                          {score.par}
                                        </td>
                                      );
                                    }
                                  })}
                                </tr>

                                <tr>
                                  <td className="text-left">{t("secure.facility.league.league_scoresheet.016")}</td>

                                  {player.round.hole_scores.map((score, scoreIndex: number) => {
                                    if (score.hole_number <= 9.1) {
                                      return (
                                        <td key={scoreIndex} className="text-center">
                                          <input
                                            ref={ref =>
                                              (inputRef.current[
                                                leagueScoresheetState.holes * playerIndex + scoreIndex
                                              ] = ref)
                                            }
                                            className="cell-input text-center"
                                            type="text"
                                            onChange={e => {
                                              if (e.target.value && !e.target.value.startsWith("1")) {
                                                inputRef.current[
                                                  leagueScoresheetState.holes * playerIndex + scoreIndex + 1
                                                ].focus();
                                                inputRef.current[
                                                  leagueScoresheetState.holes * playerIndex + scoreIndex + 1
                                                ].select();
                                              }
                                              handleCellInputChange(e, player.id, scoreIndex);
                                            }}
                                            value={score.gross ?? ""}
                                            disabled={!Number.isInteger(score.hole_number)} // Cannot Edit Hole #'s   9.1 || 18.1 || 18.2
                                          />
                                        </td>
                                      );
                                    }
                                  })}
                                </tr>

                                <tr>
                                  <td className="text-left">{t("secure.facility.league.league_scoresheet.017")}</td>

                                  {player.round.hole_scores.map((score: any, index: number) => {
                                    if (score.hole_number <= 9.1) {
                                      return (
                                        <td key={index} className="text-center">
                                          {score.net}
                                        </td>
                                      );
                                    }
                                  })}
                                </tr>

                                {leagueScoresheetState.holes === 18 && (
                                  <>
                                    <tr>
                                      <th className="text-left">{t("secure.facility.league.league_scoresheet.013")}</th>

                                      {player.round.hole_scores.map((score, index: number) => {
                                        if (score.hole_number >= 10) {
                                          const title =
                                            score.hole_number === 18.1
                                              ? t("secure.facility.league.league_scoresheet.042")
                                              : score.hole_number === 18.2
                                              ? t("secure.facility.league.league_scoresheet.043")
                                              : score.hole_number;
                                          return (
                                            <th key={index} className="text-center">
                                              {title}
                                            </th>
                                          );
                                        }
                                      })}
                                    </tr>
                                    <tr>
                                      <td className="text-left">{player.round.tee_deck_name}</td>

                                      {player.round.hole_scores.map((score, index: number) => {
                                        if (score.hole_number >= 10) {
                                          return (
                                            <td key={index} className="text-center">
                                              {score.yardage}
                                            </td>
                                          );
                                        }
                                      })}
                                    </tr>
                                    <tr>
                                      <td className="text-left">{t("secure.facility.league.league_scoresheet.014")}</td>

                                      {player.round.hole_scores.map((score, index: number) => {
                                        if (score.hole_number >= 10) {
                                          return (
                                            <td key={index} className="text-center">
                                              {score.handicap}
                                            </td>
                                          );
                                        }
                                      })}
                                    </tr>
                                    <tr>
                                      <td className="text-left">{t("secure.facility.league.league_scoresheet.015")}</td>

                                      {player.round.hole_scores.map((score, index: number) => {
                                        if (score.hole_number >= 10) {
                                          return (
                                            <td key={index} className="text-center">
                                              {score.par}
                                            </td>
                                          );
                                        }
                                      })}
                                    </tr>

                                    <tr>
                                      <td className="text-left">{t("secure.facility.league.league_scoresheet.016")}</td>

                                      {player.round.hole_scores.map((score, scoreIndex: number) => {
                                        if (score.hole_number >= 10) {
                                          return (
                                            <td key={scoreIndex} className="text-center">
                                              <input
                                                ref={ref =>
                                                  (inputRef.current[
                                                    leagueScoresheetState.holes * playerIndex + (scoreIndex - 1)
                                                  ] = ref)
                                                }
                                                className="cell-input text-center"
                                                type="text"
                                                onChange={e => {
                                                  if (e.target.value && !e.target.value.startsWith("1")) {
                                                    inputRef.current[
                                                      leagueScoresheetState.holes * playerIndex + (scoreIndex - 1) + 1
                                                    ].focus();
                                                    inputRef.current[
                                                      leagueScoresheetState.holes * playerIndex + (scoreIndex - 1) + 1
                                                    ].select();
                                                  }
                                                  handleCellInputChange(e, player.id, scoreIndex);
                                                }}
                                                value={score.gross ?? ""}
                                                disabled={!Number.isInteger(score.hole_number)} // Cannot Edit Hole #'s   9.1 || 18.1 || 18.2
                                              />
                                            </td>
                                          );
                                        }
                                      })}
                                    </tr>

                                    <tr>
                                      <td className="text-left">{t("secure.facility.league.league_scoresheet.017")}</td>

                                      {player.round.hole_scores.map((score: any, index: number) => {
                                        if (score.hole_number >= 10) {
                                          return (
                                            <td key={index} className="text-center">
                                              {score.net}
                                            </td>
                                          );
                                        }
                                      })}
                                    </tr>
                                  </>
                                )}
                              </tbody>
                            </table>
                          </div>

                          <Card.Section>
                            <div className="flex justify-between" style={{ alignItems: "center" }}>
                              <div>{t("secure.facility.league.league_scoresheet.018")}</div>
                              <div className="flex">
                                <Button
                                  onClick={() =>
                                    setFilterState(prevState => ({
                                      ...prevState,
                                      deleteRound: true,
                                      selectedRound: player.round,
                                    }))
                                  }
                                  type="error"
                                  size="small"
                                >
                                  {t("secure.facility.league.league_scoresheet.020")}
                                </Button>
                                <Button onClick={e => handleRoundSave(player)} type="primary" size="small">
                                  {t("secure.facility.league.league_scoresheet.021")}
                                </Button>
                              </div>
                            </div>
                            <div className="flex justify-between">
                              {player.round.submitted ? (
                                <div>
                                  <FontAwesomeIcon icon={"circle-check"} color={"#46B171"} /> &nbsp;{" "}
                                  {"Round has been submitted" /* TODO: Translation */}
                                </div>
                              ) : (
                                <div>
                                  <FontAwesomeIcon icon={"circle-x"} color={"#AA0504"} /> &nbsp;{" "}
                                  {"Round has not been submitted" /* TODO: Translation */}
                                </div>
                              )}
                            </div>
                          </Card.Section>

                          <Card.Section>
                            <FormLayout>
                              <FormLayout.Group>
                                <Input
                                  value={player.round.round_handicap.toString() ?? ""}
                                  label="Edit Handicap" // TODO: Translation
                                  id="round_handicap"
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    handleInputChange(event, player)
                                  }
                                />
                                <Select
                                  label={t("secure.facility.league.league_scoresheet.023")}
                                  onChange={(value: any) => handleDropDownChange(value, "tee_deck_id", player)}
                                  defaultValue={player.round.tee_deck_id}
                                >
                                  {filterState.teeDecks.map((teeDeck: any, index: number) => {
                                    return (
                                      <Option key={index} value={teeDeck.id} name={teeDeck.title}>
                                        {teeDeck.title}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </FormLayout.Group>
                              <FormLayout.Group>
                                <Select
                                  label={"Front Division"} // TODO: Translation
                                  onChange={(value: any) => handleDropDownChange(value, "division_front_id", player)}
                                  defaultValue={player.round.division_front_id}
                                  //   disabled={!productPermissions?.products_edit}
                                >
                                  {filterState.divisions.map((division: any, index: number) => {
                                    return (
                                      <Option key={index} value={division.id} name={division.title}>
                                        {division.title}
                                      </Option>
                                    );
                                  })}
                                </Select>
                                {leagueScoresheetState.holes === 18 ? (
                                  <Select
                                    label={"Back Division"} // TODO: Translation
                                    onChange={(value: any) => handleDropDownChange(value, "division_back_id", player)}
                                    defaultValue={player.round.division_back_id}
                                    //   disabled={!productPermissions?.products_edit}
                                  >
                                    {filterState.divisions.map((division: any, index: number) => {
                                      return (
                                        <Option key={index} value={division.id} name={division.title}>
                                          {division.title}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                ) : (
                                  <div />
                                )}
                              </FormLayout.Group>
                            </FormLayout>
                          </Card.Section>

                          {filteredFormats?.length > 0 ? (
                            <Card.Section title={"Formats"} /* TODO: Translation */>
                              <FormLayout>
                                <FormLayout.Group>
                                  <div className={"ui-checkbox-group formats-checkbox-group"}>
                                    {filteredFormats?.map((format: TLeagueRoundFormat, index: number) => {
                                      return (
                                        <div key={index}>
                                          <div
                                            onClick={() =>
                                              handleCheckboxChange(
                                                player.id,
                                                format.id,
                                                format.default_participation_points,
                                              )
                                            }
                                            className={classNames("ui-checkbox-group-item", {
                                              "ui-checkbox-group-item_selected":
                                                player.format_round_data.findIndex(id => id.id === format.id) >= 0,
                                            })}
                                          >
                                            <div className="ui-checkbox-group-item-content">
                                              <p className="location-title">{format.title}</p>
                                            </div>
                                            <div className="pointer-events-none">
                                              <Checkbox
                                                key={index}
                                                checked={
                                                  player.format_round_data.findIndex(id => id.id === format.id) >= 0
                                                }
                                                fullWidth
                                                size="medium"
                                                onChange={() =>
                                                  handleCheckboxChange(
                                                    player.id,
                                                    format.id,
                                                    format.default_participation_points,
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          {player.format_round_data.findIndex(id => id.id === format.id) >= 0 &&
                                            format.format !== "skins" && (
                                              <div className="flex justify-between gap-2 mt-2">
                                                <Input
                                                  value={
                                                    player.format_round_data?.find(
                                                      roundFormat => roundFormat.id === format.id,
                                                    ).bonus_points ?? ""
                                                  }
                                                  label="Bonus" // TODO: Translation
                                                  id="bonus_points"
                                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                    handleBonusChange(event.target.value, player.id, format.id)
                                                  }
                                                  type="number"
                                                />
                                                <Input
                                                  value={
                                                    player.format_round_data?.find(
                                                      roundFormat => roundFormat.id === format.id,
                                                    ).participation_points ?? ""
                                                  }
                                                  label="Participation" // TODO: Translation
                                                  id="participation_points"
                                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                    handleParticipationChange(event.target.value, player.id, format.id)
                                                  }
                                                  type="number"
                                                />
                                              </div>
                                            )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </FormLayout.Group>
                              </FormLayout>
                            </Card.Section>
                          ) : (
                            <Card.Section>
                              <Callout
                                type="info"
                                title="No Formats Available" // TODO: Translation
                                content="There are no available formats for this round. This scorecard won't be counted towards any weekly results or season leaderboards" // TODO: Translation
                                actions={{
                                  primary: {
                                    label: "Create Format", // TODO: Translation
                                    onClick: () =>
                                      history.push(
                                        `/admin/league/${leagueId}/rounds/scoresheet/${scoresheetId}/scoring-formats/new`,
                                      ),
                                  },
                                }}
                              />
                            </Card.Section>
                          )}
                        </div>
                      ) : null}
                    </div>
                  </Card>
                </div>
              );
            })}
        </div>
      ) : null}

      <Popup
        open={filterState.deleteRound}
        type="warning"
        title="Delete Round?" // TODO: Translation
        description="Are you sure you want to delete this round?" // TODO: Translation
        onCancel={() => setFilterState(prevState => ({ ...prevState, deleteRound: false, selectedRound: null }))}
        onOk={deletePlayerRound}
        okText="Delete" // TODO: Translation
      />
    </Page>
  );
}
