import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { TFunction, useTranslation } from "react-i18next";

import { PostTournament } from "api/rpc/2024-04/facilityAdmin/tournament/tournament";
import { StatusCode } from "api/protocols";
import { showError, showSuccess } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import TimePick from "components/timePick/TimePick";
import { IPostTournament } from "api/rpc/2022-09/facilityAdmin/tournament/tournament";
import { handleChangeEventInput } from "helpers/Helpers";
import { Select } from "components/select/index";
interface ITournamentNewState {
  name: string;
  start_time: string;
  date: Date;
}

type TTournamentTypes = "regular" | "shotgun" | "crossover";

export default function TournamentNew() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { Option } = Select;

  const [tournament, setTournament] = useState<IPostTournament>({
    name: "",
    start_time: "12:00",
    date: new Date(),
    tournament_type: "Regular",
  });

  async function createTournament() {
    if (tournament.date) {
      const year = tournament.date.toLocaleString("en-CA", { year: "numeric" });
      const month = tournament.date.toLocaleString("en-CA", { month: "2-digit" });
      const day = tournament.date.toLocaleString("en-CA", { day: "2-digit" });

      const dateString = `${year}-${month}-${day}`;

      const tournamentRes = await PostTournament(
        {
          name: tournament.name,
          start_time: tournament.start_time,
          date: dateString,
        },
        true,
      );

      if (tournamentRes.status !== StatusCode.OK) {
        dispatch(
          showError(
            typeof tournamentRes.data === "string"
              ? tournamentRes.data
              : t("secure.facility.tournament.tournament_new.009"),
          ),
        );
        return;
      }

      dispatch(showSuccess(t("secure.facility.tournament.tournament_new.011")));
      history.push("/admin/events-tournament");
    }
  }

  function handleDateChange(updatedDate: Date) {
    setTournament(prevState => ({ ...prevState, date: updatedDate }));
  }

  const primaryAction = {
    content: t("secure.facility.tournament.tournament_new.001"),
    action: () => createTournament(),
  };

  return (
    <Page
      title={t("secure.facility.tournament.tournament_new.002")}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[{ label: t("secure.facility.tournament.tournament_new.003"), url: "/admin/events-tournament" }]}
    >
      <Form>
        <Card title={t("secure.facility.tournament.tournament_new.004")}>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <TimePick
                  value={tournament.start_time}
                  onChange={timeString => setTournament(prev => ({ ...prev, start_time: timeString }))}
                  label={t("secure.facility.tournament.tournament_new.007")}
                  size="large"
                  status={tournament.start_time === undefined ? "warning" : undefined}
                />
                <DatePickerInput
                  label={t("secure.facility.tournament.tournament_new.010")}
                  months={1}
                  position="left"
                  startingDate={tournament.date}
                  setStartingDate={handleDateChange}
                />
              </FormLayout.Group>
            </FormLayout>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={tournament.name}
                  label={t("secure.facility.tournament.tournament_new.005")}
                  id="name"
                  onChange={e => handleChangeEventInput(e, tournament, setTournament)}
                  placeholder={t("secure.facility.tournament.tournament_new.006")}
                />
              </FormLayout.Group>
            </FormLayout>
            <FormLayout>
              <FormLayout.Group>
                <Select
                  smallDropdownSize
                  onChange={(val: TTournamentTypes) => setTournament(prev => ({ ...prev, tournament_type: val }))}
                  defaultValue={tournament.tournament_type}
                  label="Tournament Type"
                >
                  {tournamentTypes(t).map((type, index: number) => {
                    return (
                      <Option key={index} value={type.value} name={type.label}>
                        <span>{type.label}</span>
                      </Option>
                    );
                  })}
                </Select>
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>
      </Form>
    </Page>
  );
}

export const tournamentTypes = (t: TFunction): { label: string; value: TTournamentTypes }[] => {
  return [
    { label: "Regular", value: "regular" },
    { label: "Shotgun", value: "shotgun" },
    { label: "Crossover", value: "crossover" },
  ];
};
