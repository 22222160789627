import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios, { CancelToken } from "axios";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { GetVendor, IVendor } from "api/rpc/2022-09/clientAdmin/product/vendors";

import { showError } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";

import Search from "components/search/Search";
import Page from "components/page/Page";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

interface IVendorFilters {
  search: string;
}

export default function Vendors() {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();

  const [vendorState, setVendorstate] = useState<IVendor[]>(undefined);

  const [filterState, setFilterState] = useState<IVendorFilters>({
    search: "",
  });

  // Search Products
  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadVendors(source.token);
    return () => {
      source.cancel();
    };
  }, [filterState.search]);

  async function loadVendors(token?: CancelToken) {
    if (vendorState !== undefined) {
      setVendorstate(undefined);
    }
    const res = await GetVendor({ search: filterState.search }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error retrieving vendor list")); // TODO: Translation
    }

    setVendorstate(res.status !== StatusCode.OK ? [] : res.data);
  }

  const primaryAction = {
    content: "New Vendor", // TODO: Translation
    action: () => history.push("/admin/settings/client-settings/vendors/new"),
  };

  return (
    <Page title={"Vendors"} /* TODO: Translation */ narrow primaryAction={primaryAction}>
      <div className="flex-grow mb-4">
        <Search
          searchCallback={searchValue => setFilterState(prevState => ({ ...prevState, search: searchValue }))}
          historyKey="vendors-all"
          placeholder="Search vendors..." // TODO: TRANSLATION
        />
      </div>
      <DataTable
        columns={[{ label: "Title" }]} // TODO: Translation
        loading={vendorState === undefined}
      >
        {vendorState?.map(vendor => {
          return (
            <tr
              key={vendor.id}
              onClick={() => history.push("/admin/settings/client-settings/vendors/" + String(vendor.id))}
              className="clickable"
            >
              <td>{vendor.title}</td>
            </tr>
          );
        })}
      </DataTable>
    </Page>
  );
}
