import { APIGet, APIPut } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "client-admin";
const baseCashOutUrl = "/" + apiVersion + "/" + adminType + "/facility/register/shift";

export interface ICashOut {
  id: number;
  register_id: number;
  register_title: string;
  token: string;
  opened_at: string;
  closed_at: string;
  created_at: string;
  opened_by: string;
  closed_by: number;
  closed_by_full_name: string;
  starting_float: number;
  blind_close: boolean;
  deposit_cash: number;
  deposit_check: number;
  deposit_total: number;
  deposit_bag_number: string;
  notes: string;
  deposit_date: Date;
  deposited_by: number;
}

interface IGetCashOuts {
  id?: string | number;
  register?: number;
  facility_id?: string | number;
  filters?: {
    label: "register" | "year" | "month";
    value: Array<number>;
  }[];
}

interface IPutCashOut {
  closed_at: Date;
  deposit_cash: number;
  deposit_check: number;
  deposit_bag_number?: string;
  notes?: string;
  register_id: number;
}

export async function GetCashOuts(params: IGetCashOuts, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<ICashOut[]>(baseCashOutUrl, params, useGlobalLoader, token);
}

export async function PutCashOut(params: IPutCashOut, useGlobalLoader: boolean) {
  return await APIPut<ICashOut[]>(baseCashOutUrl + "/close", params, useGlobalLoader);
}

export async function UpdateCashOut(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseCashOutUrl, params, useGlobalLoader);
}

export async function GetCashOutSummary(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseCashOutUrl + "/summary", params, useGlobalLoader);
}
