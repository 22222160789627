import React, { useEffect, useState } from "react";
import { StatusCode } from "api/protocols";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { showError, showSuccess } from "redux/actions/ui";

import Page from "components/page/Page";
import Card from "components/card/Card";
import { GetFacility, PutFacilityPermissions } from "api/rpc/2024-04/masterAdmin/facility/facility";
import FormLayout from "components/form/FormLayout";
import Toggle from "components/form/toggle/Toggle";

interface IFacilityPermissionState {
  facilities: any;
}

export default function FacilityPermissions() {
  const { masterClientStore } = useAppSelector(store => store);
  const dispatch = useAppDispatch();

  const [facilityPermissionState, setFacilityPermissionState] = useState<IFacilityPermissionState>({
    facilities: [],
  });

  useEffect(() => {
    void loadFacilities();
  }, [masterClientStore.client]);

  async function loadFacilities() {
    const facilityRes = await GetFacility(
      {
        client_id: masterClientStore.client?.id,
        permissions: true,
      },
      true,
    );

    if (facilityRes.status !== StatusCode.OK) {
      dispatch(showError("Error Loading Facilities"));
      return;
    }

    setFacilityPermissionState(prev => ({
      ...prev,
      facilities: facilityRes.data,
    }));
  }

  function handlePermissionsChange(event: React.ChangeEvent<HTMLInputElement>, index: number) {
    const { id, checked } = event.target;
    const tempFacilities = [...facilityPermissionState.facilities];

    tempFacilities[index].permissions = { ...tempFacilities[index].permissions, [id]: checked };
    setFacilityPermissionState(prevState => ({ ...prevState, facilities: tempFacilities }));
  }

  const primaryAction = {
    content: "Save",
    action: savePermissions,
    disabled: !masterClientStore.client,
  };

  async function savePermissions() {
    const tempPermissions = [];

    for (let i = 0; i < facilityPermissionState.facilities.length; i++) {
      tempPermissions.push(facilityPermissionState.facilities[i].permissions);
    }

    const params = {
      client_id: masterClientStore.client?.id,
      facility_permissions: tempPermissions,
    };

    const facilityPermissionsRes = await PutFacilityPermissions(params, true);

    if (facilityPermissionsRes.status !== StatusCode.OK) {
      dispatch(showError("Error Saving Permissions"));
      return;
    }

    dispatch(showSuccess("Permissions Saved Successfully"));
  }

  return (
    <Page
      title="Facility Permissions"
      subtitle={masterClientStore.client ? masterClientStore.client.full_name : "No Client Selected"}
      narrow
      primaryAction={primaryAction}
    >
      {masterClientStore.client && (
        <>
          {facilityPermissionState.facilities.map((facility: any, index: number) => {
            return (
              <Card key={index} title={facility.long_name} collapsable defaultCollapsed>
                <Card.Section>
                  <FormLayout>
                    <FormLayout.Group className="mt-4">
                      <Toggle
                        size="medium"
                        id="products_create"
                        labelTop="Create Products"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePermissionsChange(e, index)}
                        checked={facility.permissions.products_create}
                      ></Toggle>

                      <Toggle
                        size="medium"
                        id="products_edit"
                        labelTop="Edit Products"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePermissionsChange(e, index)}
                        checked={facility.permissions.products_edit}
                      ></Toggle>

                      <Toggle
                        size="medium"
                        id="customer_types_create"
                        labelTop="Create Customer Types"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePermissionsChange(e, index)}
                        checked={facility.permissions.customer_types_create}
                      ></Toggle>
                    </FormLayout.Group>
                    <FormLayout.Group>
                      <Toggle
                        size="medium"
                        id="gift_cards_import"
                        labelTop="Import Gift Cards"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePermissionsChange(e, index)}
                        checked={facility.permissions.gift_cards_import}
                      ></Toggle>

                      <Toggle
                        size="medium"
                        id="courses_create"
                        labelTop="Create Courses"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePermissionsChange(e, index)}
                        checked={facility.permissions.courses_create}
                      ></Toggle>

                      <Toggle
                        size="medium"
                        id="divisions_create"
                        labelTop="Create Divisions"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePermissionsChange(e, index)}
                        checked={facility.permissions.divisions_create}
                      ></Toggle>
                    </FormLayout.Group>
                  </FormLayout>
                </Card.Section>
              </Card>
            );
          })}
        </>
      )}
    </Page>
  );
}
