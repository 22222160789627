import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetInvoice, IGetInvoice } from "api/rpc/2024-04/facilityAdmin/order/invoice";

import { showError } from "redux/actions/ui";
import { IInvoice } from "redux/reducers/models/order";
import { useAppDispatch } from "hooks/redux";
import { capitalize } from "helpers/Helpers";
import { LocaleCurrency } from "helpers/Locale";

import Page from "components/page/Page";
import { Badge } from "components/badge/Badge";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";
import Search from "components/search/Search";

const TABLE_LIMIT = 50;

export default function Invoices() {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [invoices, setInvoices] = useState<Array<IInvoice>>(undefined);

  const [filterState, setFilterState] = useState<IGetInvoice>({
    search: "",
    offset: 0,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadInvoices(source.token);
    return () => source.cancel();
  }, [filterState]);

  async function loadInvoices(token?: CancelToken) {
    setInvoices(undefined);

    const res = await GetInvoice(
      {
        limit: TABLE_LIMIT,
        offset: filterState.offset,
        search: filterState.search,
        extended: true,
      },
      token ? false : true,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading invoices")); // TODO: Translation
    }

    setInvoices(res.status !== StatusCode.OK ? [] : res.data);
  }

  function handleTableNavigation(direction: "prev" | "next") {
    switch (direction) {
      case "prev": {
        setFilterState(prev => ({ ...prev, offset: filterState.offset - TABLE_LIMIT }));
        return;
      }
      case "next": {
        setFilterState(prev => ({ ...prev, offset: filterState.offset + TABLE_LIMIT }));
        return;
      }
      default:
        return;
    }
  }

  const primaryAction = {
    content: "New", // TODO: Translation
    action: () => history.push("/admin/invoices/new"),
  };

  return (
    <Page
      title="Invoices" // TODO: Translation
      primaryAction={primaryAction}
    >
      <div className="flex-grow mb-4 pt-4">
        <Search
          searchCallback={searchValue => setFilterState(prev => ({ ...prev, search: searchValue, offset: 0 }))}
          historyKey="invoice-search"
          restrictPresetToRoutes={["admin/invoices"]}
        />
      </div>
      <DataTable
        columns={[
          { label: "Name" }, // TODO: Translation
          { label: "Date" }, // TODO: Translation
          { label: "Customer" }, // TODO: Translation
          { label: "Event" }, // TODO: Translation
          { label: "Status" }, // TODO: Translation
          { label: "Total Price" }, // TODO: Translation
        ]}
        loading={invoices === undefined}
        footer={{
          tableLimit: TABLE_LIMIT,
          tableOffset: filterState.offset,
          handleTableOffset: direction => handleTableNavigation(direction),
          disableNextOffset: !(invoices?.length === TABLE_LIMIT) || invoices === undefined,
        }}
      >
        {invoices?.map(invoice => (
          <tr
            key={invoice.id}
            onClick={() =>
              invoice.financial_status === "draft"
                ? history.push("/admin/invoices/" + String(invoice.id) + "/edit")
                : history.push("/admin/invoices/" + String(invoice.id))
            }
            className="clickable"
          >
            <td>{invoice.name}</td>
            <td>
              <span>{moment.utc(invoice.date).format("MMM DD, YYYY")}</span>
            </td>
            <td>{invoice.customer?.full_name}</td>
            <td>
              {invoice.tournament?.name && invoice.tournament.name}

              {invoice.hospitality_event?.name && invoice.hospitality_event.name}
            </td>
            <td>{statusBadge(invoice.financial_status)}</td>
            <td>
              <LocaleCurrency amount={invoice.total_price} currency={invoice.currency} />
            </td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}

const statusBadge = (status: string) => {
  switch (status) {
    case "paid":
      return <Badge type="success">{capitalize(status.replace(/_/g, " "))}</Badge>;
    case "partially_paid":
      return <Badge type="warning">{capitalize(status.replace(/_/g, " "))}</Badge>;
    case "voided":
      return <Badge type="error">{capitalize(status.replace(/_/g, " "))}</Badge>;
    default:
      return <Badge type="gray">{capitalize(status.replace(/_/g, " "))}</Badge>;
  }
};
