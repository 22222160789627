import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { IOptions } from "pages/secure/facility/Admin";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./menuOption.scss";

interface IProps {
  option: IOptions;
  onClick: (url: string) => void;
  showStyle?: boolean;
  currentUrl?: string;
  level: number;
  visible?: boolean;
  secondOptionVisible?: boolean;
}

export default function MenuOption(props: IProps) {
  const { option, onClick, showStyle, currentUrl, level, visible, secondOptionVisible } = props;
  const [open, setOpen] = useState(false);
  const [secondMenuOptionOpen, setSecondMenuOptionOpen] = useState(false);

  function updateOpenState() {
    setOpen(!open);
  }

  function handleUpdateState() {
    void updateOpenState();

    if (option.secondOptionHeader) {
      setSecondMenuOptionOpen(!secondMenuOptionOpen);
    }
  }

  return (
    <>
      <Link
        to={!option.subOptions && option.url ? option.url : "#"}
        className={classNames(
          "left-menu-option",
          { "left-menu-option-selected": showStyle },
          { "left-menu-option-open": open || visible },
          { "left-menu-second-option-open": secondOptionVisible },
          { "left-menu-option-header": !option.url && !option.subOptions },
          { "left-menu-last-item": !option.subOptions && level >= 1 },
        )}
        onClick={!option.subOptions && onClick && option.url ? () => onClick(option.url) : handleUpdateState}
        style={{ background: secondMenuOptionOpen ? "#2D4675" : showStyle ? "#2D4675" : "" }}
      >
        <div className="flex justify-between w-full">
          <div className="left-menu-option-group">
            {option.icon && (
              <FontAwesomeIcon
                fixedWidth
                icon={["far", option.icon]}
                className={classNames("left-menu-option-text", { "left-menu-option-text-open": open })}
                style={{ marginRight: "1rem" }}
              />
            )}
            <p
              className={classNames(
                "left-menu-option-text",
                { "left-menu-option-text-selected": showStyle },
                { "left-menu-option-text-open": open },
                { "left-menu-option-sub-text": visible },
                { "left-menu-second-option-sub-text": secondOptionVisible },
              )}
              style={{ fontWeight: showStyle && secondOptionVisible ? "600" : "" }}
            >
              {option.label}
            </p>
            {option.subOptions && <FontAwesomeIcon icon={open ? ["fal", "chevron-up"] : ["fal", "chevron-down"]} />}
          </div>
        </div>
      </Link>
      {option.subOptions && open && (
        <div>
          {option.subOptions.map((item, index: number) => {
            return (
              <MenuOption
                key={index}
                option={item}
                showStyle={currentUrl === item.url ? true : false}
                onClick={props.onClick}
                currentUrl={currentUrl}
                level={level + 1}
                visible={open}
                secondOptionVisible={secondMenuOptionOpen}
              />
            );
          })}
        </div>
      )}
    </>
  );
}
