import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";

const apiVersion = "2021-01";
const adminType = "client-admin";
const baseLeagueUrl = "/" + apiVersion + "/" + adminType + "/league";

// GET League
// Retrieve a list of leagues
export async function GetLeague(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseLeagueUrl, params, useGlobalLoader);
}

// POST League
// Create a new league
export async function PostLeague(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseLeagueUrl, params, useGlobalLoader);
}

// PUT League
// Update an existing league
export async function PutLeague(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseLeagueUrl, params, useGlobalLoader);
}

//GET Players
//Retrieve a list of players in a specific league
export async function GetLeaguePlayers(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseLeagueUrl + "/participant", params, useGlobalLoader);
}

//POST New Player
//Add new player to leage
export async function AddPlayerToLeague(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseLeagueUrl + "/participant", params, useGlobalLoader);
}
