import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { ICourse } from "redux/reducers/models/facility";

const apiVersion = "2024-04";
const adminType = "master-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/facility/course";

type TGetCourse = {
  id?: string | number;
  scoring_only?: boolean; // default = false
  facility_id: number;
};

type TPostCourse = {
  long_name?: string;
  full_name?: string;
  facility_id: number;
  holes: 6 | 9 | 12 | 18 | 27 | 36 | 54 | 72;
  scoring_only: boolean;
};

type TPutCourse = {
  course_id: string | number;
  long_name?: string;
  full_name?: string;
  facility_id: number;
  holes?: 6 | 9 | 12 | 18 | 27 | 36 | 54 | 72 | number;
  scoring_only: boolean;
};

// GET Course
export async function GetCourse(params: TGetCourse, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<ICourse[]>(baseUrl, params, useGlobalLoader, token);
}

// POST Course
export async function PostCourse(params: TPostCourse, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

// PUT Course
export async function PutCourse(params: TPutCourse, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}
