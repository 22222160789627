import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { StatusCode } from "api/protocols";
import { GetAccountingReference, IFacility } from "api/rpc/clientAdmin/facility/facility";
import { AddTicketToVariant, DeleteTicketFromVariant, GetTickets } from "api/rpc/2022-09/clientAdmin/ticket/ticket";
import {
  GetVariant,
  PutVariant,
  ArchiveVariant,
  GetInventoryLevels,
  UpdateInventoryLevels,
  GetTaxLines,
  DuplicateVariant,
} from "api/rpc/2022-09/clientAdmin/product/product";

import { IUIActions } from "redux/actions/ui";
import { IUIState } from "redux/reducers/ui";
import { IAuthState } from "redux/reducers/auth";

import { LocaleCurrency } from "helpers/Locale";
import { displayPercent, capitalize } from "helpers/Helpers";

import Page from "components/page/Page";
import Card from "components/card/Card";
import { ButtonNew as Button, ButtonNew } from "components/buttonNew";
import Popup from "components/popup/Popup";

import FormLayout from "components/form/FormLayout";
import Checkbox from "components/form/checkbox/Checkbox";
import Input from "components/form/input/Input";
import { Select } from "components/select/index";
import Sheet from "components/sheet/Sheet";
import Tabs from "components/tabs/Tabs";
import Toggle from "components/form/toggle/Toggle";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

import "pages/secure/facility/product/Variant.scss";
import "./product.scss";
import TextField from "components/form/textField/TextField";
import { isEqualWith, isNull } from "lodash";
import Portal from "elements/Portal";
import TagInputSelect from "components/tagInputSelect/TagInputSelect";
import { TTaxLines } from "redux/reducers/models/facility";

interface IVariantState {
  id: number;
  title: string;
  option1: string;
  option2: string;
  option3: string;
  price: number;
  original_price: number;
  cost: number;
  taxable: boolean;
  barcode: string;
  sku: string;
  editing: boolean;
  product: any;
  inventory_levels: any;
  quantity_available: number;
  tickets?: IVariantTicket[];
  [key: string]: any;
  accounting_reference_id: number;
  cannot_be_sold: boolean;
  description: string;
}

interface IUpdateInventoryState {
  id: number;
  updateInventoryActive: boolean;
  newQuantity: number;
}

interface ITaxState {
  updateTaxLinesActive: boolean;
  selectedInventoryLevelIndex: number;
  availableTaxLines: Record<string, any>[];
  selectedTaxLines: ITaxLines[];
  selectedTaxLineIds: number[];
  selectedFacilityID: number;
  selectedInventoryLevel: IInventoryLevel;
}

interface IInventoryLevel {
  id: number;
  facility_id: number;
  cannot_be_sold: boolean;
  cost_override: boolean;
  facility: IFacility;
  has_access: boolean;
  inventory_item: IInventoryItem;
  inventory_item_id: number;
  price_locked: boolean;
  price_override: number;
  quantity_available: boolean;
  quantity_receiving: boolean;
  tax_lines?: ITaxLines[];
}

interface IInventoryItem {
  barcode: string;
  id: number;
  inventory_level: Omit<IInventoryLevel, "facility" | "inventory_item" | "tax_lines">;
  tracked: boolean;
  variant_id: number;
}

interface ITaxLines {
  client_id: number;
  id: number;
  name: string;
  percentage: number;
  province_id: number;
  tax: number;
  type: string;
}

interface IOption {
  id: number;
  name: string;
  value: string;
  product_id: number;
}

interface IAddTicketState {
  addTicketActive: boolean;
  ticketSearch?: string;
  allTickets: any;
}

interface IVariantTicket {
  id: number;
  title: string;
  value: any;
  included: string;
  quantity: number;
}

interface IProps {
  authStore: IAuthState;
  uiActions: IUIActions;
  uiStore: IUIState;
}

interface IUpdateAllPricesState {
  displayPricesModal: boolean;
  newPrice: string;
  type: "prices" | "costs" | "";
}

interface IAccountingReference {
  client_id: number;
  id: number;
  title: string;
}

export default function Single(props: IProps) {
  const history = useHistory();
  const { productId, variantId }: any = useParams();
  const { authStore, uiActions, uiStore } = props;
  const productPermissions = authStore?.user?.permissions;
  const { Option } = Select;

  const [reloadVariant, setReloadVariant] = useState<boolean>(false);
  const [archiveVariantPopupOpen, setVariantPopupOpen] = useState<boolean>(false);

  const initialSelectedTabState = 0;
  const [selectedTab, setSelectedTab] = useState<number>(initialSelectedTabState);

  const [accountingReferences, setAccountingReferences] = useState<IAccountingReference[]>([]);
  const [duplicateVariantOpen, setDuplicateVariantOpen] = useState<boolean>(false);

  const [variantStateBeforeChanges, setVariantStateBeforeChanges] = useState<IVariantState>(undefined);
  const [taxStateBeforeChanges, setTaxStateBeforeChanges] = useState<ITaxState>(undefined);
  const [variantLoaded, setVariantLoaded] = useState<boolean>(false);
  const [taxLoaded, setTaxLoaded] = useState<boolean>(false);

  const initialVariantState: IVariantState = {
    id: null,
    title: "",
    option1: "",
    option2: "",
    option3: "",
    price: 0,
    original_price: 0,
    cost: 0,
    taxable: false,
    barcode: "",
    sku: "",
    editing: false,
    product: null,
    inventory_levels: null,
    optionList: [],
    quantity_available: null,
    accounting_reference_id: null,
    cannot_be_sold: false,
    description: "",
  };
  const [variantState, setVariantState] = useState<IVariantState>(initialVariantState);

  const initialInventoryState: IUpdateInventoryState = {
    id: null,
    updateInventoryActive: false,
    newQuantity: null,
  };
  const [inventoryState, setInventoryState] = useState<IUpdateInventoryState>(initialInventoryState);

  const initialTaxState: ITaxState = {
    updateTaxLinesActive: false,
    selectedFacilityID: null,
    availableTaxLines: [],
    selectedInventoryLevelIndex: null,
    selectedTaxLines: null,
    selectedTaxLineIds: null,
    selectedInventoryLevel: null,
  };
  const [taxState, setTaxState] = useState<ITaxState>(initialTaxState);

  const initialAddTicketState: IAddTicketState = {
    addTicketActive: false,
    ticketSearch: "",
    allTickets: [],
  };
  const [addTicketState, setAddTicketState] = useState<IAddTicketState>(initialAddTicketState);

  const [updatePrices, setUpdatePrices] = useState<IUpdateAllPricesState>({
    displayPricesModal: false,
    newPrice: "",
    type: "",
  });

  const [filterState, setFilterState] = useState({
    search: "",
    showDescription: false,
    selectAllFacilities: false,
  });

  const defaultAccountingReference = accountingReferences.find(
    (ref: Record<string, any>) => ref?.id == variantState.accounting_reference_id,
  );

  useEffect(() => {
    ReactDOM.unstable_batchedUpdates(() => {
      setSelectedTab(initialSelectedTabState);
      setVariantState(initialVariantState);
      setInventoryState(initialInventoryState);
      setTaxState(initialTaxState);
      setAddTicketState(initialAddTicketState);
      setReloadVariant(true);
    });
  }, [variantId]);

  useEffect(() => {
    if (reloadVariant) {
      void loadVariant();
      void loadInventoryLevels();
      void loadTaxLines();
      void loadAccountingReference();

      setReloadVariant(false);
    }
  }, [reloadVariant]);

  //preset the accounting reference search value
  useEffect(() => {
    if (defaultAccountingReference === undefined) {
      return;
    }
    setFilterState({
      ...filterState,
      search: defaultAccountingReference.title,
    });
  }, [defaultAccountingReference]);

  async function loadInventoryLevels() {
    const inventoryRes = await GetInventoryLevels(
      {
        variant_id: variantId,
        extended: true,
      },
      true,
    );
    if (inventoryRes.status !== StatusCode.OK) {
      uiActions.showError(inventoryRes.data.message);
      return;
    }

    const sortedInventoryLevels = inventoryRes.data?.sort(
      (firstLevel: Record<string, any>, secondLevel: Record<string, any>) => {
        if (firstLevel?.facility?.long_name < secondLevel?.facility?.long_name) {
          return -1;
        } else if (firstLevel?.facility?.long_name > secondLevel?.facility?.long_name) {
          return 1;
        } else {
          return 0;
        }
      },
    );

    const inventory = inventoryRes.data;
    let facilityAccessCount = 0;
    inventoryRes.data?.map((inventory_level: any, index: number) => {
      if (inventory_level.has_access) {
        facilityAccessCount++;
      }
    });

    inventory[0].cannot_be_sold = Boolean(inventory[0].cannot_be_sold);

    setVariantState(prev => ({
      ...prev,
      inventory_levels: sortedInventoryLevels,
    }));
    setFilterState(prev => ({
      ...prev,
      selectAllFacilities: inventory?.length === facilityAccessCount ? true : false,
    }));

    setVariantStateBeforeChanges(prev => ({
      ...prev,
      inventory_levels: inventory,
    }));

    setVariantStateBeforeChanges(undefined);
  }

  async function loadVariant() {
    const variantRes = await GetVariant({ id: variantId, extended: true }, true);
    if (variantRes.status !== StatusCode.OK) {
      uiActions.showError(variantRes.data.message);
      return;
    }

    const variant = variantRes.data[0];

    if (variant.dexription && variant.description.length > 0) {
      setFilterState(prevState => ({ ...prevState, showDescription: true }));
    }

    setVariantState(prev => ({
      ...prev,
      id: variant.id,
      title: variant.title,
      option1: variant.option1,
      option2: variant.option2,
      option3: variant.option3,
      price: variant.price,
      original_price: variant.original_price,
      cost: variant.cost,
      taxable: variant.taxable,
      barcode: variant.barcode,
      sku: variant.sku,
      product: variant.product,
      quantity_available: variant.inventory_item.inventory_level.quantity_available,
      tickets: variant.tickets,
      accounting_reference_id: variant.accounting_reference_id,
      cannot_be_sold: variant.cannot_be_sold,
      description: variant.description,
    }));

    // setVariantStateBeforeChanges(undefined);
    setVariantLoaded(true);
  }

  async function loadTaxLines() {
    const taxLinesRes = await GetTaxLines(true);

    if (taxLinesRes.status !== StatusCode.OK) {
      uiActions.showError(taxLinesRes.data.message);
      return;
    }

    setTaxState(prevState => ({ ...prevState, availableTaxLines: taxLinesRes.data }));
    setTaxLoaded(true);
  }

  async function loadAccountingReference() {
    const accountingRes = await GetAccountingReference(null, true);
    if (accountingRes.status !== StatusCode.OK) {
      return;
    }

    //I do not believe we are using accounts variable after this ?
    const accounts = accountingRes.data;
    accounts.unshift({
      id: null,
      title: "",
    });

    setAccountingReferences(accountingRes.data);
  }

  function variantNavigationOptions() {
    const productId = variantState.product?.id;

    if (productId === undefined || productId === null || variantState.product.variants === undefined) {
      return [];
    }

    const currentVariantIndex: number = variantState.product.variants.findIndex(
      (variant: any) => variant.id === variantState.id,
    );

    if (currentVariantIndex === -1) {
      return [];
    }

    const variantNavigationOptionList = [];

    if (currentVariantIndex - 1 >= 0) {
      variantNavigationOptionList.push({
        content: <FontAwesomeIcon className="variant-navigation-arrow" icon={["far", "arrow-left"]} />,
        action: () => navigateToVariant(productId, variantState.product.variants[currentVariantIndex - 1].id),
      });
    }

    if (currentVariantIndex + 1 < variantState.product.variants.length) {
      variantNavigationOptionList.push({
        content: <FontAwesomeIcon className="variant-navigation-arrow" icon={["far", "arrow-right"]} />,
        action: () => navigateToVariant(productId, variantState.product.variants[currentVariantIndex + 1].id),
      });
    }

    return variantNavigationOptionList;
  }

  function navigateToVariant(productId: number, variantId: number) {
    const url = "/admin/product/" + String(productId) + "/variant/" + String(variantId);
    history.push(url);
  }

  async function saveVariant() {
    for (let i = 0; i < variantState.tickets?.length; i++) {
      const ticket = variantState.tickets[i];

      const addTicketToVariantRes = await AddTicketToVariant(
        {
          ticket_id: Number(ticket.id),
          variant_id: Number(variantState.id),
          quantity: Number(ticket.quantity),
        },
        true,
      );

      if (addTicketToVariantRes.status !== StatusCode.OK) {
        uiActions.showError(addTicketToVariantRes.data.message);
        return;
      }
    }

    const inventoryLevelRes = await UpdateInventoryLevels({ inventory_levels: variantState.inventory_levels }, true);

    if (inventoryLevelRes.status !== StatusCode.OK) {
      uiActions.showError(inventoryLevelRes.data.message);
      return;
    }

    console.log("---->", variantState); //debug

    const variantRes = await PutVariant(variantState, true);
    if (variantRes.status !== StatusCode.OK) {
      uiActions.showError("Error updating variant"); // TODO: Translation
      return;
    }

    uiActions.showSuccess("Variant updated successfully"); // TODO: Translation
    setVariantStateBeforeChanges(variantState);

    return;
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setVariantState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleCheckboxChange(event: any) {
    const { id, checked } = event.target;
    setVariantState(prevState => ({ ...prevState, [id]: checked }));
  }

  const primaryAction = {
    content: "Save",
    action: () => console.log(variantState, variantStateBeforeChanges),
    // action: saveVariant,
  };

  async function openAddTicket() {
    const ticket = await loadTickets(addTicketState.ticketSearch);

    setAddTicketState(prev => ({
      ...prev,
      addTicketActive: true,
    }));
  }

  function closeAddTicket() {
    setAddTicketState(prev => ({
      ...prev,
      addTicketActive: false,
      ticketSearch: "",
    }));
  }

  async function handleAddTicket(event: any, ticket: any) {
    const addTicketToVariantRes = await AddTicketToVariant(
      {
        ticket_id: Number(ticket.id),
        variant_id: Number(variantState.id),
        quantity: 1,
      },
      true,
    );
    if (addTicketToVariantRes.status !== StatusCode.OK) {
      uiActions.showError(addTicketToVariantRes.data.message);
      return;
    }

    closeAddTicket();

    void loadVariant();
  }

  async function handleDeleteTicket(event: any, ticket: any) {
    const params = {
      ticket_id: Number(ticket.id),
      variant_id: Number(variantState.id),
    };

    const deleteTicketRes = await DeleteTicketFromVariant(params, true);
    if (deleteTicketRes.status !== StatusCode.OK) {
      uiActions.showError(deleteTicketRes.data.message);
      return;
    }

    void loadVariant();
  }

  async function deleteVariant() {
    const res = await ArchiveVariant({ variant_id: variantState.id }, true);
    if (res.status !== StatusCode.OK) {
      return;
    }

    setVariantPopupOpen(false);
    history.push("/admin/product/" + String(productId));
  }

  // Search Tickets
  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (async () => {
          if (addTicketState.ticketSearch !== null) {
            try {
              const res = await loadTickets(addTicketState.ticketSearch);
              console.log(res);
              if (mounted) {
                setAddTicketState(prevState => ({ ...prevState, allTickets: res }));
              }
            } catch (error) {
              console.log("err", error);
            }
            return;
          }
        })();
      }, 1000);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [addTicketState.ticketSearch]);

  async function loadTickets(search: string) {
    const ticketRes = await GetTickets({ search: search }, true);

    if (ticketRes.status === StatusCode.OK) {
      setAddTicketState(prevState => ({
        ...prevState,
        allTickets: ticketRes.data,
      }));
    }

    return ticketRes.data;
  }

  function handleTicketQuantityChange(event: any, ticket: any) {
    const newQuantity = Number(event.target.value);
    const newState = variantState.tickets?.map((obj: IVariantTicket) => {
      if (obj.id === ticket.id) {
        return { ...obj, quantity: newQuantity };
      }

      return obj;
    });

    setVariantState(prevState => ({
      ...prevState,
      tickets: newState,
    }));
  }

  const tabs = [
    {
      id: "update-invtentory",
      content: "Update Inventory",
    },
    {
      id: "update-inventory-log",
      content: "Update Inventory Log",
    },
  ];

  function initiateUpdateInventory(inventory_level: Record<string, any>) {
    setInventoryState(prevState => ({
      ...prevState,
      updateInventoryActive: true,
      id: inventory_level.id,
      newQuantity: inventory_level.quantity_available,
    }));
  }

  function handleQuantityChange(event: any) {
    setInventoryState(prevState => ({ ...prevState, newQuantity: event.target.value }));
  }

  function closeUpdateInventory() {
    setInventoryState(prevState => ({ ...prevState, updateInventoryActive: false, newQuantity: 0, id: null }));
  }

  // Handle tab change
  const handleTabChange = (selectedTabIndex: number) => {
    setSelectedTab(selectedTabIndex);
  };

  async function completeUpdateInventory() {
    const params = {
      id: inventoryState.id,
      variant_id: variantState.id,
      quantity_available: inventoryState.newQuantity,
    };

    const updateInventoryRes = await UpdateInventoryLevels(params, true);
    if (updateInventoryRes.status !== StatusCode.OK) {
      uiActions.showError(updateInventoryRes.data.message);
      return;
    }

    closeUpdateInventory();

    //Force refresh
    void loadVariant();
    void loadInventoryLevels();
  }

  function handleInventoryInputChange(event: React.ChangeEvent<HTMLInputElement>, index: number) {
    const { id, value } = event.target;
    const tempInventoryLevels = [...variantState.inventory_levels];
    tempInventoryLevels[index] = { ...tempInventoryLevels[index], [id]: Number(value) };
    setVariantState(prevState => ({ ...prevState, inventory_levels: tempInventoryLevels }));
  }

  function handleInventoryAccessChange(event: React.ChangeEvent<HTMLInputElement>, index: number) {
    const { id, checked } = event.target;
    const tempInventoryLevels = [...variantState.inventory_levels];
    tempInventoryLevels[index] = { ...tempInventoryLevels[index], [id]: checked };
    setVariantState(prevState => ({ ...prevState, inventory_levels: tempInventoryLevels }));
  }

  function handleSelectAllFacilities(event: React.ChangeEvent<HTMLInputElement>) {
    const { checked } = event.target;

    const tempAccess = [...variantState.inventory_levels];
    for (let i = 0; i < tempAccess?.length; i++) {
      tempAccess[i] = { ...tempAccess[i], has_access: checked };
    }

    setVariantState(prevState => ({ ...prevState, inventory_levels: tempAccess }));
    setFilterState(prevState => ({ ...prevState, selectAllFacilities: checked }));
  }

  function closeUpdateTaxes() {
    setTaxState(prevState => ({
      ...prevState,
      selectedInventoryLevelIndex: null,
      updateTaxLinesActive: false,
      selectedTaxLines: null,
      selectedTaxLineIds: null,
    }));
  }

  // function initiateUpdateTaxes(tax_lines: Record<string, any>[], index: number) {
  //   const taxLineIds: number[] = [];
  //   tax_lines.forEach(taxLine => {
  //     taxLineIds.push(taxLine.id);
  //   });
  //   setTaxState(prevState => ({
  //     ...prevState,
  //     selectedInventoryLevelIndex: index,
  //     updateTaxLinesActive: true,
  //     selectedTaxLines: tax_lines,
  //     selectedTaxLineIds: taxLineIds,
  //   }));
  // }

  function initiateUpdateTaxes(tax_lines: ITaxLines[], inventory_level: IInventoryLevel, index: number) {
    const taxLineIds: number[] = [];
    console.log("Tax_lines ", tax_lines, "inventory_level ", inventory_level, "index ", index);
    tax_lines.forEach(taxLine => {
      taxLineIds.push(taxLine.id);
    });
    setTaxState(prevState => ({
      ...prevState,
      selectedInventoryLevel: { ...inventory_level },
      selectedInventoryLevelIndex: index,
      updateTaxLinesActive: true,
      selectedTaxLines: [...tax_lines],
      selectedTaxLineIds: taxLineIds,
    }));
  }

  function handleRemoveTaxLine(taxLineId: number) {
    let currentTaxIds = taxState.selectedInventoryLevel.tax_lines.map((line: ITaxLines) => Number(line.id));
    currentTaxIds = currentTaxIds.filter((id: number) => id !== taxLineId);

    let currentTaxLines = taxState.selectedTaxLines;
    currentTaxLines = currentTaxLines.filter(taxLine => taxLine.id !== taxLineId);

    setTaxState(prevState => ({
      ...prevState,
      selectedInventoryLevel: {
        ...prevState.selectedInventoryLevel,
        tax_lines: currentTaxLines,
        tax_line_ids: currentTaxIds,
      },
      selectedTaxLines: currentTaxLines,
    }));
  }

  function handleTaxDropdown(tax_line_id: number, tax_line: any) {
    //Update selected tax lines
    //Filter out based on current 'allIds' values
    const allTaxLines = taxState.selectedTaxLines;
    allTaxLines.push(tax_line);

    const allTaxLineIds = taxState.selectedTaxLineIds;
    allTaxLineIds.push(tax_line_id);
    setTaxState(prevState => ({
      ...prevState,
      selectedInventoryLevel: {
        ...prevState.selectedInventoryLevel,
        tax_lines: allTaxLines,
        tax_line_ids: allTaxLineIds,
      },
      selectedTaxLines: allTaxLines,
    }));
  }

  function updateTaxLines() {
    const allInventoryLevels = [...variantState.inventory_levels];
    allInventoryLevels[taxState.selectedInventoryLevelIndex] = taxState.selectedInventoryLevel;

    setVariantState(prevState => ({
      ...prevState,
      inventory_levels: allInventoryLevels,
      taxLineIds: taxState.selectedTaxLineIds,
    }));
    setTaxState(prevState => ({
      ...prevState,
      availableTaxLines: prevState.availableTaxLines,
      selectedFacilityID: null,
      selectedInventoryLevel: null,
      selectedInventoryLevelIndex: null,
      selectedTaxLineIds: null,
      selectedTaxLines: null,
      updateTaxLinesActive: false,
    }));
  }

  function handleTaxLineDropdownChange(value: number, extraValues: any) {
    setTaxState(prevState => ({ ...prevState, selectedNewTaxLine: extraValues.tax_line }));
  }

  function handleUpdateTaxLines() {
    const tempInventoryLevels = [...variantState.inventory_levels];
    tempInventoryLevels[taxState.selectedInventoryLevelIndex].tax_line_ids = taxState.selectedTaxLineIds;
    tempInventoryLevels[taxState.selectedInventoryLevelIndex].tax_lines = taxState.selectedTaxLines;
    setVariantState(prevState => ({ ...prevState, inventory_levels: tempInventoryLevels }));
    closeUpdateTaxes();
  }

  function displayUpdateAllPrices(type: "costs" | "prices" | "") {
    setUpdatePrices(prevState => ({ ...prevState, displayPricesModal: true, type }));
  }

  function handleAllPricesInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    setUpdatePrices(prevState => ({ ...prevState, newPrice: value }));
  }

  function updateAllPriceCost() {
    if (isNaN(Number(updatePrices.newPrice)) || updatePrices.newPrice === "") {
      uiActions.showError("Enter a valid number");
      return;
    }
    const id = updatePrices.type === "prices" ? "price_override" : "cost_override";
    const updateInventoryLevels = [...variantState.inventory_levels];

    for (let i = 0; i < updateInventoryLevels?.length; i++) {
      updateInventoryLevels[i] = { ...updateInventoryLevels[i], [id]: updatePrices.newPrice };
    }
    ReactDOM.unstable_batchedUpdates(() => {
      setVariantState(prevState => ({ ...prevState, inventory_levels: updateInventoryLevels }));
      setUpdatePrices(prevState => ({ ...prevState, displayPricesModal: false, newPrice: "", type: "" }));
    });
  }

  function handleSelectOnSearch(query: string) {
    if (query.length === 0) {
      setVariantState({ ...variantState, accounting_reference_id: null });
    }
    setFilterState({ ...filterState, search: query });
  }

  async function duplicateVariant() {
    const res = await DuplicateVariant({ variant_id: variantId }, true);

    if (res.status !== StatusCode.OK) {
      props.uiActions.showError("Failed to get the duplicate variant");

      return;
    }

    setDuplicateVariantOpen(false);
    history.push("/admin/product/" + String(productId));
  }
  function handleVariantNotSoldAllChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, checked } = event.target;
    const inventoryLevels = [...variantState.inventory_levels];
    for (let i = 0; i < inventoryLevels?.length; i++) {
      inventoryLevels[i] = { ...inventoryLevels[i], cannot_be_sold: Boolean(checked) };
    }

    setVariantState(prevState => ({
      ...prevState,
      inventory_levels: inventoryLevels,
      cannot_be_sold: checked,
    }));
  }

  function submitKeyboardEvent(event: KeyboardEvent) {
    console.log("Test");
    if (event.key === "Enter" || event.key === "NumpadEnter") {
      void updateAllPriceCost();
    }
  }

  function handleVariantNotSoldStoreChange(event: React.ChangeEvent<HTMLInputElement>, index: number) {
    const { id, checked } = event.target;
    const tempInventoryLevels = [...variantState.inventory_levels];
    tempInventoryLevels[index] = { ...tempInventoryLevels[index], [id]: checked };
    setVariantState(prevState => ({ ...prevState, inventory_levels: tempInventoryLevels }));
  }

  function handleShowDescription() {
    if (filterState.showDescription) {
      setFilterState(prevState => ({ ...prevState, showDescription: false }));
      setVariantState(prevState => ({ ...prevState, description: "" }));
    } else {
      setFilterState(prevState => ({ ...prevState, showDescription: true }));
    }
  }

  function unsavedChangesExist() {
    if (variantStateBeforeChanges === undefined) {
      if (variantLoaded) {
        setVariantStateBeforeChanges(variantState);
      }
      return false;
    }

    return !isEqualWith(variantStateBeforeChanges, variantState, (originalValue, newValue) => {
      if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
        return true;
      }
    });
  }

  function cancelUnsavedChanges() {
    setVariantState(variantStateBeforeChanges);
  }

  return (
    <>
      <Page
        title={variantState.title}
        subtitle={variantState.product?.title}
        splitLayout
        // primaryAction={primaryAction}
        secondaryActions={variantNavigationOptions()}
        breadcrumbs={[
          { prefix: true, label: "Back to Product", url: "/admin/product/" + String(variantState.product?.id) },
        ]}
        notificationBarProps={{
          isVisible: unsavedChangesExist(),
          onAction: saveVariant,
          onCancel: cancelUnsavedChanges,
        }}
      >
        <Page.Section twoThirds>
          <Card
            title="Variant Details"
            //titleActions={[
            //  {
            //    content: filterState.showDescription ? "Remove Description" : "Add Description",
            //    action: handleShowDescription,
            //  },
            //]}
          >
            <Card.Section>
              <FormLayout>
                <FormLayout.Group>
                  {variantState.product?.options?.map((option: IOption, index: number) => {
                    const optionName = `option${index + 1}`;
                    const label = variantState.product.options.length === 1 ? "Title" : option.name;

                    return (
                      <div key={index}>
                        <Input
                          label={label}
                          value={variantState[optionName] || ""}
                          type="text"
                          id={`option${index + 1}`}
                          onChange={handleInputChange}
                          disabled={!productPermissions?.products_edit}
                        />
                      </div>
                    );
                  })}
                </FormLayout.Group>

                {/*<FormLayout.Group>
                  {filterState.showDescription ? (
                    <TextField
                      value={variantState.description || ""}
                      label={"Description"}
                      id="description"
                      onChange={handleInputChange}
                      placeholder={"Describe variant..."}
                    />
                  ) : null}
                </FormLayout.Group>*/}
              </FormLayout>
            </Card.Section>
            <Card.Section>
              <FormLayout>
                <FormLayout.Group>
                  <Input
                    prefix="$"
                    value={variantState.price}
                    label="Price"
                    id="price"
                    onChange={handleInputChange}
                    type="number"
                    placeholder="0.00"
                    disabled={!productPermissions?.products_edit || !productPermissions?.products_edit_price}
                  />
                  <Input
                    prefix="$"
                    value={variantState.original_price}
                    label="Original Price"
                    id="original_price"
                    onChange={handleInputChange}
                    type="number"
                    placeholder="0.00"
                    disabled={!productPermissions?.products_edit || !productPermissions?.products_edit_price}
                  />
                  <Input
                    prefix="$"
                    value={variantState.cost}
                    label="Cost"
                    id="cost"
                    onChange={handleInputChange}
                    type="number"
                    placeholder="0.00"
                    disabled={!productPermissions?.products_edit || !productPermissions?.products_edit_cost}
                  />

                  <div className="mt-4">
                    <p>
                      <span className="text-md text-semibold">
                        {displayPercent((variantState.price - variantState.cost) / variantState.price)}
                      </span>
                      <span className="text-md text-regular text-gray-500"> -- </span>
                      <span className="text-md text-semibold">
                        <LocaleCurrency currency="cad" amount={variantState.price - variantState.cost} />
                      </span>
                    </p>
                    <p className="text-md text-regular text-gray-500">Profit Margin</p>
                  </div>
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>
          </Card>

          {variantState.tickets?.length > 0 ? (
            <Card
              title="Tickets"
              subtitle="What tickets are generated when this variant is purchased?"
              titleActions={[{ content: "Add Ticket", action: openAddTicket }]}
            >
              <Card.Section>
                <div className="ui-checkbox-group">
                  {variantState.tickets?.map((ticket: any, index: number) => {
                    return (
                      <div key={index} className="ui-checkbox-group-item">
                        <div className="ui-checkbox-group-item-content">
                          <div className="ui-checkbox-group-item-text">
                            <p className="text-sm text-medium text-gray-700">{ticket.title}</p>
                            <p className="text-sm text-regular text-gray-600">{ticket.included}</p>
                            <Button type="link" size="small" onClick={(e: any) => handleDeleteTicket(e, ticket)}>
                              Remove
                            </Button>
                          </div>
                        </div>
                        <Input
                          onChange={(e: any) => handleTicketQuantityChange(e, ticket)}
                          value={ticket?.quantity}
                          type="number"
                          label=""
                        />
                      </div>
                    );
                  })}
                </div>
              </Card.Section>
            </Card>
          ) : null}

          <Card title="Inventory">
            <Card.Section>
              <FormLayout>
                <div className="logistics-wrapper">
                  <div className="logistics-wrapper_left-container">
                    <div className="barcode-and-sku">
                      <Input
                        value={variantState.barcode || ""}
                        label="Barcode"
                        id="barcode"
                        onChange={handleInputChange}
                        disabled={!productPermissions?.products_edit}
                      />
                      <Input
                        value={variantState.sku || ""}
                        label="Sku"
                        id="sku"
                        onChange={handleInputChange}
                        disabled={!productPermissions?.products_edit}
                      />
                    </div>
                    <Select
                      label={"Accounting Reference"} //TODO: Translation
                      defaultValue={defaultAccountingReference?.id}
                      showSearch
                      className={`flex justify-center align-center w-full h-10 position-relative z-20 text-black font-medium appearance-none border-none focus:outline-none placeholder-gray-200`}
                      onSearch={(query: string) => handleSelectOnSearch(query)}
                      onChange={(value: any) => setVariantState({ ...variantState, accounting_reference_id: value })}
                      allowClear={productPermissions?.products_edit}
                      searchValue={filterState.search}
                      showDropDownOnFocus={true}
                      disabled={!productPermissions?.products_edit}
                    >
                      {accountingReferences
                        .filter(accountingReference =>
                          String(accountingReference.title).toLowerCase().includes(filterState.search.toLowerCase()),
                        )
                        .map((reference: any, index: number) => {
                          return (
                            <div key={index}>
                              {filterState.search !== "" ? (
                                <div>
                                  <Option key={index} value={reference.id} name={reference.title}>
                                    <div>
                                      <div className="font-semibold text-lg">{reference?.title}</div>
                                    </div>
                                  </Option>
                                </div>
                              ) : null}
                            </div>
                          );
                        })}
                    </Select>
                  </div>
                </div>
              </FormLayout>
            </Card.Section>

            {/* Inventory Stuff */}
            <Card.Section
              title="Price and Cost"
              table="true"
              sectionTitleActions={[
                { content: "Update All Prices", action: () => displayUpdateAllPrices("prices") },
                { content: "Update All Costs", action: () => displayUpdateAllPrices("costs") },
              ]}
            >
              <DataTable
                columns={[
                  {
                    label: "All Facilities",
                    width: "25%",
                    content: (
                      <Checkbox
                        size="small"
                        label="All Facilities"
                        checked={filterState.selectAllFacilities}
                        onChange={handleSelectAllFacilities}
                      />
                    ),
                  },
                  { label: "Price", width: "30%" },
                  { label: "Cost", width: "30%" },
                  { label: "Qty", width: "10%" },
                  { label: "", width: "5%" },
                ]}
                loading={variantState === undefined}
              >
                {variantState.inventory_levels?.map((level: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td>
                        <Checkbox
                          id="has_access"
                          size="medium"
                          value={level.has_access || false}
                          checked={level.has_access || false}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInventoryAccessChange(e, index)}
                          label={level.facility.long_name}
                          disabled={!productPermissions?.products_edit}
                        />
                      </td>
                      <td>
                        <Input
                          className="variant-price-input"
                          value={level?.price_override || ""}
                          id="price_override"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInventoryInputChange(e, index)}
                          prefix="$"
                          type="number"
                          labelHidden
                          disabled={!productPermissions?.products_edit || !productPermissions?.products_edit_price}
                        />
                      </td>
                      <td>
                        <Input
                          className="variant-price-input"
                          value={level?.cost_override || ""}
                          id="cost_override"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInventoryInputChange(e, index)}
                          prefix="$"
                          type="number"
                          labelHidden
                          disabled={!productPermissions?.products_edit || !productPermissions?.products_edit_cost}
                        />
                      </td>
                      <td>{level.quantity_available || 0}</td>
                      <td>
                        <Button
                          type="link"
                          onClick={() => initiateUpdateInventory(level)}
                          disabled={!productPermissions?.products_edit}
                        >
                          Update
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </DataTable>
            </Card.Section>
          </Card>

          <Card title="Taxes" collapsable defaultCollapsed>
            <Card.Section>
              <FormLayout>
                <FormLayout.Group>
                  <Checkbox
                    id="taxable"
                    size="medium"
                    value={variantState.taxable}
                    checked={variantState.taxable}
                    onChange={handleCheckboxChange}
                    label="Charge Taxes"
                    disabled={!productPermissions?.products_edit}
                  />
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>

            <Card.Section table="true">
              <DataTable
                columns={[
                  { label: "Facility", width: "60%" },
                  { label: "Tax Rates", width: "30%" },
                  { label: "", width: "10%" },
                ]}
                loading={variantState === undefined}
              >
                {variantState.inventory_levels?.map((level: IInventoryLevel, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{level.facility.long_name}</td>
                      <td>
                        {level.tax_lines?.map((tax_line: Record<string, any>, taxIndex: number) => {
                          return <p key={taxIndex}>{tax_line.name}</p>;
                        })}
                      </td>
                      <td>
                        <a onClick={() => initiateUpdateTaxes(level.tax_lines, level, index)}>Edit</a>
                      </td>
                    </tr>
                  );
                })}
              </DataTable>
            </Card.Section>
          </Card>

          <Card title="Pricing / Product Lock">
            <Card.Section>
              <FormLayout>
                <FormLayout.Group>
                  <Toggle
                    id={"cannot_be_sold"}
                    labelTop={"Cannot be sold"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleVariantNotSoldAllChange(e)}
                    checked={variantState.cannot_be_sold}
                  ></Toggle>
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>
            <Card.Section table="true">
              <table className="ui-table ui-table-condensed">
                <thead>
                  <tr>
                    <th>Facility</th>
                    {/* <th>Lock Pricing</th> */}
                    <th>Cannot be Sold</th>
                  </tr>
                </thead>

                <tbody>
                  {variantState.inventory_levels?.map((level: any, index: number) => {
                    return (
                      <tr key={index}>
                        <>
                          <td>{level.facility.long_name}</td>
                          <td>
                            {level.has_access && (
                              <Toggle
                                id="cannot_be_sold"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  handleVariantNotSoldStoreChange(e, index)
                                }
                                checked={level.cannot_be_sold}
                                disabled={!productPermissions?.products_edit}
                              ></Toggle>
                            )}
                          </td>
                        </>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Card.Section>
          </Card>
        </Page.Section>
        <Page.Section oneThird>
          <Card title="Delete Variant">
            <Card.Section>
              <Button
                type="primary"
                size="small"
                onClick={() => setVariantPopupOpen(true)}
                disabled={!productPermissions?.products_archive}
              >
                Delete
              </Button>
            </Card.Section>
          </Card>
        </Page.Section>
      </Page>

      {/* Add Ticket Sheet - Handles adding ticket to product */}
      <Sheet
        open={addTicketState.addTicketActive}
        title={"Add Ticket"}
        size="small"
        closable
        onCancel={closeAddTicket}
        cancelText={"Cancel"}
        backDropCancel={false}
      >
        <div className="ui-checkbox-group">
          <div className="mb-4 w-full">
            <Input
              value={addTicketState.ticketSearch}
              onChange={(value: any) =>
                setAddTicketState(prevState => ({ ...prevState, ticketSearch: value.target.value }))
              }
              type="search"
              placeholder="Search tickets..."
            ></Input>
          </div>

          {addTicketState.allTickets?.map((ticket: any, index: number) => {
            return (
              <div key={index} onClick={(e: any) => handleAddTicket(e, ticket)} className="ui-checkbox-group-item">
                <div className="ui-checkbox-group-item-content">
                  <div className="ui-checkbox-group-item-text">
                    <p className="text-sm text-medium text-gray-700">{ticket.title}</p>
                    <p className="text-sm text-regular text-gray-600">{ticket.included}</p>
                  </div>
                </div>
                <Checkbox
                  size="medium"
                  value={ticket.id}
                  checked={false}
                  onChange={(e: any) => handleAddTicket(e, ticket)}
                />
              </div>
            );
          })}
        </div>
      </Sheet>

      <Sheet
        open={inventoryState.updateInventoryActive}
        size="small"
        closable
        title={"Update Inventory"}
        onOk={completeUpdateInventory}
        cancelText={"Cancel"}
        okText={"Update Inventory ✓"}
        onCancel={closeUpdateInventory}
      >
        <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange}>
          {selectedTab === 0 ? (
            <>
              <div className="booking-modal">
                <div className="booking-details">
                  <div className="booking-options">
                    <div className="booking-options_option">
                      <Input
                        label="New Quantity"
                        value={inventoryState.newQuantity || ""}
                        type="number"
                        id="newQuantity"
                        onChange={handleQuantityChange}
                        disabled={!productPermissions?.products_edit}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {selectedTab === 1 ? <div className="flex flex-column">Inventory Log</div> : null}
        </Tabs>
      </Sheet>

      {/* Update the price or cost for all facilities */}
      <Sheet
        open={updatePrices.displayPricesModal}
        title={`Update All ${capitalize(updatePrices.type)}`}
        size="small"
        closable
        onCancel={() => setUpdatePrices(prevState => ({ ...prevState, displayPricesModal: false, newPrice: "" }))}
        onOk={updateAllPriceCost}
        // onKeyDown={submitKeyboardEvent}
        cancelText={"Cancel"}
        backDropCancel={false}
      >
        <Input
          value={updatePrices.newPrice}
          labelHidden
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAllPricesInputChange(e)}
          prefix="$"
          type="number"
          disabled={!productPermissions?.products_edit}
          onKeyDown={submitKeyboardEvent}
        />
      </Sheet>

      <Popup
        okText="Confirm"
        onCancel={() => setVariantPopupOpen(false)}
        onOk={deleteVariant}
        title="Archive Variant"
        type="warning"
        description="Are you sure you want to archive this variant?"
        open={archiveVariantPopupOpen}
      />

      <Popup
        okText="Duplicate"
        onCancel={() => setDuplicateVariantOpen(false)}
        onOk={duplicateVariant}
        title="Duplicate Variant"
        type="warning"
        description="Are you sure you want to duplicate this variant?"
        open={duplicateVariantOpen}
      />
      {console.log("Variant State", variantState, "Tax State", taxState)}
      <Portal isMounted={taxState.updateTaxLinesActive}>
        <Sheet
          open={taxState.updateTaxLinesActive}
          title={"Taxes"}
          size="medium"
          closable
          onOk={updateTaxLines}
          onCancel={closeUpdateTaxes}
          cancelText={"Cancel"}
          backDropCancel={true}
        >
          <div className="edit-tax-lines_container">
            <div className="edit-tax-lines_title_container">
              <p className="edit-tax-lines_title">{variantState.title}</p>
            </div>

            <div className="edit-tax-lines_single_variant_container">
              <TagInputSelect
                tags={taxState.selectedTaxLines?.filter(lines =>
                  taxState.selectedInventoryLevel?.tax_lines?.map((line: ITaxLines) => {
                    return { name: `${lines.name} (${lines.percentage}%)`, id: lines.id };
                  }),
                )}
                selectOptions={taxState.availableTaxLines
                  ?.filter(
                    (line: TTaxLines) =>
                      taxState.selectedTaxLines?.findIndex(
                        (taxLine: { id: string | number }) => Number(taxLine.id) === Number(line.id),
                      ) < 0,
                  )
                  ?.map((line: TTaxLines) => {
                    return {
                      name: `${line.name} (${line.percentage}%)`,
                      id: line.id,
                      extraValue: line,
                    };
                  })}
                onChange={(value, line) => {
                  void handleTaxDropdown(Number(value), line);
                }}
                onTagClick={taxLineId => handleRemoveTaxLine(taxLineId)}
                className="edit-tax-lines_single_variant_container_tag_input"
                helpText={"Select tax lines to add to this variant"} // TODO: Translation
              />
            </div>
          </div>
        </Sheet>
      </Portal>
    </>
  );
}
