import React, { ChangeEvent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";

import { TProductType } from "redux/reducers/models/product";

import { showError, showSuccess } from "redux/actions/ui";
import useModal from "hooks/modals/useModal";
import { useAppDispatch } from "hooks/redux";
import { unsavedChangesExist } from "helpers/Helpers";

import Page from "components/page/Page";
import Popup from "components/popup/Popup";
import Input from "components/form/input";
import Card from "components/card/Card";
import Portal from "elements/Portal";
import { DeleteProductType, GetProductTypes, PutProductType } from "api/rpc/2024-04/facilityAdmin/product/type";
import { TPutProductTypeParams } from "api/rpc/2022-09/facilityAdmin/product/product";

const initialState: TProductType = {
  id: 0,
  client_id: 0,
  title: "",
  locked: false,
};

export default function ProductTypeEdit() {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();

  const [type, setType] = useState<TProductType>(initialState);
  const [typePreserved, setTypePreserved] = useState<TProductType>(initialState);

  const { state: deletePopup, updateModal: updateDeletePopup, closeModal: closeDeletePopup } = useModal();

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadProductType(source.token);
    return () => source.cancel();
  }, [id]);

  async function loadProductType(token?: CancelToken) {
    const res = await GetProductTypes({ id: Number(id) }, true, token);

    console.log(res);
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error getting product type"));
      return;
    }

    setType(res.data[0]);
    setTypePreserved(res.data[0]);
  }

  async function saveProductTypeChanges() {
    const params: TPutProductTypeParams = {
      id: Number(id),
      title: type.title,
    };

    const res = await PutProductType(params, true);
    console.log(res);
    if (res.status !== StatusCode.OK) {
      dispatch(showError(res.data));
      return;
    }

    dispatch(showSuccess("Successfully updated product type"));

    setType(res.data);
    setTypePreserved(res.data);
  }

  async function removeProductType() {
    const res = await DeleteProductType({ id: Number(id) }, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError(res.data));
      return;
    }
    dispatch(showSuccess("Successfully deleted product type"));
    closeDeletePopup();
    history.push("/admin/settings/types");
  }

  return (
    <Page
      title="Edit Product Type" // TODO: Translation
      breadcrumbs={[{ prefix: true, label: "Types", url: "/admin/settings/types" }]}
      notificationBarProps={{
        isVisible: unsavedChangesExist(type, typePreserved),
        onAction: () => saveProductTypeChanges(),
        onCancel: () => setType(typePreserved),
      }}
      primaryAction={{
        content: "Delete", // TODO: Translation
        action: () => updateDeletePopup({ isOpen: true }),
      }}
    >
      <Card>
        <Card.Section>
          <Input
            label="Title"
            value={type.title}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setType(prev => ({ ...prev, title: e.target.value }))}
          />
        </Card.Section>
      </Card>

      <Portal isMounted={deletePopup.isOpen}>
        <Popup
          open={deletePopup.isOpen}
          type="warning"
          title="Remove Product Type"
          description="Are you sure you want to remove this product type?"
          onOk={() => removeProductType()}
          onCancel={() => closeDeletePopup()}
        />
      </Portal>
    </Page>
  );
}
