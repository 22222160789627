import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios, { CancelToken } from "axios";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { GetLeague } from "api/rpc/2022-09/clientAdmin/league/league";

import { ILeague } from "redux/reducers/models/league";
import { useAppSelector } from "hooks/redux";

import { THIS_YEAR } from "components/form/calendarField/helpers";
import Page from "components/page/Page";
import FacilityDropFilter from "components/filters/FacilityDropFilter";
import YearDropFilter from "components/filters/YearDropFilter";
import Search from "components/search/Search";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

const TABLE_LIMIT = 50;
export default function Leagues() {
  const history = useHistory();
  const { t } = useTranslation();

  const { clientFacilityStore } = useAppSelector(store => store);

  const [leagues, setLeagues] = useState<ILeague[]>(undefined);
  const [facilityFiltersLoaded, setFacilityFiltersLoaded] = useState(false);

  // Starting filters for the Leagues API call.
  const [filterState, setFilterState] = useState({
    year: THIS_YEAR,
    facility_id: null,
    search: "",
    offset: 0,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();

    //ensures only 1 API call made on initial render
    if (facilityFiltersLoaded) {
      void loadLeagues(source.token);
    }

    return () => {
      source.cancel();
    };
  }, [filterState, facilityFiltersLoaded]);

  async function loadLeagues(token?: CancelToken) {
    // Allow UI Spinner
    if (leagues !== undefined) {
      setLeagues(undefined);
    }

    const res = await GetLeague({ ...filterState, limit: TABLE_LIMIT }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      setLeagues([]);
      return;
    }

    setLeagues(res.data);
  }

  function handleTableNavigation(direction: "prev" | "next") {
    switch (direction) {
      case "prev": {
        setFilterState(prev => ({ ...prev, offset: filterState.offset - TABLE_LIMIT }));
        return;
      }
      case "next": {
        setFilterState(prev => ({ ...prev, offset: filterState.offset + TABLE_LIMIT }));
        return;
      }
      default:
        return;
    }
  }

  const primaryAction = {
    content: "New League", // TODO: Translation
    action: () => history.push("/admin/events/new"),
  };

  return (
    <Page title="Leagues" narrow primaryAction={primaryAction}>
      <div className="flex flex-row flex-wrap gap-4 pb-4">
        <YearDropFilter
          handleApply={year => setFilterState({ ...filterState, year: year })}
          defaultCheckboxes={[filterState.year]}
          disableDropdown={!facilityFiltersLoaded}
          leftAligned
        />

        <FacilityDropFilter
          dataLoaded={() => setFacilityFiltersLoaded(true)}
          handleApply={facility_ids => setFilterState({ ...filterState, facility_id: facility_ids[0] })}
          defaultCheckboxes={[filterState.facility_id]}
          disableDropdown={!facilityFiltersLoaded}
          restrain
        />
      </div>
      <div style={{ paddingBottom: "1.5rem" }}>
        <Search
          historyKey={"client-leagues-search"}
          searchCallback={searchValue => setFilterState(prev => ({ ...prev, search: searchValue }))}
        />
      </div>
      <DataTable
        columns={[
          { label: "Name", width: "30%" }, // TODO: Translation
          { label: "Facility Name", width: "30%" }, // TODO: Translation
          { label: "Day", width: "20%" }, // TODO: Translation
          { label: "Players", width: "20%" }, // TODO: Translation
        ]}
        footer={{
          tableLimit: TABLE_LIMIT,
          tableOffset: filterState.offset,
          handleTableOffset: direction => handleTableNavigation(direction),
          disableNextOffset: !leagues || leagues.length <= TABLE_LIMIT,
        }}
        loading={leagues === undefined}
      >
        {leagues?.map(league => {
          const facility = clientFacilityStore?.facilities?.filter(facility => facility.id === league.facility_id);
          return (
            <tr
              key={league.id}
              className="clickable"
              onClick={() => history.push("/admin/league/" + String(league.id) + "/home")}
            >
              <td>{league.name}</td>
              <td>{facility[0].long_name}</td>
              <td>{league.week_day}</td>
              <td>{league.total_participant_count} players</td>
            </tr>
          );
        })}
      </DataTable>
    </Page>
  );
}
