import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";

import { PostPrinter } from "api/rpc/2022-09/facilityAdmin/facility/printer";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Form from "components/form/Form";
import Input from "components/form/input/Input";
import { Select } from "components/select/index";
import { useTranslation, Trans } from "react-i18next";

interface IProps {
  uiActions: any;
}

export default function PrinterNew(props: IProps) {
  const { uiActions } = props;
  const { Option } = Select;

  const history = useHistory();

  const { t, i18n } = useTranslation();

  const primaryAction = {
    content: t("secure.facility.settings.printer.printer_new.001"),
    action: createPrinter,
  };

  const [printerState, setPrinterState] = useState({
    title: "",
    brand: "",
    ip_address: "",
    port: "",
  });

  async function createPrinter() {
    const res = await PostPrinter(printerState, true);
    if (res.status === StatusCode.OK) {
      uiActions.showSuccess(t("secure.facility.settings.printer.printer_new.002"));
    } else {
      uiActions.showError(res.data.message);
    }

    history.push("/admin/settings/printers");
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setPrinterState(prevState => ({ ...prevState, [id]: value }));
  }

  return (
    <Page
      title={t("secure.facility.settings.printer.printer_new.003")}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        { prefix: true, label: t("secure.facility.settings.printer.printer_new.004"), url: "/admin/settings/printers" },
      ]}
    >
      <Card>
        <Card.Section>
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={printerState.title}
                  label={t("secure.facility.settings.printer.printer_new.005")}
                  id="title"
                  onChange={handleInputChange}
                  placeholder={t("secure.facility.settings.printer.printer_new.006")}
                />
                <Input
                  value={printerState.brand}
                  label={t("secure.facility.settings.printer.printer_new.007")}
                  id="brand"
                  onChange={handleInputChange}
                  placeholder={t("secure.facility.settings.printer.printer_new.008")}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Input
                  value={printerState.ip_address}
                  label={t("secure.facility.settings.printer.printer_new.009")}
                  id="ip_address"
                  onChange={handleInputChange}
                  placeholder="000.000.000.000"
                />
                <Input
                  value={printerState.port}
                  label={t("secure.facility.settings.printer.printer_new.010")}
                  id="port"
                  onChange={handleInputChange}
                  placeholder="8080"
                />
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
      </Card>
    </Page>
  );
}
