import React from "react";
import classNames from "classnames";
import MenuContext, { IMenuContextProps } from "./MenuContext";
import { SiderContext, ISiderContextProps } from "../layout/Sider";

export interface IMenuItemProps {
  icon?: React.ReactNode;
  fullWidthIcon?: boolean;
  danger?: boolean;
  title?: React.ReactNode;
  className?: string;
  prefixCls?: string;
  onClick?: any;
  selected?: boolean;
}

const MenuItem: React.FC<IMenuItemProps> = props => {
  const { prefixCls = "rc-menu-item", className, icon, fullWidthIcon, danger, title, children, ...rest } = props;

  const renderItemChildren = () => {
    if (icon) {
      return (
        <>
          <div
            className={classNames("rc-menu-item-container", {
              "rc-menu-item-container-selected": props.selected,
              "rc-menu-item-full-width": fullWidthIcon,
            })}
          >
            <span
              className={classNames("rc-menu-item-icon", {
                "rc-menu-item-icon-selected z-20": props.selected,
                "rc-menu-item-icon-full-width": fullWidthIcon,
              })}
            >
              {icon}
            </span>
          </div>
          {!fullWidthIcon && <span className="rc-menu-item-title">{children}</span>}
        </>
      );
    } else {
      return children;
    }
  };

  const renderItem = () => {
    const itemClassname = classNames(prefixCls, className);

    return (
      <li className={itemClassname} {...rest}>
        {renderItemChildren()}
      </li>
    );
  };

  return renderItem();
};

export default MenuItem;
