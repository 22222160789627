import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { PostDashboard } from "api/rpc/facilityAdmin/client/dashboard";

import { showError, showSuccess } from "redux/actions/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Input from "components/form/input/Input";

interface IDashboardState {
  title: string;
}

export default function DashboardsNew() {
  const history = useHistory();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { authStore } = useAppSelector(store => store);

  const [state, setState] = useState<IDashboardState>({
    title: "",
  });

  async function saveNewDashboard() {
    if (!state.title) {
      dispatch(showError(t("secure.facility.settings.dashboards.dashboards_new.001")));
      return;
    }

    const res = await PostDashboard({ user_id: authStore.user?.id, title: state.title }, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError(res.data?.message));
      return;
    }

    dispatch(showSuccess(res.data?.message));
    history.push("/admin/settings/dashboards");
  }

  const primaryAction = {
    content: t("secure.facility.settings.dashboards.dashboards_new.002"),
    action: saveNewDashboard,
  };

  return (
    <Page
      title={t("secure.facility.settings.dashboards.dashboards_new.003")}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.dashboards.dashboards_new.004"),
          url: "/admin/settings/dashboards",
        },
      ]}
    >
      <Card>
        <Card.Section>
          <Input
            value={state.title}
            label={t("secure.facility.settings.dashboards.dashboards_new.005")}
            onChange={e => setState(prevState => ({ ...prevState, title: e.target.value }))}
            placeholder={t("secure.facility.settings.dashboards.dashboards_new.006")}
            className="mb-2"
          />
        </Card.Section>
      </Card>
    </Page>
  );
}
