import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router";
import moment from "moment";

import { IOrder, OrderFinancialStatus } from "redux/reducers/models/order";
import { valueToString } from "helpers/Helpers";
import { LocaleCurrency } from "helpers/Locale";

import Avatar from "components/avatar/Avatar";
import { Badge } from "components/badge/Badge";
import Icon from "components/icon/Icon";
import { ButtonNew as Button } from "components/buttonNew";

import Spin from "components/spin/spin";
import { useTranslation } from "react-i18next";
import { IRainCheck } from "api/rpc/facilityAdmin/payment/rainCheck";
import DataTable from "../customer/tabs/houseAccounts/DataTable";

import "./rainChecksTable.scss";

type OrdersTableProps = {
  /** Passing undefined triggers Spin component */
  rainChecks: IRainCheck[];
  tableLimit: number;
  tableOffset: number;
  handleTableOffset: (direction: "prev" | "next") => void;
  handleTableNavigation?: (order_id: number) => void;
};

const TABLE_COLUMN_COUNT = 5;

export default function RainChecksTable(props: OrdersTableProps) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  function navigateToItem(id: number) {
    if (props.handleTableNavigation) {
      props.handleTableNavigation(id);
      return;
    }

    history.push("/admin/rainchecks/" + String(id));
  }

  return (
    <DataTable
      columns={[
        { label: t("secure.facility.order.rain_checks_table.001") },
        { label: t("secure.facility.order.rain_checks_table.002") },
        { label: t("secure.facility.order.rain_checks_table.003") },
        { label: t("secure.facility.order.rain_checks_table.004") },
        { label: t("secure.facility.order.rain_checks_table.005") },
      ]}
      footer={{
        tableOffset: props.tableOffset,
        tableLimit: props.tableLimit,
        handleTableOffset: direction => props.handleTableOffset(direction),
        disableNextOffset: !props.rainChecks || !(props.rainChecks?.length === props.tableLimit),
      }}
      loading={props.rainChecks === undefined}
    >
      {props.rainChecks?.map(rainCheck => {
        return (
          <tr key={rainCheck.id} className="clickable" onClick={() => navigateToItem(rainCheck.id)}>
            <td>{`•••• •••• ${rainCheck.last4}`}</td>
            <td>
              <span>{moment.utc(rainCheck.created_at).local().format("MMM DD, YYYY")} at </span>
              <span>{moment.utc(rainCheck.created_at).local().format("h:mm A")}</span>
            </td>
            <td className="table-data-customer">
              <div className="wrap">
                {rainCheck.customer ? (
                  <div className="customer">
                    <span>{rainCheck.customer?.full_name}</span>
                    <span>{rainCheck.customer?.email}</span>
                  </div>
                ) : (
                  <div className="customer">
                    <p>{t("components.orders_table.orders_table.006")}</p>
                  </div>
                )}
              </div>
            </td>
            <td>{generateOrderStatusBadge(rainCheck.status)}</td>
            <td>
              <LocaleCurrency currency={rainCheck.currency} amount={rainCheck.balance} />
            </td>
          </tr>
        );
      })}
    </DataTable>
  );
}

/** Generate order status badges from status title.
 *   -  Easily adjust badge styling in 1 place going forward
 *   -  TODO: Translations
 */
function generateOrderStatusBadge(status: string): JSX.Element {
  switch (status) {
    case "valid":
      return (
        <Badge
          type="success"
          // className="paid-order-badge"
          iconLeft={<Icon icon={"check"} style={"far"} size="small" />}
        >
          {valueToString(status, "capitalize")}
        </Badge>
      );

    case "redeemed":
      return <Badge type="warning">{valueToString(status, "capitalize")}</Badge>;

    default:
      return (
        <Badge type="gray" className="sales-channel-badge">
          {valueToString(status, "capitalize")}
        </Badge>
      );
  }
}
