import { APIGet, APIPost, APIPut, APIDelete, IAPIResponse } from "api/protocols";
import { CancelToken } from "axios";
import { ILeague } from "redux/reducers/models/league";

const apiVersion = "2024-04";
const baseCustomerUrl = "/" + apiVersion + "/customer/league";

interface IGetLeague {
  facility_id?: number;
  handle?: string;
  year?: number;
  offset?: number;
  limit?: number;
  search?: string;
  user_id?: number;
}

//GET Customer bookings
export async function GetLeagueParticipants(params: { league_id: number }, useGlobalLoader: boolean) {
  return await APIGet(baseCustomerUrl + "/participant", params, useGlobalLoader);
}

export async function GetCustomerLeague(
  params: IGetLeague,
  useGlobalLoader: boolean,
  token?: CancelToken,
): Promise<IAPIResponse<Array<ILeague>>> {
  return await APIGet(baseCustomerUrl, params, useGlobalLoader, token);
}
