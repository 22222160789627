import React, { createContext } from "react";

export type DirectionType = "ltr" | "rtl" | undefined;

export interface IConfigConsumerProps {
  getPrefixCls: (suffixCls?: string, customizePrefixCls?: string) => string;
  direction?: DirectionType;
}

const defaultGetPrefixCls = (suffixCls?: string, customizePrefixCls?: string) => {
  if (customizePrefixCls) {
return customizePrefixCls;
}
  return suffixCls ? `ant-${suffixCls}` : "ant";
};

export const ConfigContext = createContext<IConfigConsumerProps>({
  getPrefixCls: defaultGetPrefixCls,
});

export const ConfigConsumer = ConfigContext.Consumer;
