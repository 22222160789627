import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Page from "components/page/Page";
import Card from "components/card/Card";
import Input from "components/form/input/Input";
import { StatusCode } from "api/protocols";
import Toggle from "components/form/toggle/Toggle";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";

import { PostFolder } from "api/rpc/facilityAdmin/folders";
import { Select } from "components/select/index";

import { useTranslation } from "react-i18next";
import { GetRegisterGroups } from "api/rpc/2022-09/facilityAdmin/facility/register/group";
import { PostVendor } from "api/rpc/2024-04/facilityAdmin/product/vendor";

interface IVendorState {
  title: string;
}

export default function VendorsNew(props: any) {
  const { Option } = Select;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [vendorState, setVendorState] = useState<IVendorState>({
    title: "",
  });

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setVendorState(prevState => ({ ...prevState, [id]: value }));
  }

  async function saveNewVendor() {
    const params = {
      title: vendorState.title,
    };

    const res = await PostVendor(params, true);
    if (res.status !== StatusCode.OK) {
      console.log("Error saving new vendor");
      return;
    }

    console.log(res);
    history.push("/admin/settings/vendors");
  }

  const primaryAction = {
    content: t("secure.facility.settings.vendors.vendors_new.001"),
    action: saveNewVendor,
  };

  console.log("Search", vendorState.title);
  return (
    <Page
      title={t("secure.facility.settings.vendors.vendors_new.002")}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        { prefix: true, label: t("secure.facility.settings.vendors.vendors_new.003"), url: "/admin/settings/vendors" },
      ]}
    >
      <Card>
        <Card.Section>
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={vendorState.title}
                  label={t("secure.facility.settings.folders.folders_new.004")}
                  id="title"
                  onChange={handleInputChange}
                  placeholder={t("secure.facility.settings.folders.folders_new.005")}
                  className="mb-2"
                />
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
      </Card>
    </Page>
  );
}
