import { APIGet, APIPost, APIPut, APIDelete, IAPIResponse, StatusCode } from "api/protocols";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseTeeSheetUrl = "/" + apiVersion + "/" + adminType + "/tee-sheet";

interface IPostTeeSheet {
  date: string; //format YYYY-MM-DD
  course_id: number;
}

interface IGetTeeSheetOpenWindow {
  id?: number;
  start_date?: string;
  end_date?: string;
  month?: number;
  date?: string;
}

interface IPutTeeSheetOpenWindow {
  open_window_ids: Array<number>;
  open_time: string;
  close_time: string;
}

interface IPutTeeSheet {
  id?: number;
  tee_sheet_ids?: Array<number>;
  holiday?: boolean;
}

export async function GetTeeTime(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseTeeSheetUrl + "/tee-time", params, useGlobalLoader);
}

export async function PostTeeSheet(params: IPostTeeSheet, useGlobalLoader: boolean) {
  return await APIPost(baseTeeSheetUrl, params, useGlobalLoader);
}

export async function DeleteTeeSheetTemplate(params: any, useGlobalLoader: boolean) {
  return await APIDelete(baseTeeSheetUrl + "/template", params, useGlobalLoader);
}

export async function GetTeeSheetOpenWindow(params: IGetTeeSheetOpenWindow, useGlobalLoader: boolean) {
  return await APIGet(baseTeeSheetUrl + "/open-window", params, useGlobalLoader);
}

export async function PutTeeSheetOpenWindow(params: IPutTeeSheetOpenWindow, useGlobalLoader: boolean) {
  return await APIPut(baseTeeSheetUrl + "/open-window", params, useGlobalLoader);
}

export async function PutTeeSheet(params: IPutTeeSheet, useGlobalLoader: boolean) {
  return await APIPut(baseTeeSheetUrl, params, useGlobalLoader);
}

export async function PostTeeTime(params: { tee_time_id: number; interval: number }, useGlobalLoader: boolean) {
  return await APIPost(baseTeeSheetUrl + "/tee-time", params, useGlobalLoader);
}
