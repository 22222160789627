import { useMemo } from "react";
import { useStore } from "reactflow";

export function useLimitOutgoingBranching(id: string, outgoingBranchingLimit: number) {
  const { edges } = useStore(s => ({ edges: s.edges }));

  const isHandleConnectable = useMemo(() => {
    const outgoingEdgesCount = edges.filter(edge => edge.source === id).length;

    return outgoingEdgesCount < outgoingBranchingLimit;
  }, [edges]);

  return isHandleConnectable;
}
