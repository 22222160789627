import { IOrder, IOrderLineItem } from "./models/order";

export interface IOrderState {
  isLoaded: boolean;
  error?: any;
  order: IOrder;
  id: number;
  line_items: IOrderLineItem[];
  line_item_count: number;
}

export interface IOrderAction {
  type: string;
  payload: any;
}

const initialState: IOrderState = {
  isLoaded: false,
  error: null,
  order: null,
  id: null,
  line_items: null,
  line_item_count: 0,
};

export default function OrderReducer(state = initialState, action: IOrderAction) {
  switch (action.type) {
    case "order.update":
      return {
        ...state,
        ...action.payload,
      };
    case "order.clear":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
