import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useHistory, useParams } from "react-router";
import "pages/guest/Customer/TeeTimeRegister.scss";
import { CreateAccount, GetTeeTimeGuest, GetBookingEngine, LockTeeTimeById } from "api/rpc/2022-09/guest/bookingEngine";
import { UserLogin } from "api/rpc";
import { StatusCode } from "api/protocols";
import { IBookingEngineState } from "redux/reducers/bookingEngine";
import { IBookingEngineActions } from "redux/actions/actionTypes";
import { convertTime } from "helpers/Helpers";
import FormLayout from "components/form/FormLayout";
import Form from "components/form/Form";
import Input from "components/form/input/index";
import validator from "validator";
import { IUIActions } from "redux/actions/ui";
import { PostPasswordResetCode } from "api/rpc/2022-09/passwordReset";
import { useTranslation } from "react-i18next";

export interface IRegisterInfo {
  email: string;
  password: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  bookingEngineId: any;
  facilityId: any;
}

export interface IFilterState {
  selectedCourseLogo: any;
  selectedCourseName: any;
  selectedHeaderBackground: any;
  eyeIcon: any;
  invalidEmail: boolean;
  invalidFirstName: boolean;
  invalidLastName: boolean;
  invalidPhoneNumber: boolean;
  invalidPassword: boolean;
  emailInUse: boolean;
}

interface IProps {
  bookingEngineStore: IBookingEngineState;
  bookingEngineActions: IBookingEngineActions;
  uiActions: IUIActions;
}

interface IParams {
  bookingEngineHandle: string;
}

const TeeTimeRegister: React.FC<IProps> = props => {
  const { bookingEngineHandle } = useParams<IParams>();
  const { bookingEngineStore, bookingEngineActions } = props;

  const eyeIcon = <FontAwesomeIcon icon={["far", "eye"]} />;

  console.log(bookingEngineStore);

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const tempGolfCourseName = bookingEngineHandle.split("-");
  const golfCourseName = String(tempGolfCourseName[0]);
  const handle = String(bookingEngineHandle);

  const [registerInfo, setRegisterInfo] = useState<IRegisterInfo>({
    email: "",
    password: "",
    phoneNumber: "",
    firstName: "",
    lastName: "",
    bookingEngineId: "",
    facilityId: "",
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    selectedCourseName: bookingEngineStore.selectedCourseLongName,
    selectedCourseLogo: bookingEngineStore.selectedCourseLogo,
    selectedHeaderBackground: "https://www.foresightgolf.net/images/template/banner-cypresswood.jpg",
    eyeIcon: eyeIcon,
    invalidEmail: false,
    invalidFirstName: false,
    invalidLastName: false,
    invalidPhoneNumber: false,
    invalidPassword: false,
    emailInUse: false,
  });

  useEffect(() => {
    if (
      bookingEngineStore.selectedAddress === "" &&
      bookingEngineStore.selectedDate === "" &&
      bookingEngineStore.selectedLoginRequired === null &&
      bookingEngineStore.selectedTeeTimeId === ""
    ) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      //Page has been Refreshed
      console.log("Page refreshed, run api call");
      void getBookingEngine();
    } else {
      console.log("Data in store");

      updateFilterState({
        selectedCourseName: bookingEngineStore.selectedCourseLongName,
        selectedCourseLogo: bookingEngineStore.selectedCourseLogo,
      });

      updateRegisterInfo({
        facilityId: bookingEngineStore.selectedFacilityId,
        bookingEngineId: bookingEngineStore.selectedBookingEngineId,
      });
    }
  }, []);

  function updateRegisterInfo(newFilterState: Partial<IRegisterInfo>) {
    setRegisterInfo(cur => {
      return { ...cur, ...newFilterState };
    });
  }

  function updateFilterState(newFilterState: Partial<IFilterState>) {
    setFilterState(cur => {
      return { ...cur, ...newFilterState };
    });
  }

  async function getBookingEngine() {
    const params = {
      handle: bookingEngineHandle,
    };

    console.log(params);

    const bookingEngineRes = await GetBookingEngine(params, true);

    if (bookingEngineRes.status !== StatusCode.OK) {
      alert(bookingEngineRes.message);
      return;
    }

    registerInfo.facilityId = bookingEngineRes.data[0].facility_id;
    registerInfo.bookingEngineId = bookingEngineRes.data[0].id;

    const fullAddress = `${bookingEngineRes.data[0]?.facility?.address_line_1}, ${bookingEngineRes.data[0]?.facility?.city},
      ${bookingEngineRes.data[0]?.facility?.province_name}, ${bookingEngineRes.data[0]?.facility?.postal}`;

    updateFilterState({
      selectedCourseName: bookingEngineStore.selectedCourseLongName,
      selectedCourseLogo: bookingEngineRes.data[0].facility.logo_source,
    });

    bookingEngineActions.update({
      selectedCourseLongName: bookingEngineRes.data[0].facility.long_name,
      selectedCourseLogo: bookingEngineRes.data[0].facility.logo_source,
      selectedHeaderBackground: filterState.selectedHeaderBackground,
      selectedLoginRequired: bookingEngineRes.data[0].requires_login,
      selectedFacilityId: bookingEngineRes.data[0].facility_id,
      selectedBookingEngineId: bookingEngineRes.data[0].id,
      selectedBookingTerms: bookingEngineRes.data[0].booking_terms,
      paymentTerms: bookingEngineRes.data[0].payment_terms,
      activeCustomerId: null,
      enabled_payment_methods: bookingEngineRes.data[0].enabled_payment_methods,
      courseLogo: bookingEngineRes.data[0]?.facility.logo_source,
      selectedFullAddress: fullAddress,
    });
  }

  function sendToNextPage(address: any) {
    history.push(address);
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value, name } = event.target;

    console.log("input event name", event.target.name);
    console.log("input event value", event.target.value);

    setRegisterInfo(prevState => ({ ...prevState, [name]: value }));
  }

  async function createAccount() {
    const params = {
      first_name: registerInfo.firstName ?? "",
      last_name: registerInfo.lastName ?? "",
      email: registerInfo.email ?? "",
      password: registerInfo.password ?? "",
      phone: registerInfo.phoneNumber ?? "",
    };

    if (
      params.first_name === "" ||
      params.last_name === "" ||
      params.email === "" ||
      params.password === "" ||
      params.phone === ""
    ) {
      props.uiActions.showError(t("guest.customer.tee_time_register.001"));
      return;
    }

    const invalidFirstName = !validator.isAlpha(params.first_name);
    const invalidLastName = !validator.isAlpha(params.last_name);
    const invalidEmail = !validator.isEmail(params.email);
    const invalidPhoneNumber = !validator.isMobilePhone(params.phone, "en-CA");
    const invalidPassword = params.password.length < 8;

    updateFilterState({
      invalidFirstName: invalidFirstName,
      invalidLastName: invalidLastName,
      invalidEmail: invalidEmail,
      invalidPhoneNumber: invalidPhoneNumber,
      invalidPassword: invalidPassword,
    });

    if (invalidFirstName || invalidLastName || invalidEmail || invalidPhoneNumber || invalidPassword) {
      if (invalidEmail) {
        updateFilterState({ emailInUse: false });
      }
      return;
    }

    const accountCreationRes = await CreateAccount(params, true);

    if (accountCreationRes.status !== StatusCode.OK) {
      if (accountCreationRes.data === "The email has already been taken.") {
        updateFilterState({ emailInUse: true });
      } else {
        props.uiActions.showError(accountCreationRes.data);
      }

      return;
    }

    updateFilterState({ emailInUse: false });
    void confirmSignIn(params);
  }

  //Confirm sign in success / Check Tee Time Availability
  async function confirmSignIn(registerInfo: Partial<IRegisterInfo>) {
    const username = registerInfo.email;
    const password = registerInfo.password;

    console.log(username, password);

    const params = {
      email: username,
      password: password,
    };

    //Validate user login
    const loginRes = await UserLogin(params, true);

    console.log(loginRes);

    if (loginRes.status !== StatusCode.OK) {
      alert(t("guest.customer.tee_time_register.003"));
      return;
    }

    bookingEngineActions.update({
      selectedUserFirstName: loginRes.data.user.first_name,
      userFullName: loginRes.data.user.full_name,
    });

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const date = urlParams.get("date");
    const teeTimeId = urlParams.get("id");
    const teetimeSelectionAddress = "/booking-engine/" + golfCourseName + "/" + handle;

    if (date === null || date === "null" || teeTimeId === null || teeTimeId === "null") {
      sendToNextPage(teetimeSelectionAddress);
      return;
    }

    const teeInformationByIdRes = await GetTeeTimeGuest(
      {
        id: Number(teeTimeId),
        date: date,
        booking_engine_handle: bookingEngineHandle,
        turn_tee_time: true,
        extended: true,
      },
      true,
    );

    if (teeInformationByIdRes.status !== StatusCode.OK) {
      alert(t("guest.customer.tee_time_register.004"));
      return;
    }

    if (teeInformationByIdRes.data.length === 0) {
      alert(t("guest.customer.tee_time_register.005"));

      sendToNextPage(teetimeSelectionAddress);
      return;
    }

    //Check if Tee Time Id chosen by user matches available Tee Times Id
    if (teeInformationByIdRes.data[0].id === Number(teeTimeId)) {
      console.log("Tee Time Is Available");

      const tempId = String(teeTimeId);
      const tempDate = String(date);

      const tempStartTime = convertTime(teeInformationByIdRes.data[0].start_time);
      console.log(tempStartTime);

      const tempFormatDate = formatDateString(tempDate);

      bookingEngineActions.update({
        selectedDate: teeInformationByIdRes.data[0].date,
        selectedAddress: teeInformationByIdRes.data[0].facility.address_line_1,
        selectedTeeTimeId: teeInformationByIdRes.data[0].id,
        selectedCity: teeInformationByIdRes.data[0].facility.city,
        selectedCourseName: teeInformationByIdRes.data[0].facility.full_name,
        selectedCourseLongName: teeInformationByIdRes.data[0].facility.long_name,
        selectedPostalCode: teeInformationByIdRes.data[0].facility.postal,
        selectedProvince: teeInformationByIdRes.data[0].facility.province_name,
        selectedGolferSpotsRemaining: teeInformationByIdRes.data[0].quantity_remaining,
        selectedStartHole: teeInformationByIdRes.data[0].start_hole,
        selectedStartTime: tempStartTime,
        selectedFacilityId: teeInformationByIdRes.data[0].facility.id,
        selectedFormattedDate: tempFormatDate,
        facilityClientId: teeInformationByIdRes.data[0].facility.client_id,
        creditCardRequired: teeInformationByIdRes.data[0].credit_card_required,
        prepaidRequired: teeInformationByIdRes.data[0].pre_paid_required,
        activeCustomerId: loginRes.data.user.id,
        cart_rule: teeInformationByIdRes.data[0].cart_rule,
        divisionsAvailable: teeInformationByIdRes.data[0].divisions_available,
        turnTeeTime: teeInformationByIdRes.data[0].turn_tee_time,
      });

      console.log(bookingEngineStore);

      //If not null - User has access to locked teetime
      if (teeInformationByIdRes.data[0].lock !== null) {
        console.log(teeInformationByIdRes.data[0].lock);

        const tempCurrentDate = new Date().toUTCString();
        const currentTempTime = new Date(tempCurrentDate);
        const currentTime = new Date(currentTempTime).getTime();

        const tempString = String(teeInformationByIdRes.data[0].lock.start_time) + "Z";
        console.log(tempString);

        const lockStartDate = new Date(tempString);
        console.log(lockStartDate);

        const tempExpiryString = String(teeInformationByIdRes.data[0].lock.expire_time) + "Z";
        const lockoutTempExpiry = new Date(tempExpiryString);

        const lockStartTime = lockStartDate.getTime();
        const lockoutExpiryTime = lockoutTempExpiry.getTime();

        const lockoutDuration = lockoutExpiryTime - currentTime;

        bookingEngineActions.update({
          selectedLockoutDuration: lockoutDuration,
          selectedLockoutStartTime: lockStartTime,
          selectedLockoutExpireTime: teeInformationByIdRes.data[0].lock.expire_time,
        });

        const holeSelectionAddress =
          "/booking-engine/" + golfCourseName + "/" + handle + "/holes" + "?id=" + tempId + "&date=" + tempDate;

        sendToNextPage(holeSelectionAddress);
      } else {
        const bookingLockRes = await LockTeeTimeById({ tee_time_id: teeInformationByIdRes.data[0].id }, true);
        console.log(bookingLockRes);

        if (bookingLockRes.status !== StatusCode.OK) {
          alert(t("guest.customer.tee_time_register.006"));
          console.log("Error with booking locking");
          return;
        }

        //Locked out message
        const bookingLockMessage = t("guest.customer.tee_time_register.007");
        const bookingLockResMessage = bookingLockRes.data.message;

        if (bookingLockMessage === bookingLockResMessage) {
          alert(t("guest.customer.tee_time_register.008"));
          console.log("Booking is locked by another user");
          return;
        }

        const tempCurrentDate = new Date().toUTCString();
        const currentTempTime = new Date(tempCurrentDate);
        const currentTime = new Date(currentTempTime).getTime();

        const tempString = String(bookingLockRes.data.start_time) + "Z";
        console.log(tempString);

        const lockStartDate = new Date(tempString);
        console.log(lockStartDate);

        const tempExpiryString = String(bookingLockRes.data.expire_time) + "Z";
        const lockoutTempExpiry = new Date(tempExpiryString);

        const lockStartTime = lockStartDate.getTime();
        const lockoutExpiryTime = lockoutTempExpiry.getTime();

        const lockoutDuration = lockoutExpiryTime - currentTime;

        bookingEngineActions.update({
          selectedLockoutDuration: lockoutDuration,
          selectedLockoutStartTime: lockStartTime,
          selectedLockoutExpireTime: bookingLockRes.data.expire_time,
        });

        const holeSelectionAddress =
          "/booking-engine/" + golfCourseName + "/" + handle + "/holes" + "?id=" + tempId + "&date=" + tempDate;

        sendToNextPage(holeSelectionAddress);
      }
    } else {
      console.log("Tee Time Unavailable");
      alert(t("guest.customer.tee_time_register.009"));

      const teetimeSelectionAddress = "/booking-engine/" + golfCourseName + "/" + handle;

      sendToNextPage(teetimeSelectionAddress);
    }
  }

  function sendToLogin() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const publicAccess = urlParams.get("public");
    const teeTimeId = urlParams.get("id");
    const date = urlParams.get("date");
    const divisions = urlParams.get("divisions");
    const loginAddress =
      "/booking-engine/" +
      golfCourseName +
      "/" +
      handle +
      "/login?public=" +
      publicAccess +
      "&date=" +
      date +
      "&id=" +
      teeTimeId +
      "&divisions=" +
      divisions;

    sendToNextPage(loginAddress);
  }

  async function sendPasswordRecovery() {
    if (registerInfo.email === undefined || !validator.isEmail(registerInfo.email)) {
      props.uiActions.showError(t("guest.customer.tee_time_register.010"));
      return;
    }

    const params = {
      email: registerInfo.email,
      facility_id: bookingEngineStore.selectedFacilityId,
      //booking_engine_id: registerInfo.bookingEngineId,
    };

    const resetRes = await PostPasswordResetCode(params, true);

    console.log(resetRes);

    if (resetRes.status !== StatusCode.OK) {
      props.uiActions.showError(resetRes.data.message);
      return;
    }

    props.uiActions.showSuccess(t("guest.customer.tee_time_register.011"));
    const loginAddress = "/booking-engine/" + golfCourseName + "/" + handle + "/login";

    sendToNextPage(loginAddress);
  }

  //Function to format date to readable string
  function formatDateString(date: any) {
    const tempDateArray = date.split("-");

    //Get tee time day
    const tempDay = tempDateArray[2];

    //Get tee time year
    const tempYear = tempDateArray[0];

    //Get tee time month
    const tempMonth = tempDateArray[1] - 1;

    //Get full date for tee time
    const d = new Date(tempYear, tempMonth, tempDay);

    const month = d.toLocaleString("en-us", { month: "long" });

    const day = d.toLocaleString("en-us", { day: "numeric" });

    const year = d.toLocaleString("en-us", { year: "numeric" });

    const weekday = d.toLocaleString("en-us", { weekday: "long" });

    const dateString = weekday + " " + month + " " + day + ", " + year;

    return dateString;
  }
  return (
    <div className="ttcheckout-box">
      <div className="ttr-header-container">
        <span className="ttr-bars-icon-div">
          <img
            className="ttr-tee-on-logo"
            src="../../../public/images/Tee-On.png"
            alt={t("guest.customer.tee_time_register.012")}
          />
        </span>
        <span>
          <p style={{ fontSize: "22px", fontWeight: 700, fontStyle: "italic", marginLeft: "22px" }}>
            {filterState.selectedCourseName}
          </p>
        </span>
      </div>

      <div className="tt-register-container">
        <div className="tt-register-input-div">
          <div className="tt-first-last-div-register-info">
            <p className="register-p">{t("guest.customer.tee_time_register.013")}</p>
            <br />
            <p className="register-info-p">{t("guest.customer.tee_time_register.014")}</p>
            <p className="register-info-p">{t("guest.customer.tee_time_register.015")}</p>
          </div>

          <br />

          {filterState.invalidFirstName ||
          filterState.invalidLastName ||
          filterState.invalidEmail ||
          filterState.invalidPhoneNumber ||
          filterState.invalidPassword ||
          filterState.emailInUse ? (
            <div className="tt-register-error">
              {filterState.invalidFirstName && (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ display: "flex" }}>
                    <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="tt-register-triangle-icon" />
                  </div>
                  <p>{t("guest.customer.tee_time_register.016")}</p>
                </div>
              )}
              {filterState.invalidLastName && (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ display: "flex" }}>
                    <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="tt-register-triangle-icon" />
                  </div>
                  <p>{t("guest.customer.tee_time_register.017")}</p>
                </div>
              )}
              {filterState.invalidEmail && (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ display: "flex" }}>
                    <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="tt-register-triangle-icon" />
                  </div>
                  <p>{t("guest.customer.tee_time_register.018")}</p>
                </div>
              )}
              {filterState.invalidPhoneNumber && (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ display: "flex" }}>
                    <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="tt-register-triangle-icon" />
                  </div>
                  <p>{t("guest.customer.tee_time_register.019")}</p>
                </div>
              )}
              {filterState.invalidPassword && (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ display: "flex" }}>
                    <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="tt-register-triangle-icon" />
                  </div>
                  <p>{t("guest.customer.tee_time_register.020")}</p>
                </div>
              )}
              {filterState.emailInUse && (
                <div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex" }}>
                      <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="tt-register-triangle-icon" />
                    </div>
                    <p>
                      {t("guest.customer.tee_time_register.021")} <span>{filterState.selectedCourseName}</span>{" "}
                      {t("guest.customer.tee_time_register.022")} &apos;{t("guest.customer.tee_time_register.023")}
                      &apos; {t("guest.customer.tee_time_register.024")}
                    </p>
                  </div>

                  <button onClick={sendPasswordRecovery} className="tt-register-email-resend-invite">
                    {t("guest.customer.tee_time_register.025")} <FontAwesomeIcon icon={["fal", "paper-plane"]} />
                  </button>
                </div>
              )}
            </div>
          ) : null}

          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={registerInfo.firstName}
                  label={t("guest.customer.tee_time_register.026")}
                  name="firstName"
                  onChange={handleInputChange}
                  labelStyle={{ fontSize: "12px", color: "#999", marginBottom: "0" }}
                />
                <Input
                  value={registerInfo.lastName}
                  label={t("guest.customer.tee_time_register.027")}
                  name="lastName"
                  onChange={handleInputChange}
                  labelStyle={{ fontSize: "12px", color: "#999", marginBottom: "0" }}
                />
              </FormLayout.Group>

              <FormLayout.Group>
                <Input
                  value={registerInfo.email}
                  label={t("guest.customer.tee_time_register.028")}
                  name="email"
                  onChange={handleInputChange}
                  labelStyle={{ fontSize: "12px", color: "#999", marginBottom: "0" }}
                />
              </FormLayout.Group>

              <FormLayout.Group>
                <Input
                  value={registerInfo.phoneNumber}
                  label={t("guest.customer.tee_time_register.029")}
                  name="phoneNumber"
                  onChange={handleInputChange}
                  labelStyle={{ fontSize: "12px", color: "#999", marginBottom: "0" }}
                />
              </FormLayout.Group>

              <FormLayout.Group>
                <Input.Password
                  label={t("guest.customer.tee_time_register.030")}
                  value={registerInfo.password}
                  name="password"
                  onChange={handleInputChange}
                  labelStyle={{ fontSize: "12px", color: "#999", top: "-24px" }}
                />
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </div>

        <div>
          <button className="tt-register-button" onClick={createAccount}>
            {t("guest.customer.tee_time_register.031")}
          </button>
        </div>

        <div className="sign-in-bottom-div">
          <p className="log-in-text">{t("guest.customer.tee_time_register.032")}</p>
          <button className="log-in-button" onClick={sendToLogin}>
            {t("guest.customer.tee_time_register.033")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TeeTimeRegister;
