import { APIGet, APIPost, APIPut, APIDelete, IAPIResponse, APIUrl, AdminType } from "api/protocols";
import { CancelToken } from "axios";
import { TCustomerType } from "redux/reducers/models/facility";
import { IBookingCategory } from "redux/reducers/models/teetime";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/customer/type";

export type TGetCustomerType = {
  id?: number;
  default?: boolean;
  application?: string;
  /** Search customer type title */
  search?: string;
  configuration?: boolean;
  booking_category?: boolean;
};

export type TPutCustomerTypeConfig = {
  id: string | number;
  booking_category_id: string | number;
  days_in_advance_start: string;
  days_in_advance_end: string;
  open_time: string;
  max_current_bookings?: number;
  max_daily_bookings?: number;
};

interface IPostCustomerType {
  title?: string;
  migrate_customer_type_id?: number;
  subtitle?: string;
  configuration?: boolean;
  booking_category_id?: number;
  days_in_advance_start?: number;
  days_in_advance_end?: number;
}

interface IPutCustomerType {
  title?: string;
  subtitle?: string;
  booking_category_id?: number;
  color?: string;
}

interface IDeleteCustomerType {
  id: number;
  migrate_customer_type_id?: number;
}

export interface ICustomerTypeRes {
  status: number;
  data: CustomerTypeResStructure[];
}

export type CustomerTypeResStructure = {
  application: string;
  booking_category: IBookingCategory;
  client_id: number;
  color: string | null;
  default: number;
  default_booking_category: IBookingCategory;
  default_booking_category_id: number;
  full_title: string;
  id: number;
  subtitle: string | null;
  title: string;
};

export async function GetCustomerType(params: TGetCustomerType, useGlobalLoader: boolean, cancelToken?: CancelToken) {
  return await APIGet<TCustomerType[]>(
    APIUrl(AdminType.FacilityAdmin, "/customer/type"),
    params,
    useGlobalLoader,
    cancelToken,
  );
}

export async function PutCustomerTypeConfiguration(
  params: { configurations: TPutCustomerTypeConfig[] },
  useGlobalLoader?: boolean,
) {
  return await APIPut(APIUrl(AdminType.FacilityAdmin, "/customer/type/configuration"), params, useGlobalLoader);
}

//Post new customer type
export async function PostCustomerType(
  params: IPostCustomerType,
  useGlobalLoader?: boolean,
): Promise<ICustomerTypeRes> {
  return await APIPost(APIUrl(AdminType.FacilityAdmin, "/customer/type"), params, useGlobalLoader);
}

//Edit existing customer type
export async function PutCustomerType(params: IPutCustomerType, useGlobalLoader?: boolean): Promise<ICustomerTypeRes> {
  return await APIPut(APIUrl(AdminType.FacilityAdmin, "/customer/type"), params, useGlobalLoader);
}

//Delete and migrate customer type
export async function DeleteCustomerType(
  params: IDeleteCustomerType,
  useGlobalLoader?: boolean,
): Promise<ICustomerTypeRes> {
  return await APIDelete(APIUrl(AdminType.FacilityAdmin, "/customer/type"), params, useGlobalLoader);
}
