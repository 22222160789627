import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import {
  DeleteLeagueDivision,
  GetLeagueDivision,
  PutLeagueDivision,
  TPutLeagueDivision,
} from "api/rpc/2024-04/facilityAdmin/league/scoring/division";

import { showError, showSuccess } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";
import useModal from "hooks/modals/useModal";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import Popup from "components/popup/Popup";
import Portal from "elements/Portal";

export default function LeagueDivisionEdit() {
  const history = useHistory();
  const { t } = useTranslation();
  const { leagueId, divisionId } = useParams<{ leagueId: string; divisionId: string }>();

  const dispatch = useAppDispatch();

  const [division, setDivision] = useState<Partial<TPutLeagueDivision>>(undefined);

  const { state: deleteModal, updateModal, closeModal } = useModal();

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadLeagueDivision(leagueId, divisionId, source.token);
    return () => source.cancel();
  }, [leagueId, divisionId]);

  async function loadLeagueDivision(league_id: string | number, divisionId: string | number, token?: CancelToken) {
    const res = await GetLeagueDivision({ league_id: league_id, id: divisionId }, token ? false : true);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading division")); // TODO: Translation
      return;
    }

    setDivision(res.data[0]);
  }

  async function updateDivision(params: TPutLeagueDivision) {
    const res = await PutLeagueDivision(params, true);
    console.log(res);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error updating division")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Successfully updated division")); // TODO: Translation
    history.push(`/admin/league/${leagueId}/divisions`);
  }

  async function deleteDivision() {
    const deleteRes = await DeleteLeagueDivision({ id: divisionId }, true);

    if (deleteRes.status !== StatusCode.OK) {
      dispatch(showError("Error deleting division")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Successfully removed division")); // TODO: Translation
    history.push(`/admin/league/${leagueId}/divisions`);
  }

  const primaryAction = {
    content: "Update Division", // TODO: Translation
    action: () => updateDivision({ id: divisionId, ...division }),
    disabled: division === undefined || !division?.title,
  };

  const secondaryAction = [
    {
      content: "Delete", // TODO: Translation
      action: () => updateModal({ isOpen: true }),
      disabled: true, // TODO: BACKEND REQUIRED
      // disabled: division === undefined,
    },
  ];

  return (
    <Page
      title={"Edit Division"} // TODO: Translation
      narrow
      primaryAction={primaryAction}
      secondaryActions={secondaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: "Divisions", // TODO: Translation
          url: `/admin/league/${leagueId}/divisions`,
        },
      ]}
    >
      <Form>
        <Card title={"Division Details"}>
          {" "}
          {/* TODO: Translation */}
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  id="title"
                  value={division?.title ?? ""}
                  label={"Title"} // TODO: Translation
                  onChange={e => setDivision(prev => ({ ...prev, title: e.target.value }))}
                  placeholder={"Division title"} // TODO: Translation
                  disabled={division === undefined}
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>
      </Form>

      <Portal isMounted={deleteModal.isOpen}>
        <Popup
          open={deleteModal.isOpen}
          type="warning"
          title="Delete Division?" // TODO: Translation
          description="Are you sure you want to delete this division?" // TODO: Translation
          onCancel={closeModal}
          onOk={deleteDivision}
          okText="Delete" // TODO: Translation
        />
      </Portal>
    </Page>
  );
}
