import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import * as authActions from "actions/auth";
import ScoreSheetEdit from "pages/secure/facility/events/league/scoring/scoresheets/LeagueScoresheetEdit";
import * as UIActions from "redux/actions/ui";

const mapDispatchToProps = (dispatch: any) => {
  return {
    uiActions: bindActionCreators(
      {
        ...UIActions,
      },
      dispatch,
    ),
  };
};

const mapStateToProps = (state: any) => {
  // const { authStore } = state;
  return {
    uiStore: { ...state.uiStore },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScoreSheetEdit);
