import React from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import { IGiftCard } from "api/rpc/2022-09/facilityAdmin/payment/giftCard";

import { valueToString } from "helpers/Helpers";
import { LocaleCurrency } from "helpers/Locale";

import { Badge } from "components/badge/Badge";
import Icon from "components/icon/Icon";
import DataTable from "../customer/tabs/houseAccounts/DataTable";

type GiftCardsTableProps = {
  /** Passing undefined triggers Spin component */
  giftCards: IGiftCard[];
  viewPermission: boolean;
  /** defaults to history.push giftCard.code if not passed. */
  handleTableNavigation?: (card: IGiftCard) => void;
};

export default function GiftCardsTable(props: GiftCardsTableProps) {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  function navigateToItem(card: IGiftCard) {
    if (props.handleTableNavigation) {
      props.handleTableNavigation(card);
      return;
    }

    history.push("/admin/giftcards/" + card.code);
  }

  return (
    <DataTable
      columns={[
        { label: t("secure.facility.order.gift_cards_table.001"), width: "50%" },
        { label: t("secure.facility.order.gift_cards_table.002"), width: "25%" },
        { label: t("secure.facility.order.gift_cards_table.003"), width: "25%" },
      ]}
      loading={props.giftCards === undefined}
    >
      {props.giftCards?.map(giftCard => (
        <tr key={giftCard.id} className="clickable" onClick={() => navigateToItem(giftCard)}>
          <td>{!props.viewPermission ? <span>•••• •••• {giftCard.last4}</span> : <span>{giftCard.code}</span>}</td>
          <td>
            <LocaleCurrency currency={giftCard.currency} amount={giftCard.balance} />
          </td>
          <td>
            <GiftCardStatusBadge status={giftCard.status} />
          </td>
        </tr>
      ))}
    </DataTable>
  );
}

/** Generate order status badges from status title.
 *   -  Easily adjust badge styling in 1 place going forward
 */
export function GiftCardStatusBadge(props: { status: string }): JSX.Element {
  switch (props.status) {
    case "valid":
      return (
        <Badge type="success" iconLeft={<Icon icon={"check"} style={"far"} size="small" />}>
          {valueToString(props.status, "capitalize")}
        </Badge>
      );

    case "disabled":
      return <Badge type="error">{valueToString(props.status, "capitalize")}</Badge>;

    default:
      return (
        <Badge type="gray" className="sales-channel-badge">
          {valueToString(props.status, "capitalize")}
        </Badge>
      );
  }
}
