import Sheet from "components/sheet/Sheet";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { LocaleCurrency } from "helpers/Locale";
import Callout from "components/callout/Callout";
import Checkbox from "components/form/checkbox/Checkbox";
import { ICart } from "redux/reducers/models/cart";
import { useAppSelector } from "hooks/redux";
import "./mergeSeats.scss";

export interface IMergeSeatsState {
  selectedMergeSeatIds: Array<number>;
  currentSeatNumber: number;
}

interface IMergeSeatsProps {
  mergeSeatsVisible: boolean;
  onCancel?: () => void;
  onOk?: (priceChangeInfo: IMergeSeatsState) => void;
}

function MergeSeats(props: IMergeSeatsProps) {
  const { t, i18n } = useTranslation();
  const { cartStore } = useAppSelector(store => store);
  const [state, setState] = useState<IMergeSeatsState>({
    selectedMergeSeatIds: [],
    currentSeatNumber: null,
  });

  useEffect(() => {
    if (props.mergeSeatsVisible) {
      let foundCurrentSeat: number;

      for (let i = 0; i < cartStore.activeTable?.carts?.length; i++) {
        console.log(cartStore.activeTable.carts[i]);
        if (cartStore.activeTable.carts[i].id === cartStore.cart?.id) {
          foundCurrentSeat = i;
        }
      }

      setState(prevState => ({
        ...prevState,
        selectedMergeSeatIds: [cartStore?.cart?.id],
        currentSeatNumber: foundCurrentSeat + 1,
      }));
    }
  }, [props.mergeSeatsVisible]);

  function onOk() {
    void props.onOk({ ...state });
    setState(prevState => ({ ...prevState, selectedMergeSeatIds: [] }));
  }

  function onCancel() {
    setState(prevState => ({ ...prevState, selectedMergeSeatIds: [] }));
    props.onCancel();
  }

  function handleSeatMergeCheckBoxChange(cartId: number) {
    const seats = [...state.selectedMergeSeatIds];
    const foundIndex = seats?.indexOf(cartId);

    if (foundIndex === -1) {
      seats.push(cartId);
    } else {
      seats.splice(foundIndex, 1);
    }

    setState(prevState => ({ ...prevState, selectedMergeSeatIds: seats }));
  }

  return (
    <Sheet
      title={`Merge seat #${state?.currentSeatNumber} with:`}
      open={props.mergeSeatsVisible}
      size="small"
      closable
      onCancel={onCancel}
      onOk={onOk}
      okText={t("components.merge_seats.merge_seats.002")}
      okDisabled={state.selectedMergeSeatIds?.length <= 1 ? true : false}
    >
      <div className="mt-2">
        <span>Select seat to merge with</span>
      </div>

      {cartStore?.activeTable?.carts?.length > 0 ? (
        <>
          {cartStore?.activeTable?.carts?.map((cart: ICart, cartIndex: number) => {
            if (
              cart.id !== cartStore.cart?.id &&
              cart?.status !== "complete" &&
              cart?.status !== "void" &&
              cart?.order?.financial_status !== "partially_paid"
            ) {
              return (
                <div key={cartIndex} onClick={() => handleSeatMergeCheckBoxChange(cart.id)} className="merge-seats">
                  <div className="flex items-center pointer-events-none">
                    <Checkbox
                      onChange={() => handleSeatMergeCheckBoxChange(cart.id)}
                      size="small"
                      checked={state.selectedMergeSeatIds.includes(cart.id)}
                    />
                    <span className="merge-seats_seat_name">
                      {t("components.merge_seats.merge_seats.003")}
                      {cartIndex + 1}
                    </span>
                  </div>
                  <div className="merge-seats_total_price">
                    <LocaleCurrency currency={cart?.currency} amount={cart.total_price} />
                  </div>
                </div>
              );
            }
          })}
        </>
      ) : (
        <div className="text-black">
          <Callout
            title={t("components.merge_seats.merge_seats.004")}
            content={t("components.merge_seats.merge_seats.005")}
            type="info"
          />
        </div>
      )}
    </Sheet>
  );
}

export default MergeSeats;
