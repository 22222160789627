import React, { ChangeEvent, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TFunction, useTranslation } from "react-i18next";

import Sheet from "components/sheet/Sheet";
import { ButtonNew } from "components/buttonNew";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import { Select } from "components/select/index";
import Portal from "elements/Portal";
import CheckboxBlock from "../elements/CheckboxBlock";
import Popup from "components/popup/Popup";

interface ICustomInputEdit {
  label: string;
  helpText?: string;
  required: boolean;
  saveInput: (label: string, value: string, helpText: string, required: boolean) => void;
  editModalOpen: boolean;
  closeEditModal: () => void;
  /** Viewable INPUT on the edit page itself. */
  children: React.ReactNode;
}

/** Structural display wrapper containing edit modal and its state. */
export default function CustomInputEdit(props: ICustomInputEdit) {
  const { label, helpText = "", required = false, editModalOpen, closeEditModal } = props;
  const { t } = useTranslation();

  const { Option } = Select;

  const [state, setState] = useState({
    label: label,
    helpText: helpText,
    required: required,
    displayLength: 25, // Not connected
    maxLength: undefined, // Not connected
  });

  return (
    <div className={`input-view-wrap${required ? " required" : ""}`}>
      {props.children}

      {/* EDITING  modal */}
      <Portal isMounted={editModalOpen}>
        <Sheet
          open={editModalOpen}
          title={t("secure.facility.settings.forms.custom_edits.custom_input_edit.003")}
          onOk={() => props.saveInput(state.label, null, state.helpText, state.required)}
          onCancel={() => {
            closeEditModal();
            setState(prev => ({
              ...prev,
              label: label,
              helpText: helpText,
              required: required,
            }));
          }}
          size="medium"
          okText={t("secure.facility.settings.forms.custom_edits.custom_input_edit.004")}
        >
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  label={t("secure.facility.settings.forms.custom_edits.custom_input_edit.005")}
                  defaultValue={label}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setState(prev => ({ ...prev, label: e.target.value }))
                  }
                  className="input-edit-action"
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Input
                  label={t("secure.facility.settings.forms.custom_edits.custom_input_edit.006")}
                  defaultValue={helpText}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setState(prev => ({ ...prev, helpText: e.target.value }))
                  }
                  className="input-edit-action"
                />
              </FormLayout.Group>
              <FormLayout.Group>
                {/* TODO: Param layout to be added in the future */}
                {/* <span style={{ display: "flex", gap: "16px" }}>
                  <Input label={"Display"} defaultValue={state.displayLength} disabled />
                  <Input label={"Max"} defaultValue={state.maxLength} disabled />
                </span>

                <Select
                  label="Validation"
                  defaultValue="empty"
                  onChange={(val: string) => console.log("attach validation ... ", val)}
                  disabled
                >
                  <Option value="empty">None</Option>
                  {validationOptions(t).map((val, index) => (
                    <Option key={val.id} value={val.id}>
                      {val.label}
                    </Option>
                  ))}
                </Select> */}

                <div />
                <CheckboxBlock
                  label={t("secure.facility.settings.forms.custom_edits.custom_input_edit.007")}
                  checked={state.required}
                  className="input-view-required"
                  onChange={() => setState(prev => ({ ...prev, required: !prev.required }))}
                />
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Sheet>
      </Portal>
    </div>
  );
}

const validationOptions = (t: TFunction<"translation", undefined>) => {
  return [
    {
      id: "text_only",
      label: t("secure.facility.settings.forms.custom_edits.custom_input_edit.010"),
    },
    {
      id: "email",
      label: t("secure.facility.settings.forms.custom_edits.custom_input_edit.011"),
    },
    {
      id: "number",
      label: t("secure.facility.settings.forms.custom_edits.custom_input_edit.012"),
    },
  ];
};
