import Orders from "containers/client/orders";
import SingleOrder from "containers/client/order";
import GiftCard from "./GiftCards";
import RainChecks from "./RainChecks";
import Refund from "./Refund";
import GiftCardEdit from "containers/client/giftCard";

const orderRoutes: { path: string; component: any }[] = [
  // Orders
  {
    path: "/admin/orderMenu",
    component: Orders,
  },
  {
    path: "/admin/order",
    component: Orders,
  },
  {
    path: "/admin/order/:orderId",
    component: SingleOrder,
  },
  {
    path: "/admin/order/:orderId/refund",
    component: Refund,
  },

  //Gift Cards

  {
    path: "/admin/giftcards",
    component: GiftCard,
  },
  {
    path: "/admin/giftcards/:giftCardId",
    component: GiftCardEdit,
  },

  //Rain Checks
  {
    path: "/admin/rainchecks",
    component: RainChecks,
  },
];

export default orderRoutes;
