// 'collection's of filter conditions
export type ComponentKey = "orders" | "orders_customer" | "products" | "memberships";

/** 
 * Order filter groups  
 * 
    // export type FilterKey = "date" | "financial_status" | "register_ids" | "user_ids" | "sales_channels" | "credit_card" | "payment_type" | "total_equal_to" | "total_greater_than" | "total_less_than";
*/
export type OrderFilterGrouping =
  | "start_date"
  | "end_date"
  | "financial_status"
  | "sales_channels"
  | "user_ids"
  | "total_equal_to"
  | "total_greater_than"
  | "total_less_than"
  | "credit_card"
  | "register_ids"
  | "payment_type";

/** 
 * membership filter groups  
 * 
    // export type FilterKey = "date" | "financial_status" | "register_ids" | "user_ids" | "sales_channels" | "credit_card" | "payment_type" | "total_equal_to" | "total_greater_than" | "total_less_than";
*/
export type MembershipFilterGrouping = "membership_type";

/**
 * Product filter groups
 */
export type ProductFilterGrouping = "";

export type FilterKey = OrderFilterGrouping | ProductFilterGrouping | MembershipFilterGrouping;

export enum FilterActionType {
  ADD = "ADD",
  REMOVE = "REMOVE",
  DEFAULTS = "DEFAULTS",
  CLEAR = "CLEAR",
  UPDATE_PM = "UPDATE_PM",
  UPDATE_CC = "UPDATE_CC",
}
