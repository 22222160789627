import React, { useEffect, useState } from "react";

import Card from "components/card/Card";

import "./slideshow.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ISlideshowProps {
  /**Content to display */
  content: JSX.Element;
  /**Information about the slides */
  slides: any[];
  /**Index of the content that's currently being displayed */
  selectedSlide: number;
  /**What should happen when the user clicks the right navigate button */
  onNavigateRight: () => void;
  /**What should happen when the user clicks the left navigate button */
  onNavigateLeft: () => void;
  /**Toggles whether the slideshow will navigate on it's own */
  autoPlay?: boolean;
  /**What should happen when the slideshow auto navigates REQUIRED IF autoPlay IS TRUE */
  onAutoPlay?: () => void;
  /**Time between auto navigation in milliseconds REQUIRED IF autoPlay IS TRUE */
  interval?: number;
}

export default function Slideshow(props: ISlideshowProps) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count !== 0) {
      props.onAutoPlay();
    }
    const timer = setTimeout(() => props.autoPlay && setCount(count + props.interval), props.interval);
    return () => clearTimeout(timer);
  }, [count, props.interval]);

  return (
    <>
      <Card>
        <div className="slideshow">
          <div className="slideshow-content-container">{props.content}</div>
          <div className="slideshow-left-navigation-conatainer" onClick={props.onNavigateLeft}>
            <div className="slideshow-left-navigation">
              <div className="left-navigation-icon">
                <FontAwesomeIcon icon={["fas", "arrow-left"]} size={"2x"} />
              </div>
            </div>
          </div>
          <div className="slideshow-right-navigation-conatainer" onClick={props.onNavigateRight}>
            <div className="slideshow-right-navigation">
              <div className="right-navigation-icon">
                <FontAwesomeIcon icon={["fas", "arrow-right"]} size={"2x"} />
              </div>
            </div>
          </div>
        </div>
      </Card>
      <div className="slideshow-dots">
        {props.slides?.map((slide: any, index: number) => (
          <FontAwesomeIcon key={index} icon={["fas", props.selectedSlide === index ? "circle-dot" : "circle"]} />
        ))}
      </div>
    </>
  );
}
