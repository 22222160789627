import React, { useEffect, useState } from "react";
import { useHistory, Link, BrowserRouter as Router, Route, Switch, Redirect, useLocation } from "react-router-dom";
import { useParams } from "react-router";

import Category from "elements/settings/Category";

import "./sideMenu.scss";
import "components/text/heading/Heading";
import { IconName } from "@fortawesome/fontawesome-svg-core";

export interface ISubOptions {
  label: string;
  url: string;
  icon?: IconName;
  subOptions?: ISubOptions[];
  secondOptionHeader?: boolean; // Styling for 3rd level menu
}

export interface IOptions {
  label: string;
  url: string;
  icon?: IconName;
  subOptions?: ISubOptions[];
}

export interface IRoutes {
  path: string;
  component?: any;
  filter?: string;
}

interface IProps {
  title: string;
  options: any;
  optionsRoutes: IRoutes[];
}

const SettingsComponent: React.FC<IProps> = (props: IProps) => {
  const [currentUrl, setCurrentUrl] = useState(useLocation().pathname);

  function updateCurrentRoute(url: string) {
    setCurrentUrl(url);
  }

  const renderSettingsInfo = () => {
    return (
      <Switch>
        {props.optionsRoutes?.map((route: IRoutes, index) => {
          // return <Route key={index} path={route.path} component={route.component} exact />;
          return <Route key={index} path={route.path} render={(props: any) => <route.component {...props} />} exact />;
        })}
      </Switch>
    );
  };
  return (
    <div className="ui-settings">
      <div className="ui-settings-menu-side-group">
        <div className="ui-settings-menu-top-bar">
          <h1 className="ui-settings-heading">{props.title}</h1>
        </div>
        <div className="ui-settings-menu-container">
          <div className="ui-settings-menu">
            {props.options?.map((option: IOptions, index: number) => {
              return (
                <Category
                  key={index}
                  label={option.label}
                  url={option.url}
                  setSelected={updateCurrentRoute}
                  showStyle={currentUrl === option.url ? true : false}
                  subOptions={option.subOptions}
                  currentUrl={currentUrl}
                  icon={option?.icon}
                />
              );
            })}
          </div>
        </div>
      </div>

      <div className="ui-settings-info">
        <div className="ui-settings-info-content">{renderSettingsInfo()}</div>
      </div>
    </div>
  );
};

export default SettingsComponent;
