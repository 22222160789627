import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Moment from "react-moment";
import moment from "moment";
import classNames from "classnames";
import { convertTime, delay, formatDate } from "helpers/Helpers";
import "components/bookingPopUp/auditTrailNote/AuditTrailNote.scss";
import Icon from "components/icon/Icon";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { divide } from "lodash";

/**
 * @param {string} title title of the action performed
 * @param {any} author user logged in when action happened
 * @param {any} actions more details about actions performed on the booking
 * @param {string} action action performed on the booking - create, modify, delete, notification
 * @param {string} timestamp time the action took place
 * @param {boolean} last check for last note in array of Audit Trail Notes
 */

export interface IAuditState {
  title: string;
  author: any;
  actions: any;
  action: string;
  timestamp: string;
  last: boolean;
}

function AuditTrailNote(props: IAuditState) {
  const [auditState, setAuditState] = useState<IAuditState>({
    title: props.title,
    author: props.author,
    actions: props.actions,
    action: props.action,
    timestamp: props.timestamp,
    last: props.last,
  });

  function updateAuditState(newTimerState: Partial<IAuditState>) {
    setAuditState(cur => {
      return { ...cur, ...newTimerState };
    });
  }

  let icon: IconName = null;

  if (
    auditState.action === "create" ||
    auditState.action === "create_booking" ||
    auditState.action === "move" ||
    auditState?.action === "move_booking" ||
    auditState.action === "create_tee_time"
  ) {
    icon = "check-circle";
  } else if (auditState?.action === "modify" || auditState.action === "modify_tee_time") {
    icon = "circle-exclamation";
  } else if (auditState?.action === "delete" || auditState.action === "delete_booking") {
    icon = "trash";
  } else if (auditState.action === "notification") {
    icon = "bell";
  }

  return (
    <div>
      <div className="bm-audit-trail-note-container">
        <div className="bm-audit-circle-line-container">
          <div
            className={classNames({
              "bm-audit-trail-circle-create":
                auditState.action === "create" ||
                auditState.action === "create_booking" ||
                auditState.action === "move" ||
                auditState?.action === "move_booking" ||
                auditState.action === "create_tee_time",
              "bm-audit-trail-circle-modify": auditState.action === "modify" || auditState.action === "modify_tee_time",
              "bm-audit-trail-circle-delete": auditState.action === "delete" || auditState.action === "delete_booking",
              "bm-audit-trail-circle-notification": auditState.action === "notification",
            })}
          >
            <FontAwesomeIcon
              icon={["far", icon]}
              className={classNames({
                "bm-audit-trail-circle-create_icon":
                  auditState.action === "create" ||
                  auditState.action === "create_booking" ||
                  auditState.action === "move" ||
                  auditState?.action === "move_booking" ||
                  auditState.action === "create_tee_time",
                "bm-audit-trail-circle-modify_icon":
                  auditState.action === "modify" || auditState.action === "modify_tee_time",
                "bm-audit-trail-circle-delete_icon":
                  auditState.action === "delete" || auditState.action === "delete_booking",
                "bm-audit-trail-circle-notification_icon": auditState.action === "notification",
              })}
            />
          </div>
        </div>

        <div className="bm-audit-note-action-container">
          <div className="flex flex-col">
            <p className="bm-audit-trail-action-title">{auditState.title}</p>

            {auditState.actions?.map((action: any, index: number) => {
              return (
                <React.Fragment key={index}>
                  <div>
                    <p className="bm-audit-trail-action-description">
                      <span>{action.description}</span>
                    </p>
                  </div>
                </React.Fragment>
              );
            })}
          </div>

          <p className="bm-audit-trail-action-date">
            <span>{moment(auditState.timestamp).format("LLL")}</span>
            <span>{auditState.author?.full_name}</span>
          </p>
        </div>
      </div>

      {!auditState.last ? <div className="bm-audit-trail-line"></div> : null}
    </div>
  );
}

export default AuditTrailNote;
