import { APIGet, IAPIResponse, StatusCode } from "api/protocols";
import { IFacilityTeeSheetSettings, IGetFacilityRes } from "../customer/facility";

const apiVersion = "2022-09";
const adminType = "guest";
const baseFacilityUrl = "/" + apiVersion + "/" + adminType + "/facility";

interface IGetFacility {
  id?: number;
  short_name?: string;
  client_id?: number;
  order_by?: string;
  search?: string;
  extended?: boolean;
}
interface IGetFacilityTeeSheetSettingsRes {
  status: StatusCode;
  data: IFacilityTeeSheetSettings;
}

export async function GetFacilityGuest(params: IGetFacility, useGlobalLoader: boolean): Promise<IGetFacilityRes> {
  return await APIGet(baseFacilityUrl, params, useGlobalLoader);
}

export async function GetGuestTeeSheetSettings(
  params: { facility_id: number },
  useGlobalLoader: boolean,
): Promise<IGetFacilityTeeSheetSettingsRes> {
  return await APIGet(baseFacilityUrl + "/settings/tee-sheet", params, useGlobalLoader);
}
