import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

type FolderItemProps = {
  active: boolean;
  onClick: () => void;
  icon: Pick<FontAwesomeIconProps, "icon">;
  title: string;
};

export default function FolderItem(props: FolderItemProps) {
  const [onHover, setOnHover] = useState<boolean>(false);
  const [scrollText, setScrollText] = useState<boolean>(false);

  const pRef = useRef<HTMLParagraphElement>(null);
  const liRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (pRef.current.offsetWidth > liRef.current.offsetWidth - 30 && onHover) {
      setScrollText(true);
    } else {
      setScrollText(false);
    }
  }, [onHover]);

  return (
    <li
      className={`ui-folder-menu-option ${props.active ? "ui-folder-menu-option-selected" : ""}`}
      onClick={props.onClick}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      title={props.title}
      aria-label={props.title}
      ref={liRef}
    >
      <span>
        <FontAwesomeIcon
          icon={props.icon.icon}
          fixedWidth
          style={{ backgroundColor: props.active || onHover ? "white" : "inherit" }}
        />
        <p ref={pRef} className={scrollText ? "scroll-text" : ""}>
          {props.title}
        </p>
      </span>
    </li>
  );
}
