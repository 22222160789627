import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";

const apiVersion = "2021-01";
const adminType = "facility-admin";
const baseTournamentUrl = "/" + apiVersion + "/" + adminType + "/tournament";

// GET Tournament
// Retrieve a list of tournaments
export async function GetTournament(params: string, useGlobalLoader: boolean) {
  return await APIGet(baseTournamentUrl + "?" + params, useGlobalLoader);
}

// POST Tournament
// Create a new tournament
export async function PostTournament(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseTournamentUrl, params, useGlobalLoader);
}

// PUT Tournament
// Update an existing tournament
export async function PutTournament(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseTournamentUrl, params, useGlobalLoader);
}
