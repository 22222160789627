import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import {
  GetTicketStub,
  GetTicketStubPdf,
  GetTickets,
  PutRedeem,
  PutTicketStub,
} from "api/rpc/2022-09/facilityAdmin/ticket/ticket";
import { showError, showSuccess } from "redux/actions/ui";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { capitalize, isEmpty } from "helpers/Helpers";
import { useCustomerContext } from "../../context/CustomerContext";

import { ButtonNew as Button } from "components/buttonNew";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../tabs/tickets/customerTickets.scss";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Badge } from "components/badge/Badge";
import moment from "moment";
import Callout from "components/callout/Callout";
import Popup from "components/popup/Popup";
import Spin from "components/spin/spin";
import Markdown from "components/markdown/Markdown";
import Card from "components/card/Card";
import { NavigationDropdownNew } from "components/navigationDropdownNew/NavigationDropdownNew";

interface ITicketStub {
  id: number;
  custmer_Id: number;
  code: string;
  expiry_date: string;
  last4: number;
  pin: number;
  redemption_product: string;
  source_url: string;
  terms: string;
  status: string;
}

interface ITicketsState {
  tickets: any[];
  openVoidTicket: boolean;
  openRedeemTicket: boolean;
  ticketToVoid: ITicketStub;
  ticketToRedeem: ITicketStub;
}

export default function CustomerTickets() {
  const { t } = useTranslation();

  const { state, updateState } = useCustomerContext();
  const dispatch = useAppDispatch();
  const authStore = useAppSelector(state => state.authStore);
  const permissions = authStore.user?.permissions;

  const chevronUp: IconProp = ["far", "chevron-up"];
  const chevronDown: IconProp = ["far", "chevron-down"];

  const [ticketsLoaded, setTicketsLoaded] = useState<boolean>(false);

  const [ticketsState, setTicketsState] = useState<ITicketsState>({
    tickets: null,
    openVoidTicket: false,
    openRedeemTicket: false,
    ticketToVoid: null,
    ticketToRedeem: null,
  });

  useEffect(() => {
    if (state.customer !== undefined) {
      void loadTickets();
    }
  }, [state.customer]);

  async function loadTickets() {
    const params = {
      customer_id: state.customer.id,
      extended: true,
    };

    const ticketsRes = await GetTickets(params, false);

    if (ticketsRes.status !== StatusCode.OK) {
      return;
    }

    const tickets = ticketsRes.data;

    {
      tickets?.map((ticket: any) => {
        ticket.stubs?.map((stub: any, stubIndex: number) => {
          if (stub.status === "valid") {
            const foundStub = ticket.stubs[stubIndex];
            ticket.stubs.splice(stubIndex, 1);
            ticket.stubs.unshift(foundStub);
          }
        });

        for (let i = 0; i < ticketsState.tickets?.length; i++) {
          if (ticket.id === ticketsState.tickets[i].id) {
            if (ticketsState.tickets[i].selected) {
              ticket.selected = true;
            } else {
              ticket.selected = false;
            }
          }
        }
      });
    }

    setTicketsState(prevState => ({
      ...prevState,
      tickets: tickets,
    }));

    setTicketsLoaded(true);
  }

  async function redeemTicketStub() {
    const putRedeemRes = await PutRedeem(ticketsState.ticketToRedeem.code, true);

    if (putRedeemRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.customer.tabs.tickets.customer_tickets.001")));
      setTicketsState(prevState => ({ ...prevState, ticketToRedeem: null, openRedeemTicket: false }));

      return;
    }
    setTicketsLoaded(false);
    await loadTickets();
    setTicketsState(prevState => ({ ...prevState, ticketToRedeem: null, openRedeemTicket: false }));
  }

  function handleOpenTicket(ticket: any) {
    const tickets = ticketsState.tickets;

    {
      tickets
        ?.filter((filteredTicket: any) => filteredTicket.id === ticket.id)
        .map((ticket: any) => {
          ticket.selected = !ticket.selected;
        });
    }

    setTicketsState(prevState => ({ ...prevState, tickets: tickets }));
  }

  async function voidTicketStub() {
    const params = {
      code: ticketsState.ticketToVoid.code,
      id: ticketsState.ticketToVoid.id,
      status: "void",
    };

    const voidTicketRes = await PutTicketStub(params, true);

    if (voidTicketRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.customer.tabs.tickets.customer_tickets.002")));
      setTicketsState(prevState => ({ ...prevState, openVoidTicket: false, ticketToVoid: null }));

      return;
    }

    setTicketsLoaded(false);
    await loadTickets();
    setTicketsState(prevState => ({ ...prevState, openVoidTicket: false, ticketToVoid: null }));
  }

  function handleVoidTicketStub(stub: any) {
    setTicketsState(prevState => ({ ...prevState, ticketToVoid: stub, openVoidTicket: true }));
  }

  function handleRedeemTicketStub(stub: any) {
    setTicketsState(prevState => ({ ...prevState, ticketToRedeem: stub, openRedeemTicket: true }));
  }

  async function handleTicketDownload(stub: any) {
    const ticketStubIds: any = [];
    ticketStubIds.push(stub.id);

    const params = {
      // order_id: stub.order_id,
      // order_line_item_id: stub.order_line_item_id,
      ticket_stub_ids: ticketStubIds,
    };

    const downloadReceiptRes = await GetTicketStubPdf(params, true);

    if (downloadReceiptRes.status !== StatusCode.OK) {
      dispatch(showError("Error downloading ticket stub"));

      return;
    }

    window.open(downloadReceiptRes.data, "_blank");

    return;
  }

  return ticketsLoaded ? (
    <>
      <div className="mt-2">
        <h1 className="ui-customer_page-header mb-4">
          {t("secure.facility.customer.tabs.tickets.customer_tickets.018")}
        </h1>

        <hr className="customer-ticket-header-divider" />

        {ticketsState.tickets.length > 0 ? (
          <div>
            {ticketsState.tickets?.map((ticket: any, index: number) => {
              let activeCount = 0;

              {
                ticket.stubs
                  ?.filter((filteredStub: any) => filteredStub.status === "valid")
                  .map((ticket: any) => {
                    activeCount = activeCount + 1;
                  });
              }

              return (
                <div key={index} className="customer-accordian-card-container">
                  <div className="customer-ticket-header-container" onClick={() => handleOpenTicket(ticket)}>
                    <div className="customer-ticket-title-container">
                      <div>
                        {!ticket.logo_image ? (
                          <FontAwesomeIcon className="customer-ticket-logo" icon={"ticket"} />
                        ) : (
                          <img className="customer-ticket-logo" src={ticket.logo_image} />
                        )}
                      </div>
                      <div>
                        <p className="customer-ticket-title">{ticket.title}</p>

                        <p className="customer-ticket-subtitle">
                          {ticket.stubs.length} {ticket.subtitle ?? ""}
                        </p>
                      </div>
                    </div>

                    <div className="customer-ticket-right-content-container">
                      <div className="customer-ticket-info">
                        <p className="ticket-info-text">
                          {t("secure.facility.customer.tabs.tickets.customer_tickets.019")}
                        </p>
                        <p className="ticket-info-date">{moment(ticket?.expiry_date).format("MMMM Do, YYYY")}</p>

                        <p className="ticket-info-text">
                          {" "}
                          {activeCount} / {ticket.stubs.length}
                        </p>
                      </div>
                      <div>
                        <FontAwesomeIcon className="chevron-icon" icon={ticket.selected ? chevronUp : chevronDown} />
                      </div>
                    </div>
                  </div>

                  {ticket.selected ? (
                    <div className="customer-ticket-container">
                      <div className="ticket-description-container">
                        <Markdown markdownText={ticket.description} />
                      </div>

                      {/* <div className="flex justify-end">
                      <div className="mr-3">
                        <FontAwesomeIcon className="terms-icon" icon={["far", "grid"]} />
                      </div>
  
                      <div>
                        <FontAwesomeIcon className="terms-icon" icon={["far", "list"]} />
                      </div>
                    </div> */}

                      <Card>
                        <Card.Section table="true">
                          <table className="ui-table">
                            <thead>
                              <tr>
                                <th>{t("secure.facility.customer.tabs.tickets.customer_tickets.020")}</th>
                                <th>{t("secure.facility.customer.tabs.tickets.customer_tickets.021")}</th>
                                <th></th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {ticket.stubs.map((stub: any, index: number) => {
                                let badgeStatus: "primary" | "success" | "warning" | "error" | "gray";

                                let isDateExpired = false;

                                if (stub.expiry_date) {
                                  const dateArray = stub.expiry_date.split("-");
                                  const date = new Date(dateArray[0], dateArray[1], dateArray[2]);
                                  isDateExpired = new Date(date.toDateString()) < new Date(new Date().toDateString());
                                }

                                switch (stub.status) {
                                  case "void":
                                    badgeStatus = "error";
                                    break;
                                  case "redeemed":
                                    badgeStatus = "gray";
                                    break;
                                  case "valid":
                                    badgeStatus = "success";
                                    break;

                                  default:
                                    break;
                                }
                                return (
                                  <tr key={index}>
                                    <td>
                                      <div>
                                        <p className="stub-number-text">{stub.code}</p>
                                        <p className="ticket-term">{stub.pin}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <Badge
                                        type={badgeStatus}
                                        size="small"
                                        iconLeft={
                                          stub.status === "valid" || stub.status === "void" ? (
                                            <FontAwesomeIcon
                                              className="customer-ticket-badge-icon"
                                              icon={["fas", "circle"]}
                                            />
                                          ) : (
                                            <></>
                                          )
                                        }
                                      >
                                        {capitalize(stub.status)}
                                      </Badge>
                                    </td>

                                    <td>
                                      {stub.redeemed_at
                                        ? "Redeemed on "
                                        : isDateExpired
                                        ? "Expired on "
                                        : stub.expiry_date
                                        ? "Expires on "
                                        : ""}

                                      {stub.redeemed_at
                                        ? moment(stub.redeemed_at).format("MMMM Do, YYYY")
                                        : stub.expiry_date
                                        ? moment(stub.expiry_date).format("MMMM Do, YYYY")
                                        : ""}
                                    </td>
                                    <td>
                                      {stub.status === "valid" ? (
                                        <NavigationDropdownNew
                                          rightAlign
                                          label={"Options"}
                                          sections={[
                                            [
                                              {
                                                label: "Void",
                                                icon: "ban",
                                                onClick: () => handleVoidTicketStub(stub),
                                              },
                                              {
                                                label: "Redeem",
                                                icon: "ticket",
                                                onClick: () => handleRedeemTicketStub(stub),
                                              },
                                              {
                                                label: "Download",
                                                icon: "download",
                                                onClick: () => handleTicketDownload(stub),
                                              },
                                            ],
                                          ]}
                                        />
                                      ) : null}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </Card.Section>
                      </Card>
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        ) : (
          <div>
            <Callout
              title={t("secure.facility.customer.tabs.tickets.customer_tickets.008")}
              content={t("secure.facility.customer.tabs.tickets.customer_tickets.009")}
              type="info"
            />
          </div>
        )}
      </div>

      <Popup
        open={ticketsState.openVoidTicket}
        type="warning"
        title={t("secure.facility.customer.tabs.tickets.customer_tickets.010")}
        description={t("secure.facility.customer.tabs.tickets.customer_tickets.011")}
        onOk={voidTicketStub}
        okText={t("secure.facility.customer.tabs.tickets.customer_tickets.012")}
        cancelText={t("secure.facility.customer.tabs.tickets.customer_tickets.013")}
        onCancel={() => setTicketsState(prevState => ({ ...prevState, openVoidTicket: false, ticketToVoid: null }))}
      ></Popup>
      <Popup
        open={ticketsState.openRedeemTicket}
        type="warning"
        title={t("secure.facility.customer.tabs.tickets.customer_tickets.014")}
        description={t("secure.facility.customer.tabs.tickets.customer_tickets.015")}
        onOk={redeemTicketStub}
        okText={t("secure.facility.customer.tabs.tickets.customer_tickets.016")}
        cancelText={t("secure.facility.customer.tabs.tickets.customer_tickets.017")}
        onCancel={() =>
          setTicketsState(prevState => ({ ...prevState, openRedeemTicket: false, ticketToRedeem: null }))
        }
      ></Popup>
    </>
  ) : (
    <div style={{ marginTop: "4em", pointerEvents: "none", textAlign: "center" }}>
      <span style={{ display: "inline-flex", transform: "scale(2)" }}>
        <Spin />
      </span>
    </div>
  );
}
