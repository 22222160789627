import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetDashboard } from "api/rpc/2024-04/facilityAdmin/client/admin/dashboard";

import { showError } from "redux/actions/ui";
import { TClientDashboard } from "redux/reducers/models/facility";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";

import "../facility/facility.scss";

export default function Dashboards() {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();
  const [dashboards, setDashboards] = useState<TClientDashboard[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadDashboards(source.token);
    return () => source.cancel();
  }, []);

  async function loadDashboards(token?: CancelToken) {
    if (dashboards !== undefined) {
      setDashboards(undefined);
    }
    const { status, data } = await GetDashboard(null, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (status !== StatusCode.OK) {
      dispatch(showError("Error loading dashboards."));
    }

    setDashboards(status !== StatusCode.OK ? [] : data);
  }

  const primaryAction = {
    content: t("secure.facility.settings.dashboards.dashboards.001"),
    action: () => history.push("/admin/settings/dashboards/new"),
  };

  const secondaryAction = {
    content: t("secure.facility.settings.dashboards.dashboards.002"),
    action: () => history.push("/admin/settings/tiles/new"),
  };

  return (
    <Page
      title={t("secure.facility.settings.dashboards.dashboards.003")}
      subtitle={t("secure.facility.settings.dashboards.dashboards.004")}
      narrow
      primaryAction={primaryAction}
      secondaryActions={[secondaryAction]}
    >
      <hr className="my-8" />
      <DataTable
        columns={[{ label: t("secure.facility.settings.dashboards.dashboards.005") }]}
        loading={dashboards === undefined}
      >
        {dashboards?.map(board => (
          <Fragment key={board.id}>
            <tr onClick={() => history.push("/admin/settings/dashboards/" + String(board?.id))} className="clickable">
              <td>{board?.title}</td>
            </tr>
            {board.tiles?.map(tile => (
              <tr
                key={tile.id}
                onClick={() => history.push("/admin/settings/tiles/" + String(tile?.id))}
                className="clickable"
              >
                <td style={{ textIndent: "1rem" }}>{tile?.title}</td>
              </tr>
            ))}
          </Fragment>
        ))}
      </DataTable>
    </Page>
  );
}
