import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Input from "components/form/input/Input";
import { StatusCode } from "api/protocols";
import { Select } from "components/select/index";
import { GetDepartments, PostDepartments } from "api/rpc/facility";

import { useTranslation } from "react-i18next";

interface IDepartmentState {
  title: string;
  type: string;
  parent_id: number;
}

export default function DepartmentsNew(props: any) {
  const { Option } = Select;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [departmentState, setDepartmentState] = useState<IDepartmentState>({
    title: "",
    type: "",
    parent_id: undefined,
  });
  const [allDepartState, setAllDepartState] = useState({ department: [] });

  useEffect(() => {
    void loadDepartments();

    return () => {
      setAllDepartState({ department: [] });
      setDepartmentState({ title: "", type: "", parent_id: undefined });
    };
  }, []);

  async function loadDepartments() {
    const res = await GetDepartments(true);
    if (res.status !== StatusCode.OK) {
      return;
    }
    console.log(res.data);

    setAllDepartState(prev => ({
      ...prev,
      department: res.data,
    }));
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setDepartmentState(prevState => ({ ...prevState, [id]: value }));
  }

  async function saveNewDepartment() {
    if (
      departmentState.parent_id === undefined ||
      (departmentState.parent_id === null && departmentState.type !== "department")
    ) {
      return;
    }

    const res = await PostDepartments(departmentState, true);
    if (res.status !== StatusCode.OK) {
      return;
    }

    console.log(res);
    history.push("/admin/settings/departments");
  }

  function handleDropDownChange(value: any, property: string) {
    setDepartmentState(prev => ({
      ...prev,
      parent_id: null,
      [property]: value,
    }));
  }

  const primaryAction = {
    content: t("secure.facility.settings.departments.departments_new.001"),
    action: saveNewDepartment,
  };

  return (
    <Page
      title={t("secure.facility.settings.departments.departments_new.002")}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.departments.departments_new.003"),
          url: "/admin/settings/departments",
        },
      ]}
    >
      <Card>
        <Card.Section>
          <Input
            value={departmentState.title}
            label={t("secure.facility.settings.departments.departments_new.004")}
            id="title"
            onChange={handleInputChange}
            placeholder={t("secure.facility.settings.departments.departments_new.005")}
          />
        </Card.Section>
        <Card.Section>
          <Select
            label={t("secure.facility.settings.departments.departments_new.006")}
            onChange={(value: any) => handleDropDownChange(value, "type")}
          >
            <Option value="department" name="department">
              {t("secure.facility.settings.departments.departments_new.007")}
            </Option>
            <Option value="category" name="category">
              {t("secure.facility.settings.departments.departments_new.008")}
            </Option>
            <Option value="subcategory" name="subcategory">
              {t("secure.facility.settings.departments.departments_new.009")}
            </Option>
          </Select>
        </Card.Section>
        {(departmentState.type === "category" || departmentState.type === "subcategory") && (
          <Card.Section>
            <Select
              label={t("secure.facility.settings.departments.departments_new.010")}
              onChange={(value: any) => handleDropDownChange(value, "parent_id")}
            >
              {allDepartState.department
                ?.filter((dept: any) => dept.type === "department")
                ?.map((department: any, index: any) => {
                  return departmentState.type === "category" ? (
                    <Option key={index} value={department.id} name={department.title}>
                      <span>{department.title}</span>
                    </Option>
                  ) : (
                    <React.Fragment key={index}>
                      <p style={{ padding: "8px 16px" }}>
                        <b>{department.title}</b>
                      </p>
                      {allDepartState.department
                        ?.filter((dept: any) => dept.parent_id === department.id)
                        .map((department: any, index: number) => {
                          return (
                            <Option key={index} value={department.id} name={department.title}>
                              <span>{department.title}</span>
                            </Option>
                          );
                        })}
                    </React.Fragment>
                  );
                })}
            </Select>
          </Card.Section>
        )}
      </Card>
    </Page>
  );
}
