import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { StatusCode } from "api/protocols";
import { useHistory } from "react-router-dom";

import {
  GetReservationLocation,
  PostReservationTemplate,
  GetReservationModuleAll,
} from "api/rpc/facilityAdmin/reservation/reservation";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import Toggle from "components/form/toggle/Toggle";
import TimePick from "components/timePick/TimePick";
import Checkbox from "components/form/checkbox/Checkbox";
import { Select } from "components/select/";
import { IModule, ILocation } from "redux/reducers/models/reservations";

interface INewTemplateState {
  name: string;
  start_time: string;
  end_time: string;
  interval: number;
  modules: Array<IModule>;
  locations: Array<ILocation>;
  selectedModuleId: number;
  selectedLocations: Array<number>;
  web_booking_enabled: boolean;
  credit_card_required: boolean;
}

export default function ReservationTemplateNew() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { Option } = Select;
  const [state, setState] = useState<INewTemplateState>({
    name: "",
    modules: [],
    locations: [],
    selectedModuleId: null,
    selectedLocations: [],
    start_time: "07:00",
    end_time: "19:30",
    interval: 30,
    web_booking_enabled: false,
    credit_card_required: false,
  });

  useEffect(() => {
    void loadModules();
    void loadLocations();
  }, []);

  useEffect(() => {
    console.log("Selected Module ID", state.selectedModuleId);
  }, [state.selectedModuleId]);

  async function loadModules() {
    const moduleRes = await GetReservationModuleAll({}, true);

    if (moduleRes.status !== StatusCode.OK) {
      return;
    }

    setState(prevState => ({ ...prevState, modules: moduleRes.data }));
  }

  async function loadLocations() {
    const locationRes = await GetReservationLocation({}, true);

    if (locationRes.status !== StatusCode.OK) {
      return;
    }

    setState(prevState => ({ ...prevState, locations: locationRes.data }));
  }

  async function postTemplate() {
    const templateRes = await PostReservationTemplate(
      {
        name: state.name,
        module_id: state.selectedModuleId,
        start_time: state.start_time,
        end_time: state.end_time,
        interval: state.interval,
        location_ids: state.selectedLocations,
        web_booking_enabled: state.web_booking_enabled,
        credit_card_required: state.credit_card_required,
      },
      true,
    );

    if (templateRes.status !== StatusCode.OK) {
      return;
    }

    history.push("/admin/settings/reservations/templates");
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleToggleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, checked } = event.target;
    setState(prevState => ({ ...prevState, [id]: checked }));
  }

  function onCheckboxChange(location_id: number) {
    const locations = [...state.selectedLocations];
    const foundIndex = locations?.indexOf(location_id);
    if (foundIndex === -1) {
      locations.push(location_id);
    } else {
      locations.splice(foundIndex, 1);
    }
    setState(prevState => ({ ...prevState, selectedLocations: locations }));
  }

  function handleDropDownChange(value: any, property: string) {
    setState(prevState => ({ ...prevState, [property]: value }));
  }

  const primaryAction = {
    content: "Create",
    action: postTemplate,
  };

  return (
    <Page
      narrow
      title="New Reservation Template"
      primaryAction={primaryAction}
      breadcrumbs={[{ prefix: true, label: "Back to Templates", url: "/admin/settings/reservations/templates" }]}
    >
      <Card>
        <Card.Section>
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input value={state.name ?? ""} label={"Name"} id="name" onChange={handleInputChange} />
                <Select
                  label={"Module"}
                  onChange={(value: any) => handleDropDownChange(value, "selectedModuleId")}
                  defaultValue={state.modules[0]?.id}
                >
                  {state.modules?.map((module: IModule, index: number) => {
                    return (
                      <Option key={index} value={module.id} name={module.title}>
                        {module.title}
                      </Option>
                    );
                  })}
                </Select>
              </FormLayout.Group>
              <FormLayout.Group>
                <TimePick
                  value={state.start_time}
                  onChange={timeString => setState(prevState => ({ ...prevState, start_time: timeString }))}
                  label={"Start Time"}
                  size="large"
                />
                <TimePick
                  value={state.end_time}
                  onChange={timeString => setState(prevState => ({ ...prevState, end_time: timeString }))}
                  label={"End Time"}
                  size="large"
                />
                <Input
                  value={state.interval ?? ""}
                  label={"Interval (Minutes)"}
                  id="interval"
                  onChange={handleInputChange}
                  type="number"
                  disabled
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <div>
                  <label>{"Locations"}</label>
                  <div className="flex justify-left gap-2">
                    {state?.locations
                      ?.filter((location: ILocation) => location.reservation_module_id === state.selectedModuleId)
                      .map((location: ILocation, index: number) => {
                        return (
                          <Checkbox
                            key={index}
                            label={location?.title}
                            size="medium"
                            onChange={() => onCheckboxChange(location?.id)}
                            checked={state?.selectedLocations?.includes(location?.id)}
                          />
                        );
                      })}
                  </div>
                </div>
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
      </Card>
    </Page>
  );
}
