import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Page from "components/page/Page";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Form from "components/form/Form";
import Input from "components/form/input/Input";
import { Select } from "components/select/index";
import { StatusCode } from "api/protocols";
import ColorPicker from "components/colorpicker/color-picker";
import { useTranslation } from "react-i18next";
import { PostTag } from "api/rpc/2024-04/facilityAdmin/client/tags";
import { useDispatch } from "react-redux";
import { showError } from "redux/actions/ui";
import { TTagType } from "redux/reducers/models/customer";

interface ICustomerTagState {
  label: string;
  description: string;
  colour: any;
}

export default function CustomerTagsNew(props: any) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { Option } = Select;
  const dispatch = useDispatch();

  const [customerTagState, setCustomerTagState] = useState<ICustomerTagState>({
    label: "",
    description: "",
    colour: "",
  });

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setCustomerTagState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleDropDownChange(value: any, property: string) {
    setCustomerTagState(prevState => ({ ...prevState, [property]: value }));
  }

  async function saveNewCustomerTag() {
    const params = {
      type: "customer" as TTagType,
      description: customerTagState.description,
      colour: customerTagState.colour,
      label: customerTagState.label,
    };

    const tagRes = await PostTag(params, true);

    if (tagRes.status !== StatusCode.OK) {
      dispatch(showError("Error adding note to tournament"));
      return;
    }

    history.push("/admin/settings/customer_tags");
  }

  function handleColorPick(codeColors: string) {
    setCustomerTagState(prevState => ({ ...prevState, colour: codeColors }));
  }

  const primaryAction = {
    content: "Create Tag",
    action: saveNewCustomerTag,
  };

  return (
    <Page
      title={"New Customer Tag"}
      narrow
      breadcrumbs={[
        {
          prefix: true,
          label: "Customer Tags",
          url: "/admin/settings/customer_tags",
        },
      ]}
      primaryAction={primaryAction}
    >
      <Card>
        <Card.Section>
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={customerTagState.label}
                  label={"Label"}
                  id="label"
                  onChange={handleInputChange}
                  //   placeholder={}
                />
                <Input
                  value={customerTagState.description}
                  label={"Description"}
                  id="description"
                  onChange={handleInputChange}
                  //   placeholder={}
                />
              </FormLayout.Group>

              <FormLayout.Group>
                <div className="mt-2">
                  <p>Colour</p>
                  <ColorPicker colorValue={customerTagState.colour} onClick={handleColorPick} />
                </div>
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
      </Card>
    </Page>
  );
}
