import { ILeagueRegistrationInput } from "api/rpc/2024-04/facilityAdmin/league/registration/inputs";
import { useDropEditInputRow } from "hooks/editInputDragAndDrop/useDropEditInputRow/useDropEditInputRow";
import React, { useEffect, useRef } from "react";
import { useLeagueRegistrationContext } from "../LeagueRegistrationContext";
import "./leagueRegistrationEditInputRow.scss";
import LeagueRegistrationEditInput from "./LeagueRegistrationEditInput";

interface IProps {
  inputRow: string;
  inputs: ILeagueRegistrationInput[];
}

export default function LeagueRegistrationEditInputRow(props: IProps) {
  const { inputRow, inputs } = props;

  const leagueRegistrationContext = useLeagueRegistrationContext();

  const rowRef = useRef<HTMLDivElement>(null);

  const inputContainerRefs = useRef<HTMLDivElement[]>([]);

  const { drop, canDrop, isOver, customDragLayer } = useDropEditInputRow(
    inputs,
    rowRef,
    leagueRegistrationContext.inputRowsContainer.updatedState,
    inputRow,
    inputContainerRefs,
    leagueRegistrationContext.inputRowsContainerActions.update,
    [
      { types: ["input", "select"], maxSize: 3 },
      { types: ["checkbox"], maxSize: 3 },
      { types: ["text"], maxSize: 1 },
    ],
  );

  useEffect(() => {
    if (inputs) {
      const numInputs = inputs.length;

      inputContainerRefs.current = inputContainerRefs.current.slice(0, numInputs);
    }
  }, [inputs]);

  return (
    <div
      ref={el => {
        drop(el);
        rowRef.current = el;
      }}
      className="league-registration-edit-input-row"
      style={{
        outline: isOver && !canDrop ? "1px solid red" : "none",
      }}
    >
      {inputs.map((input, index) => {
        return (
          <div
            ref={el => (inputContainerRefs.current[index] = el)}
            key={String(input.id)}
            className="league-registration-edit-input-container"
          >
            <LeagueRegistrationEditInput input={input} />
          </div>
        );
      })}

      {customDragLayer}
    </div>
  );
}
