import React, { useEffect, useState, useCallback, ChangeEvent } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  roundNumber,
  displayPercent,
  capitalize,
  convertTime,
  customerErrorMessage,
  valueToString,
} from "helpers/Helpers";
import Moment from "react-moment";
import moment from "moment";

import {
  GetOrder,
  PutVoidOrder,
  PutReinstateOrder,
  PutOrder,
  GetReceipt,
  EmailReceipt,
} from "api/rpc/2024-04/facilityAdmin/order/order";
import { GetGiftCard, PrintGiftCard, IGiftCard } from "api/rpc/2024-04/facilityAdmin/payment/giftCard";
import { GetFormResponse, FormResponseInput } from "api/rpc/2022-09/facilityAdmin/facility/form/formResponse";

import { GetTicketStubPdf } from "api/rpc/2022-09/facilityAdmin/ticket/ticket";
import { StatusCode } from "api/protocols";

import { IOrder, IOrderLineItem, IOrderTransaction } from "redux/reducers/models/order";

import "pages/secure/facility/order/order.scss";
import Page from "components/page/Page";
import Card from "components/card/Card";
import { Badge } from "components/badge/Badge";
import Input from "components/form/input/Input";
import { LocaleCurrency } from "helpers/Locale";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Sheet from "components/sheet/Sheet";
import FormLayout from "components/form/FormLayout";
import { ButtonNew as Button } from "components/buttonNew/index";
import GolferCard from "components/bookingPopUp/golferCard/GolferCard";
import { Select } from "components/select/index";
import { ICustomer } from "redux/reducers/models/customer";
import { GetCustomer } from "api/rpc/facilityAdmin/customer";
import { PostCustomer } from "api/rpc/customer";
//import order from "containers/facility/order";
import { IUIActions, showError } from "redux/actions/ui";
import { GetRegisters } from "api/rpc/facilityAdmin/facility/register/register";
import { IUser } from "redux/reducers/models/user";
import { IRegister } from "../Admin";
import ReactDOM from "react-dom";
import { GetFacilityAdmin } from "api/rpc/facilityAdmin/client/admin";
import { IAuthState } from "redux/reducers/auth";
import { useTranslation } from "react-i18next";
import NewCustomer, { ICustomerInfoState } from "components/newCustomer/NewCustomer";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { PutTransaction } from "api/rpc/2022-09/facilityAdmin/order/transaction";
import TextField from "components/form/textField/TextField";
import classNames from "classnames";
import Popup from "components/popup/Popup";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import Checkbox from "components/form/checkbox/Checkbox";
import { IForm } from "api/rpc/2022-09/guest/form";
import TimePick from "components/timePick/TimePick";
import DataTable from "../customer/tabs/houseAccounts/DataTable";
import Avatar from "components/avatar/Avatar";
import Icon from "components/icon/Icon";

interface IOrderProps {
  uiActions: IUIActions;
  authStore: IAuthState;
}

interface IOrderState {
  order: IOrder;
  line_items: IOrderLineItem[];
  transactions: IOrderTransaction;
  notes: string;
  uiActions: IUIActions;
}

interface IGiftCardState {
  giftCard: IGiftCard[];
}

interface IChangeCustomerState {
  showChangeCustomer: boolean;
  showNewCustomer: boolean;
  customerQuery: string;
  customerSearching: boolean;
  customerSearchResult: Array<ICustomer>;
  selectedCustomer: ICustomer;
  currentCustomer: any;
}

interface IEditDetailsState {
  showEditDetails: boolean;
  selectedRegisterId: number;
  selectedUserId: number;
}

interface IEmailReceiptState {
  email: string;
  showEmailReceipt: boolean;
}

interface IBackdateOrderState {
  selectedDate: Date;
  backdate_time: string;
}

interface ITransactionEditState {
  transactionDate: Date;
  transaction_time: string;
  openTransactionEdit?: boolean;
  transaction: IOrderTransaction;
  transaction_note: string;
}

interface IFormResponseState {
  id: number;
  form_id: number;
  form: IForm;
  customer_id: number;
  order_id: number;
  cart_id: number;
  response_inputs: Array<FormResponseInput>;
  formattedResponseInputs: IFormattedInputs;
}

interface IFormResponseModalState {
  open: boolean;
  selectedForm: IFormResponseState;
}

interface IFormattedInputs {
  [key: string]: Array<FormResponseInput>;
}

export default function Single(props: IOrderProps) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { orderId } = useParams<{ orderId: string }>();
  const { facilityStore } = useAppSelector(store => store);

  const dispatch = useAppDispatch();
  const [orderState, setOrderState] = useState<IOrderState>({
    order: null,
    line_items: [],
    transactions: null,
    notes: "",
    uiActions: null,
  });

  const [giftCardState, setGiftCardState] = useState<IGiftCardState>({
    giftCard: null,
  });

  const [editDetailsState, setEditDetailsState] = useState<IEditDetailsState>({
    showEditDetails: false,

    selectedRegisterId: null,
    selectedUserId: null,
  });

  const [emailReceiptState, setEmailReceiptState] = useState<IEmailReceiptState>({
    email: "",
    showEmailReceipt: false,
  });

  const { Option } = Select;

  const { uiActions, authStore } = props;

  const [changePlayerState, setChangePlayerState] = useState<IChangeCustomerState>({
    showChangeCustomer: false,
    showNewCustomer: false,
    customerQuery: "",
    customerSearching: false,
    customerSearchResult: [],
    selectedCustomer: null,
    currentCustomer: null,
  });

  const [backdateOrderState, setBackdateOrderState] = useState<IBackdateOrderState>({
    selectedDate: new Date(),
    backdate_time: "12:00:00",
  });

  const [transactionState, setTransactionState] = useState<ITransactionEditState>({
    openTransactionEdit: false,
    transactionDate: new Date(),
    transaction_time: "12:00",
    transaction: null,
    transaction_note: "",
  });

  const [formResponseState, setFormResponseState] = useState<Array<IFormResponseState>>([]);
  const [formResponseModalState, setFormResponesModalState] = useState<IFormResponseModalState>({
    open: false,
    selectedForm: undefined,
  });

  const [voidPopupVisible, setVoidPopupVisible] = useState(false);
  const [giftCardPopupVisible, setGiftCardPopupVisible] = useState(false);
  const [giftCardPresent, setGiftCardPresent] = useState(false);
  const [reinstatePopupVisible, setReinstatePopupVisible] = useState(false);

  useEffect(() => {
    void loadOrder();
    void loadFormResponses();
  }, []);

  async function loadFormResponses() {
    const formResponseRes = await GetFormResponse({ order_id: Number(orderId) }, true);
    if (formResponseRes.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.order.order.055"));
      return;
    }

    if (formResponseRes?.data?.length > 0) {
      const formResponses: Array<IFormResponseState> = [];
      formResponseRes.data?.forEach(response => {
        const formattedResponseInputs = response?.response_inputs?.reduce(
          (formattedInputs: IFormattedInputs, responseInput) => {
            return {
              ...formattedInputs,
              [responseInput.type]: formattedInputs[responseInput.type]
                ? [...formattedInputs[responseInput.type], responseInput]
                : [responseInput],
            };
          },
          {},
        );
        const updatedFormResponse = { ...response, formattedResponseInputs: formattedResponseInputs };
        formResponses.push(updatedFormResponse);
      });
      setFormResponseState(formResponses);
    }
  }

  async function loadGiftCards(order_id: number) {
    const giftCardRes = await GetGiftCard({ order_id: order_id }, true);
    if (giftCardRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading gift card data"));
    }

    setGiftCardState({ giftCard: giftCardRes.data });
  }

  async function loadRegisters() {
    const registerRes = await GetRegisters(null, true);
    if (registerRes.status !== StatusCode.OK) {
      uiActions.showError(registerRes.message);
      return;
    }
    setEditDetailsState(prevState => ({ ...prevState, registers: registerRes.data }));
  }

  async function loadOrder() {
    const orderRes = await GetOrder({ id: Number(orderId), extended: true }, true);
    if (orderRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading order data"));
      return;
    }

    const order = orderRes.data[0];

    const lineItems = order.line_items.filter((lineItem: { product_type: string }) => {
      return lineItem.product_type !== "Modifier";
    });
    const modifiers = order.line_items.filter((lineItem: { product_type: string }) => {
      return lineItem.product_type === "Modifier";
    });
    const giftCards = order.line_items.filter((lineItem: { product_type: string }) => {
      return lineItem.product_type === "Gift Card";
    });

    for (let i = 0; i < lineItems?.length; i++) {
      const lineItemModifiers = [];

      for (let k = 0; k < modifiers.length; k++) {
        if (lineItems[i].id === modifiers[k].parent_id) {
          lineItemModifiers.push(modifiers[k]);
        }
      }

      lineItems[i].modifiers = lineItemModifiers.length !== 0 ? lineItemModifiers : null;
    }

    const orderDate = order.processed_at
      ? moment.utc(order.processed_at).local().toDate()
      : backdateOrderState.selectedDate;
    const backdateTime = order.processed_at
      ? moment.utc(order.processed_at).local().format("HH:mm")
      : backdateOrderState.backdate_time;

    ReactDOM.unstable_batchedUpdates(() => {
      setOrderState(prevState => ({
        ...prevState,
        order: order,
        line_items: lineItems,
        transactions: order.transactions,
        notes: order.notes,
      }));
      setEditDetailsState(prevState => ({
        ...prevState,
        selectedRegisterId: order.register_id,
        selectedUserId: order.user_id,
      }));
    });

    setBackdateOrderState(prevState => ({
      ...prevState,
      backdate_time: backdateTime,
      selectedDate: orderDate,
    }));

    if (order.customer?.email) {
      setEmailReceiptState(prevState => ({ ...prevState, email: order.customer?.email }));
    }

    if (giftCards.length >= 1) {
      void loadGiftCards(order.id);
      setGiftCardPresent(true);
    }
  }

  async function updateOrder() {
    const orderRes = await PutOrder(
      {
        id: orderState.order?.id,
        customer_id: changePlayerState.selectedCustomer
          ? changePlayerState.selectedCustomer.id
          : orderState.order?.customer_id,
        notes: orderState.notes,
      },
      true,
    );

    if (orderRes.status !== StatusCode.OK) {
      uiActions?.showError(orderRes.message);
      return;
    }

    uiActions?.showSuccess(orderRes.message);

    setChangePlayerState(prevState => ({
      ...prevState,
      currentCustomer: null,
      showChangeCustomer: false,
      customerSearchResult: [],
      customerQuery: "",
      selectedCustomer: null,
    }));

    void loadOrder();
  }

  async function handleVoidOrder() {
    const putVoidOrder = await PutVoidOrder({ order_id: orderState.order?.id }, true);

    if (putVoidOrder.status !== StatusCode.OK) {
      props.uiActions.showError(putVoidOrder.message);
    } else {
      props.uiActions.showSuccess(t("secure.facility.order.order.056"));
      history.push("/admin/order/");
    }

    setVoidPopupVisible(false);
  }

  async function handlePrintReceipt() {
    if (orderState.order?.financial_status === "voided") {
      return;
    }

    const receiptRes = await GetReceipt({ id: Number(orderId) }, true);

    if (receiptRes.status === StatusCode.OK) {
      window.open().document.write(receiptRes.data);
    } else {
      props.uiActions.showError(receiptRes.message);
    }
  }

  function handleOrderRefund() {
    if (orderState.order?.financial_status === "voided") {
      return;
    }

    history.push("/admin/order/" + String(orderId) + "/refund");
  }

  function editCustomerDetails() {
    //history.push("/admin/customer/" + String(customerState.id) + "/edit");
  }

  async function createNewCustomer(customerInfo: ICustomerInfoState) {
    const inputError =
      customerInfo.firstName === "" ||
      customerInfo.lastName === "" ||
      (customerInfo.emailAddress === "" && customerInfo.phoneNumber === "");

    if (inputError) {
      //uiActions.showError("Please fill all fields");
    }

    const customerRes = await PostCustomer(
      {
        first_name: customerInfo.firstName,
        last_name: customerInfo.lastName,
        phone: customerInfo.phoneNumber || null,
        email: customerInfo.emailAddress || null,
        state: "disabled",
      },
      true,
    );

    if (customerRes.status !== StatusCode.OK) {
      dispatch(showError(customerErrorMessage(t, customerRes?.data?.message)));
      return;
    }
    setChangePlayerState(prevState => ({
      ...prevState,
      showNewCustomer: false,
      selectedCustomer: customerRes.data.data,
    }));
    //uiActions.showSuccess(customerRes.data.message);
  }

  function editCustomerAddress() {}

  function closeChangePlayer() {
    closeCreateNewCustomer();
    setChangePlayerState(prevState => ({
      ...prevState,
      showNewCustomer: false,
      currentCustomer: null,
      showChangeCustomer: false,
      customerQuery: "",
      customerSearchResult: [],
      selectedCustomer: null,
    }));
  }

  function closeCreateNewCustomer() {
    setChangePlayerState(prevState => ({
      ...prevState,
      showNewCustomer: false,
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
    }));
  }

  async function changeCustomer() {
    const orderCustomerRes = await PutOrder(
      { id: orderState.order?.id, customer_id: changePlayerState.selectedCustomer.id },
      true,
    );

    if (orderCustomerRes.status !== StatusCode.OK) {
      uiActions.showError(orderCustomerRes.message);
      return;
    }

    //uiActions.showSuccess(orderCustomerRes.message);

    setChangePlayerState(prevState => ({
      ...prevState,
      currentCustomer: null,
      showChangeCustomer: false,
      customerSearchResult: [],
      customerQuery: "",
      selectedCustomer: null,
    }));

    void loadOrder();
  }

  function changePlayerHandleCustomerSearch(query: string) {
    setChangePlayerState(prevState => ({ ...prevState, customerQuery: query }));
  }

  function handleChangePlayerSelection(id: number, customer: ICustomer) {
    setChangePlayerState(prevState => ({ ...prevState, selectedCustomer: customer }));
  }

  function createNewPlayerInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setChangePlayerState(prevState => ({ ...prevState, [id]: value }));
  }

  function emailReceiptEmailChange(e: any) {
    const { value } = e.target;

    setEmailReceiptState(prevState => ({ ...prevState, email: value }));
  }

  function handleShowCustomerChange(customer: any) {
    if (orderState.order?.financial_status === "voided") {
      return;
    }

    setChangePlayerState(prevState => ({
      ...prevState,
      showChangeCustomer: true,
      showNewCustomer: false,
      customerQuery: "",
      customerSearchResult: [],
      currentCustomer: customer,
    }));
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setOrderState(prevState => ({ ...prevState, [id]: value }));
  }

  const primaryAction = {
    content: t("secure.facility.order.order.001"),
    action: updateOrder,
  };

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = global.setTimeout(() => {
        void changePlayerSearch(mounted, changePlayerState.customerQuery);
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      setChangePlayerState(prevState => ({ ...prevState, playerSearchResult: [] }));
    };
  }, [changePlayerState.customerQuery]);

  async function changePlayerSearch(mounted: boolean, customerSearchQuery: string) {
    try {
      if (customerSearchQuery === "") {
        if (mounted) {
          setChangePlayerState(prevState => ({ ...prevState, customerSearchResult: [] }));
        }
        return;
      } else {
        setChangePlayerState(prevState => ({ ...prevState, customerSearching: true }));
        const customerRes = await GetCustomer({ search: customerSearchQuery }, false);
        if (customerRes.status !== StatusCode.OK) {
          setChangePlayerState(prevState => ({ ...prevState, customerSearching: false, customerSearchResult: [] }));
          return;
        } else if (mounted) {
          setChangePlayerState(prevState => ({
            ...prevState,
            customerSearching: false,
            customerSearchResult: customerRes.data,
          }));

          setEmailReceiptState(prevState => ({ ...prevState, email: customerRes?.data[0]?.email }));
        }
      }
    } catch (error) {
      console.log("err", error);
    }
    return;
  }

  function openEditDetailsSheet() {
    setEditDetailsState(prevState => ({ ...prevState, showEditDetails: true }));
  }

  function openEmailReceiptSheet() {
    setEmailReceiptState(prevState => ({ ...prevState, showEmailReceipt: true }));
  }

  function handleEditDetailsChange(value: number, id: string) {
    setEditDetailsState(prevState => ({ ...prevState, [id]: value }));
  }

  async function handleUpdateEditDetails() {
    const dateTime =
      moment.utc(backdateOrderState.selectedDate).local().format("YYYY-MM-DD") + " " + backdateOrderState.backdate_time;

    const utcDate = moment(dateTime, "YYYY-MM-DD HH:mm:ss").utc().format("YYYY-MM-DD HH:mm:ss");

    const orderRes = await PutOrder(
      {
        id: orderState.order?.id,
        register_id: editDetailsState.selectedRegisterId,
        user_id: editDetailsState.selectedUserId,
        processed_at: utcDate,
      },
      true,
    );

    if (orderRes.status !== StatusCode.OK) {
      uiActions.showError(orderRes.message);
      return;
    }

    setEditDetailsState(prevState => ({ ...prevState, showEditDetails: false }));
    uiActions.showSuccess(t("secure.facility.order.order.002"));
    await loadOrder();
  }

  async function handleEmailReceipt() {
    //email receipt

    const params = {
      order_id: orderState.order?.id,
      customer_email: emailReceiptState.email,
    };

    const emailReceiptRes = await EmailReceipt(params, true);
    if (emailReceiptRes?.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.order.order.003"));
      return;
    }
    uiActions.showSuccess(t("secure.facility.order.order.004"));
    setEmailReceiptState(prevState => ({ ...prevState, showEmailReceipt: false }));
  }

  async function handleTicketDownload(lineItemId: number) {
    const params = {
      order_id: orderState.order?.id,
      order_line_item_id: lineItemId,
    };

    const emailReceiptRes = await GetTicketStubPdf(params, true);

    const url = emailReceiptRes as any;

    window.open(emailReceiptRes.data, "_blank");

    uiActions.showSuccess(t("secure.facility.order.order.005"));

    return;
  }

  function openNewCustomerSheet() {
    setChangePlayerState(prevState => ({
      ...prevState,
      showNewCustomer: true,
    }));
  }

  const handleDatePickerChange = (selectedDate: Date) => {
    setBackdateOrderState(prevState => ({ ...prevState, selectedDate: selectedDate }));
  };

  function backdateOrderChange(id: keyof IBackdateOrderState, value: string) {
    setBackdateOrderState(prev => ({ ...prev, [id]: String(value) }));
  }

  function backdateTimeChange(event: any) {
    const { id, value } = event.target;
    setBackdateOrderState(prevState => ({
      ...prevState,
      [id]: String(value),
    }));
  }

  function orderProcessInfo() {
    let info = " by ";
    if (orderState.order?.sales_channel === "online_store") {
      info = info + orderState.order?.customer?.full_name + " from Online Store";
    } else if (orderState?.order?.sales_channel === "booking_engine") {
      info = info + orderState.order?.customer?.full_name + " from Booking Engine";
    } else {
      info = info + orderState.order?.admin_user?.full_name + " on " + orderState.order?.register?.title;
    }

    return info;
  }

  function handleOpenTransaction(transaction: IOrderTransaction) {
    setTransactionState(prevState => ({
      ...prevState,
      openTransactionEdit: true,
      transactionDate: new Date(moment.utc(transaction?.processed_at).local().format("MMMM DD, YYYY")),
      transaction_time: moment.utc(transaction?.processed_at).local().format("HH:mm"),
      transaction: transaction,
      transaction_note: transaction.note,
    }));
  }

  async function handleTransactionEdit() {
    const dateTime =
      moment.utc(transactionState.transactionDate).local().format("YYYY-MM-DD") +
      " " +
      transactionState.transaction_time;

    const processed_at = moment(dateTime, "YYYY-MM-DD HH:mm:ss").utc();
    const processedAtString = processed_at.format("YYYY-MM-DD HH:mm:ss");

    if (processed_at.isAfter(moment.utc())) {
      uiActions.showError("Cannot select a date in the future");
      return;
    }

    const params = {
      id: transactionState.transaction.id,
      processed_at: processedAtString,
      note: transactionState.transaction_note,
    };

    const updateTransactionRes = await PutTransaction(params, true);

    if (updateTransactionRes.status !== StatusCode.OK) {
      return;
    }

    setTransactionState(prevState => ({ ...prevState, openTransactionEdit: false }));
    void loadOrder();
  }

  function handleTransactionDatePickerChange(selectedDate: Date) {
    setTransactionState(prevState => ({ ...prevState, transactionDate: selectedDate }));
  }

  function handleTransactionChange(id: string, value: string) {
    setTransactionState(prevState => ({ ...prevState, [id]: value }));
  }

  // function handleTransactionTimeChange(event: any) {
  //   const { id, value } = event.target;
  //   setTransactionState(prevState => ({ ...prevState, [id]: value }));
  // }

  function setRegisterCart() {
    if (orderState?.order?.cart_token) {
      //Send cart token to register page to continue order
      history.push("/admin/register", {
        params: { cartToken: orderState?.order?.cart_token, orderId: orderState?.order?.id },
      });
    }
  }

  async function handleReinstateOrder() {
    const orderRes = await PutReinstateOrder({ order_id: orderState.order.id }, true);

    if (orderRes.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.order.order.057"));
      return;
    }

    props.uiActions.showSuccess(t("secure.facility.order.order.058"));
    history.push("/admin/order/");
  }

  function navigateToGiftCard(code: string) {
    history.push("/admin/giftcards/" + code);
  }

  async function navigateToPrint(event: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>, giftCard: IGiftCard) {
    event.stopPropagation();
    const printGiftCardRes = await PrintGiftCard({ id: giftCard.id, last4: giftCard.last4 }, true);

    if (printGiftCardRes.status === StatusCode.OK) {
      window.open().document.write(printGiftCardRes.data);
    } else {
      props.uiActions.showError(printGiftCardRes.message);
    }
  }

  function navigateToProfile() {}
  return (
    <>
      <Page
        narrow
        splitLayout
        title={t("secure.facility.order.order.006") + orderState.order?.name}
        titleMetadata={
          <Badge
            type={
              orderState.order?.financial_status === "unpaid"
                ? "gray"
                : orderState.order?.financial_status === "paid"
                ? "success"
                : orderState.order?.financial_status === "partially_paid"
                ? "warning"
                : "gray"
            }
            size="small"
            iconLeft
            icon="circle"
            iconStyle="fas"
          >
            {valueToString(orderState.order?.financial_status)}
          </Badge>
        }
        primaryAction={primaryAction}
        subtitle={
          orderState?.order?.processed_at ? (
            <>
              <span>{moment.utc(orderState.order?.processed_at).local().format("MMMM DD, YYYY")} </span>
              <span> at </span>
              <span>{moment.utc(orderState.order?.processed_at).local().format("h:mm A")}</span>
              <span>{orderProcessInfo()}</span>
            </>
          ) : null
        }
        breadcrumbsNavigateParams={{ orderId: orderId }}
        breadcrumbs={[{ prefix: true, label: t("secure.facility.order.order.007"), url: "/admin/order" }]}
        subActions={[
          { label: t("secure.facility.order.order.008"), icon: null, type: "tertiary", onClick: handlePrintReceipt },
          { label: t("secure.facility.order.order.009"), icon: null, type: "tertiary", onClick: handleOrderRefund },
          {
            label: t("secure.facility.order.order.010"),
            type: "dropdown",
            options:
              orderState.order?.financial_status === "voided"
                ? [
                    { label: t("secure.facility.order.order.011"), onClick: openEditDetailsSheet },
                    { label: t("secure.facility.order.order.012"), onClick: openEmailReceiptSheet },
                    { label: t("secure.facility.order.order.067"), onClick: () => setReinstatePopupVisible(true) },
                  ]
                : [
                    { label: t("secure.facility.order.order.013"), onClick: () => setVoidPopupVisible(true) },
                    { label: t("secure.facility.order.order.014"), onClick: openEditDetailsSheet },
                    { label: t("secure.facility.order.order.015"), onClick: openEmailReceiptSheet },
                    ...(giftCardPresent === true
                      ? [{ label: "Gift Card Details", onClick: () => setGiftCardPopupVisible(true) }]
                      : []),
                  ],
          },
          ...(orderState?.order?.financial_status === "unpaid" ||
          orderState?.order?.financial_status === "partially_paid"
            ? [{ label: "Resume Order", icon: null, type: "tertiary", onClick: setRegisterCart }]
            : []),
        ]}
      >
        <Page.Section twoThirds>
          <Card title={t("secure.facility.order.order.016")}>
            <Card.Section>
              {orderState.order?.line_items &&
                orderState.line_items?.map((item: any, index: number) => {
                  return (
                    <Card.SubSection key={index}>
                      <div className="order-line-item">
                        <div>
                          <p className="text-lg text-semibold">{item.product_title}</p>
                          {item.product_title !== item.variant_title ? (
                            <p className="text-sm text-gray-500">{item.variant_title}</p>
                          ) : null}
                          {item.product_type === "Ticket" ? (
                            <Button type="link" onClick={() => handleTicketDownload(item.id)}>
                              {t("secure.facility.order.order.017")}
                            </Button>
                          ) : null}
                        </div>
                        <div className="text-right">
                          <p className="text-lg text-gray-500">
                            {item.quantity} x{" "}
                            <LocaleCurrency currency={orderState.order?.currency} amount={item.price} />
                          </p>
                        </div>
                        <div className="text-right">
                          <p className="text-lg text-semibold">
                            <LocaleCurrency currency={orderState.order?.currency} amount={item.price * item.quantity} />
                          </p>
                        </div>
                      </div>

                      {item.modifiers ? (
                        <div>
                          {item.modifiers.map((modifier: any, i: number) => {
                            return (
                              <div key={i}>
                                <div className="order-line-item">
                                  <div style={{ color: "#475467", marginLeft: "15px" }}>{modifier.product_title}</div>
                                  <div className="text-right">
                                    <p className="text-lg text-gray-500">
                                      {modifier.quantity} x{" "}
                                      <LocaleCurrency currency={orderState.order?.currency} amount={modifier.price} />
                                    </p>
                                  </div>
                                  <div className="text-right">
                                    <p className="text-lg text-semibold">
                                      <LocaleCurrency
                                        currency={orderState.order?.currency}
                                        amount={modifier.price * modifier.quantity}
                                      />
                                    </p>
                                  </div>
                                </div>

                                {modifier.total_discount !== 0 ? (
                                  <div>
                                    {orderState.order.discount_lines.map((discount: any, i: number) => {
                                      if (discount.order_line_item_id === modifier.id) {
                                        return (
                                          <div className="text-right">
                                            <p className="text-sm text-gray-500">
                                              {discount.title} -{" "}
                                              <LocaleCurrency
                                                currency={orderState.order?.currency}
                                                amount={discount.amount}
                                              />
                                            </p>
                                          </div>
                                        );
                                      }
                                    })}
                                  </div>
                                ) : null}
                              </div>
                            );
                          })}
                        </div>
                      ) : null}

                      {orderState.order.discount_lines.map((discount: any, i: number) => {
                        if (discount.order_line_item_id === item.id) {
                          return (
                            <div key={i} className="text-right">
                              <p className="text-lg text-gray-500">
                                {discount.title} -{" "}
                                <LocaleCurrency currency={orderState.order?.currency} amount={discount.amount} />
                              </p>
                            </div>
                          );
                        }
                      })}

                      {item.total_discount !== 0 && (
                        <div className="text-right">
                          <p className="text-lg text-semibold">
                            <LocaleCurrency currency={orderState.order?.currency} amount={item.subtotal_price} />
                          </p>
                        </div>
                      )}
                    </Card.SubSection>
                  );
                })}
            </Card.Section>
            <Card.Section>
              <div className="order-totals-container">
                <div className="order-totals">
                  <span className="text-lg text-semibold">{t("secure.facility.order.order.018")}</span>
                  <p className="text-lg text-semibold">
                    <LocaleCurrency currency="cad" amount={orderState.order?.subtotal_price} />
                  </p>

                  {orderState.order?.tax_lines?.map((tax_line: Record<string, any>, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <span className="text-lg text-semibold">
                          {tax_line.title}{" "}
                          <span className="text-lg text-gray-500">{displayPercent(tax_line.rate)}</span>
                        </span>
                        <p className="text-lg text-semibold">
                          <LocaleCurrency currency={orderState.order?.currency} amount={tax_line.price} />
                        </p>
                      </React.Fragment>
                    );
                  })}

                  {orderState.order?.total_tip > 0 ? (
                    <>
                      <span className="text-lg text-semibold">{t("secure.facility.order.order.019")}</span>
                      <p className="text-lg text-semibold">
                        <LocaleCurrency currency={orderState.order?.currency} amount={orderState.order?.total_tip} />
                      </p>
                    </>
                  ) : null}

                  <span className="text-lg text-semibold">{t("secure.facility.order.order.020")}</span>
                  <p className="text-lg text-semibold">
                    <LocaleCurrency currency={orderState.order?.currency} amount={orderState.order?.total_price} />
                  </p>

                  {orderState.order?.transactions?.map((transaction: IOrderTransaction, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <div className="flex justify-end">
                          <div
                            className="flex gap-3 items-center cursor-pointer"
                            onClick={() => handleOpenTransaction(transaction)}
                          >
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="leftMenuToolTip">
                                  {moment.utc(transaction.processed_at).local().format("MMMM DD, YYYY") +
                                    " at " +
                                    moment.utc(transaction.processed_at).local().format("h:mm A")}
                                </Tooltip>
                              }
                            >
                              <span className="order-totals-transaction-method text-lg text-semibold">
                                {valueToString(
                                  transaction.payment_method === "card"
                                    ? transaction.payment_type
                                    : transaction.payment_method,
                                )}
                              </span>
                            </OverlayTrigger>
                            {transaction.payment_last_4 ? (
                              <span className="text-lg text-gray-500">•••• {transaction.payment_last_4}</span>
                            ) : null}
                          </div>
                        </div>
                        <span className="text-lg text-semibold">
                          {transaction?.kind === "refund" ? <span>- </span> : null}
                          <LocaleCurrency currency={orderState.order?.currency} amount={transaction.amount} />
                        </span>
                        {transaction.note && (
                          <div className="text-sm text-subdued col-span-2 -mt-2">{transaction.note}</div>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </Card.Section>
          </Card>
          <Card title={t("secure.facility.order.order.021")}>
            <Card.Section>
              <Input
                id="notes"
                value={orderState.notes || ""}
                onChange={handleInputChange}
                placeholder={t("secure.facility.order.order.022")}
              />
            </Card.Section>
          </Card>

          {orderState.order?.customer_notes && (
            <Card title={t("secure.facility.order.order.054")}>
              <Card.Section>
                <div className="text-md">{orderState.order.customer_notes}</div>
              </Card.Section>
            </Card>
          )}
        </Page.Section>
        <Page.Section oneThird>
          <Card
            title={t("secure.facility.order.order.023")}
            titleActions={[
              {
                action: () => handleShowCustomerChange(orderState.order?.customer),
                content: t("secure.facility.order.order.024"),
              },
            ]}
          >
            <Card.Section>
              <div>
                {orderState.order?.customer ? (
                  <div>
                    <Avatar size="xxlarge" source={orderState?.order?.customer?.profile_image_source} />
                    <p className="text-lg">{orderState.order?.customer?.full_name}</p>
                    <p className="text-lg">{orderState.order?.customer?.email}</p>
                    <p className="text-lg">{orderState.order?.customer?.phone}</p>
                    <p className="text-lg">{orderState.order?.customer?.customer_type}</p>
                    <div
                      className="cursor-pointer customer-profile"
                      onClick={() =>
                        history.push("/admin/customers/" + String(orderState.order?.customer?.id) + "/profile")
                      }
                    >
                      View Profile{" "}
                      <FontAwesomeIcon
                        style={{ marginLeft: "5px" }}
                        icon={["fas", "arrow-up-right-from-square"]}
                        size="1x"
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <p>{t("secure.facility.order.order.025")}</p>
                  </div>
                )}
              </div>
            </Card.Section>
          </Card>
          {formResponseState?.length > 0 && (
            <Card title={t("secure.facility.order.order.059")}>
              <Card.Section>
                {formResponseState?.map((response, responseIndex) => {
                  return (
                    <div key={responseIndex} className="order-forms-container">
                      <p className="form-title">
                        {response?.form?.title ?? `${t("secure.facility.order.order.061")} ${responseIndex + 1}`}
                      </p>
                      <p
                        className="view-button"
                        onClick={() =>
                          setFormResponesModalState(prevState => ({
                            ...prevState,
                            open: true,
                            selectedForm: response,
                          }))
                        }
                      >
                        {t("secure.facility.order.order.059")}
                      </p>
                    </div>
                  );
                })}
              </Card.Section>
            </Card>
          )}
        </Page.Section>
      </Page>

      <Sheet
        title={
          changePlayerState.showNewCustomer
            ? t("secure.facility.order.order.026")
            : t("secure.facility.order.order.027")
        }
        open={changePlayerState.showChangeCustomer}
        size="small"
        closable
        onCancel={closeChangePlayer}
        onOk={changeCustomer}
        okText={t("secure.facility.order.order.028")}
        okDisabled={changePlayerState.selectedCustomer ? false : true}
        overflow
      >
        {changePlayerState.showNewCustomer ? (
          <NewCustomer
            newCustomerSheetActive={changePlayerState.showNewCustomer}
            onCancel={closeCreateNewCustomer}
            onOk={createNewCustomer}
            searchValue={changePlayerState.customerQuery}
          />
        ) : (
          <>
            {changePlayerState.currentCustomer !== null ? (
              <div>
                <span>{t("secure.facility.order.order.035")}</span>

                <GolferCard
                  email={changePlayerState.currentCustomer?.email}
                  name={changePlayerState.currentCustomer?.full_name}
                  memberCode={changePlayerState.currentCustomer?.member_code}
                  customerType={changePlayerState.currentCustomer?.customer_type}
                  phone={changePlayerState.currentCustomer?.phone}
                />

                <FontAwesomeIcon
                  className="ml-auto mr-auto mt-4 mb-2 block text-primary-ui-colour"
                  icon={["far", "arrow-circle-down"]}
                  size="2x"
                />
              </div>
            ) : null}

            <span>{t("secure.facility.order.order.036")}</span>
            {changePlayerState.selectedCustomer ? (
              <GolferCard
                closable
                removeGolfer={() =>
                  setChangePlayerState(prevState => ({
                    ...prevState,
                    selectedCustomer: null,
                    playerSearchResult: [],
                    customerQuery: "",
                  }))
                }
                email={changePlayerState.selectedCustomer.email}
                name={changePlayerState.selectedCustomer.full_name}
                memberCode={changePlayerState.selectedCustomer.member_code}
                customerType={changePlayerState.selectedCustomer.customer_type}
                phone={changePlayerState.selectedCustomer.phone}
              />
            ) : (
              <Select
                showSearch
                className={`flex justify-center align-center w-full h-10 position-relative z-20 text-black font-medium appearance-none border-none focus:outline-none placeholder-gray-200`}
                onSearch={(query: string) => changePlayerHandleCustomerSearch(query)}
                onChange={(id: number, customer: ICustomer) => handleChangePlayerSelection(id, customer)}
                placeholder={t("secure.facility.order.order.037")}
                allowClear
                searchValue={changePlayerState.customerQuery}
                showDropDownOnFocus={true}
                searching={changePlayerState.customerSearching}
              >
                <div
                  className="ui-select-dropdown-list-item"
                  onClick={
                    () => openNewCustomerSheet()
                    // setChangePlayerState(prevState => ({
                    //   ...prevState,
                    //   showNewCustomer: true,
                    // }))
                  }
                >
                  <p>{t("secure.facility.order.order.038")}</p>
                </div>
                {changePlayerState.customerSearchResult?.map((customer, index) => {
                  return (
                    <Option key={index} value={customer.id} name={customer.full_name} extraValues={customer}>
                      <div className="flex justify-between">
                        <div>
                          <div className="text-semibold text-lg">{customer?.full_name}</div>
                          <div className="text-sm text-gray-500">{customer.customer_type}</div>
                          <div className="text-sm text-gray-500">{customer.email}</div>
                          <div className="text-sm text-gray-500">{customer.phone ? customer.phone : null}</div>
                        </div>

                        <div className="font-medium text-base text-gray-500 self-end">{customer.member_code}</div>
                      </div>
                    </Option>
                  );
                })}
              </Select>
            )}
          </>
        )}
      </Sheet>

      <Sheet
        title={t("secure.facility.order.order.039")}
        open={editDetailsState.showEditDetails}
        size="medium"
        closable
        onCancel={() =>
          setEditDetailsState(prevState => ({
            ...prevState,
            showEditDetails: false,
            selectedRegisterId: orderState.order?.register_id,
            selectedUserId: orderState.order?.user_id,
          }))
        }
        overflow
        onOk={handleUpdateEditDetails}
        okText={t("secure.facility.order.order.040")}
      >
        <FormLayout>
          <FormLayout.Group>
            <Select
              label={t("secure.facility.order.order.041")}
              onChange={(value: number) => handleEditDetailsChange(value, "selectedRegisterId")}
              defaultValue={editDetailsState.selectedRegisterId ?? null}
              disabled={authStore?.user?.permissions?.orders_edit_register ? false : true}
            >
              {facilityStore?.registers?.map((register, registerIndex) => (
                <Option key={registerIndex} value={register.id} name={register.title}>
                  {register.title}
                </Option>
              ))}
            </Select>

            <>
              <Select
                label={t("secure.facility.order.order.042")}
                onChange={(value: number) => handleEditDetailsChange(value, "selectedUserId")}
                defaultValue={editDetailsState.selectedUserId ?? null}
                disabled={authStore?.user?.permissions?.orders_edit_user ? false : true}
              >
                {facilityStore?.staffAccounts?.map((user, userIndex) => (
                  <Option key={userIndex} value={user.id} name={user.full_name}>
                    {user.full_name}
                  </Option>
                ))}
              </Select>
            </>
          </FormLayout.Group>

          <div className="mt-4">
            <FormLayout.Group>
              <div className="orders-date-picker">
                <p className="date-label">{t("secure.facility.order.order.048")}</p>
                <DatePickerInput
                  months={1}
                  position="left"
                  startingDate={
                    authStore?.user?.permissions?.orders_backdate ? backdateOrderState.selectedDate : undefined
                  }
                  setStartingDate={handleDatePickerChange}
                />

                {!authStore?.user?.permissions?.orders_backdate ? t("secure.facility.order.order.049") : null}
              </div>

              <TimePick
                label={t("secure.facility.order.order.050")}
                align="right"
                value={backdateOrderState.backdate_time}
                onChange={timeString => backdateOrderChange("backdate_time", timeString)}
                disabled={!authStore?.user?.permissions?.orders_backdate}
                size="large"
              />
            </FormLayout.Group>
          </div>
        </FormLayout>
      </Sheet>

      <Sheet
        title={t("secure.facility.order.order.043")}
        open={emailReceiptState.showEmailReceipt}
        size="small"
        closable
        onCancel={() =>
          setEmailReceiptState(prevState => ({
            ...prevState,
            showEmailReceipt: false,
          }))
        }
        onOk={handleEmailReceipt}
        okText={t("secure.facility.order.order.044")}
        okDisabled={emailReceiptState?.email === "" ? true : false}
      >
        <>
          <FormLayout>
            <FormLayout.Group>
              <Input
                label={t("secure.facility.order.order.045")}
                placeholder={t("secure.facility.order.order.046")}
                id="email_receipt"
                value={emailReceiptState.email}
                onChange={emailReceiptEmailChange}
              />
            </FormLayout.Group>
          </FormLayout>
        </>
      </Sheet>

      <Sheet
        title={t("secure.facility.order.order.051")}
        open={transactionState.openTransactionEdit}
        size="small"
        closable
        onCancel={() =>
          setTransactionState(prevState => ({
            ...prevState,
            openTransactionEdit: false,
          }))
        }
        overflow
        onOk={handleTransactionEdit}
        okText={t("secure.facility.order.order.052")}
        // okDisabled={emailReceiptState?.email === "" ? true : false}
      >
        {authStore?.user?.permissions?.orders_backdate ? (
          <>
            <div>
              <p className="date-label">{t("secure.facility.order.order.053")}</p>
              <DatePickerInput
                months={1}
                position="left"
                startingDate={transactionState.transactionDate}
                setStartingDate={handleTransactionDatePickerChange}
              />

              {/* {!authStore?.user?.permissions?.orders_backdate ? "Need Order Backdate Permission" : null} */}
            </div>

            <div className="mt-4">
              <TimePick
                value={transactionState.transaction_time}
                onChange={(timeString?: string) => handleTransactionChange("transaction_time", timeString)}
                //disabled={!authStore?.user?.permissions?.orders_backdate}
              />
            </div>
          </>
        ) : null}

        <div
          style={{
            marginTop: authStore?.user?.permissions?.orders_backdate && "16px",
          }}
        >
          <TextField
            label={"Add Note"}
            value={transactionState.transaction_note ?? ""}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              handleTransactionChange("transaction_note", e.target.value)
            }
            id="transaction_note"
          />
        </div>
      </Sheet>

      <Sheet
        title={formResponseModalState?.selectedForm?.form?.title ?? t("secure.facility.order.order.062")}
        open={formResponseModalState.open}
        size="small"
        closable
        onCancel={() =>
          setFormResponesModalState(prevState => ({ ...prevState, open: false, selectedForm: undefined }))
        }
        cancelText={t("secure.facility.order.order.061")}
      >
        {formResponseModalState?.selectedForm &&
          Object.keys(formResponseModalState?.selectedForm?.formattedResponseInputs)?.map(key => {
            return (
              <div
                key={key}
                className={classNames("order-response-inputs-container", {
                  "order-response-inputs-checkbox-container": key === "checkbox",
                })}
              >
                {formResponseModalState?.selectedForm?.formattedResponseInputs[key]?.map(
                  (responseInput, inputIndex) => {
                    if (key === "input") {
                      return (
                        <Input key={inputIndex} value={responseInput.value} readOnly label={responseInput.label} />
                      );
                    } else if (key === "select") {
                      return (
                        <Select key={inputIndex} label={responseInput.label} defaultValue={responseInput.value}>
                          <Option value={responseInput.value}>{responseInput.value}</Option>
                        </Select>
                      );
                    } else if (key === "checkbox") {
                      return (
                        <Checkbox
                          key={inputIndex}
                          size="small"
                          label={responseInput.label}
                          checked={!!responseInput.value}
                          readOnly
                        />
                      );
                    }
                  },
                )}
              </div>
            );
          })}
      </Sheet>

      <Sheet
        title="Order Gift Cards"
        open={giftCardPopupVisible}
        closable
        size="small"
        onCancel={() => setGiftCardPopupVisible(false)}
        cancelText="Cancel"
      >
        <DataTable columns={[{ label: "Gift Card Code" }, { label: "Balance" }, { label: "" }]}>
          {giftCardState?.giftCard?.map(giftCard => (
            <tr
              key={giftCard.id}
              id={String(giftCard.id)}
              className="clickable"
              onClick={() => navigateToGiftCard(giftCard.code)}
            >
              <td>{giftCard.code}</td>
              <td>${giftCard.balance}</td>
              <td onClick={e => navigateToPrint(e, giftCard)}>Print</td>
            </tr>
          ))}
        </DataTable>
      </Sheet>

      <Popup
        open={voidPopupVisible}
        type={"warning"}
        title={t("secure.facility.order.order.063")}
        description={t("secure.facility.order.order.064")}
        okText={t("secure.facility.order.order.065")}
        cancelText={t("secure.facility.order.order.066")}
        backDropCancel
        onOk={() => handleVoidOrder()}
        onCancel={() => setVoidPopupVisible(false)}
      />

      <Popup
        open={reinstatePopupVisible}
        type={"warning"}
        title={t("secure.facility.order.order.067")}
        description={t("secure.facility.order.order.068")}
        okText={t("secure.facility.order.order.069")}
        cancelText={t("secure.facility.order.order.070")}
        backDropCancel
        onOk={() => handleReinstateOrder()}
        onCancel={() => setReinstatePopupVisible(false)}
      />
    </>
  );
}
