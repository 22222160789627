import { APIGet, APIPost, APIPut, APIDelete, StatusCode } from "api/protocols";
import { CancelToken } from "axios";
import { weekdayNames } from "helpers/Helpers";
import { ILeague } from "redux/reducers/models/league";
import { IProduct, IVariant } from "redux/reducers/models/product";
import { TWeekDays, WEEK_DAYS } from "components/form/calendarField/helpers";

const apiVersion = "2024-04";
const adminType = "client-admin";
const baseLeagueUrl = "/" + apiVersion + "/" + adminType + "/league";

interface ILeagueGet {
  id?: number | string;
  ids?: number[] | string[];
  search?: string;
  year?: number;
  logo_source?: string;
}

export interface ILeaguePost {
  name?: string;
  week_day?: TWeekDays;
  start_time?: string;
}

export interface ILeaguePut {
  id: number | string;
  name?: string;
  week_day?: TWeekDays;
  start_time?: string;
  description?: string;
  capacity?: number;
  registration_open?: boolean;
}

interface IGetLeagueResponse {
  data: ILeague[];
  status: number;
  message: string;
}

//League Logo
export async function UploadLeagueLogo(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseLeagueUrl + "/logo", params, useGlobalLoader);
}

export async function DeleteLeagueLogo(params: any, useGlobalLoader: boolean) {
  return await APIDelete(baseLeagueUrl + "/logo", params, useGlobalLoader);
}

// GET League
// Retrieve a list of leagues
export async function GetLeague(params: Partial<ILeagueGet>, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseLeagueUrl, params, useGlobalLoader, token);
}

// POST League
// Create a new league
export async function PostLeague(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseLeagueUrl, params, useGlobalLoader);
}

// Update Player
//Update player within a league
export async function PutParticipant(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseLeagueUrl + "/participant", params, useGlobalLoader);
}

type DeleteLeagueParticipant = {
  participant_id: number;
};

// Delete Player
//Delete player within a league
export async function DeleteLeagueParticipant(params: DeleteLeagueParticipant, useGlobalLoader: boolean) {
  return await APIDelete(baseLeagueUrl + "/participant", params, useGlobalLoader);
}
