import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { IFacility } from "redux/reducers/models/facility";

const apiVersion = "2024-04";
const adminType = "master-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/customer/type";

interface IGetCustomerType {
  id?: number;
  client_id?: number;
}

interface IPutCustomerType {
  id: number;
  client_id: number;
  title: string;
  application: string;
  subtitle: string;
  color: string;
}

interface IPostCustomerType {
  client_id: number;
  title: string;
  application: string;
  subtitle: string;
  default_booking_category_id: number;
}

interface IDeleteCustomerType {
  id?: number;
}

// GET Customer Types
export async function GetCustomerTypes(params: IGetCustomerType, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseUrl, params, useGlobalLoader, token);
}

// PUT Customer Type
export async function PutCustomerType(params: IPutCustomerType, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

// POST Customer Type
export async function PostCustomerType(params: IPostCustomerType, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

// Delete Customer Type
export async function DeleteCustomerType(params: IDeleteCustomerType, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}
