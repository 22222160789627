import React from "react";

import { getCreditCardIcon, valueToString } from "helpers/Helpers";

import Checkbox from "components/form/checkbox/Checkbox";
import Icon from "components/icon/Icon";
import { ICreditCard } from "pages/guest/Customer/CustomerProfile";
import { useTranslation, Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type CreditCardTab = {
  card: ICreditCard;
  isSelected: boolean;
  setSelectedCard: (paymentMethodId: number) => void;
};

export default function CreditCardTab(props: CreditCardTab) {
  const { card, isSelected } = props;
  const { t, i18n } = useTranslation();
  function handleCreditCardSelect(
    event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLDivElement, MouseEvent>,
    paymentId: number,
  ) {
    event.preventDefault();
    props.setSelectedCard(paymentId);
  }

  return (
    <div
      onClick={event => handleCreditCardSelect(event, card.id)}
      className={`ui-checkbox-group-item
              ${isSelected && "ui-checkbox-group-item_selected"}
            `}
    >
      <div className="ui-checkbox-group-item-content">
        <div className="ui-checkbox-group-item-lead">
          <FontAwesomeIcon size="2x" icon={getCreditCardIcon(card.brand)} />
        </div>
        <div className="ui-checkbox-group-item-text">
          <p className="text-sm text-medium text-gray-700">
            {valueToString(card.brand, "capitalize")} {t("elements.tee_sheet.credit_card.credit_card_tab.001")}{" "}
            {card.last4}
          </p>
          <p className="text-sm text-regular text-gray-600">
            {t("elements.tee_sheet.credit_card.credit_card_tab.002")} {card.expiry}
          </p>
        </div>
      </div>
      <Checkbox
        size="medium"
        value={card.id}
        checked={isSelected}
        onChange={event => handleCreditCardSelect(event, card.id)}
      />
    </div>
  );
}
