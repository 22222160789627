import { APIGet, IAPIResponse } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseRoomUrl = "/" + apiVersion + "/" + adminType + "/room";

export interface IOccupant {
  reservation_number: string;
  room_number: string;
  res_status: number;
  guest_name: string;
  credit_limit: number;
}

interface IGetRoomOccupants {
  search: string;
}

interface IGetRoomOccupantsResponse extends IAPIResponse {
  data: IOccupant[];
}

export async function GetRoomOccupants(
  params: IGetRoomOccupants,
  useGlobalLoader: boolean,
  cancelToken: CancelToken,
): Promise<IGetRoomOccupantsResponse> {
  return await APIGet(baseRoomUrl + "/occupant", params, useGlobalLoader, cancelToken);
}
