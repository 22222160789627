import { Dictionary, cloneDeep, inRange } from "lodash";
import React from "react";
import { ConnectDropTarget, XYCoord, useDrop } from "react-dnd";
import EditInputLayoutCustomDragLayer from "./EditInputLayoutCustomDragLayer";

export const EditInputDragItemTypes = {
  INPUT: "INPUT",
};

export interface IEditInputDropItem {
  id: number;
  type: string;
  row: number;
  column: number;
}

export type EditInputRows<Input extends IEditInputDropItem> = Dictionary<Input[]>;

interface IUseDropEditInputLayoutState {
  drop: ConnectDropTarget;
  customDragLayer: JSX.Element;
}

export interface IEditInputLayoutRowRange {
  startY: number;
  endY: number;
}

export function useDropEditInputLayout<Input extends IEditInputDropItem>(
  layoutRef: React.MutableRefObject<HTMLDivElement>,
  layoutRowContainerRefs: React.MutableRefObject<HTMLDivElement[]>,
  updatePositions: (editInputLayoutRows: EditInputRows<Input>) => void,
  rows: EditInputRows<Input>,
): IUseDropEditInputLayoutState {
  const [{ canDrop }, drop] = useDrop(
    () => ({
      accept: EditInputDragItemTypes.INPUT,
      canDrop: (item, monitor) => {
        return monitor.isOver({ shallow: true });
      },
      drop: (item: Input, monitor) => {
        const layoutBoundingRectangle = layoutRef.current.getBoundingClientRect();

        const layoutCoordinate: XYCoord = {
          x: layoutBoundingRectangle.x,
          y: layoutBoundingRectangle.y,
        };

        const dragCoordinate: XYCoord = monitor.getClientOffset();

        const relativeDragCoordinate: XYCoord = {
          x: dragCoordinate.x - layoutCoordinate.x,
          y: dragCoordinate.y - layoutCoordinate.y,
        };

        const [rowDropIndex, rowRanges] = getLayoutRowDropIndexInRange(layoutCoordinate, relativeDragCoordinate);

        if (rowDropIndex === undefined) {
          return;
        }

        const inputToDrag = cloneDeep(rows?.[item.row]?.find(input => input.id === item.id));

        if (!inputToDrag) {
          return;
        }

        const updatedRowsWithInsert: EditInputRows<Input> = {};

        let createdRow: Input[] = undefined;

        Object.keys(rows).map((row, index) => {
          if (index === rowDropIndex) {
            const currentRow = index + 1;
            const nextRow = index + 2;

            updatedRowsWithInsert[String(currentRow)] = [{ ...inputToDrag, row: currentRow, column: 1 }];
            createdRow = updatedRowsWithInsert[String(currentRow)];

            updatedRowsWithInsert[String(nextRow)] = cloneDeep(rows[row]).map((input, index) => ({
              ...input,
              row: nextRow,
              column: index + 1,
            }));
          } else {
            const insertRow = index > rowDropIndex ? index + 2 : index + 1;

            updatedRowsWithInsert[String(insertRow)] = cloneDeep(rows[row]).map((input, index) => ({
              ...input,
              row: insertRow,
              column: index + 1,
            }));
          }
        });

        const numInputRowsAfterInsert = Object.keys(rows).length;

        if (rowDropIndex >= numInputRowsAfterInsert) {
          const insertRow = numInputRowsAfterInsert + 1;
          updatedRowsWithInsert[String(insertRow)] = [{ ...inputToDrag, row: insertRow, column: 1 }];
          createdRow = updatedRowsWithInsert[String(insertRow)];
        }

        const updatedInputRowsWithDelete: EditInputRows<Input> = {};
        let currentRowWhenUpdatingWithDelete = 1;

        Object.keys(updatedRowsWithInsert).forEach(row => {
          const currentRowCopy = cloneDeep(updatedRowsWithInsert[row]);

          const containsDragItem = currentRowCopy.some(input => input.id === inputToDrag.id);

          if (containsDragItem && updatedRowsWithInsert[row] !== createdRow) {
            if (currentRowCopy.length > 1) {
              updatedInputRowsWithDelete[String(currentRowWhenUpdatingWithDelete)] = currentRowCopy
                .filter(input => input.id !== inputToDrag.id)
                .map((input, index) => ({
                  ...input,
                  row: currentRowWhenUpdatingWithDelete,
                  column: index + 1,
                }));

              currentRowWhenUpdatingWithDelete++;
            }
          } else {
            updatedInputRowsWithDelete[String(currentRowWhenUpdatingWithDelete)] = currentRowCopy.map(
              (input, index) => ({ ...input, row: currentRowWhenUpdatingWithDelete, column: index + 1 }),
            );

            currentRowWhenUpdatingWithDelete++;
          }
        });

        updatePositions(updatedInputRowsWithDelete);
      },
      collect: monitor => ({
        canDrop: monitor.canDrop(),
      }),
    }),
    [rows],
  );

  function getLayoutRowDropIndexInRange(
    layoutCoordinate: XYCoord,
    relativeDragCoordinate: XYCoord,
  ): [number, IEditInputLayoutRowRange[]] {
    const rowRelativeBoundingRectangles = layoutRowContainerRefs.current.map(rowRef => {
      const rowBoundingRectangle = rowRef.getBoundingClientRect();

      return {
        x: rowBoundingRectangle.x - layoutCoordinate.x,
        y: rowBoundingRectangle.y - layoutCoordinate.y,
        height: rowBoundingRectangle.height,
      };
    });

    let currentStartYPosition = 0;

    const rowRanges: IEditInputLayoutRowRange[] = rowRelativeBoundingRectangles.map((rowBoundingRectangle, index) => {
      const startY = currentStartYPosition;
      const rowEndY = rowBoundingRectangle.y + rowBoundingRectangle.height;
      let endY = undefined;

      if (index === rowRelativeBoundingRectangles.length - 1) {
        const layoutBoundingRectangle = layoutRef.current.getBoundingClientRect();
        endY = layoutBoundingRectangle.bottom - layoutBoundingRectangle.y;
      } else {
        const nextRowStartY = rowRelativeBoundingRectangles[index + 1].y;
        const halfOfGapBetweenRows = (nextRowStartY - rowEndY) / 2;
        endY = rowEndY + halfOfGapBetweenRows;
      }

      currentStartYPosition = endY;

      return {
        startY,
        endY,
      };
    });

    let rowDropIndex: number = undefined;

    rowRanges.forEach((rowRange, index) => {
      const firstHalf: IEditInputLayoutRowRange = {
        startY: rowRange.startY,
        endY: (rowRange.endY - rowRange.startY) / 2 + rowRange.startY,
      };

      const secondHalf: IEditInputLayoutRowRange = {
        startY: firstHalf.endY,
        endY: rowRange.endY,
      };

      if (inRange(relativeDragCoordinate.y, firstHalf.startY, firstHalf.endY)) {
        rowDropIndex = index;
      } else if (inRange(relativeDragCoordinate.y, secondHalf.startY, secondHalf.endY)) {
        rowDropIndex = index + 1;
      }
    });

    return [rowDropIndex, rowRanges];
  }

  const customDragLayer = (
    <EditInputLayoutCustomDragLayer
      layoutRef={layoutRef}
      getLayoutRowDropIndexInRange={getLayoutRowDropIndexInRange}
      canDrop={canDrop}
    />
  );

  return {
    drop,
    customDragLayer,
  };
}
