import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { ILocation, ISegment } from "redux/reducers/models/reservations";
import { MoveBookingToDropSegmentAction, useDropBookedSegment } from "./useDropBookedSegment";
import RightClickMenu from "components/rightClickMenu/RightClickMenu";

interface IOpenSegmentProps {
  cellClassNames: string;
  cellContentClassName: string;
  segment: ISegment;
  location: ILocation;
  availableDropHours: number;
  moveBookingToDropSegment: MoveBookingToDropSegmentAction;
  openBookingModal: (segment: ISegment, location: ILocation) => Promise<void>;
  setBlockedType: (segment: ISegment, status: string) => Promise<void>;
  updateWebBooking: (segment: ISegment) => Promise<void>;
  updateCreditCardRequired: (segment: ISegment) => Promise<void>;
  deleteSegment: (segment: ISegment) => void;
  viewAuditTrail: (segmentId: number) => void;
}

export default function OpenSegment(props: IOpenSegmentProps) {
  const {
    cellClassNames,
    cellContentClassName,
    segment,
    location,
    availableDropHours,
    moveBookingToDropSegment,
    openBookingModal,
    setBlockedType,
    updateWebBooking,
    updateCreditCardRequired,
    deleteSegment,
    viewAuditTrail,
  } = props;

  const { t, i18n } = useTranslation();

  const useDropBookedSegmentState = useDropBookedSegment(
    segment,
    location,
    availableDropHours,
    moveBookingToDropSegment,
  );

  return (
    <>
      <td
        ref={useDropBookedSegmentState.drop}
        className={cellClassNames}
        style={{
          boxShadow:
            useDropBookedSegmentState.isOver &&
            `0px 0px 0px 1px ${useDropBookedSegmentState.canDrop ? "green" : "red"} inset`,
        }}
        onClick={() => openBookingModal(segment, location)}
      >
        <RightClickMenu
          sections={[
            [
              {
                icon: "circle",
                title: "Mark as Open",

                action: () => setBlockedType(segment, "open"),
                disabled: segment.blocked_type === "open",
              },
              {
                icon: "ban",
                title: "Mark as Blocked",
                action: () => setBlockedType(segment, "blocked"),

                disabled: String(segment.blocked_type) === "blocked",
              },
            ],
            [
              {
                icon: "globe",
                title: segment.web_booking_enabled ? "Disable web booking" : "Enable web booking",
                action: () => updateWebBooking(segment),
              },
              {
                icon: "credit-card",
                title: segment.credit_card_required ? "Remove credit card required" : "Credit card required",
                action: () => updateCreditCardRequired(segment),
              },
            ],
            [
              {
                icon: "list",
                title: "View Segment Audit Trail",
                action: () => viewAuditTrail(segment.id),
              },
              {
                icon: "trash",
                title: "Delete Segment",
                action: () => deleteSegment(segment),
              },
            ],
          ]}
        >
          <div className={cellContentClassName}>
            <p>
              {(segment?.league_id || segment?.tournament_id) && (
                <>
                  {segment?.league_id && (
                    <span>
                      <span>{t("secure.facility.reservation.reservation.013")}</span>
                    </span>
                  )}
                  {segment?.tournament_id && (
                    <span>
                      <span>{t("secure.facility.reservation.reservation.014")}</span>
                    </span>
                  )}
                </>
              )}
              <FontAwesomeIcon icon={["far", "plus-circle"]} className="ml-2" />
            </p>
          </div>
        </RightClickMenu>
      </td>

      {useDropBookedSegmentState.dropConfirmationPopup}
    </>
  );
}
