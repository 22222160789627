import React, { useEffect, useState } from "react";
import axios, { CancelToken } from "axios";
import { Prompt, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { GetFolder, IFolder } from "api/rpc/2022-09/facilityAdmin/product/folder";
import { GetProduct } from "api/rpc/2022-09/facilityAdmin/product/product";
import { GetCart } from "api/rpc/2022-09/facilityAdmin/cart/cart";
import { GetOrder } from "api/rpc/2022-09/facilityAdmin/order/order";

import { dequeue, enqueue, showError, showSuccess } from "redux/actions/ui";
import { IProduct } from "redux/reducers/models/product";
import { IOrder } from "redux/reducers/models/order";
import useLocalStorage from "hooks/useLocalStorage";

import { ButtonNew as Button } from "components/buttonNew";
import Input from "components/form/input/Input";
import Icon from "components/icon/Icon";
import { FolderMenu } from "components/folderMenu/FolderMenu";
import Products, { IProductState } from "elements/register/Products";
import CartMenu from "elements/register/CartMenu";
import { PRODUCT_FOLDERS_KEY } from "../settings/folders/Folders";
import { IRegisterGroup } from "../Admin";

import "elements/register/register.scss";
import { GetVariant } from "api/rpc/2024-04/facilityAdmin/product/variant";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { loadDiscountOptions, loadPaymentOptions } from "redux/actions/facility";
import { loadCart, postCart, postLineItem } from "redux/actions/cart";

export interface IMainState {
  products: IProduct[];
  folders: IFolder[];
  selectedFolder: IFolder;
  extraOptions: any;
  modifierItem: Record<string, any>;
  unPaidOrder: IOrder;
  order: IOrder;
}

export interface IFilterState {
  search: string;
  barcodeSearch: boolean;
  sort: string;
}

export interface ITerminalState {
  online: boolean;
  connecting: boolean;
  reader: any;
}

interface ILocationState {
  params: { cartToken: string; orderId: number };
}

export default function Register() {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { facilityStore, cartStore } = useAppSelector(store => store);

  const registerGroup = useLocalStorage<IRegisterGroup>("register_group");
  const defaultFolderId = useLocalStorage<number>("default_folder_id");

  const [state, setState] = useState<IMainState>({
    products: null,
    folders: [],
    selectedFolder: null,
    extraOptions: [],
    modifierItem: null,
    unPaidOrder: null,
    order: null,
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    search: "",
    barcodeSearch: false,
    sort: "alpha",
  });

  const [productState, setProductState] = useState<IProductState>({
    selectedProduct: undefined,

    variants: [],
    variantSheetOpen: false,
  });

  useEffect(() => {
    //Show warning dialog box when browser is refreshed
    function handleBeforeUnload(event: BeforeUnloadEvent) {
      event.preventDefault();
      event.returnValue = "";
      return "";
    }
    window.addEventListener("beforeunload", handleBeforeUnload);
    void loadRegisterCart();
    if (facilityStore?.paymentOptions?.length === 0) {
      void dispatch(loadPaymentOptions());
    }
    if (facilityStore?.discountOptions?.length === 0) {
      void dispatch(loadDiscountOptions());
    }
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (registerGroup) {
      void loadFolders(registerGroup.id, source.token);
    }
    return () => source.cancel();
  }, [registerGroup]);

  // Used for searching all products
  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (() => {
          try {
            if (filterState.search === "") {
              if (mounted) {
                updateMainState({ products: [] });
              }
              return;
            } else {
              if (mounted) {
                void loadProducts(source.token);
              }
            }
          } catch (error) {
            console.log("err", error);
          }
          return;
        })();
      }, 500);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      source.cancel("Cancelled");
    };
  }, [filterState.search]);

  useEffect(() => {
    if (productState.variants.length > 1) {
      if (!productState.variantSheetOpen) {
        updateProductState({ variantSheetOpen: true });
      }
    }
  }, [productState.variants]);

  async function loadFolders(registerGroupId: number, token?: CancelToken) {
    const folderRes = await GetFolder({ register_group_id: registerGroupId, extended: false }, true, token);

    if (token && token.reason) {
      return;
    }
    if (folderRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.register.register.008")));
      return;
    }

    const folders = folderRes.data;

    // Get default folder for the selected Register or the default set for the facility
    let selectedFolder: IFolder = undefined;

    if (defaultFolderId > 0) {
      selectedFolder = folders.find(folder => folder.id === defaultFolderId);
    } else {
      selectedFolder = folders.find(folder => folder.default === true);
    }

    if (!selectedFolder) {
      selectedFolder = folders?.[0];
    }

    updateMainState({ folders: folders });

    if (selectedFolder) {
      void handleChangeSelectedFolder(selectedFolder);
    }
  }

  async function loadProducts(cancelToken: CancelToken) {
    if (!filterState.barcodeSearch) {
      const productRes = await GetProduct({ extended: true, search: filterState.search }, true, cancelToken);

      if (productRes.status === StatusCode.OK) {
        updateMainState({ products: productRes.data });
      } else {
        if (productRes.message !== "Cancelled") {
          dispatch(showError(productRes.message));
        }
      }
    } else {
      //Cannot scan and add item to cart if an order is already in progress (partial payment made)
      if (state?.order) {
        dispatch(showError("Order in progress. Start a new order to add item to cart"));
        return;
      }
      const res = await GetVariant({ extended: true, barcode: filterState.search }, true);

      if (res.status === StatusCode.OK) {
        const variants = res.data.filter((variant: any) => variant.facility_access === true);

        if (variants.length == 1) {
          const res = await dispatch(postLineItem(cartStore.cart?.id, variants[0]?.id, null, 1, true));
          if (res && res?.status === StatusCode.BAD_REQUEST) {
            dispatch(showError(t("secure.facility.register.register.004")));
            await dispatch(postCart(null, false));
          }

          updateFilterState({ search: "" });
        }
        updateMainState({ products: [] });
      } else {
        dispatch(showError(res.message));
      }
    }
  }

  async function loadRegisterCart() {
    const locationState = location.state as ILocationState;

    if (locationState?.params) {
      dispatch(enqueue());
      //Get cart if location state cart token exists
      const cartRes = await GetCart({ token: locationState?.params?.cartToken }, false);
      if (cartRes.status !== StatusCode.OK) {
        dispatch(showError(t("secure.facility.register.register.009")));
        dispatch(dequeue());
        return;
      }
      //Set the cart in redux without making another GET Cart call (tableCart used to avoid setting token in localStorage)
      void dispatch(loadCart({ tableCart: cartRes?.data[0] }, false));

      //Get the order so the user can continue to complete the order
      const orderRes = await GetOrder({ id: locationState?.params?.orderId, extended: true }, false);
      if (orderRes.status !== StatusCode.OK) {
        dispatch(showError(t("secure.facility.register.register.010")));
        dispatch(dequeue());
        return;
      }
      dispatch(dequeue());
      setState(prevState => ({ ...prevState, unPaidOrder: orderRes?.data[0] }));
    } else {
      void dispatch(loadCart({ tableCart: null }, false));
      if (localStorage.getItem("register_cart_token")) {
        await dispatch(loadCart({ token: String(localStorage.getItem("register_cart_token")) }, false));
      } else {
        await dispatch(postCart(null, false));
      }
    }
  }

  async function selectProduct(product: IProduct) {
    updateProductState({
      selectedProduct: product,
    });

    const variants = product?.variants?.filter(variant => variant?.facility_access === true);
    if (variants?.length > 1) {
      updateProductState({ variants: variants });

      // const res = await GetVariant({ product_id: product.id, extended: true }, true);
      // if (res.status === StatusCode.OK) {
      //   const variants = res.data.filter((variant: any) => variant.facility_access === true);
      //   updateProductState({ variants: variants });
      // } else {
      //   uiActions.showError(res.message);
      // }
    } else {
      // Automatically add product to cart if single variant
      if (variants[0]) {
        await onConfirmVariant(variants[0], product);
      } else {
        dispatch(showError(t("secure.facility.register.register.001")));
      }
    }
  }

  async function onConfirmVariant(variant: Record<string, any>, product?: IProduct) {
    if (variant === undefined) {
      dispatch(showError(t("secure.facility.register.register.002"))); //inform user of TypeError from Promise
      return;
    }

    if (cartStore.cart?.status === "complete") {
      dispatch(showError(t("secure.facility.register.register.003")));
      await dispatch(postCart(null, false));
    }

    // Post line item, backend checks if item is already in cart. If so, quantity is updated accordingly
    const res = await dispatch(postLineItem(cartStore.cart?.id, variant?.id, null, 1, true));

    void onCancel();

    if (res && res?.status === StatusCode.BAD_REQUEST) {
      dispatch(showError(t("secure.facility.register.register.004")));
      await dispatch(postCart(null, false));
    }

    const selectedProduct = product ? product : productState?.selectedProduct;

    // Check if the product has modifiers
    if (selectedProduct?.has_modifiers) {
      const productsRes = await GetProduct({ id: variant.product_id, modifier_groups: 1 }, true);
      if (productsRes.status !== StatusCode.OK) {
        dispatch(showError(productsRes.message));
        return;
      }
      // If the product has required modifiers, open the modifier groups modal
      productsRes.data[0]?.modifier_groups?.every(modifierGroup => {
        updateMainState({ modifierItem: variant });
        return false;
      });
    }
  }

  async function handleChangeSelectedFolder(selectedFolder: IFolder) {
    if (selectedFolder?.id === undefined) {
      return;
    }

    const storedProductFolders: IFolder[] = JSON.parse(localStorage.getItem(PRODUCT_FOLDERS_KEY)) ?? [];
    const storedProductFolder = storedProductFolders.find(productFolder => productFolder?.id === selectedFolder.id);

    const selectedFolderDate = new Date(selectedFolder.updated_at);
    const storedProductFolderDate = new Date(storedProductFolder?.updated_at);

    const storedProductFolderIsValid =
      !isNaN(selectedFolderDate?.getTime()) &&
      !isNaN(storedProductFolderDate?.getTime()) &&
      storedProductFolderDate.getTime() >= selectedFolderDate.getTime();

    if (storedProductFolderIsValid) {
      updateMainState({ selectedFolder: storedProductFolder });
    } else {
      const folderRes = await GetFolder({ id: selectedFolder.id, extended: true }, true);

      if (folderRes.status !== StatusCode.OK || folderRes.data?.length === undefined || folderRes.data.length === 0) {
        dispatch(showError(t("secure.facility.register.register.011")));
        return;
      }

      const newSelectedFolder = folderRes.data[0];

      if (!storedProductFolder) {
        const updatedStoredProductFolders = [...storedProductFolders, newSelectedFolder];
        localStorage.setItem(PRODUCT_FOLDERS_KEY, JSON.stringify(updatedStoredProductFolders));
      } else {
        const updatedStoredProductFolders = storedProductFolders.map(productFolder => {
          if (productFolder.id === newSelectedFolder.id) {
            return newSelectedFolder;
          } else {
            return productFolder;
          }
        });

        localStorage.setItem(PRODUCT_FOLDERS_KEY, JSON.stringify(updatedStoredProductFolders));
      }

      updateMainState({ selectedFolder: newSelectedFolder });
    }
  }

  function updateMainState(newMainState: Partial<IMainState>) {
    setState((cur: IMainState) => {
      return { ...cur, ...newMainState };
    });
  }

  function updateProductState(newProductState: Partial<IProductState>) {
    setProductState((cur: IProductState) => {
      return { ...cur, ...newProductState };
    });
  }

  function updateFilterState(newFilterState: Partial<IFilterState>) {
    setFilterState(cur => {
      return { ...cur, ...newFilterState };
    });
  }

  function onCancel() {
    updateProductState({
      variantSheetOpen: false,
      variants: [],
      selectedProduct: undefined,
    });
  }

  function handleBarcodeSearchClick() {
    const productSearchId = document.getElementById("productSearchId");
    productSearchId.focus();
    updateFilterState({ barcodeSearch: !filterState.barcodeSearch });
  }

  function refreshFolderStorage() {
    localStorage.removeItem(PRODUCT_FOLDERS_KEY);

    if (state.selectedFolder) {
      void handleChangeSelectedFolder(state.selectedFolder);
    }

    dispatch(showSuccess(t("secure.facility.register.register.012")));
  }

  function renderProducts() {
    if (filterState.search && state.products) {
      const products = state.products?.sort((firstProd: IProduct, secondProd: IProduct) => {
        if (filterState.sort === "alpha") {
          if (firstProd.title < secondProd.title) {
            return -1;
          } else if (firstProd.title > secondProd.title) {
            return 1;
          } else {
            return 0;
          }
        }
      }) as [];
      return { useGridPositions: false, products };
    } else {
      const products = state.selectedFolder?.products ?? ([] as []);
      return { useGridPositions: true, products };
    }
  }

  return (
    <>
      <div className="ui-register">
        <div className="ui-register-folders_container">
          <FolderMenu
            options={state.folders}
            optionTitle="title"
            defaultSelected={state.folders.findIndex(folder => folder.id === state.selectedFolder?.id)}
            onClick={(option, selectedIndex) => handleChangeSelectedFolder(option)}
          />
        </div>
        <div className="ui-register-products_container">
          <div className="ui-register-products_content">
            <div className="ui-register-top-bar">
              <div className="flex justify-between mb-4">
                <div className="flex-grow">
                  <Input
                    id="productSearchId"
                    value={filterState.search}
                    onChange={(value: any) => updateFilterState({ search: value.target.value })}
                    type="search"
                    placeholder={t("secure.facility.register.register.006")}
                    autoFocus
                    labelHidden
                    trailingButtons={[
                      <Button
                        key={1}
                        type="secondary"
                        active={filterState.barcodeSearch}
                        onClick={handleBarcodeSearchClick}
                      >
                        <Icon style="far" icon="barcode-read" size="medium" />
                      </Button>,
                      <Button key={2} type="secondary" onClick={refreshFolderStorage}>
                        <Icon style="far" icon="refresh" size="medium" />
                      </Button>,
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className={"product-content"}>
              <Products
                {...renderProducts()}
                productState={productState}
                onClick={selectProduct}
                onCancel={onCancel}
                onConfirmVariant={onConfirmVariant}
                disabled={
                  cartStore?.cart?.status === "complete" || state?.unPaidOrder
                    ? true
                    : false || state.order
                    ? true
                    : false
                }
              />
            </div>
          </div>
        </div>

        <div className="ui-register-cart-menu">
          <CartMenu
            modifierItem={state.modifierItem}
            setRegisterState={updateMainState}
            setFilterState={updateFilterState}
            unPaidOrder={state.unPaidOrder}
          />
        </div>
      </div>
      <Prompt
        when={!!state?.order}
        message={location =>
          "Order is in progress. The current order has not been completed. This will result in a partially paid order. Are you sure you want to continue?"
        }
      />
    </>
  );
}
