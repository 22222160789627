import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import axios, { CancelToken } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useModal from "hooks/modals/useModal";
import { useStripeTerminalPayment } from "hooks/useStripeTerminalPayment/useStripeTerminalPayment";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { showError, showSuccess, enqueue, dequeue } from "redux/actions/ui";

import {
  GetInvoiceTransaction,
  GetInvoiceTransactionReceipt,
  IGetInvoiceTransaction,
  PostInvoiceTransactionRefund,
} from "api/rpc/2024-04/facilityAdmin/order/invoice";
import { GetTournament } from "api/rpc/2024-04/facilityAdmin/tournament/tournament";
import { GetEvent } from "api/rpc/2024-04/facilityAdmin/event/hospitalityEvent";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";
import { LocaleCurrency } from "helpers/Locale";
import { Badge } from "components/badge/Badge";
import InvoicePaymentModal from "./InvoicePaymentModal";
import { IInvoiceTransaction } from "redux/reducers/models/order";
import { loadPaymentOptions } from "redux/actions/facility";
import { NavigationDropdownNew } from "components/navigationDropdownNew/NavigationDropdownNew";
import Portal from "elements/Portal";
import Popup from "components/popup/Popup";

interface IDepositState {
  modalOpen: boolean;
  eventSearch: string;
  amount: number;
}

const TABLE_LIMIT = 50;

export default function Deposits() {
  const dispatch = useAppDispatch();
  const { terminalStore } = useAppSelector(store => store);
  const { StripeTerminalRefundSheet, processRefund } = useStripeTerminalPayment();

  const [deposits, setDeposits] = useState<Array<IInvoiceTransaction>>(undefined);
  const [depositsRefresh, setDepositsRefresh] = useState(false);

  const [filterState, setFilterState] = useState<IGetInvoiceTransaction>({
    offset: 0,
  });

  const [events, setEvents] = useState<any>(null);

  const [depositState, setDepositState] = useState<IDepositState>({
    modalOpen: false,
    eventSearch: "",
    amount: 0,
  });

  const {
    state: refundPopup,
    closeModal: closeRefundPopup,
    updateModal: updateRefundPopup,
  } = useModal({
    clearOnClose: true,
    transaction: null,
  });

  // Load payment methods being used in modal
  useEffect(() => {
    const source = axios.CancelToken.source();
    void dispatch(loadPaymentOptions(source.token));
    return () => source.cancel();
  }, []);

  // Load table data
  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadDeposits(source.token);
    return () => source.cancel();
  }, [filterState, depositsRefresh]);

  // Load events when modal opens
  useEffect(() => {
    const source = axios.CancelToken.source();

    if (depositState.modalOpen) {
      void loadEvents(source.token);
    }

    return () => {
      source.cancel();
    };
  }, [depositState.eventSearch, depositState.modalOpen]);

  async function loadDeposits(token?: CancelToken) {
    if (deposits !== undefined) {
      setDeposits(undefined);
    }

    const res = await GetInvoiceTransaction(
      {
        all_deposits: true,
        limit: TABLE_LIMIT,
        ...filterState,
      },
      token ? false : true,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading deposits")); // TODO: Translation
    }

    const markRefunds = res.status !== StatusCode.OK ? [] : determineRefundedTransactions(res.data);

    setDeposits(markRefunds);
  }

  async function loadEvents(token: CancelToken) {
    let tournaments: Array<any> = [];
    let events: Array<any> = [];
    setEvents(null);

    const tournamentRes = await GetTournament({ search: depositState.eventSearch }, false, token);

    if (token && token.reason) {
      return;
    }
    if (tournamentRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading tournaments")); // TODO: Translation
    } else {
      tournaments = tournamentRes.data.map(tournament => ({ ...tournament, event_type: "tournament" }));
    }

    const eventRes = await GetEvent({ search: depositState.eventSearch }, false, token);

    if (token && token.reason) {
      return;
    }
    if (eventRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading events")); // TODO: Translation
    } else {
      events = eventRes.data.map(event => ({ ...event, event_type: "hospitality" }));
    }

    const allEvents = tournaments.concat(events);

    setEvents(allEvents);
  }

  async function getDepositReceipt(transactionId: number) {
    const res = await GetInvoiceTransactionReceipt({ transaction_id: transactionId }, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error printing deposit receipt")); // TODO: Translation
      return;
    }

    window.open().document.write(res.data);
  }

  async function refundDeposit() {
    //Handle interac refunds
    let interacRefundSuccessful = false;

    if (refundPopup.transaction?.payment_type === "interac") {
      if (terminalStore?.reader) {
        dispatch(enqueue());
        const refundRes = await processRefund(
          refundPopup.transaction?.amount,
          refundPopup.transaction?.payment_intent_id,
        );
        if (refundRes === "success") {
          interacRefundSuccessful = true;
          dispatch(dequeue());
          dispatch(showSuccess("Deposit refunded successfully"));
        } else {
          dispatch(dequeue());
          dispatch(showSuccess("Error refunding deposit"));
          return;
        }
      }
    }

    const res = await PostInvoiceTransactionRefund({ transaction_id: refundPopup.transaction?.id }, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error refunding deposit")); // TODO: Translation
      return;
    }

    closeRefundPopup();
    void loadDeposits();
  }

  /** Refunded transaction based off parent_id of the transaction == the currentTransactionId */
  function determineRefundedTransactions(transactions: IInvoiceTransaction[]): IInvoiceTransaction[] {
    const filteredTransactions = transactions.filter(
      (transaction: Record<string, any>) => transaction?.kind !== "deposit_authorization",
    );
    const refundedTransactions = transactions.filter(
      (transaction: Record<string, any>) => transaction?.parent_id && transaction?.kind === "refund",
    );

    const markRefunds = [...filteredTransactions]?.reduce(
      (markedRefunds, currentTransaction, index) => {
        const refunded = refundedTransactions?.some(
          (transaction: Record<string, any>) => transaction?.parent_id === currentTransaction?.id,
        );

        // Adding in 'refunded' param to HouseAccountTransactionType
        if (refunded) {
          markedRefunds[index] = { ...markedRefunds[index], refunded: true };
        } else {
          markedRefunds[index] = { ...markedRefunds[index], refunded: false };
        }
        return markedRefunds;
      },
      [...filteredTransactions],
    );

    return markRefunds;
  }

  function handleTableNavigation(direction: "prev" | "next") {
    switch (direction) {
      case "prev": {
        setFilterState(prev => ({ ...prev, offset: filterState.offset - TABLE_LIMIT }));
        return;
      }
      case "next": {
        setFilterState(prev => ({ ...prev, offset: filterState.offset + TABLE_LIMIT }));
        return;
      }
      default:
        return;
    }
  }

  const toggleDepositRefresh = () => setDepositsRefresh(prev => !prev);

  const primaryAction = {
    content: "New", // TODO: Translation
    action: () => setDepositState(prevState => ({ ...prevState, modalOpen: true })),
  };

  return (
    <Page
      title="Deposits" // TODO: Translation
      primaryAction={primaryAction}
    >
      <DataTable
        columns={[
          { label: "Event" }, // TODO: Translation
          { label: "Amount" }, // TODO: Translation
          { label: "Processed On" }, // TODO: Translation
          { label: "Status" }, // TODO: Translation
          { label: "", width: "5%" },
        ]}
        loading={deposits === undefined}
        footer={{
          tableLimit: TABLE_LIMIT,
          tableOffset: filterState.offset,
          handleTableOffset: direction => handleTableNavigation(direction),
          disableNextOffset: !(deposits?.length === TABLE_LIMIT) || deposits === undefined,
        }}
      >
        {deposits
          ?.filter(deposit => deposit.kind !== "refund")
          ?.map(deposit => (
            <tr key={deposit.id}>
              <td>{eventName(deposit)}</td>
              <td>
                <LocaleCurrency currency={deposit.currency} amount={deposit.amount} />
              </td>
              <td>
                <span>{moment(deposit.processed_at).format("MMM DD, YYYY")}</span>
              </td>
              <td>{statusBadge(deposit)}</td>
              <td>
                <NavigationDropdownNew
                  showPlainTextLabel
                  rightAlign
                  label={<FontAwesomeIcon icon={["far", "chevron-down"]} />}
                  sections={[
                    [
                      {
                        icon: "print",
                        label: "Print Receipt", // TODO: Translation
                        onClick: () => getDepositReceipt(deposit.id),
                      },
                      {
                        icon: "turn-down-left",
                        label: "Refund", // TODO: Translation
                        onClick: () => updateRefundPopup({ isOpen: true, transaction: deposit }),
                        disabled: deposit.refunded || deposit.kind === "refund",
                      },
                    ],
                  ]}
                />
              </td>
            </tr>
          ))}
      </DataTable>

      <InvoicePaymentModal
        paymentModalVisible={depositState.modalOpen}
        paymentMethods={null} // TODO: Load current customer payment methods
        onOk={() => {
          setDepositState(prevState => ({ ...prevState, modalOpen: false }));
          toggleDepositRefresh();
        }}
        closePaymentModal={() => setDepositState(prevState => ({ ...prevState, modalOpen: false }))}
        depositOnly
        events={events}
        onEventSearch={(value: string) => setDepositState(prevState => ({ ...prevState, eventSearch: value }))}
        eventSearchValue={depositState.eventSearch}
      />

      <Portal isMounted={refundPopup.isOpen}>
        <Popup
          open={refundPopup.isOpen}
          type="warning"
          title="Refund Deposit" // TODO: Translation
          description="Are you sure you want to refund this deposit?" // TODO: Translation
          onCancel={closeRefundPopup}
          onOk={() => refundDeposit()}
          okText="Refund"
        />
      </Portal>
    </Page>
  );
}

const statusBadge = (deposit: IInvoiceTransaction) => {
  if (deposit.kind === "refund") {
    return <Badge type="warning">{"Refund"}</Badge>;
  } else if (deposit.refunded) {
    return <Badge type="warning">{"Refunded"}</Badge>;
  } else {
    switch (deposit.invoice_id) {
      case null:
        return <Badge type="success">{"Available"}</Badge>;
      default:
        return <Badge type="gray">{"Applied"}</Badge>;
    }
  }
};

const eventName = (transaction: IInvoiceTransaction) => {
  if (transaction.tournament) {
    return transaction.tournament.name;
  } else if (transaction.league) {
    return transaction.league.name;
  } else if (transaction.hospitality_event) {
    return transaction.hospitality_event.name;
  } else {
    return "";
  }
};
