import React, { useEffect, useState, useCallback } from "react";
import axios, { CancelToken } from "axios";
import { useHistory } from "react-router-dom";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import "pages/secure/facility/order/order.scss";
import Input from "components/form/input/Input";
import { useTranslation } from "react-i18next";

import RainChecksTable from "./RainChecksTable";
import { GetRainChecks, IRainCheck } from "api/rpc/2024-04/facilityAdmin/payment/rainCheck";

interface IState {
  rainchecks: IRainCheck[];
}

interface IFilterState {
  search: string;
  offset: number;
  limit: number;
}

export default function Orders(props: any) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [state, setState] = useState<IState>({
    rainchecks: undefined,
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    search: null,
    offset: 0,
    limit: 25,
  });

  // Search Rain Checks
  useEffect(() => {
    if (filterState === undefined) {
      return;
    }

    const source = axios.CancelToken.source();
    void loadRainChecks(source.token);

    return () => {
      source.cancel();
    };
  }, [filterState.search, filterState.offset]);

  function updateState(newState: Partial<IState>) {
    setState((cur: IState) => {
      return { ...cur, ...newState };
    });
  }

  async function loadRainChecks(token: CancelToken) {
    updateState({ rainchecks: undefined });

    const raincheckRes = await GetRainChecks(
      {
        code: filterState.search,
        offset: filterState.offset,
        limit: filterState.limit,
      },
      false,
      token,
    );
    if (raincheckRes.status !== StatusCode.OK) {
      return [];
    }

    updateState({ rainchecks: raincheckRes.data });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return raincheckRes.data;
  }

  function navigateToItem(url: string) {
    history.push(url);
  }

  function navigateToNewOrder(url: string) {
    history.push("/admin/order");
  }

  const primaryAction = {
    content: t("secure.facility.order.rain_checks.001"),
    action: navigateToNewOrder,
  };

  return (
    <Page title={t("secure.facility.order.rain_checks.002")} narrow>
      <div className="flex-grow mb-4">
        <Input
          value={filterState.search}
          onChange={(value: any) => setFilterState(prevState => ({ ...prevState, search: value.target.value }))}
          type="search"
          placeholder={t("secure.facility.order.rain_checks.003")}
        ></Input>
      </div>
      <RainChecksTable
        rainChecks={state.rainchecks}
        tableLimit={filterState.limit}
        tableOffset={filterState.offset}
        handleTableOffset={direction =>
          setFilterState(prev => ({
            ...prev,
            offset: direction === "prev" ? prev.offset - prev.limit : prev.offset + prev.limit,
          }))
        }
      />
    </Page>
  );
}
