import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { dictionary } from "helpers/Dictionary";
import "components/text/textStyle/textStyle.scss";
import _ from "lodash";

const localeLanguage = localStorage.getItem("localeLanguage");
const localeLanguageCountry = localStorage.getItem("localeLanguageWithCountry");

const defaultLanguage = "en";

export function LocaleText(props: any) {
  return <span>{_.get(dictionary, [props.id, localeLanguage ? localeLanguage : defaultLanguage])}</span>;
}

export function LocaleCurrency(props: any) {
  const amount = parseFloat(props.amount);
  let formatted: string;
  let roundedAmount: string;

  switch (props.currency) {
    case "cad":
    case "usd":
      roundedAmount = amount.toFixed(2);
      formatted = "$" + roundedAmount.toString();
      break;
    default:
      roundedAmount = amount.toFixed(2);
      formatted = "$" + roundedAmount.toString();
  }

  return <span style={props.style}>{formatted}</span>;
}

// Retrieve the default browser locale's being used by the user
export function getBrowserLocales(options = {}) {
  const defaultOptions = {
    languageCodeOnly: false,
  };

  const opt = {
    ...defaultOptions,
    ...options,
  };

  const browserLocales = navigator.languages === undefined ? [navigator.language] : navigator.languages;

  if (!browserLocales) {
    return undefined;
  }

  return browserLocales.map(locale => {
    const trimmedLocale = locale.trim();

    return opt.languageCodeOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale;
  });
}
