import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios, { CancelToken } from "axios";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { GetBankAccount } from "api/rpc/2024-04/facilityAdmin/facility/externalAcount";
import { PutAcceptTerms } from "api/rpc/2024-04/facilityAdmin/payment/teeonPayments/accounts";

import { showError, showSuccess } from "redux/actions/ui";
import { TBankAccountExternal } from "redux/reducers/models/facility";
import { valueToString } from "helpers/Helpers";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import Page from "components/page/Page";
import { Badge } from "components/badge/Badge";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";

import "../facility/facility.scss";

export default function BankAccounts() {
  const history = useHistory();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const authStore = useAppSelector(store => store.authStore);
  const permissions = authStore?.user?.permissions;

  const [bankAccounts, setBankAccounts] = useState<TBankAccountExternal[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadBankAccounts(source.token);
    return () => source.cancel();
  }, []);

  async function loadBankAccounts(token?: CancelToken) {
    if (bankAccounts !== undefined) {
      setBankAccounts(undefined);
    }
    const res = await GetBankAccount(undefined, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      setBankAccounts([]);
      dispatch;
      return;
    }

    setBankAccounts(res.data);
  }

  async function handleAcceptTerms() {
    const res = await PutAcceptTerms(true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.bank_accounts.bank_accounts.001")));
      return;
    }
    dispatch(showSuccess(t("secure.facility.settings.bank_accounts.bank_accounts.002")));
  }

  const primaryAction = {
    content: t("secure.facility.settings.bank_accounts.bank_accounts.003"),
    action: () => history.push("/admin/settings/bank_accounts/new"),
  };

  const secondaryAction = {
    content: t("secure.facility.settings.bank_accounts.bank_accounts.004"),
    action: handleAcceptTerms,
  };

  return (
    <Page
      title={t("secure.facility.settings.bank_accounts.bank_accounts.005")}
      subtitle={t("secure.facility.settings.bank_accounts.bank_accounts.006")}
      primaryAction={permissions.bank_accounts_create ? primaryAction : null}
      secondaryActions={[secondaryAction]}
      narrow
    >
      <DataTable
        columns={[
          { label: t("secure.facility.settings.bank_accounts.bank_accounts.007") },
          { label: t("secure.facility.settings.bank_accounts.bank_accounts.008") },
          { label: t("secure.facility.settings.bank_accounts.bank_accounts.009") },
          { label: t("secure.facility.settings.bank_accounts.bank_accounts.010") },
          { label: t("secure.facility.settings.bank_accounts.bank_accounts.011") },
          { label: t("secure.facility.settings.bank_accounts.bank_accounts.012") },
        ]}
        loading={bankAccounts === undefined}
      >
        {bankAccounts?.map(account => (
          <tr
            key={account.id}
            className="clickable"
            onClick={() => history.push("/admin/settings/bank_accounts/" + String(account.id))}
          >
            <td className="table-cell-text-lead">{account.last_4_redacted}</td>
            <td>{account.account_holder_name}</td>
            <td>{valueToString(account.country, "uppercase")}</td>
            <td>
              {valueToString(account.currency, "uppercase")}
              {account.currency_default ? (
                <Badge type="gray" size="medium">
                  {t("secure.facility.settings.bank_accounts.bank_accounts.013")}
                </Badge>
              ) : null}
            </td>
            <td>{account.bank_name}</td>
            <td>{valueToString(account.type, "uppercase")}</td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
