import React, { useEffect, useState, useCallback, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import { useParams } from "react-router";

import Page from "components/page/Page";
import Card from "components/card/Card";
import { IUIActions, showError, showSuccess } from "redux/actions/ui";
import { IUIState } from "redux/reducers/ui";
import { ITerminalState } from "redux/reducers/facilityAdmin/terminal";
import { IAuthState } from "redux/reducers/auth";
import { IFacilityActions } from "redux/actions/facility";
import Input from "components/form/input/Input";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import { Select } from "components/select/index";
import { StatusCode } from "api/protocols";

import "./facility.scss";

import { isEqualWith, isNull } from "lodash";
import ReactDOM from "react-dom";
import { NotificationType } from "components/notificationBar/NotificationBar";

import { IProductImage } from "redux/reducers/models/product";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PostFacilityLogo } from "api/rpc/2022-09/facilityAdmin/facility/facility";
import { IFacilityImage } from "redux/reducers/models/facility";

import { useTranslation } from "react-i18next";
import { useAppDispatch } from "hooks/redux";
import { GetFacility, PutFacility } from "api/rpc/2024-04/facilityAdmin/facility/facility";
import TextEditor from "components/textEditor/textEditor";
import moment from "moment";

interface IProps {
  facilityActions: IFacilityActions;
  facilityStore: IFacilityState;
  UIActions: IUIActions;
  UIStore: IUIState;
}

interface IFacilityState {
  id: number;
  full_name: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  postal: string;
  phone: string;
  province: string;
  merchant_code: any;
  image: IFacilityImage;
  logo_source: string;
  google_analytics_code: string;
  facebook_pixel_code: string;
  timezone: string;
}

export default function FacilityEdit(props: IProps) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { Option } = Select;
  const { facilityActions, facilityStore, UIActions, UIStore } = props;
  const dispatch = useAppDispatch();

  const imageUploadInputRef = useRef(null);

  const timeZones = moment.tz.zonesForCountry("CA");
  const business_types = [
    {
      title: t("secure.facility.settings.facility.facility_edit.001"),
      value: 7941,
    },
    {
      title: t("secure.facility.settings.facility.facility_edit.002"),
      value: 5734,
    },
    {
      title: t("secure.facility.settings.facility.facility_edit.003"),
      value: 7011,
    },
  ];

  const [facilityState, setFacilityState] = useState<IFacilityState>({
    id: null,
    full_name: "",
    address_line_1: "",
    address_line_2: "",
    city: "",
    postal: "",
    phone: "",
    province: "",
    merchant_code: "",
    image: null,
    logo_source: "",
    google_analytics_code: "",
    facebook_pixel_code: "",
    timezone: "",
  });

  const [facilityStateBeforeChanges, setFacilityStateBeforeChanges] = useState<IFacilityState>(undefined);
  const [facilityStateLoaded, setFacilityStateLoaded] = useState<boolean>(false);

  useEffect(() => {
    void loadFacility();
  }, []);

  async function loadFacility() {
    const res = await GetFacility(null, false);
    if (res.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.facility.facility_edit.026")));
      return;
    }

    const facility = res.data[0];

    ReactDOM.unstable_batchedUpdates(() => {
      setFacilityState(prev => ({
        ...prev,
        id: facility.id,
        full_name: facility.full_name,
        address_line_1: facility.address_line_1,
        address_line_2: facility.address_line_2,
        city: facility.city,
        postal: facility.postal,
        phone: facility.phone,
        province: facility.province_name,
        merchant_code: facility.merchant_code,
        logo_source: facility.logo_source,
        google_analytics_code: facility?.google_analytics_code ?? "",
        facebook_pixel_code: facility?.facebook_pixel_code ?? "",
        timezone: facility?.timezone,
      }));
      setFacilityStateLoaded(true);
    });
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setFacilityState(prevState => ({ ...prevState, [id]: value }));
  }

  const handleSelectNewChange = (value: string | number, id: string) => {
    console.log(`value: ${value}, id: ${id}`);

    setFacilityState(prev => ({
      ...prev,
      [id]: value,
    }));
  };

  async function saveFacility() {
    const facilityRes = await PutFacility(facilityState, true);
    if (facilityRes.status !== StatusCode.OK) {
      dispatch(
        showError(
          typeof facilityRes.data === "string"
            ? facilityRes.data
            : t("secure.facility.settings.facility.facility_edit.027"),
        ),
      );
      return;
    }

    dispatch(showSuccess(t("secure.facility.settings.facility.facility_edit.028")));
    setFacilityStateBeforeChanges(facilityState);
  }

  function unsavedChangesExist() {
    if (facilityStateBeforeChanges === undefined) {
      if (facilityStateLoaded) {
        setFacilityStateBeforeChanges(facilityState);
      }
      return false;
    }

    return !isEqualWith(facilityStateBeforeChanges, facilityState, (originalValue, newValue) => {
      if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
        return true;
      }
    });
  }

  function cancelUnsavedChanges() {
    setFacilityState(facilityStateBeforeChanges);
  }

  function handleDropDownChange(value: any, property: string) {
    setFacilityState(prevState => ({ ...prevState, merchant_code: value }));
  }
  function handleTimeZoneChange(value: any) {
    setFacilityState(prevState => ({ ...prevState, timezone: value }));
  }

  async function deleteFacilityImage() {
    const imageRes = await PutFacility({ logo_source: null }, true);
    if (imageRes.status !== StatusCode.OK) {
      dispatch(
        showError(
          typeof imageRes?.data === "string" ? imageRes.data : t("secure.facility.settings.facility.facility_edit.029"),
        ),
      );
      return;
    }
    dispatch(showSuccess(t("secure.facility.settings.facility.facility_edit.028")));

    const facility = imageRes.data;

    ReactDOM.unstable_batchedUpdates(() => {
      setFacilityStateBeforeChanges(prevState => ({ ...prevState, logo_source: facility?.logo_source }));
      setFacilityState(prev => ({
        ...prev,
        id: facility.id,
        full_name: facility.full_name,
        address_line_1: facility.address_line_1,
        address_line_2: facility.address_line_2,
        city: facility.city,
        postal: facility.postal,
        phone: facility.phone,
        province: facility.province_name,
        merchant_code: facility.merchant_code,
        logo_source: facility.logo_source,
      }));
      setFacilityStateLoaded(true);
    });
  }

  function imageUploadOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files;

    if (file && file.length > 0) {
      //Upload image after the file is selected
      void uploadFacilityImage(file[0]);
    }
  }

  async function uploadFacilityImage(imageFile: File) {
    const formData = new FormData();
    formData.append("logo_image", imageFile);

    //Reset file selector input value
    imageUploadInputRef.current.value = "";

    const imageRes = await PostFacilityLogo(formData, true);

    if (imageRes.status !== StatusCode.OK) {
      dispatch(
        showError(
          typeof imageRes?.data === "string" ? imageRes.data : t("secure.facility.settings.facility.facility_edit.029"),
        ),
      );
      return;
    }
    dispatch(showSuccess(t("secure.facility.settings.facility.facility_edit.030")));

    //Update logo source with updated image source from Facility

    ReactDOM.unstable_batchedUpdates(() => {
      setFacilityState(prev => ({
        ...prev,
        logo_source: imageRes.data?.logo_source,
      }));
      setFacilityStateBeforeChanges(prevState => ({ ...prevState, logo_source: imageRes?.data?.logo_source }));
    });
  }

  return (
    <Page
      title={t("secure.facility.settings.facility.facility_edit.004")}
      subtitle={t("secure.facility.settings.facility.facility_edit.005")}
      narrow
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: saveFacility,
        onCancel: cancelUnsavedChanges,
      }}
    >
      {facilityStateLoaded && (
        <Form>
          <Card>
            <Card.Section title={t("secure.facility.settings.facility.facility_edit.007")}>
              <FormLayout>
                <FormLayout.Group>
                  <span className="facility-edit-input_container">
                    <Input
                      value={facilityState.full_name}
                      id="full_name"
                      onChange={handleInputChange}
                      placeholder={t("secure.facility.settings.facility.facility_edit.008")}
                      label={t("secure.facility.settings.facility.facility_edit.009")}
                    />
                  </span>
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>
            <Card.Section title={t("secure.facility.settings.facility.facility_edit.010")}>
              <FormLayout>
                <FormLayout.Group>
                  <span className="facility-edit-input_container">
                    <Input
                      value={facilityState.address_line_1}
                      id="address_line_1"
                      onChange={handleInputChange}
                      placeholder={t("secure.facility.settings.facility.facility_edit.011")}
                      label={t("secure.facility.settings.facility.facility_edit.012")}
                    />
                  </span>
                  <span className="facility-edit-input_container">
                    <Input
                      value={facilityState.address_line_2}
                      id="address_line_2"
                      onChange={handleInputChange}
                      placeholder={t("secure.facility.settings.facility.facility_edit.013")}
                      label={t("secure.facility.settings.facility.facility_edit.014")}
                    />
                  </span>
                </FormLayout.Group>
                <FormLayout.Group>
                  <span className="facility-edit-input_container">
                    <Input
                      value={facilityState.city}
                      id="city"
                      onChange={handleInputChange}
                      placeholder={t("secure.facility.settings.facility.facility_edit.015")}
                      label={t("secure.facility.settings.facility.facility_edit.016")}
                    />
                  </span>
                  <span className="facility-edit-input_container">
                    <Input
                      value={facilityState.province}
                      id="province_name"
                      onChange={handleInputChange}
                      placeholder={t("secure.facility.settings.facility.facility_edit.017")}
                      label={t("secure.facility.settings.facility.facility_edit.018")}
                    />
                  </span>
                  <span className="facility-edit-input_container">
                    <Input
                      value={facilityState.postal}
                      id="postal"
                      onChange={handleInputChange}
                      placeholder={t("secure.facility.settings.facility.facility_edit.019")}
                      label={t("secure.facility.settings.facility.facility_edit.020")}
                    />
                  </span>
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>

            <Card.Section title={"Time Zone"}>
              <FormLayout>
                <FormLayout.Group>
                  <span className="facility-edit-input_container">
                    <Select
                      label={"Select time zone"}
                      onChange={(value: any) => handleTimeZoneChange(value)}
                      defaultValue={facilityState.timezone}
                    >
                      {timeZones.map((timezone: any, index: number) => {
                        return (
                          <Option key={index} value={timezone} name={timezone}>
                            {timezone}
                          </Option>
                        );
                      })}
                    </Select>
                  </span>

                  <span></span>
                  <span></span>
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>

            <Card.Section title={t("secure.facility.settings.facility.facility_edit.021")}>
              <FormLayout>
                <FormLayout.Group>
                  <span className="facility-edit-input_container">
                    <Input
                      value={facilityState.phone}
                      id="phone"
                      onChange={handleInputChange}
                      placeholder={t("secure.facility.settings.facility.facility_edit.022")}
                      label={t("secure.facility.settings.facility.facility_edit.023")}
                    />
                  </span>
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>
          </Card>

          <Card>
            <Card.Section title="Analytic Tracking Codes">
              <FormLayout>
                <FormLayout.Group>
                  <Input
                    value={facilityState.google_analytics_code ?? ""}
                    onChange={e =>
                      setFacilityState(prevState => ({ ...prevState, google_analytics_code: e.target.value }))
                    }
                    label={"Google Analytics Code"}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Input
                    value={facilityState.facebook_pixel_code ?? ""}
                    onChange={e =>
                      setFacilityState(prevState => ({ ...prevState, facebook_pixel_code: e.target.value }))
                    }
                    label={"Facebook Pixel Code"}
                  />
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>
          </Card>

          <Card>
            <Card.Section title={t("secure.facility.settings.facility.facility_edit.024")}>
              <div className="product-image_grid">
                {facilityState.logo_source !== null || "" || undefined ? (
                  <div>
                    <div className="product-image_container">
                      <div className="product-image">
                        <div className="remove-button_container">
                          <div className="remove-button" onClick={() => deleteFacilityImage()}>
                            <FontAwesomeIcon icon={"times"} />
                          </div>
                        </div>
                        <img src={facilityState.logo_source} alt="product image" />
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="product-image-upload_container">
                      <div className="product-image-upload_content">
                        <input
                          ref={imageUploadInputRef}
                          className="product-image-upload_input"
                          type="file"
                          accept="image/*"
                          onChange={imageUploadOnChange}
                        />
                        <p>
                          <FontAwesomeIcon icon={"upload"} />
                        </p>
                      </div>
                    </div>
                    <div className="product-image-upload_content"></div>
                  </>
                )}
              </div>
            </Card.Section>
          </Card>
          <Card>
            <Card.Section>
              <FormLayout>
                <FormLayout.Group>
                  <span className="facility-edit-input_container">
                    <Select
                      label={t("secure.facility.settings.facility.facility_edit.025")}
                      onChange={(value: any) => handleDropDownChange(value, "merchant_id")}
                      defaultValue={facilityState.merchant_code}
                    >
                      {business_types.map((business: any, index: number) => {
                        return (
                          <Option key={index} value={business.value} name={business.title}>
                            {business.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </span>
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>
          </Card>
        </Form>
      )}
    </Page>
  );
}
