import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import axios, { CancelToken } from "axios";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import {
  DeactivatePaymentOption,
  GetPaymentOptions,
  PutPaymentOptions,
  TPutPaymentOption,
} from "api/rpc/2024-04/facilityAdmin/facility/paymentOption";
import { GetAccountingReference } from "api/rpc/2024-04/facilityAdmin/facility/accounting";

import { showError } from "redux/actions/ui";
import { TAccountingReference } from "redux/reducers/models/transaction";
import { useAppDispatch } from "hooks/redux";
import { handleChangeEventInput, unsavedChangesExist } from "helpers/Helpers";
import useModal from "hooks/modals/useModal";

import Card from "components/card/Card";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import Page from "components/page/Page";
import { Select } from "components/select/index";
import Popup from "components/popup/Popup";
import Portal from "elements/Portal";

export default function FacilityPaymentOptionsEdit() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { paymentOptionId } = useParams<{ paymentOptionId: string }>();

  const dispatch = useAppDispatch();

  const [paymentOption, setPaymentOption] = useState<TPutPaymentOption>(undefined);
  const [paymentOptionBeforeChanges, setPaymentOptionBeforeChanges] = useState<TPutPaymentOption>(undefined);

  const [accountingReferences, setAccountingReferences] = useState<TAccountingReference[]>([]);
  const { state: modal, updateModal } = useModal();

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadPaymentOption(paymentOptionId, source.token);
    void loadAccountingReferences(source.token);
    return () => source.cancel();
  }, [paymentOptionId]);

  async function loadPaymentOption(id: string | number, token?: CancelToken) {
    const res = await GetPaymentOptions({ id: id }, true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading payment option."));
    }

    setPaymentOption(res.status !== StatusCode.OK ? undefined : res.data[0]);
    setPaymentOptionBeforeChanges(res.status !== StatusCode.OK ? undefined : res.data[0]);
  }

  async function loadAccountingReferences(token?: CancelToken) {
    const res = await GetAccountingReference(null, true);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading accounting references."));
    }

    setAccountingReferences(res.status !== StatusCode.OK ? [] : (res.data as TAccountingReference[]));
  }

  async function savePaymentOption() {
    if (paymentOption.id === 0) {
      return;
    }

    const paymentOptionResponse = await PutPaymentOptions(paymentOption, true);

    if (paymentOptionResponse.status !== StatusCode.OK) {
      return;
    }

    history.push("/admin/settings/facility-payment-options");
  }

  async function deactivatePaymentOption() {
    if (paymentOption.id == "-1") {
      return;
    }

    const res = await DeactivatePaymentOption({ id: paymentOption.id }, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error deactivating the payment option."));
      return;
    }

    updateModal({ isOpen: false });
    history.push("/admin/settings/facility-payment-options");
  }

  const primaryAction = {
    content: t("secure.facility.settings.facility.facility_payment_options_edit.001"),
    action: () => updateModal({ isOpen: true }),
  };

  return (
    <Page
      title={`${t("secure.facility.settings.facility.facility_payment_options_edit.002")} ${
        paymentOptionBeforeChanges ? paymentOptionBeforeChanges.title : ""
      }`}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          label: t("secure.facility.settings.facility.facility_payment_options_edit.003"),
          url: "/admin/settings/facility-payment-options",
        },
      ]}
      notificationBarProps={{
        isVisible:
          unsavedChangesExist(paymentOption, paymentOptionBeforeChanges) &&
          paymentOption.accounting_reference_id !== "-1",
        onAction: savePaymentOption,
        onCancel: () => setPaymentOption(paymentOptionBeforeChanges),
      }}
    >
      <Form>
        <Card>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={paymentOption?.title || ""}
                  label={t("secure.facility.settings.facility.facility_payment_options_edit.005")}
                  id="title"
                  onChange={e => handleChangeEventInput(e, paymentOption, setPaymentOption)}
                  placeholder={t("secure.facility.settings.facility.facility_payment_options_edit.006")}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Select
                  label={t("secure.facility.settings.facility.facility_payment_options_edit.007")}
                  defaultValue={paymentOption ? paymentOption.accounting_reference_id : "-1"}
                  onChange={(value: string) =>
                    setPaymentOption(prev => ({ ...prev, accounting_reference_id: value }))
                  }
                  error={
                    accountingReferences.length > 0 && paymentOption?.accounting_reference_id === "-1" ? true : false
                  }
                >
                  <Select.Option key={"none"} value={"-1"} name="none">
                    <div style={{ minHeight: "32px" }}></div>
                  </Select.Option>
                  {accountingReferences.map((type, index: number) => {
                    return (
                      <Select.Option key={index} value={type.id} name={type.title}>
                        <span>{type.title}</span>
                      </Select.Option>
                    );
                  })}
                </Select>
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>
      </Form>

      <Portal isMounted={modal.isOpen}>
        <Popup
          open={modal.isOpen}
          type={"warning"}
          title={"Deactivate Payment Method"}
          description={`You are about to deactivate ${paymentOptionBeforeChanges?.title} as a payment option.  Are you sure?`}
          onOk={() => deactivatePaymentOption()}
          onCancel={() => updateModal({ isOpen: false })}
        />
      </Portal>
    </Page>
  );
}
