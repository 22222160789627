import React, { useState, useEffect, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import ReactDOM from "react-dom";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { cloneDeep, groupBy } from "lodash";
import { Form } from "react-bootstrap";
import classNames from "classnames";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import {
  GetTeeTimeAuditTrails,
  PostTeeTimeSqueeze,
  GetTeeTimePricing,
  GetTeeTime,
  GetPrintCartSigns,
  PutTeeTime,
} from "api/rpc/teeSheet/teeSheet";
import { GetTeeTimeLock, PostTeeTimeLock } from "api/rpc/facilityAdmin/teesheet/teetime";
import {
  PutCancelBooking,
  PutCancelParticipant,
  PutMoveBooking,
  GetBooking,
  GetAuditTrails,
} from "api/rpc/2022-09/facilityAdmin/teesheet/booking";
import { GetCustomerType } from "api/rpc/2022-09/facilityAdmin/customer/type";
import { RemoveShotgunTemplate } from "api/rpc/2022-09/facilityAdmin/teesheet/template";
import {
  PostTeeTime,
  DeleteTeeTime,
  UpdateShotgunStartHole,
  ExportShotgunTemplate,
} from "api/rpc/2024-04/facilityAdmin/teesheet/teeSheet";
import { GetShotGunTemplate, IGetShotGunTemplate, PutShotGunTemplateApply } from "api/rpc/teeSheet/template";
import { GetTournament } from "api/rpc/2024-04/facilityAdmin/tournament/tournament";
import { GetLeague } from "api/rpc/2024-04/facilityAdmin/league/league";
import { GetBookingCategory } from "api/rpc/facilityAdmin/teesheet/bookingCategory";

import { IUIState } from "redux/reducers/ui";
import { IUIActions, showError } from "redux/actions/ui";
import { ITeeSheetActions, selectTeeTime as selectNewTeeTime, selectTeeTimeSuccess } from "redux/actions/teesheet";
import { ITeeSheetState } from "redux/reducers/teesheet";
import { IFacilityState } from "redux/reducers/facility";
import { ISlot, ITeeTime, IBooking, IBookingCategory } from "redux/reducers/models/teetime";
import { IAuthState } from "redux/reducers/auth";
import { ITournament } from "redux/reducers/models/tournament";
import { ILeague } from "redux/reducers/models/league";

import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { MOBILE_WIDTH } from "helpers/ScreenSizes";
import { formatDate, timeToNumber, convertTime, capitalize, valueToString } from "helpers/Helpers";

import { TeeSheetCard as Card } from "components/teeSheetCard";
import Sheet from "components/sheet/Sheet";
import Popup from "components/popup/Popup";
import { Select } from "components/select/index";
import { ButtonNew as Button } from "components/buttonNew/index";
import Callout from "components/callout/Callout";
import Tabs from "components/tabs/Tabs";
import AuditTrailNote from "components/bookingPopUp/auditTrailNote/AuditTrailNote";

import FormLayout from "components/form/FormLayout";
import TeeTimeToolTip, { ITeeTimeToolTipPricing } from "components/teeTimeToolTip/teeTimeToolTip";
import { Badge } from "components/badge/Badge";
import Spin from "components/spin/spin";
import { NavigationDropdownNew } from "components/navigationDropdownNew/NavigationDropdownNew";
import RightClickMenu from "components/rightClickMenu/RightClickMenu";
import BookingModalEdit from "elements/teesheet/BookingModalEdit";
import TimePick from "components/timePick/TimePick";
import Input from "components/form/input/Input";
import Checkbox from "components/form/checkbox/Checkbox";

import "./teesheet.scss";
import { useAppDispatch } from "hooks/redux";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { CustomIcon } from "components/customIcon/CustomIcon";
import { PutBookingParticipantNoShow } from "api/rpc/teeSheet/booking";
import { IDivision } from "redux/reducers/models/facility";

interface IDragAndDropState {
  move_booking_length: any;
  starting_slot: any;
  booking_id: any;
  slot_index: any;
  group_index: any;
  teetimeToolTipDisabled: boolean; // Toggle the TeeTimeToolTip popup only when not dragging items
}

interface ITeeSheetTabsProps {
  facilityStore: IFacilityState;
  uiActions: IUIActions;
  uiStore: IUIState;
  teeSheetStore: ITeeSheetState;
  teeSheetActions: ITeeSheetActions;
  authStore: IAuthState;
  division_id?: number;
  course_id?: number;
  courseDivisions: IDivision[];
}

interface IRightClickMenuState {
  cancelBookingPopup: boolean;
  cancelPlayerPopup: boolean;
  selectedSlot: ISlot;
  teetimeToCancelDate?: string;
  teetimeToCancelTime?: string;
  noShow: { popup: boolean; slot: ISlot; type: string };
}

interface IAddTeeTimeState {
  addTeeTimePopup: boolean;
  removeTeeTimePopup: boolean;
  start_hole: string;
  start_time: string;
}

export interface IMainState {
  teetimesDraggable: ITeeTimeDraggable[] | null;
  currentTime: Date;
  customerSearchActive: boolean;
  allowScroll: boolean;
  teetimePopupVisible: boolean;
  teetimeId: number;
  teetimeStartTime: string;
  auditTrails: any;
  audit_trails_loading: boolean;
  customerTypes: any;
  teetimeLockPopupVisible: boolean;
  teetimeBookedPopupVisible: boolean;
  teeTimeBlockedPopupVisible: boolean;
  selectedTeeTime: Record<string, any>;
  teetime: any;
  booking_categories: any[];
  booking_categories_loading: boolean;
  removeShotGunPopup: boolean;
  selectedBooking: IBooking;
  teeTimeLock: boolean;
  editingBooking: boolean;
  detailsModalVisible: boolean;
  auditTrailModalVisible: boolean;
  shotgunModalVisible: boolean;
  squeezeTimePopupVisible: boolean;
  bookingAuditTrailModalVisible: boolean;
  editShotgunStartHoleVisible: boolean;
  shotgun_start_hole: string;
  shotgun_teetime_id: number;
  teeSheetTournaments: Array<ITournament>;
  teeSheetLeagues: Array<ILeague>;
}

type TDraggableSlots = Array<Array<Array<ISlot>>>;
type TDraggableSlot = Array<Array<ISlot>>;

export interface ITeeTimeDraggable extends Omit<ITeeTime, "slots"> {
  slots: TDraggableSlot;
}

interface IShotGunTemplateState {
  shotgunTemplates: IGetShotGunTemplate[];
  shotgun_template_id: number;
  tee_sheet_id: number;
  start_time: string;
  duration: string;
  lead_time: string;
  division_id: number;
  turn_division_id: number;
  tournament_id: number;
  league_id: number;
  tournaments: ITournament[];
  leagues: Array<ILeague>;
  tournamentQuery: string;
  leagueQuery: string;
  booking_categories: Array<IBookingCategory>;
  booking_category_ids: Array<number>;
}

const TeeSheetTabs: React.FC<ITeeSheetTabsProps> = props => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { Option } = Select;

  const { facilityStore, uiActions, uiStore, division_id, teeSheetActions, teeSheetStore, courseDivisions, authStore } =
    props;

  const windowSize = useWindowSize();
  const dispatch = useAppDispatch();

  const [state, setState] = useState<IMainState>({
    teetimesDraggable: null,
    currentTime: new Date(),
    customerSearchActive: false,
    allowScroll: true,
    teetimePopupVisible: false,
    teetimeId: 0,
    teetimeStartTime: "",
    auditTrails: [],
    audit_trails_loading: false,
    customerTypes: [],
    teetimeLockPopupVisible: false,
    teetimeBookedPopupVisible: false,
    teeTimeBlockedPopupVisible: false,
    selectedTeeTime: null,
    teetime: null,
    booking_categories: [],
    booking_categories_loading: false,
    removeShotGunPopup: false,
    selectedBooking: null,
    teeTimeLock: null,
    editingBooking: false,
    detailsModalVisible: false,
    auditTrailModalVisible: false,
    shotgunModalVisible: false,
    squeezeTimePopupVisible: false,
    bookingAuditTrailModalVisible: false,
    editShotgunStartHoleVisible: false,
    shotgun_start_hole: "",
    shotgun_teetime_id: null,
    teeSheetLeagues: undefined,
    teeSheetTournaments: undefined,
  });

  const [bookingState, setBookingState] = useState<IDragAndDropState>({
    move_booking_length: undefined,
    starting_slot: undefined,
    booking_id: undefined,
    slot_index: undefined,
    group_index: undefined,
    teetimeToolTipDisabled: false,
  });

  const [shotgunTemplateState, setShotgunTemplateState] = useState<IShotGunTemplateState>({
    shotgunTemplates: [],
    shotgun_template_id: undefined,
    tee_sheet_id: undefined,
    start_time: "",
    duration: "",
    lead_time: "",
    division_id: undefined,
    turn_division_id: undefined,
    tournament_id: undefined,
    league_id: undefined,
    tournaments: [],
    leagues: [],
    tournamentQuery: "",
    leagueQuery: "",
    booking_categories: [],
    booking_category_ids: [],
  });

  const [rightClickMenuState, setRightClickMenuState] = useState<IRightClickMenuState>({
    cancelBookingPopup: false,
    cancelPlayerPopup: false,
    selectedSlot: null,
    teetimeToCancelDate: null,
    teetimeToCancelTime: null,
    noShow: { popup: false, slot: null, type: "" },
  });

  const [addTeeTimeState, setAddTeeTimeState] = useState<IAddTeeTimeState>({
    addTeeTimePopup: false,
    removeTeeTimePopup: false,
    start_hole: "",
    start_time: "",
  });

  function updateMainState(newMainState: Partial<IMainState>) {
    setState((cur: IMainState) => {
      return { ...cur, ...newMainState };
    });
  }

  function updateBookingState(newTeetimeState: Partial<IDragAndDropState>) {
    setBookingState((cur: IDragAndDropState) => {
      return { ...cur, ...newTeetimeState };
    });
  }

  async function loadTeeSheetTournaments(ids: Array<number>) {
    if (ids?.length > 0) {
      const tournamentRes = await GetTournament({ ids: ids }, false);
      updateMainState({ teeSheetTournaments: tournamentRes.status === StatusCode.OK ? tournamentRes.data : [] });
    } else {
      updateMainState({ teeSheetTournaments: [] });
    }
  }

  async function loadTeeSheetLeagues(ids: Array<number>) {
    if (ids?.length > 0) {
      const leagueRes = await GetLeague({ ids: ids }, false);
      updateMainState({ teeSheetLeagues: leagueRes.status == StatusCode.OK ? leagueRes.data : [] });
    } else {
      updateMainState({ teeSheetLeagues: [] });
    }
  }

  async function loadShotgunTemplates() {
    const shotgunTemplateRes = await GetShotGunTemplate(null, false);
    if (shotgunTemplateRes.status !== StatusCode.OK) {
      return;
    }
    updateShotgunTemplateState({ shotgunTemplates: shotgunTemplateRes.data });
  }

  async function loadShotgunTournaments(token?: CancelToken) {
    const tournamentRes = await GetTournament({ search: shotgunTemplateState.tournamentQuery }, false, token);

    if (token && token.reason) {
      return;
    }
    if (tournamentRes.status !== StatusCode.OK) {
      return;
    }

    updateShotgunTemplateState({ tournaments: tournamentRes.data });
  }

  async function loadCustomerTypes() {
    const res = await GetCustomerType({ application: "green_fee" }, true);
    if (res.status !== StatusCode.OK) {
      return;
    }

    updateMainState({ customerTypes: res.data });
  }

  function findCustomerTypeBackground(bookingParticipant: any) {
    let color = "#FFFFFF";

    if (bookingParticipant.check_in_status === "checked") {
      color = "#82d195";
    } else if (bookingParticipant.no_show) {
      color = "#d62828";
    } else if (bookingParticipant.no_show_weather) {
      color = "#fcbf49";
    } else if (bookingParticipant.turn) {
      color = "#e7e7e7";
    } else if (bookingParticipant.customer) {
      const customerType = state.customerTypes.find((customerType: any) => {
        return customerType.id === bookingParticipant.customer.customer_type_id;
      });

      if (customerType) {
        color = customerType.color;
      }
    }

    return color;
  }

  async function loadShotgunLeagues(token?: CancelToken) {
    const leagueRes = await GetLeague({ search: shotgunTemplateState.leagueQuery }, false, token);

    if (token && token.reason) {
      return;
    }
    if (leagueRes.status !== StatusCode.OK) {
      return;
    }
    updateShotgunTemplateState({ leagues: leagueRes.data });
  }

  function updateShotgunTemplateState(newShotgunTemplateState: Partial<IShotGunTemplateState>) {
    setShotgunTemplateState((cur: IShotGunTemplateState) => {
      return { ...cur, ...newShotgunTemplateState };
    });
  }

  async function openTeeTimeSheet(teetime: ITeeTimeDraggable, startingTab?: number) {
    updateMainState({
      teetimePopupVisible: true,
      teetimeStartTime: teetime.start_time,
      teetimeId: teetime.id,
      teetime: teetime,
      booking_categories_loading: true,
    });

    updateShotgunTemplateState({ start_time: teetime.start_time, tee_sheet_id: teetime.tee_sheet_id });

    if (startingTab) {
      handleTabChange(startingTab);
    }

    const pricingRes = await GetTeeTimePricing({ tee_time_id: teetime.id }, false);

    if (pricingRes.status !== StatusCode.OK) {
      uiActions.showError(pricingRes.message);
    }

    const auditTrailRes = await GetTeeTimeAuditTrails({ tee_time_id: teetime.id }, true);

    if (auditTrailRes.status !== StatusCode.OK) {
      uiActions.showError(auditTrailRes.data.message);
    }

    updateMainState({ auditTrails: auditTrailRes.data });

    updateMainState({
      booking_categories: pricingRes?.data?.booking_categories ? pricingRes.data.booking_categories : [],
      booking_categories_loading: false,
    });
  }

  async function openDetailsSheet(teetime: ITeeTimeDraggable) {
    updateMainState({
      detailsModalVisible: true,
      teetimeStartTime: teetime.start_time,
      teetimeId: teetime.id,
      teetime: teetime,
      booking_categories_loading: true,
    });

    const pricingRes = await GetTeeTimePricing({ tee_time_id: teetime.id }, false);

    if (pricingRes.status !== StatusCode.OK) {
      uiActions.showError(pricingRes.message);
    }

    updateMainState({
      booking_categories: pricingRes?.data?.booking_categories ? pricingRes.data.booking_categories : [],
      booking_categories_loading: false,
    });
  }

  async function openAuditTrailSheet(teetime: ITeeTimeDraggable) {
    updateMainState({
      teetimeStartTime: teetime.start_time,
      teetimeId: teetime.id,
      teetime: teetime,
      auditTrailModalVisible: true,
      audit_trails_loading: true,
    });

    const auditTrailRes = await GetTeeTimeAuditTrails({ tee_time_id: teetime.id }, false);

    if (auditTrailRes.status !== StatusCode.OK) {
      uiActions.showError(auditTrailRes.data.message);
    }

    updateMainState({ auditTrails: auditTrailRes.data, audit_trails_loading: false });
  }

  async function openBookingAuditTrailSheet(booking: IBooking) {
    updateMainState({
      bookingAuditTrailModalVisible: true,
      audit_trails_loading: true,
    });

    const auditTrailRes = await GetAuditTrails({ tee_time_booking_id: booking.id }, false);

    if (auditTrailRes.status !== StatusCode.OK) {
      uiActions.showError(auditTrailRes.data.message);
    }

    updateMainState({ auditTrails: auditTrailRes.data, audit_trails_loading: false });
  }

  async function openShotgunSheet(teetime: ITeeTimeDraggable) {
    updateMainState({
      teetimeStartTime: teetime.start_time,
      teetimeId: teetime.id,
      teetime: teetime,
      shotgunModalVisible: true,
    });

    const bookingCategoryRes = await GetBookingCategory(null, true);

    if (bookingCategoryRes.status !== StatusCode.OK) {
      uiActions.showError("Error loading booking categories");
    }

    updateShotgunTemplateState({
      start_time: teetime.start_time,
      tee_sheet_id: teetime.tee_sheet_id,
      booking_categories: bookingCategoryRes?.data,
    });
  }

  function handleShotgunDropdownChange(value: number, property: string) {
    if ((property === "tournament_id" || property === "league_id") && value === -1) {
      value = undefined;
    }
    updateShotgunTemplateState({ [property]: value });
  }

  function handleAddTeeTimeInputChange(event: any) {
    const { id, value } = event.target;
    setAddTeeTimeState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleShotgunTimePicker(value: string, property: string) {
    updateShotgunTemplateState({ [property]: value });
  }

  function handleBookingCategoryChange(event: any) {
    const value = parseInt(event?.target?.value);
    if (isNaN(value)) {
      return;
    }

    if (
      shotgunTemplateState.booking_category_ids.find(bookingCategoryId => bookingCategoryId === value) === undefined
    ) {
      setShotgunTemplateState(prevState => ({
        ...prevState,
        booking_category_ids: [...prevState.booking_category_ids, value],
      }));
    } else {
      const updatedBookingCategoryIds = shotgunTemplateState.booking_category_ids.filter(
        bookingCategoryId => bookingCategoryId !== value,
      );
      setShotgunTemplateState(prevState => ({ ...prevState, booking_category_ids: updatedBookingCategoryIds }));
    }
  }

  // function handleTeeSlotSelect(teetime: any) {
  //   if (teetime.blocked_type === "crossover") {
  //     return;
  //   }

  //   openTeeTimeSheet(teetime);
  // }

  async function applyShotgunTemplate() {
    const durationTime = shotgunTemplateState.duration;
    const durationSplit = durationTime.split(":");
    const durationMins = +durationSplit[0] * 60 + +durationSplit[1];
    const leadTime = shotgunTemplateState.lead_time;
    const leadTimeSplit = leadTime.split(":");
    const leadTimeMins = +leadTimeSplit[0] * 60 + +leadTimeSplit[1];

    const params = {
      tee_sheet_id: shotgunTemplateState.tee_sheet_id,
      start_time: shotgunTemplateState.start_time,
      duration: durationMins,
      lead_time: leadTimeMins,
      shotgun_template_id: shotgunTemplateState.shotgun_template_id,
      division_id: shotgunTemplateState.division_id,
      turn_division_id: shotgunTemplateState.turn_division_id,
      tournament_id: shotgunTemplateState.tournament_id,
      league_id: shotgunTemplateState.league_id,
      booking_category_ids: shotgunTemplateState.booking_category_ids,
    };

    const applyShotgunTemplateRes = await PutShotGunTemplateApply(params, true);
    if (applyShotgunTemplateRes.status !== StatusCode.OK) {
      uiActions.showError(t("elements.tee_sheet.tee_sheet_tabs.052"));
      return;
    }
    uiActions.showSuccess(t("elements.tee_sheet.tee_sheet_tabs.053"));
    updateMainState({ teetimePopupVisible: false, shotgunModalVisible: false });

    updateShotgunTemplateState({
      tee_sheet_id: null,
      start_time: "",
      duration: "",
      lead_time: "",
      shotgun_template_id: null,
      division_id: null,
      turn_division_id: null,
      tournament_id: undefined,
      league_id: undefined,
    });

    setSelected(0);
    reloadTeeTimes();
  }

  function selectTeeTime(teetime: any) {
    updateMainState({
      allowScroll: false,
      teetimeId: teetime.id,
      teetimeStartTime: teetime.start_time,
      selectedTeeTime: teetime,
    });

    void handleNewBookingModalOpen(teetime);
  }

  // scroll to
  // derive the offsetTime by using the current time - 1 hour

  const tr = timeToNumber();
  const offsetTime = teeSheetStore.teetimes
    ?.filter(teetime => teetime.division_id === division_id)
    .filter(teetime => {
      const time = teetime.start_time.replace(":", "");
      if (parseInt(time) <= tr) {
        return true;
      }
      return false;
    })
    .filter((teetime, i, teetimes) => {
      if (i === teetimes.length - 8 /*magic number, 1 hour is approximately 8 indexes*/) {
        return true;
      }
      return false;
    });

  // give each element a ref
  const elementRefs = teeSheetStore.teetimes
    ?.filter(teetime => teetime.division_id === division_id)
    .reduce((acc: Record<string, any>, value) => {
      acc[value.id] = React.createRef();
      return acc;
    }, {});

  const scroll = (key: string) => {
    elementRefs[key]?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  useEffect(() => {
    const deepCloneTeetimes = cloneDeep(teeSheetStore.teetimes);

    const slotGroups: TDraggableSlots = deepCloneTeetimes?.map((teetime, rowIndex) => {
      return Object.values(
        groupBy(deepCloneTeetimes[rowIndex].slots, s =>
          s.tee_time_booking_id === null ? s.id : s.tee_time_booking_id,
        ),
      );
    });

    //potentially sort the array here instead of TeeSheetTabs
    const teetimesDraggable: ITeeTimeDraggable[] = deepCloneTeetimes
      ?.map((teetime, rowIndex) => {
        teetime.slots.splice(0); // removes the contents of slots returns empty array
        return teetime as unknown as ITeeTimeDraggable;
      })
      .map((teetime, rowIndex) => {
        teetime.slots.push(...slotGroups[rowIndex]); // pushes slotGroups to empty array
        return teetime;
      });

    if (teetimesDraggable) {
      updateMainState({ teetimesDraggable });
    }
  }, [teeSheetStore.teetimes /* currentCourseId */]);

  useEffect(() => {
    if (state.teetimesDraggable) {
      //display loader
      updateMainState({ teeSheetTournaments: undefined, teeSheetLeagues: undefined });
      const teeTimes = [...state?.teetimesDraggable];
      // Load tee sheet tournaments and leagues if they exist
      const tournamentIds = teeTimes
        ?.filter(filteredTeeTime => filteredTeeTime?.tournament_id)
        ?.map(teetime => {
          if (teetime.tournament_id) {
            return teetime.tournament_id;
          }
        });
      void loadTeeSheetTournaments([...new Set(tournamentIds)]);

      const leagueIds = teeTimes
        ?.filter(filteredTeeTime => filteredTeeTime?.league_id)
        ?.map(teetime => {
          if (teetime.league_id) {
            return teetime.league_id;
          }
        });
      void loadTeeSheetLeagues([...new Set(leagueIds)]);
    }
  }, [state.teetimesDraggable]);

  useEffect(() => {
    if (formatDate(teeSheetStore.selectedDate) === formatDate(new Date())) {
      if (state.allowScroll) {
        if (offsetTime) {
          scroll(`${offsetTime[0]?.id}`);
        }
      }
    } else {
      if (elementRefs) {
        const keys = Object.keys(elementRefs);
        keys.forEach((key, i) => {
          if (i === 0) {
            if (state.allowScroll) {
              scroll(key);
            }
          }
        });
      }
    }
  }, [teeSheetStore?.teetimes]);

  useEffect(() => {
    void loadShotgunTemplates();
    void loadCustomerTypes();
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();

    void loadShotgunTournaments(source.token);

    return () => source.cancel();
  }, [shotgunTemplateState.tournamentQuery]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    void loadShotgunLeagues(source.token);

    return () => source.cancel();
  }, [shotgunTemplateState.leagueQuery]);

  // Handle tab change
  const [selected, setSelected] = useState(0);
  const handleTabChange = (selectedTabIndex: number) => {
    setSelected(selectedTabIndex);
  };

  function handleClosePopup() {
    updateMainState({
      teeTimeBlockedPopupVisible: false,
      teetimePopupVisible: false,
      teetimeLockPopupVisible: false,
      auditTrails: [],
      teetimeId: null,
      teetimeStartTime: "",
    });
    updateShotgunTemplateState({
      tee_sheet_id: null,
      start_time: "",
      duration: "",
      lead_time: "",
      shotgun_template_id: null,
      division_id: null,
      turn_division_id: null,
      tournament_id: undefined,
      league_id: undefined,
    });
    setSelected(0);
  }

  const handleTeetimeSqueeze = async () => {
    const res = await PostTeeTimeSqueeze(
      {
        tee_time_id: state.teetimeId,
      },
      true,
    );

    if (res.status === StatusCode.OK) {
      reloadTeeTimes();

      // success message
      uiActions.showSuccess(t("elements.tee_sheet.tee_sheet_tabs.054"));

      // close modal
      handleClosePopup();
      updateMainState({ squeezeTimePopupVisible: false });
    } else {
      // error message
      uiActions.showError(t("elements.tee_sheet.tee_sheet_tabs.055"));
    }
  };

  function handleTeeTimeSqueezePopup(teetime: any) {
    updateMainState({
      squeezeTimePopupVisible: true,
      teetime: teetime,
      teetimeId: teetime.id,
    });
  }

  const tabs = [
    {
      id: "all-details-1",
      content: t("elements.tee_sheet.tee_sheet_tabs.001"),
      panelID: "all-details-content-1",
    },

    {
      id: "audit-trail-1",
      content: t("elements.tee_sheet.tee_sheet_tabs.002"),
      panelID: "audit-trail-content-1",
    },

    {
      id: "shotgun-templates",
      content: t("elements.tee_sheet.tee_sheet_tabs.003"),
      panelID: "shotgun-templates-content",
    },
  ];

  const secondaryTabs = [
    {
      id: "teetime-squeeze",
      icon: "copy",
      onSelect: handleTeetimeSqueeze,
    },
  ];

  const handleNavigateToBookingDetails = (teetime_id: number, slot: ISlot) => {
    // console.log("Entered navigate to booking details");
    // const teeTimeRes = await GetTeeTime({ id: teetime_id }, true);
    // if (teeTimeRes.status !== StatusCode.OK) {
    //   uiActions.showError(teeTimeRes.message);
    //   return;
    // }
    // console.log("Booking details get tee time successfull");
    //
    // //Check if another user has cancelled the bookings for the current tee time. If there are no bookings left and every slot
    // //is available, refresh the tee sheet
    // if (teeTimeRes.data[0]?.quantity_remaining === 4) {
    //   teeSheetActions.loadTeetimes(teeSheetStore.selectedDate);
    //   uiActions.showError("There are no bookings available for this tee time.");
    //   return;
    // }
    //
    if (slot.booking_participant?.turn === true) {
      return;
    }

    history.push(`/admin/teesheet/details/${teetime_id}`);
  };

  function handleOnDragStart(result: any) {
    // console.log("Entered start Drag and drop");
    //obtain necessary starting_slot and booking_length when drag-n-drop starts
    const sourceRowId = Number(result.source.droppableId);
    const tempArray = state.teetimesDraggable;
    const activeTee = tempArray.filter((teetime: { division_id: number }) => teetime.division_id === division_id);

    const movingSlots: ISlot[] = [];
    activeTee[sourceRowId].slots.map(slotGroup => {
      slotGroup.map(slot => {
        if (slot.tee_time_booking_id === bookingState.booking_id) {
          movingSlots.push(slot);
        }
      });
    });

    const teePositions = movingSlots.map(tee => {
      return tee.position;
    });
    const startingSplice = Math.min(...teePositions);

    updateBookingState({
      starting_slot: startingSplice - 1,
      move_booking_length: movingSlots.length,
    });
  }

  function handleOnDragEnd(result: any) {
    // console.log("Entered end drag and drop");
    updateBookingState({ teetimeToolTipDisabled: false }); //add tooltip back after dragging

    if (!result.destination || result.source.droppableId === result.destination.droppableId) {
      return;
    }

    const sourceRowId = Number(result.source.droppableId);
    const destRowId = Number(result.destination.droppableId);

    const tempArray = state.teetimesDraggable;
    const activeTee = tempArray.filter(teetime => teetime.division_id === division_id);

    activeTee.map((teetime, teeIndex) => {
      if (teeIndex === destRowId) {
        //blocked_type switches based on front vs back
        if (teetime.blocked_type === null || teetime.blocked_type === "open") {
          //Determine freeslots at destination and # of free slots
          const freeSlots: ISlot[] = [];
          activeTee[destRowId].slots.map(slotGroup => {
            slotGroup.map((slot, slotIndex) => {
              if (slot.status === "available") {
                freeSlots.push(...slotGroup);
              }
            });
          });

          const destTeeId = activeTee[destRowId].id; //PutMoveBooking param

          if (bookingState.move_booking_length <= freeSlots.length) {
            //splice each booking into an array when it matches the booking_ID
            const sourceSlots: ISlot[] = [];
            activeTee[sourceRowId].slots.map((slotGroup, groupIndex: number) => {
              if (
                slotGroup[bookingState.slot_index]?.tee_time_booking_id === bookingState.booking_id &&
                slotGroup[bookingState.slot_index] != undefined
              ) {
                sourceSlots.push(...slotGroup);
                activeTee[sourceRowId].slots.splice(groupIndex, 1);
              }
            });

            //splice available slots into an array (from end of row) -- size = sourceSlots.length
            const destSlots: ISlot[][] = [];
            for (let i = 1; i <= sourceSlots.length; i++) {
              activeTee[destRowId].slots[i].map(slot => {
                if (slot.status === "available") {
                  destSlots.push([slot]);
                }
              });
            }
            activeTee[destRowId].slots.splice(-sourceSlots.length, sourceSlots.length);

            //push slots into correct teetime rows
            activeTee[sourceRowId].slots.push(...destSlots);
            activeTee[destRowId].slots.push(sourceSlots);

            //sort updated arrays by availability (Necessary)
            activeTee[sourceRowId].slots.sort(arrayAvailabilitySorter);
            activeTee[destRowId].slots.sort(arrayAvailabilitySorter);

            //update state and database, return the array
            void PutMoveBooking({ booking_id: bookingState.booking_id, tee_time_id: destTeeId }, false);
            // setTeeState(tempArray);
            updateMainState({ teetimesDraggable: tempArray });
            // teeSheetActions.loadTeetimes(selectedDate);
          }
        }
        //Back
        if (teetime.blocked_type != null) {
          const arrayOffset = activeTee.length;
          const freeSlots: ISlot[] = [];
          activeTee[destRowId + arrayOffset].slots.map((slotGroup, groupIndex) => {
            slotGroup.map(slot => {
              if (slot.status === "available") {
                freeSlots.push(...slotGroup);
              }
            });
          });

          const destTeeId = activeTee[destRowId + arrayOffset].id; //PutMoveBooking param

          if (bookingState.move_booking_length <= freeSlots.length) {
            //splice each booking into an array when it matches the booking_ID
            const sourceSlots: ISlot[] = [];
            activeTee[sourceRowId + arrayOffset].slots.map((slotGroup, groupIndex: number) => {
              if (
                slotGroup[bookingState.slot_index]?.tee_time_booking_id === bookingState.booking_id &&
                slotGroup[bookingState.slot_index] != undefined
              ) {
                sourceSlots.push(...slotGroup);
                activeTee[sourceRowId + arrayOffset].slots.splice(groupIndex, 1);
              }
            });

            //splice available slots into an array (from end of row) -- size = sourceSlots.length
            const destSlots: ISlot[][] = [];
            for (let i = 1; i <= sourceSlots.length; i++) {
              activeTee[destRowId + arrayOffset].slots[i].map(slot => {
                if (slot.status === "available") {
                  destSlots.push([slot]);
                }
              });
            }
            activeTee[destRowId + arrayOffset].slots.splice(-sourceSlots.length, sourceSlots.length);

            //push slots into correct teetime rows
            activeTee[sourceRowId + arrayOffset].slots.push(...destSlots);
            activeTee[destRowId + arrayOffset].slots.push(sourceSlots);

            //sort updated arrays by availability (Necessary)
            activeTee[sourceRowId + arrayOffset].slots.sort(arrayAvailabilitySorter);
            activeTee[destRowId + arrayOffset].slots.sort(arrayAvailabilitySorter);

            //update state and database, return the array
            void PutMoveBooking({ booking_id: bookingState.booking_id, tee_time_id: destTeeId }, false);
            // setTeeState(tempArray);
            updateMainState({ teetimesDraggable: tempArray });

            // teeSheetActions.loadTeetimes(selectedDate);
          }
        }
      }
    });
  }

  function handleBlockedTeeTimeView() {
    updateMainState({
      teeTimeBlockedPopupVisible: false,
    });

    void selectTeeTime(state.selectedTeeTime);
  }

  function handleTeeTimeBookedPopUpRefresh() {
    reloadTeeTimes();
    updateMainState({ teetimeBookedPopupVisible: false });
  }

  function checkBlockedTeeTime(teeTime: Record<string, any>) {
    if (teeTime.blocked_type === "shotgun_blocked" || teeTime.blocked_type === "blocked") {
      updateMainState({ teeTimeBlockedPopupVisible: true, selectedTeeTime: teeTime });
      return;
    } else {
      void selectTeeTime(teeTime);
    }
  }

  // Tee Time Locks
  async function handleNewBookingModalOpen(tee_time: ITeeTime) {
    const teetimeRes = await GetTeeTime(
      {
        id: tee_time.id,
        extended: true,
        "extended-bookings": true,
        locks: true,
        turn_tee_time: true,
      },
      true,
    );

    if (teetimeRes.status !== StatusCode.OK) {
      return;
    }
    const updatedTeeTime = teetimeRes.data[0];
    teeSheetActions.selectTeeTimeSuccess(updatedTeeTime);
    if (updatedTeeTime.quantity_remaining === 0) {
      updateMainState({ teetimeBookedPopupVisible: true });
      return;
    }

    const teetimeLockRes = await GetTeeTimeLock({ tee_time_id: updatedTeeTime.id }, true);

    // Tee time is locked by another user
    if (
      teetimeLockRes.status !== StatusCode.OK ||
      (teetimeLockRes.data.data !== null && teetimeLockRes.data.user_id !== authStore.user.id)
    ) {
      updateMainState({ teetimeLockPopupVisible: true });
      return;
    }

    // If no lock then post new lock
    const postLockRes = await PostTeeTimeLock({ tee_time_id: updatedTeeTime.id }, true);
    if (postLockRes.status !== StatusCode.OK) {
      return;
    }

    updateMainState({ selectedTeeTime: null });
    uiActions.initiateNewBooking();
    return;
  }

  function arrayAvailabilitySorter(
    first: { tee_time_booking_id: number; status: string }[],
    second: { tee_time_booking_id: number; status: string }[],
  ) {
    //sort by status, then bookingID
    if (first[0].status < second[0].status) {
      return 1;
    }
    if (first[0].status > second[0].status) {
      return -1;
    }

    if (first[0].tee_time_booking_id > second[0].tee_time_booking_id) {
      return 1;
    }
    if (first[0].tee_time_booking_id < second[0].tee_time_booking_id) {
      return -1;
    }

    return 0;
  }

  function handleOnMouseDown(bookingId: number, slotIndex: number, groupIndex: number) {
    //capture necessary attributes of chosen drag-and-drop
    if (bookingId === null) {
      return; //isDragDisabled for NULL & 0 bookingID's
    }

    updateBookingState({
      booking_id: bookingId,
      slot_index: slotIndex,
      group_index: groupIndex,
      teetimeToolTipDisabled: true, //disable TeeSheetTabs popup when selecting item for DnD
    });
  }

  async function handleMouseEnter(
    teeTime: ITeeTimeDraggable,
    token?: CancelToken,
  ): Promise<ITeeTimeToolTipPricing | undefined> {
    const pricingRes = await GetTeeTimePricing({ tee_time_id: teeTime.id }, false, token);

    if ((token && token.reason) || typeof pricingRes.data === "string") {
      return undefined;
    }

    if (pricingRes.status !== StatusCode.OK) {
      dispatch(showError(pricingRes.message));
      return {
        booking_fee: undefined,
        green_fees: [],
        booking_categories: [],
      };
    }
    console.log("Entered tool tip success code");
    return pricingRes.data as ITeeTimeToolTipPricing;
  }

  function tournamentName(id: number) {
    const tournament = state.teeSheetTournaments?.find(tournament => tournament.id === id);

    return tournament !== undefined ? tournament.name : t("elements.tee_sheet.tee_sheet_tabs.026");
  }

  function leagueName(id: number) {
    const league = state.teeSheetLeagues?.find(league => league.id === id);

    return league !== undefined ? league.name : t("elements.tee_sheet.tee_sheet_tabs.025");
  }

  async function printTournamentCartSigns(date: string, eventId: number) {
    const cartSignsRes = await GetPrintCartSigns(
      {
        date: date,
        tournament_id: eventId,
      },
      true,
    );

    if (cartSignsRes.status !== StatusCode.OK) {
      uiActions.showError(t("elements.tee_sheet.tee_sheet_tabs.056"));
      return;
    }

    window.open().document.write(cartSignsRes.data);
  }

  async function printLeagueCartSigns(date: string, eventId: number) {
    const cartSignsRes = await GetPrintCartSigns(
      {
        date: date,
        league_id: eventId,
      },
      true,
    );

    if (cartSignsRes.status !== StatusCode.OK) {
      uiActions.showError(t("elements.tee_sheet.tee_sheet_tabs.056"));
      return;
    }

    window.open().document.write(cartSignsRes.data);
  }

  function tournamentNote(id: number) {
    const tournament = shotgunTemplateState.tournaments.find(tournament => tournament.id === id);

    return tournament !== undefined ? tournament.tee_sheet_note : "";
  }

  function navigateToLeagueAccount(id: number) {
    const league = shotgunTemplateState.leagues.find(league => league.id === id);

    history.push(`/admin/settings/accounts/overview/${league.account_id}`);
  }

  function navigateToTournamentAccount(id: number) {
    const tournament = shotgunTemplateState.tournaments.find(tournament => tournament.id === id);

    history.push(`/admin/settings/accounts/overview/${tournament.account_id}`);
  }

  const renderBookingParticipantIcons = (slot: ISlot) => {
    return (
      <>
        {slot.booking_participant.check_in_status === "checked" && (
          <FontAwesomeIcon icon={["fas", "check-circle"]} className="booking-participant_icon" />
        )}

        {slot.booking_participant.check_in_status !== "checked" &&
          slot.booking_participant.no_show !== true &&
          slot.booking_participant.no_show_weather !== true && (
            <FontAwesomeIcon icon={["far", "circle"]} className="booking-participant_icon" />
          )}

        {slot.booking_participant.turn === true && (
          <FontAwesomeIcon icon={["far", "repeat-alt"]} className="booking-participant_icon" />
        )}

        {slot.booking_participant.order_financial_status === "paid" && (
          <FontAwesomeIcon icon={["fas", "usd-circle"]} className="booking-participant_icon" />
        )}

        {slot.booking_participant.no_show === true && (
          <FontAwesomeIcon icon={["far", "ban"]} className="booking-participant_icon" />
        )}

        {slot.booking_participant.no_show_weather === true && (
          <FontAwesomeIcon icon={["far", "thunderstorm"]} className="booking-participant_icon" />
        )}

        <span className="booking-participant-holes-desktop">{slot.booking.holes}</span>
      </>
    );
  };

  function handleRemoveShotgunTemplate(teeTime?: ITeeTimeDraggable) {
    setState(prevState => ({ ...prevState, removeShotGunPopup: true, teetime: teeTime }));
  }

  async function handleExportShotgunTemplate(teeTime?: ITeeTimeDraggable) {
    const params = {
      shotgun_application_id: teeTime.shotgun_application_id,
    };

    const exportRes = await ExportShotgunTemplate(params, true);

    if (exportRes.status !== StatusCode.OK) {
      props.uiActions.showError("Error exporting shotgun template");
      return;
    }

    const templateExport = document.createElement("a");
    templateExport.href = "data:text/csv;charset=utf-8," + encodeURI(exportRes.data);
    templateExport.target = "_blank";
    templateExport.download = `${"Shotgun_Template"}_${moment(new Date()).format("YYYY-MM-DD").toString()}`
      .replace(/ /g, "_")
      .toLowerCase()
      .concat(".csv");
    templateExport.click();
  }

  async function removeShotgunTemplate() {
    const params = {
      tee_sheet_id: state.teetime.tee_sheet_id,
      tournament_id: state.teetime.tournament_id ? state.teetime.tournament_id : null,
      league_id: state.teetime.league_id ? state.teetime.league_id : null,
      shotgun_application_id: state.teetime.shotgun_application_id,
    };

    const removeRes = await RemoveShotgunTemplate(params, true);

    if (removeRes.status !== StatusCode.OK) {
      uiActions.showError(t("elements.tee_sheet.tee_sheet_tabs.057"));
      return;
    }

    uiActions.showSuccess(t("elements.tee_sheet.tee_sheet_tabs.058"));

    setState(prevState => ({
      ...prevState,
      removeShotGunPopup: false,
      teetimePopupVisible: false,
    }));

    reloadTeeTimes();
  }

  function handleAddTeeTimePopup(teeTime?: ITeeTimeDraggable) {
    setState(prevState => ({ ...prevState, teetime: teeTime }));
    setAddTeeTimeState(prevState => ({
      ...prevState,
      addTeeTimePopup: true,
      start_time: teeTime.start_time,
      start_hole: teeTime.start_hole,
    }));
  }

  function handleRemoveTeeTimePopup(teeTime?: ITeeTimeDraggable) {
    setState(prevState => ({ ...prevState, teetime: teeTime }));
    setAddTeeTimeState(prevState => ({ ...prevState, removeTeeTimePopup: true }));
  }

  async function addTeeTime() {
    const params = {
      tee_time_id: state.teetime.id,
      start_hole: addTeeTimeState.start_hole,
      start_time: addTeeTimeState.start_time,
    };

    const addRes = await PostTeeTime(params, true);

    if (addRes.status !== StatusCode.OK) {
      uiActions.showError("Error adding tee time");
      return;
    }

    uiActions.showSuccess("Successfully added tee time");

    setAddTeeTimeState(prevState => ({ ...prevState, start_hole: "", addTeeTimePopup: false }));

    reloadTeeTimes();
  }

  async function removeTeeTime() {
    const params = {
      tee_time_id: state.teetime.id,
    };

    const removeRes = await DeleteTeeTime(params, true);

    if (removeRes.status !== StatusCode.OK) {
      uiActions.showError("Error removing shotgun tee time");
      return;
    }

    uiActions.showSuccess("Successfully removed shotgun tee time");

    setAddTeeTimeState(prevState => ({ ...prevState, removeTeeTimePopup: false }));

    reloadTeeTimes();
  }

  async function cancelBooking() {
    const cancelRes = await PutCancelBooking(
      { tee_time_booking_id: Number(rightClickMenuState?.selectedSlot?.booking?.id) },
      true,
    );
    if (cancelRes?.status !== StatusCode.OK) {
      uiActions.showError(t("elements.tee_sheet.tee_sheet_tabs.059"));
      return;
    }
    ReactDOM.unstable_batchedUpdates(() => {
      updateMainState({ allowScroll: false });
      setRightClickMenuState(prevState => ({ ...prevState, selectedSlot: null, cancelBookingPopup: false }));
    });
    uiActions.showSuccess(t("elements.tee_sheet.tee_sheet_tabs.060"));
    reloadTeeTimes();
  }

  async function cancelBookingParticipant() {
    const cancelRes = await PutCancelParticipant(
      { id: rightClickMenuState?.selectedSlot?.booking_participant?.id },
      true,
    );
    if (cancelRes.status !== StatusCode.OK) {
      uiActions.showError(t("elements.tee_sheet.tee_sheet_tabs.061"));
      return;
    }
    ReactDOM.unstable_batchedUpdates(() => {
      updateMainState({ allowScroll: false });
      setRightClickMenuState(prevState => ({ ...prevState, selectedSlot: null, cancelPlayerPopup: false }));
    });
    uiActions.showSuccess(t("elements.tee_sheet.tee_sheet_tabs.125"));
    reloadTeeTimes();
  }

  async function setBlockedType(teetime: any, type: string) {
    teetime.blocked_type = type;

    const teeTimeRes = await PutTeeTime({ tee_times: [teetime] }, true);

    if (teeTimeRes.status !== StatusCode.OK) {
      uiActions.showError(t("elements.tee_sheet.tee_sheet_tabs.062"));
      return;
    }

    setRightClickMenuState(prevState => ({ ...prevState, selectedSlot: null }));
  }

  async function handleEditBooking(booking: IBooking, teetime: any) {
    const bookingRes = await GetBooking({ id: booking.id }, true);

    //Check if selected booking has been cancelled by another user
    if (bookingRes.status !== StatusCode.OK || bookingRes.data[0]?.status === "cancelled") {
      reloadTeeTimes();
      uiActions.showError(t("elements.tee_sheet.tee_sheet_tabs.063"));
      return;
    }

    // teeSheetActions.selectTeeTime(teeSheetStore.selectedTeeTime.id, false);

    await dispatch(selectNewTeeTime(booking.tee_time_id, true));

    // Tee Time Locks
    const teetimeLockRes = await GetTeeTimeLock({ tee_time_id: booking.tee_time_id }, true);

    // Tee time is locked by another user
    if (
      teetimeLockRes.status !== StatusCode.OK ||
      (teetimeLockRes.data.data !== null && teetimeLockRes.data.user_id !== authStore.user.id)
    ) {
      updateMainState({ teeTimeLock: true });
      uiActions.showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.005"));
    } else {
      const postLockRes = await PostTeeTimeLock({ tee_time_id: booking.tee_time_id }, true);
      if (postLockRes.status !== StatusCode.OK) {
        return;
      }
    }

    updateMainState({ selectedBooking: bookingRes.data[0], editingBooking: true });

    uiActions.initiateEditBooking();
  }

  useEffect(() => {
    if (!uiStore.editBookingActive && state.editingBooking) {
      updateMainState({ teeTimeLock: false, editingBooking: false });
      //Reset selected tee time
      dispatch(selectTeeTimeSuccess(null));
      reloadTeeTimes();
    }
  }, [uiStore.editBookingActive]);

  function getTeeTimeType(teetime: any) {
    if (!teetime) {
      return "Standard";
    }

    if (teetime.league_id) {
      return "League";
    } else if (teetime.tournament_id) {
      return "Tournament";
    } else if (teetime.shotgun_application_id) {
      return "Shotgun";
    } else {
      return "Standard";
    }
  }

  function checkForCheckedInPlayer(slotGroup: ISlot[]) {
    const checkPlayers = slotGroup?.filter(slot => slot.booking_participant?.check_in_status !== "unchecked");
    if (checkPlayers.length > 0) {
      return true;
    }
    return false;
  }

  function handleOpenEditStartHole(booking: IBooking, teetime: any) {
    console.log(booking);
    console.log(teetime);

    setState(prevState => ({
      ...prevState,
      editShotgunStartHoleVisible: true,
      shotgun_start_hole: teetime.start_hole,
      shotgun_teetime_id: teetime.id,
    }));
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setState(prevState => ({ ...prevState, shotgun_start_hole: value }));
  }

  function setNoShowType(type: string) {
    setRightClickMenuState(prevState => ({ ...prevState, noShow: { ...prevState.noShow, type: type } }));
  }

  async function handleNoShow() {
    const participantId = rightClickMenuState?.noShow?.slot?.booking_participant?.id;
    const noShowType = rightClickMenuState?.noShow?.type;
    if (participantId && (noShowType === "charge" || noShowType === "defer" || noShowType === "weather")) {
      const putBookingParticipantRes = await PutBookingParticipantNoShow(
        {
          id: participantId,
          no_show_type: noShowType,
        },
        true,
      );

      if (putBookingParticipantRes.status !== StatusCode.OK) {
        uiActions.showError(
          typeof putBookingParticipantRes?.data === "string"
            ? putBookingParticipantRes?.data
            : t("elements.tee_sheet.tee_sheet_tabs.064") + valueToString(noShowType),
        );
        return;
      }

      uiActions.showSuccess(t("elements.tee_sheet.tee_sheet_tabs.065") + valueToString(noShowType));

      ReactDOM.unstable_batchedUpdates(() => {
        updateMainState({ allowScroll: false });
        setRightClickMenuState(prevState => ({ ...prevState, noShow: { popup: false, slot: null, type: "charge" } }));
        reloadTeeTimes();
      });
    } else {
      console.log(
        "%cTeeSheetTabs.tsx line:1259 participantId or noShowType is not set",
        "color: #007acc;",
        participantId,
        noShowType,
      );
    }
  }

  function reloadTeeTimes() {
    // -1 division_id is `combined` teesheet
    if (division_id === -1) {
      teeSheetActions.loadTeetimes(
        teeSheetStore.selectedDate,
        props.course_id,
        null,
        courseDivisions.map(division => division.id),
      );
    } else {
      teeSheetActions.loadTeetimes(teeSheetStore.selectedDate, props.course_id, props.division_id);
    }
  }

  const renderTeeSheetTabs = () => {
    const sunsetSplitTime: string[] = facilityStore?.weather?.sunset.split(":");
    const sunsetAdjustedTime = sunsetSplitTime
      ? `${(Number(sunsetSplitTime[0]) - 4).toString()}:${sunsetSplitTime[1]}`
      : undefined;

    return (
      <>
        {teeSheetStore.teetimes?.length < 1 ? (
          <Callout
            type="error"
            title={t("elements.tee_sheet.tee_sheet_tabs.020")}
            content={t("elements.tee_sheet.tee_sheet_tabs.021")}
            actions={{
              primary: {
                label: t("elements.tee_sheet.tee_sheet_tabs.022"),
                onClick: () => history.push(`/admin/settings/tee-sheet/calendar`),
              },
            }}
          />
        ) : null}

        {!props.courseDivisions ||
          (props.courseDivisions?.length === 0 && (
            <Callout
              type="error"
              title={t("elements.tee_sheet.tee_sheet_tabs.023")}
              content={t("elements.tee_sheet.tee_sheet_tabs.024")}
            />
          ))}

        <DragDropContext onDragStart={handleOnDragStart} onDragEnd={handleOnDragEnd}>
          <div className="dragDropContext_container">
            {state.teetimesDraggable
              ?.filter(teetime => (division_id === -1 ? true : teetime.division_id === division_id))
              .filter(teetime => teetime.blocked_type !== "shotgun_disabled")
              .map((teetime, teetimeIndex: number, teetimes) => {
                const prevTeetime = teetimes[teetimeIndex - 1];
                const nextTeetime = teetimes[teetimeIndex + 1];

                let turnArrow = false;

                if (teetime.turn_tee_time?.quantity_remaining < teetime.quantity_remaining) {
                  turnArrow = true;
                }

                return (
                  <Fragment key={teetimeIndex}>
                    {teetime.league_id > 0 &&
                    (prevTeetime === undefined ||
                      prevTeetime.league_id === null ||
                      prevTeetime.league_id != teetime.league_id) ? (
                      <div className="background-black text-white pl-4 pr-4 py-2 rounded-top">
                        <span>
                          <div className="flex justify-between">
                            <div>
                              <FontAwesomeIcon className="mr-2" icon={["far", "users"]} />
                              {state.teeSheetLeagues === undefined ? (
                                <span style={{ display: "inline-flex" }}>
                                  <Spin />
                                </span>
                              ) : (
                                leagueName(teetime.league_id)
                              )}
                            </div>
                            <div className="text-black">
                              <NavigationDropdownNew
                                showPlainTextLabel
                                rightAlign
                                label={
                                  <FontAwesomeIcon className="th-ellipsis text-white" icon={["fas", "ellipsis-v"]} />
                                }
                                sections={[
                                  [
                                    {
                                      icon: "print",
                                      label: t("elements.tee_sheet.tee_sheet_tabs.066"),
                                      onClick: () => printLeagueCartSigns(teetime.date, teetime.league_id),
                                    },
                                    {
                                      icon: "dollar-sign",
                                      label: t("elements.tee_sheet.tee_sheet_tabs.067"),
                                      onClick: () => navigateToLeagueAccount(teetime.league_id),
                                      hidden: !shotgunTemplateState.leagues?.find(
                                        league => league.id === teetime.league_id,
                                      )?.account_id,
                                    },
                                    {
                                      icon: "trash",
                                      label: t("elements.tee_sheet.tee_sheet_tabs.068"),
                                      onClick: () => handleRemoveShotgunTemplate(teetime),
                                      hidden: teetime.shotgun_application_id === null,
                                    },
                                    // {
                                    //   icon: "file-export",
                                    //   label: "Export Shotgun Template",
                                    //   onClick: () => handleExportShotgunTemplate(teetime),
                                    //   hidden: teetime.blocked_type !== "shotgun",
                                    // },
                                  ],
                                ]}
                              />
                            </div>
                          </div>
                        </span>
                      </div>
                    ) : null}

                    {teetime.tournament_id > 0 &&
                    (prevTeetime === undefined ||
                      prevTeetime.tournament_id === null ||
                      prevTeetime.tournament_id != teetime.tournament_id) ? (
                      <div className="background-black text-white pl-4 pr-4 py-2 rounded-top">
                        <span>
                          <div className="flex justify-between">
                            <div>
                              <FontAwesomeIcon className="mr-2" icon={["far", "trophy"]} />
                              {state?.teeSheetTournaments === undefined ? (
                                <div style={{ display: "inline-flex" }}>
                                  <Spin />
                                </div>
                              ) : (
                                tournamentName(teetime.tournament_id)
                              )}
                            </div>
                            <div className="text-black">
                              <NavigationDropdownNew
                                showPlainTextLabel
                                rightAlign
                                // type=""
                                label={
                                  <FontAwesomeIcon className="th-ellipsis text-white" icon={["fas", "ellipsis-v"]} />
                                }
                                sections={[
                                  [
                                    {
                                      icon: "print",
                                      label: t("elements.tee_sheet.tee_sheet_tabs.066"),
                                      onClick: () => printTournamentCartSigns(teetime.date, teetime.tournament_id),
                                    },
                                    {
                                      icon: "dollar-sign",
                                      label: t("elements.tee_sheet.tee_sheet_tabs.067"),
                                      onClick: () => navigateToTournamentAccount(teetime.league_id),
                                      hidden: !shotgunTemplateState.tournaments?.find(
                                        tournament => tournament.id === teetime.tournament_id,
                                      )?.account_id,
                                    },
                                    {
                                      icon: "trash",
                                      label: t("elements.tee_sheet.tee_sheet_tabs.068"),
                                      onClick: () => handleRemoveShotgunTemplate(teetime),
                                      hidden: teetime.shotgun_application_id === null,
                                    },
                                    {
                                      icon: "file-export",
                                      label: "Export Shotgun Template",
                                      onClick: () => handleExportShotgunTemplate(teetime),
                                      hidden: teetime.blocked_type !== "shotgun",
                                    },
                                  ],
                                ]}
                              />
                            </div>
                          </div>
                        </span>
                        <p>
                          {tournamentNote(teetime.tournament_id)
                            ? tournamentNote(teetime.tournament_id).toLocaleLowerCase()
                            : ""}
                        </p>
                      </div>
                    ) : null}

                    {teetime.blocked_type === "shotgun" &&
                    teetime.tournament_id === null &&
                    teetime.league_id === null &&
                    (prevTeetime === undefined || prevTeetime.blocked_type !== "shotgun") ? (
                      <div className="background-primary text-white pl-4 pr-4 py-2 rounded-top">
                        <span>
                          <div className="flex justify-between">
                            <div>{t("elements.tee_sheet.tee_sheet_tabs.069")}</div>
                            <div className="text-black">
                              <NavigationDropdownNew
                                showPlainTextLabel
                                rightAlign
                                label={
                                  <FontAwesomeIcon className="th-ellipsis text-white" icon={["fas", "ellipsis-v"]} />
                                }
                                sections={[
                                  [
                                    {
                                      icon: "trash",
                                      label: t("elements.tee_sheet.tee_sheet_tabs.068"),
                                      onClick: () => handleRemoveShotgunTemplate(teetime),
                                      disabled: teetime.shotgun_application_id === null,
                                    },
                                  ],
                                ]}
                              />
                            </div>
                          </div>
                        </span>
                      </div>
                    ) : null}

                    <div
                      className={classNames("tee-time", {
                        "pb-3.5 background-darkgray event-side-border":
                          teetime.league_id > 0 || teetime.tournament_id > 0,
                        "pb-3.5 background-primary-light event-side-border":
                          teetime.league_id === null &&
                          teetime.tournament_id === null &&
                          teetime.blocked_type === "shotgun",
                        "pt-3.5":
                          (teetime.league_id > 0 &&
                            (prevTeetime === undefined ||
                              prevTeetime.league_id === null ||
                              prevTeetime.league_id != teetime.league_id)) ||
                          (teetime.tournament_id > 0 &&
                            (prevTeetime === undefined ||
                              prevTeetime.tournament_id === null ||
                              prevTeetime.tournament_id != teetime.tournament_id)) ||
                          (teetime.blocked_type === "shotgun" &&
                            (prevTeetime === undefined || prevTeetime.blocked_type !== "shotgun")),
                        "rounded-bottom event-bottom-border":
                          (teetime.league_id > 0 &&
                            (nextTeetime === undefined ||
                              nextTeetime.league_id === null ||
                              nextTeetime.league_id != teetime.league_id)) ||
                          (teetime.tournament_id > 0 &&
                            (nextTeetime === undefined ||
                              nextTeetime.tournament_id === null ||
                              nextTeetime.tournament_id != teetime.tournament_id)) ||
                          (teetime.blocked_type === "shotgun" &&
                            (nextTeetime === undefined || nextTeetime.blocked_type !== "shotgun")),
                        "my-3.5":
                          windowSize.width > MOBILE_WIDTH &&
                          teetime.league_id === null &&
                          teetime.tournament_id === null &&
                          teetime.blocked_type !== "shotgun" &&
                          prevTeetime !== undefined,
                        "my-1.5":
                          windowSize.width <= MOBILE_WIDTH &&
                          teetime.league_id === null &&
                          teetime.tournament_id === null &&
                          teetime.blocked_type !== "shotgun" &&
                          prevTeetime !== undefined,
                      })}
                      key={teetimeIndex}
                      ref={elementRefs[teetime.id]}
                    >
                      {teetimeIndex === 0 ||
                      convertTime(teetime.start_time).split(":")[0] !==
                        convertTime(prevTeetime.start_time).split(":")[0] ? (
                        <div className="tee-time hour-block">
                          <p className="tee-time time tee-time hour">
                            {convertTime(teetime.start_time).split(":")[0] +
                              ":00 " +
                              convertTime(teetime.start_time).split(" ")[1]}
                          </p>
                          <div className="tee-time hour-line"></div>
                        </div>
                      ) : null}

                      {((teetimeIndex === 0 && facilityStore.weather?.sunrise < teetime?.start_time) ||
                        (facilityStore.weather?.sunrise >= prevTeetime?.start_time &&
                          facilityStore.weather?.sunrise < teetime?.start_time)) && (
                        <div className="flex items-center mb-3.5">
                          <div style={{ marginLeft: "6.4rem" }} className="sun-time-line"></div>
                          <span className="sun-time-hour">
                            {t("elements.tee_sheet.tee_sheet_tabs.027")}
                            {convertTime(facilityStore.weather?.sunrise)}
                          </span>
                          <div style={{ marginRight: "8px" }} className="sun-time-line"></div>
                        </div>
                      )}

                      {sunsetAdjustedTime >= prevTeetime?.start_time && sunsetAdjustedTime < teetime?.start_time && (
                        <div className="flex items-center mb-3.5">
                          <div style={{ marginLeft: "6.4rem" }} className="sun-time-line"></div>
                          <span className="sun-time-hour">
                            {t("elements.tee_sheet.tee_sheet_tabs.028")} {convertTime(facilityStore.weather?.sunset)}
                          </span>
                          <div style={{ marginRight: "8px" }} className="sun-time-line"></div>
                        </div>
                      )}

                      <div className="tee-time_container">
                        <button
                          className="tee-time-time cursor-pointer text-left"
                          onClick={() => openTeeTimeSheet(teetime)}
                        >
                          <p
                            className={
                              teetime.tournament_id || teetime.league_id
                                ? "tee-time-time-text-event"
                                : "tee-time-time-text"
                            }
                          >
                            {teetime.blocked_type === "shotgun" || teetime.blocked_type === "shotgun_crossover" ? (
                              <span>{teetime.start_hole}</span>
                            ) : (
                              <span>{convertTime(teetime.start_time, windowSize.width <= MOBILE_WIDTH)}</span>
                            )}
                          </p>
                        </button>

                        <p
                          className="tee-time-division-code"
                          style={{
                            backgroundColor: courseDivisions
                              ? courseDivisions.find((division: any) => division.id === teetime.division_id).color
                              : "#e9e9e9",
                          }}
                        >
                          {teetime.division_code}
                        </p>

                        <div className="tee-time-slots">
                          <Droppable key={teetimeIndex.toString()} droppableId={teetimeIndex.toString()} type="LIST">
                            {(provided, snapshot) => (
                              <span className="row-container" ref={provided.innerRef} {...provided.droppableProps}>
                                {teetime.slots.map((slotGroup, groupIndex: number) => {
                                  return (
                                    <Draggable
                                      key={groupIndex}
                                      draggableId={
                                        slotGroup[0]?.tee_time_booking_id
                                          ? slotGroup[0].tee_time_booking_id.toString()
                                          : "0"
                                      }
                                      isDragDisabled={slotGroup[0]?.tee_time_booking_id === null ? true : false}
                                      index={groupIndex} //"non-consecutive drag indexes" warning
                                    >
                                      {provided => (
                                        <div
                                          className={classNames(
                                            "tee-time-slots_container",
                                            `tee-time-slot_spaced_${slotGroup.length}`,
                                            {
                                              "tee-time-slots_last": teetime.slots.length === groupIndex + 1,
                                            },
                                          )}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          ref={provided.innerRef}
                                        >
                                          {slotGroup.map(
                                            (slot, slotIndex: number) => {
                                              if (slot.booking_participant) {
                                                const editStartHoleSection: {
                                                  icon: IconName;
                                                  title: string;
                                                  action: () => void;
                                                } = {
                                                  icon: "golf-ball-tee",
                                                  title: "Edit Start Hole",
                                                  action: () => handleOpenEditStartHole(slot?.booking, teetime),
                                                };

                                                return (
                                                  <div
                                                    key={slot.id}
                                                    className={classNames("tee-time-slot", {
                                                      "tee-time-slot_checked":
                                                        slot.booking_participant?.check_in_status === "checked",
                                                      "tee-time-slot_crossover": teetime.blocked_type === "crossover",
                                                      "tee-time-slot_blocked": teetime.blocked_type === "blocked",
                                                      "tee-time-slot_no-show": slot.booking_participant?.no_show,
                                                      "tee-time-slot_turn": slot.booking_participant?.turn,
                                                      "tee-time-slot_no-show-weather":
                                                        slot.booking_participant?.no_show_weather,
                                                      "tee-time-slot_booking_engine":
                                                        slot.booking_participant?.booking_source === "booking_engine",
                                                      "tee-time-slot_has_notes": slot.booking_participant?.has_notes,
                                                      "tee-time-slot_customer-type-display":
                                                        facilityStore?.teeSheetSettings?.display_customer_type,
                                                    })}
                                                    style={{
                                                      backgroundColor: findCustomerTypeBackground(
                                                        slot.booking_participant,
                                                      ),
                                                    }}
                                                    onMouseDown={() =>
                                                      handleOnMouseDown(slot.tee_time_booking_id, slotIndex, groupIndex)
                                                    }
                                                    onClick={() => handleNavigateToBookingDetails(teetime.id, slot)}
                                                  >
                                                    <TeeTimeToolTip
                                                      prepaidRequired={teetime.pre_paid_required}
                                                      cardRequired={teetime.credit_card_required}
                                                      onMouseEnter={() => handleMouseEnter(teetime)}
                                                      disable={
                                                        bookingState.teetimeToolTipDisabled
                                                          ? true
                                                          : slot.tee_time_booking_id
                                                          ? true
                                                          : false
                                                      }
                                                      parentId={slot?.id}
                                                    >
                                                      <RightClickMenu
                                                        informationSection={[
                                                          `${t("elements.tee_sheet.tee_sheet_tabs.070")} ${moment
                                                            .utc(slot?.booking?.completed_at)
                                                            .local()
                                                            .format("lll")}`,
                                                          ...(slot?.booking_participant?.no_show_type
                                                            ? [
                                                                <Badge
                                                                  key="no_show_badge"
                                                                  size="xsmall"
                                                                  colour="#FDB022"
                                                                  outline
                                                                >
                                                                  {slot?.booking_participant?.no_show_type === "defer"
                                                                    ? "Defer Charge"
                                                                    : valueToString(
                                                                        slot?.booking_participant?.no_show_type,
                                                                      )}
                                                                </Badge>,
                                                              ]
                                                            : []),
                                                        ]}
                                                        sections={[
                                                          [
                                                            ...(teetime?.shotgun_application_id &&
                                                            teetime?.turn_tee_time
                                                              ? [editStartHoleSection]
                                                              : []),
                                                          ],
                                                          [
                                                            {
                                                              icon: "pencil",
                                                              title: t("elements.tee_sheet.tee_sheet_tabs.071"),
                                                              action: () => handleEditBooking(slot?.booking, teetime),
                                                            },
                                                            {
                                                              icon: "ban",
                                                              title: t("elements.tee_sheet.tee_sheet_tabs.072"),
                                                              action: () => {
                                                                const teetimeToCancelDate = moment(teetime.date).format(
                                                                  "MMMM Do, YYYY",
                                                                );
                                                                const teetimeToCancelTime = convertTime(
                                                                  teetime.start_time,
                                                                );

                                                                setRightClickMenuState(prevState => ({
                                                                  ...prevState,
                                                                  selectedSlot: slot,
                                                                  cancelBookingPopup: true,
                                                                  teetimeToCancelDate: teetimeToCancelDate,
                                                                  teetimeToCancelTime: teetimeToCancelTime,
                                                                }));
                                                              },
                                                              disabled: checkForCheckedInPlayer(slotGroup),
                                                            },
                                                            {
                                                              icon: "user-slash",
                                                              title: t("elements.tee_sheet.tee_sheet_tabs.126"),
                                                              action: () => {
                                                                setRightClickMenuState(prevState => ({
                                                                  ...prevState,
                                                                  selectedSlot: slot,
                                                                  cancelPlayerPopup: true,
                                                                }));
                                                              },
                                                              disabled:
                                                                slot.booking_participant.check_in_status !==
                                                                "unchecked",
                                                            },
                                                            {
                                                              icon: "cancel",
                                                              title: t("elements.tee_sheet.tee_sheet_tabs.073"),
                                                              action: () =>
                                                                setRightClickMenuState(prevState => ({
                                                                  ...prevState,
                                                                  noShow: { popup: true, slot: slot, type: "charge" },
                                                                })),
                                                              disabled:
                                                                slot?.booking_participant?.check_in_status ===
                                                                "no_show",
                                                            },
                                                            {
                                                              icon: "list",
                                                              title: t("elements.tee_sheet.tee_sheet_tabs.074"),
                                                              action: () => {
                                                                void openBookingAuditTrailSheet(slot.booking);
                                                              },
                                                            },
                                                          ],
                                                          [
                                                            {
                                                              icon: "circle-info",
                                                              title: t("elements.tee_sheet.tee_sheet_tabs.075"),
                                                              action: () => {
                                                                void openDetailsSheet(teetime);
                                                              },
                                                            },
                                                            {
                                                              icon: "list",
                                                              title: t("elements.tee_sheet.tee_sheet_tabs.076"),
                                                              action: () => {
                                                                void openAuditTrailSheet(teetime);
                                                              },
                                                            },
                                                            {
                                                              icon: "flag",
                                                              title: t("elements.tee_sheet.tee_sheet_tabs.077"),
                                                              action: () => {
                                                                void openShotgunSheet(teetime);
                                                              },
                                                            },
                                                            {
                                                              icon: "distribute-spacing-vertical",
                                                              title: t("elements.tee_sheet.tee_sheet_tabs.078"),
                                                              action: () => handleTeeTimeSqueezePopup(teetime),
                                                            },
                                                          ],
                                                        ]}
                                                      >
                                                        <div className="tee-time-slot_content">
                                                          <div className="tee-time-slot-booking-participant">
                                                            <div className="booking-participant_content">
                                                              {windowSize.width > MOBILE_WIDTH &&
                                                                renderBookingParticipantIcons(slot)}

                                                              <div>
                                                                <div className="booking-participant-name">
                                                                  <span>
                                                                    {slot.booking_participant.full_name}
                                                                    {slot?.booking_participant?.guest && (
                                                                      <FontAwesomeIcon
                                                                        style={{ marginLeft: "0.5rem" }}
                                                                        icon={["fas", "circle-g"]}
                                                                        size="1x"
                                                                      />
                                                                    )}
                                                                  </span>
                                                                </div>

                                                                {facilityStore?.teeSheetSettings
                                                                  ?.display_customer_type &&
                                                                !slot.booking_participant?.guest ? (
                                                                  <div className="booking-participant-customer-type">
                                                                    <span>
                                                                      {slot.booking_participant.customer?.customer_type}
                                                                    </span>
                                                                    <span>
                                                                      {
                                                                        slot.booking_participant.customer
                                                                          ?.cart_customer_type
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                ) : null}
                                                              </div>
                                                            </div>

                                                            <div className="booking-participant-secondary_content">
                                                              <div className="booking-participant-holes-mobile">
                                                                {slot.booking.holes}
                                                              </div>
                                                              <div className="booking-participant-secondary_content-icons">
                                                                {windowSize.width <= MOBILE_WIDTH &&
                                                                  renderBookingParticipantIcons(slot)}

                                                                {slot.booking.source === "booking_engine" && (
                                                                  <FontAwesomeIcon
                                                                    icon={["far", "earth-americas"]}
                                                                    className="booking-participant_icon"
                                                                  />
                                                                )}

                                                                {slot.booking.source === "golfnow" && (
                                                                  <div className={"golfNow-logo"}>
                                                                    <img src="/public/images/gn-logo-small.svg" />
                                                                  </div>
                                                                )}

                                                                {slot.booking.has_notes === true &&
                                                                  slot.booking_participant.customer_id ===
                                                                    slot.booking.customer_id &&
                                                                  slot.booking_participant.guest !== true && (
                                                                    <FontAwesomeIcon
                                                                      icon={["far", "sticky-note"]}
                                                                      className="booking-participant_icon"
                                                                    />
                                                                  )}

                                                                {slot.booking_participant.power_cart_paid === true ||
                                                                slot.booking.power_cart_quantity > 0 ? (
                                                                  // <i className="booking-participant_cart_icon fak fa-power-cart"></i>
                                                                  // <FontAwesomeIcon
                                                                  //   icon={["far", "car-side"]}
                                                                  //   className="booking-participant_icon"
                                                                  // />
                                                                  <div className="booking-participant_icon">
                                                                    <CustomIcon
                                                                      src="../../../public/images/powercart2-01.svg"
                                                                      size="small"
                                                                    />
                                                                  </div>
                                                                ) : (
                                                                  <FontAwesomeIcon
                                                                    icon={["far", "person-walking"]}
                                                                    className="booking-participant_icon"
                                                                  />
                                                                )}

                                                                {slot.booking_participant.customer?.bag_number !==
                                                                  null && (
                                                                  <CustomIcon
                                                                    src="../../../public/images/golf-bag.svg"
                                                                    size="small"
                                                                  />
                                                                )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </RightClickMenu>
                                                    </TeeTimeToolTip>
                                                  </div>
                                                );
                                              } else {
                                                // EMPTY SLOTS

                                                const editStartHoleSection: {
                                                  icon: IconName;
                                                  title: string;
                                                  action: () => void;
                                                } = {
                                                  icon: "golf-ball-tee",
                                                  title: "Edit Start Hole",
                                                  action: () => handleOpenEditStartHole(slot?.booking, teetime),
                                                };

                                                return (
                                                  <div
                                                    key={slot.id}
                                                    className={classNames("tee-time-slot", {
                                                      "tee-time-slot_crossover":
                                                        teetime.blocked_type === "crossover" ||
                                                        teetime.blocked_type === "shotgun_crossover",
                                                      "tee-time-slot_blocked":
                                                        teetime.blocked_type === "blocked" ||
                                                        teetime.blocked_type === "shotgun_blocked" ||
                                                        teetime.blocked_type === "shotgun_disabled",
                                                      "tee-time-slot_no-show": slot.booking_participant?.no_show,
                                                      "tee-time-slot_no-show-weather":
                                                        slot.booking_participant?.no_show_weather,
                                                      "tee-time-slot_customer-type-display":
                                                        facilityStore?.teeSheetSettings?.display_customer_type,
                                                    })}
                                                    onMouseDown={() =>
                                                      handleOnMouseDown(slot.tee_time_booking_id, slotIndex, groupIndex)
                                                    }
                                                    onClick={() => checkBlockedTeeTime(teetime)}
                                                  >
                                                    <TeeTimeToolTip
                                                      prepaidRequired={teetime.pre_paid_required}
                                                      cardRequired={teetime.credit_card_required}
                                                      onMouseEnter={token => handleMouseEnter(teetime, token)}
                                                      disable={
                                                        bookingState.teetimeToolTipDisabled
                                                          ? true
                                                          : slot.tee_time_booking_id
                                                          ? true
                                                          : false
                                                      }
                                                      parentId={slot?.id}
                                                    >
                                                      <RightClickMenu
                                                        sections={[
                                                          [
                                                            ...(teetime?.shotgun_application_id &&
                                                            teetime?.turn_tee_time
                                                              ? [editStartHoleSection]
                                                              : []),
                                                            {
                                                              icon: "circle",
                                                              title: t("elements.tee_sheet.tee_sheet_tabs.079"),
                                                              action: () => setBlockedType(teetime, "open"),
                                                              disabled: teetime.blocked_type === "open",
                                                            },
                                                            {
                                                              icon: "ban",
                                                              title: t("elements.tee_sheet.tee_sheet_tabs.080"),
                                                              action: () => setBlockedType(teetime, "blocked"),
                                                              disabled: teetime.blocked_type === "blocked",
                                                            },
                                                            {
                                                              icon: "arrows-repeat",
                                                              title: t("elements.tee_sheet.tee_sheet_tabs.081"),
                                                              action: () => setBlockedType(teetime, "crossover"),
                                                              disabled: teetime.blocked_type === "crossover",
                                                            },
                                                          ],
                                                          [
                                                            {
                                                              icon: "circle-info",
                                                              title: t("elements.tee_sheet.tee_sheet_tabs.082"),
                                                              action: () => {
                                                                void openDetailsSheet(teetime);
                                                              },
                                                            },
                                                            {
                                                              icon: "list",
                                                              title: t("elements.tee_sheet.tee_sheet_tabs.083"),
                                                              action: () => {
                                                                void openAuditTrailSheet(teetime);
                                                              },
                                                            },
                                                            {
                                                              icon: "flag",
                                                              title: t("elements.tee_sheet.tee_sheet_tabs.084"),
                                                              action: () => {
                                                                void openShotgunSheet(teetime);
                                                              },
                                                            },
                                                            {
                                                              icon: "distribute-spacing-vertical",
                                                              title: "Add Tee Time",
                                                              action: () => handleAddTeeTimePopup(teetime),
                                                            },
                                                            {
                                                              icon: "ban",
                                                              title: "Remove Tee Time",
                                                              action: () => handleRemoveTeeTimePopup(teetime),
                                                              disabled: teetime.quantity_remaining !== 4,
                                                            },
                                                          ],
                                                        ]}
                                                      >
                                                        <div className="tee-time-slot_content">
                                                          {teetime.blocked_type !== null &&
                                                          teetime.blocked_type !== "open" ? (
                                                            <div className="">
                                                              {teetime.blocked_type === "blocked" && (
                                                                <p className="text-center">
                                                                  <FontAwesomeIcon
                                                                    icon={["far", "ban"]}
                                                                    className="booking-participant_icon"
                                                                  />
                                                                </p>
                                                              )}

                                                              {teetime.blocked_type === "crossover" && (
                                                                <p className="text-center">
                                                                  <FontAwesomeIcon
                                                                    icon={["far", "repeat-alt"]}
                                                                    className="booking-participant_icon"
                                                                  />
                                                                </p>
                                                              )}

                                                              {teetime.blocked_type === "shotgun" && (
                                                                <p className="text-center">
                                                                  <FontAwesomeIcon
                                                                    icon={["far", "plus-circle"]}
                                                                    className="booking-participant_icon"
                                                                  />
                                                                </p>
                                                              )}

                                                              {teetime.blocked_type === "shotgun_blocked" && (
                                                                <p className="text-center">
                                                                  <FontAwesomeIcon
                                                                    icon={["far", "ban"]}
                                                                    className="booking-participant_icon"
                                                                  />
                                                                </p>
                                                              )}

                                                              {teetime.blocked_type === "shotgun_disabled" && (
                                                                <p className="text-center">
                                                                  <em>{t("elements.tee_sheet.tee_sheet_tabs.029")}</em>
                                                                </p>
                                                              )}

                                                              {teetime.blocked_type === "shotgun_crossover" && (
                                                                <p className="text-center">
                                                                  <FontAwesomeIcon
                                                                    icon={["far", "repeat-alt"]}
                                                                    className="booking-participant_icon"
                                                                  />
                                                                </p>
                                                              )}
                                                            </div>
                                                          ) : (
                                                            <div>
                                                              <p className="text-center">
                                                                <FontAwesomeIcon
                                                                  icon={["far", "plus-circle"]}
                                                                  className="booking-participant_icon"
                                                                />
                                                              </p>
                                                            </div>
                                                          )}
                                                        </div>
                                                      </RightClickMenu>
                                                    </TeeTimeToolTip>
                                                  </div>
                                                );
                                              }
                                            }, // slot group map
                                          )}
                                        </div> // tee-time-slots_container
                                      )}
                                    </Draggable>
                                  );
                                })}
                                {provided.placeholder}
                              </span>
                            )}
                          </Droppable>
                        </div>

                        {turnArrow ? (
                          <div className="ml-2 mr-2">
                            {teetime.tournament_id > 0 || teetime.league_id > 0 ? (
                              <FontAwesomeIcon
                                icon={["fas", "diamond-turn-right"]}
                                className="turn-blocked-icon"
                                style={{ color: "white" }}
                              />
                            ) : (
                              <FontAwesomeIcon icon={["fas", "diamond-turn-right"]} className="turn-blocked-icon" />
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </Fragment>
                );
              })}
          </div>
        </DragDropContext>
      </>
    );
  };

  async function updateEditStartHole() {
    const params = {
      tee_time_id: state.shotgun_teetime_id,
      start_hole: state.shotgun_start_hole,
    };

    const editStartHoleRes = await UpdateShotgunStartHole(params, true);

    if (editStartHoleRes.status !== StatusCode.OK) {
      uiActions.showError("Error updating shotgun start hole");
      return;
    }

    uiActions.showSuccess("Successfully updated shotgun start hole");
    ReactDOM.unstable_batchedUpdates(() => {
      setState(prevState => ({
        ...prevState,
        shotgun_start_hole: "",
        shotgun_teetime_id: null,
        editShotgunStartHoleVisible: false,
      }));

      reloadTeeTimes();
    });
  }

  return (
    <>
      <Card.Group className="tee-sheet-tabs-scroll">{renderTeeSheetTabs()}</Card.Group>

      <Popup
        open={state.teeTimeBlockedPopupVisible}
        type="warning"
        title={t("elements.tee_sheet.tee_sheet_tabs.030")}
        description={t("elements.tee_sheet.tee_sheet_tabs.031")}
        onOk={handleBlockedTeeTimeView}
        okText={t("elements.tee_sheet.tee_sheet_tabs.032")}
        onCancel={() => handleClosePopup()}
        backDropCancel={true}
      />

      <Popup
        open={state.teetimeLockPopupVisible}
        type="warning"
        title={t("elements.tee_sheet.tee_sheet_tabs.014")}
        description={t("elements.tee_sheet.tee_sheet_tabs.015")}
        onOk={() => handleClosePopup()}
        okText={t("elements.tee_sheet.tee_sheet_tabs.032")}
        onCancel={() => handleClosePopup()}
        backDropCancel={true}
      />

      <Popup
        open={state.teetimeBookedPopupVisible}
        type="warning"
        title={t("elements.tee_sheet.tee_sheet_tabs.016")}
        description={t("elements.tee_sheet.tee_sheet_tabs.017")}
        onOk={handleTeeTimeBookedPopUpRefresh}
        okText={t("elements.tee_sheet.tee_sheet_tabs.032")}
        onCancel={handleTeeTimeBookedPopUpRefresh}
        backDropCancel={false}
      />

      <Popup
        open={rightClickMenuState.cancelBookingPopup}
        type="warning"
        title={t("elements.tee_sheet.tee_sheet_tabs.086")}
        description={`${t("elements.tee_sheet.tee_sheet_tabs.087")} ${rightClickMenuState?.teetimeToCancelDate} at ${
          rightClickMenuState?.teetimeToCancelTime
        }?`}
        onOk={cancelBooking}
        okText={t("elements.tee_sheet.tee_sheet_tabs.088")}
        onCancel={() =>
          setRightClickMenuState(prevState => ({ ...prevState, selectedSlot: null, cancelBookingPopup: false }))
        }
        backDropCancel={false}
      />

      <Popup
        open={rightClickMenuState.cancelPlayerPopup}
        type="warning"
        title={t("elements.tee_sheet.tee_sheet_tabs.089")}
        description={`${t("elements.tee_sheet.tee_sheet_tabs.090")} ${
          rightClickMenuState?.selectedSlot?.booking_participant?.full_name
        }?`}
        onOk={cancelBookingParticipant}
        okText={t("elements.tee_sheet.tee_sheet_tabs.091")}
        onCancel={() =>
          setRightClickMenuState(prevState => ({ ...prevState, selectedSlot: null, cancelPlayerPopup: false }))
        }
        backDropCancel={false}
      />

      <Popup
        open={state.squeezeTimePopupVisible}
        type="warning"
        title={t("elements.tee_sheet.tee_sheet_tabs.092")}
        description={`${t("elements.tee_sheet.tee_sheet_tabs.093")} ${String(state.teetime?.start_time)}?`}
        onOk={handleTeetimeSqueeze}
        okText={t("elements.tee_sheet.tee_sheet_tabs.094")}
        onCancel={() => updateMainState({ squeezeTimePopupVisible: false })}
        backDropCancel={false}
      />

      <Sheet
        open={state.teetimePopupVisible}
        size="medium"
        closable
        title={t("elements.tee_sheet.tee_sheet_tabs.018") + `${state.teetimeStartTime}`}
        onCancel={handleClosePopup}
        onOk={""}
        cancelText={t("elements.tee_sheet.tee_sheet_tabs.033")}
        okText={t("elements.tee_sheet.tee_sheet_tabs.034")}
        overflow
      >
        <Tabs tabs={tabs} secondaryTabs={secondaryTabs} selected={selected} onSelect={handleTabChange}>
          {/* Booking Details */}
          {selected === 0 ? (
            <>
              <div className="teetime-modal-details">
                <p>{t("elements.tee_sheet.tee_sheet_tabs.019")}</p>
                {/* <div>
                  <p>Tee Time Sort:</p>
                  <p>League</p>
                </div> */}
                <div className="flex mt-4 mb-4">
                  <p className="teetime-modal-details-title">{t("elements.tee_sheet.tee_sheet_tabs.035")}</p>

                  {!state?.booking_categories_loading ? (
                    <div className="teetime-modal-badge-container">
                      {state?.booking_categories.map((category: any, index: number) => {
                        let type: "primary" | "success" | "warning" | "error" | "gray" | "public";

                        switch (category.title) {
                          case "Public":
                            type = "public";
                            break;
                          case "Member":
                            type = "error";
                            break;
                          case "GolfNow":
                            type = "warning";
                            break;

                          default:
                            type = "gray";
                            break;
                        }

                        return (
                          <Badge key={index} size="medium" type={type}>
                            {category.title}
                          </Badge>
                        );
                      })}
                    </div>
                  ) : (
                    <div style={{ height: "25px", width: "25px" }}>
                      <Spin />
                    </div>
                  )}
                </div>
                <div className="flex mt-4 mb-4">
                  <p className="teetime-modal-details-title">{t("elements.tee_sheet.tee_sheet_tabs.038")}</p>
                  <p className="teetime-modal-details-options">{capitalize(state.teetime?.cart_rule)}</p>
                </div>
                <div className="flex mt-4 mb-4">
                  <p className="teetime-modal-details-title">{t("elements.tee_sheet.tee_sheet_tabs.037")}</p>

                  <div className="teetime-modal-badge-container">
                    {state.teetime?.credit_card_required ? (
                      <Badge size="medium" type="gray">
                        {t("elements.tee_sheet.tee_sheet_tabs.036")}
                      </Badge>
                    ) : null}

                    {state.teetime?.pre_paid_required ? (
                      <Badge size="medium" type="gray">
                        {t("elements.tee_sheet.tee_sheet_tabs.039")}
                      </Badge>
                    ) : null}
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {selected === 1 ? (
            <div>
              <p className="bm-audit-trail-title">{t("elements.tee_sheet.tee_sheet_tabs.004")}</p>

              {state.auditTrails.map((audit: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <AuditTrailNote
                      title={audit.title}
                      author={audit.author}
                      actions={audit.actions}
                      action={audit.action}
                      timestamp={audit.created_at}
                      last={index + 1 === state.auditTrails.length ? true : false}
                    />
                  </React.Fragment>
                );
              })}
            </div>
          ) : null}
          {selected === 2 ? (
            <>
              <Form>
                <FormLayout>
                  <FormLayout.Group>
                    <Select
                      onChange={(value: number) => handleShotgunDropdownChange(value, "shotgun_template_id")}
                      label={t("elements.tee_sheet.tee_sheet_tabs.009")}
                    >
                      {shotgunTemplateState.shotgunTemplates?.map((group: IGetShotGunTemplate, index: number) => {
                        return (
                          <Option key={index} value={group.id} name={group.title}>
                            <span>{group.title}</span>
                          </Option>
                        );
                      })}
                    </Select>
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TimePick
                      value={shotgunTemplateState.start_time}
                      onChange={timeString => updateShotgunTemplateState({ start_time: timeString })}
                      label={t("elements.tee_sheet.tee_sheet_tabs.008")}
                      size="large"
                    />
                    <Select
                      onChange={(value: number) => handleShotgunDropdownChange(value, "division_id")}
                      label={t("elements.tee_sheet.tee_sheet_tabs.010")}
                    >
                      {courseDivisions?.map((division: any, index: number) => {
                        return (
                          <Option key={index} value={division.id} name={division.title}>
                            <span>{division.title}</span>
                          </Option>
                        );
                      })}
                    </Select>

                    <Select
                      onChange={(value: number) => handleShotgunDropdownChange(value, "turn_division_id")}
                      label={t("elements.tee_sheet.tee_sheet_tabs.011")}
                    >
                      {courseDivisions?.map((division: any, index: number) => {
                        return (
                          <Option key={index} value={division.id} name={division.title}>
                            <span>{division.title}</span>
                          </Option>
                        );
                      })}
                    </Select>
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <Select
                      showSearch
                      showDropDownOnFocus={true}
                      placeholder={t("elements.tee_sheet.tee_sheet_tabs.095")}
                      onSearch={(query: string) =>
                        setShotgunTemplateState(prevState => ({ ...prevState, tournamentQuery: query }))
                      }
                      onChange={(value: number) => handleShotgunDropdownChange(value, "tournament_id")}
                      label={t("elements.tee_sheet.tee_sheet_tabs.012")}
                      disabled={shotgunTemplateState.league_id !== undefined}
                    >
                      <Option value={-1} key={-1} name={"None"}>
                        <div>{t("elements.tee_sheet.tee_sheet_tabs.097")}</div>
                      </Option>

                      {shotgunTemplateState.tournaments
                        ?.filter(tournament => !moment(tournament.date).isBefore(moment(), "day"))
                        .map((tournament: any, index: number) => {
                          return (
                            <Option key={index} value={tournament.id} name={tournament.name}>
                              <div className="flex items-center">
                                <span>{tournament.name}</span>
                                <span className="shotgun-tournament-date">{moment(tournament.date).format("LL")}</span>
                              </div>
                            </Option>
                          );
                        })}

                      <Option disabled value={-2} key={-2} name={""}>
                        <div>
                          <hr style={{ margin: "0px" }} />
                        </div>
                      </Option>

                      {shotgunTemplateState.tournaments
                        ?.filter(tournament => moment(tournament.date).isBefore(moment(), "day"))
                        .map((tournament: any, index: number) => {
                          return (
                            <Option key={index} value={tournament.id} name={tournament.name}>
                              <div className="flex items-center">
                                <span>{tournament.name}</span>
                                <span className="shotgun-tournament-date">{moment(tournament.date).format("LL")}</span>
                              </div>
                            </Option>
                          );
                        })}
                    </Select>
                    <Select
                      showSearch
                      showDropDownOnFocus={true}
                      placeholder={t("elements.tee_sheet.tee_sheet_tabs.096")}
                      onSearch={(query: string) =>
                        setShotgunTemplateState(prevState => ({ ...prevState, leagueQuery: query }))
                      }
                      onChange={(value: number) => handleShotgunDropdownChange(value, "league_id")}
                      label={t("elements.tee_sheet.tee_sheet_tabs.013")}
                      disabled={shotgunTemplateState.tournament_id !== undefined}
                    >
                      <Option value={-1} key={-1} name={"None"}>
                        <div>{t("elements.tee_sheet.tee_sheet_tabs.097")}</div>
                      </Option>

                      {shotgunTemplateState.leagues
                        ?.filter(league => league.start_date && !moment(league.start_date).isBefore(moment(), "day"))

                        ?.map((league: any, index: number) => {
                          return (
                            <Option key={index} value={league.id} name={league.name}>
                              <span>{league.name}</span>
                              <span className="shotgun-tournament-date">
                                {moment(league.start_date).format("YYYY")}
                              </span>
                            </Option>
                          );
                        })}

                      <Option disabled value={-2} key={-2} name={""}>
                        <div>
                          <hr style={{ margin: "0px" }} />
                        </div>
                      </Option>

                      {shotgunTemplateState.leagues
                        ?.filter(league => !league.start_date || moment(league.start_date).isBefore(moment(), "day"))
                        ?.map((league: any, index: number) => {
                          return (
                            <Option key={index} value={league.id} name={league.name}>
                              <span>{league.name}</span>
                              {league?.start_date && (
                                <span className="shotgun-tournament-date">
                                  {moment(league.start_date).format("YYYY")}
                                </span>
                              )}
                            </Option>
                          );
                        })}
                    </Select>
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <div className="flex flex-col">
                      <TimePick
                        value={shotgunTemplateState.duration}
                        onChange={(timeString: string) => handleShotgunTimePicker(timeString, "duration")}
                        label={t("elements.tee_sheet.tee_sheet_tabs.007")}
                        disableClock
                        hideMeridiem={12}
                      />
                      {/* <label className="text-md">{t("elements.tee_sheet.tee_sheet_tabs.007")}</label>
                      
                      <TimePicker
                        onChange={(value: string) => handleShotgunTimePicker(value, "duration")}
                        value={shotgunTemplateState.duration}
                        disableClock={true}
                        format="hh:mm"
                        hourPlaceholder="HH"
                        minutePlaceholder="MM"
                        clearIcon={null}
                        className={"tee-sheet-tabs-date-picker"}
                      /> */}
                    </div>
                    <div className="flex flex-col">
                      <TimePick
                        value={shotgunTemplateState.lead_time}
                        onChange={(timeString: string) => handleShotgunTimePicker(timeString, "lead_time")}
                        label={t("elements.tee_sheet.tee_sheet_tabs.006")}
                        disableClock
                        hideMeridiem={12}
                      />

                      {/* <label className="text-md">{t("elements.tee_sheet.tee_sheet_tabs.006")}</label>
                      
                      <TimePicker
                        onChange={(value: string) => handleShotgunTimePicker(value, "lead_time")}
                        value={shotgunTemplateState.lead_time}
                        disableClock={true}
                        format="hh:mm"
                        hourPlaceholder="HH"
                        minutePlaceholder="MM"
                        clearIcon={null}
                        className={"tee-sheet-tabs-date-picker"}
                      /> */}
                    </div>
                  </FormLayout.Group>

                  <div className="flex justify-right">
                    {state.teetime.blocked_type === "shotgun" && (
                      <Button onClick={() => handleRemoveShotgunTemplate(state.teetime)}>
                        <span>{t("elements.tee_sheet.tee_sheet_tabs.098")}</span>
                      </Button>
                    )}

                    <Button
                      disabled={
                        shotgunTemplateState.division_id === null ||
                        shotgunTemplateState.duration === "" ||
                        shotgunTemplateState.lead_time === "" ||
                        shotgunTemplateState.shotgun_template_id === null ||
                        shotgunTemplateState.start_time === "" ||
                        shotgunTemplateState.tee_sheet_id === null ||
                        shotgunTemplateState.turn_division_id === null
                      }
                      onClick={applyShotgunTemplate}
                    >
                      <span>{t("elements.tee_sheet.tee_sheet_tabs.005")}</span>
                    </Button>
                  </div>
                </FormLayout>
              </Form>
            </>
          ) : null}
        </Tabs>
      </Sheet>

      <Popup
        open={state.removeShotGunPopup}
        stacked
        type="warning"
        title={t("elements.tee_sheet.tee_sheet_tabs.099")}
        description={t("elements.tee_sheet.tee_sheet_tabs.100")}
        onOk={removeShotgunTemplate}
        okText={t("elements.tee_sheet.tee_sheet_tabs.101")}
        onCancel={() => setState(prevState => ({ ...prevState, removeShotGunPopup: false }))}
        backDropCancel={false}
      />

      <Popup
        open={addTeeTimeState.removeTeeTimePopup}
        stacked
        type="warning"
        title={t("elements.tee_sheet.tee_sheet_tabs.128")}
        description={t("elements.tee_sheet.tee_sheet_tabs.129")}
        onOk={removeTeeTime}
        okText={t("elements.tee_sheet.tee_sheet_tabs.130")}
        onCancel={() => setAddTeeTimeState(prevState => ({ ...prevState, removeTeeTimePopup: false }))}
        backDropCancel={false}
      />

      <Sheet
        open={addTeeTimeState.addTeeTimePopup}
        stacked
        size="x-small"
        title={t("elements.tee_sheet.tee_sheet_tabs.131")}
        onOk={addTeeTime}
        okText={t("elements.tee_sheet.tee_sheet_tabs.132")}
        onCancel={() => setAddTeeTimeState(prevState => ({ ...prevState, addTeeTimePopup: false }))}
        backDropCancel={true}
        overflow
      >
        <Input
          value={addTeeTimeState.start_hole}
          label={t("elements.tee_sheet.tee_sheet_tabs.133")} // TODO: Translation
          id="start_hole"
          onChange={handleAddTeeTimeInputChange}
          className="mb-2"
        />
        <TimePick
          value={addTeeTimeState.start_time}
          onChange={timeString => setAddTeeTimeState(prevState => ({ ...prevState, start_time: timeString }))}
          label={t("elements.tee_sheet.tee_sheet_tabs.134")} // TODO: Translation
          size="large"
        />
      </Sheet>

      {/* Tee Time Details Modal */}
      <Sheet
        open={state.detailsModalVisible}
        size={"small"}
        closable
        title={`${t("elements.tee_sheet.tee_sheet_tabs.102")} ${
          state.teetime ? convertTime(state.teetime?.start_time) : null
        }`}
        onCancel={() => updateMainState({ detailsModalVisible: false })}
        cancelText={t("elements.tee_sheet.tee_sheet_tabs.103")}
      >
        <div className="teetime-modal-details">
          <p className="teetime-modal-details-options">{t("elements.tee_sheet.tee_sheet_tabs.104")}</p>
          <div className="flex mt-4 mb-4">
            <p className="teetime-modal-details-title">{t("elements.tee_sheet.tee_sheet_tabs.105")}</p>
            <p className="teetime-modal-details-options">{getTeeTimeType(state.teetime)}</p>
          </div>
          <div className="flex mt-4 mb-4">
            <p className="teetime-modal-details-title">{t("elements.tee_sheet.tee_sheet_tabs.035")}</p>

            {!state?.booking_categories_loading ? (
              <div className="teetime-modal-badge-container">
                {state?.booking_categories.map((category: any, index: number) => {
                  let type: "primary" | "success" | "warning" | "error" | "gray" | "public";

                  switch (category.title) {
                    case "Public":
                      type = "public";
                      break;
                    case "Member":
                      type = "error";
                      break;
                    case "GolfNow":
                      type = "warning";
                      break;

                    default:
                      type = "gray";
                      break;
                  }

                  return (
                    <Badge key={index} size="medium" type={type}>
                      {category.title}
                    </Badge>
                  );
                })}
              </div>
            ) : (
              <div style={{ height: "25px", width: "25px" }}>
                <Spin />
              </div>
            )}
          </div>
          <div className="flex mt-4 mb-4">
            <p className="teetime-modal-details-title">{t("elements.tee_sheet.tee_sheet_tabs.038")}</p>
            <p className="teetime-modal-details-options">{capitalize(state.teetime?.cart_rule)}</p>
          </div>
          <div className="flex mt-4 mb-4">
            <p className="teetime-modal-details-title">{t("elements.tee_sheet.tee_sheet_tabs.037")}</p>

            <div className="teetime-modal-badge-container">
              {state.teetime?.credit_card_required ? (
                <Badge size="medium" type="gray">
                  {t("elements.tee_sheet.tee_sheet_tabs.036")}
                </Badge>
              ) : null}

              {state.teetime?.pre_paid_required ? (
                <Badge size="medium" type="gray">
                  {t("elements.tee_sheet.tee_sheet_tabs.039")}
                </Badge>
              ) : null}
            </div>
          </div>
        </div>
      </Sheet>

      {/* Tee Time Audit Trail Modal */}
      <Sheet
        open={state.auditTrailModalVisible}
        size={"medium"}
        closable
        title={`${t("elements.tee_sheet.tee_sheet_tabs.106")} ${
          state.teetime ? convertTime(state.teetime?.start_time) : null
        }`}
        onCancel={() => updateMainState({ auditTrailModalVisible: false })}
        cancelText={t("elements.tee_sheet.tee_sheet_tabs.107")}
      >
        <p className="teetime-modal-details-options mb-4">{t("elements.tee_sheet.tee_sheet_tabs.127")}</p>
        {!state.audit_trails_loading ? (
          <div>
            {state.auditTrails.length > 0 ? (
              state.auditTrails.map((audit: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <AuditTrailNote
                      title={audit.title}
                      author={audit.author}
                      actions={audit.actions}
                      action={audit.action}
                      timestamp={audit.created_at}
                      last={index + 1 === state.auditTrails.length ? true : false}
                    />
                  </React.Fragment>
                );
              })
            ) : (
              <span>{t("elements.tee_sheet.tee_sheet_tabs.108")}</span>
            )}
          </div>
        ) : (
          <div style={{ height: "25px", width: "25px" }}>
            <Spin />
          </div>
        )}
      </Sheet>

      {/* Booking Audit Trail Modal */}
      <Sheet
        open={state.bookingAuditTrailModalVisible}
        size={"medium"}
        closable
        title={t("elements.tee_sheet.tee_sheet_tabs.109")}
        onCancel={() => updateMainState({ bookingAuditTrailModalVisible: false })}
        cancelText={t("elements.tee_sheet.tee_sheet_tabs.110")}
      >
        {!state.audit_trails_loading ? (
          <div>
            {state.auditTrails.length > 0 ? (
              state.auditTrails.map((audit: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <AuditTrailNote
                      title={audit.title}
                      author={audit.author}
                      actions={audit.actions}
                      action={audit.action}
                      timestamp={audit.created_at}
                      last={index + 1 === state.auditTrails.length ? true : false}
                    />
                  </React.Fragment>
                );
              })
            ) : (
              <span>{t("elements.tee_sheet.tee_sheet_tabs.111")}</span>
            )}
          </div>
        ) : (
          <div style={{ height: "25px", width: "25px" }}>
            <Spin />
          </div>
        )}
      </Sheet>

      {/* Create Shotgun Modal */}
      <Sheet
        open={state.shotgunModalVisible}
        size="small"
        closable
        title={`${t("elements.tee_sheet.tee_sheet_tabs.112")} ${
          state.teetime ? convertTime(state.teetime?.start_time) : null
        }`}
        onCancel={() => updateMainState({ shotgunModalVisible: false })}
        onOk={() => applyShotgunTemplate()}
        cancelText={t("elements.tee_sheet.tee_sheet_tabs.113")}
        okText={t("elements.tee_sheet.tee_sheet_tabs.114")}
        okDisabled={
          shotgunTemplateState.division_id === undefined ||
          shotgunTemplateState.duration === "" ||
          shotgunTemplateState.lead_time === "" ||
          shotgunTemplateState.shotgun_template_id === undefined ||
          shotgunTemplateState.start_time === "" ||
          shotgunTemplateState.tee_sheet_id === null ||
          shotgunTemplateState.turn_division_id === undefined
        }
        overflow
      >
        <p className="teetime-modal-details-options mb-4">{"Create Shotgun"}</p>
        <FormLayout>
          <FormLayout.Group>
            <Select
              onChange={(value: number) => handleShotgunDropdownChange(value, "shotgun_template_id")}
              label={t("elements.tee_sheet.tee_sheet_tabs.009")}
            >
              {shotgunTemplateState.shotgunTemplates?.map((group: IGetShotGunTemplate, index: number) => {
                return (
                  <Option key={index} value={group.id} name={group.title}>
                    <span>{group.title}</span>
                  </Option>
                );
              })}
            </Select>
            <div>
              <label>{"Booking Categories"}</label>
              <div>
                {shotgunTemplateState.booking_categories.map((bookingCategory, index: number) => {
                  return (
                    <Checkbox
                      key={index}
                      size="small"
                      onChange={handleBookingCategoryChange}
                      value={bookingCategory.id}
                      checked={
                        shotgunTemplateState.booking_category_ids.find(
                          bookingCategoryId => bookingCategoryId === bookingCategory.id,
                        ) !== undefined
                      }
                      label={bookingCategory.title}
                      circle
                    />
                  );
                })}
              </div>
            </div>
          </FormLayout.Group>
          {courseDivisions && (
            <FormLayout.Group>
              <TimePick
                value={shotgunTemplateState.start_time}
                onChange={timeString => updateShotgunTemplateState({ start_time: timeString })}
                label={t("elements.tee_sheet.tee_sheet_tabs.008")}
                size="large"
              />
              <Select
                onChange={(value: number) => handleShotgunDropdownChange(value, "division_id")}
                label={t("elements.tee_sheet.tee_sheet_tabs.010")}
              >
                {courseDivisions?.map((division: any, index: number) => {
                  return (
                    <Option key={index} value={division.id} name={division.title}>
                      <span>{division.title}</span>
                    </Option>
                  );
                })}
              </Select>

              <Select
                onChange={(value: number) => handleShotgunDropdownChange(value, "turn_division_id")}
                label={t("elements.tee_sheet.tee_sheet_tabs.011")}
              >
                {courseDivisions?.map((division: any, index: number) => {
                  return (
                    <Option key={index} value={division.id} name={division.title}>
                      <span>{division.title}</span>
                    </Option>
                  );
                })}
              </Select>
            </FormLayout.Group>
          )}
          <FormLayout.Group>
            <Select
              showSearch
              showDropDownOnFocus={true}
              placeholder={t("elements.tee_sheet.tee_sheet_tabs.115")}
              onSearch={(query: string) =>
                setShotgunTemplateState(prevState => ({ ...prevState, tournamentQuery: query }))
              }
              onChange={(value: number) => handleShotgunDropdownChange(value, "tournament_id")}
              label={t("elements.tee_sheet.tee_sheet_tabs.012")}
              disabled={shotgunTemplateState.league_id !== undefined}
            >
              <Option value={-1} key={-1} name={"None"}>
                <div>{t("elements.tee_sheet.tee_sheet_tabs.116")}</div>
              </Option>

              {shotgunTemplateState.tournaments
                ?.filter(tournament => !moment(tournament.date).isBefore(moment(), "day"))
                .map((tournament: any, index: number) => {
                  return (
                    <Option key={index} value={tournament.id} name={tournament.name}>
                      <div className="flex items-center">
                        <span>{tournament.name}</span>
                        <span className="shotgun-tournament-date">{moment(tournament.date).format("LL")}</span>
                      </div>
                    </Option>
                  );
                })}

              <Option disabled value={-2} key={-2} name={""}>
                <div>
                  <hr style={{ margin: "0px" }} />
                </div>
              </Option>

              {shotgunTemplateState.tournaments
                ?.filter(tournament => moment(tournament.date).isBefore(moment(), "day"))
                .map((tournament: any, index: number) => {
                  return (
                    <Option key={index} value={tournament.id} name={tournament.name}>
                      <div className="flex items-center">
                        <span>{tournament.name}</span>
                        <span className="shotgun-tournament-date">{moment(tournament.date).format("LL")}</span>
                      </div>
                    </Option>
                  );
                })}
            </Select>
            <Select
              showSearch
              showDropDownOnFocus={true}
              placeholder={t("elements.tee_sheet.tee_sheet_tabs.117")}
              onSearch={(query: string) =>
                setShotgunTemplateState(prevState => ({ ...prevState, leagueQuery: query }))
              }
              onChange={(value: number) => handleShotgunDropdownChange(value, "league_id")}
              label={t("elements.tee_sheet.tee_sheet_tabs.013")}
              disabled={shotgunTemplateState.tournament_id !== undefined}
            >
              <Option value={-1} key={-1} name={"None"}>
                <div>{t("elements.tee_sheet.tee_sheet_tabs.116")}</div>
              </Option>
              {shotgunTemplateState.leagues?.map((league: any, index: number) => {
                return (
                  <Option key={index} value={league.id} name={league.name}>
                    <span>{league.name}</span>
                  </Option>
                );
              })}
            </Select>
          </FormLayout.Group>
          <FormLayout.Group>
            <div className="flex flex-col">
              <TimePick
                value={shotgunTemplateState.duration}
                onChange={(timeString: string) => handleShotgunTimePicker(timeString, "duration")}
                label={t("elements.tee_sheet.tee_sheet_tabs.007")}
                disableClock
                hideMeridiem={12}
              />
            </div>
            <div className="flex flex-col">
              <TimePick
                value={shotgunTemplateState.lead_time}
                onChange={(timeString: string) => handleShotgunTimePicker(timeString, "lead_time")}
                label={t("elements.tee_sheet.tee_sheet_tabs.006")}
                disableClock
                hideMeridiem={12}
              />
            </div>
          </FormLayout.Group>
        </FormLayout>
      </Sheet>

      <Popup
        open={rightClickMenuState.noShow.popup}
        type="info"
        title={t("elements.tee_sheet.tee_sheet_tabs.118")}
        description={t("elements.tee_sheet.tee_sheet_tabs.119")}
        onOk={handleNoShow}
        okText={t("elements.tee_sheet.tee_sheet_tabs.120")}
        okDisabled={!rightClickMenuState.noShow.type || !rightClickMenuState.noShow.slot}
        cancelText={t("elements.tee_sheet.tee_sheet_tabs.121")}
        onCancel={() =>
          setRightClickMenuState(prevState => ({
            ...prevState,
            noShow: { popup: false, slot: null, type: "charge" },
          }))
        }
      >
        <Select defaultValue={rightClickMenuState?.noShow?.type} onChange={(value: string) => setNoShowType(value)}>
          <Option value={"charge"} name={"Charge"}>
            <span>{t("elements.tee_sheet.tee_sheet_tabs.122")}</span>
          </Option>
          <Option value={"defer"} name={"Defer Charge"}>
            <span>{t("elements.tee_sheet.tee_sheet_tabs.123")}</span>
          </Option>
          <Option value={"weather"} name={"Weather"}>
            <span>{t("elements.tee_sheet.tee_sheet_tabs.124")}</span>
          </Option>
        </Select>
      </Popup>

      <BookingModalEdit
        selectedBooking={state.selectedBooking}
        teeTimeLock={state.teeTimeLock}
        refreshTeeTime={false}
      />

      <Sheet
        open={state.editShotgunStartHoleVisible}
        size={"small"}
        closable
        title={t("elements.tee_sheet.tee_sheet_tabs.135")}
        onCancel={() => updateMainState({ editShotgunStartHoleVisible: false })}
        cancelText={t("elements.tee_sheet.tee_sheet_tabs.136")}
        onOk={updateEditStartHole}
        okText={t("elements.tee_sheet.tee_sheet_tabs.137")}
      >
        <div className="py-4">
          <Input
            value={state.shotgun_start_hole}
            label={t("elements.tee_sheet.tee_sheet_tabs.133")}
            id="start_hole"
            onChange={handleInputChange}
          />
        </div>
      </Sheet>
    </>
  );
};

export default TeeSheetTabs;
