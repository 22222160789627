import { APIGet } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "guest";
const baseUrl = "/" + apiVersion + "/" + adminType + "/league/scoring/format";

export type TGetLeagueCompetitionFormat = {
  id?: string | number;
  league_id?: string | number;
  league_division_id?: string | number;
  league_scoresheet_id?: string | number;
};

//GET Format
export async function GetLeagueCompetitionFormat(
  params: TGetLeagueCompetitionFormat,
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseUrl, params, useGlobalLoader, token);
}
