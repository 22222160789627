import { APIGet } from "api/protocols";
import { TWeather } from "redux/reducers/models/facility";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseWeatherUrl = "/" + apiVersion + "/" + adminType + "/facility/weather/forecast?";

//GET Weather
//Retrive weather from facility
export async function GetWeather(params: number, useGlobalLoader: boolean) {
  return await APIGet<TWeather>(baseWeatherUrl + `facility_id=${params}`, useGlobalLoader);
}
