import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { IBookingCategory } from "redux/reducers/models/teetime";

const apiVersion = "2024-04";
const adminType = "client-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/tee-sheet/booking-engine/category";

export type TGetBookingCategory = {
  id?: string;
};

export async function GetBookingCategory(params: TGetBookingCategory, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<IBookingCategory[]>(baseUrl, params, useGlobalLoader, token);
}
