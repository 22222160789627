import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { StatusCode } from "api/protocols";

import Checkbox from "components/form/checkbox/Checkbox";
import Card from "components/card/Card";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import Page from "components/page/Page";
import { Select } from "components/select/index";
import { isEqualWith } from "lodash";
import { NotificationType } from "components/notificationBar/NotificationBar";
import { useTranslation } from "react-i18next";
import { IPostAccount, PostAccount } from "api/rpc/2024-04/clientAdmin/client/accounts";
import { GetAccountingReference } from "api/rpc/2024-04/clientAdmin/client/accountingReference";
import { GetFacility } from "api/rpc/2022-09/clientAdmin/facility/facility";

export interface IAccount {
  title: string;
  currency: string;
  global: boolean;
  account_number: string;
  limit: string;
  limit_is_valid: boolean;
  accounting_reference_id: string;
  facility_id: number;
}

export interface IAccountingReferenceTypes {
  label: string;
  value: number | null;
}

interface IFilterState {
  facilities: any[];
}

export default function AccountNew() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { Option } = Select;
  const [account, setAccount] = useState<IAccount>({
    title: "",
    currency: "cad",
    global: false,
    account_number: "",
    limit: "",
    limit_is_valid: false,
    accounting_reference_id: "0",
    facility_id: null,
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    facilities: [],
  });

  const [accountBeforeChanges, setAccountBeforeChanges] = useState<IAccount>(undefined);

  const [accountingReferenceTypes, setAccountingReferenceTypes] = useState<IAccountingReferenceTypes[]>(undefined);

  useEffect(() => {
    void loadAccountingReferenceTypes();
    void loadFacilities();
  }, []);

  async function loadAccountingReferenceTypes() {
    const accountingResponse = await GetAccountingReference(
      {
        group: "accounts_receivable",
      },
      true,
    );

    if (accountingResponse.status !== StatusCode.OK) {
      return;
    }

    const accountingReferenceTypes: IAccountingReferenceTypes[] = [];

    accountingReferenceTypes.push({ label: "None", value: 0 });

    if (accountingResponse?.data?.length !== undefined && accountingResponse.data.length > 0) {
      const accountingReferenceTypesResponse = accountingResponse.data.map((accountingReference: any) => {
        return {
          label: accountingReference.title ?? "",
          value: accountingReference.id ?? 0,
        };
      });

      accountingReferenceTypes.push(...accountingReferenceTypesResponse);
    }

    setAccountingReferenceTypes(accountingReferenceTypes);
  }

  async function loadFacilities() {
    const facilityRes = await GetFacility(null, true);
    if (facilityRes.status === StatusCode.OK) {
      setFilterState(prevState => ({
        ...prevState,
        facilities: facilityRes.data,
      }));
      return facilityRes.data;
    }

    return [];
  }

  function handleChange(event: any) {
    const id = event?.target?.id;
    const value = event?.target?.value;

    if (id === undefined || value === undefined) {
      return;
    }

    setAccount(prevState => ({ ...prevState, [id]: value }));
  }

  function handleDropDownChange(id: any) {
    setAccount(prevState => ({ ...prevState, accounting_reference_id: id }));
  }

  function handleFacilitiesChange(id: any) {
    setAccount(prevState => ({ ...prevState, facility_id: id }));
  }

  function handleCheckboxChange(event: any) {
    const id = event?.target?.id;
    const value = event?.target?.value;

    if (id === undefined || value === undefined) {
      return;
    }

    const booleanValue = value === "true";

    setAccount(prevState => ({ ...prevState, [id]: !booleanValue }));
  }

  function handleNumberChange(event: any, propertyToChange: string, validFlagToChange: string) {
    const value = event?.target?.value;

    if (value === undefined) {
      return;
    }

    const valueCharacters = value.split("");
    setAccount(prevState => ({
      ...prevState,
      [propertyToChange]: value,
      [validFlagToChange]: valueCharacters.length > 0 && valueCharacters.every((v: any) => !Number.isNaN(Number(v))),
    }));
  }

  async function saveAccount() {
    if (account.title === "" || !account.limit_is_valid) {
      return;
    }

    const postAccount: IPostAccount = {
      title: account.title,
      currency: account.currency,
      global: account.global,
      account_number: account.account_number === "" ? undefined : account.account_number,
      limit: Number(account.limit),
      accounting_reference_id: account.accounting_reference_id === "0" ? null : Number(account.accounting_reference_id),
      facility_id: account.facility_id,
    };

    const accountResponse = await PostAccount(postAccount, true);

    if (accountResponse.status !== StatusCode.OK) {
      return;
    }

    history.push("/admin/settings/accounts");
  }

  function unsavedChangesExist() {
    if (accountBeforeChanges === undefined) {
      if (accountingReferenceTypes) {
        setAccountBeforeChanges(account);
      }
      return false;
    }

    return !isEqualWith(accountBeforeChanges, account);
  }

  function cancelUnsavedChanges() {
    setAccount(accountBeforeChanges);
  }

  return (
    <Page
      title={"New Account"}
      narrow
      breadcrumbs={[
        {
          prefix: true,
          label: "Accounts",
          url: "/admin/settings/accounts",
        },
      ]}
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: saveAccount,
        onCancel: cancelUnsavedChanges,
      }}
    >
      {accountingReferenceTypes ? (
        <Form>
          <Card>
            <Card.Section>
              <FormLayout>
                <FormLayout.Group>
                  <Input value={account.title} label={"Title"} id="title" onChange={handleChange} />
                  <Input
                    value={account.account_number}
                    id="account_number"
                    label={"Account Number"}
                    onChange={handleChange}
                    helpText={"Leave empty to auto generate a number"}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Input
                    value={account.limit}
                    label={"Limit"}
                    id={account.limit.length > 0 && !account.limit_is_valid ? "inputError" : ""}
                    onChange={(e: any) => handleNumberChange(e, "limit", "limit_is_valid")}
                  />
                  <Select
                    label={"Accounting Reference"}
                    defaultValue={account.accounting_reference_id}
                    onChange={handleDropDownChange}
                  >
                    {accountingReferenceTypes.map((type: any, index: number) => {
                      return (
                        <Option key={index} value={type.value} name={type.label}>
                          <span>{type.label}</span>
                        </Option>
                      );
                    })}
                  </Select>
                </FormLayout.Group>

                <FormLayout.Group>
                  <Select label={"Facilities"} defaultValue={account.facility_id} onChange={handleFacilitiesChange}>
                    {filterState.facilities.map((facility: any, index: number) => {
                      return (
                        <Option key={index} value={facility.id} name={facility.long_name}>
                          <span>{facility.long_name}</span>
                        </Option>
                      );
                    })}
                  </Select>
                </FormLayout.Group>
                <FormLayout.Group>
                  <Checkbox
                    id="global"
                    size="medium"
                    value={account.global}
                    checked={account.global}
                    onChange={handleCheckboxChange}
                    label={"This account can be used across all courses"}
                  ></Checkbox>
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>
          </Card>
        </Form>
      ) : null}
    </Page>
  );
}
