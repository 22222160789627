import React, { ChangeEvent, useEffect, useState } from "react";
import classNames from "classnames";
import Icon from "components/icon/Icon";

import "components/form/form.scss";
import "components/form/choiceList/choiceList.scss";
import "./checkbox.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface ICheckboxProps {
  label?: string | JSX.Element;
  helpText?: string;
  supportText?: string;
  disabled?: boolean;
  id?: string;
  labelHidden?: boolean;
  name?: string;
  value?: any;
  checked?: boolean;
  circle?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  size?: "extra small" | "small" | "medium" | "large";
  ariaChecked?: boolean;
  fullWidth?: boolean;
  borderStyle?: Record<string, any>;
  darkStyle?: boolean;
  error?: boolean;
  readOnly?: boolean;
  subtitle?: string;
}

const Checkbox: React.FC<ICheckboxProps> = props => {
  const {
    label,
    helpText,
    supportText,
    disabled,
    id,
    labelHidden,
    name,
    value,
    checked,
    circle,
    onChange,
    size,
    ariaChecked,
    fullWidth,
    readOnly,
    subtitle,
  } = props;

  if (circle) {
    return (
      <label
        className={classNames("ui-choice flex flex-0 align-center", {
          "ui-choice_label-hidden": labelHidden,
        })}
      >
        <span
          className={classNames("ui-checkbox_container flex flex-0 items-stretch", {
            "ui-checkbox-small": size === "small" || size === "extra small",
            "ui-checkbox-medium": size === "medium" || size === "large",
          })}
        >
          <span className="relative w-full ui-checkbox">
            <input
              id={id}
              type="checkbox"
              name={name}
              className="absolute top-0 overflow-hidden h-0.5 w-0.5 p-0 ui-checkbox_input"
              disabled={disabled}
              onChange={onChange}
              aria-invalid="false"
              role="checkbox"
              aria-checked={ariaChecked}
              value={value}
              checked={checked}
              onClick={event => event.stopPropagation()}
              readOnly={readOnly}
            />
            <span
              style={props?.borderStyle}
              className={
                "ui-checkbox-box bg-red rounded block relative ui-checkbox_backdrop " +
                (disabled ? "ui-checkbox-disabled " : "") +
                (props.darkStyle ? "ui-checkbox_darkstyle " : "")
              }
            ></span>
            <span
              className={
                "absolute top-1/2 left-1/2 text-white pointer-events-none ui-checkbox_icon " +
                (disabled && "ui-checkbox_icon-disabled ") +
                (size === "extra small" && " w-2 h-2 ")
              }
            >
              <FontAwesomeIcon icon={["fas", "circle"]} />
            </span>
          </span>
        </span>
        {label && (
          <span
            className={
              "ui-checkbox-label " +
              (disabled ? "ui-checkbox-label-disabled " : "") +
              (props?.darkStyle ? "ui-checkbox_darkstyle_label" : "")
            }
          >
            <span>{label}</span>
            {supportText ? <p className="ui-checkbox-label-support">{supportText}</p> : null}
          </span>
        )}
      </label>
    );
  } else {
    return (
      <div>
        <label
          className={classNames("ui-choice", {
            "ui-choice_label-hidden": labelHidden,
            "w-full": fullWidth,
          })}
        >
          <span
            className={classNames("ui-checkbox_container flex flex-0 items-stretch", {
              "ui-checkbox-small": size === "small" || size === "extra small",
              "ui-checkbox-medium": size === "medium" || size === "large",
            })}
          >
            <span className="ui-checkbox">
              <input
                id={id}
                type="checkbox"
                name={name}
                className="absolute top-0 overflow-hidden h-0.5 w-0.5 p-0 ui-checkbox_input"
                disabled={disabled}
                onChange={onChange}
                onClick={event => event.stopPropagation()}
                aria-invalid="false"
                role="checkbox"
                aria-checked={ariaChecked}
                value={value}
                checked={checked}
                readOnly={readOnly}
              />
              <span
                style={props?.borderStyle}
                className={
                  "ui-checkbox-box bg-red rounded block relative ui-checkbox_backdrop " +
                  (disabled ? "ui-checkbox-disabled " : "") +
                  (props.error ? "ui-checkbox-error " : "") +
                  (props.darkStyle ? "ui-checkbox_darkstyle " : "")
                }
              ></span>
              <span
                className={
                  "absolute top-1/2 left-1/2 text-white pointer-events-none ui-checkbox_icon " +
                  (disabled && "ui-checkbox_icon-disabled ") +
                  (size === "extra small" && " w-2 h-2 ")
                }
              >
                <FontAwesomeIcon icon={"check"} />
              </span>
            </span>
          </span>
          <span
            className={
              "ui-checkbox-label " +
              (disabled ? "ui-checkbox-label-disabled " : "") +
              (props?.darkStyle ? "ui-checkbox_darkstyle_label" : "")
            }
          >
            <span className="flex">
              {label}
              {subtitle ? <p className="ui-checkbox-label-subtitle"> - {subtitle}</p> : null}
            </span>
            {supportText ? <p className="ui-checkbox-label-support">{supportText}</p> : null}
          </span>
        </label>
      </div>
    );
  }
};

export default Checkbox;
