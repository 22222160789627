import { IFacility } from "../models/facility";

export interface IMasterFacilityState {
  isLoaded: boolean;
  error?: any;
  facility?: IFacility;
  facilities?: Array<IFacility>;
  weather?: any;
}

export interface IFacilityAction {
  type: string;
  payload: Partial<IMasterFacilityState>;
}

const initialState: IMasterFacilityState = {
  isLoaded: false,
  error: null,
  facility: null,
  facilities: [],
  weather: null,
};

export default function MasterFacilityReducer(state = initialState, action: IFacilityAction) {
  switch (action.type) {
    case "facility.update":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
