import { APIGet, APIPost, APIPut, APIDelete, IAPIResponse } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2022-09";
const baseCustomerUrl = "/" + apiVersion + "/customer";

//GET Customer bookings
export async function GetLeagueParticipants(params: { league_id: number }, useGlobalLoader: boolean) {
  return await APIGet(baseCustomerUrl + "/league/participant", params, useGlobalLoader);
}
