import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseLeagueUrl = "/" + apiVersion + "/" + adminType + "/league/scoring";

//GET League Scoresheets
//Retrieve a list of league scoresheets
export async function GetScoresheet(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseLeagueUrl + "/scoresheet", params, useGlobalLoader);
}

//POST League Scoresheets
//Create a new league scoresheet
export async function PostScoresheet(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseLeagueUrl + "/scoresheet", params, useGlobalLoader);
}

//PUT League Scoresheets
//Update league scoresheet
export async function PutScoresheet(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseLeagueUrl + "/scoresheet", params, useGlobalLoader);
}

// DELETE League Scoresheets
// Delete a league scoresheet
export async function DeleteScoresheet(params: any, useGlobalLoader: boolean) {
  return await APIDelete(baseLeagueUrl + "/scoresheet", params, useGlobalLoader);
}
