import React, { useEffect, SetStateAction, Dispatch, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as FilterHistoryActions from "redux/actions/filters/filterHistory";
import { IFilterHistory } from "redux/reducers/filters/filterHistory";

import DropFilter from "components/dropdown/DropFilter";
import PriceMatchDropFilter from "components/filters/PriceMatchDropFilter";
import { IOrderFilterStateClient } from "./Orders";

import "pages/secure/facility/order/order.scss";

interface IProps {
  filters: IOrderFilterStateClient;
  setFilters: Dispatch<SetStateAction<IOrderFilterStateClient>>;
  filterGroups: {
    date: any[]; // handled differently
    financial: any[];
    salesChannels: any[];
    facilities: any[];
    creditCard: any[];
  };

  historyKey: string;
  dropFilterHistoryKeyPrefix: string;
  filterHistoryStore?: IFilterHistory;
  filterHistoryActions?: FilterHistoryActions.IFilterHistoryActions;
}

const OrderFilters = (props: IProps) => {
  const { t, i18n } = useTranslation();
  const {
    filters,
    setFilters,
    filterGroups,

    historyKey,
    dropFilterHistoryKeyPrefix,
    filterHistoryStore,
    filterHistoryActions,
  } = props;

  const [mounted, setMounted] = useState(false);

  // handle redux store
  useEffect(() => {
    if (filterHistoryStore[historyKey] === undefined) {
      filterHistoryActions.filterHistoryCreate(historyKey);
    }
  }, [historyKey]);

  // set component 'mounted' after all async filters loaded
  useEffect(() => {
    if (filterGroups.facilities === null) {
      return;
    }
    setMounted(true);
  }, [filterGroups.facilities]);

  // set filters.all after 'mounting'
  useEffect(() => {
    if (mounted) {
      mergeFilters();
    }
  }, [mounted]);

  // populate filters.all
  function mergeFilters() {
    const activeFilterGroups: any[] =
      filterHistoryStore !== undefined ? filterHistoryStore[historyKey].currentFilter : [];
    const dataGroups = Object.keys(filters);

    Object.values(filters).map((value: any[], index) => {
      if (dataGroups[index] === "all" || value.length === 0 || value === undefined) {
        return;
      }
      //handle dates differently
      if (dataGroups[index].includes("date")) {
        const startDate = {
          label: "start_date",
          value: value[0],
        };
        const endDate = {
          label: "end_date",
          value: value[0],
        };
        activeFilterGroups.push(startDate);
        activeFilterGroups.push(endDate);
      } else {
        activeFilterGroups.push({
          label: dataGroups[index],
          value: value,
        });
      }
    });

    setFilters({
      ...filters,
      all: activeFilterGroups, //activate loadOrders call
    });
  }

  function handleClearFilter(groupId: string) {
    const allFilters: Record<string, any>[] = filters.all;
    let updatedFilters = allFilters;

    // seperating date logic with different UI-inputs
    switch (groupId) {
      // Start + End date creation (1 date input)
      case "date": {
        updatedFilters = updatedFilters.filter(
          (filter: { label: string }) => filter?.label !== "start_date" && filter?.label !== "end_date",
        );
        break;
      }
      // Select Dropdown + Number input
      case "price_match": {
        updatedFilters = updatedFilters.filter((filter: { label: string }) => !filter?.label.includes("total"));
        break;
      }
      // All checkbox inputs
      default: {
        updatedFilters = updatedFilters.filter((filter: { label: string }) => filter?.label !== groupId);
      }
    }

    setFilters(prev => ({
      ...prev,
      [groupId]: [],
      all: updatedFilters,
      total_equal_to: "",
      total_greater_than: "",
      total_less_than: "",
    }));

    filterHistoryActions.filterHistoryUpdateFilter(historyKey, updatedFilters);
  }

  function handleApplyFilters(filterReturn: Array<Record<string, any>>, dataGroup: string) {
    // Empty filter return check
    if (filterReturn.length === 0) {
      handleClearFilter(dataGroup);
      return;
    }

    const filterValues = filterReturn.map(filter => filter.id as string | number);
    let filtersWithNewDataGroup = filters.all ? filters.all.filter(group => group.label !== dataGroup) : []; // filter out the wanted group

    //handle Dates & Price Match differently
    switch (dataGroup) {
      case "date": {
        if (filtersWithNewDataGroup.length >= 2) {
          filtersWithNewDataGroup = [];
        }

        filtersWithNewDataGroup.push({
          label: "start_date",
          value: filterValues[0],
        });
        filtersWithNewDataGroup.push({
          label: "end_date",
          value: filterValues[0],
        });

        setFilters({ ...filters, all: filtersWithNewDataGroup, [dataGroup as string]: filterValues });
        break;
      }
      case "price_match": {
        filtersWithNewDataGroup = filters.all
          ? filters.all.filter(filter => !filter?.label.includes("total"))
          : filtersWithNewDataGroup; // double check wanted filters
        filtersWithNewDataGroup.push({
          label: filterReturn[0].id,
          value: filterReturn[0].value,
        });
        setFilters({ ...filters, all: filtersWithNewDataGroup, [filterReturn[0].id]: filterReturn[0].value });
        break;
      }
      default: {
        filtersWithNewDataGroup.push({
          label: dataGroup,
          value: filterValues,
        });
        setFilters({ ...filters, all: filtersWithNewDataGroup, [dataGroup]: filterValues });
      }
    }

    filterHistoryActions.filterHistoryUpdateFilter(historyKey, filtersWithNewDataGroup);
  }

  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
      {/* This component requires the updateFilters function if you want to preset a value */}
      <DropFilter
        key="date"
        title={t("secure.facility.order.order_filters.001")}
        filterData={props.filterGroups?.date}
        filterLabelPropFromData="label"
        filterIdPropFromData="id"
        filterType="Date"
        applyFilters={(filterReturn: Record<string, any>[]) => handleApplyFilters(filterReturn, "date")}
        leftAligned
        disableDropdown={!mounted}
        save
        historyKey={`${dropFilterHistoryKeyPrefix}_client_date`}
      />

      <DropFilter
        key="financial"
        title={t("secure.facility.order.order_filters.003")}
        filterData={props.filterGroups?.financial.sort()}
        filterLabelPropFromData="label"
        filterIdPropFromData="id"
        filterType="Checkbox"
        applyFilters={(filterReturn: Record<string, any>[]) => handleApplyFilters(filterReturn, "financial_status")}
        disableDropdown={!mounted}
        save
        historyKey={`${dropFilterHistoryKeyPrefix}_client_financial`}
      />

      <DropFilter
        key="facility_ids"
        title={t("secure.facility.order.order_filters.025")} //TODO: Translation
        filterData={props.filterGroups.facilities}
        filterLabelPropFromData="long_name"
        filterIdPropFromData="id"
        filterType="Checkbox"
        applyFilters={(filterReturn: Record<string, any>[]) => handleApplyFilters(filterReturn, "facility_ids")}
        disableDropdown={!mounted}
        save
        historyKey={`${dropFilterHistoryKeyPrefix}_client_facilities`}
      />

      <DropFilter
        key="sales_channel"
        title={t("secure.facility.order.order_filters.013")}
        filterData={props.filterGroups?.salesChannels.sort()}
        filterLabelPropFromData="label"
        filterIdPropFromData="id"
        filterType="Checkbox"
        applyFilters={(filterReturn: Record<string, any>[]) => handleApplyFilters(filterReturn, "sales_channels")}
        disableDropdown={!mounted}
        save
        historyKey={`${dropFilterHistoryKeyPrefix}_client_sales_channel`}
      />

      <DropFilter
        key="credit_card"
        title={t("secure.facility.order.order_filters.024")}
        filterData={props?.filterGroups.creditCard}
        filterLabelPropFromData="label"
        filterIdPropFromData="id"
        filterType="Credit Card"
        applyFilters={filterReturn => handleApplyFilters(filterReturn, "credit_card")}
        leftAligned
        disableDropdown={!mounted}
        save
        historyKey={`${dropFilterHistoryKeyPrefix}_credit_card`}
      />

      {/* <PriceMatchDropFilter
        key="price_match"
        handleApply={filterReturn => handleApplyFilters(filterReturn, "price_match")}
        leftAligned
      /> */}
    </div>
  );
};

// Redux export as default because it's required to use the component itself
const mapStateToProps = (state: any) => {
  return {
    filterHistoryStore: { ...state.filterHistoryStore },
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    filterHistoryActions: bindActionCreators(
      {
        ...FilterHistoryActions,
      },
      dispatch,
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderFilters);
