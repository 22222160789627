import React, { useEffect, useState } from "react";
import "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { GetFolder, IFolder } from "api/rpc/2022-09/facilityAdmin/product/folder";
import { StatusCode } from "api/protocols";
import { IProduct, IVariant } from "redux/reducers/models/product";

import { FilterMenu } from "components/filterMenu/FilterMenu";

import Sheet from "components/sheet/Sheet";
import Input from "components/form/input/Input";
import { ButtonNew as Button } from "components/buttonNew/index";
import "../../pages/secure/facility/teesheet/styles.scss";
import { PostCart, PostLineItemToCart } from "api/rpc/cart";
import { ICart } from "redux/reducers/models/cart";
import { IUIActions, showError, showSuccess } from "redux/actions/ui";
import { GetProduct } from "api/rpc/facilityAdmin/product/products";
import { ICartState } from "redux/reducers/cart";
import Products, { IProductState } from "elements/register/Products";
import Icon from "components/icon/Icon";
import { PRODUCT_FOLDERS_KEY } from "pages/secure/facility/settings/folders/Folders";
import { useTranslation } from "react-i18next";
import "elements/register/register.scss";
import { ICartActions } from "redux/actions/cart";
import { useAppDispatch } from "hooks/redux";

export interface IAddItemProps {
  open: boolean;
  title: string;
  onCancel: () => void;
  onOk: () => void;
  okText: string;
  cart: ICart | Partial<ICart>;
  updateCart: (cart: ICart) => void;
  cartStore: ICartState;
}

export interface IMainState {
  products: IProduct[];
  folders: IFolder[];
  selectedFolder: IFolder;
  productState: IProductState;
}

export interface IFilterState {
  search: string;
  sort: string;
  barcodeSearch: boolean;
}

const AddItemSheet: React.FC<IAddItemProps> = props => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const [state, setState] = useState<IMainState>({
    products: [],
    folders: [],
    selectedFolder: null,
    productState: {
      selectedProduct: undefined,
      variants: [],
      variantSheetOpen: false,
    },
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    search: "",
    sort: "alpha",
    barcodeSearch: false,
  });

  function updateMainState(newMainState: Partial<IMainState>) {
    setState((cur: IMainState) => {
      return { ...cur, ...newMainState };
    });
  }

  function updateFilterState(newFilterState: Partial<IFilterState>) {
    setFilterState(cur => {
      return { ...cur, ...newFilterState };
    });
  }

  useEffect(() => {
    if (open && (state?.folders === undefined || state.folders.length === 0)) {
      void loadFolders();
    }
  }, [open]);

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (async () => {
          try {
            if (filterState.search === "") {
              if (mounted) {
                updateMainState({ products: [] });
              }
              return;
            } else {
              const res = await loadProducts();
              if (mounted) {
                updateMainState({ products: res });
              }
            }
          } catch (error) {
            console.log("err", error);
          }
          return;
        })();
      }, 500);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [filterState.search]);

  async function loadFolders() {
    const register = JSON.parse(localStorage.getItem("register"));

    if (!register) {
      dispatch(showError(t("elements.tee_sheet.add_item_sheet.001")));
      return;
    }

    const folderRes = await GetFolder({ register_group_id: register.register_group_id, extended: false }, true);

    if (folderRes.status !== StatusCode.OK) {
      dispatch(showError(t("elements.tee_sheet.add_item_sheet.002")));
      return;
    }

    const folders = folderRes.data;

    const defaultFolderId = Number(localStorage.getItem("default_folder_id"));
    let selectedFolder: IFolder = undefined;

    if (defaultFolderId > 0) {
      selectedFolder = folders.find((folder: IFolder) => folder.id === defaultFolderId);
    } else {
      selectedFolder = folders.find((folder: IFolder) => folder.default === true);
    }

    if (!selectedFolder) {
      selectedFolder = folders?.[0];
    }

    updateMainState({ folders: folders });

    if (selectedFolder) {
      void handleChangeSelectedFolder(selectedFolder);
    }
  }

  const loadProducts = async () => {
    const productRes = await GetProduct({ extended: true, search: filterState.search }, true);
    if (productRes.status !== StatusCode.OK) {
      dispatch(showError(productRes.message));
      return [];
    }
    return productRes.data;
  };

  function closeAddVariantModal() {
    updateMainState({
      productState: {
        variantSheetOpen: false,
        variants: [],
        selectedProduct: undefined,
      },
    });
  }

  const onClickProduct = async (product: IProduct) => {
    updateMainState({
      productState: {
        ...state.productState,
        selectedProduct: product,
      },
    });

    const variants = product?.variants?.filter(variant => variant?.facility_access === true);

    if (variants?.length > 1) {
      updateMainState({
        productState: {
          ...state.productState,
          variantSheetOpen: true,
          variants,
        },
      });
    } else {
      if (variants?.[0]) {
        await onAddProduct(variants[0]);
      } else {
        dispatch(showError(t("elements.tee_sheet.add_item_sheet.003")));
      }
    }
  };

  const onAddProduct = async (variant: IVariant) => {
    if (variant == null) {
      dispatch(showError(t("elements.tee_sheet.add_item_sheet.004")));
      return;
    }

    // Post line item, backend checks if item is already in cart. If so, quantity is updated accordingly
    const res = await PostLineItemToCart({ cart_id: props.cart.id, variant_id: variant.id, quantity: 1 }, true);
    if (res && res.status === StatusCode.OK) {
      dispatch(showSuccess(t("elements.tee_sheet.add_item_sheet.005")));
      void props.updateCart(res.data.data);
    } else if (res && res?.status === StatusCode.BAD_REQUEST) {
      dispatch(showError(t("elements.tee_sheet.add_item_sheet.006")));
      const postCartRes = await PostCart(null, false);
      void props.updateCart(postCartRes.data.data);
    }

    closeAddVariantModal();
  };

  function renderProducts() {
    if (filterState.search && state.products) {
      const products = state.products?.sort((firstProd: IProduct, secondProd: IProduct) => {
        if (filterState.sort === "alpha") {
          if (firstProd.title < secondProd.title) {
            return -1;
          } else if (firstProd.title > secondProd.title) {
            return 1;
          } else {
            return 0;
          }
        }
      }) as [];
      return { useGridPositions: false, products };
    } else {
      const products = state.selectedFolder?.products ?? ([] as []);
      return { useGridPositions: true, products };
    }
  }

  async function handleChangeSelectedFolder(selectedFolder: IFolder) {
    if (selectedFolder?.id === undefined) {
      return;
    }

    const storedProductFolders: IFolder[] = JSON.parse(localStorage.getItem(PRODUCT_FOLDERS_KEY)) ?? [];
    const storedProductFolder = storedProductFolders.find(productFolder => productFolder?.id === selectedFolder.id);

    const selectedFolderDate = new Date(selectedFolder.updated_at);
    const storedProductFolderDate = new Date(storedProductFolder?.updated_at);

    const storedProductFolderIsValid =
      !isNaN(selectedFolderDate?.getTime()) &&
      !isNaN(storedProductFolderDate?.getTime()) &&
      storedProductFolderDate.getTime() >= selectedFolderDate.getTime();

    if (storedProductFolderIsValid) {
      updateMainState({ selectedFolder: storedProductFolder });
    } else {
      const folderRes = await GetFolder({ id: selectedFolder.id, extended: true }, true);

      if (folderRes.status !== StatusCode.OK || folderRes.data?.length === undefined || folderRes.data.length === 0) {
        dispatch(showError(t("elements.tee_sheet.add_item_sheet.007")));
        return;
      }

      const newSelectedFolder = folderRes.data[0];

      if (!storedProductFolder) {
        const updatedStoredProductFolders = [...storedProductFolders, newSelectedFolder];
        localStorage.setItem(PRODUCT_FOLDERS_KEY, JSON.stringify(updatedStoredProductFolders));
      } else {
        const updatedStoredProductFolders = storedProductFolders.map(productFolder => {
          if (productFolder.id === newSelectedFolder.id) {
            return newSelectedFolder;
          } else {
            return productFolder;
          }
        });

        localStorage.setItem(PRODUCT_FOLDERS_KEY, JSON.stringify(updatedStoredProductFolders));
      }

      updateMainState({ selectedFolder: newSelectedFolder });
    }
  }

  function refreshFolderStorage() {
    localStorage.removeItem(PRODUCT_FOLDERS_KEY);

    if (state.selectedFolder) {
      void handleChangeSelectedFolder(state.selectedFolder);
    }

    dispatch(showSuccess(t("elements.tee_sheet.add_item_sheet.008")));
  }

  function handleBarcodeSearchClick() {
    const productSearchId = document.getElementById("productSearchId");
    productSearchId.focus();
    updateFilterState({ barcodeSearch: !filterState.barcodeSearch });
  }

  return (
    <Sheet
      title={props.title}
      open={props.open}
      size="large"
      closable
      onCancel={props.onCancel}
      onOk={props.onOk}
      okText={props.okText}
    >
      <div className="ui-register">
        <div className="ui-register-folders_container">
          <FilterMenu
            options={state.folders}
            optionTitle="title"
            defaultSelected={state.folders.findIndex(folder => folder.id === state.selectedFolder?.id)}
            onClick={(option, selectedIndex) => handleChangeSelectedFolder(option)}
          />
        </div>
        <div className="ui-register-products_container">
          <div className="ui-register-products_content">
            <div className="ui-register-top-bar">
              <div className="flex justify-between mb-4">
                <div className="flex-grow">
                  <Input
                    id="productSearchId"
                    value={filterState.search}
                    onChange={(value: any) => updateFilterState({ search: value.target.value })}
                    type="search"
                    placeholder={t("elements.tee_sheet.add_item_sheet.009")}
                    labelHidden
                    connectedRight={
                      <Button
                        style={{ backgroundColor: !filterState.barcodeSearch ? "white" : "lightgrey" }}
                        onClick={handleBarcodeSearchClick}
                      >
                        <FontAwesomeIcon icon={["far", "barcode-read"]} />
                      </Button>
                    }
                    trailingButtons={[
                      <Button key={1} type="secondary" onClick={refreshFolderStorage}>
                        <Icon style="far" icon="refresh" size="medium" />
                      </Button>,
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className="overflow-auto">
              <Products
                {...renderProducts()}
                productState={state.productState}
                onClick={onClickProduct}
                onCancel={closeAddVariantModal}
                onConfirmVariant={onAddProduct}
              />
            </div>
          </div>
        </div>
      </div>
    </Sheet>
  );
};

export default AddItemSheet;
