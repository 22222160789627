import React, { useState } from "react";

import { StatusCode } from "api/protocols";
import { GetShifts } from "api/rpc/facilityAdmin/client/shifts";

import { ButtonNew } from "components/buttonNew";
import Card from "components/card/Card";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import Page from "components/page/Page";

import { useTranslation } from "react-i18next";

export default function Shifts(props: any) {
  const { t, i18n } = useTranslation();
  const [date, setDate] = useState<Date | null>(null);

  async function handleShifts() {
    const ShiftsRes = await GetShifts({ date: date.toDateString() }, true);

    if (ShiftsRes.status !== StatusCode.OK) {
      console.log(ShiftsRes.message);
      return;
    }

    window.open().document.write(ShiftsRes.data);
  }

  return (
    <Page
      title={t("secure.facility.settings.shifts.shifts.001")}
      subtitle={t("secure.facility.settings.shifts.shifts.002")}
      narrow
    >
      <Card>
        <Card.Section>
          <p className="mt-2">{t("secure.facility.settings.shifts.shifts.003")}</p>
          <DatePickerInput
            months={2}
            position={"left"}
            startingDate={date}
            setStartingDate={returnDate => setDate(returnDate)}
          />
          <ButtonNew className="mt-4" type="default" onClick={handleShifts}>
            {t("secure.facility.settings.shifts.shifts.004")}
          </ButtonNew>
        </Card.Section>
      </Card>
    </Page>
  );
}
