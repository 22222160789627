import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { IGateway, IKind, IPaymentMethod, IProcessingType, ISource } from "redux/reducers/models/transaction";

const apiVersion = "2021-01";
const newApiVersion = "2022-09";
const adminType = "facility-admin";
const baseCustomerUrl = "/" + apiVersion + "/" + adminType + "/customer";
const houseAccountUrl = "/" + apiVersion + "/" + adminType + "/client";
const houseAccountPaymentUrl = "/2022-09/" + adminType + "/client/account/transaction";

const newBaseCustomerUrl = "/" + newApiVersion + "/" + adminType + "/customer";

interface IGetCustomer {
  id?: number;
  search?: string;
  state?: string;
  "address-lines"?: boolean;
  "default-address-line"?: boolean;
  memberships?: boolean;
  limit?: number;
  offset?: number;
}
interface ICustomer {
  id?: number;
  first_name?: string;
  last_name?: string;
  phone?: string;
  email?: string;
  member_number?: number;
}

interface ICustomerRes {
  status: number;
  message?: string;
  data: any;
}

interface IPostCustomerAccountTransaction {
  kind: IKind;
  amount: number;
  account_id: number;
  payment_option_id: number;
  gift_card_code?: string;
  gift_card_pin?: string;
  note?: string;
}

interface IGetCustomerAccountTransaction {
  account_id: number;
}

export interface IAccountTransaction {
  account_id: number;
  amount: number;
  authorization_id: number;
  client_id: number;
  client_secret: string;
  currency: string;
  device: string;
  error_code: string;
  facility_id: number;
  gateway: string;
  id: number;
  kind: string;
  note: string;
  order_transaction_id: number;
  parent_id: number;
  payment_intent_id: string;
  payment_last_4: string;
  payment_method: string;
  payment_type: string;
  processed_at: string;
  processing_type: string;
  source: string;
}
export interface IPostAccountTransactionResponse {
  data: IAccountTransaction;
  status: number;
}

interface ICustomerAccountTransactionCapture {
  transaction_id: number;
  payment_intent_id: string;
}

interface IPostCustomerAddressLine {
  customer_id: number;
  first_name?: string;
  last_name?: string;
  address_line_1?: string;
  address_line_2?: string;
  city?: string;
  postal?: string;
  phone?: string;
  province_id: number;
  country_id: number;
  default: boolean;
}

interface IPutCustomerAddressLine extends IPostCustomerAddressLine {
  id: number;
}

interface IDeleteCustomerAddressLine {
  customer_id: number;
  address_line_id: number;
}

// GET Cart
// Retrieve a list of customers
export async function GetCustomer(
  params: IGetCustomer,
  useGlobalLoader?: boolean,
  cancelToken?: CancelToken,
): Promise<ICustomerRes> {
  return await APIGet(newBaseCustomerUrl, params, useGlobalLoader, cancelToken);
}

export async function PostCustomer(params: ICustomer, useGlobalLoader: boolean): Promise<ICustomerRes> {
  return await APIPost(baseCustomerUrl, params, useGlobalLoader);
}

export async function PutCustomer(params: any, useGlobalLoader: boolean): Promise<ICustomerRes> {
  return await APIPut(newBaseCustomerUrl, params, useGlobalLoader);
}

export async function DeleteCustomer(params: { id: number }, useGlobalLoader: boolean): Promise<ICustomerRes> {
  return await APIDelete(newBaseCustomerUrl, params, useGlobalLoader);
}

export async function PostCustomerAddressLine(params: IPostCustomerAddressLine, useGlobalLoader: boolean) {
  return await APIPost(newBaseCustomerUrl + "/address-line", params, useGlobalLoader);
}

export async function PutCustomerAddressLine(params: IPutCustomerAddressLine, useGlobalLoader: boolean) {
  return await APIPut(newBaseCustomerUrl + "/address-line", params, useGlobalLoader);
}

export async function DeleteCustomerAddressLine(params: IDeleteCustomerAddressLine, useGlobalLoader: boolean) {
  return await APIDelete(newBaseCustomerUrl + "/address-line", params, useGlobalLoader);
}

interface IPostPaymentMethodParams {
  payment_method_id: string;
  customer_id: number;
  save_card: boolean;
}

interface IGetCustomerPaymentMethodParams {
  id?: number;
  customer_id?: number;
}

export async function PostSetup(params: { customer_id: number }, useGlobalLoader?: boolean) {
  return await APIPost(baseCustomerUrl + "/payment-method/setup", params, useGlobalLoader);
}

export async function GetCustomerPaymentMethod(
  params: IGetCustomerPaymentMethodParams,
  useGlobalLoader?: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseCustomerUrl + "/payment-method", params, useGlobalLoader, token);
}

export async function PostPaymentMethod(params: IPostPaymentMethodParams, useGlobalLoader?: boolean) {
  return await APIPost(baseCustomerUrl + "/payment-method", params, useGlobalLoader);
}

export async function DeletePaymentMethod(params: IGetCustomerPaymentMethodParams, useGlobalLoader?: boolean) {
  return await APIDelete(baseCustomerUrl + "/payment-method", params, useGlobalLoader);
}

export async function GetCustomerHouseAccounts(
  params: { customer_id: number },
  useGlobalLoader?: boolean,
  token?: CancelToken,
): Promise<ICustomerRes> {
  return await APIGet(houseAccountUrl + "/account", params, useGlobalLoader, token);
}

export async function GetCustomerMemberships(params: { customer_id: number }, useGlobalLoader?: boolean) {
  return await APIGet(baseCustomerUrl + "/membership", params, useGlobalLoader);
}

export async function SendPasswordReset(params: ICustomer, useGlobalLoader: boolean) {
  return await APIPost(baseCustomerUrl + "/reset", params, useGlobalLoader);
}

export async function PostAccountTransaction(
  params: IPostCustomerAccountTransaction,
  useGlobalLoader: boolean,
): Promise<IPostAccountTransactionResponse> {
  return await APIPost(houseAccountPaymentUrl, params, useGlobalLoader);
}

export async function PutAccountTransactionCapture(
  params: ICustomerAccountTransactionCapture,
  useGlobalLoader: boolean,
) {
  return await APIPut(houseAccountPaymentUrl + "/capture", params, useGlobalLoader);
}

export async function GetAccountTransactions(params: IGetCustomerAccountTransaction, useGlobalLoader: boolean) {
  return await APIGet(houseAccountPaymentUrl, params, useGlobalLoader);
}

export async function GetAccountTransactionReceipt(
  params: { account_transaction_id: number },
  useGlobalLoader: boolean,
) {
  return await APIGet(houseAccountPaymentUrl + "/receipt", params, useGlobalLoader);
}

export async function PutCancelAccountTransaction(
  params: { transaction_id: number; payment_intent_id: string | number },
  useGlobalLoader: boolean,
) {
  return await APIPut(houseAccountPaymentUrl + "/cancel", params, useGlobalLoader);
}

export async function GetCustomerStatements(params: any, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(houseAccountUrl + "/account/statement", params, useGlobalLoader, token);
}

export async function DownloadCustomerAccountStatements(params: any, useGlobalLoader: boolean) {
  return await APIGet(houseAccountUrl + "/account/statement/download", params, useGlobalLoader);
}

// Upload Customer Profile Image
export async function UploadCustomerProfileImage(params: any, useGlobalLoader: boolean) {
  return await APIPost(newBaseCustomerUrl + "/profile-image", params, useGlobalLoader);
}
