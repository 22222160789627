import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useHistory } from "react-router-dom";
import "./TransactionalHeader.scss";

interface ITransactionalHeaderProps {
  facilityShortName: string;
  facilityLogoSource: string;
  shoppingBagHasItems: boolean;
}

export default function TransactionalHeader(props: ITransactionalHeaderProps) {
  const history = useHistory();

  function navigateToHomePage() {
    history.push(`/online-store/${props.facilityShortName}`);
  }

  function navigateToShoppingBag() {
    history.push(`/online-store/${props.facilityShortName}/shoppingBag`);
  }

  return (
    <div className="transactional-header">
      <ul className="transactional-header-grid">
        <li className="transactional-header-grid-cell">
          <button onClick={navigateToHomePage}>
            <img className="transactional-header-facility-logo" src={props.facilityLogoSource} />
          </button>
        </li>
        <li className="transactional-header-grid-cell">
          <div className="transactional-header-shopping-bag">
            <button onClick={navigateToShoppingBag}>
              <FontAwesomeIcon icon={["fal", "shopping-bag"]}></FontAwesomeIcon>
            </button>
            {props.shoppingBagHasItems && <span className="transactional-header-shopping-bag-dot"></span>}
          </div>
        </li>
      </ul>
    </div>
  );
}
