import Sheet from "components/sheet/Sheet";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ICartState } from "redux/reducers/cart";
import Input from "components/form/input/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select } from "components/select/index";
import { StatusCode } from "api/protocols";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { showError } from "redux/actions/ui";
import axios, { CancelToken } from "axios";
import { GetCustomer } from "api/rpc/2024-04/facilityAdmin/customer/customer";

interface IItemActionsState {
  cartDescription: string;
  selectedCustomer: any;
  customerQuery: string;
  searchingCustomer: boolean;
  searchResults: any[];
}

interface IEditSeatDetailsProps {
  editSeatVisible: boolean;
  onCancel?: () => void;
  onOk?: (priceChangeInfo: IItemActionsState) => void;
  /**The cart's current customer and description: Object {customer: CustomerObject; description: string} */
  prevCartDetails: {
    customer: Record<string, any>;
    description: string;
  };
}

function EditSeatDetails(props: IEditSeatDetailsProps) {
  const { t, i18n } = useTranslation();
  const { Option } = Select;
  const dispatch = useAppDispatch();
  const { cartStore } = useAppSelector(store => store);
  const [itemActionsState, setItemActionsState] = useState<IItemActionsState>({
    cartDescription: "",
    selectedCustomer: null,
    customerQuery: "",
    searchingCustomer: false,
    searchResults: [],
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = global.setTimeout(() => {
        void searchForCustomers(mounted, itemActionsState.customerQuery, source.token);
      }, 300);
    }
    return () => {
      source.cancel("Cancelled");
      mounted = false;
      clearTimeout(timeoutId);
      setItemActionsState(prevState => ({ ...prevState, searchResults: [], searchingCustomer: false }));
    };
  }, [itemActionsState.customerQuery]);

  useEffect(() => {
    if (props.editSeatVisible) {
      setItemActionsState(prevState => ({
        ...prevState,
        selectedCustomer: props.prevCartDetails?.customer ? props.prevCartDetails?.customer : null,
        cartDescription: props.prevCartDetails?.description ? props.prevCartDetails?.description : "",
      }));
    }
  }, [props.editSeatVisible]);

  function onOk() {
    void props.onOk({ ...itemActionsState });
    setItemActionsState(prevState => ({
      ...prevState,
      selectedCustomer: null,
      customerQuery: "",
      searchResults: [],
      searchingCustomer: false,
      cartDescription: "",
    }));
  }

  function onCancel() {
    setItemActionsState(prevState => ({
      ...prevState,
      selectedCustomer: null,
      customerQuery: "",
      searchResults: [],
      searchingCustomer: false,
      cartDescription: "",
    }));
    props.onCancel();
  }

  function handleLineItemInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setItemActionsState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleRemoveCustomer() {
    setItemActionsState(prevState => ({
      ...prevState,
      selectedCustomer: null,
      customerQuery: "",
      searchResults: [],
    }));
  }

  function handleCustomerSearch(query: string) {
    setItemActionsState(prevState => ({ ...prevState, customerQuery: query }));
  }

  function handleCustomerSelection(id: number, customer: Record<string, any>) {
    setItemActionsState(prevState => ({
      ...prevState,
      selectedCustomer: customer,
      customerQuery: "",
      searchResults: [],
    }));
  }

  async function searchForCustomers(mounted: boolean, customerQuery: string, cancelToken: CancelToken) {
    try {
      if (customerQuery === "") {
        if (mounted) {
          setItemActionsState(prevState => ({ ...prevState, searchResults: [], searchingCustomer: false }));
        }
        return;
      } else {
        setItemActionsState(prevState => ({ ...prevState, searchingCustomer: true }));
        const customerResults = await loadCustomers(customerQuery, cancelToken);
        setItemActionsState(prevState => ({ ...prevState, searchingCustomer: false }));
        if (mounted && customerResults) {
          setItemActionsState(prevState => ({ ...prevState, searchResults: customerResults }));
        }
      }
    } catch (error) {
      setItemActionsState(prevState => ({ ...prevState }));
    }
    return;
  }

  async function loadCustomers(customerQuery: string, cancelToken: CancelToken) {
    const customerRes = await GetCustomer({ search: customerQuery }, false, cancelToken);

    if (customerRes.status !== StatusCode.OK || !customerRes?.data) {
      if (customerRes?.message !== "Cancelled") {
        dispatch(showError(customerRes.message));
      }

      return [];
    }

    return customerRes?.data as Array<Record<string, any>>;
  }

  return (
    <Sheet
      open={props.editSeatVisible}
      size="small"
      closable
      title={t("components.edit_seat_details.edit_seat_details.001")}
      onCancel={onCancel}
      onOk={onOk}
      cancelText={t("components.edit_seat_details.edit_seat_details.002")}
      okText={t("components.edit_seat_details.edit_seat_details.003")}
      okDisabled={!cartStore?.cart ? true : false}
      overflow
    >
      <Input
        placeholder={t("components.edit_seat_details.edit_seat_details.004")}
        label={t("components.edit_seat_details.edit_seat_details.005")}
        id="cartDescription"
        value={itemActionsState.cartDescription}
        onChange={handleLineItemInputChange}
      />
      <p className="mt-4 mb-2 font-medium">{t("components.edit_seat_details.edit_seat_details.006")}</p>
      {itemActionsState.selectedCustomer ? (
        <div className="flex flex-row justify-start">
          <div className="mr-3 mt-2">
            <FontAwesomeIcon icon={["far", "user"]} style={{ fontSize: "24px", verticalAlign: "middle" }} />
          </div>
          <div>
            <p>{itemActionsState.selectedCustomer?.full_name}</p>
            <p className="text-subdued">{itemActionsState.selectedCustomer?.email}</p>
          </div>
          <div className="ml-auto">
            <a style={{ marginRight: "0.8rem" }} className="cursor-pointer" onClick={handleRemoveCustomer}>
              {t("components.edit_seat_details.edit_seat_details.007")}
            </a>
          </div>
        </div>
      ) : (
        <Select
          showSearch
          onSearch={(query: string) => handleCustomerSearch(query)}
          onChange={(id: number, friendObject: Record<string, any>) => handleCustomerSelection(id, friendObject)}
          allowClear
          searchValue={itemActionsState.customerQuery}
          searching={itemActionsState.searchingCustomer}
          placeholder={t("components.edit_seat_details.edit_seat_details.008")}
          noData={
            <div className="flex items-center p-2 text-black">
              <FontAwesomeIcon icon={["fas", "users-slash"]} fixedWidth size="1x" />
              <span className="ml-3 italic">No matching customer found...</span>
            </div>
          }
        >
          {itemActionsState.searchResults?.map((customer, index) => {
            return (
              <Option key={index} value={customer.id} extraValues={customer}>
                <div className="flex justify-between">
                  <div>
                    <div className="font-semibold text-lg text-black">{customer?.full_name}</div>
                    <div className="text-sm text-gray-500">{customer?.email}</div>
                  </div>
                </div>
              </Option>
            );
          })}
        </Select>
      )}
    </Sheet>
  );
}

export default EditSeatDetails;
