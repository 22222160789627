import { APIGet, APIPost, APIPut, APIDelete, IAPIResponse, APIUrl, AdminType } from "api/protocols";
import { CancelToken } from "axios";
import { TCustomerTag } from "redux/reducers/models/customer";

export interface ICustomerSegment {
  client_id: number;
  created_at: string;
  description: string;
  id: number;
  title: string;
  updated_at: string;
  tags?: Array<TCustomerTag>;
}

interface IGetCustomerSegments {
  id?: number;
  select?: string;
  extended?: boolean;
}

interface IPostCustomerSegment {
  title: string;
  description: string;
}

interface IPutCustomerSegment extends IPostCustomerSegment {
  segment_id: number;
}

interface IPutCustomerSegmentTags {
  segment_id: number;
  tag_ids: Array<number>;
}

interface IPutCustomerSegmentCustomers {
  segment_id: number;
  customer_ids: Array<number>;
}

export async function GetCustomerSegments(
  params: IGetCustomerSegments,
  useGlobalLoader: boolean,
  cancelToken?: CancelToken,
) {
  return await APIGet<Array<ICustomerSegment>>(
    APIUrl(AdminType.FacilityAdmin, "/customer/segment"),
    params,
    useGlobalLoader,
    cancelToken,
  );
}

export async function PostCustomerSegment(params: IPostCustomerSegment, useGlobalLoader: boolean) {
  return await APIPost(APIUrl(AdminType.FacilityAdmin, "/customer/segment"), params, useGlobalLoader);
}

export async function PutCustomerSegment(params: IPutCustomerSegment, useGlobalLoader: boolean) {
  return await APIPut<ICustomerSegment>(APIUrl(AdminType.FacilityAdmin, "/customer/segment"), params, useGlobalLoader);
}

export async function PutCustomerSegmentTags(params: IPutCustomerSegmentTags, useGlobalLoader: boolean) {
  return await APIPut(APIUrl(AdminType.FacilityAdmin, "/customer/segment/tag"), params, useGlobalLoader);
}

export async function GetCustomerSegmentCustomers(
  params: { segment_id: number },
  useGlobalLoader: boolean,
  cancelToken?: CancelToken,
) {
  return await APIGet(
    APIUrl(AdminType.FacilityAdmin, "/customer/segment/customer"),
    params,
    useGlobalLoader,
    cancelToken,
  );
}

export async function PutCustomerSegmentCustomers(params: IPutCustomerSegmentCustomers, useGlobalLoader: boolean) {
  return await APIPut(APIUrl(AdminType.FacilityAdmin, "/customer/segment/customer"), params, useGlobalLoader);
}
