import React, { ChangeEvent, useEffect, useMemo } from "react";

import { FilterValue, addFilter, applyDefaults, removeFilter } from "redux/actions/filters/filters";
import { ComponentKey, OrderFilterGrouping } from "redux/reducers/filters/types";

import { useAppDispatch, useAppSelector } from "hooks/redux";

import { useFilterAccordionContext } from "components/accordion/Accordion";
import Checkbox from "components/form/checkbox/Checkbox";
import { IPaymentOption } from "redux/reducers/models/transaction";

type FilterList = {
  payments: IPaymentOption[];

  /** Value provided by FilterAccordionContext takes precedent if present. */
  collection?: ComponentKey;
  defaultValues?: number[];
  condensed?: boolean;
};

export default function PaymentTypeFilterList(props: FilterList) {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(store => store.orderPageFilters);
  const { collectionContext } = useFilterAccordionContext();

  const labelArray = useMemo(() => {
    if (props.payments) {
      const active = props.payments.filter(val => filters.some(condition => condition.value.includes(val.id)));
      return active.map(filter => filter.title);
    }
    return [];
  }, [filters, props.payments]);

  useEffect(() => {
    if (!props.payments || props.payments === undefined || !props.defaultValues) {
      return;
    }

    if (!filters.find(group => group.label === "payment_type")) {
      const defaultActive = props.payments.filter(type => props.defaultValues.some(valId => valId === type.id));

      dispatch(
        applyDefaults<OrderFilterGrouping>({
          collectionName: collectionContext ? collectionContext : props.collection,
          key: "payment_type",
          value: defaultActive.map(type => type.id) as FilterValue[],
          displayLabels: defaultActive.map(type => type.title),
        }),
      );
    }
  }, [props.defaultValues, props.payments]);

  function listItemClick(prevChecked: boolean, id: number) {
    const newFilter = props.payments.filter(val => val.id === id)[0];
    const newLabel = props.payments
      .filter(val => labelArray.includes(val.title) || val.title === newFilter.title)
      .map(ele => ele.title);

    if (!prevChecked) {
      dispatch(
        addFilter<OrderFilterGrouping>({
          collectionName: collectionContext ? collectionContext : props.collection,
          key: "payment_type",
          value: id,
          displayLabels: newLabel,
        }),
      );
      return;
    }

    // remove filter condition if unchecked
    dispatch(
      removeFilter<OrderFilterGrouping>({
        collectionName: collectionContext ? collectionContext : props.collection,
        key: "payment_type",
        value: id,
        displayLabels: labelArray.filter(val => val !== newFilter.title),
      }),
    );
  }

  if (props.payments === undefined) {
    return null;
  } else {
    return (
      <ul className={`filter-list${props.condensed ? " condensed" : ""}`}>
        {props.payments.map((type, index) => {
          const checked = filters.some(condition => {
            return condition.value.includes(type.id);
          });

          return (
            <li key={index} onClick={() => listItemClick(checked, type.id)}>
              <Checkbox
                checked={checked}
                ariaChecked={checked}
                onChange={(e: ChangeEvent<HTMLInputElement>) => e.stopPropagation()}
                label={type.title}
                size="small"
              />
            </li>
          );
        })}
      </ul>
    );
  }
}
