import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { StatusCode } from "api/protocols";
import { PostDashboard } from "api/rpc/clientAdmin/client/dashboard";

import { showError, showSuccess } from "redux/actions/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Input from "components/form/input/Input";

interface IDashboardState {
  title: string;
}

export default function DashboardsNew() {
  const history = useHistory();

  const dispatch = useAppDispatch();
  const { authStore } = useAppSelector(store => store);

  const [state, setState] = useState<IDashboardState>({
    title: "",
  });

  async function saveNewDashboard() {
    if (!state.title) {
      dispatch(showError("Title required")); // TODO: Translation
      return;
    }

    const res = await PostDashboard({ user_id: authStore?.user?.id, title: state.title }, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError(res.data?.message));
      return;
    }

    dispatch(showSuccess(res.data?.message));
    history.push("/admin/dashboard/settings");
  }

  const primaryAction = {
    content: "Save", // TODO: Translation
    action: saveNewDashboard,
  };

  return (
    <Page
      title="New Dashboard" // TODO: Translation
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        { prefix: true, label: "Dashboard Settings", /* TODO: Translation */ url: "/admin/dashboard/settings" },
      ]}
    >
      <Card>
        <Card.Section>
          <Input
            value={state.title}
            label="Title" // TODO: Translation
            id="title"
            onChange={e => setState(prevState => ({ ...prevState, title: e.target.value }))}
            placeholder="Title" // TODO: Translation
            className="mb-2"
          />
        </Card.Section>
      </Card>
    </Page>
  );
}
