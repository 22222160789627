import React, { useState, ChangeEvent } from "react";
import { useHistory } from "react-router-dom";

import { StatusCode } from "api/protocols";

import { TProductType } from "redux/reducers/models/product";

import { showError } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Input from "components/form/input/Input";
import { TPostProductTypeParams } from "api/rpc/2022-09/facilityAdmin/product/product";
import { PostProductType } from "api/rpc/2024-04/facilityAdmin/product/type";

export default function ProductTypeNew() {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [type, setType] = useState<Partial<TProductType>>({
    // id: null,
    // client_id: null,
    title: "",
    // locked: false,
  });

  async function addProductType() {
    const params: TPostProductTypeParams = {
      title: type.title,
    };

    const res = await PostProductType(params, true);
    console.log(res);

    if (res.status !== StatusCode.OK) {
      dispatch(showError(res.data ? res.data : "There was an error adding the new product type."));
      return;
    }

    history.push("/admin/settings/types");
  }

  return (
    <Page
      title="New Product Type"
      breadcrumbs={[{ prefix: true, label: "Types", url: "/admin/settings/types" }]}
      primaryAction={{
        action: () => addProductType(),
        content: "Save",
        disabled: type.title.length === 0,
      }}
    >
      <Card>
        <Card.Section>
          <Input
            label="Title"
            value={type.title}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setType(prev => ({ ...prev, title: e.target.value }))}
          />
        </Card.Section>
      </Card>
    </Page>
  );
}
