import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";

const apiVersion = "2021-01";
const adminType = "customer";
const baseCustomerUrl = "/" + apiVersion + "/" + adminType + "/payment-method";

interface IPostPaymentMethodParams {
  payment_method_id: string;
  facility_id: number;
  save_card: boolean;
}
export async function PostSetup(useGlobalLoader?: boolean) {
  return await APIPost(baseCustomerUrl + "/setup", undefined, useGlobalLoader);
}

export async function PostPaymentMethod(params: IPostPaymentMethodParams, useGlobalLoader?: boolean) {
  return await APIPost(baseCustomerUrl, params, useGlobalLoader);
}
