import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StatusCode } from "api/protocols";
import { UserActive, UserLogout } from "api/rpc";
import { GetFacility } from "api/rpc/guest/facility";
import {
  GetReservationLocation,
  GetReservationModule,
  GetReservationSegment,
  PostReservationBooking,
} from "api/rpc/guest/reservations/reservations";
import Card from "components/card/Card";
import Radio from "components/radio";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { IReservationActions } from "redux/actions/reservations";
import { IUIActions } from "redux/actions/ui";
import { IReservationState as IReservationStore } from "redux/reducers/reservations";
import "./reservation.scss";
import { ReservationsNavigation } from "./ReservationsNavigation/ReservationsNavigation";

import { Select } from "components/select/index";
import ButtonGroup from "components/button/ButtonGroup";
import { ButtonNew as Button } from "components/buttonNew";
import { ISegment } from "redux/reducers/models/reservations";
import ReactDOM from "react-dom";
import Spin from "components/spin/spin";
import Checkbox from "components/form/checkbox/Checkbox";
import Sheet from "components/sheet/Sheet";
import Markdown from "components/markdown/Markdown";
import { GetCustomerPaymentMethods } from "api/rpc/bookingEngine/customer";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input";
import CardSection from "components/cardSection/CardSection";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";
import { PostPaymentMethod, PostSetup } from "api/rpc/customer/paymentMethod";
import { capitalize } from "helpers/Helpers";
import { LocaleCurrency } from "helpers/Locale";
import { GetCustomerReservationSegment } from "api/rpc/facilityAdmin/customer/reservations";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
interface IProps {
  uiActions: IUIActions;
  reservationStore: IReservationStore;
  reservationActions: IReservationActions;
}

interface IParams {
  facilityShortName: string;
  moduleHandle: string;
}

interface IReservationState {
  guestsAmount: number;
  /**The selected duration key string ('1 Hour', '1.5 Hours'... '5 Hours') */
  durationKey: string;
  segments: Array<ISegment>;
  /**Object containing the arrays of segment ids */
  durations: { [key: string]: Array<number> };
  /**Object containing arrays of the segment objects (used for displaying the time and price of the selected segments) */
  durationSegments: { [key: string]: Array<ISegment> };
  acceptTerms: boolean;
  paymentTermsActive: boolean;
  selectedCardId: number;
  customerCreditCards: Array<Record<string, any>>;
  customerGiftCards: Array<Record<string, any>>;
  customerRainChecks: Array<Record<string, any>>;
  newCardActive: boolean;
  elementComplete: {
    cardNumber: boolean;
    cardExpiry: boolean;
    cardCvc: boolean;
  };
  newCreditCardName: string;
  saveCard: boolean;
}

export default function Reservation(props: IProps) {
  const { uiActions, reservationStore, reservationActions } = props;
  const { Option } = Select;
  const { facilityShortName, moduleHandle } = useParams<IParams>();
  const [activeUser, setActiveUser] = useState<boolean>(false);
  const [reservationState, setReservationState] = useState<IReservationState>({
    guestsAmount: 1,
    durationKey: "0.5 Hour",
    segments: [],
    durations: null,
    durationSegments: null,
    acceptTerms: false,
    paymentTermsActive: false,
    selectedCardId: undefined,
    customerCreditCards: [],
    customerGiftCards: [],
    customerRainChecks: [],
    newCardActive: false,
    elementComplete: {
      cardNumber: false,
      cardExpiry: false,
      cardCvc: false,
    },
    newCreditCardName: "",
    saveCard: false,
  });

  const stripe = useStripe();
  const elements = useElements();

  const history = useHistory();

  const queryString = window.location.search;

  const locationId = Number(new URLSearchParams(queryString).get("location_id"));
  const date = new URLSearchParams(queryString).get("date");
  const startTime = new URLSearchParams(queryString).get("start_time");

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (reservationStore?.facility?.client_id && reservationStore?.selectedSegment?.credit_card_required) {
        void getCustomerPaymentMethods(reservationStore?.facility?.client_id);
      }
      void getCustomerLoggedIn();
    }

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    //Page was refreshed, get module & location
    if (
      reservationStore?.active_user !== null &&
      (reservationStore?.module === null || reservationStore?.selectedLocation === null) &&
      mounted
    ) {
      void getModule();
    } else if (
      reservationStore?.active_user !== null &&
      (reservationStore?.module !== null || reservationStore?.selectedLocation !== null) &&
      mounted
    ) {
      void getSegments();
    }

    return () => {
      mounted = false;
    };
  }, [reservationStore?.active_user]);

  async function getSegments() {
    let segmentsRes: { status: number; data: any; message: any };

    if (reservationStore?.active_user) {
      segmentsRes = await GetCustomerReservationSegment(
        { module_id: reservationStore?.module?.id, date: date, start_time: startTime, location_id: locationId },
        true,
      );
      if (segmentsRes?.status !== StatusCode.OK) {
        uiActions.showError("Error getting segments");
        return;
      }
    } else {
      segmentsRes = await GetReservationSegment(
        { module_id: reservationStore?.module?.id, date: date, start_time: startTime, location_id: locationId },
        true,
      );
      if (segmentsRes?.status !== StatusCode.OK) {
        uiActions.showError("Error getting segments");
        return;
      }
    }

    void setDurations(segmentsRes?.data);

    setReservationState(prevState => ({ ...prevState, segments: segmentsRes?.data }));
  }

  async function getCustomerLoggedIn() {
    if (!reservationStore.active_user) {
      uiActions.enqueue();
      const activeUserRes = await UserActive(false);
      uiActions.dequeue();
      if (activeUserRes.status === StatusCode.OK) {
        reservationActions.update({ active_user: activeUserRes?.data });
        setActiveUser(true);
      } else {
        //User is not logged in, send them back to the reservations page
        setActiveUser(false);
        uiActions.showError("User is not logged in. Please log in and try again.");
        void returnToReservationsPage();
      }
    } else {
      setActiveUser(true);
    }
  }

  function returnToReservationsPage() {
    reservationActions.update({ selectedLocation: null, selectedSegment: null });
    history.push(`/${facilityShortName}/${moduleHandle}`);
  }

  async function getModule() {
    uiActions.enqueue();
    const moduleRes = await GetReservationModule(
      { facility_short_name: facilityShortName, handle: moduleHandle },
      false,
    );
    if (moduleRes.status !== StatusCode.OK) {
      uiActions.showError("Error getting module");
      uiActions.dequeue();
      return;
    }
    const facilityRes = await GetFacility({ id: moduleRes?.data?.facility_id }, false);
    if (facilityRes?.status !== StatusCode.OK) {
      uiActions.showError("Error getting facility");
      uiActions.dequeue();
      return;
    }
    const locationRes = await GetReservationLocation(
      { id: locationId, facility_id: moduleRes?.data?.facility_id },
      false,
    );
    if (locationRes.status !== StatusCode.OK) {
      uiActions.showError("Error getting location");
      uiActions.dequeue();
      return;
    }

    let segmentsRes: { status: number; data: any; message: any };

    if (reservationStore?.active_user) {
      segmentsRes = await GetCustomerReservationSegment(
        { module_id: moduleRes?.data?.id, date: date, start_time: startTime, location_id: locationId },
        true,
      );
      if (segmentsRes?.status !== StatusCode.OK) {
        uiActions.showError("Error getting segments");
        uiActions.dequeue();
        return;
      }
    } else {
      segmentsRes = await GetReservationSegment(
        { module_id: moduleRes?.data?.id, date: date, start_time: startTime, location_id: locationId },
        true,
      );
      if (segmentsRes?.status !== StatusCode.OK) {
        uiActions.showError("Error getting segments");
        uiActions.dequeue();
        return;
      }
    }

    if (segmentsRes?.data[0]?.credit_card_required) {
      const customerPaymentMethodsRes = await GetCustomerPaymentMethods(
        { client_id: facilityRes?.data[0]?.client_id, type: ["card", "gift_card", "rain_check"] },
        false,
      );
      if (customerPaymentMethodsRes.status !== StatusCode.OK) {
        uiActions.showError(customerPaymentMethodsRes.message);
        uiActions.dequeue();
        return;
      }

      setReservationState(prevState => ({
        ...prevState,
        customerCreditCards: customerPaymentMethodsRes.data?.card,
        customerGiftCards: customerPaymentMethodsRes.data?.gift_card,
        customerRainChecks: customerPaymentMethodsRes.data?.rain_check,
        selectedCardId:
          customerPaymentMethodsRes.data?.card?.length >= 1 ? customerPaymentMethodsRes.data?.card[0]?.id : undefined,
      }));
    }

    void setDurations(segmentsRes?.data);
    uiActions.dequeue();

    ReactDOM.unstable_batchedUpdates(() => {
      setReservationState(prevState => ({
        ...prevState,
        segments: segmentsRes?.data,
      }));
      reservationActions.update({
        module: moduleRes?.data,
        selectedLocation: locationRes?.data[0],
        facility: facilityRes?.data[0],
        selectedSegment: segmentsRes?.data[0],
      });
    });
  }

  function handleGuestAmountChange(amount: number) {
    setReservationState(prevState => ({ ...prevState, guestsAmount: amount }));
  }

  async function bookReservation() {
    if (
      !reservationStore?.module?.id ||
      !reservationStore?.facility?.id ||
      !locationId ||
      !date ||
      !reservationState?.guestsAmount ||
      !reservationState?.durations[reservationState?.durationKey] ||
      !reservationState?.acceptTerms
    ) {
      uiActions.showError("Booking Error, missing duration or guest quantity. User may not have accepted terms");
      return;
    }

    const reservationRes = await PostReservationBooking(
      {
        module_id: reservationStore?.module?.id,
        facility_id: reservationStore?.facility?.id,
        location_id: locationId,
        date: date,
        quantity: reservationState?.guestsAmount,
        segment_ids: reservationState.durations[reservationState.durationKey],
        accept_payment_terms: reservationState.acceptTerms,
        customer_payment_method_id: reservationState.selectedCardId,
      },
      true,
    );
    if (reservationRes?.status !== StatusCode.OK) {
      uiActions.showError("Error booking reservation");
      void returnToReservationsPage();
      return;
    }

    reservationActions.update({
      booking: reservationRes?.data,
      bookedSegments: reservationState?.durationSegments[reservationState?.durationKey],
    });

    //Booking successful, go to confirmation page
    history.push(
      `/${facilityShortName}/${moduleHandle}/confirmation?start_time=${startTime}&date=${date}&location_id=${locationId}&token=${
        reservationRes?.data?.token as string
      }`,
    );
  }

  function handleDropDownChange(value: string | number, id: string) {
    setReservationState(prevState => ({ ...prevState, [id]: value }));
  }

  function setDurations(segments: Array<ISegment>) {
    //We can only select segments from index 0-9 for 0.5 - 5 hours with 0.5 hour increments
    const max = Math.min(10, segments?.length);

    const durations: { [key: string]: Array<number> } = {};
    const durationSegments: { [key: string]: Array<ISegment> } = {};

    //Increment by 2 for 1 hour segment durations (1 segment = half hour)
    for (let i = 0; i < max; i++) {
      if (
        segments[i]?.blocked_type === "open" &&
        segments[i]?.booking_id === null &&
        //In the future, we will need to allow the customer to book a league/tournament they are apart of
        segments[i]?.league_id === null &&
        segments[i]?.tournament_id === null
      ) {
        //Set the durations object keys as the hours and the segment ids array as the value
        durations[`${i / 2 + 0.5} Hour${i > 1 ? "s" : ""}`] = segments
          ?.filter((filteredSegment: ISegment, index: number) => index <= i)
          ?.map((segment: ISegment) => segment?.id);
        durationSegments[`${i / 2 + 0.5} Hour${i > 1 ? "s" : ""}`] = segments?.filter(
          (filteredSegment: ISegment, index: number) => index <= i,
        );
      } else {
        break;
      }
    }

    //If no duration times are available to select, return to the home page
    if (Object.keys(durations)?.length === 0) {
      uiActions.showError("Time unavailable, please select another time");
      void returnToReservationsPage();
      return;
    }

    setReservationState(prevState => ({ ...prevState, durations, durationSegments }));
  }

  async function getCustomerPaymentMethods(clientId: number) {
    const customerPaymentMethodsRes = await GetCustomerPaymentMethods(
      { client_id: clientId, type: ["card", "gift_card", "rain_check"] },
      true,
    );
    if (customerPaymentMethodsRes.status !== StatusCode.OK) {
      uiActions.showError(customerPaymentMethodsRes.message);
      return;
    }

    setReservationState(prevState => ({
      ...prevState,
      customerCreditCards: customerPaymentMethodsRes.data?.card,
      customerGiftCards: customerPaymentMethodsRes.data?.gift_card,
      customerRainChecks: customerPaymentMethodsRes.data?.rain_check,
      selectedCardId:
        customerPaymentMethodsRes.data?.card?.length >= 1 ? customerPaymentMethodsRes.data?.card[0]?.id : undefined,
    }));
  }

  function handleCheckboxChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, checked } = e.target;
    setReservationState(prevState => ({ ...prevState, [id]: checked }));
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setReservationState(prevState => ({ ...prevState, [id]: value }));
  }

  function openNewCardModal() {
    setReservationState(prevState => ({ ...prevState, newCardActive: true }));
  }

  function closeAddNewCard() {
    elements.getElement(CardNumberElement).clear();
    elements.getElement(CardExpiryElement).clear();
    elements.getElement(CardCvcElement).clear();
    setReservationState(prevState => ({ ...prevState, newCardActive: false, newCreditCardName: "" }));
  }

  function handleCardSectionChange(
    e: StripeCardNumberElementChangeEvent | StripeCardExpiryElementChangeEvent | StripeCardCvcElementChangeEvent,
  ) {
    setReservationState(prevState => ({
      ...prevState,
      elementComplete: { ...prevState.elementComplete, [e.elementType]: e.complete },
    }));
  }

  async function handleAddNewCard() {
    if (!stripe || !elements) {
      uiActions.showError("Error occured before card could save. Please reattempt.");
      return;
    }
    const tempCreditCards = [...reservationState.customerCreditCards];
    uiActions.enqueue();
    try {
      const stripePaymentMethodRes = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement("cardNumber"),
        billing_details: {
          name: reservationState.newCreditCardName,
        },
      });

      if (stripePaymentMethodRes.paymentMethod) {
        const setupRes = await PostSetup(false);

        if (setupRes.status !== StatusCode.OK) {
          uiActions.showError(setupRes.data.message);
        }
        if (stripePaymentMethodRes.paymentMethod.id !== "undefined") {
          const stripeConfirmCardRes = await stripe.confirmCardSetup(setupRes.data.data.setup_intent.client_secret, {
            payment_method: stripePaymentMethodRes.paymentMethod.id,
          });
          if (stripeConfirmCardRes.error) {
            uiActions.showError(stripeConfirmCardRes.error.message);
          } else {
            const postPaymentRes = await PostPaymentMethod(
              {
                payment_method_id: stripeConfirmCardRes.setupIntent.payment_method,
                facility_id: reservationStore?.facility?.id,
                save_card: reservationState.saveCard,
              },
              false,
            );
            if (postPaymentRes.status !== StatusCode.OK) {
              uiActions.showError(postPaymentRes.data.message);
            } else {
              tempCreditCards.push(postPaymentRes.data.data);
              setReservationState(prevState => ({
                ...prevState,
                customerCreditCards: tempCreditCards,
                selectedCardId: postPaymentRes.data.data.id,
              }));
              void closeAddNewCard();
              uiActions.showSuccess(postPaymentRes.data.message);
            }
          }
        }
      } else {
        uiActions.showError(stripePaymentMethodRes.error.message);
      }
    } catch (e) {
      uiActions.dequeue();
    }
    uiActions.dequeue();
  }

  const disableConfirmButton =
    !reservationState?.guestsAmount ||
    !date ||
    !locationId ||
    !reservationStore?.facility?.id ||
    !reservationStore?.module?.id ||
    !reservationState.durations ||
    !reservationState?.acceptTerms ||
    (reservationStore?.selectedSegment?.credit_card_required && reservationState?.selectedCardId == null)
      ? true
      : false;

  // Check if card inputs are filled
  const elementsComplete =
    reservationState.elementComplete.cardNumber === true &&
    reservationState.elementComplete.cardExpiry === true &&
    reservationState.elementComplete.cardCvc === true &&
    reservationState.newCreditCardName?.length >= 1;

  return (
    <>
      <div className="reservation-booking">
        <ReservationsNavigation
          uiActions={uiActions}
          activeUser={activeUser}
          userFirstName={reservationStore?.active_user?.first_name}
        />
        <div className="reservation-booking-facility-container">
          <Card>
            <Card.Section>
              <div className="reservation-booking-flex-row">
                <span className="facility-logo">
                  {reservationStore?.facility?.logo_source && (
                    <img className="facility-logo" src={reservationStore?.facility?.logo_source} alt="Facility Logo" />
                  )}
                </span>
                {reservationStore?.facility && (
                  <div className="reservation-booking-facility-container-group">
                    <p className="facility-name">{reservationStore?.facility?.long_name}</p>
                    <p className="facility-address">
                      {reservationStore?.facility?.address_line_1}, {reservationStore?.facility?.city},{" "}
                      {reservationStore?.facility?.province_name}, {reservationStore?.facility?.postal}
                    </p>
                  </div>
                )}
              </div>
            </Card.Section>
          </Card>
        </div>

        <div className="reservation-booking-main-container">
          <Card>
            <Card.Section>
              <p className="reservation-booking-start-time">
                {reservationStore?.selectedLocation?.title} - {moment(startTime, "hh:mm:ss").format("h:mm A")}
              </p>
              <p className="reservation-booking-date">
                {moment(date).format("dddd")} {moment(date).format("LL")}
              </p>

              <div className="reservation-booking-flex-row">
                <div className="reservation-booking-button-group">
                  <p className="button-title">Duration</p>
                  {reservationState?.durations ? (
                    <Select
                      defaultValue={reservationState?.durationKey}
                      onChange={(value: string) => handleDropDownChange(value, "durationKey")}
                    >
                      {Object?.keys(reservationState?.durations)?.map((durationKey, durationIndex) => {
                        return (
                          <Option key={durationIndex} value={durationKey} name={durationKey}>
                            {durationKey}
                          </Option>
                        );
                      })}
                    </Select>
                  ) : (
                    <div className="reservation-booking-spinner">
                      <Spin />
                    </div>
                  )}
                </div>

                <div className="reservation-booking-button-group">
                  <p className="button-title">Guests</p>
                  <Radio.Group
                    name="number_of_guests"
                    onChange={handleGuestAmountChange}
                    value={reservationState.guestsAmount}
                  >
                    {[...Array(6).keys()].map(index => {
                      return (
                        <Radio.Button key={index} value={index + 1}>
                          {index + 1}
                        </Radio.Button>
                      );
                    })}
                  </Radio.Group>
                </div>
              </div>

              {reservationState?.durationSegments && (
                <div className="reservation-booking-button-group">
                  <DataTable
                    columns={[
                      { label: "Time", width: "50%" }, // TODO: Translation
                      { label: "Price", width: "50%" }, // TODO: Translation
                    ]}
                  >
                    {reservationState.durationSegments[reservationState.durationKey]?.map((segment, segmentIndex) => {
                      if (segmentIndex % 2 === 0) {
                        return (
                          <tr key={segmentIndex}>
                            <td>{moment(segment?.start_time, "hh:mm:ss").format("h:mm A")}</td>
                            <td>
                              {segment?.blocks[0]?.variant_price && (
                                <LocaleCurrency amount={segment?.blocks[0]?.variant_price} />
                              )}
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </DataTable>
                </div>
              )}
            </Card.Section>
          </Card>

          <Card>
            <Card.Section>
              <p className="reservation-booking-title">Booking Info</p>

              {reservationStore?.selectedSegment?.credit_card_required ? (
                <div className="reservation-booking-dropdown">
                  <Select
                    label="Credit card is required to hold your booking, it will only be charged in accordance to our Payment Terms and Conditions"
                    defaultValue={reservationState.selectedCardId}
                    onChange={(value: number) => handleDropDownChange(value, "selectedCardId")}
                  >
                    <li className="ui-select-dropdown-list-item" onClick={openNewCardModal}>
                      <p className="font-semibold">Add New Card</p>
                    </li>
                    {reservationState.customerCreditCards?.map((card, index) => {
                      return (
                        <Option key={index} value={card?.id} name={card?.brand}>
                          <p className="font-semibold">
                            {capitalize(card?.brand)}
                            {" •••• •••• •••• ".concat(card?.last4)}
                          </p>
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              ) : null}

              <div className="reservation-booking-payment-terms-container">
                <Checkbox
                  id="acceptTerms"
                  size="medium"
                  value={reservationState.acceptTerms}
                  checked={reservationState.acceptTerms}
                  onChange={handleCheckboxChange}
                  label={`I agree to ${reservationStore?.facility?.long_name}'s Payment Terms and Conditions`}
                />
                <Button
                  type="link"
                  size="small"
                  onClick={() => setReservationState(prevState => ({ ...prevState, paymentTermsActive: true }))}
                >
                  View Payment Terms and Conditions
                </Button>
              </div>

              <ButtonGroup>
                <Button size="medium" type="secondary" onClick={returnToReservationsPage}>
                  Back
                </Button>
                <Button size="medium" type="primary" disabled={disableConfirmButton} onClick={bookReservation}>
                  Complete Booking
                </Button>
              </ButtonGroup>
            </Card.Section>
          </Card>
        </div>
      </div>

      {/* Add new credit card modal */}
      <Sheet
        size="small"
        open={reservationState.newCardActive}
        closable
        title={"Add New Credit Card"}
        cancelText={"Cancel"}
        okText={"Add Card"}
        onOk={handleAddNewCard}
        okDisabled={!elementsComplete ? true : false}
        onCancel={closeAddNewCard}
        backDropCancel={false}
      >
        <div>
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  id="newCreditCardName"
                  label="Name on Card"
                  value={reservationState.newCreditCardName || ""}
                  onChange={handleInputChange}
                  placeholder="Name on credit card"
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <CardSection onChange={handleCardSectionChange} type="one-row" />
              </FormLayout.Group>
              <FormLayout.Group>
                <Checkbox
                  id="saveCard"
                  size="small"
                  onChange={handleCheckboxChange}
                  label="Save card for future bookings"
                />
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </div>
      </Sheet>

      {/* Payment Terms and Conditions Modal */}
      <Sheet
        size="small"
        open={reservationState.paymentTermsActive}
        closable
        title={"Payment Terms and Conditions"}
        cancelText={"Close"}
        okText={"Accept"}
        onOk={() =>
          setReservationState(prevState => ({ ...prevState, paymentTermsActive: false, acceptTerms: true }))
        }
        onCancel={() => setReservationState(prevState => ({ ...prevState, paymentTermsActive: false }))}
        backDropCancel={false}
      >
        <Markdown markdownText={reservationStore?.module?.payment_terms ?? ""} />
      </Sheet>
    </>
  );
}
