import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Checkbox from "components/form/checkbox/Checkbox";
import { ColumnInstance, TableInstance } from "react-table";
import "./filterDropDown.scss";
import { IconName } from "@fortawesome/fontawesome-common-types";
import classNames from "classnames";

interface IProps {
  data: Array<Record<string, any>>;
  dataLabelProperty?: string;
  checkBoxId?: string;
  dataIdProperty?: string;
  dataValueProperty?: string;
  label?: string;
  onCheckBoxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  onAddAll?: () => void;
  iconName?: IconName;
  onApply?: () => void; //Change 'Done' button to 'Apply', run this function when apply is clicked
  leftAligned?: boolean;
  defaultCheckBoxes?: Array<boolean>;
  restrain?: boolean;
}

interface IState {
  isFilterDropdownOpen: boolean;
  filterRef: React.MutableRefObject<HTMLDivElement>;
  checkBoxes: Array<boolean>;
}

export default function FilterDropdown(props: IProps) {
  const [state, setState] = useState<IState>({
    isFilterDropdownOpen: false,
    filterRef: useRef<HTMLDivElement>(),
    checkBoxes: props?.defaultCheckBoxes ? props.defaultCheckBoxes : new Array(props?.data?.length).fill(false),
  });

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideFilterDropdownClick);
    document.addEventListener("touchstart", handleOutsideFilterDropdownClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideFilterDropdownClick);
      document.removeEventListener("touchstart", handleOutsideFilterDropdownClick);
    };
  }, []);

  useEffect(() => {
    if (props.onApply && !state.isFilterDropdownOpen) {
      props.onApply();
    }
  }, [state.isFilterDropdownOpen]);

  useEffect(() => {
    if (props.defaultCheckBoxes?.length > 0) {
      setState(prevState => ({ ...prevState, checkBoxes: props.defaultCheckBoxes }));
    }
  }, [props.defaultCheckBoxes]);

  function handleOutsideFilterDropdownClick(event: any) {
    if (!state.filterRef.current || state.filterRef.current.contains(event.target)) {
      return;
    }
    setState(prevState => ({ ...prevState, isFilterDropdownOpen: false }));
  }

  function handleFilterButtonClick() {
    setState(prevState => ({ ...prevState, isFilterDropdownOpen: !prevState.isFilterDropdownOpen }));
  }

  function onClear() {
    setState(prevState => ({ ...prevState, checkBoxes: new Array(props?.data?.length).fill(false) }));
  }

  function onAddAll() {
    setState(prevState => ({ ...prevState, checkBoxes: new Array(props?.data?.length).fill(true) }));
  }

  function handleCheckBoxClick(index: number) {
    const checkBoxes = [...state.checkBoxes];
    if (props.restrain) {
      checkBoxes.fill(false);
    }
    checkBoxes[index] = !checkBoxes[index];
    setState(prevState => ({ ...prevState, checkBoxes }));
  }

  return (
    <div className="filter-dropdown" ref={state.filterRef}>
      <div className="filter-dropdown-button" onClick={handleFilterButtonClick}>
        <span>{props?.label ? props?.label : "Filter"}</span>
        <FontAwesomeIcon
          className="filter-dropdown-icon"
          fixedWidth
          icon={["fas", props?.iconName ? props?.iconName : "filter"]}
        />
      </div>
      <div
        className={classNames("filter-dropdown-content", { "filter-dropdown-content-left-aligned": props.leftAligned })}
        style={{ display: state.isFilterDropdownOpen && props.data?.length > 0 ? "block" : "none" }}
      >
        <div className="filter-dropdown-top-menu">
          {props?.onClear && (
            <div
              className="filter-dropdown-clear-button"
              onClick={() => {
                props?.onClear();
                onClear();
              }}
            >
              Clear
            </div>
          )}
          {props?.onAddAll && (
            <div
              className="filter-dropdown-clear-button"
              onClick={() => {
                props?.onAddAll();
                onAddAll();
              }}
            >
              Add All
            </div>
          )}
          <div className="filter-dropdown-done-button" onClick={handleFilterButtonClick}>
            {props?.onApply ? "Apply" : "Done"}
          </div>
        </div>
        {props.data?.map((data, index) => {
          return (
            <div className="filter-dropdown-option" key={index}>
              <Checkbox
                fullWidth
                checked={state.checkBoxes[index] || false}
                value={data[props?.dataValueProperty] || undefined}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  props.onCheckBoxChange(e);
                  handleCheckBoxClick(index);
                }}
                id={
                  data[props?.dataIdProperty]
                    ? data[props?.dataIdProperty]
                    : props?.checkBoxId
                    ? props?.checkBoxId
                    : undefined
                }
                label={data[props?.dataLabelProperty] || ""}
                size="small"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
