import React, { useState, createContext, Context } from "react";
import classNames from "classnames";
import { ConfigContext } from "../config-provider";

export interface ITeeSheetCardGroupProps {
  prefixCls?: string;
  className?: string;
}

export const GroupContext = createContext(null);

const Group: React.FC<ITeeSheetCardGroupProps> = props => {
  const { children, prefixCls = "teesheet-card-group", className } = props;

  const cls = classNames(prefixCls, className);

  const [state, setState] = useState({});

  return (
    <GroupContext.Provider value={{ state, setState }}>
      <div className={cls}>{children}</div>
    </GroupContext.Provider>
  );
};

export default Group;
