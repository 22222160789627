import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import axios, { CancelToken } from "axios";
import { useTranslation, Trans } from "react-i18next";

import { StatusCode } from "api/protocols";
import { GetTeeSheetNotificationAll } from "api/rpc/2024-04/facilityAdmin/teesheet/notification";

import { showError } from "redux/actions/ui";
import { TTeesheetNotification } from "redux/reducers/models/teesheet";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

import "./teeSheetNotification.scss";

export default function TeeSheetNotifications() {
  const history = useHistory();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  // const dateAsString = location && location.state.params !== undefined
  //   ? new Date(location.state.params as Date).toISOString().slice(0, 10)
  //   : new Date().toISOString().slice(0, 10);

  const [notifications, setNotifications] = useState<TTeesheetNotification[]>(undefined);
  const [filters, setFilters] = useState({
    date: undefined,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadTeeSheetNotifications(filters.date, source.token);
    return () => source.cancel();
  }, [filters.date]);

  async function loadTeeSheetNotifications(date: Date, token?: CancelToken) {
    if (notifications !== undefined) {
      setNotifications(undefined);
    }
    const res = await GetTeeSheetNotificationAll({ date: date }, token ? false : true, token);
    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading teesheet notifications.")); // TODO: Translation
    }

    setNotifications(res.status !== StatusCode.OK ? [] : res.data);
  }

  const primaryAction = {
    content: t("secure.facility.settings.tee_sheets.tee_sheet_notifications.001"),
    action: () => history.push("/admin/settings/tee-sheet/notification/new"),
  };

  return (
    <Page
      title={t("secure.facility.settings.tee_sheets.tee_sheet_notifications.002")}
      subtitle={t("secure.facility.settings.tee_sheets.tee_sheet_notifications.003")}
      narrow
      primaryAction={primaryAction}
    >
      <DataTable
        columns={[
          { label: t("secure.facility.settings.tee_sheets.tee_sheet_notifications.004") },
          { label: t("secure.facility.settings.tee_sheets.tee_sheet_notifications.005") },
          { label: t("secure.facility.settings.tee_sheets.tee_sheet_notifications.006") },
        ]}
        loading={notifications === undefined}
      >
        {notifications?.map(notification => (
          <tr
            key={notification.id}
            className="clickable"
            onClick={() => history.push(`/admin/settings/tee-sheet/notification/${String(notification.id)}`)}
          >
            <td>{moment.utc(notification.date).format("MMM DD, YYYY")}</td>
            <td>{notification.subject}</td>
            <td>{notification.type}</td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
