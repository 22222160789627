import Callout from "components/callout/Callout";
import React, { useEffect, useRef } from "react";
import { ButtonNew as Button } from "components/buttonNew";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./tournamentRegistrationEditInputLayout.scss";
import { useDropEditInputLayout } from "hooks/editInputDragAndDrop/useDropEditInputLayout/useDropEditInputLayout";
import TournamentRegistrationEditInputRow from "./TournamentRegistrationEditInputRow";
import { useTournamentRegistrationContext } from "../TournamentRegistrationContext";

interface IProps {
  // setNewInputModal: (value: React.SetStateAction<ITournamentRegistrationNewInputModal>) => void;
  openModal: () => void;
}

export default function TournamentRegistrationEditInputLayout(props: IProps) {
  // const { setNewInputModal } = props;

  const tournamentRegistrationContext = useTournamentRegistrationContext();

  const tournamentLayoutRef = useRef<HTMLDivElement>(null);
  const tournamentLayoutRowContainerRefs = useRef<HTMLDivElement[]>([]);

  const { drop, customDragLayer } = useDropEditInputLayout(
    tournamentLayoutRef,
    tournamentLayoutRowContainerRefs,
    tournamentRegistrationContext.inputRowsContainerActions.update,
    tournamentRegistrationContext.inputRowsContainer.updatedState,
  );

  useEffect(() => {
    if (tournamentRegistrationContext.inputRowsContainer?.updatedState) {
      const numRows = Object.keys(tournamentRegistrationContext.inputRowsContainer.updatedState).length;

      tournamentLayoutRowContainerRefs.current = tournamentLayoutRowContainerRefs.current.slice(0, numRows);
    }
  }, [tournamentRegistrationContext.inputRowsContainer?.updatedState]);

  return (
    <>
      {tournamentRegistrationContext.inputRowsContainer?.updatedState &&
      Object.keys(tournamentRegistrationContext.inputRowsContainer.updatedState).length > 0 ? (
        <div className="tournament-registration-edit-input-layout">
          <div
            ref={el => {
              drop(el);
              tournamentLayoutRef.current = el;
            }}
            className="tournament-registration-layout-rows"
          >
            {Object.keys(tournamentRegistrationContext.inputRowsContainer.updatedState).map((row, index) => {
              const inputs = tournamentRegistrationContext.inputRowsContainer.updatedState[row];
              const inputIds = inputs.map(input => input.id);
              const inputRowIdentifier = `[${inputIds.toString()}]`;

              return (
                <div ref={el => (tournamentLayoutRowContainerRefs.current[index] = el)} key={inputRowIdentifier}>
                  <TournamentRegistrationEditInputRow inputRow={row} inputs={inputs} />
                </div>
              );
            })}
            {customDragLayer}
          </div>
          <hr className="tournament-registration-layout-add-input-divider" />
          <Button
            type="text"
            onClick={props.openModal}
            // onClick={() => setNewInputModal(prev => ({ ...prev, open: true }))}
            className="tournament-registration-layout-add-input"
          >
            <FontAwesomeIcon
              className="tournament-registration-layout-add-input-icon"
              size="sm"
              icon={["far", "plus"]}
            />
            <span>Add Input</span>
          </Button>
        </div>
      ) : (
        <Callout
          type={"info"}
          title={"0 Registration Inputs."}
          content="You do not currently have any custom inputs for this registration.  Please add a new input to this registration to get started."
        />
      )}
    </>
  );
}
