import { APIGet, APIPost, APIPut, APIDelete, StatusCode, IAPIResponse } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "guest";
const baseReservationUrl = "/" + apiVersion + "/" + adminType + "/reservation";

interface IGetReservationModule {
  facility_short_name?: string;
  handle?: string;
  id?: number;
}

interface IGetReservationLocation {
  id?: number;
  facility_id?: number;
}

interface IGetReservationSegment {
  id?: number;
  module_id?: number;
  date?: string;
  start_time?: string;
  location_id?: number;
}

export async function GetGuestReservationModule(params: IGetReservationModule, useGlobalLoader: boolean) {
  return await APIGet(baseReservationUrl + "/module", params, useGlobalLoader);
}

export async function GetGuestReservationModuleAll(params: IGetReservationModule, useGlobalLoader: boolean) {
  return await APIGet(baseReservationUrl + "/module/all", params, useGlobalLoader);
}

export async function GetGuestReservationLocation(params: IGetReservationLocation, useGlobalLoader: boolean) {
  return await APIGet(baseReservationUrl + "/location", params, useGlobalLoader);
}

export async function GetGuestReservationSegment(
  params: IGetReservationSegment,
  useGlobalLoader: boolean,
  cancelToken?: CancelToken,
) {
  return await APIGet(baseReservationUrl + "/segment", params, useGlobalLoader, cancelToken);
}
