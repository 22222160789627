import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js/types/stripe-js/elements";

import { StatusCode } from "api/protocols";
import { DeletePaymentMethod, PostPaymentMethod, PostSetup } from "api/rpc/facilityAdmin/customer";

import { showError, showSuccess } from "redux/actions/ui";

import { useAppDispatch } from "hooks/redux";
import { capitalize, getCreditCardIcon } from "helpers/Helpers";

import { useCustomerContext } from "../../context/CustomerContext";
import Popup from "components/popup/Popup";
import { ButtonNew as Button } from "components/buttonNew/index";
import Sheet from "components/sheet/Sheet";
import CardSection from "components/cardSection/CardSection";
import Spin from "components/spin/spin";
import Callout from "components/callout/Callout";
import GiftCardMergeTable from "./GiftCardMergeTable";
import { LocaleCurrency } from "helpers/Locale";
import { Badge } from "components/badge/Badge";
import "./CustomerPaymentMethods.scss";
import "./giftCardMergeTable.scss";
import Card from "components/card/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { MOBILE_WIDTH } from "helpers/ScreenSizes";
import { NavigationDropdownNew } from "components/navigationDropdownNew/NavigationDropdownNew";
import { GetRainCheckReceipt } from "api/rpc/2024-04/facilityAdmin/payment/rainCheck";
import DataTable from "../houseAccounts/DataTable";

export default function CustomerPaymentMethods() {
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useAppDispatch();
  const { state, reloadPaymentMethods, refreshGiftCards } = useCustomerContext();
  const { t } = useTranslation();
  const windowSize = useWindowSize();

  const [deleteModal, setDeleteModal] = useState<{ visible: boolean; selectedId: number | null }>({
    visible: false,
    selectedId: null,
  });

  const [addModal, setAddModal] = useState({
    visible: false,
    elements: {
      cardNumber: false,
      cardExpiry: false,
      cardCvc: false,
    },
  });

  const creditCardsLoading =
    (state.customer.id !== null || state.customer.id !== undefined) &&
    (state.paymentMethods === undefined || state.paymentMethods === null);

  async function submitCreditCard() {
    const customer = state.customer;
    try {
      //Create Stripe payment method
      const { error: stripeError, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement("cardNumber"),
        billing_details: {
          name: customer.full_name,
          email: customer.email,
          phone: customer.phone,
          address: {
            // city: null,
            // country: null,
            // line1: null,
            // line2: null,
            // postal_code: null,
            state: customer.state,
          },
        },
      });

      if (stripeError) {
        dispatch(showError(stripeError.message));
        return;
      } else {
        const setUpCardRes = await setUpCard(paymentMethod.id);

        if (!setUpCardRes) {
          console.log("Card set up error");
          return;
        }

        setAddModal({
          ...addModal,
          visible: false,
          elements: { cardCvc: false, cardExpiry: false, cardNumber: false },
        });
        void reloadPaymentMethods(customer.id, false);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function deletePaymentMethod() {
    try {
      const deleteRes = await DeletePaymentMethod({ id: deleteModal.selectedId, customer_id: state.customer.id }, true);
      if (deleteRes.status !== StatusCode.OK) {
        dispatch(showError(deleteRes.data.message));
        return;
      }

      dispatch(showSuccess(deleteRes.data.message));

      setDeleteModal({ visible: false, selectedId: null });

      void reloadPaymentMethods(state.customer.id, false);
    } catch (e) {
      console.log(e);
    }
  }

  async function setUpCard(payment_method_id: string) {
    try {
      // Create card setup intent
      const setupRes = await PostSetup({ customer_id: state.customer.id }, true);
      if (setupRes.status !== StatusCode.OK) {
        dispatch(showError(setupRes.data.message));
        return false;
      }
      //Confim card setip intent
      const stripeConfirmRes = await stripe.confirmCardSetup(setupRes.data.data.setup_intent.client_secret, {
        payment_method: payment_method_id,
      });
      if (stripeConfirmRes.error) {
        dispatch(showError(stripeConfirmRes.error.message));
        return false;
      } else {
        //Post payment method
        const paymentMethodRes = await PostPaymentMethod(
          {
            payment_method_id: stripeConfirmRes.setupIntent.payment_method,
            customer_id: state.customer.id,
            save_card: true,
          },
          true,
        );

        if (paymentMethodRes.status !== StatusCode.OK) {
          dispatch(showError(paymentMethodRes.data.message));
          return false;
        } else {
          dispatch(showSuccess(paymentMethodRes.data.message));
          return true;
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  function handleCardInputChange(
    e: StripeCardNumberElementChangeEvent | StripeCardExpiryElementChangeEvent | StripeCardCvcElementChangeEvent,
  ) {
    setAddModal({
      ...addModal,
      elements: {
        ...addModal.elements,
        [e.elementType]: e.complete,
      },
    });
  }

  async function handlePrintSelect(selectedRainCheckID: number) {
    const rainCheckReceiptRes = await GetRainCheckReceipt({ id: selectedRainCheckID }, true);
    if (rainCheckReceiptRes.status !== StatusCode.OK) {
      dispatch(showError("Error occurred printing rain check"));
      return;
    } else {
      window.open().document.write(rainCheckReceiptRes.data);
    }
  }

  return (
    <div style={{ marginTop: "0.5rem" }}>
      <div className="customer-payment-methods-header-container">
        <h1 className="ui-customer_page-header">
          {t("secure.facility.customer.tabs.payment_methods.customer_payment_methods.001")}
        </h1>
        <div>
          <Button
            type="secondary"
            size="medium"
            style={{ height: "fit-content" }}
            onClick={() => setAddModal({ ...addModal, visible: !addModal.visible })}
            disabled={state.paymentMethods === null}
          >
            <FontAwesomeIcon className="mr-2" icon={["far", "plus"]} />
            <span>
              {windowSize.width > MOBILE_WIDTH
                ? t("secure.facility.customer.tabs.payment_methods.customer_payment_methods.002")
                : t("secure.facility.customer.tabs.payment_methods.customer_payment_methods.017")}
            </span>
          </Button>
        </div>
      </div>

      <hr className="customer-payment-methods-header-divider" />

      <div>
        <div className="customer-payment-methods-payment-methods">
          {creditCardsLoading ? (
            <div style={{ display: "flex", width: "2rem", height: "4.5rem", margin: "auto" }}>
              <Spin className="self-center" />
            </div>
          ) : (
            <>
              {state.paymentMethods.length >= 1 ? (
                <div className="customer-payment-methods-payment-method">
                  {state.paymentMethods.map(method => {
                    return (
                      <div className="payment-method-item" key={method.id}>
                        <div className="payment-method-item-content">
                          <FontAwesomeIcon
                            className="payment-method-item-icon"
                            size="2x"
                            icon={getCreditCardIcon(method.brand)}
                          />
                          <div className="payment-method-item-brand">{capitalize(method.brand)}</div>
                          <div>
                            {"**** **** **** "}
                            {method.last4}
                          </div>
                        </div>
                        <Button
                          className="payment-method-item-remove"
                          type="link"
                          onClick={() => setDeleteModal({ ...deleteModal, visible: true, selectedId: method.id })}
                        >
                          {t("secure.facility.customer.tabs.payment_methods.customer_payment_methods.018")}
                        </Button>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <Callout
                  type="warning"
                  title={t("elements.tee_sheet.credit_card.credit_card_catalogue.004")}
                  content={t("elements.tee_sheet.credit_card.credit_card_catalogue.005")}
                />
              )}
            </>
          )}
        </div>

        <div className="mt-4">
          <h1 className="mb-4 ui-customer_page-header">
            {t("secure.facility.customer.tabs.payment_methods.customer_payment_methods.004")}
          </h1>
          <GiftCardMergeTable
            giftCards={state.giftCards}
            tableRowClick={giftCard => history.push("/admin/giftcards/" + giftCard.code)}
            mergeActionCallback={updatedCard => refreshGiftCards(state.customer.id, false)}
          />
        </div>
      </div>

      <div className="mt-4">
        <h1 className="mb-4 ui-customer_page-header">
          {t("secure.facility.customer.tabs.payment_methods.customer_payment_methods.019")}
        </h1>

        <div className="table-wrap">
          <table className="giftcard-table ui-table clickable">
            <colgroup>
              <col style={{ width: "50%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "20%" }} />
            </colgroup>
            <thead>
              <tr className="table-row-title">
                <th className="table-header-giftcard">
                  {t("secure.facility.customer.tabs.payment_methods.customer_payment_methods.020")}
                </th>
                <th className="table-header-balance">
                  {t("secure.facility.customer.tabs.payment_methods.customer_payment_methods.021")}
                </th>
                <th className="table-header-currency">
                  {t("secure.facility.customer.tabs.payment_methods.customer_payment_methods.022")}
                </th>
                <th className="table-header-status">
                  {t("secure.facility.customer.tabs.payment_methods.customer_payment_methods.023")}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {state.rainChecks ? (
                <>
                  {state.rainChecks.length > 0 ? (
                    <>
                      {state.rainChecks.map(rainCheck => {
                        return (
                          <tr key={rainCheck.id} onClick={() => history.push(`/admin/rainchecks/${rainCheck.id}`)}>
                            <td className="table-data-giftCard">{`**** **** ${rainCheck.last4}`}</td>
                            <td className="table-data-balance">
                              <LocaleCurrency currency={rainCheck.currency} amount={rainCheck.balance} />
                            </td>
                            <td className="table-data-currency">{rainCheck.currency.toUpperCase()}</td>
                            <td className="table-data-status">
                              {rainCheck.status.includes("redeemed") ? (
                                <Badge type="warning">
                                  {t("secure.facility.customer.tabs.payment_methods.customer_payment_methods.024")}
                                </Badge>
                              ) : (
                                <Badge type="success">{capitalize(rainCheck.status)}</Badge>
                              )}
                            </td>
                            <td className="table-data-status">
                              <NavigationDropdownNew
                                rightAlign
                                position="top"
                                showPlainTextLabel
                                label={<FontAwesomeIcon icon={["far", "chevron-down"]} />}
                                sections={[
                                  [
                                    {
                                      label: "Print",
                                      icon: "print",
                                      onClick: () => handlePrintSelect(rainCheck.id),
                                    },
                                  ],
                                ]}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : null}
                </>
              ) : (
                <tr id="gift-card-table-spinner">
                  <td height={"75px"} style={{ pointerEvents: "none" }}>
                    <Spin />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modals & Popups */}
      {addModal.visible && (
        <Sheet
          open={addModal.visible}
          size="small"
          height="flexible"
          closable
          title={t("secure.facility.customer.tabs.payment_methods.customer_payment_methods.010")} // TODO: Translation
          onCancel={() =>
            setAddModal({
              ...addModal,
              visible: false,
              elements: { cardCvc: false, cardExpiry: false, cardNumber: false },
            })
          }
          onOk={submitCreditCard}
          cancelText={t("secure.facility.customer.tabs.payment_methods.customer_payment_methods.011")}
          okText={t("secure.facility.customer.tabs.payment_methods.customer_payment_methods.012")}
          okDisabled={!addModal.elements.cardCvc || !addModal.elements.cardExpiry || !addModal.elements.cardNumber}
        >
          {/* // TODO: Translation */}
          <label>{t("secure.facility.customer.tabs.payment_methods.customer_payment_methods.013")}</label>
          <CardSection type="one-row" onChange={handleCardInputChange} />
        </Sheet>
      )}

      <Popup
        open={deleteModal.visible}
        type="warning"
        title={t("secure.facility.customer.tabs.payment_methods.customer_payment_methods.014")}
        description={t("secure.facility.customer.tabs.payment_methods.customer_payment_methods.015")}
        onOk={deletePaymentMethod}
        okText={t("secure.facility.customer.tabs.payment_methods.customer_payment_methods.016")}
        onCancel={() => setDeleteModal({ ...deleteModal, visible: false, selectedId: null })}
        backDropCancel={true}
      />
    </div>
  );
}
