import React, { useEffect, useState } from "react";
import axios, { CancelToken } from "axios";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { GetRegisters } from "api/rpc/facilityAdmin/facility/register/register";
import { GetFacilityAdmin } from "api/rpc/facilityAdmin/client/admin";
import { GetPaymentOptions } from "api/rpc/2022-09/facilityAdmin/facility/facility";

import { DBFilterContext } from "redux/reducers/filters/filters";
import { IUser } from "redux/reducers/models/user";
import { IOrder } from "redux/reducers/models/order";
import { IPaymentOption } from "redux/reducers/models/transaction";
import { IFacilityStaff } from "redux/reducers/models/facility";

import { useAppSelector } from "hooks/redux";

import Card from "components/card/Card";
import Page from "components/page/Page";
import { PriceMatchType } from "components/dropdown/dropFilterTypes";
import Search from "components/search/Search";
import OrdersTable from "components/orderTable/OrdersTable";
import { filterContextIsEqual } from "components/accordion/Accordion";
import OrderFilterAccordion from "components/accordion/filterAccordions/OrderFilterAccordion";
import DropFilter from "components/dropdown/DropFilter";
import { IRegister } from "../Admin";

import "pages/secure/facility/order/order.scss";
import { GetOrder } from "api/rpc/2024-04/facilityAdmin/order/order";
import { numberIsValid } from "helpers/Helpers";

interface IState {
  search: string;
  offset: number;
}

export type IOrderFilterState = {
  [key in keyof PriceMatchType]?: string | string[];
} & {
  all: Array<Record<string, any>>;
  date: string[];
  financial_status: string[];
  register_ids: IRegister[];
  user_ids: IUser[];
  sales_channels: string[];
  credit_card: string[];
  payment_type: string[];
};

const TABLE_LIMIT = 50;
const OFFSET_HISTORY_KEY = "facility_orders_offset";
const ORDER_DATE_HISTORY_KEY = "facility_orders_date";

export default function Orders() {
  const { t, i18n } = useTranslation();
  const filters = useAppSelector(store => store.orderPageFilters);
  const filterHistoryStore = useAppSelector(store => store.filterHistoryStore);

  const { facilityStore } = useAppSelector(store => store);

  const [orders, setOrders] = useState<IOrder[]>(undefined);

  const [activeFilters, setActiveFilters] = useState<DBFilterContext[]>(undefined);

  const [dateFilter, setDateFilter] = useState({
    start_date: filterHistoryStore[ORDER_DATE_HISTORY_KEY]
      ? (filterHistoryStore[ORDER_DATE_HISTORY_KEY].currentFilter as string)
      : "",
    end_date: filterHistoryStore[ORDER_DATE_HISTORY_KEY]
      ? (filterHistoryStore[ORDER_DATE_HISTORY_KEY].currentFilter as string)
      : "",
  });

  const [searchState, setSearchState] = useState<IState>({
    search: "",
    offset: filterHistoryStore[OFFSET_HISTORY_KEY] ? filterHistoryStore[OFFSET_HISTORY_KEY].currentFilter : 0,
  });

  // mounted only after all async filters have been pre-loaded
  useEffect(() => {
    if (
      facilityStore.staffAccounts &&
      facilityStore.registers &&
      facilityStore.paymentOptions &&
      activeFilters === undefined
    ) {
      setActiveFilters(filters);
    }
  }, [facilityStore.staffAccounts, facilityStore.registers, facilityStore.paymentOptions, activeFilters]);

  // Load the Orders with filters
  useEffect(() => {
    if (activeFilters === undefined) {
      return;
    }

    const source = axios.CancelToken.source();
    void loadOrders(source.token);

    return () => {
      source.cancel();
    };
  }, [searchState.search, searchState.offset, activeFilters, dateFilter.start_date]);

  async function loadOrders(token?: CancelToken) {
    if (orders !== undefined) {
      setOrders(undefined);
    }

    // remove unused filters from accordion
    let filterParams = activeFilters
      .filter(grouping => grouping.value.length !== 0)
      .map(ele => {
        return {
          label: ele.label,
          value: ele.value,
        };
      });

    // apply date filter if given
    if (dateFilter.start_date.length !== 0) {
      filterParams = filterParams.concat([
        { label: "start_date", value: [dateFilter.start_date] },
        { label: "end_date", value: [dateFilter.end_date] },
      ]);
    }

    let params;

    if (numberIsValid(searchState.search)) {
      params = {
        number: searchState.search,
        filters: filterParams.length > 0 ? filterParams : undefined,
        offset: searchState.offset,
      };
    } else {
      params = {
        search: searchState.search,
        filters: filterParams.length > 0 ? filterParams : undefined,
        offset: searchState.offset,
      };
    }

    const res = await GetOrder(params, false, token);

    if (token.reason) {
      setOrders(undefined); // front-end refresh
      return;
    }

    if (res.status !== StatusCode.OK) {
      setOrders([]); // backend error
      return;
    } else {
      console.log("SUCCESS!", res);
      setOrders(res.data);
    }
  }

  return (
    <Page title={t("secure.facility.order.orders.002")} narrow>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <DropFilter
          key="date"
          title={t("secure.facility.order.order_filters.001")}
          filterData={[{ id: dateFilter.start_date, label: t("secure.facility.order.order_filters.002") }]}
          filterLabelPropFromData="label"
          filterIdPropFromData="id"
          filterType="Date"
          applyFilters={(filterReturn: Record<string, any>[]) =>
            setDateFilter(
              filterReturn.length > 0
                ? { start_date: filterReturn[0].id, end_date: filterReturn[0].id }
                : { start_date: "", end_date: "" },
            )
          }
          leftAligned
          save
          historyKey={ORDER_DATE_HISTORY_KEY}
        />

        <OrderFilterAccordion
          activeFilters={activeFilters}
          applyAllAction={updatedFilters => setActiveFilters(updatedFilters)}
          clearAllAction={updatedFilters =>
            updatedFilters.length === 0 ? undefined : setActiveFilters(updatedFilters)
          }
          allFiltersAreApplied={filterContextIsEqual(activeFilters, filters)}
          rightAligned
          isLoading={activeFilters === undefined}
          paymentStatus
          channels
          priceMatch
          creditCard
          staff={facilityStore.staffAccounts}
          registers={facilityStore.registers}
          paymentType={facilityStore.paymentOptions}
        />
      </div>

      <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
        <Search
          historyKey={"facility_orders_search"}
          searchCallback={searchValue => {
            setSearchState(prev => ({
              ...prev,
              search: searchValue,
              offset: orders !== undefined ? (prev.offset !== 0 ? 0 : prev.offset) : prev.offset,
            }));
          }}
          placeholder={t("secure.facility.order.orders.003")}
        />
      </div>
      <Card>
        <Card.Section table="true">
          <OrdersTable
            orders={orders}
            tableLimit={TABLE_LIMIT}
            tableOffset={searchState.offset}
            handleTableOffset={direction =>
              setSearchState(prev => ({
                ...prev,
                offset: direction === "prev" ? prev.offset - TABLE_LIMIT : prev.offset + TABLE_LIMIT,
              }))
            }
          />
        </Card.Section>
      </Card>
    </Page>
  );
}
