import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { StatusCode } from "api/protocols";
import { showSuccess, showError } from "redux/actions/ui";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonNew } from "components/buttonNew";
import ReactDOM from "react-dom";
import Card from "components/card/Card";
import Popup from "components/popup/Popup";
import { Badge } from "components/badge/Badge";
import { useAppDispatch } from "hooks/redux";
import "../PortalBookings/viewPortalBooking.scss";
import "./portalReservations.scss";
import { useTranslation } from "react-i18next";
import { ILocation, IModule, IReservationBooking, ISegment } from "redux/reducers/models/reservations";
import axios, { CancelToken } from "axios";
import { IFacility } from "redux/reducers/models/facility";
import { CancelReservationBooking, GetReservationBooking } from "api/rpc/2024-04/customer/reservation";
import Page from "components/page/Page";
import Divider from "components/divider";
import { GetFacility } from "api/rpc/2024-04/customer/facility";
import { GetGuestReservationSegment } from "api/rpc/2024-04/guest/reservation";
import { GetReservationModule } from "api/rpc/guest/reservations/reservations";
import BottomSheet from "components/bottomSheet/BottomSheet";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { MOBILE_WIDTH } from "helpers/ScreenSizes";
interface IParams {
  token: string;
}

interface IState {
  reservation: IReservationBooking;
  facility: IFacility;
  module: IModule;
  location: ILocation;
  duration: string;
  cancelPopUp: boolean;
  reservationNotInPast: boolean;
  endTime: string;
}

interface IEditState {
  hideEdit: boolean;
  hideCancel: boolean;
}

export default function ViewPortalReservation() {
  const SEGMENT_MINUTES = 30;
  const history = useHistory();
  const { token } = useParams<IParams>();
  const windowSize = useWindowSize();
  const [state, setState] = useState<IState>({
    reservation: null,
    facility: null,
    module: null,
    location: null,
    duration: null,
    cancelPopUp: false,
    reservationNotInPast: false,
    endTime: "",
  });

  const [editState, setEditState] = useState<IEditState>({
    hideCancel: false,
    hideEdit: false,
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    const source = axios.CancelToken.source();
    void getReservation(source.token);
    return () => {
      source.cancel("Cancelled request");
    };
  }, []);

  async function getReservation(cancelToken: CancelToken) {
    let [hideEditButton, hideCancelButton] = [true, true];
    const reservationRes = await GetReservationBooking({ token: token, extended: true }, true, cancelToken);
    if (reservationRes.status !== StatusCode.OK || reservationRes.data?.length === 0) {
      dispatch(showError("Error getting reservation"));
      void returnToReservations();
      return;
    }

    const reservation = reservationRes.data[0];

    const reservationNotInPast = checkReservationInPast(reservation);

    let facility = reservation?.location?.facility;

    if (!facility) {
      const facilityRes = await GetFacility({ id: reservation?.location?.facility_id }, true);
      if (facilityRes?.status !== StatusCode.OK || facilityRes?.data?.length === 0) {
        dispatch(showError("Error getting facility"));
        void returnToReservations();
        return;
      }
      facility = facilityRes?.data[0];
    }

    const moduleRes = await GetReservationModule({ id: reservation?.location?.reservation_module_id }, true);
    if (moduleRes?.status !== StatusCode.OK) {
      dispatch(showError("Error getting module"));
      void returnToReservations();
      return;
    }
    if (reservation?.status !== "cancelled") {
      [hideEditButton, hideCancelButton] = checkEditCancelTimes(reservation, facility, moduleRes?.data);
    }

    let duration: string = null;
    let endTime: string = null;

    if (reservationNotInPast) {
      let minutes = 0;
      let hours = 0;
      const segmentsRes = await GetGuestReservationSegment(
        {
          module_id: moduleRes?.data?.id,
          date: reservation?.date,
          start_time: reservation?.start_time,
          location_id: reservation?.location_id,
        },
        true,
      );

      if (segmentsRes?.status !== StatusCode.OK) {
        dispatch(showError("Error getting segments"));
        void returnToReservations();
        return;
      }

      segmentsRes?.data?.every((segment: ISegment, i: number) => {
        minutes += SEGMENT_MINUTES;
        if (segment?.booking_id === reservation?.id) {
          hours = minutes / 60;
          duration = `${hours} ${hours > 1 ? "Hours" : "Hour"}`;
          return true;
        } else {
          return false;
        }
      });

      if (duration) {
        endTime = moment(`${reservation?.date} ${reservation?.start_time}`).add(hours, "hours").format("HH:mm:ss");
      }
    }

    ReactDOM.unstable_batchedUpdates(() => {
      setState(prevState => ({
        ...prevState,
        reservation,
        facility,
        location: reservation?.location,
        module: moduleRes?.data,
        duration,
        reservationNotInPast,
        endTime,
      }));

      setEditState(prevState => ({
        ...prevState,
        hideCancel: hideCancelButton,
        hideEdit: hideEditButton,
      }));
    });
  }

  function checkReservationInPast(reservation: IReservationBooking) {
    const reservationDate = reservation?.date + " " + reservation?.start_time;
    const currentTime = moment().utc();
    const bookingTime = moment(reservationDate)?.utc();
    return currentTime.isBefore(bookingTime);
  }

  function returnToReservations() {
    history.push(`/tee-on/portal/reservations`);
  }

  function navigateToEditReservation() {
    if (token) {
      history.push(`/tee-on/portal/reservations/${state?.reservation?.token}/edit`);
    }
  }

  function initiateCancelReservation() {
    setState(prevState => ({ ...prevState, cancelPopUp: true }));
  }

  async function handleCancelReservation() {
    if (state?.reservation) {
      const cancelRes = await CancelReservationBooking({ id: state?.reservation?.id }, true);
      if (cancelRes?.status !== StatusCode.OK) {
        dispatch(showError("Error cancelling reservation"));
        return;
      }
      dispatch(showSuccess("Successfully cancelled reservation"));
      void returnToReservations();
    }
  }

  function checkEditCancelTimes(booking: IReservationBooking, facility: IFacility, module: IModule) {
    let hideEditButton = false;
    let hideCancelButton = false;
    const currentDateUTC = moment.utc();
    const reservationTimeZoneDate = moment
      .tz(`${booking?.date}T${booking?.start_time}`, facility?.timezone)
      .format("YYYY-MM-DD HH:mm:ss")
      .toString();

    const reservationCancelTime = new Date(reservationTimeZoneDate);
    const reservationEditTime = new Date(reservationTimeZoneDate);

    reservationCancelTime.setHours(reservationCancelTime.getHours() - module?.web_cancellation_hours);
    reservationEditTime.setHours(reservationEditTime.getHours() - module?.web_edit_hours);

    const reservationCancelTimeUTC = moment.utc(reservationCancelTime);
    const reservationEditTimeUTC = moment.utc(reservationEditTime);

    if (currentDateUTC?.isSameOrAfter(reservationCancelTimeUTC)) {
      hideCancelButton = true;
    }

    if (currentDateUTC?.isSameOrAfter(reservationEditTimeUTC)) {
      hideEditButton = true;
    }

    return [hideEditButton, hideCancelButton];
  }

  return (
    <Page
      title="Reservation"
      breadcrumbs={[{ prefix: true, label: "Back to Reservations", url: "/tee-on/portal/reservations" }]}
    >
      <div className="portal-reservations">
        {state.reservation && (
          <div>
            <div className="facility-container">
              {state?.facility?.logo_source && <img src={state?.facility?.logo_source} alt={"Facility logo"} />}
              <div className="facility-details-container">
                <h1 className="facility-name">{state?.facility?.long_name}</h1>
                <p className="facility-address">{`${state?.facility?.address_line_1}, ${state?.facility?.city},
  ${state?.facility?.province_name}, ${state.facility?.postal}`}</p>
              </div>
            </div>
            <Card title="Reservation Details">
              <Card.Section>
                <p className="module-name">
                  {state?.module?.title} - {state?.location?.title}
                </p>
                <div className="info-parent-container">
                  <p className="confirmation-text">
                    Confirmation Number{" "}
                    {<span className="confirmation-number">{state.reservation?.confirmation_number}</span>}
                  </p>
                  {state?.reservation?.order_id && (
                    <Badge type="primary" size="medium">
                      Paid
                    </Badge>
                  )}
                  {state?.reservation?.status === "cancelled" && (
                    <Badge type="error" size="medium">
                      Cancelled
                    </Badge>
                  )}
                </div>
                <div className="date-group-container ">
                  <FontAwesomeIcon icon={["far", "calendar"]} />
                  <p className="date-time-info">
                    {moment(`${state.reservation?.date}T${state.reservation?.start_time}`).format("LLLL")}
                    {state?.endTime && ` - ${moment(`${state.reservation?.date}T${state.endTime}`).format("LT")}`}
                  </p>
                </div>
                <div className="info-parent-container">
                  <div className="info-container">
                    <FontAwesomeIcon className="info-text " icon={["far", "user-group"]} fixedWidth />
                    <p className="info-text ">
                      {state.reservation?.quantity} {state.reservation?.quantity === 1 ? "Guest" : "Guests"}
                    </p>
                  </div>
                  {state?.duration && (
                    <div className="info-container">
                      <FontAwesomeIcon className="info-text " icon={["far", "clock"]} fixedWidth />
                      <p className="info-text ">{state?.duration}</p>
                    </div>
                  )}
                </div>
              </Card.Section>
            </Card>
            <Divider />
            <div className="bottom-buttons-container">
              {state.reservationNotInPast && (
                <>
                  {!editState?.hideCancel && (
                    <ButtonNew
                      className="edit-reservations-update-button "
                      type="secondary"
                      size="medium"
                      onClick={initiateCancelReservation}
                    >
                      Cancel Reservation
                    </ButtonNew>
                  )}
                  {!editState?.hideEdit && (
                    <ButtonNew
                      className="edit-reservations-update-button "
                      type="primary"
                      size="medium"
                      onClick={navigateToEditReservation}
                    >
                      Edit Reservation
                    </ButtonNew>
                  )}
                </>
              )}
            </div>
          </div>
        )}

        <Popup
          onCancel={() => setState(prevState => ({ ...prevState, cancelPopUp: false }))}
          onOk={handleCancelReservation}
          cancelText={"Close"}
          okText={"Cancel"}
          open={state.cancelPopUp && windowSize.width > MOBILE_WIDTH}
          type="warning"
          title={"Confirm Cancellation"}
          description={"Are you sure you want to cancel this reservation?"}
        />

        <BottomSheet
          open={state.cancelPopUp && windowSize.width <= MOBILE_WIDTH}
          onClose={() => setState(prevState => ({ ...prevState, cancelPopUp: false }))}
          type="warning"
          content="Are you sure you want to cancel this reservation?"
          primaryAction={{ label: "Cancel", onClick: handleCancelReservation }}
        />
      </div>
    </Page>
  );
}
