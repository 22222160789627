import React, { useEffect, useState } from "react";

import { StatusCode } from "api/protocols";

import { showError } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";

import { FilterMenu } from "components/filterMenu/FilterMenu";
import Page from "components/page/Page";
import Chart, { IDisplayOptions } from "./Chart";

import "./dashboard.scss";
import { GetDashboard, GetDashboardPopulate } from "api/rpc/2024-04/facilityAdmin/client/admin/dashboard";

interface ITile {
  id: number;
  title: string;
  base_tile: Record<string, any>;
  dashboard_id: number;
  options: Record<string, any>;
  position: string;
  data: Record<string, any>;
  display_options: IDisplayOptions;
}

interface IDashboard {
  id: number;
  main: boolean;
  tiles: Array<ITile>;
  title: string;
}

interface IState {
  dashboards: Array<IDashboard>;
  selectedDashboard: IDashboard;
  selectedPopulatedDashboard: IDashboard;
  defaultDashboardIndex: number;
}

export default function Dashboard() {
  const dispatch = useAppDispatch();
  const [state, setState] = useState<IState>({
    dashboards: [],
    selectedDashboard: null,
    defaultDashboardIndex: null,
    selectedPopulatedDashboard: null,
  });

  useEffect(() => {
    void getDashboards();
  }, []);

  useEffect(() => {
    if (state.selectedDashboard) {
      void populateDashboard();
    }
  }, [state.selectedDashboard]);

  async function populateDashboard() {
    const populateRes = await GetDashboardPopulate({ id: state.selectedDashboard?.id }, true);
    if (populateRes.status !== StatusCode.OK) {
      dispatch(showError(populateRes.data?.message));
      return;
    }
    setState(prevState => ({ ...prevState, selectedPopulatedDashboard: populateRes.data }));
  }

  async function getDashboards() {
    const dashboardRes = await GetDashboard(null, true);
    if (dashboardRes.status !== StatusCode.OK) {
      dispatch(showError(dashboardRes.message));
      return;
    }
    if (dashboardRes.data?.length > 0) {
      let mainDashboardIndex = dashboardRes.data?.findIndex((dashboard: IDashboard) => dashboard?.main === true);
      if (mainDashboardIndex === -1) {
        mainDashboardIndex = 0;
      }
      setState(prevState => ({
        ...prevState,
        dashboards: dashboardRes.data,
        selectedDashboard: dashboardRes.data[mainDashboardIndex],
        defaultDashboardIndex: mainDashboardIndex,
      }));
    }
  }

  return (
    <Page title={state.selectedDashboard?.title}>
      <div className="dashboard-container">
        <div className="dashboard-nav">
          <FilterMenu
            options={state.dashboards}
            optionTitle="title"
            defaultSelected={state.defaultDashboardIndex}
            onClick={(option, selectedIndex) => setState(prevState => ({ ...prevState, selectedDashboard: option }))}
          />
        </div>
        <div className="dashboard-content">
          {state.selectedPopulatedDashboard?.tiles?.map((tile, tileIndex) => {
            return (
              <Chart
                key={tileIndex}
                rawData={tile.data?.series}
                chartType={tile?.base_tile?.type}
                title={tile?.title}
                primaryAxisOptions={tile?.data?.primaryAxis}
                secondaryAxisOptions={tile?.data?.secondaryAxis}
                displayOptions={tile?.display_options ? tile?.display_options : undefined}
              />
            );
          })}
        </div>
      </div>
    </Page>
  );
}
