import React, { createContext, useContext, useLayoutEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { DBFilterContext } from "redux/reducers/filters/filters";
import { ComponentKey } from "redux/reducers/filters/types";

import useOnclickOutside from "hooks/useOnclickOutside/useOnclickOutside";
import { ButtonNew } from "components/buttonNew";
import Spin from "components/spin/spin";

import "./accordion.scss";

export type AccordionProps = {
  toggleCallback?: () => void;

  collection: ComponentKey;
  activeFilters: DBFilterContext[];
  isLoading?: boolean;
  rightAligned?: boolean;

  applyAction: () => void;
  clearAction: () => void;
  allFiltersAreApplied?: boolean;
};

const FilterAccordionContext = createContext({ collectionContext: undefined as ComponentKey });
export const useFilterAccordionContext = () => useContext(FilterAccordionContext);

export default function Accordion(props: AccordionProps & { children: React.ReactNode }) {
  const { isLoading = false } = props;

  const accordionRef = useOnclickOutside(() => {
    setAccordion({ open: false });
  });

  const [accordion, setAccordion] = useState({ open: true });
  const accordionOpenAndLoaded = accordion.open && !isLoading;

  useLayoutEffect(() => {
    setAccordion({ open: false }); // Allow AccordionItem height and visibility to be captured before first paint
  }, []);

  function accordionToggle() {
    if (props.isLoading !== undefined && props.isLoading) {
      return;
    }

    setAccordion({ open: !accordion.open });
    props.toggleCallback && props.toggleCallback();
  }

  return (
    <div className="accordion-ui">
      <div className="accordion-ui-trigger" onClick={() => accordionToggle()} ref={accordionRef}>
        <FontAwesomeIcon size="lg" icon={["far", "bars-filter"]} />
        {props?.activeFilters && isLoading && (
          <span className="trigger-count" style={{ padding: "5px" }}>
            <Spin />
          </span>
        )}
        {props.activeFilters && !isLoading && (
          <span className="trigger-count" style={{ display: "flex", justifyContent: "center" }}>
            <p>
              {/* Redux does not remove FilterKey from store if defined before */}
              {props.activeFilters.filter(ele => ele.value.length !== 0).length}
            </p>
          </span>
        )}
      </div>
      <FilterAccordionContext.Provider value={{ collectionContext: props.collection }}>
        <div
          className="accordion-ui-panel"
          style={{
            visibility: isLoading ? "hidden" : "visible",
            display: accordionOpenAndLoaded || isLoading ? "block" : "none",
            right: props.rightAligned ? "0" : "",
          }}
          ref={accordionRef}
        >
          <div className="accordion-ui-panel-header">
            <div className="accordion-ui-header-left">
              <ButtonNew
                type="secondary"
                size="xsmall"
                onClick={() => props.applyAction()}
                disabled={props.allFiltersAreApplied !== undefined && props.allFiltersAreApplied}
              >
                Apply
              </ButtonNew>

              {props.allFiltersAreApplied === undefined ? null : (
                <div className={`left-icon ${props.allFiltersAreApplied ? "blue" : "orange"}`}>
                  <FontAwesomeIcon
                    size="lg"
                    icon={props.allFiltersAreApplied ? ["fal", "check-circle"] : ["fal", "exclamation-circle"]}
                  />
                </div>
              )}
            </div>
            <ButtonNew type="text" onClick={() => props.clearAction()} size="xsmall">
              Clear
            </ButtonNew>
          </div>
          {props.children}
        </div>
      </FilterAccordionContext.Provider>
    </div>
  );
}

export const filterContextIsEqual = (first: DBFilterContext[], second: DBFilterContext[]) => {
  if (first === undefined || second === undefined) {
    return;
  }

  return (
    JSON.stringify(first.filter(ele => ele.value.length !== 0)) ===
    JSON.stringify(second.filter(ele => ele.value.length !== 0))
  );
};
