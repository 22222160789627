import { ButtonNew as Button } from "components/buttonNew";
import Sheet from "components/sheet/Sheet";
import Portal from "elements/Portal";
import SelectCustomer from "elements/register/SelectCustomer";
import React, { useCallback, useState } from "react";
import { ICustomer } from "redux/reducers/models/customer";
import "./selectCustomers.scss";

interface IProps {
  /**Displays modal*/
  open: boolean;
  /**Function that runs when modal primary button is clicked. Returns array of selected customers*/
  onOk: (customers: Array<ICustomer>) => void;
  /**Function that runs when modal secondary button is clicked*/
  onClose: () => void;
  /**Optional title of modal. Defaults to 'Select Customers' */
  title?: string;
  /**Optional text for the modal primary button. Defaults to 'Confirm' */
  okText?: string;
  /**Optional text that is displayed when customers are selected. Title and subtitle are displayed above the list of customers */
  content?: {
    title: string;
    subtitle: string;
  };
}

interface IState {
  selectedCustomers: Array<ICustomer>;
}

/**
 * Modal used for searching and selecting multiple customers
 * @param {IProps} props
 * @param {boolean} props.open
 * @param {(customers: Array<ICustomer>) => void} props.onOk Function that runs when modal primary button is clicked. Returns array of selected customers
 * @param {() => void} props.onClose Function that runs when modal secondary button is clicked
 * @param {string} props.title Optional title of modal. Defaults to 'Select Customers' `(optional)`
 * @param {string} props.okText Optional text for the modal primary button. Defaults to 'Confirm' `(optional)`
 * @param {{title: string; subtitle: string}} props.content Optional text that is displayed when customers are selected. Title and subtitle are displayed above the list of customers  `(optional)`
 *
 * @example return: Array<ICustomer>
 */
export default function SelectCustomers(props: IProps) {
  const { open, onOk, onClose, title, okText, content } = props;
  const [state, setState] = useState<IState>({
    selectedCustomers: [],
  });

  function handleClose() {
    void onClose();
    setState(prevState => ({ ...prevState, selectedCustomers: [] }));
  }

  //Scroll added customer into view if the modal overflows
  const customersRef = useCallback(
    (element: HTMLDivElement, index: number) => {
      if (state.selectedCustomers?.length > 0) {
        if (element !== null && index === state?.selectedCustomers?.length - 1) {
          element?.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }
    },
    [state?.selectedCustomers],
  );

  return (
    <Portal isMounted={open}>
      <Sheet
        open={open}
        onCancel={handleClose}
        onOk={() => {
          onOk(state?.selectedCustomers);
          setState(prevState => ({ ...prevState, selectedCustomers: [] }));
        }}
        okText={okText ?? "Confirm"}
        size="small"
        closable
        title={title ?? "Select Customers"}
        overflow
        backDropCancel={false}
        okDisabled={state?.selectedCustomers?.length === 0}
      >
        <SelectCustomer
          onCustomerChange={customer =>
            setState(prevState => ({
              ...prevState,
              selectedCustomers: [...prevState.selectedCustomers, customer],
            }))
          }
          permissionLevel={"facility"}
          newCustomer
          placeholder="Search customers..."
          clearOnSelection
          autoFocus
          filter={{ key: "id", filterArray: [...state.selectedCustomers?.map(customer => customer?.id)] }}
        />
        {state?.selectedCustomers?.length > 0 && (
          <div className="select-customers-modal-main-container">
            {content && (
              <>
                <h1>{content.title}</h1>
                <p className="select-customers-subtext ">{content.subtitle}</p>
              </>
            )}
            <div className="select-customers-modal-customers-group">
              {state?.selectedCustomers?.map((customer, customerIndex) => {
                return (
                  <div key={customer?.id} className="customers-container" ref={e => customersRef(e, customerIndex)}>
                    <div className="customers-container-details">
                      <div className="image-outer">
                        <div className="image-inner">
                          {customer?.first_name?.charAt(0)?.toUpperCase()}
                          {customer?.last_name?.charAt(0)?.toUpperCase()}
                        </div>
                      </div>
                      <div>
                        <p className="customer-name">{customer?.full_name}</p>
                        {customer?.customer_type && <p className="customer-details">{customer?.customer_type}</p>}
                        <div className="customer-details-group">
                          {customer?.email && <p className="customer-details">{customer?.email}</p>}
                          {customer?.phone && <p className="customer-details">{customer?.phone}</p>}
                        </div>
                      </div>
                    </div>
                    <Button
                      size="small"
                      type="text"
                      onClick={() =>
                        setState(prevState => ({
                          ...prevState,
                          selectedCustomers: prevState?.selectedCustomers?.filter(
                            filterCustomer => filterCustomer?.id !== customer?.id,
                          ),
                        }))
                      }
                    >
                      Remove
                    </Button>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </Sheet>
    </Portal>
  );
}
