import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useHistory, useParams, Prompt } from "react-router";
import { useTranslation } from "react-i18next";
import _, { isEqual, cloneDeep } from "lodash";
import classNames from "classnames";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";

import { dequeue, enqueue, initiateEditBooking, showError, showSuccess } from "redux/actions/ui";
import { loadDiscountOptions, loadPaymentOptions } from "redux/actions/facility";
import { selectTeeTime, selectTeeTimeSuccess } from "redux/actions/teesheet";
import { updateTeeSheetProducts } from "redux/actions/facilityAdmin/teeSheetProducts";
import { ITeeSheetState } from "redux/reducers/teesheet";
import { IProduct } from "redux/reducers/models/product";
import { ICheckedInPlayer, ICustomer } from "redux/reducers/models/customer";
import { ICart, ICartLineItem } from "redux/reducers/models/cart";
import { IPaymentOption } from "redux/reducers/models/transaction";
import { IOrder } from "redux/reducers/models/order";
import { IBooking, IBookingParticipant, ISlot, ITeeTime } from "redux/reducers/models/teetime";

import { StatusCode } from "api/protocols";
import { PutBookingParticipant, PutBookingParticipantNoShow } from "api/rpc/teeSheet/booking";
import { GetCartWaiver } from "api/rpc/2024-04/facilityAdmin/teesheet/booking";
import {
  PostIssueRainCheck,
  PutCalculateRainCheck,
  PostRedeemRainCheck,
} from "api/rpc/facilityAdmin/payment/rainCheck";
import { GetRoomOccupants, IOccupant } from "api/rpc/room";
import { GetTeeTime } from "api/rpc/teeSheet/teeSheet";
import { GetProduct } from "api/rpc/facilityAdmin/product/products";
import { PostCustomer } from "api/rpc/customer";
import { PutCustomerCart } from "api/rpc/facilityAdmin/order/cart/cart";
import { GetTeeTimeLock, PostTeeTimeLock } from "api/rpc/facilityAdmin/teesheet/teetime";
import { GetCustomer, GetCustomerPaymentMethod } from "api/rpc/facilityAdmin/customer";
import {
  GetOrder,
  GetReceipt,
  PostLineItemsToOrder,
  PostOrder,
  PutOrderComplete,
  PutVoidOrder,
} from "api/rpc/2022-09/facilityAdmin/order/order";
import {
  GetTicketValidate,
  IGetTicketValidateRes,
  PutRedeem,
  GetTicketStub,
} from "api/rpc/2022-09/facilityAdmin/ticket/ticket";
import { PutTicketStubRedeemMultiple } from "api/rpc/2024-04/facilityAdmin/ticket/stub";
import { PostCart, PutCart, PostCheckout } from "api/rpc/2022-09/facilityAdmin/cart/cart";
import { PostLineItemToCart, PutLineItemToCart } from "api/rpc/2024-04/facilityAdmin/order/cart/lineItem";
import { PostTransaction, PutCapture } from "api/rpc/2022-09/facilityAdmin/order/transaction";
import { GetGiftCardValidate } from "api/rpc/2022-09/facilityAdmin/payment/giftCard";
import { GetBooking, PutCheckParticipant, PutCancelParticipant } from "api/rpc/2022-09/facilityAdmin/teesheet/booking";
import { DeleteDiscountLine } from "api/rpc/2022-09/facilityAdmin/product/discount";
import {
  convertOrderIntoDisplayInfo,
  setReaderDisplay,
  processPayment as stripeProcessPayment,
  sheetInfo,
  clearReaderDisplay,
} from "helpers/StripeTerminalWrapper";
import { convertTime, customerErrorMessage, isEmpty } from "helpers/Helpers";
import { LocaleCurrency } from "helpers/Locale";
import { useStripeTerminalPayment } from "hooks/useStripeTerminalPayment/useStripeTerminalPayment";

import BookingModalEdit from "elements/teesheet/BookingModalEdit";
import AddItemSheet from "elements/teesheet/AddItemSheet";
import PaymentMethods from "elements/register/cartMenu/PaymentMethods";
import CartTotals from "elements/register/cartMenu/CartTotals";
import CartLineItems from "elements/register/cartMenu/CartLineItems";
import CartDiscountLines from "elements/register/cartMenu/CartDiscountLines";
import CartTransactions from "elements/register/cartMenu/CartTransactions";
import Products, { IProductState } from "elements/register/Products";

import Icon from "components/icon/Icon";
import Input from "components/form/input/Input";
import Sheet from "components/sheet/Sheet";
import Divider from "components/divider";
import Callout from "components/callout/Callout";
import Checkbox from "components/form/checkbox/Checkbox";
import { Select } from "components/select/index";
import { ButtonNew as Button } from "components/buttonNew/index";
import ButtonGroup from "components/button/ButtonGroup";
import Tabs from "components/tabs/Tabs";
import Popup from "components/popup/Popup";
import CheckoutForm, { cancelManualCreditPaymentIntent } from "components/checkoutForm/CheckoutForm";
import GolferCard from "components/bookingPopUp/golferCard/GolferCard";
import OrderCompletePopup from "components/orderCompletePopup/OrderCompletePopup";
import GiftCardSell from "components/giftcardSell/GiftCardSell";
import GiftCardRedeem, { IGiftCardRedeemState } from "components/giftCardRedeem/GiftCardRedeem";
import AccountsModal from "components/accountsModal/AccountsModal";
import LeagueSelectionModal from "components/leagueSelectionModal/LeagueSelectionModal";
import { Badge } from "components/badge/Badge";
import NewCustomer from "components/newCustomer/NewCustomer";

import TeeSheetBookingDisplay from "./TeeSheetBookingDisplay";
import UpdateLineItemModal, { ILineItem, IUpdateLineItemModalState } from "./UpdateLineItemModal";

import "./styles.scss";
import axios, { CancelToken } from "axios";
import Markdown from "components/markdown/Markdown";
import Spin from "components/spin/spin";
import RainCheckCompletePopup from "components/rainCheckCompletePopup/RainCheckCompletePopup";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import useModal from "hooks/modals/useModal";
import { IPrizeAccount } from "../settings/prizeAccounts/PrizeAccount";
import CreditBookModal from "components/creditBookModal/CreditBookModal";
import { NavigationDropdownNew } from "components/navigationDropdownNew/NavigationDropdownNew";
import ChangeCustomer from "components/changeCustomer/ChangeCustomer";
import Portal from "elements/Portal";
import DataTable from "../customer/tabs/houseAccounts/DataTable";
import FormLayout from "components/form/FormLayout";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IStub, ITicket } from "redux/reducers/models/ticket";
import { TSku } from "../settings/inventoryCounts/EditInventoryCount";

interface ITeeSheetDetailsParams {
  teetimeId: string;
}

const enum DrawerPage {
  NEW_ORDER,
  BOOKING_DETAILS,
  GOLFER_ORDER,
  PAYMENT_METHOD,
  RECEIPT_ORDER,
}

interface IDrawerPage {
  currentPage: DrawerPage;
}

export interface ICheckInState {
  selectedTeeTimeId: number;
  checkedInPlayers: ICheckedInPlayer[];
  cart: ICart;
  paymentOptions: IPaymentOption[];
  paymentOption: Record<string, any>;
  roomChargeSheetVisible: boolean;
  roomOccupantSearchQuery: string;
  roomOccupantsSearchResults: IOccupant[];
  selectedOccupant: IOccupant;
  order: IOrder | Partial<IOrder>;
  paymentAmount: number;
  updateLineItemModalOpen: boolean;
  selectedItem: Record<string, any>;
  giftCardActive: boolean;
  redeemTicketActive: boolean;
  redeemTicketSelected: number;
  redeem_ticket_code: string;
  ticketValidSuccess: boolean;
  ticketValidWarning: boolean;
  ticketRedeemSuccess: boolean;
  availableTickets: any;
  selectedTickets: IStub[];
  getTicketValidateData: IGetTicketValidateRes["data"] | Partial<IGetTicketValidateRes["data"]>;
  accountsVisible: boolean;
  accounts: Record<string, any>[];
  selectedAccount: Record<string, any>;
  oneAccount: boolean;
  applyLineItemDiscountModal: boolean;
  lineItem: Record<string, any>;
  changeDue: number;
  changeDuePopupVisible: boolean;
  orderCompleteActive: boolean;
  cancelOrderActive: boolean;
  manualCheckInVisible: boolean;
  manualCheckInSlot: ISlot;
}

export interface ITerminalState {
  online: boolean;
  connecting: boolean;
  reader: any;
}

export interface IMainState {
  teetimes: ITeeTime[];
  currentTime: Date;
  golferSearchQuery: string; // may be able to delete this
  golferSearchResult: Array<ICustomer> | Array<Partial<ICustomer>>; // may be able to delete this
  holes: string | number;
  players: string | number;
  currentDivision: number;
  selectedBooking: IBooking;
  teeTimeLock: boolean;
  teeTimeCancelledPopupVisible: boolean;
  applyLineItemDiscountModal: boolean;
  lineItem: Record<string, any>;
  selectedDiscountId: number;
  gift_cards: Array<{
    cart_line_item_id: number;
    code: string;
    pin: number;
    random: boolean;
    balance: number;
    reload: number;
  }>;
  sellGiftCardsActive: boolean;
  clearCartActive: boolean;
  leagueFeesActive: boolean;
  leagueFeeProducts: Array<ICartLineItem>;
  selectedLeagues: Array<number>;
  paymentInProgress: boolean;
  powerCartModalVisible: boolean;
}

interface IAddedItem {
  booking_participant_id: number;
  green_fee: boolean;
  power_cart_fee: boolean;
  both: boolean;
}

export interface IAddItemState {
  addItemActive: boolean;
  greenFeeActive: boolean;
  powerCartFeeActive: boolean;
  itemSearchQuery: string;
  itemSearchResult: IProduct[];
  participant_id: number;
  tee_time_id: number;
  addedItems: Array<IAddedItem>;
  greenfeeSearchQuery: string;
  cartSearchQuery: string;
  foundGreenFees: IProduct[];
  foundCartItems: IProduct[];
  productState: IProductState;
  selectedSlot: ISlot;
}

export interface IFilterState {
  bookedSlots: any;
  openSlots: any;
  selectedTeesheet: any;
  selectedDate: Date;
  selectedOrder: any;
  selectedBookingParticipant: any;
  selectedTeeTime: any;
}

export interface IStripePaymentState {
  onOk: any;
  onCancel: any;
  okText: string;
  cancelText: string;
  sheetVisible: boolean;
  titleText: string;
  message: string;
  convertedCode: string;
  processing: boolean;
  success: boolean;
}

interface INoShowState {
  noShowActive: boolean;
  bookingParticipantIds: Array<number>;
  quantity: number;
  greenFeeProductID: number;
  greenFeeProductTitle: string;
  greenFeeProductPrice: number;
  paymentMethodID: string;
  customerID: number;
  teeTimeBookingId: number;
  greenFeeProducts: IProduct[];
  noPaymentMethodModal: boolean;
}

interface IRainCheckState {
  rainCheckActive: boolean;
  rainCheckCompleteActive: boolean;
  rainCheck: any;
  checkBoxes: boolean[];
  selectedBookingParticipantId: number;
  selectedBookingParticipant: any;
  continueBtn: boolean;
  holes: number;
  completedHoles: string;
  percent: number;
  amount: number;
  calculate: number;
  orderId: number;
  issue_on_green_fee: boolean;
  issue_on_power_cart: boolean;

  redeemRainCheckActive: boolean;
  redeemRainCheckCode: string;
  okDisabled: boolean;
}
interface IGroupPayState {
  groupPayActive: boolean;
  groupPayTeeTimes: ITeeTime[];
  playerSearch: string;
}

type FeeStatusType = {
  [id: number]: {
    greenFeeChecked: boolean;
    powerCartChecked: boolean;
  };
};

interface ICustomerState {
  customerSearchQuery: string;
  customerSearchResults: (Partial<ICustomer> | ICustomer)[];
  searchingCustomer: boolean;
  showCustomerSearch: boolean;
}

interface IChangePlayerState {
  showChangePlayer: boolean;
  showNewPlayer: boolean;
  customerQuery: string;
  customerSearching: boolean;
  playerSearchResult: Array<ICustomer>;
  selectedCustomer: ICustomer;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  currentCustomer: any;
}

export interface ICustomerInfoState {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
}

export enum StateType {
  MainState = "IMainState",
  AddItemState = "IAddItemState",
  CheckInState = "ICheckInState",
  DrawerPage = "IDrawerPage",
  NoShowState = "INoShowState",
  FilterState = "IFilterState",
  RainCheckState = "IRainCheckState",
  GroupPayState = "IGroupPayState",
  LineItemState = "ILineItemState",
}

interface ITicketState {
  tickets: Array<TSku>;
  ticketString: string;
}

const TeeSheetDetails: React.FC = () => {
  const stripe = useStripe();
  const { teeSheetStore, uiStore, facilityStore, terminalStore, authStore, cartStore, teeSheetProductStore } =
    useAppSelector(store => store);
  const { Option } = Select;

  const permissions = authStore?.user?.permissions;

  const history = useHistory();

  const elements = useElements();
  const dispatch = useAppDispatch();

  const { teetimeId } = useParams<ITeeSheetDetailsParams>();

  const [drawerPage, setDrawerPage] = useState<IDrawerPage>({
    currentPage: DrawerPage.NEW_ORDER,
  });

  const [noShow, setNoShow] = useState<INoShowState>({
    noShowActive: false,
    bookingParticipantIds: [],
    quantity: 0,
    greenFeeProductID: null,
    greenFeeProductTitle: "",
    greenFeeProductPrice: null,
    paymentMethodID: "",
    customerID: null,
    teeTimeBookingId: null,
    greenFeeProducts: [],
    noPaymentMethodModal: false,
  });

  const [addItemState, setAddItemState] = useState<IAddItemState>({
    addItemActive: false,
    greenFeeActive: false,
    powerCartFeeActive: false,
    itemSearchQuery: "",
    itemSearchResult: [],
    participant_id: null,
    tee_time_id: null,
    addedItems: [],
    greenfeeSearchQuery: "",
    cartSearchQuery: "",
    foundGreenFees: [],
    foundCartItems: [],
    productState: {
      selectedProduct: undefined,
      variants: [],
      variantSheetOpen: false,
    },
    selectedSlot: null,
  });

  const {
    state: modalCreditBook,
    updateModal: updateCreditBook,
    closeModal: closeCreditBook,
  } = useModal<{ selectedCreditBook: IPrizeAccount }>({ clearOnClose: true, selectedCreditBook: null });

  const [checkInState, setCheckInState] = useState<ICheckInState>({
    selectedTeeTimeId: null,
    checkedInPlayers: [],
    cart: null,
    paymentOptions: [],
    paymentOption: null,
    roomChargeSheetVisible: false,
    roomOccupantSearchQuery: "",
    roomOccupantsSearchResults: [],
    selectedOccupant: null,
    order: undefined,
    paymentAmount: 0,
    updateLineItemModalOpen: false,
    selectedItem: {},
    giftCardActive: false,
    redeemTicketActive: false,
    redeemTicketSelected: 0,
    redeem_ticket_code: "",
    ticketValidSuccess: false,
    ticketValidWarning: false,
    ticketRedeemSuccess: false,
    getTicketValidateData: {},
    availableTickets: [],
    selectedTickets: [],
    accountsVisible: false,
    accounts: [],
    selectedAccount: undefined,
    oneAccount: false,
    applyLineItemDiscountModal: false,
    lineItem: undefined,
    changeDue: null,
    changeDuePopupVisible: false,
    orderCompleteActive: false,
    cancelOrderActive: false,
    manualCheckInVisible: false,
    manualCheckInSlot: null,
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    selectedTeesheet: 54,
    bookedSlots: 0,
    openSlots: 0,
    selectedDate: new Date(),
    selectedOrder: null,
    selectedTeeTime: null,
    selectedBookingParticipant: null,
  });

  const [manualStripeState, setManualStripeState] = useState({
    clientSecret: "",
    visible: false,
    transaction: undefined,
    elementComplete: {
      cardNumber: false,
      cardExpiry: false,
      cardCvc: false,
    },
  });

  const [state, setState] = useState<IMainState>({
    teetimes: [],
    currentTime: new Date(),
    golferSearchQuery: "",
    golferSearchResult: [null],
    holes: null,
    players: null,
    currentDivision: null,
    selectedBooking: null,
    teeTimeLock: false,
    teeTimeCancelledPopupVisible: false,
    applyLineItemDiscountModal: false,
    lineItem: undefined,
    selectedDiscountId: -1,
    gift_cards: null,
    sellGiftCardsActive: false,
    clearCartActive: false,
    leagueFeesActive: false,
    leagueFeeProducts: [],
    selectedLeagues: null,
    paymentInProgress: false,
    powerCartModalVisible: false,
  });

  const [customerState, setCustomerState] = useState<ICustomerState>({
    customerSearchQuery: "",
    customerSearchResults: [null],
    searchingCustomer: false,
    showCustomerSearch: false,
  });

  const [rainCheckState, setRainCheckState] = useState<IRainCheckState>({
    //Issue Rain Check
    rainCheckActive: false,
    rainCheckCompleteActive: false,
    rainCheck: null,
    checkBoxes: new Array(4).fill(false),
    selectedBookingParticipantId: null,
    selectedBookingParticipant: null,
    continueBtn: false,
    holes: null,
    completedHoles: "",
    percent: null,
    amount: null,
    calculate: null,
    orderId: null,
    issue_on_green_fee: false,
    issue_on_power_cart: false,

    //Redeem Rain Check
    redeemRainCheckActive: false,
    redeemRainCheckCode: "",
    okDisabled: true,
  });

  const [groupPayState, setGroupPayState] = useState<IGroupPayState>({
    groupPayActive: false,
    groupPayTeeTimes: [],
    playerSearch: "",
  });

  const [lineItemState, setLineItemState] = useState<IUpdateLineItemModalState>({
    lineItem: null,
    isModalVisible: false,
    inputQuantity: "",
    inputPrice: "",
    inputNote: "",
  });

  const [changePlayerState, setChangePlayerState] = useState<IChangePlayerState>({
    showChangePlayer: false,
    showNewPlayer: false,
    customerQuery: "",
    customerSearching: false,
    playerSearchResult: [],
    selectedCustomer: null,
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    currentCustomer: null,
  });

  const [removeDiscountState, setRemoveDiscountState] = useState<{
    modalVisible: boolean;
    discountId: number;
    cartId: number;
  }>({
    modalVisible: false,
    discountId: null,
    cartId: null,
  });

  const {
    state: orderCompletePopup,
    updateModal: updateOrderCompletePopup,
    closeModal: closeOrderCompletePopup,
  } = useModal({
    clearOnClose: true,
    loading: false,
    transactionComplete: false,
  });

  const [ticketState, setTicketState] = useState<ITicketState>({
    tickets: [],
    ticketString: "",
  });

  const [submitTrigger, setSubmitTrigger] = useState<boolean>(false);
  const [manualProcessing, setManualProcessing] = useState<boolean>(false);
  const { t, i18n } = useTranslation();

  const {
    StripeTerminalSheet,
    processPayment,
    paymentStatus,
    order: tempStripePaymentOrder,
  } = useStripeTerminalPayment();

  const handleStripeSuccess = (message?: string, order_id?: number) => {
    setStripePaymentState(prevState => ({
      ...prevState,
      sheetVisible: true,
      onCancel: undefined,
      okText: t("secure.facility.tee_sheet.tee_sheet_booking_details.001"),
      onOk: () => {
        setStripePaymentState(prevState => ({
          ...prevState,
          sheetVisible: false,
          onCancel: undefined,
          okText: undefined,
          processing: false,
          onOk: () => {},
          titleText: "",
          message: "",
        }));
      },
      processing: false,
      success: true,
      titleText: t("secure.facility.tee_sheet.tee_sheet_booking_details.002"),
      message: t("secure.facility.tee_sheet.tee_sheet_booking_details.003"),
    }));
    dispatch(showSuccess(t("secure.facility.tee_sheet.tee_sheet_booking_details.004")));
    // updateOrderCompletePopup({ isOpen: true, transactionComplete: true, loading: true });
    void updateCartOrder(order_id);
  };

  const handleStripeError = (message: string, sheetInfo: sheetInfo) => {
    //set sheet
    setStripePaymentState(prevState => ({
      ...prevState,
      onCancel: () => {
        sheetInfo.onCancel();
        setStripePaymentState(prevState => ({ ...prevState, sheetVisible: false }));
      },
      onOk: () => {
        sheetInfo.onOk();
        setStripePaymentState(prevState => ({ ...prevState, sheetVisible: false }));
      },
      cancelText: sheetInfo.cancelText,
      okText: sheetInfo.okText,
      sheetVisible: true,
      titleText: sheetInfo.title,
      message: sheetInfo.message,
      convertedCode: sheetInfo.convertedErrorCode,
      success: false,
    }));
  };

  const handleCheckInParticipants = async (order: IOrder) => {
    if (order) {
      if (order.balance <= 0 || order.financial_status === "paid") {
        try {
          for (const item of addItemState.addedItems) {
            const checkRes = await PutCheckParticipant(
              {
                booking_participant_ids: [item.booking_participant_id],
                green_fee_paid: item?.green_fee ? true : undefined,
                power_cart_paid: item?.power_cart_fee ? true : undefined,
                order_id: item?.green_fee ? order?.id : undefined,
              },
              false,
            );
            if (checkRes?.status !== StatusCode.OK) {
              dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.179")));
            }
          }
          //Check in using array of participant ids. Disable for now
          // const participantIds = addItemState?.addedItems
          //   ?.filter(filteredItem => filteredItem?.green_fee)
          //   ?.map(item => item?.booking_participant_id);
          // await PutCheckParticipant({ booking_participant_ids: participantIds }, false);
          // await loadTeetimes(filterState.selectedDate);
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  useEffect(() => {
    //Show warning dialog box when browser is refreshed
    function handleBeforeUnload(event: BeforeUnloadEvent) {
      event.preventDefault();
      event.returnValue = "";
      return "";
    }
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  useEffect(() => {
    if ((paymentStatus === "partial_payment" || paymentStatus === "paid") && tempStripePaymentOrder !== null) {
      if (paymentStatus === "paid") {
        updateOrderCompletePopup({ isOpen: true, loading: true });
      }
      void updateCartOrder(tempStripePaymentOrder.id);
    }
  }, [paymentStatus]);

  //Update reader on cart change
  useEffect(() => {
    if (checkInState.cart?.line_items && terminalStore.reader) {
      if (checkInState.cart.total_price > 0) {
        void setReaderDisplay(convertOrderIntoDisplayInfo(checkInState.cart, checkInState.order));
      }
    }
  }, [checkInState.cart, terminalStore.reader, checkInState.cart?.tax_lines]);

  useEffect(() => {
    if (checkInState.cart) {
      setCheckInState(prevState => ({
        ...prevState,
        paymentAmount: Number(checkInState.cart?.total_price?.toFixed(2)),
      }));
    }
  }, [checkInState.cart]);

  const [stripePaymentState, setStripePaymentState] = useState<IStripePaymentState>({
    onCancel: () => {},
    onOk: () => {},
    cancelText: "",
    okText: "",
    sheetVisible: false,
    titleText: "",
    message: "",
    convertedCode: "",
    processing: false,
    success: false,
  });

  useEffect(() => {
    if (!facilityStore?.paymentOptions || facilityStore?.paymentOptions?.length === 0) {
      void dispatch(loadPaymentOptions());
    }
    if (facilityStore?.discountOptions?.length === 0) {
      void dispatch(loadDiscountOptions());
    }
    void dispatch(selectTeeTime(parseInt(teetimeId), true));
  }, []);

  useEffect(() => {
    // If there are no bookings in the selected tee time, return to the tee sheet
    if (teeSheetStore.selectedTeeTime && teeSheetStore.selectedTeeTime?.quantity_remaining === 4) {
      history.push(`/admin/teesheet`);
    }
    // void loadGroupPayTeeTimes();
  }, [teeSheetStore.selectedTeeTime]);

  function updateState<T>(newState: Partial<T>, type: StateType) {
    const states = {
      [StateType.MainState]: (newState: Partial<T>) =>
        setState((cur: IMainState) => {
          return { ...cur, ...newState };
        }),
      [StateType.AddItemState]: (newState: Partial<T>) =>
        setAddItemState((cur: IAddItemState) => {
          return { ...cur, ...newState };
        }),
      [StateType.CheckInState]: (newState: Partial<T>) =>
        setCheckInState((cur: ICheckInState) => {
          return { ...cur, ...newState };
        }),
      [StateType.DrawerPage]: (newState: Partial<T>) =>
        setDrawerPage((cur: IDrawerPage) => {
          return { ...cur, ...newState };
        }),

      [StateType.NoShowState]: (newState: Partial<T>) =>
        setNoShow((cur: INoShowState) => {
          return { ...cur, ...newState };
        }),
      [StateType.FilterState]: (newState: Partial<T>) =>
        setFilterState((cur: IFilterState) => {
          return { ...cur, ...newState };
        }),
      [StateType.RainCheckState]: (newState: Partial<T>) =>
        setRainCheckState((cur: IRainCheckState) => {
          return { ...cur, ...newState };
        }),
      [StateType.GroupPayState]: (newState: Partial<T>) =>
        setGroupPayState((cur: IGroupPayState) => {
          return { ...cur, ...newState };
        }),
      [StateType.LineItemState]: (newState: Partial<T>) =>
        setLineItemState((cur: IUpdateLineItemModalState) => {
          return { ...cur, ...newState };
        }),
    };

    states[type](newState);
  }

  async function initiateEditBookingModal(booking: IBooking, newPlayerAdded?: boolean) {
    if (newPlayerAdded) {
      booking.quantity = booking.quantity + 1;
    }

    const bookingRes = await GetBooking({ id: booking.id }, true);

    //Check if selected booking has been cancelled by another user
    if (bookingRes.status !== StatusCode.OK || bookingRes.data[0]?.status === "cancelled") {
      updateState<IMainState>({ teeTimeCancelledPopupVisible: true }, StateType.MainState);
      return;
    }

    // dispatch(selectTeeTime(teeSheetStore.selectedTeeTime.id, false);

    // Tee Time Locks
    const teetimeLockRes = await GetTeeTimeLock({ tee_time_id: teeSheetStore.selectedTeeTime.id }, true);
    console.log("TeeTimeLock", teetimeLockRes);

    // Tee time is locked by another user
    if (
      teetimeLockRes.status !== StatusCode.OK ||
      (teetimeLockRes.data.data !== null && teetimeLockRes.data.user_id !== authStore.user.id)
    ) {
      updateState<IMainState>({ teeTimeLock: true }, StateType.MainState);
      dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.005")));
    } else {
      const postLockRes = await PostTeeTimeLock({ tee_time_id: teeSheetStore.selectedTeeTime.id }, true);
      if (postLockRes.status !== StatusCode.OK) {
        return;
      }
    }

    updateState<IMainState>({ selectedBooking: booking }, StateType.MainState);

    dispatch(initiateEditBooking());
  }

  useEffect(() => {
    if (!uiStore.editBookingActive) {
      updateState<IMainState>({ teeTimeLock: false }, StateType.MainState);
    }
  }, [uiStore.editBookingActive]);

  const [addingFee, setAddingFee] = useState(false); // find better place for state

  const handleAddFee = async (
    event: React.MouseEvent<Element, MouseEvent>,
    type: "green_fee" | "power_cart_fee" | "both",
    slot: ISlot,
    addingFee: boolean,
    teeTimeId: number, // For group pay
    quickAddGreenFeeOrPowerCartId?: number,
  ) => {
    if (event) {
      event.stopPropagation();
    }

    setAddingFee(true);

    if (addingFee) {
      return;
    }

    let bothFeesAdded = false;
    let green_fee_variant_id: number;
    let power_cart_variant_id: number;

    if (
      slot.booking_participant.green_fee_variant_id === null &&
      slot.booking_participant.power_cart_variant_id === null &&
      type === "both"
    ) {
      dispatch(showError("Green fee and power cart products are not set"));
      setAddingFee(false);

      return;
    } else if (slot.booking_participant.green_fee_variant_id && slot.booking_participant.power_cart_variant_id) {
      green_fee_variant_id = slot.booking_participant.green_fee_variant_id;
      power_cart_variant_id = slot.booking_participant.power_cart_variant_id;
    }

    if (type === "both") {
      bothFeesAdded = true;
    }

    const fee_added = addItemState.addedItems.some(item => {
      if (item.booking_participant_id === slot.booking_participant.id) {
        return item[type] === true;
      }
    });

    // Check if check in status of selected player has changed (this is for the group pay feature).
    if (teeTimeId && !fee_added) {
      const teeTimeRes = await GetTeeTime({ id: teeTimeId, extended: true, "extended-bookings": true }, true);
      if (teeTimeRes.status !== StatusCode.OK) {
        dispatch(showError(teeTimeRes.message));
        return;
      } else if (teeTimeRes.data.length > 0) {
        const updatedSlot = teeTimeRes.data[0].slots.find(searchSlot => searchSlot.id === slot.id);
        if (!updatedSlot || updatedSlot.booking_participant.check_in_status !== "unchecked") {
          dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.006")));
          updateState<IGroupPayState>({ groupPayActive: false }, StateType.GroupPayState);
          setAddingFee(false);
          void loadGroupPayTeeTimes();
          return;
        }
      }
    }

    // do this when adding is successful
    updateState<IDrawerPage>({ currentPage: DrawerPage.BOOKING_DETAILS }, StateType.DrawerPage);

    let variant_id: number;
    let cartUpdate = false;
    let greenFeeUpdate = false;
    const variants = [];

    if (type === "green_fee") {
      if (quickAddGreenFeeOrPowerCartId) {
        variant_id = quickAddGreenFeeOrPowerCartId;
      } else {
        variant_id = slot.booking_participant.green_fee_variant_id;
      }
    }

    if (type === "power_cart_fee") {
      if (quickAddGreenFeeOrPowerCartId) {
        variant_id = quickAddGreenFeeOrPowerCartId;
      } else {
        variant_id = slot.booking_participant.power_cart_variant_id;
      }
    }

    // If there is no cart created or if the user is on the receipt page, create a new cart and add the item to the order
    if (!checkInState.cart?.id || drawerPage.currentPage === DrawerPage.RECEIPT_ORDER) {
      try {
        const postCartRes = await PostCart(
          { customer_id: !slot.booking_participant?.guest ? slot.booking_participant.customer_id : null },
          true,
        );

        // save cart to state
        updateState<ICheckInState>({ cart: postCartRes.data }, StateType.CheckInState);

        //If both fees added, add both
        if (bothFeesAdded) {
          // add item to cart

          const powerCartFeePaid = slot?.booking_participant?.power_cart_paid;
          const greenFeePaid = slot?.booking_participant?.green_fee_paid;

          if (slot.booking_participant.green_fee_variant_id !== null && !greenFeePaid) {
            const variant = {
              variant_id: slot.booking_participant.green_fee_variant_id,
              quantity: 1,
            };

            variants.push(variant);
            greenFeeUpdate = true;
          }

          if (slot.booking_participant.power_cart_variant_id !== null && !powerCartFeePaid) {
            const variant = {
              variant_id: slot.booking_participant.power_cart_variant_id,
              quantity: 1,
            };

            variants.push(variant);
            cartUpdate = true;
          }

          const postLineItemToCartRes = await PostLineItemToCart(
            {
              cart_id: postCartRes.data.id,
              variants: variants,
            },
            true,
          );

          if (postLineItemToCartRes.status === StatusCode.OK) {
            // save to state
            updateState<ICheckInState>(
              { cart: postLineItemToCartRes.data, order: undefined, paymentOption: null },
              StateType.CheckInState,
            );

            // item tracker, keeps track of which items have been added to cart
            const newItem: IAddedItem = {
              booking_participant_id: slot.booking_participant.id,
              green_fee: false,
              power_cart_fee: false,
              both: false,
            };

            if (greenFeeUpdate) {
              newItem.green_fee = true;
            }

            if (cartUpdate) {
              newItem.power_cart_fee = true;
            }

            updateState<IAddItemState>({ addedItems: [newItem] }, StateType.AddItemState);
          }

          setAddingFee(false);
        } else {
          // add item to cart
          const postLineItemToCartRes = await PostLineItemToCart(
            {
              cart_id: postCartRes.data.id,
              variants: [{ variant_id: variant_id, quantity: 1 }],
            },
            true,
          );

          if (postLineItemToCartRes.status === StatusCode.OK) {
            // save to state
            updateState<ICheckInState>(
              { cart: postLineItemToCartRes.data, order: undefined, paymentOption: null },
              StateType.CheckInState,
            );

            // item tracker, keeps track of which items have been added to cart
            const newItem: IAddedItem = {
              booking_participant_id: slot.booking_participant.id,
              green_fee: false,
              power_cart_fee: false,
              both: false,
            };

            newItem[type] = true;

            updateState<IAddItemState>({ addedItems: [newItem] }, StateType.AddItemState);
          }

          setAddingFee(false);
        }
      } catch (err) {
        console.log("postCartRes err", err);
      }
    } else {
      if (!fee_added) {
        if (bothFeesAdded) {
          const powerCartFeeAdded = addItemState.addedItems?.some(item => {
            if (item.booking_participant_id === slot.booking_participant.id) {
              return item.power_cart_fee;
            }
          });
          const greenFeeAdded = addItemState.addedItems?.some(item => {
            if (item.booking_participant_id === slot.booking_participant.id) {
              return item.green_fee;
            }
          });
          const powerCartFeePaid = slot?.booking_participant?.power_cart_paid;
          const greenFeePaid = slot?.booking_participant?.green_fee_paid;

          if (powerCartFeeAdded && greenFeeAdded) {
            dispatch(showError("Fees already added to cart"));
            setAddingFee(false);
            return;
          }

          if (slot.booking_participant.green_fee_variant_id !== null && !greenFeeAdded && !greenFeePaid) {
            const variant = {
              variant_id: slot.booking_participant.green_fee_variant_id,
              quantity: 1,
            };

            variants.push(variant);
            greenFeeUpdate = true;
          }

          if (slot.booking_participant.power_cart_variant_id !== null && !powerCartFeeAdded && !powerCartFeePaid) {
            const variant = {
              variant_id: slot.booking_participant.power_cart_variant_id,
              quantity: 1,
            };

            variants.push(variant);
            cartUpdate = true;
          }

          if (variants?.length > 0) {
            const postLineItemToCartRes = await PostLineItemToCart(
              {
                cart_id: checkInState.cart.id,
                variants: variants,
              },
              true,
            );

            if (postLineItemToCartRes.status !== StatusCode.OK) {
              dispatch(showError("Error adding items to cart"));
              return;
            }

            // save to state
            updateState<ICheckInState>({ cart: postLineItemToCartRes.data }, StateType.CheckInState);

            // item tracker, keeps track of which items have been added to cart
            // if array does not contain an element with the key of slot.booking_participant.id create new
            const hasKey = addItemState.addedItems.find(
              item => item.booking_participant_id === slot.booking_participant.id,
            );

            if (!hasKey) {
              const newAddedItem: IAddedItem = {
                booking_participant_id: slot.booking_participant.id,
                green_fee: false,
                power_cart_fee: false,
                both: false,
              };

              if (greenFeeUpdate) {
                newAddedItem.green_fee = true;
              }

              if (cartUpdate) {
                newAddedItem.power_cart_fee = true;
              }

              updateState<IAddItemState>(
                { addedItems: [...addItemState.addedItems, newAddedItem] },
                StateType.AddItemState,
              );
            } else {
              const updatedItem = addItemState.addedItems.map(item => {
                if (item.booking_participant_id === slot.booking_participant.id) {
                  return {
                    ...item,
                    green_fee: greenFeeUpdate === true ? greenFeeUpdate : item?.green_fee,
                    power_cart_fee: cartUpdate === true ? cartUpdate : item?.power_cart_fee,
                  };
                } else {
                  return item;
                }
              });
              // item tracker, keeps track of which items have been added to cart
              updateState<IAddItemState>({ addedItems: updatedItem }, StateType.AddItemState);
            }
          }

          setAddingFee(false);
        } else {
          // add item to cart
          const postLineItemToCartRes = await PostLineItemToCart(
            {
              cart_id: checkInState.cart.id,
              variants: [{ variant_id: variant_id, quantity: 1 }],
            },
            true,
          );

          // save to state
          updateState<ICheckInState>({ cart: postLineItemToCartRes.data }, StateType.CheckInState);

          // item tracker, keeps track of which items have been added to cart
          // if array does not contain an element with the key of slot.booking_participant.id create new
          const hasKey = addItemState.addedItems.find(
            item => item.booking_participant_id === slot.booking_participant.id,
          );

          if (!hasKey) {
            const newAddedItem: IAddedItem = {
              booking_participant_id: slot.booking_participant.id,
              green_fee: false,
              power_cart_fee: false,
              both: false,
            };

            newAddedItem[type] = true;

            updateState<IAddItemState>(
              { addedItems: [...addItemState.addedItems, newAddedItem] },
              StateType.AddItemState,
            );
          } else {
            const updatedItem = addItemState.addedItems.map(item => {
              if (item.booking_participant_id === slot.booking_participant.id) {
                return {
                  ...item,
                  [type]: true,
                };
              } else {
                return item;
              }
            });
            // item tracker, keeps track of which items have been added to cart
            updateState<IAddItemState>({ addedItems: updatedItem }, StateType.AddItemState);
          }

          setAddingFee(false);
        }
      } else if (fee_added) {
        let id: number;
        let quant: number;

        checkInState.cart.line_items.forEach(item => {
          if (type === "green_fee") {
            if (item.variant_id === slot.booking_participant.green_fee_variant_id) {
              id = item.id;
              quant = item.quantity;
            }
          }
          if (type === "power_cart_fee") {
            if (item.variant_id === slot.booking_participant.power_cart_variant_id) {
              id = item.id;
              quant = item.quantity;
            }
          }
        });

        const res = await PutLineItemToCart({ id, quantity: quant - 1 }, true);

        if (res.status === StatusCode.OK) {
          // refresh cart
          updateState<ICheckInState>({ cart: res.data }, StateType.CheckInState);
        }

        // // item tracker, keeps track of which items have been added to cart
        const newRemovedItem = addItemState.addedItems.map(item => {
          if (item.booking_participant_id === slot.booking_participant.id) {
            return {
              ...item,
              [type]: false,
            };
          } else {
            return item;
          }
        });

        updateState<IAddItemState>({ addedItems: newRemovedItem }, StateType.AddItemState);

        setAddingFee(false);
      }
    }
  };

  const handleInitiateAddFee = async (
    event: React.MouseEvent<Element, MouseEvent>,
    slot: ISlot,
    product_type: "Green Fee" | "Power Cart",
    green_fee_added?: boolean,
    power_cart_fee_added?: boolean,
    orderStarted?: boolean,
  ) => {
    event.stopPropagation();

    if (
      (green_fee_added && power_cart_fee_added) ||
      (product_type === "Green Fee" && (slot.booking_participant.green_fee_paid || green_fee_added)) ||
      (product_type === "Power Cart" && (slot.booking_participant.power_cart_paid || power_cart_fee_added)) ||
      orderStarted
    ) {
      return;
    }

    let active_type: string;
    let active_store: any;
    let active_store_string: string;

    if (product_type === "Green Fee") {
      active_type = "greenFeeActive";
      active_store = teeSheetProductStore.greenFeeItems;
      active_store_string = "greenFeeItems";
    }

    if (product_type === "Power Cart") {
      active_type = "powerCartFeeActive";
      active_store = teeSheetProductStore.powerCartItems;
      active_store_string = "powerCartItems";
    }

    // get product
    const items = active_store === null ? await GetProduct({ type: product_type, extended: true }, true) : active_store;

    const participant_id = slot.tee_time_booking_participant_id;

    const tee_time_id = slot.tee_time_id;

    if (items.status === StatusCode.OK) {
      updateState<IAddItemState>(
        { itemSearchResult: items.data, participant_id, tee_time_id, [active_type]: true, selectedSlot: slot },
        StateType.AddItemState,
      );
      dispatch(updateTeeSheetProducts(active_store_string, items));
    }
  };

  const cancelAddItem = () => {
    updateState<IAddItemState>(
      {
        itemSearchQuery: "",
        itemSearchResult: [],
        addItemActive: false,
        greenFeeActive: false,
        powerCartFeeActive: false,
        participant_id: null,
        tee_time_id: null,
        productState: {
          selectedProduct: undefined,
          variants: [],
          variantSheetOpen: false,
        },
      },
      StateType.AddItemState,
    );
  };

  async function handleAddQuickItem(variant_id: number, product_type: "Green Fee" | "Power Cart") {
    const id = addItemState.participant_id;

    let green_fee_variant_id;
    let power_cart_variant_id;

    if (product_type === "Green Fee") {
      green_fee_variant_id = variant_id;
    }

    if (product_type === "Power Cart") {
      power_cart_variant_id = variant_id;
    }

    const putBookingParticipantRes = await PutBookingParticipant(
      { id, green_fee_variant_id, power_cart_variant_id },
      true,
    );

    if (putBookingParticipantRes.status === StatusCode.OK) {
      //If teetime is today, add Fee to cart
      if (moment(teeSheetStore?.selectedTeeTime?.date).isSame(moment(), "date")) {
        if (product_type === "Power Cart") {
          void handleAddFee(
            null,
            "power_cart_fee",
            addItemState.selectedSlot,
            addingFee,
            teeSheetStore?.selectedTeeTime?.id,
            power_cart_variant_id,
          );
        } else if (product_type === "Green Fee") {
          void handleAddFee(
            null,
            "green_fee",
            addItemState.selectedSlot,
            addingFee,
            teeSheetStore?.selectedTeeTime?.id,
            green_fee_variant_id,
          );
        }
      }

      // force refresh, updates member / walking
      void dispatch(selectTeeTime(addItemState.tee_time_id, true));

      // close and clear
      cancelAddItem();

      // display success message
      dispatch(showSuccess(t("secure.facility.tee_sheet.tee_sheet_booking_details.144")));
    }
  }

  const handleDeleteOrder = () => {
    ReactDOM.unstable_batchedUpdates(() => {
      updateState<IAddItemState>({ addedItems: [] }, StateType.AddItemState);
      updateState<ICheckInState>({ order: undefined, cart: null }, StateType.CheckInState);
      updateState<IDrawerPage>({ currentPage: DrawerPage.NEW_ORDER }, StateType.DrawerPage);
      updateState<IMainState>({ gift_cards: null, selectedLeagues: null, clearCartActive: false }, StateType.MainState);
    });
  };

  const handleContinueToPaymentMethod = () => {
    updateState<IDrawerPage>(
      {
        currentPage: DrawerPage.PAYMENT_METHOD,
      },
      StateType.DrawerPage,
    );
  };

  const handlePaymentOptionChange = (paymentOption: Record<string, any>) => {
    if (paymentOption !== undefined) {
      updateState<ICheckInState>(
        {
          paymentOption: paymentOption,
        },
        StateType.CheckInState,
      );
    }
  };

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;

    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (async () => {
          try {
            if (addItemState.itemSearchQuery === "") {
              return;
            } else {
              const items = await GetProduct({ search: addItemState.itemSearchQuery, extended: true }, true);
              if (items.status === StatusCode.OK) {
                if (mounted) {
                  updateState<IAddItemState>({ itemSearchResult: items.data }, StateType.AddItemState);
                }
              }
            }
          } catch (error) {
            console.log("err", error);
          }
          return;
        })();
      }, 750);
    };

    search();

    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [addItemState.itemSearchQuery]);

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (async () => {
          try {
            if (
              (rainCheckState.completedHoles === "" && rainCheckState.amount === null) ||
              rainCheckState.orderId === null
            ) {
              if (mounted) {
                updateState<IRainCheckState>({ calculate: null }, StateType.RainCheckState);
              }
              return;
            } else {
              const calcRes = await PutCalculateRainCheck(
                {
                  order_id: rainCheckState.orderId,
                  booking_participant_id: rainCheckState.selectedBookingParticipantId,
                  issue_on_green_fee: rainCheckState.issue_on_green_fee,
                  issue_on_power_cart: rainCheckState.issue_on_power_cart,
                  amount: rainCheckState.amount,
                  percent: rainCheckState.percent,
                },
                true,
              );
              if (calcRes.status !== StatusCode.OK) {
                return;
              }
              if (mounted) {
                updateState<IRainCheckState>({ calculate: calcRes.data, okDisabled: false }, StateType.RainCheckState);
              }
            }
          } catch (error) {
            console.log("err", error);
          }
          return;
        })();
      }, 1000);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [rainCheckState.percent, rainCheckState.amount]);

  const handleRainCheckHolesInput = (event: any) => {
    const { id } = event.target;
    let { value } = event.target;
    let percentValue: number;
    if (value !== "") {
      value = Math.min(rainCheckState.holes, parseInt(value));
      percentValue = 1 - value / rainCheckState.holes;
      updateState<IRainCheckState>({ [id]: value.toString(), percent: percentValue }, StateType.RainCheckState);
      return;
    }
    updateState<IRainCheckState>({ [id]: value, percent: null }, StateType.RainCheckState);
  };

  const handleRainCheckAmountInput = (event: any) => {
    const { id } = event.target;
    let { value } = event.target;
    value = value === "" ? null : parseFloat(value);
    updateState<IRainCheckState>({ [id]: value }, StateType.RainCheckState);
  };

  const handleRainCheckbox = (position: number, slot: ISlot) => {
    const updatedCheckboxes = new Array(4).fill(false);
    updatedCheckboxes[position] = !updatedCheckboxes[position];

    updateState<IRainCheckState>(
      {
        checkBoxes: updatedCheckboxes,
        selectedBookingParticipantId: slot.booking_participant.id,
        selectedBookingParticipant: slot.booking_participant,
        okDisabled: false,
        holes: slot.booking.holes,
        orderId: slot.booking_participant.order_id,
        issue_on_green_fee:
          slot.booking_participant.green_fee_variant_id !== null && slot.booking_participant.green_fee_paid
            ? true
            : false,
        issue_on_power_cart:
          slot.booking_participant.power_cart_variant_id !== null && slot.booking_participant.power_cart_paid
            ? true
            : false,
      },
      StateType.RainCheckState,
    );
  };

  const issueRainCheck = async () => {
    //Continue button

    if (!rainCheckState.continueBtn) {
      updateState<IRainCheckState>(
        {
          continueBtn: !rainCheckState.continueBtn,
          amount: null,
          completedHoles: "",
          percent: null,
          calculate: null,
          okDisabled: true,
        },
        StateType.RainCheckState,
      );
    } else {
      const rainCheckRes = await PostIssueRainCheck(
        {
          order_id: rainCheckState.orderId,
          booking_participant_id: rainCheckState.selectedBookingParticipantId,
          issue_on_green_fee: rainCheckState.issue_on_green_fee,
          issue_on_power_cart: rainCheckState.issue_on_power_cart,
          amount: rainCheckState.calculate,
        },
        true,
      );

      if (rainCheckRes.status !== StatusCode.OK) {
        dispatch(showError(rainCheckRes.data));
        return;
      }
      dispatch(showSuccess("Rain Check Issued"));

      updateState<IRainCheckState>(
        {
          rainCheck: rainCheckRes.data,
          rainCheckCompleteActive: true,
          rainCheckActive: false,
          checkBoxes: new Array(4).fill(false),
          continueBtn: false,
          selectedBookingParticipantId: null,
        },
        StateType.RainCheckState,
      );
    }
  };

  const cancelRainCheck = () => {
    if (rainCheckState.continueBtn) {
      console.log("back to first page of modal");
      updateState<IRainCheckState>(
        {
          continueBtn: !rainCheckState.continueBtn,
          amount: null,
          completedHoles: "",
          percent: null,
          calculate: null,
          okDisabled: false,
        },
        StateType.RainCheckState,
      );
    } else {
      updateState<IRainCheckState>(
        {
          rainCheckActive: false,
          selectedBookingParticipantId: null,
          checkBoxes: new Array(4).fill(false),
          continueBtn: false,
          holes: null,
          calculate: null,
          percent: null,
          amount: null,
          completedHoles: "",
        },
        StateType.RainCheckState,
      );
    }
  };

  const handleRedeemRainCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    updateState<IRainCheckState>({ [id]: value }, StateType.RainCheckState);
  };

  const closeRedeemRainCheck = () => {
    updateState<IRainCheckState>({ redeemRainCheckActive: false, redeemRainCheckCode: "" }, StateType.RainCheckState);
  };

  const handleRedeemRainCheck = async () => {
    if (checkInState.cart?.id) {
      const redeemRes = await PostRedeemRainCheck(
        { cart_id: checkInState.cart.id, rain_check_code: rainCheckState.redeemRainCheckCode },
        true,
      );
      if (redeemRes.status !== StatusCode.OK) {
        dispatch(showError(redeemRes.data));
        return;
      }
      dispatch(showSuccess(t("secure.facility.tee_sheet.tee_sheet_booking_details.181")));
      updateState<ICheckInState>({ cart: redeemRes.data }, StateType.CheckInState);
      closeRedeemRainCheck();
    } else {
      return;
    }
  };

  const handleNoShowCheckbox = (selectedId: number) => {
    const foundIndex = noShow.bookingParticipantIds.indexOf(selectedId);

    if (foundIndex === -1) {
      updateState<INoShowState>(
        {
          bookingParticipantIds: [...noShow.bookingParticipantIds, selectedId],
          quantity: noShow.quantity + 1,
        },
        StateType.NoShowState,
      );
    } else {
      updateState<INoShowState>(
        {
          bookingParticipantIds: noShow.bookingParticipantIds.filter(val => val !== selectedId),
          quantity: noShow.quantity - 1,
        },
        StateType.NoShowState,
      );
    }
  };

  const handleNoShowDropDownChange = (value: any, extraValues: any) => {
    updateState<INoShowState>(
      { greenFeeProductID: value, greenFeeProductTitle: extraValues.title, greenFeeProductPrice: extraValues.price },
      StateType.NoShowState,
    );
  };

  //Get customer's Stripe payment method id
  const handleCustomerPaymentMethod = async (slot: ISlot) => {
    if (slot?.booking?.customer_payment_method_id === null || slot?.booking?.customer_id === null) {
      updateState<INoShowState>({ paymentMethodID: null }, StateType.NoShowState);
      return false;
    }
    const paymentMethodRes = await GetCustomerPaymentMethod(
      { id: slot?.booking?.customer_payment_method_id, customer_id: slot?.booking?.customer_id },
      true,
    );

    if (paymentMethodRes.data.length > 0) {
      updateState<INoShowState>(
        { paymentMethodID: paymentMethodRes.data[0].stripe_payment_method_id },
        StateType.NoShowState,
      );
    }
    return true;
  };

  // Confirm Stripe payment intent
  const confirmCardPaymentNoShow = async (
    clientSecret: string,
    paymentMethodID: string,
    trans_id: number,
    orderId: number,
  ) => {
    if (!stripe) {
      return;
    }

    dispatch(enqueue());
    const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: paymentMethodID,
    });
    dispatch(dequeue());

    if (stripeError) {
      dispatch(showError(stripeError.message));
      return;
    }

    //Capture the payment
    const captureRes = await PutCapture({ payment_intent_id: paymentIntent.id, transaction_id: trans_id }, true);

    if (captureRes.status !== StatusCode.OK) {
      dispatch(showError(captureRes.message));
      return;
    }

    for (const bookingParticipantId of noShow.bookingParticipantIds) {
      const putCustomerRes = await PutBookingParticipant(
        { id: bookingParticipantId, no_show_paid: true, order_id: orderId },
        true,
      );
      if (putCustomerRes.status !== StatusCode.OK) {
        dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.150")));
      }
    }

    void dispatch(selectTeeTime(parseInt(teetimeId), true));
    dispatch(showSuccess(t("secure.facility.tee_sheet.tee_sheet_booking_details.170")));
    updateState<INoShowState>(
      { noShowActive: false, bookingParticipantIds: [], quantity: 0, paymentMethodID: null },
      StateType.NoShowState,
    );
  };

  //Charging the customer for no show is handled here
  const handleNoShowCharge = () => {
    if (noShow.quantity === 0 || noShow.greenFeeProductID === null || noShow.customerID === null) {
      return;
    }

    void (async () => {
      try {
        //Create order
        const postOrderRes = await PostOrder({ customer_id: noShow.customerID }, true);
        if (postOrderRes.status === StatusCode.OK) {
          try {
            //Create line item order
            const postLineItemOrderRes = await PostLineItemsToOrder(
              {
                order_id: postOrderRes.data.id,
                variant_id: noShow.greenFeeProductID,
                quantity: noShow.quantity,
              },
              true,
            );
            if (postLineItemOrderRes.status === StatusCode.OK) {
              try {
                //Create transaction
                const postTransactionRes = await PostTransaction(
                  {
                    kind: "sale",
                    amount: postLineItemOrderRes.data.total_price,
                    order_id: postOrderRes.data.id,
                    payment_option_id: facilityStore.paymentOptions?.find(
                      paymentOption => paymentOption.payment_method === "manual_card",
                    )?.id,
                  },
                  true,
                );
                if (postTransactionRes.status === StatusCode.OK) {
                  //Confirm the payment intent after succesfully creating a transaction
                  void confirmCardPaymentNoShow(
                    postTransactionRes.data.client_secret,
                    noShow.paymentMethodID,
                    postTransactionRes.data.id,
                    postOrderRes.data.id,
                  );
                } else {
                  dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.007")));
                  return;
                }
              } catch (e) {
                dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.007")));
                console.log(e);
              }
            }
          } catch (e) {
            dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.008")));
            console.log(e);
          }
        }
      } catch (e) {
        dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.009")));
        console.log(e);
      }
    })();
  };

  const handleCancelBookingParticipant = async (slot: ISlot) => {
    try {
      const putCancelRes = await PutCancelParticipant({ id: slot.booking_participant.id }, true);

      if (putCancelRes.status === StatusCode.OK) {
        void dispatch(selectTeeTime(parseInt(teetimeId), true));
        dispatch(showSuccess(putCancelRes.message));
      }

      if (putCancelRes.status !== StatusCode.OK) {
        dispatch(showError(putCancelRes.data.message));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleNoShowBookingParticipant = async (slot: ISlot, type: "charge" | "defer" | "weather") => {
    const id = slot.tee_time_booking_participant_id;

    try {
      const putBookingParticipantRes = await PutBookingParticipantNoShow(
        {
          id,
          no_show_type: type,
        },
        true,
      );

      if (putBookingParticipantRes.status === StatusCode.OK) {
        void dispatch(selectTeeTime(parseInt(teetimeId), true)); //refreshes the page
        dispatch(showSuccess(t("secure.facility.tee_sheet.tee_sheet_booking_details.010") + type.replace(/_/g, " ")));
      } else {
        dispatch(
          showError(
            typeof putBookingParticipantRes?.data === "string"
              ? putBookingParticipantRes?.data
              : t("secure.facility.tee_sheet.tee_sheet_booking_details.139") + type.replace(/_/g, " "),
          ),
        );
      }
    } catch (e) {
      console.log("handleNoShow ERR:", e);
    }
  };

  async function handleManualCheckIn() {
    const checkInRes = await PutBookingParticipant(
      {
        id: checkInState.manualCheckInSlot.booking_participant.id,
        check_in_status: "checked",
      },
      true,
    );

    if (checkInRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.173")));
    }

    updateState<ICheckInState>(
      {
        manualCheckInVisible: false,
        manualCheckInSlot: null,
      },
      StateType.CheckInState,
    );

    void refreshTeeTime();
  }

  async function handleChargeNoShowModal(slot: ISlot) {
    //Get customer's payment method
    const paymentMethodOnBooking = await handleCustomerPaymentMethod(slot);
    if (!paymentMethodOnBooking) {
      updateState<INoShowState>(
        {
          noPaymentMethodModal: true,
        },
        StateType.NoShowState,
      );
      return;
    }
    if (noShow.greenFeeProducts?.length === 0) {
      void (await loadGreenFeeProducts());
    }

    updateState<INoShowState>(
      {
        noShowActive: true,
        teeTimeBookingId: slot.booking_participant.tee_time_booking_id,
        customerID: slot.booking.customer_id,
      },
      StateType.NoShowState,
    );
    void dispatch(loadPaymentOptions());
  }

  const handleInitiateRedeemTicket = async (slot: ISlot) => {
    const participant_id = slot.tee_time_booking_participant_id;

    const ticketRes = await GetTicketStub(
      {
        customer_id: slot.booking_participant.customer_id,
        status: "valid",
        extended: true,
      },
      true,
    );
    ReactDOM.unstable_batchedUpdates(() => {
      updateState<IMainState>({ selectedBooking: slot?.booking }, StateType.MainState);
      updateState<ICheckInState>(
        { redeemTicketActive: true, availableTickets: ticketRes.data },
        StateType.CheckInState,
      );
      updateState<IAddItemState>({ participant_id }, StateType.AddItemState);
    });
  };

  const handleTicketRedeem = async (code: string) => {
    const getTicketValidateRes = await GetTicketValidate({ code }, true);

    if (getTicketValidateRes.status === StatusCode.OK) {
      const getTicketValidateData = getTicketValidateRes.data;
      updateState<ICheckInState>(
        {
          getTicketValidateData,
          ticketValidSuccess: true,
        },
        StateType.CheckInState,
      );
    }

    if (getTicketValidateRes.status === StatusCode.BAD_REQUEST) {
      updateState<ICheckInState>({ ticketValidWarning: true }, StateType.CheckInState);
    }
    void handleCloseSearchTicket();
  };

  const handleSearchTicket = async () => {
    const codes = checkInState.selectedTickets
      ?.filter(ticket => ticket.status === "valid")
      ?.map(ticket => {
        return ticket.code;
      });

    if (codes.length < 1) {
      dispatch(showError("There are no valid tickets to redeem"));
      return;
    }

    const redeemTicketsRes = await PutTicketStubRedeemMultiple({ codes: codes }, true);

    if (redeemTicketsRes.status !== StatusCode.OK) {
      dispatch(showError("Error redeeming ticket stubs"));
      return;
    }

    const selectedBookingParticipantIndex = teeSheetStore?.selectedTeeTime?.slots?.findIndex(
      slot => slot?.booking_participant?.id == addItemState?.participant_id,
    );

    const redeemedStubs = redeemTicketsRes.data;

    const bookingSlots = [...teeSheetStore?.selectedTeeTime?.slots];
    //Order the slots so that the next participant after the current one has the ticket applied, then continue from the first participant in the booking
    const slotsOrderUpdate = [
      ...bookingSlots.slice(selectedBookingParticipantIndex, bookingSlots?.length),
      ...bookingSlots.slice(0, selectedBookingParticipantIndex),
    ];
    //Filter out the participants that have already paid and remove the slots that are not in the current booking
    const filteredSlots = slotsOrderUpdate?.filter(
      slot =>
        slot.status === "booked" &&
        slot?.booking_participant?.order_financial_status !== "paid" &&
        slot?.booking?.id === state.selectedBooking?.id,
    );

    //Set the participant ids in an array
    const participantIds = filteredSlots?.map(slot => slot?.booking_participant?.id);

    for (let i = 0; i < redeemedStubs.length; i++) {
      const greenFeeProduct = redeemedStubs[i].redemption_product?.green_fee || null;
      const powerCartProduct = redeemedStubs[i].redemption_product?.power_cart || null;

      const greenFeeQuantity = greenFeeProduct?.pivot?.quantity;
      const powerCartQuantity = powerCartProduct?.pivot?.quantity;

      if (
        ((greenFeeProduct && greenFeeQuantity >= 1) || (powerCartProduct && powerCartQuantity >= 1)) &&
        participantIds?.length > 0
      ) {
        //Get the max quantity between the green fee & power cart
        const higherQuantity =
          greenFeeQuantity && powerCartQuantity
            ? Math.max(greenFeeQuantity, powerCartQuantity)
            : greenFeeQuantity
            ? greenFeeQuantity
            : powerCartQuantity;
        let currentGreenFeeAmount = greenFeeQuantity;
        let currentPowerCartAmount = powerCartQuantity;
        for (const i of [...Array(higherQuantity).keys()]) {
          const putBookingParticipantRes = await PutBookingParticipant(
            {
              id: participantIds[0],
              green_fee_variant_id:
                greenFeeProduct && currentGreenFeeAmount >= 1 ? greenFeeProduct?.pivot?.variant_id : undefined,
              power_cart_variant_id:
                powerCartProduct && currentPowerCartAmount >= 1 ? powerCartProduct?.pivot?.variant_id : undefined,
            },
            true,
          );
          if (putBookingParticipantRes.status !== StatusCode.OK) {
            dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.182")));
            return;
          }
          //Remove the first booking participant in the array to move onto the next participant
          participantIds.shift();
          //Decrement the green fee / power cart quantity
          currentGreenFeeAmount -= 1;
          currentPowerCartAmount -= 1;
          //If the max quantity between the green fee / power cart is reached or there are no participants left to apply the ticket,
          //break out of the loop and redeem the ticket and close the modal
          if (i === higherQuantity - 1 || participantIds?.length === 0) {
            break;
          }
        }
      }
    }

    void closeRedeemTicketModal();

    handleCloseSearchTicket();

    updateState<ICheckInState>(
      {
        ticketRedeemSuccess: true,
      },
      StateType.CheckInState,
    );

    // Reload the tee times
    void dispatch(selectTeeTime(parseInt(teetimeId), true));
  };

  function closeRedeemTicketModal() {
    ReactDOM.unstable_batchedUpdates(() => {
      updateState<ICheckInState>(
        {
          ticketValidSuccess: false,
          redeem_ticket_code: "",
          getTicketValidateData: {},
          ticketRedeemSuccess: false,
        },
        StateType.CheckInState,
      );

      updateState<IAddItemState>(
        {
          participant_id: null,
        },
        StateType.AddItemState,
      );
    });
  }

  const handleTicketValidSuccess = async () => {
    const selectedBookingParticipantIndex = teeSheetStore?.selectedTeeTime?.slots?.findIndex(
      slot => slot?.booking_participant?.id == addItemState?.participant_id,
    );
    if (selectedBookingParticipantIndex === -1) {
      console.log("Cannot find selected booking participant slot");
      return;
    }

    const bookingSlots = [...teeSheetStore?.selectedTeeTime?.slots];
    //Order the slots so that the next participant after the current one has the ticket applied, then continue from the first participant in the booking
    const slotsOrderUpdate = [
      ...bookingSlots.slice(selectedBookingParticipantIndex, bookingSlots?.length),
      ...bookingSlots.slice(0, selectedBookingParticipantIndex),
    ];
    //Filter out the participants that have already paid and remove the slots that are not in the current booking
    const filteredSlots = slotsOrderUpdate?.filter(
      slot =>
        slot.status === "booked" &&
        slot?.booking_participant?.order_financial_status !== "paid" &&
        slot?.booking?.id === state.selectedBooking?.id,
    );

    //Set the participant ids in an array
    const participantIds = filteredSlots?.map(slot => slot?.booking_participant?.id);

    const greenFeeProduct = checkInState.getTicketValidateData.redemption_products?.green_fee || null;
    const powerCartProduct = checkInState.getTicketValidateData.redemption_products?.power_cart || null;

    const greenFeeQuantity = greenFeeProduct?.pivot?.quantity;
    const powerCartQuantity = powerCartProduct?.pivot?.quantity;

    if (
      ((greenFeeProduct && greenFeeQuantity >= 1) || (powerCartProduct && powerCartQuantity >= 1)) &&
      participantIds?.length > 0
    ) {
      //Get the max quantity between the green fee & power cart
      const higherQuantity =
        greenFeeQuantity && powerCartQuantity
          ? Math.max(greenFeeQuantity, powerCartQuantity)
          : greenFeeQuantity
          ? greenFeeQuantity
          : powerCartQuantity;
      let currentGreenFeeAmount = greenFeeQuantity;
      let currentPowerCartAmount = powerCartQuantity;
      for (const i of [...Array(higherQuantity).keys()]) {
        const putBookingParticipantRes = await PutBookingParticipant(
          {
            id: participantIds[0],
            green_fee_variant_id:
              greenFeeProduct && currentGreenFeeAmount >= 1 ? greenFeeProduct?.pivot?.variant_id : undefined,
            power_cart_variant_id:
              powerCartProduct && currentPowerCartAmount >= 1 ? powerCartProduct?.pivot?.variant_id : undefined,
          },
          true,
        );
        if (putBookingParticipantRes.status !== StatusCode.OK) {
          dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.182")));
          return;
        }
        //Remove the first booking participant in the array to move onto the next participant
        participantIds.shift();
        //Decrement the green fee / power cart quantity
        currentGreenFeeAmount -= 1;
        currentPowerCartAmount -= 1;
        //If the max quantity between the green fee / power cart is reached or there are no participants left to apply the ticket,
        //break out of the loop and redeem the ticket and close the modal
        if (i === higherQuantity - 1 || participantIds?.length === 0) {
          break;
        }
      }
    }

    //Redeem ticket even if there is no green fee / power cart returned on the ticket
    //Some facilities like to redeem the ticket then manually apply the prepaid items
    const putRedeemRes = await PutRedeem(checkInState.getTicketValidateData.stub.code, true);
    if (putRedeemRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.162")));
    } else {
      dispatch(showSuccess(t("secure.facility.tee_sheet.tee_sheet_booking_details.161")));
    }

    void closeRedeemTicketModal();

    handleCloseSearchTicket();

    updateState<ICheckInState>(
      {
        ticketRedeemSuccess: true,
      },
      StateType.CheckInState,
    );

    // Reload the tee times
    void dispatch(selectTeeTime(parseInt(teetimeId), true));
  };

  const handleTicketValidWarning = () => {
    // handle warning
    ReactDOM.unstable_batchedUpdates(() => {
      updateState<IMainState>({ selectedBooking: null }, StateType.MainState);
      updateState<ICheckInState>({ ticketValidWarning: false }, StateType.CheckInState);
    });
  };

  const handleCloseSearchTicket = () => {
    updateState<ICheckInState>(
      {
        redeemTicketActive: false,
        availableTickets: [],
        redeem_ticket_code: "",
        redeemTicketSelected: 0,
        selectedTickets: [],
      },
      StateType.CheckInState,
    );

    setTicketState(prevState => ({ ...prevState, tickets: [], ticketString: "" }));
  };

  function handleChargeNoShowModalClose() {
    updateState<INoShowState>(
      {
        noShowActive: false,
        bookingParticipantIds: [],
        paymentMethodID: null,
        quantity: 0,
      },
      StateType.NoShowState,
    );
  }

  async function loadGreenFeeProducts() {
    const items = await GetProduct({ type: "No Show Green Fee", extended: true }, true);
    if (items.status !== StatusCode.OK) {
      dispatch(showError(items.message));
      return;
    }
    updateState<INoShowState>({ greenFeeProducts: items.data }, StateType.NoShowState);
  }

  function handleChangePlayerSelection(id: number, customer: ICustomer) {
    setChangePlayerState(prevState => ({ ...prevState, selectedCustomer: customer }));
  }

  function handleRemoveSelectedGolfer() {
    setChangePlayerState(prevState => ({
      ...prevState,
      selectedCustomer: null,
      playerSearchResult: [],
      customerQuery: "",
    }));
  }

  function handleShowChangePlayer(bookingParticipant: IBookingParticipant) {
    setChangePlayerState(prevState => ({
      ...prevState,
      showChangePlayer: true,
      customerQuery: "",
      playerSearchResult: [],
      currentCustomer: bookingParticipant,
    }));
  }

  function changePlayerHandleCustomerSearch(query: string) {
    setChangePlayerState(prevState => ({ ...prevState, customerQuery: query }));
  }

  async function changeSelectedPlayer() {
    const bookingParticipantRes = await PutBookingParticipant(
      { id: changePlayerState.currentCustomer.id, customer_id: changePlayerState.selectedCustomer.id },
      true,
    );
    if (bookingParticipantRes.status !== StatusCode.OK) {
      dispatch(showError(bookingParticipantRes.message));
      return;
    }
    dispatch(showSuccess(bookingParticipantRes.message));
    setChangePlayerState(prevState => ({
      ...prevState,
      currentCustomer: null,
      showChangePlayer: false,
      playerSearchResult: [],
      customerQuery: "",
      selectedCustomer: null,
    }));
    void dispatch(selectTeeTime(parseInt(teetimeId), true));
  }

  function closeChangePlayer() {
    closeCreateNewCustomer();
    setChangePlayerState(prevState => ({
      ...prevState,
      showNewPlayer: false,
      currentCustomer: null,
      showChangePlayer: false,
      customerQuery: "",
      playerSearchResult: [],
      selectedCustomer: null,
    }));
  }

  function closeCreateNewCustomer() {
    setChangePlayerState(prevState => ({
      ...prevState,
      showNewPlayer: false,
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
    }));
  }

  async function createNewCustomer(customerInfo: ICustomerInfoState) {
    const inputError =
      customerInfo.firstName === "" ||
      customerInfo.lastName === "" ||
      (customerInfo.emailAddress === "" && customerInfo.phoneNumber === "");

    if (inputError) {
      dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.011")));
    }

    const customerRes = await PostCustomer(
      {
        first_name: customerInfo.firstName,
        last_name: customerInfo.lastName,
        phone: customerInfo.phoneNumber || null,
        email: customerInfo.emailAddress || null,
        state: "disabled",
      },
      true,
    );

    if (customerRes.status !== StatusCode.OK) {
      dispatch(showError(customerErrorMessage(t, customerRes?.data?.message)));
      return;
    }

    setChangePlayerState(prevState => ({
      ...prevState,
      showNewPlayer: false,
      selectedCustomer: customerRes.data.data,
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
    }));

    dispatch(showSuccess(customerRes.data.message));
  }

  async function changePlayerSearch(mounted: boolean, golferSearchQuery: string) {
    try {
      if (golferSearchQuery === "") {
        if (mounted) {
          setChangePlayerState(prevState => ({ ...prevState, playerSearchResult: [] }));
        }
        return;
      } else {
        setChangePlayerState(prevState => ({ ...prevState, customerSearching: true }));
        const customerRes = await GetCustomer({ search: golferSearchQuery }, false);
        if (customerRes.status !== StatusCode.OK) {
          setChangePlayerState(prevState => ({ ...prevState, customerSearching: false, playerSearchResult: [] }));
          return;
        } else if (mounted) {
          setChangePlayerState(prevState => ({
            ...prevState,
            customerSearching: false,
            playerSearchResult: customerRes.data,
          }));
        }
      }
    } catch (error) {
      console.log("err", error);
    }
    return;
  }

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = global.setTimeout(() => {
        void changePlayerSearch(mounted, changePlayerState.customerQuery);
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      setChangePlayerState(prevState => ({ ...prevState, playerSearchResult: [] }));
    };
  }, [changePlayerState.customerQuery]);

  const handleBackToGolferOrder = () => {
    ReactDOM.unstable_batchedUpdates(() => {
      updateState<IDrawerPage>(
        {
          // currentPage: DrawerPage.GOLFER_ORDER,
          currentPage: DrawerPage.BOOKING_DETAILS,
        },
        StateType.DrawerPage,
      );
      updateState<ICheckInState>(
        {
          paymentOption: null,
        },
        StateType.CheckInState,
      );
      updateState<IMainState>(
        {
          gift_cards: null,
          selectedLeagues: null,
        },
        StateType.MainState,
      );
    });
  };

  function changePaymentAmount(e: any) {
    const value = e.target.value;
    updateState<ICheckInState>({ paymentAmount: value }, StateType.CheckInState);
  }

  const getDateTime = () => {
    const date = teeSheetStore.selectedTeeTime.date.split("-").join("/");
    const dateTime = new Date(date + ", " + teeSheetStore.selectedTeeTime.start_time);
    //Get the time 30 minutes before the current start time
    dateTime.setMinutes(dateTime.getMinutes() - 30);
    let hours = dateTime.getHours().toString();
    if (hours.length === 1) {
      hours = "0" + hours;
    }
    const adjustedTime = hours + ":" + dateTime.getMinutes().toString();
    return [date, adjustedTime];
  };

  const loadGroupPayTeeTimes = async () => {
    if (teeSheetStore.selectedTeeTime) {
      const dateTime = getDateTime();
      const teeTimesRes = await GetTeeTime({ date: dateTime[0], start_time: dateTime[1], extended: true }, true);
      if (teeTimesRes.status !== StatusCode.OK) {
        dispatch(showError(teeTimesRes.message));
        return;
      }
      const teeTimes = teeTimesRes.data;
      const groupPayTeeTimes = teeTimes.filter(filteredTeeTime => {
        if (filteredTeeTime?.blocked_type === "crossover") {
          return false;
        } else {
          //Only return bookings that contain booking participants
          const validTeeTime = filteredTeeTime.slots.some(
            slot => slot.status === "booked" && slot.booking_participant.check_in_status === "unchecked",
          );
          return validTeeTime && filteredTeeTime.start_time !== teeSheetStore.selectedTeeTime.start_time;
        }
      });
      updateState<IGroupPayState>({ groupPayTeeTimes }, StateType.GroupPayState);
    }
  };

  const handleOpenGroupPay = () => {
    void loadGroupPayTeeTimes();
    updateState<IGroupPayState>({ groupPayActive: true }, StateType.GroupPayState);
  };

  async function completeOrder(order_id: number) {
    const register = JSON.parse(localStorage.getItem("register"));
    const registerId = register?.id;
    const completeRes = await PutOrderComplete(
      { order_id, gift_cards: state.gift_cards, register_id: registerId, league_ids: state.selectedLeagues },
      false,
    );

    if (completeRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.146")));
      return;
    }
    dispatch(showSuccess(t("secure.facility.tee_sheet.tee_sheet_booking_details.147")));

    setAddItemState(prevState => ({ ...prevState, addedItems: [], greenfeeSearchQuery: "", cartSearchQuery: "" }));
    setCustomerState(prevState => ({ ...prevState, customerSearchQuery: "" }));
    setChangePlayerState(prevState => ({ ...prevState, customerQuery: "" }));
    setFoundGreenFees([]);
    setFoundCartItems([]);
  }

  async function updateCartOrder(order_id: number) {
    if (order_id) {
      //Update order
      const orderRes = await GetOrder({ id: order_id, extended: true }, false);
      if (orderRes.status !== StatusCode.OK) {
        dispatch(showError(orderRes.message));
        return;
      }
      setManualProcessing(false);

      const order = orderRes.data[0];
      if (order.balance <= 0 || order.financial_status === "paid") {
        await completeOrder(order_id);
        await handleCheckInParticipants(order);

        setCheckInState(prevState => ({
          ...prevState,
          order: order,
          paymentAmount: 0,
          // orderCompleteActive: true,
          selectedAccount: undefined,
        }));

        updateState<IMainState>(
          {
            gift_cards: null,
            selectedLeagues: null,
          },
          StateType.MainState,
        );

        updateState<IDrawerPage>(
          {
            currentPage: DrawerPage.RECEIPT_ORDER,
          },
          StateType.DrawerPage,
        );

        //Check register to auto log out
        const register = JSON.parse(localStorage.getItem("register"));
        if (register && register?.auto_log_out === true) {
          localStorage.setItem("switch_user_after_transaction", "true");
        }

        updateOrderCompletePopup({ loading: false });

        // force refresh
        void refreshTeeTime();
      } else {
        closeOrderCompletePopup();
        // Still need to process another payment for this order
        setCheckInState(prevState => ({
          ...prevState,
          order: order,
          paymentAmount: order.balance.toFixed(2),
        }));
        updateState<IDrawerPage>(
          {
            currentPage: DrawerPage.PAYMENT_METHOD,
          },
          StateType.DrawerPage,
        );
      }
    }
  }

  async function refreshTeeTime() {
    const teetimeRes = await GetTeeTime(
      {
        id: Number(teetimeId),
        locks: true,
        extended: true,
        "extended-bookings": true,
        turn_tee_time: true,
      },
      false,
    );

    if (teetimeRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.183")));
      return;
    }

    const updatedTeeTime = teetimeRes.data[0];
    dispatch(selectTeeTimeSuccess(updatedTeeTime));
  }

  function initiateRoomChargeSheet() {
    setCheckInState(prevState => ({
      ...prevState,
      roomChargeSheetVisible: true,
      roomOccupantSearchQuery: "",
      roomOccupantsSearchResults: [],
      selectedOccupant: null,
    }));
  }

  function closeRoomChargeSheet() {
    setCheckInState(prevState => ({
      ...prevState,
      roomChargeSheetVisible: false,
      roomOccupantSearchQuery: "",
      roomOccupantsSearchResults: [],
      selectedOccupant: null,
    }));
  }

  function handleOccupantSearchQueryChange(event: React.ChangeEvent<HTMLInputElement>) {
    setCheckInState(prevState => ({ ...prevState, roomOccupantSearchQuery: event.target.value }));
  }

  const handleProcessPayment = async (giftCardInfo?: IGiftCardRedeemState) => {
    setState(prevState => ({ ...prevState, paymentInProgress: true }));
    const cartTotal = Number(checkInState.cart.total_price?.toFixed(2));
    try {
      const cart_id = checkInState.cart.id;
      let checkoutRes: { status: any; data: any };

      // Create a new order if order does not exist in state
      if (!checkInState?.order) {
        const register = JSON.parse(localStorage.getItem("register"));
        const registerId = register?.id;
        checkoutRes = await PostCheckout({ cart_id, register_id: registerId }, false);
      }

      const finalAmount = Number(checkInState.paymentAmount);
      //const finalAmount = checkInState.cart.total_price;
      console.log("Final amount: ", finalAmount);
      //Check to see if amount is acceptable
      if (Number.isNaN(finalAmount)) {
        dispatch(
          showError(`'${checkInState.paymentAmount}'${t("secure.facility.tee_sheet.tee_sheet_booking_details.012")}`),
        );
        setState(prevState => ({ ...prevState, paymentInProgress: false }));
        return;
      }

      if ((finalAmount > cartTotal && checkInState.paymentOption?.payment_method !== "cash") || finalAmount < 0) {
        dispatch(
          showError(`'${checkInState.paymentAmount}'${t("secure.facility.tee_sheet.tee_sheet_booking_details.013")}`),
        );
        setState(prevState => ({ ...prevState, paymentInProgress: false }));
        return;
      }

      let showOrderCompletePopup = false;
      if (
        (!checkInState.order && finalAmount >= checkoutRes?.data?.order?.total_price) ||
        (checkInState.order && finalAmount >= checkInState.order.balance)
      ) {
        showOrderCompletePopup = true;
      }

      if (checkoutRes?.status === StatusCode.OK || checkInState?.order) {
        if (checkoutRes) {
          updateState<ICheckInState>({ order: checkoutRes.data.order }, StateType.CheckInState);
        }

        const order_id = checkoutRes ? checkoutRes.data.order.id : checkInState?.order?.id;

        const updateOrderParams = {
          id: order_id,
          customer_id: checkInState.cart.customer_id,
        };

        // Display manual credit card sheet
        if (checkInState.paymentOption?.payment_method === "manual_card") {
          const transactionRes = await PostTransaction(
            {
              kind: "sale",
              amount: finalAmount,
              order_id: order_id,
              payment_option_id: checkInState.paymentOption?.id,
            },
            false,
          );

          if (transactionRes.status === StatusCode.OK) {
            setManualStripeState(prevState => ({
              ...prevState,
              transaction: transactionRes.data,
              clientSecret: transactionRes.data.client_secret,
              visible: true,
            }));

            // const updateOrderRes = await PutOrder(updateOrderParams, true);

            // if (updateOrderRes.status !== StatusCode.OK) {
            //   dispatch(showError(updateOrderRes.message);
            //   return;
            // }
          } else {
            dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.148")));
            closeOrderCompletePopup();
            setState(prevState => ({ ...prevState, paymentInProgress: false }));
            return;
          }

          // Using terminal - display modal
        } else if (checkInState.paymentOption?.payment_method === "card") {
          if (facilityStore.facility.terminal_integration === "server") {
            // If cart already has a tip, skip tipping
            await processPayment(
              order_id,
              checkInState.paymentOption,
              finalAmount,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              checkInState?.cart?.total_tip > 0 ? true : false,
            );
            setState(prevState => ({ ...prevState, paymentInProgress: false }));
          } else {
            setStripePaymentState(prevState => ({
              ...prevState,
              sheetVisible: true,
              onCancel: undefined,
              okText: t("secure.facility.tee_sheet.tee_sheet_booking_details.014"),
              processing: true,
              onOk: async () => {
                setStripePaymentState(prevState => ({
                  ...prevState,
                  sheetVisible: false,
                  onCancel: undefined,
                  okText: undefined,
                  processing: false,
                  onOk: () => {},
                  titleText: "",
                  success: false,
                  message: "",
                }));
                await clearReaderDisplay();
              },
              titleText: t("secure.facility.tee_sheet.tee_sheet_booking_details.015"),
              message: t("secure.facility.tee_sheet.tee_sheet_booking_details.016"),
            }));
            await stripeProcessPayment(
              order_id,
              finalAmount,
              checkInState.paymentOption,
              handleStripeSuccess,
              handleStripeError,
            );

            // const updateOrderRes = await PutOrder(updateOrderParams, true);
            // if (updateOrderRes.status !== StatusCode.OK) {
            //   dispatch(showError(updateOrderRes.message);
            //   return;
            // }
            //
            // updateState<IDrawerPage>(
            //   {
            //     currentPage: DrawerPage.RECEIPT_ORDER,
            //   },
            //   StateType.DrawerPage,
            // );
          }
        } else if (checkInState.paymentOption?.payment_method === "gift_card") {
          const transactionRes = await PostTransaction(
            {
              kind: "sale",
              amount: finalAmount,
              order_id: order_id,
              payment_option_id: checkInState.paymentOption?.id,
              gift_card_code: giftCardInfo.giftCardCode,
              gift_card_pin: giftCardInfo.giftCardPin,
            },

            false,
          );

          if (transactionRes.status === StatusCode.OK) {
            updateState<ICheckInState>(
              {
                giftCardActive: false,
              },
              StateType.CheckInState,
            );

            // const updateOrderRes = await PutOrder(updateOrderParams, true);
            // if (updateOrderRes.status !== StatusCode.OK) {
            //   dispatch(showError(updateOrderRes.message);
            //   return;
            // }
            dispatch(showSuccess(t("secure.facility.tee_sheet.tee_sheet_booking_details.149")));

            // If gift card balnce wasn't enough to cover transaction don't show order complete popup
            if (transactionRes.data?.amount < finalAmount) {
              showOrderCompletePopup = false;
            }
            if (showOrderCompletePopup) {
              updateOrderCompletePopup({ isOpen: true, loading: true });
            }

            await updateCartOrder(order_id);
            //await clearReaderDisplay(); // don't run if reader is not connected
          } else {
            dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.150")));
            closeOrderCompletePopup();
            setState(prevState => ({ ...prevState, paymentInProgress: false }));
            return;
          }
        } else if (checkInState.paymentOption?.payment_method === "account") {
          const transactionRes = await PostTransaction(
            {
              kind: "sale",
              amount: finalAmount,
              order_id: order_id,
              payment_option_id: checkInState.paymentOption?.id,
              account_id: checkInState.selectedAccount.id,
            },
            false,
          );

          if (transactionRes?.status !== StatusCode.OK) {
            dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.148")));
            setState(prevState => ({ ...prevState, paymentInProgress: false }));
            return;
          }

          // display success notification
          dispatch(showSuccess(t("secure.facility.tee_sheet.tee_sheet_booking_details.184")));

          if (showOrderCompletePopup) {
            updateOrderCompletePopup({ isOpen: true, loading: true });
          }

          // Update order and check if there is a remaining balance
          await updateCartOrder(order_id);
        } else if (checkInState.paymentOption?.payment_method === "credit_book") {
          const transactionRes = await PostTransaction(
            {
              kind: "sale",
              amount: finalAmount,
              order_id: order_id,
              payment_option_id: checkInState.paymentOption?.id,
              credit_book_id: modalCreditBook.selectedCreditBook.id,
            },
            false,
          );

          if (transactionRes?.status !== StatusCode.OK) {
            setState(prevState => ({ ...prevState, paymentInProgress: false }));
            closeCreditBook();
            closeOrderCompletePopup();
            dispatch(
              showError(
                typeof transactionRes?.data === "string" ? transactionRes.data : "Error generating transaction",
              ),
            );
            return;
          }

          // display success notification
          dispatch(showSuccess(t("secure.facility.tee_sheet.tee_sheet_booking_details.184")));

          if (showOrderCompletePopup) {
            updateOrderCompletePopup({ isOpen: true, loading: true });
          }

          closeCreditBook();

          // Update order and check if there is a remaining balance
          await updateCartOrder(order_id);
        } else if (
          checkInState.paymentOption?.payment_method === "room_charge_rdp" ||
          checkInState.paymentOption?.payment_method === "maestro"
        ) {
          const transactionRes = await PostTransaction(
            {
              kind: "sale",
              amount: finalAmount,
              order_id: order_id,
              payment_option_id: checkInState.paymentOption?.id,
              guest_name: checkInState.selectedOccupant?.guest_name,
              room_number: checkInState.selectedOccupant?.room_number,
            },
            false,
          );

          if (transactionRes.status !== StatusCode.OK) {
            dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.148")));
            closeOrderCompletePopup();
            setState(prevState => ({ ...prevState, paymentInProgress: false }));
            return;
          } else {
            dispatch(showSuccess(t("secure.facility.tee_sheet.tee_sheet_booking_details.185")));

            if (showOrderCompletePopup) {
              updateOrderCompletePopup({ isOpen: true, loading: true });
            }

            await updateCartOrder(transactionRes.data.order_id);

            closeRoomChargeSheet();
          }
        } else {
          let changeDue: number;
          if (showOrderCompletePopup) {
            updateOrderCompletePopup({ isOpen: true, loading: true });
          }
          //Display change due for cash payments
          if (checkInState.paymentOption?.payment_method === "cash") {
            if (checkInState?.order?.balance && Number(checkInState.paymentAmount) > checkInState.order?.balance) {
              changeDue = Number(checkInState.paymentAmount) - checkInState.order?.balance;
              updateState<ICheckInState>(
                {
                  changeDue: changeDue,
                  changeDuePopupVisible: true,
                },
                StateType.CheckInState,
              );
            } else if (Number(checkInState.paymentAmount) > cartTotal) {
              changeDue = Number(checkInState.paymentAmount) - cartTotal;
              updateState<ICheckInState>(
                {
                  changeDue: changeDue,
                  changeDuePopupVisible: true,
                },
                StateType.CheckInState,
              );
            }
          }

          // display success notification
          const transactionRes = await PostTransaction(
            {
              kind: "sale",
              amount: changeDue ? finalAmount - changeDue : finalAmount,
              order_id: order_id,
              payment_option_id: checkInState.paymentOption?.id,
            },
            false,
          );

          if (transactionRes.status === StatusCode.OK) {
            updateState<ICheckInState>(
              {
                giftCardActive: false,
              },
              StateType.CheckInState,
            );

            // const updateOrderRes = await PutOrder(updateOrderParams, true);
            // if (updateOrderRes.status !== StatusCode.OK) {
            //   dispatch(showError(updateOrderRes.message);
            //   return;
            // }
            dispatch(showSuccess(t("secure.facility.tee_sheet.tee_sheet_booking_details.186")));
            await updateCartOrder(order_id);
            //await clearReaderDisplay(); // don't run if reader is not connected
          } else {
            dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.148")));
            closeOrderCompletePopup();
            setState(prevState => ({ ...prevState, paymentInProgress: false }));
            return;
          }
        }

        if (showOrderCompletePopup) {
          updateOrderCompletePopup({ transactionComplete: true });
        }
      }
    } catch (e) {
      closeOrderCompletePopup();
      console.log("err:", e);
    }

    setState(prevState => ({ ...prevState, paymentInProgress: false }));
  };

  const handleBackToCheckIn = () => {
    updateState<IDrawerPage>({ currentPage: DrawerPage.NEW_ORDER }, StateType.DrawerPage);
    // Reset values
    updateState<ICheckInState>(
      {
        paymentOption: null,
        order: undefined,
        cart: null,
        // orderCompleteActive: false,
        cancelOrderActive: false,
      },
      StateType.CheckInState,
    );
    closeOrderCompletePopup();
    // void cartActions.cartClear(checkInState.cart?.id, true);
    // void orderActions.orderClear();
  };

  async function loadReceipt(order_id: number) {
    const receiptRes = await GetReceipt({ id: order_id, tee_time_id: Number(teetimeId) }, true);

    if (receiptRes.status === StatusCode.OK) {
      window.open().document.write(receiptRes.data);
    } else {
      dispatch(showError(receiptRes.message));
    }
  }

  async function loadCartWaiver(customer_id: number) {
    const waiverRes = await GetCartWaiver(
      { customer_id: customer_id, tee_time_id: teeSheetStore.selectedTeeTime.id },
      true,
    );

    if (waiverRes.status === StatusCode.OK) {
      window.open().document.write(waiverRes.data);
    } else {
      dispatch(showError("Cart waiver has not been created")); // TODO: Translation
    }
  }

  // Manage effects after payment
  //(14/Oct/21)::(06:08:46) - TODO - Can the functionality be moved to loadReceipt with bool param toPrint
  useEffect(() => {
    if (checkInState?.order) {
      // Reset for another
      if (checkInState?.order.balance > 0) {
        updateState<ICheckInState>({ paymentAmount: checkInState.order.balance }, StateType.CheckInState);
      }
    }
  }, [checkInState?.order]);

  const handleLineItemClick = (lineItem: ILineItem) => {
    updateState<IUpdateLineItemModalState>(
      {
        lineItem: lineItem,
        inputPrice: String(lineItem.price),
        inputQuantity: String(lineItem.quantity),
        inputNote: lineItem.note ? lineItem.note : "",
        isModalVisible: true,
      },
      StateType.LineItemState,
    );
  };

  const handleRemoveLineItem2 = (cartDataRes: any) => {
    // If the line item product is of type 'Green Fee' or 'Power Cart', check if the booking participants' green_fee_variant_id
    //or power_cart_variant_id matches the line item's variant_id. If so, update addItemState.addedItems
    let removedItems = [...addItemState.addedItems];
    if (
      lineItemState.lineItem?.product?.type === "Green Fee" ||
      lineItemState.lineItem?.product?.type === "Power Cart"
    ) {
      const type =
        lineItemState.lineItem?.product?.type === "Green Fee" ? "green_fee_variant_id" : "power_cart_variant_id";
      const productFee = lineItemState.lineItem?.product?.type === "Green Fee" ? "green_fee" : "power_cart_fee";
      teeSheetStore.selectedTeeTime?.slots?.forEach(slot => {
        if (slot.booking_participant && slot.booking_participant?.[type] === lineItemState.lineItem?.variant_id) {
          removedItems = removedItems?.map(item => {
            if (item.booking_participant_id === slot.booking_participant.id) {
              return {
                ...item,
                [productFee]: false,
              };
            } else {
              return item;
            }
          });
        }
      });
      updateState<IAddItemState>({ addedItems: removedItems }, StateType.AddItemState);
    }
    dispatch(showSuccess(t("secure.facility.tee_sheet.tee_sheet_booking_details.151")));
    // update line items in cart
    updateState<ICheckInState>({ cart: cartDataRes }, StateType.CheckInState);
  };

  const bookingParticipantRemoveProduct = (inputQuantity: number) => {
    let removedItems = [...addItemState.addedItems];
    const amountToRemove = lineItemState.lineItem?.quantity - inputQuantity;
    let numRemoved = 0;
    const type =
      lineItemState.lineItem?.product?.type === "Green Fee" ? "green_fee_variant_id" : "power_cart_variant_id";
    const productFee = lineItemState.lineItem?.product?.type === "Green Fee" ? "green_fee" : "power_cart_fee";
    [...teeSheetStore.selectedTeeTime?.slots]?.reverse().forEach(slot => {
      if (slot.booking_participant && slot.booking_participant?.[type] === lineItemState.lineItem?.variant_id) {
        removedItems = removedItems?.map(item => {
          if (item.booking_participant_id === slot.booking_participant.id && numRemoved < amountToRemove) {
            numRemoved++;
            return {
              ...item,
              [productFee]: false,
            };
          } else {
            return item;
          }
        });
      }
    });
    updateState<IAddItemState>({ addedItems: removedItems }, StateType.AddItemState);
  };

  const handleUpdateLineItem = (props: { updatedCart: any; quantity: number; isSuccess: boolean }) => {
    const { updatedCart, quantity, isSuccess } = props;

    if (isSuccess && quantity < lineItemState.lineItem.quantity) {
      void bookingParticipantRemoveProduct(quantity);
    }

    updateState<ICheckInState>({ cart: updatedCart }, StateType.CheckInState);
  };

  function handleCardSectionChange(
    e: StripeCardNumberElementChangeEvent | StripeCardExpiryElementChangeEvent | StripeCardCvcElementChangeEvent,
  ) {
    setManualStripeState(prevState => ({
      ...prevState,
      elementComplete: { ...prevState.elementComplete, [e.elementType]: e.complete },
    }));
  }

  const elementsComplete =
    manualStripeState.elementComplete.cardNumber === true &&
    manualStripeState.elementComplete.cardExpiry === true &&
    manualStripeState.elementComplete.cardCvc === true;

  async function loadRoomOccupants(roomOccupantSearchQuery: string, cancelToken: CancelToken) {
    const roomOccupantsRes = await GetRoomOccupants({ search: roomOccupantSearchQuery }, true, cancelToken);

    if (roomOccupantsRes.status !== StatusCode.OK && roomOccupantsRes.message !== "Cancelled") {
      dispatch(showError(roomOccupantsRes.message));
      return;
    }

    return roomOccupantsRes;
  }

  async function searchForRoomOccupants(mounted: boolean, roomOccupantSearchQuery: string, cancelToken: CancelToken) {
    try {
      if (roomOccupantSearchQuery === "") {
        if (mounted) {
          setCheckInState(prevState => ({ ...prevState, roomOccupantsSearchResults: [] }));
        }
      } else {
        const res = await loadRoomOccupants(roomOccupantSearchQuery, cancelToken);
        if (mounted && res?.status === StatusCode.OK && res?.data) {
          const occupants = res.data.map(occupant => {
            return {
              reservation_number: occupant.reservation_number ?? "",
              room_number: occupant.room_number ?? "",
              res_status: occupant.res_status,
              guest_name: occupant.guest_name ?? "",
              credit_limit: occupant.credit_limit,
            };
          });
          setCheckInState(prevState => ({ ...prevState, roomOccupantsSearchResults: occupants }));
        }
      }
    } catch (error) {
      setCheckInState(prevState => ({ ...prevState }));
    }
    setCheckInState(prevState => ({
      ...prevState,
      selectedOccupant: null,
    }));
  }

  const removeCustomerFromCart = async () => {
    if (checkInState.cart?.id) {
      const putCustomerRes = await PutCustomerCart({ id: checkInState.cart.id, customer_id: null }, true);
      if (putCustomerRes.status !== StatusCode.OK) {
        dispatch(showError(putCustomerRes.message));
      }
      updateState<ICheckInState>({ cart: putCustomerRes.data }, StateType.CheckInState);
      handleShowCustomerSearchChange(false);
      handleCustomerSearch("");
    }
  };

  const handleShowCustomerSearchChange = (showCustomerSearch: boolean) => {
    setCustomerState(prevState => ({ ...prevState, showCustomerSearch: showCustomerSearch }));
  };

  const handleCustomerSearch = (query: string) => {
    setCustomerState(prevState => ({ ...prevState, customerSearchQuery: query }));
  };

  const handleCustomerSelection = async (id: string | number) => {
    if (id === undefined || id === null || id === "") {
      return;
    } else {
      const selectedCustomer = customerState.customerSearchResults.find(customer => customer?.id === id);
      if (selectedCustomer && checkInState?.cart?.id) {
        const putCustomerRes = await PutCustomerCart(
          { id: checkInState.cart.id, customer_id: parseInt(id.toString()) },
          true,
        );
        if (putCustomerRes.status !== StatusCode.OK) {
          dispatch(showError(putCustomerRes.message));
          return;
        }
        updateState<ICheckInState>({ cart: putCustomerRes.data }, StateType.CheckInState);
      }
    }
  };

  async function searchForCustomers(mounted: boolean, customerSearchQuery: string) {
    try {
      if (customerSearchQuery === "") {
        if (mounted) {
          setCustomerState(prevState => ({ ...prevState, customerSearchResults: [null], searchingCustomer: false }));
        }
        return;
      } else {
        setCustomerState(prevState => ({ ...prevState, searchingCustomer: true }));
        const customerRes = await GetCustomer({ search: customerSearchQuery }, false);
        if (customerRes.status !== StatusCode.OK) {
          return;
        }
        setCustomerState(prevState => ({ ...prevState, searchingCustomer: false }));
        if (mounted && customerRes?.status === StatusCode.OK && customerRes?.data) {
          setCustomerState(prevState => ({ ...prevState, customerSearchResults: customerRes.data }));
        }
      }
    } catch (error) {
      setCustomerState(prevState => ({ ...prevState }));
    }
    return;
  }

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = global.setTimeout(() => {
        void searchForCustomers(mounted, customerState.customerSearchQuery);
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      setCustomerState(prevState => ({ ...prevState, customerSearchResults: [null], searchingCustomer: false }));
    };
  }, [customerState.customerSearchQuery]);

  function closeAccountsModal() {
    updateState<ICheckInState>({ accountsVisible: false, selectedAccount: undefined }, StateType.CheckInState);
  }

  function handleAccountsOk(selectedAccount: Record<string, any>) {
    updateState<ICheckInState>({ accountsVisible: false, selectedAccount }, StateType.CheckInState);
  }

  useEffect(() => {
    if (checkInState.selectedAccount || modalCreditBook.selectedCreditBook) {
      void handleProcessPayment();
    }
    return () => {
      updateState<ICheckInState>({ selectedAccount: undefined }, StateType.CheckInState);
      updateCreditBook({ selectedCreditBook: null });
    };
  }, [checkInState.selectedAccount, modalCreditBook.selectedCreditBook]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = global.setTimeout(() => {
        void searchForRoomOccupants(mounted, checkInState.roomOccupantSearchQuery, source.token);
      }, 2000);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      setCheckInState(prevState => ({ ...prevState, roomOccupantsSearchResults: [] }));
      source.cancel("Cancelled");
    };
  }, [checkInState.roomOccupantSearchQuery]);

  function handleProcessPaymentCheck() {
    if (checkInState?.paymentOption?.payment_method === "gift_card") {
      updateState<ICheckInState>({ giftCardActive: true }, StateType.CheckInState);
    } else if (checkInState?.paymentOption?.payment_method === "account" && checkInState.cart.customer?.id) {
      updateState<ICheckInState>({ accountsVisible: true }, StateType.CheckInState);
    } else if (checkInState?.paymentOption?.payment_method === "credit_book") {
      updateCreditBook({ isOpen: true });
    } else if (
      checkInState?.paymentOption?.payment_method === "room_charge_rdp" ||
      checkInState?.paymentOption?.payment_method === "maestro"
    ) {
      initiateRoomChargeSheet();
    } else {
      void handleProcessPayment();
    }
  }

  async function handlerOfTip(totalofTip: number) {
    const CartResponse = await PutCart({ id: checkInState.cart.id, total_tip: totalofTip }, true);

    if (CartResponse.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.153")));
      return;
    }

    updateState<ICheckInState>({ cart: CartResponse.data }, StateType.CheckInState);
  }

  async function cancelOrder() {
    const cancelRes = await PutVoidOrder(
      { order_id: checkInState?.order?.id, notes: "Transaction cancelled", financial_status: "cancelled" },
      true,
    );

    if (cancelRes.status !== StatusCode.OK) {
      dispatch(showError("Error cancelling order"));
      return;
    }
    dispatch(showSuccess("Successfully cancelled order"));
    ReactDOM.unstable_batchedUpdates(() => {
      setAddItemState(prevState => ({ ...prevState, greenfeeSearchQuery: "", cartSearchQuery: "" }));
      setCustomerState(prevState => ({ ...prevState, customerSearchQuery: "" }));
      updateState<IDrawerPage>({ currentPage: DrawerPage.BOOKING_DETAILS }, StateType.DrawerPage);
      // Reset values
      updateState<ICheckInState>(
        {
          paymentOption: null,
          order: undefined,
          // orderCompleteActive: false,
          cancelOrderActive: false,
          paymentAmount: Number(checkInState?.cart?.total_price?.toFixed(2)),
        },
        StateType.CheckInState,
      );
      closeOrderCompletePopup();
    });
  }

  const validateGiftCardCode = async (code: string, reload: number) => {
    const validateRes = await GetGiftCardValidate({ code, reload }, true);
    if (validateRes.status !== StatusCode.OK) {
      if (reload) {
        dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.141")));
      } else {
        dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.142")));
      }
      return false;
    }
    return true;
  };

  const handleAddSellGiftCards = async () => {
    let validateRes = true;
    //Validate gift card codes
    for (const gift_card of state.gift_cards) {
      if (!gift_card.random) {
        validateRes = await validateGiftCardCode(gift_card.code, gift_card.reload);
      }
      if (validateRes === false) {
        return;
      }
    }

    if (validateRes) {
      const moveToPaymentMethods = checkToContinuePayment("gift_card");
      updateState<IMainState>({ sellGiftCardsActive: false }, StateType.MainState);
      if (moveToPaymentMethods) {
        void handleContinueToPaymentMethod();
      }
      dispatch(showSuccess(t("secure.facility.tee_sheet.tee_sheet_booking_details.143")));
    }
  };

  const checkGiftCards = () => {
    const gift_cards: any[] = [];
    const filteredGiftCards: any[] = checkInState.cart?.line_items?.filter(
      (line_item: Record<string, any>) => line_item.product_title === "Gift Card",
    );
    const filteredLeagueFees = checkInState.cart?.line_items?.filter(
      (line_item: Record<string, any>) => line_item?.product?.type === "League Registration Fee",
    );

    if (filteredLeagueFees?.length > 0 && !checkInState.cart?.customer_id) {
      dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.172")));
      return;
    }

    //Open gift cards modal if cart contains gift cards
    if (filteredGiftCards?.length > 0 || filteredLeagueFees?.length > 0) {
      if (filteredGiftCards.length > 0) {
        filteredGiftCards.forEach((line_item: Record<string, any>, index: number) => {
          for (let i = 0; i < line_item?.quantity; i++) {
            gift_cards.push({
              product_id: line_item.product_id,
              cart_line_item_id: line_item.id,
              code: null,
              pin: null,
              random: false,
              balance: line_item.price,
              reload: 0,
            });
          }
        });
        updateState<IMainState>({ gift_cards, sellGiftCardsActive: true }, StateType.MainState);
      }
      //Check for league registration fees
      if (filteredLeagueFees?.length > 0) {
        updateState<IMainState>({ leagueFeesActive: true, leagueFeeProducts: filteredLeagueFees }, StateType.MainState);
      }
    } else {
      //Go directly to payment methods page if no gift cards in cart
      updateState<IMainState>(
        { gift_cards: null, leagueFeeProducts: null, selectedLeagues: null },
        StateType.MainState,
      );
      void handleContinueToPaymentMethod();
    }
  };

  const setSelectedLeagues = (leagues: Array<number>) => {
    const moveToPaymentMethods = checkToContinuePayment("leagues");
    setState(prevState => ({
      ...prevState,
      selectedLeagues: leagues,
      leagueFeesActive: false,
      leagueFeeProducts: null,
    }));
    if (moveToPaymentMethods) {
      void handleContinueToPaymentMethod();
    }
  };

  function onCancelAddGreenFeeModal() {
    updateState<IAddItemState>({ greenFeeActive: false }, StateType.AddItemState);
    onCancelAddVariantModal();
  }

  function onCancelAddPowerCartModal() {
    updateState<IAddItemState>({ powerCartFeeActive: false }, StateType.AddItemState);
    onCancelAddVariantModal();
  }

  function onClickProduct(product: IProduct, product_type: "Green Fee" | "Power Cart") {
    updateState<IAddItemState>(
      {
        productState: {
          ...addItemState.productState,
          selectedProduct: product,
        },
      },
      StateType.AddItemState,
    );

    const variants = product?.variants?.filter(variant => variant?.facility_access === true);

    if (variants?.length > 1) {
      updateState<IAddItemState>(
        {
          productState: {
            ...addItemState.productState,
            variantSheetOpen: true,
            variants,
          },
        },
        StateType.AddItemState,
      );
    } else {
      if (variants?.[0]) {
        void handleAddQuickItem(variants[0].id, product_type);
      } else {
        dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.187")));
      }
    }
  }

  function onCancelAddVariantModal() {
    updateState<IAddItemState>(
      {
        productState: {
          variantSheetOpen: false,
          variants: [],
          selectedProduct: undefined,
        },
      },
      StateType.AddItemState,
    );
  }

  function cancelGiftCardsAndLeagues() {
    ReactDOM.unstable_batchedUpdates(() => {
      setState(prevState => ({
        ...prevState,
        sellGiftCardsActive: false,
        gift_cards: null,
        leagueFeesActive: false,
        leagueFeeProducts: null,
        selectedLeagues: null,
      }));
      updateState<IDrawerPage>(
        {
          currentPage: DrawerPage.BOOKING_DETAILS,
        },
        StateType.DrawerPage,
      );
    });
  }

  async function addAllGroupPay(slots: Array<ISlot>) {
    const filteredSlots = slots?.filter(slot => slot?.booking_participant?.check_in_status === "unchecked");
    const addedItems = [...addItemState.addedItems];
    const { greenFeeCounts, powerCartCounts, feeList } = determineFeeCounts(filteredSlots, addedItems);

    if (greenFeeCounts?.length === 0 && powerCartCounts.length === 0) {
      return;
    }

    const postLineItemRes = await PostLineItemToCart(
      {
        cart_id: checkInState.cart.id,
        variants: [...greenFeeCounts, ...powerCartCounts],
      },
      true,
    );

    if (postLineItemRes.status !== StatusCode.OK) {
      dispatch(showError("Error adding items to cart"));
      return;
    }

    const newAddedItems = feeList.map(item => ({
      booking_participant_id: parseInt(item.participant_id),
      green_fee: item.greenFeeChecked ?? false,
      power_cart_fee: item.powerCartChecked ?? false,
      both: false,
    }));

    ReactDOM.unstable_batchedUpdates(() => {
      updateState<ICheckInState>({ cart: postLineItemRes.data }, StateType.CheckInState);
      updateState<IAddItemState>(
        { addedItems: [...addItemState.addedItems, ...newAddedItems] },
        StateType.AddItemState,
      );
    });
  }

  async function handleAddAllGroupPay(
    slot: ISlot,
    type: "green_fee" | "power_cart_fee",
    addedItems: Array<IAddedItem>,
  ) {
    let variant_id: number;
    const updatedAddedItems = [...addedItems];

    if (type === "green_fee") {
      variant_id = slot?.booking_participant?.green_fee_variant_id;
    }

    if (type === "power_cart_fee") {
      variant_id = slot?.booking_participant?.power_cart_variant_id;
    }

    const postLineItemToCartRes = await PostLineItemToCart(
      {
        cart_id: checkInState.cart.id,
        variants: [{ variant_id: variant_id, quantity: 1 }],
      },
      false,
    );

    if (postLineItemToCartRes?.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.188")));
      return updatedAddedItems;
    }

    updateState<ICheckInState>({ cart: postLineItemToCartRes.data }, StateType.CheckInState);

    const foundIndex = updatedAddedItems.findIndex(
      item => item.booking_participant_id === slot.booking_participant.id,
    );
    if (foundIndex === -1) {
      const newAddedItem: IAddedItem = {
        booking_participant_id: slot.booking_participant.id,
        green_fee: false,
        power_cart_fee: false,
        both: false,
      };
      newAddedItem[type] = true;

      updatedAddedItems.push(newAddedItem);
    } else {
      updatedAddedItems[foundIndex] = { ...updatedAddedItems[foundIndex], [type]: true };
    }

    return updatedAddedItems;
  }

  async function createCart() {
    if (!checkInState.cart?.id || drawerPage.currentPage === DrawerPage.RECEIPT_ORDER) {
      const postCartRes = await PostCart(null, true);
      if (postCartRes.status === StatusCode.OK) {
        ReactDOM.unstable_batchedUpdates(() => {
          updateState<ICheckInState>(
            { cart: postCartRes.data, order: undefined, paymentOption: null },
            StateType.CheckInState,
          );
          updateState<IDrawerPage>(
            {
              currentPage: DrawerPage.BOOKING_DETAILS,
            },
            StateType.DrawerPage,
          );
        });
      } else {
        dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.189")));
      }
    }
  }

  function togglePowerCartVisible(visible: boolean) {
    updateState<IMainState>({ powerCartModalVisible: visible }, StateType.MainState);
  }

  const handleDiscountCancel = async () => {
    const res = await DeleteDiscountLine(
      { id: removeDiscountState.discountId, cart_id: removeDiscountState.cartId },
      true,
    );

    if (res && res?.status === StatusCode.BAD_REQUEST) {
      dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.190"))); // TODO: Translation
      return;
    }

    dispatch(showSuccess(t("secure.facility.tee_sheet.tee_sheet_booking_details.191"))); // TODO: Translation

    setRemoveDiscountState({
      modalVisible: false,
      cartId: null,
      discountId: null,
    });

    updateState<ICheckInState>({ cart: res.data }, StateType.CheckInState); // update cart
  };

  const checkToContinuePayment = (type: "gift_card" | "leagues") => {
    switch (type) {
      case "gift_card":
        if (!state.selectedLeagues && state.leagueFeesActive) {
          return false;
        }
        return true;
      case "leagues":
        if (state.sellGiftCardsActive) {
          return false;
        }
        return true;
      default:
        return true;
    }
  };

  const renderContext = () => {
    if (drawerPage.currentPage === DrawerPage.NEW_ORDER) {
      return (
        <div className="teesheet-details-order p-4 flex flex-column items-center justify-center">
          <div className="teesheet-details-order_new_order_icon_container">
            <FontAwesomeIcon icon={["fat", "file-circle-plus"]} className="self-center" />
          </div>

          <div className="teesheet-details-order_new_order_title">
            {t("secure.facility.tee_sheet.tee_sheet_booking_details.022")}
          </div>

          <div className="teesheet-details-order_new_order_description">
            {t("secure.facility.tee_sheet.tee_sheet_booking_details.023")}
          </div>
        </div>
      );
    }

    if (drawerPage.currentPage === DrawerPage.BOOKING_DETAILS) {
      // console.log("Booking details");
      console.log(checkInState?.cart?.customer);
      return (
        <div className="teesheet-details-order p-4 flex flex-column">
          {checkInState.cart && (
            <>
              {checkInState.cart.customer ? (
                <div>
                  <div className="flex flex-row justify-start items-center">
                    {/* <div> */}
                    <div className="teesheet-details-order_customer_icon_container">
                      <FontAwesomeIcon icon={["far", "user"]} />
                    </div>
                    <div className="ml-3">
                      <p className="teesheet-details-order_customer_name">{checkInState.cart.customer.full_name}</p>
                      <p className="teesheet-details-order_customer_type">{checkInState.cart.customer.customer_type}</p>
                    </div>
                    {/* </div> */}

                    <div className="ml-auto">
                      <a
                        className="teesheet-details-order_remove_customer_button"
                        onClick={() => removeCustomerFromCart()}
                      >
                        {t("secure.facility.tee_sheet.tee_sheet_booking_details.024")}
                      </a>
                    </div>
                  </div>
                  <hr className="mb-2"></hr>
                </div>
              ) : (
                // : !customerState.showCustomerSearch ? (
                //   <div className="w-full text-center mb-1">
                //     <a className="cursor-pointer" onClick={() => handleShowCustomerSearchChange(true)}>
                //       {t("secure.facility.tee_sheet.tee_sheet_booking_details.025")}
                //     </a>
                //   </div>
                // )
                <div className="mb-3">
                  <Select
                    showSearch
                    customerSearch
                    className="flex justify-center align-center w-full h-10 position-relative z-20 text-black text-medium appearance-none border-none focus:outline-none placeholder-gray-200 mb-2"
                    onSearch={(query: string) => handleCustomerSearch(query)}
                    onChange={(id: string | number) => handleCustomerSelection(id)}
                    allowClear
                    searchValue={customerState.customerSearchQuery}
                    placeholder={t("secure.facility.tee_sheet.tee_sheet_booking_details.025")}
                    searching={customerState.searchingCustomer}
                    noData={
                      <div className="px-4">
                        <div className="border-b flex items-center p-4 border-b mb-4">
                          <Icon icon="users-slash" style="fas" className="m-0" />
                          <span className="ml-3 italic">
                            {t("secure.facility.tee_sheet.tee_sheet_booking_details.027")}
                          </span>
                        </div>
                      </div>
                    }
                  >
                    {customerState.customerSearchResults.map((customer: Partial<ICustomer> | ICustomer) => {
                      if (customer !== null && customer !== undefined) {
                        return (
                          <Option key={customer?.id} value={customer?.id}>
                            <div className="flex justify-between">
                              <div>
                                <div className="text-semibold text-lg">{customer?.full_name}</div>
                                <div className="text-sm text-gray-500">{customer?.email}</div>
                              </div>
                            </div>
                          </Option>
                        );
                      }
                    })}
                  </Select>
                </div>
              )}
            </>
          )}

          <Button
            block
            type="default"
            size="small"
            icon={<FontAwesomeIcon icon={["fas", "plus"]} className="self-center" />}
            onClick={() => updateState<IAddItemState>({ addItemActive: true }, StateType.AddItemState)}
          >
            {t("secure.facility.tee_sheet.tee_sheet_booking_details.028")}
          </Button>

          <CartLineItems lineItems={checkInState.cart?.line_items} handleLineItemClick={handleLineItemClick} />

          <div className="mt-auto">
            <CartDiscountLines
              currency={checkInState.cart?.currency}
              discountLines={checkInState.cart?.discount_lines}
              handleDiscountCancelClick={discountLine => {
                setRemoveDiscountState({
                  modalVisible: true,
                  cartId: discountLine.cart_id,
                  discountId: discountLine.id,
                }),
                  console.log(discountLine);
              }}
            />

            <CartTotals
              currency={checkInState?.cart?.currency}
              subtotal_price={checkInState?.cart?.subtotal_price}
              tax_lines={checkInState?.cart?.tax_lines}
              total_tip={checkInState?.cart?.total_tip}
              total_price={checkInState?.cart?.total_price}
              handleTipTeeSheet={handlerOfTip}
              order={checkInState?.order as IOrder}
            />

            <Divider />

            <div className="mb-3">
              <Button
                block
                type="primary"
                disabled={checkInState.cart?.line_items?.length === 0 ? true : false}
                icon={<FontAwesomeIcon icon={["far", "arrow-right"]} />}
                onClick={checkGiftCards}
              >
                {t("secure.facility.tee_sheet.tee_sheet_booking_details.029")}
              </Button>
            </div>

            <div>
              <Button
                type="default"
                block
                onClick={() => setState(prevState => ({ ...prevState, clearCartActive: true }))}
              >
                <span>{t("secure.facility.tee_sheet.tee_sheet_booking_details.030")}</span>
              </Button>
            </div>
          </div>
        </div>
      );
    }

    if (drawerPage.currentPage === DrawerPage.PAYMENT_METHOD) {
      return (
        <div className="teesheet-details-order flex flex-1 flex-col p-4">
          {checkInState?.order ? (
            <Button
              onClick={() => setCheckInState(prevState => ({ ...prevState, cancelOrderActive: true }))}
              type="text"
              style={{ padding: 0 }}
              className="flex mr-auto"
            >
              <div className="flex items-center">
                <FontAwesomeIcon icon={["far", "long-arrow-left"]} style={{ marginRight: "8px" }} />
                <span>{t("secure.facility.tee_sheet.tee_sheet_booking_details.192")}</span>
              </div>
            </Button>
          ) : (
            <Button onClick={handleBackToGolferOrder} type="text" style={{ padding: 0 }} className="flex mr-auto">
              <div className="flex items-center">
                <FontAwesomeIcon icon={["far", "long-arrow-left"]} style={{ marginRight: "8px" }} />
                <span>{t("secure.facility.tee_sheet.tee_sheet_booking_details.031")}</span>
              </div>
            </Button>
          )}

          <div className="flex flex-1 flex-col h-full overflow-y-scroll no-scrollbar">
            <CartDiscountLines
              currency={checkInState.cart?.currency}
              discountLines={checkInState.cart?.discount_lines}
            />

            <CartTotals
              currency={checkInState.cart?.currency}
              subtotal_price={checkInState.cart?.subtotal_price}
              tax_lines={checkInState.cart?.tax_lines}
              total_tip={checkInState?.cart?.total_tip}
              total_price={checkInState?.cart?.total_price}
              handleTipTeeSheet={handlerOfTip}
              order={checkInState?.order as IOrder}
            />

            <CartTransactions
              transactions={checkInState?.order?.transactions}
              currency={checkInState?.order?.currency}
            />

            <Divider />

            <PaymentMethods
              onChange={handlePaymentOptionChange}
              paymentOptions={facilityStore.paymentOptions}
              cardReaderAvailable={terminalStore.reader === null ? false : true}
              cart={checkInState.cart}
              paymentAmount={checkInState.paymentAmount}
            />
          </div>

          <div className="mt-auto">
            <div className="flex flex-row justify-end align-center">
              <Input
                label={t("secure.facility.tee_sheet.tee_sheet_booking_details.032")}
                prefix="$"
                placeholder={String(checkInState.paymentAmount)}
                onChange={changePaymentAmount}
                value={String(checkInState.paymentAmount)}
              />
            </div>

            <Divider />

            <div className="mb-3">
              {state?.paymentInProgress ? (
                <div className="flex justify-center">
                  <div className="h-8 w-8">
                    <Spin />
                  </div>
                </div>
              ) : (
                <Button
                  type="primary"
                  block
                  disabled={!checkInState.paymentOption?.payment_method}
                  onClick={handleProcessPaymentCheck}
                >
                  {checkInState?.paymentOption?.payment_method === "gift_card"
                    ? t("secure.facility.tee_sheet.tee_sheet_booking_details.033")
                    : t("secure.facility.tee_sheet.tee_sheet_booking_details.034")}
                </Button>
              )}
            </div>
          </div>
        </div>
      );
    }

    if (drawerPage.currentPage === DrawerPage.RECEIPT_ORDER) {
      return (
        <div className="teesheet-details-order flex flex-1 flex-col p-4">
          <span className="mr-auto text-2xl text-semibold">
            {t("secure.facility.tee_sheet.tee_sheet_booking_details.035")} {checkInState?.order?.name}
          </span>

          <CartLineItems lineItems={checkInState?.order?.line_items} handleLineItemClick={handleLineItemClick} />

          <div className="mt-auto">
            <div className="flex">
              <div className="flex ml-auto flex-col w-64">
                <CartDiscountLines
                  currency={checkInState?.order?.currency} //checkInState?.order?.currency
                  discountLines={checkInState?.order?.discount_lines} //checkInState?.order?.discount_lines
                />

                <CartTotals
                  currency={checkInState?.order?.currency}
                  subtotal_price={checkInState?.order?.subtotal_price}
                  tax_lines={checkInState?.order?.tax_lines}
                  total_tip={checkInState?.order?.total_tip}
                  total_price={checkInState?.order?.total_price}
                  order={checkInState?.order as IOrder}
                />

                <CartTransactions
                  transactions={checkInState?.order?.transactions}
                  currency={checkInState?.order?.currency}
                />
              </div>
            </div>
          </div>
          <Divider />

          {checkInState?.order.balance <= 0 ? (
            <>
              <div className="mb-3">
                <Button type="primary" block onClick={() => handleBackToCheckIn()}>
                  {t("secure.facility.tee_sheet.tee_sheet_booking_details.036")}
                </Button>
              </div>
              <div>
                <Button type="default" block onClick={() => loadReceipt(checkInState.order.id)}>
                  <span>{t("secure.facility.tee_sheet.tee_sheet_booking_details.037")}</span>
                </Button>
              </div>
            </>
          ) : (
            <div className="mb-3">
              <Button
                type="primary"
                block
                disabled={!checkInState.paymentOption?.payment_method}
                onClick={handleProcessPaymentCheck}
              >
                {checkInState?.paymentOption?.payment_method === "gift_card"
                  ? t("secure.facility.tee_sheet.tee_sheet_booking_details.038")
                  : t("secure.facility.tee_sheet.tee_sheet_booking_details.039")}
              </Button>
            </div>
          )}
        </div>
      );
    }
  };

  const tabs = [
    {
      id: "redeem-ticket-scan",
      content: t("secure.facility.tee_sheet.tee_sheet_booking_details.040"),
    },
    {
      id: "redeem-ticker-available",
      content: t("secure.facility.tee_sheet.tee_sheet_booking_details.041"),
    },
  ];

  const handleSearchTicketTabChange = (value: number) => {
    updateState<ICheckInState>({ redeemTicketSelected: value }, StateType.CheckInState);
  };

  function handleTeeTimeCancelledPopupRefresh() {
    void dispatch(selectTeeTime(teeSheetStore.selectedTeeTime.id, false));
    updateState<IMainState>({ teeTimeCancelledPopupVisible: false }, StateType.MainState);
  }

  function filterGreenFees(event: any) {
    const keyword = event.target.value;

    if (keyword !== "") {
      const results = addItemState.itemSearchResult.filter(item => {
        //return item.title.toLowerCase().startsWith(keyword.toLowerCase());

        return item.title.toLowerCase().includes(keyword.toLowerCase());
      });

      setFoundGreenFees(results);
    } else {
      // If the text field is empty, remove from list
      setFoundGreenFees([]);
    }

    updateState<IAddItemState>({ greenfeeSearchQuery: keyword }, StateType.AddItemState);
  }

  function filterCartItems(event: any) {
    const keyword = event.target.value;

    if (keyword !== "") {
      const results = addItemState.itemSearchResult.filter(item => {
        //return item.title.toLowerCase().startsWith(keyword.toLowerCase());

        return item.title.toLowerCase().includes(keyword.toLowerCase());
      });

      setFoundCartItems(results);
    } else {
      // If the text field is empty, remove from list
      setFoundCartItems([]);
    }

    updateState<IAddItemState>({ cartSearchQuery: keyword }, StateType.AddItemState);
  }

  function alphaSortProducts(products: IProduct[]) {
    const productsCopy: IProduct[] = [...products];

    productsCopy.sort((firstProd: IProduct, secondProd: IProduct) => {
      if (firstProd?.title < secondProd?.title) {
        return -1;
      } else if (firstProd?.title > secondProd?.title) {
        return 1;
      } else {
        return 0;
      }
    });

    return productsCopy;
  }

  //t("secure.facility.tee_sheet.tee_sheet_booking_details.067")

  const [foundGreenFees, setFoundGreenFees] = useState<IProduct[]>(addItemState.foundGreenFees);
  const [foundCartItems, setFoundCartItems] = useState<IProduct[]>(addItemState.foundCartItems);

  async function handleAddAllFees(slots: ISlot[], type: "green_fees" | "power_carts") {
    const { greenFeeCounts, powerCartCounts, feeList } = determineFeeCounts(slots, addItemState.addedItems);
    const customerId = !slots[0].booking_participant?.guest ? slots[0].booking_participant.customer_id : null;

    if (addingFee || (greenFeeCounts.length === 0 && powerCartCounts.length === 0)) {
      return;
    }
    setAddingFee(true);

    // do this when adding is successful
    updateState<IDrawerPage>({ currentPage: DrawerPage.BOOKING_DETAILS }, StateType.DrawerPage);

    // If there is no cart created or if the user is on the receipt page, create a new cart and add the item to the order
    if (!checkInState.cart?.id || drawerPage.currentPage === DrawerPage.RECEIPT_ORDER) {
      try {
        const postCartRes = await PostCart({ customer_id: customerId }, true);
        // save new cart to state
        updateState<ICheckInState>({ cart: postCartRes.data }, StateType.CheckInState);

        // Add items to cart
        const postLineItemToCartRes = await PostLineItemToCart(
          {
            cart_id: postCartRes.data?.id,
            variants: type === "green_fees" ? [...greenFeeCounts] : [...powerCartCounts],
          },
          true,
        );

        if (postLineItemToCartRes.status === StatusCode.OK) {
          // save to state
          updateState<ICheckInState>(
            { cart: postLineItemToCartRes.data, order: undefined, paymentOption: null },
            StateType.CheckInState,
          );
        }
        setAddingFee(false);
      } catch (err) {
        console.log("postCartRes err", err);
      }
    } else {
      // cart already present
      const postLineItemToCartRes = await PostLineItemToCart(
        {
          cart_id: checkInState.cart.id,
          variants: type === "green_fees" ? [...greenFeeCounts] : [...powerCartCounts],
        },
        true,
      );

      // save to state
      updateState<ICheckInState>({ cart: postLineItemToCartRes.data }, StateType.CheckInState);
    }

    setAddingFee(false);

    // item tracker, keeps track of which items have been added to cart
    const newItems: IAddedItem[] = feeList.map(item => ({
      booking_participant_id: parseInt(item.participant_id),
      green_fee: type === "green_fees" ? item.greenFeeChecked ?? false : false,
      power_cart_fee: type === "power_carts" ? item.powerCartChecked ?? false : false,
      both: false,
    }));
    updateState<IAddItemState>({ addedItems: [...addItemState.addedItems, ...newItems] }, StateType.AddItemState);
  }

  function handleCloseRedeemTicket() {
    void closeRedeemTicketModal();
    handleCloseSearchTicket();

    // Reload the tee times
    void dispatch(selectTeeTime(parseInt(teetimeId), true));
  }

  function handleTicketSearch() {
    // void closeRedeemTicketModal();
    // handleCloseSearchTicket();

    updateState<ICheckInState>({ redeemTicketActive: true, ticketRedeemSuccess: false }, StateType.CheckInState);
  }

  function handleEnterKeydown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      void addTicket();
    }
  }

  function addTicket() {
    if (ticketState.ticketString) {
      const updatedTickets = [...ticketState.tickets];
      const productIncremented = incrementProductQuantity(ticketState.ticketString);
      if (!productIncremented) {
        const foundSkuIndex = updatedTickets?.findIndex(skuObj => skuObj.sku === ticketState.ticketString);
        if (foundSkuIndex !== -1) {
          //Increment quantity of sku found
          const foundSku = updatedTickets[foundSkuIndex];
          updatedTickets.splice(foundSkuIndex, 1, { ...foundSku, quantity: ++foundSku.quantity });
        } else {
          //Add new sku
          const newSku: TSku = { sku: ticketState.ticketString, quantity: 1, searched: false };
          updatedTickets.push(newSku);
        }
        setTicketState(prevState => ({ ...prevState, tickets: updatedTickets }));
      } else {
        setTicketState(prevState => ({ ...prevState, ticketString: "" }));
      }
    }
  }

  function incrementProductQuantity(currentSku: string) {
    const tickets = [...checkInState?.selectedTickets];
    const productInList = false;
    const variantIndex: number = null;
    const ticketIndex = tickets?.findIndex(ticket => {
      // const foundTicketIndex = product?.variants?.findIndex(
      //   variant => variant?.sku === currentSku || variant?.barcode === currentSku,
      // );
      // if (foundVariantIndex !== -1) {
      //   variantIndex = foundVariantIndex;
      //   return true;
      // }
    });
    // if (productIndex !== -1 && variantIndex !== -1) {
    //   // Product already in list, increment the quantity
    //   const foundVariant = products[productIndex]?.variants[variantIndex];
    //   products[productIndex].variants[variantIndex] = { ...foundVariant, quantity: ++foundVariant.quantity };
    //   productInList = true;
    //   setProductState(prevState => ({ ...prevState, selectedProducts: products }));
    // }
    return productInList;
  }

  function handleEnterClick() {
    const skuSearchId = document.getElementById("ticketSearchId");
    skuSearchId.focus();
    void addTicket();
  }

  //For searching tickets by sku
  useEffect(() => {
    const source = axios.CancelToken.source();
    const currentTicket = ticketState?.ticketString?.slice();
    const skuIndex = ticketState?.tickets?.findIndex(sku => sku.sku === currentTicket);
    // setTicketState((prevState) => ({ ...prevState, ticketString: "" }));
    if (skuIndex !== -1) {
      const foundSku = ticketState.tickets[skuIndex];
      // First sku added, search to see if ticket exists
      if (foundSku.quantity === 1 || !foundSku?.searched) {
        setTicketState(prevState => ({ ...prevState, ticketString: "" }));
        void validateTicket(currentTicket);
      }
    }
    return () => {
      source.cancel();
    };
  }, [ticketState?.tickets]);

  async function validateTicket(code: string) {
    const source = axios.CancelToken.source();

    const updatedTickets = cloneDeep(ticketState.tickets);
    //Filter out skus that have already been searched
    const skus = updatedTickets?.filter(filteredSku => !filteredSku?.searched)?.map(sku => sku.sku);
    if (skus.length === 0) {
      console.log("Ticket already searched");
      return;
    }

    const getTicketValidateRes = await GetTicketValidate({ code }, false);

    if (getTicketValidateRes.status !== StatusCode.OK) {
      if (source.token?.reason) {
        return;
      }
    } else if (getTicketValidateRes.data) {
      const ticketStub = getTicketValidateRes?.data?.stub;

      const foundTicketIndex = updatedTickets?.findIndex(sku => sku.sku === ticketStub?.code);

      if (foundTicketIndex !== -1) {
        updatedTickets.splice(foundTicketIndex, 1);
      }

      const updatedSelectedTickets = [...checkInState.selectedTickets];
      const selectedTicketIndex = updatedSelectedTickets?.findIndex(
        currentTicket => currentTicket.id === ticketStub.id,
      );
      if (selectedTicketIndex !== -1) {
        // Ticket already in state
      } else {
        //Product not in state, add it to the array
        updatedSelectedTickets.push(ticketStub);
      }

      setCheckInState(prevState => ({ ...prevState, selectedTickets: updatedSelectedTickets }));
    }

    updatedTickets?.forEach((sku, index) => {
      updatedTickets[index] = { ...updatedTickets[index], searched: true };
    });

    setTicketState(prevState => ({ ...prevState, tickets: updatedTickets }));
  }

  function removeTicketSku(index: number) {
    const updatedSkus = [...ticketState.tickets];
    // Sku cannot be removed if search is in progress
    const allSkusSearched = updatedSkus?.every(sku => sku.searched);
    if (updatedSkus[index] && allSkusSearched) {
      updatedSkus.splice(index, 1);
      setTicketState(prevState => ({ ...prevState, tickets: updatedSkus }));
    }
  }

  function removeTicket(ticketIndex: number) {
    const updatedTickets = [...checkInState?.selectedTickets];
    updatedTickets.splice(ticketIndex, 1);

    setCheckInState(prevState => ({
      ...prevState,
      selectedTickets: updatedTickets,
    }));
  }

  function getStubStatus(stub: IStub) {
    switch (stub.status) {
      case "valid":
        return (
          <OverlayTrigger placement="auto" overlay={<Tooltip id="headingTooltip">Ticket validated</Tooltip>}>
            <FontAwesomeIcon className="inventory-counts-success" size="1x" icon={["fas", "circle-check"]} />
          </OverlayTrigger>
        );
      case "redeemed":
        return (
          <OverlayTrigger
            placement="auto"
            overlay={<Tooltip id="headingTooltip">Ticket has already been redeemed</Tooltip>}
          >
            <FontAwesomeIcon className="inventory-counts-error" size="1x" icon={["fas", "circle-xmark"]} />
          </OverlayTrigger>
        );
      case "void":
        return (
          <OverlayTrigger placement="auto" overlay={<Tooltip id="headingTooltip">Ticket has been voided</Tooltip>}>
            <FontAwesomeIcon className="inventory-counts-error" size="1x" icon={["fas", "circle-xmark"]} />
          </OverlayTrigger>
        );
      case "expired":
        return (
          <OverlayTrigger placement="auto" overlay={<Tooltip id="headingTooltip">Ticket is expired</Tooltip>}>
            <FontAwesomeIcon className="inventory-counts-error" size="1x" icon={["fas", "circle-xmark"]} />
          </OverlayTrigger>
        );
    }
  }

  return (
    <>
      <div className="flex">
        <div className="booking-details-container">
          <div className="mt-23 flex justify-between">
            <Button type="text" className="ml-2" onClick={() => history.push(`/admin/teesheet`)}>
              <FontAwesomeIcon icon={["fal", "long-arrow-left"]} className="mr-1" />
              {t("secure.facility.tee_sheet.tee_sheet_booking_details.042")}
            </Button>

            {!moment(teeSheetStore.selectedTeeTime?.date).isSameOrBefore(moment(), "date") ? (
              <div className="mr-4 h-12">
                <Callout type="warning" title="Caution: Checking in future tee time" content="" />
              </div>
            ) : null}
          </div>

          <div className="mx-4 flex justify-between mt-10">
            <div className="flex flex-column justify-center">
              <span className="text-semibold display-xs">
                {teeSheetStore.selectedTeeTime?.start_time
                  ? convertTime(teeSheetStore.selectedTeeTime?.start_time)
                  : null}
              </span>

              <span className="text-primary-grey text-m">
                {moment(`${teeSheetStore.selectedTeeTime?.date}`).format("MMMM Do, YYYY")}
              </span>

              <span className="text-primary-grey text-m">
                {teeSheetStore.selectedTeeTime?.division_title}
                <FontAwesomeIcon icon={["far", "long-arrow-right"]} className="mr-1 ml-1" />
                {teeSheetStore.selectedTeeTime?.turn_division_title}
              </span>
            </div>
            <ButtonGroup>
              <NavigationDropdownNew
                label={"Options"}
                rightAlign
                sections={[
                  [
                    {
                      label: t("secure.facility.tee_sheet.tee_sheet_booking_details.043"),
                      onClick: () => updateState<IRainCheckState>({ rainCheckActive: true }, StateType.RainCheckState),
                      icon: "umbrella",
                      disabled: !!checkInState.order,
                    },
                    {
                      label: t("secure.facility.tee_sheet.tee_sheet_booking_details.044"),
                      onClick: () =>
                        updateState<IRainCheckState>({ redeemRainCheckActive: true }, StateType.RainCheckState),
                      icon: "ticket",
                      disabled: !!checkInState.order,
                    },
                  ],
                  [
                    {
                      label: t("secure.facility.tee_sheet.tee_sheet_booking_details.045"),
                      onClick: () => handleOpenGroupPay(),
                      icon: "user-group",
                      disabled: !!checkInState.order,
                    },
                  ],
                  [
                    {
                      label: "Power Cart Numbers",
                      onClick: () => togglePowerCartVisible(true),
                      icon: "cars",
                      disabled: !!checkInState.order,
                    },
                  ],
                  [
                    {
                      label: "Add All Green Fees", //TODO: translate
                      onClick: () => handleAddAllFees(teeSheetStore.selectedTeeTime.slots, "green_fees"),
                      icon: "dollar-sign",
                      disabled: !!checkInState.order,
                    },
                    {
                      label: "Add All Carts", //TODO: Translation
                      onClick: () => handleAddAllFees(teeSheetStore.selectedTeeTime.slots, "power_carts"),
                      icon: "car-side",
                      disabled: !!checkInState.order,
                    },
                  ],
                ]}
              />
            </ButtonGroup>
          </div>

          <TeeSheetBookingDisplay
            teeSheetStore={teeSheetStore as ITeeSheetState}
            facilityStore={facilityStore}
            addItemState={addItemState}
            addingFee={addingFee}
            initiateEditBookingHandler={initiateEditBookingModal}
            checkInHandler={slot =>
              updateState<ICheckInState>(
                { manualCheckInVisible: true, manualCheckInSlot: slot },
                StateType.CheckInState,
              )
            }
            noShowBookingHandler={handleNoShowBookingParticipant}
            chargeNoShowHandler={handleChargeNoShowModal}
            showChangePlayerHandler={handleShowChangePlayer}
            cancelBookingParticipantHandler={handleCancelBookingParticipant}
            loadCartWaiverHandler={loadCartWaiver}
            initiateRedeemTicketHandler={handleInitiateRedeemTicket}
            navigateToOrderHandler={id => history.push("/admin/order/" + String(id))}
            initiateAddFeeHandler={handleInitiateAddFee}
            navigateToCustomerDetails={id => history.push("/admin/customers/" + String(id) + "/profile")}
            addFeeHandler={handleAddFee}
            authStore={authStore}
            order={checkInState.order}
            powerCartModalVisible={state.powerCartModalVisible}
            togglePowerCartModalVisible={togglePowerCartVisible}
          />
        </div>

        {renderContext()}
      </div>

      <BookingModalEdit selectedBooking={state.selectedBooking} teeTimeLock={state.teeTimeLock} />

      <Sheet
        open={addItemState.greenFeeActive}
        size="medium"
        closable
        title={t("secure.facility.tee_sheet.tee_sheet_booking_details.046")}
        onCancel={onCancelAddGreenFeeModal}
        // onOk={() => cancelAddItem()}

        cancelText={t("secure.facility.tee_sheet.tee_sheet_booking_details.193")}
        okText={t("secure.facility.tee_sheet.tee_sheet_booking_details.140")}
      >
        <Input
          type="search"
          onChange={filterGreenFees}
          placeholder={t("secure.facility.tee_sheet.tee_sheet_booking_details.048")}
          value={addItemState.greenfeeSearchQuery}
          autoComplete="off"
        />
        <div className="overflow-auto mt-4">
          <Products
            products={alphaSortProducts(
              foundGreenFees.length > 0
                ? foundGreenFees
                : addItemState.itemSearchResult.length > 0
                ? addItemState.itemSearchResult
                : [],
            )}
            useGridPositions={false}
            productState={addItemState.productState}
            onClick={product => onClickProduct(product, "Green Fee")}
            onCancel={onCancelAddVariantModal}
            onConfirmVariant={variant => handleAddQuickItem(variant.id, "Green Fee")}
          />
        </div>
      </Sheet>

      <StripeTerminalSheet />

      <Sheet
        open={addItemState.powerCartFeeActive}
        size="medium"
        closable
        title={t("secure.facility.tee_sheet.tee_sheet_booking_details.049")}
        onCancel={onCancelAddPowerCartModal}
        // onOk={() => cancelAddItem()}
        cancelText={t("secure.facility.tee_sheet.tee_sheet_booking_details.193")}
        okText={t("secure.facility.tee_sheet.tee_sheet_booking_details.051")}
      >
        <Input
          type="search"
          onChange={filterCartItems}
          placeholder={t("secure.facility.tee_sheet.tee_sheet_booking_details.052")}
          value={addItemState.cartSearchQuery}
          autoComplete="off"
        />
        <div className="overflow-auto mt-4">
          <Products
            products={alphaSortProducts(
              foundCartItems.length > 0
                ? foundCartItems
                : addItemState.itemSearchResult.length > 0
                ? addItemState.itemSearchResult
                : [],
            )}
            useGridPositions={false}
            productState={addItemState.productState}
            onClick={product => onClickProduct(product, "Power Cart")}
            onCancel={onCancelAddVariantModal}
            onConfirmVariant={variant => handleAddQuickItem(variant.id, "Power Cart")}
          />
        </div>
      </Sheet>

      <AddItemSheet
        title={t("secure.facility.tee_sheet.tee_sheet_booking_details.053")}
        open={addItemState.addItemActive}
        onCancel={() => updateState<IAddItemState>({ addItemActive: false }, StateType.AddItemState)}
        onOk={() => updateState<IAddItemState>({ addItemActive: false }, StateType.AddItemState)}
        okText={t("secure.facility.tee_sheet.tee_sheet_booking_details.054")}
        cart={checkInState.cart}
        updateCart={updatedCart => updateState<ICheckInState>({ cart: updatedCart }, StateType.CheckInState)}
        cartStore={cartStore}
      />

      <Sheet
        open={noShow.noShowActive}
        size="medium"
        closable
        title={t("secure.facility.tee_sheet.tee_sheet_booking_details.055")}
        onCancel={handleChargeNoShowModalClose}
        onOk={handleNoShowCharge}
        cancelText={t("secure.facility.tee_sheet.tee_sheet_booking_details.056")}
        okText={t("secure.facility.tee_sheet.tee_sheet_booking_details.057")}
        okDisabled={
          noShow.paymentMethodID === null || !noShow.greenFeeProductID || noShow.quantity === 0 ? true : false
        }
        overflow
      >
        <div className="h-full flex flex-col">
          <DataTable
            columns={[
              { label: t("secure.facility.tee_sheet.tee_sheet_booking_details.058"), width: "5%" },
              { label: t("secure.facility.tee_sheet.tee_sheet_booking_details.059"), width: "95%" },
            ]}
          >
            {teeSheetStore.selectedTeeTime?.slots
              ?.filter(
                (filteredSlot: ISlot) =>
                  filteredSlot?.booking_participant?.no_show === true &&
                  filteredSlot?.booking_participant.tee_time_booking_id === noShow.teeTimeBookingId &&
                  !filteredSlot?.booking_participant?.no_show_paid,
              )
              .map((slot: ISlot) => {
                return (
                  <tr
                    key={slot.id}
                    className="clickable"
                    onClick={() => handleNoShowCheckbox(slot.booking_participant?.id)}
                  >
                    <td>
                      <Checkbox
                        size={"medium"}
                        checked={noShow.bookingParticipantIds.some(id => id === slot?.booking_participant?.id)}
                        onChange={e => e.stopPropagation()}
                      />
                    </td>
                    <td>{slot.booking_participant.full_name}</td>
                  </tr>
                );
              })}
          </DataTable>

          <div className="mt-4">
            <Select
              label={t("secure.facility.tee_sheet.tee_sheet_booking_details.060")}
              onChange={(value: any, extraValues: any) => handleNoShowDropDownChange(value, extraValues)}
              defaultValue={noShow.greenFeeProductID}
            >
              {noShow.greenFeeProducts.length >= 1 &&
                noShow.greenFeeProducts?.map(item => {
                  const productTitle = item?.title;
                  const preferredTitle = item?.preferred_title;
                  return (
                    item.variants &&
                    item.variants
                      .filter(variantFiltered => variantFiltered.price > 0)
                      .map((variant, i) => {
                        return (
                          <Option
                            key={i}
                            value={variant.id}
                            name={variant.title}
                            extraValues={{ title: variant.title, price: variant.price }}
                          >
                            <div>
                              <span>{preferredTitle ? preferredTitle : productTitle} </span>
                              {productTitle !== variant.title && (
                                <span className="text-subdued text-sm">{variant.title}</span>
                              )}
                              <span>
                                {" "}
                                - <LocaleCurrency currency="cad" amount={variant.price} />
                              </span>
                            </div>
                          </Option>
                        );
                      })
                  );
                })}
            </Select>
          </div>
          <div className="mt-auto text-lg">
            <ul>
              <li className="flex justify-end">
                <span className="mr-4 text-medium">{noShow.greenFeeProductTitle}</span>
                <LocaleCurrency currency="cad" amount={noShow.greenFeeProductPrice ?? 0} />
              </li>
              <li className="flex justify-end">
                <span className="mr-4 text-medium">{t("secure.facility.tee_sheet.tee_sheet_booking_details.063")}</span>
                <span>{noShow.quantity}</span>
              </li>
              <li className="flex justify-end text-bold">
                <span className="mr-4">{t("secure.facility.tee_sheet.tee_sheet_booking_details.064")}</span>
                <LocaleCurrency currency="cad" amount={noShow.quantity * noShow.greenFeeProductPrice} />
              </li>
            </ul>
          </div>
        </div>
      </Sheet>

      <Popup
        open={noShow.noPaymentMethodModal}
        type="info"
        title={t("secure.facility.tee_sheet.tee_sheet_booking_details.061")}
        description={t("secure.facility.tee_sheet.tee_sheet_booking_details.062")}
        onOk={() => updateState<INoShowState>({ noPaymentMethodModal: false }, StateType.NoShowState)}
      />

      <Popup
        open={checkInState.manualCheckInVisible}
        closable
        type="info"
        title={t("secure.facility.tee_sheet.tee_sheet_booking_details.174")}
        description={t("secure.facility.tee_sheet.tee_sheet_booking_details.175")}
        onOk={handleManualCheckIn}
        onCancel={() =>
          updateState<ICheckInState>({ manualCheckInVisible: false, manualCheckInSlot: null }, StateType.CheckInState)
        }
      />

      <Sheet
        open={rainCheckState.rainCheckActive}
        size="small"
        closable
        title={t("secure.facility.tee_sheet.tee_sheet_booking_details.065")}
        onCancel={cancelRainCheck}
        onOk={issueRainCheck}
        cancelText={!rainCheckState.continueBtn ? t("secure.facility.tee_sheet.tee_sheet_booking_details.066") : "Back"}
        okText={!rainCheckState.continueBtn ? "Continue" : t("secure.facility.tee_sheet.tee_sheet_booking_details.067")}
        okDisabled={rainCheckState.selectedBookingParticipantId === null || rainCheckState.okDisabled ? true : false}
      >
        <div className="h-full">
          {!rainCheckState.continueBtn ? (
            <div>
              {teeSheetStore.selectedTeeTime?.slots?.some(
                slot =>
                  slot?.booking_participant?.check_in_status === "checked" &&
                  slot?.booking_participant?.order_financial_status === "paid",
              ) ? (
                <DataTable
                  columns={[
                    { label: t("secure.facility.tee_sheet.tee_sheet_booking_details.068"), width: "5%" },
                    { label: t("secure.facility.tee_sheet.tee_sheet_booking_details.069"), width: "95%" },
                  ]}
                >
                  {teeSheetStore.selectedTeeTime?.slots
                    ?.filter(
                      filteredSlot =>
                        filteredSlot?.booking_participant?.check_in_status === "checked" &&
                        filteredSlot?.booking_participant?.order_financial_status === "paid",
                    )
                    .map((slot, index) => {
                      return (
                        <tr key={slot.id} className="clickable" onClick={() => handleRainCheckbox(index, slot)}>
                          <td>
                            <Checkbox
                              size={"small"}
                              checked={rainCheckState.checkBoxes[index]}
                              onChange={e => e.stopPropagation()}
                            />
                          </td>
                          <td> {slot?.booking_participant?.full_name} </td>
                        </tr>
                      );
                    })}
                </DataTable>
              ) : (
                <Callout
                  type="info"
                  title="No Paid Fees" // TODO: Translation
                  content="Complete a check in on this booking to create a rain check" // TODO: Translation
                />
              )}
            </div>
          ) : (
            <>
              <p className="issue-raincheck-amount">
                {t("secure.facility.tee_sheet.tee_sheet_booking_details.072")}
                <LocaleCurrency
                  style={{ fontSize: "1.2rem" }}
                  currency="cad"
                  amount={typeof rainCheckState.calculate === "object" ? 0 : rainCheckState.calculate ?? 0}
                />
              </p>

              <div className="mb-4">
                <Input
                  id="completedHoles"
                  value={rainCheckState.completedHoles}
                  type="number"
                  label={t("secure.facility.tee_sheet.tee_sheet_booking_details.070")}
                  suffix={"/ " + String(rainCheckState.holes) + " holes"}
                  onChange={handleRainCheckHolesInput}
                />
              </div>

              <div className="mb-4">
                <Input
                  id="amount"
                  label={t("secure.facility.tee_sheet.tee_sheet_booking_details.071")}
                  type="number"
                  onChange={handleRainCheckAmountInput}
                />
              </div>
            </>
          )}
        </div>
      </Sheet>

      <RainCheckCompletePopup
        open={rainCheckState.rainCheckCompleteActive}
        setClose={() => setRainCheckState(prevState => ({ ...prevState, rainCheckCompleteActive: false }))}
        rainCheck={rainCheckState.rainCheck}
        bookingParticipant={rainCheckState?.selectedBookingParticipant}
      />

      <ChangeCustomer
        title="Change Player"
        currentCustomerTitle="Previous Golfer"
        newCustomerTitle="New Golfer"
        okText={t("secure.facility.tee_sheet.tee_sheet_booking_details.077")}
        open={changePlayerState.showChangePlayer}
        changeCustomerState={changePlayerState}
        searching={changePlayerState.customerSearching}
        searchResults={changePlayerState.playerSearchResult}
        newCustomerActive={changePlayerState.showNewPlayer}
        onCancel={closeChangePlayer}
        onOk={changeSelectedPlayer}
        handleSearchQueryChange={changePlayerHandleCustomerSearch}
        handleCustomerSelection={handleChangePlayerSelection}
        handleRemoveSelectedCustomer={handleRemoveSelectedGolfer}
        handleCreateNewCustomer={createNewCustomer}
        handleCloseCreateNewCustomer={closeCreateNewCustomer}
        handleNewCustomerActive={() => setChangePlayerState(prevState => ({ ...prevState, showNewPlayer: true }))}
      />

      <Sheet
        open={rainCheckState.redeemRainCheckActive}
        size="small"
        closable
        title={t("secure.facility.tee_sheet.tee_sheet_booking_details.088")}
        onCancel={closeRedeemRainCheck}
        onOk={handleRedeemRainCheck}
        cancelText={t("secure.facility.tee_sheet.tee_sheet_booking_details.089")}
        okText={t("secure.facility.tee_sheet.tee_sheet_booking_details.090")}
        okDisabled={rainCheckState.redeemRainCheckCode === "" || !checkInState.cart?.id}
      >
        {checkInState.cart?.id ? (
          <Input
            label={t("secure.facility.tee_sheet.tee_sheet_booking_details.091")}
            value={rainCheckState.redeemRainCheckCode}
            id="redeemRainCheckCode"
            placeholder={t("secure.facility.tee_sheet.tee_sheet_booking_details.092")}
            onChange={handleRedeemRainCheckChange}
          />
        ) : (
          <Callout
            title={t("secure.facility.tee_sheet.tee_sheet_booking_details.093")}
            content={t("secure.facility.tee_sheet.tee_sheet_booking_details.094")}
            type="info"
          />
        )}
      </Sheet>

      <GiftCardRedeem
        redeemGiftCardsActive={checkInState.giftCardActive}
        onOk={handleProcessPayment}
        onCancel={() => updateState<ICheckInState>({ giftCardActive: false }, StateType.CheckInState)}
        cart={checkInState.cart}
      />

      <GiftCardSell
        gift_cards={state.gift_cards}
        sellGiftCardsActive={state.sellGiftCardsActive}
        onOk={handleAddSellGiftCards}
        onCancel={cancelGiftCardsAndLeagues}
      />

      <LeagueSelectionModal
        open={state.leagueFeesActive}
        leagueFeeProducts={state.leagueFeeProducts}
        onCancel={cancelGiftCardsAndLeagues}
        onOk={setSelectedLeagues}
      />

      <Portal isMounted={checkInState.redeemTicketActive}>
        <Sheet
          title={t("secure.facility.tee_sheet.tee_sheet_booking_details.099")}
          open={checkInState.redeemTicketActive}
          size="medium"
          closable
          onCancel={handleCloseSearchTicket}
          onOk={handleSearchTicket}
          okText={"Redeem"}
          // okText={t("secure.facility.tee_sheet.tee_sheet_booking_details.100")}
          okDisabled={checkInState.selectedTickets.length <= 0}
        >
          <Tabs tabs={tabs} selected={checkInState.redeemTicketSelected} onSelect={handleSearchTicketTabChange}>
            {checkInState.redeemTicketSelected === 0 && (
              <>
                <FormLayout>
                  <FormLayout.Group>
                    <Input
                      id="ticketSearchId"
                      onKeyDown={handleEnterKeydown}
                      value={ticketState.ticketString}
                      placeholder="Scan Ticket / Barcode"
                      onChange={e => setTicketState(prevState => ({ ...prevState, ticketString: e.target.value }))}
                      trailingButtons={[
                        <Button key={1} onClick={handleEnterClick} type="secondary">
                          Enter
                        </Button>,
                      ]}
                    />
                  </FormLayout.Group>
                </FormLayout>

                <DataTable
                  columns={[
                    ...(checkInState?.selectedTickets?.length > 0 ? [{ label: "Title" }] : []),
                    ...(checkInState?.selectedTickets?.length > 0
                      ? [{ label: "Ticket #" }, { label: "Barcode" }]
                      : [{ label: "Ticket # / Barcode" }]),
                    {
                      label: "Quantity",
                    },
                    { label: "" },
                    { label: "" },
                  ]}
                >
                  {ticketState?.tickets?.map((sku, index) => {
                    return (
                      <tr key={index}>
                        {checkInState?.selectedTickets?.length > 0 && (
                          <>
                            <td></td>

                            <td>{sku?.sku}</td>
                          </>
                        )}
                        <td>{sku?.sku}</td>
                        <td>{sku?.quantity}</td>
                        <td className="text-right">
                          {!sku.searched ? (
                            <div style={{ display: "inline-flex" }}>
                              <Spin />
                            </div>
                          ) : (
                            <OverlayTrigger
                              placement="auto"
                              overlay={<Tooltip id="headingTooltip">Invalid Ticket</Tooltip>}
                            >
                              <FontAwesomeIcon
                                className="inventory-counts-error"
                                size="1x"
                                icon={["fas", "circle-xmark"]}
                                // onClick={() => setVariantNotFoundState({ modalOpen: true, sku: sku.sku })}
                              />
                            </OverlayTrigger>
                          )}
                        </td>
                        <td className="text-right">
                          <FontAwesomeIcon
                            onClick={() => removeTicketSku(index)}
                            icon={["far", "trash-can-xmark"]}
                            size="1x"
                            className="cursor-pointer"
                          />
                        </td>
                      </tr>
                    );
                  })}

                  {checkInState?.selectedTickets?.map((ticketStub, ticketIndex) => {
                    return (
                      <React.Fragment key={ticketIndex}>
                        <tr className="inventory-counts-product-header">
                          <td>{ticketStub.ticket.title}</td>
                          <td>{ticketStub.code}</td>
                          <td>{ticketStub.code}</td>

                          <td onClick={e => e.stopPropagation()}>1</td>
                          <td className="text-right">{getStubStatus(ticketStub)}</td>
                          <td className="text-right">
                            <FontAwesomeIcon
                              onClick={() => removeTicket(ticketIndex)}
                              icon={["far", "trash-can-xmark"]}
                              size="1x"
                              className="cursor-pointer"
                            />
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </DataTable>
              </>
            )}

            {checkInState.redeemTicketSelected === 1 && (
              <>
                <DataTable className="mt-3" columns={[{ label: "Title" }, { label: "Expires" }, { label: "" }]}>
                  {checkInState.availableTickets.map((ticketStub: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td style={{ padding: "8px 24px" }}>
                          <p className="text-xs">
                            {ticketStub.ticket?.title}{" "}
                            {ticketStub.ticket?.subtitle ? "- " + String(ticketStub.ticket.subtitle) : null}
                          </p>

                          <p className="text-xs">{ticketStub.ticket?.included}</p>
                          <p className="text-xs">{ticketStub.code}</p>
                        </td>

                        <td>
                          <p className="text-xs">{ticketStub.expiry_date ? ticketStub.expiry_date : "No Expiry"}</p>
                        </td>

                        <td className="text-xs">
                          {ticketStub.status === "valid" ? (
                            <Button type="link" size="xsmall" onClick={() => handleTicketRedeem(ticketStub.code)}>
                              {t("secure.facility.tee_sheet.tee_sheet_booking_details.102")}
                            </Button>
                          ) : null}
                        </td>
                      </tr>
                    );
                  })}
                </DataTable>
              </>
            )}
          </Tabs>
        </Sheet>
      </Portal>

      {/* Manual Credit Card Sheet*/}
      <Sheet
        size={"small"}
        closable
        title={t("secure.facility.tee_sheet.tee_sheet_booking_details.103")}
        cancelText={t("secure.facility.tee_sheet.tee_sheet_booking_details.104")}
        open={manualStripeState.visible}
        okText={t("secure.facility.tee_sheet.tee_sheet_booking_details.105")}
        okDisabled={!elementsComplete}
        cancelDisabled={manualProcessing}
        backDropCancel={false}
        onOk={() => {
          if (!submitTrigger) {
            setSubmitTrigger(!submitTrigger);
          }
        }}
        onCancel={async () => {
          await cancelManualCreditPaymentIntent(
            manualStripeState?.transaction?.payment_intent_id,
            manualStripeState?.transaction?.id,
          );
          if (elements) {
            elements.getElement(CardNumberElement).clear();
            elements.getElement(CardExpiryElement).clear();
            elements.getElement(CardCvcElement).clear();
          }
          setManualStripeState(prevState => ({
            ...prevState,
            visible: false,
            transaction: {},
          }));
        }}
      >
        {manualStripeState.visible && (
          <CheckoutForm
            onChange={handleCardSectionChange}
            onSubmit={() => setManualProcessing(prev => !prev)}
            trigger={submitTrigger}
            transaction={manualStripeState.transaction}
            onError={(message: string) => {
              setSubmitTrigger(false);
              setManualProcessing(false);
              dispatch(showError(message));
            }}
            onSuccess={async () => {
              // Clean values
              dispatch(showSuccess(t("secure.facility.tee_sheet.tee_sheet_booking_details.106")));
              setManualStripeState(prevState => ({
                ...prevState,
                visible: false,
                transaction: {},
                elementComplete: {
                  cardCvc: false,
                  cardExpiry: false,
                  cardNumber: false,
                },
              }));
              setSubmitTrigger(false);
              updateOrderCompletePopup({ isOpen: true, loading: true, transactionComplete: true });
              await updateCartOrder(checkInState.order.id);
            }}
          />
        )}
      </Sheet>

      <Popup
        open={state.clearCartActive}
        type="warning"
        title={t("secure.facility.tee_sheet.tee_sheet_booking_details.154")}
        description={t("secure.facility.tee_sheet.tee_sheet_booking_details.155")}
        onOk={handleDeleteOrder}
        okText={t("secure.facility.tee_sheet.tee_sheet_booking_details.156")}
        cancelText={t("secure.facility.tee_sheet.tee_sheet_booking_details.157")}
        onCancel={() => {
          setState(prevState => ({ ...prevState, clearCartActive: false }));
        }}
        backDropCancel={true}
      />

      <Popup
        open={checkInState.ticketValidSuccess}
        type={checkInState.getTicketValidateData?.stub?.status !== "valid" ? "info" : "success"}
        title={
          checkInState.getTicketValidateData?.stub?.status === "redeemed"
            ? t("secure.facility.tee_sheet.tee_sheet_booking_details.163")
            : checkInState.getTicketValidateData?.stub?.status === "expired"
            ? t("secure.facility.tee_sheet.tee_sheet_booking_details.164")
            : checkInState.getTicketValidateData?.stub?.status === "void"
            ? t("secure.facility.tee_sheet.tee_sheet_booking_details.165")
            : t("secure.facility.tee_sheet.tee_sheet_booking_details.108")
        }
        description={
          checkInState.getTicketValidateData?.stub?.status === "redeemed"
            ? t("secure.facility.tee_sheet.tee_sheet_booking_details.166")
            : checkInState.getTicketValidateData?.stub?.status === "expired"
            ? t("secure.facility.tee_sheet.tee_sheet_booking_details.167")
            : checkInState.getTicketValidateData?.stub?.status === "void"
            ? t("secure.facility.tee_sheet.tee_sheet_booking_details.168")
            : t("secure.facility.tee_sheet.tee_sheet_booking_details.108")
        }
        onOk={handleTicketValidSuccess}
        okText="Redeem"
        okDisabled={checkInState.getTicketValidateData?.stub?.status !== "valid" ? true : false}
        onCancel={() =>
          updateState<ICheckInState>(
            {
              ticketValidSuccess: false,
              redeem_ticket_code: "",
              getTicketValidateData: {},
            },
            StateType.CheckInState,
          )
        }
      >
        <p>{checkInState.getTicketValidateData.stub?.ticket?.title}</p>
        <p>{checkInState.getTicketValidateData.stub?.ticket?.included}</p>
        <p>
          {checkInState.getTicketValidateData.stub?.expiry_date ? (
            <span>
              {t("secure.facility.tee_sheet.tee_sheet_booking_details.109")}{" "}
              {checkInState.getTicketValidateData.stub?.expiry_date}
            </span>
          ) : (
            <span>{t("secure.facility.tee_sheet.tee_sheet_booking_details.110")}</span>
          )}
        </p>
        {checkInState.getTicketValidateData.stub?.description && (
          <Markdown markdownText={checkInState.getTicketValidateData.stub.description} />
        )}
      </Popup>

      <Popup
        open={checkInState.ticketValidWarning}
        type="warning"
        title={t("secure.facility.tee_sheet.tee_sheet_booking_details.111")}
        description=" "
        onOk={handleTicketValidWarning}
      />

      {/* Tee time cancelled pop up */}
      <Popup
        open={state.teeTimeCancelledPopupVisible}
        type="warning"
        title={t("secure.facility.tee_sheet.tee_sheet_booking_details.112")}
        description={t("secure.facility.tee_sheet.tee_sheet_booking_details.113")}
        onOk={handleTeeTimeCancelledPopupRefresh}
        okText={t("secure.facility.tee_sheet.tee_sheet_booking_details.114")}
        onCancel={handleTeeTimeCancelledPopupRefresh}
        backDropCancel={false}
      />

      {/* Payment Handler Sheet - Handles successes/errors */}
      <>
        {stripePaymentState.processing ? (
          <Popup
            type="info"
            open={stripePaymentState.sheetVisible}
            title={t("secure.facility.tee_sheet.tee_sheet_booking_details.115")}
            description={t("secure.facility.tee_sheet.tee_sheet_booking_details.116")}
            closable
            onCancel={stripePaymentState.onCancel}
            onOk={stripePaymentState.onOk}
            cancelText={stripePaymentState.cancelText}
            okText={stripePaymentState.okText}
            backDropCancel={false}
          />
        ) : (
          <>
            {stripePaymentState.success ? (
              <Popup
                type="success"
                open={stripePaymentState.sheetVisible}
                title={t("secure.facility.tee_sheet.tee_sheet_booking_details.117")}
                description={t("secure.facility.tee_sheet.tee_sheet_booking_details.118")}
                closable
                onCancel={stripePaymentState.onCancel}
                onOk={stripePaymentState.onOk}
                cancelText={stripePaymentState.cancelText}
                okText={stripePaymentState.okText}
                backDropCancel={false}
              />
            ) : (
              <Popup
                type="error"
                open={stripePaymentState.sheetVisible}
                title={t("secure.facility.tee_sheet.tee_sheet_booking_details.119")}
                description={
                  stripePaymentState.message +
                  t("secure.facility.tee_sheet.tee_sheet_booking_details.120") +
                  stripePaymentState.convertedCode
                }
                closable
                onCancel={stripePaymentState.onCancel}
                onOk={stripePaymentState.onOk}
                cancelText={stripePaymentState.cancelText}
                okText={stripePaymentState.okText}
                backDropCancel={false}
              />
            )}
          </>
        )}
      </>

      {/** Sheet to handle line-item quantity */}
      {lineItemState.lineItem && (
        <UpdateLineItemModal
          cart={checkInState.cart}
          lineItemState={lineItemState}
          setLineItemState={setLineItemState}
          permissions={permissions}
          discounts={facilityStore.discountOptions}
          dataOrigin="state"
          modalActions={{
            onCancel: () => setLineItemState({ ...lineItemState, lineItem: null, isModalVisible: false }),
            onOk: props => handleUpdateLineItem(props),
            removeLineItem: cartDataRes => handleRemoveLineItem2(cartDataRes.updatedCart),
            applyLineItemDiscountRes: postDiscountRes =>
              updateState<ICheckInState>({ cart: postDiscountRes.updatedCart }, StateType.CheckInState),
          }}
        />
      )}

      <AccountsModal
        loadAccounts={checkInState.accountsVisible}
        onCancel={closeAccountsModal}
        onOk={handleAccountsOk}
        customerId={checkInState?.cart?.customer_id}
        searchAccountPermission={permissions?.accounts_charge_all}
      />

      <Portal isMounted={modalCreditBook.isOpen}>
        <CreditBookModal
          loadCreditBooks={modalCreditBook.isOpen}
          onCancel={() => closeCreditBook()}
          onOk={(selectedCreditBook: IPrizeAccount) =>
            updateCreditBook({ selectedCreditBook: { ...selectedCreditBook } })
          }
          customerId={checkInState?.cart?.customer_id}
          // searchAccountPermission={permissions?.credit}
        />
      </Portal>

      <Sheet
        title={t("secure.facility.tee_sheet.tee_sheet_booking_details.178")}
        open={checkInState.roomChargeSheetVisible}
        size="small"
        closable
        onCancel={closeRoomChargeSheet}
        onOk={handleProcessPayment}
        okDisabled={isEmpty(checkInState.selectedOccupant?.guest_name)}
        okText={t("secure.facility.tee_sheet.tee_sheet_booking_details.176")}
        stacked
      >
        <Input
          label="Room Guest"
          type="search"
          onChange={handleOccupantSearchQueryChange}
          value={checkInState.roomOccupantSearchQuery}
        />
        {checkInState.roomOccupantsSearchResults.map((occupant, index) => {
          return (
            <div key={index} className="flex flex-row justify-between p-3 border-b">
              <Checkbox
                size="small"
                value={occupant.guest_name}
                checked={isEqual(occupant, checkInState.selectedOccupant)}
                onChange={e =>
                  setCheckInState(prev => ({
                    ...prev,
                    selectedOccupant: cloneDeep(occupant),
                  }))
                }
                label={occupant.guest_name}
              />
            </div>
          );
        })}
      </Sheet>

      <div className="group-pay-modal-ok-button">
        <Sheet
          title={t("secure.facility.tee_sheet.tee_sheet_booking_details.134")}
          open={groupPayState.groupPayActive}
          size="medium"
          closable
          onCancel={() => updateState<IGroupPayState>({ groupPayActive: false }, StateType.GroupPayState)}
          cancelText={t("secure.facility.tee_sheet.tee_sheet_booking_details.135")}
        >
          <div className="group-pay-player-search">
            <Input
              value={groupPayState.playerSearch}
              onChange={(value: any) =>
                updateState<IGroupPayState>({ playerSearch: value.target.value }, StateType.GroupPayState)
              }
              type="search"
              placeholder={t("secure.facility.tee_sheet.tee_sheet_booking_details.136")}
            />
          </div>
          <div>
            {groupPayState.groupPayTeeTimes?.map((teeTime, index) => {
              if (
                teeTime.slots.some(slot =>
                  slot.booking_participant?.full_name.toLowerCase().includes(groupPayState.playerSearch.toLowerCase()),
                )
              ) {
                return (
                  <div key={index}>
                    <div className="group-pay-time-container">
                      <p className="text-semibold text-xl mb-2">{convertTime(teeTime.start_time)}</p>
                      {!checkInState?.cart ? (
                        <Button type="text" size="medium" onClick={createCart}>
                          {"Create Cart"}
                        </Button>
                      ) : (
                        <Button type="text" size="medium" onClick={() => addAllGroupPay(teeTime?.slots)}>
                          {"Add All"}
                        </Button>
                      )}
                    </div>

                    {teeTime.slots
                      ?.filter(
                        filteredSlot =>
                          filteredSlot.booking_participant?.check_in_status === "unchecked" &&
                          filteredSlot.booking_participant.full_name
                            .toLowerCase()
                            .includes(groupPayState.playerSearch.toLowerCase()),
                      )
                      .map((slot, slotIndex) => {
                        const green_fee_added = addItemState.addedItems.some(item => {
                          if (item.booking_participant_id === slot.booking_participant?.id) {
                            return item.green_fee === true;
                          }
                        });

                        const power_cart_fee_added = addItemState.addedItems.some(item => {
                          if (item.booking_participant_id === slot.booking_participant?.id) {
                            return item.power_cart_fee === true;
                          }
                        });

                        const green_fee_setup = !(slot.booking_participant?.green_fee_product_title === null);
                        const power_cart_fee_setup = !(slot.booking_participant?.power_cart_product_title === null);

                        return (
                          <div
                            key={slotIndex}
                            className={classNames(
                              "rounded-xl",
                              "border",
                              "border-gray-400",
                              "p-2",
                              "flex",
                              "items-center",
                              "w-100",
                              "justify-between",
                              "mb-2",
                            )}
                          >
                            <p className="text-semibold text-lg">
                              {slot.booking_participant?.full_name} {slot.booking_participant.guest && "- Guest"}
                            </p>
                            <div className="group-pay-items-container">
                              {slot?.booking_participant?.green_fee_paid ? (
                                <Badge type="success" size="small">
                                  {t("secure.facility.tee_sheet.tee_sheet_booking_details.197")}
                                </Badge>
                              ) : (
                                <div className="button-group">
                                  <div className="flex flex-col ml-4 mr-2">
                                    <p className="text-semibold">
                                      {green_fee_setup
                                        ? slot.booking_participant?.green_fee_product_title
                                        : "No Green Fee Set Up"}{" "}
                                    </p>
                                    <p className="text-semibold text-xs text-gray-400">
                                      {slot.booking_participant.green_fee_variant_title}
                                    </p>
                                  </div>

                                  <Button
                                    type="primary"
                                    size="medium"
                                    style={
                                      green_fee_setup
                                        ? {
                                            backgroundColor: !green_fee_added && "#7CB342",
                                            borderColor: !green_fee_added && "#7CB342",
                                          }
                                        : {
                                            backgroundColor: "#9CA0B5",
                                            borderColor: "#9CA0B5",
                                          }
                                    }
                                    icon={
                                      green_fee_added ? (
                                        <FontAwesomeIcon icon={["fal", "times"]} />
                                      ) : (
                                        <FontAwesomeIcon icon={["fal", "arrow-right-long"]} />
                                      )
                                    }
                                    onClick={e => handleAddFee(e, "green_fee", slot, addingFee, teeTime.id)}
                                    disabled={
                                      !slot.booking_participant?.green_fee_product_title ||
                                      slot?.booking_participant?.green_fee_paid
                                    }
                                  />
                                </div>
                              )}
                              {slot?.booking_participant?.power_cart_paid ? (
                                <Badge type="success" size="small">
                                  {t("secure.facility.tee_sheet.tee_sheet_booking_details.198")}
                                </Badge>
                              ) : (
                                <div className="button-group">
                                  <p className="ml-4 mr-2 text-semibold">
                                    {power_cart_fee_setup
                                      ? slot.booking_participant?.power_cart_product_title
                                      : "No Power Cart Fee Set Up"}
                                  </p>
                                  <Button
                                    type="primary"
                                    size="medium"
                                    style={
                                      power_cart_fee_setup
                                        ? {
                                            backgroundColor: !power_cart_fee_added && "#7CB342",
                                            borderColor: !power_cart_fee_added && "#7CB342",
                                          }
                                        : {
                                            backgroundColor: "#9CA0B5",
                                            borderColor: "#9CA0B5",
                                          }
                                    }
                                    icon={
                                      power_cart_fee_added ? (
                                        <FontAwesomeIcon icon={["fal", "times"]} />
                                      ) : (
                                        <FontAwesomeIcon icon={["fal", "arrow-right-long"]} />
                                      )
                                    }
                                    onClick={e => handleAddFee(e, "power_cart_fee", slot, addingFee, teeTime.id)}
                                    disabled={
                                      !slot.booking_participant?.power_cart_product_title ||
                                      slot?.booking_participant?.power_cart_paid
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    <Divider className="mt-4 mb-2" />
                  </div>
                );
              }
            })}
          </div>
        </Sheet>
      </div>

      <Popup
        type="info"
        open={checkInState.changeDuePopupVisible}
        title={`${t("secure.facility.tee_sheet.tee_sheet_booking_details.137")} ${checkInState.changeDue?.toFixed(2)}`}
        description=""
        closable
        onOk={() =>
          updateState<ICheckInState>({ changeDue: null, changeDuePopupVisible: false }, StateType.CheckInState)
        }
        okText={t("secure.facility.tee_sheet.tee_sheet_booking_details.138")}
        backDropCancel={false}
        stacked
      />

      <Popup
        type="warning"
        open={checkInState.cancelOrderActive}
        title={t("secure.facility.tee_sheet.tee_sheet_booking_details.158")}
        description={t("secure.facility.tee_sheet.tee_sheet_booking_details.159")}
        closable
        onOk={cancelOrder}
        onCancel={() => setCheckInState(prevState => ({ ...prevState, cancelOrderActive: false }))}
        okText={t("secure.facility.tee_sheet.tee_sheet_booking_details.160")}
      />

      <OrderCompletePopup
        open={orderCompletePopup.isOpen}
        setOpen={handleBackToCheckIn}
        order={checkInState.order}
        tee_time_id={Number(teetimeId)}
        returnable={true}
        disableDuringLoad
        loading={orderCompletePopup.loading}
        transactionComplete={orderCompletePopup.transactionComplete}
        teeSheetCheckIn
      />

      <Popup
        type="success"
        open={checkInState.ticketRedeemSuccess}
        title={"Ticket Redeemed Successfully"}
        description={"Would you like to redeem another ticket?"}
        onOk={handleCloseRedeemTicket}
        okText={"Close"}
      >
        <div className="mt-4">
          <Button size="medium" onClick={handleTicketSearch} className="w-full flex justify-center">
            Search Tickets
          </Button>
        </div>
      </Popup>

      <Popup
        type="warning"
        open={removeDiscountState.modalVisible}
        title={t("secure.facility.tee_sheet.tee_sheet_booking_details.194")}
        description={t("secure.facility.tee_sheet.tee_sheet_booking_details.195")}
        onOk={() => {
          void handleDiscountCancel();
        }}
        onCancel={() => setRemoveDiscountState({ modalVisible: false, discountId: null, cartId: null })}
        okText={t("secure.facility.tee_sheet.tee_sheet_booking_details.196")}
      />

      <Prompt
        when={!!checkInState.order && checkInState.order?.financial_status !== "paid"}
        message={location =>
          "The current order has not been completed. This will result in a partially paid order. Are you sure you want to continue?"
        }
      />
    </>
  );
};

export default TeeSheetDetails;

// Map through slots to return green fees and power cart fees
function determineFeeCounts(slots: ISlot[], addedItems: IAddedItem[]) {
  const greenFeeCount: { [variantId: number]: number } = {};
  const powerCartCount: { [variantId: number]: number } = {};
  const participantFeeStatus: FeeStatusType = {};

  slots.map((slot, index) => {
    if (!slot.booking_participant) {
      return;
    }

    const greenFeeAdded = addedItems.some(item => {
      if (item.booking_participant_id === slot.booking_participant.id) {
        return item.green_fee === true;
      }
    });
    const powerCartFeeAdded = addedItems.some(item => {
      if (item.booking_participant_id === slot.booking_participant.id) {
        return item.power_cart_fee === true;
      }
    });

    // Not paid, not previously added green fee
    if (
      !slot.booking_participant.green_fee_paid &&
      !greenFeeAdded &&
      slot.booking_participant.green_fee_product_title
    ) {
      greenFeeCount[slot.booking_participant?.green_fee_variant_id] =
        (greenFeeCount[slot.booking_participant?.green_fee_variant_id] || 0) + 1;

      // Necessary for AddedItem state update
      participantFeeStatus[slot.booking_participant.id] = {
        ...participantFeeStatus[slot.booking_participant.id],
        greenFeeChecked: true,
      };
    }

    // Not paid, not previously added power cart fee
    if (
      !slot.booking_participant.power_cart_paid &&
      !powerCartFeeAdded &&
      slot.booking_participant.power_cart_product_title
    ) {
      powerCartCount[slot.booking_participant.power_cart_variant_id] =
        (powerCartCount[slot.booking_participant.power_cart_variant_id] || 0) + 1;

      participantFeeStatus[slot.booking_participant.id] = {
        ...participantFeeStatus[slot.booking_participant.id],
        powerCartChecked: true,
      };
    }
  });

  // Format the return OBJ's
  const greenFeeObj = Object.entries(greenFeeCount).map(([key, value]) => {
    return { variant_id: key, quantity: value };
  });

  const powerCartObj = Object.entries(powerCartCount).map(([key, value]) => {
    return { variant_id: key, quantity: value };
  });

  const feeStatusObj = Object.entries(participantFeeStatus).map(key => {
    return {
      participant_id: key[0],
      greenFeeChecked: key[1].greenFeeChecked,
      powerCartChecked: key[1].powerCartChecked,
    };
  });

  return {
    greenFeeCounts: greenFeeObj,
    powerCartCounts: powerCartObj,
    feeList: feeStatusObj,
  };
}
