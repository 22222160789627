import React, { ChangeEvent, useEffect, useState } from "react";

import Toggle from "components/form/toggle/Toggle";

interface IPreferenceCardOption {
  label: string;
  checked: boolean;
  id: string;
  disabled?: boolean;
}

interface IPreferenceCardProps {
  title: string;
  description: string;
  handleToggleChange: (event: any) => void;
  options: Array<IPreferenceCardOption>;
}

export default function PreferenceCard(props: IPreferenceCardProps) {
  return (
    <>
      <div className="flex justify-between">
        <div>
          <span>{props.title}</span>
          <p className="text-subdued">{props.description}</p>
        </div>
        <div>
          {props.options?.map((option: IPreferenceCardOption, index: number) => {
            return (
              <Toggle
                key={index}
                labelRight={option.label}
                id={option.id}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.handleToggleChange(e)}
                checked={option.checked}
                disabled={option.disabled}
              />
            );
          })}
        </div>
      </div>
      <div className="portal-preferences-divider" />
    </>
  );
}
