import { APIGet, APIPost, APIPut, APIDelete, IAPIResponse } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/product/inventory/transfer";

export type TInventoryTransfer = {
  id: number;
  accepted_by: number;
  approved_by: number;
  cancelled_by: number;
  created_at: string;
  created_by: number;
  created_by_full_name: string;
  date_accepted: string;
  date_approved: string;
  date_cancelled: string;
  date_created: string;
  date_submitted: string;
  deleted_at: string;
  destination_facility_id: number;
  destination_facility_long_name: string;
  facility_id: number;
  number: number;
  name: string;
  status: string;
  updated_at: string;
};

interface IPostInventoryTransferVariants {
  inventory_transfer_id: number;
  variants: Array<{ variant_id: number; quantity: number }>;
}

export type TInventoryTransferVariant = {
  barcode: string;
  inventory_transfer_id: number;
  product_id: number;
  product_title: string;
  product_vendor_title: string;
  quantity: number;
  sku: string;
  variant_id: number;
  variant_title: string;
};

export async function GetInventoryTransfer(
  params: { id?: number; offset?: number; limit?: number },
  useGlobalLoader: boolean,
  token?: CancelToken,
): Promise<IAPIResponse<Array<TInventoryTransfer>>> {
  return await APIGet(baseUrl, params, useGlobalLoader, token);
}

export async function PostInventoryTransfer(params: { destination_facility_id: number }, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

export async function PutInventoryTransfer(
  params: { id: number; destination_facility_id: number },
  useGlobalLoader: boolean,
) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

export async function DeleteInventoryTransfer(params: { id: number }, useGlobalLoader: boolean) {
  return await APIDelete(baseUrl, params, useGlobalLoader);
}

export async function GetInventoryTransferVariants(
  params: { inventory_transfer_id: number },
  useGlobalLoader: boolean,
  token?: CancelToken,
): Promise<IAPIResponse<Array<TInventoryTransferVariant>>> {
  return await APIGet(baseUrl + "/variant", params, useGlobalLoader, token);
}

export async function PostInventoryTransferVariants(params: IPostInventoryTransferVariants, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/variant", params, useGlobalLoader);
}

export async function DeleteInventoryTransferVariants(
  params: { inventory_transfer_id: number; variants: Array<number> },
  useGlobalLoader: boolean,
) {
  return await APIDelete(baseUrl + "/variant", params, useGlobalLoader);
}

export async function PutSubmitInventoryTransfer(params: { id: number }, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/submit", params, useGlobalLoader);
}

export async function PutAcceptInventoryTransfer(params: { id: number }, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/accept", params, useGlobalLoader);
}

export async function PutVoidInventoryTransfer(params: { id: number }, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/void", params, useGlobalLoader);
}
