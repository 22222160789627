import React from "react";
import { useHistory } from "react-router-dom";
import "./ProductList.scss";
import { IProduct, IVariant } from "pages/guest/models/product";
import { displayCurrency } from "helpers/currencyConverter";
import { IOnlineStoreState } from "redux/reducers/customer/onlineStore";
import { IOnlineStoreActions } from "redux/actions/customer/onlineStore";

interface IProductListProps {
  onlineStoreStore: IOnlineStoreState;
  onlineStoreActions: IOnlineStoreActions;
  products: IProduct[];
  facilityShortName: string;
  facilityId: number;
}

export default function ProductList(props: IProductListProps) {
  const history = useHistory();

  function navigateToSingleProduct(productHandle: string) {
    props.onlineStoreActions.updateProductSearchQuery("");
    history.push(`/online-store/${props.facilityShortName}/product/${productHandle}`);
  }

  function getVariantPriceOverride(variant: IVariant) {
    if (!variant) {
      return undefined;
    }

    const priceOverride = variant.inventory_item?.inventory_levels?.find(
      inventoryLevel => inventoryLevel?.facility_id === props.facilityId,
    )?.price_override;

    return priceOverride ?? variant.price;
  }

  function getProductPrice(product: IProduct) {
    const variants = product?.variants;

    if (variants === undefined || variants.length === 0) {
      return "";
    }

    const variantPrices = variants
      .map(variant => getVariantPriceOverride(variant))
      .filter(price => price !== undefined && price !== null);

    if (variantPrices.length > 0) {
      const lowestPrice = variantPrices.reduce((previousPrice, currentPrice) =>
        previousPrice < currentPrice ? previousPrice : currentPrice,
      );

      return displayCurrency("cad", lowestPrice);
    } else {
      return "";
    }
  }

  function displayNumberOfItems() {
    const numberOfItems = props.products?.length;
    if (numberOfItems === undefined || numberOfItems <= 0) {
      return "0 items";
    } else if (numberOfItems === 1) {
      return "1 item";
    } else {
      return `${numberOfItems} items`;
    }
  }

  function productMatchesSearchQuery(product: IProduct) {
    const upperCasedSearchQuery = props.onlineStoreStore.productSearchQuery.toUpperCase();
    const upperCasedProductTitle = product.title.toUpperCase();
    return upperCasedProductTitle.indexOf(upperCasedSearchQuery) > -1;
  }

  return (
    <div>
      {/*
      <div className="product-list-user-hint">
        Need it fast? Use <strong>Available Near You</strong> to buy and pick up in store
      </div>
      <div>
        <button className="mr-10 product-list-filter-active">{`All Items (${displayNumberOfItems()})`}</button>
        <button>
          Available Near You &nbsp;<FontAwesomeIcon icon={["fal", "chevron-right"]}></FontAwesomeIcon>
        </button>
      </div>
      <hr></hr>
      */}
      {props.products?.length > 0 && (
        <div className="product-list">
          {props.products.filter(productMatchesSearchQuery).map(product => {
            return (
              <div key={product.id}>
                <div className="product-image-container" onClick={() => navigateToSingleProduct(product.handle)}>
                  <img className="product-image" src={product.images?.[0]?.source} />
                </div>
                <div className="product-information-container">
                  <div className="product-information-descriptor-container">
                    <div className="product-information-descriptor">{product.title}</div>
                  </div>
                  <div className="product-information-price-container">
                    <div className="product-information-starting-at">Starting at</div>
                    <div>{getProductPrice(product)}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
