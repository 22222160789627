import React, { ChangeEvent, ReactNode, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import axios, { CancelToken } from "axios";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDOM from "react-dom";

import { StatusCode } from "api/protocols";
import {
  PostProduct,
  TPostProduct,
  TProductMetaData,
  TPutProduct,
} from "api/rpc/2024-04/facilityAdmin/product/product";
import { GetTaxLine } from "api/rpc/2024-04/facilityAdmin/client/taxLines";
import { GetProductTypes } from "api/rpc/2024-04/facilityAdmin/product/type";
import { GetVendor } from "api/rpc/2024-04/facilityAdmin/product/vendor";
import { GetAccountingReference } from "api/rpc/2024-04/facilityAdmin/facility/accounting";
import { GetDepartments } from "api/rpc/2024-04/facilityAdmin/facility/department";

import { showError } from "redux/actions/ui";
import { TAccountingReference, TTaxLines } from "redux/reducers/models/facility";
import {
  ICategories,
  IDepartmentBase,
  IDepartments,
  ISubcategories,
  TProductType,
  TProductVendor,
} from "redux/reducers/models/product";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import {
  displayCurrency,
  displayPercent,
  generateRequiredKeys,
  handleChangeEventInput,
  roundNumber,
  unsavedChangesExist,
  validateRequired,
} from "helpers/Helpers";
import { TABLET_WIDTH } from "helpers/ScreenSizes";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Input from "components/form/input/Input";
import FormLayout from "components/form/FormLayout";
import { Select } from "components/select";
import TextEditor from "components/textEditor/textEditor";
import { ButtonNew as Button } from "components/buttonNew";
import Checkbox from "components/form/checkbox/Checkbox";
import ProductMembershipOptions from "./modals/ProductMembershipOptions";
import TagInput from "components/tagInput/TagInput";
import { TProductEdit } from "./edit/useEditProduct";

import "./newProduct.scss";
import TagInputSelect from "components/tagInputSelect/TagInputSelect";

const defaultPostProduct: TPostProduct = {
  title: "",
  subtitle: "",
  type: "",
  description: "",
  internal_description: null,
  taxable: true,
  tax_line_ids: [],
  price: null,
  original_price: null,
  cost: null,
  options: [],
  meta: {
    membership_type: "",
    customer_type_id: -1,
    expiry: null,
  },
  vendor_id: -1,
  accounting_reference_id: -1,
  customer_required: false,
  shipping_required: false,
  fulfillment_required: false,
  track_inventory: false,
  department_id: -1,
  category_id: -1,
  subcategory_id: -1,
  auto_generate_sku: 0,
};

export default function NewProduct() {
  const { t } = useTranslation();
  const history = useHistory();

  const facilityStore = useAppSelector(store => store.facilityStore);
  const dispatch = useAppDispatch();
  const windowSize = useWindowSize();

  // State being applied to POST call
  const [product, setProduct] = useState<TPostProduct>(defaultPostProduct);

  const [departments, setDepartments] = useState<IDepartmentBase[]>([]);

  const [logisticsCard, setLogisticsCard] = useState({
    types: [] as TProductType[],
    vendors: [] as TProductVendor[],
    accountingReferences: [] as TAccountingReference[],
    productTypeSearch: "",
    accountingRefSearch: "",
    vendorSearch: "",
  });

  const [detailsCard, setDetailsCard] = useState({
    availableTaxLines: [] as TTaxLines[],
    allTaxLines: [] as TTaxLines[],
    tax_line_id: null,
  });

  const [variantsCard, setVariantsCard] = useState({
    multipleOptions: false,
  });

  // Connected to product.meta when creating product
  const [membershipCard, setMembershipCard] = useState<TProductMetaData>({
    membership_type: "",
    customer_type_id: -1,
    expiry: null,
  });

  // Visibility AND filter dependant on selected product.type
  const membershipApplicationFilter =
    product.type?.toLocaleLowerCase() == "membership"
      ? "green_fee"
      : product.type?.toLocaleLowerCase() == "membership add on"
      ? "power_cart"
      : null;

  // Apply UI errors only after Create action
  const [attempted, setAttempted] = useState(false);

  // memoized required keys of an IProduct.  Determined by facilityStore,validationNewProduct.required if found
  const requiredKeys = useMemo(() => {
    if (
      facilityStore.validationNewProduct.required !== undefined &&
      typeof facilityStore.validationNewProduct.required !== "string"
    ) {
      const { client_id, created_at, deleted_at, id, updated_at, ...rest } =
        facilityStore.validationNewProduct.required;

      return generateRequiredKeys<TPostProduct>(rest as Record<string, boolean>, "product_");
    }
    return [];
  }, [facilityStore.validationNewProduct.required]);

  // Call async data needed
  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadTaxLines(source.token);
    void loadProductTypes(source.token);
    void loadVendors(source.token);
    void loadDepartments(source.token);
    void loadAccountingReferences(source.token);
    return () => source.cancel();
  }, []);

  /* keep membership card props and product POST params in sync */
  useEffect(() => {
    if (unsavedChangesExist(membershipCard, product.meta)) {
      setProduct(prev => ({ ...prev, meta: { ...prev.meta, ...membershipCard } }));
    }
  }, [membershipCard]);

  async function loadDepartments(token: CancelToken) {
    const res = await GetDepartments(token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      return;
    }

    setDepartments(res.data);
  }

  async function loadAccountingReferences(token: CancelToken) {
    const res = await GetAccountingReference(null, false, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      return;
    }

    const normalizedReferences: TAccountingReference[] = [
      { id: -1, client_id: null as number, title: "None", reference_number: null as string },
      ...res.data,
    ];

    setLogisticsCard(prev => ({ ...prev, accountingReferences: normalizedReferences }));
  }

  async function loadVendors(token: CancelToken) {
    const res = await GetVendor(null, false, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      return;
    }

    const normalizedVendors = [{ id: -1, client_id: null as number, title: "None" }, ...res.data];

    setLogisticsCard(prev => ({ ...prev, vendors: normalizedVendors }));
  }

  async function loadProductTypes(token: CancelToken) {
    const res = await GetProductTypes(null, false, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      return;
    }

    const normalizedTypes = [{ id: -1, client_id: null as number, title: "", locked: true }, ...res.data];

    setLogisticsCard(prev => ({ ...prev, types: normalizedTypes }));
  }

  async function loadTaxLines(token: CancelToken) {
    const taxLineRes = await GetTaxLine(null, false);

    if (token && token.reason) {
      return;
    }
    if (taxLineRes.status !== StatusCode.OK) {
      return;
    }

    setDetailsCard(prev => ({ ...prev, availableTaxLines: taxLineRes.data, allTaxLines: taxLineRes.data }));
  }

  async function createProduct() {
    const validated = validateRequired(product as TProductEdit, requiredKeys);

    if (!validated) {
      setAttempted(true); // 'Toggles' error styling
      dispatch(showError("Please fill in all required inputs.")); // TODO: Translation
      return;
    }

    const params = removeEmpty(product, variantsCard.multipleOptions);

    // make call
    const res = await PostProduct(params, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error creating new product."));
      return;
    }

    // navigate to products table after success
    history.push("/admin/product");
  }

  /**
   * Validate specific key param.
   * - Used in JSX to help validate specific error styling
   */
  function validated(key: keyof TPutProduct | "cost_with_track_inventory") {
    if (requiredKeys.length === 0) {
      return true;
    }

    if ((requiredKeys as unknown as [keyof TPutProduct | "cost_with_track_inventory"]).includes(key)) {
      return validateRequired(product as TProductEdit, [key]);
    }
    return true;
  }

  /** Resets product.options when toggled TRUE */
  function toggleMultipleOptions(e: ChangeEvent<HTMLInputElement>) {
    setProduct(prev => ({ ...prev, options: e.target.checked ? [{ name: "", values: [""] }] : [] }));
    handleChangeEventInput(e, variantsCard, setVariantsCard);
  }

  function handleOptionChange(e: ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;

    // handle tag input -- deep object value -- Ignore ',' from keyboard
    if (value !== ",") {
      const tempState = { ...product };
      _.set(tempState, id, value);
      setProduct(() => ({ ...tempState }));
    }
  }

  function handleAddOptionTag(e: React.KeyboardEvent<HTMLInputElement>) {
    const {
      key,
      currentTarget: { id },
    } = e;

    const regex = /^(?!\s,*$).+/g;
    const tempState = { ...product };
    const arrayId = id.substring(0, id.length - 3);

    if (key === "Enter" || key === ",") {
      if (_.get(tempState, id).match(regex) !== null) {
        _.invoke(tempState, `${arrayId}.push`, "");
        setProduct(prev => ({ ...prev, ...tempState }));
      }
    }
  }

  function handleRemoveOptionTag(optionIndex: number, tagIndex: number) {
    const tempState = { ...product };
    tempState.options[optionIndex].values.splice(tagIndex, 1);
    setProduct(prev => ({ ...prev, ...tempState }));
  }

  function handleProductTypeSearch(search: string) {
    setLogisticsCard(prev => ({ ...prev, productTypeSearch: search }));
    if (search.length === 0) {
      setProduct(prev => ({ ...prev, type: "" }));
    }
  }

  function handleAccountingRefSearch(search: string) {
    setLogisticsCard(prev => ({ ...prev, accountingRefSearch: search }));
    if (search.length === 0) {
      setProduct(prev => ({ ...prev, accounting_reference_id: null }));
    }
  }

  function handleVendorSearch(search: string) {
    setLogisticsCard(prev => ({ ...prev, vendorSearch: search }));
    if (search.length === 0) {
      setProduct(prev => ({ ...prev, vendor_id: null }));
    }
  }

  function renderChargeTaxes(): ReactNode[] {
    return [
      <Checkbox
        key={1}
        id="taxable"
        size="medium"
        value={product.taxable}
        checked={Boolean(product?.taxable)}
        onChange={e => setProduct(prev => ({ ...prev, taxable: e.target.checked }))}
        label={t("secure.facility.product.product_new.017")}
      />,

      <div key={2} style={{ marginLeft: "-2rem", marginTop: "2rem" }}>
        <React.Fragment>
          {product.taxable ? (
            <div className="edit-tax-lines_single_variant_container">
              <TagInputSelect
                tags={product.tax_line_ids.map((taxLine, index: number) => {
                  const line = detailsCard.allTaxLines.find(val => val.id === taxLine);
                  return { name: `${line.name} (${line.percentage}%)`, id: line.id };
                })}
                selectOptions={detailsCard.availableTaxLines?.map(line => {
                  return {
                    name: `${line.name} (${line.percentage}%)`,
                    id: line.id,
                  };
                })}
                onChange={value => {
                  handleTaxDropdown(Number(value));
                }}
                onTagClick={taxLineId => handleRemoveTaxLine(taxLineId)}
                className="edit-tax-lines_single_variant_container_tag_input"
                helpText={"Select tax lines to add to this product"} // TODO: Translation
              />
            </div>
          ) : null}
        </React.Fragment>
      </div>,
    ];
  }

  function removeVariantOption(index: number) {
    const tempProduct = { ...product };
    tempProduct.options?.splice(index, 1);
    ReactDOM.unstable_batchedUpdates(() => {
      if (tempProduct?.options?.length === 0) {
        setVariantsCard({ multipleOptions: false });
      }
      setProduct(prevState => ({ ...prevState, ...tempProduct }));
    });
  }

  const primaryAction = {
    content: t("secure.facility.product.product_new.001"),
    action: () => createProduct(),
  };

  function handleSelectProductType(id: number) {
    if (logisticsCard.types.find(type => type.id === id)?.title === "Modifier") {
      setVariantsCard(prevState => ({ ...prevState, multipleOptions: false }));
      setProduct(prevState => ({ ...prevState, options: [] }));
    }

    setProduct(prev => ({
      ...prev,
      type: id === -1 ? "" : logisticsCard.types.find(type => type.id === id)?.title,
    }));
  }

  function handleRemoveTaxLine(tax_line_id: any) {
    const currentLists = [...detailsCard.availableTaxLines];
    const line = detailsCard.allTaxLines.find(val => val.id === tax_line_id);

    currentLists.push(line);

    setProduct(prev => ({
      ...prev,
      tax_line_ids: prev.tax_line_ids.filter(lineId => lineId !== tax_line_id),
    }));
    setDetailsCard(prevState => ({ ...prevState, availableTaxLines: currentLists }));
  }

  function handleTaxDropdown(tax_line_id: number) {
    tax_line_id && !product.tax_line_ids.includes(tax_line_id)
      ? setProduct(prev => ({ ...prev, tax_line_ids: [...prev.tax_line_ids, tax_line_id] }))
      : undefined;

    const currentLists = [...detailsCard.availableTaxLines];
    const foundIndex = currentLists.findIndex(list => tax_line_id === list.id);

    if (foundIndex !== -1) {
      currentLists.splice(foundIndex, 1);
    }

    setDetailsCard(prevState => ({ ...prevState, availableTaxLines: currentLists }));
  }

  return (
    <Page
      title={t("secure.facility.product.product_new.004")}
      primaryAction={primaryAction}
      breadcrumbs={[{ label: t("secure.facility.product.product_new.005"), url: "/admin/product" }]}
    >
      <Card title={t("secure.facility.product.product_new.006")}>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Input
                id="title"
                value={product?.title || ""}
                label={t("secure.facility.product.product_new.007")}
                onChange={e => handleChangeEventInput(e, product, setProduct)}
                placeholder={t("secure.facility.product.product_new.008")}
                error={!validated("title") && attempted}
              />
              <Input
                id="subtitle"
                value={product?.subtitle || ""}
                label={t("secure.facility.product.product_new.009")}
                onChange={e => handleChangeEventInput(e, product, setProduct)}
                placeholder={t("secure.facility.product.product_new.010")}
                error={!validated("subtitle") && attempted}
              />
            </FormLayout.Group>

            <FormLayout.Group>
              <div>
                <TextEditor
                  markdownText={product.description || ""}
                  markdownTextOnChange={description => setProduct(prev => ({ ...prev, description: description }))}
                  label={t("secure.facility.product.product_new.011")}
                />

                <Button
                  type="link"
                  size="small"
                  onClick={() =>
                    setProduct(prev => ({
                      ...prev,
                      internal_description: product.internal_description === null ? "" : null,
                    }))
                  }
                >
                  {t("secure.facility.product.product_new.052")}
                </Button>
              </div>
            </FormLayout.Group>

            {product.internal_description !== null ? (
              <FormLayout.Group>
                <TextEditor
                  markdownText={product.internal_description || ""}
                  markdownTextOnChange={description =>
                    setProduct(prev => ({ ...prev, internal_description: description }))
                  }
                  label={t("secure.facility.product.product_new.053")}
                />
              </FormLayout.Group>
            ) : null}
          </FormLayout>
        </Card.Section>
        <Card.Section title={t("secure.facility.product.product_new.012")}>
          <FormLayout>
            <FormLayout.Group>
              <Input
                id="price"
                value={product.price || ""}
                prefix="$"
                label={t("secure.facility.product.product_new.013")}
                onChange={e => handleChangeEventInput(e, product, setProduct)}
                placeholder="0.00"
                error={!validated("price") && attempted}
              />
              <Input
                id="original_price"
                value={product.original_price || ""}
                prefix="$"
                label={t("secure.facility.product.product_new.014")}
                onChange={e => handleChangeEventInput(e, product, setProduct)}
                placeholder="0.00"
                error={!validated("original_price") && attempted}
              />
              <Input
                id="cost"
                value={product.cost || ""}
                prefix="$"
                label={t("secure.facility.product.product_new.015")}
                onChange={e => handleChangeEventInput(e, product, setProduct)}
                placeholder="0.00"
                error={
                  (!validated("cost") || (!validated("cost_with_track_inventory") && product.track_inventory)) &&
                  attempted
                }
              />
              <div className="mt-4">
                <p className="product-profit-margin">
                  {displayPercent(roundNumber(100 * ((product.price - product.cost) / product.price), 0))} --{" "}
                  {displayCurrency("cad", product.price - product.cost)}
                </p>
                <p className="product-profit-margin">{t("secure.facility.product.product_new.016")}</p>
              </div>
            </FormLayout.Group>
            <FormLayout.Group>
              {/* {windowSize.width <= TABLET_WIDTH ? <div>{renderChargeTaxes()}</div> : renderChargeTaxes()}
               */}

              <div className="pt-5 pb-3">
                <div className="edit-tax-lines_title_container">
                  <Checkbox
                    key={1}
                    id="taxable"
                    size="medium"
                    value={product.taxable}
                    checked={Boolean(product?.taxable)}
                    onChange={e => setProduct(prev => ({ ...prev, taxable: e.target.checked }))}
                    label={t("secure.facility.product.product_new.017")}
                  />
                </div>

                <React.Fragment>
                  {product.taxable ? (
                    <div className="edit-tax-lines_single_variant_container">
                      <TagInputSelect
                        tags={product.tax_line_ids.map((taxLine, index: number) => {
                          const line = detailsCard.allTaxLines.find(val => val.id === taxLine);
                          return { name: `${line.name} (${line.percentage}%)`, id: line.id };
                        })}
                        selectOptions={detailsCard.availableTaxLines?.map(line => {
                          return {
                            name: `${line.name} (${line.percentage}%)`,
                            id: line.id,
                          };
                        })}
                        onChange={value => {
                          handleTaxDropdown(Number(value));
                        }}
                        onTagClick={taxLineId => handleRemoveTaxLine(taxLineId)}
                        className="edit-tax-lines_single_variant_container_tag_input"
                        helpText={"Select tax lines to add to this product"} // TODO: Translation
                      />
                    </div>
                  ) : null}
                </React.Fragment>
              </div>

              {/* {renderChargeTaxes()} */}
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>

      {product.type !== "Modifier" ? (
        <Card title={t("secure.facility.product.product_new.021")}>
          <Card.Section>
            <FormLayout>
              <div className="multiple-options-group">
                <FormLayout.Group>
                  <Checkbox
                    id="multipleOptions"
                    size="medium"
                    value={variantsCard.multipleOptions}
                    checked={variantsCard.multipleOptions}
                    onChange={e => toggleMultipleOptions(e)}
                    label={t("secure.facility.product.product_new.022")}
                  />
                  <div className="product-new-add-option">
                    <Button
                      onClick={
                        product.options.length < 3
                          ? e =>
                              setProduct(prev => ({
                                ...prev,
                                options: [...prev.options, { name: "", values: [""] }],
                              }))
                          : undefined
                      }
                      disabled={!variantsCard.multipleOptions || product.options.length > 2}
                    >
                      Add Option
                    </Button>
                  </div>
                </FormLayout.Group>
              </div>
            </FormLayout>
            {variantsCard.multipleOptions ? (
              <>
                {product.options.map((option, index: number) => {
                  return (
                    <Card.SubSection key={index}>
                      <FormLayout>
                        {/** FormLayout.Group styling + flex-grow for TagInput */}
                        <div className="multiple-options_spacing">
                          <Input
                            id={`[options][${index}][name]`}
                            value={option.name || ""}
                            label={`${t("secure.facility.product.product_new.054")} ${index + 1}`}
                            onChange={handleOptionChange}
                            placeholder={t("secure.facility.product.product_new.023")}
                          />

                          <TagInput
                            label={"Values"}
                            id={`[options][${index}][values][${
                              option.values.length === 0 ? 0 : option.values.length - 1
                            }]`}
                            value={option.values[option.values.length === 0 ? 0 : option.values.length - 1]}
                            onChange={handleOptionChange}
                            onKeyDown={handleAddOptionTag}
                            placeholder={t("secure.facility.product.product_new.025")}
                            tags={option.values.slice(0, option.values.length - 1)}
                            onTagClick={(tagIndex: number) => handleRemoveOptionTag(index, tagIndex)}
                            helpText="Add multiple tags with ' , ' or Enter." // TODO: Translation
                          />

                          <FontAwesomeIcon
                            className="ml-auto block mt-8 cursor-pointer"
                            icon={["far", "x"]}
                            onClick={() => removeVariantOption(index)}
                          />
                        </div>
                      </FormLayout>
                    </Card.SubSection>
                  );
                })}
              </>
            ) : null}
          </Card.Section>
        </Card>
      ) : null}

      <Card title={t("secure.facility.product.product_new.026")}>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Select
                label={t("secure.facility.product.product_new.027")}
                placeholder={t("secure.facility.product.product_new.028")}
                showSearch
                className={`flex justify-center align-center w-full h-10 position-relative z-20 text-black font-medium appearance-none border-none focus:outline-none placeholder-gray-200`}
                onSearch={(query: string) => handleProductTypeSearch(query)}
                onChange={(id: number) => handleSelectProductType(id)}
                allowClear
                searchValue={logisticsCard.productTypeSearch}
                dropDownPositionTop
                showDropDownOnFocus
                hideDropdown
                error={!validated("type") && attempted}
              >
                {logisticsCard.types
                  .filter(val =>
                    val.title.toLowerCase().includes(logisticsCard.productTypeSearch.toLocaleLowerCase()),
                  )
                  .map(type => (
                    <Select.Option key={type.id} value={type.id} name={type.title}>
                      <p className="font-semibold text-lg">{type.title}</p>
                    </Select.Option>
                  ))}
              </Select>

              <Select
                label={t("secure.facility.product.product.027")}
                onChange={(value: number) => setProduct(prev => ({ ...prev, vendor_id: value }))}
                className={`flex justify-center align-center w-full h-10 position-relative z-20 text-black font-medium appearance-none border-none focus:outline-none placeholder-gray-200`}
                onSearch={(query: string) => handleVendorSearch(query)}
                placeholder={"Search vendors..."}
                searchValue={logisticsCard.vendorSearch || ""}
                error={!validated("vendor_id") && attempted}
                showSearch
                showDropDownOnFocus
                hideDropdown
                allowClear
              >
                {logisticsCard.vendors
                  .filter(val => val.title?.toLowerCase().includes(logisticsCard.vendorSearch?.toLowerCase()))
                  .map((vendor, index: number) => {
                    return (
                      <Select.Option key={index} value={vendor.id} name={vendor.title}>
                        {vendor.title}
                      </Select.Option>
                    );
                  })}
              </Select>
            </FormLayout.Group>

            {/* Accounting References */}
            <FormLayout.Group>
              <Select
                label={t("secure.facility.product.product_new.030")}
                showSearch
                className={`flex justify-center align-center w-full h-10 position-relative z-20 text-black font-medium appearance-none border-none focus:outline-none placeholder-gray-200`}
                onSearch={(query: string) => handleAccountingRefSearch(query)}
                onChange={(value: number) => setProduct(prev => ({ ...prev, accounting_reference_id: value }))}
                allowClear
                placeholder={t("secure.facility.product.product_new.031")}
                searchValue={logisticsCard.accountingRefSearch}
                showDropDownOnFocus={true}
                error={!validated("accounting_reference_id") && attempted}
              >
                {logisticsCard.accountingReferences
                  .filter(accountRef =>
                    accountRef.title.toLowerCase().includes(logisticsCard.accountingRefSearch.toLowerCase()),
                  )
                  .map(reference => {
                    return (
                      <div key={reference.id}>
                        {logisticsCard.accountingRefSearch !== "" ? (
                          <div>
                            <Select.Option key={reference.id} value={reference.id} name={reference.title}>
                              <p className="font-semibold text-lg">{reference.title}</p>
                            </Select.Option>
                          </div>
                        ) : null}
                      </div>
                    );
                  })}
              </Select>
            </FormLayout.Group>
            <FormLayout.Group>
              <div>
                <Checkbox
                  id="customer_required"
                  size="medium"
                  value={product.customer_required}
                  checked={Boolean(product?.customer_required)}
                  onChange={e => setProduct(prev => ({ ...prev, customer_required: e.target.checked }))}
                  label={t("secure.facility.product.product_new.033")}
                />
                <Checkbox
                  id="shipping_required"
                  size="medium"
                  value={product.shipping_required}
                  checked={Boolean(product?.shipping_required)}
                  onChange={e => setProduct(prev => ({ ...prev, shipping_required: e.target.checked }))}
                  label={t("secure.facility.product.product_new.034")}
                />
                <Checkbox
                  id="auto_generate_sku"
                  size="medium"
                  value={product.auto_generate_sku}
                  checked={Boolean(product?.auto_generate_sku)}
                  onChange={e => setProduct(prev => ({ ...prev, auto_generate_sku: e.target.checked ? 1 : 0 }))}
                  label={"Auto Generate Sku"}
                />
              </div>
              <div>
                <Checkbox
                  id="fulfillment_required"
                  size="medium"
                  value={product.fulfillment_required}
                  checked={Boolean(product.fulfillment_required)}
                  onChange={e => setProduct(prev => ({ ...prev, fulfillment_required: e.target.checked }))}
                  label={t("secure.facility.product.product_new.035")}
                />
                <Checkbox
                  id="track_inventory"
                  size="medium"
                  value={product.track_inventory}
                  checked={Boolean(product.track_inventory)}
                  onChange={e => setProduct(prev => ({ ...prev, track_inventory: e.target.checked }))}
                  label="Track Inventory" // TODO: Translation
                />
              </div>
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
        <Card.Section title={t("secure.facility.product.product_new.036")}>
          <FormLayout>
            <FormLayout.Group>
              <div>
                <Select
                  label={t("secure.facility.product.product_new.037")}
                  onChange={(value: number) => setProduct(prev => ({ ...prev, department_id: value }))}
                  error={!validated("department_id") && attempted}
                >
                  {departments
                    .filter(value => value.type === "department")
                    .map((department: IDepartments) => (
                      <Select.Option key={department.id} value={department.id} name={department.title}>
                        {department.title}
                      </Select.Option>
                    ))}
                </Select>
              </div>

              <div>
                <Select
                  label={t("secure.facility.product.product_new.038")}
                  onChange={(value: number) => setProduct(prev => ({ ...prev, category_id: value }))}
                  error={!validated("category_id") && attempted}
                >
                  {departments
                    .filter(value => value.type === "category" && value.parent_id == Number(product.department_id))
                    .map((category: ICategories) => (
                      <Select.Option key={category.id} value={category.id} name={category.title}>
                        {category.title}
                      </Select.Option>
                    ))}
                </Select>
              </div>

              <div>
                <Select
                  label={t("secure.facility.product.product_new.039")}
                  onChange={(value: number) => setProduct(prev => ({ ...prev, subcategory_id: value }))}
                  error={!validated("subcategory_id") && attempted}
                >
                  {departments
                    .filter(value => value.type === "subcategory" && value.parent_id == Number(product.category_id))
                    .map((subcategory: ISubcategories) => (
                      <Select.Option key={subcategory.id} value={subcategory.id} name={subcategory.title}>
                        {subcategory.title}
                      </Select.Option>
                    ))}
                </Select>
              </div>
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>

      <ProductMembershipOptions
        isVisible={membershipApplicationFilter !== null}
        applicationFilter={membershipApplicationFilter}
        optionProps={membershipCard}
        setOptionProps={setMembershipCard}
      />
    </Page>
  );
}

const removeEmpty = (product: TPostProduct, multipleOptions: boolean): TPostProduct => {
  //remove blank options
  let newObj: TPostProduct = defaultPostProduct;

  Object.keys(product).forEach((key: keyof TPostProduct) => {
    const value = product[key];

    if (key !== "options") {
      if (typeof value === "string" && value.trim() === "") {
        // Remove empty strings
        newObj = {
          ...newObj,
          [key]: null as string,
        };
      } else if (typeof value === "number" && value === -1) {
        // -1 select values set to null
        newObj = {
          ...newObj,
          [key]: null as number,
        };
      } else {
        newObj = {
          ...newObj,
          [key]: value,
        };
      }
    }
    if (key === "meta") {
      newObj[key] = {
        ...newObj[key],
        membership_type: newObj[key].membership_type.trim() === "" ? null : newObj[key].membership_type,
        customer_type_id: newObj[key].customer_type_id === -1 ? null : newObj[key].customer_type_id,
        expiry: newObj[key].expiry,
      };
    }
  });

  if (!multipleOptions) {
    return { ...newObj, options: [] };
  } else {
    const tempOptions = [...product.options];

    tempOptions.forEach((option, index) => {
      if (option.name.trim() === "") {
        tempOptions.splice(index, 1);
      } else {
        option.values.forEach((tag, tagIndex) => {
          if (tag.trim() === "") {
            tempOptions[index].values.splice(tagIndex, 1);
          }
        });
      }
    });

    return { ...newObj, options: tempOptions };
  }
};
