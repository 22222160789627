import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { TFunction, useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";
import moment from "moment";

import { StatusCode } from "api/protocols";
import {
  GetTournamentParticipant,
  IPostTournamentParticipantNew,
  PostParticipant,
  PutParticipant,
} from "api/rpc/2022-09/facilityAdmin/tournament/participant";
import { GetCustomer } from "api/rpc/2022-09/facilityAdmin/customer/customer";

import { showError, showSuccess } from "redux/actions/ui";
import { searchHistoryUpdateSearch } from "redux/actions/searchBars/searchHistory";
import { ICustomer } from "redux/reducers/models/customer";
import { ITournament, ITournamentParticipant } from "redux/reducers/models/tournament";

import { useAppDispatch } from "hooks/redux";
import useModal from "hooks/modals/useModal";

import Page from "components/page/Page";
import Sheet from "components/sheet/Sheet";
import Tabs, { GenericTab } from "components/tabs/Tabs";
import FormLayout from "components/form/FormLayout";
import Search from "components/search/Search";
import DataTable from "../../../customer/tabs/houseAccounts/DataTable";
import Callout from "components/callout/Callout";
import Spin from "components/spin/spin";
import Input from "components/form/input/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CopyTournamentParticipants,
  DeleteTournamentParticipant,
  ImportTournamentPlayers,
  PutExportParticipants,
} from "api/rpc/2024-04/facilityAdmin/tournament/participant";
import Card from "components/card/Card";
import { GetTournament } from "api/rpc/2022-09/facilityAdmin/tournament/tournament";
import { Badge } from "components/badge/Badge";
import { valueToString } from "helpers/Helpers";
import { NavigationDropdownNew } from "components/navigationDropdownNew/NavigationDropdownNew";
import Icon from "components/icon/Icon";
import { Select } from "components/select/index";

interface ITournamentState {
  tournament: ITournament;
}

interface IImportState {
  modalVisible: boolean;
  selectedFile: File;
}

interface ICopyPlayerState {
  showCopyPlayers: boolean;
  selectedTournament: any;
  tournamentSearch: string;
  tournaments: Array<ITournament>;
  tournamentSearching: boolean;
}

export default function TournamentPlayers(props: any) {
  const { t, i18n } = useTranslation();
  const { tournamentId } = useParams<{ tournamentId: string }>();
  const fileUploadRef = useRef(null);
  const { Option } = Select;

  const dispatch = useAppDispatch();
  const [tournaments, setTournaments] = useState<Array<ITournament>>([]);

  const [tournamentPlayers, setTournamentPlayers] = useState<ITournamentParticipant[]>(undefined);

  const [searchState, setSearchState] = useState({
    value: "",
    customers: [] as ICustomer[],
  });

  const [copyPlayerState, setCopyPlayerState] = useState<ICopyPlayerState>({
    showCopyPlayers: false,
    selectedTournament: null,
    tournamentSearch: "",
    tournaments: [],
    tournamentSearching: false,
  });

  const [state, setState] = useState<ITournamentState>({
    tournament: undefined,
  });

  const [importState, setImportState] = useState<IImportState>({
    modalVisible: false,
    selectedFile: null,
  });

  const {
    state: newPlayerModal,
    updateModal,
    closeModal,
  } = useModal<IPostTournamentParticipantNew & { tabIndex: number }>({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    tournament_id: tournamentId,
    tabIndex: 0,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadTournamentPlayers(source.token);
    void loadTournament();
    return () => source.cancel();
  }, [tournamentId]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (searchState.value.length === 0) {
      return;
    }
    void searchCustomers(source.token);
    return () => source.cancel();
  }, [searchState.value]);

  async function loadTournamentPlayers(token?: CancelToken) {
    if (tournamentPlayers !== undefined) {
      setTournamentPlayers(undefined);
    }

    const tournamentPlayersRes = await GetTournamentParticipant(
      { tournament_id: tournamentId },
      token ? false : true,
      token,
    );

    if (token?.reason) {
      return;
    }
    if (tournamentPlayersRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading tournament participants."));
    }

    setTournamentPlayers(tournamentPlayersRes.status !== StatusCode.OK ? [] : tournamentPlayersRes.data);
  }

  async function searchCustomers(token?: CancelToken) {
    if (searchState.customers !== undefined) {
      setSearchState(prev => ({ ...prev, customers: undefined }));
    }
    const customerRes = await GetCustomer({ search: searchState.value }, token ? false : true, token);

    console.log("Customers;", customerRes);
    if (token.reason) {
      return;
    }
    setSearchState(prev => ({ ...prev, customers: customerRes.status !== StatusCode.OK ? [] : customerRes.data }));
  }

  // Add previously created user to the tournament
  async function addNewPlayer(id: string | number) {
    const addPlayerRes = await PostParticipant({ tournament_id: tournamentId, customer_id: id }, true);

    if (addPlayerRes.status !== StatusCode.OK) {
      dispatch(showError("Error adding new player to tournament."));
      return;
    }

    void loadTournamentPlayers();
    closeModal();
  }

  // Create user & add them to the Tournament
  async function createPlayer() {
    const { isOpen, tabIndex, ...playerParams } = newPlayerModal;

    const params = {
      ...playerParams,
      tournament_id: tournamentId,
    };

    const createPlayerRes = await PostParticipant(params, true);
    console.log(createPlayerRes);

    if (createPlayerRes.status !== StatusCode.OK) {
      dispatch(showError("Error creating player for tournament."));
      return;
    }

    void loadTournamentPlayers();
    closeModal();
  }

  function tabChange(index: number) {
    // Reset inputs when tabbed away
    if (index !== 0) {
      setSearchState(prev => ({ ...prev, value: "", customers: [] }));
      dispatch(searchHistoryUpdateSearch("tournament_participants", ""));
    }

    if (index !== 1) {
      updateModal({ tabIndex: index, first_name: "", last_name: "", email: "", phone: "" });
      return;
    }

    updateModal({ tabIndex: index });
  }

  const primaryAction = {
    content: t("secure.facility.tournament.tournament_players.001"),
    action: () => updateModal({ isOpen: true }),
  };

  async function deleteTournamentParticipant(id: number) {
    const deleteParticipantRes = await DeleteTournamentParticipant({ participant_id: id }, true);

    if (deleteParticipantRes.status === StatusCode.OK) {
      dispatch(showSuccess("Successfully deleted the participant."));
      void loadTournamentPlayers(); // refresh the table
    } else {
      dispatch(showError("Something went wrong deleting the tournament participant."));
    }
  }

  async function loadTournament() {
    const numericTournamentId = Number(tournamentId);
    const tournamentRes = await GetTournament({ id: numericTournamentId }, false);

    if (tournamentRes.status !== StatusCode.OK) {
      dispatch(showError("Error getting tournament"));
      return;
    }

    const tournament = tournamentRes.data[0];

    setState(prevState => ({
      ...prevState,
      tournament,
    }));
  }

  async function updatePlayerFinancialStatus(event: React.MouseEvent<HTMLElement, MouseEvent>, player: any) {
    event.stopPropagation();

    const wantedStatus = player.order_financial_status == "paid" || null ? "unpaid" : "paid";

    const params = {
      id: player.id,
      order_financial_status: wantedStatus,
    };

    // Update player within the League
    const updatePlayerInTournamentRes = await PutParticipant(params, true);
    console.log(updatePlayerInTournamentRes);
    if (updatePlayerInTournamentRes.status !== StatusCode.OK) {
      return;
    }

    void loadTournamentPlayers();
    void loadTournament();
  }

  function handleFileSelect(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files;
    if (file && file.length > 0) {
      setImportState(prevState => ({ ...prevState, selectedFile: file[0] }));
    }
  }

  async function importTournamentPlayers() {
    const formData = new FormData();

    formData.append("file", importState.selectedFile);
    formData.append("tournament_id", tournamentId);

    const importRes = await ImportTournamentPlayers(formData, true);

    if (importRes.status !== StatusCode.OK) {
      dispatch(showError("Error importing league players"));
    }

    fileUploadRef.current.value = "";
    setImportState(prevState => ({ ...prevState, selectedFile: null, modalVisible: false }));
    const source = axios.CancelToken.source();
    void loadTournamentPlayers(source.token);
  }

  async function copyPlayer() {
    const params = {
      to_tournament_id: copyPlayerState.selectedTournament.id,
      from_tournament_id: tournamentId,
    };

    const copyRes = await CopyTournamentParticipants(params, true);

    if (copyRes.status !== StatusCode.OK) {
      dispatch(showError("Error copying players"));
      return;
    }

    dispatch(showSuccess("Successfully copied players"));

    setCopyPlayerState(prevState => ({ ...prevState, showCopyPlayers: false, selectedTournament: null }));
  }

  function displayTournaments() {
    if (
      copyPlayerState?.tournaments &&
      copyPlayerState?.tournaments?.length > 0 &&
      copyPlayerState?.tournamentSearch !== ""
    ) {
      return copyPlayerState?.tournaments;
    } else {
      return tournaments;
    }
  }

  async function loadSearchedTournaments(search: string) {
    const params = {
      search: search,
    };

    const tournamentRes = await GetTournament(params, false);

    if (tournamentRes.status !== StatusCode.OK) {
      dispatch(showError(tournamentRes.message));

      return [];
    }

    return tournamentRes.data;
  }

  async function exportTournamentPlayers() {
    const exportRes = await PutExportParticipants({ tournament_id: Number(tournamentId), type: "csv" }, true);

    if (exportRes.status !== StatusCode.OK) {
      dispatch(showError("Error exporting players"));
      return;
    }

    const participantExport = document.createElement("a");
    participantExport.href = "data:text/csv;charset=utf-8," + encodeURI(exportRes.data);
    participantExport.target = "_blank";
    participantExport.download = `${state.tournament.name}_participants_${moment(new Date())
      .format("YYYY-MM-DD")
      .toString()}`
      .replace(/ /g, "_")
      .toLowerCase();
    participantExport.click();
  }

  function handleTournamentChange(value: any, id: string, tournament: ITournament) {
    setCopyPlayerState(prevState => ({ ...prevState, tournamentSearch: "", selectedTournament: tournament }));
  }

  function removeSelectedTournament() {
    setCopyPlayerState(prevState => ({
      ...prevState,
      selectedTournament: null,
      tournaments: [],
      tournamentSearching: false,
    }));
  }

  //Load Leagues
  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (async () => {
          if (copyPlayerState.tournamentSearch !== "") {
            try {
              setCopyPlayerState(prevState => ({ ...prevState, tournamentSearching: true }));
              const tournaments = await loadSearchedTournaments(copyPlayerState.tournamentSearch);
              if (mounted) {
                setCopyPlayerState(prevState => ({
                  ...prevState,
                  tournaments: tournaments,
                  tournamentSearching: false,
                }));
              }
            } catch (error) {
              console.log("err", error);
            }
            return;
          } else {
            setCopyPlayerState(prevState => ({ ...prevState, tournaments: [], tournamentSearching: false }));
          }
        })();
      }, 1000);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [copyPlayerState.tournamentSearch]);

  return (
    <Page
      title={t("secure.facility.tournament.tournament_players.009")}
      primaryAction={primaryAction}
      multipleActionDropdownAction={{
        label: "Options",
        dropdownProps: {
          alignment: "right",
          options: [
            {
              type: "handler",
              label: t("secure.facility.tournament.tournament_players.001"),
              handler: () => updateModal({ isOpen: true }),
            },
            {
              type: "handler",
              label: "Import",
              handler: () => setImportState(prevState => ({ ...prevState, modalVisible: true })),
            },
            {
              type: "handler",
              label: "Export",
              handler: exportTournamentPlayers,
            },
            {
              type: "handler",
              label: "Download Template",
              handler: () => {},
            },
            {
              type: "handler",
              label: "Copy Players",
              handler: () => setCopyPlayerState(prevState => ({ ...prevState, showCopyPlayers: true })),
            },
          ],
        },
      }}
    >
      <Card>
        <Card.Section>
          <div className="flex">
            <div className="player-count-container">
              <p className="player-counts">{"Total Players"}</p>
              <p className="player-counts">{tournamentPlayers?.length}</p>
            </div>

            <div className="player-count-container">
              <p className="player-counts">{t("secure.facility.league.players.019")} </p>
              <p className="player-counts">{state.tournament?.total_paid_participant_count} </p>
            </div>
          </div>
        </Card.Section>

        <Card.Section>
          <table className="ui-table league-players">
            <thead>
              <tr>
                <th>{t("secure.facility.tournament.tournament_players.002")}</th>
                <th>{"Email"}</th>
                {/* <th>{ t("secure.facility.tournament.tournament_players.003")}</th> */}

                <th>{t("secure.facility.tournament.tournament_players.004")}</th>
                <th>{"Player Type"}</th>
                <th>{"Status"}</th>
                <th>{t("secure.facility.tournament.tournament_players.006")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {tournamentPlayers?.map((player: any, index: number) => {
                return (
                  <tr key={player.id}>
                    <td>{player.customer.full_name}</td>
                    <td>{player.customer.email}</td>
                    <td>{player.customer.phone}</td>
                    <td>{player.customer.customer_type}</td>

                    <td>
                      {" "}
                      {player.order_financial_status ? (
                        <>
                          {player.order_financial_status === "paid" ? (
                            <span style={{ cursor: "pointer" }}>
                              <Badge
                                type="success"
                                size="medium"
                                iconLeft
                                icon="check"
                                iconStyle="fas"
                                onClick={e => updatePlayerFinancialStatus(e, player)}
                              >
                                {valueToString(player.order_financial_status, "capitalize")}
                              </Badge>
                            </span>
                          ) : (
                            <span style={{ cursor: "pointer" }}>
                              <Badge
                                type="gray"
                                size="medium"
                                iconLeft
                                icon="circle"
                                iconStyle="fas"
                                onClick={e => updatePlayerFinancialStatus(e, player)}
                              >
                                {valueToString(player.order_financial_status, "capitalize")}
                              </Badge>
                            </span>
                          )}
                        </>
                      ) : (
                        <span style={{ cursor: "pointer" }}>
                          <Badge
                            type="gray"
                            size="medium"
                            iconLeft
                            icon="circle"
                            iconStyle="fas"
                            onClick={e => updatePlayerFinancialStatus(e, player)}
                          >
                            {t("secure.facility.league.players.020")}
                          </Badge>
                        </span>
                      )}
                    </td>
                    <td>{moment(player.created_at).format("LL")}</td>
                    <td>
                      <NavigationDropdownNew
                        showPlainTextLabel
                        rightAlign
                        label={<FontAwesomeIcon icon={["far", "chevron-down"]} />}
                        sections={[
                          [
                            {
                              icon: "ban",
                              label: "Remove",
                              onClick: () => deleteTournamentParticipant(player.id),
                            },
                          ],
                        ]}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Card.Section>
      </Card>

      <Sheet
        open={newPlayerModal.isOpen}
        size="small"
        closable
        stacked
        title={t("secure.facility.tournament.tournament_players.010")}
        onCancel={() => {
          closeModal();
          setSearchState({ value: "", customers: [] });
          dispatch(searchHistoryUpdateSearch("tournament_participants", ""));
        }}
        onOk={createPlayer}
        cancelText={t("secure.facility.tournament.tournament_players.011")}
        okText={t("secure.facility.tournament.tournament_players.012")}
        okDisabled={
          newPlayerModal.first_name.length === 0 ||
          newPlayerModal.last_name.length === 0 ||
          (newPlayerModal.email.length === 0 && newPlayerModal.phone.length === 0)
        }
      >
        <Tabs tabs={tournamentTabs(t)} selected={newPlayerModal.tabIndex} onSelect={tabChange}>
          {newPlayerModal.tabIndex === 0 ? (
            <div style={{ marginTop: "20px" }}>
              <div className="flex-grow mb-4">
                <Search
                  historyKey={"tournament_participants"}
                  searchCallback={searchValue =>
                    setSearchState(prev => ({
                      ...prev,
                      value: searchValue,
                      customers: searchValue.length === 0 ? [] : prev.customers,
                    }))
                  }
                  placeholder={t("secure.facility.tournament.tournament_players.013")}
                />
              </div>

              <div className="flex-grow mb-4 min-h-[95px]">
                {/* Loading State || Empty State || Customers display */}
                {searchState.customers === undefined ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Spin className="inline-flex" />
                  </div>
                ) : searchState.customers.length === 0 ? (
                  <Callout
                    type="info"
                    title={"Player List"}
                    content={"Players who match with the search text above will be displayed here."}
                  />
                ) : (
                  searchState.customers
                    ?.filter(
                      value => !tournamentPlayers?.map(participant => participant.customer.id).includes(value.id),
                    )
                    ?.map((customer, index: number) => {
                      return (
                        <div
                          key={index}
                          className="flex justify-between league-customer-results"
                          onClick={() => addNewPlayer(customer.id)}
                        >
                          <span>
                            <p className="font-semibold text-lg">{customer?.full_name}</p>
                            <p className="text-sm text-gray-500">{customer.email}</p>
                            <p className="text-sm text-gray-500">{customer.phone ? customer.phone : null}</p>
                          </span>
                        </div>
                      );
                    })
                )}
              </div>
            </div>
          ) : null}

          {newPlayerModal.tabIndex === 1 ? (
            <FormLayout>
              <FormLayout.Group>
                <Input
                  placeholder={t("secure.facility.tournament.tournament_players.014")}
                  id="first_name"
                  value={newPlayerModal.first_name}
                  onChange={e => updateModal({ first_name: e.target.value })}
                />
                <Input
                  placeholder={t("secure.facility.tournament.tournament_players.015")}
                  id="last_name"
                  value={newPlayerModal.last_name}
                  onChange={e => updateModal({ last_name: e.target.value })}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Input
                  placeholder={t("secure.facility.tournament.tournament_players.016")}
                  id="email"
                  value={newPlayerModal.email}
                  onChange={e => updateModal({ email: e.target.value })}
                />
                <Input
                  placeholder={t("secure.facility.tournament.tournament_players.017")}
                  id="phone"
                  value={newPlayerModal.phone}
                  onChange={e => updateModal({ phone: e.target.value })}
                />
              </FormLayout.Group>
            </FormLayout>
          ) : null}
        </Tabs>
      </Sheet>

      <Sheet
        title="Select File"
        open={importState.modalVisible}
        size="small"
        closable
        onCancel={() => setImportState(prevState => ({ ...prevState, modalVisible: false }))}
        onOk={importTournamentPlayers}
        okText="Import"
        okDisabled={!importState.selectedFile ? true : false}
      >
        <Callout
          type="info"
          title={!importState.selectedFile ? "No file selected" : "Selected File"}
          content={!importState.selectedFile ? "Select a file to continue" : importState.selectedFile.name}
        />

        <div className="customer-import-container mt-2">
          <input
            ref={fileUploadRef}
            className="customer-import-input"
            type="file"
            accept=".csv"
            onChange={handleFileSelect}
          />
          <FontAwesomeIcon className="customer-import-icon" icon={["far", "upload"]} />
        </div>
      </Sheet>

      <Sheet
        open={copyPlayerState.showCopyPlayers}
        size="small"
        closable
        stacked
        title={"Select Tournament"}
        onCancel={() =>
          setCopyPlayerState(prevState => ({ ...prevState, showCopyPlayers: false, selectedTournament: null }))
        }
        onOk={copyPlayer}
        cancelText={"Cancel"}
        okText={"Copy"}
        okDisabled={false}
      >
        <div style={{ height: "250px" }}>
          {copyPlayerState.selectedTournament ? (
            <div>
              <div>
                <p className="event-label">Tournaments</p>
              </div>

              <div className="selected-container">
                <div className="event-name">
                  <div>{copyPlayerState.selectedTournament.name}</div>

                  <div>
                    <button className="" onClick={removeSelectedTournament}>
                      <Icon style="far" icon="times" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <Select
                showSearch
                label={"Tournaments"}
                onSearch={(query: string) =>
                  setCopyPlayerState(prevState => ({ ...prevState, tournamentSearch: query }))
                }
                onChange={(value: any, tournament: ITournament) =>
                  handleTournamentChange(value, "selectedTournament", tournament)
                }
                placeholder={"Search tournaments..."}
                allowClear
                searchValue={copyPlayerState.tournamentSearch}
                showDropDownOnFocus={true}
                searching={copyPlayerState.tournamentSearching}
              >
                {displayTournaments()?.map((tournament: any, index: number) => {
                  return (
                    <Option value={tournament?.id} key={index} extraValues={tournament}>
                      <div className="text-semibold text-lg">{tournament.name}</div>
                    </Option>
                  );
                })}
              </Select>
            </div>
          )}
        </div>
      </Sheet>
    </Page>
  );
}

const tournamentTabs = (t: TFunction): GenericTab[] => {
  return [
    {
      id: "search-player",
      content: t("secure.facility.tournament.tournament_players.007"),
    },
    {
      id: "add-player",
      content: t("secure.facility.tournament.tournament_players.008"),
    },
  ];
};
