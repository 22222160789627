import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios, { CancelToken } from "axios";
import moment from "moment";

import { StatusCode } from "api/protocols";
import { GetKitchenLocationChits } from "api/rpc/2022-09/facilityAdmin/facility/kitchen";
import { ICartLineItem } from "redux/reducers/models/cart";
import { capitalize } from "helpers/Helpers";

import Sheet from "components/sheet/Sheet";
import Spin from "components/spin/spin";

import "./tableServiceKitchenChits.scss";

interface IChit {
  id: number;
  kitchen_location_id: number;
  table_id: number;
  status: string;
  user_id: number;
  user_full_name: string;
  table_title: string;
  sent_at: string;
  kitchen_location_title: string;
  children_chits: IChit[];
  line_items: ICartLineItem[];
  table_short_title: string;
}

interface ITableServiceKitchenChitModal {
  isVisible: boolean;
  closeModal: () => React.Dispatch<React.SetStateAction<boolean>> | void;
  user_id: number;
  forceRefresh: boolean;
  setForceRefresh: Dispatch<SetStateAction<boolean>>;
}

export default function TableServiceKitchenChitModal(props: ITableServiceKitchenChitModal) {
  const [chits, setChits] = useState<IChit[]>(null);
  const [elapsedTimes, setElapsedTimes] = useState<Array<string>>([]);
  /* Allow modal to refresh chits AND keep token safety for memory leaks */
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  // load the chits
  useEffect(() => {
    const source = axios.CancelToken.source();

    //first load
    if (chits === null) {
      void loadChits(props.user_id, source.token);
      return;
    }

    //Refresh trigger
    if (triggerRefresh) {
      void loadChits(props.user_id, source.token);
    }

    return () => {
      source.cancel();
    };
  }, [props.user_id, triggerRefresh]);

  //Calculate and update the elapsed time
  useEffect(() => {
    let interval: NodeJS.Timeout = null;

    if (chits) {
      interval = setInterval(() => {
        const newElapsedTimes: string[] = [];
        const currentTime = moment();
        chits.forEach(chit => {
          //Get the time the chit was sent to the kitchen in user's local time
          const chitSentTime = moment.utc(chit.sent_at).local();
          //Get the difference in current time & chit sent time
          const differenceTime = moment.duration(currentTime.diff(chitSentTime));
          const formattedElapsedTime = formatTime(differenceTime);
          newElapsedTimes.push(formattedElapsedTime);
        });
        setElapsedTimes(newElapsedTimes);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [chits]);

  async function loadChits(id: number, token?: CancelToken) {
    if (chits !== null) {
      setChits(null);
    }

    const before = moment().utc().subtract(12, "hours").format("YYYY-MM-DD HH:mm:ss");
    const after = moment().utc().add(24, "hours").format("YYYY-MM-DD HH:mm:ss");

    const res = await GetKitchenLocationChits(
      {
        extended: true,
        start_date: before,
        end_date: after,
        user_id: id,
      },
      false,
      token,
    );

    const filteredChits = res?.data?.filter(
      (chit: IChit) => chit.status !== "void" && chit.status !== "archived" && chit.status !== "fire",
    );

    if (res.status == StatusCode.OK) {
      setChits(filteredChits.length > 0 ? filteredChits : []);
      setTriggerRefresh(false);
      return;
    }

    setChits([]);
    setTriggerRefresh(false);
  }

  return (
    <Sheet
      open={props.isVisible}
      onOk={() => props.closeModal()}
      onCancel={() => setTriggerRefresh(!triggerRefresh)}
      backDropCancel={false}
      okText="Close" //TODO: Translation
      cancelText="Refresh" //TODO: Translation
      size="medium"
      cancelDisabled={chits === null}
    >
      <div className="table-service-kitchen-chits">
        {chits ? (
          chits.map((chit, chitIndex) => {
            return (
              <div key={chitIndex} className="table-service-kitchen-chits-card">
                <div className="table-service-kitchen-chits-table-name-container">
                  <p className="table-service-kitchen-chits-table-name">{chit?.table_short_title}</p>
                </div>
                <div className="table-service-kitchen-chits-flex-col">
                  <div className="table-service-kitchen-chits-flex-row">
                    <p>{chit.user_full_name}</p>
                    <p className="table-service-kitchen-chits-status">{capitalize(chit.status)}</p>
                  </div>
                  <div className="table-service-kitchen-chits-flex-row">
                    <p>
                      {chit.line_items?.length} {chit.line_items?.length > 1 ? "items" : "item"}
                    </p>
                    <FontAwesomeIcon fixedWidth icon={["far", "arrow-right-long"]} size="xs" />
                    <p>{chit.kitchen_location_title}</p>
                    <p>{elapsedTimes[chitIndex]}</p>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div style={{ height: "64px", position: "absolute", width: "100%" }}>
            <Spin />
          </div>
        )}
      </div>
    </Sheet>
  );
}

function formatTime(timeDifference: moment.Duration) {
  const hours = timeDifference.hours().toString().padStart(2, "0");
  const minutes = timeDifference.minutes().toString().padStart(2, "0");
  const seconds = timeDifference.seconds().toString().padStart(2, "0");
  if (hours === "0" || hours === "00") {
    return `${minutes}:${seconds}`;
  } else {
    return `${hours}:${minutes}:${seconds}`;
  }
}
