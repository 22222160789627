import React, { useCallback, useEffect, useState, Fragment } from "react";
import { useAppSelector, useAppDispatch } from "hooks/redux";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import useModal from "hooks/modals/useModal";
import moment from "moment";
import axios, { CancelToken } from "axios";
import { chunk, cloneDeep, groupBy, range, take } from "lodash";
import { IconName } from "@fortawesome/fontawesome-svg-core";

import { GetLeague } from "api/rpc/2024-04/clientAdmin/league/league";
import { GetTournament } from "api/rpc/2024-04/clientAdmin/tournament/tournament";
import { ExportShotgunTemplate, PutTeeTime } from "api/rpc/2024-04/facilityAdmin/teesheet/teeSheet";
import { GetBookingCategory } from "api/rpc/2024-04/clientAdmin/teesheet/bookingEngine/category";
import {
  GetTeeTime,
  GetTeeTimePricing,
  DeleteTeeTime,
  GetPrintCartSigns,
  PostTeeTimeLock,
  PostTeeTimeSqueeze,
} from "api/rpc/2024-04/clientAdmin/teesheet/teeSheet";
import {
  PutCancelBooking,
  PutCancelParticipant,
  GetBooking,
  PutBookingParticipantNoShow,
  PutMoveBooking,
} from "api/rpc/2024-04/clientAdmin/teesheet/booking";
import { GetShotGunTemplate } from "api/rpc/2024-04/clientAdmin/teesheet/template";
import { RemoveShotgunTemplate } from "api/rpc/2024-04/clientAdmin/teesheet/shotgun";
import { StatusCode } from "api/protocols";

import { selectTeeTimeSuccess, selectTeeTime } from "redux/actions/clientAdmin/teesheet";
import { showError, showSuccess, initiateNewBooking, initiateEditBooking } from "redux/actions/ui";

import { MOBILE_WIDTH } from "helpers/ScreenSizes";
import { capitalize, convertTime, valueToString, timeToNumber, formatDate } from "helpers/Helpers";

import { TeeSheetCard as Card } from "components/teeSheetCard";
import TeeTimeToolTip, { ITeeTimeToolTipPricing } from "components/teeTimeToolTip/teeTimeToolTip";
import RightClickMenu from "components/rightClickMenu/RightClickMenu";
import { Badge } from "components/badge/Badge";
import { CustomIcon } from "components/customIcon/CustomIcon";
import { NavigationDropdownNew } from "components/navigationDropdownNew/NavigationDropdownNew";
import Spin from "components/spin/spin";
import Portal from "elements/Portal";
import Popup from "components/popup/Popup";
import BookingModalNew from "./modals/BookingModalNew";
import BookingModalEdit from "./modals/BookingModalEdit";
import { Select } from "components/select/index";
import CreateShotgunModal from "./modals/CreateShotgunModal";
import AuditTrailModal from "./modals/AuditTrailModal";
import TeeTimeDetailsModal from "./modals/TeeTimeDetailsModal";
import AddTeeTimeModal from "./modals/AddTeeTimeModal";
import EditStartHoleModal from "./modals/EditStartHoleModal";

import { IDivision } from "redux/reducers/models/facility";
import { ILeague } from "redux/reducers/models/league";
import { ITournament } from "redux/reducers/models/tournament";
import {
  ITeeTimeNew,
  ITeeTimeSlotNew,
  IBookingCategory,
  IBooking,
  ITeeTimeBookingCategory,
  ISlot,
} from "redux/reducers/models/teetime";
import { TShotgunTemplate } from "redux/reducers/models/teesheet";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { DragDropContext, Droppable, Draggable, DragStart, ResponderProvided, DropResult } from "react-beautiful-dnd";

import "./newTeeSheetTabs.scss";
import Sheet from "components/sheet/Sheet";
import Divider from "components/divider";
import Avatar from "components/avatar/Avatar";

interface INewTeeSheetTabs {
  teetimes: ITeeTimeNew[];
  teetimeBookingCategories: ITeeTimeBookingCategory[][];
  divisions: IDivision[];
  reloadTeeTimes: () => void;
}

interface INewTeeSheetTabsState {
  sortedTeeTimes: ITeeTimeNew[];
  groupedTeeTimes: ITeeTimeDraggable[];
  selectedTeeTime: ITeeTimeNew;
  teeSheetLeagues: ILeague[];
  teeSheetTournaments: ITournament[];
}

interface IDragAndDropState {
  draggingSlotGroup: ITeeTimeSlotNew[];
  teetimeToolTipDisabled: boolean; // Toggle the TeeTimeToolTip popup only when not dragging items
  holeConversionWarningOpen: boolean;
  destTeeTimeId: number;
  backupGroupedTeeTimes: ITeeTimeDraggable[];
}
interface IViewDetailsState {
  slot: ISlot;
  modalOpen: boolean;
  slots: ISlot[];
}

type TDraggableSlots = Array<Array<Array<ITeeTimeSlotNew>>>;
type TDraggableSlot = Array<Array<ITeeTimeSlotNew>>;

export interface ITeeTimeDraggable extends Omit<ITeeTimeNew, "slots"> {
  slots: TDraggableSlot;
}

export default function NewTeeSheetTabs(props: INewTeeSheetTabs) {
  {
    /* CONSTANTS */
  }
  const { clientFacilityStore, clientTeeSheetStore, authStore, uiStore } = useAppSelector(store => store);
  const dispatch = useAppDispatch();
  const windowSize = useWindowSize();
  const { t } = useTranslation();
  const history = useHistory();

  const { divisions } = props;

  const [bookingCategories, setBookingCategories] = useState<IBookingCategory[]>(undefined);
  const [shotgunTemplates, setShotgunTemplates] = useState<TShotgunTemplate[]>(undefined);
  const [viewDetailsState, setViewDetailsState] = useState<IViewDetailsState>({
    slot: null,
    modalOpen: false,
    slots: null,
  });

  const [state, setState] = useState<INewTeeSheetTabsState>({
    sortedTeeTimes: null,
    groupedTeeTimes: null,
    selectedTeeTime: null,
    teeSheetLeagues: undefined,
    teeSheetTournaments: undefined,
  });

  const [dragAndDropState, setDragAndDropState] = useState<IDragAndDropState>({
    draggingSlotGroup: undefined,
    teetimeToolTipDisabled: false,
    holeConversionWarningOpen: false,
    destTeeTimeId: undefined,
    backupGroupedTeeTimes: undefined,
  });

  {
    /* MODALS */
  }
  const {
    state: newBookingModal,
    updateModal: updateNewBookingModal,
    closeModal: closeNewBookingModal,
  } = useModal({
    clearOnClose: true,
    loading: false,
    loadingText: "",
    postingLock: false,
  });

  const {
    state: noShowModal,
    updateModal: updateNoShowModal,
    closeModal: closeNoShowModal,
  } = useModal({
    clearOnClose: true,
    type: "charge",
    bookingParticipantId: null,
  });

  const {
    state: createShotgunModal,
    updateModal: updateCreateShotgunModal,
    closeModal: closeCreateShotgunModal,
  } = useModal({
    clearOnClose: true,
    teetime: null,
  });

  const {
    state: auditTrailModal,
    updateModal: updateAuditTrailModal,
    closeModal: closeAuditTrailModal,
  } = useModal({
    clearOnClose: true,
    teetime: null,
    bookingId: null,
    type: null,
  });

  const {
    state: teeTimeDetailsModal,
    updateModal: updateTeeTimeDetailsModal,
    closeModal: closeTeeTimeDetailsModal,
  } = useModal({
    clearOnClose: true,
    teetime: null,
  });

  const {
    state: editStartHoleModal,
    updateModal: updateEditStartHoleModal,
    closeModal: closeEditStartHoleModal,
  } = useModal({
    clearOnClose: true,
    teetime: null,
    start_hole: "",
  });

  const {
    state: addSqueezeTimeModal,
    updateModal: updateAddSqueezeTimeModal,
    closeModal: closeAddSqueezeTimeModal,
  } = useModal({
    clearOnClose: true,
    teetime: null,
  });

  const {
    state: addTeeTimeModal,
    updateModal: updateAddTeeTimeModal,
    closeModal: closeAddTeeTimeModal,
  } = useModal({
    clearOnClose: true,
    teetime: null,
  });

  const {
    state: editBookingModal,
    updateModal: updateEditBookingModal,
    closeModal: closeEditBookingModal,
  } = useModal({
    clearOnClose: true,
    selectedTeeTime: null,
    selectedBooking: null,
    teeTimeLock: false,
    loading: false,
    loadingText: "",
    postingLock: false,
  });

  {
    /* POPUPS */
  }
  const {
    state: blockedTeeTimePopup,
    updateModal: updateBlockedTeeTimePopup,
    closeModal: closeBlockedTeeTimePopup,
  } = useModal();

  const {
    state: cancelBookingPopup,
    updateModal: updateCancelBookingPopup,
    closeModal: closeCancelBookingPopup,
  } = useModal({
    clearOnClose: true,
    teetimeToCancelDate: "",
    teetimeToCancelTime: "",
    teetimeToCancelBookingId: null,
  });

  const {
    state: cancelPlayerPopup,
    updateModal: updateCancelPlayerPopup,
    closeModal: closeCancelPlayerPopup,
  } = useModal({
    clearOnClose: true,
    bookingParticipantName: "",
    bookingParticipantId: null,
  });

  const {
    state: teeTimeBookedPopup,
    updateModal: updateTeeTimeBookedPopup,
    closeModal: closeTeeTimeBookedPopup,
  } = useModal();

  const {
    state: teeTimeLockedPopup,
    updateModal: updateTeeTimeLockedPopup,
    closeModal: closeTeeTimeLockedPopup,
  } = useModal();

  const {
    state: removeTeeTimePopup,
    updateModal: updateRemoveTeeTimePopup,
    closeModal: closeRemoveTeeTimePopup,
  } = useModal({
    clearOnClose: true,
    teetime: null,
  });

  const {
    state: removeShotgunPopup,
    updateModal: updateRemoveShotgunPopup,
    closeModal: closeRemoveShotgunPopup,
  } = useModal({
    clearOnClose: true,
    teetime: null,
  });

  {
    /* USE EFFECTS */
  }
  // Load events when tee times are updated to display information about them on the tee sheet
  useEffect(() => {
    const source = axios.CancelToken.source();
    if (props.teetimes?.length > 0) {
      // Display loader
      updateMainState({ teeSheetTournaments: undefined, teeSheetLeagues: undefined });
      const teeTimes = [...props.teetimes];
      // Load tee sheet tournaments and leagues if they exist
      const tournamentIds = teeTimes
        ?.filter(filteredTeeTime => filteredTeeTime?.slots[0].tournament_id)
        ?.map(teetime => {
          if (teetime.slots[0].tournament_id) {
            return teetime.slots[0].tournament_id;
          }
        });
      void loadTeeSheetTournaments([...new Set(tournamentIds)], source.token);

      const leagueIds = teeTimes
        ?.filter(filteredTeeTime => filteredTeeTime?.slots[0].league_id)
        ?.map(teetime => {
          if (teetime.slots[0].league_id) {
            return teetime.slots[0].league_id;
          }
        });
      void loadTeeSheetLeagues([...new Set(leagueIds)], source.token);
    }
    return () => source.cancel();
  }, [props.teetimes]);

  // Group slots together by booking id
  useEffect(() => {
    if (!props.teetimes) {
      return;
    }

    const source = axios.CancelToken.source();

    const deepCloneTeetimes = cloneDeep(props.teetimes).filter(
      (teetime: ITeeTimeNew) => teetime.slots[0].blocked_type !== "shotgun_disabled",
    );

    const sortedTeeTimes = deepCloneTeetimes.sort((current, next) => {
      return current?.slots[0]?.start_time.localeCompare(next?.slots[0]?.start_time);
    });

    const clonedSortedTeeTimes = cloneDeep(sortedTeeTimes);

    const slotGroups: TDraggableSlots = clonedSortedTeeTimes?.map((teetime, rowIndex) => {
      return Object.values(
        groupBy(clonedSortedTeeTimes[rowIndex].slots, s =>
          s.tee_time_booking_id === null ? s.tee_time_slot_id : s.tee_time_booking_id,
        ),
      );
    });

    //potentially sort the array here instead of TeeSheetTabs
    const groupedTeeTimes: ITeeTimeDraggable[] = clonedSortedTeeTimes
      ?.map((teetime, rowIndex) => {
        teetime.slots.splice(0); // removes the contents of slots returns empty array
        return teetime as unknown as ITeeTimeDraggable;
      })
      .map((teetime, rowIndex) => {
        teetime.slots.push(...slotGroups[rowIndex]); // pushes slotGroups to empty array
        return teetime;
      });

    if (groupedTeeTimes) {
      updateMainState({ groupedTeeTimes, sortedTeeTimes });
    }
    return () => source.cancel();
  }, [props.teetimes]);

  // Load shotgun information
  useEffect(() => {
    const source = axios.CancelToken.source();
    if (!bookingCategories && createShotgunModal.isOpen) {
      void loadBookingCategories(source.token);
    }
    if (!shotgunTemplates && createShotgunModal.isOpen) {
      void loadShotgunTemplates(source.token);
    }
    return () => source.cancel();
  }, [createShotgunModal.isOpen]);

  // Load booking categories for tee time details if they're not already laoded
  useEffect(() => {
    const source = axios.CancelToken.source();
    if (!bookingCategories && teeTimeDetailsModal.isOpen) {
      void loadBookingCategories(source.token);
    }
    return () => source.cancel();
  }, [teeTimeDetailsModal.isOpen]);

  // Load information for edit bookign modal
  useEffect(() => {
    const source = axios.CancelToken.source();
    if (!bookingCategories && teeTimeDetailsModal.isOpen) {
      void loadBookingCategories(source.token);
    }
    return () => source.cancel();
  }, [teeTimeDetailsModal.isOpen]);

  // Scroll current time into view
  const elementScrollRef = useCallback(element => {
    const tr = timeToNumber();
    const offsetTime = props.teetimes
      .filter(teetime => {
        const time = teetime.start_time.replace(":", "");
        if (parseInt(time) <= tr) {
          return true;
        }
        return false;
      })
      .filter((teetime, i, teetimes) => {
        if (i === teetimes.length - 8 /*magic number, 1 hour is approximately 8 indexes*/) {
          return true;
        }
        return false;
      });

    if (formatDate(clientTeeSheetStore.selectedDate as Date) === formatDate(new Date())) {
      if (offsetTime && element?.id === String(offsetTime[0]?.slots[0]?.tee_time_id)) {
        element?.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }

    return () => {
      setState({
        sortedTeeTimes: null,
        groupedTeeTimes: null,
        selectedTeeTime: null,
        teeSheetLeagues: undefined,
        teeSheetTournaments: undefined,
      });
    };
  }, []);

  // FUNCTIONS
  function updateMainState(newMainState: Partial<INewTeeSheetTabsState>) {
    setState((cur: INewTeeSheetTabsState) => {
      return { ...cur, ...newMainState };
    });
  }

  async function loadBookingCategories(token?: CancelToken) {
    const bookingCategoryRes = await GetBookingCategory(null, false, token);

    if (token && token.reason) {
      return;
    }
    if (bookingCategoryRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading booking categories")); // TODO: Translation
    }

    setBookingCategories(bookingCategoryRes.data);
  }

  async function loadShotgunTemplates(token?: CancelToken) {
    const templateRes = await GetShotGunTemplate({ facility_id: clientFacilityStore.facility?.id }, false, token);

    if (token && token.reason) {
      return;
    }
    if (templateRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading shotgun templates")); // TODO: Translation
    }

    setShotgunTemplates(templateRes.data);
  }

  function findCustomerTypeBackground(slot: ITeeTimeSlotNew) {
    let color = "#FFFFFF";

    if (slot.check_in_status === "checked") {
      color = "#82d195";
    } else if (slot.turn) {
      color = "#e7e7e7";
    } else if (slot.no_show_type == "weather") {
      color = "#fcbf49";
    } else if (slot.no_show_type) {
      color = "#d62828";
    } else if (slot.customer_id) {
      color = slot.customer_type_color;
    }

    return color;
  }

  async function handleMouseEnter(
    teeTime: ITeeTimeNew,
    token?: CancelToken,
  ): Promise<ITeeTimeToolTipPricing | undefined> {
    const pricingRes = await GetTeeTimePricing({ tee_time_id: teeTime.slots[0].tee_time_id }, false, token);

    if ((token && token.reason) || typeof pricingRes.data === "string") {
      return undefined;
    }

    if (pricingRes.status !== StatusCode.OK) {
      return {
        booking_fee: undefined,
        green_fees: [],
        booking_categories: [],
      };
    }

    return pricingRes.data as ITeeTimeToolTipPricing;
  }

  function checkForCheckedInPlayer(slotGroup: ITeeTimeSlotNew[]) {
    const checkPlayers = slotGroup?.filter(slot => slot.check_in_status !== "unchecked");
    if (checkPlayers.length > 0) {
      return true;
    }
    return false;
  }

  // TEE TIME FUNCTIONS
  async function removeTeeTime() {
    const params = {
      facility_id: clientFacilityStore.facility?.id,
      tee_time_id: removeTeeTimePopup.teetime.slots[0].tee_time_id,
    };

    const removeRes = await DeleteTeeTime(params, true);

    if (removeRes.status !== StatusCode.OK) {
      dispatch(showError("Error removing shotgun tee time")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Successfully removed shotgun tee time")); // TODO: Translation

    closeRemoveTeeTimePopup();

    props.reloadTeeTimes();
  }

  async function setBlockedType(teetime: any, type: string) {
    teetime.blocked_type = type;

    const teeTimeRes = await PutTeeTime({ tee_times: [teetime] }, true);

    if (teeTimeRes.status !== StatusCode.OK) {
      dispatch(showError(t("elements.tee_sheet.tee_sheet_tabs.062")));
      return;
    }
  }

  async function createSqueezeTime(teetime: any) {
    const teeTimeRes = await PostTeeTimeSqueeze(
      { tee_time_id: addSqueezeTimeModal.teetime?.slots[0]?.tee_time_id },
      true,
    );

    if (teeTimeRes.status !== StatusCode.OK) {
      dispatch(showError("Error creating squeeze time"));
      return;
    }

    props.reloadTeeTimes();
    closeAddSqueezeTimeModal();
  }

  // BOOKING FUNCTIONS
  async function handleInitiateBooking(teetime: ITeeTimeNew) {
    // Show booking modal
    dispatch(initiateNewBooking());

    // If tee time is not locked then load it
    updateNewBookingModal({ loading: true, loadingText: "Loading tee time..." });

    const teetimeRes = await GetTeeTime(
      {
        facility_id: clientFacilityStore.facility?.id,
        id: teetime.slots[0].tee_time_id,
        extended: true,
        "extended-bookings": true,
        locks: true,
        turn_tee_time: true,
      },
      false,
    );

    if (teetimeRes.status !== StatusCode.OK) {
      return;
    }
    const updatedTeeTime = teetimeRes.data[0];
    dispatch(selectTeeTimeSuccess(updatedTeeTime));
    if (updatedTeeTime.quantity_remaining === 0) {
      updateTeeTimeBookedPopup({ isOpen: true });
      return;
    }

    // Lock the tee time
    updateNewBookingModal({ loadingText: "Checking tee time availability...", postingLock: true });

    const postLockRes = await PostTeeTimeLock({ tee_time_id: updatedTeeTime.id }, false);

    // Tee time is locked by another user
    if (postLockRes.status !== StatusCode.OK) {
      updateTeeTimeLockedPopup({ isOpen: true });
      updateNewBookingModal({ loading: false, loadingText: "", postingLock: false });
      return;
    }

    updateMainState({ selectedTeeTime: null });
    updateNewBookingModal({ loading: false, loadingText: "", postingLock: false });
    return;
  }

  async function loadTeeTimeDetails(slot: any) {
    const teetimeRes = await GetTeeTime(
      {
        facility_id: clientFacilityStore.facility?.id,
        id: slot?.tee_time_id,
        extended: true,
        "extended-bookings": true,
        turn_tee_time: true,
      },
      true,
    );
    setViewDetailsState(prevState => ({
      ...prevState,
      modalOpen: true,
      slots: teetimeRes.data[0].slots.filter(slot => slot.status !== "available"),
    }));
  }

  async function handleEditBooking(teetime: ITeeTimeNew, bookingId: number) {
    // Show edit booking modal while data is laoding
    dispatch(initiateEditBooking());

    // Load booking
    updateEditBookingModal({ isOpen: true, loading: true, loadingText: "Loading booking..." });

    const bookingRes = await GetBooking({ id: bookingId }, false);

    //Check if selected booking has been cancelled by another user
    if (bookingRes.status !== StatusCode.OK || bookingRes.data[0]?.status === "cancelled") {
      props.reloadTeeTimes();
      dispatch(showError(t("elements.tee_sheet.tee_sheet_tabs.063")));
      return;
    }

    await dispatch(selectTeeTime(teetime.slots[0].tee_time_id, clientFacilityStore.facility?.id, true, false));

    updateEditBookingModal({ loadingText: "Checking tee time availability...", postingLock: true });

    const postLockRes = await PostTeeTimeLock({ tee_time_id: teetime.slots[0].tee_time_id }, false);
    if (postLockRes.status !== StatusCode.OK) {
      return;
    }

    // Tee time is locked by another user
    if (postLockRes.status !== StatusCode.OK) {
      updateEditBookingModal({
        selectedBooking: null,
        selectedTeeTime: null,
        loading: false,
        postingLock: false,
        teeTimeLock: false,
      });
      dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.005")));
    }

    updateEditBookingModal({
      selectedBooking: bookingRes.data[0],
      selectedTeeTime: teetime,
      loading: false,
      postingLock: false,
    });
  }

  async function cancelBooking() {
    const cancelRes = await PutCancelBooking(
      { tee_time_booking_id: Number(cancelBookingPopup.teetimeToCancelBookingId) },
      true,
    );

    if (cancelRes?.status !== StatusCode.OK) {
      dispatch(showError(t("elements.tee_sheet.tee_sheet_tabs.059")));
      return;
    }

    updateCancelBookingPopup({
      isOpen: false,
      teetimeToCancelDate: "",
      teetimeToCancelTime: "",
      teetimeToCancelBookingId: null,
    });

    dispatch(showSuccess(t("elements.tee_sheet.tee_sheet_tabs.060")));
    props.reloadTeeTimes();
  }

  async function cancelBookingParticipant() {
    const cancelRes = await PutCancelParticipant(
      { facility_id: clientFacilityStore.facility?.id, id: cancelPlayerPopup.bookingParticipantId },
      true,
    );
    if (cancelRes.status !== StatusCode.OK) {
      dispatch(showError(t("elements.tee_sheet.tee_sheet_tabs.061")));
      return;
    }

    updateCancelPlayerPopup({
      isOpen: false,
      bookingParticipantName: "",
      bookingParticipantId: null,
    });

    dispatch(showSuccess(t("elements.tee_sheet.tee_sheet_tabs.125")));
    props.reloadTeeTimes();
  }

  async function handleNoShow() {
    if (!noShowModal.bookingParticipantId) {
      return;
    }

    const putBookingParticipantRes = await PutBookingParticipantNoShow(
      {
        id: noShowModal.bookingParticipantId,
        no_show_type: noShowModal.type,
      },
      true,
    );

    if (putBookingParticipantRes.status !== StatusCode.OK) {
      dispatch(
        showError(
          typeof putBookingParticipantRes?.data === "string"
            ? putBookingParticipantRes?.data
            : t("elements.tee_sheet.tee_sheet_tabs.064") + valueToString(noShowModal.type),
        ),
      );
      return;
    }

    dispatch(showSuccess(t("elements.tee_sheet.tee_sheet_tabs.065") + valueToString(noShowModal.type)));

    updateNoShowModal({
      isOpen: false,
      type: "charge",
      bookingParticipantId: null,
    });

    props.reloadTeeTimes();
  }

  const handleNavigateToBookingDetails = (teetimeId: number, slot: ITeeTimeSlotNew) => {
    if (slot.turn === true) {
      return;
    }

    history.push(`/admin/teesheet/details/${teetimeId}`);
  };

  function changeSlotBookingInformation(slot: ITeeTimeSlotNew, bookedSlot: ITeeTimeSlotNew = null) {
    // position and tee_time_slot_position can stay the same
    // quantity_remaining will be set at the very end of drag and drop for source and destination
    slot.tee_time_booking_id = bookedSlot?.tee_time_booking_id ?? null;
    slot.tee_time_slot_status = bookedSlot == null ? "available" : bookedSlot?.tee_time_slot_status ?? null;
    slot.tee_time_bookings_holes = bookedSlot?.tee_time_bookings_holes ?? null;
    slot.tee_time_bookings_source = bookedSlot?.tee_time_bookings_source ?? null;
    slot.tee_time_bookings_completed_at = bookedSlot?.tee_time_bookings_completed_at ?? null;
    slot.has_notes = bookedSlot?.has_notes ?? null;
    slot.power_cart_quantity = bookedSlot?.power_cart_quantity ?? null;
    slot.booking_participant_id = bookedSlot?.booking_participant_id ?? null;
    slot.customer_id = bookedSlot?.customer_id ?? null;
    slot.guest = bookedSlot?.guest ?? null;
    slot.tee_time_booking_participant_status = bookedSlot?.tee_time_booking_participant_status ?? null;
    slot.check_in_status = bookedSlot?.check_in_status ?? null;
    slot.turn = bookedSlot?.turn ?? null;
    slot.customer_first_name = bookedSlot?.customer_first_name ?? null;
    slot.customer_last_name = bookedSlot?.customer_last_name ?? null;
    slot.customer_type_title = bookedSlot?.customer_type_title ?? null;
    slot.customer_type_subtitle = bookedSlot?.customer_type_subtitle ?? null;
    slot.customer_type_color = bookedSlot?.customer_type_color ?? null;
    slot.order_financial_status = bookedSlot?.order_financial_status ?? null;
    slot.no_show_type = bookedSlot?.no_show_type ?? null;
    slot.bag_number = bookedSlot?.bag_number ?? null;
  }

  function handleOnDragStart(initial: DragStart, provided: ResponderProvided) {
    const draggingSlotGroup = state.groupedTeeTimes[Number(initial.source.droppableId)]?.slots?.find(slotGroup => {
      return slotGroup?.[0]?.tee_time_slot_id === Number(initial.draggableId);
    });

    setDragAndDropState(prev => ({
      ...prev,
      draggingSlotGroup: cloneDeep(draggingSlotGroup),
      teetimeToolTipDisabled: false,
    }));
  }

  async function handleOnDragEnd(result: DropResult, provided: ResponderProvided) {
    setDragAndDropState(prev => ({ ...prev, teetimeToolTipDisabled: false }));

    if (
      !result.destination ||
      result.source.droppableId === result.destination.droppableId ||
      dragAndDropState.draggingSlotGroup == null ||
      dragAndDropState.draggingSlotGroup.some(
        slot =>
          slot.tee_time_booking_id == null || slot.blocked_type === "crossover" || slot.check_in_status === "checked",
      )
    ) {
      return;
    }

    const sourceRowId = Number(result.source.droppableId);
    const destRowId = Number(result.destination.droppableId);

    const updatedGroupedTeeTimes = cloneDeep(state.groupedTeeTimes);
    const backupGroupedTeeTimes = cloneDeep(state.groupedTeeTimes);

    const sourceTeeTime = updatedGroupedTeeTimes[sourceRowId];
    const destTeeTime = updatedGroupedTeeTimes[destRowId];

    let draggingToCrossover = false;

    destTeeTime.slots.forEach(slotGroup => {
      slotGroup.forEach(slot => {
        if (slot.blocked_type === "crossover") {
          draggingToCrossover = true;
        }
      });
    });

    if (draggingToCrossover) {
      return;
    }

    let moveBookingLength = 0;

    sourceTeeTime.slots.forEach(slotGroup => {
      slotGroup.forEach(slot => {
        if (slot.tee_time_booking_id === dragAndDropState.draggingSlotGroup[0].tee_time_booking_id) {
          moveBookingLength++;
        }
      });
    });

    const freeSlots: ITeeTimeSlotNew[] = [];

    destTeeTime.slots.forEach(slotGroup => {
      slotGroup.forEach(slot => {
        if (slot.tee_time_slot_status === "available") {
          freeSlots.push(slot);
        }
      });
    });

    if (moveBookingLength > freeSlots.length) {
      return;
    }

    const moveSlots = take(freeSlots, moveBookingLength);

    let destDraggingSlotToCopyIndex = 0;

    destTeeTime.slots.forEach(slotGroup => {
      slotGroup.forEach(slot => {
        if (moveSlots.some(moveSlot => moveSlot.tee_time_slot_id === slot.tee_time_slot_id)) {
          changeSlotBookingInformation(slot, dragAndDropState.draggingSlotGroup?.[destDraggingSlotToCopyIndex]);
          destDraggingSlotToCopyIndex++;
        }
      });
    });

    const updatedDestSlots: TDraggableSlot = [];

    destTeeTime.slots.forEach(slotGroup => {
      if (
        slotGroup.length === 1 &&
        slotGroup[0].tee_time_booking_id === dragAndDropState.draggingSlotGroup[0].tee_time_booking_id &&
        updatedDestSlots?.[updatedDestSlots.length - 1]?.[0]?.tee_time_booking_id ===
          dragAndDropState.draggingSlotGroup[0].tee_time_booking_id
      ) {
        updatedDestSlots[updatedDestSlots.length - 1].push(slotGroup[0]);
      } else {
        updatedDestSlots.push(slotGroup);
      }
    });

    destTeeTime.slots = updatedDestSlots;

    const updatedSourceSlots: TDraggableSlot = [];
    const shiftIndexes: number[] = [];

    sourceTeeTime.slots.forEach(slotGroup => {
      if (shiftIndexes.length > 0) {
        if (slotGroup.length === 1 && slotGroup[0].tee_time_slot_status === "available") {
          updatedSourceSlots.push(slotGroup);
          shiftIndexes.push(updatedSourceSlots.length - 1);
        } else if (slotGroup.every(slot => slot.tee_time_slot_status === "booked")) {
          const updatedSlotGroup = cloneDeep(slotGroup);

          const originalUpdateSlotGroup = cloneDeep(updatedSlotGroup);
          const originalSlotGroupLength = updatedSlotGroup.length;

          updatedSlotGroup.forEach(slot => {
            changeSlotBookingInformation(slot);
          });

          const availableSlots = chunk(updatedSlotGroup, 1);

          updatedSourceSlots.push(...availableSlots);
          shiftIndexes.push(...range(updatedSourceSlots.length - availableSlots.length, updatedSourceSlots.length));

          const slotIndexesToGroupAndBook = take(shiftIndexes, originalSlotGroupLength);

          const newSlotGroup: ITeeTimeSlotNew[] = [];

          let sourceDraggingSlotToCopyIndex = 0;

          for (const slotIndex of slotIndexesToGroupAndBook) {
            const slot = cloneDeep(updatedSourceSlots[slotIndex][0]);
            changeSlotBookingInformation(slot, originalUpdateSlotGroup?.[sourceDraggingSlotToCopyIndex]);

            newSlotGroup.push(slot);
            sourceDraggingSlotToCopyIndex++;
          }

          updatedSourceSlots.splice(slotIndexesToGroupAndBook[0], slotIndexesToGroupAndBook.length, newSlotGroup);

          shiftIndexes.splice(0, originalSlotGroupLength);
        }
      } else if (
        slotGroup.some(slot => slot.tee_time_booking_id === dragAndDropState.draggingSlotGroup[0].tee_time_booking_id)
      ) {
        const updatedSlotGroup = cloneDeep(slotGroup);

        updatedSlotGroup.forEach(slot => {
          changeSlotBookingInformation(slot);
        });

        const availableSlots = chunk(updatedSlotGroup, 1);

        updatedSourceSlots.push(...availableSlots);
        shiftIndexes.push(...range(updatedSourceSlots.length - availableSlots.length, updatedSourceSlots.length));
      } else {
        updatedSourceSlots.push(slotGroup);
      }
    });

    sourceTeeTime.slots = updatedSourceSlots;

    let sourceQuantityRemaining = 4;
    let destQuantityRemaining = 4;

    sourceTeeTime.slots.forEach(slotGroup => {
      slotGroup.forEach(slot => {
        if (slot.tee_time_slot_status === "booked") {
          sourceQuantityRemaining--;
        }
      });
    });

    destTeeTime.slots.forEach(slotGroup => {
      slotGroup.forEach(slot => {
        if (slot.tee_time_slot_status === "booked") {
          destQuantityRemaining--;
        }
      });
    });

    sourceTeeTime.slots.forEach(slotGroup => {
      slotGroup.forEach(slot => {
        slot.quantity_remaining = sourceQuantityRemaining;
      });
    });

    destTeeTime.slots.forEach(slotGroup => {
      slotGroup.forEach(slot => {
        slot.quantity_remaining = destQuantityRemaining;
      });
    });

    setState(prev => ({ ...prev, groupedTeeTimes: updatedGroupedTeeTimes }));

    const firstDestSlot = destTeeTime.slots[0][0];
    const destDivisions: number[] = JSON.parse(firstDestSlot.divisions_available);
    const draggingBookingHoles: number = dragAndDropState.draggingSlotGroup[0].tee_time_bookings_holes;

    if (!destDivisions.some(destDivision => destDivision === draggingBookingHoles / 9)) {
      setState(prev => ({ ...prev, groupedTeeTimes: backupGroupedTeeTimes }));
      dispatch(showError(`The tee time does not allow bookings for ${draggingBookingHoles} holes`));

      return;
    }

    let nineHoleAvailable = false;
    let eighteenHoleAvailable = false;

    destDivisions?.forEach(division => {
      if (division === 1) {
        nineHoleAvailable = true;
      } else if (division === 2) {
        const leagueId = firstDestSlot.league_id;
        const tournamentId = firstDestSlot.tournament_id;

        if (
          (destDivisions?.length > 1 && firstDestSlot.turn_tee_time_id == null) ||
          firstDestSlot?.turn_tee_time_blocked_type === "blocked" ||
          ((firstDestSlot?.turn_tee_time_league_id || firstDestSlot?.turn_tee_time_tournament_id) &&
            (leagueId !== firstDestSlot?.turn_tee_time_league_id ||
              tournamentId !== firstDestSlot?.turn_tee_time_tournament_id))
        ) {
          nineHoleAvailable = true;
        } else if (firstDestSlot.turn_tee_time_id != null) {
          if (firstDestSlot?.turn_tee_time_quantity_remaining >= moveBookingLength) {
            eighteenHoleAvailable = true;
          } else {
            nineHoleAvailable = true;
          }
        }
      }
    });

    if (!nineHoleAvailable && !eighteenHoleAvailable) {
      setState(prev => ({ ...prev, groupedTeeTimes: backupGroupedTeeTimes }));
      dispatch(showError("Bookings can't be moved into this teetime"));
      return;
    } else if (
      (draggingBookingHoles === 9 && !nineHoleAvailable) ||
      (draggingBookingHoles === 18 && !eighteenHoleAvailable)
    ) {
      setDragAndDropState(prev => ({
        ...prev,
        holeConversionWarningOpen: true,
        destTeeTimeId: firstDestSlot.tee_time_id,
        backupGroupedTeeTimes,
      }));

      return;
    }

    await moveBooking(
      dragAndDropState.draggingSlotGroup[0].tee_time_booking_id,
      firstDestSlot.tee_time_id,
      backupGroupedTeeTimes,
    );

    setDragAndDropState(prev => ({
      ...prev,
      draggingSlotGroup: undefined,
    }));
  }

  async function moveBooking(
    tee_time_booking_id: number,
    tee_time_id: number,
    backupGroupedTeeTimes: ITeeTimeDraggable[],
  ) {
    const putMove = await PutMoveBooking(
      {
        tee_time_booking_id,
        tee_time_id,
      },
      true,
    );

    if (putMove.status !== StatusCode.OK) {
      setState(prev => ({ ...prev, groupedTeeTimes: backupGroupedTeeTimes }));
      dispatch(showError("Error moving booking"));
      return;
    }

    props.reloadTeeTimes();
  }

  function closeMoveBookingPopup() {
    setDragAndDropState(prev => ({
      draggingSlotGroup: undefined,
      teetimeToolTipDisabled: false,
      holeConversionWarningOpen: false,
      holesConversionNumber: undefined,
      destTeeTimeId: undefined,
      backupGroupedTeeTimes: undefined,
    }));
  }

  function handleCancelMoveBookingPopup() {
    setState(prev => ({ ...prev, groupedTeeTimes: dragAndDropState.backupGroupedTeeTimes }));
    closeMoveBookingPopup();
  }

  // EVENT FUNCTIONS
  async function loadTeeSheetTournaments(ids: Array<number>, token?: CancelToken) {
    if (ids?.length > 0) {
      const tournamentRes = await GetTournament({ ids: ids }, false, token);

      if (token && token.reason) {
        return;
      }

      updateMainState({ teeSheetTournaments: tournamentRes.status === StatusCode.OK ? tournamentRes.data : [] });
    } else {
      updateMainState({ teeSheetTournaments: [] });
    }
  }

  async function loadTeeSheetLeagues(ids: Array<number>, token?: CancelToken) {
    if (ids?.length > 0) {
      const leagueRes = await GetLeague({ ids: ids }, false, token);

      if (token && token.reason) {
        return;
      }

      updateMainState({ teeSheetLeagues: leagueRes.status == StatusCode.OK ? leagueRes.data : [] });
    } else {
      updateMainState({ teeSheetLeagues: [] });
    }
  }

  async function printCartSigns(type: "league" | "tournament", date: string, eventId: number) {
    const cartSignsRes = await GetPrintCartSigns(
      {
        facility_id: clientFacilityStore.facility?.id,
        date: date,
        league_id: type === "league" ? eventId : undefined,
        tournament_id: type === "tournament" ? eventId : undefined,
      },
      true,
    );

    if (cartSignsRes.status !== StatusCode.OK) {
      dispatch(showError(t("elements.tee_sheet.tee_sheet_tabs.056")));
      return;
    }

    window.open().document.write(cartSignsRes.data);
  }

  async function handleExportShotgunTemplate(teeTime?: ITeeTimeNew) {
    const params = {
      shotgun_application_id: teeTime.slots[0].shotgun_application_id,
    };

    const exportRes = await ExportShotgunTemplate(params, true);

    if (exportRes.status !== StatusCode.OK) {
      dispatch(showError("Error exporting shotgun template")); // TODO: Translation
      return;
    }

    const templateExport = document.createElement("a");
    templateExport.href = "data:text/csv;charset=utf-8," + encodeURI(exportRes.data);
    templateExport.target = "_blank";
    templateExport.download = `${"Shotgun_Template"}_${moment(new Date()).format("YYYY-MM-DD").toString()}`
      .replace(/ /g, "_")
      .toLowerCase()
      .concat(".csv");
    templateExport.click();
  }

  async function removeShotgunTemplate() {
    const params = {
      facility_id: clientFacilityStore.facility?.id,
      tee_sheet_id: removeShotgunPopup.teetime.slots[0].tee_sheet_id,
      tournament_id: removeShotgunPopup.teetime.slots[0].tournament_id
        ? removeShotgunPopup.teetime.slots[0].tournament_id
        : null,
      league_id: removeShotgunPopup.teetime.slots[0].league_id ? removeShotgunPopup.teetime.slots[0].league_id : null,
      shotgun_application_id: removeShotgunPopup.teetime.slots[0].shotgun_application_id,
    };

    const removeRes = await RemoveShotgunTemplate(params, true);

    if (removeRes.status !== StatusCode.OK) {
      dispatch(showError(t("elements.tee_sheet.tee_sheet_tabs.057")));
      return;
    }

    dispatch(showSuccess(t("elements.tee_sheet.tee_sheet_tabs.058")));

    closeRemoveShotgunPopup();

    props.reloadTeeTimes();
  }

  function navigateToEventAccount(type: "league" | "tournament", eventId: number) {
    const event =
      type === "league"
        ? state.teeSheetLeagues.find(league => league.id === eventId)
        : state.teeSheetTournaments.find(tournament => tournament.id === eventId);

    history.push(`/admin/settings/accounts/overview/${event.account_id}`);
  }

  function tournamentName(id: number) {
    const tournament = state.teeSheetTournaments?.find(tournament => tournament.id === id);

    return tournament !== undefined ? tournament.name : t("elements.tee_sheet.tee_sheet_tabs.026");
  }

  function leagueName(id: number) {
    const league = state.teeSheetLeagues?.find(league => league.id === id);

    return league !== undefined ? league.name : t("elements.tee_sheet.tee_sheet_tabs.025");
  }

  function tournamentNote(id: number) {
    const tournament = state.teeSheetTournaments?.find(tournament => tournament.id === id);

    return tournament !== undefined ? tournament?.tee_sheet_note : "";
  }

  function handleOpenDetailsModal(slot: ITeeTimeSlotNew) {
    void loadTeeTimeDetails(slot);
  }

  const renderBookingParticipantIcons = (slot: ITeeTimeSlotNew) => {
    return (
      <>
        {slot.check_in_status === "checked" && (
          <FontAwesomeIcon icon={["fas", "check-circle"]} className="booking-participant_icon" />
        )}

        {slot.check_in_status !== "checked" && !slot.no_show_type && (
          <FontAwesomeIcon icon={["far", "circle"]} className="booking-participant_icon" />
        )}

        {slot.turn === true && <FontAwesomeIcon icon={["far", "repeat-alt"]} className="booking-participant_icon" />}

        {slot.order_financial_status === "paid" && (
          <FontAwesomeIcon icon={["fas", "usd-circle"]} className="booking-participant_icon" />
        )}

        {slot.no_show_type && slot.no_show_type !== "weather" && (
          <FontAwesomeIcon icon={["far", "ban"]} className="booking-participant_icon" />
        )}

        {slot.no_show_type === "weather" && (
          <FontAwesomeIcon icon={["far", "thunderstorm"]} className="booking-participant_icon" />
        )}

        <span className="booking-participant-holes-desktop">{slot.tee_time_bookings_holes}</span>
      </>
    );
  };

  const renderBookingCategoryIcons = (teeTimeId: number) => {
    if (!props.teetimeBookingCategories) {
      return;
    }

    const bookingCategories = props.teetimeBookingCategories[teeTimeId];

    if (!bookingCategories) {
      return;
    }

    return (
      <div className="booking-category-icons">
        {bookingCategories?.map((category: ITeeTimeBookingCategory, index: number) => {
          const categoryLabel = category?.title[0]?.toLocaleLowerCase();
          return (
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id={`category-${category.title}`}>{category.title}</Tooltip>}
              key={index}
            >
              <FontAwesomeIcon
                icon={["far", `circle-${categoryLabel}` as IconName]}
                className="booking-category-icon"
              />
            </OverlayTrigger>
          );
        })}
      </div>
    );
  };

  function handleSendToEditOrder(orderId: number) {
    window.open("/admin/order/" + String(orderId));
  }

  const renderTeeSheetTabs = () => {
    const sunsetSplitTime: string[] = clientFacilityStore?.weather?.sunset.split(":");

    return (
      <>
        <DragDropContext onDragStart={handleOnDragStart} onDragEnd={handleOnDragEnd}>
          <div className="dragDropContext_container">
            {state.sortedTeeTimes?.map((teetime, teetimeIndex: number, teetimes) => {
              const prevTeetime = teetimes[teetimeIndex - 1];
              const nextTeetime = teetimes[teetimeIndex + 1];

              // let turnArrow = false;

              // if (teetime.slots[0].turn_tee_time?.quantity_remaining < teetime.quantity_remaining) {
              //   turnArrow = true;
              // }

              return (
                <Fragment key={teetimeIndex}>
                  {/* LEAGUE/TOURNAMENT/SHOTGUN HEADER */}
                  {/* LEAGUE HEADER */}
                  {teetime.slots[0].league_id > 0 &&
                  (prevTeetime === undefined ||
                    prevTeetime.slots[0].league_id === null ||
                    prevTeetime.slots[0].league_id != teetime.slots[0].league_id) ? (
                    <div className="background-black text-white pl-4 pr-4 py-2 rounded-top">
                      <span>
                        <div className="flex justify-between">
                          <div>
                            <FontAwesomeIcon className="mr-2" icon={["far", "users"]} />
                            {state.teeSheetLeagues === undefined ? (
                              <span style={{ display: "inline-flex" }}>
                                <Spin />
                              </span>
                            ) : (
                              leagueName(teetime.slots[0].league_id)
                            )}
                          </div>
                          <div className="text-black">
                            <NavigationDropdownNew
                              showPlainTextLabel
                              rightAlign
                              label={
                                <FontAwesomeIcon className="th-ellipsis text-white" icon={["fas", "ellipsis-v"]} />
                              }
                              sections={[
                                [
                                  {
                                    icon: "print",
                                    label: t("elements.tee_sheet.tee_sheet_tabs.066"),
                                    onClick: () =>
                                      printCartSigns("league", teetime.slots[0].date, teetime.slots[0].league_id),
                                  },
                                  {
                                    icon: "dollar-sign",
                                    label: t("elements.tee_sheet.tee_sheet_tabs.067"),
                                    onClick: () => navigateToEventAccount("league", teetime.slots[0].league_id),
                                    hidden: !state.teeSheetLeagues?.find(
                                      league => league.id === teetime.slots[0].league_id,
                                    )?.account_id,
                                  },
                                  {
                                    icon: "trash",
                                    label: t("elements.tee_sheet.tee_sheet_tabs.068"),
                                    onClick: () => updateRemoveShotgunPopup({ isOpen: true, teetime }),
                                    hidden: teetime.slots[0].shotgun_application_id === null,
                                  },
                                  // {
                                  //   icon: "file-export",
                                  //   label: "Export Shotgun Template",
                                  //   onClick: () => handleExportShotgunTemplate(teetime),
                                  //   hidden: teetime.blocked_type !== "shotgun",
                                  // },
                                ],
                              ]}
                            />
                          </div>
                        </div>
                      </span>
                    </div>
                  ) : null}

                  {/* TOURNAMENT HEADER */}
                  {teetime.slots[0].tournament_id > 0 &&
                  (prevTeetime === undefined ||
                    prevTeetime.slots[0].tournament_id === null ||
                    prevTeetime.slots[0].tournament_id != teetime.slots[0].tournament_id) ? (
                    <div className="background-black text-white pl-4 pr-4 py-2 rounded-top">
                      <span>
                        <div className="flex justify-between">
                          <div>
                            <FontAwesomeIcon className="mr-2" icon={["far", "trophy"]} />
                            {state?.teeSheetTournaments === undefined ? (
                              <div style={{ display: "inline-flex" }}>
                                <Spin />
                              </div>
                            ) : (
                              tournamentName(teetime.slots[0].tournament_id)
                            )}
                          </div>
                          <div className="text-black">
                            <NavigationDropdownNew
                              showPlainTextLabel
                              rightAlign
                              // type=""
                              label={
                                <FontAwesomeIcon className="th-ellipsis text-white" icon={["fas", "ellipsis-v"]} />
                              }
                              sections={[
                                [
                                  {
                                    icon: "print",
                                    label: t("elements.tee_sheet.tee_sheet_tabs.066"),
                                    onClick: () =>
                                      printCartSigns(
                                        "tournament",
                                        teetime.slots[0].date,
                                        teetime.slots[0].tournament_id,
                                      ),
                                  },
                                  {
                                    icon: "dollar-sign",
                                    label: t("elements.tee_sheet.tee_sheet_tabs.067"),
                                    onClick: () => navigateToEventAccount("tournament", teetime.slots[0].tournament_id),
                                    hidden: !state.teeSheetTournaments?.find(
                                      tournament => tournament.id === teetime.slots[0].tournament_id,
                                    )?.account_id,
                                  },
                                  {
                                    icon: "trash",
                                    label: t("elements.tee_sheet.tee_sheet_tabs.068"),
                                    onClick: () => updateRemoveShotgunPopup({ isOpen: true, teetime }),
                                    hidden: teetime.slots[0].shotgun_application_id === null,
                                  },
                                  {
                                    icon: "file-export",
                                    label: "Export Shotgun Template",
                                    onClick: () => handleExportShotgunTemplate(teetime),
                                    hidden: teetime.slots[0].blocked_type !== "shotgun",
                                  },
                                ],
                              ]}
                            />
                          </div>
                        </div>
                      </span>
                      <p>
                        {tournamentNote(teetime.slots[0].tournament_id)
                          ? tournamentNote(teetime.slots[0].tournament_id).toLocaleLowerCase()
                          : ""}
                      </p>
                    </div>
                  ) : null}

                  {/* TEE TIME CONTAINER */}
                  <div
                    className={classNames("tee-time-new", {
                      "pb-3.5 background-darkgray event-side-border":
                        teetime.slots[0].league_id > 0 || teetime.slots[0].tournament_id > 0,
                      "pb-3.5 background-primary-light event-side-border":
                        teetime.slots[0].league_id === null &&
                        teetime.slots[0].tournament_id === null &&
                        teetime.slots[0].blocked_type === "shotgun",
                      "pt-3.5":
                        (teetime.slots[0].league_id > 0 &&
                          (prevTeetime === undefined ||
                            prevTeetime.slots[0].league_id === null ||
                            prevTeetime.slots[0].league_id != teetime.slots[0].league_id)) ||
                        (teetime.slots[0].tournament_id > 0 &&
                          (prevTeetime === undefined ||
                            prevTeetime.slots[0].tournament_id === null ||
                            prevTeetime.slots[0].tournament_id != teetime.slots[0].tournament_id)) ||
                        (teetime.slots[0].blocked_type === "shotgun" &&
                          (prevTeetime === undefined || prevTeetime.slots[0].blocked_type !== "shotgun")),
                      "rounded-bottom event-bottom-border":
                        (teetime.slots[0].league_id > 0 &&
                          (nextTeetime === undefined ||
                            nextTeetime.slots[0].league_id === null ||
                            nextTeetime.slots[0].league_id != teetime.slots[0].league_id)) ||
                        (teetime.slots[0].tournament_id > 0 &&
                          (nextTeetime === undefined ||
                            nextTeetime.slots[0].tournament_id === null ||
                            nextTeetime.slots[0].tournament_id != teetime.slots[0].tournament_id)) ||
                        (teetime.slots[0].blocked_type === "shotgun" &&
                          (nextTeetime === undefined || nextTeetime.slots[0].blocked_type !== "shotgun")),
                      "my-3.5":
                        windowSize.width > MOBILE_WIDTH &&
                        teetime.slots[0].league_id === null &&
                        teetime.slots[0].tournament_id === null &&
                        teetime.slots[0].blocked_type !== "shotgun" &&
                        prevTeetime !== undefined,
                      "my-1.5":
                        windowSize.width <= MOBILE_WIDTH &&
                        teetime.slots[0].league_id === null &&
                        teetime.slots[0].tournament_id === null &&
                        teetime.slots[0].blocked_type !== "shotgun" &&
                        prevTeetime !== undefined,
                    })}
                    key={teetimeIndex}
                    id={String(teetime.slots[0].tee_time_id)}
                    ref={elementScrollRef}
                  >
                    {/* SUNRISE MARKER */}
                    {((teetimeIndex === 0 && clientFacilityStore.weather?.sunrise < teetime?.start_time) ||
                      (clientFacilityStore.weather?.sunrise >= prevTeetime?.start_time &&
                        clientFacilityStore.weather?.sunrise < teetime?.start_time)) && (
                      <div className="flex items-center mb-3.5">
                        <div style={{ marginLeft: "6.4rem" }} className="sun-time-line"></div>
                        <span className="sun-time-hour">
                          <FontAwesomeIcon icon={["far", "sunrise"]} className="booking-participant_icon mt-1" />
                          {convertTime(clientFacilityStore.weather?.sunrise)}
                        </span>
                        <div style={{ marginRight: "8px" }} className="sun-time-line"></div>
                      </div>
                    )}

                    {/* HOUR MARKERS */}
                    {teetimeIndex === 0 ||
                    convertTime(teetime.start_time).split(":")[0] !==
                      convertTime(prevTeetime.start_time).split(":")[0] ? (
                      <div className="tee-time hour-block">
                        <p className="tee-time time tee-time hour">
                          {convertTime(teetime.start_time).split(":")[0] +
                            ":00 " +
                            convertTime(teetime.start_time).split(" ")[1]}
                        </p>
                        <div className="tee-time hour-line"></div>
                      </div>
                    ) : null}

                    {/* TEE TIME */}
                    <div className="tee-time_container">
                      {/* TEE TIME START TIME */}
                      <button className="tee-time-time cursor-pointer text-left" onClick={() => {}}>
                        <p
                          className={
                            teetime.slots[0].tournament_id || teetime.slots[0].league_id
                              ? "tee-time-time-text-event"
                              : "tee-time-time-text"
                          }
                        >
                          {teetime.slots[0].blocked_type === "shotgun" ||
                          teetime.slots[0].blocked_type === "shotgun_crossover" ? (
                            <span>{teetime.slots[0].start_hole}</span>
                          ) : (
                            <span>{convertTime(teetime.start_time, windowSize.width <= MOBILE_WIDTH)}</span>
                          )}
                        </p>
                      </button>

                      {/* DIVISION CODE */}
                      <p
                        className="tee-time-division-code"
                        style={{
                          backgroundColor: divisions
                            ? divisions?.find((division: IDivision) => division.id === teetime.slots[0].division_id)
                                ?.color
                            : "#e9e9e9",
                        }}
                      >
                        {divisions
                          ? divisions?.find((division: IDivision) => division.id === teetime.slots[0].division_id)?.code
                          : ""}
                      </p>

                      {/* SLOTS */}
                      <div className="tee-time-slots">
                        <Droppable
                          key={teetimeIndex.toString()}
                          droppableId={teetimeIndex.toString()}
                          type="LIST"
                          direction="horizontal"
                        >
                          {(provided, snapshot) => (
                            <span className="row-container" ref={provided.innerRef} {...provided.droppableProps}>
                              {state.groupedTeeTimes
                                ?.find(
                                  slotGroup => slotGroup.slots[0][0].tee_time_id === teetime.slots[0].tee_time_id,
                                )
                                ?.slots?.map((slotGroup, groupIndex: number) => {
                                  const slotId = slotGroup[0]?.tee_time_slot_id.toString();

                                  return (
                                    <Draggable
                                      key={slotId}
                                      draggableId={slotId}
                                      isDragDisabled={slotGroup.some(
                                        slot =>
                                          slot.tee_time_booking_id == null ||
                                          slot.blocked_type === "crossover" ||
                                          slot.check_in_status === "checked",
                                      )}
                                      index={groupIndex}
                                    >
                                      {provided => (
                                        <div
                                          className={classNames(
                                            "tee-time-slots_container",
                                            `tee-time-slot_spaced_${slotGroup.length}`,
                                            {
                                              "tee-time-slots_last": teetime.slots.length === groupIndex + 1,
                                            },
                                          )}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          ref={provided.innerRef}
                                        >
                                          {slotGroup.map(
                                            (slot, slotIndex: number) => {
                                              // BOOKED SLOT
                                              if (slot.customer_id) {
                                                return (
                                                  <div
                                                    key={slotIndex}
                                                    className={classNames("tee-time-slot", {
                                                      "tee-time-slot_checked": slot.check_in_status === "checked",
                                                      "tee-time-slot_crossover": slot.blocked_type === "crossover",
                                                      "tee-time-slot_blocked": slot.blocked_type === "blocked",
                                                      "tee-time-slot_no-show": slot.no_show_type !== null,
                                                      "tee-time-slot_turn": slot.turn,
                                                      "tee-time-slot_no-show-weather": slot.no_show_type === "weather",
                                                      "tee-time-slot_booking_engine":
                                                        slot.tee_time_bookings_source === "booking_engine",
                                                      "tee-time-slot_has_notes": slot.has_notes,
                                                      "tee-time-slot_customer-type-display": true,
                                                    })}
                                                    style={{ backgroundColor: findCustomerTypeBackground(slot) }}
                                                    onClick={
                                                      slot.check_in_status === "checked" ||
                                                      slot.check_in_status === "no_show" ||
                                                      slot.check_in_status === "no_show_weather"
                                                        ? () => handleOpenDetailsModal(slot)
                                                        : () => handleEditBooking(teetime, slot.tee_time_booking_id)
                                                    }
                                                  >
                                                    <RightClickMenu
                                                      informationSection={[
                                                        `${t("elements.tee_sheet.tee_sheet_tabs.070")} ${moment
                                                          .utc(slot.tee_time_bookings_completed_at)
                                                          .local()
                                                          .format("lll")}`,
                                                        ...(slot.no_show_type
                                                          ? [
                                                              <Badge
                                                                key="no_show_badge"
                                                                size="xsmall"
                                                                colour="#FDB022"
                                                                outline
                                                              >
                                                                {slot.no_show_type === "defer"
                                                                  ? "Defer Charge"
                                                                  : valueToString(slot.no_show_type)}
                                                              </Badge>,
                                                            ]
                                                          : []),
                                                      ]}
                                                      sections={[
                                                        [
                                                          {
                                                            icon: "pencil",
                                                            title: t("elements.tee_sheet.tee_sheet_tabs.071"),
                                                            action: () =>
                                                              handleEditBooking(teetime, slot.tee_time_booking_id),
                                                          },
                                                          {
                                                            icon: "ban",
                                                            title: t("elements.tee_sheet.tee_sheet_tabs.072"),
                                                            action: () => {
                                                              const teetimeToCancelDate = moment(slot.date).format(
                                                                "MMMM Do, YYYY",
                                                              );
                                                              const teetimeToCancelTime = convertTime(
                                                                teetime.start_time,
                                                              );

                                                              updateCancelBookingPopup({
                                                                isOpen: true,
                                                                teetimeToCancelDate,
                                                                teetimeToCancelTime,
                                                                teetimeToCancelBookingId: slot.tee_time_booking_id,
                                                              });
                                                            },
                                                            disabled: checkForCheckedInPlayer(slotGroup),
                                                          },
                                                          {
                                                            icon: "user-slash",
                                                            title: t("elements.tee_sheet.tee_sheet_tabs.126"),
                                                            action: () => {
                                                              updateCancelPlayerPopup({
                                                                isOpen: true,
                                                                bookingParticipantName: `${slot.customer_first_name} ${slot.customer_last_name}`,
                                                                bookingParticipantId: slot.booking_participant_id,
                                                              });
                                                            },
                                                            disabled: slot.check_in_status !== "unchecked",
                                                          },
                                                          {
                                                            icon: "cancel",
                                                            title: t("elements.tee_sheet.tee_sheet_tabs.073"),
                                                            action: () => {
                                                              updateNoShowModal({
                                                                isOpen: true,
                                                                bookingParticipantId: slot.booking_participant_id,
                                                              });
                                                            },
                                                            disabled:
                                                              slot.tee_time_booking_participant_status === "no_show",
                                                          },
                                                          {
                                                            icon: "list",
                                                            title: t("elements.tee_sheet.tee_sheet_tabs.074"),
                                                            action: () =>
                                                              updateAuditTrailModal({
                                                                isOpen: true,
                                                                teetime,
                                                                type: "booking",
                                                                bookingId: slot.tee_time_booking_id,
                                                              }),
                                                          },
                                                        ],
                                                        [
                                                          ...(slot.shotgun_application_id
                                                            ? [
                                                                {
                                                                  icon: "golf-ball-tee" as IconName,
                                                                  title: "Edit Start Hole",
                                                                  action: () =>
                                                                    updateEditStartHoleModal({
                                                                      isOpen: true,
                                                                      teetime,
                                                                      start_hole: teetime.slots[0].start_hole,
                                                                    }),
                                                                },
                                                              ]
                                                            : []),
                                                          {
                                                            icon: "circle-info",
                                                            title: t("elements.tee_sheet.tee_sheet_tabs.075"),
                                                            action: () =>
                                                              updateTeeTimeDetailsModal({ isOpen: true, teetime }),
                                                          },
                                                          {
                                                            icon: "list",
                                                            title: t("elements.tee_sheet.tee_sheet_tabs.076"),
                                                            action: () =>
                                                              updateAuditTrailModal({
                                                                isOpen: true,
                                                                teetime,
                                                                type: "teeTime",
                                                              }),
                                                          },
                                                          {
                                                            icon: "flag",
                                                            title: t("elements.tee_sheet.tee_sheet_tabs.077"),
                                                            action: () =>
                                                              updateCreateShotgunModal({ isOpen: true, teetime }),
                                                          },
                                                          {
                                                            icon: "distribute-spacing-vertical",
                                                            title: slot.shotgun_application_id
                                                              ? "Add Tee Time"
                                                              : "Add Squeeze Time",
                                                            action: () =>
                                                              slot.shotgun_application_id
                                                                ? updateAddTeeTimeModal({ isOpen: true, teetime })
                                                                : updateAddSqueezeTimeModal({
                                                                    isOpen: true,
                                                                    teetime,
                                                                  }),
                                                          },
                                                        ],
                                                      ]}
                                                    >
                                                      <div className="tee-time-slot_content">
                                                        <div className="tee-time-slot-booking-participant">
                                                          <div className="booking-participant_content">
                                                            {windowSize.width > MOBILE_WIDTH &&
                                                              renderBookingParticipantIcons(slot)}

                                                            <div>
                                                              <div className="booking-participant-name">
                                                                <span>
                                                                  {`${slot.customer_first_name} ${slot.customer_last_name}`}
                                                                  {slot.guest ? (
                                                                    <FontAwesomeIcon
                                                                      style={{ marginLeft: "0.5rem" }}
                                                                      icon={["fas", "circle-g"]}
                                                                      size="1x"
                                                                    />
                                                                  ) : null}
                                                                </span>
                                                              </div>

                                                              <div className="booking-participant-customer-type">
                                                                <span>{slot.customer_type_title}</span>
                                                                {slot.customer_type_subtitle ? (
                                                                  <span>{` - ${slot.customer_type_subtitle}`}</span>
                                                                ) : null}
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className="booking-participant-secondary_content">
                                                            <div className="booking-participant-holes-mobile">
                                                              {slot.tee_time_bookings_holes}
                                                            </div>
                                                            <div className="booking-participant-secondary_content-icons">
                                                              {windowSize.width <= MOBILE_WIDTH &&
                                                                renderBookingParticipantIcons(slot)}

                                                              {slot.tee_time_bookings_source === "booking_engine" && (
                                                                <FontAwesomeIcon
                                                                  icon={["far", "earth-americas"]}
                                                                  className="booking-participant_icon"
                                                                />
                                                              )}

                                                              {slot.tee_time_bookings_source === "golfnow" && (
                                                                <div className={"golfNow-logo"}>
                                                                  <img src="/public/images/gn-logo-small.svg" />
                                                                </div>
                                                              )}

                                                              {slot.has_notes && !slot.guest ? (
                                                                <FontAwesomeIcon
                                                                  icon={["far", "sticky-note"]}
                                                                  className="booking-participant_icon"
                                                                />
                                                              ) : null}

                                                              {slot.power_cart_quantity > 0 ? (
                                                                <div className="booking-participant_icon">
                                                                  <CustomIcon
                                                                    src="../../../public/images/powercart2-01.svg"
                                                                    size="small"
                                                                  />
                                                                </div>
                                                              ) : (
                                                                <FontAwesomeIcon
                                                                  icon={["far", "person-walking"]}
                                                                  className="booking-participant_icon"
                                                                />
                                                              )}

                                                              {slot.bag_number !== null && (
                                                                <CustomIcon
                                                                  src="../../../public/images/golf-bag.svg"
                                                                  size="small"
                                                                />
                                                              )}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </RightClickMenu>
                                                  </div>
                                                );
                                              } else {
                                                // UNBOOKED TEE TIME
                                                return (
                                                  <div
                                                    key={slotIndex}
                                                    className={classNames("tee-time-slot", {
                                                      "tee-time-slot_crossover":
                                                        slot.blocked_type === "crossover" ||
                                                        slot.blocked_type === "shotgun_crossover",
                                                      "tee-time-slot_blocked":
                                                        slot.blocked_type === "blocked" ||
                                                        slot.blocked_type === "shotgun_blocked" ||
                                                        slot.blocked_type === "shotgun_disabled",
                                                      "tee-time-slot_no-show":
                                                        slot.no_show_type && slot.no_show_type !== "weather",
                                                      "tee-time-slot_no-show-weather": slot.no_show_type === "weather",
                                                      "tee-time-slot_customer-type-display": true,
                                                    })}
                                                    onClick={() => {
                                                      if (
                                                        teetime.slots[0].blocked_type === "shotgun_blocked" ||
                                                        teetime.slots[0].blocked_type === "blocked"
                                                      ) {
                                                        updateMainState({ selectedTeeTime: teetime });
                                                        updateBlockedTeeTimePopup({ isOpen: true });
                                                      } else {
                                                        void handleInitiateBooking(teetime);
                                                      }
                                                    }}
                                                  >
                                                    <TeeTimeToolTip
                                                      prepaidRequired={false}
                                                      cardRequired={false}
                                                      parentId={slot.tee_time_slot_id}
                                                      onMouseEnter={token => handleMouseEnter(teetime, token)}
                                                      disable={dragAndDropState.teetimeToolTipDisabled}
                                                      // disable={
                                                      //   bookingState.teetimeToolTipDisabled
                                                      //     ? true
                                                      //     : slot.tee_time_booking_id
                                                      //     ? true
                                                      //     : false
                                                      // }
                                                    >
                                                      <RightClickMenu
                                                        sections={[
                                                          //
                                                          //   {
                                                          //     icon: "circle",
                                                          //     title: t("elements.tee_sheet.tee_sheet_tabs.079"),
                                                          //     action: () =>  setBlockedType(teetime, "open"),
                                                          //     disabled: slot.blocked_type === "open",
                                                          //   },
                                                          //   {
                                                          //     icon: "ban",
                                                          //     title: t("elements.tee_sheet.tee_sheet_tabs.080"),
                                                          //     action: () => setBlockedType(teetime, "blocked"),
                                                          //     disabled: slot.blocked_type === "blocked",
                                                          //   },
                                                          //   {
                                                          //     icon: "arrows-repeat",
                                                          //     title: t("elements.tee_sheet.tee_sheet_tabs.081"),
                                                          //     action: () => setBlockedType(teetime, "crossover"),
                                                          //     disabled: slot.blocked_type === "crossover",
                                                          //   },
                                                          // ],
                                                          [
                                                            ...(slot.shotgun_application_id
                                                              ? [
                                                                  {
                                                                    icon: "golf-ball-tee" as IconName,
                                                                    title: "Edit Start Hole",
                                                                    action: () =>
                                                                      updateEditStartHoleModal({
                                                                        isOpen: true,
                                                                        teetime,
                                                                        start_hole: teetime.slots[0].start_hole,
                                                                      }),
                                                                  },
                                                                ]
                                                              : []),
                                                            {
                                                              icon: "circle-info",
                                                              title: t("elements.tee_sheet.tee_sheet_tabs.082"),
                                                              action: () =>
                                                                updateTeeTimeDetailsModal({
                                                                  isOpen: true,
                                                                  teetime: teetime,
                                                                }),
                                                            },
                                                            {
                                                              icon: "list",
                                                              title: t("elements.tee_sheet.tee_sheet_tabs.083"),
                                                              action: () =>
                                                                updateAuditTrailModal({
                                                                  isOpen: true,
                                                                  teetime: teetime,
                                                                  type: "teeTime",
                                                                }),
                                                            },
                                                            {
                                                              icon: "flag",
                                                              title: t("elements.tee_sheet.tee_sheet_tabs.084"),
                                                              action: () =>
                                                                updateCreateShotgunModal({ isOpen: true, teetime }),
                                                            },
                                                            {
                                                              icon: "distribute-spacing-vertical",
                                                              title: slot.shotgun_application_id
                                                                ? "Add Tee Time"
                                                                : "Add Squeeze Time",
                                                              action: () =>
                                                                slot.shotgun_application_id
                                                                  ? updateAddTeeTimeModal({ isOpen: true, teetime })
                                                                  : updateAddSqueezeTimeModal({
                                                                      isOpen: true,
                                                                      teetime,
                                                                    }),
                                                            },
                                                            {
                                                              icon: "ban",
                                                              title: "Remove Tee Time",
                                                              action: () =>
                                                                updateRemoveTeeTimePopup({ isOpen: true, teetime }),
                                                              disabled: slot.quantity_remaining !== 4,
                                                            },
                                                          ],
                                                        ]}
                                                      >
                                                        <div className="tee-time-slot_content">
                                                          {slot.blocked_type !== null &&
                                                          slot.blocked_type !== "open" ? (
                                                            <div className="">
                                                              {slot.blocked_type === "blocked" && (
                                                                <p className="text-center">
                                                                  <FontAwesomeIcon
                                                                    icon={["far", "ban"]}
                                                                    className="booking-participant_icon"
                                                                  />
                                                                </p>
                                                              )}

                                                              {slot.blocked_type === "crossover" && (
                                                                <p className="text-center">
                                                                  <FontAwesomeIcon
                                                                    icon={["far", "repeat-alt"]}
                                                                    className="booking-participant_icon"
                                                                  />
                                                                </p>
                                                              )}

                                                              {slot.blocked_type === "shotgun" && (
                                                                <p className="text-center">
                                                                  <FontAwesomeIcon
                                                                    icon={["far", "plus-circle"]}
                                                                    className="booking-participant_icon"
                                                                  />
                                                                </p>
                                                              )}

                                                              {slot.blocked_type === "shotgun_blocked" && (
                                                                <p className="text-center">
                                                                  <FontAwesomeIcon
                                                                    icon={["far", "ban"]}
                                                                    className="booking-participant_icon"
                                                                  />
                                                                </p>
                                                              )}

                                                              {slot.blocked_type === "shotgun_disabled" && (
                                                                <p className="text-center">
                                                                  <em>{t("elements.tee_sheet.tee_sheet_tabs.029")}</em>
                                                                </p>
                                                              )}

                                                              {slot.blocked_type === "shotgun_crossover" && (
                                                                <p className="text-center">
                                                                  <FontAwesomeIcon
                                                                    icon={["far", "repeat-alt"]}
                                                                    className="booking-participant_icon"
                                                                  />
                                                                </p>
                                                              )}
                                                            </div>
                                                          ) : (
                                                            <div>
                                                              <p className="text-center">
                                                                <FontAwesomeIcon
                                                                  icon={["far", "plus-circle"]}
                                                                  className="booking-participant_icon"
                                                                />
                                                              </p>
                                                            </div>
                                                          )}
                                                        </div>
                                                      </RightClickMenu>
                                                    </TeeTimeToolTip>
                                                  </div>
                                                );
                                              }
                                            }, // slot group map
                                          )}
                                        </div> // tee-time-slots_container
                                      )}
                                    </Draggable>
                                  );
                                })}
                              {provided.placeholder}
                            </span>
                          )}
                        </Droppable>
                      </div>
                      {windowSize.width > MOBILE_WIDTH && renderBookingCategoryIcons(teetime?.slots[0]?.tee_time_id)}
                    </div>

                    {/* SUNSET MARKER */}
                    {((teetimeIndex === props.teetimes?.length - 1 &&
                      clientFacilityStore.weather?.sunset > teetime?.start_time) ||
                      (clientFacilityStore.weather?.sunset <= nextTeetime?.start_time &&
                        clientFacilityStore.weather?.sunset > teetime?.start_time)) && (
                      <div className="flex items-center mb-3.5">
                        <div style={{ marginLeft: "6.4rem" }} className="sun-time-line"></div>
                        <span className="sun-time-hour">
                          <FontAwesomeIcon icon={["far", "sunset"]} className="booking-participant_icon mt-1" />
                          {convertTime(clientFacilityStore.weather?.sunset)}
                        </span>
                        <div style={{ marginRight: "8px" }} className="sun-time-line"></div>
                      </div>
                    )}
                  </div>
                </Fragment>
              );
            })}
          </div>
        </DragDropContext>
      </>
    );
  };

  return (
    <>
      {state.groupedTeeTimes ? (
        <Card.Group className="tee-sheet-tabs-scroll">{renderTeeSheetTabs()}</Card.Group>
      ) : (
        <div style={{ height: "24px" }}>
          <Spin />
        </div>
      )}

      {/* MODALS */}
      {/* NEW BOOKING MODAL */}
      <BookingModalNew
        teetimes={props.teetimes}
        loading={newBookingModal.loading}
        loadingText={newBookingModal.loadingText}
        reloadTeeTimes={props.reloadTeeTimes}
      />

      {/* NO SHOW MODAL */}
      <Portal isMounted={noShowModal.isOpen}>
        <Popup
          open={noShowModal.isOpen}
          type="info"
          title={t("elements.tee_sheet.tee_sheet_tabs.118")}
          description={t("elements.tee_sheet.tee_sheet_tabs.119")}
          onOk={handleNoShow}
          okText={t("elements.tee_sheet.tee_sheet_tabs.120")}
          okDisabled={!noShowModal.type || !noShowModal.bookingParticipantId}
          cancelText={t("elements.tee_sheet.tee_sheet_tabs.121")}
          onCancel={() =>
            updateNoShowModal({
              isOpen: false,
              type: "charge",
              bookingParticipantId: null,
            })
          }
        >
          <Select defaultValue={noShowModal.type} onChange={(value: string) => updateNoShowModal({ type: value })}>
            <Select.Option value={"charge"} name={"Charge"}>
              <span>{t("elements.tee_sheet.tee_sheet_tabs.122")}</span>
            </Select.Option>
            <Select.Option value={"defer"} name={"Defer Charge"}>
              <span>{t("elements.tee_sheet.tee_sheet_tabs.123")}</span>
            </Select.Option>
            <Select.Option value={"weather"} name={"Weather"}>
              <span>{t("elements.tee_sheet.tee_sheet_tabs.124")}</span>
            </Select.Option>
          </Select>
        </Popup>
      </Portal>

      {/* CREATE SHOTGUN MODAL */}
      <Portal isMounted={createShotgunModal.isOpen}>
        <CreateShotgunModal
          open={createShotgunModal.isOpen}
          teetime={createShotgunModal.teetime}
          divisions={divisions}
          bookingCategories={bookingCategories}
          templates={shotgunTemplates}
          onOk={() => {
            closeCreateShotgunModal();
            props.reloadTeeTimes();
          }}
          onCancel={closeCreateShotgunModal}
        />
      </Portal>

      {/* AUDIT TRAIL MODAL */}
      <Portal isMounted={auditTrailModal.isOpen}>
        <AuditTrailModal
          open={auditTrailModal.isOpen}
          teetime={auditTrailModal.teetime}
          bookingId={auditTrailModal.bookingId}
          onCancel={() =>
            updateAuditTrailModal({
              isOpen: false,
              teetime: null,
              type: null,
            })
          }
          type={auditTrailModal.type}
        />
      </Portal>

      {/* TEE TIME DETAILS MODAL */}
      <Portal isMounted={teeTimeDetailsModal.isOpen}>
        <TeeTimeDetailsModal
          open={teeTimeDetailsModal.isOpen}
          teetime={teeTimeDetailsModal.teetime}
          bookingCategories={bookingCategories}
          onCancel={closeTeeTimeDetailsModal}
        />
      </Portal>

      {/* ADD TEE TIME MODAL */}
      <Portal isMounted={addTeeTimeModal.isOpen}>
        <AddTeeTimeModal
          open={addTeeTimeModal.isOpen}
          teetime={addTeeTimeModal.teetime}
          onOk={() => {
            closeAddTeeTimeModal();
            props.reloadTeeTimes();
          }}
          onCancel={closeAddTeeTimeModal}
        />
      </Portal>

      {/* ADD SQUEEZE TIME POPUP */}
      <Portal isMounted={addSqueezeTimeModal.isOpen}>
        <Popup
          open={addSqueezeTimeModal.isOpen}
          type="warning"
          title={"Add Squeeze Time?"}
          description={"Are you sure you want to add a squeeze time?"}
          onOk={createSqueezeTime}
          okText={"Add"}
          onCancel={closeAddSqueezeTimeModal}
          backDropCancel={true}
        />
      </Portal>

      {/* EDIT START HOLE MODAL */}
      <Portal isMounted={editStartHoleModal.isOpen}>
        <EditStartHoleModal
          open={editStartHoleModal.isOpen}
          teetime={editStartHoleModal.teetime}
          onOk={() => {
            closeEditStartHoleModal();
            props.reloadTeeTimes();
          }}
          onCancel={closeEditStartHoleModal}
        />
      </Portal>

      {/* EDIT BOOKING MODAL */}
      <Portal isMounted={editBookingModal.isOpen}>
        <BookingModalEdit
          selectedBooking={editBookingModal.selectedBooking}
          teeTimeLock={editBookingModal.teeTimeLock}
          refreshTeeTime={true}
          loading={editBookingModal.loading}
          loadingText={editBookingModal.loadingText}
          reloadTeeTimes={props.reloadTeeTimes}
        />
      </Portal>

      {/* POPUPS */}
      {/* BLOCKED TEE TIME POPUP */}
      <Portal isMounted={blockedTeeTimePopup.isOpen}>
        <Popup
          open={blockedTeeTimePopup.isOpen}
          type="warning"
          title={t("elements.tee_sheet.tee_sheet_tabs.030")}
          description={t("elements.tee_sheet.tee_sheet_tabs.031")}
          onOk={() => {
            closeBlockedTeeTimePopup();
            void handleInitiateBooking(state.selectedTeeTime);
          }}
          okText={t("elements.tee_sheet.tee_sheet_tabs.032")}
          onCancel={closeBlockedTeeTimePopup}
          backDropCancel={true}
        />
      </Portal>

      {/* CANCEL BOOKING POPUP */}
      <Portal isMounted={cancelBookingPopup.isOpen}>
        <Popup
          open={cancelBookingPopup.isOpen}
          type="warning"
          title={t("elements.tee_sheet.tee_sheet_tabs.086")}
          description={`${t("elements.tee_sheet.tee_sheet_tabs.087")} ${cancelBookingPopup?.teetimeToCancelDate} at ${
            cancelBookingPopup?.teetimeToCancelTime
          }?`}
          onOk={cancelBooking}
          okText={t("elements.tee_sheet.tee_sheet_tabs.088")}
          onCancel={() =>
            updateCancelBookingPopup({
              isOpen: false,
              teetimeToCancelDate: "",
              teetimeToCancelTime: "",
              teetimeToCancelBookingId: null,
            })
          }
          backDropCancel={false}
        />
      </Portal>

      {/* CANCEL PLAYER POPUP */}
      <Portal isMounted={cancelPlayerPopup.isOpen}>
        <Popup
          open={cancelPlayerPopup.isOpen}
          type="warning"
          title={t("elements.tee_sheet.tee_sheet_tabs.089")}
          description={`${t("elements.tee_sheet.tee_sheet_tabs.090")} ${cancelPlayerPopup.bookingParticipantName}?`}
          onOk={cancelBookingParticipant}
          okText={t("elements.tee_sheet.tee_sheet_tabs.091")}
          onCancel={() =>
            updateCancelPlayerPopup({
              isOpen: false,
              bookingParticipantName: "",
              bookingParticipantId: null,
            })
          }
          backDropCancel={false}
        />
      </Portal>

      {/* TEE TIME BOOKED POPUP */}
      <Portal isMounted={teeTimeBookedPopup.isOpen}>
        <Popup
          open={teeTimeBookedPopup.isOpen}
          type="warning"
          title={t("elements.tee_sheet.tee_sheet_tabs.016")}
          description={t("elements.tee_sheet.tee_sheet_tabs.017")}
          onOk={closeTeeTimeBookedPopup}
          okText={t("elements.tee_sheet.tee_sheet_tabs.032")}
          onCancel={closeTeeTimeBookedPopup}
          backDropCancel={false}
        />
      </Portal>

      {/* TEE TIME LOCKED POPUP */}
      <Portal isMounted={teeTimeLockedPopup.isOpen}>
        <Popup
          open={teeTimeLockedPopup.isOpen}
          type="warning"
          title={t("elements.tee_sheet.tee_sheet_tabs.014")}
          description={t("elements.tee_sheet.tee_sheet_tabs.015")}
          onOk={closeTeeTimeLockedPopup}
          okText={t("elements.tee_sheet.tee_sheet_tabs.032")}
          onCancel={closeTeeTimeLockedPopup}
          backDropCancel={true}
        />
      </Portal>

      {/* REMOVE TEE TIME POPUP */}
      <Portal isMounted={removeTeeTimePopup.isOpen}>
        <Popup
          open={removeTeeTimePopup.isOpen}
          stacked
          type="warning"
          title={t("elements.tee_sheet.tee_sheet_tabs.128")}
          description={t("elements.tee_sheet.tee_sheet_tabs.129")}
          onOk={removeTeeTime}
          okText={t("elements.tee_sheet.tee_sheet_tabs.130")}
          onCancel={closeRemoveTeeTimePopup}
          backDropCancel={false}
        />
      </Portal>

      {/* REMOVE SHOTGUN POPUP */}
      <Portal isMounted={removeShotgunPopup.isOpen}>
        <Popup
          open={removeShotgunPopup.isOpen}
          stacked
          type="warning"
          title={t("elements.tee_sheet.tee_sheet_tabs.099")}
          description={t("elements.tee_sheet.tee_sheet_tabs.100")}
          onOk={removeShotgunTemplate}
          okText={t("elements.tee_sheet.tee_sheet_tabs.101")}
          onCancel={closeRemoveShotgunPopup}
          backDropCancel={false}
        />
      </Portal>

      {/* DRAG AND DROP HOLE CONVERSION POPUP */}
      <Portal isMounted={dragAndDropState.holeConversionWarningOpen}>
        <Popup
          open={dragAndDropState.holeConversionWarningOpen}
          stacked
          type="warning"
          title={"Conversion required"}
          description={`Are you ok with this booking being converted from ${
            dragAndDropState.draggingSlotGroup?.[0]?.tee_time_bookings_holes
          } holes to ${dragAndDropState.draggingSlotGroup?.[0]?.tee_time_bookings_holes === 9 ? 18 : 9} holes?`}
          onOk={async () => {
            await moveBooking(
              dragAndDropState.draggingSlotGroup[0].tee_time_booking_id,
              dragAndDropState.destTeeTimeId,
              dragAndDropState.backupGroupedTeeTimes,
            );
            closeMoveBookingPopup();
          }}
          onCancel={handleCancelMoveBookingPopup}
          backDropCancel={false}
        />
      </Portal>

      {/* VIEW DETAILS POPUP */}
      <Portal isMounted={viewDetailsState.modalOpen}>
        <Sheet
          open={viewDetailsState.modalOpen}
          size={
            viewDetailsState?.slots?.length === 4
              ? "large"
              : viewDetailsState?.slots?.length === 3
              ? "large"
              : viewDetailsState?.slots?.length === 2
              ? "medium"
              : viewDetailsState?.slots?.length === 1
              ? "small"
              : "medium"
          }
          closable
          height="flexible"
          title={"Check-In Details"}
          onSecondary={() =>
            setViewDetailsState({
              modalOpen: false,
              slot: null,
              slots: null,
            })
          }
          onCancel={() =>
            setViewDetailsState({
              modalOpen: false,
              slot: null,
              slots: null,
            })
          }
          secondaryText={"Close"}
          overflow
          hideCancelButton
        >
          <div className="check-in-details-box">
            {viewDetailsState?.slots?.map((slot: ISlot, index: number) => {
              console.log("slots", viewDetailsState.slots);
              return (
                <div
                  className={classNames("check-in-details", {
                    "check-in-details-separated":
                      index > 0 &&
                      viewDetailsState.slots.length !== index + 1 &&
                      viewDetailsState?.slots[index]?.booking?.id != viewDetailsState?.slots[index + 1]?.booking?.id,
                    "check-in-details-grouped":
                      (index > 0 || index === 0) &&
                      viewDetailsState.slots.length !== index + 1 &&
                      viewDetailsState?.slots[index]?.booking?.id === viewDetailsState?.slots[index + 1]?.booking?.id,
                  })}
                  key={index}
                >
                  <div>
                    <div className="check-in-details_customer_bar">
                      <div className="check-in-details_customer_bar_image">
                        <Avatar
                          size="xlarge"
                          source={
                            slot?.booking_participant?.customer?.profile_image_source === null ||
                            slot?.booking_participant?.customer?.customer_type === "guest"
                              ? "/public/images/default_profile_image.svg"
                              : slot?.booking_participant?.customer?.profile_image_source
                          }
                        />
                      </div>

                      <div>
                        <div className="check-in-details_customer_bar_name">
                          {slot?.booking_participant?.full_name}
                          {slot?.booking_participant?.guest && <FontAwesomeIcon icon={["fas", "circle-g"]} size="1x" />}
                        </div>
                        <p className="check-in-details_customer_bar_name text-gray-400 text-sm">
                          {slot?.booking_participant?.customer?.customer_type}
                        </p>
                        {/* Checking Details Icons */}
                        <div>
                          <div className="flex gap-2 items-center mt-2 ml-2 mb-2">
                            {slot?.booking_participant?.check_in_status === "checked" && (
                              <FontAwesomeIcon icon={["fas", "check-circle"]} className="booking-participant_icon" />
                            )}

                            {slot?.booking_participant?.order_financial_status === "paid" && (
                              <FontAwesomeIcon icon={["fas", "usd-circle"]} className="booking-participant_icon" />
                            )}

                            {!!slot?.booking_participant?.no_show && (
                              <div className="flex gap-2">
                                <FontAwesomeIcon icon={["fas", "ban"]} className="booking-participant_icon" />
                                <Badge key={index} size="xsmall" colour="#FDB022" outline>
                                  {slot?.booking_participant?.no_show_type === "defer"
                                    ? "Defer Charge"
                                    : // ? slot?.booking_participant?.no_show_type.concat(" Charge")
                                      capitalize(slot?.booking_participant?.no_show_type)}
                                </Badge>
                              </div>
                            )}

                            {!!slot?.booking_participant?.no_show_weather && (
                              <div className="flex gap-2">
                                <FontAwesomeIcon icon={["fas", "thunderstorm"]} className="booking-participant_icon" />
                                <Badge key={index} size="xsmall" colour="#FDB022" outline>
                                  {"Weather"}
                                </Badge>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="check-in-details_products">
                    {slot?.booking_participant?.green_fee_product_title ? (
                      <div className="flex flex-column mt-2 mb-2">
                        <p className="check-in-details_product_title">
                          {slot?.booking_participant?.green_fee_product_title}
                        </p>

                        {slot?.booking_participant?.green_fee_variant_title !==
                          slot?.booking_participant?.green_fee_product_title && (
                          <p className="check-in-details_variant_title text-gray-600">
                            {slot?.booking_participant?.green_fee_variant_title}
                          </p>
                        )}
                      </div>
                    ) : null}
                    {slot?.booking_participant?.power_cart_product_title &&
                    slot?.booking_participant?.power_cart_paid ? (
                      <div className="flex mt-2 mb-2">
                        <p className={"check-in-details_product_title"}>
                          {slot?.booking_participant?.power_cart_product_title}
                        </p>

                        {slot?.booking_participant?.power_cart_variant_title !==
                          slot?.booking_participant?.power_cart_product_title && (
                          <>
                            <p className="check-in-details_variant_title text-gray-600">
                              {slot?.booking_participant?.power_cart_variant_title}
                            </p>
                          </>
                        )}

                        {slot?.booking_participant?.power_cart_number && (
                          <p className="check-in-details_variant_title">
                            Power Cart #: {slot?.booking_participant?.power_cart_number} &nbsp;
                          </p>
                        )}
                      </div>
                    ) : null}
                  </div>

                  <div className="order-container">
                    <Divider />
                    {slot?.booking_participant?.order_name ? (
                      <div
                        className="order-container_order cursor-pointer"
                        onClick={() => handleSendToEditOrder(slot?.booking_participant?.order_id)}
                      >
                        <span className="order-container_order_number">
                          Order {slot?.booking_participant?.order_name}
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
        </Sheet>
      </Portal>
    </>
  );
}
