import { APIGet, APIPost, APIPut, APIDelete, StatusCode, IAPIResponse } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const baseCustomerUrl = "/" + apiVersion + "/customer/tee-time";

export async function LockTeeTime(params: { tee_time_id: number }, useGlobalLoader: boolean) {
  return await APIPost(baseCustomerUrl + "/lock", params, useGlobalLoader);
}

export async function DeleteLockTeeTime(useGlobalLoader: boolean) {
  return await APIDelete(baseCustomerUrl + "/lock", null, useGlobalLoader);
}
