import React, { useEffect, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";
import validator from "validator";
import moment from "moment";

import { StatusCode } from "api/protocols";
import { GetEmailTemplate } from "api/rpc/2024-04/facilityAdmin/facility/emailTemplate";

import { showError } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";
import { ignoreOrderCompare } from "helpers/Helpers";

import FormLayout from "components/form/FormLayout";
import Sheet from "components/sheet/Sheet";
import Tabs from "components/tabs/Tabs";
import Input from "components/form/input/Input";
import Checkbox from "components/form/checkbox/Checkbox";
import { Select } from "components/select/index";
import TextEditor from "components/textEditor/textEditor";
import TagInput from "components/tagInput/TagInput";
import Spin from "components/spin/spin";
import Portal from "elements/Portal";
import { TEmailTemplate } from "pages/secure/facility/settings/facility/emailTemplates/EmailTemplates";
import { TRecipient } from "./StatisticsBar";

export interface IEmailTeeSheet {
  subject: string;
  content: string;
  recipientIds: any[];
  newEmail: string;
  additionalEmails: any[];
}

type TEmailTeesheetModal = {
  state: { isOpen: boolean } & IEmailTeeSheet;
  update: (newState: IEmailTeeSheet) => void;
  close: () => void;
  recipients: TRecipient[];
  onOk: () => void;
};

export default function EmailTeesheetModal(props: TEmailTeesheetModal) {
  const { t } = useTranslation();
  const { state, update, close, recipients, onOk } = props;

  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState(0);

  const [emailTemplates, setEmailTemplates] = useState<TEmailTemplate[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (state.isOpen) {
      void loadEmailTemplates(source.token);
    }
    return () => source.cancel();
  }, [state.isOpen]);

  // Load templates on open
  async function loadEmailTemplates(token?: CancelToken) {
    if (emailTemplates !== undefined) {
      setEmailTemplates(undefined);
    }

    const res = await GetEmailTemplate({ type: "tee_sheet" }, token ? false : true, token);
    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError(t("elements.tee_sheet.statistic_bar.014")));
    }

    setEmailTemplates(res.status !== StatusCode.OK ? [] : res.data);
  }

  // Only update state if template is found
  function handleTemplateChange(id: number) {
    if (emailTemplates?.some(template => template.id === id)) {
      const template = emailTemplates.find(template => template.id === id);
      update({ ...state, subject: template.subject, content: template.content });
    }
  }

  function handleAddNewEmail(event: KeyboardEvent) {
    if (event.key === "Enter" || event.key === ",") {
      const emails = state.additionalEmails.slice();
      const email = state.newEmail;

      if (!validator.isEmail(email)) {
        dispatch(showError(t("elements.tee_sheet.email_tee_sheet_modal.001")));
        return;
      }
      let isAdded = false;

      emails.filter(filtered => {
        if (filtered.toLowerCase() === email.toLowerCase()) {
          isAdded = true;
        }
      });

      if (isAdded) {
        dispatch(showError(t("elements.tee_sheet.email_tee_sheet_modal.002")));
        return;
      }

      emails.push(email);
      update({ ...state, additionalEmails: emails, newEmail: "" });
    }
  }

  function handleRemoveNewEmail(index: any) {
    console.log(index);
    const emails = state.additionalEmails.slice();
    emails.splice(index, 1);

    update({ ...state, additionalEmails: emails });
  }

  return (
    <Portal isMounted={state.isOpen}>
      <Sheet
        open={state.isOpen}
        size="medium"
        closable
        cancelText={t("elements.tee_sheet.statistic_bar.007")}
        okText={t("elements.tee_sheet.statistic_bar.008")}
        title={t("elements.tee_sheet.statistic_bar.009")}
        onCancel={() => {
          close(), setSelected(0);
        }}
        onOk={onOk}
      >
        <Tabs tabs={tabs(t)} selected={selected} onSelect={index => setSelected(index)}>
          {/* Booking Details */}
          {selected === 0 ? (
            <div className="mt-4 flex flex-col gap-2">
              <FormLayout>
                <FormLayout.Group>
                  <Input
                    type="text"
                    label={t("elements.tee_sheet.statistic_bar.010")}
                    onChange={e => update({ ...state, subject: e.target.value })}
                    value={state.subject}
                  />
                  <Select
                    label={t("elements.tee_sheet.email_tee_sheet_modal.003")}
                    onChange={(value: number) => handleTemplateChange(value)}
                    defaultValue={0}
                  >
                    <Select.Option key={-1} value={0} name={t("elements.tee_sheet.email_tee_sheet_modal.004")}>
                      {t("elements.tee_sheet.email_tee_sheet_modal.004")}
                    </Select.Option>
                    {emailTemplates === undefined ? (
                      <Select.Option value={-1} disabled>
                        <div style={{ height: "40px", padding: "8px 16px" }}>
                          <Spin />
                        </div>
                      </Select.Option>
                    ) : (
                      emailTemplates.map(template => {
                        return (
                          <Select.Option key={template.id} value={template.id} name={template.title}>
                            {template.title}
                          </Select.Option>
                        );
                      })
                    )}
                  </Select>
                </FormLayout.Group>
              </FormLayout>
              <TextEditor
                label={t("elements.tee_sheet.statistic_bar.012")}
                markdownText={state.content ?? ""}
                markdownTextOnChange={value => update({ ...state, content: value })}
              />
            </div>
          ) : null}
          {selected === 1 ? (
            <FormLayout>
              <FormLayout.Group>
                <div>
                  {console.log(recipients)}
                  {console.log(state.recipientIds)}
                  <div className="mt-4 mb-4">
                    <Checkbox
                      id={"select-all"}
                      size="medium"
                      checked={ignoreOrderCompare(
                        recipients.map(val => val.id),
                        state.recipientIds,
                      )}
                      onChange={() =>
                        update({
                          ...state,
                          recipientIds: ignoreOrderCompare(
                            recipients.map(val => val.id),
                            state.recipientIds,
                          )
                            ? []
                            : [...recipients.map(val => val.id)],
                        })
                      }
                      label={t("elements.tee_sheet.statistic_bar.011")}
                    />
                  </div>

                  <div>
                    {recipients.map(recipient => {
                      return (
                        <div key={recipient.id}>
                          <Checkbox
                            id={String(recipient.id)}
                            size="large"
                            checked={state.recipientIds.some(val => val === recipient.id)} // DOUBLE CHECK
                            onChange={e =>
                              update({
                                ...state,
                                recipientIds: state.recipientIds.some(val => val === recipient.id)
                                  ? state.recipientIds.filter(val => val !== recipient.id)
                                  : [...state.recipientIds, recipient.id],
                              })
                            }
                            label={moment(recipient.start_time, "hh:mm:ss").format("h:mm A")}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div>
                  <div className="mt-4 mb-4">
                    <TagInput
                      label={t("elements.tee_sheet.email_tee_sheet_modal.005")}
                      value={state.newEmail}
                      onChange={e =>
                        update({ ...state, newEmail: e.target.value !== "," ? e.target.value : state.newEmail })
                      }
                      onKeyDown={handleAddNewEmail}
                      placeholder={t("elements.tee_sheet.email_tee_sheet_modal.006")}
                      tags={state.additionalEmails}
                      onTagClick={(index: number) => handleRemoveNewEmail(index)}
                      helpText={t("elements.tee_sheet.email_tee_sheet_modal.007")}
                    />
                  </div>
                </div>
              </FormLayout.Group>
            </FormLayout>
          ) : null}
        </Tabs>
      </Sheet>
    </Portal>
  );
}

const tabs = (t: TFunction<"translation", undefined>) => [
  {
    id: "email-1",
    content: t("elements.tee_sheet.statistic_bar.005"),
    panelID: "email-content-1",
  },

  {
    id: "recipients-1",
    content: t("elements.tee_sheet.statistic_bar.015"),
    panelID: "recipients-content-1",
  },
];
