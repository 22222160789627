import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/pro-regular-svg-icons";

import "elements/register/register.scss";

import Radio from "components/radio/index";

interface IProps {
  onChange?: (paymentOption: Record<string, any>) => void;
  paymentOptions: Record<string, any>[];
  cardReaderAvailable?: boolean;
  cart: any;
  paymentAmount: number;
  darkStyle?: boolean;
  value?: Record<string, any>;
  manualOnly?: boolean;
}

export default function PaymentMethods(props: IProps) {
  return (
    <div>
      {/* <p className="text-md mb-2 font-bold">Payment Method</p> */}
      <Radio.Group
        onChange={props.onChange}
        type={3}
        className="flex-row flex-wrap"
        defaultValue={props.paymentOptions?.find(paymentOption => paymentOption.payment_method === "cash")}
        value={props.value ? props.value : undefined}
        darkStyle={props.darkStyle ? true : false}
      >
        {props.paymentOptions.length >= 1 &&
          props.paymentOptions.map((paymentOption: any) => {
            let icon: IconName;

            switch (paymentOption.title) {
              case "Interac":
                icon = "credit-card";
                break;

              case "Manual Credit":
                icon = "credit-card-front";
                break;

              case "Cash":
                icon = "money-bill";
                break;

              default:
                icon = "dollar-sign";
                break;
            }

            return (
              <Radio.Button
                disabled={
                  props.paymentAmount < 0 && paymentOption.payment_method !== "account"
                    ? true
                    : !props.cardReaderAvailable && paymentOption.processing_type === "terminal"
                    ? true
                    : paymentOption.payment_method === "account" && !props.cart?.customer
                    ? true
                    : (paymentOption.payment_method === "card" || paymentOption.payment_method === "manual_card") &&
                      props.paymentAmount < 0.5
                    ? true
                    : props.manualOnly &&
                      (paymentOption.type !== "manual" ||
                        paymentOption.payment_method === "gift_card" ||
                        paymentOption.payment_method === "credit_book") // Temporary check for invoice payments
                    ? true
                    : false
                }
                value={paymentOption}
                id={paymentOption.id}
                key={paymentOption.id}
                icon={<FontAwesomeIcon fixedWidth icon={["far", icon]} style={{ fontSize: "14px" }} />}
                paymentMethod
              >
                <span className="flex flex-1 text-center self-center justify-self-center">{paymentOption.title}</span>
              </Radio.Button>
            );
          })}
      </Radio.Group>
    </div>
  );
}
