import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { StatusCode } from "api/protocols";
import { GetInventoryRecordsAll, TInventory } from "api/rpc/2024-04/facilityAdmin/product/inventory";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import { showError } from "redux/actions/ui";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";

import "./inventory.scss";

const TABLE_LIMIT = 50;

export default function Inventory() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const [inventory, setInventory] = useState<TInventory[]>(undefined);

  const [filterState, setFilterState] = useState<{ offset: number }>({
    offset: 0,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadInventoryRecords(source.token);
    return () => source.cancel();
  }, [filterState.offset]);

  async function loadInventoryRecords(token?: CancelToken) {
    if (inventory !== undefined) {
      setInventory(undefined);
    }

    const res = await GetInventoryRecordsAll(
      { offset: filterState.offset, limit: TABLE_LIMIT },
      token ? false : true,
      token,
    );
    if (token && token.reason) {
      return;
    }

    if (res.status !== StatusCode.OK) {
      setInventory([]);
      dispatch(showError(t("secure.facility.settings.inventory.inventory.002")));
      return;
    }

    setInventory(res.data);
  }

  const primaryAction = {
    content: t("secure.facility.settings.inventory.inventory.003"),
    action: () => history.push("/admin/settings/inventory/new"),
  };

  return (
    <Page title={t("secure.facility.settings.inventory.inventory.006")} narrow primaryAction={primaryAction}>
      <div className="inventory-settings">
        <DataTable
          columns={[
            { label: t("secure.facility.settings.inventory.inventory.007") },
            { label: t("secure.facility.settings.inventory.inventory.008") },
            { label: "Vendor" }, // TODO: Translation
          ]}
          loading={inventory === undefined}
          footer={{
            tableLimit: TABLE_LIMIT,
            tableOffset: filterState.offset,
            disableNextOffset: !inventory || !(inventory?.length == TABLE_LIMIT),
            handleTableOffset: direction =>
              setFilterState(prev => ({
                ...prev,
                offset: direction === "prev" ? prev.offset - TABLE_LIMIT : prev.offset + TABLE_LIMIT,
              })),
          }}
        >
          {inventory?.map(record => (
            <tr
              key={record.id}
              className="clickable"
              onClick={() => history.push("/admin/settings/inventory/" + String(record.id))}
            >
              <td>
                <span>{moment(record.processed_at?.split(" ")[0]).format("LL")}</span>
                &nbsp;
                <span>{moment.utc(record?.processed_at?.split(" ")[1], "hh:mm:ss").local().format("h:mm A")}</span>
              </td>
              <td>{record.admin_user_full_name}</td>
              <td>{record?.vendor_title}</td>
            </tr>
          ))}
        </DataTable>
      </div>
    </Page>
  );
}
