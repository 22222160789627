import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import axios, { CancelToken } from "axios";

import { GetTeeTime } from "api/rpc/2024-04/clientAdmin/teesheet/teeSheet";
import { StatusCode } from "api/protocols";

import { capitalize, convertTime } from "helpers/Helpers";
import { showError } from "redux/actions/ui";

import Sheet from "components/sheet/Sheet";
import Spin from "components/spin/spin";
import { Badge } from "components/badge/Badge";

import { ITeeTimeNew, ITeeTime, IBookingCategory } from "redux/reducers/models/teetime";

interface ITeeTimeDetailsModal {
  open: boolean;
  teetime: ITeeTimeNew;
  bookingCategories: IBookingCategory[];
  onCancel: () => void;
}

export default function TeeTimeDetailsModal(props: ITeeTimeDetailsModal) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { clientFacilityStore } = useAppSelector(store => store);

  const { open, teetime, bookingCategories, onCancel } = props;

  const [loadedTeeTime, setLoadedTeeTime] = useState<ITeeTime>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadTeeTime(source.token);
    return () => source.cancel();
  }, []);

  async function loadTeeTime(token?: CancelToken) {
    if (!teetime) {
      return;
    }

    const teetimeRes = await GetTeeTime(
      {
        facility_id: clientFacilityStore.facility?.id,
        id: teetime.slots[0].tee_time_id,
      },
      false,
      token,
    );

    if (teetimeRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading tee time")); // TODO: Translation
      return;
    }

    setLoadedTeeTime(teetimeRes.data[0]);
  }

  function getTeeTimeType(teetime: ITeeTime) {
    if (!loadedTeeTime) {
      return "Standard";
    }

    if (loadedTeeTime.league_id) {
      return "League";
    } else if (loadedTeeTime.tournament_id) {
      return "Tournament";
    } else if (loadedTeeTime.shotgun_application_id) {
      return "Shotgun";
    } else {
      return "Standard";
    }
  }

  return (
    <Sheet
      open={open}
      size={"small"}
      closable
      title={`${t("elements.tee_sheet.tee_sheet_tabs.102")} ${
        loadedTeeTime ? convertTime(loadedTeeTime?.start_time) : null
      }`}
      onCancel={() => onCancel()}
      cancelText={t("elements.tee_sheet.tee_sheet_tabs.103")}
    >
      <div className="teetime-modal-details">
        <p className="teetime-modal-details-options">{t("elements.tee_sheet.tee_sheet_tabs.104")}</p>
        <div className="flex mt-4 mb-4">
          <p className="teetime-modal-details-title">{t("elements.tee_sheet.tee_sheet_tabs.105")}</p>
          <p className="teetime-modal-details-options">{getTeeTimeType(loadedTeeTime)}</p>
        </div>
        <div className="flex mt-4 mb-4">
          <p className="teetime-modal-details-title">{t("elements.tee_sheet.tee_sheet_tabs.035")}</p>

          {bookingCategories ? (
            <div className="teetime-modal-badge-container">
              {bookingCategories.map((category: any, index: number) => {
                let type: "primary" | "success" | "warning" | "error" | "gray" | "public";

                switch (category.title) {
                  case "Public":
                    type = "public";
                    break;
                  case "Member":
                    type = "error";
                    break;
                  case "GolfNow":
                    type = "warning";
                    break;

                  default:
                    type = "gray";
                    break;
                }

                return (
                  <Badge key={index} size="medium" type={type}>
                    {category.title}
                  </Badge>
                );
              })}
            </div>
          ) : (
            <div style={{ height: "25px", width: "25px" }}>
              <Spin />
            </div>
          )}
        </div>
        <div className="flex mt-4 mb-4">
          <p className="teetime-modal-details-title">{t("elements.tee_sheet.tee_sheet_tabs.038")}</p>
          <p className="teetime-modal-details-options">{capitalize(loadedTeeTime?.cart_rule)}</p>
        </div>
        <div className="flex mt-4 mb-4">
          <p className="teetime-modal-details-title">{t("elements.tee_sheet.tee_sheet_tabs.037")}</p>

          <div className="teetime-modal-badge-container">
            {loadedTeeTime?.credit_card_required ? (
              <Badge size="medium" type="gray">
                {t("elements.tee_sheet.tee_sheet_tabs.036")}
              </Badge>
            ) : null}

            {loadedTeeTime?.pre_paid_required ? (
              <Badge size="medium" type="gray">
                {t("elements.tee_sheet.tee_sheet_tabs.039")}
              </Badge>
            ) : null}
          </div>
        </div>
      </div>
    </Sheet>
  );
}
