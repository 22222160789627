import { IPutTeeSheetSettings } from "api/rpc/2022-09/facilityAdmin/facility/settings";
import { IKitchenCourse } from "pages/secure/facility/settings/kitchenCourse/KitchenCourse";
import { IFacility, IFacilityStaff, TWeather } from "./models/facility";
import { TProductRequirements } from "./models/product";
import { IRegister } from "pages/secure/facility/Admin";

export interface IFacilityState {
  isLoaded: boolean;
  error?: any;
  facility?: IFacility;
  weather?: TWeather;
  paymentOptions: any[];
  discountOptions: any[];
  teeSheetSettings?: IPutTeeSheetSettings;
  printerSettings?: any;
  kitchenCourses?: Array<IKitchenCourse>;
  facilities: IFacility[];
  staffAccounts: IFacilityStaff[];
  registers: IRegister[];
  validationNewProduct: {
    required?: TProductRequirements;
  };
  selectedFloorPlanId: number;
}

export interface IFacilityAction {
  type: string;
  payload: any;
}

const initialState: IFacilityState = {
  isLoaded: false,
  error: null,
  facility: null,
  weather: null,
  paymentOptions: [],
  discountOptions: [],
  teeSheetSettings: null,
  printerSettings: null,
  kitchenCourses: [],
  facilities: [],
  staffAccounts: [],
  registers: [],
  validationNewProduct: {
    required: undefined,
  },
  selectedFloorPlanId: null,
};

export default function FacilityReducer(state = initialState, action: IFacilityAction) {
  switch (action.type) {
    case "facility.update":
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        ...state,
        ...(action.payload as IFacilityState),
      };
    default:
      return state;
  }
}
