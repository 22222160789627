import { TFunction } from "react-i18next";
import { IOptions } from "../Admin";

export default function tournamentOptions(t: TFunction<"translation", undefined>, tournamentId: number): IOptions[] {
  if (isNaN(tournamentId)) {
    return [];
  } else {
    return [
      {
        label: "Home",
        url: `/admin/tournament/${tournamentId}/home`, //Temporary route
      },
      {
        label: "Players",
        url: `/admin/tournament/${tournamentId}/players`,
      },
      {
        label: "Scoring",
        url: `/admin/tournament/${tournamentId}/scores`,
      },
      {
        label: "Settings",
        url: `/admin/tournament/${tournamentId}/tournament-settings`,
        subOptions: [
          {
            label: "General",
            url: `/admin/tournament/${tournamentId}/tournament-settings/general`,
          },
          {
            label: "Registration Fees",
            url: `/admin/tournament/${tournamentId}/tournament-settings/registration-fees`,
          },
        ],
      },
    ];
  }
}
