import { APIGet, APIPost, APIPut, APIDelete, StatusCode, IAPIResponse } from "api/protocols";
import { CancelToken } from "axios";
import { IReservationBooking } from "redux/reducers/models/reservations";

const apiVersion = "2024-04";
const adminType = "customer";
const baseReservationUrl = "/" + apiVersion + "/" + adminType + "/reservation";

interface IPostReservationBooking {
  module_id: number;
  location_id: number;
  facility_id: number;
  segment_ids: Array<number>;
  date: string;
  quantity: number;
  accept_payment_terms: boolean;
  customer_payment_method_id: number;
}

interface IPutReservation {
  id: number;
  segment_ids: Array<number>;
  date: string;
  quantity: number;
  location_id: number;
}

interface IGetReservationBooking {
  id?: number;
  token?: string;
  offset?: number;
  limit?: number;
  extended?: boolean;
}

interface IGetReservationModule {
  facility_short_name?: string;
  handle?: string;
  id?: number;
}

interface IGetReservationLocation {
  id?: number;
  facility_id?: number;
}

interface IGetReservationSegment {
  id?: number;
  module_id?: number;
  date?: string;
  start_time?: string;
  location_id?: number;
}

export async function GetReservationBooking(
  params: IGetReservationBooking,
  useGlobalLoader: boolean,
  cancelToken?: CancelToken,
): Promise<IAPIResponse<Array<IReservationBooking>>> {
  return await APIGet(baseReservationUrl + "/booking", params, useGlobalLoader, cancelToken);
}

export async function PostReservationBooking(params: IPostReservationBooking, useGlobalLoader: boolean) {
  return await APIPost(baseReservationUrl + "/booking", params, useGlobalLoader);
}

export async function PutReservationBooking(params: IPutReservation, useGlobalLoader: boolean) {
  return await APIPut(baseReservationUrl + "/booking", params, useGlobalLoader);
}

export async function CancelReservationBooking(params: { id: number }, useGlobalLoader: boolean) {
  return await APIPut(baseReservationUrl + "/booking/cancel", params, useGlobalLoader);
}

export async function GetReservationModule(params: IGetReservationModule, useGlobalLoader: boolean) {
  return await APIGet(baseReservationUrl + "/module", params, useGlobalLoader);
}

export async function GetReservationModuleAll(params: IGetReservationModule, useGlobalLoader: boolean) {
  return await APIGet(baseReservationUrl + "/module/all", params, useGlobalLoader);
}

export async function GetReservationLocation(params: IGetReservationLocation, useGlobalLoader: boolean) {
  return await APIGet(baseReservationUrl + "/location", params, useGlobalLoader);
}

export async function GetCustomerReservationSegment(params: IGetReservationSegment, useGlobalLoader: boolean) {
  return await APIGet(baseReservationUrl + "/segment", params, useGlobalLoader);
}
