import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import Page from "components/page/Page";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Form from "components/form/Form";
import Input from "components/form/input/Input";
import { Select } from "components/select/index";
import { StatusCode } from "api/protocols";
import ColorPicker from "components/colorpicker/color-picker";
import { useTranslation } from "react-i18next";
import { isEqualWith, isNull } from "lodash";
import { GetTag, UpdateTag } from "api/rpc/2024-04/facilityAdmin/client/tags";
import { showError } from "redux/actions/ui";
import { useDispatch } from "react-redux";
import { TTagType } from "redux/reducers/models/customer";

interface ICustomerTagState {
  label: string;
  description: string;
  colour: any;
}

export default function EditCustomerTags(props: any) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { Option } = Select;
  const { id }: any = useParams();
  const dispatch = useDispatch();

  const [customerTagState, setCustomerTagState] = useState<ICustomerTagState>({
    label: "",
    description: "",
    colour: "",
  });

  const [tagStateBeforeChanges, setTagStateBeforeChanges] = useState<ICustomerTagState>(undefined);
  const [tagLoaded, setTagLoaded] = useState<boolean>(true);

  useEffect(() => {
    void loadCustomerTags();
  }, []);

  async function loadCustomerTags() {
    const params = {
      type: "customer" as TTagType,
      id: id,
    };

    const getTagRes = await GetTag(params, true);

    const tag = getTagRes.data[0];

    if (getTagRes.status !== StatusCode.OK) {
      dispatch(showError("Error getting tags"));
      return;
    }

    setCustomerTagState(prevState => ({
      ...prevState,
      label: tag.label,
      description: tag.description,
      colour: tag.colour,
    }));

    setTagStateBeforeChanges(prevState => ({
      ...prevState,
      label: tag.label,
      description: tag.description,
      colour: tag.colour,
    }));

    setTagLoaded(true);
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setCustomerTagState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleColorPick(codeColors: string) {
    setCustomerTagState(prevState => ({ ...prevState, colour: codeColors }));
  }

  const secondaryAction = {
    content: "Delete",
    action: handleDeleteCustomerTag,
  };

  function handleDeleteCustomerTag() {
    //delete tag
  }

  function unsavedChangesExist() {
    if (tagStateBeforeChanges === undefined) {
      if (tagLoaded) {
        setTagStateBeforeChanges(customerTagState);
      }
      return false;
    }

    return !isEqualWith(tagStateBeforeChanges, customerTagState, (originalValue, newValue) => {
      if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
        return true;
      }
    });
  }

  function cancelUnsavedChanges() {
    setCustomerTagState(tagStateBeforeChanges);
  }

  async function saveNewCustomerTag() {
    const params = {
      id: id,
      type: "customer" as TTagType,
      description: customerTagState.description,
      colour: customerTagState.colour,
      label: customerTagState.label,
    };

    const updateTagRes = await UpdateTag(params, true);

    if (updateTagRes.status !== StatusCode.OK) {
      dispatch(showError("Error updating tags"));
      return;
    }

    const tag = updateTagRes.data;

    setCustomerTagState(prevState => ({
      ...prevState,
      label: tag.label,
      description: tag.description,
      colour: tag.colour,
    }));

    setTagStateBeforeChanges(prevState => ({
      ...prevState,
      label: tag.label,
      description: tag.description,
      colour: tag.colour,
    }));
  }

  return (
    <Page
      title={customerTagState.label}
      narrow
      // secondaryActions={[secondaryAction]}
      breadcrumbs={[
        {
          prefix: true,
          label: "Customer Tags",
          url: "/admin/settings/customer_tags",
        },
      ]}
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: saveNewCustomerTag,
        onCancel: cancelUnsavedChanges,
      }}
    >
      <Card>
        <Card.Section>
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input value={customerTagState.label} label={"Label"} id="label" onChange={handleInputChange} />
                <Input
                  value={customerTagState.description}
                  label={"Description"}
                  id="description"
                  onChange={handleInputChange}
                />
              </FormLayout.Group>

              <FormLayout.Group>
                <div className="mt-2">
                  <p>Colour</p>
                  <ColorPicker colorValue={customerTagState.colour} onClick={handleColorPick} />
                </div>
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
      </Card>
    </Page>
  );
}
