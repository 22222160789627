import { APIGet, APIPost, APIPut, APIDelete, StatusCode } from "api/protocols";

/* eslint-disable @typescript-eslint/unbound-method */
const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/payment/tee-on-payment/reader";

interface IGetCardReader {
  id?: number;
  device_type?: string;
  status?: string;
}

interface IDeleteCardReader {
  id: number;
}
// GET Card Reader
// Retrieve a list of card readers for a location
export async function GetCardReaders(params?: IGetCardReader, useGlobalLoader?: boolean) {
  return await APIGet(baseUrl, params, useGlobalLoader);
}

// POST Card Reader
// Allows a new card reader to be set up for a specific location
export async function PostCardReader(params: { label: string; registration_code: string }, useGlobalLoader?: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

export async function PutCardReader(params: any, useGlobalLoader?: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

export async function PutCancelServerReaderPayment(params: { external_reader_id: string }, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/cancel", params, useGlobalLoader);
}

export async function GetReaderPaymentStatus(
  params: { payment_intent_id: string | number },
  useGlobalLoader?: boolean,
) {
  return await APIGet(baseUrl + "/payment-status", params, useGlobalLoader);
}

export async function DeleteCardReader(params: IDeleteCardReader, useGlobalLoader?: boolean) {
  return await APIDelete(baseUrl, params, useGlobalLoader);
}
