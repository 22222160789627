import React from "react";
import { ButtonNew as Button } from "components/buttonNew";
import Layout from "components/layout/index";
import { useHistory, Link } from "react-router-dom";

import Page from "components/page/Page";
import Card from "components/card/Card";

import Category from "elements/settings/Category";

import { useTranslation, Trans } from "react-i18next";

const TeeSheetSettings = () => {
  const { t, i18n } = useTranslation();
  const options = [
    {
      label: t("secure.facility.settings.tee_sheets.tee_sheet_settings.001"),
      description: t("secure.facility.settings.tee_sheets.tee_sheet_settings.002"),
      url: "/admin/settings/tee-sheet/general",
      icon: "circle",
    },
    {
      label: t("secure.facility.settings.tee_sheets.tee_sheet_settings.003"),
      description: t("secure.facility.settings.tee_sheets.tee_sheet_settings.004"),
      url: "/admin/settings/tee-sheet/booking-engine",
      icon: "circle",
    },
    {
      label: t("secure.facility.settings.tee_sheets.tee_sheet_settings.005"),
      description: t("secure.facility.settings.tee_sheets.tee_sheet_settings.006"),
      url: "/admin/settings/tee-sheet/template",
      icon: "table",
    },
    {
      label: t("secure.facility.settings.tee_sheets.tee_sheet_settings.007"),
      description: t("secure.facility.settings.tee_sheets.tee_sheet_settings.008"),
      url: "/admin/settings/tee-sheet/calendar",
      icon: "calendar",
    },
    {
      label: t("secure.facility.settings.tee_sheets.tee_sheet_settings.009"),
      description: t("secure.facility.settings.tee_sheets.tee_sheet_settings.010"),
      url: "/admin/settings/tee-sheet/pricing-sheet",
      icon: "dollar",
    },
    {
      label: t("secure.facility.settings.tee_sheets.tee_sheet_settings.011"),
      description: t("secure.facility.settings.tee_sheets.tee_sheet_settings.012"),
      url: "/admin/settings/tee-sheet/check-in-config",
      icon: "dollar",
    },
    {
      label: t("secure.facility.settings.tee_sheets.tee_sheet_settings.013"),
      description: t("secure.facility.settings.tee_sheets.tee_sheet_settings.014"),
      url: "/admin/settings/tee-sheet/customer-type-config",
      icon: "",
    },
  ];

  return (
    <Page
      title={t("secure.facility.settings.tee_sheets.tee_sheet_settings.015")}
      narrow
      breadcrumbs={[{ label: t("secure.facility.settings.tee_sheets.tee_sheet_settings.016"), url: "/admin/settings" }]}
    >
      <Card>
        <Card.Section>
          {options.map((option: any, index: number) => {
            return <Category key={index} label={option.label} url={option.url} />;
          })}
        </Card.Section>
      </Card>
    </Page>
  );
};

export default TeeSheetSettings;
