import { GetCustomer } from "api/rpc/customer";
// import { LocalStorage } from "api/localstorage";
// import { ICartState } from "reducers/cart";
import { StatusCode } from "api/protocols";

export function loadCustomer(id?: number, extended?: boolean) {
  return async (dispatch: any) => {
    const res = await GetCustomer({ id: id, extended: extended, memberships: true }, false);
    if (res.status !== StatusCode.OK) {
      console.log(res);
      return;
    }

    const customer = res.data.data[0];
    console.log("Customer", customer);

    dispatch({
      type: "update.customer",
      payload: {
        isLoaded: true,
        customer: customer,
      },
    });
  };
}
