import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetAccount, IGetAccount } from "api/rpc/2024-04/clientAdmin/client/accounts";

import { showError } from "redux/actions/ui";
import { IFacility } from "redux/reducers/models/facility";
import { ICustomer } from "redux/reducers/models/customer";
import { displayCurrency, uppercase } from "helpers/Helpers";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import { Badge } from "components/badge/Badge";
import Search from "components/search/Search";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

interface IAccount {
  id: string;
  title: string;
  account_number: string;
  currency: string;
  balance: number;
  limit: number;
  global: boolean;
  customer?: ICustomer;
  closed?: boolean;
  facility?: IFacility;
}

const TABLE_LIMIT = 25;

export default function Accounts() {
  const history = useHistory();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const [accounts, setAccounts] = useState<IAccount[]>(undefined);

  // Back-end Filters.  Changes made triggers API call.
  const [filters, setFilters] = useState<Partial<IGetAccount>>({
    search: "",
    offset: 0,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadAccounts(source.token);
    return () => source.cancel();
  }, [filters]);

  async function loadAccounts(token?: CancelToken) {
    if (accounts !== undefined) {
      setAccounts(undefined);
    }
    const res = await GetAccount({ extended: true, limit: TABLE_LIMIT, ...filters }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading accounts."));
    }

    setAccounts(res.status !== StatusCode.OK ? [] : res.data);
  }

  function handleTableNavigation(direction: "prev" | "next") {
    switch (direction) {
      case "prev": {
        setFilters({ ...filters, offset: filters.offset - TABLE_LIMIT });
        return;
      }
      case "next": {
        setFilters({ ...filters, offset: filters.offset + TABLE_LIMIT });
        return;
      }
      default:
        return;
    }
  }

  const primaryAction = {
    content: "New Account", // TODO: Translation
    action: () => history.push("/admin/settings/accounts/new"),
  };

  return (
    <Page title={"Accounts" /* TODO: Translation */} primaryAction={primaryAction}>
      <div className="mb-4 w-full">
        <Search
          historyKey="client-account-settings"
          searchCallback={searchValue => setFilters(prev => ({ ...prev, search: searchValue }))}
          placeholder={"Search accounts by title or account number..."} // TODO: Translation
        />
      </div>

      <DataTable
        columns={[
          { label: "Title", width: "12.5%" }, // TODO: Translation
          { label: "Home Course", width: "12.5%" }, // TODO: Translation
          { label: "Account Number", width: "12.5%" }, // TODO: Translation
          { label: "Currency", width: "12.5%" }, // TODO: Translation
          { label: "Balance", width: "12.5%" }, // TODO: Translation
          { label: "Limit", width: "12.5%" }, // TODO: Translation
          { label: "", width: "12.5%" }, // account.global
          { label: "", width: "12.5%" }, // account.closed
        ]}
        footer={{
          tableLimit: TABLE_LIMIT,
          tableOffset: filters.offset,
          handleTableOffset: handleTableNavigation,
          disableNextOffset: !accounts || accounts?.length <= TABLE_LIMIT,
        }}
        loading={accounts === undefined}
      >
        {accounts?.map(account => (
          <tr
            key={account.id}
            className="clickable"
            onClick={() => history.push("/admin/settings/accounts/overview/" + account.id)}
          >
            <td>
              {account.customer ? <p className="lead-text">{account.customer?.full_name}</p> : null}
              <p className={account.customer ? "support-text" : "lead-text"}>{account.title}</p>
            </td>
            <td>{account.facility.long_name}</td>
            <td>{account.account_number}</td>
            <td>{uppercase(account.currency)}</td>
            <td>{displayCurrency(account.currency, account.balance)}</td>
            <td>{displayCurrency(account.currency, account.limit)}</td>
            <td>
              {account.global ? (
                <Badge type="gray" size="medium">
                  {"Global"} {/* TODO: Translation */}
                </Badge>
              ) : null}
            </td>
            <td>
              {account.closed ? (
                <Badge type="error" size="medium">
                  {"Closed"} {/* TODO: Translation */}
                </Badge>
              ) : null}
            </td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
  // return accounts ? (
  //   <Page title={"Accounts"} primaryAction={primaryAction}>
  //     <div className="mb-4 w-full">
  //       <Input
  //         value={filterState.search}
  //         onChange={(value: any) => setFilterState(prevState => ({ ...prevState, search: value.target.value }))}
  //         type="search"
  //         placeholder={"Search accounts..."}
  //       ></Input>
  //     </div>

  //     <Card>
  //       <Card.Section table="true">
  //         <table className="ui-table">
  //           <thead>
  //             <tr>
  //               <th>{"Title"}</th>
  //               <th>{"Home Course"}</th>
  //               <th>{"Account Number"}</th>
  //               <th>{"Currency"}</th>
  //               <th>{"Balance"}</th>
  //               <th>{"Limit"}</th>
  //               <th></th>
  //               <th></th>
  //             </tr>
  //           </thead>
  //           <tbody>
  //             {accounts.length > 0
  //               ? accounts.map((account: IAccount, index: number) => {
  //                   console.log(account);
  //                   return (
  //                     <tr key={index} className="hover:bg-gray-200">
  //                       <td
  //                         className="table-cell-lead-with-support-text"
  //                         onClick={() => navigateToAccountOverview("/admin/settings/accounts/overview/" + account.id)}
  //                       >
  //                         {account.customer ? (
  //                           <p className="table-cell-lead-text">{account.customer?.full_name}</p>
  //                         ) : null}
  //                         <p
  //                           className={classNames({
  //                             "table-cell-support-text": account.customer,
  //                             "table-cell-lead-text": !account.customer,
  //                           })}
  //                         >
  //                           {account.title}
  //                         </p>
  //                       </td>
  //                       <td
  //                         onClick={() => navigateToAccountOverview("/admin/settings/accounts/overview/" + account.id)}
  //                       >
  //                         {account.facility.long_name}
  //                       </td>
  //                       <td
  //                         onClick={() => navigateToAccountOverview("/admin/settings/accounts/overview/" + account.id)}
  //                       >
  //                         {account.account_number}
  //                       </td>
  //                       <td
  //                         onClick={() => navigateToAccountOverview("/admin/settings/accounts/overview/" + account.id)}
  //                       >
  //                         {uppercase(account.currency)}
  //                       </td>
  //                       <td
  //                         onClick={() => navigateToAccountOverview("/admin/settings/accounts/overview/" + account.id)}
  //                       >
  //                         {displayCurrency(account.currency, account.balance)}
  //                       </td>
  //                       <td
  //                         onClick={() => navigateToAccountOverview("/admin/settings/accounts/overview/" + account.id)}
  //                       >
  //                         {displayCurrency(account.currency, account.limit)}
  //                       </td>
  //                       <td>
  //                         {account.global ? (
  //                           <Badge type="gray" size="medium">
  //                             {"Global"}
  //                           </Badge>
  //                         ) : null}
  //                       </td>
  //                       <td>
  //                         {account.closed ? (
  //                           <Badge type="error" size="medium">
  //                             {"Closed"}
  //                           </Badge>
  //                         ) : null}
  //                       </td>
  //                     </tr>
  //                   );
  //                 })
  //               : null}
  //           </tbody>

  //           <tfoot>
  //             <tr>
  //               <td>
  //                 <Button
  //                   size="small"
  //                   type="secondary"
  //                   onClick={() => {
  //                     handleTableNavigation("prev");
  //                   }}
  //                   disabled={!(filters.limit <= filters.offset)}
  //                 >
  //                   <FontAwesomeIcon icon={"arrow-left"} />
  //                   &nbsp;
  //                   {"Previous"}
  //                 </Button>
  //               </td>

  //               <td colSpan={5} />

  //               <td>
  //                 <div style={{ display: "flex", justifyContent: "flex-end" }}>
  //                   <Button
  //                     size="small"
  //                     type="secondary"
  //                     onClick={() => {
  //                       handleTableNavigation("next");
  //                     }}
  //                     disabled={!(accounts.length === filters.limit)}
  //                   >
  //                     {"Next"}
  //                     &nbsp;
  //                     <FontAwesomeIcon icon={"arrow-right"} />
  //                   </Button>
  //                 </div>
  //               </td>
  //             </tr>
  //           </tfoot>
  //         </table>
  //       </Card.Section>
  //     </Card>
  //   </Page>
  // ) : null;
}
