import React from "react";
import { ButtonNew as Button } from "components/buttonNew";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "components/form/input/Input";
import { useTranslation } from "react-i18next";
import "./SwitchUserModal.scss";

interface ISwitchUserModalProps {
  open: boolean;
  onCancel: () => void;
  cancelText?: string;
  backDropCancel?: boolean;
  onOk: () => void;
  okDisabled?: boolean;
  userCode: string;
  onChange: (e: any) => void;
  onKeyDown: (e: any) => void;
  autoFocus: boolean;
  darkStyle?: boolean;
}

export default function SwitchUserModal(props: ISwitchUserModalProps) {
  const { t, i18n } = useTranslation();
  const { open, onCancel, onOk, okDisabled, userCode, onChange, onKeyDown, autoFocus, cancelText, backDropCancel } =
    props;
  return (
    <>
      {open ? (
        <div>
          <div className="switch-user-modal-container">
            <div className={"switch-user-modal" + (props?.darkStyle ? " switch-user-modal-darkstyle " : "")}>
              <div className="switch-user-modal-header">
                <div className="switch-user-modal-header-title">
                  {t("components.switch_user_modal.switch_user_modal.001")}
                </div>
                <Button
                  className="switch-user-modal-header-close-button"
                  icon={<FontAwesomeIcon icon={["far", "times"]} />}
                  type="text"
                  onClick={onCancel}
                />
              </div>
              <div className="switch-user-modal-content">
                <Input
                  label={t("components.switch_user_modal.switch_user_modal.002")}
                  value={userCode}
                  onChange={onChange}
                  onKeyDown={onKeyDown}
                  autoFocus={autoFocus}
                  labelStyle={{ color: props?.darkStyle && "#FFFFFF" }}
                  type="password"
                  autoComplete="new-password"
                />
              </div>

              <div className="switch-user-modal-footer">
                <Button className="mr-2" type="default" onClick={onCancel} darkStyle={props?.darkStyle ? true : false}>
                  {cancelText ? cancelText : "Cancel"}
                </Button>
                <Button type="primary" onClick={onOk} disabled={okDisabled} darkStyle={props?.darkStyle ? true : false}>
                  {t("components.switch_user_modal.switch_user_modal.003")}
                </Button>
              </div>
            </div>
          </div>
          <div
            className={
              "switch-user-modal-backdrop " + (props?.darkStyle ? "switch-user-modal-backdrop-darkstyle " : "")
            }
            onClick={backDropCancel !== undefined && backDropCancel === false ? undefined : onCancel}
          />
        </div>
      ) : null}
    </>
  );
}
