import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ReactDOM from "react-dom";
import { isEqualWith, isNull } from "lodash";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { GetDepartments, GetFacilityNew } from "api/rpc/facility";
import { GetRegisters } from "api/rpc/facilityAdmin/facility/register/register";
import { DeleteTile, GetTile, PutTile } from "api/rpc/facilityAdmin/client/dashboard";
import { GetFacilityAdmin } from "api/rpc/facilityAdmin/client/admin";

import { dequeue, enqueue, showError, showSuccess } from "redux/actions/ui";
import { capitalize } from "helpers/Helpers";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Input from "components/form/input/Input";
import { Select } from "components/select/index";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Popup from "components/popup/Popup";
import FilterDropdown from "components/filterDropDown/FilterDropDown";
import Toggle from "components/form/toggle/Toggle";
import { IDisplayOptions } from "pages/secure/facility/dashboard/Chart";

interface ITileState {
  title: string;
  originalTitle: string;
  options: Record<string, any>;
  baseTileOptions: Array<{ name: string; type: string; options?: Array<string | number> }>;
  all_facility_ids: Array<Record<string, any>>;
  all_register_ids: Array<Record<string, any>>;
  all_user_ids: Array<Record<string, any>>;
  all_department_ids: Array<Record<string, any>>;
  timezones: Array<string>;
  //Display Options
  hide_legend: boolean;
  legend_title: string;
  legend_position: "top" | "bottom" | "left" | "right";
  [key: string]: any;
}

export default function TilesEdit() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const dispatch = useAppDispatch();

  const [state, setState] = useState<ITileState>({
    title: "",
    originalTitle: "",
    options: null,
    baseTileOptions: [],
    all_facility_ids: [],
    all_register_ids: [],
    all_user_ids: [],
    all_department_ids: [],
    timezones: [],
    //Display Options
    hide_legend: true,
    legend_title: "",
    legend_position: "top",
  });
  const [beforeChangesState, setBeforeChangesState] = useState<ITileState>(undefined);
  const [tileLoaded, setTileLoaded] = useState<boolean>(false);
  const [deleteModalState, setDeleteModalState] = useState<boolean>(false);
  const { Option } = Select;

  useEffect(() => {
    void loadTile();
  }, []);

  async function loadTile() {
    dispatch(enqueue());
    const res = await GetTile({ id: Number(id) }, false);
    if (res.status !== StatusCode.OK) {
      console.log(res.data?.message);
      dispatch(dequeue());
      return;
    }
    await getFacilities();
    await getRegisters();
    await getDepartments();
    await getAdmins();

    dispatch(dequeue());

    ReactDOM.unstable_batchedUpdates(() => {
      setTileLoaded(true);
      setState(prevState => ({
        ...prevState,
        ...(res.data?.settings as Record<string, any>),
        title: res.data?.title,
        originalTitle: res.data?.title,
        options: res?.data?.settings,
        baseTileOptions: res?.data?.base_tile?.settings,
        ...(res.data?.display_options as Record<string, any>),
      }));
    });
  }

  async function getFacilities() {
    const res = await GetFacilityNew(null, false);
    if (res.status !== StatusCode.OK) {
      dispatch(showError(res?.message));
      dispatch(dequeue());
      history.push("/admin/settings/dashboards");
      return;
    }
    setState(prevState => ({
      ...prevState,
      facility_ids: [res.data[0]?.id],
      all_facility_ids: res.data,
      timezones: [res.data[0]?.timezone],
    }));
  }

  async function getDepartments() {
    const res = await GetDepartments(false);
    if (res.status !== StatusCode.OK) {
      dispatch(showError(res?.message));
      dispatch(dequeue());
      history.push("/admin/settings/dashboards");
      return;
    }
    const departments = res?.data?.filter((department: Record<string, any>) => department?.type === "department");
    setState(prevState => ({
      ...prevState,
      all_department_ids: departments,
    }));
  }

  async function getRegisters() {
    const res = await GetRegisters(null, false);
    if (res.status !== StatusCode.OK) {
      dispatch(
        showError(
          typeof res?.data === "string" ? res.data : t("secure.facility.settings.dashboards.tiles.tiles_edit.024"),
        ),
      );
      dispatch(dequeue());
      history.push("/admin/settings/dashboards");
      return;
    }
    setState(prevState => ({
      ...prevState,
      all_register_ids: res.data,
    }));
  }

  async function getAdmins() {
    const res = await GetFacilityAdmin(null, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError(res?.message));
      history.push("/admin/settings/dashboards");
      return;
    }
    setState(prevState => ({
      ...prevState,
      all_user_ids: res.data,
    }));
  }

  async function updateTile() {
    let missingOption = false;
    if (!state.title) {
      dispatch(showError(t("secure.facility.settings.dashboards.tiles.tiles_edit.001")));
      return;
    }

    const options: { [key: string]: any } = {};
    state.baseTileOptions?.forEach(option => {
      if (!state[option.name]) {
        dispatch(showError(t("secure.facility.settings.dashboards.tiles.tiles_edit.002") + option.name));
        missingOption = true;
        return;
      } else if (Array.isArray(state[option.name]) && state[option.name]?.length === 0) {
        dispatch(
          showError(t("secure.facility.settings.dashboards.tiles.tiles_edit.003") + option.name + " selections"),
        );
        missingOption = true;
        return;
      } else if (option.type === "integer") {
        options[option.name] = Number(state[option.name]);
      } else {
        options[option.name] = state[option.name];
      }
    });

    if (missingOption) {
      return;
    }

    const display_options: IDisplayOptions = {
      hide_legend: state.hide_legend,
      legend_title: state.legend_title,
      legend_position: state.legend_position,
    };

    const res = await PutTile(
      {
        id: Number(id),
        title: state.title,
        settings: JSON.stringify(options),
        display_options: JSON.stringify(display_options),
      },
      true,
    );
    if (res.status !== StatusCode.OK) {
      dispatch(showError(res?.data?.message));
      return;
    }

    dispatch(showSuccess(res?.message));
    history.push("/admin/settings/dashboards");
  }

  async function deleteTile() {
    const res = await DeleteTile({ id: Number(id) }, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError(res.data?.message));
      return;
    }
    dispatch(showSuccess(t("secure.facility.settings.dashboards.tiles.tiles_edit.004")));
    history.push("/admin/settings/dashboards");
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;
    setState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleDropdownChange(value: number | string, id: string) {
    setState(prevState => ({ ...prevState, [id]: value }));
  }

  function onCheckBoxChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value, checked, id } = e.target;
    let updateSelectedValues: any[] = [];
    if (state[id]) {
      updateSelectedValues = [...state[id]];
    }
    if (checked) {
      updateSelectedValues.push(Number(value));
    } else {
      const removeIndex = updateSelectedValues?.findIndex(valueId => valueId === Number(value));
      if (removeIndex !== -1) {
        updateSelectedValues.splice(removeIndex, 1);
      }
    }
    setState(prevState => ({ ...prevState, [id]: updateSelectedValues }));
  }

  function handleAddAll(id: string) {
    const updateSelectedValues: any[] = [];
    state[`all_${id}`]?.forEach((value: Record<string, any>) => {
      updateSelectedValues.push(value?.id);
    });
    setState(prevState => ({
      ...prevState,
      [id]: updateSelectedValues,
    }));
  }

  function unsavedChangesExist() {
    if (beforeChangesState === undefined) {
      if (tileLoaded) {
        setBeforeChangesState(state);
      }
      return false;
    }

    return !isEqualWith(beforeChangesState, state, (originalValue, newValue) => {
      if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
        return true;
      }
    });
  }

  const primaryAction = {
    content: t("secure.facility.settings.dashboards.tiles.tiles_edit.005"),
    action: () => setDeleteModalState(true),
  };

  return (
    <Page
      title={`${t("secure.facility.settings.dashboards.tiles.tiles_edit.006")} ${state.originalTitle ?? ""}`}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.dashboards.tiles.tiles_edit.007"),
          url: "/admin/settings/dashboards",
        },
      ]}
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: updateTile,
        onCancel: () => setState(beforeChangesState),
      }}
    >
      <Card>
        <Card.Section>
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={state.title}
                  label={t("secure.facility.settings.dashboards.tiles.tiles_edit.009")}
                  id="title"
                  onChange={handleInputChange}
                  placeholder={t("secure.facility.settings.dashboards.tiles.tiles_edit.010")}
                />
              </FormLayout.Group>
              <FormLayout.Group></FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
        <Card.Section title={t("secure.facility.settings.dashboards.tiles.tiles_edit.011")}>
          <div className="flex flex-row flex-wrap gap-8 items-center">
            <Toggle
              labelTop={t("secure.facility.settings.dashboards.tiles.tiles_edit.012")}
              onChange={e => setState(prevState => ({ ...prevState, hide_legend: e.target.checked }))}
              id="hide_legend"
              checked={state.hide_legend}
            />
            <Input
              disabled={state.hide_legend}
              value={state.legend_title}
              id="legend_title"
              onChange={handleInputChange}
              label={t("secure.facility.settings.dashboards.tiles.tiles_edit.013")}
            />
            <Select
              label={t("secure.facility.settings.dashboards.tiles.tiles_edit.014")}
              onChange={(value: string) => handleDropdownChange(value, "legend_position")}
              defaultValue={state.legend_position}
              disabled={state.hide_legend}
            >
              <Option value="top" name="top">
                <span>{t("secure.facility.settings.dashboards.tiles.tiles_edit.015")}</span>
              </Option>
              <Option value="bottom" name="bottom">
                <span>{t("secure.facility.settings.dashboards.tiles.tiles_edit.016")}</span>
              </Option>
              <Option value="left" name="left">
                <span>{t("secure.facility.settings.dashboards.tiles.tiles_edit.017")}</span>
              </Option>
              <Option value="right" name="right">
                <span>{t("secure.facility.settings.dashboards.tiles.tiles_edit.018")}</span>
              </Option>
            </Select>
          </div>
        </Card.Section>
        {state.options && state.baseTileOptions?.length > 0 && (
          <Card.Section title={t("secure.facility.settings.dashboards.tiles.tiles_edit.019")}>
            <div className="flex flex-row flex-wrap gap-4 items-center">
              {state.baseTileOptions?.map((option, index) => {
                if (option.type === "integer_array" && option.name !== "facility_ids") {
                  return (
                    <div key={index} className="mt-4">
                      <FilterDropdown
                        label={capitalize(option.name)}
                        data={state[`all_${option.name}`]}
                        dataLabelProperty={option.name === "user_ids" ? "full_name" : "title"}
                        checkBoxId={option.name}
                        dataValueProperty="id"
                        onCheckBoxChange={onCheckBoxChange}
                        onClear={() => setState(prevState => ({ ...prevState, [option.name]: [] }))}
                        onAddAll={() => handleAddAll(option.name)}
                        iconName="chevron-down"
                        defaultCheckBoxes={Array.from(
                          state[`all_${option.name}`],
                          (value: Record<string, any>) => state[option.name]?.includes(value?.id) as boolean,
                        )}
                      />
                    </div>
                  );
                } else if (option.type === "string" && option.name === "timezone") {
                  return (
                    <Select
                      key={index}
                      label={capitalize(option.name)}
                      onChange={(value: string) => handleDropdownChange(value, option.name)}
                      defaultValue={state[option.name] || null}
                      className="w-52"
                    >
                      {state.timezones?.map((timezone, timezoneIndex) => {
                        return (
                          <Option value={timezone} name={timezone} key={timezoneIndex}>
                            <span>{timezone}</span>
                          </Option>
                        );
                      })}
                    </Select>
                  );
                } else if (option.type === "string" && option?.options) {
                  return (
                    <Select
                      key={index}
                      label={capitalize(option.name)}
                      onChange={(value: string) => handleDropdownChange(value, option.name)}
                      defaultValue={state[option.name] || null}
                      className="w-52"
                    >
                      {option.options?.map((optionValue, optionIndex) => {
                        return (
                          <Option value={optionValue} name={optionValue?.toString()} key={optionIndex}>
                            <span>{optionValue}</span>
                          </Option>
                        );
                      })}
                    </Select>
                  );
                } else if (option.type === "integer") {
                  if (option.name.includes("_id")) {
                    return (
                      <Select
                        key={index}
                        label={capitalize(option.name)}
                        onChange={(value: number) => handleDropdownChange(value, option.name)}
                        defaultValue={state[option.name] || null}
                        className="w-52"
                      >
                        {state[`all_${option.name}s`]?.map((value: Record<string, any>, valueIndex: number) => {
                          if (option.name === "facility_id") {
                            return (
                              <Option value={value.id} name={value.long_name} key={valueIndex}>
                                <span>{value.long_name}</span>
                              </Option>
                            );
                          } else {
                            return (
                              <Option value={value.id} name={value.title} key={valueIndex}>
                                <span>{value.title}</span>
                              </Option>
                            );
                          }
                        })}
                      </Select>
                    );
                  } else {
                    return (
                      <Input
                        key={index}
                        label={capitalize(option.name)}
                        value={state[option.name] || ""}
                        id={option.name}
                        onChange={handleInputChange}
                        placeholder={capitalize(option.name)}
                        type="number"
                      />
                    );
                  }
                } else if (option.type === "string") {
                  return (
                    <Input
                      key={index}
                      label={capitalize(option.name)}
                      value={state[option.name] || ""}
                      id={option.name}
                      onChange={handleInputChange}
                      placeholder={capitalize(option.name)}
                    />
                  );
                }
              })}
            </div>
          </Card.Section>
        )}
      </Card>

      <Popup
        open={deleteModalState}
        type="warning"
        title={t("secure.facility.settings.dashboards.tiles.tiles_edit.020")}
        description={t("secure.facility.settings.dashboards.tiles.tiles_edit.021")}
        okText={t("secure.facility.settings.dashboards.tiles.tiles_edit.022")}
        onOk={deleteTile}
        cancelText={t("secure.facility.settings.dashboards.tiles.tiles_edit.023")}
        onCancel={() => setDeleteModalState(false)}
      />
    </Page>
  );
}
