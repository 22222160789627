import * as React from "react";
import InternalTeeSheetCard, { ITeeSheetCardProps } from "./TeeSheetCard";
import Group from "./Group";
import Row from "./Row";
import Container from "./Container";

// export { TeeSheetCardProps } from './TeeSheetCard';
export { ITeeSheetCardGroupProps } from "./Group";

interface ITeeSheetCardType extends React.FC<ITeeSheetCardProps> {
  Group: typeof Group;
  Row: typeof Row;
  Container: typeof Container;
}
export const TeeSheetCard = InternalTeeSheetCard as ITeeSheetCardType;

TeeSheetCard.Group = Group;
TeeSheetCard.Row = Row;
TeeSheetCard.Container = Container;

export default TeeSheetCard;
