import { APIDelete, APIGet, APIPost, APIPut, StatusCode } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseCartUrl = "/" + apiVersion + "/" + adminType + "/facility/kitchen";

interface IGetKitchenLocation {
  id: number;
}

interface IGetKitchenLocationChits {
  kitchen_location_ids?: Array<number>;
  kitchen_location_id?: number;
  table_id?: number;
  extended?: boolean;
  start_date?: string;
  end_date?: string;
  user_id?: number;
}

interface IPostKitchenReceipt {
  table_id?: number;
  line_item_ids: Array<number>;
}

interface IPutKitchenChit {
  id: number;
  status: string;
}

interface IGetKitchenMeals {
  id: number;
}

export interface IKitchenMeal {
  id: number;
  facility_id: number;
  position: number;
  title: string;
}

interface IPostKitchenMeals {
  title: string;
}

interface IPutKitchenMeal {
  id: number;
  title: string;
}

export async function GetKitchenLocation(params?: IGetKitchenLocation, useGlobalLoader?: boolean) {
  return await APIGet(baseCartUrl + "/location", params, useGlobalLoader);
}

export async function PostKitchenLocation(params?: any, useGlobalLoader?: boolean) {
  return await APIPost(baseCartUrl + "/location", params, useGlobalLoader);
}

export async function GetKitchenLocationChits(
  params?: IGetKitchenLocationChits,
  useGlobalLoader?: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseCartUrl + "/chit", params, useGlobalLoader, token);
}

export async function PostKitchenChit(params: IPostKitchenReceipt, useGlobalLoader: boolean) {
  return await APIPost(baseCartUrl + "/chit", params, useGlobalLoader);
}

export async function PutKitchenChit(params: IPutKitchenChit, useGlobalLoader?: boolean) {
  return await APIPut(baseCartUrl + "/chit", params, useGlobalLoader);
}

export async function FireKitchenChit(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseCartUrl + "/chit/fire", params, useGlobalLoader);
}

export async function GetKitchenMeals(params: IGetKitchenMeals, useGlobalLoader?: boolean, token?: CancelToken) {
  return await APIGet<IKitchenMeal[]>(baseCartUrl + "/meal", params, useGlobalLoader, token);
}

export async function PostKitchenMeal(params: IPostKitchenMeals, useGlobalLoader: boolean) {
  return await APIPost(baseCartUrl + "/meal", params, useGlobalLoader);
}

export async function PutKitchenMeal(params: IPutKitchenMeal, useGlobalLoader: boolean) {
  return await APIPut(baseCartUrl + "/meal", params, useGlobalLoader);
}

export async function DeleteKitchenMeal(params: { id: number }, useGlobalLoader: boolean) {
  return await APIDelete(baseCartUrl + "/meal", params, useGlobalLoader);
}
