import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { IOrder } from "pages/guest/models/order";
import { ICart } from "redux/reducers/customer/models/cart";

const apiVersion = "2022-09";
const adminType = "guest";
const baseCartUrl = "/" + apiVersion + "/" + adminType + "/cart";
const baseCartLineItemUrl = "/" + apiVersion + "/" + adminType + "/cart/line-item";

interface IGetCart {
  id?: number;
  token?: string;
  facility_id?: number;
  sales_channel?: string;
}

interface IGetCartResponse {
  data: ICart;
  status: number;
}

interface IPostCart {
  facility_id: number;
  sales_channel: string;
  customer_id?: number;
}

interface IPostCartResponse {
  data: ICart;
  status: number;
}

export interface IPutCart {
  cart_token: string;
  customer_billing_address_id?: number;
  customer_email: string;
  customer_notes?: string;
}

interface IPutCartResponse {
  data: ICart;
  status: number;
}

interface IPostCheckout {
  cart_token: string;
}

interface IPostCheckoutResponse {
  data: {
    order: IOrder;
    cart: ICart;
  };
  status: number;
}

interface IPostLineItemToCart {
  cart_token: string;
  variant_id: number;
  quantity: number;
}

interface IPostLineItemToCartResponse {
  data: ICart;
  status: number;
}

interface IPutLineItemToCart {
  id: number;
  quantity?: number;
  price?: number;
}

interface IPutLineItemToCartResponse {
  data: ICart;
  status: number;
}

interface IDeleteLineItemToCart {
  id: number;
}

interface IDeleteLineItemToCartResponse {
  data: ICart;
  status: number;
}

export async function GetCart(params: IGetCart, useGlobalLoader: boolean): Promise<IGetCartResponse> {
  return await APIGet(baseCartUrl, params, useGlobalLoader);
}

export async function PostCart(params: IPostCart, useGlobalLoader: boolean): Promise<IPostCartResponse> {
  return await APIPost(baseCartUrl, params, useGlobalLoader);
}

export async function PutCart(params: IPutCart, useGlobalLoader: boolean): Promise<IPutCartResponse> {
  return await APIPut(baseCartUrl, params, useGlobalLoader);
}

export async function PostCheckout(params: IPostCheckout, useGlobalLoader: boolean): Promise<IPostCheckoutResponse> {
  return await APIPost(baseCartUrl + "/checkout", params, useGlobalLoader);
}

export async function PostLineItemToCart(
  params: IPostLineItemToCart,
  useGlobalLoader: boolean,
): Promise<IPostLineItemToCartResponse> {
  return await APIPost(baseCartLineItemUrl, params, useGlobalLoader);
}

export async function PutLineItemToCart(
  params: IPutLineItemToCart,
  useGlobalLoader: boolean,
): Promise<IPutLineItemToCartResponse> {
  return await APIPut(baseCartLineItemUrl, params, useGlobalLoader);
}

export async function DeleteLineItemToCart(
  params: IDeleteLineItemToCart,
  useGlobalLoader: boolean,
): Promise<IDeleteLineItemToCartResponse> {
  return await APIDelete(baseCartLineItemUrl, params, useGlobalLoader);
}
