import { IVariant } from "redux/reducers/models/product";
import { IModifierGroup } from "api/rpc/facilityAdmin/modifierGroups";
import Sheet from "components/sheet/Sheet";
import React from "react";
import { range } from "lodash";
import "./selectModifierSheet.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spin from "components/spin/spin";

interface ISelectModifierSheetProps {
  open: boolean;
  onCancel: () => void;
  onOk: () => void;
  allModifierGroups: IModifierGroup[];
  selectedModifierGroupVariants: { [key: string]: Record<string, any>[] };
  addModifierVariant: (modifierGroup: IModifierGroup, variant: IVariant, selected: boolean) => void;
  okDisabled: boolean;
}

export default function SelectModifierSheet(props: ISelectModifierSheetProps) {
  const { open, onCancel, onOk, allModifierGroups, selectedModifierGroupVariants, addModifierVariant, okDisabled } =
    props;

  const GRID_COL = 6;

  function calculateGridRows(modifierGroup: IModifierGroup) {
    if (modifierGroup?.variants?.length > 0) {
      const positions = modifierGroup.variants.map((variant: IVariant) => variant.modifier_group?.position);
      const maxPosition = positions.reduce((prev, next) => (prev < next ? next : prev));
      const gridRows = Math.ceil(maxPosition / GRID_COL);

      return isNaN(gridRows) || gridRows === undefined ? 0 : gridRows;
    } else {
      return 0;
    }
  }

  return (
    <Sheet
      size="medium"
      title="Select Modifiers"
      open={open}
      onCancel={onCancel}
      onOk={onOk}
      okDisabled={okDisabled}
      okText="Continue"
      closable
      backDropCancel={false}
      stacked
    >
      {!allModifierGroups || allModifierGroups?.length === 0 ? (
        <div style={{ display: "flex", height: "100%" }}>
          <div className="text-center m-auto">
            <div style={{ height: "32px" }}>
              <Spin />
            </div>
            <div>{"Loading modifier groups..."}</div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-3">
          {allModifierGroups?.map(modifierGroup => {
            return (
              <div key={modifierGroup.id}>
                <div className="flex flex-row border-b-2 border-gray-200 pl-4 pr-4 mb-3">
                  <div className="flex flex-1 text-medium items-center">
                    {modifierGroup.title}
                    {modifierGroup.required && <div className="text-primary-grey text-12 pl-2"> - Required</div>}
                  </div>
                </div>
                <div className="select-modifier-modifier-groups p-1">
                  {range(1, GRID_COL * calculateGridRows(modifierGroup) + 1).map(position => {
                    const variant = modifierGroup.variants.find(
                      (variant: IVariant) => variant.modifier_group?.position === position,
                    ) as IVariant;

                    if (variant) {
                      const found = !!selectedModifierGroupVariants[modifierGroup.id.toString()].find(
                        modGroup => modGroup.id === variant.id,
                      );
                      return (
                        <div className="select-modifier-modifier-groups-variant" key={variant.id}>
                          <div
                            className="select-modifier-modifier-groups-variant-content"
                            style={{ backgroundColor: variant.product?.meta?.button_color }}
                            onClick={() => addModifierVariant(modifierGroup, variant, found)}
                          >
                            <span className="select-modifier-modifier-groups-variant-content-title">
                              {variant?.product?.meta?.preferred_title
                                ? variant?.product?.meta?.preferred_title
                                : variant?.product?.title}
                            </span>
                            {variant?.title !== variant?.product?.title && (
                              <span className="select-modifier-modifier-groups-variant-content-title">
                                {" "}
                                - {variant.title}
                              </span>
                            )}
                            <p className="select-modifier-modifier-groups-variant-content-title ml-auto tw-font-bold">{`$${String(
                              variant?.price.toFixed(2),
                            )}`}</p>
                            {found && (
                              <FontAwesomeIcon
                                className="select-modifier-modifier-groups-variant-content-selected"
                                icon={["far", "check-circle"]}
                              />
                            )}
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          key={String(Date.now()) + String(position)}
                          className="select-modifier-modifier-groups-variant"
                        />
                      );
                    }
                  })}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </Sheet>
  );
}
