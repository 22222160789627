import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { IGroupProps } from "./interface";
import { RadioGroupContextProvider } from "./context";

const Group: React.FC<IGroupProps> = props => {
  const {
    prefixCls = "rc-radio-group",
    className,
    defaultValue = null,
    type,
    onChange,
    name,
    children,
    darkStyle,
  } = props;

  const [value, setValue] = useState<string | number | Record<string, any>>(props.value || defaultValue);
  const [id, setId] = useState<string | number>(props.id);

  useEffect(() => {
    let mounted = true;
    if (defaultValue) {
      if (mounted) {
        setValue(defaultValue);
      }
    }
    return () => {
      mounted = false;
    };
  }, [defaultValue]);

  const onRadioChange = (ev: string | number | Record<string, any>, id?: string | number) => {
    const lastValue = value;
    const val = ev;

    setValue(val);
    if (id) {
      setId(id);
    }

    if (onChange && val !== lastValue) {
      onChange(ev);
    }
  };

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    setId(props.id);
  }, [props.id]);

  const radioGroupClassName = classNames(prefixCls, { [`${prefixCls}-t${type}`]: type }, className, {
    [`${prefixCls}-dark-style`]: darkStyle,
  });

  const clonedChildren = React.Children.map(children, (child, i) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { name });
    }
  });

  return (
    <RadioGroupContextProvider
      value={{
        onChange: onRadioChange,
        value,
        id,
        darkStyle: darkStyle,
      }}
    >
      <div className={radioGroupClassName}>{clonedChildren}</div>
    </RadioGroupContextProvider>
  );
};

export default Group;
