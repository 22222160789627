import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetLeagueDivision } from "api/rpc/2024-04/facilityAdmin/league/scoring/division";

import { showError } from "redux/actions/ui";
import { TLeagueDivision } from "redux/reducers/models/league";
import { useAppDispatch } from "hooks/redux";

import { Badge } from "components/badge/Badge";
import Page from "components/page/Page";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import { ButtonNew } from "components/buttonNew";

export default function LeagueDivisions() {
  const history = useHistory();
  const { t } = useTranslation();
  const { leagueId } = useParams<{ leagueId: string }>();

  const dispatch = useAppDispatch();

  const [leagueDivisions, setLeagueDivisions] = useState<TLeagueDivision[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadDivisions(leagueId, source.token);
    return () => source.cancel();
  }, [leagueId]);

  async function loadDivisions(league_id: string | number, token?: CancelToken) {
    if (leagueDivisions !== undefined) {
      setLeagueDivisions(undefined);
    }
    const res = await GetLeagueDivision({ league_id }, token ? false : true, token);
    if (token && token.reason) {
      return;
    }

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading league divisions.")); // TODO: Translation
    }

    setLeagueDivisions(res.status !== StatusCode.OK ? [] : res.data);
  }

  function handleRowNavigation(
    e: React.MouseEvent<HTMLElement>,
    divisionId: string,
    type: "flights" | "teams" | "divisions",
  ) {
    e.stopPropagation();
    if (type === "divisions") {
      history.push(`/admin/league/${leagueId}/divisions/${divisionId}/manage_players`);
    } else {
      history.push(`/admin/league/${leagueId}/divisions/${divisionId}/${type}`);
    }
  }

  const primaryAction = {
    content: "New Division", // TODO: Translation
    action: () => history.push(`/admin/league/${leagueId}/divisions/new`),
  };

  return (
    <Page
      title={"League Divisions"} // TODO: Translation
      narrow
      primaryAction={primaryAction}
    >
      <DataTable
        columns={[
          { label: "Name", width: "55%" }, // TODO: Translation
          { label: "", width: "15%" }, // Manage Players
          { label: "", width: "15%" }, // Manage Teams
          { label: "", width: "15%" }, // Manage Flights
        ]}
        loading={leagueDivisions === undefined}
      >
        {leagueDivisions?.map(division => (
          <tr
            key={division.id}
            className="clickable"
            onClick={() => history.push(`/admin/league/${leagueId}/divisions/${division.id}/edit`)}
          >
            <td>{division.title}</td>
            <td>
              {/* TODO: Translation */}
              <ButtonNew
                size="xsmall"
                type="text"
                onClick={e => handleRowNavigation(e, String(division.id), "divisions")}
              >
                Manage Players
              </ButtonNew>
            </td>
            <td>
              {/* TODO: Translation */}
              <ButtonNew
                size="xsmall"
                type="text"
                onClick={e => handleRowNavigation(e, String(division.id), "teams")}
              >
                Manage Teams
              </ButtonNew>
            </td>
            <td>
              {/* TODO: Translation */}
              <ButtonNew
                size="xsmall"
                type="text"
                onClick={e => handleRowNavigation(e, String(division.id), "flights")}
              >
                Manage Flights
              </ButtonNew>
            </td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
