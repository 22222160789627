import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { ICustomer } from "redux/reducers/models/customer";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/league/scoring/statistics";

export type TLeagueStatisticsResStructure = {
  id: number;
  user_id: number;
  created_at: string;
  customer: ICustomer;
  deleted_at: string;
  updated_at: string;
  handicap_index: number;
  initial_handicap_index: number;
  use_initial_handicap: boolean;
  league_id: number;
  last_name: string;
};

export interface IGetLeagueStatistics {
  league_id?: number | string;
}

export async function GetLeagueStatistics(params: IGetLeagueStatistics, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<TLeagueStatisticsResStructure[]>(baseUrl, params, useGlobalLoader, token);
}

export interface IPutLeagueStatistics {
  league_id: number;
  statistics: {
    id: number;
    initial_handicap_index?: number;
  }[];
}

export async function PutLeagueStatistics(params: IPutLeagueStatistics, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

export interface ILoadPreviousHandicaps {
  league_id: number | string;
  previous_league_id: number | string;
}

export async function PutLoadPreviousHandicaps(params: ILoadPreviousHandicaps, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/load", params, useGlobalLoader);
}

export async function PostMissingLeagueStatistics(params: { league_id: string | number }, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}
