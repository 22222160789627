import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useTranslation, TFunction } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { ITeeSheet } from "api/rpc/teeSheet/teeSheet";
import { PostTeeSheetNotification, IPostNotification } from "api/rpc/2022-09/facilityAdmin/teesheet/notification";
import { GetTeeSheets } from "api/rpc/2024-04/facilityAdmin/teesheet/teeSheet";

import { IUIActions, showError } from "redux/actions/ui";
import { ICourse } from "redux/reducers/models/facility";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import { Select } from "components/select/";
import Page from "components/page/Page";
import Card from "components/card/Card";
import Input from "components/form/input/Input";
import FormLayout from "components/form/FormLayout";
import TextField from "components/form/textField/TextField";
import DatePickerInput from "components/datePickerInput/DatePickerInput";

export default function TeeSheetNotificationNew() {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const facilityStore = useAppSelector(store => store.facilityStore);

  const [saveState, setSaveState] = useState<IPostNotification>({
    date: "",
    course: "",
    subject: "",
    content: "",
    user_type: undefined,
    type: undefined,
    tee_sheet_id: null,
    course_id: null,
  });

  const [dateState, setDateState] = useState({
    selectedDate: location.state ? (location.state as Date) : new Date(),
  });

  const [facilityOptionState, setFacilityOptionState] = useState<{ courses: ICourse[]; teeSheets: ITeeSheet[] }>({
    courses: [],
    teeSheets: [],
  });

  useEffect(() => {
    if (facilityStore.facility) {
      setFacilityOptionState({ ...facilityOptionState, courses: facilityStore.facility.courses });

      const dateAsString = new Date(dateState.selectedDate).toISOString().slice(0, 10);

      setSaveState({
        ...saveState,
        date: dateAsString,
        course: facilityStore.facility.courses[0].short_name,
        course_id: facilityStore.facility.courses[0].id,
      });
    }
  }, [facilityStore.facility]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (saveState.date === undefined || saveState.course_id === null) {
      return;
    }

    void loadTeeSheets(source.token);

    return () => source.cancel();
  }, [saveState.date, saveState.course_id]);

  async function loadTeeSheets(token?: CancelToken) {
    const res = await GetTeeSheets(
      { date: saveState.date, course_id: saveState.course_id },
      token ? false : true,
      token,
    );
    if (token && token.reason) {
      return;
    }

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading teesheets."));
      return;
    }

    setFacilityOptionState({ ...facilityOptionState, teeSheets: res.data });

    if (res.data.length == 1) {
      setSaveState({ ...saveState, tee_sheet_id: res.data[0].id });
    }
  }

  async function postNotification() {
    //saves an API call if required fields caught before
    if (saveState.user_type === undefined) {
      dispatch(showError(t("secure.facility.settings.tee_sheets.tee_sheet_notifications_new.003")));
      return;
    }
    if (saveState.subject === "") {
      dispatch(showError(t("secure.facility.settings.tee_sheets.tee_sheet_notifications_new.004")));
      return;
    }
    if (saveState.content === "") {
      dispatch(showError(t("secure.facility.settings.tee_sheets.tee_sheet_notifications_new.005")));
      return;
    }

    const postNotificationRes = await PostTeeSheetNotification(saveState, true);

    if (postNotificationRes.status !== StatusCode.OK) {
      dispatch(showError(postNotificationRes.data));
      return;
    }

    history.push("/admin/settings/tee-sheet/notification");
  }

  const handleDateSelectorChange = (selectedDate: Date) => {
    const dateAsString = new Date(selectedDate).toISOString().slice(0, 10);

    setSaveState({ ...saveState, date: dateAsString });
    setDateState({ ...dateState, selectedDate: selectedDate });
  };

  function handleDropDownChange(value: any, property: string) {
    let chosenCourse;
    if (property == "course") {
      chosenCourse = facilityOptionState.courses.find(course => course.short_name == value);
    }

    setSaveState({
      ...saveState,
      [property]: value,
      course_id: chosenCourse !== undefined ? chosenCourse.id : saveState.course_id,
    });
  }

  const primaryAction = {
    content: t("secure.facility.settings.tee_sheets.tee_sheet_notifications_new.006"),
    action: () => postNotification(),
    disabled: saveState.tee_sheet_id === null ? true : false,
  };

  return (
    <Page
      title={t("secure.facility.settings.tee_sheets.tee_sheet_notifications_new.007")}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.tee_sheets.tee_sheet_notifications_new.008"),
          url: "/admin/settings/tee-sheet/notification",
        },
      ]}
    >
      <Card>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <div id="notification-date-selector" style={{ marginRight: "15px" }}>
                <DatePickerInput
                  label={t("secure.facility.settings.tee_sheets.tee_sheet_notifications_new.009")}
                  months={1}
                  position="left"
                  startingDate={dateState.selectedDate}
                  setStartingDate={handleDateSelectorChange}
                />
              </div>
              <div></div>
            </FormLayout.Group>

            <FormLayout.Group>
              {facilityOptionState.courses?.length > 1 && (
                <Select
                  label={t("secure.facility.settings.tee_sheets.tee_sheet_notifications_new.010")}
                  onChange={(value: any) => handleDropDownChange(value, "course")}
                  defaultValue={saveState.course}
                >
                  {facilityOptionState.courses.map((course: ICourse, index: number) => {
                    return (
                      <Select.Option key={index} value={course.short_name} name={course.full_name}>
                        {course.full_name}
                      </Select.Option>
                    );
                  })}
                </Select>
              )}
            </FormLayout.Group>
            <FormLayout.Group>
              <Select
                label={t("secure.facility.settings.tee_sheets.tee_sheet_notifications_new.011")}
                onChange={(value: any) => handleDropDownChange(value, "user_type")}
              >
                {notificationUserTypes(t).map((type, index: number) => {
                  return (
                    <Select.Option key={index} value={type.normalized} name={type.title}>
                      {type.title}
                    </Select.Option>
                  );
                })}
              </Select>
            </FormLayout.Group>
            <FormLayout.Group>
              <Input
                value={saveState.subject}
                label={t("secure.facility.settings.tee_sheets.tee_sheet_notifications_new.012")}
                id="subject"
                onChange={e => setSaveState(prev => ({ ...prev, subject: e.target.value }))}
                placeholder={t("secure.facility.settings.tee_sheets.tee_sheet_notifications_new.013")}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField
                value={saveState.content}
                label={t("secure.facility.settings.tee_sheets.tee_sheet_notifications_new.014")}
                id="content"
                onChange={e => setSaveState(prev => ({ ...prev, content: e.target.value }))}
                placeholder={t("secure.facility.settings.tee_sheets.tee_sheet_notifications_new.015")}
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>
    </Page>
  );
}

const notificationUserTypes = (t: TFunction<"translation", undefined>) => {
  return [
    { title: t("secure.facility.settings.tee_sheets.tee_sheet_notifications_new.001"), normalized: "admin" },
    { title: t("secure.facility.settings.tee_sheets.tee_sheet_notifications_new.002"), normalized: "customer" },
  ];
};
