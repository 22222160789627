import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import "./membershipDisplay.scss";
import { valueToString } from "helpers/Helpers";
import { NavigationDropdownNew } from "components/navigationDropdownNew/NavigationDropdownNew";

export interface IParentMembership {
  client_id: number;
  customer_type_id: number;
  customer_type_title: string;
  expiry: string;
  id: number;
  membership_type: string;
  product_id: number;
  reciprocal: boolean;
  subtitle: string;
  title: string;
  type: string;
  variant_id: number;
  logo_image_source: string;
}

type MembershipDisplayProps = {
  id: number | string;
  title: string;
  subtitle?: string;
  type?: string;
  /**
   * null = no expiry.
   * undefined = empty card.
   * */
  expiry?: any;
  parent_membership?: IParentMembership;
  clickHandler?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  /** true = show ONLY title/subtitle in center of the card */
  emptyCard?: boolean;
  status?: string;
  status_readable?: string;
  disableMembership?: (id: any) => void;
  deleteMembership?: (id: any) => void;
  moveMembership?: (id: any) => void;
};

const MembershipDisplay = (props: MembershipDisplayProps) => {
  const { t, i18n } = useTranslation();
  return (
    <div
      id={typeof props.id === "string" ? props.id : props.id.toString()}
      className={`membership-display${
        props.status !== "active" && !props.emptyCard ? " membership-display-inactive" : ""
      }`}
      onClick={props.clickHandler ? event => props.clickHandler(event) : undefined}
    >
      {props.emptyCard ? (
        <div className="empty-container">
          <p>{props.title}</p>
          <p>{props?.subtitle}</p>
        </div>
      ) : (
        <>
          <div className="membership-main-content">
            {props?.parent_membership?.logo_image_source ? (
              <div className="img-container" style={{ background: "#FFFFFF" }}>
                <img className="customer-ticket-logo" src={props?.parent_membership?.logo_image_source} alt="" />
              </div>
            ) : null}

            <div>
              {props.title ? (
                <div className="membership-name">
                  <span className="membership-name-title">{props.title}</span>
                  {props.type && <span className="membership-name-type">{valueToString(props?.type)}</span>}
                  {props.expiry && (
                    <span className="membership-expiry-date-content">
                      Expires on {moment(props?.expiry).format("MMMM Do, YYYY")}
                    </span>
                  )}
                </div>
              ) : (
                <div>N/A</div>
              )}
              {props.subtitle && <div className="membership-subtitle"> {props?.subtitle}</div>}
            </div>
          </div>
          <div className="membership-secondary-content flex justify-between">
            {props.status === "active" && (props.moveMembership || props.deleteMembership || props.deleteMembership) && (
              <NavigationDropdownNew
                showPlainTextLabel
                rightAlign
                label={<FontAwesomeIcon icon={["fas", "chevron-down"]} />}
                sections={[
                  [
                    {
                      label: "Move",
                      icon: "turn-down-right",
                      onClick: () => props.moveMembership(props.id),
                    },
                  ],
                  [
                    {
                      label: "Disable",
                      icon: "ban",
                      onClick: () => props.disableMembership(props.id),
                    },
                    {
                      label: "Delete",
                      icon: "trash",
                      onClick: () => props.deleteMembership(props.id),
                    },
                  ],
                ]}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default MembershipDisplay;
