import { ColorPickerDropdown } from "components/colorPickerDropdown/ColorPickerDropdown";
import { difference, inRange, range } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { IVariant } from "redux/reducers/models/product";
import "./variantCards.scss";
import { ButtonNew as Button } from "components/buttonNew";
import VariantCard from "./variantCard/VariantCard";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import VariantCardSlot from "./variantCardSlot/VariantCardSlot";
import { IProductOverride } from "pages/secure/facility/settings/modifierGroup/ModifierGroupEdit";
import RightClickMenu from "components/rightClickMenu/RightClickMenu";

interface IVariantCardsProps {
  variants: IVariant[];
  variantsLoaded: boolean;
  onChangeVariantPosition: (id: number, position: number) => void;
  onRemoveVariant: (id: number) => void;
  onAddVariants: (availableVariantPositions: number[]) => void;
  setVariants: (variants: IVariant[]) => void;
  productOverrides: IProductOverride[];
  setProductOverrides: (productOverrides: IProductOverride[]) => void;
}

interface IVariantCardsState {
  gridRows: number;
  gridConstructed: boolean;
  selectedVariantId: number;
  selectedRows: number[];
}

export const VariantCardsItemTypes = {
  VARIANT_CARD: "variant_card",
};

export function VariantCards(props: IVariantCardsProps) {
  const variantCardsRef = useRef(null);

  const [colorPickerDropdownOpen, setColorPickerDropdownOpen] = useState<boolean>(false);

  const GRID_COL = 6;
  const EXTRA_GRID_ROWS = 3;

  const {
    variants,
    variantsLoaded,
    onChangeVariantPosition,
    onRemoveVariant,
    onAddVariants,
    setVariants,
    productOverrides,
    setProductOverrides,
  } = props;
  const [variantCardsState, setVariantCardsState] = useState<IVariantCardsState>({
    gridRows: undefined,
    gridConstructed: false,
    selectedVariantId: undefined,
    selectedRows: [],
  });

  function getSelectedVariant(id: number) {
    return variants.find(variant => variant.id === id);
  }

  function selectedVariantExists(id: number) {
    return getSelectedVariant(id) !== undefined;
  }

  function getRowFromPosition(position: number) {
    return Math.ceil(position / GRID_COL);
  }

  function getRowFromVariantId(variantId: number) {
    const variantPosition = getSelectedVariant(variantId)?.modifier_group?.position;
    return getRowFromPosition(variantPosition);
  }

  function getFinalVariantRow() {
    if (variants.length === 0) {
      return 1;
    } else {
      const positions = variants.map(variant => variant?.modifier_group?.position);
      const finalVariantPosition = positions.reduce((prev, next) => (prev < next ? next : prev));
      const finalVariantRow = getRowFromPosition(finalVariantPosition);

      return finalVariantRow;
    }
  }

  function isPositionInASelectedRow(position: number) {
    const row = getRowFromPosition(position);
    return variantCardsState.selectedRows.some(selectedRow => selectedRow === row);
  }

  useEffect(() => {
    if (!selectedVariantExists(variantCardsState.selectedVariantId)) {
      setVariantCardsState(prev => ({ ...prev, selectedVariantId: undefined }));
    }

    if (variantsLoaded && !variantCardsState.gridConstructed) {
      const finalVariantRow = getFinalVariantRow();
      setVariantCardsState(prev => ({
        ...prev,
        gridRows: Math.max(Math.ceil(variants.length / GRID_COL) + EXTRA_GRID_ROWS, finalVariantRow),
        gridConstructed: true,
      }));
    }

    if (variantCardsState.gridConstructed) {
      const finalVariantRow = getFinalVariantRow();

      // If the user deletes all the rows in the grid or cancels unsaved changes
      // with insufficient rows the grid will grow to fit the changes
      if (finalVariantRow > variantCardsState.gridRows) {
        setVariantCardsState(prev => ({
          ...prev,
          gridRows: finalVariantRow,
        }));
      }
    }
  }, [variants, variantsLoaded]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (variantCardsRef.current && !variantCardsRef.current.contains(event.target)) {
        setVariantCardsState(prev => ({ ...prev, selectedVariantId: undefined, selectedRows: [] }));
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  function selectRow(selectedRow: number) {
    let updatedRows: number[] = [];
    if (variantCardsState.selectedRows.some(row => row === selectedRow)) {
      updatedRows = variantCardsState.selectedRows.filter(row => row !== selectedRow);
    } else {
      updatedRows = [...variantCardsState.selectedRows, selectedRow];
    }

    setVariantCardsState(prev => ({
      ...prev,
      selectedRows: updatedRows,
    }));
  }

  function selectVariant(event: any, variantId: number) {
    if (event.shiftKey) {
      const selectedRow = getRowFromVariantId(variantId);
      selectRow(selectedRow);
    } else {
      setVariantCardsState(prev => ({
        ...prev,
        selectedVariantId: prev.selectedVariantId !== variantId ? variantId : undefined,
      }));
    }
  }

  function selectEmptyCard(event: any, position: number) {
    if (event.shiftKey) {
      const selectedRow = getRowFromPosition(position);
      selectRow(selectedRow);
    } else {
      const availablePositions = calculateSubsequentAvailablePositions(position);
      onAddVariants(availablePositions);
      setVariantCardsState(prev => ({ ...prev, selectedVariantId: undefined }));
    }
  }

  function calculateSubsequentAvailablePositions(currentPosition: number) {
    const maxPosition = GRID_COL * variantCardsState.gridRows;
    const allPositions = range(1, maxPosition + 1);
    const occupiedPositions = variants.map(variant => variant?.modifier_group?.position);
    const allUntakenPositions = difference(allPositions, occupiedPositions);
    const availablePositions = allUntakenPositions.filter(position => position >= currentPosition);

    return availablePositions;
  }

  function handleColorDropdownToggle() {
    setColorPickerDropdownOpen(prev => !prev);
  }

  function handleColorChange(color: string) {
    if (variantCardsState.selectedVariantId) {
      const productId = variants.find(variant => variant.id === variantCardsState.selectedVariantId)?.product_id;

      if (productId) {
        const existingProductOverride = productOverrides.find(productOverride => productOverride.id === productId);
        const otherProductOverrides = productOverrides.filter(productOverride => productOverride.id !== productId);

        setProductOverrides([
          ...otherProductOverrides,
          existingProductOverride
            ? { ...existingProductOverride, button_color: color }
            : { id: productId, button_color: color },
        ]);
      }
    }
  }

  function addRow() {
    if (variantCardsState.selectedVariantId) {
      const selectedRow = getRowFromVariantId(variantCardsState.selectedVariantId);
      const rowShiftThreshold = selectedRow * GRID_COL;
      const variantsBeforeThreshold = variants
        .filter(variant => variant?.modifier_group?.position <= rowShiftThreshold)
        .map(variant =>
          variant.id === variantCardsState.selectedVariantId
            ? {
                ...variant,
                modifier_group: { ...variant.modifier_group, position: variant.modifier_group?.position + GRID_COL },
              }
            : { ...variant },
        );
      const variantsAfterThreshold = variants
        .filter(variant => variant?.modifier_group?.position > rowShiftThreshold)
        .map(variant => ({
          ...variant,
          modifier_group: { ...variant.modifier_group, position: variant.position + GRID_COL },
        }));
      const allVariants = [...variantsBeforeThreshold, ...variantsAfterThreshold];

      setVariants(allVariants);
      setVariantCardsState(prev => ({ ...prev, gridRows: prev.gridRows + 1 }));
    }
  }

  function deleteRows() {
    let rowsToDelete = 0;
    const updatedVariants: IVariant[] = [];
    for (let r = 1; r <= variantCardsState.gridRows; r++) {
      if (!variantCardsState.selectedRows.some(selectedRow => selectedRow === r)) {
        const startOfRowPosition = (r - 1) * GRID_COL + 1;
        const endOfRowPosition = r * GRID_COL;
        const updatedRow: IVariant[] = variants
          .filter(variant => inRange(variant?.modifier_group?.position, startOfRowPosition, endOfRowPosition + 1))
          .map(variant => ({
            ...variant,
            modifier_group: {
              ...variant?.modifier_group,
              position: variant?.modifier_group?.position - rowsToDelete * GRID_COL,
            },
          }));

        updatedVariants.push(...updatedRow);
      } else {
        rowsToDelete++;
      }
    }

    setVariants(updatedVariants);
    setVariantCardsState(prev => ({ ...prev, gridRows: prev.gridRows - rowsToDelete, selectedRows: [] }));
  }

  function toggleDefaultVariant() {
    if (variantCardsState.selectedVariantId) {
      const updatedVariants: IVariant[] = variants.map(variant => {
        if (variant.id === variantCardsState.selectedVariantId) {
          return {
            ...variant,
            modifier_group: { ...variant.modifier_group, default: !variant.modifier_group?.default },
          };
        } else {
          return { ...variant, modifier_group: { ...variant.modifier_group, default: false } };
        }
      });

      setVariants(updatedVariants);
    }
  }

  function handleEditProduct(variant: IVariant) {
    console.log(variant);
    window.open("/admin/product/" + String(variant.product_id));
  }

  if (!variantCardsState.gridConstructed) {
    return null;
  } else {
    return (
      <div className="variant-cards-container" ref={variantCardsRef}>
        <div className="variant-cards-action-menu">
          <div>
            <ColorPickerDropdown
              isDropdownOpen={colorPickerDropdownOpen}
              setIsDropdownOpen={setColorPickerDropdownOpen}
              onChange={handleColorChange}
            >
              <Button onClick={handleColorDropdownToggle} disabled={variantCardsState.selectedVariantId === undefined}>
                Change Color
              </Button>
            </ColorPickerDropdown>
          </div>
          <Button onClick={toggleDefaultVariant} disabled={variantCardsState.selectedVariantId === undefined}>
            {variants?.find(variant => variant?.id === variantCardsState.selectedVariantId)?.modifier_group?.default
              ? "Remove Default"
              : "Set as Default"}
          </Button>
          <Button onClick={addRow} disabled={variantCardsState.selectedVariantId === undefined}>
            Add Row
          </Button>
          <Button onClick={deleteRows} disabled={variantCardsState.selectedRows.length === 0}>
            Delete Rows
          </Button>
        </div>
        <DndProvider backend={HTML5Backend}>
          <div className="variant-cards">
            {range(1, GRID_COL * variantCardsState.gridRows + 1).map(position => {
              const variant = variants.find(variant => variant?.modifier_group?.position === position);
              if (variant) {
                const isSelected = variant.id === variantCardsState.selectedVariantId;

                return (
                  <React.Fragment key={variant.id}>
                    <RightClickMenu
                      sections={[
                        [
                          {
                            icon: "pen-to-square",
                            title: "Edit Product",
                            action: () => handleEditProduct(variant),
                          },
                        ],
                      ]}
                    >
                      <VariantCard
                        variant={variant}
                        isSelected={isSelected}
                        isPositionInASelectedRow={isPositionInASelectedRow}
                        selectVariant={selectVariant}
                        onRemoveVariant={onRemoveVariant}
                        productOverrides={productOverrides}
                        isDefault={variant.modifier_group?.default}
                      />
                    </RightClickMenu>
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment key={String(Date.now()) + String(position)}>
                    <VariantCardSlot
                      position={position}
                      isPositionInASelectedRow={isPositionInASelectedRow}
                      selectEmptyCard={selectEmptyCard}
                      onChangeVariantPosition={onChangeVariantPosition}
                    />
                  </React.Fragment>
                );
              }
            })}
          </div>
        </DndProvider>
      </div>
    );
  }
}
