import { APIGet, APIPut } from "api/protocols";
import { CancelToken } from "axios";
import { IGreenFee, IItem, IPowerCart, IStub } from "redux/reducers/models/ticket";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/ticket/stub";

interface IGetTicketStub {
  code?: string;
  customer_id?: number;
  id?: number;
  status?: string;
  search?: string;
  extended?: boolean;
}

interface IGetTicketStubPdf {
  order_id?: number;
  order_line_item_id?: number;
  ticket_stub_ids?: any[];
}

export interface IGetTicketStubValidateRes {
  redemption_products: {
    green_fee: IGreenFee;
    items: IItem[];
    power_cart: IPowerCart;
  };
  stub: IStub;
}

export async function GetTicketValidate(params: { code: string }, useGlobalLoader: boolean) {
  return await APIGet<IGetTicketStubValidateRes>(baseUrl + "/validate", params, useGlobalLoader);
}

//GET Ticket Stub
export async function GetTicketStub(params: IGetTicketStub, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseUrl, params, useGlobalLoader, token);
}

//PUT Ticket Stub
export async function PutTicketStub(params: IGetTicketStub, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

export async function PutTicketStubRedeem(code: string, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + `/redeem?code=${code}`, null, useGlobalLoader);
}

export async function PutTicketStubRedeemMultiple(params: { codes: string[] }, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/redeem/multiple", params, useGlobalLoader);
}

export async function GetTicketStubPdf(params: IGetTicketStubPdf, useGlobalLoader: boolean) {
  return await APIGet(baseUrl + "/pdf", params, useGlobalLoader);
}
