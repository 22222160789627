import { StatusCode } from "api/protocols";
import { PostReservationBooking, PostReservationBookingNote } from "api/rpc/facilityAdmin/reservation/reservation";
import Callout from "components/callout/Callout";
import Radio from "components/radio";
import Sheet from "components/sheet/Sheet";
import Tabs from "components/tabs/Tabs";
import moment from "moment";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { dequeue, enqueue, IUIActions, showError } from "redux/actions/ui";
import { ILocation, IModule, ISegment } from "redux/reducers/models/reservations";
import { Select } from "components/select/index";
import "./reservation.scss";
import Spin from "components/spin/spin";
import { ICustomer } from "redux/reducers/models/customer";
import { GetCustomer, PostCustomer } from "api/rpc/2022-09/facilityAdmin/customer/customer";
import GolferCard from "components/bookingPopUp/golferCard/GolferCard";
import { GetCustomerPaymentMethod, PostPaymentMethod, PostSetup } from "api/rpc/facilityAdmin/customer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CardSection from "components/cardSection/CardSection";
import Checkbox from "components/form/checkbox/Checkbox";
import { ButtonNew as Button } from "components/buttonNew/index";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";
import { customerErrorMessage, formatDate, valueToString } from "helpers/Helpers";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";
import { IAuthState } from "redux/reducers/auth";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import TextField from "components/form/textField/TextField";
import classNames from "classnames";
import NewCustomer, { ICustomerInfoState } from "components/newCustomer/NewCustomer";
import validator from "validator";
import { useAppDispatch } from "hooks/redux";
import Portal from "elements/Portal";

interface IProps {
  uiActions: IUIActions;
  authStore: IAuthState;
  date: Date;
  module: IModule;
  selectedSegment: ISegment;
  segments: Array<ISegment>;
  selectedLocation: ILocation;
  locations: Array<ILocation>;
  open: boolean;
  onSave: () => void;
  onCancel: () => void;
}

interface IBookingState {
  guestsAmount: number;
  durationKey: string;
  locationDurations: ILocationDurations;
  selectedLocationIds: Array<number>;
  selectedTab: number;
  searching: boolean;
  bookingParticipants: Array<ICustomer>;
  guest1: string;
  guest2: string;
  guest3: string;
  guest4: string;
  guest5: string;
  guest6: string;
  guestSearchResult1: Array<ICustomer>;
  guestSearchResult2: Array<ICustomer>;
  guestSearchResult3: Array<ICustomer>;
  guestSearchResult4: Array<ICustomer>;
  guestSearchResult5: Array<ICustomer>;
  guestSearchResult6: Array<ICustomer>;
  selectedCardId: number;
  customerCreditCards: Array<Record<string, any>>;
  elementComplete: {
    cardNumber: boolean;
    cardExpiry: boolean;
    cardCvc: boolean;
  };
  saveCard: boolean;
  addCard: boolean;
  note: string;
  [key: string]: any;
}

interface INewCustomerState {
  newCustomerSheetActive: boolean;
  currentSearch: string;
}

/**
 * @property {string}
 * @description key is the name of the duration and the value is an array of segment ids.
 * @example
 * {
 *
 * '0.5 Hour': [10], '1 Hour': [10,11], '1.5 Hour': [10,11,12]
 *
 * }
 *  */
interface IDuration {
  [key: string]: Array<number>;
}

/**
 * @property {string}
 * @description key is `location_id` and the value is `IDuration Object`.
 * @example
 *
 * {
 *
 * 1: { '0.5 Hour': [10], '1 Hour': [10,11], '1.5 Hour': [10,11,12] }
 *
 * 2: { '0.5 Hour': [25], '1 Hour': [25,26], '1.5 Hour': [25,26,27] }
 *
 * }
 *  */
type ILocationDurations = Record<string, IDuration>;

const MINUTES_PER_SEGMENT = 30;

export default function ReservationBookingModalNew(props: IProps) {
  const {
    uiActions,
    date,
    module,
    onSave,
    onCancel,
    open,
    selectedSegment,
    segments,
    selectedLocation,
    locations,
    authStore,
  } = props;
  const [bookingState, setBookingState] = useState<IBookingState>({
    guestsAmount: 1,
    durationKey: "",
    locationDurations: null,
    selectedLocationIds: [],
    selectedTab: 0,
    searching: false,
    bookingParticipants: [],
    guest1: "",
    guest2: "",
    guest3: "",
    guest4: "",
    guest5: "",
    guest6: "",
    guestSearchResult1: [],
    guestSearchResult2: [],
    guestSearchResult3: [],
    guestSearchResult4: [],
    guestSearchResult5: [],
    guestSearchResult6: [],
    selectedCardId: undefined,
    customerCreditCards: [],
    elementComplete: {
      cardNumber: false,
      cardExpiry: false,
      cardCvc: false,
    },
    saveCard: false,
    addCard: false,
    note: "",
  });

  const [newCustomerState, setNewCustomerState] = useState<INewCustomerState>({
    newCustomerSheetActive: false,
    currentSearch: "",
  });

  const { Option } = Select;
  const dispatch = useAppDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (open && segments && selectedLocation && locations) {
      void setDurations();
    }
  }, [open, segments, selectedLocation]);

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = setTimeout(() => {
        void search(bookingState.guest1, "guestSearchResult1");
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      setBookingState(prevState => ({ ...prevState, guestSearchResult1: [] }));
    };
  }, [bookingState.guest1]);

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = setTimeout(() => {
        void search(bookingState.guest2, "guestSearchResult2");
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      setBookingState(prevState => ({ ...prevState, guestSearchResult2: [] }));
    };
  }, [bookingState.guest2]);

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = setTimeout(() => {
        void search(bookingState.guest3, "guestSearchResult3");
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      setBookingState(prevState => ({ ...prevState, guestSearchResult3: [] }));
    };
  }, [bookingState.guest3]);

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = setTimeout(() => {
        void search(bookingState.guest4, "guestSearchResult4");
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      setBookingState(prevState => ({ ...prevState, guestSearchResult4: [] }));
    };
  }, [bookingState.guest4]);

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = setTimeout(() => {
        void search(bookingState.guest5, "guestSearchResult5");
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      setBookingState(prevState => ({ ...prevState, guestSearchResult5: [] }));
    };
  }, [bookingState.guest5]);

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = setTimeout(() => {
        void search(bookingState.guest6, "guestSearchResult6");
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      setBookingState(prevState => ({ ...prevState, guestSearchResult6: [] }));
    };
  }, [bookingState.guest6]);

  const showLocations = useMemo(() => {
    if (locations && selectedLocation && bookingState?.locationDurations && bookingState?.durationKey) {
      return (
        locations &&
        bookingState?.locationDurations &&
        selectedLocation &&
        Object.keys(bookingState?.locationDurations)?.some(
          locationId =>
            Number(locationId) !== selectedLocation?.id &&
            bookingState?.locationDurations[locationId][bookingState?.durationKey],
        )
      );
    }
    return false;
  }, [
    locations,
    selectedLocation,
    bookingState?.locationDurations,
    bookingState?.selectedLocationIds,
    bookingState?.durationKey,
  ]);

  const search = async (guestSearchQuery: string, guestSearchResult: string) => {
    try {
      if (guestSearchQuery === "") {
        setBookingState(prevState => ({ ...prevState, [guestSearchResult]: [] }));
        return;
      } else {
        setBookingState(prevState => ({ ...prevState, searching: true }));
        const guestResults = await searchCustomers(guestSearchQuery);
        setBookingState(prevState => ({ ...prevState, searching: false, [guestSearchResult]: guestResults }));
      }
    } catch (error) {
      console.log("err", error);
    }
    return;
  };

  function setDurations() {
    const locationDurations: ILocationDurations = {};
    locations?.forEach(location => {
      let minutes = 0;
      let hours = 0;
      const filteredSegments = segments?.filter(segment => segment?.location_id === location?.id);
      const max = Math.min(10, filteredSegments?.length);
      locationDurations[location?.id] = {};
      for (let i = 0; i < max; i++) {
        minutes += MINUTES_PER_SEGMENT;
        if (filteredSegments[i]?.blocked_type === "open" && filteredSegments[i]?.booking_id === null) {
          hours = minutes / 60;
          //Set the durations object keys as the hours and the segment ids array as the value
          locationDurations[location?.id][`${hours} Hour${hours > 1 ? "s" : ""}`] = filteredSegments
            ?.filter((filteredSegment: ISegment, index: number) => index <= i)
            ?.map((segment: ISegment) => segment?.id);
        } else {
          break;
        }
      }
    });

    //If no duration times are available to select, close the booking modal
    if (Object.keys(locationDurations[selectedLocation?.id])?.length === 0) {
      uiActions.showError(t("secure.facility.reservation.reservation.008"));
      void closeBookingModal();
      return;
    }

    const durationKey = Object.keys(locationDurations[selectedLocation?.id])[0];

    setBookingState(prevState => ({
      ...prevState,
      locationDurations,
      durationKey,
      selectedLocationIds: [selectedLocation?.id],
    }));
  }

  async function searchCustomers(params: string) {
    const customerRes = await GetCustomer({ search: params }, false);

    if (customerRes.status !== StatusCode.OK) {
      console.log(customerRes?.message);
      return;
    }

    return customerRes?.data;
  }

  const tabs = [
    {
      id: "booking-details",
      content: t("secure.facility.reservation.reservation_booking_modal_new.001"),
    },
    {
      id: "credit-card",
      content: t("secure.facility.reservation.reservation_booking_modal_new.002"),
    },
    {
      id: "notes",
      content: t("secure.facility.reservation.reservation_booking_modal_new.033"),
    },
  ];

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const { id, value } = e.target;
    setBookingState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleTabChange(tabIndex: number) {
    if (tabIndex === 0) {
      setBookingState(prevState => ({
        ...prevState,
        elementComplete: {
          cardNumber: false,
          cardExpiry: false,
          cardCvc: false,
        },
      }));
    } else if (tabIndex === 1) {
      if (bookingState?.bookingParticipants?.length > 0) {
        void loadCustomerPaymentMethods();
      } else {
        setBookingState(prevState => ({ ...prevState, customerCreditCards: [], selectedCardId: undefined }));
      }
    }

    setBookingState(prevState => ({ ...prevState, selectedTab: tabIndex }));
  }

  async function loadCustomerPaymentMethods() {
    const paymentMethodRes = await GetCustomerPaymentMethod(
      { customer_id: bookingState?.bookingParticipants[0]?.id },
      true,
    );
    if (paymentMethodRes?.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.reservation.reservation_booking_modal_new.003"));
      return;
    }
    setBookingState(prevState => ({ ...prevState, customerCreditCards: paymentMethodRes?.data }));
  }

  function handleGuestAmountChange(amount: number) {
    if (amount < bookingState.guestsAmount) {
      void handleRemoveGuestFromQuantityChange(amount);
    }
    setBookingState(prevState => ({ ...prevState, guestsAmount: amount }));
  }

  function handleDropDownChange(value: string | number, id: string) {
    const locationDurations = { ...bookingState?.locationDurations };
    let selectedLocationIds = [...bookingState?.selectedLocationIds];
    if (locationDurations && selectedLocationIds) {
      Object.keys(locationDurations)?.forEach(locationId => {
        if (!locationDurations[locationId][value] || locationDurations[locationId][value]?.length < 1) {
          selectedLocationIds = selectedLocationIds?.filter(location => location !== Number(locationId));
        }
      });
    }
    setBookingState(prevState => ({ ...prevState, [id]: value, selectedLocationIds }));
  }

  const handleCustomerSearch = (query: string, name: string) => {
    setBookingState(prevState => ({ ...prevState, [name]: query }));
    setNewCustomerState(prevState => ({ ...prevState, currentSearch: query }));
  };

  function handleCustomerSelection(id: number, customer: any) {
    const updatedBookingParticipants = [...bookingState.bookingParticipants];
    updatedBookingParticipants.push(customer);
    setBookingState(prevState => ({
      ...prevState,
      guest1: "",
      guest2: "",
      guest3: "",
      guest4: "",
      guest5: "",
      guest6: "",
      guestSearchResult1: [],
      guestSearchResult2: [],
      guestSearchResult3: [],
      guestSearchResult4: [],
      guestSearchResult5: [],
      guestSearchResult6: [],
      bookingParticipants: updatedBookingParticipants,
    }));
  }

  function handleRemoveGuestFromQuantityChange(newQuantity: number) {
    const updatedBookingParticipants = [...bookingState.bookingParticipants].filter(
      (participant, index) => index + 1 <= newQuantity,
    );
    setBookingState(prevState => ({
      ...prevState,
      bookingParticipants: updatedBookingParticipants,
      guest1: "",
      guest2: "",
      guest3: "",
      guest4: "",
      guest5: "",
      guest6: "",
      guestSearchResult1: [],
      guestSearchResult2: [],
      guestSearchResult3: [],
      guestSearchResult4: [],
      guestSearchResult5: [],
      guestSearchResult6: [],
    }));
  }

  function handleRemoveGuest(index: number) {
    const updatedBookingParticipants = [...bookingState.bookingParticipants];
    let selectedCardId = bookingState.selectedCardId;
    updatedBookingParticipants.splice(index, 1);

    if (index === 0) {
      selectedCardId = undefined;
    }

    setBookingState(prevState => ({
      ...prevState,
      bookingParticipants: updatedBookingParticipants,
      guest1: "",
      guest2: "",
      guest3: "",
      guest4: "",
      guest5: "",
      guest6: "",
      guestSearchResult1: [],
      guestSearchResult2: [],
      guestSearchResult3: [],
      guestSearchResult4: [],
      guestSearchResult5: [],
      guestSearchResult6: [],
      selectedCardId,
    }));
  }

  function handleCardSectionChange(
    e: StripeCardNumberElementChangeEvent | StripeCardExpiryElementChangeEvent | StripeCardCvcElementChangeEvent,
  ) {
    if (bookingState.selectedCardId !== undefined) {
      setBookingState(prevState => ({ ...prevState, selectedCardId: undefined }));
    }

    setBookingState(prevState => ({
      ...prevState,
      elementComplete: { ...prevState.elementComplete, [e.elementType]: e.complete },
    }));
  }

  function onSelectCreditCard(id: number) {
    if (id > 0) {
      if (id === bookingState.selectedCardId) {
        // Deselect card
        setBookingState(prevState => ({ ...prevState, selectedCardId: undefined }));
      } else {
        setBookingState(prevState => ({ ...prevState, selectedCardId: id, addCard: false }));
      }
    } else {
      uiActions.showError(t("secure.facility.reservation.reservation_booking_modal_new.004"));
    }
  }

  async function collectCard(customer_id: number) {
    if (!stripe || !elements) {
      uiActions.showError(t("secure.facility.reservation.reservation_booking_modal_new.005"));
      return;
    }

    // Loader for length of operation to stop user from clicking
    uiActions.enqueue();
    try {
      let setupCardRes;
      const paymentMethod = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement("cardNumber"),
      });

      if (paymentMethod.paymentMethod) {
        setupCardRes = await setupCard(paymentMethod.paymentMethod.id, customer_id);
        uiActions.dequeue();
        return {
          customer_payment_method_id: setupCardRes,
        };
      } else {
        uiActions.showError(paymentMethod?.error?.message);
      }
    } catch (e) {
      uiActions.dequeue();
    }

    uiActions.dequeue();
  }

  async function setupCard(payment_method: string, customer_id: number) {
    const setupRes = await PostSetup({ customer_id }, true);

    if (setupRes.status !== StatusCode.OK) {
      uiActions.showError(setupRes?.data?.message);
      return null;
    } else {
      if (payment_method !== "undefined") {
        const stripeRes = await stripe.confirmCardSetup(setupRes?.data?.data?.setup_intent?.client_secret, {
          payment_method: payment_method,
        });

        if (stripeRes.error) {
          uiActions.showError(stripeRes?.error?.message);
        } else {
          const methodRes = await PostPaymentMethod({
            payment_method_id: stripeRes?.setupIntent?.payment_method,
            customer_id: customer_id,
            save_card: bookingState.saveCard,
          });
          if (methodRes.status !== StatusCode.OK) {
            uiActions.showError(methodRes?.data?.message);
            return null;
          } else {
            uiActions.showSuccess(methodRes.data.message);
            setBookingState(prevState => ({ ...prevState, selectedCardId: methodRes?.data?.data?.id }));

            return methodRes?.data?.data?.id as number;
          }
        }
      }
    }
  }

  async function saveBooking() {
    let customerPaymentMethodId = bookingState.selectedCardId;

    //If the new card fields are filled out, set up new payment method and use that payment method
    if (elementsComplete) {
      const newCardRes = await collectCard(bookingState?.bookingParticipants[0]?.id);
      if (newCardRes?.customer_payment_method_id) {
        customerPaymentMethodId = newCardRes?.customer_payment_method_id;
      }
    }

    dispatch(enqueue());
    //Main reservation
    const bookingRes = await PostReservationBooking(
      {
        customer_id: bookingState?.bookingParticipants[0]?.id,
        module_id: module?.id,
        location_id: selectedLocation?.id,
        segment_ids: bookingState?.locationDurations[selectedLocation?.id][bookingState?.durationKey],
        date: formatDate(date),
        quantity: bookingState?.guestsAmount,
        customer_payment_method_id: customerPaymentMethodId,
      },
      false,
    );

    if (bookingRes?.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.reservation.reservation_booking_modal_new.006"));
      dispatch(dequeue());
      return;
    }

    //Additional reservations for other locations that were selected
    for (const locationId in bookingState.locationDurations) {
      if (
        bookingState.selectedLocationIds.includes(Number(locationId)) &&
        Number(locationId) !== selectedLocation?.id
      ) {
        const extraBookingRes = await PostReservationBooking(
          {
            customer_id: bookingState?.bookingParticipants[0]?.id,
            module_id: module?.id,
            location_id: Number(locationId),
            segment_ids: bookingState?.locationDurations[locationId][bookingState?.durationKey],
            date: formatDate(date),
            quantity: bookingState?.guestsAmount,
            customer_payment_method_id: customerPaymentMethodId,
          },
          false,
        );
        if (extraBookingRes?.status !== StatusCode.OK) {
          uiActions.showError(t("secure.facility.reservation.reservation_booking_modal_new.006"));
        }
      }
    }
    dispatch(dequeue());

    const bookingId = bookingRes.status === StatusCode.OK ? bookingRes?.data?.id : undefined;
    if (bookingId && bookingState.note !== "") {
      const bookingNoteRes = await PostReservationBookingNote(
        { reservation_booking_id: bookingId, content: bookingState.note },
        true,
      );
      if (bookingNoteRes.status !== StatusCode.OK) {
        uiActions.showError(t("secure.facility.reservation.reservation_booking_modal_new.035"));
      }
    }

    void closeBookingModal();
    void onSave();
    uiActions.showSuccess(t("secure.facility.reservation.reservation_booking_modal_new.007"));
  }

  function closeBookingModal() {
    setBookingState(prevState => ({
      ...prevState,
      guestsAmount: 1,
      selectedTab: 0,
      durationKey: "",
      bookingParticipants: [],
      locationDurations: null,
      selectedLocationIds: [],
      guest1: "",
      guest2: "",
      guest3: "",
      guest4: "",
      guest5: "",
      guest6: "",
      guestSearchResult1: [],
      guestSearchResult2: [],
      guestSearchResult3: [],
      guestSearchResult4: [],
      guestSearchResult5: [],
      guestSearchResult6: [],
      customerCreditCards: [],
      selectedCardId: undefined,
      saveCard: false,
      addCard: false,
      note: "",
    }));
    void onCancel();
  }

  function closeNewCustomer() {
    setNewCustomerState(prevState => ({
      ...prevState,
      newCustomerSheetActive: false,
    }));
  }

  async function createNewCustomer(customerInfo: ICustomerInfoState) {
    if (
      customerInfo.firstName === "" ||
      customerInfo.lastName === "" ||
      (customerInfo.emailAddress === "" && customerInfo.phoneNumber === "")
    ) {
      uiActions.showError(t("secure.facility.reservation.reservation_booking_modal_new.008"));
      return;
    }

    const customerRes = await PostCustomer(
      {
        first_name: customerInfo.firstName,
        last_name: customerInfo.lastName,
        phone: customerInfo.phoneNumber ?? null,
        email: customerInfo.emailAddress ?? null,
      },
      true,
    );

    if (customerRes.status !== 200) {
      dispatch(showError(customerErrorMessage(t, customerRes?.data)));
      return;
    }

    uiActions.showSuccess(t("secure.facility.reservation.reservation_booking_modal_new.010"));
    void handleCustomerSelection(null, customerRes?.data);
    void closeNewCustomer();
  }

  const elementsComplete =
    bookingState.elementComplete.cardNumber === true &&
    bookingState.elementComplete.cardExpiry === true &&
    bookingState.elementComplete.cardCvc === true;

  const creditCardOverride = authStore?.user?.permissions?.reservations_credit_card_override;
  const creditCardNotSelected = !bookingState.selectedCardId && !elementsComplete;

  function openNewCustomerSheet() {
    setNewCustomerState(prevState => ({
      ...prevState,
      newCustomerSheetActive: true,
    }));
  }

  function handleAddLocationSelection(locationId: number) {
    const selectedLocationIds = [...bookingState?.selectedLocationIds];
    const locationIndex = selectedLocationIds.indexOf(locationId);
    if (locationIndex === -1) {
      selectedLocationIds.push(locationId);
    } else {
      selectedLocationIds.splice(locationIndex, 1);
    }
    setBookingState(prevState => ({ ...prevState, selectedLocationIds }));
  }

  return (
    <>
      <Portal isMounted={open}>
        <Sheet
          size="medium"
          open={open}
          closable
          title={t("secure.facility.reservation.reservation_booking_modal_new.011")}
          cancelText={t("secure.facility.reservation.reservation_booking_modal_new.012")}
          okText={
            !creditCardOverride && selectedSegment?.credit_card_required && creditCardNotSelected
              ? t("secure.facility.reservation.reservation_booking_modal_new.013")
              : t("secure.facility.reservation.reservation_booking_modal_new.014")
          }
          onOk={
            !creditCardOverride && selectedSegment?.credit_card_required && creditCardNotSelected
              ? () => handleTabChange(1)
              : saveBooking
          }
          onCancel={closeBookingModal}
          backDropCancel={false}
          //height="flexible"
          okDisabled={
            bookingState?.bookingParticipants?.length === 0 ||
            !bookingState?.guestsAmount ||
            !bookingState?.locationDurations
              ? true
              : false
          }
        >
          <div className="reservation-booking-modal">
            <p className="time-text">
              {moment(selectedSegment?.start_time, "hh:mm:ss").format("h:mm A")}
              {` - ${selectedLocation?.title}`}
            </p>
            <p className="date-text">{moment(date).format("LL")}</p>

            <Tabs tabs={tabs} selected={bookingState?.selectedTab} onSelect={handleTabChange}></Tabs>
            {bookingState?.selectedTab === 0 ? (
              <div className="facility-reservation-booking-modal">
                <div className="booking-modal-options">
                  <div className="booking-modal-flex-grow">
                    <h3>{t("secure.facility.reservation.reservation_booking_modal_new.015")}</h3>
                    {bookingState?.locationDurations && selectedLocation ? (
                      <Select
                        defaultValue={bookingState?.durationKey}
                        onChange={(value: string) => handleDropDownChange(value, "durationKey")}
                      >
                        {Object?.keys(bookingState?.locationDurations[selectedLocation?.id])?.map(
                          (durationKey, durationIndex) => {
                            return (
                              <Option key={durationIndex} value={durationKey} name={durationKey}>
                                {durationKey}
                              </Option>
                            );
                          },
                        )}
                      </Select>
                    ) : (
                      <div className="booking-modal-spinner">
                        <Spin />
                      </div>
                    )}
                  </div>
                  <div className="booking-modal-flex-grow">
                    <h3>{t("secure.facility.reservation.reservation_booking_modal_new.016")}</h3>
                    <Radio.Group
                      name="number_of_guests"
                      onChange={handleGuestAmountChange}
                      value={bookingState.guestsAmount}
                    >
                      {[...Array(6).keys()].map(index => {
                        return (
                          <Radio.Button key={index} value={index + 1}>
                            {index + 1}
                          </Radio.Button>
                        );
                      })}
                    </Radio.Group>
                  </div>
                </div>
                {showLocations && (
                  <div>
                    <h3>Add Locations</h3>
                    <div className={"ui-checkbox-group reservations-checkbox-group"}>
                      {[...locations]
                        ?.sort((location1, location2) => (location1.id === selectedLocation?.id ? -1 : 0))
                        ?.map((location, index) => {
                          if (
                            bookingState?.locationDurations[location?.id] &&
                            bookingState?.locationDurations[location?.id][bookingState?.durationKey] &&
                            bookingState?.locationDurations[location?.id][bookingState?.durationKey]?.length > 0
                          ) {
                            return (
                              <div
                                key={index}
                                onClick={
                                  selectedLocation?.id !== location?.id
                                    ? () => handleAddLocationSelection(location?.id)
                                    : undefined
                                }
                                className={classNames("ui-checkbox-group-item", {
                                  "ui-checkbox-group-item_selected": bookingState?.selectedLocationIds.includes(
                                    location?.id,
                                  ),
                                  "ui-checkbox-group-disabled": selectedLocation?.id === location?.id,
                                })}
                              >
                                <div className="ui-checkbox-group-item-content">
                                  <p className="location-title">{location?.title}</p>
                                </div>
                                <div className="pointer-events-none">
                                  <Checkbox
                                    size="medium"
                                    value={location?.id}
                                    checked={bookingState?.selectedLocationIds.includes(location?.id)}
                                    onChange={() => handleAddLocationSelection(location?.id)}
                                    disabled={selectedLocation?.id === location?.id}
                                  />
                                </div>
                              </div>
                            );
                          }
                        })}
                    </div>
                  </div>
                )}

                <div>
                  <h3>Add Guests</h3>
                  {/* Change array size to 6 later */}
                  {[...Array(1).keys()].map(index => {
                    if (bookingState.bookingParticipants[index]) {
                      return (
                        <GolferCard
                          closable={true}
                          removeGolfer={() => handleRemoveGuest(index)}
                          name={bookingState.bookingParticipants[index]?.full_name}
                          email={bookingState.bookingParticipants[index]?.email}
                          memberCode={bookingState.bookingParticipants[index]?.member_code}
                          customerType={bookingState.bookingParticipants[index]?.customer_type}
                          phone={bookingState.bookingParticipants[index]?.phone}
                          key={index}
                        />
                      );
                    } else {
                      const searchResultKey = "guestSearchResult".concat((index + 1).toString());
                      const queryKey = "guest".concat((index + 1).toString());
                      return (
                        <Select
                          key={index}
                          label={`Guest ${index + 1}`}
                          showSearch
                          onSearch={(query: string) => handleCustomerSearch(query, queryKey)}
                          onChange={(id: number, customer: ICustomer) => handleCustomerSelection(id, customer)}
                          allowClear
                          searchValue={bookingState[queryKey]}
                          showDropDownOnFocus={true}
                          searching={bookingState.searching}
                          disabled={index + 1 <= bookingState.guestsAmount ? false : true}
                          placeholder={t("secure.facility.reservation.reservation_booking_modal_new.017")}
                          usePortal
                          autoFocus={open}
                        >
                          <div className="ui-select-dropdown-list-item" onClick={() => openNewCustomerSheet()}>
                            <p>{t("secure.facility.reservation.reservation_booking_modal_new.018")}</p>
                          </div>
                          {bookingState[searchResultKey].map((customer: ICustomer, customerIndex: number) => {
                            return (
                              <Option key={customerIndex} value={customer?.id} extraValues={customer}>
                                <div className="flex justify-between">
                                  <div>
                                    <div className="font-semibold text-lg">{customer?.full_name}</div>
                                    <div className="text-sm text-gray-500">{customer?.customer_type}</div>
                                    <div className="text-sm text-gray-500">{customer?.email}</div>
                                    <div className="text-sm text-gray-500">
                                      {customer?.phone ? customer?.phone : null}
                                    </div>
                                  </div>

                                  <div className="font-medium text-base text-gray-500 self-end">
                                    {customer?.member_code}
                                  </div>
                                </div>
                              </Option>
                            );
                          })}
                        </Select>
                      );
                    }
                  })}
                </div>
              </div>
            ) : bookingState?.selectedTab === 1 ? (
              <div style={{ overflowY: "scroll", height: "32rem" }}>
                <div className="flex flex-column">
                  <span className="text-xl font-semibold mb-4 mt-2">
                    {t("secure.facility.reservation.reservation_booking_modal_new.019")}
                  </span>
                  {bookingState.bookingParticipants.length >= 1 ? (
                    <div>
                      {bookingState.customerCreditCards?.length >= 1 ? (
                        <div className="ui-checkbox-group">
                          {bookingState.customerCreditCards?.map(
                            (paymentMethod: Record<string, any>, index: number) => {
                              return (
                                <div
                                  key={index}
                                  onClick={() => onSelectCreditCard(paymentMethod.id)}
                                  className={classNames("ui-checkbox-group-item", {
                                    "ui-checkbox-group-item_selected":
                                      paymentMethod.id === bookingState?.selectedCardId,
                                  })}
                                >
                                  <div className="ui-checkbox-group-item-content">
                                    <div className="ui-checkbox-group-item-lead">
                                      <FontAwesomeIcon size="1x" icon={["far", "credit-card"]} />
                                    </div>
                                    <div className="ui-checkbox-group-item-text">
                                      <p className="text-sm text-medium text-gray-700">
                                        {valueToString(paymentMethod.brand, "capitalize")}{" "}
                                        {t("secure.facility.reservation.reservation_booking_modal_new.036")}{" "}
                                        {paymentMethod.last4}
                                      </p>
                                      <p className="text-sm text-regular text-gray-600">
                                        {t("secure.facility.reservation.reservation_booking_modal_new.037")}{" "}
                                        {paymentMethod.expiry}
                                      </p>
                                    </div>
                                  </div>
                                  <Checkbox
                                    size="medium"
                                    value={paymentMethod.id}
                                    checked={paymentMethod.id === bookingState?.selectedCardId}
                                    onChange={() => onSelectCreditCard(paymentMethod.id)}
                                  />
                                </div>
                              );
                            },
                          )}
                        </div>
                      ) : (
                        <Callout
                          type="warning"
                          title={"Add a payment method"}
                          content={"Customer does not have any saved payment methods on their account"}
                        />
                      )}
                    </div>
                  ) : (
                    <Callout
                      type="info"
                      title={t("secure.facility.reservation.reservation_booking_modal_edit.026")}
                      content={t("secure.facility.reservation.reservation_booking_modal_edit.027")}
                    />
                  )}
                </div>

                {bookingState.addCard && (
                  <div className="flex flex-column">
                    <span className="text-xl font-semibold mb-4 mt-2">
                      {t("secure.facility.reservation.reservation_booking_modal_new.022")}
                    </span>
                    {open && <CardSection onChange={handleCardSectionChange} type="one-row" />}
                    <Checkbox
                      label={t("secure.facility.reservation.reservation_booking_modal_new.023")}
                      size="small"
                      value={bookingState.saveCard}
                      checked={bookingState.saveCard}
                      onChange={() => setBookingState(prevState => ({ ...prevState, saveCard: !prevState.saveCard }))}
                    />
                  </div>
                )}
                <Button
                  type="text"
                  onClick={() => setBookingState(prevState => ({ ...prevState, addCard: !prevState.addCard }))}
                  className="mt-2 mr-auto"
                >
                  <span>
                    {bookingState.addCard === false
                      ? t("secure.facility.reservation.reservation_booking_modal_new.024")
                      : t("secure.facility.reservation.reservation_booking_modal_new.025")}
                  </span>
                </Button>
              </div>
            ) : bookingState?.selectedTab === 2 ? (
              <div className="reservation-booking-notes">
                <p className="add-note-text">Add Note</p>
                <TextField id="note" rows={2} value={bookingState.note} onChange={handleInputChange}></TextField>
                <div className="clear-button-container">
                  <Button
                    className="note-clear-button"
                    size="small"
                    type="secondary"
                    onClick={() => setBookingState(prevState => ({ ...prevState, note: "" }))}
                  >
                    CLEAR
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
        </Sheet>
      </Portal>

      <NewCustomer
        newCustomerSheetActive={newCustomerState.newCustomerSheetActive}
        onCancel={closeNewCustomer}
        onOk={createNewCustomer}
        searchValue={newCustomerState.currentSearch}
      />
    </>
  );
}
