import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import {
  ExtendedFacilityProductFilterKey,
  FacilityProductExtendedFilters,
  GetProduct,
  PutProductAttach,
  PutProductDetach,
} from "api/rpc/2022-09/facilityAdmin/product/product";
import { GetDepartments as GetFacilityDepartments } from "api/rpc/2022-09/facilityAdmin/facility/facility";
import { TExtendedProductFilters } from "api/rpc/2024-04/facilityAdmin/product/product";

import { IProduct, Department, ICategories, IDepartments, ISubcategories } from "redux/reducers/models/product";
import { useAppSelector } from "hooks/redux";
import useModal from "hooks/modals/useModal";
import { sortObj } from "helpers/Helpers";

import Card from "components/card/Card";
import DropFilter from "components/dropdown/DropFilter";
import Page from "components/page/Page";
import Search from "components/search/Search";
import ProductTypesDropFilter from "components/filters/ProductTypesDropFilter";
import Popup from "components/popup/Popup";
import ProductsTable from "./ProductsTable";

import "pages/secure/facility/product/Product.scss";

type ProductFilters = {
  search: string;
  limit: number;
  offset: number;
  /** Display products that have been removed from the facility inventory. */
  all: boolean;
  extended: FacilityProductExtendedFilters;
};

type DepartmentState = {
  isLoaded: boolean;
  departments: IDepartments[] | null;
  categories: ICategories[] | null;
  subcategories: ISubcategories[] | null;
};

const PRODUCT_TYPES_FILTER_KEY = "product_types_filters";
const PRODUCT_DEPARTMENT_FILTER_KEY = "product_department_filters";
const PRODUCT_CATEGORY_FILTER_KEY = "product_category_filters";
const PRODUCT_SUBCATEGORY_FILTER_KEY = "product_subcategory_filters";

export default function Products() {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const { authStore, facilityStore, filterHistoryStore } = useAppSelector(store => store);
  const allProductFilter = filterHistoryStore.all_products;

  const permissionToCreate =
    facilityStore.facility?.permissions?.products_create && authStore?.user?.permissions?.products_create;

  const [products, setProducts] = useState<IProduct[]>(undefined);

  // Define filter presets here
  const [filters, setFilters] = useState<ProductFilters>({
    search: undefined,
    limit: 50,
    offset: 0,
    all: allProductFilter?.currentFilter ? allProductFilter.currentFilter[0] : false,
    extended: {
      type: filterHistoryStore[PRODUCT_TYPES_FILTER_KEY]
        ? filterHistoryStore[PRODUCT_TYPES_FILTER_KEY].currentFilter
        : [],
      department_id: filterHistoryStore[PRODUCT_DEPARTMENT_FILTER_KEY]
        ? filterHistoryStore[PRODUCT_DEPARTMENT_FILTER_KEY].currentFilter
        : [],
      category_id: filterHistoryStore[PRODUCT_CATEGORY_FILTER_KEY]
        ? filterHistoryStore[PRODUCT_CATEGORY_FILTER_KEY].currentFilter
        : [],
      subcategory_id: filterHistoryStore[PRODUCT_SUBCATEGORY_FILTER_KEY]
        ? filterHistoryStore[PRODUCT_SUBCATEGORY_FILTER_KEY].currentFilter
        : [],
    },
  });

  const [facilityDepartments, setFacilityDepartments] = useState<DepartmentState>({
    isLoaded: false,
    departments: null,
    categories: null,
    subcategories: null,
  });

  const {
    state: detachProductModal,
    updateModal: updateDetachProductModal,
    closeModal: closeDetachProductModal,
  } = useModal({ productIds: [] as Array<number> });

  // Load departments and their categories
  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadFacilityDepartments(source.token);
    return () => {
      source.cancel();
    };
  }, []);

  // Call the API endpoint when a filter changes only after the facilityDepartments have been set.
  useEffect(() => {
    const source = axios.CancelToken.source();

    if (filters.search !== undefined && allProductFilter?.currentFilter && facilityDepartments.isLoaded) {
      void loadProducts(source.token);
    }

    return () => {
      source.cancel();
    };
  }, [filters, filters.extended, facilityDepartments.isLoaded]);

  async function loadProducts(token?: CancelToken) {
    setProducts(undefined);

    const extendedFiltersWanted = [filters.extended].some(
      value =>
        value.type.length > 0 ||
        value.category_id.length > 0 ||
        value.department_id.length > 0 ||
        value.subcategory_id.length > 0,
    );

    // capture extendedFilters and format for backend
    const filterKeys = Object.keys(filters.extended) as ExtendedFacilityProductFilterKey[];
    const extendedFilters = filterKeys
      .map(key => {
        if (filters.extended[key].length > 0) {
          return {
            label: key,
            value: filters.extended[key] ?? undefined,
          };
        }
      })
      .filter(value => value !== undefined);

    //define GetProduct parameters
    const getProductParams = {
      search: filters.search,
      limit: filters.limit,
      sales_channels: true,
      offset: filters.offset,
      all: allProductFilter?.currentFilter && allProductFilter.currentFilter[0] ? true : undefined, //passing false to the API has no affect on results
      filters: extendedFiltersWanted ? extendedFilters : undefined,
    };

    console.log("API call", getProductParams); //debug

    const productRes = await GetProduct(getProductParams, false, token);

    if (productRes.status === StatusCode.OK) {
      setProducts(productRes.data);
    }
  }

  async function loadFacilityDepartments(token: CancelToken) {
    const { status, data } = await GetFacilityDepartments(false, token);
    if (status === StatusCode.OK) {
      const sortedData = sortObj<IDepartments>(data, "title");

      setFacilityDepartments({
        isLoaded: true,
        departments: sortedData.filter(department => department.type === "department"),
        categories: (sortedData as Department[]).filter(
          department => department.type === "category",
        ) as ICategories[],
        subcategories: (sortedData as Department[]).filter(
          department => department.type === "subcategory",
        ) as ISubcategories[],
      });
    }
  }

  async function attachProductToFacility(event: React.MouseEvent<HTMLElement, MouseEvent>, productId: number) {
    event.stopPropagation();

    const res = await PutProductAttach({ product_id: productId }, true);
    if (res.status !== StatusCode.OK) {
      return;
    }

    const tempProducts = [...products];
    let tempSingleProduct = tempProducts.find(product => product.id === productId);

    tempSingleProduct = {
      ...tempSingleProduct,
      facility_access: true,
    };

    tempProducts.splice(
      tempProducts.findIndex(product => product.id === productId),
      1,
      tempSingleProduct,
    );

    setProducts(tempProducts);
  }

  async function detachProductFromFacility() {
    const res = await PutProductDetach({ product_ids: detachProductModal.productIds }, true);
    if (res.status !== StatusCode.OK) {
      return;
    }
    closeDetachProductModal();
    void loadProducts();
  }

  function setAllProductFilter(filterData: Array<Record<string, any>>) {
    // indicates no filters are wanted
    if (filterData.length === 0) {
      setFilters(prev => ({ ...prev, all: false }));
      return;
    }
    //TODO : adjust this function if adding more than 1 extra filter
    setFilters(prev => ({
      ...prev,
      all: allProductFilter.currentFilter[0],
    }));
  }

  function handleTableNavigation(direction: "prev" | "next") {
    switch (direction) {
      case "prev": {
        setFilters(prev => ({ ...prev, offset: prev.offset - prev.limit }));
        return;
      }
      case "next": {
        setFilters(prev => ({ ...prev, offset: prev.offset + prev.limit }));
        return;
      }
      default:
        return;
    }
  }

  function handleExtendedFilter(filterReturn: Record<string, any>[], type: keyof TExtendedProductFilters) {
    setFilters(prev => ({
      ...prev,
      extended: {
        ...prev.extended,
        [type]: filterReturn.map(filter => filter.id as number),
      },
    }));
  }

  const primaryAction = {
    content: t("secure.facility.product.products.001"),
    action: () => history.push("/admin/product/new"),
    disabled: !permissionToCreate,
  };

  return (
    <Page title={t("secure.facility.product.products.002")} primaryAction={primaryAction}>
      <div className="flex flex-row flex-wrap gap-4">
        <DropFilter
          title={t("secure.facility.product.products.007")} // TODO: Translation
          filterData={[{ value: "all-products", title: t("secure.facility.product.products.008") }]}
          filterLabelPropFromData="title"
          filterIdPropFromData="value"
          filterType="Checkbox"
          applyFilters={setAllProductFilter}
          save
          historyKey="all_products"
          leftAligned
        />
        <ProductTypesDropFilter
          defaultCheckboxes={filters.extended.type}
          historyKey={PRODUCT_TYPES_FILTER_KEY}
          handleApply={filterReturn =>
            setFilters(prev => ({
              ...prev,
              extended: {
                ...prev.extended,
                type: filterReturn,
              },
            }))
          }
          userLevel="facility"
        />

        <DropFilter
          title={t("secure.facility.product.products.009")}
          filterData={facilityDepartments.departments}
          filterLabelPropFromData="title"
          filterIdPropFromData="id"
          filterType="Checkbox"
          historyKey={PRODUCT_DEPARTMENT_FILTER_KEY}
          applyFilters={filterReturn => handleExtendedFilter(filterReturn, "department_id")}
          defaultCheckboxes={filters.extended.department_id}
        />

        <DropFilter
          title={t("secure.facility.product.products.010")}
          filterData={facilityDepartments.categories}
          filterLabelPropFromData="title"
          filterIdPropFromData="id"
          filterType="Checkbox"
          historyKey={PRODUCT_CATEGORY_FILTER_KEY}
          applyFilters={filterReturn => handleExtendedFilter(filterReturn, "category_id")}
          defaultCheckboxes={filters.extended.category_id}
        />

        <DropFilter
          title={t("secure.facility.product.products.011")}
          filterData={facilityDepartments.subcategories}
          filterLabelPropFromData="title"
          filterIdPropFromData="id"
          filterType="Checkbox"
          historyKey={PRODUCT_SUBCATEGORY_FILTER_KEY}
          applyFilters={filterReturn => handleExtendedFilter(filterReturn, "subcategory_id")}
          defaultCheckboxes={filters.extended.subcategory_id}
        />
      </div>
      <div className="flex-grow mb-4 pt-4">
        <Search
          searchCallback={searchValue => setFilters(prev => ({ ...prev, search: searchValue, offset: 0 }))}
          historyKey="products-all"
          restrictPresetToRoutes={["admin/product"]}
        />
      </div>
      <Card style={{ border: 0 }}>
        <ProductsTable
          type="facility"
          products={products}
          attachProductToFacility={(event, productId) => attachProductToFacility(event, productId)}
          tableLimit={filters.limit}
          tableOffset={filters.offset}
          handleTableOffset={direction => handleTableNavigation(direction)}
          removeProducts={idArray => updateDetachProductModal({ isOpen: true, productIds: idArray })}
        />
      </Card>

      <Popup
        open={detachProductModal.isOpen}
        onCancel={closeDetachProductModal}
        onOk={detachProductFromFacility}
        type="warning"
        title="Remove Products?" // TODO: Translation
        description="Are you sure you want to remove the selected products from the facility?" // TODO: Translation
      />
    </Page>
  );
}
