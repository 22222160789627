import React from "react";
import "./plusMinus.scss";

// Credits: https://codepen.io/NeedHate/pen/oezqbK
export default function PlusMinus(props: {
  active: boolean;
  size?: "small" | "large";
  onClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  hovered?: boolean;
}) {
  const componentSize: number =
    props.size && props.size === "small" ? 10 : props.size && props.size === "large" ? 20 : null;

  return (
    <span
      className={`plus-minus${props.active ? " active" : ""}${props.hovered ? " hovered" : ""}`}
      style={componentSize && { height: `${componentSize}px`, width: `${componentSize}px` }}
      onClick={e => (props.onClick ? props.onClick(e) : undefined)}
    ></span>
  );
}
