import React from "react";

type TimePickInputProps = {
  value: string | undefined;
  onChange: (value: string) => void; // '--' value when clearing input
  /** Determine keyboard logic on input --->  0-12  |  0-59  |  'a' or 'p' */
  type: "hour" | "minute" | "meridiem";
  disabled?: boolean;
};

type TimePickInputTimerProps = TimePickInputProps & {
  type: "hour";
  disableMeridiemHour?: boolean;
};

const numberRegex = /[0-9]/;
const hourRegex = /^(?:1[0-2]|[1-9])$/;
const minuteRegex = /^([0-5][0-9])$/;
const doubleDigitRegex = /^([0-9]{1,2}(:[0-5][0-9])?)$/;

export default function TimeElement(props: TimePickInputProps | TimePickInputTimerProps) {
  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    const { key } = e;

    // Clear input regardless of type
    if (key.toLowerCase() === "backspace" && props.type !== "meridiem") {
      props.onChange("--");
    }

    // Handle Meridiem input
    if (props.type === "meridiem") {
      if (key.toLowerCase() === "a") {
        props.onChange("AM");
      }
      if (key.toLowerCase() === "p") {
        props.onChange("PM");
      }
      return;
    }

    // Number check
    if (numberRegex.test(key)) {
      // Auto-change first input value
      if (props.value === undefined) {
        props.onChange(("0" + key).slice(-2));
        return;
      }

      const wantedNumber = props.value.concat(key);

      // Only change hour value if wanted number is 0-12
      if (props.type === "hour") {
        const hourReturn = String(parseInt(wantedNumber));

        if (
          hourRegex.test(hourReturn) ||
          ((props as TimePickInputTimerProps).disableMeridiemHour && doubleDigitRegex.test(hourReturn))
        ) {
          props.onChange(("0" + wantedNumber).slice(-2));
        } else {
          props.onChange(("0" + key).slice(-2));
        }
        return;
      }

      // Only change minute value if wanted number is 0-59
      if (props.type === "minute") {
        const minuteReturn = String(parseInt(wantedNumber));

        if (minuteRegex.test(minuteReturn)) {
          props.onChange(("0" + wantedNumber).slice(-2));
        } else {
          props.onChange(("0" + key).slice(-2));
        }
        return;
      }
    }
  }

  return (
    <input
      value={props.value ?? "--"}
      type="text"
      className="picker-input"
      onKeyDown={handleKeyDown}
      onChange={e => e.preventDefault()}
      disabled={props.disabled}
      aria-disabled={props.disabled}
    />
  );
}
