import { APIGet, APIPost, APIPut, APIDelete, StatusCode, IAPIResponse } from "api/protocols";
import { ICheckIns, INotification } from "../customer/teesheet";

const apiVersion = "2024-04";
const baseGuestUrl = "/" + apiVersion + "/guest/tee-sheet";

interface IGetNotifications {
  date: string;
  facility_id: number;
}

interface IGetCheckInsParams {
  facility_id: number;
  day_of_week_number: number;
}

export async function GetGuestNotifications(
  params: IGetNotifications,
  useGlobalLoader: boolean,
): Promise<IAPIResponse<Array<INotification>>> {
  return await APIGet(baseGuestUrl + "/notification", params, useGlobalLoader);
}

export async function GetGuestCheckIns(
  params: IGetCheckInsParams,
  useGlobalLoader: boolean,
): Promise<IAPIResponse<Array<ICheckIns>>> {
  return await APIGet(baseGuestUrl + "/check-in", params, useGlobalLoader);
}

export async function PutCancelWaitTime(
  params: { waitlist_id: string; customer_email: string },
  useGlobalLoader: boolean,
) {
  return await APIPut(baseGuestUrl + "/waitlist/cancel", params, useGlobalLoader);
}
