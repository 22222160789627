import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "hooks/redux";
import { useParams } from "react-router";

import { GetFacility, PutFacility } from "api/rpc/2024-04/masterAdmin/facility/facility";
import { GetCountry, GetProvince } from "api/rpc/2022-09/common/country/country";
import { IFacility } from "redux/reducers/models/facility";
import { ICountry } from "redux/reducers/models/customer";

import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Input from "components/form/input/Input";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Toggle from "components/form/toggle/Toggle";
import TextEditor from "components/textEditor/textEditor";
import { Select } from "components/select/";
import { showError, showSuccess } from "redux/actions/ui";

export default function Facility(props: any) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { facilityId, clientId }: any = useParams();
  const { Option } = Select;
  const [facilityState, setFacilityState] = useState<IFacility>(undefined);

  const [countryState, setCountryState] = useState<Array<ICountry>>([]);

  const holeOptions = [6, 9, 12, 18, 27, 36, 54, 72];

  useEffect(() => {
    void loadFacility();
  }, []);

  async function loadFacility() {
    const facilityRes = await GetFacility({ id: facilityId }, true);
    if (facilityRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading facility"));
      return;
    }

    const countryRes = await GetCountry({ provinces: true }, true);
    if (countryRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading countries"));
      return;
    }

    setFacilityState(facilityRes.data[0]);
    setCountryState(countryRes.data);
  }

  async function saveFacility() {
    const facilityRes = await PutFacility(
      {
        ...facilityState,
        facility_id: facilityState.id,
      },
      true,
    );

    if (facilityRes.status !== StatusCode.OK) {
      dispatch(showError("Error updating facility"));
      return;
    }

    setFacilityState(facilityRes.data);
    dispatch(showSuccess("Facility updated successfully"));
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setFacilityState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleDropDownChange(value: any, property: string) {
    setFacilityState(prev => ({ ...prev, [property]: value }));
  }

  function handleCountryDropDownChange(value: any) {
    const country = countryState?.find(country => country.id === value);
    setFacilityState(prev => ({
      ...prev,
      country_id: value,
      country_code: country.code,
      country_name: country.name,
    }));
  }

  function handleProvinceDropDownChange(value: any) {
    const province = countryState
      ?.find(country => country.id === facilityState.country_id)
      ?.provinces?.find(province => province.id === value);
    if (!province) {
      dispatch(showError("Error changing province"));
      return;
    }
    setFacilityState(prev => ({
      ...prev,
      province_id: value,
      province_code: province.code,
      province_name: province.name,
    }));
  }

  function handleToggleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, checked } = e.target;
    setFacilityState(prevState => ({ ...prevState, [id]: checked }));
  }

  const primaryAction = {
    content: "Save",
    action: saveFacility,
  };

  return (
    <Page
      title={facilityState?.long_name}
      narrow
      breadcrumbs={[{ prefix: true, label: "Back to facilities", url: `/admin/facility` }]}
      primaryAction={primaryAction}
    >
      <Card>
        <Card.Section title="Basic Information">
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  label={"Long Name"}
                  id="long_name"
                  value={facilityState?.long_name || ""}
                  onChange={handleInputChange}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Input
                  label={"Full Name"}
                  id="full_name"
                  value={facilityState?.full_name || ""}
                  onChange={handleInputChange}
                />
                <Input
                  label={"Short Name"}
                  id="short_name"
                  value={facilityState?.short_name || ""}
                  onChange={handleInputChange}
                />
                <Select
                  label="Holes"
                  onChange={(value: any) => handleDropDownChange(value, "holes")}
                  defaultValue={facilityState?.holes}
                >
                  {holeOptions.map((holeOption: number, index: number) => {
                    return (
                      <Option key={index} value={holeOption}>
                        {holeOption}
                      </Option>
                    );
                  })}
                </Select>
              </FormLayout.Group>
              <FormLayout.Group>
                <Input label={"Code"} id="code" value={facilityState?.code || ""} onChange={handleInputChange} />
                <Input label={"Prefix"} id="prefix" value={facilityState?.prefix || ""} onChange={handleInputChange} />
              </FormLayout.Group>
              <FormLayout.Group>
                <Toggle labelTop="Hidden" onChange={handleToggleChange} id="hidden" checked={facilityState?.hidden} />
                <Toggle labelTop="Closed" onChange={handleToggleChange} id="closed" checked={facilityState?.closed} />
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
        <Card.Section title="Location Information">
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  label={"Address Line 1"}
                  id="address_line_1"
                  value={facilityState?.address_line_1 || ""}
                  onChange={handleInputChange}
                />
                <Input
                  label={"Address Line 2"}
                  id="address_lne_2"
                  value={facilityState?.address_line_2 || ""}
                  onChange={handleInputChange}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Input label={"City"} id="city" value={facilityState?.city || ""} onChange={handleInputChange} />
                <Input
                  label={"Postal Code"}
                  id="postal"
                  value={facilityState?.postal || ""}
                  onChange={handleInputChange}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Select
                  label="Country"
                  onChange={(value: any) => handleCountryDropDownChange(value)}
                  defaultValue={facilityState?.country_id}
                >
                  {countryState?.map((country: ICountry, index: number) => {
                    return (
                      <Option key={index} value={country.id}>
                        {country.name}
                      </Option>
                    );
                  })}
                </Select>
                <Select
                  label="Province"
                  onChange={(value: any) => handleProvinceDropDownChange(value)}
                  defaultValue={facilityState?.province_id}
                >
                  <Option key={-1} value={-1}>
                    {"None"}
                  </Option>
                  {countryState
                    ?.find(country => country.id === facilityState?.country_id)
                    ?.provinces?.map((province, index: number) => {
                      return (
                        <Option key={index} value={province.id}>
                          {province.name}
                        </Option>
                      );
                    })}
                </Select>
                <Input
                  label={"Time Zone"}
                  id="timezone"
                  value={facilityState?.timezone || ""}
                  onChange={handleInputChange}
                />
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
        <Card.Section title="Contact Information">
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  label={"Phone Number"}
                  id="phone"
                  value={facilityState?.phone || ""}
                  onChange={handleInputChange}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Input label={"Email"} id="email" value={facilityState?.email || ""} onChange={handleInputChange} />
                <Input
                  label={"Customer Email"}
                  id="customer_email"
                  value={facilityState?.customer_email || ""}
                  onChange={handleInputChange}
                />
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
        <Card.Section title="Modules">
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Toggle
                  labelTop="Tee Sheet"
                  onChange={handleToggleChange}
                  id="module_tee_sheet"
                  checked={facilityState?.module_tee_sheet}
                />
                <Toggle
                  labelTop="Reservations"
                  onChange={handleToggleChange}
                  id="module_reservations"
                  checked={facilityState?.module_reservations}
                />
                <Toggle labelTop="Waitlist" onChange={handleToggleChange} id="module_waitlist" checked={false} />
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
      </Card>
    </Page>
  );
}
