import React, { useEffect, useMemo, useState } from "react";
import "./teeTimeWaitlist.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import TimePick from "components/timePick/TimePick";
import Radio from "components/radio";
import {
  IPostCustomerAlphaRes,
  IPostGuestAlphaRes,
  PostCustomerAlpha,
  PostGuestAlpha,
} from "api/rpc/2022-09/guest/bookingEngine";
import { StatusCode } from "api/protocols";
import { cloneDeep } from "lodash";
import { ButtonNew as Button } from "components/buttonNew";
import { useAppDispatch } from "hooks/redux";
import { showError } from "redux/actions/ui";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { MOBILE_WIDTH, TABLET_WIDTH } from "helpers/ScreenSizes";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import useOnclickOutside from "hooks/useOnclickOutside/useOnclickOutside";
import Spin from "components/spin/spin";
import Input from "components/form/input";
import validator from "validator";
import { convertTime } from "helpers/Helpers";
import { useHistory } from "react-router";
import Checkbox from "components/form/checkbox/Checkbox";

interface IProps {
  visible: boolean;
  courseLogo: string;
  courseName: string;
  loginUrl: string;
  courseAddressLine: string;
  facilityId: number;
  activeUser: boolean;
}

type TeeTimeWaitlistView = "closed" | "guest" | "entry" | "success";

interface ITeeTimeWaitlistValidatedField<T> {
  value: T;
  isDirty: boolean;
  isValid: boolean;
}

interface ITeeTimeWaitlistState {
  headingToolTipVisible: boolean;
  guestFirstName: ITeeTimeWaitlistValidatedField<string>;
  guestLastName: ITeeTimeWaitlistValidatedField<string>;
  guestEmail: ITeeTimeWaitlistValidatedField<string>;
  guestPhone: ITeeTimeWaitlistValidatedField<string>;
  guestViewComplete: boolean;
  multipleDates: ITeeTimeWaitlistValidatedField<Date[]>;
  startTime: string;
  endTime: string;
  allDay: boolean;
  holes: string;
  players: string;
  loadingSubmitWaitlistEntry: boolean;
}

const playerOptions = [
  { value: "1", title: "1" },
  { value: "2", title: "2" },
  { value: "3", title: "3" },
  { value: "4", title: "4" },
];

const holeOptions = [
  { value: "18", title: "18" },
  { value: "9", title: "9" },
];

export default function TeeTimeWaitlist(props: IProps) {
  const { visible, courseLogo, courseName, loginUrl, courseAddressLine, facilityId, activeUser } = props;

  const dispatch = useAppDispatch();

  const windowSize = useWindowSize();

  const history = useHistory();

  const isMobile = windowSize.width <= MOBILE_WIDTH;
  const isTablet = windowSize.width > MOBILE_WIDTH && windowSize.width <= TABLET_WIDTH;
  const isDesktop = windowSize.width > TABLET_WIDTH;

  const [view, setView] = useState<TeeTimeWaitlistView>("closed");

  const defaultTeeTimeWaitlistState: ITeeTimeWaitlistState = useMemo(
    () => ({
      headingToolTipVisible: false,
      guestFirstName: {
        value: "",
        isDirty: false,
        isValid: false,
      },
      guestLastName: {
        value: "",
        isDirty: false,
        isValid: false,
      },
      guestEmail: {
        value: "",
        isDirty: false,
        isValid: false,
      },
      guestPhone: {
        value: "",
        isDirty: false,
        isValid: false,
      },
      guestViewComplete: false,
      multipleDates: {
        value: [],
        isDirty: false,
        isValid: false,
      },
      startTime: "08:00",
      endTime: "12:00",
      allDay: false,
      holes: holeOptions[0].value,
      players: playerOptions[0].value,
      loadingSubmitWaitlistEntry: false,
    }),
    [],
  );

  const [state, setState] = useState<ITeeTimeWaitlistState>(cloneDeep(defaultTeeTimeWaitlistState));

  function handleEntryValidatedFieldChange<T>(id: string, value: T, isValid: boolean) {
    setState(prev => ({
      ...prev,
      [id]: {
        ...(prev[id as keyof ITeeTimeWaitlistState] as Record<string, any>),
        value,
        isValid,
        isDirty: true,
      },
    }));
  }

  function continueToEntry() {
    setState(prev => ({
      ...prev,
      guestFirstName: {
        ...prev.guestFirstName,
        isDirty: true,
      },
      guestLastName: {
        ...prev.guestLastName,
        isDirty: true,
      },
      guestEmail: {
        ...prev.guestEmail,
        isDirty: true,
      },
      guestPhone: {
        ...prev.guestPhone,
        isDirty: true,
      },
    }));

    if (
      !state.guestFirstName.isValid ||
      !state.guestLastName.isValid ||
      !state.guestEmail.isValid ||
      !state.guestPhone.isValid
    ) {
      return;
    }

    setState(prev => ({ ...prev, guestViewComplete: true }));
    setView("entry");
  }

  async function submitEntry() {
    setState(prev => ({
      ...prev,
      multipleDates: { ...prev.multipleDates, isDirty: true },
    }));

    if ((!state.allDay && state.startTime > state.endTime) || !state.multipleDates.isValid) {
      return;
    }

    if (!activeUser) {
      let postGuestWaitListEntry: IPostGuestAlphaRes = null;

      try {
        setState(prev => ({ ...prev, loadingSubmitWaitlistEntry: true }));

        postGuestWaitListEntry = await PostGuestAlpha(
          {
            first_name: state.guestFirstName.value,
            last_name: state.guestLastName.value,
            email: state.guestEmail.value,
            phone: state.guestPhone.value,
            start_dates: state.multipleDates.value.map(date =>
              date.toLocaleDateString("default", { year: "numeric", month: "numeric", day: "numeric" }),
            ),
            start_time: state.allDay ? "6:00" : state.startTime,
            end_time: state.allDay ? "18:00" : state.endTime,
            quantity: Number(state.players),
            holes: Number(state.holes),
            facility_id: facilityId,
          },
          false,
        );
      } finally {
        setState(prev => ({ ...prev, loadingSubmitWaitlistEntry: false }));
      }

      if (postGuestWaitListEntry?.status !== StatusCode.OK) {
        dispatch(showError("Failed to create guest waitlist entry"));
        return;
      }
    } else {
      let postCustomerWaitListEntry: IPostCustomerAlphaRes = null;

      try {
        setState(prev => ({ ...prev, loadingSubmitWaitlistEntry: true }));

        postCustomerWaitListEntry = await PostCustomerAlpha(
          {
            start_dates: state.multipleDates.value.map(date =>
              date.toLocaleDateString("default", { year: "numeric", month: "numeric", day: "numeric" }),
            ),
            start_time: state.allDay ? "6:00" : state.startTime,
            end_time: state.allDay ? "18:00" : state.endTime,
            quantity: Number(state.players),
            holes: Number(state.holes),
            facility_id: facilityId,
          },
          false,
        );
      } finally {
        setState(prev => ({ ...prev, loadingSubmitWaitlistEntry: false }));
      }

      if (postCustomerWaitListEntry?.status !== StatusCode.OK) {
        dispatch(showError("Failed to create customer waitlist entry"));
        return;
      }
    }

    setView("success");
  }

  function toggleTeeTimeWaitlist() {
    if (state.loadingSubmitWaitlistEntry) {
      return;
    } else if (view === "closed") {
      if (!activeUser && !state.guestViewComplete) {
        setView("guest");
      } else {
        setView("entry");
      }
    } else {
      if (view === "success") {
        setState(cloneDeep(defaultTeeTimeWaitlistState));
      }

      setView("closed");
    }
  }

  useEffect(() => {
    setState(prev => ({ ...prev, headingToolTipVisible: false }));
  }, [isMobile, isTablet, isDesktop]);

  const entryHeadingTooltipRef = useOnclickOutside(() => {
    if (isMobile || isTablet) {
      setState(prev => ({ ...prev, headingToolTipVisible: false }));
    }
  });

  function handleClickHeadingTooltip() {
    if (isMobile || isTablet) {
      setState(prev => ({ ...prev, headingToolTipVisible: !prev.headingToolTipVisible }));
    }
  }

  function handleOnMouseEnterHeadingTooltip() {
    if (isDesktop) {
      setState(prev => ({ ...prev, headingToolTipVisible: true }));
    }
  }

  function handleOnMouseLeaveHeadingTooltip() {
    if (isDesktop) {
      setState(prev => ({ ...prev, headingToolTipVisible: false }));
    }
  }

  function renderIntroductionSection() {
    return (
      <>
        <div className="waitlist-header">
          <p className="waitlist-header-prompt">Course fully booked?</p>
          <div className="waitlist-heading-container">
            <h1 className="waitlist-heading">Join the waitlist</h1>
            <OverlayTrigger
              placement="bottom"
              show={state.headingToolTipVisible}
              overlay={
                <Tooltip id="teetime-waitlist-heading-tooltip">
                  By joining our exclusive waitlist, you&apos;ll be at the front of the line when tee times open up
                </Tooltip>
              }
            >
              <div ref={entryHeadingTooltipRef} className="waitlist-heading-tooltip-icon-container">
                <FontAwesomeIcon
                  className="waitlist-heading-tooltip-icon"
                  icon={["far", "circle-question"]}
                  onMouseEnter={handleOnMouseEnterHeadingTooltip}
                  onMouseLeave={handleOnMouseLeaveHeadingTooltip}
                  onClick={handleClickHeadingTooltip}
                />
              </div>
            </OverlayTrigger>
          </div>
        </div>
        <div className="waitlist-course">
          <img className="waitlist-course-logo" src={courseLogo} />
          <div>
            <p className="waitlist-course-name">{courseName}</p>
            <p className="waitlist-course-address">{courseAddressLine}</p>
          </div>
        </div>
      </>
    );
  }

  function handleToggleAllDay() {
    setState(prev => ({ ...prev, allDay: !prev.allDay }));
  }

  return (
    <>
      {visible && (
        <div className="teetime-waitlist-container">
          {isMobile && view === "closed" && (
            <div className="waitlist-fab" onClick={toggleTeeTimeWaitlist}>
              <FontAwesomeIcon className="waitlist-fab-calendar-icon" icon={["fal", "calendar"]} />
              <FontAwesomeIcon className="waitlist-fab-clock-icon" icon={["fas", "clock"]} />
            </div>
          )}
          {!isMobile && (
            <div className="waitlist-tab" onClick={toggleTeeTimeWaitlist}>
              <div className="waitlist-tab-heading-container">
                <div className="waitlist-tab-heading-icon">
                  <FontAwesomeIcon className="waitlist-tab-heading-icon-calendar" icon={["fal", "calendar"]} />
                  <FontAwesomeIcon className="waitlist-tab-heading-icon-clock" icon={["fas", "clock"]} />
                </div>
                <h1 className="waitlist-tab-heading">Waitlist</h1>
              </div>
              {view === "closed" ? (
                <FontAwesomeIcon className="waitlist-tab-toggle-icon" icon={["fas", "chevron-up"]} />
              ) : (
                <FontAwesomeIcon className="waitlist-tab-toggle-icon" icon={["fas", "chevron-down"]} />
              )}
            </div>
          )}
          {view !== "closed" && (
            <div className="waitlist">
              {view === "guest" && (
                <div className="waitlist-guest">
                  {isMobile && (
                    <FontAwesomeIcon
                      className="waitlist-close-icon"
                      icon={["fas", "xmark"]}
                      onClick={toggleTeeTimeWaitlist}
                    />
                  )}
                  <div>
                    {renderIntroductionSection()}
                    <div className="waitlist-form">
                      <Input
                        label="First Name"
                        value={state.guestFirstName.value}
                        onChange={e =>
                          handleEntryValidatedFieldChange("guestFirstName", e.target.value, e.target.value?.length > 0)
                        }
                        error={state.guestFirstName.isDirty && !state.guestFirstName.isValid}
                      />
                      <Input
                        label="Last Name"
                        value={state.guestLastName.value}
                        onChange={e =>
                          handleEntryValidatedFieldChange("guestLastName", e.target.value, e.target.value?.length > 0)
                        }
                        error={state.guestLastName.isDirty && !state.guestLastName.isValid}
                      />
                      <Input
                        label="Email"
                        value={state.guestEmail.value}
                        onChange={e =>
                          handleEntryValidatedFieldChange(
                            "guestEmail",
                            e.target.value,
                            validator.isEmail(e.target.value ?? ""),
                          )
                        }
                        error={state.guestEmail.isDirty && !state.guestEmail.isValid}
                      />
                      <Input
                        label="Phone Number"
                        value={state.guestPhone.value}
                        onChange={e =>
                          handleEntryValidatedFieldChange(
                            "guestPhone",
                            e.target.value,
                            validator.isMobilePhone(e.target.value ?? "", "en-CA"),
                          )
                        }
                        error={state.guestPhone.isDirty && !state.guestPhone.isValid}
                      />
                    </div>
                  </div>
                  <div>
                    <Button className="waitlist-guest-continue" type="secondary" onClick={continueToEntry}>
                      Continue
                    </Button>
                    <div className="waitlist-guest-login-container">
                      <div className="waitlist-guest-login" onClick={() => history.push(loginUrl)}>
                        Login with email <FontAwesomeIcon icon={["fas", "arrow-right"]} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {view === "entry" && !state.loadingSubmitWaitlistEntry && (
                <div className="waitlist-entry">
                  <div>
                    {isMobile && (
                      <FontAwesomeIcon
                        className="waitlist-close-icon"
                        icon={["fas", "xmark"]}
                        onClick={toggleTeeTimeWaitlist}
                      />
                    )}
                    {renderIntroductionSection()}
                    {!activeUser && (
                      <div
                        className="waitlist-entry-go-back"
                        onClick={() => {
                          setState(prev => ({ ...prev, guestViewComplete: false }));
                          setView("guest");
                        }}
                      >
                        <FontAwesomeIcon icon={["fas", "arrow-left"]} />
                        <div>Go back</div>
                      </div>
                    )}
                    <div className="waitlist-form">
                      <DatePickerInput
                        label="Date"
                        months={1}
                        size="small"
                        position="center"
                        multipleDates={state.multipleDates.value}
                        setMultipleDates={multipleDates =>
                          handleEntryValidatedFieldChange("multipleDates", multipleDates, multipleDates.length > 0)
                        }
                        error={state.multipleDates.isDirty && !state.multipleDates.isValid}
                        centeredInputText
                        disablePastDays
                      />

                      <div className="waitlist-form-time-picks">
                        <TimePick
                          label="Start time"
                          value={state.startTime}
                          onChange={time => setState(prev => ({ ...prev, startTime: time }))}
                          status={state.startTime > state.endTime ? "error" : undefined}
                          disabled={state.allDay}
                        />

                        <TimePick
                          label="End time"
                          value={state.endTime}
                          onChange={time => setState(prev => ({ ...prev, endTime: time }))}
                          status={state.startTime > state.endTime ? "error" : undefined}
                          disabled={state.allDay}
                        />
                      </div>

                      <Checkbox size="medium" label="All day" checked={state.allDay} onChange={handleToggleAllDay} />

                      <div>
                        <h2 className="waitlist-form-heading">Holes</h2>
                        <Radio.Group
                          name="waitlist_holes"
                          onChange={(holes: string) => setState(prev => ({ ...prev, holes }))}
                          value={state.holes}
                        >
                          {holeOptions.map(hole => {
                            return (
                              <Radio.Button size="small" key={`waitlist_hole_${hole.value}`} value={hole.value}>
                                {hole.title}
                              </Radio.Button>
                            );
                          })}
                        </Radio.Group>
                      </div>

                      <div>
                        <h2 className="waitlist-form-heading">Number of Players</h2>
                        <Radio.Group
                          name="waitlist_players"
                          onChange={(players: string) => setState(prev => ({ ...prev, players }))}
                          value={state.players}
                        >
                          {playerOptions.map(player => {
                            return (
                              <Radio.Button size="small" key={`waitlist_players_${player.value}`} value={player.value}>
                                {player.title}
                              </Radio.Button>
                            );
                          })}
                        </Radio.Group>
                      </div>
                    </div>
                  </div>
                  <Button className="waitlist-entry-submit" type="primary" onClick={submitEntry}>
                    Submit
                  </Button>
                </div>
              )}
              {view === "entry" && state.loadingSubmitWaitlistEntry && (
                <div className="waitlist-entry-loading">
                  <Spin className="waitlist-entry-loading-spin" />
                  <div className="waitlist-entry-loading-message">Loading...</div>
                </div>
              )}
              {view === "success" && (
                <div className="waitlist-success-view">
                  <div className="waitlist-success-outer-circle">
                    <div className="waitlist-success-inner-circle">
                      <FontAwesomeIcon className="waitlist-success-check-icon" icon={["far", "check-circle"]} />
                    </div>
                  </div>
                  <h1 className="waitlist-success-header">You have been successfully added to the waitlist!</h1>
                  <div className="waitlist-success-entries-container">
                    <h2 className="waitlist-success-entries-header">Times</h2>
                    <div className="waitlist-success-entries">
                      {state.multipleDates.value?.map((date, index) => {
                        return (
                          <div key={index}>
                            <div className="waitlist-success-entry-primary-content">
                              {date.toLocaleDateString("en-CA", {
                                weekday: "long",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              })}
                            </div>
                            <div className="waitlist-success-entry-secondary-content">
                              {state.allDay
                                ? "All day, "
                                : `${convertTime(state.startTime)} - ${convertTime(state.endTime)}, `}
                              {state.players} player{Number(state.players) > 1 ? "s" : ""}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {isMobile ? (
                    <div className="waitlist-success-go-back" onClick={toggleTeeTimeWaitlist}>
                      <FontAwesomeIcon className="mr-2" icon={["far", "arrow-left"]} />
                      Back to tee sheet
                    </div>
                  ) : (
                    <Button className="waitlist-success-close" onClick={toggleTeeTimeWaitlist}>
                      Close
                    </Button>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}
