import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Dictionary, cloneDeep, groupBy } from "lodash";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import {
  DeleteTournamentRegistrationInput,
  ITournamentRegistrationInput,
  PostTournamentRegistrationInput,
  PutTournamentRegistrationInput,
  PutTournamentRegistrationInputPositions,
  TPostTournamentRegistrationInput,
  TPutTournamentRegistrationInput,
  TPutTournamentRegistrationInputPosition,
  TTournamentRegistrationInputTypes,
} from "api/rpc/2024-04/facilityAdmin/tournament/registration/inputs";
import {
  GetTournamentRegistrationInput,
  TournamentRegistrationInputTypes,
} from "api/rpc/facilityAdmin/tournament/tournamentRegistrationInput";

import { showError, showSuccess } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";

import useModal from "hooks/modals/useModal";
import useUnsavedChangesContainer from "hooks/useUnsavedChangesContainer/useUnsavedChangesContainer";
import { generateInputRowsWithSequentialPositions } from "hooks/editInputDragAndDrop/useDropEditInputRow/useDropEditInputRow";
import { capitalize } from "helpers/Helpers";

import FormLayout from "components/form/FormLayout";
import { Select } from "components/select/index";
import Spin from "components/spin/spin";
import Card from "components/card/Card";
import { ButtonNew as Button } from "components/buttonNew";
import { ISelectedEditInput } from "components/draggableEditInput/DraggableEditInput";
import Sheet from "components/sheet/Sheet";
import Input from "components/form/input/Input";
import Popup from "components/popup/Popup";
import Portal from "elements/Portal";
import { TournamentRegistrationContext } from "../../TournamentRegistrationContext";
import TournamentRegistrationEditInputLayout from "../../dragAndDropElements/TournamentRegistrationEditInputLayout";

import "./registrationFeeInputs.scss";
import Page from "components/page/Page";

interface IRegistrationFeeInputs {
  tournamentId: number;
}

export type TTournamentRegistrationInputRows = Dictionary<ITournamentRegistrationInput[]>;

export interface ITournamentRegistrationNewInputModal extends Omit<TPostTournamentRegistrationInput, "type"> {
  isOpen: boolean;
  type: TTournamentRegistrationInputTypes | "none";
}

export default function RegistrationFeeInputs(props: IRegistrationFeeInputs) {
  const dispatch = useAppDispatch();

  const [selectedInput, setSelectedInput] = useState<ISelectedEditInput>({
    id: null,
    deletePopupOpen: false,
    editModalOpen: false,
  });

  const { state, updateModal } = useModal({
    tournament_id: props.tournamentId,
    label: "",
    help_text: "",
    required: true,
    type: "none" as TTournamentRegistrationInputTypes | "none",
  });

  const [tournamentRegistrationContainer, tournamentRegistrationContainerActions] =
    useUnsavedChangesContainer<TTournamentRegistrationInputRows>();

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadTournamentRegistrationInputs(Number(props.tournamentId), source.token);
    return () => source.cancel();
  }, [props.tournamentId]);

  async function loadTournamentRegistrationInputs(tournament_id: number, token?: CancelToken) {
    const res = await GetTournamentRegistrationInput({ tournament_id }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      tournamentRegistrationContainerActions.set(undefined);
      return;
    }

    const inputRows = groupBy(res.data, input => input.row);

    for (const row in inputRows) {
      inputRows[row].sort((prev, next) => prev.column - next.column);
    }

    tournamentRegistrationContainerActions.set(inputRows);
  }

  async function updateTournamentRegistrationInputRowPositions() {
    const updatedTournamentRegistrationInputRows: TTournamentRegistrationInputRows =
      generateInputRowsWithSequentialPositions(tournamentRegistrationContainer.updatedState);

    const tournamentRegistrationInputPositions: TPutTournamentRegistrationInputPosition[] = [];

    Object.keys(updatedTournamentRegistrationInputRows).forEach(row => {
      updatedTournamentRegistrationInputRows[row].forEach(input => {
        tournamentRegistrationInputPositions.push({ id: input.id, row: input.row, column: input.column });
      });
    });

    const putTournamentRegistrationInputPositions = await PutTournamentRegistrationInputPositions({
      inputs: tournamentRegistrationInputPositions,
    });

    if (putTournamentRegistrationInputPositions.status !== StatusCode.OK) {
      dispatch(showError("Error updating tournament registration input positions")); // TODO: Translation
      return undefined;
    }

    tournamentRegistrationContainerActions.set(updatedTournamentRegistrationInputRows);
    return updatedTournamentRegistrationInputRows;
  }

  async function handleAddNewInput() {
    let inputRows: TTournamentRegistrationInputRows = undefined;
    if (tournamentRegistrationContainer.changesExist) {
      inputRows = await updateTournamentRegistrationInputRowPositions();
      if (!inputRows) {
        return;
      }
    }

    // only assign right operand if inputRows = null | undefined
    inputRows ??= cloneDeep(tournamentRegistrationContainer.updatedState);

    // destructure & remove isOpen prop
    const parsedModal: ITournamentRegistrationNewInputModal = JSON.parse(JSON.stringify(state));
    const params = (({ isOpen, ...rest }) => rest)(parsedModal);

    const res = await PostTournamentRegistrationInput(
      {
        tournament_id: Number(props.tournamentId),
        ...params,
        type: params.type === "none" ? null : params.type,
        required: params.type === "text" ? false : params.required,
      },
      true,
    );

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error creating tournament registration input")); // TODO: Translation
      return;
    }

    // close modal
    dispatch(showSuccess("Successfully added a new input."));
    updateModal({
      isOpen: false,
      label: "",
      help_text: "",
      type: "none",
    });

    // set updated inputs
    const row = String(res.data.row);
    const updatedInputRowsWithInput: TTournamentRegistrationInputRows = {
      ...inputRows,
      [row]: [res.data],
    };
    tournamentRegistrationContainerActions.set(updatedInputRowsWithInput);
  }

  async function handleRemoveInput(id: number) {
    let updatedInputRows: TTournamentRegistrationInputRows = undefined;

    if (tournamentRegistrationContainer.changesExist) {
      updatedInputRows = await updateTournamentRegistrationInputRowPositions();
      if (!updatedInputRows) {
        return;
      }
    }

    // only assign right operand if inputRows = null | undefined
    updatedInputRows ??= cloneDeep(tournamentRegistrationContainer.updatedState);

    const res = await DeleteTournamentRegistrationInput({ id }, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError("There was an error removing the input.")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Successfully removed the input.")); // TODO: Translation
    setSelectedInput(prev => ({ ...prev, id: null, deletePopupOpen: false }));

    // update container context
    let rowDeletingFrom = "";

    Object.keys(updatedInputRows).forEach(row => {
      if (updatedInputRows[row]?.some(input => input.id === id)) {
        rowDeletingFrom = row;
      }
    });

    if (rowDeletingFrom.length === 0) {
      return;
    }

    const { [rowDeletingFrom]: originalRow, ...rest } = updatedInputRows;

    const updatedRow = originalRow
      .filter(input => input.id !== id)
      .map((input, index) => ({ ...input, column: index + 1 }));

    const updatedRowsWithDelete: TTournamentRegistrationInputRows = {
      ...rest,
      ...(updatedRow.length > 0 ? { [rowDeletingFrom]: updatedRow } : {}),
    };

    if (updatedRow.length > 0) {
      tournamentRegistrationContainerActions.set(updatedRowsWithDelete);
      return;
    }

    const updatedRowsWithShift: TTournamentRegistrationInputRows = {};
    const numericRowDeletingFrom = Number(rowDeletingFrom);

    Object.keys(updatedRowsWithDelete).forEach(row => {
      const numericRow = Number(row);

      if (numericRow > numericRowDeletingFrom) {
        const shiftedRow = numericRow - 1;

        updatedRowsWithShift[String(shiftedRow)] = updatedRowsWithDelete[row].map(input => {
          return {
            ...input,
            row: shiftedRow,
          };
        });
      } else {
        updatedRowsWithShift[row] = updatedRowsWithDelete[row];
      }
    });

    tournamentRegistrationContainerActions.set(updatedRowsWithShift);
  }

  async function handleUpdateInput(id: number, label: string, helpText: string, value: string[], isRequired: boolean) {
    let updatedTournamentRegistrationInputRows: TTournamentRegistrationInputRows = undefined;

    if (tournamentRegistrationContainer.changesExist) {
      updatedTournamentRegistrationInputRows = await updateTournamentRegistrationInputRowPositions();

      if (!updatedTournamentRegistrationInputRows) {
        return;
      }
    }

    updatedTournamentRegistrationInputRows ??= tournamentRegistrationContainer.updatedState;

    const params: TPutTournamentRegistrationInput = {
      id: id,
      label: label,
      help_text: helpText,
      values: value,
      required: isRequired,
    };

    const res = await PutTournamentRegistrationInput(params, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error updating input.")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Input successfully saved.")); // TODO: Translation

    const updatedTournamentRegistrationInput = cloneDeep(res.data);
    const updatedInputRowsWithChanges: TTournamentRegistrationInputRows = cloneDeep(
      updatedTournamentRegistrationInputRows,
    );

    for (const row in updatedInputRowsWithChanges) {
      const updatedRow = updatedInputRowsWithChanges[row];

      for (let i = 0; i < updatedRow.length; i++) {
        if (updatedRow[i].id === updatedTournamentRegistrationInput.id) {
          updatedRow[i] = updatedTournamentRegistrationInput;
        }
      }
    }

    tournamentRegistrationContainerActions.set(updatedInputRowsWithChanges);
    setSelectedInput(prev => ({ ...prev, id: null, editModalOpen: false }));
  }

  return (
    <Page
      full
      notificationBarProps={{
        isVisible: tournamentRegistrationContainer.changesExist,
        onAction: updateTournamentRegistrationInputRowPositions,
        onCancel: tournamentRegistrationContainerActions.rollback,
      }}
    >
      <Card
        title="Inputs" // TODO: Translation
        titleActions={[{ content: "Add Input", /* TODO: Translation */ action: () => updateModal({ isOpen: true }) }]}
        style={{ marginTop: "8px" }}
      >
        <Card.Section>
          <DndProvider backend={HTML5Backend}>
            <TournamentRegistrationContext.Provider
              value={{
                selectedInput: selectedInput,
                setSelectedInput: setSelectedInput,
                inputRowsContainer: tournamentRegistrationContainer,
                inputRowsContainerActions: tournamentRegistrationContainerActions,
                handleUpdateInput: handleUpdateInput,
                closeInputEditModal: () => setSelectedInput(prev => ({ ...prev, id: null, editModalOpen: false })),
              }}
            >
              {!tournamentRegistrationContainer.updatedState ? (
                <div style={{ height: "106px", overflow: "hidden", transform: "scale(0.5)" }}>
                  <Spin />
                </div>
              ) : (
                <Card>
                  <Card.Section>
                    <TournamentRegistrationEditInputLayout openModal={() => updateModal({ isOpen: true })} />
                  </Card.Section>
                </Card>
              )}
            </TournamentRegistrationContext.Provider>
          </DndProvider>
        </Card.Section>
      </Card>

      {/* <div className="tournament-registration-add-input-container">
        <Button type="text" onClick={() => updateModal({ isOpen: true })}>
          <FontAwesomeIcon className="tournament-registration-add-input-icon" size="sm" icon={["far", "plus"]} />
          <span>Add Input</span>
        </Button>
      </div> */}

      {/* New Input Modal */}
      <Portal isMounted={state.isOpen}>
        <Sheet
          open={state.isOpen}
          onOk={handleAddNewInput}
          okText="Create" // TODO: Translation
          onCancel={() => updateModal({ isOpen: false, label: "", help_text: "", type: "none" })}
          okDisabled={state.type === "none" || !state.label}
          size="small"
          title="New Input" // TODO: Translation
          overflow
        >
          <FormLayout>
            <FormLayout.Group>
              <Select
                label="Input Type" // TODO: Translation
                onChange={(value: TournamentRegistrationInputTypes | "none") => updateModal({ type: value })}
                defaultValue="none"
              >
                {(["none", "input", "select", "checkbox", "text"] as TournamentRegistrationInputTypes[]).map(
                  (value, index) => {
                    return (
                      <Select.Option key={index} value={value} name={capitalize(value)}>
                        {capitalize(value)}
                      </Select.Option>
                    );
                  },
                )}
              </Select>
            </FormLayout.Group>
            <FormLayout.Group>
              <Input
                label="Label" // TODO: Translation
                value={state.label}
                onChange={e => updateModal({ label: e.target.value })}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <Input
                label={state?.type === "text" ? "Subtext" : "Help Text"} // TODO: Translation
                value={state.help_text}
                onChange={e => updateModal({ help_text: e.target.value })}
              />
            </FormLayout.Group>
          </FormLayout>
        </Sheet>
      </Portal>

      {/* Remove Input Popup */}
      <Portal isMounted={selectedInput.deletePopupOpen}>
        <Popup
          open={selectedInput.deletePopupOpen}
          type="warning"
          title="Delete Input?" // TODO: Translation
          description="Are you sure you want to remove this input?" // TODO: Translation
          onCancel={() => setSelectedInput(prev => ({ ...prev, id: null, deletePopupOpen: false }))}
          onOk={() => handleRemoveInput(selectedInput.id)}
        />
      </Portal>
    </Page>
  );
}
