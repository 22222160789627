import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { isEqualWith, isNull } from "lodash";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { GetCustomer, UpdateCustomerProfile } from "api/rpc/2022-09/customer/customer";

import { showError, showSuccess } from "redux/actions/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Input from "components/form/input";
import FormLayout from "components/form/FormLayout";

import "./AccountOverview.scss";

interface IAccountState {
  first_name: string;
  last_name: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  province: string;
  postalCode: string;
  phone: string;
  email: string;
  member_number: string;
  customer_type: string;
}

export default function AccountOverview() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { bookingEngineStore } = useAppSelector(store => store);

  const [accountState, setAccountState] = useState<IAccountState>({
    first_name: "",
    last_name: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    province: "",
    postalCode: "",
    phone: "",
    email: "",
    member_number: "",
    customer_type: "",
  });
  const [accountStateBeforeChanges, setAccountStateBeforeChanges] = useState<IAccountState>(undefined);
  const [customerLoaded, setCustomerLoaded] = useState(false);
  const [customerDisplayInfo, setCustomerDisplayInfo] = useState({
    name: "",
    phone: "",
    email: "",
  });

  useEffect(() => {
    void loadCustomer();
  }, []);

  async function loadCustomer() {
    const customerRes = await GetCustomer({ id: bookingEngineStore.activeCustomerId }, true);
    if (customerRes.status !== StatusCode.OK) {
      dispatch(showError(customerRes.message));
      return;
    }
    const name = `${customerRes.data?.first_name as string} ${customerRes.data?.last_name as string}`;
    ReactDOM.unstable_batchedUpdates(() => {
      setCustomerDisplayInfo(prevState => ({
        ...prevState,
        name,
        email: customerRes.data?.email,
        phone: customerRes.data?.phone,
      }));
      setCustomerLoaded(true);
      setAccountState(prevState => ({
        ...prevState,
        email: customerRes.data?.email,
        phone: customerRes.data?.phone,
        first_name: customerRes.data?.first_name,
        last_name: customerRes.data?.last_name,
        member_number: customerRes.data?.member_number,
        customer_type: customerRes.data?.customer_type,
      }));
    });
  }

  async function saveCustomer() {
    const params = {
      id: bookingEngineStore.activeCustomerId,
      first_name: accountState.first_name,
      last_name: accountState.last_name,
      phone: accountState.phone,
      email: accountState.email,
    };
    const customerRes = await UpdateCustomerProfile(params, true);

    if (customerRes.status !== StatusCode.OK) {
      dispatch(showError(customerRes.message));
      return;
    }
    dispatch(showSuccess(t("guest.customer.customer_nav_menu.account_overview.001")));
    const name = `${accountState.first_name} ${accountState.last_name}`;
    ReactDOM.unstable_batchedUpdates(() => {
      setCustomerDisplayInfo(prevState => ({
        ...prevState,
        name,
        email: accountState.email,
        phone: accountState.phone,
      }));
      setAccountStateBeforeChanges(accountState);
    });
  }

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setAccountState(prevState => ({ ...prevState, [id]: value }));
  }

  function unsavedChangesExist() {
    if (accountStateBeforeChanges === undefined) {
      if (customerLoaded) {
        setAccountStateBeforeChanges(accountState);
      }
      return false;
    }

    return !isEqualWith(accountStateBeforeChanges, accountState, (originalValue, newValue) => {
      if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
        return true;
      }
    });
  }

  const primaryAction = {
    content: t("guest.customer.customer_nav_menu.account_overview.002"),
    action: saveCustomer,
  };

  return (
    <>
      <Page
        full
        notificationBarProps={{
          isVisible: unsavedChangesExist(),
          onAction: saveCustomer,
          onCancel: () => setAccountState(accountStateBeforeChanges),
        }}
        title={t("guest.customer.customer_nav_menu.account_overview.004")}
        primaryAction={primaryAction}
      >
        <p className="font-semibold text-xl">{customerDisplayInfo.name || "---- ----"}</p>
        <div className="mt-4 mb-4">
          <div className="account-overview-customer-display-info">
            {customerDisplayInfo.phone && <p className="text-primary-grey text-sm">{customerDisplayInfo.phone}</p>}
            {customerDisplayInfo.email && <p className="text-primary-grey text-sm">{customerDisplayInfo.email}</p>}
          </div>
        </div>

        <Card>
          <Card.Section title={t("guest.customer.customer_nav_menu.account_overview.005")}>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  id="first_name"
                  label={t("guest.customer.customer_nav_menu.account_overview.006")}
                  value={accountState.first_name || ""}
                  onChange={onInputChange}
                />
                <Input
                  id="last_name"
                  label={t("guest.customer.customer_nav_menu.account_overview.007")}
                  value={accountState.last_name || ""}
                  onChange={onInputChange}
                />
              </FormLayout.Group>
            </FormLayout>

            <FormLayout>
              <FormLayout.Group>
                <Input
                  id="phone"
                  label={t("guest.customer.customer_nav_menu.account_overview.008")}
                  value={accountState.phone || ""}
                  onChange={onInputChange}
                />
                <Input
                  id="email"
                  label={t("guest.customer.customer_nav_menu.account_overview.009")}
                  value={accountState.email || ""}
                  onChange={onInputChange}
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>
      </Page>
    </>
  );
}
