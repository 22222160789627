import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { GetDivisions } from "api/rpc/facility";
import { GetSkinsSheet, PostSkinsSheet } from "api/rpc/2022-09/facilityAdmin/league/scoring/skins";

import { IDivision } from "redux/reducers/models/facility";

import Page from "components/page/Page";
import Sheet from "components/sheet/Sheet";
import Card from "components/card/Card";
import Input from "components/form/input/Input";
import { Select } from "components/select/";

interface ILeagueSkinsState {
  league: any;
  league_id: number;
  title: string;
  scoresheet_id: number;
  division_front_id: number;
  division_back_id: number;
  score: string;
  newSkinsVisible: boolean;
  skinsSheets: any;
}

export default function LeagueSkins() {
  const { Option } = Select;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { leagueId, scoresheetId } = useParams<{ leagueId: string; scoresheetId: string }>();

  const [leagueSkinsState, setLeagueSkinsState] = useState<ILeagueSkinsState>({
    league: null,
    league_id: Number(leagueId),
    title: "",
    scoresheet_id: Number(scoresheetId),
    division_front_id: -1,
    division_back_id: null,
    score: "",
    newSkinsVisible: false,
    skinsSheets: null,
    // divisions: [],
  });

  const [divisions, setDivisions] = useState<IDivision[]>([]);

  useEffect(() => {
    void loadDivisions();
    void loadSkinsSheets();
  }, []);

  async function loadSkinsSheets() {
    const skinsSheetsRes = await GetSkinsSheet(
      {
        scoresheet_id: leagueSkinsState.scoresheet_id,
      },
      true,
    );

    if (skinsSheetsRes.status !== StatusCode.OK) {
      return;
    }

    setLeagueSkinsState(prevState => ({ ...prevState, skinsSheets: skinsSheetsRes.data }));
  }

  async function loadDivisions() {
    const divisionsRes = await GetDivisions(null, true);

    if (divisionsRes.status !== StatusCode.OK) {
      return;
    }

    setDivisions(divisionsRes.data);
  }

  function handleNewSkinSheet() {
    setLeagueSkinsState(prevState => ({ ...prevState, newSkinsVisible: true }));
  }

  function handleNewSkinCancel() {
    setLeagueSkinsState(prevState => ({ ...prevState, newSkinsVisible: false }));
  }

  function handleDropDownChange(value: any, property: string) {
    console.log(value, property);
    setLeagueSkinsState(prevState => ({ ...prevState, [property]: value }));
  }

  async function newSkinsSheet() {
    const newSkinsRes = await PostSkinsSheet(
      {
        scoresheet_id: leagueSkinsState.scoresheet_id,
        title: leagueSkinsState.title,
        score: leagueSkinsState.score,
        division_front_id: leagueSkinsState.division_front_id,
        division_back_id: leagueSkinsState.division_back_id,
      },
      true,
    );

    if (newSkinsRes.status !== StatusCode.OK) {
      return;
    }

    await loadSkinsSheets();

    setLeagueSkinsState(prevState => ({ ...prevState, newSkinsVisible: false }));
  }

  const primaryAction = {
    content: "New Skins Sheet", // TODO: Translation
    action: handleNewSkinSheet,
  };

  return (
    <Page
      title={"Skins Sheets"} // TODO: Translation
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: "Back to results", // TODO: Translation
          url: `/admin/league/${leagueId}/rounds/scoresheet/${scoresheetId}/leaderboard`,
        },
      ]}
    >
      <Card>
        <Card.Section table="true"></Card.Section>
        <table className="ui-table">
          <thead>
            <tr>
              <th>{"Title"}</th> {/* TODO: Translation */}
              <th>{"Division"}</th> {/* TODO: Translation */}
              <th>{"Score"}</th> {/* TODO: Translation */}
            </tr>
          </thead>
          <tbody>
            {leagueSkinsState.skinsSheets ? (
              <>
                {leagueSkinsState.skinsSheets.map((sheet: any, index: number) => {
                  const divisionTitle = divisions.find(division => division.id === sheet.division_front_id);
                  return (
                    <tr
                      key={index}
                      onClick={() =>
                        history.push(
                          "/admin/league/" +
                            String(leagueId) +
                            "/scoring/scoresheet/" +
                            String(scoresheetId) +
                            "/skins/" +
                            String(sheet.id),
                        )
                      }
                    >
                      <td>{sheet.title}</td>
                      <td>{divisionTitle === undefined ? null : divisionTitle.title}</td>
                      <td>{sheet.score}</td>
                    </tr>
                  );
                })}
              </>
            ) : null}
          </tbody>
        </table>
      </Card>
      <Sheet
        size="small"
        open={leagueSkinsState.newSkinsVisible}
        okText="Create" // TODO: Translation
        title="New Skins Sheet" // TODO: Translation
        onCancel={handleNewSkinCancel}
        onOk={newSkinsSheet}
        closable
        stacked
        overflow
      >
        <Input
          label={"Title"} // TODO: Translation
          value={leagueSkinsState.title ?? ""}
          onChange={(value: any) => setLeagueSkinsState(prevState => ({ ...prevState, title: value.target.value }))}
          placeholder={"Title"} // TODO: Translation
        />
        <div style={{ marginTop: "10px" }} />
        <Select
          label={"Score"}
          /* TODO: Translation */ onChange={(value: any) => handleDropDownChange(value, "score")}
          defaultValue={"gross"}
        >
          <Option key={0} value={"gross"} name={"gross"}>
            {"Gross"}
          </Option>
          <Option key={1} value={"net"} name={"net"}>
            {"Net"}
          </Option>
        </Select>
        <div style={{ marginTop: "10px" }} />
        <Select
          label={"Front Division"}
          /* TODO: Translation */ onChange={(value: any) => handleDropDownChange(value, "division_front_id")}
        >
          {divisions.map(division => {
            return (
              <Option key={division.id} value={division.id} name={division.title}>
                {division.title}
              </Option>
            );
          })}
        </Select>
        <div style={{ marginTop: "10px" }} />
        <Select
          label={"Back Division"}
          /* TODO: Translation */ onChange={(value: any) => handleDropDownChange(value, "division_back_id")}
        >
          {divisions.map(division => {
            return (
              <Option key={division.id} value={division.id} name={division.title}>
                {division.title}
              </Option>
            );
          })}
        </Select>
      </Sheet>
    </Page>
  );
}
