import * as React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, AnyAction } from "redux";
import thunk, { ThunkMiddleware } from "redux-thunk";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { fad } from "@fortawesome/pro-duotone-svg-icons";
import { fat } from "@fortawesome/pro-thin-svg-icons";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import reducer from "redux/reducers/index";

import App from "app";
import Loader from "components/loader/loader";

import "public/scss/_variables.scss";
import "public/scss/_global.scss";

import "./i18n";
import { HelmetProvider } from "react-helmet-async";

// library.add(fab, fal, far, fas, fad);
library.add(fal, far, fas, fad, fat);

// export const store = createStore(reducer, applyMiddleware(thunk));
export const store = createStore(reducer, applyMiddleware(thunk as ThunkMiddleware<any, AnyAction>));

export const stripePk = process.env.REACT_APP_STRIPE_PUBLISH_KEY;
const stripePromise = loadStripe(stripePk);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const Root = () => {
  return (
    <Provider store={store}>
      <Elements stripe={stripePromise}>
        <Loader />
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </Elements>
    </Provider>
  );
};

render(<Root />, document.getElementById("root"));
