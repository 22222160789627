import { createContext } from "react";
import { DirectionType } from "../config-provider";

export type MenuTheme = "light" | "dark";

export interface IMenuContextProps {
  inlineCollapsed: boolean;
  antdMenuTheme?: MenuTheme;
  direction?: DirectionType;
}

const MenuContext = createContext<IMenuContextProps>({
  inlineCollapsed: false,
});

export default MenuContext;
