import React from "react";
import RegisterContext from "./RegisterContext";
import RegisterNew from "./RegisterNew";

export default function RegisterContextProvider() {
  return (
    <RegisterContext>
      <RegisterNew />
    </RegisterContext>
  );
}
