import { APIGet, APIPost, IAPIResponse } from "api/protocols";
import { ITournament, ITournamentParticipant, ITournamentRegistrationFee } from "redux/reducers/models/tournament";
import { ITournamentRegistrationInput } from "../facilityAdmin/tournament/tournamentRegistrationInput";

const apiVersion = "2024-04";
const adminType = "guest";
const baseTournamentUrl = "/" + apiVersion + "/" + adminType + "/tournament";

interface IGetTournament {
  facility_id: number;
  handle?: string;
  id?: number;
}

interface IPostTournamentParticipant {
  facility_id: number;
  order_id: number;
  tournament_id: number;
  customer_id: number;
}

interface IPostTournamentParticipantResponse {
  data: ITournamentParticipant;
  status: number;
}

interface IGetTournamentRegistrationInput {
  tournament_id: number;
  facility_id: number;
}

export interface IPostTournamentRegistrationInputData {
  registration_input_id: number;
  value: string | boolean;
  participant_id: number;
}

interface IPostTournamentRegistrationInputs {
  tournament_id: number;
  facility_id: number;
  registration_input_data: IPostTournamentRegistrationInputData[];
}

export async function GetTournament(
  params: IGetTournament,
  useGlobalLoader: boolean,
): Promise<IAPIResponse<ITournament[]>> {
  return await APIGet(baseTournamentUrl, params, useGlobalLoader);
}

export async function PostTournamentParticipant(
  params: IPostTournamentParticipant,
  useGlobalLoader: boolean,
): Promise<IPostTournamentParticipantResponse> {
  return await APIPost(baseTournamentUrl + "/participant", params, useGlobalLoader);
}

export async function GetTournamentRegistrationInput(
  params: IGetTournamentRegistrationInput,
  useGlobalLoader: boolean,
): Promise<IAPIResponse<ITournamentRegistrationInput[]>> {
  return await APIGet(baseTournamentUrl + "/registration-input", params, useGlobalLoader);
}

export async function PostTournamentRegistrationInputs(
  params: IPostTournamentRegistrationInputs,
  useGlobalLoader: boolean,
) {
  return await APIPost(baseTournamentUrl + "/registration-input/response", params, useGlobalLoader);
}
