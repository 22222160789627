import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { displayCurrency, uppercase } from "helpers/Helpers";

import Card from "components/card/Card";
import Page from "components/page/Page";
import { StatusCode } from "api/protocols";

import { useTranslation } from "react-i18next";
import Sheet from "components/sheet/Sheet";
import { Select } from "components/select/index";
import { ITournament } from "redux/reducers/models/tournament";
import { ILeague, ILeagueParticipant } from "redux/reducers/models/league";
import { useAppDispatch } from "hooks/redux";
import { showError } from "redux/actions/ui";
import {
  DistributeCreditBook,
  GetCreditBook,
  GetCreditBookTransactions,
} from "api/rpc/2022-09/facilityAdmin/client/creditBook";
import "./prizeAccounts.scss";
import { GetParticipant } from "api/rpc/2022-09/facilityAdmin/league/participant";
import { GetTournamentParticipant } from "api/rpc/2022-09/facilityAdmin/tournament/participant";
import Input from "components/form/input/Input";
import Popup from "components/popup/Popup";
import { GetLeague } from "api/rpc/2022-09/facilityAdmin/league/league";
import { GetTournament } from "api/rpc/facilityAdmin/tournament/tournament";
import { GetCustomer } from "api/rpc/2022-09/facilityAdmin/customer/customer";
import { tr } from "date-fns/locale";
import { capitalize } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonNew as Button } from "components/buttonNew";

interface IFilterState {
  tournamentSearch: string;
  leagueSearch: string;
  distributeToPlayersVisible: boolean;
  tournaments: Array<ITournament>;
  selectedTournament: ITournament;
  tournamentSearching: boolean;
  leagues: Array<ILeague>;
  leagueSearching: boolean;
  selectedLeague: ILeague;
  confirmDistributeFundsVisible: boolean;
  prizeAccountEvent: ILeague | ITournament;
  prizeAccountTransactions: any[];
}

interface IPrizeAccount {
  id: number;
  balance?: number;
  client_id?: number;
  currency?: string;
  customer_id?: number;
  facility_id?: number;
  league_id?: number;
  tournament_id?: number;
  type?: "prize_account" | "credit_book";
  title: string;
}

interface IPlayerState {
  allParticipantsInEvent: any[];
  selectedPlayersToDistribute: any[];
  masterParticipantList: any[];
  playerSearch: string;
}

export default function PrizeAccount() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [selected, setSelected] = useState(0);
  const { Option } = Select;
  const dispatch = useAppDispatch();
  const { id, leagueId, tournamentId }: any = useParams();

  const [prizeAccount, setPrizeAccount] = useState<IPrizeAccount>(undefined);

  const [filterState, setFilterState] = useState<IFilterState>({
    tournamentSearch: "",
    leagueSearch: "",
    distributeToPlayersVisible: false,
    tournaments: [],
    selectedTournament: null,
    tournamentSearching: false,
    leagues: [],
    leagueSearching: false,
    selectedLeague: null,
    confirmDistributeFundsVisible: false,
    prizeAccountEvent: null,
    prizeAccountTransactions: [],
  });

  const [playerState, setPlayerState] = useState<IPlayerState>({
    allParticipantsInEvent: [],
    selectedPlayersToDistribute: [],
    masterParticipantList: [],
    playerSearch: "",
  });

  useEffect(() => {
    void getPrizeAccount();
    void loadPrizeAccountTransactions();
  }, []);

  async function getPrizeAccount() {
    const getPrizeAccountsRes = await GetCreditBook({ id: id }, true);

    let prizeAccount: any;

    if (getPrizeAccountsRes.status === StatusCode.OK) {
      prizeAccount = getPrizeAccountsRes.data[0];
    } else {
      dispatch(showError(getPrizeAccountsRes.message));
      return;
    }

    if (prizeAccount.tournament_id) {
      //load tournament & players
      void loadTournamentParticipants(prizeAccount.tournament_id);
      void loadTournaments(prizeAccount.tournament_id);
    }

    if (prizeAccount.league_id) {
      //load league & players
      void loadLeagueParticipants(prizeAccount.league_id);
      void loadLeagues(prizeAccount.league_id);
    }

    setPrizeAccount({
      id: prizeAccount?.id,
      title: prizeAccount?.title,
      balance: prizeAccount?.balance,
      currency: prizeAccount?.currency,
      customer_id: prizeAccount?.customer_id,
      facility_id: prizeAccount?.facility_id,
      league_id: prizeAccount?.league_id,
      tournament_id: prizeAccount?.tournament_id,
      client_id: prizeAccount?.client_id,
      type: prizeAccount?.type,
    });

    setFilterState(prevState => ({
      ...prevState,
      title: prizeAccount.title,
    }));
  }

  const primaryAction = {
    content: t("secure.facility.settings.prize_accounts.prize_accounts_view.001"),
    action: handleOpenDistributeFunds,
  };

  const secondaryActions = [
    {
      content: t("secure.facility.settings.prize_accounts.prize_accounts_view.002"),
      action: () =>
        handleNavigateToEditPage(
          leagueId
            ? "/admin/league/" + String(leagueId) + "/prizeAccounts/edit/" + String(prizeAccount.id)
            : tournamentId
            ? "/admin/tournament/" + String(tournamentId) + "/prizeAccounts/edit/" + String(prizeAccount.id)
            : "/admin/settings/prizeAccounts/edit/" + String(prizeAccount.id),
        ),
    },
  ];

  function handleOpenDistributeFunds() {
    setFilterState(prevState => ({ ...prevState, distributeToPlayersVisible: true }));
  }

  function handleNavigateToEditPage(path: string) {
    history.push(path);
  }

  function handleCloseDistribute() {
    setFilterState(prevState => ({ ...prevState, distributeToPlayersVisible: false }));
    setPlayerState(prevState => ({
      ...prevState,
      allParticipantsInEvent: playerState.masterParticipantList,
      selectedPlayersToDistribute: [],
    }));
  }

  async function loadLeagueParticipants(league_id: number) {
    const params = {
      league_id: league_id,
    };

    const leaguePlayersRes = await GetParticipant(params, true);

    const players: any = leaguePlayersRes.data;

    for (let i = 0; i < players.length; i++) {
      players[i].distribute_value = "";
    }

    //Sort player list
    leaguePlayersRes.data.sort((a, b) => {
      const textA = a.customer.full_name.toUpperCase();
      const textB = b.customer.full_name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    if (leaguePlayersRes.status === StatusCode.OK) {
      setPlayerState(prevState => ({
        ...prevState,
        allParticipantsInEvent: leaguePlayersRes.data,
        masterParticipantList: leaguePlayersRes.data,
      }));
    }
  }

  async function loadTournamentParticipants(tournament_id: number) {
    const params = {
      tournament_id: tournament_id,
    };

    const tournamentPlayersRes = await GetTournamentParticipant(params, true);
    console.log(tournamentPlayersRes);

    const players: any = tournamentPlayersRes.data;

    for (let i = 0; i < players.length; i++) {
      players[i].distribute_value = "";
    }

    //Sort player list
    tournamentPlayersRes.data.sort((a, b) => {
      const textA = a.customer.full_name.toUpperCase();
      const textB = b.customer.full_name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    if (tournamentPlayersRes.status === StatusCode.OK) {
      setPlayerState(prevState => ({
        ...prevState,
        allParticipantsInEvent: tournamentPlayersRes.data,
        masterParticipantList: tournamentPlayersRes.data,
      }));
    }
  }

  function handleSelectParticipantToDistribute(selectedParticipant: any) {
    const updatedParticipantList = playerState.allParticipantsInEvent.filter(
      player => player?.user_id !== selectedParticipant.user_id,
    );

    const distributeParticipantList = playerState.selectedPlayersToDistribute;

    distributeParticipantList.push(selectedParticipant);

    setPlayerState(prevState => ({
      ...prevState,
      allParticipantsInEvent: updatedParticipantList,
      selectedPlayersToDistribute: distributeParticipantList,
    }));
  }

  function handleSelectParticipantToRemove(selectedParticipant: any) {
    selectedParticipant.distribute_value = "";

    const distributeParticipantList = playerState.selectedPlayersToDistribute.filter(
      player => player?.user_id !== selectedParticipant.user_id,
    );

    const updatedParticipantList = playerState.allParticipantsInEvent;

    updatedParticipantList.push(selectedParticipant);

    setPlayerState(prevState => ({
      ...prevState,
      allParticipantsInEvent: updatedParticipantList,
      selectedPlayersToDistribute: distributeParticipantList,
    }));
  }

  function handleConfirmDistributeFunds() {
    for (let i = 0; i < playerState.selectedPlayersToDistribute.length; i++) {
      if (playerState.selectedPlayersToDistribute[i].distribute_value === "") {
        // const errorMessage =
        //   String(playerState.selectedPlayersToDistribute[i]?.customer?.full_name) + " is missing a distribute value";
        dispatch(showError(t("secure.facility.settings.prize_accounts.prize_accounts_view.003")));
        return;
      }
    }

    setFilterState(prevState => ({ ...prevState, confirmDistributeFundsVisible: true }));
  }

  async function handleDistributeFunds() {
    const players = playerState.selectedPlayersToDistribute;
    const distributionInput: any = [];

    for (let i = 0; i < players.length; i++) {
      const playerInformation = {
        customer_id: Number(players[i].user_id),
        amount: Number(players[i].distribute_value),
      };

      distributionInput.push(playerInformation);
    }

    const params = {
      distribution_input: distributionInput,
      credit_book_id: id,
    };

    const distributeRes = await DistributeCreditBook(params, true);

    if (distributeRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.prize_accounts.prize_accounts_view.004")));
    }

    setPlayerState(prevState => ({
      ...prevState,
      allParticipantsInEvent: playerState.masterParticipantList,
      selectedPlayersToDistribute: [],
    }));
    setFilterState(prevState => ({
      ...prevState,
      distributeToPlayersVisible: false,
      confirmDistributeFundsVisible: false,
    }));

    void loadPrizeAccountTransactions();
    void getPrizeAccount();
  }

  function handleParticipantDistributeValueChange(event: any, participant: any) {
    const value = event.target.value;

    setPlayerState(prevState => ({
      ...prevState,
      selectedPlayersToDistribute: prevState.selectedPlayersToDistribute.map((player: ILeagueParticipant) =>
        player.user_id === participant.user_id ? { ...player, distribute_value: value } : player,
      ),
    }));
  }

  async function loadTournaments(tournament_id: number) {
    const tournamentRes = await GetTournament({ id: tournament_id }, true);

    if (tournamentRes.status === StatusCode.OK) {
      const tournaments = tournamentRes.data;
      setFilterState(prevState => ({ ...prevState, prizeAccountEvent: tournaments[0] }));
    } else {
      dispatch(showError(tournamentRes.message));
    }
  }

  async function loadLeagues(league_id: number) {
    const leagueRes = await GetLeague({ id: league_id }, true);

    if (leagueRes.status === StatusCode.OK) {
      const leagues = leagueRes.data;
      setFilterState(prevState => ({ ...prevState, prizeAccountEvent: leagues[0] }));
    } else {
      dispatch(showError(leagueRes.message));
    }
  }

  async function loadPrizeAccountTransactions() {
    const params = {
      credit_book_id: id,
    };

    const transactionRes = await GetCreditBookTransactions(params, true);

    if (transactionRes.status === StatusCode.OK) {
      setFilterState(prevState => ({ ...prevState, prizeAccountTransactions: transactionRes.data }));
    } else {
      dispatch(showError(transactionRes.message));
    }
  }

  function getKindLabel(kind: string): string {
    switch (kind) {
      case "credit":
      case "credit_transfer":
        return t("secure.facility.settings.prize_accounts.prize_accounts_view.014");
      case "debit":
      case "debit_transfer":
        return t("secure.facility.settings.prize_accounts.prize_accounts_view.013");
      case "refund":
        return t("secure.facility.settings.prize_accounts.prize_accounts_view.023");
      case "voided":
        return t("secure.facility.settings.prize_accounts.prize_accounts_view.024");
      default:
        return "";
    }
  }

  function navigateToOrder(orderId: number | string) {
    window.open(`/admin/order/${orderId}`);
  }

  return (
    <>
      <Page
        title={prizeAccount?.title}
        primaryAction={primaryAction}
        secondaryActions={secondaryActions}
        narrow
        breadcrumbs={[
          {
            prefix: true,
            label: t("secure.facility.settings.prize_accounts.prize_accounts_view.005"),
            url: leagueId
              ? "/admin/league/" + String(leagueId) + "/prizeAccounts"
              : tournamentId
              ? "/admin/tournament/" + String(tournamentId) + "/prizeAccounts"
              : "/admin/settings/prizeAccounts",
          },
        ]}
      >
        <>
          <Card>
            <Card.Section>
              <div>
                <p>
                  {" "}
                  {t("secure.facility.settings.prize_accounts.prize_accounts_view.006")}{" "}
                  {displayCurrency("cad", prizeAccount?.balance)}
                </p>

                <p>
                  {prizeAccount?.tournament_id
                    ? t("secure.facility.settings.prize_accounts.prize_accounts_view.007")
                    : prizeAccount?.league_id
                    ? t("secure.facility.settings.prize_accounts.prize_accounts_view.008")
                    : ""}{" "}
                  {filterState?.prizeAccountEvent?.name}{" "}
                </p>
              </div>
            </Card.Section>

            <Card.Section table="true">
              <table className="ui-table">
                <thead>
                  <tr>
                    <th>{t("secure.facility.settings.prize_accounts.prize_accounts_view.009")}</th>
                    <th>{t("secure.facility.settings.prize_accounts.prize_accounts_view.010")}</th>
                    <th>{t("secure.facility.settings.prize_accounts.prize_accounts_view.011")}</th>
                    <th>{t("secure.facility.settings.prize_accounts.prize_accounts_view.012")}</th>
                    <th>{"Order" /* TODO: Translation */}</th>
                  </tr>
                </thead>

                <tbody>
                  {filterState.prizeAccountTransactions.length > 0 ? (
                    filterState.prizeAccountTransactions.map((transaction: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td style={{ width: "35%" }}>
                            <p>{transaction.processed_at_local}</p>
                          </td>
                          <td>
                            <p>{getKindLabel(transaction.kind)}</p>
                          </td>
                          <td>
                            <p>{String(transaction.currency).toUpperCase()}</p>
                          </td>

                          <td>
                            <p>{displayCurrency("cad", transaction.amount)}</p>
                          </td>

                          <td>
                            {transaction.order_id ? (
                              <Button
                                className="text-primary-500"
                                type="link-color"
                                onClick={() => navigateToOrder(transaction.order_id)}
                              >
                                {transaction.order_name}
                              </Button>
                            ) : null}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td>{t("secure.facility.settings.prize_accounts.prize_accounts_view.015")}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Card.Section>
          </Card>
        </>
      </Page>

      <Sheet
        title={t("secure.facility.settings.prize_accounts.prize_accounts_view.016")}
        open={filterState.distributeToPlayersVisible}
        size="medium"
        closable
        onCancel={handleCloseDistribute}
        onOk={handleConfirmDistributeFunds}
        okText={t("secure.facility.settings.prize_accounts.prize_accounts_view.021")}
      >
        <div className="distribute-players-container">
          <div className="distribute-players-container-left-side">
            <div className="distribute-players-container-title">
              {t("secure.facility.settings.prize_accounts.prize_accounts_view.017")}
            </div>

            <div className="mt-2 mb-3">
              <Input
                placeholder="Search for player..."
                id="player_search"
                value={playerState.playerSearch}
                onChange={e => setPlayerState(prevState => ({ ...prevState, playerSearch: e.target.value }))}
                labelHidden
              />
            </div>

            <div>
              {playerState.allParticipantsInEvent
                ?.filter(player =>
                  String(player.customer.full_name).toLowerCase().includes(playerState.playerSearch.toLowerCase()),
                )
                .map((participant: any, index: number) => {
                  return (
                    <div
                      className="distribute-players-container-player-name"
                      key={index}
                      onClick={() => handleSelectParticipantToDistribute(participant)}
                    >
                      <p>{participant?.customer?.full_name}</p>
                      <p style={{ fontSize: "12px" }}>
                        {participant?.customer?.email}
                        {participant?.customer?.phone && participant?.customer?.email ? " - " : ""}
                        {participant?.customer?.phone ? participant.customer.phone : ""}
                      </p>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="distribute-players-container-right-side">
            <div>
              <div className="distribute-players-container-title">
                {t("secure.facility.settings.prize_accounts.prize_accounts_view.018")}
              </div>

              {playerState.selectedPlayersToDistribute?.map((participant: any, index: number) => {
                return (
                  <div className="flex justify-between items-center gap-1 mb-2" key={index}>
                    <div className="w-3/4">
                      <p>
                        {participant?.customer?.full_name}

                        <span style={{ fontSize: "12px" }}>
                          {participant?.customer?.phone ? " - " : ""}
                          {participant?.customer?.phone}
                        </span>
                      </p>
                      <p style={{ fontSize: "12px" }}>{participant?.customer?.email}</p>
                    </div>

                    <div style={{ width: "130px" }}>
                      <Input
                        prefix="$"
                        placeholder="0.00"
                        id="distribute_value"
                        value={participant?.distribute_value}
                        onChange={(event: any) => handleParticipantDistributeValueChange(event, participant)}
                        labelHidden
                      />
                    </div>

                    <div style={{ textAlign: "center", width: "30px" }}>
                      <button onClick={() => handleSelectParticipantToRemove(participant)}>
                        {" "}
                        <FontAwesomeIcon icon={["far", "xmark"]} />
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Sheet>

      <Popup
        open={filterState.confirmDistributeFundsVisible}
        type="warning"
        title={t("secure.facility.settings.prize_accounts.prize_accounts_view.019")}
        description={t("secure.facility.settings.prize_accounts.prize_accounts_view.020")}
        onOk={handleDistributeFunds}
        okText={t("secure.facility.settings.prize_accounts.prize_accounts_view.021")}
        onCancel={() => setFilterState(prevState => ({ ...prevState, confirmDistributeFundsVisible: false }))}
      />
    </>
  );
}
