import React, { useEffect, useState } from "react";
import Page from "components/page/Page";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import { Select } from "components/select";
import {
  DeleteTournamentScoresheet,
  GetTournamentScoresheet,
  PutTournamentScoresheet,
  TPutTournamentScoresheet,
} from "api/rpc/2024-04/facilityAdmin/tournament/scoring/scoresheet";
import axios, { CancelToken } from "axios";
import { StatusCode } from "api/protocols";
import { useAppDispatch } from "hooks/redux";
import { showError, showSuccess } from "redux/actions/ui";
import useModal from "hooks/modals/useModal";
import Popup from "components/popup/Popup";
import Portal from "elements/Portal";
import { GetTeeDecks } from "api/rpc/2024-04/facilityAdmin/facility/teedeck";
import { GetDivision } from "api/rpc/2024-04/facilityAdmin/facility/division";
import { IDivision, ITeeDeck } from "redux/reducers/models/facility";
import { handleChangeEventInput } from "helpers/Helpers";

export default function TournamentScoresheetEdit() {
  const history = useHistory();
  const { t } = useTranslation();
  const { tournamentId, scoresheetId } = useParams<{ tournamentId: string; scoresheetId: string }>();

  const dispatch = useAppDispatch();

  const [pageTitle, setPageTitle] = useState("Edit Tournament Scoresheet");
  const [tournamentScoresheet, setTournamentScoresheet] = useState<TPutTournamentScoresheet>({
    id: Number(scoresheetId),
    name: "",
    date_played: "",
    division_front_id: null,
    division_back_id: null,
    tee_deck_id: null,
    holes: null,
  });

  const [teedecks, setTeedecks] = useState<ITeeDeck[]>([]);
  const [divisions, setDivisions] = useState<IDivision[]>([]);

  const [filterState, setFilterState] = useState({
    date_played: new Date(),
  });

  const { state: deleteModal, updateModal, closeModal } = useModal();

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadTeeDecks(source.token);
    void loadTournamentDivisions(source.token);
    return () => source.cancel();
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadTournamentScoresheet(scoresheetId, source.token);
    return () => source.cancel();
  }, [scoresheetId]);

  async function loadTournamentScoresheet(scoresheetId: string | number, token?: CancelToken) {
    const res = await GetTournamentScoresheet({ id: scoresheetId }, true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading scoresheet."));
      return;
    }

    const tournament = res.data[0];

    setTournamentScoresheet(prev => ({
      ...prev,
      name: tournament.name,
      date_played: tournament.date_played,
      tee_deck_id: tournament.tee_deck_id,
      division_back_id: tournament.division_back_id,
      division_front_id: tournament.division_front_id,
      holes: tournament.holes,
    }));
  }

  async function loadTeeDecks(token?: CancelToken) {
    const res = await GetTeeDecks(null, token ? false : true, token);
    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      return;
    }

    setTeedecks(res.data);
  }

  async function loadTournamentDivisions(token?: CancelToken) {
    const res = await GetDivision(null, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      return;
    }

    setDivisions(res.data);
  }

  async function updateScoresheet() {
    const res = await PutTournamentScoresheet(tournamentScoresheet, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error updating scoresheet.")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Scoresheet saved successfully.")); // TODO: Translation
  }

  async function deleteScoresheet(deleteId: string | number) {
    const res = await DeleteTournamentScoresheet({ id: deleteId }, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error deleting scoresheet.")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Scoresheet deleted successfully.")); // TODO: Translation
    history.push("/admin/tournament/" + tournamentId + "/scoring");
  }

  function handleDateChange(selectedDate: Date) {
    const dateString = new Date(selectedDate).toISOString().slice(0, 10);

    setFilterState(prev => ({ ...prev, date_played: selectedDate }));
    setTournamentScoresheet(prev => ({ ...prev, date_played: dateString }));
  }

  const primaryAction = {
    content: "Save", // TODO: Translation
    action: () => updateScoresheet(),
  };

  const secondaryActions = [
    {
      content: "Delete", // TODO: Translation
      action: () => updateModal({ isOpen: true }),
    },
  ];

  return (
    <Page
      title={pageTitle}
      narrow
      primaryAction={primaryAction}
      secondaryActions={secondaryActions}
      breadcrumbs={[
        {
          prefix: true,
          label: "Back To Scoresheet", // TODO: Translation
          url: "/admin/tournament/" + String(tournamentId) + "/scoring/scoresheet/" + String(scoresheetId),
        },
      ]}
    >
      <Card>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Input
                value={tournamentScoresheet.name}
                label="Name" // TODO: Translation
                id="name"
                onChange={e => handleChangeEventInput(e, tournamentScoresheet, setTournamentScoresheet)}
                placeholder="Name"
              />
              <DatePickerInput
                label="Date" // TODO: Translation
                months={1}
                showQuickOptions
                position={"right"}
                startingDate={filterState.date_played}
                setStartingDate={handleDateChange}
              />
            </FormLayout.Group>

            <FormLayout.Group>
              <Select
                label={t("secure.facility.league.league_scoresheet_new.005")}
                onChange={(value: number) => setTournamentScoresheet(prev => ({ ...prev, division_front_id: value }))}
                defaultValue={tournamentScoresheet.division_front_id}
              >
                {divisions
                  // .filter(division => division.course_id === filterState.course_id)
                  .map(division => {
                    return (
                      <Select.Option key={division.id} value={division.id} name={division.title}>
                        {division.title}
                      </Select.Option>
                    );
                  })}
              </Select>

              <Select
                label={t("secure.facility.league.league_scoresheet_new.006")}
                onChange={(value: number) => setTournamentScoresheet(prev => ({ ...prev, division_back_id: value }))}
                defaultValue={tournamentScoresheet.division_back_id}
                disabled={tournamentScoresheet?.holes === 9}
              >
                {divisions
                  // .filter(division => division.course_id === filterState.course_id)
                  .map(division => {
                    return (
                      <Select.Option key={division.id} value={division.id} name={division.title}>
                        {division.title}
                      </Select.Option>
                    );
                  })}
              </Select>
              <Select
                label={t("secure.facility.league.league_scoresheet_new.007")}
                onChange={(value: number) => setTournamentScoresheet(prev => ({ ...prev, tee_deck_id: value }))}
                defaultValue={tournamentScoresheet.tee_deck_id}
              >
                {teedecks.map(teeDeck => {
                  return (
                    <Select.Option key={teeDeck.id} value={teeDeck.id} name={teeDeck.title}>
                      {teeDeck.title}
                    </Select.Option>
                  );
                })}
              </Select>
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>

      {/* REMOVE POPUP */}
      <Portal isMounted={deleteModal.isOpen}>
        <Popup
          open={deleteModal.isOpen}
          type="warning"
          title="Delete Scoresheet?" // TODO: Translation
          description="Are you sure you want to delete this scoresheet?" // TODO: Translation
          onCancel={() => closeModal()}
          onOk={() => deleteScoresheet(scoresheetId)}
          okText="Delete" // TODO: Translation
        />
      </Portal>
    </Page>
  );
}
