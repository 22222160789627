import { APIPut } from "api/protocols";

const apiVersion = "2024-04";
const adminType = "client-admin";
const transactionUrl = "/" + apiVersion + "/" + adminType + "/order/transaction";

interface IPutTransaction {
  id: number;
  processed_at: string;
  note: string;
}

export function PutTransaction(params: IPutTransaction, useGlobalLoader: boolean) {
  return APIPut(transactionUrl, params, useGlobalLoader);
}
