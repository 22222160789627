import React, { ChangeEvent, useState } from "react";
import { ButtonNew } from "components/buttonNew";
import Input from "components/form/input/Input";
import { PostPasswordResetCode } from "api/rpc/2022-09/passwordReset";
import { StatusCode } from "api/protocols";
import { useAppDispatch } from "hooks/redux";
import { showError } from "redux/actions/ui";
import Popup from "components/popup/Popup";
import { useParams } from "react-router";
import { useTranslation, Trans } from "react-i18next";

interface IRecoveryState {
  email: string;
  successPopupVisibility: boolean;
}

interface IParams {
  clientShortName: string;
}

export default function ForgotPassword(props: { facilityId?: number }) {
  const dispatch = useAppDispatch();
  const [recoveryState, setRecoveryState] = useState<IRecoveryState>({
    email: "",
    successPopupVisibility: false,
  });
  const { clientShortName } = useParams<IParams>();
  const { t, i18n } = useTranslation();
  async function sendPasswordRecovery() {
    const resetRes = await PostPasswordResetCode(
      { email: recoveryState.email, client_short_name: clientShortName },
      true,
    );

    if (resetRes.status === StatusCode.OK) {
      setRecoveryState(prevState => ({ ...prevState, successPopupVisibility: true }));
    } else if (typeof resetRes?.data === "string") {
      dispatch(showError(resetRes.data));
      return;
    } else {
      dispatch(showError(t("elements.forgot_password.001")));
    }
  }

  function handleEnterKeydown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      void sendPasswordRecovery();
    }
  }

  return (
    <>
      <div className="reservations-login">
        <div className="reservations-login-header-container">
          <div className="reservations-login-header-container-centered">
            <div className="reservations-login-header-container-inner">
              <img src="../../../public/images/Tee-On.png" alt="Tee-On Logo" />
            </div>
          </div>
        </div>

        <div className="reservations-login-container">
          <div className="reservations-login-container-inner">
            <div className="reservations-login-container-group">
              <div className="reservations-login-text-group">
                <p className="sign-in-text">{t("elements.forgot_password.002")}</p>
                <br />
                <p className="sign-in-info">{t("elements.forgot_password.003")}</p>
              </div>

              <div className="reservations-login-input-group">
                <label className="reservations-login-input-label" htmlFor="email">
                  {t("elements.forgot_password.004")}
                </label>
                <Input
                  value={recoveryState.email}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setRecoveryState(prev => ({ ...prev, [event.target.id]: event.target.value }))
                  }
                  type="email"
                  labelHidden
                  onKeyDown={handleEnterKeydown}
                  id="email"
                />
              </div>
            </div>
            <br />
            <br />
            <div>
              <ButtonNew
                type="primary"
                block
                onClick={sendPasswordRecovery}
                className="reservations-register-button"
                disabled={recoveryState.email.length < 4}
              >
                {t("elements.forgot_password.005")}
              </ButtonNew>
            </div>
          </div>
        </div>
      </div>

      <Popup
        open={recoveryState.successPopupVisibility}
        type="success"
        title={t("elements.forgot_password.006")}
        description={t("elements.forgot_password.007")}
        onOk={() => window.close()}
        okText={t("elements.forgot_password.008")}
      />
    </>
  );
}
