import React, { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import {
  DeleteFormInput,
  FormInput,
  FormInputTypes,
  GetFormInput,
  PostFormInput,
  PostFormInputParams,
  PutFormInput,
  PutFormInputParams,
  PutFormInputPosition,
  PutFormInputPositions,
} from "api/rpc/2024-04/masterAdmin/client/formInput";
import { GetFacilityForm, PutFacilityForm, PutFacilityFormParams } from "api/rpc/2024-04/masterAdmin/client/form";

import { showError, showSuccess } from "redux/actions/ui";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { capitalize } from "helpers/Helpers";

import Page from "components/page/Page";
import Sheet from "components/sheet/Sheet";
import FormLayout from "components/form/FormLayout";
import { Select } from "components/select/index";
import Input from "components/form/input/Input";
import Popup from "components/popup/Popup";
import Card from "components/card/Card";
import Spin from "components/spin/spin";
import Portal from "elements/Portal";
import "./facilityFormEdit.scss";
import { Dictionary, cloneDeep, groupBy } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FormEditContext, FormEditHandleUpdateInput } from "./FormEditContext";
import { ButtonNew as Button } from "components/buttonNew";
import FormEditInputLayout from "./dragAndDropElements/FormEditInputLayout";
import { ISelectedEditInput } from "components/draggableEditInput/DraggableEditInput";
import useUnsavedChangesContainer from "hooks/useUnsavedChangesContainer/useUnsavedChangesContainer";
import { generateInputRowsWithSequentialPositions } from "hooks/editInputDragAndDrop/useDropEditInputRow/useDropEditInputRow";

export type FormInputRows = Dictionary<FormInput[]>;

import { TFunction, useTranslation } from "react-i18next";
export interface INewInputModal extends Omit<PostFormInputParams, "type"> {
  open: boolean;
  type: FormInputTypes | "none";
}

interface IEditFormModal {
  open: boolean;
  formTitle: string;
}

export default function FacilityFormEdit() {
  const { id } = useParams<{ id: string }>();
  const { Option } = Select;
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { masterFacilityStore } = useAppSelector(store => store);

  const [formTitle, setFormTitle] = useState<string>("");
  const [formInputRowsContainer, formInputRowsContainerActions] = useUnsavedChangesContainer<FormInputRows>();

  const [newInputModal, setNewInputModal] = useState<INewInputModal>({
    form_id: Number(id),
    open: false,
    label: "",
    help_text: "",
    required: true,
    type: "none",
  });

  const [editFormModal, setEditFormModal] = useState<IEditFormModal>({
    open: false,
    formTitle: "",
  });

  const [selectedFormInput, setSelectedFormInput] = useState<ISelectedEditInput>({
    id: null,
    deletePopupOpen: false,
    editModalOpen: false,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadForm(source.token);
    void loadFormInputs(source.token);

    return () => {
      source.cancel();
    };
  }, [id, masterFacilityStore.facility]);

  /** GET and set form data */
  async function loadForm(token?: CancelToken) {
    const res = await GetFacilityForm(
      { id: Number(id), client_id: masterFacilityStore.facility?.client_id },
      token ? false : true,
      token,
    );

    if (res.status !== StatusCode.OK) {
      return;
    }

    setFormTitle(res.data[0]?.title);
  }

  /** GET and set form inputs */
  async function loadFormInputs(token?: CancelToken) {
    const res = await GetFormInput(
      {
        form_id: Number(id),
        client_id: masterFacilityStore.facility?.client_id,
      },
      false,
      token,
    );

    if (res.status !== StatusCode.OK) {
      formInputRowsContainerActions.set(undefined);
      return;
    }

    const formInputs = res.data as FormInput[];

    formInputs.sort((prev, next) => prev.row - next.row);

    const formInputRows: FormInputRows = groupBy(formInputs, formInput => formInput.row);

    for (const row in formInputRows) {
      formInputRows[row].sort((prev, next) => prev.column - next.column);
    }

    formInputRowsContainerActions.set(formInputRows);
  }

  async function handleAddNewInput() {
    let updatedFormInputRows: FormInputRows = undefined;

    if (formInputRowsContainer.changesExist) {
      updatedFormInputRows = await updateFormInputRowPositions();

      if (!updatedFormInputRows) {
        return;
      }
    }

    updatedFormInputRows ??= cloneDeep(formInputRowsContainer.updatedState);

    // Destructure and remove open prop
    const deepClone: INewInputModal = JSON.parse(JSON.stringify(newInputModal));
    const params = (({ open, ...rest }) => rest)(deepClone);

    const res = await PostFormInput(
      {
        form_id: Number(id),
        ...params,
        type: params.type === "none" ? null : params.type,
        required: params.type === "text" ? false : params.required,
      },
      true,
    );

    if (res.status !== StatusCode.OK) {
      dispatch(showError(`${t("secure.facility.settings.forms.facility_form_edit.001")} ${res.data as string}`));
      return;
    }

    dispatch(showSuccess(t("secure.facility.settings.forms.facility_form_edit.002")));
    closeNewInputModal();

    const newFormInput = res.data as FormInput;
    const row = String(newFormInput.row);

    const updatedFormInputRowsWithInput: FormInputRows = {
      ...updatedFormInputRows,
      [row]: [newFormInput],
    };

    formInputRowsContainerActions.set(updatedFormInputRowsWithInput);
  }

  /** DELETE a form input. Update state. */
  async function handleRemoveFormInput(id: number) {
    let updatedFormInputRows: FormInputRows = undefined;

    if (formInputRowsContainer.changesExist) {
      updatedFormInputRows = await updateFormInputRowPositions();

      if (!updatedFormInputRows) {
        return;
      }
    }

    updatedFormInputRows ??= cloneDeep(formInputRowsContainer.updatedState);

    const res = await DeleteFormInput({ id: id, client_id: masterFacilityStore?.facility.client_id }, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.forms.facility_form_edit.003")));
      return;
    }

    dispatch(showSuccess(t("secure.facility.settings.forms.facility_form_edit.004")));

    setSelectedFormInput(prev => ({ ...prev, id: null, deletePopupOpen: false }));

    let rowDeletingFrom = "";

    Object.keys(updatedFormInputRows).forEach(row => {
      if (updatedFormInputRows[row]?.some(formInput => formInput.id === id)) {
        rowDeletingFrom = row;
      }
    });

    if (rowDeletingFrom === "") {
      return;
    }

    const { [rowDeletingFrom]: originalRow, ...rest } = updatedFormInputRows;

    const updatedRow = originalRow
      .filter(formInput => formInput.id !== id)
      .map((formInput, index) => ({ ...formInput, column: index + 1 }));

    const updatedFormInputRowsWithDelete: FormInputRows = {
      ...rest,
      ...(updatedRow.length > 0 ? { [rowDeletingFrom]: updatedRow } : {}),
    };

    if (updatedRow.length > 0) {
      formInputRowsContainerActions.set(updatedFormInputRowsWithDelete);
    } else {
      const updatedFormInputRowsWithShift: FormInputRows = {};
      const numericRowDeletingFrom = Number(rowDeletingFrom);

      Object.keys(updatedFormInputRowsWithDelete).forEach(row => {
        const numericRow = Number(row);

        if (numericRow > numericRowDeletingFrom) {
          const shiftedRow = numericRow - 1;

          updatedFormInputRowsWithShift[String(shiftedRow)] = updatedFormInputRowsWithDelete[row].map(formInput => {
            return {
              ...formInput,
              row: shiftedRow,
            };
          });
        } else {
          updatedFormInputRowsWithShift[row] = updatedFormInputRowsWithDelete[row];
        }
      });

      formInputRowsContainerActions.set(updatedFormInputRowsWithShift);
    }
  }

  /** PUT a form input.  Update state. */
  const handleUpdateInput: FormEditHandleUpdateInput = async (id, label, helpText, value, isRequired) => {
    let updatedFormInputRows: FormInputRows = undefined;

    if (formInputRowsContainer.changesExist) {
      updatedFormInputRows = await updateFormInputRowPositions();

      if (!updatedFormInputRows) {
        return;
      }
    }

    updatedFormInputRows ??= formInputRowsContainer.updatedState;

    const params: PutFormInputParams = {
      input_id: id,
      label: label,
      help_text: helpText,
      values: value,
      required: isRequired,
      client_id: masterFacilityStore?.facility.client_id,
    };

    const res = await PutFormInput(params, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError(res.data ? (res.data as string) : t("secure.facility.settings.forms.facility_form_edit.005")));
      return;
    }

    dispatch(showSuccess(t("secure.facility.settings.forms.facility_form_edit.006")));

    const updatedFormInput = cloneDeep(res.data as FormInput);
    const updatedFormInputRowsWithUpdateInput: FormInputRows = cloneDeep(updatedFormInputRows);

    for (const row in updatedFormInputRowsWithUpdateInput) {
      const updatedFormInputRow = updatedFormInputRowsWithUpdateInput[row];

      for (let i = 0; i < updatedFormInputRow.length; i++) {
        if (updatedFormInputRow[i].id === updatedFormInput.id) {
          updatedFormInputRow[i] = updatedFormInput;
        }
      }
    }

    formInputRowsContainerActions.set(updatedFormInputRowsWithUpdateInput);
    closeFormInputEditModal();
  };

  function openEditFormModal() {
    setEditFormModal(prev => ({
      ...prev,
      open: true,
      formTitle: formTitle,
    }));
  }

  function closeEditFormModal() {
    setEditFormModal(prev => ({
      ...prev,
      open: false,
      formTitle: "",
    }));
  }

  /** PUT a form.  */
  async function handleUpdateForm() {
    const params: PutFacilityFormParams = {
      id: Number(id),
      title: editFormModal.formTitle,
      client_id: masterFacilityStore?.facility.id,
    };

    const res = await PutFacilityForm(params, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.forms.facility_form_edit.007")));
      return;
    }

    dispatch(showSuccess(t("secure.facility.settings.forms.facility_form_edit.008")));
    setFormTitle(editFormModal.formTitle);
    closeEditFormModal();
  }

  function closeNewInputModal() {
    setNewInputModal({
      form_id: Number(id),
      open: false,
      type: "none",
      label: "",
      help_text: "",
    });
  }

  function closeFormInputEditModal() {
    setSelectedFormInput(prev => ({ ...prev, id: null, editModalOpen: false }));
  }

  async function updateFormInputRowPositions() {
    const updatedFormInputRows: FormInputRows = generateInputRowsWithSequentialPositions(
      formInputRowsContainer.updatedState,
    );

    const formInputPositions: PutFormInputPosition[] = [];

    Object.keys(updatedFormInputRows).forEach(row => {
      updatedFormInputRows[row].forEach(formInput => {
        formInputPositions.push({ id: formInput.id, row: formInput.row, column: formInput.column });
      });
    });

    const putFormInputPositions = await PutFormInputPositions({
      inputs: formInputPositions,
      client_id: masterFacilityStore.facility?.client_id,
    });

    if (putFormInputPositions.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.forms.facility_form_edit.011")));
      return undefined;
    }

    formInputRowsContainerActions.set(updatedFormInputRows);
    return updatedFormInputRows;
  }

  function cancelPositionChanges() {
    formInputRowsContainerActions.rollback();
  }

  return (
    <Page
      title={`${formTitle ?? ""} `}
      subtitle={masterFacilityStore.facility ? masterFacilityStore.facility.long_name : "No Facility Selected"}
      notificationBarProps={{
        isVisible: formInputRowsContainer?.changesExist,
        onAction: updateFormInputRowPositions,
        onCancel: cancelPositionChanges,
      }}
      secondaryActions={[
        {
          action: openEditFormModal,
          content: t("secure.facility.settings.forms.facility_form_edit.025"),
          disabled: !masterFacilityStore.facility,
        },
      ]}
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.forms.facility_form_edit.012"),
          url: "/admin/facility/settings/forms",
        },
      ]}
    >
      {masterFacilityStore.facility && (
        <>
          <hr />
          <div className="form-add-input-container">
            <Button type="text" onClick={() => setNewInputModal(prev => ({ ...prev, open: true }))}>
              <FontAwesomeIcon className="form-add-input-icon" size="sm" icon={["far", "plus"]} />
              <span>{t("secure.facility.settings.forms.facility_form_edit.013")}</span>
            </Button>
          </div>

          <DndProvider backend={HTML5Backend}>
            <FormEditContext.Provider
              value={{
                selectedFormInput,
                setSelectedFormInput,
                formInputRowsContainer,
                formInputRowsContainerActions,
                handleUpdateInput,
                closeFormInputEditModal,
              }}
            >
              {!formInputRowsContainer?.updatedState ? (
                <div style={{ height: "106px", overflow: "hidden", transform: "scale(0.5)" }}>
                  <Spin />
                </div>
              ) : (
                <Card>
                  <Card.Section>
                    <FormEditInputLayout setNewInputModal={setNewInputModal} />
                  </Card.Section>
                </Card>
              )}
            </FormEditContext.Provider>
          </DndProvider>
        </>
      )}

      {/* Edit Form Modal */}
      <Portal isMounted={editFormModal.open}>
        <Sheet
          open={editFormModal.open}
          onOk={handleUpdateForm}
          okText={t("secure.facility.settings.forms.facility_form_edit.014")}
          okDisabled={editFormModal.formTitle === ""}
          onCancel={closeEditFormModal}
          size="small"
          title={t("secure.facility.settings.forms.facility_form_edit.015")}
        >
          <Input
            label={t("secure.facility.settings.forms.facility_form_edit.016")}
            value={editFormModal.formTitle}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setEditFormModal(prev => ({ ...prev, formTitle: e.target.value }))
            }
          />
        </Sheet>
      </Portal>

      {/* New Input Modal */}
      <Portal isMounted={newInputModal.open}>
        <Sheet
          open={newInputModal.open}
          onOk={() => handleAddNewInput()}
          okText={t("secure.facility.settings.forms.facility_form_edit.017")}
          onCancel={() => closeNewInputModal()}
          okDisabled={newInputModal.type === "none" || !newInputModal.label}
          size="small"
          title={t("secure.facility.settings.forms.facility_form_edit.018")}
          overflow
        >
          <FormLayout>
            <FormLayout.Group>
              <Select
                label={t("secure.facility.settings.forms.facility_form_edit.019")}
                onChange={(value: FormInputTypes | "none") => setNewInputModal(prev => ({ ...prev, type: value }))}
                defaultValue={"none"}
              >
                {(["none", "input", "select", "checkbox", "text"] as FormInputTypes[]).map((value, index) => {
                  return (
                    <Option key={index} value={value} name={capitalize(value)}>
                      {capitalize(value)}
                    </Option>
                  );
                })}
              </Select>
            </FormLayout.Group>
            <FormLayout.Group>
              <Input
                label={t("secure.facility.settings.forms.facility_form_edit.020")}
                value={newInputModal.label}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setNewInputModal(prev => ({ ...prev, label: e.target.value }))
                }
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <Input
                label={
                  newInputModal?.type === "text"
                    ? t("secure.facility.settings.forms.facility_form_edit.021")
                    : t("secure.facility.settings.forms.facility_form_edit.022")
                }
                value={newInputModal.help_text}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setNewInputModal(prev => ({ ...prev, help_text: e.target.value }))
                }
              />
            </FormLayout.Group>
          </FormLayout>
        </Sheet>
      </Portal>

      {/* Remove Input Popup */}
      <Portal isMounted={selectedFormInput.deletePopupOpen}>
        <Popup
          open={selectedFormInput.deletePopupOpen}
          type="warning"
          title={t("secure.facility.settings.forms.facility_form_edit.023")}
          description={t("secure.facility.settings.forms.facility_form_edit.024")}
          onCancel={() => setSelectedFormInput(prev => ({ ...prev, id: null, deletePopupOpen: false }))}
          onOk={() => handleRemoveFormInput(selectedFormInput.id)}
        />
      </Portal>
    </Page>
  );
}
