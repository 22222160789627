import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import {
  GetShotGunTemplate,
  PutShotGunTemplate,
  IShotgunTemplateGroup,
} from "api/rpc/2024-04/facilityAdmin/teesheet/shotgun";
import { StatusCode } from "api/protocols";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { showError, showSuccess } from "redux/actions/ui";

import Page from "components/page/Page";
import Card from "components/card/Card";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Sheet from "components/sheet/Sheet";
import Input from "components/form/input/Input";
import Checkbox from "components/form/checkbox/Checkbox";
import { ButtonNew as Button } from "components/buttonNew/index";

import "./shotgunTemplate.scss";

interface IEditState {
  groups: IShotgunTemplateGroup[];
  groupsFormatted: IShotgunTemplateGroup[][];
  deleteIds: Array<number>;
  teeTimeId: number;
  addTeeTimePopup: boolean;
  templateTitle: string;
  selectAllHoles: boolean;
  selectedHoles: number[];
  addHoleModalVisible: boolean;
  newHoleNumber: number;
}

export default function ShotGunTemplateEdit() {
  const { id } = useParams<any>();
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
  const dispatch = useAppDispatch();
  const facilityStore = useAppSelector(store => store.facilityStore);

  const totalHoles = new Array(facilityStore?.facility?.holes);
  for (let i = 0; i < facilityStore?.facility?.holes; i++) {
    totalHoles[i] = i + 1;
  }

  const [editState, setEditState] = useState<IEditState>({
    groups: null,
    groupsFormatted: null,
    deleteIds: [],
    teeTimeId: null,
    addTeeTimePopup: false,
    templateTitle: "",
    selectAllHoles: false,
    selectedHoles: [],
    addHoleModalVisible: false,
    newHoleNumber: null,
  });

  const sortGroups = (a: IShotgunTemplateGroup, b: IShotgunTemplateGroup) =>
    a.start_hole.localeCompare(b.start_hole, "en", { numeric: true });

  useEffect(() => {
    void loadShotgunTemplate();
  }, []);

  async function loadShotgunTemplate() {
    const templateRes = await GetShotGunTemplate({ id: Number(id), extended: true }, true);

    if (templateRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading shotgun template")); // TODO: Translation
      return;
    }

    const teeTimes = templateRes.data[0].tee_times;

    const sortedTeeTimes = teeTimes.sort(sortGroups);

    const groups: any = sortedTeeTimes.map((teeTime: any, index: number) => {
      return {
        id: teeTime.id,
        group_index: parseInt(teeTime.start_hole),
        start_hole: teeTime.start_hole,
        start_time: teeTime.start_time,
        position: teeTime.position,
      };
    });

    const groupedArray = groups.reduce(
      (formattedGroup: Array<Array<IShotgunTemplateGroup>>, group: IShotgunTemplateGroup) => {
        formattedGroup[group.group_index - 1] = formattedGroup[group.group_index - 1] || [];
        formattedGroup[group.group_index - 1].push(group);
        return formattedGroup;
      },
      [],
    );

    for (let i = 0; i < groupedArray.length; i++) {
      if (groupedArray[i]) {
        groupedArray[i].checked = false;
      }
    }

    setEditState(prevState => ({
      ...prevState,
      groups: groups,
      groupsFormatted: groupedArray,
      templateTitle: templateRes.data[0].title,
    }));
  }

  async function updateShotgunTemplate() {
    const positionedGroups = editState.groups
      .filter(group => {
        return !!group;
      })
      .map((group: IShotgunTemplateGroup, index: number) => {
        if (group) {
          group.position = index + 1;
          return group;
        }
      });

    const templateRes = await PutShotGunTemplate(
      {
        template_id: Number(id),
        title: editState.templateTitle,
        groups: positionedGroups,
        delete_group_ids: editState.deleteIds,
      },
      true,
    );

    if (templateRes.status !== StatusCode.OK) {
      dispatch(showError("Error updating shotgun template")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Shotgun template updated successfully")); // TODO: Translation
    void loadShotgunTemplate();
  }

  function addGroup(holeIndex: number) {
    if (
      editState.groupsFormatted[holeIndex].length < alphabet.length &&
      editState.groupsFormatted[holeIndex].length < 4
    ) {
      const currentAlphabet = alphabet;
      let groupLetter;

      editState.groupsFormatted[holeIndex].forEach(group => {
        groupLetter = group.start_hole.substring(group.start_hole.length - 1);
        currentAlphabet.splice(currentAlphabet.indexOf(groupLetter), 1);
      });

      const currentGroupLetter = currentAlphabet[0];

      const newGroup: IShotgunTemplateGroup = {
        id: 0,
        position: 0,
        group_index: holeIndex + 1,
        start_hole: (holeIndex + 1).toString() + currentGroupLetter,
        start_time: "12:00",
      };

      const groupedArray = editState.groupsFormatted;

      groupedArray[holeIndex].push(newGroup);

      const currentGroups: IShotgunTemplateGroup[] = [].concat(...groupedArray);

      setEditState(prevState => ({ ...prevState, groups: currentGroups, groupsFormatted: groupedArray }));
    }
  }

  function removeGroup(holeIndex: number) {
    const newDeleteIds = editState.deleteIds;

    const groupedArray = editState.groupsFormatted;

    const removedGroup = groupedArray[holeIndex].pop();

    newDeleteIds.push(removedGroup.id);

    const currentGroups: IShotgunTemplateGroup[] = [].concat(...groupedArray);
    setEditState(prevState => ({
      ...prevState,
      groups: currentGroups,
      groupsFormatted: groupedArray,
      deleteIds: newDeleteIds,
    }));
  }

  function addHole() {
    const currentGroupIndex: number = editState.newHoleNumber;
    if (editState.groupsFormatted[currentGroupIndex - 1]) {
      dispatch(showError("Hole already exists")); // TODO: Translation
      return;
    }
    const groupA: IShotgunTemplateGroup = {
      id: 0,
      position: 0,
      group_index: currentGroupIndex,
      start_hole: currentGroupIndex.toString() + "A",
      start_time: "12:00",
    };
    const groupB: IShotgunTemplateGroup = {
      id: 0,
      position: 0,
      group_index: currentGroupIndex,
      start_hole: currentGroupIndex.toString() + "B",
      start_time: "12:00",
    };
    const updatedGroups = editState.groups;
    updatedGroups.push(groupA);
    updatedGroups.push(groupB);

    const groupedArray = editState.groupsFormatted;
    // If group is being added in middle of array differently
    if (currentGroupIndex !== groupedArray.length) {
      groupedArray[currentGroupIndex - 1] = [];
    } else {
      groupedArray.push([]);
    }
    groupedArray[currentGroupIndex - 1].push(groupA);
    groupedArray[currentGroupIndex - 1].push(groupB);

    setEditState(prevState => ({
      ...prevState,
      groups: updatedGroups,
      groupsFormatted: groupedArray,
      addHoleModalVisible: false,
      newHoleNumber: null,
    }));
  }

  function removeHole() {
    const groupedArray: any = editState.groupsFormatted;

    const newDeleteIds = editState.deleteIds;

    for (let i = 0; i < editState.selectedHoles.length; i++) {
      for (let j = 0; j < groupedArray[editState.selectedHoles[i]].length; j++) {
        newDeleteIds.push(groupedArray[editState.selectedHoles[i]][j].id);
      }
      groupedArray[editState.selectedHoles[i]] = [];
      groupedArray[editState.selectedHoles[i]].checked = false;
    }

    const currentGroups: IShotgunTemplateGroup[] = [].concat(...groupedArray);
    setEditState(prevState => ({
      ...prevState,
      groups: currentGroups,
      groupsFormatted: groupedArray,
      selectedHoles: [],
    }));
  }

  function handleShotgunInputChange(event: any) {
    const { id, value } = event.target;
    setEditState(prevState => ({ ...prevState, [id]: value }));
  }

  function selectAllHoles(event: any) {
    const { checked } = event.target;

    const groupedArray: any = editState.groupsFormatted;

    if (checked) {
      for (let i = 0; i < groupedArray.length; i++) {
        if (groupedArray[i]) {
          groupedArray[i].checked = true;
        }
      }
    } else {
      for (let i = 0; i < groupedArray.length; i++) {
        if (groupedArray[i]) {
          groupedArray[i].checked = false;
        }
      }
    }

    setEditState(prevState => ({
      ...prevState,
      selectAllHoles: !editState.selectAllHoles,
      groupsFormatted: groupedArray,
    }));
  }

  function handleHoleSelectChange(event: any, holeIndex: number) {
    const { checked } = event.target;
    const selectedHoles = editState.selectedHoles;

    if (checked) {
      selectedHoles.push(holeIndex);
    } else {
      const index = selectedHoles.indexOf(holeIndex);
      if (index > -1) {
        selectedHoles.splice(index, 1);
      }
    }

    const groupedArray: any = editState.groupsFormatted;

    groupedArray[holeIndex].checked = !groupedArray[holeIndex].checked;

    setEditState(prevState => ({ ...prevState, groupsFormatted: groupedArray }));
  }

  const primaryAction = {
    content: "Save", // TODO: Translation
    action: updateShotgunTemplate,
  };

  return (
    <Page
      title="Edit Shotgun Template" // TODO: Translation
      narrow
      breadcrumbs={[
        {
          prefix: true,
          label: "Back to Shotgun Templates", // TODO: Translation
          url: "/admin/settings/tee-sheet/shotgun-templates",
        },
      ]}
      primaryAction={primaryAction}
    >
      <Card>
        <Card.Section title="Information" /* TODO: Translation */>
          <Input
            value={editState.templateTitle}
            label={"Title"} // TODO: Translation
            id="templateTitle"
            onChange={handleShotgunInputChange}
          />
        </Card.Section>
        <Card.Section title="Structure" /* TODO: Translation */>
          <DataTable
            columns={[
              {
                label: "holes",
                width: "10%",
                content: (
                  <div className="flex">
                    <Checkbox size="medium" value={1} onChange={selectAllHoles} checked={editState.selectAllHoles} />
                    <span className="shotgun-template-edit-label">
                      {"Holes"}
                      {/* TODO: Translation */}
                    </span>
                  </div>
                ),
              },
              {
                label: "Groups", // TODO: Translation
              },
              {
                label: "delete",
                width: "15%",
                content: (
                  <div
                    title="Add group"
                    /* TODO: Translation */ onClick={removeHole}
                    className="shotgun-template_add_button"
                  >
                    Delete &nbsp; <FontAwesomeIcon icon={["fas", "minus"]} />
                  </div>
                ),
              },
            ]}
            className="ui-table-clickable"
          >
            {editState?.groupsFormatted?.map((hole: any, index: number) => {
              return (
                <tr key={index}>
                  <td>
                    <div className="flex items-center gap-4">
                      <div className="flex">
                        <Checkbox
                          size="medium"
                          value={1}
                          onChange={event => handleHoleSelectChange(event, index)}
                          checked={hole.checked}
                        />
                      </div>

                      <div className="shotgun-template_table_text">{index + 1}</div>
                    </div>
                  </td>
                  <td>
                    <div className="shotgun-template_table_body_holes_container">
                      {hole.map((teeTime: any, teeTimeIndex: number) => {
                        return (
                          <div key={teeTimeIndex} className="shotgun-template_table_body_holes">
                            {teeTime.start_hole?.replace(/[0-9]/g, "")}
                          </div>
                        );
                      })}
                    </div>
                  </td>
                  <td>
                    <div className="shotgun-template_add_button">
                      <Button onClick={() => addGroup(index)} type="text" size="small" disabled={hole.length >= 4}>
                        <FontAwesomeIcon icon={["fas", "plus"]} />
                      </Button>
                      <Button onClick={() => removeGroup(index)} type="text" size="small" disabled={hole.length === 0}>
                        <FontAwesomeIcon icon={["fas", "minus"]} />
                      </Button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </DataTable>
          {editState.groupsFormatted?.length < 18 && (
            <div
              title="Add hole"
              /* TODO: Translation */ className="shotgun-template_add_button mt-3"
              style={{ justifyContent: "left" }}
            >
              <Button
                onClick={() => setEditState(prevState => ({ ...prevState, addHoleModalVisible: true }))}
                type="text"
                size="small"
              >
                <FontAwesomeIcon icon={["fas", "plus"]} /> &nbsp; Add hole
              </Button>
            </div>
          )}
        </Card.Section>
      </Card>

      <Sheet
        title="Add Hole" // TODO: Translation
        open={editState.addHoleModalVisible}
        size="small"
        onOk={addHole}
        onCancel={() => setEditState(prevState => ({ ...prevState, addHoleModalVisible: false }))}
        okText="Add" // TODO: Translation
        okDisabled={!editState.newHoleNumber}
      >
        <Input
          value={editState.newHoleNumber ?? ""}
          label={"Hole Number"} // TODO: Translation
          id="newHoleNumber"
          onChange={handleShotgunInputChange}
          type={"number"}
        />
      </Sheet>
    </Page>
  );
}
