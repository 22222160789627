import React, { useEffect, useState } from "react";
import { GetProduct as FacilityGetProduct, TGetProduct } from "api/rpc/2024-04/facilityAdmin/product/product";
import {
  GetProduct as ClientGetProduct,
  TGetProduct as ClientTGetProduct,
} from "api/rpc/2024-04/clientAdmin/product/product";
import "./productSelect.scss";
import axios, { CancelToken } from "axios";
import { IProduct, IVariant } from "redux/reducers/models/product";
import { showError } from "redux/actions/ui";
import { StatusCode } from "api/protocols";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import Search from "components/search/Search";
import classNames from "classnames";
import Spin from "components/spin/spin";
import Checkbox from "components/form/checkbox/Checkbox";
import { LocaleCurrency } from "helpers/Locale";
import Callout from "components/callout/Callout";
import Input from "components/form/input";
import { ButtonNew as Button } from "components/buttonNew";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cloneDeep } from "lodash";
import { UserType } from "redux/reducers/models/user";

interface IProductExtended extends IProduct {
  variants: Array<IVariantExtended>;
}

interface IVariantExtended extends IVariant {
  [key: string]: any;
}

type TVariantInput = {
  key: string;
  placeholder?: string;
};
interface IProps {
  /**Array of products selected in state*/
  selectedProducts: Array<IProductExtended>;
  /**Function that returns the updated selected variants */
  onChange: (variants: Array<IProductExtended>) => void;
  /**(Optional) Display search input to search products */
  search?: boolean;
  /**(Optional) Function that runs when search is performed. Returns search string*/
  onSearch?: (search: string) => void;
  /**(Optional) Parameters for GET Product endpoint*/
  getProductsParams?: Partial<Omit<TGetProduct, "search" | "limit" | "offset" | "variants">>;
  /**(Optional) Sets the maximum height of the products table*/
  maxHeight?: string;
  /**(Optional) Object that contains the variant input key and optional placeholder*/
  variantInput?: TVariantInput;
  /**(Optional) Limit the number of products returned to this value*/
  limit?: number;
}

interface IProductState {
  products: Array<IProductExtended>;
  searchQuery: string;
}

/**
 * Component that allows products to be searched and selected. Returns an array of variants
 * @param {IProps} props
 * @param {boolean} props.selectedProducts Array of products selected in state
 * @param {(variants: Array<IVariant>) => void} props.onChange Function that returns the updated selected variants
 * @param {boolean} props.search `(Optional)` Display search input to search products
 * @param {(search: string) => void} props.onSearch `(Optional)`Function that runs when search is performed. Returns search string
 * @param {TGetProduct} props.getProductsParams `(Optional)` Parameters for GET Product endpoint
 * @param {CSSHeight} props.maxHeight `(Optional)` Sets the maximum height of the products table
 * @param {TVariantInput} props.variantInput `(Optional)` Object that contains the variant input key and optional placeholder
 * @param {number} props.limit `(Optional)` Limit the number of products returned to this value
 * @returns {JSX.Element}
 */
export default function ProductSelect(props: IProps): JSX.Element {
  const { selectedProducts, onChange, search, getProductsParams, maxHeight, onSearch, variantInput, limit } = props;
  const dispatch = useAppDispatch();
  const authStore = useAppSelector(store => store.authStore);
  const [productState, setProductState] = useState<IProductState>({
    products: undefined,
    searchQuery: "",
  });

  const [offset, setOffset] = useState<number>(0);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadProducts(productState.searchQuery, source.token);
    return () => source.cancel();
  }, [productState.searchQuery, offset]);

  async function loadProducts(search: string, token: CancelToken) {
    const userType = authStore?.user?.user_type;
    if (productState.products !== undefined) {
      setProductState(prevState => ({ ...prevState, products: undefined }));
    }

    const filter = {
      offset: limit ? offset : undefined,
      limit: limit ? limit : undefined,
    };

    if (userType === UserType.CLIENT_ADMIN) {
      const res = await ClientGetProduct(
        { search: search ?? undefined, variants: true, ...getProductsParams, ...filter },
        false,
        token,
      );
      console.log("Product Res", res);
      if (res.status !== StatusCode.OK) {
        if (token?.reason) {
          return;
        }
        dispatch(showError("Error loading products"));
      }
      setProductState(prevState => ({ ...prevState, products: res.status !== StatusCode.OK ? [] : res.data }));
    } else if (userType === UserType.FACILITY_ADMIN) {
      const res = await FacilityGetProduct(
        { search: search ?? undefined, variants: true, ...getProductsParams, ...filter },
        false,
        token,
      );

      if (res.status !== StatusCode.OK) {
        if (token?.reason) {
          return;
        }
        dispatch(showError("Error loading products"));
      }
      setProductState(prevState => ({ ...prevState, products: res.status !== StatusCode.OK ? [] : res.data }));
    } else {
      dispatch(showError("User is not authorized to access this page"));
    }
  }

  function handleSelectProduct(productIndex: number, variantIndex?: number) {
    const updatedSelectedProducts = [...selectedProducts];
    const currentProduct = cloneDeep(productState.products[productIndex]);
    const foundProductIndex = updatedSelectedProducts?.findIndex(
      selectedProduct => selectedProduct?.id === currentProduct?.id,
    );
    if (variantIndex != null) {
      const currentVariant = currentProduct?.variants[variantIndex];
      // Single variant selected
      if (foundProductIndex === -1) {
        // Add product and single variant
        const updatedCurrentProduct = { ...currentProduct, variants: [{ ...currentVariant }] };
        updatedSelectedProducts.push(updatedCurrentProduct);
      } else {
        const foundVariantIndex = updatedSelectedProducts[foundProductIndex]?.variants?.findIndex(
          selectedVariant => selectedVariant?.id === currentVariant?.id,
        );
        // Add single variant
        if (foundVariantIndex === -1) {
          updatedSelectedProducts[foundProductIndex] = {
            ...updatedSelectedProducts[foundProductIndex],
            variants: [...updatedSelectedProducts[foundProductIndex]?.variants, currentVariant],
          };
        } else {
          updatedSelectedProducts[foundProductIndex]?.variants?.splice(foundVariantIndex, 1);
          if (updatedSelectedProducts[foundProductIndex]?.variants?.length === 0) {
            updatedSelectedProducts.splice(foundProductIndex, 1);
          }
        }
      }
    } else {
      // All variants selected
      if (foundProductIndex === -1) {
        // Product not found, add product + all its variants
        updatedSelectedProducts.push({ ...currentProduct });
      } else {
        // Product found
        if (
          currentProduct?.variants?.every(currentVariant =>
            updatedSelectedProducts[foundProductIndex]?.variants?.some(variant => variant?.id === currentVariant?.id),
          )
        ) {
          // Every remove product and all variants
          updatedSelectedProducts.splice(foundProductIndex, 1);
        } else {
          // Add missing variants
          currentProduct?.variants?.forEach(currentVariant => {
            !updatedSelectedProducts[foundProductIndex]?.variants?.find(
              variant => variant?.id === currentVariant?.id,
            ) && updatedSelectedProducts[foundProductIndex]?.variants?.push({ ...currentVariant });
          });
        }
      }
    }
    void onChange(updatedSelectedProducts);
  }

  function handleSearch(searchValue: string) {
    if (onSearch) {
      void onSearch(searchValue);
    }
    setProductState(prevState => ({ ...prevState, searchQuery: searchValue }));
  }

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, productId: number, variantId: number) {
    const { value } = e.target;
    const products = [...selectedProducts];
    const productIndex = products?.findIndex(product => product.id === productId);
    if (productIndex !== -1) {
      const variants = products[productIndex]?.variants;
      const variantIndex = variants?.findIndex(variant => variant?.id === variantId);
      if (variantIndex !== -1) {
        variants[variantIndex] = { ...variants[variantIndex], [variantInput.key]: value };
        void onChange(products);
        return;
      }
      console.error(`VariantId Id: ${variantId} not found`);
    }
    console.error(`ProductId Id: ${productId} not found`);
  }

  return (
    <div className="product-select">
      {search && (
        <Search
          searchCallback={searchValue => handleSearch(searchValue)}
          historyKey="inventory_product_modal"
          placeholder="Search products..."
        />
      )}
      <div className="product-select-table" style={{ maxHeight: maxHeight ?? undefined }}>
        {productState.products ? (
          productState.products?.length > 0 ? (
            productState?.products?.map((product, productIndex) => {
              const singleVariant = product?.variant_count === 1;
              const foundProduct = selectedProducts?.find(selectedProduct => selectedProduct?.id === product?.id);
              const foundSingleVariant = foundProduct?.variants?.find(
                variant => variant?.id === product?.variants[0]?.id,
              );
              return (
                <section id={`section_${productIndex}`} key={productIndex}>
                  <div
                    className={classNames("table-row product-header", {
                      "table-row-input": variantInput,
                    })}
                    onClick={() => handleSelectProduct(productIndex, singleVariant ? 0 : null)}
                  >
                    <div>
                      <Checkbox
                        size="medium"
                        labelHidden
                        checked={
                          product?.variants?.every(productVariant =>
                            foundProduct?.variants?.find(variant => variant?.id === productVariant?.id),
                          ) ?? false
                        }
                        onChange={() => {}}
                      />
                    </div>
                    <div>
                      <p>{product?.preferred_title ? product?.preferred_title : product.title}</p>
                      {product?.subtitle && <p>{product.subtitle}</p>}
                    </div>
                    <div>{product.vendor_title}</div>
                    <div>{singleVariant && product?.variants[0]?.sku}</div>
                    {singleVariant && variantInput && (
                      <div onClick={e => e.stopPropagation()}>
                        {
                          <Input
                            placeholder={variantInput.placeholder}
                            type="number"
                            disabled={!foundSingleVariant}
                            value={foundSingleVariant ? foundSingleVariant[variantInput.key] ?? "" : ""}
                            labelHidden
                            onChange={e => onInputChange(e, product?.id, product?.variants[0]?.id)}
                          />
                        }
                      </div>
                    )}
                  </div>
                  {product?.variant_count > 1 &&
                    product?.variants?.map((variant, variantIndex: number) => {
                      const foundVariant = foundProduct?.variants?.find(
                        selectedVariant => selectedVariant?.id === variant?.id,
                      );
                      return (
                        <div
                          className={classNames("table-row sub-row", { "sub-row-input": variantInput })}
                          key={variantIndex}
                          onClick={() => handleSelectProduct(productIndex, variantIndex)}
                        >
                          <div style={{ textIndent: "40px" }}>
                            <Checkbox size="medium" labelHidden checked={!!foundVariant ?? false} onChange={() => {}} />
                          </div>
                          <div>{variant?.title}</div>
                          <div></div>
                          <div>{variant?.sku}</div>
                          {variantInput && (
                            <div onClick={e => e.stopPropagation()}>
                              <Input
                                placeholder={variantInput.placeholder}
                                type="number"
                                disabled={!foundVariant}
                                value={foundVariant ? foundVariant[variantInput.key] ?? "" : ""}
                                labelHidden
                                onChange={e => onInputChange(e, product.id, variant.id)}
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                </section>
              );
            })
          ) : (
            <Callout type="info" title="No products found" />
          )
        ) : (
          <div className="spinner">
            <Spin />
          </div>
        )}
      </div>
      {!!limit && productState.products && (
        <div className={classNames("table-footer")}>
          <Button
            size="medium"
            type="secondary"
            onClick={() => setOffset(prev => prev - limit)}
            disabled={!(limit <= offset)}
          >
            {" "}
            <FontAwesomeIcon icon={"arrow-left"} />
            &nbsp;Prev
          </Button>
          <Button
            size="medium"
            type="secondary"
            onClick={() => setOffset(prev => prev + limit)}
            disabled={!productState.products || !(productState?.products?.length === limit)}
          >
            Next&nbsp;
            <FontAwesomeIcon icon={"arrow-right"} />
          </Button>
        </div>
      )}
    </div>
  );
}
