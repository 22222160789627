import { IClient } from "../models/client";

export interface IClientState {
  isLoaded: boolean;
  error?: any;
  clients?: IClient[];
  client: IClient;
}

export interface IClientAction {
  type: string;
  payload: any;
}

const initialState: IClientState = {
  isLoaded: false,
  error: null,
  clients: [],
  client: null,
};

export default function MasterClientReducer(state = initialState, action: IClientAction) {
  switch (action.type) {
    case "client.update":
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        ...state,
        ...action.payload,
      };
    case "client.select":
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
