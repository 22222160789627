import React, { ChangeEvent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import {
  GetTeeDecks,
  PutTeeDeck,
  DeleteTeeDeck,
  GetYardages,
  PutYardages,
  PostYardages,
  ITeeDeckRating,
} from "api/rpc/2022-09/facilityAdmin/facility/teedeck";
import { GetCourse } from "api/rpc/2022-09/facilityAdmin/facility/course";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Input from "components/form/input/Input";
import FormLayout from "components/form/FormLayout";
import Card from "components/card/Card";
import Form from "components/form/Form";
import { Select } from "components/select/index";
import { ButtonNew as Button } from "components/buttonNew";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEqualWith, isNull } from "lodash";
import { NotificationType } from "components/notificationBar/NotificationBar";
import { IAuthState } from "redux/reducers/auth";
import { IAuthActions } from "redux/actions/auth";
import { IUIActions } from "redux/actions/ui";
import { IFacilityState } from "redux/reducers/facility";

import { useTranslation } from "react-i18next";

interface ITeeDeckProps {
  authStore: IAuthState;
  authActions: IAuthActions;
  uiActions: IUIActions;
  facilityStore: IFacilityState;
}

interface ITeeDeck {
  id: number;
  title: string;
  course_id: number;
  yardages: any;
  ratings: ITeeDeckRating[];
  courses: any;
  courseOptions: any;
}

interface IYardage {
  division_id?: number;
  id?: number;
  tee_deck_id?: number;
  yards?: number[];
}

export default function TeeDeck(props: ITeeDeckProps) {
  const { teeDeckId } = useParams<{ teeDeckId: string }>();
  const { t, i18n } = useTranslation();
  const { Option } = Select;
  const history = useHistory();

  const [teeDeckState, setTeeDeckState] = useState<ITeeDeck>(undefined);

  const [teeDeckStateBeforeChanges, setTeeDeckStateBeforeChanges] = useState<ITeeDeck>(undefined);
  const [teeDeckLoaded, setTeeDeckLoaded] = useState<boolean>(false);
  const [coursesLoaded, setCoursesLoaded] = useState<boolean>(false);

  useEffect(() => {
    void loadTeeDeck();
  }, []);

  async function loadCourses() {
    const courseRes = await GetCourse({ scoring_only: true }, true);

    if (courseRes.status !== StatusCode.OK) {
      props.uiActions.showError("Error Loading Courses");
      return;
    }

    const courses = courseRes.data;

    const courseOptions = courses.map((course: any) => {
      return { label: course.full_name, value: course.id };
    });

    setTeeDeckState(prev => ({
      ...prev,
      courses: courses,
      courseOptions: courseOptions,
    }));

    setCoursesLoaded(true);
  }

  async function loadTeeDeck() {
    const teeDeckRes = await GetTeeDecks({ id: Number(teeDeckId) }, true);
    if (teeDeckRes.status !== StatusCode.OK) {
      props.uiActions.showError(t("secure.facility.settings.facility.tee_deck.001"));
      return;
    }

    const yardagesRes = await GetYardages({ tee_deck_id: teeDeckId }, true);

    setTeeDeckState(prev => ({
      ...prev,
      id: teeDeckRes.data[0].id,
      title: teeDeckRes.data[0].title ?? "",
      course_id: teeDeckRes.data[0].course_id,
      yardages: yardagesRes.data,
      ratings: teeDeckRes.data[0].ratings,
      viewLeagueOnlyYardages: false,
      viewLeagueOnlyRatings: false,
    }));

    setTeeDeckLoaded(true);

    void loadCourses();
  }

  async function saveTeeDeckState() {
    // console.log(teeDeckState);
    // console.log(teeDeckStateBeforeChanges);
    const putTeeDeckRes = await PutTeeDeck(
      {
        id: teeDeckState.id,
        title: teeDeckState.title,
        ratings: teeDeckState.ratings,
      },
      true,
    );

    const putYardagesRes = await PutYardages({ tee_deck_id: teeDeckId, yardages: teeDeckState.yardages }, true);

    if (putTeeDeckRes.status !== StatusCode.OK || putYardagesRes.status != StatusCode.OK) {
      props.uiActions.showError(t("secure.facility.settings.facility.tee_deck.002"));
      return;
    }

    setTeeDeckStateBeforeChanges(teeDeckState);

    history.push("/admin/settings/tee_deck");
  }

  async function deleteTeeDeck() {
    const putTeeDeckRes = await DeleteTeeDeck(
      {
        id: teeDeckState.id,
        title: teeDeckState.title,
        course_id: teeDeckState.course_id,
      },
      true,
    );

    if (putTeeDeckRes.status !== StatusCode.OK) {
      props.uiActions.showError(t("secure.facility.settings.facility.tee_deck.003"));
      return;
    }

    history.push("/admin/settings/tee_deck");
  }

  async function createYardages(division_id: number) {
    const yardagesRes = await PostYardages(
      {
        division_id: division_id,
        tee_deck_id: teeDeckState.id,
      },
      true,
    );

    if (yardagesRes.status !== StatusCode.OK) {
      props.uiActions.showError("Error creating yardages");
      return;
    }

    props.uiActions.showSuccess("Yardages created successfully");
    void loadTeeDeck();
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setTeeDeckState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleDropDownChange(value: any, property: string) {
    setTeeDeckState(prevState => ({ ...prevState, [property]: value }));
  }

  function unsavedChangesExist() {
    if (teeDeckStateBeforeChanges === undefined) {
      if (teeDeckLoaded && coursesLoaded) {
        setTeeDeckStateBeforeChanges(teeDeckState);
      }
      return false;
    }

    return (
      !isEqualWith(teeDeckStateBeforeChanges, teeDeckState, (originalValue, newValue) => {
        if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
          return true;
        }
      }) ||
      !isEqualWith(teeDeckStateBeforeChanges.yardages, teeDeckState.yardages, (originalValue, newValue) => {
        if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
          return true;
        }
      }) ||
      !isEqualWith(teeDeckStateBeforeChanges.ratings, teeDeckState.ratings, (originalValue, newValue) => {
        if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
          return true;
        }
      })
    );
  }

  function cancelUnsavedChanges() {
    setTeeDeckState(teeDeckStateBeforeChanges);
  }

  function handleYardageInputChange(event: any, divisionId: number, index: number) {
    const { value } = event.target;

    const yardages = [...teeDeckState.yardages]?.map((yardage: IYardage) => ({
      ...yardage,
      yards: yardage?.yards?.map((yard: number, i: number) =>
        i === index && yardage?.division_id === divisionId ? Number(value) : yard,
      ),
    }));

    // yardages.find((yardage: any) => yardage.division_id === divisionId).yards[index] = Number(value);

    setTeeDeckState(prevState => ({ ...prevState, yardages: yardages }));
  }

  function handleCourseRatingInputChange(event: any, id: number) {
    const { value } = event.target;

    const newRatings = [...teeDeckState.ratings]?.map((rating: ITeeDeckRating, i: number) => ({
      ...rating,
      course_rating: id === rating.id ? Number(value) : rating.course_rating,
    }));

    // newRatings.find((rating) => rating.id === id).course_rating = Number(value);

    setTeeDeckState(prevState => ({ ...prevState, ratings: newRatings }));
  }

  function handleSlopeRatingInputChange(event: any, id: number) {
    const { value } = event.target;

    const newRatings = [...teeDeckState.ratings]?.map((rating: ITeeDeckRating, i: number) => ({
      ...rating,
      slope_rating: id === rating.id ? Number(value) : rating.slope_rating,
    }));

    // newRatings.find((rating) => rating.id === id).slope_rating = Number(value);

    setTeeDeckState(prevState => ({ ...prevState, ratings: newRatings }));
  }

  return (
    <Page
      title={t("secure.facility.settings.facility.tee_deck.005")}
      subtitle={teeDeckState ? teeDeckState.title : ""}
      narrow
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.facility.tee_deck.006"),
          url: "/admin/settings/tee_deck",
        },
      ]}
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: saveTeeDeckState,
        onCancel: cancelUnsavedChanges,
      }}
    >
      {teeDeckState && (
        <Form>
          <Card>
            <Card.Section>
              <FormLayout>
                <FormLayout.Group>
                  <Input
                    value={teeDeckState.title}
                    label={t("secure.facility.settings.facility.tee_deck.008")}
                    id="title"
                    onChange={handleInputChange}
                    placeholder={t("secure.facility.settings.facility.tee_deck.009")}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  {teeDeckState.courseOptions?.length > 1 && (
                    <Select
                      defaultValue={teeDeckState.course_id}
                      label={t("secure.facility.settings.facility.tee_deck.010")}
                      onChange={(value: any) => handleDropDownChange(value, "course_id")}
                    >
                      {teeDeckState.courseOptions?.map((courseOption: any, index: number) => {
                        return (
                          <Option key={index} name={courseOption.label} value={courseOption.value}>
                            <span>{courseOption.label}</span>
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>
            {teeDeckState.yardages[0] ? (
              <Card.Section
                title={t("secure.facility.settings.facility.tee_deck.011")}
                sectionTitleActions={[
                  { content: t("secure.facility.settings.facility.tee_deck.012"), action: saveTeeDeckState },
                ]}
              >
                <div>
                  <table className="scoresheet-scorecard cell-spacing w-full">
                    <tbody>
                      <tr>
                        <th className="text-left">{t("secure.facility.settings.facility.tee_deck.013")}</th>

                        {teeDeckState.yardages[0].yards.map((yard: number, index: number) => {
                          return (
                            <th key={index} className="text-center">
                              {index + 1}
                            </th>
                          );
                        })}
                      </tr>
                      {teeDeckState.courses
                        ?.find((course: any) => course.id === teeDeckState.course_id)
                        .divisions.map((division: any, divisionIndex: number) => {
                          return (
                            <tr key={divisionIndex}>
                              <td className="text-left">{division.title}</td>

                              {teeDeckState.yardages.find((yardage: any) => yardage.division_id === division.id) ? (
                                teeDeckState.yardages
                                  .find((yardage: any) => yardage.division_id === division.id)
                                  ?.yards.map((yard: number, index: number) => {
                                    return (
                                      <td key={index} className="text-center">
                                        <input
                                          className="cell-input text-center"
                                          type="number"
                                          onChange={e => handleYardageInputChange(e, division.id, index)}
                                          value={yard ?? ""}
                                        />
                                      </td>
                                    );
                                  })
                              ) : (
                                <td colSpan={2}>
                                  <Button onClick={() => createYardages(division.id)} type="text">
                                    <FontAwesomeIcon icon={["far", "plus"]} className="mr-2" />
                                    Add Yards
                                  </Button>
                                </td>
                              )}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </Card.Section>
            ) : null}
            <Card.Section
              title={t("secure.facility.settings.facility.tee_deck.014")}
              sectionTitleActions={[
                { content: t("secure.facility.settings.facility.tee_deck.015"), action: saveTeeDeckState },
              ]}
            >
              <div>
                <table className="scoresheet-scorecard cell-spacing w-full">
                  <tbody>
                    <tr>
                      <th className="text-left">{t("secure.facility.settings.facility.tee_deck.016")}</th>
                      <th className="text-left">{t("secure.facility.settings.facility.tee_deck.017")}</th>
                      <th className="text-left">{t("secure.facility.settings.facility.tee_deck.018")}</th>
                      <th className="text-left">{t("secure.facility.settings.facility.tee_deck.019")}</th>
                    </tr>
                    {teeDeckState.ratings
                      .filter((rating: Record<string, any>) => {
                        const ratingDivision = teeDeckState.courses
                          ?.find((course: any) => course.id === teeDeckState.course_id)
                          .divisions.find((division: Record<string, any>) => division.id === rating.division_id);
                        if (ratingDivision) {
                          return true;
                        } else {
                          return false;
                        }
                      })
                      .map((rating: Record<string, any>, index: number) => {
                        return (
                          <tr key={index}>
                            <td className="text-left">
                              {
                                teeDeckState.courses
                                  ?.find((course: any) => course.id === teeDeckState.course_id)
                                  ?.divisions.find(
                                    (division: Record<string, any>) => division.id === rating.division_id,
                                  )?.title
                              }
                            </td>
                            <td className="text-left">{rating.gender.replace(/./, (c: string) => c.toUpperCase())}</td>
                            <td className="text-center">
                              <input
                                className="cell-input text-center"
                                type="number"
                                onChange={e => handleCourseRatingInputChange(e, rating.id)}
                                value={rating.course_rating ?? ""}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                className="cell-input text-center"
                                type="number"
                                onChange={e => handleSlopeRatingInputChange(e, rating.id)}
                                value={rating.slope_rating ?? ""}
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </Card.Section>
          </Card>
        </Form>
      )}
    </Page>
  );
}
