export interface IProductOptions {
  label: string;
  description?: string;
  url: string;
}

const productOptions: IProductOptions[] = [
  {
    label: "Products",
    description: "List of Products",
    url: "/admin/product",
  },
  {
    label: "Memberships",
    description: "List of Membership Options",
    url: "/admin/membership",
  },
  {
    label: "Tickets",
    description: "List of Ticket Options",
    url: "/admin/tickets",
  },
];

export default productOptions;
