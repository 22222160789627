import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ButtonNew as Button } from "components/buttonNew";

import "./callout.scss";

export type CalloutProps = {
  type: NoticeType;
  title: string;
  content?: string;
  actions?: NoticeActions;
  /** Displays X in top-right corner. */
  onClose?: () => void;
  /** Displays Save button to the slight left of onCLose position */
  onSave?: () => void;
  /** Overrides default styling:
   * 1) display ICON to be strictly info circle
   * 2) onClose button becomes 'DISMISS'
   * 3) disables border
   */
  toast?: boolean;
};

type ActionClick = {
  label?: string;
  onClick: () => void;
};

/** Primary action is given bolded text + right-arrow Icon */
type NoticeActions = {
  primary?: ActionClick;
  secondary?: ActionClick;
};

export type NoticeType = "primary" | "info" | "success" | "warning" | "error";

export default function Callout(props: CalloutProps) {
  const { type, title = "", content, onClose, onSave, actions, toast } = props;

  return (
    <div className={`notice-bar-container ${type} ${toast ? "is-toast" : ""}`}>
      <div className="notice-bar">
        <span className={`icon-wrap${!actions ? " flexed" : ""}`}>
          <GeneratedNoticeIcon noticeType={type} isToast={toast} />
        </span>
        <span className="content-wrap">
          <p className="title">{title}</p>
          {content && (
            <p className="content" style={!actions ? { paddingBottom: "0" } : undefined}>
              {content}
            </p>
          )}
          {actions && (
            <span className="action-wrap">
              {actions.secondary && (
                <button className="secondary" onClick={actions.secondary.onClick}>
                  {actions.secondary.label ?? "Learn More"}
                </button>
              )}
              {actions.primary && (
                <button className="primary" onClick={actions.primary.onClick}>
                  {actions.primary.label ?? "View Changes "} <FontAwesomeIcon size="sm" icon={["fas", "arrow-right"]} />
                </button>
              )}
            </span>
          )}
        </span>

        <span className={`close-wrap${!actions ? " flexed" : ""}`}>
          {onSave && (
            <Button onClick={onSave} className="notice-save" size="xsmall" type="tertiary">
              Save
            </Button>
          )}

          {onClose && (
            <Button onClick={onClose} className="notice-close" type="text" size="small">
              {toast ? "DISMISS" : <FontAwesomeIcon size="lg" icon={["far", "times"]} />}
            </Button>
          )}
        </span>
      </div>
    </div>
  );
}

const GeneratedNoticeIcon = ({ noticeType, isToast }: { noticeType: NoticeType; isToast: boolean }) => {
  if (isToast) {
    return <FontAwesomeIcon icon={["far", "info-circle"]} />;
  }

  switch (noticeType) {
    case "primary": {
      return <FontAwesomeIcon icon={["far", "info-circle"]} />;
    }
    case "info": {
      return <FontAwesomeIcon icon={["far", "info-circle"]} />;
    }
    case "success": {
      return <FontAwesomeIcon icon={["far", "circle-check"]} />;
    }
    case "error": {
      return <FontAwesomeIcon icon={["far", "info-circle"]} />;
    }
    case "warning": {
      return <FontAwesomeIcon icon={["far", "info-circle"]} />;
    }
  }
};
