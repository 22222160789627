import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import "./CustomerTeeTimeCard.scss";
import { useTranslation, Trans } from "react-i18next";
import { displayCurrency } from "helpers/Helpers";
import { Badge } from "components/badge/Badge";
import classNames from "classnames";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { TABLET_WIDTH } from "helpers/ScreenSizes";
import { CustomIcon } from "components/customIcon/CustomIcon";

interface IProps {
  time: string;
  isPrepaid: boolean;
  isShotgun: boolean;
  spotsRemaining: number;
  holes: number[];
  cartRule: string;
  nineHolePrice: number;
  eighteenHolePrice: number;
  onClick: () => void;
  courseName?: string;
  divisionTitle?: string;
  singleBookings?: string;
  startingHole?: string;
  isLeague?: boolean;
}

export function CustomerTeeTimeCard(props: IProps) {
  const {
    time,
    isPrepaid,
    isShotgun,
    spotsRemaining,
    holes,
    cartRule,
    nineHolePrice,
    eighteenHolePrice,
    onClick,
    courseName,
    divisionTitle,
    singleBookings,
    startingHole,
    isLeague,
  } = props;

  const windowSize = useWindowSize();

  const { t, i18n } = useTranslation();

  function convertCartRuleToIcon(cartRule: "none" | "optional" | "required") {
    if (cartRule === "none") {
      return "person-walking";
    } else if (cartRule === "optional" || cartRule === "required") {
      return "car-side";
    }
  }

  function convertCartRuleToDescription(cartRule: "none" | "optional" | "required") {
    if (cartRule === "none") {
      return t("components.customer_tee_time_card.customer_tee_time_card.004");
    } else if (cartRule === "optional") {
      return t("components.customer_tee_time_card.customer_tee_time_card.005");
    } else if (cartRule === "required") {
      return t("components.customer_tee_time_card.customer_tee_time_card.006");
    }
  }

  const teeTimeBadges = (mobile = false) => {
    return (
      <>
        {isLeague && (
          <div className={classNames("customer-tee-time-prepaid", { "customer-tee-time-prepaid-mobile": mobile })}>
            LEAGUE
          </div>
        )}
        {isPrepaid ? (
          <div className={classNames("customer-tee-time-prepaid", { "customer-tee-time-prepaid-mobile": mobile })}>
            {t("components.customer_tee_time_card.customer_tee_time_card.001")}
          </div>
        ) : null}
        {isShotgun ? (
          <>
            <div className={classNames("customer-tee-time-prepaid", { "customer-tee-time-prepaid-mobile": mobile })}>
              {"SHOTGUN"}
            </div>
            <div className={classNames("customer-tee-time-prepaid", { "customer-tee-time-prepaid-mobile": mobile })}>
              {startingHole}
            </div>
          </>
        ) : null}
      </>
    );
  };

  return (
    <div className="customer-tee-time" onClick={onClick}>
      <div className="customer-tee-time-content">
        <div className="customer-tee-time-details-container">
          <div className="customer-tee-time-primary-details">
            <div className="customer-tee-time-active-time">{time}</div>
            {teeTimeBadges()}
          </div>
          <div className="customer-tee-time-secondary-details">
            {courseName ? <p>{courseName}</p> : null}
            {divisionTitle ? <p>{divisionTitle}</p> : null}
          </div>
          <div
            className={classNames("customer-tee-time-primary-details customer-tee-time-badges-container", {
              "customer-tee-time-hide-badges-container": !(isLeague || isPrepaid || isShotgun),
            })}
          >
            {teeTimeBadges(true)}
          </div>
          <div className="customer-tee-time-tertiary-details">
            <div className="customer-tee-time-detail">
              {windowSize.width < TABLET_WIDTH ? (
                <Fragment>
                  <span>
                    {spotsRemaining === 1
                      ? `1 ${t("components.customer_tee_time_card.customer_tee_time_card.008")}`
                      : `${
                          singleBookings === "none" || (singleBookings === "allow_within_group" && spotsRemaining === 4)
                            ? "2"
                            : "1"
                        }
                  ${singleBookings === "none" && spotsRemaining <= 2 ? "" : `- ${spotsRemaining}`} ${t(
                          "components.customer_tee_time_card.customer_tee_time_card.009",
                        )}`}
                  </span>
                </Fragment>
              ) : (
                <Fragment>
                  <FontAwesomeIcon className="customer-tee-time-detail-icon" icon={["fas", "users"]} />
                  <span>
                    {spotsRemaining === 1
                      ? `1 ${t("components.customer_tee_time_card.customer_tee_time_card.008")}`
                      : `${
                          singleBookings === "none" || (singleBookings === "allow_within_group" && spotsRemaining === 4)
                            ? "2"
                            : "1"
                        }
                  ${singleBookings === "none" && spotsRemaining <= 2 ? "" : `- ${spotsRemaining}`} ${t(
                          "components.customer_tee_time_card.customer_tee_time_card.009",
                        )}`}
                  </span>
                </Fragment>
              )}
            </div>
            <div className="customer-tee-time-detail">
              {windowSize.width < TABLET_WIDTH ? (
                <Fragment>
                  <span>{`${holes.join(" / ")} ${t(
                    "components.customer_tee_time_card.customer_tee_time_card.007",
                  )}`}</span>
                </Fragment>
              ) : (
                <Fragment>
                  <FontAwesomeIcon className="customer-tee-time-detail-icon" icon={["fas", "golf-flag-hole"]} />
                  <span>{`${holes.join(" / ")} ${t(
                    "components.customer_tee_time_card.customer_tee_time_card.007",
                  )}`}</span>
                </Fragment>
              )}
            </div>

            {cartRule === "none" || cartRule === "optional" || cartRule === "required" ? (
              <div className="customer-tee-time-detail">
                {windowSize.width < TABLET_WIDTH ? (
                  <Fragment>
                    <span>{convertCartRuleToDescription(cartRule)}</span>
                  </Fragment>
                ) : (
                  <Fragment>
                    {cartRule === "none" && (
                      <FontAwesomeIcon className="customer-tee-time-detail-icon" icon={["fas", "person-walking"]} />
                    )}

                    <div className="mr-2">
                      {cartRule === "optional" || cartRule === "required" ? (
                        <CustomIcon colour="blue" src="../../../public/images/powercart.svg" size="medium" />
                      ) : null}
                    </div>

                    <span>{convertCartRuleToDescription(cartRule)}</span>
                  </Fragment>
                )}
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>

        <div className="customer-tee-time-pricing">
          <div className="customer-tee-time-price-container">
            {nineHolePrice != null && (
              <div className="holes-pricing-container">
                <p className="customer-tee-time-holes-text">9 Holes</p>
                <p className="customer-tee-time-price">{displayCurrency("cad", nineHolePrice)}</p>
              </div>
            )}
            {eighteenHolePrice != null && (
              <div className="holes-pricing-container">
                <p className="customer-tee-time-holes-text">18 Holes</p>
                <span className="customer-tee-time-price">{displayCurrency("cad", eighteenHolePrice)}</span>
              </div>
            )}
          </div>
          <button className="customer-tee-time-select-price">
            {t("components.customer_tee_time_card.customer_tee_time_card.002")}
          </button>
        </div>
      </div>
    </div>
  );
}
