import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Page from "components/page/Page";
import Card from "components/card/Card";
import Input from "components/form/input/Input";
import { StatusCode } from "api/protocols";
import { IShotgunTemplateGroup, PostShotGunTemplate, PutShotGunTemplateGenerate } from "api/rpc/teeSheet/template";
import { Select } from "components/select/index";
import { ButtonNew as Button } from "components/buttonNew/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IFacilityState } from "redux/reducers/facility";
import "../../facility/facility.scss";
import "./shotgunTemplate.scss";

import { useTranslation, Trans } from "react-i18next";
import { group } from "console";
import Checkbox from "components/form/checkbox/Checkbox";

interface IShotGunTemplate {
  title: string;
  holes: number;
  groups: IShotgunTemplateGroup[];
  groupsFormatted: IShotgunTemplateGroup[][];
  selectedHoles: any[];
  selectAllHoles: boolean;
}

interface IProps {
  facilityStore: IFacilityState;
}

export default function ShotGunTemplateNew(props: IProps) {
  const { facilityStore } = props;
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
  const { Option } = Select;
  //const facilityHoles = faciltyStore.
  const totalHoles = new Array(facilityStore?.facility?.holes);
  for (let i = 0; i < facilityStore?.facility?.holes; i++) {
    totalHoles[i] = i + 1;
  }

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [state, setState] = useState<IShotGunTemplate>({
    title: "",
    holes: 1,
    groups: [],
    groupsFormatted: [],
    selectedHoles: [],
    selectAllHoles: false,
  });

  async function generateGroups() {
    const groupsRes = await PutShotGunTemplateGenerate("holes=" + state.holes.toString(), true);
    if (groupsRes.status !== StatusCode.OK) {
      console.log(groupsRes.message);
      return;
    }

    const groupedArray = groupsRes.data.reduce(
      (formattedGroup: Array<Array<IShotgunTemplateGroup>>, group: IShotgunTemplateGroup) => {
        formattedGroup[group.group_index - 1] = formattedGroup[group.group_index - 1] || [];
        formattedGroup[group.group_index - 1].push(group);
        return formattedGroup;
      },
      [],
    );

    for (let i = 0; i < groupedArray.length; i++) {
      groupedArray[i].checked = false;
    }

    setState(prevState => ({ ...prevState, groups: groupsRes.data, groupsFormatted: groupedArray }));
  }

  async function saveNewShotGunTemplate() {
    if (state.title === "" || state.groups.length === 0) {
      return;
    }

    console.log(state);
    const res = await PostShotGunTemplate({ title: state.title, groups: state.groups }, true);
    if (res.status !== StatusCode.OK) {
      return;
    }

    console.log(res);
    history.push("/admin/settings/tee-sheet/shotgun-templates");
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleDropdownChange(value: number) {
    setState(prevState => ({ ...prevState, holes: value }));
  }

  function handleAddHole() {
    const currentGroupIndex: number = state.groups[state.groups.length - 1].group_index + 1;
    const groupA: IShotgunTemplateGroup = {
      group_index: currentGroupIndex,
      start_hole: currentGroupIndex.toString() + "A",
      start_time: "12:00",
    };
    const groupB: IShotgunTemplateGroup = {
      group_index: currentGroupIndex,
      start_hole: currentGroupIndex.toString() + "B",
      start_time: "12:00",
    };
    const updatedGroups = state.groups;
    updatedGroups.push(groupA);
    updatedGroups.push(groupB);

    const groupedArray = state.groupsFormatted;
    groupedArray.push([]);
    groupedArray[currentGroupIndex - 1].push(groupA);
    groupedArray[currentGroupIndex - 1].push(groupB);

    setState(prevState => ({ ...prevState, groups: updatedGroups, groupsFormatted: groupedArray }));
  }

  function handleRemoveHole() {
    const groupedArray: any = state.groupsFormatted;

    for (let i = 0; i < state.selectedHoles.length; i++) {
      groupedArray[state.selectedHoles[i]] = [];
      groupedArray[state.selectedHoles[i]].checked = false;
    }

    const currentGroups: IShotgunTemplateGroup[] = [].concat(...groupedArray);
    setState(prevState => ({
      ...prevState,
      groups: currentGroups,
      groupsFormatted: groupedArray,
      selectedHoles: [],
    }));
  }

  function handleAddGroup(holeNum: number) {
    if (state.groupsFormatted[holeNum - 1].length < alphabet.length && state.groupsFormatted[holeNum - 1].length < 4) {
      const currentAlphabet = alphabet;
      let groupLetter;

      state.groupsFormatted[holeNum - 1].forEach(group => {
        groupLetter = group.start_hole.substring(group.start_hole.length - 1);
        currentAlphabet.splice(currentAlphabet.indexOf(groupLetter), 1);
      });

      const currentGroupLetter = currentAlphabet[0];

      const newGroup: IShotgunTemplateGroup = {
        group_index: holeNum,
        start_hole: holeNum.toString() + currentGroupLetter,
        start_time: "12:00",
      };

      const groupedArray = state.groupsFormatted;

      groupedArray[holeNum - 1].push(newGroup);

      const currentGroups: IShotgunTemplateGroup[] = [].concat(...groupedArray);

      setState(prevState => ({ ...prevState, groups: currentGroups, groupsFormatted: groupedArray }));
    }
  }

  function handleRemoveGroup(holeNum: number) {
    const groupedArray = state.groupsFormatted;

    groupedArray[holeNum].pop();

    const currentGroups: IShotgunTemplateGroup[] = [].concat(...groupedArray);
    setState(prevState => ({ ...prevState, groups: currentGroups, groupsFormatted: groupedArray }));
  }

  const primaryAction = {
    content: t("secure.facility.settings.tee_sheets.shotgun_template.shotgun_template_new.001"),
    action: saveNewShotGunTemplate,
  };

  function handleSelectAllHoles(event: any) {
    const { checked } = event.target;

    const groupedArray: any = state.groupsFormatted;

    if (checked) {
      for (let i = 0; i < groupedArray.length; i++) {
        groupedArray[i].checked = true;
      }
    } else {
      for (let i = 0; i < groupedArray.length; i++) {
        groupedArray[i].checked = false;
      }
    }

    setState(prevState => ({ ...prevState, selectAllHoles: !state.selectAllHoles, groupsFormatted: groupedArray }));
  }

  function handleHoleSelectChange(event: any, hole: any, holeNum: any) {
    // setState((prevState) => ({ ...prevState, selectAllHoles: !state.selectAllHoles }));
    const { checked } = event.target;
    const selectedHoles = state.selectedHoles;

    if (checked) {
      selectedHoles.push(holeNum);
    } else {
      const index = selectedHoles.indexOf(holeNum);
      if (index > -1) {
        selectedHoles.splice(index, 1);
      }
    }

    const groupedArray: any = state.groupsFormatted;

    groupedArray[holeNum].checked = !groupedArray[holeNum].checked;

    setState(prevState => ({ ...prevState, groupsFormatted: groupedArray }));
  }

  return (
    <Page
      title={t("secure.facility.settings.tee_sheets.shotgun_template.shotgun_template_new.002")}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.tee_sheets.shotgun_template.shotgun_template_new.003"),
          url: "/admin/settings/tee-sheet/shotgun-templates",
        },
      ]}
    >
      <Card>
        <Card.Section>
          <div className="flex justify-between">
            <div style={{ width: "75%" }}>
              <Input
                value={state.title}
                label={t("secure.facility.settings.tee_sheets.shotgun_template.shotgun_template_new.004")}
                id="title"
                onChange={handleInputChange}
                placeholder={t("secure.facility.settings.tee_sheets.shotgun_template.shotgun_template_new.005")}
              />
            </div>

            <div className="">
              <Select
                label={t("secure.facility.settings.tee_sheets.shotgun_template.shotgun_template_new.006")}
                onChange={handleDropdownChange}
                defaultValue={state.holes}
              >
                {totalHoles.map((hole: number, index: number) => {
                  return (
                    <Option key={index} value={hole}>
                      <span>{hole}</span>
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>

          <div className="mt-4" style={{ display: "flex", justifyContent: "end" }}>
            <Button type="primary" onClick={generateGroups}>
              <span>{t("secure.facility.settings.tee_sheets.shotgun_template.shotgun_template_new.007")}</span>
            </Button>
          </div>
        </Card.Section>
      </Card>

      {state.groupsFormatted.length >= 1 && (
        <div className="mt-4">
          {/* <div className="shotgun-template_table_header_container">
    <h1>Shotgun</h1>
    <p>Customize shotgun template</p>
  </div> */}

          <table className="ui-table shotgun-template_table">
            <thead>
              <tr className="">
                <td style={{ width: "15%" }}>
                  <div className="flex">
                    <Checkbox size="medium" value={1} onChange={handleSelectAllHoles} checked={state.selectAllHoles} />
                    {t("secure.facility.settings.tee_sheets.shotgun_template.shotgun_template_new.008")}
                  </div>
                </td>
                <td>{t("secure.facility.settings.tee_sheets.shotgun_template.shotgun_template_new.009")}</td>
                <td style={{ width: "15%" }}></td>
                <td style={{ width: "20%", textAlign: "center" }}>
                  <div title="Add group" onClick={handleRemoveHole} className="shotgun-template_add_button">
                    Delete &nbsp; <FontAwesomeIcon icon={["fas", "minus"]} />
                  </div>
                </td>
              </tr>
            </thead>
            <tbody className="shotgun-template_table_body">
              {state.groupsFormatted?.map((hole: any, holeNum: number) => {
                return (
                  <tr key={holeNum}>
                    <td style={{ borderBottomStyle: "none", padding: "0px 24px" }}>
                      <div className="flex items-center gap-4">
                        <div className="flex">
                          <Checkbox
                            size="medium"
                            value={1}
                            onChange={event => handleHoleSelectChange(event, hole, holeNum)}
                            checked={hole.checked}
                          />
                        </div>

                        <div className="shotgun-template_table_text">{holeNum + 1}</div>
                      </div>
                    </td>

                    <td
                      className="shotgun-template_table_body_holes_container"
                      style={{
                        borderBottomStyle: "none",
                        padding: "0px 24px",
                      }}
                    >
                      {hole.map((group: IShotgunTemplateGroup, index: number) => {
                        return (
                          <div key={index} className="shotgun-template_table_body_holes">
                            <div>{group.start_hole.substring(group.start_hole.length - 1)}</div>

                            {/* <div title="Delete group" className="shotgun-template_trash_icon">
                      <FontAwesomeIcon
                        onClick={() =>
                          handleRemoveGroup(
                            holeNum + 1,
                            group.start_hole.substring(group.start_hole.length - 1),
                          )
                        }
                        icon={["fal", "trash-can"]}
                      />

                    </div> */}
                          </div>
                        );
                      })}
                    </td>

                    <td style={{ borderBottomStyle: "none", padding: "0px 0px" }}>
                      {state.groupsFormatted[holeNum].length < alphabet.length && (
                        <div className="shotgun-template_add_button">
                          <Button onClick={() => handleAddGroup(holeNum + 1)} type="text" size="small">
                            <FontAwesomeIcon icon={["fas", "plus"]} /> &nbsp; Add group
                          </Button>
                        </div>
                      )}
                    </td>

                    <td style={{ borderBottomStyle: "none", padding: "0px 0px" }}>
                      {state.groupsFormatted[holeNum].length < alphabet.length && (
                        <div className="shotgun-template_add_button">
                          <Button
                            onClick={() => handleRemoveGroup(holeNum)}
                            type="text"
                            size="small"
                            disabled={hole.length === 0}
                          >
                            <FontAwesomeIcon icon={["fas", "minus"]} /> &nbsp; Remove group
                          </Button>
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {state.groupsFormatted.length < 18 && (
            <div title="Add hole" className="shotgun-template_add_button mt-3" style={{ justifyContent: "left" }}>
              <Button onClick={handleAddHole} type="text" size="small">
                <FontAwesomeIcon icon={["fas", "plus"]} /> &nbsp; Add hole
              </Button>
            </div>
          )}
        </div>
      )}
    </Page>
  );
}
