import React, { useEffect, useState } from "react";
import axios, { CancelToken } from "axios";
import { useTranslation } from "react-i18next";
import { isEqualWith, isNull } from "lodash";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { StatusCode } from "api/protocols";
import { GetCountry } from "api/rpc/2022-09/common/country/country";
import { DeleteCustomer, GetCustomerType, SendPasswordReset } from "api/rpc/2022-09/clientAdmin/customer/customer";
import {
  DeleteCustomerAddressLine,
  PostCustomerAddressLine,
  PutCustomerAddressLine,
} from "api/rpc/clientAdmin/customer/customer";
import { PutCustomer, PutCustomerPreferences } from "api/rpc/2024-04/clientAdmin/customer/customer";

import { showError, showSuccess } from "redux/actions/ui";
import { IAddress, ICustomerPreferences } from "redux/reducers/models/customer";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useCustomerClientContext } from "../../context/CustomerClientContext";

import Sheet from "components/sheet/Sheet";
import PreferenceCard from "components/preferenceCard/PreferenceCard";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import { Select } from "components/select/index";
import { ButtonNew as Button } from "components/buttonNew";
import { Badge } from "components/badge/Badge";
import Popup from "components/popup/Popup";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import CustomerAddressModal, { IAddressLineState } from "elements/customer/CustomerAddressModal";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { MOBILE_WIDTH } from "helpers/ScreenSizes";
import "./CustomerProfile.scss";
import ReactDOM from "react-dom";
import Page from "components/page/Page";

export default function CustomerProfile() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { Option } = Select;
  const windowSize = useWindowSize();

  const { authStore, facilityStore } = useAppSelector(store => store);
  const dispatch = useAppDispatch();

  const { state, updateState } = useCustomerClientContext(); // changes seen across customer context

  const [deleteCustomerConfirmation, setDeleteCustomerConfirmation] = useState(false);
  const [deleteAddressConfirmation, setDeleteAddressConfirmation] = useState({
    visible: false,
    address: null,
  });
  const [resetPasswordVisible, setResetPasswordVisible] = useState(false);
  const [addressModalOpen, setAddressModalOpen] = useState(false);
  const [customerTypesOpen, setCustomerTypesOpen] = useState(false);
  const [cartTypesOpen, setCartTypesOpen] = useState(false);

  const [customerProfileStateBeforeChanges, setCustomerProfileStateBeforeChanges] = useState(undefined);
  const [customerProfileLoaded, setCustomerProfileLoaded] = useState<boolean>(false);

  // Populate Profile Dropdowns
  useEffect(() => {
    const source = axios.CancelToken.source();

    if (state.countries === null) {
      void GetCountries(source.token);
    }

    if (state.customerTypes === null) {
      void GetCustomerTypes(source.token);
    }

    if (state.cartTypes === null) {
      void GetCustomerCartTypes(source.token);
    }

    setCustomerProfileLoaded(true);

    return () => {
      source.cancel();
    };
  }, [state.customer.id]);

  // Save EditState and update context
  async function saveCustomer() {
    const customerRes = await PutCustomer(
      {
        id: state.editState.id,
        first_name: state.editState.first_name,
        last_name: state.editState.last_name,
        email: state.editState.email,
        phone: state.editState.phone,
        customer_type_id: state.editState.customer_type_id,
        cart_customer_type_id: state.editState.cart_customer_type_id,
        member_code: state.editState.member_code,
        username: state.editState.username,
        birth_date: state.editState.birth_date,
        bag_number: state.editState.bag_number,
        home_facility_id: state.editState.home_facility_id,
      },
      true,
    );

    if (
      !isEqualWith(state.customer?.address_lines, state.editState?.address_lines, (originalValue, newValue) => {
        if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
          return true;
        }
      })
    ) {
      for (const addressLine of state.editState?.address_lines) {
        const addressLineRes = await PutCustomerAddressLine(
          {
            id: addressLine?.id,
            customer_id: state.customer?.id,
            country_id: addressLine?.country_id,
            province_id: addressLine?.province_id,
            city: addressLine?.city,
            default: addressLine?.default,
            address_line_1: addressLine?.address_line_1,
            address_line_2: addressLine?.address_line_2,
            postal: addressLine?.postal,
            phone: addressLine?.phone,
          },
          true,
        );
        if (addressLineRes.status !== StatusCode.OK) {
          dispatch(showError("Error updating address line"));
          return;
        }
      }
    }

    if (customerRes.status !== StatusCode.OK) {
      dispatch(showError("Error updating customer profile"));
      return;
    }

    dispatch(showSuccess("Successfully updated customer profile"));

    updateState({
      customer: { ...state.customer, ...customerRes.data },
      editState: { ...state.editState, ...customerRes.data },
    });

    setCustomerProfileStateBeforeChanges({ ...state.editState, ...customerRes.data });
  }

  /** Called after POPUP to delete the customer and navigate to /admin/customers */
  async function deleteCustomer(customerId: number) {
    const deleteRes = await DeleteCustomer({ id: customerId }, true);

    if (deleteRes.status !== StatusCode.OK) {
      dispatch(showSuccess("Error deleting customer"));
      return;
    }

    setDeleteCustomerConfirmation(false);
    dispatch(showSuccess("Successfully deleted customer"));
    history.push("/admin/customers");
  }

  /** Called after POPUP to reset customer password */
  async function sendPasswordReset() {
    const params = {
      email: state.customer.email,
    };

    const sendPasswordResetRes = await SendPasswordReset(params, true);

    if (sendPasswordResetRes?.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.customer.tabs.profile.Customer_Profile.043")));
      return;
    }

    setResetPasswordVisible(false);
    dispatch(showSuccess(t("secure.facility.customer.tabs.profile.Customer_Profile.044")));
  }

  async function GetCountries(token: CancelToken) {
    const countryRes = await GetCountry({ provinces: true }, false, token);

    if (token.reason) {
      return;
    }

    if (countryRes.status !== StatusCode.OK) {
      dispatch(showError("Error getting countries"));
      return;
    }

    updateState({ countries: countryRes.data });
  }

  async function GetCustomerTypes(token: CancelToken) {
    const typesRes = await GetCustomerType(
      {
        application: "green_fee",
      },
      false,
      token,
    );

    if (token.reason) {
      return;
    }

    if (typesRes.status !== StatusCode.OK) {
      dispatch(showError("Error getting customer types"));
      return;
    }

    updateState({ customerTypes: typesRes.data });
  }

  async function GetCustomerCartTypes(token: CancelToken) {
    const typesRes = await GetCustomerType(
      {
        application: "power_cart",
      },
      false,
      token,
    );

    if (token.reason) {
      return;
    }

    if (typesRes.status !== StatusCode.OK) {
      dispatch(showError("Error getting cart types"));
      return;
    }

    updateState({ cartTypes: typesRes.data });
  }

  async function addAddressLine(addressLineState: IAddressLineState) {
    const addressLineRes = await PostCustomerAddressLine(
      {
        customer_id: state.customer.id,
        country_id: addressLineState?.country_id,
        province_id: addressLineState?.province_id,
        city: addressLineState?.city,
        default: addressLineState?.default,
        address_line_1: addressLineState?.addressLine1,
        address_line_2: addressLineState?.addressLine2,
        postal: addressLineState?.postal,
        phone: addressLineState?.phone,
      },
      true,
    );
    if (addressLineRes.status !== StatusCode.OK) {
      dispatch(showError("Error adding address line"));
      return;
    }

    dispatch(showSuccess("Successfully added address line"));

    updateState({ editState: { ...state.editState, ...addressLineRes.data } });
    setAddressModalOpen(false);
  }

  async function handleDefaultAddress(addressLine: IAddress) {
    const addressLineRes = await PutCustomerAddressLine(
      {
        id: addressLine?.id,
        customer_id: state.customer?.id,
        country_id: addressLine?.country_id,
        province_id: addressLine?.province_id,
        city: addressLine?.city,
        default: !addressLine?.default,
        address_line_1: addressLine?.address_line_1,
        address_line_2: addressLine?.address_line_2,
        postal: addressLine?.postal,
        phone: addressLine?.phone,
      },
      true,
    );
    if (addressLineRes.status !== StatusCode.OK) {
      dispatch(showError("Error updating address line"));
      return;
    }

    updateState({
      customer: { ...state.customer, ...addressLineRes.data },
      editState: { ...state.editState, ...addressLineRes.data },
    }); // Update UI
  }

  async function deleteCustomerAddress(addressLine: IAddress) {
    const res = await DeleteCustomerAddressLine(
      { customer_id: state.customer.id, address_line_id: addressLine.id },
      true,
    );

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error removing customer address."));
      return;
    }

    updateState({
      customer: { ...state.customer, ...res.data },
      editState: { ...state.editState, ...res.data },
    });

    dispatch(showSuccess("Address has been successfully removed.")); // TODO: Translation
    setDeleteAddressConfirmation({ visible: false, address: null });
  }

  function addressLineInputHandler(event: React.ChangeEvent<HTMLInputElement>, index: number, id: string) {
    const { value } = event.target;
    const updatedAddressLines = state.editState.address_lines ? [...state.editState.address_lines] : [];
    updatedAddressLines[index] = { ...updatedAddressLines[index], [id]: value };

    updateState({
      editState: {
        ...state.editState,
        address_lines: updatedAddressLines,
      },
    });
  }

  function addressLineDropdownChange(value: number, id: string, index: number) {
    const updatedAddressLines = state.editState.address_lines ? [...state.editState.address_lines] : [];
    updatedAddressLines[index] = { ...updatedAddressLines[index], [id]: value };

    updateState({
      editState: {
        ...state.editState,
        address_lines: updatedAddressLines,
      },
    });
  }

  function inputHandler(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;
    updateState({ editState: { ...state.editState, [id]: value } });
  }

  function handleToggleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, checked } = event.target;
    updateState({ preferences: { ...state.preferences, [id]: checked } });
  }

  function handleHomeCourseSelect(home_course_id: number) {
    const updatedState = {
      ...state.editState,
      home_facility_id: home_course_id,
    };

    updateState({ editState: updatedState });
  }

  function renderSaveChanges(screenSize: "desktop" | "mobile") {
    return (
      <Button type="primary" onClick={saveCustomer} className={`customer-profile-save-changes-${screenSize}`}>
        {t("secure.facility.customer.tabs.profile.Customer_Profile.002")}
      </Button>
    );
  }

  function renderAddAddress(screenSize: "desktop" | "mobile") {
    const isDesktop = windowSize.width > MOBILE_WIDTH;

    const addAddress = (
      <Button
        className={`customer-profile-action customer-profile-add-address-${screenSize}`}
        size="medium"
        type="link"
        onClick={() => setAddressModalOpen(true)}
      >
        {t("secure.facility.customer.tabs.profile.Customer_Profile.028")}
      </Button>
    );

    if (screenSize === "desktop" && isDesktop) {
      return <>{addAddress}</>;
    } else if (screenSize === "mobile" && !isDesktop) {
      return <FormLayout.Group>{addAddress}</FormLayout.Group>;
    } else {
      return <></>;
    }
  }

  function handleDateChange(selectedDate: Date) {
    const updatedState = {
      ...state.editState,
      birth_date: selectedDate,
    };

    updateState({ editState: updatedState });
  }

  function unsavedChangesExist() {
    if (customerProfileStateBeforeChanges === undefined) {
      if (customerProfileLoaded) {
        setCustomerProfileStateBeforeChanges(state.editState);
      }
      return false;
    }

    return !isEqualWith(customerProfileStateBeforeChanges, state.editState, (originalValue, newValue) => {
      if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
        return true;
      }
    });
  }

  function cancelUnsavedChanges() {
    updateState({
      editState: { ...state.editState, ...customerProfileStateBeforeChanges },
    });
  }

  return (
    <>
      <Page
        notificationBarProps={{
          isVisible: unsavedChangesExist(),
          onAction: saveCustomer,
          onCancel: cancelUnsavedChanges,
        }}
      >
        <div className="mb-5">
          <span className="flex mt-2">
            <h1 className="ui-customer_page-header">
              {t("secure.facility.customer.tabs.profile.Customer_Profile.001")}
            </h1>
            {/* {renderSaveChanges("desktop")} */}
          </span>
          <hr className="customer-profile-header-divider" />
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={state.editState.first_name || ""}
                  label={t("secure.facility.customer.tabs.profile.Customer_Profile.003")}
                  id="first_name"
                  onChange={inputHandler}
                />
                <Input
                  value={state.editState.last_name || ""}
                  label={t("secure.facility.customer.tabs.profile.Customer_Profile.004")}
                  id="last_name"
                  onChange={inputHandler}
                />
              </FormLayout.Group>

              <FormLayout.Group>
                <Input
                  value={state.editState.phone || ""}
                  label={t("secure.facility.customer.tabs.profile.Customer_Profile.005")}
                  id="phone"
                  onChange={inputHandler}
                />
                <Input
                  value={state.editState.email || ""}
                  label={t("secure.facility.customer.tabs.profile.Customer_Profile.006")}
                  id="email"
                  onChange={inputHandler}
                />
              </FormLayout.Group>

              <div className="customer-profile-mobile-adaptive-fields">
                <Input
                  value={state.editState?.username || ""}
                  label={t("secure.facility.customer.tabs.profile.Customer_Profile.040")}
                  id="username"
                  onChange={inputHandler}
                />
                <div className="password-input">
                  <Input
                    value={"**********"}
                    type="password"
                    label={t("secure.facility.customer.tabs.profile.Customer_Profile.041")}
                    disabled={true}
                    className="password-input-input"
                  />
                  <span className="password-input-reset">
                    <button onClick={() => setResetPasswordVisible(true)} type="button">
                      {t("secure.facility.customer.tabs.profile.Customer_Profile.042")}
                    </button>
                  </span>
                </div>
              </div>

              {renderAddAddress("mobile")}

              <div className="customer-profile-mobile-adaptive-fields-triple">
                {!customerTypesOpen ? (
                  <div className="customer-type-group">
                    <span className="text-sm text-semibold">
                      {t("secure.facility.customer.tabs.profile.Customer_Profile.007")}
                    </span>
                    <div className="customer-type-group-content">
                      {state?.customer?.customer_type ? (
                        <span>{state.customer?.customer_type}</span>
                      ) : (
                        <span>{t("secure.facility.customer.tabs.profile.Customer_Profile.008")}</span>
                      )}
                      {!!authStore?.user?.permissions?.user_edit_customer_type && (
                        <FontAwesomeIcon
                          icon={["far", "pen"]}
                          className="customer-type-group-content-edit-icon"
                          onClick={() => setCustomerTypesOpen(!customerTypesOpen)}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <Select
                    label={t("secure.facility.customer.tabs.profile.Customer_Profile.010")}
                    onChange={(value: number) =>
                      updateState({ editState: { ...state.editState, customer_type_id: value } })
                    }
                    defaultValue={state.editState?.customer_type_id}
                    disabled={!authStore?.user?.permissions?.user_edit_customer_type ? true : false}
                  >
                    {state?.customerTypes?.map((type, index) => {
                      return (
                        <Option key={index} value={type.id} name={type.full_title}>
                          {type.full_title}
                        </Option>
                      );
                    })}
                  </Select>
                )}

                {!cartTypesOpen ? (
                  <div className="customer-type-group">
                    <span className="text-sm text-semibold">
                      {t("secure.facility.customer.tabs.profile.Customer_Profile.011")}
                    </span>
                    <div className="customer-type-group-content">
                      {state.customer?.cart_customer_type ? (
                        <span>{state.customer?.cart_customer_type}</span>
                      ) : (
                        <span>Unavailable</span>
                      )}
                      {!!authStore?.user?.permissions?.user_edit_customer_type && (
                        <FontAwesomeIcon
                          icon={["far", "pen"]}
                          className="customer-type-group-content-edit-icon"
                          onClick={() => setCartTypesOpen(!cartTypesOpen)}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <Select
                    label={t("secure.facility.customer.tabs.profile.Customer_Profile.014")}
                    onChange={(value: number) =>
                      updateState({ editState: { ...state.editState, cart_customer_type_id: value } })
                    }
                    defaultValue={state.editState?.cart_customer_type_id}
                    disabled={!authStore?.user?.permissions?.user_edit_customer_type ? true : false}
                  >
                    {state?.cartTypes.map((type, index) => {
                      return (
                        <Option key={index} value={type.id} name={type.full_title}>
                          {type.full_title}
                        </Option>
                      );
                    })}
                  </Select>
                )}

                {state.editState?.birth_date ? (
                  <div className="birthday-input">
                    <DatePickerInput
                      months={1}
                      position={windowSize.width < MOBILE_WIDTH ? "left" : "right"}
                      label="Birthday"
                      startingDate={new Date(state.editState?.birth_date as unknown as string)}
                      setStartingDate={(e: Date) => handleDateChange(e)}
                    />
                    <div className="birthday-cancel">
                      <FontAwesomeIcon
                        className="birthday-cancel-icon"
                        icon={["far", "times"]}
                        onClick={() => updateState({ editState: { ...state.editState, birth_date: null } })}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="birthday-toggle">
                    <span>
                      <Button
                        onClick={() =>
                          updateState({
                            editState: { ...state.editState, birth_date: state.customer.birth_date ?? new Date() },
                          })
                        }
                      >
                        Set Birthdate
                      </Button>
                    </span>
                  </div>
                )}
              </div>
              {console.log("Facility Store", facilityStore)}
              <FormLayout.Group>
                <Input
                  value={state.editState.member_code || ""}
                  label={t("secure.facility.customer.tabs.profile.Customer_Profile.015")}
                  id="member_code"
                  onChange={inputHandler}
                  placeholder=""
                />

                <Input
                  label={t("secure.facility.customer.tabs.profile.Customer_Profile.016")}
                  id="bag_number"
                  placeholder="513"
                  onChange={inputHandler}
                  value={state.editState.bag_number || ""}
                />

                <Input
                  label={t("secure.facility.customer.tabs.profile.Customer_Profile.017")}
                  id=""
                  placeholder="628"
                />
              </FormLayout.Group>

              <FormLayout.Group>
                <Select
                  label="Home Course"
                  defaultValue={state.customer?.home_facility_id === null ? null : state.customer?.home_facility_id}
                  onChange={(selected_course_id: number) => handleHomeCourseSelect(selected_course_id)}
                >
                  {facilityStore.facilities.map(facility => {
                    return (
                      <Option key={facility.id} value={facility.id} name={facility.full_name}>
                        <span>{facility.long_name}</span>
                      </Option>
                    );
                  })}
                </Select>
              </FormLayout.Group>
            </FormLayout>
          </Form>

          {state.editState?.address_lines?.map((addressLine, index) => {
            return (
              <React.Fragment key={index}>
                <div className="customer-default-address-container">
                  <div>
                    <h1 style={{ display: "inline" }} className="mr-4 ui-customer_page-header">{`Address ${
                      index + 1
                    }`}</h1>
                    {addressLine?.default && (
                      <Badge size="medium" type="gray">
                        {t("secure.facility.customer.tabs.profile.Customer_Profile.018")}
                      </Badge>
                    )}
                  </div>
                  <div className="ui-customer_address-actions">
                    <div>
                      <Button size="medium" type="link" onClick={() => handleDefaultAddress(addressLine)}>
                        {!addressLine?.default
                          ? t("secure.facility.customer.tabs.profile.Customer_Profile.019")
                          : t("secure.facility.customer.tabs.profile.Customer_Profile.020")}
                      </Button>
                    </div>
                    <Button
                      size="medium"
                      type="link"
                      onClick={() => setDeleteAddressConfirmation({ visible: true, address: addressLine })}
                    >
                      Remove
                    </Button>
                  </div>
                </div>

                <Form>
                  <FormLayout>
                    <FormLayout.Group>
                      <Input
                        value={addressLine?.address_line_1 || ""}
                        label={t("secure.facility.customer.tabs.profile.Customer_Profile.021")}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          addressLineInputHandler(event, index, "address_line_1")
                        }
                      />

                      <Input
                        value={addressLine?.address_line_2 || ""}
                        label={t("secure.facility.customer.tabs.profile.Customer_Profile.022")}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          addressLineInputHandler(event, index, "address_line_2")
                        }
                      />
                    </FormLayout.Group>

                    <FormLayout.Group>
                      <Input
                        value={addressLine?.city || ""}
                        label={t("secure.facility.customer.tabs.profile.Customer_Profile.023")}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          addressLineInputHandler(event, index, "city")
                        }
                      />

                      <Input
                        value={addressLine?.postal || ""}
                        label={t("secure.facility.customer.tabs.profile.Customer_Profile.024")}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          addressLineInputHandler(event, index, "postal")
                        }
                      />

                      <Input
                        value={addressLine?.phone || ""}
                        label={t("secure.facility.customer.tabs.profile.Customer_Profile.025")}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          addressLineInputHandler(event, index, "phone")
                        }
                      />
                    </FormLayout.Group>

                    <FormLayout.Group>
                      {state.countries && (
                        <Select
                          label={t("secure.facility.customer.tabs.profile.Customer_Profile.026")}
                          onChange={(value: number) => addressLineDropdownChange(value, "country_id", index)}
                          defaultValue={addressLine?.country_id}
                        >
                          {state?.countries?.map((country, index) => {
                            return (
                              <Option key={index} value={country?.id} name={country?.name}>
                                {country?.name}
                              </Option>
                            );
                          })}
                        </Select>
                      )}

                      {state?.countries?.some(country => country?.id === addressLine?.country_id) && (
                        <Select
                          label={t("secure.facility.customer.tabs.profile.Customer_Profile.027")}
                          onChange={(value: number) => addressLineDropdownChange(value, "province_id", index)}
                          defaultValue={addressLine?.province_id}
                        >
                          {state.countries
                            ?.find(country => country?.id === addressLine?.country_id)
                            ?.provinces?.map((province: Record<string, any>, index: number) => {
                              return (
                                <Option key={index} value={province?.id} name={province?.name}>
                                  {province?.name}
                                </Option>
                              );
                            })}
                        </Select>
                      )}
                    </FormLayout.Group>
                  </FormLayout>
                </Form>
              </React.Fragment>
            );
          })}

          <div className="customer-profile-actions">
            <div>{renderAddAddress("desktop")}</div>
            <div>
              {/*<Button className="customer-profile-action" size="medium" type="link" onClick={() => {}}>
              <FontAwesomeIcon
                className="mr-2"
                icon={["far", "paper-plane"]}
              />
              Send welcome email
            </Button>*/}
            </div>
          </div>

          <div className="customer-profile-delete-customer">{renderSaveChanges("mobile")}</div>
          <div className="customer-profile-delete-customer">
            <Button type="secondary" onClick={() => setDeleteCustomerConfirmation(true)}>
              {t("secure.facility.customer.tabs.profile.Customer_Profile.063")}
            </Button>
          </div>
        </div>
      </Page>

      {addressModalOpen && (
        <CustomerAddressModal
          isVisible={addressModalOpen}
          countries={state.countries}
          onCancel={() => setAddressModalOpen(false)}
          onOk={addressLine => addAddressLine(addressLine)}
        />
      )}

      {/* DELETE CUSTOMER POPUP */}
      <Popup
        open={deleteCustomerConfirmation}
        description={`Are you sure you want to delete ${state.customer?.full_name}?`} // TODO: Translation
        title="Delete Customer" // TODO: Translation
        type="warning"
        onCancel={() => setDeleteCustomerConfirmation(false)}
        onOk={() => deleteCustomer(state.customer.id)}
      />

      {/* RESET PASSWORD POPUP */}
      <Popup
        open={resetPasswordVisible}
        description={`${t("secure.facility.customer.tabs.profile.Customer_Profile.045")}`}
        title={t("secure.facility.customer.tabs.profile.Customer_Profile.046")}
        type="info"
        onCancel={() => setResetPasswordVisible(false)}
        onOk={sendPasswordReset}
      />

      {/* DELETE ADDRESS POPUP */}
      <Popup
        open={deleteAddressConfirmation.visible}
        description={`Are you sure you want to remove this address from ${state.customer.full_name}'s profile?`} // TODO: Translation
        title="Delete Customer Address" // TODO: Translation
        type="warning"
        onCancel={() => setDeleteAddressConfirmation({ visible: false, address: null })}
        onOk={() => deleteCustomerAddress(deleteAddressConfirmation.address)}
      />
    </>
  );
}
