import React, { ChangeEvent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { TFunction, useTranslation } from "react-i18next";
import axios from "axios";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { TPutProduct } from "api/rpc/2024-04/facilityAdmin/product/product";

import { IVariant, TComboVariants, TProductVendor } from "redux/reducers/models/product";
import { TModifierGroupExtended, TTaxLines } from "redux/reducers/models/facility";

import { useAppSelector } from "hooks/redux";
import useModal from "hooks/modals/useModal";
import {
  displayCurrency,
  displayPercent,
  handleChangeEventInput,
  isEmpty,
  isWholeNumber,
  numberIsValid,
  roundNumber,
  unsavedChangesExist,
} from "helpers/Helpers";
import { LocaleCurrency } from "helpers/Locale";
import useEditProduct from "./useEditProduct";

import { negativeCheckHelper } from "../../settings/floorPlan/floorPlanEdit/FloorPlanEdit";
import Page from "components/page/Page";
import Card from "components/card/Card";
import ColorPicker from "components/colorpicker/color-picker";
import Popup from "components/popup/Popup";
import Sheet from "components/sheet/Sheet";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import { Select } from "components/select/";
import { ButtonNew } from "components/buttonNew";
import TextEditor from "components/textEditor/textEditor";
import Checkbox from "components/form/checkbox/Checkbox";
import SponsorList from "components/sponsorList/SponsorList";
import Search from "components/search/Search";
import Spin from "components/spin/spin";
import Stack from "components/stack/Stack";
import Tabs from "components/tabs/Tabs";
import Toggle from "components/form/toggle/Toggle";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import TimePick from "components/timePick/TimePick";
import { Badge } from "components/badge/Badge";
import AttachProductAsComboModal from "../modals/AttachProductAsComboModal";
import Portal from "elements/Portal";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";
import ProductAvailabilityWarning from "./ProductAvailabilityWarning";
import UpdateProductAsComboModal from "../modals/UpdateProductAsComboModal";
import TagInputSelect from "components/tagInputSelect/TagInputSelect";

import "./productEditTable.scss";
import FacilityInventoryTable from "./FacilityInventoryTable";
import ModifierGroup from "../../settings/modifierGroup/ModifierGroup";

export default function ProductEdit() {
  const { t } = useTranslation();
  const history = useHistory();
  const { productId } = useParams<{ productId: string }>();

  const { authStore, facilityStore } = useAppSelector(store => store);

  const {
    /** Editable value */
    product,
    setProduct,

    /** Readable value */
    productPreserved,

    labelVariants,
    setLabelVariants,

    forms,
    setForms,

    comboProducts,
    productModifiers,

    availableAtDate,
    setAvailableAtDate,

    availableUntilDate,
    setAvailableUntilDate,

    productImages,
    modifierGroups,
    accountingReferences,
    salesChannels,
    departments,
    vendors,
    tickets,
    productTypes,
    taxlines,
    kitchenLocations,

    // Validation
    attempted,
    validated,

    // ASYNC functions
    saveProduct,
    attachProductToFacility,
    detachProductFromfacility,
    duplicateProduct,
    printLabels,
    addNewProductOption,
    uploadProductImage,
    deleteProductImage,
    addComboProduct,
    removeProductFromCombo,
    updateComboProduct,
    loadTickets,
    loadVariantForms,
    addTicket,
    removeTicket,
    updateInventory,
    attachModifierGroupToProduct,
    detachModifierGroupFromProduct,
    updateSalesChannel,
    saveFormToVariant,
  } = useEditProduct(productId);

  const [detailsCard, setDetailsCard] = useState({
    internal_description_visible: false,
  });

  const [logisticsCard, setLogisticsCard] = useState({
    productTypeSearch: null as string, //sets when product is returned.
    accountRefSearch: null as string,
    vendorSearch: null as string,
  });

  const [showAllFaciliityInventories, setShowAllFaciliityInventories] = useState(false);

  const {
    state: kitchenLocationModal,
    updateModal: updateKitchenModal,
    closeModal: closeKitchenModal,
  } = useModal({
    kitchen_location_id: undefined,
    kitchen_location_id_2: undefined,
    kitchen_location_id_3: undefined,
  });

  const {
    state: printLabelModal,
    updateModal: updatePrintLabelModal,
    closeModal: closePrintLabelModal,
  } = useModal<{ offset: string }>({ offset: "" });

  const {
    state: removeProductModal,
    updateModal: updateRemoveProductModal,
    closeModal: closeRemoveProductModal,
  } = useModal();

  const {
    state: duplicateProductModal,
    updateModal: updateDuplicateProductModal,
    closeModal: closeDuplicateProductModal,
  } = useModal();

  const {
    state: newVariantModal,
    updateModal: updateNewVariantModal,
    closeModal: closeNewVariantModal,
  } = useModal({ productOption: "", optionValue: "", optionPrice: "" });

  const {
    state: newComboProductModal,
    updateModal: updateNewComboProductModal,
    closeModal: closeNewComboProductModal,
  } = useModal();

  const {
    state: editComboProductModal,
    updateModal: updateEditComboProductModal,
    closeModal: closeEditComboProductModal,
  } = useModal({ selectedComboVariant: null as TComboVariants });

  const {
    state: addTicketModal,
    updateModal: updateAddTicketModal,
    closeModal: closeAddTicketModal,
  } = useModal({ search: "" });

  const { state: inventoryModal, updateModal: updateInventoryModal } = useModal({
    quantity: 0,
    selectedTab: 0,
    tabs: [
      {
        id: "update-inventory",
        content: t("secure.facility.product.product.003"),
      },
      {
        id: "update-inventory-log",
        content: t("secure.facility.product.product.004"),
      },
    ],
  });

  const {
    state: taxModal,
    updateModal: updateTaxModal,
    closeModal: closeTaxModal,
  } = useModal({ taxLineDropdowns: [] as { variant_id: number; tax_line_id: number }[] });

  const {
    state: modifierModal,
    updateModal: updateModifierModal,
    closeModal: closeModifierModal,
  } = useModal({ search: "", required: false, groupIds: [] as Array<number> });

  const {
    state: formsModal,
    updateModal: updateFormsModal,
    closeModal: closeFormsModal,
  } = useModal({ formToVariantVisible: false, variant_id: -1, form_id: -1 });

  const productAccountReference = accountingReferences.find(ref => ref.id === product?.accounting_reference_id);
  const readOnly =
    (authStore.user?.permissions && !authStore.user?.permissions?.products_edit) ||
    (facilityStore.facility?.permissions && !facilityStore.facility?.permissions?.products_edit) ||
    !product;

  // Keep Visual with an internal description
  useEffect(() => {
    // Toggle open if starting with an internal description
    if (!detailsCard.internal_description_visible && productPreserved?.internal_description) {
      setDetailsCard(prev => ({ ...prev, internal_description_visible: true }));
    }
  }, [detailsCard.internal_description_visible, productPreserved?.internal_description]);

  // Keep Select search consistent with product type
  useEffect(() => {
    if (product?.type) {
      setLogisticsCard(prev => ({ ...prev, productTypeSearch: product.type }));
    }
  }, [product?.type]);

  useEffect(() => {
    if (accountingReferences && productAccountReference !== undefined) {
      setLogisticsCard(prev => ({ ...prev, accountRefSearch: productAccountReference.title }));
    }
  }, [accountingReferences, productAccountReference]);

  useEffect(() => {
    if (vendors && product?.vendor_id) {
      const vendor = vendors.find(value => value.id === product.vendor_id);

      setLogisticsCard(prev => ({ ...prev, vendorSearch: vendor ? vendor?.title : prev.vendorSearch }));
    }
  }, [vendors, product?.vendor_id]);

  // Lazy load tickets on first modal open
  useEffect(() => {
    const source = axios.CancelToken.source();

    if (addTicketModal.isOpen && tickets === undefined) {
      void loadTickets(null, source.token);
    }

    return () => source.cancel();
  }, [addTicketModal.isOpen]);

  // Lazy load variant forms on first modal open
  useEffect(() => {
    const source = axios.CancelToken.source();

    if (formsModal.isOpen && forms.variants === undefined) {
      void loadVariantForms({ variant_ids: product.variants.map(val => val.id) }, source.token);
    }
  }, [formsModal.isOpen]);

  // Debouced 0.75ms because of <Search />
  useEffect(() => {
    const source = axios.CancelToken.source();
    if (!product) {
      return;
    }
    void loadTickets(addTicketModal.search, source.token);
    return () => source.cancel();
  }, [addTicketModal.search]);

  function updateProduct() {
    const { facility_access, available_on_time, unavailable_on_time, ...rest } = product;
    let onDateTime = null;
    let untilDateTime = null;

    if (availableAtDate !== null && product.available_on_time !== null) {
      const tempOnDateTime = availableAtDate.toLocaleDateString() + " " + product.available_on_time;
      onDateTime = new Date(tempOnDateTime).toISOString();
    }

    if (availableUntilDate !== null && product.available_on_time !== null) {
      const tempUntilDateTime = availableUntilDate.toLocaleDateString() + " " + product.unavailable_on_time;
      untilDateTime = new Date(tempUntilDateTime).toISOString();
    }

    const putProduct: TPutProduct & { cannot_be_sold: boolean } = {
      ...rest,
      //The first variant's taxable value determines if the checkbox is set. Backend handles setting all variant's taxable value
      taxable: product?.variants[0]?.taxable,
      meta: {
        ...rest.meta,
        kitchen_location_id: negativeCheckHelper(product.meta.kitchen_location_id),
        kitchen_location_id_2: negativeCheckHelper(product.meta.kitchen_location_id_2),
        kitchen_location_id_3: negativeCheckHelper(product.meta.kitchen_location_id_3),
      },
      available_on: onDateTime,
      unavailable_on: untilDateTime,
      department_id: negativeCheckHelper(product.department_id),
      category_id: negativeCheckHelper(product.category_id),
      subcategory_id: negativeCheckHelper(product.subcategory_id),
    };

    console.log("params", putProduct);

    void saveProduct(putProduct);
  }

  function syncKitchenLocations() {
    setProduct(prev => ({
      ...prev,
      meta: {
        ...prev.meta,
        kitchen_location_id: kitchenLocationModal.kitchen_location_id,
        kitchen_location_id_2: kitchenLocationModal.kitchen_location_id_2,
        kitchen_location_id_3: kitchenLocationModal.kitchen_location_id_3,
      },
    }));
    closeKitchenModal();
  }

  /** Update product.meta.cannot_be_sold value */
  function toggleCannotBeSold() {
    const params = {
      ...product,
      meta: {
        ...product.meta,
        cannot_be_sold: !product.meta?.cannot_be_sold,
        kitchen_location_id: negativeCheckHelper(product.meta?.kitchen_location_id),
        kitchen_location_id_2: negativeCheckHelper(product.meta?.kitchen_location_id_2),
        kitchen_location_id_3: negativeCheckHelper(product.meta?.kitchen_location_id_3),
      },
      department_id: negativeCheckHelper(product.department_id),
      category_id: negativeCheckHelper(product.category_id),
      subcategory_id: negativeCheckHelper(product.subcategory_id),
    };

    void saveProduct(params);
  }

  function isPrintLabelsDisabled() {
    return (
      (printLabelModal.offset !== "" && !isWholeNumber(printLabelModal.offset)) ||
      labelVariants.filter(variant => variant.quantity !== 0).length === 0 ||
      labelVariants.some(variant => variant.quantity !== 0 && !isWholeNumber(String(variant.quantity)))
    );
  }

  // Only called if !isPrintLabelsDisabled
  function printLabelModalOk() {
    void printLabels(printLabelModal.offset);

    closePrintLabelModal();
    setLabelVariants(
      labelVariants.map((val, index) => ({
        ...val,
        quantity: 0,
      })),
    );
  }

  function variantFacilityAccessChange(id: number, checked: boolean) {
    setProduct(prev => ({
      ...prev,
      variants: prev.variants.map(variant => {
        if (variant.id == id) {
          return { ...variant, facility_access: checked };
        }
        return { ...variant };
      }),
    }));
  }

  function logisticsCardSearch(
    key: keyof { productTypeSearch: string; accountRefSearch: string; vendorSearch: string },
    query: string,
  ) {
    // handle the search query
    setLogisticsCard(prev => ({ ...prev, [key]: query }));

    // If search is cleared, remove the product param
    if (logisticsCard[key] !== null && query?.length === 0) {
      switch (key) {
        case "accountRefSearch": {
          setProduct(prev => ({ ...prev, accounting_reference_id: undefined }));
          break;
        }
        case "productTypeSearch": {
          setProduct(prev => ({ ...prev, type: undefined }));
          break;
        }
        case "vendorSearch": {
          setProduct(prev => ({ ...prev, vendor_id: undefined }));
          break;
        }
        default:
          return;
      }
    }
  }

  function handleComboProductModalOk(newId: number) {
    const params = {
      base_variant_id: product.variants[0].id,
      variant_id: newId,
    };

    void addComboProduct(params);
    closeNewComboProductModal();
  }

  /** TODO: Connect to backend || updateComboProduct function within useEditProduct hook */
  function handleUpdateComboProductModalOk(updateId: number, cost: string, price: string) {
    const params = {
      base_variant_id: product.variants[0].id,
      variant_id: updateId,
      cost: cost,
      price: price,
    };

    void updateComboProduct(params);
    closeEditComboProductModal();
  }

  function productTicketQuantityOnChange(ticketId: number, value: string) {
    setProduct(prev => ({
      ...prev,
      variants: prev.variants.map(variant => ({
        ...variant,
        tickets: variant.tickets.map(ticket => {
          if (ticket.id === ticketId) {
            return {
              ...ticket,
              quantity: Number(value),
            };
          } else {
            return ticket;
          }
        }),
      })),
    }));
  }

  function handleAddTicket(ticketId: number) {
    void addTicket(ticketId, null, null, true);
    closeAddTicketModal();
  }

  function handleUpdateInventory(quantity: number) {
    void updateInventory({ variant_id: null, quantity: quantity, refreshProduct: true });
    updateInventoryModal({ isOpen: false, selectedTab: 0 });
  }

  function handleAddTaxLine(variant: IVariant) {
    const allIds = variant.inventory_item.inventory_level.tax_lines.map(line => line.id);

    taxModal.taxLineDropdowns.map(lineDropdown => {
      if (lineDropdown.variant_id == variant.id) {
        allIds.push(lineDropdown.tax_line_id);
      }
    });

    void updateInventory({ variant_id: variant.id, tax_line_ids: allIds, refreshProduct: true });
  }

  function handleRemoveTaxLine(variant: IVariant, taxLineId: number) {
    let currentTaxIds = variant.inventory_item.inventory_level.tax_lines.map(line => line.id);
    currentTaxIds = currentTaxIds.filter(id => id !== taxLineId);

    void updateInventory({ variant_id: variant.id, tax_line_ids: currentTaxIds, refreshProduct: true });
  }

  function handleTaxDropdown(tax_line_id: number, variant: IVariant) {
    const currentLists = [...taxModal.taxLineDropdowns];

    const allIds = variant.inventory_item.inventory_level.tax_lines.map(line => line.id);
    const foundIndex = currentLists.findIndex(list => variant.id === list.variant_id);

    // if (foundIndex === -1) {
    allIds.push(tax_line_id);
    //   // updateTaxModal({ taxLineDropdowns: [...currentLists, { variant_id: variant.id, tax_line_id: tax_line_id }] });
    // } else {
    //   currentLists.map(list => {
    //     if (list.variant_id == variant.id) {
    //       allIds.push(tax_line_id);
    //       return { ...list, tax_line_id: tax_line_id };
    //     }
    //     return list;
    //   });
    //   updateTaxModal({ taxLineDropdowns: currentLists });
    // }

    void updateInventory({ variant_id: variant.id, tax_line_ids: allIds, refreshProduct: true });
  }

  // Only called with modifierModal.groupIds.length > 0
  function handleModifierModalOnOk() {
    const attachParams = {
      modifier_group_id: modifierModal.groupIds[0],
      required: modifierModal.required,
    };

    void attachModifierGroupToProduct(attachParams, true);

    closeModifierModal();
  }

  /** add / removes the sales channel immediately based on the targets checked value. */
  function handleSalesChannelUpdate(e: ChangeEvent<HTMLInputElement>) {
    const { id, checked, value: facility_id } = e.target;

    const params = {
      sales_channel_id: Number(id),
      remove: !checked,
    };

    void updateSalesChannel(params);
  }

  function handleFormsModalOk() {
    const params = {
      variant_id: formsModal.variant_id,
      form_id: formsModal.form_id,
    };
    void saveFormToVariant(params);

    setForms(prev => ({ ...prev, variants: undefined }));
    updateFormsModal({ formToVariantVisible: false, variant_id: null, form_id: null });
  }

  function handleAvailableOnToggle(e: ChangeEvent<HTMLInputElement>) {
    const { id, checked } = e.target;

    if (!checked) {
      setAvailableAtDate(null);
      setProduct(prev => ({ ...prev, available_on: null, available_on_time: null }));
    } else {
      // re-apply previous date when toggled on
      if (productPreserved.available_on) {
        setProduct(prev => ({
          ...prev,
          available_on: productPreserved.available_on,
          available_on_time: productPreserved.available_on_time,
        }));
        setAvailableAtDate(new Date(productPreserved.available_on));
      } else {
        const newDate = new Date();
        newDate.setHours(0, 0, 0, 0);

        setProduct(prev => ({
          ...prev,
          available_on: newDate.toLocaleDateString(),
          available_on_time: "12:00",
        }));

        setAvailableAtDate(newDate);
      }
    }
  }

  function handleAvailableUntilToggle(e: ChangeEvent<HTMLInputElement>) {
    const { id, checked } = e.target;

    if (!checked) {
      setAvailableUntilDate(null);
      setProduct(prev => ({ ...prev, unavailable_on: null, unavailable_on_time: null }));
    } else {
      // try to re-apply previous date when toggled on
      if (productPreserved.unavailable_on) {
        setProduct(prev => ({
          ...prev,
          unavailable_on: productPreserved.unavailable_on,
          unavailable_on_time: productPreserved.unavailable_on_time,
        }));
        setAvailableUntilDate(new Date(productPreserved.unavailable_on));
      } else {
        const newDate = new Date();
        newDate.setHours(0, 0, 0, 0);
        newDate.setDate(newDate.getDate() + 7); // 1 Week after todays date

        setProduct(prev => ({
          ...prev,
          unavailable_on: newDate.toLocaleDateString(),
          unavailable_on_time: "12:00",
        }));

        setAvailableUntilDate(newDate);
      }
    }
  }

  function handleAvailableOnDateSelectorChange(selectedDate: Date) {
    const tempDate = new Date(selectedDate).toLocaleDateString();

    // Handle range error with available_at and available_until
    if (availableUntilDate) {
      const tempStart = new Date(selectedDate.toLocaleDateString() + " " + product.available_on_time);
      const tempEnd = new Date(availableUntilDate.toLocaleDateString() + " " + product.unavailable_on_time);

      if (tempStart > tempEnd) {
        const monthAfter = new Date(selectedDate.getTime());
        monthAfter.setMonth(monthAfter.getMonth() + 1);

        setProduct(prev => ({ ...prev, unavailable_on: monthAfter.toLocaleDateString() }));
        setAvailableUntilDate(monthAfter);
      }
    }

    setProduct(prev => ({ ...prev, available_on: tempDate }));
    setAvailableAtDate(selectedDate);
  }

  function handleAvailableUntilDateSelectorChange(selectedDate: Date) {
    const tempDate = new Date(selectedDate).toLocaleDateString();

    setAvailableUntilDate(selectedDate);
    setProduct(prev => ({ ...prev, available_on: tempDate }));
  }

  function handleTimeClear(id: "available_on_time" | "unavailable_on_time") {
    if (id === "available_on_time") {
      setProduct(prevState => ({
        ...prevState,
        available_on: null,
        available_on_time: null,
      }));

      return;
    }
    if (id === "unavailable_on_time") {
      setProduct(prevState => ({
        ...prevState,
        unavailable_on: null,
        unavailable_on_time: null,
      }));
      return;
    }
  }

  function handleTimeAvailability(id: "available_on_time" | "unavailable_on_time", value: string) {
    if (value === undefined) {
      setProduct(prev => ({ ...prev, [id]: null }));
      return;
    }

    if (value === null || value?.length === 0) {
      handleTimeClear(id);
      return;
    }

    if ((product.available_on as string) === (product.unavailable_on as string)) {
      const dateVal = id === "available_on_time" ? "available_on" : "unavailable_on";
      const dateValue = new Date(`${product[dateVal] as string} ${value}`);

      if (id === "available_on_time") {
        const unavailableDate = new Date((product.unavailable_on as string) + " " + product.unavailable_on_time);
        if (dateValue < unavailableDate) {
          setProduct(prev => ({ ...prev, available_on_time: value }));
        }
      }
      if (id === "unavailable_on_time") {
        const availableDate = new Date((product.available_on as string) + " " + product.available_on_time);
        if (dateValue > availableDate) {
          setProduct(prev => ({ ...prev, unavailable_on_time: value }));
        }
      }

      return;
    }

    setProduct(prev => ({ ...prev, [id]: value }));
  }

  /*
    - available_on date could previously be set to a date before today and be a valid date.
    - available_on date can not be updated to date value before the current date.
    - <DatePick /> component `status` param visually indicates this edge case when applicable
  */
  function availableOnDateIsValid() {
    if (availableAtDate === null) {
      return true;
    }

    const wantedDate = new Date(`${availableAtDate.toLocaleDateString()} ${product?.available_on_time}`);
    const preservedDate = new Date(
      `${productPreserved?.available_on as string} ${productPreserved?.available_on_time}`,
    );

    if (
      product.available_on_time === null ||
      (wantedDate < new Date() && wantedDate.getTime() !== preservedDate.getTime())
    ) {
      return false;
    } else {
      return true;
    }
  }

  function availableUntilDateIsValid() {
    if (availableUntilDate === null) {
      return true;
    }

    const wantedDate = new Date(`${availableUntilDate?.toLocaleDateString()} ${product?.unavailable_on_time}`);
    const startDate = new Date(`${availableAtDate?.toLocaleDateString()} ${product?.available_on_time}`);

    if (product.unavailable_on_time === null || wantedDate < startDate) {
      return false;
    }

    return true;
  }

  if (product === undefined) {
    return (
      <Page
        breadcrumbs={[{ label: t("secure.facility.product.product.099"), url: "/admin/product" }]}
        multipleActionDropdownAction={{
          label: "More Options", // TODO: Translation
          disabled: true,
          dropdownProps: {
            alignment: "right",
            options: [],
          },
        }}
      >
        <div style={{ height: "50vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div
            style={{
              display: "grid",
              gap: "24px",
              padding: "24px",
              background: "white",
              borderRadius: "6px",
              borderColor: "#D0D5DD",
            }}
          >
            <div style={{ height: "72px" }}>
              <Spin />
            </div>
            <p>Getting things ready..</p>
          </div>
        </div>
      </Page>
    );
  } else {
    return (
      <Page
        title={productPreserved?.title}
        subtitle={productPreserved?.subtitle}
        breadcrumbs={[{ label: t("secure.facility.product.product.099"), url: "/admin/product" }]}
        splitLayout
        multipleActionDropdownAction={{
          label: "More Options", // TODO: Translation
          disabled: product === undefined ?? false,
          dropdownProps: {
            alignment: "right",
            options: [
              {
                type: "handler",
                label: "Remove", // TODO: Translation
                handler: () => updateRemoveProductModal({ isOpen: true }),
                disabled: readOnly,
                icon: "trash",
              },
              {
                type: "handler",
                label: "Duplicate", // TODO: Translation
                handler: () => updateDuplicateProductModal({ isOpen: true }),
                disabled: readOnly,
                icon: "copy",
              },
              {
                type: "handler",
                label: "Forms", // TODO: Translation
                handler: () => updateFormsModal({ isOpen: true }),
                disabled: readOnly,
                icon: "pen-to-square",
              },
              {
                type: "handler",
                label: product?.meta?.cannot_be_sold ? "Set as Can Be Sold" : "Set as Cannot Be Sold", // TODO: Translation
                handler: () => toggleCannotBeSold(),
                disabled: product?.cannot_be_sold,
                icon: product?.meta?.cannot_be_sold ? "check" : "ban",
              },
              {
                type: "handler",
                label: "Kitchen Locations", // TODO: Translation
                handler: () =>
                  updateKitchenModal({
                    isOpen: true,
                    kitchen_location_id: product.meta?.kitchen_location_id,
                    kitchen_location_id_2: product.meta?.kitchen_location_id_2,
                    kitchen_location_id_3: product.meta?.kitchen_location_id_3,
                  }),
                disabled: false,
                icon: "utensils",
              },
              {
                type: "handler",
                label: "Print Labels", // TODO: Translation
                handler: () => updatePrintLabelModal({ isOpen: true }),
                disabled: false,
                icon: "print",
              },
            ],
          },
        }}
        notificationBarProps={{
          isVisible:
            unsavedChangesExist(product, productPreserved) && availableOnDateIsValid() && availableUntilDateIsValid(),
          onAction: () => updateProduct(),
          onCancel: () => {
            setProduct(productPreserved);
            setAvailableAtDate(productPreserved.available_on ? new Date(productPreserved.available_on) : null);
          },
        }}
      >
        <Page.Section twoThirds>
          <ProductAvailabilityWarning
            t={t}
            cannotBeSold={product.meta?.cannot_be_sold}
            facilityAccess={product.facility_access}
            toggleFacilityAccess={() => attachProductToFacility()}
          />

          {/** PRODUCT DETAILS */}
          <Card title={t("secure.facility.product.product.009")}>
            <Card.Section>
              <FormLayout>
                <FormLayout.Group>
                  <Input
                    id="title"
                    value={product?.title || ""}
                    onChange={e => handleChangeEventInput(e, product, setProduct)}
                    label={t("secure.facility.product.product.010")}
                    placeholder={t("secure.facility.product.product.011")}
                    readOnly={readOnly}
                    error={!validated("title") && attempted}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Input
                    id="subtitle"
                    value={product?.subtitle || ""}
                    label={t("secure.facility.product.product.013")}
                    onChange={e => handleChangeEventInput(e, product, setProduct)}
                    placeholder={t("secure.facility.product.product.014")}
                    readOnly={readOnly}
                    error={!validated("subtitle") && attempted}
                  />
                  <Input
                    id="preferred_title"
                    value={product?.preferred_title || ""}
                    label={t("secure.facility.product.product.015")}
                    onChange={e => handleChangeEventInput(e, product, setProduct)}
                    placeholder={t("secure.facility.product.product.016")}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <TextEditor
                    markdownText={product?.description || ""}
                    markdownTextOnChange={(description: string) => setProduct(prev => ({ ...prev, description }))}
                    label={t("secure.facility.product.product.012")}
                    readOnly={readOnly}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <ButtonNew
                    type="text"
                    size="small"
                    onClick={e => {
                      setDetailsCard(prev => ({
                        ...prev,
                        internal_description_visible: !prev.internal_description_visible,
                      }));
                    }}
                  >
                    {t("secure.facility.product.product.096")}
                  </ButtonNew>
                </FormLayout.Group>
                {detailsCard.internal_description_visible ||
                productPreserved?.internal_description ||
                product?.internal_description ? (
                  <FormLayout.Group>
                    <TextEditor
                      markdownText={product.internal_description || ""}
                      markdownTextOnChange={internal_description =>
                        setProduct(prev => ({
                          ...prev,
                          internal_description: internal_description === "" ? null : internal_description,
                        }))
                      }
                      label={t("secure.facility.product.product.097")}
                      readOnly={
                        !authStore.user?.permissions?.products_edit ||
                        !facilityStore.facility?.permissions.products_edit
                      }
                    />
                  </FormLayout.Group>
                ) : null}
              </FormLayout>
            </Card.Section>
          </Card>

          {/**
           * PRICING
           * 1 Variant
           */}
          {product?.variants.length === 1 ? (
            <Card
              title={t("secure.facility.product.product.017")}
              titleActions={
                authStore.user.permissions?.products_edit && facilityStore.facility?.permissions?.products_edit
                  ? [
                      {
                        content: t("secure.facility.product.product.023"),
                        action: () => updateNewVariantModal({ isOpen: true }),
                      },
                    ]
                  : undefined
              }
            >
              <Card.Section>
                <FormLayout>
                  <FormLayout.Group>
                    <Input
                      id="price"
                      value={product.price || ""}
                      type={"number"}
                      prefix="$"
                      label={t("secure.facility.product.product_new.013")}
                      onChange={e => setProduct(prev => ({ ...prev, price: e.target.value as any }))}
                      placeholder="0.00"
                      error={!validated("price") && attempted}
                    />
                    <Input
                      id="original_price"
                      value={product.original_price || ""}
                      type={"number"}
                      prefix="$"
                      label={t("secure.facility.product.product_new.014")}
                      onChange={e => setProduct(prev => ({ ...prev, original_price: e.target.value as any }))}
                      placeholder="0.00"
                      error={!validated("original_price") && attempted}
                    />
                    <Input
                      id="cost"
                      value={product.cost || ""}
                      type={"number"}
                      prefix="$"
                      label={t("secure.facility.product.product_new.015")}
                      onChange={e => setProduct(prev => ({ ...prev, cost: e.target.value as any }))}
                      placeholder="0.00"
                      error={!validated("cost") && attempted}
                    />
                    <div className="mt-4">
                      <p className="product-profit-margin">
                        {displayPercent(roundNumber((product.price - product.cost) / product.price, 2))} --{" "}
                        {displayCurrency("cad", product.price - product.cost)}
                      </p>
                      <p className="product-profit-margin">{t("secure.facility.product.product_new.016")}</p>
                    </div>
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
            </Card>
          ) : null}

          {/**
           * VARIANTS
           * 2+ Variants
           */}
          {product?.variants.length > 1 ? (
            <Card
              title={t("secure.facility.product.product.022")}
              titleActions={
                authStore.user.permissions?.products_edit && facilityStore.facility?.permissions?.products_edit
                  ? [
                      {
                        content: t("secure.facility.product.product.023"),
                        action: () => history.push("/admin/product/" + productId + "/variant/new"),
                      },
                    ]
                  : undefined
              }
            >
              <Card.Section>
                <DataTable
                  columns={[
                    { label: "", width: "50%" }, //checkbox
                    { label: "", width: "25%" }, //variant price
                    { label: "", width: "25%" }, //button
                  ]}
                  hideHeader
                  className="min-h-auto"
                >
                  {product.variants.map((variant, index) => (
                    <tr key={index}>
                      <td>
                        <Checkbox
                          size="medium"
                          value={variant.facility_access ? variant.facility_access : false}
                          checked={variant.facility_access ? variant.facility_access : false}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            variantFacilityAccessChange(variant.id, e.target.checked)
                          }
                          label={variant.title}
                        />
                      </td>
                      <td>
                        <LocaleCurrency amount={variant.price} />
                      </td>
                      <td className="text-right">
                        <ButtonNew
                          type="text"
                          size="small"
                          onClick={() => history.push("/admin/product/" + productId + "/variant/" + String(variant.id))}
                        >
                          {t("secure.facility.product.product.098")}
                        </ButtonNew>
                      </td>
                    </tr>
                  ))}
                </DataTable>
              </Card.Section>
            </Card>
          ) : null}

          <Card>
            {/** LOGISTICS */}
            <Card.Section title={t("secure.facility.product.product.024")}>
              <FormLayout>
                <FormLayout.Group>
                  <Select
                    label={t("secure.facility.product.product.025")}
                    placeholder={t("secure.facility.product.product.026")}
                    className={`flex justify-center align-center w-full h-10 position-relative z-20 text-black font-medium appearance-none border-none focus:outline-none placeholder-gray-200`}
                    onSearch={(query: string) => logisticsCardSearch("productTypeSearch", query)}
                    onChange={(value: string) => setProduct(prev => ({ ...prev, type: value }))}
                    searchValue={logisticsCard.productTypeSearch || ""}
                    allowClear={
                      authStore.user?.permissions?.products_edit || facilityStore.facility?.permissions.products_edit
                    }
                    disabled={readOnly}
                    showSearch
                    showDropDownOnFocus
                    hideDropdown
                    error={!validated("type") && attempted}
                  >
                    {productTypes
                      .filter(val =>
                        val.title?.toLowerCase().includes(logisticsCard.productTypeSearch?.toLowerCase()),
                      )
                      .map(type => {
                        return (
                          <Select.Option key={type.id} value={type.title} name={type.title}>
                            {type.title}
                          </Select.Option>
                        );
                      })}
                  </Select>
                  <Select
                    label={t("secure.facility.product.product.027")}
                    onChange={(value: number) => setProduct(prev => ({ ...prev, vendor_id: value }))}
                    className={`flex justify-center align-center w-full h-10 position-relative z-20 text-black font-medium appearance-none border-none focus:outline-none placeholder-gray-200`}
                    onSearch={(query: string) => logisticsCardSearch("vendorSearch", query)}
                    placeholder={"Search vendors..."}
                    searchValue={logisticsCard.vendorSearch || ""}
                    // defaultValue={product?.vendor_id}
                    disabled={readOnly}
                    error={!validated("vendor_id") && attempted}
                    showSearch
                    showDropDownOnFocus
                    hideDropdown
                    allowClear={
                      authStore.user?.permissions?.products_edit || facilityStore.facility?.permissions.products_edit
                    }
                  >
                    <Select.Option key={"none"} value={undefined} name="none">
                      None
                    </Select.Option>
                    {vendors
                      .filter(val => val.title?.toLowerCase().includes(logisticsCard.vendorSearch?.toLowerCase()))
                      .map((vendor, index: number) => {
                        return (
                          <Select.Option key={index} value={vendor.id} name={vendor.title}>
                            {vendor.title}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </FormLayout.Group>
                <FormLayout.Group>
                  <Select
                    label={t("secure.facility.product.product.028")}
                    placeholder={t("secure.facility.product.product.029")}
                    className={`flex justify-center align-center w-full h-10 position-relative z-20 text-black font-medium appearance-none border-none focus:outline-none placeholder-gray-200`}
                    onSearch={(query: string) => logisticsCardSearch("accountRefSearch", query)}
                    onChange={(value: any) => setProduct(prev => ({ ...prev, accounting_reference_id: value }))}
                    searchValue={logisticsCard.accountRefSearch || ""}
                    showDropDownOnFocus={false}
                    hideDropdown
                    showSearch
                    disabled={readOnly}
                    allowClear={
                      authStore.user?.permissions?.products_edit || facilityStore.facility?.permissions.products_edit
                    }
                    error={!validated("accounting_reference_id") && attempted}
                  >
                    <div key={"wrap"}>
                      {accountingReferences
                        .filter(accountingReference =>
                          accountingReference.title
                            ?.toLowerCase()
                            .includes(logisticsCard.accountRefSearch?.toLowerCase()),
                        )
                        .map(reference => {
                          return (
                            <div key={reference.id}>
                              {logisticsCard.accountRefSearch !== "" ? (
                                <Select.Option key={reference.id} value={reference.id} name={reference.title}>
                                  {reference.title}
                                </Select.Option>
                              ) : null}
                            </div>
                          );
                        })}
                    </div>
                  </Select>
                </FormLayout.Group>
                <FormLayout.Group>
                  <Checkbox
                    id="customer_required"
                    size="medium"
                    value={product.customer_required}
                    checked={product.customer_required}
                    onChange={e => handleChangeEventInput(e, product, setProduct)}
                    label={t("secure.facility.product.product.031")}
                    disabled={!authStore.user?.permissions?.products_edit}
                    error={!validated("customer_required") && attempted}
                  />

                  <Checkbox
                    id="shipping_required"
                    size="medium"
                    value={product.shipping_required}
                    checked={product.shipping_required}
                    onChange={e => handleChangeEventInput(e, product, setProduct)}
                    label={t("secure.facility.product.product.032")}
                    disabled={!authStore.user?.permissions?.products_edit}
                    error={!validated("shipping_required") && attempted}
                  />
                  <Checkbox
                    id="fulfillment_required"
                    size="medium"
                    value={product.fulfillment_required}
                    checked={product.fulfillment_required}
                    onChange={e => handleChangeEventInput(e, product, setProduct)}
                    label={t("secure.facility.product.product.033")}
                    disabled={!authStore.user?.permissions?.products_edit}
                    error={!validated("fulfillment_required") && attempted}
                  />
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>

            {/* DEPARTMENTS */}
            <Card.Section title={t("secure.facility.product.product.034")}>
              <FormLayout>
                <FormLayout.Group>
                  <Select
                    label={t("secure.facility.product.product.035")}
                    onChange={(value: number) => setProduct(prev => ({ ...prev, department_id: value }))}
                    defaultValue={product.department_id}
                    disabled={readOnly}
                    error={!validated("department_id") && attempted}
                  >
                    <Select.Option value={-1}>None</Select.Option>
                    {departments
                      .filter(department => department.type === "department")
                      .map(department => (
                        <Select.Option key={department.id} value={department.id} name={department.title}>
                          {department.title}
                        </Select.Option>
                      ))}
                  </Select>

                  <Select
                    label={t("secure.facility.product.product.036")}
                    onChange={(value: number) => setProduct(prev => ({ ...prev, category_id: value }))}
                    defaultValue={product?.category_id}
                    disabled={readOnly}
                    error={!validated("category_id") && attempted}
                  >
                    <Select.Option value={-1}>None</Select.Option>
                    {departments
                      .filter(
                        department => department.type == "category" && department.parent_id == product.department_id,
                      )
                      .map(category => (
                        <Select.Option key={category.id} value={category.id} name={category.title}>
                          {category.title}
                        </Select.Option>
                      ))}
                  </Select>
                  <Select
                    label={t("secure.facility.product.product.037")}
                    onChange={(value: number) => setProduct(prev => ({ ...prev, subcategory_id: value }))}
                    defaultValue={product.subcategory_id}
                    disabled={readOnly}
                    error={!validated("subcategory_id") && attempted}
                  >
                    <Select.Option value={-1}>None</Select.Option>
                    {departments
                      .filter(
                        department => department.type == "subcategory" && department.parent_id == product.category_id,
                      )
                      .map(subcategory => (
                        <Select.Option key={subcategory.id} value={subcategory.id} name={subcategory.title}>
                          {subcategory.title}
                        </Select.Option>
                      ))}
                  </Select>
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>
          </Card>

          {/* IMAGES */}
          <Card>
            <Card.Section title={t("secure.facility.product.product.038")}>
              <SponsorList
                sponsors={productImages}
                onNew={file => uploadProductImage(file)}
                onRemove={id => deleteProductImage(id)}
              />
            </Card.Section>
          </Card>

          {/* COLOR */}
          <Card>
            <Card.Section title={t("secure.facility.product.product.039")}>
              <ColorPicker
                colorValue={product?.meta?.button_color}
                onClick={(codeColor: string) =>
                  setProduct(prev => ({
                    ...prev,
                    meta: { ...prev.meta, button_color: prev.meta?.button_color === codeColor ? null : codeColor },
                  }))
                }
              />
            </Card.Section>
          </Card>

          {/**
           * PRODUCT COMBOS
           * 1 Variant
           */}
          {product.type?.toLowerCase() === "combo" && product.variants.length === 1 ? (
            <Card
              title={t("secure.facility.product.product.040")}
              titleActions={[
                {
                  content: t("secure.facility.product.product.042"),
                  action: () => updateNewComboProductModal({ isOpen: true }),
                  disabled: !authStore.user?.permissions?.products_edit,
                },
              ]}
            >
              <Card.Section>
                <div className="product-edit-combo-table">
                  <DataTable
                    columns={[
                      { label: "Product Title", width: "35%" }, // TODO: Translation
                      { label: "Price", width: "20%" }, // TODO: Translation
                      { label: "Cost", width: "20%" }, // TODO: Translation
                      { label: "Type", width: "15%" }, // TODO: Translation
                      { label: "", width: "5%" }, // Remove column
                      { label: "", width: "5%" }, // Edit column
                    ]}
                  >
                    {comboProducts.map(combo => (
                      <tr key={combo.id}>
                        <td>
                          <p>{combo.product.title}</p>
                          <p className="text-sm text-gray-400">{combo.title}</p>
                        </td>
                        <td>
                          {combo?.combo_pivot.price ? displayCurrency("cad", parseFloat(combo?.combo_pivot.price)) : ""}
                        </td>
                        <td>
                          {combo?.combo_pivot.cost ? displayCurrency("cad", parseFloat(combo?.combo_pivot.cost)) : ""}
                        </td>
                        <td>
                          <Badge>{combo.product.type}</Badge>
                        </td>
                        <td>
                          <ButtonNew
                            type="text"
                            onClick={() => removeProductFromCombo(combo)}
                            disabled={!authStore.user?.permissions?.products_edit}
                          >
                            <FontAwesomeIcon icon={{ prefix: "fad", iconName: "trash" }} />
                          </ButtonNew>
                        </td>
                        <td>
                          <ButtonNew
                            type="text"
                            onClick={() => updateEditComboProductModal({ isOpen: true, selectedComboVariant: combo })}
                            disabled={!authStore.user?.permissions?.products_edit}
                          >
                            <FontAwesomeIcon icon={{ prefix: "fad", iconName: "pen-to-square" }} />
                          </ButtonNew>
                        </td>
                      </tr>
                    ))}
                  </DataTable>
                </div>
              </Card.Section>
            </Card>
          ) : null}

          {/**
           * PRODUCT TICKETS
           * 1 Variant
           */}
          {product.type?.toLowerCase() === "ticket" && product.variants.length === 1 ? (
            <Card
              title={t("secure.facility.product.product.043")}
              titleActions={[
                {
                  content: t("secure.facility.product.product.044"),
                  action: () => updateAddTicketModal({ isOpen: true }),
                  disabled: !authStore.user?.permissions?.products_edit,
                },
              ]}
            >
              <Card.Section>
                <FormLayout>
                  <FormLayout.Group>
                    <div className="ui-checkbox-group">
                      {product.variants[0].tickets.map(ticket => (
                        <div key={ticket.id} className="ui-checkbox-group-item">
                          <div className="ui-checkbox-group-item-content">
                            <div className="ui-checkbox-group-item-text">
                              <p className="text-sm text-medium text-gray-700">{ticket.title}</p>
                              {ticket.subtitle && (
                                <p className="text-xs text-medium text-gray-500">{ticket.subtitle}</p>
                              )}
                              <p className="text-xs text-regular text-gray-500">{ticket.included}</p>
                              <ButtonNew
                                type="text"
                                size="small"
                                onClick={(e: React.MouseEvent) => removeTicket(ticket)}
                              >
                                {t("secure.facility.product.variant.013")}
                              </ButtonNew>
                            </div>
                          </div>
                          <Input
                            onChange={e => productTicketQuantityOnChange(ticket.id, e.target.value)}
                            value={ticket.quantity}
                            type="number"
                            label=""
                            error={Number(ticket.quantity) < 1 && attempted}
                          />
                        </div>
                      ))}
                    </div>
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
            </Card>
          ) : null}

          {product.variants.length !== 1 ? (
            <Card title={t("secure.facility.product.product.105")}>
              <Card.Section>
                <Checkbox
                  id="track_inventory"
                  size="medium"
                  value={product.track_inventory || false}
                  checked={product.track_inventory || false}
                  onChange={e => handleChangeEventInput(e, product, setProduct)}
                  label={t("secure.facility.product.product.106")}
                  disabled={!authStore.user?.permissions?.products_edit}
                />
              </Card.Section>
              {/* QUANTITY */}
              <Card.Section
                title={"Facilities Inventory"} // TODO: Translation
                sectionTitleActions={[
                  {
                    content: showAllFaciliityInventories ? "Hide Secondary Locations" : "View All Locations", // TODO: Translation
                    action: (e: React.MouseEvent<HTMLElement>) =>
                      setShowAllFaciliityInventories(!showAllFaciliityInventories),
                    disabled: false,
                  },
                ]}
              >
                <div className="product-edit-inventory-table">
                  <FacilityInventoryTable
                    mainFacilityId={facilityStore.facility?.id || 0}
                    facilities={facilityStore.facilities}
                    variants={product.variants}
                    showAllFacilities={showAllFaciliityInventories}
                  />
                </div>
              </Card.Section>
            </Card>
          ) : (
            <Card
              title={t("secure.facility.product.product.047")}
              titleActions={[
                {
                  content: t("secure.facility.product.product.048"),
                  action: () => updateInventoryModal({ isOpen: true }),
                },
              ]}
            >
              {/* INVENTORY */}
              <Card.Section>
                <FormLayout>
                  <FormLayout.Group alignCenter>
                    <Input
                      id="barcode"
                      value={product.barcode || ""}
                      onChange={e => handleChangeEventInput(e, product, setProduct)}
                      label={t("secure.facility.product.product.049")}
                      disabled={readOnly}
                    />
                    <Input
                      id="sku"
                      value={product.sku || ""}
                      onChange={e => handleChangeEventInput(e, product, setProduct)}
                      label={t("secure.facility.product.product.050")}
                      disabled={readOnly}
                    />
                    <Checkbox
                      id="track_inventory"
                      size="medium"
                      value={product.track_inventory || false}
                      checked={product.track_inventory || false}
                      onChange={e => handleChangeEventInput(e, product, setProduct)}
                      label={t("secure.facility.product.product.104")}
                      disabled={!authStore.user?.permissions?.products_edit}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
              {/* QUANTITY */}
              <Card.Section
                title={"Facilities Inventory"} // TODO: Translation
                sectionTitleActions={[
                  {
                    content: showAllFaciliityInventories ? "Hide Secondary Locations" : "View All Locations", // TODO: Translation
                    action: (e: React.MouseEvent<HTMLElement>) =>
                      setShowAllFaciliityInventories(!showAllFaciliityInventories),
                    disabled: false,
                  },
                ]}
              >
                <div className="product-edit-inventory-table">
                  <FacilityInventoryTable
                    mainFacilityId={facilityStore.facility?.id || 0}
                    facilities={facilityStore.facilities}
                    variants={product.variants}
                    showAllFacilities={showAllFaciliityInventories}
                  />
                </div>
              </Card.Section>
            </Card>
          )}

          {/* <Card title="Facilities Inventory" collapsable defaultCollapsed>
            <Card.Section>
              <div className="product-edit-inventory-table">
                <FacilityInventoryTable
                  mainFacilityId={facilityStore.facility?.id || 0}
                  facilities={facilityStore.facilities}
                  variants={product.variants}
                  showAllFacilities={showAllFaciliityInventories}
                />
              </div>
              <ButtonNew
                type="secondary"
                onClick={() => setShowAllFaciliityInventories(!showAllFaciliityInventories)}
                className="mt-4"
              >
                {showAllFaciliityInventories ? "Hide Secondary Locations" : "View All Locations"}{" "}
              </ButtonNew>
            </Card.Section>
          </Card> */}
        </Page.Section>

        <Page.Section oneThird>
          <Card
            title={t("secure.facility.product.product.054")}
            titleActions={[
              {
                content: t("secure.facility.product.product.055"),
                action: () => updateTaxModal({ isOpen: true }),
                disabled:
                  !authStore.user?.permissions?.products_edit || !facilityStore.facility?.permissions?.products_edit,
              },
            ]}
          >
            <Card.Section>
              <FormLayout>
                <FormLayout.Group>
                  <Checkbox
                    id="taxable"
                    size="medium"
                    value={product.variants[0].taxable}
                    checked={product.variants[0].taxable}
                    onChange={e =>
                      setProduct(prev => ({
                        ...prev,
                        variants: prev.variants.map((vari, index) => {
                          if (index === 0) {
                            return { ...vari, taxable: !vari.taxable };
                          } else {
                            return vari;
                          }
                        }),
                      }))
                    }
                    label={t("secure.facility.product.product.056")}
                    disabled={
                      !authStore.user?.permissions?.products_edit || !facilityStore.facility?.permissions?.products_edit
                    }
                  />
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>
          </Card>
          {/* SALES CHANNELS & Dates */}
          <Card title={t("secure.facility.product.product.057")}>
            <Card.Section>
              <FormLayout>
                <FormLayout.Group>
                  {salesChannels.map(channel => (
                    <Toggle
                      key={channel.id}
                      id={String(channel.id)}
                      value={channel.facility_id}
                      labelTop={channel.title}
                      onChange={e => handleSalesChannelUpdate(e)}
                      checked={channel.checked}
                      disabled={!authStore.user?.permissions?.products_edit}
                    />
                  ))}
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>
            <Card.Section>
              <FormLayout>
                <FormLayout.Group>
                  <Toggle
                    id="available_on_toggle"
                    labelRight={t("secure.facility.product.product.058")}
                    onChange={handleAvailableOnToggle}
                    checked={availableAtDate !== null}
                    disabled={readOnly}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  {availableAtDate === null ? null : (
                    <div>
                      <div style={{ flex: "1" }}>
                        <div style={{ width: "100%" }}>
                          <DatePickerInput
                            months={1}
                            position={"left"}
                            startingDate={availableAtDate}
                            label={t("secure.facility.product.product.059")}
                            setStartingDate={(selectedDate: any) => {
                              if (authStore.user?.permissions?.products_edit) {
                                handleAvailableOnDateSelectorChange(selectedDate);
                              }
                            }}
                            error={!availableOnDateIsValid()}
                          />
                        </div>
                      </div>
                      <div style={{ display: "flex", marginTop: "15px", width: "100%", alignItems: "center" }}>
                        <TimePick
                          value={product.available_on_time}
                          onChange={timeString => handleTimeAvailability("available_on_time", timeString)}
                          disabled={readOnly}
                          align="right"
                          status={!availableOnDateIsValid() ? "error" : undefined}
                          size="large"
                        />
                        <label style={{ marginLeft: "5px", marginBottom: "0px" }} htmlFor="">
                          {moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).zoneAbbr()}
                        </label>
                      </div>
                    </div>
                  )}
                </FormLayout.Group>

                <FormLayout.Group>
                  <Toggle
                    id="unavailable_on_toggle"
                    labelRight={t("secure.facility.product.product.060")}
                    onChange={handleAvailableUntilToggle}
                    checked={availableUntilDate !== null}
                    disabled={readOnly}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  {availableUntilDate === null ? null : (
                    <div>
                      <div style={{ flex: "1" }}>
                        <div style={{ width: "100%" }}>
                          <DatePickerInput
                            months={1}
                            position={"left"}
                            startingDate={availableUntilDate}
                            label={t("secure.facility.product.product.061")}
                            setStartingDate={(selectedDate: any) => {
                              if (authStore.user?.permissions?.products_edit) {
                                handleAvailableUntilDateSelectorChange(selectedDate);
                              }
                            }}
                            error={!availableUntilDateIsValid()}
                          />
                        </div>
                      </div>
                      <div style={{ display: "flex", marginTop: "15px", width: "100%", alignItems: "center" }}>
                        <TimePick
                          value={product.unavailable_on_time}
                          onChange={timeString => handleTimeAvailability("unavailable_on_time", timeString)}
                          disabled={readOnly}
                          align="right"
                          status={!availableUntilDateIsValid() ? "error" : undefined}
                          size="large"
                        />
                        <label style={{ marginLeft: "5px", marginBottom: "0px" }} htmlFor="">
                          {moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).zoneAbbr()}
                        </label>
                      </div>
                    </div>
                  )}
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>
          </Card>

          {/* MODIFIERS */}
          <Card
            title={t("secure.facility.product.product.062")}
            titleActions={[
              {
                content: t("secure.facility.product.product.063"),
                action: () => updateModifierModal({ isOpen: true }),
              },
            ]}
          >
            <Card.Section>
              {productModifiers.map(group => (
                <div key={group.id} className="flex items-center gap-4 mb-2">
                  <ButtonNew type="secondary" size="small" onClick={() => detachModifierGroupFromProduct(group.id)}>
                    {t("secure.facility.product.product.064")}
                  </ButtonNew>
                  <p className="font-semibold">{group.title}</p>
                </div>
              ))}
            </Card.Section>
          </Card>
        </Page.Section>

        <Portal isMounted={modifierModal.isOpen}>
          <Sheet
            open={modifierModal.isOpen}
            title={t("secure.facility.product.product.080")}
            size="medium"
            closable
            onCancel={closeModifierModal}
            cancelText={t("secure.facility.product.product.081")}
            onOk={() => handleModifierModalOnOk()}
            okText={t("secure.facility.product.product.082")}
            okDisabled={modifierModal.groupIds.length === 0 ? true : false}
          >
            <div>
              <div style={{ top: "55px", width: "94%" }} className="absolute bg-white z-20 h-6"></div>
              <div className="sticky-top bg-white z-20 border-b-2 border-gray-200">
                <div className="relative flex items-center">
                  <div className="flex-1">
                    {/* Do not need bounceback */}
                    <Input
                      onChange={e => updateModifierModal({ search: e.target.value })}
                      value={modifierModal.search}
                      type="search"
                      placeholder={t("secure.facility.product.product.083")}
                    />
                  </div>
                  <div className="mt-4 ml-4">
                    <Checkbox
                      size="medium"
                      label={t("secure.facility.product.product.084")}
                      checked={modifierModal.required}
                      onChange={e => updateModifierModal({ required: !modifierModal.required })}
                    />
                  </div>
                </div>
              </div>
              <DataTable
                columns={[
                  { label: "", width: "5%" },
                  { label: "Modifier Title", width: "95%" },
                ]}
              >
                {modifierGroups
                  .filter(
                    filteredGroup =>
                      !productModifiers.some(group => group.id === filteredGroup.id) &&
                      filteredGroup.title.toLowerCase().includes(modifierModal.search.toLowerCase()),
                  )
                  .map((modifierGroup, index) => (
                    <tr
                      onClick={e =>
                        updateModifierModal({
                          groupIds: modifierModal.groupIds.includes(modifierGroup.id) ? [] : [modifierGroup.id],
                        })
                      }
                      className="clickable"
                      key={modifierGroup.id}
                    >
                      <td>
                        <Checkbox
                          size="small"
                          checked={modifierModal.groupIds.includes(modifierGroup.id)}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => e.stopPropagation()}
                        />
                      </td>

                      <td>{modifierGroup.title}</td>
                    </tr>
                  ))}
              </DataTable>
            </div>
          </Sheet>
        </Portal>

        <Portal isMounted={newComboProductModal.isOpen}>
          <AttachProductAsComboModal
            isVisible={newComboProductModal.isOpen}
            currentlyAddedProducts={comboProducts}
            userLevel={"facility"}
            onCancel={() => closeNewComboProductModal()}
            onOk={productId => handleComboProductModalOk(productId)}
          />
        </Portal>

        <Portal isMounted={editComboProductModal.isOpen}>
          <UpdateProductAsComboModal
            isVisible={editComboProductModal.isOpen}
            onCancel={() => closeEditComboProductModal()}
            onOk={(comboId, cost, price) => handleUpdateComboProductModalOk(comboId, cost, price)}
            combo={editComboProductModal.selectedComboVariant}
            handleUpdates={updateEditComboProductModal}
          />
        </Portal>

        {/* DETACH product from facility.  Navigate to Products.tsx after. */}
        <Portal isMounted={removeProductModal.isOpen}>
          <Popup
            okText={t("secure.facility.product.product.073")}
            onCancel={() => closeRemoveProductModal()}
            onOk={() => detachProductFromfacility()}
            title={t("secure.facility.product.product.074")}
            type="warning"
            description={t("secure.facility.product.product.075")}
            open={removeProductModal.isOpen}
          />
        </Portal>

        {/* DUPLICATE product.  Navigate to Products.tsx after. */}
        <Portal isMounted={duplicateProductModal.isOpen}>
          <Popup
            okText={"Confirm"}
            onCancel={() => closeDuplicateProductModal()}
            onOk={() => duplicateProduct()}
            title={"Duplicate Product"} //TODO: Translation
            type="warning"
            description={"Are you sure you want to duplicate this product?"} //TODO: Translation
            open={duplicateProductModal.isOpen}
          />
        </Portal>

        {/* Set kitchen locations, does not generate API call. onOk syncs with product.meta state. */}
        <Portal isMounted={kitchenLocationModal.isOpen}>
          <Sheet
            open={kitchenLocationModal.isOpen}
            size="small"
            onCancel={() => closeKitchenModal()}
            onOk={() => syncKitchenLocations()}
            title={t("secure.facility.product.product.068")}
            supportText={t("secure.facility.product.product.069")}
            overflow
          >
            <FormLayout>
              <FormLayout.Group>
                <Select
                  label=""
                  onChange={(id: any) => updateKitchenModal({ kitchen_location_id: id })}
                  defaultValue={kitchenLocationModal.kitchen_location_id}
                  disabled={!authStore.user?.permissions?.products_edit}
                >
                  <Select.Option key={"none"} value={-1} name="none">
                    None
                  </Select.Option>
                  {kitchenLocations
                    .filter((location: any) => {
                      // Filter out other selected kitchen_location_ids
                      if (
                        location.id !== kitchenLocationModal.kitchen_location_id_2 &&
                        location.id !== kitchenLocationModal.kitchen_location_id_3
                      ) {
                        return location;
                      }
                    })
                    .map((location, index: number) => {
                      return (
                        <Select.Option key={index} value={location.id} name={location.title}>
                          {location.title}
                        </Select.Option>
                      );
                    })}
                </Select>
              </FormLayout.Group>
              <FormLayout.Group>
                <Select
                  label=""
                  onChange={(id: any) => updateKitchenModal({ kitchen_location_id_2: id })}
                  defaultValue={kitchenLocationModal.kitchen_location_id_2}
                  disabled={!authStore.user?.permissions?.products_edit}
                >
                  <Select.Option key={"none"} value={-1} name="none">
                    None
                  </Select.Option>
                  {kitchenLocations
                    .filter((location: any) => {
                      // Filter out other selected kitchen_location_ids
                      if (
                        location.id !== kitchenLocationModal.kitchen_location_id &&
                        location.id !== kitchenLocationModal.kitchen_location_id_3
                      ) {
                        return location;
                      }
                    })
                    .map((location, index: number) => {
                      return (
                        <Select.Option key={index} value={location.id} name={location.title}>
                          {location.title}
                        </Select.Option>
                      );
                    })}
                </Select>
              </FormLayout.Group>
              <FormLayout.Group>
                <Select
                  label=""
                  onChange={(id: any) => updateKitchenModal({ kitchen_location_id_3: id })}
                  defaultValue={kitchenLocationModal.kitchen_location_id_3}
                  disabled={!authStore.user?.permissions?.products_edit}
                >
                  <Select.Option key={"none"} value={-1} name="none">
                    None
                  </Select.Option>
                  {kitchenLocations
                    .filter((location: any) => {
                      // Filter out other selected kitchen_location_ids
                      if (
                        location.id !== kitchenLocationModal.kitchen_location_id &&
                        location.id !== kitchenLocationModal.kitchen_location_id_2
                      ) {
                        return location;
                      }
                    })
                    .map((location, index: number) => {
                      return (
                        <Select.Option key={index} value={location.id} name={location.title}>
                          {location.title}
                        </Select.Option>
                      );
                    })}
                </Select>
              </FormLayout.Group>
            </FormLayout>
          </Sheet>
        </Portal>

        <Portal isMounted={printLabelModal.isOpen}>
          <Sheet
            open={printLabelModal.isOpen}
            size="small"
            onCancel={() => closePrintLabelModal()}
            onOk={() => printLabelModalOk()}
            okDisabled={isPrintLabelsDisabled()}
            title={t("secure.facility.product.product.065")}
          >
            <FormLayout>
              <FormLayout.Group>
                <Input
                  label={t("secure.facility.product.product.066")}
                  value={printLabelModal.offset}
                  onChange={(event: any) => updatePrintLabelModal({ offset: event.target.value })}
                />
              </FormLayout.Group>

              {labelVariants
                .sort((previousVariant, nextVariant) => previousVariant.position - nextVariant.position)
                .map((variant, index) => (
                  <FormLayout.Group key={variant.id}>
                    <Input
                      label={variant.title}
                      value={variant.quantity}
                      onChange={event =>
                        setLabelVariants(
                          labelVariants.map(vari =>
                            vari.id !== variant.id ? vari : { ...vari, quantity: Number(event.target.value) },
                          ),
                        )
                      }
                    />
                  </FormLayout.Group>
                ))}
            </FormLayout>
          </Sheet>
        </Portal>

        {/* Currently only 1 variant, wanting to add another. */}
        <Portal isMounted={newVariantModal.isOpen}>
          <Sheet
            open={newVariantModal.isOpen}
            title={t("secure.facility.product.product.107")}
            supportText="Confirming will navigate you to the edit page of the created variant." // TODO: Translation
            cancelText={t("secure.facility.product.product.108")}
            okText={t("secure.facility.product.product.109")}
            closable
            onCancel={() => closeNewVariantModal()}
            onOk={() =>
              addNewProductOption({
                name: newVariantModal.productOption,
                value: newVariantModal.optionValue,
                price: Number(newVariantModal.optionPrice),
              })
            }
            okDisabled={
              newVariantModal.productOption === "" ||
              newVariantModal.optionValue === "" ||
              !numberIsValid(newVariantModal.optionPrice)
            }
          >
            <FormLayout>
              <FormLayout.Group>
                <Input
                  label={t("secure.facility.product.product.110")}
                  value={newVariantModal.productOption}
                  onChange={e => updateNewVariantModal({ productOption: e.target.value })}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Input
                  label={newVariantModal.productOption}
                  value={newVariantModal.optionValue}
                  onChange={e => updateNewVariantModal({ optionValue: e.target.value })}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Input
                  label={t("secure.facility.product.product.111")}
                  value={newVariantModal.optionPrice}
                  onChange={e => updateNewVariantModal({ optionPrice: e.target.value })}
                  error={!numberIsValid(newVariantModal.optionPrice, true)}
                />
              </FormLayout.Group>
            </FormLayout>
          </Sheet>
        </Portal>

        <Portal isMounted={addTicketModal.isOpen}>
          <Sheet
            open={addTicketModal.isOpen}
            title={t("secure.facility.product.product.085")}
            size="small"
            closable
            onCancel={() => updateAddTicketModal({ isOpen: false, search: "" })}
            cancelText={t("secure.facility.product.product.086")}
            backDropCancel={false}
          >
            <div className="ui-checkbox-group" style={{ minHeight: "250px" }}>
              <div className="mb-4 w-full">
                <Search
                  historyKey={"product_edit-ticket_search"}
                  searchCallback={searchValue => updateAddTicketModal({ search: searchValue })}
                  placeholder="Search Tickets.."
                />
              </div>
              {tickets === undefined ? (
                <div className="ui-checkbox-group-item" style={{ height: "72px", justifyContent: "center" }}>
                  <Spin />
                </div>
              ) : (
                tickets
                  .filter(val => product.variants[0].tickets.every(ticket => ticket.id !== val.id))
                  .map(ticket => {
                    return (
                      <div
                        key={ticket.id}
                        onClick={() => handleAddTicket(ticket.id)}
                        className="ui-checkbox-group-item clickable"
                      >
                        <div className="ui-checkbox-group-item-content">
                          <div className="ui-checkbox-group-item-text">
                            <p className="text-sm text-medium text-gray-700">{ticket.title}</p>
                            <p className="text-xs text-medium text-gray-500">{ticket.subtitle}</p>
                            <p className="text-xs text-regular text-gray-500">{ticket.included}</p>
                          </div>
                        </div>
                        <p className="text-m text-large">SELECT</p>
                      </div>
                    );
                  })
              )}
            </div>
          </Sheet>
        </Portal>

        <Portal isMounted={inventoryModal.isOpen}>
          <Sheet
            open={inventoryModal.isOpen}
            size="small"
            closable
            title={t("secure.facility.product.product.091")}
            onOk={() => handleUpdateInventory(inventoryModal.quantity)}
            okDisabled={Number(inventoryModal.quantity) < 0}
            cancelText={t("secure.facility.product.product.092")}
            okText={t("secure.facility.product.product.093")}
            onCancel={() => updateInventoryModal({ isOpen: false, selectedTab: 0 })}
          >
            <Tabs
              tabs={inventoryModal.tabs}
              selected={inventoryModal.selectedTab}
              onSelect={tabIndex => updateInventoryModal({ selectedTab: tabIndex })}
            >
              {inventoryModal.selectedTab === 0 ? (
                <div className="booking-modal">
                  <div className="booking-details">
                    <div className="booking-options">
                      <div className="booking-options_option">
                        <Input
                          label={t("secure.facility.product.product.094")}
                          value={inventoryModal.quantity ?? ""}
                          type="number"
                          id="newQuantity"
                          onChange={e => updateInventoryModal({ quantity: e.target.valueAsNumber })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {inventoryModal.selectedTab === 1 ? (
                <div className="flex flex-column">{t("secure.facility.product.product.095")}</div>
              ) : null}
            </Tabs>
          </Sheet>
        </Portal>

        <Portal isMounted={taxModal.isOpen}>
          <Sheet
            open={taxModal.isOpen}
            title={t("secure.facility.product.product.087")}
            size="medium"
            closable
            onCancel={closeTaxModal}
            cancelText={t("secure.facility.product.product.088")}
            backDropCancel={false}
          >
            {product.variants.map((variant: any, index: any) => {
              return (
                <div key={index} className="edit-tax-lines_container">
                  <div className="edit-tax-lines_title_container">
                    <p className="edit-tax-lines_title">{variant.title}</p>
                  </div>

                  <div className="edit-tax-lines_single_variant_container">
                    <TagInputSelect
                      tags={variant.inventory_item?.inventory_level?.tax_lines.map((line: TTaxLines) => {
                        return { name: `${line.name} (${line.percentage}%)`, id: line.id };
                      })}
                      selectOptions={taxlines
                        ?.filter(
                          line =>
                            variant.inventory_item.inventory_level.tax_lines?.length === 0 ||
                            variant.inventory_item.inventory_level.tax_lines.findIndex(
                              (taxLine: { id: string | number }) => Number(taxLine.id) === Number(line.id),
                            ) < 0,
                        )
                        ?.map(line => {
                          return {
                            name: `${line.name} (${line.percentage}%) - ${line.province_code ?? ""}`,
                            id: line.id,
                          };
                        })}
                      onChange={(value, foo) => {
                        handleTaxDropdown(Number(value), variant);
                      }}
                      onTagClick={taxLineId => handleRemoveTaxLine(variant, taxLineId)}
                      className="edit-tax-lines_single_variant_container_tag_input"
                      helpText={"Select tax lines to add to this variant"} // TODO: Translation
                    />
                  </div>
                </div>
              );
            })}
          </Sheet>
        </Portal>

        <Portal isMounted={formsModal.isOpen}>
          <Sheet
            open={formsModal.isOpen}
            size="medium"
            closable
            onOk={() => updateFormsModal({ formToVariantVisible: true })}
            title="Forms"
            okText={"Attach Form"} // TODO: Translation
            onCancel={closeFormsModal}
            backDropCancel
          >
            <DataTable
              columns={[
                { label: "Variant" }, // TODO: Translation
                { label: "Form" }, // TODO: Translation
              ]}
              loading={forms.variants === undefined}
            >
              {forms.variants?.map(state => (
                <tr key={state.variant?.id}>
                  <td>{state.variant?.title}</td>
                  <td>
                    <div className="flex gap-2">
                      {state.forms?.map(variantForm => (
                        <Badge key={variantForm?.id} size="small" type="primary">
                          {variantForm?.form?.title}
                        </Badge>
                      ))}
                    </div>
                  </td>
                </tr>
              ))}
            </DataTable>
          </Sheet>
        </Portal>

        <Portal isMounted={formsModal.formToVariantVisible}>
          <Sheet
            open={formsModal.formToVariantVisible}
            title={"Add Form to Variant"} // TODO: Translation
            size="small"
            closable
            onCancel={() => updateFormsModal({ formToVariantVisible: false, variant_id: -1, form_id: -1 })}
            onOk={() => handleFormsModalOk()}
            okText="Add Form" // TODO: Translation
            okDisabled={formsModal.form_id === -1 || formsModal.variant_id === -1}
            stacked
            overflow
          >
            <div className="flex justify-between">
              <div className="w-full mr-2">
                <Select
                  label={"Variant"} // TODO: Translation
                  onChange={(value: number) => updateFormsModal({ variant_id: value })}
                  defaultValue={formsModal.variant_id}
                  placeholder="Select Variant..." // TODO: Translation
                >
                  <Select.Option value={-1}>{"None"}</Select.Option>
                  {product?.variants.map(variant => {
                    return (
                      <Select.Option key={variant.id} value={variant.id} name={variant.title}>
                        {variant.title}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>

              <div className="w-full ml-2">
                <Select
                  label={"Form"} //TODO: Translation
                  defaultValue={formsModal.form_id}
                  onChange={(value: number) => updateFormsModal({ form_id: value })}
                  placeholder="Select Form..." //TODO: Translation
                >
                  <Select.Option value={-1}>{"None"}</Select.Option>
                  {forms.all.sort()?.map(form => {
                    return (
                      <Select.Option key={form.id} value={form.id} name={form.title}>
                        {form.title}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          </Sheet>
        </Portal>
      </Page>
    );
  }
}
