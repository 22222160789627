import React, { createContext, useEffect, useState, useRef, forwardRef, useImperativeHandle, useCallback } from "react";
import Spin from "components/spin/spin";
import classNames from "classnames";
import { ConfigContext } from "../config-provider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icon from "components/icon/Icon";
import "./style.scss";
import { ButtonNew as Button } from "components/buttonNew";
import useOnclickOutside from "hooks/useOnclickOutside/useOnclickOutside";
import Input from "components/form/input/Input";
import Portal from "elements/Portal";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface ISelectProps {
  prefixCls?: string;
  className?: string;
  block?: boolean;
  defaultValue?: undefined | string | number;
  onChange?: (value: any, extraValues: any) => void;
  showSearch?: boolean;
  placeholder?: string;
  onSearch?: any;
  loading?: boolean;
  leftIcon?: React.ReactNode;
  label?: string;
  labelAction?: { content: any; onClick: (e: React.MouseEvent<HTMLButtonElement>) => void };
  ref?: any;
  allowClear?: boolean;
  searchValue?: string;
  noData?: React.ReactElement;
  dropDownPositionTop?: boolean;
  inputDropdown?: boolean;
  disabled?: boolean;
  smallDropdownSize?: boolean;
  showDropDownOnFocus?: boolean;
  searching?: boolean;
  hideDropdown?: boolean;
  error?: boolean;
  helpText?: string;
  /**Render dropdown outside root DOM using <Portal> component. Set to true for dropdowns used inside modals that overflow */
  usePortal?: boolean;
  handleClear?: any;
  autoFocus?: boolean;
  timeSelect?: boolean;
  customerSearch?: boolean;
}

export interface ISelectState {
  value: string | number;
  dropdownVisible: boolean;
  search: string;
  focus: boolean;
  extraValues: { [key: string]: any };
  hideDropdown: boolean;
}

export const SelectContext = createContext(null);

const Select: React.FC<ISelectProps> = forwardRef((props, ref) => {
  const {
    children,
    prefixCls = "rc-select",
    block,
    className,
    defaultValue = undefined,
    onChange,
    showSearch,
    placeholder = "Placeholder",
    onSearch,
    loading = false,
    leftIcon,
    label,
    labelAction,
    allowClear,
    searchValue = "",
    noData,
    disabled,
    dropDownPositionTop,
    smallDropdownSize,
    showDropDownOnFocus,
    searching,
    hideDropdown,
    error,
    usePortal,
    autoFocus,
    timeSelect,
    customerSearch,
  } = props;

  const [state, setState] = useState<ISelectState>({
    value: defaultValue,
    dropdownVisible: false,
    hideDropdown: hideDropdown,
    search: searchValue,
    focus: false,
    extraValues: null,
  });

  const updateState = (newState: Partial<ISelectState>) => {
    setState((cur: ISelectState) => {
      return { ...cur, ...newState };
    });
  };

  const positionTopRef = useRef(null);
  const [offSet, setOffset] = useState("67px");
  const [displayClass, setDisplayClass] = useState("rc-select-top-position");

  const [finishedTyping, setFinishedTyping] = useState<boolean>(true);

  const selectRef = useRef<HTMLDivElement>(null);
  const [selectPosition, setSelectPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });
  const scrollableParentElementRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (usePortal) {
      scrollableParentElementRef.current = getOverflowParent(selectRef?.current);
      if (scrollableParentElementRef.current) {
        scrollableParentElementRef.current.addEventListener("scroll", setPositionAfterScroll, { passive: true });
      }
    }
    window.addEventListener("resize", setPositionAfterScroll);
    return () => {
      if (scrollableParentElementRef.current) {
        scrollableParentElementRef.current.removeEventListener("scroll", setPositionAfterScroll);
      }
      window.removeEventListener("resize", setPositionAfterScroll);
    };
  }, []);

  // Handle API/Promised based setting of defaultValue
  useEffect(() => {
    let mounted = true;

    if (defaultValue) {
      if (mounted) {
        updateState({ value: defaultValue });
      }
    }

    return () => {
      mounted = false;
    };
  }, [defaultValue]);

  useEffect(() => {
    if (autoFocus) {
      handleFocus();
    }
  }, [autoFocus]);

  const handleFocus = () => {
    focusInput();
    if (showDropDownOnFocus) {
      updateState({ focus: true, dropdownVisible: true });
    } else {
      updateState({ focus: true });
    }
  };

  const handleBlur = () => {
    updateState({ focus: false });
  };

  const handleInputChange = (e: any) => {
    updateState({ search: e.target.value });
  };

  //Clear search via ref
  useImperativeHandle(ref, () => ({
    clearSearch() {
      updateState({ search: "" });
    },
  }));

  const cls = classNames(prefixCls, className, {
    [`${prefixCls}-block`]: block,
    [`${prefixCls}-hasSearch`]: showSearch,
    [`${prefixCls}-disabled`]: disabled,
    [`${prefixCls}-error`]: error,
  });

  const handleSelect = () => {
    if (!showSearch) {
      updateState({ dropdownVisible: !state.dropdownVisible });
    }

    if (showSearch) {
      inputRef.current.focus();
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const blurInput = () => {
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  const mappedChildren = React.Children.map(children, child => {
    return child;
  });

  useEffect(() => {
    setFinishedTyping(false);
    let mounted = true;
    let timeoutId: any = null;
    const search = () => {
      timeoutId = setTimeout(() => {
        if (mounted) {
          setFinishedTyping(true);
        }
      }, 1000);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [searchValue]);

  const childNode =
    mappedChildren?.length >= 1 ? (
      <ul className="rc-select-dropdown-list">{mappedChildren}</ul>
    ) : (
      noData && searchValue.length > 0 && finishedTyping === true && !searching && noData
    );
  // ) : (
  //   <div className="no-data-container">
  //     <Icon style="fal" icon="inbox" size="medium" /> <div className="no-data-text">No Data</div>
  //   </div>
  // );

  const emptyValue = state.value === undefined || state.value === null || state.value === "";

  useEffect(() => {
    let mounted = true;
    if (dropDownPositionTop && positionTopRef.current) {
      const clientHeight: number = positionTopRef.current.children[0].clientHeight;
      if (mounted) {
        setOffset(`-${clientHeight + 67}px`);
        setTimeout(() => {
          setDisplayClass("rc-select-top-position-visible");
        }, 100);
      }
    }
    return () => {
      mounted = false;
    };
  }, [childNode]);

  const renderSelected = () => {
    if (showSearch) {
      return (
        <>
          <FontAwesomeIcon
            className="rc-select-dropdown-icon"
            icon={["far", customerSearch ? "user" : "magnifying-glass"]}
          />

          <input
            ref={inputRef}
            type="text"
            onChange={handleInputChange}
            value={state.search}
            placeholder={placeholder}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className="rc-select-search text-black font-medium w-full"
            disabled={disabled}
            autoFocus={state.focus}
          />
        </>
      );
    } else {
      return React.Children.map(children, (child, i) => {
        if (React.isValidElement(child)) {
          return (
            <>
              {child.type === React.Fragment
                ? React.Children.map(child.props.children, (fragmentChild, i) => {
                    return (
                      fragmentChild.props.value &&
                      fragmentChild.props.value === state.value && (
                        <span className="rc-select-dropdown-list-item-selected-text">
                          {leftIcon}
                          <span
                            className={
                              props.inputDropdown ? "whitespace-nowrap overflow-hidden overflow-ellipsis w-24" : ""
                            }
                          >
                            {fragmentChild.props.children}
                          </span>
                        </span>
                      )
                    );
                  })
                : child.props.value === state.value && (
                    <span className="rc-select-dropdown-list-item-selected-text">
                      {leftIcon}
                      <span
                        className={
                          props.inputDropdown ? "whitespace-nowrap overflow-hidden overflow-ellipsis w-24" : ""
                        }
                      >
                        {child.props.children}
                      </span>
                    </span>
                  )}
            </>
          );
        }
      });
    }
  };

  useEffect(() => {
    let mounted = true;

    if (onChange) {
      if (state.value !== undefined && state.value !== null) {
        if (mounted) {
          onChange(state.value, state.extraValues);
        }
      }
    }

    return () => {
      mounted = false;
    };
  }, [state.value]);

  useEffect(() => {
    let mounted = true;

    if (onSearch) {
      if (mounted) {
        if (state.search !== undefined) {
          onSearch(state.search);
        }
      }
    }

    return () => {
      mounted = false;
    };
  }, [state.search]);

  useEffect(() => {
    let mounted = true;

    if (showSearch) {
      updateState({ search: searchValue });

      if (emptyValue) {
        if (searchValue.length > 0 && state.dropdownVisible === false) {
          if (mounted) {
            if (!state.hideDropdown) {
              updateState({ dropdownVisible: true });
            } else {
              updateState({ hideDropdown: false });
            }
          }
        }
      }

      if (searchValue.length === 0 && state.dropdownVisible === true) {
        if (state.hideDropdown === undefined) {
          if (mounted) {
            updateState({ dropdownVisible: false });
          }
        }
      }

      if (searchValue.length === 0) {
        if (mounted) {
          updateState({ value: undefined, search: "" });
        }
      }
    }

    return () => {
      mounted = false;
    };
  }, [searchValue]);

  let clearNode: React.ReactNode;

  const onClear = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.stopPropagation();
    if (props.handleClear) {
      props.handleClear();
    }
    updateState({ value: undefined });

    if (showSearch) {
      updateState({ search: "" });
      focusInput();
    }
  };

  if (allowClear) {
    clearNode = (
      <span
        onClick={e => onClear(e)}
        className={classNames(`${prefixCls}-clear`, { [`${prefixCls}-clear-visible`]: !emptyValue })}
      >
        <FontAwesomeIcon icon={["far", "times"]} size="1x" />
      </span>
    );
  }

  const searchIcon = loading && <Spin />;

  const arrowNode =
    props.inputDropdown && !props.disabled ? (
      <span
        className={classNames(`${prefixCls}-arrow-input`, {
          [`${prefixCls}-hide`]: allowClear && !emptyValue,
        })}
      >
        {showSearch ? (
          searchIcon
        ) : (
          <FontAwesomeIcon
            icon={[
              timeSelect ? "fal" : "fas",
              timeSelect ? "clock" : state.dropdownVisible ? "chevron-up" : "chevron-down",
            ]}
            className="rc-select-dropdown-icon"
          />
        )}
      </span>
    ) : (
      <span
        className={classNames(`${prefixCls}-arrow`, {
          [`${prefixCls}-hide`]: allowClear && !emptyValue,
        })}
      >
        {showSearch ? (
          searchIcon
        ) : !props.disabled ? (
          <FontAwesomeIcon
            icon={[
              timeSelect ? "fal" : "fas",
              timeSelect ? "clock" : state.dropdownVisible ? "chevron-up" : "chevron-down",
            ]}
            className="rc-select-dropdown-icon"
          />
        ) : undefined}
      </span>
    );

  const clickOutsideRef = useOnclickOutside(() => {
    if (state.value === undefined && state.focus === true && usePortal === false) {
      updateState({ search: "" });
    }
    updateState({ dropdownVisible: false });
    setDisplayClass("rc-select-top-position");
  });

  /**
   * @description Returns the first overflow parent HTML element found from a starting HTML Element
   * @param {HTMLElement} element - Starting HTML Element
   * @returns HTMLElement
   */
  function getOverflowParent(element: HTMLElement): HTMLElement {
    const overflowYStyling = element && window.getComputedStyle(element).overflowY;
    const scrollable = overflowYStyling !== "visible" && overflowYStyling !== "hidden";

    if (!element) {
      return null;
    } else if (scrollable && element.scrollHeight >= element.clientHeight) {
      // check if element has overflow scrollable css property and the element's contents scroll height >= the element's height
      return element;
    }

    return getOverflowParent(element.parentElement) || document.body;
  }

  function setPositionAfterScroll() {
    setSelectPosition({
      top: selectRef.current?.getBoundingClientRect().y + 45,
      left: selectRef.current?.getBoundingClientRect().x,
    });
  }

  const DropdownWrapper = useCallback(({ usePortal, children }: { usePortal: boolean; children: React.ReactNode }) => {
    return usePortal ? <Portal>{children}</Portal> : <>{children}</>;
  }, []);

  return (
    <SelectContext.Provider value={{ state, updateState, onChange, focusInput, blurInput }}>
      <div className={props.inputDropdown ? "" : "rc-select-container"}>
        {label && (
          <div className="flex flex-wrap justify-between items-end">
            <div className="rc-select-dropdown-label">
              <label>{label}</label>
            </div>
            {labelAction ? (
              <div className="rc-select-dropdown-label">
                <Button className="rc-select-dropdown-label-button" type="link" onClick={labelAction.onClick}>
                  {labelAction.content}
                </Button>
              </div>
            ) : null}
          </div>
        )}
        <div tabIndex={0} className={cls} ref={usePortal ? selectRef : clickOutsideRef} onClick={handleSelect}>
          {renderSelected()}
          {clearNode}
          {arrowNode}
        </div>

        {state.dropdownVisible && !props.disabled && (
          <div
            ref={positionTopRef}
            className={dropDownPositionTop ? displayClass : ""}
            style={{ top: dropDownPositionTop ? offSet : "0" }}
          >
            <DropdownWrapper usePortal={usePortal}>
              <div
                className={classNames(
                  "rc-select-dropdown",
                  {
                    [`${prefixCls}-dropdown-lower`]: label,
                  },
                  smallDropdownSize ? "rc-select-dropdown-small" : "rc-select-dropdown-medium",
                )}
                ref={clickOutsideRef}
                style={
                  usePortal
                    ? {
                        top: selectPosition.top || selectRef.current?.getBoundingClientRect().y + 45,
                        left: selectPosition.left || selectRef.current?.getBoundingClientRect().x,
                        width: selectRef?.current?.clientWidth,
                        zIndex: 115,
                        maxHeight: window.innerHeight - selectRef?.current?.getBoundingClientRect().bottom,
                        minWidth: "unset",
                      }
                    : undefined
                }
              >
                {childNode}
                {searching && (
                  <div className="h-8 overflow-hidden">
                    <Spin />
                  </div>
                )}
              </div>
            </DropdownWrapper>
          </div>
        )}

        {props.helpText ? (
          <div className="rc-select-help-text font-normal text-gray-400 mt-2">
            <span>{props.helpText}</span>
          </div>
        ) : null}
      </div>
    </SelectContext.Provider>
  );
});

Select.displayName = "Select";
export default Select;
