import { APIGet, APIPost, APIPut, APIDelete, APIUrl, AdminType } from "api/protocols";
import { CancelToken } from "axios";
import { ICourse } from "redux/reducers/models/facility";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/facility/course";

type TGetCourse = {
  id?: string | number;
  scoring_only?: boolean; // default = false
};

type TPostCourse = {
  long_name?: string;
  full_name?: string;
  holes: THoleNumbers;
  scoring_only?: boolean;
};

type TPutCourse = {
  course_id: string | number;
  long_name?: string;
  full_name?: string;
  holes?: THoleNumbers;
  scoring_only?: boolean;
};

export type THoleNumbers = 6 | 9 | 12 | 18 | 27 | 36 | 54 | 72;

// GET Course
export async function GetCourse(params: TGetCourse, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<ICourse[]>(APIUrl(AdminType.FacilityAdmin, "/facility/course"), params, useGlobalLoader, token);
}

// POST Course
export async function PostCourse(params: TPostCourse, useGlobalLoader: boolean) {
  return await APIPost(APIUrl(AdminType.FacilityAdmin, "/facility/course"), params, useGlobalLoader);
}

// PUT Course
export async function PutCourse(params: TPutCourse, useGlobalLoader: boolean) {
  return await APIPut(APIUrl(AdminType.FacilityAdmin, "/facility/course"), params, useGlobalLoader);
}
