import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios, { CancelToken } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { StatusCode } from "api/protocols";
import { GetProductTypesClient } from "api/rpc/2022-09/clientAdmin/product/product";
import { TProductType } from "redux/reducers/models/product";

import Page from "components/page/Page";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

export default function ProductTypesClient() {
  const history = useHistory();
  const [productTypes, setProductTypes] = useState<TProductType[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadClientProductTypes(source.token);
    return () => source.cancel();
  }, []);

  async function loadClientProductTypes(token?: CancelToken) {
    if (productTypes !== undefined) {
      setProductTypes(undefined);
    }

    const res = await GetProductTypesClient(null, token ? false : true, token);

    if (res.status !== StatusCode.OK) {
      setProductTypes([]);
      return;
    }

    console.log(res);
    setProductTypes(res.data);
  }

  return (
    <Page
      title="Product Types"
      primaryAction={{
        content: "Add Type", // TODO: Translation
        action: () => history.push("/admin/settings/client-settings/products/types/new"),
        disabled: productTypes === undefined,
      }}
    >
      <DataTable
        columns={[
          { label: "Type", width: "90%" },
          { label: "", width: "10%" },
        ]}
        loading={productTypes === undefined}
      >
        {productTypes?.map((type, index) => (
          <tr
            key={type.id}
            className={type?.locked ? "" : "clickable"}
            onClick={
              type?.locked ? null : () => history.push(`/admin/settings/client-settings/products/types/${type.id}`)
            }
          >
            <td>{type.title}</td>
            <td>{type.locked ? <FontAwesomeIcon icon={["far", "cancel"]} /> : null}</td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
