import React from "react";
import RegisterContext from "../../register/registerNew/RegisterContext";
import NewTeeSheetDetails from "./NewTeeSheetDetails";

export default function TeeSheetDetailsContextProvider() {
  return (
    <RegisterContext>
      <NewTeeSheetDetails />
    </RegisterContext>
  );
}
