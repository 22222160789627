import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetCourse } from "api/rpc/2024-04/masterAdmin/facility/course";

import { showError } from "redux/actions/ui";
import { ICourse } from "redux/reducers/models/facility";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import Page from "components/page/Page";
import { Badge } from "components/badge/Badge";
import DataTable from "pages/secure/master/customer/tabs/houseAccounts/DataTable";

export default function Courses() {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();

  const authPermissions = useAppSelector(store => store.authStore)?.user?.permissions;
  const facilityPermissions = useAppSelector(store => store.facilityStore)?.facility?.permissions;
  const { masterFacilityStore } = useAppSelector(store => store);

  const [courses, setCourses] = useState<ICourse[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadCourses(source.token);
    return () => source.cancel();
  }, [masterFacilityStore.facility]);

  async function loadCourses(token?: CancelToken) {
    if (courses !== undefined) {
      setCourses(undefined);
    }
    const res = await GetCourse(
      {
        scoring_only: true,
        facility_id: masterFacilityStore.facility?.id,
      },
      token ? false : true,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading courses"));
    }

    setCourses(res.status !== StatusCode.OK ? [] : res.data);
  }

  const primaryAction = {
    content: "New Course", // TODO: Translation
    action: () => history.push("/admin/facility/settings/course/new"),
    disabled: courses === undefined || !masterFacilityStore.facility,
  };

  return (
    <Page
      title={"Courses"}
      subtitle={masterFacilityStore.facility ? masterFacilityStore.facility.long_name : "No Facility Selected"}
      narrow
      primaryAction={primaryAction}
    >
      {masterFacilityStore.facility && (
        <DataTable
          columns={[{ label: t("secure.facility.settings.facility.divisions.003") }, { label: "" }]}
          loading={courses === undefined}
        >
          {courses?.map(course => (
            <tr
              key={course.id}
              onClick={() => history.push("/admin/facility/settings/course/" + String(course.id))}
              className="clickable"
            >
              <td>{course.long_name}</td>
              <td>
                {course.scoring_only ? (
                  // TODO: Translation
                  <Badge type="primary">{"Scoring Only"}</Badge>
                ) : null}
              </td>
            </tr>
          ))}
        </DataTable>
      )}
    </Page>
  );
}
