import { APIGet, APIPost, APIPut, APIDelete, StatusCode, IAPIResponse } from "api/protocols";
import { CancelToken } from "axios";
import { IReservationBooking } from "redux/reducers/models/reservations";

const apiVersion = "2022-09";
const adminType = "guest";
const baseReservationsUrl = "/" + apiVersion + "/" + adminType + "/reservation";
const baseReservationBookingUrl = "/" + apiVersion + "/customer/reservation/booking";

interface IGetReservationModule {
  facility_short_name?: string;
  handle?: string;
  id?: number;
}

interface IGetReservationLocation {
  id?: number;
  facility_id?: number;
}

interface IGetReservationSegment {
  id?: number;
  module_id?: number;
  date?: string;
  start_time?: string;
  location_id?: number;
}

interface IPostReservationBooking {
  module_id: number;
  location_id: number;
  facility_id: number;
  segment_ids: Array<number>;
  date: string;
  quantity: number;
  accept_payment_terms: boolean;
  customer_payment_method_id: number;
}

interface IPutReservation {
  id: number;
  segment_ids: Array<number>;
  date: string;
  quantity: number;
  location_id: number;
}

interface IGetReservationBooking {
  id?: number;
  token?: string;
  offset?: number;
  limit?: number;
}

interface IReservationRes extends IAPIResponse {
  data: Array<IReservationBooking>;
}

export async function GetReservationModule(params: IGetReservationModule, useGlobalLoader: boolean) {
  return await APIGet(baseReservationsUrl + "/module", params, useGlobalLoader);
}

export async function GetReservationModuleAll(params: IGetReservationModule, useGlobalLoader: boolean) {
  return await APIGet(baseReservationsUrl + "/module/all", params, useGlobalLoader);
}

export async function GetReservationLocation(params: IGetReservationLocation, useGlobalLoader: boolean) {
  return await APIGet(baseReservationsUrl + "/location", params, useGlobalLoader);
}

export async function GetReservationSegment(params: IGetReservationSegment, useGlobalLoader: boolean) {
  return await APIGet(baseReservationsUrl + "/segment", params, useGlobalLoader);
}

export async function GetReservationBooking(
  params: IGetReservationBooking,
  useGlobalLoader: boolean,
  cancelToken?: CancelToken,
): Promise<IReservationRes> {
  return await APIGet(baseReservationBookingUrl, params, useGlobalLoader, cancelToken);
}

export async function PostReservationBooking(params: IPostReservationBooking, useGlobalLoader: boolean) {
  return await APIPost(baseReservationBookingUrl, params, useGlobalLoader);
}

export async function PutReservationBooking(params: IPutReservation, useGlobalLoader: boolean) {
  return await APIPut(baseReservationBookingUrl, params, useGlobalLoader);
}

export async function CancelReservationBooking(params: { id: number }, useGlobalLoader: boolean) {
  return await APIPut(baseReservationBookingUrl + "/cancel", params, useGlobalLoader);
}
