import { StatusCode } from "api/protocols";
import { GetPrinterAll } from "api/rpc/2022-09/facilityAdmin/facility/printer";
import { store } from "index";
import { isIP } from "is-ip";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import {
  loadFacilityPrinterSettings,
  updateFacilityPrinterSettings,
  updateFacilityTeeSheetSettings,
} from "redux/actions/facility";
import { IOrder } from "redux/reducers/models/order";

interface IEPOSDevice {
  isConnected: boolean;
  DEVICE_TYPE_PRINTER: string;
  connect: (ipAddress: string, port: number, Callback_connect: (data: any) => void) => void;
  onreconnecting: () => void;
  onreconnect: () => void;
  ondisconnect: () => void;
  createDevice: (
    deviceID: any,
    DEVICE_TYPE_PRINTER: string,
    options: any,
    callback: (data: any, code: any) => void,
  ) => void;
}

interface IPrinter {
  brand: string;
  facility_id: number;
  id: number;
  ip_address: string;
  port: number;
  serial_number: null | string;
  title: string;
  EPOSDevice: undefined | IEPOSDevice;
  PrinterObject: undefined | any;
  status: undefined | string;
}

interface IEPOSDeviceConstructor {
  new (): IEPOSDevice;
}
declare const epson: {
  CanvasPrint: (address: any) => void;
  ePOSBuilder: () => void;
  ePOSDevice: IEPOSDeviceConstructor;
  ePOSDeviceConfiguration: (address: any) => void;
  ePOSPrint: (address: any) => void;
};

export interface IChit {
  created_at: string;
  id: number;
  kitchen_location_id: number;
  line_items: ILineItem[];
  sent_at: string;
  status: string;
  table_id: number;
  updated_at: string;
  user_id: number;
  printer: null | IPrinter;
  admin_user: IUser | null;
  table: null | ITable;
}

interface ILineItem {
  product_title: string;
  note: string;
  quantity: number;
  children_line_items: ILineItem[];
}

interface IUser {
  birth_date: null | string;
  client_id: number;
  email: string | null;
  facility_id: number;
  first_name: string;
  full_name: string;
  guest: number;
  id: number;
  initials: string;
  last_name: string;
  member_number: null;
  permission_id: null;
  phone: null;
  primary_locale: string;
  primary_locale_country: string;
  profile_image_source: null | string;
  profile_image_source_path: null | string;
  quick_code: null | string;
  state: null | string;
  user_type: number;
  username: null | string;
}
interface ITable {
  archived_at: null | string;
  created_at: null | string;
  deleted_at: null | string;
  facility_id: number;
  floor_plan_id: number;
  hidden: number;
  id: number;
  open: boolean;
  open_time: string;
  register_group_id: number;
  rotation: number;
  seats: number;
  short_title: string;
  table_element_id: number;
  table_section_id: null | number;
  title: string;
  token: string;
  type: null | string;
  updated_at: string | null;
  user_id: number | null;
  x_coordinate: number | null;
  y_coordinate: number | null;
}
interface IUser {
  birth_date: null | string;
  client_id: number;
  email: null | string;
  facility_id: number;
  first_name: string;
  full_name: string;
  guest: number;
  id: number;
  initials: string;
  last_name: string;
  phone: null;
  primary_locale: string;
  primary_locale_country: string;
  profile_image_source: null | string;
  profile_image_source_path: null | string;
  quick_code: null | string;
  state: null | string;
  user_import_id: null | number;
  user_type: number;
  username: string | null;
}

export enum PrinterStatus {
  Disconnected = "DISCONNECTED",
  Connecting = "CONNECTING",
  Printing = "PRINTING",
  Success = "SUCCESS",
  Error = "ERROR",
  Connected = "CONNECTED",
}

export const usePOSPrinter = () => {
  const [serverURI, setServerURI] = useState<string>("");
  const [printerStatus, setPrinterStatus] = useState<PrinterStatus>(PrinterStatus.Disconnected);
  const [defaultPrinter, setDefaultPrinter] = useState<IPrinter | undefined>(undefined);
  const [printers, setPrinters] = useState<IPrinter[]>([]);
  const [loadingPrinters, setLoadingPrinters] = useState<boolean>(false);

  useEffect(() => {
    getDefaultPOSPrinter();
  }, []);

  useEffect(() => {
    console.log(store.getState().facilityStore.printerSettings);
  }, [
    store.getState().facilityStore?.printerSettings?.print_server_ip,
    store.getState().facilityStore?.printerSettings?.print_server_port,
  ]);

  function reqListener() {
    console.log(this.responseText);
  }
  const updatePOSServerUri = async (address: string, port: number) => {
    if (isIP(address) && port > -1) {
      await store.dispatch(updateFacilityPrinterSettings({ print_server_port: port, print_server_ip: address }, false));
    }
  };

  const printChits = (_chits: IChit[]) => {
    _chits.forEach(c => {
      console.log(c);
      if (c.printer !== null) {
        printerHttp(
          `https://${String(store.getState().facilityStore.printerSettings.print_server_ip)}:${String(
            store.getState().facilityStore.printerSettings.print_server_port,
          )}/print/chit`,
          c.printer,
          c,
        );
      }
    });
  };
  const printerHttp = (address: string, printer: IPrinter, printObject: any) => {
    if (
      store.getState().facilityStore.printerSettings.print_server_port &&
      store.getState().facilityStore.printerSettings.print_server_ip
    ) {
      const req = new XMLHttpRequest();
      console.log(address);

      req.addEventListener("load", reqListener);
      req.open("POST", address);
      req.setRequestHeader("Content-Type", "application/json;charset=UTF-8");

      req.send(JSON.stringify({ printer, printObject }));
    }
  };

  const loadPrinters = async () => {
    setLoadingPrinters(true);
    const printerRes = await GetPrinterAll(undefined, false);

    if (printerRes.status === StatusCode.OK) {
      setPrinters([...printerRes.data]);
    }
    setLoadingPrinters(false);
  };

  const getDefaultPOSPrinter = () => {
    const _p = localStorage.getItem("defaultPrinter");

    if (_p !== null) {
      const printer = JSON.parse(_p) as IPrinter;
      console.log(printer);
      setDefaultPrinter(printer);
      return printer;
    } else {
      setDefaultPrinter(undefined);
      return undefined;
    }
  };

  const setDefaultPOSPrinter = (printer?: IPrinter) => {
    if (printer) {
      setDefaultPrinter(printer);
      localStorage.setItem("defaultPrinter", JSON.stringify(printer));
    } else {
      localStorage.removeItem("defaultPrinter");
      setDefaultPrinter(undefined);
    }
    setPrinterStatus(PrinterStatus.Disconnected);
  };

  function uiMessage(message: string, type: "error" | "success") {
    if (type === "error") {
      store.dispatch({ type: "error.show", payload: { message, duration: 1500 } });
    } else {
      store.dispatch({ type: "success.show", payload: { message, duration: 1500 } });
    }
  }

  return {
    updatePOSServerUri,
    printChits,
    loadPrinters,
    loadingPrinters,
    printers,
    printerStatus,
    defaultPrinter,
    setDefaultPOSPrinter,
    getDefaultPOSPrinter,
    serverURI,
  };
};
