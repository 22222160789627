import { APIGet, APIPost, APIPut, APIDelete, APIUrl, AdminType } from "api/protocols";
import { CancelToken } from "axios";
import { IBookingCategory } from "redux/reducers/models/teetime";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/tee-sheet/booking-category";

interface IGetBookingCategory {
  id?: number;
  client_id?: number;
}

interface IPutBookingCategory {
  id: number;
  title: string;
}

interface IPostBookingCategory {
  title: string;
}

// GET Booking Categories
export async function GetBookingCategories(params: IGetBookingCategory, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<IBookingCategory[]>(
    APIUrl(AdminType.FacilityAdmin, "/tee-sheet/booking-category"),
    params,
    useGlobalLoader,
    token,
  );
}

// PUT Booking Category
export async function PutBookingCategory(params: IPutBookingCategory, useGlobalLoader: boolean) {
  return await APIPut(APIUrl(AdminType.FacilityAdmin, "/tee-sheet/booking-category"), params, useGlobalLoader);
}

// POST Booking Category
export async function PostBookingCategory(params: IPostBookingCategory, useGlobalLoader: boolean) {
  return await APIPost(APIUrl(AdminType.FacilityAdmin, "/tee-sheet/booking-category"), params, useGlobalLoader);
}
