import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { showError, showSuccess } from "redux/actions/ui";

import Page from "components/page/Page";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Form from "components/form/Form";
import Input from "components/form/input/Input";
import { Select } from "components/select/index";
import { StatusCode } from "api/protocols";

import { PostCustomerType } from "api/rpc/2024-04/masterAdmin/client/settings/customerType";
import { GetBookingCategories } from "api/rpc/2024-04/masterAdmin/client/settings/bookingCategory";
import { IBookingCategory } from "redux/reducers/models/teetime";

import { useTranslation } from "react-i18next";

interface IApplicationOption {
  label: string;
  value: string;
}

interface ICustomerTypeState {
  title: string;
  application: string;
  application_options: Array<IApplicationOption>;
  default_booking_category_id: number;
  categories: Array<IBookingCategory>;
  subtitle: string;
}

export default function CustomerTypeNew(props: any) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const { Option } = Select;
  const { masterClientStore } = useAppSelector(store => store);

  const [customerTypeState, setCustomerTypeState] = useState<ICustomerTypeState>({
    title: "",
    application: "",
    application_options: [
      {
        label: "Green Fee",
        value: "green_fee",
      },
      {
        label: "Power Cart",
        value: "power_cart",
      },
    ],
    default_booking_category_id: null,
    categories: [],
    subtitle: "",
  });

  useEffect(() => {
    if (masterClientStore.client) {
      void loadBookingCategories();
    }
  }, [masterClientStore.client]);

  async function loadBookingCategories() {
    const categoryRes = await GetBookingCategories(
      {
        client_id: masterClientStore.client?.id,
      },
      true,
    );

    if (categoryRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading booking categories"));
      return;
    }

    setCustomerTypeState(prevState => ({ ...prevState, categories: categoryRes.data }));
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setCustomerTypeState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleDropDownChange(value: any, property: string) {
    setCustomerTypeState(prevState => ({ ...prevState, [property]: value }));
  }

  async function saveNewCustomerType() {
    const typeRes = await PostCustomerType(
      {
        client_id: masterClientStore.client?.id,
        ...customerTypeState,
      },
      true,
    );

    if (typeRes.status !== StatusCode.OK) {
      dispatch(showError("Error creating customer type"));
      return;
    }

    dispatch(showError("Customer type created successfully"));
    history.push("/admin/client-settings/customer-type");
  }

  const primaryAction = {
    content: "Create",
    action: saveNewCustomerType,
    disabled: !masterClientStore.client,
  };

  return (
    <Page
      title={"New Customer Type"}
      subtitle={masterClientStore.client ? masterClientStore.client.full_name : "No Client Selected"}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: "Back to customer types",
          url: "/admin/client-settings/customer-type",
        },
      ]}
    >
      {masterClientStore.client && (
        <Card>
          <Card.Section>
            <Form>
              <FormLayout>
                <FormLayout.Group>
                  <Input value={customerTypeState.title} label={"Title"} id="title" onChange={handleInputChange} />
                  <Input
                    value={customerTypeState.subtitle}
                    label={"Subtitle"}
                    id="subtitle"
                    onChange={handleInputChange}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Select label={"Application"} onChange={(value: any) => handleDropDownChange(value, "application")}>
                    {customerTypeState.application_options.map((option: IApplicationOption, index: number) => (
                      <Option key={index} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                  <Select
                    label={"Default Booking Category"}
                    onChange={(value: any) => handleDropDownChange(value, "default_booking_category_id")}
                  >
                    {customerTypeState.categories.map((category: IBookingCategory, index: number) => (
                      <Option key={index} value={category.id}>
                        {category.title}
                      </Option>
                    ))}
                  </Select>
                </FormLayout.Group>
              </FormLayout>
            </Form>
          </Card.Section>
        </Card>
      )}
    </Page>
  );
}
