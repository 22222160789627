import React from "react";
import { IFacility } from "pages/guest/models/facility";
import "./LeagueHeader.scss";
import { ILeague } from "redux/reducers/models/league";
import { useHistory } from "react-router";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { RegistrationNavigationMenu } from "components/registrationNavigationMenu/RegistrationNavigationMenu";
import LeagueTitle from "../LeagueTitle/LeagueTitle";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { NavigationDropdownNew } from "components/navigationDropdownNew/NavigationDropdownNew";
import { MOBILE_WIDTH } from "helpers/ScreenSizes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ILeagueHeaderProps {
  facility: IFacility;
  league: ILeague;
}

export default function LeagueHeader(props: ILeagueHeaderProps) {
  const { facility, league } = props;
  const history = useHistory();
  const windowSize = useWindowSize();
  const { t, i18n } = useTranslation();

  function constructPath(pathEnding: string) {
    return `/league/${facility.short_name}/${league.handle}/${pathEnding}`;
  }

  function doesPathMatchLocation(pathEnding: string) {
    return history.location.pathname === constructPath(pathEnding);
  }

  function navigateTo(pathEnding: string) {
    history.push(constructPath(pathEnding));
  }

  if (facility && league) {
    return (
      <div className="league-header-container">
        <div className="league-header">
          <div className="league-header-title">
            <img className="league-header-facility-logo" src={league.logo_source} />
            <LeagueTitle leagueName={league.name} facilityName={facility.long_name} />
          </div>
          {windowSize.width > MOBILE_WIDTH ? (
            <div className="league-header-navigation">
              <div
                className={classNames("league-header-navigation-item", {
                  "league-header-navigation-item-selected": doesPathMatchLocation("home"),
                })}
                onClick={() => navigateTo("home")}
              >
                {"Home" /* TODO: Translation */}
              </div>
              <div
                className={classNames("league-header-navigation-item", {
                  "league-header-navigation-item-selected": doesPathMatchLocation("register"),
                })}
                onClick={() => navigateTo("register")}
              >
                {t("guest.league.league_header.league_header.001")}
              </div>

              <div
                className={classNames("league-header-navigation-item", {
                  "league-header-navigation-item-selected": doesPathMatchLocation("announcements"),
                })}
                onClick={() => navigateTo("announcements")}
              >
                {"Announcements" /* TODO: Translation */}
              </div>
              <div
                className={classNames("league-header-navigation-item", {
                  "league-header-navigation-item-selected": doesPathMatchLocation("scoring/season-leaderboard"),
                })}
                onClick={() => navigateTo("scoring/season-leaderboard")}
              >
                {"Season Leaderboards" /* TODO: Translation */}
              </div>
              <div
                className={classNames("league-header-navigation-item", {
                  "league-header-navigation-item-selected": doesPathMatchLocation("scoring"),
                })}
                onClick={() => navigateTo("scoring")}
              >
                {"Weekly Results" /* TODO: Translation */}
              </div>
            </div>
          ) : (
            <NavigationDropdownNew
              showPlainTextLabel
              rightAlign
              label={<FontAwesomeIcon icon={["far", "bars"]} size="lg" />}
              sections={[
                [
                  {
                    icon: "pencil",
                    label: t("guest.league.league_header.league_header.001"),
                    onClick: () => navigateTo("register"),
                  },
                ],
                [
                  {
                    icon: "square-poll-vertical",
                    label: t("guest.league.league_header.league_header.003"),
                    onClick: () => navigateTo("scoring/season-leaderboard"),
                  },
                  {
                    icon: "stars",
                    label: t("guest.league.league_header.league_header.004"),
                    onClick: () => navigateTo("scoring"),
                  },
                ],
              ]}
            />
          )}
        </div>
      </div>
    );
  } else {
    return null;
  }
}
