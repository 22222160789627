import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  ReactFlow,
  Background,
  addEdge,
  BackgroundVariant,
  useReactFlow,
  ReactFlowProvider,
  applyNodeChanges,
  OnNodesChange,
  OnConnect,
  OnConnectStart,
  OnConnectEnd,
  OnEdgesChange,
  Edge,
  applyEdgeChanges,
  useOnViewportChange,
  Viewport,
  useNodesInitialized,
  Rect,
  clamp,
  OnInit,
} from "reactflow";
import "reactflow/dist/style.css";
import DynamicPricingRootNode, {
  DYNAMIC_PRICING_ROOT_NODE,
  DYNAMIC_PRICING_ROOT_NODE_ID,
  IDynamicPricingRootNode,
} from "./dynamicPricingNodes/DynamicPricingRootNode";
import DynamicPricingFactorNode, {
  DYNAMIC_PRICING_FACTOR_NODE,
  DYNAMIC_PRICING_FACTOR_NODE_CLASS_NAME,
  DYNAMIC_PRICING_FACTOR_NODE_HEIGHT,
  DynamicPricingFactorNodeData,
  IDynamicPricingFactorNode,
  isFactorValid,
} from "./dynamicPricingNodes/DynamicPricingFactorNode";
import DynamicPricingFactorGroupNode, {
  DYNAMIC_PRICING_FACTOR_GROUP_NODE,
  DYNAMIC_PRICING_FACTOR_GROUP_NODE_CLASS_NAME,
  DYNAMIC_PRICING_FACTOR_GROUP_VARIATION_ID,
  DYNAMIC_PRICING_FACTOR_GROUP_NODE_WIDTH,
  IDynamicPricingFactorGroupNode,
  DynamicPricingFactorValue,
  DynamicPricingFactorValueType,
  getDefaultFactorValue,
  DYNAMIC_PRICING_FACTOR_GROUP_VARIATION_WIDTH,
} from "./dynamicPricingNodes/DynamicPricingFactorGroupNode";
import { DynamicPricingContext } from "./DynamicPricingContext";
import { Dictionary, cloneDeep, differenceBy, flatten, groupBy, isEqual, max } from "lodash";
import DynamicPricingActionPanel from "./dynamicPricingActionPanel/DynamicPricingActionPanel";
import { useAppDispatch } from "hooks/redux";
import { dequeue, enqueue, showError, showSuccess } from "redux/actions/ui";
import {
  convert24HourTimeStringToFactorTime,
  convertFactorTimeTo24HourTimeString,
  factorTimeToMinutes,
} from "./dynamicPricingNodes/factorNodeEditModes/FactorNodeTimeRangeEditMode";
import { useHistory, useParams } from "react-router";
import Popup from "components/popup/Popup";
import Sheet from "components/sheet/Sheet";
import Input from "components/form/input/Input";
import FormLayout from "components/form/FormLayout";
import Checkbox from "components/form/checkbox/Checkbox";
import {
  IDynamicPricingFactor,
  IDynamicPricingGroup,
  IDynamicPricingTemplate,
} from "redux/reducers/models/dynamicPricing";
import {
  DeleteDynamicPricingFactors,
  DeleteDynamicPricingTemplate,
  GetDynamicPricingFactors,
  GetDynamicPricingGroups,
  GetDynamicPricingTemplates,
  INewDynamicPricingFactor,
  IUpdatedDynamicPricingFactor,
  PostDynamicPricingFactors,
  PutDynamicPricingFactors,
  PutDynamicPricingTemplate,
} from "api/rpc/2024-04/facilityAdmin/teesheet/dynamicPricing";
import { StatusCode } from "api/protocols";
import { isEmpty } from "helpers/Helpers";
import Callout from "components/callout/Callout";
import "./teeSheetDynamicPricingEdit.scss";

export default function TeeSheetDynamicPricingEditWithProvider() {
  return (
    <ReactFlowProvider>
      <TeeSheetDynamicPricingEdit />
    </ReactFlowProvider>
  );
}

type DynamicPricingNode = IDynamicPricingRootNode | IDynamicPricingFactorGroupNode | IDynamicPricingFactorNode;

interface IDynamicPricingEdgeData {
  targetGroupId: string;
}

export type DynamicPricingEdge = Edge<IDynamicPricingEdgeData>;

interface IDynamicPricingTreeNode {
  node: IDynamicPricingFactorNode;
  children: IDynamicPricingTreeNode[];
}

interface IDynamicPricingTree {
  root: IDynamicPricingRootNode;
  children: IDynamicPricingTreeNode[];
}

type DynamicPricingTreeValidationCheck =
  | {
      outcome: "success";
    }
  | {
      outcome: "failure";
      reason: "invalid_factor" | "factor_type_mismatch" | "overlapping_factor_ranges";
    };

interface IDynamicPricingConnectingNode {
  id: string;
  targetGroupId: string;
  path: string;
}

export interface IDynamicPricingSelectedNode {
  id: string;
  isGroup: boolean;
  isVariation: boolean;
}

interface IDynamicPricingState {
  template: IDynamicPricingTemplate;
  nodes: DynamicPricingNode[];
  edges: DynamicPricingEdge[];
  groupHeight: number;
  selectedNode: IDynamicPricingSelectedNode;
  templateLoaded: boolean;
  groupsLoaded: boolean;
  factorsLoaded: boolean;
  zoomOnLoadOccured: boolean;
  deletePopupOpen: boolean;
  isIncompatibleDevice: boolean;
}

interface IDynamicPricingEditTemplateState {
  open: boolean;
  title: string;
  active: boolean;
}

interface IDynamicPricingTrackedStateBeforeChanges {
  nodes: DynamicPricingNode[];
  edges: DynamicPricingEdge[];
  groupHeight: number;
}

const nodeTypes = {
  [DYNAMIC_PRICING_ROOT_NODE]: DynamicPricingRootNode,
  [DYNAMIC_PRICING_FACTOR_GROUP_NODE]: DynamicPricingFactorGroupNode,
  [DYNAMIC_PRICING_FACTOR_NODE]: DynamicPricingFactorNode,
};

const FACTOR_GROUP_NODE_Y_POSITION = 50;
const DEFAULT_GROUP_HEIGHT = 600;
const ROOT_NODE_X_POSITION = 175;
const STARTING_ROOT_NODE_Y_POSITION = DEFAULT_GROUP_HEIGHT / 2 + FACTOR_GROUP_NODE_Y_POSITION;
const STARTING_FACTOR_GROUP_NODE_X_POSITION = 400;
const FACTOR_GROUP_GAP = 50;
const MAXIMUM_ZOOM = 2;
const MINIMUM_ZOOM = 0.5;

export function areFactorValuesOverlapping(values: DynamicPricingFactorValue[]) {
  if (values == null || values.length === 0 || values.some(value => value.valueType !== values[0].valueType)) {
    return false;
  }

  const valueType = values[0].valueType;

  if (valueType === "number_range" || valueType === "time_range" || valueType === "percent_range") {
    const ranges: Array<{ start: number; end: number }> = values.map(value => {
      switch (value.valueType) {
        case "number_range":
          return { start: value.startNumber, end: value.endNumber };
        case "time_range":
          return { start: factorTimeToMinutes(value.startTime), end: factorTimeToMinutes(value.endTime) };
        case "percent_range":
          return { start: value.startPercent, end: value.endPercent };
      }
    });

    ranges.sort((a, b) => a.start - b.start);

    for (let i = 0; i < ranges.length; i++) {
      if (i < ranges.length - 1 && ranges[i].end >= ranges[i + 1].start) {
        return true;
      }
    }

    return false;
  } else {
    return false;
  }
}

function TeeSheetDynamicPricingEdit() {
  const { screenToFlowPosition, getNode, zoomIn, zoomOut, fitBounds } = useReactFlow();

  const dispatch = useAppDispatch();

  const { templateId } = useParams<{ templateId: string }>();

  const history = useHistory();

  const flowActionPanelRef = useRef<HTMLDivElement>(null);
  const connectingNode = useRef<IDynamicPricingConnectingNode>(null);
  const [zoom, setZoom] = useState<number>(null);

  const id = useRef<number>(1);
  const getId = () => `${id.current++}`;

  useOnViewportChange({
    onStart: (viewport: Viewport) => setZoom(viewport.zoom),
    onChange: (viewport: Viewport) => setZoom(viewport.zoom),
    onEnd: (viewport: Viewport) => setZoom(viewport.zoom),
  });

  const [state, setState] = useState<IDynamicPricingState>({
    template: null,
    nodes: [],
    edges: [],
    groupHeight: DEFAULT_GROUP_HEIGHT,
    selectedNode: null,
    templateLoaded: false,
    groupsLoaded: false,
    factorsLoaded: false,
    zoomOnLoadOccured: false,
    deletePopupOpen: false,
    isIncompatibleDevice: false,
  });

  const interactivityEnabled = state.templateLoaded && state.groupsLoaded && state.factorsLoaded;

  const [editTemplateState, setEditTemplateState] = useState<IDynamicPricingEditTemplateState>({
    open: false,
    title: "",
    active: false,
  });

  const [trackedStateBeforeChanges, setTrackedStateBeforeChanges] =
    useState<IDynamicPricingTrackedStateBeforeChanges>(null);

  const nodesInitialized = useNodesInitialized();

  function determineDeviceIncompatibility() {
    if (navigator.maxTouchPoints > 0) {
      return true;
    } else {
      const mQ = window.matchMedia ? matchMedia("(pointer:coarse)") : null;

      if (mQ?.media === "(pointer:coarse)" && !!mQ.matches) {
        return true;
      } else if ("orientation" in window) {
        return true;
      } else {
        const UA = navigator.userAgent;

        return /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }
  }

  async function loadTemplateAndGroups() {
    dispatch(enqueue());

    const getTemplateResponse = await GetDynamicPricingTemplates({ id: Number(templateId) }, false);

    if (
      getTemplateResponse.status !== StatusCode.OK ||
      getTemplateResponse.data == null ||
      getTemplateResponse.data.length === 0
    ) {
      dispatch(showError("Error loading template"));
      dispatch(dequeue());
      return;
    }

    setState(prev => ({
      ...prev,
      template: getTemplateResponse.data[0],
      templateLoaded: true,
    }));

    const isIncompatibleDevice = determineDeviceIncompatibility();

    if (isIncompatibleDevice) {
      dispatch(dequeue());
      setState(prev => ({
        ...prev,
        isIncompatibleDevice,
      }));
      return;
    }

    const getGroupsResponse = await GetDynamicPricingGroups({ template_id: Number(templateId) }, false);

    if (getGroupsResponse.status !== StatusCode.OK) {
      dispatch(showError("Error loading groups"));
      dispatch(dequeue());
      return;
    }

    const factorGroups: IDynamicPricingGroup[] = getGroupsResponse.data;

    factorGroups.sort((a, b) => a.order - b.order);

    const factorGroupNodes: IDynamicPricingFactorGroupNode[] = [];

    let x = STARTING_FACTOR_GROUP_NODE_X_POSITION;

    for (let i = 0; i < factorGroups.length; i++) {
      const factorGroup = factorGroups[i];
      const nextGroupId = i === factorGroups.length - 1 ? null : factorGroups[i + 1].token;

      factorGroupNodes.push({
        id: factorGroup.token,
        type: DYNAMIC_PRICING_FACTOR_GROUP_NODE,
        position: {
          x,
          y: FACTOR_GROUP_NODE_Y_POSITION,
        },
        data: {
          primaryKey: factorGroup.id,
          title: factorGroup.title,
          valueType: factorGroup.value_type,
          order: factorGroup.order,
          nextGroupId,
          incomingBranchingLimit: factorGroup.incoming_branching_limit,
        },
        draggable: false,
      });

      x += DYNAMIC_PRICING_FACTOR_GROUP_NODE_WIDTH + FACTOR_GROUP_GAP;
    }

    const rootNode: IDynamicPricingRootNode = {
      id: DYNAMIC_PRICING_ROOT_NODE_ID,
      type: DYNAMIC_PRICING_ROOT_NODE,
      position: {
        x: ROOT_NODE_X_POSITION,
        y: STARTING_ROOT_NODE_Y_POSITION,
      },
      data: {
        nextGroupId: factorGroupNodes?.[0]?.id,
        outgoingBranchingLimit: factorGroupNodes?.[0]?.data?.incomingBranchingLimit,
      },
      draggable: false,
    };

    setState(prev => ({
      ...prev,
      nodes: [...prev.nodes, rootNode, ...factorGroupNodes],
      groupsLoaded: true,
    }));

    dispatch(dequeue());
  }

  useEffect(() => {
    void loadTemplateAndGroups();
  }, []);

  async function loadFactors() {
    const GROUP_BOTTOM_PADDING = 50;

    const getFactorsResponse = await GetDynamicPricingFactors({ template_id: Number(templateId) }, true);

    if (getFactorsResponse.status !== StatusCode.OK) {
      dispatch(showError("Error loading factors"));
      return;
    }

    const factors = getFactorsResponse.data;

    const variationFactors = factors.filter(
      factor => factor.dynamic_pricing_group_token === DYNAMIC_PRICING_FACTOR_GROUP_VARIATION_ID,
    );

    const factorNodes: IDynamicPricingFactorNode[] = factors.map(factor => {
      const splitPath = factor.path.split("/");
      const parentId = splitPath[splitPath.length - 2];

      const groupElement = document.getElementById(factor.dynamic_pricing_group_token) as HTMLDivElement;
      const groupElementBoundingRectangle = groupElement.getBoundingClientRect();

      const xPosition = screenToFlowPosition({
        x: groupElementBoundingRectangle.x + groupElementBoundingRectangle.width / 2,
        y: 0,
      }).x;

      const groupNode = getNode(factor.dynamic_pricing_group_token) as IDynamicPricingFactorGroupNode;

      const nextGroupId = groupNode?.data?.nextGroupId;

      let nextGroupNode: IDynamicPricingFactorGroupNode = null;

      if (nextGroupId) {
        nextGroupNode = getNode(nextGroupId) as IDynamicPricingFactorGroupNode;
      }

      let factorValue: DynamicPricingFactorValue = null;

      switch (factor.value_type) {
        case "number_range":
          factorValue = {
            valueType: "number_range",
            startNumber: Number(factor.range_start),
            endNumber: Number(factor.range_end),
          };
          break;
        case "time_range":
          factorValue = {
            valueType: "time_range",
            startTime: convert24HourTimeStringToFactorTime(factor.range_start),
            endTime: convert24HourTimeStringToFactorTime(factor.range_end),
          };
          break;
        case "percent_range":
          factorValue = {
            valueType: "percent_range",
            startPercent: Number(factor.range_start),
            endPercent: Number(factor.range_end),
          };
          break;
        case "percent_variation":
          factorValue = {
            valueType: "percent_variation",
            percentVariation: Number(factor.value),
          };
          break;
      }

      let connectedVariationId: string = null;

      if (nextGroupId === DYNAMIC_PRICING_FACTOR_GROUP_VARIATION_ID) {
        connectedVariationId = variationFactors.find(variationFactor => {
          const splitPath = variationFactor.path.split("/");
          const parentId = splitPath[splitPath.length - 2];

          return parentId === factor.token;
        })?.token;
      }

      const factorNode: IDynamicPricingFactorNode = {
        id: factor.token,
        type: DYNAMIC_PRICING_FACTOR_NODE,
        dragHandle: `.${DYNAMIC_PRICING_FACTOR_NODE_CLASS_NAME}`,
        position: {
          x: xPosition,
          y: factor.y_position,
        },
        data: {
          primaryKey: factor.id,
          groupForeignKey: factor.dynamic_pricing_group_id,
          parentId,
          groupElement,
          groupId: factor.dynamic_pricing_group_token,
          nextGroupId,
          connectedVariationId,
          path: factor.path,
          outgoingBranchingLimit: nextGroupNode?.data?.incomingBranchingLimit ?? 0,
          ...factorValue,
        },
      };

      return factorNode;
    });

    factorNodes.forEach(factorNode => {
      const factorNodeId = Number(factorNode.id);

      if (factorNodeId >= id.current) {
        id.current = factorNodeId + 1;
      }
    });

    const nodeReuseMap = new Map<string, DynamicPricingNode>();

    const variationFactorPaths: DynamicPricingNode[][] = variationFactors.map(variationFactor => {
      const splitPath = variationFactor.path.split("/");

      return splitPath.map(id => {
        if (nodeReuseMap.has(id)) {
          return cloneDeep(nodeReuseMap.get(id));
        } else if (id === DYNAMIC_PRICING_ROOT_NODE_ID) {
          const rootNode = state.nodes.find(node => node.id === id);
          nodeReuseMap.set(id, rootNode);
          return rootNode;
        } else {
          const factorNode = factorNodes.find(factorNode => factorNode.id === id);
          nodeReuseMap.set(id, factorNode);
          return factorNode;
        }
      });
    });

    const edgeMap = new Map<string, DynamicPricingEdge>();

    function isFactorNode(node: DynamicPricingNode): node is IDynamicPricingFactorNode {
      return (node as IDynamicPricingFactorNode).data?.groupId != null;
    }

    variationFactorPaths.forEach(variationFactorPath => {
      for (let i = 0; i < variationFactorPath.length - 1; i++) {
        const source = variationFactorPath[i];
        const target = variationFactorPath[i + 1];
        const edgeId = `e${source.id}-${target.id}`;

        const targetGroupId = isFactorNode(target) ? target.data.groupId : null;

        if (!edgeMap.has(edgeId)) {
          edgeMap.set(edgeId, {
            id: edgeId,
            source: source.id,
            target: target.id,
            data: {
              targetGroupId,
            },
          });
        }
      }
    });

    const edges = Array.from(edgeMap.values());

    let updatedGroupHeight = state.groupHeight;

    if (factorNodes?.length > 0) {
      const maxFactorNodeYPosition = factorNodes.reduce((prev, next) => {
        const prevY = prev.position.y;
        const nextY = next.position.y;

        return prevY > nextY ? prev : next;
      }).position.y;

      updatedGroupHeight = max([
        maxFactorNodeYPosition - FACTOR_GROUP_NODE_Y_POSITION + GROUP_BOTTOM_PADDING,
        DEFAULT_GROUP_HEIGHT,
      ]);
    }

    const updatedNodes: DynamicPricingNode[] = state.nodes.concat(factorNodes).map(node => {
      if (node.id === DYNAMIC_PRICING_ROOT_NODE_ID) {
        return {
          ...node,
          position: {
            ...node.position,
            y: FACTOR_GROUP_NODE_Y_POSITION + updatedGroupHeight / 2,
          },
        };
      } else {
        return node;
      }
    });

    const updatedEdges: DynamicPricingEdge[] = state.edges.concat(edges);

    setState(prev => ({
      ...prev,
      nodes: updatedNodes,
      edges: updatedEdges,
      groupHeight: updatedGroupHeight,
      factorsLoaded: true,
    }));

    setTrackedStateBeforeChanges(prev => ({
      ...prev,
      nodes: updatedNodes,
      edges: updatedEdges,
      groupHeight: updatedGroupHeight,
    }));
  }

  useEffect(() => {
    if (state.groupsLoaded && nodesInitialized && trackedStateBeforeChanges == null) {
      void loadFactors();
    }
  }, [state.groupsLoaded, nodesInitialized, trackedStateBeforeChanges]);

  useEffect(() => {
    if (!state.zoomOnLoadOccured && state.factorsLoaded && nodesInitialized && state.nodes?.length > 0) {
      zoomToFit(state.nodes, state.groupHeight, 1000);
      setState(prev => ({ ...prev, zoomOnLoadOccured: true }));
    }
  }, [state.zoomOnLoadOccured, state.factorsLoaded, nodesInitialized, state.nodes, state.groupHeight]);

  function zoomToFit(nodes: DynamicPricingNode[], groupHeight: number, duration = 0) {
    const rootNode = nodes?.find(node => node.id === DYNAMIC_PRICING_ROOT_NODE_ID);
    const variationsGroupNode = nodes?.find(node => node.id === DYNAMIC_PRICING_FACTOR_GROUP_VARIATION_ID);

    if (rootNode && variationsGroupNode) {
      const ZOOM_PADDING = FACTOR_GROUP_GAP;

      const flowBounds: Rect = {
        x: rootNode.position.x - ZOOM_PADDING,
        y: variationsGroupNode.position.y - ZOOM_PADDING,
        width:
          variationsGroupNode.position.x +
          DYNAMIC_PRICING_FACTOR_GROUP_VARIATION_WIDTH -
          rootNode.position.x +
          ZOOM_PADDING * 2,
        height: groupHeight + ZOOM_PADDING * 2,
      };

      fitBounds(flowBounds, { duration, padding: 0 });
    }
  }

  const unsavedChangesExist = doUnsavedChangesExist();

  function isNodeEqual(first: DynamicPricingNode, second: DynamicPricingNode) {
    return (
      first.id === second.id &&
      first.type === second.type &&
      isEqual(first.data, second.data) &&
      isEqual(first.position, second.position)
    );
  }

  function isEdgeEqual(first: DynamicPricingEdge, second: DynamicPricingEdge) {
    return (
      first.id === second.id &&
      first.source === second.source &&
      first.target === second.target &&
      isEqual(first.data, second.data)
    );
  }

  function doUnsavedChangesExist() {
    if (trackedStateBeforeChanges == null) {
      return false;
    }

    return (
      state.nodes.length !== trackedStateBeforeChanges.nodes.length ||
      !state.nodes.every((node, i) => isNodeEqual(node, trackedStateBeforeChanges.nodes[i])) ||
      state.edges.length !== trackedStateBeforeChanges.edges.length ||
      !state.edges.every((edge, i) => isEdgeEqual(edge, trackedStateBeforeChanges.edges[i])) ||
      state.groupHeight !== trackedStateBeforeChanges.groupHeight
    );
  }

  const onInit: OnInit = useCallback(
    reactFlowInstance => {
      setZoom(reactFlowInstance.getZoom());
    },
    [setZoom],
  );

  const onNodesChange: OnNodesChange = useCallback(
    defaultChanges => {
      let changes = cloneDeep(defaultChanges);

      let updatedGroupHeight: number = null;

      const connectedVariationChanges: Array<{
        id: string;
        y: number;
      }> = [];

      for (const change of changes) {
        if (change?.type === "position" && change.position?.x) {
          const originalNode = state.nodes?.find(node => node?.id === change.id);

          if (
            originalNode?.type === DYNAMIC_PRICING_FACTOR_NODE &&
            originalNode.data?.groupId === DYNAMIC_PRICING_FACTOR_GROUP_VARIATION_ID
          ) {
            change.position.x = originalNode.position.x;
            change.position.y = originalNode.position.y;
          } else if (originalNode?.type === DYNAMIC_PRICING_FACTOR_NODE && originalNode.data?.groupElement) {
            const groupBoundingRectangle = originalNode.data.groupElement.getBoundingClientRect();

            const groupFlowStartingPosition = screenToFlowPosition({
              x: groupBoundingRectangle.x,
              y: groupBoundingRectangle.y,
            });

            const groupFlowEndingPosition = screenToFlowPosition({
              x: groupBoundingRectangle.x + groupBoundingRectangle.width,
              y: groupBoundingRectangle.y + groupBoundingRectangle.height,
            });

            const halfOriginalNodeHeight = DYNAMIC_PRICING_FACTOR_NODE_HEIGHT / 2;

            const topGroupClampPosition = groupFlowStartingPosition.y + halfOriginalNodeHeight;
            const bottomGroupClampPosition = groupFlowEndingPosition.y - halfOriginalNodeHeight;

            if (change.position.y < topGroupClampPosition) {
              change.position.y = topGroupClampPosition;
            } else if (change.position.y > bottomGroupClampPosition) {
              if (updatedGroupHeight == null) {
                updatedGroupHeight = state.groupHeight;
              }

              updatedGroupHeight += change.position.y - bottomGroupClampPosition;
            }

            change.position.x = originalNode.position.x;

            if (originalNode.data.connectedVariationId) {
              connectedVariationChanges.push({
                id: originalNode.data.connectedVariationId,
                y: change.position.y,
              });
            }
          }
        }
      }

      changes = changes.filter(change => change.type !== "remove");

      setState(prev => {
        const updatedNodes = (
          applyNodeChanges(changes, prev.nodes as IDynamicPricingFactorNode[]) as DynamicPricingNode[]
        ).map(node => {
          if (node.type === DYNAMIC_PRICING_ROOT_NODE && updatedGroupHeight != null) {
            return {
              ...node,
              position: {
                x: node.position.x,
                y: FACTOR_GROUP_NODE_Y_POSITION + updatedGroupHeight / 2,
              },
            };
          } else if (
            connectedVariationChanges.some(connectedVariationChange => connectedVariationChange.id === node.id)
          ) {
            const connectedVariationChange = connectedVariationChanges.find(
              connectedVariationChange => connectedVariationChange.id === node.id,
            );

            return {
              ...node,
              position: {
                x: node.position.x,
                y: connectedVariationChange.y,
              },
            };
          } else {
            return node;
          }
        });

        return {
          ...prev,
          nodes: updatedNodes,
          groupHeight: updatedGroupHeight ?? prev.groupHeight,
        };
      });
    },
    [state.nodes, state.groupHeight, setState, screenToFlowPosition],
  );

  const onEdgesChange: OnEdgesChange = useCallback(
    changes => setState(prev => ({ ...prev, edges: applyEdgeChanges(changes, prev.edges) })),
    [setState],
  );

  const onConnect: OnConnect = useCallback(connection => {
    connectingNode.current = null;
    setState(prev => ({ ...prev, edges: addEdge(connection, prev.edges) }));
  }, []);

  const onConnectStart: OnConnectStart = useCallback(
    (_, params) => {
      const sourceNode = state.nodes?.find(node => node?.id === params?.nodeId);

      if (sourceNode?.type === DYNAMIC_PRICING_ROOT_NODE) {
        connectingNode.current = {
          id: params.nodeId,
          targetGroupId: sourceNode.data.nextGroupId,
          path: DYNAMIC_PRICING_ROOT_NODE_ID,
        };
      } else if (sourceNode?.type === DYNAMIC_PRICING_FACTOR_NODE) {
        connectingNode.current = {
          id: params.nodeId,
          targetGroupId: sourceNode.data.nextGroupId,
          path: sourceNode.data.path,
        };
      } else {
        connectingNode.current = null;
      }
    },
    [state.nodes],
  );

  const onConnectEnd: OnConnectEnd = useCallback(
    event => {
      const targetGroup = event.target;

      if (
        connectingNode.current?.id &&
        connectingNode.current?.targetGroupId &&
        connectingNode.current?.path &&
        targetGroup instanceof HTMLDivElement &&
        targetGroup.classList.contains(DYNAMIC_PRICING_FACTOR_GROUP_NODE_CLASS_NAME) &&
        targetGroup.id !== DYNAMIC_PRICING_FACTOR_GROUP_VARIATION_ID &&
        targetGroup.id === connectingNode.current.targetGroupId
      ) {
        const newFactorId = getId();
        const targetGroupBoundingRectangle = targetGroup.getBoundingClientRect();
        let y = 0;

        if (event instanceof MouseEvent) {
          y = event.clientY;
        } else if (event instanceof TouchEvent) {
          y = event.touches[0].clientY;
        }

        const groupNode = getNode(targetGroup.id) as IDynamicPricingFactorGroupNode;

        if (!groupNode) {
          return;
        }

        const nextGroupId = groupNode?.data?.nextGroupId;

        let nextGroupNode: IDynamicPricingFactorGroupNode = null;

        if (nextGroupId) {
          nextGroupNode = getNode(nextGroupId) as IDynamicPricingFactorGroupNode;
        }

        const newVariationId = nextGroupId === DYNAMIC_PRICING_FACTOR_GROUP_VARIATION_ID ? getId() : null;

        const newFactorPath = connectingNode.current.path + `/${newFactorId}`;

        const minYPosition = FACTOR_GROUP_NODE_Y_POSITION + DYNAMIC_PRICING_FACTOR_NODE_HEIGHT / 2;
        const maxYPosition = FACTOR_GROUP_NODE_Y_POSITION + state.groupHeight - DYNAMIC_PRICING_FACTOR_NODE_HEIGHT / 2;

        const newFactorPosition = screenToFlowPosition({
          x: targetGroupBoundingRectangle.x + targetGroupBoundingRectangle.width / 2,
          y,
        });

        newFactorPosition.y = clamp(newFactorPosition.y, minYPosition, maxYPosition);

        const newFactor: IDynamicPricingFactorNode = {
          id: newFactorId,
          type: DYNAMIC_PRICING_FACTOR_NODE,
          position: newFactorPosition,
          data: {
            primaryKey: null,
            groupForeignKey: groupNode.data.primaryKey,
            parentId: connectingNode.current.id,
            groupElement: targetGroup,
            groupId: targetGroup.id,
            nextGroupId,
            connectedVariationId: newVariationId,
            path: newFactorPath,
            outgoingBranchingLimit: nextGroupNode?.data?.incomingBranchingLimit ?? 0,
            ...getDefaultFactorValue(groupNode.data.valueType),
          },
        };

        let newVariation: IDynamicPricingFactorNode = null;

        if (newVariationId != null) {
          const variationGroupNode = getNode(
            DYNAMIC_PRICING_FACTOR_GROUP_VARIATION_ID,
          ) as IDynamicPricingFactorGroupNode;

          if (!variationGroupNode?.data?.valueType) {
            return;
          }

          const newVariationPath = newFactorPath + `/${newVariationId}`;

          const variationGroupElement = document.getElementById(
            DYNAMIC_PRICING_FACTOR_GROUP_VARIATION_ID,
          ) as HTMLDivElement;

          const variationGroupBoundingRectangle = variationGroupElement?.getBoundingClientRect();

          const newVariationXPosition = screenToFlowPosition({
            x: variationGroupBoundingRectangle?.x + variationGroupBoundingRectangle?.width / 2,
            y: 0,
          }).x;

          newVariation = {
            id: newVariationId,
            type: DYNAMIC_PRICING_FACTOR_NODE,
            position: {
              x: newVariationXPosition,
              y: newFactorPosition.y,
            },
            data: {
              primaryKey: null,
              groupForeignKey: variationGroupNode.data.primaryKey,
              parentId: newFactorId,
              groupElement: variationGroupElement,
              groupId: DYNAMIC_PRICING_FACTOR_GROUP_VARIATION_ID,
              nextGroupId: null,
              connectedVariationId: null,
              path: newVariationPath,
              outgoingBranchingLimit: 0,
              ...getDefaultFactorValue(variationGroupNode.data.valueType),
            },
          };
        }

        setState(prev => {
          return {
            ...prev,
            nodes: prev.nodes.concat([newFactor, ...(newVariation ? [newVariation] : [])]),
            edges: prev.edges.concat([
              {
                id: `e${connectingNode.current.id}-${newFactorId}`,
                source: connectingNode.current.id,
                target: newFactorId,
                data: {
                  targetGroupId: connectingNode.current.targetGroupId,
                },
              },
              ...(newVariation
                ? [
                    {
                      id: `e${newFactorId}-${newVariationId}`,
                      source: newFactorId,
                      target: newVariationId,
                      data: {
                        targetGroupId: DYNAMIC_PRICING_FACTOR_GROUP_VARIATION_ID,
                      },
                    },
                  ]
                : []),
            ]),
            selectedNode: {
              id: newFactorId,
              isGroup: false,
              isVariation: false,
            },
          };
        });
      }
    },
    [state.groupHeight, setState, screenToFlowPosition],
  );

  function deleteFactor(id: string) {
    const sourceFactorsToDelete: string[] = [id];
    const edgesToDelete: string[] = [];

    for (const edge of state.edges) {
      if (edge.target === id) {
        edgesToDelete.push(edge.id);
      } else if (sourceFactorsToDelete.some(sourceFactorToDelete => sourceFactorToDelete === edge.source)) {
        sourceFactorsToDelete.push(edge.target);
        edgesToDelete.push(edge.id);
      }
    }

    setState(prev => {
      return {
        ...prev,
        nodes: prev.nodes.filter(
          node => !sourceFactorsToDelete.some(sourceFactorToDelete => sourceFactorToDelete === node.id),
        ),
        edges: prev.edges.filter(edge => !edgesToDelete.some(edgeToDelete => edgeToDelete === edge.id)),
        selectedNode: null,
      };
    });
  }

  function updateFactor(id: string, value: DynamicPricingFactorValue) {
    setState(prev => {
      const updatedNodes = prev.nodes.map(node => {
        if (node.id === id && node.type === DYNAMIC_PRICING_FACTOR_NODE) {
          const data: DynamicPricingFactorNodeData = {
            ...node.data,
            ...value,
          };

          return {
            ...node,
            data,
          };
        } else {
          return node;
        }
      });

      return {
        ...prev,
        nodes: updatedNodes,
        selectedNode: null,
      };
    });
  }

  function deleteGroupFactors(id: string) {
    const groupNode = getNode(id) as IDynamicPricingFactorGroupNode;

    if (!groupNode) {
      return;
    }

    const groupsToDelete = state.nodes.filter(
      node => node.type === DYNAMIC_PRICING_FACTOR_GROUP_NODE && node.data.order >= groupNode.data.order,
    ) as IDynamicPricingFactorGroupNode[];

    setState(prev => {
      return {
        ...prev,
        nodes: prev.nodes.filter(
          node =>
            node.type !== DYNAMIC_PRICING_FACTOR_NODE ||
            !groupsToDelete.some(group => group.id === node.data.groupId),
        ),
        edges: prev.edges.filter(edge => !groupsToDelete.some(group => group.id === edge.data.targetGroupId)),
        selectedNode: null,
      };
    });
  }

  function handleDeleteSelectionAction() {
    if (state.selectedNode.isGroup) {
      deleteGroupFactors(state.selectedNode.id);
    } else {
      deleteFactor(state.selectedNode.id);
    }
  }

  function validateTreeChildren(children: IDynamicPricingTreeNode[]): DynamicPricingTreeValidationCheck[] {
    if (children == null || children.length === 0) {
      return [{ outcome: "success" }];
    } else if (children.some(child => !isFactorValid(child.node.data))) {
      return [{ outcome: "failure", reason: "invalid_factor" }];
    } else if (children.some(child => child.node.type !== children[0].node.type)) {
      return [{ outcome: "failure", reason: "factor_type_mismatch" }];
    } else if (areFactorValuesOverlapping(children.map(child => child.node.data))) {
      return [{ outcome: "failure", reason: "overlapping_factor_ranges" }];
    } else {
      return [{ outcome: "success" }, ...flatten(children.map(child => validateTreeChildren(child.children)))];
    }
  }

  function validateTree(tree: IDynamicPricingTree): DynamicPricingTreeValidationCheck {
    const validationChecks = validateTreeChildren(tree.children);

    const validationFailure = validationChecks.find(validationCheck => validationCheck.outcome === "failure");

    if (validationFailure) {
      return validationFailure;
    } else {
      return {
        outcome: "success",
      };
    }
  }

  async function handleSaveChangesAction() {
    let incompletePathErrorOccured = false;

    function constructTreeChildren(
      parentId: string,
      parentConnections: DynamicPricingEdge[],
      nodes: Dictionary<IDynamicPricingFactorNode>,
      nodeConnections: Dictionary<DynamicPricingEdge[]>,
      variationIds: string[],
    ): IDynamicPricingTreeNode[] {
      if (incompletePathErrorOccured) {
        return null;
      } else if (parentConnections?.length > 0) {
        return parentConnections.map(parentConnection => {
          const node = nodes[parentConnection.target];

          return {
            node,
            children: constructTreeChildren(node.id, nodeConnections[node.id], nodes, nodeConnections, variationIds),
          };
        });
      } else {
        if (variationIds.some(variationId => variationId === parentId)) {
          return [];
        } else {
          incompletePathErrorOccured = true;
        }
      }
    }

    const factorNodes: IDynamicPricingFactorNode[] = state.nodes.filter(
      node => node.type === DYNAMIC_PRICING_FACTOR_NODE,
    ) as IDynamicPricingFactorNode[];

    const groupedFactorNodes: Dictionary<IDynamicPricingFactorNode> = {};

    factorNodes.forEach(factorNode => {
      groupedFactorNodes[factorNode.id] = cloneDeep(factorNode);
    });

    const groupedEdges: Dictionary<DynamicPricingEdge[]> = groupBy(state.edges, edge => edge.source);

    const variationIds = factorNodes
      .filter(node => node.data.groupId === DYNAMIC_PRICING_FACTOR_GROUP_VARIATION_ID)
      .map(variationNode => variationNode.id);

    const root = state.nodes.find(node => node.id === DYNAMIC_PRICING_ROOT_NODE_ID);

    if (!root || root.type !== DYNAMIC_PRICING_ROOT_NODE) {
      return;
    }

    const tree: IDynamicPricingTree = {
      root,
      children: constructTreeChildren(root.id, groupedEdges[root.id], groupedFactorNodes, groupedEdges, variationIds),
    };

    if (incompletePathErrorOccured) {
      dispatch(showError("All paths must result in a variation"));
      return;
    }

    const treeValidationCheck = validateTree(tree);

    if (treeValidationCheck.outcome === "failure") {
      switch (treeValidationCheck.reason) {
        case "invalid_factor":
          dispatch(showError("Factors must be valid"));
          return;
        case "factor_type_mismatch":
          dispatch(showError("Factors in a group must be the same type"));
          return;
        case "overlapping_factor_ranges":
          dispatch(showError("Factor ranges cannot overlap when they share the same parent factor"));
          return;
      }
    }

    function extractFactorValues(data: DynamicPricingFactorNodeData) {
      let range_start: string = null;
      let range_end: string = null;
      let value: string = null;

      switch (data.valueType) {
        case "number_range":
          range_start = data.startNumber == null ? "" : String(data.startNumber);
          range_end = data.endNumber == null ? "" : String(data.endNumber);
          break;
        case "time_range":
          range_start = convertFactorTimeTo24HourTimeString(data.startTime);
          range_end = convertFactorTimeTo24HourTimeString(data.endTime);
          break;
        case "percent_range":
          range_start = data.startPercent == null ? "" : String(data.startPercent);
          range_end = data.endPercent == null ? "" : String(data.endPercent);
          break;
        case "percent_variation":
          value = data.percentVariation == null ? "" : String(data.percentVariation);
          break;
      }

      return {
        range_start,
        range_end,
        value,
      };
    }

    dispatch(enqueue());

    try {
      const updatedFactors: IUpdatedDynamicPricingFactor[] = factorNodes
        .filter(factorNode => factorNode.data.primaryKey != null)
        .map(factorNode => {
          return {
            id: factorNode.data.primaryKey,
            dynamic_pricing_group_id: factorNode.data.groupForeignKey,
            dynamic_pricing_group_token: factorNode.data.groupId,
            token: factorNode.id,
            value_type: factorNode.data.valueType,
            ...extractFactorValues(factorNode.data),
            y_position: factorNode.position.y,
            path: factorNode.data.path,
          };
        });

      const putFactorsResponse = await PutDynamicPricingFactors(
        { template_id: Number(templateId), factors: updatedFactors },
        false,
      );

      if (putFactorsResponse.status !== StatusCode.OK) {
        dispatch(showError("Error updating factors"));
        return;
      }

      const newFactors: INewDynamicPricingFactor[] = factorNodes
        .filter(factorNode => factorNode.data.primaryKey == null)
        .map(factorNode => {
          return {
            dynamic_pricing_group_id: factorNode.data.groupForeignKey,
            dynamic_pricing_group_token: factorNode.data.groupId,
            token: factorNode.id,
            value_type: factorNode.data.valueType,
            ...extractFactorValues(factorNode.data),
            y_position: factorNode.position.y,
            path: factorNode.data.path,
          };
        });

      const postFactorsResponse = await PostDynamicPricingFactors(
        { template_id: Number(templateId), factors: newFactors },
        false,
      );

      if (postFactorsResponse.status !== StatusCode.OK) {
        dispatch(showError("Error creating factors"));
        return;
      }

      const nodesWithPrimaryKey: DynamicPricingNode[] = state.nodes.map(node => {
        if (node.type === DYNAMIC_PRICING_FACTOR_NODE && node.data.primaryKey == null) {
          const newFactorId = postFactorsResponse.data.find(factor => factor.token === node.id)?.id;

          return {
            ...node,
            data: {
              ...node.data,
              primaryKey: newFactorId,
            },
          };
        } else {
          return node;
        }
      });

      setState(prev => ({
        ...prev,
        nodes: nodesWithPrimaryKey,
      }));

      const existingFactorNodes = factorNodes.filter(node => node.data.primaryKey != null);

      const existingTrackedFactorNodes = trackedStateBeforeChanges.nodes.filter(
        node => node.type === DYNAMIC_PRICING_FACTOR_NODE && node.data.primaryKey != null,
      ) as IDynamicPricingFactorNode[];

      const factorNodesToRemove = differenceBy(
        existingTrackedFactorNodes,
        existingFactorNodes,
        node => node.data.primaryKey,
      );

      const deleteFactorsResponse = await DeleteDynamicPricingFactors(
        { template_id: Number(templateId), factors: factorNodesToRemove.map(node => ({ id: node.data.primaryKey })) },
        false,
      );

      if (deleteFactorsResponse.status !== StatusCode.OK) {
        dispatch(showError("Error deleting factors"));
        return;
      }

      dispatch(showSuccess("Successfully saved dynamic pricing"));

      setTrackedStateBeforeChanges({
        nodes: cloneDeep(nodesWithPrimaryKey),
        edges: cloneDeep(state.edges),
        groupHeight: state.groupHeight,
      });
    } finally {
      dispatch(dequeue());
    }
  }

  function handleUndoChangesAction() {
    setState(prev => ({
      ...prev,
      nodes: cloneDeep(trackedStateBeforeChanges.nodes),
      edges: cloneDeep(trackedStateBeforeChanges.edges),
      groupHeight: trackedStateBeforeChanges.groupHeight,
      selectedNode: null,
    }));

    zoomToFit(trackedStateBeforeChanges.nodes, trackedStateBeforeChanges.groupHeight);
  }

  function getFactorSiblings(parentId: string, id: string): IDynamicPricingFactorNode[] {
    const siblings: IDynamicPricingFactorNode[] = state.nodes.filter(node => {
      return node.type === DYNAMIC_PRICING_FACTOR_NODE && node.data.parentId === parentId && node.id !== id;
    }) as IDynamicPricingFactorNode[];

    return siblings;
  }

  function handleOpenEditTemplate() {
    setEditTemplateState(prev => ({
      ...prev,
      open: true,
      title: state.template.title ?? "",
      active: state.template.active ?? false,
    }));
  }

  function handleCloseEditTemplate() {
    setEditTemplateState(prev => ({
      ...prev,
      open: false,
      title: "",
      active: false,
    }));
  }

  async function handleSaveEditTemplate() {
    const putTemplateResponse = await PutDynamicPricingTemplate(
      {
        id: state.template.id,
        title: editTemplateState.title,
        active: editTemplateState.active,
      },
      true,
    );

    if (putTemplateResponse.status !== StatusCode.OK) {
      dispatch(showError("Error editing template"));
      return;
    }

    setState(prev => ({ ...prev, template: putTemplateResponse.data }));

    handleCloseEditTemplate();
  }

  async function handleDeleteTemplate() {
    const deleteTemplateResponse = await DeleteDynamicPricingTemplate({ template_id: state.template.id }, true);

    if (deleteTemplateResponse.status !== StatusCode.OK) {
      dispatch(showError("Error deleting template"));
      return;
    }

    setState(prev => ({ ...prev, deletePopupOpen: false }));

    history.push("/admin/settings/tee-sheet/dynamic-pricing");
  }

  return (
    <div className="dynamic-pricing-edit-react-flow-container">
      <DynamicPricingContext.Provider
        value={{
          deleteFactor,
          updateFactor,
          deleteGroupFactors,
          groupHeight: state.groupHeight,
          selectedNode: state.selectedNode,
          setSelectedNode: (updatedSelectedNode: IDynamicPricingSelectedNode) =>
            setState(prev => ({ ...prev, selectedNode: updatedSelectedNode })),
          flowActionPanelRef,
          getFactorSiblings,
        }}
      >
        <ReactFlow
          nodes={state.nodes}
          edges={state.edges}
          nodeTypes={nodeTypes}
          onInit={onInit}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onConnectStart={onConnectStart}
          onConnectEnd={onConnectEnd}
          nodesDraggable={interactivityEnabled}
          nodesConnectable={interactivityEnabled}
          elementsSelectable={interactivityEnabled}
          panOnDrag={!state.isIncompatibleDevice}
          panOnScroll={false}
          zoomOnScroll={!state.isIncompatibleDevice}
          zoomOnPinch={false}
          multiSelectionKeyCode={null}
          zoomOnDoubleClick={false}
          disableKeyboardA11y={true}
          defaultEdgeOptions={{
            deletable: false,
          }}
          nodeOrigin={[0, 0]}
          proOptions={{
            hideAttribution: true,
          }}
        >
          <div
            className="dynamic-pricing-edit-incompatible-device-warning"
            style={{
              display: state.isIncompatibleDevice ? "block" : "none",
            }}
          >
            <Callout
              type="warning"
              title="Incompatible device"
              content="Please resume editing factors on a standard desktop device"
            />
          </div>
          <DynamicPricingActionPanel
            panelPosition="top-left"
            direction="row"
            items={[
              {
                type: "action",
                tooltipId: "dynamic-pricing-template-go-back-tooltip",
                tooltipLabel: "Go Back To Templates",
                tooltipPlacement: "bottom-start",
                icon: "arrow-left",
                disabled: false,
                onClick: () => history.push("/admin/settings/tee-sheet/dynamic-pricing"),
              },
              {
                type: "label",
                label: state.template?.title,
                hidden: !state.templateLoaded,
              },
              {
                type: "action",
                tooltipId: "dynamic-pricing-template-edit-tooltip",
                tooltipLabel: "Edit Template",
                tooltipPlacement: "bottom",
                icon: "pen",
                disabled: !state.templateLoaded,
                onClick: handleOpenEditTemplate,
              },
              {
                type: "action",
                tooltipId: "dynamic-pricing-template-delete-tooltip",
                tooltipLabel: "Delete Template",
                tooltipPlacement: "bottom",
                icon: "trash",
                disabled: !state.templateLoaded,
                onClick: () => setState(prev => ({ ...prev, deletePopupOpen: true })),
              },
            ]}
          />
          <DynamicPricingActionPanel
            actionPanelRef={flowActionPanelRef}
            panelPosition="bottom-left"
            direction="column"
            items={[
              {
                type: "action",
                tooltipId: "dynamic-pricing-flow-zoom-to-fit-tooltip",
                tooltipLabel: "Zoom To Fit",
                tooltipPlacement: "right",
                icon: "expand",
                disabled: state.isIncompatibleDevice,
                onClick: () => zoomToFit(state.nodes, state.groupHeight),
              },
              {
                type: "action",
                tooltipId: "dynamic-pricing-flow-zoom-in-tooltip",
                tooltipLabel: "Zoom In",
                tooltipPlacement: "right",
                icon: "plus",
                disabled: zoom >= MAXIMUM_ZOOM || state.isIncompatibleDevice,
                onClick: zoomIn,
              },
              {
                type: "action",
                tooltipId: "dynamic-pricing-flow-zoom-out-tooltip",
                tooltipLabel: "Zoom Out",
                tooltipPlacement: "right",
                icon: "minus",
                disabled: zoom <= MINIMUM_ZOOM || state.isIncompatibleDevice,
                onClick: zoomOut,
              },
              {
                type: "action",
                tooltipId: "dynamic-pricing-flow-delete-selection-tooltip",
                tooltipLabel: "Delete Selection",
                tooltipPlacement: "right",
                icon: "trash",
                disabled:
                  !state.selectedNode ||
                  state.selectedNode.isVariation ||
                  !interactivityEnabled ||
                  state.isIncompatibleDevice,
                onClick: handleDeleteSelectionAction,
              },
              {
                type: "action",
                tooltipId: "dynamic-pricing-flow-save-changes-tooltip",
                tooltipLabel: "Save Changes",
                tooltipPlacement: "right",
                icon: "save",
                disabled: !unsavedChangesExist || !interactivityEnabled || state.isIncompatibleDevice,
                onClick: handleSaveChangesAction,
              },
              {
                type: "action",
                tooltipId: "dynamic-pricing-flow-undo-changes-tooltip",
                tooltipLabel: "Undo Changes",
                tooltipPlacement: "right",
                icon: "undo",
                disabled: !unsavedChangesExist || !interactivityEnabled || state.isIncompatibleDevice,
                onClick: handleUndoChangesAction,
              },
            ]}
          />
          <Background variant={BackgroundVariant.Dots} gap={24} size={1} />
        </ReactFlow>
      </DynamicPricingContext.Provider>

      <Popup
        open={state.deletePopupOpen}
        type="warning"
        title="Delete Confirmation"
        description="Are you sure you want to delete this template?"
        backDropCancel
        onCancel={() => setState(prev => ({ ...prev, deletePopupOpen: false }))}
        onOk={handleDeleteTemplate}
        okText="Ok"
      />

      <Sheet
        open={editTemplateState.open}
        title="Edit Template"
        size="small"
        closable
        onCancel={handleCloseEditTemplate}
        onOk={handleSaveEditTemplate}
        okDisabled={isEmpty(editTemplateState.title)}
      >
        <FormLayout>
          <FormLayout.Group>
            <Input
              label="Title"
              value={editTemplateState.title}
              onChange={e => setEditTemplateState(prev => ({ ...prev, title: e.target.value }))}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <Checkbox
              label="Active"
              size="medium"
              checked={editTemplateState.active}
              onChange={e => setEditTemplateState(prev => ({ ...prev, active: e.target.checked }))}
            />
          </FormLayout.Group>
        </FormLayout>
      </Sheet>
    </div>
  );
}
