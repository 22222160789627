import React, { ChangeEvent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { GetDivision, PutDivision } from "api/rpc/2022-09/facilityAdmin/facility/division";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Input from "components/form/input/Input";
import FormLayout from "components/form/FormLayout";
import Card from "components/card/Card";
import Form from "components/form/Form";
import Toggle from "components/form/toggle/Toggle";
import { isEqualWith, isNull } from "lodash";
import { NotificationType } from "components/notificationBar/NotificationBar";
import { IAuthState } from "redux/reducers/auth";
import { IAuthActions } from "redux/actions/auth";
import { IUIActions } from "redux/actions/ui";
import { isPositiveInteger } from "helpers/Helpers";
import Popup from "components/popup/Popup";
import { PostPasswordResetCode } from "api/rpc/2022-09/passwordReset";

import { useTranslation } from "react-i18next";
import ColorPicker from "components/colorpicker/color-picker";

interface IDivisionProps {
  authStore: IAuthState;
  authActions: IAuthActions;
  uiActions: IUIActions;
}

interface IDivision {
  id: number;
  title: string;
  color: string;
  code: string;
  pars: number[];
  handicaps: number[];
}

export default function Division(props: IDivisionProps) {
  const { divisionId } = useParams<{ divisionId: string }>();
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [divisionState, setDivisionState] = useState<IDivision>(undefined);

  const [divisionStateBeforeChanges, setDivisionStateBeforeChanges] = useState<IDivision>(undefined);

  useEffect(() => {
    void loadDivision();
  }, []);

  async function loadDivision() {
    const divisionRes = await GetDivision({ id: divisionId }, true);

    if (divisionRes.status !== StatusCode.OK) {
      props.uiActions.showError("Division does not exist");
      return;
    }

    setDivisionState(prev => ({
      ...prev,
      id: divisionRes.data[0].id,
      title: divisionRes.data[0].title ?? "",
      color: divisionRes.data[0].color,
      code: divisionRes.data[0].code,
      pars: divisionRes.data[0].par,
      handicaps: divisionRes.data[0].handicap,
    }));
  }

  async function saveDivisionState() {
    const putDivisionRes = await PutDivision(
      {
        id: divisionState.id,
        title: divisionState.title,
        color: divisionState.color,
        code: divisionState.code,
        par: divisionState.pars,
        handicap: divisionState.handicaps,
      },
      true,
    );

    if (putDivisionRes.status !== StatusCode.OK) {
      props.uiActions.showError(t("secure.facility.settings.facility.facility_staff_edit.003"));
      return;
    }

    setDivisionStateBeforeChanges(divisionState);

    history.push("/admin/settings/division");
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setDivisionState(prevState => ({ ...prevState, [id]: value }));
  }

  function unsavedChangesExist() {
    if (divisionStateBeforeChanges === undefined) {
      if (divisionState) {
        const parsCopy = Object.assign([], divisionState.pars);
        const handicapsCopy = Object.assign([], divisionState.handicaps);
        setDivisionStateBeforeChanges({ ...divisionState, pars: parsCopy, handicaps: handicapsCopy });
      }
      return false;
    }

    return !isEqualWith(divisionStateBeforeChanges, divisionState, (originalValue, newValue) => {
      if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
        return true;
      }
    });
  }

  function cancelUnsavedChanges() {
    setDivisionState(divisionStateBeforeChanges);
  }

  function handleColorPick(value: string) {
    setDivisionState(prevState => ({
      ...prevState,
      color: value,
    }));
  }

  function handleParInputChange(event: any, index: number) {
    const { value } = event.target;

    const newPars = divisionState.pars;

    newPars[index] = Number(value);

    setDivisionState(prevState => ({ ...prevState, pars: newPars }));
  }

  function handleHandicapInputChange(event: any, index: number) {
    const { value } = event.target;

    const newHandicaps = divisionState.handicaps;

    newHandicaps[index] = Number(value);

    setDivisionState(prevState => ({ ...prevState, handicaps: newHandicaps }));
  }

  return (
    <Page
      title="Division"
      narrow
      breadcrumbs={[
        {
          prefix: true,
          label: "Divisions",
          url: "/admin/settings/division",
        },
      ]}
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: saveDivisionState,
        onCancel: cancelUnsavedChanges,
      }}
    >
      {divisionState && divisionStateBeforeChanges && (
        <Form>
          <Card>
            <Card.Section>
              <FormLayout>
                <FormLayout.Group>
                  <Input
                    value={divisionState.title}
                    label="Title"
                    id="title"
                    onChange={handleInputChange}
                    placeholder="Title"
                  />
                  <Input
                    value={divisionState.code}
                    label="Code"
                    id="code"
                    onChange={handleInputChange}
                    placeholder="Code"
                  />
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>
          </Card>
          <Card title="Tee Sheet Color" subtitle="Select a color for this division to use on the tee sheet">
            <Card.Section>
              <ColorPicker colorValue={divisionState.color} onClick={handleColorPick} />
            </Card.Section>
          </Card>
          <Card>
            <Card.Section>
              <div className="text-left" style={{ marginBottom: "20px" }}>
                {"Pars"}
              </div>
              <div>
                <table className="scoresheet-scorecard cell-spacing w-full">
                  <tbody>
                    <tr>
                      {divisionState.pars.map((par: number, index: number) => {
                        return (
                          <th key={index} className="text-center">
                            {index + 1}
                          </th>
                        );
                      })}
                    </tr>
                    <tr>
                      {divisionState.pars.map((par: any, index: number) => {
                        return (
                          <td key={index} className="text-center">
                            <input
                              className="cell-input text-center"
                              type="number"
                              onChange={e => handleParInputChange(e, index)}
                              value={par ?? ""}
                            />
                          </td>
                        );
                      })}
                    </tr>
                  </tbody>
                </table>
              </div>
            </Card.Section>
            <Card.Section>
              <div className="text-left" style={{ marginBottom: "20px" }}>
                {"Handicaps"}
              </div>
              <div>
                <table className="scoresheet-scorecard cell-spacing w-full">
                  <tbody>
                    <tr>
                      {divisionState.handicaps.map((handicap: number, index: number) => {
                        return (
                          <th key={index} className="text-center">
                            {index + 1}
                          </th>
                        );
                      })}
                    </tr>
                    <tr>
                      {divisionState.handicaps.map((handicap: any, index: number) => {
                        return (
                          <td key={index} className="text-center">
                            <input
                              className="cell-input text-center"
                              type="number"
                              onChange={e => handleHandicapInputChange(e, index)}
                              value={handicap ?? ""}
                            />
                          </td>
                        );
                      })}
                    </tr>
                  </tbody>
                </table>
              </div>
            </Card.Section>
          </Card>
        </Form>
      )}

      <div style={{ height: "40px" }}></div>
    </Page>
  );
}
