import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { isEmpty } from "helpers/Helpers";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import TextField from "components/form/textField/TextField";
import Checkbox from "components/form/checkbox/Checkbox";

import "pages/secure/facility/product/Ticket.scss";
import { ITeeSheetState } from "redux/reducers/teesheet";
import { ITeeSheetActions } from "redux/actions/teesheet";
import { GetFacility } from "api/rpc/clientAdmin/facility/facility";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import moment from "moment";
import { Select } from "components/select/";
import TextEditor from "components/textEditor/textEditor";
import { GetTickets, PutTicket } from "api/rpc/2024-04/clientAdmin/ticket/ticket";
import { isEqualWith, isNull } from "lodash";
import { useAppDispatch } from "hooks/redux";
import { showError, showSuccess } from "redux/actions/ui";

interface ITicketState {
  id: number;
  title: string;
  included: string;
  description: string;
  value: string;
  expiry_type: string;
  expiry_date: string;
  expiry_days: string;
  valid_facilities: number[];
  facility_ids_list: IFacilityInformation[];
  selectedDate: Date;
}

interface IFacilityInformation {
  id: any;
  value: any;
  label: any;
  isChecked: boolean;
}

interface ISingleProps {
  teeSheetStore: ITeeSheetState;
  teeSheetActions: ITeeSheetActions;
}

const Single: React.FC<ISingleProps> = props => {
  const { teeSheetStore, teeSheetActions } = props;

  const history = useHistory();
  const { ticketId }: any = useParams();
  const dispatch = useAppDispatch();

  const [ticketStateBeforeChanges, setTicketStateBeforeChanges] = useState<ITicketState>(undefined);
  const [ticketLoaded, setTicketLoaded] = useState<boolean>(false);

  const [ticketState, setTicketState] = useState<ITicketState>({
    id: null,
    title: "",
    included: "",
    description: "",
    value: "",
    expiry_type: "",
    expiry_date: "",
    expiry_days: null,
    valid_facilities: [],
    facility_ids_list: [],
    selectedDate: new Date(),
  });

  const { Option } = Select;

  const [optionState, setOptionState] = useState({
    expiryTypes: [
      { label: "Date", value: "date" },
      { label: "Days", value: "days" },
      { label: "None", value: "none" },
    ],
  });

  useEffect(() => {
    void loadTicketInformation();
  }, []);

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setTicketState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleDescriptionChange(value: string) {
    setTicketState(prevState => ({ ...prevState, description: value }));
  }

  function changeFacilityList(facility: any) {
    const updatedFacilities = [...ticketState.valid_facilities];
    const indexFound = ticketState?.valid_facilities?.findIndex(facilityId => facilityId === facility?.id);

    if (indexFound !== -1) {
      updatedFacilities.splice(indexFound, 1);
    } else {
      updatedFacilities.push(facility?.id);
    }

    updatedFacilities.sort();

    setTicketState(prevState => ({
      ...prevState,
      valid_facilities: updatedFacilities,
    }));
  }

  async function loadTicketInformation() {
    const facilityParams = "client-facilities=true";

    const facilityRes = await GetFacility(null, true);

    const tempFacilityIdList: IFacilityInformation[] = [];

    if (facilityRes.status !== StatusCode.OK) {
      dispatch(showError("Error getting facilities"));
      return;
    }

    const params = {
      id: ticketId,
      extended: true,
    };

    const loadTicketRes = await GetTickets(params, true);
    const ticket = loadTicketRes.data[0];

    if (loadTicketRes.status !== StatusCode.OK) {
      dispatch(showError("Error getting ticket"));
      return;
    }

    const tempValidFacilityIds: number[] = [];

    //Get valid facility id's
    for (let i = 0; i < ticket.valid_facilities.length; i++) {
      tempValidFacilityIds.push(ticket.valid_facilities[i].id);
    }

    for (let i = 0; i < facilityRes.data.length; i++) {
      const tempFacilityId = {
        id: facilityRes.data[i].id,
        value: facilityRes.data[i].id,
        label: facilityRes.data[i].long_name,
        isChecked: null as boolean,
      };

      tempFacilityIdList.push(tempFacilityId);
    }

    const currentExpiryDate = new Date(moment(ticket.expiry_date).format("LL"));

    setTicketState(prevState => ({
      ...prevState,
      id: ticket.id,
      title: ticket.title,
      included: ticket.included,
      description: ticket.description,
      value: ticket.value ? String(ticket.value) : "",
      expiry_date: ticket.expiry_date,
      expiry_days: isEmpty(ticket.expiry_days) ? null : String(ticket.expiry_days),
      expiry_type: ticket.expiry_type,
      valid_facilities: tempValidFacilityIds,
      selectedDate: currentExpiryDate,
      facility_ids_list: tempFacilityIdList,
    }));

    setTicketLoaded(true);
  }

  async function saveTicket() {
    console.log(ticketState);

    const ticketRes = await PutTicket(ticketState, true);

    console.log(ticketRes);
    if (ticketRes.status !== StatusCode.OK) {
      return;
    }
    dispatch(showSuccess("Ticket saved successfully"));
    history.push("/admin/tickets");
  }

  function handleSelectChange(event: any) {
    const { id, value, text } = event.target;
    console.log(id, value, text);

    const datePicker = document.getElementById("ticket-date-selector");
    const dayInput = document.getElementById("ticket-day-input");

    switch (Number(value)) {
      case 0:
        datePicker.style.display = "none";
        dayInput.style.display = "none";
        setTicketState(prevState => ({ ...prevState, expiry_type: "none" }));
        break;
      case 1:
        //Date Picker Selected
        datePicker.style.display = "inline-block";
        dayInput.style.display = "none";
        setTicketState(prevState => ({ ...prevState, expiry_type: "date" }));
        break;

      case 2:
        //Day Input Selected
        datePicker.style.display = "none";
        dayInput.style.display = "inline-block";
        setTicketState(prevState => ({ ...prevState, expiry_type: "days" }));

        break;

      case 3:
        datePicker.style.display = "none";
        dayInput.style.display = "none";
        setTicketState(prevState => ({ ...prevState, expiry_type: "none" }));
        break;

      default:
        break;
    }
  }

  const handleDateSelectorChange = (selectedDate: Date) => {
    console.log("DATE", selectedDate);

    const tempDate = new Date(selectedDate).toISOString().slice(0, 10);

    console.log(tempDate);

    setTicketState(prevState => ({ ...prevState, expiry_date: tempDate, selectedDate: selectedDate }));
  };

  function handleDropDownChange(value: any, property: string) {
    setTicketState(prev => ({ ...prev, [property]: value }));
  }

  function unsavedChangesExist() {
    if (ticketStateBeforeChanges === undefined) {
      if (ticketLoaded) {
        setTicketStateBeforeChanges(ticketState);
      }
      return false;
    }

    return !isEqualWith(ticketStateBeforeChanges, ticketState, (originalValue, newValue) => {
      if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
        return true;
      }
    });
  }

  function cancelUnsavedChanges() {
    setTicketState(ticketStateBeforeChanges);
  }

  return (
    <Page
      title={ticketState.title}
      narrow
      breadcrumbs={[{ label: "Back to Tickets", url: "/admin/tickets" }]}
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: saveTicket,
        onCancel: cancelUnsavedChanges,
      }}
    >
      <Card>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Input
                value={ticketState.title}
                label="Title"
                id="title"
                onChange={handleInputChange}
                placeholder="Title"
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField
                value={ticketState.included || ""}
                label="Included"
                id="included"
                onChange={handleInputChange}
                placeholder="Description"
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
        <Card.Section>
          <TextEditor
            label="Description"
            markdownText={ticketState.description ?? ""}
            markdownTextOnChange={handleDescriptionChange}
          />
        </Card.Section>
        <Card.Section title="Expiry">
          <FormLayout>
            <FormLayout.Group>
              <div>
                <Select
                  label={"Expiry Type"}
                  onChange={(value: any) => handleDropDownChange(value, "expiry_type")}
                  defaultValue={ticketState.expiry_type}
                >
                  {optionState.expiryTypes.map((option: any, index: number) => {
                    return (
                      <Option key={index} value={option.value} name={option.label}>
                        {option.label}
                      </Option>
                    );
                  })}
                </Select>

                {/* 
                <p className="expiry-headings">Expiry Type</p>
                <select
                  className="expiry-select-input"
                  name="expiry_type"
                  id="expiry_type"
                  onChange={handleSelectChange}
                ></select> */}
              </div>

              <div>
                {/* <div id="ticket-day-input">
                  <Input
                    label="Days"
                    id="expiry_days"
                    value={ticketState.expiry_days}
                    onChange={handleInputChange}
                    placeholder="Days.."
                  />
                </div> */}

                {ticketState.expiry_type === "date" ? (
                  <div style={{ height: "44px" }}>
                    <DatePickerInput
                      label={"Date"}
                      months={1}
                      position="left"
                      startingDate={ticketState.selectedDate}
                      setStartingDate={handleDateSelectorChange}
                    />
                  </div>
                ) : null}

                {ticketState.expiry_type === "days" ? (
                  <Input
                    label={"Days"}
                    id="expiry_days"
                    value={ticketState.expiry_days ?? ""}
                    onChange={handleInputChange}
                    placeholder={"Days..."}
                  />
                ) : null}
              </div>
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>

        <Card.Section title="Value">
          <FormLayout>
            <FormLayout.Group>
              <Input
                value={ticketState.value}
                label="Value"
                id="value"
                onChange={handleInputChange}
                placeholder="Value"
                type="number"
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>

        <Card.Section title="Facilities">
          <FormLayout>
            {ticketState.facility_ids_list.map((facility: any, index: number) => {
              return (
                <FormLayout.Group key={index}>
                  <Checkbox
                    id={facility.id}
                    size="small"
                    checked={ticketState?.valid_facilities?.some(facilityId => facilityId === facility?.id)}
                    value={facility.value}
                    onChange={() => changeFacilityList(facility)}
                    label={facility.label}
                  />
                </FormLayout.Group>
              );
            })}
          </FormLayout>
        </Card.Section>
      </Card>
    </Page>
  );
};

export default Single;
