import Card from "components/card/Card";
import Page from "components/page/Page";
import React, { useEffect, useState } from "react";

import { StatusCode } from "api/protocols";
import { NotificationType } from "components/notificationBar/NotificationBar";
import TextEditor from "components/textEditor/textEditor";
import { isEqualWith } from "lodash";
import { useTranslation } from "react-i18next";
import { GetCartWaiver as PrintCartWaiver } from "api/rpc/2024-04/facilityAdmin/teesheet/booking";
import { showError } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";
import { GetCartWaiver, PutCartWaiver } from "api/rpc/2024-04/facilityAdmin/facility/teesheet";
interface ICartWaiver {
  content: string;
  loaded: boolean;
}

export default function CartWaiver() {
  const dispatch = useAppDispatch();
  const [cartWaiver, setCartWaiver] = useState<ICartWaiver>({
    content: "",
    loaded: false,
  });

  const { t, i18n } = useTranslation();
  const [cartWaiverBeforeChanges, setCartWaiverBeforeChanges] = useState<ICartWaiver>(undefined);

  useEffect(() => {
    void loadCartWaiver();
  }, []);

  async function loadCartWaiver() {
    const getCartWaiverResponse = await GetCartWaiver(true);

    console.log(getCartWaiverResponse);
    if (getCartWaiverResponse.status !== StatusCode.OK) {
      return;
    }

    setCartWaiver(prevState => {
      return {
        ...prevState,
        content: getCartWaiverResponse.data?.cart_waiver_content ?? "",
        loaded: true,
      };
    });
  }

  async function saveCartWaiver() {
    const putCartWaiverResponse = await PutCartWaiver({ cart_waiver_content: cartWaiver.content }, true);

    if (putCartWaiverResponse.status !== StatusCode.OK) {
      return;
    }

    setCartWaiverBeforeChanges(cartWaiver);
  }

  function unsavedChangesExist() {
    if (cartWaiverBeforeChanges === undefined) {
      if (cartWaiver.loaded) {
        setCartWaiverBeforeChanges(cartWaiver);
      }
      return false;
    }

    return !isEqualWith(cartWaiverBeforeChanges, cartWaiver);
  }

  function cancelUnsavedChanges() {
    setCartWaiver(cartWaiverBeforeChanges);
  }

  function setCartWaiverContent(content: string) {
    setCartWaiver(prevState => {
      return { ...prevState, content: content };
    });
  }

  async function handlePrintBlankWaiver() {
    const printRes = await PrintCartWaiver({ test: true }, true);

    console.log(printRes);

    if (printRes.status === StatusCode.OK) {
      window.open().document.write(printRes.data);
    } else {
      dispatch(showError("Cart waiver has not been created"));
    }
  }

  return (
    <Page
      title={t("secure.facility.settings.cart_waiver.cart_waiver.001")}
      subtitle={t("secure.facility.settings.cart_waiver.cart_waiver.002")}
      narrow
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: saveCartWaiver,
        onCancel: cancelUnsavedChanges,
      }}
      multipleActionDropdownAction={{
        label: "Options",
        dropdownProps: {
          alignment: "right",
          options: [
            {
              type: "handler",
              label: "Print Blank Waiver", // TODO: Translation
              icon: "print",
              handler: handlePrintBlankWaiver,
            },
          ],
        },
      }}
    >
      <hr className="my-8" />
      {cartWaiver.loaded ? (
        <Card>
          <TextEditor markdownText={cartWaiver.content} markdownTextOnChange={setCartWaiverContent} />
        </Card>
      ) : null}
    </Page>
  );
}
