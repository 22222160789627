import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { StatusCode } from "api/protocols";
import { GetCustomer, PostCustomer } from "api/rpc/2024-04/facilityAdmin/customer/customer";
import { GetCustomerType } from "api/rpc/2022-09/facilityAdmin/customer/type";

import { showError, showSuccess } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { MOBILE_WIDTH } from "helpers/ScreenSizes";
import { customerErrorMessage } from "helpers/Helpers";
import useModal from "hooks/modals/useModal";

import Page from "components/page/Page";
import NewCustomer, { ICustomerInfoState } from "components/newCustomer/NewCustomer";
import Search from "components/search/Search";
import CustomerTable from "elements/customer/CustomerTable";
import DropFilter from "components/dropdown/DropFilter";
import { ButtonNew as Button } from "components/buttonNew";

import "elements/customer/customer.scss";

interface IFilterState {
  search: string;
  limit: number;
  offset: number;
  customer_type: number[];
  cart_customer_type: number[];
}

const TABLE_LIMIT = 50;
export default function Customers() {
  const { t } = useTranslation();
  const history = useHistory();

  const dispatch = useAppDispatch();
  const windowSize = useWindowSize();

  const [customerTypes, setCustomerTypes] = useState(null);
  const [customers, setCustomers] = useState(undefined);
  const [filter, setFilter] = useState<IFilterState>({
    search: "",
    offset: 0,
    limit: TABLE_LIMIT,
    customer_type: null,
    cart_customer_type: null,
  });

  const { state, updateModal, closeModal } = useModal();

  useEffect(() => {
    void loadCustomerTypes();
  }, []);

  // Search Products
  useEffect(() => {
    const source = axios.CancelToken.source();

    void loadCustomers(source.token);

    return () => {
      source.cancel();
    };
  }, [filter.search, filter.offset, filter.customer_type, filter.cart_customer_type]);

  async function loadCustomers(token?: CancelToken) {
    if (customers !== undefined) {
      setCustomers(undefined);
    }

    const customerRes = await GetCustomer(
      {
        "default-address-line": true,
        limit: TABLE_LIMIT,
        offset: filter.offset,
        search: filter.search,
        customer_type: filter.customer_type,
        cart_customer_type: filter.cart_customer_type,
      },
      token ? false : true,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (customerRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading customers")); // TODO: Translation
    }

    setCustomers(customerRes.status !== StatusCode.OK ? [] : customerRes.data);
  }

  async function loadCustomerTypes() {
    const res = await GetCustomerType(null, false);

    if (res.status !== StatusCode.OK) {
      return;
    }

    setCustomerTypes(res.data);
  }

  async function createCustomer(customerInfo: ICustomerInfoState) {
    const error =
      customerInfo.firstName === "" ||
      customerInfo.lastName === "" ||
      (customerInfo.emailAddress === "" && customerInfo.phoneNumber === "");

    if (error) {
      return;
    }

    const customerRes = await PostCustomer(
      {
        first_name: customerInfo.firstName,
        last_name: customerInfo.lastName,
        phone: customerInfo.phoneNumber || null,
        email: customerInfo.emailAddress || null,
      },
      true,
    );

    if (customerRes.status !== StatusCode.OK) {
      dispatch(showError(customerErrorMessage(t, customerRes?.data?.message)));
      setCustomers([]);
      return;
    }

    dispatch(showSuccess(t("secure.facility.customer.main.customers.011")));
    void loadCustomers();
    closeModal();
  }

  function handleTableNavigation(direction: "prev" | "next") {
    switch (direction) {
      case "prev": {
        setFilter(prev => ({ ...prev, offset: filter.offset - TABLE_LIMIT }));
        break;
      }
      case "next": {
        setFilter(prev => ({ ...prev, offset: filter.offset + TABLE_LIMIT }));
        break;
      }
      default:
        return;
    }
  }

  const addCustomerAction = {
    content: (
      <div>
        <FontAwesomeIcon className="mr-2" icon={["far", "plus"]} />
        {t("secure.facility.customer.main.customers.002")}
      </div>
    ),
    action: () => updateModal({ isOpen: true }),
  };

  return (
    <Page
      title={t("secure.facility.customer.main.customers.001")}
      primaryAction={windowSize.width > MOBILE_WIDTH ? addCustomerAction : undefined}
    >
      {customerTypes && (
        <div className="flex flex-row flex-wrap gap-4 mb-2">
          <DropFilter
            title={"Customer Type"} // TODO: Translation
            filterData={customerTypes.filter((type: any) => type.application === "green_fee")}
            filterLabelPropFromData="title"
            filterIdPropFromData="id"
            filterType="Checkbox"
            applyFilters={filterReturn =>
              setFilter({
                ...filter,
                customer_type: filterReturn.map(filter => filter.id as number),
              })
            }
            save
            historyKey="customer_type"
            leftAligned
            subtitle
          />

          <DropFilter
            title={"Cart Type"} // TODO: Translation
            filterData={customerTypes.filter((type: any) => type.application === "power_cart")}
            filterLabelPropFromData="title"
            filterIdPropFromData="id"
            filterType="Checkbox"
            applyFilters={filterReturn =>
              setFilter({
                ...filter,
                cart_customer_type: filterReturn.map(filter => filter.id as number),
              })
            }
            save
            historyKey="cart_customer_type"
            leftAligned
          />
        </div>
      )}
      <div className="flex-grow mb-3.5">
        <Search
          placeholder={t("secure.facility.customer.main.customers.003")}
          historyKey={"search_customers_table"}
          searchCallback={searchValue => setFilter(prev => ({ ...prev, search: searchValue, offset: 0 }))}
        />
      </div>
      <div className="customers-add-customer-actions-mobile">
        <Button type="primary" size="medium" onClick={addCustomerAction.action}>
          {addCustomerAction.content}
        </Button>
      </div>

      <CustomerTable
        customers={customers}
        onClick={customer => history.push("/admin/customers/" + String(customer.id) + "/profile")}
        tableLimit={TABLE_LIMIT}
        tableOffset={filter.offset}
        handlePagination={direction => handleTableNavigation(direction)}
      />

      <NewCustomer
        newCustomerSheetActive={state.isOpen}
        onCancel={closeModal}
        onOk={createCustomer}
        searchValue={filter.search}
      />
    </Page>
  );
}
