import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetCourse } from "api/rpc/2024-04/facilityAdmin/facility/course";
import { GetTeeDecks } from "api/rpc/2024-04/facilityAdmin/facility/teedeck";
import { PostLeagueScoresheet, TPostLeagueScoresheet } from "api/rpc/2024-04/facilityAdmin/league/scoring/scoresheet";

import { showError, showSuccess } from "redux/actions/ui";
import { ICourse, IDivision, ITeeDeck } from "redux/reducers/models/facility";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import { Select } from "components/select/";
import DatePickerInput from "components/datePickerInput/DatePickerInput";

export default function LeagueScoresheetNew() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { leagueId } = useParams<{ leagueId: string }>();

  const dispatch = useAppDispatch();

  const [leagueScoresheetState, setLeagueScoresheetState] = useState<
    Omit<TPostLeagueScoresheet, "league_id" | "date_played">
  >({
    name: "",
    division_front_id: 0,
    division_back_id: 0,
    tee_deck_id: null,
  });

  const [datePlayed, setDatePlayed] = useState<Date>(null);

  const [teeDecks, setTeeDecks] = useState<ITeeDeck[]>([]);
  const [filterState, setFilterState] = useState<{ courses: ICourse[]; divisions: IDivision[] }>({
    courses: [],
    divisions: undefined,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadTeeDecks(source.token);
    void loadCourses(source.token);
    return () => source.cancel();
  }, []);

  async function loadTeeDecks(token?: CancelToken) {
    const res = await GetTeeDecks(null, true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading TeeDecks")); // TODO: Translation
    }

    setTeeDecks(res.status !== StatusCode.OK ? [] : res.data);
  }

  async function loadCourses(token?: CancelToken) {
    const res = await GetCourse({ scoring_only: true }, true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading courses")); // TODO: Translation
    }

    setFilterState(prev => ({
      ...prev,
      courses: res.status !== StatusCode.OK ? [] : res.data,
    }));
  }

  async function createScoresheet() {
    const params: TPostLeagueScoresheet = {
      league_id: leagueId,
      ...leagueScoresheetState,
      division_front_id: leagueScoresheetState.division_front_id === 0 ? null : leagueScoresheetState.division_front_id,
      division_back_id: leagueScoresheetState.division_back_id === 0 ? null : leagueScoresheetState.division_back_id,
      date_played: new Date(datePlayed).toISOString().slice(0, 10),
    };

    const res = await PostLeagueScoresheet(params, true);

    if (res.status !== StatusCode.OK) {
      dispatch(
        showError(typeof res.data === "string" ? res.data : t("secure.facility.league.league_scoresheet_new.010")),
      );
      return;
    }

    dispatch(showSuccess(t("secure.facility.league.league_scoresheet_new.011")));
    history.push(`/admin/league/${leagueId}/rounds`);
  }

  /** reset division_front_id & division_back_id on course change */
  function handleCourseSelect(courseId: number) {
    setFilterState(prev => ({ ...prev, divisions: prev.courses.find(val => val.id === courseId).divisions }));
    setLeagueScoresheetState(prev => ({ ...prev, division_front_id: 0, division_back_id: 0 }));
  }

  const primaryAction = {
    content: t("secure.facility.league.league_scoresheet_new.002"),
    action: createScoresheet,
  };

  return (
    <Page
      title={"New Round"} // TODO: Translation
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: "Rounds", // TODO: Translation
          url: "/admin/league/" + String(leagueId) + "/rounds",
        },
      ]}
    >
      <Card>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Input
                value={leagueScoresheetState.name || ""}
                label={t("secure.facility.league.league_scoresheet_new.003")}
                id="name"
                onChange={e => setLeagueScoresheetState(prevState => ({ ...prevState, name: e.target.value }))}
                placeholder={t("secure.facility.league.league_scoresheet_new.004")}
              />

              <div>
                <DatePickerInput
                  label={"Date"} // TODO: Translation
                  months={1}
                  position="left"
                  startingDate={datePlayed}
                  setStartingDate={selectedDate => setDatePlayed(selectedDate)}
                />
              </div>
            </FormLayout.Group>
            <FormLayout.Group>
              <Select
                label={"Course"} // TODO: Translation
                onChange={(value: number) => handleCourseSelect(value)}
              >
                {filterState.courses.map(course => (
                  <Select.Option key={course.id} value={course.id} name={course.long_name}>
                    {course.long_name}
                  </Select.Option>
                ))}
              </Select>
            </FormLayout.Group>
            <FormLayout.Group>
              <Select
                label={"Front Division"} // TODO: Translation
                onChange={(value: any) => setLeagueScoresheetState(prev => ({ ...prev, division_front_id: value }))}
                disabled={filterState.divisions === undefined}
                defaultValue={leagueScoresheetState.division_front_id}
              >
                {filterState.divisions?.map(division => (
                  <Select.Option key={division.id} value={division.id} name={division.title}>
                    {division.title}
                  </Select.Option>
                ))}
              </Select>

              <Select
                label={"Back Division"} // TODO: Translation
                onChange={(value: any) => setLeagueScoresheetState(prev => ({ ...prev, division_back_id: value }))}
                disabled={filterState.divisions === undefined}
                defaultValue={leagueScoresheetState.division_back_id}
              >
                {filterState.divisions?.map(division => (
                  <Select.Option key={division.id} value={division.id} name={division.title}>
                    {division.title}
                  </Select.Option>
                ))}
              </Select>
            </FormLayout.Group>
            <FormLayout.Group>
              <Select
                label={t("secure.facility.league.league_scoresheet_new.007")}
                onChange={(value: any) => setLeagueScoresheetState(prev => ({ ...prev, tee_deck_id: value }))}
              >
                {teeDecks.map(teeDeck => (
                  <Select.Option key={teeDeck.id} value={teeDeck.id} name={teeDeck.title}>
                    {teeDeck.title}
                  </Select.Option>
                ))}
              </Select>
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>
    </Page>
  );
}
