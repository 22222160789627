import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { IGateway, IKind, IPaymentMethod, IProcessingType, ISource } from "redux/reducers/models/transaction";

const apiVersion = "2024-04";
const adminType = "client-admin";
const baseCustomerUrl = "/" + apiVersion + "/" + adminType + "/customer";

interface IGetCustomer {
  id?: number;
  search?: string;
  state?: string;
  "address-lines"?: boolean;
  "default-address-line"?: boolean;
  memberships?: boolean;
  limit?: number;
  offset?: number;
  preferences?: boolean;
}

interface IPostCustomer {
  id?: number;
  first_name?: string;
  last_name?: string;
  phone?: string;
  email?: string;
  member_number?: number;
  state?: string;
}

interface ICustomerRes {
  status: number;
  message?: string;
  data: any;
}

export async function GetCustomer(
  params: IGetCustomer,
  useGlobalLoader?: boolean,
  token?: CancelToken,
): Promise<ICustomerRes> {
  return await APIGet(baseCustomerUrl, params, useGlobalLoader, token);
}

export async function PostCustomer(params: IPostCustomer, useGlobalLoader: boolean): Promise<ICustomerRes> {
  return await APIPost(baseCustomerUrl, params, useGlobalLoader);
}

export async function PutCustomer(params: any, useGlobalLoader: boolean): Promise<ICustomerRes> {
  return await APIPut(baseCustomerUrl, params, useGlobalLoader);
}

export async function DeleteCustomer(params: { id: number }, useGlobalLoader: boolean): Promise<ICustomerRes> {
  return await APIDelete(baseCustomerUrl, params, useGlobalLoader);
}

//Get Customer Notes
export async function GetCustomerNotes(params: any, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseCustomerUrl + "/note", params, useGlobalLoader, token);
}

// Upload Customer Profile Image
export async function UploadCustomerProfileImage(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseCustomerUrl + "/profile-image", params, useGlobalLoader);
}

// Delete Customer Profile Image
export async function DeleteCustomerProfileImage(params: any, useGlobalLoader: boolean) {
  return await APIDelete(baseCustomerUrl + "/profile-image", params, useGlobalLoader);
}

//POST Customer Notes
export async function PostCustomerNote(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseCustomerUrl + "/note", params, useGlobalLoader);
}

//Delete Customer Note
export async function DeleteCustomerNote(params: { id: number }, useGlobalLoader: boolean) {
  return await APIDelete(baseCustomerUrl + "/note", params, useGlobalLoader);
}

//PUT Customer Note
export async function PutCustomerNote(params: { id: number; content: string }, useGlobalLoader: boolean) {
  return await APIPut(baseCustomerUrl + "/note", params, useGlobalLoader);
}

export async function PostCustomerImport(params: FormData, useGlobalLoader: boolean): Promise<ICustomerRes> {
  return await APIPost(baseCustomerUrl + "/import", params, useGlobalLoader);
}

export async function SendPasswordReset(params: { email: string }, useGlobalLoader: boolean) {
  return await APIPost(baseCustomerUrl + "/reset", params, useGlobalLoader);
}

export async function GetCustomerType(
  params: { id?: number; application?: "green_fee" | "power_cart" },
  useGlobalLoader?: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseCustomerUrl + "/type", params, useGlobalLoader, token);
}

export async function PutMergeCustomers(
  params: { parent_id: number; child_id: number },
  useGlobalLoader: boolean,
): Promise<ICustomerRes> {
  return await APIPut(baseCustomerUrl + "/merge", params, useGlobalLoader);
}

export interface IPutCustomerPreferences {
  customer_id: number;
  marketing_email: boolean;
  booking_confirmation_email: boolean;
  booking_reminder_email: boolean;
  booking_update_email: boolean;
  booking_cancellation_email: boolean;
}

export async function PutCustomerPreferences(params: IPutCustomerPreferences, useGlobalLoader: boolean) {
  return await APIPut(baseCustomerUrl + "/preference", params, useGlobalLoader);
}

interface IPostPaymentMethodParams {
  payment_method_id: string;
  customer_id: number;
  save_card: boolean;
}

interface IGetCustomerPaymentMethodParams {
  id?: number;
  customer_id?: number;
}

export async function PostSetup(params: { customer_id: number }, useGlobalLoader?: boolean) {
  return await APIPost(baseCustomerUrl + "/payment-method/setup", params, useGlobalLoader);
}

export async function GetCustomerPaymentMethod(params: IGetCustomerPaymentMethodParams, useGlobalLoader?: boolean) {
  return await APIGet(baseCustomerUrl + "/payment-method", params, useGlobalLoader);
}

export async function PostPaymentMethod(params: IPostPaymentMethodParams, useGlobalLoader?: boolean) {
  return await APIPost(baseCustomerUrl + "/payment-method", params, useGlobalLoader);
}

export async function DeletePaymentMethod(params: IGetCustomerPaymentMethodParams, useGlobalLoader?: boolean) {
  return await APIDelete(baseCustomerUrl + "/payment-method", params, useGlobalLoader);
}
