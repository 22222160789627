import { APIGet, APIPost, APIPut, APIDelete, IAPIResponse } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const baseUrl = "/" + apiVersion + "/customer/payment-method";

type CustomerPaymentMethod = "card" | "gift_card" | "rain_check";

export interface ICustomerPaymentMethod {
  brand: string;
  client_id: number;
  exp_month: number;
  exp_year: number;
  id: number;
  last4: string;
  stripe_payment_method_id: string;
  type: CustomerPaymentMethod;
}

export interface ICustomerPaymentMethods {
  card: Array<ICustomerPaymentMethod>;
  // gift_card
  // rain_check
}

interface IPostPaymentMethodParams {
  payment_method_id: string;
  save_card: boolean;
  facility_id?: number;
}

export async function GetCustomerPaymentMethods(
  params: { id?: number; type?: Array<CustomerPaymentMethod> },
  useGlobalLoader: boolean,
  cancelToken?: CancelToken,
): Promise<IAPIResponse<ICustomerPaymentMethods>> {
  return await APIGet(baseUrl, params, useGlobalLoader, cancelToken);
}

export async function PostSetup(useGlobalLoader?: boolean) {
  return await APIPost(baseUrl + "/setup", undefined, useGlobalLoader);
}

export async function PostPaymentMethod(params: IPostPaymentMethodParams, useGlobalLoader?: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

export async function DeleteCustomerPaymentMethod(params: { id: number }, useGlobalLoader: boolean) {
  return await APIDelete(baseUrl, params, useGlobalLoader);
}
