import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import Page from "components/page/Page";
import {
  GetModifierGroup,
  PutModifierGroup,
  DeleteModifierGroup,
  PostModifierGroupVariants,
} from "api/rpc/2024-04/masterAdmin/product/modifierGroups";
import { StatusCode } from "api/protocols";
import Input from "components/form/input/Input";
import Card from "components/card/Card";
import Sheet from "components/sheet/Sheet";
import Callout from "components/callout/Callout";
import { IProduct, IVariant } from "redux/reducers/models/product";
import Toggle from "components/form/toggle/Toggle";
import { VariantCards } from "components/variantCards/VariantCards";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GetProduct, PutProductColor } from "api/rpc/2024-04/masterAdmin/product/product";
import { NotificationType } from "components/notificationBar/NotificationBar";
import { isEqual } from "lodash";
import { useTranslation, Trans } from "react-i18next";
import Popup from "components/popup/Popup";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { showError, showSuccess } from "redux/actions/ui";

interface IModifierGroupEditable {
  id: number;
  title: string;
  multiSelect: boolean;
  required: boolean;
}

interface IModiferGroupState {
  modifierGroup: IModifierGroupEditable;
  modifierGroupLoaded: boolean;
}

interface IVariantState {
  variants: IVariant[];
  variantsLoaded: boolean;
  variantsToAddToModifierGroup: IVariant[];
  availableVariantPositions: number[];
  productSearchResults: IProduct[];
}

interface IUnsavedChangesState {
  modifierGroupBeforeChanges: IModifierGroupEditable;
  variantsBeforeChanges: IVariant[];
}

interface IModalVisibilityState {
  deleteModalVisibility: boolean;
  addVariantsVisibility: boolean;
}

interface IFilterState {
  search: string;
}

export interface IProductOverride {
  id: number;
  button_color: string;
}

export interface IVariantModifier {
  id?: number;
  title?: string;
  default?: boolean;
  position?: number;
}

export default function ModifierGroupEdit() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { masterFacilityStore } = useAppSelector(store => store);
  const dispatch = useAppDispatch();

  const [modifierGroupState, setModifierGroupState] = useState<IModiferGroupState>({
    modifierGroup: {
      id: null,
      title: "",
      multiSelect: false,
      required: false,
    },
    modifierGroupLoaded: false,
  });

  const [variantState, setVariantState] = useState<IVariantState>({
    variants: [],
    variantsLoaded: false,
    variantsToAddToModifierGroup: [],
    availableVariantPositions: [],
    productSearchResults: [],
  });

  const [unsavedChangesState, setUnsavedChangesState] = useState<IUnsavedChangesState>({
    modifierGroupBeforeChanges: undefined,
    variantsBeforeChanges: undefined,
  });

  const [modalVisibilityState, setModalVisibilityState] = useState<IModalVisibilityState>({
    deleteModalVisibility: false,
    addVariantsVisibility: false,
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    search: "",
  });

  const [productOverrides, setProductOverrides] = useState<IProductOverride[]>([]);

  useEffect(() => {
    void loadModifierGroup();
  }, [masterFacilityStore.facility]);

  // Search Products
  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (async () => {
          try {
            const productSearchResults = await loadProducts();
            if (mounted) {
              setVariantState(prevState => ({ ...prevState, productSearchResults }));
            }
          } catch (error) {
            console.log("err", error);
          }
          return;
        })();
      }, 1000);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [filterState.search]);

  async function loadProducts() {
    const productRes = await GetProduct(
      {
        type: "Modifier",
        search: filterState.search,
        extended_variants: true,
        facility_id: masterFacilityStore.facility?.id,
        client_id: masterFacilityStore.facility?.client_id,
      },
      true,
    );
    if (productRes.status !== StatusCode.OK) {
      return [];
    }

    return productRes.data ?? [];
  }

  async function loadModifierGroup() {
    const resModGroup = await GetModifierGroup(
      {
        id: Number(id),
        extended: true,
        facility_id: masterFacilityStore.facility?.id,
      },
      true,
    );
    if (resModGroup.status !== StatusCode.OK || resModGroup.data.length <= 0) {
      dispatch(showError(t("secure.facility.settings.modifier_group.modifier_group_edit.022")));
      return;
    }

    setModifierGroupState(prev => ({
      ...prev,
      modifierGroup: {
        id: resModGroup.data[0].id,
        title: resModGroup.data[0].title,
        multiSelect: resModGroup.data[0].multi_select,
        required: resModGroup.data[0].required,
      },
      modifierGroupLoaded: true,
    }));
    setVariantState(prev => ({
      ...prev,
      variants: resModGroup.data[0].variants,
      variantsLoaded: true,
    }));
  }

  async function removeModifierGroup() {
    const res = await DeleteModifierGroup(
      {
        id: modifierGroupState.modifierGroup.id,
        facility_id: masterFacilityStore.facility?.id,
      },
      true,
    );

    if (res.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.modifier_group.modifier_group_edit.023")));
      return;
    }

    history.push("/admin/facility/settings/modifier-group");
  }

  function handleDeleteModalVisibility() {
    setModalVisibilityState(prev => ({
      ...prev,
      deleteModalVisibility: !modalVisibilityState.deleteModalVisibility,
    }));
  }

  function handleTitleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setModifierGroupState(prev => ({ ...prev, modifierGroup: { ...prev.modifierGroup, title: event.target.value } }));
  }

  function handleMultiSelectToggle() {
    if (modifierGroupState.modifierGroup.multiSelect) {
      const updatedVariants = variantState.variants.map(variant => {
        return { ...variant, modifier_group: { ...variant.modifier_group, default: false } };
      });

      setVariantState(prev => ({
        ...prev,
        variants: updatedVariants,
      }));
    }

    setModifierGroupState(prev => ({
      ...prev,
      modifierGroup: { ...prev.modifierGroup, multiSelect: !prev.modifierGroup.multiSelect },
    }));
  }

  function handleRequiredToggle() {
    setModifierGroupState(prev => ({
      ...prev,
      modifierGroup: { ...prev.modifierGroup, required: !prev.modifierGroup.required },
    }));
  }

  function handleChangeVariantPosition(id: number, position: number) {
    const updatedVariants = variantState.variants.map(variant => {
      if (variant.id === id) {
        return { ...variant, modifier_group: { ...variant.modifier_group, position: position } };
      } else {
        return variant;
      }
    });

    setVariantState(prev => ({ ...prev, variants: updatedVariants }));
  }

  function handleRemoveVariant(id: number) {
    const updatedVariants = variantState.variants.filter(variant => variant.id !== id);

    setVariantState(prev => ({
      ...prev,
      variants: updatedVariants,
    }));
  }

  function handleOpenAddVariantsModal(availableVariantPositions: number[]) {
    setModalVisibilityState(prev => ({
      ...prev,
      addVariantsVisibility: !prev.addVariantsVisibility,
    }));
    setVariantState(prev => ({ ...prev, availableVariantPositions }));
    setFilterState({ search: "" });
  }

  function handleCloseAddVariantsModal() {
    setModalVisibilityState(prev => ({
      ...prev,
      addVariantsVisibility: !prev.addVariantsVisibility,
    }));
    setVariantState(prev => ({
      ...prev,
      variantsToAddToModifierGroup: [],
      productSearchResults: filterState.search === "" ? prev.productSearchResults : [],
      availableVariantPositions: [],
    }));
  }

  function addVariants() {
    const updatedVariantsToAddToModifierGroup: IVariant[] = [];

    for (
      let i = 0;
      i < Math.min(variantState.variantsToAddToModifierGroup.length, variantState.availableVariantPositions.length);
      i++
    ) {
      const variantToAdd = variantState.variantsToAddToModifierGroup[i];

      updatedVariantsToAddToModifierGroup.push({
        ...variantToAdd,
        modifier_group: {
          ...variantToAdd.modifier_group,
          position: variantState.availableVariantPositions[i],
          default: false,
        },
      });
    }

    setVariantState(prev => ({
      ...prev,
      variants: [...prev.variants, ...updatedVariantsToAddToModifierGroup],
    }));
    handleCloseAddVariantsModal();
  }

  function handleSelectVariantToAdd(variant: IVariant, product: IProduct) {
    if (variantState.variantsToAddToModifierGroup.length >= variantState.availableVariantPositions.length) {
      return;
    }

    const variantToAdd: IVariant = {
      ...variant,
      product,
    };

    const updatedVariantsToAddToModifierGroup = [...variantState.variantsToAddToModifierGroup, variantToAdd];

    setVariantState(prev => ({ ...prev, variantsToAddToModifierGroup: updatedVariantsToAddToModifierGroup }));
  }

  function handleUnselectVariantToAdd(variant: IVariant) {
    const updatedVariantsToAddToModifierGroup = variantState.variantsToAddToModifierGroup.filter(
      variantToAdd => variantToAdd.id !== variant.id,
    );

    setVariantState(prev => ({ ...prev, variantsToAddToModifierGroup: updatedVariantsToAddToModifierGroup }));
  }

  function variantAlreadyAdded(variant: IVariant) {
    return (
      variantState.variants.some(existingVariant => existingVariant.id === variant.id) ||
      variantState.variantsToAddToModifierGroup.some(variantToAdd => variantToAdd.id === variant.id)
    );
  }

  function setVariants(variants: IVariant[]) {
    setVariantState(prev => ({ ...prev, variants }));
  }

  function variantToVariantModifier(variant: IVariant): IVariantModifier {
    return {
      id: variant.id,
      title: variant.title,
      default: !!variant.modifier_group?.default,
      position: variant.modifier_group?.position,
    };
  }

  function unsavedChangesExist() {
    if (
      unsavedChangesState.modifierGroupBeforeChanges === undefined ||
      unsavedChangesState.variantsBeforeChanges === undefined
    ) {
      if (modifierGroupState.modifierGroupLoaded && variantState.variantsLoaded) {
        setUnsavedChangesState(prev => ({
          ...prev,
          modifierGroupBeforeChanges: modifierGroupState.modifierGroup,
          variantsBeforeChanges: variantState.variants,
        }));
      }
      return false;
    }

    const sortedVariantModifiersBeforeChanges = [...unsavedChangesState.variantsBeforeChanges]
      .sort((prev, next) => prev.id - next.id)
      .map(variantToVariantModifier);

    const sortedVariantModifiersAfterChanges = [...variantState.variants]
      .sort((prev, next) => prev.id - next.id)
      .map(variantToVariantModifier);

    return (
      !isEqual(unsavedChangesState.modifierGroupBeforeChanges, modifierGroupState.modifierGroup) ||
      !isEqual(sortedVariantModifiersBeforeChanges, sortedVariantModifiersAfterChanges) ||
      productOverrides.length > 0
    );
  }

  async function saveModifierGroupHandler() {
    const putModifierGroupResponse = await PutModifierGroup(
      {
        id: modifierGroupState.modifierGroup.id,
        title: modifierGroupState.modifierGroup.title,
        multi_select: modifierGroupState.modifierGroup.multiSelect,
        required: modifierGroupState.modifierGroup.required,
        facility_id: masterFacilityStore.facility?.id,
      },
      false,
    );

    if (putModifierGroupResponse.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.modifier_group.modifier_group_edit.024")));
      return;
    }

    const postModifierGroupVariantsResponse = await PostModifierGroupVariants(
      {
        modifier_group_id: modifierGroupState.modifierGroup.id,
        variants: variantState.variants.map(variantToVariantModifier),
        facility_id: masterFacilityStore.facility?.id,
        client_id: masterFacilityStore.facility?.client_id,
      },
      false,
    );

    if (postModifierGroupVariantsResponse.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.modifier_group.modifier_group_edit.025")));
      return;
    }

    const putProductColorResponse = await PutProductColor(
      {
        products: productOverrides.map(productOverride => ({
          id: productOverride.id,
          button_color: productOverride.button_color,
        })),
        facility_id: masterFacilityStore.facility?.id,
        client_id: masterFacilityStore.facility?.client_id,
      },
      false,
    );

    if (putProductColorResponse.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.modifier_group.modifier_group_edit.026")));
      return;
    }

    const resModGroup = await GetModifierGroup(
      {
        id: modifierGroupState.modifierGroup.id,
        extended: true,
        facility_id: masterFacilityStore.facility?.id,
      },
      false,
    );

    if (resModGroup.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.modifier_group.modifier_group_edit.027")));
      return;
    }

    const updatedModifierGroup: IModifierGroupEditable = {
      id: resModGroup.data[0].id,
      title: resModGroup.data[0].title,
      multiSelect: resModGroup.data[0].multi_select,
      required: resModGroup.data[0].required,
    };

    const updatedVariants: IVariant[] = resModGroup.data[0].variants;

    setModifierGroupState(prev => ({
      ...prev,
      modifierGroup: updatedModifierGroup,
    }));

    setVariantState(prev => ({
      ...prev,
      variants: updatedVariants,
    }));

    setUnsavedChangesState(prev => ({
      ...prev,
      modifierGroupBeforeChanges: updatedModifierGroup,
      variantsBeforeChanges: updatedVariants,
    }));

    setProductOverrides([]);
  }

  function cancelUnsavedChanges() {
    setModifierGroupState(prev => ({
      ...prev,
      modifierGroup: unsavedChangesState.modifierGroupBeforeChanges,
    }));
    setVariantState(prev => ({
      ...prev,
      variants: unsavedChangesState.variantsBeforeChanges,
    }));
    setProductOverrides([]);
  }

  return (
    <div>
      <Page
        title={
          t("secure.facility.settings.modifier_group.modifier_group_edit.004") +
          (unsavedChangesState.modifierGroupBeforeChanges?.title ?? "")
        }
        subtitle={masterFacilityStore.facility ? masterFacilityStore.facility.long_name : "No Facility Selected"}
        narrow
        breadcrumbs={[
          {
            prefix: true,
            label: t("secure.facility.settings.modifier_group.modifier_group_edit.005"),
            url: "/admin/facility/settings/modifier-group",
          },
        ]}
        notificationBarProps={{
          isVisible:
            unsavedChangesExist() &&
            !modalVisibilityState.addVariantsVisibility &&
            !modalVisibilityState.deleteModalVisibility,
          onAction: saveModifierGroupHandler,
          onCancel: cancelUnsavedChanges,
        }}
        // primaryAction={deleteAction}
        multipleActionDropdownAction={{
          label: "Options",
          dropdownProps: {
            alignment: "right",
            options: [
              {
                type: "handler",
                label: "Delete",
                icon: "trash",
                handler: handleDeleteModalVisibility,
                disabled: !masterFacilityStore.facility,
              },
            ],
          },
        }}
      >
        {masterFacilityStore.facility && (
          <Card>
            <Card.Section>
              <Input
                label={t("secure.facility.settings.modifier_group.modifier_group_edit.006")}
                className="mb-4"
                value={modifierGroupState.modifierGroup.title}
                onChange={handleTitleChange}
                placeholder={t("secure.facility.settings.modifier_group.modifier_group_edit.006")}
              />
              <div className="flex flex-column gap-3">
                <Toggle
                  labelTop={t("secure.facility.settings.modifier_group.modifier_group_edit.007")}
                  checked={modifierGroupState.modifierGroup.multiSelect}
                  onChange={handleMultiSelectToggle}
                />
                <Toggle
                  labelTop={t("secure.facility.settings.modifier_group.modifier_group_edit.028")}
                  checked={modifierGroupState.modifierGroup.required}
                  onChange={handleRequiredToggle}
                />
              </div>
            </Card.Section>

            <Card.Section>
              <VariantCards
                variants={variantState.variants}
                variantsLoaded={variantState.variantsLoaded}
                onChangeVariantPosition={handleChangeVariantPosition}
                onRemoveVariant={handleRemoveVariant}
                onAddVariants={handleOpenAddVariantsModal}
                setVariants={setVariants}
                productOverrides={productOverrides}
                setProductOverrides={setProductOverrides}
              />
            </Card.Section>
          </Card>
        )}
      </Page>

      <Popup
        open={modalVisibilityState.deleteModalVisibility}
        type="warning"
        title={t("secure.facility.settings.modifier_group.modifier_group_edit.014")}
        description={t("secure.facility.settings.modifier_group.modifier_group_edit.016")}
        okText={t("secure.facility.settings.modifier_group.modifier_group_edit.013")}
        onOk={removeModifierGroup}
        cancelText={t("secure.facility.settings.modifier_group.modifier_group_edit.012")}
        onCancel={handleDeleteModalVisibility}
      />

      <Sheet
        closable
        onCancel={handleCloseAddVariantsModal}
        size="medium"
        open={modalVisibilityState.addVariantsVisibility}
        cancelText={t("secure.facility.settings.modifier_group.modifier_group_edit.017")}
        okText={t("secure.facility.settings.modifier_group.modifier_group_edit.018")}
        title={t("secure.facility.settings.modifier_group.modifier_group_edit.029")}
        onOk={addVariants}
      >
        <div style={{ top: "60px" }} className="absolute bg-white z-20 h-6 w-11/12"></div>
        <div className="sticky-top bg-white z-20">
          <div className="text-lg">
            {`${variantState.variantsToAddToModifierGroup.length} / ${variantState.availableVariantPositions.length} available positions filled`}
          </div>
          {variantState.variantsToAddToModifierGroup.length > 0 && (
            <div className="mt-3">
              {variantState.variantsToAddToModifierGroup.map(variant => {
                return (
                  <div
                    key={variant.id}
                    className="inline-flex p-2 whitespace-nowrap items-center rounded-lg mt-1 mb-1 mr-2 border"
                  >
                    <p className="whitespace-nowrap overflow-clip inline-block overflow-hidden ml-1 font-semibold">
                      {variant.product.preferred_title ? variant.product.preferred_title : variant.product.title}
                    </p>
                    <span>
                      <FontAwesomeIcon
                        onClick={() => handleUnselectVariantToAdd(variant)}
                        className="ml-2 cursor-pointer"
                        icon={["fal", "xmark"]}
                      />
                    </span>
                  </div>
                );
              })}
            </div>
          )}
          <div className="relative mb-2">
            <Input
              value={filterState.search}
              onChange={(value: any) => setFilterState(prevState => ({ ...prevState, search: value.target.value }))}
              type="search"
              placeholder={t("secure.facility.settings.modifier_group.modifier_group_edit.020")}
            />
          </div>
        </div>

        <div className="overflow-y-scroll">
          {variantState.productSearchResults
            ?.filter(
              (product: IProduct) =>
                product?.variants?.length > 0 && !product.variants.every(variant => variantAlreadyAdded(variant)),
            )
            ?.map((product: IProduct, index: number) => {
              const singleVariantProduct = product.variant_count === 1;

              if (singleVariantProduct) {
                const singleVariant = product.variants[0];

                return (
                  <div
                    key={index}
                    onClick={() => handleSelectVariantToAdd(singleVariant, product)}
                    className="flex flex-row gap-5 hover:bg-gray-200 p-4 border-b border-gray-200 cursor-pointer select-none"
                  >
                    <div className="flex flex-1 font-normal">
                      {product?.preferred_title ? product?.preferred_title : product.title}
                    </div>
                  </div>
                );
              } else {
                return (
                  <div key={index}>
                    <div className="mt-4 mb-3">{product.title}</div>
                    <div className="mb-4">
                      {product.variants
                        ?.filter(variant => !variantAlreadyAdded(variant))
                        ?.map((variant, index) => {
                          return (
                            <div
                              key={index}
                              onClick={() => handleSelectVariantToAdd(variant, product)}
                              className="flex flex-row gap-5 hover:bg-gray-200 p-4 border-b border-gray-200 cursor-pointer select-none ml-3"
                            >
                              <div className="flex flex-1 font-normal">
                                {product?.preferred_title ? product?.preferred_title : product.title}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
              }
            })}
        </div>
      </Sheet>
    </div>
  );
}
