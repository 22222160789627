import React, { useState, useEffect, ChangeEvent } from "react";
import { useHistory } from "react-router";
import axios, { CancelToken } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import useModal from "hooks/modals/useModal";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";
import moment from "moment";

import { closeEditBooking, dequeue, enqueue, showError, showSuccess } from "redux/actions/ui";
import { ICustomer } from "redux/reducers/models/customer";
import { IBooking, ISlot } from "redux/reducers/models/teetime";

import { StatusCode } from "api/protocols";
import {
  PutCopyBooking,
  GetAuditTrails,
  PutBooking,
  PutCancelBooking,
  PutMoveBooking,
  PutBookingConfirmation,
  PostBookingNote,
  GetBookingNotes,
  DeleteBookingNote,
  PutBookingNote,
} from "api/rpc/2024-04/masterAdmin/teesheet/booking";
import {
  GetCustomer,
  PostCustomer,
  GetCustomerPaymentMethod,
  PostPaymentMethod,
  PostSetup,
} from "api/rpc/2024-04/clientAdmin/customer/customer";
import { DeleteTeeTimeLocks } from "api/rpc/clientAdmin/teeSheet/teetime";

import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { customerErrorMessage, delay } from "helpers/Helpers";
import { MOBILE_WIDTH } from "helpers/ScreenSizes";

import Sheet from "components/sheet/Sheet";
import Tabs from "components/tabs/Tabs";
import Popover from "components/popover/Popover";
import { Select } from "components/select/index";
import { ButtonNew as Button, ButtonNew } from "components/buttonNew/index";
import GolferCard from "components/bookingPopUp/golferCard/GolferCard";
import Note from "components/note/Note";
import AuditTrailNote from "components/bookingPopUp/auditTrailNote/AuditTrailNote";
import Radio from "components/radio";
import NewCustomer, { ICustomerInfoState } from "components/newCustomer/NewCustomer";
import CreditCardCatalogue from "elements/teesheet/creditCard/CreditCardCatalogue";
import CreditCardAdd from "elements/teesheet/creditCard/CreditCardAdd";
import { useTranslation, Trans } from "react-i18next";
import MoveBookingTab from "elements/teesheet/MoveBookingTab";
import DuplicateBookingTab from "elements/teesheet/DuplicateBookingTab";
import Portal from "elements/Portal";
import TextField from "components/form/textField/TextField";
import Spin from "components/spin/spin";

import "public/scss/teesheet.scss";
import "elements/teesheet/bookingModal.scss";

interface IBookingModalEditProps {
  selectedBooking: IBooking;
  teeTimeLock: boolean;
  /**Optional boolean - Refresh selected tee time when modal is closed. Set to true on default. */
  refreshTeeTime?: boolean;
  loading: boolean;
  loadingText: string;
  reloadTeeTimes: () => void;
}

interface IMainState {
  [key: string]: any;
  golferSearchQuery1: string;
  golferSearchQuery2: string;
  golferSearchQuery3: string;
  golferSearchQuery4: string;
  golferSearchResult1: (Partial<ICustomer> | ICustomer)[];
  golferSearchResult2: (Partial<ICustomer> | ICustomer)[];
  golferSearchResult3: (Partial<ICustomer> | ICustomer)[];
  golferSearchResult4: (Partial<ICustomer> | ICustomer)[];
  searching: boolean;
}

export interface INewPlayerState {
  newCustomer: ICustomer;
  selectedSlot: ISlot;
  newCustomerSheetActive: boolean;
  currentSearch: string;
}

export interface IEditBookingState {
  holes: number;
  quantity: number;
  powerCartQuantity: number;
  teeTimeSlots: { id: number; customer_id: number; guest: boolean }[];
  loadedTeeTimeSlots: { id: number; customer_id: number; guest: boolean }[];
  customerSelection: ICustomer[];
  originalCustomerSelection: ICustomer[];
  customer: any;
  customers: [];
  golferInitialLoad: any;
  cartInitialLoad: any;
  holesInitialLoad: any;
  saveCard: boolean;
  addCard: boolean;
  auditTrails: any;
  notes: any;
  addNoteVisible: string;
  notesTitleVisible: string;
  paymentMethods: any[];
  selectedPaymentMethod?: number;
  cardReady: boolean;
  copyBooking: boolean;
  copyBookingIdList: any;
  copyBookingFutureDates: any;
  copyBookingDate: string;
  copyBookingTime: string;
  elementComplete: {
    cardNumber: boolean;
    cardExpiry: boolean;
    cardCvc: boolean;
  };

  holeButtons: boolean[]; // disabled
  playerButtons: boolean[]; // disabled
  cartButtons: boolean[]; // disabled

  bookingWithinHour: boolean;

  bookingPosting: boolean;
}

enum StateType {
  MainState = "IMainState",
  EditBookingState = "IEditBookingState",
  NewPlayerState = "INewPlayerState",
}

const BookingModalEdit: React.FC<IBookingModalEditProps> = props => {
  const { Option } = Select;
  const windowSize = useWindowSize();
  const history = useHistory();
  const { authStore, clientTeeSheetStore, uiStore, clientFacilityStore } = useAppSelector(state => state);
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const permissions = authStore.user?.permissions;

  const {
    state: newBookingNoteModal,
    closeModal: closeNewBookingNoteModal,
    updateModal: updateNewBookingNote,
  } = useModal({ content: "" });

  const {
    state: editNoteModal,
    closeModal: closeEditNoteModal,
    updateModal: updateEditNoteModal,
  } = useModal({ id: null as number, content: "" });

  const { selectedBooking, teeTimeLock, refreshTeeTime = true } = props;

  const [newPlayerState, setNewPlayerState] = useState<INewPlayerState>({
    newCustomer: null,
    selectedSlot: null,
    newCustomerSheetActive: false,
    currentSearch: "",
  });

  const [moveBookingPopoverIsVisible, setMoveBookingPopoverIsVisible] = useState(false);
  const [duplicateBookingPopoverIsVisible, setDuplicateBookingPopoverIsVisible] = useState(false);
  const [cancelBookingPopoverIsVisible, setCancelBookingPopoverIsVisible] = useState(false);

  const [state, setState] = useState<IMainState>({
    golferSearchQuery1: "",
    golferSearchQuery2: "",
    golferSearchQuery3: "",
    golferSearchQuery4: "",
    golferSearchResult1: [null],
    golferSearchResult2: [null],
    golferSearchResult3: [null],
    golferSearchResult4: [null],
    searching: false,
  });

  const [editBookingState, setEditBookingState] = useState<IEditBookingState>({
    holes: /*18*/ null,
    quantity: /*2*/ null,
    powerCartQuantity: /*0*/ null,
    teeTimeSlots: [],
    loadedTeeTimeSlots: [],
    customerSelection: [],
    originalCustomerSelection: [],
    customer: null,
    customers: [],
    golferInitialLoad: null,
    cartInitialLoad: null,
    holesInitialLoad: null,
    notes: [],
    auditTrails: [],
    addNoteVisible: "none",
    notesTitleVisible: "none",
    saveCard: false,
    addCard: false,
    paymentMethods: undefined,
    selectedPaymentMethod: undefined,
    cardReady: false,
    copyBooking: false,
    copyBookingIdList: [],
    copyBookingFutureDates: [],
    copyBookingDate: "",
    copyBookingTime: "",
    elementComplete: {
      cardNumber: false,
      cardExpiry: false,
      cardCvc: false,
    },

    holeButtons: [false, false], // disabled
    playerButtons: [false, false, false, false], // disabled
    cartButtons: [false, false], // disabled

    bookingWithinHour: false,

    bookingPosting: false,
  });

  function updateState<T>(newState: Partial<T>, type: StateType) {
    const states = {
      [StateType.MainState]: (newState: Partial<T>) =>
        setState((cur: IMainState) => {
          return { ...cur, ...newState };
        }),
      [StateType.EditBookingState]: (newState: Partial<T>) =>
        setEditBookingState((cur: IEditBookingState) => {
          return { ...cur, ...newState };
        }),
      [StateType.NewPlayerState]: (newState: Partial<T>) =>
        setNewPlayerState((cur: INewPlayerState) => {
          return { ...cur, ...newState };
        }),
    };

    states[type](newState);
  }

  useEffect(() => {
    if (uiStore.editBookingActive && clientTeeSheetStore.selectedTeeTime && selectedBooking) {
      const teeTimeSlots: any[] = [];
      const customerSelection: any[] = [];
      clientTeeSheetStore.selectedTeeTime?.slots
        .filter(slot => slot.tee_time_booking_id === selectedBooking?.id)
        .map((slot, i) => {
          if (slot.status === "booked") {
            customerSelection.push(slot.booking_participant.customer);
            teeTimeSlots.push({
              id: slot.id,
              customer_id: slot.booking_participant.customer_id,
              guest: slot.booking_participant.guest,
            });
          }
        });

      updateState<IEditBookingState>(
        {
          holes: selectedBooking.holes,
          quantity: selectedBooking.quantity,
          powerCartQuantity: selectedBooking.power_cart_quantity,
          selectedPaymentMethod: selectedBooking.customer_payment_method_id,
          customerSelection,
          originalCustomerSelection: [...customerSelection],
          teeTimeSlots,
          loadedTeeTimeSlots: teeTimeSlots.slice(),
        },
        StateType.EditBookingState,
      );

      // load paymentMethods on first load
      void loadCustomersPaymentMethods(customerSelection[0].id);
      void loadAuditTrail();
    }
  }, [selectedBooking]);

  const getBookingNotes = async () => {
    const getBookingNotesRes = await GetBookingNotes({ tee_time_booking_id: selectedBooking.id }, true);
    if (getBookingNotesRes.status !== StatusCode.OK) {
      dispatch(showError(getBookingNotesRes.message));
      return;
    }
    let tempNotes = getBookingNotesRes.data;
    if (tempNotes?.length > 0) {
      tempNotes = tempNotes.reverse();
    }
    updateState<IEditBookingState>(
      {
        notes: tempNotes,
      },
      StateType.EditBookingState,
    );
  };

  const handleUpdateBooking = async () => {
    const teeTimeSlots = editBookingState.teeTimeSlots;

    updateState<IEditBookingState>({ bookingPosting: true }, StateType.EditBookingState);

    // Handle booking guests
    if (editBookingState.quantity > editBookingState.teeTimeSlots.length) {
      const diff = editBookingState.quantity - editBookingState.teeTimeSlots.length;
      //Get all slots that have been freed up
      const availableSlots = editBookingState.loadedTeeTimeSlots.filter(
        initialSlot => !editBookingState.teeTimeSlots.some(slot => slot.id === initialSlot.id),
      );
      clientTeeSheetStore.selectedTeeTime.slots.forEach(slot => {
        //Get all available slots
        if (slot.status === "available") {
          availableSlots.push({ id: slot.id, customer_id: editBookingState.customerSelection[0].id, guest: true });
        }
      });
      //Set require amount of slots as guests
      for (let i = 0; i < diff; i++) {
        teeTimeSlots.push({
          id: availableSlots[i].id,
          customer_id: editBookingState.customerSelection[0].id,
          guest: true,
        });
      }

      updateState<IEditBookingState>({ teeTimeSlots }, StateType.EditBookingState);
    }

    //If no payment method is selected, add new card & set selected payment method as the new card
    if (elementsComplete) {
      const collectCardRes = await collectCard(selectedBooking.id, selectedBooking.customer_id);
      if (!collectCardRes) {
        return;
      }
    } else {
      const res = await PutBooking(
        {
          id: selectedBooking.id,
          quantity: Number(editBookingState.quantity),
          holes: Number(editBookingState.holes),
          power_cart_quantity: Number(editBookingState.powerCartQuantity),
          customer_payment_method_id: editBookingState.selectedPaymentMethod,
          tee_time_slots: editBookingState.teeTimeSlots,
        },
        false,
      );

      if (res.status !== StatusCode.OK) {
        dispatch(showError("Error updating booking"));
      } else {
        //Post booking note if textfield contains text
        // if (editBookingState.noteDescription !== "") {
        //   // const noteInfo = {
        //   //   tee_time_booking_id: selectedBooking.id,
        //   //   content: String(editBookingState.noteDescription),
        //   // };

        //   // const bookingNoteRes = await PostBookingNote(noteInfo, true);

        //   void handleAddNote();
        // }

        dispatch(showSuccess("Booking updated successfully"));
      }
    }

    closeBooking();

    props.reloadTeeTimes();
  };

  function closeBooking() {
    updateState<IEditBookingState>(
      {
        customerSelection: [],
        originalCustomerSelection: [],
        teeTimeSlots: [],
        addNoteVisible: "none",
        notesTitleVisible: "none",
        // noteDescription: "",
        notes: [],
        holes: null,
        quantity: null,
        powerCartQuantity: null,
        copyBooking: false,
        copyBookingIdList: [],
        copyBookingFutureDates: [],
        copyBookingDate: "",
        copyBookingTime: "",
        selectedPaymentMethod: null,
        paymentMethods: undefined,
        addCard: false,
        saveCard: false,
        elementComplete: {
          cardNumber: false,
          cardExpiry: false,
          cardCvc: false,
        },
        auditTrails: [],
        bookingPosting: false,
      },
      StateType.EditBookingState,
    );

    // clear search and result
    updateState<IMainState>(
      {
        golferSearchQuery1: "",
        golferSearchQuery2: "",
        golferSearchQuery3: "",
        golferSearchQuery4: "",
        golferSearchResult1: [null],
        golferSearchResult2: [null],
        golferSearchResult3: [null],
        golferSearchResult4: [null],
        searching: false,
      },
      StateType.MainState,
    );

    void deleteTeeTimeLocks();
    dispatch(closeEditBooking());

    setSelected(0);
    handleSecondaryTabToggle();
    // if (refreshTeeTime) {
    //   // force refresh, removes player
    //   void dispatch(selectTeeTime(clientTeeSheetStore.selectedTeeTime.id, true));
    // }
  }

  async function deleteTeeTimeLocks() {
    const deleteLocksRes = await DeleteTeeTimeLocks(false);
    if (deleteLocksRes.status !== StatusCode.OK) {
      console.log("Delete tee time locks error");
      return;
    }
  }

  /**
   * compares tee_time_quantity and turn_tee_time quantity and returns the lower quantity
   * if turn_tee_time_quantity is null turn_tee_time will be returned
   * @param tee_time_quantity
   * @param turn_tee_time_quantity
   * @returns number
   */
  const lowerQuantity = (tee_time_quantity: number, turn_tee_time_quantity: number, selected_holes: number): number => {
    if (turn_tee_time_quantity === null) {
      return tee_time_quantity;
    } else if (selected_holes === 9) {
      return tee_time_quantity;
    } else {
      return Math.min(tee_time_quantity, turn_tee_time_quantity);
    }
  };

  useEffect(() => {
    // disabling options...
    let mounted = true;

    const disableOptions = () => {
      const holeButtons: boolean[] = [true, true];
      let playerButtons: boolean[] = [false, false, false, false];

      let quantity_remaining = lowerQuantity(
        clientTeeSheetStore.selectedTeeTime?.quantity_remaining,
        clientTeeSheetStore.selectedTeeTime?.turn_tee_time?.quantity_remaining,
        editBookingState.holes,
      );

      // Disable additional player buttons to add players if tee time is locked
      if (!teeTimeLock) {
        quantity_remaining += editBookingState.loadedTeeTimeSlots.length;
      } else {
        quantity_remaining = editBookingState.loadedTeeTimeSlots.length;
      }

      if (editBookingState.quantity > quantity_remaining) {
        updateState<IEditBookingState>({ quantity: null }, StateType.EditBookingState);
      }

      // disabling number of players based on quantity remaining
      switch (quantity_remaining) {
        case 1:
          playerButtons = playerButtons.map((disabled, i) => {
            if (i === 0) {
              return (disabled = false);
            } else {
              return (disabled = true);
            }
          });
          break;

        case 2:
          playerButtons = playerButtons.map((disabled, i) => {
            if (i === 0 || i === 1) {
              return (disabled = false);
            } else {
              return (disabled = true);
            }
          });
          break;

        case 3:
          playerButtons = playerButtons.map((disabled, i) => {
            if (i !== 3) {
              return (disabled = false);
            } else {
              return (disabled = true);
            }
          });
          break;

        case 4:
          playerButtons = playerButtons.map((disabled, i) => {
            return (disabled = false);
          });
          break;
      }

      if (selectedBooking?.holes !== 18) {
        // Disabling holes
        clientTeeSheetStore.selectedTeeTime?.divisions_available?.forEach((division, index) => {
          // 9 holes available
          if (division === 1) {
            holeButtons[1] = false;
          } else if (division === 2) {
            // Only 9 holes available
            if (
              (clientTeeSheetStore.selectedTeeTime?.divisions_available?.length > 1 &&
                clientTeeSheetStore.selectedTeeTime?.turn_tee_time === null) ||
              clientTeeSheetStore?.selectedTeeTime?.turn_tee_time?.blocked_type === "blocked" ||
              ((clientTeeSheetStore.selectedTeeTime?.turn_tee_time?.league_id ||
                clientTeeSheetStore.selectedTeeTime?.turn_tee_time?.tournament_id) &&
                (clientTeeSheetStore.selectedTeeTime?.league_id !==
                  clientTeeSheetStore.selectedTeeTime?.turn_tee_time?.league_id ||
                  clientTeeSheetStore.selectedTeeTime?.tournament_id !==
                    clientTeeSheetStore.selectedTeeTime?.turn_tee_time?.tournament_id))
            ) {
              holeButtons[1] = false;
            } else if (clientTeeSheetStore.selectedTeeTime?.turn_tee_time != null) {
              // 18 holes available
              if (clientTeeSheetStore.selectedTeeTime?.turn_tee_time?.quantity_remaining >= editBookingState.quantity) {
                holeButtons[0] = false;
              } else {
                // Only 9 holes available
                holeButtons[1] = false;
              }
            }
          }
        });
      } else {
        holeButtons.fill(false);
      }

      if (mounted) {
        if (holeButtons[0] && holeButtons[1]) {
          updateState<IEditBookingState>({ holeButtons, playerButtons, holes: null }, StateType.EditBookingState);
        } else if (holeButtons[0]) {
          updateState<IEditBookingState>({ holeButtons, playerButtons, holes: 9 }, StateType.EditBookingState);
        } else if (holeButtons[1]) {
          updateState<IEditBookingState>({ holeButtons, playerButtons, holes: 18 }, StateType.EditBookingState);
        }
        updateState<IEditBookingState>({ holeButtons, playerButtons }, StateType.EditBookingState);
      }
    };

    const run = async () => {
      if (uiStore.editBookingActive === true) {
        // dispatch(enqueue());
        await delay(100);

        disableOptions();

        await delay(1000);
        // dispatch(dequeue());
      }
    };
    void run();

    return () => {
      mounted = false;
    };
  }, [
    clientTeeSheetStore.selectedTeeTime,
    editBookingState.holes,
    uiStore.editBookingActive,
    editBookingState.quantity,
  ]);

  const confirmCancelBooking = async () => {
    try {
      const putCancelRes = await PutCancelBooking({ tee_time_booking_id: Number(selectedBooking.id) }, true);

      if (putCancelRes.status === StatusCode.OK) {
        closeBooking();
        dispatch(showSuccess(t("elements.tee_sheet.booking_modal_edit.036")));
      }

      if (putCancelRes.status !== StatusCode.OK) {
        dispatch(showError(t("elements.tee_sheet.booking_modal_edit.037")));
      }

      props.reloadTeeTimes();
    } catch (e) {
      console.log(e);
    }
  };

  const confirmMoveBooking = async (selectedTeeTimeId: number) => {
    if (selectedTeeTimeId > 0) {
      const params = {
        tee_time_booking_id: Number(selectedBooking.id),
        tee_time_id: selectedTeeTimeId,
      };

      const moveBookingRes = await PutMoveBooking(params, true);
      if (moveBookingRes.status !== StatusCode.OK) {
        dispatch(showError(t("elements.tee_sheet.booking_modal_edit.001")));
        return;
      }

      dispatch(showSuccess(t("elements.tee_sheet.booking_modal_edit.002")));
      dispatch(closeEditBooking());

      props.reloadTeeTimes();

      history.push(`/admin/teesheet`);
    }
  };

  const addCopyBookingIdToList = (copyBookingId: number, copyBookingTime: string, copyBookingDate: string) => {
    if (copyBookingTime === null) {
      dispatch(showError(t("elements.tee_sheet.booking_modal_edit.038"))); // TODO: Translation
      return;
    }
    const tempList = editBookingState.copyBookingIdList;
    const currentCopyDates = editBookingState.copyBookingFutureDates;

    if (tempList.includes(copyBookingId) === false) {
      const dateString = copyBookingDate + " at " + copyBookingTime;
      currentCopyDates.push(dateString);
      tempList.push(copyBookingId);

      updateState<IEditBookingState>(
        { copyBookingIdList: tempList, copyBookingFutureDates: currentCopyDates },
        StateType.EditBookingState,
      );
    } else {
      dispatch(showError(t("elements.tee_sheet.booking_modal_edit.039"))); // TODO: Translation
    }
  };

  const confirmCopyBooking = async (copyBookingId: number | string) => {
    const tempIdList = editBookingState.copyBookingIdList;

    if (copyBookingId !== undefined && copyBookingId !== "") {
      if (tempIdList.includes(Number(copyBookingId)) === false) {
        tempIdList.push(copyBookingId);
      } else {
        dispatch(showError(t("elements.tee_sheet.booking_modal_edit.040"))); // TODO: Translation
      }
    }

    //API CALL
    if (tempIdList.length !== 0) {
      if (tempIdList[0] === null) {
        updateState<IEditBookingState>({ copyBookingIdList: [] }, StateType.EditBookingState);
        return;
      }

      const params = {
        tee_time_booking_id: Number(selectedBooking.id),
        tee_time_ids: tempIdList,
      };

      const copyBookingRes = await PutCopyBooking(params, true);
      if (copyBookingRes.status !== StatusCode.OK) {
        dispatch(showError(t("elements.tee_sheet.booking_modal_edit.003"))); // TODO: Translation
        updateState<IEditBookingState>({ copyBookingIdList: [] }, StateType.EditBookingState);
        return;
      }

      dispatch(showSuccess(t("elements.tee_sheet.booking_modal_edit.004")));
      dispatch(closeEditBooking());

      props.reloadTeeTimes();

      history.push(`/admin/teesheet`);
    }
  };

  async function loadCustomers(params: string, cancelToken: CancelToken) {
    const customerRes = await GetCustomer({ search: params }, false, cancelToken);

    if (customerRes.status !== StatusCode.OK) {
      return [];
    }

    return customerRes.data as Array<Record<string, any>>;
  }

  const search = async (
    mounted: boolean,
    golferSearchQuery: string,
    golferSearchResult: string,
    cancelToken: CancelToken,
  ) => {
    try {
      if (golferSearchQuery === "") {
        if (mounted) {
          updateState<IMainState>({ [golferSearchResult]: [null] }, StateType.MainState);
        }
        return;
      } else {
        updateState<IMainState>({ searching: true }, StateType.MainState);
        const res = await loadCustomers(golferSearchQuery, cancelToken);
        if (mounted) {
          updateState<IMainState>({ [golferSearchResult]: res, searching: false }, StateType.MainState);
        }
      }
    } catch (error) {
      console.log("err", error);
    }
    return;
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = setTimeout(() => {
        void search(mounted, state.golferSearchQuery1, "golferSearchResult1", source.token);
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      updateState<IMainState>({ golferSearchResult1: [null] }, StateType.MainState);
      source.cancel("Cancelled");
    };
  }, [state.golferSearchQuery1]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = setTimeout(() => {
        void search(mounted, state.golferSearchQuery2, "golferSearchResult2", source.token);
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      updateState<IMainState>({ golferSearchResult2: [null] }, StateType.MainState);
      source.cancel("Cancelled");
    };
  }, [state.golferSearchQuery2]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = setTimeout(() => {
        void search(mounted, state.golferSearchQuery3, "golferSearchResult3", source.token);
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      updateState<IMainState>({ golferSearchResult3: [null] }, StateType.MainState);
      source.cancel("Cancelled");
    };
  }, [state.golferSearchQuery3]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = setTimeout(() => {
        void search(mounted, state.golferSearchQuery4, "golferSearchResult4", source.token);
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      updateState<IMainState>({ golferSearchResult4: [null] }, StateType.MainState);
      source.cancel("Cancelled");
    };
  }, [state.golferSearchQuery4]);

  const handleCustomerSearch = (query: string, name: string) => {
    updateState<IMainState>({ [name]: query }, StateType.MainState);
    setNewPlayerState(prevState => ({
      ...prevState,
      currentSearch: query,
    }));
  };

  const handleCustomerSelection = (id: number | string, slot: ISlot, newCustomer: Record<string, any>) => {
    if (editBookingState.customerSelection.length === 4) {
      console.log("max players added, remove 1 or more players to add more");
      return;
    }

    if (id === "") {
      return;
    } else {
      const selection = !newCustomer
        ? state["golferSearchResult".concat(slot.position.toString())]
            .filter((res: ICustomer) => {
              if (res.id === id) {
                return true;
              } else {
                return false;
              }
            })
            .map((res: ICustomer) => res)
        : [newCustomer];

      const customerSelection = editBookingState.customerSelection;
      customerSelection.splice(slot.position - 1, 0, selection[0]);

      const teeTimeSlots = editBookingState.teeTimeSlots;
      teeTimeSlots.splice(slot.position - 1, 0, { id: slot.id, customer_id: selection[0].id, guest: false });

      updateState({ customerSelection, teeTimeSlots }, StateType.EditBookingState);
    }
  };

  // Organize away from rendering
  const tabs = [
    {
      id: "booking-details",
      content: t("elements.tee_sheet.booking_modal_edit.032"),
    },
    {
      id: "credit-card",
      content: t("elements.tee_sheet.booking_modal_edit.033"),
    },
    {
      id: "notes",
      content: t("elements.tee_sheet.booking_modal_edit.034"),
      // counter: 2,
    },
    {
      id: "audit-trail",
      content:
        windowSize.width <= MOBILE_WIDTH ? (
          <FontAwesomeIcon icon={["fas", "bars"]} style={{ fontSize: "16px" }} />
        ) : (
          t("elements.tee_sheet.booking_modal_edit.035")
        ),
    },
  ];

  const closeNewCustomer = () => {
    updateState<INewPlayerState>(
      {
        newCustomer: null,
        selectedSlot: null,
        newCustomerSheetActive: false,
      },
      StateType.NewPlayerState,
    );

    updateState<IMainState>(
      { golferSearchQuery1: "", golferSearchQuery2: "", golferSearchQuery3: "", golferSearchQuery4: "" },
      StateType.MainState,
    );
  };

  const addCustomer = async (customerInfo: ICustomerInfoState) => {
    const customer = await createCustomer(customerInfo);
    if (customer) {
      handleCustomerSelection(customer.id, newPlayerState.selectedSlot, customer);
    }
  };

  const createCustomer = async (customerInfo: ICustomerInfoState) => {
    const error =
      customerInfo.firstName === "" ||
      customerInfo.lastName === "" ||
      (customerInfo.emailAddress === "" && customerInfo.phoneNumber === "");

    if (error) {
      console.log("missing parameters");
      return null;
    }

    const customerRes = await PostCustomer(
      {
        first_name: customerInfo.firstName,
        last_name: customerInfo.lastName,
        phone: customerInfo.phoneNumber || null,
        email: customerInfo.emailAddress || null,
        state: "disabled",
      },
      true,
    );

    if (customerRes.status !== 200) {
      dispatch(showError(customerErrorMessage(t, customerRes?.data?.message)));
    }

    if (customerRes.status === 200) {
      updateState<INewPlayerState>({ newCustomer: customerRes.data }, StateType.NewPlayerState);
      void closeNewCustomer();
      dispatch(showSuccess("Customer created successfully")); // TODO: Translation
      return customerRes.data as ICustomer;
    }
    return null;
  };

  const removeGolfer = (slot: any, e: any) => {
    const deleteIndex = editBookingState.teeTimeSlots.indexOf(slot);
    let customerSelection = editBookingState.customerSelection;
    let teeTimeSlots = editBookingState.teeTimeSlots;
    let selectedPaymentMethod = editBookingState.selectedPaymentMethod;

    if (deleteIndex === 0) {
      selectedPaymentMethod = undefined;
    }

    //Get the number of guests the selected player has (+ themselves)
    const playerAndGuests = editBookingState.teeTimeSlots.filter(
      teeTimeSlot => teeTimeSlot.customer_id === slot.customer_id,
    );

    //If the selected player is not a guest but has guests, remove the player and guests
    if (playerAndGuests.length > 1 && slot.guest === false) {
      customerSelection = customerSelection.filter(customer => customer.id !== slot.customer_id);
      teeTimeSlots = teeTimeSlots.filter(teeTimeSlots => teeTimeSlots.customer_id !== slot.customer_id);
      //Remove single player
    } else if (deleteIndex !== -1) {
      customerSelection.splice(deleteIndex, 1);
      teeTimeSlots.splice(deleteIndex, 1);
    } else {
      return;
    }

    updateState<IEditBookingState>(
      { customerSelection, teeTimeSlots, selectedPaymentMethod },
      StateType.EditBookingState,
    );
  };

  const onSelectCreditCard = (id: number) => {
    if (id > 0) {
      if (id === editBookingState.selectedPaymentMethod) {
        // unselect
        updateState<IEditBookingState>({ selectedPaymentMethod: undefined }, StateType.EditBookingState);
      } else {
        updateState<IEditBookingState>({ selectedPaymentMethod: id, addCard: false }, StateType.EditBookingState);
      }
    } else {
      dispatch(showError(t("elements.tee_sheet.booking_modal_edit.005")));
    }
  };

  const loadCustomersPaymentMethods = async (customer_id: number) => {
    const paymentMethodRes = await GetCustomerPaymentMethod({ customer_id }, false);
    if (paymentMethodRes.status === StatusCode.OK) {
      //update paymentMethods and selectedPaymentMethod ONLY if original customer
      updateState<IEditBookingState>(
        {
          paymentMethods: paymentMethodRes.data,
          selectedPaymentMethod:
            selectedBooking.customer_id === customer_id && selectedBooking.customer_payment_method_id,
        },
        StateType.EditBookingState,
      );
    } else {
      dispatch(showError(paymentMethodRes.message));
    }
  };

  const loadAuditTrail = async () => {
    const auditTrailRes = await GetAuditTrails({ tee_time_booking_id: selectedBooking.id }, false);
    if (auditTrailRes.status === StatusCode.OK) {
      updateState<IEditBookingState>({ auditTrails: auditTrailRes.data }, StateType.EditBookingState);
    } else {
      dispatch(showError("Error loading audit trail"));
    }
  };

  //Function to save card to customer and to booking
  const setupCard = async (payment_method: string, booking_id: number, customer_id: number) => {
    const setupRes = await PostSetup({ customer_id }, true);

    console.log("setupRes", setupRes);

    if (setupRes.status !== StatusCode.OK) {
      dispatch(showError(setupRes.data.message));
      return false;
    } else {
      if (payment_method !== "undefined") {
        const stripeRes = await stripe.confirmCardSetup(setupRes?.data.setup_intent.client_secret, {
          payment_method: payment_method,
        });

        console.log("stripeRes", stripeRes);

        if (stripeRes.error) {
          dispatch(showError(stripeRes.error.message));
        } else {
          const methodRes = await PostPaymentMethod({
            payment_method_id: stripeRes.setupIntent.payment_method,
            customer_id: customer_id,
            save_card: editBookingState.saveCard,
          });
          console.log("methodRes", methodRes);
          if (methodRes.status !== StatusCode.OK) {
            dispatch(showError(methodRes.data.message));
            return false;
          } else {
            dispatch(showSuccess(methodRes.data.message));
            const bookingRes = await PutBooking(
              {
                customer_payment_method_id: methodRes?.data.id,
                id: booking_id,
                quantity: Number(editBookingState.quantity),
                holes: Number(editBookingState.holes),
                power_cart_quantity: Number(editBookingState.powerCartQuantity),
                tee_time_slots: editBookingState.teeTimeSlots,
              },
              true,
            );
            if (bookingRes.status !== StatusCode.OK) {
              dispatch(showError(bookingRes.message));
              return false;
            }
            dispatch(showSuccess(bookingRes.message));
            return true;
          }
        }
      }
    }
  };

  //Function to save card
  const stripe = useStripe();
  const elements = useElements();

  const collectCard = async (booking_id: number, customer_id: number) => {
    if (!stripe || !elements) {
      dispatch(showError(t("elements.tee_sheet.booking_modal_edit.006")));
      return false;
    }

    // Loader for length of operation to stop user from clicking
    dispatch(enqueue());
    try {
      let setupCardRes;
      const paymentMethod = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement("cardNumber"),
      });

      if (paymentMethod.paymentMethod) {
        setupCardRes = await setupCard(paymentMethod.paymentMethod.id, booking_id, customer_id);
        if (setupCardRes) {
          dispatch(dequeue());
          return true;
        } else {
          dispatch(dequeue());
          return false;
        }
      } else {
        dispatch(dequeue());
        dispatch(showError(paymentMethod.error.message));
        return false;
      }
    } catch (e) {
      dispatch(dequeue());
    }

    dispatch(dequeue());
  };

  const secondaryTabs = [
    {
      id: "booking-copy",
      icon: "copy",
      onSelect: () => handleSecondaryTabToggle("copy"),
    },
    {
      id: "booking-reoccur",
      icon: "arrow-right",
      onSelect: () => handleSecondaryTabToggle("move"),
    },
    {
      id: "booking-delete",
      icon: "trash",
      onSelect: () => handleSecondaryTabToggle("cancel"),
    },
  ];

  /** Toggles specified modal of the secondary tab given.  If no tab is passed -- closes all secondary tabs */
  function handleSecondaryTabToggle(tab?: "copy" | "move" | "cancel") {
    switch (tab) {
      case "copy": {
        setEditBookingState({ ...editBookingState, copyBookingFutureDates: [], copyBookingIdList: [] });
        setDuplicateBookingPopoverIsVisible(!duplicateBookingPopoverIsVisible);
        moveBookingPopoverIsVisible && setMoveBookingPopoverIsVisible(false);
        cancelBookingPopoverIsVisible && setCancelBookingPopoverIsVisible(false);
        break;
      }
      case "move": {
        setMoveBookingPopoverIsVisible(!moveBookingPopoverIsVisible);
        cancelBookingPopoverIsVisible && setCancelBookingPopoverIsVisible(false);
        duplicateBookingPopoverIsVisible && setDuplicateBookingPopoverIsVisible(false);
        break;
      }
      case "cancel": {
        setCancelBookingPopoverIsVisible(!cancelBookingPopoverIsVisible);
        moveBookingPopoverIsVisible && setMoveBookingPopoverIsVisible(false);
        duplicateBookingPopoverIsVisible && setDuplicateBookingPopoverIsVisible(false);
        break;
      }
      default: {
        setEditBookingState({ ...editBookingState, copyBookingFutureDates: [], copyBookingIdList: [] });
        setDuplicateBookingPopoverIsVisible(false);
        setMoveBookingPopoverIsVisible(false);
        setCancelBookingPopoverIsVisible(false);
      }
    }
  }

  const [selected, setSelected] = useState(uiStore.editBookingStartTab);

  useEffect(() => {
    setSelected(uiStore.editBookingStartTab);
  }, [uiStore.editBookingStartTab]);

  const handleTabChange = (selectedTabIndex: number) => {
    setSelected(selectedTabIndex);

    if (selectedTabIndex === 0) {
      updateState<IEditBookingState>(
        {
          elementComplete: {
            cardNumber: false,
            cardExpiry: false,
            cardCvc: false,
          },
        },
        StateType.EditBookingState,
      );
    }

    if (selectedTabIndex === 2) {
      void getBookingNotes();
    }
  };

  const handleHolesChange = (value: number) => {
    const holes = value;
    updateState<IEditBookingState>({ holes }, StateType.EditBookingState);
  };

  const handlePlayersChange = (value: number) => {
    const quantity = value;
    // If the new selected quanitity is less than the previous quantity, remove the extra players
    if (quantity < editBookingState.quantity) {
      void handleRemoverGolfers(quantity);
    }
    updateState<IEditBookingState>({ quantity }, StateType.EditBookingState);
  };

  const handleRemoverGolfers = (newQuantity: number) => {
    const updatedCustomerSelection = editBookingState.customerSelection.filter(
      (customer, index) => index + 1 <= newQuantity,
    );

    const updatedTeeTimeSlots = editBookingState.teeTimeSlots.filter((slot, index) => index + 1 <= newQuantity);

    updateState<IEditBookingState>(
      { customerSelection: updatedCustomerSelection, teeTimeSlots: updatedTeeTimeSlots },
      StateType.EditBookingState,
    );
    updateState<IMainState>(
      { golferSearchQuery1: "", golferSearchQuery2: "", golferSearchQuery3: "", golferSearchQuery4: "" },
      StateType.MainState,
    );
  };

  const handleCartChange = (value: number) => {
    const powerCartQuantity = value;
    updateState<IEditBookingState>({ powerCartQuantity }, StateType.EditBookingState);
  };

  const handleResendConfirmation = async (tee_time_booking_id: number) => {
    const putBookingConfirmationResponse = await PutBookingConfirmation(
      { booking_group_id: selectedBooking.booking_group_id },
      true,
    );

    if (putBookingConfirmationResponse.status === StatusCode.OK) {
      dispatch(showSuccess("Booking Confirmation Sent"));
    } else {
      dispatch(showError(t("elements.tee_sheet.booking_modal_edit.007")));
    }
  };

  const toggleNewCustomerSheet = (slot: ISlot) => {
    updateState<INewPlayerState>(
      {
        selectedSlot: slot,
        newCustomerSheetActive: !newPlayerState.newCustomerSheetActive,
      },
      StateType.NewPlayerState,
    );

    openNewCustomerSheet();
  };

  useEffect(() => {
    if (!editBookingState.customerSelection[0]?.id) {
      updateState<IEditBookingState>(
        { selectedPaymentMethod: undefined, paymentMethods: undefined },
        StateType.EditBookingState,
      );
      return;
    }

    //Load paymentMethods if the first player changes
    if (editBookingState.originalCustomerSelection[0] !== editBookingState.customerSelection[0]) {
      void loadCustomersPaymentMethods(editBookingState.customerSelection[0].id);
    }
  }, [editBookingState.customerSelection[0]]);

  const elementsComplete =
    editBookingState.elementComplete.cardNumber === true &&
    editBookingState.elementComplete.cardExpiry === true &&
    editBookingState.elementComplete.cardCvc === true;

  const cc_required = clientTeeSheetStore.selectedTeeTime?.credit_card_required;
  const cc_unselected = editBookingState.selectedPaymentMethod === undefined && !elementsComplete;
  const cc_override = permissions?.tee_sheet_credit_card_override;

  const handleCardSectionChange = (
    e: StripeCardNumberElementChangeEvent | StripeCardExpiryElementChangeEvent | StripeCardCvcElementChangeEvent,
  ) => {
    if (editBookingState.selectedPaymentMethod !== undefined) {
      updateState<IEditBookingState>({ selectedPaymentMethod: undefined }, StateType.EditBookingState);
    }

    updateState<IEditBookingState>(
      {
        elementComplete: { ...editBookingState.elementComplete, [e.elementType]: e.complete },
      },
      StateType.EditBookingState,
    );
  };

  function openNewCustomerSheet() {
    setNewPlayerState(prevState => ({
      ...prevState,
      newCustomerSheetActive: true,
    }));
  }

  useEffect(() => {
    const selectedTeeTimeDateTime = new Date(
      moment(`${clientTeeSheetStore.selectedTeeTime?.date}T${clientTeeSheetStore.selectedTeeTime?.start_time}`).format(
        "LLL",
      ),
    );

    const hourBeforeSelectedTeeTimeDateTime = new Date(
      moment(`${clientTeeSheetStore.selectedTeeTime?.date}T${clientTeeSheetStore.selectedTeeTime?.start_time}`).format(
        "LLL",
      ),
    );

    hourBeforeSelectedTeeTimeDateTime.setHours(hourBeforeSelectedTeeTimeDateTime.getHours() - 1);

    const currentTime = moment();

    const bookingWithinHour = currentTime.isBetween(hourBeforeSelectedTeeTimeDateTime, selectedTeeTimeDateTime);

    updateState<IEditBookingState>({ bookingWithinHour: bookingWithinHour }, StateType.EditBookingState);
  }, [uiStore.editBookingActive]);

  async function addBookingNote() {
    const noteInfo = {
      tee_time_booking_id: selectedBooking.id,
      content: newBookingNoteModal.content,
    };

    const res = await PostBookingNote(noteInfo, true);
    if (res.status !== StatusCode.OK) {
      return;
    }

    updateState<IEditBookingState>(
      { notesTitleVisible: "block", notes: [res.data, ...editBookingState.notes] },
      StateType.EditBookingState,
    );

    closeNewBookingNoteModal();
  }

  async function removeBookingNote(id: number) {
    const res = await DeleteBookingNote({ booking_note_id: id }, true);

    if (res.status !== StatusCode.OK) {
      return;
    }
    console.log(res);

    // Generate AUDIT LOG ?

    // REFRESH NOTES
    updateState<IEditBookingState>(
      { notesTitleVisible: "block", notes: editBookingState.notes.filter((note: any) => note.id !== id) },
      StateType.EditBookingState,
    );
  }

  async function editBookingNote() {
    const noteInfo = {
      booking_note_id: editNoteModal.id,
      content: editNoteModal.content,
    };

    const res = await PutBookingNote(noteInfo, true);
    console.log(res);

    if (res.status !== StatusCode.OK) {
      return;
    }

    const { booking, ...wantedParams } = res.data;

    updateState<IEditBookingState>(
      {
        notesTitleVisible: "block",
        notes: editBookingState.notes.map((note: any, index: number) => {
          if (note.id === wantedParams.id) {
            return wantedParams as Record<string, any>;
          } else {
            return note as Record<string, any>;
          }
        }),
      },
      StateType.EditBookingState,
    );

    closeEditNoteModal();
  }

  return (
    <div>
      <NewCustomer
        newCustomerSheetActive={newPlayerState.newCustomerSheetActive}
        onCancel={closeNewCustomer}
        onOk={addCustomer}
        searchValue={newPlayerState.currentSearch}
      />

      <Portal isMounted={uiStore.editBookingActive}>
        <Sheet
          open={uiStore.editBookingActive}
          size="medium"
          closable={!editBookingState.bookingPosting}
          title={t("elements.tee_sheet.booking_modal_edit.008")}
          height="flexible"
          onCancel={closeBooking}
          onOk={
            !cc_override && cc_required && cc_unselected && !editBookingState.bookingWithinHour
              ? () => handleTabChange(1)
              : handleUpdateBooking
          }
          cancelText={t("elements.tee_sheet.booking_modal_edit.009")}
          okText={
            !cc_override && cc_required && cc_unselected && !editBookingState.bookingWithinHour
              ? t("elements.tee_sheet.booking_modal_edit.010")
              : t("elements.tee_sheet.booking_modal_edit.011")
          }
          okDisabled={
            editBookingState.customerSelection.length === 0 ||
            editBookingState.holes === null ||
            editBookingState.quantity === null ||
            editBookingState.powerCartQuantity === null ||
            editBookingState.bookingPosting
              ? true
              : false
          }
          cancelDisabled={editBookingState.bookingPosting}
          backDropCancel={false}
        >
          {props.loading || editBookingState.bookingPosting ? (
            <div className="text-center">
              <div style={{ height: "32px", overflow: "hidden" }}>
                <Spin />
              </div>
              <div className="mt-2">{props.loading ? props.loadingText : "Saving Booking"}</div>
            </div>
          ) : (
            <>
              <span className="text-primary-grey text-12">
                {moment(
                  `${clientTeeSheetStore.selectedTeeTime?.date}T${clientTeeSheetStore.selectedTeeTime?.start_time}`,
                ).format("LLL")}
              </span>
              <Tabs tabs={tabs} secondaryTabs={secondaryTabs} selected={selected} onSelect={handleTabChange}>
                {moveBookingPopoverIsVisible && (
                  <MoveBookingTab
                    isActive={true}
                    bookingStateQuantity={editBookingState.quantity}
                    bookingStateHoles={editBookingState.holes}
                    course_id={clientTeeSheetStore.selectedTeeTime?.course_id}
                    confirmMoveBooking={confirmMoveBooking}
                    userLevel="client"
                    facility_id={clientFacilityStore.facility?.id}
                  />
                )}

                {duplicateBookingPopoverIsVisible && (
                  <DuplicateBookingTab
                    isActive={true}
                    copyBookingFutureDates={editBookingState.copyBookingFutureDates}
                    bookingStateHoles={editBookingState.holes}
                    bookingStateQuantity={editBookingState.quantity}
                    addCopyBookingIdToList={addCopyBookingIdToList}
                    confirmCopyBooking={confirmCopyBooking}
                    userLevel="client"
                  />
                )}

                <div className="bm-cancel-booking-popover-container">
                  <Popover active={cancelBookingPopoverIsVisible}>
                    <div className="bm-cancel-booking-container">
                      <div className="bm-cancel-warning-container">
                        <div className="bm-cancel-warning-outer-circle">
                          <div className="bm-cancel-warning-inner-circle">
                            <FontAwesomeIcon icon={["fas", "trash-can"]} className="bm-cancel-warning-icon" />
                          </div>
                        </div>
                      </div>

                      <p className="bm-cancel-booking-title">{t("elements.tee_sheet.booking_modal_edit.042")}</p>
                      <p className="bm-cancel-booking-text">{t("elements.tee_sheet.booking_modal_edit.041")}</p>
                      <hr />
                      <button onClick={confirmCancelBooking} className="bm-cancel-booking-button">
                        {t("elements.tee_sheet.booking_modal_edit.021")}
                      </button>
                    </div>
                  </Popover>
                </div>

                {selected === 0 ? (
                  <>
                    <div className="booking-modal">
                      <div className="booking-details">
                        <div className="booking-options">
                          <div className="booking-options_option">
                            <h3>{t("elements.tee_sheet.booking_modal_edit.022")}</h3>
                            <Radio.Group name="holes_edit" onChange={handleHolesChange} value={editBookingState.holes}>
                              {editBookingState.holeButtons?.map((disabled, i) => {
                                const holes = i === 0 ? 18 : 9;
                                return (
                                  <Radio.Button key={`holes_edit_${holes}`} value={holes} disabled={disabled}>
                                    {holes}
                                  </Radio.Button>
                                );
                              })}
                            </Radio.Group>
                          </div>

                          <div className="booking-options_option">
                            <h3>{t("elements.tee_sheet.booking_modal_edit.023")}</h3>

                            <Radio.Group
                              name="players_edit"
                              onChange={handlePlayersChange}
                              value={editBookingState.quantity}
                            >
                              {editBookingState.playerButtons?.map((disabled, i) => {
                                const players = i + 1;
                                return (
                                  <Radio.Button key={`players_edit_${players}`} value={players} disabled={disabled}>
                                    {players}
                                  </Radio.Button>
                                );
                              })}
                            </Radio.Group>
                          </div>
                          <div className="booking-options_option">
                            <h3>{t("elements.tee_sheet.booking_modal_edit.024")}</h3>

                            <Radio.Group
                              name="power_carts_edit"
                              onChange={handleCartChange}
                              value={editBookingState.powerCartQuantity}
                            >
                              <Radio.Button
                                value={0}
                                disabled={clientTeeSheetStore.selectedTeeTime?.cart_rule == "required"}
                              >
                                0
                              </Radio.Button>
                              <Radio.Button
                                value={1}
                                disabled={clientTeeSheetStore.selectedTeeTime?.cart_rule == "none"}
                              >
                                1
                              </Radio.Button>
                              <Radio.Button
                                value={2}
                                disabled={clientTeeSheetStore.selectedTeeTime?.cart_rule == "none"}
                              >
                                2
                              </Radio.Button>
                            </Radio.Group>
                          </div>
                        </div>
                        <div className="booking-customers-list">
                          {clientTeeSheetStore.selectedTeeTime?.slots
                            ?.filter(
                              filteredSlot =>
                                filteredSlot.status === "available" ||
                                filteredSlot?.booking?.id === selectedBooking?.id,
                            )
                            .map((slot: ISlot, index: number) => {
                              const golferPosition = slot.position.toString();
                              const queryKey = "golferSearchQuery".concat(golferPosition);
                              const searchResultKey = "golferSearchResult".concat(golferPosition);
                              //Get the matching slot that was added to the selection
                              const slotFound = editBookingState.teeTimeSlots.find(
                                (element: any) => element.id === slot.id,
                              );
                              if (slotFound) {
                                //Get the golfer info from current slot
                                const golfer = editBookingState.customerSelection.find(
                                  golfer => golfer?.id === slotFound.customer_id,
                                );
                                if (golfer != null) {
                                  return (
                                    <GolferCard
                                      closable
                                      removeGolfer={(e: any) => removeGolfer(slotFound, e)}
                                      email={golfer?.email}
                                      name={golfer?.full_name}
                                      memberCode={golfer?.member_code}
                                      customerType={golfer?.customer_type}
                                      phone={golfer?.phone}
                                      key={index}
                                    />
                                  );
                                }
                              } else if (
                                slot.booking?.id === selectedBooking?.id ||
                                (slot.status === "available" && !teeTimeLock)
                              ) {
                                //Display booking if slot is available or if slot is apart of this booking
                                return (
                                  <div key={index} className="booking-player-dropdown">
                                    <Select
                                      key={index}
                                      label={t("elements.tee_sheet.booking_modal_edit.025")}
                                      placeholder="Golfer"
                                      showSearch
                                      className={`flex justify-center align-center w-full h-10 position-relative z-20 text-black font-medium appearance-none border-none focus:outline-none placeholder-gray-200`}
                                      onSearch={(query: string) => handleCustomerSearch(query, queryKey)}
                                      onChange={(id: string | number, slot: any) =>
                                        handleCustomerSelection(id, slot, null)
                                      }
                                      allowClear
                                      searchValue={state[queryKey]}
                                      showDropDownOnFocus={true}
                                      searching={state.searching}
                                      disabled={
                                        editBookingState?.customerSelection?.length >= editBookingState?.quantity ||
                                        index + 1 > editBookingState.quantity
                                          ? true
                                          : false
                                      }
                                      usePortal
                                      autoFocus={uiStore.editBookingActive}
                                    >
                                      <div
                                        className="ui-select-dropdown-list-item"
                                        onClick={() => toggleNewCustomerSheet(slot)}
                                      >
                                        <p className="text-md">{t("elements.tee_sheet.booking_modal_edit.026")}</p>
                                      </div>

                                      {state[searchResultKey].map((golfer: any, i: number) => {
                                        if (golfer !== null && golfer !== undefined) {
                                          return (
                                            <Option key={i} value={golfer.id} extraValues={slot}>
                                              <div className="flex justify-between">
                                                <div>
                                                  <div className="font-semibold text-lg">{golfer?.full_name}</div>
                                                  <div className="text-sm text-gray-500">{golfer.customer_type}</div>
                                                  <div className="text-sm text-gray-500">{golfer.email}</div>
                                                  <div className="text-sm text-gray-500">
                                                    {golfer.phone ? golfer.phone : null}
                                                  </div>
                                                </div>

                                                <div className="font-medium text-base text-gray-500 self-end">
                                                  {golfer.member_code}
                                                </div>
                                              </div>
                                            </Option>
                                          );
                                        }
                                      })}
                                    </Select>
                                  </div>
                                );
                              }
                            })}
                        </div>
                      </div>
                      <div className="flex justify-between">
                        <Button type="link" onClick={() => handleResendConfirmation(selectedBooking.id)}>
                          {t("elements.tee_sheet.booking_modal_edit.027")}
                        </Button>
                      </div>
                    </div>
                  </>
                ) : null}
                {selected === 1 ? (
                  <div className="booking-credit-card-tab">
                    <CreditCardCatalogue
                      customerId={editBookingState.teeTimeSlots[0]?.customer_id}
                      creditCards={editBookingState.paymentMethods}
                      selectedCard={editBookingState.selectedPaymentMethod}
                      setSelectedCard={(paymentId: number) => onSelectCreditCard(paymentId)}
                    />
                    <CreditCardAdd
                      isSavingCard={editBookingState.saveCard}
                      setIsSavingCard={() =>
                        updateState<IEditBookingState>(
                          { saveCard: !editBookingState.saveCard },
                          StateType.EditBookingState,
                        )
                      }
                      handleStripeInputs={handleCardSectionChange}
                    />
                  </div>
                ) : null}
                {selected === 2 ? (
                  <div style={{ overflowY: "scroll", maxHeight: "32rem" }}>
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <ButtonNew
                        style={{ display: "flex", gap: "8px" }}
                        type="text"
                        onClick={() => updateNewBookingNote({ isOpen: true })}
                      >
                        <FontAwesomeIcon icon={["fas", "plus"]} />
                        <p>Add Note</p>
                      </ButtonNew>
                    </div>

                    {/* <div>
                  <p className="booking-modal-add-note">{t("elements.tee_sheet.booking_modal_edit.028")}</p>
                  <TextField
                    id="booking-modal-notes-input"
                    rows={2}
                    value={editBookingState.noteDescription}
                    onChange={handleNoteInput}
                  ></TextField>

                  <div style={{ textAlign: "right", marginTop: "5px" }}>
                    <button
                      onClick={() =>
                        updateState<IEditBookingState>({ noteDescription: "" }, StateType.EditBookingState)
                      }
                      className="booking-modal-clear-note-button"
                    >
                      {t("elements.tee_sheet.booking_modal_edit.029")}
                    </button>
                    <button
                      className="booking-modal-add-note-button"
                      id="add-note-button"
                      style={{ display: editBookingState.addNoteVisible }}
                      onClick={handleAddNote}
                      disabled={editBookingState.noteDescription === "" ? true : false}
                    >
                      {t("elements.tee_sheet.booking_modal_edit.030")} <FontAwesomeIcon icon="edit" />
                    </button>
                  </div>
                </div>
                <br /> */}
                    <div className="overflow-y-scroll h-64 flex flex-col gap-2">
                      <p
                        className="booking-modal-add-note"
                        style={{ display: editBookingState.notes?.length > 0 ? "block" : "none" }}
                      >
                        {t("elements.tee_sheet.booking_modal_edit.031")}
                      </p>

                      {editBookingState.notes?.map((props: any, index: number) => {
                        return (
                          <Note
                            key={index}
                            authorName={props.author?.full_name ?? authStore.user.full_name}
                            content={props.content}
                            dateString={moment(props.created_at).format("LLL")}
                            onEdit={
                              authStore.user.id === props.admin_user_id ||
                              authStore.user.permissions?.bookings_edit_note
                                ? () => updateEditNoteModal({ isOpen: true, content: props.content, id: props.id })
                                : null
                            }
                            onRemove={e => removeBookingNote(props.id)}
                          />
                        );
                      })}
                    </div>
                  </div>
                ) : null}
                {selected === 3 ? (
                  <>
                    {editBookingState.auditTrails.map((audit: any, index: number) => {
                      return (
                        <div key={index} className="mt-4">
                          <AuditTrailNote
                            title={audit.title}
                            author={audit.author}
                            actions={audit.actions}
                            action={audit.action}
                            timestamp={audit.created_at}
                            last={index + 1 === editBookingState.auditTrails.length ? true : false}
                          />
                        </div>
                      );
                    })}
                  </>
                ) : null}
              </Tabs>
            </>
          )}
        </Sheet>
      </Portal>

      <Portal isMounted={newBookingNoteModal.isOpen}>
        <Sheet
          title="New Note"
          open={newBookingNoteModal.isOpen}
          size="small"
          onCancel={() => closeNewBookingNoteModal()}
          onOk={() => addBookingNote()}
          backDropCancel={true}
          stacked
          okDisabled={newBookingNoteModal.content.length === 0}
        >
          <TextField
            label="Note"
            value={newBookingNoteModal.content}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => updateNewBookingNote({ content: e.target.value })}
          />
        </Sheet>
      </Portal>

      <Portal isMounted={editNoteModal.isOpen}>
        <Sheet
          title="Edit Note"
          open={editNoteModal.isOpen}
          size="small"
          onCancel={() => closeEditNoteModal()}
          onOk={() => editBookingNote()}
          backDropCancel={true}
          stacked
          okDisabled={editNoteModal.content.length === 0}
        >
          <TextField
            label="Note"
            value={editNoteModal.content}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => updateEditNoteModal({ content: e.target.value })}
          />
        </Sheet>
      </Portal>
    </div>
  );
};

export default BookingModalEdit;
