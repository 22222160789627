import { APIGet } from "api/protocols";
import { IFacility } from "pages/guest/models/facility";

const apiVersion = "2024-04";
const adminType = "guest";
const baseFacilityUrl = "/" + apiVersion + "/" + adminType + "/facility";

interface IGetFacility {
  id?: number;
  short_name?: string;
  client_id?: number;
}

interface IGetFacilityRes {
  data: IFacility[];
  message: string;
  status: number;
}

export async function GetFacility(params: IGetFacility, useGlobalLoader: boolean): Promise<IGetFacilityRes> {
  return await APIGet(baseFacilityUrl, params, useGlobalLoader);
}
