import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { TLeagueDivision } from "redux/reducers/models/league";

const apiVersion = "2024-04";
const adminType = "guest";
const baseUrl = "/" + apiVersion + "/" + adminType + "/league/scoring";

type TGetLeagueDivision = {
  facility_id: string | number;
  id?: string | number;
  league_handle?: string;
};

export async function GetLeagueDivision(params: TGetLeagueDivision, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<TLeagueDivision[]>(baseUrl + "/division", params, useGlobalLoader, token);
}
