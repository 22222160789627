import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { BookingPlayerType } from "api/rpc/2024-04/customer/facility";
import { useParams } from "react-router";

type SingleBookingSetting = {
  disable: boolean;
  golferAmount: number;
};

interface IParams {
  clientShortName: string;
}

interface IClientPortalURLParams {
  date?: string;
  waitlist_reference_id?: string;
}

export function useClientPortal() {
  const { clientShortName } = useParams<IParams>();
  const clientPortalStore = useAppSelector(store => store.clientPortalStore);
  /**
   * Determines if single bookings are disabled for the selected tee time
   *
   *
   * @param singleBookings single_bookings value from the facility_tee_sheet_settings table ("allow", "allow_within_group", "none")
   * @param quantityRemaining The remaining number of slots on the tee time
   * @returns {BookingPlayerType} An object with a disable boolean value and a default golfer amount
   */
  function getSingleBookings(singleBookings: BookingPlayerType, quantityRemaining: number): SingleBookingSetting {
    let singleBookingSettings: SingleBookingSetting = { disable: false, golferAmount: 1 };
    if ((singleBookings === "allow_within_group" && quantityRemaining > 3) || singleBookings === "none") {
      singleBookingSettings = { disable: true, golferAmount: 2 };
    }
    return singleBookingSettings;
  }

  /**
   *
   * @returns {string} URL that directs the user to the Client Portal home page
   */
  function getHomePageUrl(): string {
    const facilityShortName = clientPortalStore?.selectedFacility
      ? `/${clientPortalStore?.selectedFacility?.short_name}`
      : "";
    return `/portal/${clientShortName}/teetimes`.concat(facilityShortName);
  }

  /**
   *
   * @example {date: "2024-04-01", waitlist_reference_id: 1}
   * @returns Object containing the URL params
   */
  function getURLParams(): IClientPortalURLParams {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const date = urlParams.get("date");
    const waitlist_reference_id = urlParams.get("waitlist_reference_id");
    return { date: date, waitlist_reference_id: waitlist_reference_id };
  }

  return {
    getSingleBookings,
    getHomePageUrl,
    getURLParams,
  };
}
