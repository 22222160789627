import { AdminType, APIDelete, APIGet, APIPost, APIPut, APIUrl } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/facility/email-template";

interface IGetEmailTemplate {
  id?: number;
  type?: "tee_sheet" | "league_announcement" | null;
}

interface IPostEmailTemplate {
  title: string;
  type: "tee_sheet" | "league_announcement" | null;
  subject: string;
  content: string;
}

interface IPutEmailTemplate {
  id: number;
  title: string;
  type: "tee_sheet" | "league_announcement" | null;
  subject: string;
  content: string;
}

interface IDeleteEmailTemplate {
  id?: number;
}

export async function GetEmailTemplate(params: IGetEmailTemplate, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(APIUrl(AdminType.FacilityAdmin, "/facility/email-template"), params, useGlobalLoader, token);
}

export async function PostEmailTemplate(params: IPostEmailTemplate, useGlobalLoader: boolean) {
  return await APIPost(APIUrl(AdminType.FacilityAdmin, "/facility/email-template"), params, useGlobalLoader);
}

export async function PutEmailTemplate(params: IPutEmailTemplate, useGlobalLoader: boolean) {
  return await APIPut(APIUrl(AdminType.FacilityAdmin, "/facility/email-template"), params, useGlobalLoader);
}

export async function DeleteEmailTemplate(params: IDeleteEmailTemplate, useGlobalLoader: boolean) {
  return await APIDelete(APIUrl(AdminType.FacilityAdmin, "/facility/email-template"), params, useGlobalLoader);
}
