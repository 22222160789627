import { APIGet, APIPost, APIPut, APIDelete, StatusCode } from "api/protocols";
import { ICustomer } from "redux/reducers/models/customer";
import { ILeagueParticipant } from "redux/reducers/models/league";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseLeagueUrl = "/" + apiVersion + "/" + adminType + "/league";

interface ILeagueParticipantRes {
  status: StatusCode;
  data: Array<ILeagueParticipant>;
}

//GET Players
//Retrieve a list of players in a specific league
export async function GetParticipant(params: any, useGlobalLoader: boolean): Promise<ILeagueParticipantRes> {
  return await APIGet(baseLeagueUrl + "/participant", params, useGlobalLoader);
}

//POST New Player
//Add new player to leage
export async function PostParticipant(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseLeagueUrl + "/participant", params, useGlobalLoader);
}

// Update Player
//Update player within a league
export async function PutParticipant(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseLeagueUrl + "/participant", params, useGlobalLoader);
}

type DeleteLeagueParticipant = {
  participant_id: number;
};
// Delete Player
//Delete player within a league
export async function DeleteLeagueParticipant(params: DeleteLeagueParticipant, useGlobalLoader: boolean) {
  return await APIDelete(baseLeagueUrl + "/participant", params, useGlobalLoader);
}

export async function PutExportParticipants(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseLeagueUrl + "/participant/export", params, useGlobalLoader);
}
