import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import {
  GetTeeOnPaymentAccounts,
  PutActivateAccount,
} from "api/rpc/2024-04/facilityAdmin/payment/teeonPayments/accounts";

import { showError, showSuccess } from "redux/actions/ui";
import { TTeeOnPaymentAccount } from "redux/reducers/models/transaction";
import { useAppDispatch } from "hooks/redux";

import Card from "components/card/Card";
import InnerLayout from "components/innerLayout/InnerLayout";
import Page from "components/page/Page";
import Spin from "components/spin/spin";

export default function PaymentManage() {
  const history = useHistory();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [account, setAccount] = useState<TTeeOnPaymentAccount>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadTeeOnPaymentsAccounts(source.token);
    return () => source.cancel();
  }, []);

  async function loadTeeOnPaymentsAccounts(token?: CancelToken) {
    if (account !== undefined) {
      setAccount(undefined);
    }
    const res = await GetTeeOnPaymentAccounts(null, token ? false : true, token);
    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.payments.manage.009")));
    }

    setAccount(res.data);
  }

  async function activateTeeOnPayments() {
    const res = await PutActivateAccount(true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.payments.manage.010")));
      return;
    }

    setAccount(prev => ({ ...prev, activated: true }));
    dispatch(showSuccess(t("secure.facility.settings.payments.manage.011")));
  }

  return (
    <Page
      title={t("secure.facility.settings.payments.manage.001")}
      subtitle={t("secure.facility.settings.payments.manage.002")}
    >
      <InnerLayout>
        <InnerLayout.Section>
          <div>
            <p className="text-xl text-semibold">{t("secure.facility.settings.payments.manage.003")}</p>
            <p className="text-md text-regular text-gray-500">{t("secure.facility.settings.payments.manage.004")}</p>
          </div>

          {account !== undefined ? (
            <Card
              title={t("secure.facility.settings.payments.manage.005")}
              titleActions={
                !account?.activated
                  ? [
                      {
                        content: t("secure.facility.settings.payments.manage.012"),
                        action: () => activateTeeOnPayments(),
                        disabled: account?.activated,
                      },
                    ]
                  : undefined
              }
              style={{ marginTop: "12px" }}
            >
              <Card.Section
                title={
                  account?.activated
                    ? t("secure.facility.settings.payments.manage.013")
                    : t("secure.facility.settings.payments.manage.014")
                }
                sectionTitleActions={[
                  {
                    content: t("secure.facility.settings.payments.manage.015"),
                    action: () => history.push(`/admin/settings/payments/manage/tee-on-payments`),
                    disabled: !account?.activated,
                  },
                ]}
              />
            </Card>
          ) : (
            <Card style={{ marginTop: "12px", height: "75px", padding: "24px", overflow: "hidden" }}>
              <Spin />
            </Card>
          )}
        </InnerLayout.Section>
      </InnerLayout>
    </Page>
  );
}
