import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";
import moment from "moment";

import { StatusCode } from "api/protocols";

import { showError, showSuccess } from "redux/actions/ui";
import { loadPaymentOptions } from "redux/actions/facility";

import { LocaleCurrency } from "helpers/Locale";
import { capitalize, displayCurrency, formatDate, valueToString } from "helpers/Helpers";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import { Select } from "components/select/";
import { ButtonNew as Button } from "components/buttonNew";
import Spin from "components/spin/spin";
import Card from "components/card/Card";
import { Badge } from "components/badge/Badge";
import Popup from "components/popup/Popup";
import { useCustomerContext } from "../../context/CustomerContext";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { TABLET_WIDTH } from "helpers/ScreenSizes";
import Icon from "components/icon/Icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tabs from "components/tabs/Tabs";
import ButtonGroup from "components/button/ButtonGroup";
import { IFacilityState } from "redux/reducers/facility";
import "./CustomerCredit.scss";
import {
  GetCustomerCredit,
  PostCustomerCreditBook,
  GetCreditBookTransactions,
  ICreditTransactionRes,
} from "api/rpc/2022-09/facilityAdmin/customer/customerCredit";
import NewCustomerCreditModal from "./NewCustomerCreditModal";
import DataTable from "../houseAccounts/DataTable";
import ReactDOM from "react-dom";

interface IProps {
  customerId: string;
}

export default function CustomerCredit(props: IProps) {
  const { t } = useTranslation();
  const { Option } = Select;
  const dispatch = useAppDispatch();
  const windowSize = useWindowSize();

  const [creditState, setCreditState] = useState([null]);
  const [creditTransactionState, setCreditTransactionState] = useState<ICreditTransactionRes[]>([null]);
  const [showNewCreditBookingModal, setShowNewCreditBookingModal] = useState(false);
  const customer_id = Number(props.customerId);

  useEffect(() => {
    void loadCustomerCredit();
  }, []);

  async function loadCustomerCredit() {
    const customerCreditRes = await GetCustomerCredit({ customer_id: customer_id }, true);
    if (customerCreditRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.customer.tabs.credit.customer_credit.001")));
      return;
    }

    const creditTransactionData = await loadCreditTransactions(customerCreditRes.data[0]?.id);
    ReactDOM.unstable_batchedUpdates(() => {
      setCreditTransactionState(creditTransactionData);
      setCreditState(customerCreditRes.data);
    });
  }

  async function loadCreditTransactions(credit_book_id: number) {
    const creditTransactionRes = await GetCreditBookTransactions({ credit_book_id: credit_book_id }, true);
    if (creditTransactionRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.customer.tabs.credit.customer_credit.002")));
      return;
    }

    return creditTransactionRes.data;
  }

  async function createNewBookingCredit(title: string) {
    if (title == "") {
      dispatch(showError(t("secure.facility.customer.tabs.credit.customer_credit.003")));
      return;
    }

    const creditRes = await PostCustomerCreditBook({ title: title, customer_id: customer_id }, true);
    if (creditRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.customer.tabs.credit.customer_credit.004")));
      return;
    }
    setShowNewCreditBookingModal(false);
    void loadCustomerCredit();
  }

  return (
    <div>
      {creditState[0] !== null ? (
        <>
          <div className="house-accounts-header">
            <div className="ui-customer_page-header house-accounts-title">
              {creditState.length === 0 ? "Credit Book" : creditState[0].title}
            </div>
            <div className="house-accounts-actions">
              {creditState[0] !== null && creditState.length === 0 ? (
                <div>
                  <Button
                    type="secondary"
                    size="medium"
                    disabled={creditState?.length === Number(1) ? true : false}
                    className="house-accounts-create-action"
                    onClick={() => setShowNewCreditBookingModal(true)}
                  >
                    {t("secure.facility.customer.tabs.credit.customer_credit.005")}
                  </Button>
                </div>
              ) : null}
            </div>
          </div>

          <div className="house-accounts-divider"></div>

          <div>
            {creditState[0] !== null && creditState.length === 1 ? (
              <>
                <div className="house-accounts_balance">
                  <div className="currency-text">
                    <LocaleCurrency amount={creditState[0]?.balance} />
                  </div>

                  <div>
                    <p className="left-text">{t("secure.facility.customer.tabs.credit.customer_credit.006")}</p>
                  </div>
                </div>

                <div className="house-accounts-divider"></div>

                <div className="ui-customer_page-header house-accounts-title mb-2 mt-1">
                  {t("secure.facility.customer.tabs.credit.customer_credit.007")}
                </div>

                <DataTable
                  columns={[
                    { label: t("secure.facility.customer.tabs.credit.customer_credit.008") },
                    { label: t("secure.facility.customer.tabs.credit.customer_credit.009") },
                    { label: t("secure.facility.customer.tabs.credit.customer_credit.010") },
                    { label: t("secure.facility.customer.tabs.credit.customer_credit.011") },
                  ]}
                >
                  {creditTransactionState?.map((transaction: ICreditTransactionRes) => {
                    return (
                      <tr key={transaction?.id}>
                        <td>
                          {transaction?.processed_at_local
                            ? moment(transaction?.processed_at_local).format("ll")
                            : null}
                        </td>
                        <td>
                          {String(transaction?.currency).toUpperCase()} {displayCurrency("cad", transaction?.amount)}
                        </td>
                        <td>{transaction?.order_number}</td>
                        <td>
                          {transaction?.kind === "debit_transfer" || transaction?.kind === "debit"
                            ? t("secure.facility.customer.tabs.credit.customer_credit.012")
                            : t("secure.facility.customer.tabs.credit.customer_credit.013")}
                        </td>
                      </tr>
                    );
                  })}
                </DataTable>
              </>
            ) : (
              <div className="empty-container"></div>
            )}
          </div>
        </>
      ) : (
        // Page Spinner
        <div style={{ marginTop: "4em", pointerEvents: "none", textAlign: "center" }}>
          <span style={{ display: "inline-flex", transform: "scale(2)" }}>
            <Spin />
          </span>
        </div>
      )}

      {/**
       *
       *  MODALS AND POPUPS
       *
       */}

      <NewCustomerCreditModal
        isVisible={showNewCreditBookingModal}
        customerId={customer_id}
        onClose={() => setShowNewCreditBookingModal(false)}
        onOk={createNewBookingCredit}
      />
    </div>
  );
}
