import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import Page from "components/page/Page";
import {
  GetFolder,
  PutFolder,
  DeleteFolder,
  PostFolderProducts,
  IPostFolderProduct,
  DuplicateFolder,
} from "api/rpc/facilityAdmin/folders";
import { StatusCode } from "api/protocols";
import Input from "components/form/input/Input";
import Card from "components/card/Card";
import Sheet from "components/sheet/Sheet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select } from "components/select/index";
import { ProductCards } from "components/productCards/ProductCards";
import { IProduct } from "redux/reducers/models/product";
import { isEqual } from "lodash";
import { NotificationType } from "components/notificationBar/NotificationBar";
import { IUIActions } from "redux/actions/ui";
import Popup from "components/popup/Popup";
import { GetProduct } from "api/rpc/facilityAdmin/product/products";

import { useTranslation } from "react-i18next";
import { GetRegisterGroups } from "api/rpc/2022-09/facilityAdmin/facility/register/group";

interface IFoldersEditProps {
  uiActions: IUIActions;
}

interface IFolderState {
  id: number;
  title: string;
  register_group_id: number;
}

interface IFilterState {
  search: string;
}

export interface IProductState {
  productSearchResults: IProduct[];
  productsToAddToFolder: IProduct[];
  folderProducts: IProduct[];
  availableProductPositions: number[];
}

interface IUnsavedChangesState {
  folderProductsBeforeChanges: IProduct[];
  folderProductsLoaded: boolean;
  folderStateBeforeChanges: IFolderState;
  folderStateLoaded: boolean;
}

interface IRegisterState {
  registers: Array<Record<string, any>>;
}

interface IModalVisibilityState {
  deleteModalVisibility: boolean;
  duplicateModalVisibility: boolean;
  addProductsVisibility: boolean;
  saveEmptyFolderVisibility: boolean;
}

export default function FoldersEdit(props: IFoldersEditProps) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { Option } = Select;
  const { id } = useParams<{ id: string }>();

  const initialFolderState: IFolderState = {
    id: undefined,
    title: "",
    register_group_id: undefined,
  };

  const initialProductState: IProductState = {
    productSearchResults: [],
    productsToAddToFolder: [],
    folderProducts: [],
    availableProductPositions: [],
  };

  const initialUnsavedChangesState: IUnsavedChangesState = {
    folderProductsBeforeChanges: undefined,
    folderProductsLoaded: false,
    folderStateBeforeChanges: undefined,
    folderStateLoaded: false,
  };

  const initialRegisterState: IRegisterState = {
    registers: [],
  };

  const initialModalVisibilityState: IModalVisibilityState = {
    deleteModalVisibility: false,
    duplicateModalVisibility: false,
    addProductsVisibility: false,
    saveEmptyFolderVisibility: false,
  };

  const initialFilterState: IFilterState = {
    search: "",
  };

  const [folderState, setFolderState] = useState<IFolderState>(initialFolderState);
  const [productState, setProductState] = useState<IProductState>(initialProductState);
  const [unsavedChangesState, setUnsavedChangesState] = useState<IUnsavedChangesState>(initialUnsavedChangesState);
  const [registerState, setRegisterState] = useState<IRegisterState>(initialRegisterState);
  const [modalVisibilityState, setModalVisibilityState] = useState<IModalVisibilityState>(initialModalVisibilityState);
  const [filterState, setFilterState] = useState<IFilterState>(initialFilterState);

  useEffect(() => {
    setFolderState(initialFolderState);
    setProductState(prev => ({ ...initialProductState, productSearchResults: prev.productSearchResults }));
    setUnsavedChangesState(initialUnsavedChangesState);
    setRegisterState(initialRegisterState);
    setModalVisibilityState(initialModalVisibilityState);
    setFilterState(initialFilterState);

    void loadFoldersProducts();
    void loadRegisters();
  }, [id]);

  // Search Products
  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (async () => {
          try {
            const productSearchResults = await loadProducts();
            if (mounted) {
              setProductState(prevState => ({ ...prevState, productSearchResults }));
            }
          } catch (error) {
            console.log("err", error);
          }
          return;
        })();
      }, 1000);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [filterState.search]);

  async function loadProducts() {
    const productRes = await GetProduct({ search: filterState.search }, true);
    if (productRes.status !== StatusCode.OK) {
      props.uiActions.showError(t("secure.facility.settings.folders.folders_edit.001"));
      return [];
    }

    return productRes.data ?? [];
  }

  async function loadFoldersProducts() {
    const resFolder = await GetFolder("id=" + String(id), true);

    if (resFolder.status !== StatusCode.OK) {
      props.uiActions.showError(t("secure.facility.settings.folders.folders_edit.002"));
      return;
    }

    setProductState(prev => ({
      ...prev,
      folderProducts: resFolder.data[0].products
        .filter((product: any) => {
          return product.folder_position !== undefined && product.folder_position !== null;
        })
        .sort((prev: any, next: any) => prev.folder_position - next.folder_position),
    }));

    setFolderState(prev => ({
      ...prev,
      id: resFolder.data[0].id,
      title: resFolder.data[0].title,
      register_group_id: resFolder.data[0]?.register_group_id,
    }));

    setUnsavedChangesState(prev => ({
      ...prev,
      folderProductsLoaded: true,
      folderStateLoaded: true,
    }));
  }

  async function saveFolder() {
    const params = {
      id: folderState.id,
      title: folderState.title,
      register_group_id: folderState.register_group_id,
    };

    const putFolderRes = await PutFolder(params, true);

    if (putFolderRes.status !== StatusCode.OK) {
      props.uiActions.showError(t("secure.facility.settings.folders.folders_edit.003"));
      return false;
    }

    setUnsavedChangesState(prev => ({
      ...prev,
      folderStateBeforeChanges: folderState,
    }));

    const products: IPostFolderProduct[] = [];
    for (let i = 0; i < productState.folderProducts.length; i++) {
      const product = productState.folderProducts[i];
      products.push({
        id: product.id,
        position: product.folder_position,
        button_color: product.meta?.button_color,
      });
    }

    const postFolderProductsRes = await PostFolderProducts({ folder_id: Number(id), products }, true);

    if (postFolderProductsRes.status !== StatusCode.OK) {
      props.uiActions.showError(t("secure.facility.settings.folders.folders_edit.004"));
      return false;
    }

    setUnsavedChangesState(prev => ({
      ...prev,
      folderProductsBeforeChanges: productState.folderProducts,
    }));

    return true;
  }

  function saveFolderHandler() {
    if (productState.folderProducts.length === 0 && unsavedChangesState.folderProductsBeforeChanges.length > 0) {
      openSaveEmptyFolderModal();
    } else {
      void saveFolder();
    }
  }

  function openSaveEmptyFolderModal() {
    setModalVisibilityState(prev => ({
      ...prev,
      saveEmptyFolderVisibility: true,
    }));
  }

  function closeSaveEmptyFolderModal() {
    setModalVisibilityState(prev => ({
      ...prev,
      saveEmptyFolderVisibility: false,
    }));
  }

  async function handleSaveEmptyFolderModal() {
    const saveSuccessful = await saveFolder();

    if (saveSuccessful) {
      closeSaveEmptyFolderModal();
    }
  }

  async function removeFolder() {
    const res = await DeleteFolder(folderState, true);

    if (res.status !== StatusCode.OK) {
      props.uiActions.showError(t("secure.facility.settings.folders.folders_edit.005"));
      return;
    }

    history.push("/admin/settings/folders");
  }

  async function duplicateFolder() {
    const res = await DuplicateFolder({ folder_id: parseInt(id) }, true);

    if (res.status !== StatusCode.OK) {
      props.uiActions.showError(t("secure.facility.settings.folders.folders_edit.006"));
      return;
    }

    history.push(`/admin/settings/folders/${res.data?.id}`);
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;
    setFolderState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleDeleteModalVisibility() {
    setModalVisibilityState(prev => ({
      ...prev,
      deleteModalVisibility: !prev.deleteModalVisibility,
    }));
  }

  function handleDuplicateModalVisibility() {
    setModalVisibilityState(prev => ({
      ...prev,
      duplicateModalVisibility: !prev.duplicateModalVisibility,
    }));
  }

  function handleOpenAddProductsModal(availableProductPositions: number[]) {
    setModalVisibilityState(prev => ({
      ...prev,
      addProductsVisibility: !prev.addProductsVisibility,
    }));
    setProductState(prev => ({ ...prev, availableProductPositions }));
    setFilterState({ search: "" });
  }

  function handleCloseAddProductsModal() {
    setModalVisibilityState(prev => ({
      ...prev,
      addProductsVisibility: !prev.addProductsVisibility,
    }));
    setProductState(prev => ({
      ...prev,
      productsToAddToFolder: [],
      productSearchResults: filterState.search === "" ? prev.productSearchResults : [],
      availableProductPositions: [],
    }));
  }

  function handleRemoveFolderProduct(id: number) {
    const updatedFolderProducts = productState.folderProducts.filter(product => product.id !== id);

    setProductState(prev => ({
      ...prev,
      folderProducts: updatedFolderProducts,
    }));
  }

  function handleSelectProductToAdd(product: IProduct) {
    if (productState.productsToAddToFolder.length >= productState.availableProductPositions.length) {
      return;
    }

    const updatedProductsToAddToFolder = [...productState.productsToAddToFolder, product];

    setProductState(prev => ({ ...prev, productsToAddToFolder: updatedProductsToAddToFolder }));
  }

  function handleUnselectProductToAdd(product: IProduct) {
    const updatedProductsToAddToFolder = productState.productsToAddToFolder.filter(
      productToAdd => productToAdd.id !== product.id,
    );

    setProductState(prev => ({ ...prev, productsToAddToFolder: updatedProductsToAddToFolder }));
  }

  function handleChangeFolderProductPosition(id: number, position: number) {
    const updatedFolderProducts = productState.folderProducts.map(product => {
      if (product.id === id) {
        return { ...product, folder_position: position };
      } else {
        return product;
      }
    });

    setProductState(prev => ({ ...prev, folderProducts: updatedFolderProducts }));
  }

  function addFolderProducts() {
    const updatedProductsToAddToFolder: IProduct[] = [];

    for (
      let i = 0;
      i < Math.min(productState.productsToAddToFolder.length, productState.availableProductPositions.length);
      i++
    ) {
      updatedProductsToAddToFolder.push({
        ...productState.productsToAddToFolder[i],
        folder_position: productState.availableProductPositions[i],
      });
    }

    setProductState(prev => ({ ...prev, folderProducts: [...prev.folderProducts, ...updatedProductsToAddToFolder] }));
    handleCloseAddProductsModal();
  }

  function setFolderProducts(products: IProduct[]) {
    setProductState(prev => ({ ...prev, folderProducts: products }));
  }

  const deleteAction = {
    content: t("secure.facility.settings.folders.folders_edit.007"),
    action: handleDeleteModalVisibility,
  };

  const duplicateAction = {
    content: t("secure.facility.settings.folders.folders_edit.008"),
    action: handleDuplicateModalVisibility,
  };

  async function loadRegisters() {
    const registersRes = await GetRegisterGroups(null, true);
    if (registersRes.status !== StatusCode.OK) {
      props.uiActions.showError(t("secure.facility.settings.folders.folders_edit.009"));
      return;
    }

    setRegisterState(prev => ({ ...prev, registers: registersRes.data }));
  }

  function handleRegisterSelect(register_group_id: number) {
    setFolderState(prevState => ({ ...prevState, register_group_id }));
  }

  function unsavedChangesExist() {
    if (
      unsavedChangesState.folderProductsBeforeChanges === undefined ||
      unsavedChangesState.folderStateBeforeChanges === undefined
    ) {
      if (unsavedChangesState.folderProductsLoaded && unsavedChangesState.folderStateLoaded) {
        setUnsavedChangesState(prev => ({
          ...prev,
          folderProductsBeforeChanges: productState.folderProducts,
          folderStateBeforeChanges: folderState,
        }));
      }
      return false;
    }

    // Only should compare product properties that are being saved
    // GetProduct and GetFolder return slightly different data
    const productToPost = (product: IProduct): IPostFolderProduct => ({
      id: product.id,
      position: product.folder_position,
      button_color: product.meta?.button_color,
    });

    const postFolderProducts: IPostFolderProduct[] = productState.folderProducts.map(productToPost);
    const postFolderProductsBeforeChanges: IPostFolderProduct[] =
      unsavedChangesState.folderProductsBeforeChanges.map(productToPost);

    return (
      !isEqual(postFolderProductsBeforeChanges, postFolderProducts) ||
      !isEqual(unsavedChangesState.folderStateBeforeChanges, folderState)
    );
  }

  function cancelUnsavedChanges() {
    setFolderState(unsavedChangesState.folderStateBeforeChanges);
    setProductState(prev => ({ ...prev, folderProducts: unsavedChangesState.folderProductsBeforeChanges }));
  }

  return (
    <div>
      {unsavedChangesState.folderProductsLoaded && unsavedChangesState.folderStateLoaded && (
        <>
          <Page
            title={
              t("secure.facility.settings.folders.folders_edit.010") +
              (unsavedChangesState.folderStateBeforeChanges?.title ?? "")
            }
            narrow
            // primaryAction={deleteAction}
            // secondaryActions={[duplicateAction]}
            multipleActionDropdownAction={{
              label: "Options",
              dropdownProps: {
                alignment: "right",
                options: [
                  {
                    type: "handler",
                    label: "Delete",
                    icon: "trash",
                    handler: handleDeleteModalVisibility,
                  },
                  {
                    type: "handler",
                    label: "Duplicate",
                    handler: handleDuplicateModalVisibility,
                    icon: "copy",
                  },
                ],
              },
            }}
            breadcrumbs={[
              {
                prefix: true,
                label: t("secure.facility.settings.folders.folders_edit.011"),
                url: "/admin/settings/folders",
              },
            ]}
            notificationBarProps={{
              isVisible:
                unsavedChangesExist() &&
                !modalVisibilityState.addProductsVisibility &&
                !modalVisibilityState.saveEmptyFolderVisibility,
              onAction: saveFolderHandler,
              onCancel: cancelUnsavedChanges,
            }}
          >
            <Card>
              <Card.Section>
                <Input
                  value={folderState.title}
                  label={t("secure.facility.settings.folders.folders_edit.013")}
                  id="title"
                  onChange={handleInputChange}
                  placeholder={t("secure.facility.settings.folders.folders_edit.014")}
                  className="mb-2"
                />
                <Select
                  label={t("secure.facility.settings.folders.folders_edit.015")}
                  onChange={handleRegisterSelect}
                  defaultValue={folderState.register_group_id}
                >
                  {registerState.registers?.map((register, index) => {
                    return (
                      <Option value={register?.id} name={register?.title} key={index}>
                        <span>{register?.title}</span>
                      </Option>
                    );
                  })}
                </Select>
              </Card.Section>
              <Card.Section>
                <ProductCards
                  products={productState.folderProducts}
                  productsLoaded={unsavedChangesState.folderProductsLoaded}
                  onChangeProductPosition={handleChangeFolderProductPosition}
                  onRemoveProduct={handleRemoveFolderProduct}
                  onAddProducts={handleOpenAddProductsModal}
                  setProducts={setFolderProducts}
                />
              </Card.Section>
            </Card>
          </Page>

          <Popup
            open={modalVisibilityState.deleteModalVisibility}
            type="warning"
            title={t("secure.facility.settings.folders.folders_edit.016")}
            description={t("secure.facility.settings.folders.folders_edit.017")}
            okText={t("secure.facility.settings.folders.folders_edit.018")}
            onOk={removeFolder}
            cancelText={t("secure.facility.settings.folders.folders_edit.019")}
            onCancel={handleDeleteModalVisibility}
          />

          <Popup
            open={modalVisibilityState.duplicateModalVisibility}
            type="warning"
            title={t("secure.facility.settings.folders.folders_edit.020")}
            description={t("secure.facility.settings.folders.folders_edit.021")}
            okText={t("secure.facility.settings.folders.folders_edit.022")}
            onOk={duplicateFolder}
            cancelText={t("secure.facility.settings.folders.folders_edit.023")}
            onCancel={handleDuplicateModalVisibility}
          />

          <Popup
            open={modalVisibilityState.saveEmptyFolderVisibility}
            type="warning"
            title={t("secure.facility.settings.folders.folders_edit.024")}
            description={t("secure.facility.settings.folders.folders_edit.025")}
            okText={t("secure.facility.settings.folders.folders_edit.026")}
            onOk={handleSaveEmptyFolderModal}
            cancelText={t("secure.facility.settings.folders.folders_edit.027")}
            onCancel={closeSaveEmptyFolderModal}
          />

          <Sheet
            closable
            onCancel={handleCloseAddProductsModal}
            size="medium"
            open={modalVisibilityState.addProductsVisibility}
            cancelText={t("secure.facility.settings.folders.folders_edit.028")}
            okText={t("secure.facility.settings.folders.folders_edit.029")}
            title={t("secure.facility.settings.folders.folders_edit.030")}
            onOk={addFolderProducts}
          >
            <div style={{ top: "60px" }} className="absolute bg-white z-20 h-6 w-11/12"></div>
            <div className="sticky-top bg-white z-20">
              <div className="text-lg">
                {`${productState.productsToAddToFolder.length} / ${productState.availableProductPositions.length} available positions filled`}
              </div>
              {productState.productsToAddToFolder.length > 0 && (
                <div className="mt-3">
                  {productState.productsToAddToFolder.map(product => {
                    return (
                      <div
                        key={product.id}
                        className="inline-flex p-2 whitespace-nowrap items-center rounded-lg mt-1 mb-1 mr-2 border"
                      >
                        <p className="whitespace-nowrap overflow-clip inline-block overflow-hidden ml-1 font-semibold">
                          {product.title}
                        </p>
                        <span>
                          <FontAwesomeIcon
                            onClick={() => handleUnselectProductToAdd(product)}
                            className="ml-2 cursor-pointer"
                            icon={["fal", "xmark"]}
                          />
                        </span>
                      </div>
                    );
                  })}
                </div>
              )}
              <div className="relative mb-2">
                <Input
                  value={filterState.search}
                  onChange={(value: any) =>
                    setFilterState(prevState => ({ ...prevState, search: value.target.value }))
                  }
                  type="search"
                  placeholder={t("secure.facility.settings.folders.folders_edit.031")}
                ></Input>
              </div>
              <div className="flex flex-row border-b-2 border-gray-200 mt-4 select-none">
                <div className="flex flex-1 text-medium pl-4">
                  {t("secure.facility.settings.folders.folders_edit.032")}
                </div>
                <div className="flex flex-1 text-medium">{t("secure.facility.settings.folders.folders_edit.033")}</div>
              </div>
            </div>

            <div className="overflow-y-scroll">
              {productState.productSearchResults
                ?.filter(
                  (product: IProduct) =>
                    !productState.folderProducts.some(folderProduct => folderProduct.id === product.id),
                )
                ?.filter(
                  (product: IProduct) =>
                    !productState.productsToAddToFolder.some(productToAdd => productToAdd.id === product.id),
                )
                ?.map((product: IProduct, index: number) => {
                  return (
                    <div
                      onClick={() => handleSelectProductToAdd(product)}
                      key={index}
                      className="flex flex-row gap-5 hover:bg-gray-200 p-4 border-b border-gray-200 cursor-pointer select-none"
                    >
                      <div className="flex-1 font-normal">
                        <div>{product.title}</div>
                        <div className="text-gray-400 text-sm">{product.subtitle}</div>
                      </div>
                      <div className="flex-1 font-normal">{product.type}</div>
                    </div>
                  );
                })}
            </div>
          </Sheet>
        </>
      )}
    </div>
  );
}
