import { TFunction } from "react-i18next";
import { IOptions } from "../../Admin";

export default function tournamentOptions(t: TFunction<"translation", undefined>, tournamentId: number): IOptions[] {
  if (isNaN(tournamentId)) {
    return [];
  } else {
    return [
      {
        label: t("secure.facility.tournament.tournament_menu.001"),
        url: `/admin/tournament/${tournamentId}/home`, //Temporary route
      },
      {
        label: t("secure.facility.tournament.tournament_menu.011"), // TODO: Translation
        url: `/admin/tournament/${tournamentId}/players`,
      },
      {
        label: t("secure.facility.tournament.tournament_menu.005"),
        url: `/admin/tournament/${tournamentId}/scores`,
        subOptions: [
          {
            label: t("secure.facility.tournament.tournament_menu.012"), // TODO: Translation
            url: `/admin/tournament/${tournamentId}/scoring`,
          },
          {
            label: t("secure.facility.tournament.tournament_menu.013"), // TODO: Translation
            url: `/admin/tournament/${tournamentId}/teams`,
          },
          {
            label: t("secure.facility.tournament.tournament_menu.014"), // TODO: Translation
            url: `/admin/tournament/${tournamentId}/flights`,
          },
        ],
      },

      {
        label: t("secure.facility.tournament.tournament_menu.007"),
        url: `/admin/tournament/${tournamentId}/tournament-settings`,
        subOptions: [
          {
            label: t("secure.facility.tournament.tournament_menu.008"),
            url: `/admin/tournament/${tournamentId}/tournament-settings/general`,
          },
          {
            label: t("secure.facility.tournament.tournament_menu.005"),
            url: `/admin/tournament/${tournamentId}/tournament-settings/scoring`,
          },
          {
            label: t("secure.facility.tournament.tournament_menu.015"),
            url: `/admin/tournament/${tournamentId}/tournament-settings/registration`,
          },
          {
            label: t("secure.facility.tournament.tournament_menu.010"),
            url: `/admin/tournament/${tournamentId}/tournament-settings/organizers`,
          },
          {
            label: "Prize Accounts", // TODO: Translation
            url: `/admin/tournament/${tournamentId}/prizeAccounts`,
          },
        ],
      },
    ];
  }
}
