import React, { useEffect, useState } from "react";
import classNames from "classnames";
import "./card.scss";
import ButtonGroup from "components/button/ButtonGroup";
import { ButtonNew as Button } from "components/buttonNew";
import Heading from "components/text/heading/Heading";
import SubHeading from "components/text/heading/SubHeading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Section = (props: any) => (
  <div
    className={classNames("ui-card-section", {
      "ui-card-section-subdued": props.subdued,
      "ui-card-section-table": props.table,
      p0: props.flushed,
    })}
  >
    {props.title ? (
      <div className={classNames("ui-card-section-title", { "ui-card-padding": props.table })}>
        <div className="">
          <h3 className="text-md text-semibold">{props.title}</h3>
          <p className="text-sm text-regular text-subdued">{props.subtitle}</p>
        </div>
        <div className="flex">
          {props.sectionTitleActions?.map((item: any, index: number) => {
            return (
              <div key={index} className="flex flex-row items-end">
                <Button
                  onClick={item.action}
                  disabled={item.disabled ?? false}
                  type={item?.type ? item?.type : "tertiary"}
                  size={item?.size ? item?.size : "small"}
                  icon={item?.icon ? item?.icon : undefined}
                >
                  {item.content}
                </Button>
              </div>
            );
          })}
        </div>
      </div>
    ) : null}
    {props.children}
  </div>
);

const SubSection = (props: any) => (
  <div
    className={classNames("ui-card-sub-section", {
      "ui-card-section-subdued bg-gray-100": props.subdued,
    })}
  >
    {props.children}
  </div>
);
interface ICardProps {
  subdued: boolean;
  sectioned: boolean;
  style: any;
  title: string;
  subtitle: string;
  titleActions: {
    content: string | React.ReactNode;
    action: () => void;
    disabled?: boolean;
    buttonType?: "default" | "primary" | "secondary" | "tertiary" | "error" | "text" | "link" | "link-color" | "close";
  }[];
  children: any;
  collapsable: boolean | string;
  /** Collapse the card on mount */
  defaultCollapsed?: boolean;
}

function Card(props: Partial<ICardProps>) {
  const [collapsed, setCollapsed] = useState<boolean>(props.defaultCollapsed ? true : false);
  return (
    <div
      className={classNames("ui-card", {
        "ui-card-subdued": props.subdued,
        "ui-card-sectioned": props.sectioned,
        "ui-card-collapsed": collapsed,
      })}
      style={props.style}
    >
      {props.title ? (
        <div className="ui-card-header">
          <div>
            <h3 className="text-lg text-semibold">{props.title}</h3>
            <p className="text-sm text-regular text-subdued">{props?.subtitle}</p>
          </div>
          <div className="flex">
            {props.titleActions?.map((item, index: number) => {
              return (
                <div key={index} className="flex flex-row items-end">
                  <Button
                    onClick={item.action}
                    disabled={item.disabled ?? false}
                    type={item.buttonType ?? "tertiary"}
                    size="small"
                  >
                    {item.content}
                  </Button>
                </div>
              );
            })}
            {props.collapsable ? (
              <div>
                {typeof props.collapsable === "string" ? (
                  <Button onClick={() => setCollapsed(prevState => !prevState)} type="tertiary" size="small">
                    {props.collapsable} &nbsp;{" "}
                    <FontAwesomeIcon icon={["fas", !collapsed ? "chevron-up" : "chevron-down"]} />
                  </Button>
                ) : (
                  <Button onClick={() => setCollapsed(prevState => !prevState)} type="tertiary" size="small">
                    <FontAwesomeIcon icon={["fas", !collapsed ? "chevron-up" : "chevron-down"]} />
                  </Button>
                )}
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      {React.Children.map(props.children, child => {
        return <>{child}</>;
      })}
    </div>
  );
}

Section.displayName = "Section";
SubSection.displayName = "SubSection";
Card.Section = Section;
Card.SubSection = SubSection;
export default Card;
