import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetAccountingReference } from "api/rpc/2024-04/facilityAdmin/facility/accounting";

import { showError } from "redux/actions/ui";
import { TAccountingReference } from "redux/reducers/models/facility";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";
import Input from "components/form/input/Input";

import "../facility/facility.scss";

type AccountFilters = {
  search: string;
};

export default function AccountingReferences() {
  const history = useHistory();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const [accountReferences, setAccountReferences] = useState<TAccountingReference[]>(undefined);

  const [filterState, setFilterState] = useState<AccountFilters>({
    search: "",
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadAccountingReferences(source.token);
    return () => source.cancel();
  }, []);

  async function loadAccountingReferences(token?: CancelToken) {
    if (accountReferences !== undefined) {
      setAccountReferences(undefined);
    }

    const res = await GetAccountingReference(null, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading accounting references"));
    }

    setAccountReferences(res.status !== StatusCode.OK ? [] : res.data);
  }

  const primaryAction = {
    content: t("secure.facility.settings.accounting_references.accounting_references.001"),
    action: () => history.push("/admin/settings/accounting_references/new"),
  };

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    console.log(id, value);
    setFilterState(prevState => ({ ...prevState, [id]: value }));
  }

  async function searchAccountingReferences() {
    const accountReferencesRes = await GetAccountingReference(
      {
        search: filterState.search,
      },
      true,
    );

    console.log(accountReferencesRes);

    if (accountReferencesRes.status !== StatusCode.OK) {
      return [];
    }

    setAccountReferences(accountReferencesRes.data);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return accountReferencesRes.data;
  }

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (async () => {
          if (filterState.search !== null) {
            try {
              const res = await searchAccountingReferences();
              if (mounted) {
                setAccountReferences(res);
              }
            } catch (error) {
              console.log("err", error);
            }
            return;
          }
        })();
      }, 1000);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [filterState.search]);

  return (
    <Page
      title={t("secure.facility.settings.accounting_references.accounting_references.002")}
      subtitle={t("secure.facility.settings.accounting_references.accounting_references.003")}
      narrow
      primaryAction={primaryAction}
    >
      <div className="flex-grow mb-4 pt-4">
        <Input
          value={filterState.search}
          label={"Search Accounts"}
          id="search"
          onChange={handleInputChange}
          placeholder={"Search..."}
          autoComplete="off"
        />
      </div>

      <DataTable
        columns={[
          { label: t("secure.facility.settings.accounting_references.accounting_references.004"), width: "50%" },
          { label: t("secure.facility.settings.accounting_references.accounting_references.005"), width: "50%" },
        ]}
        loading={accountReferences === undefined}
      >
        {accountReferences?.map(reference => (
          <tr
            key={reference.id}
            onClick={() => history.push("/admin/settings/accounting_references/" + String(reference.id))}
            className="clickable"
          >
            <td>{reference.title}</td>
            <td>{reference.reference_number}</td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
