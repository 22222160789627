import React, { useEffect, useState } from "react";
import classNames from "classnames";

import Icon from "components/icon/Icon";

import "components/dataTable/dataTable.scss";

interface IDataTable {
  columnContentTypes: "regular" | "numeric";
  headings: string[];
  rows: (string | JSX.Element)[][];
  hover?: any;
  sortable?: any;
  totals?: any;
  showTotalsInFooter?: any;
  totalsName?: any;
  footerContent?: any;
  style?: React.CSSProperties;
}

const DataTable: React.FC<IDataTable> = (props) => {
  const {
    columnContentTypes,
    headings,
    rows,
    hover,
    sortable,
    totals,
    showTotalsInFooter,
    totalsName,
    footerContent,
    children,
    ...rest
  } = props;

  return (
    <div {...rest} className="ui-data-table">
      <div className="ui-data-table_scroll-container">
        <table
          className={classNames("ui-data-table_table", {
            "ui-data-table_table--hover": hover,
          })}
        >
          <thead>
            <tr>
              {headings.map((heading: string, index: number) => (
                <th
                  key={index}
                  className={classNames(
                    "ui-data-table_cell ui-data-table_cell--header-cell ui-data-table_cell--vertical-align-top",
                    {
                      "ui-data-table_cell--first-column": index === 1,
                      "ui-data-table_cell--numeric": columnContentTypes[index] === "numeric",
                    },
                  )}
                >
                  {sortable ? (
                    <button className="ui-data-table_heading">
                      <span className="ui-data-table_heading">
                        <Icon style="fas" icon="caret-down" />
                      </span>
                      {heading}
                    </button>
                  ) : (
                    <>{heading}</>
                  )}
                </th>
              ))}
            </tr>
            <tr>
              {totals ? (
                <>
                  {totals.map((total: any, index: number) => (
                    <th
                      key={index}
                      className={classNames(
                        "ui-data-table_cell ui-data-table_cell--vertical-align-top ui-data-table_cell--total",
                        {
                          "ui-data-table_cell--first-column": index === 1,
                          "ui-data-table_cell--numeric": columnContentTypes[index] === "numeric",
                        },
                      )}
                    >
                      {total}
                    </th>
                  ))}
                </>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index: number) => (
              <tr className="ui-data-table_table-row" key={index}>
                {row.map((data, i: number) => (
                  <td
                    key={i}
                    scope="row"
                    className={classNames("ui-data-table_cell ui-data-table_cell--vertical-align-top", {
                      "ui-data-table_cell--first-column": i === 1,
                      "ui-data-table_cell--numeric": columnContentTypes[i] === "numeric",
                    })}
                  >
                    {data}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          {showTotalsInFooter ? (
            <tfoot>
              <tr>
                {totals.map((total: any, index: number) => (
                  <th
                    key={index}
                    className={classNames(
                      "ui-data-table_cell ui-data-table_cell--vertical-align-top ui-data-table_cell--total ui-data-table_cell--total-footer",
                      {
                        "ui-data-table_cell--first-column": index === 1,
                        "ui-data-table_cell--numeric": columnContentTypes[index] === "numeric",
                      },
                    )}
                  >
                    {index === 0 ? <>{totalsName ? <>{totalsName.plural}</> : "Totals"}</> : <>{total}</>}
                  </th>
                ))}
              </tr>
            </tfoot>
          ) : null}
        </table>
      </div>
      {footerContent ? <div className="ui-data-table_footer">{footerContent}</div> : null}
    </div>
  );
};

export default DataTable;
