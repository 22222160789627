import { APIGet, APIPost, APIPut, APIDelete, StatusCode, APIUrl, AdminType } from "api/protocols";
import { CancelToken } from "axios";

/* eslint-disable @typescript-eslint/unbound-method */
const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/payment/tee-on-payment/reader";

interface IGetCardReader {
  id?: number;
  device_type?: string;
  status?: string;
}

interface IDeleteCardReader {
  id: number;
}
// GET Card Reader
// Retrieve a list of card readers for a location
export async function GetCardReaders(params?: IGetCardReader, useGlobalLoader?: boolean, token?: CancelToken) {
  return await APIGet(
    APIUrl(AdminType.FacilityAdmin, "/payment/tee-on-payment/reader"),
    params,
    useGlobalLoader,
    token,
  );
}

// POST Card Reader
// Allows a new card reader to be set up for a specific location
export async function PostCardReader(params: { label: string; registration_code: string }, useGlobalLoader?: boolean) {
  return await APIPost(APIUrl(AdminType.FacilityAdmin, "/payment/tee-on-payment/reader"), params, useGlobalLoader);
}

export async function PutCardReader(params: any, useGlobalLoader?: boolean) {
  return await APIPut(APIUrl(AdminType.FacilityAdmin, "/payment/tee-on-payment/reader"), params, useGlobalLoader);
}

export async function PutCancelServerReaderPayment(params: { external_reader_id: string }, useGlobalLoader: boolean) {
  return await APIPut(
    APIUrl(AdminType.FacilityAdmin, "/payment/tee-on-payment/reader/cancel"),
    params,
    useGlobalLoader,
  );
}

export async function GetReaderPaymentStatus(
  params: { payment_intent_id: string | number },
  useGlobalLoader?: boolean,
) {
  return await APIGet(
    APIUrl(AdminType.FacilityAdmin, "/payment/tee-on-payment/reader/payment-status"),
    params,
    useGlobalLoader,
  );
}

export async function DeleteCardReader(params: IDeleteCardReader, useGlobalLoader?: boolean) {
  return await APIDelete(APIUrl(AdminType.FacilityAdmin, "/payment/tee-on-payment/reader"), params, useGlobalLoader);
}
