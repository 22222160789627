import { StatusCode } from "api/protocols";

import Card from "components/card/Card";
import Checkbox from "components/form/checkbox/Checkbox";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import { NotificationType } from "components/notificationBar/NotificationBar";
import Page from "components/page/Page";
import { isEqualWith } from "lodash";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./bankAccounts.scss";
import { useTranslation } from "react-i18next";
import { IPostBankAccount } from "api/rpc/facilityAdmin/facility/bankAccount";
import { PostBankAccount } from "api/rpc/2024-04/facilityAdmin/facility/externalAcount";
import { TBankAccountType } from "redux/reducers/models/facility";

interface IBankAccount {
  type: TBankAccountType;
  currency: string;
  country: string;
  currency_default: boolean;
  institution_number: string;
  transit_number: string;
  account_number: string;
  confirm_account_number: string;
  institution_number_is_valid: boolean;
  transit_number_is_valid: boolean;
  account_number_is_valid: boolean;
  confirm_account_number_is_valid: boolean;
}

export default function BankAccountNew() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const initialBankAccount: IBankAccount = {
    type: "bank_account",
    currency: "CAD",
    country: "",
    currency_default: false,
    institution_number: "",
    transit_number: "",
    account_number: "",
    confirm_account_number: "",
    institution_number_is_valid: false,
    transit_number_is_valid: false,
    confirm_account_number_is_valid: false,
    account_number_is_valid: false,
  };

  const [bankAccount, setBankAccount] = useState<IBankAccount>(initialBankAccount);
  const [bankAccountBeforeChanges, setBankAcountBeforeChanges] = useState<IBankAccount>(initialBankAccount);

  const INSTITUTION_NUMBER_LENGTH = 3;
  const TRANSIT_NUMBER_LENGTH = 5;
  const ACCOUNT_NUMBER_LENGTH = 7;

  function handleChange(event: any) {
    const id = event?.target?.id;
    const value = event?.target?.value;

    if (id === undefined || value === undefined) {
      return;
    }

    setBankAccount(prevState => ({ ...prevState, [id]: value }));
  }

  function handleNumberChange(event: any, propertyToChange: string, validFlagToChange: string, length: number) {
    const value = event?.target?.value;

    if (value === undefined) {
      return;
    }

    const valueCharacters = value.split("");
    setBankAccount(prevState => ({
      ...prevState,
      [propertyToChange]: value,
      [validFlagToChange]:
        valueCharacters.length === length && valueCharacters.every((v: any) => !Number.isNaN(Number(v))),
    }));
  }

  function handleConfirmChange(event: any) {
    const value = event?.target?.value;

    if (value === undefined) {
      return;
    }

    setBankAccount(prevState => ({
      ...prevState,
      confirm_account_number: value,
      confirm_account_number_is_valid: value === bankAccount.account_number,
    }));
  }

  function handleCheckboxChange(event: any) {
    const id = event?.target?.id;
    const value = event?.target?.value;

    if (id === undefined || value === undefined) {
      return;
    }

    const booleanValue = value === "true";

    setBankAccount(prevState => ({ ...prevState, [id]: !booleanValue }));
  }

  function handleAccountNumberChange(event: any) {
    const value = event?.target?.value;

    if (value === undefined) {
      return;
    }

    const valueCharacters = value.split("");
    setBankAccount(prevState => ({
      ...prevState,
      account_number: value,
      account_number_is_valid:
        valueCharacters.length === ACCOUNT_NUMBER_LENGTH && valueCharacters.every((v: any) => !Number.isNaN(Number(v))),
      confirm_account_number_is_valid: value === bankAccount.confirm_account_number,
    }));
  }

  function unsavedChangesExist() {
    return !isEqualWith(bankAccountBeforeChanges, bankAccount);
  }

  function cancelUnsavedChanges() {
    setBankAccount(bankAccountBeforeChanges);
  }

  async function saveBankAccount() {
    if (
      !bankAccount.institution_number_is_valid ||
      !bankAccount.transit_number_is_valid ||
      !bankAccount.confirm_account_number_is_valid ||
      !bankAccount.account_number_is_valid ||
      bankAccount.country === ""
    ) {
      return;
    }
    const postBankAccountRequest = {
      type: bankAccount.type,
      currency: bankAccount.currency,
      country: bankAccount.country,
      currency_default: bankAccount.currency_default,
      account_number: bankAccount.account_number,
      routing_number: bankAccount.transit_number + "-" + bankAccount.institution_number,
    };

    const bankAccountResponse = await PostBankAccount(postBankAccountRequest, true);

    if (bankAccountResponse.status !== StatusCode.OK) {
      return;
    }

    history.push("/admin/settings/bank_accounts");
  }

  return (
    <Page
      title={t("secure.facility.settings.bank_accounts.bank_account_new.001")}
      breadcrumbs={[
        {
          label: t("secure.facility.settings.bank_accounts.bank_account_new.002"),
          url: "/admin/settings/bank_accounts",
        },
      ]}
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: saveBankAccount,
        onCancel: cancelUnsavedChanges,
      }}
    >
      <Form>
        <Card>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={bankAccount.country}
                  label={t("secure.facility.settings.bank_accounts.bank_account_new.004")}
                  id="country"
                  onChange={handleChange}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Checkbox
                  id="currency_default"
                  size="small"
                  value={bankAccount.currency_default}
                  checked={bankAccount.currency_default}
                  onChange={handleCheckboxChange}
                  label={`${t("secure.facility.settings.bank_accounts.bank_account_new.005")} ${
                    bankAccount.currency_default
                      ? t("secure.facility.settings.bank_accounts.bank_account_new.006")
                      : t("secure.facility.settings.bank_accounts.bank_account_new.007")
                  }`}
                ></Checkbox>
              </FormLayout.Group>
              <FormLayout.Group>
                <Input
                  value={bankAccount.institution_number}
                  id={
                    bankAccount.institution_number.length > 0 && !bankAccount.institution_number_is_valid
                      ? "inputError"
                      : ""
                  }
                  label={t("secure.facility.settings.bank_accounts.bank_account_new.008")}
                  onChange={(e: any) =>
                    handleNumberChange(
                      e,
                      "institution_number",
                      "institution_number_is_valid",
                      INSTITUTION_NUMBER_LENGTH,
                    )
                  }
                />
                <Input
                  value={bankAccount.transit_number}
                  label={t("secure.facility.settings.bank_accounts.bank_account_new.009")}
                  id={bankAccount.transit_number.length > 0 && !bankAccount.transit_number_is_valid ? "inputError" : ""}
                  onChange={(e: any) =>
                    handleNumberChange(e, "transit_number", "transit_number_is_valid", TRANSIT_NUMBER_LENGTH)
                  }
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Input
                  value={bankAccount.account_number}
                  label={t("secure.facility.settings.bank_accounts.bank_account_new.010")}
                  id={bankAccount.account_number.length > 0 && !bankAccount.account_number_is_valid ? "inputError" : ""}
                  onChange={(e: any) => handleAccountNumberChange(e)}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Input
                  value={bankAccount.confirm_account_number}
                  label={t("secure.facility.settings.bank_accounts.bank_account_new.011")}
                  id={
                    bankAccount.confirm_account_number.length > 0 && !bankAccount.confirm_account_number_is_valid
                      ? "inputError"
                      : ""
                  }
                  onChange={(e: any) => handleConfirmChange(e)}
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>
      </Form>
    </Page>
  );
}
