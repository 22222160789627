import { FormInput } from "api/rpc/2022-09/facilityAdmin/facility/form/formInput";
import React, { useEffect, useRef } from "react";
import { useFormEditContext } from "../FormEditContext";
import FormEditInput from "./FormEditInput";
import "./formEditInputRow.scss";
import { useDropEditInputRow } from "hooks/editInputDragAndDrop/useDropEditInputRow/useDropEditInputRow";

interface IFormEditInputRowProps {
  formInputRow: string;
  formInputs: FormInput[];
}

export default function FormEditInputRow(props: IFormEditInputRowProps) {
  const { formInputRow, formInputs } = props;

  const formEditContext = useFormEditContext();

  const rowRef = useRef<HTMLDivElement>(null);

  const formEditInputContainerRefs = useRef<HTMLDivElement[]>([]);

  const { drop, canDrop, isOver, customDragLayer } = useDropEditInputRow(
    formInputs,
    rowRef,
    formEditContext.formInputRowsContainer.updatedState,
    formInputRow,
    formEditInputContainerRefs,
    formEditContext.formInputRowsContainerActions.update,
    [
      { types: ["input", "select"], maxSize: 3 },
      { types: ["checkbox"], maxSize: 3 },
      { types: ["text"], maxSize: 1 },
    ],
  );

  useEffect(() => {
    if (formInputs) {
      const numInputs = formInputs.length;

      formEditInputContainerRefs.current = formEditInputContainerRefs.current.slice(0, numInputs);
    }
  }, [formInputs]);

  return (
    <div
      ref={el => {
        drop(el);
        rowRef.current = el;
      }}
      className="form-edit-input-row"
      style={{
        outline: isOver && !canDrop ? "1px solid red" : "none",
      }}
    >
      {formInputs.map((formInput, index) => {
        return (
          <div
            ref={el => (formEditInputContainerRefs.current[index] = el)}
            key={String(formInput.id)}
            className="form-edit-input-container"
          >
            <FormEditInput formInput={formInput} />
          </div>
        );
      })}

      {customDragLayer}
    </div>
  );
}
