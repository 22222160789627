import React, { useMemo, useState } from "react";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Page from "components/page/Page";
import Input from "components/form/input/Input";
import { useHistory } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { DYNAMIC_PRICING_FACTOR_GROUP_VARIATION_ID } from "../dynamicPricingEdit/dynamicPricingNodes/DynamicPricingFactorGroupNode";
import "./teeSheetDynamicPricingNew.scss";
import {
  INewDynamicPricingGroup,
  PostDynamicPricingGroups,
  PostDynamicPricingTemplate,
} from "api/rpc/2024-04/facilityAdmin/teesheet/dynamicPricing";
import { useAppDispatch } from "hooks/redux";
import { StatusCode } from "api/protocols";
import { dequeue, enqueue, showError, showSuccess } from "redux/actions/ui";

interface ITemplatePreviousAttempt {
  id: number;
}

interface ITeeSheetDynamicPricingNewState {
  title: string;
  availableGroups: INewDynamicPricingGroup[];
}

const FLOW_MAX_GROUPS_ALLOWED = 5;

export default function TeeSheetDynamicPricingNew() {
  const history = useHistory();

  const dispatch = useAppDispatch();

  const [templatePreviousAttempt, setTemplatePreviousAttempt] = useState<ITemplatePreviousAttempt>({
    id: null,
  });

  const [state, setState] = useState<ITeeSheetDynamicPricingNewState>({
    title: "",
    availableGroups: [
      {
        title: "Days In Advance",
        token: "days_in_advance",
        order: null,
        value_type: "number_range",
        incoming_branching_limit: 3,
      },
      {
        title: "Tee-times",
        token: "tee_times",
        order: null,
        value_type: "time_range",
        incoming_branching_limit: 4,
      },
      {
        title: "Occupancy",
        token: "occupancy",
        order: null,
        value_type: "percent_range",
        incoming_branching_limit: 4,
      },
    ],
  });

  const flow: INewDynamicPricingGroup[] = useMemo(() => {
    const updatedFlow: INewDynamicPricingGroup[] = state.availableGroups
      .filter(availableGroup => availableGroup.order != null)
      .sort((prev, next) => prev.order - next.order);

    const maxOrder = updatedFlow?.[updatedFlow.length - 1]?.order ?? 0;

    updatedFlow.push({
      title: "Variations",
      token: DYNAMIC_PRICING_FACTOR_GROUP_VARIATION_ID,
      order: maxOrder + 1,
      value_type: "percent_variation",
      incoming_branching_limit: 1,
    });

    return updatedFlow;
  }, [state.availableGroups]);

  const flowIsValid = useMemo(() => {
    return (
      flow.some(flowGroup => flowGroup.token !== DYNAMIC_PRICING_FACTOR_GROUP_VARIATION_ID) &&
      flow?.[flow.length - 1]?.token === DYNAMIC_PRICING_FACTOR_GROUP_VARIATION_ID
    );
  }, [flow]);

  function setAvailableGroupOrder(availableGroupToUpdate: INewDynamicPricingGroup) {
    if (availableGroupToUpdate.order != null) {
      setState(prev => ({
        ...prev,
        availableGroups: prev.availableGroups.map(availableGroup => {
          if (availableGroup.token === availableGroupToUpdate.token) {
            return {
              ...availableGroup,
              order: null,
            };
          } else {
            return {
              ...availableGroup,
              order:
                availableGroup.order != null && availableGroup.order > availableGroupToUpdate.order
                  ? availableGroup.order - 1
                  : availableGroup.order,
            };
          }
        }),
      }));
    } else {
      const maxOrder = state.availableGroups
        .map(availableGroup => availableGroup.order)
        .reduce((prev, next) => {
          if (next == null) {
            return prev;
          } else {
            return prev < next ? next : prev;
          }
        }, 0);

      if (maxOrder >= FLOW_MAX_GROUPS_ALLOWED) {
        return;
      }

      setState(prev => ({
        ...prev,
        availableGroups: prev.availableGroups.map(availableGroup => {
          if (availableGroup.token === availableGroupToUpdate.token) {
            return {
              ...availableGroup,
              order: maxOrder + 1,
            };
          } else {
            return availableGroup;
          }
        }),
      }));
    }
  }

  async function handleCreate() {
    dispatch(enqueue());

    let templateId = templatePreviousAttempt.id;

    if (templateId == null) {
      const postTemplateResponse = await PostDynamicPricingTemplate({ title: state.title }, false);

      if (postTemplateResponse.status !== StatusCode.OK || postTemplateResponse.data?.id == null) {
        dispatch(showError("Error creating template"));
        dispatch(dequeue());
        return;
      }

      templateId = postTemplateResponse.data.id;

      setTemplatePreviousAttempt(prev => ({ ...prev, id: templateId }));
    }

    const postGroupResponse = await PostDynamicPricingGroups(
      {
        template_id: templateId,
        groups: flow,
      },
      false,
    );

    if (postGroupResponse.status !== StatusCode.OK) {
      dispatch(showError("Error creating groups"));
      dispatch(dequeue());
      return;
    }

    dispatch(showSuccess("Successfully created template"));
    dispatch(dequeue());

    history.push("/admin/settings/tee-sheet/dynamic-pricing");
  }

  return (
    <Page
      title="New Dynamic Pricing Template"
      narrow
      primaryAction={{
        content: "Create",
        action: handleCreate,
        disabled: state.title == null || state.title.length === 0 || !flowIsValid,
      }}
      breadcrumbs={[
        { prefix: true, label: "Back to dynamic pricing", url: "/admin/settings/tee-sheet/dynamic-pricing" },
      ]}
    >
      <Card>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Input
                value={state.title}
                label="Title"
                onChange={e => setState(prev => ({ ...prev, title: e.target.value }))}
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>
      <Card>
        <Card.Section title="Flow">
          <div className="dynamic-pricing-new-flow-container">
            <div className="dynamic-pricing-new-flow-options">
              {state.availableGroups.map(availableGroup => {
                return (
                  <React.Fragment key={availableGroup.token}>
                    <div
                      onClick={() => setAvailableGroupOrder(availableGroup)}
                      className="dynamic-pricing-new-flow-option"
                    >
                      {availableGroup.title}
                    </div>
                    <div>
                      {availableGroup.order != null && availableGroup.order >= 0 && availableGroup.order <= 9 ? (
                        <FontAwesomeIcon
                          icon={["far", `circle-${availableGroup.order}` as IconName]}
                          className="dynamic-pricing-new-flow-option-order"
                        />
                      ) : (
                        <div>{availableGroup.order}</div>
                      )}
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
            <div className="dynamic-pricing-new-flow">
              <FontAwesomeIcon icon={["fas", "circle"]} className="dynamic-pricing-new-flow-root" />
              <FontAwesomeIcon icon={["far", "arrow-right"]} className="dynamic-pricing-new-flow-arrow" />
              {flow.map((flowGroup, index) => {
                return (
                  <div key={flowGroup.token}>
                    <div className="dynamic-pricing-new-flow-title">{flowGroup.title}</div>
                    {index < flow.length - 1 && (
                      <FontAwesomeIcon icon={["far", "arrow-right"]} className="dynamic-pricing-new-flow-arrow" />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </Card.Section>
      </Card>
    </Page>
  );
}
