import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Facilities from "pages/secure/master/facility/Facilities";
import * as ClientActions from "redux/actions/masterAdmin/client";
import * as FacilityActions from "redux/actions/masterAdmin/facility";

const mapDispatchToProps = (dispatch: any) => {
  return {
    facilityActions: bindActionCreators(
      {
        ...FacilityActions,
      },
      dispatch,
    ),
  };
};

const mapStateToProps = (state: any) => {
  const { masterFacilityStore } = state;
  return {
    masterFacilityStore: { ...masterFacilityStore },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Facilities);
