import React, { useEffect, useState } from "react";
import axios, { CancelToken } from "axios";
import { useHistory } from "react-router-dom";
import { StatusCode } from "api/protocols";
import Page from "components/page/Page";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";
import { GetProductGroups } from "api/rpc/2024-04/facilityAdmin/product/groups";
import { capitalize } from "helpers/Helpers";
import Card from "components/card/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import "./productGroups.scss";

export default function ProductGroups() {
  const history = useHistory();
  const [productGroupsState, setProductGroupsState] = useState({
    groups: null,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadProductGroups(source.token);
    return () => source.cancel();
  }, []);

  const groupIcon: IconProp = ["fas", "circle"];
  const subgroupIcon: IconProp = ["fas", "circle"];
  const chevronUp: IconProp = ["far", "chevron-up"];
  const chevronDown: IconProp = ["far", "chevron-down"];

  async function loadProductGroups(token?: CancelToken) {
    if (productGroupsState.groups !== undefined) {
      setProductGroupsState(undefined);
    }

    const res = await GetProductGroups(null, token ? false : true, token);

    if (res.status !== StatusCode.OK) {
      setProductGroupsState(prevState => ({ ...prevState, groups: [] }));
      return;
    }

    const groups = res.data;
    const subgroups: any[] = [];

    {
      groups.map((group: any) => {
        group.selected = true;
        if (group.type === "group") {
          group.subgroups = [];
        }

        if (group.type === "sub_group") {
          subgroups.push(group);
        }
      });
    }

    {
      subgroups.map((subgroup: any) => {
        groups.map((group: any) => {
          if (subgroup.parent_id === group.id) {
            group.subgroups.push(subgroup);
          }
        });
      });
    }

    setProductGroupsState(prevState => ({ ...prevState, groups: res.data }));
  }

  function handleGroupSelect(group: any) {
    const groups = productGroupsState.groups;

    {
      groups
        ?.filter((filteredGroup: any) => filteredGroup.id === group.id)
        .map((mappedGroup: any) => {
          mappedGroup.selected = !mappedGroup.selected;
        });
    }

    setProductGroupsState(prevState => ({ ...prevState, groups: groups }));
  }

  return (
    <Page
      title="Product Groups"
      primaryAction={{
        content: "Add Group", // TODO: Translation
        action: () => history.push("/admin/settings/groups/new"),
        disabled: productGroupsState?.groups === undefined,
      }}
    >
      <Card>
        <Card.Section>
          <div className="flex flex-col mb-4">
            <p className="text-lg text-semibold mb-1">Product Groups</p>
            <div className="flex">
              <p className="text-md mr-3 text-gray-400">
                Group <FontAwesomeIcon icon={groupIcon} style={{ color: "#6888C5" }} />
              </p>
              <p className="text-md mr-3 text-gray-400">
                Subgroup <FontAwesomeIcon icon={subgroupIcon} style={{ color: "#CDD7EC" }} />
              </p>
            </div>
          </div>

          <div className="groups-container">
            {/* Map through groups */}
            {productGroupsState?.groups
              ?.filter((filteredGroup: any) => filteredGroup.type === "group")
              .map((group: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <div key={index} className="group-card">
                      <div>
                        <p className="group-title">{group.title}</p>
                      </div>

                      <div>
                        <p style={{ fontSize: "20px" }} onClick={() => handleGroupSelect(group)}>
                          <FontAwesomeIcon icon={group.selected ? chevronDown : chevronUp} />
                        </p>
                      </div>
                    </div>

                    {/* Map through categories */}
                    {group.selected ? (
                      <div>
                        {group.subgroups?.map((subgroup: any, index: number) => {
                          return (
                            <React.Fragment key={index}>
                              <div key={index} className="subgroup-card">
                                <div>
                                  <p
                                    className="group-title"
                                    style={{
                                      textIndent: "4rem",
                                    }}
                                  >
                                    {subgroup.title}
                                  </p>
                                </div>

                                <div>
                                  <p
                                    style={{ fontSize: "20px" }}
                                    // onClick={() => handlegroupSelect(group, subgroup)}
                                  >
                                    {/* <FontAwesomeIcon icon={group.selected ? chevronDown : chevronUp} /> */}
                                  </p>
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    ) : null}
                  </React.Fragment>
                );
              })}
          </div>
        </Card.Section>
      </Card>
    </Page>
  );
}
