import { APIGet, APIPost, APIPut, IAPIResponse } from "api/protocols";
import { CancelToken } from "axios";
import { ICart } from "redux/reducers/models/cart";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/facility/table";

export type TableDegreeRotation = 0 | 90 | 180 | 270;

export interface ITable {
  facility_id: number;
  floor_plan_id: number;
  id: number;
  open: number;
  open_time?: string;
  register_group_id: number;
  rotation: TableDegreeRotation;
  seats: number;
  short_title: string;
  table_element_id: number;
  table_section_id: number;
  title: string;
  user_full_name?: string;
  user_id?: number;
  x_coordinate: number;
  y_coordinate: number;
  carts?: Array<ICart>;
}

export interface IFloorPlanTable {
  id: string;
  x_coordinate: number;
  y_coordinate: number;
  seats: number;
  title: string;
  short_title: string;
  table_element_id: number;
  floor_plan_id: number;
  rotation: TableDegreeRotation;
}

interface IGetTable {
  id?: number;
  carts?: boolean;
  register_group_id?: number;
  orders?: boolean;
  floor_plan_id?: number;
}

interface IGetTableResponse extends IAPIResponse {
  data: ITable[];
}

interface IPostTable {
  id?: number;
  title?: string;
  table_section_id?: number;
  register_group_id?: number;
  tables?: Omit<IFloorPlanTable, "id">[];
}

interface IPostTableResponse {
  status: number;
  data: ITable[];
}

interface IPutTable {
  id?: number;
  title?: string;
  table_section_id?: number;
  register_group_id?: number;
  tables?: IFloorPlanTable[];
  user_id?: number;
}

interface IPutTableResponse extends IAPIResponse {
  data: ITable[];
}

interface IPutArchiveTable {
  id?: number;
  table_ids?: number[];
}

interface IPutArchiveTableResponse extends IAPIResponse {
  data: ITable[];
}

interface IGetTableReceipt {
  table_id: number;
  line_item_ids: Array<number>;
}

interface IGetTableReceiptResponse extends IAPIResponse {
  data: string;
}

interface IPutOpenTable {
  table_id: number;
  number_of_seats: number;
  register_id: number;
}

interface IPutOpenTableResponse extends IAPIResponse {
  data: ITable;
}

interface IPutCloseTable {
  table_id: number;
}

interface IPutCloseTableResponse extends IAPIResponse {
  data: ITable;
}

export interface ITableElement {
  id: number;
  title: string;
  seats: number;
  image_src: string;
  grid_width: number;
  grid_height: number;
}

interface IGetTableElementsResponse extends IAPIResponse {
  data: ITableElement[];
}

export interface IFloorPlan extends KitchenLocationMeta {
  id: number;
  facility_id: number;
  title: string;
  register_group_id: number;
  grid_width: number;
  grid_height: number;
}

interface IGetFloorPlans {
  id?: number;
  register_group_id?: number;
}

interface IPostFloorPlansResponse {
  status: number;
  data: IFloorPlan[];
}

interface IGetFloorPlansResponse extends IAPIResponse {
  data: IFloorPlan[];
}

interface IPutFloorPlan extends KitchenLocationMeta {
  id: number;
  title: string;
  grid_width: number;
  grid_height: number;
  register_group_id: number;
}

interface IPutFloorPlansResponse extends IAPIResponse {
  data: IFloorPlan[];
}

interface IPutMoveSeats {
  table_id: number;
  cart_ids: Array<number>;
  register_id?: number;
}

interface IMoveSeatsData {
  carts: Array<ICart>;
  facility_id: number;
  floor_plan_id: number;
  id: number;
  open: boolean;
  open_time: string;
  register_group_id: number;
  rotation: number;
  seats: number;
  short_title: string;
  table_element_id: number;
  table_section_id: number;
  title: string;
  token: string;
  user_full_name: string;
  user_id: number;
  x_coordinate: number;
  y_coordinate: number;
}

interface IPutMoveSeatsResponse extends IAPIResponse {
  data: IMoveSeatsData;
}

interface IPostFloorPlan {
  title: string;
  grid_width?: number;
  grid_height?: number;
}

export type KitchenLocationMeta = {
  kitchen_location_id_1: number;
  kitchen_location_id_2: number;
  kitchen_location_id_3: number;
};

export async function GetTable(
  params: IGetTable,
  useGlobalLoader: boolean,
  cancelToken?: CancelToken,
): Promise<IGetTableResponse> {
  return await APIGet(baseUrl, params, useGlobalLoader, cancelToken);
}

export async function PostTable(params: IPostTable, useGlobalLoader: boolean): Promise<IPostTableResponse> {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

export async function PutTable(params: IPutTable, useGlobalLoader: boolean): Promise<IPutTableResponse> {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

export async function PutArchiveTable(
  params: IPutArchiveTable,
  useGlobalLoader: boolean,
): Promise<IPutArchiveTableResponse> {
  return await APIPut(baseUrl + "/archive", params, useGlobalLoader);
}

export async function GetTableReceipt(
  params: IGetTableReceipt,
  useGlobalLoader: boolean,
): Promise<IGetTableReceiptResponse> {
  return await APIGet(baseUrl + "/receipt", params, useGlobalLoader);
}

export async function PutOpenTable(params: IPutOpenTable, useGlobalLoader: boolean): Promise<IPutOpenTableResponse> {
  return await APIPut(baseUrl + "/open", params, useGlobalLoader);
}

export async function PutCloseTable(params: IPutCloseTable, useGlobalLoader: boolean): Promise<IPutCloseTableResponse> {
  return await APIPut(baseUrl + "/close", params, useGlobalLoader);
}

export async function GetTableElements(useGlobalLoader: boolean): Promise<IGetTableElementsResponse> {
  return await APIGet(baseUrl + "/element", {}, useGlobalLoader);
}

export async function GetFloorPlans(params: IGetFloorPlans, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<IFloorPlan[]>(baseUrl + "/floor-plan", params, useGlobalLoader, token);
}

export async function PostFloorPlan(
  params: IPostFloorPlan,
  useGlobalLoader: boolean,
): Promise<IPostFloorPlansResponse> {
  return await APIPost(baseUrl + "/floor-plan", params, useGlobalLoader);
}

export async function PutFloorPlan(params: IPutFloorPlan, useGlobalLoader: boolean): Promise<IPutFloorPlansResponse> {
  return await APIPut(baseUrl + "/floor-plan", params, useGlobalLoader);
}

export async function PutMoveSeats(params: IPutMoveSeats, useGlobalLoader: boolean): Promise<IPutMoveSeatsResponse> {
  return await APIPut(baseUrl + "/move", params, useGlobalLoader);
}
