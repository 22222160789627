import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import axios, { CancelToken } from "axios";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Input from "components/form/input/Input";
import Sheet from "components/sheet/Sheet";
import {
  GetBookingCategories,
  PutBookingCategory,
  PostBookingCategory,
} from "api/rpc/2024-04/masterAdmin/client/settings/bookingCategory";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { showError, showSuccess } from "redux/actions/ui";
import { IBookingCategory } from "redux/reducers/models/teetime";

interface IBookingCategoriesState {
  categories: Array<IBookingCategory>;
  selectedCategory: IBookingCategory;
  categoryTitle: string;
  editBookingCategoryModalVisible: boolean;
  newBookingCategoryModalVisible: boolean;
}

export default function BookingCategories(props: any) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { masterClientStore } = useAppSelector(store => store);

  const [state, setState] = useState<IBookingCategoriesState>({
    categories: [],
    selectedCategory: null,
    categoryTitle: "",
    editBookingCategoryModalVisible: false,
    newBookingCategoryModalVisible: false,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (masterClientStore.client) {
      void loadBookingCategories(source.token);
    }

    return () => {
      source.cancel();
    };
  }, [masterClientStore.client]);

  async function loadBookingCategories(token?: CancelToken) {
    const categoryRes = await GetBookingCategories(
      {
        client_id: masterClientStore.client?.id,
      },
      true,
      token,
    );

    if (categoryRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading booking categories"));
      return;
    }

    setState(prevState => ({ ...prevState, categories: categoryRes.data }));
  }

  async function updateBookingCategory() {
    const categoryRes = await PutBookingCategory(
      {
        category_id: state.selectedCategory.id,
        title: state.categoryTitle,
      },
      true,
    );

    if (categoryRes.status !== StatusCode.OK) {
      dispatch(showError("Error updating booking category"));
      return;
    }

    dispatch(showSuccess("Booking category updated successfully"));
    setState(prevState => ({
      ...prevState,
      selectedCategory: null,
      categoryTitle: "",
      editBookingCategoryModalVisible: false,
    }));
    void loadBookingCategories();
  }

  async function createBookingCategory() {
    const categoryRes = await PostBookingCategory(
      {
        client_id: masterClientStore?.client?.id,
        title: state.categoryTitle,
      },
      true,
    );

    if (categoryRes.status !== StatusCode.OK) {
      dispatch(showError("Error creating booking category"));
      return;
    }

    dispatch(showSuccess("Booking category created successfully"));
    setState(prevState => ({
      ...prevState,
      categoryTitle: "",
      newBookingCategoryModalVisible: false,
    }));
    void loadBookingCategories();
  }

  function handleEditBookingCategory(category: IBookingCategory) {
    setState(prevState => ({
      ...prevState,
      selectedCategory: category,
      categoryTitle: category.title,
      editBookingCategoryModalVisible: true,
    }));
  }

  function handleNewBookingCategory() {
    setState(prevState => ({
      ...prevState,
      categoryTitle: "",
      newBookingCategoryModalVisible: true,
    }));
  }

  function handleCancelEdit() {
    setState(prevState => ({
      ...prevState,
      selectedCategory: null,
      categoryTitle: "",
      editBookingCategoryModalVisible: false,
    }));
  }

  function handleCancelNew() {
    setState(prevState => ({
      ...prevState,
      categoryTitle: "",
      newBookingCategoryModalVisible: false,
    }));
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setState(prevState => ({ ...prevState, [id]: value }));
  }

  const primaryAction = {
    content: "New Booking Category",
    action: handleNewBookingCategory,
    disabled: !masterClientStore.client,
  };

  return (
    <Page
      title="Booking Categories"
      subtitle={masterClientStore.client ? masterClientStore.client.full_name : "No Client Selected"}
      narrow
      primaryAction={primaryAction}
    >
      {masterClientStore.client && (
        <Card>
          <Card.Section table="true">
            <table className="ui-table ui-table-clickable">
              <thead>
                <tr>
                  <th>Title</th>
                </tr>
              </thead>
              <tbody>
                {state.categories ? (
                  <>
                    {state.categories.map((category: any, index: number) => {
                      return (
                        <tr key={index} onClick={() => handleEditBookingCategory(category)}>
                          <td>
                            <p className="table-cell-lead-text">{category.title}</p>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : null}
              </tbody>
            </table>
          </Card.Section>
        </Card>
      )}

      <Sheet
        title="Edit Booking Category"
        open={state.editBookingCategoryModalVisible}
        size="small"
        onCancel={handleCancelEdit}
        onOk={updateBookingCategory}
        closable
        backDropCancel
        cancelText="Cancel"
        okText="Update"
      >
        <Input value={state.categoryTitle} label={"Title"} id="categoryTitle" onChange={handleInputChange} />
      </Sheet>

      <Sheet
        title="New Booking Category"
        open={state.newBookingCategoryModalVisible}
        size="small"
        onCancel={handleCancelNew}
        onOk={createBookingCategory}
        closable
        backDropCancel
        cancelText="Cancel"
        okText="Create"
      >
        <Input value={state.categoryTitle} label={"Title"} id="categoryTitle" onChange={handleInputChange} />
      </Sheet>
    </Page>
  );
}
