export interface IOrderOptions {
  label: string;
  description?: string;
  url: string;
  icon?: any;
}

import { useTranslation, Trans } from "react-i18next";
export default function SettingOptionsTranslation() {
  const { t, i18n } = useTranslation();

  const orderOptions = [
    {
      label: t("secure.facility.order.order_options.001"),
      description: "List of Orders",
      url: "/admin/order", //Temporary route
    },
    {
      label: t("secure.facility.order.order_options.002"),
      description: "List of Rain Checks",
      url: "/admin/rainchecks",
    },
    {
      label: t("secure.facility.order.order_options.003"),
      description: "List of Gift Cards",
      url: "/admin/giftcards",
    },
    {
      label: "Forms", // TODO: Translation
      description: "List of Order Forms", // TODO: Translation
      url: "/admin/forms",
    },
    {
      label: "Invoices", // TODO: Translation
      description: "List of Invoices", // TODO: Translation
      url: "/admin/invoices",
    },
    {
      label: "Deposits", // TODO: Translation
      description: "List of Deposits", // TODO: Translation
      url: "/admin/invoices/deposits",
    },
  ];

  return orderOptions;
}
