import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import axios, { CancelToken } from "axios";
import classNames from "classnames";
import { cloneDeep, size } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { StatusCode } from "api/protocols";
import {
  PostLeagueFormatTemplate,
  TPostLeagueFormatTemplate,
} from "api/rpc/2024-04/facilityAdmin/league/scoring/formatTemplate";
import { GetLeagueDivision } from "api/rpc/2024-04/facilityAdmin/league/scoring/division";
import { GetSeasonLeaderboard } from "api/rpc/2024-04/facilityAdmin/league/scoring/leaderboard";

import { showError, showSuccess } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";
import {
  TCompetitionFormat,
  TCompetitionFormatHoles,
  TCompetitionHandicap,
  TCompetitionOrganization,
  TCompetitionTeamScores,
  TLeagueDivision,
  TPlayerCompetition,
  TLeagueRoundFormatTemplate,
  TLeagueSeasonLeaderboard,
  TCompetitionFormatPointAllocation,
} from "redux/reducers/models/league";
import { handleChangeEventInput } from "helpers/Helpers";

import useLeagueScoringFormatOptions from "../../scoring/rounds/scoringFormat/useLeagueScoringFormatOptions";
import useModal from "hooks/modals/useModal";
import Page from "components/page/Page";
import Card from "components/card/Card";
import { Select } from "components/select";
import FormLayout from "components/form/FormLayout";
import { IPrimaryPageAction } from "components/page/PageActions";
import Input from "components/form/input";
import Portal from "elements/Portal";
import Sheet from "components/sheet/Sheet";
import Checkbox from "components/form/checkbox/Checkbox";
import { ButtonNew as Button } from "components/buttonNew/";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import Callout from "components/callout/Callout";
import Popup from "components/popup/Popup";

import "../../scoring/rounds/scoringFormat/leagueScoringFormatNew.scss";

export default function LeagueFormatTemplateNew() {
  const history = useHistory();
  const { leagueId } = useParams<{ leagueId: string }>();

  const dispatch = useAppDispatch();

  const {
    organizationOptions,
    formatOptions,
    formatHoleOptions,
    handicapOptions,
    teamScoreOptions,
    playerCompetitionOptions,
    nineHolePoints,
    eighteenHolePoints,
  } = useLeagueScoringFormatOptions();

  const [templateState, setTemplateState] = useState<TPostLeagueFormatTemplate>({
    league_id: leagueId,
    name: "",
    league_division_id: undefined,
    title: "",
    organization: undefined,
    format: undefined,
    competition: undefined,
    default_participation_points: 0,
    holes: undefined,
    handicap: undefined,
    handicap_allowance: 100,
    team_scores_best_gross: 0,
    team_scores_best_net: 0,
    custom_points: nineHolePoints,
    leaderboard_data: [],
  });
  const [leaderboards, setLeaderboards] = useState<TLeagueSeasonLeaderboard[]>([]);
  const [filteredLeaderboards, setFilteredLeaderboards] = useState<TLeagueSeasonLeaderboard[]>([]);
  const [divisions, setDivisions] = useState<TLeagueDivision[]>([]);
  const [formatTemplates, setFormatTemplates] = useState<TLeagueRoundFormatTemplate[]>([]);

  const {
    state: customPointsModal,
    updateModal: updateCustomPointsModal,
    closeModal: closeCustomPointsModal,
  } = useModal({
    selectedCustomPointsIndex: null,
    customPoints: null,
  });

  const {
    state: noLeaderboardPopup,
    updateModal: updateNoLeaderboardPopup,
    closeModal: closeNoLeaderboardPopup,
  } = useModal();

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadLeagueLeaderboards(leagueId, source.token);
    void loadLeagueDivision(leagueId, source.token);
    return () => source.cancel();
  }, [leagueId]);

  useEffect(() => {
    const filter = leaderboards
      ?.filter(leaderboard => leaderboard.league_division_id === templateState.league_division_id)
      ?.filter(leaderboard => leaderboard.organization === templateState.organization);
    setFilteredLeaderboards(filter);
  }, [templateState.organization, templateState.league_division_id]);

  async function loadLeagueLeaderboards(leagueId: string | number, token?: CancelToken) {
    const res = await GetSeasonLeaderboard({ league_id: leagueId }, token ? false : true, token);
    if (token && token.reason) {
      return;
    }

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading league leaderboards")); // TODO: Translation
      return;
    }

    setLeaderboards(res.data);
  }

  async function loadLeagueDivision(leagueId: string | number, token?: CancelToken) {
    const res = await GetLeagueDivision({ league_id: leagueId }, token ? false : true, token);
    if (token && token.reason) {
      return;
    }

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading league division")); // TODO: Translation
      return;
    }

    if (res.data.length > 0) {
      setTemplateState(prev => ({ ...prev, league_division_id: res.data[0].id }));
    }

    setDivisions(res.data);
  }

  function formatScoringPoints() {
    let formattedScoringPoints = {};

    for (let i = 0; i < templateState.custom_points.length; i++) {
      formattedScoringPoints = {
        ...formattedScoringPoints,
        [templateState.custom_points[i].id]: templateState.custom_points[i].points,
      };
    }

    return formattedScoringPoints;
  }

  async function saveScoringFormatTemplate() {
    const params: TPostLeagueFormatTemplate = {
      ...templateState,
      // No gross score if organization is individual
      team_scores_best_gross:
        templateState.organization === "individual" ? undefined : templateState.team_scores_best_gross,
      // No net score if organization is individual OR team_scoring is ALL
      team_scores_best_net:
        templateState.organization === "individual" ? undefined : templateState.team_scores_best_net,
      // No score combination if team_scoring is BEST
      team_scores_combination:
        templateState.team_scores === "best_scores" ? undefined : templateState.team_scores_combination,
      handicap_allowance: templateState.handicap_allowance / 100,
      custom_points: formatScoringPoints(),
    };

    if (!params.league_division_id) {
      dispatch(showError("A league division is required to create a new format template.")); // TODO: Translation
      return;
    }

    const res = await PostLeagueFormatTemplate(params, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError(typeof res.data === "string" ? res.data : "Error creating new format template.")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Successfully created new format template.")); // TODO: Translation
    history.push(`/admin/league/${leagueId}/league-settings/format-templates`);
  }

  function handleChangeScoringPoints(id: string, value: string) {
    const tempScoringPoints = templateState.custom_points;

    tempScoringPoints.find((point: any) => point.id === id).points = value;

    setTemplateState(prevState => ({ ...prevState, custom_points: tempScoringPoints }));
  }

  function handleLeaderboardCheckboxChange(leaderboardId: number) {
    const tempLeaderboardData = templateState.leaderboard_data;
    const leaderboardIndex = tempLeaderboardData.findIndex(data => data.id === leaderboardId);

    if (leaderboardIndex < 0) {
      tempLeaderboardData.push({
        id: leaderboardId,
        point_allocation: templateState.format === "stroke_play" ? "posted" : "posted_points",
        custom_points: null,
      });
    } else {
      tempLeaderboardData.splice(leaderboardIndex, 1);
    }

    setTemplateState(prevState => ({ ...prevState, leaderboard_data: tempLeaderboardData }));
  }

  function handlePointAllocationChange(value: TCompetitionFormatPointAllocation, leaderboardIndex: number) {
    const tempLeaderboardData = cloneDeep(templateState.leaderboard_data);

    tempLeaderboardData[leaderboardIndex].point_allocation = value;

    if (value === "custom") {
      tempLeaderboardData[leaderboardIndex].custom_points = [{ position: 1, points: 0 }];
    } else {
      tempLeaderboardData[leaderboardIndex].custom_points = null;
    }

    setTemplateState(prevState => ({ ...prevState, leaderboard_data: tempLeaderboardData }));
  }

  function handleAddCustomPoint() {
    const tempCustomPoints = [...customPointsModal.customPoints];

    tempCustomPoints.push({ position: Number(tempCustomPoints[tempCustomPoints.length - 1].position) + 1, points: 0 });

    updateCustomPointsModal({ customPoints: tempCustomPoints });
  }

  function handleRemoveCustomPoint() {
    const tempCustomPoints = [...customPointsModal.customPoints];

    tempCustomPoints.splice(tempCustomPoints.length - 1, 1);

    updateCustomPointsModal({ customPoints: tempCustomPoints });
  }

  function handleEditCustomPoint(value: number | string, pointIndex: number) {
    const tempCustomPoints = [...customPointsModal.customPoints];

    tempCustomPoints[pointIndex].points = Number(value);

    updateCustomPointsModal({ customPoints: tempCustomPoints });
  }

  function handleSaveCustomPoints() {
    const tempLeaderboardData = cloneDeep(templateState.leaderboard_data);

    tempLeaderboardData[customPointsModal.selectedCustomPointsIndex].custom_points = customPointsModal.customPoints;

    setTemplateState(prevState => ({ ...prevState, leaderboard_data: tempLeaderboardData }));
    updateCustomPointsModal({ isOpen: false, selectedCustomPointsIndex: null, customPoints: null });
  }

  function checkForLeaderboards() {
    if (templateState?.leaderboard_data?.length <= 0 && templateState.format !== "skins") {
      updateNoLeaderboardPopup({ isOpen: true });
    } else {
      void saveScoringFormatTemplate();
    }
  }

  const primaryAction: IPrimaryPageAction = {
    action: () => void checkForLeaderboards(),
    content: "Save", // TODO: Translation
  };

  return (
    <Page
      title="New Format Template" // TODO: Translation
      breadcrumbs={[
        {
          prefix: true,
          label: "Format Templates", // TODO: Translation
          url: `/admin/league/${leagueId}/league-settings/format-templates`,
        },
      ]}
      primaryAction={primaryAction}
    >
      <Card>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Input
                type="text"
                label="Template Name" // TODO: Translation
                id="name"
                value={templateState.name}
                onChange={e => handleChangeEventInput(e, templateState, setTemplateState)}
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Input
                type="text"
                label="Format Title" // TODO: Translation
                id="title"
                value={templateState.title}
                onChange={e => handleChangeEventInput(e, templateState, setTemplateState)}
              />
            </FormLayout.Group>
            {divisions.length >= 2 && (
              <FormLayout.Group>
                <Select
                  label="League Division" // TODO: Translation
                  defaultValue={templateState.league_division_id}
                  onChange={(value: number) => setTemplateState(prev => ({ ...prev, league_division_id: value }))}
                  disabled={divisions.length < 2}
                >
                  {divisions.map(division => (
                    <Select.Option key={division.id} value={division.id}>
                      {division.title}
                    </Select.Option>
                  ))}
                </Select>
              </FormLayout.Group>
            )}
          </FormLayout>
        </Card.Section>

        <Card.Section title="Individual or Team Scoring" /* TODO: Translation */>
          <FormLayout>
            <FormLayout.Group>
              <Select
                defaultValue={templateState.organization}
                onChange={(value: TCompetitionOrganization) =>
                  setTemplateState(prev => ({
                    ...prev,
                    organization: value,
                    team_scores: value === "individual" ? undefined : prev.team_scores,
                    team_scores_combination: value === "individual" ? undefined : prev.team_scores_combination,
                    team_scores_best_net: value === "individual" ? 0 : prev.team_scores_best_net,
                    team_scores_best_gross: value === "individual" ? 0 : prev.team_scores_best_gross,
                    competition: undefined,
                  }))
                }
              >
                {organizationOptions.map(option => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
        <Card.Section title="Scoring" /* TODO: Translation */>
          <FormLayout>
            <FormLayout.Group>
              <Select
                label="Scoring Format" // TODO: Translation
                defaultValue={templateState.format}
                onChange={(value: TCompetitionFormat) => setTemplateState(prev => ({ ...prev, format: value }))}
              >
                {formatOptions.map(option => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>

              <Select
                label="Competition" // TODO: Translation
                defaultValue={templateState.competition}
                onChange={(value: TPlayerCompetition) => setTemplateState(prev => ({ ...prev, competition: value }))}
                disabled={templateState.organization === undefined}
              >
                {playerCompetitionOptions
                  .filter(val => val.organization === templateState.organization)
                  .map(option => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.label}
                    </Select.Option>
                  ))}
              </Select>
            </FormLayout.Group>
            <FormLayout.Group>
              <Select
                label="Holes" // TODO: Translation
                defaultValue={templateState.holes}
                onChange={(value: TCompetitionFormatHoles) =>
                  setTemplateState(prev => ({
                    ...prev,
                    holes: value,
                    custom_points: value === "9_holes" ? nineHolePoints : eighteenHolePoints,
                  }))
                }
              >
                {formatHoleOptions.map(option => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>

              <Input
                label={"Default Participation Points"} // TODO: Translation
                id="default_participation_points"
                type="number"
                value={templateState.default_participation_points}
                onChange={e => handleChangeEventInput(e, templateState, setTemplateState)}
              />
            </FormLayout.Group>
            {templateState.format === "points_by_score" && (
              <div className="league-scoring-format-new-custom_points_container">
                {templateState.custom_points?.map((point: any) => {
                  return (
                    <div key={point.id} className="league-scoring-format-new-custom_points_input">
                      <Input
                        type="number"
                        label={point.label}
                        id={point.id}
                        value={point.points}
                        onChange={e => handleChangeScoringPoints(point.id, e.target.value)}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </FormLayout>
        </Card.Section>

        {templateState.organization === "team" ? (
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Select
                  label="Team Scoring" // TODO: Translation
                  defaultValue={templateState.team_scores}
                  onChange={(value: TCompetitionTeamScores) =>
                    setTemplateState(prev => ({
                      ...prev,
                      team_scores: value,
                    }))
                  }
                >
                  {teamScoreOptions.map(option => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>

                {...templateState.team_scores === "best_scores" || templateState.team_scores === "gross_scores"
                  ? [
                      <Input
                        key={0}
                        label={"Gross Scores"} // TODO: Translation
                        id="team_scores_best_gross"
                        type="number"
                        value={templateState.team_scores_best_gross}
                        onChange={e => handleChangeEventInput(e, templateState, setTemplateState)}
                      />,
                    ]
                  : []}

                {...templateState.team_scores === "best_scores" || templateState.team_scores === "net_scores"
                  ? [
                      <Input
                        key={0}
                        label={"Net Scores"} // TODO: Translation
                        id="team_scores_best_net"
                        type="number"
                        value={templateState.team_scores_best_net}
                        onChange={e => handleChangeEventInput(e, templateState, setTemplateState)}
                      />,
                    ]
                  : []}
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        ) : null}

        <Card.Section title="Handicaps" /* TODO: Translation */>
          <FormLayout>
            <FormLayout.Group>
              <Select
                defaultValue={templateState.handicap}
                label="Handicap" //  TODO: Translation
                onChange={(value: TCompetitionHandicap) => setTemplateState(prev => ({ ...prev, handicap: value }))}
              >
                {handicapOptions.map(option => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
              {/* <Input
                label={"Handicap Allowance"} // TODO: Translation
                id={"handicap_allowance"}
                type="number"
                value={formatState.handicap_allowance}
                suffix="%"
                onChange={e => handleChangeEventInput(e, formatState, setFormatState)}
              /> */}
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>

        {filteredLeaderboards?.length > 0 && templateState.organization && templateState.format !== "skins" ? (
          <Card.Section title="Leaderboards" /* TODO: Translation */>
            <div className={"ui-checkbox-group formats-checkbox-group"}>
              {filteredLeaderboards?.map((leaderboard, index) => (
                <div key={index}>
                  <div
                    onClick={() => handleLeaderboardCheckboxChange(leaderboard.id)}
                    className={classNames("ui-checkbox-group-item", {
                      "ui-checkbox-group-item_selected":
                        templateState.leaderboard_data.findIndex(data => data.id === leaderboard.id) >= 0,
                    })}
                  >
                    <div className="ui-checkbox-group-item-content">
                      <p className="location-title">{leaderboard.title}</p>
                    </div>
                    <div className="pointer-events-none">
                      <Checkbox
                        key={index}
                        checked={templateState.leaderboard_data.findIndex(data => data.id === leaderboard.id) >= 0}
                        fullWidth
                        size="medium"
                        onChange={() => handleLeaderboardCheckboxChange(leaderboard.id)}
                      />
                    </div>
                  </div>
                  {/* {formatState.leaderboard_data.findIndex(data => data.id === leaderboard.id) >= 0 && (
                    // <div className="flex justify-between gap-2 mt-2">
                    //   <Select
                    //     defaultValue={"posted_points"}
                    //     label="Point Allocation" //  TODO: Translation
                    //     onChange={(value: TCompetitionFormatPointAllocation) =>
                    //       handlePointAllocationChange(value, index)
                    //     }
                    //   >
                    //     {pointAllocationOptions.map(option => (
                    //       <Select.Option key={option.id} value={option.id}>
                    //         {option.label}
                    //       </Select.Option>
                    //     ))}
                    //   </Select>
                    //   {formatState.leaderboard_data[index]?.point_allocation === "custom" && (
                    //     <>
                    //       <div />
                    //       <Button
                    //         shape="circle"
                    //         onClick={() =>
                    //           updateCustomPointsModal({
                    //             isOpen: true,
                    //             selectedCustomPointsIndex: index,
                    //             customPoints: formatState.leaderboard_data.find(data => data.id === leaderboard.id)
                    //               .custom_points,
                    //           })
                    //         }
                    //         type="tertiary"
                    //         size="small"
                    //         block
                    //       >
                    //         <FontAwesomeIcon icon={["far", "pencil-square"]} size={"2x"} />
                    //       </Button>
                    //     </>
                    //   )}
                    // </div>
                  )} */}
                </div>
              ))}
            </div>
          </Card.Section>
        ) : (
          <Card.Section>
            <Callout
              type="info"
              title="No Leaderboards Available" // TODO: Translation
              content="No leaderboards have been created for the selected division and organization" // TODO: Translation
              actions={{
                primary: {
                  label: "Leaderboards", // TODO: Translation
                  onClick: () => history.push(`/admin/league/${leagueId}/scoring/leaderboards`),
                },
              }}
            />
          </Card.Section>
        )}
      </Card>

      <Portal isMounted={customPointsModal.isOpen}>
        <Sheet
          open={customPointsModal.isOpen}
          title="Custom Leaderboard Points" // TODO: Translation
          okText="Save" // TODO: Translation
          onCancel={closeCustomPointsModal}
          closable
          onOk={handleSaveCustomPoints}
          size="small"
        >
          <DataTable
            columns={[
              { label: "Position" }, // TODO: Translation
              { label: "Points" }, //TODO: Translation
            ]}
          >
            {customPointsModal.customPoints?.map((point: any, index: number) => (
              <tr key={point.position}>
                <td>{point.position}</td>
                <td>
                  <Input
                    labelHidden
                    id={"handicap_allowance"}
                    type="number"
                    value={point.points}
                    onChange={e => handleEditCustomPoint(e.target.value, index)}
                  />
                </td>
              </tr>
            ))}
            <tr>
              <td style={{ alignItems: "center" }}>
                <Button onClick={handleAddCustomPoint} type="tertiary" size="small">
                  <FontAwesomeIcon icon={["far", "plus"]} size={"2x"} />
                </Button>
              </td>
              <td style={{ alignItems: "center" }}>
                <Button
                  onClick={handleRemoveCustomPoint}
                  type="tertiary"
                  size="small"
                  disabled={customPointsModal.customPoints?.length <= 1}
                >
                  <FontAwesomeIcon icon={["far", "minus"]} size={"2x"} />
                </Button>
              </td>
            </tr>
          </DataTable>
        </Sheet>
      </Portal>

      <Portal isMounted={noLeaderboardPopup.isOpen}>
        <Popup
          open={noLeaderboardPopup.isOpen}
          title="No Leaderboards Selected" // TODO: Translation
          description="Any formats that use this template will not be counted towards any season leaderboards. Are you sure you want to continue?" // TODO: Translation
          type="warning"
          okText="Continue" // TODO: Translation
          onOk={saveScoringFormatTemplate}
          onCancel={closeNoLeaderboardPopup}
        />
      </Portal>
    </Page>
  );
}
