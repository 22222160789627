import { IOptions } from "components/sideMenu/SideMenu";

const customerOptions: IOptions[] = [
  {
    label: "All Customers",
    url: "/admin/customers", //auto-route
  },
  {
    label: "Public",
    url: "/admin/customers-public",
  },
  {
    label: "Members",
    url: "/admin/customers-member",
  },
];

export default customerOptions;
