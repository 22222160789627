import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { StatusCode } from "api/protocols";
import Page from "components/page/Page";
import Card from "components/card/Card";
import Input from "components/input/Input";
import "pages/secure/facility/product/TicketStub.scss";
import { IUIActions } from "redux/actions/ui";
import { GetTicketStub, PutRedeem } from "api/rpc/2022-09/clientAdmin/ticket/ticket";
import moment from "moment";
import { Badge } from "components/badge/Badge";
import { valueToString } from "helpers/Helpers";

interface IState {
  stubs: Array<Record<string, any>>;
}

interface IFilterState {
  search: string;
  filters: Array<Record<string, any>>;
}

interface IProps {
  uiActions: IUIActions;
}

export default function Tickets(props: IProps) {
  const { uiActions } = props;

  const history = useHistory();
  const [state, setState] = useState<IState>({
    stubs: [],
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    search: "",
    filters: [],
  });

  function updateState(newState: Partial<IState>) {
    setState((cur: IState) => {
      return { ...cur, ...newState };
    });
  }

  function navigateToItem(url: string) {
    history.push(url);
  }

  async function loadTicketStubs() {
    const ticketStubRes = await GetTicketStub(
      {
        search: filterState.search,
      },
      true,
    );

    if (ticketStubRes.status !== StatusCode.OK) {
      return [];
    }

    updateState({ stubs: ticketStubRes.data });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return ticketStubRes.data;
  }

  // Search Orders
  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (async () => {
          if (filterState.search !== null) {
            try {
              const res = await loadTicketStubs();
              if (mounted) {
                setState(prevState => ({ ...prevState, stubs: res }));
              }
            } catch (error) {
              console.log("err", error);
            }
            return;
          }
        })();
      }, 1000);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [filterState.search]);

  async function redeemTicketStub(ticketStub: any) {
    const code = ticketStub.code;

    const putRedeemRes = await PutRedeem(code, true);

    if (putRedeemRes.status !== StatusCode.OK) {
      uiActions.showError("Error redeeming ticket stub");
      return;
    }

    uiActions.showSuccess("Redeem Ticket Stub Successful");
    const res = await loadTicketStubs();

    setState(prevState => ({ ...prevState, stubs: res }));
  }

  return (
    <Page title="Ticket Stubs" narrow breadcrumbs={[{ label: "Back to Tickets", url: "/admin/tickets" }]}>
      <div className="flex-grow mb-4">
        <Input
          value={filterState.search}
          onChange={(value: any) => setFilterState(prevState => ({ ...prevState, search: value.target.value }))}
          type="search"
          placeholder="Search..."
        ></Input>
      </div>

      <Card>
        <Card.Section table="true">
          <table className="ui-table ui-table-clickable">
            <tbody>
              {state.stubs ? (
                <>
                  {state.stubs.map((stub: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td>
                          <p
                            className="table-cell-lead-text"
                            onClick={() => navigateToItem("/admin/tickets/stubs/" + String(stub.id))}
                          >
                            {stub.code}
                          </p>
                        </td>
                        <td>
                          {stub.expiry_date === null ? (
                            <span>No Expiry</span>
                          ) : (
                            <span>
                              {"Expiry: "} {moment.utc(stub.expiry_date).local().format("MMMM DD, YYYY")}
                            </span>
                          )}
                        </td>
                        <td>
                          <Badge type="gray" size="small" iconLeft icon="circle" iconStyle="fas">
                            {valueToString(stub.status, "capitalize")}
                          </Badge>
                        </td>
                        <td>
                          {stub.status === "valid" ? (
                            <button onClick={() => redeemTicketStub(stub)}>{"Redeem"}</button>
                          ) : null}
                        </td>
                      </tr>
                    );
                  })}
                </>
              ) : null}
            </tbody>
          </table>
        </Card.Section>
      </Card>
    </Page>
  );
}
