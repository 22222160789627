import React, { useEffect, useRef, useState } from "react";

import Page from "components/page/Page";
import Card from "components/card/Card";
import { GetCheckIns, PutCheckIns, IPutCheckIns, PutCopyCheckIns } from "api/rpc/2024-04/clientAdmin/teesheet/checkIn";

import { StatusCode } from "api/protocols";
import { groupBy, isEqualWith, isNull } from "lodash";
import { capitalize } from "helpers/Helpers";
import { NotificationType } from "components/notificationBar/NotificationBar";
import { Select } from "components/select/index";
import { Badge } from "components/badge/Badge";
import { IUIActions } from "redux/actions/ui";
import { useTranslation, Trans } from "react-i18next";
import { GetPricingSheet } from "api/rpc/2022-09/clientAdmin/teeSheets/pricingSheet";
import { IClientFacilityStore } from "redux/reducers/clientAdmin/facility";
import classNames from "classnames";
import "./pricingSheets.scss";
import Checkbox from "components/form/checkbox/Checkbox";
import Sheet from "components/sheet/Sheet";
import Callout from "components/callout/Callout";
import ReactDOM from "react-dom";
import DropFilter from "components/dropdown/DropFilter";
import { GetCustomerType } from "api/rpc/2022-09/clientAdmin/customer/customer";

interface ICheckIn {
  id: number;
  customerTypeId: number;
  pricingSheetId: number;
  variantId: number;
  customerType: string;
  customer_type: ICustomerType;
  pricingSheet: string;
  pricingSheetCourse: string;
  pricingSheetApplication: string;
  holes: number;
  dayOfWeekNumber: number;
  courseId: number;
}

interface IVariant {
  id: number;
  type: string;
  productId: number;
  productTitle: string;
}

interface ICustomerType {
  id: number;
  title: string;
  subtitle: string;
  full_title: string;
}

interface ICheckInOption {
  productSheetId: number;
  variants: IVariant[];
}

interface IFilterState {
  application: Array<string>;
  emptyVariant: number;
  filterInProgress: boolean;
  holes: Array<number>;
  courses: Array<number>;
  customerTypes: Array<ICustomerType>;
  customerTypeSearch: string;
  selectedCustomerTypeId: number;
}

interface IProps {
  uiActions: IUIActions;
  clientFacilityStore: IClientFacilityStore;
}

interface IDuplicateState {
  selectedFacility: Record<string, any>;
  selectedFacilityId: number;
  selectedCourseId: number;
  selectedDayOfWeek?: string;
  showModal: boolean;
  copyEntireDay: boolean;
}

enum Day {
  Holiday = 99,
  Monday = 1,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday,
}

type DayValue = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 99;

const DAYS_OF_WEEK = {
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  7: "Sunday",
  99: "Holiday",
};

export default function CheckInConfig(props: IProps) {
  const { uiActions, clientFacilityStore } = props;
  const [checkInState, setCheckInState] = useState<ICheckIn[]>([]);
  const [checkInStateBeforeChanges, setCheckInStateBeforeChanges] = useState<ICheckIn[]>([]);
  const [checkInStateInitialLoad, setCheckInStateInitialLoad] = useState<ICheckIn[]>([]);
  const [checkInIds, setCheckInIds] = useState<Array<number>>([]);
  const mounted = useRef(false);

  const [filterState, setFilterState] = useState<IFilterState>({
    application: null,
    emptyVariant: 0,
    holes: null,
    courses: null,
    filterInProgress: false,
    customerTypes: [],
    customerTypeSearch: "",
    selectedCustomerTypeId: null,
  });

  const { Option } = Select;

  const [checkInOptionState, setCheckInOptionState] = useState<ICheckInOption[]>([]);
  const [duplicateState, setDuplicateState] = useState<IDuplicateState>({
    selectedFacilityId: null,
    selectedFacility: null,
    selectedCourseId: null,
    selectedDayOfWeek: "",
    showModal: false,
    copyEntireDay: false,
  });

  const [filterDayState, setFilterDayState] = useState<DayValue>(Day.Monday);

  const holesTypes = [9, 18];

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (clientFacilityStore?.facility && mounted.current) {
      void loadCheckIns();
      void loadVariantTypes();
    }
  }, [filterDayState, filterState.application, filterState.selectedCustomerTypeId, filterState.emptyVariant]);

  useEffect(() => {
    if (clientFacilityStore?.facility) {
      void loadCheckIns(true);
      void loadVariantTypes();
      void loadCustomerTypes();
    }
  }, [clientFacilityStore?.facility]);

  useEffect(() => {
    if (filterState.holes || filterState.courses) {
      setFilterState(prevState => ({ ...prevState, filterInProgress: true }));
    } else if (mounted.current) {
      void loadCheckIns();
    } else {
      mounted.current = true;
    }
  }, [filterState.holes, filterState.courses]);

  useEffect(() => {
    if (filterState.filterInProgress) {
      //handle filters on the frontend
      void handleFilterCheckIns();
    }
  }, [filterState.filterInProgress]);

  async function loadCustomerTypes() {
    const customerTypeRes = await GetCustomerType(null, false);
    if (customerTypeRes.status !== StatusCode.OK) {
      uiActions.showError("Error getting customer types");
      return;
    }
    setFilterState(prevState => ({ ...prevState, customerTypes: customerTypeRes.data }));
  }

  function handleFilterCheckIns(checkIns?: Array<ICheckIn>, resetCoursesFilter?: boolean) {
    const updatedCheckIns = checkIns ? [...checkIns] : [...checkInStateInitialLoad];
    let filteredCheckIns = updatedCheckIns;
    if (filterState.holes) {
      filteredCheckIns = filteredCheckIns.filter(checkIn => filterState.holes?.includes(checkIn?.holes));
    }

    if (filterState.courses && !resetCoursesFilter) {
      filteredCheckIns = filteredCheckIns.filter(checkIn => filterState.courses?.includes(checkIn?.courseId));
    }

    ReactDOM.unstable_batchedUpdates(() => {
      setCheckInState(filteredCheckIns);
      setCheckInStateBeforeChanges(filteredCheckIns);
      setCheckInIds([]);
      setFilterState(prevState => ({ ...prevState, filterInProgress: false }));
    });
  }

  async function loadCheckIns(resetCoursesFilter?: boolean) {
    const checkInsResponse = await GetCheckIns(
      {
        facility_id: clientFacilityStore?.facility?.id,
        day_of_week_number: filterDayState,
        application: filterState.application,
        customer_type_id: filterState.selectedCustomerTypeId,
        limit: 100,
        no_variant: filterState.emptyVariant,
      },
      true,
    );

    if (checkInsResponse.status !== StatusCode.OK) {
      return;
    }

    const checkIns: ICheckIn[] = checkInsResponse.data.map((checkInData: any) => {
      return {
        id: checkInData.id,
        customerTypeId: checkInData.customer_type_id,
        pricingSheetId: checkInData.pricing_sheet_id,
        variantId: checkInData.variant_id,
        dayOfWeekNumber: checkInData.pricing_sheet.day_of_week_number,
        customerType: checkInData.customer_type?.title,
        customer_type: checkInData.customer_type,
        pricingSheet: checkInData.pricing_sheet.title,
        pricingSheetCourse: checkInData.pricing_sheet.course?.full_name,
        pricingSheetApplication: checkInData.pricing_sheet.application,
        holes: checkInData.holes,
        courseId: checkInData?.pricing_sheet?.course_id,
      };
    });
    if (filterState.holes || filterState.courses) {
      void handleFilterCheckIns(checkIns, resetCoursesFilter);
      if (resetCoursesFilter) {
        setFilterState(prevState => ({ ...prevState, courses: null }));
      }
    } else {
      ReactDOM.unstable_batchedUpdates(() => {
        setCheckInState(checkIns);
        setCheckInStateBeforeChanges(checkIns);
        setCheckInIds([]);
        setDuplicateState(prevState => ({
          ...prevState,
          selectedFacilityId: clientFacilityStore?.facility?.id,
          selectedFacility: clientFacilityStore?.facility,
          selectedCourseId:
            clientFacilityStore?.facility?.courses?.length > 1 ? clientFacilityStore?.facility?.courses[0]?.id : null,
        }));
      });
    }
    setCheckInStateInitialLoad(checkIns);
  }

  async function loadVariantTypes() {
    const pricingSheetsResponse = await GetPricingSheet(
      {
        facility_id: clientFacilityStore?.facility?.id,
        extended: false,
        day_of_week_number: filterDayState,
      },
      true,
    );

    if (pricingSheetsResponse.status !== StatusCode.OK) {
      return;
    }

    const checkInOptionState: ICheckInOption[] = pricingSheetsResponse.data.map((pricingSheet: any) => {
      const variants: IVariant[] =
        !!pricingSheet && !!pricingSheet.variants
          ? pricingSheet.variants.map((variant: any) => {
              return {
                id: variant.id,
                type: variant.title,
                productId: variant.product.id,
                productTitle: variant.product.title,
              };
            })
          : [];
      return {
        productSheetId: pricingSheet.id,
        variants: variants,
      };
    });

    setCheckInOptionState(checkInOptionState);
  }

  function handleSelectChange(event: any, checkInIndex: number) {
    const id = event?.target?.id;
    const value = parseInt(event?.target?.value);

    if (id === undefined || isNaN(value)) {
      return;
    }

    setCheckInState(prevState =>
      prevState.map((originalCheckIn, index) => {
        if (index === checkInIndex) {
          return { ...originalCheckIn, [id]: value };
        } else {
          return originalCheckIn;
        }
      }),
    );
  }

  function handleDropDownChange(value: number, property: string, currentCheckIn: ICheckIn, checkInIndex: number) {
    const currentCheckIns = [...checkInState];

    if (property === undefined) {
      return;
    }

    if (checkInIds.includes(currentCheckIn?.id)) {
      const newCheckIns = currentCheckIns?.map(checkIn => {
        //Get pricing sheet variants for the current check in
        const checkInOption = checkInOptionState?.find(option => option.productSheetId === checkIn?.pricingSheetId);
        if (
          checkInIds.includes(checkIn?.id) &&
          currentCheckIn?.pricingSheetApplication === checkIn?.pricingSheetApplication &&
          checkInOption &&
          checkInOption?.variants?.some(variant => variant?.id === value)
        ) {
          return { ...checkIn, [property]: value };
        } else {
          return checkIn;
        }
      });
      setCheckInState(newCheckIns);
    } else {
      currentCheckIns[checkInIndex] = { ...currentCheckIns[checkInIndex], [property]: value };
      setCheckInState(currentCheckIns);
    }
  }

  function getVariants(productSheetId: number): Array<Array<IVariant>> {
    let variants: IVariant[] = [];
    for (let i = 0; i < checkInOptionState.length; i++) {
      if (productSheetId === checkInOptionState[i].productSheetId) {
        variants = checkInOptionState[i].variants;
      }
    }

    return Object.values(groupBy(variants, v => v.productId));
  }

  function DayTab({ title, value }: { title: string; value: DayValue }): JSX.Element {
    return (
      <button
        className={classNames("pricing-sheet-day-tab", { "pricing-sheet-day-tab-selected": filterDayState === value })}
        onClick={() => setFilterDayState(value)}
      >
        {title}
      </button>
    );
  }

  async function saveCheckins() {
    const changedCheckins: IPutCheckIns[] = [];

    for (let i = 0; i < checkInState.length; i++) {
      if (
        !isEqualWith(checkInState[i], checkInStateBeforeChanges[i], (originalValue, newValue) => {
          if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
            return true;
          }
        })
      ) {
        changedCheckins.push({
          id: checkInState[i].id,
          customer_type_id: checkInState[i].customerTypeId,
          pricing_sheet_id: checkInState[i].pricingSheetId,
          variant_id: checkInState[i].variantId,
          holes: checkInState[i].holes,
        });
      }
    }

    const putCheckInsResponse = await PutCheckIns({ configurations: changedCheckins }, true);

    if (putCheckInsResponse.status !== StatusCode.OK) {
      return;
    }

    if (filterState.holes || filterState.courses) {
      setFilterState(prevState => ({ ...prevState, filterInProgress: true }));
    }
    await loadCheckIns();
  }

  function unsavedChangesExist() {
    if (checkInStateBeforeChanges.length === 0) {
      if (!!checkInState && checkInState.length > 0 && !!checkInOptionState && checkInOptionState.length > 0) {
        setCheckInStateBeforeChanges(checkInState);
      }
      return false;
    }

    return !isEqualWith(checkInStateBeforeChanges, checkInState, (originalValue, newValue) => {
      if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
        return true;
      }
    });
  }

  function cancelUnsavedChanges() {
    setCheckInIds([]);
    setCheckInState(checkInStateBeforeChanges);
  }

  function handleCheckboxClick(checkInId: number) {
    const currentCheckInIds = [...checkInIds];
    const foundIndex = currentCheckInIds.indexOf(checkInId);
    if (foundIndex === -1) {
      currentCheckInIds.push(checkInId);
    } else {
      currentCheckInIds.splice(foundIndex, 1);
    }
    setCheckInIds(currentCheckInIds);
  }

  function handleDuplicateDropdownChange(value: string | number, id: string, facility?: Record<string, any>) {
    setDuplicateState(prevState => ({
      ...prevState,
      [id]: value,
      selectedFacility: facility ? facility : prevState?.selectedFacility,
      selectedCourseId: null,
    }));
  }

  function handleCourseChange(value: number) {
    setDuplicateState(prevState => ({
      ...prevState,
      selectedCourseId: value,
    }));
  }

  function handleDayOfWeekChange(value: string) {
    setDuplicateState(prevState => ({
      ...prevState,
      selectedDayOfWeek: value,
    }));
  }

  function selectAllCheckInIds() {
    const ids = checkInState.map(checkIn => checkIn.id);
    setCheckInIds(ids);
  }

  async function handleDuplicate() {
    const res = await PutCopyCheckIns(
      {
        facility_id: duplicateState?.selectedFacilityId,
        check_in_ids: checkInIds,
        course_id: duplicateState?.selectedCourseId ? duplicateState?.selectedCourseId : undefined,
        day_of_week: duplicateState.selectedDayOfWeek,
        select_all_day_of_week: duplicateState.copyEntireDay ? DAYS_OF_WEEK[filterDayState] : undefined,
        base_facility_id: clientFacilityStore?.facility?.id,
      },
      true,
    );
    if (res.status !== StatusCode.OK) {
      uiActions.showError("Error copying check in configs");
      return;
    }
    uiActions.showSuccess("Successfully copied check in configs");
    ReactDOM.unstable_batchedUpdates(() => {
      setDuplicateState(prevState => ({ ...prevState, showModal: false, copyEntireDay: false }));
      setCheckInIds([]);
    });
  }

  function setEmptyVariantFilter(filterData: Array<{ value: number; title: string }>) {
    if (filterData.length === 0) {
      setFilterState(prevState => ({ ...prevState, emptyVariant: 0 }));
    } else {
      setFilterState(prevState => ({ ...prevState, emptyVariant: filterData[0]?.value }));
    }
  }

  function setCheckInFilter(filterData: Array<Record<string, any>>, labelKey: string, idKey: string) {
    setFilterState(prevState => ({
      ...prevState,
      [labelKey]:
        filterData.length === 0 ? null : filterData?.map(filterValue => filterValue[idKey] as number | string),
    }));
  }

  return (
    <Page
      title={"Tee Sheet Check-Ins"}
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: saveCheckins,
        onCancel: cancelUnsavedChanges,
      }}
      splitButtonAction={{
        label: "Copy",
        type: "primary",
        onClick: () => setDuplicateState(prevState => ({ ...prevState, showModal: true })),
        options: [
          {
            label: "Copy Entire Day",
            onClick: () => setDuplicateState(prevState => ({ ...prevState, showModal: true, copyEntireDay: true })),
            icon: "clone",
          },
        ],
      }}
      secondaryActions={[
        { content: "Clear", action: () => setCheckInIds([]) },
        { content: "Select All", action: () => selectAllCheckInIds() },
      ]}
    >
      <div className="flex flex-row flex-wrap gap-4 mb-4">
        <DropFilter
          title="Application"
          filterData={[
            { value: "green_fee", title: "Green Fee" },
            { value: "power_cart", title: "Power Cart" },
            { value: "no_show", title: "No Show" },
            { value: "green_fee_league", title: "League Green Fee" },
            { value: "power_cart_league", title: "League Power Cart" },
          ]}
          filterLabelPropFromData="title"
          filterIdPropFromData="value"
          filterType="Checkbox"
          applyFilters={filterData => setCheckInFilter(filterData, "application", "value")}
          leftAligned
        />
        <DropFilter
          title="No Variant"
          filterData={[{ value: 1, title: "No Variant" }]}
          filterLabelPropFromData="title"
          filterIdPropFromData="value"
          filterType="Checkbox"
          applyFilters={setEmptyVariantFilter}
          leftAligned
        />
        <DropFilter
          title={"Holes"}
          filterData={[
            { value: 9, title: "9" },
            { value: 18, title: "18" },
          ]}
          filterLabelPropFromData="title"
          filterIdPropFromData="value"
          filterType="Checkbox"
          applyFilters={filterData => setCheckInFilter(filterData, "holes", "value")}
          leftAligned
        />
        {clientFacilityStore?.facility?.courses?.length > 1 && (
          <DropFilter
            title={"Courses"}
            filterData={[...clientFacilityStore?.facility?.courses]}
            filterLabelPropFromData="long_name"
            filterIdPropFromData="id"
            filterType="Checkbox"
            applyFilters={filterData => setCheckInFilter(filterData, "courses", "id")}
            leftAligned
          />
        )}
      </div>
      <Select
        placeholder={"Filter by customer type..."}
        showSearch
        onSearch={(query: string) =>
          setFilterState(prevState => ({
            ...prevState,
            customerTypeSearch: query,
            selectedCustomerTypeId: query ? prevState.selectedCustomerTypeId : null,
          }))
        }
        onChange={(value: number) => setFilterState(prevState => ({ ...prevState, selectedCustomerTypeId: value }))}
        className="mb-4"
        allowClear
        searchValue={filterState.customerTypeSearch}
        showDropDownOnFocus={true}
      >
        {filterState?.customerTypes
          ?.filter(custType =>
            String(custType.full_title).toLowerCase().includes(filterState.customerTypeSearch.toLowerCase()),
          )
          .map((type: ICustomerType, i: number) => {
            return (
              <Option key={i} value={type.id} name={type.full_title}>
                {type?.full_title}
              </Option>
            );
          })}
      </Select>
      {!!checkInState && checkInState.length > 0 && !!checkInOptionState && checkInOptionState.length > 0 ? (
        <Card>
          <Card.Section table="true">
            <div className="flex">
              <DayTab title={"Monday"} value={Day.Monday} />
              <DayTab title={"Tuesday"} value={Day.Tuesday} />
              <DayTab title={"Wednesday"} value={Day.Wednesday} />
              <DayTab title={"Thursday"} value={Day.Thursday} />
              <DayTab title={"Friday"} value={Day.Friday} />
              <DayTab title={"Saturday"} value={Day.Saturday} />
              <DayTab title={"Sunday"} value={Day.Sunday} />
              <DayTab title={"Holiday"} value={Day.Holiday} />
            </div>
            <table className="ui-table">
              <thead>
                <tr>
                  <th></th>
                  <th>{"Customer Type"}</th>
                  <th></th>
                  <th>{"Pricing Sheet"}</th>
                  <th>{"Variant"}</th>
                  <th>{"Holes"}</th>
                </tr>
              </thead>
              <tbody>
                {!filterState.filterInProgress
                  ? checkInState.map((checkIn: ICheckIn, checkInIndex: number) => {
                      return checkIn.dayOfWeekNumber === filterDayState ? (
                        <tr key={checkInIndex}>
                          <td>
                            <Checkbox
                              onChange={() => handleCheckboxClick(checkIn?.id)}
                              size="medium"
                              checked={checkInIds?.includes(checkIn?.id)}
                            />
                          </td>
                          <td>{checkIn.customer_type?.full_title}</td>
                          <td>
                            <Badge type={checkIn.pricingSheetApplication === "green_fee" ? "success" : "gray"}>
                              {capitalize(checkIn.pricingSheetApplication)}
                            </Badge>
                          </td>

                          <td>
                            {checkIn.pricingSheetCourse} - {checkIn.pricingSheet}
                          </td>
                          <td className="overflow-visible">
                            <Select
                              key={checkIn.variantId}
                              onChange={(value: any) => handleDropDownChange(value, "variantId", checkIn, checkInIndex)}
                              defaultValue={checkIn.variantId}
                            >
                              {getVariants(checkIn.pricingSheetId).map((variantGroup: IVariant[], index: number) => {
                                return (
                                  <React.Fragment key={index}>
                                    <p style={{ padding: "8px 16px" }}>
                                      <b>{variantGroup[0].productTitle}</b>
                                    </p>
                                    {variantGroup.map((variant: IVariant, index: number) => {
                                      return (
                                        <Option key={index} value={variant.id} name={variant.type}>
                                          <span className="ml-1">
                                            {variant.productTitle}
                                            {variant.type !== variant.productTitle ? (
                                              <span> - {variant.type}</span>
                                            ) : null}
                                          </span>
                                        </Option>
                                      );
                                    })}
                                  </React.Fragment>
                                );
                              })}
                            </Select>
                          </td>

                          <td className="overflow-visible">
                            <Select
                              onChange={(value: any) => handleDropDownChange(value, "holes", checkIn, checkInIndex)}
                              defaultValue={checkIn.holes}
                            >
                              {holesTypes.map((type: number, index: number) => {
                                return (
                                  <Option key={index} value={type} name={String(type)}>
                                    <span>{`${type} holes`}</span>
                                  </Option>
                                );
                              })}
                            </Select>
                          </td>
                        </tr>
                      ) : null;
                    })
                  : null}
              </tbody>
            </table>
          </Card.Section>
        </Card>
      ) : null}

      <div className="duplicate-sheet-overflow">
        <Sheet
          open={duplicateState?.showModal}
          size="small"
          closable
          title={"Copy Check In Configurations"}
          onCancel={() => setDuplicateState(prevState => ({ ...prevState, showModal: false, copyEntireDay: false }))}
          onOk={handleDuplicate}
          cancelText={"Cancel"}
          okText={"Copy"}
          okDisabled={
            !duplicateState.selectedFacilityId ||
            (checkInIds?.length === 0 && !duplicateState.copyEntireDay) ||
            !duplicateState.selectedDayOfWeek
              ? true
              : false
          }
        >
          {clientFacilityStore?.facilities && (
            <Select
              onChange={(value: string | number, extraValues: Record<string, any>) =>
                handleDuplicateDropdownChange(value, "selectedFacilityId", extraValues)
              }
              label="Facility to Duplicate to"
              defaultValue={clientFacilityStore?.facility?.id}
              className="mb-4"
            >
              {clientFacilityStore?.facilities?.map((facility, index) => {
                return (
                  <Option key={index} value={facility?.id} extraValues={facility}>
                    {facility?.full_name}
                  </Option>
                );
              })}
            </Select>
          )}

          {duplicateState?.selectedFacility?.courses?.length > 1 && (
            <Select
              onChange={(value: number) => handleCourseChange(value)}
              label="Course"
              defaultValue={duplicateState?.selectedFacility?.courses[0]?.id}
              className="mb-4"
            >
              {duplicateState?.selectedFacility?.courses?.map((course: Record<string, any>, index: number) => {
                return (
                  <Option key={index} value={course?.id}>
                    {course?.full_name}
                  </Option>
                );
              })}
            </Select>
          )}

          <Select onChange={(value: string) => handleDayOfWeekChange(value)} label={"Day of Week"}>
            <Option value="Monday">{"Monday"}</Option>
            <Option value="Tuesday">{"Tuesday"}</Option>
            <Option value="Wednesday">{"Wednesday"}</Option>
            <Option value="Thursday">{"Thursday"}</Option>
            <Option value="Friday">{"Friday"}</Option>
            <Option value="Saturday">{"Saturday"}</Option>
            <Option value="Sunday">{"Sunday"}</Option>
            <Option value="Holiday">{"Holiday"}</Option>
          </Select>

          {checkInIds?.length === 0 && !duplicateState.copyEntireDay && (
            <Callout
              type="error"
              title="No Check In Configurations Selected"
              content="Select the check boxes of the check in configurations you wish to duplicate and try again"
            />
          )}
        </Sheet>
      </div>
    </Page>
  );
}
