import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ButtonNew as Button } from "components/buttonNew";

import "./depositCallout.scss";

export type DepositCalloutProps = {
  type: NoticeType;
  title: string;
  content?: string;
  actions?: NoticeActions;
  /** Displays X in top-right corner. */
  onClose?: () => void;
  /** Displays Save button to the slight left of onCLose position */
  onSave?: () => void;
  /** Overrides default styling:
   * 1) display ICON to be strictly info circle
   * 2) onClose button becomes 'DISMISS'
   * 3) disables border
   */
  toast?: boolean;
  applied: boolean;
};

type ActionClick = {
  label?: string;
  onClick: () => void;
  disabled?: boolean;
};

/** Primary action is given bolded text + right-arrow Icon */
type NoticeActions = {
  primary?: ActionClick;
  secondary?: ActionClick;
};

export type NoticeType = "primary" | "info" | "success" | "warning" | "error";

export default function DepositCallout(props: DepositCalloutProps) {
  const { type, title = "", content, onClose, onSave, actions, toast } = props;

  return (
    <div className={`deposit-notice-bar-container ${type} ${toast ? "is-toast" : ""}`}>
      <div className="deposit-notice-bar">
        <span className={`deposit-icon-wrap${!actions ? " flexed" : ""}`}>
          <GeneratedNoticeIcon noticeType={props.applied ? "success" : type} isToast={toast} />
        </span>
        <span className="deposit-content-wrap">
          <div className="flex flex-col">
            <p className="deposit-title">{title}</p>
            {content && (
              <p className="deposit-content" style={!actions ? { paddingBottom: "0" } : undefined}>
                {content}
              </p>
            )}
          </div>
          {actions && (
            <span className="deposit-action-wrap">
              {actions.primary && (
                <Button
                  size="small"
                  className="deposit-primary"
                  onClick={actions.primary.onClick}
                  disabled={actions.primary.disabled}
                >
                  {actions.primary.label}
                </Button>
              )}
            </span>
          )}
        </span>
      </div>
    </div>
  );
}

const GeneratedNoticeIcon = ({ noticeType, isToast }: { noticeType: NoticeType; isToast: boolean }) => {
  if (isToast) {
    return <FontAwesomeIcon icon={["far", "info-circle"]} />;
  }

  switch (noticeType) {
    case "primary": {
      return <FontAwesomeIcon icon={["far", "info-circle"]} />;
    }
    case "info": {
      return <FontAwesomeIcon icon={["far", "info-circle"]} />;
    }
    case "success": {
      return <FontAwesomeIcon icon={["fas", "check"]} />;
    }
    case "error": {
      return <FontAwesomeIcon icon={["far", "exclamation-circle"]} />;
    }
    case "warning": {
      return <FontAwesomeIcon icon={["far", "exclamation-triangle"]} />;
    }
  }
};
