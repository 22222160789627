import React, { createContext, useContext, useEffect, useState } from "react";

import { StatusCode } from "api/protocols";
import { UserActive } from "api/rpc";
import { IUser, IUserActive } from "redux/reducers/models/user";
import { useParams } from "react-router";

export interface IGuestPortalContextProps {
  /**
   * - Undefined  ??  Fresh reload
   * - Null  ??  No active user
   */
  guest?: IUser | IUserActive;
  loading?: boolean;
}

const defaultState: PortalContextType = {
  portalState: {
    guest: undefined,
    loading: false,
  },
  updatePortalState: null,
};

type PortalContextType = {
  portalState: IGuestPortalContextProps;
  updatePortalState: (curr: Partial<IGuestPortalContextProps>) => void;
};

interface IUrlParams {
  clientShortName: string;
}

const GuestPortalContext = createContext<PortalContextType>(defaultState);
export const useGuestPortalContext = () => useContext(GuestPortalContext);

/**
 *  Determines if active user.  Syncs found active user to state.
 *  - portalState.guest will not be null to children.
 * @returns portalState
 * @returns updatePortalState (curr: portalState) => void
 *
 */
export const GuestPortalProvider = (props: IGuestPortalContextProps & { children: any }) => {
  const { clientShortName } = useParams<IUrlParams>();
  const [portalState, setPortalState] = useState<IGuestPortalContextProps>({
    guest: undefined,
    loading: true,
  });

  useEffect(() => {
    let mounted = true;

    if (portalState.guest === undefined) {
      void checkActiveUser(mounted);
    }

    return () => {
      mounted = false;
    };
  }, [portalState.guest]);

  async function checkActiveUser(stillMounted: boolean) {
    if (!portalState.loading) {
      updatePortalState({ loading: true });
    }
    const activeUserRes = await UserActive(false);

    if (activeUserRes.status === StatusCode.OK && stillMounted) {
      // Sync active user to context
      updatePortalState({ guest: activeUserRes?.data, loading: false });
      console.log("user re-initialized, ", activeUserRes?.data); //debug
    } else {
      // No active user
      updatePortalState({ guest: null, loading: false });
    }
  }

  function updatePortalState(updatedState: Partial<IGuestPortalContextProps>) {
    setPortalState(currentState => {
      return { ...currentState, ...updatedState };
    });
  }

  return (
    <GuestPortalContext.Provider value={{ portalState, updatePortalState }}>
      {props.children}
    </GuestPortalContext.Provider>
  );
};
