import { StatusCode } from "api/protocols";
import { AppDispatch } from "index";
import { IBookingEngineState } from "redux/reducers/bookingEngine";
import { BookingEngineActionTypes } from "./actionTypes";

export const update = (field: Partial<IBookingEngineState>) => (dispatch: AppDispatch) => {
  dispatch({
    type: BookingEngineActionTypes.UPDATE,
    payload: {
      ...field,
    },
  });
};
