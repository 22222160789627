import React, { useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { convertTime, capitalize, isEmpty } from "helpers/Helpers";

import { ITeeTimeBookingCategoryUpdate } from "api/rpc/teeSheet/template";
import { GetTeeSheetTemplate, PutTeeSheetTemplate } from "api/rpc/2024-04/facilityAdmin/teesheet/teesheetTemplate";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import { Badge } from "components/badge/Badge";

import { cloneDeep, isEqualWith, range } from "lodash";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import { NotificationType } from "components/notificationBar/NotificationBar";
import Select from "components/form/select/Select";

import { Select as SelectNew } from "components/select/index";
import Checkbox from "components/form/checkbox/Checkbox";
import "./TeeSheetTemplate.scss";
import { IFacilityState } from "redux/reducers/facility";
import { ButtonNew as Button } from "components/buttonNew";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tabs from "components/tabs/Tabs";
import { GetBookingCategory } from "api/rpc/facilityAdmin/teesheet/bookingCategory";
import { IBookingCategory, ITeeTime } from "redux/reducers/models/teetime";

import { useTranslation, Trans } from "react-i18next";
import { DeleteTeeSheetTemplate } from "api/rpc/2022-09/facilityAdmin/teesheet/teeSheet";
import Popup from "components/popup/Popup";
import { IUIActions, dequeue, enqueue, showError } from "redux/actions/ui";
import Icon from "components/icon/Icon";
import { useAppDispatch } from "hooks/redux";

import { ITournament } from "redux/reducers/models/tournament";
import { ILeague } from "redux/reducers/models/league";
import moment from "moment";
import TextField from "components/form/textField/TextField";
import useKeyPress from "hooks/events/useKeyPress";
import { GetLeague } from "api/rpc/2024-04/facilityAdmin/league/league";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import { GetTournament } from "api/rpc/2024-04/facilityAdmin/tournament/tournament";

interface ITeeSheetTemplateProps {
  facilityStore: IFacilityState;
  uiActions: IUIActions;
}

interface ITemplateState {
  id: number;
  interval: number;
  end_time: string;
  holes: number;
  format: string;
  name: string;
  start_time: string;
  turn_time: number;
  facility_id: number;
  course_id: number;
  list_of_teetime_ids: any;
  tee_times: ITeeTime[];
  divisions_list: any;
  description: string;
}

interface ISettingsState {
  turn_division_id: string;
  pre_paid_required: boolean;
  credit_card_required: boolean;
  web_booking_enabled: boolean;
  blocked_type: string;
  cart_rule: string;
  league_id: string;
  tournament_id: string;
  divisions_available: number[];
  booking_category_ids: number[];
  leagues: ILeague[];
  tournaments: ITournament[];
  booking_categories: IBookingCategory[];
  selectedTeetimes: number[];
  openDeleteTemplate: boolean;
}

interface IFilterState {
  tournamentSearchValue: string;
  leagueSearchValue: string;
  tournaments: Array<ITournament>;
  tournamentSearching: boolean;
  selectedTournament: ITournament;
  leagues: Array<ILeague>;
  leagueSearching: boolean;
  selectedLeague: ILeague;
  selectAllTeetimes: boolean;
}

export default function TeeSheetTemplate(props: ITeeSheetTemplateProps) {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { uiActions } = props;
  const { t, i18n } = useTranslation();
  const { templateId }: any = useParams();
  const filteredTeeTimes: any = [];
  const { Option } = SelectNew;

  const [templateStateBeforeChanges, setTemplateStateBeforeChanges] = useState<ITemplateState>(undefined);
  const [finishedPageLoading, setFinishedPageLoading] = useState<boolean>(false);

  const [templateState, setTemplateState] = useState<ITemplateState>({
    id: null,
    interval: null,
    end_time: "",
    holes: null,
    format: "",
    name: "",
    start_time: "",
    turn_time: null,
    facility_id: null,
    course_id: null,
    list_of_teetime_ids: [],
    tee_times: [],
    divisions_list: [],
    description: "",
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    tournamentSearchValue: "",
    leagueSearchValue: "",
    tournaments: [],
    tournamentSearching: false,
    selectedTournament: null,
    leagues: [],
    leagueSearching: false,
    selectedLeague: null,
    selectAllTeetimes: false,
  });

  const [settingsStateBeforeChanges, setSettingsStateBeforeChanges] = useState<ISettingsState>(undefined);

  const [settingsState, setSettingsState] = useState<ISettingsState>({
    turn_division_id: "",
    pre_paid_required: null,
    credit_card_required: null,
    web_booking_enabled: null,
    blocked_type: "",
    cart_rule: "",
    league_id: "",
    tournament_id: "",
    divisions_available: [],
    booking_category_ids: [],
    leagues: [],
    tournaments: [],
    booking_categories: [],
    selectedTeetimes: [],
    openDeleteTemplate: false,
  });

  const [selectedTab, setSelectedTab] = useState<number>(0);

  const shiftPressed = useKeyPress(["ShiftLeft", "ShiftRight"]);

  useEffect(() => {
    if (props.facilityStore.facility) {
      void loadTemplatePage();
    }
  }, [props.facilityStore.facility]);

  async function loadTemplatePage() {
    dispatch(enqueue());

    try {
      const [leagueRes, tournamentRes, bookingCategoryRes, templateRes] = await Promise.all([
        GetLeague(null, false),
        GetTournament(null, false),
        GetBookingCategory(null, false),
        GetTeeSheetTemplate({ id: templateId, extended: true }, false),
      ]);

      if (leagueRes.status !== StatusCode.OK) {
        props.uiActions.showError("Error when loading leagues");
        return;
      }

      if (tournamentRes.status !== StatusCode.OK) {
        props.uiActions.showError("Error when loading tournaments");
        return;
      }

      if (bookingCategoryRes.status !== StatusCode.OK) {
        props.uiActions.showError("Error when loading booking category");
        return;
      }

      if (templateRes.status !== StatusCode.OK) {
        props.uiActions.showError("Error when loading template");
        return;
      }

      setSettingsState(prev => ({
        ...prev,
        leagues: leagueRes.data,
        tournaments: tournamentRes.data,
        booking_categories: bookingCategoryRes.data,
      }));

      const template = templateRes.data[0];

      const usedLeagueIds: Set<number> = new Set();
      const usedTournamentIds: Set<number> = new Set();

      template.tee_times.forEach(teeTime => {
        if (teeTime.league_id) {
          usedLeagueIds.add(teeTime.league_id);
        }
        if (teeTime.tournament_id) {
          usedTournamentIds.add(teeTime.tournament_id);
        }
      });

      const [usedLeaguesRes, usedTournamentsRes] = await Promise.all([
        GetLeague({ ids: Array.from(usedLeagueIds) }, false),
        GetTournament({ ids: Array.from(usedTournamentIds) }, false),
      ]);

      if (usedLeaguesRes.status !== StatusCode.OK) {
        props.uiActions.showError("Error when loading leagues");
        return;
      }

      if (usedTournamentsRes.status !== StatusCode.OK) {
        props.uiActions.showError("Error when loading tournaments");
        return;
      }

      const teeTimes: ITeeTime[] = template.tee_times.map(teeTime => ({
        ...teeTime,
        league:
          teeTime?.league_id != null ? usedLeaguesRes.data.find(league => league?.id === teeTime.league_id) : null,
        tournament:
          teeTime?.tournament_id != null
            ? usedTournamentsRes.data.find(tournament => tournament?.id === teeTime.tournament_id)
            : null,
      }));

      const divisions_list =
        props.facilityStore.facility.courses?.find(course => course?.id === template?.course_id)?.divisions ?? [];

      setTemplateState(prev => ({
        ...prev,
        id: template.id,
        interval: template.interval,
        end_time: template.end_time,
        holes: template.holes,
        format: template.format,
        name: template.name,
        start_time: template.start_time,
        turn_time: template.turn_time,
        tee_times: teeTimes,
        course_id: template.course_id,
        divisions_list: divisions_list,
        description: template.description,
      }));

      setFinishedPageLoading(true);
    } finally {
      dispatch(dequeue());
    }
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setTemplateState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleSettingsChange(event: any) {
    const { id, value } = event.target;

    setSettingsState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleBookingCategoryChange(event: any) {
    const value = parseInt(event?.target?.value);
    if (isNaN(value)) {
      return;
    }

    if (settingsState.booking_category_ids.find(bookingCategoryId => bookingCategoryId === value) === undefined) {
      setSettingsState(prevState => ({
        ...prevState,
        booking_category_ids: [...prevState.booking_category_ids, value],
      }));
    } else {
      const updatedBookingCategoryIds = settingsState.booking_category_ids.filter(
        bookingCategoryId => bookingCategoryId !== value,
      );
      setSettingsState(prevState => ({ ...prevState, booking_category_ids: updatedBookingCategoryIds }));
    }
  }

  function handleDivisionsAvailableChange(event: any) {
    const value = parseInt(event?.target?.value);
    if (isNaN(value)) {
      return;
    }

    if (settingsState.divisions_available.find(divisionsAvailable => divisionsAvailable === value) === undefined) {
      setSettingsState(prevState => ({
        ...prevState,
        divisions_available: [...prevState.divisions_available, value].sort((a, b) => a - b),
      }));
    } else {
      const updatedDivisionsAvailable = settingsState.divisions_available.filter(
        divisionsAvailable => divisionsAvailable !== value,
      );
      setSettingsState(prevState => ({
        ...prevState,
        divisions_available: updatedDivisionsAvailable.sort((a, b) => a - b),
      }));
    }
  }

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
  };

  function unsavedChangesExist() {
    if (templateStateBeforeChanges === undefined || settingsStateBeforeChanges === undefined) {
      if (finishedPageLoading) {
        setTemplateStateBeforeChanges(templateState);
        setSettingsStateBeforeChanges(settingsState);
      }

      return false;
    }

    return !isEqualWith(templateStateBeforeChanges, templateState);
  }

  function cancelUnsavedChanges() {
    setTemplateState(templateStateBeforeChanges);
  }

  async function saveTemplate() {
    const updatedTeetimes: ITeeTime[] = templateState.tee_times?.filter((teetime, index: number) => {
      return !isEqualWith(teetime, templateStateBeforeChanges.tee_times[index]);
    });

    const updatedTeetimesWithBookingCategories: ITeeTimeBookingCategoryUpdate[] = updatedTeetimes.map(
      updatedTeetime => {
        return {
          ...updatedTeetime,
          booking_category_ids: updatedTeetime.booking_categories?.map(bookingCategory => bookingCategory.id) ?? [],
        };
      },
    );

    for (let i = 0; i < updatedTeetimesWithBookingCategories.length; i++) {
      if (
        updatedTeetimesWithBookingCategories[i].tournament_id !== null &&
        updatedTeetimesWithBookingCategories[i].league_id !== null
      ) {
        props.uiActions.showError("Teetime cannot have both a league and tournament");
        return;
      }
    }

    const templateRes = await PutTeeSheetTemplate(
      {
        id: templateState.id,
        name: templateState.name,
        tee_times: updatedTeetimesWithBookingCategories,
        description: templateState.description,
      },
      true,
    );

    if (templateRes.status !== StatusCode.OK) {
      return;
    }

    setTemplateStateBeforeChanges(templateState);
    setFilterState(prevState => ({
      ...prevState,
      leagueSearchValue: "",
      tournamentSearchValue: "",
      selectedLeague: null,
      selectedTournament: null,
    }));
  }

  const formatOptions = [
    { label: "", value: "" },
    { label: "Standard", value: "standard", disbaled: false },
    { label: "Crossover", value: "crossover", disbaled: true },
  ];

  const intervalOptions = [
    { label: "7 mins", value: 7 },
    { label: "7.5 mins", value: 7.5 },
    { label: "8 mins", value: 8 },
    { label: "8.5 mins", value: 8.5 },
    { label: "9 mins", value: 9 },
    { label: "9.5 mins", value: 9.5 },
    { label: "10 mins", value: 10 },
    { label: "10.5 mins", value: 10.5 },
    { label: "11 mins", value: 11 },
    { label: "11.5 mins", value: 11.5 },
    { label: "12 mins", value: 12 },
  ];

  const blockedTypeOptions = [
    { label: "", value: "" },
    { label: t("secure.facility.settings.tee_sheets.tee_sheet_template.001"), value: "open" },
    { label: t("secure.facility.settings.tee_sheets.tee_sheet_template.002"), value: "blocked" },
    { label: "Turn", value: "crossover" }, // TODO: Translation
  ];

  const cartRuleOptions = [
    { label: "", value: "" },
    { label: t("secure.facility.settings.tee_sheets.tee_sheet_template.004"), value: "none" },
    { label: t("secure.facility.settings.tee_sheets.tee_sheet_template.005"), value: "optional" },
    { label: t("secure.facility.settings.tee_sheets.tee_sheet_template.006"), value: "required" },
  ];

  const divisionOptions = [
    { label: t("secure.facility.settings.tee_sheets.tee_sheet_template.007"), value: 1 },
    { label: t("secure.facility.settings.tee_sheets.tee_sheet_template.008"), value: 2 },
  ];

  function handleCheckboxChange(event: any) {
    const { id, checked, value } = event.target;
    console.log(id, " ", value);
    if (!checked) {
      setSettingsState(prevState => ({ ...prevState, [id]: null }));
    } else {
      setSettingsState(prevState => ({ ...prevState, [id]: value === "true" ? checked : !checked }));
    }
  }

  function handleSelectAllCheckboxChange(event: any) {
    const { id, checked, value } = event.target;

    if (!checked) {
      void unselectAll();
    } else {
      void selectAll();
    }
  }

  const onSelectedTeetimeChange = (newId: number) => {
    if (newId === undefined || isNaN(newId)) {
      return;
    }

    if (!shiftPressed) {
      if (settingsState.selectedTeetimes.some(id => id === newId)) {
        const updatedSelections = settingsState.selectedTeetimes
          .filter(teetimeId => {
            if (teetimeId === newId) {
              return false;
            } else {
              return true;
            }
          })
          .map(teetimetId => Number(teetimetId));

        setSettingsState(prevState => ({ ...prevState, selectedTeetimes: updatedSelections }));
      } else {
        setSettingsState(prevState => ({ ...prevState, selectedTeetimes: [...prevState.selectedTeetimes, newId] }));
      }
    } else {
      if (!settingsState.selectedTeetimes.some(id => id === newId)) {
        const selectedTeetime = templateState?.tee_times?.find(teetime => teetime?.id === newId);
        const divisionTeetimes = templateState?.tee_times.filter(
          teetime => teetime?.division_id === selectedTeetime?.division_id,
        );
        const selectedTeetimeDivisionIndex = divisionTeetimes?.findIndex(
          teetime => teetime?.id === selectedTeetime?.id,
        );

        if (
          selectedTeetime === undefined ||
          divisionTeetimes === undefined ||
          selectedTeetimeDivisionIndex === undefined ||
          selectedTeetimeDivisionIndex === -1
        ) {
          return;
        }

        let previousSelectedDivisionIndex = undefined;

        for (let i = selectedTeetimeDivisionIndex - 1; i >= 0 && previousSelectedDivisionIndex === undefined; i--) {
          if (settingsState?.selectedTeetimes?.some(id => id === divisionTeetimes[i]?.id)) {
            previousSelectedDivisionIndex = i;
          }
        }

        if (previousSelectedDivisionIndex === undefined) {
          return;
        }

        const selectedDivisionIndexes = range(previousSelectedDivisionIndex + 1, selectedTeetimeDivisionIndex + 1);

        const selectedTeetimes = selectedDivisionIndexes.map(index => {
          return divisionTeetimes?.[index]?.id;
        });

        setSettingsState(prevState => ({
          ...prevState,
          selectedTeetimes: [...prevState.selectedTeetimes, ...selectedTeetimes],
        }));
      }
    }
  };

  function applySettings() {
    if (settingsState.selectedTeetimes === undefined || settingsState.selectedTeetimes.length === 0) {
      props.uiActions.showError("Tee times must be selected");
      return;
    }

    const selectedBookingCategories: IBookingCategory[] = [];
    const updatedSelectedTeetimes: ITeeTime[] = [];

    settingsState.booking_category_ids.map(bookingCategoryId => {
      const selectedBookingCategory = settingsState.booking_categories.find(
        bookingCategory => bookingCategory.id === bookingCategoryId,
      );
      if (selectedBookingCategory) {
        selectedBookingCategories.push(selectedBookingCategory);
      }
    });

    settingsState.selectedTeetimes?.map(selectedId => {
      const selectedTeetime = templateState.tee_times?.find(teetime => teetime?.id === selectedId);

      if (selectedTeetime === undefined) {
        return;
      }

      let league_id: number = null;
      let league: ILeague = null;

      if (settingsState?.league_id === "none") {
        league_id = null;
        league = null;
      } else if (isNaN(parseInt(settingsState?.league_id))) {
        league_id = selectedTeetime.league_id;
        league = selectedTeetime.league;
      } else {
        league_id = parseInt(settingsState.league_id);

        const filterLeagueMatch = filterState.leagues?.find(league => league.id === league_id);
        const settingsLeagueMatch = settingsState.leagues?.find(league => league.id === league_id);

        if (filterLeagueMatch) {
          league = cloneDeep(filterLeagueMatch);
        } else if (settingsLeagueMatch) {
          league = cloneDeep(settingsLeagueMatch);
        } else {
          league_id = selectedTeetime.league_id;
          league = selectedTeetime.league;
        }
      }

      let tournament_id: number = null;
      let tournament: ITournament = null;

      if (settingsState?.tournament_id === "none") {
        tournament_id = null;
        tournament = null;
      } else if (isNaN(parseInt(settingsState?.tournament_id))) {
        tournament_id = selectedTeetime.tournament_id;
        tournament = selectedTeetime.tournament;
      } else {
        tournament_id = parseInt(settingsState.tournament_id);

        const filterTournamentMatch = filterState.tournaments?.find(tournament => tournament.id === tournament_id);
        const settingsTournamentMatch = settingsState.tournaments?.find(
          tournament => tournament.id === tournament_id,
        );

        if (filterTournamentMatch) {
          tournament = cloneDeep(filterTournamentMatch);
        } else if (settingsTournamentMatch) {
          tournament = cloneDeep(settingsTournamentMatch);
        } else {
          tournament_id = selectedTeetime.tournament_id;
          tournament = selectedTeetime.tournament;
        }
      }

      const updatedSelectedTeetime: ITeeTime = {
        ...selectedTeetime,
        blocked_type: isEmpty(settingsState?.blocked_type) ? selectedTeetime.blocked_type : settingsState?.blocked_type,
        cart_rule: isEmpty(settingsState?.cart_rule) ? selectedTeetime.cart_rule : settingsState?.cart_rule,
        league_id,
        league,
        tournament_id,
        tournament,
        turn_division_id: isNaN(parseInt(settingsState?.turn_division_id))
          ? selectedTeetime.turn_division_id
          : parseInt(settingsState?.turn_division_id),
        pre_paid_required: isEmpty(settingsState?.pre_paid_required)
          ? selectedTeetime.pre_paid_required
          : settingsState?.pre_paid_required,
        credit_card_required: isEmpty(settingsState?.credit_card_required)
          ? selectedTeetime.credit_card_required
          : settingsState?.credit_card_required,
        divisions_available:
          settingsState.divisions_available.length === 0
            ? selectedTeetime.divisions_available
            : settingsState.divisions_available,
        booking_categories:
          settingsState.booking_category_ids.length === 0
            ? selectedTeetime.booking_categories
            : selectedBookingCategories,
        web_booking_enabled: isEmpty(settingsState?.web_booking_enabled)
          ? selectedTeetime.web_booking_enabled
          : settingsState?.web_booking_enabled,
      };

      updatedSelectedTeetimes.push(updatedSelectedTeetime);
    });

    const updatedTeetimes = templateState.tee_times?.map(originalTeetime => {
      const updatedTeetime = updatedSelectedTeetimes.find(
        updatedTeetime => updatedTeetime?.id === originalTeetime?.id,
      );
      return updatedTeetime ?? originalTeetime;
    });

    for (let i = 0; i < updatedTeetimes.length; i++) {
      if (updatedTeetimes[i].tournament_id != null && updatedTeetimes[i].league_id != null) {
        props.uiActions.showError("Teetime cannot have both a league and tournament");
        return;
      }
    }

    setSettingsState(settingsStateBeforeChanges);
    setTemplateState(prevState => ({ ...prevState, tee_times: updatedTeetimes ?? prevState.tee_times }));
    setFilterState(prevState => ({
      ...prevState,
      leagueSearchValue: "",
      tournamentSearchValue: "",
      selectedLeague: null,
      selectedTournament: null,
    }));
  }

  function clearSettings() {
    setSettingsState(prevState => ({ ...settingsStateBeforeChanges, selectedTeetimes: prevState.selectedTeetimes }));
  }

  function selectAll() {
    const everyTeeTimeId =
      selectedTab !== templateState.divisions_list.length
        ? templateState.tee_times
            .filter(teetime => teetime.division_id === templateState.divisions_list[selectedTab].id)
            .map(teetime => {
              return teetime?.id;
            })
        : templateState.tee_times.map(teetime => {
            return teetime?.id;
          });
    setSettingsState(prevState => ({ ...prevState, selectedTeetimes: everyTeeTimeId }));
    setFilterState(prevState => ({ ...prevState, selectAllTeetimes: true }));
  }

  function unselectAll() {
    setSettingsState(prevState => ({ ...prevState, selectedTeetimes: [] }));
    setFilterState(prevState => ({ ...prevState, selectAllTeetimes: false }));
  }

  templateState.divisions_list?.forEach((division: any, i: number) => {
    const teeTimesByDivision: any[] = [];

    {
      templateState.tee_times
        .filter(teetime => teetime.division_id === division.id)
        .forEach((teetime, index: number) => {
          teeTimesByDivision.push(teetime);
        });
    }

    filteredTeeTimes.push(teeTimesByDivision);
  });

  const MemoizedTeetimes: JSX.Element = useMemo(() => {
    if (templateState.tee_times === undefined || templateState.tee_times.length === 0) {
      return <div>{t("secure.facility.settings.tee_sheets.tee_sheet_template.009")}</div>;
    } else {
      const tabs = templateState.divisions_list.map((division: any) => {
        return {
          id: `${String(division.title)} - ${String(division.id)}`,
          content: division.title,
        };
      });

      const condensedView = Number(tabs.length);

      tabs.push({
        id: "Condensed View",
        content: "All",
      });

      return (
        <Card title={t("secure.facility.settings.tee_sheets.tee_sheet_template.035")}>
          <Card.Section>
            <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange} />
          </Card.Section>
          <Card.Section table="true">
            {templateState.divisions_list.map((division: any, index: number) => {
              if (selectedTab === index) {
                return (
                  <DataTable
                    columns={[
                      {
                        label: t("secure.facility.tee_sheet.tee_sheet_edit.034"),
                        content: (
                          <div className="flex">
                            <Checkbox
                              id="selectAllTeetimes"
                              size="medium"
                              checked={filterState.selectAllTeetimes}
                              onChange={handleSelectAllCheckboxChange}
                              labelHidden
                            />

                            <span className="shotgun-template-edit-label">
                              {"Time"}
                              {/* TODO: Translation */}
                            </span>
                          </div>
                        ),
                      },
                      { label: "" },
                      { label: t("secure.facility.tee_sheet.tee_sheet_edit.035") },
                      { label: t("secure.facility.tee_sheet.tee_sheet_edit.036") },
                      { label: "" },
                      { label: "" },
                      { label: t("secure.facility.tee_sheet.tee_sheet_edit.037") },
                      { label: t("secure.facility.tee_sheet.tee_sheet_edit.038") },
                      { label: t("secure.facility.tee_sheet.tee_sheet_edit.039") },
                      { label: "Web Booking" }, // TODO: Translation
                    ]}
                  >
                    {templateState.tee_times
                      .filter(teetime => teetime.division_id === division.id)
                      .map((teetime, index: number) => {
                        const divisionsListLabel = templateState.divisions_list.find(
                          (division: any) => division.id === teetime.turn_division_id,
                        )?.title;

                        const divisionsAvailableLabels: string[] = [];

                        teetime.divisions_available.map(divisionAvailable => {
                          const divisionLabel = divisionOptions.find(
                            divisionOption => divisionOption.value === divisionAvailable,
                          )?.label;
                          if (divisionLabel) {
                            divisionsAvailableLabels.push(divisionLabel);
                          }
                        });

                        return (
                          <tr
                            key={teetime.id}
                            className="clickable prevent_select"
                            onClick={e => {
                              e.stopPropagation();
                              onSelectedTeetimeChange(teetime.id);
                            }}
                          >
                            <td>
                              <Checkbox
                                id={`teetime ${index}`}
                                size="medium"
                                checked={settingsState.selectedTeetimes.some(id => id === teetime.id)}
                                label={convertTime(teetime.start_time)}
                                readOnly
                              />
                            </td>
                            <td>
                              {teetime.blocked_type === "open" ? (
                                <Badge type="success">{capitalize(teetime.blocked_type)}</Badge>
                              ) : null}

                              {teetime.blocked_type === "blocked" ? (
                                <Badge type="warning">{capitalize(teetime.blocked_type)}</Badge>
                              ) : null}
                              {teetime.blocked_type === "crossover" ? <Badge type="gray">{"Turn"}</Badge> : null}
                            </td>
                            <td>
                              <Badge type="gray">{capitalize(teetime.cart_rule)}</Badge>
                            </td>
                            <td>
                              {teetime.tournament ? (
                                <Badge type="gray" iconLeft icon="trophy" iconStyle="far">
                                  {teetime.tournament.name}
                                </Badge>
                              ) : null}
                              {teetime.league ? (
                                <Badge type="gray" iconLeft icon="users" iconStyle="far">
                                  {teetime.league.name}
                                </Badge>
                              ) : null}
                            </td>
                            <td>
                              {teetime.pre_paid_required ? (
                                <Badge type="warning" iconLeft icon="dollar-sign" iconStyle="far">
                                  {t("secure.facility.tee_sheet.tee_sheet_edit.042")}
                                </Badge>
                              ) : null}
                            </td>
                            <td>
                              {teetime.credit_card_required ? (
                                <Badge type="error" iconLeft icon="credit-card-front" iconStyle="far">
                                  {t("secure.facility.tee_sheet.tee_sheet_edit.043")}
                                </Badge>
                              ) : null}
                            </td>
                            <td>
                              {divisionsAvailableLabels.length > 0 ? (
                                <span>{divisionsAvailableLabels.reduce((prev, next) => prev + ", " + next)}</span>
                              ) : null}
                            </td>
                            <td>
                              {teetime.booking_categories && teetime.booking_categories.length > 0 ? (
                                <span>
                                  {teetime.booking_categories
                                    .map(bookingCategory => bookingCategory.title)
                                    .sort()
                                    .reduce((prev, next) => prev + ", " + next)}
                                </span>
                              ) : null}
                            </td>
                            <td>
                              {teetime.turn_division_id && divisionsListLabel ? (
                                <span>
                                  <FontAwesomeIcon className="mr-2" icon={["far", "arrow-right"]} />
                                  {divisionsListLabel}
                                </span>
                              ) : null}
                            </td>
                            <td>
                              {teetime.web_booking_enabled ? (
                                <span>
                                  <FontAwesomeIcon className="mr-2" icon={["far", "globe"]} />
                                </span>
                              ) : null}
                            </td>
                          </tr>
                        );
                      })}
                  </DataTable>
                );
              }

              if (selectedTab === condensedView && selectedTab === index + 1) {
                return (
                  <div key={index} className="flex justify-between w-full">
                    {filteredTeeTimes.map((teetimeArray: any, index: number) => {
                      return (
                        <div key={index} className="w-full items-center">
                          <div>{teetimeArray[index]?.division.title}</div>

                          <DataTable
                            columns={[
                              {
                                label: "Time",
                                content: (
                                  <div className="flex">
                                    <Checkbox
                                      id="selectAllTeetimes"
                                      size="medium"
                                      checked={filterState.selectAllTeetimes}
                                      onChange={handleSelectAllCheckboxChange}
                                      labelHidden
                                    />

                                    <span className="shotgun-template-edit-label">
                                      {"Time"}
                                      {/* TODO: Translation */}
                                    </span>
                                  </div>
                                ),
                              }, // TODO: Translation
                              { label: "" },
                              { label: t("secure.facility.tee_sheet.tee_sheet_edit.039") },
                            ]}
                          >
                            {teetimeArray.map((teetime: any, i: number) => {
                              const divisionsListLabel = templateState.divisions_list.find(
                                (division: any) => division.id === teetime.turn_division_id,
                              )?.title;

                              const divisionsAvailableLabels: string[] = [];

                              teetime.divisions_available.map((divisionAvailable: number) => {
                                const divisionLabel = divisionOptions.find(
                                  divisionOption => divisionOption.value === divisionAvailable,
                                )?.label;
                                if (divisionLabel) {
                                  divisionsAvailableLabels.push(divisionLabel);
                                }
                              });

                              return (
                                <tr
                                  key={teetime.id}
                                  className="clickable prevent_select"
                                  onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                                    e.stopPropagation();
                                    onSelectedTeetimeChange(teetime.id);
                                  }}
                                >
                                  <td>
                                    <Checkbox
                                      id={`teetime ${index}`}
                                      size="medium"
                                      checked={settingsState.selectedTeetimes.some(id => id === teetime.id)}
                                      label={convertTime(teetime.start_time)}
                                      readOnly
                                    />
                                  </td>
                                  <td>
                                    {teetime.blocked_type === "open" ? (
                                      <Badge type="success">{capitalize(teetime.blocked_type)}</Badge>
                                    ) : null}

                                    {teetime.blocked_type === "blocked" ? (
                                      <Badge type="warning">{capitalize(teetime.blocked_type)}</Badge>
                                    ) : null}
                                    {teetime.blocked_type === "crossover" ? <Badge type="gray">{"Turn"}</Badge> : null}
                                  </td>
                                  <td>
                                    {teetime.turn_division_id && divisionsListLabel ? (
                                      <span>
                                        <FontAwesomeIcon className="mr-2" icon={["far", "arrow-right"]} />
                                        {divisionsListLabel}
                                      </span>
                                    ) : null}
                                  </td>
                                </tr>
                              );
                            })}
                          </DataTable>
                        </div>
                      );
                    })}
                  </div>
                );
              }
            })}
          </Card.Section>
        </Card>
      );
    }
  }, [
    templateState.divisions_list,
    templateState.tee_times,
    settingsState.selectedTeetimes,
    shiftPressed,
    selectedTab,
  ]);

  function openDeleteTemplate() {
    setSettingsState(prevState => ({ ...prevState, openDeleteTemplate: true }));
  }

  async function deleteTemplate() {
    const params = {
      id: templateState.id,
    };

    const deleteTemplateRes = await DeleteTeeSheetTemplate(params, true);

    if (deleteTemplateRes.status !== StatusCode.OK) {
      uiActions.showError(deleteTemplateRes.data);
      return;
    }

    setSettingsState(prevState => ({ ...prevState, openDeleteTemplate: false }));
    history.push("/admin/settings/tee-sheet/template");
  }

  function handleCloseDeleteTemplate() {
    setSettingsState(prevState => ({ ...prevState, openDeleteTemplate: false }));
  }

  function handleTournamentChange(value: any, id: string, tournament: ITournament) {
    setSettingsState(prevState => ({ ...prevState, [id]: tournament.id }));
    setFilterState(prevState => ({ ...prevState, tournamentSearchValue: "", selectedTournament: tournament }));
  }

  function handleLeagueChange(value: any, id: string, league: ILeague) {
    setSettingsState(prevState => ({ ...prevState, [id]: league.id }));
    setFilterState(prevState => ({ ...prevState, leagueSearchValue: "", selectedLeague: league }));
  }

  function removeSelectedTournament() {
    setFilterState(prevState => ({
      ...prevState,
      selectedTournament: null,
      tournaments: [],
      tournamentSearching: false,
    }));
    setSettingsState(prevState => ({ ...prevState, tournament_id: "" }));
  }

  function removeSelectedLeague() {
    setFilterState(prevState => ({
      ...prevState,
      selectedLeague: null,
      leagues: [],
      leagueSearching: false,
    }));
    setSettingsState(prevState => ({ ...prevState, league_id: "" }));
  }

  //Load Leagues
  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (async () => {
          if (filterState.leagueSearchValue !== "") {
            try {
              setFilterState(prevState => ({ ...prevState, leagueSearching: true }));
              const leagues = await loadSearchedLeagues(filterState.leagueSearchValue);
              if (mounted) {
                setFilterState(prevState => ({ ...prevState, leagues: leagues, leagueSearching: false }));
              }
            } catch (error) {
              console.log("err", error);
            }
            return;
          } else {
            setFilterState(prevState => ({ ...prevState, leagues: [], leagueSearching: false }));
          }
        })();
      }, 1000);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [filterState.leagueSearchValue]);

  // Search Tournaments
  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (async () => {
          if (filterState.tournamentSearchValue !== "") {
            try {
              setFilterState(prevState => ({ ...prevState, tournamentSearching: true }));
              const tournaments = await loadSearchedTournaments(filterState.tournamentSearchValue);
              if (mounted) {
                setFilterState(prevState => ({ ...prevState, tournaments: tournaments, tournamentSearching: false }));
              }
            } catch (error) {
              console.log("err", error);
            }
            return;
          } else {
            setFilterState(prevState => ({ ...prevState, tournaments: [], tournamentSearching: false }));
          }
        })();
      }, 1000);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [filterState.tournamentSearchValue]);

  async function loadSearchedTournaments(search: string) {
    const params = {
      search: search,
    };

    const tournamentRes = await GetTournament(params, false);

    if (tournamentRes.status !== StatusCode.OK) {
      dispatch(showError(tournamentRes.message));
      return [];
    }

    return tournamentRes.data;
  }

  async function loadSearchedLeagues(search: string) {
    const params = {
      search: search,
    };

    const leagueRes = await GetLeague(params, false);

    if (leagueRes.status !== StatusCode.OK) {
      dispatch(showError(leagueRes.message));

      return [];
    }

    return leagueRes.data;
  }

  function displayTournaments() {
    if (filterState?.tournaments && filterState?.tournaments?.length > 0 && filterState?.tournamentSearchValue !== "") {
      return filterState?.tournaments;
    } else {
      return settingsState?.tournaments;
    }
  }

  function displayLeagues() {
    if (filterState?.leagues && filterState?.leagues?.length > 0 && filterState?.leagueSearchValue !== "") {
      return filterState?.leagues;
    } else {
      return settingsState?.leagues;
    }
  }

  const primaryAction = {
    content: "Delete Template", // TODO: Translation
    action: openDeleteTemplate,
  };

  return (
    finishedPageLoading && (
      <Page
        title={templateState.name}
        breadcrumbs={[
          {
            prefix: true,
            label: t("secure.facility.settings.tee_sheets.tee_sheet_template.010"),
            url: "/admin/settings/tee-sheet/template",
          },
        ]}
        notificationBarProps={{
          isVisible: unsavedChangesExist(),
          onAction: saveTemplate,
          onCancel: cancelUnsavedChanges,
        }}
        primaryAction={primaryAction}
      >
        <Card title={t("secure.facility.settings.tee_sheets.tee_sheet_template.012")}>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={templateState.name}
                  label={t("secure.facility.settings.tee_sheets.tee_sheet_template.013")}
                  id="name"
                  onChange={handleInputChange}
                  placeholder={t("secure.facility.settings.tee_sheets.tee_sheet_template.014")}
                />
              </FormLayout.Group>

              <FormLayout.Group>
                <TextField
                  value={templateState.description}
                  label={"Description"}
                  id="description"
                  onChange={handleInputChange}
                  placeholder={"Template description"}
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>

        <br />

        <Card title={t("secure.facility.settings.tee_sheets.tee_sheet_template.015")}>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <div className="flex">
                  <Button onClick={() => selectAll()}>
                    {t("secure.facility.settings.tee_sheets.tee_sheet_template.016")}
                  </Button>
                  <Button onClick={() => unselectAll()}>
                    {t("secure.facility.settings.tee_sheets.tee_sheet_template.017")}
                  </Button>
                  <Button onClick={() => clearSettings()}>
                    {t("secure.facility.settings.tee_sheets.tee_sheet_template.018")}
                  </Button>
                  <Button onClick={() => applySettings()}>
                    {t("secure.facility.settings.tee_sheets.tee_sheet_template.019")}
                  </Button>
                </div>
              </FormLayout.Group>

              <FormLayout.Group>
                <Select
                  value={settingsState.blocked_type}
                  id="blocked_type"
                  label={t("secure.facility.settings.tee_sheets.tee_sheet_template.020")}
                  onChange={handleSettingsChange}
                  options={blockedTypeOptions}
                  showOptionLabel
                />
                <Select
                  value={settingsState.cart_rule}
                  id="cart_rule"
                  label={t("secure.facility.settings.tee_sheets.tee_sheet_template.021")}
                  onChange={handleSettingsChange}
                  showOptionLabel
                  options={[
                    ...cartRuleOptions.map(cartRuleOption => {
                      return { label: cartRuleOption.label, value: cartRuleOption.value };
                    }),
                  ]}
                />

                {filterState.selectedTournament ? (
                  <div>
                    <div>
                      <p className="event-label">Tournaments</p>
                    </div>

                    <div className="selected-container">
                      <div className="event-name">
                        <div>{filterState.selectedTournament.name}</div>

                        <div>
                          <button className="" onClick={removeSelectedTournament}>
                            <Icon style="far" icon="times" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <SelectNew
                      showSearch
                      label="Tournaments"
                      onSearch={(query: string) =>
                        setFilterState(prevState => ({ ...prevState, tournamentSearchValue: query }))
                      }
                      onChange={(value: any, tournament: ITournament) =>
                        handleTournamentChange(value, "tournament_id", tournament)
                      }
                      placeholder={"Search tournaments..."}
                      allowClear
                      searchValue={filterState.tournamentSearchValue}
                      showDropDownOnFocus={true}
                      searching={filterState.tournamentSearching}
                    >
                      <Option
                        value={"none"}
                        extraValues={{
                          name: t("secure.facility.settings.tee_sheets.tee_sheet_template.025"),
                          id: "none",
                        }}
                      >
                        <div className="">{t("secure.facility.settings.tee_sheets.tee_sheet_template.025")}</div>
                      </Option>

                      {displayTournaments()
                        ?.filter(tournament => !moment(tournament.date).isBefore(moment(), "day"))
                        .map((tournament: any, index: number) => {
                          return (
                            <Option key={index} value={tournament.id} name={tournament.name} extraValues={tournament}>
                              <div className="flex items-center">
                                <span>{tournament.name}</span>
                                <span className="shotgun-tournament-date">{moment(tournament.date).format("LL")}</span>
                              </div>
                            </Option>
                          );
                        })}

                      <Option disabled value={-2} key={-2} name={""}>
                        <div>
                          <hr style={{ margin: "0px" }} />
                        </div>
                      </Option>

                      {displayTournaments()
                        ?.filter(tournament => moment(tournament.date).isBefore(moment(), "day"))
                        .map((tournament: any, index: number) => {
                          return (
                            <Option key={index} value={tournament.id} name={tournament.name} extraValues={tournament}>
                              <div className="flex items-center">
                                <span>{tournament.name}</span>
                                <span className="shotgun-tournament-date">{moment(tournament.date).format("LL")}</span>
                              </div>
                            </Option>
                          );
                        })}
                    </SelectNew>
                  </div>
                )}

                {filterState.selectedLeague ? (
                  <div>
                    <div>
                      <p className="event-label">Leagues</p>
                    </div>

                    <div className="selected-container">
                      <div className="event-name">
                        <div>{filterState.selectedLeague.name}</div>

                        <div>
                          <button className="" onClick={removeSelectedLeague}>
                            <Icon style="far" icon="times" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <SelectNew
                      showSearch
                      label="Leagues"
                      onSearch={(query: string) =>
                        setFilterState(prevState => ({ ...prevState, leagueSearchValue: query }))
                      }
                      onChange={(value: any, league: ILeague) => handleLeagueChange(value, "league_id", league)}
                      placeholder={"Search leagues..."}
                      allowClear
                      searchValue={filterState.leagueSearchValue}
                      showDropDownOnFocus={true}
                      searching={filterState.leagueSearching}
                    >
                      <Option
                        value={"none"}
                        extraValues={{
                          name: t("secure.facility.settings.tee_sheets.tee_sheet_template.023"),
                          id: "none",
                        }}
                      >
                        <div className="">{t("secure.facility.settings.tee_sheets.tee_sheet_template.023")}</div>
                      </Option>
                      {displayLeagues()
                        ?.filter(league => league.start_date && !moment(league.start_date).isBefore(moment(), "day"))
                        ?.map((league: any, index: number) => {
                          return (
                            <Option value={league?.id} key={index} extraValues={league}>
                              <div className="">
                                <span className="">{league.name}</span>
                                <span className="shotgun-tournament-date">
                                  {moment(league.start_date).format("YYYY")}
                                </span>
                              </div>
                            </Option>
                          );
                        })}

                      <Option disabled value={-2} key={-2} name={""}>
                        <div>
                          <hr style={{ margin: "0px" }} />
                        </div>
                      </Option>

                      {displayLeagues()
                        ?.filter(league => !league.start_date || moment(league.start_date).isBefore(moment(), "day"))
                        ?.map((league: any, index: number) => {
                          return (
                            <Option value={league?.id} key={index} extraValues={league}>
                              <div className="">
                                <span className="">{league.name}</span>
                                {league?.start_date && (
                                  <span className="shotgun-tournament-date">
                                    {moment(league.start_date).format("YYYY")}
                                  </span>
                                )}
                              </div>
                            </Option>
                          );
                        })}
                    </SelectNew>
                  </div>
                )}
              </FormLayout.Group>
              <FormLayout.Group>
                <Select
                  value={settingsState.turn_division_id}
                  id="turn_division_id"
                  label={t("secure.facility.settings.tee_sheets.tee_sheet_template.026")}
                  onChange={handleSettingsChange}
                  options={[
                    { label: "", value: "" },
                    ...templateState.divisions_list.map((division: any) => {
                      return { label: division?.title, value: division?.id };
                    }),
                  ]}
                  showOptionLabel
                />
                <div className="text-base font-medium text-black block">
                  <label>{t("secure.facility.settings.tee_sheets.tee_sheet_template.027")}</label>
                  <Checkbox
                    id="pre_paid_required"
                    size="small"
                    value="true"
                    checked={settingsState.pre_paid_required !== null && settingsState.pre_paid_required}
                    onChange={handleCheckboxChange}
                    label={t("secure.facility.settings.tee_sheets.tee_sheet_template.028")}
                    circle
                  />
                  <Checkbox
                    id="pre_paid_required"
                    size="small"
                    value="false"
                    checked={settingsState.pre_paid_required !== null && !settingsState.pre_paid_required}
                    onChange={handleCheckboxChange}
                    label={t("secure.facility.settings.tee_sheets.tee_sheet_template.029")}
                    circle
                  />
                </div>
                <div className="text-base font-medium text-black block">
                  <label>{t("secure.facility.settings.tee_sheets.tee_sheet_template.030")}</label>
                  <Checkbox
                    id="credit_card_required"
                    size="small"
                    value="true"
                    checked={settingsState.credit_card_required !== null && settingsState.credit_card_required}
                    onChange={handleCheckboxChange}
                    label={t("secure.facility.settings.tee_sheets.tee_sheet_template.031")}
                    circle
                  />
                  <Checkbox
                    id="credit_card_required"
                    size="small"
                    value="false"
                    checked={settingsState.credit_card_required !== null && !settingsState.credit_card_required}
                    onChange={handleCheckboxChange}
                    label={t("secure.facility.settings.tee_sheets.tee_sheet_template.032")}
                    circle
                  />
                </div>
                <div className="text-base font-medium text-black block">
                  <label>{"Web Booking Enabled"}</label>
                  <Checkbox
                    id="web_booking_enabled"
                    size="small"
                    value="true"
                    checked={settingsState.web_booking_enabled !== null && settingsState.web_booking_enabled}
                    onChange={handleCheckboxChange}
                    label={"Yes"}
                    circle
                  />
                  <Checkbox
                    id="web_booking_enabled"
                    size="small"
                    value="false"
                    checked={settingsState.web_booking_enabled !== null && !settingsState.web_booking_enabled}
                    onChange={handleCheckboxChange}
                    label={"No"}
                    circle
                  />
                </div>
                <div>
                  <label>{t("secure.facility.settings.tee_sheets.tee_sheet_template.033")}</label>
                  {divisionOptions.map((divisionOption, index: number) => {
                    return (
                      <Checkbox
                        key={index}
                        size="small"
                        onChange={handleDivisionsAvailableChange}
                        value={divisionOption.value}
                        checked={
                          settingsState.divisions_available.find(
                            divisionsAvailable => divisionsAvailable === divisionOption.value,
                          ) !== undefined
                        }
                        label={divisionOption.label}
                        circle
                      />
                    );
                  })}
                </div>
                <div>
                  <label>{t("secure.facility.settings.tee_sheets.tee_sheet_template.034")}</label>
                  {settingsState.booking_categories.map((bookingCategory, index: number) => {
                    return (
                      <Checkbox
                        key={index}
                        size="small"
                        onChange={handleBookingCategoryChange}
                        value={bookingCategory.id}
                        checked={
                          settingsState.booking_category_ids.find(
                            bookingCategoryId => bookingCategoryId === bookingCategory.id,
                          ) !== undefined
                        }
                        label={bookingCategory.title}
                        circle
                      />
                    );
                  })}
                </div>
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>

        <div>{MemoizedTeetimes}</div>

        <Popup
          open={settingsState.openDeleteTemplate}
          type="warning"
          title="Delete Template"
          description={"Are you sure you want to delete this template?"}
          onOk={() => deleteTemplate()}
          okText="Delete"
          onCancel={() => handleCloseDeleteTemplate()}
          backDropCancel={true}
        />
      </Page>
    )
  );
}
