import { AdminType, APIGet, APIPost, APIPut, APIUrl } from "api/protocols";
import { CancelToken } from "axios";
import { IRegister } from "pages/secure/facility/Admin";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseRegisterUrl = "/" + apiVersion + "/" + adminType + "/facility/register";

export type TGetRegister = {
  id?: number;
  extended?: boolean;
};

export async function GetRegisters(params: TGetRegister, useGlobalLoader?: boolean, token?: CancelToken) {
  return await APIGet<IRegister[]>(
    APIUrl(AdminType.FacilityAdmin, "/facility/register"),
    params,
    useGlobalLoader,
    token,
  );
}

export async function PutRegister(params: any, useGlobablLoader?: boolean) {
  return await APIPut(APIUrl(AdminType.FacilityAdmin, "/facility/register"), params, useGlobablLoader);
}

export async function PostRegister(
  params: { register_group_id?: null | number; default_card_reader_id?: null | number; title: string },
  useGlobablLoader?: boolean,
) {
  return await APIPost(APIUrl(AdminType.FacilityAdmin, "/facility/register"), params, useGlobablLoader);
}
