import { APIGet, APIPost, APIPut, APIDelete, StatusCode } from "api/protocols";
import { CancelToken } from "axios";
import { IModule, ISegment } from "redux/reducers/models/reservations";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseReservationsUrl = "/" + apiVersion + "/" + adminType + "/reservation";

interface IGetReservationModule {
  facility_short_name?: string;
  handle?: string;
  id?: number;
}
interface IPutReservationModule {
  id: number;
  title: string;
  enable_web_booking: boolean;
  web_cancellation_hours: number;
  web_edit_hours: number;
  days_in_advance_start: number;
  days_in_advance_end: number;
  open_time: string;
  booking_terms: string;
  payment_terms: string;
}

interface IGetReservationLocation {
  id?: number;
  facility_id?: number;
  reservation_module_id?: number;
}

interface IGetReservationSegment {
  id?: number;
  date?: string;
  start_time?: string;
  location_id?: number;
  extended?: boolean;
  module_id?: number;
}

interface IPostReservationBooking {
  customer_id: number;
  module_id: number;
  location_id: number;
  segment_ids: Array<number>;
  date: string;
  quantity: number;
  customer_payment_method_id: number;
}

interface IPostReservationConfig {
  location_ids: Array<number>;
  start_time: string;
  end_time: string;
  interval: number;
  date: string;
}
interface IPutReservationBooking {
  id: number;
  segment_ids: Array<number>;
  date: string;
  quantity: number;
  customer_payment_method_id: number;
  check_in_status?: string;
  order_financial_status?: string;
}

interface IMoveReservationBooking {
  booking_id: number;
  location_id: number;
  date: string;
  segment_ids: number[];
}

interface IGetReservationBooking {
  id: number;
}

interface IGetReservationBookingAll {
  customer_id?: number;
}

interface IPutReservationSegment {
  segments: Array<ISegment>;
}

interface IDeleteReservationSegment {
  segment_ids: Array<number>;
}

interface IPutReservationBookingConfirmation {
  reservation_booking_id: number;
}

interface IGetReservationNotes {
  id: number;
}

interface IGetReservationNotesAll {
  reservation_booking_id: number;
}

interface IPostReservationBookingNote {
  reservation_booking_id: number;
  content?: string;
}

interface IGetReservationTemplate {
  id?: number;
  module_id?: number;
}

interface IPostReservationTemplate {
  module_id: number;
  name: string;
  start_time: string;
  end_time: string;
  interval: number;
  location_ids: Array<number>;
  web_booking_enabled: boolean;
  credit_card_required: boolean;
}

interface IPutReservationTemplate {
  segments: Array<ISegment>;
}

interface IPutApplyReservationTemplate {
  date: string;
  template_id: number;
}

export async function GetReservationModule(params: IGetReservationModule, useGlobalLoader: boolean) {
  return await APIGet(baseReservationsUrl + "/module", params, useGlobalLoader);
}

export async function PutReservationModule(params: IPutReservationModule, useGlobalLoader: boolean) {
  return await APIPut(baseReservationsUrl + "/module", params, useGlobalLoader);
}

export async function GetReservationModuleAll(
  params: IGetReservationModule,
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet<IModule[]>(baseReservationsUrl + "/module/all", params, useGlobalLoader, token);
}

export async function GetReservationLocation(params: IGetReservationLocation, useGlobalLoader: boolean) {
  return await APIGet(baseReservationsUrl + "/location", params, useGlobalLoader);
}

export async function GetReservationSegment(params: IGetReservationSegment, useGlobalLoader: boolean) {
  return await APIGet(baseReservationsUrl + "/segment", params, useGlobalLoader);
}

export async function GetSegmentAuditTrail(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseReservationsUrl + "/segment/audit", params, useGlobalLoader);
}

export async function GetReservationBooking(params: IGetReservationBooking, useGlobalLoader: boolean) {
  return await APIGet(baseReservationsUrl + "/booking", params, useGlobalLoader);
}
export async function GetPrintReservation(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseReservationsUrl + "/segment/print", params, useGlobalLoader);
}

export async function GetReservationBookingAll(
  params: IGetReservationBookingAll,
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseReservationsUrl + "/booking/all", params, useGlobalLoader, token);
}

export async function PostReservationBooking(params: IPostReservationBooking, useGlobalLoader: boolean) {
  return await APIPost(baseReservationsUrl + "/booking", params, useGlobalLoader);
}

export async function PostReservationSegmentConfig(params: IPostReservationConfig, useGlobalLoader: boolean) {
  return await APIPost(baseReservationsUrl + "/segment/config", params, useGlobalLoader);
}

export async function PutReservationBooking(params: IPutReservationBooking, useGlobalLoader: boolean) {
  return await APIPut(baseReservationsUrl + "/booking", params, useGlobalLoader);
}

export async function MoveReservationBooking(params: IMoveReservationBooking, useGlobalLoader: boolean) {
  return await APIPut(baseReservationsUrl + "/booking/move", params, useGlobalLoader);
}

export async function PutReservationBookingConfirmation(
  params: IPutReservationBookingConfirmation,
  useGlobalLoader: boolean,
) {
  return await APIPut(baseReservationsUrl + "/booking/confirmation", params, useGlobalLoader);
}

export async function PutCancelReservationBooking(params: { id: number }, useGlobalLoader: boolean) {
  return await APIPut(baseReservationsUrl + "/booking/cancel", params, useGlobalLoader);
}

export async function PutReservationSegment(params: IPutReservationSegment, useGlobalLoader: boolean) {
  return await APIPut(baseReservationsUrl + "/segment", params, useGlobalLoader);
}

export async function DeleteReservationSegment(params: IDeleteReservationSegment, useGlobalLoader: boolean) {
  return await APIDelete(baseReservationsUrl + "/segment", params, useGlobalLoader);
}

//Booking Notes

/**Get a single reservation booking note base on the note's id */
export async function GetReservationBookingNote(params: IGetReservationNotes, useGlobalLoader: boolean) {
  return await APIGet(baseReservationsUrl + "/booking/note", params, useGlobalLoader);
}

/**Get all booking notes attached to a booking based on reservation booking id */
export async function GetReservationBookingNoteAll(params: IGetReservationNotesAll, useGlobalLoader: boolean) {
  return await APIGet(baseReservationsUrl + "/booking/note/all", params, useGlobalLoader);
}

export async function PostReservationBookingNote(params: IPostReservationBookingNote, useGlobalLoader: boolean) {
  return await APIPost(baseReservationsUrl + "/booking/note", params, useGlobalLoader);
}

// Notifications

/**Send an email to selected booking ids */
export async function PostReservationEmail(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseReservationsUrl + "/notification/email", params, useGlobalLoader);
}

// Templates
export async function GetReservationTemplate(
  params: IGetReservationTemplate,
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseReservationsUrl + "/template", params, useGlobalLoader, token);
}

export async function PostReservationTemplate(params: IPostReservationTemplate, useGlobalLoader: boolean) {
  return await APIPost(baseReservationsUrl + "/template", params, useGlobalLoader);
}

export async function PutReservationTemplate(params: IPutReservationTemplate, useGlobalLoader: boolean) {
  return await APIPut(baseReservationsUrl + "/template", params, useGlobalLoader);
}

export async function PutApplyReservationTemplate(params: IPutApplyReservationTemplate, useGlobalLoader: boolean) {
  return await APIPut(baseReservationsUrl + "/template/apply", params, useGlobalLoader);
}

export async function PutCopyReservationBooking(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseReservationsUrl + "/booking/copy", params, useGlobalLoader);
}
