import React from "react";
import { Node, Handle, Position, NodeProps } from "reactflow";
import "./dynamicPricingRootNode.scss";
import { useLimitOutgoingBranching } from "./useLimitOutgoingBranching";
import classNames from "classnames";

export const DYNAMIC_PRICING_ROOT_NODE = "DYNAMIC_PRICING_ROOT_NODE";
export const DYNAMIC_PRICING_ROOT_NODE_ID = "0";

interface IDynamicPricingRootNodeData {
  nextGroupId: string;
  outgoingBranchingLimit: number;
}

export interface IDynamicPricingRootNode extends Node {
  id: typeof DYNAMIC_PRICING_ROOT_NODE_ID;
  type: typeof DYNAMIC_PRICING_ROOT_NODE;
  data: IDynamicPricingRootNodeData;
}

type DynamicPricingFactorNodeProps = NodeProps<IDynamicPricingRootNodeData>;

export default function DynamicPricingRootNode(props: DynamicPricingFactorNodeProps) {
  const { id, data } = props;

  const isHandleConnectable = useLimitOutgoingBranching(id, data.outgoingBranchingLimit);

  return (
    <div className="dynamic-pricing-root-node">
      <Handle
        type="source"
        position={Position.Right}
        onMouseDownCapture={e => {
          if (!isHandleConnectable) {
            e.stopPropagation();
          }
        }}
        className={classNames("dynamic-pricing-root-node-handle", {
          "dynamic-pricing-root-node-handle-disabled": !isHandleConnectable,
        })}
      />
    </div>
  );
}
