import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetRegisters } from "api/rpc/facilityAdmin/facility/register/register";
import { GetRegisters as GetClientRegisters } from "api/rpc/2024-04/clientAdmin/register/register";

import { DropFilterCheckboxOptionals } from "../dropdown/dropFilterTypes";
import DropFilter from "components/dropdown/DropFilter";
import { IRegister } from "pages/secure/facility/Admin";

type RegisterDropFilterBase = DropFilterCheckboxOptionals & {
  /** callback value to inform parent when ASYNC data is loaded */
  dataLoaded?: () => void;
  /** When 'Apply' is clicked, returns all title's of selected registers */
  handleApply: (registerTitles: Array<number>) => void;

  /** Adds global loader to async call. */
  withLoader?: boolean;
};

type RegisterDropFilterFacilityProps = DropFilterCheckboxOptionals & {
  userLevel: "facility";
  facility_id?: never;
};
type RegisterDropFilterClientProps = DropFilterCheckboxOptionals & {
  userLevel: "client";
  facility_id?: number;
};

// if client level, include facility_id on call
type RegisterDropFilterProps = RegisterDropFilterBase &
  (RegisterDropFilterClientProps | RegisterDropFilterFacilityProps);

export default function RegisterDropFilter(props: RegisterDropFilterProps) {
  const [registers, setRegisters] = useState<IRegister[]>(null);
  const { dataLoaded, handleApply, withLoader = false, userLevel, facility_id } = props;
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const source = axios.CancelToken.source();

    switch (userLevel) {
      case "client": {
        void loadClientRegisters(facility_id, source.token);
        return;
      }
      case "facility": {
        void loadFacilityRegisters(source.token);
        return;
      }
      default: {
        null;
      }
    }

    return () => source.cancel();
  }, [userLevel, facility_id]);

  const loadFacilityRegisters = async (token?: CancelToken) => {
    const { status, data } = await GetRegisters(null, withLoader, token);
    if (token && token.reason) {
      return;
    }
    if (status !== StatusCode.OK) {
      return;
    }

    setRegisters(data);
    dataLoaded && dataLoaded();
  };

  const loadClientRegisters = async (facilityId?: number, token?: CancelToken) => {
    const { status, data } = await GetClientRegisters({ facility_id: facilityId }, withLoader, token);
    if (token && token.reason) {
      return;
    }
    if (status !== StatusCode.OK) {
      return;
    }

    setRegisters(data);
    dataLoaded && dataLoaded();
  };

  // Capture filter ids and apply callback
  function handleApplyFilter(filterReturn: Record<string, any>[]) {
    const tempReturn: number[] = filterReturn.map((value: Record<string, any>, index: number) => {
      return value.id as number;
    });
    handleApply(tempReturn);
  }

  return (
    <DropFilter
      key="product_types_titles"
      title={t("components.filters.register_drop_filter.001")} //TODO: Translation
      filterData={registers}
      filterLabelPropFromData="title"
      filterIdPropFromData="id"
      filterType="Checkbox"
      applyFilters={filterReturn => handleApplyFilter(filterReturn)}
      defaultCheckboxes={props.defaultCheckboxes}
      leftAligned={props.leftAligned ?? false}
      rightAligned={props.rightAligned ?? false}
      dropdownSize={props.dropdownSize}
      darkMode={props.darkMode}
      save={props.save}
      historyKey={props.historyKey}
      restrain={props.restrain}
      disableDropdown={props.disableDropdown}
    />
  );
}
