import { useEffect, useState } from "react";

const isLocalStorageAvailable = browserSupportTest();
function browserSupportTest() {
  const test = "test";
  try {
    window.localStorage.setItem(test, test);
    window.localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

const obtainLocalStorage = <T>(key: string) => {
  const activeStorage = JSON.parse(localStorage.getItem(key)) as T;
  if (activeStorage) {
    return activeStorage;
  }

  return null;
};

/**
 *  Return value found in localStorage dependant on search key passed.
 *  - Defaults to NULL if no storage is found | localStorage is not available with browser.
 *  - Pass optional typing to define return value.
 */
export default function useLocalStorage<T>(key: string) {
  const [state, setState] = useState<T>(null);

  useEffect(() => {
    if (isLocalStorageAvailable) {
      setState(obtainLocalStorage<T>(key));
    } else {
      console.warn(`Local storage not supported on this browser.`);
    }
  }, [key, localStorage.getItem(key)]);

  return state;
}
