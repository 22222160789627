import React from "react";
import "./color-picker.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
interface IColorPicker {
  onClick: (codeColors: string) => void;
  colorValue: string;
  compact?: boolean;
}
export default function ColorPicker(props: IColorPicker) {
  const arrayOfColors = [
    "#7357D6",
    "#C6B7F7",
    "#B846C3",
    "#E9ACE8",
    "#1B94FD",
    "#88C6F7",
    "#2CDFFD",
    "#5BCEEC",
    "#1BAA5A",
    "#87CE9B",
    "#FEAB2D",
    "#FAC35B",
    "#FE2889",
    "#F7A8C9",
    "#F73952",
    "#F6A7A7",
  ];

  function handleColorPick(codeColors: string) {
    props.onClick(codeColors);
  }

  return (
    <div className={props.compact ? "colorsContainer-compact" : "colorsContainer-regular"}>
      {arrayOfColors.map((codeColors, index) => {
        return (
          <div
            key={index}
            className={"colorContainer " + (codeColors === props.colorValue ? "colorContainer-selected" : "")}
          >
            <div
              className={props.compact ? "colors-compact" : "colors-regular"}
              onClick={() => handleColorPick(codeColors)}
              style={{ backgroundColor: codeColors }}
            >
              {codeColors === props.colorValue ? (
                <FontAwesomeIcon
                  icon={["fas", "check"]}
                  className={props.compact ? "color-icon-compact" : "color-icon-regular"}
                  style={{ color: "#FFFFFF" }}
                />
              ) : null}
            </div>
          </div>
        );
      })}
    </div>
  );
}
