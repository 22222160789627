import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { ButtonNew as Button } from "components/buttonNew";
import Input from "components/form/input/Input";
import useOutsideAlerter from "hooks/useOutsideAlerter/useOutsideAlerter";
import React, { createContext, useEffect, useRef, useState } from "react";
import "./customDropdown.scss";

interface IDropdownProps {
  onChange: (value: number | string) => void;
  defaultValue?: number | string;
  /**Search function that displays a search bar within the dropdown */
  onSearch?: (query: string) => void;
  searchPlacerHolder?: string;
  /**HTML element that shows the currently selected value */
  parentElement: JSX.Element;
  /**Dropdown items */
  children: React.ReactNode;
}

export interface ICustomerDropdownState {
  dropDownOpen: boolean;
  value: number | string;
  searchValue: string;
}
export const CustomDropdownContext = createContext(null);

export default function CustomDropdown(props: IDropdownProps) {
  const { onChange, parentElement, defaultValue, onSearch, searchPlacerHolder, children } = props;
  const container = useRef();
  const initialLoad = useRef(false);
  const [state, setState] = useState<ICustomerDropdownState>({
    dropDownOpen: false,
    value: null,
    searchValue: "",
  });

  useEffect(() => {
    if (defaultValue && !initialLoad.current) {
      setState(prevState => ({ ...prevState, value: defaultValue }));
      initialLoad.current = true;
    }
  }, [defaultValue]);

  useEffect(() => {
    if (onSearch) {
      void onSearch(state?.searchValue);
    }
  }, [state.searchValue]);

  function updateState(newState: Partial<ICustomerDropdownState>) {
    setState(prevState => ({ ...prevState, ...newState }));
  }

  function handleClearButtonClick() {
    updateState({ searchValue: "" });
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    updateState({ [id]: value });
  }

  useOutsideAlerter(container, () => {
    setState(prevState => ({ ...prevState, dropDownOpen: false }));
  });

  return (
    <CustomDropdownContext.Provider value={{ state, updateState, onChange }}>
      <div
        className="custom-dropdown-parent-container"
        ref={container}
        onClick={() => setState(prevState => ({ ...prevState, dropDownOpen: !prevState.dropDownOpen }))}
      >
        {parentElement}
        <FontAwesomeIcon
          icon={["far", state.dropDownOpen ? "chevron-up" : "chevron-down"]}
          className="custom-dropdown-chevron"
        />

        <div
          className={classNames("custom-dropdown-select-container", {
            "custom-dropdown-select-container-display": state.dropDownOpen,
          })}
        >
          {onSearch && (
            <div className="custom-dropdown-option-search-bar" onClick={e => e.stopPropagation()}>
              <Input
                onChange={handleInputChange}
                value={state?.searchValue}
                id="searchValue"
                labelHidden
                onSearchClear={handleClearButtonClick}
                placeholder={searchPlacerHolder ?? ""}
              />
            </div>
          )}
          <ul>{children}</ul>
        </div>
      </div>
    </CustomDropdownContext.Provider>
  );
}
