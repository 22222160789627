import { ICart } from "./models/cart";

export interface ICartState {
  isLoaded: boolean;
  cart: ICart;
}

export interface ICartAction {
  type: string;
  payload: Partial<ICartState>;
}

const initialState: ICartState = {
  isLoaded: false,
  cart: null,
};

export default function CartReducer(state = initialState, action: ICartAction) {
  switch (action.type) {
    case "cart.update":
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        ...state,
        ...action.payload,
      };
    case "cart.clear":
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
