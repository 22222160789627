import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useTranslation, TFunction } from "react-i18next";
import { isEqualWith } from "lodash";

import { StatusCode } from "api/protocols";
import { GetTeeSheets, ITeeSheet } from "api/rpc/teeSheet/teeSheet";
import { PutTeeSheetNotification, IPostNotification } from "api/rpc/2022-09/facilityAdmin/teesheet/notification";
import {
  DeleteTeeSheetNotification,
  GetTeeSheetNotification,
} from "api/rpc/2024-04/facilityAdmin/teesheet/notification";

import { showError } from "redux/actions/ui";
import { ICourse } from "redux/reducers/models/facility";
import { formatDate } from "helpers/Helpers";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Popup from "components/popup/Popup";
import Input from "components/form/input/Input";
import FormLayout from "components/form/FormLayout";
import TextField from "components/form/textField/TextField";
import { Select } from "components/select";
import DatePickerInput from "components/datePickerInput/DatePickerInput";

export default function TeeSheetNotification() {
  const { notificationId } = useParams<{ notificationId: string }>();
  const { t } = useTranslation();
  const history = useHistory();

  const dispatch = useAppDispatch();
  const facilityStore = useAppSelector(store => store.facilityStore);

  const [notificationState, setNotificationState] = useState({
    prevSaveState: undefined,
    prevDate: undefined,
    deletePopupVisibile: false,
  });

  const [saveState, setSaveState] = useState({
    id: null,
    // course: "",
    subject: "",
    content: "",
    user_type: undefined,
    type: undefined,
    tee_sheet_id: null,
    course_id: null,
  });

  const [dateState, setDateState] = useState({
    selectedDate: null,
  });

  const [facilityOptionState, setFacilityOptionState] = useState<{ courses: ICourse[]; teeSheets: ITeeSheet[] }>({
    courses: [],
    teeSheets: [],
  });

  const dateAsString = new Date(dateState.selectedDate).toISOString().slice(0, 10);
  const selectedCourse = facilityOptionState.courses.filter(course => course.id === saveState.course_id);

  // populate component
  useEffect(() => {
    if (saveState.id === null) {
      void loadNotification();
    }
  }, [notificationId]);

  useEffect(() => {
    if (facilityStore.facility && facilityOptionState.courses.length === 0) {
      setFacilityOptionState({ ...facilityOptionState, courses: facilityStore.facility.courses });
    }
  }, [facilityStore.facility]);

  // load teesheets when selected date OR course changes
  useEffect(() => {
    if (dateState.selectedDate === null || saveState.course_id === null) {
      return;
    }
    void loadTeeSheet();
  }, [dateState.selectedDate, saveState.course_id]);

  async function loadNotification() {
    const tempRes = await GetTeeSheetNotification({ id: notificationId }, true);
    if (tempRes.status !== StatusCode.OK) {
      return;
    }

    const tempState = {
      id: notificationId,
      subject: tempRes.data.subject,
      content: tempRes.data.content,
      user_type: tempRes.data.user_type,
      tee_sheet_id: tempRes.data.tee_sheet_id,
      type: tempRes.data.type,
      course_id: tempRes.data.course_id,
    };

    const startingDate = dateFromFormattedString(tempRes.data.date);

    setSaveState({ ...saveState, ...tempState });
    setDateState({ selectedDate: startingDate });

    setNotificationState({ ...notificationState, prevSaveState: tempState, prevDate: startingDate });
  }

  async function saveNotification() {
    const tempState = {
      ...saveState,
      date: dateAsString,
      course: selectedCourse[0].short_name,
      tee_sheet_id: facilityOptionState.teeSheets[0]?.id,
    };

    const res = await PutTeeSheetNotification(tempState as IPostNotification, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError(res.data));
      return;
    }
    history.push("/admin/settings/tee-sheet/notification", dateState.selectedDate);
  }

  async function loadTeeSheet() {
    const res = await GetTeeSheets({ date: formatDate(dateState.selectedDate), course_id: saveState.course_id }, true);
    if (res.status !== StatusCode.OK) {
      return;
    }

    setFacilityOptionState({ ...facilityOptionState, teeSheets: res.data });
  }

  function unsavedChangesExist() {
    if (notificationState.prevSaveState === undefined) {
      if (loadNotification) {
        setNotificationState({ ...notificationState, prevSaveState: saveState, prevDate: dateState.selectedDate });
      }
      return false;
    }
    return (
      !isEqualWith(notificationState.prevSaveState, saveState) ||
      !isEqualWith(notificationState.prevDate, dateState.selectedDate)
    );
  }

  function cancelUnsavedChanges() {
    setSaveState(notificationState.prevSaveState);
    setDateState({ ...dateState, selectedDate: notificationState.prevDate as Date });
  }

  async function handleNotificationDelete() {
    const deleteRes = await DeleteTeeSheetNotification({ id: notificationId }, true);
    if (deleteRes.status !== StatusCode.OK) {
      dispatch(showError(deleteRes.data));
      setNotificationState({ ...notificationState, deletePopupVisibile: false });
      return;
    }

    history.push("/admin/settings/tee-sheet/notification", dateState.selectedDate);
  }

  const primaryAction = {
    content: t("secure.facility.settings.tee_sheets.tee_sheet_notification.003"),
    action: () => setNotificationState({ ...notificationState, deletePopupVisibile: true }),
  };

  return (
    <Page
      title={`${t("secure.facility.settings.tee_sheets.tee_sheet_notification.004")} #${notificationId}`}
      narrow
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.tee_sheets.tee_sheet_notification.005"),
          url: "/admin/settings/tee-sheet/notification",
        },
      ]}
      primaryAction={primaryAction}
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: saveNotification,
        onCancel: cancelUnsavedChanges,
      }}
    >
      <Card>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <div id="notification-date-selector" style={{ marginRight: "15px" }}>
                {dateState.selectedDate && (
                  <DatePickerInput
                    label={t("secure.facility.settings.tee_sheets.tee_sheet_notification.007")}
                    months={1}
                    position="left"
                    startingDate={dateState.selectedDate}
                    setStartingDate={(date: Date) => setDateState({ selectedDate: date })}
                  />
                )}
              </div>

              <div></div>
            </FormLayout.Group>
            <FormLayout.Group>
              {facilityOptionState.courses?.length > 1 && (
                <Select
                  label={t("secure.facility.settings.tee_sheets.tee_sheet_notification.008")}
                  onChange={(value: number) => setSaveState(prev => ({ ...prev, course_id: value }))}
                  defaultValue={saveState.course_id}
                >
                  {facilityOptionState.courses.map((course: ICourse, index: number) => {
                    return (
                      <Select.Option key={course.id} value={course.id} name={course.full_name}>
                        {course.full_name}
                      </Select.Option>
                    );
                  })}
                </Select>
              )}
            </FormLayout.Group>
            <FormLayout.Group>
              <Select
                defaultValue={saveState.user_type}
                label={t("secure.facility.settings.tee_sheets.tee_sheet_notification.009")}
                onChange={(value: number) => setSaveState(prev => ({ ...prev, user_type: value }))}
              >
                {notificationUserTypes(t).map((type, index: number) => {
                  return (
                    <Select.Option key={index} value={type.normalized} name={type.title}>
                      {type.title}
                    </Select.Option>
                  );
                })}
              </Select>
            </FormLayout.Group>
            <FormLayout.Group>
              <Input
                value={saveState.subject}
                label={t("secure.facility.settings.tee_sheets.tee_sheet_notification.010")}
                id="subject"
                onChange={e => setSaveState(prev => ({ ...prev, subject: e.target.value }))}
                placeholder={t("secure.facility.settings.tee_sheets.tee_sheet_notification.011")}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField
                value={saveState.content}
                label={t("secure.facility.settings.tee_sheets.tee_sheet_notification.012")}
                id="content"
                onChange={e => setSaveState(prev => ({ ...prev, content: e.target.value }))}
                placeholder={t("secure.facility.settings.tee_sheets.tee_sheet_notification.013")}
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>

      <Popup
        onCancel={() => setNotificationState(prevState => ({ ...prevState, deletePopupVisibile: false }))}
        onOk={handleNotificationDelete}
        cancelText={t("secure.facility.settings.tee_sheets.tee_sheet_notification.014")}
        okText={t("secure.facility.settings.tee_sheets.tee_sheet_notification.015")}
        open={notificationState.deletePopupVisibile}
        type="warning"
        title={t("secure.facility.settings.tee_sheets.tee_sheet_notification.016")}
        description={t("secure.facility.settings.tee_sheets.tee_sheet_notification.017")}
      />
    </Page>
  );
}

/** Formatted String ==> YYYY-MM-DD */
function dateFromFormattedString(formattedString: string): Date {
  const tempDateArray = formattedString.split("-");

  try {
    const returnDate = new Date(Number(tempDateArray[0]), Number(tempDateArray[1]) - 1, Number(tempDateArray[2]));
    return returnDate;
  } catch (error: any) {
    return new Date();
  }
}

const notificationUserTypes = (t: TFunction<"translation", undefined>) => {
  return [
    { title: t("secure.facility.settings.tee_sheets.tee_sheet_notifications_new.001"), normalized: "admin" },
    { title: t("secure.facility.settings.tee_sheets.tee_sheet_notifications_new.002"), normalized: "customer" },
  ];
};
