import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./customerImage.scss";
import classNames from "classnames";

type CustomerImageProps = {
  imageSource: string | null;
  saveProfileImage: (imageFile: File) => void;
  deleteProfileImage?: () => void;
  circleImage?: boolean;
};

export default function CustomerImage(props: CustomerImageProps) {
  const imageUploadInputRef = useRef(null);
  const { imageSource, saveProfileImage, deleteProfileImage, circleImage } = props;

  function imageUploadOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files;
    if (file && file.length > 0) {
      //Upload image after the file is selected
      void saveProfileImage(file[0]);

      //Reset file selector input value
      imageUploadInputRef.current.value = "";
    }
  }

  return (
    <div className="customer-image_grid" style={{ gridGap: "0rem" }}>
      {imageSource ? (
        <div>
          <div className={classNames("product-image_container", { "product-image_container_circle": circleImage })}>
            <div className="product-image">
              <div className={classNames("remove-button_container", { "remove-button_container_circle": circleImage })}>
                <div className="remove-button" onClick={deleteProfileImage}>
                  <FontAwesomeIcon icon={"times"} />
                </div>
              </div>
              <img src={imageSource} alt="profile image" />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="product-image-upload_container">
            <div className="product-image-upload_content">
              <input
                ref={imageUploadInputRef}
                className="product-image-upload_input"
                type="file"
                accept="image/*"
                onChange={imageUploadOnChange}
              />
              <p>
                <FontAwesomeIcon icon={"upload"} />
              </p>
            </div>
          </div>
          <div className="product-image-upload_content"></div>
        </>
      )}
    </div>
  );
}
