import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios, { CancelToken } from "axios";
import { useHistory } from "react-router";

import { StatusCode } from "api/protocols";
import { GetGiftCards, IGiftCard, ImportGiftCards, TGiftCardsGet } from "api/rpc/2024-04/clientAdmin/payment/giftCard";

import { showError } from "redux/actions/ui";
import useModal from "hooks/modals/useModal";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import Page from "components/page/Page";
import Sheet from "components/sheet/Sheet";
import Callout from "components/callout/Callout";
import Search from "components/search/Search";
import GiftCardsTable from "pages/secure/facility/order/GiftCardsTable";
import Portal from "elements/Portal";

import "pages/secure/facility/order/order.scss";

export default function GiftCards() {
  const fileUploadRef = useRef(null);
  const history = useHistory();

  const dispatch = useAppDispatch();
  const authStore = useAppSelector(state => state.authStore);
  const permissions = authStore?.user?.permissions;

  const [giftCards, setGiftCards] = useState<IGiftCard[]>(undefined);
  const [filters, setFilters] = useState<TGiftCardsGet>({
    code: "",
    customer_id: null,
  });

  const {
    state: fileModal,
    updateModal: updateFileModal,
    closeModal: closeFileModal,
  } = useModal<{ selectedFile: File }>();

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadGiftCards(source.token);

    return () => source.cancel();
  }, [filters]);

  async function loadGiftCards(token?: CancelToken) {
    if (giftCards !== undefined) {
      setGiftCards(undefined);
    }
    const giftcardRes = await GetGiftCards(filters, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (giftcardRes.status !== StatusCode.OK) {
      dispatch(showError(typeof giftcardRes.data === "string" ? giftcardRes.data : "Error loading gift cards."));
    }

    setGiftCards(giftcardRes.status === StatusCode.OK ? giftcardRes.data : []);
  }

  async function importGiftCards() {
    const formData = new FormData();
    formData.append("file", fileModal.selectedFile);

    const importRes = await ImportGiftCards(formData, true);

    if (importRes.status !== StatusCode.OK) {
      dispatch(showError(typeof importRes.data === "string" ? importRes.data : "Error importing gift cards"));
    }

    void loadGiftCards();
    fileUploadRef.current.value = "";
    closeFileModal();
  }

  function handleFileSelect(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files;
    if (file && file.length > 0) {
      updateFileModal({ selectedFile: file[0] });
    }
  }

  function navigateToItem(id: number) {
    history.push("/admin/giftcards/" + String(id));
  }

  return (
    <>
      <Page
        title="Gift Cards"
        narrow
        multipleActionDropdownAction={{
          label: "Options",
          dropdownProps: {
            alignment: "right",
            options: [
              {
                type: "handler",
                label: "Import",
                handler: () => updateFileModal({ isOpen: true }),
                disabled: !permissions?.gift_cards_import,
              },
              {
                type: "handler",
                label: "Download Template",
                handler: () => {},
              },
            ],
          },
        }}
      >
        <div className="flex-grow mb-4">
          <Search
            searchCallback={searchValue => setFilters(prev => ({ ...prev, code: searchValue }))}
            historyKey="giftcards-all"
            placeholder="Search by code..." // TODO: Translation
          />
        </div>
        <GiftCardsTable
          giftCards={giftCards}
          viewPermission={false}
          handleTableNavigation={card => navigateToItem(card.id)}
        />
      </Page>

      <Portal isMounted={fileModal.isOpen}>
        <Sheet
          title="Select File"
          open={fileModal.isOpen}
          size="small"
          closable
          onCancel={() => closeFileModal()}
          onOk={() => importGiftCards()}
          okText="Import"
          okDisabled={!fileModal.selectedFile}
        >
          <Callout
            type="info"
            title={!fileModal.selectedFile ? "No file selected" : "Selected File"} // TODO: Translation
            content={!fileModal.selectedFile ? "Select a file to continue" : fileModal.selectedFile.name}
          />

          <div className="customer-import-container mt-2">
            <input
              ref={fileUploadRef}
              className="customer-import-input"
              type="file"
              accept=".csv"
              onChange={handleFileSelect}
            />
            <FontAwesomeIcon className="customer-import-icon" icon={["far", "upload"]} />
          </div>
        </Sheet>
      </Portal>
    </>
  );
}
