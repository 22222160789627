import React, { useState } from "react";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";

import { useAppSelector } from "hooks/redux";

import { ButtonNew as Button } from "components/buttonNew/index";
import CardSection from "components/cardSection/CardSection";
import Checkbox from "components/form/checkbox/Checkbox";
import { useTranslation, Trans } from "react-i18next";

type CreditCardAddProps = {
  isSavingCard: boolean;
  setIsSavingCard: () => void;
  handleStripeInputs: (
    e: StripeCardNumberElementChangeEvent | StripeCardExpiryElementChangeEvent | StripeCardCvcElementChangeEvent,
  ) => void;
};

export default function CreditCardAdd(props: CreditCardAddProps) {
  const uiStore = useAppSelector(store => store.uiStore);
  const [isAddingCard, setIsAddingCard] = useState(false);
  const { t, i18n } = useTranslation();

  return (
    <>
      {isAddingCard && (
        <div className="flex flex-column">
          <span className="text-xl font-semibold mb-4 mt-2">
            {t("elements.tee_sheet.credit_card.credit_card_add.001")}
          </span>
          {(uiStore.newBookingActive || uiStore.editBookingActive) && (
            <CardSection onChange={props.handleStripeInputs} type="one-row" />
          )}
          <Checkbox
            label={t("elements.tee_sheet.credit_card.credit_card_add.002")}
            size="small"
            value={props.isSavingCard}
            checked={props.isSavingCard}
            onChange={props.setIsSavingCard}
          />
        </div>
      )}

      <Button type="text" onClick={() => setIsAddingCard(!isAddingCard)} className="mt-2 mr-auto">
        <span>
          {isAddingCard === false
            ? t("elements.tee_sheet.credit_card.credit_card_add.003")
            : t("elements.tee_sheet.credit_card.credit_card_add.004")}
        </span>
      </Button>
    </>
  );
}
