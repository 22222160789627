import React from "react";
import { useParams } from "react-router";

import Page from "components/page/Page";
import FormLayout from "components/form/FormLayout";
import Form from "components/form/Form";

export default function TournamentLeaderboard() {
  const { tournamentId, scoresheetId } = useParams<{ tournamentId: string; scoresheetId: string }>();

  function printScoresheet(id: number) {
    console.log("PRINT SCORESHEET", id);
    // const res = await PrintScoresheet({ scoresheet_id: id }, true);
    // console.log(res);  //DEBUG
  }

  const primaryAction = {
    content: "Print",
    action: () => printScoresheet(Number(scoresheetId)),
  };

  return (
    <Page
      title="Leaderboard"
      primaryAction={primaryAction}
      subActions={[{}]}
      narrow
      breadcrumbs={[
        {
          prefix: true,
          label: "Scoresheets",
          url: "/admin/tournament/" + tournamentId + "/scoring",
        },
      ]}
    >
      <Form>
        <FormLayout></FormLayout>
      </Form>
    </Page>
  );
}
