import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseRegisterGroupUrl = "/" + apiVersion + "/" + adminType + "/facility/register/group";

export interface IGetRegisterGroup {
  id: number;
}

export interface IPostRegisterGroup {
  title: string;
  tipping_type: string;
}

export interface IPutRegisterGroup {
  id: number;
  title: string;
  tipping_type: string;
  tip_percentage_low: number;
  tip_percentage_mid: number;
  tip_percentage_high: number;
  tip_fixed_amount_low: number;
  tip_fixed_amount_mid: number;
  tip_fixed_amount_high: number;
}

export async function GetRegisterGroups(params?: IGetRegisterGroup, useGlobablLoader?: boolean, token?: CancelToken) {
  return await APIGet(baseRegisterGroupUrl, params, useGlobablLoader, token);
}

export async function PostRegisterGroup(params: IPostRegisterGroup, useGlobablLoader?: boolean) {
  return await APIPost(baseRegisterGroupUrl, params, useGlobablLoader);
}

export async function PutRegisterGroup(params: IPutRegisterGroup, useGlobablLoader?: boolean) {
  return await APIPut(baseRegisterGroupUrl, params, useGlobablLoader);
}
