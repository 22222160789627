import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { IGetTournament } from "api/rpc/2022-09/facilityAdmin/tournament/tournament";
import { GetTournament } from "api/rpc/2024-04/facilityAdmin/tournament/tournament";

import { convertTime } from "helpers/Helpers";
import { ITournament } from "redux/reducers/models/tournament";

import { THIS_YEAR } from "components/form/calendarField/helpers";
import Page from "components/page/Page";
import Search from "components/search/Search";
import YearDropFilter from "components/filters/YearDropFilter";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";

const TABLE_LIMIT = 50;
export default function Tournaments() {
  const history = useHistory();
  const { t } = useTranslation();

  const [tournaments, setTournaments] = useState<ITournament[]>(undefined);
  const [filterState, setFilterState] = useState<IGetTournament>({
    year: THIS_YEAR,
    offset: 0,
    search: "",
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadTournaments(source.token);
    return () => {
      source.cancel();
    };
  }, [filterState]);

  async function loadTournaments(token?: CancelToken) {
    if (tournaments !== undefined) {
      setTournaments(undefined);
    }

    const tournamentRes = await GetTournament({ ...filterState, limit: TABLE_LIMIT }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (tournamentRes.status !== StatusCode.OK) {
      setTournaments([]);
      return;
    }

    setTournaments(tournamentRes.data);
  }

  function handleTableNavigation(direction: "prev" | "next") {
    switch (direction) {
      case "prev": {
        setFilterState(prev => ({ ...prev, offset: filterState.offset - filterState.limit }));
        return;
      }
      case "next": {
        setFilterState(prev => ({ ...prev, offset: filterState.offset + filterState.limit }));
        return;
      }
      default:
        return;
    }
  }

  const primaryAction = {
    content: t("secure.facility.tournament.tournaments.001"),
    action: () => history.push("/admin/events-tournament/new"),
  };

  return (
    <Page title={t("secure.facility.tournament.tournaments.002")} narrow primaryAction={primaryAction}>
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", paddingBottom: "1.5rem" }}>
        <YearDropFilter
          handleApply={year => setFilterState({ ...filterState, year: year })}
          defaultCheckboxes={[filterState.year]}
          leftAligned
        />
      </div>
      <div style={{ paddingBottom: "1.5rem" }}>
        <Search
          historyKey={"facility-tournaments-search"}
          searchCallback={searchValue => {
            if (searchValue.length === 0 || searchValue.length > 2) {
              setFilterState(prev => ({ ...prev, search: searchValue, offset: 0 }));
            }
          }}
          placeholder="Search by Tournament Name..." // TODO: Translation
        />
      </div>
      <DataTable
        columns={[
          { label: t("secure.facility.tournament.tournaments.003"), width: "40%" },
          { label: t("secure.facility.tournament.tournaments.004"), width: "40%" },
          { label: t("secure.facility.tournament.tournaments.005"), width: "20%" },
        ]}
        loading={tournaments === undefined}
        footer={{
          tableLimit: TABLE_LIMIT,
          tableOffset: filterState.offset,
          handleTableOffset: direction => handleTableNavigation(direction),
          disableNextOffset: !(tournaments?.length === filterState.limit) || tournaments === undefined,
        }}
      >
        {tournaments?.map(tournament => (
          <tr
            key={tournament.id}
            onClick={() => history.push("/admin/tournament/" + String(tournament.id) + "/home")}
            className="clickable"
          >
            <td>{tournament.name}</td>
            <td>
              <span>{moment.utc(tournament.date).format("MMM DD, YYYY")} at </span>
              <span>{convertTime(tournament.start_time)}</span>
            </td>
            <td>
              <span>{tournament.total_participant_count}</span>
              {tournament.capacity ? <span>{"/" + String(tournament.capacity)}</span> : null}
              <span> {t("secure.facility.tournament.tournaments.006")}</span>
            </td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
