import { APIGet, APIPost, APIPut, APIDelete, StatusCode } from "api/protocols";
import { IProduct } from "redux/reducers/models/product";

const apiVersion = "2021-01";
const updatedApiVersion = "2022-09";

const adminType = "facility-admin";

const baseProductUrl = "/" + apiVersion + "/" + adminType + "/product";
const updatedBaseProductUrl = "/" + updatedApiVersion + "/" + adminType + "/product";

interface IGetProduct {
  id?: number;
  search?: string;
  extended?: boolean;
  extended_variants?: boolean;
  sales_channels?: boolean;
  inventory?: boolean;
  limit?: number;
  offset?: number;
  type?: string;
  types?: any;
  all?: boolean;
  modifier_groups?: number;
}

interface IGetProductRes {
  data: IProduct[];
  message: string;
  status: StatusCode;
}

export async function GetProduct(params: IGetProduct, useGlobalLoader: boolean): Promise<IGetProductRes> {
  return await APIGet(updatedBaseProductUrl, params, useGlobalLoader);
}

interface IPutProductAttach {
  product_id: number;
}

interface IProductRes {
  status: number;
  message?: string;
  data: any;
}

// Attach a product to a facility
export async function PutProductAttach(params: IPutProductAttach, useGlobalLoader?: boolean): Promise<IProductRes> {
  return await APIPut(updatedBaseProductUrl + "/attach", params, useGlobalLoader);
}

export async function PutProductDetach(params: { product_id: number }, useGlobalLoader: boolean) {
  return await APIPut(updatedBaseProductUrl + "/detach", params, useGlobalLoader);
}

// POST Products
export async function PostProduct(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseProductUrl, params, useGlobalLoader);
}

// PUT Product
export async function PutProduct(params: any, useGlobalLoader: boolean) {
  return await APIPut(updatedBaseProductUrl, params, useGlobalLoader);
}

// DELETE Product
export async function DeleteProduct(useGlobalLoader: boolean) {
  return await APIDelete(updatedBaseProductUrl, useGlobalLoader);
}

// GET Variant
interface IGetVariant {
  id?: number;
  product_id?: number;
  search?: string;
  extended?: boolean;
  types?: any;
}

interface IGetVariantLabels {
  offset: number;
  variant_id: number;
  quantity: number;
}

export async function GetVariant(params: IGetVariant, useGlobalLoader: boolean) {
  return await APIGet(updatedBaseProductUrl + "/variant", params, useGlobalLoader);
}

// PUT Variant
export async function PostVariant(params: any, useGlobalLoader: boolean) {
  return await APIPost(updatedBaseProductUrl + "/variant", params, useGlobalLoader);
}

// PUT Variant
export async function PutVariant(params: any, useGlobalLoader: boolean) {
  return await APIPut(updatedBaseProductUrl + "/variant", params, useGlobalLoader);
}

interface IVariantFacilityAccess {
  id: number;
  facility_access: boolean;
}

interface IPutVariantsFacilityAccess {
  variants: IVariantFacilityAccess[];
}

export async function PutVariantsFacilityAccess(params: IPutVariantsFacilityAccess, useGlobalLoader: boolean) {
  return await APIPut(updatedBaseProductUrl + "/variant/attach", params, useGlobalLoader);
}

export async function GetVariantLabels(params: IGetVariantLabels, useGlobalLoader: boolean) {
  return await APIGet(updatedBaseProductUrl + "/variant/label", params, useGlobalLoader);
}

// GET Product types
export async function GetProductTypes(useGlobalLoader: boolean) {
  return await APIGet(updatedBaseProductUrl + "/type", useGlobalLoader);
}

interface IGetInventoryLevel {
  variant_id: number;
  extended: boolean;
}

// GET Inventory Levels
export async function GetInventoryLevels(params: IGetInventoryLevel, useGlobalLoader: boolean) {
  return await APIGet(updatedBaseProductUrl + "/inventory/level", params, useGlobalLoader);
}

// PUT Update Inventory Level
export async function UpdateInventoryLevels(params: any, useGlobalLoader: boolean) {
  return await APIPut(updatedBaseProductUrl + "/inventory/level", params, useGlobalLoader);
}

// POST Add Products to Variant
export async function AddProductToVariant(params: any, useGlobalLoader: boolean) {
  return await APIPost(updatedBaseProductUrl + "/combination", params, useGlobalLoader);
}

export interface IRemoveProductFromVariant {
  /** the base product ID */
  variant_id: number;

  /** the variant ID's to remove from the base product */
  variant_ids: Array<number>;
}
// DELETE Products from Variant
export async function RemoveProductFromVariant(params: IRemoveProductFromVariant, useGlobalLoader: boolean) {
  return await APIDelete(updatedBaseProductUrl + "/combination", params, useGlobalLoader);
}

//GET Retrieve tax lines
const updatedBaseTaxUrl = "/" + updatedApiVersion + "/" + adminType + "/client/tax-line";

export async function GetTaxLines(useGlobalLoader: boolean) {
  return await APIGet(updatedBaseTaxUrl, useGlobalLoader);
}

//Attach modifier group to product
export async function AttachModifierGroup(params: any, useGlobalLoader: boolean) {
  return await APIPut(updatedBaseProductUrl + "/modifier", params, useGlobalLoader);
}

//Remove modifier group to product
export async function DeleteModifierGroup(params: any, useGlobalLoader: boolean) {
  return await APIDelete(updatedBaseProductUrl + "/modifier", params, useGlobalLoader);
}

//Get Modifier Groups of Product
export async function GetModifierGroup(params: any, useGlobalLoader: boolean) {
  return await APIGet(updatedBaseProductUrl + "/modifier", params, useGlobalLoader);
}

export async function PostProductImage(params: FormData, useGlobalLoader: boolean) {
  return await APIPost(updatedBaseProductUrl + "/image", params, useGlobalLoader);
}

export async function DeleteProductImage(params: { id: number }, useGlobalLoader: boolean) {
  return await APIDelete(updatedBaseProductUrl + "/image", params, useGlobalLoader);
}

interface IProductColor {
  id: number;
  button_color: string;
}

interface IPutProductColor {
  products: IProductColor[];
}

export async function PutProductColor(params: IPutProductColor, useGlobalLoader: boolean) {
  return await APIPut(updatedBaseProductUrl + "/color", params, useGlobalLoader);
}
