import React, { useEffect, useState } from "react";
import Page from "components/page/Page";
import DataTable from "../customer/tabs/houseAccounts/DataTable";
import { useHistory } from "react-router";
import { GetCustomerSegments, ICustomerSegment } from "api/rpc/2024-04/facilityAdmin/customer/segment";
import axios, { CancelToken } from "axios";
import { StatusCode } from "api/protocols";
import { useAppDispatch } from "hooks/redux";
import { showError } from "redux/actions/ui";

interface IState {
  customerSegments: Array<ICustomerSegment>;
}

export default function CustomerSegments() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [state, setState] = useState<IState>({
    customerSegments: undefined,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void getCustomerSegments(source.token);
    return () => source.cancel();
  }, []);

  async function getCustomerSegments(cancelToken: CancelToken) {
    //loader
    if (state?.customerSegments !== undefined) {
      setState(prevState => ({ ...prevState, customerSegments: undefined }));
    }
    const segmentsRes = await GetCustomerSegments(null, false, cancelToken);
    if (segmentsRes?.status !== StatusCode.OK) {
      if (cancelToken && cancelToken?.reason) {
        return;
      }
      setState(prevState => ({ ...prevState, customerSegments: [] }));
      dispatch(showError("Error getting customer segments"));
      return;
    }
    setState(prevState => ({ ...prevState, customerSegments: segmentsRes?.data }));
  }

  function navigateRoute(url: string) {
    history.push(url);
  }

  return (
    <Page
      narrow
      title="Customer Segments"
      primaryAction={{
        content: "New Segment",
        action: () => navigateRoute("/admin/marketing/customer-segments/new"),
      }}
    >
      <DataTable columns={[{ label: "Title" }, { label: "Description" }]} loading={!state.customerSegments}>
        {state?.customerSegments &&
          state?.customerSegments?.map((segment, index) => (
            <tr
              key={index}
              className="clickable"
              onClick={() => navigateRoute(`/admin/marketing/customer-segments/view/${segment?.id}`)}
            >
              <td>{segment?.title}</td>
              <td>{segment?.description}</td>
            </tr>
          ))}
      </DataTable>
    </Page>
  );
}
