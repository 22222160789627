import {
  DeleteLineItemToCart,
  GetCart,
  IGetCart,
  PostCart,
  PostLineItemToCart,
  PutClearCart,
  PutCart,
  IPostCart,
  PutVoid,
} from "api//rpc/2022-09/facilityAdmin/cart/cart";
import {
  PutCartVariants,
  PutLineItemToCart,
  TPostLineItemToCart,
  PostLineItemToCart as PostLineItem,
} from "api/rpc/2024-04/facilityAdmin/order/cart/lineItem";
// import { LocalStorage } from "api/localstorage";
// import { ICartState } from "reducers/cart";
import { StatusCode } from "api/protocols";
import { GetTable, ITable } from "api/rpc/facilityAdmin/tables/tables";
import { ICart } from "redux/reducers/models/cart";
import { CancelToken } from "axios";

export function loadCart(params: IGetCart, useGlobalLoader: boolean) {
  return async (dispatch: any) => {
    let cart;
    if (params?.tableCart || params?.tableCart === null) {
      //Set table cart without calling the api
      cart = params?.tableCart;
    } else {
      const res = await GetCart(params, useGlobalLoader);
      if (res.status !== StatusCode.OK) {
        console.log(res);
        return;
      }

      cart = res.data[0];

      // switching facilities && create new cart
      if (cart === undefined) {
        await dispatch(postCart(null, false));
        return;
      }

      localStorage.setItem("register_cart_token", cart.token);
    }

    dispatch({
      type: "cart.update",
      payload: {
        isLoaded: true,
        cart: cart,
      },
    });
  };
}

export function loadTableCart(params: IGetCart, useGlobalLoader: boolean) {
  return async (dispatch: any) => {
    let cart;
    if (params?.tableCart || params?.tableCart === null) {
      //Set table cart without calling the api
      cart = params?.tableCart;
    } else {
      const res = await GetCart(params, useGlobalLoader);
      if (res.status !== StatusCode.OK) {
        console.log(res);
        return;
      }

      cart = res.data[0];
    }

    dispatch({
      type: "cart.update",
      payload: {
        isLoaded: true,
        cart: cart,
      },
    });
  };
}

export function loadTabCarts(params: IGetCart, useGlobalLoader: boolean) {
  return async (dispatch: any) => {
    const res = await GetCart(params, useGlobalLoader);
    if (res.status !== StatusCode.OK) {
      console.log(res);
      return;
    }

    const tabCarts = res.data;

    dispatch({
      type: "cart.update",
      payload: {
        isLoaded: true,
        tabCarts: tabCarts,
      },
    });
  };
}

export function postCart(params: IPostCart, useGlobalLoader: boolean) {
  return async (dispatch: any) => {
    const res = await PostCart(params, useGlobalLoader);
    if (res.status !== StatusCode.OK) {
      //TODO: handle error here
      console.log(res);
      return;
    }

    const cart = res.data;
    localStorage.setItem("register_cart_token", cart.token);

    console.log("Cart: ", cart);

    dispatch({
      type: "cart.update",
      payload: {
        isLoaded: true,
        cart: cart,
      },
    });
  };
}

export function postTableCart(params: IPostCart, useGlobalLoader: boolean) {
  return async (dispatch: any) => {
    const res = await PostCart(params, useGlobalLoader);
    if (res.status !== StatusCode.OK) {
      //TODO: handle error here
      console.log(res);
      return;
    }

    const cart = res.data;

    dispatch({
      type: "cart.update",
      payload: {
        isLoaded: true,
        cart: cart,
      },
    });
  };
}

export function putCart(
  params: { id: number; type?: string; table_id?: number; total_tip?: number; status?: string },

  useGlobalLoader: boolean,
) {
  return async (dispatch: any) => {
    const res = await PutCart(params, useGlobalLoader);
    if (res.status !== StatusCode.OK) {
      console.log(res);
      return;
    }

    const cart = res.data;
    console.log("Cart", cart);
    //localStorage.setItem("register_cart_token", cart.token);

    console.log("Cart: ", cart);

    dispatch({
      type: "cart.update",
      payload: {
        isLoaded: true,
        cart: cart,
      },
    });
  };
}

export function postLineItem(
  cartId: number,
  variantId: number,
  parentId: number,
  quantity: number,
  useGlobalLoader: boolean,
) {
  const params = {
    variant_id: variantId,
    cart_id: cartId,
    quantity: quantity,
    parent_id: parentId,
  };

  return async (dispatch: any) => {
    const res = await PostLineItemToCart(params, useGlobalLoader);
    if (res.status !== StatusCode.OK) {
      //TODO: handle error here
      console.log(res);
      return;
    }

    const cart = res.data;

    dispatch({
      type: "cart.update",
      payload: {
        isLoaded: true,
        cart: cart,
      },
    });

    return res;
  };
}

export function putLineItem(
  lineItemId: number,
  quantity: number,
  price: number,
  useGlobalLoader: boolean,
  note?: string,
) {
  const params = {
    id: lineItemId,
    quantity: quantity,
    price: price,
    note: note,
  };

  return async (dispatch: any) => {
    const res = await PutLineItemToCart(params, useGlobalLoader);
    if (res.status !== StatusCode.OK) {
      //TODO: handle error here
      console.log(res);
      return;
    }

    const cart = res.data;
    console.log("cart res", res);

    console.log("Cart: ", cart);

    dispatch({
      type: "cart.update",
      payload: {
        isLoaded: true,
        cart: cart,
      },
    });
  };
}

export function putCartLineItem(input: any, useGlobalLoader: boolean) {
  const params = {
    id: input.id,
    quantity: input.quantity,
    price: input.price,
    note: input.note,
  };

  return async (dispatch: any) => {
    const res = await PutLineItemToCart(params, useGlobalLoader);
    if (res.status !== StatusCode.OK) {
      //TODO: handle error here
      return;
    }

    const cart = res.data;

    dispatch({
      type: "cart.update",
      payload: {
        isLoaded: true,
        cart: cart,
      },
    });
  };
}

export function putCustomer(id: number, customerId: number, useGlobalLoader: boolean) {
  const params = {
    id: id,
    customer_id: customerId,
  };

  return async (dispatch: any) => {
    const res = await PutCart(params, useGlobalLoader);
    if (res.status !== StatusCode.OK) {
      //TODO: handle error here
      console.log(res);
      return;
    }
    const cart = res.data;

    console.log("Cart: ", res);

    dispatch({
      type: "cart.update",
      payload: {
        isLoaded: true,
        cart: cart,
      },
    });
  };
}

export function deleteLineItem(lineItemId: number, useGlobalLoader: boolean) {
  const params = {
    id: lineItemId,
  };

  return async (dispatch: any) => {
    const res = await DeleteLineItemToCart(params, useGlobalLoader);
    if (res.status !== StatusCode.OK) {
      //TODO: handle error here
      console.log(res);
      return;
    }

    const cart = res.data;

    console.log("Cart: ", cart);

    dispatch({
      type: "cart.update",
      payload: {
        isLoaded: true,
        cart: cart,
      },
    });
  };
}

export function cartClear(cart_id: number, useGlobalLoader: boolean) {
  return async (dispatch: any) => {
    const res = await PutClearCart(
      {
        cart_id: cart_id,
      },
      useGlobalLoader,
    );
    if (res.status !== StatusCode.OK) {
      console.log(res);
      return;
    }

    const cart = res.data;

    dispatch({
      type: "cart.clear",
      payload: {
        isLoaded: true,
        cart: cart,
      },
    });
  };
}

export function loadActiveTable(tableId: number, useGlobalLoader: boolean) {
  return async (dispatch: any) => {
    let table;
    if (tableId) {
      const res = await GetTable({ id: tableId, carts: true }, useGlobalLoader);

      if (res.status !== StatusCode.OK) {
        console.log(res);
        return;
      }

      table = res.data[0];
    } else {
      table = null;
    }

    dispatch({
      type: "cart.update",
      payload: {
        isLoaded: true,
        activeTable: table,
      },
    });
  };
}

export function loadActiveTableNew(tableId: number, useGlobalLoader: boolean) {
  return async (dispatch: any) => {
    let table;
    if (tableId) {
      const res = await GetTable({ id: tableId, carts: true, orders: true }, useGlobalLoader);

      if (res.status !== StatusCode.OK) {
        console.log(res);
        return;
      }

      table = res.data[0];
    } else {
      table = null;
    }

    dispatch({
      type: "cart.update",
      payload: {
        isLoaded: true,
        activeTable: table,
      },
    });
  };
}

export function loadTables(useGlobalLoader: boolean, floor_plan_id?: number) {
  return async (dispatch: any) => {
    const register = JSON.parse(localStorage.getItem("register"));
    const params = floor_plan_id ? { floor_plan_id } : { register_group_id: register.register_group_id };
    const res = await GetTable({ ...params }, useGlobalLoader);
    if (res.status !== StatusCode.OK) {
      console.log(res);
      return;
    }

    const tables = res.data;

    dispatch({
      type: "cart.update",
      payload: {
        isLoaded: true,
        tables: tables,
      },
    });
  };
}

export function putVoid(
  params: { cart_id: number; cancel_reason: string },

  useGlobalLoader: boolean,
) {
  return async (dispatch: any) => {
    const res = await PutVoid(params, useGlobalLoader);
    if (res.status !== StatusCode.OK) {
      console.log(res);
      return;
    }

    const cart = res.data;
    console.log("Cart", cart);
    //localStorage.setItem("register_cart_token", cart.token);

    console.log("Cart: ", cart);

    dispatch({
      type: "cart.update",
      payload: {
        isLoaded: true,
        cart: cart,
      },
    });
  };
}

export function setActiveTable(table: ITable) {
  return (dispatch: any) => {
    dispatch({
      type: "cart.update",
      payload: {
        isLoaded: true,
        activeTable: table,
      },
    });
  };
}

export function postCartVariants(
  params: {
    cart_id: number;
    variants: Array<{
      variant_id: number;
      quantity: number;
      parent_id?: number;
      kitchen_meal_id?: number;
      prepaid_required?: boolean;
      modifiers?: Array<{ variant_id: number; quantity: number }>;
    }>;
  },
  useGlobalLoader: boolean,
) {
  return async (dispatch: any) => {
    return new Promise<ICart>((resolve, reject) => {
      const postParams = {
        cart_id: params.cart_id,
        variants: params.variants,
      };
      void PostLineItem(postParams, useGlobalLoader).then(res => {
        if (res.status !== StatusCode.OK) {
          resolve(undefined);
          dispatch({
            type: "cart.update",
            payload: {
              isLoaded: true,
            },
          });
        } else {
          const cart = res.data;

          dispatch({
            type: "cart.update",
            payload: {
              isLoaded: true,
              cart: cart,
            },
          });

          resolve(cart);
        }
      });
    });
  };
}

export interface ICartActions {
  loadCart: typeof loadCart;
  postCart: typeof postCart;
  putCart: typeof putCart;
  putVoid: typeof putVoid;
  loadActiveTable: typeof loadActiveTable;
  loadActiveTableNew: typeof loadActiveTableNew;
  loadTables: typeof loadTables;
  postLineItem: typeof postLineItem;
  putLineItem: typeof putLineItem;
  putCustomer: typeof putCustomer;
  deleteLineItem: typeof deleteLineItem;
  cartClear: typeof cartClear;
  putCartLineItem: typeof putCartLineItem;
  loadTableCart: typeof loadTableCart;
  postTableCart: typeof postTableCart;
  setActiveTable: typeof setActiveTable;
  postCartVariants: typeof postCartVariants;
}
