import Sheet from "components/sheet/Sheet";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonNew as Button } from "components/buttonNew";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICartLineItem } from "redux/reducers/models/cart";
import Input from "components/form/input/Input";

interface IChangeQuantityProps {
  quantitySheetVisible: boolean;
  selectedLineItem: ICartLineItem;
  onCancel?: () => void;
  onOk?: (splitLineItemInfo: IItemActionsState) => void;
  quantitySelected: number;
}

interface IItemActionsState {
  quantitySelected: number;
}

function ChangeQuantity(props: IChangeQuantityProps) {
  const { t, i18n } = useTranslation();

  const [itemActionsState, setItemActionsState] = useState<IItemActionsState>({
    quantitySelected: props.quantitySelected,
  });

  useEffect(() => {
    if (props.quantitySelected && props.quantitySheetVisible) {
      setItemActionsState(prevState => ({
        ...prevState,
        quantitySelected: props.quantitySelected,
      }));
    }
  }, [props.quantitySheetVisible]);

  function onOk() {
    void props.onOk({ ...itemActionsState });
    setItemActionsState(prevState => ({ ...prevState, quantitySelected: null }));
  }

  function onCancel() {
    setItemActionsState(prevState => ({ ...prevState, quantitySelected: null }));
    props.onCancel();
  }

  function handlePaymentInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    if (isNaN(Number(value))) {
      return;
    }
    setItemActionsState(prevState => ({ ...prevState, quantitySelected: Number(value) }));
  }

  return (
    <Sheet
      closable
      size="small"
      open={props.quantitySheetVisible}
      title={t("components.change_quantity.change_quantity.001")}
      onCancel={onCancel}
      onOk={onOk}
      okText={t("components.change_quantity.change_quantity.002")}
      cancelText={t("components.change_quantity.change_quantity.003")}
    >
      <div className="flex flex-1 flex-col gap-4 h-full">
        <div className="flex flex-col">
          <span className="text-lg">{props?.selectedLineItem?.product_title}</span>
          {props?.selectedLineItem?.product_title !== props?.selectedLineItem?.variant_title && (
            <span className="text-sm text-gray-300">{props?.selectedLineItem?.variant_title}</span>
          )}
        </div>
        <div className="flex flex-row justify-between items-center mt-auto mb-auto">
          <Button
            onClick={() => {
              setItemActionsState(prevState => ({
                ...prevState,
                quantitySelected: prevState.quantitySelected <= 1 ? 1 : prevState.quantitySelected - 1,
              }));
            }}
          >
            <FontAwesomeIcon icon={"minus"} />
          </Button>
          <span className="text-2xl" style={{ width: "100px" }}>
            <Input
              prefix=""
              labelHidden
              id="paymentAmount"
              value={itemActionsState.quantitySelected}
              onChange={handlePaymentInputChange}
              className="text-center"
            />
          </span>
          <Button
            onClick={() => {
              setItemActionsState(prevState => ({
                ...prevState,
                quantitySelected: prevState.quantitySelected + 1,
              }));
            }}
          >
            <FontAwesomeIcon icon={"plus"} />
          </Button>
        </div>
      </div>
    </Sheet>
  );
}

export default ChangeQuantity;
