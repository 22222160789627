import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseFacilityUrl = "/" + apiVersion + "/" + adminType + "/facility/accounting";

interface IAccountingRef {
  id?: number;
  title?: string;
  reference_number: string;
  group?: string;
}

// GET Accounting Reference
export async function GetAccountingReference(params: any, useGlobalLoader?: boolean, token?: CancelToken) {
  return await APIGet(baseFacilityUrl, params, useGlobalLoader, token);
}

// POST Accounting Reference
export async function PostAccountingReference(params: IAccountingRef, useGlobalLoader?: boolean) {
  return await APIPost(baseFacilityUrl, params, useGlobalLoader);
}

// PUT Accounting Reference
export async function PutAccountingReference(params: IAccountingRef, useGlobalLoader?: boolean) {
  return await APIPut(baseFacilityUrl, params, useGlobalLoader);
}

// DELETE Accounting Reference
export async function DeleteAccountingReference(params: IAccountingRef, useGlobalLoader?: boolean) {
  return await APIDelete(baseFacilityUrl, params, useGlobalLoader);
}
