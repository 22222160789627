import React, { useEffect, useState, useCallback } from "react";
import { useHistory, Link } from "react-router-dom";
import { useParams } from "react-router";

import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import { ButtonNew as Button } from "components/buttonNew";
import ResourceList from "components/resourceList/ResourceList";
import ResourceItem from "components/resourceList/ResourceItem";
import TextStyle from "components/text/textStyle/TextStyle";
import Stack from "components/stack/Stack";
import Checkbox from "components/form/checkbox/Checkbox";
import DataTable from "components/dataTable/DataTable";
import { AddPlayerToTournament, GetTournamentPlayers } from "api/rpc/clientAdmin/tournament/tournament";
import Sheet from "components/sheet/Sheet";
import Tabs from "components/tabs/Tabs";
import FormLayout from "components/form/FormLayout";
import Input from "components/input/Input";
import { GetCustomer } from "api/rpc/clientAdmin/customer/customer";

interface IMainState {
  tournamentPlayers: IPlayer[];
  search: string;
  customers: any;
}

interface IPlayer {
  customer: IPlayerInfo;
  order_financial_status: string;
  created_at: string;
}

interface IPlayerInfo {
  full_name: string;
  email: string;
  phone: string;
}

interface INewPlayerState {
  first_name: string;
  last_name: string;
  email_address: string;
  phone_number: string;
  newPlayerSheetActive: boolean;
}

export default function TournamentPlayers(props: any) {
  const history = useHistory();
  const { tournamentId }: any = useParams();

  console.log(tournamentId);

  const [state, setState] = useState<IMainState>({
    tournamentPlayers: [],
    search: "",
    customers: [],
  });

  const [newPlayerState, setNewPlayerState] = useState<INewPlayerState>({
    first_name: "",
    last_name: "",
    email_address: "",
    phone_number: "",
    newPlayerSheetActive: false,
  });

  useEffect(() => {
    void loadTournamentPlayers();
  }, []);

  async function loadTournamentPlayers() {
    const params = {
      tournament_id: tournamentId,
    };

    const tournamentPlayersRes = await GetTournamentPlayers(params, true);

    console.log(tournamentPlayersRes);
    if (tournamentPlayersRes.status === StatusCode.OK) {
      setState(prevState => ({
        ...prevState,
        tournamentPlayers: tournamentPlayersRes.data,
      }));
    }
  }

  function saveTournament() {
    console.log(state);
  }

  function showNewPlayerSheet() {
    setNewPlayerState(prev => ({ ...prev, newPlayerSheetActive: true }));
  }

  function handleAddPlayer() {
    void showNewPlayerSheet();
  }

  const primaryAction = {
    content: "Add Player",
    action: handleAddPlayer,
  };

  const columns = ["Player Name", "Email", "Phone", "Financial Status", "Registered On"];

  const dataSource: (string | JSX.Element)[][] = [];

  state.tournamentPlayers?.forEach(player => {
    console.log(player);
    dataSource.push([
      player.customer.full_name,
      player.customer.email,
      player.customer.phone,
      player.order_financial_status,
      player.created_at,
    ]);
  });

  // Handle tab change
  const [selected, setSelected] = useState(0);
  const handleTabChange = (selectedTabIndex: number) => {
    setSelected(selectedTabIndex);

    // if (selectedTabIndex === 0) {
    // }

    // if (selectedTabIndex === 1) {
    // }
  };

  const search = async (mounted: boolean, search: string) => {
    try {
      if (search === "") {
        if (mounted) {
          setState(prevState => ({ ...prevState, customers: null }));
        }
        return;
      } else {
        const res = await loadCustomers(search);
        if (mounted) {
          setState(prevState => ({ ...prevState, customers: res }));
        }
      }
    } catch (error) {
      console.log("err", error);
    }
    return;
  };

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = global.setTimeout(() => {
        void search(mounted, state.search);
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);

      setState(prevState => ({ ...prevState, customers: null }));
    };
  }, [state.search]);

  async function addNewPlayer(customer: any) {
    console.log(customer);
    console.log("Add New Player");

    const params = {
      tournament_id: tournamentId,
      customer_id: Number(customer.id),
      //first_name: newPlayerState.first_name,
      //last_name: newPlayerState.last_name,
      //email: customer.email,
      // phone_number: newPlayerState.phone_number,
    };

    console.log(params);

    const addPlayerToTournamentRes = await AddPlayerToTournament(params, true);
    console.log(addPlayerToTournamentRes);

    if (addPlayerToTournamentRes.status === StatusCode.OK) {
      void loadTournamentPlayers();
      setNewPlayerState(prev => ({ ...prev, newPlayerSheetActive: false }));
    }
  }

  function createPlayerInputChange(e: any) {
    const id = e.target.id;
    const value = e.target.value;

    setNewPlayerState(prev => ({ ...prev, [id]: value }));
  }

  function closeNewPlayerSheet() {
    setNewPlayerState(prev => ({
      ...prev,
      newPlayerSheetActive: false,
      first_name: "",
      last_name: "",
      email_address: "",
      phone_number: "",
    }));
  }

  async function createPlayer() {
    console.log(newPlayerState);

    const params = {
      league_id: Number(tournamentId),
      first_name: newPlayerState.first_name,
      last_name: newPlayerState.last_name,
      email: newPlayerState.email_address,
      phone_number: newPlayerState.phone_number,
    };

    const addPlayerToLeagueRes = await AddPlayerToTournament(params, true);
    console.log(addPlayerToLeagueRes);

    if (addPlayerToLeagueRes.status === StatusCode.OK) {
      void closeNewPlayerSheet();
      void loadTournamentPlayers();
    }
  }

  const tabs = [
    {
      id: "search-player",
      content: "Search Player",
    },
    {
      id: "add-player",
      content: "Add New Player",
    },
  ];

  async function loadCustomers(searchQuery: string) {
    const customerRes = await GetCustomer({ search: searchQuery }, false);

    console.log("Customers;", customerRes);
    if (customerRes.status !== StatusCode.OK) {
      return;
    }

    return customerRes.data as [];
  }

  return (
    <Page title="Player List" narrow primaryAction={primaryAction}>
      <DataTable
        headings={columns}
        columnContentTypes="numeric"
        rows={dataSource}
        style={{ height: "calc(100vh - 192px)", overflowY: "scroll" }}
      />

      <Sheet
        open={newPlayerState.newPlayerSheetActive}
        size="small"
        closable
        stacked
        title={"New Player"}
        onCancel={closeNewPlayerSheet}
        onOk={createPlayer}
        cancelText={"Cancel"}
        okText={"Save"}
        okDisabled={
          newPlayerState.first_name === "" ||
          newPlayerState.last_name === "" ||
          (newPlayerState.email_address === "" && newPlayerState.phone_number === "")
        }
      >
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          {selected === 0 ? (
            <div style={{ marginTop: "20px" }}>
              <div className="flex-grow mb-4">
                <Input
                  value={state.search}
                  onChange={(value: any) => setState(prevState => ({ ...prevState, search: value.target.value }))}
                  type="search"
                  placeholder="Search for a player..."
                ></Input>
              </div>

              <div className="flex-grow mb-4">
                {state.customers?.map((customer: any, index: number) => {
                  console.log(customer);
                  return (
                    <div
                      key={index}
                      className="flex justify-between league-customer-results"
                      onClick={() => addNewPlayer(customer)}
                    >
                      <div>
                        <div className="font-semibold text-lg">{customer?.full_name}</div>
                        <div className="text-sm text-gray-500">{customer.email}</div>
                        <div className="text-sm text-gray-500">{customer.phone ? customer.phone : null}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}

          {selected === 1 ? (
            <div style={{ marginTop: "20px" }}>
              <FormLayout>
                <FormLayout.Group>
                  <Input
                    placeholder="First Name"
                    id="first_name"
                    value={newPlayerState.first_name}
                    onChange={createPlayerInputChange}
                  />
                  <Input
                    placeholder="Last Name"
                    id="last_name"
                    value={newPlayerState.last_name}
                    onChange={createPlayerInputChange}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Input
                    placeholder="Email Address"
                    id="email_address"
                    value={newPlayerState.email_address}
                    onChange={createPlayerInputChange}
                  />
                  <Input
                    placeholder="Phone Number"
                    id="phone_number"
                    value={newPlayerState.phone_number}
                    onChange={createPlayerInputChange}
                  />
                </FormLayout.Group>
              </FormLayout>
            </div>
          ) : null}
        </Tabs>
      </Sheet>
    </Page>
  );
}
