import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";

const apiVersion = "2021-01";
const apiVersionNew = "2022-07";
const adminType = "facility-admin";
const baseCartUrl = "/" + apiVersion + "/" + adminType + "/cart?";
const baseCartLineItemUrl = "/" + apiVersion + "/" + adminType + "/cart/line-item";
const baseCartClearUrl = "/" + apiVersion + "/" + adminType + "/cart/clear?";
const baseNewCartUrl = "/" + apiVersionNew + "/" + adminType + "/cart";
export interface IPostLineItemToCart {
  cart_id: number;
  variant_id: number;
  quantity: number;
  parent_id?: number;
  note?: string;
}

export interface IPutLineItemToCart {
  id: number;
  quantity?: number;
  price?: number;
  note?: string;
}

export interface IPutPrice {
  id: number;
  price: number;
}

export interface IDeleteLineItemToCart {
  id: number;
}

export interface IGetCart {
  id?: number;
  token?: string;
  tableCart?: Record<string, any>;
}

interface ISplitLineItem {
  line_item_ids: Array<number>;
  cart_ids: Array<number>;
}

interface IMoveLineItem {
  line_items: Array<{ id: number; quantity: string }>;
  cart_id: number; //cart to move to
}

// GET Cart
// Retrieve a list of carts
export async function GetCart(params: IGetCart, useGlobalLoader: boolean) {
  return await APIGet(baseCartUrl, params, useGlobalLoader);
}

// POST Cart
// Create a new, empty cart
export async function PostCart(params: string, useGlobalLoader: boolean) {
  return await APIPost(baseCartUrl + params, useGlobalLoader);
}

// //PUT Cart
export async function PutCart(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseCartUrl, params, useGlobalLoader);
}

export async function PutPrice(params: IPutPrice, useGlobalLoader: boolean) {
  return await APIPut(baseCartLineItemUrl + "/price", params, useGlobalLoader);
}

export interface IPutClearCart {
  cart_id: number;
}

export async function PutClearCart(params: IPutClearCart, useGlobalLoader: boolean) {
  return await APIPut(baseCartClearUrl, params, useGlobalLoader);
}

// Post Line Item
// Add a new item to a cart
// cart_id: number
// variant_id: number
// quantity: number\
export async function PostLineItemToCart(params: IPostLineItemToCart, useGlobalLoader: boolean) {
  return await APIPost(baseCartLineItemUrl, params, useGlobalLoader);
}

export async function PutLineItemToCart(params: IPutLineItemToCart, useGlobalLoader: boolean) {
  return await APIPut(baseCartLineItemUrl, params, useGlobalLoader);
}

export async function DeleteLineItemToCart(params: IDeleteLineItemToCart, useGlobalLoader: boolean) {
  return await APIDelete(baseCartLineItemUrl, params, useGlobalLoader);
}

export async function PutLineItemSplit(params: ISplitLineItem, useGlobalLoader: boolean) {
  return await APIPut(baseNewCartUrl + "/line-item/split", params, useGlobalLoader);
}

export async function PutLineItemMove(params: IMoveLineItem, useGlobalLoader: boolean) {
  return await APIPut(baseNewCartUrl + "/line-item/move", params, useGlobalLoader);
}
