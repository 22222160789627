import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";

import { formatDate } from "helpers/Helpers";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { MOBILE_WIDTH, TABLET_WIDTH } from "helpers/ScreenSizes";

import { GetReport, GetReportRun, GetCSVDownload } from "api/rpc/2024-04/facilityAdmin/report/report";
import { StatusCode } from "api/protocols";
import { IConfiguration } from "api/rpc/clientAdmin/client/report";

import Page from "components/page/Page";
import Card from "components/card/Card";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import TicketsRedeemedTable from "../tables/TicketsRedeemedTable";

import "../report.scss";

interface IReportState {
  id: number;
  title: string;
  code: string;
  dateRangeStart: Date;
  dateRangeEnd: Date;
  singleDate: Date;
  configurations: Array<IConfiguration>;
  date_range: Array<string>;
  date: string;
  facilityOptions: Array<{ label: string; onClick: () => void }>;
  facility_id_name: string;
  [key: string]: any;
}

interface IReportDataState {
  headings: any;
  rows: any;
  columns: Array<Record<string, any>>;
  tableDisplay: boolean;
  description: string;
  compileDate: string;
}

export default function TicketsRedeemed(props: any) {
  const windowSize = useWindowSize();
  const location = useLocation();
  const routeName = location.pathname.split("/");
  const code = routeName[routeName.length - 1];

  function formatDateRange(startingDate: Date, endingDate: Date) {
    return [formatDate(startingDate), formatDate(endingDate) + " 23:59:00"];
  }

  const today = new Date();

  const [state, setState] = useState<IReportState>({
    dateRangeStart: today,
    dateRangeEnd: today,
    singleDate: today,
    id: null,
    title: "",
    code: "",
    configurations: [],
    date_range: formatDateRange(today, today),
    date: formatDate(today),
    facilityOptions: [],
    facility_id_name: "",
  });

  const [reportData, setReportData] = useState<IReportDataState>({
    headings: [],
    rows: [],
    columns: [],
    tableDisplay: false,
    description: "",
    compileDate: "",
  });

  useEffect(() => {
    void loadReport();
  }, []);

  async function loadReport() {
    const reportRes = await GetReport({ code: code }, true);
    if (reportRes.status !== StatusCode.OK) {
      return;
    }

    const report = reportRes.data[0];
    const sortedConfigurations = [...report.configurations].sort((prev, next) => prev.position - next.position);

    setState(prev => ({
      ...prev,
      id: report.id,
      title: report.title,
      code: report.code,
      configurations: sortedConfigurations,
    }));
  }

  async function getReport() {
    const reportRes = await GetReportRun(
      {
        report_id: state.id,
        date_range: state.date_range,
      },
      true,
    );

    if (reportRes.status !== StatusCode.OK) {
      return;
    }

    const compileDate = reportRes.data?.compiled_at ? reportRes.data?.compiled_at.concat("Z") : "";

    setReportData(prev => ({
      ...prev,
      rows: reportRes.data.rows,
      columns: reportRes.data.columns,
      description: reportRes.data?.description ?? "",
      compileDate,
    }));

    return;
  }

  async function getDownload() {
    const segmentsRes = await GetCSVDownload(
      {
        report_id: state.id,
        type: "csv",
        date_range: state.date_range,
      },
      true,
    );

    // Do we need a translation for this?
    if (segmentsRes.status !== StatusCode.OK) {
      props.uiActions.showError("Error exporting transaction report");
      return;
    }

    const reportExport = document.createElement("a");
    reportExport.href = "data:text/csv;charset=utf-8," + encodeURI(segmentsRes.data);
    reportExport.target = "_blank";
    reportExport.download = `${state.title}_${moment(new Date()).format("YYYY-MM-DD").toString()}`
      .replace(/ /g, "_")
      .toLowerCase();
    reportExport.click();
  }

  async function getPrint() {
    const printRes = await GetReportRun(
      {
        report_id: state.id,
        type: "print",
        date_range: state.date_range,
      },
      true,
    );

    // Do we need a translation for this?
    if (printRes.status !== StatusCode.OK) {
      props.uiActions.showError("Error printing report");
      return;
    }

    window.open().document.write(printRes.data);
  }

  useEffect(() => {
    if (state.dateRangeStart && state.dateRangeEnd) {
      const date_range = formatDateRange(state.dateRangeStart, state.dateRangeEnd);
      setState(prevState => ({ ...prevState, date_range }));
    }
  }, [state.dateRangeStart, state.dateRangeEnd]);

  function setDateRangeStart(date: Date) {
    setState(prevState => ({
      ...prevState,
      dateRangeStart: date,
    }));
  }

  function setDateRangeEnd(date: Date) {
    setState(prevState => ({
      ...prevState,
      dateRangeEnd: date,
    }));
  }

  const primaryAction = {
    content: "Run",
    action: getReport,
  };

  return (
    <Page
      title="Tickets Redeemed Report"
      primaryAction={primaryAction}
      multipleActionDropdownAction={{
        label: "Options",
        dropdownProps: {
          alignment: "right",
          options: [
            {
              type: "handler",
              label: "Export",
              handler: () => getDownload(),
              icon: "file-arrow-down",
            },
            {
              type: "handler",
              label: "Print",
              handler: () => getPrint(),
              icon: "print",
            },
          ],
        },
      }}
      breadcrumbs={[{ prefix: true, label: "Back to Reports", url: "/admin/report" }]}
    >
      <Card>
        <Card.Section>
          <div className="report-filters-container">
            <DatePickerInput
              months={windowSize.width > TABLET_WIDTH ? 2 : 1}
              position="left"
              showQuickOptions={windowSize.width > MOBILE_WIDTH}
              startingDate={state.dateRangeStart}
              setStartingDate={setDateRangeStart}
              endingDate={state.dateRangeEnd}
              setEndingDate={setDateRangeEnd}
            />
          </div>
        </Card.Section>
      </Card>

      <div className="dynamic-table-container">
        <TicketsRedeemedTable
          data={reportData.rows}
          columns={reportData.columns}
          description={reportData.description}
          compileDate={reportData.compileDate}
        />
      </div>
    </Page>
  );
}
