import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";

import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Form from "components/form/Form";
import Input from "components/form/input/Input";
import { Select } from "components/select/index";

import { IRegisterGroup } from "../../Admin";
import { Prev } from "react-bootstrap/esm/PageItem";

import { useTranslation } from "react-i18next";
import { GetRegisterGroups } from "api/rpc/2024-04/facilityAdmin/facility/register/group";
import { PostCardReader } from "api/rpc/2024-04/facilityAdmin/payment/teeonPayments/reader";

interface IProps {
  uiActions: any;
}

export default function CardReaderNew(props: IProps) {
  const { uiActions } = props;
  const { Option } = Select;

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const primaryAction = {
    content: t("secure.facility.settings.card_reader.card_reader_new.001"),
    action: createCardReader,
  };

  const [state, setState] = useState({
    readers: [],
  });

  const [readerState, setCardReaderState] = useState({
    label: "",
    registration_code: "",
    register_group_id: undefined,
  });

  async function createCardReader() {
    const res = await PostCardReader(readerState, true);
    if (res.status === StatusCode.OK) {
      uiActions.showSuccess(t("secure.facility.settings.card_reader.card_reader_new.011"));
    } else {
      uiActions.showError(t("secure.facility.settings.card_reader.card_reader_new.012"));
      return;
    }

    history.push("/admin/settings/card_reader");
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;

    setCardReaderState(prevState => ({ ...prevState, [id]: value }));
  }

  const [registerGroups, setRegisterGroups] = useState<IRegisterGroup[]>([]);

  const LoadRegisterGroups = async () => {
    const res = await GetRegisterGroups();

    if (res.status === StatusCode.OK) {
      if (res.data) {
        setRegisterGroups(res.data);
      }
    } else {
      uiActions.showError(t("secure.facility.settings.card_reader.card_reader_new.013"));
    }
  };

  const onChangeRegisterGroup = (register_group_id: number) => {
    setCardReaderState(prevState => ({ ...prevState, register_group_id: register_group_id }));
  };
  useEffect(() => {
    void LoadRegisterGroups();
  }, []);

  return (
    <Page
      title={t("secure.facility.settings.card_reader.card_reader_new.002")}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.card_reader.card_reader_new.004"),
          url: "/admin/settings/card_reader",
        },
      ]}
    >
      <Card>
        <Card.Section>
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <div>{t("secure.facility.settings.card_reader.card_reader_new.005")}</div>
              </FormLayout.Group>
              <FormLayout.Group>
                <Input
                  value={readerState.label ? readerState.label : ""}
                  label={t("secure.facility.settings.card_reader.card_reader_new.006")}
                  id="label"
                  onChange={handleInputChange}
                  placeholder={t("secure.facility.settings.card_reader.card_reader_new.007")}
                />
                <Input
                  value={readerState.registration_code ? readerState.registration_code : ""}
                  label={t("secure.facility.settings.card_reader.card_reader_new.008")}
                  id="registration_code"
                  onChange={handleInputChange}
                  placeholder={t("secure.facility.settings.card_reader.card_reader_new.009")}
                />
                <Select
                  onChange={onChangeRegisterGroup}
                  label={t("secure.facility.settings.card_reader.card_reader_new.010")}
                >
                  {registerGroups.map(registerGroup => (
                    <Option key={registerGroup.id} value={registerGroup.id}>
                      {registerGroup.title}
                    </Option>
                  ))}
                </Select>
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
      </Card>
    </Page>
  );
}
