import { IFacility } from "./models/facility";
import { ILocation, IModule, IReservationBooking, ISegment } from "./models/reservations";
import { IUser, IUserActive } from "./models/user";

export interface IReservationState {
  module: IModule;
  selectedSegment: ISegment;
  selectedLocation: ILocation;
  active_user: IUser | IUserActive;
  facility: Partial<IFacility>;
  booking: IReservationBooking;
  /**An array of segment objects that have been booked. It is used to display times & prices on the confirmation page */
  bookedSegments: Array<ISegment>;
}

export interface IReservationAction {
  type: string;
  payload: Partial<IReservationState>;
}

const initialState: IReservationState = {
  module: null,
  selectedSegment: null,
  selectedLocation: null,
  active_user: null,
  facility: null,
  booking: null,
  bookedSegments: null,
};

export default function ReservationsReducer(state = initialState, action: IReservationAction) {
  switch (action.type) {
    case "reservation.update":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
