import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { ITournament } from "redux/reducers/models/tournament";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseTournamentUrl = "/" + apiVersion + "/" + adminType + "/tournament";

export type TGetTournament = {
  id?: string | number;
  ids?: Array<string> | Array<number>;
  search?: string; // Search by tournament name
  year?: number;
  limit?: number;
  offset?: number;
};

export type TPostTournament = {
  name: string;
  start_time: string;
  date: string;
};

export type TPutTournament = {
  id: string | number;

  name?: string;
  start_time?: string;
  date?: string | Date;
  description?: string;
  player_limit?: number;
  waitlist_limit?: number;
  tee_sheet_note?: string;

  registration_open_date?: string;
  registration_close_date?: string;
  display_online?: boolean;

  registration_enabled?: boolean;

  minimum_registration_amount?: number;
  maximum_registration_amount?: number;

  // registration_deadline?: Date;
  // capacity?: number;
  // logo_source?: string;
};

// GET Tournament
// Retrieve a list of tournaments
export async function GetTournament(params: TGetTournament, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<ITournament[]>(baseTournamentUrl, params, useGlobalLoader, token);
}

// POST Tournament
// Create a new tournament
export async function PostTournament(params: TPostTournament, useGlobalLoader: boolean) {
  return await APIPost<ITournament>(baseTournamentUrl, params, useGlobalLoader);
}

// PUT Tournament
// Update an existing tournament
export async function PutTournament(params: TPutTournament, useGlobalLoader: boolean) {
  return await APIPut<ITournament>(baseTournamentUrl, params, useGlobalLoader);
}

// PUT Tournament
// Update an existing tournament
export async function DeleteTournament(params: { id: string | number }, useGlobalLoader: boolean) {
  return await APIDelete(baseTournamentUrl, params, useGlobalLoader);
}

//Tournament Logo
export async function UploadTournamentLogo(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseTournamentUrl + "/logo", params, useGlobalLoader);
}

export async function DeleteTournamentLogo(params: any, useGlobalLoader: boolean) {
  return await APIDelete(baseTournamentUrl + "/logo", params, useGlobalLoader);
}

export async function GetTournamentSponsorship(
  params: { tournament_id?: string },
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseTournamentUrl + "/sponsorship", params, useGlobalLoader, token);
}

export async function PostTournamentSponsorship(params: FormData, useGlobalLoader: boolean) {
  return await APIPost(baseTournamentUrl + "/sponsorship", params, useGlobalLoader);
}

export async function DeleteTournamentSponsorship(params: { id: number }, useGlobalLoader: boolean) {
  return await APIDelete(baseTournamentUrl + "/sponsorship", params, useGlobalLoader);
}
