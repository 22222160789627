import { StatusCode } from "api/protocols";
import { GetCustomer, PostCustomer } from "api/rpc/2022-09/facilityAdmin/customer/customer";
import { IRainCheck, PutRainCheck } from "api/rpc/facilityAdmin/payment/rainCheck";
import { Badge } from "components/badge/Badge";
import Card from "components/card/Card";
import Page from "components/page/Page";
import Sheet from "components/sheet/Sheet";
import { capitalize, customerErrorMessage, uppercase } from "helpers/Helpers";
import { LocaleCurrency } from "helpers/Locale";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { IUIActions } from "redux/actions/ui";
import { ICustomer } from "redux/reducers/models/customer";
import { Select } from "components/select/index";
import "./giftCard.scss";
import GolferCard from "components/bookingPopUp/golferCard/GolferCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDOM from "react-dom";
import { IOrder } from "redux/reducers/models/order";
import { GetOrder } from "api/rpc/2022-09/facilityAdmin/order/order";
import Popup from "components/popup/Popup";
import Input from "components/form/input/Input";

import { useTranslation } from "react-i18next";

import NewCustomer, { ICustomerInfoState } from "components/newCustomer/NewCustomer";
import {
  EmailRainCheckReceipt,
  GetRainCheckReceipt,
  GetRainChecks,
} from "api/rpc/2024-04/facilityAdmin/payment/rainCheck";

interface IRainCheckState {
  rainCheck: IRainCheck;
  customer: ICustomer;
  order: IOrder;
  showChangeCustomerModal: boolean;
  customerQuery: string;
  selectedCustomer: ICustomer;
  customerSearching: boolean;
  customerSearchResult: Array<ICustomer>;
  showRedeemModal: boolean;
  showPrintModal: boolean;
  showEmailModal: boolean;
}

interface INewCustomerState {
  showNewCustomerModal: boolean;
}

interface IProps {
  uiActions: IUIActions;
}

interface IEmailState {
  modalVisible: boolean;
  email: string;
}

export default function RainCheck(props: IProps) {
  const { uiActions } = props;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { rainCheckId } = useParams<{ rainCheckId: string }>();
  const { Option } = Select;
  const [state, setState] = useState<IRainCheckState>({
    rainCheck: null,
    customer: null,
    order: null,
    showChangeCustomerModal: false,
    customerQuery: "",
    selectedCustomer: null,
    customerSearching: false,
    customerSearchResult: [],
    showRedeemModal: false,
    showEmailModal: false,
    showPrintModal: false,
  });

  const [emailState, setEmailState] = useState<IEmailState>({
    modalVisible: false,
    email: "",
  });

  const [newCustomerState, setNewCustomerState] = useState<INewCustomerState>({
    showNewCustomerModal: false,
  });

  useEffect(() => {
    void loadRainCheck();
  }, []);

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (async () => {
          if (state.customerQuery !== "") {
            try {
              setState(prevState => ({ ...prevState, customerSearching: true }));
              const customers = await searchCustomer();
              if (mounted) {
                setState(prevState => ({ ...prevState, customerSearchResult: customers, customerSearching: false }));
              }
            } catch (error) {
              console.log("err", error);
            }
            return;
          } else {
            setState(prevState => ({ ...prevState, customerSearchResult: [], customerSearching: false }));
          }
        })();
      }, 1000);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [state.customerQuery]);

  async function searchCustomer() {
    const customerRes = await GetCustomer({ search: state.customerQuery }, false);
    if (customerRes.status !== StatusCode.OK) {
      return [];
    }
    return customerRes.data;
  }

  async function loadRainCheck() {
    uiActions.enqueue();

    const rainCheckRes = await GetRainChecks({ id: Number(rainCheckId) }, false);

    if (rainCheckRes.status !== StatusCode.OK || rainCheckRes?.data?.length === 0) {
      uiActions.showError(t("secure.facility.order.rain_check.001"));
      history.push("/admin/rainchecks");
      uiActions.dequeue();
      return;
    }
    const rainCheck = rainCheckRes.data[0];

    if (rainCheck?.customer_id) {
      await loadCustomer(rainCheck?.customer_id);
    }

    if (rainCheck?.order_id) {
      await loadOrder(rainCheck?.order_id);
    }

    uiActions.dequeue();
    setState(prevState => ({ ...prevState, rainCheck: rainCheckRes.data[0] }));
    setEmailState(prevState => ({ ...prevState, email: rainCheckRes.data[0].customer?.email }));
  }

  async function loadCustomer(customer_id: number) {
    const customerRes = await GetCustomer({ id: customer_id }, false);
    if (customerRes.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.order.rain_check.002"));
      return;
    }
    setState(prevState => ({ ...prevState, customer: customerRes.data[0] }));
  }

  async function loadOrder(orderId: number) {
    const orderRes = await GetOrder({ id: orderId }, false);
    if (orderRes.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.order.rain_check.003"));
      return;
    }
    setState(prevState => ({ ...prevState, order: orderRes.data[0] }));
  }

  function closeChangeCustomerModal() {
    setState(prevState => ({
      ...prevState,
      showChangeCustomerModal: false,
      customerQuery: "",
      customerSearchResult: [],
      selectedCustomer: null,
    }));
  }

  function handleCustomerSearch(query: string) {
    setState(prevState => ({ ...prevState, customerQuery: query }));
  }

  function handleCustomerSelection(id: number, customer: ICustomer) {
    setState(prevState => ({ ...prevState, selectedCustomer: customer }));
  }

  async function assignCustomer() {
    const updateRainCheckRes = await PutRainCheck(
      { id: Number(rainCheckId), customer_id: state.selectedCustomer?.id },
      true,
    );
    if (updateRainCheckRes.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.order.rain_check.004"));
      return;
    }
    uiActions.showSuccess(t("secure.facility.order.rain_check.005"));
    void closeChangeCustomerModal();
    await loadRainCheck();
  }

  async function handleRainCheckRedeem() {
    const updateRainCheckRes = await PutRainCheck({ id: Number(rainCheckId), status: "redeemed" }, true);

    if (updateRainCheckRes.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.order.rain_check.006"));
      return;
    }

    uiActions.showSuccess(t("secure.facility.order.rain_check.007"));
    setState(prevState => ({ ...prevState, showRedeemModal: false }));
    await loadRainCheck();
  }

  async function createNewCustomer(customerInfo: ICustomerInfoState) {
    if (
      !customerInfo.firstName ||
      !customerInfo.lastName ||
      (!customerInfo.emailAddress && !customerInfo.phoneNumber)
    ) {
      uiActions.showError(t("secure.facility.order.rain_check.008"));
    }

    const customerRes = await PostCustomer(
      {
        first_name: customerInfo.firstName,
        last_name: customerInfo.lastName,
        phone: customerInfo.phoneNumber ?? null,
        email: customerInfo.emailAddress ?? null,
      },
      true,
    );

    if (customerRes.status !== StatusCode.OK) {
      uiActions.showError(customerErrorMessage(t, customerRes?.data?.message));
      return;
    }
    ReactDOM.unstable_batchedUpdates(() => {
      setNewCustomerState(prevState => ({
        ...prevState,
        showNewCustomerModal: false,
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
      }));
      setState(prevState => ({ ...prevState, selectedCustomer: customerRes.data }));
    });
    uiActions.showSuccess(t("secure.facility.order.rain_check.009"));
  }

  function openNewCustomerSheet() {
    setNewCustomerState(prevState => ({
      ...prevState,
      showNewCustomerModal: true,
    }));
  }

  function navigateToOrder() {
    if (state.rainCheck?.order_id) {
      history.push("/admin/order/" + String(state.rainCheck?.order_id));
    }
  }

  async function printReceipt() {
    if (state.rainCheck) {
      const rainCheckReceiptRes = await GetRainCheckReceipt({ id: state.rainCheck.id }, true);
      if (rainCheckReceiptRes.status !== StatusCode.OK) {
        uiActions.showError(rainCheckReceiptRes.message);
        return;
      } else {
        window.open().document.write(rainCheckReceiptRes.data);
      }
    }
  }

  async function emailReceipt() {
    const emailReceiptRes = await EmailRainCheckReceipt(
      { id: state?.rainCheck?.id, customer_email: emailState?.email },
      true,
    );
    if (emailReceiptRes?.status !== StatusCode.OK) {
      uiActions.showError("Error sending email rain check");
      return;
    }
    setEmailState(prevState => ({ ...prevState, modalVisible: false, email: "" }));
    uiActions.showSuccess("Email rain check sent successfully");
  }

  function handleEmailInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setEmailState(prevState => ({ ...prevState, [id]: value }));
  }

  return (
    <>
      <Page
        narrow
        splitLayout
        title={t("secure.facility.order.rain_check.010")}
        subtitle={`•••• •••• ${state.rainCheck?.last4}`}
        breadcrumbs={[{ prefix: true, label: t("secure.facility.order.rain_check.011"), url: "/admin/rainchecks" }]}
        multipleActionDropdownAction={{
          label: "Options",
          dropdownProps: {
            alignment: "right",
            options: [
              {
                type: "handler",
                label: t("secure.facility.order.rain_check.012"),
                icon: "ticket",
                handler: () => setState(prevState => ({ ...prevState, showRedeemModal: true })),
              },
              {
                type: "handler",
                label: "Print",
                handler: () => printReceipt(),
                icon: "print",
              },
              {
                type: "handler",
                label: "Email",
                handler: () =>
                  setEmailState(prevState => ({
                    ...prevState,
                    modalVisible: true,
                    email: state.rainCheck.customer?.email,
                  })),
                icon: "envelope",
              },
            ],
          },
        }}
      >
        {state?.rainCheck && (
          <>
            <Page.Section twoThirds>
              {/* Rain Check Details Card*/}
              <Card title={t("secure.facility.order.rain_check.013")}>
                <Card.Section>
                  <div className="orders-giftcard">
                    <div className="giftcard-column">
                      <div className="giftcard-row">
                        <p className="giftcard-details-title">{t("secure.facility.order.rain_check.014")}</p>
                        <p className="giftcard-details-value">
                          <LocaleCurrency currency={state.rainCheck?.currency} amount={state.rainCheck?.balance} />
                        </p>
                      </div>
                      <div className="giftcard-row">
                        <p className="giftcard-details-title">{t("secure.facility.order.rain_check.015")}</p>
                        <p className="giftcard-details-value">{uppercase(state.rainCheck?.currency)}</p>
                      </div>
                      <div className="giftcard-row">
                        <p className="giftcard-details-title">{t("secure.facility.order.rain_check.016")}</p>
                        <Badge size="small" type="success">
                          {capitalize(state.rainCheck?.status)}
                        </Badge>
                      </div>
                    </div>
                  </div>
                </Card.Section>
              </Card>
            </Page.Section>

            <Page.Section oneThird>
              <Card
                title={t("secure.facility.order.rain_check.017")}
                titleActions={[
                  {
                    action: () => setState(prevState => ({ ...prevState, showChangeCustomerModal: true })),
                    content: t("secure.facility.order.rain_check.018"),
                  },
                ]}
              >
                {/* Customer Card */}
                <Card.Section>
                  {state.customer ? (
                    <div>
                      <p className="text-lg">{state?.customer?.full_name}</p>
                      <p className="text-lg">{state?.customer?.email}</p>
                      <p className="text-lg">{state?.customer?.phone}</p>
                      <p className="text-lg">{state?.customer?.customer_type}</p>
                    </div>
                  ) : (
                    <p>{t("secure.facility.order.rain_check.019")}</p>
                  )}
                </Card.Section>
                {/* End Customer Card */}
              </Card>

              {state?.order && (
                <Card
                  title={`${t("secure.facility.order.rain_check.020")} ${state.order?.name}`}
                  titleActions={[
                    {
                      action: navigateToOrder,
                      content: t(t("secure.facility.order.rain_check.021")),
                    },
                  ]}
                >
                  {/* Rain Check Order Card */}
                  <Card.Section>
                    <div className="orders-giftcard">
                      <div className="giftcard-column">
                        <div className="giftcard-row">
                          <p className="giftcard-details-title">{"Financial Status"}</p>
                          <Badge size="small" type="success">
                            {capitalize(state?.order?.financial_status)}
                          </Badge>
                        </div>
                        <div className="giftcard-row">
                          <p className="giftcard-details-title">{"Total"}</p>
                          <LocaleCurrency currency={state?.order?.currency} amount={state?.order?.total_price} />
                        </div>
                      </div>
                    </div>
                  </Card.Section>
                  {/* Rain Check Order Card End */}
                </Card>
              )}
            </Page.Section>
          </>
        )}
      </Page>

      {/* Golfer Card */}
      <div className="orders-giftcard-container-overflow">
        <Sheet
          title={t("secure.facility.order.rain_check.022")}
          open={state.showChangeCustomerModal}
          size="small"
          closable
          onCancel={closeChangeCustomerModal}
          onOk={assignCustomer}
          okText={t("secure.facility.order.rain_check.023")}
          okDisabled={!state.selectedCustomer ? true : false}
        >
          {state.customer !== null ? (
            <div>
              <span>{t("secure.facility.order.rain_check.024")}</span>
              <GolferCard
                email={state.customer?.email}
                name={state.customer?.full_name}
                memberCode={state.customer?.member_code}
                customerType={state.customer?.customer_type}
                phone={state.customer?.phone}
              />

              <FontAwesomeIcon className="orders-giftcard-arrow-icon" icon={["far", "arrow-circle-down"]} size="2x" />
            </div>
          ) : null}
          {state.selectedCustomer ? (
            <GolferCard
              closable
              removeGolfer={() =>
                setState(prevState => ({
                  ...prevState,
                  selectedCustomer: null,
                  customerSearchResult: [],
                  customerQuery: "",
                }))
              }
              email={state?.selectedCustomer?.email}
              name={state?.selectedCustomer?.full_name}
              memberCode={state?.selectedCustomer?.member_code}
              customerType={state?.selectedCustomer?.customer_type}
              phone={state?.selectedCustomer?.phone}
            />
          ) : (
            <Select
              showSearch
              onSearch={(query: string) => handleCustomerSearch(query)}
              onChange={(id: number, customer: ICustomer) => handleCustomerSelection(id, customer)}
              placeholder={t("secure.facility.order.rain_check.025")}
              allowClear
              searchValue={state.customerQuery}
              showDropDownOnFocus={true}
              searching={state.customerSearching}
            >
              <div className="ui-select-dropdown-list-item" onClick={() => openNewCustomerSheet()}>
                <p>{"New Customer"}</p>
              </div>
              {state.customerSearchResult?.map((customer, index) => {
                return (
                  <Option key={index} value={customer.id} name={customer.full_name} extraValues={customer}>
                    <div className="flex justify-between">
                      <div>
                        <div className="text-semibold text-lg">{customer?.full_name}</div>
                        <div className="text-sm text-gray-500">{customer.customer_type}</div>
                        <div className="text-sm text-gray-500">{customer.email}</div>
                        <div className="text-sm text-gray-500">{customer.phone ? customer.phone : null}</div>
                      </div>

                      <div className="font-medium text-base text-gray-500 self-end">{customer.member_code}</div>
                    </div>
                  </Option>
                );
              })}
            </Select>
          )}
        </Sheet>

        <NewCustomer
          newCustomerSheetActive={newCustomerState.showNewCustomerModal}
          onCancel={() =>
            setNewCustomerState(prevState => ({
              ...prevState,
              showNewCustomerModal: false,
            }))
          }
          onOk={createNewCustomer}
          searchValue={state.customerQuery}
        />
      </div>
      {/* End Golfer Card */}

      <Popup
        type="warning"
        open={state.showRedeemModal}
        title={t("secure.facility.order.rain_check.026")}
        description={t("secure.facility.order.rain_check.027")}
        closable
        onOk={handleRainCheckRedeem}
        onCancel={() => setState(prevState => ({ ...prevState, showRedeemModal: false }))}
        okText={t("secure.facility.order.rain_check.012")}
      />

      <Sheet
        title="Enter Email"
        open={emailState.modalVisible}
        size="small"
        stacked
        closable
        onCancel={() => setEmailState(prevState => ({ ...prevState, modalVisible: false, email: "" }))}
        onOk={emailReceipt}
        okText="Send Email"
        okDisabled={!emailState.email ? true : false}
      >
        <Input placeholder="Email" value={emailState.email} id="email" onChange={handleEmailInputChange} />
      </Sheet>
    </>
  );
}
