import { APIGet, APIPost, APIPut, APIDelete, StatusCode } from "api/protocols";
import { IOrderTransaction } from "redux/reducers/models/order";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseOrderUrl = "/" + apiVersion + "/" + adminType + "/order";

interface IPostRefund {
  order_id?: number;
  refund_line_items?: { line_item_id: number; refund_quantity: number; refund_amount: number }[];
  refund_transactions?: any[];
}

interface IPostCalculateRefund {
  order_id?: number;
  refund_line_items?: { line_item_id: number; refund_quantity: number; refund_amount: number }[];
  refund_transactions?: IOrderTransaction[];
  refund_tip?: boolean;
}

export async function PostRefund(params: IPostRefund, useGlobalLoader: boolean) {
  return await APIPost(baseOrderUrl + "/refund", params, useGlobalLoader);
}

export async function PostCalculateRefund(params: IPostCalculateRefund, useGlobalLoader: boolean) {
  return await APIPost(baseOrderUrl + "/refund/calculate", params, useGlobalLoader);
}
