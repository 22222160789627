import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import { StatusCode } from "api/protocols";
import { GetTicketStubPdf, GetTickets, PutTicketStub } from "api/rpc/2022-09/clientAdmin/ticket/ticket";
import { showError, showSuccess } from "redux/actions/ui";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { capitalize } from "helpers/Helpers";
import { useCustomerClientContext } from "../../context/CustomerClientContext";

import { ButtonNew as Button } from "components/buttonNew";
import { Badge } from "components/badge/Badge";
import Callout from "components/callout/Callout";
import Popup from "components/popup/Popup";
import Spin from "components/spin/spin";

import "../../../../facility/customer/tabs/tickets/customerTickets.scss";
import Markdown from "components/markdown/Markdown";
import Card from "components/card/Card";
import { NavigationDropdownNew } from "components/navigationDropdownNew/NavigationDropdownNew";

interface ITicketsState {
  tickets: any[];
  openVoidTicket: boolean;
  ticketToVoid: any;
  ticketToDownload: any;
  openDownloadTicket: boolean;
}

export default function CustomerTickets() {
  const { t } = useTranslation();

  const { state } = useCustomerClientContext();
  const dispatch = useAppDispatch();

  const chevronUp: IconProp = ["far", "chevron-up"];
  const chevronDown: IconProp = ["far", "chevron-down"];

  const [ticketsLoaded, setTicketsLoaded] = useState<boolean>(false);

  const [ticketsState, setTicketsState] = useState<ITicketsState>({
    tickets: null,
    openVoidTicket: false,
    ticketToVoid: null,
    ticketToDownload: null,
    openDownloadTicket: false,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (state.customer !== undefined) {
      void loadTickets(source.token);
    }

    return () => {
      source.cancel();
    };
  }, [state.customer]);

  async function loadTickets(token?: CancelToken) {
    const params = {
      customer_id: state.customer.id,
      extended: true,
    };

    const ticketsRes = await GetTickets(params, false, token);

    if (ticketsRes.status !== StatusCode.OK) {
      return;
    }

    const tickets = ticketsRes.data;

    tickets?.map((ticket: any, index: number) => {
      ticket.stubs?.map((stub: any, stubIndex: number) => {
        if (stub.status === "valid") {
          const foundStub = ticket.stubs[stubIndex];
          ticket.stubs.splice(stubIndex, 1);
          ticket.stubs.unshift(foundStub);
        }
      });

      for (let i = 0; i < ticketsState.tickets?.length; i++) {
        if (ticket.id === ticketsState.tickets[i].id) {
          if (ticketsState.tickets[i].selected) {
            ticket.selected = true;
          } else {
            ticket.selected = false;
          }
        }
      }
    });

    setTicketsState(prevState => ({
      ...prevState,
      tickets: tickets,
    }));

    setTicketsLoaded(true);
  }

  function handleOpenTicket(ticket: any) {
    const tickets = ticketsState.tickets;

    tickets
      ?.filter((filteredTicket: any) => filteredTicket.id === ticket.id)
      .map((ticket: any) => {
        ticket.selected = !ticket.selected;
      });

    setTicketsState(prevState => ({ ...prevState, tickets: tickets }));
  }

  async function voidTicketStub(stub: any) {
    const params = {
      code: ticketsState.ticketToVoid.code,
      id: ticketsState.ticketToVoid.id,
      status: "void",
      customer_id: ticketsState.ticketToVoid.customer_id,
    };

    const voidTicketRes = await PutTicketStub(params, true);

    if (voidTicketRes.status !== StatusCode.OK) {
      dispatch(showError("Error voiding ticket"));
      setTicketsState(prevState => ({ ...prevState, openVoidTicket: false, ticketToVoid: null }));

      return;
    }

    setTicketsLoaded(false);
    await loadTickets();
    setTicketsState(prevState => ({ ...prevState, openVoidTicket: false, ticketToVoid: null }));
  }

  function handleVoidTicketStub(stub: any) {
    setTicketsState(prevState => ({ ...prevState, ticketToVoid: stub, openVoidTicket: true }));
  }

  async function handleTicketDownload(stub: any) {
    const ticketStubIds: any = [];
    ticketStubIds.push(stub.id);

    const params = {
      // order_id: stub.order_id,
      // order_line_item_id: stub.order_line_item_id,
      ticket_stub_ids: ticketStubIds,
    };

    const downloadReceiptRes = await GetTicketStubPdf(params, true);

    if (downloadReceiptRes.status !== StatusCode.OK) {
      dispatch(showError("Error downloading ticket stub"));

      return;
    }

    window.open(downloadReceiptRes.data, "_blank");

    return;
  }

  return ticketsLoaded ? (
    <>
      <div className="mt-2">
        <h1 className="ui-customer_page-header mb-4">{"Tickets"}</h1>

        <hr className="customer-ticket-header-divider" />

        {ticketsState.tickets.length > 0 ? (
          <div>
            {ticketsState.tickets?.map((ticket: any, index: number) => {
              let activeCount = 0;

              {
                ticket.stubs
                  ?.filter((filteredStub: any) => filteredStub.status === "valid")
                  .map((ticket: any) => {
                    activeCount = activeCount + 1;
                  });
              }

              return (
                <div key={index} className="customer-accordian-card-container">
                  <div className="customer-ticket-header-container" onClick={() => handleOpenTicket(ticket)}>
                    <div className="customer-ticket-title-container">
                      <div>
                        {!ticket.logo_image ? (
                          <FontAwesomeIcon className="customer-ticket-logo" icon={"ticket"} />
                        ) : (
                          <img className="customer-ticket-logo" src={ticket.logo_image} />
                        )}
                      </div>
                      <div>
                        <p className="customer-ticket-title">{ticket.title}</p>

                        <p className="customer-ticket-subtitle">
                          {ticket.stubs.length} {ticket.subtitle ?? ""}
                        </p>
                      </div>
                    </div>

                    <div className="customer-ticket-right-content-container">
                      <div className="customer-ticket-info">
                        <p className="ticket-info-text">EXPIRY DATE</p>
                        <p className="ticket-info-date">{moment(ticket?.expiry_date).format("MMMM Do, YYYY")}</p>

                        <p className="ticket-info-text">
                          {" "}
                          {activeCount} / {ticket.stubs.length}
                        </p>
                      </div>
                      <div>
                        <FontAwesomeIcon className="chevron-icon" icon={ticket.selected ? chevronUp : chevronDown} />
                      </div>
                    </div>
                  </div>

                  {ticket.selected ? (
                    <div className="customer-ticket-container">
                      <div className="ticket-description-container">
                        <Markdown markdownText={ticket.description} />
                      </div>

                      {/* <div className="flex justify-end">
                      <div className="mr-3">
                        <FontAwesomeIcon className="terms-icon" icon={["far", "grid"]} />
                      </div>
  
                      <div>
                        <FontAwesomeIcon className="terms-icon" icon={["far", "list"]} />
                      </div>
                    </div> */}

                      <Card>
                        <Card.Section table="true">
                          <table className="ui-table">
                            <thead>
                              <tr>
                                <th>Code</th>
                                <th>Status</th>
                                <th></th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {ticket.stubs.map((stub: any, index: number) => {
                                let badgeStatus: "primary" | "success" | "warning" | "error" | "gray";

                                let isDateExpired = false;

                                if (stub.expiry_date) {
                                  const dateArray = stub.expiry_date.split("-");
                                  const date = new Date(dateArray[0], dateArray[1], dateArray[2]);
                                  isDateExpired = new Date(date.toDateString()) < new Date(new Date().toDateString());
                                }

                                switch (stub.status) {
                                  case "void":
                                    badgeStatus = "error";
                                    break;
                                  case "redeemed":
                                    badgeStatus = "gray";
                                    break;
                                  case "valid":
                                    badgeStatus = "success";
                                    break;

                                  default:
                                    break;
                                }
                                return (
                                  <tr key={index}>
                                    <td>
                                      <div>
                                        <p className="stub-number-text">{stub.code}</p>
                                        <p className="ticket-term">{stub.pin}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <Badge
                                        type={badgeStatus}
                                        size="small"
                                        iconLeft={
                                          stub.status === "valid" || stub.status === "void" ? (
                                            <FontAwesomeIcon
                                              className="customer-ticket-badge-icon"
                                              icon={["fas", "circle"]}
                                            />
                                          ) : (
                                            <></>
                                          )
                                        }
                                      >
                                        {capitalize(stub.status)}
                                      </Badge>
                                    </td>
                                    <td>
                                      {stub.redeemed_at
                                        ? "Redeemed on "
                                        : isDateExpired
                                        ? "Expired on "
                                        : stub.expiry_date
                                        ? "Expires on "
                                        : ""}

                                      {stub.redeemed_at
                                        ? moment(stub.redeemed_at).format("MMMM Do, YYYY")
                                        : stub.expiry_date
                                        ? moment(stub.expiry_date).format("MMMM Do, YYYY")
                                        : ""}
                                    </td>
                                    <td>
                                      {stub.status === "valid" ? (
                                        <NavigationDropdownNew
                                          rightAlign
                                          label={"Options"}
                                          sections={[
                                            [
                                              {
                                                label: "Void",
                                                icon: "ban",
                                                onClick: () => handleVoidTicketStub(stub),
                                              },
                                              // {
                                              //   label: "Redeem",
                                              //   icon: "plus",
                                              //   onClick: () => handleRedeemTicketStub(stub),
                                              // },
                                              {
                                                label: "Download",
                                                icon: "download",
                                                onClick: () => handleTicketDownload(stub),
                                              },
                                            ],
                                          ]}
                                        />
                                      ) : null}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </Card.Section>
                      </Card>
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        ) : (
          <div>
            <Callout title="No Ticket Found" content="The customer does not have any tickets" type="info" />
          </div>
        )}
      </div>

      <Popup
        open={ticketsState.openVoidTicket}
        type="warning"
        title={"Void Ticket"}
        description={"Are you sure you want to void this ticket"}
        onOk={voidTicketStub}
        okText={"Void Ticket"}
        cancelText={"Cancel"}
        onCancel={() => setTicketsState(prevState => ({ ...prevState, openVoidTicket: false, ticketToVoid: null }))}
      ></Popup>
    </>
  ) : (
    <div style={{ marginTop: "4em", pointerEvents: "none", textAlign: "center" }}>
      <span style={{ display: "inline-flex", transform: "scale(2)" }}>
        <Spin />
      </span>
    </div>
  );
}
