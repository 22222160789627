import React, { useEffect, useState, useCallback } from "react";
import { useHistory, Link } from "react-router-dom";
import { useParams } from "react-router";

// API
import { GetRegisters, PutRegister } from "api/rpc/facilityAdmin/facility/register/register";
import { GetCardReaders } from "api/rpc/facilityAdmin/payment/teeOnPayments/reader";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import { Select } from "components/select/";

import { useTranslation, Trans } from "react-i18next";
import Toggle from "components/form/toggle/Toggle";
import { GetRegisterGroups } from "api/rpc/2022-09/facilityAdmin/facility/register/group";
import { isEqualWith, isNull } from "lodash";

export default function Register(props: any) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { Option } = Select;
  const { registerId }: any = useParams();
  const [state, setState] = useState({
    register: null,
    register_title: "",
    register_group_id: null,
    register_groups: [],
    default_card_reader_id: null,
    readers: [],
    auto_log_out: false,
    default_print_receipt: false,
    send_kitchen: false,
  });

  const [registerStateBeforeChanges, setRegisterStateBeforeChanges] = useState(undefined);
  const [registerLoaded, setRegisterLoaded] = useState<boolean>(false);
  const [registerGroupLoaded, setRegisterGroupLoaded] = useState<boolean>(false);
  const [readerLoaded, setReaderLoaded] = useState<boolean>(false);

  useEffect(() => {
    void loadRegister();
    void loadRegisterGroups();
    void loadReaders();
  }, []);

  async function loadRegister() {
    const res = await GetRegisters({ id: registerId }, true);
    if (res.status !== StatusCode.OK) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      register: res.data[0],
      register_title: res.data[0].title,
      default_card_reader_id: res.data[0].default_card_reader_id,
      register_group_id: res.data[0].register_group_id,
      auto_log_out: res.data[0]?.auto_log_out,
      send_kitchen: res.data[0]?.send_kitchen,
      default_print_receipt: res.data[0]?.print_by_default,
    }));

    setRegisterLoaded(true);
  }

  async function loadRegisterGroups() {
    const res = await GetRegisterGroups(undefined, false);
    if (res.status !== StatusCode.OK) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      register_groups: res.data,
    }));

    setRegisterGroupLoaded(true);
  }

  async function loadReaders() {
    const res = await GetCardReaders(undefined, false);
    if (res.status !== StatusCode.OK) {
      return;
    }

    setState(prevState => ({ ...prevState, readers: res.data }));

    setReaderLoaded(true);
  }

  function navigateTo(url: string) {
    history.push(url);
  }

  async function saveRegister() {
    const params = {
      id: state.register.id,
      register_group_id: state.register_group_id,
      title: state.register_title,
      default_card_reader_id: state.default_card_reader_id,
      auto_log_out: state.auto_log_out,
      send_kitchen: state.send_kitchen,
      print_by_default: state.default_print_receipt,
    };

    const updateRes = await PutRegister(params, true);
    if (updateRes.status !== StatusCode.OK) {
      return;
    }

    const selectedRegister = JSON.parse(localStorage.getItem("register"));

    if (state?.register?.id === selectedRegister?.id) {
      const registerAsString = JSON.stringify(updateRes?.data);
      if (registerAsString) {
        localStorage.setItem("register", registerAsString);
      }
    }

    history.push("/admin/settings/registers");
  }

  const primaryAction = {
    content: t("secure.facility.settings.register.register.001"),
    action: saveRegister,
  };

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleDropDownChange(value: any, property: string) {
    setState(prev => ({ ...prev, [property]: value }));
  }

  function handleToggleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, checked } = e.target;
    setState(prevState => ({ ...prevState, [id]: checked }));
  }

  function unsavedChangesExist() {
    if (registerStateBeforeChanges === undefined) {
      if (registerLoaded && registerGroupLoaded && readerLoaded) {
        setRegisterStateBeforeChanges(state);
      }
      return false;
    }

    return !isEqualWith(registerStateBeforeChanges, state, (originalValue, newValue) => {
      if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
        return true;
      }
    });
  }

  function cancelUnsavedChanges() {
    setState(registerStateBeforeChanges);
  }

  return (
    <Page
      title={state.register?.title}
      narrow
      // primaryAction={primaryAction}
      //Translation Needed
      breadcrumbs={[
        { prefix: true, label: t("secure.facility.settings.register.register.006"), url: "/admin/settings/registers" },
      ]}
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: saveRegister,
        onCancel: cancelUnsavedChanges,
      }}
    >
      <Card title={t("secure.facility.settings.register.register.002")}>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Input
                value={state.register_title}
                label={t("secure.facility.settings.register.register.003")}
                id="register_title"
                onChange={handleInputChange}
                placeholder={t("secure.facility.settings.register.register.004")}
              />
              <Select
                label={t("secure.facility.settings.register.register.005")}
                onChange={(value: any) => handleDropDownChange(value, "register_group_id")}
                defaultValue={state.register_group_id}
              >
                {state.register_groups.map((registerGroup: any, index: number) => {
                  return (
                    <Option key={index} value={registerGroup.id} name={registerGroup.title}>
                      {registerGroup.title}
                    </Option>
                  );
                })}
              </Select>

              {/* Translation Needed */}
              <Select
                label={t("secure.facility.settings.register.register.007")}
                onChange={(value: any) => handleDropDownChange(value, "default_card_reader_id")}
                defaultValue={state.default_card_reader_id}
              >
                {state.readers.map((reader: any, index: number) => {
                  return (
                    <Option key={index} value={reader.id} name={reader.label}>
                      {reader.label}
                    </Option>
                  );
                })}
              </Select>
            </FormLayout.Group>

            <div className="mt-4">
              <FormLayout.Group>
                <Toggle
                  id={"auto_log_out"}
                  labelTop={t("secure.facility.settings.register.register.008")}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleToggleChange(e)}
                  checked={state.auto_log_out}
                />

                <Toggle
                  id={"default_print_receipt"}
                  labelTop={"Print Receipt by Default"}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleToggleChange(e)}
                  checked={state.default_print_receipt}
                />

                <Toggle
                  id={"send_kitchen"}
                  labelTop={"Send Kitchen"}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleToggleChange(e)}
                  checked={state.send_kitchen}
                />
              </FormLayout.Group>
            </div>
          </FormLayout>
        </Card.Section>
      </Card>
    </Page>
  );
}
