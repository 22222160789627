import { GetClient } from "api/rpc/clientAdmin/client";
import { LocalStorage } from "api/localstorage";
// import { ICartState } from "reducers/cart";
import { StatusCode } from "api/protocols";
import { GetWeather } from "api/rpc/weather/weather";

export function loadClient(params: any, useGlobalLoader: boolean) {
  return async (dispatch: any) => {
    const res = await GetClient(params, useGlobalLoader);
    if (res.status !== StatusCode.OK) {
      console.log(res);
      return;
    }

    console.log("Admin Client: ", res);

    dispatch({
      type: "client.update",
      payload: {
        isLoaded: true,
        client: res.data,
      },
    });
  };
}

export interface IClientActions {
  loadClient: typeof loadClient;
}
