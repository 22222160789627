import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios, { CancelToken } from "axios";

import Page from "components/page/Page";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import { Select } from "components/select/index";
import { StatusCode } from "api/protocols";

import { useTranslation, Trans } from "react-i18next";
import { PostTaxNumber } from "api/rpc/2024-04/facilityAdmin/facility/taxNumber";
import { useAppDispatch } from "hooks/redux";
import { showError, showSuccess } from "redux/actions/ui";

interface ITaxNumberState {
  title: string;
  number: string;
}

export default function TaxNumbersNew(props: any) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { Option } = Select;
  const { t, i18n } = useTranslation();
  const [taxLineState, setTaxLineState] = useState<ITaxNumberState>({
    title: "",
    number: "",
  });

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setTaxLineState(prevState => ({ ...prevState, [id]: value }));
  }

  async function saveNewTaxNumber() {
    const res = await PostTaxNumber(
      {
        title: taxLineState.title,
        number: taxLineState.number,
      },
      true,
    );
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error creating tax number")); // TODO: Translation
      return;
    }

    history.push("/admin/settings/taxes");
  }

  const primaryAction = {
    content: "Create", // TODO: Translation
    action: saveNewTaxNumber,
  };

  return (
    <Page
      title={"New Tax Number"} // TODO: Translation
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: "Taxes", // TODO: Translation
          url: "/admin/settings/taxes",
        },
      ]}
    >
      <Card>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Input
                value={taxLineState.title}
                label={"Title"} // TODO: Translation
                id="title"
                onChange={handleInputChange}
              />
              <Input
                value={taxLineState.number}
                label={"Number"} // TODO: Translation
                id="number"
                onChange={handleInputChange}
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>
    </Page>
  );
}
