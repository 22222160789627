import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { StatusCode } from "api/protocols";
import { useAppDispatch } from "hooks/redux";
import Page from "components/page/Page";
import { showError } from "redux/actions/ui";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";
import Input from "components/form/input/Input";
import FormLayout from "components/form/FormLayout";
import Sheet from "components/sheet/Sheet";
import ProductSelect from "components/productModal/ProductSelect";
import { IProduct } from "redux/reducers/models/product";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PrintLabels } from "api/rpc/2024-04/facilityAdmin/product/variant";
import { IPrimaryPageAction } from "components/page/PageActions";
import { Select } from "components/select/index";

const TABLE_LIMIT = 50;

interface ILabelState {
  selectedProducts: any;
  finalSelectedProducts: any;
  selectedVariants: any;
  offset: number;
  openSelectProducts: boolean;
  template_id: number;
}

export default function InventoryLabels() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { Option } = Select;

  const [labelState, setLabelState] = useState<ILabelState>({
    selectedProducts: [],
    finalSelectedProducts: [],
    selectedVariants: [],
    offset: 0,
    openSelectProducts: false,
    template_id: 5160,
  });

  const primaryAction: IPrimaryPageAction = {
    content: "Print Labels",
    action: () => handlePrintLabels(),
    disabled: labelState.finalSelectedProducts.length === 0,
  };

  const secondaryAction = {
    content: "Select Products",
    action: () => setLabelState(prevState => ({ ...prevState, openSelectProducts: true })),
  };

  const handleOffsetInputChange = (e: any) => {
    const id = e.target.id;
    const value = e.target.value;

    setLabelState(prevState => ({
      ...prevState,
      offset: value,
    }));
  };

  function handleProductSelect(products: Array<IProduct>) {
    const selectedProducts = [...products];

    selectedProducts?.forEach((product, productIndex) => {
      product?.variants?.forEach((variant, variantIndex) => {
        if (variant?.quantity == null) {
          selectedProducts[productIndex].variants[variantIndex] = { ...variant, quantity: 1 };
        }
      });
    });

    setLabelState(prevState => ({
      ...prevState,
      selectedProducts: selectedProducts,
    }));
  }

  function removeVariant(productIndex: number, variantIndex?: number) {
    const updatedProducts = [...labelState.selectedProducts];
    //Product with single variant
    if (variantIndex == null || updatedProducts[productIndex]?.variants?.length === 1) {
      updatedProducts.splice(productIndex, 1);
    } else {
      // Product with multiple variants
      if (updatedProducts[productIndex]?.variants[variantIndex]) {
        updatedProducts[productIndex]?.variants?.splice(variantIndex, 1);
      }
    }
    setLabelState(prevState => ({ ...prevState, selectedProducts: updatedProducts }));
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>, productIndex: number, variantIndex: number) {
    const { valueAsNumber } = e.target;
    const updatedProducts = [...labelState.selectedProducts];
    const updatedVariant = updatedProducts[productIndex].variants[variantIndex];
    updatedProducts[productIndex].variants[variantIndex] = {
      ...updatedVariant,
      quantity: isNaN(valueAsNumber) ? null : valueAsNumber,
    };
    setLabelState(prevState => ({ ...prevState, selectedProducts: updatedProducts }));
  }

  async function handlePrintLabels() {
    const selectedProducts = [...labelState.selectedProducts];
    const selectedVariants = [...labelState.selectedVariants];

    selectedProducts?.forEach((product, productIndex) => {
      product?.variants?.forEach((variant: any, variantIndex: number) => {
        if (variant?.quantity !== null) {
          const selectedVariant = {
            variant_id: variant.id,
            quantity: variant.quantity,
          };

          selectedVariants.push(selectedVariant);
        }
      });
    });

    const params = {
      offset: labelState.offset,
      variants: selectedVariants,
      template_id: labelState.template_id,
    };

    const printLabelsRes = await PrintLabels(params, true);

    if (printLabelsRes.status !== StatusCode.OK) {
      return;
    }

    setLabelState(prevState => ({ ...prevState, selectedProducts: [], offset: 0, finalSelectedProducts: [] }));

    window.open().document.write(printLabelsRes.data);
  }

  function handleSelectProducts() {
    setLabelState(prevState => ({
      ...prevState,
      openSelectProducts: false,
      finalSelectedProducts: labelState.selectedProducts,
    }));
  }

  const handleSelectChange = (value: string | number) => {
    setLabelState(prev => ({
      ...prev,
      template_id: Number(value),
    }));
  };

  return (
    <Page title={"Print Labels"} narrow primaryAction={primaryAction} secondaryActions={[secondaryAction]}>
      <div className="">
        <FormLayout>
          <FormLayout.Group>
            <div>
              <Input
                label={"Offset"}
                value={labelState.offset}
                placeholder={"Offset.."}
                id=""
                onChange={handleOffsetInputChange}
                type="number"
              />
            </div>

            <div>
              <Select
                onChange={(value: string | number) => handleSelectChange(value)}
                label={"Label Template"} //TODO: Translate
                allowClear
                defaultValue={labelState.template_id}
              >
                <Option value={5160}>{"Avery 5160"}</Option>
              </Select>
            </div>
            <div></div>
          </FormLayout.Group>
        </FormLayout>

        <DataTable
          columns={[
            { label: "Title" }, // TODO: Translation
            { label: "Vendor" }, // TODO: Translation
            { label: "Sku" }, // TODO: Translation
            { label: "Quantity" }, // TODO: Translation
            { label: "" },
          ]}
          //   loading={labelState.selectedProducts === undefined}
          footer={{
            tableLimit: TABLE_LIMIT,
            tableOffset: labelState.offset,
            disableNextOffset: !labelState.selectedProducts || !(labelState.selectedProducts?.length == TABLE_LIMIT),
            handleTableOffset: direction =>
              setLabelState(prev => ({
                ...prev,
                offset: direction === "prev" ? prev.offset - TABLE_LIMIT : prev.offset + TABLE_LIMIT,
              })),
          }}
        >
          {labelState?.finalSelectedProducts?.map((product: any, productIndex: number) => {
            return (
              <React.Fragment key={productIndex}>
                <tr className="inventory-receiving-product-header">
                  <td>{product?.preferred_title ? product?.preferred_title : product.title}</td>
                  <td>{product.vendor_title}</td>
                  <td>{product?.variant_count === 1 && product?.variants[0]?.sku}</td>
                  <td style={{ width: "15%" }}>
                    {product?.variant_count === 1 && (
                      <Input
                        type="number"
                        labelHidden
                        onChange={e => handleInputChange(e, productIndex, 0)}
                        value={product?.variants[0]?.quantity ?? ""}
                        placeholder="Quantity"
                      />
                    )}
                  </td>
                  <td>
                    {product?.variant_count === 1 && (
                      <FontAwesomeIcon
                        onClick={() => removeVariant(productIndex)}
                        icon={["far", "trash-can-xmark"]}
                        size="1x"
                        className="cursor-pointer"
                      />
                    )}
                  </td>
                </tr>
                {product?.variant_count > 1 &&
                  product?.variants?.map((variant: any, variantIndex: number) => (
                    <tr key={variantIndex}>
                      <td style={{ textIndent: "2rem" }}>{variant?.title}</td>
                      <td></td>
                      <td>{variant?.sku}</td>
                      <td style={{ width: "15%" }}>
                        <Input
                          type="number"
                          labelHidden
                          onChange={e => handleInputChange(e, productIndex, variantIndex)}
                          value={variant?.quantity ?? ""}
                          placeholder="Quantity"
                        />
                      </td>
                      <td>
                        <FontAwesomeIcon
                          onClick={() => removeVariant(productIndex, variantIndex)}
                          icon={["far", "trash-can-xmark"]}
                          size="1x"
                          className="cursor-pointer"
                        />
                      </td>
                    </tr>
                  ))}
              </React.Fragment>
            );
          })}
        </DataTable>
      </div>

      <Sheet
        open={labelState.openSelectProducts}
        title="Search Products"
        size="medium"
        onCancel={() =>
          setLabelState(prevState => ({
            ...prevState,
            openSelectProducts: false,
            selectedProducts: labelState.finalSelectedProducts,
          }))
        }
        cancelText="Close"
        closable
        // onOk={() => setLabelState((prevState) => ({ ...prevState, openSelectProducts: false }))}
        onOk={handleSelectProducts}
        okText="Confirm"
      >
        <ProductSelect
          selectedProducts={labelState.selectedProducts}
          onChange={handleProductSelect}
          search
          variantInput={{ key: "quantity", placeholder: "Quantity" }}
        />
      </Sheet>
    </Page>
  );
}
