import { AdminType, APIGet, APIPut, APIUrl, IAPIResponse } from "api/protocols";
import { ICashOut } from "api/rpc/2022-09/facilityAdmin/facility/register/cashOut";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/facility/register/shift";

type TGetCashout = {
  id?: number;
  register?: number;
  filters?: {
    label: "register" | "year" | "month";
    value: Array<number>;
  }[];
};

// type TPostCashout = {};

type TUpdateCashout = {
  register_shift_id: number;
  closed_at?: string;
  deposit_cash?: number;
  deposit_check?: number;
  deposit_bank_number?: number;
  notes?: string;
  closed_by?: string;
  deposit_date?: string;
  deposited_by?: number;
  register_id?: number;
};

type TCloseCashout = {
  register_id: number;
  closed_at: Date;
  opened_at?: Date;
  deposit_cash?: number; // default to 0
  deposit_check?: number; //default to 0
  deposit_bag_number?: string; // default to null
  notes?: string; // default to null
};

export async function GetCashOuts(params: TGetCashout, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<ICashOut[]>(
    APIUrl(AdminType.FacilityAdmin, "/facility/register/shift"),
    params,
    useGlobalLoader,
    token,
  );
}

export async function UpdateCashOut(params: TUpdateCashout, useGlobalLoader: boolean) {
  return await APIPut(APIUrl(AdminType.FacilityAdmin, "/facility/register/shift"), params, useGlobalLoader);
}

export async function CloseCashOut(params: TCloseCashout, useGlobalLoader: boolean) {
  return await APIPut(APIUrl(AdminType.FacilityAdmin, "/facility/register/shift/close"), params, useGlobalLoader);
}

export async function GetCashOutSummary(
  params: { register_shift_id: number },
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(
    APIUrl(AdminType.FacilityAdmin, "/facility/register/shift/summary"),
    params,
    useGlobalLoader,
    token,
  );
}
