import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "components/bookingEngine/bookingTimer/bookingTimer.scss";
import { update } from "lodash";
import { useHistory, useParams } from "react-router";
import Sheet from "components/sheet/Sheet";
import Popup from "components/popup/Popup";
import moment from "moment";

interface ITimerState {
  timeLeft: string;
  isActive: boolean;
  expireTime: string;
  expireSheet: boolean;
}

interface IProps {
  active: boolean;
  expireTime: string;
  /**The URL to send the user to after the tee time expires */
  teeTimeExpiryUrl: string;
}

function BookingTimer(props: IProps) {
  const history = useHistory();
  const [timerState, setTimerState] = useState<ITimerState>({
    timeLeft: "",
    expireTime: props.expireTime,
    isActive: props.active,
    expireSheet: false,
  });

  function updateTimerState(newTimerState: Partial<ITimerState>) {
    setTimerState(cur => {
      return { ...cur, ...newTimerState };
    });
  }

  function formatTime(timeDifference: moment.Duration) {
    const minutes = timeDifference.minutes().toString().padStart(2, "0");
    const seconds = timeDifference.seconds().toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  }

  useEffect(() => {
    let interval: NodeJS.Timeout = null;

    if (timerState.isActive) {
      interval = setInterval(() => {
        //Current local time
        const currentTime = moment();
        //Convert expiry time from utc to local
        const expireTime = moment.utc(timerState.expireTime).local();
        const differenceTime = moment.duration(expireTime.diff(currentTime));
        const formattedElapsedTime = formatTime(differenceTime);
        updateTimerState({ timeLeft: formattedElapsedTime });

        //Show expiry modal if the current time is greater than or equal to the expire time
        if (currentTime.isSameOrAfter(expireTime)) {
          updateTimerState({ isActive: false, timeLeft: "Booking Expired", expireSheet: true });
        }
      }, 1000);
    } else if (!timerState.isActive) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timerState.isActive]);

  function sendBackToTeetimes() {
    history.push(props.teeTimeExpiryUrl);
  }

  return (
    <div className="time-left-div">
      <p className="time-left-p">{timerState.timeLeft}</p>

      <Popup
        open={timerState.expireSheet}
        type="error"
        title="Booking Session Has Expired!"
        description={"Your booking has expired. Please select another tee time."}
        onOk={sendBackToTeetimes}
        okText="OK"
        backDropCancel={false}
      />
    </div>
  );
}

export default BookingTimer;
