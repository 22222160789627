import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { ITournamentParticipant } from "redux/reducers/models/tournament";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/tournament";

export type TGetTournamentParticipant = {
  tournament_id?: string | number;
};

type DeleteTournamentParticipant = {
  id?: number;
  tournament_id?: number;
  participant_id?: number;
};

type TExportTournamentParticipants = {
  tournament_id: number;
  type: "csv";
};

export async function GetTournamentParticipant(
  params: TGetTournamentParticipant,
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet<ITournamentParticipant[]>(baseUrl + "/participant", params, useGlobalLoader, token);
}

//Delete player within a tournament
export async function DeleteTournamentParticipant(params: DeleteTournamentParticipant, useGlobalLoader: boolean) {
  return await APIDelete(baseUrl + "/participant", params, useGlobalLoader);
}

//Copy tournament players to another tournament
export async function CopyTournamentParticipants(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/participant/copy", params, useGlobalLoader);
}

//Import Tournament Players
export async function ImportTournamentPlayers(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/participant/import", params, useGlobalLoader);
}

// Export Tournament Players
export async function PutExportParticipants(params: TExportTournamentParticipants, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/participant/export", params, useGlobalLoader);
}
