import React, { useEffect, useState, useCallback } from "react";
import { useHistory, Link } from "react-router-dom";
import { useParams } from "react-router";

import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import { ButtonNew as Button } from "components/buttonNew";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import TextField from "components/form/textField/TextField";
import Select from "components/form/select/Select";
import Checkbox from "components/form/checkbox/Checkbox";
import { GetLeague, PutLeague } from "api/rpc/clientAdmin/league/league";
import CustomerImage from "elements/customer/CustomerImage";
import { DeleteLeagueLogo, UploadLeagueLogo } from "api/rpc/2024-04/clientAdmin/league/league";

interface ILeagueState {
  id: number;
  name: string;
  week_day: string;
  description: string;
  logo_source: string;
}

export default function Settings(props: any) {
  const history = useHistory();
  const { leagueId }: any = useParams();
  console.log(leagueId);
  const [state, setState] = useState<ILeagueState>({
    id: null,
    name: "",
    week_day: "",
    description: "",
    logo_source: "",
  });

  useEffect(() => {
    void loadLeague();
  }, []);

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    console.log(event);
    setState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleSaveLeague() {
    void saveLeague();
  }

  const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  const weekDays = daysOfWeek.map((day: any, index: number) => {
    return {
      label: day,
      value: day,
      prefix: "",
    };
  });

  async function loadLeague() {
    const params = {
      id: leagueId,
    };

    console.log(params);

    const leagueRes = await GetLeague(params, true);
    console.log(leagueRes);

    const league = leagueRes.data[0];
    setState(prevState => ({
      ...prevState,
      id: league.id,
      name: league.name,
      week_day: league.week_day,
      description: league.description,
      logo_source: league.logo_source,
    }));

    return;
  }

  async function saveLeague() {
    console.log(state);
    const leagueRes = await PutLeague(state, true);
    if (leagueRes.status !== StatusCode.OK) {
      return;
    }
  }

  const primaryAction = {
    content: "Save",
    action: handleSaveLeague,
  };

  async function saveLeagueLogo(imageFile: File) {
    console.log(imageFile);
    const formData = new FormData();

    formData.append("image", imageFile);
    formData.append("league_id", state.id.toString());
    console.log(formData);

    const imageRes = await UploadLeagueLogo(formData, true);
    if (imageRes.status !== StatusCode.OK) {
      return;
    }

    console.log(imageRes.data); //debug

    setState(prevState => ({ ...prevState, logo_source: imageRes.data.logo_source }));
  }

  async function deleteLeagueLogo() {
    const deleteRes = await DeleteLeagueLogo({ league_id: leagueId }, true);
    console.log(deleteRes);

    if (deleteRes.status !== StatusCode.OK) {
      return;
    }

    setState(prevState => ({ ...prevState, logo_source: deleteRes.data.logo_source }));
  }

  return (
    <Page
      title="General Settings"
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[{ label: "Settings", url: "/admin/league/" + String(state?.id) + "/settings" }]}
    >
      <Form>
        <Card title="League Details">
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Input value={state.name} label="Name" id="name" onChange={handleInputChange} placeholder="Name" />
              </FormLayout.Group>
            </FormLayout>
            <FormLayout>
              <FormLayout.Group>
                <Select
                  value={state.week_day}
                  id="week_day"
                  label="Week Day"
                  onChange={handleInputChange}
                  options={weekDays}
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <TextField
                  value={state?.description}
                  label="Description"
                  id="description"
                  onChange={handleInputChange}
                  placeholder="Description"
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
          <Card.Section title="Logo">
            <div className="stats_bar-picture">
              <CustomerImage
                imageSource={state.logo_source}
                saveProfileImage={imageFile => saveLeagueLogo(imageFile)}
                deleteProfileImage={deleteLeagueLogo}
              />
            </div>
          </Card.Section>
        </Card>
      </Form>
    </Page>
  );
}
