import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import axios, { CancelToken } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useModal from "hooks/modals/useModal";

import Page from "components/page/Page";
import { ButtonNew as Button } from "components/buttonNew";
import FormLayout from "components/form/FormLayout";
import Card from "components/card/Card";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import { GetLeague } from "api/rpc/2024-04/facilityAdmin/league/league";
import {
  GetLeagueCompetitionFormat,
  DeleteLeagueCompetitionFormat,
} from "api/rpc/2024-04/facilityAdmin/league/scoring/format";
import { GetLeagueScoresheets, DeleteLeagueScoresheet } from "api/rpc/2024-04/facilityAdmin/league/scoring/scoresheet";
import { StatusCode } from "api/protocols";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { showError, showSuccess } from "redux/actions/ui";
import { TLeagueRoundFormat, TLeagueScoresheet, ILeague } from "redux/reducers/models/league";
import { valueToString } from "helpers/Helpers";
import Popup from "components/popup/Popup";
import Input from "components/form/input/Input";
import CopyLink from "components/copyLink/CopyLink";

export default function LeagueRoundOverview() {
  const history = useHistory();
  const { leagueId, scoresheetId } = useParams<{ leagueId: string; scoresheetId: string }>();

  const dispatch = useAppDispatch();
  const { facilityStore } = useAppSelector(store => store);
  const [league, setLeague] = useState<ILeague>(undefined);
  const [scoresheet, setScoresheet] = useState<TLeagueScoresheet>(undefined);
  const [scoringFormats, setScoringFormats] = useState<TLeagueRoundFormat[]>(undefined);

  const {
    state: deleteScoresheetPopup,
    updateModal: updateDeleteScoresheetPopup,
    closeModal: closeDeleteScoresheetPopup,
  } = useModal();

  const {
    state: deleteFormatPopup,
    updateModal: updateDeleteFormatPopup,
    closeModal: closeDeleteFormatPopup,
  } = useModal({
    format_id: null,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadleague(source.token);
    void loadScoresheet(scoresheetId, source.token);
    void loadScoringFormats(scoresheetId, source.token);
    return () => source.cancel();
  }, [scoresheetId]);

  async function loadleague(token?: CancelToken) {
    const res = await GetLeague({ id: leagueId }, token ? false : true, token);
    if (token && token.reason) {
      return;
    }

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading league.")); // TODO: Translation
      return;
    }

    setLeague(res.data[0]);
  }

  async function loadScoresheet(leagueScoresheetId: string | number, token?: CancelToken) {
    const res = await GetLeagueScoresheets({ id: leagueScoresheetId }, token ? false : true, token);
    if (token && token.reason) {
      return;
    }

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading scoresheet.")); // TODO: Translation
      return;
    }

    setScoresheet(res.data[0]);
  }

  async function loadScoringFormats(leagueScoresheetId: string | number, token?: CancelToken) {
    if (scoringFormats !== undefined) {
      setScoringFormats(undefined);
    }
    const res = await GetLeagueCompetitionFormat(
      { league_scoresheet_id: leagueScoresheetId },
      token ? false : true,
      token,
    );
    if (token && token.reason) {
      return;
    }

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading round formats.")); // TODO: Translation
    }

    setScoringFormats(res.status !== StatusCode.OK ? [] : res.data);
  }

  async function deleteScoresheet() {
    const res = await DeleteLeagueScoresheet({ id: scoresheetId }, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error deleting scoresheet.")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Scoresheet deleted successfully")); // TODO: Translation
    history.push(`/admin/league/${leagueId}/rounds`);
  }

  async function deleteScoringFormat(formatId: number) {
    const res = await DeleteLeagueCompetitionFormat({ format_id: formatId }, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error deleting format.")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Format deleted successfully")); // TODO: Translation
    void closeDeleteFormatPopup();
    void loadScoringFormats(scoresheetId);
  }

  async function writeTextToClipBoard(link: string) {
    await navigator.clipboard.writeText(link);
    dispatch(showSuccess("Copied link to clipboard"));
  }

  const primaryAction = {
    content: "Edit", // TODO: Translation
    action: () => history.push(`/admin/league/${leagueId}/rounds/scoresheet/${scoresheetId}/edit`),
  };

  const secondaryActions = [
    {
      content: "Delete", // TODO: Translation
      action: () => updateDeleteScoresheetPopup({ isOpen: true }),
    },
  ];

  return (
    <Page
      title={`${scoresheet ? scoresheet?.name : "Round"} Overview`} // TODO: Translation
      primaryAction={primaryAction}
      secondaryActions={secondaryActions}
      breadcrumbs={[
        {
          prefix: true,
          label: "Back to Rounds", // TODO: Translation
          url: `/admin/league/${leagueId}/rounds`,
        },
      ]}
    >
      <Card>
        <Card.Section title="Details" /* TODO: Translation */>
          <FormLayout>
            <FormLayout.Group>
              <Input label={"Front Division"} disabled={true} value={scoresheet?.front_division_title} />
              <Input label={"Back Division"} disabled={true} value={scoresheet?.back_division_title} />
              <Input disabled={true} value={`${scoresheet?.participation} Rounds Played`} />
            </FormLayout.Group>
            <FormLayout.Group>
              <CopyLink
                label={"Public Leaderboard Link"} // TODO: Translation
                link={
                  window.origin +
                  `/league/${facilityStore.facility?.short_name}/${league?.handle}/scoring/results/${scoresheetId}`
                }
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
        <Card.Section title="Actions" /* TODO: Translation */>
          <div className="flex justify-between">
            <div className="flex justify-start">
              <Button
                onClick={() => history.push(`/admin/league/${leagueId}/rounds/scoresheet/${scoresheetId}/scores`)}
              >
                Enter Scores {/* TODO: Translation */}
              </Button>
              <Button
                onClick={() => history.push(`/admin/league/${leagueId}/rounds/scoresheet/${scoresheetId}/leaderboard`)}
              >
                Results {/* TODO: Translation */}
              </Button>
            </div>
            <Button
              onClick={() =>
                history.push(`/admin/league/${leagueId}/rounds/scoresheet/${scoresheetId}/scoring-formats/new`)
              }
            >
              New Scoring Format {/* TODO: Translation */}
            </Button>
          </div>
        </Card.Section>
        <Card.Section title={"Scoring Formats"} /* TODO: Translation */>
          <DataTable
            columns={[
              { label: "Title" }, // TODO: Translation
              { label: "Organization" },
              { label: "Holes" },
              { label: "", width: "3%" },
              { label: "", width: "3%" },
            ]}
            loading={scoringFormats === undefined}
          >
            {scoringFormats?.map(format => (
              <tr key={format.id}>
                <td>{format.title}</td>
                <td>{valueToString(format.organization)}</td>
                <td>{valueToString(format.holes)}</td>
                <td>
                  <Button
                    type="tertiary"
                    onClick={() =>
                      history.push(
                        `/admin/league/${leagueId}/rounds/scoresheet/${scoresheetId}/scoring-formats/${format.id}`,
                      )
                    }
                  >
                    <FontAwesomeIcon icon={["far", "pen-to-square"]} />
                  </Button>
                </td>
                <td>
                  <Button
                    type="tertiary"
                    onClick={() => updateDeleteFormatPopup({ isOpen: true, format_id: format.id })}
                  >
                    <FontAwesomeIcon icon={["far", "trash"]} />
                  </Button>
                </td>
              </tr>
            ))}
          </DataTable>
        </Card.Section>
      </Card>

      <Popup
        type="warning"
        closable
        open={deleteScoresheetPopup.isOpen}
        onCancel={closeDeleteScoresheetPopup}
        onOk={deleteScoresheet}
        title="Delete Round?" // TODO: Translation
        description="Are you sure you want to delete this round?" // TODO: Translation
      />

      <Popup
        type="warning"
        closable
        open={deleteFormatPopup.isOpen}
        onCancel={closeDeleteFormatPopup}
        onOk={() => deleteScoringFormat(deleteFormatPopup.format_id)}
        title="Delete Scoring Format?" // TODO: Translation
        description="Are you sure you want to delete this scoring format?" // TODO: Translation
      />
    </Page>
  );
}
