import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import Sheet from "components/sheet/Sheet";

import { ICartActions } from "redux/actions/cart";
import { IUIActions } from "redux/actions/ui";
import { ICartState } from "redux/reducers/cart";
import { ButtonNew as Button } from "components/buttonNew";
import { useHistory } from "react-router";
import {
  GetFloorPlans,
  GetTableElements,
  IFloorPlan,
  ITable,
  ITableElement,
  PutOpenTable,
} from "api/rpc/facilityAdmin/tables/tables";
import { StatusCode } from "api/protocols";
import "./tableSelection.scss";
import SwitchUserModal from "components/switchUserModal/SwitchUserModal";
import { IAuthState } from "redux/reducers/auth";
import { IAuthActions } from "redux/actions/auth";
import { SwitchUser, UserLogout } from "api/rpc";
import { Select } from "components/select/index";
import { XYCoord } from "react-dnd";

import { useTranslation, Trans } from "react-i18next";
import ReactDOM from "react-dom";
import { useDispatch } from "react-redux";
import { useAppSelector } from "hooks/redux";

interface IProps {
  cartStore: ICartState;
  cartActions: ICartActions;
  uiActions: IUIActions;
  authStore: IAuthState;
  authActions: IAuthActions;
}

interface IState {
  tableElements: ITableElement[];
  floorPlanRef: React.MutableRefObject<HTMLDivElement>;
  floorPlanWidth: number;
  floorPlanHeight: number;
  floorPlanGridSquareSideLength: number;
  floorPlans: IFloorPlan[];
  selectedFloorPlan: IFloorPlan;
  seatsAmount: number;
  seatsAmountVisible: boolean;
  selectedTable: Record<string, any>;
  setCartActive: boolean;
  activeUserCode: string;
  displayActiveUserSheet: boolean;
}

export default function TableSelection(props: IProps) {
  const { cartStore, cartActions, uiActions, authStore, authActions } = props;
  const { facilityStore } = useAppSelector(store => store);

  const dispatch = useDispatch();

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { Option } = Select;

  const [state, setState] = useState<IState>({
    tableElements: [],
    floorPlanRef: useRef(),
    floorPlanWidth: 0,
    floorPlanHeight: 0,
    floorPlanGridSquareSideLength: 0,
    floorPlans: [],
    selectedFloorPlan: undefined,
    seatsAmount: 1,
    seatsAmountVisible: false,
    selectedTable: null,
    setCartActive: false,
    activeUserCode: "",
    displayActiveUserSheet: false,
  });

  const [switchUserAfterTransaction, setSwitchUserAfterTransaction] = useState<boolean>(false);

  useEffect(() => {
    void loadTableElements();
    void loadFloorPlans();
  }, []);

  useEffect(() => {
    const switchUserAfterTransaction = localStorage.getItem("switch_user_after_transaction");
    if (switchUserAfterTransaction === "true") {
      setSwitchUserAfterTransaction(true);
    }
  }, [localStorage.getItem("switch_user_after_transaction")]);

  async function loadTableElements() {
    const getTableElementsResponse = await GetTableElements(true);

    if (getTableElementsResponse.status !== StatusCode.OK || getTableElementsResponse.data === undefined) {
      uiActions.showError(t("secure.facility.table_service.table_selection.001"));
      return;
    }

    setState(prevState => ({
      ...prevState,
      tableElements: getTableElementsResponse.data,
    }));
  }

  async function loadFloorPlans() {
    const register = JSON.parse(localStorage.getItem("register"));

    const getFloorPlansResponse = await GetFloorPlans(null, true);

    if (getFloorPlansResponse.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.table_service.table_selection.002"));
      return;
    }

    const default_floor_plan_id = register?.default_floor_plan_id;
    let defaultFloorPlan: IFloorPlan;

    if (facilityStore?.selectedFloorPlanId) {
      defaultFloorPlan = getFloorPlansResponse.data?.find(
        floorPlan => floorPlan.id === facilityStore?.selectedFloorPlanId,
      );
    } else {
      defaultFloorPlan = getFloorPlansResponse.data?.find(
        floorPlan =>
          floorPlan.id === default_floor_plan_id || floorPlan?.register_group_id === register?.register_group_id,
      );
    }

    setState(prevState => ({
      ...prevState,
      floorPlans: getFloorPlansResponse.data,
      selectedFloorPlan: defaultFloorPlan ?? getFloorPlansResponse.data?.[0],
    }));
  }

  useEffect(() => {
    if (state.selectedFloorPlan?.id) {
      props.cartActions.loadTables(true, state.selectedFloorPlan.id);
    }
  }, [state.selectedFloorPlan?.id]);

  useEffect(() => {
    function handleResize() {
      setState(prevState => ({
        ...prevState,
        floorPlanWidth: state.floorPlanRef.current.clientWidth,
      }));
    }

    if (state.floorPlanRef?.current) {
      handleResize();
      window.addEventListener("resize", handleResize);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [state.floorPlanRef?.current]);

  useEffect(() => {
    if (
      state.floorPlanWidth > 0 &&
      state.selectedFloorPlan?.grid_width > 0 &&
      state.selectedFloorPlan?.grid_height > 0
    ) {
      const gridProportion = state.selectedFloorPlan.grid_height / state.selectedFloorPlan.grid_width;

      setState(prevState => ({
        ...prevState,
        floorPlanHeight: state.floorPlanWidth * gridProportion,
        floorPlanGridSquareSideLength: state.floorPlanWidth / state.selectedFloorPlan.grid_width,
      }));
    }
  }, [state.floorPlanWidth, cartStore.tables]);

  function handleSelectFloorPlan(id: number) {
    const selectedFloorPlan = state.floorPlans.find(floorPlan => floorPlan.id === id);

    setState(prevState => ({ ...prevState, selectedFloorPlan }));

    dispatch({
      type: "facility.update",
      payload: {
        selectedFloorPlanId: id,
      },
    });
  }

  async function selectTable(table: Record<string, any>) {
    if (
      table.open === false ||
      table.user_id === authStore.user.id ||
      authStore.user.permissions.table_service_table_override
    ) {
      if (table?.open) {
        // eslint-disable-next-line @typescript-eslint/await-thenable
        await cartActions.loadActiveTableNew(table?.id, true);
        history.push("/admin/table-service");
      } else {
        setState(prevState => ({ ...prevState, selectedTable: table }));
        void handleOpenTable(table);
      }
    } else {
      uiActions.showError(t("secure.facility.table_service.table_selection.003"));
    }
  }

  async function handleOpenTable(table: Record<string, any>) {
    const register = JSON.parse(localStorage.getItem("register"));
    if (register) {
      const openTableRes = await PutOpenTable(
        {
          register_id: register?.id,
          table_id: table?.id,
          number_of_seats: 1,
        },
        true,
      );

      if (openTableRes.status !== StatusCode.OK) {
        uiActions.showError(t("secure.facility.table_service.table_selection.004"));
        return;
      }

      uiActions.showSuccess(t("secure.facility.table_service.table_selection.013"));
      setState(prevState => ({ ...prevState, seatsAmountVisible: false, seatsAmount: 1, selectedTable: null }));
      await selectTable(openTableRes?.data);
    } else {
      uiActions.showError(t("secure.facility.table_service.table_selection.005"));
    }
  }

  function backToDashboard() {
    history.push("/admin/dashboard");
  }

  function handleOpenActiveUserSheet() {
    setState(prevState => ({ ...prevState, activeUserCode: "", displayActiveUserSheet: true }));
  }

  function handleCloseActiveUserSheet() {
    ReactDOM.unstable_batchedUpdates(() => {
      setSwitchUserAfterTransaction(false);
      setState(prevState => ({ ...prevState, activeUserCode: "", displayActiveUserSheet: false }));
    });
  }

  function isPositiveInteger(value: string) {
    const positiveIntegerRegex = /^[1-9][0-9]*$/;
    return positiveIntegerRegex.test(value);
  }

  async function handleUpdateActiveUser() {
    if (isPositiveInteger(state.activeUserCode)) {
      const switchUserResponse = await SwitchUser({ quick_code: parseInt(state.activeUserCode) }, true);

      if (switchUserResponse.status !== StatusCode.OK) {
        props.uiActions.showError(t("secure.facility.table_service.table_selection.006"));
        return;
      }

      authActions.userLogin(switchUserResponse.data.user);
      localStorage.setItem("localeLanguage", switchUserResponse.data.user.primary_locale);
      localStorage.setItem("localeLanguageWithCountry", switchUserResponse.data.user.primary_locale_country);
      localStorage.setItem("switch_user_after_transaction", "false");

      props.uiActions.showSuccess(t("secure.facility.table_service.table_selection.007"));

      handleCloseActiveUserSheet();
    }
  }

  function handleActiveUserCodeEnter(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      void handleUpdateActiveUser();
    }
  }

  function handleActiveUserCodeChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    setState(prevState => ({ ...prevState, activeUserCode: value }));
  }

  function getTableImageSource(table: ITable) {
    const tableElement = state.tableElements.find(tableElement => tableElement.id === table.table_element_id);
    const src = tableElement?.image_src ?? "";

    return src;
  }

  function getTableDimensions(table: ITable) {
    const tableElement = state.tableElements.find(tableElement => tableElement.id === table.table_element_id);

    if (!tableElement) {
      return { width: 0, height: 0 };
    } else {
      return {
        width: tableElement.grid_width * state.floorPlanGridSquareSideLength,
        height: tableElement.grid_height * state.floorPlanGridSquareSideLength,
      };
    }
  }

  function getTableCoordinates(table: ITable): XYCoord {
    const tableElement = state.tableElements.find(tableElement => tableElement.id === table.table_element_id);
    if (tableElement) {
      const xCoordinate =
        table.x_coordinate * state.floorPlanGridSquareSideLength -
        (tableElement.grid_width * state.floorPlanGridSquareSideLength) / 2;

      const yCoordinate =
        table.y_coordinate * state.floorPlanGridSquareSideLength -
        (tableElement.grid_height * state.floorPlanGridSquareSideLength) / 2;

      return { x: xCoordinate, y: yCoordinate };
    } else {
      return { x: 0, y: 0 };
    }
  }

  function getCenterOfTableCoordinates(table: ITable) {
    const tableElement = state.tableElements.find(tableElement => tableElement.id === table.table_element_id);
    if (tableElement) {
      const xCoordinate = (tableElement.grid_width * state.floorPlanGridSquareSideLength) / 2;
      const yCoordinate = (tableElement.grid_height * state.floorPlanGridSquareSideLength) / 2;

      return { xCenter: xCoordinate, yCenter: yCoordinate };
    } else {
      return { xCenter: 0, yCenter: 0 };
    }
  }

  async function handleLogoutUser() {
    const logoutRes = await UserLogout(true);
    localStorage.setItem("switch_user_after_transaction", "false");
    authActions.userLogout();
    history.push("/");
  }

  return (
    <div className="table-selection">
      <div className="table-selection-top-bar-container">
        <div className="flex flex-row justify-start gap-10">
          <div className="table-selection-action_container">
            <div className="table-selection-table-action" onClick={backToDashboard}>
              <span className="table-selection-table-action-icon">
                <FontAwesomeIcon icon={["far", "arrow-left-long"]} size="sm" fixedWidth />
              </span>
              <p>{t("secure.facility.table_service.table_selection.010")}</p>
            </div>
          </div>
        </div>
        <Select className="w-56" onChange={handleSelectFloorPlan} defaultValue={state.selectedFloorPlan?.id}>
          {state.floorPlans?.map((floorPlan, index) => {
            return (
              <Option key={index} value={floorPlan.id}>
                {floorPlan.title}
              </Option>
            );
          })}
        </Select>
        <div className="table-selection-action_container">
          <div className="table-selection-table-action" onClick={handleOpenActiveUserSheet}>
            <span className="table-selection-table-action-icon">
              <FontAwesomeIcon icon={["far", "user"]} size="sm" fixedWidth />
            </span>
            <p className="table-selection-username">{authStore?.user?.full_name}</p>
          </div>
        </div>
      </div>
      {state.tableElements?.length > 0 && (
        <div className="table-selection-floor-plan-tables-container">
          <div
            ref={state.floorPlanRef}
            className="table-selection-floor-plan-tables"
            style={{
              height: state.floorPlanHeight,
            }}
          >
            {cartStore.tables?.map((table, index: number) => {
              const { x, y } = getTableCoordinates(table);
              const { xCenter, yCenter } = getCenterOfTableCoordinates(table);
              const { width, height } = getTableDimensions(table);

              return (
                <React.Fragment key={`table-${index}`}>
                  <img
                    className={classNames({
                      "table-selection-floor-plan-table-blocked":
                        table?.user_id !== authStore?.user?.id &&
                        table?.open &&
                        !authStore?.user?.permissions?.table_service_table_override,
                      "table-selection-floor-plan-table-success":
                        (table?.user_id === authStore?.user?.id ||
                          authStore?.user?.permissions?.table_service_table_override) &&
                        table?.open,
                      "table-selection-floor-plan-table-closed": !table?.open,
                    })}
                    src={getTableImageSource(table)}
                    draggable={false}
                    onClick={() => selectTable(table)}
                    style={{
                      position: "absolute",
                      cursor: "pointer",
                      left: x,
                      top: y,
                      width: width,
                      height: height,
                      transform: `rotate(${table.rotation}deg)`,
                      transformOrigin: "center",
                    }}
                  />
                  {state.floorPlanGridSquareSideLength > 0 && (
                    <div
                      onClick={() => selectTable(table)}
                      style={{
                        position: "absolute",
                        userSelect: "none",
                        cursor: "pointer",
                        left: x + xCenter,
                        top: y + yCenter,
                        transform: "translate(-50%, -50%)",
                        fontSize: `${Math.max(8, Math.round(state.floorPlanGridSquareSideLength / 2))}px`,
                      }}
                    >
                      <div>{table.short_title}</div>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      )}

      <Sheet
        title={t("secure.facility.table_service.table_selection.008")}
        open={state.seatsAmountVisible}
        size="small"
        closable
        onCancel={() =>
          setState(prevState => ({ ...prevState, seatsAmount: 1, seatsAmountVisible: false, selectedTable: null }))
        }
        onOk={handleOpenTable}
        okText={t("secure.facility.table_service.table_selection.009")}
        okDisabled={state.seatsAmount < 1 ? true : false}
      >
        <div className="flex flex-row justify-between items-center mt-4 h-full mt-auto mb-auto">
          <Button
            onClick={() => {
              setState(prevState => ({
                ...prevState,
                seatsAmount: prevState.seatsAmount <= 1 ? 1 : prevState.seatsAmount - 1,
              }));
            }}
          >
            <FontAwesomeIcon icon={"minus"} />
          </Button>
          <span className="text-2xl">
            {state.seatsAmount}{" "}
            {`${t("secure.facility.table_service.table_selection.011")}${state.seatsAmount > 1 ? "s" : ""}`}
          </span>
          <Button
            onClick={() => {
              setState(prevState => ({ ...prevState, seatsAmount: prevState.seatsAmount + 1 }));
            }}
          >
            <FontAwesomeIcon icon={"plus"} />
          </Button>
        </div>
      </Sheet>

      <SwitchUserModal
        open={state.displayActiveUserSheet}
        onCancel={handleCloseActiveUserSheet}
        onOk={handleUpdateActiveUser}
        okDisabled={!isPositiveInteger(state.activeUserCode)}
        userCode={state.activeUserCode}
        onChange={handleActiveUserCodeChange}
        onKeyDown={handleActiveUserCodeEnter}
        autoFocus
      />

      {/* Switch user after table is closed */}
      <SwitchUserModal
        open={switchUserAfterTransaction}
        onCancel={handleLogoutUser}
        cancelText={t("secure.facility.table_service.table_selection.012")}
        onOk={handleUpdateActiveUser}
        okDisabled={!isPositiveInteger(state.activeUserCode)}
        userCode={state.activeUserCode}
        onChange={handleActiveUserCodeChange}
        onKeyDown={handleActiveUserCodeEnter}
        autoFocus
        backDropCancel={false}
      />
    </div>
  );
}
