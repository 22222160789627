import React, { useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { IFacility } from "api/rpc/2022-09/clientAdmin/facility/facility";

import { filterHistoryAddItem, filterHistoryCreate } from "redux/actions/filters/filterHistory";
import { IOrder, OrderFinancialStatus } from "redux/reducers/models/order";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { valueToString } from "helpers/Helpers";
import { LocaleCurrency } from "helpers/Locale";

import Avatar from "components/avatar/Avatar";
import { Badge } from "components/badge/Badge";
import Icon from "components/icon/Icon";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

import "./ordersTable.scss";

type TOrdersTableProps = {
  /** Passing undefined triggers Spin component */
  orders: IOrder[];
  facilities?: IFacility[];
  tableLimit: number;
  tableOffset: number;
  handleTableOffset: (direction: "prev" | "next") => void;
  handleTableNavigation?: (order_id: number) => void;
};

const OFFSET_HISTORY_KEY = "facility_orders_offset";

export default function OrdersTable(props: TOrdersTableProps) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const location = useLocation<{ params: { orderId: number } }>();

  const dispatch = useAppDispatch();
  const filterHistoryStore = useAppSelector(store => store.filterHistoryStore);

  useEffect(() => {
    if (filterHistoryStore[OFFSET_HISTORY_KEY] === undefined) {
      dispatch(filterHistoryCreate(OFFSET_HISTORY_KEY));
    }
  }, []);

  function navigateToItem(id: number) {
    if (props.handleTableNavigation) {
      props.handleTableNavigation(id);
      return;
    }

    dispatch(filterHistoryAddItem(OFFSET_HISTORY_KEY, props.tableOffset));
    history.push("/admin/order/" + String(id));
  }

  const elementScrollRef = useCallback(element => {
    const locationState = location.state;
    if (element !== null && locationState?.params?.orderId) {
      if (element?.id === locationState?.params?.orderId) {
        element?.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, []);

  function getFacilityName(facilityId: number) {
    const facilityName = props.facilities.find(facility => facility.id === facilityId).full_name;
    return facilityName;
  }

  return (
    <DataTable
      className="order-table"
      columns={
        props.facilities
          ? [
              { label: t("components.orders_table.orders_table.001") },
              { label: t("components.orders_table.orders_table.002") },
              { label: t("components.orders_table.orders_table.003") },
              { label: "Facility" }, // TODO: Translation
              { label: t("components.orders_table.orders_table.004") },
              { label: t("components.orders_table.orders_table.005") },
            ]
          : [
              { label: t("components.orders_table.orders_table.001") },
              { label: t("components.orders_table.orders_table.002") },
              { label: t("components.orders_table.orders_table.003") },
              { label: t("components.orders_table.orders_table.004") },
              { label: t("components.orders_table.orders_table.005") },
            ]
      }
      loading={props.orders === undefined}
      footer={{
        tableLimit: props.tableLimit,
        tableOffset: props.tableOffset,
        handleTableOffset: direction => props.handleTableOffset(direction),
        disableNextOffset: !props.orders || !(props.orders?.length === props.tableLimit),
      }}
    >
      {props.orders?.map(order => (
        <tr
          key={order.id}
          id={String(order.id)}
          className="clickable"
          onClick={() => navigateToItem(order.id)}
          ref={elementScrollRef}
        >
          <td>{`# ${order.number}`}</td>
          <td>
            {order.processed_at ? (
              <>
                <span>{moment.utc(order.processed_at).local().format("MMM DD, YYYY")} at </span>
                <span>{moment.utc(order.processed_at).local().format("h:mm A")}</span>
              </>
            ) : (
              <>
                <span>{moment.utc(order.created_at).local().format("MMM DD, YYYY")} at </span>
                <span>{moment.utc(order.created_at).local().format("h:mm A")}</span>
              </>
            )}
          </td>
          <td className="table-data-customer">
            <div className="wrap">
              {order.customer ? (
                <>
                  <Avatar size="medium" source={order.customer?.profile_image_source} />
                  <div className="customer">
                    <span>{order.customer?.full_name}</span>
                    <span>{order.customer?.email}</span>
                  </div>
                </>
              ) : (
                <div className="customer" />
              )}
            </div>
          </td>
          {props.facilities && <td>{getFacilityName(order.facility_id)}</td>}
          <td>{generateOrderStatusBadge(order.financial_status)}</td>
          <td>
            <LocaleCurrency currency={order.currency} amount={order.total_price} />
          </td>
        </tr>
      ))}
    </DataTable>
  );
}

/** Generate order status badges from financial_status title.
 *   -  Easily adjust badge styling in 1 place going forward
 *   -  TODO: Translations
 */
function generateOrderStatusBadge(status: OrderFinancialStatus): JSX.Element {
  switch (status) {
    case "paid":
      return (
        <Badge
          type="success"
          // className="paid-order-badge"
          iconLeft={<Icon icon={"check"} style={"far"} size="small" />}
        >
          {valueToString(status, "capitalize")}
        </Badge>
      );
    case "partially_paid":
      return (
        <Badge
          type="primary"
          // className="partial_paid-order-badge"
          iconLeft={<Icon icon={"circle-exclamation"} style={"far"} size="small" />}
        >
          {valueToString(status, "capitalize")}
        </Badge>
      );
    case "cancelled":
      return (
        <Badge
          type="error"
          // className="cancelled-order-badge"
          iconLeft={<Icon icon={"circle-xmark"} style={"far"} size="small" />}
        >
          {valueToString(status, "capitalize")}
        </Badge>
      );

    case "refunded":
      return (
        <Badge
          type="warning"
          // className="refunded-order-badge"
          iconLeft={<Icon icon={"turn-down-left"} style={"far"} size="small" />}
        >
          {valueToString(status, "capitalize")}
        </Badge>
      );

    // case "unpaid":
    //   return (
    //     <Badge
    //       type="gray"
    //       // className="unpaid-order-badge"
    //       iconLeft={<Icon icon={"turn-down-left"} style={"fas"} size="small" />}
    //     >
    //       {valueToString(status, "capitalize")}
    //     </Badge>
    //   );

    // case "partially_refunded":
    //   return (
    //     <Badge
    //       type="gray"
    //       // className="partial_refunded-order-badge"
    //       iconLeft={<Icon icon={"turn-down-left"} style={"far"} size="small" />}
    //     >
    //       {valueToString(status, "capitalize")}
    //     </Badge>
    //   );

    // case "voided":
    //   return (
    //     <Badge
    //       type="gray"
    //       // className="voided-order-badge"
    //       iconLeft={<Icon icon={"turn-down-left"} style={"fas"} size="small" />}
    //     >
    //       {valueToString(status, "capitalize")}
    //     </Badge>
    //   );

    default:
      return (
        <Badge type="gray" className="sales-channel-badge">
          {valueToString(status, "capitalize")}
        </Badge>
      );
  }
}
