import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { DeleteDashboard, GetDashboard, PutDashboard } from "api/rpc/facilityAdmin/client/dashboard";

import { showError, showSuccess } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";
import { unsavedChangesExist } from "helpers/Helpers";
import useModal from "hooks/modals/useModal";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Input from "components/form/input/Input";
import Popup from "components/popup/Popup";

interface IDashboardState {
  title: string;
}

export default function DashboardsEdit() {
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const dispatch = useAppDispatch();

  const [state, setState] = useState<IDashboardState>(undefined);
  const [beforeChangesState, setBeforeChangesState] = useState<IDashboardState>(undefined);

  const { state: deleteModal, updateModal, closeModal } = useModal();

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadDashboard(id, source.token);
    return () => source.cancel();
  }, [id]);

  async function loadDashboard(dashboardId: string | number, token?: CancelToken) {
    const res = await GetDashboard({ id: dashboardId }, true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError(res.data?.message));
      history.push("/admin/settings/dashboards");
      return;
    }

    if (res.data?.length > 0) {
      ReactDOM.unstable_batchedUpdates(() => {
        setState(prevState => ({ ...prevState, title: res.data[0]?.title }));
        setBeforeChangesState(prev => ({ ...prev, title: res.data[0]?.title }));
      });
    } else {
      dispatch(showError(t("secure.facility.settings.dashboards.dashboards_edit.001")));
      history.push("/admin/settings/dashboards");
      return;
    }
  }

  async function updateDashboard() {
    if (!state.title) {
      dispatch(showError(t("secure.facility.settings.dashboards.dashboards_edit.002")));
      return;
    }
    const res = await PutDashboard({ id: Number(id), title: state.title }, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError(res.data?.message));
      return;
    }
    dispatch(showSuccess(res?.message));
    history.push("/admin/settings/dashboards");
  }

  async function deleteDashboard() {
    const res = await DeleteDashboard({ id: Number(id) }, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError(res.data?.message));
      return;
    }

    dispatch(showSuccess(res.data?.message));
    history.push("/admin/settings/dashboards");
  }

  const primaryAction = {
    content: t("secure.facility.settings.dashboards.dashboards_edit.003"),
    action: () => updateModal({ isOpen: true }),
  };

  return (
    <Page
      title={t("secure.facility.settings.dashboards.dashboards_edit.004").concat(
        ` ${beforeChangesState ? beforeChangesState.title : ""}`,
      )}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.dashboards.dashboards_edit.005"),
          url: "/admin/settings/dashboards",
        },
      ]}
      notificationBarProps={{
        isVisible: unsavedChangesExist(state, beforeChangesState),
        onAction: updateDashboard,
        onCancel: () => setState(beforeChangesState),
      }}
    >
      <Card>
        <Card.Section>
          <Input
            value={state ? state.title : ""}
            label={t("secure.facility.settings.dashboards.dashboards_edit.007")}
            onChange={e => setState(prevState => ({ ...prevState, title: e.target.value }))}
            placeholder={t("secure.facility.settings.dashboards.dashboards_edit.008")}
            className="mb-2"
          />
        </Card.Section>
      </Card>

      <Popup
        open={deleteModal.isOpen}
        type="warning"
        title={t("secure.facility.settings.dashboards.dashboards_edit.009")}
        description={t("secure.facility.settings.dashboards.dashboards_edit.010")}
        okText={t("secure.facility.settings.dashboards.dashboards_edit.011")}
        onOk={deleteDashboard}
        cancelText={t("secure.facility.settings.dashboards.dashboards_edit.012")}
        onCancel={closeModal}
      />
    </Page>
  );
}
