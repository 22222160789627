import { APIDelete, APIGet, APIPost, APIPut } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/league/registration";

export type TLeagueRegistrationInputTypes = "input" | "select" | "checkbox" | "text";
export interface ILeagueRegistrationInput {
  id: number;
  label: string;
  required: boolean;
  type: TLeagueRegistrationInputTypes;
  help_text: string;
  row: number;
  column: number;
  values: string[] | null;
}

type TGetLeagueRegistrationInput = {
  league_id?: number;
};

export type TPostLeagueRegistrationInput = {
  league_id: number;
  label: string;
  help_text: string;
  type: TLeagueRegistrationInputTypes;
  required?: boolean; // backend defaults to true if not given
};

export type TPutLeagueRegistrationInput = {
  id: number;
  label?: string;
  help_text?: string;
  type?: TLeagueRegistrationInputTypes;
  values?: string[];
  required?: boolean;
};

export type TPutLeagueRegistrationInputPosition = {
  id: number;
  row: number;
  column: number;
};

export async function GetLeagueRegistrationInput(
  params: TGetLeagueRegistrationInput,
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet<ILeagueRegistrationInput[]>(baseUrl + "-input", params, useGlobalLoader, token);
}

export async function PostLeagueRegistrationInput(params: TPostLeagueRegistrationInput, useGlobalLoader: boolean) {
  return await APIPost<ILeagueRegistrationInput>(baseUrl + "-input", params, useGlobalLoader);
}

export async function PutLeagueRegistrationInput(params: TPutLeagueRegistrationInput, useGlobalLoader = true) {
  return await APIPut(baseUrl + "-input", params, useGlobalLoader);
}

export async function DeleteLeagueRegistrationInput(params: { id: number }, useGlobalLoader = true) {
  return await APIDelete(baseUrl + "-input", params, useGlobalLoader);
}

export async function PutLeagueRegistrationInputPositions(
  params: { inputs: TPutLeagueRegistrationInputPosition[] },
  useGlobalLoader = true,
) {
  return await APIPut(baseUrl + "-input/position", params, useGlobalLoader);
}
