import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import axios, { CancelToken } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";

import { StatusCode } from "api/protocols";
import { GetCustomer, PostCustomer, PostCustomerImport } from "api/rpc/2022-09/clientAdmin/customer/customer";

import { showError, showSuccess } from "redux/actions/ui";

import { useAppDispatch } from "hooks/redux";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { MOBILE_WIDTH } from "helpers/ScreenSizes";
import { customerErrorMessage } from "helpers/Helpers";
import useModal from "hooks/modals/useModal";

import Page from "components/page/Page";
import Sheet from "components/sheet/Sheet";
import NewCustomer, { ICustomerInfoState } from "components/newCustomer/NewCustomer";
import Callout from "components/callout/Callout";
import Search from "components/search/Search";
import { ButtonNew as Button } from "components/buttonNew";
import CustomerTable from "elements/customer/CustomerTable";
import Portal from "elements/Portal";

import "elements/customer/customer.scss";

interface IUploadFileState {
  fileSelectVisible: boolean;
  selectedFile: File;
}

const TABLE_LIMIT = 50;
export default function Customers() {
  const history = useHistory();
  const fileUploadRef = useRef(null);

  const dispatch = useAppDispatch();
  const windowSize = useWindowSize();

  const [customers, setCustomers] = useState(undefined);
  const [filter, setFilter] = useState({
    search: "",
    offset: 0,
  });

  const [uploadFileState, setUploadFileState] = useState<IUploadFileState>({
    fileSelectVisible: false,
    selectedFile: null,
  });

  const { state, updateModal, closeModal } = useModal();

  // Search Customers
  useEffect(() => {
    const source = axios.CancelToken.source();

    void loadCustomers(source.token);

    return () => {
      source.cancel();
    };
  }, [filter.search, filter.offset]);

  async function loadCustomers(token?: CancelToken) {
    if (customers !== undefined) {
      setCustomers(undefined);
    }

    const customerRes = await GetCustomer(
      {
        "default-address-line": true,
        limit: TABLE_LIMIT,
        offset: filter.offset,
        search: filter.search,
      },
      token ? false : true,
      token,
    );

    if (customerRes.status !== StatusCode.OK) {
      if (token && token.reason) {
        return;
      }
      dispatch(showError("Error loading customers")); // TODO: Translation
    }

    setCustomers(customerRes.status !== StatusCode.OK ? [] : customerRes.data);
  }

  async function createCustomer(customerInfo: ICustomerInfoState) {
    const error =
      customerInfo.firstName === "" ||
      customerInfo.lastName === "" ||
      (customerInfo.emailAddress === "" && customerInfo.phoneNumber === "");

    if (error) {
      return;
    }

    const customerRes = await PostCustomer(
      {
        first_name: customerInfo.firstName,
        last_name: customerInfo.lastName,
        phone: customerInfo.phoneNumber || null,
        email: customerInfo.emailAddress || null,
      },
      true,
    );

    if (customerRes.status !== StatusCode.OK) {
      dispatch(showError(customerErrorMessage(t, customerRes?.data?.message)));
      setCustomers([]);
      return;
    }

    dispatch(showSuccess(customerRes.data.message));
    void loadCustomers();
    closeModal();
  }

  async function handleImport() {
    const formData = new FormData();

    formData.append("file", uploadFileState.selectedFile);

    const importRes = await PostCustomerImport(formData, true);
    if (importRes.status !== StatusCode.OK) {
      dispatch(showError("Error importing customers")); // TODO: Translation
      return;
    }
    dispatch(showSuccess("Successfully imported customers")); // TODO: Translation

    closeImport();
    void loadCustomers();
  }

  function handleFileSelect(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files;
    if (file && file.length > 0) {
      setUploadFileState(prevState => ({ ...prevState, selectedFile: file[0] }));
    }
  }

  function closeImport() {
    fileUploadRef.current.value = "";
    setUploadFileState(prevState => ({ ...prevState, selectedFile: null, fileSelectVisible: false }));
  }

  function handleTableNavigation(direction: "prev" | "next") {
    switch (direction) {
      case "prev": {
        setFilter(prev => ({ ...prev, offset: filter.offset - TABLE_LIMIT }));
        break;
      }
      case "next": {
        setFilter(prev => ({ ...prev, offset: filter.offset + TABLE_LIMIT }));
        break;
      }
      default:
        return;
    }
  }

  const addCustomerAction = {
    content: (
      <div>
        <FontAwesomeIcon className="mr-2" icon={["far", "plus"]} />
        Add Customer {/* TODO: Translation */}
      </div>
    ),
    action: () => updateModal({ isOpen: true }),
  };

  const secondaryActions = [
    {
      content: (
        <div>
          <FontAwesomeIcon className="mr-2" icon={["far", "cloud-arrow-up"]} />
          Import {/* TODO: Translation */}
        </div>
      ),
      action: () => setUploadFileState(prevState => ({ ...prevState, fileSelectVisible: true })),
    },
  ];

  return (
    <>
      <Page
        title="Customers" // TODO: Translation
        primaryAction={windowSize.width > MOBILE_WIDTH ? addCustomerAction : undefined}
        secondaryActions={windowSize.width > MOBILE_WIDTH ? secondaryActions : undefined}
      >
        <div className="flex-grow mb-3.5">
          <Search
            historyKey={"customers_table_search_client"}
            searchCallback={searchValue => setFilter(prev => ({ ...prev, search: searchValue, offset: 0 }))}
            placeholder="Search customers by name, membership, address, or phone..." // TODO: Translation
          />
        </div>

        <div className="customers-add-customer-actions-mobile">
          {secondaryActions.map((secondaryAction, index) => {
            return (
              <Button key={index} type="secondary" size="medium" onClick={secondaryAction.action}>
                {secondaryAction.content}
              </Button>
            );
          })}
          <Button type="primary" size="medium" onClick={addCustomerAction.action}>
            {addCustomerAction.content}
          </Button>
        </div>

        <CustomerTable
          customers={customers}
          onClick={customer => history.push("/admin/customers/" + String(customer.id) + "/profile")}
          tableLimit={TABLE_LIMIT}
          tableOffset={filter.offset}
          handlePagination={direction => handleTableNavigation(direction)}
        />
      </Page>

      <NewCustomer
        newCustomerSheetActive={state.isOpen}
        onCancel={closeModal}
        onOk={createCustomer}
        searchValue={filter.search}
      />

      <Portal isMounted={uploadFileState.fileSelectVisible}>
        <Sheet
          title="Select File" // TODO: Translation
          open={uploadFileState.fileSelectVisible}
          size="small"
          closable
          onCancel={closeImport}
          onOk={handleImport}
          okText="Import" // TODO: Translation
          okDisabled={!uploadFileState.selectedFile ? true : false}
        >
          <Callout
            type="info"
            title={!uploadFileState.selectedFile ? "No file selected" : "Selected File"} // TODO: Translation
            content={!uploadFileState.selectedFile ? "Select a file to continue" : uploadFileState.selectedFile.name} // TODO: Translation
          />

          <div className="customer-import-container">
            <input
              ref={fileUploadRef}
              className="customer-import-input"
              type="file"
              accept=".csv"
              onChange={handleFileSelect}
            />
            <FontAwesomeIcon className="customer-import-icon" icon={["far", "upload"]} />
          </div>
        </Sheet>
      </Portal>
    </>
  );
}
