import React, { ChangeEvent, useEffect, useState } from "react";

import { StatusCode } from "api/protocols";
import { showError, showSuccess } from "redux/actions/ui";

import { useGuestPortalContext } from "./GuestPortalContext";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input";
import { useTranslation } from "react-i18next";
import "./portalOverview.scss";
import CustomerImage from "elements/customer/CustomerImage";
import { DeleteCustomerProfileImage, PutCustomer, UploadCustomerProfileImage } from "api/rpc/2024-04/customer/customer";
import Popup from "components/popup/Popup";
import { UserLogout } from "api/rpc";
import { useHistory } from "react-router";

export default function PortalOverview() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { portalState, updatePortalState } = useGuestPortalContext();
  const guest = portalState.guest;

  const [editState, setEditState] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
  });

  const [filterState, setFilterState] = useState({
    adminUserLoggedIn: false,
  });

  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (portalState.guest) {
      setEditState({
        first_name: guest.first_name,
        last_name: guest.last_name,
        email: guest.email ?? "",
        phone: guest.phone ?? "",
      });
    }
  }, []);

  useEffect(() => {
    if (guest?.user_type === 2 || guest?.user_type === 3) {
      setFilterState(prevState => ({ ...prevState, adminUserLoggedIn: true }));

      return;
    }
  }, [guest]);

  async function updateGuestProfile() {
    const params = {
      id: portalState.guest.id,
      ...editState,
    };
    const customerUpdateRes = await PutCustomer(params, true);

    if (customerUpdateRes.status === StatusCode.OK) {
      updatePortalState({ guest: customerUpdateRes.data });
      dispatch(showSuccess(t("guest.portal.portal_overview.001")));
      return;
    } else {
      dispatch(showError(t("guest.portal.portal_overview.002")));
    }
  }

  function handleOnChangeEvent(event: ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;
    setEditState(prev => ({ ...prev, [id]: value }));
  }

  async function saveCustomerImage(imageFile: File) {
    const formData = new FormData();

    formData.append("profile_image", imageFile);

    const imageRes = await UploadCustomerProfileImage(formData, true);
    if (imageRes.status !== StatusCode.OK) {
      dispatch(showError("Error uploading image"));
      return;
    }

    dispatch(showSuccess("Image was uploaded successfully"));

    const tempCustomer = { ...guest, ...imageRes.data };
    updatePortalState({ guest: tempCustomer });
  }

  async function handleRemovepicture() {
    const imageResponse = await DeleteCustomerProfileImage(true);
    if (imageResponse.status !== StatusCode.OK) {
      dispatch(showError("Error removing image"));
      return;
    }
    updatePortalState({ guest: imageResponse.data });
    dispatch(showSuccess("Image removed successfully"));
  }

  async function logoutUser() {
    const logoutRes = await UserLogout(true);

    if (logoutRes.status !== StatusCode.OK) {
      dispatch(showError("Error logging out"));
    }

    history.push(`/tee-on/portal/login`);
  }

  return (
    <Page
      full
      secondaryActions={[
        {
          content: t("guest.portal.portal_overview.004"),
          action: () => updateGuestProfile(),
        },
      ]}
      title={t("guest.portal.portal_overview.003")}
    >
      <Card title={t("guest.portal.portal_overview.009")}>
        <Card.Section>
          <FormLayout.Group>
            <CustomerImage
              circleImage
              imageSource={guest.profile_image_source}
              saveProfileImage={imageFile => saveCustomerImage(imageFile)}
              deleteProfileImage={handleRemovepicture}
            />
          </FormLayout.Group>
          <br></br>
          <FormLayout.Group>
            <div id="first-name">
              <Input
                id="first_name"
                label={t("guest.portal.portal_overview.005")}
                className="basic-information-input"
                value={editState.first_name}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleOnChangeEvent(event)}
              />
            </div>
            <div id="last-name">
              <Input
                id="last_name"
                label={t("guest.portal.portal_overview.006")}
                className="basic-information-input"
                value={editState.last_name}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleOnChangeEvent(event)}
              />
            </div>
          </FormLayout.Group>
          <FormLayout.Group>
            <div>
              <Input
                id="email"
                label={t("guest.portal.portal_overview.007")}
                className="basic-information-input"
                value={editState.email}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleOnChangeEvent(event)}
              />
            </div>
            <div>
              <Input
                id="phone"
                label={t("guest.portal.portal_overview.008")}
                type="text"
                className="basic-information-input"
                value={editState.phone}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleOnChangeEvent(event)}
              />
            </div>
          </FormLayout.Group>
        </Card.Section>
      </Card>

      <Popup
        open={filterState.adminUserLoggedIn}
        type="warning"
        title="Admin Account Detected"
        description="Looks like you are logged into an admin account. Click logout below to view account overview"
        onOk={logoutUser}
        okText="Logout"
      />
    </Page>
  );
}
