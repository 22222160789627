import { IUserActive } from "redux/reducers/models/user";
import { AuthActionTypes } from "./actionTypes";
import { IAuthAction } from "redux/reducers/auth";

type AuthDispatchType = (action: IAuthAction) => void;

export function userLogin(user: IUserActive) {
  return (dispatch: AuthDispatchType) => {
    dispatch({ type: AuthActionTypes.USER_LOGIN, payload: user });
  };
}

export function userLogout() {
  return (dispatch: AuthDispatchType) => {
    dispatch({ type: AuthActionTypes.USER_LOGOUT });
  };
}

export function initialize(initialized: boolean) {
  return (dispatch: AuthDispatchType) => {
    dispatch({ type: AuthActionTypes.USER_INITIALIZE, payload: { initialized } });
  };
}

export interface IAuthActions {
  userLogin: typeof userLogin;
  userLogout: typeof userLogout;
  initialize: typeof initialize;
}
