import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import axios, { CancelToken } from "axios";
import classNames from "classnames";

import { StatusCode } from "api/protocols";
import {
  GetTournamentFlightPlayers,
  GetTournamentFlights,
  PutTournamentFlightPlayers,
  PutTournamentFlights,
  TPutTournamentFlight,
} from "api/rpc/2024-04/facilityAdmin/tournament/scoring/flights/flights";
import { GetTournamentParticipant } from "api/rpc/2024-04/facilityAdmin/tournament/participant";

import { showError, showSuccess } from "redux/actions/ui";
import { ITournamentParticipant, TTournamentTeamPlayer } from "redux/reducers/models/tournament";
import { useAppDispatch } from "hooks/redux";
import { handleChangeEventInput } from "helpers/Helpers";
import useModal from "hooks/modals/useModal";

import { IPrimaryPageAction, ISecondaryPageAction } from "components/page/PageActions";
import Page from "components/page/Page";
import Form from "components/form/Form";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import Sheet from "components/sheet/Sheet";
import Popup from "components/popup/Popup";
import Checkbox from "components/form/checkbox/Checkbox";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import Portal from "elements/Portal";

export default function TournamentFlightEdit() {
  const history = useHistory();
  const { t } = useTranslation();
  const { tournamentId, flightId } = useParams<{ tournamentId: string; flightId: string }>();

  const dispatch = useAppDispatch();

  const [editState, setEditState] = useState<TPutTournamentFlight>({
    id: tournamentId,
    name: "",
  });

  const [flightPlayers, setFlightPlayers] = useState<TTournamentTeamPlayer[]>(undefined);
  const [loadFlightPlayerTrigger, setLoadFlightPlayerTrigger] = useState(false); // load teamPlayers with axios source token

  const { state: addPlayerModal, updateModal: updateAddPlayerModal } = useModal({
    clearOnClose: false,
    selectedIds: new Set<number>(),
    allFlightPlayers: [] as ITournamentParticipant[],
  });

  const selectedIdsAreEqualToPlayers =
    JSON.stringify(Array.from(addPlayerModal.selectedIds).sort()) ==
    JSON.stringify(flightPlayers?.map(player => player.id).sort());

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadTournamentPlayers(tournamentId, source.token);
    return () => source.cancel();
  }, [tournamentId]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadTournamentFlights(flightId, source.token);
    void loadFlightPlayers(flightId, source.token);
    return () => source.cancel();
  }, [flightId]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (loadFlightPlayerTrigger) {
      void loadFlightPlayers(flightId, source.token);
    }
    return () => source.cancel();
  }, [flightId, loadFlightPlayerTrigger]);

  async function loadTournamentPlayers(tournament_id: string | number, token?: CancelToken) {
    const res = await GetTournamentParticipant({ tournament_id }, token ? false : true, token);
    if (token && token.reason) {
      return;
    }

    // if(res.status !== StatusCode.OK) {
    //   dispatch(showError("Error loading all tournament players"));
    // }

    updateAddPlayerModal({ allFlightPlayers: res.status !== StatusCode.OK ? [] : res.data });
  }

  async function loadTournamentFlights(id: string | number, token?: CancelToken) {
    const res = await GetTournamentFlights({ id }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }

    // if(res.status !== StatusCode.OK) {
    //   dispatch(showError("Error loading tournament flight"));
    // }

    setEditState(prev => ({ ...prev, name: res.data[0]?.name }));
  }

  async function loadFlightPlayers(flight_id: string | number, token?: CancelToken) {
    if (flightPlayers !== undefined) {
      setFlightPlayers(undefined);
    }
    const res = await GetTournamentFlightPlayers({ flight_id }, token ? false : true, token);
    if (token && token.reason) {
      return;
    }

    // if(res.status !== StatusCode.OK) {
    //   dispatch(showError("Error loading flight players"));
    // }

    setFlightPlayers(res.status !== StatusCode.OK ? [] : res.data);
    setLoadFlightPlayerTrigger(false);
  }

  async function updateFlight(flight: TPutTournamentFlight) {
    const res = await PutTournamentFlights(flight, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError(typeof res.data === "string" ? res.data : "Error updating flight.")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Successfully updated tournament flight.")); // TODO: Translation
    history.push(`/admin/tournament/${tournamentId}/flights`);
  }

  async function addParticipantsToFlight() {
    const params = {
      flight_id: flightId,
      participant_ids: Array.from(addPlayerModal.selectedIds),
    };
    const res = await PutTournamentFlightPlayers(params, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error adding participant")); // TODO: Translation
      return;
    }

    updateAddPlayerModal({ isOpen: false, selectedIds: new Set() });
    setLoadFlightPlayerTrigger(true);
  }

  async function deleteFlight() {
    // Add in delete functionality
  }

  const selectPlayerId = (e: ChangeEvent<HTMLInputElement>) => {
    const { id } = e.target;
    const updatedIds = new Set(addPlayerModal.selectedIds);

    // Allow user to deselect a chosen player not already found instead of closing and reopening the modal
    if (addPlayerModal.selectedIds.has(Number(id)) && !flightPlayers.map(player => player.id).includes(Number(id))) {
      updatedIds.delete(Number(id));
    } else {
      updatedIds.add(Number(id));
    }

    updateAddPlayerModal({ selectedIds: updatedIds });
  };

  const primaryAction: IPrimaryPageAction = {
    content: "Update", // TODO: Translation
    action: () => updateFlight(editState),
    disabled: !editState.name,
  };

  const secondaryAction: ISecondaryPageAction[] = [
    {
      content: "Add Participant", // TODO: Translation
      action: () =>
        updateAddPlayerModal({
          isOpen: true,
          selectedIds: new Set(flightPlayers?.map(player => player.id as number)),
        }),
    },
  ];

  return (
    <Page
      title={"Edit Flight"} // TODO: Translation
      narrow
      primaryAction={primaryAction}
      secondaryActions={secondaryAction}
      breadcrumbs={[
        { prefix: true, label: "Flights", /* TODO: Translation */ url: `/admin/tournament/${tournamentId}/flights` },
      ]}
    >
      <Form>
        <Card title={"Flight Details"} /* TODO: Translation */>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  id="name"
                  value={editState.name}
                  onChange={e => handleChangeEventInput(e, editState, setEditState)}
                  label={t("secure.facility.league.new.012")}
                  placeholder={t("secure.facility.league.new.012")}
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>
        <Card title={"Flight Players"} /* TODO: Translation */>
          <DataTable
            columns={[
              { label: "Name", /* TODO: Translation */ width: "90%" },
              { label: "", width: "10%" },
            ]}
            loading={flightPlayers === undefined}
          >
            {flightPlayers?.map(player => (
              <tr key={player.id}>
                <td>{player.customer.full_name}</td>
                <td></td>
              </tr>
            ))}
          </DataTable>
        </Card>
      </Form>

      <Portal isMounted={false}>
        <Popup
          open={false}
          type="warning"
          title={"Deleting Flight"} // TODO: Translation
          description={"Are you sure you want to delete this flight?"} // TODO: Translation
          okText={"Delete"} // TODO: Translation
          // onCancel={() => setEditState(prevState => ({ ...prevState, showDeleteTeamWarning: false }))}
          // onOk={handleDeleteFlight}
        />
      </Portal>

      <Portal isMounted={addPlayerModal.isOpen}>
        <Sheet
          open={addPlayerModal.isOpen}
          title={"Add Participant"} // TODO: Translation
          size="small"
          closable
          okText={"Add Players"} // TODO: Translation
          backDropCancel={false}
          okDisabled={selectedIdsAreEqualToPlayers || addPlayerModal.selectedIds.size === 0}
          onOk={() => addParticipantsToFlight()}
          onCancel={() => updateAddPlayerModal({ isOpen: false, selectedIds: new Set() })}
        >
          <div className="ui-checkbox-group">
            {addPlayerModal.allFlightPlayers?.map(player => {
              return (
                <div
                  key={player.id}
                  className={classNames("ui-checkbox-group-item", "mb-2", {
                    "ui-checkbox-group-item_selected": addPlayerModal.selectedIds.has(player.id),
                  })}
                >
                  <div className="ui-checkbox-group-item-content">
                    <div className="ui-checkbox-group-item-text">
                      <p className="text-sm text-medium text-gray-700">{player.customer?.full_name}</p>
                    </div>
                  </div>
                  <Checkbox
                    id={String(player.id)}
                    checked={addPlayerModal.selectedIds.has(player.id)}
                    onChange={selectPlayerId}
                    size="medium"
                  />
                </div>
              );
            })}
          </div>
        </Sheet>
      </Portal>
    </Page>
  );
}
