import { createContext, useContext } from "react";
import { ISelectedEditInput } from "components/draggableEditInput/DraggableEditInput";
import {
  IUnsavedChangesContainer,
  IUnsavedChangesDispatchActions,
} from "hooks/useUnsavedChangesContainer/useUnsavedChangesContainer";
import { TLeagueRegistrationInputRows } from "./tabs/fee/RegistrationFeeInputs";

export type LeagueRegistrationHandleUpdateInput = (
  id: any,
  label: string,
  helpText: string,
  value: string[] | null,
  isRequired?: boolean,
) => void;

interface ILeagueRegistrationContext {
  selectedInput: ISelectedEditInput;
  setSelectedInput: React.Dispatch<React.SetStateAction<ISelectedEditInput>>;
  inputRowsContainer: IUnsavedChangesContainer<TLeagueRegistrationInputRows>;
  inputRowsContainerActions: IUnsavedChangesDispatchActions<TLeagueRegistrationInputRows>;
  handleUpdateInput: LeagueRegistrationHandleUpdateInput;
  closeInputEditModal: () => void;
}

const defaultLeagueRegistrationContext: ILeagueRegistrationContext = {
  selectedInput: undefined,
  setSelectedInput: undefined,
  inputRowsContainer: undefined,
  inputRowsContainerActions: undefined,
  handleUpdateInput: undefined,
  closeInputEditModal: undefined,
};

export const LeagueRegistrationContext = createContext<ILeagueRegistrationContext>(defaultLeagueRegistrationContext);
export const useLeagueRegistrationContext = () => useContext(LeagueRegistrationContext);
