import React, { useState } from "react";
import { RouteProps, useHistory, useParams } from "react-router";

import { StatusCode } from "api/protocols";
import { UserLogin } from "api/rpc";
import { showError, showSuccess } from "redux/actions/ui";

import { useGuestPortalContext } from "./GuestPortalContext";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import Input from "components/form/input";
import { ButtonNew as Button } from "components/buttonNew";

import "./portalLogin.scss";
import { clientPortalUpdate } from "redux/actions/clientPortal";
import { GetTeeTimeGuest, LockTeeTimeById } from "api/rpc/2022-09/guest/bookingEngine";
import { GetClientGuest } from "api/rpc/2022-09/guest/client";
import { GetGuestTeeSheetSettings } from "api/rpc/2022-09/guest/facility";
import { convertTime, formatDateFromString } from "helpers/Helpers";
import { useClientPortal } from "../Customer/ClientPortal/useClientPortal";
import { GetTeeTimesCustomer } from "api/rpc/2022-09/customer/teetime";

interface ILoginState {
  email: string;
  password: string;
}

interface IParams {
  clientShortName: string;
}

export default function PortalLogin(props: RouteProps) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const clientPortalStore = useAppSelector(store => store.clientPortalStore);
  const { updatePortalState } = useGuestPortalContext();
  const { clientShortName } = useParams<IParams>();
  const clientPortal = window.location.href.includes(`/portal/${clientShortName}/login`);
  const HOME_URL = useClientPortal().getHomePageUrl();

  const [loginState, setLoginState] = useState<ILoginState>({
    email: "",
    password: "",
  });

  async function handleTeeOnPortalLogin() {
    const user = await handleLogin();
    if (!user) {
      return;
    }

    updatePortalState({ guest: user });
    dispatch(showSuccess("Logged in successfully", 2000));

    history.push(`/tee-on/portal/overview`);
  }

  async function handleClientPortalLogin() {
    const user = await handleLogin();
    if (!user) {
      return;
    }

    dispatch(clientPortalUpdate({ user: user }));

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const date = urlParams.get("date");
    const teeTimeId = urlParams.get("id");
    const facility = urlParams.get("facility");

    if (date && teeTimeId) {
      await handleNavigateToTeeTime(date, Number(teeTimeId), facility);
    } else {
      history.push(HOME_URL);
    }
  }

  async function handleLogin() {
    const params = {
      email: loginState.email,
      password: loginState.password,
    };

    const loginRes = await UserLogin(params, true);

    if (loginRes.status !== StatusCode.OK) {
      dispatch(showError("Invalid Credentials, please try again"));
      return null;
    }

    return loginRes.data?.user;
  }

  async function handleNavigateToTeeTime(teeTimeDate: string, teeTimeId: number, facilityShortName: string) {
    let client = clientPortalStore?.client;
    let facility = clientPortalStore.selectedFacility;
    let foundTeeTime = clientPortalStore?.selectedTeeTime;
    let teeSheetSettings = clientPortalStore?.teeSheetSettings;

    if (!client) {
      const clientRes = await GetClientGuest({ short_name: clientShortName }, true);
      if (clientRes.status !== StatusCode.OK || clientRes?.data?.length === 0) {
        dispatch(showError("Error loading client"));
        return;
      }
      client = clientRes.data[0];
      dispatch(clientPortalUpdate({ client: client }));
    }

    if (!facility) {
      const foundFacility = client?.facilities?.find(facility => facility?.short_name === facilityShortName);
      if (foundFacility) {
        facility = foundFacility;
        dispatch(clientPortalUpdate({ selectedFacility: foundFacility }));
      }
    }

    if (!teeSheetSettings) {
      const settingsRes = await GetGuestTeeSheetSettings({ facility_id: facility?.id }, true);
      if (settingsRes.status !== StatusCode.OK) {
        dispatch(showError("Error getting tee sheet settings"));
        return;
      }
      teeSheetSettings = settingsRes.data;
      dispatch(clientPortalUpdate({ teeSheetSettings }));
    }

    const teeTimeParams = {
      date: teeTimeDate,
      id: teeTimeId,
      facility_id: facility?.id,
      extended: true,
      turn_tee_time: true,
    };

    const teeTimeRes = await GetTeeTimesCustomer(teeTimeParams, true);

    if (teeTimeRes.status !== StatusCode.OK) {
      dispatch(showError("Error getting selected tee time"));
      return;
    }

    if (teeTimeRes?.data?.length === 0) {
      dispatch(showError("Tee time is unavailable, please choose another"));
      history.push(HOME_URL);
      return;
    }
    const formattedStartTime = convertTime(teeTimeRes.data[0]?.start_time);
    const formattedDate = formatDateFromString(teeTimeRes.data[0]?.date);
    foundTeeTime = { ...teeTimeRes.data[0], formattedDate, formattedStartTime };

    if (foundTeeTime?.id === teeTimeId) {
      //If not null - User has access to their own locked teetime
      if (foundTeeTime?.lock !== null) {
        const tempCurrentDate = new Date().toUTCString();
        const currentTempTime = new Date(tempCurrentDate);
        const currentTime = new Date(currentTempTime).getTime();

        const tempString = String(foundTeeTime?.lock?.start_time) + "Z";

        const lockStartDate = new Date(tempString);

        const tempExpiryString = String(foundTeeTime?.lock?.expire_time) + "Z";
        const lockoutTempExpiry = new Date(tempExpiryString);

        const lockStartTime = lockStartDate.getTime();
        const lockoutExpiryTime = lockoutTempExpiry.getTime();

        const lockoutDuration = lockoutExpiryTime - currentTime;

        dispatch(
          clientPortalUpdate({
            selectedTeeTimeLock: {
              lockoutDuration: lockoutDuration,
              lockStartTime: lockStartTime,
              expireTime: foundTeeTime?.lock?.expire_time,
            },
            selectedTeeTime: foundTeeTime,
          }),
        );

        history.push(`/portal/${clientShortName}/booking/id?=${teeTimeId}&date=${teeTimeDate}`);
      } else {
        const bookingLockRes = await LockTeeTimeById({ tee_time_id: foundTeeTime?.id }, true);

        if (bookingLockRes.status !== StatusCode.OK) {
          dispatch(showError("Error locking tee time"));
          return;
        }

        //Locked out message
        const bookingLockMessage = "Tee time already locked by user";
        const bookingLockResMessage = bookingLockRes.data.message;

        if (bookingLockMessage === bookingLockResMessage) {
          dispatch(showError("Booking is locked by another user, please try again in a few minutes.."));
          return;
        }

        const tempCurrentDate = new Date().toUTCString();
        const currentTempTime = new Date(tempCurrentDate);
        const currentTime = new Date(currentTempTime).getTime();

        const tempString = String(bookingLockRes?.data?.start_time) + "Z";
        const lockStartDate = new Date(tempString);

        const tempExpiryString = String(bookingLockRes?.data?.expire_time) + "Z";
        const lockoutTempExpiry = new Date(tempExpiryString);

        const lockStartTime = lockStartDate.getTime();
        const lockoutExpiryTime = lockoutTempExpiry.getTime();

        const lockoutDuration = lockoutExpiryTime - currentTime;

        dispatch(
          clientPortalUpdate({
            selectedTeeTimeLock: {
              lockoutDuration: lockoutDuration,
              lockStartTime: lockStartTime,
              expireTime: bookingLockRes?.data?.expire_time,
            },
            selectedTeeTime: foundTeeTime,
          }),
        );

        history.push(`/portal/${clientShortName}/booking/id?=${teeTimeId}&date=${teeTimeDate}`);
      }
    } else {
      dispatch(showError("Tee time is unavailable, please choose another"));
      history.push(HOME_URL);
      return;
    }
  }

  // Call handleLogin if enter key pressed
  function handleEnterKeydown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      if (clientPortal) {
        void handleClientPortalLogin();
      } else {
        void handleTeeOnPortalLogin();
      }
    }
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;
    setLoginState(prevState => ({ ...prevState, [id]: value }));
  }

  function navigateToRegister() {
    if (clientPortal) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const date = urlParams.get("date");
      const teeTimeId = urlParams.get("id");
      const facility = urlParams.get("facility");
      if (date && teeTimeId && facility) {
        history.push(`/portal/${clientShortName}/register?id=${teeTimeId}&date=${date}&facility=${facility}`);
      } else {
        history.push(`/portal/${clientShortName}/register`);
      }
    } else {
      history.push(`/tee-on/portal/register`);
    }
  }

  function navigateToForgotPassword() {
    if (clientPortal) {
      window.open(`/portal/${clientShortName}/forgot`);
    } else {
      window.open(`/tee-on/portal/forgot`);
    }
  }

  return (
    <>
      <div className="tee-on-portal-login">
        <div className="tee-on-portal-login-header-container">
          <div className="tee-on-portal-login-header-container-centered">
            <div className="tee-on-portal-login-header-container-inner">
              <img
                src={
                  clientPortal && clientPortalStore?.client?.logo_source_url
                    ? clientPortalStore?.client?.logo_source_url
                    : "/public/images/Tee-On.png"
                }
                alt="Client logo"
              />
            </div>
          </div>
        </div>

        <div className="tee-on-portal-login-container">
          <div className="tee-on-portal-login-container-inner">
            <div className="tee-on-portal-login-container-group">
              <div className="tee-on-portal-login-text-group">
                <p className="sign-in-text">Sign In</p>
                <br />
                <p className="sign-in-info">
                  {clientPortal
                    ? "Please sign into an account to book a tee time"
                    : "Please sign into an account to see profile information."}
                </p>
              </div>

              <div className="tee-on-portal-login-input-group">
                <label className="tee-on-portal-login-input-label" htmlFor="username">
                  Email Address
                </label>
                <Input
                  value={loginState.email}
                  onChange={handleInputChange}
                  type="email"
                  labelHidden
                  onKeyDown={handleEnterKeydown}
                  id="email"
                />
              </div>

              <div className="tee-on-portal-login-input-group">
                <label className="tee-on-portal-login-input-label" htmlFor="password">
                  Password
                </label>
                <Input
                  value={loginState.password}
                  onChange={handleInputChange}
                  type="password"
                  labelHidden
                  onKeyDown={handleEnterKeydown}
                  id="password"
                />
              </div>

              <div className="tee-on-portal-login-forgot-password">
                <Button type="text" onClick={navigateToForgotPassword}>
                  Forgot Password?
                </Button>
              </div>
            </div>

            <div>
              <Button
                type="primary"
                block
                onClick={clientPortal ? handleClientPortalLogin : handleTeeOnPortalLogin}
                className="tee-on-portal-login-button"
              >
                SIGN IN
              </Button>
            </div>
            <div>
              <Button type="default" block onClick={navigateToRegister}>
                REGISTER
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
