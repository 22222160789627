import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { IAuthState } from "redux/reducers/auth";
import { IFacility } from "redux/reducers/models/facility";

import { IUIActions, showError, showSuccess } from "redux/actions/ui";
import { IProduct, IVariant } from "redux/reducers/models/product";
import { StatusCode } from "api/protocols";
import {
  DeleteTicketImage,
  GetTickets,
  PostTicketImage,
  PutTicket,
  RemoveRedemptionProduct,
  AddRedemptionProducts,
} from "api/rpc/2024-04/facilityAdmin/ticket/ticket";
import { GetFacility } from "api/rpc/2022-09/facilityAdmin/facility/facility";
import { TicketStubType } from "pages/secure/facility/customer/context/contextTypes";

import Page from "components/page/Page";
import Card from "components/card/Card";
import { ButtonNew as Button } from "components/buttonNew";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import { Select } from "components/select/";
import TextField from "components/form/textField/TextField";
import Checkbox from "components/form/checkbox/Checkbox";
import RedemptionProductModal from "./modals/RedemptionProductModal";

import "pages/secure/facility/product/Ticket.scss";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import moment from "moment";
import TextEditor from "components/textEditor/textEditor";
import { isEqualWith, isNull } from "lodash";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { sortObj } from "helpers/Helpers";
import Sheet from "components/sheet/Sheet";
import ProductSelect from "components/productModal/ProductSelect";
import Portal from "elements/Portal";
import ReactDOM from "react-dom";

interface ITicketState {
  id: number;
  title: string;
  subtitle?: string;
  included: string;
  description: string;
  value: number;
  expiry_type: string;
  expiry_date: string;
  expiry_days: number;
  valid_facilities: Array<number>;
  facility_ids_list: IFacilityInformation[];
  selectedDate: Date;
  product_redemption: any[];
  logo_image: string;
  ticket_stubs: Array<TicketStubType>;
  archived: boolean;
  terms: Array<string>;
  client_id: number;
}

interface IFacilityInformation {
  id: any;
  value: any;
  label: any;
  isChecked: boolean;
}

interface ISingleProps {
  authStore: IAuthState;
  uiActions: IUIActions;
}

const Single: React.FC<ISingleProps> = props => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { Option } = Select;
  const { ticketId }: any = useParams();
  const imageUploadInputRef = useRef(null);
  const dispatch = useAppDispatch();

  const { facilityStore } = useAppSelector(store => store);

  const [redemptionProductModalOpen, setRedemptionProductModalOpen] = useState<boolean>(false);
  const [selectedProducts, setSelectedProducts] = useState<Array<IProduct>>([]);

  const [ticketStateBeforeChanges, setTicketStateBeforeChanges] = useState<ITicketState>(undefined);
  const [ticketLoaded, setTicketLoaded] = useState<boolean>(false);

  const [optionState, setOptionState] = useState({
    expiryTypes: [
      { label: "Date", value: "date" },
      { label: "Days", value: "days" },
      { label: "None", value: "none" },
    ],
  });

  const [ticketState, setTicketState] = useState<ITicketState>({
    id: null,
    title: "",
    subtitle: "",
    included: "",
    description: "",
    value: 0,
    expiry_type: "",
    expiry_date: "",
    expiry_days: null,
    valid_facilities: [],
    facility_ids_list: [],
    selectedDate: new Date(),
    product_redemption: [],
    logo_image: null,
    ticket_stubs: [],
    archived: false,
    terms: null,
    client_id: null,
  });

  useEffect(() => {
    void loadTicket();
  }, []);

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setTicketState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleDescriptionChange(value: string) {
    setTicketState(prevState => ({ ...prevState, description: value }));
  }

  function changeFacilityList(facility: any) {
    const updatedFacilities = [...ticketState.valid_facilities];
    const indexFound = ticketState?.valid_facilities?.findIndex(facilityId => facilityId === facility?.id);

    if (indexFound !== -1) {
      updatedFacilities.splice(indexFound, 1);
    } else {
      updatedFacilities.push(facility?.id);
    }

    setTicketState(prevState => ({
      ...prevState,
      valid_facilities: updatedFacilities,
    }));
  }

  async function loadTicket() {
    const facilityRes = await GetFacility(
      {
        "client-facilities": true,
      },
      true,
    );

    if (facilityRes.status !== StatusCode.OK) {
      dispatch(showError("Error getting facilities"));
      return;
    }

    const tempFacilityIdList: IFacilityInformation[] = [];

    const params = {
      id: ticketId,
      extended: true,
    };

    const loadTicketRes = await GetTickets(params, true);
    if (loadTicketRes.status !== StatusCode.OK) {
      dispatch(showError("Error getting ticket"));
      return;
    }

    const ticket = loadTicketRes.data[0];

    const tempValidFacilityIds: number[] = [];
    //Get valid facility id's
    for (let i = 0; i < ticket.valid_facilities.length; i++) {
      tempValidFacilityIds.push(ticket.valid_facilities[i].id);
    }

    for (let i = 0; i < facilityRes.data.length; i++) {
      const tempFacilityId = {
        id: facilityRes.data[i].id,
        value: facilityRes.data[i].id,
        label: facilityRes.data[i].long_name,
        isChecked: false,
      };

      tempFacilityIdList.push(tempFacilityId);
    }

    const currentExpiryDate = new Date(moment(ticket.expiry_date).format("LL"));

    setTicketState(prevState => ({
      ...prevState,
      id: ticket.id,
      title: ticket.title,
      subtitle: ticket.subtitle ?? "",
      included: ticket.included,
      description: ticket.description,
      value: ticket.value ?? null,
      expiry_date: ticket.expiry_date ?? "",
      expiry_days: ticket.expiry_days,
      expiry_type: ticket.expiry_type,
      logo_image: ticket.logo_image, // in GET
      selectedDate: currentExpiryDate,
      facility_ids_list: tempFacilityIdList,
      product_redemption: ticket.variant_redemption,
      ticket_stubs: ticket.stubs,
      archived: ticket.archived,
      terms: ticket.terms,
      client_id: ticket.client_id,
      valid_facilities: tempValidFacilityIds,
    }));

    // terms: Array<string>; //
    // archived: boolean; //
    // client_id: number;
    // stubs: Array<TicketStubType>; //
  }

  async function saveTicket() {
    console.log("Save Ticket State", ticketState);
    const ticketRes = await PutTicket(ticketState, true);
    if (ticketRes.status !== StatusCode.OK) {
      dispatch(showError("Error saving ticket"));
      return;
    }
    dispatch(showSuccess("Ticket saved successfully"));
    history.push("/admin/tickets");
  }

  function handleDropDownChange(value: any, property: string) {
    setTicketState(prev => ({ ...prev, [property]: value }));
  }

  const handleDateSelectorChange = (selectedDate: Date) => {
    const tempDate = new Date(selectedDate).toISOString().slice(0, 10);

    setTicketState(prevState => ({ ...prevState, expiry_date: tempDate, selectedDate: selectedDate }));
  };

  async function addRedemptionProduct() {
    let variants: Array<IVariant> = [];
    selectedProducts?.forEach(product => {
      variants = [...variants, ...product?.variants?.map(variant => variant)];
    });
    const formattedVariants = variants?.map(variant => ({ variant_id: variant?.id, quantity: variant?.quantity }));
    const params = {
      ticket_id: ticketId,
      variants: formattedVariants,
    };

    const redemptionRes = await AddRedemptionProducts(params, true);
    if (redemptionRes.status !== StatusCode.OK) {
      return;
    }

    const ticketParams = {
      id: ticketId,
      extended: true,
    };

    const loadTicketRes = await GetTickets(ticketParams, true);

    if (loadTicketRes.status !== StatusCode.OK) {
      return;
    }

    dispatch(showSuccess("Redemption products added successfully"));

    ReactDOM.unstable_batchedUpdates(() => {
      setTicketState(prevState => ({
        ...prevState,
        product_redemption: loadTicketRes.data[0].variant_redemption,
      }));

      setTicketStateBeforeChanges(prevState => ({
        ...prevState,
        product_redemption: loadTicketRes.data[0].variant_redemption,
      }));
      setRedemptionProductModalOpen(false);
      setSelectedProducts([]);
    });
  }

  async function removeRedemptionProduct(newProduct: any) {
    const params = {
      ticket_id: ticketId,
      variant_id: newProduct.id,
      quantity: 1,
    };

    const redemptionRes = await RemoveRedemptionProduct(params, true);

    if (redemptionRes.status !== StatusCode.OK) {
      return;
    }

    const ticketParams = {
      id: ticketId,
      extended: true,
    };

    const loadTicketRes = await GetTickets(ticketParams, true);

    if (loadTicketRes.status !== StatusCode.OK) {
      return;
    }

    setTicketState(prevState => ({
      ...prevState,
      product_redemption: loadTicketRes.data[0].variant_redemption,
    }));

    setTicketStateBeforeChanges(prevState => ({
      ...prevState,
      product_redemption: loadTicketRes.data[0].variant_redemption,
    }));
  }

  async function deleteTicketImage() {
    const deleteRes = await DeleteTicketImage({ id: ticketId }, true);
    if (deleteRes.status !== StatusCode.OK) {
      console.log("Error deleting", deleteRes);
      return;
    }

    const ticket = deleteRes.data;

    setTicketState(prevState => ({
      ...prevState,
      id: ticket.id,
      title: ticket.title,
      included: ticket.included,
      description: ticket.description,
      value: ticket.value,
      expiry_date: ticket.expiry_date,
      expiry_days: ticket.expiry_days,
      expiry_type: ticket.expiry_type,
      logo_image: ticket.logo_image,
    }));

    setTicketStateBeforeChanges(prevState => ({
      ...prevState,
      id: ticket.id,
      title: ticket.title,
      included: ticket.included,
      description: ticket.description,
      value: ticket.value,
      expiry_date: ticket.expiry_date,
      expiry_days: ticket.expiry_days,
      expiry_type: ticket.expiry_type,
      logo_image: ticket.logo_image,
    }));
  }

  function imageUploadOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files;
    if (file && file.length > 0) {
      //Upload image after the file is selected
      void uploadTicketImage(file[0]);
    }
  }

  async function uploadTicketImage(imageFile: File) {
    const formData = new FormData();

    formData.append("logo_image", imageFile);
    formData.append("id", ticketId);

    //Reset file selector input value
    imageUploadInputRef.current.value = "";

    const imageRes = await PostTicketImage(formData, true);
    if (imageRes.status !== StatusCode.OK) {
      return;
    }

    const ticket = imageRes.data;

    setTicketState(prevState => ({
      ...prevState,
      id: ticket.id,
      title: ticket.title,
      included: ticket.included,
      description: ticket.description,
      value: ticket.value,
      expiry_date: ticket.expiry_date,
      expiry_days: ticket.expiry_days,
      expiry_type: ticket.expiry_type,
      logo_image: ticket.logo_image,
    }));

    setTicketStateBeforeChanges(prevState => ({
      ...prevState,
      id: ticket.id,
      title: ticket.title,
      included: ticket.included,
      description: ticket.description,
      value: ticket.value,
      expiry_date: ticket.expiry_date,
      expiry_days: ticket.expiry_days,
      expiry_type: ticket.expiry_type,
      logo_image: ticket.logo_image,
    }));
  }

  function unsavedChangesExist() {
    if (ticketStateBeforeChanges === undefined) {
      if (ticketLoaded) {
        setTicketStateBeforeChanges(ticketState);
      }
      return false;
    }

    return !isEqualWith(ticketStateBeforeChanges, ticketState, (originalValue, newValue) => {
      if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
        return true;
      }
    });
  }

  function cancelUnsavedChanges() {
    setTicketState(ticketStateBeforeChanges);
  }

  const disableAddRedemptionProducts = selectedProducts?.every(product =>
    product?.variants?.some(variant => variant?.quantity),
  );

  return (
    <Page
      title={ticketState.title}
      narrow
      breadcrumbs={[{ label: "Back to Tickets", url: "/admin/tickets" }]}
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: saveTicket,
        onCancel: cancelUnsavedChanges,
      }}
    >
      <Card>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Input
                value={ticketState.title}
                label={t("secure.facility.product.ticket_edit.002")}
                id="title"
                onChange={handleInputChange}
                placeholder={t("secure.facility.product.ticket_edit.003")}
              />
              <Input
                value={ticketState.subtitle}
                label={t("secure.facility.product.ticket_edit.030")}
                id="subtitle"
                onChange={handleInputChange}
                placeholder={t("secure.facility.product.ticket_edit.031")}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField
                value={ticketState.included || ""}
                label={t("secure.facility.product.ticket_edit.004")}
                id="included"
                onChange={handleInputChange}
                placeholder={t("secure.facility.product.ticket_edit.005")}
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
        <Card.Section>
          <TextEditor
            label="Description"
            markdownText={ticketState.description ?? ""}
            markdownTextOnChange={handleDescriptionChange}
          />
        </Card.Section>
      </Card>

      <Card title={t("secure.facility.product.ticket_edit.010")}>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <div className="customer-image_grid">
                {ticketState.logo_image !== null || "" || undefined ? (
                  <div>
                    <div className="product-image_container">
                      <div className="product-image">
                        <div className="remove-button_container">
                          <div className="remove-button" onClick={() => deleteTicketImage()}>
                            <FontAwesomeIcon icon={"times"} />
                          </div>
                        </div>
                        <img src={ticketState.logo_image} alt="profile image" />
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="product-image-upload_container">
                      <div className="product-image-upload_content">
                        <input
                          ref={imageUploadInputRef}
                          className="product-image-upload_input"
                          type="file"
                          accept="image/*"
                          onChange={imageUploadOnChange}
                        />
                        <p>
                          <FontAwesomeIcon icon={"upload"} />
                        </p>
                      </div>
                    </div>
                    <div className="product-image-upload_content"></div>
                  </>
                )}
              </div>
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>

      <Card title={t("secure.facility.product.ticket_edit.011")}>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Select
                label={t("secure.facility.product.ticket_edit.012")}
                onChange={(value: any) => handleDropDownChange(value, "expiry_type")}
                defaultValue={ticketState.expiry_type}
              >
                {optionState.expiryTypes.map((option: any, index: number) => {
                  return (
                    <Option key={index} value={option.value} name={option.label}>
                      {option.label}
                    </Option>
                  );
                })}
              </Select>

              <div>
                {ticketState.expiry_type === "date" ? (
                  // <DateSelector onChange={handleDateSelectorChange} hideInput selectedDate={ticketState.selectedDate} />

                  <DatePickerInput
                    label={"Date"}
                    months={1}
                    position="left"
                    startingDate={ticketState.selectedDate}
                    setStartingDate={handleDateSelectorChange}
                  />
                ) : null}

                {ticketState.expiry_type === "days" ? (
                  <Input
                    label={t("secure.facility.product.ticket_edit.013")}
                    id="expiry_days"
                    value={ticketState.expiry_days}
                    onChange={handleInputChange}
                    placeholder={t("secure.facility.product.ticket_edit.014")}
                  />
                ) : null}
              </div>
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>

        <Card.Section title={t("secure.facility.product.ticket_edit.015")}>
          <FormLayout>
            <FormLayout.Group>
              <Input
                value={ticketState.value}
                label={t("secure.facility.product.ticket_edit.016")}
                id="value"
                onChange={handleInputChange}
                placeholder={t("secure.facility.product.ticket_edit.017")}
                type="number"
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>

        <Card.Section
          title={t("secure.facility.product.ticket_edit.018")}
          subtitle={t("secure.facility.product.ticket_edit.019")}
        >
          <FormLayout>
            {ticketState.facility_ids_list.map((facility: any, index: number) => {
              return (
                <FormLayout.Group key={index}>
                  <Checkbox
                    id={facility.id}
                    size="small"
                    checked={ticketState?.valid_facilities?.some(facilityId => facilityId === facility?.id)}
                    value={facility.value}
                    onChange={() => changeFacilityList(facility)}
                    label={facility.label}
                  />
                </FormLayout.Group>
              );
            })}
          </FormLayout>
        </Card.Section>

        <Card.Section title={t("secure.facility.product.ticket_edit.020")}>
          <FormLayout>
            <FormLayout.Group>
              <div>
                <div style={{ display: "flex", alignItems: "center", gap: "16px", paddingBottom: "8px" }}>
                  <h1>{t("secure.facility.product.ticket_edit.028")}</h1>
                  <Button size="small" onClick={() => setRedemptionProductModalOpen(true)}>
                    Add Redemption Product
                  </Button>
                </div>

                {ticketState.product_redemption ? (
                  <div className="redemption-wrapper">
                    {ticketState.product_redemption.map((product: any, index: number) => {
                      let productTitle: string;

                      if (product.title === product.product.title) {
                        productTitle = String(product.title);
                      } else {
                        productTitle = String(product.product.title) + " - " + String(product.title);
                      }

                      return (
                        <div key={index} className="redemption-container">
                          <div className="redemption-product-title">{productTitle}</div>
                          <div className="font-normal">x{product.redemption_details.quantity}</div>
                          <div className="redemption-product-button">
                            <button className="redemption-button-back" onClick={() => removeRedemptionProduct(product)}>
                              {t("secure.facility.product.ticket_edit.029")}
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            </FormLayout.Group>
          </FormLayout>

          {/* Redemption Modal */}
          <Portal isMounted={redemptionProductModalOpen}>
            <div className="pricing-sheets-products-modal">
              <Sheet
                closable
                onCancel={() =>
                  ReactDOM.unstable_batchedUpdates(() => {
                    setRedemptionProductModalOpen(false);
                    setSelectedProducts([]);
                  })
                }
                size="medium"
                open={redemptionProductModalOpen}
                cancelText="Close"
                okText="Add"
                title="Add Redemption Products"
                okDisabled={selectedProducts?.length === 0 || !disableAddRedemptionProducts}
                onOk={addRedemptionProduct}
              >
                <ProductSelect
                  selectedProducts={selectedProducts}
                  search
                  onChange={products => setSelectedProducts(products)}
                  getProductsParams={{ extended_variants: true }}
                  variantInput={{ key: "quantity", placeholder: "Quantity" }}
                  limit={50}
                />
              </Sheet>
            </div>
          </Portal>
        </Card.Section>
      </Card>
    </Page>
  );
};

export default Single;
