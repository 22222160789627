import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { formatDateFromString } from "helpers/Helpers";
import useOutsideAlerter from "hooks/useOutsideAlerter/useOutsideAlerter";

import Panel from "components/panel/Panel";

import "./facilityStatusInfo.scss";

type TFacilityStatusProps = {
  isOpen: boolean;
  close: () => void;
  dateString: string; // YYYY-MM-DD
  gridRefElement?: HTMLDivElement;
  /** Determines where placement is from top of page. */
  iFrame: boolean;
};

export default function FacilityStatusInfo(props: TFacilityStatusProps & { children: any }) {
  const { isOpen, close, dateString, gridRefElement, iFrame } = props;
  const popupRef = useRef<HTMLDivElement>(null);

  useOutsideAlerter(popupRef, () => {
    close();
  });

  /* Display information in sheet rather than absolute position */
  if (!gridRefElement) {
    return (
      <Panel
        open={isOpen}
        onClose={close}
        title="Daily Updates" // TODO: Translation
        subtitle={dateString ? formatDateFromString(dateString) : undefined}
        animate={0.5}
        pagePercent={70}
      >
        {props.children}
      </Panel>
    );
  }

  return (
    <div
      ref={popupRef}
      className={`facility-status-info-popup${!isOpen ? " closed" : ""}`}
      style={{
        top: iFrame
          ? gridRefElement.offsetTop + 300 > window.screen.height
            ? gridRefElement.offsetTop - 300
            : gridRefElement.offsetTop
          : gridRefElement.offsetTop + 213 > window.screen.height
          ? gridRefElement.offsetTop - 153
          : gridRefElement.offsetTop + 213,
        left: calculateLeftPositioning(
          gridRefElement.getBoundingClientRect().left,
          gridRefElement.getBoundingClientRect().width,
          375,
        ),
      }}
    >
      <div className="popup-wrap">
        <div className="popup-header">
          <span className="calendar-icon-wrap">
            <FontAwesomeIcon icon={["far", "calendar"]} className="calendar-icon" />
          </span>
          <FontAwesomeIcon icon={["far", "x"]} size="1x" className="close-action" onClick={() => close()} />
        </div>

        <div className="popup-subheader">
          <p className="popup-heading">Daily Updates</p>
          <p className="popup-date-heading">
            {dateString ? formatDateFromString(dateString) : "Monday, Aug 12th, 2024"}
          </p>
        </div>

        <div className="popup-content">{props.children}</div>
      </div>
    </div>
  );
}

/** If left positioning is negative, calculate to be right-positioned instead */
export function calculateLeftPositioning(gridLeftPos = 0, gridWidth = 0, modalWidth = 0) {
  let leftPos = gridLeftPos - modalWidth - 10;

  if (leftPos < 0) {
    leftPos = gridLeftPos + gridWidth + 10;
  }

  return leftPos;
}
