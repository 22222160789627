import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { DeleteLeagueTeam, GetLeagueTeams, PutLeagueTeams } from "api/rpc/2024-04/facilityAdmin/league/scoring/teams";

import { showError, showSuccess } from "redux/actions/ui";
import { TLeagueTeam } from "redux/reducers/models/league";
import { useAppDispatch } from "hooks/redux";
import useModal from "hooks/modals/useModal";
import { unsavedChangesExist } from "helpers/Helpers";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import Popup from "components/popup/Popup";
import Portal from "elements/Portal";

export default function LeagueTeamsEdit() {
  const history = useHistory();
  const { t } = useTranslation();
  const { leagueId, divisionId, teamId } = useParams<{ leagueId: string; divisionId: string; teamId: string }>();

  const dispatch = useAppDispatch();

  const { state: deleteModal, updateModal, closeModal } = useModal();

  const [teamState, setTeamState] = useState<Partial<TLeagueTeam>>({ name: "" });

  // unsavedChanges bar toggle
  const [preserved, setPreserved] = useState<Partial<TLeagueTeam>>({ name: "" });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadLeagueTeams(divisionId, source.token);
    return () => source.cancel();
  }, [divisionId]);

  async function loadLeagueTeams(leagueDivisionId: string | number, token?: CancelToken) {
    const teamRes = await GetLeagueTeams({ league_division_id: leagueDivisionId, id: teamId }, true, token);
    if (token && token.reason) {
      return;
    }

    if (teamRes.status !== StatusCode.OK) {
      dispatch(showError("Error getting team")); // TODO: Translation
      history.push(`/admin/league/${leagueId}/divisions/${divisionId}/teams`);
      return;
    }

    setTeamState(prevState => ({ ...prevState, name: teamRes.data[0]?.name }));
    setPreserved(prev => ({ ...prev, name: teamRes.data[0]?.name }));
  }

  async function updateTeam() {
    const updateRes = await PutLeagueTeams(
      { id: Number(teamId), league_division_id: divisionId, name: teamState.name },
      true,
    );
    if (updateRes.status !== StatusCode.OK) {
      dispatch(showError("Error updating team")); // TODO: Translation
      return;
    }
    dispatch(showSuccess("Succesfully updated team")); // TODO: Translation
    history.push(`/admin/league/${leagueId}/divisions/${divisionId}/teams`);
  }

  async function deleteTeam() {
    const res = await DeleteLeagueTeam({ id: teamId }, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error removing team from division")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Succesfully removed team from division")); // TODO: Translation
    history.push(`/admin/league/${leagueId}/divisions/${divisionId}/teams`);
  }

  return (
    <Page
      title={"Edit Team"} // TODO: Translation
      narrow
      multipleActionDropdownAction={{
        label: "Options", // TODO: Translation
        dropdownProps: {
          alignment: "right",
          options: [
            {
              type: "handler",
              label: "Delete", // TODO: Translation
              handler: () => updateModal({ isOpen: true }),
              // icon: "print",
            },
            {
              type: "handler",
              label: "Manage Players", // TODO: Translation
              handler: () => history.push(`/admin/league/${leagueId}/divisions/${divisionId}/teams/manage_players`),
              // icon: "file-arrow-down",
            },
          ],
        },
      }}
      notificationBarProps={{
        isVisible: unsavedChangesExist(teamState, preserved),
        onAction: () => updateTeam(),
        onCancel: () => setTeamState(preserved),
      }}
      breadcrumbs={[
        {
          prefix: true,
          label: "Teams", // TODO: Translation
          url: `/admin/league/${leagueId}/divisions/${divisionId}/teams`,
        },
      ]}
    >
      <Form>
        {/* TODO: Translation */}
        <Card title={"Team Details"}>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={teamState.name ?? ""}
                  label={t("secure.facility.league.new.012")}
                  id="name"
                  onChange={e => setTeamState(prevState => ({ ...prevState, name: e.target.value }))}
                  placeholder={t("secure.facility.league.new.012")}
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>
      </Form>

      <Portal isMounted={deleteModal.isOpen}>
        <Popup
          open={deleteModal.isOpen}
          type="warning"
          title="Delete Team?" // TODO: Translation
          description="Are you sure you want to delete this team from the division?" // TODO:Translation
          onCancel={closeModal}
          onOk={() => deleteTeam()}
          okText="Delete" // TODO: Translation
        />
      </Portal>
    </Page>
  );
}
