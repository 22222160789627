export interface IUserPermissions {
  accounts_charge_all: boolean;
  accounts_import: boolean;
  accounts_post_adjustment: boolean;
  admin_accounts_disable: boolean;
  admin_accounts_edit: boolean;
  admin_permissions_edit: boolean;

  bookings_edit_note: boolean;

  cash_outs_create: boolean;
  cash_outs_edit: boolean;
  cash_outs_view_summary: boolean;
  courses_create: boolean;
  created_at: string;
  customers_edit_note: boolean;

  deleted_at: string;
  discounts_create: boolean;
  divisions_create: boolean;

  gift_cards_import: boolean;
  gift_cards_view: boolean;

  id: number;

  departments_create: boolean;
  departments_edit: boolean;

  memberships_apply_manual: boolean;
  merge_customers: boolean;

  orders_apply_discount: boolean;
  orders_backdate: boolean;
  orders_edit_line_item_price: boolean;
  orders_edit_register: boolean;
  orders_edit_user: boolean;
  orders_refund: boolean;
  orders_refund_tip: boolean;
  orders_remove_line_item: boolean;
  orders_remove_line_item_after_send: boolean;
  orders_void: boolean;
  orders_void_line_item: boolean;

  payment_options_create: boolean;
  products_archive: boolean;
  products_create: boolean;
  products_edit: boolean;
  products_edit_cost: boolean;
  products_edit_price: boolean;

  reports_access: boolean;
  tee_sheet_credit_card_override: boolean;
  tee_sheet_charge_no_show: boolean;
  table_service_table_override: boolean;
  reservations_credit_card_override: boolean;
  user_id: number;
  updated_at: string;

  user_edit_customer_type: boolean;

  bank_accounts_create: boolean;
  booking_category_create: boolean;

  tee_on_payments_view_persons: boolean;

  reports_facility_transactions: boolean;
  reports_facility_scheduled_payments: boolean;
  reports_facility_detailed_sales_by_department: boolean;
  reports_facility_sales_by_accounting_reference: boolean;
  reports_facility_inventory_listing_by_department: boolean;
  reports_facility_order_exceptions: boolean;
  reports_facility_golfnorth_daily_sales: boolean;
  reports_facility_ticket_overview: boolean;
  reports_facility_aged_accounts_receivable: boolean;
  reports_facility_inventory_adjustments: boolean;
  reports_facility_gift_card_activity: boolean;
  reports_facility_tickets_redeemed: boolean;
  reports_facility_tickets: boolean;
  reports_facility_tickets_expired: boolean;
  reports_facility_rounds_by_customer_type: boolean;
  reports_facility_membership_sales: boolean;
  reports_facility_player_spend: boolean;
  reports_facility_sales_by_department: boolean;
  reports_facility_tee_sheet_utilization: boolean;
  reports_facility_email_activity: boolean;
  reports_facility_tee_sheet_no_show: boolean;

  reports_client_scheduled_payments: boolean;
  reports_client_account_adjustments: boolean;
  reports_client_detailed_sales_by_department: boolean;
  reports_client_aged_accounts_receivable: boolean;
  reports_client_membership_sales: boolean;
  reports_client_order_exceptions: boolean;
  reports_client_tee_sheet_utilization: boolean;
}

export interface IUser {
  birth_date: null | any;
  client_id: number;
  customer_code: string;
  email: string;
  facility_id: number;
  first_name: string;
  full_name: string;
  id: number;
  last_name: string;
  member_number: null | number;
  password_reset_code: null | any;
  permissions: IUserPermissions;
  phone: null | any;
  primary_locale: string;
  primary_locale_country: string;
  state: null | any;
  stripe_id: null | number;
  user_type: UserType;
  verification_code: null | any;
  profile_image_source: string;
  profile_image_source_path: string;

  // id: number;
  // client_id: number;
  // email: string;
  // facility_id: number;
  // first_name: string;
  // full_name: string;
  // last_name: string;
  // user_type: UserType;
  // primary_locale: string;
  // primary_locale_country: string;
  // permission: {
  //   level: AdminPermission;
  //   name: string;
  //   type: "permission";
  // };
}

export type IUserActive = Omit<IUser, "verification_code">;

export enum UserType {
  STANDARD_CUSTOMER = 1,
  CLIENT_ADMIN = 2,
  FACILITY_ADMIN = 3,
  MASTER_ADMIN = 4,
}

export enum AdminPermission {
  FACILITY_ADMIN = "facility_admin",
  CLIENT_ADMIN = "client_admin",
  MASTER_ADMIN = "master_admin",
}
