import React, { useEffect, useState, useCallback } from "react";
import { useHistory, Link } from "react-router-dom";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";

import {
  GetCardReaders,
  PutCardReader,
  DeleteCardReader,
} from "api/rpc/2024-04/facilityAdmin/payment/teeonPayments/reader";
import { StatusCode } from "api/protocols";

import { IRegisterGroup } from "../../Admin";
import { IUIActions, showError, showSuccess } from "redux/actions/ui";

import Page from "components/page/Page";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Form from "components/form/Form";
import Input from "components/form/input/Input";
import { Select } from "components/select/index";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { removeReader } from "redux/actions/facilityAdmin/terminal";
import Popup from "components/popup/Popup";
import { isEqualWith } from "lodash";

interface IProps {
  uiActions: IUIActions;
}

interface IParams {
  id: string;
}

export default function CardReader(props: IProps) {
  const { uiActions } = props;
  const { Option } = Select;

  const dispatch = useAppDispatch();
  const { terminalStore } = useAppSelector(store => store);

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { id } = useParams<IParams>();
  const [state, setState] = useState({
    id: null,
    label: "",
    serial_number: "",
  });
  const [showWarningState, setShowWarningState] = useState<boolean>(false);
  useEffect(() => {
    void loadCardReaders();
  }, []);

  const [cardReaderBeforeChanges, setCardReaderBeforeChanges] = useState(undefined);
  const [cardReaderLoaded, setCardReaderLoaded] = useState<boolean>(false);

  async function loadCardReaders() {
    const res = await GetCardReaders({ id: Number(id) }, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.card_reader.card_reader.002")));
      history.push("/admin/settings/card_reader");
      return;
    }

    const cardReader = res.data[0];

    setState(prevState => ({
      ...prevState,
      id: cardReader.id,
      label: cardReader.label,
      serial_number: cardReader.serial_number,
    }));

    setCardReaderLoaded(true);
  }

  async function saveCardReader() {
    const res = await PutCardReader(
      {
        id: state.id,
        label: state.label,
      },
      true,
    );

    if (res.status === StatusCode.OK) {
      dispatch(showSuccess(t("secure.facility.settings.card_reader.card_reader.003")));
      history.push("/admin/settings/card_reader");
    } else {
      dispatch(showError(t("secure.facility.settings.card_reader.card_reader.004")));
    }
  }

  async function deleteCardReader() {
    const res = await DeleteCardReader(
      {
        id: state.id,
      },
      true,
    );

    if (res.status === StatusCode.OK) {
      dispatch(showSuccess("Reader deleted successfully")); // TODO: Translation

      console.log("terminal Store", terminalStore);

      // If the reader is currently selected, remove it
      if (terminalStore.reader?.serial_number === state.serial_number) {
        dispatch(removeReader(true));
      }

      history.push("/admin/settings/card_reader");
    } else {
      dispatch(showError("Error ocurred when deleting card reader")); // TODO: Translation
    }
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleDelete() {
    setShowWarningState(true);
  }
  const primaryAction = {
    content: t("secure.facility.settings.card_reader.card_reader.001"),
    action: saveCardReader,
  };

  const secondaryAction = [
    {
      content: "Delete",
      action: handleDelete,
    },
  ];

  function unsavedChangesExist() {
    if (cardReaderBeforeChanges === undefined) {
      if (cardReaderLoaded) {
        setCardReaderBeforeChanges(state);
      }
      return false;
    }

    return !isEqualWith(cardReaderBeforeChanges, state);
  }

  function cancelUnsavedChanges() {
    setState(cardReaderBeforeChanges);
  }

  return (
    <Page
      title={state.label}
      subtitle={state.serial_number}
      narrow
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.card_reader.card_reader.005"),
          url: "/admin/settings/card_reader",
        },
      ]}
      // primaryAction={primaryAction}
      secondaryActions={secondaryAction}
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: saveCardReader,
        onCancel: cancelUnsavedChanges,
      }}
    >
      <Card>
        <Card.Section>
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={state.label}
                  label={t("secure.facility.settings.card_reader.card_reader.006")}
                  id="label"
                  onChange={handleInputChange}
                  placeholder={t("secure.facility.settings.card_reader.card_reader.007")}
                />
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
      </Card>
      <Popup
        open={showWarningState}
        type={"warning"}
        stacked
        title={"Delete Card Reader?"} // TODO: Translation
        onCancel={() => setShowWarningState(false)}
        cancelText={"Cancel"} // TODO: Translation
        okText={"Delete"} // TODO: Translation
        onOk={deleteCardReader}
        description={"Deleting this card reader will make it unusable for this facility, continue?"} // TODO: Translation
      />
    </Page>
  );
}
