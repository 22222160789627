import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { StatusCode } from "api/protocols";
import {
  GetAnnouncement,
  PutAnnouncement,
  PostAnnouncement,
  DeleteAnnouncement,
  PutResendAnnouncement,
  TLeagueAnnouncement,
} from "api/rpc/2024-04/facilityAdmin/league/announcement";
import { GetEmailTemplate, PostEmailTemplate } from "api/rpc/2024-04/facilityAdmin/facility/emailTemplate";
import { TEmailTemplate } from "pages/secure/facility/settings/facility/emailTemplates/EmailTemplates";

import { showError, showSuccess } from "redux/actions/ui";

import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import Toggle from "components/form/toggle/Toggle";
import Sheet from "components/sheet/Sheet";
import TextEditor from "components/textEditor/textEditor";
import { ButtonNew as Button } from "components/buttonNew";
import Popup from "components/popup/Popup";
import Markdown from "components/markdown/Markdown";
import { Select } from "components/select";

interface IModalState {
  editAnnouncementVisible: boolean;
  newAnnouncementVisible: boolean;
  deleteModalVisibile: boolean;
}

/** Type used across New | Edit modals.  Resets each time modal closes. */
type EditAnnouncementType = {
  sendEmail: boolean;
  subject: string;
  content: string;
  id: number | null;
};

export default function LeagueAnnouncements() {
  const { t, i18n } = useTranslation();
  const { leagueId } = useParams<{ leagueId: string }>();
  const dispatch = useAppDispatch();

  const [announcements, setAnnouncements] = useState<TLeagueAnnouncement[]>([]);
  const [templates, setTemplates] = useState<TEmailTemplate[]>(undefined);

  const [modalState, setModalState] = useState<IModalState>({
    editAnnouncementVisible: false,
    newAnnouncementVisible: false,
    deleteModalVisibile: false,
  });

  const [editState, setEditState] = useState<EditAnnouncementType>({
    sendEmail: false,
    subject: "",
    content: "",
    id: null,
  });

  useEffect(() => {
    void loadAnnouncements(leagueId); // populate on first load.
  }, [leagueId]);

  /** Passing in an announcement_id returns 1 Announcement. */
  async function loadAnnouncements(league_id: string, announcement_id?: string | number) {
    const announcementRes = await GetAnnouncement(
      {
        league_id: league_id,
        id: announcement_id,
      },
      true,
    );

    if (announcementRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading League Announcements."));
      return;
    }

    if (announcement_id) {
      setEditState(prev => ({
        ...prev,
        subject: announcementRes.data[0].subject,
        content: announcementRes.data[0].content,
      }));
    } else {
      setAnnouncements(announcementRes.data); //set all announcements
    }

    // Laod templates if they haven't already been loaded
    if (!templates) {
      const templateRes = await GetEmailTemplate(
        {
          type: "league_announcement",
        },
        true,
      );

      if (templateRes.status === StatusCode.OK) {
        setTemplates(templateRes.data);
      }
    }
  }

  async function newAnnouncement() {
    const announcementRes = await PostAnnouncement(
      {
        league_id: leagueId,
        subject: editState.subject,
        send_email: editState.sendEmail,
        content: editState.content,
      },
      true,
    );

    if (announcementRes.status !== StatusCode.OK) {
      dispatch(showError(announcementRes.data));
      return;
    }

    handleModalClear();
    setModalState(prev => ({ ...prev, newAnnouncementVisible: false }));

    dispatch(showSuccess("Announcement Created Successfully.", 2000));
    void loadAnnouncements(leagueId);
  }

  async function updateAnnouncement() {
    const announcementRes = await PutAnnouncement(
      {
        id: editState.id,
        subject: editState.subject,
        send_email: editState.sendEmail,
        content: editState.content,
      },
      true,
    );

    if (announcementRes.status !== StatusCode.OK) {
      return;
    }

    handleModalClear();
    setModalState(prev => ({ ...prev, editAnnouncementVisible: false }));

    dispatch(showSuccess("Announcement Updated Successfully.", 2000));
    void loadAnnouncements(leagueId);
  }

  async function resendEmail(id: number) {
    const resendRes = await PutResendAnnouncement(
      {
        id: id,
      },
      true,
    );

    if (resendRes.status !== StatusCode.OK) {
      dispatch(showError("Announcement could not be resent.", 2000));
      return;
    }

    dispatch(showSuccess("Announcement Resent Successfully.", 2000));
  }

  async function deleteAnnouncement() {
    const announcementRes = await DeleteAnnouncement({ announcement_id: editState.id }, true);

    if (announcementRes.status !== StatusCode.OK) {
      dispatch(showError("Error Deleting Announcement")); // TODO: Translation
      return;
    }

    handleModalClear();
    setModalState(prev => ({ ...prev, editAnnouncementVisible: false, deleteModalVisibile: false }));

    dispatch(showSuccess("Announcement Deleted Successfully")); // TODO: Translation
    void loadAnnouncements(leagueId);
  }

  async function saveAsTemplate() {
    const templateRes = await PostEmailTemplate(
      {
        title: editState.subject,
        subject: editState.subject,
        type: "league_announcement",
        content: editState.content,
      },
      true,
    );

    if (templateRes.status !== StatusCode.OK) {
      dispatch(showError("Error Saving Template")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Template Saved Successfully")); // TODO: Translation
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setEditState(prev => ({ ...prev, [id]: value }));
  }

  function handleShowEditAnnouncement(announcement: TLeagueAnnouncement) {
    setModalState(prev => ({ ...prev, editAnnouncementVisible: true }));
    setEditState(prev => ({
      ...prev,
      id: announcement.id,
      content: announcement.content,
      subject: announcement.subject,
    }));
  }

  function handleCheckboxChange(event: any) {
    const { id, checked } = event.target;
    setEditState(prev => ({ ...prev, [id]: checked }));
  }

  function handleModalClear() {
    setEditState({
      sendEmail: false,
      content: "",
      subject: "",
      id: null,
    });
  }

  function handleTemplateChange(template: TEmailTemplate) {
    setEditState(prevState => ({
      ...prevState,
      content: template?.content,
      subject: template?.subject,
    }));
  }

  const primaryAction = {
    content: "New Announcement",
    action: () => setModalState(prev => ({ ...prev, newAnnouncementVisible: true })),
  };

  return (
    <Page title={"Announcements"} narrow primaryAction={primaryAction}>
      {announcements.map((announcement, index: number) => {
        return (
          <Card key={index}>
            <Card.Section>
              <FormLayout>
                {/* Mimic FormLayout.Group styling, but less padding + pushing content over */}
                <div style={{ display: "inline-flex", width: "100%" }}>
                  <div style={{ flex: "1", marginLeft: "2rem" }}>
                    <label className="text-semibold text-xl">{announcement.subject}</label>
                  </div>
                  <div style={{ flex: "1", marginLeft: "2rem", display: "flex", justifyContent: "end" }}>
                    <Button type="text" size="small" onClick={() => resendEmail(announcement.id)}>
                      Resend
                    </Button>
                    <Button type="text" size="small" onClick={() => handleShowEditAnnouncement(announcement)}>
                      Edit
                    </Button>
                  </div>
                </div>
                <FormLayout.Group>
                  <p className="text-xs">
                    {"Date Posted: " + moment.utc(announcement.created_at).local().format("MMM DD, YYYY")}
                  </p>
                  <p className="text-xs">Author: {announcement.author.full_name}</p>
                </FormLayout.Group>
                <FormLayout.Group>
                  <Markdown markdownText={announcement.content} />
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>
          </Card>
        );
      })}

      {modalState.newAnnouncementVisible && (
        <Sheet
          size="medium"
          open={true}
          okText="Create"
          title="New Announcement"
          onCancel={() => {
            handleModalClear();
            setModalState(prev => ({ ...prev, newAnnouncementVisible: false }));
          }}
          onOk={newAnnouncement}
          closable
          secondaryText="Save As Template"
          onSecondary={saveAsTemplate}
          backDropCancel={false}
        >
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input value={editState.subject} label={"Subject"} id="subject" onChange={handleInputChange} />
                <Toggle
                  labelRight={"Send Email"} // TODO: Translation
                  id={"sendEmail"}
                  onChange={handleCheckboxChange}
                  checked={editState.sendEmail}
                />
                <Select
                  defaultValue={-1}
                  label="Template"
                  onChange={(value, extraValues) => handleTemplateChange(extraValues)}
                >
                  <Select.Option
                    key={-1}
                    value={-1}
                    extraValues={{
                      id: -1,
                      content: "",
                      subject: "",
                    }}
                  >
                    {"None" /* TODO: Translation */}
                  </Select.Option>
                  {templates.map(template => (
                    <Select.Option key={template.id} value={template.id} extraValues={template}>
                      {template.title}
                    </Select.Option>
                  ))}
                </Select>
              </FormLayout.Group>
              <FormLayout.Group>
                <TextEditor
                  markdownText={editState.content ?? ""}
                  markdownTextOnChange={(newContent: string) =>
                    setEditState(prev => ({ ...prev, content: newContent }))
                  }
                  label={"Content"}
                />
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Sheet>
      )}

      {modalState.editAnnouncementVisible && (
        <Sheet
          size="medium"
          open={true}
          okText="Update"
          title="Edit Announcement"
          onCancel={() => {
            handleModalClear();
            setModalState(prev => ({ ...prev, editAnnouncementVisible: false }));
          }}
          onOk={updateAnnouncement}
          closable
          secondaryText="Save As Template"
          onSecondary={saveAsTemplate}
          backDropCancel={false}
        >
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input value={editState.subject} label={"Subject"} id="subject" onChange={handleInputChange} />
              </FormLayout.Group>
              <FormLayout.Group>
                <Toggle
                  labelRight={"Resend Email"} // TODO: Translation
                  id={"sendEmail"}
                  onChange={handleCheckboxChange}
                  checked={editState.sendEmail}
                />
                <Button
                  type="primary"
                  size="medium"
                  onClick={() => setModalState(prevState => ({ ...prevState, deleteModalVisibile: true }))}
                >
                  Delete Announcement
                </Button>
              </FormLayout.Group>
              <FormLayout.Group>
                <TextEditor
                  markdownText={editState.content ?? ""}
                  markdownTextOnChange={(newContent: string) =>
                    setEditState(prev => ({ ...prev, content: newContent }))
                  }
                  label={"Content"}
                />
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Sheet>
      )}

      <Popup
        open={modalState.deleteModalVisibile}
        type="warning"
        title={"Delete Announcement?"}
        description={"Are you sure you want to delete this announcement?"}
        onCancel={() => setModalState(prevState => ({ ...prevState, deleteModalVisibile: false }))}
        onOk={deleteAnnouncement}
      />
    </Page>
  );
}
