import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios, { CancelToken } from "axios";
import classNames from "classnames";
import useModal from "hooks/modals/useModal";

import { StatusCode } from "api/protocols";
import {
  GetLeagueRound,
  GetLeagueRoundHoleScores,
  PrintRounds,
} from "api/rpc/2024-04/facilityAdmin/league/scoring/round";
import { GetLeagueCompetitionFormat } from "api/rpc/2024-04/facilityAdmin/league/scoring/format";
import { GetLeagueTeamScores } from "api/rpc/2024-04/facilityAdmin/league/scoring/teams";
import { GetScoringSettings } from "api/rpc/2022-09/facilityAdmin/league/scoring/settings";
import { GetLeagueFlights } from "api/rpc/2024-04/facilityAdmin/league/scoring/flight";
import { GetLeagueDivision } from "api/rpc/2024-04/facilityAdmin/league/scoring/division";

import { showError } from "redux/actions/ui";
import {
  TLeagueFlight,
  TLeagueRoundFormat,
  TEventRound,
  TLeagueTeamScores,
  TLeagueDivision,
} from "redux/reducers/models/league";

import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import Card from "components/card/Card";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import Spin from "components/spin/spin";
import { SkinsTable } from "./tables/SkinsTable";
import { IndividualScoresTable } from "./tables/IndividualScoresTable";
import { TeamScoresTable } from "./tables/TeamScoresTable";
import Toggle from "components/form/toggle/Toggle";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import { Select } from "components/select/";
import Callout from "components/callout/Callout";
import Sheet from "components/sheet/Sheet";
import Portal from "elements/Portal";
import Tabs, { GenericTab } from "components/tabs/Tabs";

import "../../leaderboards/leagueLeaderboard.scss";
import "./leagueLeaderboard.scss";

interface IParams {
  leagueId: string;
  scoresheetId: string;
}

interface ISortBy {
  /**Column to sort by. Default sorts the data by gross in ascending order */
  type: "default" | "gross" | "net" | "pts" | "overall";
  /**Ascending is true, Descending is false*/
  direction: boolean;
}

interface IState {
  rounds: Array<TEventRound>;
  teamScores: Array<TLeagueTeamScores>;
  divisions: Array<TLeagueDivision>;
  flights: Array<TLeagueFlight>;
  currentFlightId: number;
  sortBy: ISortBy;
  currentFormat: TLeagueRoundFormat;
  selectedRoundId: number;
  holeScoreRounds: Array<TEventRound>;
  selectedDivisionId: number;
}

interface IPrintFormat {
  format_id: number;
}

export interface IFlightParticipant {
  created_at: string;
  deleted_at: string;
  updated_at: string;
  flight_id: number;
  id: number;
  league_id: number;
  notes: string;
  order_financial_status: string;
  order_id: number;
  order_name: string;
  user_id: number;
}

export default function LeagueLeaderboard() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { leagueId, scoresheetId } = useParams<IParams>();
  const dispatch = useAppDispatch();
  const { Option } = Select;

  const [state, setState] = useState<IState>({
    rounds: null,
    teamScores: null,
    divisions: [],
    flights: null,
    currentFlightId: -1,
    sortBy: { type: "default", direction: true },
    currentFormat: null,
    selectedRoundId: null,
    holeScoreRounds: null,
    selectedDivisionId: null,
  });

  const [scoringFormats, setScoringFormats] = useState<TLeagueRoundFormat[]>(undefined);
  const [leagueTeamScores, setLeagueTeamScores] = useState<TLeagueTeamScores[]>(null);
  const [showTeamScores, setShowTeamScores] = useState(false);

  const [tabs, setTabs] = useState<GenericTab[]>([]);
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const {
    state: printModal,
    updateModal: updatePrintModal,
    closeModal: closePrintModal,
  } = useModal<IPrintFormat>({ format_id: null });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadLeagueScoringSettings();
    void loadFlights(source.token);
    void loadDivisions(source.token);
    return () => source.cancel();
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (state.currentFormat?.organization === "team") {
      void loadTeamScores(source.token);
    } else {
      void loadRounds(source.token);
    }
    if (state.currentFormat?.format === "skins") {
      void loadRoundHoleScores(source.token);
    }
    return () => source.cancel();
  }, [state.currentFormat]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (state.selectedRoundId) {
      void loadRoundHoleScores(source.token);
    }
    return () => source.cancel();
  }, [state.selectedRoundId]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (state.selectedDivisionId) {
      void loadScoringFormats(scoresheetId, source.token);
    }
    return () => source.cancel();
  }, [state.selectedDivisionId]);

  async function loadScoringFormats(leagueScoresheetId: string | number, token?: CancelToken) {
    const res = await GetLeagueCompetitionFormat(
      {
        league_scoresheet_id: leagueScoresheetId,
        league_division_id: state.selectedDivisionId,
      },
      true,
      token,
    );
    if (token && token.reason) {
      return;
    }

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading round formats.")); // TODO: Translation
    }

    const formatTabs = res.data.map((format: TLeagueRoundFormat) => {
      return {
        content: format.title,
      };
    });

    setScoringFormats(res.status !== StatusCode.OK ? [] : res.data);
    setTabs(formatTabs);
    setState(prevState => ({ ...prevState, currentFormat: res.data[0] }));
  }

  async function loadFlights(token?: CancelToken) {
    const flightsRes = await GetLeagueFlights({ league_id: Number(leagueId) }, true, token);

    if (token && token.reason) {
      return;
    }
    if (flightsRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading flights"));
      return;
    }

    setState(prevState => ({ ...prevState, flights: flightsRes.data }));
  }

  async function loadDivisions(token?: CancelToken) {
    const divisionsRes = await GetLeagueDivision({ league_id: Number(leagueId) }, true, token);

    if (token && token.reason) {
      return;
    }
    if (divisionsRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading divisions"));
      return;
    }

    setState(prevState => ({ ...prevState, divisions: divisionsRes.data }));
  }

  async function loadRounds(token?: CancelToken) {
    if (!state.currentFormat) {
      return;
    }

    const roundsRes = await GetLeagueRound(
      {
        scoresheet_id: Number(scoresheetId),
        league_id: Number(leagueId),
        format_id: state.currentFormat.id,
      },
      false,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (roundsRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.league.league_leaderboard.001")));
      history.push(`/admin/league/${leagueId}/scoring`);
      return;
    }

    const sortedRounds = roundsRes.data?.sort((currentRound: any, nextRound: any) => {
      if (!currentRound?.round?.gross_score) {
        return 1;
      } else if (!nextRound?.round?.gross_score) {
        return -1;
      } else {
        return currentRound?.round?.gross_score - nextRound?.round?.gross_score;
      }
    });

    setState(prevState => ({ ...prevState, rounds: sortedRounds }));
  }

  async function loadTeamScores(token?: CancelToken) {
    if (!state.currentFormat) {
      return;
    }

    const scoreRes = await GetLeagueTeamScores(
      {
        scoresheet_id: Number(scoresheetId),
        format_id: state.currentFormat.id,
      },
      false,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (scoreRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading team scores"));
      return;
    }

    setState(prevState => ({ ...prevState, teamScores: scoreRes.data }));
  }

  async function loadRoundHoleScores(token?: CancelToken) {
    if (!state.currentFormat) {
      return;
    }

    const holeScoreRes = await GetLeagueRoundHoleScores(
      {
        format_id: state.currentFormat.id,
        round_id:
          state.currentFormat.organization === "individual" && state.currentFormat.format !== "skins"
            ? state.selectedRoundId
            : null,
        team_id:
          state.currentFormat.organization === "team" && state.currentFormat.format !== "skins"
            ? state.selectedRoundId
            : null,
      },
      false,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (holeScoreRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading round hole scores")); // TODO: Translation
      return;
    }

    if (state.currentFormat.organization === "team" && state.currentFormat.format !== "skins") {
      void loadRounds(token);
    }

    setState(prevState => ({ ...prevState, holeScoreRounds: holeScoreRes.data }));
  }

  async function loadLeagueScoringSettings() {
    const params = {
      league_id: Number(leagueId),
    };

    const leagueScoringSettingsRes = await GetScoringSettings(params, true);

    if (leagueScoringSettingsRes.status !== StatusCode.OK) {
      return;
    }

    if (leagueScoringSettingsRes.data.competition === "team_vs_field") {
      setShowTeamScores(true); //default is shown
      // void loadLeagueTeamScores();
    }
  }

  function navigateToTvView() {
    history.push("/admin/league/" + String(leagueId) + "/scoring/tv/" + String(scoresheetId));
  }

  async function printScoresheet() {
    if (!printModal.format_id) {
      dispatch(showError("No format selected")); // TODO: Translation
    }

    const printRes = await PrintRounds(
      {
        format_id: printModal.format_id,
        scoresheet_id: Number(scoresheetId),
      },
      true,
    );

    if (printRes.status === StatusCode.OK) {
      window.open().document.write(printRes.data);
    } else {
      return;
    }
  }

  const primaryAction = {
    content: t("secure.facility.league.league_leaderboard.022"),
    action: () => updatePrintModal({ isOpen: true }),
    disabled: !(scoringFormats && scoringFormats.length > 0),
  };

  function handleClickRound(roundId: number) {
    setState(prevState => ({ ...prevState, holeScoreRounds: null, selectedRoundId: roundId }));
  }

  function handleTabChange(selectedTabIndex: number) {
    if (selectedTabIndex === selectedTab) {
      return;
    }

    const formats = [...scoringFormats];

    setState(prevState => ({
      ...prevState,
      selectedRoundId: null,
      rounds: null,
      holeScoreRounds: null,
      teamScores: null,
      currentFormat: formats[selectedTabIndex],
    }));

    setSelectedTab(selectedTabIndex);
  }

  return (
    <Page
      title={t("secure.facility.league.league_leaderboard.011")}
      primaryAction={primaryAction}
      narrow
      breadcrumbs={[
        {
          prefix: true,
          label: "Round Overview", // TODO: Translation
          url: `/admin/league/${leagueId}/rounds/scoresheet/${scoresheetId}/overview`,
        },
      ]}
    >
      <FormLayout>
        <FormLayout.Group>
          <Select
            label="Division"
            onChange={(value: number) => {
              setState(prevState => ({
                ...prevState,
                selectedDivisionId: value,
                selectedRoundId: null,
                rounds: null,
                holeScoreRounds: null,
                teamScores: null,
              }));
              setSelectedTab(0);
            }}
            defaultValue={state.divisions[0]?.id}
          >
            {state.divisions?.map(division => (
              <Option key={division.id} value={division.id}>
                {division.title}
              </Option>
            ))}
          </Select>
          <div />
          <div />
        </FormLayout.Group>
      </FormLayout>

      {scoringFormats && scoringFormats.length > 0 ? (
        <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange}>
          {state.currentFormat?.format !== "skins" && state.currentFormat?.organization === String("individual") && (
            <>
              {state.rounds ? (
                <IndividualScoresTable
                  format={state.currentFormat}
                  rounds={state.rounds}
                  flights={state.currentFormat?.competition === "player_vs_flight" ? state.flights : []}
                  holeScoreRound={state.holeScoreRounds ? state.holeScoreRounds[0] : null}
                  selectedRoundId={state.selectedRoundId}
                  onClick={handleClickRound}
                />
              ) : (
                <div style={{ height: "24px" }}>
                  <Spin />
                </div>
              )}
            </>
          )}
          {state.currentFormat?.format !== "skins" && state.currentFormat?.organization === String("team") && (
            <>
              {state.teamScores ? (
                <TeamScoresTable
                  format={state.currentFormat}
                  teamScores={state.teamScores}
                  rounds={state.holeScoreRounds}
                  formatRounds={state.rounds}
                  selectedRoundId={state.selectedRoundId}
                  onClick={handleClickRound}
                />
              ) : (
                <div style={{ height: "24px" }}>
                  <Spin />
                </div>
              )}
            </>
          )}
          {state.currentFormat?.format === "skins" && (
            <div>
              {state.holeScoreRounds ? (
                <SkinsTable
                  format={state.currentFormat}
                  holeScoreRounds={state.holeScoreRounds}
                  rounds={state.rounds}
                />
              ) : (
                <div style={{ height: "24px" }}>
                  <Spin />
                </div>
              )}
            </div>
          )}
        </Tabs>
      ) : (
        <Callout
          type="info"
          title="No Formats"
          content="There are no formats for the selected division" /* TODO: Translation */
        />
      )}

      <Portal isMounted={printModal.isOpen}>
        <Sheet
          open={printModal.isOpen}
          title="Print Leaderboard" // TODO: Translation
          onOk={printScoresheet}
          onCancel={closePrintModal}
          okText="Print" // TODO: Translation
          size="small"
          overflow
        >
          <Select
            label="Format" // TODO: Translation
            onChange={(value: number) => updatePrintModal({ format_id: value })}
          >
            {scoringFormats?.map(format => (
              <Option key={format.id} value={format.id} name={format.title}>
                {format.title}
              </Option>
            ))}
          </Select>
        </Sheet>
      </Portal>
    </Page>
  );
}
