import { GetClient } from "api/rpc/2024-04/masterAdmin/client/client";
import { LocalStorage } from "api/localstorage";
// import { ICartState } from "reducers/cart";
import { StatusCode } from "api/protocols";
import { GetWeather } from "api/rpc/weather/weather";

export function loadClients(params: any, useGlobalLoader: boolean) {
  return async (dispatch: any) => {
    const res = await GetClient(params, useGlobalLoader);
    if (res.status !== StatusCode.OK) {
      console.log(res);
      return;
    }

    dispatch({
      type: "client.update",
      payload: {
        isLoaded: true,
        clients: res.data,
      },
    });
  };
}

export function selectClient(client: any) {
  return (dispatch: any) => {
    dispatch({
      type: "client.select",
      payload: {
        isLoaded: true,
        client: client,
      },
    });
  };
}

export interface IClientActions {
  loadClients: typeof loadClients;
  selectClient: typeof selectClient;
}
