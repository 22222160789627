import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetAccountingReference } from "api/rpc/2024-04/clientAdmin/client/accountingReference";

import { showError } from "redux/actions/ui";
import { TAccountingReference } from "redux/reducers/models/facility";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import Search from "components/search/Search";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

type AccountFilters = {
  search: string;
};

export default function AccountingReferences() {
  const history = useHistory();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const [accountReferences, setAccountReferences] = useState<TAccountingReference[]>(undefined);

  const [filterState, setFilterState] = useState<AccountFilters>({
    search: "",
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadAccountingReferences(source.token);
    return () => source.cancel();
  }, [filterState]);

  async function loadAccountingReferences(token?: CancelToken) {
    if (accountReferences !== undefined) {
      setAccountReferences(undefined);
    }
    const res = await GetAccountingReference({ search: filterState.search }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading accounting references")); //TODO: Translate
    }

    setAccountReferences(res.status !== StatusCode.OK ? [] : res.data);
  }

  const primaryAction = {
    content: "New Accounting Reference", //TODO: Translate
    action: () => history.push("/admin/settings/accounting_references/new"),
  };

  return (
    <Page
      title={"Accounting References"} //TODO: Translate
      subtitle={"Manage accounting references"} //TODO: Translate
      narrow
      //   primaryAction={primaryAction}
    >
      <div className="flex-grow mb-4">
        <Search
          searchCallback={searchValue => setFilterState(prevState => ({ ...prevState, search: searchValue }))}
          historyKey="accounting-references-all"
          placeholder="Search accounts..." // TODO: TRANSLATION
        />
      </div>

      <DataTable
        columns={[
          { label: "Title", width: "50%" }, //TODO: Translate
          { label: "Reference Number", width: "50%" }, //TODO: Translate
        ]}
        loading={accountReferences === undefined}
      >
        {accountReferences?.map(reference => (
          <tr
            key={reference.id}
            onClick={() => history.push("/admin/settings/accounting_references/" + String(reference.id))}
            className="clickable"
          >
            <td>{reference.title}</td>
            <td>{reference.reference_number}</td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
