import React, { useState, createContext, useContext, useEffect } from "react";

import { SiderContext, ISiderContextProps } from "../layout/Sider";

import classNames from "classnames";
// import SubMenu, { SubMenuProps } from './SubMenu';
import Item, { IMenuItemProps } from "./MenuItem";
import { ConfigConsumer, IConfigConsumerProps } from "../config-provider/index";
import MenuContext, { MenuTheme } from "./MenuContext";

import { faGameConsoleHandheld } from "@fortawesome/pro-duotone-svg-icons";
import { prefix } from "@fortawesome/free-brands-svg-icons";

import "./style.scss";

export type MenuMode = "vertical" | "vertical-left" | "vertical-right" | "horizontal" | "inline";

interface IMenuComposition {
  Item: React.FC<IMenuItemProps>;
}

interface IMenuProps {
  prefixCls?: string;
  className?: string;
  collapsed?: boolean;
}

type InternalMenuProps = IMenuProps & ISiderContextProps;

const InternalMenu: React.FC<InternalMenuProps> = props => {
  const [state, setState] = useState("a");

  const { prefixCls = "rc-menu", className, collapsed, siderCollapsed, children, ...rest } = props;

  const getInlineCollapsed = () => {
    const { siderCollapsed } = props;
    if (siderCollapsed !== undefined) {
      return siderCollapsed;
    }
    // return inlineCollapsed;
  };

  const menuClassname = classNames(`rc-menu`, className, {
    [`${prefixCls}-sider-collapsed`]: siderCollapsed,
  });

  const renderMenu = () => {
    return (
      <MenuContext.Provider
        value={{
          inlineCollapsed: getInlineCollapsed() || false,
        }}
      >
        <ul className={menuClassname}>{children}</ul>
      </MenuContext.Provider>
    );
  };

  return (
    <>
      <ConfigConsumer>{renderMenu}</ConfigConsumer>
    </>
  );
};

const Menu: React.FC<IMenuProps> & IMenuComposition = props => {
  return (
    <>
      <SiderContext.Consumer>
        {(context: ISiderContextProps) => <InternalMenu {...props} {...context} />}
      </SiderContext.Consumer>
    </>
  );
};

// interface IItemProps {
//   className?: string;
//   prefixCls?: string;
//   key?: any;
// }

// const Item: React.FC<IItemProps> = (props) => {
//   const { prefixCls = "rc-menu-item", className, ...rest } = props;

//   const itemClassname = classNames(prefixCls, className);

//   // const { setState } = useContext(MenuContext);

//   return (
//     <li className={itemClassname} {...rest}>
//       {props.children}
//     </li>
//   );
// };

Menu.Item = Item;

export default Menu;
