import { useTranslation, Trans } from "react-i18next";
import { IOptions } from "components/sideMenu/SideMenu";

export default function SettingOptionsTranslation() {
  const { t, i18n } = useTranslation();

  const customerOptions: IOptions[] = [
    {
      label: t("secure.facility.customer.customer_options.001"),
      url: "/admin/customers", //auto-route
    },
    {
      label: t("secure.facility.customer.customer_options.002"),
      url: "/admin/customers-public",
    },
    {
      label: t("secure.facility.customer.customer_options.003"),
      url: "/admin/customers-member",
    },
  ];

  return customerOptions;
}
