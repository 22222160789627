import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetCountry, GetProvince } from "api/rpc/2024-04/country";
import {
  GetTeeOnPaymentRepresentative,
  PostTeeOnPaymentRepresentative,
  PutAcceptTerms,
  TPostTeeOnPaymentRepresentative,
} from "api/rpc/2024-04/facilityAdmin/payment/teeonPayments/accounts";

import { showError, showSuccess } from "redux/actions/ui";
import { ICountry, IProvince } from "redux/reducers/models/customer";
import { TTeeOnPaymentRepresentative } from "redux/reducers/models/transaction";
import useModal from "hooks/modals/useModal";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import Page from "components/page/Page";
import InnerLayout from "components/innerLayout/InnerLayout";
import Card from "components/card/Card";
import Sheet from "components/sheet/Sheet";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Checkbox from "components/form/checkbox/Checkbox";
import Input from "components/form/input/Input";
import { Select } from "components/select";
import Spin from "components/spin/spin";
import Portal from "elements/Portal";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";
import Callout from "components/callout/Callout";

export default function TeeOnPayments() {
  const { t } = useTranslation();
  const { authStore } = useAppSelector(store => store);

  const dispatch = useAppDispatch();
  const {
    state: newRepresentativeModalOpen,
    updateModal,
    closeModal,
  } = useModal<TPostTeeOnPaymentRepresentative>({
    first_name: "",
    last_name: "",
    address_line_1: "",
    city: "",
    postal: "",
    country_id: undefined,
    province_id: undefined,
    dob_day: "",
    dob_month: "",
    dob_year: "",
    relationship: {
      director: false,
      executive: false,
      owner: false,
      representative: false,
    },
    id_number: "",
  });

  const [countries, setCountries] = useState<ICountry[]>([]);
  const [provinces, setProvinces] = useState<IProvince[]>([]);

  const [representatives, setRepresentatives] = useState<{ data: TTeeOnPaymentRepresentative[]; reload: boolean }>({
    data: undefined,
    reload: true,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (representatives.reload) {
      void loadRepresentatives(source.token);
    }
    return () => source.cancel();
  }, [representatives.reload]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadCountries(source.token);
    return () => source.cancel();
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (newRepresentativeModalOpen.country_id) {
      void loadProvinces(newRepresentativeModalOpen.country_id, source.token);
    } else {
      setProvinces([]);
    }
  }, [newRepresentativeModalOpen.country_id]);

  async function loadRepresentatives(token?: CancelToken) {
    if (representatives.data !== undefined) {
      setRepresentatives(prev => ({ ...prev, data: undefined }));
    }
    const res = await GetTeeOnPaymentRepresentative(undefined, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading Tee On payment representatives")); // TODO: Translation
    }

    setRepresentatives({
      data: res.status !== StatusCode.OK ? [] : res.data,
      reload: false,
    });
  }

  // Data only needed in Select dropdown.  No global loader
  async function loadCountries(token?: CancelToken) {
    const res = await GetCountry(null, false, token);
    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading countries.")); // TODO: Translation
    }

    setCountries(res.data);
  }
  /* Called only when country_id changes & not nullish */
  async function loadProvinces(countryId: string | number, token?: CancelToken) {
    if (provinces !== undefined) {
      setProvinces(undefined);
    }
    const res = await GetProvince({ country_id: countryId }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading provinces.")); // TODO: Translation
    }

    setProvinces(res.status !== StatusCode.OK ? [] : res.data);
  }

  async function addNewRepresentative() {
    const { isOpen, ...params } = newRepresentativeModalOpen;

    if (
      !params.first_name ||
      !params.last_name ||
      !params.address_line_1 ||
      !params.city ||
      !params.postal ||
      !params.dob_day ||
      !params.dob_month ||
      !params.dob_year ||
      !params.id_number ||
      !params.country_id ||
      !params.province_id
    ) {
      dispatch(showError("Please fill in all input fields.")); // TODO: Translation
      return;
    }

    const res = await PostTeeOnPaymentRepresentative(params, true);

    if (res.status !== StatusCode.OK) {
      dispatch(
        showError(typeof res.data === "string" ? res.data : t("secure.facility.settings.payments.tee_on_payments.001")),
      );
      return;
    }

    dispatch(showSuccess(t("secure.facility.settings.payments.tee_on_payments.002")));
    closeModal();
    setRepresentatives(prev => ({ ...prev, reload: true }));
  }

  async function handleAcceptTerms() {
    const res = await PutAcceptTerms(true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.payments.tee_on_payments.003")));
      return;
    }
    dispatch(showSuccess(t("secure.facility.settings.payments.tee_on_payments.004")));
  }

  return (
    <Page
      title={t("secure.facility.settings.payments.tee_on_payments.005")}
      subtitle={t("secure.facility.settings.payments.tee_on_payments.006")}
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.payments.tee_on_payments.007"),
          url: "/admin/settings/payments/manage",
        },
      ]}
    >
      <InnerLayout>
        <InnerLayout.Section>
          {authStore.user?.permissions?.tee_on_payments_view_persons ? (
            <Card
              title={t("secure.facility.settings.payments.tee_on_payments.010")}
              subtitle="These are the representatives attached to your payment account." /* Spelling fix ** TODO: Translation */
              titleActions={[
                {
                  content: (
                    <span className="flex" style={{ color: "$primaryBlue" }}>
                      <FontAwesomeIcon icon={["fas", "plus"]} />
                      <h4>{t("secure.facility.settings.payments.tee_on_payments.011")}</h4>
                    </span>
                  ),
                  action: () => updateModal({ isOpen: true }),
                },
              ]}
              style={{ marginTop: "12px" }}
            >
              <Card.Section>
                <DataTable columns={[{ label: "Name" }]} loading={representatives.data === undefined}>
                  {representatives.data?.map(rep => (
                    <tr key={rep.id}>
                      <td>
                        {rep.first_name} {rep.last_name}
                      </td>
                    </tr>
                  ))}
                </DataTable>
              </Card.Section>
            </Card>
          ) : (
            <Card>
              <Card.Section>
                <Callout
                  type="info"
                  title={"The logged in user does not have permission to view the payment account representatives"} // TODO: Translation
                />
              </Card.Section>
            </Card>
          )}
          {representatives.data === undefined ? (
            <Card style={{ marginTop: "12px", height: "75px", padding: "24px", overflow: "hidden" }}>
              <Spin />
            </Card>
          ) : (
            <Card
              title={t("secure.facility.settings.payments.tee_on_payments.014")}
              subtitle={t("secure.facility.settings.payments.tee_on_payments.015")}
              titleActions={[
                {
                  content: t("secure.facility.settings.payments.tee_on_payments.016"),
                  action: () => handleAcceptTerms(),
                  buttonType: "primary",
                },
              ]}
              style={{ paddingBottom: "24px" }}
            />
          )}
        </InnerLayout.Section>
      </InnerLayout>

      <Portal isMounted={newRepresentativeModalOpen.isOpen}>
        <Sheet
          open={newRepresentativeModalOpen.isOpen}
          closable
          size="small"
          title={t("secure.facility.settings.payments.tee_on_payments.017")}
          onCancel={closeModal}
          cancelText={t("secure.facility.settings.payments.tee_on_payments.018")}
          onOk={() => addNewRepresentative()}
          okText={t("secure.facility.settings.payments.tee_on_payments.019")}
        >
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={newRepresentativeModalOpen.first_name}
                  label={t("secure.facility.settings.payments.tee_on_payments.020")}
                  id="first_name"
                  onChange={e => updateModal({ first_name: e.target.value })}
                  placeholder={t("secure.facility.settings.payments.tee_on_payments.021")}
                />
                <Input
                  value={newRepresentativeModalOpen.last_name}
                  label={t("secure.facility.settings.payments.tee_on_payments.022")}
                  id="last_name"
                  onChange={e => updateModal({ last_name: e.target.value })}
                  placeholder={t("secure.facility.settings.payments.tee_on_payments.023")}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Input
                  value={newRepresentativeModalOpen.address_line_1}
                  label={t("secure.facility.settings.payments.tee_on_payments.024")}
                  id="address_line_1"
                  onChange={e => updateModal({ address_line_1: e.target.value })}
                  placeholder={t("secure.facility.settings.payments.tee_on_payments.025")}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Input
                  value={newRepresentativeModalOpen.city}
                  label={t("secure.facility.settings.payments.tee_on_payments.026")}
                  id="city"
                  onChange={e => updateModal({ city: e.target.value })}
                  placeholder={t("secure.facility.settings.payments.tee_on_payments.027")}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Select
                  label={t("secure.facility.settings.payments.tee_on_payments.028")}
                  onChange={(value: number) => updateModal({ country_id: value })} // Triggers province load
                  defaultValue={newRepresentativeModalOpen.country_id}
                >
                  {countries.map(country => {
                    return (
                      <Select.Option key={country.id} value={country.id} name={country.name}>
                        {country.name}
                      </Select.Option>
                    );
                  })}
                </Select>
                <Select
                  label={t("secure.facility.settings.payments.tee_on_payments.029")}
                  onChange={(value: number) => updateModal({ province_id: value })}
                  defaultValue={newRepresentativeModalOpen.province_id}
                >
                  {provinces === undefined && (
                    <Select.Option value={"loading"} disabled>
                      <div style={{ height: "40px", padding: "8px 16px" }}>
                        <Spin />
                      </div>
                    </Select.Option>
                  )}
                  {provinces?.map(province => {
                    return (
                      <Select.Option key={province.id} value={province.id} name={province.name}>
                        {province.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </FormLayout.Group>
              <FormLayout.Group>
                <Input
                  value={newRepresentativeModalOpen.postal}
                  label={t("secure.facility.settings.payments.tee_on_payments.030")}
                  id="postal"
                  onChange={e => updateModal({ postal: e.target.value })}
                  placeholder={t("secure.facility.settings.payments.tee_on_payments.031")}
                />
              </FormLayout.Group>
              <FormLayout.Group title="Birthdate" /* TODO: Translation */>
                <Input
                  value={newRepresentativeModalOpen.dob_day}
                  label={t("secure.facility.settings.payments.tee_on_payments.032")}
                  id="dob_day"
                  onChange={e => updateModal({ dob_day: e.target.value })}
                  placeholder={t("secure.facility.settings.payments.tee_on_payments.033")}
                  type="number"
                />
                <Input
                  value={newRepresentativeModalOpen.dob_month}
                  label={t("secure.facility.settings.payments.tee_on_payments.034")}
                  id="dob_month"
                  onChange={e => updateModal({ dob_month: e.target.value })}
                  placeholder={t("secure.facility.settings.payments.tee_on_payments.035")}
                  type="number"
                />
                <Input
                  value={newRepresentativeModalOpen.dob_year}
                  label={t("secure.facility.settings.payments.tee_on_payments.036")}
                  id="dob_year"
                  onChange={e => updateModal({ dob_year: e.target.value })}
                  placeholder={t("secure.facility.settings.payments.tee_on_payments.037")}
                  type="number"
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Input
                  value={newRepresentativeModalOpen.id_number}
                  label={t("secure.facility.settings.payments.tee_on_payments.038")}
                  id="id_number"
                  onChange={e => updateModal({ id_number: e.target.value })}
                  placeholder={t("secure.facility.settings.payments.tee_on_payments.039")}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Checkbox
                  id="director"
                  size="medium"
                  checked={newRepresentativeModalOpen.relationship.director}
                  onChange={e =>
                    updateModal({
                      relationship: { ...newRepresentativeModalOpen.relationship, director: e.target.checked },
                    })
                  }
                  label={t("secure.facility.settings.payments.tee_on_payments.040")}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Checkbox
                  id="owner"
                  size="medium"
                  checked={newRepresentativeModalOpen.relationship.owner}
                  onChange={e =>
                    updateModal({
                      relationship: { ...newRepresentativeModalOpen.relationship, owner: e.target.checked },
                    })
                  }
                  label={t("secure.facility.settings.payments.tee_on_payments.041")}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Checkbox
                  id="executive"
                  size="medium"
                  checked={newRepresentativeModalOpen.relationship.executive}
                  onChange={e =>
                    updateModal({
                      relationship: { ...newRepresentativeModalOpen.relationship, executive: e.target.checked },
                    })
                  }
                  label={t("secure.facility.settings.payments.tee_on_payments.042")}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Checkbox
                  id="representative"
                  size="medium"
                  checked={newRepresentativeModalOpen.relationship.representative}
                  onChange={e =>
                    updateModal({
                      relationship: { ...newRepresentativeModalOpen.relationship, representative: e.target.checked },
                    })
                  }
                  label={t("secure.facility.settings.payments.tee_on_payments.043")}
                />
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Sheet>
      </Portal>
    </Page>
  );
}
