import { APIGet, APIPost, APIPut, APIDelete, StatusCode } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2021-01";
const newApiVersion = "2022-09";
const adminType = "facility-admin";
const baseBookingUrl = "/" + apiVersion + "/" + adminType + "/tee-sheet/tee-time/booking";
const newBaseBookingUrl = "/" + newApiVersion + "/" + adminType + "/tee-sheet/tee-time/booking";

// GET Tee Time Booking
// Retrieve an array of tee time bookings
interface IGetBooking {
  id?: number;
  tee_time_id?: number;
  customer_id?: number;
  status?: boolean;
  extended?: boolean;
  extended_tee_time?: boolean;
}

export async function GetBooking(params: IGetBooking, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseBookingUrl, params, useGlobalLoader, token);
}

export async function GetTotalBookings(params: { customer_id: string }, useGlobalLoader: boolean) {
  return await APIGet(baseBookingUrl + "/participant/count", params, useGlobalLoader);
}

// POST Booking
// Create a new booking for a tee time
export interface IPostBooking {
  tee_time_id?: number;
  send_confirmation?: boolean;
  quantity?: number;
  holes?: number;
  power_cart_quantity?: number;
  customer_id?: number;
  customer_payment_method_id?: number;
  tee_time_slots?: {
    id: number;
    customer_id: number;
    guest: boolean;
  }[];
}

export async function PostBooking(params: IPostBooking, useGlobalLoader: boolean) {
  return await APIPost(baseBookingUrl, params, useGlobalLoader);
}

// PUT Booking
// Update an existing tee time booking
export interface IPutBooking {
  id: number;
  quantity?: number;
  order_id?: number;
  customer_id?: number;
  holes?: number;
  power_cart_quantity?: number;
  customer_payment_method_id?: number;
  tee_time_slots?: { id: number; customer_id: number; guest: boolean }[];
  extended?: boolean;
}

interface IPutBookingRes {
  status: StatusCode;
  data: {
    message?: string;
    data: {
      order_id: number;
    };
  };
  message?: string;
}

interface IPutMoveBooking {
  booking_id: number;
  tee_time_id: number;
}

export async function PutBooking(params: IPutBooking, useGlobalLoader: boolean): Promise<IPutBookingRes> {
  return await APIPut(baseBookingUrl, params, useGlobalLoader);
}

export async function PutMoveBooking(params: IPutMoveBooking, useGlobalLoader: boolean) {
  return await APIPut(baseBookingUrl + "/move", params, useGlobalLoader);
}

// Cancel Booking
// Cancel an existing booking for a tee time
export interface IPutCancelBooking {
  tee_time_booking_id: number;
}

export interface IPutCancelBookingRes {
  status: StatusCode;
  data: any;
  message?: string;
}

// export async function PutCancelBooking(params: any, useGlobalLoader: boolean) {
//   const string = "/2021-01/facility-admin/tee-sheet/tee-time/booking/cancel?tee_time_booking_id=718";
//   return await APIPut(baseBookingUrl + "/cancel", params, useGlobalLoader);
// }

export async function PutCancelBooking(
  params: IPutCancelBooking,
  useGlobalLoader: boolean,
): Promise<IPutCancelBookingRes> {
  return await APIPut(baseBookingUrl + "/cancel", params, useGlobalLoader);
}

// Cancel participant in a booking
export interface IPutCancelParticipant {
  id: number;
}

export interface IPutCancelParticipantRes {
  status: StatusCode;
  data: any;
  message?: string;
}
export async function PutCancelParticipant(
  params: IPutCancelParticipant,
  useGlobalLoader: boolean,
): Promise<IPutCancelParticipantRes> {
  return await APIPut(baseBookingUrl + "/participant/cancel", params, useGlobalLoader);
}

export interface IPutCheckParticipant {
  booking_participant_ids: number[];
}

export interface IPutCheckParticipantRes {
  status: StatusCode;
  data: any;
}

export async function PutCheckParticipant(
  params: IPutCheckParticipant,
  useGlobalLoader: boolean,
): Promise<IPutCheckParticipantRes> {
  return await APIPut(baseBookingUrl + "/participant/check", params, useGlobalLoader);
}

// Add Green Fee / Power Cart to booking participant

interface IPutBookingParticipant {
  id: number;
  customer_id?: number;
  no_show?: boolean;
  no_show_weather?: boolean;
  no_show_paid?: boolean;
  green_fee_variant_id?: number;
  green_fee_paid?: boolean;
  power_cart_variant_id?: number;
  power_cart_paid?: boolean;
  order_id?: number;
  first_name?: string;
  last_name?: string;
  full_name?: string;
  check_in_status?: string;
  power_cart_number?: number;
  seat_number?: number;
}

interface IPutBookingParticipantRes {
  status: StatusCode;
  data: any;
  message: string;
}

interface IPutBookingConfirmation {
  tee_time_booking_id: number;
}

interface IPutBookingParticipantNoShow {
  id: number;
  no_show_type: string;
  variant_id?: number;
}

export async function PutBookingParticipant(
  params: IPutBookingParticipant,
  useGlobalLoader: boolean,
): Promise<IPutBookingParticipantRes> {
  return await APIPut(newBaseBookingUrl + "/participant", params, useGlobalLoader);
}

export async function PutBookingParticipantNoShow(params: IPutBookingParticipantNoShow, useGlobalLoader: boolean) {
  return await APIPut(newBaseBookingUrl + "/participant/noshow", params, useGlobalLoader);
}

// Get/Add/Remove/Update Note to Booking
export async function GetBookingNotes(params: { tee_time_booking_id: number }, useGlobalLoader: boolean) {
  return await APIGet(newBaseBookingUrl + "/note", params, useGlobalLoader);
}

export async function PostBookingNote(
  params: { tee_time_booking_id: number; tee_time_booking_participant_id?: number; content: string },
  useGlobalLoader: boolean,
) {
  return await APIPost(newBaseBookingUrl + "/note", params, useGlobalLoader);
}

export async function PutBookingNote(params: { booking_note_id: number; content: string }, useGlobalLoader: boolean) {
  return await APIPut(newBaseBookingUrl + "/note", params, useGlobalLoader);
}

export async function DeleteBookingNote(params: { booking_note_id: number }, useGlobalLoader: boolean) {
  return await APIDelete(newBaseBookingUrl + "/note", params, useGlobalLoader);
}

// Get Booking Audit Trails
export async function GetAuditTrails(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseBookingUrl + "/audit", params, useGlobalLoader);
}

// Move Booking
export async function MoveBooking(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseBookingUrl + "/move", params, useGlobalLoader);
}

// Copy Booking
export async function CopyBooking(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseBookingUrl + "/copy", params, useGlobalLoader);
}

export async function GetCartWaiver(params: { customer_id?: number; tee_time_id?: number }, useGlobalLoader?: boolean) {
  return await APIGet(baseBookingUrl + "/cart-waiver", params, useGlobalLoader);
}

export async function PutBookingConfirmation(params: IPutBookingConfirmation, useGlobalLoader?: boolean) {
  return await APIPut(baseBookingUrl + "/confirmation", params, useGlobalLoader);
}
