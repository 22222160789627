import React, { useEffect, useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useOnclickOutside from "hooks/useOnclickOutside/useOnclickOutside";
import { isEmpty } from "helpers/Helpers";

import TimeElement from "./TimeElement";

import "./timePick.scss";

type TimePickProps = {
  value: string | undefined; // Undefined || empty string = removing
  label?: string;
  helpText?: string;
  onChange: (timeString: string | undefined) => void; // 24-hr format

  hourStep?: number;
  minuteStep?: number;
  size?: "medium" | "large"; // 'small' is default

  /** Trigger onChange event when blur */
  changeOnBlur?: boolean;
  status?: "error" | "warning";
  disabled?: boolean;
  /** Disables the dropdown input on the component. */
  disableClock?: boolean;

  align?: "center" | "right";
  positionTop?: boolean;
  /** Hide AM | PM column. param passed = length of Hour element values */
  hideMeridiem?: number;
};

const armyTimeRegex = /^([01]\d|20|21|22|23):[0-5]\d$/; // 00:00 - 23:59

export default function TimePick(props: TimePickProps) {
  const { hourStep = 1, minuteStep = 5, disabled = false, positionTop = false, disableClock = false } = props;
  const minuteList = generateArray(0, minuteStep, 60);
  const hourList = !props.hideMeridiem
    ? ["12", ...generateArray(1, hourStep, 12)]
    : generateArray(1, hourStep, props.hideMeridiem + 1);

  const [time, setTime] = useState<{ hour: string; minute: string; amPm: string }>(undefined);

  // Style states
  const [focused, setFocused] = useState(false);
  const [iconHovered, setIconHovered] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Column scroll refs
  const hourListContainerRef = useRef<HTMLUListElement>();
  const hourLiRefs = useMemo(
    () =>
      Array(hourList.length)
        .fill(0)
        .map(() => React.createRef<HTMLLIElement>()),
    [],
  );
  const minuteListContainerRef = useRef<HTMLUListElement>();
  const minuteRefs = useMemo(
    () =>
      Array(minuteList.length)
        .fill(0)
        .map(() => React.createRef<HTMLLIElement>()),
    [],
  );

  // string value being returned to user
  const armyTimeString = useMemo(() => {
    if (!time || (time && !time.amPm && !props.hideMeridiem)) {
      return undefined;
    } // ensure all 3 inputs are present

    if (time.amPm === "PM") {
      let tempHour = parseInt(time.hour) + 12;
      if (tempHour === 24) {
        tempHour = 12;
      }
      return String(tempHour) + ":" + time.minute;
    }

    let tempHour = parseInt(time.hour);
    if (tempHour === 12 && !props.hideMeridiem) {
      tempHour = 0;
    }
    return ("0" + String(tempHour)).slice(-2) + ":" + time.minute;
  }, [time]);

  const timePickRef = useOnclickOutside(() => {
    if (focused) {
      setFocused(false);
    }
    if (dropdownOpen) {
      setDropdownOpen(false);
    }

    if (props.changeOnBlur && focused) {
      props.onChange(armyTimeString);
    }
  });

  // Handle Time state based on props.value
  useEffect(() => {
    if (!isEmpty(props.value)) {
      if (props.hideMeridiem) {
        const destructured = props.value.split(":");
        const hourValue =
          parseInt(destructured[0]) < props.hideMeridiem
            ? ("0" + destructured[0]).slice(-2)
            : ("0" + String(props.hideMeridiem)).slice(-2);
        setTime({
          hour: hourValue,
          minute: ("0" + destructured[1]).slice(-2),
          amPm: null,
        });

        return;
      }

      // convert into AM | PM
      if (isValidTime(props.value)) {
        const destructured = props.value.split(":");

        const amOrPm = parseInt(destructured[0]) >= 12 ? "PM" : "AM";
        const destructuredHours = parseInt(destructured[0]) % 12 || 12;

        setTime({
          hour: ("0" + String(destructuredHours)).slice(-2),
          minute: ("0" + String(destructured[1])).slice(-2),
          amPm: amOrPm,
        });
      } else {
        console.warn("Invalid 24-hr string format: ", props.value);
        setTime({
          hour: undefined,
          minute: undefined,
          amPm: undefined,
        });
      }
    } else if (props.value?.length === 0) {
      console.log("empty string clear... ");
      setTime({
        hour: undefined,
        minute: undefined,
        amPm: undefined,
      });
      return;
    } else {
      if (time && (!time.amPm || !time.hour || !time.minute)) {
        console.log("remove single value ... ", time);
        setTime(prev => ({ ...prev })); // 'remove'-ing a single value
      } else {
        console.log("remove all values ", time);
        setTime(undefined); // remove all values
      }
    }
  }, [props.value]);

  // onChange callback when return value is updated
  useEffect(() => {
    if (armyTimeString === undefined || props.value === armyTimeString) {
      return;
    }

    if (time && props.hideMeridiem && parseInt(time.hour) <= props.hideMeridiem && time.hour && time.minute) {
      props.onChange(armyTimeString);
    } else if (time && time.amPm && time.hour && time.minute) {
      props.onChange(armyTimeString);
    } else {
      props.onChange(undefined);
    }
  }, [armyTimeString]);

  function timePickAction() {
    setDropdownOpen(prev => !prev);

    // When opening -- scroll to selected hour | minute
    if (!dropdownOpen) {
      if (time && (time.hour || time.hour === undefined)) {
        handleScroll("hour", time.hour ?? "12");
      }

      if (time && (time.minute || time.minute === undefined)) {
        handleScroll("minute", time.minute ?? "0");
      }
    }
  }

  function timeChangeHandlerDropdown(value: { hour?: string; minute?: string; amPm?: string }) {
    const { hour, minute, amPm } = value;
    setTime(prev => ({
      ...prev,
      hour: hour ? hour : prev?.hour ? prev.hour : undefined,
      minute: minute ? minute : prev?.minute ? prev.minute : undefined,
      amPm: amPm ? amPm : prev?.amPm ? prev.amPm : undefined,
    }));
  }

  function timeChangeHandlerKeyboard(value: { hour?: string; minute?: string; amPm?: string }) {
    const { hour, minute, amPm } = value;

    if (hour) {
      if (hour === "--") {
        setTime(prev => ({ ...prev, hour: undefined }));
        handleScroll("hour", "12");
        return;
      }
      const hourValue = parseInt(hour) < props.hideMeridiem ? ("0" + hour).slice(-2) : hour;

      setTime(prev => ({ ...prev, hour: hourValue }));
      handleScroll("hour", hour);
      return;
    }

    if (minute) {
      if (minute === "--") {
        setTime(prev => ({ ...prev, minute: undefined }));
        handleScroll("minute", "0");
        return;
      }
      setTime(prev => ({ ...prev, minute: minute }));
      handleScroll("minute", minute);
      return;
    }

    if (amPm) {
      if (amPm === "--") {
        setTime(prev => ({ ...prev, amPm: undefined }));
        return;
      }
      setTime(prev => ({ ...prev, amPm: amPm }));
      return;
    }
  }

  /** If item not found -- scroll to top of the container */
  function handleScroll(type: "hour" | "minute", item: string) {
    if (type === "hour") {
      const index = hourList.findIndex(val => val === ("0" + item).slice(-2));
      scrollAction(hourLiRefs, hourListContainerRef, index ? index - 1 : -1);
    }
    if (type === "minute") {
      const index = minuteList.findIndex(val => val === ("0" + item).slice(-2));
      scrollAction(minuteRefs, minuteListContainerRef, index ? index - 1 : -1);
    }
  }

  return (
    <div className={`time_pick${props.hideMeridiem ? " timer_style" : ""}`}>
      {props.label && <label htmlFor="time_pick-input">{props.label}</label>}
      <div
        id="time_pick-input"
        ref={timePickRef}
        className={`time_pick-input ${props.size ?? ""} ${props.status ?? ""}${focused && !disabled ? " focused" : ""}${
          disabled ? " disabled" : ""
        }`}
        onClick={() => (!disabled ? setFocused(prev => true) : undefined)}
      >
        <div className="time_pick_input-wrap">
          <div className="time_pick_input-wrap_content">
            <TimeElement
              value={time?.hour}
              onChange={value => timeChangeHandlerKeyboard({ hour: value })}
              type="hour"
              disabled={disabled}
              disableMeridiemHour={props.hideMeridiem ? true : false}
            />
            <p>:</p>
            <TimeElement
              value={time?.minute}
              onChange={value => timeChangeHandlerKeyboard({ minute: value })}
              type="minute"
              disabled={disabled}
            />
            <br />
            {!props.hideMeridiem ? (
              <TimeElement
                value={time?.amPm}
                onChange={value => timeChangeHandlerKeyboard({ amPm: value })}
                type="meridiem"
                disabled={disabled}
              />
            ) : null}
          </div>

          {/* Open | Close dropdown if icon is clicked */}
          <div
            onMouseEnter={() => setIconHovered(true)}
            onMouseLeave={() => setIconHovered(false)}
            className={`time_pick_icon-wrap${(iconHovered && !disabled) || dropdownOpen ? " hovered" : ""}`}
          >
            <FontAwesomeIcon
              size="lg"
              icon={["fal", "clock"]}
              className="time_pick_icon-clock"
              onClick={() => (!disabled && !disableClock ? timePickAction() : undefined)}
            />
          </div>
        </div>
      </div>

      {props.helpText && <p className="time_pick-help_text">{props.helpText}</p>}
      <div
        ref={timePickRef}
        className={`time_dropdown${positionTop ? " align_top" : ""}${props.align ? ` align_${props.align}` : ""}`}
      >
        <div className={`time_dropdown-wrap${dropdownOpen ? " open" : ""}`} aria-hidden={!dropdownOpen}>
          <div className="list-collection">
            <ul className="time_dropdown-hour" ref={hourListContainerRef}>
              {hourList.map((hour, index) => {
                const selected = time?.hour === hour ? " selected" : "";
                return (
                  <li
                    key={hour}
                    className={`time_dropdown-cell${selected}`}
                    onClick={e => timeChangeHandlerDropdown({ hour: hour })}
                    ref={hourLiRefs[index]}
                  >
                    <span>{hour}</span>
                  </li>
                );
              })}
            </ul>
            <ul className="time_dropdown-minute" ref={minuteListContainerRef}>
              {minuteList.map((minute, index) => {
                const selected = time?.minute === minute ? " selected" : "";
                return (
                  <li
                    key={minute + "_minute"}
                    className={`time_dropdown-cell${selected}`}
                    onClick={e => timeChangeHandlerDropdown({ minute: minute })}
                    ref={minuteRefs[index]}
                  >
                    <span>{minute}</span>
                  </li>
                );
              })}
            </ul>
            {!props.hideMeridiem ? (
              <ul className="time_dropdown-am_pm">
                {["AM", "PM"].map((value, index) => {
                  const selected = time?.amPm === value ? " selected" : "";
                  return (
                    <li
                      key={value}
                      className={`time_dropdown-cell${selected}`}
                      onClick={e => timeChangeHandlerDropdown({ amPm: value })}
                    >
                      <span>{value}</span>
                    </li>
                  );
                })}
              </ul>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

function generateArray(start: number, increment: number, limit: number) {
  if (isEmpty(start) || isEmpty(increment) || isEmpty(limit)) {
    return;
  }

  const array: Array<string> = [];
  for (let i = start; i < limit; i += increment) {
    array.push(("0" + String(i)).slice(-2)); // Ensure string is length of 2
  }

  return array;
}

/**
 * Calculates the top position of the reflist item and uses scrollTo function to animate the behaviour.
 * @param refList List Item element reference array.
 * @param refContainer Unordered List element to be scrolled within.
 * @param index The index of the ref you want to scroll too.
 */
function scrollAction(
  refList: React.RefObject<HTMLLIElement>[],
  refContainer: React.MutableRefObject<HTMLUListElement>,
  index: number, // < 0 = first item
) {
  if (refList[index] && refList[index].current && refContainer.current) {
    // Item is contained in the list
    const listCenter =
      refList[index].current.offsetTop -
      (refContainer.current.getBoundingClientRect().height - refList[index].current.getBoundingClientRect().height) / 2;

    refContainer.current.scrollTo({ top: listCenter + 9, behavior: "smooth" });
  }

  if (index < 0) {
    refContainer.current.scrollTo({ top: 0, behavior: "smooth" });
    return;
  }
}

/**
 * Populating the first value with a `new Date` time breaks the regex if first 19 minutes of the hour.
 * - Offset the minute element of the time if necessary before testing against regex.
 */
function isValidTime(time: string) {
  const regex = new RegExp(armyTimeRegex);
  const destructured = time.split(":");

  if (destructured.length === 1) {
    return false;
  }

  const minutes = parseInt(destructured[1]);

  if (String(minutes).length === 1) {
    destructured[1] = ("0" + String(minutes)).slice(-2);
  }

  return regex.test(destructured.join(":"));
}
