import React, { useEffect, useState } from "react";

import { StatusCode } from "api/protocols";

import MembershipDisplay from "elements/customer/membership/MembershipDisplay";

import Spin from "components/spin/spin";
import Page from "components/page/Page";

import "./portalMembership.scss";
import Tabs from "components/tabs/Tabs";
import { useAppDispatch } from "hooks/redux";
import { showError } from "redux/actions/ui";
import Callout from "components/callout/Callout";
import axios, { CancelToken } from "axios";
import { ButtonNew as Button } from "components/buttonNew";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GetCustomerMemberships, ICustomerMembership } from "api/rpc/2024-04/customer/membership";

interface IFilter {
  offset: number;
  limit: number;
}

export default function PortalMembership() {
  const [membershipState, setMembershipState] = useState<ICustomerMembership[]>(null);
  const [filter, setFilter] = useState<IFilter>({
    offset: 0,
    limit: 25,
  });
  const activeMemberships = membershipState?.filter(membership => membership?.status === "active");
  const pastMemberships = membershipState?.filter(membership => membership?.status !== "active");
  const dispatch = useAppDispatch();

  useEffect(() => {
    const source = axios.CancelToken.source();
    void getMemberships(source.token);

    return () => {
      source.cancel("Cancelled");
    };
  }, [filter]);

  const membershipTabs = [
    {
      id: "active",
      content: "Active",
    },
    {
      id: "inactive",
      content: "Inactive",
    },
  ];

  const [selectedTab, setSelectedTab] = useState<number>(0);

  async function getMemberships(cancelToken: CancelToken) {
    //Display spinner
    if (membershipState !== null) {
      setMembershipState(null);
    }

    const membershipRes = await GetCustomerMemberships({ ...filter }, false, cancelToken);

    if (membershipRes.status !== StatusCode.OK) {
      if (membershipRes?.message !== "Cancelled") {
        dispatch(showError("Error getting memeberships"));
      }
      return;
    }
    setMembershipState(membershipRes.data);
  }

  const renderMembershipCards = (memberships: ICustomerMembership[]) => {
    return (
      <>
        {memberships ? (
          <>
            {memberships.length > 0 ? (
              memberships.map(membership => {
                return (
                  <div key={membership.id.toString() + "_active"} style={{ marginBottom: "14px" }}>
                    <MembershipDisplay
                      id={membership.membership_id}
                      title={membership.membership_title}
                      type={membership?.membership_type}
                      expiry={membership.expiry}
                      status={membership.status}
                    />
                  </div>
                );
              })
            ) : (
              <Callout type="info" title="No Memberships" content="You do not have any memberships." />
            )}
          </>
        ) : (
          <span style={{ display: "flex", justifyContent: "center" }}>
            <Spin />
          </span>
        )}
      </>
    );
  };

  return (
    <Page title="Memberships">
      <Tabs tabs={membershipTabs} selected={selectedTab} onSelect={setSelectedTab}>
        <div className="portal-memberships-container">
          <div style={{ display: selectedTab === 0 ? "block" : "none", paddingTop: "8px" }}>
            <div>{renderMembershipCards(activeMemberships)}</div>
          </div>

          <div style={{ display: selectedTab === 1 ? "block" : "none", paddingTop: "8px" }}>
            <div>{renderMembershipCards(pastMemberships)}</div>
          </div>
        </div>
        {membershipState && membershipState?.length > 0 && (
          <div className="portal-memberships-footer">
            <Button
              size="small"
              type="secondary"
              onClick={() => setFilter(prev => ({ ...prev, offset: prev.offset - prev.limit }))}
              disabled={!membershipState || !(filter.limit <= filter.offset)}
            >
              <FontAwesomeIcon icon={"arrow-left"} />
              &nbsp; Prev
            </Button>
            <Button
              size="small"
              type="secondary"
              onClick={() => setFilter(prev => ({ ...prev, offset: prev.offset + prev.limit }))}
              disabled={!(membershipState?.length === filter.limit)}
            >
              Next &nbsp;
              <FontAwesomeIcon icon={"arrow-right"} />
            </Button>
          </div>
        )}
      </Tabs>
    </Page>
  );
}
