import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import {
  TCompetitionFormat,
  TCompetitionFormatHoles,
  TCompetitionHandicap,
  TCompetitionOrganization,
  TCompetitionScoreCombo,
  TCompetitionTeamScores,
  TPlayerCompetition,
} from "redux/reducers/models/league";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/league/scoring/format/template";

export type TGetLeagueFormatTemplate = {
  id?: string | number;
  league_id?: string | number;
};

export type TPostLeagueFormatTemplate = {
  league_id: string | number;
  league_division_id: string | number;
  name: string;
  title: string;
  organization: TCompetitionOrganization;
  format: TCompetitionFormat;
  competition: TPlayerCompetition;
  default_participation_points: number;
  holes: TCompetitionFormatHoles;
  handicap: TCompetitionHandicap;
  handicap_allowance?: number; // default = 1
  team_scores?: TCompetitionTeamScores;
  team_scores_best_gross?: number;
  team_scores_best_net?: number;
  team_scores_combination?: TCompetitionScoreCombo;
  custom_points: any;
  leaderboard_data: Array<{
    id: number;
    point_allocation: "custom" | "posted_points" | "posted";
    custom_points?: Array<{ position: number; points: number }> | any;
  }>;
};

export type TPutLeagueFormatTemplate = {
  format_template_id: string | number;
  league_division_id: string | number;
  name: string;
  title: string;
  organization: TCompetitionOrganization;
  format: TCompetitionFormat;
  competition: TPlayerCompetition;
  default_participation_points: number;
  holes: TCompetitionFormatHoles;
  handicap: TCompetitionHandicap;
  handicap_allowance?: number; // default = 1
  team_scores?: TCompetitionTeamScores;
  team_scores_best_gross?: number;
  team_scores_best_net?: number;
  team_scores_combination?: TCompetitionScoreCombo;
  custom_points: any;
  leaderboard_data: Array<{
    id: number;
    point_allocation: "custom" | "posted_points" | "posted";
    custom_points?: Array<{ position: number; points: number }> | any;
  }>;
};

//GET Format
export async function GetLeagueFormatTemplate(
  params: TGetLeagueFormatTemplate,
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseUrl, params, useGlobalLoader, token);
}

// POST Format
export async function PostLeagueFormatTemplate(params: TPostLeagueFormatTemplate, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

//PUT Round
export async function PutLeagueFormatTemplate(params: TPutLeagueFormatTemplate, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

//DELETE Format
export async function DeleteLeagueFormatTemplate(
  params: { format_template_id: string | number },
  useGlobalLoader: boolean,
) {
  return await APIDelete(baseUrl, params, useGlobalLoader);
}
