import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetRegisterGroups } from "api/rpc/2024-04/facilityAdmin/facility/register/group";
import { GetCardReaders } from "api/rpc/2024-04/facilityAdmin/payment/teeonPayments/reader";
import { PostRegister } from "api/rpc/2024-04/facilityAdmin/facility/register/register";

import { showError, showSuccess } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";

import FormLayout from "components/form/FormLayout";
import Form from "components/form/Form";
import Input from "components/form/input/Input";
import { Select } from "components/select/index";
import Page from "components/page/Page";
import Card from "components/card/Card";
import { IRegisterGroup } from "../../Admin";
import Spin from "components/spin/spin";

export default function RegisterNew() {
  const history = useHistory();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [registerGroups, setRegisterGroups] = useState<IRegisterGroup[]>([]);
  const [cardReaders, setCardReaders] = useState<any[]>([]);

  const [registerState, setRegisterState] = useState({
    register_group_id: null,
    default_card_reader_id: null,
    title: "",
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadRegisterGroups(source.token);
    void loadReaders(source.token);
    return () => source.cancel();
  }, []);

  async function loadRegisterGroups(token?: CancelToken) {
    if (registerGroups !== undefined) {
      setRegisterGroups(undefined);
    }

    const res = await GetRegisterGroups(undefined, token ? false : true, token);

    if (res.status !== StatusCode.OK) {
      setRegisterGroups([]);
      return;
    }

    setRegisterGroups(res.data);
  }

  async function loadReaders(token?: CancelToken) {
    if (cardReaders !== undefined) {
      setCardReaders(undefined);
    }
    const res = await GetCardReaders(undefined, token ? false : true, token);
    if (res.status !== StatusCode.OK) {
      setCardReaders([]);
      return;
    }

    setCardReaders(res.data);
  }

  async function createRegister() {
    const res = await PostRegister(registerState, true);

    if (res.status !== StatusCode.OK) {
      dispatch(
        showError(typeof res.data === "string" ? res.data : t("secure.facility.settings.register.register_new.011")),
      );
      return;
    }

    dispatch(
      showSuccess(typeof res.data === "string" ? res.data : t("secure.facility.settings.register.register_new.012")),
    );
    history.push("/admin/settings/registers");
  }

  const primaryAction = {
    content: t("secure.facility.settings.register.register_new.001"),
    action: createRegister,
  };

  return (
    <Page
      title={t("secure.facility.settings.register.register_new.002")}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.register.register_new.004"),
          url: "/admin/settings/registers",
        },
      ]}
    >
      <Card>
        <Card.Section>
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={registerState.title}
                  label={t("secure.facility.settings.register.register_new.005")}
                  id="title"
                  onChange={e => setRegisterState(prev => ({ ...prev, title: e.target.value }))}
                  placeholder={t("secure.facility.settings.register.register_new.006")}
                />
                <Select
                  label={t("secure.facility.settings.register.register_new.007")}
                  className={`flex justify-center align-center w-full h-10 position-relative z-20 text-black font-medium appearance-none border-none focus:outline-none placeholder-gray-200`}
                  placeholder={t("secure.facility.settings.register.register_new.008")}
                  onChange={(value: number) => setRegisterState(prev => ({ ...prev, register_group_id: value }))}
                >
                  {registerGroups === undefined ? (
                    <Select.Option value={"loading"} disabled>
                      <div style={{ height: "40px", padding: "8px 16px" }}>
                        <Spin />
                      </div>
                    </Select.Option>
                  ) : (
                    registerGroups.map(group => (
                      <Select.Option key={group.id} value={group.id}>
                        {group.title}
                      </Select.Option>
                    ))
                  )}
                </Select>
                <Select
                  label={t("secure.facility.settings.register.register_new.009")}
                  className={`w-full h-10 position-relative z-20 text-black font-medium appearance-none border-none focus:outline-none placeholder-gray-200`}
                  placeholder={t("secure.facility.settings.register.register_new.010")}
                  onChange={(value: any) => setRegisterState(prev => ({ ...prev, default_card_reader_id: value }))}
                >
                  {cardReaders === undefined ? (
                    <Select.Option value={"loading"} disabled>
                      <div style={{ height: "40px", padding: "8px 16px" }}>
                        <Spin />
                      </div>
                    </Select.Option>
                  ) : (
                    cardReaders.map((reader: any) => (
                      <Select.Option key={reader.id} value={reader.id}>
                        {reader.label}
                      </Select.Option>
                    ))
                  )}
                </Select>
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
      </Card>
    </Page>
  );
}
