import React, { useEffect, useState } from "react";
import axios, { CancelToken } from "axios";
import { useTranslation } from "react-i18next";
import { isEqualWith, isNull } from "lodash";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { StatusCode } from "api/protocols";
import {
  DeleteCustomerAddressLine,
  PostCustomerAddressLine,
  PutCustomerAddressLine,
} from "api/rpc/facilityAdmin/customer";
import { GetCountry } from "api/rpc/2022-09/common/country/country";
import { GetCustomerType } from "api/rpc/2022-09/facilityAdmin/customer/type";
import { DeleteCustomer, SendPasswordReset, SendWelcomeEmail } from "api/rpc/2022-09/facilityAdmin/customer/customer";
import {
  GetCustomer,
  PutCustomer,
  PutCustomerPreferences,
  PutMergeCustomers,
} from "api/rpc/2024-04/facilityAdmin/customer/customer";

import { showError, showSuccess } from "redux/actions/ui";
import { IAddress, ICustomer, ICustomerPreferences } from "redux/reducers/models/customer";

import { useAppDispatch, useAppSelector } from "hooks/redux";

import { useCustomerContext } from "../../context/CustomerContext";
import Sheet from "components/sheet/Sheet";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import { Select } from "components/select/index";
import { ButtonNew as Button } from "components/buttonNew";
import { Badge } from "components/badge/Badge";
import Popup from "components/popup/Popup";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import CustomerAddressModal, { IAddressLineState } from "elements/customer/CustomerAddressModal";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { MOBILE_WIDTH } from "helpers/ScreenSizes";
import PreferenceCard from "components/preferenceCard/PreferenceCard";
import "./CustomerProfile.scss";
import ReactDOM from "react-dom";
import Page from "components/page/Page";
import Card from "components/card/Card";
import GolferCard from "components/bookingPopUp/golferCard/GolferCard";
import Divider from "components/divider";

interface IMergeState {
  //Merge Modal
  showMergeModal: boolean;
  selectedCustomer: ICustomer;
  customerSearchResults: Array<ICustomer>;
  customerSearchQuery: string;
  customerSearching: boolean;

  //Warning Popup
  showWarning: boolean;
}

export default function CustomerProfile() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { Option } = Select;

  const windowSize = useWindowSize();

  const { authStore } = useAppSelector(store => store);
  const dispatch = useAppDispatch();

  const { state, updateState, loadCustomer } = useCustomerContext(); // changes seen across customer context

  const [deleteCustomerConfirmation, setDeleteCustomerConfirmation] = useState(false);
  const [deleteAddressConfirmation, setDeleteAddressConfirmation] = useState({
    visible: false,
    address: null,
  });
  const [resetPasswordVisible, setResetPasswordVisible] = useState(false);
  const [addressModalOpen, setAddressModalOpen] = useState(false);
  const [customerTypesOpen, setCustomerTypesOpen] = useState(false);
  const [cartTypesOpen, setCartTypesOpen] = useState(false);
  const [welcomeEmailVisible, setWelcomeEmailVisible] = useState<boolean>(false);
  const [preferencesVisible, setPreferencesVisible] = useState<boolean>(false);
  const [prevPreferences, setPrevPreferences] = useState<ICustomerPreferences>(undefined); // Default preferences on load

  const notificationOptions = ["None", "In-app", "Email"];

  const [mergeState, setMergeState] = useState<IMergeState>({
    showMergeModal: false,
    selectedCustomer: null,
    customerSearchResults: [],
    customerSearchQuery: "",
    customerSearching: false,

    showWarning: false,
  });

  // Populate Profile Dropdowns
  useEffect(() => {
    const source = axios.CancelToken.source();

    if (state.countries === null) {
      void GetCountries(source.token);
    }

    if (state.customerTypes === null) {
      void GetCustomerTypes(source.token);
    }

    if (state.cartTypes === null) {
      void GetCustomerCartTypes(source.token);
    }

    if (state.preferences) {
      setPrevPreferences({ ...state.preferences });
    }

    // Reset inputs when tab changes
    if (JSON.stringify(state.customer) != JSON.stringify(state.editState)) {
      updateState({ editState: { ...state.customer } });
    }

    return () => {
      source.cancel();
    };
  }, [state.customer.id]);

  // Save EditState and update context state
  async function saveCustomer() {
    const customerRes = await PutCustomer(
      {
        id: state.editState.id,
        first_name: state.editState.first_name,
        last_name: state.editState.last_name,
        email: state.editState.email,
        phone: state.editState.phone,
        customer_type_id: state.editState.customer_type_id,
        cart_customer_type_id: state.editState.cart_customer_type_id,
        member_code: state.editState.member_code,
        username: state.editState.username,
        birth_date: state.editState.birth_date,
        bag_number: state.editState.bag_number,
      },
      true,
    );

    if (
      !isEqualWith(state.customer?.address_lines, state.editState?.address_lines, (originalValue, newValue) => {
        if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
          return true;
        }
      })
    ) {
      for (const addressLine of state.editState?.address_lines) {
        const addressLineRes = await PutCustomerAddressLine(
          {
            id: addressLine?.id,
            customer_id: state.customer?.id,
            country_id: addressLine?.country_id,
            province_id: addressLine?.province_id,
            city: addressLine?.city,
            default: addressLine?.default,
            address_line_1: addressLine?.address_line_1,
            address_line_2: addressLine?.address_line_2,
            postal: addressLine?.postal,
            phone: addressLine?.phone,
          },
          true,
        );
        if (addressLineRes.status !== StatusCode.OK) {
          dispatch(showError(t("secure.facility.customer.tabs.profile.Customer_Profile.047")));
          return;
        }
      }
    }

    if (customerRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.customer.tabs.profile.Customer_Profile.048")));
      return;
    }

    dispatch(showSuccess(t("secure.facility.customer.tabs.profile.Customer_Profile.049")));

    updateState({
      customer: { ...state.customer, ...customerRes.data },
      editState: { ...state.editState, ...customerRes.data },
    });
  }

  /** Called after POPUP to delete the customer and navigate to /admin/customers */
  async function deleteCustomer(customerId: number) {
    const deleteRes = await DeleteCustomer({ id: customerId }, true);

    if (deleteRes.status !== StatusCode.OK) {
      dispatch(showSuccess(t("secure.facility.customer.tabs.profile.Customer_Profile.050")));
      return;
    }

    setDeleteCustomerConfirmation(false);
    dispatch(showSuccess(t("secure.facility.customer.tabs.profile.Customer_Profile.051")));
    history.push("/admin/customers");
  }

  /** Called after POPUP to reset customer password */
  async function sendPasswordReset() {
    const params = {
      email: state.customer.email,
    };

    const sendPasswordResetRes = await SendPasswordReset(params, true);

    if (sendPasswordResetRes?.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.customer.tabs.profile.Customer_Profile.043")));
      return;
    }

    setResetPasswordVisible(false);
    dispatch(showSuccess(t("secure.facility.customer.tabs.profile.Customer_Profile.044")));
  }

  async function GetCountries(token: CancelToken) {
    const countryRes = await GetCountry({ provinces: true }, false, token);

    if (token.reason) {
      return;
    }

    if (countryRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.customer.tabs.profile.Customer_Profile.052")));
      return;
    }

    updateState({ countries: countryRes.data });
  }

  async function GetCustomerTypes(token: CancelToken) {
    const typesRes = await GetCustomerType(
      {
        application: "green_fee",
      },
      false,
      token,
    );

    if (token.reason) {
      return;
    }

    if (typesRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.customer.tabs.profile.Customer_Profile.053")));
      return;
    }

    updateState({ customerTypes: typesRes.data });
  }

  async function GetCustomerCartTypes(token: CancelToken) {
    const typesRes = await GetCustomerType(
      {
        application: "power_cart",
      },
      false,
      token,
    );

    if (token.reason) {
      return;
    }

    if (typesRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.customer.tabs.profile.Customer_Profile.054")));
      return;
    }

    updateState({ cartTypes: typesRes.data });
  }

  async function addAddressLine(addressLineState: IAddressLineState) {
    const addressLineRes = await PostCustomerAddressLine(
      {
        customer_id: state.customer.id,
        country_id: addressLineState?.country_id,
        province_id: addressLineState?.province_id,
        city: addressLineState?.city,
        default: addressLineState?.default,
        address_line_1: addressLineState?.addressLine1,
        address_line_2: addressLineState?.addressLine2,
        postal: addressLineState?.postal,
        phone: addressLineState?.phone,
      },
      true,
    );
    if (addressLineRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.customer.tabs.profile.Customer_Profile.055")));
      return;
    }

    dispatch(showSuccess(t("secure.facility.customer.tabs.profile.Customer_Profile.056")));

    updateState({ editState: { ...state.editState, ...addressLineRes.data } });
    setAddressModalOpen(false);
  }

  async function handleDefaultAddress(addressLine: IAddress) {
    const addressLineRes = await PutCustomerAddressLine(
      {
        id: addressLine?.id,
        customer_id: state.customer?.id,
        country_id: addressLine?.country_id,
        province_id: addressLine?.province_id,
        city: addressLine?.city,
        default: !addressLine?.default,
        address_line_1: addressLine?.address_line_1,
        address_line_2: addressLine?.address_line_2,
        postal: addressLine?.postal,
        phone: addressLine?.phone,
      },
      true,
    );
    if (addressLineRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.customer.tabs.profile.Customer_Profile.057")));
      return;
    }

    updateState({
      customer: { ...state.customer, ...addressLineRes.data },
      editState: { ...state.editState, ...addressLineRes.data },
    }); // Update UI
  }

  async function deleteCustomerAddress(addressLine: IAddress) {
    const res = await DeleteCustomerAddressLine(
      { customer_id: state.customer.id, address_line_id: addressLine.id },
      true,
    );

    if (res.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.customer.tabs.profile.Customer_Profile.058")));
      return;
    }

    updateState({
      customer: { ...state.customer, ...res.data },
      editState: { ...state.editState, ...res.data },
    });

    dispatch(showSuccess(t("secure.facility.customer.tabs.profile.Customer_Profile.059")));
    setDeleteAddressConfirmation({ visible: false, address: null });
  }

  function addressLineInputHandler(event: React.ChangeEvent<HTMLInputElement>, index: number) {
    const { id, value } = event.target;
    const updatedAddressLines = state.editState.address_lines ? [...state.editState.address_lines] : [];
    updatedAddressLines[index] = { ...updatedAddressLines[index], [id]: value };

    updateState({
      editState: {
        ...state.editState,
        address_lines: updatedAddressLines,
      },
    });
  }

  function addressLineDropdownChange(value: number, id: string, index: number) {
    const updatedAddressLines = state.editState.address_lines ? [...state.editState.address_lines] : [];
    updatedAddressLines[index] = { ...updatedAddressLines[index], [id]: value };

    updateState({
      editState: {
        ...state.editState,
        address_lines: updatedAddressLines,
      },
    });
  }

  function inputHandler(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;
    updateState({ editState: { ...state.editState, [id]: value } });
  }

  function renderSaveChanges(screenSize: "desktop" | "mobile") {
    return (
      <Button type="primary" onClick={saveCustomer} className={`customer-profile-save-changes-${screenSize}`}>
        {t("secure.facility.customer.tabs.profile.Customer_Profile.002")}
      </Button>
    );
  }

  function renderCustomerPreferences(screenSize: "desktop" | "mobile") {
    if (!state.preferences) {
      return undefined;
    }
    return (
      <Button
        type="secondary"
        onClick={() => setPreferencesVisible(true)}
        className={`customer-profile-save-changes-${screenSize}`}
      >
        {"Customer Preferences"}
      </Button>
    );
  }

  function renderAddAddress(screenSize: "desktop" | "mobile") {
    const isDesktop = windowSize.width > MOBILE_WIDTH;

    const addAddress = (
      <Button
        className={`customer-profile-action customer-profile-add-address-${screenSize}`}
        size="medium"
        type="link"
        onClick={() => setAddressModalOpen(true)}
      >
        {t("secure.facility.customer.tabs.profile.Customer_Profile.028")}
      </Button>
    );

    if (screenSize === "desktop" && isDesktop) {
      return <>{addAddress}</>;
    } else if (screenSize === "mobile" && !isDesktop) {
      return <FormLayout.Group>{addAddress}</FormLayout.Group>;
    } else {
      return <></>;
    }
  }

  function handleDateChange(selectedDate: Date) {
    const updatedState = {
      ...state.editState,
      birth_date: selectedDate,
    };

    updateState({ editState: updatedState });
  }

  async function handleSendWelcomeEmail() {
    const sendWelcomeRes = await SendWelcomeEmail({ email: state.editState.email }, true);

    if (sendWelcomeRes.status !== StatusCode.OK) {
      dispatch(showError("Error sending welcome email"));
      return;
    }

    setWelcomeEmailVisible(false);
  }

  async function handleSavePreferences() {
    const preferencesRes = await PutCustomerPreferences(
      {
        ...state.preferences,
      },
      true,
    );

    if (preferencesRes.status !== StatusCode.OK) {
      dispatch(showError("Error saving preferences"));
      return;
    }

    ReactDOM.unstable_batchedUpdates(() => {
      setPreferencesVisible(false);
      setPrevPreferences({ ...state.preferences });
    });
    dispatch(showSuccess("Preferences saved successfully"));
  }

  function handleToggleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, checked } = event.target;
    updateState({ preferences: { ...state.preferences, [id]: checked } });
  }

  function closeMergeAccounts() {
    setMergeState(prevState => ({
      ...prevState,
      showMergeModal: false,
      customerSearchQuery: "",
      customerSearchResults: [],
      selectedCustomer: null,
      showWarning: false,
    }));
  }

  function handleCustomerSearch(query: string) {
    setMergeState(prevState => ({ ...prevState, customerSearchQuery: query }));
  }

  function handleCustomerSelection(customer: ICustomer) {
    setMergeState(prevState => ({ ...prevState, selectedCustomer: customer }));
  }

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const source = axios.CancelToken.source();
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (async () => {
          if (mergeState.customerSearchQuery !== "") {
            try {
              setMergeState(prevState => ({ ...prevState, customerSearching: true }));
              const customers = await searchCustomer(source.token);
              if (mounted) {
                setMergeState(prevState => ({
                  ...prevState,
                  customerSearchResults: customers,
                  customerSearching: false,
                }));
              }
            } catch (error) {
              console.log("err", error);
            }
            return;
          } else {
            setMergeState(prevState => ({ ...prevState, customerSearchResults: [], customerSearching: false }));
          }
        })();
      }, 1000);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      source.cancel();
    };
  }, [mergeState.customerSearchQuery]);

  async function searchCustomer(cancelToken: CancelToken) {
    const customerRes = await GetCustomer({ search: mergeState.customerSearchQuery }, false, cancelToken);
    if (customerRes.status !== StatusCode.OK) {
      return [];
    }
    const filteredCustomers = customerRes?.data?.filter((customer: ICustomer) => customer?.id !== state?.customer?.id);
    return filteredCustomers as Array<ICustomer>;
  }

  async function handleCustomerMerge() {
    const mergeRes = await PutMergeCustomers(
      { parent_id: state?.customer?.id, child_id: mergeState?.selectedCustomer?.id },
      true,
    );
    if (mergeRes?.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.customer.main.customer.001")));
      return;
    }
    dispatch(showSuccess(t("secure.facility.customer.main.customer.002")));
    void closeMergeAccounts();
    await loadCustomer(state?.customer?.id);
  }

  return (
    <>
      <Page splitLayout>
        <Page.Section twoThirds>
          <div className="mb-5">
            <span className="flex mt-2">
              <h1 className="ui-customer_page-header">
                {t("secure.facility.customer.tabs.profile.Customer_Profile.001")}
              </h1>
              {renderCustomerPreferences("desktop")}
              {renderSaveChanges("desktop")}
            </span>
            <hr className="customer-profile-header-divider" />
            <Form>
              <FormLayout>
                <FormLayout.Group>
                  <Input
                    value={state.editState.first_name || ""}
                    label={t("secure.facility.customer.tabs.profile.Customer_Profile.003")}
                    id="first_name"
                    onChange={inputHandler}
                  />
                  <Input
                    value={state.editState.last_name || ""}
                    label={t("secure.facility.customer.tabs.profile.Customer_Profile.004")}
                    id="last_name"
                    onChange={inputHandler}
                  />
                </FormLayout.Group>

                <FormLayout.Group>
                  <Input
                    value={state.editState.phone || ""}
                    label={t("secure.facility.customer.tabs.profile.Customer_Profile.005")}
                    id="phone"
                    onChange={inputHandler}
                  />
                  <Input
                    value={state.editState.email || ""}
                    label={t("secure.facility.customer.tabs.profile.Customer_Profile.006")}
                    id="email"
                    onChange={inputHandler}
                  />
                </FormLayout.Group>

                <div className="customer-profile-mobile-adaptive-fields">
                  <Input
                    value={state.editState?.username || ""}
                    label={t("secure.facility.customer.tabs.profile.Customer_Profile.040")}
                    id="username"
                    onChange={inputHandler}
                  />
                  <div className="password-input">
                    <Input
                      value={"**********"}
                      type="password"
                      autoComplete="off"
                      label={t("secure.facility.customer.tabs.profile.Customer_Profile.041")}
                      disabled={true}
                      className="password-input-input"
                    />
                  </div>
                </div>

                {renderAddAddress("mobile")}

                {/* <div className="customer-profile-mobile-adaptive-fields-triple">
                  {!customerTypesOpen ? (
                    <div className="customer-type-group">
                      <span className="text-sm text-semibold">
                        {t("secure.facility.customer.tabs.profile.Customer_Profile.007")}
                      </span>
                      <div
                        style={{ borderRadius: "8px", border: "1px solid #D0D5DD" }}
                        className="customer-type-group-content"
                      >
                        {state?.customer?.customer_type ? (
                          <span>{state.customer?.customer_type}</span>
                        ) : (
                          <span>{t("secure.facility.customer.tabs.profile.Customer_Profile.008")}</span>
                        )}
                        {!!authStore?.user?.permissions?.user_edit_customer_type && (
                          <FontAwesomeIcon
                            icon={["far", "pen"]}
                            className="customer-type-group-content-edit-icon"
                            onClick={() => setCustomerTypesOpen(!customerTypesOpen)}
                          />
                        )}
                      </div>
                    </div>
                  ) : (
                    <Select
                      label={t("secure.facility.customer.tabs.profile.Customer_Profile.010")}
                      onChange={(value: number) =>
                        updateState({ editState: { ...state.editState, customer_type_id: value } })
                      }
                      defaultValue={state.customer?.customer_type_id}
                      disabled={!authStore?.user?.permissions?.user_edit_customer_type ? true : false}
                    >
                      {state?.customerTypes?.map((type, index) => {
                        return (
                          <Option key={index} value={type.id}>
                            {type.full_title}
                          </Option>
                        );
                      })}
                    </Select>
                  )}

                  {!cartTypesOpen ? (
                    <div className="customer-type-group">
                      <span className="text-sm text-semibold">
                        {t("secure.facility.customer.tabs.profile.Customer_Profile.011")}
                      </span>
                      <div
                        style={{ borderRadius: "8px", border: "1px solid #D0D5DD" }}
                        className="customer-type-group-content"
                      >
                        {state.customer?.cart_customer_type ? (
                          <span>{state.customer?.cart_customer_type}</span>
                        ) : (
                          <span>{t("secure.facility.customer.tabs.profile.Customer_Profile.069")}</span>
                        )}
                        {!!authStore?.user?.permissions?.user_edit_customer_type && (
                          <FontAwesomeIcon
                            icon={["far", "pen"]}
                            className="customer-type-group-content-edit-icon"
                            onClick={() => setCartTypesOpen(!cartTypesOpen)}
                          />
                        )}
                      </div>
                    </div>
                  ) : (
                    <Select
                      label={t("secure.facility.customer.tabs.profile.Customer_Profile.014")}
                      onChange={(value: number) =>
                        updateState({ editState: { ...state.editState, cart_customer_type_id: value } })
                      }
                      defaultValue={state.customer?.cart_customer_type_id}
                      disabled={!authStore?.user?.permissions?.user_edit_customer_type ? true : false}
                    >
                      {state?.cartTypes.map((type, index) => {
                        return (
                          <Option key={index} value={type.id}>
                            {type.full_title}
                          </Option>
                        );
                      })}
                    </Select>
                  )}

                  {state.editState?.birth_date ? (
                    <div className="birthday-input">
                      <DatePickerInput
                        months={1}
                        position={windowSize.width < MOBILE_WIDTH ? "left" : "right"}
                        label={t("secure.facility.customer.tabs.profile.Customer_Profile.070")}
                        startingDate={new Date(state.editState?.birth_date as unknown as string)}
                        setStartingDate={(e: Date) => handleDateChange(e)}
                      />
                      <div className="birthday-cancel">
                        <FontAwesomeIcon
                          className="birthday-cancel-icon"
                          icon={["far", "times"]}
                          onClick={() => updateState({ editState: { ...state.editState, birth_date: null } })}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="birthday-toggle">
                      <span>
                        <Button
                          onClick={() =>
                            updateState({
                              editState: { ...state.editState, birth_date: state.customer.birth_date ?? new Date() },
                            })
                          }
                        >
                          {t("secure.facility.customer.tabs.profile.Customer_Profile.071")}
                        </Button>
                      </span>
                    </div>
                  )}
                </div>

                <FormLayout.Group>
                  <Input
                    value={state.editState.member_code || ""}
                    label={t("secure.facility.customer.tabs.profile.Customer_Profile.015")}
                    id="member_code"
                    onChange={inputHandler}
                    placeholder=""
                  />

                  <Input
                    label={t("secure.facility.customer.tabs.profile.Customer_Profile.016")}
                    id="bag_number"
                    placeholder="513"
                    onChange={inputHandler}
                    value={state.editState.bag_number || ""}
                  />

                  <Input
                    label={t("secure.facility.customer.tabs.profile.Customer_Profile.017")}
                    id="locker_number"
                    placeholder="628"
                  />
                </FormLayout.Group>
                */}
              </FormLayout>
            </Form>

            {state.editState?.address_lines?.map((addressLine, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="customer-default-address-container">
                    <div>
                      <h1 style={{ display: "inline" }} className="ui-customer_page-header">{`Address ${
                        index + 1
                      }`}</h1>
                      {addressLine?.default && (
                        <Badge size="medium" type="gray">
                          {t("secure.facility.customer.tabs.profile.Customer_Profile.018")}
                        </Badge>
                      )}
                    </div>
                    <div className="ui-customer_address-actions">
                      <div>
                        <Button size="medium" type="link" onClick={() => handleDefaultAddress(addressLine)}>
                          {!addressLine?.default
                            ? t("secure.facility.customer.tabs.profile.Customer_Profile.019")
                            : t("secure.facility.customer.tabs.profile.Customer_Profile.020")}
                        </Button>
                      </div>
                      <Button
                        size="medium"
                        type="link"
                        onClick={() => setDeleteAddressConfirmation({ visible: true, address: addressLine })}
                      >
                        {t("secure.facility.customer.tabs.profile.Customer_Profile.062")}
                      </Button>
                    </div>
                  </div>

                  <Form>
                    <FormLayout>
                      <FormLayout.Group>
                        <Input
                          value={addressLine?.address_line_1 || ""}
                          label={t("secure.facility.customer.tabs.profile.Customer_Profile.021")}
                          id="address_line_1"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            addressLineInputHandler(event, index)
                          }
                        />

                        <Input
                          value={addressLine?.address_line_2 || ""}
                          label={t("secure.facility.customer.tabs.profile.Customer_Profile.022")}
                          id="address_line_2"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            addressLineInputHandler(event, index)
                          }
                        />
                      </FormLayout.Group>

                      <FormLayout.Group>
                        <Input
                          value={addressLine?.city || ""}
                          label={t("secure.facility.customer.tabs.profile.Customer_Profile.023")}
                          id="city"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            addressLineInputHandler(event, index)
                          }
                        />

                        <Input
                          value={addressLine?.postal || ""}
                          label={t("secure.facility.customer.tabs.profile.Customer_Profile.024")}
                          id="postal"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            addressLineInputHandler(event, index)
                          }
                        />

                        <Input
                          value={addressLine?.phone || ""}
                          label={t("secure.facility.customer.tabs.profile.Customer_Profile.025")}
                          id="address_phone"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            addressLineInputHandler(event, index)
                          }
                        />
                      </FormLayout.Group>

                      <FormLayout.Group>
                        {state.countries && (
                          <Select
                            label={t("secure.facility.customer.tabs.profile.Customer_Profile.026")}
                            onChange={(value: number) => addressLineDropdownChange(value, "country_id", index)}
                            defaultValue={addressLine?.country_id}
                          >
                            {state?.countries?.map((country, index) => {
                              return (
                                <Option key={index} value={country?.id} name={country?.name}>
                                  {country?.name}
                                </Option>
                              );
                            })}
                          </Select>
                        )}

                        {state?.countries?.some(country => country?.id === addressLine?.country_id) && (
                          <Select
                            label={t("secure.facility.customer.tabs.profile.Customer_Profile.027")}
                            onChange={(value: number) => addressLineDropdownChange(value, "province_id", index)}
                            defaultValue={addressLine?.province_id}
                          >
                            {state.countries
                              ?.find(country => country?.id === addressLine?.country_id)
                              ?.provinces?.map((province: Record<string, any>, index: number) => {
                                return (
                                  <Option key={index} value={province?.id} name={province?.name}>
                                    {province?.name}
                                  </Option>
                                );
                              })}
                          </Select>
                        )}
                      </FormLayout.Group>
                    </FormLayout>
                  </Form>
                </React.Fragment>
              );
            })}

            <div className="customer-profile-actions">
              <div>{renderAddAddress("desktop")}</div>
            </div>

            <div className="customer-profile-delete-customer">{renderCustomerPreferences("mobile")}</div>
            <div className="customer-profile-delete-customer">{renderSaveChanges("mobile")}</div>
            <div className="customer-profile-delete-customer">
              <Button type="secondary" onClick={() => setDeleteCustomerConfirmation(true)}>
                {t("secure.facility.customer.tabs.profile.Customer_Profile.063")}
              </Button>
            </div>
          </div>
        </Page.Section>

        <Page.Section oneThird>
          <div className="ml-5">
            <Card>
              <Card.Section>
                <div className="w-full">
                  <div className="w-full">
                    <p className="customer-profile-right-side-button-text">Welcome Email</p>
                    <Button size="small" block onClick={() => setWelcomeEmailVisible(true)}>
                      {/* <FontAwesomeIcon className="mr-2" icon={["far", "paper-plane"]} /> */}
                      {"Send"}
                    </Button>
                    <p className="customer-profile-right-side-button-help-text">Send welcome email to customer</p>
                  </div>

                  <div className="w-full">
                    <Divider />
                    <p className="customer-profile-right-side-button-text">Password Reset</p>
                    <Button size="small" block onClick={() => setResetPasswordVisible(true)}>
                      {"Send"}
                    </Button>
                    <p className="customer-profile-right-side-button-help-text">
                      Send password reset request to customer
                    </p>
                  </div>

                  <div className="w-full">
                    {authStore?.user?.permissions?.merge_customers ? (
                      <div>
                        <Divider />

                        <p className="customer-profile-right-side-button-text">Merge Customer</p>

                        <Button
                          size="small"
                          block
                          onClick={() => setMergeState(prevState => ({ ...prevState, showMergeModal: true }))}
                        >
                          {"Merge"}
                        </Button>

                        <p className="customer-profile-right-side-button-help-text">Merge one customer into another</p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </Card.Section>
            </Card>
          </div>
        </Page.Section>
      </Page>

      {addressModalOpen && (
        <CustomerAddressModal
          isVisible={addressModalOpen}
          countries={state.countries}
          onCancel={() => setAddressModalOpen(false)}
          onOk={addressLine => addAddressLine(addressLine)}
        />
      )}

      {/* DELETE CUSTOMER POPUP */}
      <Popup
        open={deleteCustomerConfirmation}
        description={`${t("secure.facility.customer.tabs.profile.Customer_Profile.064")} ${state.customer?.full_name}?`}
        title={t("secure.facility.customer.tabs.profile.Customer_Profile.065")}
        type="warning"
        onCancel={() => setDeleteCustomerConfirmation(false)}
        onOk={() => deleteCustomer(state.customer.id)}
      />

      {/* RESET PASSWORD POPUP */}
      <Popup
        open={resetPasswordVisible}
        description={`${t("secure.facility.customer.tabs.profile.Customer_Profile.045")}`}
        title={t("secure.facility.customer.tabs.profile.Customer_Profile.046")}
        type="info"
        onCancel={() => setResetPasswordVisible(false)}
        onOk={sendPasswordReset}
      />

      {/* DELETE ADDRESS POPUP */}
      <Popup
        open={deleteAddressConfirmation.visible}
        description={`${t("secure.facility.customer.tabs.profile.Customer_Profile.066")} ${
          state.customer.full_name
        } ${t("secure.facility.customer.tabs.profile.Customer_Profile.067")}`}
        title={t("secure.facility.customer.tabs.profile.Customer_Profile.068")}
        type="warning"
        onCancel={() => setDeleteAddressConfirmation({ visible: false, address: null })}
        onOk={() => deleteCustomerAddress(deleteAddressConfirmation.address)}
      />

      {/* SEND WELCOME EMAIL POPUP */}

      <Popup
        open={welcomeEmailVisible}
        description={t("secure.facility.customer.tabs.profile.Customer_Profile.073")}
        title={t("secure.facility.customer.tabs.profile.Customer_Profile.074")}
        type="info"
        onCancel={() => setWelcomeEmailVisible(false)}
        onOk={handleSendWelcomeEmail}
      />

      {/* CUSTOMER PREFERENCES SHEET */}
      <Sheet
        open={preferencesVisible}
        size="medium"
        closable
        title={"Customer Preferences"}
        onCancel={() => {
          setPreferencesVisible(false);
          updateState({ preferences: prevPreferences ? prevPreferences : state.preferences });
        }}
        onOk={handleSavePreferences}
        okText="Save"
        overflow
      >
        <div>
          <div>
            <span className="portal-preferences-header">Notifications Settings</span>
            <p className="text-subdued">
              {
                "We may still send you important notifications about your account outside of your normal notification settings"
              }
            </p>
          </div>
          <div className="portal-preferences-divider" />
          <PreferenceCard
            title="Marketing Notifications"
            description="Information about upcoming promotions"
            handleToggleChange={handleToggleChange}
            options={[
              {
                label: "Email",
                id: "marketing_email",
                checked: state.preferences?.marketing_email,
              },
            ]}
          />
          <PreferenceCard
            title="Booking Confirmations"
            description="Notifications when you create a new booking"
            handleToggleChange={handleToggleChange}
            options={[
              {
                label: "Email",
                id: "booking_confirmation_email",
                checked: state.preferences?.booking_confirmation_email,
              },
            ]}
          />
          <PreferenceCard
            title="Booking Reminders"
            description="Notifications when you have an upcoming booking"
            handleToggleChange={handleToggleChange}
            options={[
              {
                label: "Email",
                id: "booking_reminder_email",
                checked: state.preferences?.booking_reminder_email,
              },
            ]}
          />
          <PreferenceCard
            title="Booking Updates"
            description="Notifications when you update an upcoming booking"
            handleToggleChange={handleToggleChange}
            options={[
              {
                label: "Email",
                id: "booking_update_email",
                checked: state.preferences?.booking_update_email,
              },
            ]}
          />
          <PreferenceCard
            title="Booking Cancellations"
            description="Notifications when you cancel an upcoming booking"
            handleToggleChange={handleToggleChange}
            options={[
              {
                label: "Email",
                id: "booking_cancellation_email",
                checked: state.preferences?.booking_cancellation_email,
              },
            ]}
          />
        </div>
      </Sheet>

      <Sheet
        title={t("secure.facility.customer.main.customer.008")}
        open={mergeState.showMergeModal}
        size="small"
        closable
        onCancel={closeMergeAccounts}
        onOk={() => setMergeState(prevState => ({ ...prevState, showWarning: true }))}
        okText={t("secure.facility.customer.main.customer.009")}
        okDisabled={!mergeState.selectedCustomer ? true : false}
        overflow
      >
        <span className="merge-customer-title">{t("secure.facility.customer.main.customer.010")}</span>
        {mergeState.selectedCustomer ? (
          <GolferCard
            closable
            removeGolfer={() =>
              setMergeState(prevState => ({
                ...prevState,
                selectedCustomer: null,
                customerSearchResults: [],
                customerSearchQuery: "",
              }))
            }
            email={mergeState?.selectedCustomer?.email}
            name={mergeState?.selectedCustomer?.full_name}
            memberCode={mergeState?.selectedCustomer?.member_code}
            customerType={mergeState?.selectedCustomer?.customer_type}
            phone={mergeState?.selectedCustomer?.phone}
          />
        ) : (
          <>
            <Select
              showSearch
              onSearch={(query: string) => handleCustomerSearch(query)}
              onChange={(id: number, customer: ICustomer) => handleCustomerSelection(customer)}
              placeholder={t("secure.facility.customer.main.customer.011")}
              allowClear
              searchValue={mergeState.customerSearchQuery}
              showDropDownOnFocus={true}
              searching={mergeState.customerSearching}
              noData={
                <div className="ui-select-dropdown-list-item">
                  <span>{t("secure.facility.customer.main.customer.012")}</span>
                  <FontAwesomeIcon style={{ marginLeft: "5px" }} icon={["far", "users-slash"]} size="1x" />
                </div>
              }
            >
              {mergeState.customerSearchResults?.map((customer, index) => {
                return (
                  <Option key={index} value={customer.id} name={customer.full_name} extraValues={customer}>
                    <div className="flex justify-between">
                      <div>
                        <div className="text-semibold text-lg">{customer?.full_name}</div>
                        <div className="text-sm text-gray-500">{customer.customer_type}</div>
                        <div className="text-sm text-gray-500">{customer.email}</div>
                        <div className="text-sm text-gray-500">{customer.phone ? customer.phone : null}</div>
                      </div>

                      <div className="font-medium text-base text-gray-500 self-end">{customer.member_code}</div>
                    </div>
                  </Option>
                );
              })}
            </Select>
          </>
        )}
        {state.customer !== null ? (
          <div>
            <FontAwesomeIcon className="merge-customer-arrow" icon={["far", "arrow-circle-down"]} size="2x" />
            <span className="merge-customer-title">{t("secure.facility.customer.main.customer.013")}</span>
            <GolferCard
              email={state.customer?.email}
              name={state.customer?.full_name}
              memberCode={state.customer?.member_code}
              customerType={state.customer?.customer_type}
              phone={state.customer?.phone}
            />
          </div>
        ) : null}
      </Sheet>

      <Popup
        open={mergeState?.showWarning}
        type={"warning"}
        stacked
        title={t("secure.facility.customer.main.customer.014")}
        onCancel={() => setMergeState(prevState => ({ ...prevState, showWarning: false }))}
        cancelText={t("secure.facility.customer.main.customer.015")}
        okText={t("secure.facility.customer.main.customer.016")}
        onOk={handleCustomerMerge}
        description={`${t("secure.facility.customer.main.customer.017")} ${mergeState?.selectedCustomer?.full_name} ${t(
          "secure.facility.customer.main.customer.018",
        )}
        ${state?.customer?.full_name}. ${t("secure.facility.customer.main.customer.019")}
        `}
      />
    </>
  );
}
