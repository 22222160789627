import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ITable } from "api/rpc/facilityAdmin/tables/tables";
import { ICart, ICartLineItem } from "redux/reducers/models/cart";

import { LocaleCurrency } from "helpers/Locale";

import Callout from "components/callout/Callout";
import Checkbox from "components/form/checkbox/Checkbox";
import Sheet from "components/sheet/Sheet";

type SendLineItemModalProps = {
  open: boolean;
  selectedIds: number[];
  activeTable: ITable;
  onOk: (lineItemIds: number[]) => Promise<void>;
  onCancel: () => void;
};

export default function TableServiceSendLineItemsModal(props: SendLineItemModalProps) {
  const { t } = useTranslation();
  const [chosenIds, setChosenIds] = useState<number[]>(undefined);
  const [onOkInProgress, setOnOkInProgress] = useState<boolean>(false);

  useEffect(() => {
    if (props.selectedIds && chosenIds === undefined) {
      const selectAllCheckbox = document.getElementById("table_service_select_all_checkbox") as HTMLInputElement;
      selectAllCheckbox.checked = true;
      setChosenIds(props.selectedIds);
    }
  }, [props.selectedIds]);

  // Handle previously sent ID's differently when selecting all checkboxes
  function handleSelectAllCheckboxChange(e: React.ChangeEvent<HTMLInputElement>, printReceipt: boolean) {
    const { checked } = e.target;
    const lineItems: number[] = [];
    if (checked) {
      props?.activeTable?.carts?.forEach((cart: Record<string, any>) => {
        cart?.line_items?.forEach((lineItem: ICartLineItem) => {
          // Don't add modifiers
          if (!lineItem.parent_id && cart?.status !== "complete") {
            //Don't add line items that have been sent already
            if (printReceipt) {
              lineItems.push(lineItem.id);
            } else {
              const lineItemProductMeta = lineItem.product.meta;
              if (
                (lineItemProductMeta.kitchen_location_id ||
                  lineItemProductMeta.kitchen_location_id_2 ||
                  lineItemProductMeta.kitchen_location_id_3) &&
                // Handle unsent but selected line items
                (lineItem.kitchen_status !== "sent" || chosenIds.includes(lineItem.id))
              ) {
                lineItems.push(lineItem.id);
              }
            }
          }
        });
      });
    } else {
      props?.activeTable?.carts?.forEach((cart: Record<string, any>) => {
        cart?.line_items?.forEach((lineItem: ICartLineItem) => {
          // re-apply unsent but selected items with a clear
          if (chosenIds.includes(lineItem.id) && lineItem.kitchen_status === "sent") {
            lineItems.push(lineItem.id);
          }
        });
      });
    }

    setChosenIds(lineItems);
  }

  function handleCheckboxChange(id: number) {
    const lineItemIds = [...chosenIds];
    const foundIndex = lineItemIds?.indexOf(id);
    if (foundIndex === -1) {
      lineItemIds.push(id);
    } else {
      lineItemIds.splice(foundIndex, 1);
    }
    setChosenIds(lineItemIds);
  }

  function onCancel() {
    props.onCancel();
    setChosenIds([]);
  }

  useEffect(() => {
    if (onOkInProgress) {
      props
        .onOk(chosenIds)
        .then(() => {
          setChosenIds([]);
          setOnOkInProgress(false);
        })
        .catch(() => {
          setOnOkInProgress(false);
        });
    }
  }, [onOkInProgress]);

  return (
    <>
      <Sheet
        title={t("secure.facility.table_service.table_service.132")}
        open={props.open}
        size="medium"
        closable
        onCancel={onCancel}
        onOk={() => setOnOkInProgress(true)}
        okText={t("secure.facility.table_service.table_service.221")}
        okDisabled={chosenIds?.length < 1 ? true : false}
        okLoading={onOkInProgress}
      >
        {props?.activeTable?.carts?.length > 0 ? (
          <>
            <div className="flex flex-row justify-end">
              <Checkbox
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSelectAllCheckboxChange(e, false)}
                size="medium"
                id="table_service_select_all_checkbox"
                label={"Select All Unsent Items"} // TODO: Translation
                // label={t("secure.facility.table_service.table_service.133")}
              />
            </div>
            {props?.activeTable?.carts?.map((cart: ICart, cartIndex: number) => {
              if (cart.status === "open" && (!cart?.order || cart?.order?.financial_status === "unpaid")) {
                return (
                  <div key={cartIndex} className="mb-4">
                    <p className="text-lg text-semibold">
                      {t("secure.facility.table_service.table_service.134")}
                      {cartIndex + 1}
                    </p>
                    {cart.line_items?.map((lineItem, lineItemIndex: number) => {
                      //Don't display modifiers
                      if (
                        !lineItem?.parent_id &&
                        (lineItem?.product?.meta?.kitchen_location_id ||
                          lineItem?.product?.meta?.kitchen_location_id_2 ||
                          lineItem?.product?.meta?.kitchen_location_id_3)
                      ) {
                        return (
                          <div
                            key={lineItem.id.toString() + "_" + lineItemIndex.toString()}
                            onClick={
                              lineItem.kitchen_status !== "sent" ? () => handleCheckboxChange(lineItem.id) : null
                            }
                            className={`
                                flex p-2 items-center justify-between cursor-pointer table-service-hover
                                ${lineItem.kitchen_status === "sent" ? "table-service-sent-item" : undefined}
                            `}
                          >
                            <div className="flex items-center pointer-events-none">
                              <Checkbox
                                onChange={() => handleCheckboxChange(lineItem.id)}
                                size="medium"
                                label={lineItem?.preferred_title ? lineItem?.preferred_title : lineItem?.product_title}
                                supportText={
                                  lineItem.product_title !== lineItem.variant_title ? lineItem.variant_title : null
                                }
                                checked={chosenIds?.includes(lineItem.id) || lineItem.kitchen_chit_id !== null}
                                disabled={lineItem.kitchen_chit_id !== null || lineItem.kitchen_status === "sent"}
                              />
                            </div>
                            {lineItem.kitchen_status === "sent" ? (
                              <span
                                className="table-service-resend-button"
                                onClick={() => handleCheckboxChange(lineItem.id)}
                              >
                                {t("secure.facility.table_service.table_service.135")}
                              </span>
                            ) : null}
                            <LocaleCurrency currency={cart?.currency} amount={lineItem.subtotal_price} />
                          </div>
                        );
                      }
                    })}
                  </div>
                );
              }
            })}
          </>
        ) : (
          <div className="text-black">
            <Callout
              title={t("secure.facility.table_service.table_service.136")}
              content={t("secure.facility.table_service.table_service.137")}
              type="info"
            />
          </div>
        )}
      </Sheet>
    </>
  );
}
