import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { IUIActions } from "redux/actions/ui";

import "./giftCard.scss";

import { useTranslation } from "react-i18next";

import { GetFormResponse } from "api/rpc/2024-04/facilityAdmin/facility/form/formResponse";
import { IOrder, OrderFinancialStatus } from "redux/reducers/models/order";
import DataTable from "../customer/tabs/houseAccounts/DataTable";
import { Badge } from "components/badge/Badge";
import Icon from "components/icon/Icon";
import { valueToString } from "helpers/Helpers";
import moment from "moment";
import classNames from "classnames";

interface IProps {
  uiActions: IUIActions;
}

export interface IFormResponse {
  id: number;
  cart_id: number;
  order_id: number;
  customer_id: number;
  order: IOrder;
  created_at: string;
}

export default function OrderForm(props: IProps) {
  const { uiActions } = props;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { orderFormId } = useParams<{ orderFormId: string }>();
  const [formResponseState, setFormResponseState] = useState<IFormResponse[]>([]);

  const dataSource: (string | JSX.Element)[][] = [];

  formResponseState?.forEach(response => {
    dataSource.push([
      String(response?.order_id),
      response.order?.customer?.full_name,
      response.order?.processed_at,
      response.order?.financial_status,
    ]);
  });

  useEffect(() => {
    void LoadFormResponse();
  }, []);

  async function LoadFormResponse() {
    const form_id = Number(orderFormId);
    const formRes = await GetFormResponse({ form_id }, true);
    if (formRes.status !== StatusCode.OK) {
      uiActions.showError("Error retrieving form responses");
      return;
    }

    console.log("Form Response", formRes);
    setFormResponseState(formRes.data);
  }

  function navigateToItem(id: number) {
    history.push("/admin/order/" + String(id));
  }

  function generateOrderStatusBadge(status: OrderFinancialStatus): JSX.Element {
    switch (status) {
      case "paid":
        return (
          <Badge
            type="success"
            // className="paid-order-badge"
            iconLeft={<Icon icon={"check"} style={"far"} size="small" />}
          >
            {valueToString(status, "capitalize")}
          </Badge>
        );
      case "partially_paid":
        return (
          <Badge
            type="primary"
            // className="partial_paid-order-badge"
            iconLeft={<Icon icon={"circle-exclamation"} style={"far"} size="small" />}
          >
            {valueToString(status, "capitalize")}
          </Badge>
        );
      case "cancelled":
        return (
          <Badge
            type="error"
            // className="cancelled-order-badge"
            iconLeft={<Icon icon={"circle-xmark"} style={"far"} size="small" />}
          >
            {valueToString(status, "capitalize")}
          </Badge>
        );

      case "refunded":
        return (
          <Badge
            type="warning"
            // className="refunded-order-badge"
            iconLeft={<Icon icon={"turn-down-left"} style={"far"} size="small" />}
          >
            {valueToString(status, "capitalize")}
          </Badge>
        );

      default:
        return (
          <Badge type="gray" className="sales-channel-badge">
            {valueToString(status, "capitalize")}
          </Badge>
        );
    }
  }

  return (
    <>
      <Page
        narrow
        splitLayout
        title="Form Responses"
        breadcrumbs={[{ prefix: true, label: "Back to Forms", url: "/admin/forms" }]}
      >
        <DataTable
          columns={[{ label: "Order #" }, { label: "Customer" }, { label: "Processed Date" }, { label: "Status" }]}
        >
          {formResponseState?.map((responses, index) => {
            return (
              <tr
                key={index}
                onClick={responses?.order?.number ? () => navigateToItem(responses.order_id) : null}
                className="clickable"
              >
                <td>{responses?.order?.number}</td>
                <td>{responses?.order?.customer?.full_name}</td>
                <td>{responses?.created_at ? moment(responses?.created_at).format("LLL") : null}</td>
                <td>
                  {responses?.order?.financial_status
                    ? generateOrderStatusBadge(responses?.order?.financial_status)
                    : null}
                </td>
              </tr>
            );
          })}
        </DataTable>
      </Page>
    </>
  );
}
