import React, { Dispatch, forwardRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { formatDate } from "helpers/Helpers";

import { ButtonNew } from "components/buttonNew";
import { CALENDAR_MONTHS, THIS_YEAR } from "components/form/calendarField/helpers";

import "./dateChangePanel.scss";

type TDateChangePanel = {
  topPosition: number;
  closePanel: () => void;
  selectedDate: Date;
  setSelectedDate: Dispatch<React.SetStateAction<Date>>;
};

const DateChangePanel = forwardRef<HTMLDivElement, TDateChangePanel>((props, ref) => {
  const { topPosition, closePanel, selectedDate, setSelectedDate } = props;

  const [yearView, setYearView] = useState(false);

  const dozenYears = Array.from({ length: 12 }, (_, i) => THIS_YEAR - 8 + (i + 1));

  function handleDateChange(year?: number, month?: number) {
    const newYear = year ?? selectedDate.getFullYear();
    const newMonth = month ?? selectedDate.getMonth();
    const newDate = new Date(newYear, newMonth);
    newDate.setDate(selectedDate.getDate());
    setSelectedDate(newDate);
  }

  return (
    <div ref={ref} className="date-change-panel" style={{ top: topPosition }}>
      <div className="date-change-panel-wrap">
        <div className="panel-content">
          <div className="panel-content-actions">
            <ButtonNew type="text" className="primary" onClick={() => setYearView(prev => !prev)}>
              <FontAwesomeIcon icon={["far", "chevron-left"]} />
              {!yearView
                ? selectedDate.getFullYear()
                : Object.keys(CALENDAR_MONTHS).filter((val, index) => index === selectedDate.getMonth())[0]}
            </ButtonNew>
            <ButtonNew
              type="text"
              className={`secondary${
                (!yearView && selectedDate.getMonth() === new Date().getMonth()) ||
                (yearView && selectedDate.getFullYear() === new Date().getFullYear())
                  ? " disabled"
                  : ""
              }`}
              onClick={() => {
                !yearView
                  ? handleDateChange(null, new Date().getMonth())
                  : handleDateChange(new Date().getFullYear(), null);
              }}
              disabled={
                (!yearView && selectedDate.getMonth() === new Date().getMonth()) ||
                (yearView && selectedDate.getFullYear() === new Date().getFullYear())
              }
            >
              {!yearView ? "This Month" : "This Year"}
            </ButtonNew>
          </div>
          {!yearView ? (
            <div className="panel-content-grid">
              {Object.values(CALENDAR_MONTHS).map((val, index) => (
                <span
                  key={val}
                  role="button"
                  className={`month${selectedDate.getMonth() === index ? " selected" : ""}`}
                  onClick={() => (selectedDate.getMonth() !== index ? handleDateChange(null, index) : undefined)}
                >
                  {val}
                </span>
              ))}
            </div>
          ) : (
            <div className="panel-content-grid">
              {dozenYears.map(val => (
                <span
                  key={val}
                  role="button"
                  className={`year${selectedDate.getFullYear() === val ? " selected" : ""}`}
                  onClick={() => (selectedDate.getFullYear() !== val ? handleDateChange(val, null) : undefined)}
                >
                  {val}
                </span>
              ))}
            </div>
          )}
        </div>

        <div className="panel-actions">
          <ButtonNew type="secondary" onClick={() => closePanel()}>
            Close
          </ButtonNew>
        </div>
      </div>
    </div>
  );
});

DateChangePanel.displayName = "DateChangePanel";
export default DateChangePanel;
