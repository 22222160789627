import React, { useEffect, useRef, useState } from "react";
import { useDrag, XYCoord } from "react-dnd";
import Input from "components/form/input/Input";
import { Select } from "components/select/index";
import "./floorPlanTable.scss";
import { isPositiveInteger } from "helpers/Helpers";
import { getEmptyImage } from "react-dnd-html5-backend";
import { IFloorPlanTable, ITableElement, TableDegreeRotation } from "api/rpc/facilityAdmin/tables/tables";
import { DEFAULT_TABLE_DIMENSION, ItemTypes } from "../floorPlanEdit/FloorPlanEdit";
import { IFloorPlanTableScale } from "../floorPlan/FloorPlan";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useTranslation } from "react-i18next";

export interface IFloorPlanOptionTable {
  element: ITableElement;
}

export type UpdateDroppedTable = (updatedTable: Partial<IFloorPlanTable>) => void;

interface IFloorPlanTableProps {
  optionTable?: IFloorPlanOptionTable;
  droppedTable?: IFloorPlanTable;
  updateDroppedTable?: UpdateDroppedTable;
  deleteDroppedTable?: () => void;
  tableElements?: ITableElement[];
  calculateTableScale?: (tableElement: ITableElement, degreeRotation?: TableDegreeRotation) => IFloorPlanTableScale;
  xCoordinate?: number;
  yCoordinate?: number;
  xCenterCoordinate?: number;
  yCenterCoordinate?: number;
  gridSquareSideLength?: number;
}

interface IFloorPlanTableInputState {
  seats: string;
  title: string;
  table_element_id: number;
}

export interface IFloorPlanTableItem {
  optionTable: IFloorPlanOptionTable;
  droppedTable: IFloorPlanTable;
  tableRef: React.MutableRefObject<HTMLDivElement>;
  initialDragCoordinate: XYCoord;
  initialDragScrollYPosition: number;
}

export default function FloorPlanTable(props: IFloorPlanTableProps) {
  const { Option } = Select;
  const { t, i18n } = useTranslation();
  const floorPlanTableClass = "floor-plan-table";
  const floorPlanTableShortTitleClass = "floor-plan-table-short-title";
  const floorPlanTableImageClass = "floor-plan-table-image";

  const {
    optionTable,
    droppedTable,
    updateDroppedTable,
    deleteDroppedTable,
    tableElements,
    calculateTableScale,
    xCoordinate,
    yCoordinate,
    xCenterCoordinate,
    yCenterCoordinate,
    gridSquareSideLength,
  } = props;

  const floorPlanTableRef = useRef<HTMLDivElement>(null);
  const floorPlanDropdownRef = useRef<HTMLDivElement>(null);

  const [currentTableElement, setCurrentTableElement] = useState<ITableElement>(undefined);
  const [initialDragCoordinate, setInitialDragCoordinate] = useState<XYCoord>(null);
  const [initialDragElement, setInitialDragElement] = useState<Element>(null);
  const [editTableModalIsOpen, setEditTableModalIsOpen] = useState<boolean>(false);

  const [floorPlanTableInputState, setFloorPlanTableInputState] = useState<IFloorPlanTableInputState>({
    seats: "",
    title: "",
    table_element_id: 0,
  });

  useEffect(() => {
    if (initialDragCoordinate) {
      setInitialDragElement(document.elementFromPoint(initialDragCoordinate.x, initialDragCoordinate.y));
    } else {
      setInitialDragElement(null);
    }
  }, [initialDragCoordinate]);

  useEffect(() => {
    if (droppedTable) {
      setFloorPlanTableInputState({
        seats: String(droppedTable.seats),
        title: droppedTable.title,
        table_element_id: droppedTable.table_element_id,
      });
    }
  }, [droppedTable, editTableModalIsOpen]);

  useEffect(() => {
    if (titleIsValid(droppedTable?.title)) {
      const positiveIntegerRegex = /([1-9][0-9]*)/;
      const firstCharacter = droppedTable.title[0];
      const restOfTitle = droppedTable.title.slice(1);

      const positiveIntegerAfterFirstCharacter = positiveIntegerRegex.exec(restOfTitle)?.[0];
      const updatedShortTitle = firstCharacter + (positiveIntegerAfterFirstCharacter ?? "");
      updateDroppedTable({ short_title: updatedShortTitle });
    }
  }, [droppedTable?.title]);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideDropdownClick);
    document.addEventListener("touchstart", handleOutsideDropdownClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideDropdownClick);
      document.removeEventListener("touchstart", handleOutsideDropdownClick);
    };
  }, []);

  useEffect(() => {
    if (optionTable || droppedTable) {
      let tableElement: ITableElement = undefined;
      if (optionTable) {
        tableElement = optionTable.element;
      } else if (droppedTable) {
        const droppedTableElement = tableElements.find(
          tableElement => tableElement.id === droppedTable.table_element_id,
        );

        tableElement = droppedTableElement;
      }

      setCurrentTableElement(tableElement);
    }
  }, [optionTable, droppedTable]);

  function handleSetTableModalIsOpen(value: React.SetStateAction<boolean>): void {
    if (droppedTable) {
      setEditTableModalIsOpen(value);
    }
  }

  function handleOutsideDropdownClick(event: any) {
    if (
      !floorPlanTableRef.current ||
      floorPlanTableRef.current.contains(event.target) ||
      !floorPlanDropdownRef.current ||
      floorPlanDropdownRef.current.contains(event.target)
    ) {
      return;
    }

    handleSetTableModalIsOpen(false);
  }

  const [{ isDragging }, drag, dragPreview] = useDrag(() => {
    const item: IFloorPlanTableItem = {
      optionTable: optionTable,
      droppedTable: droppedTable,
      tableRef: floorPlanTableRef,
      initialDragCoordinate: initialDragCoordinate,
      initialDragScrollYPosition: window.scrollY,
    };

    return {
      item: item,
      canDrag: () => {
        if (
          initialDragElement.classList.contains(floorPlanTableClass) ||
          initialDragElement.classList.contains(floorPlanTableShortTitleClass) ||
          initialDragElement.classList.contains(floorPlanTableImageClass)
        ) {
          if (editTableModalIsOpen) {
            handleSetTableModalIsOpen(false);
          }

          return true;
        } else {
          return false;
        }
      },
      type: ItemTypes.TABLE,
      collect: monitor => ({
        isDragging: !!monitor.isDragging(),
      }),
    };
  }, [initialDragCoordinate, initialDragElement, editTableModalIsOpen]);

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  function handleTableMouseDown(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    setInitialDragCoordinate({ x: e.clientX, y: e.clientY });
  }

  function handleTableClick() {
    if (
      initialDragElement &&
      (initialDragElement.classList.contains(floorPlanTableClass) ||
        initialDragElement.classList.contains(floorPlanTableShortTitleClass) ||
        initialDragElement.classList.contains(floorPlanTableImageClass))
    ) {
      handleSetTableModalIsOpen(prev => !prev);
    }
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;

    setFloorPlanTableInputState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleTableElementChange(id: number) {
    setFloorPlanTableInputState(prevState => ({ ...prevState, table_element_id: id }));
  }

  function titleIsValid(title: string) {
    if (title) {
      const firstCharacter = title?.[0];
      return firstCharacter !== undefined && firstCharacter !== " ";
    } else {
      return false;
    }
  }

  function handleUpdateDroppedTable() {
    if (isPositiveInteger(floorPlanTableInputState.seats) && titleIsValid(floorPlanTableInputState.title)) {
      updateDroppedTable({
        seats: parseInt(floorPlanTableInputState.seats),
        title: floorPlanTableInputState.title,
        table_element_id: floorPlanTableInputState.table_element_id,
      });

      handleSetTableModalIsOpen(false);
    }
  }

  function rotateTable(rotateRight: boolean) {
    const currentTableDegreeRotation: TableDegreeRotation = droppedTable.rotation;
    let updatedTableDegreeRotation: TableDegreeRotation = 0;

    if (currentTableDegreeRotation === 0 && !rotateRight) {
      updatedTableDegreeRotation = 270;
    } else if (currentTableDegreeRotation === 270 && rotateRight) {
      updatedTableDegreeRotation = 0;
    } else {
      const attemptedTableDegreeRotation: number = rotateRight
        ? currentTableDegreeRotation + 90
        : currentTableDegreeRotation - 90;

      updatedTableDegreeRotation =
        attemptedTableDegreeRotation !== 0 &&
        attemptedTableDegreeRotation !== 90 &&
        attemptedTableDegreeRotation !== 180 &&
        attemptedTableDegreeRotation !== 270
          ? 0
          : attemptedTableDegreeRotation;
    }

    updateDroppedTable({ rotation: updatedTableDegreeRotation });
  }

  return (
    <div
      onMouseDown={handleTableMouseDown}
      onClick={handleTableClick}
      ref={el => {
        drag(el);
        floorPlanTableRef.current = el;
      }}
      className={floorPlanTableClass}
      style={{
        position: droppedTable ? "absolute" : "static",
        left: xCoordinate,
        top: yCoordinate,
        opacity: isDragging ? 0.5 : 1,
        cursor: isDragging ? "grabbing" : "grab",
        border: `1px dashed ${editTableModalIsOpen ? "blue" : "transparent"}`,
        width: droppedTable
          ? DEFAULT_TABLE_DIMENSION * calculateTableScale(currentTableElement, droppedTable.rotation).widthScale
          : DEFAULT_TABLE_DIMENSION,
        height: droppedTable
          ? DEFAULT_TABLE_DIMENSION * calculateTableScale(currentTableElement, droppedTable.rotation).heightScale
          : DEFAULT_TABLE_DIMENSION,
      }}
    >
      <img
        src={currentTableElement?.image_src ?? ""}
        className={floorPlanTableImageClass}
        style={{
          ...(droppedTable
            ? {
                position: "absolute",
                left: xCenterCoordinate,
                top: yCenterCoordinate,
                transform: `translate(-50%, -50%) rotate(${droppedTable.rotation}deg)`,
              }
            : {}),
          transformOrigin: "center",
          width: droppedTable
            ? DEFAULT_TABLE_DIMENSION * calculateTableScale(currentTableElement, undefined).widthScale
            : DEFAULT_TABLE_DIMENSION,
          height: droppedTable
            ? DEFAULT_TABLE_DIMENSION * calculateTableScale(currentTableElement, undefined).heightScale
            : DEFAULT_TABLE_DIMENSION,
        }}
      />
      {droppedTable && (
        <div
          className={floorPlanTableShortTitleClass}
          style={{
            left: xCenterCoordinate,
            top: yCenterCoordinate,
            fontSize: `${Math.max(8, Math.round(gridSquareSideLength / 2))}px`,
          }}
        >
          {droppedTable.short_title}
        </div>
      )}
      <div
        ref={floorPlanDropdownRef}
        className="floor-plan-table-dropdown"
        style={{
          display: editTableModalIsOpen ? "flex" : "none",
        }}
      >
        <div className="floor-plan-table-dropdown-actions">
          <button className="floor-plan-table-dropdown-action" onClick={() => rotateTable(false)}>
            <FontAwesomeIcon icon={["far", "rotate-left"]} />
          </button>
          <button className="floor-plan-table-dropdown-action" onClick={() => rotateTable(true)}>
            <FontAwesomeIcon icon={["far", "rotate-right"]} />
          </button>
          <button className="floor-plan-table-dropdown-action" onClick={handleUpdateDroppedTable}>
            <FontAwesomeIcon style={{ color: "green" }} icon={["far", "check"]} />
          </button>
          <button className="floor-plan-table-dropdown-action" onClick={deleteDroppedTable}>
            <FontAwesomeIcon style={{ color: "red" }} icon={["far", "x"]} />
          </button>
        </div>
        {editTableModalIsOpen && droppedTable && tableElements && (
          <Select
            label={t("secure.facility.settings.floor_plan.floor_plan_table.floor_plan_table.001")}
            onChange={handleTableElementChange}
            defaultValue={droppedTable.table_element_id}
          >
            {tableElements.map((element, index) => {
              return (
                <Option key={index} value={element.id}>
                  {element.title}
                </Option>
              );
            })}
          </Select>
        )}
        <Input
          id="seats"
          label={t("secure.facility.settings.floor_plan.floor_plan_table.floor_plan_table.002")}
          value={floorPlanTableInputState.seats}
          onChange={handleInputChange}
          error={!isPositiveInteger(floorPlanTableInputState.seats)}
        />
        <Input
          id="title"
          label={t("secure.facility.settings.floor_plan.floor_plan_table.floor_plan_table.003")}
          value={floorPlanTableInputState.title}
          onChange={handleInputChange}
          error={!titleIsValid(floorPlanTableInputState.title)}
        />
      </div>
    </div>
  );
}
