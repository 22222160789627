import React, { useState } from "react";
import { NavLink, Redirect, useRouteMatch } from "react-router-dom";
import { useTranslation, TFunction } from "react-i18next";

import CustomerProfile from "./profile/CustomerProfile";
import CustomerReservations from "./reservations/CustomerReservations";
import CustomerNotes from "./notes/CustomerNotes";
import CustomerMemberships from "./memberships/CustomerMemberships";
import CustomerHouseAccounts from "./houseAccounts/CustomerHouseAccounts";
import CustomerPaymentMethods from "./paymentMethods/CustomerPaymentMethods";
import CustomerItemSales from "./itemSales/CustomerItemSales";
import CustomerTickets from "./tickets/CustomerTickets";
import CustomerCredit from "./credit/CustomerCredit";

import "./customerTabs.scss";

type TabNavigationType = {
  title: string;
  route: string;
};

/**
 * Handles Tab navigation within Customer.tsx on facility-side.
 *     - Add a new tab using this element.
 *
 * @property {string | number} customerId used to generate and define active URL routes.
 * @returns {JSX.Element} A navigation list with the tab content displayed under it.
 */
export default function CustomerTabs(props: { customerId: string | number }): JSX.Element {
  const routeMatch = useRouteMatch();
  const { t } = useTranslation();
  const customerTabInfo = generateCustomerTabs(props.customerId, t);
  const [state, setState] = useState(routeMatch.url);

  return (
    <>
      <ul className="navigation-list">
        {customerTabInfo.map(route => (
          <li key={route.route} className="navigation-list-li">
            <NavLink
              to={route.route}
              key={route.route}
              onClick={() => setState(route.route)}
              className="customer-layout-nav-link"
            >
              {route.title}
            </NavLink>
          </li>
        ))}
      </ul>

      {displayCustomerPage(props.customerId, routeMatch.url)}
    </>
  );
}

/** Define the URL routes for the specific customer.  Returns array for profile navigation. */
const generateCustomerTabs = (
  customerId: number | string,
  t: TFunction<"translation", undefined>,
): TabNavigationType[] => {
  if (!customerId) {
    return [];
  }

  const idAsString = typeof customerId === "number" ? customerId.toString() : customerId;
  return [
    {
      title: t("secure.facility.customer.tabs.customer_tabs.001"),
      route: `/admin/customers/${idAsString}/profile`,
    },
    {
      title: t("secure.facility.customer.tabs.customer_tabs.002"),
      route: `/admin/customers/${idAsString}/reservations`,
    },
    {
      title: t("secure.facility.customer.tabs.customer_tabs.003"),
      route: `/admin/customers/${idAsString}/membership`,
    },
    {
      title: t("secure.facility.customer.tabs.customer_tabs.008"),
      route: `/admin/customers/${idAsString}/tickets`,
    },
    {
      title: t("secure.facility.customer.tabs.customer_tabs.004"),
      route: `/admin/customers/${idAsString}/house-accounts`,
    },
    {
      title: t("secure.facility.customer.tabs.customer_tabs.005"),
      route: `/admin/customers/${idAsString}/payment-methods`,
    },
    {
      title: t("secure.facility.customer.tabs.customer_tabs.006"),
      route: `/admin/customers/${idAsString}/item-sales`,
    },
    {
      title: "Credit Book",
      route: `/admin/customers/${idAsString}/credit`,
    },
    {
      title: t("secure.facility.customer.tabs.customer_tabs.007"),
      route: `/admin/customers/${idAsString}/notes`,
    },
  ];
};

/** Returns a JSX element based on the given customerID and a defined route URL. */
const displayCustomerPage = (customerId: number | string, routeURL: string): JSX.Element => {
  const idAsString = typeof customerId === "number" ? customerId.toString() : customerId;

  switch (routeURL) {
    case `/admin/customers/${idAsString}/profile`:
      return <CustomerProfile />;
    case `/admin/customers/${idAsString}/reservations`:
      return <CustomerReservations />;
    case `/admin/customers/${idAsString}/membership`:
      return <CustomerMemberships />;
    case `/admin/customers/${idAsString}/tickets`:
      return <CustomerTickets />;
    case `/admin/customers/${idAsString}/house-accounts`:
      return <CustomerHouseAccounts />;
    case `/admin/customers/${idAsString}/payment-methods`:
      return <CustomerPaymentMethods />;
    case `/admin/customers/${idAsString}/item-sales`:
      return <CustomerItemSales />;
    case `/admin/customers/${idAsString}/credit`:
      return <CustomerCredit customerId={idAsString} />;
    case `/admin/customers/${idAsString}/notes`:
      return <CustomerNotes />;

    default:
      return <Redirect to={`/admin/customers/${idAsString}/profile`} />;
  }
};
