import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { IUIActions } from "redux/actions/ui";

import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Page from "components/page/Page";
import Input from "components/form/input/Input";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import { Select } from "components/select/index";
import TextField from "components/form/textField/TextField";
import { isNumeric } from "helpers/Helpers";
import { IRegister } from "../../Admin";
import { GetRegisters } from "api/rpc/2022-09/clientAdmin/register/register";
import { PutCashOut } from "api/rpc/2022-09/clientAdmin/register/cashout";

interface ICashOutNewProps {
  uiActions: IUIActions;
}

interface IValidatedField {
  data: string;
  isValid: boolean;
  isDirty: boolean;
}

interface ICashOutNewState {
  closed_at: Date;
  depositCash: IValidatedField;
  depositCheck: IValidatedField;
  depositBagNumber: string;
  notes: string;
  registerId: number;
  registers: IRegister[];
}

export default function ClientCashOutNew(props: ICashOutNewProps) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { uiActions } = props;

  const { Option } = Select;

  const [cashOutNewState, setCashOutNewState] = useState<ICashOutNewState>({
    closed_at: new Date(),
    depositCash: { data: "", isValid: false, isDirty: false },
    depositCheck: { data: "", isValid: false, isDirty: false },
    depositBagNumber: "",
    notes: "",
    registerId: undefined,
    registers: [],
  });

  useEffect(() => {
    void loadRegisters();
  }, []);

  async function loadRegisters() {
    const getRegistersResponse = await GetRegisters(null, true);

    if (getRegistersResponse.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.settings.cash_outs.cash_out_new.001"));
      return;
    }

    const registerId =
      localStorage.getItem("register") === null
        ? getRegistersResponse.data?.[0]?.id
        : JSON.parse(localStorage.getItem("register")).id;

    setCashOutNewState(prevState => ({
      ...prevState,
      registerId: registerId,
      registers: getRegistersResponse.data,
    }));
  }

  function navigateToCashOuts() {
    history.push("/admin/settings/cash-out");
  }

  function handleValidatedInputChange(id: string, data: string, isValid: boolean) {
    setCashOutNewState(prev => ({
      ...prev,
      [id]: {
        data,
        isValid,
        isDirty: true,
      },
    }));
  }

  function handleDateChange(date: Date) {
    setCashOutNewState(prevState => ({ ...prevState, closed_at: date }));
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setCashOutNewState(prev => ({ ...prev, [id]: value }));
  }

  function handleRegisterChange(registerId: number) {
    setCashOutNewState(prev => ({ ...prev, registerId }));
  }

  async function createCashOut() {
    if (!cashOutNewState.depositCash.isValid || !cashOutNewState.depositCheck.isValid || !cashOutNewState.registerId) {
      setCashOutNewState(prev => ({
        ...prev,
        depositCash: { ...prev.depositCash, isDirty: true },
        depositCheck: { ...prev.depositCheck, isDirty: true },
      }));
    } else {
      const putCashOutResponse = await PutCashOut(
        {
          closed_at: cashOutNewState.closed_at,
          deposit_cash: parseFloat(cashOutNewState.depositCash.data),
          deposit_check: parseFloat(cashOutNewState.depositCheck.data),
          deposit_bag_number: cashOutNewState.depositBagNumber,
          notes: cashOutNewState.notes,
          register_id: cashOutNewState.registerId,
        },
        true,
      );

      if (putCashOutResponse.status !== StatusCode.OK) {
        uiActions.showError(t("secure.facility.settings.cash_outs.cash_out_new.002"));
        return;
      }

      navigateToCashOuts();
    }
  }

  const primaryAction = {
    content: "Create Cash Out",
    action: createCashOut,
  };

  return (
    <Page
      title={"Cash Out New"}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: "back to Cash Outs",
          url: "/admin/settings/facility-settings/cash-out",
        },
      ]}
    >
      <Card>
        <Card.Section title={t("secure.facility.settings.cash_outs.cash_out_new.006")}>
          <FormLayout>
            <FormLayout.Group>
              <DatePickerInput
                months={1}
                position="left"
                startingDate={cashOutNewState.closed_at}
                setStartingDate={handleDateChange}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <Input
                value={cashOutNewState.depositCash.data}
                label={t("secure.facility.settings.cash_outs.cash_out_new.007")}
                onChange={(e: any) =>
                  handleValidatedInputChange("depositCash", e.target.value, isNumeric(e.target.value))
                }
                error={!cashOutNewState.depositCash.isValid && cashOutNewState.depositCash.isDirty}
              />
              <Input
                value={cashOutNewState.depositCheck.data}
                label={t("secure.facility.settings.cash_outs.cash_out_new.008")}
                onChange={(e: any) =>
                  handleValidatedInputChange("depositCheck", e.target.value, isNumeric(e.target.value))
                }
                error={!cashOutNewState.depositCheck.isValid && cashOutNewState.depositCheck.isDirty}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <Input
                value={cashOutNewState.depositBagNumber}
                label={t("secure.facility.settings.cash_outs.cash_out_new.009")}
                id="depositBagNumber"
                onChange={handleInputChange}
              />
              <Select
                label={t("secure.facility.settings.cash_outs.cash_out_new.010")}
                onChange={handleRegisterChange}
                defaultValue={cashOutNewState.registerId}
              >
                {cashOutNewState.registers?.map((register, index) => {
                  return (
                    <Option key={index} value={register.id}>
                      {register.title}
                    </Option>
                  );
                })}
              </Select>
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField
                value={cashOutNewState.notes}
                label={t("secure.facility.settings.cash_outs.cash_out_new.011")}
                id="notes"
                onChange={handleInputChange}
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>
    </Page>
  );
}
