import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TeeSheetDetails from "pages/secure/facility/teesheet/TeeSheetDetails";

import * as FacilityActions from "redux/actions/facility";
import * as UIActions from "redux/actions/ui";
import * as TeeSheetActions from "redux/actions/teesheet";
import * as CartActions from "redux/actions/cart";
import * as OrderActions from "redux/actions/order";
import * as TeeSheetProductActions from "redux/actions/facilityAdmin/teeSheetProducts";

const mapStateToProps = (state: any) => {
  return {
    facilityStore: { ...state.facilityStore },
    teeSheetStore: { ...state.teeSheetStore },
    uiStore: { ...state.uiStore },
    terminalStore: { ...state.terminalStore },
    authStore: { ...state.authStore },
    teeSheetProductStore: { ...state.teeSheetProductStore },
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    teeSheetActions: bindActionCreators(
      {
        ...TeeSheetActions,
      },
      dispatch,
    ),
    facilityActions: bindActionCreators(
      {
        ...FacilityActions,
      },
      dispatch,
    ),
    uiActions: bindActionCreators(
      {
        ...UIActions,
      },
      dispatch,
    ),
    cartActions: bindActionCreators(
      {
        ...CartActions,
      },
      dispatch,
    ),
    orderActions: bindActionCreators(
      {
        ...OrderActions,
      },
      dispatch,
    ),
    teeSheetProductActions: bindActionCreators(
      {
        ...TeeSheetProductActions,
      },
      dispatch,
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeeSheetDetails);
