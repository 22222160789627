import React, { ChangeEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";
import ReactDOM from "react-dom";
import moment from "moment";

import { StatusCode } from "api/protocols";
import { DeleteLeague, ILeaguePut } from "api/rpc/2022-09/facilityAdmin/league/league";
import { GetLeague, PutLeague, UploadLeagueLogo, DeleteLeagueLogo } from "api/rpc/2024-04/facilityAdmin/league/league";

import { showError, showSuccess } from "redux/actions/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { formatDate, handleChangeEventInput } from "helpers/Helpers";

import { TWeekDays } from "components/form/calendarField/helpers";
import Page from "components/page/Page";
import Card from "components/card/Card";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import Popup from "components/popup/Popup";
import TextEditor from "components/textEditor/textEditor";
import Checkbox from "components/form/checkbox/Checkbox";
import TimePick from "components/timePick/TimePick";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import Portal from "elements/Portal";
import CustomerImage from "elements/customer/CustomerImage";

interface ILeagueState {
  id: number | string;
  name?: string;
  week_day?: TWeekDays;
  start_time?: string;
  description?: string;
  register_link: string;
  leaderboard_link: string;
  logo_source: string;
  display_online: boolean;
  start_date: string;
  end_date: string;
}

export default function SingleLeagueSettings() {
  const history = useHistory();
  const { leagueId } = useParams<{ leagueId: string }>();
  const { t, i18n } = useTranslation();

  const { facilityStore } = useAppSelector(store => store);
  const dispatch = useAppDispatch();

  const [league, setLeague] = useState<ILeagueState>({
    id: leagueId,
    name: "",
    week_day: null,
    start_time: "12:00",
    description: "",
    register_link: "",
    leaderboard_link: "",
    logo_source: null,
    display_online: false,
    start_date: "",
    end_date: "",
  });

  const [leagueTimes, setLeagueTimes] = useState({
    start_time: "12:00",
    start_date: new Date(),
    end_date: new Date(),
  });

  // const [sponsorships, setSponsorships] = useState<ISponsorshipImage[]>(undefined);
  const [deleteLeaguePopup, setDeleteLeaguePopup] = useState(false);

  // const { state: removeSponsorshipPopup, updateModal, closeModal } = useModal({ sponsorId: null as number });

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (facilityStore.facility) {
      void loadLeague(leagueId, source.token);
      // void loadSponsorships(leagueId, source.token);
    }

    return () => {
      source.cancel();
    };
  }, [leagueId, facilityStore]);

  async function loadLeague(id?: string, token?: CancelToken) {
    const leagueRes = await GetLeague({ id: id }, true, token);

    if (token && token.reason) {
      return;
    }
    if (leagueRes.status !== StatusCode.OK) {
      dispatch(
        showError(
          typeof leagueRes.data === "string" ? leagueRes.data : t("secure.facility.league.league_settings.002"),
        ),
      );
      history.push(`/admin/league/${leagueId}/home`);
      return;
    }

    ReactDOM.unstable_batchedUpdates(() => {
      setLeague(prev => ({
        ...prev,
        name: leagueRes.data[0]?.name,
        start_time: leagueRes.data[0]?.start_time,
        week_day: leagueRes.data[0]?.week_day,
        description: leagueRes.data[0]?.description,
        logo_source: leagueRes.data[0]?.logo_source,
        display_online: leagueRes.data[0]?.display_online,
      }));
      setLeagueTimes(prevState => ({
        ...prevState,
        start_date: leagueRes.data[0]?.start_date
          ? new Date(moment(leagueRes.data[0]?.start_date).format())
          : prevState.start_date,
        end_date: leagueRes.data[0]?.end_date
          ? new Date(moment(leagueRes.data[0]?.end_date).format())
          : prevState.end_date,
      }));
    });
  }

  async function updateLeague(params: ILeaguePut) {
    const leagueParams = {
      ...params,
      start_date: formatDate(leagueTimes.start_date),
      end_date: formatDate(leagueTimes.end_date),
    };

    const updateRes = await PutLeague(leagueParams, true);

    if (updateRes.status !== StatusCode.OK) {
      dispatch(
        showError(
          typeof updateRes.data === "string" ? updateRes.data : t("secure.facility.league.league_settings.003"),
        ),
      );
      return;
    }
    dispatch(showSuccess(t("secure.facility.league.league_settings.004")));
  }

  async function removeLeague() {
    const res = await DeleteLeague({ id: leagueId }, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError(typeof res.data === "string" ? res.data : "There was an error deleting the league.")); // TODO: Translation
      setDeleteLeaguePopup(false);
      return;
    }

    setDeleteLeaguePopup(false);
    history.push("/admin/events");
  }

  // async function loadSponsorships(leagueId: string, token?: CancelToken) {
  //   if (sponsorships !== undefined) {
  //     setSponsorships(undefined);
  //   }

  //   const res = await GetLeagueSponsorship({ league_id: leagueId }, false, token);

  //   if (token && token.reason) {
  //     return;
  //   }
  //   if (res.status !== StatusCode.OK) {
  //     dispatch(showError(typeof res.data === "string" ? res.data : "Error loading sponsorships."));  // TODO: Translation
  //   }

  //   setSponsorships(res.status !== StatusCode.OK ? [] : res.data);
  // }

  // async function updateSponsorship(imageFile: File) {
  //   const formData = new FormData();

  //   formData.append("image", imageFile);
  //   formData.append("league_id", leagueId);

  //   const res = await PostLeagueSponsorship(formData, true);

  //   if (res.status !== StatusCode.OK) {
  //     dispatch(showError(typeof res.data === "string" ? res.data : "Error adding sponsorship."));
  //     return;
  //   }

  //   void loadSponsorships(leagueId);
  // }

  // async function removeSponsorship() {
  //   const res = await DeleteLeagueSponsorship({ id: removeSponsorshipPopup.sponsorId }, true);

  //   if (res.status !== StatusCode.OK) {
  //     dispatch(showError(typeof res.data === "string" ? res.data : "Error removing sponsorship.")); // TODO: Translation
  //     return;
  //   }

  //   const updatedSponsors = [...sponsorships];
  //   const removedSponsor = updatedSponsors.indexOf(
  //     sponsorships.find(value => value.id === removeSponsorshipPopup.sponsorId),
  //   );
  //   updatedSponsors.splice(removedSponsor, 1);

  //   setSponsorships(updatedSponsors);
  //   closeModal();
  // }

  async function saveLeagueLogo(imageFile: File) {
    const formData = new FormData();

    formData.append("image", imageFile);
    formData.append("league_id", league.id.toString());

    const imageRes = await UploadLeagueLogo(formData, true);
    if (imageRes.status !== StatusCode.OK) {
      return;
    }

    setLeague(prevState => ({ ...prevState, logo_source: imageRes.data.logo_source }));
  }

  async function deleteLeagueLogo(league_id: string) {
    const deleteRes = await DeleteLeagueLogo({ league_id }, true);

    if (deleteRes.status !== StatusCode.OK) {
      return;
    }

    setLeague(prevState => ({ ...prevState, logo_source: deleteRes.data.logo_source }));
  }

  function setDate(date: Date, key: string) {
    setLeagueTimes(prevState => ({ ...prevState, [key]: date }));
  }

  const primaryAction = {
    content: t("secure.facility.league.league_settings.005"),
    action: () => updateLeague(league),
  };

  const secondaryAction = [
    {
      content: "Delete", // TODO: Translation
      action: () => setDeleteLeaguePopup(true),
    },
  ];

  return (
    <Page
      title={t("secure.facility.league.league_settings.006")}
      narrow
      primaryAction={primaryAction}
      secondaryActions={secondaryAction}
    >
      <Form>
        <Card>
          <Card.Section>
            <Input
              value={league.name}
              label={t("secure.facility.league.new.012")}
              id="name"
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeEventInput(e, league, setLeague)}
              placeholder={t("secure.facility.league.new.012")}
            />
          </Card.Section>
        </Card>

        <Card title={t("secure.facility.league.league_settings.007")}>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Checkbox
                  id="display_online"
                  size="medium"
                  value={league.display_online}
                  checked={league.display_online}
                  onChange={() => setLeague(prevState => ({ ...prevState, display_online: !league.display_online }))}
                  label={"Display Online"}
                  supportText="Would you like to publicly display this league online?"
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <TimePick
                  label="Start Time" // TODO: Translation
                  value={league.start_time}
                  onChange={timeString => setLeague(prev => ({ ...prev, start_time: timeString }))}
                  size="large"
                  align="right"
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <DatePickerInput
                  label="Start Date"
                  startingDate={leagueTimes.start_date}
                  setStartingDate={(date: Date) => setDate(date, "start_date")}
                  months={1}
                  position="left"
                />

                <DatePickerInput
                  label="End Date"
                  startingDate={leagueTimes.end_date}
                  setStartingDate={(date: Date) => setDate(date, "end_date")}
                  months={1}
                  position="left"
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
          <Card.Section title="Logo">
            <div className="stats_bar-picture">
              <CustomerImage
                imageSource={league.logo_source}
                saveProfileImage={imageFile => saveLeagueLogo(imageFile)}
                deleteProfileImage={() => deleteLeagueLogo(leagueId)}
              />
            </div>
          </Card.Section>
          <Card.Section>
            <TextEditor
              markdownText={league.description ?? ""}
              markdownTextOnChange={description => setLeague(prev => ({ ...prev, description: description }))}
              label={"Description"} // TODO: Translation
            />
          </Card.Section>
        </Card>
      </Form>

      {/* <Portal isMounted={removeSponsorshipPopup.isOpen}>
        <Popup
          open={removeSponsorshipPopup.isOpen}
          type={"warning"}
          title={"Warning!"} // TODO: Translation
          description={"Are you sure you want to remove this sponsorship from the league?"} // TODO: Translation
          onCancel={() => closeModal()}
          onOk={() => removeSponsorship()}
          okText="Remove" // TODO: Translation
        />
      </Portal> */}

      <Portal isMounted={deleteLeaguePopup}>
        <Popup
          open={deleteLeaguePopup}
          type={"warning"}
          title={"Warning!"} // TODO: Translation
          description={"Are you sure you want to delete this League?"} // TODO: Translation
          onCancel={() => setDeleteLeaguePopup(false)}
          onOk={() => removeLeague()}
          okText="Remove" // TODO: Translation
        />
      </Portal>
    </Page>
  );
}
