import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Checkbox from "components/form/checkbox/Checkbox";
import { ColumnInstance, TableInstance } from "react-table";
import "./tableFilterDropdown.scss";

interface IProps {
  columns: ColumnInstance<Record<string, any>>[];
  tableInstance: TableInstance<Record<string, any>>;
}

interface IState {
  isFilterDropdownOpen: boolean;
  filterRef: React.MutableRefObject<HTMLDivElement>;
}

export default function TableFilterDropdown(props: IProps) {
  const [state, setState] = useState<IState>({
    isFilterDropdownOpen: false,
    filterRef: useRef<HTMLDivElement>(),
  });

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideFilterDropdownClick);
    document.addEventListener("touchstart", handleOutsideFilterDropdownClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideFilterDropdownClick);
      document.removeEventListener("touchstart", handleOutsideFilterDropdownClick);
    };
  }, []);

  function handleOutsideFilterDropdownClick(event: any) {
    if (!state.filterRef.current || state.filterRef.current.contains(event.target)) {
      return;
    }
    setState(prevState => ({ ...prevState, isFilterDropdownOpen: false }));
  }

  function handleFilterButtonClick() {
    setState(prevState => ({ ...prevState, isFilterDropdownOpen: !prevState.isFilterDropdownOpen }));
  }

  function handleClearButton() {
    props.tableInstance.toggleHideAllColumns(false);
  }

  return (
    <div className="table-filter-dropdown" ref={state.filterRef}>
      <div className="table-filter-dropdown-button" onClick={handleFilterButtonClick}>
        <FontAwesomeIcon fixedWidth icon={["fas", "filter"]} />
        Filter
      </div>
      <div
        className="table-filter-dropdown-content"
        style={{ display: state.isFilterDropdownOpen && props.columns?.length > 0 ? "block" : "none" }}
      >
        <div className="table-filter-dropdown-top-menu">
          <div className="table-filter-dropdown-clear-button" onClick={handleClearButton}>
            Clear
          </div>
          <p>Filters</p>
          <div className="table-filter-dropdown-done-button" onClick={handleFilterButtonClick}>
            Done
          </div>
        </div>
        {props.columns?.map((column, index) => {
          return (
            <div className="table-filter-dropdown-option" key={index}>
              <Checkbox {...column?.getToggleHiddenProps()} label={column.Header} size="small" />
            </div>
          );
        })}
      </div>
    </div>
  );
}
