import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { TTaxLines } from "redux/reducers/models/facility";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseTaxLineUrl = "/" + apiVersion + "/" + adminType + "/client/tax-line";

type TGetTaxLine = {
  id?: number;
  name?: string;
  percentage?: number;
  migrate_tax_line_id?: number;
  accounting_reference_id?: number;
};

type TPostTaxLine = {
  name: string;
  percentage?: number;
  accounting_reference_id?: number;
};

export type TPutTaxLine = {
  id: string | number;
  name: string;
  percentage: number;
  accounting_reference_id?: number;
};

//Get tax lines
export async function GetTaxLine(params: TGetTaxLine, useGlobalLoader?: boolean, token?: CancelToken) {
  return await APIGet<TTaxLines[]>(baseTaxLineUrl, params, useGlobalLoader, token);
}

//Post Tax Lines
export async function PostTaxLine(params: TPostTaxLine, useGlobalLoader?: boolean) {
  return await APIPost(baseTaxLineUrl, params, useGlobalLoader);
}

//Edit Tax Lines
export async function PutTaxLine(params: TPutTaxLine, useGlobalLoader?: boolean) {
  return await APIPut(baseTaxLineUrl, params, useGlobalLoader);
}

//Delete Tax Lines
export async function DeleteTaxLine(params: any, useGlobalLoader?: boolean) {
  return await APIDelete(baseTaxLineUrl, params, useGlobalLoader);
}
