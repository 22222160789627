import { APIGet, APIPost, APIPut, IAPIResponse } from "api/protocols";
import { ITeeTime } from "redux/reducers/models/teetime";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseTemplateUrl = "/" + apiVersion + "/" + adminType + "/tee-sheet/template/shotgun";

export interface IGetShotGunTemplate {
  id: number;
  title?: string;
  extended?: boolean;
}

export interface IShotgunTemplateGroup {
  group_index: number;
  start_hole: string;
  start_time: string;
  checked?: boolean;
  position?: number;
  id?: number;
}

export interface IApplyShotgunTemplate {
  tee_sheet_id: number;
  start_time: string;
  duration: number;
  lead_time: number;
  shotgun_template_id: number;
  division_id: number;
  turn_division_id: number;
}

export interface IPutShotgunTemplate {
  template_id: number;
  title: string;
  groups: Array<IShotgunTemplateGroup>;
  delete_group_ids: Array<number>;
}

// Get Tee Sheet Shotgun Template
export async function GetShotGunTemplate(params: IGetShotGunTemplate, useGlobalLoader: boolean) {
  return await APIGet(baseTemplateUrl, params, useGlobalLoader);
}

// POST Tee Sheet Shotgun Template
export async function PostShotGunTemplate(
  params: { title: string; groups: Array<IShotgunTemplateGroup> },
  useGlobalLoader: boolean,
) {
  return await APIPost(baseTemplateUrl, params, useGlobalLoader);
}

export async function PutShotGunTemplate(params: IPutShotgunTemplate, useGlobalLoader: boolean) {
  return await APIPut(baseTemplateUrl, params, useGlobalLoader);
}

// PUT Generate Shotgun Template
export async function PutShotGunTemplateGenerate(holes: string, useGlobalLoader: boolean) {
  return await APIPut(baseTemplateUrl + "/generate", holes, useGlobalLoader);
}

// Put Tee Sheet Shotgun Template
export async function PutShotGunTemplateApply(params: IApplyShotgunTemplate, useGlobalLoader: boolean) {
  return await APIPut(baseTemplateUrl + "/apply", params, useGlobalLoader);
}

// DELETE Shotgun Template
export async function RemoveShotgunTemplate(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseTemplateUrl + "/remove", params, useGlobalLoader);
}

// POST Tee Time
export async function PostShotgunTeeTime(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseTemplateUrl + "/group/add", params, useGlobalLoader);
}

// PUT Remove Tee Time
export async function PutRemoveShotgunTeeTime(params: { tee_time_id: number }, useGlobalLoader: boolean) {
  return await APIPut(baseTemplateUrl + "/group/remove", params, useGlobalLoader);
}
