import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { TLeagueFlight } from "redux/reducers/models/league";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/league/scoring";

export type TGetLeagueFlight = {
  id?: string | number;
  league_id?: string | number;
  league_division_id?: string | number;
};

export type TPostLeagueFlight = {
  league_id: string | number;
  league_division_id: string | number;
  name: string;
};

export type TPutLeagueFlight = {
  id: string | number; // flight ID
  name: string;
  league_division_id: string | number;
};

export async function GetLeagueFlights(params: TGetLeagueFlight, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<TLeagueFlight[]>(baseUrl + "/flight", params, useGlobalLoader, token);
}

export async function PostLeagueFlight(params: TPostLeagueFlight, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/flight", params, useGlobalLoader);
}

export async function PutLeagueFlights(params: TPutLeagueFlight, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/flight", params, useGlobalLoader);
}

export async function DeleteLeagueFlight(params: { id: string | number }, useGlobalLoader: boolean) {
  return await APIDelete(baseUrl + "/flight", params, useGlobalLoader);
}

/*********************
 * League Flight Participants
 */
export type TGetFlightParticipant = {
  flight_id?: string | number;
};

export type TPostFlightParticipant = {
  flight_id: string | number;
  /** Pass in all participant ids, even if already included */
  participant_ids: Array<string | number>;
};

//GET League flight participants
export async function GetLeagueFlightParticipant(
  params: TGetFlightParticipant,
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseUrl + "/flight/participant", params, useGlobalLoader, token);
}

//PUT League Flight Participant
export async function PostLeagueFlightParticipant(params: TPostFlightParticipant, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/flight/participant", params, useGlobalLoader);
}

export async function GetAllLeagueFlightParticipants(
  params: { league_id: string | number; division_id: string | number },
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet<{ league_flight_id: number; league_participant_id: number }[]>(
    baseUrl + "/flight/participant/all",
    params,
    useGlobalLoader,
    token,
  );
}
