import { IFacility } from "pages/guest/models/facility";
import { ILeague, ILeagueRegistrationFee } from "../models/league";

export interface ILeagueRegistrationAction {
  type: string;
  payload: Partial<ILeagueRegistrationState>;
}

export interface ISelectedLeagueRegistrationFee extends ILeagueRegistrationFee {
  quantity: number;
}

export interface ILeagueRegistrationState {
  facility: IFacility;
  league: ILeague;
  activeRegistrationFees: ISelectedLeagueRegistrationFee[];
}

const initialState: ILeagueRegistrationState = {
  facility: null,
  league: null,
  activeRegistrationFees: null,
};

export default function LeagueRegistrationReducer(state = initialState, action: ILeagueRegistrationAction) {
  switch (action.type) {
    case "leagueRegistration.update":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
