import React, { useEffect, useState } from "react";
import { StatusCode } from "api/protocols";
import "./CustomerBooking.scss";
import "./CustomerBookingConfirmation.scss";
import OrderSummaryLineItem from "components/bookingEngine/orderSummaryLineItem/OrderSummaryLineItem";
import { useHistory, useParams } from "react-router";
import { IBookingEngineState } from "redux/reducers/bookingEngine";
import { IBookingEngineActions } from "redux/actions/actionTypes";
import CustomerNav from "elements/customer/CustomerNav";
import { UserActive } from "api/rpc";
import { IUIActions } from "redux/actions/ui";
import Card from "components/card/Card";
import { ButtonNew as Button } from "components/buttonNew";
import { GetCustomerPaymentMethods } from "api/rpc/2022-09/customer/customer";
import ReactDOM from "react-dom";
import { LocaleCurrency } from "helpers/Locale";
import { GetBooking, GetBookingEngine, GetCart, GetTeeTime } from "api/rpc/2022-09/guest/bookingEngine";
import { convertTime } from "helpers/Helpers";
import { CustomerNavigation } from "elements/customer/CustomerNavigation";
import { IBooking } from "redux/reducers/models/teetime";
import { useTranslation } from "react-i18next";
import { ITaxLine } from "components/OrderSummary/OrderSummary";

interface IProps {
  bookingEngineStore: IBookingEngineState;
  bookingEngineActions: IBookingEngineActions;
  uiActions: IUIActions;
}

interface IParams {
  bookingEngineHandle: string;
  facilityShortName: string;
}

export default function CustomerBookingConfirmation(props: IProps) {
  const { bookingEngineStore, bookingEngineActions, uiActions } = props;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { bookingEngineHandle, facilityShortName } = useParams<IParams>();

  const [activeUser, setActiveUser] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [cartState, setCartState] = useState<Record<string, any>>(null);
  const [editBookingState, setEditBookingState] = useState<boolean>(true);
  // June 6, 2022
  const editDate = new Date("2022-06-06T00:00:00.000000Z").toUTCString();

  const queryString = window.location.search;
  const bookingToken = new URLSearchParams(queryString).get("token");

  function formatDateString(date: string) {
    const tempDateArray = date.split("-");

    //Get full date for tee time
    const d = new Date(Number(tempDateArray[0]), Number(tempDateArray[1]) - 1, Number(tempDateArray[2]));

    const month = d.toLocaleString("en-us", { month: "long" });

    const day = d.toLocaleString("en-us", { day: "numeric" });

    const year = d.toLocaleString("en-us", { year: "numeric" });

    const weekday = d.toLocaleString("en-us", { weekday: "long" });

    const dateString = weekday + " " + month + " " + day + ", " + year;

    return dateString;
  }

  async function getCustomerLoggedIn() {
    // Check if user is logged in
    if (bookingEngineStore.activeCustomerId == null) {
      uiActions.enqueue();
      const activeUserRes = await UserActive(true);
      uiActions.dequeue();
      if (activeUserRes.status === StatusCode.OK) {
        ReactDOM.unstable_batchedUpdates(() => {
          setActiveUser(true);
          bookingEngineActions.update({
            selectedUserFirstName: activeUserRes.data.first_name,
            selectedUserLastName: activeUserRes.data.last_name,
            selectedUserEmail: activeUserRes.data.email,
            selectedUserPhone: activeUserRes.data.phone,
            userFullName: activeUserRes.data.full_name,
            activeCustomerId: activeUserRes.data.id,
          });
        });
      } else {
        //User is not logged in, send them back to the home page
        setActiveUser(false);
        uiActions.showError(t("guest.customer.customer_booking_confirmation.001"));
        void backToTeeTimes();
      }
    } else {
      setActiveUser(true);
    }
  }

  useEffect(() => {
    void getCustomerLoggedIn();
    void setCart();
    if (bookingEngineStore?.postedBooking) {
      void checkBookingEditTime(bookingEngineStore?.postedBooking);
    }
    if (bookingEngineStore.creditCardRequired) {
      void getCustomerPaymentMethod();
    }
  }, []);

  useEffect(() => {
    if (
      bookingEngineStore.activeCustomerId !== null &&
      bookingEngineStore.selectedDate === "" &&
      bookingEngineStore.selectedTeeTimeId === ""
    ) {
      //Page was refreshed, get booking engine and tee time
      void getBookingEngine();
    }
  }, [bookingEngineStore.activeCustomerId]);

  function setCart() {
    if (bookingEngineStore.postedBooking) {
      setCartState(bookingEngineStore.postedBooking.cart);
    }
  }

  async function getBookingEngine() {
    const params = {
      handle: bookingEngineHandle,
    };

    const bookingEngineRes = await GetBookingEngine(params, true);

    if (bookingEngineRes.status !== StatusCode.OK) {
      uiActions.showError(t("guest.customer.customer_booking_confirmation.002"));
      void backToTeeTimes();
      return;
    }

    const bookingRes = await GetBooking({ token: bookingToken, extended: true }, true);
    if (bookingRes.status !== StatusCode.OK || !bookingToken) {
      uiActions.showError(t("guest.customer.customer_booking_confirmation.003"));
      void backToTeeTimes();
      return;
    }

    if (bookingRes.data?.length === 0) {
      uiActions.showError(t("guest.customer.customer_booking_confirmation.004"));
      void backToTeeTimes();
    }

    void checkBookingEditTime(bookingRes.data[0]);

    const teeTimeRes = bookingRes.data[0]?.tee_time;

    const cartRes = await GetCart({ id: bookingRes.data[0]?.cart_id }, true);
    if (cartRes.status !== StatusCode.OK) {
      uiActions.showError(t("guest.customer.customer_booking_confirmation.005"));
      void backToTeeTimes();
      return;
    }

    const fullAddress = `${bookingEngineRes.data[0]?.facility?.address_line_1}, ${bookingEngineRes.data[0]?.facility?.city},
        ${bookingEngineRes.data[0]?.facility?.province_name}, ${bookingEngineRes.data[0]?.facility?.postal}`;

    let convertedStartTime: string = teeTimeRes.start_time;
    if (teeTimeRes?.start_time) {
      convertedStartTime = convertTime(teeTimeRes.start_time);
    }

    let formatDate = teeTimeRes?.date;
    if (teeTimeRes?.date) {
      formatDate = formatDateString(teeTimeRes.date);
    }

    ReactDOM.unstable_batchedUpdates(() => {
      bookingEngineActions.update({
        selectedCourseLogo: bookingEngineRes.data[0].facility.logo_source,
        selectedCourseFacility: bookingEngineRes.data[0].facility.long_name,
        selectedCourseLongName: bookingEngineRes.data[0].facility.long_name,
        selectedLoginRequired: bookingEngineRes.data[0].requires_login,
        selectedFacilityId: bookingEngineRes.data[0].facility_id,
        selectedBookingEngineId: bookingEngineRes.data[0].id,
        selectedBookingTerms: bookingEngineRes.data[0].booking_terms,
        paymentTerms: bookingEngineRes.data[0].payment_terms,
        enabled_payment_methods: bookingEngineRes.data[0].enabled_payment_methods,
        courseLogo: bookingEngineRes.data[0]?.facility.logo_source,
        facilityClientId: bookingEngineRes.data[0].facility.client_id,
        selectedFullAddress: fullAddress,
        creditCardRequired: teeTimeRes?.credit_card_required,
        divisionsAvailable: teeTimeRes?.divisions_available,
        turnTeeTime: teeTimeRes?.turn_tee_time,
        selectedGolferSpotsRemaining: teeTimeRes?.quantity_remaining,
        selectedDate: teeTimeRes?.date,
        selectedStartTime: convertedStartTime,
        selectedFormattedDate: formatDate,
        selectedTeeTimeId: teeTimeRes?.id,
        cartsAvailable: teeTimeRes?.carts_available,
        cart_rule: teeTimeRes?.cart_rule,
        postedBooking: bookingRes?.data[0],
      });

      setCartState(cartRes?.data[0]);
    });
  }

  function backToTeeTimes() {
    bookingEngineActions.update({
      creditCardRequired: null,
      divisionsAvailable: [],
      turnTeeTime: null,
      selectedGolferSpotsRemaining: null,
      selectedDate: "",
      selectedStartTime: "",
      selectedFormattedDate: "",
      selectedTeeTimeId: null,
      cartsAvailable: false,
      cart_rule: null,
      selectedPaymentMethodId: undefined,
      postedBooking: null,
    });
    history.push(`/booking-engine/${facilityShortName}/${bookingEngineHandle}`);
  }

  function editBooking() {
    const address = `/booking-engine/${facilityShortName}/${bookingEngineHandle}/profile/reservations/edit/${bookingEngineStore?.postedBooking?.id}`;
    window.open(address);
  }

  async function getCustomerPaymentMethod() {
    const paymentMethodRes = await GetCustomerPaymentMethods(
      { id: bookingEngineStore.selectedPaymentMethodId, client_id: bookingEngineStore.facilityClientId },
      true,
    );
    if (paymentMethodRes.status !== StatusCode.OK) {
      uiActions.showError(paymentMethodRes.message);
      return;
    }

    if (paymentMethodRes.data?.length >= 1) {
      setPaymentMethod(paymentMethodRes.data[0]);
    }
  }

  function checkBookingEditTime(booking: IBooking) {
    const completedAtDate = new Date(booking?.completed_at).toUTCString();

    const currentDateUTCString = new Date().toUTCString();

    const teeTimeDateSetUp = new Date(booking?.tee_time?.date + "T" + booking?.tee_time?.start_time);
    //Set date-time based on facility timezone
    const teeTimeDateTimezoneString = new Date(teeTimeDateSetUp).toLocaleString("en-US", {
      timeZone: booking?.tee_time?.facility?.timezone,
    });
    const teeTimeEditTime = new Date(teeTimeDateTimezoneString);

    teeTimeEditTime.setHours(teeTimeEditTime.getHours() - booking?.tee_sheet_settings?.web_edit_hours);

    const teeTimeEditTimeUTCString = teeTimeEditTime.toUTCString();

    if (
      Date.parse(completedAtDate) <= Date.parse(editDate) ||
      Date.parse(currentDateUTCString) >= Date.parse(teeTimeEditTimeUTCString)
    ) {
      setEditBookingState(false);
    }
    return;
  }

  return (
    activeUser &&
    cartState && (
      <>
        <div className="cb-background">
          <CustomerNavigation
            //uiActions={uiActions}
            activeUser={activeUser}
            userFirstName={bookingEngineStore.selectedUserFirstName}
            weather={undefined}
          />

          <div className="cb-timer-container">
            <div className="cb-timer-facility-container">
              <span className="cb-timer-course-logo">
                {bookingEngineStore.courseLogo && (
                  <img
                    className="cb-timer-course-logo"
                    src={bookingEngineStore.courseLogo}
                    alt={t("guest.customer.customer_booking_confirmation.006")}
                  />
                )}
              </span>
              <div className="flex flex-col gap-1 bc-text-align-left-responsive">
                <p className="cb-timer-course-name">{bookingEngineStore.selectedCourseLongName}</p>
                <p className="cb-timer-address">{bookingEngineStore.selectedFullAddress}</p>
              </div>
            </div>
          </div>

          <div className="bc-card-container">
            <Card>
              <Card.Section>
                <div className="bc-booked-container bc-flex-column">
                  <p className="bc-booked-text bc-booked-title-text-responsive">
                    {t("guest.customer.customer_booking_confirmation.007")}
                  </p>
                  <p className="bc-booked-text-subtext bc-booked-sub-text-responsive">
                    {!bookingEngineStore.postedBooking?.customer?.email?.includes("guest.teeon.com")
                      ? `${t("guest.customer.customer_booking_confirmation.025")} ${
                          bookingEngineStore.postedBooking?.customer?.email
                        } ${t("guest.customer.customer_booking_confirmation.026")}`
                      : `${t("guest.customer.customer_booking_confirmation.027")}`}
                  </p>
                  <div className="bc-booked-confirmation-container">
                    <p className="bc-booked-text-confirmation bc-booked-sub-text-responsive">
                      {t("guest.customer.customer_booking_confirmation.008")}
                    </p>
                    <p className="bc-booked-text-confirmation-number bc-booked-sub-text-responsive">
                      {bookingEngineStore.postedBooking?.confirmation_number}
                    </p>
                  </div>
                </div>
              </Card.Section>
            </Card>
          </div>

          <div className="bc-card-container">
            <Card>
              <Card.Section>
                <p className="bc-booking-details-card-title">{t("guest.customer.customer_booking_confirmation.009")}</p>
                <div className="bc-booking-details-content">
                  <div className="bc-booking-details-container">
                    <p className="bc-booking-details-title bc-booked-title-text-responsive">
                      {t("guest.customer.customer_booking_confirmation.010")}
                    </p>
                    <div className="bc-booking-details-golfer-details bc-flex-column bc-flex-grow">
                      <p className="mb-1 bc-booked-sub-text-responsive">
                        {bookingEngineStore.postedBooking?.customer?.full_name}
                      </p>
                      {!bookingEngineStore?.postedBooking?.customer?.email?.includes("guest.teeon.com") && (
                        <p className="bc-booked-sub-text-responsive">
                          {bookingEngineStore?.postedBooking?.customer?.email}
                        </p>
                      )}
                      <p className="bc-booked-sub-text-responsive">
                        {bookingEngineStore?.postedBooking?.customer?.phone}
                      </p>
                    </div>
                  </div>

                  <div className="bc-booking-details-container">
                    <p className="bc-booking-details-title bc-booked-title-text-responsive">
                      {t("guest.customer.customer_booking_confirmation.011")}
                    </p>
                    <div className="bc-booking-details-order-summary bc-flex-column bc-flex-grow">
                      <p className="font-bold bc-booked-sub-text-responsive bc-booked-sub-text-responsive">
                        {bookingEngineStore?.selectedCourseLongName}
                      </p>
                      <p className="bc-booking-details-order-summary-date-and-time bc-booked-sub-text-responsive">
                        {bookingEngineStore?.selectedFormattedDate} • {bookingEngineStore?.selectedStartTime}
                      </p>
                      <p className="font-semibold bc-booked-sub-text-responsive">
                        {bookingEngineStore?.postedBooking?.holes}{" "}
                        {t("guest.customer.customer_booking_confirmation.012")}{" "}
                        {bookingEngineStore?.postedBooking?.quantity} Golfer
                        {bookingEngineStore?.postedBooking?.quantity > 1 ? "s " : " "}•{" "}
                        {bookingEngineStore?.postedBooking?.power_cart_quantity}{" "}
                        {t("guest.customer.customer_booking_confirmation.013")}
                        {bookingEngineStore?.postedBooking?.power_cart_quantity === 1 ? " " : "s"}
                      </p>
                      <div>
                        <p className="font-bold bc-booked-sub-text-responsive">
                          {t("guest.customer.customer_booking_confirmation.014")}
                        </p>
                        {cartState?.line_items?.map((lineItem: Record<string, any>, index: number) => {
                          return (
                            <OrderSummaryLineItem
                              key={index}
                              lineItemQuantity={lineItem.quantity}
                              lineItem={lineItem.product_title}
                              lineItemVariant={lineItem.variant_title}
                              prepaid={lineItem.pre_paid_required}
                              itemValue={
                                <LocaleCurrency currency={cartState?.currency} amount={lineItem.subtotal_price} />
                              }
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="bc-booking-details-container">
                    <p className="bc-booking-details-title bc-booked-title-text-responsive">
                      {t("guest.customer.customer_booking_confirmation.015")}
                    </p>
                    <div className="bc-flex-column w-60 bc-width-auto bc-flex-grow">
                      {bookingEngineStore.creditCardRequired && paymentMethod?.brand && paymentMethod?.last4 ? (
                        <p className="bc-booking-payment-card bc-booked-sub-text-responsive">
                          {String(paymentMethod?.brand).charAt(0).toUpperCase() + String(paymentMethod?.brand).slice(1)}{" "}
                          {t("guest.customer.customer_booking_confirmation.016")} {paymentMethod?.last4}
                        </p>
                      ) : (
                        <p></p>
                      )}
                      <div className="flex flex-row justify-between">
                        <p className="font-medium bc-booked-sub-text-responsive">
                          {t("guest.customer.customer_booking_confirmation.017")}
                        </p>
                        <p className="font-medium bc-booked-sub-text-responsive">
                          <LocaleCurrency currency={cartState?.currency} amount={cartState?.subtotal_price} />
                        </p>
                      </div>
                      {cartState?.tax_lines?.map((taxLine: ITaxLine, index: number) => {
                        return (
                          <div className="flex flex-row justify-between" key={index}>
                            <p className="font-medium bc-booked-sub-text-responsive">
                              {taxLine?.title}{" "}
                              {taxLine?.rate && <span className="bc-booked-tax-rate">{`${taxLine?.rate * 100}%`}</span>}
                            </p>
                            <p className="font-medium bc-booked-sub-text-responsive">
                              <LocaleCurrency currency={cartState?.currency} amount={taxLine?.price} />
                            </p>
                          </div>
                        );
                      })}
                      <div className="flex flex-row justify-between">
                        <p className="font-medium bc-booked-sub-text-responsive">
                          {t("guest.customer.customer_booking_confirmation.019")}
                        </p>
                        <p className="font-medium bc-booked-sub-text-responsive">
                          <LocaleCurrency currency={cartState?.currency} amount={cartState?.total_price} />
                        </p>
                      </div>
                      <hr />
                      <div className="flex flex-row justify-between">
                        <p className="font-bold bc-booked-sub-text-responsive">
                          {t("guest.customer.customer_booking_confirmation.020")}
                        </p>
                        <p className="font-bold bc-booked-sub-text-responsive">
                          <LocaleCurrency
                            currency={cartState?.currency}
                            amount={
                              cartState?.total_price_due
                                ? cartState?.total_price - cartState?.total_price_due
                                : cartState?.total_price
                            }
                          />
                        </p>
                      </div>
                      {cartState?.total_price_due ? (
                        <div className="flex flex-row justify-between">
                          <p className="font-bold bc-booked-sub-text-responsive">
                            {t("guest.customer.customer_booking_confirmation.021")}
                          </p>
                          <p className="font-bold bc-booked-sub-text-responsive">
                            <LocaleCurrency currency={cartState?.currency} amount={cartState?.total_price_due} />
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="bc-tee-times-button">
                  <Button type="primary" size="medium" onClick={backToTeeTimes}>
                    {t("guest.customer.customer_booking_confirmation.022")}
                  </Button>
                  <Button type="secondary" size="medium" onClick={editBooking}>
                    {t("guest.customer.customer_booking_confirmation.023")}
                  </Button>
                  {editBookingState && (
                    <Button type="secondary" size="medium" onClick={editBooking}>
                      {t("guest.customer.customer_booking_confirmation.024")}
                    </Button>
                  )}
                </div>
              </Card.Section>
            </Card>
          </div>
        </div>
      </>
    )
  );
}
