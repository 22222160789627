import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Checkbox from "components/form/checkbox/Checkbox";
import Sheet from "components/sheet/Sheet";
import Input from "components/form/input/Input";
import { Select as SelectNew } from "components/select/";
import { AccountingReferenceType, HouseAccountType } from "../../context/contextTypes";

type NewCustomerHouseAccountModalProps = {
  customerId: number;
  accountingReferenceTypes: AccountingReferenceType[] | null;
  isVisible: boolean;
  onClose: () => void;
  onOk: (houseAccount: HouseAccountType, isValid: boolean) => void;
};

export default function NewCustomerHouseAccountModal(props: NewCustomerHouseAccountModalProps) {
  const { customerId, accountingReferenceTypes, isVisible, onClose } = props;

  const { t } = useTranslation();
  const { Option } = SelectNew;

  const [houseAccount, setHouseAccount] = useState<HouseAccountType & { limit_is_valid: boolean }>({
    account_number: "",
    accounting_reference_id: 0,
    balance: 0,
    client_id: undefined,
    currency: "",
    customer_id: customerId,
    facility_id: undefined,
    global: false,
    id: undefined,
    limit: "",
    title: "",
    limit_is_valid: false,
  });

  function handleCloseModal() {
    setHouseAccount(prevState => ({
      ...prevState,
      global: false,
      accounting_reference_id: 0,
      account_number: "",
      limit: "",
      title: "",
    }));

    onClose();
  }

  function handleChange(event: any) {
    const id = event?.target?.id;
    const value = event?.target?.value;

    if (id === undefined || value === undefined) {
      return;
    }

    setHouseAccount(prevState => ({ ...prevState, [id]: value }));
  }

  function handleNumberChange(event: any, propertyToChange: string, validFlagToChange: string) {
    const value = event?.target?.value;

    if (value === undefined) {
      return;
    }

    const valueCharacters = value.split("");
    setHouseAccount(prevState => ({
      ...prevState,
      [propertyToChange]: value,
      [validFlagToChange]: valueCharacters.length > 0 && valueCharacters.every((v: any) => !Number.isNaN(Number(v))),
    }));
  }

  function handleOnOk(houseAccount: HouseAccountType, isValid: boolean) {
    props.onOk(houseAccount, isValid);
    handleCloseModal();
  }

  return (
    <Sheet
      open={isVisible}
      size="medium"
      height="flexible"
      closable
      title={t("secure.facility.customer.tabs.house_accounts.new_customer_house_account_modal.001")}
      onCancel={() => handleCloseModal()}
      onOk={() => handleOnOk(houseAccount, houseAccount.limit_is_valid)}
      cancelText={t("secure.facility.customer.tabs.house_accounts.new_customer_house_account_modal.002")}
      okText={t("secure.facility.customer.tabs.house_accounts.new_customer_house_account_modal.003")}
      okDisabled={houseAccount.title === "" || !houseAccount.limit_is_valid ? true : false}
    >
      <Form>
        <FormLayout>
          <FormLayout.Group>
            <Input
              value={houseAccount.title}
              label={t("secure.facility.customer.tabs.house_accounts.new_customer_house_account_modal.004")}
              id="title"
              placeholder={t("secure.facility.customer.tabs.house_accounts.new_customer_house_account_modal.010")}
              onChange={handleChange}
            />
            <Input
              value={houseAccount.account_number}
              id="account_number"
              label={t("secure.facility.customer.tabs.house_accounts.new_customer_house_account_modal.005")}
              onChange={handleChange}
              placeholder={t("secure.facility.customer.tabs.house_accounts.new_customer_house_account_modal.011")}
              helpText={t("secure.facility.customer.tabs.house_accounts.new_customer_house_account_modal.006")}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <Input
              value={houseAccount.limit}
              label={t("secure.facility.customer.tabs.house_accounts.new_customer_house_account_modal.007")}
              id={Number(houseAccount.limit) > 0 && !houseAccount.limit_is_valid ? "inputError" : ""}
              onChange={(e: any) => handleNumberChange(e, "limit", "limit_is_valid")}
              placeholder={t("secure.facility.customer.tabs.house_accounts.new_customer_house_account_modal.012")}
            />
            {accountingReferenceTypes && (
              <SelectNew
                label={t("secure.facility.customer.tabs.house_accounts.new_customer_house_account_modal.008")}
                defaultValue={houseAccount.accounting_reference_id}
                onChange={(value: number) => setHouseAccount({ ...houseAccount, accounting_reference_id: value })}
                placeholder={t("secure.facility.customer.tabs.house_accounts.new_customer_house_account_modal.013")}
              >
                {accountingReferenceTypes.map((type, index: number) => {
                  return (
                    <Option key={index} value={type.value} name={type.label}>
                      <span>{type.label}</span>
                    </Option>
                  );
                })}
              </SelectNew>
            )}
          </FormLayout.Group>

          <FormLayout.Group>
            <Checkbox
              id="global"
              size="medium"
              value={houseAccount.global}
              checked={houseAccount.global}
              onChange={({ target }) =>
                setHouseAccount({ ...houseAccount, [target.id]: target.value === "true" ? false : true })
              }
              label={t("secure.facility.customer.tabs.house_accounts.new_customer_house_account_modal.009")}
            ></Checkbox>
          </FormLayout.Group>
        </FormLayout>
      </Form>
    </Sheet>
  );
}
