import React, { useEffect, useState } from "react";
import axios, { CancelToken } from "axios";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { GetAccountingReference } from "api/rpc/2024-04/facilityAdmin/facility/accounting";
import { PostPaymentOptions, TPostPaymentOption } from "api/rpc/2024-04/facilityAdmin/facility/paymentOption";

import { showError, showSuccess } from "redux/actions/ui";
import { TAccountingReference } from "redux/reducers/models/transaction";
import { useAppDispatch } from "hooks/redux";
import { handleChangeEventInput, unsavedChangesExist } from "helpers/Helpers";

import Card from "components/card/Card";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import Page from "components/page/Page";
import { Select } from "components/select/index";

const defaultState: TPostPaymentOption = {
  title: "",
  source: "in_person",
  accounting_reference_id: "-1",
};

export default function FacilityPaymentOptionsNew() {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();

  const [paymentOption, setPaymentOption] = useState(defaultState);
  const [paymentOptionBeforeChanges, setPaymentOptionBeforeChanges] = useState<TPostPaymentOption>(defaultState);

  const [accountingReferences, setAccountingReferences] = useState<TAccountingReference[]>([]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadAccountingReferenceTypes(source.token);
    return () => source.cancel();
  }, []);

  async function loadAccountingReferenceTypes(token?: CancelToken) {
    const accountingResponse = await GetAccountingReference(null, token ? false : true, token);

    if (token && token.reason) {
      return;
    }

    if (accountingResponse.status !== StatusCode.OK) {
      dispatch(showError("Error loading accounting references."));
    }

    setAccountingReferences(
      accountingResponse.status !== StatusCode.OK ? [] : (accountingResponse.data as TAccountingReference[]),
    );
  }

  async function saveNewPaymentOption() {
    const res = await PostPaymentOptions(paymentOption, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError(typeof res.data === "string" ? res.data : "Error creating new payment option."));
      return;
    }

    dispatch(showSuccess("Payment option created successfully."));
    history.push("/admin/settings/facility-payment-options");
  }

  return (
    <Page
      title={t("secure.facility.settings.facility.facility_payments_options_edit_new.001")}
      narrow
      breadcrumbs={[
        {
          label: t("secure.facility.settings.facility.facility_payments_options_edit_new.002"),
          url: "/admin/settings/facility-payment-options",
        },
      ]}
      notificationBarProps={{
        isVisible: unsavedChangesExist(paymentOption, paymentOptionBeforeChanges),
        onAction: saveNewPaymentOption,
        onCancel: () => setPaymentOption(paymentOptionBeforeChanges),
      }}
    >
      <Form>
        <Card>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={paymentOption.title}
                  label={t("secure.facility.settings.facility.facility_payments_options_edit_new.004")}
                  id="title"
                  onChange={e => handleChangeEventInput(e, paymentOption, setPaymentOption)}
                  placeholder={t("secure.facility.settings.facility.facility_payments_options_edit_new.005")}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Select
                  label={t("secure.facility.settings.facility.facility_payments_options_edit_new.006")}
                  defaultValue={paymentOption.accounting_reference_id}
                  onChange={(value: string) =>
                    setPaymentOption(prev => ({ ...prev, accounting_reference_id: value }))
                  }
                >
                  <Select.Option key={"none"} value={"-1"} name="none">
                    <div style={{ minHeight: "32px" }}></div>
                  </Select.Option>
                  {accountingReferences?.map((type, index: number) => {
                    return (
                      <Select.Option key={index} value={type.id} name={type.title}>
                        <span>{type.title}</span>
                      </Select.Option>
                    );
                  })}
                </Select>
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>
      </Form>
    </Page>
  );
}
