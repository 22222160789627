import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { IFacility } from "redux/reducers/models/facility";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/facility";

// GET Courses
export async function GetCourse(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseUrl + "/course", params, useGlobalLoader);
}

// POST Course
export async function PostCourse(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/course", params, useGlobalLoader);
}

// PUT Course
export async function PutCourse(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/course", params, useGlobalLoader);
}
