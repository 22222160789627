import { ITable } from "api/rpc/facilityAdmin/tables/tables";
import { ICart, ICartLineItem } from "./models/cart";

export interface ICartState {
  isLoaded: boolean;
  error?: any;
  cart: ICart;
  token: string;
  line_items: ICartLineItem[];
  line_item_count: number;
  tabCarts: ICart[];
  activeTable: ITable;
  tables: Array<ITable>;
}

export interface ICartAction {
  type: string;
  payload: Partial<ICartState>;
}

const initialState: ICartState = {
  isLoaded: false,
  error: null,
  cart: null,
  token: null,
  line_items: null,
  line_item_count: 0,
  tabCarts: null,
  activeTable: null,
  tables: null,
};

export default function CartReducer(state = initialState, action: ICartAction) {
  switch (action.type) {
    case "cart.update":
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        ...state,
        ...action.payload,
      };
    case "cart.clear":
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
