import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import {
  DeleteLeagueFlight,
  GetLeagueFlights,
  PutLeagueFlights,
} from "api/rpc/2024-04/facilityAdmin/league/scoring/flight";

import { showError, showSuccess } from "redux/actions/ui";
import { TLeagueFlight } from "redux/reducers/models/league";
import { useAppDispatch } from "hooks/redux";
import useModal from "hooks/modals/useModal";
import { handleChangeEventInput, unsavedChangesExist } from "helpers/Helpers";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import Popup from "components/popup/Popup";
import Portal from "elements/Portal";

export default function LeagueFlightsEdit() {
  const history = useHistory();
  const { t } = useTranslation();
  const { leagueId, divisionId, flightId } = useParams<{ leagueId: string; divisionId: string; flightId: string }>();

  const dispatch = useAppDispatch();

  const { state: deleteModal, updateModal, closeModal } = useModal();

  const [flightState, setFlightState] = useState<Partial<TLeagueFlight>>({
    name: "",
  });

  // unsavedChanges bar toggle
  const [preserved, setPreserved] = useState<Partial<TLeagueFlight>>({ name: "" });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadFlight(flightId, source.token);
    return () => source.cancel();
  }, [flightId]);

  async function loadFlight(id: string, token?: CancelToken) {
    const res = await GetLeagueFlights({ id }, true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error getting flight")); // TODO: Translation
      history.push(`/admin/league/${leagueId}/divisions/${divisionId}/flights`);
      return;
    }

    setFlightState(prevState => ({ ...prevState, name: res.data[0]?.name }));
    setPreserved(prev => ({ ...prev, name: res.data[0]?.name }));
  }

  async function updateFlight() {
    const updateRes = await PutLeagueFlights(
      { id: Number(flightId), league_division_id: divisionId, name: flightState.name },
      true,
    );
    if (updateRes.status !== StatusCode.OK) {
      dispatch(showError("Error updating flight")); // TODO: Translation
      return;
    }
    dispatch(showSuccess("Succesfully updated flight")); // TODO: Translation
    history.push(`/admin/league/${leagueId}/divisions/${divisionId}/flights`);
  }

  async function deleteFlight() {
    const deleteRes = await DeleteLeagueFlight({ id: Number(flightId) }, true);

    if (deleteRes.status !== StatusCode.OK) {
      dispatch(showError("Error deleting flight")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Successfully removed flight from division")); // TODO: Translation
    history.push(`/admin/league/${leagueId}/divisions/${divisionId}/flights`);
  }

  return (
    <Page
      title={"Edit Flight"} // TODO: Translation
      narrow
      multipleActionDropdownAction={{
        label: "Options", // TODO: Translation
        dropdownProps: {
          alignment: "right",
          options: [
            {
              type: "handler",
              label: "Delete", // TODO: Translation
              handler: () => updateModal({ isOpen: true }),
              // icon: "print",
            },
            {
              type: "handler",
              label: "Manage Players", // TODO: Translation
              handler: () => history.push(`/admin/league/${leagueId}/divisions/${divisionId}/flights/manage_players`),
              // icon: "file-arrow-down",
            },
          ],
        },
      }}
      notificationBarProps={{
        isVisible: unsavedChangesExist(flightState, preserved),
        onAction: () => updateFlight(),
        onCancel: () => setFlightState(preserved),
      }}
      breadcrumbs={[
        {
          prefix: true,
          label: "Flights", // TODO: Translation
          url: `/admin/league/${leagueId}/divisions/${divisionId}/flights`,
        },
      ]}
    >
      <Form>
        {/* TODO: Translation */}
        <Card title={"Flight Details"}>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  id="name"
                  value={flightState.name ?? ""}
                  label={t("secure.facility.league.new.012")}
                  onChange={e => handleChangeEventInput(e, flightState, setFlightState)}
                  placeholder={t("secure.facility.league.new.012")}
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>
      </Form>

      <Portal isMounted={deleteModal.isOpen}>
        <Popup
          open={deleteModal.isOpen}
          type="warning"
          title="Delete Flight?" // TODO: Translation
          description="Are you sure you want to delete this flight?" // TODO: Translation
          onCancel={closeModal}
          onOk={() => deleteFlight()}
          okText="Delete" // TODO: Translation
        />
      </Portal>
    </Page>
  );
}
