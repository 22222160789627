import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";
import { useAppDispatch } from "hooks/redux";

import { GetAuditTrails } from "api/rpc/2024-04/facilityAdmin/teesheet/booking";
import { GetTeeTimeAuditTrails } from "api/rpc/2024-04/facilityAdmin/teesheet/teeSheet";
import { StatusCode } from "api/protocols";

import { convertTime } from "helpers/Helpers";

import { showError } from "redux/actions/ui";

import Sheet from "components/sheet/Sheet";
import AuditTrailNote from "components/bookingPopUp/auditTrailNote/AuditTrailNote";
import Spin from "components/spin/spin";

import { ITeeTimeNew, IAuditTrail } from "redux/reducers/models/teetime";

interface IAuditTrailModal {
  open: boolean;
  teetime: ITeeTimeNew;
  bookingId: number;
  onCancel: () => void;
  type: "teeTime" | "booking";
}

export default function AuditTrailModal(props: IAuditTrailModal) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { open, teetime, bookingId, onCancel, type } = props;

  const [auditTrails, setAuditTrails] = useState<IAuditTrail[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadAuditTrails(source.token);
    return () => source.cancel();
  }, []);

  async function loadAuditTrails(token?: CancelToken) {
    if (type === "teeTime") {
      const auditRes = await GetTeeTimeAuditTrails({ tee_time_id: teetime.slots[0].tee_time_id }, false, token);

      if (auditRes.status !== StatusCode.OK) {
        dispatch(showError("Error loading audit trail"));
        return;
      }

      setAuditTrails(auditRes.data);
    } else if (type === "booking") {
      const auditRes = await GetAuditTrails({ tee_time_booking_id: bookingId }, false, token);

      if (auditRes.status !== StatusCode.OK) {
        dispatch(showError("Error loading audit trail"));
        return;
      }

      setAuditTrails(auditRes.data);
    }
  }

  return (
    <Sheet
      open={open}
      size={"small"}
      closable
      title={`${t("elements.tee_sheet.tee_sheet_tabs.106")} ${teetime ? convertTime(teetime?.start_time) : null}`}
      onCancel={() => onCancel()}
      cancelText={t("elements.tee_sheet.tee_sheet_tabs.107")}
    >
      <p className="teetime-modal-details-options mb-4">{t("elements.tee_sheet.tee_sheet_tabs.127")}</p>
      {auditTrails ? (
        <div>
          {auditTrails.length > 0 ? (
            auditTrails.map((audit: any, index: number) => {
              return (
                <React.Fragment key={index}>
                  <AuditTrailNote
                    title={audit.title}
                    author={audit.author}
                    actions={audit.actions}
                    action={audit.action}
                    timestamp={audit.created_at}
                    last={index + 1 === auditTrails.length ? true : false}
                  />
                </React.Fragment>
              );
            })
          ) : (
            <span>{t("elements.tee_sheet.tee_sheet_tabs.108")}</span>
          )}
        </div>
      ) : (
        <div style={{ height: "25px", width: "25px" }}>
          <Spin />
        </div>
      )}
    </Sheet>
  );
}
