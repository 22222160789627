import React, { useEffect, useState } from "react";
import classNames from "classnames";
import "components/text/heading/heading.scss";

// Available Props

const Heading = (props: any) => (
  <>
    {!props.element ? <h2 className="ui-heading text-xl font-semibold leading-8 m-0">{props.children}</h2> : null}
    {props.element === "h1" ? (
      <h1 className="ui-heading text-xl font-semibold leading-8 m-0">{props.children}</h1>
    ) : null}
    {props.element === "h2" ? (
      <h2 className="ui-heading text-xl font-semibold leading-8 m-0">{props.children}</h2>
    ) : null}
    {props.element === "h3" ? (
      <h3 className="ui-heading text-xl font-semibold leading-8 m-0">{props.children}</h3>
    ) : null}
    {props.element === "h4" ? (
      <h4 className="ui-heading text-xl font-semibold leading-8 m-0">{props.children}</h4>
    ) : null}
    {props.element === "h5" ? (
      <h5 className="ui-heading text-xl font-semibold leading-8 m-0">{props.children}</h5>
    ) : null}
    {props.element === "h6" ? (
      <h6 className="ui-heading text-xl font-semibold leading-8 m-0">{props.children}</h6>
    ) : null}
    {props.element === "p" ? <p className="ui-heading text-xl font-semibold leading-8 m-0">{props.children}</p> : null}
  </>
);

export default Heading;
