import { ILocation, ISegment } from "redux/reducers/models/reservations";
import { IBookedSegmentItem } from "./BookedSegment";
import React, { useMemo, useState } from "react";
import { ConnectDropTarget, useDrop } from "react-dnd";
import Portal from "elements/Portal";
import Popup from "components/popup/Popup";

export const BOOKED_SEGMENT_DROP_TYPE = "BOOKED_SEGMENT_DROP_TYPE";

type UseDropBookedSegment = (
  dropSegment: ISegment,
  dropLocation: ILocation,
  availableDropHours: number,
  moveBookingToDropSegment: MoveBookingToDropSegmentAction,
) => IUseDropBookedSegmentState;

interface IUseDropBookedSegmentState {
  isOver: boolean;
  canDrop: boolean;
  drop: ConnectDropTarget;
  dropConfirmationPopup: JSX.Element;
}

export type MoveBookingToDropSegmentAction = (
  dropSegment: ISegment,
  dropLocation: ILocation,
  bookedHours: number,
  bookingId: number,
) => Promise<void>;

interface IDropConfirmationPopupState {
  open: boolean;
  item: IBookedSegmentItem;
}

export const useDropBookedSegment: UseDropBookedSegment = (
  dropSegment,
  dropLocation,
  availableDropHours,
  moveBookingToDropSegment,
) => {
  const [dropConfirmationPopupState, setDropConfirmationPopupState] = useState<IDropConfirmationPopupState>({
    open: false,
    item: undefined,
  });

  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: BOOKED_SEGMENT_DROP_TYPE,
      canDrop: (item: IBookedSegmentItem, monitor) => {
        return availableDropHours >= item.bookedHours;
      },
      drop: (item: IBookedSegmentItem, monitor) => {
        if (dropSegment?.id === item?.startingSegment?.id) {
          return;
        }

        setDropConfirmationPopupState({
          open: true,
          item,
        });
      },
      collect: monitor => ({
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver(),
      }),
    }),
    [dropSegment, availableDropHours],
  );

  function closeDropConfirmationPopup() {
    setDropConfirmationPopupState({
      open: false,
      item: undefined,
    });
  }

  async function moveBooking() {
    await moveBookingToDropSegment(
      dropSegment,
      dropLocation,
      dropConfirmationPopupState.item?.bookedHours,
      dropConfirmationPopupState.item?.segment.booking_id,
    );

    closeDropConfirmationPopup();
  }

  function convert24ClockTimeTo12(time: string) {
    if (!time) {
      return "";
    }

    const [hour, minute, second] = time.split(":");
    const dateWithTime = new Date(0, 0, 0, Number(hour), Number(minute), Number(second));
    const formattedTime = dateWithTime.toLocaleString("en-CA", { hour: "numeric", minute: "numeric", hour12: true });

    return formattedTime;
  }

  const dropConfirmationPopup = useMemo(() => {
    return (
      <Portal isMounted={dropConfirmationPopupState.open}>
        <Popup
          open={dropConfirmationPopupState.open}
          type={"warning"}
          title={"Move Booking?"}
          description={`Are you sure you want to move the booking at 
            ${convert24ClockTimeTo12(dropConfirmationPopupState.item?.startingSegment?.start_time)} from 
            ${dropConfirmationPopupState.item?.location?.title} 
            to ${convert24ClockTimeTo12(dropSegment?.start_time)} in ${dropLocation?.title}?`}
          onCancel={closeDropConfirmationPopup}
          onOk={moveBooking}
        />
      </Portal>
    );
  }, [dropConfirmationPopupState, dropSegment, dropLocation]);

  return {
    isOver,
    canDrop,
    drop,
    dropConfirmationPopup,
  };
};
