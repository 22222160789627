import React from "react";
import { ButtonNew as Button } from "components/buttonNew";
import Card from "components/card/Card";
import Checkbox from "components/form/checkbox/Checkbox";
import { useAppSelector } from "hooks/redux";
import { IClientPortalBookingState, IClientPortalPaymentState, PaymentStep } from "./ClientPortalBooking";
import { Select } from "components/select/index";
import "./clientPortalBooking.scss";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { MOBILE_WIDTH } from "helpers/ScreenSizes";

interface IProps {
  bookingState: IClientPortalBookingState;
  paymentState: IClientPortalPaymentState;
  handleCardDropDownChange: (value: number, id: string, card: Record<string, any>) => void;
  openNewCardModal: () => void;
  handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  returnToHomePage: (deleteLock: boolean) => void;
  disableCheckout: () => boolean;
  displayPrePaidWarning: () => void;
  displayBookingFeeWarning: () => void;
  checkFriendsToAdd: () => void;
  displayTerms: (e: React.MouseEvent) => void;
  bookingFeePaid: boolean;
}

export default function ClientPortalBookingCard(props: IProps) {
  const clientPortalStore = useAppSelector(store => store?.clientPortalStore);
  const {
    bookingState,
    paymentState,
    handleCardDropDownChange,
    openNewCardModal,
    handleCheckboxChange,
    returnToHomePage,
    disableCheckout,
    displayPrePaidWarning,
    displayBookingFeeWarning,
    checkFriendsToAdd,
    displayTerms,
    bookingFeePaid,
  } = props;
  const { Option } = Select;
  const windowSize = useWindowSize();

  return (
    <Card>
      <Card.Section>
        <p className="client-portal-booking-booking-details-selection-title">{"Booking Info"}</p>
        {clientPortalStore?.selectedTeeTime?.credit_card_required ||
        clientPortalStore?.selectedTeeTime?.pre_paid_required ||
        bookingState?.cart?.total_price_due ? (
          <div className="client-portal-booking-dropdown">
            <Select
              label={"Credit Card"}
              defaultValue={bookingState.selectedCardId}
              onChange={(value: number, card: Record<string, any>) =>
                handleCardDropDownChange(value, "selectedCardId", card)
              }
              disabled={paymentState?.step >= PaymentStep.put_capture && !!paymentState?.order}
            >
              <li className="ui-select-dropdown-list-item" onClick={openNewCardModal}>
                <p className="text-md">{"Add New Card"}</p>
              </li>
              {bookingState.customerCreditCards?.map((card, index) => {
                return (
                  <Option key={index} value={card?.id} name={card?.brand} extraValues={card}>
                    <span>
                      {String(card?.brand)?.charAt(0).toUpperCase()}
                      {String(card?.brand).slice(1)} •••• •••• •••• {card?.last4}
                    </span>
                  </Option>
                );
              })}
            </Select>
          </div>
        ) : null}

        <div className="client-portal-booking-terms">
          <Checkbox
            id="acceptTerms"
            size="small"
            value={bookingState.acceptTerms}
            checked={bookingState.acceptTerms}
            onChange={handleCheckboxChange}
            borderStyle={{ outline: "1px solid black" }}
            label={
              <span>
                I agree to the{" "}
                <span onClick={e => displayTerms(e)} className="client-portal-booking-conditions">
                  Booking and Payment terms of {clientPortalStore?.selectedFacility?.long_name}
                </span>
              </span>
            }
          />
        </div>
        <div className="client-portal-booking-booking-details-selection-confirm-button-group">
          <Button
            block={windowSize.width <= MOBILE_WIDTH}
            className="w-100"
            size="medium"
            type="default"
            onClick={() => returnToHomePage(true)}
          >
            {"Back"}
          </Button>
          <Button
            className="w-100"
            size="medium"
            type="primary"
            disabled={disableCheckout()}
            block={windowSize.width <= MOBILE_WIDTH}
            onClick={
              clientPortalStore?.selectedTeeTime?.pre_paid_required
                ? displayPrePaidWarning
                : bookingState?.cart?.total_price_due &&
                  paymentState?.step >= PaymentStep.post_cart &&
                  paymentState?.step <= PaymentStep.put_capture
                ? displayBookingFeeWarning
                : checkFriendsToAdd
            }
          >
            {bookingState?.cart?.total_price_due && !bookingFeePaid ? "Book and Pay" : "Confirm and Book"}
          </Button>
        </div>
      </Card.Section>
    </Card>
  );
}
