import React from "react";
import classNames from "classnames";

import Icon from "components/icon/Icon";

import "components/tabs/tabs.scss";
import { ICustomer } from "redux/reducers/models/customer";

type Props<T> = {
  tabs: GenericTab<T>[];
  secondaryTabs?: SecondaryTab[];
  selected: number;
  onSelect: (index: number) => void;
  fitted?: boolean;
  children?: any;
};

// Generate compatible tabs type from an optionally given type.
export type GenericTab<T = Record<string, any>> = T & {
  content: string | Element | JSX.Element;
  counter?: number;
  disabled?: boolean;
};

type SecondaryTab = {
  icon: string; // with 'far' icon type
  onSelect: (index: number) => void;
};

function Tabs<T>(props: Props<T>) {
  return (
    <div>
      <div className="ui-tabs_wrapper">
        <ul
          className={classNames("ui-tabs", {
            "ui-tabs--fitted": props.fitted,
          })}
        >
          <div className="ui-tabs_primary">
            {props.tabs.map((tab, index: number) => (
              <li className="ui-tabs_tab-container" key={index}>
                <button
                  className={classNames("ui-tabs_tab", {
                    "ui-tabs_tab--selected": props.selected === index,
                    "ui-tabs_tab--disabled": tab.disabled ?? false,
                  })}
                  onClick={() => props.onSelect(index)}
                  disabled={tab.disabled ?? false}
                >
                  <span className="ui-tabs_title">
                    {tab.content}
                    <span className="ui-tabs_counter">{tab.counter}</span>
                  </span>
                </button>
              </li>
            ))}
          </div>
          <div className="ui-tabs_secondary">
            {props.secondaryTabs ? (
              <>
                {props.secondaryTabs.map((tab, index: number) => (
                  <li className="ui-tabs_tab-container" key={index}>
                    {/* eslint-disable-next-line @typescript-eslint/no-unsafe-return */}
                    <button onClick={() => tab.onSelect(index)}>
                      <span className="ui-tabs_title-secondary">
                        <Icon icon={tab.icon} style="far" />
                      </span>
                    </button>
                  </li>
                ))}
              </>
            ) : null}
          </div>
        </ul>
      </div>
      <div className="ui-tabs_panel">{props.children}</div>
    </div>
  );
}

export default Tabs;
