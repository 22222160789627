import { GetOrder } from "api/rpc/order/order";
// import { LocalStorage } from "api/localstorage";
// import { IOrderState } from "reducers/order";
import { StatusCode } from "api/protocols";

export function loadOrder(params: string, useGlobalLoader: boolean) {
  return async (dispatch: any) => {
    const res = await GetOrder(params, useGlobalLoader);
    if (res.status !== StatusCode.OK) {
      //TODO: handle error here
      console.log(res);
      return;
    }

    const order = res.data.data[0];

    console.log("Order: ", order);

    dispatch({
      type: "order.update",
      payload: {
        isLoaded: true,
        order: order,
      },
    });
  };
}

export function orderClear() {
  return (dispatch: any) => {
    dispatch({
      type: "order.clear",
      payload: {
        isLoaded: true,
        order: undefined,
      },
    });
  };
}

export interface IOrderActions {
  loadOrder: typeof loadOrder;
  orderClear: typeof orderClear;
}
