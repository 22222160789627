import { StatusCode } from "api/protocols";
import {
  GetFloorPlans,
  GetTable,
  GetTableElements,
  IFloorPlan,
  IFloorPlanTable,
  ITable,
  ITableElement,
  PostTable,
  PutArchiveTable,
  PutFloorPlan,
  PutTable,
} from "api/rpc/facilityAdmin/tables/tables";
import Card from "components/card/Card";
import { NotificationType } from "components/notificationBar/NotificationBar";
import Page from "components/page/Page";
import { isEmpty, isPositiveInteger } from "helpers/Helpers";
import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import { IUIActions } from "redux/actions/ui";
import FloorPlanTable from "../floorPlanTable/FloorPlanTable";
import FloorPlan, { IFloorPlanBoundingRectangle } from "../floorPlan/FloorPlan";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Input from "components/form/input/Input";
import FormLayout from "components/form/FormLayout";
import { useParams } from "react-router";
import "./floorPlanEdit.scss";

import { useTranslation } from "react-i18next";
import { Select } from "components/select/index";
import { GetRegisterGroups } from "api/rpc/2022-09/facilityAdmin/facility/register/group";
import { GetKitchenLocations } from "api/rpc/facilityAdmin/facility/kitchen/kitchen";

interface IFloorPlanEditProps {
  uiActions: IUIActions;
}

interface IFloorPlanEditState {
  floorPlanId: number;

  floorPlan: IFloorPlan;
  floorPlanBeforeChanges: IFloorPlan;
  floorPlanLoaded: boolean;

  floorPlanTableElements: ITableElement[];
  floorPlanTableElementsLoaded: boolean;

  floorPlanTables: IFloorPlanTable[];
  floorPlanTablesBeforeChanges: IFloorPlanTable[];
  floorPlanTablesLoaded: boolean;

  floorPlanGridWidthInput: string;
  floorPlanGridHeightInput: string;

  floorPlanTablesBoundingRectangles: IFloorPlanBoundingRectangle[];

  register_group_id: number;
  register_groups: any[];

  kitchenLocations: any[];
}

interface IParams {
  floorPlanId: string;
}

export const ItemTypes = {
  TABLE: "table",
};

export const DEFAULT_TABLE_DIMENSION = 40;

export default function FloorPlanEdit(props: IFloorPlanEditProps) {
  const { uiActions } = props;

  const { floorPlanId } = useParams<IParams>();
  const { t, i18n } = useTranslation();
  const MAX_GRID_DIMENSION = 75;
  const MIN_GRID_DIMENSION = 10;
  const { Option } = Select;

  const [floorPlanEditState, setFloorPlanEditState] = useState<IFloorPlanEditState>({
    floorPlanId: isPositiveInteger(floorPlanId) ? parseInt(floorPlanId) : undefined,

    floorPlan: undefined,
    floorPlanBeforeChanges: undefined,
    floorPlanLoaded: false,

    floorPlanTableElements: [],
    floorPlanTableElementsLoaded: false,

    floorPlanTables: [],
    floorPlanTablesBeforeChanges: undefined,
    floorPlanTablesLoaded: false,

    floorPlanGridWidthInput: "",
    floorPlanGridHeightInput: "",

    floorPlanTablesBoundingRectangles: [],

    register_group_id: -1,
    register_groups: [],

    kitchenLocations: [],
  });

  useEffect(() => {
    loadFloorPlanEdit();
    void loadRegisterGroups();
    void loadKitchenLocations();
  }, []);

  function loadFloorPlanEdit() {
    if (!floorPlanEditState.floorPlanId) {
      uiActions.showError(t("secure.facility.settings.floor_plan.floor_plan_edit.floor_plan_edit.001"));
    } else {
      void loadFloorPlanTableElements();
      void loadFloorPlanTables();
      void loadFloorPlan();
    }
  }

  async function loadFloorPlanTableElements() {
    const getFloorPlanTableElementsResponse = await GetTableElements(true);

    if (
      getFloorPlanTableElementsResponse.status !== StatusCode.OK
      // getFloorPlanTableElementsResponse.data === undefined
    ) {
      uiActions.showError(t("secure.facility.settings.floor_plan.floor_plan_edit.floor_plan_edit.002"));
      return;
    }

    setFloorPlanEditState(prev => ({
      ...prev,
      floorPlanTableElements: getFloorPlanTableElementsResponse.data,
      floorPlanTableElementsLoaded: true,
    }));
  }

  async function loadKitchenLocations() {
    const kitchenLocationRes = await GetKitchenLocations(null, false);
    if (kitchenLocationRes.status !== StatusCode.OK) {
      return;
    }

    const kitchenLocations = kitchenLocationRes.data;

    kitchenLocations.unshift({ title: "None", id: -1 });
    setFloorPlanEditState(prev => ({ ...prev, kitchenLocations: kitchenLocations }));
  }

  function toFloorPlanTables(tables: ITable[]): IFloorPlanTable[] {
    if (tables.length === 0 || tables === undefined || tables === null) {
      return [];
    } else {
      const floorPlanTables: IFloorPlanTable[] = tables.map(table => {
        const floorPlanTable: IFloorPlanTable = {
          id: String(table.id),
          x_coordinate: table.x_coordinate,
          y_coordinate: table.y_coordinate,
          seats: table.seats,
          title: table.title,
          short_title: table.short_title,
          table_element_id: table.table_element_id,
          floor_plan_id: table.floor_plan_id,
          rotation: table.rotation,
        };

        return floorPlanTable;
      });

      return floorPlanTables;
    }
  }

  async function loadFloorPlanTables() {
    const getFloorPlanTablesResponse = await GetTable({ floor_plan_id: floorPlanEditState.floorPlanId }, true);

    if (getFloorPlanTablesResponse.status !== StatusCode.OK || getFloorPlanTablesResponse.data === undefined) {
      uiActions.showError(t("secure.facility.settings.floor_plan.floor_plan_edit.floor_plan_edit.003"));
    } else {
      const loadedFloorPlanTables = toFloorPlanTables(getFloorPlanTablesResponse.data);

      setFloorPlanEditState(prev => ({
        ...prev,
        floorPlanTables: loadedFloorPlanTables,
        floorPlanTablesLoaded: true,
      }));
    }
  }

  async function loadFloorPlan() {
    const getFloorPlanResponse = await GetFloorPlans({ id: floorPlanEditState.floorPlanId }, true);

    if (getFloorPlanResponse.status !== StatusCode.OK || getFloorPlanResponse.data?.[0] === undefined) {
      uiActions.showError(t("secure.facility.settings.floor_plan.floor_plan_edit.floor_plan_edit.004"));
    } else {
      const floorPlan = getFloorPlanResponse.data[0];

      setFloorPlanEditState(prev => ({
        ...prev,
        floorPlan: {
          ...floorPlan,
          kitchen_location_id_1: floorPlan.kitchen_location_id_1 ?? -1,
          kitchen_location_id_2: floorPlan.kitchen_location_id_2 ?? -1,
          kitchen_location_id_3: floorPlan.kitchen_location_id_3 ?? -1,
          register_group_id: floorPlan.register_group_id ?? -1,
        },
        floorPlanLoaded: true,
        floorPlanGridWidthInput: String(floorPlan.grid_width),
        floorPlanGridHeightInput: String(floorPlan.grid_height),
        register_group_id: floorPlan.register_group_id ?? -1,
      }));
    }
  }

  function isGridDimensionInBounds(value: string) {
    if (isPositiveInteger(value)) {
      const dimension = parseInt(value);

      return dimension >= MIN_GRID_DIMENSION && dimension <= MAX_GRID_DIMENSION;
    } else {
      return false;
    }
  }

  function isValidGridWidthDimension(value: string) {
    if (isGridDimensionInBounds(value)) {
      return isValidGridDimension(parseInt(value), floorPlanEditState.floorPlan.grid_height);
    } else {
      return false;
    }
  }

  function isValidGridHeightDimension(value: string) {
    if (isGridDimensionInBounds(value)) {
      return isValidGridDimension(floorPlanEditState.floorPlan.grid_width, parseInt(value));
    } else {
      return false;
    }
  }

  function isValidGridDimension(width: number, height: number) {
    return !floorPlanEditState.floorPlanTablesBoundingRectangles.some(boundingRectangle => {
      const bottomRightGridCoordinate = boundingRectangle.bottomRight;

      return bottomRightGridCoordinate.x > width || bottomRightGridCoordinate.y > height;
    });
  }

  useEffect(() => {
    if (isValidGridWidthDimension(floorPlanEditState.floorPlanGridWidthInput)) {
      setFloorPlanEditState(prev => ({
        ...prev,
        floorPlan: {
          ...prev.floorPlan,
          grid_width: parseInt(floorPlanEditState.floorPlanGridWidthInput),
        },
      }));
    }
  }, [floorPlanEditState.floorPlanGridWidthInput]);

  useEffect(() => {
    if (isValidGridHeightDimension(floorPlanEditState.floorPlanGridHeightInput)) {
      setFloorPlanEditState(prev => ({
        ...prev,
        floorPlan: {
          ...prev.floorPlan,
          grid_height: parseInt(floorPlanEditState.floorPlanGridHeightInput),
        },
      }));
    }
  }, [floorPlanEditState.floorPlanGridHeightInput]);

  function unsavedChangesExist() {
    if (
      floorPlanEditState.floorPlanBeforeChanges === undefined ||
      floorPlanEditState.floorPlanTablesBeforeChanges === undefined
    ) {
      if (floorPlanEditState.floorPlanLoaded && floorPlanEditState.floorPlanTablesLoaded) {
        setFloorPlanEditState(prev => ({
          ...prev,
          floorPlanBeforeChanges: prev.floorPlan,
          floorPlanTablesBeforeChanges: prev.floorPlanTables,
        }));
      }

      return false;
    }

    return (
      !isEqual(floorPlanEditState.floorPlanBeforeChanges, floorPlanEditState.floorPlan) ||
      !isEqual(floorPlanEditState.floorPlanTablesBeforeChanges, floorPlanEditState.floorPlanTables)
    );
  }

  function isUUID(value: string) {
    return value.length === 36 && !isPositiveInteger(value);
  }

  async function saveFloorPlanTables() {
    if (isEmpty(floorPlanEditState.floorPlan.title)) {
      uiActions.showError(t("secure.facility.settings.floor_plan.floor_plan_edit.floor_plan_edit.005"));
      return;
    }

    if (floorPlanEditState.floorPlan.register_group_id === -1) {
      uiActions.showError(t("secure.facility.settings.floor_plan.floor_plan_edit.floor_plan_edit.019")); // TODO: Translation
      return;
    }

    const putFloorPlanResponse = await PutFloorPlan(
      {
        id: floorPlanEditState.floorPlan.id,
        title: floorPlanEditState.floorPlan.title,
        grid_width: floorPlanEditState.floorPlan.grid_width,
        grid_height: floorPlanEditState.floorPlan.grid_height,
        register_group_id: floorPlanEditState.floorPlan.register_group_id,
        kitchen_location_id_1: negativeCheckHelper(floorPlanEditState.floorPlan.kitchen_location_id_1),
        kitchen_location_id_2: negativeCheckHelper(floorPlanEditState.floorPlan.kitchen_location_id_2),
        kitchen_location_id_3: negativeCheckHelper(floorPlanEditState.floorPlan.kitchen_location_id_3),
      },
      true,
    );

    if (putFloorPlanResponse.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.settings.floor_plan.floor_plan_edit.floor_plan_edit.006"));
      return;
    }

    const tablesToUpdate = floorPlanEditState.floorPlanTables.filter(table => !isUUID(table.id));
    const tablesToCreate = floorPlanEditState.floorPlanTables.filter(table => isUUID(table.id));
    const tablesToArchive = floorPlanEditState.floorPlanTablesBeforeChanges.filter(
      initialTable => !floorPlanEditState.floorPlanTables.some(table => table.id === initialTable.id),
    );

    let updatedTables: IFloorPlanTable[] = [];
    let createdTables: IFloorPlanTable[] = [];

    if (tablesToUpdate.length > 0) {
      const putFloorPlanTablesResponse = await PutTable({ tables: tablesToUpdate }, true);

      if (putFloorPlanTablesResponse.status !== StatusCode.OK) {
        uiActions.showError(t("secure.facility.settings.floor_plan.floor_plan_edit.floor_plan_edit.007"));
        return;
      }

      updatedTables = toFloorPlanTables(putFloorPlanTablesResponse.data);
    }

    if (tablesToCreate.length > 0) {
      const postFloorPlanTablesResponse = await PostTable(
        {
          tables: tablesToCreate.map(table => {
            const { id, ...remainder } = table;
            const postTable: Omit<IFloorPlanTable, "id"> = remainder;

            return postTable;
          }),
        },
        true,
      );

      if (postFloorPlanTablesResponse.status !== StatusCode.OK) {
        uiActions.showError(t("secure.facility.settings.floor_plan.floor_plan_edit.floor_plan_edit.008"));
        return;
      }

      createdTables = toFloorPlanTables(postFloorPlanTablesResponse.data);
    }

    if (tablesToArchive.length > 0) {
      const archiveFloorPlanTablesResponse = await PutArchiveTable(
        {
          table_ids: tablesToArchive.map(table => parseInt(table.id)),
        },
        true,
      );

      if (archiveFloorPlanTablesResponse.status !== StatusCode.OK) {
        uiActions.showError(t("secure.facility.settings.floor_plan.floor_plan_edit.floor_plan_edit.009"));
        return;
      }
    }

    const updatedFloorPlanTables: IFloorPlanTable[] = [...updatedTables, ...createdTables];

    setFloorPlanEditState(prev => ({
      ...prev,
      floorPlanBeforeChanges: prev.floorPlan,
      floorPlanTables: updatedFloorPlanTables,
      floorPlanTablesBeforeChanges: updatedFloorPlanTables,
      floorPlanGridWidthInput: String(prev.floorPlan.grid_width),
      floorPlanGridHeightInput: String(prev.floorPlan.grid_height),
    }));
  }

  function cancelUnsavedChanges() {
    setFloorPlanEditState(prev => ({
      ...prev,
      floorPlan: prev.floorPlanBeforeChanges,
      floorPlanTables: prev.floorPlanTablesBeforeChanges,
      floorPlanGridWidthInput: String(prev.floorPlanBeforeChanges.grid_width),
      floorPlanGridHeightInput: String(prev.floorPlanBeforeChanges.grid_height),
    }));
  }

  function setFloorPlanTables(updateTables: (prevTables: IFloorPlanTable[]) => IFloorPlanTable[]): void {
    setFloorPlanEditState(prev => ({ ...prev, floorPlanTables: updateTables(prev.floorPlanTables) }));
  }

  function setFloorPlanTablesBoundingRectangles(boundingRectangles: IFloorPlanBoundingRectangle[]) {
    setFloorPlanEditState(prev => ({ ...prev, floorPlanTablesBoundingRectangles: boundingRectangles }));
  }

  function setFloorPlanTitle(e: any) {
    setFloorPlanEditState(prev => ({ ...prev, floorPlan: { ...prev.floorPlan, title: e.target.value } }));
  }

  function handleDropDownChange(value: any, property: keyof IFloorPlan) {
    setFloorPlanEditState(prev => ({ ...prev, floorPlan: { ...prev.floorPlan, [property]: value } }));
  }

  async function loadRegisterGroups() {
    const res = await GetRegisterGroups(undefined, false);
    if (res.status !== StatusCode.OK) {
      return;
    }

    res.data.unshift({ title: "None", id: -1 });

    setFloorPlanEditState(prevState => ({
      ...prevState,
      register_groups: res.data,
    }));
  }

  return (
    <Page
      title={t("secure.facility.settings.floor_plan.floor_plan_edit.floor_plan_edit.010")}
      subtitle={t("secure.facility.settings.floor_plan.floor_plan_edit.floor_plan_edit.011")}
      narrow
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.floor_plan.floor_plan_edit.floor_plan_edit.012"),
          url: "/admin/settings/floor-plan",
        },
      ]}
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: saveFloorPlanTables,
        onCancel: cancelUnsavedChanges,
      }}
    >
      {floorPlanEditState.floorPlanTableElementsLoaded &&
        floorPlanEditState.floorPlanTablesLoaded &&
        floorPlanEditState.floorPlanLoaded && (
          <div>
            <Card>
              <Card.Section title={t("secure.facility.settings.floor_plan.floor_plan_edit.floor_plan_edit.014")}>
                <FormLayout>
                  <FormLayout.Group>
                    <Input
                      label={t("secure.facility.settings.floor_plan.floor_plan_edit.floor_plan_edit.015")}
                      value={floorPlanEditState.floorPlan.title}
                      onChange={setFloorPlanTitle}
                      error={isEmpty(floorPlanEditState.floorPlan.title)}
                    />
                    <Select
                      label={t("secure.facility.settings.floor_plan.floor_plan_edit.floor_plan_edit.022")}
                      onChange={(value: any) => handleDropDownChange(value, "register_group_id")}
                      defaultValue={floorPlanEditState.floorPlan.register_group_id}
                    >
                      {floorPlanEditState.register_groups.map((registerGroup: any, index: number) => {
                        return (
                          <Option
                            className={index === 0 ? "first-register-group_dropdown" : ""}
                            key={index}
                            value={registerGroup.id}
                            name={registerGroup.title}
                          >
                            {registerGroup.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <Input
                      label={t("secure.facility.settings.floor_plan.floor_plan_edit.floor_plan_edit.016")}
                      value={floorPlanEditState.floorPlanGridWidthInput}
                      onChange={(e: any) =>
                        setFloorPlanEditState(prev => ({ ...prev, floorPlanGridWidthInput: e.target.value }))
                      }
                      error={!isValidGridWidthDimension(floorPlanEditState.floorPlanGridWidthInput)}
                    />
                    <Input
                      label={t("secure.facility.settings.floor_plan.floor_plan_edit.floor_plan_edit.017")}
                      value={floorPlanEditState.floorPlanGridHeightInput}
                      onChange={(e: any) =>
                        setFloorPlanEditState(prev => ({ ...prev, floorPlanGridHeightInput: e.target.value }))
                      }
                      error={!isValidGridHeightDimension(floorPlanEditState.floorPlanGridHeightInput)}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
            </Card>

            <Card
              title={t("secure.facility.settings.floor_plan.floor_plan_edit.floor_plan_edit.020")}
              subtitle={t("secure.facility.settings.floor_plan.floor_plan_edit.floor_plan_edit.021")}
            >
              <Card.Section>
                <FormLayout>
                  <FormLayout.Group>
                    <Select
                      onChange={(value: any) => handleDropDownChange(value, "kitchen_location_id_1")}
                      defaultValue={floorPlanEditState.floorPlan.kitchen_location_id_1}
                    >
                      {floorPlanEditState.kitchenLocations
                        // Filter out other selected kitchen_location_ids
                        .filter((location: any) => {
                          if (location.id === -1) {
                            return location;
                          } else {
                            if (
                              location.id !== floorPlanEditState.floorPlan.kitchen_location_id_2 &&
                              location.id !== floorPlanEditState.floorPlan.kitchen_location_id_3
                            ) {
                              return location;
                            }
                          }
                        })
                        .map((location: any, index: number) => {
                          return (
                            <Option key={location.id} value={location.id} name={location.title}>
                              {location.title}
                            </Option>
                          );
                        })}
                    </Select>

                    <Select
                      onChange={(value: any) => handleDropDownChange(value, "kitchen_location_id_2")}
                      defaultValue={floorPlanEditState.floorPlan.kitchen_location_id_2}
                    >
                      {floorPlanEditState.kitchenLocations
                        // Filter out other selected kitchen_location_ids
                        .filter((location: any) => {
                          if (location.id === -1) {
                            return location;
                          } else {
                            if (
                              location.id !== floorPlanEditState.floorPlan.kitchen_location_id_1 &&
                              location.id !== floorPlanEditState.floorPlan.kitchen_location_id_3
                            ) {
                              return location;
                            }
                          }
                        })
                        .map((location: any, index: number) => {
                          return (
                            <Option key={location.id} value={location.id} name={location.title}>
                              {location.title}
                            </Option>
                          );
                        })}
                    </Select>
                    <Select
                      onChange={(value: any) => handleDropDownChange(value, "kitchen_location_id_3")}
                      defaultValue={floorPlanEditState.floorPlan.kitchen_location_id_3}
                    >
                      {floorPlanEditState.kitchenLocations
                        // Filter out other selected kitchen_location_ids
                        .filter((location: any) => {
                          if (location.id === -1) {
                            return location;
                          } else {
                            if (
                              location.id !== floorPlanEditState.floorPlan.kitchen_location_id_1 &&
                              location.id !== floorPlanEditState.floorPlan.kitchen_location_id_2
                            ) {
                              return location;
                            }
                          }
                        })
                        .map((location: any, index: number) => {
                          return (
                            <Option key={location.id} value={location.id} name={location.title}>
                              {location.title}
                            </Option>
                          );
                        })}
                    </Select>
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
            </Card>

            <Card style={{ marginBottom: "320px" }}>
              <Card.Section title={t("secure.facility.settings.floor_plan.floor_plan_edit.floor_plan_edit.018")}>
                <DndProvider backend={HTML5Backend}>
                  <div className="floor-plan-view">
                    <div
                      className="floor-plan-view-table-elements"
                      style={{
                        gridTemplateColumns: `repeat(auto-fill, minmax(${DEFAULT_TABLE_DIMENSION}px, 1fr))`,
                      }}
                    >
                      {floorPlanEditState.floorPlanTableElements.map((element, i) => {
                        return (
                          <div key={`option-${i}`}>
                            <FloorPlanTable optionTable={{ element }} />
                          </div>
                        );
                      })}
                    </div>
                    <FloorPlan
                      tables={floorPlanEditState.floorPlanTables}
                      setTables={setFloorPlanTables}
                      tableElements={floorPlanEditState.floorPlanTableElements}
                      floorPlanId={floorPlanEditState.floorPlanId}
                      gridWidth={floorPlanEditState.floorPlan.grid_width}
                      gridHeight={floorPlanEditState.floorPlan.grid_height}
                      tablesBoundingRectangles={floorPlanEditState.floorPlanTablesBoundingRectangles}
                      setTablesBoundingRectangles={setFloorPlanTablesBoundingRectangles}
                    />
                  </div>
                </DndProvider>
              </Card.Section>
            </Card>
          </div>
        )}
    </Page>
  );
}

/**
 *  Returns null if negative number.
 *  NULL kitchen_location_id defaults to -1 ("None")
 *  - Convert "None" kitchen_location_id to NULL before PUT call.
 */
export const negativeCheckHelper = (value: number) => {
  return value < 0 ? null : value;
};
