import { IRoutes } from "components/sideMenu/SideMenu";
import ClientStaffAccountEdit from "containers/client/settings/client/clientStaffEdit";
import ClientStaffAccountNew from "./clientSettings/staffAccounts/ClientStaffAccountNew";
import ClientStaffAccounts from "./clientSettings/staffAccounts/ClientStaffAccounts";
import Settings from "./Settings";
import PricingSheets from "./teeSheets/PricingSheets";
import PricingSheet from "./teeSheets/PricingSheet";
import CheckInConfig from "containers/client/settings/teeSheet/checkInConfig";
import Payouts from "./payouts/Payouts";
import PayoutsInfo from "containers/client/settings/payouts/payoutsInfo";
import CashOuts from "./cashouts/CashOuts";
import FacilityPermissions from "containers/client/settings/facilityPermissions/facilityPermissions";
import ClientCashOutEdit from "containers/client/settings/cashouts/cashoutsEdit";
import ClientCashOutNew from "containers/client/settings/cashouts/cashoutsNew";
import ProductTypesClient from "./productTypes/ProductTypesClient";
import ProductTypesClientNew from "./productTypes/ProductTypesClientNew";
import ProductTypesClientEdit from "./productTypes/ProductTypesClientEdit";
import Vendors from "./vendors/Vendors";
import VendorsEdit from "./vendors/VendorsEdit";
import VendorsNew from "./vendors/VendorsNew";
import Accounts from "./clientSettings/accounts/Accounts";
import AccountOverview from "./clientSettings/accounts/AccountOverview";
import AccountEdit from "./clientSettings/accounts/AccountEdit";
import AccountNew from "./clientSettings/accounts/AccountNew";
import CustomerTypes from "./customerTypes/CustomerTypes";
import CustomerTypesNew from "./customerTypes/CustomerTypeNew";
import CustomerTypeEdit from "./customerTypes/CustomerTypeEdit";
import Details from "./details/Details";
import AccountingReferences from "./accountingReferences/AccountingReferences";
import AccountingReferenceNew from "./accountingReferences/AccountingReferenceNew";
import AccountingReferenceEdit from "./accountingReferences/AccountingReferenceEdit";
import Facilities from "./clientSettings/facilities/details/Facilities";
import FacilityNew from "./clientSettings/facilities/details/FacilityNew";
import FacilityEdit from "./clientSettings/facilities/details/FacilityEdit";

const routes: IRoutes[] = [
  // Tee Sheets

  {
    path: "/admin/settings/",
    component: Settings,
  },

  // Details
  {
    path: "/admin/settings/client-settings/details",
    component: Details,
  },

  // Staff
  {
    path: "/admin/settings/client-settings/staff",
    component: ClientStaffAccounts,
  },

  {
    path: "/admin/settings/client-settings/staff/new",
    component: ClientStaffAccountNew,
  },
  {
    path: "/admin/settings/client-settings/staff/edit/:id",
    component: ClientStaffAccountEdit,
  },
  {
    path: "/admin/settings/client-settings/facility-details",
    component: Facilities,
  },
  {
    path: "/admin/settings/client-settings/facility-details/new",
    component: FacilityNew,
  },
  {
    path: "/admin/settings/client-settings/facility-details/edit/:facilityId",
    component: FacilityEdit,
  },
  {
    path: "/admin/settings/client-settings/facility-permissions",
    component: FacilityPermissions,
  },
  {
    path: "/admin/settings/client-settings/products/types",
    component: ProductTypesClient,
  },
  {
    path: "/admin/settings/client-settings/products/types/new",
    component: ProductTypesClientNew,
  },
  {
    path: "/admin/settings/client-settings/products/types/:id",
    component: ProductTypesClientEdit,
  },
  {
    path: "/admin/settings/client-settings/vendors",
    component: Vendors,
  },
  {
    path: "/admin/settings/client-settings/vendors/new",
    component: VendorsNew,
  },
  {
    path: "/admin/settings/client-settings/vendors/:id",
    component: VendorsEdit,
  },

  //Customer Types
  {
    path: "/admin/settings/client-settings/customer_types",
    component: CustomerTypes,
  },
  {
    path: "/admin/settings/client-settings/customer_types/new",
    component: CustomerTypesNew,
  },
  {
    path: "/admin/settings/client-settings/customer_types/:id",
    component: CustomerTypeEdit,
  },

  // Accounts
  {
    path: "/admin/settings/accounts",
    component: Accounts,
  },
  {
    path: "/admin/settings/accounts/new",
    component: AccountNew,
  },
  {
    path: "/admin/settings/accounts/edit/:accountId",
    component: AccountEdit,
  },
  {
    path: "/admin/settings/accounts/overview/:accountId",
    component: AccountOverview,
  },

  //Accounting References
  {
    path: "/admin/settings/accounting_references",
    component: AccountingReferences,
  },
  {
    path: "/admin/settings/accounting_references/new",
    component: AccountingReferenceNew,
  },
  {
    path: "/admin/settings/accounting_references/:id",
    component: AccountingReferenceEdit,
  },

  //Facility Settings

  {
    path: "/admin/settings/facility-settings/tee-sheet/pricing-sheet",
    component: PricingSheets,
  },
  {
    path: "/admin/settings/facility-settings/tee-sheet/pricing-sheet/:pricingSheetId",
    component: PricingSheet,
  },
  {
    path: "/admin/settings/facility-settings/tee-sheet-/check-in-config",
    component: CheckInConfig,
  },
  {
    path: "/admin/settings/facility-settings/payouts",
    component: Payouts,
  },
  {
    path: "/admin/settings/facility-settings/payouts/:id",
    component: PayoutsInfo,
  },
  {
    path: "/admin/settings/facility-settings/cash-out",
    component: CashOuts,
  },
  {
    path: "/admin/settings/facility-settings/cash-out/new",
    component: ClientCashOutNew,
  },
  {
    path: "/admin/settings/facility-settings/cash-out/edit/:cashOutId",
    component: ClientCashOutEdit,
  },
];

export default routes;
