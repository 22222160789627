import { APIDelete, APIGet, APIPost, APIPut } from "api/protocols";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/discount";

export interface IDiscount {
  id: number;
  client_id: number;
  title: string;
  application: string;
  value_type: string;
  value: number;
  custom: boolean;
  start_date: string;
  end_date: string;
  notes: string;
  tracked?: boolean;
}

export interface IGetDiscount {
  id?: number;
  application?: string;
}
interface IPostDiscount {
  title: string;
  value_type: string;
  application: string;
  value: number;
  custom: boolean;
  tracked: boolean;
}
interface IPutDiscount {
  id: number;
  title: string;
  value_type: string;
  application: string;
  value: number;
  custom: boolean;
  tracked: boolean;
}
interface IDeleteDiscount {
  id: number;
}

interface IPostDiscountLine {
  discount_id: number;
  cart_id: number;
  cart_line_item_id?: number;
  value_type?: "percent" | "fixed_amount";
  value?: number;
}
interface IDeleteDiscountLine {
  id: number;
  cart_id: number;
}

//Get Discount
//Retrieve discounts
export async function GetDiscount(params?: IGetDiscount, useGlobalLoader?: boolean) {
  return await APIGet(baseUrl, params, useGlobalLoader);
}

//POST Discount
//Create a new discount
export async function PostDiscount(params?: IPostDiscount, useGlobalLoader?: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

//PUT Discount
//Update an existing discount
export async function PutDiscount(params?: IPutDiscount, useGlobalLoader?: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

//DELETE Discount
//Delete a discount
export async function DeleteDiscount(params?: IDeleteDiscount, useGlobalLoader?: boolean) {
  return await APIDelete(baseUrl, params, useGlobalLoader);
}

// POST Order
// Create a new order
export async function PostDiscountLine(params: IPostDiscountLine, useGlobalLoader: boolean) {
  return await APIPost("/" + apiVersion + "/" + adminType + "/cart/discount", params, useGlobalLoader);
}

export async function DeleteDiscountLine(param: IDeleteDiscountLine, useGlobalLoader: boolean) {
  return await APIDelete("/" + apiVersion + "/" + adminType + "/cart/discount", param, useGlobalLoader);
}
