import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { IAPIResponse, StatusCode } from "api/protocols";
import { DeleteLineItemToCart, PostCart } from "api/rpc/2022-09/facilityAdmin/cart/cart";
import { PutLineItemToCart } from "api/rpc/2024-04/facilityAdmin/order/cart/lineItem";
import { IDiscount } from "api/rpc/2022-09/facilityAdmin/product/discount";
import { IUserPermissions } from "redux/reducers/models/user";
import { deleteLineItem, loadCart, loadTableCart, putCartLineItem } from "redux/actions/cart";
import { showError, showSuccess } from "redux/actions/ui";
import { ICart, ICartLineItem, ICartTaxLine } from "redux/reducers/models/cart";
import { IProduct } from "redux/reducers/models/product";

import { isEmpty, isNumeric, isWholeNumber } from "helpers/Helpers";

import Sheet from "components/sheet/Sheet";
import Input from "components/form/input/Input";
import { ButtonNew as Button } from "components/buttonNew/index";
import DiscountLineItemModal from "components/discountLineItemModal/DiscountLineItemModal";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import ButtonGroup from "components/button/ButtonGroup";
import ReactDOM from "react-dom";
import { useRegisterContext } from "./RegisterContext";
import { PostDiscountLine } from "api/rpc/2024-04/facilityAdmin/order/cart/discountLine";
import "elements/register/register.scss";
import { useAppSelector } from "hooks/redux";
import { isEqual } from "lodash";

export interface ILineItem {
  cart_id: number;
  cost: number;
  custom: boolean;
  fulfillment_required: any | null;
  gift_card: boolean;
  id: number;
  kitchen_chit_id: number | null;
  non_refundable: boolean;
  note: string | null;
  parent_id: number | null;
  pre_paid_required: number;
  preferred_title: string | null;
  price: number;
  product: IProduct;
  product_default_image?: string | null;
  product_id: number;
  product_title: string;
  quantity: number;
  requires_shipping: any | null;
  subtitle: string | null;
  subtotal_price: number;
  tax_included: boolean;
  tax_lines: ICartTaxLine[];
  taxable: boolean;
  total_discount: number;
  total_price: number;
  total_tax: number;
  variant_id: number;
  variant_title: string;
}

export interface IUpdateLineItemModalState {
  lineItem: ICartLineItem;
  inputQuantity: string;
  inputPrice: string;
  inputNote: string;
  isModalVisible: boolean;
}

interface ILineItemModalBase {
  lineItemState: IUpdateLineItemModalState;
  setLineItemState: React.Dispatch<React.SetStateAction<IUpdateLineItemModalState>>;
  openModifiersSheet?: (lineItem: ICartLineItem) => void;
  permissions: IUserPermissions;
  discounts: IDiscount[];
  cart: ICart | Partial<ICart>;
}

interface ILineItemStateData extends ILineItemModalBase {
  dataOrigin: "state";
  modalActions: ILineItemModalActions;
}

interface ILineItemReduxData extends ILineItemModalBase {
  dataOrigin: "redux";
  modalActions: Partial<ILineItemModalActions>;
}

interface ILineItemModalActions {
  onOk: (updatedCart: ICart) => void;
}

type LineItemModalType = ILineItemStateData | ILineItemReduxData;

export default function LineItemModal(props: LineItemModalType) {
  const {
    updateRegisterState,
    addLineItemUpdating,
    removeLineItemUpdating,
    participantPaidFees,
    updateParticipantPaidFees,
  } = useRegisterContext();
  const { teeSheetStore } = useAppSelector(store => store);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    lineItemState,
    setLineItemState,
    permissions,
    discounts,
    dataOrigin,
    modalActions,
    openModifiersSheet,
    cart,
  } = props;

  const [discountModal, setDiscountModal] = useState({
    isOpen: false,
    selectedDiscountId: -1,
    selectedDiscount: null,
  });

  const handleDiscountModal = () => {
    setDiscountModal({
      ...discountModal,
      isOpen: !discountModal.isOpen,
      selectedDiscountId: -1,
      selectedDiscount: null,
    });
  };

  const resetInputState = () => {
    ReactDOM.unstable_batchedUpdates(() => {
      updateRegisterState({ modifierLineItem: null });
      setLineItemState({
        ...lineItemState,
        lineItem: null,
        isModalVisible: false,
        inputQuantity: "",
        inputPrice: "",
        inputNote: "",
      });
    });
  };

  // Update the line item
  async function handleUpdateLineItem(discountApplied = false) {
    const quantity = parseInt(lineItemState.inputQuantity);
    const price = parseFloat(lineItemState.inputPrice);

    //determine what to do with lineItem based off quantity wanted
    if (isNumeric(lineItemState.inputPrice)) {
      const lineItemId = lineItemState?.lineItem?.id;
      const lineItemParentId = lineItemState?.lineItem?.parent_id;
      //Get the key values of the line item that can be edited
      const lineItemPickedKeys = (({ quantity, price, note }) => ({
        quantity,
        price,
        note,
      }))(lineItemState?.lineItem);

      const params = {
        price: price,
        quantity: quantity,
        note: isEmpty(lineItemState.inputNote) ? null : lineItemState.inputNote,
      };

      //Update line item if a change was made
      if (!isEqual(lineItemPickedKeys, params)) {
        void addLineItemUpdating(lineItemParentId ?? lineItemId);
        //Reset the modal
        void resetInputState();
        const res = await PutLineItemToCart({ id: lineItemId, ...params }, false);
        void removeLineItemUpdating(lineItemParentId ?? lineItemId);
        if (res && res.status !== StatusCode.OK) {
          dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.019")));
          return;
        } else if (res && res.status === StatusCode.OK) {
          //Don't set cart in state if discount is being applied
          if (discountApplied === false) {
            if (dataOrigin === "redux") {
              void dispatch(loadTableCart({ tableCart: res?.data }, false));
            } else {
              modalActions?.onOk(res?.data);
            }
          }
        }
      }
    } else {
      dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.018")));
    }
  }

  // Remove the selected lineItem
  async function handleRemoveLineItem() {
    const lineItem = { ...lineItemState?.lineItem };
    const updateTeeSheetParticipant =
      dataOrigin === "state" && (lineItem?.product?.type === "Green Fee" || lineItem?.product?.type === "Power Cart");
    const feeType = lineItem?.product?.type === "Green Fee" ? "green_fee" : "power_cart_fee";
    let updatedParticipant: {
      booking_participant_id: number;
      green_fee: boolean;
      power_cart_fee: boolean;
      green_fee_loading: boolean;
      power_cart_fee_loading: boolean;
    } = undefined;

    void addLineItemUpdating(lineItem?.parent_id ?? lineItem?.id, "delete");
    //Reset the modal
    void resetInputState();
    if (updateTeeSheetParticipant) {
      updatedParticipant = setLoadingParticipant(lineItem);
    }
    const deleteRes = await DeleteLineItemToCart({ id: lineItem?.id }, false);
    void removeLineItemUpdating(lineItem?.parent_id ?? lineItem?.id);
    if (deleteRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.017")));
      if (updateTeeSheetParticipant && updatedParticipant) {
        const participant = [...participantPaidFees?.current]?.find(
          participant => participant?.booking_participant_id === updatedParticipant?.booking_participant_id,
        );
        participant && updateParticipantPaidFees({ ...participant, [`${feeType}_loading`]: false });
      }
      return;
    }

    dispatch(showSuccess("Successfully removed line item"));
    //Redux cart (Register)
    if (dataOrigin === "redux") {
      void dispatch(loadTableCart({ tableCart: deleteRes?.data }, false));
    } else {
      if (updatedParticipant && updateTeeSheetParticipant) {
        //Variant removed from cart, set participant fee value to false
        const participant = [...participantPaidFees?.current]?.find(
          participant => participant?.booking_participant_id === updatedParticipant?.booking_participant_id,
        );
        participant &&
          updateParticipantPaidFees({ ...participant, [`${feeType}`]: false, [`${feeType}_loading`]: false });
      }
      //State cart (Tee Sheet Details)
      modalActions?.onOk(deleteRes?.data);
    }
  }

  //Display loader for booking participants with matching green fee / power cart fee
  function setLoadingParticipant(lineItem: ICartLineItem) {
    const productType = lineItem?.product?.type === "Green Fee" ? "green_fee_variant_id" : "power_cart_variant_id";
    const feeType = lineItem?.product?.type === "Green Fee" ? "green_fee" : "power_cart_fee";
    let updatedParticipant: {
      booking_participant_id: number;
      green_fee: boolean;
      power_cart_fee: boolean;
      green_fee_loading: boolean;
      power_cart_fee_loading: boolean;
    } = undefined;
    [...teeSheetStore?.selectedTeeTime?.slots]?.reverse()?.every(slot => {
      if (slot?.booking_participant && slot?.booking_participant?.[productType] === lineItem?.variant_id) {
        return [...participantPaidFees?.current]?.reverse()?.every(participant => {
          if (
            participant?.booking_participant_id === slot?.booking_participant?.id &&
            participant?.[`${feeType}`] === true &&
            participant?.[`${feeType}_loading`] === false
          ) {
            updatedParticipant = { ...participant, [`${feeType}_loading`]: true };
            updateParticipantPaidFees(updatedParticipant);
            return false;
          }
          return true;
        });
      }
      return true;
    });
    return updatedParticipant;
  }

  function getModifierLineItemIds() {
    const cartLineItems = [...cart?.line_items];
    const parentLineItemId = lineItemState?.lineItem?.id;
    return cartLineItems
      ?.filter(lineItem => lineItem?.parent_id === parentLineItemId)
      ?.map(lineItem => lineItem?.id);
  }

  // Apply discount to line item
  async function handleLineItemDiscountApply(customAmount?: string, typeFixed?: boolean) {
    const updatingLineItemId = lineItemState?.lineItem?.id;
    const lineItemParentId = lineItemState?.lineItem?.parent_id;
    const type = typeFixed !== undefined ? (typeFixed ? "fixed_amount" : "percent") : undefined;
    const modifierLineItemIds = getModifierLineItemIds();
    //Update line item quantity, price and/or note first
    await handleUpdateLineItem(true);

    void addLineItemUpdating(lineItemParentId ?? updatingLineItemId);

    //Reset modals
    void handleDiscountModal();
    void resetInputState();

    const res = await PostDiscountLine(
      {
        discount_id: discountModal.selectedDiscountId,
        cart_id: lineItemState.lineItem.cart_id,
        cart_line_item_id: updatingLineItemId,
        value: customAmount ? Number(customAmount) : undefined,
        value_type: type,
        modifier_line_item_ids:
          modifierLineItemIds?.length > 0 &&
          (type == "percent" || (!type && discountModal?.selectedDiscount?.value_type === "percent"))
            ? modifierLineItemIds
            : undefined,
      },
      false,
    );
    void removeLineItemUpdating(lineItemParentId ?? updatingLineItemId);

    if (res.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_details.020")));
      return;
    }

    // allow parent to handle the change
    if (dataOrigin === "state") {
      void modalActions?.onOk(res?.data);
    } else if (dataOrigin === "redux") {
      if (res?.data) {
        dispatch(loadTableCart({ tableCart: res?.data }, false));
      } else {
        dispatch(loadCart({ id: lineItemState.lineItem.cart_id }, true));
      }
    }

    dispatch(showSuccess(t("secure.facility.tee_sheet.tee_sheet_booking_details.171")));
  }

  function openModifiersRegisterNew(lineItem: ICartLineItem) {
    setLineItemState(prevState => ({ ...prevState, isModalVisible: false }));
    openModifiersSheet(lineItem);
  }

  return (
    <>
      <Sheet
        closable
        size="small"
        open={lineItemState.isModalVisible}
        title={t("secure.facility.tee_sheet.tee_sheet_booking_details.121")}
        onCancel={() => resetInputState()}
        cancelText={t("secure.facility.tee_sheet.tee_sheet_booking_details.124")}
        onOk={() => handleUpdateLineItem(false)}
        okText={
          parseInt(lineItemState.inputQuantity) === 0
            ? t("secure.facility.tee_sheet.tee_sheet_booking_details.122")
            : t("secure.facility.tee_sheet.tee_sheet_booking_details.123")
        }
        okDisabled={!isNumeric(lineItemState.inputQuantity) || !isNumeric(lineItemState.inputPrice)}
        backDropCancel={false}
      >
        <div className="update-line-item-modal-container">
          <Button
            onClick={() => handleDiscountModal()}
            type="text"
            className="update-line-item-modal-primary"
            disabled={!permissions?.orders_apply_discount}
          >
            {t("secure.facility.tee_sheet.tee_sheet_booking_details.128")}
          </Button>

          {lineItemState.lineItem.product?.has_modifiers && lineItemState.lineItem?.parent_id === null ? (
            <Button
              onClick={() => openModifiersRegisterNew(lineItemState?.lineItem)}
              className="update-line-item-modal-primary"
              type="text"
            >
              {t("secure.facility.tee_sheet.tee_sheet_booking_details.130")}
            </Button>
          ) : null}

          <Button
            onClick={handleRemoveLineItem}
            type="text"
            className="update-line-item-modal-error"
            disabled={
              !(
                permissions?.orders_remove_line_item ||
                (permissions?.orders_remove_line_item_after_send && !lineItemState.lineItem?.kitchen_chit_id)
              )
            }
          >
            {t("secure.facility.tee_sheet.tee_sheet_booking_details.131")}
          </Button>
        </div>

        <div className="flex flex-col mb-4">
          <span className="text-lg">
            {lineItemState.lineItem?.preferred_title
              ? lineItemState?.lineItem?.preferred_title
              : lineItemState?.lineItem?.product_title}
          </span>
          {lineItemState.lineItem?.preferred_title !== lineItemState.lineItem?.variant_title &&
            lineItemState?.lineItem?.product_title !== lineItemState.lineItem?.variant_title && (
              <span style={{ color: "#475467" }} className="text-sm">
                {lineItemState.lineItem?.variant_title}
              </span>
            )}
        </div>

        <Form>
          <FormLayout>
            <FormLayout.Group>
              <Input
                label={t("secure.facility.tee_sheet.tee_sheet_booking_details.125")}
                id="inputQuantity"
                value={lineItemState.inputQuantity || ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setLineItemState({ ...lineItemState, [e.target.id]: e.target.value })
                }
                error={!isNumeric(lineItemState.inputQuantity)}
              />
              <Input
                prefix="$"
                label={t("secure.facility.tee_sheet.tee_sheet_booking_details.126")}
                placeholder={t("secure.facility.tee_sheet.tee_sheet_booking_details.127")}
                id="inputPrice"
                value={lineItemState.inputPrice || ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setLineItemState({ ...lineItemState, [e.target.id]: e.target.value })
                }
                disabled={!permissions?.orders_edit_line_item_price || lineItemState?.lineItem?.product?.price_locked}
                error={!isNumeric(lineItemState.inputPrice)}
              />
            </FormLayout.Group>

            <FormLayout.Group>
              <Input
                placeholder="Note"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setLineItemState({ ...lineItemState, [e.target.id]: e.target.value })
                }
                id="inputNote"
                value={lineItemState.inputNote}
              />
            </FormLayout.Group>
          </FormLayout>
        </Form>
      </Sheet>

      {discounts && (
        <DiscountLineItemModal
          title={t("secure.facility.tee_sheet.tee_sheet_booking_details.129")} //"Line Item Discounts"
          open={discountModal.isOpen}
          onCancel={handleDiscountModal}
          onOk={handleLineItemDiscountApply}
          onChange={(id: number, discount: IDiscount) =>
            setDiscountModal({ ...discountModal, selectedDiscountId: id, selectedDiscount: discount })
          }
          okDisabled={!discountModal.selectedDiscountId || discountModal.selectedDiscountId < 0}
          discounts={discounts}
          selectedDiscount={discountModal.selectedDiscount}
        />
      )}
    </>
  );
}
