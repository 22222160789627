import React, { useState, useRef } from "react";

import Sheet from "components/sheet/Sheet";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import Avatar from "components/avatar/Avatar";
import FeatureIcon from "components/icon/FeatureIcon";
import { ButtonNew as Button } from "components/buttonNew";
import { ICustomer } from "redux/reducers/models/customer";

import "./existingCustomers.scss";

interface IExistingCustomersProps {
  open: boolean;
  onClose: () => void;
  onLoadCustomer: (customerId: number) => void;
  customers: ICustomer[];
}

export default function ExistingCustomers(props: IExistingCustomersProps) {
  return (
    <Sheet
      open={props.open}
      title={"Existing Customers"}
      onCancel={props.onClose}
      onOk={props.onClose}
      okText="Close"
      size="small"
    >
      <div className="customer-profile-existing-customer-header">
        <FeatureIcon type="warning" theme="light-circle-outline" size="medium" icon="exclamation" />
        <span className="text-lg text-medium">
          {props.customers?.length > 1
            ? `${props.customers?.length} customers already exist with similar information`
            : "A customer already exists with similar information"}
        </span>
      </div>
      <DataTable columns={[{ label: "Customer" }, { label: "Actions" }]}>
        {props.customers?.map(customer => (
          <tr key={customer.id}>
            <td>
              <div className="customer-profile-existing-customer">
                <Avatar size="medium" source={customer.profile_image_source} />
                <div className="customer-info">
                  <span>{`${customer.first_name} ${customer.last_name}`}</span>
                  <span>{customer.email}</span>
                  <span>{customer.phone}</span>
                </div>
              </div>
            </td>
            <td>
              <div className="flex justify-between">
                <Button size="medium" type="tertiary" onClick={() => props.onLoadCustomer(customer.id)}>
                  {"Load" /* TODO: Translation */}
                </Button>
                <Button size="medium" type="tertiary" onClick={() => {}} disabled={true}>
                  {"Merge" /* TODO: Translation */}
                </Button>
              </div>
            </td>
          </tr>
        ))}
      </DataTable>
    </Sheet>
  );
}
