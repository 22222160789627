import Orders from "./Orders";
import SingleOrder from "../../../../containers/facility/orderEdit";
import GiftCards from "./GiftCards";
import RainChecks from "./RainChecks";
import OrderForms from "./OrderForms";
import Refund from "../../../../containers/facility/orderRefund";
import GiftCard from "./GiftCard";
import RainCheck from "containers/facility/rainCheck";
import OrderForm from "./OrderForm";
import Invoices from "./invoices/Invoices";
import InvoiceNew from "./invoices/InvoiceNew";
import InvoiceEdit from "./invoices/InvoiceEdit";
import Invoice from "./invoices/Invoice";
import Deposits from "./invoices/Deposits";

const orderRoutes: { path: string; component: any }[] = [
  // Orders
  {
    path: "/admin/orderMenu",
    component: Orders,
  },
  {
    path: "/admin/order",
    component: Orders,
  },
  {
    path: "/admin/order/:orderId",
    component: SingleOrder,
  },
  {
    path: "/admin/order/:orderId/refund",
    component: Refund,
  },

  //Gift Cards

  {
    path: "/admin/giftcards",
    component: GiftCards,
  },

  {
    path: "/admin/giftcards/:giftCardCode",
    component: GiftCard,
  },

  //Rain Checks
  {
    path: "/admin/rainchecks",
    component: RainChecks,
  },
  {
    path: "/admin/rainchecks/:rainCheckId",
    component: RainCheck,
  },
  {
    path: "/admin/forms",
    component: OrderForms,
  },
  {
    path: "/admin/forms/:orderFormId",
    component: OrderForm,
  },

  // Invoices
  {
    path: "/admin/invoices",
    component: Invoices,
  },
  {
    path: "/admin/invoices/new",
    component: InvoiceNew,
  },
  {
    path: "/admin/invoices/deposits",
    component: Deposits,
  },
  {
    path: "/admin/invoices/:id/edit",
    component: InvoiceEdit,
  },
  {
    path: "/admin/invoices/:id",
    component: Invoice,
  },
];

export default orderRoutes;
