import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import { putCart } from "redux/actions/cart";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { LocaleCurrency } from "helpers/Locale";
import useLocalStorage from "../../hooks/useLocalStorage";

import Sheet from "components/sheet/Sheet";
import Input from "components/form/input/Input";
import Radio from "components/radio";
import { IRegisterGroup } from "pages/secure/facility/Admin";

import "elements/register/register.scss";
import classNames from "classnames";
import { IOrder } from "redux/reducers/models/order";

interface ITipState {
  tipActive: boolean;
  tipAmount: string;
  tipPercent: number;
}

interface IProps {
  totalTipAmount: number;
  handleTipTeeSheet?: (tipAmount: number) => void;
  totalCartPrice?: number;
  darkStyle?: boolean;
  order?: IOrder;
}

// Using new component/folder that I added to the components folder
export default function Tip(props: IProps) {
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();
  const cartStore = useAppSelector(store => store.cartStore);

  const registerGroup = useLocalStorage<IRegisterGroup>("register_group");

  const [tipState, setTipState] = useState<ITipState>({
    tipActive: false,
    tipAmount: "0",
    tipPercent: null,
  });

  useEffect(() => {
    setTipState(prevState => ({ ...prevState, tipAmount: props.totalTipAmount?.toString() }));
  }, [props.totalTipAmount]);

  // Function: Restarting the amount of tip every time the user uses the cancel button
  function cancelClickTip() {
    setTipState(prevState => ({
      ...prevState,
      tipActive: false,
      tipAmount: tipState.tipAmount === "0" ? "0" : props.totalTipAmount.toString(),
      tipPercent: null,
    }));
  }

  // Function: Making the API call to put the Tip in the cart
  async function handleAddTip() {
    if (props.handleTipTeeSheet) {
      void props.handleTipTeeSheet(Number(tipState.tipAmount));
    } else {
      await dispatch(putCart({ id: cartStore.cart.id, total_tip: Number(tipState.tipAmount) }, true));
    }

    void cancelClickTip();
  }

  function onClickTip(tipPercent: number) {
    const calculateTip = (props.totalCartPrice - props.totalTipAmount) * tipPercent;
    let valueOfTip = tipPercent;

    if (registerGroup?.tipping_type === "percent" || registerGroup?.tipping_type === "none") {
      valueOfTip = Math.floor(calculateTip * 100) / 100;
    }

    setTipState(prevState => ({ ...prevState, tipAmount: valueOfTip.toString(), tipPercent }));
  }

  return (
    <div>
      <div className="flex">
        {props.totalTipAmount > 0 || props?.order || cartStore?.cart?.status === "void" ? (
          <div
            className={classNames("ml-auto", { "cursor-pointer": !props.order })}
            onClick={
              cartStore?.cart?.status !== "complete" && !props?.order && cartStore?.cart?.status !== "void"
                ? () => setTipState(prevState => ({ ...prevState, tipActive: true }))
                : undefined
            }
          >
            <LocaleCurrency currency="cad" amount={props.totalTipAmount} />
          </div>
        ) : (
          cartStore?.cart?.status !== "complete" && (
            <div
              className="cursor-pointer"
              onClick={() => setTipState(prevState => ({ ...prevState, tipActive: true }))}
            >
              <span style={{ fontWeight: "600", color: "#203254" }}>
                {" "}
                <FontAwesomeIcon icon="plus" /> {t("components.tip.tip.001")}
              </span>
            </div>
          )
        )}
      </div>

      {tipState.tipActive ? (
        <Sheet
          title={t("components.tip.tip.001")}
          open={tipState.tipActive}
          size="small"
          closable
          onCancel={cancelClickTip}
          onOk={handleAddTip} //Using the function in order to add the tip to the cart
          okText={t("components.tip.tip.001")}
          darkStyle={props?.darkStyle ? true : false}
        >
          <Input
            label={t("components.tip.tip.001")}
            type="number"
            value={tipState.tipAmount}
            autoFocus={true}
            onFocus={(e: React.ChangeEvent<HTMLInputElement>) => e.target.select()}
            onChange={e =>
              setTipState(prevState => ({ ...prevState, tipAmount: e.target.value, tipPercent: null }))
            }
            labelStyle={{ color: props?.darkStyle && "#FFFFFF" }}
          />

          <div className="mt-4 p-4">
            <Radio.Group
              darkStyle={props?.darkStyle ? true : false}
              name="tips"
              onChange={onClickTip}
              value={tipState.tipPercent}
            >
              {registerGroup?.tipping_type === "percent" ? (
                <>
                  <Radio.Button value={registerGroup?.tip_percentage_low / 100}>
                    {`${registerGroup?.tip_percentage_low}%`}{" "}
                  </Radio.Button>
                  <Radio.Button value={registerGroup?.tip_percentage_mid / 100}>
                    {`${registerGroup?.tip_percentage_mid}%`}{" "}
                  </Radio.Button>
                  <Radio.Button value={registerGroup?.tip_percentage_high / 100}>
                    {`${registerGroup?.tip_percentage_high}%`}{" "}
                  </Radio.Button>
                </>
              ) : registerGroup?.tipping_type === "fixed_amount" ? (
                <>
                  <Radio.Button value={registerGroup?.tip_fixed_amount_low}>
                    {`$${registerGroup?.tip_fixed_amount_low}`}{" "}
                  </Radio.Button>
                  <Radio.Button value={registerGroup?.tip_fixed_amount_mid}>
                    {`$${registerGroup?.tip_fixed_amount_mid}`}{" "}
                  </Radio.Button>
                  <Radio.Button value={registerGroup?.tip_fixed_amount_high}>
                    {`$${registerGroup?.tip_fixed_amount_high}`}{" "}
                  </Radio.Button>
                </>
              ) : (
                <>
                  <Radio.Button value={0.1}>{"10%"}</Radio.Button>
                  <Radio.Button value={0.15}>{"15%"}</Radio.Button>
                  <Radio.Button value={0.2}>{"20%"}</Radio.Button>
                </>
              )}
            </Radio.Group>
          </div>
        </Sheet>
      ) : null}
    </div>
  );
}
