import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import {
  TCompetitionFormat,
  TCompetitionFormatHoles,
  TCompetitionHandicap,
  TCompetitionOrganization,
  TCompetitionScoreCombo,
  TCompetitionTeamScores,
  TPlayerCompetition,
} from "redux/reducers/models/league";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/league/scoring/format";

export type TGetLeagueCompetitionFormat = {
  id?: string | number;
  league_id?: string | number;
  league_division_id?: string | number;
  league_scoresheet_id?: string | number;
  leaderboards?: boolean;
  scoresheet?: boolean;
};

export type TPostLeagueCompetitionFormat = {
  league_scoresheet_id: string | number;
  league_division_id: string | number;
  title: string;
  organization: TCompetitionOrganization;
  format: TCompetitionFormat;
  competition: TPlayerCompetition;
  default_participation_points: number;
  holes: TCompetitionFormatHoles;
  handicap: TCompetitionHandicap;
  handicap_allowance?: number; // default = 1
  team_scores?: TCompetitionTeamScores;
  team_scores_best_gross?: number;
  team_scores_best_net?: number;
  team_scores_combination?: TCompetitionScoreCombo;
  custom_points: any;
  leaderboard_data: Array<{
    id: number;
    point_allocation: "custom" | "posted_points" | "posted";
    custom_points?: Array<{ position: number; points: number }> | any;
  }>;
};

export type TPutLeagueCompetitionFormat = {
  format_id: string | number;
  league_division_id: string | number;
  title: string;
  organization: TCompetitionOrganization;
  format: TCompetitionFormat;
  competition: TPlayerCompetition;
  default_participation_points: number;
  holes: TCompetitionFormatHoles;
  handicap: TCompetitionHandicap;
  handicap_allowance?: number; // default = 1
  team_scores?: TCompetitionTeamScores;
  team_scores_best_gross?: number;
  team_scores_best_net?: number;
  team_scores_combination?: TCompetitionScoreCombo;
  custom_points: any;
  leaderboard_data: Array<{
    id: number;
    point_allocation: "custom" | "posted_points" | "posted";
    custom_points?: Array<{ position: number; points: number }> | any;
  }>;
};

//GET Format
export async function GetLeagueCompetitionFormat(
  params: TGetLeagueCompetitionFormat,
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseUrl, params, useGlobalLoader, token);
}

// POST Format
export async function PostLeagueCompetitionFormat(params: TPostLeagueCompetitionFormat, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

//PUT Round
export async function PutLeagueCompetitionFormat(params: TPutLeagueCompetitionFormat, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

//DELETE Format
export async function DeleteLeagueCompetitionFormat(params: { format_id: number }, useGlobalLoader: boolean) {
  return await APIDelete(baseUrl, params, useGlobalLoader);
}
