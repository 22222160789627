import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { showError } from "redux/actions/ui";
import { TTaxLines } from "redux/reducers/models/facility";

import { displayPercent } from "helpers/Helpers";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import Page from "components/page/Page";
import Card from "components/card/Card";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";
import Input from "components/form/input/Input";

import { useTranslation, Trans } from "react-i18next";
import { GetTaxLine } from "api/rpc/2024-04/facilityAdmin/client/taxLines";
import { GetTaxNumbers } from "api/rpc/2024-04/facilityAdmin/facility/taxNumber";
import axios, { CancelToken } from "axios";
import { GetProvince } from "api/rpc/2022-09/common/country/country";
import { Badge } from "components/badge/Badge";
import { IFacilityState } from "redux/reducers/facility";
import "../facility/facility.scss";
import { StatusCode } from "api/protocols";

interface ITaxNumber {
  id: number;
  title: string;
  number: string;
}

const CURRENT_URL = "/admin/settings/taxes";
export default function TaxLines() {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();
  const facilityStore = useAppSelector(store => store.facilityStore);

  const [taxLines, setTaxLines] = useState<TTaxLines[]>(undefined);
  const [taxNumbers, setTaxNumbers] = useState<ITaxNumber[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadTaxLines(source.token);
    return () => source.cancel("TaxLines unmounted.");
  }, [facilityStore.facility]);

  async function loadTaxLines(token?: CancelToken) {
    if (taxLines !== undefined) {
      setTaxLines(undefined);
    }
    const res = await GetTaxLine(null, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading taxlines."));
    }

    const numberRes = await GetTaxNumbers(null, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (numberRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading tax numbers."));
    }

    const params = {
      country_id: facilityStore?.facility?.country_id,
    };
    const provinceRes = await GetProvince(params, false);
    if (provinceRes.status !== StatusCode.OK) {
      return;
    }

    const provinces = provinceRes.data;
    const taxLinesRes = res.data;

    for (let i = 0; i < taxLinesRes.length; i++) {
      for (let k = 0; k < provinces.length; k++) {
        if (taxLinesRes[i].province_id === provinces[k].id) {
          taxLinesRes[i].province_name = provinces[k].name;
        }
      }
    }

    setTaxLines(res.status !== StatusCode.OK ? [] : taxLinesRes);
    setTaxNumbers(numberRes.data);
  }

  const primaryAction = {
    content: t("secure.facility.settings.tax_lines.tax_lines.001"),
    action: () => history.push(CURRENT_URL + "/line/new"),
  };

  return (
    <Page
      title={"Taxes"} /* TODO: Translation */
      narrow
      multipleActionDropdownAction={{
        label: "Options",
        dropdownProps: {
          alignment: "right",
          options: [
            {
              type: "handler",
              label: "Add Tax Number", // TODO: Translation
              handler: () => history.push(CURRENT_URL + "/number/new"),
              icon: "hashtag",
            },
            {
              type: "handler",
              label: t("secure.facility.settings.tax_lines.tax_lines.001"),
              handler: () => history.push(CURRENT_URL + "/line/new"),
              icon: "percent",
            },
          ],
        },
      }}
    >
      <Card>
        <Card.Section title="Tax Numbers" /* TODO: Translation */>
          <DataTable
            columns={[
              { label: "Title" }, // TODO: Translation
              { label: "Number" }, // TODO: Translation
            ]}
            loading={taxNumbers === undefined}
          >
            {taxNumbers?.map(number => (
              <tr
                key={number.id}
                onClick={() => history.push(CURRENT_URL + `/number/${String(number.id)}`)}
                className="clickable"
              >
                <td>{number.title}</td>
                <td>{number.number}</td>
              </tr>
            ))}
          </DataTable>
        </Card.Section>
      </Card>
      <Card>
        <Card.Section title="Tax Lines" /* TODO: Translation */>
          <DataTable
            columns={[
              { label: t("secure.facility.settings.tax_lines.tax_lines.004") },
              { label: t("secure.facility.settings.tax_lines.tax_lines.005") },
              { label: "Province" },
            ]}
            loading={taxLines === undefined}
          >
            {taxLines?.map(line => (
              <tr
                key={line.id}
                onClick={() => history.push(CURRENT_URL + `/line/${String(line.id)}`)}
                className="clickable"
              >
                <td>{line.name}</td>
                <td>{displayPercent(line.percentage / 100)}</td>
                <td>
                  <Badge size="medium" type="primary">
                    {line.province_name}
                  </Badge>
                </td>
              </tr>
            ))}
          </DataTable>
        </Card.Section>
      </Card>
    </Page>
  );
}
