import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { TTeesheetNotification } from "redux/reducers/models/teesheet";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/tee-sheet/notification";

export type TTeesheetNotificationType = "admin" | "customer";

type TGetTeesheetNotificationAll = {
  date?: Date;
  course_id?: string | number;
  user_type?: TTeesheetNotificationType;
};

export type TPosTeesheettNotification = {
  date: string;
  course_id: number;
  subject?: string;
  content?: string;
  user_type: TTeesheetNotificationType;
  type?: string; // default = 'passive'
};

export type TPutTeesheetNotification = {
  id: string | number;
  date: string;
  course_id: number;
  subject?: string;
  content?: string;
  user_type: TTeesheetNotificationType;
  type?: string; // default = 'passive'
};

//GET
export async function GetTeeSheetNotification(
  params: { id: string | number },
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet<TTeesheetNotification>(baseUrl, params, useGlobalLoader, token);
}

export async function GetTeeSheetNotificationAll(
  params: TGetTeesheetNotificationAll,
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet<TTeesheetNotification[]>(baseUrl + "/all", params, useGlobalLoader, token);
}

//POST
export async function PostTeeSheetNotification(params: TPosTeesheettNotification, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

//PUT
export async function PutTeeSheetNotification(params: TPutTeesheetNotification, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

//DELETE
export async function DeleteTeeSheetNotification(params: { id: string | number }, useGlobalLoader: boolean) {
  return await APIDelete(baseUrl, params, useGlobalLoader);
}
