import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useHistory } from "react-router-dom";
import { IOnlineStoreActions } from "redux/actions/customer/onlineStore";
import { IOnlineStoreState } from "redux/reducers/customer/onlineStore";
import "./NavigationBar.scss";

interface INavigationBarProps {
  onlineStoreStore: IOnlineStoreState;
  onlineStoreActions: IOnlineStoreActions;
  facilityShortName: string;
  facilityLogoSource: string;
  shoppingBagHasItems: boolean;
}

export default function NavigationBar(props: INavigationBarProps) {
  const history = useHistory();

  function navigateToHomePage() {
    history.push(`/online-store/${props.facilityShortName}`);
  }

  function navigateToCart() {
    history.push(`/online-store/${props.facilityShortName}/shoppingBag`);
  }

  function handleProductSearchQueryChange(e: any) {
    props.onlineStoreActions.updateProductSearchQuery(e.target.value);
  }

  function handleProductSearchKeyDown(e: any) {
    if (e.key === "Enter") {
      navigateToHomePage();
    }
  }

  return (
    <div className="online-store-navigation-bar">
      <div>
        <ul className="navigation-bar-options">
          <li className="navigation-bar-option">
            <button onClick={navigateToHomePage}>
              <img className="navigation-bar-facility-logo" src={props.facilityLogoSource} alt="Facility Logo" />
            </button>
          </li>
        </ul>
      </div>
      <div>
        <span className="navigation-bar-search-input-container">
          <span className="navigation-bar-search-icon">
            <FontAwesomeIcon icon={["fal", "search"]}></FontAwesomeIcon>
          </span>
          <input
            className="navigation-bar-search-input"
            placeholder="Search"
            value={props.onlineStoreStore.productSearchQuery}
            onChange={handleProductSearchQueryChange}
            onKeyDown={handleProductSearchKeyDown}
          />
        </span>
        <button className="navigation-bar-shopping-bag" onClick={navigateToCart}>
          <FontAwesomeIcon icon={["fal", "shopping-bag"]}></FontAwesomeIcon>
        </button>
        {props.shoppingBagHasItems && <span className="navigation-bar-shopping-bag-dot"></span>}
      </div>
    </div>
  );
}
