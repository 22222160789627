import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dictionary, groupBy, range } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ButtonNew as Button } from "components/buttonNew";
import DatePickerBlurTitleInput from "./DatePickerBlurTitleInput";
import "./datePickerInput.scss";
import classNames from "classnames";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { TABLET_WIDTH } from "helpers/ScreenSizes";

export type DatePickerMonthsShown = 1 | 2;
export type DatePickerPosition = "left" | "center" | "right";
type DatePickerSize = "small" | "medium";

interface IDatePickerInputProps {
  label?: string;
  months: DatePickerMonthsShown;
  position: DatePickerPosition;
  size?: DatePickerSize;
  centeredInputText?: boolean;
  rightAlignedCalendar?: boolean;
  showQuickOptions?: boolean;
  startingDate?: Date;
  setStartingDate?: React.Dispatch<React.SetStateAction<Date>>;
  endingDate?: Date;
  setEndingDate?: React.Dispatch<React.SetStateAction<Date>>;
  multipleDates?: Date[];
  setMultipleDates?: (multipleDates: Date[]) => void;
  error?: boolean;
  disablePastDays?: boolean;
  showBlurTitleOnError?: boolean;
}

interface IDatePickerInputState {
  rangeDatePickerInputContainerFocused: boolean;
  multipleDatePickerInputContainerFocused: boolean;
  datePickerRef: React.MutableRefObject<HTMLDivElement>;
  datePickerOpen: boolean;
  datePickerInput: string;
  firstVisibleMonth: Date;
  selectedStartingDate: Date;
  selectedStartingDateInput: string;
  selectedEndingDate: Date;
  selectedEndingDateInput: string;
  selectedQuickOption: number;
}

interface IDatePickerInputDateRange {
  start: Date;
  end: Date;
}

interface IQuickOption {
  label: string;
  onClick: () => void;
}

type DatePickerView = "single" | "range" | "multiple" | "invalid";

export default function DatePickerInput(props: IDatePickerInputProps) {
  const {
    label,
    months,
    position,
    size = "medium",
    centeredInputText,
    rightAlignedCalendar,
    showQuickOptions,
    startingDate,
    setStartingDate,
    endingDate,
    setEndingDate,
    multipleDates,
    setMultipleDates,
    error,
    disablePastDays,
    showBlurTitleOnError,
  } = props;

  const windowSize = useWindowSize();

  const isTabletSize = windowSize.width <= TABLET_WIDTH;

  function determineDatePickerView(): DatePickerView {
    if (startingDate && endingDate) {
      return "range";
    } else if (startingDate) {
      return "single";
    } else if (multipleDates) {
      return "multiple";
    } else {
      return "invalid";
    }
  }

  const datePickerView = determineDatePickerView();

  const FORMAT_DATE_STRING_REGEX = /^([0-9][0-9])\/([0-9][0-9])\/([1-9][0-9]{3})$/;

  const [datePickerInputState, setDatePickerInputState] = useState<IDatePickerInputState>({
    rangeDatePickerInputContainerFocused: false,
    multipleDatePickerInputContainerFocused: false,
    datePickerRef: useRef<HTMLDivElement>(null),
    datePickerOpen: false,
    datePickerInput: "",
    firstVisibleMonth: undefined,
    selectedStartingDate: undefined,
    selectedStartingDateInput: "",
    selectedEndingDate: undefined,
    selectedEndingDateInput: "",
    selectedQuickOption: undefined,
  });

  const quickOptions: IQuickOption[] = [
    { label: "Today", onClick: selectToday },
    { label: "Yesterday", onClick: selectYesterday },
    { label: "This week", onClick: selectThisWeek },
    { label: "Last week", onClick: selectLastWeek },
    { label: "This month", onClick: selectThisMonth },
    { label: "Last month", onClick: selectLastMonth },
    { label: "This year", onClick: selectThisYear },
    { label: "Last year", onClick: selectLastYear },
  ];

  function dateToFormattedDateString(date: Date) {
    if (!date) {
      return "";
    } else {
      const month = String(date.getMonth() + 1);
      const day = String(date.getDate());
      const year = String(date.getFullYear());
      const formatString = `${month.length === 1 ? "0" + month : month}/${day.length === 1 ? "0" + day : day}/${year}`;

      return formatString;
    }
  }

  function dateToReadableString(date: Date) {
    if (!date || isNaN(date.getTime())) {
      return "";
    } else {
      return date.toLocaleDateString("en-CA", { month: "short", day: "numeric", year: "numeric" });
    }
  }

  function handleOutsideDatePickerClick(event: any) {
    if (
      !datePickerInputState.datePickerRef.current ||
      datePickerInputState.datePickerRef.current.contains(event.target)
    ) {
      return;
    }

    setDatePickerInputState(prev => ({ ...prev, datePickerOpen: false }));
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideDatePickerClick);
    document.addEventListener("touchstart", handleOutsideDatePickerClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideDatePickerClick);
      document.removeEventListener("touchstart", handleOutsideDatePickerClick);
    };
  }, []);

  function formattedStringFromDateList(dates: Date[]) {
    let formattedString = "";

    const groupedYears: Dictionary<Date[]> = groupBy(dates, date => {
      return date.getFullYear();
    });

    Object.keys(groupedYears).forEach(year => {
      const yearlyDates: Date[] = groupedYears[year];

      const groupedMonths: Dictionary<Date[]> = groupBy(yearlyDates, yearlyDate => {
        return yearlyDate.getMonth();
      });

      let monthlyFormattedString = "";

      Object.keys(groupedMonths).forEach(month => {
        if (monthlyFormattedString !== "") {
          monthlyFormattedString += ", ";
        }

        const monthlyDates: Date[] = groupedMonths[month];

        monthlyFormattedString += monthlyDates[0].toLocaleString("default", { month: "short" });

        monthlyDates.forEach((monthDate, monthDateIndex) => {
          monthlyFormattedString += ` ${monthDate.getDate()}${monthDateIndex < monthlyDates.length - 1 ? "," : ""}`;
        });
      });

      if (formattedString !== "") {
        formattedString += "; ";
      }

      formattedString += monthlyFormattedString + " " + yearlyDates[0].toLocaleString("default", { year: "numeric" });
    });

    return formattedString;
  }

  const formattedMultipleDates = useMemo(() => {
    if (datePickerView !== "multiple" || multipleDates.length === 0) {
      return "Select Dates";
    }

    return formattedStringFromDateList(multipleDates);
  }, [multipleDates]);

  function getStartingAndEndingDatesWithDefault(): IDatePickerInputDateRange {
    const dateRange: IDatePickerInputDateRange = {
      start: undefined,
      end: undefined,
    };

    /* If there isn't a starting date default to the current date and
       check if the ending date occurs before it and if it does replace it
       with the current date as well
    */
    if (!startingDate) {
      dateRange.start = new Date();

      if (endingDate && dateIsGreaterThanOrEqual(dateRange.start, endingDate)) {
        dateRange.end = new Date();
      } else {
        dateRange.end = endingDate;
      }

      return dateRange;
    } else {
      // If there is already a starting date then leave it alone
      dateRange.start = startingDate;
      dateRange.end = endingDate;

      return dateRange;
    }
  }

  function resetDatePickerToInitialState() {
    let newFirstVisibleMonth: Date = null;
    let dateRange: IDatePickerInputDateRange = null;

    if (datePickerView === "multiple") {
      if (multipleDates.length === 0) {
        const today = new Date();

        newFirstVisibleMonth = new Date(today.getFullYear(), today.getMonth());
      } else {
        newFirstVisibleMonth = new Date(multipleDates[0].getFullYear(), multipleDates[0].getMonth());
      }
    } else {
      dateRange = getStartingAndEndingDatesWithDefault();

      newFirstVisibleMonth = new Date(dateRange.start.getFullYear(), dateRange.start.getMonth());
    }

    setDatePickerInputState(prev => ({
      ...prev,
      firstVisibleMonth: newFirstVisibleMonth,
      selectedStartingDate: undefined,
      selectedStartingDateInput: !dateRange?.start ? "" : dateToFormattedDateString(dateRange.start),
      selectedEndingDate: undefined,
      selectedEndingDateInput: !dateRange?.end ? "" : dateToFormattedDateString(dateRange.end),
      selectedQuickOption: undefined,
    }));
  }

  useEffect(() => {
    if (!datePickerInputState.datePickerOpen) {
      resetDatePickerToInitialState();
    }
  }, [datePickerInputState.datePickerOpen]);

  useEffect(() => {
    if (!startingDate && setStartingDate) {
      const dateRange = getStartingAndEndingDatesWithDefault();
      setStartingDate(dateRange.start);

      if (setEndingDate) {
        setEndingDate(dateRange.end);
      }
    }

    if (startingDate && !endingDate) {
      setDatePickerInputState(prev => ({
        ...prev,
        datePickerInput: dateToFormattedDateString(startingDate),
      }));
    }

    resetDatePickerToInitialState();
  }, [startingDate, endingDate]);

  useEffect(() => {
    if (datePickerInputState.selectedStartingDate) {
      setDatePickerInputState(prev => ({
        ...prev,
        selectedStartingDateInput: dateToFormattedDateString(datePickerInputState.selectedStartingDate),
      }));
    }
    if (datePickerInputState.selectedEndingDate) {
      setDatePickerInputState(prev => ({
        ...prev,
        selectedEndingDateInput: dateToFormattedDateString(datePickerInputState.selectedEndingDate),
      }));
    }
    if (datePickerInputState.selectedStartingDate && !datePickerInputState.selectedEndingDate) {
      setDatePickerInputState(prev => ({
        ...prev,
        selectedEndingDateInput: "",
      }));
    }
  }, [datePickerInputState.selectedStartingDate, datePickerInputState.selectedEndingDate]);

  function formattedDateStringIsValid(value: string) {
    // mm/dd/yyyy

    const matchedDateValues = FORMAT_DATE_STRING_REGEX.exec(value);

    const month = parseInt(matchedDateValues?.[1]);
    const day = parseInt(matchedDateValues?.[2]);
    const year = parseInt(matchedDateValues?.[3]);

    const daysInMonth = new Date(year, month, 0)?.getDate();

    if (!year || !month || !day || month < 1 || month > 12 || day < 1 || day > daysInMonth) {
      return false;
    }

    const date = new Date(value);

    if (disablePastDays && dateIsLessThan(date, getDayFromOffset({}))) {
      return false;
    }

    return true;
  }

  function getDatePickerPositionStyles() {
    if (position === "left") {
      return { left: "0%", transform: "translate(0%, 10px)" };
    } else if (position === "center") {
      return { left: "50%", transform: "translate(-50%, 10px)" };
    } else if (position === "right") {
      return { left: "100%", transform: "translate(-100%, 10px)" };
    }
  }

  function datesAreEqual(first: Date, second: Date) {
    if (first === undefined || second === undefined) {
      return false;
    } else {
      return (
        first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate()
      );
    }
  }

  function dateIsGreaterThan(first: Date, second: Date) {
    if (first === undefined || second === undefined) {
      return false;
    } else {
      const firstDate = new Date(first.getFullYear(), first.getMonth(), first.getDate());
      const secondDate = new Date(second.getFullYear(), second.getMonth(), second.getDate());

      return firstDate > secondDate;
    }
  }

  function dateIsGreaterThanOrEqual(first: Date, second: Date) {
    return dateIsGreaterThan(first, second) || datesAreEqual(first, second);
  }

  function dateIsLessThan(first: Date, second: Date) {
    if (first === undefined || second === undefined) {
      return false;
    } else {
      const firstDate = new Date(first.getFullYear(), first.getMonth(), first.getDate());
      const secondDate = new Date(second.getFullYear(), second.getMonth(), second.getDate());

      return firstDate < secondDate;
    }
  }

  function dateIsLessThanOrEqual(first: Date, second: Date) {
    return dateIsLessThan(first, second) || datesAreEqual(first, second);
  }

  function getDaysFromPreviousMonthInCurrentMonth(date: Date) {
    return new Date(date.getFullYear(), date.getMonth()).getDay();
  }

  function getDaysInMonth(date: Date) {
    return 32 - new Date(date.getFullYear(), date.getMonth(), 32).getDate();
  }

  function getStartAndEndDaysInPreviousMonth(date: Date): [number, number] {
    const previousMonth = new Date(date.getFullYear(), date.getMonth() - 1);
    const totalDaysInPreviousMonth = getDaysInMonth(previousMonth);
    const daysFromPreviousMonthInCurrentMonth = getDaysFromPreviousMonthInCurrentMonth(date);

    return [totalDaysInPreviousMonth - (daysFromPreviousMonthInCurrentMonth - 1), totalDaysInPreviousMonth + 1];
  }

  function getDaysInNextMonth(date: Date) {
    return 7 * 6 - (getDaysFromPreviousMonthInCurrentMonth(date) + getDaysInMonth(date));
  }

  function shiftMonth(date: Date, monthsToShiftBy: number) {
    const shiftedMonth = new Date(date.getFullYear(), date.getMonth());

    shiftedMonth.setMonth(date.getMonth() + monthsToShiftBy);
    return shiftedMonth;
  }

  function shiftFirstVisibleMonth(monthsToShiftBy: number) {
    const newFirstVisibleMonth = shiftMonth(datePickerInputState.firstVisibleMonth, monthsToShiftBy);
    setDatePickerInputState(prev => ({ ...prev, firstVisibleMonth: newFirstVisibleMonth }));
  }

  function handleSelectCalendarDay(disableSelect: boolean, day: Date, keepDatePickerOpen = false) {
    if (disableSelect) {
      return;
    } else if (datePickerView === "range") {
      if (datePickerInputState.selectedStartingDate === undefined) {
        setDatePickerInputState(prev => ({ ...prev, selectedStartingDate: day }));
      } else if (datePickerInputState.selectedEndingDate === undefined) {
        const [start, end] = [datePickerInputState.selectedStartingDate, day].sort(
          (a, b) => a?.getTime() - b?.getTime(),
        );
        setDatePickerInputState(prev => ({ ...prev, selectedStartingDate: start, selectedEndingDate: end }));
      } else {
        setDatePickerInputState(prev => ({ ...prev, selectedStartingDate: day, selectedEndingDate: undefined }));
      }
      setDatePickerInputState(prev => ({ ...prev, selectedQuickOption: undefined }));
    } else if (datePickerView === "single") {
      setStartingDate(day);
      setDatePickerInputState(prev => ({
        ...prev,
        datePickerOpen: keepDatePickerOpen,
        datePickerInput: dateToFormattedDateString(day),
      }));
    } else if (datePickerView === "multiple") {
      let updatedMultipleDates: Date[] = null;

      if (multipleDates.some(multipleDate => datesAreEqual(multipleDate, day))) {
        updatedMultipleDates = multipleDates.filter(multipleDate => !datesAreEqual(multipleDate, day));
      } else {
        updatedMultipleDates = [...multipleDates, day];
      }

      updatedMultipleDates.sort((prev, next) => {
        if (datesAreEqual(prev, next)) {
          return 0;
        } else if (dateIsGreaterThan(prev, next)) {
          return 1;
        } else {
          return -1;
        }
      });

      setMultipleDates(updatedMultipleDates);
    }
  }

  type CalendarDayVisibleMonthRelation = "previous" | "current" | "next";
  function renderCalendarDay(
    monthRelation: CalendarDayVisibleMonthRelation,
    day: number,
    visible: boolean,
    visibleMonth: Date,
  ) {
    const year = visibleMonth.getFullYear();
    const month = visibleMonth.getMonth() + (monthRelation === "current" ? 0 : monthRelation === "previous" ? -1 : 1);
    const calendarDay = new Date(year, month, day);
    const dayIsDisabled = disablePastDays && dateIsLessThan(calendarDay, new Date());

    let dayIsSelected = false;
    let dayIsSelectedTwice = false;
    let dayIsInDateRange = false;

    if (datePickerInputState.selectedStartingDate || datePickerInputState.selectedEndingDate) {
      dayIsSelected =
        datesAreEqual(datePickerInputState.selectedStartingDate, calendarDay) ||
        datesAreEqual(datePickerInputState.selectedEndingDate, calendarDay);
      dayIsSelectedTwice =
        datesAreEqual(datePickerInputState.selectedStartingDate, calendarDay) &&
        datesAreEqual(datePickerInputState.selectedEndingDate, calendarDay);

      if (
        dateIsGreaterThanOrEqual(calendarDay, datePickerInputState.selectedStartingDate) &&
        dateIsGreaterThanOrEqual(datePickerInputState.selectedEndingDate, calendarDay)
      ) {
        dayIsInDateRange = true;
      }
    } else if (startingDate || endingDate) {
      dayIsSelected = datesAreEqual(startingDate, calendarDay) || datesAreEqual(endingDate, calendarDay);
      dayIsSelectedTwice = datesAreEqual(startingDate, calendarDay) && datesAreEqual(endingDate, calendarDay);

      if (dateIsGreaterThanOrEqual(calendarDay, startingDate) && dateIsLessThanOrEqual(calendarDay, endingDate)) {
        dayIsInDateRange = true;
      }
    } else if (multipleDates) {
      if (multipleDates.some(multipleDate => datesAreEqual(multipleDate, calendarDay))) {
        dayIsSelected = true;
      }
    }

    let calendarDayContainerClass = "";
    let calendarDayClasses = "";

    if (dayIsDisabled) {
      calendarDayContainerClass = "date-picker-calendar-day-disabled-container";
    } else if (dayIsSelectedTwice) {
      calendarDayContainerClass = "date-picker-calendar-day-selected-twice-container";
    } else if (dayIsInDateRange) {
      calendarDayContainerClass = "date-picker-calendar-day-in-date-range-container";
    }

    calendarDayClasses += getClassNameWithResponsiveSize("date-picker-calendar-day");

    if (dayIsDisabled && monthRelation !== "current") {
      calendarDayClasses += " date-picker-calendar-day-disabled date-picker-calendar-day-outside-month";
    } else if (dayIsDisabled) {
      calendarDayClasses += " date-picker-calendar-day-disabled";
    } else if (dayIsSelected) {
      calendarDayClasses += " date-picker-calendar-day-selected";
    } else if (monthRelation !== "current") {
      calendarDayClasses += " date-picker-calendar-day-outside-month";
    }

    const isCurrentDay = calendarDay.toDateString() === getDayFromOffset({}).toDateString();

    return (
      <div
        className={classNames(calendarDayContainerClass, {
          "font-bold": !dayIsSelected && !dayIsInDateRange && isCurrentDay,
        })}
        style={{
          ...(dayIsInDateRange && !dayIsDisabled
            ? datesAreEqual(calendarDay, datePickerInputState.selectedStartingDate ?? startingDate)
              ? { borderTopLeftRadius: "50%", borderBottomLeftRadius: "50%" }
              : datesAreEqual(calendarDay, datePickerInputState.selectedEndingDate ?? endingDate)
              ? { borderTopRightRadius: "50%", borderBottomRightRadius: "50%" }
              : {}
            : {}),
          visibility: visible ? "visible" : "hidden",
        }}
        onClick={() => handleSelectCalendarDay(dayIsDisabled, calendarDay)}
      >
        <div className={calendarDayClasses}>
          {day}
          {isCurrentDay && <span className={getClassNameWithResponsiveSize("day-circle")} />}
        </div>
      </div>
    );
  }

  function handleClearMultipleDates() {
    setMultipleDates([]);
  }

  function handleCancelDatePicker() {
    setDatePickerInputState(prev => ({ ...prev, datePickerOpen: false }));
  }

  function handleApplySelectedDate() {
    if (datePickerInputState.selectedStartingDate && datePickerInputState.selectedEndingDate) {
      if (setEndingDate) {
        setStartingDate(datePickerInputState.selectedStartingDate);
        setEndingDate(datePickerInputState.selectedEndingDate);
        setDatePickerInputState(prev => ({ ...prev, datePickerOpen: false }));
      }
    }
  }

  function getDayFromOffset(offset: { yearOffset?: number; monthOffset?: number; dayOffset?: number }) {
    const currentDate = new Date();
    const offsettedDate = new Date(
      currentDate.getFullYear() + (offset.yearOffset ?? 0),
      currentDate.getMonth() + (offset.monthOffset ?? 0),
      currentDate.getDate() + (offset.dayOffset ?? 0),
    );

    return offsettedDate;
  }

  function selectDateRange(id: number, select: () => void) {
    setDatePickerInputState(prev => ({
      ...prev,
      selectedQuickOption: id,
    }));

    select();
  }

  function selectToday() {
    const today = getDayFromOffset({});
    const newFirstVisibleMonth = new Date(today.getFullYear(), today.getMonth());

    setDatePickerInputState(prev => ({
      ...prev,
      selectedStartingDate: today,
      selectedEndingDate: today,
      firstVisibleMonth: newFirstVisibleMonth,
    }));
  }

  function selectYesterday() {
    const yesterday = getDayFromOffset({ dayOffset: -1 });
    const newFirstVisibleMonth = new Date(yesterday.getFullYear(), yesterday.getMonth());

    setDatePickerInputState(prev => ({
      ...prev,
      selectedStartingDate: yesterday,
      selectedEndingDate: yesterday,
      firstVisibleMonth: newFirstVisibleMonth,
    }));
  }

  function selectThisWeek() {
    const currentDate = new Date();
    const startOfWeek = getDayFromOffset({ dayOffset: currentDate.getDay() * -1 });
    const endOfWeek = getDayFromOffset({ dayOffset: 6 - currentDate.getDay() });
    const newFirstVisibleMonth = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth());

    setDatePickerInputState(prev => ({
      ...prev,
      selectedStartingDate: startOfWeek,
      selectedEndingDate: endOfWeek,
      firstVisibleMonth: newFirstVisibleMonth,
    }));
  }

  function selectLastWeek() {
    const currentDate = new Date();
    const startOfLastWeek = getDayFromOffset({ dayOffset: currentDate.getDay() * -1 - 7 });
    const endOfLastWeek = getDayFromOffset({ dayOffset: currentDate.getDay() * -1 - 1 });
    const newFirstVisibleMonth = new Date(startOfLastWeek.getFullYear(), startOfLastWeek.getMonth());

    setDatePickerInputState(prev => ({
      ...prev,
      selectedStartingDate: startOfLastWeek,
      selectedEndingDate: endOfLastWeek,
      firstVisibleMonth: newFirstVisibleMonth,
    }));
  }

  function selectThisMonth() {
    const currentDate = new Date();

    const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth());
    const endOfMonth = new Date(startOfMonth.getFullYear(), startOfMonth.getMonth() + 1, 0);

    setDatePickerInputState(prev => ({
      ...prev,
      selectedStartingDate: startOfMonth,
      selectedEndingDate: endOfMonth,
      firstVisibleMonth: startOfMonth,
    }));
  }

  function selectLastMonth() {
    const currentDate = new Date();

    const startOfLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1);
    const endOfLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);

    setDatePickerInputState(prev => ({
      ...prev,
      selectedStartingDate: startOfLastMonth,
      selectedEndingDate: endOfLastMonth,
      firstVisibleMonth: startOfLastMonth,
    }));
  }

  function selectThisYear() {
    const currentDate = new Date();

    const startOfYear = new Date(currentDate.getFullYear(), 0);
    const newFirstVisibleMonth = new Date(currentDate.getFullYear(), currentDate.getMonth());

    setDatePickerInputState(prev => ({
      ...prev,
      selectedStartingDate: startOfYear,
      selectedEndingDate: currentDate,
      firstVisibleMonth: newFirstVisibleMonth,
    }));
  }

  function selectLastYear() {
    const currentDate = new Date();

    const startOfLastYear = new Date(currentDate.getFullYear() - 1, 0);
    const endOfLastYear = new Date(currentDate.getFullYear(), 0, 0);
    const newFirstVisibleMonth = new Date(startOfLastYear.getFullYear(), startOfLastYear.getMonth());

    setDatePickerInputState(prev => ({
      ...prev,
      selectedStartingDate: startOfLastYear,
      selectedEndingDate: endOfLastYear,
      firstVisibleMonth: newFirstVisibleMonth,
    }));
  }

  function handleToggleDatePicker() {
    setDatePickerInputState(prev => ({
      ...prev,
      datePickerOpen: !prev.datePickerOpen,
    }));
  }

  function handleOpenDatePickerOnFocus() {
    setDatePickerInputState(prev => ({
      ...prev,
      datePickerOpen: true,
    }));
  }

  function changeDatePickerInput(event: any) {
    const updatedDatePickerInput = event.target.value;

    setDatePickerInputState(prev => ({
      ...prev,
      datePickerInput: updatedDatePickerInput,
    }));

    if (formattedDateStringIsValid(updatedDatePickerInput)) {
      const matchDatePickerDate = updatedDatePickerInput.match(FORMAT_DATE_STRING_REGEX);

      const month = parseInt(matchDatePickerDate[1]) - 1;
      const day = parseInt(matchDatePickerDate[2]);
      const year = parseInt(matchDatePickerDate[3]);

      const newDatePickerInputDate = new Date(year, month, day);

      const newFirstVisibleMonth = new Date(newDatePickerInputDate.getFullYear(), newDatePickerInputDate.getMonth());

      handleSelectCalendarDay(false, newDatePickerInputDate, true);
      setDatePickerInputState(prev => ({
        ...prev,
        firstVisibleMonth: newFirstVisibleMonth,
      }));
    }
  }

  function changeSelectedStartingDateInput(event: any) {
    const updatedSelectedStartingDateInput = event.target.value;

    setDatePickerInputState(prev => ({
      ...prev,
      selectedStartingDateInput: updatedSelectedStartingDateInput,
    }));

    if (formattedDateStringIsValid(updatedSelectedStartingDateInput)) {
      const matchedStartingDate = updatedSelectedStartingDateInput.match(FORMAT_DATE_STRING_REGEX);

      const month = parseInt(matchedStartingDate[1]) - 1;
      const day = parseInt(matchedStartingDate[2]);
      const year = parseInt(matchedStartingDate[3]);

      const newSelectedStartingDate = new Date(year, month, day);

      const [start, end] = [newSelectedStartingDate, datePickerInputState.selectedEndingDate].sort(
        (a, b) => a?.getTime() - b?.getTime(),
      );
      const startInput = dateToFormattedDateString(start);
      const endInput = dateToFormattedDateString(end);

      const newFirstVisibleMonth = new Date(start.getFullYear(), start.getMonth());

      setDatePickerInputState(prev => ({
        ...prev,
        selectedStartingDate: start,
        selectedStartingDateInput: startInput,
        selectedEndingDate: end,
        selectedEndingDateInput: endInput,
        firstVisibleMonth: newFirstVisibleMonth,
      }));
    }
  }

  function changeSelectedEndingDateInput(event: any) {
    const updatedSelectedEndingDateInput = event.target.value;

    setDatePickerInputState(prev => ({
      ...prev,
      selectedEndingDateInput: updatedSelectedEndingDateInput,
    }));

    if (formattedDateStringIsValid(updatedSelectedEndingDateInput)) {
      const matchedEndingDate = updatedSelectedEndingDateInput.match(FORMAT_DATE_STRING_REGEX);

      const month = parseInt(matchedEndingDate[1]) - 1;
      const day = parseInt(matchedEndingDate[2]);
      const year = parseInt(matchedEndingDate[3]);

      const newSelectedEndingDate = new Date(year, month, day);

      const [start, end] = [
        datePickerInputState.selectedStartingDate ?? newSelectedEndingDate,
        newSelectedEndingDate,
      ].sort((a, b) => a?.getTime() - b?.getTime());

      const startInput = dateToFormattedDateString(start);
      const endInput = dateToFormattedDateString(end);

      const newFirstVisibleMonth = new Date(end.getFullYear(), end.getMonth() - (months - 1));

      setDatePickerInputState(prev => ({
        ...prev,
        selectedStartingDate: start,
        selectedStartingDateInput: startInput,
        selectedEndingDate: end,
        selectedEndingDateInput: endInput,
        firstVisibleMonth: newFirstVisibleMonth,
      }));
    }
  }

  function renderDateInputRanges() {
    return (
      <div
        className="date-picker-date-ranges-container"
        style={{
          ...(months > 1 ? { gap: "10px" } : {}),
        }}
      >
        <DatePickerBlurTitleInput
          value={datePickerInputState.selectedStartingDateInput}
          containerClassName={getClassNameWithResponsiveSize("date-picker-date-ranges-date")}
          calendarIconClassName="date-picker-input-calendar-icon"
          centeredInputText={false}
          error={!formattedDateStringIsValid(datePickerInputState.selectedStartingDateInput)}
          errorTitle="Invalid Date"
          showCalendarIcon={false}
          blurTitle={dateToReadableString(new Date(datePickerInputState.selectedStartingDateInput))}
          showErrorOnEmptyString={false}
          showBlurTitleOnError={false}
          onChange={changeSelectedStartingDateInput}
        />
        <div className="date-picker-date-ranges-dash">-</div>
        <DatePickerBlurTitleInput
          value={datePickerInputState.selectedEndingDateInput}
          containerClassName={getClassNameWithResponsiveSize("date-picker-date-ranges-date")}
          calendarIconClassName="date-picker-input-calendar-icon"
          centeredInputText={false}
          error={!formattedDateStringIsValid(datePickerInputState.selectedEndingDateInput)}
          errorTitle="Invalid Date"
          showCalendarIcon={false}
          blurTitle={dateToReadableString(new Date(datePickerInputState.selectedEndingDateInput))}
          showErrorOnEmptyString={false}
          showBlurTitleOnError={false}
          onChange={changeSelectedEndingDateInput}
        />
      </div>
    );
  }

  function areRangeViewDatesOutOfBounds() {
    const today = getDayFromOffset({});

    if (!disablePastDays) {
      return false;
    } else if (datePickerView === "range") {
      return dateIsLessThan(startingDate, today) || dateIsLessThan(endingDate, today);
    }
  }

  function areMultipleViewDatesOutOfBounds() {
    const today = getDayFromOffset({});

    if (!disablePastDays) {
      return false;
    } else if (datePickerView === "multiple") {
      return multipleDates.some(date => dateIsLessThan(date, today));
    }
  }

  function getClassNameWithResponsiveSize(className: string) {
    const responsiveSize = windowSize.width > TABLET_WIDTH ? size : "small";

    return `${className}-${responsiveSize}`;
  }

  function getRangeBorderStyles() {
    const hasError = error || areRangeViewDatesOutOfBounds();
    const isFocusedError = datePickerInputState.rangeDatePickerInputContainerFocused && hasError;
    const isError = !datePickerInputState.rangeDatePickerInputContainerFocused && hasError;
    const isFocused = datePickerInputState.rangeDatePickerInputContainerFocused && !hasError;

    return {
      "focused-error": isFocusedError,
      error: isError,
      focused: isFocused,
    };
  }

  function getMultipleBorderStyles() {
    const hasError = error || areMultipleViewDatesOutOfBounds();
    const isFocusedError = datePickerInputState.multipleDatePickerInputContainerFocused && hasError;
    const isError = !datePickerInputState.multipleDatePickerInputContainerFocused && hasError;
    const isFocused = datePickerInputState.multipleDatePickerInputContainerFocused && !hasError;

    return {
      "focused-error": isFocusedError,
      error: isError,
      focused: isFocused,
    };
  }

  function getMobileDatePickerPositionStyles() {
    return datePickerInputState.datePickerOpen
      ? { transform: "translate(0, -400px)" }
      : { transform: "translate(0, 0)" };
  }

  return (
    <>
      {datePickerView !== "invalid" && datePickerInputState.firstVisibleMonth && (
        <div>
          {label && <div className="date-picker-label">{label}</div>}
          <div ref={datePickerInputState.datePickerRef} className="date-picker-container">
            {datePickerView === "range" && (
              <button
                type="button"
                className={classNames("date-picker-input-container", getRangeBorderStyles())}
                onClick={handleToggleDatePicker}
                onFocus={() =>
                  setDatePickerInputState(prev => ({ ...prev, rangeDatePickerInputContainerFocused: true }))
                }
                onBlur={() =>
                  setDatePickerInputState(prev => ({ ...prev, rangeDatePickerInputContainerFocused: false }))
                }
              >
                <div className="date-picker-input-range" style={{ justifyContent: centeredInputText && "center" }}>
                  <FontAwesomeIcon className="date-picker-input-calendar-icon" icon={["fal", "calendar"]} />
                  {dateToReadableString(startingDate)} - {dateToReadableString(endingDate)}
                </div>
              </button>
            )}
            {datePickerView === "multiple" && (
              <button
                type="button"
                className={classNames("date-picker-input-container", getMultipleBorderStyles())}
                onClick={handleToggleDatePicker}
                onFocus={() =>
                  setDatePickerInputState(prev => ({ ...prev, multipleDatePickerInputContainerFocused: true }))
                }
                onBlur={() =>
                  setDatePickerInputState(prev => ({ ...prev, multipleDatePickerInputContainerFocused: false }))
                }
              >
                <div
                  className="date-picker-input-multiple-dates"
                  style={{ justifyContent: centeredInputText && "center" }}
                >
                  <FontAwesomeIcon className="date-picker-input-calendar-icon" icon={["fal", "calendar"]} />
                  <div className="date-picker-input-multiple-dates-content">{formattedMultipleDates}</div>
                </div>
              </button>
            )}
            {datePickerView === "single" && (
              <DatePickerBlurTitleInput
                value={datePickerInputState.datePickerInput}
                containerClassName="date-picker-input-container"
                calendarIconClassName="date-picker-input-calendar-icon"
                centeredInputText={centeredInputText}
                error={error || !formattedDateStringIsValid(datePickerInputState.datePickerInput)}
                errorTitle="Invalid Date"
                showBlurTitleOnError={!!showBlurTitleOnError}
                showCalendarIcon={true}
                blurTitle={dateToReadableString(new Date(datePickerInputState.datePickerInput))}
                showErrorOnEmptyString
                onChange={changeDatePickerInput}
                placeholder="Select date"
                revealText={datePickerInputState.datePickerOpen}
                onFocus={handleOpenDatePickerOnFocus}
                rightAlignedCalendar={rightAlignedCalendar}
              />
            )}
            {datePickerInputState.datePickerOpen && isTabletSize && (
              <div
                className="date-picker-backdrop"
                onClick={() => setDatePickerInputState(prev => ({ ...prev, datePickerOpen: false }))}
              ></div>
            )}
            <div
              className="date-picker"
              style={
                isTabletSize
                  ? getMobileDatePickerPositionStyles()
                  : { ...getDatePickerPositionStyles(), display: datePickerInputState.datePickerOpen ? "flex" : "none" }
              }
            >
              {showQuickOptions && datePickerView === "range" && (
                <div className="date-picker-quick-options-section">
                  {quickOptions.map((quickOption, index) => {
                    return (
                      <div
                        key={index}
                        className={classNames(getClassNameWithResponsiveSize("date-picker-quick-option"), {
                          "date-picker-quick-option-selected": datePickerInputState.selectedQuickOption === index,
                        })}
                        onClick={() => selectDateRange(index, quickOption.onClick)}
                      >
                        {quickOption.label}
                      </div>
                    );
                  })}
                </div>
              )}
              <div className="date-picker-selection-section">
                <div className="date-picker-calendars-section">
                  {range(1, months + 1).map((month, index) => {
                    const currentVisibleMonth = shiftMonth(datePickerInputState.firstVisibleMonth, index);

                    return (
                      <div
                        key={`month-${month}`}
                        className="date-picker-calendar-section"
                        style={{
                          ...(month < months ? { borderRight: "1px solid lightgrey" } : {}),
                        }}
                      >
                        <div className={getClassNameWithResponsiveSize("date-picker-month-shifter-container")}>
                          <button
                            type="button"
                            className={getClassNameWithResponsiveSize("date-picker-month-shifter-shift-button")}
                            style={{
                              ...(month === 1 ? { visibility: "visible" } : { visibility: "hidden" }),
                            }}
                            onClick={() => shiftFirstVisibleMonth(-1)}
                          >
                            <FontAwesomeIcon icon={["far", "chevron-left"]} />
                          </button>
                          <div className="date-picker-month-shifter-month">
                            {currentVisibleMonth.toLocaleDateString("en-ca", {
                              month: "long",
                              year: "numeric",
                            })}
                          </div>
                          <button
                            type="button"
                            className={getClassNameWithResponsiveSize("date-picker-month-shifter-shift-button")}
                            style={{
                              ...(month === months ? { visibility: "visible" } : { visibility: "hidden" }),
                            }}
                            onClick={() => shiftFirstVisibleMonth(1)}
                          >
                            <FontAwesomeIcon icon={["far", "chevron-right"]} />
                          </button>
                        </div>
                        {datePickerView === "single" && (
                          <Button
                            size={isTabletSize ? "medium" : size}
                            className="date-picker-today-date-btn"
                            type="secondary"
                            onClick={() =>
                              handleSelectCalendarDay(
                                datesAreEqual(startingDate, getDayFromOffset({})),
                                getDayFromOffset({}),
                              )
                            }
                          >
                            {"Today"}
                          </Button>
                        )}
                        {datePickerView === "range" && months === 1 && <>{renderDateInputRanges()}</>}
                        <div className={getClassNameWithResponsiveSize("date-picker-calendar")}>
                          {["Su", "Mo", "Tu", "We", "Th", "Fr", "Sat"].map(weekDay => {
                            return (
                              <div
                                key={weekDay}
                                className={getClassNameWithResponsiveSize("date-picker-calendar-week-day")}
                              >
                                {weekDay}
                              </div>
                            );
                          })}
                          {range(...getStartAndEndDaysInPreviousMonth(currentVisibleMonth)).map(day => {
                            return (
                              <React.Fragment key={`previous-month-day-${day}`}>
                                {renderCalendarDay("previous", day, months === 1, currentVisibleMonth)}
                              </React.Fragment>
                            );
                          })}
                          {range(1, getDaysInMonth(currentVisibleMonth) + 1).map(day => {
                            return (
                              <React.Fragment key={`current-month-day-${day}`}>
                                {renderCalendarDay("current", day, true, currentVisibleMonth)}
                              </React.Fragment>
                            );
                          })}
                          {range(1, getDaysInNextMonth(currentVisibleMonth) + 1).map(day => {
                            return (
                              <React.Fragment key={`next-month-day-${day}`}>
                                {renderCalendarDay("next", day, months === 1, currentVisibleMonth)}
                              </React.Fragment>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="date-picker-actions-section">
                  {months > 1 && (
                    <div
                      style={{
                        visibility: endingDate ? "visible" : "hidden",
                      }}
                    >
                      {renderDateInputRanges()}
                    </div>
                  )}
                  <div
                    className="date-picker-actions"
                    style={{
                      ...(months === 1 ? { width: "100%" } : {}),
                    }}
                  >
                    {datePickerView === "multiple" && (
                      <Button
                        size={isTabletSize ? "medium" : size}
                        style={{
                          ...(months === 1 ? { flex: "1" } : {}),
                        }}
                        onClick={handleClearMultipleDates}
                      >
                        <div className="date-picker-action">Clear</div>
                      </Button>
                    )}
                    <Button
                      size={isTabletSize ? "medium" : size}
                      type={datePickerView === "multiple" ? "primary" : "secondary"}
                      style={{
                        ...(months === 1 ? { flex: "1" } : {}),
                      }}
                      onClick={handleCancelDatePicker}
                    >
                      <div className="date-picker-action">{datePickerView === "range" ? "Cancel" : "Close"}</div>
                    </Button>
                    {datePickerView === "range" && (
                      <Button
                        size={isTabletSize ? "medium" : size}
                        type="primary"
                        style={{
                          ...(months === 1 ? { flex: "1" } : {}),
                        }}
                        onClick={handleApplySelectedDate}
                        disabled={
                          datePickerInputState.selectedStartingDate === undefined ||
                          datePickerInputState.selectedEndingDate === undefined
                        }
                      >
                        <div className="date-picker-action">Apply</div>
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
