import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import { GetStaffAccounts } from "api/rpc/2024-04/masterAdmin/client/settings/staffAccount";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import { Badge } from "components/badge/Badge";
import Icon from "components/icon/Icon";
import DropFilter from "components/dropdown/DropFilter";
import { IUser } from "redux/reducers/models/user";

type ClientStaffFilters = {
  accountState: Set<AccountStateType>;
};
type AccountStateType = "active" | "disabled";

export default function StaffAccounts() {
  const history = useHistory();
  const { masterClientStore } = useAppSelector(store => store);

  const [staffFilters, setStaffFilters] = useState<ClientStaffFilters>({
    accountState: new Set(["active"]),
  });

  const [staffData, setStaffData] = useState<IUser[]>(null);

  const filteredAdmins = useMemo(() => {
    if (staffData === null) {
      return null;
    } else {
      const activeFilters = [...staffFilters.accountState];
      return staffData.filter((admin: any, index: number) => {
        return activeFilters.includes(admin.state);
      });
    }
  }, [staffData, staffFilters.accountState]);

  useEffect(() => {
    void loadStaffAccounts();
  }, [masterClientStore.client]);

  async function loadStaffAccounts() {
    const res = await GetStaffAccounts(
      {
        client_id: masterClientStore?.client?.id,
      },
      false,
    );
    if (res.status !== StatusCode.OK) {
      return;
    }

    setStaffData(res.data);
  }

  function navigateToNewStaff() {
    history.push("/admin/client-settings/staff/new");
  }

  function navigateToEditStaff(url: string) {
    history.push(url);
  }

  const primaryAction = {
    content: "New Admin",
    action: navigateToNewStaff,
    disabled: !masterClientStore.client,
  };

  function handleApplyFilters(filterReturn: Record<string, any>[]) {
    const filterIds = filterReturn.map(filter => filter.id as AccountStateType);
    setStaffFilters({
      ...staffFilters,
      accountState: new Set([...filterIds]),
    });
  }

  return (
    <Page
      title="Staff Accounts"
      subtitle={masterClientStore.client ? masterClientStore.client.full_name : "No Client Selected"}
      narrow
      primaryAction={primaryAction}
    >
      {masterClientStore.client && (
        <>
          <div className="flex flex-row flex-wrap gap-4 pb-4">
            <DropFilter
              filterType="Checkbox"
              title="Account State"
              filterData={[
                { id: "active", label: "Active" },
                { id: "disabled", label: "Disabled" },
              ]}
              filterIdPropFromData="id"
              filterLabelPropFromData="label"
              applyFilters={filterReturn => handleApplyFilters(filterReturn)}
              defaultCheckboxes={[...staffFilters.accountState]}
              leftAligned
            />
          </div>
          <Card>
            <Card.Section table="true">
              <table className="ui-table ui-table-clickable">
                <thead>
                  <tr>
                    <td>Name</td>
                    <td>Username</td>
                    <td>Email</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {filteredAdmins?.map((admin: any, index: any) => {
                    return (
                      <tr
                        key={index}
                        onClick={() => navigateToEditStaff("/admin/client-settings/staff/" + String(admin.id))}
                      >
                        <td>{admin.full_name}</td>
                        <td>{admin.username}</td>
                        <td>{admin.email}</td>
                        <td>
                          {admin.state === "active" || admin.state === null ? (
                            <Badge
                              type="success"
                              size="medium"
                              iconLeft={<Icon icon="check" style="far" size="xsmall" />}
                              iconSize="small"
                              iconStyle="fas"
                            >
                              Active
                            </Badge>
                          ) : null}
                          {admin.state === "disabled" ? (
                            <Badge
                              type="error"
                              size="medium"
                              iconLeft={<Icon icon="times" style="far" size="xsmall" />}
                              iconSize="small"
                              iconStyle="fas"
                            >
                              Disabled
                            </Badge>
                          ) : null}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Card.Section>
          </Card>
        </>
      )}
    </Page>
  );
}
