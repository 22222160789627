import React, { useEffect, useRef, useState } from "react";
import axios, { CancelToken } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import {
  DeleteLockTeeTime,
  GetBookingEngine,
  GetTeeTime,
  PostBooking,
  GetCart,
  PutCart,
  UpdateBooking,
} from "api/rpc/2022-09/guest/bookingEngine";
import { StatusCode } from "api/protocols";
import "./CustomerBooking.scss";
import OrderSummaryLineItem from "components/bookingEngine/orderSummaryLineItem/OrderSummaryLineItem";
import { useHistory, useParams } from "react-router";
import { IBookingEngineState } from "redux/reducers/bookingEngine";
import { IBookingEngineActions } from "redux/actions/actionTypes";
import CustomerNav from "elements/customer/CustomerNav";
import { UserActive } from "api/rpc";
import { IUIActions } from "redux/actions/ui";
import Card from "components/card/Card";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input";
import Radio from "components/radio";
import BookingTimer from "components/bookingEngine/bookingTimer/bookingTimer";
import { ButtonNew as Button } from "components/buttonNew";
import {
  AddFriend,
  GetCustomerFriends,
  GetCustomerPaymentMethods,
  ICustomerFriend,
  PostPaymentMethod,
  PostSetup,
  SearchCustomer,
} from "api/rpc/2022-09/customer/customer";
import Checkbox from "components/form/checkbox/Checkbox";
import ReactDOM from "react-dom";
import { Select } from "components/select/index";
import Sheet from "components/sheet/Sheet";
import CardSection from "components/cardSection/CardSection";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import Markdown from "components/markdown/Markdown";
import { convertTime } from "helpers/Helpers";
import { LocaleCurrency } from "helpers/Locale";
import Spin from "components/spin/spin";
import { CustomerNavigation } from "elements/customer/CustomerNavigation";
import { PostCart, PostCheckout, PostLineItemToCart } from "api/rpc/guest/cart";
import { PostTransaction, PutCapture } from "api/rpc/2022-09/guest/transaction";
import { PutCompleteOrder } from "api/rpc/guest/order";
import { GetCustomerTeeSheetSettings } from "api/rpc/2022-09/customer/facility";
import { IBookingParticipant } from "redux/reducers/models/teetime";
import GolferCard from "components/bookingPopUp/golferCard/GolferCard";
import { useTranslation } from "react-i18next";
import { IOrder } from "../models/order";
import Popup from "components/popup/Popup";
import { GetLeagueParticipants } from "api/rpc/2022-09/customer/league";
import validator from "validator";
import { ITaxLine } from "components/OrderSummary/OrderSummary";
export interface IBookingState {
  holeAmount: number;
  golferAmount: number;
  powerCartAmount: number;
  teeTimeLockExpire: string;
  customerCreditCards: Array<Record<string, any>>;
  customerGiftCards: Array<Record<string, any>>;
  customerRainChecks: Array<Record<string, any>>;
  selectedCardId: number;
  selectedCard: Record<string, any>;
  customerFirstName: string;
  customerLastName: string;
  customerEmail: string;
  customerPhone: string;
  acceptTerms: boolean;
  newCardActive: boolean;
  saveCard: boolean;
  paymentTermsActive: boolean;
  elementComplete: {
    cardNumber: boolean;
    cardExpiry: boolean;
    cardCvc: boolean;
  };
  cart: Record<string, any>;
  loadPutCart: boolean;

  bookingParticipants: Array<Partial<IBookingParticipant>>;
  player2: string;
  player3: string;
  player4: string;
  friendsSearchResult2: Array<ICustomerFriend>;
  friendsSearchResult3: Array<ICustomerFriend>;
  friendsSearchResult4: Array<ICustomerFriend>;
  newPlayerQuery: string;
  selectedNewPlayer: ICustomerFriend;
  addPlayerModalVisible: boolean;
  searching: boolean;
  customerSearchResult: Array<ICustomerFriend>;
  friendsList: Array<ICustomerFriend>;
  leagueParticipantList: Array<Record<string, any>>;
  disableSingleBooking: boolean;
  [key: string]: any;
}

export interface ITeeTimeState {
  activeUser: boolean;
}

interface IProps {
  bookingEngineStore: IBookingEngineState;
  bookingEngineActions: IBookingEngineActions;
  uiActions: IUIActions;
}

interface IParams {
  bookingEngineHandle: string;
  facilityShortName: string;
}

interface IPrepaidWarning {
  title: string;
  description: string;
  open: boolean;
}

export default function CustomerBooking(props: IProps) {
  const { bookingEngineStore, bookingEngineActions, uiActions } = props;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { bookingEngineHandle, facilityShortName } = useParams<IParams>();
  const { Option } = Select;
  const loadedPaymentMethods = useRef(false);

  const stripe = useStripe();
  const elements = useElements();

  const queryString = window.location.search;

  const teeTimeId = Number(new URLSearchParams(queryString).get("id"));
  const date = new URLSearchParams(queryString).get("date");

  const [activeUser, setActiveUser] = useState(false);
  const [holeButtons, setHoleButtons] = useState([true, true]);
  const [loadingComplete, setLoadingComplete] = useState(false);

  const [prepaidWarning, setPrepaidWarning] = useState<IPrepaidWarning>({
    title: "",
    description: "",
    open: false,
  });

  const [bookingState, setBookingState] = useState<IBookingState>({
    holeAmount: 18,
    golferAmount: 1,
    powerCartAmount: bookingEngineStore.cart_rule === "required" ? 1 : 0,
    teeTimeLockExpire: bookingEngineStore.selectedLockoutExpireTime,
    customerCreditCards: [],
    customerGiftCards: [],
    customerRainChecks: [],
    selectedCardId: undefined,
    selectedCard: undefined,
    customerFirstName: bookingEngineStore.selectedUserFirstName,
    customerLastName: bookingEngineStore.selectedUserLastName,
    customerEmail: bookingEngineStore.selectedUserEmail,
    customerPhone: bookingEngineStore.selectedUserPhone,
    acceptTerms: false,
    newCardActive: false,
    saveCard: false,
    paymentTermsActive: false,
    elementComplete: {
      cardNumber: false,
      cardExpiry: false,
      cardCvc: false,
    },
    cart: null,
    loadPutCart: false,
    bookingParticipants: [
      { customer_id: bookingEngineStore?.activeCustomerId, full_name: bookingEngineStore?.userFullName },
    ],

    player2: "",
    player3: "",
    player4: "",
    friendsSearchResult2: [],
    friendsSearchResult3: [],
    friendsSearchResult4: [],
    newPlayerQuery: "",
    selectedNewPlayer: null,
    addPlayerModalVisible: false,
    searching: false,
    customerSearchResult: [],
    friendsList: [],
    leagueParticipantList: [],
    disableSingleBooking: false,
  });

  function updateBookingState(newBookingState: Partial<IBookingState>) {
    setBookingState(cur => {
      return { ...cur, ...newBookingState };
    });
  }

  const orderSummaryObjects = [
    {
      lineItem: "Date",
      itemValue: bookingEngineStore.selectedDate,
    },
    {
      lineItem: "Time",
      itemValue: bookingEngineStore.selectedStartTime,
    },
    {
      lineItem: "No. Holes",
      itemValue: bookingState.holeAmount,
    },
    {
      lineItem: "No. Golfers",
      itemValue: bookingState.golferAmount,
    },
    {
      lineItem: "No. Carts",
      itemValue: bookingState.powerCartAmount,
    },
  ];

  useEffect(() => {
    void setSingleBookings(bookingEngineStore?.singleBookings);
    if (bookingEngineStore?.selectedTeeTime?.league_id) {
      void getLeagueParticipants();
    } else {
      void getFriendsList();
    }
    void getCustomerLoggedIn();
    void getCart();
    if (bookingEngineStore.selectedTeeTimeId) {
      void disableHoles();
    }

    if (bookingEngineStore.creditCardRequired || bookingEngineStore?.prepaidRequired) {
      void getCustomerPaymentMethods(bookingEngineStore.facilityClientId);
      loadedPaymentMethods.current = true;
    }
  }, []);

  useEffect(() => {
    if (!loadedPaymentMethods.current && bookingState?.cart?.total_price_due) {
      void getCustomerPaymentMethods(bookingEngineStore.facilityClientId);
      loadedPaymentMethods.current = true;
    }
  }, [bookingState?.cart?.total_price_due]);

  useEffect(() => {
    if (
      bookingEngineStore.activeCustomerId !== null &&
      bookingEngineStore.selectedDate === "" &&
      bookingEngineStore.selectedTeeTimeId === ""
    ) {
      //Page was refreshed, get booking engine, tee time and customer payment information
      //void getBookingEngine();
      void returnToHomePage(true);
    }
  }, [bookingEngineStore.activeCustomerId]);

  useEffect(() => {
    if (bookingEngineStore.selectedTeeTimeId) {
      void disableHoles();
    }
  }, [bookingState.golferAmount, bookingEngineStore.selectedTeeTimeId]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    async function updateCart() {
      updateBookingState({ loadPutCart: true });
      const customerIds = [
        ...bookingState?.bookingParticipants?.map(participant => participant?.customer_id),
        ...new Array(Math.abs(bookingState.golferAmount - bookingState?.bookingParticipants?.length)).fill(
          bookingEngineStore?.activeCustomerId,
        ),
      ];
      const putCartRes = await PutCart(
        {
          cart_token: localStorage.getItem(`${bookingEngineHandle}_booking_engine_cart_token`),
          tee_time_id: bookingEngineStore.selectedTeeTimeId,
          holes: bookingState.holeAmount,
          quantity: bookingState.golferAmount,
          power_cart_quantity: bookingState.powerCartAmount,
          customer_ids: customerIds,
        },
        false,
        source.token,
      )
        .then(data => {
          if (data.data === "Cancelled") {
            updateBookingState({ cart: null, loadPutCart: false });
          } else {
            updateBookingState({ cart: data.data, loadPutCart: false });
          }
        })
        .catch(err => {
          uiActions.showError(err.message);
          updateBookingState({ cart: null, loadPutCart: false });
          return;
        });
    }
    // Update the cart line items on load & when a selection is made
    if (
      localStorage.getItem(`${bookingEngineHandle}_booking_engine_cart_token`) &&
      bookingState.holeAmount &&
      bookingState.golferAmount &&
      bookingState.powerCartAmount != null &&
      bookingEngineStore.selectedTeeTimeId &&
      loadingComplete
    ) {
      void updateCart();
    }

    return () => {
      //Cancel request (sets the returned data to 'Cancelled' when request is cancelled)"
      source.cancel("Cancelled");
    };
  }, [
    bookingState.holeAmount,
    bookingState.golferAmount,
    bookingState.powerCartAmount,
    loadingComplete,
    localStorage.getItem(`${bookingEngineHandle}_booking_engine_cart_token`),
    bookingState?.bookingParticipants,
  ]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = setTimeout(() => {
        void search(bookingState.newPlayerQuery, "customerSearchResult", source.token);
      }, 500);
    }
    return () => {
      source.cancel("Cancelled");
      mounted = false;
      clearTimeout(timeoutId);
      setBookingState(prevState => ({ ...prevState, customerSearchResult: [] }));
    };
  }, [bookingState.newPlayerQuery]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = setTimeout(() => {
        void search(bookingState.player2, "friendsSearchResult2", source.token);
      }, 500);
    }
    return () => {
      source.cancel("Cancelled");
      mounted = false;
      clearTimeout(timeoutId);
      setBookingState(prevState => ({ ...prevState, friendsSearchResult2: [] }));
    };
  }, [bookingState.player2]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = setTimeout(() => {
        void search(bookingState.player3, "friendsSearchResult3", source.token);
      }, 500);
    }
    return () => {
      source.cancel("Cancelled");
      mounted = false;
      clearTimeout(timeoutId);
      setBookingState(prevState => ({ ...prevState, friendsSearchResult3: [] }));
    };
  }, [bookingState.player3]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = setTimeout(() => {
        void search(bookingState.player4, "friendsSearchResult4", source.token);
      }, 500);
    }
    return () => {
      source.cancel("Cancelled");
      mounted = false;
      clearTimeout(timeoutId);
      setBookingState(prevState => ({ ...prevState, friendsSearchResult4: [] }));
    };
  }, [bookingState.player4]);

  function setSingleBookings(value: string) {
    if ((value === "allow_within_group" && bookingEngineStore?.selectedGolferSpotsRemaining > 3) || value === "none") {
      setBookingState(prevState => ({ ...prevState, disableSingleBooking: true, golferAmount: 2 }));
    }
  }

  const search = async (playerSearchQuery: string, playerSearchResult: string, cancelToken: CancelToken) => {
    try {
      if (playerSearchQuery === "") {
        setBookingState(prevState => ({ ...prevState, [playerSearchResult]: [] }));
        return;
      } else {
        setBookingState(prevState => ({ ...prevState, searching: true }));
        let friendsResults: any;
        if (playerSearchResult === "customerSearchResult") {
          friendsResults = await searchCustomers(cancelToken);
        } else {
          friendsResults = await searchFriends(playerSearchQuery, cancelToken);
        }
        setBookingState(prevState => ({ ...prevState, searching: false, [playerSearchResult]: friendsResults }));
      }
    } catch (error) {
      console.log("err", error);
    }
    return;
  };

  async function getFriendsList() {
    const friendsListRes = await GetCustomerFriends(null, true);
    if (friendsListRes.status !== StatusCode.OK) {
      uiActions.showError(friendsListRes.message);
      return;
    }
    setBookingState(prevState => ({ ...prevState, friendsList: friendsListRes.data }));
  }

  async function getLeagueParticipants() {
    const leagueParticipantRes = await GetLeagueParticipants(
      { league_id: bookingEngineStore?.selectedTeeTime?.league_id },
      true,
    );
    if (leagueParticipantRes?.status !== StatusCode.OK) {
      uiActions.showError("Error getting league participants");
      return;
    }

    const filteredLeagueParticipants = leagueParticipantRes?.data?.filter(
      (participant: Record<string, any>) => participant?.user_id !== bookingEngineStore?.activeCustomerId,
    );
    setBookingState(prevState => ({ ...prevState, leagueParticipantList: filteredLeagueParticipants }));
  }

  async function searchCustomers(cancelToken: CancelToken) {
    let searchCustomersRes;
    const phoneNumberFormats = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(bookingState?.newPlayerQuery);
    if (
      validator.isEmail(bookingState?.newPlayerQuery) ||
      validator.isMobilePhone(bookingState?.newPlayerQuery) ||
      validator.isNumeric(bookingState?.newPlayerQuery) ||
      phoneNumberFormats
    ) {
      searchCustomersRes = await SearchCustomer(
        { search: bookingState.newPlayerQuery, client_id: bookingEngineStore?.facilityClientId },
        false,
        cancelToken,
      );
    } else {
      searchCustomersRes = await SearchCustomer(
        { member_search: bookingState.newPlayerQuery, client_id: bookingEngineStore?.facilityClientId },
        false,
        cancelToken,
      );
      searchCustomersRes.data = searchCustomersRes?.data?.filter(
        (customer: Record<string, any>) => !customer?.customer_type[0]?.default,
      );
    }

    if (searchCustomersRes.status !== StatusCode.OK) {
      if (searchCustomersRes.message !== "Cancelled") {
        uiActions.showError(searchCustomersRes.message);
      }
      return [];
    }
    return searchCustomersRes.data as Array<ICustomerFriend>;
  }

  async function searchFriends(playerSearchQuery: string, cancelToken: CancelToken) {
    const searchFriendsRes = await GetCustomerFriends({ search: playerSearchQuery }, false, cancelToken);
    if (searchFriendsRes.status !== StatusCode.OK) {
      if (searchFriendsRes.message !== "Cancelled") {
        uiActions.showError(searchFriendsRes.message);
      }
      return [];
    }
    return searchFriendsRes.data;
  }

  async function getCart() {
    //Create a new cart if there is no customer cart token in local storage
    if (!localStorage.getItem(`${bookingEngineHandle}_booking_engine_cart_token`)) {
      const getCartRes = await GetCart(
        {
          facility_id: bookingEngineStore.selectedFacilityId,
          token: localStorage.getItem(`${bookingEngineHandle}_booking_engine_cart_token`),
        },
        true,
      );
      if (getCartRes.status !== StatusCode.OK) {
        uiActions.showError(getCartRes.message);
        return;
      }

      //Set new customer cart token
      localStorage.setItem(`${bookingEngineHandle}_booking_engine_cart_token`, getCartRes.data.token);

      updateBookingState({ cart: getCartRes.data });
    }
  }

  async function getTeeTime() {
    const params = {
      date,
      id: teeTimeId,
      booking_engine_handle: bookingEngineHandle,
      extended: true,
    };
    const teeTimeRes = await GetTeeTime(params, true);

    if (teeTimeRes.status !== StatusCode.OK) {
      uiActions.showError(teeTimeRes.message);
      return;
    }

    return teeTimeRes?.data[0] as Record<string, any>;
  }

  async function getCustomerLoggedIn() {
    // Check if user is logged in
    if (bookingEngineStore.activeCustomerId == null) {
      uiActions.enqueue();
      const activeUserRes = await UserActive(true);
      uiActions.dequeue();
      if (activeUserRes.status === StatusCode.OK) {
        ReactDOM.unstable_batchedUpdates(() => {
          setActiveUser(true);
          bookingEngineActions.update({
            selectedUserFirstName: activeUserRes.data.first_name,
            selectedUserLastName: activeUserRes.data.last_name,
            selectedUserEmail: activeUserRes.data.email,
            selectedUserPhone: activeUserRes.data.phone,
            userFullName: activeUserRes.data.full_name,
            activeCustomerId: activeUserRes.data.id,
          });
          updateBookingState({
            customerFirstName: activeUserRes.data.first_name,
            customerLastName: activeUserRes.data.last_name,
            customerEmail: activeUserRes.data.email,
            customerPhone: activeUserRes.data.phone,
          });
        });
      } else {
        //User is not logged in, send them back to the home page
        setActiveUser(false);
        uiActions.showError(t("guest.customer.customer_booking.001"));
        void returnToHomePage(false);
      }
    } else {
      setActiveUser(true);
    }
  }

  async function getBookingEngine() {
    const params = {
      handle: bookingEngineHandle,
    };

    const bookingEngineRes = await GetBookingEngine(params, true);

    if (bookingEngineRes.status !== StatusCode.OK) {
      uiActions.showError(t("guest.customer.customer_booking.002"));
      void returnToHomePage(true);
      return;
    }

    const teeTimeRes = await getTeeTime();

    const teesheetSettingsRes = await GetCustomerTeeSheetSettings(
      { facility_id: bookingEngineRes?.data[0]?.facility_id },
      true,
    );
    if (teesheetSettingsRes?.status !== StatusCode.OK) {
      uiActions.showError(t("guest.customer.customer_booking.058"));
      void returnToHomePage(true);
      return;
    }

    if (!teeTimeRes) {
      void returnToHomePage(true);
      return;
    }

    if (teeTimeRes.credit_card_required) {
      await getCustomerPaymentMethods(bookingEngineRes?.data[0].facility?.client_id);
    }

    const fullAddress = `${bookingEngineRes.data[0]?.facility?.address_line_1}, ${bookingEngineRes.data[0]?.facility?.city},
      ${bookingEngineRes.data[0]?.facility?.province_name}, ${bookingEngineRes.data[0]?.facility?.postal}`;

    let convertedStartTime: string = teeTimeRes.start_time;
    if (teeTimeRes?.start_time) {
      convertedStartTime = convertTime(teeTimeRes.start_time);
    }

    let formatDate = teeTimeRes?.date;
    if (teeTimeRes?.date) {
      formatDate = formatDateString(teeTimeRes.date);
    }

    ReactDOM.unstable_batchedUpdates(() => {
      bookingEngineActions.update({
        selectedCourseLogo: bookingEngineRes.data[0].facility.logo_source,
        selectedCourseFacility: bookingEngineRes.data[0].facility.long_name,
        selectedCourseLongName: bookingEngineRes.data[0].facility.long_name,
        selectedLoginRequired: bookingEngineRes.data[0].requires_login,
        selectedFacilityId: bookingEngineRes.data[0].facility_id,
        selectedBookingEngineId: bookingEngineRes.data[0].id,
        enabled_payment_methods: bookingEngineRes.data[0].enabled_payment_methods,
        courseLogo: bookingEngineRes.data[0]?.facility.logo_source,
        facilityClientId: bookingEngineRes.data[0].facility.client_id,
        selectedFullAddress: fullAddress,
        creditCardRequired: teeTimeRes?.credit_card_required,
        divisionsAvailable: teeTimeRes?.divisions_available,
        turnTeeTime: teeTimeRes?.turn_tee_time,
        selectedGolferSpotsRemaining: teeTimeRes?.quantity_remaining,
        selectedDate: teeTimeRes?.date,
        selectedStartTime: convertedStartTime,
        selectedFormattedDate: formatDate,
        selectedTeeTimeId: teeTimeRes?.id,
        cartsAvailable: teeTimeRes?.carts_available,
        cart_rule: teeTimeRes?.cart_rule,
        bookingTerms: teesheetSettingsRes.data?.booking_terms,
        paymentTerms: teesheetSettingsRes?.data?.payment_terms,
        singleBookings: teesheetSettingsRes?.data?.single_bookings,
        twosomeBookings: teesheetSettingsRes?.data?.twosome_bookings,
        threesomeBookings: teesheetSettingsRes?.data?.threesome_bookings,
        foursomeBookings: teesheetSettingsRes?.data?.foursome_bookings,
      });

      updateBookingState({
        teeTimeLockExpire: teeTimeRes?.lock?.expire_time,
        powerCartAmount: teeTimeRes?.cart_rule === "required" ? 1 : 0,
      });
    });
  }

  function handleHoleChange(holeAmount: number) {
    updateBookingState({ holeAmount });
  }

  function handleGolferAmountChange(golferAmount: number) {
    if (golferAmount < bookingState.golferAmount) {
      void handleRemoveGolfers(golferAmount);
    }

    updateBookingState({
      golferAmount: golferAmount,
    });
  }

  function handleCartChange(cartAmount: number) {
    updateBookingState({ powerCartAmount: cartAmount });
  }

  function handleRemoveGolfers(newQuantity: number) {
    const updatedBookingParticipants = [...bookingState.bookingParticipants].filter(
      (participant, index) => index + 1 <= newQuantity,
    );

    setBookingState(prevState => ({
      ...prevState,
      bookingParticipants: updatedBookingParticipants,
      player2: "",
      player3: "",
      player4: "",
    }));
  }

  async function returnToHomePage(deleteLock: boolean) {
    if (deleteLock) {
      const deleteLockTeeTimeRes = await DeleteLockTeeTime(true);

      if (deleteLockTeeTimeRes.status === StatusCode.OK) {
        bookingEngineActions.update({
          selectedLockoutDuration: undefined,
          selectedLockoutStartTime: undefined,
          selectedLockoutExpireTime: undefined,
        });
      }
    }
    bookingEngineActions.update({
      creditCardRequired: null,
      divisionsAvailable: [],
      turnTeeTime: null,
      selectedGolferSpotsRemaining: null,
      selectedDate: "",
      selectedStartTime: "",
      selectedFormattedDate: "",
      selectedTeeTimeId: null,
      cartsAvailable: false,
      cart_rule: null,
      selectedPaymentMethodId: undefined,
      postedBooking: null,
      selectedTeeTime: null,
    });
    const address = "/booking-engine/" + facilityShortName + "/" + bookingEngineHandle;
    history.push(address);
  }

  function formatDateString(date: string) {
    const tempDateArray = date.split("-");

    //Get full date for tee time
    const d = new Date(Number(tempDateArray[0]), Number(tempDateArray[1]) - 1, Number(tempDateArray[2]));

    const month = d.toLocaleString("en-us", { month: "long" });

    const day = d.toLocaleString("en-us", { day: "numeric" });

    const year = d.toLocaleString("en-us", { year: "numeric" });

    const weekday = d.toLocaleString("en-us", { weekday: "long" });

    const dateString = weekday + " " + month + " " + day + ", " + year;

    return dateString;
  }

  async function getCustomerPaymentMethods(clientId: number) {
    const customerPaymentMethodsRes = await GetCustomerPaymentMethods(
      { client_id: clientId, type: ["card", "gift_card", "rain_check"] },
      true,
    );
    if (customerPaymentMethodsRes.status !== StatusCode.OK) {
      uiActions.showError(customerPaymentMethodsRes.message);
      return;
    }
    updateBookingState({
      customerCreditCards: customerPaymentMethodsRes.data?.card,
      customerGiftCards: customerPaymentMethodsRes.data?.gift_card,
      customerRainChecks: customerPaymentMethodsRes.data?.rain_check,
      selectedCardId:
        customerPaymentMethodsRes.data?.card?.length >= 1 ? customerPaymentMethodsRes.data?.card[0]?.id : undefined,
      selectedCard:
        customerPaymentMethodsRes.data?.card?.length >= 1 ? customerPaymentMethodsRes.data?.card[0] : undefined,
    });
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    updateBookingState({ [id]: value });
  }

  function handleCardDropDownChange(value: number, id: string, card: Record<string, any>) {
    ReactDOM.unstable_batchedUpdates(() => {
      bookingEngineActions.update({
        selectedPaymentMethodId: value,
      });
      setBookingState(prevState => ({
        ...prevState,
        [id]: value,
        selectedCard: card ? card : prevState.selectedCard,
      }));
    });
  }

  function handleCheckboxChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, checked } = e.target;
    updateBookingState({ [id]: checked });
  }

  function disableCheckout() {
    //User hasn't accepted the payment terms and conditions
    if (!bookingState.acceptTerms) {
      return true;
    }
    // # of holes, # of players or # of power carts has not been selected
    if (!bookingState.holeAmount || !bookingState.golferAmount || bookingState.powerCartAmount == null) {
      return true;
    }

    // Cart is loading
    if (bookingState.loadPutCart || bookingState.cart === null) {
      return true;
    }

    //Credit card has not been selected
    if (bookingEngineStore.creditCardRequired) {
      if (bookingState.selectedCardId == null) {
        return true;
      }
    }

    //Credit card has not been selected (payment required)
    if (bookingEngineStore.prepaidRequired || bookingState?.cart?.total_price_due) {
      if (bookingState.selectedCardId == null) {
        return true;
      }
    }

    return false;
  }

  function disableHoles() {
    const tempHoleButtons = [true, true];
    // Disabling holes
    bookingEngineStore.divisionsAvailable?.forEach(division => {
      // 9 holes available
      if (division === 1) {
        tempHoleButtons[1] = false;
      } else if (division === 2) {
        if (
          (bookingEngineStore.divisionsAvailable?.length > 1 && bookingEngineStore.turnTeeTime === null) ||
          bookingEngineStore?.selectedTeeTime?.turn_tee_time?.blocked_type === "blocked" ||
          ((bookingEngineStore.selectedTeeTime?.turn_tee_time?.league_id ||
            bookingEngineStore.selectedTeeTime?.turn_tee_time?.tournament_id) &&
            (bookingEngineStore.selectedTeeTime?.league_id !==
              bookingEngineStore.selectedTeeTime?.turn_tee_time?.league_id ||
              bookingEngineStore.selectedTeeTime?.tournament_id !==
                bookingEngineStore.selectedTeeTime?.turn_tee_time?.tournament_id))
        ) {
          // Only 9 holes available
          tempHoleButtons[1] = false;
        } else if (bookingEngineStore.turnTeeTime != null) {
          // 18 holes available
          if (
            bookingEngineStore?.selectedGolferSpotsRemaining >= bookingState.golferAmount &&
            bookingEngineStore?.turnTeeTime?.quantity_remaining >= bookingState.golferAmount
          ) {
            tempHoleButtons[0] = false;
          } else {
            // Only 9 holes available
            tempHoleButtons[1] = false;
          }
        }
      }
    });

    if (tempHoleButtons[0] && tempHoleButtons[1]) {
      updateBookingState({ holeAmount: null });
    } else if (tempHoleButtons[0]) {
      updateBookingState({ holeAmount: 9 });
    } else if (tempHoleButtons[1]) {
      updateBookingState({ holeAmount: 18 });
    }
    setHoleButtons(tempHoleButtons);
    if (!loadingComplete) {
      setLoadingComplete(true);
    }
  }

  function openNewCardModal() {
    updateBookingState({ newCardActive: true });
  }

  function handleCardSectionChange(
    e: StripeCardNumberElementChangeEvent | StripeCardExpiryElementChangeEvent | StripeCardCvcElementChangeEvent,
  ) {
    updateBookingState({
      elementComplete: { ...bookingState.elementComplete, [e.elementType]: e.complete },
    });
  }

  async function handleAddNewCard() {
    if (!stripe || !elements) {
      uiActions.showError(t("guest.customer.customer_booking.003"));
      return;
    }
    const tempCreditCards = [...bookingState.customerCreditCards];
    uiActions.enqueue();
    try {
      const stripePaymentMethodRes = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement("cardNumber"),
      });

      if (stripePaymentMethodRes.paymentMethod) {
        const setupRes = await PostSetup(false);

        if (setupRes.status !== StatusCode.OK) {
          uiActions.showError(t("guest.customer.customer_booking.004"));
        }
        if (stripePaymentMethodRes.paymentMethod.id !== "undefined") {
          const stripeConfirmCardRes = await stripe.confirmCardSetup(setupRes.data.setup_intent.client_secret, {
            payment_method: stripePaymentMethodRes.paymentMethod.id,
          });
          if (stripeConfirmCardRes.error) {
            uiActions.showError(stripeConfirmCardRes.error.message);
          } else {
            const postPaymentRes = await PostPaymentMethod(
              {
                payment_method_id: stripeConfirmCardRes.setupIntent.payment_method,
                facility_id: props.bookingEngineStore.selectedFacilityId,
                client_id: props.bookingEngineStore.facilityClientId,
                save_card: bookingState.saveCard,
              },
              false,
            );
            if (postPaymentRes.status !== StatusCode.OK) {
              uiActions.showError(t("guest.customer.customer_booking.005"));
            } else {
              tempCreditCards.push(postPaymentRes.data);
              updateBookingState({
                customerCreditCards: tempCreditCards,
                selectedCardId: postPaymentRes.data.id,
                selectedCard: postPaymentRes?.data,
              });
              void closeAddNewCard();
              uiActions.showSuccess(t("guest.customer.customer_booking.006"));
            }
          }
        }
      } else {
        uiActions.showError(stripePaymentMethodRes.error.message);
      }
    } catch (e) {
      uiActions.dequeue();
    }
    uiActions.dequeue();
  }

  function closeAddNewCard() {
    elements.getElement(CardNumberElement).clear();
    elements.getElement(CardExpiryElement).clear();
    elements.getElement(CardCvcElement).clear();
    updateBookingState({ newCardActive: false });
  }

  async function handleCreateBooking() {
    let order = undefined;

    if (bookingState?.cart?.total_price_due) {
      uiActions.enqueue();
      //Create payment cart
      const cartRes = await PostCart(
        {
          sales_channel: "booking_engine",
          facility_id: bookingEngineStore?.selectedFacilityId,
          customer_id: bookingEngineStore?.activeCustomerId,
        },
        false,
      );
      if (cartRes.status !== StatusCode.OK) {
        uiActions.showError(t("guest.customer.customer_booking.007"));
        uiActions.dequeue();
        return;
      }

      const currentCartToken = cartRes?.data?.token;

      //Add prepaid line items to the new cart
      for (const lineItem of bookingState?.cart?.line_items) {
        if (lineItem?.pre_paid_required) {
          const postLineItemRes = await PostLineItemToCart(
            { variant_id: lineItem?.variant_id, cart_token: currentCartToken, quantity: lineItem?.quantity },
            false,
          );
          if (postLineItemRes?.status !== StatusCode.OK) {
            uiActions.showError(t("guest.customer.customer_booking.008"));
            uiActions.dequeue();
            return;
          }
        }
      }

      const postCheckoutRes = await PostCheckout({ cart_token: currentCartToken }, false);
      if (postCheckoutRes.status !== StatusCode.OK) {
        uiActions.showError(t("guest.customer.customer_booking.009"));
        uiActions.dequeue();
        return;
      }
      order = postCheckoutRes.data?.order;

      if (!order?.token) {
        uiActions.showError(t("guest.customer.customer_booking.010"));
        uiActions.dequeue();
        return;
      }

      //Process transaction
      const postTransactionRes = await PostTransaction(
        {
          processing_type: "online",
          order_token: order.token,
          kind: "authorization",
          source: "online",
          amount: order.total_price,
          payment_method: "card",
        },
        false,
      );

      const clientSecret = postTransactionRes?.data?.client_secret;

      if (postTransactionRes.status !== StatusCode.OK || !clientSecret) {
        uiActions.showError(t("guest.customer.customer_booking.011"));
        uiActions.dequeue();
        return;
      }

      if (!stripe || !elements) {
        uiActions.showError(t("guest.customer.customer_booking.012"));
        uiActions.dequeue();
        return;
      }

      //Confrim stripe card payment
      const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: bookingState?.selectedCard?.stripe_payment_method_id,
      });

      if (stripeError) {
        uiActions.showError(stripeError.message);
        uiActions.dequeue();
        return;
      }
      //Capture card payment
      const putCaptureRes = await PutCapture(
        { payment_intent_id: paymentIntent.id, transaction_id: postTransactionRes?.data?.id },
        false,
      );

      if (putCaptureRes.status !== StatusCode.OK) {
        uiActions.showError(t("guest.customer.customer_booking.013"));
        uiActions.dequeue();
        return;
      }

      //Complete Order
      const putCompleteOrderRes = await PutCompleteOrder({ token: order.token }, false);

      if (putCompleteOrderRes.status !== StatusCode.OK) {
        uiActions.showError(t("guest.customer.customer_booking.014"));
        uiActions.dequeue();
        return;
      }
      uiActions.dequeue();
    }

    await postCustomerBooking(order);
  }

  async function postCustomerBooking(order: Record<string, any>) {
    if (
      !bookingState.acceptTerms ||
      !bookingState.holeAmount ||
      !bookingState.golferAmount ||
      bookingState.powerCartAmount == null
    ) {
      console.log("Booking error, missing holes, players or power cart amount. User may not have accepted terms.");
      return;
    }

    if (bookingEngineStore.creditCardRequired && bookingState.selectedCardId == null) {
      console.log("Booking error, missing payment information.");
      return;
    }

    const lockAllOptions = order && bookingState?.cart?.pre_paid_required ? true : false;
    const lockQuantity =
      order && !bookingState?.cart?.pre_paid_required && bookingState?.cart?.booking_fee_required ? true : false;

    const customerIds = setCustomerIds();

    const params = {
      tee_time_id: bookingEngineStore.selectedTeeTimeId,
      holes: bookingState.holeAmount,
      quantity: bookingState.golferAmount,
      booking_engine_handle: bookingEngineHandle,
      power_cart_quantity: bookingState.powerCartAmount,
      customer_payment_method_id: bookingState.selectedCardId,
      accept_payment_terms: bookingState.acceptTerms,
      cart_token: bookingState.cart?.token,
      order_id: order && bookingState?.cart?.pre_paid_required ? order?.id : undefined,
      booking_fee_order_id: order && bookingState?.cart?.booking_fee_required ? order?.id : undefined,
      lock_quantity: lockAllOptions || lockQuantity,
      lock_holes: lockAllOptions,
      lock_power_cart_quantity: lockAllOptions,
      customer_ids: customerIds,
    };

    const bookingRes = await PostBooking(params, true);
    if (bookingRes.status !== StatusCode.OK) {
      uiActions.showError(t("guest.customer.customer_booking.015"));
      return;
    }
    bookingEngineActions.update({
      postedBooking: bookingRes.data,
      selectedTeeTime: null,
    });

    const bookingToken = bookingRes.data?.token as string;

    if (customerIds?.some(customerId => customerId !== bookingEngineStore?.activeCustomerId)) {
      //Skip adding friends for leagues
      if (!bookingEngineStore?.selectedTeeTime?.league_id) {
        //Add players in booking to friends list if they are not already apart of it
        await addFriends();
      }
    }

    localStorage.removeItem(`${bookingEngineHandle}_booking_engine_cart_token`);
    const address =
      "/booking-engine/" + facilityShortName + "/" + bookingEngineHandle + "/confirm" + "?token=" + bookingToken;
    history.push(address);
  }

  function setCustomerIds() {
    const customerIds: number[] = bookingState?.bookingParticipants?.map(participant => participant?.customer_id);

    const playerDifference = bookingState.golferAmount - customerIds.length;

    // Fill guest slots
    if (customerIds.length < bookingState.golferAmount) {
      for (let i = 0; i < playerDifference; i++) {
        customerIds.push(bookingEngineStore?.activeCustomerId);
      }
    }
    return customerIds;
  }

  async function addFriends() {
    for (const participant of bookingState.bookingParticipants) {
      if (participant?.customer_id !== bookingEngineStore?.activeCustomerId) {
        const checkFriendStatus = bookingState.friendsList?.some(friend => friend.id === participant.customer_id);
        if (!checkFriendStatus) {
          const addFriendRes = await AddFriend({ friend_user_id: participant.customer_id }, true);
          if (addFriendRes.status !== StatusCode.OK) {
            uiActions.showError(t("guest.customer.customer_booking.017"));
            return;
          }
        }
      }
    }
  }

  // Check if card inputs are filled
  const elementsComplete =
    bookingState.elementComplete.cardNumber === true &&
    bookingState.elementComplete.cardExpiry === true &&
    bookingState.elementComplete.cardCvc === true;

  function removeGolferFromBooking(index: number) {
    const updatedBookingParticipants = [...bookingState.bookingParticipants];
    updatedBookingParticipants.splice(index, 1);
    ReactDOM.unstable_batchedUpdates(() => {
      setBookingState(prevState => ({ ...prevState, bookingParticipants: updatedBookingParticipants }));
      setBookingState(prevState => ({ ...prevState, player2: "", player3: "", player4: "" }));
    });
  }

  const handleCustomerSearch = (query: string, name: string) => {
    setBookingState(prevState => ({ ...prevState, [name]: query }));
  };

  function handleCustomerSelection(id: number, friend: any) {
    const updatedBookingParticipants = [...bookingState.bookingParticipants];
    updatedBookingParticipants.push({ ...friend, customer_id: friend?.id });
    setBookingState(prevState => ({
      ...prevState,
      bookingParticipants: updatedBookingParticipants,
      player2: "",
      player3: "",
      player4: "",
      selectedNewPlayer: null,
      addPlayerModalVisible: false,
      newPlayerQuery: "",
      customerSearchResult: [],
      friendsSearchResult2: [],
      friendsSearchResult3: [],
      friendsSearchResult4: [],
    }));
  }

  function handleLeagueParticipantSelection(id: number, participant: Record<string, any>) {
    const updatedBookingParticipants = [...bookingState.bookingParticipants];
    updatedBookingParticipants.push({
      ...participant,
      customer_id: participant?.user_id,
      full_name: participant?.customer?.full_name,
    });
    setBookingState(prevState => ({
      ...prevState,
      bookingParticipants: updatedBookingParticipants,
      leagueParticipantQuery_2: "",
      leagueParticipantQuery_3: "",
      leagueParticipantQuery_4: "",
    }));
  }

  function displayAddPlayerModal() {
    setBookingState(prevState => ({
      ...prevState,
      addPlayerModalVisible: !prevState.addPlayerModalVisible,
      selectedNewPlayer: null,
      newPlayerQuery: "",
      customerSearchResult: [],
    }));
  }

  function renderFriendsList(playerSearchResult: string) {
    //Display the search results if the array contains values
    if (bookingState[playerSearchResult]?.length > 0) {
      return bookingState[playerSearchResult] as Array<ICustomerFriend>;
    } else {
      //Else return the entire friends list
      return bookingState.friendsList;
    }
  }

  function handleNewPlayerSelection(id: string, friend: any) {
    setBookingState(prevState => ({ ...prevState, selectedNewPlayer: friend }));
  }

  function removeNewPlayerSelection() {
    setBookingState(prevState => ({ ...prevState, selectedNewPlayer: null, newPlayerQuery: "" }));
  }

  function displayBookingFeeWarning() {
    setPrepaidWarning(prevState => ({
      ...prevState,
      open: true,
      title: t("guest.customer.customer_booking.054"),
      description: t("guest.customer.customer_booking.055"),
    }));
  }

  function displayPrePaidWarning() {
    setPrepaidWarning(prevState => ({
      ...prevState,
      open: true,
      title: t("guest.customer.customer_booking.056"),
      description: t("guest.customer.customer_booking.057"),
    }));
  }

  function searchLeagueParticipant(searchString: string, index: number) {
    setBookingState(prevState => ({ ...prevState, [`leagueParticipantQuery_${index + 1}`]: searchString }));
  }

  return (
    activeUser &&
    loadingComplete && (
      <>
        <div className="cb-background">
          <CustomerNavigation
            //uiActions={uiActions}
            activeUser={activeUser}
            userFirstName={bookingEngineStore.selectedUserFirstName}
            weather={undefined}
          />
          <div className="cb-timer-container">
            <div className="cb-timer-facility-container">
              <span className="cb-timer-course-logo">
                {bookingEngineStore.courseLogo && (
                  <img
                    className="cb-timer-course-logo"
                    src={bookingEngineStore.courseLogo}
                    alt={t("guest.customer.customer_booking.018")}
                  />
                )}
              </span>
              <div className="flex flex-col gap-1">
                <p className="cb-timer-course-name">{bookingEngineStore.selectedCourseLongName}</p>
                <p className="cb-timer-address">{bookingEngineStore.selectedFullAddress}</p>
              </div>
            </div>
            <div className="cb-timer-duration-container">
              <FontAwesomeIcon className="cb-timer-icon" size="1x" color="#999999" icon={["far", "clock"]} />
              <span className="cb-timer-address">{t("guest.customer.customer_booking.019")}</span>
              <span className="cb-timer-duration-countdown">
                <BookingTimer
                  expireTime={bookingEngineStore.selectedLockoutExpireTime}
                  teeTimeExpiryUrl={`/booking-engine/${facilityShortName}/${bookingEngineHandle}`}
                  active={true}
                />
              </span>
            </div>
          </div>
          <div className="cb-main-container">
            <div className="cb-main-container-left-side">
              <Card>
                <Card.Section>
                  <p className="cb-booking-details-selection-start-time">{bookingEngineStore.selectedStartTime}</p>
                  <p className="cb-booking-details-selection-date">{bookingEngineStore.selectedFormattedDate}</p>

                  <div className="cb-booking-details-selection-container">
                    <div className="cb-booking-details-selection-button-group">
                      <p className="cb-booking-details-selection-title">{t("guest.customer.customer_booking.020")}</p>
                      <Radio.Group name="number_of_holes" onChange={handleHoleChange} value={bookingState.holeAmount}>
                        <Radio.Button value={18} disabled={holeButtons[0]}>
                          <span className="mr-2">18</span>
                          <FontAwesomeIcon size="1x" icon={["far", "golf-flag-hole"]} />
                        </Radio.Button>
                        <Radio.Button value={9} disabled={holeButtons[1]}>
                          <span className="mr-2">9</span>
                          <FontAwesomeIcon size="1x" icon={["far", "golf-flag-hole"]} />
                        </Radio.Button>
                      </Radio.Group>
                    </div>

                    <div className="cb-booking-details-selection-button-group">
                      <p className="cb-booking-details-selection-title">{t("guest.customer.customer_booking.021")}</p>
                      <Radio.Group
                        name="number_of_golfers"
                        onChange={handleGolferAmountChange}
                        value={bookingState.golferAmount}
                      >
                        <Radio.Button value={1} disabled={bookingState.disableSingleBooking}>
                          {1}
                        </Radio.Button>
                        <Radio.Button
                          value={2}
                          disabled={bookingEngineStore.selectedGolferSpotsRemaining < 2 ? true : false}
                        >
                          {2}
                        </Radio.Button>
                        <Radio.Button
                          value={3}
                          disabled={bookingEngineStore.selectedGolferSpotsRemaining < 3 ? true : false}
                        >
                          {3}
                        </Radio.Button>
                        <Radio.Button
                          value={4}
                          disabled={bookingEngineStore.selectedGolferSpotsRemaining < 4 ? true : false}
                        >
                          {4}
                        </Radio.Button>
                      </Radio.Group>
                    </div>
                  </div>
                </Card.Section>
              </Card>

              <Card>
                {bookingEngineStore.enable_cart_bookings ? (
                  <div>
                    {(bookingEngineStore.cart_rule === "optional" || bookingEngineStore.cart_rule === "required") && (
                      <Card.Section>
                        <div className="cb-booking-details-selection-container">
                          <div style={{ width: "100%" }} className="cb-booking-details-selection-button-group">
                            <p className="cb-booking-details-selection-title">
                              {t("guest.customer.customer_booking.022")}
                            </p>
                            <Radio.Group
                              name="number_of_carts"
                              onChange={handleCartChange}
                              value={bookingState.powerCartAmount}
                            >
                              <Radio.Button
                                value={0}
                                disabled={bookingEngineStore.cart_rule === "required" ? true : false}
                              >
                                {t("guest.customer.customer_booking.051")}
                              </Radio.Button>
                              <Radio.Button value={1}>{t("guest.customer.customer_booking.052")}</Radio.Button>
                              <Radio.Button value={2}>{t("guest.customer.customer_booking.053")}</Radio.Button>
                            </Radio.Group>
                          </div>
                        </div>
                      </Card.Section>
                    )}
                  </div>
                ) : null}

                <Card.Section>
                  <p className="cb-booking-details-selection-title">{t("guest.customer.customer_booking.023")}</p>
                  {[...Array(bookingEngineStore?.selectedGolferSpotsRemaining)]?.map((num, index) => {
                    if (bookingState.bookingParticipants[index]) {
                      return (
                        <GolferCard
                          bookingEngineCard={true}
                          closable={index > 0}
                          removeGolfer={() => removeGolferFromBooking(index)}
                          name={bookingState.bookingParticipants[index]?.full_name}
                          key={index}
                        />
                      );
                    } else {
                      const searchResultKey = "friendsSearchResult".concat((index + 1).toString());

                      return (
                        <div key={index} className="new-player-sheet">
                          <Select
                            showSearch
                            onSearch={(query: string) =>
                              bookingEngineStore?.selectedTeeTime?.league_id
                                ? searchLeagueParticipant(query, index)
                                : handleCustomerSearch(query, "player".concat((index + 1).toString()))
                            }
                            onChange={(id: number, friendObject: ICustomerFriend) =>
                              bookingEngineStore?.selectedTeeTime?.league_id
                                ? handleLeagueParticipantSelection(id, friendObject)
                                : handleCustomerSelection(id, friendObject)
                            }
                            allowClear
                            searchValue={
                              bookingEngineStore?.selectedTeeTime?.league_id
                                ? bookingState[`leagueParticipantQuery_${index + 1}`]
                                : bookingState["player".concat((index + 1).toString())]
                            }
                            showDropDownOnFocus={!bookingEngineStore?.selectedTeeTime?.league_id ? true : false}
                            searching={bookingState.searching}
                            placeholder={`Guest`}
                            disabled={index + 1 <= bookingState.golferAmount ? false : true}
                          >
                            {!bookingEngineStore?.selectedTeeTime?.league_id && (
                              <div className="ui-select-dropdown-list-item" onClick={displayAddPlayerModal}>
                                {t("guest.customer.customer_booking.025")}
                              </div>
                            )}
                            {bookingEngineStore?.selectedTeeTime?.league_id
                              ? bookingState?.leagueParticipantList
                                  ?.filter(filteredParticipant =>
                                    filteredParticipant?.customer?.full_name
                                      ?.toLowerCase()
                                      .includes(bookingState[`leagueParticipantQuery_${index + 1}`]?.toLowerCase()),
                                  )
                                  ?.map((participant, index) => (
                                    <Option key={index} value={participant.user_id} extraValues={participant}>
                                      <span>{participant?.customer?.full_name}</span>
                                    </Option>
                                  ))
                              : renderFriendsList(searchResultKey)?.map((friend: ICustomerFriend, index: number) => {
                                  return (
                                    <Option key={index} value={friend.id} extraValues={friend}>
                                      <span>{friend.full_name}</span>
                                    </Option>
                                  );
                                })}
                          </Select>
                        </div>
                      );
                    }
                  })}
                </Card.Section>
              </Card>

              <Card>
                <Card.Section>
                  <p className="cb-booking-details-selection-title">{t("guest.customer.customer_booking.026")}</p>
                  {/* Hide for now, re-enable when we want to allow guest bookings */}

                  {/* <div className="cb-booking-details-selection-input-group">
                            <div className="cb-booking-details-selection-input">
                              <Input id="customerFirstName" label="First Name" value={bookingState.customerFirstName || ""} onChange={handleInputChange} />
                            </div>
                            <div className="cb-booking-details-selection-input">
                              <Input id="customerLastName" label="Last Name" value={bookingState.customerLastName || ""} onChange={handleInputChange}/>
                            </div>
                          </div>
                          
                          <div className="cb-booking-details-selection-input-group">
                            <div className="cb-booking-details-selection-input">
                              <Input className="cb-booking-details-selection-input" id="customerEmail" label="Email" value={bookingState.customerEmail || ""} onChange={handleInputChange}/>
                            </div>
                            <div className="cb-booking-details-selection-input">
                              <Input className="cb-booking-details-selection-input" id="customerPhone" type="number" label="Phone" value={bookingState.customerPhone || ""} onChange={handleInputChange} />
                            </div>
                          </div> */}

                  {bookingEngineStore.creditCardRequired ||
                  bookingEngineStore.prepaidRequired ||
                  bookingState?.cart?.total_price_due ? (
                    <div className="cb-dropdown">
                      <Select
                        label={t("guest.customer.customer_booking.027")}
                        defaultValue={bookingState.selectedCardId}
                        onChange={(value: number, card: Record<string, any>) =>
                          handleCardDropDownChange(value, "selectedCardId", card)
                        }
                      >
                        <li className="ui-select-dropdown-list-item" onClick={openNewCardModal}>
                          <p className="text-md">{t("guest.customer.customer_booking.028")}</p>
                        </li>
                        {bookingState.customerCreditCards?.map((card, index) => {
                          return (
                            <Option key={index} value={card?.id} name={card?.brand} extraValues={card}>
                              <span>
                                {String(card?.brand)?.charAt(0).toUpperCase()}
                                {String(card?.brand).slice(1)} •••• •••• •••• {card?.last4}
                              </span>
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  ) : null}

                  <div className="cb-terms">
                    <Checkbox
                      id="acceptTerms"
                      size="small"
                      value={bookingState.acceptTerms}
                      checked={bookingState.acceptTerms}
                      onChange={handleCheckboxChange}
                      borderStyle={{ outline: "1px solid black" }}
                      label={`${t("guest.customer.customer_booking.029")} ${bookingEngineStore.selectedCourseLongName}`}
                    />
                    <Button type="link" size="small" onClick={() => updateBookingState({ paymentTermsActive: true })}>
                      {t("guest.customer.customer_booking.031")}
                    </Button>
                  </div>
                  <div className="cb-booking-details-selection-confirm-button-group">
                    <Button className="w-100" size="medium" type="default" onClick={() => returnToHomePage(true)}>
                      {t("guest.customer.customer_booking.032")}
                    </Button>
                    <Button
                      className="w-100"
                      size="medium"
                      type="primary"
                      disabled={disableCheckout()}
                      onClick={
                        bookingEngineStore?.prepaidRequired
                          ? displayPrePaidWarning
                          : bookingState?.cart?.total_price_due
                          ? displayBookingFeeWarning
                          : handleCreateBooking
                      }
                    >
                      {bookingState?.cart?.total_price_due
                        ? t("guest.customer.customer_booking.033")
                        : t("guest.customer.customer_booking.034")}
                    </Button>
                  </div>
                </Card.Section>
              </Card>
            </div>
            <div className="cb-main-container-right-side">
              <div className="cb-order-summary">
                <Card>
                  <Card.Section>
                    <p className="cb-booking-details-selection-title">{t("guest.customer.customer_booking.035")}</p>
                    {orderSummaryObjects?.map((summaryObject, index) => {
                      return (
                        <OrderSummaryLineItem
                          key={index}
                          lineItem={summaryObject.lineItem}
                          itemValue={summaryObject.itemValue}
                        />
                      );
                    })}
                    <br />
                    {bookingState.loadPutCart || bookingState.cart === null ? (
                      <div className="h-8 mt-2 flex justify-center">
                        <Spin className="self-center" />
                      </div>
                    ) : (
                      <>
                        <p>{t("guest.customer.customer_booking.036")}</p>
                        {bookingState.cart?.line_items?.map((lineItem: Record<string, any>, index: number) => {
                          return (
                            <OrderSummaryLineItem
                              key={index}
                              lineItemQuantity={lineItem.quantity}
                              lineItem={lineItem.product_title}
                              lineItemVariant={lineItem.variant_title}
                              prepaid={lineItem.pre_paid_required}
                              itemValue={
                                <LocaleCurrency
                                  currency={bookingState.cart?.currency}
                                  amount={lineItem.subtotal_price}
                                />
                              }
                            />
                          );
                        })}
                        <br />
                        <OrderSummaryLineItem
                          lineItem={"Subtotal"}
                          itemValue={
                            <LocaleCurrency
                              currency={bookingState.cart?.currency}
                              amount={bookingState.cart?.subtotal_price}
                            />
                          }
                        />

                        {bookingState?.cart?.tax_lines?.map((taxLine: ITaxLine, index: number) => {
                          return (
                            <OrderSummaryLineItem
                              key={index}
                              lineItem={taxLine?.title ?? "Tax"}
                              itemSubtitle={taxLine?.rate ? `${taxLine?.rate * 100}%` : undefined}
                              itemValue={
                                <LocaleCurrency currency={bookingState.cart?.currency} amount={taxLine?.price} />
                              }
                            />
                          );
                        })}

                        <OrderSummaryLineItem
                          lineItem={"Total"}
                          itemValue={
                            <LocaleCurrency
                              currency={bookingState.cart?.currency}
                              amount={bookingState.cart?.total_price}
                            />
                          }
                        />
                        <br />
                        <p className="cb-order-summary-total-text">{t("guest.customer.customer_booking.037")}</p>
                        <p className="cb-order-summary-total-price">
                          <LocaleCurrency
                            currency={bookingState.cart?.currency}
                            amount={
                              bookingState?.cart?.total_price_due
                                ? bookingState.cart?.total_price - bookingState.cart?.total_price_due
                                : bookingState.cart?.total_price
                            }
                          />
                        </p>
                        {bookingState?.cart?.total_price_due ? (
                          <>
                            <br />
                            <p className="cb-order-summary-total-text">{t("guest.customer.customer_booking.038")}</p>
                            <p className="cb-order-summary-total-price">
                              <LocaleCurrency
                                currency={bookingState.cart?.currency}
                                amount={bookingState.cart?.total_price_due}
                              />
                            </p>
                          </>
                        ) : null}
                      </>
                    )}
                  </Card.Section>
                  {bookingEngineStore?.bookingTerms && (
                    <Card.Section title={t("guest.customer.customer_booking.059")}>
                      <Markdown markdownText={bookingEngineStore?.bookingTerms} />
                    </Card.Section>
                  )}
                </Card>
              </div>
            </div>
          </div>
        </div>

        {/* Add new credit card modal */}
        <Sheet
          size="small"
          height="flexible"
          open={bookingState.newCardActive}
          closable
          title={t("guest.customer.customer_booking.039")}
          cancelText={t("guest.customer.customer_booking.040")}
          okText={t("guest.customer.customer_booking.041")}
          onOk={handleAddNewCard}
          okDisabled={!elementsComplete ? true : false}
          onCancel={closeAddNewCard}
          backDropCancel={false}
        >
          <div>
            <Form>
              <FormLayout>
                <FormLayout.Group>
                  <CardSection onChange={handleCardSectionChange} type="one-row" />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Checkbox
                    id="saveCard"
                    size="small"
                    onChange={handleCheckboxChange}
                    label={t("guest.customer.customer_booking.042")}
                  />
                </FormLayout.Group>
              </FormLayout>
            </Form>
          </div>
        </Sheet>

        {/* Payment Terms Modal */}
        <Sheet
          size="small"
          height="flexible"
          open={bookingState.paymentTermsActive}
          closable
          title={t("guest.customer.customer_booking.043")}
          cancelText={t("guest.customer.customer_booking.044")}
          okText={t("guest.customer.customer_booking.045")}
          onOk={() => updateBookingState({ paymentTermsActive: false, acceptTerms: true })}
          onCancel={() => updateBookingState({ paymentTermsActive: false })}
          backDropCancel={false}
        >
          <Markdown markdownText={bookingEngineStore.paymentTerms} />
        </Sheet>

        <Sheet
          open={bookingState.addPlayerModalVisible}
          size="small"
          height="flexible"
          closable
          title={t("guest.customer.customer_booking.046")}
          onCancel={displayAddPlayerModal}
          onOk={() => handleCustomerSelection(bookingState.selectedNewPlayer?.id, bookingState.selectedNewPlayer)}
          cancelText={t("guest.customer.customer_booking.047")}
          okText={t("guest.customer.customer_booking.048")}
          okDisabled={bookingState.selectedNewPlayer ? false : true}
          overflow
        >
          {bookingState.selectedNewPlayer ? (
            <GolferCard
              closable
              removeGolfer={() => removeNewPlayerSelection()}
              name={bookingState.selectedNewPlayer?.full_name}
              email={bookingState.selectedNewPlayer?.email}
              bookingEngineCard={true}
            />
          ) : (
            <>
              <div className="cb-search-player-label-container">
                <p className="text-sm text-subdued">Search for other members using their name, email or phone number</p>
                <p className="text-sm text-subdued">Search for public players using an email or phone number</p>
              </div>
              <Select
                showSearch
                onSearch={(query: string) => handleCustomerSearch(query, "newPlayerQuery")}
                onChange={(id: string, friendObject: ICustomerFriend) => handleNewPlayerSelection(id, friendObject)}
                allowClear
                searchValue={bookingState.newPlayerQuery}
                searching={bookingState.searching}
                placeholder={"Search..."}
                noData={
                  <div className="no-players-found">
                    <FontAwesomeIcon icon={["fas", "users-slash"]} fixedWidth size="1x" />
                    <span>{t("guest.customer.customer_booking.050")}</span>
                  </div>
                }
              >
                {bookingState.customerSearchResult?.map((friend, index) => {
                  return (
                    <Option key={index} value={friend.id} extraValues={friend} name={friend?.full_name}>
                      <span>
                        {friend.full_name} - {friend?.customer_type[0]?.title}
                      </span>
                    </Option>
                  );
                })}
              </Select>
            </>
          )}
        </Sheet>

        <Popup
          open={prepaidWarning?.open}
          type="info"
          title={prepaidWarning?.title}
          description={prepaidWarning?.description}
          onCancel={() => setPrepaidWarning({ open: false, title: "", description: "" })}
          onOk={handleCreateBooking}
        />
      </>
    )
  );
}
