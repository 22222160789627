import { APIGet, APIPost, APIPut, APIDelete, StatusCode } from "api/protocols";
import { CancelToken } from "axios";
import { IGateway, IKind, IPaymentMethod, IProcessingType, ISource } from "redux/reducers/models/transaction";

const apiVersion = "2024-04";
const adminType = "facility-admin";

const baseCustomerUrl = "/" + apiVersion + "/" + adminType + "/customer";
const baseHouseAccountUrl = "/" + apiVersion + "/" + adminType + "/client";
const houseAccountPaymentUrl = "/" + apiVersion + "/" + adminType + "/client/account/transaction";

interface IGetCustomer {
  id?: number;
  search?: string;
  state?: string;
  "address-lines"?: boolean;
  "default-address-line"?: boolean;
  memberships?: boolean;
  preferences?: boolean;
  limit?: number;
  offset?: number;
  customer_type?: number[];
  cart_customer_type?: number[];
}

interface IGetCustomerAll {
  email: string;
  phone: string;
  full_name: string;
}

interface IPostCustomer {
  id?: number;
  first_name?: string;
  last_name?: string;
  phone?: string;
  email?: string;
  member_number?: number;
}

interface ICustomerRes {
  status: number;
  message?: string;
  data: any;
}

// GET Cart
// Retrieve a list of customers
export async function GetCustomer(
  params: IGetCustomer,
  useGlobalLoader?: boolean,
  cancelToken?: CancelToken,
): Promise<ICustomerRes> {
  return await APIGet(baseCustomerUrl, params, useGlobalLoader, cancelToken);
}

export async function GetCustomerAll(params: IGetCustomerAll, useGlobalLoader: boolean) {
  return await APIGet(baseCustomerUrl + "/all", params, useGlobalLoader);
}

export async function PostCustomer(params: IPostCustomer, useGlobalLoader: boolean): Promise<ICustomerRes> {
  return await APIPost(baseCustomerUrl, params, useGlobalLoader);
}

export async function PostExistingCustomer(
  params: { customer_id: number },
  useGlobalLoader: boolean,
): Promise<ICustomerRes> {
  return await APIPost(baseCustomerUrl + "/existing", params, useGlobalLoader);
}

export async function PutCustomer(params: any, useGlobalLoader: boolean): Promise<ICustomerRes> {
  return await APIPut(baseCustomerUrl, params, useGlobalLoader);
}

export async function DeleteCustomer(params: { id: number }, useGlobalLoader: boolean): Promise<ICustomerRes> {
  return await APIDelete(baseCustomerUrl, params, useGlobalLoader);
}

// Upload Customer Profile Image
export async function UploadCustomerProfileImage(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseCustomerUrl + "/profile-image", params, useGlobalLoader);
}

// Delete Customer Profile Image
export async function DeleteCustomerProfileImage(params: any, useGlobalLoader: boolean) {
  return await APIDelete(baseCustomerUrl + "/profile-image", params, useGlobalLoader);
}

//Get Customer Notes
export async function GetCustomerNotes(params: any, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseCustomerUrl + "/note", params, useGlobalLoader, token);
}

//POST Customer Notes
export async function PostCustomerNote(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseCustomerUrl + "/note", params, useGlobalLoader);
}

//Delete Customer Note
export async function DeleteCustomerNote(params: { id: number }, useGlobalLoader: boolean) {
  return await APIDelete(baseCustomerUrl + "/note", params, useGlobalLoader);
}

export async function SendPasswordReset(params: { email: string }, useGlobalLoader: boolean) {
  return await APIPost(baseCustomerUrl + "/reset", params, useGlobalLoader);
}

interface IDownloadCustomerAccountStatements {
  account_statement_id: number;
  account_id: number;
  start_date: Date;
  end_date: Date;
}

export async function DownloadCustomerAccountStatements(
  params: Partial<IDownloadCustomerAccountStatements>,
  useGlobalLoader: boolean,
) {
  return await APIGet(baseHouseAccountUrl + "/account/statement/download", params, useGlobalLoader);
}

export async function GetCustomerHouseAccounts(
  params: { customer_id: number; closed?: boolean },
  useGlobalLoader?: boolean,
  token?: CancelToken,
): Promise<ICustomerRes> {
  return await APIGet(baseHouseAccountUrl + "/account", params, useGlobalLoader, token);
}

export async function GetAccountTransactionReceipt(
  params: { account_transaction_id: number },
  useGlobalLoader: boolean,
) {
  return await APIGet(houseAccountPaymentUrl + "/receipt", params, useGlobalLoader);
}

export async function GetCustomerStatements(
  params: { id?: number; account_id?: number },
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseHouseAccountUrl + "/account/statement", params, useGlobalLoader, token);
}

export async function PutMergeCustomers(
  params: { parent_id: number; child_id: number },
  useGlobalLoader: boolean,
): Promise<ICustomerRes> {
  return await APIPut(baseCustomerUrl + "/merge", params, useGlobalLoader);
}

export async function SendWelcomeEmail(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseCustomerUrl + "/welcome", params, useGlobalLoader);
}

export interface IPutCustomerPreferences {
  customer_id: number;
  marketing_email: boolean;
  booking_confirmation_email: boolean;
  booking_reminder_email: boolean;
  booking_update_email: boolean;
  booking_cancellation_email: boolean;
}

export async function PutCustomerPreferences(params: IPutCustomerPreferences, useGlobalLoader: boolean) {
  return await APIPut(baseCustomerUrl + "/preference", params, useGlobalLoader);
}

//Customer Tags
export interface ICustomerTag {
  customer_id?: number;
  tag_id?: number;
}

export async function GetCustomerTag(params: ICustomerTag, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseCustomerUrl + "/tag", params, useGlobalLoader, token);
}
export async function PutCustomerTag(params: ICustomerTag, useGlobalLoader: boolean) {
  return await APIPut(baseCustomerUrl + "/tag", params, useGlobalLoader);
}

export async function DeleteCustomerTag(params: ICustomerTag, useGlobalLoader: boolean) {
  return await APIDelete(baseCustomerUrl + "/tag", params, useGlobalLoader);
}

interface IPostCustomerAccountTransaction {
  kind: IKind;
  amount: number;
  account_id: number;
  payment_option_id: number;
  gift_card_code?: string;
  gift_card_pin?: string;
  note?: string;
}

export interface IAccountTransaction {
  account_id: number;
  amount: number;
  authorization_id: number;
  client_id: number;
  client_secret: string;
  currency: string;
  device: string;
  error_code: string;
  facility_id: number;
  gateway: string;
  id: number;
  kind: string;
  note: string;
  order_transaction_id: number;
  parent_id: number;
  payment_intent_id: string;
  payment_last_4: string;
  payment_method: string;
  payment_type: string;
  processed_at: string;
  processing_type: string;
  source: string;
}
export interface IPostAccountTransactionResponse {
  data: IAccountTransaction;
  status: number;
}

interface ICustomerAccountTransactionCapture {
  transaction_id: number;
  payment_intent_id: string;
}

export async function PostAccountTransaction(
  params: IPostCustomerAccountTransaction,
  useGlobalLoader: boolean,
): Promise<IPostAccountTransactionResponse> {
  return await APIPost(houseAccountPaymentUrl, params, useGlobalLoader);
}

export async function PutAccountTransactionCapture(
  params: ICustomerAccountTransactionCapture,
  useGlobalLoader: boolean,
) {
  return await APIPut(houseAccountPaymentUrl + "/capture", params, useGlobalLoader);
}
