import { APIGet, APIPost, APIPut, APIDelete, IAPIResponse } from "api/protocols";
import { formatDate, capitalize } from "helpers/Helpers";

const apiVersion = "2021-01";
const adminType = "guest";
const baseCustomerUrl = "/" + apiVersion + "/customer";

export interface ICustomerFriend {
  email: string;
  first_name: string;
  full_name: string;
  id: number;
  initials: string;
  last_name: string;
  phone: string;
}

interface ICustomerFriendRes extends IAPIResponse {
  data: Array<ICustomerFriend>;
  message: string;
}

//GET Customer bookings
export async function GetCustomerBookings(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseCustomerUrl + "/tee-time/booking", params, useGlobalLoader);
}

//PUT Remove Customer Booking
export async function RemoveCustomerBooking(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseCustomerUrl + "/tee-time/booking/cancel", params, useGlobalLoader);
}

//GET Customer Credit Cards
export async function GetCustomerPaymentMethods(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseCustomerUrl + "/payment-method", params, useGlobalLoader);
}

//DELETE Customer Credit Cards
export async function DeleteCustomerCreditCard(params: any, useGlobalLoader: boolean) {
  return await APIDelete(baseCustomerUrl + "/customer/payment-method", params, useGlobalLoader);
}

//PUT Update Customer Profile
export async function UpdateCustomerProfile(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseCustomerUrl + "/customer", params, useGlobalLoader);
}

//GET Customer search
export async function SearchCustomer(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseCustomerUrl + "/customer/search", params, useGlobalLoader);
}

//GET Customer Friends
export async function GetCustomerFriends(params: any, useGlobalLoader: boolean): Promise<ICustomerFriendRes> {
  return await APIGet(baseCustomerUrl + "/customer/friend", params, useGlobalLoader);
}

//PUT Add Friend
export async function AddFriend(params: any, useGlobalLoader?: boolean) {
  return await APIPost(baseCustomerUrl + "/customer/friend", params, useGlobalLoader);
}

//POST Add Gift-Card
export async function AddGiftCard(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseCustomerUrl + "/customer/gift-card", params, useGlobalLoader);
}

export async function GetCustomer(params: { id: number }, useGlobalLoader: boolean) {
  return await APIGet(baseCustomerUrl + "/customer", params, useGlobalLoader);
}
