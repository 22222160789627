import { TFunction } from "react-i18next";

export const playerOptions = (t: TFunction, allowSingleBookings?: boolean) => [
  {
    value: "1",
    title: "1",
    disabled: allowSingleBookings ? true : false,
  },
  { value: "2", title: "2", disabled: false },
  { value: "3", title: "3", disabled: false },
  { value: "4", title: "4", disabled: false },
  { value: "any", title: "Any", disabled: false },
];

export const holeOptions = [
  { value: "9", title: "9", disabled: false },
  { value: "18", title: "18", disabled: false },
  { value: "any", title: "Any", disabled: false },
];

export const cartOptions = [
  { value: "yes", title: "Yes", disabled: false },
  { value: "no", title: "No", disabled: false },
  { value: "any", title: "Any", disabled: false },
];
