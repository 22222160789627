import { StatusCode } from "api/protocols";
import { GetReceipt, EmailReceipt } from "api/rpc/2024-04/facilityAdmin/order/order";
import { GetGiftCard, PrintGiftCard } from "api/rpc/2022-09/facilityAdmin/payment/giftCard";
import Sheet from "components/sheet/Sheet";
import React, { useEffect, useState } from "react";
import { IUIActions, showError, showSuccess } from "redux/actions/ui";
import { IOrder } from "redux/reducers/models/order";
import Input from "components/form/input/Input";
import Popup from "components/popup/Popup";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import { ButtonNew as Button } from "components/buttonNew";
import Spin from "components/spin/spin";
import ReactDOM from "react-dom";
import { GetCartWaiver } from "api/rpc/2024-04/facilityAdmin/teesheet/booking";
import { useHistory } from "react-router";
import { useAppDispatch } from "hooks/redux";
interface IProps {
  /**Boolean that determines if the action bar is displayed */
  open: boolean;
  /**Function that sets the state of the open boolean */
  setOpen: (open: boolean) => void;
  /**Current active order */
  order: Record<string, any> | IOrder;
  tee_time_id?: number;
  returnable?: boolean;
  disableDuringLoad?: boolean;
  loading?: boolean;
  transactionComplete?: boolean;
  /**Set to true to load the gift cards when the order is completed */
  teeSheetCheckIn?: boolean;
}

interface IEmailState {
  modalVisible: boolean;
  email: string;
}

export default function OrderCompletePopup(props: IProps) {
  const {
    open,
    setOpen,
    order,
    tee_time_id,
    returnable,
    disableDuringLoad,
    loading,
    transactionComplete,
    teeSheetCheckIn,
  } = props;
  const dispatch = useAppDispatch();

  const history = useHistory();
  const [giftCards, setGiftCards] = useState<Array<Record<string, any>>>([]);
  const [giftCardsLoading, setGiftCardsLoading] = useState<boolean>(false);
  const [emailState, setEmailState] = useState<IEmailState>({
    modalVisible: false,
    email: "",
  });

  useEffect(() => {
    if (open && order) {
      const giftCardInOrder = order?.line_items?.some(
        (item: Record<string, any>) => item?.product_type === "Gift Card",
      );
      const powerCartInOrder = order?.line_items?.some(
        (item: Record<string, any>) => item?.product_type === "Power Cart",
      );
      const register = JSON.parse(localStorage.getItem("register"));
      if (giftCardInOrder) {
        void handleSetGiftCardIds();
      }
      if (powerCartInOrder && register.print_by_default) {
        void printCartWaiver();
      }
    } else {
      setGiftCards([]);
    }
  }, [open]);

  useEffect(() => {
    if (teeSheetCheckIn && order && order?.financial_status === "paid") {
      if (order?.line_items?.some((item: Record<string, any>) => item?.product_type === "Gift Card")) {
        void handleSetGiftCardIds();
      }
    }
  }, [order]);

  async function handleSetGiftCardIds() {
    setGiftCardsLoading(true);
    const giftCardRes = await GetGiftCard({ order_id: order?.id }, false);
    if (giftCardRes.status !== StatusCode.OK) {
      dispatch(showError("Error getting gift cards"));
      setGiftCardsLoading(false);
      return;
    }
    ReactDOM.unstable_batchedUpdates(() => {
      setGiftCardsLoading(false);
      setGiftCards(giftCardRes?.data);
    });
  }

  function closeActionBar() {
    setOpen(false);
  }

  async function printReceipt() {
    if (order) {
      const receiptRes = await GetReceipt({ id: order?.id, tee_time_id }, true);
      if (receiptRes.status !== StatusCode.OK) {
        dispatch(showError("Error printing receipt"));
        return;
      }
      window.open().document.write(receiptRes.data);
    }
  }

  async function printCartWaiver() {
    const waiverRes = await GetCartWaiver({ tee_time_id: tee_time_id, customer_id: order?.customer?.id }, true);

    if (waiverRes.status === StatusCode.OK) {
      window.open().document.write(waiverRes.data);
    } else {
      dispatch(showError("Cart waiver has not been created"));
    }
  }

  async function emailReceipt() {
    const emailReceiptRes = await EmailReceipt({ order_id: order?.id, customer_email: emailState?.email }, true);
    if (emailReceiptRes?.status !== StatusCode.OK) {
      dispatch(showError("Error sending email receipt"));
      return;
    }
    setEmailState(prevState => ({ ...prevState, modalVisible: false, email: "" }));
    dispatch(showSuccess("Email receipt sent successfully"));
  }

  async function printGiftCardReceipts() {
    if (order) {
      for (const giftCard of giftCards) {
        const receiptRes = await PrintGiftCard({ id: giftCard?.id, last4: giftCard?.last4 }, true);
        if (receiptRes.status !== StatusCode.OK) {
          dispatch(showError("Error printing gift card receipt"));
          return;
        }
        window.open().document.write(receiptRes.data);
      }
    }
  }

  function handleEmailInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setEmailState(prevState => ({ ...prevState, [id]: value }));
  }

  function returnToTeeSheet() {
    history.push("/admin/teesheet");
  }

  return (
    <>
      <Popup
        type={loading === true ? "info" : "success"}
        open={open}
        title={order ? `Order ${order?.name as string}` : "Order is being created"}
        description={loading === true ? "Order is processing" : "Order has been completed"}
        closable={loading === true ? false : true}
        onOk={closeActionBar}
        okDisabled={loading === true ? true : false}
        okText="Close"
        backDropCancel={false}
        loading={loading}
      >
        {/* {loading === true ? (
          <div style={{ marginTop: "10px", height: "24px", overflow: "hidden" }}>
            <Spin />
            <div className="mt-2">{props.loadingText}</div>
          </div>
        ) : ( */}
        <div className="mt-4">
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Button block size="medium" onClick={printReceipt} disabled={disableDuringLoad && !transactionComplete}>
                  Print Receipt
                </Button>
                <Button
                  block
                  size="medium"
                  onClick={() =>
                    setEmailState(prevState => ({
                      ...prevState,
                      modalVisible: true,
                      email: order?.customer?.email ? order?.customer?.email : "",
                    }))
                  }
                  disabled={disableDuringLoad && !transactionComplete}
                >
                  Email Receipt
                </Button>
              </FormLayout.Group>
              {tee_time_id && (
                <FormLayout.Group>
                  <Button block size="medium" onClick={printCartWaiver}>
                    Print Cart Waiver
                  </Button>
                </FormLayout.Group>
              )}
              {giftCardsLoading ? (
                <FormLayout.Group>
                  <div className="h-8">
                    <Spin className="self-center" />
                  </div>
                  <Button block size="medium" onClick={closeActionBar} disabled={disableDuringLoad && loading}>
                    No Receipt
                  </Button>
                </FormLayout.Group>
              ) : giftCards?.length > 0 ? (
                <FormLayout.Group>
                  <Button block size="medium" onClick={printGiftCardReceipts}>
                    Print Gift Cards
                  </Button>
                  <Button block size="medium" onClick={closeActionBar} disabled={disableDuringLoad && loading}>
                    No Receipt
                  </Button>
                </FormLayout.Group>
              ) : (
                <FormLayout.Group>
                  <Button block size="medium" onClick={closeActionBar} disabled={disableDuringLoad && loading}>
                    No Receipt
                  </Button>
                </FormLayout.Group>
              )}
              {returnable && (
                <FormLayout.Group>
                  <Button block size="medium" onClick={returnToTeeSheet} disabled={loading}>
                    Return to Tee Sheet
                  </Button>
                </FormLayout.Group>
              )}
            </FormLayout>
          </Form>
        </div>
        {/* )} */}
      </Popup>

      <Sheet
        title="Enter Email"
        open={emailState.modalVisible}
        size="small"
        stacked
        closable
        onCancel={() => setEmailState(prevState => ({ ...prevState, modalVisible: false, email: "" }))}
        onOk={emailReceipt}
        okText="Send Email"
        okDisabled={!emailState.email ? true : false}
      >
        <Input placeholder="Email" value={emailState.email} id="email" onChange={handleEmailInputChange} />
      </Sheet>
    </>
  );
}
