import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as OnlineStoreActions from "redux/actions/customer/onlineStore";
import NavigationBar from "pages/guest/OnlineStore/StoreHeader/NavigationBar";

const mapStateToProps = (state: any) => {
  return {
    onlineStoreStore: { ...state.onlineStoreStore },
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onlineStoreActions: bindActionCreators(
      {
        ...OnlineStoreActions,
      },
      dispatch,
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
