import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StatusCode } from "api/protocols";
import { UserActive, UserLogout } from "api/rpc";
import { GetFacility } from "api/rpc/guest/facility";
import {
  GetReservationBooking,
  GetReservationLocation,
  GetReservationModule,
  GetReservationSegment,
} from "api/rpc/guest/reservations/reservations";
import Card from "components/card/Card";
import Radio from "components/radio";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { IReservationActions } from "redux/actions/reservations";
import { IUIActions } from "redux/actions/ui";
import { IReservationState as IReservationStore } from "redux/reducers/reservations";
import "./reservationConfirmation.scss";
import { ReservationsNavigation } from "./ReservationsNavigation/ReservationsNavigation";
import { ButtonNew as Button } from "components/buttonNew";
import { ISegment } from "redux/reducers/models/reservations";
import ReactDOM from "react-dom";
import { LocaleCurrency } from "helpers/Locale";

interface IProps {
  uiActions: IUIActions;
  reservationStore: IReservationStore;
  reservationActions: IReservationActions;
}

interface IParams {
  facilityShortName: string;
  moduleHandle: string;
}

interface IConfirmationState {
  guestsAmount: number;
  segmentIds: Array<number>;
  durationKey: string;
  segments: Array<ISegment>;
  durations: { [key: string]: Array<number> };
}

export default function ReservationConfirmation(props: IProps) {
  const { uiActions, reservationStore, reservationActions } = props;
  const { facilityShortName, moduleHandle } = useParams<IParams>();
  const [activeUser, setActiveUser] = useState<boolean>(false);

  const history = useHistory();

  const queryString = window.location.search;

  const locationId = Number(new URLSearchParams(queryString).get("location_id"));
  const date = new URLSearchParams(queryString).get("date");
  const startTime = new URLSearchParams(queryString).get("start_time");
  const token = new URLSearchParams(queryString).get("token");

  useEffect(() => {
    void getCustomerLoggedIn();
  }, []);

  useEffect(() => {
    //Page was refreshed, get module & location
    if (
      reservationStore?.active_user !== null &&
      (reservationStore?.module === null || reservationStore?.selectedLocation === null)
    ) {
      void getBooking();
    }
  }, [reservationStore?.active_user]);

  async function getCustomerLoggedIn() {
    if (!reservationStore.active_user) {
      uiActions.enqueue();
      const activeUserRes = await UserActive(false);
      uiActions.dequeue();
      if (activeUserRes.status === StatusCode.OK) {
        reservationActions.update({ active_user: activeUserRes?.data });
        setActiveUser(true);
      } else {
        //User is not logged in, send them back to the reservations page
        setActiveUser(false);
        uiActions.showError("User is not logged in. Please log in and try again.");
        void returnToReservationsPage();
      }
    } else {
      setActiveUser(true);
    }
  }

  function returnToReservationsPage() {
    reservationActions.update({ selectedLocation: null, selectedSegment: null, booking: null });
    history.push(`/${facilityShortName}/${moduleHandle}`);
  }

  async function getBooking() {
    uiActions.enqueue();

    const bookingRes = await GetReservationBooking({ token: token }, false);
    if (bookingRes?.status !== StatusCode.OK) {
      uiActions.showError("Error getting booking");
      uiActions.dequeue();
      return;
    }

    const moduleRes = await GetReservationModule(
      { facility_short_name: facilityShortName, handle: moduleHandle },
      false,
    );
    if (moduleRes.status !== StatusCode.OK) {
      uiActions.showError("Error getting module");
      uiActions.dequeue();
      return;
    }
    const facilityRes = await GetFacility({ id: moduleRes?.data?.facility_id }, false);
    if (facilityRes?.status !== StatusCode.OK) {
      uiActions.showError("Error getting facility");
      uiActions.dequeue();
      return;
    }
    const locationRes = await GetReservationLocation(
      { id: locationId, facility_id: moduleRes?.data?.facility_id },
      false,
    );
    if (locationRes.status !== StatusCode.OK) {
      uiActions.showError("Error getting location");
      uiActions.dequeue();
      return;
    }

    uiActions.dequeue();

    ReactDOM.unstable_batchedUpdates(() => {
      reservationActions.update({
        module: moduleRes?.data,
        selectedLocation: locationRes?.data[0],
        facility: facilityRes?.data[0],
        booking: bookingRes?.data[0],
      });
    });
  }

  return (
    <>
      <div className="reservation-confirmation">
        <ReservationsNavigation
          uiActions={uiActions}
          activeUser={activeUser}
          userFirstName={reservationStore?.active_user?.first_name}
        />
        <div className="reservation-confirmation-facility-container">
          <Card>
            <Card.Section>
              <div className="reservation-confirmation-flex-row">
                <span className="facility-logo">
                  {reservationStore?.facility?.logo_source && (
                    <img className="facility-logo" src={reservationStore?.facility?.logo_source} alt="Facility Logo" />
                  )}
                </span>
                {reservationStore?.facility && (
                  <div className="reservation-booking-facility-container-group">
                    <p className="facility-name">{reservationStore?.facility?.long_name}</p>
                    <p className="facility-address">
                      {reservationStore?.facility?.address_line_1}, {reservationStore?.facility?.city},{" "}
                      {reservationStore?.facility?.province_name}, {reservationStore?.facility?.postal}
                    </p>
                  </div>
                )}
              </div>
            </Card.Section>
          </Card>
        </div>

        <div className="reservation-confirmation-main-container">
          <Card>
            <Card.Section>
              <div className="reservation-confirmation-flex-column">
                <p className="reservation-confirmation-booked-text reservation-confirmation-booked-title-text-responsive">
                  Your reservation has been booked.
                </p>
                <p className="reservation-confirmation-booked-text-subtext reservation-confirmation-booked-sub-text-responsive">
                  We&apos;ll send you a confirmation and receipt to {reservationStore?.booking?.customer?.email} shortly
                </p>
                <div className="reservation-confirmation-booked-confirmation-container">
                  <p className="reservation-confirmation-booked-text-confirmation reservation-confirmation-booked-sub-text-responsive">
                    Confirmation Number:{" "}
                  </p>
                  <p className="reservation-confirmation-booked-text-confirmation-number reservation-confirmation-booked-sub-text-responsive">
                    {reservationStore?.booking?.confirmation_number}
                  </p>
                </div>
              </div>
            </Card.Section>
          </Card>

          <Card>
            <Card.Section>
              <p className="reservation-confirmation-booking-details-card-title">Booking Details</p>
              <div className="reservation-confirmation-booking-details-content">
                <div className="reservation-confirmation-booking-details-container">
                  <p className="reservation-confirmation-booking-details-title reservation-confirmation-booked-title-text-responsive">
                    Booked By
                  </p>
                  <div className="reservation-confirmation-booking-details-guest-details reservation-confirmation-flex-column reservation-confirmation-flex-grow">
                    <p className="mb-1 reservation-confirmation-booked-sub-text-responsive">
                      {reservationStore?.booking?.first_name} {reservationStore?.booking?.last_name}
                    </p>
                    <p className="reservation-confirmation-booked-sub-text-responsive">
                      {reservationStore?.booking?.customer?.email}
                    </p>
                    <p className="reservation-confirmation-booked-sub-text-responsive">
                      {reservationStore?.booking?.customer?.phone}
                    </p>
                  </div>
                </div>

                <div className="reservation-confirmation-booking-details-container">
                  <p className="reservation-confirmation-booking-details-title reservation-confirmation-booked-title-text-responsive">
                    Order Summary
                  </p>
                  <div className="reservation-confirmation-booking-details-order-summary reservation-confirmation-flex-column reservation-confirmation-flex-grow">
                    <p className="font-bold reservation-confirmation-booked-sub-text-responsive">
                      {reservationStore?.facility?.long_name}
                    </p>
                    <p className="reservation-confirmation-booking-details-order-summary-date-and-time reservation-confirmation-booked-sub-text-responsive">
                      {moment(date).format("dddd")} {moment(date).format("LL")} •{" "}
                      {moment(startTime, "hh:mm:ss").format("h:mm A")}
                    </p>
                    <p className="font-semibold reservation-confirmation-booked-sub-text-responsive">
                      {reservationStore?.selectedLocation?.title} • {reservationStore?.booking?.quantity} Guest
                      {reservationStore?.booking?.quantity > 1 ? "s" : ""}
                    </p>
                    {reservationStore?.bookedSegments && (
                      <table className="reservation-confirmation-table">
                        <thead>
                          <tr className="reservation-confirmation-table-header">
                            <td>Time</td>
                            <td>Price</td>
                          </tr>
                        </thead>
                        <tbody>
                          {reservationStore?.bookedSegments?.map((segment, segmentIndex) => {
                            if (segmentIndex % 2 === 0) {
                              return (
                                <tr key={segmentIndex}>
                                  <td>{moment(segment?.start_time, "hh:mm:ss").format("h:mm A")}</td>
                                  <td>
                                    {segment?.blocks[0]?.variant_price && (
                                      <LocaleCurrency amount={segment?.blocks[0]?.variant_price} />
                                    )}
                                  </td>
                                </tr>
                              );
                            }
                          })}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>

              <div className="reservation-confirmation-flex-row reservation-confirmation-confirm-button-group">
                <Button size="medium" type="primary" onClick={returnToReservationsPage}>
                  BACK TO RESERVATIONS
                </Button>
              </div>
            </Card.Section>
          </Card>
        </div>
      </div>
    </>
  );
}
