import { CancelToken } from "axios";
import { APIDelete, APIGet, APIPost, APIPut } from "api/protocols";
import { CustomerTypeResStructure } from "api/rpc/2022-09/facilityAdmin/customer/type";
import { IProduct, IVariant } from "redux/reducers/models/product";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/tee-sheet/booking-fee";

export type TeesheetBookingFee = {
  id: number;
  facility_id?: number;
  booking_engine_id?: number;
  booking_window_id: number | null;
  product_id?: number;
  variant_id?: number;
  product?: IProduct;
  product_title?: string;
  variant?: IVariant;
  variant_title?: string;
  price: number;
  taxable?: boolean;
  unit: "player" | "booking";
  non_refundable: boolean;
  days_in_advance_start: string;
  days_in_advance_end: string;
  customer_types?: CustomerTypeResStructure[]; // Retrieved with extended param
};

export interface ITeesheetBookingFeeRes<T> {
  status: number;
  data: T;
}

type TPostRes = TeesheetBookingFee;
type TGetRes = TeesheetBookingFee[];

export interface IPostTeesheetBookingFee {
  variant_id: number;
  non_refundable: boolean;
  days_in_advance_start: number;
  days_in_advance_end: number;
}

export interface IPutTeesheetBookingFee {
  id: number;
  variant_id: number;
  unit?: "player" | "booking";
  non_refundable: boolean;
  days_in_advance_start: number;
  days_in_advance_end: number;
}

export async function GetTeesheetBookingFee(
  params: { id?: number; extended?: boolean },
  useGlobalLoader: boolean,
  token?: CancelToken,
): Promise<ITeesheetBookingFeeRes<TGetRes>> {
  return await APIGet(baseUrl, params, useGlobalLoader, token);
}
export async function PostTeesheetBookingFee(
  params: IPostTeesheetBookingFee,
  useGlobalLoader: boolean,
): Promise<ITeesheetBookingFeeRes<TPostRes>> {
  return await APIPost(baseUrl, params, useGlobalLoader);
}
export async function PutTeesheetBookingFee(
  params: IPutTeesheetBookingFee,
  useGlobalLoader: boolean,
): Promise<ITeesheetBookingFeeRes<TGetRes | string>> {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

export async function DeleteTeesheetBookingFee(
  params: { id: number },
  useGlobalLoader: boolean,
): Promise<ITeesheetBookingFeeRes<string>> {
  return await APIDelete(baseUrl, params, useGlobalLoader);
}

export async function PutBookingFeeCustomerType(
  params: { booking_fee_id: number; customer_type_ids: Array<number> },
  useGlobalLoader: boolean,
): Promise<ITeesheetBookingFeeRes<any>> {
  return await APIPut(baseUrl + "/customer-type", params, useGlobalLoader);
}
