import { APIGet, APIPost, APIPut } from "api/protocols";
import { CancelToken } from "axios";
import { TKitchenLocation } from "redux/reducers/models/kitchen";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/facility/kitchen";

export type TGetKitchenLocation = {
  id?: number;
};

export type TPostKitchenLocation = {
  facility_id?: number;
  title: string;
  type?: string; //default: "kitchen"
  printer_id?: number;
};

export type TPutKitchenLocation = {
  id: number;
  tite?: string;
  type?: string;
  printer_id?: number;
};

export async function GetKitchenLocations(params: TGetKitchenLocation, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<TKitchenLocation | TKitchenLocation[]>(baseUrl + "/location", params, useGlobalLoader, token);
}

export async function PostKitchenLocation(params: TPostKitchenLocation, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/location", params, useGlobalLoader);
}
