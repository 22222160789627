import { APIGet, APIPost, APIPut, APIDelete, IAPIResponse } from "api/protocols";
import { CancelToken } from "axios";
import { ITournament } from "redux/reducers/models/tournament";

const apiVersion = "2024-04";
const adminType = "guest";
const baseTournamentUrl = "/" + apiVersion + "/" + adminType + "/tournament";

interface IGetTournament {
  id?: number;
  facility_id?: number;
  handle?: string;
  year?: number;
  offset?: number;
  limit?: number;
  search?: string;
}

export async function GetGuestTournament(
  params: IGetTournament,
  useGlobalLoader: boolean,
  token?: CancelToken,
): Promise<IAPIResponse<Array<ITournament>>> {
  return await APIGet(baseTournamentUrl, params, useGlobalLoader, token);
}
