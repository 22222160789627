import { cloneDeep, isEqualWith, isNull } from "lodash";
import { useReducer } from "react";

export interface IUnsavedChangesContainer<T> {
  updatedState: T;
  originalState: T;
  changesExist: boolean;
}

interface ISetAction<T> {
  type: "SET";
  payload: T;
}

interface IUpdateAction<T> {
  type: "UPDATE";
  payload: T;
}

interface IRollbackAction {
  type: "ROLLBACK";
}

interface ISaveAction {
  type: "SAVE";
}

export interface IUnsavedChangesDispatchActions<T> {
  set: (state: T) => void;
  update: (updatedState: T) => void;
  rollback: () => void;
  save: () => void;
}

type UnsavedChangesContainerAction<T> = ISetAction<T> | IUpdateAction<T> | IRollbackAction | ISaveAction;

export default function useUnsavedChangesContainer<T>(): [
  IUnsavedChangesContainer<T>,
  IUnsavedChangesDispatchActions<T>,
] {
  function reducer(
    state: IUnsavedChangesContainer<T>,
    action: UnsavedChangesContainerAction<T>,
  ): IUnsavedChangesContainer<T> {
    switch (action.type) {
      case "SET":
        return {
          ...state,
          updatedState: cloneDeep(action.payload),
          originalState: cloneDeep(action.payload),
          changesExist: false,
        };
      case "UPDATE": {
        return {
          ...state,
          updatedState: action.payload,
          changesExist: !isEqualWith(action.payload, state.originalState, (originalValue, newValue) => {
            if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
              return true;
            }
          }),
        };
      }
      case "ROLLBACK":
        return {
          ...state,
          updatedState: cloneDeep(state.originalState),
          changesExist: false,
        };
      case "SAVE":
        return {
          ...state,
          originalState: cloneDeep(state.updatedState),
          changesExist: false,
        };
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    updatedState: undefined,
    originalState: undefined,
    changesExist: false,
  });

  function set(state: T) {
    dispatch({
      type: "SET",
      payload: state,
    });
  }

  function update(state: T) {
    dispatch({
      type: "UPDATE",
      payload: state,
    });
  }

  function rollback() {
    dispatch({ type: "ROLLBACK" });
  }

  function save() {
    dispatch({ type: "SAVE" });
  }

  const actions: IUnsavedChangesDispatchActions<T> = {
    set,
    update,
    rollback,
    save,
  };

  return [state, actions];
}
