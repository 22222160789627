import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router";

import { StatusCode } from "api/protocols";
import {
  DeleteTeesheetBookingFee,
  GetTeesheetBookingFee,
  IPostTeesheetBookingFee,
  PostTeesheetBookingFee,
  TeesheetBookingFee,
} from "api/rpc/2024-04/facilityAdmin/teesheet/bookingFee";
import { GetProduct } from "api/rpc/2022-09/facilityAdmin/product/product";

import { showError } from "redux/actions/ui";

import { useAppDispatch } from "hooks/redux";
import { handleChangeEventInput, isEmpty } from "helpers/Helpers";
import { LocaleCurrency } from "helpers/Locale";
import useModal from "hooks/modals/useModal";

import BookingFeeProductsModal, { TProductModal } from "./BookingFeeProductsModal";
import Page from "components/page/Page";
import Sheet from "components/sheet/Sheet";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import Checkbox from "components/form/checkbox/Checkbox";
import { ButtonNew } from "components/buttonNew";
import { Badge } from "components/badge/Badge";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import Portal from "elements/Portal";

export default function BookingFees() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [teesheetBookingFees, setTeesheetBookingFees] = useState<TeesheetBookingFee[]>(undefined);
  const [bookingFeeNew, setBookingFeeNew] = useState<Partial<TeesheetBookingFee> & { open: boolean }>({
    open: false,
    days_in_advance_start: "",
    days_in_advance_end: "",
    non_refundable: false,
    product: null,
    variant: null,
  });

  const { state: productModal, updateModal } = useModal<TProductModal>({
    search: "",
    products: [],
    isLoading: false,
    selectedVariant: null,
    selectedProduct: null,
    clearOnClose: false,
  });

  const [bookingFeeDelete, setBookingFeeDelete] = useState({
    open: false,
    id: null,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadTeesheetBookingFees(source.token);
    return () => {
      source.cancel();
    };
  }, []);

  // Debounce from <Search /> component
  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadProducts(source.token);
    return () => {
      source.cancel();
    };
  }, [productModal.search]);

  async function loadTeesheetBookingFees(token?: CancelToken) {
    if (teesheetBookingFees) {
      setTeesheetBookingFees(undefined);
    }

    const res = await GetTeesheetBookingFee(null, token ? false : true, token);
    console.log(res);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      setTeesheetBookingFees([]);
      return;
    }

    setTeesheetBookingFees(res.data);
  }

  async function loadProducts(token?: CancelToken) {
    if (!productModal.isLoading) {
      updateModal({ isLoading: true });
    }
    const res = await GetProduct(
      { type: "Booking Fee", search: productModal.search, extended_variants: true },
      token ? false : true,
      token,
    );
    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.tee_sheets.booking_engine.042")));
      updateModal({ isLoading: false });
      return;
    }

    updateModal({ products: res.data, isLoading: false });
  }

  async function saveTeesheetBookingFee() {
    const params: IPostTeesheetBookingFee = {
      variant_id: bookingFeeNew.variant?.id,
      non_refundable: bookingFeeNew.non_refundable,
      days_in_advance_start: parseInt(bookingFeeNew.days_in_advance_start),
      days_in_advance_end: parseInt(bookingFeeNew.days_in_advance_end),
    };

    const res = await PostTeesheetBookingFee(params, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Something went wrong.  Unable to save new booking fee."));
      return;
    }

    // Update the state with the new item
    setTeesheetBookingFees([...teesheetBookingFees, res.data]);
    closeBookingFeeNewModal();
  }

  // async function handleRemoveBookingFee() {
  //   const removeRes = await DeleteTeesheetBookingFee({ id: bookingFeeDelete.id }, true);

  //   if (removeRes.status !== StatusCode.OK) {
  //     dispatch(showError("Error deleting booking fee"));
  //     return;
  //   }
  //   dispatch(showSuccess("Successfully deleted booking fee"));

  //   // update visual state
  //   setTeesheetBookingFees(teesheetBookingFees.filter(fee => fee.product_id === bookingFeeDelete.id));
  //   setBookingFeeDelete({ open: false, id: null });
  // }

  function closeBookingFeeNewModal() {
    setBookingFeeNew(prev => ({
      ...prev,
      open: false,
      days_in_advance_start: "",
      days_in_advance_end: "",
      non_refundable: false,
      product: null,
      variant: null,
    }));

    updateModal({ isOpen: false, selectedProduct: null, selectedVariant: null });
  }

  const primaryAction = {
    content: "New Booking Fee", // TODO: Translation
    action: () => setBookingFeeNew(prev => ({ ...prev, open: true })),
  };

  return (
    <>
      <Page
        title={"Booking Fees"} // TODO: Translation
        narrow
        primaryAction={primaryAction}
      >
        <DataTable
          columns={[
            { label: "Booking Fee" },
            // { label: "Customer Types" },
            { label: "Price / Unit" },
            { label: t("secure.facility.settings.tee_sheets.booking_engines.004") },
            { label: "Fees Type" },
            // { label: "" }, // Delete
          ]}
          loading={teesheetBookingFees === undefined}
        >
          {teesheetBookingFees &&
            teesheetBookingFees.map(fee => (
              <tr
                key={fee.id}
                className="clickable"
                onClick={() => history.push("/admin/settings/tee-sheet/booking-fee/" + String(fee.id))}
              >
                <td>{fee?.product_title}</td>
                {/* <td> 
                    {fee?.customer_types?.map(type => ( 
                      <p style={{ fontSize: "14px" }} key={type.id}>{capitalize(type.full_title)}</p>
                    ))}
                  </td> */}
                <td>
                  <LocaleCurrency amount={fee.price} /> /{fee.unit}
                </td>
                <td>
                  {fee.days_in_advance_start} {t("secure.facility.settings.tee_sheets.booking_engines.007")} -{" "}
                  {fee.days_in_advance_end} {t("secure.facility.settings.tee_sheets.booking_engines.008")}
                </td>
                {!isEmpty(fee.non_refundable) ? (
                  <td>{fee.non_refundable ? "Refundable" : "Non-Refundable"}</td>
                ) : (
                  <td>
                    <p>N/A</p>
                  </td>
                )}
              </tr>
            ))}
        </DataTable>
      </Page>

      <Portal isMounted={bookingFeeNew.open}>
        <Sheet
          open={bookingFeeNew.open}
          onCancel={closeBookingFeeNewModal}
          title="New Booking Fee"
          secondaryText="Booking window"
          closable
          onOk={saveTeesheetBookingFee}
          size="small"
          okText="Create" //TODO: Translation
          okDisabled={
            isEmpty(bookingFeeNew.days_in_advance_start) ||
            isEmpty(bookingFeeNew.days_in_advance_end) ||
            isEmpty(bookingFeeNew.variant)
          }
        >
          <FormLayout>
            <FormLayout.Group>
              <Input
                id="days_in_advance_start"
                value={bookingFeeNew.days_in_advance_start}
                onChange={e => handleChangeEventInput(e, bookingFeeNew, setBookingFeeNew)}
                label={t("secure.facility.settings.tee_sheets.booking_engine.025")}
                helpText={t("secure.facility.settings.tee_sheets.booking_engine.026")}
                type="number"
                suffix="days"
                placeholder={t("secure.facility.settings.tee_sheets.booking_engine.027")}
              />
              <Input
                id="days_in_advance_end"
                value={bookingFeeNew.days_in_advance_end}
                onChange={e => handleChangeEventInput(e, bookingFeeNew, setBookingFeeNew)}
                label={t("secure.facility.settings.tee_sheets.booking_engine.028")}
                helpText={t("secure.facility.settings.tee_sheets.booking_engine.029")}
                type="number"
                suffix="days"
                placeholder={t("secure.facility.settings.tee_sheets.booking_engine.030")}
              />
            </FormLayout.Group>
          </FormLayout>
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center gap-8">
              <ButtonNew size="medium" type="secondary" onClick={() => updateModal({ isOpen: true })}>
                Select Booking Fee
                {/* {t("secure.facility.settings.tee_sheets.booking_engine.052")} */}
              </ButtonNew>
              {bookingFeeNew.variant && (
                <div className="booking-engine-variant-badge-container">
                  <Badge type="gray" size="medium">
                    <span>
                      {bookingFeeNew.product?.preferred_title
                        ? bookingFeeNew.product?.preferred_title
                        : bookingFeeNew.product?.title}{" "}
                      {bookingFeeNew.variant?.title !== bookingFeeNew.variant?.title
                        ? ` - ${bookingFeeNew.variant?.title}`
                        : ""}{" "}
                      <LocaleCurrency amount={bookingFeeNew.variant?.price} />
                    </span>
                  </Badge>
                  <FontAwesomeIcon
                    size="1x"
                    icon={["far", "xmark"]}
                    onClick={() => setBookingFeeNew(prev => ({ ...prev, product: null, variant: null }))}
                    className="booking-engine-remove-variant"
                  />
                </div>
              )}
            </div>
            <Checkbox
              id="non_refundable"
              checked={bookingFeeNew.non_refundable}
              onChange={e => handleChangeEventInput(e, bookingFeeNew, setBookingFeeNew)}
              size="medium"
              label={t("secure.facility.settings.tee_sheets.booking_engine.053")}
            />
          </div>
        </Sheet>
      </Portal>

      <BookingFeeProductsModal
        state={productModal}
        updateState={updateModal}
        defaultProduct={bookingFeeNew.product}
        defaultVariant={bookingFeeNew.variant}
        onOk={(product, variant) => setBookingFeeNew(prev => ({ ...prev, product: product, variant: variant }))}
        navigateToNewProduct={() => history.push("/admin/product/new")}
      />
    </>
  );
}
