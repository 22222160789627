import React, { useEffect, useState, useCallback, SetStateAction } from "react";
import { useHistory, Link } from "react-router-dom";
import { useParams } from "react-router";
import moment from "moment";
import { isEqualWith, isNull } from "lodash";

import { GetDivisions, GetTeeDecks } from "api/rpc/facility";
import { GetScoresheet, PutScoresheet } from "api/rpc/2022-09/facilityAdmin/league/scoresheet";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";

import { Select } from "components/select/";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import { ILeague } from "redux/reducers/models/league";
import "./LeagueScoresheet.scss";
import { IUIActions } from "redux/actions/ui";

interface ILeagueScoresheetState {
  id: number;
  league_id: number;
  league: ILeague;
  name: string;
  holes: number;
  date_played: string;
  course_id: number;
  division_front_id: number;
  division_back_id: number;
  tee_deck_id: number;
}

interface IFilterState {
  divisions: any;
  teeDecks: any;
  filter_date: Date;
  deleteScoreSheet: boolean;
}

interface IProps {
  uiActions: IUIActions;
}

export default function LeagueScoresheetEdit(props: IProps) {
  const history = useHistory();
  const { leagueId }: any = useParams();
  const { scoresheetId }: any = useParams();

  const { uiActions } = props;

  const { Option } = Select;

  const [leagueScoreStateBeforeChanges, setLeagueScoresheetStateBeforeChanges] =
    useState<ILeagueScoresheetState>(undefined);
  const [leagueScoreLoaded, setLeagueScoresheetLoaded] = useState<boolean>(false);

  const [leagueScoresheetState, setLeagueScoresheetState] = useState<ILeagueScoresheetState>({
    id: scoresheetId,
    league_id: leagueId,
    league: null,
    name: "",
    holes: null,
    date_played: "",
    course_id: null,
    division_front_id: null,
    division_back_id: null,
    tee_deck_id: null,
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    divisions: [],
    teeDecks: [],
    filter_date: null,
    deleteScoreSheet: false,
  });

  useEffect(() => {
    void loadLeague();
    void loadTeeDecks();
    void loadDivisions();
  }, []);

  async function loadLeague() {
    const params = {
      id: scoresheetId,
    };

    const leagueRes = await GetScoresheet(params, true);

    if (leagueRes.status === StatusCode.OK) {
      const tempDateArray = leagueRes.data[0].date_played.split("-");
      const tempDateString = String(tempDateArray[1]) + "-" + String(tempDateArray[2]) + "-" + String(tempDateArray[0]);

      const currentDatePlayed = new Date(tempDateString);

      setLeagueScoresheetState(prevState => ({
        ...prevState,
        league: leagueRes.data[0],
        name: leagueRes.data[0].name,
        date_played: leagueRes.data[0].date_played,
        holes: leagueRes.data[0].holes,
        division_front_id: leagueRes.data[0].division_front_id,
        division_back_id: leagueRes.data[0].division_back_id,
        course_id: leagueRes.data[0].course_id,
        tee_deck_id: leagueRes.data[0].tee_deck_id,
      }));

      setFilterState(prevState => ({ ...prevState, filter_date: currentDatePlayed }));
    }
  }

  async function loadTeeDecks() {
    const teeDeckRes = await GetTeeDecks(null, true);

    if (teeDeckRes.status === StatusCode.OK) {
      setFilterState(prevState => ({ ...prevState, teeDecks: teeDeckRes.data }));
    }
  }

  async function loadDivisions() {
    const divisionRes = await GetDivisions(null, true);

    if (divisionRes.status === StatusCode.OK) {
      setFilterState(prevState => ({ ...prevState, divisions: divisionRes.data }));
    }
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setLeagueScoresheetState(prevState => ({ ...prevState, [id]: value }));
  }

  function unsavedChangesExist() {
    if (leagueScoreStateBeforeChanges === undefined) {
      if (leagueScoreLoaded) {
        setLeagueScoresheetStateBeforeChanges(leagueScoresheetState);
      }
      return false;
    }

    return !isEqualWith(leagueScoreStateBeforeChanges, leagueScoresheetState, (originalValue, newValue) => {
      if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
        return true;
      }
    });
  }

  function cancelUnsavedChanges() {
    setLeagueScoresheetState(leagueScoreStateBeforeChanges);
  }

  const handleDateSelectorChange = (selectedDate: any) => {
    const date_string = moment(selectedDate).format("YYYY-MM-DD");

    setFilterState(prevState => ({ ...prevState, filter_date: selectedDate }));

    setLeagueScoresheetState(prevState => ({
      ...prevState,
      date_played: date_string,
    }));
  };

  function handleDropDownChange(value: any, property: string) {
    setLeagueScoresheetState(prev => ({ ...prev, [property]: value }));
  }

  async function updateLeagueScoresheet() {
    const updateScoresheetRes = await PutScoresheet(leagueScoresheetState, true);

    if (updateScoresheetRes.status !== StatusCode.OK) {
      uiActions.showError("Error saving scoresheet");
      return;
    }

    uiActions.showSuccess("Scoresheet saved successfully");
  }

  const primaryAction = {
    content: "Save",
    action: updateLeagueScoresheet,
  };

  return (
    <Page
      title={`Edit ${leagueScoresheetState.league?.name}`}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: "Round Overview", // TODO: Translation
          url: "/admin/league/" + String(leagueId) + "/rounds/scoresheet/" + String(scoresheetId) + "/overview",
        },
      ]}
    >
      <Card>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Input
                value={leagueScoresheetState.name}
                label="Name"
                id="name"
                onChange={handleInputChange}
                placeholder="Name"
              />
              <DatePickerInput
                label="Date"
                months={1}
                showQuickOptions
                position={"right"}
                startingDate={filterState.filter_date}
                setStartingDate={function (value: SetStateAction<Date>): void {
                  handleDateSelectorChange(value);
                }}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <Select
                label="Front Division"
                onChange={(value: any) => handleDropDownChange(value, "division_front_id")}
                defaultValue={leagueScoresheetState.division_front_id}
              >
                {filterState.divisions.map((division: any, index: number) => {
                  return (
                    <Option key={index} value={division.id} name={division.title}>
                      {division.title}
                    </Option>
                  );
                })}
              </Select>

              <Select
                label="Back Division"
                onChange={(value: any) => handleDropDownChange(value, "division_back_id")}
                defaultValue={leagueScoresheetState.division_back_id}
              >
                {filterState.divisions.map((division: any, index: number) => {
                  return (
                    <Option key={index} value={division.id} name={division.title}>
                      {division.title}
                    </Option>
                  );
                })}
              </Select>
              <Select
                label="Tee Deck"
                onChange={(value: any) => handleDropDownChange(value, "tee_deck_id")}
                defaultValue={leagueScoresheetState.tee_deck_id}
              >
                {filterState.teeDecks.map((teeDeck: any, index: number) => {
                  return (
                    <Option key={index} value={teeDeck.id} name={teeDeck.title}>
                      {teeDeck.title}
                    </Option>
                  );
                })}
              </Select>
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>
    </Page>
  );
}
