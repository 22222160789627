import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";
import moment from "moment";
import { useHistory } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { StatusCode } from "api/protocols";
import {
  DownloadCustomerAccountStatements,
  GetAccountTransactionReceipt,
  GetCustomerHouseAccounts,
  GetCustomerStatements,
} from "api/rpc/2024-04/facilityAdmin/customer/customer";
import {
  CloseHouseAccount,
  GetAccountScheduledPayments,
  CancelAccountScheduledPayment,
  GetAccountTransactions,
  IPostAccount,
  IPutAccount,
  PostAccount,
  PostAccountTransactionRefund,
  PutAccount,
  PutAccountBalanceRefresh,
} from "api/rpc/2024-04/facilityAdmin/client/accounts";

import { showError, showSuccess } from "redux/actions/ui";
import { loadPaymentOptions } from "redux/actions/facility";

import { LocaleCurrency } from "helpers/Locale";
import { capitalize, valueToString } from "helpers/Helpers";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { TABLET_WIDTH } from "helpers/ScreenSizes";

import { useCustomerContext } from "../../context/CustomerContext";
import { Select } from "components/select/";
import { ButtonNew as Button } from "components/buttonNew";
import Spin from "components/spin/spin";
import { Badge } from "components/badge/Badge";
import Popup from "components/popup/Popup";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import Tabs from "components/tabs/Tabs";
import { NavigationDropdownNew } from "components/navigationDropdownNew/NavigationDropdownNew";
import Sheet from "components/sheet/Sheet";
import AccountScheduledPaymentModal from "./AccountScheduledPaymentModal";
import EditAccountScheduledPaymentModal from "./EditAccountScheduledPaymentModal";
import DataTable from "./DataTable";
import HouseAccountPaymentModal from "./HouseAccountPaymentModal";
import NewCustomerHouseAccountModal from "./NewCustomerHouseAccountModal";
import HouseAccountAdjustModal from "./HouseAccountAdjustModal";
import { EditCustomerHouseAccountModal, EditHouseAccountState } from "./EditCustomerHouseAccountModal";
import {
  HouseAccountStatementType,
  HouseAccountTransactionType,
  HouseAccountType,
  TransactionKind,
} from "../../context/contextTypes";

import "./CustomerHouseAccounts.scss";

interface ILoadHouseAccountsProps {
  customerId: number;
  /** Display overlay-spinner while loading */
  globalLoader: boolean;
  /** Selected Account after loading HouseAccounts */
  selectedAccountIndex: number;
  token?: CancelToken;
}

export default function CustomerHouseAccounts() {
  const { t } = useTranslation();
  const history = useHistory();
  const { Option } = Select;
  const windowSize = useWindowSize();

  const { state, updateState } = useCustomerContext();
  const dispatch = useAppDispatch();
  const { facilityStore, authStore } = useAppSelector(store => store);

  const permissions = authStore.user?.permissions;

  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [refundModal, setRefundModal] = useState({
    isVisible: false,
    transactionId: null,
  });

  const [startingDate, setStartingDate] = useState(new Date());
  const [endingDate, setEndingDate] = useState(new Date());

  const [newAccountModalVisible, setNewAccountModalVisible] = useState(false);
  const [editAccountModalVisible, setEditAccountModalVisible] = useState(false);
  const [editAccountSheet, setEditAccountSheet] = useState<Partial<EditHouseAccountState>>({
    title: "",
    account_number: "",
    accounting_reference_id: null,
    limit: "",
    tournament_id: null,
  });
  const [printStatementModalVisible, setPrintStatementModalVisible] = useState(false);

  const [scheduledPaymentModalVisible, setScheduledPaymentModalVisible] = useState<boolean>(false);

  const [editScheduledPaymentModalVisible, setEditScheduledPaymentModalVisible] = useState<boolean>(false);

  const [cancelModal, setCancelModal] = useState({
    scheduledPaymentId: null,
    modalVisible: false,
  });

  const [editScheduledPayment, setEditScheduledPayment] = useState<any>(null);

  const [adjustAccountModalVisible, setAdjustAccountModalVisible] = useState<boolean>(false);

  const [selectedTableTab, setSelectedTableTab] = useState<number>(0);

  const [selectedParentId, setSelectedParentId] = useState<number>(0);

  const tableTabs = [
    {
      id: "statements",
      content: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.044"),
    },
    {
      id: "transactions",
      content: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.050"),
    },
    {
      id: "scheduledPayments",
      content: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.057"),
    },
  ];

  // load all HouseAccounts associated with customer
  useEffect(() => {
    const source = axios.CancelToken.source();

    if (state.houseAccounts === null) {
      void loadHouseAccounts({
        customerId: state.customer.id,
        selectedAccountIndex: 0,
        globalLoader: false,
        token: source.token,
      });
    }

    if (facilityStore.paymentOptions.length === 0) {
      void dispatch(loadPaymentOptions());
    }

    return () => {
      source.cancel("CustomerHouseAccounts unmounted.");
    };
  }, [state.customer.id]);

  // Update statements & transactions as side-effect of changing selected HouseAccount
  useEffect(() => {
    const source = axios.CancelToken.source();

    if (state.selectedHouseAccount) {
      setEditAccountSheet({
        ...editAccountSheet,
        title: state.selectedHouseAccount.title,
        account_number: state.selectedHouseAccount.account_number,
        accounting_reference_id: state.selectedHouseAccount.accounting_reference_id,
        limit: state.selectedHouseAccount.limit as string,
        tournament_id: state.selectedHouseAccount.tournament_id,
      });

      void loadCustomerStatements(source.token);
      void loadAccountTransactions(source.token);
      void loadScheduledPayments(source.token);
    } else {
      updateState({
        accountStatements: [],
        accountTransactions: [],
        accountScheduledPayments: [],
      });
    }

    return () => {
      source.cancel("CustomerHouseAccounts unmounted.");
    };
  }, [state.selectedHouseAccount]);

  async function loadHouseAccounts(args: ILoadHouseAccountsProps) {
    const { customerId, globalLoader, token, selectedAccountIndex } = args;

    const accountRes = await GetCustomerHouseAccounts({ customer_id: customerId, closed: false }, globalLoader, token);
    if (accountRes.status !== StatusCode.OK) {
      return;
    }

    updateState({
      houseAccounts: accountRes.data,
      selectedHouseAccount: accountRes.data.length > 0 ? accountRes.data[0] : [],
      paymentAmount:
        accountRes.data.length > 0 ? accountRes.data.length[selectedAccountIndex]?.balance.toString() : "0",
    });
  }

  async function loadCustomerStatements(token?: CancelToken) {
    if (state.accountStatements !== null) {
      updateState({ accountStatements: null }); // disable inputs
    }

    const statementRes = await GetCustomerStatements({ account_id: state.selectedHouseAccount.id }, false, token);
    if (token && token.reason) {
      return;
    }
    if (statementRes.status !== StatusCode.OK) {
      return;
    }

    console.log("customerStatements", statementRes.data); //debug

    updateState({ accountStatements: statementRes.data }); // enable inputs
  }

  async function loadAccountTransactions(token?: CancelToken) {
    if (state.accountTransactions !== null) {
      updateState({ accountTransactions: null });
    }
    const accountTransactionsRes = await GetAccountTransactions(
      { account_id: state.selectedHouseAccount?.id },
      false,
      token,
    );

    if (accountTransactionsRes.status !== StatusCode.OK) {
      if (token.reason) {
        console.log(token.reason.message);
      } else {
        dispatch(showError(t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.051")));
      }
      return;
    }

    const markRefunds = determineRefundedTransactions(accountTransactionsRes.data);

    const facilities = facilityStore.facilities;

    for (let i = 0; i < markRefunds.length; i++) {
      for (let k = 0; k < facilities.length; k++) {
        if (markRefunds[i].facility_id === facilities[k].id) {
          markRefunds[i].facility = facilities[k];
        }
      }
    }

    updateState({ accountTransactions: markRefunds });
  }

  async function loadScheduledPayments(cancelToken?: CancelToken) {
    if (state.accountScheduledPayments !== null) {
      updateState({ accountScheduledPayments: null });
    }

    const paymentRes = await GetAccountScheduledPayments(
      { account_id: state?.selectedHouseAccount?.id },
      false,
      cancelToken,
    );

    if (cancelToken && cancelToken.reason) {
      return;
    }
    if (paymentRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.058")));
      return;
    }

    updateState({ accountScheduledPayments: paymentRes?.data });
  }

  async function refreshAccountBalance() {
    const balanceRes = await PutAccountBalanceRefresh({ account_id: state.selectedHouseAccount?.id }, true);
    if (balanceRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.052")));
    }
    const updatedAccounts = state.houseAccounts;
    updatedAccounts.map(account => {
      if (account.id === state.selectedHouseAccount.id) {
        return { ...balanceRes.data } as HouseAccountType;
      } else {
        return account;
      }
    });

    dispatch(showSuccess(t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.053")));
    updateState({ selectedHouseAccount: balanceRes.data, houseAccounts: updatedAccounts });

    void loadCustomerStatements();
    void loadAccountTransactions();
  }

  async function downloadAccountStatement(statement_id: number) {
    const downloadRes = await DownloadCustomerAccountStatements({ account_statement_id: statement_id }, true);
    if (downloadRes.status !== StatusCode.OK) {
      dispatch(showError(downloadRes.data.message));
      return;
    }

    window.open().document.write(downloadRes.data);
  }

  async function printAccountStatementRange(accountId: number) {
    const params = {
      account_id: accountId,
      start_date: startingDate,
      end_date: endingDate,
    };

    const downloadRes = await DownloadCustomerAccountStatements(params, true);
    if (downloadRes.status !== StatusCode.OK) {
      return;
    }
    window.open().document.write(downloadRes.data);
  }

  async function getAccountTransactionReceipt(accountId: number) {
    const receiptRes = await GetAccountTransactionReceipt({ account_transaction_id: accountId }, true);
    if (receiptRes.status !== StatusCode.OK) {
      dispatch(showError(receiptRes.data.message));
      return;
    }
    window.open().document.write(receiptRes.data);
  }

  async function createHouseAccount(houseAccount: HouseAccountType, limitValid: boolean) {
    if (houseAccount.title === "" || !limitValid) {
      return;
    }

    const postAccount: IPostAccount = {
      title: houseAccount.title,
      currency: "cad",
      global: houseAccount.global,
      account_number: houseAccount.account_number === "" ? undefined : houseAccount.account_number,
      limit: Number(houseAccount.limit),
      accounting_reference_id: houseAccount.accounting_reference_id === 0 ? null : houseAccount.accounting_reference_id,
      customer_id: state.customer.id,
    };

    const accountRes = await PostAccount(postAccount, true);
    if (accountRes.status !== StatusCode.OK) {
      return;
    }

    console.log("PostAccount res ", accountRes); //debug

    setNewAccountModalVisible(false);
    void loadHouseAccounts({
      customerId: state.customer.id,
      globalLoader: true,
      selectedAccountIndex: state.houseAccounts.length,
    });
  }

  async function updateHouseAccount(editState: EditHouseAccountState) {
    const params: IPutAccount = {
      id: state.selectedHouseAccount.id,
      title: editState.title,
      account_number: editState.account_number,
      limit: Number(editState.limit),
      accounting_reference_id: editState.accounting_reference_id === 0 ? null : editState.accounting_reference_id,
      tournament_id: editState.tournament_id,
      global: editState.global,
    };

    const accountRes = await PutAccount(params, true);

    if (accountRes.status !== StatusCode.OK) {
      return;
    }

    setEditAccountModalVisible(false);
    void loadHouseAccounts({
      customerId: state.customer.id,
      globalLoader: true,
      selectedAccountIndex: state.houseAccounts.findIndex(account => {
        return account.id === state.selectedHouseAccount.id;
      }),
    });
  }

  async function handleAccountTransactionRefund(transactionId: number) {
    const refundRes = await PostAccountTransactionRefund(
      {
        account_transaction_id: transactionId,
      },
      true,
    );

    if (refundRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.054")));
      return;
    }

    dispatch(showSuccess(t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.055")));

    setRefundModal({ isVisible: false, transactionId: null });
    void refreshAccountBalance();
  }

  async function handleCloseHouseAccount(accountId: number) {
    const res = await CloseHouseAccount({ account_id: accountId }, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError(`Error: ${res.data as string}`));
      return;
    }

    dispatch(showSuccess(t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.071")));
    setCloseModal(false);

    void loadHouseAccounts({
      customerId: state.customer.id,
      globalLoader: true,
      selectedAccountIndex: state.houseAccounts.findIndex(account => {
        return account.id === state.selectedHouseAccount.id;
      }),
    });
  }

  function handleHouseAccountChange(accountId: number) {
    if (accountId !== null) {
      const account = state.houseAccounts.find(account => {
        return account.id === accountId;
      });

      updateState({
        selectedHouseAccount: account,
        paymentAmount: account?.balance.toString(),
      });
    }
  }

  function handleShowCancelScheduledPayment(id: number) {
    setCancelModal({ modalVisible: true, scheduledPaymentId: id });
  }

  async function cancelScheduledPayment() {
    const cancelRes = await CancelAccountScheduledPayment({ id: cancelModal.scheduledPaymentId }, true);

    if (cancelRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.072")));
      return;
    }

    setCancelModal({ modalVisible: false, scheduledPaymentId: null });
    dispatch(showSuccess(t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.073")));
    void loadScheduledPayments();
  }

  const elementScrollRef = useCallback(
    element => {
      if (element !== null && selectedParentId) {
        if (element?.id === String(selectedParentId)) {
          element?.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }
    },
    [selectedParentId],
  );

  return (
    <div>
      {state.houseAccounts ? (
        <>
          <div className="house-accounts-header">
            <div className="ui-customer_page-header house-accounts-title">
              {t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.060")}
            </div>
            <div className="house-accounts-actions mr-2">
              <NavigationDropdownNew
                rightAlign
                label={t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.074")}
                sections={[
                  [
                    {
                      label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.075"),
                      icon: "plus",
                      onClick: () => setNewAccountModalVisible(true),
                    },
                  ],
                  [
                    {
                      label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.076"),
                      icon: "dollar-sign",
                      onClick: () => setPaymentModalVisible(true),
                      disabled: !state.selectedHouseAccount?.id,
                    },
                    {
                      label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.077"),
                      icon: "arrow-up-arrow-down",
                      disabled: !permissions.accounts_post_adjustment || !state.selectedHouseAccount?.id,
                      onClick: () => setAdjustAccountModalVisible(true),
                    },
                    {
                      label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.078"),
                      icon: "calendar-days",
                      onClick: () => setScheduledPaymentModalVisible(true),
                      disabled: !state.selectedHouseAccount?.id,
                    },
                  ],
                  [
                    {
                      label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.079"),
                      icon: "pencil",
                      // disabled: state.accountStatements === null || state.accountTransactions === null,
                      onClick: () => setEditAccountModalVisible(true),
                      disabled: !state.selectedHouseAccount?.id,
                    },
                    {
                      label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.080"),
                      icon: "lock",
                      onClick: () => setCloseModal(true),
                      disabled: !state.selectedHouseAccount?.id,
                    },
                    {
                      label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.081"),
                      icon: "merge",
                      disabled: true, // Temporary until merge account is implemented
                      onClick: () => {},
                    },
                    {
                      label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.082"),
                      icon: "print",
                      onClick: () => setPrintStatementModalVisible(true),
                      disabled: !state.selectedHouseAccount?.id,
                    },
                  ],
                ]}
              />
            </div>
          </div>

          <div className="house-accounts-divider"></div>

          <Select
            className="mb-4"
            defaultValue={state.selectedHouseAccount?.id}
            onChange={(id: number) => handleHouseAccountChange(id)}
            disabled={state.accountStatements === null || state.accountTransactions === null}
          >
            {state.houseAccounts.map(account => {
              return (
                <Option key={account.id} value={account.id} name={account.title}>
                  {account?.closed ? (
                    <span>
                      {account.title}
                      &nbsp;
                      <Badge type="gray">Closed</Badge>
                    </span>
                  ) : (
                    <span>{account.title}</span>
                  )}
                </Option>
              );
            })}
          </Select>

          <div className="balance-credit-container">
            <div className="house-accounts_left">
              <div className="house-accounts_balance">
                <div className="currency-text">
                  <LocaleCurrency amount={state.selectedHouseAccount?.balance} />
                </div>
                <div>
                  <p className="left-text">
                    {t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.003")}
                  </p>
                  <Button type="text" size="small" onClick={refreshAccountBalance}>
                    {t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.045")}
                  </Button>
                </div>
              </div>
              <div className="house-accounts_credit-limit">
                <div className="currency-text">
                  <LocaleCurrency amount={state.selectedHouseAccount?.limit} />
                </div>
                <p className="left-text">
                  {t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.004")}
                </p>
              </div>
            </div>
          </div>

          <Tabs tabs={tableTabs} selected={selectedTableTab} onSelect={setSelectedTableTab}>
            <div style={{ display: selectedTableTab === 0 ? "block" : "none", paddingTop: "8px" }}>
              <DataTable<HouseAccountStatementType>
                columns={[
                  { label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.006") },
                  { label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.007") },
                  { label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.008") },
                  { label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.009") },
                  { label: "" },
                ]}
                // sort={(column, direction) => updateState({ accountStatements: sortColumn(state.accountStatements, column, direction) })}
                loading={!state.accountStatements}
              >
                {state.accountStatements?.map((statement, index) => {
                  return (
                    <tr key={statement.id}>
                      <td>{statement.id}</td>
                      <td>{moment(statement.start_date).utc().format("LL")}</td>
                      <td>{moment(statement.end_date).utc().format("LL")}</td>
                      <td>
                        <LocaleCurrency currency={"CAD"} amount={statement.current_balance} />
                      </td>
                      <td>
                        <p onClick={() => downloadAccountStatement(statement.id)}>
                          {t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.049")}
                        </p>
                      </td>
                    </tr>
                  );
                })}
              </DataTable>
            </div>

            <div style={{ display: selectedTableTab === 1 ? "block" : "none", paddingTop: "8px" }}>
              <DataTable<HouseAccountTransactionType>
                columns={[
                  { label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.067") },
                  { label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.068") },
                  { label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.010") },

                  { label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.069") },
                  { label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.070") },
                  { label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.083") },
                  { label: "", width: "5%" }, // 'Dropdown'
                ]}
                loading={!state.accountTransactions}
              >
                {state.accountTransactions?.map((transaction, index: number) => {
                  return (
                    <tr
                      key={transaction.id}
                      className={
                        transaction.id === selectedParentId ? "house-account-transaction-selected-parent" : null
                      }
                      id={String(transaction.id)}
                      ref={elementScrollRef}
                    >
                      <td>{moment(transaction?.processed_at).format("ll")}</td>

                      <td>{transaction?.facility?.long_name}</td>

                      <td>
                        <span>
                          <LocaleCurrency amount={transaction?.amount} currency={transaction?.currency} />{" "}
                          {transaction?.currency.toUpperCase()}
                        </span>
                      </td>
                      <td>
                        <p style={{ fontSize: "12px" }}>{valueToString(transaction?.kind)}</p>
                        <p>{transactionBadge(transaction?.kind)}</p>
                      </td>
                      <td>
                        <span>{valueToString(transaction?.payment_type) || ""}</span>
                      </td>
                      <td>{transaction.payment_last_4 ? transaction.payment_last_4 : ""}</td>
                      <td>
                        <NavigationDropdownNew
                          rightAlign
                          position={index === 0 ? "bottom" : "top"}
                          showPlainTextLabel
                          label={<FontAwesomeIcon icon={["far", "chevron-down"]} />}
                          sections={[
                            [
                              {
                                label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.084"),
                                icon: "print",
                                onClick: () => getAccountTransactionReceipt(transaction?.id),
                                hidden: transaction?.kind !== "account_payment",
                              },
                              {
                                label: transaction?.refunded ? "Refunded" : "Refund",
                                icon: "turn-down-left",
                                onClick: () => setRefundModal({ isVisible: true, transactionId: transaction.id }),
                                disabled: transaction?.refunded || transaction?.kind !== "account_payment",
                              },
                              {
                                label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.085"),
                                icon: "magnifying-glass",
                                onClick: () =>
                                  history.push("/admin/order/" + String(transaction?.order_transaction?.order_id)),
                                disabled: !transaction.order_transaction?.order_id,
                              },
                              {
                                label: "Hightlight Original Transaction",
                                icon: "eye",
                                onClick: () => setSelectedParentId(transaction.parent_id),
                                hidden: !transaction.parent_id,
                              },
                            ],
                          ]}
                          informationSection={transaction?.note ? [transaction.note] : undefined}
                        />
                      </td>
                    </tr>
                  );
                })}
              </DataTable>
            </div>

            <div style={{ display: selectedTableTab === 2 ? "block" : "none", paddingTop: "8px" }}>
              <DataTable
                columns={[
                  { label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.062") },
                  { label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.063") },
                  { label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.064") },
                  { label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.065") },
                  { label: "" },
                  { label: "", width: "5%" },
                ]}
                loading={!state?.accountScheduledPayments}
              >
                {state.accountScheduledPayments?.map((payment, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{moment(payment?.process_date).format("ll")}</td>
                      <td>{<LocaleCurrency amount={payment?.amount} />}</td>
                      <td>{scheduledPaymentBadge(payment.status)}</td>
                      <td>{payment?.note}</td>
                      <td>
                        {payment?.charge_balance ? (
                          <Badge type="primary" size="small">
                            {t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.066")}
                          </Badge>
                        ) : null}
                      </td>
                      <td>
                        {payment.status === "pending" && (
                          <NavigationDropdownNew
                            rightAlign
                            position={index === 0 ? "bottom" : "top"}
                            showPlainTextLabel
                            label={<FontAwesomeIcon icon={["far", "chevron-down"]} />}
                            sections={[
                              [
                                {
                                  label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.086"),
                                  icon: "pen-to-square",
                                  onClick: () => {
                                    setEditScheduledPayment(payment);
                                    setEditScheduledPaymentModalVisible(true);
                                  },
                                },
                                {
                                  label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.087"),
                                  icon: "ban",
                                  onClick: () => handleShowCancelScheduledPayment(payment?.id),
                                },
                              ],
                            ]}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </DataTable>
            </div>
          </Tabs>
        </>
      ) : (
        // Page Spinner
        <div style={{ marginTop: "4em", pointerEvents: "none", textAlign: "center" }}>
          <span style={{ display: "inline-flex", transform: "scale(2)" }}>
            <Spin />
          </span>
        </div>
      )}

      {/**
       *
       *  MODALS AND POPUPS
       *
       */}
      <NewCustomerHouseAccountModal
        isVisible={newAccountModalVisible}
        accountingReferenceTypes={state.accountingReferenceTypes}
        customerId={state.customer.id}
        onClose={() => setNewAccountModalVisible(false)}
        onOk={createHouseAccount}
      />

      <EditCustomerHouseAccountModal
        isVisible={editAccountModalVisible}
        accountingReferenceTypes={state.accountingReferenceTypes}
        editAccount={editAccountSheet}
        setEditAccount={setEditAccountSheet}
        onClose={() => {
          setEditAccountModalVisible(false);
          setEditAccountSheet({
            title: state.selectedHouseAccount?.title,
            account_number: state.selectedHouseAccount?.account_number,
            accounting_reference_id: state.selectedHouseAccount?.accounting_reference_id,
            limit: state.selectedHouseAccount?.limit as string,
            tournament_id: state.selectedHouseAccount?.tournament_id,
          });
        }}
        onOk={updateHouseAccount}
      />

      {state.selectedHouseAccount && paymentModalVisible && (
        <HouseAccountPaymentModal
          paymentModalVisible={paymentModalVisible}
          houseAccount={state.selectedHouseAccount}
          paymentMethods={state.paymentMethods}
          onOk={() => void refreshAccountBalance()}
          closePaymentModal={() => setPaymentModalVisible(false)}
        />
      )}

      {state.selectedHouseAccount && closeModal && (
        <Popup
          open={true}
          type={"warning"}
          title={t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.088")}
          description={t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.089")}
          onCancel={() => setCloseModal(false)}
          onOk={() => handleCloseHouseAccount(state.selectedHouseAccount.id)} // TODO: Attach to an endpoint
        />
      )}

      <AccountScheduledPaymentModal
        open={scheduledPaymentModalVisible}
        onClose={() => setScheduledPaymentModalVisible(false)}
        onOk={loadScheduledPayments}
      />

      <EditAccountScheduledPaymentModal
        open={editScheduledPaymentModalVisible}
        onClose={() => setEditScheduledPaymentModalVisible(false)}
        onOk={loadScheduledPayments}
        scheduledPayment={editScheduledPayment}
      />

      <Popup
        open={cancelModal.modalVisible}
        type="warning"
        title={t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.090")}
        description={t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.091")}
        onOk={() => cancelScheduledPayment()}
        okText={t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.092")}
        cancelText={t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.093")}
        onCancel={() => setCancelModal({ modalVisible: false, scheduledPaymentId: null })}
      />

      <Popup
        open={refundModal.isVisible}
        type="warning"
        title={t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.041")}
        description={t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.042")}
        onOk={() => handleAccountTransactionRefund(refundModal.transactionId)}
        okText={t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.043")}
        cancelText={t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.020")}
        onCancel={() => setRefundModal({ isVisible: false, transactionId: null })}
      />

      <HouseAccountAdjustModal
        open={adjustAccountModalVisible}
        onClose={() => setAdjustAccountModalVisible(false)}
        onOk={refreshAccountBalance}
      />

      <Sheet
        open={printStatementModalVisible}
        closable
        size="medium"
        title={t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.094")}
        supportText={t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.095")}
        onOk={() => printAccountStatementRange(state.selectedHouseAccount.id)}
        okText={t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.096")}
        cancelText={t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.097")}
        onCancel={() => setPrintStatementModalVisible(false)}
        overflow
      >
        <DatePickerInput
          months={windowSize.width <= TABLET_WIDTH ? 1 : 2}
          position="center"
          showQuickOptions
          startingDate={startingDate}
          setStartingDate={setStartingDate}
          endingDate={endingDate}
          setEndingDate={setEndingDate}
        />
      </Sheet>
    </div>
  );
}

/** Refunded transaction based off parent_id of the transaction == the currentTransactionId */
function determineRefundedTransactions(transactions: HouseAccountTransactionType[]): HouseAccountTransactionType[] {
  const filteredTransactions = transactions.filter(
    (transaction: Record<string, any>) => transaction?.kind !== "authorization",
  );
  const refundedTransactions = transactions.filter(
    (transaction: Record<string, any>) => transaction?.parent_id && transaction?.kind === "account_refund",
  );

  const markRefunds = [...filteredTransactions]?.reduce(
    (markedRefunds, currentTransaction, index) => {
      const refunded = refundedTransactions?.some(
        (transaction: Record<string, any>) => transaction?.parent_id === currentTransaction?.id,
      );

      // Adding in 'refunded' param to HouseAccountTransactionType
      if (refunded) {
        markedRefunds[index] = { ...markedRefunds[index], refunded: true };
      } else {
        markedRefunds[index] = { ...markedRefunds[index], refunded: false };
      }
      return markedRefunds;
    },
    [...filteredTransactions],
  );

  return markRefunds;
}

// Account Payments and Refunds decrease balance on account
const transactionBadge = (type: TransactionKind) => {
  switch (type) {
    case "account_payment":
    case "refund":
    case "credit_adjustment":
      return (
        <Badge type="success" iconLeft={<FontAwesomeIcon icon={["fas", "arrow-up-left"]} />}>
          Credit
        </Badge>
      );
    case "debit_adjustment":
    case "sale":
      return (
        <Badge type="error" iconLeft={<FontAwesomeIcon icon={["fas", "arrow-down-left"]} />}>
          Debit
        </Badge>
      );
    default:
      return <></>;
  }
};

const scheduledPaymentBadge = (status: string) => {
  switch (status) {
    case "complete":
      return <Badge type="success">{capitalize(status)}</Badge>;
    case "canceled":
      return <Badge type="error">{capitalize(status)}</Badge>;
    default:
      return <Badge type="gray">{capitalize(status)}</Badge>;
  }
};
