import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDOM from "react-dom";

import { StatusCode } from "api/protocols";
import {
  GetBookingEngine,
  GetBookingFees,
  PostBookingFee,
  PutBookingEngine,
  PutBookingFee,
  DeleteBookingFee,
} from "api/rpc/2022-09/facilityAdmin/teesheet/bookingEngine";
import { PutBookingWindow } from "api/rpc/2022-09/facilityAdmin/teesheet/bookingWindow";
import { GetBookingCategory } from "api/rpc/2022-09/facilityAdmin/teesheet/bookingCategory";
import { GetProduct } from "api/rpc/2022-09/facilityAdmin/product/product";

import { showError, showSuccess } from "redux/actions/ui";
import { LocaleCurrency } from "helpers/Locale";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Input from "components/form/input/Input";
import Checkbox from "components/form/checkbox/Checkbox";
import FormLayout from "components/form/FormLayout";
import Sheet from "components/sheet/Sheet";
import Stack from "components/stack/Stack";
import TextEditor from "components/textEditor/textEditor";
import { ButtonNew as Button } from "components/buttonNew";
import { Badge } from "components/badge/Badge";
import Popup from "components/popup/Popup";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

import "./bookingEngine.scss";

interface IBookingEngineState {
  bookingEngineId: string;
  title: string;
  bookingTerms: string;
  paymentTerms: string;

  bookingWindowId: number;
  daysInAdvanceStart: number;
  daysInAdvanceEnd: number;

  bookingFees: Array<Record<string, any>>;

  editBookingFeeVisible: boolean;
  selectedBookingFee: Record<string, any>;

  bookingCategories: Array<Record<string, any>>;
  selectedBookingCategories: Array<Record<string, any>>;

  deleteBookingFeePopup: boolean;
  selectedBookingFeeId: number;
}

interface INewBookingFeeState {
  showBookingFeeModal: boolean;
  nonRefundable: boolean;
  daysInAdvanceStart: string;
  daysInAdvanceEnd: string;

  showProductModal: boolean;
  selectedVariant: Record<string, any>;
  selectedProduct: Record<string, any>;
  productSearchQuery: string;
  products: Array<Record<string, any>>;
}

export default function TeeSheetBookingEngine() {
  const { t, i18n } = useTranslation();
  const { bookingEngineId } = useParams<{ bookingEngineId: string }>();

  const dispatch = useAppDispatch();

  const [bookingEngineState, setBookingEngineState] = useState<IBookingEngineState>({
    bookingEngineId: null,
    title: "",
    bookingTerms: null,
    paymentTerms: null,

    bookingWindowId: null,
    daysInAdvanceStart: null,
    daysInAdvanceEnd: null,

    bookingFees: [],

    editBookingFeeVisible: false,
    selectedBookingFee: null,

    bookingCategories: [],
    selectedBookingCategories: [],

    deleteBookingFeePopup: false,
    selectedBookingFeeId: null,
  });

  const [newBookingFeeState, setNewBookingFeeState] = useState<INewBookingFeeState>({
    showBookingFeeModal: false,
    nonRefundable: false,
    daysInAdvanceStart: "",
    daysInAdvanceEnd: "",

    showProductModal: false,
    selectedVariant: null,
    selectedProduct: null,
    productSearchQuery: "",
    products: [],
  });

  useEffect(() => {
    void loadBookingEngines();
    void loadBookingCategories();
    void loadBookingFees();
    void loadProducts();
  }, []);

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (() => {
          try {
            if (newBookingFeeState.productSearchQuery === "") {
              if (mounted) {
                setNewBookingFeeState(prevState => ({ ...prevState, selectedVariant: null, selectedProduct: null }));
                void loadProducts();
              }
              return;
            } else {
              if (mounted) {
                void loadProducts();
              }
            }
          } catch (error) {
            console.log("Products error", error);
          }
          return;
        })();
      }, 1000);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [newBookingFeeState.productSearchQuery]);

  async function loadBookingEngines() {
    const res = await GetBookingEngine({ id: bookingEngineId, extended: true }, false);
    if (res.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.tee_sheets.booking_engine.031")));
      return;
    }

    const bookingEngine = res.data[0];
    const bookingWindow = bookingEngine.booking_window;

    setBookingEngineState(prev => ({
      ...prev,
      bookingEngineId: bookingEngine.id,
      title: bookingEngine.title,
      bookingWindowId: bookingWindow.id,
      daysInAdvanceStart: bookingWindow.days_in_advance_start,
      daysInAdvanceEnd: bookingWindow.days_in_advance_end,
      bookingTerms: bookingEngine.booking_terms,
      paymentTerms: bookingEngine.payment_terms,
      selectedBookingCategories: bookingEngine?.booking_categories,
    }));
  }

  async function loadBookingFees() {
    const res = await GetBookingFees({ booking_engine_id: bookingEngineId }, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.tee_sheets.booking_engine.041")));
      return;
    }
    setBookingEngineState(prevState => ({ ...prevState, bookingFees: res.data }));
  }

  async function loadProducts() {
    const res = await GetProduct(
      { type: "Booking Fee", search: newBookingFeeState.productSearchQuery, extended_variants: true },
      true,
    );
    if (res.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.tee_sheets.booking_engine.042")));
      return;
    }
    setNewBookingFeeState(prevState => ({ ...prevState, products: res.data }));
  }

  async function loadBookingCategories() {
    const res = await GetBookingCategory(null, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.tee_sheets.booking_engine.032")));
      return;
    }
    setBookingEngineState(prevState => ({ ...prevState, bookingCategories: res.data }));
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setBookingEngineState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleBookingFeeCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, checked } = event.target;
    const selectedBookingFee = { ...bookingEngineState?.selectedBookingFee, [id]: checked };
    setBookingEngineState(prevState => ({ ...prevState, selectedBookingFee }));
  }

  function handleNewBookingFeeCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, checked } = event.target;
    setNewBookingFeeState(prevState => ({ ...prevState, [id]: checked }));
  }

  function handleNewBookingFeeInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setNewBookingFeeState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleBookingFeeInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    const selectedBookingFee = { ...bookingEngineState?.selectedBookingFee, [id]: value };
    setBookingEngineState(prevState => ({ ...prevState, selectedBookingFee }));
  }

  function openEditBookingFee(bookingFee: any) {
    ReactDOM.unstable_batchedUpdates(() => {
      setBookingEngineState(prevState => ({
        ...prevState,
        selectedBookingFee: bookingFee,
        editBookingFeeVisible: true,
      }));
      setNewBookingFeeState(prevState => ({ ...prevState, selectedVariant: bookingFee?.variant }));
    });
  }

  function closeEditBookingFee() {
    setBookingEngineState(prevState => ({
      ...prevState,
      selectedBookingFee: null,
      editBookingFeeVisible: false,
    }));
    void closeNewBookingFee();
  }

  async function saveBookingEngine() {
    const bookingCategoryIds: Array<number> = bookingEngineState?.selectedBookingCategories.map(
      category => category?.id as number,
    );

    const bookingEngineRes = await PutBookingEngine(
      {
        id: bookingEngineState.bookingEngineId,
        title: bookingEngineState.title,
        booking_terms: bookingEngineState.bookingTerms,
        payment_terms: bookingEngineState.paymentTerms,
        booking_category_ids: bookingCategoryIds,
      },
      true,
    );
    if (bookingEngineRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.tee_sheets.booking_engine.033")));
      return;
    }

    const bookingEngine = bookingEngineRes.data;

    const bookingWindowRes = await PutBookingWindow(
      {
        id: bookingEngineState.bookingWindowId,
        days_in_advance_start: bookingEngineState.daysInAdvanceStart,
        days_in_advance_end: bookingEngineState.daysInAdvanceEnd,
      },
      true,
    );
    if (bookingWindowRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.tee_sheets.booking_engine.034")));
      return;
    }

    const bookingWindow = bookingWindowRes.data;

    dispatch(showSuccess(t("secure.facility.settings.tee_sheets.booking_engine.035")));

    setBookingEngineState(prev => ({
      ...prev,
      bookingEngineId: bookingEngine.id,
      title: bookingEngine.title,
      booking_window: bookingEngine.booking_window,
      bookingWindowId: bookingWindow.id,
      daysInAdvanceStart: bookingWindow.days_in_advance_start,
      daysInAdvanceEnd: bookingWindow.days_in_advance_end,
    }));
  }

  async function saveBookingFee() {
    const bookingFee = bookingEngineState.selectedBookingFee;

    const bookingFeeRes = await PutBookingFee(
      {
        id: bookingFee.id,
        non_refundable: bookingFee.non_refundable,
        days_in_advance_start: bookingFee.days_in_advance_start,
        days_in_advance_end: bookingFee.days_in_advance_end,
        variant_id: newBookingFeeState?.selectedVariant?.id,
      },
      true,
    );

    if (bookingFeeRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.tee_sheets.booking_engine.036")));
      return;
    }

    dispatch(showSuccess(t("secure.facility.settings.tee_sheets.booking_engine.037")));

    setBookingEngineState(prevState => ({
      ...prevState,
      selectedBookingFee: null,
      editBookingFeeVisible: false,
    }));
    void loadBookingFees();
  }

  function setBookingTerms(content: string) {
    setBookingEngineState(prevState => {
      return { ...prevState, bookingTerms: content };
    });
  }

  function setPaymentTerms(content: string) {
    setBookingEngineState(prevState => {
      return { ...prevState, paymentTerms: content };
    });
  }

  function handleBookingCategoryChange(bookingCategory: Record<string, any>) {
    const bookingCategoriesSelected = [...bookingEngineState?.selectedBookingCategories];
    const bookingCategoryIndex = bookingCategoriesSelected?.findIndex(
      category => category?.id === bookingCategory?.id,
    );

    if (bookingCategoryIndex === -1) {
      bookingCategoriesSelected.push(bookingCategory);
    } else {
      bookingCategoriesSelected?.splice(bookingCategoryIndex, 1);
    }
    setBookingEngineState(prevState => ({ ...prevState, selectedBookingCategories: bookingCategoriesSelected }));
  }

  function closeNewBookingFee() {
    setNewBookingFeeState(prevState => ({
      ...prevState,
      showBookingFeeModal: false,
      taxable: false,
      nonRefundable: false,
      daysInAdvanceEnd: "",
      daysInAdvanceStart: "",
      selectedProduct: null,
      selectedVariant: null,
    }));
  }

  function selectVariant(variant: Record<string, any>, product: Record<string, any>) {
    if (newBookingFeeState.selectedVariant && newBookingFeeState.selectedVariant?.id === variant?.id) {
      setNewBookingFeeState(prevState => ({ ...prevState, selectedVariant: null, selectedProduct: null }));
    } else {
      setNewBookingFeeState(prevState => ({ ...prevState, selectedVariant: variant, selectedProduct: product }));
    }
  }

  async function createBookingFee() {
    const res = await PostBookingFee(
      {
        booking_engine_id: Number(bookingEngineId),
        variant_id: newBookingFeeState?.selectedVariant?.id,
        non_refundable: newBookingFeeState?.nonRefundable,
        days_in_advance_end: newBookingFeeState?.daysInAdvanceEnd,
        days_in_advance_start: newBookingFeeState?.daysInAdvanceStart,
      },
      true,
    );
    if (res.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.tee_sheets.booking_engine.043")));
      return;
    }
    dispatch(showSuccess(t("secure.facility.settings.tee_sheets.booking_engine.044")));
    void closeNewBookingFee();
    void loadBookingFees();
  }

  async function handleRemoveBookingFee() {
    const removeRes = await DeleteBookingFee({ id: bookingEngineState?.selectedBookingFeeId }, true);
    if (removeRes?.status !== StatusCode.OK) {
      dispatch(showError("Error deleting booking fee")); // TODO: Translation
      return;
    }
    setBookingEngineState(prevState => ({ ...prevState, deleteBookingFeePopup: false, selectedBookingFeeId: null }));
    dispatch(showSuccess("Successfully deleted booking fee")); // TODO: Translation
    void loadBookingFees();
  }

  const primaryAction = {
    content: t("secure.facility.settings.tee_sheets.booking_engine.001"),
    action: saveBookingEngine,
  };

  return (
    <Page
      title={bookingEngineState.title}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.tee_sheets.booking_engine.038"),
          url: "/admin/settings/tee-sheet/booking-engine",
        },
      ]}
    >
      <Card title={t("secure.facility.settings.tee_sheets.booking_engine.002")}>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Input
                value={bookingEngineState.title ?? ""}
                label={t("secure.facility.settings.tee_sheets.booking_engine.003")}
                id="title"
                onChange={handleInputChange}
                placeholder={t("secure.facility.settings.tee_sheets.booking_engine.004")}
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>

      <Card title={t("secure.facility.settings.tee_sheets.booking_engine.005")}>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Input
                value={bookingEngineState.daysInAdvanceStart ?? ""}
                label={t("secure.facility.settings.tee_sheets.booking_engine.006")}
                id="daysInAdvanceStart"
                helpText={t("secure.facility.settings.tee_sheets.booking_engine.007")}
                onChange={handleInputChange}
                suffix="days"
                placeholder={t("secure.facility.settings.tee_sheets.booking_engine.008")}
              />
              <Input
                value={bookingEngineState.daysInAdvanceEnd ?? ""}
                label={t("secure.facility.settings.tee_sheets.booking_engine.009")}
                id="daysInAdvanceEnd"
                helpText={t("secure.facility.settings.tee_sheets.booking_engine.010")}
                onChange={handleInputChange}
                suffix="days"
                placeholder={t("secure.facility.settings.tee_sheets.booking_engine.011")}
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>

      <Card
        title={t("secure.facility.settings.tee_sheets.booking_engine.012")}
        titleActions={[
          {
            content: t("secure.facility.settings.tee_sheets.booking_engine.040"),
            action: () => setNewBookingFeeState(prevState => ({ ...prevState, showBookingFeeModal: true })),
          },
        ]}
      >
        <Card.Section>
          {bookingEngineState.bookingFees?.map((bookingFee: any, index: number) => {
            return (
              <Card.SubSection key={index}>
                <Stack>
                  <Stack.Item fill>
                    <p>{t("secure.facility.settings.tee_sheets.booking_engine.013")}</p>
                  </Stack.Item>
                  <Stack.Item>
                    <LocaleCurrency currency="cad" amount={bookingFee.price} />
                    <span>/ {bookingFee.unit}</span>
                  </Stack.Item>
                  <Stack.Item>
                    <p>
                      {bookingFee.days_in_advance_start} - {bookingFee.days_in_advance_end} days
                    </p>
                  </Stack.Item>
                  <Stack.Item>
                    <p>
                      {bookingFee?.non_refundable
                        ? t("secure.facility.settings.tee_sheets.booking_engine.045")
                        : t("secure.facility.settings.tee_sheets.booking_engine.046")}
                    </p>
                  </Stack.Item>
                  <Stack.Item>
                    <button className="booking-engine-fee-button" onClick={() => openEditBookingFee(bookingFee)}>
                      Edit
                    </button>
                  </Stack.Item>
                  <Stack.Item>
                    <button
                      className="booking-engine-fee-button"
                      onClick={() =>
                        setBookingEngineState(prevState => ({
                          ...prevState,
                          deleteBookingFeePopup: true,
                          selectedBookingFeeId: bookingFee?.id,
                        }))
                      }
                    >
                      Delete
                    </button>
                  </Stack.Item>
                </Stack>
              </Card.SubSection>
            );
          })}
        </Card.Section>
      </Card>

      <DataTable title={t("secure.facility.settings.tee_sheets.booking_engine.039")} columns={[]} hideHeader>
        {bookingEngineState.bookingCategories?.map((bookingCategory: Record<string, any>, index: number) => {
          return (
            <tr key={index} className="clickable" onClick={e => handleBookingCategoryChange(bookingCategory)}>
              <td>
                <Checkbox
                  size="medium"
                  checked={bookingEngineState?.selectedBookingCategories?.some(
                    category => category?.id === bookingCategory?.id,
                  )}
                  onChange={e => e.stopPropagation()}
                  label={bookingCategory?.title}
                />
              </td>
            </tr>
          );
        })}
      </DataTable>
      <Card title={t("secure.facility.settings.tee_sheets.booking_engine.014")}>
        <Card.Section
          title={t("secure.facility.settings.tee_sheets.booking_engine.015")}
          subtitle={t("secure.facility.settings.tee_sheets.booking_engine.016")}
        >
          <TextEditor markdownText={bookingEngineState.bookingTerms} markdownTextOnChange={setBookingTerms} />
        </Card.Section>
        <Card.Section
          title={t("secure.facility.settings.tee_sheets.booking_engine.017")}
          subtitle={t("secure.facility.settings.tee_sheets.booking_engine.018")}
        >
          <TextEditor markdownText={bookingEngineState.paymentTerms} markdownTextOnChange={setPaymentTerms} />
        </Card.Section>
      </Card>

      <Sheet
        open={bookingEngineState.editBookingFeeVisible}
        title={t("secure.facility.settings.tee_sheets.booking_engine.019")}
        size="medium"
        closable
        onCancel={closeEditBookingFee}
        onOk={saveBookingFee}
        cancelText={t("secure.facility.settings.tee_sheets.booking_engine.020")}
        okText={t("secure.facility.settings.tee_sheets.booking_engine.021")}
        backDropCancel={false}
        okDisabled={
          bookingEngineState.selectedBookingFee?.days_in_advance_start === "" ||
          bookingEngineState.selectedBookingFee?.days_in_advance_end === "" ||
          !newBookingFeeState?.selectedVariant
            ? true
            : false
        }
      >
        <FormLayout>
          <FormLayout.Group>
            <Input
              value={bookingEngineState.selectedBookingFee?.days_in_advance_start ?? ""}
              label={t("secure.facility.settings.tee_sheets.booking_engine.025")}
              id="days_in_advance_start"
              helpText={t("secure.facility.settings.tee_sheets.booking_engine.026")}
              onChange={handleBookingFeeInputChange}
              suffix="days"
              placeholder={t("secure.facility.settings.tee_sheets.booking_engine.027")}
              type="number"
            />
            <Input
              value={bookingEngineState.selectedBookingFee?.days_in_advance_end ?? ""}
              label={t("secure.facility.settings.tee_sheets.booking_engine.028")}
              id="days_in_advance_end"
              helpText={t("secure.facility.settings.tee_sheets.booking_engine.029")}
              onChange={handleBookingFeeInputChange}
              suffix="days"
              placeholder={t("secure.facility.settings.tee_sheets.booking_engine.030")}
              type="number"
            />
          </FormLayout.Group>
        </FormLayout>
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center gap-8">
            <Button
              size="medium"
              type="secondary"
              onClick={() => setNewBookingFeeState(prevState => ({ ...prevState, showProductModal: true }))}
            >
              {t("secure.facility.settings.tee_sheets.booking_engine.047")}
            </Button>
            {newBookingFeeState?.selectedVariant && (
              <div className="booking-engine-variant-badge-container">
                <Badge type="gray" size="medium">
                  <span>
                    {newBookingFeeState?.selectedProduct?.preferred_title
                      ? newBookingFeeState?.selectedProduct?.preferred_title
                      : newBookingFeeState?.selectedProduct?.title}{" "}
                    {newBookingFeeState?.selectedProduct &&
                    newBookingFeeState?.selectedVariant?.title !== newBookingFeeState?.selectedProduct?.title
                      ? ` - ${newBookingFeeState?.selectedVariant?.title as string}`
                      : newBookingFeeState?.selectedVariant?.title !== newBookingFeeState?.selectedProduct?.title
                      ? newBookingFeeState?.selectedVariant?.title
                      : ""}{" "}
                    <LocaleCurrency amount={newBookingFeeState?.selectedVariant?.price} />
                  </span>
                </Badge>
                <FontAwesomeIcon
                  size="1x"
                  icon={["far", "xmark"]}
                  onClick={() =>
                    setNewBookingFeeState(prevState => ({
                      ...prevState,
                      selectedProduct: null,
                      selectedVariant: null,
                    }))
                  }
                  className="booking-engine-remove-variant"
                />
              </div>
            )}
          </div>
          <Checkbox
            id="non_refundable"
            size="medium"
            value={bookingEngineState.selectedBookingFee?.non_refundable ?? false}
            checked={bookingEngineState.selectedBookingFee?.non_refundable ?? false}
            onChange={handleBookingFeeCheckboxChange}
            label={t("secure.facility.settings.tee_sheets.booking_engine.048")}
          />
        </div>
      </Sheet>

      <Sheet
        open={newBookingFeeState.showBookingFeeModal}
        title={t("secure.facility.settings.tee_sheets.booking_engine.049")}
        size="medium"
        closable
        onCancel={closeNewBookingFee}
        onOk={createBookingFee}
        cancelText={t("secure.facility.settings.tee_sheets.booking_engine.050")}
        okText={t("secure.facility.settings.tee_sheets.booking_engine.051")}
        okDisabled={
          newBookingFeeState?.daysInAdvanceStart === "" ||
          newBookingFeeState?.daysInAdvanceEnd === "" ||
          !newBookingFeeState?.selectedVariant
            ? true
            : false
        }
      >
        <FormLayout>
          <FormLayout.Group>
            <Input
              value={newBookingFeeState?.daysInAdvanceStart ?? ""}
              label={t("secure.facility.settings.tee_sheets.booking_engine.025")}
              id="daysInAdvanceStart"
              helpText={t("secure.facility.settings.tee_sheets.booking_engine.026")}
              type="number"
              onChange={handleNewBookingFeeInputChange}
              suffix="days"
              placeholder={t("secure.facility.settings.tee_sheets.booking_engine.027")}
            />
            <Input
              value={newBookingFeeState?.daysInAdvanceEnd ?? ""}
              label={t("secure.facility.settings.tee_sheets.booking_engine.028")}
              id="daysInAdvanceEnd"
              helpText={t("secure.facility.settings.tee_sheets.booking_engine.029")}
              type="number"
              onChange={handleNewBookingFeeInputChange}
              suffix="days"
              placeholder={t("secure.facility.settings.tee_sheets.booking_engine.030")}
            />
          </FormLayout.Group>
        </FormLayout>
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center gap-8">
            <Button
              size="medium"
              type="secondary"
              onClick={() => setNewBookingFeeState(prevState => ({ ...prevState, showProductModal: true }))}
            >
              {t("secure.facility.settings.tee_sheets.booking_engine.052")}
            </Button>
            {newBookingFeeState?.selectedProduct && newBookingFeeState?.selectedVariant && (
              <div className="booking-engine-variant-badge-container">
                <Badge type="gray" size="medium">
                  <span>
                    {newBookingFeeState?.selectedProduct?.preferred_title
                      ? newBookingFeeState?.selectedProduct?.preferred_title
                      : newBookingFeeState?.selectedProduct?.title}{" "}
                    {newBookingFeeState?.selectedVariant?.title !== newBookingFeeState?.selectedProduct?.title
                      ? ` - ${newBookingFeeState?.selectedVariant?.title as string}`
                      : ""}{" "}
                    <LocaleCurrency amount={newBookingFeeState?.selectedVariant?.price} />
                  </span>
                </Badge>
                <FontAwesomeIcon
                  size="1x"
                  icon={["far", "xmark"]}
                  onClick={() =>
                    setNewBookingFeeState(prevState => ({
                      ...prevState,
                      selectedProduct: null,
                      selectedVariant: null,
                    }))
                  }
                  className="booking-engine-remove-variant"
                />
              </div>
            )}
          </div>
          <Checkbox
            id="nonRefundable"
            size="medium"
            value={newBookingFeeState?.nonRefundable ?? false}
            checked={newBookingFeeState?.nonRefundable ?? false}
            onChange={handleNewBookingFeeCheckboxChange}
            label={t("secure.facility.settings.tee_sheets.booking_engine.053")}
          />
        </div>
      </Sheet>

      <Sheet
        open={newBookingFeeState.showProductModal}
        title={t("secure.facility.settings.tee_sheets.booking_engine.054")}
        size="medium"
        closable
        onCancel={() =>
          setNewBookingFeeState(prevState => ({
            ...prevState,
            selectedVariant: null,
            selectedProduct: null,
            showProductModal: false,
          }))
        }
        onOk={() => setNewBookingFeeState(prevState => ({ ...prevState, showProductModal: false }))}
        cancelText={t("secure.facility.settings.tee_sheets.booking_engine.055")}
        okText={t("secure.facility.settings.tee_sheets.booking_engine.056")}
        okDisabled={!newBookingFeeState?.selectedVariant ? true : false}
        stacked
      >
        <Input
          value={newBookingFeeState.productSearchQuery}
          onChange={handleNewBookingFeeInputChange}
          type="search"
          id="productSearchQuery"
          placeholder={t("secure.facility.settings.tee_sheets.booking_engine.057")}
        />

        <div className="booking-engine-product-header">
          <div className="booking-engine-product-title">
            {t("secure.facility.settings.tee_sheets.booking_engine.058")}
          </div>
          <div className="booking-engine-price-title">
            {t("secure.facility.settings.tee_sheets.booking_engine.059")}
          </div>
        </div>
        <div className="booking-engine-products-container">
          {newBookingFeeState.products?.map((product, productIndex) => {
            return (
              <div
                key={productIndex}
                onClick={product?.variant_count === 1 ? () => selectVariant(product?.variants[0], product) : undefined}
                className={classNames("booking-engine-product-list-item", {
                  "booking-engine-product-list-item-hover": product?.variant_count === 1,
                  "booking-engine-product-list-item-selected":
                    product?.variant_count === 1 && product?.variants[0]?.id === newBookingFeeState.selectedVariant?.id
                      ? true
                      : false,
                })}
              >
                <div className="font-normal">
                  {product?.preferred_title ? product?.preferred_title : product?.title}
                </div>
                {product?.variant_count === 1 && <LocaleCurrency amount={product?.variants[0]?.price} />}
                {product?.variant_count > 1 && (
                  <>
                    <div className="booking-engine-dashed-border"></div>
                    {product?.variants?.map((variant: Record<string, any>, variantIndex: number) => {
                      return (
                        <div
                          key={variantIndex}
                          onClick={() => selectVariant(variant, product)}
                          className={classNames(
                            "booking-engine-variant-list-item booking-engine-product-list-item-hover",
                            {
                              "booking-engine-product-list-item-selected":
                                newBookingFeeState.selectedVariant?.id === variant?.id,
                            },
                          )}
                        >
                          <div className="font-normal">{variant?.title}</div>
                          <LocaleCurrency amount={variant?.price} />
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            );
          })}
        </div>
      </Sheet>

      <Popup
        open={bookingEngineState?.deleteBookingFeePopup}
        type="warning"
        title="Delete Booking Fee?"
        description="Are you sure you want to delete the selected booking fee?"
        onCancel={() =>
          setBookingEngineState(prevState => ({
            ...prevState,
            deleteBookingFeePopup: false,
            selectedBookingFeeId: null,
          }))
        }
        onOk={handleRemoveBookingFee}
      />
    </Page>
  );
}
