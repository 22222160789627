import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { IFacility } from "redux/reducers/models/facility";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/facility";

// GET TeeDecks
export async function GetTeeDecks(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseUrl + "/tee-deck", params, useGlobalLoader);
}

// GET Divisions
export async function GetDivision(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseUrl + "/division", params, useGlobalLoader);
}

// POST Divisions
export async function PostDivision(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/division", params, useGlobalLoader);
}

// PUT Divisions
export async function PutDivision(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/division", params, useGlobalLoader);
}
