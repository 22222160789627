import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { StatusCode } from "api/protocols";
import { IGiftCard, MergeGiftCards } from "api/rpc/2022-09/facilityAdmin/payment/giftCard";

import { showError } from "redux/actions/ui";

import { useAppDispatch } from "hooks/redux";
import { LocaleCurrency } from "helpers/Locale";
import { capitalize, displayCurrency } from "helpers/Helpers";

import { ButtonNew as Button } from "components/buttonNew";
import Spin from "components/spin/spin";
import Checkbox from "components/form/checkbox/Checkbox";
import Sheet from "components/sheet/Sheet";
import { Select } from "components/select/index";
import Popup from "components/popup/Popup";
import { Badge } from "components/badge/Badge";

import "./giftCardMergeTable.scss";

type GiftCardMergeTableProps = {
  giftCards: IGiftCard[];
  /** Returns the updated parent gift card. */
  mergeActionCallback?: (giftCard: IGiftCard) => void;
  /** Optional functionality for clicking a gift card row. */
  tableRowClick?: (giftCard: IGiftCard) => void;

  /** Displays Footer.  Gives access to table pagination. */
  footer?: false;
};

type GiftCardMergeTablePropsWithFooter = {
  giftCards: IGiftCard[];
  /** Returns the updated parent gift card. */
  mergeActionCallback?: (giftCard: IGiftCard) => void;
  /** Optional functionality for clicking a gift card row. */
  tableRowClick?: (giftCard: IGiftCard) => void;

  /** Gives access to table pagination. */
  footer: true;
  tableLimit: number;
  tableOffset: number;
  /** Allow parent to handle gift card pagination. Called when table footer buttons clicked. */
  handleTableOffset: (direction: "prev" | "next") => void;
};

const TABLE_COLUMN_COUNT = 5;

export default function GiftCardMergeTable(props: GiftCardMergeTableProps | GiftCardMergeTablePropsWithFooter) {
  const { Option } = Select;
  const dispatch = useAppDispatch();

  const [checkboxState, setCheckboxState] = useState<number[]>([]);
  const [mergeModal, setMergeModal] = useState({
    visible: false,
    parentId: null,
    confirmationPopup: false,
  });

  const checkedCards = props?.giftCards?.filter(card => checkboxState.includes(card.id) && card);

  /** @param [parent=false] Optional param to toggle every checkbox. */
  function handleChangeEvent(event: React.ChangeEvent<HTMLInputElement>, parent = false) {
    event.stopPropagation();

    const targetId = Number(event.target.value);

    // Title checkbox click.  Return early.
    if (parent) {
      if (!event.target.checked) {
        setCheckboxState([]);
      } else {
        const allProducts = props.giftCards
          .filter(value => !value.status.includes("disabled"))
          .map(card => card.id);
        setCheckboxState(allProducts);
      }
      return;
    }

    // Table Row checkbox click
    const updatedState: Array<number> = [...checkboxState];
    const foundIndex = checkboxState.indexOf(targetId);

    if (foundIndex > -1) {
      updatedState.splice(foundIndex, 1);
    } else {
      updatedState.push(targetId);
    }

    setCheckboxState(updatedState);
  }

  // TODO: Change into ASYNC function to handle API call
  async function mergeCreditCardBalances() {
    const params = {
      parent_gift_card_id: mergeModal.parentId,
      gift_card_ids: checkedCards
        .filter(value => value.id !== mergeModal.parentId) //remove the parent ID
        .map(card => card.id),
    };

    const res = await MergeGiftCards(params, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error merging credit card balances."));
      return;
    }

    setMergeModal({ visible: false, parentId: null, confirmationPopup: false });
    setCheckboxState([]);

    if (props.mergeActionCallback) {
      props.mergeActionCallback(res.data);
    }
  }

  function handleTableRowClick(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, card: IGiftCard) {
    // Only apply callback if TR itself is clicked
    if ((event.target as HTMLElement).nodeName === "TD") {
      props.tableRowClick(card);
    }
  }

  return (
    <div className="table-wrap">
      <table className="giftcard-table ui-table clickable">
        <colgroup>
          <col style={{ width: "10%" }} />
          <col style={{ width: "50%" }} />
          <col style={{ width: "20%" }} />
          <col style={{ width: "20%" }} />
        </colgroup>

        <thead>
          <tr>
            <th colSpan={TABLE_COLUMN_COUNT - 2} />
            <th colSpan={2}>
              <Button
                size="small"
                onClick={() => setMergeModal(prev => ({ ...prev, visible: true }))}
                disabled={!checkedCards || checkedCards?.length < 2}
                style={{ float: "right" }}
              >
                Merge Cards
              </Button>
            </th>
          </tr>
          <tr className="table-row-title">
            <th className="table-header-checkbox">
              {/* Only checked if all non-disabled boxes checked */}
              <Checkbox
                size="small"
                checked={
                  props?.giftCards?.length !== 0 &&
                  checkboxState.length !== 0 &&
                  checkboxState?.length ===
                    props?.giftCards?.filter(data => !data.status.includes("disabled"))?.length
                }
                onChange={event => handleChangeEvent(event, true)}
              />
            </th>
            <th className="table-header-giftcard">Gift Card No.</th>
            <th className="table-header-balance">Balance</th>
            <th className="table-header-currency">Currency</th>
            <th className="table-header-status">Status</th>
          </tr>
        </thead>

        <tbody>
          {props.giftCards ? (
            <>
              {props.giftCards.length !== 0 ? (
                <>
                  {props.giftCards.map(giftCard => {
                    return (
                      <tr
                        key={giftCard.id}
                        onClick={event => (props.tableRowClick ? handleTableRowClick(event, giftCard) : undefined)}
                      >
                        <td
                          id="checkbox"
                          className={`table-data-checkbox${giftCard.status.includes("disabled") ? " inactive" : ""}`}
                        >
                          <Checkbox
                            id={"checkbox_" + String(giftCard.id)}
                            size="small"
                            value={String(giftCard.id)}
                            checked={checkboxState.includes(giftCard.id) ?? false}
                            disabled={giftCard.status.includes("disabled")}
                            onChange={event => handleChangeEvent(event)}
                          />
                        </td>
                        <td className="table-data-giftCard">{`**** **** **** ${giftCard.last4}`}</td>
                        <td className="table-data-balance">
                          <LocaleCurrency currency={giftCard.currency} amount={giftCard.balance} />
                        </td>
                        <td className="table-data-currency">{giftCard.currency.toUpperCase()}</td>
                        <td className="table-data-status">
                          {giftCard.status.includes("disabled") ? (
                            <Badge type="warning">Invalid</Badge>
                          ) : (
                            <Badge type="success">{capitalize(giftCard.status)}</Badge>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </>
              ) : null}
            </>
          ) : (
            <tr id="gift-card-table-spinner">
              <td colSpan={TABLE_COLUMN_COUNT} height={"75px"} style={{ pointerEvents: "none" }}>
                <Spin />
              </td>
            </tr>
          )}
        </tbody>

        {props.footer && (
          <tfoot>
            <tr>
              <td colSpan={2}>
                <Button
                  size="small"
                  type="secondary"
                  onClick={() => {
                    props.handleTableOffset("prev");
                  }}
                  disabled={!(props.tableLimit <= props.tableOffset)}
                >
                  <FontAwesomeIcon icon={"arrow-left"} />
                  &nbsp;
                  {"Previous"}
                </Button>
              </td>

              <td colSpan={TABLE_COLUMN_COUNT - 4} />

              <td colSpan={2}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    size="small"
                    type="secondary"
                    onClick={() => {
                      props.handleTableOffset("next");
                    }}
                    disabled={!(props.giftCards.length === props.tableLimit)}
                  >
                    {"Next"}
                    &nbsp;
                    <FontAwesomeIcon icon={"arrow-right"} />
                  </Button>
                </div>
              </td>
            </tr>
          </tfoot>
        )}
      </table>

      {/** Merge Modal + Popup */}
      {mergeModal.visible && (
        <Sheet
          open={mergeModal.visible}
          title="Choose The Gift Card Parent"
          size="small"
          okText={`Merge ${calculateSumOfValues(checkedCards)}`}
          onOk={() => setMergeModal(prev => ({ ...prev, confirmationPopup: true }))}
          okDisabled={mergeModal.parentId === null || mergeModal.parentId === -1 || mergeModal.confirmationPopup} // Limit Modal use when confirmation popup is open
          onCancel={() => setMergeModal({ visible: false, parentId: -1, confirmationPopup: false })}
          cancelDisabled={mergeModal.confirmationPopup} // Limit Modal use when confirmation popup is open
          cancelText="Cancel"
        >
          <Select
            onChange={(value: number) => setMergeModal(prev => ({ ...prev, parentId: value }))}
            defaultValue={-1}
            className="parent-select"
          >
            <Option value={-1}>Select Parent Card</Option>

            {checkedCards &&
              checkedCards.map((card, index) => {
                return (
                  <Option key={card.id} value={card.id} name={card.last4}>
                    **** **** **** {card.last4}
                  </Option>
                );
              })}
          </Select>
        </Sheet>
      )}

      {/* Confirms the async Merge Credit Card call. */}
      <Popup
        open={mergeModal.confirmationPopup}
        type={"warning"}
        title={"Merging Gift Card Balances"}
        description={
          "Are you sure you want to load all the balances onto a single card?  This operation cannot be undone."
        }
        onOk={mergeCreditCardBalances}
        okText={"Merge Balances"}
        onCancel={() => setMergeModal(prev => ({ ...prev, confirmationPopup: false }))}
        cancelText={"Cancel"}
      />
    </div>
  );
}

/**
 * Returns the sum of the all gift card balances as a currency string.
 */
function calculateSumOfValues(checkedCards: IGiftCard[]) {
  if (!checkedCards[0].currency) {
    return "";
  }

  let sum = 0;
  checkedCards?.forEach(card => {
    sum += card.balance;
  });

  return displayCurrency(checkedCards[0].currency, sum);
}
