import React, { useEffect, useRef, useState } from "react";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import useOutsideAlerter from "hooks/useOutsideAlerter/useOutsideAlerter";
import "./splitButton.scss";

type TSplitButtonOption = {
  label: string;
  onClick: () => void;
  icon?: IconName;
  disabled?: boolean;
  hidden?: boolean;
};

export interface ISplitButtonProps {
  /**Function that runs when main button is clicked */
  onClick: () => void;
  /**Disable main button if set to true */
  disabled?: boolean;
  /**Disable chevron button if set to true */
  disabledDropdown?: boolean;
  /**Makes main button full width if set to true */
  block?: boolean;
  /**Align dropdown position (left, right or center) */
  align?: "left" | "right" | "center";
  /**Component styling. Primary: Blue. Secondary: White */
  type?: "primary" | "secondary";
  /**The text displayed within the main button */
  label?: string;
  /**Dropdown options */
  options: Array<TSplitButtonOption>;
  /**Content within the main button */
  children?: React.ReactNode;
}

export interface ISplitButtonState {
  dropdownOpen: boolean;
}

/**
 * Split button component that offers more clickable options through a dropdown
 * @param {ISplitButtonProps} props
 * @param {() => void} props.onClick Function that runs when main button is clicked
 * @param {boolean} props.disabled Disable main button if set to true `(optional)`
 * @param {boolean} props.disabledDropdown Disable chevron button if set to true `(optional)`
 * @param {boolean} props.block Makes main button full width if set to true `(optional)`
 * @param {"left" | "right" | "center"} props.alignAlign Align dropdown position (left, right or center). Default value is right `(optional)`
 * @param {"primary" | "secondary"} props.type Component styling. Primary: Blue. Secondary: White . Default value is primary`(optional)`
 * @param {string} props.label The text displayed within the main button
 * @param { Array<TSplitButtonOption>} props.options Dropdown options
 *
 * @returns {JSX.Element}
 */
export default function SplitButton(props: ISplitButtonProps): JSX.Element {
  const {
    onClick,
    disabled = false,
    block,
    align = "right",
    type = "primary",
    options,
    disabledDropdown = false,
    label,
    children,
  } = props;
  const container = useRef();
  const [state, setState] = useState<ISplitButtonState>({
    dropdownOpen: false,
  });

  function handleMainButtonClick() {
    if (state.dropdownOpen) {
      setState(prevState => ({ ...prevState, dropdownOpen: false }));
    }
    void onClick();
  }

  function handleDropdownClick(optionClick: () => void) {
    setState(prevState => ({ ...prevState, dropdownOpen: false }));
    void optionClick();
  }

  useOutsideAlerter(container, () => {
    setState(prevState => ({ ...prevState, dropdownOpen: false }));
  });

  return (
    <div ref={container} className={classNames("split-button", { "split-button-block": block })}>
      <button
        disabled={disabled}
        className={classNames(`main-button button-base  split-button-${type}`)}
        onClick={handleMainButtonClick}
      >
        {label && label}
        {children}
      </button>
      <div
        onClick={
          !disabledDropdown
            ? () => setState(prevState => ({ ...prevState, dropdownOpen: !prevState.dropdownOpen }))
            : undefined
        }
        className={classNames(`button-base chevron-button split-button-${type} `, {
          "chevron-button-disabled": disabledDropdown,
        })}
      >
        <FontAwesomeIcon icon={["far", "chevron-down"]} />
      </div>
      <div
        className={classNames(`chevron-button-dropdown chevron-button-dropdown-${align}`, {
          "chevron-button-dropdown-display": state.dropdownOpen,
        })}
      >
        {options
          ?.filter(filteredOption => !filteredOption.hidden)
          ?.map((option, index) => {
            return (
              <button
                key={index}
                className="chevron-button-dropdown-item"
                disabled={option.disabled}
                onClick={() => handleDropdownClick(option.onClick)}
              >
                {option?.icon && <FontAwesomeIcon className="chevron-button-content" icon={["far", option.icon]} />}
                <p className="chevron-button-content">{option?.label}</p>
              </button>
            );
          })}
      </div>
    </div>
  );
}
