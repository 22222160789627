import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { PostTournamentTeam, TPostTournamentTeam } from "api/rpc/2024-04/facilityAdmin/tournament/scoring/teams";

import { showError, showSuccess } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";
import { handleChangeEventInput } from "helpers/Helpers";

import { IPrimaryPageAction } from "components/page/PageActions";
import Page from "components/page/Page";
import Card from "components/card/Card";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";

export default function TournamentTeamNew() {
  const history = useHistory();
  const { tournamentId } = useParams<{ tournamentId: string }>();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [team, setTeam] = useState<Omit<TPostTournamentTeam, "tournament_id">>({
    name: "",
  });

  async function createTeam(params: TPostTournamentTeam) {
    const res = await PostTournamentTeam(params, true);
    console.log(res); // DEBUG

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error creating new team")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Successfully created new team")); // TODO: Translation
    history.push(`/admin/tournament/${tournamentId}/teams`);
  }

  const primaryAction: IPrimaryPageAction = {
    content: t("secure.facility.league.new.008"),
    action: () => createTeam({ tournament_id: tournamentId, ...team }),
    disabled: !team.name,
  };

  return (
    <Page
      title={"New Team"} // TODO: Translation
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        { prefix: true, label: "Teams", /* TODO: Translation */ url: `/admin/tournament/${tournamentId}/teams` },
      ]}
    >
      <Form>
        <Card title={"Team Details"} /* TODO: Translation */>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  id="name"
                  value={team.name}
                  onChange={e => handleChangeEventInput(e, team, setTeam)}
                  label={t("secure.facility.league.new.012")}
                  placeholder={t("secure.facility.league.new.012")}
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>
      </Form>
    </Page>
  );
}
