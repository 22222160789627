import React, { useEffect, useState } from "react";
import "./reservationsLogin.scss";
import { useHistory, useParams } from "react-router";
import { StatusCode } from "api/protocols";
import Input from "components/form/input";
import { ButtonNew } from "components/buttonNew";
import { IUIActions } from "redux/actions/ui";
import Popup from "components/popup/Popup";
import { IReservationState } from "redux/reducers/reservations";
import { PostPasswordResetCode } from "api/rpc/2022-09/passwordReset";
import { GetFacility } from "api/rpc/guest/facility";

interface IRecoveryState {
  email: string;
  successPopupVisibility: boolean;
}

interface IProps {
  uiActions: IUIActions;
  reservationStore: IReservationState;
}
interface IParams {
  facilityShortName: string;
  moduleHandle: string;
}

export default function ReservationsLogin(props: IProps) {
  const history = useHistory();
  const { facilityShortName, moduleHandle } = useParams<IParams>();
  const { uiActions, reservationStore } = props;

  useEffect(() => {
    void getFacility();
  }, []);

  const [facility, setFacility] = useState<Record<string, any>>(null);

  const [recoveryState, setRecoveryState] = useState<IRecoveryState>({
    email: "",
    successPopupVisibility: false,
  });

  async function getFacility() {
    if (facilityShortName) {
      const facilityRes = await GetFacility({ short_name: facilityShortName }, true);
      if (facilityRes.status !== StatusCode.OK) {
        uiActions.showError("Error loading facility");
        return;
      }
      setFacility(facilityRes?.data[0]);
    }
  }

  function handleEnterKeydown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      void sendPasswordRecovery();
    }
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;
    setRecoveryState(prevState => ({ ...prevState, [id]: value }));
  }

  async function sendPasswordRecovery() {
    const resetRes = await PostPasswordResetCode(
      { email: recoveryState.email, facility_id: facility?.id ?? undefined },
      true,
    );

    if (resetRes.status === StatusCode.OK) {
      setRecoveryState(prevState => ({ ...prevState, successPopupVisibility: true }));
    } else {
      uiActions.showError(resetRes.data?.message);
      return;
    }
  }

  function handleEmailSuccess() {
    window.close();
  }

  return (
    <>
      <div className="reservations-login">
        <div className="reservations-login-header-container">
          <div className="reservations-login-header-container-centered">
            <div className="reservations-login-header-container-inner">
              <img src="../../../public/images/Tee-On.png" alt="Tee-On Logo" />
            </div>
          </div>
        </div>

        <div className="reservations-login-container">
          <div className="reservations-login-container-inner">
            <div className="reservations-login-container-group">
              <div className="reservations-login-text-group">
                <p className="sign-in-text">Account Recovery</p>
                <br />
                <p className="sign-in-info">Please enter email address for account recovery</p>
              </div>

              <div className="reservations-login-input-group">
                <label className="reservations-login-input-label" htmlFor="username">
                  Email Address
                </label>
                <Input
                  value={recoveryState.email}
                  onChange={handleInputChange}
                  type="email"
                  labelHidden
                  onKeyDown={handleEnterKeydown}
                  id="email"
                />
              </div>
            </div>
            <br />
            <br />
            <div>
              <ButtonNew type="primary" block onClick={sendPasswordRecovery} className="reservations-register-button">
                SEND
              </ButtonNew>
            </div>
          </div>
        </div>
      </div>

      <Popup
        open={recoveryState.successPopupVisibility}
        type="success"
        title="Email Sent Successfully"
        description="Check your inbox or spam folder"
        onOk={handleEmailSuccess}
        okText="Back to login"
      />
    </>
  );
}
