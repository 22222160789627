import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { GetBookingEngine } from "api/rpc/2022-09/guest/bookingEngine";
import { GetFacilityGuest } from "api/rpc/2022-09/guest/facility";
import { StatusCode } from "api/protocols";
import { useAppDispatch } from "hooks/redux";
import { showError } from "redux/actions/ui";
import { IBookingEngine } from "redux/reducers/bookingEngine";
import "./landing.scss";
import { IFacility } from "redux/reducers/models/facility";
interface IParams {
  facilityShortName: string;
}

interface IState {
  facility: IFacility;
  bookingEngines: Array<IBookingEngine>;
}

export default function Landing(props: any) {
  const [state, setState] = useState<IState>({
    facility: null,
    bookingEngines: [],
  });
  const history = useHistory();
  const { facilityShortName } = useParams<IParams>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    void loadBookingEngines();
  }, []);

  async function loadBookingEngines() {
    const facilityRes = await GetFacilityGuest({ short_name: facilityShortName }, true);
    if (facilityRes.status !== StatusCode.OK) {
      dispatch(showError("Error getting facility information"));
    }
    if (facilityRes?.data?.length > 0) {
      const bookingEngineRes = await GetBookingEngine({ facility_id: facilityRes?.data[0]?.id }, true);
      if (bookingEngineRes.status !== StatusCode.OK) {
        dispatch(showError("Error getting booking engines"));
      }

      setState(prevState => ({
        ...prevState,
        facility: facilityRes?.data[0],
        bookingEngines: bookingEngineRes?.data,
      }));
    }
  }

  function navigateToBookingEngine(bookingEngineHandle: string) {
    if (bookingEngineHandle) {
      history.push(`/booking-engine/${facilityShortName}/${bookingEngineHandle}`);
    }
  }

  return (
    <div className="booking-engine-landing">
      {state?.facility && (
        <div className="facility-container">
          <span className="facility-logo">
            {state?.facility?.logo_source && (
              <img className="facility-logo" src={state?.facility?.logo_source} alt="Facility Logo" />
            )}
          </span>
          <div className="facility-address-container">
            <p className="facility-name">{state?.facility?.long_name}</p>
            <p className="facility-address">{`${state?.facility?.address_line_1}, ${state?.facility?.city}, ${state?.facility?.province_name}, ${state?.facility?.postal}`}</p>
            {state?.facility?.address_line_2 && (
              <p className="facility-address">{`${state?.facility?.address_line_2}, ${state?.facility?.city}, ${state?.facility?.province_name}, ${state?.facility?.postal}`}</p>
            )}
          </div>
        </div>
      )}

      {state?.bookingEngines?.length > 0 && (
        <div className="booking-engines-container">
          {state?.bookingEngines?.map((bookingEngine, index) => (
            <div
              key={index}
              className="booking-engines-card"
              onClick={() => navigateToBookingEngine(bookingEngine?.handle)}
            >
              <p className="booking-engine-name">{bookingEngine?.title}</p>
              <button className="select-button">Select</button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
