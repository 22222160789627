import React, { useEffect, useState, useCallback } from "react";
import { useHistory, Link } from "react-router-dom";
import { useParams } from "react-router";

import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import { ButtonNew as Button } from "components/buttonNew";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import TextField from "components/form/textField/TextField";
import Select from "components/form/select/Select";
import Checkbox from "components/form/checkbox/Checkbox";
import { GetTournament, PutTournament } from "api/rpc/clientAdmin/tournament/tournament";

interface ITournamentState {
  id: number;
  name: string;
  start_time: string;
  date: Date;
  description: string;
}

export default function Settings(props: any) {
  const history = useHistory();
  const [state, setState] = useState<ITournamentState>({
    id: null,
    name: "",
    start_time: "",
    date: null,
    description: "",
  });

  const { tournamentId }: any = useParams();

  useEffect(() => {
    void loadTournament();
  }, []);

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    console.log(event);
    setState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleSaveTournament() {
    void saveTournament();
  }

  async function loadTournament() {
    const params = {
      id: tournamentId,
      extended: true,
    };

    const tournamentRes = await GetTournament(params, false);

    console.log(tournamentRes);
    if (tournamentRes.status !== StatusCode.OK) {
      return;
    }

    const tournament = tournamentRes.data[0];
    setState(prevState => ({
      ...prevState,
      id: tournament.id,
      name: tournament.name,
      start_time: tournament.start_time,
      date: tournament.date,
      description: tournament.description,
    }));

    return;
  }

  async function saveTournament() {
    console.log(state);
    const tournamentRes = await PutTournament(state, true);
    if (tournamentRes.status !== StatusCode.OK) {
      return;
    }

    void loadTournament();
  }

  const primaryAction = {
    content: "Save",
    action: handleSaveTournament,
  };

  return (
    <Page
      title="Registration Settings"
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[{ label: "Settings", url: "/admin/tournament/menu/" + String(state?.id) + "/settings" }]}
    >
      <div>Testing Registration Settings</div>
    </Page>
  );
}
