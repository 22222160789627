import { APIGet, APIPost, APIPut, APIDelete, StatusCode } from "api/protocols";
import { IConfiguration } from "api/rpc/clientAdmin/client/report";

const apiVersion = "2024-04";
const adminType = "client-admin";
const baseReportUrl = "/" + apiVersion + "/" + adminType + "/client/report";

//GET Report
interface IReport {
  id: number;
  title: string;
  code: string;
  configurations?: Array<IConfiguration>;
  client_id: string | null;
  category: string;
  sub_category: string | null;
  data: [];
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
}

interface IGetReport {
  id?: number;
  code?: string;
  search?: string;
  configurations?: Array<IConfiguration>;
}
interface IGetReportRes {
  data: IReport[];
  message: string;
  status: StatusCode;
}

//Retrive Report
export async function GetReport(params: IGetReport, useGlobalLoader: boolean): Promise<IGetReportRes> {
  return await APIGet(baseReportUrl, params, useGlobalLoader);
}

// Retrive Data for a Report
export async function GetReportRun(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseReportUrl + "/run", params, useGlobalLoader);
}
