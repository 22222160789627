import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { IAPIResponse, StatusCode } from "api/protocols";
import { GetCreditBook } from "api/rpc/2022-09/facilityAdmin/client/creditBook";

import { dequeue, enqueue, showError } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";
import { LocaleCurrency } from "helpers/Locale";

import Sheet from "components/sheet/Sheet";
import Tabs from "components/tabs/Tabs";
import Checkbox from "components/form/checkbox/Checkbox";
import { IPrizeAccount } from "pages/secure/facility/settings/prizeAccounts/PrizeAccount";

interface IProps {
  /**Boolean trigger for loading the customer's credit books */
  loadCreditBooks: boolean;
  customerId: number;
  onCancel: () => void;
  onOk: (selectedBooking: Record<string, any>) => void;
  /**User permission to allow the user to search */
  searchPermissions?: boolean;
}

interface IState {
  selectedBooking: IPrizeAccount;
  creditBookings: IPrizeAccount[];
  prizeAccounts: IPrizeAccount[];
  selectedTabIndex: number;
  modalVisible: boolean;
}

export default function CreditBookModal(props: IProps) {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const { loadCreditBooks, onCancel, onOk, customerId, searchPermissions = false } = props;
  const [state, setState] = useState<IState>({
    selectedBooking: null,
    creditBookings: [],
    prizeAccounts: [],
    selectedTabIndex: 0,
    modalVisible: false,
  });

  useEffect(() => {
    if (loadCreditBooks) {
      void loadCreditBookings();
    }
  }, [loadCreditBooks]);

  async function loadCreditBookings() {
    dispatch(enqueue());
    let creditBookingRes: IAPIResponse<Array<IPrizeAccount>> = null;
    if (customerId) {
      creditBookingRes = await GetCreditBook({ customer_id: customerId }, false);
    }
    const prizeAccountRes = await GetCreditBook({ type: "prize_account" }, false);

    if (prizeAccountRes.status !== StatusCode.OK || (creditBookingRes && creditBookingRes?.status !== StatusCode.OK)) {
      dispatch(showError(t("components.accounts_modal.accounts_modal.002")));
      dispatch(dequeue());
      return;
    }

    if (
      creditBookingRes &&
      creditBookingRes?.data?.length === 0 &&
      prizeAccountRes.data.length === 0 &&
      !searchPermissions
    ) {
      // Do not open modal, provide onCancel callback
      dispatch(dequeue());
      dispatch(showError(t("components.accounts_modal.accounts_modal.003")));
      void onCancel();
      return;
    } else {
      // Open modal
      setState(prevState => ({
        ...prevState,
        creditBookings: creditBookingRes ? creditBookingRes?.data?.filter(val => val?.type === "credit_book") : [],
        prizeAccounts: prizeAccountRes?.data,
        modalVisible: true,
      }));
    }
    dispatch(dequeue());
  }

  function handleTabChange(selectedTabIndex: number) {
    setState(prevState => ({ ...prevState, selectedTabIndex, selectedBooking: null, searchedBookings: [] }));
  }

  function selectBooking(booking: IPrizeAccount) {
    setState(prevState => ({ ...prevState, selectedBooking: booking }));
  }

  function handleClose() {
    void onCancel();
    setState(prevState => ({
      ...prevState,
      selectedBooking: null,
      selectedTabIndex: 0,
      creditBookings: [],
      modalVisible: false,
      searchValue: null,
      searchedBookings: [],
    }));
  }

  function handleOk() {
    void onOk(state.selectedBooking);
    setState(prevState => ({
      ...prevState,
      selectedBooking: null,
      selectedTabIndex: 0,
      creditBookings: [],
      modalVisible: false,
      searchValue: null,
      searchedBookings: [],
    }));
  }

  const modalTabs = [
    {
      id: "customer-credit_booking",
      content: "Credit Books", // TODO: Translation
    },
    {
      id: "prize_account-credit_booking",
      content: "Prize Accounts", // TODO: Translation
    },
  ];

  return (
    <Sheet
      title={t("components.accounts_modal.accounts_modal.006")}
      open={state.modalVisible}
      size="small"
      closable
      onCancel={handleClose}
      onOk={handleOk}
      okText={t("components.accounts_modal.accounts_modal.007")}
      okDisabled={!state.selectedBooking}
      stacked
    >
      <Tabs tabs={modalTabs} selected={state.selectedTabIndex} onSelect={handleTabChange} />
      {/* Customer Credit Books */}
      {state.selectedTabIndex === 0 && (
        <div className="ui-checkbox-group" style={{ minHeight: "122px" }}>
          <br />
          {state.creditBookings.map((booking, i) => {
            return (
              <div
                key={i}
                onClick={() => selectBooking(booking)}
                className={classNames("ui-checkbox-group-item", {
                  "ui-checkbox-group-item_selected": state.selectedBooking?.id === booking.id,
                })}
              >
                <div className="ui-checkbox-group-item-content">
                  <div className="ui-checkbox-group-item-text">
                    <p className="text-semibold">{booking.title}</p>
                    <p>
                      Balance: <LocaleCurrency amount={booking.balance} currency={booking.currency} />
                    </p>
                  </div>
                </div>
                <Checkbox
                  size="medium"
                  value={booking.id}
                  checked={state.selectedBooking?.id === booking.id}
                  onChange={() => selectBooking(booking)}
                />
              </div>
            );
          })}
        </div>
      )}
      {/* Prize Accounts */}
      {state.selectedTabIndex === 1 && (
        <div className="ui-checkbox-group" style={{ minHeight: "122px" }}>
          <br />
          {state.prizeAccounts.map((account, i) => {
            return (
              <div
                key={i}
                onClick={() => selectBooking(account)}
                className={classNames("ui-checkbox-group-item", {
                  "ui-checkbox-group-item_selected": state.selectedBooking?.id === account.id,
                })}
              >
                <div className="ui-checkbox-group-item-content">
                  <div className="ui-checkbox-group-item-text">
                    <p className="text-semibold">{account.title}</p>
                    <p>
                      Balance: <LocaleCurrency amount={account.balance} currency={account.currency} />
                    </p>
                  </div>
                </div>
                <Checkbox
                  size="medium"
                  value={account.id}
                  checked={state.selectedBooking?.id === account.id}
                  onChange={() => selectBooking(account)}
                />
              </div>
            );
          })}
        </div>
      )}
    </Sheet>
  );
}
