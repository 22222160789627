import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import Page from "components/page/Page";
import { GetDepartments, PutDepartments, DeleteDepartments } from "api/rpc/facility";
import { StatusCode } from "api/protocols";
import Input from "components/form/input/Input";
import Card from "components/card/Card";
import Sheet from "components/sheet/Sheet";
import { Select } from "components/select/index";

import { useTranslation } from "react-i18next";
import Popup from "components/popup/Popup";
import { isEqualWith } from "lodash";

interface IDepartmentState {
  id: number;
  title: string;
  type: string;
  parent_id: number;
}

export default function DepartmentsEdit(props: any) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { Option } = Select;
  const { id } = useParams<{ id: string }>();
  const [departmentState, setDepartmentState] = useState<IDepartmentState>({
    id: null,
    title: "",
    type: "",
    parent_id: null,
  });
  const [allDepartState, setAllDepartState] = useState({
    department: [],
    dropDownValue: "Select New Parent Department",
    title: "",
  });
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);

  const [departmentBeforeChanges, setdepartmentBeforeChanges] = useState(undefined);
  const [departmentLoaded, setdepartmentLoaded] = useState<boolean>(false);

  useEffect(() => {
    void loadDepartments();

    return () => {
      setDepartmentState({ id: undefined, title: "", type: "", parent_id: null });
      setAllDepartState({ department: [], dropDownValue: "Select New Parent Department", title: "" });
    };
  }, []);

  async function loadDepartments() {
    const res = await GetDepartments(true);

    if (res.status !== StatusCode.OK) {
      return;
    }

    const currentDepartment = res.data.find((department: any) => department.id === Number(id));

    setAllDepartState(prev => ({
      ...prev,
      department: res.data,
      title: currentDepartment.title,
    }));

    setDepartmentState(prev => ({
      ...prev,
      id: currentDepartment.id,
      title: currentDepartment.title,
      type: currentDepartment.type,
      parent_id: currentDepartment.parent_id,
    }));

    setdepartmentLoaded(true);
  }

  async function saveDepartment() {
    const res = await PutDepartments(departmentState, true);

    if (res.status !== StatusCode.OK) {
      return;
    }

    history.push("/admin/settings/departments");
  }

  async function removeDepartment() {
    const res = await DeleteDepartments(departmentState, true);

    if (res.status !== StatusCode.OK) {
      return;
    }

    history.push("/admin/settings/departments");
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;
    setDepartmentState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleDeleteModalVisibility() {
    setDeleteModalVisibility(!deleteModalVisibility);
  }

  function handleDropDownChange(value: any, property: string) {
    setDepartmentState(prev => ({
      ...prev,
      [property]: value,
    }));
  }

  const primaryAction = {
    content: t("secure.facility.settings.departments.departments_edit.001"),
    action: saveDepartment,
  };

  const secondaryAction = {
    content: t("secure.facility.settings.departments.departments_edit.002"),
    action: handleDeleteModalVisibility,
  };

  function unsavedChangesExist() {
    if (departmentBeforeChanges === undefined) {
      if (departmentLoaded) {
        setdepartmentBeforeChanges(departmentState);
      }
      return false;
    }

    return !isEqualWith(departmentBeforeChanges, departmentState);
  }

  function cancelUnsavedChanges() {
    setDepartmentState(departmentBeforeChanges);
  }

  return (
    <div>
      <Page
        title={t("secure.facility.settings.departments.departments_edit.003") + allDepartState.title}
        narrow
        // primaryAction={primaryAction}
        secondaryActions={[secondaryAction]}
        breadcrumbs={[
          {
            prefix: true,
            label: t("secure.facility.settings.departments.departments_edit.004"),
            url: "/admin/settings/departments",
          },
        ]}
        notificationBarProps={{
          isVisible: unsavedChangesExist(),
          onAction: saveDepartment,
          onCancel: cancelUnsavedChanges,
        }}
      >
        <Card>
          <Card.Section>
            <Input
              value={departmentState.title}
              label={t("secure.facility.settings.departments.departments_edit.005")}
              id="title"
              onChange={handleInputChange}
              placeholder={t("secure.facility.settings.departments.departments_edit.006")}
            />
          </Card.Section>
          {departmentState.type !== "department" && (
            <Card.Section>
              <Select
                label={t("secure.facility.settings.departments.departments_edit.007")}
                onChange={(value: any) => handleDropDownChange(value, "parent_id")}
                defaultValue={departmentState.parent_id}
              >
                {allDepartState.department
                  ?.filter((dept: any) => dept.type === "department")
                  ?.map((department: any, index: any) => {
                    return departmentState.type === "category" ? (
                      <Option key={index} value={department.id} name={department.title}>
                        <span>{department.title}</span>
                      </Option>
                    ) : (
                      <React.Fragment key={index}>
                        <p style={{ padding: "8px 16px" }}>
                          <b>{department.title}</b>
                        </p>
                        {allDepartState.department
                          ?.filter((dept: any) => dept.parent_id === department.id)
                          .map((department: any, index: number) => {
                            return (
                              <Option key={index} value={department.id} name={department.title}>
                                {department.title}
                              </Option>
                            );
                          })}
                      </React.Fragment>
                    );
                  })}
              </Select>
            </Card.Section>
          )}
        </Card>
      </Page>

      <Popup
        open={deleteModalVisibility}
        type="warning"
        title={t("secure.facility.settings.departments.departments_edit.010")}
        description={
          t("secure.facility.settings.departments.departments_edit.011") +
          " categories, subcategories and " +
          t("secure.facility.settings.departments.departments_edit.012") +
          "\n" +
          t("secure.facility.settings.departments.departments_edit.013")
        }
        onOk={removeDepartment}
        okText={t("secure.facility.settings.departments.departments_edit.009")}
        onCancel={handleDeleteModalVisibility}
        backDropCancel={true}
      />
    </div>
  );
}
