import React, { useEffect, useState } from "react";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import {
  GetBookingCategories,
  PostBookingCategory,
  PutBookingCategory,
} from "api/rpc/2024-04/facilityAdmin/teesheet/bookingCategory";

import { showError, showSuccess } from "redux/actions/ui";
import { IBookingCategory } from "redux/reducers/models/teetime";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import Page from "components/page/Page";
import Input from "components/form/input/Input";
import Sheet from "components/sheet/Sheet";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

interface IBookingCategoriesState {
  categories: Array<IBookingCategory>;
  selectedCategory: IBookingCategory;
  categoryTitle: string;
  editBookingCategoryModalVisible: boolean;
  newBookingCategoryModalVisible: boolean;
}

export default function BookingCategories() {
  const dispatch = useAppDispatch();

  const { authStore } = useAppSelector(store => store);
  const permissions = authStore?.user?.permissions;

  const [state, setState] = useState<IBookingCategoriesState>({
    categories: undefined,
    selectedCategory: null,
    categoryTitle: "",
    editBookingCategoryModalVisible: false,
    newBookingCategoryModalVisible: false,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();

    void loadBookingCategories(source.token);

    return () => {
      source.cancel();
    };
  }, []);

  async function loadBookingCategories(token?: CancelToken) {
    const categoryRes = await GetBookingCategories(null, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (categoryRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading booking categories")); // TODO: Translation
      return;
    }

    setState(prevState => ({ ...prevState, categories: categoryRes.data }));
  }

  async function updateBookingCategory() {
    const categoryRes = await PutBookingCategory(
      {
        id: state.selectedCategory.id,
        title: state.categoryTitle,
      },
      true,
    );

    if (categoryRes.status !== StatusCode.OK) {
      dispatch(showError("Error updating booking category"));
      return;
    }

    dispatch(showSuccess("Booking category updated successfully"));
    setState(prevState => ({
      ...prevState,
      selectedCategory: null,
      categoryTitle: "",
      editBookingCategoryModalVisible: false,
    }));
    void loadBookingCategories();
  }

  async function createBookingCategory() {
    const categoryRes = await PostBookingCategory(
      {
        title: state.categoryTitle,
      },
      true,
    );

    if (categoryRes.status !== StatusCode.OK) {
      dispatch(showError("Error creating booking category")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Booking category created successfully")); // TODO: Translation
    setState(prevState => ({
      ...prevState,
      categoryTitle: "",
      newBookingCategoryModalVisible: false,
    }));
    void loadBookingCategories();
  }

  function handleEditBookingCategory(category: IBookingCategory) {
    setState(prevState => ({
      ...prevState,
      selectedCategory: category,
      categoryTitle: category.title,
      editBookingCategoryModalVisible: true,
    }));
  }

  function handleNewBookingCategory() {
    setState(prevState => ({
      ...prevState,
      categoryTitle: "",
      newBookingCategoryModalVisible: true,
    }));
  }

  function handleCancelEdit() {
    setState(prevState => ({
      ...prevState,
      selectedCategory: null,
      categoryTitle: "",
      editBookingCategoryModalVisible: false,
    }));
  }

  function handleCancelNew() {
    setState(prevState => ({
      ...prevState,
      categoryTitle: "",
      newBookingCategoryModalVisible: false,
    }));
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setState(prevState => ({ ...prevState, [id]: value }));
  }

  const primaryAction = {
    content: "New Booking Category", // TODO: Translation
    action: handleNewBookingCategory,
  };

  return (
    <Page
      title="Booking Categories" // TODO: Translation
      narrow
      primaryAction={permissions?.booking_category_create ? primaryAction : null}
    >
      <DataTable
        columns={[
          { label: "Title", width: "100%" }, // TODO: Translation
        ]}
        loading={state.categories === undefined}
      >
        {state.categories ? (
          <>
            {state.categories.map(category => {
              return (
                <tr key={category.id} onClick={() => handleEditBookingCategory(category)}>
                  <td>
                    <p className="table-cell-lead-text">{category.title}</p>
                  </td>
                </tr>
              );
            })}
          </>
        ) : null}
      </DataTable>

      <Sheet
        title="Edit Booking Category" // TODO: Translation
        open={state.editBookingCategoryModalVisible}
        size="small"
        onCancel={handleCancelEdit}
        onOk={updateBookingCategory}
        closable
        backDropCancel
        cancelText="Cancel"
        okText="Update" // TODO: Translation
      >
        <Input
          value={state.categoryTitle}
          label={"Title"}
          id="categoryTitle" // TODO: Translation
          onChange={handleInputChange}
        />
      </Sheet>

      <Sheet
        title="New Booking Category" // TODO: Translation
        open={state.newBookingCategoryModalVisible}
        size="small"
        onCancel={handleCancelNew}
        onOk={createBookingCategory}
        closable
        backDropCancel
        cancelText="Cancel"
        okText="Create" // TODO: Translation
      >
        <Input
          value={state.categoryTitle}
          label={"Title"} // TODO: Translation
          id="categoryTitle"
          onChange={handleInputChange}
        />
      </Sheet>
    </Page>
  );
}
