import { connect } from "react-redux";
import Products from "pages/secure/client/product/Products";

const mapStateToProps = (state: any) => {
  const { authStore } = state;
  return {
    authStore: { ...authStore },
  };
};

export default connect(mapStateToProps)(Products);
