import React, { createContext, useContext, useEffect, useState } from "react";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetRegisters } from "api/rpc/facilityAdmin/facility/register/register";
import { GetFacilityAdmin } from "api/rpc/facilityAdmin/client/admin";
import { GetCustomer } from "api/rpc/2024-04/facilityAdmin/customer/customer";
import { GetAccountingReference } from "api/rpc/2022-09/facilityAdmin/client/accountingReference";

import { CustomerContextType, CustomerContextState, BookingCountType } from "./contextTypes";
import { GetCustomerPaymentMethod } from "api/rpc/facilityAdmin/customer";
import { GetGiftCard } from "api/rpc/2022-09/facilityAdmin/payment/giftCard";
import { GetRainChecks } from "api/rpc/facilityAdmin/payment/rainCheck";
import { GetTotalBookings } from "api/rpc/2022-09/facilityAdmin/teesheet/booking";
import { GetPaymentOptions } from "api/rpc/2022-09/facilityAdmin/facility/facility";

const defaultState: CustomerContextType = {
  state: {
    customer: null,
    preferences: null,
    editState: null,
    notes: null,
    bookings: null,
    bookingCounts: [],
    memberships: null,
    reservationBookings: null,
    houseAccounts: null,
    selectedHouseAccount: null,
    accountTransactions: null,
    accountStatements: null, // assigning NULL disables HouseAccount inputs
    accountScheduledPayments: null,
    accountingReferenceTypes: null,
    paymentMethods: null,
    paymentAmount: "",
    giftCards: null,
    rainChecks: null,
    ticketStubs: null,
    countries: null,
    customerTypes: null,
    cartTypes: null,
    facilityAdminFilters: null,
    registerFilters: null,
    paymentOptionFilters: null,
  },
  updateState: null,
  reloadPaymentMethods: null,
  loadCustomer: null,
  refreshGiftCards: null,
};
/* ACTUAL CONTEXT */
const CustomerContext = createContext<CustomerContextType>(defaultState);

/* CONTEXT PROVIDER */
type CustomerProviderProps = {
  customerId: number;
  children: any;
};

export const useCustomerContext = () => useContext(CustomerContext);

/** Loads necessary customer data with Global-Loader based off ID passed.  This is data available on first-load, other state defined in specified tabs. */
export default function CustomerContextProvider(props: CustomerProviderProps) {
  const [state, setState] = useState<CustomerContextState>({ ...defaultState.state });

  function updateState(newCustomerState: Partial<CustomerContextState>) {
    setState((cur: CustomerContextState) => {
      return { ...cur, ...newCustomerState };
    });
  }

  useEffect(() => {
    const source = axios.CancelToken.source();

    void loadCustomer(props.customerId, source.token);
    void loadAccountingReferenceTypes(source.token);

    // Payment methods and cards
    void reloadPaymentMethods(props.customerId, false, source.token);
    void refreshGiftCards(props.customerId, false, source.token);
    void loadRainChecks(props.customerId, source.token);

    // Drop filters
    void loadRegisterFilters(source.token);
    void loadFacilityAdminFilters(source.token);
    void loadTotalBookings(props.customerId);
    void loadFacilityPaymentOptions(source.token);

    return () => {
      source.cancel();
    };
  }, []);

  async function loadCustomer(customerId: number, token?: CancelToken) {
    const customerRes = await GetCustomer(
      {
        id: customerId,
        memberships: true,
        preferences: true,
        "default-address-line": true,
        "address-lines": true,
      },
      true,
      token,
    );

    if (customerRes.status !== StatusCode.OK) {
      return;
    }

    let preferences = customerRes.data[0].preferences;

    // Set default preferences if preferences don't exist
    if (preferences === null) {
      preferences = {
        customer_id: customerId,
        marketing_email: false,
        booking_confirmation_email: true,
        booking_reminder_email: true,
        booking_update_email: true,
        booking_cancellation_email: true,
      };
    }

    console.log("Customer", customerRes.data[0]);

    updateState({
      customer: customerRes.data[0],
      preferences: preferences,
      editState: customerRes.data[0],
    });
  }

  async function loadAccountingReferenceTypes(token?: CancelToken) {
    const accountingResponse = await GetAccountingReference(
      {
        group: "accounts_receivable",
      },
      false,
      token,
    );

    if (accountingResponse.status !== StatusCode.OK) {
      return;
    }

    const accountingReferenceTypes: any[] = [];

    accountingReferenceTypes.push({ label: "None", value: 0 });

    if (accountingResponse?.data?.length !== undefined && accountingResponse.data.length > 0) {
      const accountingReferenceTypesResponse = accountingResponse.data.map((accountingReference: any) => {
        return {
          label: accountingReference.title ?? "",
          value: accountingReference.id ?? 0,
        };
      });

      accountingReferenceTypes.push(...accountingReferenceTypesResponse);
    }

    console.log("AccountingRefType ", accountingReferenceTypes);

    updateState({ accountingReferenceTypes: accountingReferenceTypes });
  }

  async function reloadPaymentMethods(customerId: any, useGlobalLoader = true, token?: CancelToken) {
    if (state.paymentMethods !== null) {
      updateState({ paymentMethods: null });
    }

    const cardRes = await GetCustomerPaymentMethod({ customer_id: customerId }, useGlobalLoader, token);
    if (cardRes.status !== StatusCode.OK) {
      return;
    }

    updateState({ paymentMethods: cardRes.data });
  }

  async function refreshGiftCards(customer_id: number, useGlobalLoader = false, token?: CancelToken) {
    if (state.giftCards !== null) {
      updateState({ giftCards: null });
    }

    const giftCardRes = await GetGiftCard({ customer_id: customer_id }, useGlobalLoader, token);
    if (giftCardRes.status !== StatusCode.OK) {
      return;
    }

    console.log("gift cards ", giftCardRes); //debug
    updateState({ giftCards: giftCardRes.data.reverse() }); // Newest displayed first
  }

  async function loadRainChecks(customer_id: number, token?: CancelToken) {
    if (state.rainChecks !== undefined) {
      updateState({ rainChecks: undefined });
    }

    const rainChecksRes = await GetRainChecks({ customer_id: customer_id }, token ? false : true, token);
    if (token && token.reason) {
      return;
    }
    if (rainChecksRes.status !== StatusCode.OK) {
      updateState({ rainChecks: [] });
      return;
    }

    updateState({ rainChecks: rainChecksRes.data });
  }

  async function loadRegisterFilters(token?: CancelToken) {
    const registersRes = await GetRegisters(null, false, token);
    if (registersRes.status !== StatusCode.OK) {
      console.log("Register error: ", registersRes?.message);
      return;
    }

    updateState({ registerFilters: registersRes.data });
  }

  async function loadFacilityAdminFilters(token?: CancelToken) {
    const adminsRes = await GetFacilityAdmin(null, false, token);
    if (adminsRes.status !== StatusCode.OK) {
      console.log("Admin error: ", adminsRes?.message);
      return;
    }

    updateState({ facilityAdminFilters: adminsRes.data });
  }

  async function loadTotalBookings(customerId: number, token?: CancelToken) {
    const bookingRes = await GetTotalBookings({ customer_id: customerId }, false, token);
    if (bookingRes.status !== StatusCode.OK) {
      console.log("Total bookings error");
      return;
    }

    const bookingCounts: Array<BookingCountType> = [];

    const keysByYear = Object.keys(bookingRes.data);

    keysByYear.map(year => {
      let total = 0;
      let noShow = 0;
      let weatherNoShow = 0;
      total += (bookingRes.data[year].checked ?? 0) + (bookingRes.data[year].unchecked ?? 0);
      noShow += (bookingRes.data[year].no_show_defer ?? 0) + (bookingRes.data[year].no_show_charge ?? 0);
      weatherNoShow += bookingRes.data[year].no_show_weather ?? 0;
      bookingCounts.push({
        year: year,
        count: total,
        no_shows: noShow,
        weather_no_shows: weatherNoShow,
      });
    });

    updateState({ bookingCounts: bookingCounts });
  }

  async function loadFacilityPaymentOptions(token?: CancelToken) {
    const res = await GetPaymentOptions(null, false, token);

    if (res.status !== StatusCode.OK) {
      console.log("options error", res?.message);
      return;
    }

    updateState({ paymentOptionFilters: res.data });
  }

  return (
    <CustomerContext.Provider value={{ state, updateState, reloadPaymentMethods, loadCustomer, refreshGiftCards }}>
      {props.children}
    </CustomerContext.Provider>
  );
}
