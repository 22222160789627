import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { UserType } from "redux/reducers/models/user";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/tee-sheet/notification";

export interface INotificationData {
  content: string;
  id: number;
  subject: string;
  tee_sheet_id: string;
  type: string;
  user_id: number;
  user_type: string;
}

interface INotificationRes {
  status: number;
  data: any;
}

interface IGetNotification {
  id: number;
}

interface IGetNotificationAll {
  date?: string;
  user_type?: string;
}

export interface IPostNotification {
  id?: number;
  date: string;
  course: string;
  subject: string;
  content: string;
  user_type: UserType | undefined;
  type: CustomerNotificationType | undefined;
  tee_sheet_id?: number | null;
  course_id?: number | null;
}

type CustomerNotificationType = "Admin" | "Customer";

interface IDeleteNotification {
  id: number;
}

//GET
export async function GetTeeSheetNotification(
  params: IGetNotification,
  useGlobalLoader: boolean,
): Promise<INotificationRes> {
  return await APIGet(baseUrl, params, useGlobalLoader);
}

export async function GetTeeSheetNotificationAll(
  params: IGetNotificationAll,
  useGlobalLoader: boolean,
): Promise<INotificationRes> {
  return await APIGet(baseUrl + "/all", params, useGlobalLoader);
}

//POST
export async function PostTeeSheetNotification(
  params: IPostNotification,
  useGlobalLoader: boolean,
): Promise<INotificationRes> {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

//PUT
export async function PutTeeSheetNotification(
  params: IPostNotification,
  useGlobalLoader: boolean,
): Promise<INotificationRes> {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

//DELETE
export async function DeleteTeeSheetNotification(
  params: IDeleteNotification,
  useGlobalLoader: boolean,
): Promise<INotificationRes> {
  return await APIDelete(baseUrl, params, useGlobalLoader);
}
