import React, { useEffect, useState } from "react";
import { valueToString, displayPercent, capitalize } from "helpers/Helpers";
import { useParams, useHistory } from "react-router";
import { useAppDispatch } from "hooks/redux";
import useModal from "hooks/modals/useModal";
import { showError, showSuccess } from "redux/actions/ui";
import moment from "moment";

import {
  GetInvoice,
  GetInvoiceLineItem,
  GetDownloadInvoice,
  PutVoidInvoice,
  PostDuplicateInvoice,
  PutApplyDeposit,
  GetInvoiceTransaction,
} from "api/rpc/2024-04/facilityAdmin/order/invoice";
import { GetTournament } from "api/rpc/2024-04/facilityAdmin/tournament/tournament";
import { GetEvent } from "api/rpc/2024-04/facilityAdmin/event/hospitalityEvent";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import { Badge } from "components/badge/Badge";
import { OverlayTrigger } from "react-bootstrap";
import Tooltip from "components/tooltip/Tooltip";
import InvoicePaymentModal from "./InvoicePaymentModal";
import { LocaleCurrency } from "helpers/Locale";
import { IInvoice, IInvoiceLineItem, IInvoiceTransaction } from "redux/reducers/models/order";
import { loadPaymentOptions } from "redux/actions/facility";
import Portal from "elements/Portal";
import Popup from "components/popup/Popup";
import { ButtonNew as Button } from "components/buttonNew";
import DepositCallout from "components/depositCallout/DepositCallout";

import "pages/secure/facility/order/order.scss";

export default function Invoice() {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [invoice, setInvoice] = useState<IInvoice>(undefined);
  const [parentInvoice, setParentInvoice] = useState<IInvoice>(undefined);
  const [invoiceLineItems, setInvoiceLineItems] = useState<Array<IInvoiceLineItem>>(undefined);
  const [paymentModalVisible, setPaymentModalVisible] = useState<boolean>(false);

  const {
    state: voidInvoicePopup,
    closeModal: closeVoidInvoicePopup,
    updateModal: updateVoidInvoicePopup,
  } = useModal();

  const {
    state: duplicateInvoicePopup,
    closeModal: closeDuplicateInvoicePopup,
    updateModal: updateDuplicateInvoicePopup,
  } = useModal();

  const {
    state: addAdjustmentsPopup,
    closeModal: closeAddAdjustmentsPopup,
    updateModal: updateAddAdjustmentsPopup,
  } = useModal();

  const {
    state: applyDepositPopup,
    updateModal: updateApplyDepositPopup,
    closeModal: closeApplyDepositPopup,
  } = useModal({ depositId: null });

  useEffect(() => {
    void dispatch(loadPaymentOptions());
  }, []);

  useEffect(() => {
    void loadInvoice();
  }, [id]);

  async function loadInvoice() {
    const invoiceRes = await GetInvoice(
      {
        id: Number(id),
        extended: true,
      },
      true,
    );

    if (invoiceRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading invoice")); // TODO: Translation
      return;
    }

    // If invoice has a parent invoice load it
    if (invoiceRes.data[0].parent_id) {
      const parentRes = await GetInvoice({ id: invoiceRes.data[0].parent_id }, true);

      if (invoiceRes.status !== StatusCode.OK) {
        dispatch(showError("Error loading parent invoice")); // TODO: Translation
      } else {
        setParentInvoice(parentRes.data[0]);
      }
    }

    if (invoiceRes.data[0].tournament_id) {
      const tournamentRes = await GetTournament({ id: invoiceRes.data[0].tournament_id }, true);

      if (tournamentRes.status !== StatusCode.OK) {
        dispatch(showError("Error loading tournament")); // TODO: Translation
        return;
      }

      invoiceRes.data[0].tournament = tournamentRes.data[0];

      const depositRes = await GetInvoiceTransaction({ tournament_id: tournamentRes.data[0].id }, true);

      if (depositRes.status !== StatusCode.OK) {
        dispatch(showError("Error loading tournament deposits")); // TODO: Translation
        return;
      }

      invoiceRes.data[0].tournament.deposits = determineRefundedTransactions(depositRes.data);
    } else if (invoiceRes.data[0].hospitality_event_id) {
      const eventRes = await GetEvent({ id: invoiceRes.data[0].hospitality_event_id }, true);

      if (eventRes.status !== StatusCode.OK) {
        dispatch(showError("Error loading hospitality event")); // TODO: Translation
        return;
      }

      invoiceRes.data[0].hospitality_event = eventRes.data[0];

      const depositRes = await GetInvoiceTransaction({ hospitality_event_id: eventRes.data[0].id }, true);

      if (depositRes.status !== StatusCode.OK) {
        dispatch(showError("Error loading hospitality event deposits")); // TODO: Translation
        return;
      }

      invoiceRes.data[0].hospitality_event.deposits = determineRefundedTransactions(depositRes.data);
    }

    const lineItemRes = await GetInvoiceLineItem({ invoice_id: invoiceRes.data[0].id }, true);

    if (lineItemRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading invoice line items")); // TODO: Translation
      return;
    }

    setInvoice(invoiceRes.data[0]);
    setInvoiceLineItems(lineItemRes.data);
  }

  async function downloadInvoice() {
    const invoiceRes = await GetDownloadInvoice({ invoice_id: Number(id) }, true);

    if (invoiceRes.status !== StatusCode.OK) {
      dispatch(showError("Error downloading invoice")); // TODO: Translation
      return;
    }

    window.open(invoiceRes.data);
  }

  async function voidInvoice() {
    const voidRes = await PutVoidInvoice({ invoice_id: Number(id) }, true);

    if (voidRes.status !== StatusCode.OK) {
      dispatch(showError("Error voiding invoice")); // TODO: Translation
      return;
    }

    void loadInvoice();
    updateVoidInvoicePopup({ isOpen: false });
  }

  async function duplicateInvoice() {
    const duplicateRes = await PostDuplicateInvoice({ invoice_id: Number(id) }, true);

    if (duplicateRes.status !== StatusCode.OK) {
      dispatch(showError("Error duplicating invoice")); // TODO: Translation
      return;
    }

    updateDuplicateInvoicePopup({ isOpen: false });
    dispatch(showSuccess("Invoice duplicated successfully")); // TODO: Translation
  }

  async function addAdjustments() {
    const duplicateRes = await PostDuplicateInvoice(
      {
        invoice_id: Number(id),
        adjustment: true,
      },
      true,
    );

    if (duplicateRes.status !== StatusCode.OK) {
      dispatch(showError("Error duplicating invoice")); // TODO: Translation
      return;
    }

    closeAddAdjustmentsPopup();
    dispatch(showSuccess("Invoice duplicated successfully")); // TODO: Translation
    history.push(`/admin/invoices/${String(duplicateRes.data.id)}/edit`);
  }

  async function applyDeposit(depositId: number) {
    if (!depositId) {
      dispatch(showError("No deposit selected")); // TODO: Translation
      return;
    }

    if (invoice.financial_status !== "open") {
      dispatch(showError("Cannot apply deposits to a closed invoice")); // TODO: Translation
      return;
    }

    const applyRes = await PutApplyDeposit(
      {
        invoice_id: Number(id),
        deposit_id: depositId,
      },
      true,
    );

    if (applyRes.status !== StatusCode.OK) {
      dispatch(showError("Error applying deposit")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Deposit applied successfully")); // TODO: Translation
    updateApplyDepositPopup({ isOpen: false, depositId: null });
    void loadInvoice();
  }

  function getBalance(): number {
    if (!invoice?.transactions) {
      return;
    }

    let total = invoice?.total_price;

    for (let i = 0; i < invoice?.transactions.length; i++) {
      if (invoice?.transactions[i].kind === "refund") {
        total += invoice?.transactions[i].amount;
      } else {
        total -= invoice?.transactions[i].amount;
      }
    }

    return total;
  }

  /** Refunded transaction based off parent_id of the transaction == the currentTransactionId */
  function determineRefundedTransactions(transactions: IInvoiceTransaction[]): IInvoiceTransaction[] {
    const filteredTransactions = transactions.filter(
      (transaction: Record<string, any>) => transaction?.kind !== "deposit_authorization",
    );
    const refundedTransactions = transactions.filter(
      (transaction: Record<string, any>) => transaction?.parent_id && transaction?.kind === "refund",
    );

    const markRefunds = [...filteredTransactions]?.reduce(
      (markedRefunds, currentTransaction, index) => {
        const refunded = refundedTransactions?.some(
          (transaction: Record<string, any>) => transaction?.parent_id === currentTransaction?.id,
        );

        // Adding in 'refunded' param to HouseAccountTransactionType
        if (refunded) {
          markedRefunds[index] = { ...markedRefunds[index], refunded: true };
        } else {
          markedRefunds[index] = { ...markedRefunds[index], refunded: false };
        }
        return markedRefunds;
      },
      [...filteredTransactions],
    );

    return markRefunds;
  }

  function navigateToParentInvoice() {
    const url =
      parentInvoice?.financial_status === "draft"
        ? `/admin/invoices/${parentInvoice.id}/edit`
        : `/admin/invoices/${parentInvoice.id}`;

    history.push(url);
  }

  return (
    <Page
      title={`Invoice ${invoice?.name}`} // TODO: Translation
      titleMetadata={generateStatusBadge(invoice?.financial_status)}
      breadcrumbs={[{ prefix: true, label: "Back to invoices", url: "/admin/invoices" }]} // TODO: Translation
      splitLayout
      multipleActionDropdownAction={{
        label: "Options", // TODO: Translation
        dropdownProps: {
          alignment: "right",
          options: [
            {
              type: "handler",
              label: "Make Payment", // TODO: Translation
              icon: "money-bill",
              handler: () => setPaymentModalVisible(true),
              disabled: invoice?.financial_status === "paid" || invoice?.financial_status === "voided",
            },
            {
              type: "handler",
              label: "Download", // TODO: Translation
              icon: "download",
              handler: () => downloadInvoice(),
            },
            {
              type: "handler",
              label: "Void", // TODO: Translation
              icon: "ban",
              handler: () => updateVoidInvoicePopup({ isOpen: true }),
              disabled: invoice?.financial_status !== "open",
            },
            {
              type: "handler",
              label: "Duplicate", // TODO: Translation
              icon: "copy",
              handler: () => updateDuplicateInvoicePopup({ isOpen: true }),
              disabled: invoice?.financial_status === "voided",
            },
          ],
        },
      }}
    >
      <Page.Section twoThirds>
        <Card title="Items" /* TODO: Translation */>
          <Card.Section>
            {invoiceLineItems &&
              invoiceLineItems?.map((item: any, index: number) => {
                return (
                  <Card.SubSection key={index}>
                    <div className="order-line-item">
                      <div>
                        <p className="text-lg text-semibold">{item.product_title}</p>
                        {item.product_title !== item.variant_title ? (
                          <p className="text-sm text-gray-500">{item.variant_title}</p>
                        ) : null}
                      </div>
                      <div className="text-right">
                        <p className="text-lg text-gray-500">
                          {item.quantity} x{" "}
                          {Intl.NumberFormat("en-CA", { style: "currency", currency: "CAD" }).format(item.price)}
                        </p>
                      </div>
                      <div className="text-right">
                        <p className="text-lg text-semibold">
                          {Intl.NumberFormat("en-CA", { style: "currency", currency: "CAD" }).format(
                            item.price * item.quantity,
                          )}
                        </p>
                      </div>
                    </div>

                    {item.modifiers ? (
                      <div>
                        {item.modifiers.map((modifier: any, i: number) => {
                          return (
                            <div key={i}>
                              <div className="order-line-item">
                                <div style={{ color: "#475467", marginLeft: "15px" }}>{modifier.product_title}</div>
                                <div className="text-right">
                                  <p className="text-lg text-gray-500">
                                    {modifier.quantity} x{" "}
                                    {Intl.NumberFormat("en-CA", { style: "currency", currency: "CAD" }).format(
                                      modifier.price,
                                    )}
                                  </p>
                                </div>
                                <div className="text-right">
                                  <p className="text-lg text-semibold">
                                    {Intl.NumberFormat("en-CA", { style: "currency", currency: "CAD" }).format(
                                      modifier.price * modifier.quantity,
                                    )}
                                  </p>
                                </div>
                              </div>

                              {/* {modifier.total_discount !== 0 ? (
                                <div>
                                  {orderState.order.discount_lines.map((discount: any, i: number) => {
                                    if (discount.order_line_item_id === modifier.id) {
                                      return (
                                        <div className="text-right">
                                          <p className="text-sm text-gray-500">
                                            {discount.title} -{" "}
                                            <LocaleCurrency
                                              currency={orderState.order?.currency}
                                              amount={discount.amount}
                                            />
                                          </p>
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                              ) : null} */}
                            </div>
                          );
                        })}
                      </div>
                    ) : null}

                    {/* {orderState.order.discount_lines.map((discount: any, i: number) => {
                      if (discount.order_line_item_id === item.id) {
                        return (
                          <div key={i} className="text-right">
                            <p className="text-lg text-gray-500">
                              {discount.title} -{" "}
                              <LocaleCurrency currency={orderState.order?.currency} amount={discount.amount} />
                            </p>
                          </div>
                        );
                      }
                    })}

                    {item.total_discount !== 0 && (
                      <div className="text-right">
                        <p className="text-lg text-semibold">
                          <LocaleCurrency currency={orderState.order?.currency} amount={item.subtotal_price} />
                        </p>
                      </div>
                    )} */}
                  </Card.SubSection>
                );
              })}
          </Card.Section>
          <Card.Section>
            <div>
              <div className="order-totals">
                <div className="order-totals_row flex gap-5 justify-end">
                  <span className="text-lg text-semibold">{"Subtotal"}</span>
                  <p className="text-lg text-semibold">
                    {Intl.NumberFormat("en-CA", { style: "currency", currency: "CAD" }).format(invoice?.subtotal_price)}
                  </p>
                </div>
                <>
                  {invoice?.tax_lines &&
                    invoice?.tax_lines.map((tax_line: Record<string, any>, index: number) => {
                      return (
                        <div key={index} className="order-totals_row flex gap-5 justify-end">
                          <span className="text-lg text-semibold">
                            {tax_line.title}{" "}
                            <span className="text-lg text-gray-500">{displayPercent(tax_line.rate)}</span>
                          </span>
                          <p className="text-lg text-semibold">
                            {Intl.NumberFormat("en-CA", { style: "currency", currency: "CAD" }).format(tax_line.price)}
                          </p>
                        </div>
                      );
                    })}
                </>

                {invoice?.total_tip > 0 ? (
                  <div className="order-totals_row flex gap-5 justify-end">
                    <span className="text-lg text-semibold">{"Tip"}</span>
                    <p className="text-lg text-semibold">
                      {Intl.NumberFormat("en-CA", { style: "currency", currency: "CAD" }).format(invoice?.total_tip)}
                    </p>
                  </div>
                ) : null}

                <div className="order-totals_row flex gap-5 justify-end text-semibold text-md">
                  <span className="text-lg text-semibold">{"Price"}</span>

                  <p className="text-lg text-semibold">
                    {Intl.NumberFormat("en-CA", { style: "currency", currency: "CAD" }).format(invoice?.total_price)}
                  </p>
                </div>

                {invoice?.transactions &&
                  invoice.transactions.map((transaction: any, index: number) => {
                    return (
                      <div className="order-totals_row flex justify-end" key={index}>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="leftMenuToolTip">
                              {moment.utc(transaction.processed_at).local().format("MMMM DD, YYYY") +
                                " at " +
                                moment.utc(transaction.processed_at).local().format("h:mm A")}
                            </Tooltip>
                          }
                        >
                          <div>
                            <div className="flex gap-5 justify-end">
                              <span className="text-lg text-semibold">
                                {
                                  transaction.kind === "deposit"
                                    ? "Deposit: "
                                    : transaction.kind === "refund"
                                    ? "Refund: "
                                    : "Payment: " /* TODO: Translation */
                                }
                                {capitalize(transaction.payment_type)}
                              </span>
                              {transaction.payment_last_4 ? (
                                <span className="text-lg text-gray-500">•••• {transaction.payment_last_4}</span>
                              ) : null}
                              <span className="text-lg text-semibold">
                                {Intl.NumberFormat("en-CA", { style: "currency", currency: "CAD" }).format(
                                  transaction.kind === "refund" ? transaction.amount * -1 : transaction.amount,
                                )}
                              </span>
                            </div>
                            {transaction.note && <div className="text-sm text-subdued">{transaction.note} </div>}
                          </div>
                        </OverlayTrigger>
                      </div>
                    );
                  })}

                <div className="order-totals_row flex gap-5 justify-end text-semibold text-md">
                  <span className="text-lg text-semibold">{"Balance" /* TODO: Translation */}</span>

                  <p className="text-lg text-semibold">
                    {Intl.NumberFormat("en-CA", { style: "currency", currency: "CAD" }).format(getBalance())}
                  </p>
                </div>
              </div>
            </div>
          </Card.Section>
          {invoice?.financial_status === "open" && (
            <Card.Section>
              <Button type="primary" onClick={() => updateAddAdjustmentsPopup({ isOpen: true })}>
                {"Add Adjustments" /* TODO: Translation */}
              </Button>
            </Card.Section>
          )}
        </Card>
      </Page.Section>
      <Page.Section oneThird>
        <Card title="Details" /* TODO: Translation */>
          <Card.Section>
            <div>
              {invoice?.customer ? (
                <div>
                  <p className="text-md">Invoice Date: {invoice?.date /* TODO: Translation */}</p>
                  <p className="text-md">Invoice Due: {invoice?.due_date /* TODO: Translation */}</p>
                </div>
              ) : (
                <div>
                  <p>{"No Invoice Selected" /* TODO: Translation */}</p>
                </div>
              )}
            </div>
          </Card.Section>
        </Card>
        {invoice?.parent_id && (
          <Card
            title="Parent Invoice" /* TODO: Translation */
            titleActions={[
              {
                content: "View",
                action: navigateToParentInvoice,
              },
            ]}
          >
            <Card.Section>
              <div>
                <p className="text-md text-semibold">{parentInvoice?.name}</p>
              </div>
            </Card.Section>
          </Card>
        )}
        <Card title="Customer" /* TODO: Translation */>
          <Card.Section>
            <div>
              {invoice?.customer ? (
                <div className="flex-col">
                  <div>
                    <p className="text-md text-semibold">{invoice?.customer?.full_name}</p>
                    <p className="text-md">{invoice?.customer?.email}</p>
                    <p className="text-md">{invoice?.customer?.phone}</p>
                  </div>
                  {invoice?.billing_address ? (
                    <div className="mt-2">
                      <p className="text-md text-semibold">{"Billing Address" /* TODO: Translation */}</p>
                      <p className="text-md">{invoice?.billing_address?.address_line_1}</p>
                      {...invoice?.billing_address?.address_line_2
                        ? [
                            <p className="text-md" key="address-line-2">
                              {invoice?.billing_address?.address_line_2}
                            </p>,
                          ]
                        : []}
                      <p className="text-md">{`${invoice?.billing_address?.city}, ${invoice?.billing_address?.province_code}`}</p>
                      <p className="text-md">{invoice?.billing_address?.postal}</p>
                    </div>
                  ) : (
                    <div className="mt-2">
                      <p className="text-md text-semibold">{"Billing Address" /* TODO: Translation */}</p>
                      <p className="text-md">{"None Selected"}</p>
                    </div>
                  )}
                  {invoice?.shipping_address ? (
                    <div className="mt-2">
                      <p className="text-md text-semibold">{"Shipping Address" /* TODO: Translation */}</p>
                      <p className="text-md">{invoice?.shipping_address?.address_line_1}</p>
                      {...invoice?.shipping_address?.address_line_2
                        ? [
                            <p className="text-md" key="address-line-2">
                              {invoice?.shipping_address?.address_line_2}
                            </p>,
                          ]
                        : []}
                      <p className="text-md">{`${invoice?.shipping_address?.city}, ${invoice?.shipping_address?.province_code}`}</p>
                      <p className="text-md">{invoice?.shipping_address?.postal}</p>
                    </div>
                  ) : (
                    <div className="mt-2">
                      <p className="text-md text-semibold">{"Shipping Address" /* TODO: Translation */}</p>
                      <p className="text-md">{"None Selected"}</p>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <p>{"No Customer Selected" /* TODO: Translation */}</p>
                </div>
              )}
            </div>
          </Card.Section>
        </Card>
        {invoice?.tournament && (
          <Card title="Tournament" /* TODO: Translation */>
            <Card.Section>
              <div>
                {invoice?.tournament ? (
                  <div>
                    <p className="text-md text-semibold">{invoice?.tournament?.name}</p>
                    <p className="text-md">{`${invoice?.tournament?.total_participant_count}/${Number(
                      invoice?.tournament?.player_limit,
                    )} Players`}</p>{" "}
                    {/* TODO: Translation */}
                    <p className="text-md">{invoice?.tournament?.date}</p>
                    {invoice?.tournament?.deposits && invoice?.financial_status === "open" ? (
                      <div className="flex flex-col gap-2 mt-2">
                        {invoice?.tournament?.deposits
                          ?.filter(deposit => deposit.invoice_id === invoice?.id || deposit.invoice_id === null)
                          ?.filter(deposit => !deposit.refunded && deposit.kind !== "refund")
                          ?.map((deposit, index) => {
                            return (
                              <DepositCallout
                                key={deposit?.id}
                                type={"info"}
                                title={`Deposit ${index + 1}`}
                                content={new Intl.NumberFormat("en-CA", {
                                  style: "currency",
                                  currency: invoice?.currency,
                                }).format(deposit?.amount)}
                                actions={{
                                  primary: {
                                    label: deposit?.invoice_id ? "Applied" : "Apply",
                                    onClick: () => updateApplyDepositPopup({ isOpen: true, depositId: deposit?.id }),
                                    disabled: deposit?.invoice_id !== null,
                                  },
                                }}
                                applied={deposit?.invoice_id !== null}
                              />
                            );
                          })}
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div>
                    <p>{"No Tournament Selected" /* TODO: Translation */}</p>
                  </div>
                )}
              </div>
            </Card.Section>
          </Card>
        )}
        {invoice?.hospitality_event && (
          <Card title="Hospitality Event" /* TODO: Translation */>
            <Card.Section>
              <div>
                {invoice?.hospitality_event ? (
                  <div>
                    <p className="text-lg">{invoice?.hospitality_event?.name}</p>
                    <p className="text-lg">{invoice?.hospitality_event?.date}</p>
                    {invoice?.hospitality_event?.deposits && invoice?.financial_status === "open" ? (
                      <div className="flex flex-col gap-2 mt-2">
                        {invoice?.hospitality_event?.deposits
                          ?.filter(deposit => deposit.invoice_id === invoice?.id || deposit.invoice_id === null)
                          ?.filter(deposit => !deposit.refunded && deposit.kind !== "refund")
                          ?.map((deposit, index) => {
                            return (
                              <DepositCallout
                                key={deposit?.id}
                                type={"info"}
                                title={`Deposit ${index + 1}`}
                                content={new Intl.NumberFormat("en-CA", {
                                  style: "currency",
                                  currency: invoice?.currency,
                                }).format(deposit?.amount)}
                                actions={{
                                  primary: {
                                    label: deposit?.invoice_id ? "Applied" : "Apply",
                                    onClick: () => updateApplyDepositPopup({ isOpen: true, depositId: deposit?.id }),
                                    disabled: deposit?.invoice_id !== null,
                                  },
                                }}
                                applied={deposit?.invoice_id !== null}
                              />
                            );
                          })}
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div>
                    <p>{"No Hospitality Event Selected" /* TODO: Translation */}</p>
                  </div>
                )}
              </div>
            </Card.Section>
          </Card>
        )}
      </Page.Section>

      {invoice && paymentModalVisible && (
        <InvoicePaymentModal
          paymentModalVisible={paymentModalVisible}
          invoice={invoice}
          paymentMethods={null} // TODO: Load current customer payment methods
          onOk={() => loadInvoice()}
          closePaymentModal={() => setPaymentModalVisible(false)}
        />
      )}

      <Portal isMounted={voidInvoicePopup.isOpen}>
        <Popup
          open={voidInvoicePopup.isOpen}
          title="Void Invoice" // TODO: Translation
          description="Are you sure you want to void this invoice?" // TODO: Translation
          onOk={voidInvoice}
          okText="Void" // TODO: Translation
          onCancel={closeVoidInvoicePopup}
          type="warning"
        />
      </Portal>

      <Portal isMounted={duplicateInvoicePopup.isOpen}>
        <Popup
          open={duplicateInvoicePopup.isOpen}
          title="Duplicate Invoice" // TODO: Translation
          description="Are you sure you want to duplicate this invoice?" // TODO: Translation
          onOk={duplicateInvoice}
          okText="Duplicate" // TODO: Translation
          onCancel={closeDuplicateInvoicePopup}
          type="warning"
        />
      </Portal>

      <Portal isMounted={addAdjustmentsPopup.isOpen}>
        <Popup
          open={addAdjustmentsPopup.isOpen}
          title="Add Adjustments" // TODO: Translation
          description="Are you sure you want to add adjustments to this invoice? This will void the current invoice and create a new invoice with locked line items." // TODO: Translation
          onOk={addAdjustments}
          okText="Continue" // TODO: Translation
          onCancel={closeAddAdjustmentsPopup}
          type="warning"
        />
      </Portal>

      <Portal isMounted={applyDepositPopup.isOpen}>
        <Popup
          open={applyDepositPopup.isOpen}
          title="Apply Deposit?" // TODO: Translation
          description="Are you sure you want to apply this deposit? This deposit will no longer be appliable to any other invoices." // TODO: Translation
          type="warning"
          onCancel={() => closeApplyDepositPopup()}
          onOk={() => applyDeposit(applyDepositPopup.depositId)}
        />
      </Portal>
    </Page>
  );
}

function generateStatusBadge(status: string) {
  switch (status) {
    case "draft":
      return <Badge type="gray">{valueToString(status, "capitalize")}</Badge>;
    case "open":
      return <Badge type="primary">{valueToString(status, "capitalize")}</Badge>;
    case "paid":
      return <Badge type="success">{valueToString(status, "capitalize")}</Badge>;
    case "partially_paid":
      return <Badge type="warning">{valueToString(status, "capitalize")}</Badge>;
    case "voided":
      return <Badge type="error">{valueToString(status, "capitalize")}</Badge>;
    default:
      return <Badge type="gray">{valueToString(status, "capitalize")}</Badge>;
  }
}
