import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import ReactDOM from "react-dom";
import validator from "validator";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";

import { showSuccess, showError } from "redux/actions/ui";
import { IBooking, IBookingParticipant, ITeeTime } from "redux/reducers/models/teetime";

import { convertTime } from "helpers/Helpers";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import { ButtonNew } from "components/buttonNew";
import Radio from "components/radio";
import Card from "components/card/Card";
import GolferCard from "components/bookingPopUp/golferCard/GolferCard";
import { Select } from "components/select/index";
import Sheet from "components/sheet/Sheet";
import { useGuestPortalContext } from "../GuestPortalContext";

import "./editPortalBooking.scss";
import { useTranslation } from "react-i18next";
import { useClientPortal } from "pages/guest/Customer/ClientPortal/useClientPortal";
import Divider from "components/divider";
import { GetCustomerFriends, ICustomerFriend, PostFriends, SearchCustomer } from "api/rpc/2024-04/customer/customer";
import { GetBooking, PutCustomerBooking } from "api/rpc/2024-04/customer/booking";
import { GetCustomerTeeSheetSettings, IFacilityTeeSheetSettings } from "api/rpc/2024-04/customer/facility";
import Panel from "components/panel/Panel";
import { MOBILE_WIDTH } from "helpers/ScreenSizes";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
interface IState {
  selectedTeeTime: ITeeTime;
  selectedBooking: IBooking;
  formattedAddress: string;
  bookingParticipants: Array<Partial<IBookingParticipant>>;
  friendsList: Array<ICustomerFriend>;
  teeSheetSettings: IFacilityTeeSheetSettings;
  disableSingleBooking: boolean;
}

interface IEditBookingState {
  holesAmount: number;
  playerAmount: number;
  powerCartsAmount: number;
  maxPlayers: number;
  player2: string;
  player3: string;
  player4: string;
  friendsSearchResult2: Array<ICustomerFriend>;
  friendsSearchResult3: Array<ICustomerFriend>;
  friendsSearchResult4: Array<ICustomerFriend>;
  newPlayerQuery: string;
  selectedNewPlayer: ICustomerFriend;
  addPlayerModalVisible: boolean;
  searching: boolean;
  customerSearchResult: Array<ICustomerFriend>;
  [key: string]: any;
}

export default function EditPortalBooking() {
  const { Option } = Select;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const windowSize = useWindowSize();

  const dispatch = useAppDispatch();
  const { portalState } = useGuestPortalContext();

  const { getSingleBookings } = useClientPortal();

  const editDate = new Date("2022-06-06T00:00:00.000000Z").toUTCString();

  const [state, setState] = useState<IState>({
    selectedTeeTime: null,
    selectedBooking: null,
    formattedAddress: "",
    bookingParticipants: [],
    friendsList: [],
    teeSheetSettings: null,
    disableSingleBooking: false,
  });

  const [editBookingState, setEditBookingState] = useState<IEditBookingState>({
    holesAmount: null,
    playerAmount: null,
    powerCartsAmount: null,
    maxPlayers: null,
    player2: "",
    player3: "",
    player4: "",
    friendsSearchResult2: [],
    friendsSearchResult3: [],
    friendsSearchResult4: [],
    newPlayerQuery: "",
    selectedNewPlayer: null,
    addPlayerModalVisible: false,
    searching: false,
    customerSearchResult: [],
  });

  const [disableButtons, setDisableButtons] = useState<boolean>(false);
  const [holeButtons, setHoleButtons] = useState([true, true]);

  const allowCartBookings =
    (state.selectedTeeTime?.cart_rule === "optional" || state.selectedTeeTime?.cart_rule === "required") &&
    !!state.teeSheetSettings.enable_cart_bookings;

  useEffect(() => {
    void getBooking();
    void getFriendsList();
  }, []);

  useEffect(() => {
    if (state.selectedTeeTime) {
      void disableHoles();
    }
  }, [state.selectedTeeTime, editBookingState.playerAmount]);

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const source = axios.CancelToken.source();
    if (mounted === true) {
      timeoutId = setTimeout(() => {
        void search(editBookingState.newPlayerQuery, "customerSearchResult", source.token);
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      setEditBookingState(prevState => ({ ...prevState, customerSearchResult: [] }));
      source.cancel();
    };
  }, [editBookingState.newPlayerQuery]);

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const source = axios.CancelToken.source();
    if (mounted === true) {
      timeoutId = setTimeout(() => {
        void search(editBookingState.player2, "friendsSearchResult2", source.token);
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      setEditBookingState(prevState => ({ ...prevState, friendsSearchResult2: [] }));
      source.cancel();
    };
  }, [editBookingState.player2]);

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const source = axios.CancelToken.source();
    if (mounted === true) {
      timeoutId = setTimeout(() => {
        void search(editBookingState.player3, "friendsSearchResult3", source.token);
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      setEditBookingState(prevState => ({ ...prevState, friendsSearchResult3: [] }));
      source.cancel();
    };
  }, [editBookingState.player3]);

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const source = axios.CancelToken.source();
    if (mounted === true) {
      timeoutId = setTimeout(() => {
        void search(editBookingState.player4, "friendsSearchResult4", source.token);
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      setEditBookingState(prevState => ({ ...prevState, friendsSearchResult4: [] }));
      source.cancel();
    };
  }, [editBookingState.player4]);

  const search = async (playerSearchQuery: string, playerSearchResult: string, cancelToken: CancelToken) => {
    try {
      if (playerSearchQuery === "") {
        setEditBookingState(prevState => ({ ...prevState, [playerSearchResult]: [] }));
        return;
      } else {
        setEditBookingState(prevState => ({ ...prevState, searching: true }));
        let friendsResults: any;
        if (playerSearchResult === "customerSearchResult") {
          friendsResults = await searchCustomers(cancelToken);
        } else {
          friendsResults = await searchFriends(playerSearchQuery, cancelToken);
        }
        setEditBookingState(prevState => ({ ...prevState, searching: false, [playerSearchResult]: friendsResults }));
      }
    } catch (error) {
      console.log("err", error);
    }
    return;
  };

  async function searchCustomers(cancelToken: CancelToken) {
    let searchCustomersRes;
    const phoneNumberFormats = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(editBookingState?.newPlayerQuery);
    if (
      validator.isEmail(editBookingState?.newPlayerQuery) ||
      validator.isMobilePhone(editBookingState?.newPlayerQuery) ||
      validator.isNumeric(editBookingState?.newPlayerQuery) ||
      phoneNumberFormats
    ) {
      searchCustomersRes = await SearchCustomer(
        { search: editBookingState.newPlayerQuery, client_id: state?.selectedBooking?.tee_time?.facility?.client_id },
        false,
        cancelToken,
      );
    } else {
      searchCustomersRes = await SearchCustomer(
        {
          member_search: editBookingState.newPlayerQuery,
          client_id: state?.selectedBooking?.tee_time?.facility?.client_id,
        },
        false,
        cancelToken,
      );
      searchCustomersRes.data = searchCustomersRes?.data?.filter(
        (customer: Record<string, any>) => !customer?.customer_type[0]?.default,
      );
    }
    if (searchCustomersRes.status !== StatusCode.OK) {
      return [];
    }
    return searchCustomersRes.data as Array<ICustomerFriend>;
  }

  async function searchFriends(playerSearchQuery: string, cancelToken: CancelToken) {
    const searchFriendsRes = await GetCustomerFriends({ search: playerSearchQuery }, false, cancelToken);
    if (searchFriendsRes.status !== StatusCode.OK) {
      return [];
    }
    return searchFriendsRes.data;
  }

  async function getBooking() {
    const bookingRes = await GetBooking({ id: Number(id), extended: true, turn_tee_time: true }, true);
    if (bookingRes.status !== StatusCode.OK) {
      dispatch(showError(t("guest.portal.edit_portal_reservations.001")));
      return;
    }

    //Return to reservations page if booking isn't found
    if (bookingRes.data?.length === 0) {
      dispatch(showError(t("guest.portal.edit_portal_reservations.002")));
      history.push(`/tee-on/portal/bookings`);
    }

    //Return to reservations page if booking is cancelled
    if (bookingRes.data[0]?.status === "cancelled") {
      history.push(`/tee-on/portal/bookings`);
    }

    const teeSheetSettingsRes = await GetCustomerTeeSheetSettings(
      { facility_id: bookingRes?.data[0]?.tee_time?.facility_id },
      true,
    );
    if (teeSheetSettingsRes.status !== StatusCode.OK) {
      dispatch(showError(t("guest.portal.edit_portal_reservations.003")));
      history.push(`/tee-on/portal/bookings`);
    }

    checkBookingEditTime(bookingRes.data[0]);
    checkTeeTimeInPast(bookingRes?.data[0]);

    const maxPlayers = bookingRes.data[0]?.quantity + bookingRes.data[0]?.tee_time?.quantity_remaining;
    const singleBookingSettings = getSingleBookings(teeSheetSettingsRes?.data?.single_bookings, maxPlayers);

    const fullAddress = `${bookingRes.data[0]?.tee_time?.facility?.address_line_1}, ${bookingRes.data[0]?.tee_time?.facility?.city},
    ${bookingRes.data[0]?.tee_time?.facility?.province_name}, ${bookingRes.data[0]?.tee_time?.facility?.postal}`;

    ReactDOM.unstable_batchedUpdates(() => {
      setState(prevState => ({
        ...prevState,
        selectedBooking: bookingRes.data[0],
        formattedAddress: fullAddress,
        selectedTeeTime: bookingRes.data[0]?.tee_time,
        bookingParticipants: bookingRes.data[0]?.participants,
        teeSheetSettings: teeSheetSettingsRes?.data,
        disableSingleBooking: singleBookingSettings?.disable,
      }));
      setEditBookingState(prevState => ({
        ...prevState,
        holesAmount: bookingRes.data[0]?.holes,
        powerCartsAmount: bookingRes.data[0]?.power_cart_quantity,
        playerAmount: bookingRes.data[0]?.quantity,
        maxPlayers,
      }));
    });
  }

  function checkTeeTimeInPast(booking: IBooking) {
    const bookingDate = booking?.tee_time?.date + " " + booking?.tee_time?.start_time + ":00";
    const currentTime = moment().utc();
    const bookingTime = moment(bookingDate)?.utc();
    if (currentTime.isAfter(bookingTime)) {
      dispatch(showError(t("guest.portal.edit_portal_reservations.004")));
      history.push(`/tee-on/portal/bookings`);
    }
  }

  function checkBookingEditTime(booking: IBooking) {
    const completedAtDate = new Date(booking?.completed_at).toUTCString();

    const currentDateUTCString = new Date().toUTCString();

    const teeTimeDateSetUp = new Date(booking?.tee_time?.date + "T" + booking?.tee_time?.start_time);
    //Set date-time based on facility timezone
    const teeTimeDateTimezoneString = new Date(teeTimeDateSetUp).toLocaleString("en-US", {
      timeZone: booking?.tee_time?.facility?.timezone,
    });
    const teeTimeEditTime = new Date(teeTimeDateTimezoneString);

    teeTimeEditTime.setHours(teeTimeEditTime.getHours() - booking?.tee_sheet_settings?.web_edit_hours);

    const teeTimeEditTimeUTCString = teeTimeEditTime.toUTCString();

    if (
      Date.parse(completedAtDate) <= Date.parse(editDate) ||
      Date.parse(currentDateUTCString) >= Date.parse(teeTimeEditTimeUTCString)
    ) {
      setDisableButtons(true);
    }
    return;
  }

  async function getFriendsList() {
    const friendsListRes = await GetCustomerFriends(null, true);
    if (friendsListRes.status !== StatusCode.OK) {
      dispatch(showError(t("guest.portal.edit_portal_reservations.005")));
      return;
    }
    setState(prevState => ({ ...prevState, friendsList: friendsListRes.data }));
  }

  function handleGolferAmountChange(playerAmount: number) {
    if (playerAmount < editBookingState.playerAmount) {
      void handleRemoveGolfers(playerAmount);
    }
    setEditBookingState(prevState => ({ ...prevState, playerAmount }));
  }

  function disableHoles() {
    const tempHoleButtons = [true, true];
    if (state.selectedBooking?.holes !== 18) {
      // Disabling holes
      state.selectedTeeTime.divisions_available?.forEach(division => {
        // 9 holes available
        if (division === 1) {
          tempHoleButtons[1] = false;
        } else if (division === 2) {
          if (
            (state.selectedTeeTime?.divisions_available.length > 1 && state.selectedTeeTime?.turn_tee_time === null) ||
            state?.selectedTeeTime?.turn_tee_time?.blocked_type === "blocked" ||
            ((state.selectedTeeTime?.turn_tee_time?.league_id || state.selectedTeeTime?.turn_tee_time?.tournament_id) &&
              (state.selectedTeeTime?.league_id !== state.selectedTeeTime?.turn_tee_time?.league_id ||
                state.selectedTeeTime?.tournament_id !== state.selectedTeeTime?.turn_tee_time?.tournament_id))
          ) {
            // Only 9 holes available
            tempHoleButtons[1] = false;
          } else if (state.selectedTeeTime?.turn_tee_time != null) {
            // 18 holes available
            if (state.selectedTeeTime?.turn_tee_time?.quantity_remaining >= editBookingState?.playerAmount) {
              tempHoleButtons[0] = false;
            } else {
              // Only 9 holes available
              tempHoleButtons[1] = false;
            }
          }
        }
      });
    } else {
      tempHoleButtons.fill(false);
    }

    if (tempHoleButtons[0] && tempHoleButtons[1]) {
      setEditBookingState(prevState => ({ ...prevState, holesAmount: null }));
    } else if (tempHoleButtons[0]) {
      setEditBookingState(prevState => ({ ...prevState, holesAmount: 9 }));
    } else if (tempHoleButtons[1]) {
      setEditBookingState(prevState => ({ ...prevState, holesAmount: 18 }));
    }
    setHoleButtons(tempHoleButtons);
  }

  async function addFriends() {
    for (const participant of state.bookingParticipants) {
      if (participant?.customer_id !== portalState?.guest?.id) {
        const checkFriendStatus = state.friendsList?.some(friend => friend.id === participant.customer_id);
        if (!checkFriendStatus) {
          const addFriendRes = await PostFriends({ friend_user_id: participant.customer_id }, true);
          if (addFriendRes.status !== StatusCode.OK) {
            dispatch(showError(t("guest.portal.edit_portal_reservations.006")));
            return;
          }
        }
      }
    }
  }

  async function updateBooking() {
    const customer_ids: number[] = [];

    // Fill friend slots
    state.bookingParticipants?.forEach(participant => {
      customer_ids.push(participant.customer_id);
    });

    const playerDifference = editBookingState.playerAmount - customer_ids.length;
    // Fill guest slots
    if (customer_ids.length < editBookingState.playerAmount) {
      for (let i = 0; i < playerDifference; i++) {
        customer_ids.push(state.selectedBooking?.customer_id);
      }
    }

    const putBookingRes = await PutCustomerBooking(
      {
        token: state.selectedBooking?.token,
        power_cart_quantity: editBookingState.powerCartsAmount,
        quantity: editBookingState.playerAmount,
        holes: editBookingState.holesAmount,
        customer_ids: customer_ids?.length > 0 ? customer_ids : undefined,
      },
      true,
    );

    if (putBookingRes.status !== StatusCode.OK) {
      dispatch(showError(t("guest.portal.edit_portal_reservations.007")));
      return;
    }

    //Add players in booking to friends list if they are not already apart of it
    await addFriends();

    dispatch(showSuccess(t("guest.portal.edit_portal_reservations.008")));
    history.push(`/tee-on/portal/bookings/${id}/view`);
  }

  function removeGolferFromBooking(index: number) {
    const updatedBookingParticipants = [...state.bookingParticipants];
    updatedBookingParticipants.splice(index, 1);
    ReactDOM.unstable_batchedUpdates(() => {
      setState(prevState => ({ ...prevState, bookingParticipants: updatedBookingParticipants }));
      setEditBookingState(prevState => ({ ...prevState, player2: "", player3: "", player4: "" }));
    });
  }

  const handleCustomerSearch = (query: string, name: string) => {
    setEditBookingState(prevState => ({ ...prevState, [name]: query }));
  };

  function handleCustomerSelection(id: number, friend: any) {
    const updatedBookingParticipants = [...state.bookingParticipants];
    updatedBookingParticipants.push({ ...friend, customer_id: friend?.id });
    ReactDOM.unstable_batchedUpdates(() => {
      setState(prevState => ({ ...prevState, bookingParticipants: updatedBookingParticipants }));
      setEditBookingState(prevState => ({
        ...prevState,
        player2: "",
        player3: "",
        player4: "",
        selectedNewPlayer: null,
        addPlayerModalVisible: false,
        newPlayerQuery: "",
        customerSearchResult: [],
        friendsSearchResult2: [],
        friendsSearchResult3: [],
        friendsSearchResult4: [],
      }));
    });
  }

  function handleRemoveGolfers(newQuantity: number) {
    const updatedBookingParticipants = [...state.bookingParticipants].filter(
      (participant, index) => index + 1 <= newQuantity,
    );
    ReactDOM.unstable_batchedUpdates(() => {
      setState(prevState => ({ ...prevState, bookingParticipants: updatedBookingParticipants }));
      setEditBookingState(prevState => ({ ...prevState, player2: "", player3: "", player4: "" }));
    });
  }

  function displayAddPlayerModal() {
    setEditBookingState(prevState => ({
      ...prevState,
      addPlayerModalVisible: !prevState.addPlayerModalVisible,
      selectedNewPlayer: null,
      newPlayerQuery: "",
      customerSearchResult: [],
    }));
  }

  function renderFriendsList(playerSearchResult: string) {
    //Display the search results if the array contains values
    if (editBookingState[playerSearchResult]?.length > 0) {
      return editBookingState[playerSearchResult] as Array<ICustomerFriend>;
    } else {
      //Else return the entire friends list
      return state.friendsList;
    }
  }

  return (
    <div>
      {state.selectedBooking && state.selectedTeeTime && (
        <Page
          full
          title={t("guest.portal.edit_portal_reservations.009")}
          breadcrumbs={[{ prefix: true, label: "Back to View Booking", url: `/tee-on/portal/bookings/${id}/view` }]}
        >
          <div className="portal-reservations-edit-booking">
            <div className="facility-container">
              {state.selectedBooking?.tee_time?.facility?.logo_source && (
                <img src={state.selectedBooking?.tee_time?.facility?.logo_source} alt={"Facility logo"} />
              )}
              <div className="facility-details-container">
                <h1 className="facility-name">
                  {state.selectedBooking?.tee_time?.facility?.long_name}{" "}
                  {state.selectedBooking?.tee_time?.course?.long_name !==
                  state.selectedBooking?.tee_time?.facility?.long_name
                    ? ` - ${state.selectedBooking?.tee_time?.course?.long_name}`
                    : ""}
                </h1>
                <p className="facility-address">{state.formattedAddress}</p>
              </div>
            </div>
            <Card>
              <Card.Section>
                <p className="portal-reservations-edit-booking-start-time">
                  {convertTime(state.selectedBooking?.tee_time?.start_time ?? "")}
                </p>
                <p className="portal-reservations-edit-booking-date">
                  {moment(`${state.selectedBooking?.tee_time?.date}`).format("dddd")}{" "}
                  {moment(`${state.selectedBooking?.tee_time?.date}`).format("LL")}
                </p>
                <div className="portal-reservations-edit-booking-selection-container">
                  <div className="portal-reservations-edit-booking-button-group">
                    <p className="portal-reservations-edit-booking-title">{"Holes"}</p>
                    <Radio.Group
                      name="number_of_holes"
                      onChange={(holesAmount: number) =>
                        setEditBookingState(prevState => ({ ...prevState, holesAmount }))
                      }
                      value={editBookingState.holesAmount}
                    >
                      <Radio.Button
                        value={18}
                        disabled={holeButtons[0] || disableButtons || state?.selectedBooking?.lock_holes}
                      >
                        <span className="mr-2">18</span>
                        <FontAwesomeIcon size="1x" icon={["far", "golf-flag-hole"]} />
                      </Radio.Button>
                      <Radio.Button
                        value={9}
                        disabled={holeButtons[1] || disableButtons || state?.selectedBooking?.lock_holes}
                      >
                        <span className="mr-2">9</span>
                        <FontAwesomeIcon size="1x" icon={["far", "golf-flag-hole"]} />
                      </Radio.Button>
                    </Radio.Group>
                  </div>

                  <div className="portal-reservations-edit-booking-button-group">
                    <p className="portal-reservations-edit-booking-title">{"Players"}</p>
                    <Radio.Group
                      name="number_of_golfers"
                      onChange={handleGolferAmountChange}
                      value={editBookingState.playerAmount}
                    >
                      <Radio.Button
                        value={1}
                        disabled={disableButtons || state?.selectedBooking?.lock_quantity || state.disableSingleBooking}
                      >
                        {1}
                      </Radio.Button>
                      <Radio.Button
                        value={2}
                        disabled={
                          editBookingState.maxPlayers < 2 || disableButtons || state?.selectedBooking?.lock_quantity
                        }
                      >
                        {2}
                      </Radio.Button>
                      <Radio.Button
                        value={3}
                        disabled={
                          editBookingState.maxPlayers < 3 || disableButtons || state?.selectedBooking?.lock_quantity
                        }
                      >
                        {3}
                      </Radio.Button>
                      <Radio.Button
                        value={4}
                        disabled={
                          editBookingState.maxPlayers < 4 || disableButtons || state?.selectedBooking?.lock_quantity
                        }
                      >
                        {4}
                      </Radio.Button>
                    </Radio.Group>
                  </div>
                </div>
              </Card.Section>
            </Card>
            {allowCartBookings && (
              <Card>
                <Card.Section>
                  <div className="portal-reservations-edit-booking-selection-container">
                    <div style={{ width: "100%" }} className="portal-reservations-edit-booking-button-group">
                      <p className="portal-reservations-edit-booking-title">{"Power Carts"}</p>
                      <Radio.Group
                        name="number_of_carts"
                        onChange={(powerCartsAmount: number) =>
                          setEditBookingState(prevState => ({ ...prevState, powerCartsAmount }))
                        }
                        value={editBookingState.powerCartsAmount}
                      >
                        <Radio.Button
                          value={0}
                          disabled={
                            state.selectedTeeTime?.cart_rule === "required" ||
                            disableButtons ||
                            state?.selectedBooking?.lock_power_cart_quantity
                          }
                        >
                          {"Walking"}
                        </Radio.Button>
                        <Radio.Button
                          value={1}
                          disabled={disableButtons || state?.selectedBooking?.lock_power_cart_quantity}
                        >
                          {"1"}
                        </Radio.Button>
                        <Radio.Button
                          value={2}
                          disabled={disableButtons || state?.selectedBooking?.lock_power_cart_quantity}
                        >
                          {"2"}
                        </Radio.Button>
                      </Radio.Group>
                    </div>
                  </div>
                </Card.Section>
              </Card>
            )}
            <Card>
              <Card.Section>
                <p className="portal-reservations-edit-booking-title">
                  {t("guest.portal.edit_portal_reservations.010")}
                </p>
                {[...Array(editBookingState?.playerAmount)]?.map((num, index) => {
                  if (state.bookingParticipants[index]) {
                    return (
                      <GolferCard
                        bookingEngineCard={true}
                        closable={index > 0 ? true : false}
                        removeGolfer={() => removeGolferFromBooking(index)}
                        name={
                          state.bookingParticipants[index]?.guest
                            ? "Guest"
                            : `${state.bookingParticipants[index]?.full_name}`
                        }
                        key={index}
                      />
                    );
                  } else {
                    const searchResultKey = "friendsSearchResult".concat((index + 1).toString());
                    return (
                      <div key={index} className="portal-new-player-sheet">
                        <Select
                          showSearch
                          className={`flex justify-center align-center w-full h-10 position-relative z-20 text-black font-medium appearance-none border-none focus:outline-none placeholder-gray-200 mt-4`}
                          onSearch={(query: string) =>
                            handleCustomerSearch(query, "player".concat((index + 1).toString()))
                          }
                          onChange={(id: number, friendObject: ICustomerFriend) =>
                            handleCustomerSelection(id, friendObject)
                          }
                          allowClear
                          searchValue={editBookingState["player".concat((index + 1).toString())]}
                          showDropDownOnFocus={true}
                          searching={editBookingState.searching}
                          placeholder={"Guest"}
                          disabled={index + 1 <= editBookingState.playerAmount ? false : true}
                        >
                          <div className="ui-select-dropdown-list-item" onClick={displayAddPlayerModal}>
                            {t("guest.portal.edit_portal_reservations.011")}
                          </div>
                          {renderFriendsList(searchResultKey)?.map((friend: ICustomerFriend, index: number) => {
                            return (
                              <Option key={index} value={friend.id} extraValues={friend}>
                                <span>{friend.full_name}</span>
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                    );
                  }
                })}
              </Card.Section>
            </Card>
            <Divider />
            <div className="portal-reservations-edit-booking-confirm-button-group">
              <ButtonNew className="update-booking-button" type="primary" size="medium" onClick={updateBooking}>
                {t("guest.portal.edit_portal_reservations.013")}
              </ButtonNew>
            </div>
          </div>
        </Page>
      )}

      {/* Add playing partner modal */}
      <Panel
        open={editBookingState.addPlayerModalVisible && windowSize.width < MOBILE_WIDTH}
        onClose={displayAddPlayerModal}
        title={"Add Playing Partners"}
        animate
        primaryAction={{
          onClick: () =>
            handleCustomerSelection(editBookingState.selectedNewPlayer?.id, editBookingState.selectedNewPlayer),
          label: t("guest.portal.edit_portal_reservations.016"),
          disabled: !editBookingState.selectedNewPlayer,
        }}
        secondaryAction={{
          onClick: displayAddPlayerModal,
          label: t("guest.portal.edit_portal_reservations.015"),
        }}
      >
        {editBookingState.selectedNewPlayer ? (
          <GolferCard
            closable
            removeGolfer={() =>
              setEditBookingState(prevState => ({ ...prevState, selectedNewPlayer: null, newPlayerQuery: "" }))
            }
            name={editBookingState.selectedNewPlayer?.full_name}
            email={editBookingState.selectedNewPlayer?.email}
            bookingEngineCard={true}
          />
        ) : (
          <>
            <div className="portal-edit-booking-search-players-label">
              <p className="text-sm text-subdued">{t("guest.portal.edit_portal_reservations.018")}</p>
              <p className="text-sm text-subdued">{t("guest.portal.edit_portal_reservations.017")}</p>
            </div>
            <Select
              showSearch
              onSearch={(query: string) => handleCustomerSearch(query, "newPlayerQuery")}
              onChange={(id: string, friendObject: ICustomerFriend) =>
                setEditBookingState(prevState => ({ ...prevState, selectedNewPlayer: friendObject }))
              }
              allowClear
              searchValue={editBookingState.newPlayerQuery}
              searching={editBookingState.searching}
              noData={
                <div className="flex items-center p-2">
                  <FontAwesomeIcon icon={["fas", "users-slash"]} fixedWidth size="1x" />
                  <span className="ml-3 italic">{t("guest.portal.edit_portal_reservations.019")}</span>
                </div>
              }
              placeholder="Search..."
            >
              {editBookingState.customerSearchResult?.map((friend, index) => {
                return (
                  <Option key={index} value={friend.id} extraValues={friend} name={friend?.full_name}>
                    <span>
                      {friend.full_name} - {friend?.customer_type[0]?.title}
                    </span>
                  </Option>
                );
              })}
            </Select>
          </>
        )}
      </Panel>

      <Sheet
        open={editBookingState.addPlayerModalVisible && windowSize.width > MOBILE_WIDTH}
        size="small"
        closable
        title={t("guest.portal.edit_portal_reservations.014")}
        onCancel={displayAddPlayerModal}
        onOk={() => handleCustomerSelection(editBookingState.selectedNewPlayer?.id, editBookingState.selectedNewPlayer)}
        cancelText={t("guest.portal.edit_portal_reservations.015")}
        okText={t("guest.portal.edit_portal_reservations.016")}
        okDisabled={editBookingState.selectedNewPlayer ? false : true}
        overflow
      >
        {editBookingState.selectedNewPlayer ? (
          <GolferCard
            closable
            removeGolfer={() =>
              setEditBookingState(prevState => ({ ...prevState, selectedNewPlayer: null, newPlayerQuery: "" }))
            }
            name={editBookingState.selectedNewPlayer?.full_name}
            email={editBookingState.selectedNewPlayer?.email}
            bookingEngineCard={true}
          />
        ) : (
          <>
            <div className="portal-edit-booking-search-players-label">
              <p className="text-sm text-subdued">{t("guest.portal.edit_portal_reservations.018")}</p>
              <p className="text-sm text-subdued">{t("guest.portal.edit_portal_reservations.017")}</p>
            </div>
            <Select
              showSearch
              onSearch={(query: string) => handleCustomerSearch(query, "newPlayerQuery")}
              onChange={(id: string, friendObject: ICustomerFriend) =>
                setEditBookingState(prevState => ({ ...prevState, selectedNewPlayer: friendObject }))
              }
              allowClear
              searchValue={editBookingState.newPlayerQuery}
              searching={editBookingState.searching}
              noData={
                <div className="flex items-center p-2">
                  <FontAwesomeIcon icon={["fas", "users-slash"]} fixedWidth size="1x" />
                  <span className="ml-3 italic">{t("guest.portal.edit_portal_reservations.019")}</span>
                </div>
              }
              placeholder="Search..."
            >
              {editBookingState.customerSearchResult?.map((friend, index) => {
                return (
                  <Option key={index} value={friend.id} extraValues={friend} name={friend?.full_name}>
                    <span>
                      {friend.full_name} - {friend?.customer_type[0]?.title}
                    </span>
                  </Option>
                );
              })}
            </Select>
          </>
        )}
      </Sheet>
    </div>
  );
}
