import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";

const apiVersion = "2021-01";
const newApiVersion = "2022-09";
const adminType = "facility-admin";

const baseLeagueUrl = "/" + apiVersion + "/" + adminType + "/league";
const newBaseLeagueUrl = "/" + newApiVersion + "/" + adminType + "/league";

// GET League
// Retrieve a list of leagues
export async function GetLeague(params: string, useGlobalLoader: boolean) {
  return await APIGet(baseLeagueUrl + "?" + params, useGlobalLoader);
}

// POST League
// Create a new league
export async function PostLeague(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseLeagueUrl, params, useGlobalLoader);
}

// PUT League
// Update an existing league
export async function PutLeague(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseLeagueUrl, params, useGlobalLoader);
}

//GET League Settings
export async function GetLeagueScoringSettings(params: any, useGlobalLoader: boolean) {
  return await APIGet(newBaseLeagueUrl + "/scoring/settings", params, useGlobalLoader);
}

//Post League Settings
export async function UpdateLeagueScoreSettings(params: any, useGlobalLoader: boolean) {
  return await APIPut(newBaseLeagueUrl + "/scoring/settings", params, useGlobalLoader);
}
