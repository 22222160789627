import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "hooks/redux";
import Page from "components/page/Page";
import axios, { CancelToken } from "axios";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";
import Portal from "elements/Portal";
import Popup from "components/popup/Popup";
import { showError, showSuccess } from "redux/actions/ui";
import moment from "moment";
import {
  GetInventoryCount,
  PostInventoryCount,
  TInventoryCount,
} from "api/rpc/2024-04/facilityAdmin/product/inventoryCount";
import { StatusCode } from "api/protocols";
import { valueToString } from "helpers/Helpers";
import { getCountsBadgeType } from "./ViewInventoryCount";
import { Badge } from "components/badge/Badge";

interface IState {
  inventoryCounts: Array<TInventoryCount>;
  newCountsPopup: boolean;
}

interface IFilterState {
  limit: number;
  offset: number;
}

export default function InventoryCounts() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [state, setState] = useState<IState>({
    inventoryCounts: undefined,
    newCountsPopup: false,
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    limit: 50,
    offset: 0,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadInventoryCounts(source.token);
    return () => {
      source.cancel();
    };
  }, [filterState.offset]);

  async function loadInventoryCounts(cancelToken: CancelToken) {
    const countsRes = await GetInventoryCount(
      { offset: filterState.offset, limit: filterState.limit },
      false,
      cancelToken,
    );
    if (countsRes.status !== StatusCode.OK) {
      if (cancelToken.reason) {
        return;
      }
      dispatch(showError("Error getting inventory counts"));
      return;
    }
    setState(prevState => ({ ...prevState, inventoryCounts: countsRes.data }));
  }

  async function createCountRecord() {
    const countsRes = await PostInventoryCount(true);
    if (countsRes.status !== StatusCode.OK) {
      dispatch(showError("Error creating inventory count record"));
      return;
    }
    dispatch(showSuccess("Successfully created inventory count record"));
    setState(prevState => ({ ...prevState, newCountsPopup: false }));
    history.push(`/admin/settings/counts/new/${countsRes?.data?.id}`);
  }

  function navigateToViewPage(id: number) {
    history.push(`/admin/settings/counts/view/${id}`);
  }

  return (
    <Page
      title="Inventory Counts"
      narrow
      primaryAction={{
        content: "New Count",
        action: () => setState(prevState => ({ ...prevState, newCountsPopup: true })),
      }}
    >
      <DataTable
        columns={[{ label: "Status" }, { label: "Created By" }, { label: "Date Created" }, { label: "Date Submitted" }]}
        footer={{
          tableLimit: filterState.limit,
          tableOffset: filterState.offset,
          disableNextOffset: !state.inventoryCounts || !(state.inventoryCounts?.length === filterState.limit),
          handleTableOffset: direction =>
            setFilterState(prev => ({
              ...prev,
              offset: direction === "prev" ? prev.offset - prev.limit : prev.offset + prev.limit,
            })),
        }}
        loading={!state.inventoryCounts}
      >
        {state.inventoryCounts?.map((count, index) => {
          return (
            <tr key={index} className="clickable" onClick={() => navigateToViewPage(count?.id)}>
              <td>
                {" "}
                <Badge type={getCountsBadgeType(count?.status)}>{valueToString(count?.status)}</Badge>
              </td>
              <td>{count?.created_by_full_name}</td>
              <td>{moment(count.date_created, "YYYY-MM-DD h:mm:ss").format("LL")}</td>
              <td>{count.date_submitted && moment(count.date_submitted, "YYYY-MM-DD h:mm:ss").format("LL")}</td>
            </tr>
          );
        })}
      </DataTable>
      <Portal isMounted={state.newCountsPopup}>
        <Popup
          open={state.newCountsPopup}
          type="info"
          onOk={() => createCountRecord()}
          onCancel={() => setState(prevState => ({ ...prevState, newCountsPopup: false }))}
          okText="Add"
          title="Add New Inventory Count Record?"
          description="Inventory can be counted and saved before finalized"
        />
      </Portal>
    </Page>
  );
}
