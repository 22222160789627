import React, { useEffect, useState } from "react";
import { StatusCode } from "api/protocols";
import Card from "components/card/Card";
import Input from "components/form/input/Input";
import Page from "components/page/Page";
import { useAppDispatch } from "hooks/redux";
import { useHistory, useParams } from "react-router";
import { showError, showSuccess } from "redux/actions/ui";
import Popup from "components/popup/Popup";
import { isEqualWith } from "lodash";
import { NotificationType } from "components/notificationBar/NotificationBar";
import {
  DeleteKitchenMeal,
  GetKitchenMeals,
  IKitchenMeal,
  PutKitchenMeal,
} from "api/rpc/2022-09/facilityAdmin/facility/kitchen";
import { IKitchenCourse } from "./KitchenCourse";
import { useTranslation, Trans } from "react-i18next";
interface IState {
  title: string;
  course: IKitchenMeal;
  deletePopup: boolean;
}

interface IParams {
  id: string;
}

export default function KitchenCourseEdit(props: any) {
  const [state, setState] = useState<IState>({
    title: "",
    course: null,
    deletePopup: false,
  });
  const [titleBeforeChanges, setTitleBeforeChanges] = useState<string>(undefined);
  const { id } = useParams<IParams>();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    void loadKitchenCourse();
  }, []);

  async function loadKitchenCourse() {
    const mealRes = await GetKitchenMeals({ id: Number(id) }, true);
    if (mealRes?.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.kitchen_course.kitchen_course_edit.001")));
      history.push("/admin/settings/courses");
      return;
    }
    if (mealRes?.data?.length === 1) {
      setState(prevState => ({ ...prevState, course: mealRes?.data[0], title: mealRes?.data[0]?.title }));
    } else {
      dispatch(showError(t("secure.facility.settings.kitchen_course.kitchen_course_edit.002")));
      history.push("/admin/settings/courses");
    }
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setState(prevState => ({ ...prevState, [id]: value }));
  }

  async function updateKitchenCourse() {
    const updateMealRes = await PutKitchenMeal({ id: Number(id), title: state?.title }, true);
    if (updateMealRes?.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.kitchen_course.kitchen_course_edit.003")));
      return;
    }
    dispatch(showSuccess(t("secure.facility.settings.kitchen_course.kitchen_course_edit.004")));
    history.push("/admin/settings/courses");
  }

  async function handleDeleteCourse() {
    const deleteRes = await DeleteKitchenMeal({ id: Number(id) }, true);
    if (deleteRes?.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.kitchen_course.kitchen_course_edit.005")));
      return;
    }
    dispatch(showSuccess(t("secure.facility.settings.kitchen_course.kitchen_course_edit.006")));
    history.push("/admin/settings/courses");
  }

  const primaryAction = {
    content: t("secure.facility.settings.kitchen_course.kitchen_course_edit.007"),
    action: () => setState(prevState => ({ ...prevState, deletePopup: true })),
  };

  function unsavedChangesExist() {
    if (titleBeforeChanges === undefined) {
      if (state?.course) {
        setTitleBeforeChanges(state?.title);
      }
      return false;
    }

    return !isEqualWith(titleBeforeChanges, state?.title);
  }

  function cancelUnsavedChanges() {
    setState(prevState => ({ ...prevState, title: titleBeforeChanges }));
  }

  return (
    <Page
      title={`${t("secure.facility.settings.kitchen_course.kitchen_course_edit.008")} ${state?.course?.title ?? ""}`}
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.kitchen_course.kitchen_course_edit.009"),
          url: "/admin/settings/courses",
        },
      ]}
      primaryAction={primaryAction}
      narrow
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: updateKitchenCourse,
        onCancel: cancelUnsavedChanges,
      }}
    >
      <Card>
        <Card.Section>
          <Input
            label={t("secure.facility.settings.kitchen_course.kitchen_course_edit.011")}
            placeholder={t("secure.facility.settings.kitchen_course.kitchen_course_edit.012")}
            id="title"
            value={state?.title ?? ""}
            onChange={handleInputChange}
          />
        </Card.Section>
      </Card>

      <Popup
        open={state?.deletePopup}
        type="warning"
        title={t("secure.facility.settings.kitchen_course.kitchen_course_edit.013")}
        description={t("secure.facility.settings.kitchen_course.kitchen_course_edit.014")}
        onCancel={() => setState(prevState => ({ ...prevState, deletePopup: false }))}
        onOk={handleDeleteCourse}
      />
    </Page>
  );
}
