import { APIGet, APIPost, APIPut, APIDelete, StatusCode } from "api/protocols";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/facility/printer";

// GET A Single Printer
export async function GetPrinter(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseUrl, params, useGlobalLoader);
}

// GET A Single Printer
export async function GetPrinterAll(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseUrl + "/all", params, useGlobalLoader);
}

// POST Printers
export async function PostPrinter(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

// Put Printers
export async function PutPrinter(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}
