import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { displayCurrency, roundNumber, displayPercent } from "helpers/Helpers";

import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import { ButtonNew as Button } from "components/buttonNew";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import TextField from "components/form/textField/TextField";
import Select from "components/form/select/Select";
import Checkbox from "components/form/checkbox/Checkbox";

import "pages/secure/facility/product/Variant.scss";
import { GetProduct, PostVariant } from "api/rpc/facilityAdmin/product/products";
import { useTranslation } from "react-i18next";

interface IVariantState {
  id: number;
  product_id: number;
  product_options: any;
  title: string;
  option1: string;
  option2: string;
  option3: string;
  price: number;
  original_price: number;
  cost: number;
  taxable: boolean;
  barcode: string;
  sku: string;
  tracked: boolean;
  product: any;
  editing: boolean;
}

export default function VariantNew(props: any) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { productId }: any = useParams();
  const [variantState, setVariantState] = useState<IVariantState>({
    id: null,
    product_id: null,
    product_options: null,
    title: "",
    option1: "",
    option2: "",
    option3: "",
    price: null,
    original_price: null,
    cost: null,
    taxable: false,
    barcode: "",
    sku: "",
    tracked: false,
    product: null,
    editing: false,
  });

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    console.log(event);
    console.log(id, value);
    setVariantState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleCheckboxChange(event: any) {
    const { id, checked } = event.target;
    setVariantState(prevState => ({ ...prevState, [id]: checked }));
  }

  useEffect(() => {
    void loadProduct();
  }, []);

  async function loadProduct() {
    const productRes = await GetProduct({ id: productId, extended: true }, true);
    if (productRes.status !== StatusCode.OK) {
      return;
    }

    const product = productRes.data[0];
    console.log("Product", product);

    setVariantState(prev => ({
      ...prev,
      product_id: product.id,
      product: product,
    }));
  }

  async function createVariant() {
    const variantRes = await PostVariant(variantState, true);

    console.log("Variant: ", variantRes);
    if (variantRes.status !== StatusCode.OK) {
      return;
    }

    history.push("/admin/product/" + String(productId));
    return;
  }

  const primaryAction = {
    content: t("secure.facility.product.variant_new.001"),
    action: createVariant,
  };

  return (
    <Page title={t("secure.facility.product.variant_new.002")} narrow primaryAction={primaryAction}>
      <Card title={t("secure.facility.product.variant_new.003")}>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              {variantState.product?.options?.map((option: any, index: number) => {
                return (
                  <Input
                    //defaultValue={variantState.optionList[index].value}
                    key={index}
                    type="text"
                    id={`option${index + 1}`}
                    onChange={handleInputChange}
                    label={option.name}
                  />
                );
              })}

              {/* {variantState.product?.options?.map((option: any, index: number) => {
                return (
                  <Input
                    key={index}
                    value={variantState.option1 || ""}
                    label={option.name}
                    id={`option${index + 1}`}
                    onChange={handleInputChange}
                    placeholder={option.name}
                  />
                );
              })} */}
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Input
                prefix="$"
                value={variantState.price || ""}
                label={t("secure.facility.product.variant_new.004")}
                id="price"
                onChange={handleInputChange}
                placeholder="0.00"
              />
              <Input
                prefix="$"
                value={variantState.original_price || ""}
                label={t("secure.facility.product.variant_new.005")}
                id="original_price"
                onChange={handleInputChange}
                placeholder="0.00"
              />
              <Input
                prefix="$"
                value={variantState.cost || ""}
                label={t("secure.facility.product.variant_new.006")}
                id="cost"
                onChange={handleInputChange}
                placeholder="0.00"
              />
              <div className="mt-4">
                <p className="variant-profit-margin">
                  {displayPercent(
                    roundNumber(100 * ((variantState.price - variantState.cost) / variantState.price), 0),
                  )}{" "}
                  -- {displayCurrency("cad", variantState.price - variantState.cost)}
                </p>
                <p className="variant-profit-margin">{t("secure.facility.product.variant_new.007")}</p>
              </div>
            </FormLayout.Group>
            <FormLayout.Group>
              <Checkbox
                id="taxable"
                size="medium"
                value={variantState.taxable}
                checked={variantState.taxable}
                onChange={handleCheckboxChange}
                label={t("secure.facility.product.variant_new.008")}
              />
              {variantState.taxable ? <p>{t("secure.facility.product.variant_new.009")}</p> : null}
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>
      <Card title={t("secure.facility.product.variant_new.010")}>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Input
                value={variantState.barcode || ""}
                label={t("secure.facility.product.variant_new.011")}
                id="barcode"
                onChange={handleInputChange}
              />
              <Input
                value={variantState.sku || ""}
                label={t("secure.facility.product.variant_new.012")}
                id="sku"
                onChange={handleInputChange}
              />
              <Checkbox
                id="tracked"
                size="medium"
                value={variantState.tracked}
                checked={variantState.tracked}
                onChange={handleCheckboxChange}
                label={t("secure.facility.product.variant_new.013")}
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>
    </Page>
  );
}
