import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { IGateway, IKind, IPaymentMethod, IProcessingType, ISource } from "redux/reducers/models/transaction";

const apiVersion = "2021-01";
const newApiVersion = "2022-09";
const adminType = "client-admin";
const baseCustomerUrl = "/" + apiVersion + "/" + adminType + "/customer";
const houseAccountUrl = "/" + apiVersion + "/" + adminType + "/client";
const houseAccountPaymentUrl = houseAccountUrl + "/account/transaction";

const newBaseCustomerUrl = "/" + newApiVersion + "/" + adminType + "/customer";

interface IGetCustomer {
  id?: number;
  search?: string;
  state?: string;
  "address-lines"?: boolean;
  "default-address-line"?: boolean;
  memberships?: boolean;
  limit?: number;
  offset?: number;
}
interface ICustomer {
  id?: number;
  first_name?: string;
  last_name?: string;
  phone?: string;
  email?: string;
  member_number?: number;
  state?: string;
}

interface ICustomerRes {
  status: number;
  message?: string;
  data: any;
}

interface ICustomerAccountTransaction {
  account_id?: number;
  kind?: IKind;
  amount?: number;
  processing_type?: string;
  payment_method?: string;
  payment_type?: string;
  source?: ISource;
  gateway?: IGateway;
  gift_card_code?: string;
  gift_card_pin?: string;
}

interface ICustomerAccountTransactionCapture {
  transaction_id: number;
  payment_intent_id: string;
}

interface IPostCustomerAddressLine {
  customer_id: number;
  first_name?: string;
  last_name?: string;
  address_line_1?: string;
  address_line_2?: string;
  city?: string;
  postal?: string;
  phone?: string;
  province_id: number;
  country_id: number;
  default: boolean;
}

interface IPutCustomerAddressLine extends IPostCustomerAddressLine {
  id: number;
}

interface IDeleteCustomerAddressLine {
  customer_id: number;
  address_line_id: number;
}

// GET Cart
// Retrieve a list of customers
export async function GetCustomer(params: IGetCustomer, useGlobalLoader?: boolean): Promise<ICustomerRes> {
  return await APIGet(newBaseCustomerUrl, params, useGlobalLoader);
}

export async function PostCustomer(params: ICustomer, useGlobalLoader: boolean): Promise<ICustomerRes> {
  return await APIPost(newBaseCustomerUrl, params, useGlobalLoader);
}

export async function PutCustomer(params: any, useGlobalLoader: boolean): Promise<ICustomerRes> {
  return await APIPut(newBaseCustomerUrl, params, useGlobalLoader);
}

export async function DeleteCustomer(params: { id: number }, useGlobalLoader: boolean): Promise<ICustomerRes> {
  return await APIDelete(newBaseCustomerUrl, params, useGlobalLoader);
}

export async function PostCustomerAddressLine(params: IPostCustomerAddressLine, useGlobalLoader: boolean) {
  return await APIPost(newBaseCustomerUrl + "/address-line", params, useGlobalLoader);
}

export async function PutCustomerAddressLine(params: IPutCustomerAddressLine, useGlobalLoader: boolean) {
  return await APIPut(newBaseCustomerUrl + "/address-line", params, useGlobalLoader);
}

export async function DeleteCustomerAddressLine(params: IDeleteCustomerAddressLine, useGlobalLoader: boolean) {
  return await APIDelete(newBaseCustomerUrl + "/address-line", params, useGlobalLoader);
}

interface IPostPaymentMethodParams {
  payment_method_id: string;
  customer_id: number;
  save_card: boolean;
}

interface IGetCustomerPaymentMethodParams {
  id?: number;
  customer_id?: number;
}

export async function PostSetup(params: { customer_id: number }, useGlobalLoader?: boolean) {
  return await APIPost(baseCustomerUrl + "/payment-method/setup", params, useGlobalLoader);
}

export async function GetCustomerPaymentMethod(params: IGetCustomerPaymentMethodParams, useGlobalLoader?: boolean) {
  return await APIGet(baseCustomerUrl + "/payment-method", params, useGlobalLoader);
}

export async function PostPaymentMethod(params: IPostPaymentMethodParams, useGlobalLoader?: boolean) {
  return await APIPost(baseCustomerUrl + "/payment-method", params, useGlobalLoader);
}

export async function DeletePaymentMethod(params: IGetCustomerPaymentMethodParams, useGlobalLoader?: boolean) {
  return await APIDelete(baseCustomerUrl + "/payment-method", params, useGlobalLoader);
}

export async function GetCustomerHouseAccounts(
  params: { customer_id: number },
  useGlobalLoader?: boolean,
): Promise<ICustomerRes> {
  return await APIGet(houseAccountUrl + "/account", params, useGlobalLoader);
}

export async function GetCustomerMemberships(params: { customer_id: number }, useGlobalLoader?: boolean) {
  return await APIGet(baseCustomerUrl + "/membership", params, useGlobalLoader);
}

export async function SendPasswordReset(params: ICustomer, useGlobalLoader: boolean) {
  return await APIPost(baseCustomerUrl + "/reset", params, useGlobalLoader);
}

export async function PostAccountTransaction(params: ICustomerAccountTransaction, useGlobalLoader: boolean) {
  return await APIPost(houseAccountPaymentUrl, params, useGlobalLoader);
}

export async function PutAccountTransactionCapture(
  params: ICustomerAccountTransactionCapture,
  useGlobalLoader: boolean,
) {
  return await APIPut(houseAccountPaymentUrl + "/capture", params, useGlobalLoader);
}

export async function GetAccountTransactions(params: ICustomerAccountTransaction, useGlobalLoader: boolean) {
  return await APIGet(houseAccountPaymentUrl, params, useGlobalLoader);
}

export async function GetAccountTransactionReceipt(
  params: { account_transaction_id: number },
  useGlobalLoader: boolean,
) {
  return await APIGet(houseAccountPaymentUrl + "/receipt", params, useGlobalLoader);
}

export async function GetCustomerStatements(params: any, useGlobalLoader: boolean) {
  return await APIGet(houseAccountUrl + "/account/statement", params, useGlobalLoader);
}

export async function DownloadCustomerAccountStatements(params: any, useGlobalLoader: boolean) {
  return await APIGet(houseAccountUrl + "/account/statement/download", params, useGlobalLoader);
}
