export interface IFilterHistory {
  [key: string]: {
    history: Set<any>;
    currentFilter: any;
  };
}

export interface IFilterHistoryAction {
  type: string;
  payload: {
    groupKey: string;
    value?: any;
  };
}
const initialState: IFilterHistory = {};

export default function FilterHistoryReducer(state = initialState, action: IFilterHistoryAction) {
  switch (action.type) {
    case "history.create":
      return {
        ...state,
        [action.payload.groupKey]: {
          history: new Set<any>(),
          currentFilter: action.payload.value,
        },
      };

    case "history.clear":
      return {
        ...state,
        [action.payload.groupKey]: {
          history: new Set<any>(),
          currentFilter: false,
        },
      };

    case "history.add": {
      const historyAdded = new Set(state[action.payload.groupKey].history);
      historyAdded.add(action.payload.value);
      return {
        ...state,
        [action.payload.groupKey]: {
          ...state[action.payload.groupKey],
          history: historyAdded,
          currentFilter: action.payload.value,
        },
      };
    }
    case "history.remove": {
      const historyDeleted = new Set(state[action.payload.groupKey].history);
      historyDeleted.delete(action.payload.value);
      return {
        ...state,
        [action.payload.groupKey]: {
          ...state[action.payload.groupKey],
          history: historyDeleted,
          currentFilter: state[action.payload.groupKey].currentFilter,
        },
      };
    }

    case "history.update":
      return {
        ...state,
        [action.payload.groupKey]: {
          ...state[action.payload.groupKey],
          currentFilter: action.payload.value,
        },
      };
    default:
      return state;
  }
}
