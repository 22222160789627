import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment-timezone";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BadgeGroup from "components/badge/BadgeGroup";
import { TeeTimeSlot } from "./TeeTimeSlot";

import "./teesheet.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

interface IProps {
  numberOfDays: number;
  onClick: (date: Date) => void;
  selectedDate: Date;
}
function getInitDates(days: number) {
  const dates = [new Date()];
  for (let i = 1; i <= days; i++) {
    dates.push(new Date(new Date().setDate(new Date().getDate() + i)));
  }
  return dates;
}

function DateBar(props: IProps) {
  const availableDates = getInitDates(props.numberOfDays);
  const selectedDate = props.selectedDate;
  const { t, i18n } = useTranslation();

  return (
    <div className="flex">
      {availableDates.map((date, idx) => {
        return (
          <div
            className={`flex-1 flex-row cursor-pointer uppercase mx-2`}
            key={idx}
            onClick={() => props.onClick(date)}
          >
            <span className="flex justify-center items-center flex-col">
              <span className="text-xs text-semibold text-gray mb-2">
                {`${moment(date).format("MMM")} ${moment(date).format("DD")}`}
              </span>
              {idx === 0 ? (
                <span
                  className={classNames(
                    "overflow-hidden pr-3 pl-3 pb-1 pt-1 rounded-medium text-bold date-bar-unselected",
                    {
                      "date-bar-selected":
                        props.selectedDate?.getDate() === date.getDate() &&
                        props.selectedDate?.getMonth() === date.getMonth(),
                    },
                  )}
                >
                  {t("elements.tee_sheet.date_bar.001")}
                </span>
              ) : (
                <span
                  className={classNames(
                    "overflow-hidden pr-3 pl-3 pb-1 pt-1 rounded-medium text-bold date-bar-unselected",
                    {
                      "date-bar-selected":
                        props.selectedDate?.getDate() === date.getDate() &&
                        props.selectedDate?.getMonth() === date.getMonth(),
                    },
                  )}
                >
                  {`${moment(date).format("ddd")}`}
                </span>
              )}
            </span>
          </div>
        );
      })}
    </div>
  );
}

export default DateBar;
