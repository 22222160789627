import { APIGet, IAPIResponse } from "api/protocols";
import { CancelToken } from "axios";
import { IFacility } from "redux/reducers/models/facility";

const apiVersion = "2024-04";
const baseClientUrl = "/" + apiVersion + "/customer/client";

export interface IClient {
  address_line_1: string;
  address_line_2: string;
  city: string;
  country_code: string;
  country_id: number;
  country_name: string;
  emblem_source_url: string;
  full_name: string;
  id: number;
  logo_source_url: string;
  long_name: string;
  phone: string;
  postal: string;
  province_code: string;
  province_id: number;
  province_name: string;
  website_url: string;
  facilities?: Array<IFacility>;
  short_name?: string;
}

export interface IClientRes extends IAPIResponse {
  data: Array<IClient>;
}

export async function GetClient(
  params: { id?: number; short_name?: string; collect?: boolean },
  useGlobalLoader: boolean,
): Promise<IClientRes> {
  return await APIGet(baseClientUrl, params, useGlobalLoader);
}
