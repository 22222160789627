import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

import { StatusCode } from "api/protocols";

import { Select } from "components/select/index";
import Card from "components/card/Card";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import { NotificationType } from "components/notificationBar/NotificationBar";
import Page from "components/page/Page";

import { isEqualWith } from "lodash";
import { useTranslation } from "react-i18next";
import { ICustomer } from "redux/reducers/models/customer";
import GolferCard from "components/bookingPopUp/golferCard/GolferCard";
import Sheet from "components/sheet/Sheet";
import NewCustomer, { ICustomerInfoState } from "components/newCustomer/NewCustomer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { customerErrorMessage } from "helpers/Helpers";
import { showError } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";
import { ITournament } from "redux/reducers/models/tournament";
import Icon from "components/icon/Icon";
import { IAccountingReferenceTypes } from "./AccountNew";
import { GetAccount, IPutAccount, PutAccount } from "api/rpc/2024-04/clientAdmin/client/accounts";
import { GetAccountingReference } from "api/rpc/2024-04/clientAdmin/client/accountingReference";
import { GetTournament } from "api/rpc/2024-04/clientAdmin/tournament/tournament";
import { GetCustomer, PostCustomer } from "api/rpc/2022-09/clientAdmin/customer/customer";
import Checkbox from "components/form/checkbox/Checkbox";

interface IAccount {
  id: number;
  title: string;
  account_number: string;
  limit: string;
  limit_is_valid: boolean;
  accounting_reference_id: string;
  customer: ICustomer;
  tournament_id: number;
  global: boolean;
}

interface IChangeCustomerState {
  showChangeCustomer: boolean;
  showNewCustomer: boolean;
  customerQuery: string;
  customerSearching: boolean;
  customerSearchResult: Array<ICustomer>;
  selectedCustomer: ICustomer;
  currentCustomer: any;
}

interface IFilterState {
  tournaments: Array<ITournament>;
  tournamentQuery: string;
  tournamentSearching: boolean;
  selectedTournament: ITournament;
}

export default function AccountEdit() {
  const { accountId } = useParams<{ accountId: string }>();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const { Option } = Select;
  const dispatch = useAppDispatch();
  const [account, setAccount] = useState<IAccount>(undefined);

  const [accountBeforeChanges, setAccountBeforeChanges] = useState<IAccount>(undefined);
  const [tournaments, setTournaments] = useState<Array<ITournament>>([]);
  const [accountingReferenceTypes, setAccountingReferenceTypes] = useState<IAccountingReferenceTypes[]>(undefined);

  const [changeCustomerState, setChangeCustomerState] = useState<IChangeCustomerState>({
    showChangeCustomer: false,
    showNewCustomer: false,
    customerQuery: "",
    customerSearching: false,
    customerSearchResult: [],
    selectedCustomer: null,
    currentCustomer: null,
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    tournaments: [],
    tournamentQuery: "",
    tournamentSearching: false,
    selectedTournament: null,
  });

  useEffect(() => {
    void loadAccount();
    void loadAccountingReferences();
  }, []);

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = setTimeout(() => {
        void search(changeCustomerState.customerQuery);
      }, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      setChangeCustomerState(prevState => ({ ...prevState, customerSearchResult: [] }));
    };
  }, [changeCustomerState.customerQuery]);

  const search = async (guestSearchQuery: string) => {
    try {
      if (guestSearchQuery === "") {
        setChangeCustomerState(prevState => ({ ...prevState, customerSearchResult: [] }));
        return;
      } else {
        const guestResults = await searchCustomers(guestSearchQuery);
        setChangeCustomerState(prevState => ({ ...prevState, searching: false, customerSearchResult: guestResults }));
      }
    } catch (error) {
      console.log("err", error);
    }
    return;
  };

  async function searchCustomers(params: string) {
    const customerRes = await GetCustomer({ search: params }, false);

    if (customerRes.status !== StatusCode.OK) {
      console.log(customerRes?.data);
      return;
    }

    return customerRes?.data;
  }

  async function loadAccount() {
    const accountResponse = await GetAccount({ id: Number(accountId), extended: true });

    if (
      accountResponse.status !== StatusCode.OK ||
      accountResponse?.data?.length === undefined ||
      accountResponse.data.length === 0
    ) {
      return;
    }

    const tournamentRes = await GetTournament(null, true);

    if (tournamentRes.status === StatusCode.OK) {
      setTournaments(tournamentRes.data);
    } else {
      dispatch(showError(tournamentRes.message));
    }

    const tournaments = tournamentRes.data;

    let currentTournament: ITournament;

    for (let i = 0; i < tournaments.length; i++) {
      if (tournaments[i].id === accountResponse.data[0].tournament_id) {
        currentTournament = tournaments[i];
      }
    }

    const limit = accountResponse?.data[0]?.limit?.toString() ?? "";

    setAccount({
      id: isNaN(parseInt(accountId)) ? 0 : parseInt(accountId),
      title: accountResponse?.data[0]?.title?.toString() ?? "",
      account_number: accountResponse?.data[0]?.account_number?.toString() ?? "",
      limit: limit,
      limit_is_valid: stringIsNumber(limit),
      accounting_reference_id: accountResponse?.data[0]?.accounting_reference_id?.toString() ?? "0",
      customer: accountResponse?.data[0]?.customer,
      tournament_id: accountResponse?.data[0]?.tournament_id,
      global: accountResponse?.data[0]?.global,
    });

    setFilterState(prevState => ({ ...prevState, selectedTournament: currentTournament }));
  }

  async function loadAccountingReferences() {
    const accountingResponse = await GetAccountingReference(
      {
        group: "accounts_receivable",
      },
      true,
    );

    if (accountingResponse.status !== StatusCode.OK) {
      return;
    }

    setAccountingReferenceTypes(accountingResponse.data);
  }

  function handleChange(event: any) {
    const id = event?.target?.id;
    const value = event?.target?.value;

    if (id === undefined || value === undefined) {
      return;
    }

    setAccount(prevState => ({ ...prevState, [id]: value }));
  }

  function handleDropDownChange(id: any) {
    setAccount(prevState => ({ ...prevState, accounting_reference_id: id }));
  }

  function stringIsNumber(value: string) {
    if (value === undefined || value.length === 0) {
      return false;
    } else {
      const valueCharacters = value.split("");
      return valueCharacters.every((v: any) => !Number.isNaN(Number(v)));
    }
  }

  function handleNumberChange(event: any, propertyToChange: string, validFlagToChange: string) {
    const value = event?.target?.value;

    if (value === undefined) {
      return;
    }

    setAccount(prevState => ({
      ...prevState,
      [propertyToChange]: value,
      [validFlagToChange]: stringIsNumber(value),
    }));
  }

  async function saveAccount() {
    if (account.title === "" || account.account_number === "" || !account.limit_is_valid) {
      return;
    }

    console.log("Account ID", account.id);
    const putAccount: IPutAccount = {
      id: account.id,
      title: account.title,
      account_number: account.account_number,
      limit: Number(account.limit),
      accounting_reference_id: account.accounting_reference_id === "0" ? null : Number(account.accounting_reference_id),

      customer_id: account.customer === null ? null : account.customer.id,
      tournament_id: account.tournament_id,
      global: account.global,
    };

    const putAccountResponse = await PutAccount(putAccount, true);

    if (putAccountResponse.status !== StatusCode.OK) {
      return;
    }

    history.push("/admin/settings/accounts/overview/" + String(accountId));
  }

  function unsavedChangesExist() {
    if (accountBeforeChanges === undefined) {
      if (account && accountingReferenceTypes) {
        setAccountBeforeChanges(account);
      }
      return false;
    }

    return !isEqualWith(accountBeforeChanges, account);
  }

  function cancelUnsavedChanges() {
    setAccount(accountBeforeChanges);
  }

  function closeChangePlayer() {
    setChangeCustomerState(prevState => ({
      ...prevState,
      showNewCustomer: false,
      currentCustomer: null,
      showChangeCustomer: false,
      customerQuery: "",
      customerSearchResult: [],
      selectedCustomer: null,
    }));
  }

  function changeCustomer() {
    setAccount(prevState => ({ ...prevState, customer: changeCustomerState.selectedCustomer }));
    console.log("Current Customer", changeCustomerState.currentCustomer);
    console.log("Selected Customer", changeCustomerState.selectedCustomer);
    setChangeCustomerState(prevState => ({
      ...prevState,
      currentCustomer: null,
      showChangeCustomer: false,
      customerSearchResult: [],
      customerQuery: "",
      selectedCustomer: null,
    }));
  }

  function handleShowCustomerChange(customer: any) {
    setChangeCustomerState(prevState => ({
      ...prevState,
      showChangeCustomer: true,
      showNewCustomer: false,
      customerQuery: "",
      customerSearchResult: [],
      currentCustomer: customer,
    }));
  }

  async function createNewCustomer(customerInfo: ICustomerInfoState) {
    const inputError =
      customerInfo.firstName === "" ||
      customerInfo.lastName === "" ||
      (customerInfo.emailAddress === "" && customerInfo.phoneNumber === "");

    if (inputError) {
      //uiActions.showError("Please fill all fields");
    }

    const customerRes = await PostCustomer(
      {
        first_name: customerInfo.firstName,
        last_name: customerInfo.lastName,
        phone: customerInfo.phoneNumber || null,
        email: customerInfo.emailAddress || null,
        state: "disabled",
      },
      true,
    );

    if (customerRes.status !== StatusCode.OK) {
      dispatch(showError(customerErrorMessage(t, customerRes?.data?.message)));
      return;
    }
    setChangeCustomerState(prevState => ({
      ...prevState,
      showNewCustomer: false,
      selectedCustomer: customerRes.data.data,
    }));
  }

  function changePlayerHandleCustomerSearch(query: string) {
    setChangeCustomerState(prevState => ({ ...prevState, customerQuery: query }));
  }

  function handleChangePlayerSelection(id: number, customer: ICustomer) {
    setChangeCustomerState(prevState => ({ ...prevState, selectedCustomer: customer }));
  }

  function openNewCustomerSheet() {
    setChangeCustomerState(prevState => ({
      ...prevState,
      showNewCustomer: true,
    }));
  }

  function handleTournamentChange(value: any, id: string, tournament?: ITournament) {
    if (tournament) {
      setAccount(prevState => ({ ...prevState, [id]: tournament.id === -1 ? null : tournament.id }));
      setFilterState(prevState => ({ ...prevState, tournamentQuery: "", selectedTournament: tournament }));
    }
  }

  function removeSelectedTournament() {
    setFilterState(prevState => ({
      ...prevState,
      selectedTournament: null,
      tournaments: [],
      tournamentSearching: false,
    }));
    setAccount(prevState => ({ ...prevState, tournament_id: null }));
  }

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (async () => {
          if (filterState.tournamentQuery !== "") {
            try {
              setFilterState(prevState => ({ ...prevState, tournamentSearching: true }));
              const tournaments = await loadSearchedTournaments(filterState.tournamentQuery);
              if (mounted) {
                setFilterState(prevState => ({ ...prevState, tournaments: tournaments, tournamentSearching: false }));
              }
            } catch (error) {
              console.log("err", error);
            }
            return;
          } else {
            setFilterState(prevState => ({ ...prevState, tournaments: [], tournamentSearching: false }));
          }
        })();
      }, 1000);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [filterState.tournamentQuery]);

  async function loadSearchedTournaments(search: string) {
    const params = {
      search: search,
    };

    const tournamentRes = await GetTournament(params, false);

    if (tournamentRes.status !== StatusCode.OK) {
      dispatch(showError(tournamentRes.message));
      return [];
    }

    return tournamentRes.data;
  }

  function displayTournaments() {
    if (filterState?.tournaments && filterState?.tournaments?.length > 0 && filterState?.tournamentQuery !== "") {
      return filterState?.tournaments;
    } else {
      return tournaments;
    }
  }

  return (
    <>
      <Page
        title={"Edit Account"}
        narrow
        splitLayout
        breadcrumbs={[
          {
            prefix: true,
            label: "Accounts",
            url: "/admin/settings/accounts/overview/" + String(accountId),
          },
        ]}
        notificationBarProps={{
          isVisible: unsavedChangesExist(),
          onAction: saveAccount,
          onCancel: cancelUnsavedChanges,
        }}
      >
        {account && accountingReferenceTypes ? (
          <>
            <Page.Section twoThirds>
              <Card>
                <Card.Section>
                  <Form>
                    <FormLayout>
                      <FormLayout.Group>
                        <Input value={account.title} label={"Title"} id="title" onChange={handleChange} />
                        <Input
                          label={"Account Number"}
                          id="account_number"
                          value={account.account_number}
                          onChange={handleChange}
                        />
                      </FormLayout.Group>
                      <FormLayout.Group>
                        <Input
                          value={account.limit}
                          label={"Limit"}
                          id={!account.limit_is_valid ? "inputError" : ""}
                          onChange={(e: any) => handleNumberChange(e, "limit", "limit_is_valid")}
                        />
                      </FormLayout.Group>
                      <FormLayout.Group>
                        <Select
                          label={"Accounting Reference"}
                          defaultValue={account.accounting_reference_id}
                          onChange={handleDropDownChange}
                        >
                          {accountingReferenceTypes.map((type: any, index: number) => {
                            return (
                              <Option key={index} value={type.id} name={type.title}>
                                <span>{type.title}</span>
                              </Option>
                            );
                          })}
                        </Select>
                      </FormLayout.Group>
                      <FormLayout.Group>
                        <Checkbox
                          id="global"
                          size="medium"
                          value={account.global}
                          checked={account.global}
                          onChange={({ target }) =>
                            setAccount({ ...account, [target.id]: target.value === "true" ? false : true })
                          }
                          label={t("secure.facility.customer.tabs.house_accounts.new_customer_house_account_modal.009")}
                        ></Checkbox>
                      </FormLayout.Group>
                    </FormLayout>
                  </Form>
                </Card.Section>
              </Card>

              <Card>
                <Card.Section>
                  {filterState.selectedTournament ? (
                    <div>
                      <div>
                        <p className="event-label">Tournament</p>
                      </div>

                      <div className="selected-container">
                        <div className="event-name">
                          <div>{filterState.selectedTournament.name}</div>

                          <div>
                            <button className="" onClick={removeSelectedTournament}>
                              <Icon style="far" icon="times" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <Select
                        showSearch
                        label={"Tournament"}
                        onSearch={(query: string) =>
                          setFilterState(prevState => ({ ...prevState, tournamentQuery: query }))
                        }
                        onChange={(value: any, tournament: ITournament) =>
                          handleTournamentChange(value, "tournament_id", tournament)
                        }
                        placeholder={"Search tournaments..."}
                        allowClear
                        searchValue={filterState.tournamentQuery}
                        showDropDownOnFocus={true}
                        searching={filterState.tournamentSearching}
                      >
                        <Option value={-1} extraValues={{ name: "None", id: -1 }}>
                          <div className="text-semibold text-lg">None</div>
                        </Option>
                        {displayTournaments()?.map((tournament: any, index: number) => {
                          return (
                            <Option value={tournament?.id} extraValues={tournament} key={index}>
                              <div className="text-semibold text-lg">{tournament.name}</div>
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  )}
                </Card.Section>
              </Card>
            </Page.Section>
            <Page.Section oneThird>
              <Card
                title={"Customer"}
                titleActions={[
                  {
                    action: () => handleShowCustomerChange(account.customer),
                    content: "Edit",
                  },
                ]}
              >
                <Card.Section>
                  <div className="order-customer">
                    {account.customer ? (
                      <div>
                        <p>{account.customer.full_name}</p>
                        <p>{account.customer.email}</p>
                        <p>{account.customer.phone}</p>
                        <p>{account.customer.customer_type}</p>
                      </div>
                    ) : (
                      <div>
                        <p>{"No Customer"}</p>
                      </div>
                    )}
                  </div>
                </Card.Section>
              </Card>
            </Page.Section>
          </>
        ) : null}
      </Page>

      <Sheet
        title={changeCustomerState.showNewCustomer ? "New Customer" : "Change Customer"}
        open={changeCustomerState.showChangeCustomer}
        size="small"
        closable
        onCancel={closeChangePlayer}
        onOk={changeCustomer}
        okText={"Save"}
        okDisabled={changeCustomerState.selectedCustomer ? false : true}
        overflow
      >
        {changeCustomerState.showNewCustomer ? (
          <NewCustomer
            newCustomerSheetActive={changeCustomerState.showNewCustomer}
            onCancel={closeChangePlayer}
            onOk={createNewCustomer}
            searchValue={changeCustomerState.customerQuery}
          />
        ) : (
          <>
            {changeCustomerState.currentCustomer !== null ? (
              <div>
                <span>{"Previous Customer"}</span>

                <GolferCard
                  email={changeCustomerState.currentCustomer?.email}
                  name={changeCustomerState.currentCustomer?.full_name}
                  memberCode={changeCustomerState.currentCustomer?.member_code}
                  customerType={changeCustomerState.currentCustomer?.customer_type}
                  phone={changeCustomerState.currentCustomer?.phone}
                />

                <FontAwesomeIcon
                  className="ml-auto mr-auto mt-4 mb-2 block text-primary-ui-colour"
                  icon={["far", "arrow-circle-down"]}
                  size="2x"
                />
              </div>
            ) : null}

            <span>{"New Customer"}</span>
            {changeCustomerState.selectedCustomer ? (
              <GolferCard
                closable
                removeGolfer={() =>
                  setChangeCustomerState(prevState => ({
                    ...prevState,
                    selectedCustomer: null,
                    playerSearchResult: [],
                    customerQuery: "",
                  }))
                }
                email={changeCustomerState.selectedCustomer.email}
                name={changeCustomerState.selectedCustomer.full_name}
                memberCode={changeCustomerState.selectedCustomer.member_code}
                customerType={changeCustomerState.selectedCustomer.customer_type}
                phone={changeCustomerState.selectedCustomer.phone}
              />
            ) : (
              <Select
                showSearch
                className={`flex justify-center align-center w-full h-10 position-relative z-20 text-black font-medium appearance-none border-none focus:outline-none placeholder-gray-200`}
                onSearch={(query: string) => changePlayerHandleCustomerSearch(query)}
                onChange={(id: number, customer: ICustomer) => handleChangePlayerSelection(id, customer)}
                placeholder={"Search player..."}
                allowClear
                searchValue={changeCustomerState.customerQuery}
                showDropDownOnFocus={true}
                searching={changeCustomerState.customerSearching}
              >
                <div className="ui-select-dropdown-list-item" onClick={() => openNewCustomerSheet()}>
                  <p>{"New Customer"}</p>
                </div>
                {changeCustomerState.customerSearchResult?.map((customer, index) => {
                  return (
                    <Option key={index} value={customer.id} name={customer.full_name} extraValues={customer}>
                      <div className="flex justify-between">
                        <div>
                          <div className="text-semibold text-lg">{customer?.full_name}</div>
                          <div className="text-sm text-gray-500">{customer.customer_type}</div>
                          <div className="text-sm text-gray-500">{customer.email}</div>
                          <div className="text-sm text-gray-500">{customer.phone ? customer.phone : null}</div>
                        </div>

                        <div className="font-medium text-base text-gray-500 self-end">{customer.member_code}</div>
                      </div>
                    </Option>
                  );
                })}
              </Select>
            )}
          </>
        )}
      </Sheet>
    </>
  );
}
