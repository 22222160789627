import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import axios, { CancelToken } from "axios";
import { GetSeasonLeaderboard, GetSeasonLeaderboardTotals } from "api/rpc/2024-04/guest/league/scoring/leaderboards";
import { StatusCode } from "api/protocols";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { loadLeague, loadFacility } from "redux/actions/customer/leagueRegistration";
import { showError } from "redux/actions/ui";

import LeagueHeader from "../LeagueHeader/LeagueHeader";
import Page from "components/page/Page";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import { TLeagueSeasonLeaderboard, TLeagueSeasonLeaderboardTotal } from "redux/reducers/models/league";

interface IParams {
  facilityShortName: string;
  leagueHandle: string;
  seasonLeaderboardId: string;
}

interface ILeaderboardTotalsState {
  defaultSorted: boolean;
  sortByType: string;
  sortByDirection: boolean;
}

export default function LeagueSeasonLeaderboardTotals() {
  const { facilityShortName, leagueHandle, seasonLeaderboardId } = useParams<IParams>();
  const { leagueRegistrationStore } = useAppSelector(store => store);
  const dispatch = useAppDispatch();

  const [state, setState] = useState<ILeaderboardTotalsState>({
    defaultSorted: false,
    sortByType: "default",
    sortByDirection: false,
  });

  const [leaderboard, setLeaderboard] = useState<TLeagueSeasonLeaderboard>(undefined);
  const [totals, setTotals] = useState<Array<TLeagueSeasonLeaderboardTotal>>(undefined);

  useEffect(() => {
    if (!leagueRegistrationStore.facility) {
      void dispatch(loadFacility(facilityShortName, true));
    }
  }, []);

  useEffect(() => {
    if (leagueRegistrationStore.facility && !leagueRegistrationStore.league) {
      void dispatch(loadLeague(leagueRegistrationStore.facility.id, leagueHandle, true));
    }
    const source = axios.CancelToken.source();
    void loadLeaderboard(source.token);
    return () => source.cancel();
  }, [leagueRegistrationStore.facility]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadLeaderboardTotals(source.token);
    return () => source.cancel();
  }, []);

  useEffect(() => {
    if (!state.defaultSorted && totals) {
      void sortByDefault();
    }
  }, [totals]);

  async function loadLeaderboard(token?: CancelToken) {
    const leaderboardRes = await GetSeasonLeaderboard(
      {
        facility_id: leagueRegistrationStore.facility?.id,
        league_handle: leagueHandle,
        id: seasonLeaderboardId,
      },
      token ? false : true,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (leaderboardRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading leaderboard")); // TODO: Translation
      return;
    }

    setLeaderboard(leaderboardRes.data[0]);
  }

  async function loadLeaderboardTotals(token?: CancelToken) {
    const totalRes = await GetSeasonLeaderboardTotals(
      { season_leaderboard_id: seasonLeaderboardId },
      token ? false : true,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (totalRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading leaderboard totals")); // TODO: Translation
      return;
    }

    setTotals(totalRes.data);
  }

  function sortLeaderboard(sortType: "default" | "bonus" | "participation" | "total" | "overall", sortValue: string) {
    const currentTotals = [...totals];
    let direction: boolean = state.sortByDirection;
    if (state.sortByType !== sortType) {
      direction = true;
    } else if (direction === false) {
      //Descending order, return to default order
      void sortByDefault();
      return;
    } else {
      direction = !direction;
    }

    const sortedTotals = currentTotals.sort((currentTotal, nextTotal) => {
      //Sort null values last
      if (!currentTotal?.[sortValue]) {
        return 1;
      } else if (!nextTotal?.[sortValue]) {
        return -1;
      } else if (sortType == "overall") {
        const currentPlayerPoints = currentTotal?.total + currentTotal?.bonus + currentTotal?.participation;
        const nextPlayerPoints = nextTotal?.total + nextTotal?.bonus + nextTotal?.participation;
        return direction ? nextPlayerPoints - currentPlayerPoints : currentPlayerPoints - nextPlayerPoints;
      } else {
        return direction
          ? nextTotal?.[sortValue] - currentTotal?.[sortValue]
          : currentTotal?.[sortValue] - nextTotal?.[sortValue];
      }
    });
    setState(prevState => ({ ...prevState, sortByType: sortType, sortByDirection: direction }));
    setTotals(sortedTotals);
  }

  function sortByDefault() {
    if (!totals) {
      return;
    }
    const currentTotals = [...totals];
    const sortedTotals = currentTotals?.sort((currentTotal, nextTotal) => {
      if (!currentTotal?.total) {
        return 1;
      } else if (!nextTotal?.total) {
        return -1;
      } else {
        const currentPlayerPoints = currentTotal?.total + currentTotal?.bonus + currentTotal?.participation;
        const nextPlayerPoints = nextTotal?.total + nextTotal?.bonus + nextTotal?.participation;
        return nextPlayerPoints - currentPlayerPoints;
      }
    });

    setState(prevState => ({
      ...prevState,
      sortByType: "overall",
      sortByDirection: true,
      defaultSorted: true,
    }));

    setTotals(sortedTotals);
  }

  return (
    <>
      <LeagueHeader facility={leagueRegistrationStore.facility} league={leagueRegistrationStore.league} />
      <Page
        title={leaderboard?.title ?? ""}
        breadcrumbs={[
          {
            prefix: true,
            label: "Leaderboards", // TODO: Translation
            url: `/league/${facilityShortName}/${leagueHandle}/scoring/season-leaderboard`,
          },
        ]}
      >
        <DataTable
          columns={[
            { label: "Rank", width: "5%" }, // TODO: Translation
            { label: leaderboard?.organization === "team" ? "Team" : "Player" }, // TODO: Translation
            {
              label: "bonus",
              content: (
                <p onClick={() => sortLeaderboard("bonus", "bonus")} className="leaderboard-header">
                  {"Bonus"} {/* TODO: Translation */}
                  <FontAwesomeIcon
                    style={{ visibility: state?.sortByType === "bonus" ? "visible" : "hidden" }}
                    icon={["far", state?.sortByDirection ? "arrow-down-long" : "arrow-up-long"]}
                  />
                </p>
              ),
              width: "11%",
            },
            {
              label: "participation",
              content: (
                <p onClick={() => sortLeaderboard("participation", "participation")} className="leaderboard-header">
                  {"Participation"} {/* TODO: Translation */}
                  <FontAwesomeIcon
                    style={{ visibility: state?.sortByType === "participation" ? "visible" : "hidden" }}
                    icon={["far", state?.sortByDirection ? "arrow-down-long" : "arrow-up-long"]}
                  />
                </p>
              ),
              width: "11%",
            },
            {
              label: "season",
              content: (
                <p onClick={() => sortLeaderboard("total", "total")} className="leaderboard-header">
                  {"Season"} {/* TODO: Translation */}
                  <FontAwesomeIcon
                    style={{ visibility: state?.sortByType === "total" ? "visible" : "hidden" }}
                    icon={["far", state?.sortByDirection ? "arrow-down-long" : "arrow-up-long"]}
                  />
                </p>
              ),
              width: "11%",
            },
            {
              label: "overall",
              content: (
                <p onClick={() => sortLeaderboard("overall", "overall")} className="leaderboard-header">
                  {"Total"} {/* TODO: Translation */}
                  <FontAwesomeIcon
                    style={{ visibility: state?.sortByType === "overall" ? "visible" : "hidden" }}
                    icon={["far", state?.sortByDirection ? "arrow-down-long" : "arrow-up-long"]}
                  />
                </p>
              ),
              width: "11%",
            },
          ]}
          loading={totals === undefined}
        >
          {totals?.map((total, index) => (
            <tr key={total.id}>
              <td>{index + 1}</td>
              <td>{total.customer ? total.customer.full_name : total.team?.name}</td>
              <td>{total.bonus}</td>
              <td>{total.participation}</td>
              <td>{total.total}</td>
              <td>{total.total + total.participation + total.bonus}</td>
            </tr>
          ))}
        </DataTable>
      </Page>
    </>
  );
}
