import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios, { CancelToken } from "axios";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { GetModifierGroup } from "api/rpc/2024-04/facilityAdmin/product/modifier/modifierGroup";

import { showError } from "redux/actions/ui";
import { TModifierGroup } from "redux/reducers/models/facility";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";

import "../facility/facility.scss";

export default function ModifierGroup() {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();

  const [modifierGroups, setModifierGroups] = useState<TModifierGroup[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadModifierGroups(source.token);
    return () => source.cancel();
  }, []);

  async function loadModifierGroups(token?: CancelToken) {
    if (modifierGroups !== undefined) {
      setModifierGroups(undefined);
    }

    const res = await GetModifierGroup(null, token ? false : true, token);

    if (token && token.reason) {
      return;
    }

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error retrieving modifier groups.")); // TODO: Translation
    }

    setModifierGroups(res.status !== StatusCode.OK ? [] : res.data);
  }

  const primaryAction = {
    content: t("secure.facility.settings.modifier_group.modifier_group.001"),
    action: () => history.push("/admin/settings/modifier_group/new"),
  };

  return (
    <Page title={t("secure.facility.settings.modifier_group.modifier_group.002")} narrow primaryAction={primaryAction}>
      <DataTable
        columns={[
          { label: t("secure.facility.settings.modifier_group.modifier_group.003") }, // TODO: Translation
        ]}
        loading={modifierGroups === undefined}
      >
        {modifierGroups?.map(group => (
          <tr
            key={group.id}
            className="clickable"
            onClick={() => history.push("/admin/settings/modifier_group/" + String(group.id))}
          >
            <td>{group.title}</td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
