import React, { ChangeEvent, useEffect, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { TProductMetaData } from "api/rpc/2024-04/facilityAdmin/product/product";
import { GetCustomerType } from "api/rpc/2024-04/facilityAdmin/customer/type";
import { TCustomerType } from "redux/reducers/models/facility";
import { formatDate } from "helpers/Helpers";

import Spin from "components/spin/spin";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import Checkbox from "components/form/checkbox/Checkbox";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import { Select } from "components/select/";

type MembershipOptionType = keyof TProductMetaData;

interface IProductMembershipOptionsProps {
  isVisible: boolean;
  applicationFilter: "green_fee" | "power_cart" | null;
  optionProps: TProductMetaData;
  setOptionProps: React.Dispatch<React.SetStateAction<TProductMetaData>>;
}

export default function ProductMembershipOptions(props: IProductMembershipOptionsProps) {
  const { isVisible, applicationFilter, optionProps, setOptionProps } = props;
  const { t, i18n } = useTranslation();
  const { Option } = Select;

  const [dateState, setDateState] = useState({
    hasExpiry: false,
    expiryDate: new Date(),
  });

  const [types, setTypes] = useState<TCustomerType[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (types !== undefined) {
      return;
    }
    void loadCustomerTypes(source.token);
    return () => source.cancel();
  }, [isVisible]);

  useEffect(() => {
    // reset entire options if removing applicationFilter -- always reset customer type on applicationFilter change
    if (applicationFilter === null) {
      setOptionProps({ customer_type_id: -1, expiry: null, membership_type: "" });
    } else {
      setOptionProps(prev => ({ ...prev, customer_type_id: -1 }));
    }
  }, [applicationFilter]);

  async function loadCustomerTypes(token?: CancelToken) {
    const res = await GetCustomerType(null, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    setTypes(res.status !== StatusCode.OK ? [] : res.data);
  }

  function handleDateChange(event: ChangeEvent<HTMLInputElement>) {
    const { id, checked } = event.target;

    setDateState({ ...dateState, hasExpiry: !dateState.hasExpiry });
    setOptionProps({ ...optionProps, expiry: checked ? formatDate(dateState.expiryDate) : null });
  }

  return (
    <>
      {isVisible && (
        <Card title={t("secure.facility.product.product_new.044")}>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Select
                  label={t("secure.facility.product.product_new.045")}
                  onChange={(value: string) =>
                    setOptionProps({
                      ...optionProps,
                      membership_type: value,
                    })
                  }
                  className="dropdown-max_height"
                >
                  {membershipOptions(t).map((type: any, index: number) => {
                    return (
                      <Option key={index} value={type.id} name={type.title}>
                        {type.title}
                      </Option>
                    );
                  })}
                </Select>
                <Select
                  label={t("secure.facility.product.product_new.048")}
                  onChange={(value: any) =>
                    setOptionProps({
                      ...optionProps,
                      customer_type_id: value,
                    })
                  }
                  defaultValue={optionProps.customer_type_id}
                  className="dropdown-max_height"
                >
                  {types ? (
                    types
                      .filter(val => val.application === applicationFilter)
                      .map((type, index) => (
                        <Option key={type.id} value={type.id} name={type.title}>
                          {type.title}
                        </Option>
                      ))
                  ) : (
                    <Spin />
                  )}
                </Select>
              </FormLayout.Group>

              <FormLayout.Group>
                <div className="membership-expiry">
                  <Checkbox
                    id="expiry"
                    size="medium"
                    value={dateState.hasExpiry}
                    checked={dateState.hasExpiry}
                    onChange={event => handleDateChange(event)}
                    label={t("secure.facility.product.product_new.049")}
                  />
                  {dateState.hasExpiry && (
                    <div id="membership-date-selector">
                      <span>{t("secure.facility.product.product_new.050")}</span>
                      <DatePickerInput
                        months={1}
                        position={"center"}
                        startingDate={dateState.expiryDate}
                        setStartingDate={(selectedDate: Date) =>
                          setOptionProps({
                            ...optionProps,
                            expiry: formatDate(selectedDate),
                          })
                        }
                      />
                    </div>
                  )}
                </div>
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>
      )}
    </>
  );
}

const membershipOptions = (t: TFunction<"translation", undefined>) => {
  return [
    {
      id: "single_course",
      title: t("secure.facility.product.product_new.002"),
    },
    {
      id: "multi_course",
      title: t("secure.facility.product.product_new.003"),
    },
  ];
};
