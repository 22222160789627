import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import axios, { CancelToken } from "axios";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import {
  GetTeesheetBookingFee,
  IPutTeesheetBookingFee,
  PutBookingFeeCustomerType,
  PutTeesheetBookingFee,
  TeesheetBookingFee,
} from "api/rpc/2024-04/facilityAdmin/teesheet/bookingFee";
import { CustomerTypeResStructure, GetCustomerType } from "api/rpc/2022-09/facilityAdmin/customer/type";
import { GetProduct } from "api/rpc/2022-09/facilityAdmin/product/product";

import { showError } from "redux/actions/ui";
import { IProduct, IVariant } from "redux/reducers/models/product";

import { ignoreOrderCompare, sortObj, unsavedChangesExist } from "helpers/Helpers";
import { LocaleCurrency } from "helpers/Locale";
import { useAppDispatch } from "hooks/redux";
import useModal from "hooks/modals/useModal";

import BookingFeeProductsModal, { TProductModal } from "./BookingFeeProductsModal";
import Page from "components/page/Page";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import Checkbox from "components/form/checkbox/Checkbox";
import { ButtonNew } from "components/buttonNew";
import { Badge } from "components/badge/Badge";
import Search from "components/search/Search";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

const initialBookingFee: TeesheetBookingFee = {
  id: 0,
  booking_window_id: null,
  product: undefined,
  variant: undefined,
  price: 0,
  taxable: false,
  unit: null,
  non_refundable: false,
  days_in_advance_start: null,
  days_in_advance_end: null,
  customer_types: undefined,
};

export default function BookingFee() {
  const { feeId } = useParams<{ feeId: string }>();
  const history = useHistory();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [customerTypeState, setCustomerTypeState] = useState({
    types: [] as Array<CustomerTypeResStructure>,
    selectedIds: [] as Array<number>,
  });

  const [bookingFeePreserved, setBookingFeePreserved] = useState<TeesheetBookingFee>(initialBookingFee);
  const [bookingFee, setBookingFee] = useState<TeesheetBookingFee>(initialBookingFee);

  const { state: productModal, updateModal } = useModal<TProductModal>({
    isOpen: false,
    search: "",
    products: [],
    isLoading: false,
    selectedVariant: null,
    selectedProduct: null,
    clearOnClose: false,
  });

  const [productSearch, setProductSearch] = useState("");

  // search value filters unselected types only
  const unselectedTypes = customerTypeState.types
    ?.filter(val => !bookingFee.customer_types?.find(feeType => feeType.id === val.id))
    ?.filter(
      val =>
        val.full_title?.toLowerCase().includes(productSearch.toLowerCase()) ||
        val.subtitle?.toLowerCase().includes(productSearch.toLowerCase()),
    );

  const selectedTypes = customerTypeState.types?.filter(val =>
    bookingFee.customer_types?.find(feeType => feeType.id === val.id),
  );

  // Capture activeProduct information for productModal
  const activeProduct = useMemo(() => {
    if (bookingFee.product) {
      const active = productModal.products.filter(valu => valu.id === bookingFee.product.id);
      return active;
    }
  }, [bookingFee.product, productModal.products]);

  useEffect(() => {
    if (activeProduct) {
      updateModal({ selectedProduct: activeProduct[0], selectedVariant: activeProduct[0]?.variants[0] });
    }
  }, [activeProduct]);

  // Load data for page
  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadBookingFee(source.token);
    void loadCustomerTypes(source.token);
    void loadProducts(source.token);
    return () => {
      source.cancel();
    };
  }, [feeId]);

  // debounce from <Search /> component
  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadProducts(source.token);
    return () => {
      source.cancel();
    };
  }, [productModal.search]);

  async function loadBookingFee(token?: CancelToken) {
    const res = await GetTeesheetBookingFee({ id: parseInt(feeId), extended: true }, true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      return;
    }

    setBookingFee({
      ...bookingFee,
      ...res.data[0],
      customer_types: res.data[0].customer_types ?? [],
    });
    setBookingFeePreserved({
      ...bookingFeePreserved,
      ...res.data[0],
      customer_types: res.data[0].customer_types ?? [],
    });

    setCustomerTypeState(prev => ({
      ...prev,
      selectedIds: res.data[0].customer_types ? res.data[0].customer_types.map(type => type.id) : [],
    }));
  }

  // GET customer types
  async function loadCustomerTypes(token?: CancelToken) {
    const res = await GetCustomerType({ application: "green_fee" }, token ? false : true, token);

    if (res.status !== StatusCode.OK) {
      return;
    }

    setCustomerTypeState(prev => ({ ...prev, types: res.data }));
  }

  // GET Product
  async function loadProducts(token?: CancelToken) {
    console.log("search products", productModal.search);

    if (!productModal.isLoading) {
      updateModal({ isLoading: true });
    }

    const res = await GetProduct(
      { type: "Booking Fee", search: productModal.search, extended_variants: true },
      token ? false : true,
      token,
    );
    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      updateModal({ isLoading: false });
      return;
    }
    updateModal({ products: res.data, isLoading: false });
  }

  // PUT teesheet booking fee
  async function updateBookingFee() {
    const params: IPutTeesheetBookingFee = {
      id: parseInt(feeId),
      variant_id: bookingFee.variant?.id,
      non_refundable: bookingFee.non_refundable,
      days_in_advance_start: parseInt(bookingFee.days_in_advance_start),
      days_in_advance_end: parseInt(bookingFee.days_in_advance_end),
    };

    const res = await PutTeesheetBookingFee(params, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError(res.data as string));
      return;
    }
  }

  // PUT booking fee customer types
  async function updateCustomerTypes() {
    const res = await PutBookingFeeCustomerType(
      { booking_fee_id: parseInt(feeId), customer_type_ids: customerTypeState.selectedIds },
      true,
    );

    if (res.status !== StatusCode.OK) {
      return;
    }

    const wantedCustomerTypes = customerTypeState.types.filter(type =>
      customerTypeState.selectedIds.includes(type.id),
    );

    setBookingFee(prev => ({ ...prev, customer_types: [...wantedCustomerTypes] }));
    setBookingFeePreserved(prev => ({ ...prev, customer_types: [...wantedCustomerTypes] }));
  }

  // Optionally call updateCustomerTypes() if needed
  function handleUpdate() {
    if (
      !ignoreOrderCompare(
        customerTypeState.selectedIds,
        bookingFee.customer_types?.map(val => val.id),
      )
    ) {
      void updateCustomerTypes();
    }

    if (unsavedChangesExist(bookingFee, bookingFeePreserved)) {
      void updateBookingFee();
    }

    setBookingFeePreserved(prev => ({ ...prev, ...bookingFee }));
  }

  // Reset any changes currently on page
  function handleReset() {
    setBookingFee(bookingFeePreserved);
    setCustomerTypeState(prev => ({ ...prev, selectedIds: bookingFee.customer_types?.map(val => val.id) }));
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setBookingFee(prev => ({ ...prev, [e.target.id]: e.target.value ? parseInt(e.target.value) : null }));
  }

  function headerOnChange(e: ChangeEvent<HTMLInputElement>) {
    const allSelected = ignoreOrderCompare(
      customerTypeState.selectedIds,
      bookingFee.customer_types?.map(val => val.id),
    );

    if (e.target.checked) {
      // Only apply checks to the filtered Unselected types
      setCustomerTypeState(prev => ({
        ...prev,
        selectedIds: prev.types
          ?.map(val => {
            if (
              bookingFee.customer_types?.find(feeType => feeType.id === val.id) ||
              val.full_title.toLowerCase().includes(productSearch.toLowerCase()) ||
              val.subtitle?.toLowerCase().includes(productSearch.toLowerCase())
            ) {
              return val?.id;
            }
          })
          .filter(val => val !== undefined),
      }));
    } else {
      setCustomerTypeState(prev => ({
        ...prev,
        selectedIds: allSelected ? [] : bookingFee.customer_types?.map(val => val.id),
      }));
    }
  }

  // customer_type.id added or removed from state dependant on remove param
  function handleCustomerTypeChange(id: number, remove = false) {
    setCustomerTypeState(prev => ({
      ...prev,
      selectedIds: remove
        ? customerTypeState.selectedIds.filter(val => val !== id)
        : customerTypeState.selectedIds.concat(id),
    }));
  }

  return (
    <Page
      title="Booking Fee"
      narrow
      breadcrumbs={[{ prefix: true, label: "Booking Fees", url: "/admin/settings/tee-sheet/booking-fees" }]}
      notificationBarProps={{
        isVisible:
          (unsavedChangesExist(bookingFee, bookingFeePreserved, setBookingFeePreserved) ||
            !ignoreOrderCompare(
              customerTypeState.selectedIds,
              bookingFee.customer_types?.map(val => val.id),
            )) &&
          bookingFee.days_in_advance_start !== null &&
          bookingFee.days_in_advance_end !== null &&
          !productModal.isOpen, // Hide when user has BookingFeeProductsModal open
        onAction: () => handleUpdate(),
        onCancel: () => handleReset(),
      }}
    >
      <Card title={t("secure.facility.settings.tee_sheets.booking_engine.005")}>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Input
                id="days_in_advance_start"
                value={bookingFee.days_in_advance_start ?? ""}
                type="number"
                label={t("secure.facility.settings.tee_sheets.booking_engine.006")}
                helpText={t("secure.facility.settings.tee_sheets.booking_engine.007")}
                onChange={handleInputChange}
                suffix="days"
                placeholder={t("secure.facility.settings.tee_sheets.booking_engine.008")}
                disabled={bookingFee.days_in_advance_start === undefined}
                error={bookingFee.days_in_advance_start !== bookingFeePreserved.days_in_advance_start}
              />
              <Input
                id="days_in_advance_end"
                value={bookingFee.days_in_advance_end ?? ""}
                type="number"
                label={t("secure.facility.settings.tee_sheets.booking_engine.009")}
                helpText={t("secure.facility.settings.tee_sheets.booking_engine.010")} // TODO: Fix translation
                onChange={handleInputChange}
                suffix="days"
                placeholder={t("secure.facility.settings.tee_sheets.booking_engine.011")}
                disabled={bookingFee.days_in_advance_end === undefined}
                error={bookingFee.days_in_advance_end !== bookingFeePreserved.days_in_advance_end}
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
        <Card.Section>
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center gap-8">
              <ButtonNew size="medium" type="secondary" onClick={() => updateModal({ isOpen: true })}>
                {t("secure.facility.settings.tee_sheets.booking_engine.052")}
              </ButtonNew>
              {bookingFee.product && bookingFee.variant && (
                <div className="booking-engine-variant-badge-container">
                  <Badge type="gray" size="medium">
                    <span>
                      {bookingFee.product.preferred_title
                        ? bookingFee.product.preferred_title
                        : bookingFee.product.title}{" "}
                      {bookingFee?.variant?.title !== bookingFee.product?.title ? ` - ${bookingFee.variant.title}` : ""}{" "}
                      <LocaleCurrency amount={bookingFee.variant.price} />
                    </span>
                  </Badge>
                </div>
              )}
            </div>
            <Checkbox
              id="nonRefundable"
              size="medium"
              value={bookingFee.non_refundable}
              checked={bookingFee.non_refundable}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setBookingFee(prev => ({ ...prev, non_refundable: e.target.checked }))
              }
              label={t("secure.facility.settings.tee_sheets.booking_engine.053")}
            />
          </div>
        </Card.Section>
      </Card>
      <Card title="Customer Types">
        <Card.Section>
          <Search
            historyKey={"unselected-customer_type-search"}
            searchCallback={searchValue => setProductSearch(searchValue)}
            placeholder="Filter unselected customer types.." // TODO: Translation
            debounceTime={150}
          />
        </Card.Section>
        <Card.Section>
          <DataTable<CustomerTypeResStructure>
            columns={[
              {
                label: "",
                content: (
                  <Checkbox
                    size="small"
                    checked={
                      customerTypeState.types
                        .filter(
                          val =>
                            val.full_title?.toLowerCase().includes(productSearch.toLowerCase()) ||
                            val.subtitle?.toLowerCase()?.includes(productSearch.toLowerCase()),
                        )
                        .filter(type => !customerTypeState.selectedIds.includes(type.id)).length === 0
                    }
                    onChange={headerOnChange}
                  />
                ),
                width: "10%",
              },
              { id: "full_title", label: "Type", width: "90%" },
            ]}
            sort={(key, direction) =>
              setCustomerTypeState(prev => ({
                ...prev,
                types: sortObj(customerTypeState.types, key, direction),
              }))
            }
            loading={customerTypeState.types === undefined || bookingFee.customer_types === undefined}
          >
            {bookingFee.customer_types?.length > 0 ? (
              <>
                <tr>
                  <td colSpan={2} style={{ borderBottom: "0" }}>
                    Selected
                  </td>
                </tr>
                {selectedTypes.map(type => {
                  return (
                    <tr
                      key={type.id}
                      className="clickable"
                      onClick={() => handleCustomerTypeChange(type.id, customerTypeState.selectedIds.includes(type.id))}
                    >
                      <td onClick={e => e.stopPropagation()}>
                        <Checkbox
                          size="small"
                          checked={customerTypeState.selectedIds.includes(type.id)}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleCustomerTypeChange(type.id, !e.target.checked)
                          }
                        />
                      </td>
                      <td>{type?.full_title}</td>
                    </tr>
                  );
                })}
              </>
            ) : null}

            {/* Unselected customer types */}
            <tr>
              <td colSpan={2} className={unselectedTypes.length > 0 ? "no-border" : ""}>
                Unselected
              </td>
            </tr>
            {unselectedTypes.map(type => {
              return (
                <tr
                  key={type.id}
                  className="clickable"
                  onClick={() => handleCustomerTypeChange(type.id, customerTypeState.selectedIds.includes(type.id))}
                >
                  <td onClick={e => e.stopPropagation()}>
                    <Checkbox
                      size="small"
                      checked={customerTypeState.selectedIds.includes(type.id)}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleCustomerTypeChange(type.id, !e.target.checked)
                      }
                    />
                  </td>
                  <td>{type?.full_title}</td>
                </tr>
              );
            })}
          </DataTable>
        </Card.Section>
      </Card>

      <BookingFeeProductsModal
        state={productModal}
        updateState={updateModal}
        defaultProduct={bookingFee.product}
        defaultVariant={bookingFee.variant}
        //Reverting to preserved value removes unsavedChanges bug with nested 'variants' OBJ param
        onOk={(selectedProduct: IProduct, selectedVariant: IVariant) =>
          setBookingFee(prev => ({
            ...prev,
            product:
              selectedProduct.id === bookingFeePreserved.product?.id
                ? { ...bookingFeePreserved.product }
                : selectedProduct,
            variant:
              selectedVariant.id === bookingFeePreserved.variant?.id
                ? { ...bookingFeePreserved.variant }
                : selectedVariant,
          }))
        }
        navigateToNewProduct={() => history.push("/admin/product/new")}
      />
    </Page>
  );
}
