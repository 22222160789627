import React, { useEffect, useState } from "react";
import classNames from "classnames";
import "components/text/textStyle/textStyle.scss";

// Available Props

const TextStyle = (props: any) => (
  <span
    className={classNames("ui-text-style", {
      "ui-text-style_positive": props.variation === "positive",
      "ui-text-style_negative": props.variation === "negative",
      "ui-text-style_strong": props.variation === "strong",
      "ui-text-style_subdued": props.variation === "subdued",
    })}
  >
    {props.children}
  </span>
);

export default TextStyle;
