import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import moment from "moment";
import classNames from "classnames";
import { Select } from "components/select/index";
import { StatusCode } from "api/protocols";
import {
  GetKitchenLocationChits,
  GetKitchenLocations,
  PutKitchenChit,
} from "api/rpc/facilityAdmin/facility/kitchen/kitchen";
import { capitalize, isEmpty } from "helpers/Helpers";
import "./kitchen.scss";

import { useTranslation } from "react-i18next";
import DropFilter from "components/dropdown/DropFilter";
import { IProduct } from "redux/reducers/models/product";
import { GetKitchenMeals, IKitchenMeal } from "api/rpc/2022-09/facilityAdmin/facility/kitchen";
import { TKitchenLocation } from "redux/reducers/models/kitchen";

// Replace all IChit with IChitFinal - this represents exactly what is on the back end
interface IChit {
  id: number;
  kitchen_location_id: number;
  table_id: number;
  status: string;
  user_id: number;
  user_full_name: string;
  table_title: string;
  sent_at: string;
  kitchen_location_title: string;
  children_chits: IChit[];
  line_items: ILineItem[];
  selected_chit: boolean;
}

interface ILineItem {
  id: number;
  cart_id: number;
  product_id: number;
  variant_id: number;
  product_title: string;
  preferred_title: string;
  variant_title: string;
  price: number;
  cost: number;
  quantity: number;
  taxable: boolean;
  parent_id: null | any;
  gift_card: boolean;
  total_discount: number;
  subtotal_price: number;
  total_tax: number;
  total_price: number;
  tax_lines: ITaxLines[];
  tax_included: boolean;
  custom: boolean;
  note: string;
  kitchen_chit_id: number;
  kitchen_meal_id: number;
  non_refundable: boolean;
  fulfillment_required: null | any;
  requires_shipping: null | any;
  pre_paid_required: number;
  children_line_items: ILineItem[];
  product: IProduct;
}

interface ITaxLines {
  title: string;
  price: number;
  rate: number;
}

interface IChitProps {
  prefixCls?: string;
  chit: IChit;
  selected: boolean;
  index: number;
}

interface IChitItemSection {
  id: number;
  lineItems: ILineItem[];
  position: number;
  title: string;
}

interface IChitItemProps {
  prefixCls?: string;
  item: ILineItem;
  status?: string;
}

interface IKitchenIdState {
  selected: Array<number> | undefined;
  current: Array<number> | undefined;
  defaultChecked: Array<boolean>;
}

export default function KitchenDisplaySystem(props: any) {
  const [selectedChit, setSelectedChit] = useState<IChit | undefined>(undefined);
  const [chits, setChits] = useState<IChit[]>([]);
  const [kitchenLocations, setKitchenLocations] = useState<TKitchenLocation[]>(undefined);
  const [kitchenMeals, setKitchenMeals] = useState<IKitchenMeal[]>([]);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [kitchenIds, setKitchenIds] = useState<IKitchenIdState>({
    selected: undefined,
    current: undefined,
    defaultChecked: [],
  });

  const [elapsedTimes, setElapsedTimes] = useState<Array<string>>([]);
  const [filteredChits, setFilteredChits] = useState<Array<IChit>>([]);
  const { t, i18n } = useTranslation();

  const FILTER_STATUS = [
    { title: t("secure.facility.settings.kitchen.kitchen_display_system.001"), english: "All Active" },
    { title: t("secure.facility.settings.kitchen.kitchen_display_system.002"), english: "New" },
    { title: t("secure.facility.settings.kitchen.kitchen_display_system.003"), english: "Preparing" },
    { title: t("secure.facility.settings.kitchen.kitchen_display_system.004"), english: "Done" },
    { title: t("secure.facility.settings.kitchen.kitchen_display_system.005"), english: "Archived" },
    { title: t("secure.facility.settings.kitchen.kitchen_display_system.006"), english: "Void" },
  ];

  // states that could be a settingsState
  const [selectedStatusFilter, setSelectedStatusFilter] = useState(FILTER_STATUS[0]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [gridSize, setGridSize] = useState({ row: 3, col: 4 });

  const { Option } = Select;

  const GRID_COUNT = gridSize.col * gridSize.row;

  useEffect(() => {
    const filteredChits = chits
      //.slice(selectedPage <= 1 ? 0 : selectedPage * GRID_COUNT - 1, selectedPage * GRID_COUNT + selectedPage * GRID_COUNT) -- Bugged
      .filter(chit => {
        if (chit.status.toLowerCase() === "archived" && selectedStatusFilter.english !== "Archived") {
          return false;
        } else {
          if (
            selectedStatusFilter.english === "All Active" &&
            chit.status.toLowerCase() !== "archived" &&
            chit.status.toLowerCase() !== "void"
          ) {
            return true;
          } else {
            return chit.status.toLowerCase() === selectedStatusFilter.english.toLocaleLowerCase();
          }
        }
      });

    setFilteredChits(filteredChits);
  }, [chits, selectedStatusFilter]);

  const MAX_PAGES = GRID_COUNT === 1 ? filteredChits.length : Math.ceil(filteredChits.length / GRID_COUNT);

  // On kitchen location change - update chits
  useEffect(() => {
    let mounted = true;

    void loadChits();

    let interval: undefined | NodeJS.Timeout;

    clearInterval(interval);
    interval = setInterval(() => {
      void loadChits();
    }, 20000);

    if (mounted) {
      setForceRefresh(false);
    }

    return () => {
      clearInterval(interval);
      mounted = false;
    };
  }, [kitchenIds.current, forceRefresh]);

  // Get kitchen locations
  useEffect(() => {
    let mounted = true;

    // Move outside of use effect?
    const loadKitchenLocations = async () => {
      const res = await GetKitchenLocations();

      if (res.status === StatusCode.OK) {
        if (res.data?.length > 0) {
          //prepopulate first checkbox
          const defaultChecked = new Array(res.data?.length).fill(false);
          defaultChecked[0] = true;

          setKitchenIds({
            ...kitchenIds,
            selected: [res.data[0]?.id],
            current: [res.data[0]?.id],
            defaultChecked: defaultChecked,
          });
        }

        setKitchenLocations(res.data);
      }
    };

    if (mounted) {
      void loadKitchenLocations();
      void loadKitchenMeals();
    }

    return () => {
      mounted = false;
    };
  }, []);

  const loadKitchenMeals = async () => {
    const getMealsResponse = await GetKitchenMeals(null, true);

    if (getMealsResponse?.status !== StatusCode.OK) {
      return;
    }

    setKitchenMeals(getMealsResponse?.data ?? []);
  };

  // Move outside of use effect?
  const loadChits = async () => {
    const before = moment().utc().subtract(12, "hours").format("YYYY-MM-DD HH:mm:ss");
    const after = moment().utc().add(24, "hours").format("YYYY-MM-DD HH:mm:ss");

    if (kitchenIds.current) {
      const res = await GetKitchenLocationChits({
        kitchen_location_ids: kitchenIds.current,
        extended: true,
        start_date: before,
        end_date: after,
      });

      if (res.status === StatusCode.OK) {
        if (res.data?.length >= 0) {
          setChits(res.data);
        }
      }
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timeout = null;
    function formatTime(timeDifference: moment.Duration) {
      const hours = timeDifference.hours().toString().padStart(2, "0");
      const minutes = timeDifference.minutes().toString().padStart(2, "0");
      const seconds = timeDifference.seconds().toString().padStart(2, "0");
      if (hours === "0" || hours === "00") {
        return `${minutes}:${seconds}`;
      } else {
        return `${hours}:${minutes}:${seconds}`;
      }
    }

    if (selectedStatusFilter.english === "Void" || selectedStatusFilter.english === "Archived") {
      setElapsedTimes([]);
    } else {
      interval = setInterval(() => {
        const newElapsedTimes: string[] = [];
        const currentTime = moment();
        filteredChits.forEach(chit => {
          //Get the time the chit was sent to the kitchen in user's local time
          const chitSentTime = moment.utc(chit.sent_at).local();
          //Get the difference in current time & chit sent time
          const differenceTime = moment.duration(currentTime.diff(chitSentTime));
          const formattedElapsedTime = formatTime(differenceTime);
          newElapsedTimes.push(formattedElapsedTime);
        });
        setElapsedTimes(newElapsedTimes);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [filteredChits]);

  // Chit Component
  const Chit: React.FC<IChitProps> = props => {
    const { prefixCls = "chit", chit, index } = props;

    // const [selected, setSelected] = useState<boolean>(false);
    const ORDER_STATUS = [
      { id: 0, msg: "new" },
      { id: 1, msg: "preparing" },
      { id: 2, msg: "done" },
      { id: 3, msg: "archived" },
      { id: 4, msg: "void" },
      { id: 5, msg: "fire" },
    ];

    const currStatus = ORDER_STATUS.find(stat => stat.msg === chit.status.toLowerCase());

    const [statusId, setStatusId] = useState(currStatus?.id);

    const chitCls = classNames(
      prefixCls,
      {
        [`${prefixCls}-selected`]: props.selected,
        [`${prefixCls}-not-started`]: statusId === 0,
        [`${prefixCls}-preparing`]: statusId === 1,
        [`${prefixCls}-done`]: statusId === 2,
        [`${prefixCls}-archived`]: statusId === 3,
        [`${prefixCls}-void`]: statusId === 4,
        [`${prefixCls}-fire`]: statusId === 5,
      },
      // className,
    );

    const handleSelect = (chit: IChit) => {
      if (selectedChit?.id === chit.id) {
        setSelectedChit(undefined);
      } else {
        setSelectedChit(chit);
      }
    };

    const handleArchiveChit = async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.stopPropagation();

      // Archived status
      const newStatus = ORDER_STATUS.find(stat => stat.id === 3);

      const res = await PutKitchenChit(
        {
          id: chit.id,
          status: newStatus.msg,
        },
        true,
      );

      setForceRefresh(true);
    };

    const handleChangeStatus = async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.stopPropagation();
      // If the status is FIRE, we move it to ARCHIVED
      if (statusId === 5) {
        // ARCHIVED Status
        const newStatus = ORDER_STATUS.find(stat => stat.id === 3);

        const res = await PutKitchenChit(
          {
            id: chit.id,
            status: newStatus.msg,
          },
          true,
        );

        setForceRefresh(true);
      } else if (statusId === 2) {
        // If the status is DONE, we move it to ARCHIVED
        const newStatus = ORDER_STATUS.find(stat => stat.id === 3);

        const id = chit.id;
        const status = newStatus.msg;
        const res = await PutKitchenChit({ id, status }, true);

        setForceRefresh(true);
      } else if (statusId === ORDER_STATUS.length - 1) {
        const newStatus = ORDER_STATUS.find(stat => stat.id === 0);

        const id = chit.id;
        const status = newStatus.msg;
        const res = await PutKitchenChit({ id, status }, true);

        setForceRefresh(true);
      } else {
        const newStatus = ORDER_STATUS.find(stat => stat.id === statusId + 1);

        const id = chit.id;
        const status = newStatus.msg;

        const res = await PutKitchenChit({ id, status }, true);

        setForceRefresh(true);
      }
    };

    const ChitItem: React.FC<IChitItemProps> = props => {
      let icon: IconProp | undefined = undefined;

      switch (props.status?.toLowerCase()) {
        case "done":
          icon = ["fas", "check"];
          break;
        case "preparing":
          icon = ["fas", "arrows-rotate"];
          break;
        case "void":
          icon = ["fas", "xmark"];
          break;
        default:
      }

      return (
        <div className="chit-item">
          <div className="flex flex-1 flex-row align-center">
            <span className="chit-item-primary-info mr-3">{props.item.quantity}</span>
            {props.item?.product_title === props.item?.variant_title ? (
              <span className="chit-item-primary-info">
                {props.item?.preferred_title !== null ? (
                  <span>{props.item?.preferred_title}</span>
                ) : (
                  <span>{props.item?.product_title}</span>
                )}
              </span>
            ) : (
              <span className="chit-item-primary-info">
                {props.item?.preferred_title !== null ? (
                  <span>{props.item?.preferred_title}</span>
                ) : (
                  <span>{props.item?.product_title}</span>
                )}
                <span className="text-subdued"> - {props.item?.variant_title}</span>
              </span>
            )}
            {icon && (
              <div className="ml-auto flex flex-col justify-center">
                <FontAwesomeIcon icon={icon} className="ml-auto" />
              </div>
            )}
          </div>

          <div className="flex flex-col mt-1">
            {props.item.children_line_items?.map((child_line_item, i) =>
              child_line_item.product_title === child_line_item.variant_title ? (
                <span key={i} className="chit-item-secondary-info">
                  {child_line_item.product_title}
                </span>
              ) : (
                <span key={i} className="chit-item-secondary-info">
                  {}
                  {child_line_item.product_title}
                  <span> - {child_line_item.variant_title}</span>
                </span>
              ),
            )}
            <span className="chit-item-secondary-info">{props.item.note}</span>
          </div>
        </div>
      );
    };

    const isChitItemSectionInvalid = (section: IChitItemSection) => {
      return (
        section.id === undefined ||
        section.id === null ||
        isNaN(section.id) ||
        section.position === undefined ||
        section.position === null ||
        isNaN(section.position) ||
        isEmpty(section.title)
      );
    };

    const getChitItemSections = () => {
      const sections: IChitItemSection[] = [];

      props.chit.line_items?.forEach(lineItem => {
        const sectionIndex = sections.findIndex(section => section.id === lineItem?.kitchen_meal_id);
        const meal = kitchenMeals?.find(meal => meal.id === lineItem?.kitchen_meal_id);

        if (sectionIndex !== -1) {
          sections[sectionIndex]?.lineItems?.push({ ...lineItem });
        } else {
          sections.push({
            id: lineItem?.kitchen_meal_id,
            position: meal?.position,
            title: meal?.title,
            lineItems: [{ ...lineItem }],
          });
        }
      });

      sections?.sort((prevSection, nextSection) => {
        if (isChitItemSectionInvalid(prevSection)) {
          return -1;
        } else if (isChitItemSectionInvalid(nextSection)) {
          return 1;
        } else {
          return prevSection.position - nextSection.position;
        }
      });

      return sections;
    };

    const chitNum = index + 1;

    return (
      <div className={chitCls} onClick={() => handleSelect(chit)}>
        {/* Status bar */}
        <div className="chit-info">
          <div className="chit-details">
            <div className="chit-detail-info">
              <span className="chit-table-number">{chitNum}</span>
              <span>{capitalize(ORDER_STATUS[statusId]?.msg)}</span>
            </div>

            <div className="chit-detail-actions">
              {selectedStatusFilter.english !== "Void" && selectedStatusFilter.english !== "Archived" && (
                <div className="chit-detail-action" onClick={handleArchiveChit}>
                  <FontAwesomeIcon icon={["far", "box-archive"]} />
                </div>
              )}
              <div className="chit-detail-action" onClick={handleChangeStatus}>
                <FontAwesomeIcon icon={["far", "arrow-up"]} />
              </div>
            </div>
          </div>

          <hr className="chit-details-divider" style={{ visibility: statusId !== 0 ? "visible" : "hidden" }} />

          <div className="chit-table">
            <div className="chit-table-name">{props.chit.table_title}</div>
            {selectedStatusFilter.english !== "Void" &&
              selectedStatusFilter.english !== "Archived" &&
              !isEmpty(elapsedTimes[index]) && (
                <div className="chit-table-time">
                  <FontAwesomeIcon icon={["far", "clock"]} />
                  <div>{elapsedTimes[index]}</div>
                </div>
              )}
          </div>
          <div className="chit-user">
            <span>{props.chit.user_full_name}</span>
            <span>{props.chit.kitchen_location_title}</span>
          </div>
        </div>

        {/* Chit Items */}
        <div className="chit-items">
          {/* If an EXPO type screen display children instead of line items - MAY 23, Commented OUT - Might utilize later*/}
          {/* {selectedKitchenLocation.type === "expo"
            ? props.chit.children_chits?.map((child_chit, i) => (
                <div key={i}>
                  <div className="chit-child-header px-2 py-1">{child_chit.kitchen_location_title}</div>
                  {child_chit.line_items?.map((line_item, i) => (
                    <ChitItem key={i} item={line_item} status={child_chit.status} />
                  ))}
                </div>
              ))
            : props.chit.line_items?.map((item, i) => <ChitItem key={i} item={item} />)} */}
          {getChitItemSections().map(section => {
            return (
              <React.Fragment key={section.id}>
                {!isChitItemSectionInvalid(section) && <div className="chit-items-meal-section">{section.title}</div>}
                {section?.lineItems?.map(lineItem => {
                  return <ChitItem key={lineItem?.id} item={lineItem} />;
                })}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  };

  function setKitchenLocationIds(filterReturn: Array<Record<string, any>>) {
    //means no filters are wanted from this particular group
    if (filterReturn.length === 0) {
      setKitchenIds({ ...kitchenIds, selected: [] });
      return;
    }

    const selectedIds = filterReturn.map(location => location?.id as number);

    if (selectedIds) {
      setKitchenIds({
        ...kitchenIds,
        current: selectedIds,
      });
    }
  }

  return (
    <div className="ui-kitchen-display">
      {/* Top navigation bar */}
      <div className="kitchen-top-bar">
        {/* Select locations in kitchen */}
        <div style={{ marginLeft: "1rem", marginTop: "1rem" }}>
          {kitchenLocations && (
            <DropFilter
              title={t("secure.facility.settings.kitchen.kitchen_display_system.007")}
              filterData={kitchenLocations}
              filterLabelPropFromData="title"
              filterIdPropFromData="id"
              filterType="Checkbox"
              applyFilters={setKitchenLocationIds}
              // clearFilters={() => setKitchenIds({ ...kitchenIds, selected: [] })}
              defaultCheckboxes={kitchenLocations[0]?.id ? [kitchenLocations[0]?.id] : undefined}
              dropdownSize="36px"
              darkMode
              leftAligned
            />
          )}
        </div>

        <div className="ml-auto mr-5 flex-row flex gap-5">
          {/* Filter Buttons */}
          {FILTER_STATUS.map(status => (
            <button
              key={status.english}
              onClick={() => setSelectedStatusFilter(status)}
              className={
                selectedStatusFilter.english === status.english
                  ? "kitchen-top-bar-text-button-selected"
                  : "kitchen-top-bar-text-button"
              }
            >
              {status.title}
            </button>
          ))}

          {/* Grid Settings - BUGGED */}
          {/* <div className="kitchen-top-bar-button-group mx-2">
            <button
              onClick={() => setGridSize(prev => ({ row: prev.row, col: prev.col + 1 }))}
              className="kitchen-top-bar-text-button p-2"
            >
              +
            </button>
            <div className="mx-2 flex-row">
              <FontAwesomeIcon className="text-white" icon={"table-columns"} />
              <span className="text-white">col {gridSize.col}</span>
            </div>

            <button
              onClick={() => setGridSize(prev => ({ row: prev.row, col: prev.col === 1 ? prev.col : prev.col - 1 }))}
              className="kitchen-top-bar-text-button p-2"
            >
              -
            </button>
          </div>

          <div className="kitchen-top-bar-button-group mx-2">
            <button
              onClick={() => setGridSize(prev => ({ row: prev.row + 1, col: prev.col }))}
              className="kitchen-top-bar-text-button p-2"
            >
              +
            </button>

            <div className="mx-2 flex-row">
              <FontAwesomeIcon className="text-white" icon={"table-rows"} />
              <span className="text-white text-lg">row {gridSize.row}</span>
            </div>

            <button
              onClick={() => setGridSize(prev => ({ row: prev.row <= 1 ? prev.row : prev.row - 1, col: prev.col }))}
              className="kitchen-top-bar-text-button p-2"
            >
              -
            </button>
          </div> */}
        </div>
      </div>

      {/* Switch Chit Pages - BUGGED*/}
      {/* <div className="kitchen-top-bar-page">
        <button
          onClick={() => setSelectedPage(prev => (prev <= 1 ? 1 : prev - 1))}
          className="kitchen-top-bar-text-button p-2"
        >
          <FontAwesomeIcon icon={"arrow-left"} />
        </button>
        <span className="kitchen-top-bar-page-text">
          {selectedPage} / {MAX_PAGES} ({filteredChits.length})
        </span>
        <button
          onClick={() => setSelectedPage(prev => (prev < MAX_PAGES ? prev + 1 : prev))}
          className="kitchen-top-bar-text-button p-2"
        >
          <FontAwesomeIcon icon={"arrow-right"} />
        </button>
      </div> */}

      {/* Chit board */}
      <div className={"grid gap-2 grid-cols-4 p-2 auto-rows-auto overflow-y-scroll m-3 items-start"}>
        {/* Calculate display window, filter, create chits*/}
        {filteredChits.map((item, i) => (
          <Chit index={i} key={item.id} chit={item} selected={item.id === selectedChit?.id} />
        ))}
      </div>
    </div>
  );
}
