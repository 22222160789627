import { APIGet, APIPost, IAPIResponse } from "api/protocols";
import { ILeague, ILeagueParticipantGuest, ILeagueRegistrationFee } from "redux/reducers/models/league";
import { ILeagueRegistrationInput } from "../2024-04/facilityAdmin/league/registration/inputs";

const apiVersion = "2024-04";
const adminType = "guest";
const baseLeagueUrl = "/" + apiVersion + "/" + adminType + "/league";

interface IGetLeague {
  facility_id: number;
  handle: string;
}

interface IGetLeagueResponse {
  data: ILeague[];
  status: number;
}

interface IGetLeagueRegistrationFee {
  facility_id: number;
  league_id: string | number;
}

interface IGetLeagueRegistrationFeeResponse {
  data: ILeagueRegistrationFee[];
  status: number;
}

interface IPostLeagueParticipant {
  facility_id: number;
  order_id: number;
  league_id: string | number;
  customer_id: number;
}

interface IPostLeagueParticipantResponse {
  data: ILeagueParticipantGuest;
  status: number;
}

interface IGetLeagueRegistrationInput {
  league_id: string | number;
  facility_id: number;
}

export interface IPostLeagueRegistrationInputData {
  registration_input_id: number;
  value: string | boolean;
  participant_id: number;
}

interface IPostLeagueRegistrationInputs {
  league_id: string | number;
  facility_id: number;
  registration_input_data: IPostLeagueRegistrationInputData[];
}

export async function GetLeague(params: IGetLeague, useGlobalLoader: boolean): Promise<IGetLeagueResponse> {
  return await APIGet(baseLeagueUrl, params, useGlobalLoader);
}

export async function GetLeagueRegistrationFee(
  params: IGetLeagueRegistrationFee,
  useGlobalLoader: boolean,
): Promise<IGetLeagueRegistrationFeeResponse> {
  return await APIGet(baseLeagueUrl + "/registration-fee", params, useGlobalLoader);
}

export async function PostLeagueParticipant(
  params: IPostLeagueParticipant,
  useGlobalLoader: boolean,
): Promise<IPostLeagueParticipantResponse> {
  return await APIPost(baseLeagueUrl + "/participant", params, useGlobalLoader);
}

export async function GetLeagueRegistrationInput(
  params: IGetLeagueRegistrationInput,
  useGlobalLoader: boolean,
): Promise<IAPIResponse<ILeagueRegistrationInput[]>> {
  return await APIGet(baseLeagueUrl + "/registration-input", params, useGlobalLoader);
}

export async function PostLeagueRegistrationInputs(params: IPostLeagueRegistrationInputs, useGlobalLoader: boolean) {
  return await APIPost(baseLeagueUrl + "/registration-input/response", params, useGlobalLoader);
}
