import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { ITournamentParticipant } from "redux/reducers/models/tournament";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseTournamentUrl = "/" + apiVersion + "/" + adminType + "/tournament";

// GET Tournament Players
interface IGetTournamentParticipant {
  id?: string | number;
  tournament_id?: string | number;
}
// Retrieve a list of tournament players
export async function GetTournamentParticipant(
  params: IGetTournamentParticipant,
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet<ITournamentParticipant[]>(baseTournamentUrl + "/participant", params, useGlobalLoader, token);
}

//POST Add Player
export interface IPostTournamentParticipant {
  tournament_id: string | number;
  customer_id: string | number;
  first_name?: string;
  last_name?: string;
  email?: never;
  phone?: never;
}
export interface IPostTournamentParticipantNew {
  tournament_id: string | number;
  first_name: string;
  last_name: string;
  email?: string;
  phone?: string;
}

// Add player to tournament
export async function PostParticipant(
  params: IPostTournamentParticipant | IPostTournamentParticipantNew,
  useGlobalLoader: boolean,
) {
  return await APIPost(baseTournamentUrl + "/participant", params, useGlobalLoader);
}

// Update player to tournament
export async function PutParticipant(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseTournamentUrl + "/participant", params, useGlobalLoader);
}
