import React, { useEffect, useMemo, useState, useCallback } from "react";

import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import Toggle from "components/form/toggle/Toggle";
import { TEventRound } from "redux/reducers/models/league";

interface ISkinsTableProps {
  format: any;
  rounds: Array<TEventRound>;
  holeScoreRounds: Array<TEventRound>;
  defaultListView?: boolean;
}

interface ISkinsHole {
  hole_number: number;
  score: number;
  round_id: number;
  customer_id: number;
}

export function SkinsTable(props: ISkinsTableProps) {
  const { holeScoreRounds, format, rounds } = props;

  const submittedHoleScoreRounds = holeScoreRounds.filter(round => round.submitted);

  const [winners, setWinners] = useState<Array<ISkinsHole>>(null);
  const [listView, setListView] = useState<boolean>(props.defaultListView);

  useEffect(() => {
    void calculateWinners();
  }, []);

  function calculateWinners() {
    if (!submittedHoleScoreRounds) {
      return;
    }

    const winners: Array<ISkinsHole> = [];

    // Account for total hole scores (9.1, 18.1, 18.2)
    const holeCount = format.holes === "18_holes" ? 19 : 9;

    for (let i = 0; i < holeCount; i++) {
      for (let j = 0; j < submittedHoleScoreRounds.length; j++) {
        const score =
          format.handicap === "gross"
            ? submittedHoleScoreRounds[j].hole_scores[i].gross
            : submittedHoleScoreRounds[j].hole_scores[i].net;
        // Create initial winners
        if (j === 0) {
          winners.push({
            hole_number: submittedHoleScoreRounds[j].hole_scores[i].hole_number,
            score: score,
            round_id: submittedHoleScoreRounds[j].hole_scores[i].round_id,
            customer_id: submittedHoleScoreRounds[j].customer_id,
          });
        } else {
          if (score < winners[i].score) {
            winners[i].score = score;
            winners[i].round_id = submittedHoleScoreRounds[j].hole_scores[i].round_id;
            winners[i].customer_id = submittedHoleScoreRounds[j].customer_id;
          } else if (score === winners[i].score) {
            winners[i].round_id = null;
            winners[i].customer_id = null;
          }
        }
      }
    }

    setWinners(winners);
  }

  function gridViewTable() {
    return (
      <DataTable
        columns={[
          { label: "Player" },
          ...submittedHoleScoreRounds[0].hole_scores
            ?.filter(score => {
              if (score.hole_number % 1 !== 0) {
                return false;
              } else if (format.holes === "9_holes" && score.hole_number >= 10) {
                return false;
              } else {
                return true;
              }
            })
            ?.map(score => {
              return { label: String(score.hole_number) };
            }),
        ]}
        loading={!winners}
      >
        {winners &&
          rounds?.map((round, index) => {
            return (
              <tr key={index}>
                <td>{round.customer}</td>
                {submittedHoleScoreRounds
                  ?.find(holeScoreRound => holeScoreRound.hole_scores[0].round_id === round.round_id)
                  .hole_scores?.filter(score => score.hole_number % 1 === 0)
                  ?.map((score, holeIndex) => {
                    const isWinner =
                      winners.findIndex(
                        winner => winner.hole_number === score.hole_number && winner.round_id === round.round_id,
                      ) > -1;
                    if (!(format.holes === "9_holes" && score.hole_number >= 10)) {
                      return (
                        <td key={holeIndex} className={isWinner ? "background-primary-light" : null}>
                          {format.handicap === "gross" ? score.gross : score.net}
                        </td>
                      );
                    }
                  })}
              </tr>
            );
          })}
      </DataTable>
    );
  }

  function listViewTable() {
    return (
      <DataTable columns={[{ label: "Hole" }, { label: "Player" }, { label: "Score" }]} loading={!winners}>
        {winners?.map((winner, index) => {
          if (winner.round_id) {
            return (
              <tr key={index}>
                <td>{winner.hole_number}</td>
                <td>{rounds.find(round => round.round_id === winner.round_id)?.customer}</td>
                <td>{winner.score}</td>
              </tr>
            );
          }
        })}
      </DataTable>
    );
  }

  return (
    <>
      {submittedHoleScoreRounds.length > 0 ? (
        <div className={props.defaultListView ? "" : "mt-4"}>
          {!props.defaultListView && (
            <Toggle checked={listView} label="List View" onChange={() => setListView(!listView)} />
          )}
          {listView ? listViewTable() : gridViewTable()}
        </div>
      ) : null}
    </>
  );
}
