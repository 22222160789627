import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import useModal from "hooks/modals/useModal";

import { StatusCode } from "api/protocols";
import { GetOrder } from "api/rpc/2022-09/facilityAdmin/order/order";
import { PostCalculateRefund, PostRefund } from "api/rpc/2024-04/facilityAdmin/order/refund";

import { IUIActions, dequeue, enqueue, showError, showSuccess } from "redux/actions/ui";
import { IOrder, IOrderLineItem, IOrderTaxLine, IOrderTransaction } from "redux/reducers/models/order";

import { capitalize, delay } from "helpers/Helpers";
import { LocaleCurrency } from "helpers/Locale";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useStripeTerminalPayment } from "hooks/useStripeTerminalPayment/useStripeTerminalPayment";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Input from "components/form/input/Input";
import { ButtonNew as Button } from "components/buttonNew";
import Checkbox from "components/form/checkbox/Checkbox";
import { IAuthState } from "redux/reducers/auth";
import Portal from "elements/Portal";
import Popup from "components/popup/Popup";

interface IRefundProps {
  authStore: IAuthState;
}

interface IOrderState {
  order: IOrder;
  line_items: IOrderLineItem;
  transactions: IOrderTransaction[];
  total_tax: number;
  total_tip: number;
  subtotal_price: number;
  total_price: number;
  tax_lines: IOrderTaxLine[];
  calculated_refund_transactions: Record<string, any>[];
  refund_line_items: {
    line_item_id: number;
    refund_quantity: number;
    refund_amount: number;
  }[];
  refund_tip: boolean;
}

interface ITerminalState {
  online: boolean;
  connecting: boolean;
  reader: any;
}

export default function Refund(props: IRefundProps) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { orderId } = useParams<{ orderId: string }>();
  const mounted = useRef(false);

  const { authStore } = props;

  const dispatch = useAppDispatch();

  const terminalStore = useAppSelector(store => store.terminalStore);
  const { StripeTerminalRefundSheet, processRefund } = useStripeTerminalPayment();

  const [calculateState, setCalculateState] = useState({ index: 0, quantity: "" });
  const [orderState, setOrderState] = useState<IOrderState>({
    order: null,
    line_items: null,
    transactions: null,
    total_tax: null,
    total_tip: null,
    subtotal_price: null,
    total_price: null,
    tax_lines: null,
    calculated_refund_transactions: null,
    refund_line_items: null,
    refund_tip: false,
  });

  const { state: warningPopup, updateModal: updateWarningPopup, closeModal: closeWarningPopup } = useModal();

  useEffect(() => {
    void loadOrder();
  }, []);

  useEffect(() => {
    let mounted = true;
    let timeout: NodeJS.Timeout = null;
    if (mounted === true) {
      timeout = global.setTimeout(() => {
        try {
          if (orderState.order) {
            void calculateRefund();
          }
        } catch (error) {
          console.log("err", error);
        }
        return;
      }, 1000);
    }
    return () => {
      mounted = false;
      clearTimeout(timeout);
    };
  }, [calculateState.quantity, calculateState.index]);

  useEffect(() => {
    if (mounted.current === true) {
      void calculateRefund();
    } else {
      mounted.current = true;
    }
  }, [orderState?.refund_tip]);

  async function loadOrder() {
    const orderRes = await GetOrder({ id: Number(orderId), extended: true, refund: true }, true);
    if (orderRes.status !== StatusCode.OK) {
      return;
    }

    const order = orderRes.data[0];
    const refund_line_items: { line_item_id: number; refund_quantity: number; refund_amount: number }[] = [];

    order.line_items.forEach((line_item: Record<string, any>, i: number) => {
      const refund_line_item = {
        line_item_id: line_item.id,
        refund_quantity: 0,
        refund_amount: line_item.subtotal_price,
      };
      refund_line_items.push(refund_line_item);
    });

    const calculateRes = await PostCalculateRefund(
      {
        order_id: order.id,
        refund_line_items,
        refund_transactions: order.transactions,
      },
      true,
    );

    if (calculateRes.status !== StatusCode.OK) {
      return;
    }

    refund_line_items.forEach((line_item, i) => {
      refund_line_items[i] = { ...line_item, refund_amount: calculateRes.data.refund_line_items[i].refund_amount };
    });

    const filtered_transactions = order.transactions?.filter(
      (filteredTransaction: IOrderTransaction) =>
        filteredTransaction.kind === "sale" || filteredTransaction.kind === "capture",
    );

    setOrderState(prevState => ({
      ...prevState,
      order: order,
      line_items: order.line_items,
      transactions: filtered_transactions,
      total_tax: calculateRes.data.total_tax,
      subtotal_price: calculateRes.data.subtotal_price,
      total_price: calculateRes.data.total_price,
      total_tip: calculateRes?.data?.total_tip,
      calculated_refund_transactions: calculateRes.data.refund_transactions,
      refund_line_items,
      tax_lines: calculateRes.data.tax_lines,
    }));
  }

  async function calculateRefund() {
    const calculateRes = await PostCalculateRefund(
      {
        order_id: orderState.order?.id,
        refund_line_items: orderState.refund_line_items,
        refund_transactions: orderState.transactions,
        refund_tip: orderState.refund_tip,
      },
      true,
    );

    if (calculateRes.status !== StatusCode.OK) {
      dispatch(showError(calculateRes.data)); // TODO: Translation
      console.log(calculateRes);
      return;
    }

    const refund_line_items = orderState.refund_line_items;
    calculateRes.data.refund_line_items?.forEach((item: Record<string, any>, i: number) => {
      refund_line_items[i].refund_amount = item.refund_amount;
    });

    setOrderState(prevState => ({
      ...prevState,
      calculated_refund_transactions: calculateRes.data.refund_transactions,
      total_tax: calculateRes.data.total_tax,
      subtotal_price: calculateRes.data.subtotal_price,
      total_tip: calculateRes?.data?.total_tip,
      total_price: calculateRes.data.total_price,
      refund_line_items,
      tax_lines: calculateRes.data.tax_lines,
    }));
  }

  function getInteracTransactions() {
    const terminal_transactions: Array<IOrderTransaction> = [];
    let refund_transactions = [...orderState.calculated_refund_transactions];
    orderState.transactions?.forEach((transaction, i) => {
      //Add interac transaction with amount > 0
      if (transaction.payment_type === "interac" && Number(orderState.calculated_refund_transactions[i].amount) > 0) {
        terminal_transactions.push({
          ...transaction,
          amount: Number(orderState.calculated_refund_transactions[i].amount),
        });
      } else if (
        transaction.payment_type === "interac" &&
        (!orderState.calculated_refund_transactions[i].amount ||
          Number(orderState.calculated_refund_transactions[i].amount) == 0)
      ) {
        //Amount = 0, remove from refund_transaction
        refund_transactions = refund_transactions.filter(
          refund_transaction => refund_transaction.parent_id !== transaction.id,
        );
      }
    });

    return [terminal_transactions, refund_transactions];
  }

  function checkRefund() {
    if (orderState.refund_line_items?.filter(item => item.refund_quantity > 0).length <= 0) {
      updateWarningPopup({ isOpen: true });
    } else {
      void handleRefund();
    }
  }

  async function handleRefund() {
    void closeWarningPopup();
    //Add all interac transactions to an array to be processed
    const transactions = getInteracTransactions();
    const [terminal_transactions] = transactions;
    let [, refund_transactions] = transactions;
    let interacRefundSuccessful = false;

    refund_transactions = refund_transactions.filter(refund => refund.amount > 0);

    // Handle interac refunds with the terminal
    if (terminal_transactions.length > 0) {
      //Check if card reader is connected
      if (terminalStore?.reader) {
        for (const transaction of terminal_transactions) {
          dispatch(enqueue());
          await delay(2000);
          dispatch(dequeue());

          const refundRes = await processRefund(transaction.amount, transaction.payment_intent_id);
          if (refundRes === "success") {
            interacRefundSuccessful = true;
            dispatch(showSuccess(t("secure.facility.order.refund.004")));
          } else {
            //Interac refund failed, remove from refund_transaction
            refund_transactions = refund_transactions.filter(
              refund_transaction => refund_transaction.parent_id !== transaction.id,
            );
          }
        }
      } else {
        dispatch(showError(t("secure.facility.order.refund.006")));
        return;
      }
    }
    //If at least 1 interac refund succeeded, POST the refund for those transactions + non interac transactions
    if ((terminal_transactions.length > 0 && interacRefundSuccessful) || terminal_transactions.length === 0) {
      const refundRes = await PostRefund(
        {
          order_id: orderState.order.id,
          refund_line_items: orderState.refund_line_items,
          refund_transactions: refund_transactions,
        },
        true,
      );
      if (refundRes.status !== StatusCode.OK) {
        dispatch(showError(t("secure.facility.order.refund.007")));
        return;
      }
      dispatch(showSuccess(t("secure.facility.order.refund.004")));
      history.push("/admin/order/" + String(orderId));
    }
  }

  function handleQuantityInputChange(event: React.ChangeEvent<HTMLInputElement>, item_quantity: number, index: number) {
    const { value } = event.target;
    const refundLineItems = orderState.refund_line_items;
    if (Number(value) >= 0 && Number(value) <= item_quantity) {
      refundLineItems[index].refund_quantity = Number(value);
      setOrderState(prevState => ({ ...prevState, refund_line_items: refundLineItems }));
      setCalculateState(prevState => ({ ...prevState, index, quantity: value }));
    }
  }

  function handleRefundAmountInputChange(event: React.ChangeEvent<HTMLInputElement>, index: number) {
    const { value } = event.target;
    const transactions = orderState.calculated_refund_transactions;
    //Don't allow an amount greater than the maxiumum refundable amount
    if (Number(value) >= 0 && Number(value) <= transactions[index].maximum_refundable) {
      transactions[index].amount = value;
      setOrderState(prevState => ({ ...prevState, calculated_refund_transactions: transactions }));
    }
  }

  const disableRefundBtn = orderState.calculated_refund_transactions?.every(
    transaction => transaction.maximum_refundable === 0,
  );

  function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { checked } = event.target;
    setOrderState(prevState => ({
      ...prevState,
      refund_tip: checked,
    }));
  }

  return (
    <>
      <Page
        narrow
        splitLayout
        title={t("secure.facility.order.refund.010") + orderState.order?.name}
        breadcrumbs={[
          { prefix: true, label: t("secure.facility.order.refund.011"), url: "/admin/order/" + String(orderId) },
        ]}
      >
        <Page.Section twoThirds>
          <Card>
            <div className="flex justify-between p-6">
              <div>
                <h3 className="text-lg text-semibold">{t("secure.facility.order.refund.012")}</h3>
              </div>

              {authStore?.user?.permissions?.orders_refund_tip ? (
                <div>
                  <Checkbox
                    fullWidth
                    size="large"
                    label={"Refund Tip"}
                    value={orderState.refund_tip}
                    onChange={handleCheckboxChange}
                    checked={orderState.refund_tip}
                    disabled={!orderState?.total_tip}
                  />
                </div>
              ) : null}
            </div>

            <Card.Section>
              {orderState.order?.line_items &&
                orderState.order.line_items?.map((item: any, index: number) => {
                  return (
                    <Card.SubSection key={index}>
                      <div className="order-line-item">
                        <div>
                          <p className="text-lg font-semibold">{item.product_title}</p>
                          {item.product_title !== item.variant_title ? (
                            <p className="text-sm text-gray-600">{item.variant_title}</p>
                          ) : null}
                        </div>
                        <div className="text-right">
                          <Input
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              handleQuantityInputChange(e, item.refundable_quantity, index)
                            }
                            value={orderState.refund_line_items[index].refund_quantity}
                            suffix={"/ " + String(item.refundable_quantity)}
                            disabled={item.refundable_quantity === 0 || item.non_refundable ? true : false}
                            type="number"
                          />
                          <p className="text-lg text-gray-600">
                            x <LocaleCurrency currency="cad" amount={item.price} />
                          </p>
                        </div>
                        <div className="text-right text-lg">
                          <LocaleCurrency currency="cad" amount={orderState.refund_line_items[index].refund_amount} />
                        </div>
                      </div>
                    </Card.SubSection>
                  );
                })}
            </Card.Section>
          </Card>
        </Page.Section>
        <Page.Section oneThird>
          <Card>
            <Card.Section title={t("secure.facility.order.refund.013")}>
              <Card.SubSection>
                <div className="flex items-center justify-between">
                  <p className="text-lg">{t("secure.facility.order.refund.014")}</p>
                  <LocaleCurrency currency="cad" amount={orderState.subtotal_price} />
                </div>
              </Card.SubSection>
              <Card.SubSection>
                {/* Hide Discount total for now */}
                <div className="flex items-center justify-between" style={{ display: "none" }}>
                  <p className="text-lg">{t("secure.facility.order.refund.015")}</p>
                  <LocaleCurrency currency="cad" amount={0} />
                </div>

                {orderState.tax_lines?.map((tax_line, i) => {
                  return (
                    <div key={i} className="flex items-center justify-between">
                      <p className="text-lg">{tax_line.title}</p>
                      <LocaleCurrency currency="cad" amount={tax_line.price} />
                    </div>
                  );
                })}

                <div className="flex items-center justify-between">
                  <p className="text-lg">{t("secure.facility.order.refund.016")}</p>
                  <LocaleCurrency currency="cad" amount={orderState.total_tax} />
                </div>
              </Card.SubSection>
              {orderState.refund_tip ? (
                <Card.SubSection>
                  <div className="flex items-center justify-between">
                    <p className="text-lg">Total Tip</p>
                    <LocaleCurrency currency="cad" amount={orderState.order.total_tip} />
                  </div>
                </Card.SubSection>
              ) : null}

              <Card.SubSection>
                <div className="flex items-center justify-between">
                  <p className="text-lg font-semibold">{t("secure.facility.order.refund.017")}</p>
                  <LocaleCurrency currency="cad" amount={orderState.total_price} />
                </div>
              </Card.SubSection>
              <Card.SubSection>
                <span>{t("secure.facility.order.refund.018")}</span>
                {orderState.transactions?.map((transaction: IOrderTransaction, i: number) => {
                  return (
                    <div key={i} className="flex items-center justify-between">
                      <p className="mr-3 mt-3">
                        {transaction?.payment_type
                          ? capitalize(transaction?.payment_type)
                          : capitalize(transaction?.payment_method)}
                      </p>
                      <div className="w-4/5">
                        <Input
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleRefundAmountInputChange(e, i)}
                          value={orderState.calculated_refund_transactions[i]?.amount}
                          prefix="$"
                          type="number"
                        />
                      </div>
                    </div>
                  );
                })}
              </Card.SubSection>
              <Card.SubSection>
                <Button onClick={checkRefund} block size="medium" type="primary" disabled={disableRefundBtn}>
                  {t("secure.facility.order.refund.019")}
                </Button>
              </Card.SubSection>
            </Card.Section>
          </Card>
        </Page.Section>

        <Portal isMounted={warningPopup.isOpen}>
          <Popup
            open={warningPopup.isOpen}
            type="warning"
            title="No Line Items Selected" // TODO: Translation
            description="No line items are being refunded. This will create a variance. Are you sure want to continue?" // TODO: Translation
            onOk={handleRefund}
            okText="Continue" // TODO: Translation
            onCancel={() => closeWarningPopup()}
          />
        </Portal>
      </Page>
      <StripeTerminalRefundSheet />
    </>
  );
}
