import React, { useState, useRef } from "react";

import Sheet from "components/sheet/Sheet";
import Callout from "components/callout/Callout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./fileImport.scss";

interface IFileSelectProps {
  open: boolean;
  onOk: (selectedFile: File) => void;
  onCancel: () => void;
}

export default function FileImport(props: IFileSelectProps) {
  const fileUploadRef = useRef(null);

  const [selectedFile, setSelectedFile] = useState<File>(null);

  function handleFileSelect(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files;
    if (file && file.length > 0) {
      setSelectedFile(file[0]);
    }
  }

  function onOk() {
    props.onOk(selectedFile);

    fileUploadRef.current.value = "";
    setSelectedFile(null);
  }

  return (
    <Sheet
      title="Select File" // TODO: Translation
      open={props.open}
      size="small"
      closable
      onCancel={props.onCancel}
      onOk={onOk}
      okText="Import" // TODO: Translation
      okDisabled={!selectedFile ? true : false}
    >
      <Callout
        type="info"
        title={!selectedFile ? "No file selected" : "Selected File"} // TODO: Translation
        content={!selectedFile ? "Select a file to continue" : selectedFile.name} // TODO: Translation
      />

      <div className="file-import-container mt-2">
        <input
          ref={fileUploadRef}
          className="file-import-input"
          type="file"
          accept=".csv"
          onChange={handleFileSelect}
        />
        <FontAwesomeIcon className="file-import-icon" icon={["far", "upload"]} />
      </div>
    </Sheet>
  );
}
