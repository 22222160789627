import { createContext, useContext } from "react";
import { ISelectedEditInput } from "components/draggableEditInput/DraggableEditInput";
import {
  IUnsavedChangesContainer,
  IUnsavedChangesDispatchActions,
} from "hooks/useUnsavedChangesContainer/useUnsavedChangesContainer";
import { FormInputRows } from "./FacilityFormEdit";

export type FormEditHandleUpdateInput = (
  id: any,
  label: string,
  helpText: string,
  value: string[] | null,
  isRequired?: boolean,
) => void;

interface IFormEditContext {
  selectedFormInput: ISelectedEditInput;
  setSelectedFormInput: React.Dispatch<React.SetStateAction<ISelectedEditInput>>;
  formInputRowsContainer: IUnsavedChangesContainer<FormInputRows>;
  formInputRowsContainerActions: IUnsavedChangesDispatchActions<FormInputRows>;
  handleUpdateInput: FormEditHandleUpdateInput;
  closeFormInputEditModal: () => void;
}

const defaultFormEditContext: IFormEditContext = {
  selectedFormInput: undefined,
  setSelectedFormInput: undefined,
  formInputRowsContainer: undefined,
  formInputRowsContainerActions: undefined,
  handleUpdateInput: undefined,
  closeFormInputEditModal: undefined,
};

export const FormEditContext = createContext<IFormEditContext>(defaultFormEditContext);
export const useFormEditContext = () => useContext(FormEditContext);
