import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { StatusCode } from "api/protocols";
import { showSuccess, showError } from "redux/actions/ui";
import { IBooking } from "redux/reducers/models/teetime";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonNew } from "components/buttonNew";
import ReactDOM from "react-dom";
import Card from "components/card/Card";
import Popup from "components/popup/Popup";
import { Badge } from "components/badge/Badge";
import { useAppDispatch } from "hooks/redux";
import "./viewPortalBooking.scss";
import { useTranslation } from "react-i18next";
import Page from "components/page/Page";
import Divider from "components/divider";
import { GetBooking, PutCancelBooking } from "api/rpc/2024-04/customer/booking";
import { useGuestPortalContext } from "../GuestPortalContext";
import { CustomIcon } from "components/customIcon/CustomIcon";
import BottomSheet from "components/bottomSheet/BottomSheet";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { MOBILE_WIDTH } from "helpers/ScreenSizes";
interface IParams {
  id: string;
}

interface IState {
  selectedBooking: IBooking;
  formattedAddress: string;
}

interface IEditBookingState {
  cancelPopUp: boolean;
  hideEditButton: boolean;
  hideCancelButton: boolean;
  teeTimeNotInPast: boolean;
}

export default function ViewPortalBooking() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { portalState } = useGuestPortalContext();
  const { id } = useParams<IParams>();
  const windowSize = useWindowSize();
  const [state, setState] = useState<IState>({
    selectedBooking: null,
    formattedAddress: "",
  });

  const [editBookingState, setEditBookingState] = useState<IEditBookingState>({
    cancelPopUp: false,
    hideEditButton: false,
    hideCancelButton: false,
    teeTimeNotInPast: false,
  });

  const dispatch = useAppDispatch();

  // June 6, 2022
  const editDate = new Date("2022-06-06T00:00:00.000000Z").toUTCString();

  useEffect(() => {
    void getBooking();
  }, []);

  async function getBooking() {
    let [hideEditBooking, hideCancelBooking] = [true, true];
    const bookingRes = await GetBooking({ id: Number(id) }, true);
    if (bookingRes.status !== StatusCode.OK) {
      dispatch(showError(t("guest.portal.view_portal_reservations.001")));
      return;
    }

    //Return to reservations page if booking isn't found
    if (bookingRes.data?.length === 0) {
      dispatch(showError(t("guest.portal.view_portal_reservations.002")));
      history.push(`/tee-on/portal/bookings`);
    }

    if (bookingRes.data[0]?.status !== "cancelled") {
      [hideEditBooking, hideCancelBooking] = checkEditCancelTimes(bookingRes.data[0]);
      void checkTeeTimeInPast(bookingRes?.data[0]);
    }

    const fullAddress = `${bookingRes.data[0]?.tee_time?.facility?.address_line_1}, ${bookingRes.data[0]?.tee_time?.facility?.city},
      ${bookingRes.data[0]?.tee_time?.facility?.province_name}, ${bookingRes.data[0]?.tee_time?.facility?.postal}`;

    ReactDOM.unstable_batchedUpdates(() => {
      setState(prevState => ({ ...prevState, selectedBooking: bookingRes.data[0], formattedAddress: fullAddress }));
      setEditBookingState(prevState => ({
        ...prevState,
        hideCancelButton: hideCancelBooking,
        hideEditButton: hideEditBooking,
      }));
    });
  }

  function checkTeeTimeInPast(booking: IBooking) {
    const bookingDate = booking?.tee_time?.date + " " + booking?.tee_time?.start_time + ":00";
    const currentTime = moment().utc();
    const bookingTime = moment(bookingDate)?.utc();
    //Check if the booking was made by the logged in user
    const userBooking = booking?.customer_id === portalState?.guest?.id;
    setEditBookingState(prevState => ({
      ...prevState,
      teeTimeNotInPast: currentTime.isBefore(bookingTime) && userBooking,
    }));
  }

  function checkEditCancelTimes(booking: IBooking) {
    let hideEditButton = false;
    let hideCancelButton = false;
    const completedAtDate = new Date(booking?.completed_at).toUTCString();

    const currentDateUTCString = new Date().toUTCString();

    const teeTimeDateSetUp = new Date(booking?.tee_time?.date + "T" + booking?.tee_time?.start_time);
    //Set date-time based on facility timezone
    const teeTimeDateTimezoneString = new Date(teeTimeDateSetUp).toLocaleString("en-US", {
      timeZone: booking?.tee_time?.facility?.timezone,
    });
    const teeTimeCancelTime = new Date(teeTimeDateTimezoneString);
    const teeTimeEditTime = new Date(teeTimeDateTimezoneString);

    teeTimeCancelTime.setHours(teeTimeCancelTime.getHours() - booking?.tee_sheet_settings?.web_cancellation_hours);
    teeTimeEditTime.setHours(teeTimeEditTime.getHours() - booking?.tee_sheet_settings?.web_edit_hours);

    const teeTimeCancelTimeUTCString = teeTimeCancelTime.toUTCString();
    const teeTimeEditTimeUTCString = teeTimeEditTime.toUTCString();

    if (Date.parse(currentDateUTCString) >= Date.parse(teeTimeCancelTimeUTCString)) {
      hideCancelButton = true;
    }

    if (Date.parse(completedAtDate) <= Date.parse(editDate)) {
      hideEditButton = true;
    } else if (Date.parse(currentDateUTCString) >= Date.parse(teeTimeEditTimeUTCString)) {
      hideEditButton = true;
    }

    //Hide edit/cancel buttons if logged in user did not create the booking
    if (booking?.customer_id !== portalState?.guest?.id) {
      hideCancelButton = true;
      hideEditButton = true;
    }

    return [hideEditButton, hideCancelButton];
  }

  function initiateEditBooking() {
    if (state?.selectedBooking?.token) {
      history.push(`/tee-on/portal/bookings/${state?.selectedBooking?.id}/edit`);
    }
  }

  function initiateCancelBooking() {
    setEditBookingState(prevState => ({ ...prevState, cancelPopUp: true }));
  }

  async function cancelBooking() {
    const putCancelRes = await PutCancelBooking({ token: state.selectedBooking?.token }, true);

    if (putCancelRes.status !== StatusCode.OK) {
      dispatch(showError(t("guest.portal.view_portal_reservations.003")));
      setEditBookingState(prevState => ({ ...prevState, cancelPopUp: false }));
      return;
    }

    dispatch(showSuccess(t("guest.portal.view_portal_reservations.004")));
    history.push(`/tee-on/portal/bookings`);
  }

  return (
    <Page title="Booking" breadcrumbs={[{ prefix: true, label: "Back to Bookings", url: "/tee-on/portal/bookings" }]}>
      <div>
        {state.selectedBooking && (
          <div className="portal-reservations-view-booking">
            <div className="facility-container">
              {state.selectedBooking?.tee_time?.facility?.logo_source && (
                <img src={state.selectedBooking?.tee_time?.facility?.logo_source} alt={"Facility logo"} />
              )}
              <div className="facility-details-container">
                <h1 className="facility-name">
                  {state.selectedBooking?.tee_time?.facility?.long_name}{" "}
                  {state.selectedBooking?.tee_time?.course?.long_name !==
                  state.selectedBooking?.tee_time?.facility?.long_name
                    ? ` - ${state.selectedBooking?.tee_time?.course?.long_name}`
                    : ""}
                </h1>
                <p className="facility-address">{state.formattedAddress}</p>
              </div>
            </div>
            <Card title="Booking Details">
              <Card.Section>
                <div className="info-parent-container">
                  <div>
                    <p className="confirmation-text">
                      Booked By{" "}
                      {
                        <span className="confirmation-number">
                          {state.selectedBooking?.first_name} {state?.selectedBooking?.last_name}
                        </span>
                      }
                    </p>
                    <p className="confirmation-text">
                      Confirmation Number{" "}
                      {<span className="confirmation-number">{state.selectedBooking?.confirmation_number}</span>}
                    </p>
                  </div>
                  {state?.selectedBooking?.order_id && (
                    <Badge
                      type="success"
                      size="medium"
                      iconSize="medium"
                      iconLeft={<FontAwesomeIcon icon={["far", "dollar-sign"]} />}
                    >
                      {t("guest.portal.view_portal_reservations.005")}
                    </Badge>
                  )}
                  {state?.selectedBooking?.booking_fee_order_id && (
                    <Badge
                      type="success"
                      size="medium"
                      iconSize="medium"
                      iconLeft={<FontAwesomeIcon icon={["far", "dollar-sign"]} />}
                    >
                      {t("guest.portal.view_portal_reservations.006")}
                    </Badge>
                  )}
                  {state?.selectedBooking?.status === "cancelled" && (
                    <Badge type="error" size="medium">
                      Cancelled
                    </Badge>
                  )}
                </div>
                <div className="date-group-container">
                  <FontAwesomeIcon className="info-text" icon={["far", "calendar"]} />
                  <p className="date-time-info">
                    {moment(
                      `${state.selectedBooking?.tee_time?.date}T${state.selectedBooking?.tee_time?.start_time}`,
                    ).format("LLLL")}
                  </p>
                </div>

                <div className="info-parent-container ">
                  <div className="info-container">
                    <FontAwesomeIcon className="info-text" icon={["far", "golf-flag-hole"]} fixedWidth />
                    <p className="info-text">
                      {state.selectedBooking?.holes} {t("guest.portal.view_portal_reservations.007")}
                    </p>
                  </div>
                  <div className="info-container">
                    <FontAwesomeIcon className="info-text" icon={["far", "user-group"]} fixedWidth />
                    <p className="info-text">
                      {state.selectedBooking?.quantity}{" "}
                      {state.selectedBooking?.quantity > 1
                        ? t("guest.portal.view_portal_reservations.008")
                        : t("guest.portal.view_portal_reservations.019")}
                    </p>
                  </div>
                  {state.selectedBooking?.power_cart_quantity >= 1 ? (
                    <div className="info-container">
                      <CustomIcon src="/public/images/powercart2-01.svg" size="small" />
                      <p className="info-text">
                        {state.selectedBooking?.power_cart_quantity}{" "}
                        {state.selectedBooking?.power_cart_quantity > 1
                          ? t("guest.portal.view_portal_reservations.009")
                          : t("guest.portal.view_portal_reservations.017")}
                      </p>
                    </div>
                  ) : (
                    <div className="info-container">
                      <FontAwesomeIcon className="info-text" icon={["far", "person-walking"]} fixedWidth />
                      <p className="info-text">{t("guest.portal.view_portal_reservations.018")}</p>
                    </div>
                  )}
                </div>
              </Card.Section>
            </Card>
            <Divider />
            <div className="bottom-buttons-container">
              {!editBookingState.hideCancelButton && (
                <ButtonNew
                  className="view-booking-button"
                  type="secondary"
                  size="medium"
                  onClick={initiateCancelBooking}
                >
                  {t("guest.portal.view_portal_reservations.011")}
                </ButtonNew>
              )}
              {editBookingState?.teeTimeNotInPast ? (
                <ButtonNew className="view-booking-button" type="primary" size="medium" onClick={initiateEditBooking}>
                  {!editBookingState.hideEditButton
                    ? t("guest.portal.view_portal_reservations.012")
                    : t("guest.portal.view_portal_reservations.013")}
                </ButtonNew>
              ) : null}
            </div>
          </div>
        )}

        <Popup
          onCancel={() => setEditBookingState(prevState => ({ ...prevState, cancelPopUp: false }))}
          onOk={cancelBooking}
          cancelText={t("guest.portal.view_portal_reservations.014")}
          okText={t("guest.portal.view_portal_reservations.011")}
          open={editBookingState.cancelPopUp && windowSize.width > MOBILE_WIDTH}
          type="warning"
          title={t("guest.portal.view_portal_reservations.015")}
          description={t("guest.portal.view_portal_reservations.016")}
        />

        <BottomSheet
          open={editBookingState.cancelPopUp && windowSize.width <= MOBILE_WIDTH}
          onClose={() => setEditBookingState(prevState => ({ ...prevState, cancelPopUp: false }))}
          type="warning"
          content="Are you sure you want to cancel this booking?"
          primaryAction={{ label: "Cancel Booking", onClick: cancelBooking }}
        />
      </div>
    </Page>
  );
}
