import React, { useEffect, useState } from "react";
import axios, { CancelToken } from "axios";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { GetOrder } from "api/rpc/2022-09/clientAdmin/order/order";
import { GetFacility, IFacility } from "api/rpc/2022-09/clientAdmin/facility/facility";

import { IOrder } from "redux/reducers/models/order";

import { useOrderFilters } from "hooks/useOrderFilters/useDropFilters";

import Page from "components/page/Page";
import Card from "components/card/Card";
import OrdersTable from "components/orderTable/OrdersTable";
import { PriceMatchType } from "components/dropdown/dropFilterTypes";
import Search from "components/search/Search";

import OrderFiltersClient from "./OrderFiltersClient";

import "pages/secure/facility/order/order.scss";
import { numberIsValid } from "helpers/Helpers";

interface IState {
  search: string;
  limit: number;
  offset: number;
}

export type IOrderFilterStateClient = {
  [key in keyof PriceMatchType]?: string | string[];
} & {
  all: Array<Record<string, any>>;
  date: string[];
  financial_status: string[];
  sales_channels: string[];
  facility_ids: IFacility[];
  credit_card: string[];
};

export default function Orders(props: any) {
  const { t, i18n } = useTranslation();
  const orderFilters = useOrderFilters();

  const { clientFacilityStore } = props;
  const facilities = clientFacilityStore.facilities;

  const [searchState, setSearchState] = useState<IState>({
    search: "",
    limit: 50,
    offset: 0,
  });

  const [filterState, setFilterState] = useState<IOrderFilterStateClient>({
    all: undefined,
    date: [],
    financial_status: [],
    sales_channels: [],
    facility_ids: [],
    credit_card: [],
    total_equal_to: "",
    total_greater_than: "",
    total_less_than: "",
  });

  // async states
  const [orders, setOrders] = useState<IOrder[]>(undefined);

  // offset and searchState both change at same time, but first call is cancelled with source.token
  useEffect(() => {
    if (filterState.all === undefined) {
      return;
    }

    const source = axios.CancelToken.source();
    void loadOrders(source.token);

    return () => {
      source.cancel();
    };
  }, [searchState, filterState.all]);

  async function loadOrders(token: CancelToken) {
    console.log("API CALLED ", {
      ...searchState,
      filters: filterState.all?.length > 0 ? filterState.all : undefined,
    });

    if (orders !== undefined) {
      setOrders(undefined);
    }

    let params;

    if (numberIsValid(searchState.search)) {
      params = {
        number: searchState.search,
        offset: searchState.offset,
        filters: filterState.all?.length > 0 ? filterState.all : undefined,
      };
    } else {
      params = {
        search: searchState.search,
        offset: searchState.offset,
        filters: filterState.all?.length > 0 ? filterState.all : undefined,
      };
    }

    const orderRes = await GetOrder(params, false, token);

    if (token.reason) {
      setOrders(undefined); // front-end refresh
      return;
    }

    if (orderRes.status !== StatusCode.OK) {
      setOrders([]); // backend error
      return;
    } else {
      setOrders(orderRes.data);
    }
  }

  return (
    <Page title="Orders">
      <OrderFiltersClient
        filters={filterState}
        setFilters={setFilterState}
        filterGroups={{
          date: orderFilters.dateFilters,
          financial: orderFilters.financialStatusFilters,
          salesChannels: orderFilters.salesChannelFilters,
          facilities: facilities,
          creditCard: orderFilters.creditCardFilters,
        }}
        historyKey="client_order_filters"
        dropFilterHistoryKeyPrefix="client_orders"
      />
      <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
        <Search
          historyKey={"client_orders_search"}
          searchCallback={searchValue => {
            setSearchState(prev => ({ ...prev, search: searchValue, offset: prev.offset !== 0 ? 0 : prev.offset }));
          }}
          placeholder={"Search for an order..."}
        />
      </div>
      <Card>
        <Card.Section table="true">
          <OrdersTable
            orders={orders}
            facilities={facilities}
            tableLimit={searchState.limit}
            tableOffset={searchState.offset}
            handleTableOffset={direction =>
              setSearchState(prev => ({
                ...prev,
                offset: direction === "prev" ? prev.offset - prev.limit : prev.offset + prev.limit,
              }))
            }
          />
        </Card.Section>
      </Card>
    </Page>
  );
}
