import { APIGet } from "api/protocols";

const apiVersion = "2024-04";
const adminType = "master-admin";
const baseWeathertUrl = "/" + apiVersion + "/" + adminType + "/facility/weather/forecast?";

//GET Weather
//Retrive weather from facility
export async function GetWeather(params: number, useGlobalLoader: boolean) {
  return await APIGet(baseWeathertUrl + `facility_id=${params}`, useGlobalLoader);
}
