import React from "react";
import { Panel, PanelPosition } from "reactflow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IconName } from "@fortawesome/fontawesome-common-types";
import "./dynamicPricingActionPanel.scss";
import { Placement } from "react-bootstrap/esm/Overlay";

interface IDynamicPricingActionPanelAction {
  type: "action";
  tooltipId: string;
  tooltipLabel: string;
  tooltipPlacement: Placement;
  icon: IconName;
  disabled: boolean;
  hidden?: boolean;
  onClick: () => void;
}

interface IDynamicPricingActionPanelLabel {
  type: "label";
  label: string;
  hidden?: boolean;
}

type DynamicPricingActionPanelItem = IDynamicPricingActionPanelAction | IDynamicPricingActionPanelLabel;

interface IDynamicPricingActionPanelProps {
  actionPanelRef?: React.MutableRefObject<HTMLDivElement>;
  panelPosition: PanelPosition;
  direction: "column" | "row";
  items: DynamicPricingActionPanelItem[];
}

export default function DynamicPricingActionPanel(props: IDynamicPricingActionPanelProps) {
  const { actionPanelRef, panelPosition, direction, items } = props;

  return (
    <Panel position={panelPosition}>
      <div
        ref={actionPanelRef}
        className="dynamic-pricing-action-panel"
        style={{
          flexDirection: direction,
        }}
      >
        {items?.map((item, index) => {
          if (item.hidden) {
            return <div key={index} />;
          } else if (item.type === "action") {
            return (
              <OverlayTrigger
                key={index}
                placement={item.tooltipPlacement}
                trigger={["hover", "click"]}
                overlay={<Tooltip id={item.tooltipId}>{item.tooltipLabel}</Tooltip>}
              >
                <button
                  type="button"
                  className="dynamic-pricing-action-panel-action"
                  disabled={item.disabled}
                  onClick={item.onClick}
                >
                  <FontAwesomeIcon icon={["far", item.icon]} />
                </button>
              </OverlayTrigger>
            );
          } else if (item.type === "label") {
            return (
              <div key={index} className="dynamic-pricing-action-panel-label">
                {item.label}
              </div>
            );
          }
        })}
      </div>
    </Panel>
  );
}
