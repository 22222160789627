import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { IOrder } from "../../models/order";
import { IFacility } from "../../models/facility";
import LeagueHeader from "../LeagueHeader/LeagueHeader";
import { GetOrder } from "api/rpc/guest/order";

import { dequeue, enqueue, showError } from "redux/actions/ui";
import { ILeague } from "redux/reducers/models/league";
import { displayCurrency } from "helpers/Helpers";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import Card from "components/card/Card";
import OrderSummary from "components/OrderSummary/OrderSummary";
import PaymentSummary, { IPaymentSummaryData } from "components/PaymentSummary/PaymentSummary";
import Page from "components/page/Page";
import { loadFacility, loadLeague } from "redux/actions/customer/leagueRegistration";
import LeagueTitle from "../LeagueTitle/LeagueTitle";
import "./LeagueConfirmation.scss";

interface ILeagueConfirmationParams {
  facilityShortName: string;
  leagueHandle: string;
  orderToken: string;
}

interface ILeagueConfirmationState {
  order: IOrder;
}

export default function LeagueConfirmation() {
  const { t } = useTranslation();
  const { facilityShortName, leagueHandle, orderToken } = useParams<ILeagueConfirmationParams>();

  const { leagueRegistrationStore } = useAppSelector(store => store);
  const dispatch = useAppDispatch();

  const [leagueConfirmationState, setLeagueConfirmationState] = useState<ILeagueConfirmationState>({
    order: undefined,
  });

  useEffect(() => {
    void loadOrder();
  }, []);

  async function loadOrder() {
    let facility: IFacility = undefined;

    dispatch(enqueue());

    try {
      const getFacilityPromise: Promise<IFacility> = leagueRegistrationStore.facility
        ? Promise.resolve(leagueRegistrationStore.facility)
        : dispatch(loadFacility(facilityShortName, true));

      facility = await getFacilityPromise;
    } catch {
      dispatch(showError(t("guest.league_registration.league_confirmation.001")));
      dispatch(dequeue());
      return;
    }

    const facilityId = facility.id;

    try {
      const getLeaguePromise: Promise<ILeague> = leagueRegistrationStore.league
        ? Promise.resolve(leagueRegistrationStore.league)
        : dispatch(loadLeague(facilityId, leagueHandle, true));

      await getLeaguePromise;
    } catch {
      dispatch(showError(t("guest.league_registration.league_confirmation.002")));
      dispatch(dequeue());
      return;
    }

    const getOrderResponse = await GetOrder({ token: orderToken }, true);

    if (getOrderResponse.status !== StatusCode.OK) {
      dispatch(showError(t("guest.league_registration.league_confirmation.003")));
      dispatch(dequeue());
      return;
    }

    setLeagueConfirmationState(prevState => ({
      ...prevState,
      order: getOrderResponse.data?.[0],
    }));

    dispatch(dequeue());
  }

  function getPaymentSummary() {
    const summaryData: IPaymentSummaryData[] = [
      {
        label: t("guest.league_registration.league_confirmation.006"),
        values: [leagueConfirmationState.order.customer?.full_name, leagueConfirmationState.order.customer?.email],
      },
    ];

    if (leagueConfirmationState.order?.customer_notes) {
      summaryData.push({
        label: t("guest.league_registration.league_confirmation.008"),
        values: [leagueConfirmationState.order.customer_notes],
      });
    }

    if (leagueConfirmationState.order.transactions?.[0]) {
      summaryData.push({
        label: t("guest.league_registration.league_confirmation.007"),
        values: [
          `•••• ${leagueConfirmationState.order.transactions?.[0]?.payment_last_4}`,
          displayCurrency("cad", leagueConfirmationState.order.transactions?.[0]?.amount),
        ],
      });
    }

    return summaryData;
  }

  return (
    <>
      <LeagueHeader facility={leagueRegistrationStore.facility} league={leagueRegistrationStore.league} />
      {leagueRegistrationStore.facility && leagueRegistrationStore.league && leagueConfirmationState.order && (
        <Page>
          <div>
            <div className="league-confirmation-page-sections">
              <div className="league-confirmation-information-section">
                <Card>
                  <Card.Section>
                    <h2 className="league-confirmation-information-section-title">{`${t(
                      "guest.league_registration.league_confirmation.004",
                    )} ${leagueConfirmationState.order.name}`}</h2>
                    <h3 className="league-confirmation-information-section-subtitle">
                      {`${t("guest.league_registration.league_confirmation.005")} ${
                        leagueConfirmationState.order.customer?.first_name
                      }.`}
                    </h3>
                  </Card.Section>
                </Card>
                <Card>
                  <Card.Section>
                    <PaymentSummary summaryData={getPaymentSummary()} />
                  </Card.Section>
                </Card>
              </div>
              <div className="league-confirmation-order-summary-section">
                <OrderSummary
                  subtotal={leagueConfirmationState.order.subtotal_price}
                  taxLines={leagueConfirmationState.order.tax_lines}
                  discount={leagueConfirmationState.order.total_discount}
                  total={leagueConfirmationState.order.total_price}
                  displayLineItemsWithoutToggle={true}
                  lineItems={leagueConfirmationState.order.line_items.map(lineItem => {
                    return {
                      src: lineItem.product_default_image?.source,
                      productTitle: lineItem.product_title,
                      variantTitle: lineItem.variant_title,
                      quantity: lineItem.quantity,
                      price: lineItem.subtotal_price,
                    };
                  })}
                />
              </div>
            </div>
          </div>
        </Page>
      )}
    </>
  );
}
