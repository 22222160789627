import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import StoreHeader from "./StoreHeader/StoreHeader";
import "./ViewShoppingBag.scss";
import { displayCurrency } from "helpers/Helpers";
import { Select } from "components/select/index";
import { ICartState } from "redux/reducers/customer/cart";
import { ICartActions } from "redux/actions/customer/cart";
import { GetFacility } from "api/rpc/guest/facility";
import { StatusCode } from "api/protocols";
import { IFacility } from "../models/facility";
import { StorePage } from "./StorePage/StorePage";
import OrderSummary from "components/OrderSummary/OrderSummary";
import { ICart } from "redux/reducers/customer/models/cart";
import { getOnlineStoreCartToken, getOnlineStoreCartTokenName } from "./HomePage";
import { IUIActions } from "redux/actions/ui";
import { useTranslation } from "react-i18next";

interface IViewShoppingBagPageProps {
  cartStore: ICartState;
  cartActions: ICartActions;
  uiActions: IUIActions;
}

interface IViewShoppingBagState {
  facility: IFacility;
  loaded: boolean;
}

export default function ViewShoppingBag(props: IViewShoppingBagPageProps) {
  const { facilityShortName } = useParams<{ facilityShortName: string }>();

  const { cartStore, cartActions, uiActions } = props;

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { Option } = Select;

  const [viewShoppingBagState, setViewShoppingBagState] = useState<IViewShoppingBagState>({
    facility: undefined,
    loaded: false,
  });

  const quantities = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
    { label: "9", value: 9 },
    { label: "10", value: 10 },
    { label: "11", value: 11 },
    { label: "12", value: 12 },
    { label: "13", value: 13 },
    { label: "14", value: 14 },
    { label: "15", value: 15 },
  ];

  useEffect(() => {
    void loadViewShoppingBag();
  }, []);

  async function loadViewShoppingBag() {
    uiActions.enqueue();

    try {
      const facilityRes = await GetFacility({ short_name: facilityShortName }, false);
      const facilityId = facilityRes?.data?.[0]?.id;

      if (facilityRes.status !== StatusCode.OK || facilityId === undefined) {
        uiActions.showError("Failed to get facility");
        uiActions.dequeue();
        return;
      }

      let cart: ICart = undefined;

      if (getOnlineStoreCartToken(facilityShortName)) {
        try {
          const getCartPromise: Promise<ICart> = cartStore?.isLoaded
            ? Promise.resolve(cartStore?.cart)
            : (cartActions.loadCart(
                {
                  facility_id: facilityId,
                  tokenName: getOnlineStoreCartTokenName(facilityShortName),
                  token: getOnlineStoreCartToken(facilityShortName),
                },
                false,
              ) as unknown as Promise<ICart>);

          cart = await getCartPromise;
        } catch {
          uiActions.showError("Failed to load cart");
          uiActions.dequeue();
          return;
        }
      } else {
        try {
          const postCartPromise: Promise<ICart> = cartActions.postCart(
            { tokenName: getOnlineStoreCartTokenName(facilityShortName), facility_id: facilityId },
            false,
          ) as unknown as Promise<ICart>;

          cart = await postCartPromise;
        } catch {
          uiActions.showError("Failed to update cart");
          uiActions.dequeue();
          return;
        }
      }

      if (cart?.status === "complete") {
        cartActions.cartClear({ tokenName: getOnlineStoreCartTokenName(facilityShortName) });
        try {
          const postCartPromise: Promise<ICart> = cartActions.postCart(
            { tokenName: getOnlineStoreCartTokenName(facilityShortName), facility_id: facilityId },
            false,
          ) as unknown as Promise<ICart>;

          cart = await postCartPromise;
        } catch {
          uiActions.showError("Failed to update cart");
          uiActions.dequeue();
          return;
        }
      }

      setViewShoppingBagState(prevState => ({
        ...prevState,
        facility: facilityRes.data[0],
        loaded: true,
      }));

      uiActions.dequeue();
    } catch {
      uiActions.dequeue();
    }
  }

  function navigateToCheckout() {
    history.push(`/online-store/${facilityShortName}/checkout`);
  }

  function navigateToStore() {
    history.push(`/online-store/${facilityShortName}`);
  }

  function handleQuantityChange(quantity: any, index: number) {
    const lineItem = cartStore.cart.line_items?.[index];

    if (isNaN(quantity) || lineItem === undefined) {
      return;
    }

    if (lineItem.quantity !== quantity) {
      cartActions.putLineItem(lineItem.id, cartStore.cart.token, quantity, lineItem.price, true);
    }
  }

  function removeLineItem(index: number) {
    const lineItem = cartStore.cart.line_items?.[index];

    if (lineItem === undefined) {
      return;
    }

    void cartActions.deleteLineItem(lineItem.id, cartStore.cart.token, true);
  }

  return (
    <>
      {viewShoppingBagState.loaded && (
        <div>
          <StoreHeader
            facilityShortName={facilityShortName}
            facilityLogoSource={viewShoppingBagState.facility.logo_source}
            shoppingBagHasItems={cartStore.cart?.line_items?.length > 0}
          />
          <StorePage>
            {cartStore.isLoaded && cartStore.cart && (
              <div className="shopping-bag-page">
                <div className="shopping-bag-line-items-section">
                  <h2 className="shopping-bag-section-title">Bag</h2>
                  {cartStore.cart.line_items.length === 0 ? (
                    <span>{t("guest.online_store.view_shopping_bag.001")}</span>
                  ) : (
                    cartStore.cart.line_items.map((lineItem, index) => {
                      return (
                        <div key={index}>
                          <div className="shopping-bag-line-item">
                            <img
                              className="shopping-bag-line-item-image"
                              src={lineItem.product_default_image?.source}
                            />
                            <div className="shopping-bag-line-item-information-container">
                              <div className="shopping-bag-line-item-title-container">
                                <div className="shopping-bag-line-item-product-title">{lineItem.product_title}</div>
                                {lineItem.variant_title !== lineItem.product_title && (
                                  <div className="shopping-bag-line-item-variant-title">{lineItem.variant_title}</div>
                                )}
                              </div>
                              <div className="shopping-bag-order-information-container">
                                <div className="text-subdued">{t("guest.online_store.view_shopping_bag.002")}</div>
                                <div className="text-subdued">{t("guest.online_store.view_shopping_bag.003")}</div>
                                <div className="text-subdued">{t("guest.online_store.view_shopping_bag.004")}</div>
                                <div>{displayCurrency("cad", lineItem.price)}</div>
                                <div className="shopping-bag-quantity-information">
                                  <Select
                                    defaultValue={lineItem.quantity}
                                    onChange={(quantity: any) => handleQuantityChange(quantity, index)}
                                  >
                                    {quantities.map((option: any, index: number) => {
                                      return (
                                        <Option key={index} name={option.label} value={option.value}>
                                          <span>{option.label}</span>
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </div>
                                <div>{displayCurrency("cad", lineItem.subtotal_price)}</div>
                                <div></div>
                                <div></div>
                                <button
                                  onClick={() => {
                                    removeLineItem(index);
                                  }}
                                  className="shopping-bag-remove-line-item"
                                >
                                  {t("guest.online_store.view_shopping_bag.005")}
                                </button>
                              </div>
                            </div>
                          </div>
                          {index !== cartStore.cart.line_items.length - 1 ? (
                            <span>
                              <hr className="shopping-bag-dividing-line"></hr>
                            </span>
                          ) : null}
                        </div>
                      );
                    })
                  )}
                </div>
                <div className="shopping-bag-order-summary-section">
                  <OrderSummary
                    subtotal={cartStore.cart.subtotal_price}
                    taxLines={cartStore.cart.tax_lines}
                    discount={cartStore.cart.total_discount}
                    total={cartStore.cart.total_price}
                  >
                    <button
                      className="shopping-bag-order-summary-checkout"
                      onClick={navigateToCheckout}
                      disabled={cartStore.cart.line_items.length === 0}
                    >
                      {t("guest.online_store.view_shopping_bag.006")}
                    </button>
                    <button className="shopping-bag-order-summary-continue" onClick={navigateToStore}>
                      {"Continue Shopping"}
                    </button>
                  </OrderSummary>
                </div>
              </div>
            )}
          </StorePage>
        </div>
      )}
    </>
  );
}
