import { APIGet, APIPost, APIPut, APIDelete, IAPIResponse, StatusCode } from "api/protocols";
import { CancelToken } from "axios";
import { NotificationType } from "components/notificationBar/NotificationBar";
import { ISlot, ITeeTime } from "redux/reducers/models/teetime";
import { UserType } from "redux/reducers/models/user";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseTeeSheetUrl = "/" + apiVersion + "/" + adminType + "/tee-sheet";

const newApiVersion = "2024-04";
const newBaseTeeSheetUrl = "/" + newApiVersion + "/" + adminType + "/tee-sheet";

// GET Tee Sheet

interface IGetTeeSheets {
  id?: number;
  facility_id?: number;
  course_id?: number;
  date?: string;
}

export interface ITeeSheet {
  id: number;
  facility_id: number;
  course_id: number;
  date: string;
  day_of_week: string;
  week_number: number;
  format: string;
  tee_sheet_template_title: string;
  tee_sheet_template_version: number;
  holiday: number;
}

interface IGetTeeSheetsRes extends IAPIResponse {
  data: ITeeSheet[];
}

export async function GetTeeSheets(params: IGetTeeSheets, useGlobalLoader: boolean): Promise<IGetTeeSheetsRes> {
  return await APIGet(baseTeeSheetUrl, params, useGlobalLoader);
}

// GET Tee Times

interface IGetTeeTime {
  id?: number;
  facility_id?: number;
  tee_sheet_id?: number;
  course_id?: number;
  division_id?: number;
  division_ids?: number[];
  date?: string;
  start_hole?: number;
  start_time?: string;
  end_time?: Partial<Date>;
  upcoming?: boolean;
  extended?: boolean;
  "extended-bookings"?: boolean;
  locks?: boolean;
  booking_fee?: boolean;
  booking_categories?: boolean;
  blocked_type?: string;
  limit?: number;
  events?: boolean;
  turn_tee_time?: boolean;
  limited?: boolean;
}

export interface IGetTeeTimeRes extends IAPIResponse {
  data: Array<ITeeTime>;
}

export async function GetTeeTime(params: IGetTeeTime, useGlobalLoader: boolean): Promise<IGetTeeTimeRes> {
  return await APIGet(baseTeeSheetUrl + "/tee-time", params, useGlobalLoader);
}

interface IPutApply {
  date?: string;
  template_id?: number;
  tee_sheet_ids?: number[];
}

interface IPutApplyRes {
  status: StatusCode;
  data: any;
}

// Apply Template to Tee Sheet
export async function PutApply(params: IPutApply, useGlobalLoader: boolean): Promise<IPutApplyRes> {
  return await APIPut(newBaseTeeSheetUrl + "/apply", params, useGlobalLoader);
}

export interface ITeeTimeBookingCategoryUpdate extends ITeeTime {
  booking_category_ids?: number[];
}

interface IPutTeeTime {
  tee_times: ITeeTimeBookingCategoryUpdate[];
}

// Put Tee time changes
export async function PutTeeTime(params: IPutTeeTime, useGlobalLoader: boolean) {
  return await APIPut(baseTeeSheetUrl + "/tee-time", params, useGlobalLoader);
}

interface IPostTeeTimeLock {
  tee_time_id: number;
}
export async function PostTeeTimeLock(params: IPostTeeTimeLock, useGlobalLoader: boolean) {
  return await APIPost(baseTeeSheetUrl + "/tee-time/lock", params, useGlobalLoader);
}

export async function PostEmailTeeSheet(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseTeeSheetUrl + "/notification/email", params, useGlobalLoader);
}

//Get TeeSheet Audit Trails
// Get Booking Audit Trails
export async function GetTeeTimeAuditTrails(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseTeeSheetUrl + "/tee-time/audit", params, useGlobalLoader);
}

// Get Tee Sheet Print
// Formatted version of the tee sheet for printing purposes
export async function GetTeeSheetPrint(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseTeeSheetUrl + "/print", params, useGlobalLoader);
}

interface IGetPrintCartSigns {
  date: string;
  tournament_id?: number;
  league_id?: number;
}

// Get Print Cart Signs
// Print cart signs for tee times with the provided tournament id
export async function GetPrintCartSigns(params: IGetPrintCartSigns, useGlobalLoader: boolean) {
  return await APIGet(baseTeeSheetUrl + "/print-cart-signs", params, useGlobalLoader);
}

interface IPostTeeTimeSqueeze {
  tee_time_id: number;
}

interface IPostTeeTimeSqueezeRes {
  data: {
    data: {
      blocked_type: any;
      course_id: number;
      date: string;
      division_id: number;
      divisions_allowed: number;
      facility_id: number;
      id: number;
      league_id: number;
      position: number;
      quantity_remaining: number;
      size: number;
      start_hole: string;
      start_time: string;
      tee_sheet_id: number;
      tournament_id: number;
      turn_course_id: number;
      turn_division_id: number;
      turn_tee_time_id: number;
    };
    message: string;
  };
  status: number;
}

export async function PostTeeTimeSqueeze(
  params: IPostTeeTimeSqueeze,
  useGlobalLoader: boolean,
): Promise<IPostTeeTimeSqueezeRes> {
  return await APIPost(baseTeeSheetUrl + "/tee-time/squeeze", params, useGlobalLoader);
}

export interface IPostTeeSheetNotification {
  date: string;
  course: string;
  subject: string;
  content: string;
  user_type: UserType | undefined;
  type: CustomerNotificationType | undefined;
  tee_sheet_id?: number | null;
  course_id?: number | null;
}

type CustomerNotificationType = "Admin" | "Customer";

export async function GetTeeSheetNotifications(useGlobalLoader: boolean) {
  return await APIGet(baseTeeSheetUrl + "/notification/all", useGlobalLoader);
}

export async function GetTeeSheetNotificationsByDate(params: { date: string }, useGlobalLoader: boolean) {
  return await APIGet(baseTeeSheetUrl + "/notification/all", params, useGlobalLoader);
}

export async function GetTeeSheetNotificationById(params: { id: number }, useGlobalLoader: boolean) {
  return await APIGet(baseTeeSheetUrl + "/notification", params, useGlobalLoader);
}

export async function PostTeeSheetNotification(params: IPostTeeSheetNotification, useGlobalLoader: boolean) {
  return await APIPost(baseTeeSheetUrl + "/notification", params, useGlobalLoader);
}
export async function PutTeeSheetNotification(params: IPostTeeSheetNotification, useGlobalLoader: boolean) {
  return await APIPut(baseTeeSheetUrl + "/notification", params, useGlobalLoader);
}
export async function DeleteTeeSheetNotification(params: { id: number }, useGlobalLoader: boolean) {
  return await APIDelete(baseTeeSheetUrl + "/notification", params, useGlobalLoader);
}

export async function GetCheckIns(useGlobalLoader: boolean) {
  return APIGet(baseTeeSheetUrl + "/check-in", useGlobalLoader);
}

interface IGetPricingSheets {
  id?: number;
  extended?: boolean;
}

export async function GetPricingSheets(params: IGetPricingSheets, useGlobalLoader: boolean) {
  return APIGet(baseTeeSheetUrl + "/pricing-sheet", params, useGlobalLoader);
}

export interface IPutCheckIns {
  id: number;
  customer_type_id: number;
  pricing_sheet_id: number;
  variant_id: number;
  holes: number;
}

export interface IConfiguration {
  configurations: IPutCheckIns[];
}

interface IGetTeeTimePricing extends IAPIResponse {
  data: Record<string, any>;
  message: string;
  status: number;
}

export async function PutCheckIns(params: IConfiguration, useGlobalLoader: boolean) {
  return await APIPut(baseTeeSheetUrl + "/check-in", params, useGlobalLoader);
}

export async function GetTeeTimePricing(
  params: any,
  useGlobalLoader: boolean,
  token?: CancelToken,
): Promise<IGetTeeTimePricing> {
  return await APIGet(baseTeeSheetUrl + "/tee-time/pricing", params, useGlobalLoader, token);
}
