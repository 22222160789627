import React from "react";
import { LocaleCurrency } from "helpers/Locale";

import "elements/register/register.scss";

interface IProps {
  currency?: string;
  discountLines?: any;
  handleDiscountCancelClick?: (discountLine: any) => void;
}

export default function CartDiscountLines(props: IProps) {
  return (
    <div className="flex">
      <div className="flex ml-auto flex-col w-64">
        {props.discountLines?.map((discountLine: any, index: number) => {
          return (
            <div
              className={"flex flex-row " + (props.handleDiscountCancelClick ? "hover:bg-gray-100 cursor-pointer" : "")}
              key={index}
              onClick={
                props.handleDiscountCancelClick ? () => props.handleDiscountCancelClick(discountLine) : undefined
              }
            >
              <span>{discountLine.title}</span>
              <span className="ml-auto">
                <LocaleCurrency currency={props.currency} amount={discountLine.amount} />
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}
