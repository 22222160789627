import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";

const apiVersion = "2021-01";
const adminType = "client-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/tee-sheet/tee-time/lock";

interface IGetTeeTimeLock {
  tee_time_id: number;
}

export async function GetTeeTimeLock(params: IGetTeeTimeLock, useGlobalLoader: boolean) {
  return await APIGet(baseUrl, params, useGlobalLoader);
}

interface IPostTeeTimeLock {
  tee_time_id?: number;
  tee_time_ids?: number[];
}

export async function PostTeeTimeLock(params: IPostTeeTimeLock, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

//Delete all tee time locks created by the user
export async function DeleteTeeTimeLocks(useGlobalLoader: boolean) {
  return await APIDelete(baseUrl, null, useGlobalLoader);
}
