import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetFacilityAdmin } from "api/rpc/2024-04/facilityAdmin/client/admin/admin";

import { showError } from "redux/actions/ui";
import { IFacilityStaff } from "redux/reducers/models/facility";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import { Badge } from "components/badge/Badge";
import Icon from "components/icon/Icon";
import DropFilter from "components/dropdown/DropFilter";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";

import "./facility.scss";

type FacilityStaffFilters = {
  accountState: Set<"active" | "disabled">;
};

export default function FacilityStaff() {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();

  const [staffFilters, setStaffFilters] = useState<FacilityStaffFilters>({
    accountState: new Set(["active"]),
  });

  const [adminData, setAdminData] = useState<IFacilityStaff[]>(undefined);

  /* adminData filtered to display specific accounts based off staffFilters.accountState */
  const filteredAdmins = useMemo(() => {
    if (adminData === undefined) {
      return undefined;
    } else {
      const activeFilters = [...staffFilters.accountState];
      console.log(activeFilters);

      if (activeFilters.length === 0) {
        return adminData;
      }

      return adminData.filter((admin, index: number) => {
        return (
          activeFilters.includes(admin.state as "active" | "disabled") ||
          (activeFilters.includes("active") && admin.state === null)
        );
      });
    }
  }, [adminData, staffFilters.accountState]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadFacilityStaff(source.token);
    return () => source.cancel();
  }, []);

  async function loadFacilityStaff(token?: CancelToken) {
    if (adminData !== undefined) {
      setAdminData(undefined);
    }

    const res = await GetFacilityAdmin(null, token ? false : true, token);
    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading staff accounts for the facility.")); // TODO: Translation
    }

    setAdminData(res.status !== StatusCode.OK ? [] : res.data);
  }

  function handleApplyFilters(filterReturn: Record<string, any>[]) {
    const filterIds = filterReturn.map(filter => filter.id as "active" | "disabled");
    setStaffFilters({
      ...staffFilters,
      accountState: new Set([...filterIds]),
    });
  }

  const primaryAction = {
    content: t("secure.facility.settings.facility.facility_staff.001"),
    action: () => history.push("/admin/settings/staff_new"),
  };

  console.log(filteredAdmins);

  return (
    <Page
      title={t("secure.facility.settings.facility.facility_staff.002")}
      subtitle={t("secure.facility.settings.facility.facility_staff.003")}
      narrow
      primaryAction={primaryAction}
    >
      <div className="flex flex-row flex-wrap gap-4 pb-4">
        <DropFilter
          filterType="Checkbox"
          title="Account State" // TODO: Translation
          filterData={[
            { id: "active", label: "Active" }, // TODO: Translation
            { id: "disabled", label: "Disabled" }, // TODO: Translation
          ]}
          filterIdPropFromData="id"
          filterLabelPropFromData="label"
          applyFilters={filterReturn => handleApplyFilters(filterReturn)}
          defaultCheckboxes={[...staffFilters.accountState]}
          leftAligned
        />
      </div>

      <DataTable
        columns={[
          { label: t("secure.facility.settings.facility.facility_staff.004"), width: "25%" },
          { label: t("secure.facility.settings.facility.facility_staff.005"), width: "25%" },
          { label: t("secure.facility.settings.facility.facility_staff.006"), width: "25%" },
          { label: "", width: "25%" }, // Admin state
        ]}
        loading={filteredAdmins === undefined}
      >
        {filteredAdmins?.map(admin => (
          <tr
            key={admin.id}
            className="clickable"
            onClick={() => history.push("/admin/settings/staff_edit/" + String(admin.id))}
          >
            <td>{admin.full_name}</td>
            <td>{admin.username}</td>
            <td>{admin.email}</td>
            <td>
              {}
              {admin.state === "active" || admin.state === null
                ? adminBadge(true, t("secure.facility.settings.facility.facility_staff.007"))
                : adminBadge(false, t("secure.facility.settings.facility.facility_staff.008"))}
            </td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}

function adminBadge(active: boolean, label: string) {
  if (active) {
    return (
      <Badge
        type="success"
        size="medium"
        iconLeft={<Icon icon="check" style="far" size="xsmall" />}
        iconSize="small"
        iconStyle="fas"
      >
        {label}
      </Badge>
    );
  } else {
    return (
      <Badge
        type="error"
        size="medium"
        iconLeft={<Icon icon="times" style="far" size="xsmall" />}
        iconSize="small"
        iconStyle="fas"
      >
        {label}
      </Badge>
    );
  }
}
