import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { formatDate } from "helpers/Helpers";

import { GetReport, GetReportRun, IConfiguration, GetCSVDownload } from "api/rpc/2022-09/clientAdmin/client/report";
import { GetVendor } from "api/rpc/clientAdmin/vendor";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";

import "./report.scss";

import ReportTable from "components/reportTable/ReportTable";
import { GetFacility } from "api/rpc/clientAdmin/facility/facility";
import FilterDropdown from "components/filterDropDown/FilterDropDown";
import { NavigationDropdown } from "components/navigationDropdown/NavigationDropdown";
import { useTranslation } from "react-i18next";
import { IUIActions } from "redux/actions/ui";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import { config } from "process";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { MOBILE_WIDTH, TABLET_WIDTH } from "helpers/ScreenSizes";

interface IReportState {
  id: number;
  title: string;
  code: string;
  dateRangeStart: Date;
  dateRangeEnd: Date;
  singleDate: Date;
  configurations: Array<IConfiguration>;
  allFacilities: Array<Record<string, any>>;
  allVendors: Array<Record<string, any>>;
  date_range: Array<string>;
  date: string;
  facilityOptions: Array<{ label: string; onClick: () => void }>;
  facility_id_name: string;
  [key: string]: any;
}

interface IReportDataState {
  headings: any;
  rows: any;
  columns: Array<Record<string, any>>;
  tableDisplay: boolean;
  description: string;
  compileDate: string;
}

interface IParams {
  report_id: number;
  [key: string]: any;
}

interface IProps {
  uiActions: IUIActions;
}

export default function Single(props: IProps) {
  const { id }: any = useParams();
  const { t } = useTranslation();
  const today = new Date();
  const windowSize = useWindowSize();

  function formatDateRange(startingDate: Date, endingDate: Date) {
    return [formatDate(startingDate), formatDate(endingDate) + " 23:59:00"];
  }

  const [state, setState] = useState<IReportState>({
    dateRangeStart: today,
    dateRangeEnd: today,
    singleDate: today,
    id: null,
    title: "",
    code: "",
    configurations: [],
    allFacilities: [],
    allVendors: [],
    date_range: formatDateRange(today, today),
    date: formatDate(today),
    facilityOptions: [],
    facility_id_name: "",
  });

  const [reportData, setReportData] = useState<IReportDataState>({
    headings: [],
    rows: [],
    columns: [],
    tableDisplay: false,
    description: "",
    compileDate: "",
  });

  useEffect(() => {
    void loadReport();
    void getFacilities();
    void getVendors();
  }, []);

  useEffect(() => {
    if (state.dateRangeStart && state.dateRangeEnd) {
      const date_range = formatDateRange(state.dateRangeStart, state.dateRangeEnd);
      setState(prevState => ({ ...prevState, date_range }));
    }
  }, [state.dateRangeStart, state.dateRangeEnd]);

  function setDateRangeStart(date: Date) {
    setState(prevState => ({
      ...prevState,
      dateRangeStart: date,
    }));
  }

  function setDateRangeEnd(date: Date) {
    setState(prevState => ({
      ...prevState,
      dateRangeEnd: date,
    }));
  }

  function setSingleDate(date: Date) {
    setState(prevState => ({
      ...prevState,
      singleDate: date,
      date: formatDate(date),
    }));
  }

  async function loadReport() {
    const reportRes = await GetReport({ id: id }, true);
    if (reportRes.status !== StatusCode.OK) {
      return;
    }

    const report = reportRes.data[0];
    const sortedConfigurations = [...report.configurations].sort((prev, next) => prev.position - next.position);

    setState(prev => ({
      ...prev,
      id: report.id,
      title: report.title,
      code: report.code,
      configurations: sortedConfigurations,
    }));
  }

  async function getReport() {
    const configuredParams = setConfigParameters();
    const reportRes = await GetReportRun(configuredParams, true);

    if (reportRes.status !== StatusCode.OK) {
      return;
    }

    const compileDate = reportRes.data?.compiled_at ? reportRes.data?.compiled_at.concat("Z") : "";

    setReportData(prev => ({
      ...prev,
      rows: reportRes.data.rows,
      columns: reportRes.data.columns,
      description: reportRes.data.description ?? "",
      compileDate,
    }));
  }

  async function getDownload() {
    let configuredParams = setConfigParameters();

    if (!configuredParams) {
      return;
    }

    configuredParams = { ...configuredParams, type: "csv" };
    const segmentsRes = await GetCSVDownload(configuredParams, true);

    // Do we need a translation for this?
    if (segmentsRes.status !== StatusCode.OK) {
      props.uiActions.showError("Error exporting report");
      return;
    }

    window.open(segmentsRes?.data, "_blank").focus();
  }

  function setConfigParameters() {
    const params: IParams = { report_id: state.id };
    let missingRequiredConfig = false;

    state.configurations?.forEach(config => {
      if (config?.required === 1 && !state[config?.option_name]) {
        props.uiActions.showError(t("secure.facility.reports.report.001") + config.label);
        missingRequiredConfig = true;
        return;
      } else if (config.option_name.includes("grat")) {
        params[config?.option_name] = isNaN(state[config?.option_name]) ? 0 : Number(state[config?.option_name]);
      } else {
        params[config?.option_name] = state[config?.option_name];
      }
    });

    if (missingRequiredConfig) {
      return false;
    }

    return params;
  }

  const primaryAction = {
    content: "Run",
    action: getReport,
  };

  const secondaryActions = {
    content: "Export",
    action: getDownload,
  };

  function handleNavDropdownChange(id: string, value: string | number) {
    setState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleFacilityNavDropdownChange(facility_name: string, facility_id: number) {
    setState(prevState => ({ ...prevState, facility_id_name: facility_name, facility_id }));
  }

  async function getFacilities() {
    const facilityRes = await GetFacility(null, true);
    const facilityOptions: Array<{ label: string; onClick: () => void }> = [];
    facilityRes?.data?.forEach(facility => {
      facilityOptions.push({
        label: facility?.long_name,
        onClick: () => handleFacilityNavDropdownChange(facility?.long_name, facility?.id),
      });
    });

    if (facilityRes.status !== StatusCode.OK) {
      console.log(facilityRes.message);
      return;
    }
    setState(prevState => ({ ...prevState, allFacilities: facilityRes.data, facilityOptions }));
  }

  async function getVendors() {
    const vendorRes = await GetVendor(null, true);
    if (vendorRes.status !== StatusCode.OK) {
      console.log(vendorRes.message);
      return;
    }
    setState(prevState => ({ ...prevState, allVendors: vendorRes.data }));
  }

  function onCheckBoxChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value, checked, id } = e.target;
    let updateSelectedValues: any[] = [];
    if (state[id]) {
      updateSelectedValues = [...state[id]];
    }
    if (checked) {
      updateSelectedValues.push(Number(value));
    } else {
      const removeIndex = updateSelectedValues?.findIndex(valueId => valueId === Number(value));
      if (removeIndex !== -1) {
        updateSelectedValues.splice(removeIndex, 1);
      }
    }
    setState(prevState => ({ ...prevState, [id]: updateSelectedValues }));
  }

  function handleRemoveAll(id: string) {
    setState(prevState => ({ ...prevState, [id]: [] }));
  }

  function handleAddAllFacilities(id: string) {
    const updatedSelectedFacilities: any[] = [];
    state.allFacilities?.forEach(facility => {
      updatedSelectedFacilities.push(facility?.id);
    });
    setState(prevState => ({
      ...prevState,
      [id]: updatedSelectedFacilities,
    }));
  }

  function handleAddAllVendors(id: string) {
    const updatedSelectedVendors: any[] = [];
    state.allVendors?.forEach(vendor => {
      updatedSelectedVendors.push(vendor?.id);
    });
    setState(prevState => ({
      ...prevState,
      [id]: updatedSelectedVendors,
    }));
  }

  return (
    <Page
      title={state.title}
      primaryAction={primaryAction}
      secondaryActions={[secondaryActions]}
      breadcrumbs={[{ prefix: true, label: "Reports", url: "/admin/report" }]}
    >
      {state.configurations?.length > 0 && (
        <Card>
          <Card.Section>
            <div className="report-filters-container">
              {state.configurations?.map((config, index) => {
                if (config.data_type === "integer_array" && config.option_name === "facility_ids") {
                  return (
                    <FilterDropdown
                      key={index}
                      label={config.label}
                      data={state.allFacilities}
                      dataLabelProperty={"long_name"}
                      checkBoxId={config.option_name}
                      dataValueProperty="id"
                      onCheckBoxChange={onCheckBoxChange}
                      onClear={() => handleRemoveAll(config.option_name)}
                      onAddAll={() => handleAddAllFacilities(config.option_name)}
                      iconName="chevron-down"
                      leftAligned={windowSize.width <= MOBILE_WIDTH || index === 0}
                    />
                  );
                } else if (config.data_type === "integer" && config.option_name === "facility_id") {
                  return (
                    <NavigationDropdown
                      key={index}
                      label={<span className="mr-2">{state.facility_id_name || config.label}</span>}
                      options={state.facilityOptions}
                      leftAlign={windowSize.width <= MOBILE_WIDTH || index === 0}
                    />
                  );
                } else if (config.data_type === "string" && config?.values) {
                  const configArray = config.values.reduce(
                    (newArray: { label: any; onClick: () => void }[], currentValue) => {
                      newArray.push({
                        label: currentValue,
                        onClick: () => handleNavDropdownChange(config.option_name, currentValue),
                      });
                      return newArray;
                    },
                    [],
                  );
                  return (
                    <NavigationDropdown
                      key={index}
                      label={<span className="mr-2">{state[config.option_name] || config.label}</span>}
                      options={configArray}
                      leftAlign={windowSize.width <= MOBILE_WIDTH}
                    />
                  );
                } else if (config.data_type === "date_time_array" && config.option_name === "date_range") {
                  return (
                    <DatePickerInput
                      key={index}
                      months={windowSize.width > TABLET_WIDTH ? 2 : 1}
                      position="left"
                      showQuickOptions={windowSize.width > MOBILE_WIDTH}
                      startingDate={state.dateRangeStart}
                      setStartingDate={setDateRangeStart}
                      endingDate={state.dateRangeEnd}
                      setEndingDate={setDateRangeEnd}
                    />
                  );
                } else if (config.data_type === "date_time" && config.option_name === "date") {
                  return (
                    <DatePickerInput
                      key={index}
                      months={1}
                      position="left"
                      startingDate={state.singleDate}
                      setStartingDate={setSingleDate}
                    />
                  );
                } else if (config.data_type === "integer_array" && config.option_name === "vendor_ids") {
                  return (
                    <FilterDropdown
                      key={index}
                      label={config.label}
                      data={state.allVendors}
                      dataLabelProperty={"title"}
                      checkBoxId={config.option_name}
                      dataValueProperty="id"
                      onCheckBoxChange={onCheckBoxChange}
                      onClear={() => handleRemoveAll(config.option_name)}
                      onAddAll={() => handleAddAllVendors(config.option_name)}
                      iconName="chevron-down"
                      leftAligned={windowSize.width <= MOBILE_WIDTH}
                    />
                  );
                }
              })}
            </div>
          </Card.Section>
        </Card>
      )}

      <div className="dynamic-table-container">
        <ReportTable
          data={reportData.rows}
          columns={reportData.columns}
          description={reportData.description}
          compileDate={reportData.compileDate}
        />
      </div>
    </Page>
  );
}
