import { APIGet, APIPost, APIPut, StatusCode } from "api/protocols";
import { CancelToken } from "axios";

const adminType = "facility-admin";
const version = "2022-09";
const baseUrl = "/" + version + "/" + adminType + "/order";

interface IGetOrder {
  id?: number;
  search?: string;
  customer_id?: number;
  extended?: boolean;
  refund?: boolean;
  limit?: number;
  offset?: number;
  filters?: Array<Record<string, any>>;
}

// GET Order
// Retrieve a list of orders for a facility
// extended: boolean
export async function GetOrder(params: IGetOrder, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseUrl, params, useGlobalLoader, token);
}

interface IPostOrder {
  customer_id: number;
}

interface IPostOrderRes {
  status: StatusCode;
  data: any;
}

// POST Order
// Create a new order
export async function PostOrder(params: IPostOrder, useGlobalLoader: boolean): Promise<IPostOrderRes> {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

// POST Line Items
// Add a new line to an order
interface IPostLineItemsToOrder {
  order_id: number;
  variant_id: number;
  quantity: number;
}

interface IPostLineItemsToOrderRes {
  status: StatusCode;
  data: any;
}

export async function PostLineItemsToOrder(
  params: IPostLineItemsToOrder,
  useGlobalLoader: boolean,
): Promise<IPostLineItemsToOrderRes> {
  return await APIPost(baseUrl + "/line-item", params, useGlobalLoader);
}

interface IPutOrderComplete {
  order_id: number;
  gift_cards: { cart_line_item_id: number; code: string; pin: number; random: boolean }[];
  register_id?: number;
  league_ids?: Array<number>;
}

interface IPutVoidOrder {
  order_id: number;
  notes?: string;
  financial_status?: string;
}

interface IPutReinstateOrder {
  order_id: number;
}

// PUT Order
// Update some attributes on an order
export async function PutOrder(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

export async function GetReceipt(
  params: { id: number; tee_time_id?: number; type?: string },
  useGlobalLoader?: boolean,
) {
  return await APIGet(baseUrl + "/receipt", params, useGlobalLoader);
}

export async function PutOrderComplete(params: IPutOrderComplete, useGlobalLoader?: boolean) {
  return await APIPut(baseUrl + "/complete", params, useGlobalLoader);
}

export async function PutVoidOrder(params: IPutVoidOrder, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/void", params, useGlobalLoader);
}

export async function PutReinstateOrder(params: IPutReinstateOrder, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/reinstate", params, useGlobalLoader);
}

//Email Order Receipt
export async function EmailReceipt(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/receipt/email", params, useGlobalLoader);
}
