import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/tee-sheet/booking-engine/window";

interface IGetBookingWindow {
  id?: string;
  extended?: boolean;
}

interface IPutBookingWindow {
  id: number;
  days_in_advance_start?: number;
  days_in_advance_end?: number;
}

interface IGetBookingFee {
  id?: string;
}

interface IBookingFeeRes {
  status: number;
  data: any;
}

interface IPutBookingFee {
  id: number;
  price?: number;
  taxable?: boolean;
  unit?: string;
  non_refundable?: boolean;
  days_in_advance_start?: number;
  days_in_advance_end?: number;
}

export async function GetBookingWindow(params: IGetBookingWindow, useGlobalLoader: boolean) {
  return await APIGet(baseUrl, params, useGlobalLoader);
}

export async function PutBookingWindow(params: IPutBookingWindow, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

export async function GetBookingFee(params: IGetBookingFee, useGlobalLoader: boolean): Promise<IBookingFeeRes> {
  return await APIGet(baseUrl + "/fee", params, useGlobalLoader);
}

export async function PutBookingFee(params: IPutBookingFee, useGlobalLoader: boolean): Promise<IBookingFeeRes> {
  return await APIPut(baseUrl + "/fee", params, useGlobalLoader);
}
