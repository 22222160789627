import React, { useRef, useState } from "react";
// import Search from "./Search";
import classNames from "classnames";
import "./style.scss";

export interface IInputProps {
  className?: string;
  placeholder?: string;
  onChange?: (value: any) => void;
  onKeyDown?: (value: any) => void;
  value?: string;
  disabled?: boolean;
  type?: "text" | "password" | "email" | "search";
  prefixCls?: string;
  id?: string;
  addonAfter?: React.ReactNode;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  name?: string;
  style?: any;
}

interface IInputComposition {
  Search: React.FC;
}

const Input: React.FC<IInputProps> = props => {
  const {
    prefixCls = "rc-input",
    type = "text",
    className,
    onChange,
    onKeyDown,
    addonAfter,
    prefix = null,
    suffix = null,
    ...rest
  } = props;

  const [value, setValue] = useState<string>("");
  const [focused, setFocused] = useState<boolean>(false);

  function resolveOnChange<E extends HTMLInputElement | HTMLTextAreaElement>(e: React.ChangeEvent<E>) {
    if (!onChange) {
      return;
    }
    const event = e;
    onChange(event);
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    resolveOnChange(e);
  };

  const handleKeyDown = (event: any) => {
    if (!onKeyDown) {
      return;
    }

    onKeyDown(event);
  };

  const inputClassName = classNames(prefixCls, {
    [`${prefixCls}-${type}`]: type,
    [`${className}`]: !prefix && !suffix,
  });

  const prefixNode = prefix ? <span className={`${prefixCls}-prefix`}>{prefix}</span> : null;
  const suffixNode = suffix ? <span className={`${prefixCls}-suffix`}>{suffix}</span> : null;

  const inputRef = useRef(null);

  const renderInput = () => {
    const affixWrapperClass = classNames(`${prefixCls}-affix-wrapper`, {
      [`${prefixCls}-affix-wrapper-focused`]: focused,
      [`${className}`]: prefix || suffix,
    });

    const focusInput = () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
      setFocused(true);
    };

    const onBlur = () => {
      setFocused(false);
    };

    if (prefix || suffix) {
      return (
        <span className={affixWrapperClass} onClick={focusInput}>
          {prefixNode}
          <input
            ref={inputRef}
            onBlur={onBlur}
            onFocus={focusInput}
            type={type}
            value={value}
            onChange={e => handleChange(e)}
            onKeyDown={handleKeyDown}
            {...rest}
            className={inputClassName}
          />
          {suffixNode}
        </span>
      );
    } else {
      return (
        <input
          type={type}
          value={value}
          onChange={e => handleChange(e)}
          onKeyDown={handleKeyDown}
          {...rest}
          className={inputClassName}
        />
      );
    }
  };

  return renderInput();
};

export default Input;
