import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "client-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/facility";

export type TFacilityPayout = {
  id: number;
  facility_id: number;
  authorization: string;
  status: string;
  currency: string;
  gross: number;
  fee: number;
  net: number;
  facility_external_account_id?: number;
  process_date: Date;
  /** date string */
  processed_at?: string;
  arrival_date?: string;
  statement_description?: string;
  notes?: string;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
};

interface IGetPayouts {
  id?: number;
  facility_id?: string | number;
  month?: any;
  year?: any;
}

interface IGetTransactions {
  facility_id: number;
  payout_id: number;
}

export async function GetPayout(params: IGetPayouts, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<TFacilityPayout[]>(baseUrl + "/payout", params, useGlobalLoader, token);
}

export async function GetTransactions(params: IGetTransactions, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseUrl + "/payout/transaction", params, useGlobalLoader, token);
}
