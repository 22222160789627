import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../facility/facility.scss";
import { valueToString } from "helpers/Helpers";

import Page from "components/page/Page";
import Card from "components/card/Card";

import { StatusCode } from "api/protocols";

import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GetCustomerType } from "api/rpc/2024-04/facilityAdmin/customer/type";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";

export default function CustomerTypes(props: any) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [customerTypeState, setcustomerTypeState] = useState({
    customerType: null,
    bookingCategories: null,
    title: "",
    full_title: "",
    color: "",
    booking_category: null,
  });

  useEffect(() => {
    void loadCustomerTypes();

    return () => {
      setcustomerTypeState({
        customerType: null,
        bookingCategories: null,
        title: "",
        full_title: "",
        color: "",
        booking_category: null,
      });
    };
  }, []);

  async function loadCustomerTypes() {
    const res = await GetCustomerType(
      {
        booking_category: true,
      },
      false,
    );
    if (res.status !== StatusCode.OK) {
      return;
    }

    setcustomerTypeState(prev => ({
      ...prev,
      customerType: res.data,
    }));
  }

  function navigateToNewCustomerType() {
    history.push("/admin/settings/customer_types/new");
  }

  function navigateToEditCustomerType(url: string) {
    history.push(url);
  }

  const primaryAction = {
    content: t("secure.facility.settings.customer_type.customer_type.001"),
    action: navigateToNewCustomerType,
  };

  return (
    <Page
      title={t("secure.facility.settings.customer_type.customer_type.002")}
      subtitle={t("secure.facility.settings.customer_type.customer_type.003")}
      narrow
      primaryAction={primaryAction}
    >
      <Card>
        <Card.Section table="true">
          <DataTable
            columns={[
              { label: t("secure.facility.settings.customer_type.customer_type.004") },
              { label: t("secure.facility.settings.customer_type.customer_type.009") },
              { label: t("secure.facility.settings.customer_type.customer_type.005") },
              { label: "" },
              { label: t("secure.facility.settings.customer_type.customer_type.006") },
              { label: t("secure.facility.settings.customer_type.customer_type.007") },
            ]}
            loading={customerTypeState.customerType === null ? true : false}
          >
            {customerTypeState.customerType?.map((customerType: any, index: number) => {
              return (
                <tr
                  key={index}
                  onClick={() =>
                    navigateToEditCustomerType("/admin/settings/customer_types/" + String(customerType.id))
                  }
                  className="clickable"
                >
                  <td className="table-cell-text-lead">{customerType.title}</td>
                  <td className="table-cell-text-lead">{customerType.subtitle}</td>
                  <td>
                    <span>{valueToString(customerType.application, "capitalize")}</span>
                  </td>
                  <td>
                    {customerType.application === "green_fee" && customerType.color && (
                      <FontAwesomeIcon icon={["fas", "circle"]} color={customerType?.color} />
                    )}
                  </td>
                  <td>
                    <em>{t("secure.facility.settings.customer_type.customer_type.008")}</em>
                  </td>
                  <td>{customerType?.default_booking_category?.title}</td>
                </tr>
              );
            })}
          </DataTable>
          {/* <table className="ui-table ui-table-clickable">
            <thead>
              <tr>
                <th>{t("secure.facility.settings.customer_type.customer_type.004")}</th>
                <th>{t("secure.facility.settings.customer_type.customer_type.009")}</th>
                <th>{t("secure.facility.settings.customer_type.customer_type.005")}</th>
                <th></th>
                <th>{t("secure.facility.settings.customer_type.customer_type.006")}</th>
                <th>{t("secure.facility.settings.customer_type.customer_type.007")}</th>
              </tr>
            </thead>
            <tbody>

            </tbody>
          </table> */}
        </Card.Section>
      </Card>
    </Page>
  );
}
