import { DragLayerMonitor, XYCoord, useDragLayer } from "react-dnd";
import React, { useEffect, useState } from "react";
import { IEditInputRowRange } from "./useDropEditInputRow";
import { IEditInputDropItem } from "../useDropEditInputLayout/useDropEditInputLayout";

interface IEditInputRowCustomDragLayer {
  rowRef: React.MutableRefObject<HTMLDivElement>;
  isOver: boolean;
  canDrop: boolean;
  getRowInputDropIndexInRange: (
    rowCoordinate: XYCoord,
    relativeDragCoordinate: XYCoord,
  ) => [number, IEditInputRowRange[]];
}

interface IEditInputRowDropHintState {
  x: number;
  height: number;
}

export default function EditInputRowCustomDragLayer(props: IEditInputRowCustomDragLayer) {
  const { rowRef, isOver, canDrop, getRowInputDropIndexInRange } = props;

  const DROP_HINT_THICKNESS = 1;

  const [dropHintState, setDropHintState] = useState<IEditInputRowDropHintState>(null);

  const { clientOffset, item } = useDragLayer((monitor: DragLayerMonitor) => {
    return {
      clientOffset: monitor.getClientOffset(),
      item: monitor.getItem<IEditInputDropItem>(),
    };
  });

  useEffect(() => {
    if (clientOffset && item && isOver) {
      const rowBoundingRectangle = rowRef.current.getBoundingClientRect();

      const rowCoordinate: XYCoord = {
        x: rowBoundingRectangle.x,
        y: rowBoundingRectangle.y,
      };

      const relativeDragCoordinate: XYCoord = {
        x: clientOffset.x - rowCoordinate.x,
        y: clientOffset.y - rowCoordinate.y,
      };

      const [inputDropIndex, inputRanges] = getRowInputDropIndexInRange(rowCoordinate, relativeDragCoordinate);

      const dropHintLocation = inputRanges?.[inputDropIndex]?.startX ?? inputRanges[inputRanges.length - 1].endX;

      setDropHintState({
        x: dropHintLocation === 0 ? DROP_HINT_THICKNESS : dropHintLocation,
        height: rowBoundingRectangle.height / 2,
      });
    } else {
      setDropHintState(null);
    }
  }, [clientOffset, item, isOver]);

  return (
    <div
      style={{
        display: isOver && canDrop ? "block" : "none",
        position: "absolute",
        zIndex: 99,
        left: dropHintState?.x,
        top: "25%",
        height: dropHintState?.height,
        width: DROP_HINT_THICKNESS,
        backgroundColor: "green",
      }}
    />
  );
}
