import { APIGet, APIPost, APIPut, APIDelete, StatusCode, APIUrl, AdminType } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseBookingUrl = "/" + apiVersion + "/" + adminType + "/tee-sheet/tee-time/booking";

// POST Booking
// Create a new booking for a tee time
export interface IPostBooking {
  tee_time_id?: number;
  send_confirmation?: boolean;
  quantity?: number;
  holes?: number;
  power_cart_quantity?: number;
  customer_id?: number;
  customer_payment_method_id?: number;
  tee_time_slots?: {
    id: number;
    customer_id: number;
    guest: boolean;
  }[];
  booking_group_id?: string;
}

export async function PostBooking(params: IPostBooking, useGlobalLoader: boolean) {
  return await APIPost(baseBookingUrl, params, useGlobalLoader);
}

// PUT Booking
// Update an existing tee time booking
export interface IPutBooking {
  id: number;
  quantity?: number;
  order_id?: number;
  customer_id?: number;
  holes?: number;
  power_cart_quantity?: number;
  customer_payment_method_id?: number;
  tee_time_slots?: { id: number; customer_id: number; guest: boolean }[];
  extended?: boolean;
}

interface IPutBookingRes {
  status: StatusCode;
  data: {
    message?: string;
    data: {
      order_id: number;
    };
  };
  message?: string;
}

export interface IPutMoveBooking {
  booking_id: number;
  tee_time_id: number;
}

interface IPutMoveBookingRes {
  status: StatusCode;
  data: {
    message?: string;
    data: {
      order_id: number;
    };
  };
  message?: string;
}

interface IGetBooking {
  id?: number;
  tee_time_id?: number;
  customer_id?: number;
  status?: boolean;
  extended?: boolean;
  extended_tee_time?: boolean;
}

interface IPutCancelBooking {
  tee_time_booking_id: number;
}

interface IBookingCounts {
  checked: number;
  no_show: number;
  no_show_defer: number;
  no_show_charge: number;
  no_show_weather: number;
  no_show_details: {
    charge: number;
    defer: number;
    waived: number;
  };
  unchecked: number;
}
interface IBookingCountYear {
  [key: string]: IBookingCounts;
}
interface IBookingCountsRes {
  status: StatusCode;
  data: IBookingCountYear;
}
export interface IPutCheckParticipant {
  booking_participant_ids: number[];
  order_id: number;
  green_fee_paid: boolean;
  power_cart_paid: boolean;
}

interface IPutBookingConfirmation {
  booking_group_id: number;
}

export async function PutBooking(params: IPutBooking, useGlobalLoader: boolean): Promise<IPutBookingRes> {
  return await APIPut(baseBookingUrl, params, useGlobalLoader);
}

export async function PutMoveBooking(params: IPutMoveBooking, useGlobalLoader: boolean): Promise<IPutMoveBookingRes> {
  return await APIPut(baseBookingUrl + "/move", params, useGlobalLoader);
}

export async function GetAuditTrails(params: { tee_time_booking_id: number }, useGlobalLoader: boolean) {
  return await APIGet(baseBookingUrl + "/audit", params, useGlobalLoader);
}

export async function GetBooking(params: IGetBooking, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseBookingUrl, params, useGlobalLoader, token);
}

export async function PutCancelBooking(params: IPutCancelBooking, useGlobalLoader: boolean) {
  return await APIPut(baseBookingUrl + "/cancel", params, useGlobalLoader);
}

export async function PutCopyBooking(
  params: { booking_id: number; tee_time_ids: Array<string> },
  useGlobalLoader: boolean,
) {
  return await APIPut(baseBookingUrl + "/copy", params, useGlobalLoader);
}

export async function PutCheckParticipant(params: IPutCheckParticipant, useGlobalLoader: boolean) {
  return await APIPut(baseBookingUrl + "/participant/check", params, useGlobalLoader);
}

// Cancel participant in a booking
export interface IPutCancelParticipant {
  id: number;
}

export async function PutCancelParticipant(params: IPutCancelParticipant, useGlobalLoader: boolean) {
  return await APIPut(baseBookingUrl + "/participant/cancel", params, useGlobalLoader);
}

export async function GetTotalBookings(
  params: { customer_id: number },
  useGlobalLoader: boolean,
  cancelToken?: CancelToken,
): Promise<IBookingCountsRes> {
  return await APIGet(baseBookingUrl + "/participant/count", params, useGlobalLoader, cancelToken);
}

export async function PutBookingConfirmation(params: IPutBookingConfirmation, useGlobalLoader?: boolean) {
  return await APIPut(baseBookingUrl + "/confirmation", params, useGlobalLoader);
}

interface IGetBookingParticipant {
  customer_id: number;
  extended_booking: boolean;
  offset: number;
  year?: string;
}

export async function GetBookingParticipant(
  params: IGetBookingParticipant,
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseBookingUrl + "/participant", params, useGlobalLoader, token);
}

interface IPutCheckParticipantNew {
  booking_participants: Array<{ booking_participant_id: number; green_fee_paid: boolean; power_cart_paid: boolean }>;
  order_id: number;
}

export async function PutCheckParticipantAll(params: IPutCheckParticipantNew, useGlobalLoader: boolean) {
  return await APIPut(
    APIUrl(AdminType.FacilityAdmin, "/tee-sheet/tee-time/booking/participant/check-all"),
    params,
    useGlobalLoader,
  );
}
