import React, { useEffect, useMemo, useState } from "react";
import axios, { CancelToken } from "axios";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StatusCode } from "api/protocols";
import { GetBookingParticipant } from "api/rpc/2022-09/guest/bookingEngine";
import { IBooking } from "redux/reducers/models/teetime";
import { capitalize, convertTime } from "helpers/Helpers";
import Spin from "components/spin/spin";
import { ButtonNew as Button } from "components/buttonNew";
import { useHistory, useParams } from "react-router";
import { Badge } from "components/badge/Badge";
import { useTranslation } from "react-i18next";
import { ClientPortalNavigation } from "../ClientPortalNavigation";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { showError } from "redux/actions/ui";
import "./clientPortalMemberships.scss";
import Callout from "components/callout/Callout";
import Tabs from "components/tabs/Tabs";
import { GetCustomerMemberships, ICustomerMembership } from "api/rpc/2024-04/customer/membership";
import { useClientPortal } from "../useClientPortal";

interface IFilterState {
  limit: number;
  offset: number;
}

interface IParams {
  clientShortName: string;
}

export default function ClientPortalMemberships() {
  const [memberships, setMemberships] = useState<Array<ICustomerMembership>>(null);
  const [filter, setFilter] = useState<IFilterState>({
    limit: 15,
    offset: 0,
  });
  const { clientShortName } = useParams<IParams>();
  const dispatch = useAppDispatch();
  const { clientPortalStore } = useAppSelector(store => store);
  const history = useHistory();
  const [tabSelected, setTabSelected] = useState<number>(0);
  const HOME_URL = useClientPortal().getHomePageUrl();

  const tabs = [{ content: "Active" }, { content: "Expired" }];

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (clientPortalStore?.client) {
      void getMemberships(source.token);
    } else {
      history.push(HOME_URL);
    }

    return () => {
      source.cancel("Cancelled");
    };
  }, [filter]);

  async function getMemberships(token: CancelToken) {
    //display loader
    if (memberships !== null) {
      setMemberships(null);
    }
    const membershipRes = await GetCustomerMemberships(
      { ...filter, client_id: clientPortalStore?.client?.id },
      false,
      token,
    );

    if (membershipRes.status !== StatusCode.OK) {
      if (token.reason) {
        return;
      }
      dispatch(showError("Error getting memberships"));
      setMemberships([]);
      return;
    }
    setMemberships(membershipRes.data);
  }

  function handleTabChange(selectedTabIndex: number) {
    setTabSelected(selectedTabIndex);
  }

  const filteredMemberships = useMemo(() => {
    if (memberships) {
      return memberships?.filter(membership =>
        tabSelected === 0 ? membership?.status === "active" : !(membership.status === "active"),
      );
    }
  }, [memberships, tabSelected]);

  return (
    <div className="client-portal-memberships">
      <ClientPortalNavigation showNavOptions />
      <div className="client-portal-memberships-container">
        <h1 className="memberships-header-text">Memberships</h1>
        <Tabs tabs={tabs} selected={tabSelected} onSelect={handleTabChange}>
          <div className="memberships-list-container">
            {memberships ? (
              memberships && memberships.length > 0 && filteredMemberships.length > 0 ? (
                filteredMemberships?.map((membership, index) => {
                  return (
                    <div key={index} className="memberships-container">
                      <div>
                        <div className="membership-name">{membership?.membership_title}</div>
                        <div className="facility-name">{membership?.home_facility_id}</div>
                        {membership?.expiry && (
                          <div className="expiry-date">
                            {moment(membership.expiry).isSameOrAfter(moment()) ? "Expires on" : "Expired on"}{" "}
                            {moment(membership?.expiry).format("LL")}
                          </div>
                        )}
                      </div>
                      <Badge size="small" type="primary">
                        {capitalize(membership?.status)}
                      </Badge>
                    </div>
                  );
                })
              ) : (
                <Callout
                  type="info"
                  title="No Memberships"
                  // content="There are no memberships on this account" />
                  content={
                    tabSelected === 0 && filteredMemberships.length <= 0
                      ? "There are no active memberships on this account"
                      : "There are no expired memberships on this account"
                  }
                />
              )
            ) : (
              <span style={{ display: "flex", justifyContent: "center" }}>
                <Spin />
              </span>
            )}
          </div>
          {memberships && memberships?.length > 0 && (
            <div className="memberships-footer">
              <div>
                <Button
                  size="small"
                  type="secondary"
                  onClick={() => setFilter(prev => ({ ...prev, offset: prev.offset - prev.limit }))}
                  disabled={!memberships || memberships?.length === 0 || !(filter.limit <= filter.offset)}
                >
                  <FontAwesomeIcon icon={"arrow-left"} />
                  &nbsp;
                  {"Prev"}
                </Button>
              </div>
              <div>
                <Button
                  size="small"
                  type="secondary"
                  onClick={() => setFilter(prev => ({ ...prev, offset: prev.offset + prev.limit }))}
                  disabled={!memberships || memberships?.length === 0 || !(memberships?.length === filter.limit)}
                >
                  {"Next"}
                  &nbsp;
                  <FontAwesomeIcon icon={"arrow-right"} />
                </Button>
              </div>
            </div>
          )}
        </Tabs>
      </div>
    </div>
  );
}
