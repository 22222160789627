import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseLeagueUrl = "/" + apiVersion + "/" + adminType + "/league";

export async function GetSeasonLeaderboard(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseLeagueUrl + "/scoring/season-leaderboard", params, useGlobalLoader);
}

// PUT Order of Merit
export async function PutUpdateSeasonLeaderboard(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseLeagueUrl + "/scoring/season-leaderboard", params, useGlobalLoader);
}

// GET Print Season Leaderboard
export async function GetPrintSeasonLeaderboard(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseLeagueUrl + "/scoring/season-leaderboard/print", params, useGlobalLoader);
}

// GET Order of Merit
export async function GetOrderOfMerit(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseLeagueUrl + "/scoring/order-of-merit", params, useGlobalLoader);
}

// PUT Order of Merit
export async function PutUpdateOrderOfMerit(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseLeagueUrl + "/scoring/order-of-merit", params, useGlobalLoader);
}
// GET Print Order of Merit
export async function GetPrintOrderOfMerit(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseLeagueUrl + "/scoring/order-of-merit/print", params, useGlobalLoader);
}
