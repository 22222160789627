import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useAppDispatch } from "hooks/redux";
import { showError, showSuccess } from "redux/actions/ui";
import Page from "components/page/Page";
import { GetTaxNumbers, PutTaxNumber, DeleteTaxNumber } from "api/rpc/2024-04/facilityAdmin/facility/taxNumber";
import { StatusCode } from "api/protocols";

import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import Card from "components/card/Card";

import { useTranslation, Trans } from "react-i18next";
import Popup from "components/popup/Popup";
import { isEqualWith } from "lodash";

interface ITaxNumberState {
  id: number;
  title: string;
  number: string;
}

export default function TaxNumbersEdit(props: any) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const [taxLineState, setTaxLineState] = useState<ITaxNumberState>({
    id: undefined,
    title: "",
    number: "",
  });
  const [prevTaxLineState, setPrevTaxLineState] = useState<ITaxNumberState>(taxLineState);
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);

  const [taxNumberBeforeChanges, setTaxNumberBeforeChanges] = useState(undefined);
  const [taxNumberLoaded, setTaxNumberLoaded] = useState<boolean>(false);

  useEffect(() => {
    void loadTaxNumber();

    return () => {
      setTaxLineState({ id: undefined, title: "", number: "" });
      setPrevTaxLineState({ id: undefined, title: "", number: "" });
    };
  }, []);

  async function loadTaxNumber() {
    const res = await GetTaxNumbers({ id: Number(id) }, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading tax number")); // TODO: Translation
      return;
    }

    setTaxLineState(prev => ({
      ...prev,
      id: res.data[0].id,
      title: res.data[0].title,
      number: res.data[0].number,
    }));

    setPrevTaxLineState(prev => ({
      ...prev,
      id: res.data[0].id,
      title: res.data[0].title,
      number: res.data[0].number,
    }));

    setTaxNumberLoaded(true);
  }

  async function saveTaxNumber() {
    const res = await PutTaxNumber(taxLineState, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error updating tax number")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Tax number updated successfully")); // TODO: Translation
    history.push("/admin/settings/taxes");
  }

  async function removeTaxNumber() {
    const res = await DeleteTaxNumber(prevTaxLineState, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error deleting tax number")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Tax number deleted successfully")); // TODO: Translation
    history.push("/admin/settings/taxes");
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setTaxLineState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleDeleteModalVisibility() {
    setDeleteModalVisibility(!deleteModalVisibility);
  }

  const primaryAction = {
    content: "Update", // TODO: Translation
    action: saveTaxNumber,
  };

  const secondaryAction = {
    content: "Delete", // TODO: Translation
    action: handleDeleteModalVisibility,
  };

  function cancelUnsavedChanges() {
    setTaxLineState(taxNumberBeforeChanges);
  }

  function unsavedChangesExist() {
    if (taxNumberBeforeChanges === undefined) {
      if (taxNumberLoaded) {
        setTaxNumberBeforeChanges(taxLineState);
      }
      return false;
    }

    return !isEqualWith(taxNumberBeforeChanges, taxLineState);
  }

  return (
    <>
      <Page
        title={"Edit Tax Number"} // TODO: Translation
        narrow
        // primaryAction={primaryAction}
        secondaryActions={[secondaryAction]}
        breadcrumbs={[
          {
            prefix: true,
            label: "Taxes", // TODO: Translation
            url: "/admin/settings/taxes",
          },
        ]}
        notificationBarProps={{
          isVisible: unsavedChangesExist(),
          onAction: saveTaxNumber,
          onCancel: cancelUnsavedChanges,
        }}
      >
        <Card>
          <Card.Section>
            <Form>
              <FormLayout>
                <FormLayout.Group>
                  <Input
                    value={taxLineState.title}
                    label={"Title"} // TODO: Translation
                    id="title"
                    onChange={handleInputChange}
                  />
                  <Input
                    value={taxLineState.number}
                    label={"Number"} // TODO: Translation
                    id="number"
                    onChange={handleInputChange}
                  />
                </FormLayout.Group>
              </FormLayout>
            </Form>
          </Card.Section>
        </Card>
      </Page>

      <Popup
        open={deleteModalVisibility}
        type="warning"
        title={"Delete Tax Number?"} // TODO: Translation
        description={"Are you sure you want to delete this tax number? This cannot be undone"} // TODO: Translation
        onOk={removeTaxNumber}
        okText={"Delete"} // TODO: Translation
        onCancel={handleDeleteModalVisibility}
        backDropCancel={true}
      />
    </>
  );
}
