import { APIDelete, APIGet, APIPost, APIPut } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseDashboardUrl = "/" + apiVersion + "/" + adminType + "/client/admin/dashboard";

//Dashboard interfaces
interface IGetDashboard {
  id?: string | number;
}

interface IPostDashboard {
  user_id: number;
  title: string;
}

interface IPutDashboard {
  id: number;
  title?: string;
}

//Tile interfaces
interface IGetTile {
  id?: number;
}

interface IPostTile {
  dashboard_id: number;
  base_tile_id: number;
  title: string;
  settings?: string;
  display_options?: string;
}

interface IPutTile {
  id: number;
  title?: string;
  settings?: string;
  display_options?: string;
}

//Dashboard API Calls
export async function GetDashboard(params: IGetDashboard, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseDashboardUrl, params, useGlobalLoader, token);
}

export async function PostDashboard(params: IPostDashboard, useGlobalLoader: boolean) {
  return await APIPost(baseDashboardUrl, params, useGlobalLoader);
}

export async function PutDashboard(params: IPutDashboard, useGlobalLoader: boolean) {
  return await APIPut(baseDashboardUrl, params, useGlobalLoader);
}

export async function DeleteDashboard(params: IGetDashboard, useGlobalLoader: boolean) {
  return await APIDelete(baseDashboardUrl, params, useGlobalLoader);
}

export async function GetDashboardPopulate(params: IGetDashboard, useGlobalLoader: boolean) {
  return await APIGet(baseDashboardUrl + "/populated", params, useGlobalLoader);
}

//Tile API Calls
export async function GetTile(params: IGetTile, useGlobalLoader: boolean) {
  return await APIGet(baseDashboardUrl + "/tile", params, useGlobalLoader);
}

export async function PostTile(params: IPostTile, useGlobalLoader: boolean) {
  return await APIPost(baseDashboardUrl + "/tile", params, useGlobalLoader);
}

export async function PutTile(params: IPutTile, useGlobalLoader: boolean) {
  return await APIPut(baseDashboardUrl + "/tile", params, useGlobalLoader);
}

export async function DeleteTile(params: IGetTile, useGlobalLoader: boolean) {
  return await APIDelete(baseDashboardUrl + "/tile", params, useGlobalLoader);
}

export async function GetBaseTile(params: IGetTile, useGlobalLoader: boolean) {
  return await APIGet(baseDashboardUrl + "/base-tile", params, useGlobalLoader);
}
