import React from "react";
import { Helmet } from "react-helmet-async";

interface IProps {
  /**Unique Google Tag ID */
  tagID: string;
  /**Overwrites the default page title that is displayed in the browser's tab `(optional)`*/
  title?: string;
}
/**
 * Sets up the required Google Analytic scripts within the <head> tag
 * @param {IProps} props
 * @param {string} props.tagID Unique Google Tag ID
 * @param {string} props.title The page title that is displayed in the browser's tab `(optional)`
 * @returns
 */
export default function GoogleAnalytics(props: IProps) {
  const { tagID, title } = props;
  return tagID ? (
    <Helmet>
      <script>
        {`(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

        ga('create', '${tagID}', 'auto');
        ga('send', 'pageview');`}
      </script>
      {title && <title>{title}</title>}
    </Helmet>
  ) : null;
}
