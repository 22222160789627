import Sheet from "components/sheet/Sheet";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "components/form/checkbox/Checkbox";
import { Badge } from "components/badge/Badge";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import "./moveSeats.scss";
import classNames from "classnames";
import { GetFloorPlans, GetTable, IFloorPlan, ITable } from "api/rpc/facilityAdmin/tables/tables";
import { ICartActions, loadTables } from "redux/actions/cart";
import { StatusCode } from "api/protocols";
import { Select } from "components/select/index";
import { IUIActions, showError } from "redux/actions/ui";
import axios, { CancelToken } from "axios";
import Spin from "components/spin/spin";

export interface IMoveSeatsState {
  // Move Seats
  /** Id of the table the selected seats are being moved to */
  selectedTableId: number;
  /** Seats that are going to be moved to another table */
  selectedSeatIds: Array<number>;

  /** Floor Plan to display tables for */
  selectedFloorPlan: IFloorPlan;
  floorPlans: IFloorPlan[];
  tables: Array<ITable>;
  loadingTables: boolean;
}

interface IMoveSeatsProps {
  addToTableModalVisible: boolean;
  onCancel?: () => void;
  onOk?: (priceChangeInfo: IMoveSeatsState) => void;
}

function MoveSeats(props: IMoveSeatsProps) {
  const { t, i18n } = useTranslation();
  const { Option } = Select;
  const { cartStore } = useAppSelector(store => store);
  const dispatch = useAppDispatch();
  const [state, setState] = useState<IMoveSeatsState>({
    selectedTableId: undefined,
    selectedSeatIds: [],
    selectedFloorPlan: undefined,
    floorPlans: [],
    tables: cartStore?.tables ? [...cartStore?.tables] : [],
    loadingTables: false,
  });

  useEffect(() => {
    void loadFloorPlans();
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (state.selectedFloorPlan?.id) {
      void loadTables(source.token);
      setState(prevState => ({
        ...prevState,
        selectedTableId: undefined,
      }));
    }
    return () => {
      source.cancel();
    };
  }, [state.selectedFloorPlan?.id]);

  async function loadTables(cancelToken: CancelToken) {
    setState(prevState => ({ ...prevState, loadingTables: true }));
    const tablesRes = await GetTable({ floor_plan_id: state?.selectedFloorPlan?.id }, false, cancelToken);
    if (tablesRes?.status !== StatusCode.OK) {
      if (cancelToken?.reason) {
        return;
      }
      setState(prevState => ({ ...prevState, loadingTables: false }));
      dispatch(showError("Error getting tables"));
      return;
    }
    setState(prevState => ({ ...prevState, tables: tablesRes?.data, loadingTables: false }));
  }

  async function loadFloorPlans() {
    const register = JSON.parse(localStorage.getItem("register"));
    const getFloorPlansResponse = await GetFloorPlans(null, false);
    if (getFloorPlansResponse.status !== StatusCode.OK) {
      dispatch(showError("Error getting floor plans"));
      return;
    }

    const default_floor_plan_id = register?.default_floor_plan_id;
    const defaultFloorPlan = getFloorPlansResponse.data?.find(
      floorPlan =>
        floorPlan.id === default_floor_plan_id || floorPlan?.register_group_id === register?.register_group_id,
    );

    setState(prevState => ({
      ...prevState,
      floorPlans: getFloorPlansResponse.data,
      selectedFloorPlan: defaultFloorPlan ?? getFloorPlansResponse.data?.[0],
    }));
  }

  function onOk() {
    void props.onOk({ ...state });
    setState(prevState => ({ ...prevState, selectedSeatIds: [], selectedTableId: undefined }));
  }

  function onCancel() {
    setState(prevState => ({ ...prevState, selectedSeatIds: [], selectedTableId: undefined }));
    props.onCancel();
  }

  function handleMoveSeatsCheckboxChange(cartId: number) {
    const updatedSeatIds = [...state.selectedSeatIds];
    const foundIndex = updatedSeatIds?.indexOf(cartId);

    if (foundIndex === -1) {
      updatedSeatIds.push(cartId);
    } else {
      updatedSeatIds.splice(foundIndex, 1);
    }
    setState(prevState => ({ ...prevState, selectedSeatIds: updatedSeatIds }));
  }

  function handleTablesCheckboxChange(id: number) {
    setState(prevState => ({ ...prevState, selectedTableId: id }));
  }

  function handleSelectFloorPlan(id: number) {
    const selectedFloorPlan = state.floorPlans.find(floorPlan => floorPlan.id === id);

    setState(prevState => ({ ...prevState, selectedFloorPlan }));
  }

  return (
    <Sheet
      title={t("components.move_seats.move_seats.001")}
      open={props.addToTableModalVisible}
      size="medium"
      closable
      onCancel={onCancel}
      onOk={onOk}
      okText={t("components.move_seats.move_seats.002")}
      okDisabled={state.selectedTableId == null || state.selectedSeatIds?.length === 0 ? true : false}
    >
      <div className="move-seats">
        <div className="flex-1">
          <span className="move-seats_seats_title">{t("components.move_seats.move_seats.003")}</span>
          <div className="move-seats_seats_container">
            {cartStore.activeTable?.carts.map((cart: Record<string, any>, cartIndex: number) => {
              if (cart.status !== "complete") {
                return (
                  <div
                    key={cartIndex}
                    onClick={() => handleMoveSeatsCheckboxChange(cart?.id)}
                    className="move-seats_seat_container"
                  >
                    <div className="flex items-center pointer-events-none">
                      <div style={{ marginTop: "5px" }}>
                        <Checkbox
                          onChange={() => handleMoveSeatsCheckboxChange(cart?.id)}
                          size="small"
                          checked={state.selectedSeatIds.includes(cart?.id)}
                        />
                      </div>

                      <span className="ml-2 move-seats_seat_name">
                        {t("components.move_seats.move_seats.007")}
                        {cartIndex + 1}
                      </span>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      {cart?.description && (
                        <Badge type="gray" size="small" iconLeft icon="circle" iconStyle="far">
                          {cart?.description}
                        </Badge>
                      )}

                      {cart?.customer && (
                        <Badge type="gray" size="small" iconLeft icon="circle" iconStyle="far">
                          {cart?.customer?.full_name}
                        </Badge>
                      )}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
        <div className="flex-1">
          <div className="flex justify-between items-center">
            <div>
              <span className="move-seats_tables_title">{t("components.move_seats.move_seats.004")}</span>
            </div>

            <div>
              <Select className="w-45" onChange={handleSelectFloorPlan} defaultValue={state.selectedFloorPlan?.id}>
                {state.floorPlans?.map((floorPlan, index) => {
                  return (
                    <Option key={index} value={floorPlan.id}>
                      {floorPlan.title}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
          <div className="move-seats_tables_container">
            {state?.loadingTables ? (
              <div style={{ display: "flex", height: "100%", margin: "auto" }}>
                <div className="text-center m-auto">
                  <div style={{ height: "32px" }}>
                    <Spin />
                  </div>
                  <div>Loading tables...</div>
                </div>
              </div>
            ) : (
              state?.tables
                ?.filter((filteredTable: Record<string, any>) => filteredTable?.id !== cartStore?.activeTable?.id)
                ?.map((table: Record<string, any>, index: number) => {
                  return (
                    <div
                      key={index}
                      onClick={() => handleTablesCheckboxChange(table?.id)}
                      className={classNames("move-seats_table_container", {
                        "move-seats_table_container_selected": state.selectedTableId === table?.id ? true : false,
                      })}
                    >
                      <div>
                        <p className="move-seats_table_name">{table?.title}</p>
                      </div>

                      <div className="flex gap-2">
                        <Badge type="gray" size="small">
                          {table?.open
                            ? t("components.move_seats.move_seats.005")
                            : t("components.move_seats.move_seats.006")}
                        </Badge>

                        <div style={{ marginTop: "5px" }}>
                          <Checkbox
                            size="small"
                            checked={state.selectedTableId === table?.id ? true : false}
                            onChange={() => handleTablesCheckboxChange(table?.id)}
                            labelHidden
                          />
                        </div>
                      </div>
                    </div>
                  );
                })
            )}
          </div>
        </div>
      </div>
    </Sheet>
  );
}

export default MoveSeats;
