import { useDropEditInputRow } from "hooks/editInputDragAndDrop/useDropEditInputRow/useDropEditInputRow";
import React, { useEffect, useRef } from "react";
import "./tournamentRegistrationEditInputRow.scss";
import TournamentRegistrationEditInput from "./TournamentRegistrationEditInput";
import { useTournamentRegistrationContext } from "../TournamentRegistrationContext";
import { ITournamentRegistrationInput } from "api/rpc/facilityAdmin/tournament/tournamentRegistrationInput";

interface IProps {
  inputRow: string;
  inputs: ITournamentRegistrationInput[];
}

export default function TournamentRegistrationEditInputRow(props: IProps) {
  const { inputRow, inputs } = props;

  const tournamentRegistrationContext = useTournamentRegistrationContext();

  const rowRef = useRef<HTMLDivElement>(null);

  const inputContainerRefs = useRef<HTMLDivElement[]>([]);

  const { drop, canDrop, isOver, customDragLayer } = useDropEditInputRow(
    inputs,
    rowRef,
    tournamentRegistrationContext.inputRowsContainer.updatedState,
    inputRow,
    inputContainerRefs,
    tournamentRegistrationContext.inputRowsContainerActions.update,
    [
      { types: ["input", "select"], maxSize: 3 },
      { types: ["checkbox"], maxSize: 3 },
      { types: ["text"], maxSize: 1 },
    ],
  );

  useEffect(() => {
    if (inputs) {
      const numInputs = inputs.length;

      inputContainerRefs.current = inputContainerRefs.current.slice(0, numInputs);
    }
  }, [inputs]);

  return (
    <div
      ref={el => {
        drop(el);
        rowRef.current = el;
      }}
      className="tournament-registration-edit-input-row"
      style={{
        outline: isOver && !canDrop ? "1px solid red" : "none",
      }}
    >
      {inputs.map((input, index) => {
        return (
          <div
            ref={el => (inputContainerRefs.current[index] = el)}
            key={String(input.id)}
            className="tournament-registration-edit-input-container"
          >
            <TournamentRegistrationEditInput input={input} />
          </div>
        );
      })}

      {customDragLayer}
    </div>
  );
}
