import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetFacilityStatus, GetFacilityStatusInputs } from "api/rpc/2024-04/facilityAdmin/facility/status";

import { showError } from "redux/actions/ui";
import { TFacilityStatusInput } from "redux/reducers/models/facility";
import { arrayAsColumnTemplate, arrayAsGrid, formatDate } from "helpers/Helpers";
import { useAppDispatch } from "hooks/redux";

import DatePickerInput from "components/datePickerInput/DatePickerInput";
import Input from "components/form/input/Input";
import SelectNew from "components/select/SelectNew";
import Sheet from "components/sheet/Sheet";
import Spin from "components/spin/spin";
import Portal from "elements/Portal";
import Checkbox from "components/form/checkbox/Checkbox";

export interface IFacilityStatusState {
  date: Date;
  inputData: Array<{ status_input_id: number; value: string }>;
}

type TFaciltyStatus = {
  state: { isOpen: boolean } & IFacilityStatusState;
  update: (newState: IFacilityStatusState) => void;
  close: () => void;
  onOk: () => void;
};

export default function StatusModal(props: TFaciltyStatus) {
  const { state, update, close, onOk } = props;
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [statusInputs, setStatusInputs] = useState<TFacilityStatusInput[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (state.isOpen) {
      void loadFacilityStatus(source.token);
    }

    if (state.isOpen && statusInputs === undefined) {
      void loadStatusInputs(source.token);
    }
    return () => source.cancel();
  }, [state.isOpen, state.date]);

  const selectStatusInputs = useMemo(() => {
    if (!statusInputs) {
      return [];
    }
    return statusInputs.filter(val => val.type === "select");
  }, [statusInputs]);

  const inputStatusInputs = useMemo(() => {
    if (!statusInputs) {
      return [];
    }
    return statusInputs.filter(val => val.type === "input");
  }, [statusInputs]);

  const checkboxStatusInputs = useMemo(() => {
    if (!statusInputs) {
      return [];
    }
    return statusInputs.filter(val => val.type === "checkbox");
  }, [statusInputs]);

  async function loadFacilityStatus(token?: CancelToken) {
    if (state.inputData !== undefined) {
      update({ ...state, inputData: undefined });
    }
    const res = await GetFacilityStatus({ date: formatDate(state.date) }, token ? false : true, token);

    console.log("facility status ", res);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error getting facility status inputs.")); // TODO: Translation
      update({ ...state, inputData: [] });
    }

    const usableStructure = res.data.map(val => ({ status_input_id: val.status_input_id, value: val.value }));
    // setFacilityStatus(res.data.length > 0 ? usableStructure : []);
    console.log("updated state ... ", usableStructure);

    update({ ...state, inputData: usableStructure });
  }

  async function loadStatusInputs(token?: CancelToken) {
    const res = await GetFacilityStatusInputs(null, token ? false : true, token);

    console.log("facility status inputs ", res);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error getting facility status inputs.")); // TODO: Translation
    }

    setStatusInputs(res.data);
  }

  const changeModalDate = (selectedDate: Date) => update({ ...state, date: selectedDate });

  const updateFacilityStatus = (statusId: number, selected: string) => {
    if (state.inputData.find(val => val.status_input_id === statusId)) {
      // update prev status input
      update({
        ...state,
        inputData: state.inputData.map(val => {
          if (val.status_input_id === statusId) {
            return {
              ...val,
              value: selected,
            };
          } else {
            return val;
          }
        }),
      });
    } else {
      // add new status input
      update({ ...state, inputData: [...state.inputData, { status_input_id: statusId, value: selected }] });
    }
  };

  return (
    <Portal isMounted={state.isOpen}>
      <Sheet
        open={state.isOpen}
        size="medium"
        closable
        onCancel={() => close()}
        onOk={onOk}
        title={"Daily Status"} // TODO: Translation
        okText={"Save"} // TODO: Translation
        cancelText={t("elements.tee_sheet.statistic_bar.007")}
      >
        <DatePickerInput months={1} position="left" startingDate={state.date} setStartingDate={changeModalDate} />
        {state.inputData === undefined ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
            <div style={{ height: "50px" }}>
              <Spin />
            </div>
          </div>
        ) : (
          <>
            {arrayAsGrid(selectStatusInputs, selectStatusInputs.length < 3 ? 1 : 3).map((row, rowIndex) => (
              <div
                key={rowIndex}
                style={{
                  display: "grid",
                  gridTemplateColumns: arrayAsColumnTemplate(selectStatusInputs.length < 3 ? 1 : 3),
                  columnGap: "16px",
                }}
              >
                {row.map(selectInput => {
                  const facilityInputStatus = state.inputData.find(val => val.status_input_id === selectInput.id);
                  return (
                    <SelectNew
                      key={selectInput.id}
                      id={`select_${selectInput.id}`}
                      value={facilityInputStatus?.value}
                      onChange={val => updateFacilityStatus(selectInput.id, val)}
                      options={selectInput.values ? selectInput.values.map(val => ({ id: val, label: val })) : []}
                      label={selectInput.label}
                      helpText={selectInput.help_text}
                      className="pt-4"
                    />
                  );
                })}
              </div>
            ))}

            {arrayAsGrid(inputStatusInputs, inputStatusInputs.length < 2 ? 1 : 2).map((row, rowIndex) => (
              <div
                key={rowIndex}
                className="pt-4"
                style={{
                  display: "grid",
                  gridTemplateColumns: arrayAsColumnTemplate(inputStatusInputs.length < 2 ? 1 : 2),
                  columnGap: "16px",
                }}
              >
                {row.map(inputInput => {
                  const facilityInputStatus = state.inputData.find(val => val.status_input_id === inputInput.id);
                  return (
                    <Input
                      key={inputInput.id}
                      value={facilityInputStatus?.value ?? ""}
                      onChange={e => updateFacilityStatus(inputInput.id, e.target.value)}
                      label={inputInput.label}
                      helpText={inputInput.help_text}
                    />
                  );
                })}
              </div>
            ))}

            {arrayAsGrid(checkboxStatusInputs, checkboxStatusInputs.length < 4 ? checkboxStatusInputs.length : 4).map(
              (row, rowIndex) => (
                <div
                  key={rowIndex}
                  className="pt-4"
                  style={{
                    display: "grid",
                    gridTemplateColumns: arrayAsColumnTemplate(
                      checkboxStatusInputs.length < 4 ? checkboxStatusInputs.length : 4,
                    ),
                    columnGap: "16px",
                  }}
                >
                  {row.map(checkboxInput => {
                    const facilityInputStatus = state.inputData.find(val => val.status_input_id === checkboxInput.id);
                    return (
                      <Checkbox
                        key={checkboxInput.id}
                        value={facilityInputStatus?.value ?? "false"}
                        checked={facilityInputStatus?.value === "true"}
                        size="large"
                        onChange={e => updateFacilityStatus(checkboxInput.id, e.target.checked ? "true" : "false")}
                        label={checkboxInput.label}
                        helpText={checkboxInput.help_text}
                      />
                    );
                  })}
                </div>
              ),
            )}
          </>
        )}
      </Sheet>
    </Portal>
  );
}
