import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "components/bookingPopUp/golferCard/GolferCard.scss";

import Icon from "components/icon/Icon";

function GolferCard(props: any) {
  return (
    <div className="golfer-card" style={{ alignItems: props?.bookingEngineCard ? "center" : "" }}>
      <div className="golfer-card-details">
        <p className="golfer-card-name">{props.name}</p>
        <div className="golfer-card-details-info">
          <p className="golfer-card-email">{props.customerType}</p>
          <p className="golfer-card-email">{props.email}</p>
          <p className="golfer-card-phone">{props.phone ? props.phone : null}</p>
        </div>
      </div>

      <div className="golfer-card-id-close-container">
        <div className="customerId">
          <span className="golfer-card-customer-id">{props.memberCode}</span>
        </div>
        {props?.closable && (
          <div className="close-button-container">
            <button className="golfer-card-close-button" onClick={props.removeGolfer}>
              <Icon style="far" icon="times" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default GolferCard;
