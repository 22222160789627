import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Markdown from "components/markdown/Markdown";
import Card from "components/card/Card";
import { Badge } from "components/badge/Badge";
import { capitalize } from "helpers/Helpers";
import Callout from "components/callout/Callout";
import { StatusCode } from "api/protocols";
import { useGuestPortalContext } from "./GuestPortalContext";
import "./portalTickets.scss";
import { ButtonNew as Button } from "components/buttonNew";
import Spin from "components/spin/spin";
import { useAppDispatch } from "hooks/redux";
import { showError } from "redux/actions/ui";
import Sheet from "components/sheet/Sheet";
import { TicketStubType } from "pages/secure/facility/customer/context/contextTypes";
import { ITicket } from "redux/reducers/models/ticket";
import { QRCodeSVG } from "qrcode.react";
import classNames from "classnames";
import { GetCustomerTickets, PrintCustomerTicket } from "api/rpc/2024-04/customer/ticket";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { MOBILE_WIDTH } from "helpers/ScreenSizes";
import Panel from "components/panel/Panel";

interface ITicketExtended extends ITicket {
  selected: boolean;
}

interface ITicketStubExtended extends TicketStubType {
  ticket: ITicketExtended;
}

interface ITicketsState {
  tickets: Array<ITicketExtended>;
  ticketStubs: Array<TicketStubType>;
}

type PortalTicketsFilters = {
  limit: number;
  start: number;
  end: number;
};

interface IStubState {
  modalOpen: boolean;
  selectedStub: ITicketStubExtended;
}

export default function PortalTickets() {
  const dispatch = useAppDispatch();
  const [ticketsState, setTicketsState] = useState<ITicketsState>({
    tickets: null,
    ticketStubs: [],
  });

  const [stubState, setStubState] = useState<IStubState>({
    modalOpen: false,
    selectedStub: null,
  });

  const { portalState } = useGuestPortalContext();

  const [filter, setFilter] = useState<PortalTicketsFilters>({
    limit: 5,
    start: 0,
    end: 5,
  });

  const chevronUp: IconProp = ["far", "chevron-up"];
  const chevronDown: IconProp = ["far", "chevron-down"];
  const windowSize = useWindowSize();

  useEffect(() => {
    void loadTickets();
  }, []);

  async function loadTickets() {
    const ticketsRes = await GetCustomerTickets(null, false);

    if (ticketsRes.status !== StatusCode.OK) {
      dispatch(showError("Error getting tickets"));
      setTicketsState(prevState => ({ ...prevState, tickets: [] }));
      return;
    }

    const tickets = ticketsRes.data;

    {
      tickets?.map((ticket: any) => {
        ticket.stubs?.map((stub: any, stubIndex: number) => {
          if (stub.status === "valid") {
            const foundStub = ticket.stubs[stubIndex];
            ticket.stubs.splice(stubIndex, 1);
            ticket.stubs.unshift(foundStub);
          }
        });

        for (let i = 0; i < ticketsState.tickets?.length; i++) {
          if (ticket.id === ticketsState.tickets[i].id) {
            if (ticketsState.tickets[i].selected) {
              ticket.selected = true;
            } else {
              ticket.selected = false;
            }
          }
        }
      });
    }

    for (let i = 0; i < tickets?.length; i++) {
      tickets[i].stubsToView = tickets[i].stubs?.slice(filter.start, filter.end);
    }

    setTicketsState(prevState => ({
      ...prevState,
      tickets: tickets,
      ticketStubs: tickets.stubs,
    }));
  }

  function handleOpenTicket(ticket: any) {
    const tickets = ticketsState.tickets;

    {
      tickets
        ?.filter((filteredTicket: any) => filteredTicket.id === ticket.id)
        .map((ticket: any) => {
          ticket.selected = !ticket.selected;
        });
    }

    setTicketsState(prevState => ({ ...prevState, tickets: tickets }));
  }

  function handleNextPage(ticket: any) {
    const start = filter.start + filter.limit;
    const end = filter.end + filter.limit;

    const stubsToView = ticket.stubs.slice(start, end);
    setFilter(prev => ({ ...prev, start: start, end: end }));

    const tickets = ticketsState.tickets;
    {
      tickets
        ?.filter((filteredTicket: any) => filteredTicket.id === ticket.id)
        .map((ticket: any) => {
          ticket.stubsToView = stubsToView;
        });
    }

    setTicketsState(prevState => ({ ...prevState, tickets: tickets }));
  }

  function handlePrevPage(ticket: any) {
    const start = filter.start - filter.limit;
    const end = filter.end - filter.limit;

    const stubsToView = ticket.stubs.slice(start, end);
    setFilter(prev => ({ ...prev, start: start, end: end }));

    const tickets = ticketsState.tickets;
    {
      tickets
        ?.filter((filteredTicket: any) => filteredTicket.id === ticket.id)
        .map((ticket: any) => {
          ticket.stubsToView = stubsToView;
        });
    }

    setTicketsState(prevState => ({ ...prevState, tickets: tickets }));
  }

  async function handlePrintTicketStub() {
    const ticketRes = await PrintCustomerTicket(
      { ticket_id: stubState?.selectedStub?.ticket_id, stub_id: stubState?.selectedStub?.id },
      true,
    );
    if (ticketRes?.status !== StatusCode.OK) {
      dispatch(showError("Error printing ticket"));
      return;
    }
    window.open(ticketRes.data);
  }

  return (
    <>
      <div>
        <h1 className="ui-customer_page-header mb-4 mt-4">{"Tickets"}</h1>
        {ticketsState?.tickets ? (
          ticketsState.tickets?.length > 0 ? (
            <div>
              <div>
                {ticketsState.tickets?.map((ticket: any, index: number) => {
                  let activeCount = 0;

                  {
                    ticket.stubs
                      ?.filter((filteredStub: any) => filteredStub.status === "valid")
                      .map((ticket: any) => {
                        activeCount = activeCount + 1;
                      });
                  }

                  return (
                    <div key={index} className="customer-portal-accordian-card-container">
                      <div
                        className="customer-ticket-header-container"
                        onClick={() => handleOpenTicket(ticket)}
                        style={{ marginBottom: "0px" }}
                      >
                        <div className="customer-ticket-title-container">
                          <div>
                            {!ticket.logo_image ? (
                              <FontAwesomeIcon className="customer-ticket-logo" icon={"ticket"} />
                            ) : (
                              <img className="customer-ticket-logo" src={ticket.logo_image} />
                            )}
                          </div>
                          <div>
                            <p className="customer-ticket-title">{ticket.title} Tickets</p>

                            <p className="customer-ticket-subtitle">{ticket.subtitle ?? ""}</p>
                            <p className="customer-portal-ticket-info-text">
                              {" "}
                              {activeCount} / {ticket.stubs.length}
                            </p>
                          </div>
                        </div>

                        <div className="customer-portal-customer-ticket-right-content-container">
                          <div className="customer-ticket-info">
                            {/* <p className="ticket-info-text">{"Expiry Date"}</p> */}
                            {/* <p className="ticket-info-date">{moment(ticket?.expiry_date).format("MMMM Do, YYYY")}</p> */}
                          </div>
                          <div>
                            <FontAwesomeIcon
                              className="chevron-icon"
                              icon={ticket.selected ? chevronUp : chevronDown}
                            />
                          </div>
                        </div>
                      </div>

                      {ticket.selected ? (
                        <div className="customer-portal-ticket-container">
                          <div className="ticket-description-container">
                            <Markdown markdownText={ticket.description} />
                          </div>

                          {ticket.stubsToView.map((stub: any, i: number) => {
                            let badgeStatus: "primary" | "success" | "warning" | "error" | "gray";

                            let isDateExpired = false;

                            if (stub.expiry_date) {
                              const dateArray = stub.expiry_date.split("-");
                              const date = new Date(dateArray[0], dateArray[1], dateArray[2]);
                              isDateExpired = new Date(date.toDateString()) < new Date(new Date().toDateString());
                            }

                            switch (stub.status.toLowerCase()) {
                              case "void":
                                badgeStatus = "error";
                                break;
                              case "redeemed":
                                badgeStatus = "gray";
                                break;
                              case "valid":
                                badgeStatus = "success";

                                break;

                              default:
                                break;
                            }
                            return (
                              <div
                                className={
                                  stub.status === "valid"
                                    ? "customer-portal-ticket-stub-container-valid"
                                    : "customer-portal-ticket-stub-container-non-valid"
                                }
                                key={i}
                                onClick={
                                  stub.status === "valid"
                                    ? () =>
                                        setStubState(prevState => ({
                                          ...prevState,
                                          modalOpen: true,
                                          selectedStub: { ...stub, ticket: ticket },
                                        }))
                                    : undefined
                                }
                              >
                                <div>
                                  <p
                                    className={
                                      stub.status === "valid"
                                        ? "customer-portal-ticket-stub-title-valid"
                                        : "customer-portal-ticket-stub-title-non-valid"
                                    }
                                  >
                                    {ticket.title}
                                  </p>
                                  <p
                                    className={
                                      stub.status === "valid"
                                        ? "customer-portal-stub-number-text-valid customer-portal-stub-code"
                                        : "customer-portal-stub-number-text-non-valid customer-portal-stub-code"
                                    }
                                  >
                                    {stub.code}
                                  </p>
                                  <p
                                    className={classNames("customer-portal-stub-number-text-valid", {
                                      "customer-portal-stub-number-text-non-valid": stub.status !== "valid",
                                    })}
                                  >
                                    Pin: {stub.pin}
                                  </p>

                                  <p
                                    className={
                                      stub.status === "valid"
                                        ? "customer-portal-expires-on-text-valid"
                                        : "customer-portal-expires-on-text-non-valid"
                                    }
                                  >
                                    {stub.redeemed_at
                                      ? "Redeemed on "
                                      : isDateExpired
                                      ? "Expired on "
                                      : stub.expiry_date
                                      ? "Expires on "
                                      : ""}

                                    {stub.redeemed_at
                                      ? moment(stub.redeemed_at).format("MMMM Do, YYYY")
                                      : stub.expiry_date
                                      ? moment(stub.expiry_date).format("MMMM Do, YYYY")
                                      : ""}
                                  </p>
                                </div>

                                <div>
                                  <Badge
                                    type={badgeStatus}
                                    size="medium"
                                    iconLeft={
                                      stub.status === "valid" || stub.status === "void" ? (
                                        <FontAwesomeIcon
                                          className="customer-ticket-badge-icon"
                                          icon={["fas", "circle"]}
                                        />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  >
                                    {capitalize(stub.status)}
                                  </Badge>
                                </div>
                              </div>
                            );
                          })}

                          <div className="customer-portal-tickets-footer">
                            <div>
                              <Button
                                size="small"
                                type="secondary"
                                onClick={() => handlePrevPage(ticket)}
                                disabled={!ticket.stubsToView || !(filter.limit <= filter.start)}
                              >
                                <FontAwesomeIcon icon={"arrow-left"} />
                                &nbsp;
                                {"Prev"}
                              </Button>
                            </div>
                            <div>
                              <Button
                                size="small"
                                type="secondary"
                                onClick={() => handleNextPage(ticket)}
                                disabled={!(ticket.stubsToView?.length === filter.limit)}
                              >
                                {"Next"}
                                &nbsp;
                                <FontAwesomeIcon icon={"arrow-right"} />
                              </Button>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div>
              <Callout title={"No Tickets Found"} content={"You do not have any tickets"} type="info" />
            </div>
          )
        ) : (
          <div className="portal-spinner">
            <span>
              <Spin />
            </span>
          </div>
        )}
      </div>

      <Panel
        open={stubState?.modalOpen && windowSize.width < MOBILE_WIDTH}
        onClose={() => setStubState(prevState => ({ ...prevState, modalOpen: false, selectedStub: null }))}
        title={stubState?.selectedStub?.ticket?.title}
        animate
        primaryAction={{
          onClick: handlePrintTicketStub,
          label: "Download",
        }}
        secondaryAction={{
          onClick: () => setStubState(prevState => ({ ...prevState, modalOpen: false, selectedStub: null })),
          label: "Close",
        }}
      >
        <div className="customer-portal-ticket-stub-info">
          <p className="ticket-stub-code">{stubState?.selectedStub?.code}</p>
          <div className="expire-date-row">
            <p
              className={classNames("ticket-stub-expiry-date", {
                "hide-expiry-badge": !stubState?.selectedStub?.expiry_date,
              })}
            >
              Expires on {moment(stubState?.selectedStub?.expiry_date).format("LL")}
            </p>
            <Badge
              type="success"
              size="medium"
              iconLeft={<FontAwesomeIcon className="customer-ticket-badge-icon" icon={["fas", "circle"]} />}
            >
              {capitalize(stubState?.selectedStub?.status)}
            </Badge>
          </div>
        </div>
        <div className="customer-portal-ticket-stub-code-container">
          <QRCodeSVG value={stubState.selectedStub?.code} />
          <p className="ticket-stub-pin">Pin: {stubState?.selectedStub?.pin}</p>
        </div>
      </Panel>

      <Sheet
        title={stubState?.selectedStub?.ticket?.title}
        open={stubState?.modalOpen && windowSize.width > MOBILE_WIDTH}
        size="small"
        closable
        onCancel={() => setStubState(prevState => ({ ...prevState, modalOpen: false, selectedStub: null }))}
        cancelText={"Close"}
        onOk={handlePrintTicketStub}
        okText="Download"
      >
        <div className="customer-portal-ticket-stub-info">
          <p className="ticket-stub-code">{stubState?.selectedStub?.code}</p>
          <div className="expire-date-row">
            <p
              className={classNames("ticket-stub-expiry-date", {
                "hide-expiry-badge": !stubState?.selectedStub?.expiry_date,
              })}
            >
              Expires on {moment(stubState?.selectedStub?.expiry_date).format("LL")}
            </p>
            <Badge
              type="success"
              size="medium"
              iconLeft={<FontAwesomeIcon className="customer-ticket-badge-icon" icon={["fas", "circle"]} />}
            >
              {capitalize(stubState?.selectedStub?.status)}
            </Badge>
          </div>
        </div>
        <div className="customer-portal-ticket-stub-code-container">
          <QRCodeSVG value={stubState.selectedStub?.code} />
          <p className="ticket-stub-pin">Pin: {stubState?.selectedStub?.pin}</p>
        </div>
      </Sheet>
    </>
  );
}
