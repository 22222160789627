import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { StatusCode } from "api/protocols";
import Page from "components/page/Page";
import Card from "components/card/Card";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "hooks/redux";
import { showError, showSuccess } from "redux/actions/ui";
import { PostLeagueTeam, TPostLeagueTeams } from "api/rpc/2024-04/facilityAdmin/league/scoring/teams";

interface IParams {
  leagueId: string;
  divisionId: string;
}

export default function LeagueTeamNew() {
  const history = useHistory();
  const { t } = useTranslation();
  const { leagueId, divisionId } = useParams<IParams>();

  const dispatch = useAppDispatch();

  const [teamState, setTeamState] = useState<TPostLeagueTeams>({
    league_id: leagueId,
    league_division_id: divisionId,
    name: "",
  });

  async function createTeam() {
    const leagueRes = await PostLeagueTeam(teamState, true);
    if (leagueRes.status !== StatusCode.OK) {
      dispatch(showError("Error creating new team")); // TODO: Translation
      return;
    }
    dispatch(showSuccess("Successfully created new team")); // TODO: Translation
    history.push(`/admin/league/${leagueId}/divisions/${divisionId}/teams`);
  }

  const primaryAction = {
    content: t("secure.facility.league.new.008"),
    action: () => createTeam(),
    disabled: teamState.name.length === 0,
  };

  return (
    <Page
      title={"New Team"} // TODO: Translation
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: "Teams", // TODO: Translation
          url: `/admin/league/${leagueId}/divisions/${divisionId}/teams`,
        },
      ]}
    >
      <Form>
        {/* TODO: Translation */}
        <Card title={"Team Details"}>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={teamState.name ?? ""}
                  label={t("secure.facility.league.new.012")}
                  id="name"
                  onChange={e => setTeamState(prev => ({ ...prev, name: e.target.value }))}
                  placeholder={t("secure.facility.league.new.012")}
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>
      </Form>
    </Page>
  );
}
