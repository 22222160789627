import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { displayCurrency, uppercase } from "helpers/Helpers";
import axios, { CancelToken } from "axios";
import moment from "moment";

import Card from "components/card/Card";
import Page from "components/page/Page";
import { StatusCode } from "api/protocols";
import { useTranslation } from "react-i18next";
import Sheet from "components/sheet/Sheet";
import Tabs from "components/tabs/Tabs";
import { GetTournament } from "api/rpc/2024-04/facilityAdmin/tournament/tournament";
import { GetLeague } from "api/rpc/2024-04/facilityAdmin/league/league";
import { Select } from "components/select/index";
import { ITournament } from "redux/reducers/models/tournament";
import { ILeague } from "redux/reducers/models/league";
import { useAppDispatch } from "hooks/redux";
import { showError } from "redux/actions/ui";
import Icon from "components/icon/Icon";
import {
  DeleteCreditBookVariant,
  GetCreditBook,
  PutCreditBook,
  PutCreditBookVariant,
} from "api/rpc/2022-09/facilityAdmin/client/creditBook";
import { isEqualWith } from "lodash";
import { GetVariant } from "api/rpc/2022-09/facilityAdmin/product/product";
import Popup from "components/popup/Popup";
import Input from "components/form/input/Input";
import ReactDOM from "react-dom";

interface IFilterState {
  tournamentSearch: string;
  leagueSearch: string;
  newVariantVisible: boolean;
  tournaments: Array<ITournament>;
  selectedTournament: ITournament;
  tournamentSearching: boolean;
  leagues: Array<ILeague>;
  leagueSearching: boolean;
  selectedLeague: ILeague;
  variantQuery: string;
  variantSearching: boolean;
}

export interface IPrizeAccount {
  id: number;
  balance?: number;
  client_id?: number;
  currency?: string;
  /** Response does NOT contain type="prize_account" objects */
  customer_id?: number | null;
  facility_id?: number | null;
  league_id?: string | number;
  tournament_id?: number | null;
  type?: "prize_account" | "credit_book";
  title: string;
}

interface IVariantState {
  productVariants: any[];
  selectedVariant: any;
  prizeAccountVariants: any[];
  variantToDelete: any;
  deleteVariantVisible: boolean;
}

export default function PrizeAccount() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [selected, setSelected] = useState(0);
  const { Option } = Select;
  const dispatch = useAppDispatch();
  const { id, leagueId, tournamentId }: any = useParams();

  const [leagues, setLeagues] = useState<Array<ILeague>>([]);
  const [tournaments, setTournaments] = useState<Array<ITournament>>([]);
  const [prizeAccountBeforeChanges, setPrizeAccountBeforeChanges] = useState<IPrizeAccount>(undefined);
  const [prizeAccount, setPrizeAccount] = useState<IPrizeAccount>(undefined);

  const [filterState, setFilterState] = useState<IFilterState>({
    tournamentSearch: "",
    leagueSearch: "",
    newVariantVisible: false,
    tournaments: [],
    selectedTournament: null,
    tournamentSearching: false,
    leagues: [],
    leagueSearching: false,
    selectedLeague: null,
    variantQuery: "",
    variantSearching: false,
  });

  const [variantState, setVariantState] = useState<IVariantState>({
    productVariants: [],
    selectedVariant: null,
    prizeAccountVariants: [],
    variantToDelete: null,
    deleteVariantVisible: false,
  });

  useEffect(() => {
    void getPrizeAccount();
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();

    void loadVariants(source.token);

    return () => source.cancel();
  }, [filterState.variantQuery]);

  async function getPrizeAccount() {
    const getPrizeAccountsRes = await GetCreditBook({ id: id, extended: true }, true);

    let prizeAccount: any;

    if (getPrizeAccountsRes.status === StatusCode.OK) {
      prizeAccount = getPrizeAccountsRes.data[0];
    } else {
      dispatch(showError(getPrizeAccountsRes.message));
      return;
    }

    const tournamentRes = await GetTournament(
      { id: prizeAccount.tournament_id ? prizeAccount.tournament_id : undefined },
      true,
    );

    if (tournamentRes.status === StatusCode.OK) {
      setTournaments(tournamentRes.data);
    } else {
      dispatch(showError(tournamentRes.message));
    }

    const leagueRes = await GetLeague({ id: prizeAccount.league_id ? prizeAccount.league_id : undefined }, true);
    if (leagueRes.status === StatusCode.OK) {
      setLeagues(leagueRes.data);
    } else {
      dispatch(showError(leagueRes.message));
    }

    const leagues = leagueRes.data;
    const tournaments = tournamentRes.data;

    const tournament = tournaments.filter(tournament => tournament.id === prizeAccount.tournament_id);
    const league = leagues.filter(league => league.id === prizeAccount.league_id);

    if (league.length > 0) {
      setSelected(1);
    } else {
      setSelected(0);
    }

    setPrizeAccount({
      id: prizeAccount?.id,
      title: prizeAccount?.title,
      balance: prizeAccount?.balance,
      currency: prizeAccount?.currency,
      customer_id: prizeAccount?.customer_id,
      facility_id: prizeAccount?.facility_id,
      league_id: prizeAccount?.league_id,
      tournament_id: prizeAccount?.tournament_id,
      client_id: prizeAccount?.client_id,
      type: prizeAccount?.type,
    });

    setFilterState(prevState => ({
      ...prevState,
      title: prizeAccount.title,
      selectedLeague: league[0],
      selectedTournament: tournament[0],
    }));

    setVariantState(prevState => ({ ...prevState, prizeAccountVariants: prizeAccount?.variants }));
  }

  async function loadVariants(token?: CancelToken) {
    setFilterState(prevState => ({ ...prevState, variantSearching: true }));

    const types: any[] = ["League Registration Fee", "Tournament Registration Fee", "Credit Book Contribution"];

    const getProductRes = await GetVariant(
      {
        search: filterState.variantQuery,
        types: types,
        extended: true,
      },
      false,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (getProductRes.status !== StatusCode.OK) {
      return;
    }

    setFilterState(prevState => ({ ...prevState, variantSearching: false }));
    setVariantState(prevState => ({ ...prevState, productVariants: getProductRes.data }));
  }

  const primaryAction = {
    content: t("secure.facility.settings.prize_accounts.prize_account.001"),
    action: handleOpenNewVariant,
  };

  async function loadSearchedTournaments(search: string) {
    const params = {
      search: search,
    };

    const tournamentRes = await GetTournament(params, false);

    if (tournamentRes.status !== StatusCode.OK) {
      dispatch(showError(tournamentRes.message));
      return [];
    }

    return tournamentRes.data;
  }

  async function loadSearchedLeagues(search: string) {
    const params = {
      search: search,
    };

    const leagueRes = await GetLeague(params, false);

    if (leagueRes.status !== StatusCode.OK) {
      dispatch(showError(leagueRes.message));

      return [];
    }

    return leagueRes.data;
  }

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (async () => {
          if (filterState.tournamentSearch !== "") {
            try {
              setFilterState(prevState => ({ ...prevState, tournamentSearching: true }));
              const tournaments = await loadSearchedTournaments(filterState.tournamentSearch);
              if (mounted) {
                setFilterState(prevState => ({ ...prevState, tournaments: tournaments, tournamentSearching: false }));
              }
            } catch (error) {
              console.log("err", error);
            }
            return;
          } else {
            setFilterState(prevState => ({ ...prevState, tournaments: [], tournamentSearching: false }));
          }
        })();
      }, 1000);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [filterState.tournamentSearch]);

  //Load Leagues
  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (async () => {
          if (filterState.leagueSearch !== "") {
            try {
              setFilterState(prevState => ({ ...prevState, leagueSearching: true }));
              const leagues = await loadSearchedLeagues(filterState.leagueSearch);
              if (mounted) {
                setFilterState(prevState => ({ ...prevState, leagues: leagues, leagueSearching: false }));
              }
            } catch (error) {
              console.log("err", error);
            }
            return;
          } else {
            setFilterState(prevState => ({ ...prevState, leagues: [], leagueSearching: false }));
          }
        })();
      }, 1000);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [filterState.leagueSearch]);

  function handleOpenNewVariant() {
    setFilterState(prevState => ({ ...prevState, newVariantVisible: true }));
  }

  const handleTabChange = (selectedTabIndex: number) => {
    setSelected(selectedTabIndex);
  };

  const tabs = [
    {
      id: "tournament-1",
      content: t("secure.facility.settings.prize_accounts.prize_account.002"),
      panelID: "tournament-content-1",
      disabled: leagueId ? true : false,
    },

    {
      id: "league-1",
      content: t("secure.facility.settings.prize_accounts.prize_account.003"),
      panelID: "league-content-1",
      disabled: tournamentId ? true : false,
    },
  ];

  function prizeAccountInputChange(e: any) {
    const id = e.target.id;
    const value = e.target.value;

    setPrizeAccount(prev => ({ ...prev, [id]: value }));
  }

  function handleTournamentChange(value: any, tournament: ITournament) {
    if (tournament) {
      setPrizeAccount(prevState => ({ ...prevState, tournament_id: tournament.id }));

      setFilterState(prevState => ({
        ...prevState,
        tournamentSearch: "",
        selectedTournament: tournament,
        selectedLeague: null,
        leagueSearch: "",
      }));
    }
  }

  function handleLeagueChange(value: any, league: ILeague) {
    if (league) {
      setPrizeAccount(prevState => ({ ...prevState, league_id: league.id }));

      setFilterState(prevState => ({
        ...prevState,
        leagueSearch: "",
        selectedLeague: league,
        selectedTournament: null,
        tournamentSearch: "",
      }));
    }
  }

  function handleVariantChange(value: any, variant: any) {
    if (variant) {
      setVariantState(prevState => ({
        ...prevState,
        selectedVariant: variant,
      }));
    }
  }

  function displayTournaments() {
    if (filterState?.tournaments && filterState?.tournaments?.length > 0 && filterState?.tournamentSearch !== "") {
      return filterState?.tournaments;
    } else {
      return tournaments;
    }
  }

  function displayLeagues() {
    if (filterState?.leagues && filterState?.leagues?.length > 0 && filterState?.leagueSearch !== "") {
      return filterState?.leagues;
    } else {
      return leagues;
    }
  }

  function removeSelectedTournament() {
    setPrizeAccount(prevState => ({ ...prevState, tournament_id: null }));

    setFilterState(prevState => ({
      ...prevState,
      selectedTournament: null,
      tournaments: [],
      tournamentSearching: false,
    }));
  }

  function removeSelectedLeague() {
    setPrizeAccount(prevState => ({ ...prevState, league_id: null }));

    setFilterState(prevState => ({
      ...prevState,
      selectedLeague: null,
      leagues: [],
      leagueSearching: false,
    }));
  }

  function removeSelectedVariant() {
    setVariantState(prevState => ({ ...prevState, selectedVariant: null }));
  }

  async function handleAddVariant() {
    const params = {
      credit_book_id: Number(id),
      variant_id: variantState.selectedVariant.id,
    };

    for (let i = 0; i < variantState.prizeAccountVariants.length; i++) {
      if (variantState.prizeAccountVariants[i].id === variantState.selectedVariant.id) {
        dispatch(showError(t("secure.facility.settings.prize_accounts.prize_account.004")));
        return;
      }
    }

    const attachVariantRes = await PutCreditBookVariant(params, true);

    if (attachVariantRes.status !== StatusCode.OK) {
      dispatch(showError(attachVariantRes.message));

      return;
    }

    setFilterState(prevState => ({ ...prevState, newVariantVisible: false }));
    setVariantState(prevState => ({ ...prevState, selectedVariant: null }));

    void getPrizeAccount();
  }

  function handleCloseAddVariant() {
    ReactDOM.unstable_batchedUpdates(() => {
      setVariantState(prevState => ({ ...prevState, selectedVariant: null }));
      setFilterState(prevState => ({ ...prevState, newVariantVisible: false }));
    });
  }

  function unsavedChangesExist() {
    if (prizeAccountBeforeChanges === undefined) {
      if (prizeAccount) {
        setPrizeAccountBeforeChanges(prizeAccount);
      }
      return false;
    }

    return !isEqualWith(prizeAccountBeforeChanges, prizeAccount);
  }

  async function savePrizeAccount() {
    const updatePrizeAccountRes = await PutCreditBook(prizeAccount, true);

    if (updatePrizeAccountRes.status !== StatusCode.OK) {
      dispatch(showError(updatePrizeAccountRes.message));
      return;
    }

    history.push("/admin/settings/prizeAccounts");
  }

  function cancelUnsavedChanges() {
    setPrizeAccount(prizeAccountBeforeChanges);
    const league = leagues.filter(league => league.id === prizeAccountBeforeChanges.league_id);
    const tournament = tournaments.filter(tournament => tournament.id === prizeAccountBeforeChanges.tournament_id);

    setFilterState(prevState => ({ ...prevState, selectedTournament: tournament[0], selectedLeague: league[0] }));
  }

  async function removePrizeAccountVariant() {
    const params = {
      credit_book_id: id,
      variant_id: variantState.variantToDelete.id,
    };
    const removePrizeAccountRes = await DeleteCreditBookVariant(params, true);

    if (removePrizeAccountRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.prize_accounts.prize_account.005")));
      return;
    }

    setVariantState(prevState => ({ ...prevState, variantToDelete: null, deleteVariantVisible: false }));
    void getPrizeAccount();
  }

  function handleRemovePrizeAccountVariant(variant: any) {
    setVariantState(prevState => ({ ...prevState, variantToDelete: variant, deleteVariantVisible: true }));
  }

  return (
    <>
      <Page
        title={prizeAccount?.title}
        primaryAction={primaryAction}
        narrow
        breadcrumbs={[
          {
            prefix: true,
            label: t("secure.facility.settings.prize_accounts.prize_account.006"),
            url: leagueId
              ? "/admin/league/" + String(leagueId) + "/prizeAccounts/view/" + String(id)
              : tournamentId
              ? "/admin/tournament/" + String(tournamentId) + "/prizeAccounts/view/" + String(id)
              : "/admin/settings/prizeAccounts/view/" + String(id),
          },
        ]}
        notificationBarProps={{
          isVisible: unsavedChangesExist(),
          onAction: savePrizeAccount,
          onCancel: cancelUnsavedChanges,
        }}
      >
        {prizeAccount ? (
          <>
            <Card>
              <Card.Section>
                <div className="mb-4">
                  <Input
                    placeholder={t("secure.facility.settings.prize_accounts.prize_account.007")}
                    id="title"
                    value={prizeAccount.title}
                    onChange={prizeAccountInputChange}
                    label={t("secure.facility.settings.prize_accounts.prize_account.007")}
                  />
                </div>

                <div>
                  <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                    {/* Booking Details */}
                    {selected === 0 ? (
                      <div className="flex-grow mb-4 pt-4">
                        {filterState.selectedTournament ? (
                          <div>
                            <div>
                              <p className="event-label">
                                {t("secure.facility.settings.prize_accounts.prize_account.008")}
                              </p>
                            </div>

                            <div className="selected-container">
                              <div className="event-name">
                                <div>{filterState.selectedTournament.name}</div>

                                <div>
                                  <button className="" onClick={removeSelectedTournament}>
                                    <Icon style="far" icon="times" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <Select
                            showSearch
                            label={t("secure.facility.settings.prize_accounts.prize_account.002")}
                            onSearch={(query: string) =>
                              setFilterState(prevState => ({ ...prevState, tournamentSearch: query }))
                            }
                            onChange={(value: any, tournament: ITournament) =>
                              handleTournamentChange(value, tournament)
                            }
                            placeholder={t("secure.facility.settings.prize_accounts.prize_account.009")}
                            allowClear
                            searchValue={filterState.tournamentSearch}
                            showDropDownOnFocus={true}
                            searching={filterState.tournamentSearching}
                          >
                            <Option
                              value={-1}
                              extraValues={{ name: t("secure.facility.tee_sheet.tee_sheet_edit.022"), id: -1 }}
                            >
                              <div className="text-semibold text-lg">
                                {t("secure.facility.tee_sheet.tee_sheet_edit.022")}
                              </div>
                            </Option>
                            {displayTournaments()?.map((tournament: any, index: number) => {
                              return (
                                <Option value={tournament?.id} extraValues={tournament} key={index}>
                                  <span className="text-semibold text-lg">{tournament.name}</span>
                                  <span className="shotgun-tournament-date">
                                    {moment(tournament?.date).format("LL")}
                                  </span>
                                </Option>
                              );
                            })}
                          </Select>
                        )}
                      </div>
                    ) : null}
                    {selected === 1 ? (
                      <div className="flex-grow mb-4 pt-4">
                        {filterState.selectedLeague ? (
                          <div>
                            <div>
                              <p className="event-label">
                                {t("secure.facility.settings.prize_accounts.prize_account.010")}
                              </p>
                            </div>

                            <div className="selected-container">
                              <div className="event-name">
                                <div>{filterState.selectedLeague.name}</div>

                                <div>
                                  <button className="" onClick={removeSelectedLeague}>
                                    <Icon style="far" icon="times" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <Select
                              showSearch
                              label={t("secure.facility.tee_sheet.tee_sheet_edit.023")}
                              onSearch={(query: string) =>
                                setFilterState(prevState => ({ ...prevState, leagueSearch: query }))
                              }
                              onChange={(value: any, league: ILeague) => handleLeagueChange(value, league)}
                              placeholder={t("secure.facility.settings.prize_accounts.prize_account.011")}
                              allowClear
                              searchValue={filterState.leagueSearch}
                              showDropDownOnFocus={true}
                              searching={filterState.leagueSearching}
                            >
                              <Option
                                value={-1}
                                extraValues={{ name: t("secure.facility.tee_sheet.tee_sheet_edit.024"), id: -1 }}
                              >
                                <div className="text-semibold text-lg">
                                  {t("secure.facility.tee_sheet.tee_sheet_edit.024")}
                                </div>
                              </Option>
                              {displayLeagues()?.map((league: any, index: number) => {
                                return (
                                  <Option value={league?.id} key={index} extraValues={league}>
                                    <span className="text-semibold text-lg">{league.name}</span>
                                    <span className="shotgun-tournament-date">
                                      {league?.start_date ? moment(league?.start_date).format("LL") : ""}
                                    </span>
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                        )}
                      </div>
                    ) : null}
                  </Tabs>
                </div>
              </Card.Section>
            </Card>
            <Card title={t("secure.facility.settings.prize_accounts.prize_account.012")}>
              <Card.Section table="true">
                <table className="ui-table mt-4">
                  <thead>
                    <tr>
                      <th>{t("secure.facility.settings.prize_accounts.prize_account.013")}</th>
                      <th>{t("secure.facility.settings.prize_accounts.prize_account.014")}</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {variantState.prizeAccountVariants.map((variant: any, index: any) => {
                      return (
                        <tr key={index}>
                          <td>
                            {`${
                              variant.product.title !== variant.title ? String(variant.product.title) + " - " : ""
                            }${String(variant.title)}${
                              variant.product.subtitle ? " - " + String(variant.product.subtitle) : ""
                            }`}
                          </td>

                          <td>{displayCurrency("cad", variant.price)}</td>
                          <td style={{ display: "flex", justifyContent: "center" }}>
                            <button className="" onClick={() => handleRemovePrizeAccountVariant(variant)}>
                              <Icon style="far" icon="times" />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Card.Section>
            </Card>
          </>
        ) : null}
      </Page>

      <Sheet
        title={t("secure.facility.settings.prize_accounts.prize_account.020")}
        open={filterState.newVariantVisible}
        size="small"
        closable
        onCancel={handleCloseAddVariant}
        onOk={handleAddVariant}
        okText={t("secure.facility.settings.prize_accounts.prize_account.015")}
        overflow
      >
        <div className="flex-grow">
          {variantState.selectedVariant ? (
            <div>
              <div>
                <p className="event-label">{t("secure.facility.settings.prize_accounts.prize_account.016")}</p>
              </div>

              <div className="selected-container">
                <div className="event-name">
                  <div>{`${
                    variantState.selectedVariant.product.title !== variantState.selectedVariant.title
                      ? String(variantState.selectedVariant.product.title) + " - "
                      : ""
                  }${String(variantState.selectedVariant.title)}${
                    variantState.selectedVariant.product.subtitle
                      ? " - " + String(variantState.selectedVariant.product.subtitle)
                      : ""
                  }`}</div>

                  <div>
                    <button className="" onClick={removeSelectedVariant}>
                      <Icon style="far" icon="times" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <Select
                showSearch
                onSearch={(value: string) => setFilterState(prevState => ({ ...prevState, variantQuery: value }))}
                searching={filterState.variantSearching}
                label={t("secure.facility.settings.prize_accounts.prize_account.021")}
                onChange={(value: any, variant: any) => handleVariantChange(value, variant)}
                placeholder={t("secure.facility.settings.prize_accounts.prize_account.022")}
                allowClear
                showDropDownOnFocus={true}
              >
                {variantState.productVariants && (
                  <div>
                    {variantState.productVariants.map((variant: any, index: number) => {
                      return (
                        <Option value={variant?.id} key={index} extraValues={variant}>
                          <div className="text-semibold text-lg">
                            {`${
                              variant.product.title !== variant.title ? String(variant.product.title) + " - " : ""
                            }${String(variant.title)}${
                              variant.product.subtitle ? " - " + String(variant.product.subtitle) : ""
                            }`}
                          </div>
                        </Option>
                      );
                    })}
                  </div>
                )}
              </Select>
            </div>
          )}
        </div>
      </Sheet>

      <Popup
        open={variantState.deleteVariantVisible}
        type="warning"
        title={t("secure.facility.settings.prize_accounts.prize_account.017")}
        description={t("secure.facility.settings.prize_accounts.prize_account.018")}
        onOk={removePrizeAccountVariant}
        okText={t("secure.facility.settings.prize_accounts.prize_account.019")}
        onCancel={() =>
          setVariantState(prevState => ({ ...prevState, deleteVariantVisible: false, variantToDelete: null }))
        }
      />
    </>
  );
}
