import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StatusCode } from "api/protocols";
import { GetTag } from "api/rpc/2024-04/facilityAdmin/client/tags";
import {
  GetCustomerSegments,
  ICustomerSegment,
  PutCustomerSegment,
  PutCustomerSegmentTags,
} from "api/rpc/2024-04/facilityAdmin/customer/segment";
import axios, { CancelToken } from "axios";
import { ButtonNew as Button } from "components/buttonNew";
import Card from "components/card/Card";
import Checkbox from "components/form/checkbox/Checkbox";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input";
import Page from "components/page/Page";
import Popup from "components/popup/Popup";
import Sheet from "components/sheet/Sheet";
import Portal from "elements/Portal";
import { useAppDispatch } from "hooks/redux";
import { isEqual, isEqualWith, isNull } from "lodash";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useHistory, useLocation, useParams } from "react-router";
import { dequeue, enqueue, showError, showSuccess } from "redux/actions/ui";
import { TCustomerTag } from "redux/reducers/models/customer";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";

interface IParams {
  segmentId: string;
}

interface IState {
  segment: ICustomerSegment;
  tags: Array<TCustomerTag>;
  segmentTitle: string;
  segmentDescription: string;
}

interface ITagState {
  tags: Array<TCustomerTag>;
  open: boolean;
  selectedTags: Array<TCustomerTag>;
  tagSearch: string;
}

interface ILocationState {
  params: { segment: ICustomerSegment };
}

export default function EditCustomerSegment() {
  const { segmentId } = useParams<IParams>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const locationState = location.state as ILocationState;
  const [state, setState] = useState<IState>({
    segment: locationState?.params?.segment ?? undefined,
    tags: locationState?.params?.segment?.tags ?? [],
    segmentTitle: locationState?.params?.segment?.title ?? undefined,
    segmentDescription: locationState?.params?.segment?.description ?? undefined,
  });
  const [beforeChangesState, setBeforeChangesState] = useState<IState>(undefined);

  const [tagState, setTagState] = useState<ITagState>({
    tags: undefined,
    open: false,
    selectedTags: [],
    tagSearch: "",
  });

  const [deletePopup, setDeletePopup] = useState<boolean>(false);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadTags(source.token);
    void getCustomerSegment(source.token);

    return () => {
      source.cancel();
    };
  }, []);

  async function getCustomerSegment(cancelToken: CancelToken) {
    //Set segment loaded from 'View' page
    if (locationState?.params?.segment) {
      const segment = locationState?.params?.segment;
      setState(prevState => ({
        ...prevState,
        segment: segment,
        segmentTitle: segment?.title,
        segmentDescription: segment?.description,
        tags: segment?.tags,
      }));
    } else {
      //Reload segment

      //loader
      if (state?.segment !== undefined) {
        setState(prevState => ({ ...prevState, segment: undefined }));
      }

      const res = await GetCustomerSegments({ id: Number(segmentId), extended: true }, false, cancelToken);
      if (res?.status !== StatusCode.OK) {
        if (cancelToken && cancelToken.reason) {
          return;
        }
        dispatch(showError("Error getting segment"));
        void navigateRoute(`/admin/marketing/customer-segments/view/${segmentId}`);
        return;
      }
      const segment = res?.data[0];
      setState(prevState => ({
        ...prevState,
        segment: segment,
        segmentTitle: segment?.title,
        segmentDescription: segment?.description,
        tags: segment?.tags,
      }));
    }
  }

  async function loadTags(cancelToken: CancelToken) {
    const tagsRes = await GetTag({ type: "customer" }, false, cancelToken);
    if (tagsRes.status !== StatusCode.OK) {
      if (cancelToken && cancelToken?.reason) {
        return;
      }
      dispatch(showError("Error getting tags"));
      return;
    }
    setTagState(prevState => ({ ...prevState, tags: [...tagsRes?.data] }));
  }

  function handleSelectTag(tag: TCustomerTag) {
    let selectedTags = [...tagState.selectedTags];
    const foundIndex = selectedTags?.findIndex(searchTag => searchTag?.id === tag?.id);
    if (foundIndex !== -1) {
      selectedTags = selectedTags?.filter(filteredTag => filteredTag?.id !== tag?.id);
    } else {
      selectedTags = [...selectedTags, tag];
    }
    setTagState(prevState => ({ ...prevState, selectedTags }));
  }

  function handleAddTags() {
    const selectedTags = [...tagState?.selectedTags];
    const tags = [...state?.tags];
    ReactDOM.unstable_batchedUpdates(() => {
      setTagState(prevState => ({ ...prevState, open: false, tagSearch: "", selectedTags: [] }));
      setState(prevState => ({ ...prevState, tags: [...tags, ...selectedTags] }));
    });
  }

  function unsavedChangesExist() {
    if (beforeChangesState === undefined && state?.segment) {
      setBeforeChangesState(state);
      return false;
    }

    return !isEqualWith(beforeChangesState, state, (originalValue, newValue) => {
      if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
        return true;
      }
    });
  }

  function cancelUnsavedChanges() {
    setState({ ...beforeChangesState });
  }

  async function handleSaveChanges() {
    if (!state?.segmentTitle || !state?.segmentDescription) {
      dispatch(showError("Title and description required"));
      return;
    }

    let segment: ICustomerSegment = { ...state?.segment };
    dispatch(enqueue());
    //Update segment if the title or description was updated
    if (
      !isEqual(state?.segmentTitle, beforeChangesState?.segmentTitle) ||
      !isEqual(state?.segmentDescription, beforeChangesState?.segmentDescription)
    ) {
      const putRes = await PutCustomerSegment(
        { segment_id: Number(segmentId), title: state.segmentTitle, description: state.segmentDescription },
        false,
      );
      if (putRes?.status !== StatusCode.OK) {
        dispatch(showError("Error updating customer segment"));
        dispatch(dequeue());
        return;
      }
      segment = putRes?.data;
    }

    //Update the tags if the tags were updated
    if (!isEqual(state?.tags, beforeChangesState?.tags)) {
      const tagIds = state?.tags?.map(tag => tag?.id);
      const tagsRes = await PutCustomerSegmentTags({ segment_id: Number(segmentId), tag_ids: tagIds }, false);
      if (tagsRes?.status !== StatusCode.OK) {
        dispatch(showError("Error updating customer segment tags"));
        dispatch(dequeue());
        return;
      }
      //Reload segment
      const segmentRes = await GetCustomerSegments({ id: Number(segmentId), extended: true }, false);
      if (segmentRes?.status !== StatusCode.OK) {
        dispatch(showError("Error getting customer segment"));
        dispatch(dequeue());
        void navigateRoute(`/admin/marketing/customer-segments/view/${segmentId}`);
        return;
      }
      segment = segmentRes?.data[0];
    }
    dispatch(dequeue());
    dispatch(showSuccess("Successfully updated customer segment"));

    const segmentDetails = {
      segment: segment,
      segmentTitle: segment?.title,
      segmentDescription: segment?.description,
      tags: segment?.tags ?? state?.tags,
    };
    ReactDOM.unstable_batchedUpdates(() => {
      setState(prevState => ({
        ...prevState,
        ...segmentDetails,
      }));
      setBeforeChangesState(prevState => ({ ...prevState, ...segmentDetails }));
    });
  }

  function navigateRoute(url: string) {
    history.push(url);
  }

  return (
    <Page
      narrow
      title={state?.segment ? `Edit ${state?.segment?.title}` : "Loading..."}
      primaryAction={{
        content: "Save",
        action: () => handleSaveChanges(),
      }}
      secondaryActions={[
        {
          content: "Delete",
          action: () => setDeletePopup(true),
        },
      ]}
      breadcrumbs={[
        {
          prefix: true,
          label: "Back to View Customer Segment",
          url: `/admin/marketing/customer-segments/view/${segmentId}`,
        },
      ]}
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: handleSaveChanges,
        onCancel: cancelUnsavedChanges,
      }}
    >
      <Card>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Input
                label="Title"
                placeholder="Title"
                value={state.segmentTitle}
                onChange={e => setState(prevState => ({ ...prevState, segmentTitle: e.target.value }))}
                error={!state?.segmentTitle}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <Input
                label="Description"
                placeholder="Description"
                value={state.segmentDescription}
                onChange={e => setState(prevState => ({ ...prevState, segmentDescription: e.target.value }))}
                error={!state?.segmentDescription}
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>
      <Card>
        <Card.Section
          title="Tags"
          sectionTitleActions={[
            {
              content: "Add Tags",
              action: () => setTagState(prevState => ({ ...prevState, open: true })),
              type: "secondary",
              icon: <FontAwesomeIcon icon={["far", "plus"]} />,
              size: "xsmall",
            },
          ]}
        >
          <DataTable
            columns={[{ label: "Label" }, { label: "Description" }, { label: "" }, { label: "", justify: "right" }]}
            loading={!state?.tags}
          >
            {state?.tags?.map(tag => {
              return (
                <tr key={tag?.id}>
                  <td>{tag?.label}</td>
                  <td>{tag?.description}</td>
                  <td>
                    <FontAwesomeIcon icon={["fas", "circle"]} size="1x" color={tag?.colour} />
                  </td>
                  <td
                    onClick={() =>
                      setState(prevState => ({
                        ...prevState,
                        tags: prevState?.tags?.filter(filteredTag => filteredTag?.id !== tag?.id),
                      }))
                    }
                  >
                    <FontAwesomeIcon icon={["far", "trash-can"]} className="cursor-pointer float-right" />
                  </td>
                </tr>
              );
            })}
          </DataTable>
        </Card.Section>
      </Card>

      <Portal isMounted={tagState.open}>
        <Sheet
          open={tagState.open}
          onCancel={() => setTagState(prevState => ({ ...prevState, open: false, selectedTags: [], tagSearch: "" }))}
          onOk={handleAddTags}
          okText="Add"
          size="small"
          closable
          title="Add Tags"
          okDisabled={tagState.selectedTags?.length === 0}
        >
          <Input
            onSearchClear={() => setTagState(prevState => ({ ...prevState, tagSearch: "" }))}
            onChange={e => setTagState(prevState => ({ ...prevState, tagSearch: e.target.value }))}
            placeholder="Search tags..."
            autoFocus
            value={tagState.tagSearch}
          />
          <br />
          <DataTable
            columns={[{ label: "" }, { label: "Label" }, { label: "Description" }, { label: "" }]}
            loading={!tagState?.tags}
          >
            {tagState?.tags &&
              tagState.tags
                ?.filter(
                  filteredTag =>
                    !state.tags?.some(foundTag => foundTag.id === filteredTag.id) &&
                    filteredTag?.label?.toLowerCase()?.includes(tagState.tagSearch?.toLowerCase()),
                )
                ?.map(tag => {
                  return (
                    <tr key={tag?.id} className="clickable" onClick={e => handleSelectTag(tag)}>
                      <td onClick={e => e.stopPropagation()}>
                        <Checkbox
                          size="small"
                          onChange={e => handleSelectTag(tag)}
                          checked={tagState.selectedTags?.some(selectedTag => selectedTag?.id === tag?.id)}
                        />
                      </td>
                      <td>{tag.label}</td>
                      <td>{tag?.description}</td>
                      <td>
                        <FontAwesomeIcon icon={["fas", "circle"]} size="1x" color={tag.colour} />
                      </td>
                    </tr>
                  );
                })}
          </DataTable>
        </Sheet>
      </Portal>

      <Portal isMounted={deletePopup}>
        <Popup
          title="Delete Segment?"
          description="Are you sure you want to delete this customer segment? This cannot be undone."
          open={deletePopup}
          onOk={() => {}}
          onCancel={() => setDeletePopup(false)}
          okText="Delete"
          type="warning"
        />
      </Portal>
    </Page>
  );
}
