import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { TGetEvent, GetEvent } from "api/rpc/2024-04/facilityAdmin/event/hospitalityEvent";

import { convertTime, capitalize } from "helpers/Helpers";
import { IEvent } from "redux/reducers/models/event";

import { THIS_YEAR } from "components/form/calendarField/helpers";
import Page from "components/page/Page";
import Search from "components/search/Search";
import YearDropFilter from "components/filters/YearDropFilter";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";

const TABLE_LIMIT = 50;
export default function HospitalityEvents() {
  const history = useHistory();
  const { t } = useTranslation();

  const [events, setEvents] = useState<IEvent[]>(undefined);
  const [filterState, setFilterState] = useState<TGetEvent>({
    year: THIS_YEAR,
    offset: 0,
    search: "",
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadEvents(source.token);
    return () => {
      source.cancel();
    };
  }, [filterState]);

  async function loadEvents(token?: CancelToken) {
    if (events !== undefined) {
      setEvents(undefined);
    }

    const eventRes = await GetEvent({ ...filterState, limit: TABLE_LIMIT }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (eventRes.status !== StatusCode.OK) {
      setEvents([]);
      return;
    }

    setEvents(eventRes.data);
  }

  function handleTableNavigation(direction: "prev" | "next") {
    switch (direction) {
      case "prev": {
        setFilterState(prev => ({ ...prev, offset: filterState.offset - filterState.limit }));
        return;
      }
      case "next": {
        setFilterState(prev => ({ ...prev, offset: filterState.offset + filterState.limit }));
        return;
      }
      default:
        return;
    }
  }

  const primaryAction = {
    content: t("secure.facility.hospitality.hospitality_events.001"),
    action: () => history.push("/admin/events-hospitality/new"),
  };

  return (
    <Page title={t("secure.facility.hospitality.hospitality_events.002")} narrow primaryAction={primaryAction}>
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", paddingBottom: "1.5rem" }}>
        <YearDropFilter
          handleApply={year => setFilterState({ ...filterState, year: year })}
          defaultCheckboxes={[filterState.year]}
          leftAligned
        />
      </div>
      <div style={{ paddingBottom: "1.5rem" }}>
        <Search
          historyKey={"facility-tournaments-search"}
          searchCallback={searchValue => {
            if (searchValue.length === 0 || searchValue.length > 2) {
              setFilterState(prev => ({ ...prev, search: searchValue, offset: 0 }));
            }
          }}
          placeholder={t("secure.facility.hospitality.hospitality_events.003")}
        />
      </div>
      <DataTable
        columns={[
          { label: t("secure.facility.hospitality.hospitality_events.004"), width: "40%" },
          { label: t("secure.facility.hospitality.hospitality_events.005"), width: "40%" },
          { label: t("secure.facility.hospitality.hospitality_events.006"), width: "20%" },
        ]}
        loading={events === undefined}
        footer={{
          tableLimit: TABLE_LIMIT,
          tableOffset: filterState.offset,
          handleTableOffset: direction => handleTableNavigation(direction),
          disableNextOffset: !(events?.length === filterState.limit) || events === undefined,
        }}
      >
        {events?.map(event => (
          <tr
            key={event.id}
            onClick={() => history.push("/admin/event/" + String(event.id) + "/home")}
            className="clickable"
          >
            <td>{event.name}</td>
            <td>
              <span>{moment.utc(event.date).format("MMM DD, YYYY")} at </span>
              <span>{convertTime(event.start_time)}</span>
            </td>
            <td>{capitalize(event.type)}</td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
