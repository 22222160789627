import { APIGet, APIPost, WebGet, WebPost, IAPIResponse, StatusCode } from "./protocols";
import { IUser, IUserActive } from "../redux/reducers/models/user";
import { CancelToken } from "axios";

export async function FetchCSRFCookie(useGlobalLoader: boolean) {
  return WebGet("sanctum/csrf-cookie", useGlobalLoader);
}

interface IUserLogin {
  email: string;
  password: string;
  admin?: boolean;
}

interface IUserLoginRes {
  status: StatusCode;
  data: {
    session: boolean;
    user: IUser;
  };
}

interface ISwitchUser {
  quick_code: number;
}

interface ISwitchUserRes {
  status: StatusCode;
  data: {
    session: boolean;
    user: IUser;
  };
}

export async function UserLogin(params: IUserLogin, useGlobalLoader: boolean): Promise<IUserLoginRes> {
  return WebPost("login", params, useGlobalLoader);
}

export async function UserLogout(useGlobalLoader: boolean) {
  return WebPost("logout", {}, useGlobalLoader);
}

interface IUserActiveRes extends IAPIResponse {
  data: IUserActive;
}

export async function UserActive(useGlobalLoader: boolean): Promise<IUserActiveRes> {
  return APIGet("2021-01/common/auth/active", useGlobalLoader);
}

export async function ActiveUser(useGlobalLoader: boolean) {
  return APIGet("2021-01/common/auth/active", useGlobalLoader);
}

export async function SwitchUser(params: ISwitchUser, useGlobalLoader: boolean): Promise<ISwitchUserRes> {
  return WebPost("switch", params, useGlobalLoader);
}
