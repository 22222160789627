import { APIGet, APIPost, APIPut, APIDelete, StatusCode } from "api/protocols";
import { CancelToken } from "axios";
import { weekdayNames } from "helpers/Helpers";
import { ILeague } from "redux/reducers/models/league";
import { IProduct, IVariant } from "redux/reducers/models/product";
import { TWeekDays, WEEK_DAYS } from "components/form/calendarField/helpers";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseLeagueUrl = "/" + apiVersion + "/" + adminType + "/league";

interface ILeagueGet {
  id?: number | string;
  search?: string;
  year?: number;
}

export interface ILeaguePost {
  name?: string;
  week_day?: TWeekDays;
  start_time?: string;
}

export interface ILeaguePut {
  id: number | string;
  name?: string;
  week_day?: TWeekDays;
  start_time?: string;
  description?: string;
  capacity?: number;
  registration_open?: boolean;
  logo_source?: string;
}

interface IGetLeagueResponse {
  data: ILeague[];
  status: number;
  message: string;
}

// GET League
// Retrieve a list of leagues
export async function GetLeague(
  params: ILeagueGet,
  useGlobalLoader: boolean,
  token?: CancelToken,
): Promise<IGetLeagueResponse> {
  return await APIGet(baseLeagueUrl, params, useGlobalLoader, token);
}

// POST League
// Create a new league
export async function PostLeague(params: ILeaguePost, useGlobalLoader: boolean) {
  return await APIPost(baseLeagueUrl, params, useGlobalLoader);
}

// PUT League
// Update an existing league
export async function PutLeague(params: ILeaguePut, useGlobalLoader: boolean) {
  return await APIPut(baseLeagueUrl, params, useGlobalLoader);
}

// PUT League
// Update an existing league
export async function DeleteLeague(params: { id: string | number }, useGlobalLoader: boolean) {
  return await APIDelete(baseLeagueUrl, params, useGlobalLoader);
}

//DELETE League Scoresheet
export async function DeleteLeagueScoresheet(params: any, useGlobalLoader: boolean) {
  return await APIDelete(baseLeagueUrl + "/scoring/scoresheet", params, useGlobalLoader);
}

// Post League Teams
interface IPostLeagueTeamRes {
  data: IPostLeagueTeam;
  status: number;
}
interface IPostLeagueTeam {
  league_id: number;
  name: string;
}

export async function PostLeagueTeamNew(
  params: IPostLeagueTeam,
  useGlobalLoader: boolean,
): Promise<IPostLeagueTeamRes> {
  return await APIPost(baseLeagueUrl + "/scoring/team", params, useGlobalLoader);
}

// POST League Flights
interface IPostLeagueFlightRes {
  data: IPostLeagueFlight;
  status: number;
}

interface IPostLeagueFlight {
  league_id: number;
  name: string;
}

interface IPutLeagueTeam {
  id: number;
  name: string;
}

interface IPutLeagueFlight {
  id: number;
  name: string;
}

interface IGetLeagueRegistrationFees {
  league_id?: number;
  variant_id?: number;
}

export interface IRegistrationFee {
  description: string;
  id: number;
  league?: ILeague;
  league_id: number;
  players: number;
  product?: IProduct;
  product_id: number;
  product_title: string;
  type: string;
  variant?: IVariant;
  variant_id: number;
  variant_title: string;
}

interface IGetLeagueRegistrationFeesRes {
  status: StatusCode;
  data: Array<IRegistrationFee>;
}

export async function PostLeagueFlight(
  params: IPostLeagueFlight,
  useGlobalLoader: boolean,
): Promise<IPostLeagueFlightRes> {
  return await APIPost(baseLeagueUrl + "/scoring/flight", params, useGlobalLoader);
}

//GET League Teams
export async function GetLeagueTeams(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseLeagueUrl + "/scoring/team", params, useGlobalLoader);
}

export async function PutLeagueTeams(params: IPutLeagueTeam, useGlobalLoader: boolean) {
  return await APIPut(baseLeagueUrl + "/scoring/team", params, useGlobalLoader);
}

//GET Team Players
export async function GetLeagueTeamPlayers(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseLeagueUrl + "/scoring/team/participant", params, useGlobalLoader);
}

//PUT Team Players
export async function PutLeagueTeamPlayers(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseLeagueUrl + "/scoring/team/participant", params, useGlobalLoader);
}

//GET League Flights
export async function GetLeagueFlights(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseLeagueUrl + "/scoring/flight", params, useGlobalLoader);
}

export async function PutLeagueFlights(params: IPutLeagueFlight, useGlobalLoader: boolean) {
  return await APIPut(baseLeagueUrl + "/scoring/flight", params, useGlobalLoader);
}

export async function DeleteLeagueFlight(params: any, useGlobalLoader: boolean) {
  return await APIDelete(baseLeagueUrl + "/scoring/flight", params, useGlobalLoader);
}

//GET League flight participants
export async function GetLeagueFlightParticipant(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseLeagueUrl + "/scoring/flight/participant", params, useGlobalLoader);
}

//PUT League Flight Participant
export async function PutLeagueFlightParticipant(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseLeagueUrl + "/scoring/flight/participant", params, useGlobalLoader);
}

//DELETE League Flight Participant
export async function DeleteLeagueFlightParticipant(params: any, useGlobalLoader: boolean) {
  return await APIDelete(baseLeagueUrl + "/scoring/flight/participant", params, useGlobalLoader);
}

export async function GetLeagueRegistrationFees(
  params: IGetLeagueRegistrationFees,
  useGlobalLoader: boolean,
): Promise<IGetLeagueRegistrationFeesRes> {
  return await APIGet(baseLeagueUrl + "/registration-fee", params, useGlobalLoader);
}

export async function GetLeagueTeamScores(params: { scoresheet_id: string }, useGlobalLoader: boolean) {
  return await APIGet(baseLeagueUrl + "/scoring/scoresheet/team", params, useGlobalLoader);
}

export async function GetLeagueSponsorship(
  params: { league_id?: string },
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseLeagueUrl + "/sponsorship", params, useGlobalLoader, token);
}

export async function PostLeagueSponsorship(params: FormData, useGlobalLoader: boolean) {
  return await APIPost(baseLeagueUrl + "/sponsorship", params, useGlobalLoader);
}

export async function DeleteLeagueSponsorship(params: { id: number }, useGlobalLoader: boolean) {
  return await APIDelete(baseLeagueUrl + "/sponsorship", params, useGlobalLoader);
}
