import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
const apiVersion = "2021-01";
const adminType = "facility-admin";
const baseTaxLineUrl = "/" + apiVersion + "/" + adminType + "/client/tax-line";

interface ITaxLine {
  id?: number;
  name?: string;
  percentage?: number;
  migrate_tax_line_id?: number;
}

interface ITaxLineRes {
  status: number;
  data: any;
}

//Get tax lines
export async function GetTaxLine(params: string, useGlobalLoader?: boolean): Promise<ITaxLineRes> {
  return await APIGet(baseTaxLineUrl + "?" + params, useGlobalLoader);
}

//Post Tax Lines
export async function PostTaxLine(params: ITaxLine, useGlobalLoader?: boolean): Promise<ITaxLineRes> {
  return await APIPost(baseTaxLineUrl, params, useGlobalLoader);
}

//Edit Tax Lines
export async function PutTaxLine(params: ITaxLine, useGlobalLoader?: boolean): Promise<ITaxLineRes> {
  return await APIPut(baseTaxLineUrl, params, useGlobalLoader);
}

//Delete Tax Lines
export async function DeleteTaxLine(params: ITaxLine, useGlobalLoader?: boolean): Promise<ITaxLineRes> {
  return await APIDelete(baseTaxLineUrl, params, useGlobalLoader);
}
