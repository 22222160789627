import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { applyDefaults, updateCreditCardFilter } from "redux/actions/filters/filters";
import { ComponentKey, OrderFilterGrouping } from "redux/reducers/filters/types";

import { useAppSelector, useAppDispatch } from "hooks/redux";

import { useFilterAccordionContext } from "components/accordion/Accordion";
import Input from "components/form/input/Input";

import "./filterList.scss";

type FilterList = {
  /** Value provided by FilterAccordionContext takes precedent if present. */
  collection: ComponentKey;
  defaultValue?: string[];
  condensed?: boolean;
};

export default function CreditCardFilter(props: FilterList) {
  const { t } = useTranslation();

  const filters = useAppSelector(store => store.orderPageFilters);
  const dispatch = useAppDispatch();
  const { collectionContext } = useFilterAccordionContext();

  const [inputState, setInputState] = useState<{ value: string; validated: boolean }>({
    value: "",
    validated: false,
  });

  useEffect(() => {
    if (inputState.validated === true) {
      dispatch(
        updateCreditCardFilter<OrderFilterGrouping>({
          collectionName: collectionContext ? collectionContext : props.collection,
          groupKey: "credit_card",
          value: [inputState.value],
          displayLabels: ["**** " + inputState.value],
        }),
      );
    } else {
      dispatch(
        updateCreditCardFilter<OrderFilterGrouping>({
          collectionName: collectionContext ? collectionContext : props.collection,
          groupKey: "credit_card",
          value: [],
          displayLabels: [],
        }),
      );
    }
  }, [inputState.value]);

  // Apply default filters
  useEffect(() => {
    if (!props.defaultValue) {
      return;
    }

    if (!filters.find(group => group.label === props.defaultValue[0])) {
      dispatch(
        applyDefaults<OrderFilterGrouping>({
          collectionName: collectionContext ? collectionContext : props.collection,
          key: "credit_card",
          value: [props.defaultValue[0]],
          displayLabels: ["•••• " + props.defaultValue[0]],
        }),
      );
    } else {
      dispatch(
        applyDefaults<OrderFilterGrouping>({
          collectionName: collectionContext ? collectionContext : props.collection,
          key: "credit_card",
          value: [],
          displayLabels: [""],
        }),
      );
    }
  }, [props.defaultValue]);

  function validateCreditCardFilter(creditCardFilter: string) {
    if (creditCardFilter?.length !== 4) {
      return false;
    } else {
      const chars = creditCardFilter.split("");
      const allCharsAreDigits = chars.every(char => /^\d$/.test(char));

      return allCharsAreDigits;
    }
  }

  return (
    <div className={`filter-list credit-card${props.condensed ? " condensed" : ""}`}>
      <Input
        labelHidden
        placeholder={t("components.dropdown.drop_filter.004")}
        value={inputState.value}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setInputState(prev => ({
            ...prev,
            value: e.target.value,
            validated: validateCreditCardFilter(e.target.value),
          }))
        }
      />
    </div>
  );
}
