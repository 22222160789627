import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useParams } from "react-router";

import { GetPrinter, PutPrinter } from "api/rpc/2022-09/facilityAdmin/facility/printer";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Form from "components/form/Form";
import Input from "components/form/input/Input";
import { Select } from "components/select/index";

import { useTranslation, Trans } from "react-i18next";
import { isEqualWith } from "lodash";

interface IProps {
  uiActions: any;
}

export default function Printer(props: IProps) {
  const { uiActions } = props;
  const { printerId }: any = useParams();
  const { t, i18n } = useTranslation();
  const { Option } = Select;

  const history = useHistory();

  const [printerStateBeforeChanges, setPrinterStateBeforeChanges] = useState(undefined);
  const [printerStateLoaded, setPrinterStateLoaded] = useState<boolean>(false);

  useEffect(() => {
    void loadPrinter();
  }, []);

  const primaryAction = {
    content: t("secure.facility.settings.printer.printer.001"),
    action: savePrinter,
  };

  const [printerState, setPrinterState] = useState({
    id: printerId,
    title: "",
    brand: "",
    ip_address: "",
    port: "",
  });

  async function loadPrinter() {
    const res = await GetPrinter(
      {
        id: printerId,
      },
      true,
    );

    if (res.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.settings.printer.printer.002"));
    }

    const printer = res.data;

    if (res.status === StatusCode.OK) {
      setPrinterState(prevState => ({
        ...prevState,
        title: printer.title,
        brand: printer.brand,
        ip_address: printer.ip_address,
        port: String(printer.port),
      }));
    }

    setPrinterStateLoaded(true);
  }

  async function savePrinter() {
    const res = await PutPrinter(printerState, true);
    if (res.status === StatusCode.OK) {
      uiActions.showSuccess(t("secure.facility.settings.printer.printer.003"));
    } else {
      uiActions.showError(res.data.message);
    }

    history.push("/admin/settings/printers");
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setPrinterState(prevState => ({ ...prevState, [id]: value }));
  }

  function unsavedChangesExist() {
    if (printerStateBeforeChanges === undefined) {
      if (printerStateLoaded) {
        setPrinterStateBeforeChanges(printerState);
      }
      return false;
    }

    return !isEqualWith(printerStateBeforeChanges, printerState);
  }

  function cancelUnsavedChanges() {
    setPrinterState(printerStateBeforeChanges);
  }

  return (
    <Page
      title={t("secure.facility.settings.printer.printer.004")}
      narrow
      // primaryAction={primaryAction}
      breadcrumbs={[
        { prefix: true, label: t("secure.facility.settings.printer.printer.005"), url: "/admin/settings/printers" },
      ]}
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: savePrinter,
        onCancel: cancelUnsavedChanges,
      }}
    >
      <Card>
        <Card.Section>
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={printerState.title}
                  label={t("secure.facility.settings.printer.printer.006")}
                  id="title"
                  onChange={handleInputChange}
                  placeholder={t("secure.facility.settings.printer.printer.007")}
                />
                <Input
                  value={printerState.brand}
                  label={t("secure.facility.settings.printer.printer.008")}
                  id="brand"
                  onChange={handleInputChange}
                  placeholder={t("secure.facility.settings.printer.printer.009")}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Input
                  value={printerState.ip_address}
                  label={t("secure.facility.settings.printer.printer.010")}
                  id="ip_address"
                  onChange={handleInputChange}
                  placeholder="000.000.000.000"
                />
                <Input
                  value={printerState.port}
                  label={t("secure.facility.settings.printer.printer.011")}
                  id="port"
                  onChange={handleInputChange}
                  placeholder="8080"
                />
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
      </Card>
    </Page>
  );
}
