import { createContext, useContext } from "react";
import { ISelectedEditInput } from "components/draggableEditInput/DraggableEditInput";
import {
  IUnsavedChangesContainer,
  IUnsavedChangesDispatchActions,
} from "hooks/useUnsavedChangesContainer/useUnsavedChangesContainer";
import { TTournamentRegistrationInputRows } from "./tabs/fee/RegistrationFeeInputs";

export type TournamentRegistrationHandleUpdateInput = (
  id: any,
  label: string,
  helpText: string,
  value: string[] | null,
  isRequired?: boolean,
) => void;

interface ITournamentRegistrationContext {
  selectedInput: ISelectedEditInput;
  setSelectedInput: React.Dispatch<React.SetStateAction<ISelectedEditInput>>;
  inputRowsContainer: IUnsavedChangesContainer<TTournamentRegistrationInputRows>;
  inputRowsContainerActions: IUnsavedChangesDispatchActions<TTournamentRegistrationInputRows>;
  handleUpdateInput: TournamentRegistrationHandleUpdateInput;
  closeInputEditModal: () => void;
}

const defaultTournamentRegistrationContext: ITournamentRegistrationContext = {
  selectedInput: undefined,
  setSelectedInput: undefined,
  inputRowsContainer: undefined,
  inputRowsContainerActions: undefined,
  handleUpdateInput: undefined,
  closeInputEditModal: undefined,
};

export const TournamentRegistrationContext = createContext<ITournamentRegistrationContext>(
  defaultTournamentRegistrationContext,
);
export const useTournamentRegistrationContext = () => useContext(TournamentRegistrationContext);
