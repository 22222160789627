import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { TEventRound } from "redux/reducers/models/league";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/tournament/scoring/round";

export type TGetTournamentRound = {
  tournament_id: string | number;
  id?: string | number;
  scoresheet_id?: string | number;
  round_id?: string | number;
  /** Return round skin sheets with it */
  skins_sheets?: boolean;
};

export type TPostTournamentRound = {
  scoresheet_id: string | number;
  customer_id: string | number;
};

/** Same structure as TPutLeagueRound */
export type TPutTournamentRound = {
  id: string | number;
  tee_deck_id?: number;
  course_id?: number;
  holes?: 9 | 18;
  division_front_id?: number;
  division_back_id?: number;
  hole_scores?: { [x: number]: number | null }; // { hole#: score } key-value pairing
  round_handicap?: number;
  handicap_allowance?: number;
  attestor_id?: number;
  attested?: boolean;
  played_alone?: boolean;
  submitted?: boolean;
  points_bonus?: number;
  points_participation?: number;
};

type TTournamentRoundRes = {
  id: number;
  league_id?: number;
  round: TEventRound;
  round_id: number;
  scoresheet_id: number;
};

export async function GetTournamentRound(params: TGetTournamentRound, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<TTournamentRoundRes[]>(baseUrl, params, useGlobalLoader, token);
}

export async function PostTournamentRound(params: TPostTournamentRound, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

export async function PutTournamentRound(params: TPutTournamentRound, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

export async function DeleteTournamentRound(params: { round_id: string | number }, useGlobalLoader: boolean) {
  return await APIDelete(baseUrl, params, useGlobalLoader);
}
