import React, { forwardRef, useEffect, useRef, useState } from "react";
import { LocaleCurrency } from "helpers/Locale";

import "elements/register/register.scss";
import Sheet from "components/sheet/Sheet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { range } from "lodash";
import { IProduct, IVariant } from "redux/reducers/models/product";
import classNames from "classnames";
import RightClickMenu from "components/rightClickMenu/RightClickMenu";
import useModal from "hooks/modals/useModal";
import Portal from "elements/Portal";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import { displayCurrency } from "helpers/Helpers";
import { useRegisterContext } from "./RegisterContext";
import ReactDOM from "react-dom";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { showError, showSuccess } from "redux/actions/ui";
import { loadCart, loadTableCart, postCartVariants, setActiveTable } from "redux/actions/cart";
import { ICart, ICartLineItem, IDiscountLines } from "redux/reducers/models/cart";
import { IModifierGroup } from "api/rpc/facilityAdmin/modifierGroups";
import SelectModifierSheet from "components/selectModifierSheet/SelectModifierSheet";
import axios, { CancelToken } from "axios";
import { GetModifierGroup } from "api/rpc/2024-04/facilityAdmin/product/modifier/modifierGroup";
import { IAPIResponse, StatusCode } from "api/protocols";
import { PRODUCT_FOLDERS_KEY } from "../../settings/folders/Folders";
import { IFolder } from "api/rpc/2022-09/facilityAdmin/product/folder";
import Spin from "components/spin/spin";
import { PostDiscountLine } from "api/rpc/2022-09/facilityAdmin/product/discount";
import { StateType } from "../../teesheet/newTeeSheetDetails/NewTeeSheetDetails";
import { PostLineItemToCart } from "api/rpc/2024-04/facilityAdmin/order/cart/lineItem";

interface IProps {
  products: IProduct[];
  useGridPositions: boolean;
  disabled?: boolean;
  cart: ICart;
  selectedFolder: IFolder;
  //Update state function for state cart (Tee Sheet Details)
  updateStateCart?: <T>(newState: Partial<T>, type: StateType) => void;

  /**Message that displays when products are loading */
  productsLoadingMessage: string;

  /**Meal course id selected on Table Service */
  selectedCourseId?: number;
}

interface IModifierState {
  sheetOpen: boolean;
  allModifierGroups: IModifierGroup[];
  selectedModifierVariants: { [key: string]: IVariant[] };
}

export default function Products(props: IProps) {
  const GRID_COL = 6;
  const [gridRows, setGridRows] = useState<number>(0);
  const {
    cart,
    disabled,
    products,
    useGridPositions,
    selectedFolder,
    productsLoadingMessage,
    updateStateCart,
    selectedCourseId,
  } = props;

  const { state, updateModal, closeModal } = useModal({ product: undefined as IProduct });
  const [variantSheetOpen, setVariantSheetOpen] = useState<boolean>(false);
  const {
    registerState,
    updateRegisterState,
    addLoadingVariant,
    removeLoadingVariant,
    addLineItemUpdating,
    removeLineItemUpdating,
  } = useRegisterContext();
  const dispatch = useAppDispatch();
  const { cartStore } = useAppSelector(store => store);

  const [modifierState, setModifierState] = useState<IModifierState>({
    sheetOpen: false,
    allModifierGroups: [],
    selectedModifierVariants: null,
  });

  useEffect(() => {
    const positions = products?.map(product => product.folder_position);

    if (positions === undefined || positions.length === 0) {
      setGridRows(0);
    } else {
      const maxPosition = positions.reduce((prev, next) => (prev < next ? next : prev));
      const gridRows = Math.ceil(maxPosition / GRID_COL);
      setGridRows(gridRows);
    }
  }, [products]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (registerState?.selectedVariant && registerState?.selectedProduct) {
      //Add new modifier item
      void openModifierGroups(registerState?.selectedProduct?.id, source.token);
    } else if (registerState?.modifierLineItem) {
      //Add modifiers to existing parent item
      void openModifierGroups(registerState?.modifierLineItem?.product_id, source.token);
    }
    return () => source.cancel();
  }, [registerState?.selectedVariant, registerState?.modifierLineItem]);

  function onProductClick(product: IProduct) {
    const variants = product?.variants?.filter(variant => variant?.facility_access === true);
    //More than one variant -> open Variants Modal
    if (variants?.length > 1) {
      ReactDOM.unstable_batchedUpdates(() => {
        setVariantSheetOpen(true);
        updateRegisterState({ variants: variants, selectedProduct: product });
      });
    } else {
      if (variants[0]) {
        //  Open modifier modal if product has modifiers
        if (product?.has_modifiers) {
          updateRegisterState({ selectedProduct: product, selectedVariant: variants[0] });
          return;
        }
        void addVariantToCart(variants[0], product);
      } else {
        dispatch(showError("No variants available"));
      }
    }
  }

  function updateActiveTable(updatedCart: ICart) {
    if (cartStore?.activeTable) {
      const activeTable = { ...cartStore?.activeTable };
      const updatedTable = {
        ...activeTable,
        carts: activeTable?.carts?.map(tableCart => (tableCart?.id === updatedCart?.id ? updatedCart : tableCart)),
      };
      dispatch(setActiveTable(updatedTable));
    }
  }

  async function addVariantToCart(variant: IVariant, product: IProduct) {
    void onVariantModalClose();
    void addLoadingVariant(variant, product, undefined, selectedCourseId);
    //State cart
    if (updateStateCart) {
      const postVariantRes = await PostLineItemToCart(
        { cart_id: cart?.id, variants: [{ variant_id: variant?.id, quantity: 1, parent_id: null }] },
        false,
      );
      void removeLoadingVariant(variant?.id);
      if (postVariantRes?.status !== StatusCode?.OK) {
        dispatch(showError("Error adding line item to cart. Please try again"));
      } else {
        void updateStateCart({ cart: postVariantRes?.data }, StateType.CheckInState);
        dispatch(showSuccess("Successfully added line item to cart", 2000));
      }
    } else {
      //Redux Cart
      const updatedCart = await dispatch(
        postCartVariants(
          {
            cart_id: cart?.id,
            variants: [{ variant_id: variant?.id, quantity: 1, parent_id: null, kitchen_meal_id: selectedCourseId }],
          },
          false,
        ),
      );
      void removeLoadingVariant(variant?.id);
      if (!updatedCart) {
        dispatch(showError("Error adding line item to cart. Please try again"));
      } else if (updatedCart && cartStore?.activeTable) {
        void updateActiveTable(updatedCart);
      }
    }
  }

  async function openModifierGroups(productId: number, cancelToken?: CancelToken) {
    ReactDOM.unstable_batchedUpdates(() => {
      setVariantSheetOpen(false);
      setModifierState(prevState => ({ ...prevState, sheetOpen: true }));
    });

    //Get folder in local storage
    const storedProductFolders: IFolder[] = JSON.parse(localStorage.getItem(PRODUCT_FOLDERS_KEY)) ?? [];
    const storedProductFolder = storedProductFolders.find(productFolder => productFolder?.id === selectedFolder?.id);
    // Get selected product in local storage
    const storedProduct = storedProductFolder?.products?.find(product => product?.id === productId);
    let modifierGroups: Array<IModifierGroup>;
    let loadModifierGroups = false;

    if (storedProductFolder) {
      if (storedProduct && storedProduct?.modifier_groups && storedProduct?.modifier_groups?.length > 0) {
        //Modifier group found in local storage
        modifierGroups = storedProduct?.modifier_groups;
      } else {
        loadModifierGroups = true;
      }
    } else {
      loadModifierGroups = true;
    }

    // Modifier groups not found, load them
    if (loadModifierGroups) {
      const modifierGroupResponse = await GetModifierGroup(
        { product_id: productId, extended: true },
        false,
        cancelToken,
      );
      if (modifierGroupResponse.status !== StatusCode.OK) {
        if (cancelToken && cancelToken?.reason) {
          return;
        }
        dispatch(showError("Loading modifier group failed"));
        return;
      }
      modifierGroups = modifierGroupResponse?.data;
      if (storedProductFolder && storedProduct) {
        const folderIndex = storedProductFolders?.findIndex(folder => folder?.id === storedProductFolder?.id);
        if (folderIndex !== -1 && storedProduct) {
          const updatedProducts = storedProductFolder?.products?.map(product => {
            if (product?.id === storedProduct?.id) {
              return {
                ...storedProduct,
                modifier_groups: modifierGroups,
              };
            }
            return product;
          });
          storedProductFolders[folderIndex] = { ...storedProductFolder, products: updatedProducts };
          //Set modifier groups in local storage
          localStorage.setItem(PRODUCT_FOLDERS_KEY, JSON.stringify(storedProductFolders));
        }
      }
    }

    const selectedModifiers = { ...modifierState?.selectedModifierVariants };

    //Preselect the default modifier groups
    modifierGroups?.forEach((modifier: IModifierGroup) => {
      selectedModifiers[modifier.id.toString()] = [];
      modifier.variants.forEach((variant: IVariant) => {
        if (variant.modifier_group.default) {
          selectedModifiers[modifier.id.toString()].push(variant);
        }
      });
    });

    setModifierState(prevState => ({
      ...prevState,
      selectedModifierVariants: selectedModifiers,
      allModifierGroups: modifierGroups,
    }));
  }

  function checkModifiers(variant: IVariant, product: IProduct) {
    //  Open modifier modal if product has modifiers
    if (product?.has_modifiers) {
      updateRegisterState({ selectedProduct: product, selectedVariant: variant });
    } else {
      void addVariantToCart(variant, product);
    }
  }

  function onVariantModalClose() {
    ReactDOM.unstable_batchedUpdates(() => {
      setVariantSheetOpen(false);
      updateRegisterState({ variants: null, selectedProduct: null, selectedVariant: null });
    });
  }

  async function onModifiersOk() {
    //Modify new line item
    if (registerState?.selectedProduct && registerState?.selectedVariant) {
      const selectedVariant = { ...registerState?.selectedVariant };
      const selectedProduct = { ...registerState?.selectedProduct };
      const modifiersToAdd: Array<{ variant_id: number; quantity: number }> = [];
      const modifierVariants: Array<IVariant> = [];

      for (const key in modifierState.selectedModifierVariants) {
        modifierState.selectedModifierVariants[key]?.forEach(variant => {
          modifiersToAdd.push({ variant_id: variant?.id, quantity: 1 });
          modifierVariants.push({ ...variant, quantity: 1 });
        });
      }

      const variantParam = {
        cart_id: cartStore?.cart?.id,
        variant_id: selectedVariant?.id,
        quantity: 1,
        modifiers: modifiersToAdd,
        kitchen_meal_id: selectedCourseId,
      };
      void addLoadingVariant(selectedVariant, selectedProduct, modifierVariants, selectedCourseId);
      void handleCloseModifierGroups();
      updateRegisterState({ selectedProduct: null, selectedVariant: null });
      //State cart
      if (updateStateCart) {
        const lineItemRes = await PostLineItemToCart({ cart_id: cart?.id, variants: [variantParam] }, false);
        void removeLoadingVariant(selectedVariant?.id);
        if (lineItemRes?.status !== StatusCode?.OK) {
          dispatch(showError("Error adding line item to cart. Please try again"));
        } else {
          void updateStateCart({ cart: lineItemRes?.data }, StateType.CheckInState);
          dispatch(showSuccess("Successfully added line item to cart", 2000));
        }
      } else {
        //Redux cart
        const updatedCart = await dispatch(
          postCartVariants({ cart_id: cartStore?.cart?.id, variants: [variantParam] }, false),
        );
        void removeLoadingVariant(selectedVariant?.id);
        if (!updatedCart) {
          dispatch(showError("Error adding line item to cart. Please try again"));
          updateRegisterState({ selectedProduct: null, selectedVariant: null });
        } else if (updatedCart && cartStore?.activeTable) {
          void updateActiveTable(updatedCart);
        }
      }
    } else if (registerState?.modifierLineItem) {
      //Modify existing line item
      const lineItem = { ...registerState?.modifierLineItem };
      const modifiersToAdd: Array<{ variant_id: number; quantity: number; parent_id?: number }> = [];
      const modifierVariants: Array<IVariant & { parent_id?: number }> = [];

      for (const key in modifierState.selectedModifierVariants) {
        modifierState.selectedModifierVariants[key]?.forEach(variant => {
          modifiersToAdd.push({ variant_id: variant?.id, quantity: 1, parent_id: lineItem?.id });
          modifierVariants.push({ ...variant, quantity: 1, parent_id: lineItem?.id });
          void addLoadingVariant({ ...variant, quantity: 1, parent_id: lineItem?.id });
        });
      }

      void handleCloseModifierGroups();
      updateRegisterState({ modifierLineItem: null });
      let updatedCart: ICart;
      //Start cart
      if (updateStateCart) {
        const lineItemRes = await PostLineItemToCart({ cart_id: cart?.id, variants: modifiersToAdd }, false);
        modifierVariants?.forEach(variant => {
          void removeLoadingVariant(variant?.id);
        });
        if (lineItemRes?.status !== StatusCode?.OK) {
          dispatch(showError("Error adding line item to cart. Please try again"));
        } else {
          void updateStateCart({ cart: lineItemRes?.data }, StateType.CheckInState);
          updatedCart = lineItemRes?.data;
          dispatch(showSuccess("Successfully added line item to cart", 2000));
        }
      } else {
        //Redux cart
        updatedCart = await dispatch(
          postCartVariants({ cart_id: cartStore?.cart?.id, variants: modifiersToAdd }, false),
        );
        modifierVariants?.forEach(variant => {
          void removeLoadingVariant(variant?.id);
        });
        if (!updatedCart) {
          dispatch(showError("Error modifiers to cart. Please try again"));
          updateRegisterState({ selectedProduct: null, selectedVariant: null });
        } else if (updatedCart && cartStore?.activeTable) {
          void updateActiveTable(updatedCart);
        }
      }

      if (updatedCart) {
        //Retroactively apply discounts
        void applyDiscountsToModifiers(lineItem, updatedCart);
      }
    }
  }

  function handleCloseModifierGroups() {
    ReactDOM.unstable_batchedUpdates(() => {
      updateRegisterState({ selectedProduct: null, selectedVariant: null, modifierLineItem: null });
      setModifierState(prevState => ({
        ...prevState,
        sheetOpen: false,
        allModifierGroups: [],
        selectedModifierVariants: null,
      }));
    });
  }

  function addModifierVariant(modifierGroup: IModifierGroup, variant: IVariant, selected: boolean) {
    const selectedModifiers = { ...modifierState?.selectedModifierVariants };
    if (modifierGroup.multi_select) {
      if (selected && selectedModifiers[modifierGroup.id.toString()].length >= 1) {
        if (modifierGroup.required && selectedModifiers[modifierGroup.id.toString()].length === 1) {
          return;
        }
        const selectedGroup = selectedModifiers[modifierGroup.id.toString()];
        selectedModifiers[modifierGroup.id.toString()] = selectedGroup.filter(filtered => filtered.id !== variant.id);
      } else {
        selectedModifiers[modifierGroup.id.toString()] = [...selectedModifiers[modifierGroup.id.toString()], variant];
      }
    } else {
      selectedModifiers[modifierGroup.id.toString()] = [variant];
    }

    setModifierState(prevState => ({ ...prevState, selectedModifierVariants: selectedModifiers }));
  }

  function disableSelectingModifiers() {
    //Disable checks for adding modifiers to existing line item
    if (registerState?.modifierLineItem) {
      const lineItem = { ...registerState?.modifierLineItem };
      if (lineItem && cart?.line_items && modifierState?.allModifierGroups?.length > 0) {
        const requiredModifierGroups = modifierState?.allModifierGroups?.filter(
          modifierGroup => modifierGroup.required,
        );

        const lineItemModifiers = cart?.line_items.filter(cartLineItem => {
          return cartLineItem.id !== lineItem.id && lineItem.id === cartLineItem.parent_id;
        });

        const modalRequiredModifierGroups: IModifierGroup[] = requiredModifierGroups.filter(modifierGroup => {
          return !modifierGroup.variants?.some(variant =>
            lineItemModifiers.some(lineItem => lineItem.variant_id === variant.id),
          );
        });

        const allRequiredModifiersSelected = modalRequiredModifierGroups.every(modifierGroup => {
          return modifierState?.selectedModifierVariants[modifierGroup.id.toString()]?.length > 0;
        });

        return !allRequiredModifiersSelected;
      } else {
        return true;
      }
    } else {
      // Disable checks for adding modifiers to new line item
      if (registerState?.selectedVariant && modifierState?.allModifierGroups?.length > 0) {
        const requiredModifierGroups = modifierState?.allModifierGroups?.filter(
          modifierGroup => modifierGroup.required,
        );
        const allRequiredModifiersSelected = requiredModifierGroups?.every(
          modifierGroup => modifierState?.selectedModifierVariants[modifierGroup.id.toString()]?.length > 0,
        );
        return !allRequiredModifiersSelected;
      } else {
        return true;
      }
    }
  }

  async function applyDiscountsToModifiers(lineItem: ICartLineItem, cart: ICart) {
    const cartLineItems = [...cart?.line_items]?.filter(cartLineItem => cartLineItem?.parent_id === lineItem?.id);
    //Get the discounts to apply
    const discounts = getDiscountsToApply(lineItem?.id, cart);
    if (cartLineItems.length > 0 && discounts?.length > 0) {
      let responses: Array<Omit<IAPIResponse, "message">>;
      void addLineItemUpdating(lineItem?.id);
      void handleCloseModifierGroups();
      const discountsToApply: Array<{
        discount_id: number;
        cart_id: number;
        cart_line_item_id: number;
        value: number;
        value_type: "percent" | "fixed_amount";
      }> = [];
      cartLineItems?.forEach(cartLineItem => {
        //Check if the line item's parent id matches the selected line item's id
        //Also check if the discount line has already been added to the line item
        if (
          !cart?.discount_lines?.some(discount_line => discount_line?.cart_line_item_id === cartLineItem?.id) &&
          cartLineItem.price > 0
        ) {
          discounts?.forEach(discount => {
            discountsToApply.push({
              discount_id: discount.discount_id,
              cart_id: cartLineItem.cart_id,
              cart_line_item_id: cartLineItem.id,
              value: discount.value ? Number(discount.value) : undefined,
              value_type: discount.value_type,
            });
          });
        }
      });
      if (discountsToApply?.length > 0) {
        responses = await Promise.all(
          discountsToApply?.map(discountParams => PostDiscountLine(discountParams, false)),
        );
        if (responses?.some(res => res?.status !== StatusCode.OK)) {
          dispatch(showError("Error adding a discount"));
        }
      }
      void removeLineItemUpdating(lineItem?.id);

      //State cart
      if (updateStateCart) {
        if (responses) {
          const updatedCart = responses[0]?.data;
          void updateStateCart({ cart: updatedCart }, StateType.CheckInState);
        }
      } else {
        //Redux cart
        if (responses) {
          const updatedCart = responses[0]?.data;
          //Set cart in cartStore
          void dispatch(loadTableCart({ tableCart: updatedCart }, false));
          if (cartStore?.activeTable) {
            void updateActiveTable(updatedCart);
          }
        } else {
          void dispatch(loadCart({ id: cartStore?.cart?.id }, true));
        }
      }
    }
  }

  function getDiscountsToApply(parentId: number, cart: ICart) {
    const cartDiscounts = [...cart?.discount_lines];
    let discountsToApply: Array<IDiscountLines> = [];
    //Get discounts that are applied to the parent item and are of type 'percent'
    if (cartDiscounts.length > 0) {
      discountsToApply = cartDiscounts?.filter(
        discount => discount?.cart_line_item_id === parentId && discount?.value_type === "percent",
      );
    }
    return discountsToApply;
  }

  function renderProducts() {
    if (useGridPositions) {
      return range(1, GRID_COL * gridRows + 1).map((position, index) => {
        const product = products.find(product => product.folder_position === position);

        if (product) {
          return (
            <SingleProductRender
              key={index}
              product={product}
              disabled={disabled}
              onContentClick={onProductClick}
              onMenuClick={product => updateModal({ isOpen: true, product: product })}
            />
          );
        } else {
          return <div key={index} className="product_container" />;
        }
      });
    } else {
      return products.map((product, index) => {
        return (
          <SingleProductRender
            key={index}
            product={product}
            disabled={disabled}
            onContentClick={onProductClick}
            onMenuClick={product => updateModal({ isOpen: true, product: product })}
          />
        );
      });
    }
  }

  return productsLoadingMessage ? (
    <div style={{ display: "flex", height: "100%" }}>
      <div className="text-center m-auto">
        <div style={{ height: "32px" }}>
          <Spin />
        </div>
        <div>{productsLoadingMessage}</div>
      </div>
    </div>
  ) : (
    <div>
      <div className="ui-register-products">{renderProducts()}</div>
      {/*Variant modal */}
      <Portal isMounted={variantSheetOpen}>
        <Sheet
          size={"small"}
          title={"Select Product Variant"}
          open={variantSheetOpen}
          onCancel={onVariantModalClose}
          closable
          stacked
        >
          <div className={classNames("flex flex-col py-2 px-3 mb-4 ui-register-product-variant-title-container")}>
            <span className="ui-register-product-variant-title">
              {registerState?.selectedProduct?.preferred_title
                ? registerState?.selectedProduct?.preferred_title
                : registerState?.selectedProduct?.title}
            </span>
            <span className="ui-register-product-variant-sub_title">{`${registerState?.variants?.length} ${
              registerState?.variants?.length === 1 ? "Variant" : "Variants"
            }`}</span>
          </div>
          <div className="flex-1 overflow-auto h-full">
            {registerState?.variants?.map(variant => {
              return (
                <div
                  key={String(variant.id)}
                  className={classNames("ui-register-product-variant-container", {
                    "ui-register-product-variant-disabled": variant?.meta?.cannot_be_sold,
                  })}
                  onClick={
                    !variant?.meta?.cannot_be_sold
                      ? () => checkModifiers(variant, registerState?.selectedProduct)
                      : undefined
                  }
                >
                  <div className="flex w-full flex-col">
                    <span className="ui-register-product-variant-container-title">{variant.title}</span>
                    <span className="ui-register-product-variant-container-sub_title">{`${
                      variant.inventory_item?.inventory_level?.quantity_available
                        ? `${String(variant.inventory_item.inventory_level.quantity_available)} in stock`
                        : ""
                    }`}</span>
                  </div>

                  <div className="mr-6 ui-register-product-variant-container-price_title">
                    <LocaleCurrency currency="cad" amount={variant.price} />
                  </div>
                  <FontAwesomeIcon icon={["fad", "cart-plus"]} className="ui-register-product-variant-icon" />
                </div>
              );
            })}
          </div>
        </Sheet>
      </Portal>

      {/* Details modal */}
      <Portal isMounted={state.isOpen}>
        <Sheet
          title={`${state.product?.title} Details`}
          open={state.isOpen}
          onCancel={closeModal}
          closable
          hideCancelButton
          size="small"
          stacked
        >
          {!state.product ? null : (
            <>
              {state.product.track_inventory ? (
                // Inventory is tracked.  Column added to end of table
                <DataTable
                  columns={
                    state.product.variant_count > 1
                      ? [
                          { label: "" }, // variant.title
                          { label: "Cost", justify: "right" }, // TODO: Translation
                          { label: "Price", justify: "right" }, // TODO: Translation
                          { label: "Inventory", justify: "right" }, // TODO: Translation
                        ]
                      : [
                          { label: "Cost", justify: "right" }, // TODO: Translation
                          { label: "Price", justify: "right" }, // TODO: Translation
                          { label: "Inventory", justify: "right" }, // TODO: Translation
                        ]
                  }
                >
                  {state.product.variants.map(variant => (
                    <tr key={variant.id}>
                      {state.product.variant_count > 1 ? <td>{variant.title}</td> : null}
                      <td align="right">{displayCurrency("cad", variant.cost)}</td>
                      <td align="right">{displayCurrency("cad", variant.price)}</td>
                      <td align="right">{variant.inventory_item.inventory_level?.quantity_available}</td>
                    </tr>
                  ))}
                </DataTable>
              ) : (
                // NO Inventory count column
                <DataTable
                  columns={
                    state.product.variant_count > 1
                      ? [
                          { label: "" }, // variant.title
                          { label: "Cost", justify: "right" }, // TODO: Translation
                          { label: "Price", justify: "right" }, // TODO: Translation
                        ]
                      : [
                          { label: "Cost", justify: "right" }, // TODO: Translation
                          { label: "Price", justify: "right" }, // TODO: Translation
                        ]
                  }
                >
                  {state.product.variants.map(variant => (
                    <tr key={variant.id}>
                      {state.product.variant_count > 1 ? <td>{variant.title}</td> : null}
                      <td align="right">{displayCurrency("cad", variant.cost)}</td>
                      <td align="right">{displayCurrency("cad", variant.price)}</td>
                    </tr>
                  ))}
                </DataTable>
              )}
            </>
          )}
        </Sheet>
      </Portal>

      <SelectModifierSheet
        open={modifierState.sheetOpen}
        onCancel={() => handleCloseModifierGroups()}
        onOk={() => onModifiersOk()}
        okDisabled={disableSelectingModifiers()}
        allModifierGroups={modifierState.allModifierGroups}
        selectedModifierGroupVariants={modifierState.selectedModifierVariants}
        addModifierVariant={addModifierVariant}
      />
    </div>
  );
}

function SingleProductRender(props: {
  product: IProduct;
  disabled: boolean;
  onContentClick: (product: IProduct) => void;
  onMenuClick: (product: IProduct) => void;
}) {
  const { product, disabled, onContentClick } = props;
  return (
    <RightClickMenu
      sections={[
        [
          {
            icon: "circle-info",
            title: "Product Details", // TODO: Translation
            action: () => props.onMenuClick(product),
            // disabled: String(segment.blocked_type) === "open",
          },
        ],
      ]}
    >
      <div className="product_container">
        <div
          className={"product_content " + (disabled || product?.meta?.cannot_be_sold ? "product_content-disabled" : "")}
          style={product.meta?.button_color ? { background: product?.meta?.button_color } : {}}
          onClick={disabled || product?.meta?.cannot_be_sold ? undefined : () => onContentClick(product)}
        >
          <div>
            <div className="product-title">{product.preferred_title ? product.preferred_title : product.title}</div>
            {product.subtitle ? <div className="product-vendor">{product.subtitle}</div> : null}
            <div className="product-vendor">{product.vendor_title}</div>
          </div>
          <div className="product-details">
            <div className="product-stock">
              {product?.variant_count === 1 && product?.variants[0]?.inventory_item?.tracked
                ? product.variants[0]?.inventory_item?.inventory_level?.quantity_available
                : null}
            </div>
            <div className="product-price">
              {product.variants?.length === 1 ? `$${String(product?.variants[0]?.price?.toFixed(2))}` : ""}
            </div>
          </div>
        </div>
      </div>
    </RightClickMenu>
  );
}
