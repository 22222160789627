import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { StatusCode } from "api/protocols";
import { PostTeeSheetTemplate, IIntervalSection } from "api/rpc/2024-04/facilityAdmin/teesheet/teesheetTemplate";
import { showError } from "redux/actions/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import { Select } from "components/select/index";
import TimePick from "components/timePick/TimePick";
import TextField from "components/form/textField/TextField";
import { ButtonNew as Button } from "components/buttonNew";
import Popup from "components/popup/Popup";

import "./TeeSheetTemplate.scss";

const TeeSheetTemplateNew: React.FC = () => {
  const { Option } = Select;
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const facilityStore = useAppSelector(store => store.facilityStore);
  const dispatch = useAppDispatch();

  const [filterState, setFilterState] = useState({
    turn_time_string: "02:00",
  });

  const [templateState, setTemplateState] = useState({
    name: "",
    format: "standard",
    start_time: "06:00",
    end_time: "20:00",
    turn_time: 120,
    holes: 18,
    interval: 8,
    course_id: undefined,
    description: "",
    interval_sections: [
      {
        start_time: "06:00",
        end_time: "20:00",
        interval_1: 8,
        interval_2: -1,
      },
    ],
  });

  const [warningPopupVisible, setWarningPopupVisible] = useState<boolean>(false);

  const courseOptions = facilityStore.facility?.courses.map((course: any) => {
    return { label: course.full_name, value: course.id };
  });

  async function postTemplate() {
    if (!checkCrossover()) {
      void formatSections();
      const templateRes = await PostTeeSheetTemplate(templateState, true);
      if (templateRes.status !== StatusCode.OK) {
        dispatch(showError(templateRes.data));
        return;
      }

      if (templateRes.status === StatusCode.OK) {
        history.push("/admin/settings/tee-sheet/template/" + String(templateRes.data?.id));
        return;
      }
    }

    return;
  }

  function checkCrossover(): boolean {
    const sections = templateState.interval_sections;

    let prevEndTime = "00:00";
    let crossoverExists = false;

    for (let i = 0; i < sections.length; i++) {
      if (sections[i].start_time < prevEndTime) {
        crossoverExists = true;
        setWarningPopupVisible(true);
      }
      prevEndTime = sections[i].end_time;
    }

    return crossoverExists;
  }

  function formatSections() {
    const sections = templateState.interval_sections;

    let prevEndTime = "00:00";

    for (let i = 0; i < sections.length; i++) {
      if (sections[i].interval_2 < 0) {
        sections[i].interval_2 = null;
      }
      if (sections[i].start_time <= prevEndTime) {
        setWarningPopupVisible(true);
      }
      prevEndTime = sections[i].end_time;
    }

    setTemplateState(prevState => ({ ...prevState, interval_sections: sections }));
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setTemplateState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleTurnTimeChange(timeString: any) {
    if (!timeString) {
      dispatch(showError("Error updating turn time"));
      return;
    }
    const array = timeString.split(":");

    const hours = Number(array[0]);
    const minutes = Number(array[1]);

    const hoursToMinutes = hours * 60;
    const totalMinutes = hoursToMinutes + minutes;
    setFilterState(prevState => ({ ...prevState, turn_time_string: timeString }));
    setTemplateState(prevState => ({ ...prevState, turn_time: totalMinutes }));
  }

  function handleDropDownChange(value: any, property: string) {
    setTemplateState(prevState => ({ ...prevState, [property]: value }));
  }

  function addIntervalSection() {
    const sections = templateState.interval_sections;

    sections.push({
      start_time: sections[sections.length - 1].end_time,
      end_time: "20:00",
      interval_1: 8,
      interval_2: null,
    });

    setTemplateState(prevState => ({ ...prevState, interval_sections: sections }));
  }

  function removeIntervalSection(index: number) {
    const sections = templateState.interval_sections;

    sections.splice(index, 1);

    setTemplateState(prevState => ({ ...prevState, interval_sections: sections }));
  }

  function handleSectionStartTime(value: string, index: number) {
    const sections = templateState.interval_sections;

    sections[index].start_time = value;

    setTemplateState(prevState => ({ ...prevState, interval_sections: sections }));
  }

  function handleSectionEndTime(value: string, index: number) {
    const sections = templateState.interval_sections;

    sections[index].end_time = value;

    setTemplateState(prevState => ({ ...prevState, interval_sections: sections }));
  }

  function handleSectionInterval1(value: number, index: number) {
    const sections = templateState.interval_sections;

    sections[index].interval_1 = value;

    setTemplateState(prevState => ({ ...prevState, interval_sections: sections }));
  }

  function handleSectionInterval2(value: number, index: number) {
    const sections = templateState.interval_sections;

    sections[index].interval_2 = value;

    setTemplateState(prevState => ({ ...prevState, interval_sections: sections }));
  }

  const holeOptions = [
    { label: t("secure.facility.settings.tee_sheets.tee_sheet_template_new.001"), value: 18 },
    { label: t("secure.facility.settings.tee_sheets.tee_sheet_template_new.002"), value: 9 },
    { label: t("secure.facility.settings.tee_sheets.tee_sheet_template_new.003"), value: 27 },
  ];

  const formatOptions = [
    { label: t("secure.facility.settings.tee_sheets.tee_sheet_template_new.004"), value: "standard", disbaled: false },
    // { label: "Crossover", value: "crossover", disbaled: true },
  ];

  const intervalOptions = [
    { label: t("secure.facility.settings.tee_sheets.tee_sheet_template_new.005"), value: 7 },
    //{ label: t("secure.facility.settings.tee_sheets.tee_sheet_template_new.006"), value: 7.5 },
    { label: t("secure.facility.settings.tee_sheets.tee_sheet_template_new.007"), value: 8 },
    //{ label: t("secure.facility.settings.tee_sheets.tee_sheet_template_new.008"), value: 8.5 },
    { label: t("secure.facility.settings.tee_sheets.tee_sheet_template_new.009"), value: 9 },
    //{ label: t("secure.facility.settings.tee_sheets.tee_sheet_template_new.010"), value: 9.5 },
    { label: t("secure.facility.settings.tee_sheets.tee_sheet_template_new.011"), value: 10 },
    //{ label: t("secure.facility.settings.tee_sheets.tee_sheet_template_new.012"), value: 10.5 },
    { label: t("secure.facility.settings.tee_sheets.tee_sheet_template_new.013"), value: 11 },
    //{ label: t("secure.facility.settings.tee_sheets.tee_sheet_template_new.014"), value: 11.5 },
    { label: t("secure.facility.settings.tee_sheets.tee_sheet_template_new.015"), value: 12 },
  ];

  const primaryAction = {
    content: t("secure.facility.settings.tee_sheets.tee_sheet_template_new.016"),
    action: () => postTemplate(),
  };

  return (
    <Page
      title={t("secure.facility.settings.tee_sheets.tee_sheet_template_new.017")}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.tee_sheets.tee_sheet_template_new.018"),
          url: "/admin/settings/tee-sheet/template",
        },
      ]}
    >
      <Card>
        <Card.Section>
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={templateState.name || ""}
                  label={t("secure.facility.settings.tee_sheets.tee_sheet_template_new.019")}
                  id="name"
                  onChange={handleInputChange}
                  placeholder={t("secure.facility.settings.tee_sheets.tee_sheet_template_new.020")}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Input
                  value={templateState.turn_time || ""}
                  label={t("secure.facility.settings.tee_sheets.tee_sheet_template_new.026")}
                  id="turn_time"
                  onChange={handleInputChange}
                  placeholder={t("secure.facility.settings.tee_sheets.tee_sheet_template_new.026")}
                  suffix="minutes"
                  type="number"
                />
                {/* <TimePick
                  value={filterState.turn_time_string}
                  onChange={timeString => handleTurnTimeChange(timeString)}
                  label={t("secure.facility.settings.tee_sheets.tee_sheet_template_new.026")}
                  size="large"
                  helpText={"What is your turn time? (hh:mm)"}
                  status={templateState.turn_time === undefined ? "warning" : null}
                  hideMeridiem={24}
                /> */}
                <Select
                  defaultValue={templateState.holes}
                  label={t("secure.facility.settings.tee_sheets.tee_sheet_template_new.028")}
                  onChange={(value: any) => handleDropDownChange(value, "holes")}
                >
                  {holeOptions?.map((holeOption: any, index: number) => {
                    return (
                      <Option key={index} name={holeOption.label} value={holeOption.value}>
                        <span>{holeOption.value}</span>
                      </Option>
                    );
                  })}
                </Select>
              </FormLayout.Group>
              <FormLayout.Group>
                {courseOptions?.length > 1 && (
                  <Select
                    defaultValue={templateState.course_id}
                    label={t("secure.facility.settings.tee_sheets.tee_sheet_template_new.030")}
                    onChange={(value: any) => handleDropDownChange(value, "course_id")}
                  >
                    {courseOptions?.map((courseOption: any, index: number) => {
                      return (
                        <Option key={index} name={courseOption.label} value={courseOption.value}>
                          <span>{courseOption.label}</span>
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  value={templateState.description}
                  label={"Description"}
                  id="description"
                  onChange={handleInputChange}
                  placeholder={"Template description"}
                />
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
        <Card.Section
          title="Interval Sections"
          sectionTitleActions={[
            {
              action: () => addIntervalSection(),
              content: "Add Section", // TODO: Translation
            },
          ]}
        >
          {templateState.interval_sections?.map((section: IIntervalSection, index: number) => (
            <div key={index} className="interval-section">
              <div className="flex justify-between">
                <span className="interval-section-title">
                  {`Time Frame #${index + 1}`} {/* TODO: Translation */}
                </span>
                {templateState.interval_sections.length > 1 && (
                  <Button onClick={() => removeIntervalSection(index)} type="tertiary" size="small">
                    {"Remove"}
                  </Button>
                )}
              </div>
              <div>
                <FormLayout>
                  <FormLayout.Group>
                    <TimePick
                      value={section.start_time}
                      onChange={timeString => handleSectionStartTime(timeString, index)}
                      label={t("secure.facility.settings.tee_sheets.tee_sheet_template_new.022")}
                      size="large"
                      status={section.start_time === undefined ? "warning" : null}
                    />
                    <TimePick
                      value={section.end_time}
                      onChange={timeString => handleSectionEndTime(timeString, index)}
                      label={t("secure.facility.settings.tee_sheets.tee_sheet_template_new.024")}
                      size="large"
                      status={section.end_time === undefined ? "warning" : null}
                    />
                    <Select
                      defaultValue={section.interval_1}
                      label={"Interval 1"} // TODO: Translation
                      onChange={(value: any) => handleSectionInterval1(value, index)}
                    >
                      {intervalOptions?.map((intervalOption: any, index: number) => {
                        return (
                          <Option key={index} name={intervalOption.label} value={intervalOption.value}>
                            <span>{intervalOption.value}</span>
                          </Option>
                        );
                      })}
                    </Select>
                    <Select
                      defaultValue={section.interval_2}
                      label={"Interval 2"} // TODO: Translation
                      onChange={(value: any) => handleSectionInterval2(value, index)}
                    >
                      <Option key={-1} name={"None"} value={-1}>
                        <span>{"None"}</span>
                      </Option>
                      {intervalOptions?.map((intervalOption: any, index: number) => {
                        return (
                          <Option key={index} name={intervalOption.label} value={intervalOption.value}>
                            <span>{intervalOption.value}</span>
                          </Option>
                        );
                      })}
                    </Select>
                  </FormLayout.Group>
                </FormLayout>
              </div>
            </div>
          ))}
        </Card.Section>
      </Card>

      <Popup
        open={warningPopupVisible}
        type="error"
        title="Time Frame Error"
        description="One or more time frame crosses over with another."
        onOk={() => setWarningPopupVisible(false)}
        okText="Continue"
      />
    </Page>
  );
};

export default TeeSheetTemplateNew;
