import React, { createContext, ReactNode, useContext, useRef, useState } from "react";
import { ICartLineItem } from "redux/reducers/models/cart";
import { IProduct, IVariant } from "redux/reducers/models/product";

type TRegisterState = {
  selectedVariant: IVariant;
  selectedProduct: IProduct;
  variants: Array<IVariant>;
  modifierLineItem: ICartLineItem;
};

type TRegisterContext = {
  registerState: TRegisterState;
  updateRegisterState: (state: Partial<TRegisterState>) => void;
  loadingVariants: React.MutableRefObject<TLoadingVariants[]>;
  addLoadingVariant: (
    variant: IVariant & { parent_id?: number },
    product?: IProduct,
    modifiers?: Array<IVariant>,
    kitchen_meal_id?: number,
  ) => void;
  removeLoadingVariant: (variantId: number) => void;
  lineItemsUpdating: React.MutableRefObject<Array<TLineItemsEdit>>;
  addLineItemUpdating: (id: number, type?: TLineItemEditType) => void;
  removeLineItemUpdating: (lineItem: number) => void;
  participantPaidFees: React.MutableRefObject<TParticipantPaidFees>;
  updateParticipantPaidFees: (updatedParticipant: {
    booking_participant_id: number;
    green_fee: boolean;
    power_cart_fee: boolean;
    green_fee_loading: boolean;
    power_cart_fee_loading: boolean;
  }) => void;
  initializeParticipantPaidFees: (participantFees: TParticipantPaidFees) => void;
};

type TLoadingVariants = IVariant & {
  product_title: string;
  product_preferred_title: string;
  modifiers: Array<IVariant>;
  parent_id?: number;
  kitchen_meal_id?: number;
};

const defaultState: TRegisterContext = {
  registerState: {
    selectedProduct: null,
    selectedVariant: null,
    variants: null,
    modifierLineItem: null,
  },
  updateRegisterState: null,
  loadingVariants: null,
  addLoadingVariant: null,
  removeLoadingVariant: null,
  lineItemsUpdating: null,
  addLineItemUpdating: null,
  removeLineItemUpdating: null,
  participantPaidFees: null,
  updateParticipantPaidFees: null,
  initializeParticipantPaidFees: null,
};

type TLineItemEditType = "edit" | "delete";
type TLineItemsEdit = {
  type: TLineItemEditType;
  id: number;
};

export type TParticipantPaidFees = Array<{
  booking_participant_id: number;
  green_fee: boolean;
  power_cart_fee: boolean;
  green_fee_loading: boolean;
  power_cart_fee_loading: boolean;
}>;

const RegisterCreateContext = createContext<TRegisterContext>(defaultState);
export const useRegisterContext = () => useContext(RegisterCreateContext);

export default function RegisterContext(props: { children: ReactNode }) {
  const [registerState, setRegisterState] = useState<TRegisterState>({ ...defaultState.registerState });
  const loadingVariants = useRef<Array<TLoadingVariants>>([]);
  const lineItemsUpdating = useRef<Array<TLineItemsEdit>>([]);
  const participantPaidFees = useRef<TParticipantPaidFees>([]);

  function updateRegisterState(newRegisterState: Partial<TRegisterState>) {
    setRegisterState((prevState: TRegisterState) => {
      return { ...prevState, ...newRegisterState };
    });
  }

  /**Add new variant to cart, show loader */
  function addLoadingVariant(
    variant: IVariant & { parent_id?: number },
    product?: IProduct,
    modifiers: Array<IVariant> = [],
    kitchen_meal_id?: number,
  ) {
    loadingVariants.current = [
      ...loadingVariants?.current,
      {
        ...variant,
        quantity: 1,
        product_title: product?.title,
        product_preferred_title: product?.preferred_title,
        modifiers: modifiers,
        kitchen_meal_id: kitchen_meal_id,
      },
    ];
  }

  /**Remove new variant from cart */
  function removeLoadingVariant(variantId: number) {
    const foundfirstIndex = [...loadingVariants.current]?.findIndex(foundVariant => foundVariant?.id === variantId);
    if (foundfirstIndex !== -1) {
      loadingVariants.current = [...loadingVariants.current]?.filter((variant, index) => index !== foundfirstIndex);
    }
  }

  /**Line items that will display shimmer effect as they are being updated or deleted */
  function addLineItemUpdating(lineItemId: number, type: TLineItemEditType = "edit") {
    lineItemsUpdating.current = [...lineItemsUpdating?.current, { id: lineItemId, type: type }];
  }

  /**Remove line items that are being updated or deleted */
  function removeLineItemUpdating(lineItemId: number) {
    lineItemsUpdating.current = [...lineItemsUpdating?.current]?.filter(item => item?.id !== lineItemId);
  }

  function updateParticipantPaidFees(updatedParticipant: {
    booking_participant_id: number;
    green_fee: boolean;
    power_cart_fee: boolean;
    green_fee_loading: boolean;
    power_cart_fee_loading: boolean;
  }) {
    const foundIndex = [...participantPaidFees.current]?.findIndex(
      foundParticipant => foundParticipant?.booking_participant_id === updatedParticipant?.booking_participant_id,
    );
    if (foundIndex !== -1) {
      participantPaidFees.current = [...participantPaidFees.current]?.map((participant, index) =>
        index === foundIndex ? updatedParticipant : participant,
      );
    }
  }

  function initializeParticipantPaidFees(participantFees: TParticipantPaidFees) {
    if (participantFees) {
      participantPaidFees.current = participantFees;
    }
  }

  return (
    <RegisterCreateContext.Provider
      value={{
        registerState,
        updateRegisterState,
        loadingVariants,
        addLoadingVariant,
        removeLoadingVariant,
        lineItemsUpdating,
        addLineItemUpdating,
        removeLineItemUpdating,
        participantPaidFees,
        updateParticipantPaidFees,
        initializeParticipantPaidFees,
      }}
    >
      {props?.children}
    </RegisterCreateContext.Provider>
  );
}
