import { APIDelete, APIGet, APIPost, APIPut } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/tournament/registration-input";

export type TournamentRegistrationInputTypes = "input" | "select" | "checkbox" | "text";

export interface ITournamentRegistrationInput {
  id: number;
  label: string;
  required: boolean;
  type: TournamentRegistrationInputTypes;
  help_text: string;
  row: number;
  column: number;
  values: string[] | null;
}

interface ITournamentRegistrationInputRes<T> {
  status: number;
  data: T;
  message?: string;
}

interface IGetTournamentRegistrationInputParams {
  tournament_id: number;
}

export interface IPutTournamentRegistrationInputParams {
  id: number;
  label?: string;
  help_text?: string;
  type?: TournamentRegistrationInputTypes;
  values?: string[];
  required?: boolean;
}

interface IDeleteTournamentRegistrationInputParams {
  id: number;
}

export type PutTournamentRegistrationInputPosition = {
  id: number;
  row: number;
  column: number;
};

type PutTournamentRegistrationInputPositionParams = {
  inputs: PutTournamentRegistrationInputPosition[];
};

export type IPostTournamentRegistrationInputParams = {
  tournament_id: number;
  label: string;
  help_text: string;
  type: TournamentRegistrationInputTypes;
  required?: boolean; // backend defaults to true if not given
};

export async function GetTournamentRegistrationInput(
  params: IGetTournamentRegistrationInputParams,
  useGlobalLoader: boolean,
  token?: CancelToken,
): Promise<ITournamentRegistrationInputRes<ITournamentRegistrationInput[]>> {
  return await APIGet(baseUrl, params, useGlobalLoader, token);
}

export async function PostTournamentRegistrationInput(
  params: IPostTournamentRegistrationInputParams,
  useGlobalLoader: boolean,
): Promise<ITournamentRegistrationInputRes<ITournamentRegistrationInput>> {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

export async function PutTournamentRegistrationInput(
  params: IPutTournamentRegistrationInputParams,
  useGlobalLoader = true,
): Promise<ITournamentRegistrationInputRes<ITournamentRegistrationInput>> {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

export async function DeleteTournamentRegistrationInput(
  params: IDeleteTournamentRegistrationInputParams,
  useGlobalLoader = true,
): Promise<ITournamentRegistrationInputRes<any>> {
  return await APIDelete(baseUrl, params, useGlobalLoader);
}

export async function PutTournamentRegistrationInputPositions(
  params: PutTournamentRegistrationInputPositionParams,
  useGlobalLoader = true,
): Promise<ITournamentRegistrationInputRes<any>> {
  return await APIPut(baseUrl + "/position", params, useGlobalLoader);
}
