import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import axios, { CancelToken } from "axios";
import moment from "moment";

import { StatusCode } from "api/protocols";
import { DeleteTournament, IPutTournament } from "api/rpc/2022-09/facilityAdmin/tournament/tournament";
import { GetInvoiceTransaction, GetInvoiceTransactionReceipt } from "api/rpc/2024-04/facilityAdmin/order/invoice";

import {
  DeleteTournamentLogo,
  UploadTournamentLogo,
  GetTournamentSponsorship,
  PostTournamentSponsorship,
  DeleteTournamentSponsorship,
  GetTournament,
  PutTournament,
} from "api/rpc/2024-04/facilityAdmin/tournament/tournament";

import { showError, showSuccess } from "redux/actions/ui";
import { handleChangeEventInput } from "helpers/Helpers";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import useModal from "hooks/modals/useModal";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import TextField from "components/form/textField/TextField";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import TimePick from "components/timePick/TimePick";
import Popup from "components/popup/Popup";
import Portal from "elements/Portal";
import TextEditor from "components/textEditor/textEditor";
import DataTable from "../../../customer/tabs/houseAccounts/DataTable";
import { LocaleCurrency } from "helpers/Locale";
import { ButtonNew as Button } from "components/buttonNew";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Checkbox from "components/form/checkbox/Checkbox";
import SponsorList, { ISponsorshipImage } from "components/sponsorList/SponsorList";
import { IInvoiceTransaction } from "redux/reducers/models/order";
import { NavigationDropdownNew } from "components/navigationDropdownNew/NavigationDropdownNew";
import { Badge } from "components/badge/Badge";

interface ITournamentState {
  id: number;
  name: string;
  start_time: string;
  date: Date;
  description: string;
  handle: string;
  tee_sheet_note: string;
  registration_link: string;
  logo_source: string;
  display_online: boolean;
  deposits: Array<IInvoiceTransaction>;
}

export default function TournamentSettings() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { tournamentId } = useParams<{ tournamentId: string }>();

  const dispatch = useAppDispatch();

  const [state, setState] = useState<ITournamentState>({
    id: Number(tournamentId),
    name: "",
    start_time: "",
    date: new Date(),
    description: "",
    handle: "",
    tee_sheet_note: "",
    registration_link: "",
    logo_source: "",
    display_online: false,
    deposits: null,
  });

  const [deleteTournamentPopup, setDeleteTournamentPopup] = useState(false);

  const { facilityStore } = useAppSelector(store => store);

  const { state: removeSponsorshipPopup, updateModal, closeModal } = useModal({ sponsorId: null as number });

  const [sponsorships, setSponsorships] = useState<ISponsorshipImage[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (facilityStore.facility) {
      void loadTournament(tournamentId, source.token);
      void loadSponsorships(tournamentId, source.token);
    }

    return () => source.cancel("Cancelled");
  }, [tournamentId, facilityStore]);

  async function loadTournament(id: number | string, token?: CancelToken) {
    const tournamentRes = await GetTournament({ id: id }, true, token);

    if (tournamentRes.status !== StatusCode.OK) {
      if (tournamentRes.message === "Cancelled") {
        return;
      }
      dispatch(
        showError(
          typeof tournamentRes.data === "string"
            ? tournamentRes.data
            : t("secure.facility.tournament.settings.tournament_settings.001"),
        ),
      );
      return;
    }

    const tournament = tournamentRes.data[0];

    const depositRes = await GetInvoiceTransaction({ tournament_id: Number(tournamentId) }, true);

    if (depositRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading tournament deposits")); // TODO: Translation
      return;
    }

    setState(prevState => ({
      ...prevState,
      id: tournament.id,
      name: tournament.name,
      start_time: tournament.start_time,
      date: new Date(`${tournament.date}T00:00`),
      description: tournament.description,
      loaded: true,
      handle: tournament.handle,
      tee_sheet_note: tournament.tee_sheet_note,
      registration_link: window.origin + `/tournament/${facilityStore?.facility?.short_name}/${tournament.handle}/`,
      logo_source: tournament.logo_source,
      display_online: tournament.display_online,
      deposits: depositRes.data,
    }));
  }

  async function saveTournament() {
    if (state.date) {
      const year = state.date.toLocaleString("en-CA", { year: "numeric" });
      const month = state.date.toLocaleString("en-CA", { month: "2-digit" });
      const day = state.date.toLocaleString("en-CA", { day: "2-digit" });

      const dateString = `${year}-${month}-${day}`;

      const tournamentRes = await PutTournament(
        {
          id: state.id,
          name: state.name,
          start_time: state.start_time ?? null,
          date: dateString,
          description: state.description,
          display_online: state.display_online,
        },
        true,
      );

      if (tournamentRes.status !== StatusCode.OK) {
        dispatch(showError(t("secure.facility.tournament.settings.tournament_settings.002")));
      } else {
        dispatch(showSuccess(t("secure.facility.tournament.settings.tournament_settings.003")));
      }
    }
  }

  async function removeTournament() {
    const res = await DeleteTournament({ id: tournamentId }, true);

    if (res.status !== StatusCode.OK) {
      dispatch(
        showError(
          typeof res.data === "string" ? res.data : t("secure.facility.tournament.settings.tournament_settings.004"),
        ),
      );
      dispatch(
        showError(
          typeof res.data === "string" ? res.data : t("secure.facility.tournament.settings.tournament_settings.022"),
        ),
      );
      setDeleteTournamentPopup(false);
      return;
    }

    setDeleteTournamentPopup(false);
    history.push("/admin/events-tournament");
  }

  function handleDateChange(updatedDate: Date) {
    setState(prevState => ({ ...prevState, date: updatedDate }));
  }

  async function loadSponsorships(tournamentId: string, token?: CancelToken) {
    if (sponsorships !== undefined) {
      setSponsorships(undefined);
    }

    const res = await GetTournamentSponsorship({ tournament_id: tournamentId }, false, token);

    if (res.status !== StatusCode.OK) {
      if (res.message === "Cancelled") {
        return;
      }
      dispatch(
        showError(
          typeof res.data === "string" ? res.data : t("secure.facility.tournament.settings.tournament_settings.023"),
        ),
      );
      setSponsorships([]);
      return;
    }

    setSponsorships(res.data);
  }

  async function updateSponsorship(imageFile: File) {
    const formData = new FormData();

    formData.append("image", imageFile);
    formData.append("tournament_id", tournamentId);

    const res = await PostTournamentSponsorship(formData, true);

    if (res.status !== StatusCode.OK) {
      dispatch(
        showError(
          typeof res.data === "string" ? res.data : t("secure.facility.tournament.settings.tournament_settings.024"),
        ),
      );
      return;
    }

    void loadSponsorships(tournamentId);
  }

  async function removeSponsorship() {
    const res = await DeleteTournamentSponsorship({ id: removeSponsorshipPopup.sponsorId }, true);

    if (res.status !== StatusCode.OK) {
      dispatch(
        showError(
          typeof res.data === "string" ? res.data : t("secure.facility.tournament.settings.tournament_settings.025"),
        ),
      );
      return;
    }

    const updatedSponsors = [...sponsorships];
    const removedSponsor = updatedSponsors.indexOf(
      sponsorships.find(value => value.id === removeSponsorshipPopup.sponsorId),
    );
    updatedSponsors.splice(removedSponsor, 1);

    setSponsorships(updatedSponsors);
    closeModal();
  }

  async function getDepositReceipt(transactionId: number) {
    const res = await GetInvoiceTransactionReceipt({ transaction_id: transactionId }, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error printing deposit receipt")); // TODO: Translation
      return;
    }

    window.open().document.write(res.data);
  }

  const primaryAction = {
    content: t("secure.facility.tournament.settings.tournament_settings.005"),
    action: () => saveTournament(),
  };

  const secondaryAction = [
    {
      content: t("secure.facility.tournament.settings.tournament_settings.006"),
      action: () => setDeleteTournamentPopup(true),
    },
  ];

  function handleTournamentDescription(description: string) {
    setState(prevState => ({ ...prevState, description }));
  }

  async function saveTournamentLogo(imageFile: File) {
    const formData = new FormData();

    formData.append("image", imageFile);
    formData.append("tournament_id", state.id.toString());

    const imageRes = await UploadTournamentLogo(formData, true);
    if (imageRes.status !== StatusCode.OK) {
      return;
    }

    setState(prevState => ({ ...prevState, logo_source: imageRes.data.logo_source }));
  }

  async function deleteTournamentLogo() {
    const deleteRes = await DeleteTournamentLogo({ tournament_id: tournamentId }, true);

    if (deleteRes.status !== StatusCode.OK) {
      return;
    }

    setState(prevState => ({ ...prevState, logo_source: deleteRes.data.logo_source }));
  }

  function handleNoteInputChange(event: any) {
    const { id, value } = event.target;
    setState(prev => ({
      ...prev,
      [id]: value,
    }));
  }

  async function handleSaveNote() {
    const params = {
      id: tournamentId,
      tee_sheet_note: state.tee_sheet_note,
    };

    const tournamentRes = await PutTournament(params, true);

    if (tournamentRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.tournament.settings.tournament_settings.007")));
      return;
    }
    dispatch(showSuccess(t("secure.facility.tournament.settings.tournament_settings.008")));
  }

  return (
    <Page
      title={t("secure.facility.tournament.settings.tournament_settings.009")}
      narrow
      primaryAction={primaryAction}
      secondaryActions={secondaryAction}
    >
      <Form>
        <Card title={t("secure.facility.tournament.settings.tournament_settings.010")}>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={state.name}
                  label={t("secure.facility.tournament.settings.tournament_settings.011")}
                  id="name"
                  onChange={e => handleChangeEventInput(e, state, setState)}
                  placeholder={t("secure.facility.tournament.settings.tournament_settings.012")}
                />
              </FormLayout.Group>
            </FormLayout>
            <FormLayout>
              <FormLayout.Group>
                <TimePick
                  label={t("secure.facility.tournament.settings.tournament_settings.013")}
                  value={state.start_time}
                  onChange={(timeString: string) => setState(prev => ({ ...prev, start_time: timeString }))}
                  size="large"
                  status={state?.start_time === undefined ? "error" : undefined}
                />
                <DatePickerInput
                  label={t("secure.facility.tournament.settings.tournament_settings.014")}
                  months={1}
                  position="left"
                  startingDate={state?.date}
                  setStartingDate={handleDateChange}
                />
              </FormLayout.Group>
            </FormLayout>

            <FormLayout>
              <FormLayout.Group>
                <Checkbox
                  id="display_online"
                  size="medium"
                  value={state.display_online}
                  checked={state.display_online}
                  onChange={() => setState(prevState => ({ ...prevState, display_online: !state.display_online }))}
                  label={t("secure.facility.tournament.settings.tournament_settings.026")}
                  supportText={t("secure.facility.tournament.settings.tournament_settings.027")}
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <TextEditor
                  markdownText={state.description}
                  markdownTextOnChange={handleTournamentDescription}
                  label={t("secure.facility.tournament.settings.tournament_settings.015")}
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>

        <Card title={t("secure.facility.tournament.settings.tournament_settings.016")}>
          <Card.Section>
            <TextField
              value={state.tee_sheet_note}
              label={t("secure.facility.tournament.settings.tournament_settings.017")}
              id="tee_sheet_note"
              onChange={handleNoteInputChange}
              maxLength={150}
              showCharacterCount
            />

            <div className="flex justify-end mt-3" style={{ marginRight: "33px" }}>
              <Button type="primary" onClick={handleSaveNote}>
                {t("secure.facility.tournament.settings.tournament_settings.018")}
              </Button>
            </div>
          </Card.Section>
        </Card>
      </Form>

      <div className="mt-4">
        {state?.deposits?.length >= 1 && (
          <Card title={t("secure.facility.hospitality.hospitality_event.008")}>
            <Card.Section>
              <DataTable
                columns={[
                  { label: t("secure.facility.hospitality.hospitality_event.009"), width: "25%" },
                  { label: t("secure.facility.hospitality.hospitality_event.010"), width: "20%" },
                  { label: "Status", width: "10%" }, // TODO: Translation
                  { label: t("secure.facility.hospitality.hospitality_event.011"), width: "40%" },
                  { label: "", width: "5%" },
                ]}
                loading={state.deposits === undefined}
              >
                {state.deposits?.map((deposit, index) => (
                  <tr key={index}>
                    <td>{moment(deposit.processed_at_local).format("YYYY-MM-DD")}</td>
                    <td>
                      <LocaleCurrency currency={deposit.currency} amount={deposit.amount} />
                    </td>
                    <td>{statusBadge(deposit.invoice_id)}</td>
                    <td>{deposit.note}</td>
                    <td>
                      <NavigationDropdownNew
                        showPlainTextLabel
                        rightAlign
                        label={<FontAwesomeIcon icon={["far", "chevron-down"]} />}
                        sections={[
                          [
                            {
                              icon: "print",
                              label: "Print Receipt",
                              onClick: () => getDepositReceipt(deposit.id),
                            },
                          ],
                        ]}
                      />
                    </td>
                  </tr>
                ))}
              </DataTable>
            </Card.Section>
          </Card>
        )}
      </div>

      <Portal isMounted={removeSponsorshipPopup.isOpen}>
        <Popup
          open={true}
          type={"warning"}
          title={t("secure.facility.tournament.settings.tournament_settings.028")}
          description={t("secure.facility.tournament.settings.tournament_settings.029")}
          onCancel={() => closeModal()}
          onOk={() => removeSponsorship()}
          okText={t("secure.facility.tournament.settings.tournament_settings.030")}
        />
      </Portal>

      <Portal isMounted={deleteTournamentPopup}>
        <Popup
          open={true}
          type={"warning"}
          title={t("secure.facility.tournament.settings.tournament_settings.019")}
          description={t("secure.facility.tournament.settings.tournament_settings.020")}
          onCancel={() => setDeleteTournamentPopup(false)}
          onOk={() => removeTournament()}
          okText={t("secure.facility.tournament.settings.tournament_settings.021")}
        />
      </Portal>
    </Page>
  );
}

const statusBadge = (invoice_id: number) => {
  switch (invoice_id) {
    case null:
      return <Badge type="success">{"Available"}</Badge>;
    default:
      return <Badge type="gray">{"Applied"}</Badge>;
  }
};
