import { TFunction } from "react-i18next";
import {
  TEventCompetition,
  TEventCompetitionStrokePlay,
  TFoursomeCompetition,
  TPairCompetition,
  TPlayerCompetition,
  TTeamCompetition,
} from "redux/reducers/models/league";

export const formatOptions = (t: TFunction<"translation", undefined>) => [
  {
    id: "stroke_play",
    title: "Stroke Play",
  },
  // {
  //   id: "match_play",
  //   title: "Match Play",
  // },
  // {
  //   id: "points_by_net_score",
  //   title: "Points by Net Score",
  // },
  // {
  //   id: "points_by_gross_score",
  //   title: "Points by Gross Score",
  // },
  // {
  //   id: "stableford_net",
  //   title: "Stableford Net",
  // },
  // {
  //   id: "stableford_gross",
  //   title: "Stableford Gross",
  // },

  // {
  //   id: "stabled",
  //   title: "Stabled",
  // },
];

export const competitionOptions = (t: TFunction<"translation", undefined>) => [
  {
    id: "player_vs_field" as TPlayerCompetition,
    title: "Player vs Field",
  },
  {
    id: "player_vs_flight" as TPlayerCompetition,
    title: "Player vs Flight",
  },
  {
    id: "pair_vs_field" as TPairCompetition,
    title: "Pair vs Field",
  },
  {
    id: "pair_vs_flight" as TPairCompetition,
    title: "Pair vs Flight",
  },
  {
    id: "team_vs_field" as TTeamCompetition,
    title: "Team vs Field",
  },
  {
    id: "team_vs_flight" as TTeamCompetition,
    title: "Team vs Flight",
  },
  {
    id: "foursome_vs_field" as TFoursomeCompetition,
    title: "Foursome vs Field",
  },
  {
    id: "foursome_vs_flight" as TFoursomeCompetition,
    title: "Foursome vs Flight",
  },
];

export const competitionOptionsStrokePlay = (t: TFunction<"translation", undefined>) => [
  {
    id: "player_vs_field" as TEventCompetitionStrokePlay,
    title: "Player vs Field",
  },
  {
    id: "players_vs_flight" as TEventCompetitionStrokePlay,
    title: "Players vs Flight",
  },
  {
    id: "pair_vs_field" as TEventCompetitionStrokePlay,
    title: "Pair vs Field",
  },
  {
    id: "pair_vs_flight" as TEventCompetitionStrokePlay,
    title: "Pair vs Flight",
  },

  {
    id: "foursome_vs_field" as TEventCompetitionStrokePlay,
    title: "Foursome vs Field",
  },
  {
    id: "foursome_vs_field" as TEventCompetitionStrokePlay,
    title: "Foursome vs Flight",
  },
];

export const holesOptions = (t: TFunction<"translation", undefined>) => [
  {
    id: 9,
    title: "9",
  },
  {
    id: 18,
    title: "18",
  },
];

export const handicapFormatOptions = (t: TFunction<"translation", undefined>) => [
  {
    id: "gross",
    title: "Gross",
  },
  {
    id: "net",
    title: "Net",
  },
];
