import { APIGet, APIPost, APIPut } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "client-admin";
const baseRegisterUrl = "/" + apiVersion + "/" + adminType + "/facility/register";

export interface IGetRegister {
  id?: number;
  extended?: boolean;
  facility_id: number;
}

export async function GetRegisters(params?: IGetRegister, useGlobalLoader?: boolean, token?: CancelToken) {
  return await APIGet(baseRegisterUrl, params, useGlobalLoader, token);
}
