import React, { useEffect, useState } from "react";
import axios, { CancelToken } from "axios";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StatusCode } from "api/protocols";
import { convertTime, valueToString } from "helpers/Helpers";
import Spin from "components/spin/spin";
import { ButtonNew as Button } from "components/buttonNew";
import "../PortalBookings/portalBookings.scss";
import { useHistory } from "react-router";
import { Badge } from "components/badge/Badge";
import { useTranslation } from "react-i18next";
import { useGuestPortalContext } from "../GuestPortalContext";
import { useAppDispatch } from "hooks/redux";
import { showError } from "redux/actions/ui";
import { IReservationBooking } from "redux/reducers/models/reservations";
import Callout from "components/callout/Callout";
import { GetReservationBooking } from "api/rpc/2024-04/customer/reservation";

interface IPortalReservationFilters {
  limit: number;
  offset: number;
}

export default function PortalReservations() {
  const { portalState } = useGuestPortalContext();
  const dispatch = useAppDispatch();
  const [reservations, setReservations] = useState<Array<IReservationBooking>>([]);
  const [filter, setFilter] = useState<IPortalReservationFilters>({
    limit: 15,
    offset: 0,
  });

  const history = useHistory();

  useEffect(() => {
    const source = axios.CancelToken.source();
    void getGuestBookings(source.token);
    return () => {
      source.cancel("Cancelled request");
    };
  }, [filter]);

  async function getGuestBookings(token: CancelToken) {
    if (reservations !== null) {
      //Show loader
      setReservations(null);
    }

    const reservationsRes = await GetReservationBooking({ ...filter, extended: true }, false, token);

    if (token && token.reason) {
      return;
    }
    if (reservationsRes.status === StatusCode.OK) {
      setReservations(reservationsRes.data);
    } else {
      dispatch(showError("Error getting reservations")); // TODO: Translation
      setReservations([]);
    }
  }

  function handleViewReservation(token: string) {
    if (token) {
      history.push(`/tee-on/portal/reservations/${token}/view`);
    }
  }

  return (
    <div className="guest-bookings-page-container">
      <div className="guest-bookings-header">
        <h1 className="guest-bookings-header-text">Reservations</h1>
      </div>

      {reservations ? (
        <div className="guest-bookings-body-container">
          {reservations?.length > 0 ? (
            reservations?.map((reservation, index) => {
              return (
                <div
                  key={index}
                  onClick={() => handleViewReservation(reservation?.token)}
                  className={"guest-bookings-container booking-border"}
                >
                  <p className="guest-bookings-reservation-title">{reservation?.location?.facility?.long_name}</p>
                  <div className="guest-bookings-date-time-container">
                    <div className="guest-bookings-date-time">{moment(reservation?.date).format("MMMM DD YYYY")}</div>

                    <div className="guest-bookings-date-time">{convertTime(reservation?.start_time)}</div>
                  </div>

                  <div className="guest-bookings-quantity-status-container">
                    <div className="guest-bookings-quantity">
                      <FontAwesomeIcon icon={["fas", "user"]} />
                      <span>
                        {reservation?.quantity} {reservation?.quantity === 1 ? "Guest" : "Guests"}
                      </span>
                      <span>{reservation?.location?.title}</span>
                    </div>

                    <div className="guest-bookings-status">
                      {reservation?.status !== "cancelled" ? (
                        reservation?.check_in_status !== "unchecked" && (
                          <Badge
                            size="small"
                            type={returnBadgeType(
                              reservation?.status === "cancelled" ? reservation?.status : reservation?.check_in_status,
                            )}
                          >
                            {valueToString(reservation?.check_in_status)}
                          </Badge>
                        )
                      ) : (
                        <Badge size="small" type="error">
                          {valueToString(reservation?.status)}
                        </Badge>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <Callout
              type="info"
              title="No Reservations Found"
              content="There are no reservations booked under this account"
            />
          )}
        </div>
      ) : (
        <span style={{ display: "flex", justifyContent: "center" }}>
          <Spin />
        </span>
      )}

      <div className="guest-bookings-footer">
        <div>
          <Button
            size="small"
            type="secondary"
            onClick={() => setFilter(prev => ({ ...prev, offset: prev.offset - prev.limit }))}
            disabled={!reservations || !(filter.limit <= filter.offset)}
          >
            <FontAwesomeIcon icon={"arrow-left"} />
            &nbsp;
            {"Prev"}
          </Button>
        </div>
        <div>
          <Button
            size="small"
            type="secondary"
            onClick={() => setFilter(prev => ({ ...prev, offset: prev.offset + prev.limit }))}
            disabled={!(reservations?.length === filter.limit)}
          >
            {"Next"}
            &nbsp;
            <FontAwesomeIcon icon={"arrow-right"} />
          </Button>
        </div>
      </div>
    </div>
  );
}

function returnBadgeType(status: string) {
  switch (status) {
    case "checked":
      return "public";
    case "no_show":
      return "error";
    default:
      return "gray";
  }
}
