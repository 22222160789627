import { useEffect, useRef } from "react";

export default function useEventListener(
  eventType: keyof HTMLElementEventMap,
  callback: (event?: any) => void,
  element?: HTMLDivElement,
) {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const eventHandler = (event: any) => callbackRef.current(event);

  useEffect(() => {
    if (element == null) {
      return;
    }
    element.addEventListener(eventType, eventHandler);
    return () => element.removeEventListener(eventType, eventHandler);
  }, [eventType, element]);
}
