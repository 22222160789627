import { GetPaymentOptions, GetFacilityNew } from "api/rpc/clientAdmin/facility/facility";
import { GetFacility } from "api/rpc/2024-04/clientAdmin/facility/facility";

import { LocalStorage } from "api/localstorage";
// import { ICartState } from "reducers/cart";
import { StatusCode } from "api/protocols";
import { GetWeather } from "api/rpc/clientAdmin/weather/weather";

export function loadAdminFacility(params: any, useGlobalLoader: boolean) {
  return async (dispatch: any) => {
    const res = await GetFacilityNew(params, useGlobalLoader);
    if (res.status !== StatusCode.OK) {
      console.log(res);
      return;
    }

    console.log("Admin Facility: ", res.data[0]);

    dispatch({
      type: "facility.update",
      payload: {
        isLoaded: true,
        facility: res.data[0],
      },
    });
  };
}

export function setAdminFacility(facility: Record<string, any>) {
  return (dispatch: any) => {
    dispatch({
      type: "facility.update",
      payload: {
        isLoaded: true,
        facility: facility,
      },
    });
  };
}

export function loadAllAdminFacilities(useGlobalLoader: boolean) {
  return async (dispatch: any) => {
    const res = await GetFacility(null, useGlobalLoader);
    if (res.status !== StatusCode.OK) {
      console.log("Error loading facilities");
      return;
    }

    dispatch({
      type: "facility.update",
      payload: {
        isLoaded: true,
        facilities: res.data,
      },
    });
  };
}

export function loadPaymentOptions() {
  return async (dispatch: any, getState: any) => {
    if (getState().facilityStore.paymentOptions.length === 0) {
      try {
        const paymentRes = await GetPaymentOptions();
        console.log("paymentRes: ", paymentRes);
        if (paymentRes.status === StatusCode.OK) {
          // push options to redux

          const paymentOptions = paymentRes.data;

          dispatch({
            type: "facility.update",
            payload: {
              isLoaded: true,
              paymentOptions: paymentOptions,
            },
          });
        }
      } catch (e) {
        console.log("GetPaymentOptions ERR:", e);
      }
    }
  };
}

export function loadFacilityWeather(facility_id: number, useGlobalLoader: boolean) {
  return async (dispatch: any) => {
    const res = await GetWeather(facility_id, useGlobalLoader);

    if (res.status !== StatusCode.OK) {
      console.log(res);
      return;
    }

    // console.log("Facility Weather: ", res.data);

    dispatch({
      type: "facility.update",
      payload: {
        weather: res.data,
      },
    });
  };
}

export interface IFacilityActions {
  loadAdminFacility: typeof loadAdminFacility;
  loadFacilityWeather: typeof loadFacilityWeather;
  loadPaymentOptions: typeof loadPaymentOptions;
  loadAllAdminFacilities: typeof loadAllAdminFacilities;
  setAdminFacility: typeof setAdminFacility;
}
