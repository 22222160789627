import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import {
  PutUpdateSeasonLeaderboard,
  GetPrintSeasonLeaderboard,
} from "api/rpc/2022-09/facilityAdmin/league/scoring/leaderboards";
import { GetLeagueFlights } from "api/rpc/2024-04/facilityAdmin/league/scoring/flight";
import { GetSeasonLeaderboard } from "api/rpc/2024-04/facilityAdmin/league/scoring/leaderboard";

import { showError } from "redux/actions/ui";
import { ICustomer } from "redux/reducers/models/customer";
import { TLeagueFlight, TLeagueFlightParticipant } from "redux/reducers/models/league";
import { useAppDispatch } from "hooks/redux";

import Form from "components/form/Form";
import Page from "components/page/Page";
import FormLayout from "components/form/FormLayout";
import Spin from "components/spin/spin";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

import "./leagueLeaderboard.scss";

interface ISortBy {
  /**Column to sort by. Default sorts the data by gross in ascending order */
  type: "default" | "bonus" | "season" | "participation" | "points";
  /**Ascending is true, Descending is false*/
  direction: boolean;
}

interface ISeasonLeaderBoard {
  created_at: string;
  customer: ICustomer;
  deleted_at: string;
  id: number;
  league_id: number;
  rounds_played: number;
  team_id: number;
  total: number;
  total_points_bonus: number;
  total_points_participation: number;
  updated_at: string;
  user_id: number;
  flight_id?: number;
}

interface IState {
  order: Array<ISeasonLeaderBoard>;
  sortBy: ISortBy;
  flights: Array<TLeagueFlight>;
}

export default function SeasonLeaderboard() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { leagueId, scoresheetId } = useParams<{ leagueId: string; scoresheetId: string }>();
  const dispatch = useAppDispatch();

  const [state, setState] = useState<IState>({
    order: undefined,
    sortBy: { type: "default", direction: true },
    flights: undefined,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadLeagueFlights(leagueId, source.token);
    return () => source.cancel();
  }, [leagueId]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (state.flights === undefined) {
      return;
    }
    void loadSeasonLeaderboards(leagueId, source.token);

    return () => {
      source.cancel();
    };
  }, [leagueId, state.flights]);

  const orderedTableData = useMemo(() => {
    if (state.order && state.flights?.length > 0) {
      const participants: TLeagueFlightParticipant[] = [];

      state.flights.forEach(flight => {
        if (flight.participants) {
          flight.participants.map(player => participants.push(player as any));
        }
      });

      return state.order.reduce((formattedRounds: Array<Record<string, any>>, currentRound: Record<string, any>) => {
        const foundPlayer = participants?.find((player: any) => player?.user_id === currentRound?.user_id);
        if (foundPlayer) {
          formattedRounds.push({ ...currentRound, flight_id: foundPlayer?.flight_id });
        }
        return formattedRounds;
      }, []) as Array<ISeasonLeaderBoard & { flight_id: number }>;
    } else if (state.order) {
      return state.order;
    } else {
      return undefined;
    }
  }, [state.order, state.flights]);

  async function loadLeagueFlights(league_id: string, token?: CancelToken) {
    const res = await GetLeagueFlights({ league_id }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error getting flights"));
      setState(prev => ({ ...prev, flights: [] }));
      return;
    }

    setState(prev => ({ ...prev, flights: res.data }));
  }

  async function loadSeasonLeaderboards(league_id: string, token?: CancelToken) {
    if (state.order !== undefined) {
      setState(prev => ({ ...prev, order: undefined }));
    }
    const res = await GetSeasonLeaderboard({ league_id }, false, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error getting players"));
    }

    setState(prev => ({ ...prev, order: res.status !== StatusCode.OK ? [] : res.data }));
  }

  async function refreshSeasonLeaderboard() {
    const orderUpdateRes = await PutUpdateSeasonLeaderboard({ league_id: leagueId }, true);

    if (orderUpdateRes.status !== StatusCode.OK) {
      return;
    }

    void loadSeasonLeaderboards(leagueId);
  }

  async function printLeaderboard() {
    const printRes = await GetPrintSeasonLeaderboard({ league_id: leagueId }, true);

    if (printRes.status === StatusCode.OK) {
      window.open().document.write(printRes.data);
    } else {
      return;
    }
  }

  function sortByDefault() {
    const order = [...state.order];
    const sortedOrder = order?.sort((currentPlayer, nextPlayer) => {
      if (!currentPlayer?.total) {
        return 1;
      } else if (!nextPlayer?.total) {
        return -1;
      } else {
        return nextPlayer?.total - currentPlayer?.total;
      }
    });
    setState(prevState => ({ ...prevState, order: sortedOrder, sortBy: { type: "default", direction: true } }));
  }

  function sortLeaderboard(
    sortType: "default" | "bonus" | "season" | "participation" | "points",
    sortValue: keyof ISeasonLeaderBoard,
  ) {
    const order = [...state.order];
    let direction: boolean = state?.sortBy?.direction;
    if (state?.sortBy?.type !== sortType) {
      direction = true;
    } else if (direction === false) {
      //Descending order, return to default order
      void sortByDefault();
      return;
    } else {
      direction = !direction;
    }

    const sortedOrder = order.sort((currentPlayer, nextPlayer) => {
      //Sort null values last
      if (!currentPlayer?.[sortValue]) {
        return 1;
      } else if (!nextPlayer?.[sortValue]) {
        return -1;
      } else if (sortType == "points") {
        const currentPlayerPoints =
          currentPlayer?.total + currentPlayer?.total_points_bonus + currentPlayer?.total_points_participation;
        const nextPlayerPoints =
          nextPlayer?.total + nextPlayer?.total_points_bonus + nextPlayer?.total_points_participation;
        return direction ? nextPlayerPoints - currentPlayerPoints : currentPlayerPoints - nextPlayerPoints;
      } else {
        return direction
          ? (nextPlayer?.[sortValue] as any) - (currentPlayer?.[sortValue] as any)
          : (currentPlayer?.[sortValue] as any) - (nextPlayer?.[sortValue] as any);
      }
    });
    setState(prevState => ({ ...prevState, order: sortedOrder, sortBy: { type: sortType, direction } }));
  }

  const primaryAction = {
    content: "Refresh", // TODO: Translation
    action: refreshSeasonLeaderboard,
    disabled: orderedTableData === undefined,
  };

  const secondaryAction = {
    content: "Print", // TODO: Translation
    action: printLeaderboard,
    disabled: orderedTableData === undefined,
  };

  return (
    <Page title="Season Leaderboard" narrow primaryAction={primaryAction} secondaryActions={[secondaryAction]}>
      <Form>
        <FormLayout>
          {!state.order ? (
            <div style={{ height: "100px" }}>
              <Spin />
            </div>
          ) : state.flights?.length > 0 ? (
            state.flights?.map(flight => (
              <DataTable
                key={flight.id}
                title={flight.name}
                columns={[
                  { label: "", width: "5%" },
                  { label: "Player", width: "25%" }, // TODO: Translation
                  {
                    label: "Bonus",
                    width: "7.5%",
                    content: (
                      <p onClick={() => sortLeaderboard("bonus", "total_points_bonus")} className="leaderboard-header">
                        {"Bonus "}{" "}
                        <FontAwesomeIcon
                          style={{ visibility: state?.sortBy?.type === "bonus" ? "visible" : "hidden" }}
                          icon={["far", state?.sortBy?.direction ? "arrow-down-long" : "arrow-up-long"]}
                        />
                      </p>
                    ),
                  },
                  {
                    label: "Season",
                    width: "7.5%",
                    content: (
                      <p onClick={() => sortLeaderboard("season", "total")} className="leaderboard-header">
                        {"Season "}{" "}
                        <FontAwesomeIcon
                          style={{ visibility: state?.sortBy?.type === "season" ? "visible" : "hidden" }}
                          icon={["far", state?.sortBy?.direction ? "arrow-down-long" : "arrow-up-long"]}
                        />
                      </p>
                    ),
                  },
                  {
                    label: "Participation",
                    width: "7.5%",
                    content: (
                      <p
                        onClick={() => sortLeaderboard("participation", "total_points_participation")}
                        className="leaderboard-header"
                      >
                        {"Participation "}{" "}
                        <FontAwesomeIcon
                          style={{ visibility: state?.sortBy?.type === "participation" ? "visible" : "hidden" }}
                          icon={["far", state?.sortBy?.direction ? "arrow-down-long" : "arrow-up-long"]}
                        />
                      </p>
                    ),
                  },
                  {
                    label: "Points",
                    width: "7.5%",
                    content: (
                      <p onClick={() => sortLeaderboard("points", "total")} className="leaderboard-header">
                        {"Points "}{" "}
                        <FontAwesomeIcon
                          style={{ visibility: state?.sortBy?.type === "points" ? "visible" : "hidden" }}
                          icon={["far", state?.sortBy?.direction ? "arrow-down-long" : "arrow-up-long"]}
                        />
                      </p>
                    ),
                  },
                ]}
                loading={orderedTableData === undefined}
              >
                {orderedTableData
                  ?.filter(round => round.flight_id === flight.id)
                  .map((round, index: number) => (
                    <tr key={round.id}>
                      <td>{index + 1}</td>
                      <td>{round.customer?.full_name}</td>
                      <td>{round.total_points_bonus}</td>
                      <td>{round.total}</td>
                      <td>{round.total_points_participation}</td>
                      <td>{round?.total_points_participation + round?.total + round?.total_points_bonus}</td>
                    </tr>
                  ))}
              </DataTable>
            ))
          ) : (
            <DataTable
              columns={[
                { label: "", width: "5%" },
                { label: "Player", width: "25%" }, // TODO: Translation
                {
                  label: "Bonus",
                  width: "7.5%",
                  content: (
                    <p onClick={() => sortLeaderboard("bonus", "total_points_bonus")} className="leaderboard-header">
                      {"Bonus "}{" "}
                      <FontAwesomeIcon
                        style={{ visibility: state?.sortBy?.type === "bonus" ? "visible" : "hidden" }}
                        icon={["far", state?.sortBy?.direction ? "arrow-down-long" : "arrow-up-long"]}
                      />
                    </p>
                  ),
                },
                {
                  label: "Season",
                  width: "7.5%",
                  content: (
                    <p onClick={() => sortLeaderboard("season", "total")} className="leaderboard-header">
                      {"Season "}{" "}
                      <FontAwesomeIcon
                        style={{ visibility: state?.sortBy?.type === "season" ? "visible" : "hidden" }}
                        icon={["far", state?.sortBy?.direction ? "arrow-down-long" : "arrow-up-long"]}
                      />
                    </p>
                  ),
                },
                {
                  label: "Participation",
                  width: "7.5%",
                  content: (
                    <p
                      onClick={() => sortLeaderboard("participation", "total_points_participation")}
                      className="leaderboard-header"
                    >
                      {"Participation "}{" "}
                      <FontAwesomeIcon
                        style={{ visibility: state?.sortBy?.type === "participation" ? "visible" : "hidden" }}
                        icon={["far", state?.sortBy?.direction ? "arrow-down-long" : "arrow-up-long"]}
                      />
                    </p>
                  ),
                },
                {
                  label: "Points",
                  width: "7.5%",
                  content: (
                    <p onClick={() => sortLeaderboard("points", "total")} className="leaderboard-header">
                      {"Points "}{" "}
                      <FontAwesomeIcon
                        style={{ visibility: state?.sortBy?.type === "points" ? "visible" : "hidden" }}
                        icon={["far", state?.sortBy?.direction ? "arrow-down-long" : "arrow-up-long"]}
                      />
                    </p>
                  ),
                },
              ]}
              loading={orderedTableData === undefined}
            >
              {orderedTableData.map((round, index: number) => (
                <tr key={round.id}>
                  <td>{index + 1}</td>
                  <td>{round.customer?.full_name}</td>
                  <td>{round.total_points_bonus}</td>
                  <td>{round.total}</td>
                  <td>{round.total_points_participation}</td>
                  <td>{round?.total_points_participation + round?.total + round?.total_points_bonus}</td>
                </tr>
              ))}
            </DataTable>
          )}
        </FormLayout>
      </Form>
    </Page>
  );
}
