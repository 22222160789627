import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import Page from "components/page/Page";
import Card from "components/card/Card";
import { IPrimaryPageAction } from "components/page/PageActions";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";
import axios, { CancelToken } from "axios";
import { StatusCode } from "api/protocols";
import { dequeue, enqueue, showError, showSuccess } from "redux/actions/ui";
import moment from "moment";
import Spin from "components/spin/spin";
import "./viewInventoryTransfer.scss";
import "./editInventoryTransfer.scss";
import FormLayout from "components/form/FormLayout";
import {
  DeleteInventoryTransfer,
  GetInventoryTransfer,
  GetInventoryTransferVariants,
  PutAcceptInventoryTransfer,
  PutSubmitInventoryTransfer,
  PutVoidInventoryTransfer,
  TInventoryTransfer,
  TInventoryTransferVariant,
} from "api/rpc/2024-04/facilityAdmin/product/inventoryTransfer";
import Portal from "elements/Portal";
import Popup from "components/popup/Popup";
import { Badge } from "components/badge/Badge";
import { valueToString } from "helpers/Helpers";
import ReactDOM from "react-dom";

interface IInventoryProduct {
  id: number;
  title: string;
  vendor_title: string;
  variants: Array<TInventoryTransferVariant>;
}
interface IInventoryState {
  inventoryTransfer: TInventoryTransfer;
  inventoryTransferVariants: Array<TInventoryTransferVariant>;
  products: Array<IInventoryProduct>;
}

interface IParams {
  id: string;
}

export default function ViewInventoryTransfer() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { facilityStore } = useAppSelector(store => store);
  const { id } = useParams<IParams>();
  const [submitPopupState, setSubmitPopupState] = useState<boolean>(false);
  const [voidPopupState, setVoidPopupState] = useState<boolean>(false);
  const [acceptPopupState, setAcceptPopupState] = useState<boolean>(false);
  const [deletePopupState, setDeletePopupState] = useState<boolean>(false);
  const [inventoryState, setInventoryState] = useState<IInventoryState>({
    inventoryTransfer: undefined,
    inventoryTransferVariants: undefined,
    products: undefined,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadInventoryTransfer(source.token);
    return () => {
      source.cancel();
    };
  }, []);

  async function loadInventoryTransfer(cancelToken?: CancelToken) {
    let products: Array<IInventoryProduct> = [];
    // show loader
    if (inventoryState?.inventoryTransfer !== undefined) {
      setInventoryState(prevState => ({ ...prevState, inventoryTransfers: undefined }));
    }

    const inventoryTransferRes = await GetInventoryTransfer({ id: Number(id) }, false, cancelToken);
    if (inventoryTransferRes.status !== StatusCode.OK) {
      if (cancelToken?.reason) {
        return;
      }
      dispatch(showError("Error getting inventory transfer"));
    }

    const variantsRes = await GetInventoryTransferVariants({ inventory_transfer_id: Number(id) }, false, cancelToken);

    if (variantsRes.status !== StatusCode.OK) {
      if (cancelToken?.reason) {
        return;
      }
      dispatch(showError("Error getting inventory transfer products"));
    } else {
      products = variantsRes?.data?.reduce((productsArray: Array<IInventoryProduct>, variant) => {
        const updatedProducts = [...productsArray];
        const productIndex = productsArray?.findIndex(product => product?.id === variant?.product_id);
        if (productIndex === -1) {
          updatedProducts.push({
            id: variant.product_id,
            title: variant.product_title,
            vendor_title: variant?.product_vendor_title,
            variants: [variant],
          });
        } else {
          updatedProducts[productIndex]?.variants?.push(variant);
          updatedProducts[productIndex] = { ...updatedProducts[productIndex] };
        }
        return updatedProducts;
      }, []);
    }

    setInventoryState(prevState => ({
      ...prevState,
      inventoryTransfer: inventoryTransferRes.status === StatusCode.OK ? inventoryTransferRes?.data[0] : null,
      inventoryTransferVariants: variantsRes?.status === StatusCode.OK ? variantsRes?.data : [],
      products: products,
    }));
  }

  async function handleSubmit() {
    dispatch(enqueue());
    const submitRes = await PutSubmitInventoryTransfer({ id: Number(id) }, false);
    if (submitRes.status !== StatusCode.OK) {
      dispatch(showError("Error submitting transfer"));
      dispatch(dequeue());
      return;
    }
    dispatch(showSuccess("Successfully submitted transfer"));
    await handleReload();
    dispatch(dequeue());
  }

  async function handleAccept() {
    dispatch(enqueue());
    const acceptRes = await PutAcceptInventoryTransfer({ id: Number(id) }, false);
    if (acceptRes.status !== StatusCode.OK) {
      dispatch(showError("Error accepting transfer"));
      dispatch(dequeue());
      return;
    }
    dispatch(showSuccess("Successfully accepted transfer"));
    await handleReload();
    dispatch(dequeue());
  }

  async function handleVoid() {
    dispatch(enqueue());
    const acceptRes = await PutVoidInventoryTransfer({ id: Number(id) }, false);
    if (acceptRes.status !== StatusCode.OK) {
      dispatch(showError("Error voiding transfer"));
      dispatch(dequeue());
      return;
    }
    dispatch(showSuccess("Successfully voided transfer"));
    await handleReload();
    dispatch(dequeue());
  }

  async function handleReload() {
    ReactDOM.unstable_batchedUpdates(() => {
      setVoidPopupState(false);
      setAcceptPopupState(false);
      setSubmitPopupState(false);
    });
    await loadInventoryTransfer();
  }

  async function handleDeleteTransfer() {
    const deleteRes = await DeleteInventoryTransfer({ id: Number(id) }, true);
    if (deleteRes.status !== StatusCode.OK) {
      dispatch(showError("Error deleting inventory transfer draft"));
      return;
    }
    dispatch(showSuccess("Successfully deleted inventory transfer draft"));
    history.push("/admin/settings/transfer");
  }

  const editAction: IPrimaryPageAction = {
    content: "Edit",
    action: () =>
      history.push(`/admin/settings/transfer/${id}/edit`, {
        params: {
          inventoryTransfer: inventoryState?.inventoryTransfer,
          inventoryTransferVariants: inventoryState?.inventoryTransferVariants,
        },
      }),
    disabled:
      !inventoryState?.inventoryTransfer ||
      !!inventoryState?.inventoryTransfer?.date_submitted ||
      !!inventoryState?.inventoryTransfer?.date_accepted ||
      inventoryState?.inventoryTransfer?.status === "voided",
  };

  const acceptAction: IPrimaryPageAction = {
    content: "Accept",
    action: () => setAcceptPopupState(true),
    disabled:
      !inventoryState?.inventoryTransfer ||
      !inventoryState?.inventoryTransfer?.date_submitted ||
      !!inventoryState?.inventoryTransfer?.date_accepted ||
      inventoryState?.inventoryTransfer?.status === "voided",
  };

  const destinationIn = facilityStore?.facility?.id !== inventoryState?.inventoryTransfer?.facility_id;

  return (
    <Page
      title={`Transfer: ${!inventoryState?.inventoryTransfer ? "" : inventoryState?.inventoryTransfer?.name}`}
      titleMetadata={
        !inventoryState?.inventoryTransfer ? undefined : (
          <>
            <Badge
              iconRight
              icon={destinationIn ? "arrow-turn-down-left" : "arrow-turn-down-right"}
              type={destinationIn ? "success" : "warning"}
            >
              {destinationIn ? "In" : "Out"}
            </Badge>{" "}
            <Badge type={getTransferBadgeType(inventoryState?.inventoryTransfer?.status)}>
              {valueToString(inventoryState?.inventoryTransfer?.status)}
            </Badge>
          </>
        )
      }
      primaryAction={destinationIn ? acceptAction : editAction}
      multipleActionDropdownAction={
        destinationIn || !!inventoryState?.inventoryTransfer?.date_accepted
          ? undefined
          : {
              label: "Options",
              dropdownProps: {
                alignment: "right",
                options: [
                  {
                    type: "handler",
                    label: "Submit",
                    icon: "circle-check",
                    handler: () => setSubmitPopupState(true),
                    disabled: !inventoryState?.inventoryTransfer || !!inventoryState?.inventoryTransfer?.date_submitted,
                  },
                  {
                    type: "handler",
                    label: "Void",
                    icon: "circle-x",
                    handler: () => setVoidPopupState(true),
                    disabled:
                      !inventoryState?.inventoryTransfer ||
                      inventoryState?.inventoryTransfer?.status !== "awaiting_acceptance",
                  },
                  {
                    type: "handler",
                    label: "Delete",
                    icon: "trash-can-xmark",
                    handler: () => setDeletePopupState(true),
                    disabled:
                      !inventoryState?.inventoryTransfer || inventoryState?.inventoryTransfer?.status !== "draft",
                  },
                ],
              },
            }
      }
      splitLayout
      breadcrumbs={[{ prefix: true, label: "Back to Inventory Transfers", url: "/admin/settings/transfer" }]}
    >
      {inventoryState?.inventoryTransfer === undefined ? (
        <span style={{ display: "flex", justifyContent: "center", margin: "auto" }}>
          <Spin />
        </span>
      ) : (
        <>
          <Page.Section twoThirds>
            <DataTable
              columns={[{ label: "Title" }, { label: "Vendor" }, { label: "Sku" }, { label: "Quantity" }]}
              loading={inventoryState?.products === undefined}
            >
              {inventoryState?.products?.map((product, productIndex) => {
                const singleVariant =
                  product?.variants?.length === 1 && product?.title === product?.variants[0]?.variant_title;
                return (
                  <React.Fragment key={productIndex}>
                    <tr className="inventory-transfer-product-header">
                      <td>{product?.title}</td>
                      <td>{product?.vendor_title}</td>
                      <td>{singleVariant && product?.variants[0]?.sku}</td>
                      <td>{singleVariant && product?.variants[0]?.quantity}</td>
                    </tr>
                    {!singleVariant &&
                      product?.variants?.map((variant, variantIndex) => (
                        <tr key={variantIndex}>
                          <td style={{ textIndent: "3rem" }}>{variant?.variant_title}</td>
                          <td></td>
                          <td>{variant?.sku}</td>
                          <td>{variant?.quantity}</td>
                        </tr>
                      ))}
                  </React.Fragment>
                );
              })}
            </DataTable>
          </Page.Section>
          <Page.Section oneThird>
            <Card>
              <Card.Section title="Transfer Details">
                <FormLayout>
                  <FormLayout.Group>
                    <div className="view-inventory-transfer-info-container">
                      <p className="title">Destination Facility</p>
                      <p>{inventoryState.inventoryTransfer?.destination_facility_long_name}</p>
                    </div>
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <div className="view-inventory-transfer-info-container">
                      <p className="title">Created By</p>
                      <p>{inventoryState.inventoryTransfer?.created_by_full_name}</p>
                    </div>
                    <div className="view-inventory-transfer-info-container">
                      <p className="title">Date Created</p>
                      <p>{moment(inventoryState.inventoryTransfer?.date_created, "YYYY-MM-DD h:mm:ss").format("LL")}</p>
                    </div>
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <div className="view-inventory-transfer-info-container" title="Date Submitted">
                      <p className="title">Date Submitted</p>
                      <p>
                        {inventoryState.inventoryTransfer?.date_submitted &&
                          moment(inventoryState.inventoryTransfer?.date_submitted, "YYYY-MM-DD h:mm:ss").format("LL")}
                      </p>
                    </div>
                    <div className="view-inventory-transfer-info-container">
                      <p className="title">Date Accepted</p>
                      <p>
                        {inventoryState.inventoryTransfer?.date_accepted &&
                          moment(inventoryState.inventoryTransfer?.date_accepted, "YYYY-MM-DD h:mm:ss").format("LL")}
                      </p>
                    </div>
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
            </Card>
          </Page.Section>
        </>
      )}
      <Portal isMounted={submitPopupState}>
        <Popup
          open={submitPopupState}
          onOk={handleSubmit}
          onCancel={() => setSubmitPopupState(false)}
          okText="Submit"
          type="info"
          title={`Submit Transfer for ${inventoryState?.inventoryTransfer?.destination_facility_long_name}?`}
          description={`Transfer cannot be edited after submitting`}
        />
      </Portal>

      <Portal isMounted={acceptPopupState}>
        <Popup
          open={acceptPopupState}
          onOk={handleAccept}
          onCancel={() => setAcceptPopupState(false)}
          okText="Accept"
          type="info"
          title={`Accept Transfer?`}
          description={`Inventory will be updated for the products in this transfer`}
        />
      </Portal>

      <Portal isMounted={voidPopupState}>
        <Popup
          open={voidPopupState}
          onOk={handleVoid}
          onCancel={() => setVoidPopupState(false)}
          okText="Void"
          type="info"
          title={`Void Transfer?`}
          description={`Transfer request will be voided`}
        />
      </Portal>

      <Portal isMounted={deletePopupState}>
        <Popup
          open={deletePopupState}
          onOk={handleDeleteTransfer}
          onCancel={() => setDeletePopupState(false)}
          okText="Delete"
          type="info"
          title={`Delete Transfer Draft?`}
          description={`Transfer draft will be deleted. This cannot be undone.`}
        />
      </Portal>
    </Page>
  );
}

export function getTransferBadgeType(status: string) {
  switch (status) {
    case "draft":
      return "gray";
    case "awaiting_approval":
      return "warning";
    case "awaiting_acceptance":
      return "warning";
    case "accepted":
      return "success";
    case "voided":
      return "error";
    default:
      return "gray";
  }
}
