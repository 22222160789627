import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName, IconPrefix } from "@fortawesome/fontawesome-common-types";
import "components/icon/icon.scss";

interface IIconProps {
  icon: string;
  type?: "primary" | "gray" | "error" | "info" | "warning" | "success";
  theme?: "light-circle" | "light-circle-outline";
  size?: "xsmall" | "small" | "medium" | "large" | "xlarge";
  className?: string;
}

const Icon: React.FC<IIconProps> = props => {
  const { icon, type, theme, size, className } = props;

  const iconCls = classNames(
    "ui-feature-icon",
    {
      [`ui-feature-icon-${props.size}`]: props.size,
      [`ui-feature-icon-${props.type}`]: props.type,

      "ui-feature-icon-light-circle":
        props.theme === "light-circle" || props.theme === null || props.theme === undefined,
      "ui-feature-icon-light-circle-outline": props.theme === "light-circle-outline",
    },
    className,
  );

  return (
    <span className={iconCls}>
      <FontAwesomeIcon icon={["far", icon as IconName]} className="ui-feature-icon-svg" />
    </span>
  );
};

export default Icon;
