import { APIGet, APIPost, APIPut } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseAdminUrl = "/" + apiVersion + "/" + adminType + "/client/admin";

interface IPutFacilityAdmin {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  username: string;
  quick_code: number;
}

interface IPutFacilityAdminPermissions {
  user_id: number;
  accounts_charge_all: boolean;
  accounts_post_adjustment: boolean;
  accounts_import: boolean;
  admin_accounts_disable: boolean;
  admin_permissions_edit: boolean;
  admin_accounts_edit: boolean;
  products_archive: boolean;
  products_create: boolean;
  products_edit: boolean;
  products_edit_cost: boolean;
  products_edit_price: boolean;
  orders_remove_line_item: boolean;
  orders_remove_line_item_after_send: boolean;
  orders_edit_line_item_price: boolean;
  orders_apply_discount: boolean;
  orders_void: boolean;
  orders_refund_tip: boolean;
  reports_access: boolean;
  tee_sheet_credit_card_override: boolean;
  tee_sheet_charge_no_show: boolean;
  orders_edit_user: boolean;
  orders_edit_register: boolean;
  orders_backdate: boolean;
  table_service_table_override: boolean;
  reservations_credit_card_override: boolean;
  merge_customers: boolean;

  cash_outs_create: boolean;
  cash_outs_edit: boolean;
  cash_outs_view_summary: boolean;

  memberships_apply_manual: boolean;
  user_edit_customer_type: boolean;
  discounts_create: boolean;
  gift_cards_view: boolean;
  customers_edit_note: boolean;
  bookings_edit_note: boolean;
  gift_cards_import: boolean;

  courses_create: boolean;
  divisions_create: boolean;

  payment_options_create: boolean;
}

interface IPutDisableFacilityAdmin {
  admin_id: number;
}

interface IPutReactivateFacilityAdmin {
  admin_id: number;
}

export async function GetFacilityAdmin(params: any, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseAdminUrl, params, useGlobalLoader, token);
}

export async function PostFacilityAdmin(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseAdminUrl, params, useGlobalLoader);
}

export async function PutFacilityAdmin(params: IPutFacilityAdmin, useGlobalLoader: boolean) {
  return await APIPut(baseAdminUrl, params, useGlobalLoader);
}

export async function PutFacilityAdminPermissions(params: IPutFacilityAdminPermissions, useGlobalLoader: boolean) {
  return await APIPut(baseAdminUrl + "/permission", params, useGlobalLoader);
}

export async function PutDisableFacilityAdmin(params: IPutDisableFacilityAdmin, useGlobalLoader: boolean) {
  return await APIPut(baseAdminUrl + "/disable", params, useGlobalLoader);
}

export async function PutReactivateFacilityAdmin(params: IPutReactivateFacilityAdmin, useGlobalLoader: boolean) {
  return await APIPut(baseAdminUrl + "/reactivate", params, useGlobalLoader);
}
