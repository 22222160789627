import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import "./progressSteps.scss";

export interface IProgressStep {
  statuses: string[];
  title: string;
  description: string;
}

interface IProgressStepsProps {
  currentStatus: string;
  progressSteps: IProgressStep[];
  finishedStatuses: string[];
  error: string;
}

interface IProgressStepsState {
  currentStepIndex: number;
}

type ProgressStepCompletionStatus = "incomplete" | "current" | "complete";

export default function ProgressSteps(props: IProgressStepsProps) {
  const { currentStatus, progressSteps, finishedStatuses, error } = props;

  const [progressStepsState, setProgressStepsState] = useState<IProgressStepsState>({
    currentStepIndex: -1,
  });

  function isEmpty(value: string) {
    return value === "" || value === null || value === undefined;
  }

  useEffect(() => {
    const newCurrentStepIndex = progressSteps.findIndex(progressStep => {
      return progressStep.statuses.find(status => status === currentStatus) !== undefined;
    });

    setProgressStepsState(prev => ({
      ...prev,
      currentStepIndex: newCurrentStepIndex,
    }));
  }, [currentStatus]);

  function renderProgressStepIcon(
    completionStatus: ProgressStepCompletionStatus,
    isLastStep: boolean,
    errorExists: boolean,
  ) {
    if (completionStatus === "incomplete") {
      return (
        <div className="progress-step-tertiary-circle-invisible">
          {!isLastStep && <div className="progress-step-tertiary-connector-faded" />}
          <div className="progress-step-secondary-circle-incomplete">
            <div className="progress-step-primary-circle-incomplete" />
          </div>
        </div>
      );
    } else if (completionStatus === "current") {
      return (
        <div className={`progress-step-tertiary-circle${errorExists ? "-current-error" : ""}`}>
          {!isLastStep && <div className="progress-step-tertiary-connector-faded" />}
          <div className={`progress-step-secondary-circle-current${errorExists ? "-error" : ""}`}>
            {errorExists ? (
              <FontAwesomeIcon className="progress-step-error-icon" icon={["far", "x"]} />
            ) : (
              <div className="progress-step-primary-circle" />
            )}
          </div>
        </div>
      );
    } else if (completionStatus === "complete") {
      return (
        <div className="progress-step-tertiary-circle-invisible">
          {!isLastStep && <div className="progress-step-tertiary-connector" />}
          <div className="progress-step-secondary-circle">
            <FontAwesomeIcon className="progress-step-primary-check-icon" icon={["far", "check"]} />
          </div>
        </div>
      );
    }
  }

  return (
    <div className="progress-steps-container">
      {progressSteps.map((progressStep, index) => {
        let completionStatus: ProgressStepCompletionStatus = "incomplete";
        const isLastStep = index === progressSteps.length - 1;
        const errorExists = !isEmpty(error);

        if (finishedStatuses.some(finishedStatus => finishedStatus === currentStatus)) {
          completionStatus = "complete";
        } else if (progressStepsState.currentStepIndex === -1) {
          completionStatus = "incomplete";
        } else if (index < progressStepsState.currentStepIndex) {
          completionStatus = "complete";
        } else if (index === progressStepsState.currentStepIndex) {
          completionStatus = "current";
        } else if (index > progressStepsState.currentStepIndex) {
          completionStatus = "incomplete";
        }

        return (
          <div key={index} className="progress-step">
            {renderProgressStepIcon(completionStatus, isLastStep, errorExists)}
            <div className="progress-step-content">
              <div className="progress-step-content-title">{progressStep.title}</div>
              <div className="progress-step-content-description">
                {completionStatus === "current" && errorExists ? error : progressStep.description}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
