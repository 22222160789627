import { GetPaymentOptions } from "api/rpc/facility";
import { GetDiscount } from "api/rpc/2022-09/facilityAdmin/product/discount";
import { LocalStorage } from "api/localstorage";
// import { ICartState } from "reducers/cart";
import { StatusCode } from "api/protocols";
import { GetWeather } from "api/rpc/weather/weather";
import {
  GetFacilityPrinterSettings,
  GetFacilityTeeSheetSettings,
  IPutPrinterSettings,
  IPutTeeSheetSettings,
  PutFacilityPrinterSettings,
  PutFacilityTeeSheetSettings,
} from "api/rpc/2022-09/facilityAdmin/facility/settings";
import { GetKitchenMeals } from "api/rpc/2022-09/facilityAdmin/facility/kitchen";
import { IKitchenCourse } from "pages/secure/facility/settings/kitchenCourse/KitchenCourse";
import { GetFacility } from "api/rpc/2024-04/facilityAdmin/facility/facility";
import { GetProductRequirements } from "api/rpc/2024-04/facilityAdmin/client/requirements";
import { CancelToken } from "axios";
import { TProductInputsRequired, TProductRequirements } from "redux/reducers/models/product";
import { GetRegisters } from "api/rpc/2024-04/facilityAdmin/facility/register/register";
import { GetFacilityAdmin } from "api/rpc/2024-04/facilityAdmin/client/admin/admin";

export function loadAdminFacility(params: any, useGlobalLoader: boolean) {
  return async (dispatch: any) => {
    const res = await GetFacility(params, useGlobalLoader);
    if (res.status !== StatusCode.OK) {
      console.log(res);
      return;
    }

    console.log("Admin Facility: ", res.data[0]);

    dispatch({
      type: "facility.update",
      payload: {
        isLoaded: true,
        facility: res.data[0],
      },
    });
  };
}

export function loadPaymentOptions(token?: CancelToken) {
  return async (dispatch: any, getState: any) => {
    if (getState().facilityStore.paymentOptions.length === 0) {
      try {
        const paymentRes = await GetPaymentOptions(null, token ? false : true, token);
        if (token && token.reason) {
          return;
        }

        console.log("paymentRes: ", paymentRes);
        if (paymentRes.status === StatusCode.OK) {
          // push options to redux

          const paymentOptions = paymentRes.data;

          dispatch({
            type: "facility.update",
            payload: {
              isLoaded: true,
              paymentOptions: paymentOptions,
            },
          });
        }
      } catch (e) {
        console.log("GetPaymentOptions ERR:", e);
      }
    }
  };
}

export function loadRegisters(token?: CancelToken) {
  return async (dispatch: any, getState: any) => {
    if (getState().facilityStore.registers.length === 0) {
      try {
        const registersRes = await GetRegisters(null, false, token);

        if (token && token.reason) {
          return;
        }

        console.log("registersRes: ", registersRes);
        if (registersRes.status === StatusCode.OK) {
          // push options to redux

          const registers = registersRes.data;

          dispatch({
            type: "facility.update",
            payload: {
              isLoaded: true,
              registers: registers,
            },
          });
        }
      } catch (e) {
        console.log("GetRegisters ERR:", e);
      }
    }
  };
}

export function loadStaffAccounts(token?: CancelToken) {
  return async (dispatch: any, getState: any) => {
    if (getState().facilityStore.staffAccounts.length === 0) {
      try {
        const usersRes = await GetFacilityAdmin(null, false);

        if (token && token.reason) {
          return;
        }

        console.log("staffAccountRes: ", usersRes);
        if (usersRes.status === StatusCode.OK) {
          // push options to redux

          const staffAccounts = usersRes.data;

          dispatch({
            type: "facility.update",
            payload: {
              isLoaded: true,
              staffAccounts: staffAccounts,
            },
          });
        }
      } catch (e) {
        console.log("GetStaffAccounts ERR:", e);
      }
    }
  };
}

export function loadDiscountOptions() {
  return async (dispatch: any, getState: any) => {
    if (getState().facilityStore.discountOptions.length === 0) {
      try {
        const discountRes = await GetDiscount();
        console.log("discounttRes: ", discountRes);
        if (discountRes.status === StatusCode.OK) {
          // push options to redux

          const discountOptions = discountRes.data;

          dispatch({
            type: "facility.update",
            payload: {
              isLoaded: true,
              discountOptions: discountOptions,
            },
          });
        }
      } catch (e) {
        console.log("GetDiscount ERR:", e);
      }
    }
  };
}

export function loadFacilityWeather(facility_id: number, useGlobalLoader: boolean) {
  return async (dispatch: any) => {
    const res = await GetWeather(facility_id, useGlobalLoader);

    if (res.status !== StatusCode.OK) {
      console.log(res);
      return;
    }

    // console.log("Facility Weather: ", res.data);

    dispatch({
      type: "facility.update",
      payload: {
        weather: res.data,
      },
    });
  };
}

export function updateFacilityTeeSheetSettings(params: IPutTeeSheetSettings, useGlobalLoader: boolean) {
  return async (dispatch: any) => {
    const res = await PutFacilityTeeSheetSettings(params, useGlobalLoader);
    if (res.status !== StatusCode.OK) {
      console.log(res.message);
      return;
    }

    dispatch({
      type: "facility.update",
      payload: {
        teeSheetSettings: {
          ...params,
        },
      },
    });
  };
}

export function loadFacilityTeeSheetSettings() {
  return async (dispatch: any) => {
    const settingsRes = await GetFacilityTeeSheetSettings(null, false);
    if (settingsRes.status !== StatusCode.OK) {
      console.log("ERROR", settingsRes);
      return;
    }

    console.log("Facility TeeSheetSettings: ", settingsRes);

    dispatch({
      type: "facility.update",
      payload: {
        teeSheetSettings: {
          ...(settingsRes.data as Partial<IPutTeeSheetSettings>),
        },
      },
    });
  };
}

export function loadFacilityPrinterSettings() {
  return async (dispatch: any) => {
    const settingsRes = await GetFacilityPrinterSettings(false);
    if (settingsRes.status !== StatusCode.OK) {
      console.log("ERROR", settingsRes);
      return;
    }

    console.log("Facility TeeSheetSettings: ", settingsRes);

    dispatch({
      type: "facility.update",
      payload: {
        printerSettings: {
          ...(settingsRes.data as Record<string, any>),
        },
      },
    });
  };
}

export function updateFacilityPrinterSettings(params: IPutPrinterSettings, useGlobalLoader: boolean) {
  return async (dispatch: any) => {
    const res = await PutFacilityPrinterSettings(params, useGlobalLoader);
    if (res.status !== StatusCode.OK) {
      console.log(res.message);
      return;
    }

    dispatch({
      type: "facility.update",
      payload: {
        printerSettings: {
          ...(res.data as Record<string, any>),
        },
      },
    });
  };
}

export function loadFacilityKitchenCourses(params: { id: number }, useGlobalLoader?: boolean) {
  return async (dispatch: any) => {
    const res = await GetKitchenMeals(params, useGlobalLoader ?? true);
    if (res.status !== StatusCode.OK) {
      return;
    }

    //Sort by course position
    const sortedCourses = res?.data?.sort((currentCourse: IKitchenCourse, nextCourse: IKitchenCourse) => {
      if (currentCourse?.position > nextCourse?.position) {
        return 1;
      } else if (currentCourse?.position < nextCourse?.position) {
        return -1;
      } else {
        return 0;
      }
    });

    dispatch({
      type: "facility.update",
      payload: {
        kitchenCourses: sortedCourses,
      },
    });
  };
}

export function loadAllFacilities(params: any, useGlobalLoader: boolean) {
  return async (dispatch: any) => {
    const res = await GetFacility(params, useGlobalLoader);
    if (res.status !== StatusCode.OK) {
      console.log(res);
      return;
    }

    console.log("All Facilities: ", res.data[0]);

    dispatch({
      type: "facility.update",
      payload: {
        isLoaded: true,
        facilities: res.data,
      },
    });
  };
}

export function loadProductValidation(token?: CancelToken) {
  return async (dispatch: any, getState: any) => {
    if (getState().facilityStore.validationNewProduct.required === undefined) {
      const res = await GetProductRequirements(null, token ? false : true, token);
      if (token && token.reason) {
        return;
      }
      if (res.status !== StatusCode.OK) {
        return;
      }

      dispatch({
        type: "facility.update",
        payload: {
          isLoaded: true,
          validationNewProduct: {
            required: {
              ...(getState().facilityStore.validationNewProduct.required as TProductInputsRequired),
              ...(res.data as TProductRequirements),
            },
          },
        },
      });
    }
  };
}

export interface IFacilityActions {
  loadAdminFacility: typeof loadAdminFacility;
  loadAllFacilities: typeof loadAllFacilities;
  loadFacilityWeather: typeof loadFacilityWeather;
  loadPaymentOptions: typeof loadPaymentOptions;
  loadDiscountOptions: typeof loadDiscountOptions;
  loadFacilityTeeSheetSettings: typeof loadFacilityTeeSheetSettings;
  updateFacilityTeeSheetSettings: typeof updateFacilityTeeSheetSettings;
  loadFacilityPrinterSettings: typeof loadFacilityPrinterSettings;
  updateFacilityPrinterSettings: typeof updateFacilityPrinterSettings;
  loadFacilityKitchenCourses: typeof loadFacilityKitchenCourses;
  loadProductValidation: typeof loadProductValidation;
  loadRegisters: typeof loadRegisters;
  loadStaffAccounts: typeof loadStaffAccounts;
}
