import React, { useEffect, useState } from "react";
import { Link, Route, Switch, Redirect, useHistory, useLocation } from "react-router-dom";
import { useRouteMatch } from "react-router";
import ReactDOM from "react-dom";
// API
import { PostConnection } from "api/rpc/facilityAdmin/payment/teeOnPayments/reader";

import TeeSheet from "containers/facility/teesheet";
import TeeSheetEdit from "./teesheet/TeeSheetEdit";
import TeeSheetDetails from "containers/facility/teeSheetDetails";

import NewTeeSheet from "./teesheet/newTeeSheet/NewTeeSheet";

import Dashboard from "./dashboard/Dashboard";
import Register from "containers/facility/register";
import TableService from "containers/facility/tableService";
import TableSelection from "containers/facility/tableSelection";

import Reports from "pages/secure/facility/reports/Reports"; //needs container
import Report from "pages/secure/facility/reports/Report";
import reportRoutes from "./reports/ReportRoutes";

import ProductMenu from "containers/facility/product"; // < ----

import Order from "pages/secure/facility/order/Order"; // < ----
import Orders from "pages/secure/facility/order/Orders"; // need containers
import Refund from "containers/facility/orderRefund"; // ----->

import Tickets from "containers/facility/ticket";
import TicketsNew from "pages/secure/facility/product/TicketNew";
import TicketEdit from "pages/secure/facility/product/TicketEdit";

import Leagues from "pages/secure/facility/events/league/Leagues";
import LeagueNew from "pages/secure/facility/events/league/LeagueNew";
import LeaguePlayers from "pages/secure/facility/events/league/players/LeaguePlayers";
import League from "./events/league/League";

import Tournaments from "./events/tournament/Tournaments";
import TournamentNew from "./events/tournament/TournamentNew";
import Tournament from "./events/tournament/Tournament";
import TournamentSettings from "./events/tournament/settings/TournamentSettings";
import TournamentScoreSettings from "./events/tournament/settings/scoring/ScoreSettings";

import HospitalityEvents from "./events/hospitality/HospitalityEvents";
import HospitalityEventNew from "./events/hospitality/HospitalityEventNew";
import HospitalityEvent from "./events/hospitality/HospitalityEvent";

import Settings from "./settings/Settings";
import Reservation from "containers/facility/reservation/reservation";
import EditSegments from "containers/reservations/editSegments";

import Layout from "components/layout/index";
import Menu from "components/menu/index";
import Checkbox from "components/form/checkbox/Checkbox";
import Icon from "components/icon/Icon";
import { Title } from "components/typography/index";
import DateSelector from "components/dateSelector/dateSelector";
import Input from "components/form/input/Input";
import { ButtonNew as Button, ButtonNew } from "components/buttonNew/index";
import { Select } from "components/select/index";
import Spin from "components/spin/spin";

import Moment from "react-moment";
import moment from "moment";

import { mainMenu } from "routes/menuOptions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "public/scss/admin.scss";
import Divider from "components/divider";

import { GetTeeSheets } from "api/rpc/teeSheet/teeSheet";
import classNames from "classnames";

import { IUIAction, IUIState } from "redux/reducers/ui";
import { IFacilityState } from "redux/reducers/facility";

import { IUIActions } from "redux/actions/ui";
import { IAuthActions } from "redux/actions/auth";
import { IFacilityActions } from "redux/actions/facility";
import { ITerminalActions, setError, setReaderArray } from "redux/actions/facilityAdmin/terminal";

import { SwitchUser, UserLogout } from "../../../api/rpc";
import { StatusCode } from "../../../api/protocols";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import {
  connectReader,
  disconnectReader,
  discoverReaders,
  MAX_RECONNECT_ATTEMPTS,
  reader,
  reconnectReader,
  setupStripe,
} from "helpers/StripeTerminalWrapper";
import TerminalReducer, { ITerminalState } from "redux/reducers/facilityAdmin/terminal";
import Sheet from "components/sheet/Sheet";
import { IAuthState } from "redux/reducers/auth";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import POSPrinterStatus from "components/posPrinterStatus/POSPrinterStatus";
import kitchenDisplaySystem from "containers/facility/kitchen/kitchenDisplaySystem";
import SwitchUserModal from "components/switchUserModal/SwitchUserModal";
import { isPositiveInteger } from "helpers/Helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import LeftSubMenu from "../../../components/leftMenu/LeftSubMenu";

//Settings Submenu
import settingsOptions from "pages/secure/facility/settings/settingsOptions";
import settingsRoutes from "pages/secure/facility/settings/settingsRoutes";

//Orders Submenu
import orderOptions from "./order/OrderOptions";
import orderRoutes from "./order/OrderRoutes";

//Products Submenu
import productOptions from "./product/productOptions";
import productRoutes from "./product/productRoutes";

//Customers Submenu
import customerOptions from "./customer/customerOptions";
// import customerRoutes from "./customer/customerRoutes";

//Events Submenu
import eventOptions from "./events/EventOptions";
// import eventRoutes from "./events/EventRoutes";

import { useTranslation, Trans } from "react-i18next";
import LeagueAnnouncements from "./events/league/announcements/LeagueAnnouncements";
import LeagueRounds from "./events/league/scoring/rounds/LeagueRounds";
import LeagueScoresheetNew from "./events/league/scoring/scoresheets/LeagueScoresheetNew";
import LeagueTeams from "./events/league/scoring/divisions/teams/LeagueTeams";
import LeagueTeamNew from "./events/league/scoring/divisions/teams/LeagueTeamsNew";
import LeaguePlayerManage from "./events/league/scoring/divisions/teams/LeagueTeamManagePlayers";
import LeagueTeamsEdit from "./events/league/scoring/divisions/teams/LeagueTeamsEdit";
import LeagueFlights from "./events/league/scoring/divisions/flights/LeagueFlights";
import LeagueFlightsNew from "./events/league/scoring/divisions/flights/LeagueFlightsNew";
import LeagueFlightPlayerManage from "./events/league/scoring/divisions/flights/LeagueFlightManagePlayers";
import LeagueFlightsEdit from "./events/league/scoring/divisions/flights/LeagueFlightEdit";
import LeagueScoresheetEdit from "containers/facility/leagues/leagueScoreSheetEdit";

import leagueOptions from "./events/league/LeagueOptions";
import LeagueRegistrationLanding from "./events/league/settings/registration/LeagueRegistrationLanding";
import SingleLeagueSettings from "./events/league/settings/LeagueSettings";
import TournamentPlayers from "./events/tournament/players/TournamentPlayers";
import TournamentScores from "./events/tournament/scoring/scoresheets/TournamentScores";
import tournamentOptions from "./events/tournament/TournamentOptions";
import LeagueScoresheet from "./events/league/scoring/scoresheets/LeagueScoresheet";
import LeagueLeaderboard from "./events/league/scoring/scoresheets/results/LeagueLeaderboard";
import { SupportTab } from "components/supportTab/SupportTab";
import { GetRegisterGroups } from "api/rpc/2022-09/facilityAdmin/facility/register/group";
import LeagueSkins from "./events/league/scoring/scoresheets/results/LeagueSkins";
import LeagueSkin from "./events/league/scoring/scoresheets/results/LeagueSkin";
import LeagueSeasonLeaderboard from "./events/league/scoring/leaderboards/SeasonLeaderboard";
import Leaderboards from "./events/league/scoring/leaderboards/Leaderboards";
import LeaderboardTotals from "./events/league/scoring/leaderboards/LeaderboardTotals";
import LeagueRingerBoard from "./events/league/settings/scoring/LeagueRingerBoard";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { MOBILE_WIDTH } from "helpers/ScreenSizes";
import LeagueTvLeaderboard from "./events/league/scoring/scoresheets/results/LeagueTvLeaderboard";
import Customers from "./customer/main/Customers";
import CustomerProvider from "./customer/context/CustomerProvider";
import LeagueOrganizers from "./events/league/settings/organizers/LeagueOrganizers";
import TournamentOrganizers from "./events/tournament/settings/organizers/TournamentOrganizers";
import MembershipsSold from "./customer/main/MembershipsSold";
import RegistersModal from "elements/register/RegistersModal";
import TournamentScoresheet from "./events/tournament/scoring/scoresheets/TournamentScoresheet";
import TournamentScoresheetNew from "./events/tournament/scoring/scoresheets/TournamentScoresheetNew";
import TournamentScoresheetEdit from "./events/tournament/scoring/scoresheets/TournamentScoresheetEdit";
import TournamentLeaderboard from "./events/tournament/scoring/scoresheets/results/TournamentLeaderboard";
import axios from "axios";
import LeagueStatistics from "./events/league/scoring/statistics/LeagueStatistics";
import LeagueTvViews from "./events/league/scoring/tvViews/TvViews";
import LeagueTvViewNew from "./events/league/scoring/tvViews/TvViewNew";
import LeagueTvViewEdit from "./events/league/scoring/tvViews/TvViewEdit";
import LeagueTvView from "./events/league/scoring/tvViews/TvView";
import TournamentTeams from "./events/tournament/scoring/teams/TournamentTeams";
import TournamentTeamNew from "./events/tournament/scoring/teams/TournamentTeamNew";
import TournamentTeam from "./events/tournament/scoring/teams/TournamentTeamEdit";
import TournamentFlights from "./events/tournament/scoring/flights/TournamentFlights";
import TournamentFlightEdit from "./events/tournament/scoring/flights/TournamentFlightEdit";
import TournamentFlightNew from "./events/tournament/scoring/flights/TournamentFlightNew";
import TournamentRegistrationLanding from "./events/tournament/settings/registration/TournamentRegistrationLanding";
import LeagueDivisions from "./events/league/scoring/divisions/LeagueDivisions";
import LeagueDivisionsNew from "./events/league/scoring/divisions/LeagueDivisionsNew";
import LeagueDivisionsManagePlayers from "./events/league/scoring/divisions/LeagueDivisionsManagePlayers";
import LeagueDivisionEdit from "./events/league/scoring/divisions/LeagueDivisionEdit";
import CustomerSegments from "./marketing/CustomerSegments";
import marketingOptions from "./marketing/MarketingOptions";
import NewCustomerSegment from "./marketing/NewCustomerSegment";
import LeagueTeeTimes from "./events/league/settings/teeTimes/LeagueTeeTimes";
import LeaguePrizeAccounts from "./events/league/prizeAccounts/PrizeAccounts";
import PrizeAccount from "./settings/prizeAccounts/PrizeAccount";
import PrizeAccountView from "./settings/prizeAccounts/PrizeAccountView";
import PrizeAccountNew from "./settings/prizeAccounts/PrizeAccountNew";
import TournamentPrizeAccounts from "./events/tournament/prizeAccounts/PrizeAccounts";
import LeagueRoundOverview from "./events/league/scoring/rounds/LeagueRoundOverview";
import LeagueScoringFormatNew from "./events/league/scoring/rounds/scoringFormat/LeagueScoringFormatNew";
import ViewCustomerSegment from "./marketing/viewCustomerSegment/ViewCustomerSegment";
import EditCustomerSegment from "./marketing/editCustomerSegment/EditCustomerSegment";
import LeagueScoringFormatEdit from "./events/league/scoring/rounds/scoringFormat/LeagueScoringFormatEdit";
import LeagueFormatTemplates from "./events/league/settings/formatTemplates/FormatTemplates";
import LeagueFormatTemplateNew from "./events/league/settings/formatTemplates/FormatTemplateNew";
import LeagueFormatTemplate from "./events/league/settings/formatTemplates/FormatTemplate";
import RegisterNew from "./register/registerNew/RegisterNew";
import RegisterContextProvider from "./register/registerNew/RegisterContextProvider";
import TableServiceContextProvider from "./tableService/newTableService/TableServiceContextProvider";
import TeeSheetDetailsContextProvider from "./teesheet/newTeeSheetDetails/TeeSheetDetailsContextProvider";

export interface ISubOptions {
  label: string;
  url: string;
  icon?: IconName;
  subOptions?: ISubOptions[];
}

export interface IOptions {
  label: string;
  url?: string;
  icon?: IconName;
  subOptions?: ISubOptions[];
  secondOptionHeader?: boolean;
}

interface IProps {
  authActions: IAuthActions;
  facilityActions: IFacilityActions;
  facilityStore: IFacilityState;
  terminalActions: ITerminalActions;
  terminalStore: ITerminalState;
  uiStore: IUIState;
  uiActions: IUIActions;
  authStore: IAuthState;
}

export interface IRegisterGroup {
  default: boolean;
  facility_id: number;
  id: number;
  registers: IRegister[];
  stripe_bbpos_wisepos_reader_settings_id: string;
  stripe_configuration_id: string;
  stripe_location_id: string;
  stripe_verifone_p400_reader_settings_id: string;
  tip_fixed_amount_high: null | number;
  tip_fixed_amount_low: null | number;
  tip_fixed_amount_mid: null | number;
  tip_percentage_high: null | number;
  tip_percentage_low: null | number;
  tip_percentage_mid: null | number;
  tip_smart_threshold: null | number;
  tipping_type: string;
  tipping_type_readable: string;
  title: string;
}

export interface IRegister {
  auto_log_out: boolean;
  default_card_reader_id: number | null;
  default_floor_plan_id: number | null;
  default_folder_id: number | null;
  facility_id: number;
  id: number;
  print_by_default: boolean;
  register_group?: IRegisterGroup;
  register_group_id: number;
  send_kitchen: boolean;
  title: string;
}

interface IPath {
  name: string;
  title: string;
  icon: IconName;
  options?: Array<IOptions>;
  OnClickTitle?: () => void;
}

interface IRouteMatch {
  leagueId: string;
  tournamentId: string;
}

// Use this to toggle simulator
const StripeTerminalSimulation = false;

const FacilityAdmin: React.FC<IProps> = props => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const baseLeagueUrl = "/admin/league/:leagueId";
  const leagueId = parseInt(useRouteMatch<IRouteMatch>(baseLeagueUrl)?.params?.leagueId ?? "");
  const baseTournamentUrl = "/admin/tournament/:tournamentId";
  const tournamentId = parseInt(useRouteMatch<IRouteMatch>(baseTournamentUrl)?.params?.tournamentId ?? "");
  const { uiStore, facilityStore, facilityActions, authActions, terminalStore, terminalActions, uiActions, authStore } =
    props;

  const permissions = props?.authStore?.user?.permissions;
  // const facilityPermissions = props?.facilityStore?.facility?.permissions;

  useEffect(() => {
    const source = axios.CancelToken.source();

    void facilityActions.loadAdminFacility({ client: true }, false);
    void facilityActions.loadFacilityTeeSheetSettings();
    void facilityActions.loadProductValidation(source.token);
    void facilityActions.loadFacilityPrinterSettings();
    void facilityActions.loadFacilityKitchenCourses(null, false);
    void facilityActions.loadPaymentOptions(source.token);
    void facilityActions.loadRegisters(source.token);
    void facilityActions.loadStaffAccounts(source.token);

    void facilityActions.loadAllFacilities(
      {
        "client-facilities": true,
      },
      true,
    );

    // default reader group?
    const registerGroup: undefined | IRegisterGroup = JSON.parse(
      localStorage.getItem("register_group"),
    ) as IRegisterGroup;

    // setDefaultRegisterGroup(registerGroup);
    void setupStripe(registerGroup?.id);

    return () => source.cancel();
  }, []);

  // Reader - handle reconnection
  useEffect(() => {
    console.log("Terminal Store", terminalStore);
    if (terminalStore.readers.length !== 0) {
      if (
        terminalStore.reconnectionAttempts < MAX_RECONNECT_ATTEMPTS &&
        !terminalStore.reader &&
        terminalStore.status !== "Reconnecting"
      ) {
        if (props.facilityStore.facility.terminal_integration !== "server") {
          uiActions.showError(
            t("secure.facility.admin.001") +
              String(Number(terminalStore.reconnectionAttempts + 1)) +
              "/" +
              String(MAX_RECONNECT_ATTEMPTS),
          );
        }

        console.log("Reconnection Attempt " + String(terminalStore.reconnectionAttempts), terminalStore.status);
        void attemptReconnect();
      }
    }

    // Set to not connected because we have failed the max number of attempts
    // if (terminalStore.reconnectionAttempts === MAX_RECONNECT_ATTEMPTS) {
    //   terminalActions.setStatus("Not Connected", false);
    // }
  }, [terminalStore.readers, terminalStore.reconnectionAttempts]);

  useEffect(() => {
    const switchUserAfterTransaction = localStorage.getItem("switch_user_after_transaction");
    if (switchUserAfterTransaction === "true") {
      setSwitchUserAfterTransaction(true);
    } else {
      setSwitchUserAfterTransaction(false);
    }
  }, [localStorage.getItem("switch_user_after_transaction")]);

  // async function loadReader(id?: number) {
  //   const registerGroup = JSON.parse(localStorage.getItem("register_group"));
  //   if (registerGroup == null) {
  //     uiActions.showError(t("secure.facility.admin.016"));
  //     return;
  //   }
  //   try {
  //     uiActions.enqueue();
  //     const setup = await setupStripe(registerGroup ? registerGroup.id : id);
  //     const readers = await discoverReaders(
  //       StripeTerminalSimulation,
  //       registerGroup ? registerGroup.stripe_location_id : undefined,
  //     );
  //   } catch (e) {
  //     uiActions.showError(t("secure.facility.admin.002"));
  //     uiActions.dequeue();
  //     return;
  //   }
  //   uiActions.dequeue();
  // }

  async function attemptReconnect() {
    const defaultTerminal = localStorage.getItem("defaultTerminal");
    if (defaultTerminal) {
      await reconnectReader(terminalStore.readers, t, undefined, message => {
        // Specific reconnection error
        setError("reconnection_error", false);
        uiActions.showError(message);
      });
    } else {
      uiActions.showError(t("secure.facility.admin.003"), 3000);
    }
  }

  const location = useLocation();

  const [currentRoute, setCurrentRoute] = useState(undefined);
  const [currentPath, setCurrentPath] = useState<IPath>(undefined);
  const [mobileLeftMenuVisible, setMobileLeftMenuVisible] = useState<boolean>(false);
  const windowSize = useWindowSize();

  // const onCollapse = () => {
  //   setCollapsed(!collapsed);
  // };

  // const [value, setValue] = useState<any>();

  const adminContentCls = classNames("ui-main_content", {
    "no-scroll": location.pathname === "/admin/teesheet",
    "ui-main_content-teesheet": location.pathname === "/admin/teesheet",
  });

  async function logoutUser() {
    const logoutRes = await UserLogout(true);
    localStorage.setItem("switch_user_after_transaction", "false");
    if (logoutRes.status !== StatusCode.OK) {
      authActions.userLogout();
    }
    if (logoutRes.status === StatusCode.OK) {
      history.push("/");
    }
  }

  const reportPath: { name: string; title: string; icon: IconName } = {
    name: "report",
    title: t("secure.facility.admin.004"),
    icon: "file-chart-line",
  };
  const teesheetPath: { name: string; title: string; icon: IconName } = {
    name: "teesheet",
    title: t("secure.facility.admin.005"),
    icon: "stream",
  };
  const reservationPath: { name: string; title: string; icon: IconName } = {
    name: "reservation",
    title: t("secure.facility.admin.008"),
    icon: "calendar",
  };

  const paths: {
    name: string;
    title: string;
    icon: IconName;
    hidden?: boolean;
    options?: Array<IOptions>;
    OnClickTitle?: () => void;
  }[] = [
    // { name: "dashboard", title: "Dashboards", icon: "chart-line" },
    ...(facilityStore?.facility?.module_tee_sheet ? [teesheetPath] : []),

    // { name: "teesheet", title: t("secure.facility.admin.005"), icon: "stream" },
    { name: "register", title: t("secure.facility.admin.006"), icon: "burger-soda" },
    { name: "table-selection", title: t("secure.facility.admin.007"), icon: "plate-utensils" },
    // { name: "reservation", title: t("secure.facility.admin.008"), icon: "calendar" },
    ...(facilityStore?.facility?.module_reservations ? [reservationPath] : []),

    { name: "order", title: t("secure.facility.admin.009"), icon: "box-open", options: orderOptions() },
    { name: "product", title: t("secure.facility.admin.010"), icon: "tag", options: productOptions() },
    { name: "customers", title: t("secure.facility.admin.011"), icon: "user", options: customerOptions() },
    { name: "events", title: t("secure.facility.admin.012"), icon: "trophy", options: eventOptions() },
    {
      name: "league",
      title: t("secure.facility.admin.039"),
      icon: "trophy",
      hidden: true,
      options: leagueOptions(t, leagueId),
      OnClickTitle: () => history.push("/admin/events"),
    },
    {
      name: "tournament",
      title: t("secure.facility.admin.040"),
      icon: "trophy",
      hidden: true,
      options: tournamentOptions(t, tournamentId),
    },
    { name: "settings", title: t("secure.facility.admin.013"), icon: "cog", options: settingsOptions() },
    ...(permissions?.reports_access ? [reportPath] : []),
    { name: "marketing", title: "Marketing", icon: "megaphone", options: marketingOptions() },
  ];

  useEffect(() => {
    const routeName = history.location.pathname.split("/");
    if (currentRoute === undefined) {
      setCurrentRoute(routeName[routeName.length - 1]);
    }
    let foundPath = paths?.find(path => path.name === routeName?.find(name => name === path.name));
    if (!foundPath) {
      foundPath = paths?.find(path =>
        path?.options?.some(option =>
          option?.url
            ?.split("/")
            ?.some(
              optionPath => optionPath && optionPath !== "admin" && routeName?.some(name => name === optionPath),
            ),
        ),
      );
    }
    setCurrentPath(foundPath);
  }, [currentRoute, location]);

  const handleMenuItemClick = (path: IPath) => {
    if (mobileLeftMenuVisible && windowSize.width <= MOBILE_WIDTH && (!path?.options || path.options.length === 0)) {
      setMobileLeftMenuVisible(false);
    }

    ReactDOM.unstable_batchedUpdates(() => {
      setCurrentRoute(path.name);
      setCurrentPath(path);
    });
  };

  const [displayReaders, setDisplayReaders] = useState<boolean>(false);
  //Handle undefined status
  const [readerStatus, setReaderStatus] = useState({
    status: "",
    color: "bg-black",
  });

  const handleReaderSheet = () => {
    if (mobileLeftMenuVisible && windowSize.width <= MOBILE_WIDTH) {
      setMobileLeftMenuVisible(false);
    }
    if (displayReaders) {
      setDisplayReaders(false);
    } else {
      setDisplayReaders(true);
    }
  };

  useEffect(() => {
    console.log("Color Orb", terminalStore);
    if (terminalStore.status !== undefined) {
      setReaderStatus(prevState => ({ ...prevState, status: terminalStore.status }));
      if (terminalStore.status === "Connected") {
        setReaderStatus(prevState => ({ ...prevState, color: "bg-green-600" }));
      } else if (terminalStore.status === "Not connected") {
        setReaderStatus(prevState => ({ ...prevState, color: "bg-red-600" }));
      } else if (terminalStore.status === "Selected") {
        setReaderStatus(prevState => ({ ...prevState, color: "bg-green-600" }));
      } else {
        setReaderStatus(prevState => ({ ...prevState, color: "bg-yellow-500" }));
      }
    }
    console.log("Status", terminalStore.status);
  }, [terminalStore.status]);

  // Changing Current Active User
  const [displayCurrentActiveUserSheet, setDisplayCurrentActiveUserSheet] = useState<boolean>(false);
  const [currentActiveUserCode, setCurrentActiveUserCode] = useState<string>("");

  const [switchUserAfterTransaction, setSwitchUserAfterTransaction] = useState<boolean>(false);

  const handleOpenCurrentActiveUserSheet = () => {
    if (mobileLeftMenuVisible && windowSize.width <= MOBILE_WIDTH) {
      setMobileLeftMenuVisible(false);
    }
    setDisplayCurrentActiveUserSheet(true);
    setCurrentActiveUserCode("");
  };

  const handleCloseCurrentActiveUserSheet = () => {
    setDisplayCurrentActiveUserSheet(false);
    setSwitchUserAfterTransaction(false);
    setCurrentActiveUserCode("");
  };

  const handleUpdateCurrentActiveUser = async () => {
    if (isPositiveInteger(currentActiveUserCode)) {
      const switchUserResponse = await SwitchUser({ quick_code: parseInt(currentActiveUserCode) }, true);

      if (switchUserResponse.status !== StatusCode.OK) {
        props.uiActions.showError(t("secure.facility.admin.014"));
        return;
      }

      authActions.userLogin(switchUserResponse.data.user);
      localStorage.setItem("localeLanguage", switchUserResponse.data.user.primary_locale);
      localStorage.setItem("localeLanguageWithCountry", switchUserResponse.data.user.primary_locale_country);
      localStorage.setItem("switch_user_after_transaction", "false");
      props.uiActions.showSuccess(t("secure.facility.admin.015"));

      handleCloseCurrentActiveUserSheet();
    }
  };

  const handleCurrentActiveUserCodeEnter = (event: any) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      void handleUpdateCurrentActiveUser();
    }
  };

  const handleCurrentActiveUserCodeChange = (event: any) => {
    setCurrentActiveUserCode(event.target.value);
  };

  useEffect(() => {
    const defaultRegisterGroup = JSON.parse(localStorage.getItem("register_group"));
    if (defaultRegisterGroup) {
      void loadReaders();
    }
  }, [localStorage.getItem("register_group")]);

  //*************
  // REGISTERS
  const [displayRegisters, setDisplayRegisters] = useState<boolean>(false);
  const [registerState, setRegisterState] = useState({
    registers: [],
    selectedRegister: null,
    defaultFolderId: null,
  });

  useEffect(() => {
    setRegisterState(prevState => ({
      ...prevState,
      selectedRegister: JSON.parse(localStorage.getItem("register")),
    }));
  }, []);

  const handleRegisterModalVisibility = () => {
    if (mobileLeftMenuVisible && windowSize.width <= MOBILE_WIDTH) {
      setMobileLeftMenuVisible(false);
    }
    if (displayRegisters) {
      setDisplayRegisters(false);
    } else {
      // void loadRegisters();
      setDisplayRegisters(true);
    }
  };

  const handleRegisterChange = (register?: IRegister | undefined) => {
    // Close register modal
    handleRegisterModalVisibility();

    // handle the new register
    if (register) {
      setRegisterState(prev => ({
        ...prev,
        selectedRegister: register,
        defaultFolderId: register.default_folder_id,
      }));

      localStorage.setItem("register", JSON.stringify(register));
      localStorage.setItem("default_folder_id", JSON.stringify(register.default_folder_id));
      localStorage.setItem("register_group", JSON.stringify(register.register_group));
    }
  };

  const loadReaders = async () => {
    const register_group = JSON.parse(localStorage.getItem("register_group"));
    if (register_group == null) {
      uiActions.showError(t("secure.facility.admin.016"));
      return;
    }

    uiActions.enqueue();
    try {
      await setupStripe(register_group?.id);
      const reader = await discoverReaders(StripeTerminalSimulation, register_group?.stripe_location_id);
    } catch (e) {
      uiActions.showError(t("secure.facility.admin.017"));
      uiActions.dequeue();
      return;
    }
    uiActions.dequeue();
  };

  const routeName = history.location.pathname;

  return (
    <>
      {/** Connect to readers modal */}
      <Sheet
        size="medium"
        closable
        title={t("secure.facility.admin.024")}
        open={displayReaders}
        cancelText={t("secure.facility.admin.025")}
        onCancel={handleReaderSheet}
        okText={t("secure.facility.admin.026")}
        onOk={loadReaders}
      >
        {terminalStore.readers.length > 0 ? (
          <div className="ui-checkbox-group">
            {terminalStore.readers
              .sort((a, b) => a.label.localeCompare(b.label))
              .map((reader: reader) => (
                <div
                  key={reader.serial_number}
                  onClick={() =>
                    connectReader(
                      reader,
                      true,
                      () => uiActions.showSuccess(t("secure.facility.admin.030")),
                      message => uiActions.showError(message),
                    )
                  }
                  className={classNames("ui-checkbox-group-item", {
                    "ui-checkbox-group-item_selected": reader.serial_number === terminalStore.reader?.serial_number,
                    "ui-checkbox-group-item_disabled": reader.status === "offline",
                  })}
                >
                  <div className="ui-checkbox-group-item-content">
                    <div className="ui-checkbox-group-item-lead">
                      <Icon size="small" icon="cash-register" style="far" />
                    </div>
                    <div className="ui-checkbox-group-item-text">
                      <p className="text-sm text-medium text-gray-700">{reader.label}</p>
                      <p className="text-sm text-medium text-gray-700">{reader.device_type.replace(/_/g, " ")}</p>
                      <p className="text-sm text-medium text-gray-700">{reader.serial_number}</p>
                    </div>
                  </div>
                  <Checkbox
                    size="medium"
                    value={reader.serial_number}
                    checked={reader.serial_number === terminalStore.reader?.serial_number}
                    onChange={() =>
                      connectReader(
                        reader,
                        true,
                        () => uiActions.showSuccess(t("secure.facility.admin.030")),
                        message => uiActions.showError(message),
                      )
                    }
                  />
                  {/*<ButtonNew className="mt-2" onClick={() => disconnectReader()}>
                          {props.facilityStore.facility.terminal_integration === "server"
                            ? t("secure.facility.admin.035")
                            : t("secure.facility.admin.036")}
                        </ButtonNew> */}
                </div>
              ))}
          </div>
        ) : (
          <div className="flex justify-center align-center">
            <span className="text-xl text-bold self-center">{t("secure.facility.admin.032")}</span>
          </div>
        )}
      </Sheet>

      {routeName === "/admin/kds" ? (
        <Switch>
          <Route exact path="/admin/kds" component={kitchenDisplaySystem} />
        </Switch>
      ) : routeName === "/admin/table-selection" ? (
        <Switch>
          <Route exact path="/admin/table-selection" component={TableSelection} />
        </Switch>
      ) : routeName === "/admin/table-service" ? (
        <Switch>
          <Route
            exact
            path="/admin/table-service"
            component={
              facilityStore?.facility?.permissions?.table_service_new ? TableServiceContextProvider : TableService
            }
          />
        </Switch>
      ) : /^\/admin\/league\/.+\/scoring\/tv\/.+$/.test(routeName) ? (
        <Switch>
          <Route exact path={"/admin/league/:leagueId/scoring/tv/:scoresheetId"} component={LeagueTvLeaderboard} />
        </Switch>
      ) : /^\/admin\/league\/.+\/scoring\/tv-views\/.+\/view/.test(routeName) ? (
        <Switch>
          <Route exact path={baseLeagueUrl + "/scoring/tv-views/:viewId/view"} component={LeagueTvView} />
        </Switch>
      ) : (
        <Layout>
          {mobileLeftMenuVisible && (!currentPath?.options || currentPath.options.length === 0) && (
            <div className="ui-layout_left-nav-mobile">
              <div className="ui-left-nav_container">
                <div className="ui-left-nav_content">
                  <div className="ui-left-nav-logo">
                    <img src="../../../public/images/tee_on_menu_logo.png" alt="Tee-On Logo" />
                  </div>
                  <div className="ui-left-nav-header">
                    <div className="ui-left-nav-admin-current-active-user">
                      <div onClick={handleOpenCurrentActiveUserSheet}>{authStore?.user?.full_name}</div>
                    </div>
                    <div className="ui-left-nav-admin-terminal" onClick={handleRegisterModalVisibility}>
                      <div>
                        {registerState.selectedRegister ? (
                          <span>{registerState.selectedRegister?.title}</span>
                        ) : (
                          <span>{t("secure.facility.admin.018")}</span>
                        )}
                      </div>
                    </div>
                    <div className="ui-left-nav-admin-terminal" onClick={handleReaderSheet}>
                      <div className="ui-left-nav-admin-terminal-status">
                        <span>
                          {terminalStore.reader ? terminalStore.reader.label : t("secure.facility.admin.020")}
                        </span>
                        <span>{readerStatus.status}</span>
                      </div>
                      <div className={`rounded-full h-4 w-4 ${readerStatus.color} self-center ml-2`} />
                    </div>
                  </div>
                  <div className="ui-left-nav-menu">
                    <Menu>
                      {paths.map((path, i) => {
                        if (!path.hidden) {
                          return (
                            <Link key={i} to={`/admin/${path.name}`}>
                              <Menu.Item
                                selected={currentRoute === path.name ? true : false}
                                onClick={() => handleMenuItemClick(path)}
                                fullWidthIcon
                                icon={
                                  <div className="flex gap-1 items-center justify-between px-2 w-full">
                                    <div className="flex gap-1 items-center">
                                      <div className="w-6">
                                        <FontAwesomeIcon icon={["far", path.icon]} />
                                      </div>
                                      <div>{path.title}</div>
                                    </div>
                                    <FontAwesomeIcon className="mr-1" icon={["far", "chevron-right"]} />
                                  </div>
                                }
                              ></Menu.Item>
                            </Link>
                          );
                        }
                      })}
                    </Menu>
                    <Menu className="mb-4">
                      <SupportTab>
                        <Menu.Item
                          className="self-center cursor-pointer"
                          fullWidthIcon
                          icon={
                            <div className="flex gap-1 items-center justify-between px-2 w-full">
                              <div className="flex gap-1 items-center">
                                <div className="w-6">
                                  <FontAwesomeIcon icon={["far", "circle-info"]} />
                                </div>
                                <div>{t("secure.facility.admin.042")}</div>
                              </div>
                            </div>
                          }
                        ></Menu.Item>
                      </SupportTab>
                      <Menu.Item
                        className="self-center cursor-pointer"
                        onClick={logoutUser}
                        fullWidthIcon
                        icon={
                          <div className="flex gap-1 items-center justify-between px-2 w-full">
                            <div className="flex gap-1 items-center">
                              <div className="w-6">
                                <FontAwesomeIcon style={{ transform: "scaleX(-1)" }} icon={["far", "sign-out"]} />
                              </div>
                              <div>{t("secure.facility.admin.043")}</div>
                            </div>
                          </div>
                        }
                      ></Menu.Item>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          )}

          {mobileLeftMenuVisible && currentPath?.options?.length > 0 && (
            <div className="ui-layout_left-nav-icons-mobile">
              <div className="ui-left-nav_container">
                <div className="ui-left-nav_content">
                  <div className="ui-left-nav-logo">
                    <img src="../../../public/images/tee_on_menu_logo.png" alt="Tee-On Logo" />
                  </div>
                  <div className="ui-left-nav-menu">
                    <Menu>
                      {paths.map((path, i) => {
                        if (!path.hidden) {
                          return (
                            <Link key={i} to={`/admin/${path.name}`}>
                              <Menu.Item
                                selected={currentRoute === path.name ? true : false}
                                onClick={() => handleMenuItemClick(path)}
                                icon={<FontAwesomeIcon icon={["far", path.icon]} />}
                              ></Menu.Item>
                            </Link>
                          );
                        }
                      })}
                    </Menu>
                    <Menu className="mb-4">
                      <SupportTab>
                        <Menu.Item
                          className="self-center cursor-pointer"
                          icon={<FontAwesomeIcon icon={["far", "circle-info"]} />}
                        />
                      </SupportTab>
                      <Menu.Item
                        onClick={logoutUser}
                        className="self-center cursor-pointer"
                        icon={<FontAwesomeIcon style={{ transform: "scaleX(-1)" }} icon={["far", "sign-out"]} />}
                      />
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          )}

          {mobileLeftMenuVisible && (
            <>
              <div
                onClick={() => setMobileLeftMenuVisible(prev => !prev)}
                className="ui-layout_left-nav-mobile-backdrop"
              />
              <FontAwesomeIcon
                onClick={() => setMobileLeftMenuVisible(prev => !prev)}
                className="ui-layout_left-nav-mobile-backdrop-close-button"
                icon={["far", "xmark"]}
              />
            </>
          )}

          <div
            className={classNames("ui-layout_left-submenu-mobile", {
              "ui-layout_left-submenu-mobile-hide": mobileLeftMenuVisible && currentPath?.options ? false : true,
            })}
          >
            <LeftSubMenu
              title={currentPath?.title}
              options={currentPath?.options}
              onClickTitle={() => (currentPath?.OnClickTitle ? currentPath?.OnClickTitle() : undefined)}
              onClickOption={() => setMobileLeftMenuVisible(false)}
            />
          </div>

          <div className="ui-layout_left-nav-desktop">
            <div className="ui-left-nav_container">
              <div className="ui-left-nav_content">
                <div className="ui-left-nav-logo">
                  <img src="../../../public/images/tee_on_menu_logo.png" alt="Tee-On Logo" />
                </div>
                <div className="ui-left-nav-menu">
                  <Menu>
                    {paths.map((path, i) => {
                      if (!path.hidden) {
                        return (
                          <Link key={i} to={`/admin/${path.name}`}>
                            <OverlayTrigger
                              placement="auto"
                              overlay={<Tooltip id="leftMenuToolTip">{path.title}</Tooltip>}
                            >
                              <Menu.Item
                                selected={currentRoute === path.name ? true : false}
                                onClick={() => handleMenuItemClick(path)}
                                icon={<FontAwesomeIcon icon={["far", path.icon]} />}
                              ></Menu.Item>
                            </OverlayTrigger>
                          </Link>
                        );
                      }
                    })}
                  </Menu>
                  <Menu className="mb-4">
                    <SupportTab>
                      <Menu.Item
                        className="self-center cursor-pointer"
                        icon={<FontAwesomeIcon icon={["far", "circle-info"]} />}
                      />
                    </SupportTab>
                    <OverlayTrigger
                      placement="auto"
                      overlay={<Tooltip id="leftMenuToolTip">{`${t("secure.facility.admin.019")}`}</Tooltip>}
                    >
                      <Menu.Item
                        onClick={logoutUser}
                        className="self-center cursor-pointer"
                        icon={<FontAwesomeIcon style={{ transform: "scaleX(-1)" }} icon={["far", "sign-out"]} />}
                      />
                    </OverlayTrigger>
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          <div
            className={classNames("ui-layout_left-submenu-desktop", {
              "ui-layout_left-submenu-desktop-hide": currentPath?.options ? false : true,
            })}
          >
            <LeftSubMenu
              title={currentPath?.title}
              options={currentPath?.options}
              onClickTitle={() => (currentPath?.OnClickTitle ? currentPath?.OnClickTitle() : undefined)}
            />
          </div>

          <div
            className="ui-main"
            style={mobileLeftMenuVisible && windowSize.width <= MOBILE_WIDTH ? { filter: "blur(5px)" } : {}}
          >
            <div className="ui-header">
              <div className="ui-header_container">
                <div className="ui-header-content_mobile">
                  <div className="header-content-image-container">
                    {facilityStore.facility?.logo_source && (
                      <img className="header-content-image" src={facilityStore.facility.logo_source} />
                    )}
                  </div>
                  <div className="header-content-image-container">
                    {facilityStore.facility?.logo_source && (
                      <img className="header-content-image" src="../../../public/images/Tee-On.png" />
                    )}
                  </div>
                  <div className="header-menu-icon" onClick={() => setMobileLeftMenuVisible(prev => !prev)}>
                    <FontAwesomeIcon icon={["far", "bars"]} />
                  </div>
                </div>
                <div className="ui-header-content_desktop">
                  <div className="facility-details">
                    <h2 className="h2">{facilityStore.facility?.long_name}</h2>
                  </div>
                  <div className="admin-details">
                    <div className="admin-current-active-user">
                      <div onClick={() => handleOpenCurrentActiveUserSheet()}>{authStore?.user?.full_name}</div>
                    </div>
                    <div className="admin-terminal" onClick={() => handleRegisterModalVisibility()}>
                      <div className="admin-register-status">
                        {registerState.selectedRegister ? (
                          <span>{registerState.selectedRegister?.title}</span>
                        ) : (
                          <span>{t("secure.facility.admin.018")}</span>
                        )}
                      </div>
                    </div>
                    <div className="admin-terminal" onClick={() => handleReaderSheet()}>
                      <div className="admin-terminal-status">
                        <span>
                          {terminalStore.reader ? terminalStore.reader.label : t("secure.facility.admin.020")}
                        </span>
                        <span>{readerStatus.status}</span>
                      </div>
                      <div className={`rounded-full h-5 w-5 ${readerStatus.color} self-center ml-3`} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ui-main_container">
              <div className={adminContentCls}>
                <Switch>
                  <Route exact path="/admin/customers" component={Customers} />
                  <Route exact path="/admin/customers-public" component={Customers} />
                  <Route exact path="/admin/customers-member" component={MembershipsSold} />
                  <Route exact path="/admin/customers/:customerId/profile" component={CustomerProvider} />
                  <Route exact path="/admin/customers/:customerId/reservations" component={CustomerProvider} />
                  <Route exact path="/admin/customers/:customerId/membership" component={CustomerProvider} />
                  <Route exact path="/admin/customers/:customerId/tickets" component={CustomerProvider} />
                  <Route exact path="/admin/customers/:customerId/house-accounts" component={CustomerProvider} />
                  <Route exact path="/admin/customers/:customerId/payment-methods" component={CustomerProvider} />
                  <Route exact path="/admin/customers/:customerId/credit" component={CustomerProvider} />
                  <Route exact path="/admin/customers/:customerId/notes" component={CustomerProvider} />
                  <Route exact path="/admin/customers/:customerId/item-sales" component={CustomerProvider} />

                  {/* <Route exact path="/admin/customer/new" component={CustomerNew} /> */}
                  {/* <Route exact path="/admin/customers/:customerId/profile" component={Customer} />
                  <Route exact path="/admin/customers/:customerId/reservations" component={Customer} />
                  <Route exact path="/admin/customers/:customerId/membership" component={Customer} />
                  <Route exact path="/admin/customers/:customerId/houseAccounts" component={Customer} />
                  <Route exact path="/admin/customers/:customerId/billing" component={Customer} />
                  <Route exact path="/admin/customers/:customerId/payment-methods" component={Customer} />
                  <Route exact path="/admin/customers/:customerId/item-sales" component={Customer} /> */}

                  {/* Temporarily disable dashboard route */}
                  {/* <Route exact path="/admin/dashboard" component={Dashboard} /> */}

                  <Route
                    exact
                    path="/admin/register"
                    component={facilityStore?.facility?.permissions?.register_new ? RegisterContextProvider : Register}
                  />
                  {permissions?.reports_access && [
                    <Route exact path="/admin/report" key="/admin/report" component={Reports} />,
                    reportRoutes?.map((route, index) => {
                      return <Route key={index} path={route.path} component={route.component} exact />;
                    }),
                    <Route exact path="/admin/report/:id" key="/admin/report/:id" component={Report} />,
                  ]}

                  {/* Settings routes */}
                  <Route path="/admin/settings" component={Settings} exact />
                  {settingsRoutes?.map((route, index) => {
                    return <Route key={index} path={route.path} component={route.component} exact />;
                  })}

                  {/* Order routes */}
                  {orderRoutes?.map((route, index) => {
                    return <Route key={index} path={route.path} component={route.component} exact />;
                  })}

                  {/* Product routes */}
                  {productRoutes?.map((route, index) => {
                    return <Route key={index} path={route.path} component={route.component} exact />;
                  })}

                  {/* Customer routes */}
                  {/* {customerRoutes?.map((route, index) => {
                    return <Route key={index} path={route.path} component={route.component} exact />;
                  })} */}

                  <Route
                    exact
                    path="/admin/teesheet"
                    component={facilityStore?.teeSheetSettings?.tee_sheet_new ? NewTeeSheet : TeeSheet}
                  />
                  <Route exact path="/admin/teesheet/edit" component={TeeSheetEdit} />
                  <Route
                    exact
                    path="/admin/teesheet/details/:teetimeId"
                    component={
                      facilityStore?.facility?.permissions?.check_in_new
                        ? TeeSheetDetailsContextProvider
                        : TeeSheetDetails
                    }
                  />

                  <Route exact path="/admin/reservation" component={Reservation} />
                  <Route exact path="/admin/reservation/edit/:moduleId" component={EditSegments} />

                  <Route exact path="/admin/events" component={Leagues} />
                  <Route exact path="/admin/events/new" component={LeagueNew} />
                  <Route exact path={baseLeagueUrl + "/home"} component={League} />
                  <Route exact path={baseLeagueUrl + "/players"} component={LeaguePlayers} />
                  <Route exact path={baseLeagueUrl + "/announcements"} component={LeagueAnnouncements} />
                  <Route exact path={baseLeagueUrl + "/prizeAccounts"} component={LeaguePrizeAccounts} />
                  <Route exact path={baseLeagueUrl + "/prizeAccounts/edit/:id"} component={PrizeAccount} />
                  <Route exact path={baseLeagueUrl + "/prizeAccounts/view/:id"} component={PrizeAccountView} />
                  <Route exact path={baseLeagueUrl + "/prizeAccounts/new"} component={PrizeAccountNew} />
                  <Route exact path={baseLeagueUrl + "/rounds"} component={LeagueRounds} />

                  <Route exact path={baseLeagueUrl + "/divisions"} component={LeagueDivisions} />
                  <Route exact path={baseLeagueUrl + "/divisions/new"} component={LeagueDivisionsNew} />
                  <Route
                    exact
                    path={baseLeagueUrl + "/divisions/:divisionId/manage_players"}
                    component={LeagueDivisionsManagePlayers}
                  />

                  <Route exact path={baseLeagueUrl + "/divisions/:divisionId/edit"} component={LeagueDivisionEdit} />

                  <Route exact path={baseLeagueUrl + "/divisions/:divisionId/teams"} component={LeagueTeams} />
                  <Route exact path={baseLeagueUrl + "/divisions/:divisionId/teams/new"} component={LeagueTeamNew} />
                  <Route
                    exact
                    path={baseLeagueUrl + "/divisions/:divisionId/teams/manage_players"}
                    component={LeaguePlayerManage}
                  />

                  <Route
                    exact
                    path={baseLeagueUrl + "/divisions/:divisionId/teams/:teamId/edit"}
                    component={LeagueTeamsEdit}
                  />
                  <Route exact path={baseLeagueUrl + "/divisions/:divisionId/flights"} component={LeagueFlights} />
                  <Route
                    exact
                    path={baseLeagueUrl + "/divisions/:divisionId/flights/new"}
                    component={LeagueFlightsNew}
                  />
                  <Route
                    exact
                    path={baseLeagueUrl + "/divisions/:divisionId/flights/manage_players"}
                    component={LeagueFlightPlayerManage}
                  />
                  <Route
                    exact
                    path={baseLeagueUrl + "/divisions/:divisionId/flights/:flightId/edit"}
                    component={LeagueFlightsEdit}
                  />
                  <Route exact path={baseLeagueUrl + "/rounds/scoresheet/new"} component={LeagueScoresheetNew} />
                  <Route
                    exact
                    path={baseLeagueUrl + "/rounds/scoresheet/:scoresheetId/scores"}
                    component={LeagueScoresheet}
                  />
                  <Route
                    exact
                    path={baseLeagueUrl + "/rounds/scoresheet/:scoresheetId/scoring-formats/new"}
                    component={LeagueScoringFormatNew}
                  />
                  <Route
                    exact
                    path={baseLeagueUrl + "/rounds/scoresheet/:scoresheetId/scoring-formats/:formatId"}
                    component={LeagueScoringFormatEdit}
                  />
                  <Route
                    exact
                    path={baseLeagueUrl + "/rounds/scoresheet/:scoresheetId/leaderboard"}
                    component={LeagueLeaderboard}
                  />

                  <Route
                    exact
                    path={baseLeagueUrl + "/rounds/scoresheet/:scoresheetId/overview"}
                    component={LeagueRoundOverview}
                  />
                  <Route
                    exact
                    path={baseLeagueUrl + "/rounds/scoresheet/:scoresheetId/edit"}
                    component={LeagueScoresheetEdit}
                  />
                  <Route
                    exact
                    path={baseLeagueUrl + "/scoring/scoresheet/:scoresheetId/skins"}
                    component={LeagueSkins}
                  />
                  <Route
                    exact
                    path={baseLeagueUrl + "/scoring/scoresheet/:scoresheetId/skins/:skinsheetId"}
                    component={LeagueSkin}
                  />
                  <Route
                    exact
                    path={baseLeagueUrl + "/scoring/season-leaderboard"}
                    component={LeagueSeasonLeaderboard}
                  />
                  <Route exact path={baseLeagueUrl + "/scoring/leaderboards"} component={Leaderboards} />
                  <Route
                    exact
                    path={baseLeagueUrl + "/scoring/leaderboards/:leaderboardId"}
                    component={LeaderboardTotals}
                  />
                  <Route exact path={baseLeagueUrl + "/scoring/ringer-board"} component={LeagueRingerBoard} />
                  <Route exact path={baseLeagueUrl + "/scoring/statistics"} component={LeagueStatistics} />
                  <Route exact path={baseLeagueUrl + "/league-settings/organizers"} component={LeagueOrganizers} />
                  <Route exact path={baseLeagueUrl + "/league-settings/teetimes"} component={LeagueTeeTimes} />
                  <Route
                    exact
                    path={baseLeagueUrl + "/league-settings/registration"}
                    component={LeagueRegistrationLanding}
                  />
                  <Route exact path={baseLeagueUrl + "/scoring/tv-views"} component={LeagueTvViews} />
                  <Route exact path={baseLeagueUrl + "/scoring/tv-views/new"} component={LeagueTvViewNew} />
                  <Route exact path={baseLeagueUrl + "/scoring/tv-views/:viewId/edit"} component={LeagueTvViewEdit} />
                  <Route exact path={baseLeagueUrl + "/league-settings/general"} component={SingleLeagueSettings} />
                  <Route
                    exact
                    path={baseLeagueUrl + "/league-settings/format-templates"}
                    component={LeagueFormatTemplates}
                  />
                  <Route
                    exact
                    path={baseLeagueUrl + "/league-settings/format-templates/new"}
                    component={LeagueFormatTemplateNew}
                  />
                  <Route
                    exact
                    path={baseLeagueUrl + "/league-settings/format-templates/:templateId"}
                    component={LeagueFormatTemplate}
                  />

                  <Route exact path="/admin/events-tournament" component={Tournaments} />
                  <Route exact path="/admin/events-tournament/new" component={TournamentNew} />
                  <Route exact path={baseTournamentUrl + "/home"} component={Tournament} />
                  <Route exact path={baseTournamentUrl + "/players"} component={TournamentPlayers} />
                  <Route exact path={baseTournamentUrl + "/teams"} component={TournamentTeams} />
                  <Route exact path={baseTournamentUrl + "/teams/new"} component={TournamentTeamNew} />
                  <Route exact path={baseTournamentUrl + "/teams/:teamId/edit"} component={TournamentTeam} />
                  <Route exact path={baseTournamentUrl + "/flights"} component={TournamentFlights} />
                  <Route exact path={baseTournamentUrl + "/flights/new"} component={TournamentFlightNew} />
                  <Route exact path={baseTournamentUrl + "/flights/:flightId/edit"} component={TournamentFlightEdit} />
                  <Route exact path={baseTournamentUrl + "/scoring"} component={TournamentScores} />

                  <Route exact path={baseTournamentUrl + "/prizeAccounts"} component={TournamentPrizeAccounts} />
                  <Route exact path={baseTournamentUrl + "/prizeAccounts/edit/:id"} component={PrizeAccount} />
                  <Route exact path={baseTournamentUrl + "/prizeAccounts/view/:id"} component={PrizeAccountView} />
                  <Route exact path={baseTournamentUrl + "/prizeAccounts/new"} component={PrizeAccountNew} />
                  <Route
                    exact
                    path={baseTournamentUrl + "/scoring/scoresheet/new"}
                    component={TournamentScoresheetNew}
                  />
                  <Route
                    exact
                    path={baseTournamentUrl + "/scoring/scoresheet/:scoresheetId"}
                    component={TournamentScoresheet}
                  />
                  <Route
                    exact
                    path={baseTournamentUrl + "/scoring/scoresheet/:scoresheetId/edit"}
                    component={TournamentScoresheetEdit}
                  />

                  <Route
                    exact
                    path={baseTournamentUrl + "/scoring/results/:scoresheetId"}
                    component={TournamentLeaderboard}
                  />

                  <Route
                    exact
                    path={baseTournamentUrl + "/tournament-settings/general"}
                    component={TournamentSettings}
                  />
                  <Route
                    exact
                    path={baseTournamentUrl + "/tournament-settings/registration"}
                    component={TournamentRegistrationLanding}
                  />
                  <Route
                    exact
                    path={baseTournamentUrl + "/tournament-settings/scoring"}
                    component={TournamentScoreSettings}
                  />
                  {/* <Route
                    exact
                    path={baseTournamentUrl + "/tournament-settings/registration"}
                    component={TournamentRegistration}
                  /> */}
                  {/* <Route
                    exact
                    path={baseTournamentUrl + "/tournament-settings/registration/registration-fees-new"}
                    component={TournamentRegistrationFeesNew}
                  /> */}
                  <Route
                    exact
                    path={baseTournamentUrl + "/tournament-settings/organizers"}
                    component={TournamentOrganizers}
                  />

                  <Route exact path="/admin/events-hospitality" component={HospitalityEvents} />
                  <Route exact path="/admin/events-hospitality/new" component={HospitalityEventNew} />
                  <Route exact path="/admin/event/:eventId/home" component={HospitalityEvent} />

                  {/* Marketing */}
                  <Route exact path="/admin/marketing" component={CustomerSegments} />
                  <Route exact path="/admin/marketing/customer-segments/new" component={NewCustomerSegment} />
                  <Route
                    exact
                    path="/admin/marketing/customer-segments/view/:segmentId"
                    component={ViewCustomerSegment}
                  />
                  <Route
                    exact
                    path="/admin/marketing/customer-segments/edit/:segmentId"
                    component={EditCustomerSegment}
                  />

                  {/* <Route exact path="/admin/tournament/:tournamentId/settings" component={TournamentSettings} />
                      <Route
                        exact
                        path="/admin/tournament/:tournamentId/settings/general"
                        component={TournamentSettingsGeneral}
                      /> */}

                  {/* Temporary dashboard route */}
                  <Route exact path="/admin/dashboard-beta" component={Dashboard} />

                  <Redirect to="/admin/dashboard" />
                </Switch>
              </div>
            </div>
          </div>

          {/** Current Active User Modal */}
          <SwitchUserModal
            open={displayCurrentActiveUserSheet}
            onCancel={handleCloseCurrentActiveUserSheet}
            onOk={handleUpdateCurrentActiveUser}
            okDisabled={!isPositiveInteger(currentActiveUserCode)}
            userCode={currentActiveUserCode}
            onChange={handleCurrentActiveUserCodeChange}
            onKeyDown={handleCurrentActiveUserCodeEnter}
            autoFocus
          />

          {/* Display switch user modal with logout button */}
          <div className="ui-switch-user-modal">
            <SwitchUserModal
              open={switchUserAfterTransaction}
              onCancel={logoutUser}
              cancelText={t("secure.facility.admin.041")}
              onOk={handleUpdateCurrentActiveUser}
              okDisabled={!isPositiveInteger(currentActiveUserCode)}
              userCode={currentActiveUserCode}
              onChange={handleCurrentActiveUserCodeChange}
              onKeyDown={handleCurrentActiveUserCodeEnter}
              autoFocus
              backDropCancel={false}
            />
          </div>

          {/** Connect to Register modal */}
          <RegistersModal open={displayRegisters} onClose={register => handleRegisterChange(register)} />
        </Layout>
      )}
    </>
  );
};

export default FacilityAdmin;
