import React, { useState } from "react";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useOnclickOutside from "hooks/useOnclickOutside/useOnclickOutside";
import "./NavigationDropdownNew.scss";
import classNames from "classnames";

interface INavigationDropdownOption {
  label: string | JSX.Element;
  icon?: IconName;
  disabled?: boolean;
  hidden?: boolean;
  onClick: () => void;
}

export interface INavigationDropdownProps {
  showPlainTextLabel?: boolean;
  leftAlign?: boolean;
  rightAlign?: boolean;
  position?: "top" | "bottom";
  label: string | JSX.Element;
  sections: Array<Array<INavigationDropdownOption>>;
  informationSection?: string[];
  type?: "text" | "primary";
}

interface INavigationDropdownState {
  isDropdownOpen: boolean;
}

export function NavigationDropdownNew(props: INavigationDropdownProps) {
  const {
    showPlainTextLabel,
    leftAlign,
    rightAlign,
    position,
    label,
    sections,
    informationSection,
    type = "primary",
    ...rest
  } = props;

  const [navigationDropdownState, setNavigationDropdownState] = useState<INavigationDropdownState>({
    isDropdownOpen: false,
  });

  const dropdownRef = useOnclickOutside(() => {
    setNavigationDropdownState(prev => ({ ...prev, isDropdownOpen: false }));
  });

  function handleDropdownClick(e: React.MouseEvent) {
    e.stopPropagation();
    setNavigationDropdownState(prev => ({ ...prev, isDropdownOpen: !prev.isDropdownOpen }));
  }

  function handleOptionClick(onClick: () => void, e: React.MouseEvent) {
    e.stopPropagation();
    onClick();
    setNavigationDropdownState(prev => ({ ...prev, isDropdownOpen: false }));
  }

  return (
    <div ref={dropdownRef} className="navigation-dropdown-new">
      <div
        onClick={handleDropdownClick}
        className={classNames(showPlainTextLabel ? "navigation-plain-text-dropbutton" : "navigation-dropbutton", {
          "primary-button": type === "primary" && !showPlainTextLabel,
          "text-button": type === "text",
          "plain-text": showPlainTextLabel,
        })}
      >
        {props.label}
        {!props.showPlainTextLabel && (
          <FontAwesomeIcon className="navigation-dropbutton-icon" icon={["fas", "chevron-down"]} />
        )}
      </div>
      <div
        style={{
          display: navigationDropdownState.isDropdownOpen && props.sections?.length > 0 ? "block" : "none",
          right: props.rightAlign ? 0 : null,
          left: props.leftAlign ? 0 : props.rightAlign ? null : "-25%",
          bottom: props.position === "top" ? "100%" : null,
        }}
        className="navigation-dropdown-new-content"
      >
        {props.sections.map((section, index) => {
          return (
            <React.Fragment key={index}>
              <div className="navigation-dropdown-new-content-section">
                {section
                  ?.filter((option: INavigationDropdownOption) => !option.hidden)
                  .map((option, optionIndex) => {
                    return (
                      <button
                        key={optionIndex}
                        onClick={e => handleOptionClick(option.onClick, e)}
                        disabled={option.disabled}
                        className="navigation-dropdown-new-content-section-option"
                      >
                        {option?.icon && (
                          <FontAwesomeIcon
                            className="navigation-dropdown-new-content-section-option-icon"
                            icon={["far", option.icon]}
                          />
                        )}
                        <div>{option.label}</div>
                      </button>
                    );
                  })}
              </div>
              {index < props.sections.length - 1 && <hr className="navigation-dropdown-new-content-section-divider" />}
            </React.Fragment>
          );
        })}
        {props.informationSection?.length > 0 && (
          <>
            {props.sections?.length > 0 && <hr className="navigation-dropdown-new-content-section-divider" />}

            <div className="navigation-dropdown-new-content-information-section">
              {props.informationSection.map((information, index) => {
                return <div key={index}>{information}</div>;
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
