import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { IFacility } from "redux/reducers/models/facility";
import { IDepartments } from "redux/reducers/models/product";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/facility";

interface IPutFacilityRes {
  status: number;
  data: IFacility;
  message: string;
}

interface IAccountingRef {
  id?: number;
  title?: string;
  reference_number: string;
}

// GET Facility
export async function GetFacility(params: any, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<IFacility[]>(baseUrl, params, useGlobalLoader, token);
}

// PUT Facility
export async function PutFacility(body: any, useGlobalLoader: boolean) {
  return await APIPut<IFacility>(baseUrl, body, useGlobalLoader);
}

//POST Facility Logo Image
export async function PostFacilityLogo(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/image", params, useGlobalLoader);
}

export async function GetPaymentOptions(
  params?: Record<string, unknown>,
  useGlobalLoader?: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseUrl + "/payment-option", params, (useGlobalLoader = false), token);
}

//GET Departments
export async function GetDepartments(useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseUrl + "/department?extended=true", null, useGlobalLoader, token);
}

//POST Departments
export async function PostDepartments(params: IDepartments, useGlobalLoader?: boolean) {
  return await APIPost(baseUrl + "/department", params, useGlobalLoader);
}

//PUT Departments
export async function PutDepartments(params: IDepartments, useGlobalLoader?: boolean) {
  return await APIPut(baseUrl + "/department", params, useGlobalLoader);
}

//DELETE Departments
export async function DeleteDepartments(params: IDepartments, useGlobalLoader?: boolean) {
  return await APIDelete(baseUrl + "/department", params, useGlobalLoader);
}

export async function GetPayout(useGlobalLoader: boolean) {
  return await APIGet(baseUrl + "/payout", null, useGlobalLoader);
}

export async function GetTransactions(params: string, useGlobalLoader: boolean) {
  return await APIGet(baseUrl + "/payout/transaction" + "?" + params, null, useGlobalLoader);
}

// GET Accounting Reference
export async function GetAccountingReference(params: string, useGlobalLoader?: boolean) {
  return await APIGet(baseUrl + "/accounting?" + params, null, useGlobalLoader);
}

// POST Accounting Reference
export async function PostAccountingReference(params: IAccountingRef, useGlobalLoader?: boolean) {
  return await APIPost(baseUrl + "/accounting", params, useGlobalLoader);
}

// PUT Accounting Reference
export async function PutAccountingReference(params: IAccountingRef, useGlobalLoader?: boolean) {
  return await APIPut(baseUrl + "/accounting", params, useGlobalLoader);
}

// DELETE Accounting Reference
export async function DeleteAccountingReference(params: IAccountingRef, useGlobalLoader?: boolean) {
  return await APIDelete(baseUrl + "/accounting", params, useGlobalLoader);
}

//GET Accounts
export async function GetAccounts(useGlobalLoader: boolean) {
  return await APIGet("/" + apiVersion + "/" + adminType + "/client/account", useGlobalLoader);
}

//GET Sales Channels
//Retrieve a list of sales channels for the facility
export async function GetSalesChannels(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseUrl + "/sales-channel", useGlobalLoader);
}

//PUT Update Sales Channel
//Adds or removes a product from sales channel
export async function UpdateSalesChannel(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/sales-channel/product", params, useGlobalLoader);
}
