import { APIDelete, APIGet, APIPost, APIPut } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "master-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/discount";

export interface IDiscount {
  id: number;
  client_id: number;
  title: string;
  application: string;
  value_type: string;
  value: number;
  custom: boolean;
  start_date: string;
  end_date: string;
  notes: string;
}

export interface IGetDiscount {
  id?: number;
  facility_id: number;
  application?: string;
}
interface IPostDiscount {
  title: string;
  value_type: string;
  application: string;
  value: number;
  custom: boolean;
  tracked: boolean;
  facility_id: number;
}
interface IPutDiscount {
  id: number;
  title: string;
  value_type: string;
  application: string;
  value: number;
  custom: boolean;
  tracked: boolean;
  facility_id: number;
}
interface IDeleteDiscount {
  id: number;
  facility_id: number;
}

//Get Discount
//Retrieve discounts
export async function GetDiscount(params?: IGetDiscount, useGlobalLoader?: boolean, token?: CancelToken) {
  return await APIGet(baseUrl, params, useGlobalLoader);
}

//POST Discount
//Create a new discount
export async function PostDiscount(params?: IPostDiscount, useGlobalLoader?: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

//PUT Discount
//Update an existing discount
export async function PutDiscount(params?: IPutDiscount, useGlobalLoader?: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

//DELETE Discount
//Delete a discount
export async function DeleteDiscount(params?: IDeleteDiscount, useGlobalLoader?: boolean) {
  return await APIDelete(baseUrl, params, useGlobalLoader);
}
