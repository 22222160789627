import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetLeagueFlights } from "api/rpc/2024-04/facilityAdmin/league/scoring/flight";

import { useAppDispatch } from "hooks/redux";
import { showError } from "redux/actions/ui";
import { TLeagueFlight } from "redux/reducers/models/league";

import Page from "components/page/Page";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

export default function LeagueFlights() {
  const history = useHistory();
  const { t } = useTranslation();
  const { leagueId, divisionId } = useParams<{ leagueId: string; divisionId: string }>();

  const dispatch = useAppDispatch();

  const [flights, setFlights] = useState<TLeagueFlight[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadFlights(divisionId, source.token);
    return () => source.cancel();
  }, [divisionId]);

  async function loadFlights(divisionId: string | number, token?: CancelToken) {
    if (flights !== undefined) {
      setFlights(undefined);
    }
    const res = await GetLeagueFlights({ league_division_id: divisionId }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error getting flights.")); // TODO: Translation
    }

    setFlights(res.status !== StatusCode.OK ? [] : res.data);
  }

  const primaryAction = {
    content: "New Flight", // TODO: Translation
    action: () => history.push(`/admin/league/${leagueId}/divisions/${divisionId}/flights/new`),
  };

  const secondaryAction = [
    {
      content: "Manage Players", // TODO: Translation
      action: () => history.push(`/admin/league/${leagueId}/divisions/${divisionId}/flights/manage_players`),
    },
  ];

  return (
    <Page
      title={"League Flights"} // TODO: Translation
      narrow
      primaryAction={primaryAction}
      secondaryActions={secondaryAction}
      breadcrumbs={[
        { prefix: true, label: "Divisions", /* TODO: Translation */ url: `/admin/league/${leagueId}/divisions` },
      ]}
    >
      <DataTable columns={[{ label: t("secure.facility.league.leagues.003") }]} loading={flights === undefined}>
        {flights?.map(flight => (
          <tr
            key={flight.id}
            className="clickable"
            onClick={() => history.push(`/admin/league/${leagueId}/divisions/${divisionId}/flights/${flight.id}/edit`)}
          >
            <td>{flight.name}</td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
