import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { isEqualWith } from "lodash";
import moment from "moment";
import ReactDOM from "react-dom";
import { useHistory, useParams } from "react-router-dom";

import { StatusCode } from "api/protocols";
import {
  GetReservationLocation,
  GetReservationModule,
  GetReservationSegment,
  PostReservationSegmentConfig,
  PutReservationModule,
  PutApplyReservationTemplate,
  GetReservationTemplate,
} from "api/rpc/facilityAdmin/reservation/reservation";

import { dequeue, enqueue, showError, showSuccess } from "redux/actions/ui";
import { ILocation, IModule, ISegment, ITemplate } from "redux/reducers/models/reservations";

import { useAppDispatch } from "hooks/redux";
import { formatDate } from "helpers/Helpers";
import { LocaleCurrency } from "helpers/Locale";

import { ButtonNew as Button } from "components/buttonNew";
import Callout from "components/callout/Callout";
import Card from "components/card/Card";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import Checkbox from "components/form/checkbox/Checkbox";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import Page from "components/page/Page";
import Toggle from "components/form/toggle/Toggle";
import TimePick from "components/timePick/TimePick";
import { Select } from "components/select/index";
import Tabs from "components/tabs/Tabs";
import TextEditor from "components/textEditor/textEditor";

import "./configSegment.scss";

interface IState {
  module: IModule;
  locations: Array<ILocation>;
  selectedLocations: Array<number>;
  selectedDate: Date;
  start_time: string;
  end_time: string;
  interval: number;
  templates: Array<ITemplate>;
  selectedTemplateId: number;
  segments: { [key: string]: Array<ISegment> };
}

interface IModuleState {
  moduleTitle: string;
  webBookingEnabled: boolean;
  editWindow: string;
  cancellationWindow: string;
  daysInAdvanceStart: number;
  daysInAdvanceEnd: number;
  openTime: string;
  payment_terms: string;
  booking_terms: string;
}

export default function ConfigSegment() {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { Option } = Select;

  const dispatch = useAppDispatch();

  const [selected, setSelected] = useState(0); // Handle tab change

  const [state, setState] = useState<IState>({
    module: null,
    locations: [],
    selectedLocations: [],
    selectedDate: new Date(),
    start_time: "07:00",
    end_time: "19:30",
    interval: 30,
    segments: null,
    templates: [],
    selectedTemplateId: null,
  });

  const [moduleState, setModuleState] = useState<IModuleState>({
    moduleTitle: "",
    webBookingEnabled: false,
    editWindow: "",
    cancellationWindow: "",
    daysInAdvanceStart: null,
    daysInAdvanceEnd: null,
    openTime: "0:00",
    booking_terms: "",
    payment_terms: "",
  });

  const [moduleStateBeforeChanges, setModuleStateBeforeChanges] = useState<IModuleState>(undefined);
  const [moduleLoaded, setModuleLoaded] = useState<boolean>(false);

  const tabs = [
    {
      id: "manual",
      content: "Manual",
    },
    {
      id: "template",
      content: "Template",
    },
  ];

  useEffect(() => {
    void getModule();
  }, []);

  useEffect(() => {
    if (state.selectedDate) {
      void getSegments();
    }
  }, [state.selectedDate]);

  async function getModule() {
    dispatch(enqueue());
    const moduleRes = await GetReservationModule({ id: Number(id) }, false);
    if (moduleRes?.status !== StatusCode.OK) {
      history.push("/admin/settings/reservations/modules");
      dispatch(showError(t("secure.facility.settings.reservations.configSegment.002")));
      dispatch(dequeue());
      return;
    }
    const locationRes = await GetReservationLocation({ reservation_module_id: Number(id) }, false);
    if (locationRes.status !== StatusCode.OK) {
      history.push("/admin/settings/reservations/modules");
      dispatch(showError(t("secure.facility.settings.reservations.configSegment.003")));
      dispatch(dequeue());
      return;
    }
    dispatch(dequeue());

    const templateRes = await GetReservationTemplate({ module_id: Number(id) }, true);

    if (templateRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading templates"));
      return;
    }

    ReactDOM.unstable_batchedUpdates(() => {
      setState(prevState => ({
        ...prevState,
        module: moduleRes.data,
        locations: locationRes?.data,
        templates: templateRes.data,
      }));
      setModuleState({
        moduleTitle: moduleRes?.data?.title,
        webBookingEnabled: moduleRes?.data?.enable_web_booking,
        cancellationWindow: moduleRes?.data?.web_cancellation_hours,
        editWindow: moduleRes?.data?.web_edit_hours,
        daysInAdvanceStart: moduleRes?.data?.days_in_advance_start,
        daysInAdvanceEnd: moduleRes?.data?.days_in_advance_end,
        openTime: moduleRes?.data?.open_time,
        booking_terms: moduleRes?.data?.booking_terms,
        payment_terms: moduleRes?.data?.payment_terms,
      });
      setModuleStateBeforeChanges({
        moduleTitle: moduleRes?.data?.title,
        webBookingEnabled: moduleRes?.data?.enable_web_booking,
        cancellationWindow: moduleRes?.data?.web_cancellation_hours,
        editWindow: moduleRes?.data?.web_edit_hours,
        daysInAdvanceStart: moduleRes?.data?.days_in_advance_start,
        daysInAdvanceEnd: moduleRes?.data?.days_in_advance_end,
        openTime: moduleRes?.data?.open_time,
        booking_terms: moduleRes?.data?.booking_terms,
        payment_terms: moduleRes?.data?.payment_terms,
      });
      setModuleLoaded(true);
    });
  }

  async function getSegments() {
    const segmentRes = await GetReservationSegment(
      { module_id: Number(id), date: formatDate(state.selectedDate) },
      true,
    );
    if (segmentRes.status !== StatusCode.OK) {
      history.push("/admin/settings/reservations/modules");
      dispatch(showError(t("secure.facility.settings.reservations.configSegment.004")));
      return;
    }

    const formattedSegments = segmentRes.data?.reduce(
      (formattedSegments: { [key: string]: Array<ISegment> }, currentSegment: ISegment) => {
        return {
          ...formattedSegments,
          [currentSegment.start_time]: formattedSegments[currentSegment.start_time]
            ? [...formattedSegments[currentSegment.start_time], currentSegment]
            : [currentSegment],
        };
      },
      {},
    );
    setState(prevState => ({
      ...prevState,
      segments: formattedSegments,
      selectedVariant: null,
    }));
  }

  function handleDateChange(date: Date) {
    setState(prevState => ({ ...prevState, selectedDate: date }));
  }

  function onCheckboxChange(location_id: number) {
    const locations = [...state.selectedLocations];
    const foundIndex = locations?.indexOf(location_id);
    if (foundIndex === -1) {
      locations.push(location_id);
    } else {
      locations.splice(foundIndex, 1);
    }
    setState(prevState => ({ ...prevState, selectedLocations: locations }));
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleModuleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setModuleState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleToggleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, checked } = event.target;
    setModuleState(prevState => ({ ...prevState, [id]: checked }));
  }

  async function handleSegmentGeneration() {
    if (
      !state.start_time ||
      !state.end_time ||
      !state.interval ||
      !state.selectedDate ||
      state.selectedLocations.length === 0
    ) {
      dispatch(showError(t("secure.facility.settings.reservations.configSegment.005")));
      return;
    }

    const res = await PostReservationSegmentConfig(
      {
        location_ids: state.selectedLocations,
        date: formatDate(state.selectedDate),
        start_time: state.start_time,
        end_time: state.end_time,
        interval: state.interval,
      },
      true,
    );
    if (res.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.reservations.configSegment.006")));
      return;
    }
    dispatch(showSuccess(t("secure.facility.settings.reservations.configSegment.007")));
    void getSegments();
  }

  function unsavedChangesExist() {
    if (moduleStateBeforeChanges === undefined) {
      if (moduleLoaded) {
        setModuleStateBeforeChanges(moduleState);
      }
      return false;
    }

    return !isEqualWith(moduleState, moduleStateBeforeChanges);
  }

  async function saveModule() {
    if (!moduleState?.moduleTitle) {
      dispatch(showError(t("secure.facility.settings.reservations.configSegment.008")));
      return;
    }

    const moduleRes = await PutReservationModule(
      {
        id: Number(id),
        enable_web_booking: moduleState?.webBookingEnabled,
        web_edit_hours: Number(moduleState?.editWindow),
        web_cancellation_hours: Number(moduleState?.cancellationWindow),
        days_in_advance_start: Number(moduleState?.daysInAdvanceStart),
        days_in_advance_end: Number(moduleState?.daysInAdvanceEnd),
        open_time: moduleState?.openTime,
        title: moduleState?.moduleTitle,
        booking_terms: moduleState?.booking_terms,
        payment_terms: moduleState?.payment_terms,
      },
      true,
    );
    if (moduleRes?.status !== StatusCode.OK) {
      dispatch(showError("Error updating module"));
      return;
    }
    dispatch(showSuccess("Module updated successfully"));
    void getModule();
  }

  async function applyTemplate() {
    if (!state.selectedTemplateId) {
      dispatch(showError("No template selected"));
      return;
    }
    const applyRes = await PutApplyReservationTemplate(
      {
        date: formatDate(state.selectedDate),
        template_id: state.selectedTemplateId,
      },
      true,
    );

    if (applyRes?.status !== StatusCode.OK) {
      dispatch(showError("Error applying template"));
      return;
    }

    void getSegments();
  }

  function handleDropDownChange(value: string, id: string) {
    setState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleBookingTermsChange(terms: string) {
    setModuleState(prevState => ({ ...prevState, booking_terms: terms }));
  }

  function handlePaymentTermsChange(terms: string) {
    setModuleState(prevState => ({ ...prevState, payment_terms: terms }));
  }

  return (
    <>
      <Page
        title={state?.module?.title ?? ""}
        subtitle={t("secure.facility.settings.reservations.configSegment.011")}
        breadcrumbs={[
          {
            prefix: true,
            label: t("secure.facility.settings.reservations.configSegment.012"),
            url: "/admin/settings/reservations/modules",
          },
        ]}
        notificationBarProps={{
          isVisible: unsavedChangesExist(),
          onAction: saveModule,
          onCancel: () => setModuleState(moduleStateBeforeChanges),
        }}
      >
        {/* Translations Needed Below */}
        <Card>
          <Card.Section title={t("secure.facility.settings.reservations.configSegment.013")}>
            <div className="config-segments-generate-button-container">
              <Button size="medium" type="primary" onClick={saveModule}>
                {t("secure.facility.settings.reservations.configSegment.014")}
              </Button>
            </div>
            <Form>
              <FormLayout>
                <FormLayout.Group>
                  <Input
                    value={moduleState.moduleTitle ?? ""}
                    label={t("secure.facility.settings.reservations.configSegment.015")}
                    id="moduleTitle"
                    onChange={handleModuleInputChange}
                    placeholder={t("secure.facility.settings.reservations.configSegment.016")}
                  />
                  <Toggle
                    labelTop={t("secure.facility.settings.reservations.configSegment.017")}
                    id="webBookingEnabled"
                    checked={!!moduleState.webBookingEnabled ?? false}
                    onChange={handleToggleChange}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Input
                    value={moduleState.daysInAdvanceStart || ""}
                    label={"Booking Window Start"}
                    id="daysInAdvanceStart"
                    onChange={handleModuleInputChange}
                    suffix="days"
                    type="number"
                    placeholder={0}
                  />
                  <Input
                    value={moduleState.daysInAdvanceEnd || ""}
                    label={"Booking Window End"}
                    id="daysInAdvanceEnd"
                    onChange={handleModuleInputChange}
                    suffix="days"
                    type="number"
                    placeholder={0}
                  />
                  <TimePick
                    value={moduleState.openTime}
                    onChange={timeString => setModuleState(prevState => ({ ...prevState, openTime: timeString }))}
                    label={"Open Time"}
                    size="large"
                    status={moduleState.openTime === undefined ? "warning" : undefined}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Input
                    value={moduleState.cancellationWindow || ""}
                    label={t("secure.facility.settings.reservations.configSegment.018")}
                    id="cancellationWindow"
                    helpText={t("secure.facility.settings.reservations.configSegment.019")}
                    onChange={handleModuleInputChange}
                    suffix="hours"
                    placeholder={t("secure.facility.settings.reservations.configSegment.020")}
                    type="number"
                  />
                  <Input
                    value={moduleState.editWindow || ""}
                    label={t("secure.facility.settings.reservations.configSegment.021")}
                    id="editWindow"
                    helpText={t("secure.facility.settings.reservations.configSegment.022")}
                    onChange={handleModuleInputChange}
                    suffix="hours"
                    placeholder={t("secure.facility.settings.reservations.configSegment.023")}
                    type="number"
                  />
                </FormLayout.Group>
              </FormLayout>
            </Form>
            <Card
              title={"Booking and Payment Terms"} // TODO: Translation
              collapsable
              defaultCollapsed
            >
              <Card.Section>
                <FormLayout>
                  <FormLayout.Group>
                    <TextEditor
                      markdownText={moduleState.booking_terms ?? ""}
                      markdownTextOnChange={handleBookingTermsChange}
                      label={"Booking Terms"} // TODO: Translation
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextEditor
                      markdownText={moduleState.payment_terms ?? ""}
                      markdownTextOnChange={handlePaymentTermsChange}
                      label={"Payment Terms"} // TODO: Translation
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
            </Card>
          </Card.Section>
          <Card.Section title={t("secure.facility.settings.reservations.configSegment.024")}>
            <Tabs tabs={tabs} selected={selected} onSelect={selectedTabIndex => setSelected(selectedTabIndex)}>
              {selected === 0 ? (
                <>
                  <div className="config-segments-generate-button-container mt-4">
                    <Button size="medium" type="primary" onClick={handleSegmentGeneration}>
                      {t("secure.facility.settings.reservations.configSegment.025")}
                    </Button>
                  </div>
                  <Form>
                    <FormLayout>
                      <FormLayout.Group>
                        <TimePick
                          value={state.start_time}
                          onChange={timeString => setState(prevState => ({ ...prevState, start_time: timeString }))}
                          label={t("secure.facility.settings.reservations.configSegment.026")}
                          size="large"
                          status={state.start_time === undefined ? "warning" : undefined}
                        />
                        <TimePick
                          value={state.end_time}
                          onChange={timeString => setState(prevState => ({ ...prevState, end_time: timeString }))}
                          label={t("secure.facility.settings.reservations.configSegment.027")}
                          size="large"
                          status={state.end_time === undefined ? "warning" : undefined}
                        />
                        <Input
                          value={state.interval || ""}
                          label={t("secure.facility.settings.reservations.configSegment.028")}
                          id="interval"
                          type="number"
                          onChange={handleInputChange}
                          disabled={true}
                        />
                        <div className="flex flex-col">
                          <label className="config-segment-label">
                            {t("secure.facility.settings.reservations.configSegment.029")}
                          </label>
                          <DatePickerInput
                            months={1}
                            position="left"
                            startingDate={state.selectedDate}
                            setStartingDate={handleDateChange}
                          />
                        </div>
                      </FormLayout.Group>
                      <FormLayout.Group>
                        <div className="flex flex-col">
                          <label className="config-segment-label">
                            {t("secure.facility.settings.reservations.configSegment.030")}
                          </label>
                          <div className="flex flex-row gap-4 flex-wrap">
                            {state?.locations?.map((location, index) => {
                              return (
                                <Checkbox
                                  key={index}
                                  label={location?.title}
                                  size="medium"
                                  onChange={() => onCheckboxChange(location?.id)}
                                  checked={state?.selectedLocations?.includes(location?.id)}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </FormLayout.Group>
                    </FormLayout>
                  </Form>
                </>
              ) : null}

              {selected === 1 ? (
                <>
                  <div className="config-segments-generate-button-container mt-4">
                    <Button size="medium" type="primary" onClick={applyTemplate}>
                      {"Apply Template"}
                    </Button>
                  </div>
                  <Form>
                    <FormLayout>
                      <FormLayout.Group>
                        <Select
                          label="Template"
                          onChange={(value: string) => handleDropDownChange(value, "selectedTemplateId")}
                        >
                          {state.templates?.map((template: ITemplate, index: number) => {
                            return (
                              <Option key={index} value={template?.id}>
                                {template?.name}
                              </Option>
                            );
                          })}
                        </Select>
                        <div className="flex flex-col">
                          <label className="config-segment-label">
                            {t("secure.facility.settings.reservations.configSegment.029")}
                          </label>
                          <DatePickerInput
                            months={1}
                            position="left"
                            startingDate={state.selectedDate}
                            setStartingDate={handleDateChange}
                          />
                        </div>
                      </FormLayout.Group>
                    </FormLayout>
                  </Form>
                </>
              ) : null}
            </Tabs>
          </Card.Section>
        </Card>
        <Card>
          <Card.Section
            title={t("secure.facility.settings.reservations.configSegment.031")}
            sectionTitleActions={[
              {
                content: t("secure.facility.settings.reservations.configSegment.032"),
                action: () => history.push(`/admin/reservation/edit/${id}`),
              },
            ]}
          >
            {state.segments && Object.keys(state.segments)?.length > 0 ? (
              <div className="config-segments">
                <div className="segments-container">
                  <table className="ui-table ui-table-condensed ui-table-separated-horizontal segments-table">
                    <thead>
                      <tr className="segments-table-header">
                        <td className="segments-table-times-header">
                          {t("secure.facility.settings.reservations.configSegment.035")}
                        </td>
                        {state?.locations?.map((location, locationIndex) => {
                          return <td key={locationIndex}>{location.title}</td>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(state?.segments)?.map((segmentKey, segmentKeyIndex) => {
                        return (
                          <tr key={segmentKeyIndex}>
                            <td className="time-text">{moment(segmentKey, "hh:mm:ss").format("h:mm A")}</td>
                            {state?.locations?.map((location, locationIndex) => {
                              const segment = state?.segments[segmentKey]?.find(
                                searchSegment => searchSegment?.location_id === location?.id,
                              );
                              if (segment) {
                                return (
                                  <td key={locationIndex} className="segment-available">
                                    <div className="segment-container">
                                      <p className="text-semibold">
                                        <span>{location.title}, </span>
                                        <span>{moment(segmentKey, "hh:mm:ss").format("h:mm A")}</span>
                                      </p>
                                      <p>
                                        {segment?.blocks[0]?.variant_price ? (
                                          <LocaleCurrency amount={segment?.blocks[0]?.variant_price} />
                                        ) : (
                                          "$"
                                        )}{" "}
                                        / hour
                                      </p>
                                    </div>
                                  </td>
                                );
                              } else {
                                return <td key={locationIndex} className="segment-unavailable"></td>;
                              }
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <Callout
                type="info"
                title={t("secure.facility.settings.reservations.configSegment.033")}
                content={t("secure.facility.settings.reservations.configSegment.034")}
              />
            )}
          </Card.Section>
        </Card>
      </Page>
    </>
  );
}
