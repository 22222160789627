import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { IProduct, IVariant } from "redux/reducers/models/product";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import Page from "components/page/Page";
import FormLayout from "components/form/FormLayout";
import { Select } from "components/select/index";
import Icon from "components/icon/Icon";
import { IFacility } from "redux/reducers/models/facility";
import "./newInventoryTransfer.scss";
import "./editInventoryTransfer.scss";
import ProductSelect from "components/productModal/ProductSelect";
import Portal from "elements/Portal";
import Popup from "components/popup/Popup";
import ReactDOM from "react-dom";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";
import Input from "components/form/input";
import Sheet from "components/sheet/Sheet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios, { CancelToken } from "axios";
import { StatusCode } from "api/protocols";
import { dequeue, enqueue, showError, showSuccess } from "redux/actions/ui";
import { cloneDeep, isEqualWith, isNull } from "lodash";
import {
  DeleteInventoryTransferVariants,
  GetInventoryTransfer,
  GetInventoryTransferVariants,
  PostInventoryTransferVariants,
  PutInventoryTransfer,
  TInventoryTransfer,
  TInventoryTransferVariant,
} from "api/rpc/2024-04/facilityAdmin/product/inventoryTransfer";
import Checkbox from "components/form/checkbox/Checkbox";
import Callout from "components/callout/Callout";
import Spin from "components/spin/spin";
import { GetProduct } from "api/rpc/2024-04/facilityAdmin/product/product";

interface IFacilityState {
  facilities: Array<IFacility>;
  selectedFacility: IFacility;
  selectedFacilityBeforeChanges: IFacility;
  facilitySearchQuery: string;
}
interface IProductState {
  selectedProducts: Array<IProduct>;
  selectedProductsBeforeChanges: Array<IProduct>;
  /**Checked off variants to remove */
  variantIdsToRemove: Array<number>;
  /**Variants that will be passed to the api to remove */
  variantIdsSetToRemove: Array<number>;
  openModal: boolean;
}

interface IParams {
  id: string;
}

interface IInventoryState {
  inventoryTransfer: TInventoryTransfer;
}

interface ILocationState {
  params: { inventoryTransfer: TInventoryTransfer; inventoryTransferVariants: Array<TInventoryTransferVariant> };
}

export default function EditInventoryTransfer() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { Option } = Select;
  const { facilityStore } = useAppSelector(store => store);
  const { id } = useParams<IParams>();

  const [facilityState, setFacilityState] = useState<IFacilityState>({
    facilities: [],
    selectedFacility: null,
    selectedFacilityBeforeChanges: undefined,
    facilitySearchQuery: "",
  });

  const [productState, setProductState] = useState<IProductState>({
    selectedProducts: undefined,
    selectedProductsBeforeChanges: undefined,
    variantIdsToRemove: [],
    variantIdsSetToRemove: [],
    openModal: false,
  });

  const [inventoryState, setInventoryState] = useState<IInventoryState>({
    inventoryTransfer: undefined,
  });

  const [popupState, setPopupState] = useState<boolean>(false);

  useEffect(() => {
    if (facilityStore?.facilities?.length > 0 && facilityStore?.facility) {
      //Filter out current selected facility
      const filteredFacilities = [...facilityStore?.facilities]?.filter(
        facility => facility?.id !== facilityStore?.facility?.id,
      );
      setFacilityState(prevState => ({ ...prevState, facilities: filteredFacilities }));
    }
  }, [facilityStore?.facilities, facilityStore?.facility]);

  useEffect(() => {
    if (inventoryState?.inventoryTransfer && facilityState?.facilities) {
      const foundFacility = facilityState?.facilities?.find(
        facility => facility?.id === inventoryState?.inventoryTransfer?.destination_facility_id,
      );
      if (foundFacility) {
        setFacilityState(prevState => ({ ...prevState, selectedFacility: foundFacility }));
      }
    }
  }, [inventoryState?.inventoryTransfer, facilityState?.facilities]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadInventoryTransfer(source.token);
    return () => {
      source.cancel();
    };
  }, []);

  async function loadInventoryTransfer(cancelToken: CancelToken) {
    const locationState = location.state as ILocationState;
    let transfer = locationState?.params?.inventoryTransfer ?? undefined;
    let inventoryVariants = locationState?.params?.inventoryTransferVariants ?? undefined;

    if (!transfer || !inventoryVariants) {
      // show loader
      if (inventoryState?.inventoryTransfer !== undefined) {
        setInventoryState(prevState => ({ ...prevState, inventoryTransfers: undefined }));
      }

      const res = await GetInventoryTransfer({ id: Number(id) }, false, cancelToken);
      if (res.status !== StatusCode.OK) {
        if (cancelToken.reason) {
          return;
        }
        dispatch(showError("Error getting inventory transfer"));
        return;
      }
      transfer = res?.data[0];

      const variantsRes = await GetInventoryTransferVariants({ inventory_transfer_id: Number(id) }, false, cancelToken);

      if (variantsRes.status !== StatusCode.OK) {
        if (cancelToken.reason) {
          return;
        }
        dispatch(showError("Error getting inventory transfer products"));
        return;
      }
      inventoryVariants = variantsRes?.data;
    }

    const productRes = await GetProduct(
      { ids: inventoryVariants?.map(product => product?.product_id), variants: true },
      false,
    );
    if (productRes?.status !== StatusCode.OK) {
      if (cancelToken.reason) {
        return;
      }
      dispatch(showError("Error getting products"));
    } else {
      productRes?.data?.forEach((product, productIndex) => {
        productRes.data[productIndex].variants = product?.variants
          ?.filter(filteredVariant =>
            inventoryVariants?.some(inventoryVariant => inventoryVariant?.variant_id === filteredVariant?.id),
          )
          ?.map(variant => {
            const foundVariant = inventoryVariants?.find(
              inventoryVariant => inventoryVariant?.variant_id === variant?.id,
            );
            if (foundVariant) {
              return { ...variant, quantity: foundVariant?.quantity };
            }
          });
      });
    }

    ReactDOM.unstable_batchedUpdates(() => {
      setInventoryState(prevState => ({
        ...prevState,
        inventoryTransfer: transfer,
      }));
      setProductState(prevState => ({
        ...prevState,
        selectedProducts: productRes?.status === StatusCode.OK ? productRes?.data : [],
      }));
    });
  }

  function handleFacilityDropdownChange(value: number, facility: IFacility) {
    setFacilityState(prevState => ({ ...prevState, selectedFacility: facility, facilitySearchQuery: "" }));
  }

  async function handleTransfer() {
    const variants: Array<IVariant> = [];
    dispatch(enqueue());
    if (
      facilityState?.selectedFacility &&
      facilityState?.selectedFacility?.id !== facilityState?.selectedFacilityBeforeChanges?.id
    ) {
      const putRes = await PutInventoryTransfer(
        { id: Number(id), destination_facility_id: facilityState?.selectedFacility?.id },
        false,
      );
      if (putRes.status !== StatusCode.OK) {
        dispatch(dequeue());
        dispatch(showError("Error updating inventory transfer"));
        return;
      }
    }

    if (productState.variantIdsSetToRemove?.length > 0) {
      const deleteRes = await DeleteInventoryTransferVariants(
        { inventory_transfer_id: Number(id), variants: productState?.variantIdsSetToRemove },
        false,
      );
      if (deleteRes.status !== StatusCode.OK) {
        dispatch(showError("Error removing variants from transfer"));
      }
    }

    productState?.selectedProducts.forEach(product => {
      variants?.push(...[...product?.variants]);
    });
    const formattedVariants = variants?.map(variant => ({ variant_id: variant?.id, quantity: variant?.quantity }));
    setPopupState(false);
    const variantsRes = await PostInventoryTransferVariants(
      { inventory_transfer_id: Number(id), variants: formattedVariants },
      false,
    );
    if (variantsRes?.status !== StatusCode.OK) {
      dispatch(dequeue());
      dispatch(showError("Error updating inventory transfer"));
      return;
    }
    dispatch(dequeue());
    dispatch(showSuccess("Successfully updated inventory transfer"));
    history.push(`/admin/settings/transfer/${id}/view`);
  }

  function handleProductSelect(products: Array<IProduct>) {
    const selectedProducts = [...products];
    selectedProducts?.forEach((product, productIndex) => {
      product?.variants?.forEach((variant, variantIndex) => {
        if (variant?.quantity == null) {
          selectedProducts[productIndex].variants[variantIndex] = { ...variant, quantity: 1 };
        }
      });
    });
    setProductState(prevState => ({ ...prevState, selectedProducts: selectedProducts }));
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>, productIndex: number, variantIndex: number) {
    const { valueAsNumber } = e.target;
    const updatedProducts = [...productState.selectedProducts];
    const updatedVariant = updatedProducts[productIndex].variants[variantIndex];
    updatedProducts[productIndex].variants[variantIndex] = {
      ...updatedVariant,
      quantity: isNaN(valueAsNumber) ? null : valueAsNumber,
    };
    setProductState(prevState => ({ ...prevState, selectedProducts: updatedProducts }));
  }

  function handleColumnCheckboxChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { checked: allProductsChecked } = e.target;
    const variantsToRemove: Array<number> = [];
    if (allProductsChecked) {
      productState?.selectedProducts?.forEach(product => {
        product?.variants?.forEach(variant => {
          variantsToRemove?.push(variant?.id);
        });
      });
    }
    setProductState(prevState => ({ ...prevState, variantIdsToRemove: variantsToRemove }));
  }

  function handleProductCheckboxChange(productChecked: boolean, product: IProduct) {
    let variantsToRemove = [...productState?.variantIdsToRemove];
    if (productChecked) {
      variantsToRemove = variantsToRemove?.filter(
        variantId => !product?.variants?.some(variant => variant?.id === variantId),
      );
    } else {
      variantsToRemove = [...variantsToRemove, ...product?.variants?.map(variant => variant?.id)];
    }
    setProductState(prevState => ({ ...prevState, variantIdsToRemove: variantsToRemove }));
  }

  function handleVariantCheckboxChange(variantId: number) {
    const variantsToRemove = [...productState?.variantIdsToRemove];
    const variantIndex = variantsToRemove.indexOf(variantId);
    if (variantIndex !== -1) {
      variantsToRemove.splice(variantIndex, 1);
    } else {
      variantsToRemove.push(variantId);
    }
    setProductState(prevState => ({ ...prevState, variantIdsToRemove: variantsToRemove }));
  }

  function handleRemoveProducts() {
    const variantsToRemove = [...productState.variantIdsToRemove];
    const allVariantsToRemove = [...productState.variantIdsSetToRemove, ...productState.variantIdsToRemove];
    const updatedProducts = [...productState?.selectedProducts]?.filter(
      product => !product?.variants?.every(variant => variantsToRemove.includes(variant?.id)),
    );
    updatedProducts?.forEach((product, productIndex) => {
      const filteredVariants = [...product?.variants]?.filter(variant => !variantsToRemove.includes(variant?.id));
      updatedProducts[productIndex].variants = filteredVariants;
    });
    setProductState(prevState => ({
      ...prevState,
      selectedProducts: updatedProducts,
      variantIdsSetToRemove: allVariantsToRemove,
      variantIdsToRemove: [],
    }));
  }

  function unsavedChangesExist() {
    if (
      productState.selectedProductsBeforeChanges === undefined &&
      facilityState?.selectedFacilityBeforeChanges === undefined
    ) {
      if (productState?.selectedProducts && facilityState?.selectedFacility) {
        ReactDOM.unstable_batchedUpdates(() => {
          setProductState(prevState => ({
            ...prevState,
            selectedProductsBeforeChanges: cloneDeep([...prevState.selectedProducts]),
          }));
          setFacilityState(prevState => ({
            ...prevState,
            selectedFacilityBeforeChanges: { ...prevState.selectedFacility },
          }));
        });
      }
      return false;
    }

    return (
      !isEqualWith(
        productState.selectedProductsBeforeChanges,
        productState.selectedProducts,
        (originalProductArray, newProductArray) => {
          if (isNull(originalProductArray) && isNull(newProductArray)) {
            return true;
          }
        },
      ) ||
      !isEqualWith(
        facilityState.selectedFacilityBeforeChanges,
        facilityState.selectedFacility,
        (originalFacility, newFacility) => {
          if (isNull(originalFacility) && isNull(newFacility)) {
            return true;
          }
        },
      )
    );
  }

  function cancelUnsavedChanges() {
    ReactDOM.unstable_batchedUpdates(() => {
      setProductState(prevState => ({
        ...prevState,
        selectedProducts: cloneDeep([...prevState.selectedProductsBeforeChanges]),
        variantIdsToRemove: [],
        variantIdsSetToRemove: [],
      }));
      setFacilityState(prevState => ({
        ...prevState,
        selectedFacility: { ...prevState.selectedFacilityBeforeChanges },
      }));
    });
  }

  return (
    <Page
      title={`Edit Transfer: ${!inventoryState?.inventoryTransfer ? "" : inventoryState?.inventoryTransfer?.name}`}
      breadcrumbs={[{ prefix: true, label: "Back to View Transfer", url: `/admin/settings/transfer/${id}/view` }]}
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: () => setPopupState(true),
        onCancel: cancelUnsavedChanges,
      }}
    >
      {inventoryState?.inventoryTransfer === undefined ? (
        <span style={{ display: "flex", justifyContent: "center", margin: "auto" }}>
          <Spin />
        </span>
      ) : (
        <>
          <FormLayout>
            <FormLayout.Group>
              {facilityState.selectedFacility ? (
                <>
                  <div>
                    <p className="inventory-transfer-label">Send To</p>
                  </div>
                  <div className="inventory-transfer-selected-name">
                    <div>{facilityState.selectedFacility?.long_name}</div>
                    <button
                      onClick={() =>
                        setFacilityState(prevState => ({
                          ...prevState,
                          selectedFacility: null,
                          facilitySearchQuery: "",
                        }))
                      }
                    >
                      <Icon style="far" icon="times" />
                    </button>
                  </div>
                </>
              ) : (
                <Select
                  showSearch
                  onSearch={(query: string) =>
                    setFacilityState(prevState => ({ ...prevState, facilitySearchQuery: query }))
                  }
                  showDropDownOnFocus
                  searchValue={facilityState?.facilitySearchQuery}
                  label="Send To"
                  onChange={handleFacilityDropdownChange}
                  placeholder="Search facilities..."
                >
                  {facilityState?.facilities
                    ?.filter(facility =>
                      facility?.long_name
                        .toLocaleLowerCase()
                        .includes(facilityState?.facilitySearchQuery?.toLocaleLowerCase()),
                    )
                    ?.map((facility, index) => {
                      return (
                        <Option key={index} value={facility?.id} extraValues={facility}>
                          {facility?.long_name}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </FormLayout.Group>
          </FormLayout>

          {productState?.variantIdsToRemove?.length > 0 ? (
            <button onClick={handleRemoveProducts} className="edit-inventory-transfer-button">
              <FontAwesomeIcon size="1x" icon={["far", "minus"]} /> Remove Products
            </button>
          ) : (
            <button
              onClick={() => setProductState(prevState => ({ ...prevState, openModal: true }))}
              className="edit-inventory-transfer-button"
            >
              <FontAwesomeIcon size="1x" icon={["far", "plus"]} /> Add Products
            </button>
          )}
          <DataTable
            className="edit-inventory-transfer-table"
            columns={[
              {
                label: "",
                content: (
                  <Checkbox
                    size="small"
                    checked={
                      productState?.selectedProducts?.length === 0
                        ? false
                        : productState?.selectedProducts?.every(product =>
                            product?.variants?.every(variant =>
                              productState?.variantIdsToRemove?.includes(variant?.id),
                            ),
                          )
                    }
                    onChange={handleColumnCheckboxChange}
                  />
                ),
              },
              { label: "Title" },
              { label: "Vendor" },
              { label: "Sku" },
              { label: "Quantity" },
            ]}
            loading={!productState.selectedProducts}
          >
            {productState?.selectedProducts?.map((product, productIndex) => {
              const singleVariant = product?.variant_count === 1;
              const productChecked = singleVariant
                ? productState?.variantIdsToRemove?.includes(product?.variants[0]?.id)
                : product?.variants?.every(variant => productState?.variantIdsToRemove?.includes(variant?.id));
              return (
                <React.Fragment key={productIndex}>
                  <tr
                    className="inventory-transfer-product-header clickable"
                    onClick={e => handleProductCheckboxChange(productChecked, product)}
                  >
                    <td onClick={e => e.stopPropagation()}>
                      <Checkbox
                        size="small"
                        checked={productChecked}
                        onChange={e => handleProductCheckboxChange(productChecked, product)}
                      />
                    </td>
                    <td>{product?.preferred_title ? product?.preferred_title : product.title}</td>
                    <td>{product.vendor_title}</td>
                    <td>{singleVariant && product?.variants[0]?.sku}</td>
                    <td style={{ width: "15%" }} onClick={e => e.stopPropagation()}>
                      {singleVariant && (
                        <Input
                          type="number"
                          labelHidden
                          onChange={e => handleInputChange(e, productIndex, 0)}
                          value={product?.variants[0]?.quantity ?? ""}
                          placeholder="Quantity"
                        />
                      )}
                    </td>
                  </tr>
                  {product?.variant_count > 1 &&
                    product?.variants?.map((variant, variantIndex) => (
                      <tr
                        key={variantIndex}
                        className="clickable"
                        onClick={e => handleVariantCheckboxChange(variant?.id)}
                      >
                        <td style={{ textIndent: "3rem" }} onClick={e => e.stopPropagation()}>
                          <Checkbox
                            size="small"
                            checked={productState?.variantIdsToRemove?.includes(variant?.id)}
                            onChange={e => handleVariantCheckboxChange(variant?.id)}
                          />
                        </td>
                        <td>{variant?.title}</td>
                        <td></td>
                        <td>{variant?.sku}</td>
                        <td style={{ width: "15%" }} onClick={e => e.stopPropagation()}>
                          <Input
                            type="number"
                            labelHidden
                            onChange={e => handleInputChange(e, productIndex, variantIndex)}
                            value={variant?.quantity ?? ""}
                            placeholder="Quantity"
                          />
                        </td>
                      </tr>
                    ))}
                </React.Fragment>
              );
            })}
          </DataTable>
        </>
      )}

      <div className="inventory-transfer-products-modal">
        <Sheet
          open={productState.openModal}
          title="Search Products"
          size="medium"
          onCancel={() => setProductState(prevState => ({ ...prevState, openModal: false }))}
          cancelText="Close"
          closable
          okText="Confirm"
          onOk={() => setProductState(prevState => ({ ...prevState, openModal: false }))}
        >
          <ProductSelect
            selectedProducts={productState.selectedProducts}
            onChange={handleProductSelect}
            search
            getProductsParams={{ track_inventory: true }}
            variantInput={{ key: "quantity", placeholder: "Quantity" }}
          />
        </Sheet>
      </div>

      <Portal isMounted={popupState}>
        <Popup
          stacked
          open={popupState}
          onOk={handleTransfer}
          okDisabled={
            !facilityState.selectedFacility ||
            productState.selectedProducts?.length === 0 ||
            productState?.selectedProducts?.some(product => product?.variants?.some(variant => !variant.quantity))
          }
          onCancel={() => setPopupState(false)}
          type="info"
          title={"Save Changes?"}
          description={"Are you sure you want to save these changes?"}
        >
          {!facilityState.selectedFacility && <Callout type="error" title="No Facility Selected" />}
          {productState.selectedProducts?.length === 0 && <Callout type="error" title="No Inventory Selected" />}
          {productState?.selectedProducts?.some(product =>
            product?.variants?.some(variant => !variant.quantity),
          ) && (
            <Callout
              type="error"
              title="Missing Quantity"
              content="One or more inventory products are missing a quantity value"
            />
          )}
        </Popup>
      </Portal>
    </Page>
  );
}
