import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import { loadLeague, loadFacility } from "redux/actions/customer/leagueRegistration";

import Page from "components/page/Page";
import LeagueHeader from "../LeagueHeader/LeagueHeader";

interface IParams {
  facilityShortName: string;
  leagueHandle: string;
}

export default function LeagueAnnouncements() {
  const { facilityShortName, leagueHandle } = useParams<IParams>();
  const uiActions = useAppDispatch();
  const { leagueRegistrationStore } = useAppSelector(store => store);

  useEffect(() => {
    if (!leagueRegistrationStore.facility) {
      void uiActions(loadFacility(facilityShortName, true));
    }
  }, []);

  useEffect(() => {
    if (leagueRegistrationStore.facility && !leagueRegistrationStore.league) {
      void uiActions(loadLeague(leagueRegistrationStore.facility.id, leagueHandle, true));
    }
  }, [leagueRegistrationStore.facility]);

  return (
    <>
      <LeagueHeader facility={leagueRegistrationStore.facility} league={leagueRegistrationStore.league} />
      <Page title="Announcements"></Page>
    </>
  );
}
