export interface IProductOptions {
  label: string;
  description?: string;
  url: string;
  icon?: any;
}

import { useTranslation, Trans } from "react-i18next";
export default function SettingOptionsTranslation() {
  const { t, i18n } = useTranslation();

  const productOptions: IProductOptions[] = [
    {
      label: t("secure.facility.product.product_options.001"),
      description: "List of Products",
      url: "/admin/product", //Temporary route
    },
    {
      label: t("secure.facility.product.product_options.002"),
      description: "List of Membership Options",
      url: "/admin/membership",
    },
    {
      label: t("secure.facility.product.product_options.003"),
      description: "List of Ticket Options",
      url: "/admin/tickets",
    },
  ];

  return productOptions;
}
