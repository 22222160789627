import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { PostEvent } from "api/rpc/2024-04/facilityAdmin/event/hospitalityEvent";
import { StatusCode } from "api/protocols";
import { showError, showSuccess } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import TimePick from "components/timePick/TimePick";
import { Select } from "components/select/index";
import { handleChangeEventInput } from "helpers/Helpers";

interface IEventNewState {
  name: string;
  start_time: string;
  date: Date;
  type: string;
}

export default function HospitalityEventNew() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { Option } = Select;

  const dispatch = useAppDispatch();

  const [tournament, setTournament] = useState<IEventNewState>({
    name: "",
    start_time: "12:00",
    date: new Date(),
    type: null,
  });

  async function createEvent() {
    if (tournament.date) {
      const year = tournament.date.toLocaleString("en-CA", { year: "numeric" });
      const month = tournament.date.toLocaleString("en-CA", { month: "2-digit" });
      const day = tournament.date.toLocaleString("en-CA", { day: "2-digit" });

      const dateString = `${year}-${month}-${day}`;

      const tournamentRes = await PostEvent(
        {
          name: tournament.name,
          start_time: tournament.start_time,
          date: dateString,
          type: tournament.type,
        },
        true,
      );

      if (tournamentRes.status !== StatusCode.OK) {
        dispatch(
          showError(
            typeof tournamentRes.data === "string"
              ? tournamentRes.data
              : t("secure.facility.hospitality.hospitality_event_new.001"),
          ),
        );
        return;
      }

      dispatch(showSuccess(t("secure.facility.hospitality.hospitality_event_new.002")));
      history.push("/admin/events-hospitality");
    }
  }

  function handleDateChange(updatedDate: Date) {
    setTournament(prevState => ({ ...prevState, date: updatedDate }));
  }

  const primaryAction = {
    content: t("secure.facility.hospitality.hospitality_event_new.003"),
    action: () => createEvent(),
  };

  return (
    <Page
      title={t("secure.facility.hospitality.hospitality_event_new.004")}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.hospitality.hospitality_event_new.005"),
          url: "/admin/events-hospitality",
        },
      ]}
    >
      <Form>
        <Card title={t("secure.facility.hospitality.hospitality_event_new.006")}>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <TimePick
                  value={tournament.start_time}
                  onChange={timeString => setTournament(prev => ({ ...prev, start_time: timeString }))}
                  label={t("secure.facility.hospitality.hospitality_event_new.007")}
                  size="large"
                  status={tournament.start_time === undefined ? "warning" : undefined}
                />
                <DatePickerInput
                  label={t("secure.facility.hospitality.hospitality_event_new.008")}
                  months={1}
                  position="left"
                  startingDate={tournament.date}
                  setStartingDate={handleDateChange}
                />
              </FormLayout.Group>
            </FormLayout>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={tournament.name}
                  label={t("secure.facility.hospitality.hospitality_event_new.009")}
                  id="name"
                  onChange={e => handleChangeEventInput(e, tournament, setTournament)}
                />
                <Select
                  label={t("secure.facility.hospitality.hospitality_event_new.010")}
                  onChange={(value: string) => setTournament(prev => ({ ...prev, type: value }))}
                >
                  <Option key={0} value={"wedding"}>
                    {t("secure.facility.hospitality.hospitality_event_new.011")}
                  </Option>
                  <Option key={1} value={"banquet"}>
                    {t("secure.facility.hospitality.hospitality_event_new.012")}
                  </Option>
                  <Option key={2} value={"other"}>
                    {t("secure.facility.hospitality.hospitality_event_new.013")}
                  </Option>
                </Select>
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>
      </Form>
    </Page>
  );
}
