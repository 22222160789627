import { APIGet, APIPost, APIPut } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "client-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/client/account";

export interface IGetAccount {
  id?: number;
  customer_id?: number;
  search?: string;
  extended?: boolean;
  limit?: number;
  offset?: number;
}

export async function GetAccount(params: IGetAccount, useGlobalLoader?: boolean, token?: CancelToken) {
  return await APIGet(baseUrl, params, useGlobalLoader, token);
}

export interface IPostAccount {
  title: string;
  currency: string;
  global: boolean;
  account_number: string;
  limit: number;
  customer_id?: number;
  accounting_reference_id?: number;
  facility_id?: number;
}

export async function PostAccount(params: IPostAccount, useGlobalLoader?: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

export interface IPutAccount {
  id: number;
  title: string;
  account_number: string;
  limit: number;
  accounting_reference_id: number;
  customer_id?: number;
  tournament_id?: number;
  global?: boolean;
}

export async function PutAccount(params: IPutAccount, useGlobalLoader?: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

//GET AccountTransactions
//Get a list of account transactions
export async function GetAccountTransactions(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseUrl + "/transaction", params, useGlobalLoader);
}

export async function ReopenHouseAccount(params: { account_id: number }, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/close", params, useGlobalLoader);
}
