import React, { useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { StatusCode } from "api/protocols";

import { ITeeTime } from "redux/reducers/models/teetime";

import { useAppSelector } from "hooks/redux";
import { convertTime, formatDate } from "helpers/Helpers";

import DatePickerInput from "components/datePickerInput/DatePickerInput";
import Popover from "components/popover/Popover";
import { Select } from "components/select/index";

import { useTranslation } from "react-i18next";
import Spin from "components/spin/spin";
import { GetTeeTime as GetClientTeeTime } from "api/rpc/2024-04/clientAdmin/teesheet/teeSheet";
import { GetTeeTime as GetFacilityTeeTime } from "api/rpc/2024-04/facilityAdmin/teesheet/teeSheet";

type DuplicateBookingTab = {
  isActive: boolean;
  copyBookingFutureDates: any[];
  bookingStateHoles: number;
  bookingStateQuantity: number;
  addCopyBookingIdToList: (copyBookingId: number, copyBookingTime: string, copyBookingDate: string) => void;
  confirmCopyBooking: (copyBookingId: number) => void;
  userLevel: "facility" | "client";
};

export default function DuplicateBookingTab(props: DuplicateBookingTab) {
  const { Option } = Select;
  const { t, i18n } = useTranslation();
  const teeSheetStore = useAppSelector(store =>
    props.userLevel === "facility" ? store.teeSheetStore : store.clientTeeSheetStore,
  );
  const facilityStore = useAppSelector(store =>
    props.userLevel === "facility" ? store.facilityStore : store.clientFacilityStore,
  );
  const [date, setDate] = useState<Date | null>(new Date());
  const [loadingTeeTimes, setLoadingTeeTimes] = useState(false);
  const [duplicateBooking, setDuplicateBooking] = useState({
    copyBookingId: null,
    copyBookingTime: null,
    copyBookingDate: null,
  });

  const [allAvailableTeeTimes, setAllAvailableTeeTimes] = useState([]);
  const availableTimes = useMemo(() => {
    const returnData: Partial<ITeeTime>[] = [];

    if (props.bookingStateHoles === 18) {
      allAvailableTeeTimes.map((teetime: ITeeTime, index) => {
        if (
          teetime.blocked_type === "open" &&
          teetime.divisions_available.includes(2) &&
          teetime.turn_tee_time !== null
        ) {
          returnData.push({
            id: teetime.id,
            start_time: convertTime(teetime.start_time),
            course_id: teetime.course_id,
            tournament_id: teetime.tournament_id,
            league_id: teetime.league_id,
          });
        }
      });
    } else {
      allAvailableTeeTimes.map((teetime: ITeeTime, index) => {
        if (teetime.blocked_type === "open") {
          returnData.push({
            id: teetime.id,
            start_time: convertTime(teetime.start_time),
            course_id: teetime.course_id,
            tournament_id: teetime.tournament_id,
            league_id: teetime.league_id,
          });
        }
      });
    }
    return returnData;
  }, [allAvailableTeeTimes, props.bookingStateHoles]);

  //handle async times dependant on date
  useEffect(() => {
    if (date !== null) {
      void updateAvailableTeeTimes();
    }
  }, [date]);

  //reset if Tab opens/closes
  useEffect(() => {
    resetDuplicateBookingState();
    syncDateWithState(date);
  }, [props.isActive]);

  async function updateAvailableTeeTimes() {
    const formattedDate = formatDate(date);
    const apiParams = {
      facility_id: props.userLevel === "client" ? facilityStore.facility?.id : undefined,
      date: formattedDate,
      quantity_remaining: props.bookingStateQuantity,
      turn_tee_time: true,
    };

    // API call dependant on userLevel
    switch (props.userLevel) {
      case "facility": {
        const teeTimeRes = await GetFacilityTeeTime(apiParams, false);
        if (teeTimeRes.status === StatusCode.OK) {
          console.log("moveBooking facility API ...", teeTimeRes); //debug
          setAllAvailableTeeTimes(teeTimeRes.data);
          setLoadingTeeTimes(true);
        }
        break;
      }
      case "client": {
        const teeTimeRes = await GetClientTeeTime(apiParams, false);
        if (teeTimeRes.status === StatusCode.OK) {
          console.log("moveBooking client API ...", teeTimeRes); //debug
          setAllAvailableTeeTimes(teeTimeRes.data);
          setLoadingTeeTimes(true);
        }
        break;
      }
      default:
        setAllAvailableTeeTimes([]);
    }
  }

  function syncDateWithState(returnDate: Date) {
    const copyDateArr = String(returnDate).split(" ");
    const copyDate = copyDateArr[1] + " " + copyDateArr[2];

    if (returnDate !== date) {
      setDate(returnDate);
      setLoadingTeeTimes(false);
    }

    setDuplicateBooking({ ...duplicateBooking, copyBookingDate: copyDate });
  }

  function handleTimeChange(incomingValue: number) {
    const foundTimeSlot = availableTimes.find(time => time.id === incomingValue);

    setDuplicateBooking({
      ...duplicateBooking,
      copyBookingId: incomingValue,
      copyBookingTime: foundTimeSlot?.start_time,
    });
  }

  function handleConfirmCopyBooking() {
    props.confirmCopyBooking(duplicateBooking.copyBookingId);
    resetDuplicateBookingState();
  }

  function handleCopyBookingIdToList() {
    props.addCopyBookingIdToList(
      duplicateBooking.copyBookingId,
      duplicateBooking.copyBookingTime,
      duplicateBooking.copyBookingDate,
    );
    resetDuplicateBookingState();
  }

  const resetDuplicateBookingState = () => {
    setDuplicateBooking({
      ...duplicateBooking,
      copyBookingId: null,
      copyBookingTime: null,
    });
  };

  return (
    <div className="bm-copy-booking-popover-container">
      <Popover active={props.isActive}>
        <div className="bm-copy-booking-container">
          <p className="bm-copy-booking-title">{t("elements.tee_sheet.duplicate_booking_tab.001")}</p>
          <p className="bm-copy-booking-subtitle">Do you want to duplicate this booking?</p> {/**TODO: Translate */}
          {props.copyBookingFutureDates.map((teetime: any, index: number) => {
            console.log(teetime);
            return (
              <div className="bm-copy-booking-dates-chosen" key={index}>
                <p className="bm-copy-booking-dates-chosen-p">{teetime}</p>
                <FontAwesomeIcon icon={["far", "copy"]} className="bm-copy-booking-dates-chosen-icon" />
              </div>
            );
          })}
          {/* <div className="bm-copy-booking-current-date-container">
            <div>
              <p className="bm-copy-booking-current-date">{props.currentDate}</p>
            </div>

            <div>
              <FontAwesomeIcon icon={["far", "copy"]} className="bm-copy-booking-current-date-icon" />
            </div>
          </div> */}
          <hr />
          <div className="bm-copy-booking-select-date">
            <DatePickerInput
              months={1}
              position="left"
              startingDate={date}
              disablePastDays
              setStartingDate={(returnDate: Date) => syncDateWithState(returnDate)}
              rightAlignedCalendar
            />
          </div>
          {loadingTeeTimes ? (
            <div style={{ marginTop: "20px" }}>
              {/* <FontAwesomeIcon icon={["far", "clock"]} /> */}
              <Select
                className="w-full bm-copy-booking-select"
                onChange={(incomingValue: number) => handleTimeChange(incomingValue)}
                defaultValue={"0"}
                timeSelect
              >
                <Option value="0">{t("elements.tee_sheet.duplicate_booking_tab.002")}</Option>

                {availableTimes
                  .filter(availableTeeTime => {
                    if (availableTeeTime?.course_id === teeSheetStore.selectedTeeTime?.course_id) {
                      return true;
                    } else {
                      return false;
                    }
                  })
                  .map(memoizedTeetime => {
                    return (
                      <Option value={memoizedTeetime.id} key={memoizedTeetime.id}>
                        <span style={{ display: "flex", alignItems: "center", gap: "6px" }}>
                          {memoizedTeetime.start_time}
                          {memoizedTeetime.tournament_id && <FontAwesomeIcon icon={["far", "trophy"]} />}
                          {memoizedTeetime.league_id && <FontAwesomeIcon icon={["far", "users"]} />}
                        </span>
                      </Option>
                    );
                  })}
              </Select>
            </div>
          ) : (
            <div style={{ marginTop: "20px", height: "40px" }}>
              <Spin className="self-center" />
            </div>
          )}
          <div className="flex flex-col">
            <button onClick={handleCopyBookingIdToList} className="bm-copy-booking-duplicate-again-button">
              {"Add +"} {/* TODO: Translate */}
            </button>
            <button onClick={handleConfirmCopyBooking} className="bm-copy-booking-confirm-button">
              {t("elements.tee_sheet.duplicate_booking_tab.004")}
            </button>
          </div>
        </div>
      </Popover>
    </div>
  );
}
