import { APIGet, APIPost, APIPut, APIDelete, StatusCode, IAPIResponse } from "api/protocols";
import { IGreenFee, IItem, IPowerCart, IStub } from "redux/reducers/models/ticket";

const apiVersion = "2021-01";
const adminType = "client-admin";
const baseTicketUrl = "/" + apiVersion + "/" + adminType + "/ticket";
const baseTicketStubUrl = "/2022-09/" + adminType + "/ticket";

interface ITicket {
  id?: number;
  title?: string;
  value?: any;
  included?: string;
  quantity?: number;
}

// POST Ticket
export async function PostTicket(body: any, useGlobalLoader: boolean) {
  return await APIPost(baseTicketUrl, body, useGlobalLoader);
}

//GET Tickets
export async function GetTickets(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseTicketUrl, params, useGlobalLoader);
}

// GET Ticket Validate
interface IGetTicketValidate {
  code?: string;
}

export interface IGetTicketValidateRes extends IAPIResponse {
  data: {
    redemption_products: {
      green_fee: IGreenFee[];
      items: IItem[];
      power_cart: IPowerCart[];
    };
    stub: IStub;
  };
}

export async function GetTicketValidate(
  params: IGetTicketValidate,
  useGlobalLoader: boolean,
): Promise<IGetTicketValidateRes> {
  return await APIGet(baseTicketUrl + "/stub/validate", params, useGlobalLoader);
}

//GET Specific Ticket
interface IGetTicketById {
  id: number;
}

export async function GetTicketById(params: IGetTicketById, useGlobalLoader: boolean) {
  return await APIGet(baseTicketUrl, params, useGlobalLoader);
}

interface IGetTicketStub {
  code?: string;
  customer_id?: number;
  id?: number;
  search?: string;
}
//GET Ticket Stub
export async function GetTicketStub(params: IGetTicketStub, useGlobalLoader: boolean) {
  return await APIGet(baseTicketUrl + "/stub", params, useGlobalLoader);
}

//PUT Ticket Stub
export async function PutTicketStub(params: IGetTicketStub, useGlobalLoader: boolean) {
  return await APIPut(baseTicketStubUrl + "/stub", params, useGlobalLoader);
}

//PUT Update Tickets
export async function UpdateTickets(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseTicketUrl, params, useGlobalLoader);
}

// PUT Redeem

export async function PutRedeem(code: string, useGlobalLoader: boolean) {
  return await APIPut(baseTicketUrl + `/stub/redeem?code=${code}`, null, useGlobalLoader);
}

//POST Add Ticket To Product / variant
export async function AddTicketToVariant(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseTicketUrl + "/attach", params, useGlobalLoader);
}

//DELETE Ticket from product / variant
export async function DeleteTicketFromVariant(params: any, useGlobalLoader: boolean) {
  return await APIDelete(baseTicketUrl + "/detach", params, useGlobalLoader);
}
