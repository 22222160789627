import { APIGet, APIPost, APIPut, IAPIResponse } from "api/protocols";
import { ITeeTime } from "redux/reducers/models/teetime";
import { CancelToken } from "axios";
import { TShotgunTemplate } from "redux/reducers/models/teesheet";

const apiVersion = "2024-04";
const adminType = "client-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/tee-sheet/template";

export type TGetShotgunTemplate = {
  id?: string | number;
  /** returns tee_times attached */
  extended?: boolean;
  facility_id: number;
};

// Get Tee Sheet Shotgun Template
export async function GetShotGunTemplate(params: TGetShotgunTemplate, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<TShotgunTemplate[]>(baseUrl + "/shotgun", params, useGlobalLoader, token);
}
