import { APIGet, APIPost, APIPut, APIDelete, StatusCode, IAPIResponse } from "api/protocols";
import { CustomerTypeResStructure } from "api/rpc/2022-09/facilityAdmin/customer/type";
import { IVariant } from "pages/guest/models/product";

const apiVersion = "2024-04";
const baseCustomerUrl = "/" + apiVersion + "/customer/tee-sheet";

interface IGetNotifications {
  date: string;
  facility_id: number;
}

interface IGetCheckInsParams {
  facility_id: number;
  day_of_week_number: number;
}

export interface INotification {
  id: number;
  tee_sheet_id: string;
  date?: string;
  subject: string;
  content: string;
  type: string;
  user_id: number;
}

export interface ICheckIns {
  created_at: string;
  customer_type: CustomerTypeResStructure;
  customer_type_id: number;
  facility_id: number;
  holes: number;
  id: number;
  pricing_sheet: {
    application: string;
    course_id: number;
    created_at: string;
    day_of_week: string;
    day_of_week_number: number;
    end_time: string;
    start_time: string;
    facility_id: number;
    id: number;
    title: string;
    updated_at: string;
  };
  variant_id: string;
  variant: IVariant;
}

export async function GetCustomerNotifications(
  params: IGetNotifications,
  useGlobalLoader: boolean,
): Promise<IAPIResponse<Array<INotification>>> {
  return await APIGet(baseCustomerUrl + "/notification", params, useGlobalLoader);
}

export async function GetCustomerCheckIns(
  params: IGetCheckInsParams,
  useGlobalLoader: boolean,
): Promise<IAPIResponse<Array<ICheckIns>>> {
  return await APIGet(baseCustomerUrl + "/check-in", params, useGlobalLoader);
}
