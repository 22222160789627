import { ColorPickerDropdown } from "components/colorPickerDropdown/ColorPickerDropdown";
import { difference, inRange, range } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { IProduct } from "redux/reducers/models/product";
import "./productCards.scss";
import { ButtonNew as Button } from "components/buttonNew";
import ProductCard from "./productCard/ProductCard";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ProductCardSlot from "./productCardSlot/ProductCardSlot";

import { useTranslation } from "react-i18next";
import RightClickMenu from "components/rightClickMenu/RightClickMenu";

interface IProductCardsProps {
  products: IProduct[];
  productsLoaded: boolean;
  onChangeProductPosition: (id: number, position: number) => void;
  onRemoveProduct: (id: number) => void;
  onAddProducts: (availableProductPositions: number[]) => void;
  setProducts: (products: IProduct[]) => void;
}

interface IProductCardsState {
  gridRows: number;
  gridConstructed: boolean;
  selectedProductId: number;
  selectedRows: number[];
}

export const ProductCardsItemTypes = {
  PRODUCT_CARD: "product_card",
};

export function ProductCards(props: IProductCardsProps) {
  const productCardsRef = useRef(null);
  const { t, i18n } = useTranslation();
  const [colorPickerDropdownOpen, setColorPickerDropdownOpen] = useState<boolean>(false);

  const GRID_COL = 6;
  const EXTRA_GRID_ROWS = 3;

  const { products, productsLoaded, onChangeProductPosition, onRemoveProduct, onAddProducts, setProducts } = props;
  const [productCardsState, setProductCardsState] = useState<IProductCardsState>({
    gridRows: undefined,
    gridConstructed: false,
    selectedProductId: undefined,
    selectedRows: [],
  });

  function getSelectedProduct(id: number) {
    return products.find(product => product.id === id);
  }

  function selectedProductExists(id: number) {
    return getSelectedProduct(id) !== undefined;
  }

  function getRowFromPosition(position: number) {
    return Math.ceil(position / GRID_COL);
  }

  function getRowFromProductId(productId: number) {
    const productPosition = getSelectedProduct(productId)?.folder_position;
    return getRowFromPosition(productPosition);
  }

  function getFinalProductRow() {
    if (products.length === 0) {
      return 1;
    } else {
      const positions = products.map(product => product.folder_position);
      const finalProductPosition = positions.reduce((prev, next) => (prev < next ? next : prev));
      const finalProductRow = getRowFromPosition(finalProductPosition);

      return finalProductRow;
    }
  }

  function isPositionInASelectedRow(position: number) {
    const row = getRowFromPosition(position);
    return productCardsState.selectedRows.some(selectedRow => selectedRow === row);
  }

  useEffect(() => {
    if (!selectedProductExists(productCardsState.selectedProductId)) {
      setProductCardsState(prev => ({ ...prev, selectedProductId: undefined }));
    }

    if (productsLoaded && !productCardsState.gridConstructed) {
      const finalProductRow = getFinalProductRow();
      setProductCardsState(prev => ({
        ...prev,
        gridRows: Math.max(Math.ceil(products.length / GRID_COL) + EXTRA_GRID_ROWS, finalProductRow),
        gridConstructed: true,
      }));
    }

    if (productCardsState.gridConstructed) {
      const finalProductRow = getFinalProductRow();

      // If the user deletes all the rows in the grid or cancels unsaved changes
      // with insufficient rows the grid will grow to fit the changes
      if (finalProductRow > productCardsState.gridRows) {
        setProductCardsState(prev => ({
          ...prev,
          gridRows: finalProductRow,
        }));
      }
    }
  }, [products, productsLoaded]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (productCardsRef.current && !productCardsRef.current.contains(event.target)) {
        setProductCardsState(prev => ({ ...prev, selectedProductId: undefined, selectedRows: [] }));
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  function selectRow(selectedRow: number) {
    let updatedRows: number[] = [];
    if (productCardsState.selectedRows.some(row => row === selectedRow)) {
      updatedRows = productCardsState.selectedRows.filter(row => row !== selectedRow);
    } else {
      updatedRows = [...productCardsState.selectedRows, selectedRow];
    }

    setProductCardsState(prev => ({
      ...prev,
      selectedRows: updatedRows,
    }));
  }

  function selectProduct(event: any, productId: number) {
    if (event.shiftKey) {
      const selectedRow = getRowFromProductId(productId);
      selectRow(selectedRow);
    } else {
      setProductCardsState(prev => ({
        ...prev,
        selectedProductId: prev.selectedProductId !== productId ? productId : undefined,
      }));
    }
  }

  function selectEmptyCard(event: any, position: number) {
    if (event.shiftKey) {
      const selectedRow = getRowFromPosition(position);
      selectRow(selectedRow);
    } else {
      const availablePositions = calculateSubsequentAvailablePositions(position);
      onAddProducts(availablePositions);
      setProductCardsState(prev => ({ ...prev, selectedProductId: undefined }));
    }
  }

  function calculateSubsequentAvailablePositions(currentPosition: number) {
    const maxPosition = GRID_COL * productCardsState.gridRows;
    const allPositions = range(1, maxPosition + 1);
    const occupiedPositions = products.map(product => product.folder_position);
    const allUntakenPositions = difference(allPositions, occupiedPositions);
    const availablePositions = allUntakenPositions.filter(position => position >= currentPosition);

    return availablePositions;
  }

  function handleColorDropdownToggle() {
    setColorPickerDropdownOpen(prev => !prev);
  }

  function handleColorChange(color: string) {
    if (productCardsState.selectedProductId) {
      const updatedProducts = products.map(product => {
        if (product.id === productCardsState.selectedProductId) {
          return {
            ...product,
            meta: product.meta
              ? { ...product.meta, button_color: color }
              : {
                  product_id: null,
                  facility_id: null,
                  has_modifiers: null,
                  kitchen_location_id: null,
                  kitchen_location_id_2: null,
                  kitchen_location_id_3: null,
                  preferred_title: null,
                  button_color: color,
                  price_locked: false,
                },
          };
        } else {
          return { ...product };
        }
      });
      setProducts(updatedProducts);
    }
  }

  function addRow() {
    if (productCardsState.selectedProductId) {
      const selectedRow = getRowFromProductId(productCardsState.selectedProductId);
      const rowShiftThreshold = selectedRow * GRID_COL;
      const productsBeforeThreshold = products
        .filter(product => product.folder_position <= rowShiftThreshold)
        .map(product =>
          product.id === productCardsState.selectedProductId
            ? { ...product, folder_position: product.folder_position + GRID_COL }
            : { ...product },
        );
      const productsAfterThreshold = products
        .filter(product => product.folder_position > rowShiftThreshold)
        .map(product => ({ ...product, folder_position: product.folder_position + GRID_COL }));
      const allProducts = [...productsBeforeThreshold, ...productsAfterThreshold];

      setProducts(allProducts);
      setProductCardsState(prev => ({ ...prev, gridRows: prev.gridRows + 1 }));
    }
  }

  function deleteRows() {
    let rowsToDelete = 0;
    const updatedProducts: IProduct[] = [];
    for (let r = 1; r <= productCardsState.gridRows; r++) {
      if (!productCardsState.selectedRows.some(selectedRow => selectedRow === r)) {
        const startOfRowPosition = (r - 1) * GRID_COL + 1;
        const endOfRowPosition = r * GRID_COL;
        const updatedRow: IProduct[] = products
          .filter(product => inRange(product.folder_position, startOfRowPosition, endOfRowPosition + 1))
          .map(product => ({ ...product, folder_position: product.folder_position - rowsToDelete * GRID_COL }));

        updatedProducts.push(...updatedRow);
      } else {
        rowsToDelete++;
      }
    }

    setProducts(updatedProducts);
    setProductCardsState(prev => ({ ...prev, gridRows: prev.gridRows - rowsToDelete, selectedRows: [] }));
  }

  function handleEditProduct(product: IProduct) {
    window.open("/admin/product/" + String(product.id));
  }

  if (!productCardsState.gridConstructed) {
    return null;
  } else {
    return (
      <div className="product-cards-container" ref={productCardsRef}>
        <div className="product-cards-action-menu">
          <div>
            <ColorPickerDropdown
              isDropdownOpen={colorPickerDropdownOpen}
              setIsDropdownOpen={setColorPickerDropdownOpen}
              onChange={handleColorChange}
            >
              <Button onClick={handleColorDropdownToggle} disabled={productCardsState.selectedProductId === undefined}>
                {t("components.product_cards.product_cards.001")}
              </Button>
            </ColorPickerDropdown>
          </div>
          <Button onClick={addRow} disabled={productCardsState.selectedProductId === undefined}>
            {t("components.product_cards.product_cards.002")}
          </Button>
          <Button onClick={deleteRows} disabled={productCardsState.selectedRows.length === 0}>
            {t("components.product_cards.product_cards.003")}
          </Button>
        </div>
        <DndProvider backend={HTML5Backend}>
          <div className="product-cards">
            {range(1, GRID_COL * productCardsState.gridRows + 1).map(position => {
              const product = products.find(product => product.folder_position === position);
              if (product) {
                const isSelected = product.id === productCardsState.selectedProductId;

                return (
                  <React.Fragment key={product.id}>
                    <RightClickMenu
                      sections={[
                        [
                          {
                            icon: "pen-to-square",
                            title: "Edit Product",
                            action: () => handleEditProduct(product),
                          },
                        ],
                      ]}
                    >
                      <ProductCard
                        product={product}
                        isSelected={isSelected}
                        isPositionInASelectedRow={isPositionInASelectedRow}
                        selectProduct={selectProduct}
                        onRemoveProduct={onRemoveProduct}
                      />
                    </RightClickMenu>
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment key={String(Date.now()) + String(position)}>
                    <ProductCardSlot
                      position={position}
                      isPositionInASelectedRow={isPositionInASelectedRow}
                      selectEmptyCard={selectEmptyCard}
                      onChangeProductPosition={onChangeProductPosition}
                    />
                  </React.Fragment>
                );
              }
            })}
          </div>
        </DndProvider>
      </div>
    );
  }
}
