import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { IDivision } from "redux/reducers/models/facility";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/facility/division";

export type TGetDivision = {
  id?: string | number;
  course_id?: string | number;
};

// export type TPostDivision = {};

// export type TPutDivision = {};

// GET Divisions
export async function GetDivision(params: TGetDivision, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<IDivision[]>(baseUrl, params, useGlobalLoader, token);
}

// POST Divisions
export async function PostDivision(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

// PUT Divisions
export async function PutDivision(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}
