import { useEffect } from "react";

const useOutsideAlerter = (ref: React.MutableRefObject<any>, callback: any) => {
  useEffect(() => {
    let mounted = true;
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (mounted) {
          callback();
        }
      }
    }

    document.addEventListener("mousedown", (e) => handleClickOutside(e));

    return () => {
      mounted = false;
      document.removeEventListener("mousedown", (e) => handleClickOutside(e));
    };
  }, [ref]);
};

export default useOutsideAlerter;
