import React from "react";
import SideMenu from "components/sideMenu/SideMenu";

//Get settings routes array from 'settingsRoutes.ts'
import settingsRoutes from "./settingsRoutes";

//Get settings options array from 'settingsOptions.ts'
import options from "./settingsOptions";

const Settings: React.FC = (props: any) => {
  // return <SideMenu title={"Settings"} options={options} optionsRoutes={settingsRoutes} />;
  return <div></div>;
};

export default Settings;
