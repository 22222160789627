import { useTranslation, Trans } from "react-i18next";
export default function SettingOptionsTranslation() {
  const { t, i18n } = useTranslation();

  const eventOptions = [
    {
      label: t("secure.facility.events.event_options.001"),
      url: "/admin/events",
    },
    {
      label: t("secure.facility.events.event_options.002"),
      url: "/admin/events-tournament",
    },
    {
      label: "Hospitality",
      url: "/admin/events-hospitality",
    },
  ];

  return eventOptions;
}
