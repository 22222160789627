import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { displayCurrency } from "helpers/Helpers";
import React, { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import "./OrderSummary.scss";
import { Badge, IBadgeProps } from "components/badge/Badge";
import classNames from "classnames";
import { ButtonNew as Button } from "components/buttonNew";

export interface ILineItem {
  src: string;
  productTitle: string;
  variantTitle: string;
  quantity: number;
  price: number;
}

export interface ITaxLine {
  title: string;
  price: number;
  rate: number;
}

interface IHeaderAction {
  label: string;
  action: () => void;
}

export interface IOrderSummaryProps {
  subtotal: number;
  total: number;
  taxLines?: ITaxLine[];
  discount: number;
  lineItems?: ILineItem[];
  togglableVisibility?: boolean;
  displayLineItemsWithoutToggle?: boolean;
  headerBadge?: IBadgeProps;
  headerAction?: IHeaderAction;
  children?: ReactNode;
}

export default function OrderSummary(props: IOrderSummaryProps) {
  const {
    subtotal,
    total,
    taxLines,
    discount,
    lineItems,
    togglableVisibility,
    displayLineItemsWithoutToggle,
    headerBadge,
    headerAction,
    children,
  } = props;

  const [displayItems, setDisplayItems] = useState<boolean>(false);
  const [showOrderSummary, setShowOrderSummary] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  function renderOrderSummary() {
    return (
      <div className="order-summary">
        <div
          className={classNames("order-summary-header", {
            "order-summary-header-offset": !lineItems,
          })}
        >
          <div className="order-summary-heading-container">
            <h2 className="order-summary-heading">{t("components.order_summary.order_summary.001")}</h2>
            {headerBadge && <Badge {...headerBadge} />}
          </div>
          {headerAction && (
            <Button className="order-summary-header-action" type="link" onClick={headerAction.action}>
              {headerAction.label}
            </Button>
          )}
        </div>
        {lineItems ? (
          <>
            {!displayLineItemsWithoutToggle && (
              <button
                className="order-summary-no-toggle"
                onClick={() => setDisplayItems(previousDisplayItems => !previousDisplayItems)}
              >
                <span className="order-summary-no-toggle-primary">
                  <span className="order-summary-no-toggle-item-count">{`${lineItems.length} ${
                    lineItems.length === 1 ? "item" : "items"
                  }`}</span>
                  {displayItems ? (
                    <FontAwesomeIcon
                      className="order-summary-no-toggle-display-icon"
                      icon={["fal", "angle-up"]}
                    ></FontAwesomeIcon>
                  ) : (
                    <FontAwesomeIcon
                      className="order-summary-no-toggle-display-icon"
                      icon={["fal", "angle-down"]}
                    ></FontAwesomeIcon>
                  )}
                </span>
                <span className="order-summary-no-toggle-total">{displayCurrency("cad", subtotal)}</span>
              </button>
            )}
            <hr
              style={{
                margin: !displayLineItemsWithoutToggle && "0 0 20px 0",
                display: displayLineItemsWithoutToggle && lineItems.length === 0 && "none",
              }}
            />
            {displayLineItemsWithoutToggle || displayItems ? (
              <>
                <div className="order-summary-line-item-container">
                  {lineItems.map((lineItem, index) => {
                    return (
                      <>
                        <div className="order-summary-line-item-content" key={index}>
                          <div className="order-summary-line-item-content-primary">
                            <div className="order-summary-line-item-quantity">{lineItem.quantity}</div>
                            <div>
                              <div className="order-summary-line-item-product-title">{lineItem.productTitle}</div>
                              {lineItem.variantTitle !== lineItem.productTitle && (
                                <div className="order-summary-line-item-variant-title">{lineItem.variantTitle}</div>
                              )}
                            </div>
                          </div>
                          <div className="order-summary-line-item-price">{displayCurrency("cad", lineItem.price)}</div>
                        </div>
                        {index < lineItems.length - 1 && <hr className="order-summary-line-item-divider" />}
                      </>
                    );
                  })}
                </div>
                <hr></hr>
              </>
            ) : null}
          </>
        ) : null}
        <div className="order-summary-shopping-bag-values">
          <div>
            <span>{t("components.order_summary.order_summary.003")}</span>
            <span className="order-summary-shopping-bag-value">
              {subtotal !== undefined && subtotal !== null ? displayCurrency("cad", subtotal) : "Invalid"}
            </span>
          </div>
          {taxLines != null && taxLines.length > 0 ? (
            taxLines.map((taxLine, index) => {
              return (
                <div key={index}>
                  <span>
                    {taxLine.title} <span className="text-subdued">{taxLine.rate * 100}%</span>
                  </span>
                  <span className="order-summary-shopping-bag-value">{displayCurrency("cad", taxLine.price)}</span>
                </div>
              );
            })
          ) : (
            <div>
              <span>{t("components.order_summary.order_summary.004")}</span>
              <span className="order-summary-shopping-bag-value">{displayCurrency("cad", 0)}</span>
            </div>
          )}

          {discount !== 0 ? (
            <div>
              <span>{t("components.order_summary.order_summary.005")}</span>
              <span className="order-summary-shopping-bag-value">
                {discount != null ? displayCurrency("cad", discount) : "Invalid"}
              </span>
            </div>
          ) : null}

          <div>
            <span className="order-summary-shopping-bag-total">{t("components.order_summary.order_summary.006")}</span>
            <span className="order-summary-shopping-bag-total order-summary-shopping-bag-value">
              {total != null ? displayCurrency("cad", total) : "Invalid"}
            </span>
          </div>

          <hr className="order-summary-shopping-bag-total-dividing-line" />

          {children}
        </div>
      </div>
    );
  }

  return (
    <>
      {togglableVisibility ? (
        <>
          <h2 className="order-summary-visibility-display-toggle" onClick={() => setShowOrderSummary(prev => !prev)}>
            {t("components.order_summary.order_summary.007")}
            <FontAwesomeIcon
              className="order-summary-visibility-display-toggle-icon"
              icon={["fal", showOrderSummary ? "angle-up" : "angle-down"]}
            />
          </h2>
          {showOrderSummary && <div className="order-summary-visibility-display-container">{renderOrderSummary()}</div>}
        </>
      ) : (
        <>{renderOrderSummary()}</>
      )}
    </>
  );
}
