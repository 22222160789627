import React, { useEffect, useState } from "react";
import { Link, Route, Switch, Redirect, useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDOM from "react-dom";
import useModal from "hooks/modals/useModal";
import { useAppSelector, useAppDispatch } from "hooks/redux";

import Dashboard from "./Dashboard";
import Register from "containers/facility/register";
import TeeSheet from "containers/facility/teesheet";

import Layout from "components/layout/index";
import Menu from "components/menu/index";
import Checkbox from "components/checkbox/index";
import { Select } from "components/select/index";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Icon from "components/icon/Icon";
import Portal from "elements/Portal";
import Sheet from "components/sheet/Sheet";
import { Badge } from "components/badge/Badge";

import Moment from "react-moment";
import moment from "moment";

import "public/scss/admin.scss";
import "components/select/style.scss";

import classNames from "classnames";

import { IUIAction, IUIState } from "redux/reducers/ui";
import { IMasterFacilityState } from "redux/reducers/masterAdmin/facility";

import { IAuthState } from "redux/reducers/auth";

import { IUIActions } from "redux/actions/ui";
import { IAuthActions, userLogout } from "redux/actions/auth";
import { IFacilityActions, loadFacilities, selectFacility } from "redux/actions/masterAdmin/facility";
import { ITerminalActions, setError, setReaderArray } from "redux/actions/facilityAdmin/terminal";

import { IClientState } from "redux/reducers/masterAdmin/client";
import { IClientActions, loadClients, selectClient } from "redux/actions/masterAdmin/client";

import { UserActive, UserLogout } from "../../../api/rpc";
import { StatusCode } from "../../../api/protocols";
import { IconName } from "@fortawesome/fontawesome-svg-core";

import TerminalReducer, { ITerminalState } from "redux/reducers/facilityAdmin/terminal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import LeftSubMenu from "components/leftMenu/LeftSubMenu";
import Facilities from "containers/master/facilities";
import Facility from "./facility/Facility";
import FacilityNew from "./facility/FacilityNew";
import Clients from "containers/master/clients";
import Client from "./client/Client";
import ClientNew from "./client/ClientNew";
import ClientSettings from "./client/clientSettings/ClientSettings";
import FacilitySettings from "./facility/facilitySettings/FacilitySettings";

import clientOptions from "./client/ClientOptions";
import facilityOptions from "./facility/FacilityOptions";
import { IClient } from "redux/reducers/models/client";
import { IFacility } from "redux/reducers/models/facility";

import BookingCategories from "./client/clientSettings/bookingCategories/BookingCategories";
import CustomerTypes from "./client/clientSettings/customerTypes/CustomerTypes";
import CustomerTypeNew from "./client/clientSettings/customerTypes/CustomerTypeNew";
import CustomerType from "./client/clientSettings/customerTypes/CustomerType";
import ProductTypes from "./client/clientSettings/productTypes/ProductTypes";
import Vendors from "./client/clientSettings/vendors/Vendors";
import StaffAccounts from "./client/clientSettings/staffAccounts/StaffAccounts";
import StaffAccount from "./client/clientSettings/staffAccounts/StaffAccount";
import StaffAccountNew from "./client/clientSettings/staffAccounts/StaffAccountNew";
import FacilityPermissions from "./client/clientSettings/facilityPermissions/FacilityPermissions";
import Folders from "./facility/facilitySettings/folders/Folders";
import FoldersEdit from "./facility/facilitySettings/folders/FoldersEdit";
import FoldersNew from "./facility/facilitySettings/folders/FoldersNew";
import Discounts from "./facility/facilitySettings/discount/Discounts";
import DiscountEdit from "./facility/facilitySettings/discount/DiscountEdit";
import DiscountNew from "./facility/facilitySettings/discount/DiscountNew";
import ModifierGroup from "./facility/facilitySettings/modifierGroup/ModifierGroup";
import ModifierGroupEdit from "./facility/facilitySettings/modifierGroup/ModifierGroupEdit";
import ModifierGroupNew from "./facility/facilitySettings/modifierGroup/ModifierGroupNew";
import Departments from "./facility/facilitySettings/departments/Departments";
import DepartmentsEdit from "./facility/facilitySettings/departments/DepartmentsEdit";
import DepartmentsNew from "./facility/facilitySettings/departments/DepartmentsNew";
import FacilityForm from "./facility/facilitySettings/forms/FacilityForms";
import FacilityFormEdit from "./facility/facilitySettings/forms/FacilityFormEdit";
import Courses from "./facility/facilitySettings/courses/Courses";
import CourseNew from "./facility/facilitySettings/courses/CourseNew";
import CourseEdit from "./facility/facilitySettings/courses/CourseEdit";
import Divisions from "./facility/facilitySettings/divisions/Divisions";
import DivisionNew from "./facility/facilitySettings/divisions/DivisionNew";
import Division from "./facility/facilitySettings/divisions/Division";

import Customers from "./customer/main/Customers";
import CustomerProvider from "./customer/context/CustomerProvider";

import orderOptions from "./order/OrderOptions";
import Orders from "./order/Orders";
import Order from "./order/Order";

import NewTeeSheet from "./teeSheet/NewTeeSheet";

import options from "./settings/settingsOptions";
import { store } from "index";

import { GetFacility } from "api/rpc/2024-04/masterAdmin/facility/facility";

interface IProps {
  authActions: IAuthActions;
  authStore: IAuthState;
  clientActions: IClientActions;
  masterClientStore: IClientState;
  facilityActions: IFacilityActions;
  masterFacilityStore: IMasterFacilityState;
  terminalActions: ITerminalActions;
  terminalStore: ITerminalState;
  uiActions: IUIActions;
  uiStore: IUIState;
}

export interface IRegister {
  id: number;
  facility_id: number;
  title: string;
  default_card_reader_id: number;
  register_group_id: number;
}

interface IPath {
  name: string;
  title: string;
  icon: IconName;
  options?: Array<IOptions>;
}

export interface ISubOptions {
  label: string;
  url: string;
  icon?: IconName;
  subOptions?: ISubOptions[];
}

export interface IOptions {
  label: string;
  url?: string;
  icon?: IconName;
  subOptions?: ISubOptions[];
}

interface IFilterState {
  clientSearchValue: string;
  clientSearching: boolean;
  selectedClient: IClient;
  facilitySearchValue: string;
  facilitySearching: boolean;
  selectedFacility: IFacility;
}

export default function MasterAdmin() {
  const history = useHistory();
  const { Option } = Select;

  const { masterFacilityStore, authStore, masterClientStore } = useAppSelector(store => store);

  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(loadClients(null, false));
    // void facilityActions.loadAdminFacility(null, false);
    // void setupStripe();
  }, []);

  const location = useLocation();

  const { Sider, Header, Content, Footer } = Layout;

  const [currentRoute, setCurrentRoute] = useState(undefined);

  const [currentPath, setCurrentPath] = useState<IPath>(undefined);

  const [facilities, setFacilities] = useState<IFacility[]>(undefined);
  const [facilitySearch, setFacilitySearch] = useState<string>("");

  const adminContentCls = classNames("ui-main_content", {
    "no-scroll": location.pathname === "/admin/teesheet",
  });

  const [filters, setFilters] = useState<IFilterState>({
    clientSearchValue: "",
    clientSearching: false,
    selectedClient: null,
    facilitySearchValue: "",
    facilitySearching: false,
    selectedFacility: null,
  });

  const {
    state: changeClientModal,
    closeModal: closeChangeClientModal,
    updateModal: updateChangeClientModal,
  } = useModal();

  async function logoutUser() {
    const logoutRes = await UserLogout(true);
    if (logoutRes.status !== StatusCode.OK) {
      dispatch(userLogout());
    }
    if (logoutRes.status === StatusCode.OK) {
      history.push("/");
    }
  }

  const paths: { name: string; title: string; icon: IconName; hidden?: boolean; options?: Array<IOptions> }[] = [
    { name: "client", title: "Clients", icon: "city", options: null },
    { name: "facility", title: "Facilites", icon: "building", options: null },
    // { name: "teesheet", title: "Tee Sheet", icon: "stream", options: null },
    { name: "order", title: "Orders", icon: "box-open", options: orderOptions },
    // { name: "customers", title: "Customers", icon: "user", options: null },
    { name: "settings", title: "Settings", icon: "gear", options: options },
  ];

  useEffect(() => {
    const routeName = history.location.pathname.split("/");
    if (currentRoute === undefined) {
      setCurrentRoute(routeName[routeName.length - 1]);
    }
    let foundPath = paths?.find(path => path.name === routeName?.find(name => name === path.name));
    if (!foundPath) {
      foundPath = paths?.find(path =>
        path?.options?.some(option =>
          option?.url
            ?.split("/")
            ?.some(
              optionPath => optionPath && optionPath !== "admin" && routeName?.some(name => name === optionPath),
            ),
        ),
      );
    }
    setCurrentPath(foundPath);
  }, [currentRoute, location]);

  useEffect(() => {
    if (!masterClientStore.client) {
      void searchFacilities();
    }
  }, [filters.facilitySearchValue]);

  const handleMenuItemClick = (path: IPath) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setCurrentRoute(path.name);
      setCurrentPath(path);
    });
  };

  function handleClientChange(value: any, client: IClient) {
    void dispatch(selectClient(client));
    void dispatch(selectFacility(null));
    void dispatch(loadFacilities({ client_id: client?.id }, false));
    setFilters(prevState => ({ ...prevState, clientSearchValue: "", selectedClient: client }));
  }

  function handleClearClient() {
    void dispatch(selectClient(null));
    void dispatch(selectFacility(null));
    setFilters(prevState => ({
      ...prevState,
      clientSearchValue: "",
      selectedClient: null,
      facilitySearchValue: "",
      selectedFacility: null,
    }));
  }

  function handleFacilityChange(value: any, facility: IFacility) {
    void dispatch(selectFacility(facility));
    void dispatch(
      selectClient(masterClientStore?.clients?.find((client: IClient) => client?.id === facility?.client_id)),
    );
    setFilters(prevState => ({ ...prevState, facilitySearchValue: "", selectedFacility: facility }));
  }

  function handleClearFacility() {
    void dispatch(selectFacility(null));
    void dispatch(selectClient(null));
    setFilters(prevState => ({ ...prevState, facilitySearchValue: "", selectedFacility: null }));
  }

  function displayClients(): IClient[] {
    if (filters?.clientSearchValue !== "") {
      return masterClientStore?.clients?.filter((client: IClient) =>
        client.full_name.toLowerCase().includes(filters.clientSearchValue.toLowerCase()),
      ) as IClient[];
    } else {
      return masterClientStore?.clients as IClient[];
    }
  }

  function displayFacilities(): IFacility[] {
    if (filters?.facilitySearchValue !== "") {
      return masterFacilityStore?.facilities?.filter(facility =>
        facility.full_name.toLowerCase().includes(filters.facilitySearchValue.toLowerCase()),
      );
    } else {
      return masterFacilityStore?.facilities;
    }
  }

  async function searchFacilities() {
    const facilityRes = await GetFacility({ search: filters.facilitySearchValue }, false);

    if (facilityRes.status !== StatusCode.OK) {
      return;
    }

    setFacilities(facilityRes.data);
  }

  return (
    <>
      <Layout>
        <div className="ui-layout_left-nav-desktop">
          <div className="ui-left-nav_container">
            <div className="ui-left-nav_content">
              <div className="ui-left-nav-logo">
                <img src="../../../public/images/tee_on_menu_logo.png" alt="Tee-On Logo" />
              </div>
              <div className="ui-left-nav-menu">
                <Menu>
                  {paths.map((path, i) => {
                    if (!path.hidden) {
                      return (
                        <Link key={i} to={`/admin/${path.name}`}>
                          <OverlayTrigger
                            placement="auto"
                            overlay={<Tooltip id="leftMenuToolTip">{path.title}</Tooltip>}
                          >
                            <Menu.Item
                              selected={currentRoute === path.name ? true : false}
                              onClick={() => handleMenuItemClick(path)}
                              icon={<FontAwesomeIcon icon={["far", path.icon]} />}
                            ></Menu.Item>
                          </OverlayTrigger>
                        </Link>
                      );
                    }
                  })}
                </Menu>
                <Menu className="mb-4">
                  <OverlayTrigger placement="auto" overlay={<Tooltip id="leftMenuToolTip">Logout</Tooltip>}>
                    <Menu.Item
                      onClick={logoutUser}
                      className="self-center cursor-pointer"
                      icon={<FontAwesomeIcon style={{ transform: "scaleX(-1)" }} icon={["far", "sign-out"]} />}
                    />
                  </OverlayTrigger>
                </Menu>
              </div>
            </div>
          </div>
        </div>

        <div
          className={classNames("ui-layout_left-submenu-desktop", {
            "ui-layout_left-submenu-desktop-hide": currentPath?.options ? false : true,
          })}
        >
          <LeftSubMenu title={currentPath?.title} options={currentPath?.options} />
        </div>

        <div className="ui-main">
          <div className="ui-header">
            <div className="ui-header_container">
              <div className="ui-header-content_desktop">
                <div></div>
                <div className="admin-details" onClick={() => updateChangeClientModal({ isOpen: true })}>
                  <div className="admin-current-active-user">
                    {masterFacilityStore.facility ? (
                      <Badge type="success">{`${String(masterFacilityStore?.facility?.long_name)}`}</Badge>
                    ) : (
                      <Badge type="error">{`No Facility Selected`}</Badge>
                    )}
                  </div>
                  <div className="admin-current-active-user">
                    <div>{authStore?.user?.full_name}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ui-main_container">
            <div className={adminContentCls}>
              <Switch>
                <Route exact path="/admin/client" component={Clients} />
                <Route exact path="/admin/client/new" component={ClientNew} />
                <Route exact path="/admin/client/:clientId" component={Client} />
                <Route exact path="/admin/client/:clientId/facility" component={Facilities} />
                <Route exact path="/admin/client/:clientId/facility/new" component={FacilityNew} />
                <Route exact path="/admin/client/:clientId/facility/:facilityId" component={Facility} />

                <Route exact path="/admin/settings" component={ClientSettings} />
                <Route exact path="/admin/client/settings" component={ClientSettings} />

                <Route exact path="/admin/settings/booking-category" component={BookingCategories} />
                <Route exact path="/admin/settings/customer-type" component={CustomerTypes} />
                <Route exact path="/admin/settings/customer-type/new" component={CustomerTypeNew} />
                <Route exact path="/admin/settings/customer-type/:typeId" component={CustomerType} />
                <Route exact path="/admin/settings/product-type" component={ProductTypes} />
                <Route exact path="/admin/settings/vendor" component={Vendors} />
                <Route exact path="/admin/settings/staff" component={StaffAccounts} />
                <Route exact path="/admin/settings/staff/new" component={StaffAccountNew} />
                <Route exact path="/admin/settings/staff/:adminId" component={StaffAccount} />
                <Route exact path="/admin/settings/facility-permissions" component={FacilityPermissions} />

                <Route exact path="/admin/facility" component={Facilities} />
                <Route exact path="/admin/facility/new" component={FacilityNew} />
                <Route exact path="/admin/facility/:facilityId" component={Facility} />

                <Route exact path="/admin/settings" component={ClientSettings} />
                <Route exact path="/admin/settings/folders" component={Folders} />
                <Route exact path="/admin/settings/folders/new" component={FoldersNew} />
                <Route exact path="/admin/settings/folders/:id" component={FoldersEdit} />
                <Route exact path="/admin/settings/discount" component={Discounts} />
                <Route exact path="/admin/settings/discount/new" component={DiscountNew} />
                <Route exact path="/admin/settings/discount/:id" component={DiscountEdit} />
                <Route exact path="/admin/settings/modifier-group" component={ModifierGroup} />
                <Route exact path="/admin/settings/modifier-group/new" component={ModifierGroupNew} />
                <Route exact path="/admin/settings/modifier-group/:id" component={ModifierGroupEdit} />
                <Route exact path="/admin/settings/departments" component={Departments} />
                <Route exact path="/admin/settings/departments/new" component={DepartmentsNew} />
                <Route exact path="/admin/settings/departments/:id" component={DepartmentsEdit} />
                <Route exact path="/admin/settings/forms" component={FacilityForm} />
                <Route exact path="/admin/settings/forms/:id" component={FacilityFormEdit} />
                <Route exact path="/admin/settings/course" component={Courses} />
                <Route exact path="/admin/settings/course/new" component={CourseNew} />
                <Route exact path="/admin/settings/course/:id" component={CourseEdit} />
                <Route exact path="/admin/settings/division" component={Divisions} />
                <Route exact path="/admin/settings/division/new" component={DivisionNew} />
                <Route exact path="/admin/settings/division/:id" component={Division} />

                <Route exact path="/admin/order" component={Orders} />
                <Route exact path="/admin/order/:orderId" component={Order} />

                <Route exact path="/admin/customers" component={Customers} />
                <Route exact path="/admin/customers/:customerId/profile" component={CustomerProvider} />
                <Route exact path="/admin/customers/:customerId/reservations" component={CustomerProvider} />
                <Route exact path="/admin/customers/:customerId/membership" component={CustomerProvider} />
                <Route exact path="/admin/customers/:customerId/tickets" component={CustomerProvider} />
                <Route exact path="/admin/customers/:customerId/house-accounts" component={CustomerProvider} />
                <Route exact path="/admin/customers/:customerId/payment-methods" component={CustomerProvider} />
                <Route exact path="/admin/customers/:customerId/credit" component={CustomerProvider} />
                <Route exact path="/admin/customers/:customerId/notes" component={CustomerProvider} />
                <Route exact path="/admin/customers/:customerId/item-sales" component={CustomerProvider} />

                <Route exact path="/admin/teesheet" component={NewTeeSheet} />
                <Redirect to="/admin/dashboard" />
              </Switch>
            </div>
          </div>
        </div>
      </Layout>

      <Portal isMounted={changeClientModal.isOpen}>
        <Sheet
          open={changeClientModal.isOpen}
          title="Change Facility"
          onCancel={closeChangeClientModal}
          cancelText="Close"
          closable
          backDropCancel
          size="small"
          overflow
        >
          <div className="mt-2">
            {masterFacilityStore.facility ? (
              <div className="rc-select-container">
                <div className="flex flex-col">
                  <div className="rc-select-dropdown-label">
                    <label>Facility</label>
                  </div>

                  <div className="selected-container">
                    <div className="event-name">
                      <div>{masterFacilityStore.facility.long_name}</div>

                      <div>
                        <button
                          className="rc-select-search text-black font-medium w-full"
                          onClick={handleClearFacility}
                        >
                          <Icon style="far" icon="times" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Select
                label="Facility"
                onChange={(value: any, facility: IFacility) => handleFacilityChange(value, facility)}
                className="edit-segments-blocked-type-dropdown"
                placeholder="Search facilities..."
                defaultValue={masterFacilityStore?.facility?.id}
                showSearch
                searchValue={filters.facilitySearchValue}
                showDropDownOnFocus={true}
                searching={filters.facilitySearching}
                onSearch={(query: string) => setFilters(prevState => ({ ...prevState, facilitySearchValue: query }))}
              >
                <>
                  {facilities?.map((facility: IFacility, index: number) => {
                    return (
                      <Option key={index} value={facility?.id} extraValues={facility} name={facility?.full_name}>
                        <div className="flex items-center">
                          <span>{facility?.long_name}</span>
                          <span className="shotgun-tournament-date">
                            <span>{`${facility?.city}, ${facility?.province_name}, ${facility?.country_name}`}</span>
                          </span>
                        </div>
                      </Option>
                    );
                  })}
                </>
              </Select>
            )}
          </div>
        </Sheet>
      </Portal>
    </>
  );
}
