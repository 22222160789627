import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetKitchenLocations } from "api/rpc/2024-04/facilityAdmin/facility/kitchen/location";
import { PostKitchenLocation } from "api/rpc/2022-09/facilityAdmin/facility/kitchen";

import { showError } from "redux/actions/ui";
import { TKitchenLocation } from "redux/reducers/models/kitchen";
import { useAppDispatch } from "hooks/redux";
import useModal from "hooks/modals/useModal";

import Card from "components/card/Card";
import Page from "components/page/Page";
import Sheet from "components/sheet/Sheet";
import FormLayout from "components/form/FormLayout";
import Form from "components/form/Form";
import Input from "components/form/input/Input";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";

import "../facility/facility.scss";

export default function KitchenLocations() {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();

  const {
    state: newLocationModal,
    updateModal: updateNewLocationModal,
    closeModal: closeNewLocationModal,
  } = useModal({ title: "" });

  const [kitchenLocations, setKitchenLocations] = useState<TKitchenLocation[]>(undefined);
  const [kitchenLocationReload, setKitchenLocationReload] = useState(true); // load on first render

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (kitchenLocationReload) {
      void loadKitchenLocations(source.token);
    }
    return () => source.cancel();
  }, [kitchenLocationReload]);

  async function loadKitchenLocations(token?: CancelToken) {
    if (kitchenLocations !== undefined) {
      setKitchenLocations(undefined);
    }

    const res = await GetKitchenLocations(null, token ? false : true, token);
    if (token && token.reason) {
      return;
    }

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading kitchen locations.")); // TODO: Translation
    }

    setKitchenLocations(res.status !== StatusCode.OK ? [] : (res.data as TKitchenLocation[]));
    setKitchenLocationReload(false);
  }

  async function handleAddLocation() {
    const res = await PostKitchenLocation(
      {
        title: newLocationModal.title,
      },
      true,
    );

    if (res.status !== StatusCode.OK) {
      return;
    } else {
      closeNewLocationModal();
      setKitchenLocationReload(true);
    }
  }

  const primaryAction = {
    content: t("secure.facility.settings.kitchen.kitchen_setup.003"),
    action: () => updateNewLocationModal({ isOpen: true }),
  };

  return (
    <Page title={t("secure.facility.settings.kitchen.kitchen_setup.001")} primaryAction={primaryAction} narrow>
      {/* title={t("secure.facility.settings.kitchen.kitchen_setup.002")} */}
      <DataTable
        columns={[{ label: t("secure.facility.settings.kitchen.kitchen_setup.002") }]}
        loading={kitchenLocations === undefined}
      >
        {kitchenLocations?.map(location => {
          return (
            <tr
              key={location.id}
              className="clickable"
              onClick={() => history.push("/admin/settings/kitchen-location/" + String(location.id))}
            >
              <td>{location.title}</td>
            </tr>
          );
        })}
      </DataTable>

      <Sheet
        open={newLocationModal.isOpen}
        closable
        title={t("secure.facility.settings.kitchen.kitchen_setup.005")}
        onCancel={closeNewLocationModal}
        onOk={handleAddLocation}
        cancelText={t("secure.facility.settings.kitchen.kitchen_setup.006")}
        okText={t("secure.facility.settings.kitchen.kitchen_setup.007")}
        size="small"
      >
        <Form>
          <FormLayout>
            <FormLayout.Group>
              <Input
                value={newLocationModal.title}
                label={t("secure.facility.settings.kitchen.kitchen_setup.008")}
                id="title"
                onChange={e => updateNewLocationModal({ title: e.target.value })}
                placeholder={t("secure.facility.settings.kitchen.kitchen_setup.009")}
              />
            </FormLayout.Group>
          </FormLayout>
        </Form>
      </Sheet>
    </Page>
  );
}
