import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { ICountry, IProvince } from "redux/reducers/models/customer";

const apiVersion = "2024-04";
const adminType = "common";
const baseUrl = "/" + apiVersion + "/" + adminType + "/country";

type TGetCountry = {
  id?: string | number;
  search?: string;
  provinces?: boolean;
};

type TGetProvince = {
  id?: string | number;
  country_id?: string | number;
  search?: string;
};

// GET Country
export async function GetCountry(params: TGetCountry, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<ICountry[]>(baseUrl, params, useGlobalLoader, token);
}

// GET Provinces
export async function GetProvince(params: TGetProvince, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<IProvince[]>(baseUrl + "/province", params, useGlobalLoader);
}
