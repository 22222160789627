import { APIGet, APIPost, APIPut, APIDelete, IAPIResponse } from "api/protocols";
import { IClient } from "api/rpc/2022-09/customer/client";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "customer";
const baseUrl = "/" + apiVersion + "/" + adminType + "/customer";

interface IAddFriend {
  /**Add a single user */
  friend_user_id?: number;
  /**Add many users */
  friend_user_ids?: Array<number>;
}

interface IPutCustomer {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
}

interface IPutCustomerPreferences {
  client_id: number;
  marketing_email: boolean;
  booking_confirmation_email: boolean;
  booking_reminder_email: boolean;
  booking_update_email: boolean;
  booking_cancellation_email: boolean;
}

interface ISearchCustomer {
  client_id?: number;
  search?: string;
  member_search?: string;
}

export interface IPreferences {
  id: number;
  customer_id: number;
  client_id: number;
  client?: IClient;
  marketing_email: boolean;
  booking_confirmation_email: boolean;
  booking_reminder_email: boolean;
  booking_update_email: boolean;
  booking_cancellation_email: boolean;
}

export interface ICustomerFriend {
  email: string;
  first_name: string;
  full_name: string;
  id: number;
  initials: string;
  last_name: string;
  phone: string;
  customer_type: Array<{ title: string }>;
}

export async function PutCustomer(params: IPutCustomer, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

export async function UploadCustomerProfileImage(params: FormData, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/profile-image", params, useGlobalLoader);
}

export async function DeleteCustomerProfileImage(useGlobalLoader: boolean) {
  return await APIDelete(baseUrl + "/profile-image", null, useGlobalLoader);
}

export async function GetCustomerPreferences(
  params: { client_id: number },
  useGlobalLoader: boolean,
  cancelToken?: CancelToken,
): Promise<IAPIResponse<Array<IPreferences>>> {
  return APIGet(baseUrl + "/preference", params, useGlobalLoader, cancelToken);
}

export async function PostCustomerPreferences(params: { client_id: number }, useGlobalLoader: boolean) {
  return APIPost(baseUrl + "/preference", params, useGlobalLoader);
}

export async function PutCustomerPreferences(params: IPutCustomerPreferences, useGlobalLoader: boolean) {
  return APIPut(baseUrl + "/preference", params, useGlobalLoader);
}

//PUT Add Friend
export async function PostFriends(params: IAddFriend, useGlobalLoader?: boolean) {
  return await APIPost(baseUrl + "/friend", params, useGlobalLoader);
}

export async function GetCustomerFriends(
  params: { search?: string },
  useGlobalLoader: boolean,
  cancelToken?: CancelToken,
): Promise<IAPIResponse<Array<ICustomerFriend>>> {
  return await APIGet(baseUrl + "/friend", params, useGlobalLoader, cancelToken);
}

export async function DeleteFriend(params: { friend_user_id: number }, useGlobalLoader?: boolean) {
  return await APIDelete(baseUrl + "/friend", params, useGlobalLoader);
}

export async function SearchCustomer(params: ISearchCustomer, useGlobalLoader: boolean, cancelToken?: CancelToken) {
  return await APIGet(baseUrl + "/search", params, useGlobalLoader, cancelToken);
}

export async function GetCustomerPaymentMethods(
  params: { client_id: number; id?: number; type?: Array<"card" | "gift_card" | "rain_check"> },
  useGlobalLoader: boolean,
) {
  return await APIGet(baseUrl + "/payment-method", params, useGlobalLoader);
}
