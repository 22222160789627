import React, { useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { StatusCode } from "api/protocols";
import { GetTeeTime as GetFacilityTeeTime } from "api/rpc/2022-09/facilityAdmin/teesheet/teeSheet";
import { GetTeeTime as GetClientTeeTime } from "api/rpc/2024-04/clientAdmin/teesheet/teeSheet";

import { ITeeTime } from "redux/reducers/models/teetime";

import { useAppSelector } from "hooks/redux";
import { convertTime, formatDate } from "helpers/Helpers";

import DatePickerInput from "components/datePickerInput/DatePickerInput";
import Popover from "components/popover/Popover";
import { Select } from "components/select/index";
import { useTranslation } from "react-i18next";
import Spin from "components/spin/spin";

type MoveBookingTab = {
  isActive: boolean;
  bookingStateQuantity: number;
  bookingStateHoles: number;
  course_id: number;
  facility_id?: number;
  confirmMoveBooking: (selectedTeeTimeId: number) => void;
  userLevel: "facility" | "client";
};

export default function MoveBookingTab(props: MoveBookingTab) {
  const { Option } = Select;
  const { t, i18n } = useTranslation();
  const teeSheetStore = useAppSelector(store =>
    props.userLevel === "facility" ? store.teeSheetStore : store.clientTeeSheetStore,
  );
  const [date, setDate] = useState<Date | null>(null);
  const [selectedTeeTimeId, setSelectedTeeTimeId] = useState(undefined);
  const [loadingTeeTimes, setLoadingTeeTimes] = useState(false);
  const [allAvailableTeeTimes, setAllAvailableTeeTimes] = useState([]);
  const availableTimes = useMemo(() => {
    const returnData: Partial<ITeeTime>[] = [];
    if (props.bookingStateHoles === 18) {
      allAvailableTeeTimes.map((teetime: ITeeTime, index) => {
        if (
          teetime.blocked_type === "open" &&
          teetime.divisions_available.includes(2) &&
          teetime.turn_tee_time !== null
        ) {
          returnData.push({
            id: teetime.id,
            start_time: convertTime(teetime.start_time),
            course_id: teetime.course_id,
            tournament_id: teetime.tournament_id,
            league_id: teetime.league_id,
          });
        }
      });
    } else {
      allAvailableTeeTimes.map((teetime, index) => {
        if (teetime.blocked_type === "open") {
          returnData.push({
            id: teetime.id,
            start_time: convertTime(teetime.start_time),
            course_id: teetime.course_id,
            tournament_id: teetime.tournament_id,
            league_id: teetime.league_id,
          });
        }
      });
    }
    return returnData;
  }, [allAvailableTeeTimes, props.bookingStateHoles]);

  useEffect(() => {
    if (date !== null) {
      void updateAvailableTeeTimes();
      setLoadingTeeTimes(false);
    }
  }, [date]);

  async function updateAvailableTeeTimes() {
    const formattedDate = formatDate(date);
    const apiParams = {
      date: formattedDate,
      quantity_remaining: props.bookingStateQuantity,
      turn_tee_time: true,
      facility_id: props.facility_id,
    };

    // API call dependant on userLevel
    switch (props.userLevel) {
      case "facility": {
        const teeTimeRes = await GetFacilityTeeTime(apiParams, false);
        if (teeTimeRes.status === StatusCode.OK) {
          console.log("moveBooking facility API ...", teeTimeRes); //debug
          setAllAvailableTeeTimes(teeTimeRes.data);
          setLoadingTeeTimes(true);
        }
        break;
      }
      case "client": {
        const teeTimeRes = await GetClientTeeTime(apiParams, false);
        if (teeTimeRes.status === StatusCode.OK) {
          console.log("moveBooking client API ...", teeTimeRes); //debug
          setAllAvailableTeeTimes(teeTimeRes.data);
          setLoadingTeeTimes(true);
        }
      }
    }
  }

  function handleConfirmMoveBooking() {
    props.confirmMoveBooking(selectedTeeTimeId);
  }

  return (
    <div className="bm-move-booking-popover-container">
      <Popover active={props.isActive}>
        <div className="bm-move-booking-container">
          <p className="bm-move-booking-title">{t("elements.tee_sheet.move_booking_tab.001")}</p>
          <hr />
          <div>
            <DatePickerInput
              months={1}
              position="left"
              startingDate={date}
              disablePastDays
              setStartingDate={(date: Date) => setDate(date)}
            />
          </div>

          {loadingTeeTimes ? (
            <div style={{ textAlign: "center", marginTop: "20px", display: "flex", alignItems: "center" }}>
              <FontAwesomeIcon icon={["far", "clock"]} />
              <Select
                className="bm-move-booking-select"
                defaultValue={"0"}
                onChange={(value: number) => setSelectedTeeTimeId(value)}
              >
                <Option value="0">{t("elements.tee_sheet.move_booking_tab.002")}</Option>
                {availableTimes
                  .filter(availableTeeTime => {
                    if (availableTeeTime.course_id === teeSheetStore.selectedTeeTime?.course_id) {
                      return true;
                    } else {
                      return false;
                    }
                  })
                  .map(memoizedTeetime => {
                    return (
                      <Option key={memoizedTeetime.id} value={memoizedTeetime.id}>
                        <span style={{ display: "flex", alignItems: "center", gap: "6px" }}>
                          {memoizedTeetime.start_time}
                          {memoizedTeetime.tournament_id && <FontAwesomeIcon icon={["far", "trophy"]} />}
                          {memoizedTeetime.league_id && <FontAwesomeIcon icon={["far", "users"]} />}
                        </span>
                      </Option>
                    );
                  })}
              </Select>
            </div>
          ) : (
            <div style={{ marginTop: "20px", height: "40px" }}>
              <Spin className="self-center" />
            </div>
          )}

          <button onClick={() => handleConfirmMoveBooking()} className="bm-move-booking-button">
            {t("elements.tee_sheet.move_booking_tab.003")}
          </button>
        </div>
      </Popover>
    </div>
  );
}
