import { APIGet, APIPost, APIPut, APIDelete, IAPIResponse } from "api/protocols";
import { CancelToken } from "axios";
import { ITournament } from "redux/reducers/models/tournament";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseTournamentUrl = "/" + apiVersion + "/" + adminType + "/tournament";

export interface IGetTournament {
  id?: string | number;
  search?: string; // Search by tournament name
  year?: number;
  limit?: number;
  offset?: number;
}

export interface IPostTournament {
  name: string;
  start_time: string;
  date: Date;
  tournament_type: string;
}

export interface IPutTournament {
  id: string | number;
  name?: string;
  start_time?: string;
  date?: string | Date;
  registration_deadline?: Date;
  description?: string;
  capacity?: number;
  tee_sheet_note?: string;
  logo_source?: string;
}

// GET Tournament
// Retrieve a list of tournaments
export async function GetTournament(
  params: IGetTournament,
  useGlobalLoader: boolean,
  token?: CancelToken,
): Promise<IAPIResponse<Array<ITournament>>> {
  return await APIGet(baseTournamentUrl, params, useGlobalLoader, token);
}

// POST Tournament
// Create a new tournament
export async function PostTournament(params: IPostTournament, useGlobalLoader: boolean) {
  return await APIPost(baseTournamentUrl, params, useGlobalLoader);
}

// PUT Tournament
// Update an existing tournament
export async function PutTournament(params: IPutTournament, useGlobalLoader: boolean) {
  return await APIPut(baseTournamentUrl, params, useGlobalLoader);
}

// PUT Tournament
// Update an existing tournament
export async function DeleteTournament(params: { id: string | number }, useGlobalLoader: boolean) {
  return await APIDelete(baseTournamentUrl, params, useGlobalLoader);
}
