import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useContext } from "react";
import RadioGroupContext from "./context";
import { IButtonProps } from "./interface";

const Button: React.FC<IButtonProps> = props => {
  const {
    icon,
    size = "medium",
    value,
    check,
    prefixCls = "rc-radio-button",
    className,
    disabled,
    children,
    name,
    onClick,
    id,
    paymentMethod,
    ...rest
  } = props;

  const radioStore = useContext(RadioGroupContext);

  const onChange = () => {
    // props.onChange?.(e);
    if (!onClick) {
      radioStore?.onChange?.(value, id);
    }
  };

  const radioInputClassName = classNames(
    `${prefixCls}-input`,
    { [`${prefixCls}-input-disabled`]: disabled },
    className,
  );

  const radioLabelClassName = classNames(
    `${prefixCls}-label`,
    {
      [`${prefixCls}-label-medium`]: size === "medium",
    },
    {
      [`${prefixCls}-label-small`]: size === "small",
    },
    {
      [`${prefixCls}-label-checked`]: id ? radioStore?.id === id : radioStore.value === value,
    },
    { [`${prefixCls}-label-disabled`]: disabled },
    { [`${prefixCls}-label-dark-style`]: radioStore.darkStyle && !disabled },
    {
      [`${prefixCls}-label-checked-dark-style`]: id
        ? radioStore?.id === id && radioStore?.darkStyle
        : radioStore.value === value && radioStore?.darkStyle,
    },
    {
      [`${prefixCls}-label-payment-method`]: paymentMethod,
    },
  );

  const renderButton = () => {
    if (check) {
      return (
        <div>
          <div className="rc-radio-text-with-check-container">
            <FontAwesomeIcon
              className="self-center text-xl mr-4"
              icon={radioStore.value === value ? "check-circle" : ["far", "circle"]}
              color="#3D5FA0"
            />
            <span>{children}</span>
          </div>
        </div>
      );
    }
    if (icon) {
      return (
        <div
          className={classNames("rc-radio-text-with-icon-container", {
            "rc-radio-text-with-icon-container-payment-method": paymentMethod,
          })}
        >
          <span
            className={classNames(paymentMethod ? "payment-method-icon" : "", {
              "payment-method-icon-checked": paymentMethod
                ? id
                  ? radioStore?.id === id
                  : radioStore.value === value
                : null,
              "payment-method-icon-disabled": paymentMethod ? disabled : null,
            })}
          >
            {icon}
          </span>
          <span
            className={classNames(paymentMethod ? "payment-method-text" : "", {
              "payment-method-text-disabled": paymentMethod ? disabled : null,
            })}
          >
            {children}
          </span>
        </div>
      );
    } else {
      return <span className="rc-radio-text">{children}</span>;
    }
  };

  return (
    <React.Fragment {...rest}>
      <label
        onClick={onClick}
        htmlFor={id ? `${name}-${id.toString()}` : `${name}-${value.toString()}`}
        className={radioLabelClassName}
      >
        <span className="rc-radio-button">
          <input
            type="radio"
            id={id ? `${name}-${id.toString()}` : `${name}-${value.toString()}`}
            name={id ? `${name}-${id.toString()}` : `${name}-${value.toString()}`}
            className={radioInputClassName}
            disabled={disabled}
            onClick={onChange}
          />
        </span>
        {renderButton()}
      </label>
    </React.Fragment>
  );
};

export default Button;
