import React, { useEffect, useState } from "react";

import { StatusCode } from "api/protocols";
import { GetReport } from "api/rpc/2024-04/clientAdmin/report/report";

import { useHistory } from "react-router-dom";
import { useAppSelector } from "hooks/redux";

import Page from "components/page/Page";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

export default function Reports(props: any) {
  const history = useHistory();
  const { authStore } = useAppSelector(store => store);
  const permissions = authStore.user?.permissions as Record<string, any>;
  const [reportState, setReportState] = useState({
    reports: null,
    categories: null,
  });

  // Add new report codes here, routes will use the code instead of the id
  const newReports = [
    "scheduled-payments",
    "account-adjustments",
    "aged-accounts-receivable",
    "detailed-sales-by-department",
    "membership-sales",
    "tee-sheet-utilization",
    "order-exceptions",
    "tournaments-not-invoiced",
    "invoices",
    "deposits",
    "prize-accounts",
    "golfnorth-daily-sales",
    "hospitality-events-not-invoiced",
    "inventory-adjustments",
    "inventory-listing-by-department",
    "revenue-summary",
  ];

  useEffect(() => {
    void loadReports();
  }, []);

  async function loadReports() {
    const reportRes = await GetReport(null, false);

    if (reportRes.status !== StatusCode.OK) {
      setReportState(prevState => ({
        ...prevState,
        reports: [],
      }));
      return;
    }

    const categories = [...new Set(reportRes.data.map(report => report.category))];

    setReportState(prevState => ({
      ...prevState,
      reports: reportRes.data,
      categories: categories,
    }));
  }

  function navigateToItem(url: string) {
    history.push(url);
  }

  return (
    <Page title="Reports">
      {reportState.categories && (
        <DataTable columns={[{ label: reportState.categories[0] }]}>
          {reportState.categories.map((category: any, categoryIndex: number) => (
            <>
              {categoryIndex > 0 && (
                <tr key={categoryIndex} className="report-category-row">
                  <td className="report-category">{category}</td>
                </tr>
              )}
              {reportState.reports
                ?.filter((report: any) => report.category === category)
                ?.map((report: any, index: number) => {
                  const permission = "reports_client_" + String(report.code.replace(/-/g, "_"));
                  return permissions[permission] == true || permissions[permission] == null ? (
                    <tr
                      key={report.id}
                      className="clickable"
                      onClick={() =>
                        newReports.indexOf(report.code) >= 0
                          ? navigateToItem("/admin/report/" + String(report.code))
                          : navigateToItem("/admin/report/" + String(report.id))
                      }
                    >
                      <td>{report.title}</td>
                    </tr>
                  ) : null;
                })}
            </>
          ))}
        </DataTable>
      )}
    </Page>
  );
}
