import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetFacility } from "api/rpc/2024-04/clientAdmin/facility/facility";

import { showError } from "redux/actions/ui";
import { IFacility } from "redux/reducers/models/facility";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import Avatar from "components/avatar/Avatar";
import { Flag } from "components/flag/Flag";
import { Badge } from "components/badge/Badge";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

export default function Facilities() {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [facilities, setFacilities] = useState<IFacility[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadFacilities(source.token);
    return () => source.cancel();
  }, []);

  async function loadFacilities(token?: CancelToken) {
    const res = await GetFacility(null, token ? false : true, token);
    if (token && token.reason) {
      return;
    }

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading facilities.")); // TODO: Translation
    }

    setFacilities(res.status !== StatusCode.OK ? [] : res.data);
  }

  const primaryAction = {
    content: "New Facility", // TODO: Translation
    action: () => history.push("/admin/settings/client-settings/facility-details/new"),
  };

  return (
    <Page title="Facilities" /* TODO: Translation */ primaryAction={primaryAction}>
      <DataTable
        columns={[
          { label: "Name" }, // TODO: Translation
          { label: "Address" }, // TODO: Translation
          { label: "City" }, // TODO: Translation
          { label: "Postal" }, // TODO: Translation
          { label: "Province" }, // TODO: Translation
          { label: "Country" }, // TODO: Translation
        ]}
        loading={facilities === undefined}
      >
        {facilities?.map(facility => (
          <tr
            key={facility.id}
            className="clickable"
            onClick={() => history.push("/admin/settings/client-settings/facility-details/edit/" + String(facility.id))}
          >
            <td className="table-cell-lead-avatar">
              <Avatar source={facility.logo_source} size="medium" />
              <p className="table-cell-lead-text">{facility.long_name}</p>
            </td>
            <td>{facility.address_line_1}</td>
            <td>{facility.city}</td>
            <td>{facility.postal}</td>
            <td>{facility.province_name}</td>
            <td>
              <Badge type="gray" iconSize="medium" iconLeft={<Flag countryCode={facility.country_code} />}>
                {facility.country_name}
              </Badge>
            </td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
