import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { IBookingCategory } from "redux/reducers/models/teetime";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/tee-sheet/booking-engine";

interface IGetBookingEngine {
  id?: string;
  extended?: boolean;
}

interface IPutBookingEngine {
  id: string;
  title?: string;
  booking_terms?: string;
  payment_terms?: string;
  booking_category_ids?: Array<number>;
}

interface IGetBookingFee {
  id?: number;
  booking_engine_id: string | number;
}

interface IPostBookingFee {
  booking_engine_id: number;
  variant_id: number;
  unit?: string;
  non_refundable: boolean;
  days_in_advance_start: string;
  days_in_advance_end: string;
}

interface IPutBookingFee {
  id: number;
  days_in_advance_start: string;
  days_in_advance_end: string;
  variant_id: number;
  non_refundable: boolean;
}

interface IGetBookingCategory {
  id?: string;
  extended?: boolean;
}

interface IBookingCategoryRes {
  status: number;
  data: IBookingCategory[];
  message: string;
}

export async function GetBookingEngine(params: IGetBookingEngine, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseUrl, params, useGlobalLoader, token);
}

export async function PutBookingEngine(params: IPutBookingEngine, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

export async function GetBookingFees(params: IGetBookingFee, useGlobalLoader: boolean) {
  return await APIGet(baseUrl + "/fee", params, useGlobalLoader);
}

export async function PostBookingFee(params: IPostBookingFee, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/fee", params, useGlobalLoader);
}

export async function PutBookingFee(params: IPutBookingFee, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/fee", params, useGlobalLoader);
}

export async function DeleteBookingFee(params: { id: number }, useGlobalLoader: boolean) {
  return await APIDelete(baseUrl + "/fee", params, useGlobalLoader);
}

export async function GetBookingCategory(
  params: IGetBookingCategory,
  useGlobalLoader: boolean,
): Promise<IBookingCategoryRes> {
  return await APIGet(baseUrl + "/category", params, useGlobalLoader);
}
