import Sheet from "components/sheet/Sheet";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IUser } from "redux/reducers/models/user";
import { Select } from "components/select/index";
import { useAppSelector } from "hooks/redux";

interface ITransferTableProps {
  modalVisibility: boolean;
  users: Array<IUser>;
  onCancel?: () => void;
  onOk?: (transferInfo: ITransferTableSheetState) => void;
}

export interface ITransferTableSheetState {
  selectedUserId: number;
}

function TransferTableSheet(props: ITransferTableProps) {
  const { t } = useTranslation();
  const { Option } = Select;
  const { cartStore } = useAppSelector(store => store);

  const [transferTableState, setTransferTableState] = useState<ITransferTableSheetState>({
    selectedUserId: null,
  });

  useEffect(() => {
    if (props.modalVisibility) {
      setTransferTableState(prevState => ({ ...prevState, selectedUserId: cartStore?.activeTable?.user_id }));
    }
  }, [props.modalVisibility]);

  function onOk() {
    void props.onOk({ ...transferTableState });
  }

  function onCancel() {
    props.onCancel();
  }

  function handleUserSelectChange(userId: number) {
    setTransferTableState(prevState => ({ ...prevState, selectedUserId: userId }));
  }

  return (
    <Sheet
      title={t("components.transfer_table_sheet.transfer_table_sheet.001")}
      open={props.modalVisibility}
      size="small"
      closable
      onCancel={onCancel}
      onOk={onOk}
      overflow
      okText={t("components.transfer_table_sheet.transfer_table_sheet.002")}
      okDisabled={cartStore?.activeTable?.user_id === transferTableState?.selectedUserId ? true : false}
    >
      <Select
        onChange={(value: number) => handleUserSelectChange(value)}
        defaultValue={transferTableState?.selectedUserId ?? null}
        label="Transfer to"
      >
        {props.users.map((user, userIndex) => (
          <Option key={userIndex} value={user.id} name={user.full_name}>
            {user.full_name}
          </Option>
        ))}
      </Select>
    </Sheet>
  );
}

export default TransferTableSheet;
