import React, { useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import { showError, showSuccess } from "redux/actions/ui";

import { StatusCode } from "api/protocols";
import {
  GetTeeSheetOpenWindow,
  GetTeeSheets,
  ITeeSheet,
  PostTeeSheet,
  PutApply,
  PutTeeSheet,
  PutTeeSheetOpenWindow,
} from "api/rpc/2024-04/facilityAdmin/teesheet/teeSheet";

import { useAppDispatch, useAppSelector } from "hooks/redux";

import Input from "components/form/input/Input";
import Toggle from "components/form/toggle/Toggle";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Callout from "components/callout/Callout";
import { ButtonNew as Button } from "components/buttonNew";
import Popup from "components/popup/Popup";
import { Badge } from "components/badge/Badge";
import Page from "components/page/Page";
import { Select } from "components/select/index";
import Sheet from "components/sheet/Sheet";

import "./teesheetCalendar.scss";
import { monthNames } from "helpers/Helpers";
import Tooltip from "components/tooltip/Tooltip";
import { selectDate } from "redux/actions/teesheet";
import { useHistory } from "react-router";
import Spin from "components/spin/spin";
import TimePick from "components/timePick/TimePick";
import MonthPicker from "components/monthPicker/MonthPicker";
import RightClickMenu from "components/rightClickMenu/RightClickMenu";
import { GetTeeSheetTemplate, GetTeeSheetTemplatePrint } from "api/rpc/2024-04/facilityAdmin/teesheet/teesheetTemplate";

interface ITemplate {
  end_time: string;
  facility_id: number;
  course_id: number;
  format: string;
  holes: number;
  id: number;
  interval: number;
  name: string;
  start_time: string;
}

interface IMainState {
  teesheets: ITeeSheet[];
  selectedTeeSheets: number[];
  applyTemplateActive: boolean;
  templates: ITemplate[];
  selectedTemplate: number;
  checked?: boolean | null;
  selectedCalendarDate: Date;
  courseId: number;
  viewOpenCloseTimes: boolean;
  markHolidays: boolean;
  holidaysAddPopupVisible: boolean;
  holidaysRemovePopupVisible: boolean;
  addTemplatePopupVisible: boolean;
  months: any[];
  years: any[];
  selectedQuickDateMonth: number;
  selectedQuickDateYear: number;
}

interface ICreateTeeSheetState {
  selectedDate: string;
  modalVisible: boolean;
}

interface IOpenWindowState {
  editOpenWindowVisible: boolean;
  openTime: string;
  closeTime: string;
  openWindows: Record<string, any>;
  selectedOpenWindows: Array<number>;
}

enum StateType {
  MainState = "IMainState",
}

const TeeSheetCalendar: React.FC = () => {
  const facilityStore = useAppSelector(store => store.facilityStore);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { Option } = Select;
  const { t, i18n } = useTranslation();

  const [state, setState] = useState<IMainState>({
    teesheets: [],
    selectedTeeSheets: [],
    applyTemplateActive: false,
    templates: undefined,
    selectedTemplate: null,
    checked: null,
    selectedCalendarDate: new Date(),
    courseId: undefined,
    viewOpenCloseTimes: false,
    markHolidays: false,
    holidaysAddPopupVisible: false,
    holidaysRemovePopupVisible: false,
    addTemplatePopupVisible: false,
    months: [],
    years: [],
    selectedQuickDateMonth: new Date().getMonth() + 1,
    selectedQuickDateYear: new Date().getFullYear(),
  });

  const [createTeeSheetState, setCreateTeeSheetState] = useState<ICreateTeeSheetState>({
    selectedDate: moment(new Date()).format("YYYY-MM").toString(),
    modalVisible: false,
  });

  const [openWindowState, setOpenWindowState] = useState<IOpenWindowState>({
    editOpenWindowVisible: false,
    openTime: "06:00",
    closeTime: "20:00",
    openWindows: {},
    selectedOpenWindows: [],
  });

  // Filtered TeeSheet[] specific to calendar month and courseId
  const filteredTeeSheets = useMemo(() => {
    return (
      state.courseId !== undefined &&
      !isNaN(state.courseId) &&
      state.teesheets.length !== 0 &&
      state.teesheets.filter(teesheet => {
        const teesheetDate = new Date(teesheet?.date + " 00:00"); //capture date

        //determine related TeeSheets
        return (
          teesheetDate.toString() !== "Invalid Date" &&
          teesheetDate.getFullYear() === state.selectedCalendarDate.getFullYear() &&
          teesheetDate.getMonth() === state.selectedCalendarDate.getMonth() &&
          state.courseId === teesheet?.course_id
        );
      })
    );
  }, [state.selectedCalendarDate, state.courseId, state.teesheets]);

  useEffect(() => {
    void loadTeeSheets();
  }, []);

  useEffect(() => {
    const months: { month: any; id: number }[] = [];
    const years: { year: number; id: number }[] = [];

    monthNames(t).map((monthName: any, index: number) => {
      const month = {
        month: monthName,
        id: index + 1,
      };

      months.push(month);
    });

    let startingYear = new Date().getFullYear() - 5;
    const endingYear = new Date().getFullYear() + 5;

    for (let i = startingYear; i <= endingYear; i++) {
      const year = {
        year: startingYear,
        id: startingYear,
      };

      years.push(year);
      startingYear++;
    }

    console.log(months, years);

    setState(prevState => ({ ...prevState, months: months, years: years }));
  }, []);

  useEffect(() => {
    if (state.selectedCalendarDate && state.viewOpenCloseTimes) {
      void getOpenWindows();
    }
  }, [state.selectedCalendarDate, state.viewOpenCloseTimes]);

  useEffect(() => {
    if (state.courseId === undefined || isNaN(state.courseId)) {
      updateState({ courseId: facilityStore.facility?.courses?.[0]?.id }, StateType.MainState);
    }
  }, [facilityStore.facility]);

  async function getOpenWindows() {
    const windowsRes = await GetTeeSheetOpenWindow({ month: state.selectedCalendarDate.getMonth() + 1 }, true);
    if (windowsRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.tee_sheets.tee_sheet_calendar.018")));
      return;
    }
    const formatOpenWindows = windowsRes?.data?.reduce(
      (formattedTest: { [key: string]: Record<string, any> }, currentDay: Record<string, any>) => {
        return { ...formattedTest, [currentDay.date]: currentDay };
      },
      {},
    );
    setOpenWindowState(prevState => ({ ...prevState, openWindows: formatOpenWindows }));
  }

  function updateState<T>(newState: Partial<T>, type: StateType) {
    const states = {
      [StateType.MainState]: (newState: Partial<T>) =>
        setState((cur: IMainState) => {
          return { ...cur, ...newState };
        }),
    };

    states[type](newState);
  }

  const onSelectedTeeSheetChange = (newId: number) => {
    if (newId === undefined || isNaN(newId)) {
      return;
    }

    if (state.selectedTeeSheets.some(selectedId => selectedId === newId)) {
      const updatedSelectedTeeSheets = state.selectedTeeSheets.filter(teesheetId => {
        if (teesheetId === newId) {
          return false;
        } else {
          return true;
        }
      });

      updateState<IMainState>({ selectedTeeSheets: updatedSelectedTeeSheets }, StateType.MainState);
    } else {
      //TODO: Remove checks once backend updated to queuing system
      //Only apply checks if 'Mark Holidays' is toggled off
      if (!state.markHolidays) {
        const selectedTeeSheetTemplateTitles = state.teesheets
          .filter(teesheet => state.selectedTeeSheets.includes(teesheet.id))
          .map(value => value.tee_sheet_template_title);

        // limit of 7 selected TeeSheets at a time.
        if (selectedTeeSheetTemplateTitles.length > 6) {
          dispatch(showError(t("secure.facility.settings.tee_sheets.tee_sheet_calendar.013"), 2500));
          return;
        }

        // cannot select past dates
        if (
          moment(filteredTeeSheets.find(teeSheet => teeSheet.id === newId).date).isBefore(
            moment(new Date()).format("YYYY-MM-DD").toString(),
            "day",
          )
        ) {
          dispatch(showError("Cannot select past dates", 2500)); //TODO: Translation
          return;
        }

        // Always apply first selected tee sheet
        if (selectedTeeSheetTemplateTitles.length !== 0) {
          // limit to 1 teesheet if template found with the currently selected teesheet
          if (selectedTeeSheetTemplateTitles[0] !== null) {
            dispatch(showError(t("secure.facility.settings.tee_sheets.tee_sheet_calendar.037"), 2500)); //TODO: Translation
            return;
          }

          // disable select if currently selecting teesheets are null
          if (
            selectedTeeSheetTemplateTitles[0] === null &&
            filteredTeeSheets.find(teeSheet => teeSheet.id === newId).tee_sheet_template_title !== null
          ) {
            dispatch(showError(t("secure.facility.settings.tee_sheets.tee_sheet_calendar.038"))); // TODO: Translation
            return;
          }
        }
      }

      updateState<IMainState>({ selectedTeeSheets: [...state.selectedTeeSheets, newId] }, StateType.MainState);
    }
  };

  const loadTeeSheets = async () => {
    try {
      const res = await GetTeeSheets({}, true);
      // load to state
      updateState<IMainState>({ teesheets: res.data }, StateType.MainState);
    } catch (e) {
      console.log("load tee sheets", e);
    }
  };

  const handleApplyTemplate = async () => {
    updateState<IMainState>({ applyTemplateActive: true }, StateType.MainState);

    // load templates
    try {
      const templateRes = await GetTeeSheetTemplate({ id: null }, false);

      if (templateRes.status !== StatusCode.OK) {
        updateState<IMainState>({ templates: [] }, StateType.MainState);
        return;
      }

      if (templateRes.status === StatusCode.OK) {
        updateState<IMainState>({ templates: templateRes.data }, StateType.MainState);
      }
    } catch (e) {
      console.log("GetTeeSheetTemplate ERR", e);
      updateState<IMainState>({ templates: [] }, StateType.MainState);
    }
  };

  async function printTeeSheetTemplate(teeSheetTemplateId: number) {
    const printRes = await GetTeeSheetTemplatePrint({ id: teeSheetTemplateId }, true);

    if (printRes.status !== StatusCode.OK) {
      return;
    }

    window.open().document.write(printRes.data);
  }

  const cancelApplyTemplate = () => {
    updateState<IMainState>(
      { applyTemplateActive: false, selectedTeeSheets: [], checked: false, addTemplatePopupVisible: false },
      StateType.MainState,
    );
  };

  const onTemplateChange = (value: number) => {
    updateState<IMainState>({ selectedTemplate: value }, StateType.MainState);
  };

  const handleApply = async () => {
    const tee_sheet_ids = state.selectedTeeSheets;

    const template_id = state.selectedTemplate;

    console.log(`apply ${template_id} to ${tee_sheet_ids.toString()}`);

    try {
      const res = await PutApply({ template_id, tee_sheet_ids }, true);

      if (res.status !== StatusCode.OK) {
        dispatch(showError(t("secure.facility.settings.tee_sheets.tee_sheet_calendar.016")));
        cancelApplyTemplate(); // remove ID's if error trying to apply
      }

      if (res.status === StatusCode.OK) {
        void loadTeeSheets();
        dispatch(showSuccess(t("secure.facility.settings.tee_sheets.tee_sheet_calendar.017")));

        cancelApplyTemplate();
      }
    } catch (e) {
      console.log("PutApply ERR:", e);
    }
  };

  const primaryAction = {
    content: t("secure.facility.settings.tee_sheets.tee_sheet_calendar.001"),
    action: handleApplyTemplate,
    disabled: state.viewOpenCloseTimes || state.markHolidays ? true : false,
  };

  const onCourseChange = (value: number) => {
    updateState<IMainState>({ courseId: value, selectedTeeSheets: [] }, StateType.MainState);
  };

  function getDaysInPreviousMonth(date: Date) {
    return new Date(date.getFullYear(), date.getMonth()).getDay();
  }

  function getDaysInMonth(date: Date) {
    return 32 - new Date(date.getFullYear(), date.getMonth(), 32).getDate();
  }

  function getDaysInNextMonth(date: Date) {
    return 7 * 6 - (getDaysInPreviousMonth(date) + getDaysInMonth(date));
  }

  function shiftMonth(monthsToShiftBy: number) {
    const newDate = new Date(state.selectedCalendarDate.getFullYear(), state.selectedCalendarDate.getMonth());
    newDate.setMonth(newDate.getMonth() + monthsToShiftBy);

    updateState<IMainState>(
      {
        selectedCalendarDate: newDate,
        selectedQuickDateMonth: newDate.getMonth() + 1,
        selectedQuickDateYear: newDate.getFullYear(),
      },
      StateType.MainState,
    );
    setOpenWindowState(prevState => ({ ...prevState, selectedOpenWindows: [] }));
  }

  function handleDateInputChange(date: string) {
    setCreateTeeSheetState(prevState => ({ ...prevState, selectedDate: date }));
  }

  async function createTeeSheets() {
    //Add in the 15th day of the month for formatting
    const createRes = await PostTeeSheet(
      { date: createTeeSheetState.selectedDate.concat("-15"), course_id: state.courseId },
      true,
    );
    if (createRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.tee_sheets.tee_sheet_calendar.014")));
      return;
    }
    void loadTeeSheets();
    setCreateTeeSheetState(prevState => ({ ...prevState, modalVisible: false }));
    dispatch(showSuccess(t("secure.facility.settings.tee_sheets.tee_sheet_calendar.015")));
  }

  function handleToggle(viewOpenCloseTimes: boolean) {
    ReactDOM.unstable_batchedUpdates(() => {
      updateState<IMainState>({ selectedTeeSheets: [], viewOpenCloseTimes, markHolidays: false }, StateType.MainState);
      setOpenWindowState(prevState => ({ ...prevState, selectedOpenWindows: [] }));
    });
  }

  function handleHolidayToggle(markHolidays: boolean) {
    ReactDOM.unstable_batchedUpdates(() => {
      updateState<IMainState>(
        { selectedTeeSheets: !markHolidays ? [] : state.selectedTeeSheets, markHolidays, viewOpenCloseTimes: false },
        StateType.MainState,
      );
      setOpenWindowState(prevState => ({ ...prevState, selectedOpenWindows: [] }));
    });
  }

  async function handleApplyOpenWindow() {
    const openWindowRes = await PutTeeSheetOpenWindow(
      {
        open_time: openWindowState.openTime ?? null,
        close_time: openWindowState.closeTime ?? null,
        open_window_ids: openWindowState.selectedOpenWindows,
      },
      true,
    );
    if (openWindowRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.tee_sheets.tee_sheet_calendar.019")));
      return;
    }
    dispatch(showSuccess(t("secure.facility.settings.tee_sheets.tee_sheet_calendar.026")));
    setOpenWindowState(prevState => ({
      ...prevState,
      editOpenWindowVisible: false,
      openTime: "06:00",
      closeTime: "20:00",
      selectedOpenWindows: [],
    }));
    await getOpenWindows();
  }

  function onSelectedOpenWindowChange(id: number) {
    if (id == null) {
      return;
    }
    const updatedOpenWindowSelection = [...openWindowState.selectedOpenWindows];
    const foundIndex = updatedOpenWindowSelection.indexOf(id);
    if (foundIndex === -1) {
      updatedOpenWindowSelection.push(id);
    } else {
      updatedOpenWindowSelection.splice(foundIndex, 1);
    }
    setOpenWindowState(prevState => ({ ...prevState, selectedOpenWindows: updatedOpenWindowSelection }));
  }

  function selectAllOpenWindows() {
    const openWindows = { ...openWindowState.openWindows };
    const allOpenWindowIds = Object.keys(openWindows).map(keyName => {
      return openWindows[keyName]?.id as number;
    });
    setOpenWindowState(prevState => ({ ...prevState, selectedOpenWindows: allOpenWindowIds }));
  }

  async function applyHolidays({ mark = false }) {
    const res = await PutTeeSheet({ tee_sheet_ids: state.selectedTeeSheets, holiday: mark }, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.tee_sheets.tee_sheet_calendar.027")));
      return;
    }

    if (mark) {
      dispatch(showSuccess(t("secure.facility.settings.tee_sheets.tee_sheet_calendar.028")));
    } else {
      dispatch(showSuccess(t("secure.facility.settings.tee_sheets.tee_sheet_calendar.047")));
    }
    setState(prevState => ({
      ...prevState,
      holidaysAddPopupVisible: false,
      holidaysRemovePopupVisible: false,
      selectedTeeSheets: [],
    }));
    void loadTeeSheets();
  }

  const handleMonthChange = (value: number) => {
    const newDate = new Date(state.selectedQuickDateYear, value - 1);
    newDate.setMonth(newDate.getMonth());
    ReactDOM.unstable_batchedUpdates(() => {
      updateState<IMainState>({ selectedQuickDateMonth: value, selectedCalendarDate: newDate }, StateType.MainState);
      setOpenWindowState(prevState => ({ ...prevState, selectedOpenWindows: [] }));
    });
  };

  const handleYearChange = (value: number) => {
    const newDate = new Date(value, state.selectedQuickDateMonth - 1);
    newDate.setMonth(newDate.getMonth());
    ReactDOM.unstable_batchedUpdates(() => {
      updateState<IMainState>({ selectedQuickDateYear: value, selectedCalendarDate: newDate }, StateType.MainState);
      setOpenWindowState(prevState => ({ ...prevState, selectedOpenWindows: [] }));
    });
  };

  const handleDateNavigation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, dateString: string) => {
    e.stopPropagation();

    const passingDate = new Date(dateString.replace(/-/g, "/"));
    dispatch(selectDate(passingDate));
    history.push("/admin/teesheet");
  };

  return (
    <Page
      title={t("secure.facility.settings.tee_sheets.tee_sheet_calendar.002")}
      subtitle={t("secure.facility.settings.tee_sheets.tee_sheet_calendar.003")}
      primaryAction={primaryAction}
      multipleActionDropdownAction={{
        label: t("secure.facility.settings.tee_sheets.tee_sheet_calendar.048"),
        dropdownProps: {
          alignment: "right",
          options: [
            {
              type: "handler",
              label: t("secure.facility.settings.tee_sheets.tee_sheet_calendar.008"),
              handler: () => setCreateTeeSheetState(prevState => ({ ...prevState, modalVisible: true })),
            },
            {
              type: "conditional",
              label: t("secure.facility.settings.tee_sheets.tee_sheet_calendar.049"),
              active: state.markHolidays,
              setActive: handleHolidayToggle,
              subOptions: [
                {
                  type: "handler",
                  label: t("secure.facility.settings.tee_sheets.tee_sheet_calendar.039"),
                  handler: () => setState(prevState => ({ ...prevState, holidaysRemovePopupVisible: true })),
                },
                {
                  type: "handler",
                  label: t("secure.facility.settings.tee_sheets.tee_sheet_calendar.029"),
                  handler: () => setState(prevState => ({ ...prevState, holidaysAddPopupVisible: true })),
                },
              ],
            },
            {
              type: "conditional",
              label: t("secure.facility.settings.tee_sheets.tee_sheet_calendar.050"),
              active: state.viewOpenCloseTimes,
              setActive: handleToggle,
              subOptions: [
                {
                  type: "handler",
                  label: t("secure.facility.settings.tee_sheets.tee_sheet_calendar.030"),
                  handler: () => setOpenWindowState(prevState => ({ ...prevState, editOpenWindowVisible: true })),
                },
                {
                  type: "handler",
                  label: t("secure.facility.settings.tee_sheets.tee_sheet_calendar.024"),
                  handler: selectAllOpenWindows,
                  disableAutoClose: true,
                },
                {
                  type: "handler",
                  label: t("secure.facility.settings.tee_sheets.tee_sheet_calendar.025"),
                  handler: () => setOpenWindowState(prevState => ({ ...prevState, selectedOpenWindows: [] })),
                  disableAutoClose: true,
                },
              ],
            },
          ],
        },
      }}
    >
      {state.teesheets?.length > 0 &&
        state.courseId &&
        !isNaN(state.courseId) &&
        (() => {
          const courses = facilityStore?.facility?.courses ?? [];

          return (
            <>
              {courses.length > 1 ? (
                <div className="courses-dropdown-container">
                  <Select className="courses-dropdown" defaultValue={state.courseId} onChange={onCourseChange}>
                    {courses.map((course: any) => {
                      return (
                        <Option value={course?.id} key={course?.id}>
                          {course?.full_name}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              ) : null}

              <div className="calendar-quick-date-selection">
                <button className="navigation-button" onClick={() => shiftMonth(-1)}>
                  <FontAwesomeIcon icon={["far", "chevron-left"]} />
                </button>

                <div className="calendar-quick-date-picker">
                  <div>
                    <Select defaultValue={state.selectedQuickDateMonth} onChange={handleMonthChange}>
                      {state.months?.map(month => {
                        return (
                          <Option value={month.id} key={month.id}>
                            {month.month}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>

                  <div>
                    <Select defaultValue={state.selectedQuickDateYear} onChange={handleYearChange}>
                      {state.years?.map(year => {
                        return (
                          <Option value={year.id} key={year.id}>
                            {year.year}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>

                <button className="navigation-button" onClick={() => shiftMonth(1)}>
                  <FontAwesomeIcon icon={["far", "chevron-right"]} />
                </button>
              </div>

              {filteredTeeSheets && filteredTeeSheets.length > 0 ? (
                <>
                  <div className="grid-container">
                    {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day, i) => {
                      return (
                        <div key={i} className="day-title">
                          <p>{day}</p>
                        </div>
                      );
                    })}
                    {Array.from(Array(getDaysInPreviousMonth(state.selectedCalendarDate)).keys()).map(i => {
                      return <div key={i} className="grid-item"></div>;
                    })}
                    {filteredTeeSheets.map((teesheet, i) => {
                      return (
                        <RightClickMenu
                          key={i}
                          sections={[
                            [
                              {
                                icon: "pencil",
                                title: "Edit Template",
                                action: () =>
                                  history.push(
                                    "/admin/settings/tee-sheet/template/" + String(teesheet.tee_sheet_template_id),
                                  ),
                                disabled: !teesheet.tee_sheet_template_title,
                              },
                              {
                                icon: "print",
                                title: "Print Template",
                                action: () => printTeeSheetTemplate(teesheet.tee_sheet_template_id),
                                disabled: !teesheet.tee_sheet_template_title,
                              },
                            ],
                          ]}
                        >
                          <div
                            className={`grid-item teesheet ${
                              state.selectedTeeSheets.some(selectedId => selectedId === teesheet?.id) ||
                              openWindowState.selectedOpenWindows.some(
                                selectedId => selectedId === openWindowState.openWindows[teesheet.date]?.id,
                              )
                                ? "selected-teesheet"
                                : "unselected-teesheet"
                            }`}
                            onClick={
                              state.viewOpenCloseTimes
                                ? () => onSelectedOpenWindowChange(openWindowState.openWindows[teesheet.date]?.id)
                                : () => onSelectedTeeSheetChange(teesheet?.id)
                            }
                            key={i}
                          >
                            {!!teesheet?.holiday && (
                              <div className="holiday">
                                <Badge type="primary" size="small">
                                  {t("secure.facility.settings.tee_sheets.tee_sheet_calendar.033")}
                                </Badge>
                              </div>
                            )}
                            {moment(teesheet.date).isSameOrAfter(
                              moment(new Date()).format("YYYY-MM-DD").toString(),
                              "day",
                            ) && <FontAwesomeIcon icon={["far", "pencil"]} className="edit-icon" />}
                            <div className="day-of-month" onClick={e => handleDateNavigation(e, teesheet.date)}>
                              <p>{i + 1}</p>
                            </div>
                            <div className="grid-content">
                              {state.viewOpenCloseTimes ? (
                                <>
                                  <div className="open-window-container">
                                    <span>{t("secure.facility.settings.tee_sheets.tee_sheet_calendar.020")} </span>
                                    <span>
                                      {openWindowState.openWindows[teesheet.date]?.open_time
                                        ? moment(
                                            openWindowState.openWindows[teesheet.date]?.open_time,
                                            "hh:mm:ss",
                                          ).format("h:mm A")
                                        : ""}
                                    </span>
                                  </div>
                                  <div className="open-window-container">
                                    <span>{t("secure.facility.settings.tee_sheets.tee_sheet_calendar.021")} </span>
                                    <span>
                                      {openWindowState.openWindows[teesheet.date]?.close_time
                                        ? moment(
                                            openWindowState.openWindows[teesheet.date]?.close_time,
                                            "hh:mm:ss",
                                          ).format("h:mm A")
                                        : ""}
                                    </span>
                                  </div>
                                </>
                              ) : teesheet.tee_sheet_template_title ? (
                                <div className="template-title-wrap">
                                  <span className="template-title">{teesheet.tee_sheet_template_title}</span>
                                  {teesheet.tee_sheet_template_version && (
                                    <span className="calendar-version">
                                      Version {teesheet.tee_sheet_template_version}
                                    </span>
                                  )}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </RightClickMenu>
                      );
                    })}
                    {Array.from(Array(getDaysInNextMonth(state.selectedCalendarDate)).keys()).map(i => {
                      return <div key={i} className="grid-item"></div>;
                    })}
                  </div>
                </>
              ) : (
                <Callout
                  type="error"
                  title={t("secure.facility.settings.tee_sheets.tee_sheet_calendar.004")}
                  content={"To create Tee Sheets click Options > Create Tee Sheets"}
                />
              )}

              <Sheet
                open={state.applyTemplateActive}
                closable
                title={t("secure.facility.settings.tee_sheets.tee_sheet_calendar.005")}
                onCancel={cancelApplyTemplate}
                onOk={() => setState(prevState => ({ ...prevState, addTemplatePopupVisible: true }))}
                cancelText={t("secure.facility.settings.tee_sheets.tee_sheet_calendar.006")}
                okText={t("secure.facility.settings.tee_sheets.tee_sheet_calendar.007")}
                size="small"
                overflow
                okDisabled={state.selectedTemplate === null || state.selectedTeeSheets.length === 0}
              >
                <Select
                  defaultValue={state.templates?.find(template => template?.course_id === state.courseId)?.id}
                  onChange={onTemplateChange}
                >
                  {state.templates ? (
                    <>
                      {state.templates
                        .filter(template => template?.course_id === state.courseId)
                        .map(template => {
                          return (
                            <Option value={template.id} key={template.id}>
                              {template.name}
                            </Option>
                          );
                        })}
                    </>
                  ) : (
                    <Option value={"spinner"}>
                      <div style={{ height: "40px" }}>
                        <Spin />
                      </div>
                    </Option>
                  )}
                </Select>
              </Sheet>

              <Sheet
                open={openWindowState.editOpenWindowVisible}
                closable
                overflow
                title={t("secure.facility.settings.tee_sheets.tee_sheet_calendar.040")}
                onCancel={() =>
                  setOpenWindowState(prevState => ({
                    ...prevState,
                    editOpenWindowVisible: false,
                    openTime: "06:00",
                    closeTime: "20:00",
                  }))
                }
                onOk={handleApplyOpenWindow}
                cancelText={t("secure.facility.settings.tee_sheets.tee_sheet_calendar.010")}
                okText={t("secure.facility.settings.tee_sheets.tee_sheet_calendar.041")}
                size="small"
                okDisabled={
                  !openWindowState.openTime ||
                  !openWindowState.closeTime ||
                  openWindowState?.selectedOpenWindows?.length === 0
                    ? true
                    : false
                }
              >
                <Form>
                  <FormLayout>
                    <FormLayout.Group>
                      <TimePick
                        value={openWindowState.openTime}
                        onChange={timeString => setOpenWindowState(prev => ({ ...prev, openTime: timeString }))}
                        label={t("secure.facility.settings.tee_sheets.tee_sheet_calendar.042")}
                        size="large"
                        status={openWindowState.openTime === undefined ? "warning" : undefined}
                      />
                    </FormLayout.Group>
                    <FormLayout.Group>
                      <TimePick
                        value={openWindowState.closeTime}
                        onChange={timeString => setOpenWindowState(prev => ({ ...prev, closeTime: timeString }))}
                        label={t("secure.facility.settings.tee_sheets.tee_sheet_calendar.043")}
                        size="large"
                        status={openWindowState.closeTime === undefined ? "warning" : undefined}
                      />
                    </FormLayout.Group>
                  </FormLayout>
                </Form>
                {openWindowState.selectedOpenWindows?.length === 0 ? (
                  <Callout
                    type="error"
                    title={t("secure.facility.settings.tee_sheets.tee_sheet_calendar.022")}
                    content={t("secure.facility.settings.tee_sheets.tee_sheet_calendar.023")}
                  />
                ) : null}
              </Sheet>
            </>
          );
        })()}
      <Sheet
        open={createTeeSheetState.modalVisible}
        closable
        title={t("secure.facility.settings.tee_sheets.tee_sheet_calendar.009")}
        onCancel={() => setCreateTeeSheetState(prevState => ({ ...prevState, modalVisible: false }))}
        onOk={createTeeSheets}
        cancelText={t("secure.facility.settings.tee_sheets.tee_sheet_calendar.010")}
        okText={t("secure.facility.settings.tee_sheets.tee_sheet_calendar.011")}
        size="small"
        overflow
      >
        <MonthPicker
          onApply={date => handleDateInputChange(date)}
          label={t("secure.facility.settings.tee_sheets.tee_sheet_calendar.012")}
        />
      </Sheet>
      <Popup
        open={state.holidaysAddPopupVisible}
        okDisabled={state.selectedTeeSheets?.length === 0 ? true : false}
        onCancel={() => setState(prevState => ({ ...prevState, holidaysAddPopupVisible: false }))}
        onOk={() => applyHolidays({ mark: true })}
        okText={t("secure.facility.settings.tee_sheets.tee_sheet_calendar.034")}
        type="info"
        title={t("secure.facility.settings.tee_sheets.tee_sheet_calendar.035")}
        description={t("secure.facility.settings.tee_sheets.tee_sheet_calendar.036")}
      />

      <Popup
        open={state.holidaysRemovePopupVisible}
        okDisabled={state.selectedTeeSheets?.length === 0 ? true : false}
        onCancel={() => setState(prevState => ({ ...prevState, holidaysRemovePopupVisible: false }))}
        onOk={() => applyHolidays({ mark: false })}
        okText={t("secure.facility.settings.tee_sheets.tee_sheet_calendar.044")}
        type="warning"
        title={t("secure.facility.settings.tee_sheets.tee_sheet_calendar.044")}
        description={t("secure.facility.settings.tee_sheets.tee_sheet_calendar.046")}
      />

      <Popup
        open={state.addTemplatePopupVisible}
        onCancel={() => setState(prevState => ({ ...prevState, addTemplatePopupVisible: false }))}
        onOk={handleApply}
        stacked
        okText={t("secure.facility.settings.tee_sheets.tee_sheet_calendar.051")}
        type="warning"
        title={t("secure.facility.settings.tee_sheets.tee_sheet_calendar.052")}
        description={t("secure.facility.settings.tee_sheets.tee_sheet_calendar.053")}
      />
    </Page>
  );
};

export default TeeSheetCalendar;
