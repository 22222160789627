import { StatusCode } from "api/protocols";

import Card from "components/card/Card";
import Page from "components/page/Page";
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import "./bankAccounts.scss";
import { useTranslation } from "react-i18next";
import { DeleteBankAccount, GetBankAccount } from "api/rpc/2024-04/facilityAdmin/facility/externalAcount";

interface IBankAccount {
  last_4_redacted: string;
  account_holder_name: string;
  country: string;
  currency: string;
  bank_name: string;
  type: string;
  currency_default: boolean;
}

export default function BankAccountDetails() {
  const { bankAccountId } = useParams<{ bankAccountId: string }>();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [bankAccount, setBankAccount] = useState<IBankAccount>(undefined);

  useEffect(() => {
    void loadBankAccount();
  }, []);

  async function loadBankAccount() {
    const loadBankAccountResponse = await GetBankAccount({ id: parseInt(bankAccountId) }, true);

    if (
      loadBankAccountResponse.status !== StatusCode.OK ||
      loadBankAccountResponse?.data?.length === undefined ||
      loadBankAccountResponse.data.length === 0
    ) {
      return;
    }

    setBankAccount({
      last_4_redacted: loadBankAccountResponse.data[0]?.last_4_redacted ?? "",
      account_holder_name: loadBankAccountResponse.data[0]?.account_holder_name ?? "",
      country: loadBankAccountResponse.data[0]?.country ?? "",
      currency: loadBankAccountResponse.data[0]?.currency ?? "",
      bank_name: loadBankAccountResponse.data[0]?.bank_name ?? "",
      type: loadBankAccountResponse.data[0]?.type ?? "",
      currency_default: loadBankAccountResponse.data[0]?.currency_default ?? true,
    });
  }

  async function deleteBankAccount() {
    const deleteBankAccountResponse = await DeleteBankAccount({ id: parseInt(bankAccountId) }, true);

    if (deleteBankAccountResponse.status !== StatusCode.OK) {
      return;
    }

    history.push("/admin/settings/bank_accounts");
  }

  const primaryAction = {
    content: t("secure.facility.settings.bank_accounts.bank_account_details.001"),
    action: deleteBankAccount,
  };

  return bankAccount ? (
    <Page
      title={`${t("secure.facility.settings.bank_accounts.bank_account_details.002")} ${bankAccount.last_4_redacted}`}
      narrow
      primaryAction={bankAccount.currency_default ? undefined : primaryAction}
      breadcrumbs={[
        {
          label: t("secure.facility.settings.bank_accounts.bank_account_details.003"),
          url: "/admin/settings/bank_accounts",
        },
      ]}
    >
      <hr className="my-8" />
      <Card>
        <div className="details">
          <dt className="detailTitles">{t("secure.facility.settings.bank_accounts.bank_account_details.004")}</dt>
          <dd className="detailData">{bankAccount.account_holder_name}</dd>
          <dt className="detailTitles">{t("secure.facility.settings.bank_accounts.bank_account_details.005")}</dt>
          <dd className="detailData">{bankAccount.country}</dd>
          <dt className="detailTitles">{t("secure.facility.settings.bank_accounts.bank_account_details.006")}</dt>
          <dd className="detailData">{bankAccount.currency}</dd>
          <dt className="detailTitles">{t("secure.facility.settings.bank_accounts.bank_account_details.007")}</dt>
          <dd className="detailData">{bankAccount.bank_name}</dd>
          <dt className="detailTitles">{t("secure.facility.settings.bank_accounts.bank_account_details.008")}</dt>
          <dd className="detailData">{bankAccount.type}</dd>
        </div>
      </Card>
    </Page>
  ) : null;
}
