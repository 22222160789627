import React, { useContext } from "react";
import classNames from "classnames";
import "./style.scss";
import { render } from "sass";
import { GroupContext } from "../teeSheetCard/Group";

interface IProps {
  className?: string;
  prefixCls?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  time?: boolean;
  row?: string;
}

const Divider: React.FC<IProps> = props => {
  const { prefixCls = "rc-divider", className, time, children, row, ...rest } = props;

  const groupStore = useContext(GroupContext);

  const timeCls = classNames("time-display", {
    [`time-display-selected`]: groupStore && groupStore.state.row === row,
  });

  const separatorClassName = classNames(
    prefixCls,
    {
      [`${prefixCls}-time`]: time,
      [`${prefixCls}-selected`]: groupStore && groupStore.state.row === row,
    },
    className,
  );

  const renderChild = () => {
    if (time) {
      return (
        <div className="rc-divider-container">
          <span className={timeCls}>{props.time}</span>
          <div className={separatorClassName} role="separator" {...rest}>
            {children && <span className={`${prefixCls}-inner-text`}>{children}</span>}
          </div>
        </div>
      );
    } else {
      return (
        <div className={separatorClassName} role="separator" {...rest}>
          {children && <span className={`${prefixCls}-inner-text`}>{children}</span>}
        </div>
      );
    }
  };

  return renderChild();
};

export default Divider;
