import React, { Fragment, useEffect, useState } from "react";
import classNames from "classnames";
import moment from "moment";
import Note from "components/note/Note";
import { ButtonNew as Button } from "components/buttonNew/index";
import { IBooking, IBookingParticipant, ISlot } from "redux/reducers/models/teetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Sheet from "components/sheet/Sheet";
import Input from "components/form/input/Input";
import Popup from "components/popup/Popup";
import FormLayout from "components/form/FormLayout";
import { Select } from "components/select/index";
import MembershipDisplay from "components/membership-display/MembershipDisplay";
import Callout from "components/callout/Callout";
import Tabs from "components/tabs/Tabs";
import { PutCustomer } from "api/rpc/facilityAdmin/customer";
import { IAPIResponse, StatusCode } from "api/protocols";
import { selectTeeTime } from "redux/actions/teesheet";
import { DeleteBookingNote, GetBookingNotes, PutBookingParticipant } from "api/rpc/teeSheet/booking";
import { GetCustomerMemberships } from "api/rpc/2022-09/facilityAdmin/customer/membership";
import { showError, showSuccess } from "redux/actions/ui";
import { GetCustomerNotes } from "api/rpc/2022-09/facilityAdmin/customer/customer";
import { useTranslation, Trans } from "react-i18next";
import axios, { CancelToken } from "axios";
import { NavigationDropdownNew } from "components/navigationDropdownNew/NavigationDropdownNew";
import Portal from "elements/Portal";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { Badge } from "components/badge/Badge";
import { valueToString } from "helpers/Helpers";
import RightClickMenu from "components/rightClickMenu/RightClickMenu";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useRegisterContext } from "../../register/registerNew/RegisterContext";
import { IOrderProgess, OrderProgress, StateType } from "./NewTeeSheetDetails";
import { useHistory } from "react-router";

interface ITeeSheetBookings {
  initiateEditBookingHandler: (booking: IBooking, newPlayerAdded?: boolean) => void;
  checkInHandler: (slot: ISlot) => void;
  noShowBookingHandler: (slot: ISlot, type: "charge" | "defer" | "weather") => void;
  chargeNoShowHandler: (slot: ISlot) => void;
  showChangePlayerHandler: (bookingParticipant: IBookingParticipant) => void;
  cancelBookingParticipantHandler: (slot: ISlot) => void;
  loadCartWaiverHandler: (customer_id: number) => void;
  initiateRedeemTicketHandler: (slot: ISlot) => void;
  initiateAddFeeHandler: (
    event: React.MouseEvent<Element, MouseEvent>,
    slot: ISlot,
    product_type: "Green Fee" | "Power Cart",
    green_fee_added?: boolean,
    power_cart_fee_added?: boolean,
    orderStarted?: boolean,
  ) => void;
  order?: Record<string, any>;
  powerCartModalVisible: boolean;
  togglePowerCartModalVisible: (visible: boolean) => void;

  orderProgress: IOrderProgess;
  addFeeHandler: (
    slot: ISlot,
    greenFee: {
      id: number;
      productTitle: string;
      variantTitle: string;
    },
    powerCartFee: {
      id: number;
      productTitle: string;
      variantTitle: string;
    },
    event: React.MouseEvent<Element, MouseEvent>,
  ) => Promise<void>;
  removeFeeHandler: (
    slot: ISlot,
    variantId: number,
    type: "green_fee" | "power_cart_fee",
    event: React.MouseEvent<Element, MouseEvent>,
  ) => void;
}

interface IEditCustomerState {
  customerEditVisible: boolean;
  customerFirstName: string;
  customerEmail: string;
  customerPhone: string;
  customerLastName: string;
  customerId: number;
  teeTimeId: string;
  bookingId: number;
  booking_participant_id: number;
  customer_image: any;
  customerMemberships: any;
  customerType: string;
  cartCustomerType: string;
  customerMemberNumber: string;
}

interface IEditNameState {
  newName: string;
  selectedBookingParticipant: IBookingParticipant;
  modalOpen: boolean;
}

interface ICartNumbersState {
  cartNumbersModalVisible: boolean;
  cartNumberModalVisible: boolean;
  power_cart_numbers: number[];
  seatNumbers: number[];
  currentSlot: ISlot;
}

const NewTeeSheetBookingDisplay: React.FC<ITeeSheetBookings> = props => {
  const { Option } = Select;
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { teeSheetStore, facilityStore, authStore } = useAppSelector(store => store);
  const history = useHistory();

  const { order, orderProgress, addFeeHandler, removeFeeHandler } = props;

  const permissions = authStore?.user?.permissions;

  const { participantPaidFees } = useRegisterContext();

  const [editCustomerState, setEditCustomerState] = useState<IEditCustomerState>({
    customerEditVisible: false,
    customerEmail: "",
    customerFirstName: "",
    customerLastName: "",
    customerPhone: "",
    customerId: null,
    teeTimeId: "",
    bookingId: null,
    booking_participant_id: null,
    customer_image: null,
    customerMemberships: null,
    customerType: "",
    cartCustomerType: "",
    customerMemberNumber: "",
  });

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (noteState === null && editCustomerState.customerId) {
      void loadCustomerNotes(false, source.token);
    }

    return () => {
      source.cancel();
    };
  }, [editCustomerState.customerId]);

  const [noteState, setNoteState] = useState(null);
  const [noShowState, setNoShowState] = useState({
    type: null,
    slot: null,
    confirmationPopup: false,
  });

  const [notes, setNotes] = useState<Record<string, any>[]>([]);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const tabs = [
    {
      id: "Details",
      content: "Details",
    },
    {
      id: "Notes",
      content: "Notes",
    },
  ];

  const [editName, setEditName] = useState<IEditNameState>({
    newName: "",
    selectedBookingParticipant: null,
    modalOpen: false,
  });

  const [powerCartNumbersState, setPowerCartNumbersState] = useState<ICartNumbersState>({
    cartNumbersModalVisible: false,
    cartNumberModalVisible: false,
    power_cart_numbers: [0],
    seatNumbers: [0],
    currentSlot: null,
  });

  // Remove bookings as a state to reduce a re-render
  const uniqueBookings: IBooking[] = teeSheetStore.selectedTeeTime?.slots
    .map(slot => {
      if (slot.booking && !uniqueBookings?.find(innerSlot => innerSlot.id === slot.id)) {
        return slot.booking;
      }
    })
    .filter((booking, index, array) => array.findIndex(secBooking => secBooking?.id === booking?.id) === index)
    .filter(booking => booking !== undefined);

  useEffect(() => {
    if (uniqueBookings !== undefined && uniqueBookings?.length !== 0) {
      void updateBookingNotes();
    }
  }, [teeSheetStore.selectedTeeTime?.slots]);

  async function loadCustomerNotes(globalLoader: boolean, token?: CancelToken) {
    const customerNotesRes = await GetCustomerNotes(
      {
        customer_id: editCustomerState.customerId,
      },
      globalLoader,
      token,
    );

    if (customerNotesRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading customer notes"));
      return;
    }

    setNoteState({ notes: customerNotesRes.data });
  }

  async function updateBookingNotes() {
    const tempNotes: Record<string, any>[] = [];

    for (const booking of uniqueBookings) {
      if (booking.has_notes) {
        const bookingNotesRes: IAPIResponse = await GetBookingNotes({ tee_time_booking_id: booking.id }, false);

        if (bookingNotesRes.status !== StatusCode.OK) {
          dispatch(showError(bookingNotesRes.message));
          return;
        }

        if (bookingNotesRes.data?.length > 0) {
          bookingNotesRes.data.reverse();
          tempNotes.push(bookingNotesRes.data);
        }

        //filter out duplicates
        const seenIds = new Set();
        tempNotes.filter(note => {
          let duplicateNote: boolean;
          note.filter((innerEle: { id: number }) => {
            duplicateNote = seenIds.has(innerEle.id);
            seenIds.add(innerEle.id);
          });
          return !duplicateNote;
        });
      }
    }
    setNotes(tempNotes);
  }

  async function updateCustomer() {
    const params = {
      id: editCustomerState.customerId,
      first_name: editCustomerState.customerFirstName,
      last_name: editCustomerState.customerLastName,
      email: editCustomerState.customerEmail,
      phone: editCustomerState.customerPhone,
    };

    const fullName = editCustomerState.customerFirstName + " " + editCustomerState.customerLastName;

    const updateCustomerRes = await PutCustomer(params, true);

    if (updateCustomerRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_display.012")));
      return;
    }
    console.log("Update Customers", updateCustomerRes);
    const bookingParticipantRes = await PutBookingParticipant(
      {
        id: editCustomerState.booking_participant_id,
        first_name: editCustomerState.customerFirstName,
        last_name: editCustomerState.customerLastName,
        full_name: fullName,
      },
      true,
    );

    if (bookingParticipantRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.tee_sheet.tee_sheet_booking_display.013")));
      return;
    }

    setEditCustomerState(prevState => ({ ...prevState, customerEditVisible: false }));
    dispatch(showSuccess(t("secure.facility.tee_sheet.tee_sheet_booking_display.014")));
    void dispatch(selectTeeTime(parseInt(editCustomerState.teeTimeId), true));
  }

  const handleAddAll = (event: React.MouseEvent<Element, MouseEvent>, slot: ISlot) => {
    event.stopPropagation();

    //slot wat not being used before
    //props.handleAddAll(slot);
  };

  function editCustomer(event: React.MouseEvent<Element, MouseEvent>, slot: ISlot) {
    void loadMemberships(slot.booking_participant.customer.id);

    let phoneNumberString: string;

    if (slot.booking_participant.customer.phone === null) {
      phoneNumberString = "";
    } else {
      phoneNumberString = slot.booking_participant.customer.phone;
    }

    setEditCustomerState((prevState: IEditCustomerState) => {
      return {
        ...prevState,
        customerEditVisible: true,
        customerFirstName: slot.booking_participant.customer.first_name,
        customerLastName: slot.booking_participant.customer.last_name,
        customerEmail: slot.booking_participant.customer.email,
        customerPhone: phoneNumberString,
        customerId: slot.booking_participant.customer_id,
        teeTimeId: String(slot.booking.tee_time_id),
        bookingId: slot.booking.id,
        booking_participant_id: slot.booking_participant.id,
        customer_image: slot?.booking_participant?.customer.profile_image_source,
        customerMemberNumber: slot?.booking_participant?.customer?.member_number,
        customerType: slot?.booking_participant?.customer?.customer_type,
        cartCustomerType: slot?.booking_participant?.customer?.cart_customer_type,
      };
    });
  }

  function editCartNumbers(event: React.MouseEvent<Element, MouseEvent>, slots: ISlot[]) {
    const tempPowerCartNumbers: number[] = [];
    const tempSeatNumbers: number[] = [];

    slots.forEach((slot: any, index: number) => {
      tempPowerCartNumbers[index] = slot?.booking_participant?.power_cart_number;
      tempSeatNumbers[index] = slot?.booking_participant?.seat_number;
    });

    setPowerCartNumbersState(prevState => ({
      ...prevState,
      power_cart_numbers: tempPowerCartNumbers,
      seatNumbers: tempSeatNumbers,
      cartNumbersModalVisible: true,
    }));
  }

  function addCartNumber(event: React.MouseEvent<Element, MouseEvent>, slots: ISlot[], slot: ISlot) {
    const tempPowerCartNumbers: number[] = [];
    const tempSeatNumbers: number[] = [];

    slots.forEach((slot: any, index: number) => {
      tempPowerCartNumbers[index] = slot?.booking_participant?.power_cart_number;
      tempSeatNumbers[index] = slot?.booking_participant?.seat_number;
    });

    setPowerCartNumbersState(prevState => ({
      ...prevState,
      power_cart_numbers: tempPowerCartNumbers,
      seatNumbers: tempSeatNumbers,
      currentSlot: slot,
      cartNumberModalVisible: true,
    }));
  }

  async function loadMemberships(id: number) {
    const membershipRes = await GetCustomerMemberships({ customer_id: id }, true);

    if (membershipRes.status !== StatusCode.OK) {
      dispatch(showError("Unable to load memberships"));
      return;
    }

    setEditCustomerState((prevState: IEditCustomerState) => {
      return {
        ...prevState,
        customerMemberships: membershipRes,
      };
    });
  }

  function handleCustomerDetailsChange(event: any) {
    const { id, value } = event.target;
    setEditCustomerState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleCartNumberChange(event: any, index: number) {
    const { id, value } = event.target;

    const tempNumbers = powerCartNumbersState.power_cart_numbers;
    tempNumbers[index] = Number(value);

    setPowerCartNumbersState(prevState => ({ ...prevState, power_cart_numbers: tempNumbers }));
  }

  function handleSeatNumberChange(seatNumber: number, index: number) {
    const tempNumbers = powerCartNumbersState.seatNumbers;
    tempNumbers[index] = seatNumber;

    setPowerCartNumbersState(prevState => ({ ...prevState, seatNumbers: tempNumbers }));
  }

  function saveCartNumbers() {
    powerCartNumbersState.power_cart_numbers.forEach((cart, index) => {
      if (teeSheetStore.selectedTeeTime.slots[index].booking_participant) {
        void saveCartNumber(teeSheetStore.selectedTeeTime.slots[index].booking_participant, index);
      }
    });
    props.togglePowerCartModalVisible(false);
  }

  async function saveCartNumber(bookingParticipant: IBookingParticipant, index: number) {
    setPowerCartNumbersState(prevState => ({ ...prevState, cartNumberModalVisible: false }));
    if (powerCartNumbersState.power_cart_numbers[index] || powerCartNumbersState.seatNumbers[index]) {
      const participantRes = await PutBookingParticipant(
        {
          id: bookingParticipant.id,
          power_cart_number: powerCartNumbersState.power_cart_numbers[index],
          seat_number: powerCartNumbersState.seatNumbers[index],
        },
        false,
      );

      if (participantRes.status !== StatusCode.OK) {
        dispatch(showError("Unable to save cart number"));
        return;
      }
    }
  }

  function handleNoShowSelection(slot: any) {
    setNoShowState(prevState => ({ ...prevState, slot: slot, confirmationPopup: true }));
  }

  function cancelNoShowSelection() {
    setNoShowState(prevState => ({ ...prevState, bookingParticipantId: null, confirmationPopup: false }));
  }

  function handleNoShowType(type: string) {
    setNoShowState(prevState => ({ ...prevState, type: type }));
  }

  function confirmNoShowSelection() {
    props.noShowBookingHandler(noShowState.slot, noShowState.type);
    void cancelNoShowSelection();
  }

  function handleInitiateEditName(slot: ISlot) {
    setEditName(prevState => ({
      ...prevState,
      selectedBookingParticipant: slot?.booking_participant,
      newName: slot?.booking_participant?.full_name,
      modalOpen: true,
    }));
  }

  function handleEditNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setEditName(prevState => ({ ...prevState, [id]: value }));
  }

  async function handleEditName() {
    const [firstName, lastName] = editName?.newName?.split(" ");
    const participantRes = await PutBookingParticipant(
      { id: editName?.selectedBookingParticipant?.id, first_name: firstName ?? "", last_name: lastName ?? "" },
      true,
    );
    if (participantRes?.status !== StatusCode.OK) {
      dispatch(showError("Error editing participant name"));
      return;
    }
    dispatch(showSuccess("Participant name saved successfully"));
    void dispatch(selectTeeTime(teeSheetStore?.selectedTeeTime?.id, true));
    setEditName(prevState => ({ ...prevState, newName: "", selectedBookingParticipant: null, modalOpen: false }));
  }

  async function removeBookingNote(id: number) {
    const res = await DeleteBookingNote({ booking_note_id: id }, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("There was an error removing the booking note."));
      return;
    }

    // Generate AUDIT LOG ?
    void dispatch(selectTeeTime(teeSheetStore?.selectedTeeTime?.id, true));
  }

  async function applyToAllBooking(slot: ISlot, fee: "green_fee" | "power_cart") {
    let counter = 0;

    const slots = [...teeSheetStore?.selectedTeeTime?.slots];

    if (fee === "green_fee") {
      if (slot.booking_participant?.green_fee_variant_id) {
        for (let i = 0; i < slots?.length; i++) {
          if (slot.tee_time_booking_id === slots[i].tee_time_booking_id) {
            if (slot.position !== slots[i].position) {
              if (!slots[i].booking_participant.green_fee_paid) {
                const green_fee_added = participantPaidFees?.current?.some(item => {
                  if (item.booking_participant_id === slots[i].booking_participant?.id) {
                    return item.green_fee === true;
                  }
                });

                if (!green_fee_added) {
                  if (
                    slot.booking_participant.green_fee_variant_id !== slots[i].booking_participant.green_fee_variant_id
                  ) {
                    const params = {
                      id: slots[i].booking_participant.id,
                      green_fee_variant_id: slot.booking_participant.green_fee_variant_id,
                    };

                    const putBookingParticipantRes = await PutBookingParticipant(params, true);

                    if (putBookingParticipantRes.status !== StatusCode.OK) {
                      dispatch(showError("Error updating green fee"));
                    }

                    counter++;
                  }
                }
              }
            }
          }
        }
      }
    }

    if (fee === "power_cart") {
      if (slot.booking_participant?.power_cart_variant_id) {
        for (let i = 0; i < slots?.length; i++) {
          if (slot.tee_time_booking_id === slots[i].tee_time_booking_id) {
            if (slot.position !== slots[i].position) {
              if (!slots[i].booking_participant.power_cart_paid) {
                const power_cart_fee_added = participantPaidFees?.current?.some(item => {
                  if (item.booking_participant_id === slots[i].booking_participant?.id) {
                    return item.power_cart_fee === true;
                  }
                });

                if (!power_cart_fee_added) {
                  if (
                    slot.booking_participant.power_cart_variant_id !==
                    slots[i].booking_participant.power_cart_variant_id
                  ) {
                    const params = {
                      id: slots[i].booking_participant.id,
                      power_cart_variant_id: slot.booking_participant.power_cart_variant_id,
                    };

                    const putBookingParticipantRes = await PutBookingParticipant(params, true);

                    if (putBookingParticipantRes.status !== StatusCode.OK) {
                      dispatch(showError("Error updating power cart fee"));
                    }

                    counter++;
                  }
                }
              }
            }
          }
        }
      }
    }

    // force refresh, updates member / walking
    if (counter !== 0) {
      void dispatch(selectTeeTime(teeSheetStore?.selectedTeeTime?.id, true));
    }
  }

  async function applyToAllPlayers(slot: any, fee: "green_fee" | "power_cart") {
    let counter = 0;
    const slots = [...teeSheetStore?.selectedTeeTime?.slots];

    if (fee === "green_fee") {
      if (slot.booking_participant?.green_fee_variant_id) {
        for (let i = 0; i < slots?.length; i++) {
          if (slots[i].status === "booked") {
            if (slot.position !== slots[i].position) {
              if (!slots[i].booking_participant.green_fee_paid) {
                const green_fee_added = participantPaidFees?.current?.some(item => {
                  if (item.booking_participant_id === slots[i].booking_participant?.id) {
                    return item.green_fee === true;
                  }
                });

                if (!green_fee_added) {
                  if (
                    slot.booking_participant.green_fee_variant_id !== slots[i].booking_participant.green_fee_variant_id
                  ) {
                    const params = {
                      id: slots[i].booking_participant.id,
                      green_fee_variant_id: slot.booking_participant.green_fee_variant_id,
                    };

                    const putBookingParticipantRes = await PutBookingParticipant(params, true);

                    if (putBookingParticipantRes.status !== StatusCode.OK) {
                      dispatch(showError("Error updating green fee"));
                    }

                    counter++;
                  }
                }
              }
            }
          }
        }
      }
    }

    if (fee === "power_cart") {
      if (slot.booking_participant?.power_cart_variant_id) {
        for (let i = 0; i < slots?.length; i++) {
          if (slots[i].status === "booked") {
            if (slot.position !== slots[i].position) {
              if (!slots[i].booking_participant.power_cart_paid) {
                const power_cart_fee_added = participantPaidFees?.current?.some(item => {
                  if (item.booking_participant_id === slots[i].booking_participant?.id) {
                    return item.power_cart_fee === true;
                  }
                });

                if (!power_cart_fee_added) {
                  if (
                    slot.booking_participant.power_cart_variant_id !==
                    slots[i].booking_participant.power_cart_variant_id
                  ) {
                    const params = {
                      id: slots[i].booking_participant.id,
                      power_cart_variant_id: slot.booking_participant.power_cart_variant_id,
                    };

                    const putBookingParticipantRes = await PutBookingParticipant(params, true);

                    if (putBookingParticipantRes.status !== StatusCode.OK) {
                      dispatch(showError("Error updating power cart fee"));
                    }

                    counter++;
                  }
                }
              }
            }
          }
        }
      }
    }

    // force refresh, updates member / walking
    if (counter !== 0) {
      void dispatch(selectTeeTime(teeSheetStore?.selectedTeeTime?.id, true));
    }
  }

  return (
    <>
      <div className="flex flex-col p-4 ">
        {uniqueBookings !== undefined &&
          uniqueBookings?.map(booking => {
            return (
              <div
                key={booking?.id}
                className="context-sider-options-group booking-details h-full flex flex-col flex-1 background-primary-light rounded-xl p-4 mb-2"
              >
                <div className="flex justify-between align-center mb-2">
                  <div className="text-xl text-semibold">
                    {t("secure.facility.tee_sheet.tee_sheet_booking_display.001")}
                    {booking?.confirmation_number}
                  </div>
                  <div className="flex">
                    <Button
                      type="secondary"
                      onClick={() => props.initiateEditBookingHandler(booking)}
                      disabled={!!order || orderProgress.orderStep !== OrderProgress.NEW_ORDER}
                    >
                      {t("secure.facility.tee_sheet.tee_sheet_booking_display.002")}
                    </Button>
                  </div>
                </div>

                <div /*className="h-full overflow-y-scroll no-scrollbar"*/>
                  {teeSheetStore.selectedTeeTime?.slots
                    .filter(slot => slot.booking?.id === booking?.id)
                    .map((slot, index: number) => {
                      const currentParticipant = participantPaidFees?.current.find(
                        participant => participant?.booking_participant_id === slot?.booking_participant?.id,
                      );
                      const green_fee_added =
                        currentParticipant?.green_fee || currentParticipant?.green_fee === undefined;
                      const power_cart_fee_added =
                        currentParticipant?.power_cart_fee || currentParticipant?.power_cart_fee === undefined;
                      return (
                        <Fragment key={index}>
                          {/* Booked Player */}
                          {slot.status === "booked" ? (
                            <div
                              onClick={e => handleAddAll(e, slot)}
                              className="bg-white rounded-xl p-4 mb-2 cursor-pointer"
                            >
                              <div className="flex justify-between gap-2 items-center">
                                <div>
                                  <div className="flex items-center">
                                    <div className="profile-image_container">
                                      <div className="profile-image">
                                        <img
                                          onClick={
                                            !slot?.booking_participant?.guest ? e => editCustomer(e, slot) : null
                                          }
                                          src={
                                            !slot?.booking_participant?.guest &&
                                            slot?.booking_participant?.customer.profile_image_source !== null
                                              ? slot?.booking_participant?.customer?.profile_image_source
                                              : "/public/images/default_profile_image.svg"
                                          }
                                          alt="customer_image"
                                        />
                                      </div>
                                    </div>

                                    <div>
                                      <div className="customer-info-container  edit-name-container">
                                        <div
                                          className="customer-name-text"
                                          onClick={() => props.showChangePlayerHandler(slot.booking_participant)}
                                        >
                                          {slot.booking_participant?.full_name}
                                        </div>

                                        <NavigationDropdownNew
                                          showPlainTextLabel
                                          label={<FontAwesomeIcon icon={["fas", "chevron-down"]} />}
                                          sections={[
                                            [
                                              {
                                                hidden: slot?.booking_participant?.guest,
                                                label: t("secure.facility.tee_sheet.tee_sheet_booking_display.025"),
                                                icon: "magnifying-glass",
                                                onClick: () =>
                                                  history.push(
                                                    "/admin/customers/" +
                                                      String(slot?.booking_participant?.customer_id) +
                                                      "/profile",
                                                  ),
                                              },
                                              {
                                                icon: "circle-check",
                                                label: t("secure.facility.tee_sheet.tee_sheet_booking_display.026"),
                                                onClick: () => props.checkInHandler(slot),
                                              },
                                              {
                                                icon: "ban",
                                                label: t("secure.facility.tee_sheet.tee_sheet_booking_display.027"),
                                                onClick: () => handleNoShowSelection(slot),
                                              },
                                              {
                                                icon: "dollar-sign",
                                                label: t("secure.facility.tee_sheet.tee_sheet_booking_display.005"),
                                                onClick: () => props.chargeNoShowHandler(slot),
                                                hidden:
                                                  !permissions.tee_sheet_charge_no_show ||
                                                  !slot.booking_participant.no_show,
                                              },
                                              {
                                                icon: "right-left",
                                                label: t("secure.facility.tee_sheet.tee_sheet_booking_display.006"),
                                                onClick: () => props.showChangePlayerHandler(slot.booking_participant),
                                              },
                                              {
                                                icon: "user-slash",
                                                label: t("secure.facility.tee_sheet.tee_sheet_booking_display.007"),
                                                onClick: () => props.cancelBookingParticipantHandler(slot),
                                              },
                                              {
                                                icon: "print",
                                                label: t("secure.facility.tee_sheet.tee_sheet_booking_display.008"),
                                                onClick: () =>
                                                  props.loadCartWaiverHandler(slot?.booking_participant?.customer_id),
                                              },
                                              {
                                                icon: "ticket",
                                                label: t("secure.facility.tee_sheet.tee_sheet_booking_display.009"),
                                                onClick: () => props.initiateRedeemTicketHandler(slot),
                                              },
                                              {
                                                hidden: !slot?.booking_participant?.guest,
                                                icon: "pen-to-square" as IconName,
                                                label: "Edit Name",
                                                onClick: () => handleInitiateEditName(slot),
                                              },
                                            ],
                                          ]}
                                        />
                                      </div>

                                      <div>
                                        <div className="mr-2">
                                          {slot.booking_participant.guest && (
                                            <p className="booking-display-new-customer-type">
                                              {t("secure.facility.tee_sheet.tee_sheet_booking_display.015")}
                                            </p>
                                          )}
                                          <div>
                                            {!slot.booking_participant.guest && (
                                              <>
                                                <p className="booking-display-new-customer-type">
                                                  {slot.booking_participant.customer?.customer_type}{" "}
                                                </p>
                                                <p className="booking-display-new-customer-type">
                                                  {slot.booking_participant.customer?.cart_customer_type}
                                                </p>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                        <div className="flex items-center mt-1">
                                          {slot.booking.source === "golfnow" && (
                                            <span className="mr-2">
                                              <div className={"golfNow-logo"}>
                                                <img src="/public/images/gn-logo-small.svg" />
                                              </div>
                                            </span>
                                          )}

                                          {slot.booking_participant.check_in_status === "checked" && (
                                            <span className="mr-2">
                                              <FontAwesomeIcon
                                                icon={["fas", "check-circle"]}
                                                className="booking-participant_icon"
                                              />
                                            </span>
                                          )}

                                          {slot.booking_participant.order_financial_status === "paid" ? (
                                            <span className="mr-2">
                                              <FontAwesomeIcon
                                                icon={["fas", "usd-circle"]}
                                                className="booking-participant_icon"
                                              />
                                            </span>
                                          ) : null}

                                          {slot.booking_participant.no_show === true ? (
                                            <span className="mr-2 flex items-center">
                                              <FontAwesomeIcon
                                                icon={["far", "ban"]}
                                                className="booking-participant_icon mr-2"
                                              />
                                              <Badge size="xsmall" colour="#FDB022" outline>
                                                {slot?.booking_participant?.no_show_type === "defer"
                                                  ? "Defer Charge"
                                                  : valueToString(slot?.booking_participant?.no_show_type)}
                                              </Badge>
                                            </span>
                                          ) : null}

                                          {slot.booking_participant.no_show_weather === true ? (
                                            <span className="mr-2 flex items-center">
                                              <FontAwesomeIcon
                                                icon={["far", "thunderstorm"]}
                                                className="booking-participant_icon mr-2"
                                              />
                                              <Badge size="xsmall" colour="#FDB022" outline>
                                                {slot?.booking_participant?.no_show_type === "defer"
                                                  ? "Defer Charge"
                                                  : valueToString(slot?.booking_participant?.no_show_type)}
                                              </Badge>
                                            </span>
                                          ) : null}
                                        </div>
                                        {slot?.booking_participant?.order_id ? (
                                          <p
                                            className="tee-sheet-details-order booking-display-new-customer-type"
                                            onClick={() =>
                                              history.push("/admin/order/" + String(slot.booking_participant.order_id))
                                            }
                                          >
                                            {t("secure.facility.tee_sheet.tee_sheet_booking_display.023")}{" "}
                                            {slot.booking_participant.order_name}{" "}
                                            {slot.booking_participant.order_financial_status}
                                          </p>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="flex flex-col justify-between self-center">
                                  <div className="booking-display-new-fees-container">
                                    <RightClickMenu
                                      sections={[
                                        [
                                          {
                                            icon: "circle",
                                            title: "Apply to Booking",
                                            action: () => applyToAllBooking(slot, "green_fee"),
                                            disabled: orderProgress.orderStep === OrderProgress.CREATING_CART,
                                          },
                                          {
                                            icon: "ban",
                                            title: "Apply to All Players",
                                            action: () => applyToAllPlayers(slot, "green_fee"),
                                            disabled: orderProgress.orderStep === OrderProgress.CREATING_CART,
                                          },
                                        ],
                                      ]}
                                      fullHeightWidth
                                    >
                                      <div
                                        onClick={e =>
                                          props.initiateAddFeeHandler(
                                            e,
                                            slot,
                                            "Green Fee",
                                            green_fee_added || currentParticipant?.green_fee_loading,
                                            undefined,
                                            !!order,
                                          )
                                        }
                                        className={classNames(
                                          "rounded-xl",
                                          "border",
                                          "border-gray-400",
                                          "pl-3",
                                          "p-2",
                                          "flex",
                                          "items-center",
                                          "h-full",
                                          "booking-display-new",
                                          {
                                            "cursor-not-allowed bg-gray-100":
                                              green_fee_added ||
                                              currentParticipant?.green_fee_loading ||
                                              slot?.booking_participant?.green_fee_paid ||
                                              orderProgress.orderStep === OrderProgress.CREATING_CART ||
                                              !!order,
                                          },
                                        )}
                                      >
                                        <div>
                                          <div className="text-semibold">
                                            {slot.booking_participant.green_fee_product_title || "No Green Fee"}
                                          </div>

                                          {slot.booking_participant.green_fee_product_title &&
                                            slot.booking_participant.green_fee_product_title !==
                                              slot.booking_participant.green_fee_variant_title && (
                                              <div className="text-semibold text-xs text-subdued">
                                                {slot.booking_participant.green_fee_variant_title}
                                              </div>
                                            )}
                                        </div>

                                        {!slot.booking_participant.green_fee_paid ? (
                                          <Button
                                            className={classNames("ml-auto", {
                                              "booking-details-add-button": !green_fee_added,
                                            })}
                                            type="primary"
                                            icon={
                                              currentParticipant?.green_fee_loading ? (
                                                <FontAwesomeIcon size="1x" icon={["fad", "spinner-third"]} spin fade />
                                              ) : green_fee_added ? (
                                                <FontAwesomeIcon icon={["fal", "times"]} />
                                              ) : (
                                                <FontAwesomeIcon icon={["fal", "arrow-right-long"]} />
                                              )
                                            }
                                            onClick={e =>
                                              !green_fee_added
                                                ? void addFeeHandler(
                                                    slot,
                                                    {
                                                      id: slot?.booking_participant?.green_fee_variant_id,
                                                      variantTitle: slot?.booking_participant?.green_fee_variant_title,
                                                      productTitle: slot?.booking_participant?.green_fee_product_title,
                                                    },
                                                    null,
                                                    e,
                                                  )
                                                : void removeFeeHandler(
                                                    slot,
                                                    slot?.booking_participant?.green_fee_variant_id,
                                                    "green_fee",
                                                    e,
                                                  )
                                            }
                                            disabled={
                                              !slot?.booking_participant?.green_fee_variant_id ||
                                              currentParticipant?.green_fee_loading ||
                                              orderProgress.orderStep === OrderProgress.CREATING_CART ||
                                              !!order
                                            }
                                          />
                                        ) : null}
                                      </div>
                                    </RightClickMenu>

                                    <RightClickMenu
                                      sections={[
                                        [
                                          {
                                            icon: "circle",
                                            title: "Apply to Booking",
                                            action: () => applyToAllBooking(slot, "power_cart"),
                                            disabled: orderProgress.orderStep === OrderProgress.CREATING_CART,
                                          },
                                          {
                                            icon: "ban",
                                            title: "Apply to All Players",
                                            action: () => applyToAllPlayers(slot, "power_cart"),
                                            disabled: orderProgress.orderStep === OrderProgress.CREATING_CART,
                                          },
                                        ],
                                      ]}
                                      fullHeightWidth
                                    >
                                      <div
                                        onClick={e =>
                                          props.initiateAddFeeHandler(
                                            e,
                                            slot,
                                            "Power Cart",
                                            green_fee_added,
                                            power_cart_fee_added || currentParticipant?.power_cart_fee_loading,
                                            !!order,
                                          )
                                        }
                                        className={classNames(
                                          "rounded-xl",
                                          "border",
                                          "border-gray-400",
                                          "pl-3",
                                          "p-2",
                                          "flex",
                                          "items-center",
                                          "h-full",
                                          "booking-display-new",
                                          {
                                            "cursor-not-allowed bg-gray-100":
                                              power_cart_fee_added ||
                                              currentParticipant?.power_cart_fee_loading ||
                                              slot?.booking_participant?.power_cart_paid ||
                                              orderProgress.orderStep === OrderProgress.CREATING_CART ||
                                              !!order,
                                          },
                                        )}
                                      >
                                        <div>
                                          <div className="text-semibold">
                                            {slot.booking_participant.power_cart_product_title ||
                                              t("secure.facility.tee_sheet.tee_sheet_booking_display.024")}
                                          </div>

                                          {slot.booking_participant.power_cart_product_title &&
                                            slot.booking_participant.power_cart_product_title !==
                                              slot.booking_participant.power_cart_variant_title && (
                                              <div className="text-semibold text-xs text-subdued">
                                                {slot.booking_participant.power_cart_variant_title}
                                              </div>
                                            )}
                                        </div>

                                        {!slot.booking_participant.power_cart_paid ? (
                                          <Button
                                            className={classNames("ml-auto", {
                                              "booking-details-add-button": !power_cart_fee_added,
                                            })}
                                            type="primary"
                                            icon={
                                              currentParticipant?.power_cart_fee_loading ? (
                                                <FontAwesomeIcon size="1x" icon={["fad", "spinner-third"]} spin fade />
                                              ) : power_cart_fee_added ? (
                                                <FontAwesomeIcon icon={["fal", "times"]} />
                                              ) : (
                                                <FontAwesomeIcon icon={["fal", "arrow-right-long"]} />
                                              )
                                            }
                                            onClick={e => {
                                              !power_cart_fee_added
                                                ? facilityStore?.teeSheetSettings?.track_cart_numbers
                                                  ? addCartNumber(e, teeSheetStore.selectedTeeTime?.slots, slot)
                                                  : void addFeeHandler(
                                                      slot,
                                                      null,
                                                      {
                                                        id: slot?.booking_participant?.power_cart_variant_id,
                                                        variantTitle:
                                                          slot?.booking_participant?.power_cart_variant_title,
                                                        productTitle:
                                                          slot?.booking_participant?.power_cart_product_title,
                                                      },
                                                      e,
                                                    )
                                                : void removeFeeHandler(
                                                    slot,
                                                    slot?.booking_participant?.power_cart_variant_id,
                                                    "power_cart_fee",
                                                    e,
                                                  );
                                              e.stopPropagation();
                                            }}
                                            disabled={
                                              !slot?.booking_participant?.power_cart_variant_id ||
                                              currentParticipant?.power_cart_fee_loading ||
                                              orderProgress.orderStep === OrderProgress.CREATING_CART ||
                                              !!order
                                            }
                                          />
                                        ) : null}
                                      </div>
                                    </RightClickMenu>

                                    {!(
                                      slot?.booking_participant?.green_fee_paid &&
                                      slot?.booking_participant?.power_cart_paid
                                    ) && (
                                      <div>
                                        <Button
                                          className="ml-auto"
                                          type="secondary"
                                          // style={{
                                          //   backgroundColor: "white",
                                          //   borderColor: "#e7e7e7",
                                          //   color: "#667085",
                                          // }}
                                          size="medium"
                                          icon={<FontAwesomeIcon icon={["fal", "angles-right"]} />}
                                          onClick={e =>
                                            void addFeeHandler(
                                              slot,
                                              {
                                                id: slot?.booking_participant?.green_fee_variant_id,
                                                variantTitle: slot?.booking_participant?.green_fee_variant_title,
                                                productTitle: slot?.booking_participant?.green_fee_product_title,
                                              },
                                              {
                                                id: slot?.booking_participant?.power_cart_variant_id,
                                                variantTitle: slot?.booking_participant?.power_cart_variant_title,
                                                productTitle: slot?.booking_participant?.power_cart_product_title,
                                              },
                                              e,
                                            )
                                          }
                                          disabled={
                                            (slot?.booking_participant?.green_fee_paid &&
                                              slot?.booking_participant?.power_cart_paid) ||
                                            (green_fee_added && power_cart_fee_added) ||
                                            orderProgress.orderStep === OrderProgress.CREATING_CART ||
                                            !!order
                                          }
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>

                              {/* NOTES TO BE DISPLAYED HERE */}
                              {index == 0 && (
                                <div className="mt-2 flex flex-col gap-2">
                                  {slot.status === "booked" && notes
                                    ? notes.map((note: any, noteIndex: number) => {
                                        return (
                                          <Fragment key={noteIndex}>
                                            {note.map((props: any, propIndex: number) => {
                                              return (
                                                <Fragment key={propIndex}>
                                                  {props.tee_time_booking_id == slot.tee_time_booking_id ? (
                                                    <Note
                                                      key={propIndex}
                                                      authorName={props.author?.full_name}
                                                      content={props.content}
                                                      dateString={moment(props.created_at).format("LLL")}
                                                      onRemove={e => removeBookingNote(props.id)}
                                                      quickNote
                                                    />
                                                  ) : null}
                                                </Fragment>
                                              );
                                            })}
                                          </Fragment>
                                        );
                                      })
                                    : null}
                                </div>
                              )}
                            </div>
                          ) : null}
                        </Fragment>
                      );
                    })}
                </div>

                {/* Non Booked Player */}
                {teeSheetStore.selectedTeeTime?.quantity_remaining > 0 ? (
                  <div
                    className={classNames(
                      "border-b cursor-pointer rounded-xl mb-2 p-4 booking-display-new-add-golfer",
                      {
                        "booking-display-new-disable-add-golfer":
                          !!order || orderProgress.orderStep !== OrderProgress.NEW_ORDER,
                      },
                    )}
                    onClick={
                      !!order || orderProgress.orderStep !== OrderProgress.NEW_ORDER
                        ? undefined
                        : () => props.initiateEditBookingHandler(booking, true)
                    }
                  >
                    <div className="teesheet-card-content">
                      <div className="teesheet-card-header">
                        <div className="flex bg-gray-200 h-10 w-10 rounded self-start justify-center align-center mr-4 ml-0">
                          <FontAwesomeIcon icon={["fal", "plus"]} className="text-xl self-center" />
                        </div>
                        <div>
                          <div className="teesheet-card-player-name">
                            {t("secure.facility.tee_sheet.tee_sheet_booking_display.011")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            );
          })}
      </div>

      {/* Translations Needed for Sheet Below */}

      <Sheet
        size="small"
        closable
        title={t("secure.facility.tee_sheet.tee_sheet_booking_display.016")}
        cancelText={t("secure.facility.tee_sheet.tee_sheet_booking_display.017")}
        open={editCustomerState.customerEditVisible}
        okText={t("secure.facility.tee_sheet.tee_sheet_booking_display.018")}
        onOk={() => updateCustomer()}
        onCancel={() => {
          setEditCustomerState(prevState => ({
            ...prevState,
            customerEditVisible: false,
            customerMemberships: null,
          }));
        }}
        okDisabled={
          editCustomerState.customerFirstName === "" ||
          editCustomerState.customerLastName === "" ||
          (editCustomerState.customerEmail === "" && editCustomerState.customerPhone === "")
        }
      >
        <div className="customer-image-container mb-4">
          <img
            className="w-36 h-36"
            src={
              editCustomerState.customer_image !== null
                ? editCustomerState.customer_image
                : "/public/images/default_profile_image.svg"
            }
            alt="customer_image"
          />

          <p>{editCustomerState.customerType || ""}</p>
          <p>{editCustomerState.cartCustomerType || ""}</p>
          <p>{editCustomerState.customerMemberNumber || ""}</p>
        </div>

        <FormLayout>
          <FormLayout.Group>
            <Input
              value={editCustomerState.customerFirstName}
              label={t("secure.facility.tee_sheet.tee_sheet_booking_display.019")}
              onChange={handleCustomerDetailsChange}
              id="customerFirstName"
            />
            <Input
              value={editCustomerState.customerLastName}
              label={t("secure.facility.tee_sheet.tee_sheet_booking_display.020")}
              onChange={handleCustomerDetailsChange}
              id="customerLastName"
            />
          </FormLayout.Group>
        </FormLayout>

        <FormLayout>
          <FormLayout.Group>
            <Input
              value={editCustomerState.customerEmail}
              label={t("secure.facility.tee_sheet.tee_sheet_booking_display.021")}
              onChange={handleCustomerDetailsChange}
              id="customerEmail"
            />
            <Input
              value={editCustomerState.customerPhone}
              label={t("secure.facility.tee_sheet.tee_sheet_booking_display.022")}
              onChange={handleCustomerDetailsChange}
              id="customerPhone"
            />
          </FormLayout.Group>
        </FormLayout>

        {/* <br /> */}

        <Tabs tabs={tabs} selected={selectedTab} onSelect={setSelectedTab}>
          <div style={{ display: selectedTab === 0 ? "block" : "none", paddingTop: "8px" }}>
            <h1 className="mb-2 ui-customer_page-header">Memberships</h1>
            {editCustomerState.customerMemberships && editCustomerState.customerMemberships.data.length >= 1 ? (
              editCustomerState.customerMemberships.data
                .filter((membership: any) => membership.status === "active")
                .map((membership: any, index: number) => {
                  return (
                    <div key={index}>
                      <MembershipDisplay
                        title={membership.membership_title}
                        season={membership.season}
                        homeCourse={membership.home_facility_long_name}
                        memberNumber={membership.member_number}
                        expiry={membership.expiry}
                        status={membership.status_readable}
                        parent_membership={membership.parent_membership}
                      />
                    </div>
                  );
                })
            ) : (
              <Callout
                type="info"
                title="No Active Memberships"
                content="There are no active memberships attached to this customer."
              />
            )}
          </div>
          <div style={{ display: selectedTab === 1 ? "block" : "none", paddingTop: "8px" }}>
            <div className="flex flex-column gap-2">
              {selectedTab == 1 && (
                <>
                  {noteState.notes?.map((note: any, index: number) => {
                    return (
                      <Fragment key={index}>
                        <Note
                          key={index}
                          authorName={note.author?.full_name}
                          content={note.content}
                          dateString={moment(note.created_at).format("LLL")}
                        />
                      </Fragment>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </Tabs>
      </Sheet>

      <Popup
        open={noShowState.confirmationPopup}
        type="info"
        title={"Select No Show"}
        description={"Select the type of no show you would like to apply to this customer."}
        onOk={confirmNoShowSelection}
        okText={"Confirm"}
        cancelText={"Cancel"}
        onCancel={cancelNoShowSelection}
      >
        <Select label="" defaultValue={"Charge"} onChange={(value: string) => handleNoShowType(value)}>
          <Option value={"charge"} name={"Charge"}>
            <span>Charge</span>
          </Option>
          <Option value={"defer"} name={"Defer Charge"}>
            <span>Defer Charge</span>
          </Option>
          <Option value={"weather"} name={"Weather"}>
            <span>Weather</span>
          </Option>
        </Select>
      </Popup>

      <Sheet
        size="medium"
        height="flexible"
        closable
        title={"Power Cart Numbers"}
        cancelText={"Cancel"}
        open={props.powerCartModalVisible}
        okText={"Save"}
        overflow
        onOk={() => saveCartNumbers()}
        onCancel={() => props.togglePowerCartModalVisible(false)}
      >
        <div>
          <div>
            {teeSheetStore.selectedTeeTime?.slots
              .filter(slot => slot.booking_participant !== null)
              .map((slot, index: number) => {
                return (
                  <div className="mb-2" key={index}>
                    <FormLayout>
                      <FormLayout.Group>
                        <div>
                          <div className="text-xl text-semibold mr-1">{slot.booking_participant?.full_name}</div>
                          <div className="text-sm text-subdued text-semibold">
                            <span className="mr-2">
                              <span className="text-semibold">
                                {slot.booking_participant.guest && (
                                  <span>{t("secure.facility.tee_sheet.tee_sheet_booking_display.015")}</span>
                                )}
                              </span>
                              <span>
                                {!slot.booking_participant.guest && (
                                  <>
                                    <span>{slot.booking_participant.customer?.customer_type}</span>
                                    {slot.booking_participant.customer?.cart_customer_type && (
                                      <span>{`(${String(
                                        slot.booking_participant.customer?.cart_customer_type,
                                      )})`}</span>
                                    )}
                                  </>
                                )}
                              </span>
                            </span>
                          </div>
                        </div>
                        <Input
                          key={index}
                          defaultValue={slot.booking_participant.power_cart_number}
                          label={"Cart Number"}
                          onChange={(e: any) => handleCartNumberChange(e, index)}
                          id={powerCartNumbersState.power_cart_numbers[index]}
                          type="number"
                        />
                        <Select
                          label="Seat Number"
                          defaultValue={slot.booking_participant.seat_number}
                          onChange={(value: number) => handleSeatNumberChange(value, index)}
                        >
                          <Option value={1} name={"1st Seat"}>
                            <span>1st Seat</span>
                          </Option>
                          <Option value={2} name={"2nd Seat"}>
                            <span>2nd Seat</span>
                          </Option>
                        </Select>
                      </FormLayout.Group>
                    </FormLayout>
                  </div>
                );
              })}
          </div>
        </div>
      </Sheet>

      <Portal isMounted={editName.modalOpen}>
        <Sheet
          size="small"
          height="flexible"
          closable
          title={`Edit Name of ${editName.selectedBookingParticipant?.full_name}`}
          open={editName.modalOpen}
          okText="Update"
          onOk={handleEditName}
          okDisabled={editName?.newName?.split(" ")?.length < 2}
          onCancel={() => {
            setEditName(prevState => ({
              ...prevState,
              newName: "",
              selectedBookingParticipant: null,
              modalOpen: false,
            }));
          }}
        >
          <div className="p-2">
            <Input
              autoFocus
              label="Name"
              onChange={handleEditNameChange}
              value={editName?.newName ?? ""}
              placeholder={"Enter first and last name..."}
              id="newName"
            />
          </div>
        </Sheet>
      </Portal>

      {powerCartNumbersState.cartNumberModalVisible ? (
        <Sheet
          size="small"
          height="flexible"
          closable
          title={"Power Cart Number"}
          cancelText={"Cancel"}
          open={powerCartNumbersState.cartNumberModalVisible}
          okText={"Add Cart"}
          overflow
          onOk={(e: any) => {
            void addFeeHandler(
              powerCartNumbersState.currentSlot,
              null,
              {
                id: powerCartNumbersState.currentSlot?.booking_participant?.power_cart_variant_id,
                variantTitle: powerCartNumbersState.currentSlot?.booking_participant?.power_cart_variant_title,
                productTitle: powerCartNumbersState.currentSlot?.booking_participant?.power_cart_product_title,
              },
              e,
            );
            void saveCartNumber(
              powerCartNumbersState.currentSlot.booking_participant,
              powerCartNumbersState.currentSlot.position - 1,
            );
          }}
          onCancel={() => {
            setPowerCartNumbersState(prevState => ({
              ...prevState,
              cartNumberModalVisible: false,
            }));
          }}
        >
          <FormLayout>
            <FormLayout.Group>
              <div>
                <div className="text-xl text-semibold mr-1">
                  {powerCartNumbersState.currentSlot?.booking_participant?.full_name}
                </div>
                <div className="text-sm text-subdued text-semibold">
                  <span className="mr-2">
                    <span className="text-semibold">
                      {powerCartNumbersState.currentSlot?.booking_participant?.guest && (
                        <span>{t("secure.facility.tee_sheet.tee_sheet_booking_display.015")}</span>
                      )}
                    </span>
                    <span>
                      {!powerCartNumbersState.currentSlot?.booking_participant?.guest && (
                        <>
                          <span>{powerCartNumbersState.currentSlot?.booking_participant?.customer?.customer_type}</span>
                          {powerCartNumbersState.currentSlot?.booking_participant?.customer?.cart_customer_type && (
                            <span>{`(${String(
                              powerCartNumbersState.currentSlot.booking_participant.customer?.cart_customer_type,
                            )})`}</span>
                          )}
                        </>
                      )}
                    </span>
                  </span>
                </div>
              </div>
            </FormLayout.Group>
            <FormLayout.Group>
              <Input
                key={0}
                defaultValue={powerCartNumbersState.currentSlot?.booking_participant?.power_cart_number}
                label={"Cart Number"}
                onChange={(e: any) => handleCartNumberChange(e, powerCartNumbersState.currentSlot?.position - 1)}
                id={powerCartNumbersState.power_cart_numbers[powerCartNumbersState.currentSlot?.position - 1]}
                type="number"
                autoFocus
              />
              <Select
                label="Seat Number"
                defaultValue={powerCartNumbersState.currentSlot?.booking_participant?.seat_number}
                onChange={(value: number) =>
                  handleSeatNumberChange(value, powerCartNumbersState.currentSlot?.position - 1)
                }
              >
                <Option key={1} value={1} name={"1st Seat"}>
                  <span>1st Seat</span>
                </Option>
                <Option key={2} value={2} name={"2nd Seat"}>
                  <span>2nd Seat</span>
                </Option>
              </Select>
            </FormLayout.Group>
          </FormLayout>
        </Sheet>
      ) : null}
    </>
  );
};

export default NewTeeSheetBookingDisplay;
