import InternalLayout, { IBasicProps, Content, Footer, Header } from "./layout";
import Sider from "./Sider";

export { IBasicProps as ILayoutProps } from "./layout";
export { ISiderProps } from "./Sider";

interface ILayoutType extends React.FC<IBasicProps> {
  Header: typeof Header;
  Footer: typeof Footer;
  Content: typeof Content;
  Sider: typeof Sider;
}

const Layout = InternalLayout as ILayoutType;

Layout.Header = Header;
Layout.Footer = Footer;
Layout.Content = Content;
Layout.Sider = Sider;

export default Layout;
