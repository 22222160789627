import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";

import { StatusCode } from "api/protocols";
import { PostInvoiceTransaction, PutCaptureInvoiceTransaction } from "api/rpc/2024-04/facilityAdmin/order/invoice";

import { dequeue, enqueue, showError, showSuccess } from "redux/actions/ui";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useStripeTerminalPayment } from "hooks/useStripeTerminalPayment/useStripeTerminalPayment";
import {
  clearReaderDisplay,
  sheetInfo,
  processInvoicePayment as stripeProcessPayment,
} from "helpers/StripeTerminalWrapper";

import Input from "components/form/input/Input";
import Sheet from "components/sheet/Sheet";
import { ButtonNew as Button } from "components/buttonNew";
import Popup from "components/popup/Popup";
import CheckoutForm, { cancelManualCreditPaymentIntent } from "components/checkoutForm/CheckoutForm";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import TextField from "components/form/textField/TextField";
import { Select } from "components/select/index";
import Icon from "components/icon/Icon";
import { capitalize } from "helpers/Helpers";
import CreditBookModal from "components/creditBookModal/CreditBookModal";

import { IStripePaymentState } from "pages/secure/facility/teesheet/TeeSheet";
import PaymentMethods from "elements/register/cartMenu/PaymentMethods";
import CreditCardCatalogue from "elements/teesheet/creditCard/CreditCardCatalogue";
import { ICreditCard } from "pages/guest/Customer/CustomerProfile";
import { IInvoice } from "redux/reducers/models/order";
import { IPrizeAccount } from "../../settings/prizeAccounts/PrizeAccount";

interface IAccountPaymentState {
  paymentAmount: string;
  paymentOption: Record<string, any>;
  transaction: Record<string, any>;
  clientSecret: string;
  manualCardModalVisible: boolean;
  elementComplete: {
    cardNumber: boolean;
    cardExpiry: boolean;
    cardCvc: boolean;
  };
  giftCardModalVisible: boolean;
  giftCardCode: string;
  giftCardPin: string;
  selectedCustomerPaymentMethod: number;
  showCustomerPaymentMethods: boolean;
  note: string;
  selected_event: any;
  creditBookModalVisible: boolean;
  creditBookId: number;
}

type HouseAccountPaymentModalProps = {
  paymentModalVisible: boolean;
  invoice?: IInvoice;
  paymentMethods: ICreditCard[];
  onOk: () => void;
  /** Closes the base payment modal. */
  closePaymentModal?: () => void;
  depositOnly?: boolean;
  onEventSearch?: (search: string) => void;
  events?: Array<any>;
  eventSearchValue?: string;
};

export default function InvoicePaymentModal(props: HouseAccountPaymentModalProps) {
  const stripe = useStripe();
  const elements = useElements();
  const { processPayment: processTerminalPayment, StripeTerminalSheet, paymentStatus } = useStripeTerminalPayment();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { facilityStore, terminalStore } = useAppSelector(store => store);
  const { Option } = Select;

  const { invoice } = props;

  const [manualProcessing, setManualProcessing] = useState<boolean>(false);
  const [submitTrigger, setSubmitTrigger] = useState<boolean>(false);
  const [paymentInProgress, setPaymentInProgress] = useState<boolean>(false);

  const [paymentState, setPaymentState] = useState<IAccountPaymentState>({
    paymentAmount: String(getDefaultPaymentAmount()),
    paymentOption: null,
    transaction: undefined,
    clientSecret: "",
    manualCardModalVisible: false,
    elementComplete: {
      cardNumber: false,
      cardExpiry: false,
      cardCvc: false,
    },
    giftCardModalVisible: false,
    giftCardCode: "",
    giftCardPin: "",
    selectedCustomerPaymentMethod: undefined,
    showCustomerPaymentMethods: false,
    note: "",
    selected_event: null,
    creditBookModalVisible: false,
    creditBookId: null,
  });

  const [stripeState, setStripeState] = useState<IStripePaymentState>({
    onCancel: () => {},
    onOk: () => {},
    cancelText: "",
    okText: "",
    sheetVisible: false,
    titleText: "",
    message: "",
    convertedCode: "",
    processing: false,
    success: false,
  });

  const elementsComplete =
    paymentState.elementComplete.cardNumber === true &&
    paymentState.elementComplete.cardExpiry === true &&
    paymentState.elementComplete.cardCvc === true;

  const temp = Boolean(paymentState.selectedCustomerPaymentMethod) || elementsComplete;

  // refresh parent once terminal paymentStatus is completed
  useEffect(() => {
    if (paymentStatus === "completed") {
      props.onOk();
      props.closePaymentModal();
    }
  }, [paymentStatus]);

  useEffect(() => {
    if (paymentState.creditBookId) {
      void processPayment();
    }
    return () => {
      setPaymentState(prevState => ({ ...prevState, creditBookId: null }));
    };
  }, [paymentState.creditBookId]);

  function getDefaultPaymentAmount(): number {
    let total = props.invoice?.total_price;

    for (let i = 0; i < props.invoice?.transactions.length; i++) {
      total -= props.invoice?.transactions[i].amount;
    }

    if (total < 0) {
      total = 0;
    }

    return total;
  }

  async function processPayment() {
    dispatch(enqueue());
    setPaymentInProgress(true);
    try {
      if (
        paymentState.paymentAmount === "" ||
        !Number(paymentState.paymentAmount) ||
        Number.isNaN(paymentState.paymentAmount)
      ) {
        setPaymentInProgress(false);
        dispatch(showError(`'${paymentState.paymentAmount}' is not a valid amount`));
        dispatch(dequeue());
        return;
      }

      if (props.depositOnly && !paymentState.selected_event) {
        setPaymentInProgress(false);
        dispatch(showError("No event selected"));
        dispatch(dequeue());
        return;
      }

      if (paymentState.paymentOption?.payment_method === "manual_card") {
        const transactionRes = await PostInvoiceTransaction(
          {
            invoice_id: invoice?.id,
            kind: props.invoice?.financial_status === "draft" || props.depositOnly ? "deposit" : "invoice_payment",
            amount: Number(paymentState.paymentAmount),
            payment_option_id: paymentState.paymentOption?.id,
            league_id:
              paymentState.selected_event?.event_type === "league"
                ? paymentState.selected_event?.id
                : invoice?.league_id
                ? invoice?.league_id
                : undefined,
            tournament_id:
              paymentState.selected_event?.event_type === "tournament"
                ? paymentState.selected_event?.id
                : invoice?.tournament_id
                ? invoice?.tournament_id
                : undefined,
            hospitality_event_id:
              paymentState.selected_event?.event_type === "hospitality"
                ? paymentState.selected_event?.id
                : invoice?.hospitality_event_id
                ? invoice?.hospitality_event_id
                : undefined,
          },
          true,
        );

        if (transactionRes.status === StatusCode.OK) {
          setPaymentState(prevState => ({
            ...prevState,
            transaction: transactionRes.data,
            clientSecret: transactionRes.data.client_secret,
            manualCardModalVisible: true,
            paymentModalVisible: false,
          }));
        } else {
          setPaymentInProgress(false);
          dispatch(showError(t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.001")));
          dispatch(dequeue());
          return;
        }
      } else if (paymentState.paymentOption?.payment_method === "card") {
        if (facilityStore.facility.terminal_integration === "server") {
          setPaymentState(prevState => ({ ...prevState, paymentModalVisible: false }));
          await processTerminalPayment(
            invoice?.financial_status !== "draft" ? invoice?.id : null,
            paymentState.paymentOption,
            Number(paymentState.paymentAmount),
            false,
            true,
            paymentState.selected_event?.event_type === "league"
              ? paymentState.selected_event?.id
              : invoice?.league_id
              ? invoice?.league_id
              : undefined,
            paymentState.selected_event?.event_type === "tournament"
              ? paymentState.selected_event?.id
              : invoice?.tournament_id
              ? invoice?.tournament_id
              : undefined,
            paymentState.selected_event?.event_type === "hospitality"
              ? paymentState.selected_event?.id
              : invoice?.hospitality_event_id
              ? invoice?.hospitality_event_id
              : undefined,
          );
        } else {
          ReactDOM.unstable_batchedUpdates(() => {
            setPaymentState(prevState => ({ ...prevState, paymentModalVisible: false }));

            setStripeState(prevState => ({
              ...prevState,
              sheetVisible: true,
              onCancel: undefined,
              okText: t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.002"),
              processing: true,
              onOk: async () => {
                setStripeState(prevState => ({
                  ...prevState,
                  sheetVisible: false,
                  onCancel: undefined,
                  okText: undefined,
                  processing: false,
                  onOk: () => {},
                  titleText: "",
                  success: false,
                  message: "",
                }));
                await clearReaderDisplay();
              },
              titleText: t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.003"),
              message: t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.004"),
            }));
          });

          await stripeProcessPayment(
            invoice?.id,
            Number(paymentState.paymentAmount),
            paymentState.paymentOption,
            handleStripeSuccess,
            handleStripeError,
            paymentState.selected_event?.event_type === "league"
              ? paymentState.selected_event?.id
              : invoice?.league_id
              ? invoice?.league_id
              : undefined,
            paymentState.selected_event?.event_type === "tournament"
              ? paymentState.selected_event?.id
              : invoice?.tournament_id
              ? invoice?.tournament_id
              : undefined,
            paymentState.selected_event?.event_type === "hospitality"
              ? paymentState.selected_event?.id
              : invoice?.hospitality_event_id
              ? invoice?.hospitality_event_id
              : undefined,
          );
        }
      } else if (paymentState.paymentOption?.payment_method === "gift_card") {
        const transactionRes = await PostInvoiceTransaction(
          {
            invoice_id: invoice?.id,
            kind: props.invoice?.financial_status === "draft" || props.depositOnly ? "deposit" : "invoice_payment",
            amount: Number(paymentState.paymentAmount),
            payment_option_id: paymentState.paymentOption?.id,
            league_id:
              paymentState.selected_event?.event_type === "league"
                ? paymentState.selected_event?.id
                : invoice?.league_id
                ? invoice?.league_id
                : undefined,
            tournament_id:
              paymentState.selected_event?.event_type === "tournament"
                ? paymentState.selected_event?.id
                : invoice?.tournament_id
                ? invoice?.tournament_id
                : undefined,
            hospitality_event_id:
              paymentState.selected_event?.event_type === "hospitality"
                ? paymentState.selected_event?.id
                : invoice?.hospitality_event_id
                ? invoice?.hospitality_event_id
                : undefined,
            gift_card_code: paymentState.giftCardCode,
            gift_card_pin: paymentState.giftCardPin,
          },
          true,
        );
        if (transactionRes.status === StatusCode.OK) {
          setPaymentState(prevState => ({
            ...prevState,
            giftCardModalVisible: false,
            giftCardCode: null,
            giftCardPin: null,
          }));
          dispatch(showSuccess(t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.005")));
          props.onOk();
          props.closePaymentModal();
        }
      } else if (paymentState.paymentOption?.payment_method === "credit_book") {
        const transactionRes = await PostInvoiceTransaction(
          {
            invoice_id: invoice?.id,
            kind: props.invoice?.financial_status === "draft" || props.depositOnly ? "deposit" : "invoice_payment",
            amount: Number(paymentState.paymentAmount),
            payment_option_id: paymentState.paymentOption?.id,
            league_id:
              paymentState.selected_event?.event_type === "league"
                ? paymentState.selected_event?.id
                : invoice?.league_id
                ? invoice?.league_id
                : undefined,
            tournament_id:
              paymentState.selected_event?.event_type === "tournament"
                ? paymentState.selected_event?.id
                : invoice?.tournament_id
                ? invoice?.tournament_id
                : undefined,
            hospitality_event_id:
              paymentState.selected_event?.event_type === "hospitality"
                ? paymentState.selected_event?.id
                : invoice?.hospitality_event_id
                ? invoice?.hospitality_event_id
                : undefined,
            credit_book_id: paymentState.creditBookId,
          },
          true,
        );
        if (transactionRes.status === StatusCode.OK) {
          setPaymentState(prevState => ({
            ...prevState,
            creditBookModalVisible: false,
            creditBookId: null,
          }));
          dispatch(showSuccess(t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.005")));
          props.onOk();
          props.closePaymentModal();
        }
      } else {
        //Cash & Moneris payments
        const transactionRes = await PostInvoiceTransaction(
          {
            invoice_id: invoice?.id,
            kind: props.invoice?.financial_status === "draft" || props.depositOnly ? "deposit" : "invoice_payment",
            amount: Number(paymentState.paymentAmount),
            payment_option_id: paymentState.paymentOption?.id,
            league_id:
              paymentState.selected_event?.event_type === "league"
                ? paymentState.selected_event?.id
                : invoice?.league_id
                ? invoice?.league_id
                : undefined,
            tournament_id:
              paymentState.selected_event?.event_type === "tournament"
                ? paymentState.selected_event?.id
                : invoice?.tournament_id
                ? invoice?.tournament_id
                : undefined,
            hospitality_event_id:
              paymentState.selected_event?.event_type === "hospitality"
                ? paymentState.selected_event?.id
                : invoice?.hospitality_event_id
                ? invoice?.hospitality_event_id
                : undefined,
          },
          true,
        );

        if (transactionRes.status !== StatusCode.OK) {
          setPaymentInProgress(false);
          dispatch(showError(t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.006")));
          dispatch(dequeue());
          return;
        }
        dispatch(showSuccess("Invoice payment created successfully"));
        props.onOk();
        props.closePaymentModal();
      }
    } catch (e) {
      console.log(e);
      dispatch(showError(t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.008")));
    }
    setPaymentState(prevState => ({
      ...prevState,
      paymentAmount: "0",
      selected_event_id: null,
      selected_event_type: null,
    }));
    setPaymentInProgress(false);
    dispatch(dequeue());
  }

  async function handleProcessCustomerCard() {
    if (!stripe) {
      console.log("Stripe setup error");
      return;
    }
    const stripePaymentMethod = props.paymentMethods.find(
      method => method.id === paymentState.selectedCustomerPaymentMethod,
    );
    dispatch(enqueue());
    const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(paymentState.clientSecret, {
      payment_method: stripePaymentMethod.stripe_payment_method_id,
    });
    if (stripeError) {
      dispatch(showError(stripeError.message));
      dispatch(dequeue());
      return;
    }
    //Capture the payment
    const captureRes = await PutCaptureInvoiceTransaction(
      { payment_intent_id: paymentIntent.id, transaction_id: paymentState.transaction?.id },
      false,
    );
    if (captureRes.status !== StatusCode.OK) {
      dispatch(showError("Error capturing payment"));
      dispatch(dequeue());
      return;
    }
    dispatch(showSuccess("Payment captured successfully"));
    setPaymentState(prevState => ({
      ...prevState,
      showCustomerPaymentMethods: false,
      selectedCustomerPaymentMethod: undefined,
      manualCardModalVisible: false,
      transaction: undefined,
      clientSecret: "",
      paymentAmount: invoice?.total_price.toString(),
      elementComplete: {
        cardCvc: false,
        cardExpiry: false,
        cardNumber: false,
      },
    }));
    dispatch(dequeue());
    props.onOk();
    props.closePaymentModal();
  }

  function handleStripeSuccess() {
    setStripeState(prevState => ({
      ...prevState,
      sheetVisible: true,
      onCancel: undefined,
      okText: "Close",
      onOk: () => {
        setStripeState(prevState => ({
          ...prevState,
          sheetVisible: false,
          onCancel: undefined,
          okText: undefined,
          processing: false,
          onOk: () => {},
          titleText: "",
          message: "",
        }));
      },
      processing: false,
      success: true,
      titleText: t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.009"),
      message: t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.010"),
    }));

    setPaymentState({ ...paymentState, paymentAmount: "", paymentOption: null });
    dispatch(showSuccess(t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.011")));

    props.onOk();
    props.closePaymentModal(); // void loadHouseAccounts(true);
  }

  function handleStripeError(message: string, sheetInfo: sheetInfo) {
    //set sheet
    setStripeState(prevState => ({
      ...prevState,
      onCancel: () => {
        sheetInfo.onCancel();
        setStripeState(prevState => ({ ...prevState, sheetVisible: false }));
      },
      onOk: () => {
        sheetInfo.onOk();
        setStripeState(prevState => ({
          ...prevState,
          sheetVisible: true,
          onCancel: undefined,
          okText: "Cancel Payment",
          processing: true,
          onOk: async () => {
            setStripeState(prevState => ({
              ...prevState,
              sheetVisible: false,
              onCancel: undefined,
              okText: undefined,
              processing: false,
              onOk: () => {},
              titleText: "",
              success: false,
              message: "",
            }));
            await clearReaderDisplay();
          },
          titleText: t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.012"),
          message: t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.013"),
        }));
      },
      cancelText: sheetInfo.cancelText,
      okText: sheetInfo.okText,
      sheetVisible: true,
      titleText: sheetInfo.title,
      message: sheetInfo.message,
      convertedCode: sheetInfo.convertedErrorCode,
      success: false,
      processing: false,
    }));
  }

  // completing stripe card inputs auto-submits the onOk.  This handles the early call on that page
  function handleManualCardProcessing() {
    if (paymentState.selectedCustomerPaymentMethod) {
      void handleProcessCustomerCard();
    } else if (elementsComplete && !submitTrigger) {
      setSubmitTrigger(!submitTrigger);
    }
  }

  function handleCardSectionChange(
    e: StripeCardNumberElementChangeEvent | StripeCardExpiryElementChangeEvent | StripeCardCvcElementChangeEvent,
  ) {
    setPaymentState(prevState => ({
      ...prevState,
      elementComplete: { ...prevState.elementComplete, [e.elementType]: e.complete },
      selectedCustomerPaymentMethod: undefined,
      showCustomerPaymentMethods: false,
    }));
  }

  return (
    <>
      <StripeTerminalSheet />

      {/* Base Sheet */}
      {props.paymentModalVisible && (
        <Sheet
          open={props.paymentModalVisible}
          size="small"
          height="flexible"
          closable
          title={"Invoice Payment"} // TODO: Translation
          onCancel={() => props.closePaymentModal()}
          onOk={
            paymentState.paymentOption?.payment_method === "gift_card"
              ? () => setPaymentState({ ...paymentState, giftCardModalVisible: true })
              : paymentState.paymentOption?.payment_method === "credit_book"
              ? () => setPaymentState({ ...paymentState, creditBookModalVisible: true })
              : processPayment
          }
          cancelText={t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.015")}
          okText={
            paymentState.paymentOption?.payment_method === "gift_card"
              ? t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.016")
              : t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.017")
          }
          backDropCancel={false}
          okDisabled={
            paymentState.paymentOption === null ||
            !Number(paymentState.paymentAmount) ||
            Number(paymentState.paymentAmount) <= 0
              ? true
              : false
          }
          okLoading={paymentInProgress}
          overflow
        >
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <PaymentMethods
                  onChange={paymentOption =>
                    setPaymentState(prevState => ({
                      ...prevState,
                      paymentOption: paymentOption !== undefined ? paymentOption : null,
                    }))
                  }
                  paymentOptions={facilityStore.paymentOptions}
                  cardReaderAvailable={terminalStore?.reader === null ? false : true}
                  cart={undefined}
                  paymentAmount={Number(paymentState.paymentAmount)}
                  value={paymentState.paymentOption}
                />
              </FormLayout.Group>

              {props.depositOnly && (
                <FormLayout.Group>
                  {paymentState.selected_event ? (
                    <div>
                      <div>
                        <p className="event-label">Event</p> {/* TODO: Translation */}
                      </div>

                      <div className="selected-container">
                        <div className="event-name">
                          <div>{paymentState.selected_event.name}</div>

                          <div>
                            <button
                              className=""
                              onClick={() => setPaymentState(prevState => ({ ...prevState, selected_event: null }))}
                            >
                              <Icon style="far" icon="times" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Select
                      label="Event"
                      onChange={(value: any) => {
                        setPaymentState(prevState => ({
                          ...prevState,
                          selected_event: value,
                        }));
                        props.onEventSearch("");
                      }}
                      showSearch
                      searchValue={props.eventSearchValue}
                      onSearch={(value: string) => props.onEventSearch(value)}
                      showDropDownOnFocus={true}
                      searching={!props.events}
                      placeholder="Search events..."
                    >
                      {props.events?.map((event: any, index: number) => {
                        return (
                          <Option key={index} value={event}>
                            {`${String(event.name)} (${String(capitalize(event.event_type))})`}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </FormLayout.Group>
              )}

              <FormLayout.Group>
                <TextField
                  label={t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.018")}
                  name={t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.019")}
                  id="note"
                  onChange={({ target }: { target: EventTarget & HTMLTextAreaElement }) =>
                    setPaymentState({ ...paymentState, [target.id]: target.value })
                  }
                  value={paymentState.note}
                  rows={2}
                  maxLength={255}
                />
              </FormLayout.Group>

              <FormLayout.Group>
                <Input
                  prefix="$"
                  type="number"
                  label={t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.020")}
                  id="paymentAmount"
                  value={paymentState.paymentAmount}
                  onChange={({ target }: { target: EventTarget & HTMLInputElement }) =>
                    setPaymentState({ ...paymentState, paymentAmount: target.value })
                  }
                />
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Sheet>
      )}

      <>
        {stripeState.processing ? (
          <Popup
            type="info"
            open={stripeState.sheetVisible}
            title={t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.021")}
            description={t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.022")}
            closable
            onCancel={stripeState.onCancel}
            onOk={stripeState.onOk}
            cancelText={stripeState.cancelText}
            okText={stripeState.okText}
            backDropCancel={false}
          />
        ) : (
          <>
            {stripeState.success ? (
              <Popup
                type="success"
                open={stripeState.sheetVisible}
                title={t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.023")}
                description={t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.024")}
                closable
                onCancel={stripeState.onCancel}
                onOk={stripeState.onOk}
                cancelText={stripeState.cancelText}
                okText={stripeState.okText}
                backDropCancel={false}
              />
            ) : (
              <Popup
                type="error"
                open={stripeState.sheetVisible}
                title={t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.025")}
                description={stripeState.message + " - Error Code: " + stripeState.convertedCode}
                closable
                onCancel={stripeState.onCancel}
                onOk={stripeState.onOk}
                cancelText={stripeState.cancelText}
                okText={stripeState.okText}
                backDropCancel={false}
              />
            )}
          </>
        )}
      </>

      {/* GIFT CARD */}
      <Sheet
        title={t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.026")}
        open={paymentState.giftCardModalVisible}
        size="small"
        closable
        stacked
        backDropCancel={false}
        onCancel={() =>
          setPaymentState(prevState => ({
            ...prevState,
            giftCardModalVisible: false,
            giftCardCode: "",
            giftCardPin: "",
          }))
        }
        onOk={processPayment}
        okText={t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.027")}
        cancelText={t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.028")}
        okDisabled={paymentState.giftCardCode === "" && paymentState.giftCardPin ? true : false}
      >
        <Input
          value={paymentState.giftCardCode}
          placeholder={t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.029")}
          id="giftCardCode"
          onChange={({ target }: { target: EventTarget & HTMLInputElement }) =>
            setPaymentState({ ...paymentState, [target.id]: target.value })
          }
        />
        <Input
          value={paymentState.giftCardPin}
          placeholder={t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.030")}
          id="giftCardPin"
          onChange={({ target }: { target: EventTarget & HTMLInputElement }) =>
            setPaymentState({ ...paymentState, [target.id]: target.value })
          }
        />
      </Sheet>

      {/* CREDIT BOOK */}
      <CreditBookModal
        loadCreditBooks={paymentState.creditBookModalVisible}
        onCancel={() =>
          setPaymentState(prevState => ({ ...prevState, creditBookModalVisible: false, creditBookId: null }))
        }
        onOk={(selectedCreditBook: IPrizeAccount) =>
          setPaymentState({ ...paymentState, creditBookId: selectedCreditBook.id })
        }
        customerId={invoice?.customer_id}
        // searchAccountPermission={permissions?.credit}
      />

      {/* MANUAL CARD */}
      <Sheet
        size={"small"}
        closable
        title={t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.031")}
        cancelText={t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.032")}
        open={paymentState.manualCardModalVisible}
        okText={t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.033")}
        okDisabled={paymentState.selectedCustomerPaymentMethod ? false : !elementsComplete}
        // cancelDisabled={manualProcessing}
        backDropCancel={false}
        onOk={handleManualCardProcessing}
        stacked
        onCancel={async () => {
          await cancelManualCreditPaymentIntent(
            paymentState.transaction?.payment_intent_id,
            paymentState.transaction?.id,
            false,
            true,
          );
          elements.getElement(CardNumberElement)?.clear();
          elements.getElement(CardExpiryElement)?.clear();
          elements.getElement(CardCvcElement)?.clear();
          setPaymentState(prevState => ({
            ...prevState,
            selectedCustomerPaymentMethod: undefined,
            manualCardModalVisible: !paymentState.manualCardModalVisible,
            showCustomerPaymentMethods: false,
            transaction: undefined,
            clientSecret: "",
            elementComplete: {
              cardCvc: false,
              cardExpiry: false,
              cardNumber: false,
            },
          }));
        }}
      >
        <div style={{ minHeight: "300px" }}>
          {/* Hide inputs when saved payment methods are displayed */}
          {!paymentState.showCustomerPaymentMethods && (
            <CheckoutForm
              onChange={handleCardSectionChange}
              onSubmit={() => setManualProcessing(!manualProcessing)}
              trigger={submitTrigger}
              transaction={paymentState.transaction}
              onError={(message: string) => {
                dispatch(showError(message));
                setManualProcessing(false);
                setSubmitTrigger(false);
              }}
              invoiceTransaction={true}
              onSuccess={() => {
                // Clean values
                dispatch(
                  showSuccess(t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.034")),
                );
                setPaymentState(prevState => ({
                  ...prevState,
                  manualCardModalVisible: false,
                  transaction: undefined,
                  elementComplete: {
                    cardCvc: false,
                    cardExpiry: false,
                    cardNumber: false,
                  },
                }));
                setSubmitTrigger(false);
                props.onOk();
                props.closePaymentModal();
              }}
            />
          )}

          <div>
            {paymentState.showCustomerPaymentMethods && (
              <CreditCardCatalogue
                customerId={props.invoice?.customer_id}
                creditCards={props.paymentMethods}
                selectedCard={Number(paymentState.selectedCustomerPaymentMethod) ?? undefined}
                setSelectedCard={paymentId =>
                  setPaymentState({
                    ...paymentState,
                    selectedCustomerPaymentMethod:
                      paymentId === paymentState.selectedCustomerPaymentMethod ? undefined : paymentId,
                  })
                }
                backButtonAction={() =>
                  setPaymentState(prevState => ({
                    ...prevState,
                    showCustomerPaymentMethods: false,
                    selectedCustomerPaymentMethod: undefined,
                  }))
                }
              />
            )}
          </div>

          {/* Toggles between checkout form and customer payment methods */}
          {props.paymentMethods?.length > 0 && !paymentState.showCustomerPaymentMethods && (
            <Button
              type="text"
              onClick={() => {
                setPaymentState(prevState => ({
                  ...prevState,
                  showCustomerPaymentMethods: true,
                  selectedCustomerPaymentMethod: undefined,
                  elementComplete: {
                    cardCvc: false,
                    cardExpiry: false,
                    cardNumber: false,
                  },
                }));
              }}
              className="mt-2 mr-auto"
            >
              <span>{t("secure.facility.customer.tabs.house_accounts.house_account_payment_modal.035")}</span>
            </Button>
          )}
        </div>
      </Sheet>
    </>
  );
}
