import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";

import Card from "components/card/Card";
import Page from "components/page/Page";
import { StatusCode } from "api/protocols";
import { useTranslation } from "react-i18next";
import Tabs from "components/tabs/Tabs";
import { GetTournament } from "api/rpc/2024-04/facilityAdmin/tournament/tournament";
import { GetLeague } from "api/rpc/2024-04/facilityAdmin/league/league";
import { Select } from "components/select/index";
import { ITournament } from "redux/reducers/models/tournament";
import { ILeague } from "redux/reducers/models/league";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { showError } from "redux/actions/ui";
import Icon from "components/icon/Icon";
import { PostCreditBook } from "api/rpc/2022-09/facilityAdmin/client/creditBook";
import Input from "components/form/input/Input";
import { divide } from "lodash";

interface INewPrizeAccountState {
  tournamentSearch: string;
  leagueSearch: string;
  newPrizeAccountVisible: boolean;
  tournaments: Array<ITournament>;
  selectedTournament: ITournament;
  tournamentSearching: boolean;
  leagues: Array<ILeague>;
  leagueSearching: boolean;
  selectedLeague: ILeague;
  title: string;
}

export default function PrizeAccountNew() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { Option } = Select;
  const dispatch = useAppDispatch();
  const { facilityStore } = useAppSelector(state => state);
  const { leagueId, tournamentId }: any = useParams();

  const [leagues, setLeagues] = useState<Array<ILeague>>([]);
  const [tournaments, setTournaments] = useState<Array<ITournament>>([]);
  const [selected, setSelected] = useState(0);

  const [prizeAccountState, setPrizeAccountState] = useState<INewPrizeAccountState>({
    tournamentSearch: "",
    leagueSearch: "",
    newPrizeAccountVisible: false,
    tournaments: [],
    selectedTournament: null,
    tournamentSearching: false,
    leagues: [],
    leagueSearching: false,
    selectedLeague: null,
    title: "",
  });

  useEffect(() => {
    void loadLeagues();
    void loadTournaments();
  }, []);

  async function loadTournaments() {
    try {
      const tournamentRes = await GetTournament(null, true);

      if (tournamentRes.status === StatusCode.OK) {
        setTournaments(tournamentRes.data);
      } else {
        dispatch(showError(tournamentRes.message));
      }
    } catch (e) {
      dispatch(showError(e));
    }
  }

  async function loadLeagues() {
    try {
      const leagueRes = await GetLeague(null, true);
      if (leagueRes.status === StatusCode.OK) {
        setLeagues(leagueRes.data);
      } else {
        dispatch(showError(leagueRes.message));
      }
    } catch (e) {
      dispatch(showError(e));
    }
  }

  async function loadSearchedTournaments(search: string) {
    const params = {
      search: search,
    };

    const tournamentRes = await GetTournament(params, false);

    if (tournamentRes.status !== StatusCode.OK) {
      dispatch(showError(tournamentRes.message));
      return [];
    }

    return tournamentRes.data;
  }

  async function loadSearchedLeagues(search: string) {
    const params = {
      search: search,
    };

    const leagueRes = await GetLeague(params, false);

    if (leagueRes.status !== StatusCode.OK) {
      dispatch(showError(leagueRes.message));

      return [];
    }

    return leagueRes.data;
  }

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (async () => {
          if (prizeAccountState.tournamentSearch !== "") {
            try {
              setPrizeAccountState(prevState => ({ ...prevState, tournamentSearching: true }));
              const tournaments = await loadSearchedTournaments(prizeAccountState.tournamentSearch);
              if (mounted) {
                setPrizeAccountState(prevState => ({
                  ...prevState,
                  tournaments: tournaments,
                  tournamentSearching: false,
                }));
              }
            } catch (error) {
              console.log("err", error);
            }
            return;
          } else {
            setPrizeAccountState(prevState => ({ ...prevState, tournaments: [], tournamentSearching: false }));
          }
        })();
      }, 1000);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [prizeAccountState.tournamentSearch]);

  //Load Leagues
  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (async () => {
          if (prizeAccountState.leagueSearch !== "") {
            try {
              setPrizeAccountState(prevState => ({ ...prevState, leagueSearching: true }));
              const leagues = await loadSearchedLeagues(prizeAccountState.leagueSearch);
              if (mounted) {
                setPrizeAccountState(prevState => ({ ...prevState, leagues: leagues, leagueSearching: false }));
              }
            } catch (error) {
              console.log("err", error);
            }
            return;
          } else {
            setPrizeAccountState(prevState => ({ ...prevState, leagues: [], leagueSearching: false }));
          }
        })();
      }, 1000);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [prizeAccountState.leagueSearch]);

  async function handleCreatePrizeAccount() {
    if (prizeAccountState.title === "") {
      dispatch(showError(t("secure.facility.settings.prize_accounts.prize_accounts_new.001")));
      return;
    }

    const params = {
      title: prizeAccountState.title,
      type: "prize_account" as const,
      league_id: leagueId ? leagueId : prizeAccountState.selectedLeague ? prizeAccountState.selectedLeague.id : null,
      tournament_id: tournamentId
        ? tournamentId
        : prizeAccountState.selectedTournament
        ? prizeAccountState.selectedTournament.id
        : null,
      facility_id: facilityStore?.facility?.id,
    };

    const createPrizeAccountRes = await PostCreditBook(params, true);

    if (createPrizeAccountRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.prize_accounts.prize_accounts_new.002")));
      return;
    }

    history.push(
      leagueId
        ? "/admin/league/" + String(leagueId) + "/prizeAccounts"
        : tournamentId
        ? "/admin/tournament/" + String(tournamentId) + "/prizeAccounts"
        : "/admin/settings/prizeAccounts",
    );
  }

  function prizeAccountInputChange(e: any) {
    const id = e.target.id;
    const value = e.target.value;

    setPrizeAccountState(prev => ({ ...prev, [id]: value }));
  }

  function handleTournamentChange(value: any, tournament: ITournament) {
    setPrizeAccountState(prevState => ({
      ...prevState,
      tournamentSearch: "",
      selectedTournament: tournament,
      selectedLeague: null,
      leagueSearch: "",
    }));
  }

  function handleLeagueChange(value: any, league: ILeague) {
    setPrizeAccountState(prevState => ({
      ...prevState,
      leagueSearch: "",
      selectedLeague: league,
      selectedTournament: null,
      tournamentSearch: "",
    }));
  }

  function displayTournaments() {
    if (
      prizeAccountState?.tournaments &&
      prizeAccountState?.tournaments?.length > 0 &&
      prizeAccountState?.tournamentSearch !== ""
    ) {
      return prizeAccountState?.tournaments;
    } else {
      return tournaments;
    }
  }

  function displayLeagues() {
    if (
      prizeAccountState?.leagues &&
      prizeAccountState?.leagues?.length > 0 &&
      prizeAccountState?.leagueSearch !== ""
    ) {
      return prizeAccountState?.leagues;
    } else {
      return leagues;
    }
  }

  function removeSelectedTournament() {
    setPrizeAccountState(prevState => ({
      ...prevState,
      selectedTournament: null,
      tournaments: [],
      tournamentSearching: false,
    }));
  }

  function removeSelectedLeague() {
    setPrizeAccountState(prevState => ({
      ...prevState,
      selectedLeague: null,
      leagues: [],
      leagueSearching: false,
    }));
  }

  const handleTabChange = (selectedTabIndex: number) => {
    setSelected(selectedTabIndex);
  };

  const tabs = [
    {
      id: "tournament-1",
      content: t("secure.facility.settings.prize_accounts.prize_accounts_new.003"),
      panelID: "tournament-content-1",
    },

    {
      id: "league-1",
      content: t("secure.facility.settings.prize_accounts.prize_accounts_new.004"),
      panelID: "league-content-1",
    },
  ];

  const primaryAction = {
    content: t("secure.facility.settings.prize_accounts.prize_accounts_new.005"),
    action: handleCreatePrizeAccount,
  };

  return (
    <Page
      title={t("secure.facility.settings.prize_accounts.prize_accounts_new.006")}
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.prize_accounts.prize_accounts_new.007"),
          url: leagueId
            ? "/admin/league/" + String(leagueId) + "/prizeAccounts"
            : tournamentId
            ? "/admin/league/" + String(tournamentId) + "/prizeAccounts"
            : "/admin/settings/prizeAccounts",
        },
      ]}
      narrow
      primaryAction={primaryAction}
    >
      <Card>
        <div className="mb-4 mr-4 ml-4 mt-4">
          <Input
            placeholder={t("secure.facility.settings.prize_accounts.prize_accounts_new.008")}
            id="title"
            value={prizeAccountState.title}
            onChange={prizeAccountInputChange}
            label={t("secure.facility.settings.prize_accounts.prize_accounts_new.009")}
          />
        </div>
        <div className="mr-4 ml-4 mb-4">
          {leagueId || tournamentId ? null : (
            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
              {selected === 0 ? (
                <div className="flex-grow mb-4 pt-4">
                  {prizeAccountState.selectedTournament ? (
                    <div>
                      <div>
                        <p className="event-label">
                          {t("secure.facility.settings.prize_accounts.prize_accounts_new.010")}
                        </p>
                      </div>

                      <div className="selected-container">
                        <div className="event-name">
                          <div>{prizeAccountState.selectedTournament.name}</div>

                          <div>
                            <button className="" onClick={removeSelectedTournament}>
                              <Icon style="far" icon="times" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Select
                      showSearch
                      label={t("secure.facility.settings.prize_accounts.prize_accounts_new.011")}
                      onSearch={(query: string) =>
                        setPrizeAccountState(prevState => ({ ...prevState, tournamentSearch: query }))
                      }
                      onChange={(value: any, tournament: ITournament) => handleTournamentChange(value, tournament)}
                      placeholder={t("secure.facility.settings.prize_accounts.prize_accounts_new.012")}
                      allowClear
                      searchValue={prizeAccountState.tournamentSearch}
                      showDropDownOnFocus={true}
                      searching={prizeAccountState.tournamentSearching}
                    >
                      <Option
                        value={-1}
                        extraValues={{ name: t("secure.facility.tee_sheet.tee_sheet_edit.022"), id: -1 }}
                      >
                        <div className="text-semibold text-lg">{t("secure.facility.tee_sheet.tee_sheet_edit.022")}</div>
                      </Option>
                      {displayTournaments()?.map((tournament: any, index: number) => {
                        return (
                          <Option value={tournament?.id} extraValues={tournament} key={index}>
                            <span className="text-semibold text-lg">{tournament.name}</span>
                            <span className="shotgun-tournament-date">{moment(tournament?.date).format("LL")}</span>
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </div>
              ) : null}
              {selected === 1 ? (
                <div className="flex-grow mb-4 pt-4">
                  {prizeAccountState.selectedLeague ? (
                    <div>
                      <div>
                        <p className="event-label">
                          {t("secure.facility.settings.prize_accounts.prize_accounts_new.013")}
                        </p>
                      </div>

                      <div className="selected-container">
                        <div className="event-name">
                          <div>{prizeAccountState.selectedLeague.name}</div>

                          <div>
                            <button className="" onClick={removeSelectedLeague}>
                              <Icon style="far" icon="times" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <Select
                        showSearch
                        label={t("secure.facility.tee_sheet.tee_sheet_edit.023")}
                        onSearch={(query: string) =>
                          setPrizeAccountState(prevState => ({ ...prevState, leagueSearch: query }))
                        }
                        onChange={(value: any, league: ILeague) => handleLeagueChange(value, league)}
                        placeholder={t("secure.facility.settings.prize_accounts.prize_accounts_new.014")}
                        allowClear
                        searchValue={prizeAccountState.leagueSearch}
                        showDropDownOnFocus={true}
                        searching={prizeAccountState.leagueSearching}
                      >
                        <Option
                          value={-1}
                          extraValues={{ name: t("secure.facility.tee_sheet.tee_sheet_edit.024"), id: -1 }}
                        >
                          <div className="text-semibold text-lg">
                            {t("secure.facility.tee_sheet.tee_sheet_edit.024")}
                          </div>
                        </Option>
                        {displayLeagues()?.map((league: any, index: number) => {
                          return (
                            <Option value={league?.id} key={index} extraValues={league}>
                              <span className="text-semibold text-lg">{league.name}</span>
                              <span className="shotgun-tournament-date">
                                {league?.start_date ? moment(league?.start_date).format("LL") : ""}
                              </span>
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  )}
                </div>
              ) : null}
            </Tabs>
          )}
        </div>
      </Card>
    </Page>
  );
}
