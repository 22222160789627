import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { TFunction, useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { StatusCode } from "api/protocols";
import { GetParticipant } from "api/rpc/2024-04/facilityAdmin/league/participant";
import {
  GetAllLeagueFlightParticipants,
  GetLeagueFlights,
  PostLeagueFlightParticipant,
} from "api/rpc/2024-04/facilityAdmin/league/scoring/flight";

import { showError, showSuccess } from "redux/actions/ui";
import { searchHistoryUpdateSearch } from "redux/actions/searchBars/searchHistory";
import { ILeagueParticipant, TLeagueFlight } from "redux/reducers/models/league";
import { useAppDispatch } from "hooks/redux";
import { ignoreOrderCompare } from "helpers/Helpers";

import Page from "components/page/Page";
import Card from "components/card/Card";
import { Select } from "components/select";
import FormLayout from "components/form/FormLayout";
import Spin from "components/spin/spin";
import Tabs from "components/tabs/Tabs";
import Search from "components/search/Search";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

import "../teams/leagueTeamManagePlayers.scss";

export default function LeagueFlightsManagePlayers() {
  const { leagueId, divisionId } = useParams<{ leagueId: string; divisionId: string }>();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [leagueFlights, setLeagueFlights] = useState<TLeagueFlight[]>([]);
  const [leaguePlayers, setLeaguePlayers] = useState<ILeagueParticipant[]>([]);

  const [activeFlightPlayerIds, setActiveFlightPlayerIds] =
    useState<{ league_flight_id: number; league_participant_id: Array<number> }[]>(undefined);

  const [state, setState] = useState({
    tabIndex: 0,
    flightId: 0,
    search: "",
  });

  const [selectedIds, setSelectedIds] = useState({
    active: [] as Array<number>,
    preserved: [] as Array<number>,
  });

  // Always return list of flights with associated players given the leagueFLights & leaguePlayers & selectedIds.active
  // Auto updates with temporarily selected ID's in order to move player on UI
  const playersInFlight = useMemo(() => {
    if (leagueFlights.length === 0) {
      return [];
    }

    const teamIds = leagueFlights.map(team => team.id);

    return teamIds.map(id => {
      return {
        league_flight_id: id,
        players: leaguePlayers.filter(player => selectedIds.active.includes(player.id)),
      };
    });
  }, [leagueFlights, leaguePlayers, state.flightId, selectedIds.active]);

  // Flight players whom are not currently found on any team
  const playersWithoutFlight =
    state.flightId === 0
      ? []
      : leaguePlayers.filter(player => !playersInFlight.some(({ players }) => players.some(val => val === player)));

  // League players whom are found on a different team than selected teamId & NOT temporarily selected
  const playersOnOtherFlight =
    state.flightId === 0
      ? []
      : leaguePlayers.filter(
          a =>
            !playersInFlight.some(
              ({ league_flight_id, players }) =>
                league_flight_id === state.flightId && players.some(val => val === a),
            ) &&
            activeFlightPlayerIds?.some(
              ({ league_flight_id, league_participant_id }) =>
                league_flight_id !== state.flightId && league_participant_id.includes(a.id),
            ),
        );

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadLeagueFlights(leagueId, source.token);
    void loadLeaguePlayers(leagueId, source.token);
    return () => source.cancel();
  }, [leagueId]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (state.flightId === 0) {
      return;
    }
    if (activeFlightPlayerIds !== undefined) {
      const activeDivisionFlight = activeFlightPlayerIds.filter(meta => meta.league_flight_id === state.flightId)[0];

      // Reset ID's when flightId changes
      setSelectedIds(prev => ({
        ...prev,
        active: activeDivisionFlight ? activeDivisionFlight.league_participant_id : [],
        preserved: activeDivisionFlight ? activeDivisionFlight.league_participant_id : [],
      }));

      return;
    }

    // Load the current division participant ID's
    void loadFlightParticipantIds(leagueId, divisionId, source.token);
    return () => source.cancel();
  }, [leagueId, divisionId, state.flightId]);

  async function loadLeagueFlights(leagueId: string | number, token?: CancelToken) {
    const res = await GetLeagueFlights(
      { league_id: leagueId, league_division_id: divisionId },
      token ? false : true,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading league flights.")); // TODO: Translation
    }

    setLeagueFlights(res.status !== StatusCode.OK ? [] : res.data);

    // preset <Select />
    if (res.data.length > 0) {
      setState(prev => ({ ...prev, flightId: res.data[0].id }));
    }
  }

  async function loadLeaguePlayers(leagueId: string | number, token?: CancelToken) {
    const res = await GetParticipant({ league_id: leagueId }, token ? false : true, token);
    if (token && token.reason) {
      return;
    }

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading league players.")); // TODO: Translation
    }

    setLeaguePlayers(res.status !== StatusCode.OK ? [] : res.data);
  }

  async function loadFlightParticipantIds(leagueId: string | number, divisionId: string | number, token?: CancelToken) {
    // Remove unsavedChangesBar
    setSelectedIds(prev => ({ ...prev, active: [], preserved: [] }));

    // page spinner
    if (activeFlightPlayerIds !== undefined) {
      setActiveFlightPlayerIds(undefined);
    }

    const res = await GetAllLeagueFlightParticipants(
      { league_id: leagueId, division_id: divisionId },
      token ? false : true,
      token,
    );
    if (token && token.reason) {
      return;
    }

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading all league players.")); // TODO: Translation
    }

    // map through result and structure response
    const map = new Map(
      res.data.map(({ league_flight_id }) => [league_flight_id, { league_flight_id, league_participant_id: [] }]),
    );
    for (const { league_flight_id, league_participant_id } of res.data) {
      map.get(league_flight_id).league_participant_id.push(...[league_participant_id]);
    }

    setActiveFlightPlayerIds([...map.values()]); // Needed to sort participants

    // preset ID's on page
    const startingFlightIds = [...map.values()].filter(val => val.league_flight_id === state.flightId)[0];
    setSelectedIds(prev => ({
      ...prev,
      active: startingFlightIds ? startingFlightIds.league_participant_id : [],
      preserved: startingFlightIds ? startingFlightIds.league_participant_id : [],
    }));
  }

  async function updatePlayersOnFlight(flightId: string | number, playerIds: Array<number>) {
    const res = await PostLeagueFlightParticipant({ flight_id: flightId, participant_ids: playerIds }, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error adding players to the flight.")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Successfully added players on the flight")); // TODO: Translation
    void loadFlightParticipantIds(leagueId, divisionId);
  }

  function handlePlayerToggle(id: number) {
    setSelectedIds(prev => ({
      ...prev,
      active: prev.active.includes(id) ? prev.active.filter(value => value !== id) : [id, ...prev.active],
    }));
  }

  function onSelectChange(value: number) {
    setState(prev => ({ ...prev, flightId: value, search: "" }));
    dispatch(searchHistoryUpdateSearch("league_flight_manage_players", ""));
  }

  function onTabChange(index: number) {
    setState(prev => ({ ...prev, tabIndex: index, search: "" }));
    dispatch(searchHistoryUpdateSearch("league_flight_manage_players", ""));
  }

  return (
    <Page
      title="Manage Flight Players" // TODO: Translation
      breadcrumbs={[
        {
          prefix: true,
          label: "Flights", // TODO: Translation
          url: `/admin/league/${leagueId}/divisions/${divisionId}/flights`,
        },
      ]}
      notificationBarProps={{
        isVisible: !ignoreOrderCompare(selectedIds.active, selectedIds.preserved),
        onAction: () => updatePlayersOnFlight(state.flightId, selectedIds.active),
        onCancel: () => setSelectedIds(prev => ({ ...prev, active: prev.preserved })),
      }}
    >
      <Card>
        <Card.Section>
          <Select
            label={"League Flights"} // TODO: Translation
            onChange={(value: number) => onSelectChange(value)}
            defaultValue={state.flightId}
            disabled={leagueFlights.length === 0}
          >
            {leagueFlights.map(flight => (
              <Select.Option key={flight.id} value={flight.id}>
                {flight.name}
              </Select.Option>
            ))}
          </Select>
        </Card.Section>
        <Card.Section>
          {activeFlightPlayerIds !== undefined ? (
            <FormLayout>
              <FormLayout.Group>
                <div style={{ marginTop: "14px", minHeight: "500px" }}>
                  <DataTable
                    columns={[
                      { label: "Current Players" /* TODO: Translation */, width: "95%" },
                      { label: "", width: "5%" },
                    ]}
                  >
                    {playersInFlight
                      .filter(meta => meta.league_flight_id === state.flightId)
                      .map(meta =>
                        meta.players.map(player => (
                          <tr key={player.id}>
                            <td>
                              <p>{player.customer?.full_name}</p>
                            </td>
                            <td className="clickable">
                              <FontAwesomeIcon
                                icon={["fas", "x"]}
                                onClick={() =>
                                  setSelectedIds(prev => ({
                                    ...prev,
                                    active: prev.active.filter(id => id !== player.id),
                                  }))
                                }
                              />
                            </td>
                          </tr>
                        )),
                      )}
                  </DataTable>
                </div>
                <div className="player_manage-tab_section">
                  <Tabs
                    tabs={manageFlightTabs(t)}
                    selected={state.tabIndex}
                    onSelect={(value: number) => onTabChange(value)}
                  >
                    <div className="league-manage_players-search">
                      <Search
                        historyKey={"league_flight_manage_players"}
                        searchCallback={searchValue => setState(prev => ({ ...prev, search: searchValue }))}
                        placeholder="Search customer name..." // TODO: Translation
                        debounceTime={0}
                      />
                    </div>
                    {state.tabIndex === 0 && (
                      <DataTable columns={[]} hideHeader className="player_manage-tab_table">
                        {playersWithoutFlight
                          .filter(player =>
                            state.search.length !== 0
                              ? player.customer.full_name.toLowerCase().includes(state.search)
                              : true,
                          )
                          .filter(player => !playersOnOtherFlight.includes(player))
                          .map(player => (
                            <tr key={player.id} className="clickable" onClick={() => handlePlayerToggle(player.id)}>
                              <td>
                                <p>{player.customer?.full_name}</p>
                              </td>
                            </tr>
                          ))}
                      </DataTable>
                    )}
                    {state.tabIndex === 1 && (
                      <DataTable columns={[]} hideHeader className="player_manage-tab_table">
                        {playersOnOtherFlight
                          .filter(player =>
                            state.search.length !== 0
                              ? player.customer.full_name.toLowerCase().includes(state.search)
                              : true,
                          )
                          .map(player => (
                            <tr key={player.id}>
                              <td>
                                <p>{player.customer?.full_name}</p>
                              </td>
                            </tr>
                          ))}
                      </DataTable>
                    )}
                  </Tabs>
                </div>
              </FormLayout.Group>
            </FormLayout>
          ) : (
            <div style={{ height: "25px", overflow: "hidden" }}>
              <Spin />
            </div>
          )}
        </Card.Section>
      </Card>
    </Page>
  );
}

const manageFlightTabs = (t: TFunction<"translation", undefined>) => [
  {
    id: "no_flight",
    content: "Not In A Flight", // TODO: Translation
    panelID: "player_manage-content_1",
  },

  {
    id: "other_flight",
    content: "In Another Flight", // TODO: Translation
    panelID: "player_manage-content_2",
  },
];
