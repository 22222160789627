import { useTranslation, Trans } from "react-i18next";
export default function MarketingOptions() {
  const { t, i18n } = useTranslation();

  const eventOptions = [
    {
      label: "Customer Segments",
      url: "/admin/marketing",
    },
  ];

  return eventOptions;
}
