import React from "react";
import { annualFiltersObj } from "hooks/useOrderFilters/useDropFilters";
import DropFilter from "components/dropdown/DropFilter";
import { DropFilterCheckboxOptionals } from "components/dropdown/dropFilterTypes";
import { useTranslation } from "react-i18next";
type YearDropFilterProps = DropFilterCheckboxOptionals & {
  /** When 'Apply' is clicked, returns all ID's of selected facilities */
  handleApply: (year: number | null) => void;
};

export default function YearDropFilter(props: YearDropFilterProps) {
  const yearFilters = annualFiltersObj().yearFilters;
  const { t, i18n } = useTranslation();
  return (
    <DropFilter
      key="year"
      title={t("components.filters.year_drop_filter.001")} //TODO: Translation
      filterData={yearFilters()}
      filterLabelPropFromData="label"
      filterIdPropFromData="id"
      filterType="Checkbox"
      applyFilters={filterReturn => props.handleApply(filterReturn.length === 0 ? null : filterReturn[0].id)}
      defaultCheckboxes={props.defaultCheckboxes}
      leftAligned={props.leftAligned ?? false}
      rightAligned={props.rightAligned ?? false}
      dropdownSize={props.dropdownSize}
      darkMode={props.darkMode}
      save={props.save}
      historyKey={props.historyKey}
      disableDropdown={props.disableDropdown}
      restrain
    />
  );
}
