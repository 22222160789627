import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import { useAppDispatch } from "hooks/redux";
import axios, { CancelToken } from "axios";
import useModal from "hooks/modals/useModal";

import {
  GetTvView,
  PutTvView,
  TPutTvView,
  GetTvViewSlide,
  PostTvViewSlide,
  TPostTvViewSlide,
  PostTvViewHeaderImage,
  DeleteTvViewHeaderImage,
  DeleteTvView,
  DeleteTvViewSlide,
} from "api/rpc/2024-04/facilityAdmin/league/tvView/tvView";
import { GetLeagueCompetitionFormat } from "api/rpc/2024-04/facilityAdmin/league/scoring/format";
import { GetLeagueScoresheets } from "api/rpc/2024-04/facilityAdmin/league/scoring/scoresheet";
import { StatusCode } from "api/protocols";

import { showError, showSuccess } from "redux/actions/ui";
import { valueToString } from "helpers/Helpers";

import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import Page from "components/page/Page";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import Sheet from "components/sheet/Sheet";
import Portal from "elements/Portal";
import Toggle from "components/form/toggle/Toggle";
import { Select } from "components/select/index";
import CustomerImage from "elements/customer/CustomerImage";
import { ButtonNew as Button } from "components/buttonNew/index";

import { TLeagueTvView, TLeagueTvViewSlide, TLeagueRoundFormat, TLeagueScoresheet } from "redux/reducers/models/league";
import Popup from "components/popup/Popup";

export default function LeagueTvViewEdit() {
  const { leagueId, viewId } = useParams<{ leagueId: string; viewId: string }>();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [view, setView] = useState<TLeagueTvView>(undefined);
  const [slides, setSlides] = useState<TLeagueTvViewSlide[]>(undefined);
  const [scoresheets, setScoresheets] = useState<TLeagueScoresheet[]>(undefined);
  const [formats, setFormats] = useState<TLeagueRoundFormat[]>(undefined);
  const [allFormats, setAllFormats] = useState<TLeagueRoundFormat[]>(undefined);

  const [deleteState, setDeleteState] = useState({
    deleteTvView: false,
    deleteTvViewSlide: false,
    slideToDelete: null,
  });

  const {
    state: newSlideModal,
    updateModal: updateNewSlideModal,
    closeModal: closeNewSlideModal,
  } = useModal<TPostTvViewSlide>({
    tv_view_id: viewId,
    type: "leaderboard",
    league_scoresheet_id: null,
    scoresheet_format_id: null,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadTvView(source.token);
    void loadScoresheets(source.token);
    return () => source.cancel();
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (newSlideModal.league_scoresheet_id) {
      void loadFormats(source.token);
    }
    return () => source.cancel();
  }, [newSlideModal.league_scoresheet_id]);

  async function loadTvView(token?: CancelToken) {
    setSlides(undefined);

    const viewRes = await GetTvView({ id: viewId }, true, token);

    if (token && token.reason) {
      return;
    }
    if (viewRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading TV view")); // TODO: Translation
      return;
    }

    const slidesRes = await GetTvViewSlide({ tv_view_id: viewId }, true, token);

    if (slidesRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading TV view slides")); // TODO: Translation
      return;
    }

    setView(viewRes.data[0]);
    setSlides(slidesRes.data);
  }

  async function loadScoresheets(token?: CancelToken) {
    const scoresheetRes = await GetLeagueScoresheets({ league_id: leagueId }, false, token);

    if (token && token.reason) {
      return;
    }
    if (scoresheetRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading league rounds")); // TODO: Translation
      return;
    }

    setScoresheets(scoresheetRes.data);

    const formatRes = await GetLeagueCompetitionFormat({ league_id: leagueId }, false, token);

    if (token && token.reason) {
      return;
    }
    if (formatRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading league formats")); // TODO: Translation
      return;
    }

    setAllFormats(formatRes.data);
  }

  async function loadFormats(token?: CancelToken) {
    setFormats(undefined);

    const formatRes = await GetLeagueCompetitionFormat(
      { league_id: leagueId, league_scoresheet_id: newSlideModal.league_scoresheet_id },
      false,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (formatRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading league formats")); // TODO: Translation
      return;
    }

    setFormats(formatRes.data);
  }

  async function updateTvView() {
    const params = {
      id: viewId,
      title: view.title,
      auto_play: view.auto_play,
      auto_play_interval: view.auto_play_interval,
    };

    const viewRes = await PutTvView(params, true);

    if (viewRes.status !== StatusCode.OK) {
      dispatch(showError("Error updating TV view")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("TV view updated successfully")); // TODO: Translation
    void loadTvView();
  }

  async function createSlide() {
    const slideRes = await PostTvViewSlide(
      {
        tv_view_id: viewId,
        type: newSlideModal.type,
        scoresheet_format_id: newSlideModal.scoresheet_format_id,
      },
      true,
    );

    if (slideRes.status !== StatusCode.OK) {
      dispatch(showError("Error creating TV view slide")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("TV view slide created successfully")); // TODO: Translation
    closeNewSlideModal();
    void loadTvView();
  }

  async function saveHeaderImage(imageFile: File, type: "left" | "right") {
    const formData = new FormData();

    formData.append("image", imageFile);
    formData.append("type", type);
    formData.append("id", viewId);

    const imageRes = await PostTvViewHeaderImage(formData, true);
    if (imageRes.status !== StatusCode.OK) {
      return;
    }

    void loadTvView();
  }

  async function deleteHeaderImage(type: "left" | "right") {
    const deleteRes = await DeleteTvViewHeaderImage({ type: type, id: viewId }, true);

    if (deleteRes.status !== StatusCode.OK) {
      return;
    }

    void loadTvView();
  }

  const primaryAction = {
    content: "Save", // TODO: Translation
    action: updateTvView,
  };

  const secondaryActions = [
    {
      content: "View", // TODO: Translation
      action: () => window.open(`/admin/league/${leagueId}/scoring/tv-views/${viewId}/view`),
    },
    {
      content: "Delete", // TODO: Translation
      action: () => setDeleteState(prevState => ({ ...prevState, deleteTvView: true })),
    },
  ];

  async function deleteTvView() {
    const deleteRes = await DeleteTvView({ id: viewId }, true);

    if (deleteRes.status !== StatusCode.OK) {
      dispatch(showError("Error deleting TV view ")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("TV View deleted successfully")); // TODO: Translation
    setDeleteState(prevState => ({ ...prevState, deleteTvView: false }));
    history.push(`/admin/league/${leagueId}/scoring/tv-views`);
  }

  async function deleteTvSlide() {
    const deleteRes = await DeleteTvViewSlide({ id: deleteState.slideToDelete.id }, true);

    if (deleteRes.status !== StatusCode.OK) {
      dispatch(showError("Error deleting TV view slide")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("TV View Slide deleted successfully")); // TODO: Translation
    setDeleteState(prevState => ({ ...prevState, deleteTvViewSlide: false }));
    void loadTvView();
  }

  return (
    <Page
      title="Edit TV View" // TODO: Translation
      subtitle={view?.title}
      primaryAction={primaryAction}
      secondaryActions={secondaryActions}
      breadcrumbs={[
        {
          prefix: true,
          label: "Tv Views", // TODO: Translation
          url: `/admin/league/${leagueId}/scoring/tv-views`,
        },
      ]}
    >
      <Card>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Input
                label={"Title"} // TODO: Translation
                value={view?.title}
                onChange={e => setView({ ...view, title: e.target.value })}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <Toggle
                checked={view?.auto_play}
                onChange={e =>
                  setView({
                    ...view,
                    auto_play: e.target.checked,
                    auto_play_interval: e.target.checked ? 10 : null,
                  })
                }
                label="Auto Play" // TODO: Translation
                disabled={slides?.length <= 1}
              />
              {view?.auto_play ? (
                <Input
                  label={"Interval"} // TODO: Translation
                  value={view?.auto_play_interval}
                  onChange={e => setView({ ...view, auto_play_interval: Number(e.target.value) })}
                  type={"number"}
                  suffix="Seconds" // TODO: Translation
                />
              ) : null}
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
        <Card.Section title="Images" /* TODO: Translation */>
          <div className="flex gap-4">
            <div className="stats_bar-picture">
              <span>{"Left Header"}</span>
              <CustomerImage
                imageSource={view?.header_image_left_source}
                saveProfileImage={imageFile => saveHeaderImage(imageFile, "left")}
                deleteProfileImage={() => deleteHeaderImage("left")}
              />
            </div>
            <div className="stats_bar-picture">
              <span>{"Right Header"}</span>
              <CustomerImage
                imageSource={view?.header_image_right}
                saveProfileImage={imageFile => saveHeaderImage(imageFile, "right")}
                deleteProfileImage={() => deleteHeaderImage("right")}
              />
            </div>
          </div>
        </Card.Section>
      </Card>
      <Card
        title="Slides" // TODO: Translation
        titleActions={[
          {
            content: "New Slide", // TODO: Translation
            action: () => updateNewSlideModal({ isOpen: true }),
          },
        ]}
      >
        <Card.Section table>
          <DataTable
            columns={[
              { label: "Round" }, // TODO: Translation
              { label: "Format" }, // TODO: Translation
              { label: "Type" }, // TODO: Translation
              { label: "" }, // TODO: Translation
            ]}
            loading={!slides}
          >
            {slides?.map((slide, index) => {
              const format = allFormats?.find(format => format.id === slide.scoresheet_format_id);
              return (
                <tr key={index}>
                  <td>{scoresheets?.find(scoresheet => scoresheet.id === format?.league_scoresheet_id)?.name}</td>
                  <td>{format?.title}</td>
                  <td>{valueToString(slide.type)}</td>
                  <td>
                    <Button
                      block
                      type="text"
                      size="small"
                      onClick={() =>
                        setDeleteState(prevState => ({ ...prevState, deleteTvViewSlide: true, slideToDelete: slide }))
                      }
                    >
                      {"Delete"}
                    </Button>
                  </td>
                </tr>
              );
            })}
          </DataTable>
        </Card.Section>
      </Card>

      <Portal isMounted={newSlideModal.isOpen}>
        <Sheet
          open={newSlideModal.isOpen}
          title="New Slide" // TODO: Translation
          onOk={createSlide}
          okText="Create" // TODO: Translation
          okDisabled={!newSlideModal.scoresheet_format_id}
          onCancel={closeNewSlideModal}
          size="small"
          overflow
        >
          <div className="mb-2">
            <Select
              label="Round" // TODO: Translation
              defaultValue={newSlideModal.league_scoresheet_id}
              onChange={value => updateNewSlideModal({ league_scoresheet_id: value })}
            >
              {scoresheets?.map(scoresheet => (
                <Select.Option key={scoresheet.id} value={scoresheet.id}>
                  {scoresheet.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <Select
            label="Format" // TODO: Translation
            onChange={value => updateNewSlideModal({ scoresheet_format_id: value })}
            disabled={!newSlideModal.league_scoresheet_id}
            loading={formats === undefined}
          >
            {formats?.map(format => (
              <Select.Option key={format.id} value={format.id}>
                {format.title}
              </Select.Option>
            ))}
          </Select>
        </Sheet>
      </Portal>

      <Popup
        type="warning"
        open={deleteState.deleteTvView}
        title={"Delete TV View"}
        description={"Are you sure you want to delete this TV View? This cannot be undone."}
        onOk={deleteTvView}
        onCancel={() => setDeleteState(prevState => ({ ...prevState, deleteTvView: false }))}
        okText={"Delete"}
      />
      <Popup
        type="warning"
        open={deleteState.deleteTvViewSlide}
        title={"Delete TV View Slide"}
        description={"Are you sure you want to delete this TV View Slide? This cannot be undone."}
        onOk={deleteTvSlide}
        onCancel={() =>
          setDeleteState(prevState => ({ ...prevState, deleteTvViewSlide: false, slideToDelete: null }))
        }
        okText={"Delete"}
      />
    </Page>
  );
}
