import React from "react";
import ReactMarkdown from "react-markdown";
import "./markdown.scss";

interface IMarkdownProps {
  markdownText: string;
}

export function formatMarkdownBreaks(markdownText: string) {
  if (markdownText === undefined || markdownText === null) {
    return "";
  } else {
    const markdownTextLines = markdownText.split("\n");
    const formattedMarkdownTextLines = markdownTextLines.map(line => line + "  ").join("\n");

    return formattedMarkdownTextLines;
  }
}

export default function Markdown(props: IMarkdownProps) {
  return <ReactMarkdown className="markdown-display">{formatMarkdownBreaks(props.markdownText)}</ReactMarkdown>;
}
