import React, { useCallback, useEffect, useRef, useState } from "react";
import { LocaleCurrency } from "helpers/Locale";
import { ICartLineItem, IDiscountLines } from "redux/reducers/models/cart";
import "elements/register/register.scss";
import "./cartLineItemsNew.scss";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRegisterContext } from "pages/secure/facility/register/registerNew/RegisterContext";

interface IProps {
  lineItems?: Array<ICartLineItem>;
  handleLineItemClick?: (line: ICartLineItem) => void;
  discountLines: Array<IDiscountLines>;
  handleDiscountClick?: (discountLine: IDiscountLines, parentLineItemId?: number) => void;
}

interface IModifierGroupsState {
  [key: string]: ICartLineItem[];
}

export default function CartLineItemsNew(props: IProps) {
  const { lineItems, handleLineItemClick, discountLines, handleDiscountClick } = props;
  const { t } = useTranslation();
  const [modifierGroups, setModifierGroups] = useState<IModifierGroupsState>({});
  const { loadingVariants, lineItemsUpdating } = useRegisterContext();
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    void groupModifiers();
  }, [lineItems]);

  useEffect(() => {
    if (loadingVariants?.current?.length > 0 && scrollRef?.current) {
      scrollRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  });

  function groupModifiers() {
    const groups = lineItems?.reduce((prevModifierGroups: { [key: string]: Array<ICartLineItem> }, lineItem: any) => {
      if (lineItem.parent_id !== null) {
        return {
          ...prevModifierGroups,
          [lineItem.parent_id]: [...(prevModifierGroups[lineItem.parent_id] || []), lineItem],
        };
      } else {
        return prevModifierGroups;
      }
    }, {});

    setModifierGroups(groups);
  }

  return (
    <>
      {lineItems?.map(line_item => {
        if (line_item.parent_id === null) {
          const lineItemDiscounts = [...discountLines]?.filter(
            discount => discount?.cart_line_item_id === line_item?.id,
          );
          const lineItemUpdating = lineItemsUpdating?.current?.find(item => item?.id === line_item?.id);
          return (
            <div
              key={line_item.id}
              className={classNames("cart-line-items-new", {
                [`cart-line-items-new-updating-${lineItemUpdating?.type}`]: !!lineItemUpdating,
              })}
            >
              <div
                className={classNames("cart-line-items-new-single-item", {
                  "cart-line-items-new-single-item-clickable": handleLineItemClick && !lineItemUpdating,
                })}
                onClick={handleLineItemClick && !lineItemUpdating ? () => handleLineItemClick(line_item) : undefined}
              >
                <div className="cart-line-items-new-details-container">
                  <p className="cart-line-items-new-quantity">{line_item.quantity}</p>
                  <div className="cart-line-items-new-item-container">
                    <ProductTitleContainer
                      productTitle={line_item?.product_title}
                      productPreferredTitle={line_item?.preferred_title}
                      variantTitle={line_item?.variant_title}
                    />
                  </div>
                </div>
                <span className="cart-line-items-new-price">
                  {lineItemDiscounts?.length > 0 && (
                    <span className="cart-line-items-new-discount cart-line-items-new-discount-total">
                      <LocaleCurrency currency="cad" amount={line_item?.price * line_item?.quantity} />
                    </span>
                  )}
                  <LocaleCurrency currency="cad" amount={line_item.subtotal_price} />
                </span>
              </div>
              {lineItemDiscounts?.map(discount => {
                return (
                  <div key={discount?.id} className="cart-line-items-new-discount-container">
                    <div
                      className={classNames("cart-line-items-new-discount-container-inner", {
                        "cart-line-items-new-discount-container-inner-clickable":
                          handleDiscountClick && !lineItemUpdating,
                      })}
                      onClick={
                        handleDiscountClick && !lineItemUpdating ? () => handleDiscountClick(discount) : undefined
                      }
                    >
                      <p className="cart-line-items-new-discount">
                        {discount?.title}: -<LocaleCurrency currency="cad" amount={discount?.amount} />
                      </p>
                    </div>
                  </div>
                );
              })}

              {modifierGroups &&
                line_item.id.toString() in modifierGroups &&
                modifierGroups[line_item.id.toString()].map(modifier => {
                  const modifierDiscounts = [...discountLines]?.filter(
                    discount => discount?.cart_line_item_id === modifier?.id,
                  );
                  return (
                    <React.Fragment key={modifier.id}>
                      <div
                        className={classNames("cart-line-items-new-single-item", {
                          "cart-line-items-new-single-item-clickable": handleLineItemClick && !lineItemUpdating,
                        })}
                        onClick={
                          handleLineItemClick && !lineItemUpdating ? () => handleLineItemClick(modifier) : undefined
                        }
                      >
                        <p className="flex items-center">+</p>
                        <div className="cart-line-items-new-details-container">
                          <p className="cart-line-items-new-quantity-modifier">{modifier?.quantity}</p>
                          <div className="cart-line-items-new-item-container">
                            <div className="cart-line-items-new-variant-title">
                              {modifier?.preferred_title ? modifier?.preferred_title : modifier?.product_title}
                              {modifier?.product_title !== modifier?.variant_title &&
                                modifier?.preferred_title !== modifier?.variant_title && (
                                  <p className="cart-line-items-new-variant-title"> {modifier?.variant_title}</p>
                                )}
                            </div>
                          </div>
                        </div>
                        <span className="cart-line-items-new-price">
                          {modifierDiscounts?.length > 0 && (
                            <span className="cart-line-items-new-discount cart-line-items-new-discount-total">
                              <LocaleCurrency currency="cad" amount={modifier?.price * modifier?.quantity} />
                            </span>
                          )}
                          <LocaleCurrency currency="cad" amount={modifier?.subtotal_price} />
                        </span>
                      </div>

                      {modifierDiscounts?.map(discount => {
                        return (
                          <div key={discount?.id} className="cart-line-items-new-discount-container">
                            <div
                              className={classNames("cart-line-items-new-discount-container-inner", {
                                "cart-line-items-new-discount-container-inner-clickable":
                                  handleDiscountClick && !lineItemUpdating,
                              })}
                              onClick={
                                handleDiscountClick && !lineItemUpdating
                                  ? () => handleDiscountClick(discount, line_item?.id)
                                  : undefined
                              }
                            >
                              <p className="cart-line-items-new-discount">
                                {discount?.title}: -<LocaleCurrency currency="cad" amount={discount?.amount} />
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </React.Fragment>
                  );
                })}

              {/* Loading modifiers added for an existing cart line item */}
              {loadingVariants?.current
                ?.filter(variant => variant?.parent_id === line_item?.id)
                ?.map((modifier, index) => {
                  return (
                    <div className={classNames("cart-line-items-new-single-item")} key={index}>
                      <p className="flex items-center">+</p>
                      <div className="cart-line-items-new-details-container">
                        <p className="cart-line-items-new-quantity-modifier">{modifier?.quantity}</p>
                        <div className="cart-line-items-new-item-container">
                          <div className="cart-line-items-new-variant-title">
                            <p className="cart-line-items-new-variant-title">
                              {modifier?.title === modifier?.product?.title
                                ? modifier?.product?.meta?.preferred_title
                                  ? modifier?.product?.meta?.preferred_title
                                  : modifier?.title
                                : modifier?.title}
                            </p>
                          </div>
                        </div>
                      </div>
                      <FontAwesomeIcon
                        className="cart-variant-spinner"
                        size="1x"
                        icon={["fad", "spinner-third"]}
                        spin
                        fade
                      />
                    </div>
                  );
                })}

              {line_item.note && (
                <div className="cart-line-items-new-note-container">
                  <FontAwesomeIcon className="cart-line-items-new-note-icon" icon={["far", "file-lines"]} />
                  <p className="cart-line-items-new-note">{line_item?.note}</p>
                </div>
              )}
            </div>
          );
        }
      })}

      {/* Loading new items added to the cart */}
      {loadingVariants?.current
        ?.filter(variant => !variant?.parent_id)
        ?.map((variant, index) => {
          return (
            <div key={index} className="cart-line-items-new">
              <div className={classNames("cart-line-items-new-single-item")}>
                <div className="cart-line-items-new-details-container">
                  <p className="cart-line-items-new-quantity">{variant?.quantity}</p>
                  <div className="cart-line-items-new-item-container">
                    <div className="cart-line-items-new-product-title">
                      {variant?.product_preferred_title ? variant.product_preferred_title : variant?.product_title}
                      {variant?.product_title !== variant?.title &&
                        variant.product_preferred_title !== variant?.title && (
                          <p className="cart-line-items-new-variant-title">{variant?.title}</p>
                        )}
                    </div>
                  </div>
                </div>
                <FontAwesomeIcon className="cart-variant-spinner" size="1x" icon={["fad", "spinner-third"]} spin fade />
              </div>
              {/* Loading modifiers for new parent item added to cart */}
              {variant?.modifiers?.map((modifier, index) => {
                return (
                  <div className={classNames("cart-line-items-new-single-item")} key={index}>
                    <p>+</p>
                    <div className="cart-line-items-new-details-container">
                      <p className="cart-line-items-new-quantity-modifier">{modifier?.quantity}</p>
                      <div className="cart-line-items-new-item-container">
                        <div className="cart-line-items-new-variant-title">
                          <p className="cart-line-items-new-variant-title">
                            {modifier?.title === modifier?.product?.title
                              ? modifier?.product?.meta?.preferred_title
                                ? modifier?.product?.meta?.preferred_title
                                : modifier?.title
                              : modifier?.title}
                          </p>
                        </div>
                      </div>
                    </div>
                    <FontAwesomeIcon
                      className="cart-variant-spinner"
                      size="1x"
                      icon={["fad", "spinner-third"]}
                      spin
                      fade
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
      <div ref={scrollRef} style={{ visibility: "hidden" }}></div>
    </>
  );
}

/**Handles scolling though truncated product title on hover*/
function ProductTitleContainer({
  productTitle,
  productPreferredTitle,
  variantTitle,
}: {
  productTitle: string;
  productPreferredTitle: string;
  variantTitle: string;
}) {
  const [hoverState, setHoverState] = useState<boolean>(false);
  const [scrollState, setScrollState] = useState<boolean>(false);
  const titleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (hoverState && titleRef?.current && titleRef?.current?.offsetWidth < titleRef?.current?.scrollWidth) {
      setScrollState(true);
    } else {
      setScrollState(false);
    }
  }, [hoverState]);

  return (
    <div className="cart-line-items-new-item-container">
      <div
        ref={titleRef}
        onMouseEnter={() => setHoverState(true)}
        onMouseLeave={() => setHoverState(false)}
        className={classNames("cart-line-items-new-product-title", {
          "cart-line-items-new-product-title-scroll": scrollState,
        })}
      >
        {productPreferredTitle ? productPreferredTitle : productTitle}
        {productTitle !== variantTitle && productPreferredTitle !== variantTitle && (
          <p className="cart-line-items-new-variant-title"> {variantTitle}</p>
        )}
      </div>
    </div>
  );
}
