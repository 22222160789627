import React, { useEffect, useState } from "react";
import { useAppSelector } from "hooks/redux";
import { StatusCode } from "api/protocols";
import { PutCancelWaitTime } from "api/rpc/2024-04/guest/teesheet";
import "./cancelWaitTime.scss";
import Spin from "components/spin/spin";
import FeatureIcon from "components/icon/FeatureIcon";

interface IState {
  cancelSuccess: boolean;
}

export default function CancelWaitTime() {
  const { authStore } = useAppSelector(store => store);
  const [state, setState] = useState<IState>({
    cancelSuccess: undefined,
  });

  const urlParams = new URLSearchParams(window.location.search);
  const waitListId = urlParams.get("waitlist_reference_id");
  const customerEmail = urlParams.get("customer_email");

  useEffect(() => {
    if (authStore.loaded) {
      if (waitListId && customerEmail) {
        void handleCancelWaitTime();
      } else {
        setState(prevState => ({ ...prevState, cancelSuccess: false }));
      }
    }
  }, [authStore.loaded]);

  async function handleCancelWaitTime() {
    const res = await PutCancelWaitTime({ waitlist_id: waitListId, customer_email: customerEmail }, true);
    setState(prevState => ({ ...prevState, cancelSuccess: res.status === StatusCode.OK }));
  }

  return (
    <div className="waitlist-cancel-parent-container">
      <div className="waitlist-cancel-inner-container">
        {state.cancelSuccess === undefined ? (
          <div style={{ height: "50px", width: "50px" }}>
            <Spin />
          </div>
        ) : (
          <div className="waitlist-group">
            {state.cancelSuccess ? (
              <FeatureIcon type="success" theme="light-circle-outline" size="large" icon="check" />
            ) : (
              <FeatureIcon type="error" theme="light-circle-outline" size="large" icon="exclamation-triangle" />
            )}
            <p className={"cancel-title"}>{state.cancelSuccess ? "Success" : "Error"}</p>
            <p className="cancel-subtext">
              {state.cancelSuccess
                ? "Your waitlist request has been successfully cancelled"
                : "There was an error with cancelling your waitlist request"}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
