import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import TransactionlHeader from "./TransactionalHeader/TransactionalHeader";
import "./Confirmation.scss";
import Card from "components/card/Card";
import { displayCurrency } from "helpers/Helpers";
import { GetOrder } from "api/rpc/guest/order";
import { StatusCode } from "api/protocols";
import { IOrder } from "../models/order";
import { IFacility } from "../models/facility";
import { GetFacility } from "api/rpc/guest/facility";
import { ICartState } from "redux/reducers/customer/cart";
import { ICartActions } from "redux/actions/customer/cart";
import { StorePage } from "./StorePage/StorePage";
import OrderSummary from "components/OrderSummary/OrderSummary";
import PaymentSummary from "components/PaymentSummary/PaymentSummary";
import { ICart } from "redux/reducers/customer/models/cart";
import { getOnlineStoreCartToken, getOnlineStoreCartTokenName } from "./HomePage";
import { IUIActions } from "redux/actions/ui";
import { useTranslation } from "react-i18next";
import { GetFormResponse, IFormResponse } from "api/rpc/2022-09/guest/form";

interface IConfirmationProps {
  cartStore: ICartState;
  cartActions: ICartActions;
  uiActions: IUIActions;
}

interface IConfirmationState {
  facility: IFacility;
  order: IOrder;
  formResponses: IFormResponse[];
}

export default function Confirmation(props: IConfirmationProps) {
  const { facilityShortName, orderToken } = useParams<{ facilityShortName: string; orderToken: string }>();

  const { cartStore, cartActions, uiActions } = props;

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [confirmationState, setConfirmationState] = useState<IConfirmationState>({
    facility: undefined,
    order: undefined,
    formResponses: [],
  });

  useEffect(() => {
    void loadOrder();
  }, []);

  async function loadOrder() {
    uiActions.enqueue();

    try {
      const facilityRes = await GetFacility({ short_name: facilityShortName }, false);
      const facilityId = facilityRes?.data?.[0]?.id;

      if (facilityRes.status !== StatusCode.OK || facilityId === undefined) {
        uiActions.showError(t("guest.online_store.confirmation.001"));
        uiActions.dequeue();
        return;
      }

      let cart: ICart = undefined;

      if (getOnlineStoreCartToken(facilityShortName)) {
        try {
          const getCartPromise: Promise<ICart> = cartStore?.isLoaded
            ? Promise.resolve(cartStore?.cart)
            : (cartActions.loadCart(
                {
                  facility_id: facilityId,
                  tokenName: getOnlineStoreCartTokenName(facilityShortName),
                  token: getOnlineStoreCartToken(facilityShortName),
                },
                false,
              ) as unknown as Promise<ICart>);

          cart = await getCartPromise;
        } catch {
          uiActions.showError(t("guest.online_store.confirmation.002"));
          uiActions.dequeue();
          return;
        }
      }

      if (cart?.status === "complete") {
        cartActions.cartClear({ tokenName: getOnlineStoreCartTokenName(facilityShortName) });
      }

      const getOrderResponse = await GetOrder({ token: orderToken }, false);

      if (getOrderResponse.status !== StatusCode.OK) {
        uiActions.showError(t("guest.online_store.confirmation.003"));
        uiActions.dequeue();
        return;
      }

      const facility = facilityRes.data?.[0];
      const order = getOrderResponse.data?.[0];

      const formResponseRes = await GetFormResponse({ client_id: facility.client_id, order_id: order.id }, false);
      let formResponses: IFormResponse[] = [];

      if (formResponseRes.status === StatusCode.OK) {
        formResponses = formResponseRes.data;
      }

      setConfirmationState(prevState => ({
        ...prevState,
        facility,
        order,
        formResponses,
      }));

      uiActions.dequeue();
    } catch {
      uiActions.dequeue();
    }
  }

  function navigateToHomepage() {
    history.push(`/online-store/${facilityShortName}`);
  }

  function renderOrderSummary(togglableVisibility: boolean) {
    return (
      <OrderSummary
        subtotal={confirmationState.order.subtotal_price}
        taxLines={confirmationState.order.tax_lines}
        discount={confirmationState.order.total_discount}
        total={confirmationState.order.total_price}
        togglableVisibility={togglableVisibility}
        lineItems={confirmationState.order.line_items.map(lineItem => {
          return {
            src: lineItem.product_default_image?.source,
            productTitle: lineItem.product_title,
            variantTitle: lineItem.variant_title,
            quantity: lineItem.quantity,
            price: lineItem.subtotal_price,
          };
        })}
      />
    );
  }

  return (
    <div>
      {confirmationState.facility && (
        <>
          <header>
            <TransactionlHeader
              facilityShortName={facilityShortName}
              facilityLogoSource={confirmationState.facility.logo_source}
              shoppingBagHasItems={cartStore.cart?.line_items?.length > 0}
            />
          </header>
          <main>
            <StorePage>
              {confirmationState.order && (
                <div className="confirmation-page-sections">
                  <section className="confirmation-order-summary-mobile-section">{renderOrderSummary(true)}</section>
                  <section className="confirmation-information-section">
                    <Card>
                      <Card.Section>
                        <h2 className="confirmation-information-section-title">{`Order ${confirmationState.order.name}`}</h2>
                        <h3 className="confirmation-information-section-subtitle">
                          {`${t("guest.online_store.confirmation.008")} ${
                            confirmationState.order.customer?.first_name
                          } ${t("guest.online_store.confirmation.009")}`}
                        </h3>
                      </Card.Section>
                    </Card>
                    <Card>
                      <Card.Section>
                        <PaymentSummary
                          summaryData={[
                            {
                              label: t("guest.online_store.confirmation.004"),
                              values: [
                                confirmationState.order.customer.full_name,
                                confirmationState.order.customer.email,
                              ],
                            },
                            {
                              label: t("guest.online_store.confirmation.005"),
                              values: [
                                `${confirmationState.order.billing_address_line.first_name} ${confirmationState.order.billing_address_line.last_name}`,
                                confirmationState.order.billing_address_line.address_line_1,
                                confirmationState.order.billing_address_line.address_line_2,
                                `${confirmationState.order.billing_address_line.city} ${confirmationState.order.billing_address_line.province_code} ${confirmationState.order.billing_address_line.postal}`,
                                confirmationState.order.billing_address_line.country_name,
                                confirmationState.order.billing_address_line.phone,
                              ],
                            },
                            ...(confirmationState.order?.customer_notes
                              ? [
                                  {
                                    label: t("guest.online_store.confirmation.010"),
                                    values: [confirmationState.order.customer_notes],
                                  },
                                ]
                              : []),
                            ...(confirmationState.formResponses?.length > 0
                              ? [
                                  {
                                    label: t("guest.online_store.confirmation.011"),
                                    values: [
                                      `${confirmationState.formResponses.length} ${
                                        confirmationState.formResponses.length === 1 ? "form" : "forms"
                                      } completed`,
                                    ],
                                  },
                                ]
                              : []),
                            {
                              label: t("guest.online_store.confirmation.006"),
                              values: [
                                `•••• ${confirmationState.order.transactions?.[0]?.payment_last_4}`,
                                displayCurrency("cad", confirmationState.order.transactions?.[0]?.amount),
                              ],
                            },
                          ]}
                        />
                      </Card.Section>
                    </Card>
                    <div className="confirmation-information-continue-shopping-container">
                      <button className="confirmation-information-continue-shopping" onClick={navigateToHomepage}>
                        {t("guest.online_store.confirmation.007")}
                      </button>
                    </div>
                  </section>
                  <section className="confirmation-order-summary-desktop-section">{renderOrderSummary(false)}</section>
                </div>
              )}
            </StorePage>
          </main>
        </>
      )}
    </div>
  );
}
