import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { capitalize, displayCurrency, uppercase, valueToString } from "helpers/Helpers";
import { isEqualWith } from "lodash";

import { Select } from "components/select/index";
import Card from "components/card/Card";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import { NotificationType } from "components/notificationBar/NotificationBar";
import Page from "components/page/Page";

import { GetAccountingReference } from "api/rpc/facility";
import { StatusCode } from "api/protocols";

import DataTable from "components/dataTable/DataTable";
import { useTranslation } from "react-i18next";
import { GetAccount, GetAccountTransactions, ReopenHouseAccount } from "api/rpc/2024-04/clientAdmin/client/accounts";
import moment from "moment";
import { Badge } from "components/badge/Badge";
import Popup from "components/popup/Popup";
import { useAppDispatch } from "hooks/redux";
import { showError, showSuccess } from "redux/actions/ui";

interface IAccountState {
  id: number;
  title: string;
  customer_name: string;
  account_number: string;
  account_balance: number;
  currency: string;
  limit: number;
  limit_is_valid: boolean;
  accounting_reference_id: string;
  account_transactions: any[];
  closed?: boolean;
  reopenVisible: boolean;
}

export default function AccountOverview() {
  const { accountId } = useParams<{ accountId: string }>();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { Option } = Select;
  const dispatch = useAppDispatch();

  const [accountState, setAccountState] = useState<IAccountState>({
    id: null,
    title: "",
    customer_name: "",
    account_number: "",
    account_balance: null,
    currency: "",
    limit: null,
    limit_is_valid: null,
    accounting_reference_id: "",
    account_transactions: [],
    closed: false,
    reopenVisible: false,
  });

  const [accountBeforeChanges, setAccountBeforeChanges] = useState<IAccountState>(undefined);

  //   const [accountingReferenceTypes, setAccountingReferenceTypes] = useState<IAccountingReferenceTypes[]>(undefined);

  useEffect(() => {
    void loadAccountTransactions();
    void loadAccount();
  }, []);

  async function loadAccount() {
    const accountResponse = await GetAccount({ id: Number(accountId) });

    if (
      accountResponse.status !== StatusCode.OK ||
      accountResponse?.data?.length === undefined ||
      accountResponse.data.length === 0
    ) {
      return;
    }

    const limit = accountResponse?.data[0]?.limit?.toString() ?? "";

    setAccountState(prevState => ({
      ...prevState,
      id: isNaN(parseInt(accountId)) ? 0 : parseInt(accountId),
      title: accountResponse?.data[0]?.title?.toString() ?? "",
      customer_name: accountResponse?.data[0]?.customer?.full_name?.toString() ?? "",
      account_number: accountResponse?.data[0]?.account_number?.toString() ?? "",
      account_balance: accountResponse.data[0].balance,
      currency: accountResponse.data[0].currency,
      limit: limit,
      accounting_reference_id: accountResponse?.data[0]?.accounting_reference_id?.toString() ?? "0",
      closed: accountResponse.data[0].closed,
    }));
  }

  async function loadAccountTransactions() {
    const params = {
      account_id: accountId,
    };

    const accountTransactionsRes = await GetAccountTransactions(params, true);
    if (accountTransactionsRes.status !== StatusCode.OK) {
      return;
    }

    const transactions: any = [];

    for (let i = 0; i < accountTransactionsRes.data.length; i++) {
      if (accountTransactionsRes.data[i].kind !== "authorization") {
        transactions.push(accountTransactionsRes.data[i]);
      }
    }

    setAccountState(prevState => ({
      ...prevState,
      account_transactions: transactions,
    }));
  }

  function cancelUnsavedChanges() {
    setAccountState(accountBeforeChanges);
  }

  function navigateToAccountEdit() {
    history.push("/admin/settings/accounts/edit/" + String(accountId));
  }

  const primaryAction = {
    content: "Edit",
    action: navigateToAccountEdit,
  };

  const secondaryActions = {
    content: "Reopen",
    action: () => setAccountState(prevState => ({ ...prevState, reopenVisible: true })),
  };

  async function confirmReopenAccount() {
    //reopen account

    const accountResponse = await ReopenHouseAccount({ account_id: Number(accountId) }, true);

    if (accountResponse.status !== StatusCode.OK) {
      dispatch(showError("Error reopening account"));
      return;
    }
    dispatch(showSuccess("Successfully reopened account"));
    setAccountState(prevState => ({ ...prevState, reopenVisible: false }));
    void loadAccount();
  }

  return (
    <Page
      title={"Account Overview"}
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: "Accounts",
          url: "/admin/settings/accounts",
        },
      ]}
      secondaryActions={accountState.closed ? [secondaryActions] : null}
    >
      <Form>
        <Card>
          <Card.Section>
            <FormLayout.Group>
              <div>
                <label htmlFor="account-title">{"Title:"}</label>
                <p id="account-title">{accountState.title}</p>
              </div>
              <div>
                <label htmlFor="account-customer">{"Customer:"}</label>
                <p id="account-customer">{accountState.customer_name}</p>
              </div>
              <div>
                <label htmlFor="account-number">{"Account Number:"}</label>
                <p id="account-number">{accountState.account_number}</p>
              </div>

              <div>
                <label htmlFor="account-balance">{"Balance:"}</label>
                <p id="account-balance">{displayCurrency(accountState.currency, accountState.account_balance)}</p>
              </div>
              <div>
                <label htmlFor="account-balance">{"Limit"}</label>
                <p id="account-balance">{displayCurrency(accountState.currency, accountState.limit)}</p>
              </div>

              <div>
                <label htmlFor="account-closed">{"Status"}</label>
                <p id="account-balance">
                  {accountState.closed ? (
                    <Badge type="error" size="medium">
                      {"Closed"}
                    </Badge>
                  ) : (
                    <Badge type="success" size="medium">
                      {"Active"}
                    </Badge>
                  )}
                </p>
              </div>
            </FormLayout.Group>
          </Card.Section>
        </Card>

        <Card title={"Account Transactions"}>
          <Card.Section table="true">
            <table className="ui-table" style={{ marginTop: "20px" }}>
              <thead>
                <tr>
                  <th>{"Date / Time"}</th>
                  <th></th>
                  <th>{"Amount"}</th>
                  <th>{"Currency"}</th>
                  <th>{"Payment Method"}</th>
                  <th>{"Source"}</th>
                </tr>
              </thead>
              <tbody>
                {accountState.account_transactions.length > 0
                  ? accountState.account_transactions.map((transaction: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td>
                            {transaction.processed_at && (
                              <>
                                <span>{moment.utc(transaction.processed_at).local().format("MMM DD, YYYY")} at </span>
                                <span>{moment.utc(transaction.processed_at).local().format("h:mm A")}</span>
                              </>
                            )}
                          </td>
                          <td>{valueToString(transaction?.kind)}</td>
                          <td>{displayCurrency(transaction.currency, transaction.amount)}</td>
                          <td>{uppercase(transaction.currency)}</td>
                          <td>{transaction.payment_method}</td>
                          <td>{transaction.source}</td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </Card.Section>
        </Card>

        <Popup
          okText={"Reopen"}
          onCancel={() => setAccountState(prevState => ({ ...prevState, reopenVisible: false }))}
          onOk={confirmReopenAccount}
          title={"Reopen Account"}
          type="warning"
          description={"Are you sure you want to reopen this account?"}
          open={accountState.reopenVisible}
        />
      </Form>
    </Page>
  );
}
