import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import { StatusCode } from "api/protocols";
import { UserLogout } from "api/rpc";
import { showError } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";

import { useGuestPortalContext } from "../GuestPortalContext";

import { NavigationDropdown } from "components/navigationDropdown/NavigationDropdown";
import { ButtonNew as Button } from "components/buttonNew/index";
import Callout from "components/callout/Callout";
import Spin from "components/spin/spin";

import "./portalNavigation.scss";
import "../../Customer/ClientPortal/Home/clientPortalHome.scss";
import { TFunction, useTranslation } from "react-i18next";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { MOBILE_WIDTH } from "helpers/ScreenSizes";
import classNames from "classnames";
import useOutsideAlerter from "hooks/useOutsideAlerter/useOutsideAlerter";

type NavLinkType = Partial<FontAwesomeIconProps> & {
  title: string;
  url: string;
  icon: IconProp; //define icon as being necessary
};

interface IState {
  profileDetailsOpen: boolean;
}

/** Structures the Layout for the Guest Portal based on acitve user.
 * -  Wrap Portal page with in component to provide layout.  Wrapped component will display as the main content.
 * -  Requires to be a child of a GuestPortalProvider
 */
export function PortalNavigation(props: { children: any }) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { portalState } = useGuestPortalContext();
  const [mobileLeftMenuVisible, setMobileLeftMenuVisible] = useState<boolean>(false);
  const windowSize = useWindowSize();
  const profileContainer = useRef<HTMLDivElement>();
  const [state, setState] = useState<IState>({
    profileDetailsOpen: false,
  });

  /* TODO: Build out subOptions UI if needed */
  const portalNavigationLinks: Array<NavLinkType & { subOptions?: NavLinkType[] }> = [
    {
      title: t("guest.portal.navigation.portal_navigation.001"),
      url: `/tee-on/portal/overview`,
      icon: ["far", "user"] as IconProp,
    },
    {
      title: t("guest.portal.navigation.portal_navigation.002"),
      url: `/tee-on/portal/bookings`,
      icon: ["far", "golf-flag-hole"] as IconProp,
    },
    {
      title: "Reservations",
      url: `/tee-on/portal/reservations`,
      icon: ["far", "calendar"] as IconProp,
    },
    {
      title: "Payment Methods",
      url: `/tee-on/portal/payment-methods`,
      icon: ["far", "credit-card"] as IconProp,
    },
    {
      title: "Memberships",
      url: `/tee-on/portal/memberships`,
      icon: ["far", "id-card-clip"] as IconProp,
    },
    {
      title: "Tickets",
      url: `/tee-on/portal/tickets`,
      icon: ["far", "ticket"] as IconProp,
    },
    {
      title: "Playing Partners",
      url: `/tee-on/portal/playing-partners`,
      icon: ["far", "users"] as IconProp,
    },
    {
      title: "Favourites",
      url: `/tee-on/portal/favourites`,
      icon: ["far", "heart"] as IconProp,
    },
    // {
    //   title: "Preferences",
    //   url: `/tee-on/portal/preferences`,
    //   icon: ["far", "sliders-up"] as IconProp,
    // },
  ];

  // Redirect user if not logged in
  useEffect(() => {
    if (portalState.guest === null) {
      sendUserToLogin();
    }
  }, [portalState.guest]);

  async function logoutUser() {
    const logoutRes = await UserLogout(true);

    if (logoutRes.status !== StatusCode.OK) {
      dispatch(showError(t("guest.portal.navigation.portal_navigation.003")));
      return;
    }

    sendUserToLogin();
  }

  function sendUserToLogin() {
    history.push(`/tee-on/portal/login`);
  }

  useOutsideAlerter(profileContainer, () => {
    setState(prevState => ({ ...prevState, profileDetailsOpen: false }));
  });

  function navigateToPage(url: string, newWindow = false) {
    if (newWindow) {
      window.open(url);
      return;
    }
    history.push(url);
  }

  return (
    <>
      <div className="portal-content-container">
        {mobileLeftMenuVisible && (
          <div className="ui-layout_left-nav-mobile">
            <div className="ui-left-nav_container">
              <div className="ui-left-nav_content">
                <div className="ui-left-nav-logo" style={{ marginBottom: "10px" }}>
                  <img src="../../../public/images/tee_on_menu_logo.png" alt="Tee-On Logo" />
                </div>
                <div className="ui-left-nav-header">
                  <div style={{ fontSize: "14px" }}>Profile Settings</div>
                </div>
                <div className="ui-left-nav-menu">
                  <div className="portal-sidebar">
                    <ul id="portal-navigation-list">
                      {portalNavigationLinks.map(({ title, url, subOptions, ...rest }) => {
                        return (
                          <li key={title + "_" + url}>
                            <NavLink
                              to={url}
                              activeClassName="active"
                              onClick={
                                mobileLeftMenuVisible && windowSize.width <= MOBILE_WIDTH
                                  ? () => setMobileLeftMenuVisible(false)
                                  : null
                              }
                            >
                              &nbsp;
                              <FontAwesomeIcon {...(rest as FontAwesomeIconProps)} />
                              &nbsp; &nbsp;
                              {title}
                            </NavLink>
                          </li>
                        );
                      })}

                      <li>
                        <NavLink onClick={() => logoutUser()} to={"/tee-on/portal/login"} activeClassName="none">
                          &nbsp;
                          <FontAwesomeIcon icon={["far", "sign-out"]} />
                          &nbsp; &nbsp;
                          {"Logout"}
                        </NavLink>
                      </li>
                    </ul>
                    <div className="portal-sidebar-bottom-bar">
                      {/* <Button
                        style={{ marginRight: "1rem" }}
                        size="medium"
                        type="primary"
                        block
                        onClick={() => navigateToPage("/tee-on/courses", true)}
                      >
                        Book a Tee Time
                        <FontAwesomeIcon style={{ marginLeft: "5px" }} icon={["far", "golf-flag-hole"]} />
                      </Button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {mobileLeftMenuVisible && (
          <>
            <div
              onClick={() => setMobileLeftMenuVisible(prev => !prev)}
              className="ui-layout_left-nav-mobile-backdrop"
            />
            <FontAwesomeIcon
              onClick={() => setMobileLeftMenuVisible(prev => !prev)}
              className="ui-layout_left-nav-mobile-backdrop-close-button"
              icon={["far", "xmark"]}
            />
          </>
        )}

        {windowSize.width > MOBILE_WIDTH && (
          <div className="left-menu-container-desktop">
            <div className="logo-container">
              <img className="reservations-navigation-tee-on-logo" src="../../../../public/images/Tee-On--KO.png"></img>
            </div>
            <div className="profile-settings-heading">
              <p>Profile Settings</p>
            </div>
            <div className="portal-sidebar">
              <ul id="portal-navigation-list">
                {portalNavigationLinks.map(({ title, url, subOptions, ...rest }) => {
                  return (
                    <li key={title + "_" + url}>
                      <NavLink to={url} activeClassName="active">
                        &nbsp;
                        <FontAwesomeIcon {...(rest as FontAwesomeIconProps)} />
                        &nbsp; &nbsp;
                        {title}
                      </NavLink>
                    </li>
                  );
                })}
                <li>
                  <NavLink onClick={logoutUser} to={"/tee-on/portal/login"} activeClassName="none">
                    &nbsp;
                    <FontAwesomeIcon icon={["far", "sign-out"]} />
                    &nbsp; &nbsp;
                    {"Logout"}
                  </NavLink>
                </li>
              </ul>
              <div className="portal-sidebar-bottom-bar">
                {/* <Button
                  style={{ marginRight: "1rem" }}
                  size="medium"
                  type="primary"
                  block
                  onClick={() => navigateToPage("/tee-on/courses", true)}
                >
                  Book a Tee Time
                  <FontAwesomeIcon style={{ marginLeft: "5px" }} icon={["far", "golf-flag-hole"]} />
                </Button> */}
              </div>
            </div>
          </div>
        )}
        {/* <div className="left-menu-container-desktop">
          <div className="logo-container">
            <img className="reservations-navigation-tee-on-logo" src="../../../../public/images/Tee-On--KO.png"></img>
          </div>
          <div className="profile-settings-heading">
            <p>Profile Settings</p>
          </div>
          <div className="portal-sidebar">
            <ul id="portal-navigation-list">
              {portalNavigationLinks.map(({ title, url, subOptions, ...rest }) => {
                return (
                  <li key={title + "_" + url}>
                    <NavLink to={url} activeClassName="active">
                      &nbsp;
                      <FontAwesomeIcon {...(rest as FontAwesomeIconProps)} />
                      &nbsp; &nbsp;
                      {title}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
        </div> */}

        <div
          className="ui-main-container"
          style={mobileLeftMenuVisible && windowSize.width <= MOBILE_WIDTH ? { filter: "blur(5px)" } : {}}
        >
          <div className="portal-navigation-header">
            <div
              className={classNames("portal-navigation-bar", {
                "portal-navigation-bar-mobile": windowSize.width <= MOBILE_WIDTH,
              })}
            >
              {windowSize.width <= MOBILE_WIDTH && (
                <img className="reservations-navigation-tee-on-logo" src="/public/images/Tee-On.png" />
              )}
              <div>
                {windowSize.width > MOBILE_WIDTH ? (
                  <div>
                    {portalState.guest ? (
                      <div
                        className="portal-navigation-dropdown"
                        ref={profileContainer}
                        onClick={() => setState(prevState => ({ ...prevState, profileDetailsOpen: true }))}
                      >
                        <p className="portal-navigation-user-info-name">
                          Hi, {portalState?.guest?.first_name}{" "}
                          {portalState?.guest?.last_name
                            ? `${portalState?.guest?.last_name?.charAt(0)?.toUpperCase()}.`
                            : ""}
                        </p>
                        <div className="portal-navigation-profile-image-container">
                          {portalState?.guest?.profile_image_source ? (
                            <img
                              className="portal-navigation-profile-image"
                              src={portalState?.guest?.profile_image_source}
                              alt="User profile image"
                            />
                          ) : (
                            <div className="client-portal-profile-default-image">
                              <p>
                                {portalState?.guest?.first_name?.charAt(0)?.toUpperCase()}
                                {portalState?.guest?.last_name?.charAt(0)?.toUpperCase()}
                              </p>
                            </div>
                          )}
                        </div>
                        <div
                          className={classNames("portal-navigation-dropdown-container", {
                            "portal-navigation-display-user-info-dropdown": state.profileDetailsOpen,
                          })}
                        >
                          <FontAwesomeIcon
                            onClick={e => {
                              e.stopPropagation();
                              setState(prevState => ({ ...prevState, profileDetailsOpen: false }));
                            }}
                            icon={["far", "xmark"]}
                            className="portal-navigation-close-button"
                          />
                          <div className="portal-navigation-user-info-container">
                            <div className="portal-navigation-profile-image-container">
                              {portalState?.guest?.profile_image_source ? (
                                <img
                                  className="portal-navigation-profile-image"
                                  src={portalState?.guest?.profile_image_source}
                                  alt="User profile image"
                                />
                              ) : (
                                <div className="client-portal-profile-default-image">
                                  <p>
                                    {portalState?.guest?.first_name?.charAt(0)?.toUpperCase()}
                                    {portalState?.guest?.last_name?.charAt(0)?.toUpperCase()}
                                  </p>
                                </div>
                              )}
                            </div>
                            <div>
                              <p className="portal-navigation-user-name">Hello, {`${portalState?.guest?.full_name}`}</p>
                              <p className="portal-navigation-user-email">{portalState?.guest?.email}</p>
                            </div>
                          </div>
                          <div
                            className="portal-navigation-actions-container"
                            onClick={e => {
                              e.stopPropagation();
                              setState(prevState => ({ ...prevState, profileDetailsOpen: false }));
                            }}
                          >
                            <div onClick={logoutUser} className="portal-navigation-action">
                              <FontAwesomeIcon
                                className="portal-navigation-action-icon"
                                icon={["far", "right-from-bracket"]}
                              />
                              <span>Logout</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <Button
                          onClick={sendUserToLogin}
                          disabled={portalState.loading}
                          className="portal-navigation-login-button"
                        >
                          <span className="portal-navigation-login-button-label">Login</span>
                          <FontAwesomeIcon icon={["fal", "arrow-right-long"]} />
                        </Button>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="header-menu-icon" onClick={() => setMobileLeftMenuVisible(prev => !prev)}>
                    <div className="portal-navigation-dropdown">
                      <p className="portal-navigation-user-info-name">
                        Hi, {portalState?.guest?.first_name}{" "}
                        {portalState?.guest?.last_name
                          ? `${portalState?.guest?.last_name?.charAt(0)?.toUpperCase()}.`
                          : ""}
                      </p>
                      <div className="portal-navigation-profile-image-container">
                        {portalState?.guest?.profile_image_source ? (
                          <img
                            className="portal-navigation-profile-image"
                            src={portalState?.guest?.profile_image_source}
                            alt="User profile image"
                          />
                        ) : (
                          <div className="client-portal-profile-default-image">
                            <p>
                              {portalState?.guest?.first_name?.charAt(0)?.toUpperCase()}
                              {portalState?.guest?.last_name?.charAt(0)?.toUpperCase()}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="portal-content">
            {renderContent(!!portalState.guest, portalState.loading, props.children, t)}
          </div>
        </div>
      </div>
    </>
  );
}

/** Renders either a Spinner || Callout || Children */
const renderContent = (
  activeUser: boolean,
  userLoading: boolean,
  children: any,
  t: TFunction<"translation", undefined>,
) => {
  // Trying to load user
  if (userLoading) {
    return (
      <div className="portal-spinner">
        <span>
          <Spin />
        </span>
      </div>
    );
  } else {
    // check if user found
    if (!activeUser) {
      return (
        <div className="portal-callout">
          <Callout
            type={"error"}
            title={t("guest.portal.navigation.portal_navigation.006")}
            content={t("guest.portal.navigation.portal_navigations.007")}
          />
        </div>
      );
    }
  }

  // Not loading && active user
  return children as JSX.Element;
};
