import React from "react";
import { useTranslation, Trans } from "react-i18next";

import Callout from "components/callout/Callout";
import Spin from "components/spin/spin";
import { ICreditCard } from "pages/guest/Customer/CustomerProfile";
import CreditCardTab from "./CreditCardTab";
import { ButtonNew as Button } from "components/buttonNew";

type CreditCardCatalogueProps = {
  customerId: number; //necessary for spinner to appear on async calls

  creditCards: ICreditCard[];
  selectedCard: number;
  setSelectedCard: (paymentId: number) => void;
  backButtonAction?: () => void;

  /** Hide 'Select Credit Card' from the component */
  hideTitle?: boolean;
};

export default function CreditCardCatalogue(props: CreditCardCatalogueProps) {
  const { customerId, creditCards, selectedCard, hideTitle, backButtonAction } = props;
  const { t, i18n } = useTranslation();

  const noCustomerSelected = customerId === null || customerId === undefined;
  const creditCardsLoading =
    (customerId !== null || customerId !== undefined) && (creditCards === undefined || creditCards === null);

  return (
    <div className="flex flex-column">
      {!hideTitle && (
        <p className="text-lg text-semibold my-2">{t("elements.tee_sheet.credit_card.credit_card_catalogue.001")}</p>
      )}
      {noCustomerSelected ? (
        <Callout
          type="info"
          title={t("elements.tee_sheet.credit_card.credit_card_catalogue.002")}
          content={t("elements.tee_sheet.credit_card.credit_card_catalogue.003")}
        />
      ) : (
        <>
          {creditCardsLoading ? (
            <div style={{ display: "flex", width: "2rem", height: "4.5rem", margin: "auto" }}>
              <Spin className="self-center" />
            </div>
          ) : (
            <>
              {creditCards.length >= 1 ? (
                <div className="ui-checkbox-group">
                  {creditCards.map((paymentMethod, index) => {
                    return (
                      <CreditCardTab
                        key={index}
                        card={paymentMethod}
                        isSelected={paymentMethod.id === selectedCard}
                        setSelectedCard={props.setSelectedCard}
                      />
                    );
                  })}
                </div>
              ) : (
                <Callout
                  type="warning"
                  title={t("elements.tee_sheet.credit_card.credit_card_catalogue.004")}
                  content={t("elements.tee_sheet.credit_card.credit_card_catalogue.005")}
                />
              )}
              {backButtonAction && (
                <Button size="medium" type="text" onClick={backButtonAction}>
                  {t("elements.tee_sheet.credit_card.credit_card_catalogue.006")}
                </Button>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
