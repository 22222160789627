import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetTeeDecks } from "api/rpc/2024-04/facilityAdmin/facility/teedeck";
import { GetDivision } from "api/rpc/2024-04/facilityAdmin/facility/division";
import {
  PostTournamentScoresheet,
  TPostTournamentScoresheet,
} from "api/rpc/2024-04/facilityAdmin/tournament/scoring/scoresheet";
import { GetCourse } from "api/rpc/2024-04/facilityAdmin/facility/course";

import { showError, showSuccess } from "redux/actions/ui";
import { ICourse, IDivision, ITeeDeck } from "redux/reducers/models/facility";
import { useAppDispatch } from "hooks/redux";
import { handleChangeEventInput, isEmpty } from "helpers/Helpers";

import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import Page from "components/page/Page";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import { Select } from "components/select";
import { IPrimaryPageAction } from "components/page/PageActions";

export default function TournamentScoresheetNew() {
  const history = useHistory();
  const { t } = useTranslation();
  const { tournamentId } = useParams<{ tournamentId: string }>();

  const dispatch = useAppDispatch();

  const [scoresheet, setScoresheet] = useState<TPostTournamentScoresheet>({
    name: "",
    tournament_id: tournamentId,
    date_played: "",
    division_front_id: null,
    division_back_id: null,
    tee_deck_id: null,
  });

  const [filterState, setFilterState] = useState({
    course_id: -1,
    date_played: null,
  });

  const [teedecks, setTeedecks] = useState<ITeeDeck[]>([]);
  const [divisions, setDivisions] = useState<IDivision[]>([]);
  const [courses, setCourses] = useState<ICourse[]>([]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadTeeDecks(source.token);
    void loadTournamentDivisions(source.token);
    void loadFacilityCourses(source.token);
    return () => source.cancel();
  }, []);

  async function loadTeeDecks(token?: CancelToken) {
    const res = await GetTeeDecks(null, token ? false : true, token);
    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      return;
    }

    setTeedecks(res.data);
  }

  async function loadTournamentDivisions(token?: CancelToken) {
    const res = await GetDivision(null, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      return;
    }

    setDivisions(res.data);
  }

  async function loadFacilityCourses(token?: CancelToken) {
    const res = await GetCourse(null, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      return;
    }

    const courses = res.data;
    setCourses(res.data);

    // If facility only has 1 course -- preset it as default
    if (courses.length === 1) {
      setFilterState(prev => ({ ...prev, course_id: courses[0].id }));
    }
  }

  async function createScoresheet() {
    const res = await PostTournamentScoresheet(scoresheet, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError(typeof res.data === "string" ? res.data : "Error creating scoresheet."));
      return;
    }

    dispatch(showSuccess("Successfully created scoresheet.")); // TODO: Translation
    history.push("/admin/tournament/" + tournamentId + "/scoring");
  }

  /** Reset division_front_id and division_back_id with change. */
  function handleCourseChange(course_id: number) {
    setFilterState(prev => ({ ...prev, course_id }));
    setScoresheet(prev => ({ ...prev, division_back_id: null, division_front_id: null }));
  }

  function handleDateChange(selectedDate: Date) {
    const dateString = new Date(selectedDate).toISOString().slice(0, 10);
    setFilterState(prev => ({ ...prev, date_played: selectedDate }));
    setScoresheet(prev => ({ ...prev, date_played: dateString }));
  }

  const primaryAction: IPrimaryPageAction = {
    content: t("secure.facility.league.league_scoresheet_new.002"),
    action: () => createScoresheet(),
    disabled: requirementCheck(scoresheet),
  };

  return (
    <Page
      title={t("secure.facility.league.league_scoresheet_new.008")}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.league.league_scoresheet_new.009"),
          url: "/admin/tournament/" + tournamentId + "/scoring",
        },
      ]}
    >
      <Card>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Input
                id="name"
                value={scoresheet.name || ""}
                onChange={e => handleChangeEventInput(e, scoresheet, setScoresheet)}
                label={t("secure.facility.league.league_scoresheet_new.003")}
                placeholder={t("secure.facility.league.league_scoresheet_new.004")}
              />
              <DatePickerInput
                label={"Date"}
                months={1}
                position="right"
                startingDate={filterState.date_played}
                setStartingDate={handleDateChange}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <Select
                label={"Course"} // TODO: Translation
                onChange={(value: number) => handleCourseChange(value)}
                defaultValue={filterState.course_id}
              >
                <Select.Option key="none" value={-1} name="none">
                  None
                </Select.Option>
                {courses?.map(course => {
                  return (
                    <Select.Option key={course.id} value={course.id} name={course.long_name}>
                      {course.long_name}
                    </Select.Option>
                  );
                })}
              </Select>
            </FormLayout.Group>
            <FormLayout.Group>
              <Select
                label={t("secure.facility.league.league_scoresheet_new.005")}
                onChange={(value: number) => setScoresheet(prev => ({ ...prev, division_front_id: value }))}
                disabled={filterState.course_id === -1}
              >
                {divisions
                  .filter(division => division.course_id === filterState.course_id)
                  .map(division => {
                    return (
                      <Select.Option key={division.id} value={division.id} name={division.title}>
                        {division.title}
                      </Select.Option>
                    );
                  })}
              </Select>

              <Select
                label={t("secure.facility.league.league_scoresheet_new.006")}
                onChange={(value: number) => setScoresheet(prev => ({ ...prev, division_back_id: value }))}
                disabled={filterState.course_id === -1}
              >
                {divisions
                  .filter(division => division.course_id === filterState.course_id)
                  .map(division => {
                    return (
                      <Select.Option key={division.id} value={division.id} name={division.title}>
                        {division.title}
                      </Select.Option>
                    );
                  })}
              </Select>
            </FormLayout.Group>
            <FormLayout.Group>
              <Select
                label={t("secure.facility.league.league_scoresheet_new.007")}
                onChange={(value: any) => setScoresheet(prev => ({ ...prev, tee_deck_id: value }))}
              >
                {teedecks.map(teeDeck => {
                  return (
                    <Select.Option key={teeDeck.id} value={teeDeck.id} name={teeDeck.title}>
                      {teeDeck.title}
                    </Select.Option>
                  );
                })}
              </Select>
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>
    </Page>
  );
}

/** Returns true if the object's required values are either null || empty. */
function requirementCheck(obj: TPostTournamentScoresheet) {
  const nonEmptyRequired = Object.keys(obj).some((key: keyof TPostTournamentScoresheet) => {
    // Do not apply to optional inputs of the object
    if (key !== "division_back_id") {
      return isEmpty(obj[key]);
    }
  });

  return nonEmptyRequired;
}
