import { ConnectDropTarget, XYCoord, useDrop } from "react-dnd";
import {
  EditInputDragItemTypes,
  EditInputRows,
  IEditInputDropItem,
} from "../useDropEditInputLayout/useDropEditInputLayout";
import { cloneDeep, inRange } from "lodash";
import EditInputRowCustomDragLayer from "./EditInputRowCustomDragLayer";
import React from "react";

/*
  Types should not be reused on multiple validation rules because
  then it won't be possible to tell which rule applies
  e.g. { types: ["text"], maxSize: 1 }, { types: ["text"], maxSize: 2 }
  They should also be exhaustive
*/
interface IEditInputRowDropValidationRule {
  types: string[];
  maxSize: number;
}

interface IUseDropEditInputRowState {
  drop: ConnectDropTarget;
  canDrop: boolean;
  isOver: boolean;
  customDragLayer: JSX.Element;
}

export interface IEditInputRowRange {
  startX: number;
  endX: number;
}

export function generateInputRowsWithSequentialPositions<Input extends IEditInputDropItem>(
  inputRows: EditInputRows<Input>,
): EditInputRows<Input> {
  if (!inputRows) {
    return undefined;
  }

  const updatedInputRows: EditInputRows<Input> = {};

  Object.keys(inputRows).forEach((row, rowIndex) => {
    const updatedRow = rowIndex + 1;
    const updatedRowKey = String(updatedRow);

    updatedInputRows[updatedRowKey] = inputRows[row].map((input, columnIndex) => {
      return {
        ...input,
        row: updatedRow,
        column: columnIndex + 1,
      };
    });
  });

  return updatedInputRows;
}

export function useDropEditInputRow<Input extends IEditInputDropItem>(
  inputs: Input[],
  rowRef: React.MutableRefObject<HTMLDivElement>,
  rows: EditInputRows<Input>,
  inputRow: string,
  inputRefs: React.MutableRefObject<HTMLDivElement[]>,
  updatePositions: (editInputRows: EditInputRows<Input>) => void,
  dropValidationRules: IEditInputRowDropValidationRule[],
): IUseDropEditInputRowState {
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: EditInputDragItemTypes.INPUT,
      canDrop: (item: Input, monitor) => {
        if (!inputs || inputs.length === 0 || !dropValidationRules) {
          return false;
        }

        const isSource = !!inputs.find(input => input.id === item.id);

        if (isSource) {
          return true;
        }

        const inputRowDropValidationRule = dropValidationRules.find(dropValidationRule => {
          return dropValidationRule.types.some(type => type === inputs[0].type);
        });

        if (!inputRowDropValidationRule) {
          return false;
        }

        const isValidDropType = inputRowDropValidationRule.types.some(type => type === item.type);
        const isLessThanMaxSize = inputRowDropValidationRule.maxSize > inputs.length;

        return isValidDropType && isLessThanMaxSize;
      },
      drop: (item: Input, monitor) => {
        const rowBoundingRectangle = rowRef.current.getBoundingClientRect();

        const rowCoordinate: XYCoord = {
          x: rowBoundingRectangle.x,
          y: rowBoundingRectangle.y,
        };

        const clientDragCoordinate: XYCoord = monitor.getClientOffset();

        const relativeDragCoordinate: XYCoord = {
          x: clientDragCoordinate.x - rowCoordinate.x,
          y: clientDragCoordinate.y - rowCoordinate.y,
        };

        const [inputDropIndex, inputRanges] = getRowInputDropIndexInRange(rowCoordinate, relativeDragCoordinate);

        if (inputDropIndex === undefined) {
          return;
        }

        const updatedInputs: Input[] = [];

        const inputToDrag = cloneDeep(rows?.[item.row]?.find(input => input.id === item.id));

        if (!inputToDrag) {
          return;
        }

        let currentColumn = 1;

        for (let i = 0; i < Math.max(inputs.length, inputDropIndex + 1); i++) {
          const input = inputs?.[i];
          const isValidNonDragInput = input && input.id !== item.id;

          if (i === inputDropIndex) {
            updatedInputs.push({ ...inputToDrag, row: Number(inputRow), column: currentColumn++ });

            if (isValidNonDragInput) {
              updatedInputs.push({ ...input, column: currentColumn++ });
            }
          } else if (isValidNonDragInput) {
            updatedInputs.push({ ...input, column: currentColumn++ });
          }
        }

        let updatedSourceRow: Input[] = undefined;
        const itemFromAnotherRow = inputRow !== String(item.row);

        if (itemFromAnotherRow) {
          updatedSourceRow = rows?.[item.row]
            ?.filter(input => input.id !== item.id)
            ?.map((input, index) => {
              return {
                ...input,
                column: index + 1,
              };
            });
        }

        const { [item.row]: originalSourceRow, ...rest } = rows;

        const updatedInputRows: EditInputRows<Input> = {
          ...rest,
          [inputRow]: updatedInputs,
          ...(itemFromAnotherRow && updatedSourceRow?.length > 0 ? { [item.row]: updatedSourceRow } : {}),
        };

        const updatedInputRowsWithSequentialPositions = generateInputRowsWithSequentialPositions(updatedInputRows);

        updatePositions(updatedInputRowsWithSequentialPositions);
      },
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [rows],
  );

  function getRowInputDropIndexInRange(
    rowCoordinate: XYCoord,
    relativeDragCoordinate: XYCoord,
  ): [number, IEditInputRowRange[]] {
    const inputRelativeBoundingRectangles = inputRefs.current.map(inputRef => {
      const inputBoundingRectangle = inputRef.getBoundingClientRect();

      return {
        x: inputBoundingRectangle.x - rowCoordinate.x,
        y: inputBoundingRectangle.y - rowCoordinate.y,
        width: inputBoundingRectangle.width,
      };
    });

    let currentStartXPosition = 0;

    const inputRanges: IEditInputRowRange[] = inputRelativeBoundingRectangles.map((inputBoundingRectangle, index) => {
      const startX = currentStartXPosition;
      const inputEndX = inputBoundingRectangle.x + inputBoundingRectangle.width;
      let endX = undefined;

      if (index === inputRelativeBoundingRectangles.length - 1) {
        const rowBoundingRectangle = rowRef.current.getBoundingClientRect();
        endX = rowBoundingRectangle.right - rowBoundingRectangle.x;
      } else {
        const nextInputStartX = inputRelativeBoundingRectangles[index + 1].x;
        const halfOfGapBetweenInputs = (nextInputStartX - inputEndX) / 2;
        endX = inputEndX + halfOfGapBetweenInputs;
      }

      currentStartXPosition = endX;

      return {
        startX,
        endX,
      };
    });

    let inputDropIndex: number = undefined;

    inputRanges.forEach((inputRange, index) => {
      const firstHalf: IEditInputRowRange = {
        startX: inputRange.startX,
        endX: (inputRange.endX - inputRange.startX) / 2 + inputRange.startX,
      };

      const secondHalf: IEditInputRowRange = {
        startX: firstHalf.endX,
        endX: inputRange.endX,
      };

      if (inRange(relativeDragCoordinate.x, firstHalf.startX, firstHalf.endX)) {
        inputDropIndex = index;
      } else if (inRange(relativeDragCoordinate.x, secondHalf.startX, secondHalf.endX)) {
        inputDropIndex = index + 1;
      }
    });

    return [inputDropIndex, inputRanges];
  }

  const customDragLayer = (
    <EditInputRowCustomDragLayer
      rowRef={rowRef}
      isOver={isOver}
      canDrop={canDrop}
      getRowInputDropIndexInRange={getRowInputDropIndexInRange}
    />
  );

  return {
    drop,
    canDrop,
    isOver,
    customDragLayer,
  };
}
