import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios, { CancelToken } from "axios";
import classNames from "classnames";
import Portal from "elements/Portal";
import { delay } from "helpers/Helpers";
import React, { useEffect, useState } from "react";
import "./bottomSheet.scss";

type TBottomSheetAction = {
  label: string;
  onClick: () => void;
  disabled?: boolean;
};

type TBottomSheetType = "info" | "warning" | "error";

type TProps = {
  /**Visibility of the sheet*/
  open: boolean;
  /**The string content that is displayed in the sheet */
  content: string;
  /**Callback to state function to close the sheet*/
  onClose: () => void;
  /**Callback to a function when primary button is clicked*/
  primaryAction: TBottomSheetAction;
  /**Overrides cancel button*/
  secondaryAction?: TBottomSheetAction;
  /**Determines styling of entire sheet */
  type: TBottomSheetType;
  /**Overrides default icon*/
  icon?: IconName;
};

export default function BottomSheet(props: TProps) {
  const { open, icon, content, onClose, primaryAction, secondaryAction, type } = props;
  const [mounted, setMounted] = useState<boolean>(false);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void delayMount(open, source.token);
    return () => source.cancel();
  }, [open]);

  async function delayMount(mounted: boolean, token: CancelToken) {
    if (!mounted) {
      if (token.reason) {
        return;
      }
      await delay(0.2);
    }
    setMounted(mounted);
  }

  return (
    <Portal isMounted={open}>
      <div
        onClick={() => onClose()}
        className={classNames("ui-bottom-sheet-backdrop-hidden", { "ui-bottom-sheet-backdrop": open && mounted })}
      ></div>
      <div
        style={{ opacity: open && mounted ? "1" : undefined }}
        className={classNames("ui-bottom-sheet-container", {
          [`ui-bottom-sheet-${type}`]: type,
        })}
      >
        <div className="ui-bottom-sheet-header">
          {icon ? <FontAwesomeIcon icon={["far", icon]} size="1x" /> : <BottomSheetIcon type={type} />}
          <FontAwesomeIcon onClick={() => onClose()} icon={["far", "xmark"]} size="1x" className="cursor-pointer" />
        </div>
        <p className="ui-bottom-sheet-content">{content}</p>
        <div className={classNames("ui-bottom-sheet-footer", { "ui-bottom-sheet-footer-centered": !secondaryAction })}>
          {secondaryAction ? (
            <button
              className="ui-bottom-sheet-button"
              onClick={secondaryAction?.onClick}
              disabled={secondaryAction?.disabled}
            >
              {secondaryAction?.label}
            </button>
          ) : (
            <button className="ui-bottom-sheet-button" onClick={() => onClose()}>
              Close
            </button>
          )}
          <button
            className={`ui-bottom-sheet-button ui-bottom-sheet-button-primary ui-bottom-sheet-button-primary-${type}`}
            onClick={primaryAction?.onClick}
            disabled={primaryAction?.disabled}
          >
            {primaryAction?.label}
          </button>
        </div>
      </div>
    </Portal>
  );
}

const BottomSheetIcon = ({ type }: { type: TBottomSheetType }) => {
  switch (type) {
    case "info":
      return <FontAwesomeIcon icon={["far", "info-circle"]} size="1x" />;
    case "error":
      return <FontAwesomeIcon icon={["far", "exclamation-circle"]} size="1x" />;
    case "warning":
      return <FontAwesomeIcon icon={["far", "exclamation-triangle"]} size="1x" />;
    default:
      <FontAwesomeIcon icon={["far", "info-circle"]} size="1x" />;
  }
};
