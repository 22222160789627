import { APIGet, APIPost, APIPut, APIDelete, IAPIResponse } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2022-09";
const baseCustomerUrl = "/" + apiVersion + "/customer";

export interface ICustomerFriend {
  email: string;
  first_name: string;
  full_name: string;
  id: number;
  initials: string;
  last_name: string;
  phone: string;
  customer_type: Array<{ title: string }>;
}

interface ICustomerFriendRes extends IAPIResponse {
  data: Array<ICustomerFriend>;
  message: string;
}

interface IPostPaymentMethodParams {
  payment_method_id: string;
  client_id: number;
  facility_id?: number;
  save_card: boolean;
}

//GET Customer bookings
export async function GetCustomerBookings(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseCustomerUrl + "/tee-time/booking", params, useGlobalLoader);
}

//PUT Remove Customer Booking
export async function RemoveCustomerBooking(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseCustomerUrl + "/tee-time/booking/cancel", params, useGlobalLoader);
}

//DELETE Customer Credit Cards
export async function DeleteCustomerCreditCard(params: any, useGlobalLoader: boolean) {
  return await APIDelete(baseCustomerUrl + "/customer/payment-method", params, useGlobalLoader);
}

//PUT Update Customer Profile
export async function UpdateCustomerProfile(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseCustomerUrl + "/customer", params, useGlobalLoader);
}

//GET Customer search
export async function SearchCustomer(params: any, useGlobalLoader: boolean, cancelToken?: CancelToken) {
  return await APIGet(baseCustomerUrl + "/customer/search", params, useGlobalLoader, cancelToken);
}

//GET Customer Friends
export async function GetCustomerFriends(
  params: any,
  useGlobalLoader: boolean,
  cancelToken?: CancelToken,
): Promise<ICustomerFriendRes> {
  return await APIGet(baseCustomerUrl + "/customer/friend", params, useGlobalLoader, cancelToken);
}

//PUT Add Friend
export async function AddFriend(params: any, useGlobalLoader?: boolean) {
  return await APIPost(baseCustomerUrl + "/customer/friend", params, useGlobalLoader);
}

//DELETE Remove Friend
export async function RemoveFriend(params: any, useGlobalLoader?: boolean) {
  return await APIDelete(baseCustomerUrl + "/customer/friend", params, useGlobalLoader);
}

//POST Add Gift-Card
export async function AddGiftCard(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseCustomerUrl + "/customer/gift-card", params, useGlobalLoader);
}

export async function GetCustomer(params: { id: number }, useGlobalLoader: boolean) {
  return await APIGet(baseCustomerUrl + "/customer", params, useGlobalLoader);
}

export async function UploadCustomerProfileImage(params: FormData, useGlobalLoader: boolean) {
  return await APIPost(baseCustomerUrl + "/customer/profile-image", params, useGlobalLoader);
}

export async function DeleteCustomerProfileImage(useGlobalLoader: boolean) {
  return await APIDelete(baseCustomerUrl + "/customer/profile-image", null, useGlobalLoader);
}

//GET Customer Credit Cards
export async function GetCustomerPaymentMethods(
  params: { client_id: number; id?: number; type?: Array<string> },
  useGlobalLoader: boolean,
) {
  return await APIGet(baseCustomerUrl + "/payment-method", params, useGlobalLoader);
}

export async function PostSetup(useGlobalLoader?: boolean) {
  return await APIPost(baseCustomerUrl + "/payment-method/setup", undefined, useGlobalLoader);
}

export async function PostPaymentMethod(params: IPostPaymentMethodParams, useGlobalLoader?: boolean) {
  return await APIPost(baseCustomerUrl + "/payment-method", params, useGlobalLoader);
}

export async function GetCustomerTickets(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseCustomerUrl + "/ticket", params, useGlobalLoader);
}

export async function PrintCustomerTicket(params: { ticket_id: number; stub_id?: number }, useGlobalLoader: boolean) {
  return await APIGet(baseCustomerUrl + "/ticket/download", params, useGlobalLoader);
}

export async function DeleteCustomerPaymentMethod(params: { id: number; client_id: number }, useGlobalLoader: boolean) {
  return await APIDelete(baseCustomerUrl + "/payment-method", params, useGlobalLoader);
}
