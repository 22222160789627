import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useHistory, useParams } from "react-router";
import "pages/guest/Customer/TeeTimeLogin.scss";
import { StatusCode } from "api/protocols";
import { GetBookingEngine } from "api/rpc/bookingEngine/bookingEngine";
import { IBookingEngineState } from "redux/reducers/bookingEngine";
import { IBookingEngineActions } from "redux/actions/actionTypes";
import Popup from "components/popup/Popup";
import { PostPasswordResetCode } from "api/rpc/2022-09/passwordReset";
import Input from "components/form/input/Input";

export interface ISignInInfo {
  email: string;
  facilityId: number;
  bookingEngineId: number;
  emailSuccessActive: boolean;
}

export interface IFilterState {
  selectedCourseLogo: string;
  selectedCourseName: string;
  selectedHeaderBackground: string;
  weather: string;
}

interface IProps {
  bookingEngineStore: IBookingEngineState;
  bookingEngineActions: IBookingEngineActions;
}

interface IParams {
  bookingEngineHandle: string;
}

const TeeTimeForgotPassword: React.FC<IProps> = props => {
  const { bookingEngineHandle } = useParams<IParams>();
  const { bookingEngineStore, bookingEngineActions } = props;

  const [signInInfo, setSignInInfo] = useState<ISignInInfo>({
    email: "",
    facilityId: null,
    bookingEngineId: null,
    emailSuccessActive: false,
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    selectedCourseName: bookingEngineStore.selectedCourseLongName,
    selectedCourseLogo: bookingEngineStore.selectedCourseLogo,
    selectedHeaderBackground: "https://www.foresightgolf.net/images/template/banner-cypresswood.jpg",
    weather: null,
  });

  /*  */
  useEffect(() => {
    if (
      bookingEngineStore.selectedAddress === "" &&
      bookingEngineStore.selectedDate === "" &&
      bookingEngineStore.selectedLoginRequired === null &&
      bookingEngineStore.selectedTeeTimeId === ""
    ) {
      document.addEventListener("keydown", logKey);

      void getBookingEngine();

      //Fill in state with missing info lost on refresh
    } else {
      console.log("Data in store");
      document.addEventListener("keydown", logKey);

      updateSignInInfo({
        facilityId: bookingEngineStore.selectedFacilityId,
        bookingEngineId: bookingEngineStore.selectedBookingEngineId,
      });
    }
  }, []);

  function logKey(ev: KeyboardEvent) {
    if (ev.code === "Enter") {
      document.getElementById("submit-button").click();
    }
  }

  function updateSignInInfo(newFilterState: Partial<ISignInInfo>) {
    setSignInInfo(cur => {
      return { ...cur, ...newFilterState };
    });
  }

  function updateFilterState(newFilterState: Partial<IFilterState>) {
    setFilterState(cur => {
      return { ...cur, ...newFilterState };
    });
  }

  async function getBookingEngine() {
    const params = {
      handle: bookingEngineHandle,
    };

    console.log(params);

    const bookingEngineRes = await GetBookingEngine(params, true);
    console.log(bookingEngineRes);

    if (bookingEngineRes.status !== StatusCode.OK) {
      alert(bookingEngineRes.message);
      return;
    }

    console.log(bookingEngineRes);

    updateFilterState({
      selectedCourseName: bookingEngineRes.data[0].facility.long_name,
      selectedCourseLogo: bookingEngineRes.data[0].facility.logo_source,
    });

    updateSignInInfo({
      facilityId: bookingEngineRes.data[0].facility_id,
      bookingEngineId: bookingEngineRes.data[0].id,
    });

    bookingEngineActions.update({
      selectedFacilityId: bookingEngineRes.data[0].facility_id,
      selectedBookingEngineId: bookingEngineRes.data[0].id,
      selectedCourseLongName: bookingEngineRes.data[0].facility.long_name,
      selectedCourseLogo: bookingEngineRes.data[0].facility.logo_source,
      selectedHeaderBackground: filterState.selectedHeaderBackground,
    });
  }

  //Updates login email as user types
  function updateEmail(event: React.ChangeEvent<HTMLInputElement>) {
    updateSignInInfo({ email: event.target.value });
  }

  async function sendPasswordRecovery() {
    console.log(signInInfo.email);
    console.log(bookingEngineStore.selectedFacilityId);

    if (signInInfo.email === "") {
      alert("Please enter a valid email for recovery");
      return;
    }

    const params = {
      email: signInInfo.email,
      facility_id: bookingEngineStore.selectedFacilityId,
      booking_engine_id: signInInfo.bookingEngineId,
    };

    const resetRes = await PostPasswordResetCode(params, true);

    console.log(resetRes);

    if (resetRes.status === StatusCode.OK) {
      updateSignInInfo({
        emailSuccessActive: true,
      });
    } else {
      alert(resetRes.data.message);
      return;
    }
  }

  const handleEmailSuccess = () => {
    window.close();
  };

  return (
    <div>
      <div className="ttl-header-container">
        <span className="ttl-bars-icon-div">
          <img className="ttl-tee-on-logo" src="/../../../public/images/Tee-On.png" alt="Tee-On Logo" />
        </span>
        <span className="ttl-user-div">
          <div className="ttl-active-user-name">{/* <Weather weather={filterState.weather} size={"small"} /> */}</div>
        </span>
      </div>

      <Popup
        open={signInInfo.emailSuccessActive}
        type="success"
        title="Email Sent Successfully"
        description="Check your inbox or spam folder."
        onOk={handleEmailSuccess}
        okText="Back to login"
      />

      <div className="tt-login-container">
        <div className="ttl-sign-in-text">
          <p className="sign-in-p">Account Recovery</p>
          <p className="sign-in-info-p">Please enter email address for account recovery.</p>
        </div>

        <div className="tt-sign-in-email-password-container">
          <Input label="Email Address" value={signInInfo.email} onChange={updateEmail} />
        </div>

        <br />

        <div className="sign-in-register-container">
          <button id="submit-button" className="sign-in-button" onClick={sendPasswordRecovery}>
            SEND
          </button>
        </div>
      </div>
    </div>
  );
};

export default TeeTimeForgotPassword;
