import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IAPIResponse, StatusCode } from "api/protocols";
import { GetProductTypes as GetFacilityProductTypes } from "api/rpc/2022-09/facilityAdmin/product/product";
import { GetProductTypesClient as GetClientProductTypes } from "api/rpc/2022-09/clientAdmin/product/product";

import { filterHistoryUpdateFilter } from "redux/actions/filters/filterHistory";
import { IProduct } from "redux/reducers/models/product";
import { sortObj } from "helpers/Helpers";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import DropFilter from "components/dropdown/DropFilter";
import { DropFilterCheckboxOptionals } from "../dropdown/dropFilterTypes";

type FacilityDropFilterProps = DropFilterCheckboxOptionals & {
  /** callback value to inform parent when ASYNC data is loaded */
  dataLoaded?: () => void;
  /** When 'Apply' is clicked, returns all title's of selected Product Types */
  handleApply: (productTypeTitles: Array<string>) => void;

  userLevel: "facility" | "client";
};

export default function ProductTypesDropFilter(props: FacilityDropFilterProps) {
  const [productTypes, setProductTypes] = useState<IProduct[]>(null);
  const { dataLoaded, handleApply, userLevel } = props;
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();
  const { filterHistoryStore } = useAppSelector(store => store);

  useEffect(() => {
    let mounted = true;

    const loadProductTypes = async () => {
      let asyncFunction: Promise<IAPIResponse>;

      switch (userLevel) {
        case "facility": {
          asyncFunction = GetFacilityProductTypes(null, false);
          break;
        }
        case "client": {
          asyncFunction = GetClientProductTypes(null, false);
          break;
        }
        default: {
          return;
        }
      }

      const { status, data } = await asyncFunction;

      const sortedData = sortObj<IProduct>(data, "title");

      if (status === StatusCode.OK && mounted) {
        setProductTypes(sortedData);
        if (filterHistoryStore[props.historyKey]) {
          handleApply(filterHistoryStore[props.historyKey].currentFilter);
        }
        dataLoaded && dataLoaded();
      }
    };

    void loadProductTypes();

    return () => {
      mounted = false;
    };
  }, []);

  // Capture filter titles and apply callback
  function handleApplyFilter(filterReturn: Record<string, any>[]) {
    const tempReturn: string[] = filterReturn.map((value: Record<string, any>, index: number) => {
      return value.title as string;
    });
    if (props.historyKey) {
      dispatch(filterHistoryUpdateFilter(props.historyKey, tempReturn));
    }
    handleApply(tempReturn);
  }

  return (
    <DropFilter
      key="product_types_titles"
      title={t("components.filters.product_types_drop_filter.001")} //TODO: Translation
      filterData={productTypes}
      filterLabelPropFromData="title"
      filterIdPropFromData="id"
      filterType="Checkbox"
      applyFilters={filterReturn => handleApplyFilter(filterReturn)}
      defaultCheckboxes={props.defaultCheckboxes}
      leftAligned={props.leftAligned ?? false}
      rightAligned={props.rightAligned ?? false}
      dropdownSize={props.dropdownSize}
      darkMode={props.darkMode}
      save={props.save}
      historyKey={props.historyKey}
      restrain={props.restrain}
      disableDropdown={props.disableDropdown}
    />
  );
}
