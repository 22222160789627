import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import axios, { CancelToken } from "axios";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Input from "components/form/input/Input";
import Sheet from "components/sheet/Sheet";
import { GetVendors, PutVendor, PostVendor, DeleteVendor } from "api/rpc/2024-04/masterAdmin/client/settings/vendor";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { showError, showSuccess } from "redux/actions/ui";
import { TProductVendor } from "redux/reducers/models/product";

interface IVendorsState {
  vendors: Array<TProductVendor>;
  selectedVendor: TProductVendor;
  vendorTitle: string;
  editVendorModalVisible: boolean;
  newVendorModalVisible: boolean;
}

export default function Vendors(props: any) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { masterClientStore } = useAppSelector(store => store);

  const [state, setState] = useState<IVendorsState>({
    vendors: [],
    selectedVendor: null,
    vendorTitle: "",
    editVendorModalVisible: false,
    newVendorModalVisible: false,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (masterClientStore.client) {
      void loadVendors(source.token);
    }

    return () => {
      source.cancel();
    };
  }, [masterClientStore.client]);

  async function loadVendors(token?: CancelToken) {
    const vendorRes = await GetVendors(
      {
        client_id: masterClientStore.client?.id,
      },
      true,
      token,
    );

    if (vendorRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading vendors"));
      return;
    }

    setState(prevState => ({ ...prevState, vendors: vendorRes.data }));
  }

  async function updateVendors() {
    const vendorRes = await PutVendor(
      {
        id: state.selectedVendor.id,
        client_id: masterClientStore.client?.id,
        title: state.vendorTitle,
      },
      true,
    );

    if (vendorRes.status !== StatusCode.OK) {
      dispatch(showError("Error updating vendor"));
      return;
    }

    dispatch(showSuccess("Vendor updated successfully"));
    setState(prevState => ({
      ...prevState,
      selectedVendor: null,
      vendorTitle: "",
      editVendorModalVisible: false,
    }));
    void loadVendors();
  }

  async function createVendor() {
    const vendorRes = await PostVendor(
      {
        client_id: masterClientStore?.client?.id,
        title: state.vendorTitle,
      },
      true,
    );

    if (vendorRes.status !== StatusCode.OK) {
      dispatch(showError("Error creating vendor"));
      return;
    }

    dispatch(showSuccess("Vendor created successfully"));
    setState(prevState => ({
      ...prevState,
      vendorTitle: "",
      newVendorModalVisible: false,
    }));
    void loadVendors();
  }

  function handleEditVendor(vendor: TProductVendor) {
    setState(prevState => ({
      ...prevState,
      selectedVendor: vendor,
      vendorTitle: vendor.title,
      editVendorModalVisible: true,
    }));
  }

  function handleNewVendor() {
    setState(prevState => ({
      ...prevState,
      vendorTitle: "",
      newVendorModalVisible: true,
    }));
  }

  function handleCancelEdit() {
    setState(prevState => ({
      ...prevState,
      selectedVendor: null,
      vendorTitle: "",
      editVendorModalVisible: false,
    }));
  }

  function handleCancelNew() {
    setState(prevState => ({
      ...prevState,
      vendorTitle: "",
      newVendorModalVisible: false,
    }));
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setState(prevState => ({ ...prevState, [id]: value }));
  }

  const primaryAction = {
    content: "New Vendor",
    action: handleNewVendor,
    disabled: !masterClientStore.client,
  };

  return (
    <Page
      title="Vendors"
      subtitle={masterClientStore.client ? masterClientStore.client.full_name : "No Client Selected"}
      narrow
      primaryAction={primaryAction}
    >
      {masterClientStore.client && (
        <Card>
          <Card.Section table="true">
            <table className="ui-table ui-table-clickable">
              <thead>
                <tr>
                  <th>Title</th>
                </tr>
              </thead>
              <tbody>
                {state.vendors ? (
                  <>
                    {state.vendors.map((vendor: TProductVendor, index: number) => {
                      return (
                        <tr key={index} onClick={() => handleEditVendor(vendor)}>
                          <td>
                            <p className="table-cell-lead-text">{vendor.title}</p>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : null}
              </tbody>
            </table>
          </Card.Section>
        </Card>
      )}

      <Sheet
        title="Edit Vendor"
        open={state.editVendorModalVisible}
        size="small"
        onCancel={handleCancelEdit}
        onOk={updateVendors}
        closable
        backDropCancel
        cancelText="Cancel"
        okText="Update"
      >
        <Input value={state.vendorTitle} label={"Title"} id="vendorTitle" onChange={handleInputChange} />
      </Sheet>

      <Sheet
        title="New Vendor"
        open={state.newVendorModalVisible}
        size="small"
        onCancel={handleCancelNew}
        onOk={createVendor}
        closable
        backDropCancel
        cancelText="Cancel"
        okText="Create"
      >
        <Input value={state.vendorTitle} label={"Title"} id="vendorTitle" onChange={handleInputChange} />
      </Sheet>
    </Page>
  );
}
