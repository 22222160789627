import React, { ChangeEvent, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ButtonNew } from "components/buttonNew";
import FormLayout from "components/form/FormLayout";
import Sheet from "components/sheet/Sheet";
import Form from "components/form/Form";
import Input from "components/form/input/Input";
import TextField from "components/form/textField/TextField";
import Portal from "elements/Portal";
import Popup from "components/popup/Popup";
import { TFunction, useTranslation } from "react-i18next";
interface ICustomTextEdit {
  label?: string;
  description?: string;
  saveInput: (label: string, description?: string) => void;
  editModalOpen: boolean;
  closeEditModal: () => void;
  /** Viewable INPUT on the edit page itself. */
  children: React.ReactNode;
}

/** Structural display wrapper containing edit modal and its state. */
export default function CustomTextEdit(props: ICustomTextEdit) {
  const { label = "", description } = props;
  const { t } = useTranslation();
  const [state, setState] = useState({
    label: label,
    description: description,
  });

  return (
    <div className="input-view-wrap">
      {props.children}

      {/* EDITING  modal */}
      <Portal isMounted={props.editModalOpen}>
        <Sheet
          open={props.editModalOpen}
          title={t("secure.facility.settings.forms.custom_edits.custom_text_edit.003")}
          onOk={() => props.saveInput(state.label, state.description)}
          okDisabled={state.label.length === 0}
          onCancel={() => {
            props.closeEditModal();
            setState(prev => ({ ...prev, open: false, label: label, description: description }));
          }}
          size="medium"
          okText={t("secure.facility.settings.forms.custom_edits.custom_text_edit.004")}
        >
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  label={t("secure.facility.settings.forms.custom_edits.custom_text_edit.005")}
                  defaultValue={label}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setState(prev => ({ ...prev, label: e.target.value }))
                  }
                  className="input-edit-action"
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  label={t("secure.facility.settings.forms.custom_edits.custom_text_edit.006")}
                  placeholder={t("secure.facility.settings.forms.custom_edits.custom_text_edit.007")}
                  value={state.description}
                  onChange={e => setState(prev => ({ ...prev, description: e.target.value }))}
                  // className="input-edit-action"
                />
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Sheet>
      </Portal>
    </div>
  );
}
