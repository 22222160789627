import React, { useEffect, useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TableFilterDropdown from "components/tableFilterDropdown/TableFilterDropdown";
import { Column, ColumnInstance, useExpanded, useGroupBy, useTable } from "react-table";
import "./reportTable.scss";
import { LocaleCurrency } from "helpers/Locale";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import classNames from "classnames";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { MOBILE_WIDTH } from "helpers/ScreenSizes";

interface IProps {
  data: Array<Record<string, any>>;
  columns: Array<Record<string, any>>;
  description: string;
  compileDate: string;
}

interface ILockedColumn {
  id: string;
  offset: number;
}

export default function ReportTable(props: IProps) {
  const [columnsState, setColumnsState] = useState<ColumnInstance<Record<string, any>>[]>([]);
  const [lockedColumns, setLockedColumns] = useState<ILockedColumn[]>([]);
  const tableColumnsRef = useRef<HTMLTableRowElement>(null);
  const [tableColumnsWidth, setTableColumnsWidth] = useState<number>(undefined);
  const windowSize = useWindowSize();

  function formatDisplay(type: string, value: string) {
    let newValue = value !== null && value !== undefined ? value : "";

    if (!isNaN(Number(newValue)) && newValue !== "") {
      switch (type) {
        case "currency":
          newValue = new Intl.NumberFormat("en-CA", { style: "currency", currency: "CAD" }).format(Number(value));
          break;
        case "decimal":
          newValue = Number(value).toFixed(2);
          break;
        case "percentage":
          newValue = `${Number(value) * 100}%`;
          break;
        default:
          break;
      }
    }

    return newValue;
  }

  //Set columns and subcolumns
  function getColumns(colArray: Array<Record<string, any>>) {
    const columnsArray: Column<Record<string, any>>[] =
      colArray?.length > 0
        ? colArray?.map(col => {
            const columnId = col.id.toString();

            return {
              Header: col.display ?? "",
              id: columnId,
              locked: col.locked,
              accessor: col.subColumns ? undefined : columnId,
              columns: !col.subColumns ? undefined : getColumns(col.subColumns),
              show_with_parent: col.show_with_parent,
              display_type: col?.type,
              aggregate: col?.aggregate ? col?.aggregate : undefined,
              Aggregated:
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                ({ value }: any) => `${formatDisplay(col.type, value)} ${col?.aggregateText ? col?.aggregateText : ""}`,
              disableGroupBy: !col?.canGroup,
            };
          })
        : [];
    return columnsArray;
  }

  const reportsTableData = useMemo(() => props.data, [props.data]);

  const reportsDataColumns: any = useMemo(
    () => [
      //Set expandable rows
      {
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }: any) => (
          <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? (
              <FontAwesomeIcon fixedWidth icon={["far", "chevron-down"]} />
            ) : (
              <FontAwesomeIcon fixedWidth icon={["far", "chevron-right"]} />
            )}
          </span>
        ),
        id: "expander",
        locked: true,
        Cell: ({ row }: Record<string, any>) =>
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${row.depth * 1.1}rem`,
                },
              })}
            >
              {row.isExpanded ? (
                <FontAwesomeIcon fixedWidth icon={["far", "chevron-down"]} />
              ) : (
                <FontAwesomeIcon fixedWidth icon={["far", "chevron-right"]} />
              )}
            </span>
          ) : null,
      },
      //Set columns
      ...getColumns(props.columns),
    ],
    [props.columns],
  );

  // initialState: {hiddenColumns: ["number"]} <- column ids fill this array
  const tableInstance = useTable({ columns: reportsDataColumns, data: reportsTableData }, useGroupBy, useExpanded);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, allColumns } = tableInstance;

  useEffect(() => {
    const filteredColumns = allColumns?.filter(column => column.id !== "expander");
    setColumnsState(filteredColumns);
  }, [allColumns]);

  useEffect(() => {
    if (!tableColumnsRef?.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      if (tableColumnsRef?.current) {
        let tableColumnsWidth = 0;

        for (let i = 0; i < tableColumnsRef.current.cells.length; i++) {
          tableColumnsWidth += tableColumnsRef.current.cells[i].getBoundingClientRect().width;
        }

        setTableColumnsWidth(tableColumnsWidth);
      }
    });

    resizeObserver.observe(tableColumnsRef.current);
    return () => resizeObserver.disconnect();
  }, [tableColumnsRef?.current]);

  useEffect(() => {
    if (tableColumnsRef.current) {
      const lockedHeaders: Array<{ id: string; locked: boolean; index: number }> = headerGroups?.[
        headerGroups.length - 1
      ]?.headers
        ?.map((header: any, index: number) => ({ id: header.id, locked: header.locked || header.isGrouped, index }))
        ?.filter(header => header.locked);

      const lockedColumns: ILockedColumn[] = [];
      let offset = 0;

      lockedHeaders.forEach(lockedHeader => {
        lockedColumns.push({ id: lockedHeader.id, offset });
        offset += tableColumnsRef.current.cells[lockedHeader.index].getBoundingClientRect().width;
      });

      setLockedColumns(lockedColumns);
    }
  }, [headerGroups, tableColumnsWidth]);

  //Hide subcolumns based on the header's show_with_parent value
  function handleColumnClick(column: any) {
    column?.headers?.forEach((header: any) => {
      if (header?.show_with_parent === false) {
        header.toggleHidden();
      }
    });
  }

  return props.data && props.data?.length > 0 ? (
    <>
      <div className="report-table-filter-button-container">
        <TableFilterDropdown columns={columnsState} tableInstance={tableInstance} />
        <div>
          {props.description && (
            <OverlayTrigger placement="auto" overlay={<Tooltip id="descriptionToolTip">{props.description}</Tooltip>}>
              <FontAwesomeIcon icon={["fas", "square-info"]} fixedWidth />
            </OverlayTrigger>
          )}
          {props.compileDate && (
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip id="compileDateToolTip">
                  {"Compiled at: " + moment(props.compileDate).local().format("LLL")}
                </Tooltip>
              }
            >
              <FontAwesomeIcon icon={["fas", "clock"]} fixedWidth />
            </OverlayTrigger>
          )}
        </div>
      </div>

      <div className="report-table-container">
        <table {...getTableProps()} className="report-table-dynamic">
          <thead>
            {headerGroups.map((headerGroup, headerGroupIndex) => (
              <tr
                key={headerGroupIndex}
                {...headerGroup.getHeaderGroupProps()}
                ref={headerGroupIndex === headerGroups.length - 1 ? tableColumnsRef : null}
                className="report-table-header"
              >
                {headerGroup.headers.map((column: any, columnIndex) => {
                  const lockedColumn = lockedColumns?.find(lockedColumn => lockedColumn?.id === column?.id);
                  const isColumnLocked =
                    lockedColumn !== undefined &&
                    lockedColumn?.offset !== undefined &&
                    (windowSize.width > MOBILE_WIDTH ||
                      lockedColumn.id === lockedColumns?.[0]?.id ||
                      lockedColumn.id === lockedColumns?.[1]?.id);

                  if (column?.columns) {
                    return (
                      <td key={columnIndex} {...column.getHeaderProps()} onClick={() => handleColumnClick(column)}>
                        {column.render("Header")}
                      </td>
                    );
                  } else {
                    return (
                      <td
                        key={columnIndex}
                        {...column.getHeaderProps()}
                        style={isColumnLocked ? { left: lockedColumn.offset } : {}}
                        className={classNames({
                          "report-table-column-locked": isColumnLocked,
                        })}
                      >
                        {column.canGroupBy ? (
                          // If the column can be grouped, let's add a toggle
                          <span {...column.getGroupByToggleProps()}>
                            {column.isGrouped ? (
                              <FontAwesomeIcon className="mr-1" fixedWidth icon={["far", "octagon-minus"]} />
                            ) : (
                              <FontAwesomeIcon className="mr-1" fixedWidth icon={["far", "octagon-plus"]} />
                            )}
                          </span>
                        ) : null}
                        {column.render("Header")}
                      </td>
                    );
                  }
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row: any, indx: number) => {
              prepareRow(row);
              return (
                <tr key={indx} {...row.getRowProps()} className="report-table-row">
                  {row.cells.map((cell: any, index: number) => {
                    const lockedColumn = lockedColumns?.find(lockedColumn => lockedColumn?.id === cell?.column?.id);
                    const isRowLocked =
                      lockedColumn !== undefined &&
                      lockedColumn?.offset !== undefined &&
                      (windowSize.width > MOBILE_WIDTH ||
                        lockedColumn.id === lockedColumns?.[0]?.id ||
                        lockedColumn.id === lockedColumns?.[1]?.id);

                    return (
                      <td
                        key={index}
                        {...cell.getCellProps()}
                        style={isRowLocked ? { left: lockedColumn.offset } : {}}
                        className={classNames({
                          "report-table-row-locked": isRowLocked,
                        })}
                      >
                        <>
                          {cell.isGrouped && (
                            <span {...row.getToggleRowExpandedProps()}>
                              {row.isExpanded ? (
                                <FontAwesomeIcon fixedWidth icon={["far", "chevron-down"]} />
                              ) : (
                                <FontAwesomeIcon fixedWidth icon={["far", "chevron-right"]} />
                              )}
                            </span>
                          )}
                          {cell.isAggregated
                            ? cell.render("Aggregated")
                            : cell?.column?.display_type !== "string"
                            ? formatDisplay(cell?.column?.display_type, cell.value)
                            : cell.render("Cell")}{" "}
                          {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                          {cell.isGrouped && `(${row.subRows.length})`}
                        </>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  ) : (
    <div></div>
  );
}
