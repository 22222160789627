import React from "react";
import RegisterContext from "../../register/registerNew/RegisterContext";
import NewTableService from "./NewTableService";

export default function TeeSheetDetailsContextProvider() {
  return (
    <RegisterContext>
      <NewTableService />
    </RegisterContext>
  );
}
