import { APIDelete, APIGet, APIPost, APIPut } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/tournament/registration";

export type TTournamentRegistrationInputTypes = "input" | "select" | "checkbox" | "text";
export interface ITournamentRegistrationInput {
  id: number;
  label: string;
  required: boolean;
  type: TTournamentRegistrationInputTypes;
  help_text: string;
  row: number;
  column: number;
  values: string[] | null;
}

type TGetTournamentRegistrationInput = {
  tournament_id?: number;
};

export type TPostTournamentRegistrationInput = {
  tournament_id: number;
  label: string;
  help_text: string;
  type: TTournamentRegistrationInputTypes;
  required?: boolean; // backend defaults to true if not given
};

export type TPutTournamentRegistrationInput = {
  id: number;
  label?: string;
  help_text?: string;
  type?: TTournamentRegistrationInputTypes;
  values?: string[];
  required?: boolean;
};

export type TPutTournamentRegistrationInputPosition = {
  id: number;
  row: number;
  column: number;
};

export async function GetTournamentRegistrationInput(
  params: TGetTournamentRegistrationInput,
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet<ITournamentRegistrationInput[]>(baseUrl + "-input", params, useGlobalLoader, token);
}

export async function PostTournamentRegistrationInput(
  params: TPostTournamentRegistrationInput,
  useGlobalLoader: boolean,
) {
  return await APIPost<ITournamentRegistrationInput>(baseUrl + "-input", params, useGlobalLoader);
}

export async function PutTournamentRegistrationInput(params: TPutTournamentRegistrationInput, useGlobalLoader = true) {
  return await APIPut(baseUrl + "-input", params, useGlobalLoader);
}

export async function DeleteTournamentRegistrationInput(params: { id: number }, useGlobalLoader = true) {
  return await APIDelete(baseUrl + "-input", params, useGlobalLoader);
}

export async function PutTournamentRegistrationInputPositions(
  params: { inputs: TPutTournamentRegistrationInputPosition[] },
  useGlobalLoader = true,
) {
  return await APIPut(baseUrl + "-input/position", params, useGlobalLoader);
}
