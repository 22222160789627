import { APIGet, APIPost, APIPut, APIDelete, StatusCode, IAPIResponse } from "api/protocols";
import { formatDate } from "helpers/Helpers";
import { IBooking, ITeeTime } from "redux/reducers/models/teetime";
import { CancelToken } from "axios";
import { IBookingEngine, IBookingEngineGuest } from "redux/reducers/bookingEngine";

const apiVersion = "2021-01";
const adminType = "guest";

const apiVersionNew = "2022-07";

//POST Booking
const baseBookingUrl = "/" + apiVersionNew + "/customer/tee-time/booking";

//Get Tee Time Information
const baseTeeSheetUrl = "/" + apiVersion + "/" + adminType + "/tee-time";

//GET Customer Tee Times
const baseCustomerTeeTimeUrl = "/" + apiVersion + "/customer/tee-time";

//GET Guest Tee Times
const baseGuestTeeTimeUrl = "/" + apiVersion + "/guest/tee-time";

//GET Booking Engine
const baseBookingEngineUrl = "/" + apiVersion + "/guest/tee-sheet/booking-engine";

//GET Merged Cart
const baseCartMergeUrl = "/" + apiVersion + "/customer/cart/merge";

//GET Cart
const baseCartUrl = "/" + apiVersion + "/customer/cart";

//PUT Complete booking
const completeBookingCustomerUrl = "/" + apiVersion + "/customer/tee-time/booking/complete";

//POST Create New Account
const createNewAccountUrl = "/" + apiVersion + "/customer/auth/register";

//POST New Booking Information
const baseCustomerUrl = "/" + apiVersion + "/customer/tee-time/booking";

//POST Lock Booking
const baseBookingLockUrl = "/" + apiVersion + "/customer/tee-time/lock";

//POST Reset Password Code
const basePasswordReset = "/" + apiVersion + "/guest/auth/reset-code";

//PUT New Password
const baseNewPassword = "/" + apiVersion + "/guest/auth/reset";

// Validate Password
const baseValidateReset = "/" + apiVersion + "/guest/auth/validate-reset";

const baseCustomerNotification = "/" + apiVersion + "/customer/tee-sheet/notification";

const baseGuestNotification = "/" + apiVersion + "/guest/tee-sheet/notification";

interface IGetBookingEngine {
  handle: string;
}

interface IGetBookingEngineRes {
  data: Array<IBookingEngine>;
  message: string;
  status: StatusCode;
}

//GET Booking engine information
export async function GetBookingEngine(params: IGetBookingEngine, useGlobalLoader: boolean) {
  return await APIGet<IBookingEngineGuest[]>(baseBookingEngineUrl, params, useGlobalLoader);
}

interface IGetGuestTeeTimes {
  date: Date;
  booking_engine_handle: string;
  offset?: number;
  limit?: number;
}

interface IGetGuestTeeTimesResponse extends IAPIResponse {
  data: ITeeTime[];
}
//GET Guest Tee Times
export async function GetGuestTeeTimes(
  params: IGetGuestTeeTimes,
  useGlobalLoader: boolean,
): Promise<IGetGuestTeeTimesResponse> {
  const chosenDate = formatDate(params.date).toString();

  const tempParams = {
    date: chosenDate,
    booking_engine_handle: params.booking_engine_handle,
    offset: params.offset,
    limit: params.limit,
  };

  return await APIGet("/2022-07/guest/tee-time", tempParams, useGlobalLoader);
}

interface IGetCustomerTeeTimes {
  date: Date;
  booking_engine_handle: string;
  offset?: number;
  limit?: number;
}

interface IGetCustomerTeeTimesResponse extends IAPIResponse {
  data: ITeeTime[];
}

interface IGetNotifications {
  date: string;
  facility_id: number;
}

export interface INotification {
  id: number;
  tee_sheet_id: string;
  date?: string;
  subject: string;
  content: string;
  type: string;
  user_id: number;
}

interface IGetNotificationsResponse extends IAPIResponse {
  data: INotification[];
}

//GET Customer Tee Times
export async function GetCustomerTeeTimes(
  params: IGetCustomerTeeTimes,
  useGlobalLoader: boolean,
): Promise<IGetCustomerTeeTimesResponse> {
  const chosenDate = formatDate(params.date).toString();

  const tempParams = {
    date: chosenDate,
    booking_engine_handle: params.booking_engine_handle,
    offset: params.offset,
    limit: params.limit,
  };

  return await APIGet("/2022-07/customer/tee-time", tempParams, useGlobalLoader);

  // return await APIGet(
  //   baseCustomerTeeTimeUrl + "?" + "booking_engine_handle=" + handle + "&date=" + chosenDate,
  //   useGlobalLoader,
  // );
}

interface IGetTeeTimeParams {
  id?: number;
  booking_engine_handle?: string;
  date?: string;
  turn_tee_time?: boolean;
  extended?: boolean;
}

// Get tee times for authorized users
export async function GetTeeTime(params: IGetTeeTimeParams, useGlobalLoader: boolean) {
  return await APIGet("/2022-07/customer/tee-time", params, useGlobalLoader);
}

export async function GetTeeTimeGuest(params: IGetTeeTimeParams, useGlobalLoader: boolean) {
  return await APIGet("/2022-07/guest/tee-time", params, useGlobalLoader);
}

//GET Tee Information based on ID
export async function GetTeeInformationByID(params: IGetTeeTimeParams, useGlobalLoader: boolean) {
  return await APIGet("/2021-01/guest/tee-time", params, useGlobalLoader);
}

// GET Customer Notification
export async function GetCustomerNotifications(
  params: IGetNotifications,
  useGlobalLoader: boolean,
): Promise<IGetNotificationsResponse> {
  return await APIGet(baseCustomerNotification, params, useGlobalLoader);
}

// GET Guest Notification
export async function GetGuestNotifications(
  params: IGetNotifications,
  useGlobalLoader: boolean,
): Promise<IGetNotificationsResponse> {
  return await APIGet(baseGuestNotification, params, useGlobalLoader);
}

//PUT Update Bookings
export async function UpdateBooking(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseBookingUrl, params, useGlobalLoader);
}

//PUT Update Bookings
export async function CompleteBooking(params: any, useGlobalLoader: boolean) {
  return await APIPut(completeBookingCustomerUrl, params, useGlobalLoader);
}

//POST  Create New Account
export async function CreateAccount(params: any, useGlobalLoader: boolean) {
  const tempFirstName = String(params.firstName);
  const tempLastName = String(params.lastName);
  const tempEmail = String(params.email);
  const tempPassword = String(params.password);
  const tempPhoneNumber = String(params.phoneNumber);

  return await APIPost(
    createNewAccountUrl +
      "?email=" +
      tempEmail +
      "&first_name=" +
      tempFirstName +
      "&last_name=" +
      tempLastName +
      "&password=" +
      tempPassword +
      "&phone=" +
      tempPhoneNumber,
    params,
    useGlobalLoader,
  );
}

//POST New Booking
export async function PostNewBooking(params: any, useGlobalLoader: boolean) {
  const tempId = String(params.tempTeeTimeId);
  const holes = String(params.holes);
  const tempBookingEngineHandle = String(params.bookingEngineHandle);

  return await APIPost(
    baseCustomerUrl +
      "?" +
      "tee_time_id=" +
      tempId +
      "&holes=" +
      holes +
      "&quantity=0&booking_engine_handle=" +
      tempBookingEngineHandle,
    params,
    useGlobalLoader,
  );
}

//POST Lock Booking 5 Mins
export async function LockTeeTimeById(teeTimeId: any, useGlobalLoader: boolean) {
  const tempId = String(teeTimeId);
  return await APIPost(baseBookingLockUrl + "?" + "tee_time_id=" + tempId, {}, useGlobalLoader);
}

export async function DeleteLockTeeTime(useGlobalLoader: boolean) {
  return await APIDelete(baseBookingLockUrl, {}, useGlobalLoader);
}

//POST Merged Cart
export async function GetMergedCart(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseCartMergeUrl, params, useGlobalLoader);

  // return await APIPost(baseCartMergeUrl + "?" + "cart_ids=" + cartArray, params, true);
}

//Get Cart
export async function GetCart(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseCartUrl, params, useGlobalLoader);

  // return await APIPost(baseCartMergeUrl + "?" + "cart_ids=" + cartArray, params, true);
}

//GET PASSWORD RECOVERY CODE
export async function GetPasswordResetCode(params: any, useGlobalLoader: boolean) {
  return await APIPost(basePasswordReset + "?", params, useGlobalLoader);
}

//SET NEW PASSWORD
export async function ResetPassword(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseNewPassword + "?", params, useGlobalLoader);
}

interface IValidateReset {
  email: string;
  code: string;
}

//GET VALIDATE RESET
export async function ValidateReset(params: IValidateReset, useGlobalLoader: boolean) {
  return await APIGet(baseValidateReset, params, useGlobalLoader);
}

interface IPostBookingParams {
  tee_time_id: number;
  holes: number;
  quantity: number;
  booking_engine_handle: string;
  power_cart_quantity: number;
}

interface IPostCartParams {
  cart_token?: string;
  holes: number;
  quantity: number;
  power_cart_quantity: number;
  tee_time_id: number;
}

interface IPutCancelBookingParams {
  token: string;
}

interface IGetBooking {
  token: string;
  extended?: boolean;
  turn_tee_time?: true;
  limit?: number;
  offset?: number;
}

interface IGetBookingResponse extends IAPIResponse {
  data: IBooking[];
}

// GET Customer booking
export async function GetBooking(params: IGetBooking, useGlobalLoader: boolean): Promise<IGetBookingResponse> {
  return await APIGet(baseBookingUrl, params, useGlobalLoader);
}

// POST Customer booking
export async function PostBooking(params: IPostBookingParams, useGlobalLoader: boolean) {
  return await APIPost(baseBookingUrl, params, useGlobalLoader);
}

export async function PostCart(params: IPostCartParams, useGlobalLoader: boolean) {
  return await APIPost(baseBookingUrl + "/cart", params, useGlobalLoader);
}

export async function PutCart(params: IPostCartParams, useGlobalLoader: boolean, cancelToken: CancelToken) {
  return await APIPut(baseBookingUrl + "/cart", params, useGlobalLoader, cancelToken);
}

export async function PutCancelBooking(params: IPutCancelBookingParams, useGlobalLoader: boolean) {
  return await APIPut(baseBookingUrl + "/cancel", params, useGlobalLoader);
}
