import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetDepartments as GetClientDepartments } from "api/rpc/clientAdmin/facility/facility";
import { ExtendedClientProductFilterKey } from "api/rpc/2022-09/clientAdmin/product/product";
import { FacilityProductExtendedFilters } from "api/rpc/2022-09/facilityAdmin/product/product";
import { GetProduct, PutArchiveProduct } from "api/rpc/2024-04/clientAdmin/product/product";

import { Department, ICategories, IDepartments, IProduct, ISubcategories } from "redux/reducers/models/product";
import { useAppSelector } from "hooks/redux";
import useModal from "hooks/modals/useModal";
import { sortObj } from "helpers/Helpers";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Search from "components/search/Search";
import ProductTypesDropFilter from "components/filters/ProductTypesDropFilter";
import DropFilter from "components/dropdown/DropFilter";
import ProductsTable from "pages/secure/facility/product/ProductsTable";
import Popup from "components/popup/Popup";
import Portal from "elements/Portal";

type ProductFilters = {
  search: string;
  limit: number;
  offset: number;
  extended: FacilityProductExtendedFilters;
};

type DepartmentState = {
  isLoaded: boolean;
  departments: IDepartments[] | null;
  categories: ICategories[] | null;
  subcategories: ISubcategories[] | null;
};

const PRODUCT_TYPES_FILTER_KEY = "product_types_filters";
const PRODUCT_DEPARTMENT_FILTER_KEY = "product_department_filters";
const PRODUCT_CATEGORY_FILTER_KEY = "product_category_filters";
const PRODUCT_SUBCATEGORY_FILTER_KEY = "product_subcategory_filters";

export default function Products() {
  const history = useHistory();

  const { authStore, filterHistoryStore } = useAppSelector(store => store);
  const permissionToCreate = authStore.user?.permissions?.products_create;

  const [products, setProducts] = useState<IProduct[]>(undefined);

  const [clientDepartments, setClientDepartments] = useState<DepartmentState>({
    isLoaded: false,
    departments: null,
    categories: null,
    subcategories: null,
  });

  // Back-end Filters.  Changes made triggers API call.
  const [filters, setFilters] = useState<ProductFilters>({
    search: undefined,
    limit: 50,
    offset: 0,
    extended: {
      type: filterHistoryStore[PRODUCT_TYPES_FILTER_KEY]
        ? filterHistoryStore[PRODUCT_TYPES_FILTER_KEY].currentFilter
        : [],
      department_id: filterHistoryStore[PRODUCT_DEPARTMENT_FILTER_KEY]
        ? filterHistoryStore[PRODUCT_DEPARTMENT_FILTER_KEY].currentFilter
        : [],
      category_id: filterHistoryStore[PRODUCT_CATEGORY_FILTER_KEY]
        ? filterHistoryStore[PRODUCT_CATEGORY_FILTER_KEY].currentFilter
        : [],
      subcategory_id: filterHistoryStore[PRODUCT_SUBCATEGORY_FILTER_KEY]
        ? filterHistoryStore[PRODUCT_SUBCATEGORY_FILTER_KEY].currentFilter
        : [],
    },
  });

  const {
    state: archiveProductModal,
    updateModal: updateArchiveProductModal,
    closeModal: closeArchiveProductModal,
  } = useModal({ productIds: [] as Array<number> });

  // Generate Departments
  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadClientDepartments(source.token);
    return () => {
      source.cancel();
    };
  }, []);

  // Re-call the API endpoint when a filter changes only after the facilityDepartments have been set.
  useEffect(() => {
    const source = axios.CancelToken.source();

    if (filters.search !== undefined && clientDepartments.isLoaded) {
      void loadProducts(source.token);
    }

    return () => {
      source.cancel();
    };
  }, [filters, filters.extended, clientDepartments.isLoaded]);

  async function loadProducts(token?: CancelToken) {
    setProducts(undefined);

    const extendedFiltersWanted = [filters.extended].some(
      value =>
        value.type.length > 0 ||
        value.category_id.length > 0 ||
        value.department_id.length > 0 ||
        value.subcategory_id.length > 0,
    );

    // capture extendedFilters and format
    const filterKeys = Object.keys(filters.extended) as ExtendedClientProductFilterKey[];
    const extendedFilters = filterKeys
      .map(key => {
        if (filters.extended[key].length > 0) {
          return {
            label: key,
            value: filters.extended[key],
          };
        }
      })
      .filter(value => value !== undefined);

    //define GetProduct parameters
    const getProductParams = {
      search: filters.search,
      limit: filters.limit,
      offset: filters.offset,
      filters: extendedFiltersWanted ? extendedFilters : undefined,
    };

    console.log("API call", getProductParams); //debug

    const productRes = await GetProduct(getProductParams, false, token);
    if (productRes.status === StatusCode.OK) {
      setProducts(productRes.data);
    }
  }

  async function loadClientDepartments(token: CancelToken) {
    const { status, data } = await GetClientDepartments(false, token);
    if (status === StatusCode.OK) {
      const sortedData = sortObj<IDepartments>(data, "title");

      setClientDepartments({
        isLoaded: true,
        departments: sortedData.filter(department => department.type === "department"),
        categories: (sortedData as Department[]).filter(
          department => department.type === "category",
        ) as ICategories[],
        subcategories: (sortedData as Department[]).filter(
          department => department.type === "subcategory",
        ) as ISubcategories[],
      });
    }
  }

  async function archiveProducts() {
    const res = await PutArchiveProduct({ product_ids: archiveProductModal.productIds }, true);
    if (res.status !== StatusCode.OK) {
      return;
    }
    closeArchiveProductModal();
    void loadProducts();
  }

  function handleTableNavigation(direction: "prev" | "next") {
    switch (direction) {
      case "prev": {
        setFilters(prev => ({ ...prev, offset: prev.offset - prev.limit }));
        return;
      }
      case "next": {
        setFilters(prev => ({ ...prev, offset: prev.offset + prev.limit }));
        return;
      }
      default:
        return;
    }
  }

  function handleExtendedFilter(filterReturn: Record<string, any>[], type: keyof FacilityProductExtendedFilters) {
    setFilters(prev => ({
      ...prev,
      extended: {
        ...prev.extended,
        [type]: filterReturn.map(filter => filter.id as number),
      },
    }));
  }

  const primaryAction = {
    content: "New Product", // TODO: Translation
    action: () => history.push("/admin/product/new"),
    disabled: !permissionToCreate,
  };

  return (
    <Page title="Products" narrow primaryAction={primaryAction}>
      <div className="flex flex-row flex-wrap gap-4 pb-4">
        <ProductTypesDropFilter
          defaultCheckboxes={filters.extended.type}
          historyKey={PRODUCT_TYPES_FILTER_KEY}
          handleApply={filterReturn =>
            setFilters(prev => ({
              ...prev,
              extended: {
                ...prev.extended,
                type: filterReturn,
              },
            }))
          }
          userLevel="client"
          leftAligned
        />

        <DropFilter
          title="Department" // TODO: Translation
          filterData={clientDepartments.departments}
          filterLabelPropFromData="title"
          filterIdPropFromData="id"
          filterType="Checkbox"
          historyKey={PRODUCT_DEPARTMENT_FILTER_KEY}
          applyFilters={filterReturn => handleExtendedFilter(filterReturn, "department_id")}
          defaultCheckboxes={filters.extended.department_id}
        />

        <DropFilter
          title="Category" // TODO: Translation
          filterData={clientDepartments.categories}
          filterLabelPropFromData="title"
          filterIdPropFromData="id"
          filterType="Checkbox"
          historyKey={PRODUCT_CATEGORY_FILTER_KEY}
          applyFilters={filterReturn => handleExtendedFilter(filterReturn, "category_id")}
          defaultCheckboxes={filters.extended.category_id}
        />

        <DropFilter
          title="Sub-Category" // TODO: Translation
          filterData={clientDepartments.subcategories}
          filterLabelPropFromData="title"
          filterIdPropFromData="id"
          filterType="Checkbox"
          historyKey={PRODUCT_SUBCATEGORY_FILTER_KEY}
          applyFilters={filterReturn => handleExtendedFilter(filterReturn, "subcategory_id")}
          defaultCheckboxes={filters.extended.subcategory_id}
        />
      </div>
      <div className="flex-grow mb-4">
        <Search
          searchCallback={searchValue => setFilters(prev => ({ ...prev, search: searchValue }))}
          historyKey="products-all"
          restrictPresetToRoutes={["admin/product"]}
        />
      </div>
      <Card style={{ border: 0 }}>
        <ProductsTable
          type="client"
          products={products}
          tableLimit={filters.limit}
          tableOffset={filters.offset}
          handleTableOffset={direction => handleTableNavigation(direction)}
          removeProducts={idArray => updateArchiveProductModal({ isOpen: true, productIds: idArray })}
        />
      </Card>

      <Portal isMounted={archiveProductModal.isOpen}>
        <Popup
          open={archiveProductModal.isOpen}
          onCancel={closeArchiveProductModal}
          onOk={archiveProducts}
          type="warning"
          title="Archive Products?" // TODO: Translation
          description="Are you sure you want to archive the selected product(s)?" // TODO: Translation
        />
      </Portal>
    </Page>
  );
}
