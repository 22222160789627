import React, { useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./factorNodeEditMode.scss";
import { useAutoFocusInput } from "./useAutoFocusInput";
import { useDynamicPricingContext } from "../../DynamicPricingContext";
import { DynamicPricingFactorValue } from "../DynamicPricingFactorGroupNode";
import { areFactorValuesOverlapping } from "../../TeeSheetDynamicPricingEdit";

export interface IFactorPercentRangeValueType {
  valueType: "percent_range";
  startPercent: number;
  endPercent: number;
}

interface IFactorNodePercentRangeEditMode {
  parentId: string;
  id: string;
  defaultStartPercent: number;
  defaultEndPercent: number;
  onConfirm: (value: IFactorPercentRangeValueType) => void;
  onCancel: () => void;
}

export function isFactorPercentRangeValid(startPercent: number, endPercent: number) {
  return (
    startPercent != null &&
    endPercent != null &&
    startPercent >= 0 &&
    startPercent <= 100 &&
    endPercent >= 0 &&
    endPercent <= 100 &&
    startPercent % 1 === 0 &&
    endPercent % 1 === 0 &&
    startPercent < endPercent
  );
}

export default function FactorNodePercentRangeEditMode(props: IFactorNodePercentRangeEditMode) {
  const { parentId, id, defaultStartPercent, defaultEndPercent, onConfirm, onCancel } = props;

  const dynamicPricingContext = useDynamicPricingContext();

  const autoFocusInputRef = useAutoFocusInput();

  const [startPercentInput, setStartPercentInput] = useState<string>(
    defaultStartPercent == null ? "" : String(defaultStartPercent),
  );

  const [endPercentInput, setEndPercentInput] = useState<string>(
    defaultEndPercent == null ? "" : String(defaultEndPercent),
  );

  const startPercent = startPercentInput === "" ? null : Number(startPercentInput);
  const endPercent = endPercentInput === "" ? null : Number(endPercentInput);

  const confirmDisabled = useMemo(() => {
    if (!isFactorPercentRangeValid(startPercent, endPercent)) {
      return true;
    }

    const factorSiblings = dynamicPricingContext.getFactorSiblings(parentId, id);

    const dynamicFactorValue: DynamicPricingFactorValue = {
      valueType: "percent_range",
      startPercent,
      endPercent,
    };

    return areFactorValuesOverlapping([...factorSiblings.map(sibling => sibling.data), dynamicFactorValue]);
  }, [startPercent, endPercent]);

  return (
    <div className="factor-node-edit-mode">
      <div>
        <input
          ref={autoFocusInputRef}
          className="factor-node-edit-mode-input"
          value={startPercentInput}
          onChange={e => setStartPercentInput(e.target.value)}
        />
        %
      </div>
      -
      <div>
        <input
          className="factor-node-edit-mode-input"
          value={endPercentInput}
          onChange={e => setEndPercentInput(e.target.value)}
        />
        %
      </div>
      <button
        type="button"
        className="factor-node-edit-mode-confirm"
        disabled={confirmDisabled}
        onClick={() => {
          onConfirm({
            valueType: "percent_range",
            startPercent,
            endPercent,
          });
        }}
      >
        <FontAwesomeIcon icon={["fal", "circle-check"]} />
      </button>
      <button type="button" className="factor-node-edit-mode-cancel" onClick={onCancel}>
        <FontAwesomeIcon icon={["fal", "circle-x"]} />
      </button>
    </div>
  );
}
