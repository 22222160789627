import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetReport, IReport } from "api/rpc/2024-04/facilityAdmin/report/report";

import { showError } from "redux/actions/ui";
import { IUserPermissions } from "redux/reducers/models/user";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import Page from "components/page/Page";
import DataTable from "../customer/tabs/houseAccounts/DataTable";

import "./reports.scss";

// Add new report codes here, routes will use the code instead of the id
const newReports = [
  "transactions",
  "scheduled-payments",
  "detailed-sales-by-department",
  "sales-by-accounting-reference",
  "inventory-listing-by-department",
  "order-exceptions",
  "tee-sheet-utilization",
  "email-activity",
  "golfnorth-daily-sales",
  "ticket-overview",
  "aged-accounts-receivable",
  "inventory-adjustments",
  "gift-card-activity",
  "tickets-redeemed",
  "tickets",
  "tickets-expired",
  "rounds-by-customer-type",
  "membership-sales",
  "tee-sheet-no-show",
  "player-spend",
  "sales-by-department",
  "accounts-receivable",
  "deposits",
  "item-sales-history",
  "tournaments",
  "events-not-invoiced",
  "invoices",
  "prize-accounts",
  "daily-sales",
  "tee-sheet-overview",
  "hospitality-events-not-invoiced",
];

// Formats code argument and checks if within user permissions.  Defaults to true if not indexed
const viewPermission = (permissions: IUserPermissions, code: string) => {
  if (!permissions) {
    return false;
  }
  const permissionKey = `reports_facility_${code.replace(/-/g, "_")}` as keyof IUserPermissions;
  return permissions[permissionKey] == true || permissions[permissionKey] == null;
};

export default function Reports() {
  const history = useHistory();
  const { t } = useTranslation();

  const authStore = useAppSelector(store => store.authStore);
  const dispatch = useAppDispatch();

  const [reportState, setReportState] = useState({
    reports: undefined as IReport[],
    categories: undefined as string[],
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadReports(source.token);
    return () => source.cancel();
  }, []);

  async function loadReports(token?: CancelToken) {
    const res = await GetReport(null, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error retrieving reports.")); // TODO: Translation
    }

    setReportState(prevState => ({
      ...prevState,
      reports: res.status !== StatusCode.OK ? [] : res.data,
      categories: res.status !== StatusCode.OK ? [] : [...new Set(res.data.map(report => report.category))],
    }));
  }

  return (
    <Page title={t("secure.facility.reports.reports.001")}>
      <DataTable
        columns={[{ label: reportState.categories?.length > 0 ? reportState.categories[0] : "" }]}
        loading={reportState.reports === undefined}
      >
        {reportState.categories?.map((category, categoryIndex: number) => (
          <>
            {categoryIndex > 0 && (
              <tr key={category} className="report-category-row">
                <td className="report-category">{category}</td>
              </tr>
            )}
            {reportState.reports
              ?.filter(report => report.category === category)
              ?.filter(report => viewPermission(authStore.user?.permissions, report.code))
              ?.map(report => (
                <tr
                  key={report.id}
                  className="clickable"
                  onClick={() =>
                    newReports.indexOf(report.code) >= 0
                      ? history.push("/admin/report/" + report.code)
                      : history.push("/admin/report/" + String(report.id))
                  }
                >
                  <td>{report.title}</td>
                </tr>
              ))}
          </>
        ))}
      </DataTable>
    </Page>
  );
}
