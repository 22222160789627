import Folders from "pages/secure/facility/settings/folders/Folders";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UIActions from "redux/actions/ui";

const mapDispatchToProps = (dispatch: any) => {
  return {
    uiActions: bindActionCreators(
      {
        ...UIActions,
      },
      dispatch,
    ),
  };
};

export default connect(null, mapDispatchToProps)(Folders);
