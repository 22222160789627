import { APIGet, APIPost, APIPut, APIDelete, StatusCode, APIUrl, AdminType } from "api/protocols";

interface IVoidCart {
  cart_ids: Array<number>;
  cancel_reason: string;
}

interface ISplitLineItem {
  line_item_ids: Array<number>;
  cart_ids: Array<number>;
}

interface IGetCart {
  id?: number;
  token?: string;
}

interface IPostCart {
  type?: string;
  register_id?: number;
  table_id?: number;
  customer_id?: number;
}

export async function GetCart(params: IGetCart, useGlobalLoader: boolean) {
  return await APIGet(APIUrl(AdminType.FacilityAdmin, "/cart"), params, useGlobalLoader);
}

export async function PostCart(params: IPostCart, useGlobalLoader: boolean) {
  return await APIPost(APIUrl(AdminType.FacilityAdmin, "/cart"), params, useGlobalLoader);
}

export async function PutVoidCarts(params: IVoidCart, useGlobalLoader: boolean) {
  return await APIPut(APIUrl(AdminType.FacilityAdmin, "/cart/void"), params, useGlobalLoader);
}

export async function PutLineItemSplit(params: ISplitLineItem, useGlobalLoader: boolean) {
  return await APIPut(APIUrl(AdminType.FacilityAdmin, "/cart/line-item/split"), params, useGlobalLoader);
}
