import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseFolderUrl = "/" + apiVersion + "/" + adminType + "/product/folder";

export interface IFolder {
  id?: number;
  title?: string;
  default?: boolean;
  register_group_id?: number;
}

export interface IPostFolderProduct {
  id: number;
  position: number;
  button_color: string;
}

interface IDuplicateFolder {
  folder_id: number;
}

interface IFolderProducts {
  folder_id?: number;
  products?: IPostFolderProduct[];
}

interface IDuplicateFolderResponse {
  status: number;
  data: {
    facility_id: number;
    title: string;
    description: string;
    deleted_at: string;
    default: boolean;
    register_id: number;
    register_group_id: number;
    updated_at: string;
    created_at: string;
    id: number;
  };
}

export async function GetFolder(params: string, useGlobalLoader: boolean) {
  return await APIGet(baseFolderUrl + "?" + params + "&extended=true", null, useGlobalLoader);
}

export async function PostFolder(params: IFolder, useGlobalLoader: boolean) {
  return await APIPost(baseFolderUrl, params, useGlobalLoader);
}

export async function PutFolder(params: IFolder, useGlobalLoader: boolean) {
  return await APIPut(baseFolderUrl, params, useGlobalLoader);
}

export async function DeleteFolder(params: IFolder, useGlobalLoader: boolean) {
  return await APIDelete(baseFolderUrl, params, useGlobalLoader);
}

export async function DuplicateFolder(
  params: IDuplicateFolder,
  useGlobalLoader: boolean,
): Promise<IDuplicateFolderResponse> {
  return await APIPost(baseFolderUrl + "/duplicate", params, useGlobalLoader);
}

export async function PostFolderProducts(params: IFolderProducts, useGlobalLoader: boolean) {
  return await APIPost(baseFolderUrl + "/product", params, useGlobalLoader);
}
