import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { StatusCode } from "api/protocols";

import { IOrder, IOrderLineItem, IOrderTaxLine, IOrderTransaction } from "redux/reducers/models/order";
import Page from "components/page/Page";
import Card from "components/card/Card";
import { LocaleCurrency } from "helpers/Locale";
import Input from "components/form/input/Input";
import { ButtonNew } from "components/buttonNew";
import {
  clearReaderDisplay,
  convertOrderIntoDisplayInfo,
  setReaderDisplay,
  processRefund as stripeProcessRefund,
  sheetInfo,
} from "helpers/StripeTerminalWrapper";
import { IUIActions } from "redux/actions/ui";
import Popup from "components/popup/Popup";
import { delay } from "helpers/Helpers";
import { GetOrder, PostCalculateRefund, PostRefund } from "api/rpc/clientAdmin/order/order";

interface IOrderState {
  order: IOrder;
  line_items: IOrderLineItem;
  transactions: IOrderTransaction[];
  total_tax: number;
  subtotal_price: number;
  total_price: number;
  tax_lines: IOrderTaxLine[];
  calculated_refund_transactions: Record<string, any>[];
  refund_line_items: {
    line_item_id: number;
    refund_quantity: number;
    refund_amount: number;
  }[];
}

interface ITerminalState {
  online: boolean;
  connecting: boolean;
  reader: any;
}

interface IProps {
  terminalStore: ITerminalState;
  uiActions: IUIActions;
}

interface IStripePaymentState {
  onOk: any;
  onCancel: any;
  okText: string;
  cancelText: string;
  sheetVisible: boolean;
  titleText: string;
  message: string;
  convertedCode: string;
  processing: boolean;
  success: boolean;
  amount: number;
}

export default function Refund(props: IProps) {
  const { terminalStore, uiActions } = props;
  const history = useHistory();
  const { orderId } = useParams<{ orderId: string }>();
  const [orderState, setOrderState] = useState<IOrderState>({
    order: null,
    line_items: null,
    transactions: null,
    total_tax: null,
    subtotal_price: null,
    total_price: null,
    tax_lines: null,
    calculated_refund_transactions: null,
    refund_line_items: null,
  });
  const [calculateState, setCalculateState] = useState({ index: 0, quantity: "" });

  const [stripePaymentState, setStripePaymentState] = useState<IStripePaymentState>({
    onCancel: () => {},
    onOk: () => {},
    cancelText: "",
    okText: "",
    sheetVisible: false,
    titleText: "",
    message: "",
    convertedCode: "",
    processing: false,
    success: false,
    amount: null,
  });

  useEffect(() => {
    void loadOrder();
  }, []);

  useEffect(() => {
    let mounted = true;
    let timeout: NodeJS.Timeout = null;
    if (mounted === true) {
      timeout = global.setTimeout(() => {
        try {
          if (orderState.order) {
            void calculateRefund();
          }
        } catch (error) {
          console.log("err", error);
        }
        return;
      }, 1000);
    }
    return () => {
      mounted = false;
      clearTimeout(timeout);
    };
  }, [calculateState.quantity, calculateState.index]);

  async function loadOrder() {
    const orderRes = await GetOrder({ id: Number(orderId), extended: true, refund: true }, true);
    if (orderRes.status !== StatusCode.OK) {
      return;
    }

    const order = orderRes.data[0];
    const refund_line_items: { line_item_id: number; refund_quantity: number; refund_amount: number }[] = [];

    order.line_items.forEach((line_item: Record<string, any>, i: number) => {
      const refund_line_item = {
        line_item_id: line_item.id,
        refund_quantity: 0,
        refund_amount: line_item.subtotal_price,
      };
      refund_line_items.push(refund_line_item);
    });

    const calculateRes = await PostCalculateRefund(
      {
        order_id: order.id,
        refund_line_items,
        refund_transactions: order.transactions,
      },
      true,
    );

    if (calculateRes.status !== StatusCode.OK) {
      return;
    }

    refund_line_items.forEach((line_item, i) => {
      refund_line_items[i] = { ...line_item, refund_amount: calculateRes.data.data.refund_line_items[i].refund_amount };
    });

    const filtered_transactions = order.transactions?.filter(
      (filteredTransaction: IOrderTransaction) =>
        filteredTransaction.kind === "sale" || filteredTransaction.kind === "capture",
    );

    setOrderState(prevState => ({
      ...prevState,
      order: order,
      line_items: order.line_items,
      transactions: filtered_transactions,
      total_tax: calculateRes.data.data.total_tax,
      subtotal_price: calculateRes.data.data.subtotal_price,
      total_price: calculateRes.data.data.total_price,
      calculated_refund_transactions: calculateRes.data.data.refund_transactions,
      refund_line_items,
      tax_lines: calculateRes.data.data.tax_lines,
    }));
  }

  async function calculateRefund() {
    const calculateRes = await PostCalculateRefund(
      {
        order_id: orderState.order?.id,
        refund_line_items: orderState.refund_line_items,
        refund_transactions: orderState.transactions,
      },
      true,
    );
    if (calculateRes.status !== StatusCode.OK) {
      return;
    }

    const refund_line_items = orderState.refund_line_items;
    calculateRes.data.data.refund_line_items?.forEach((item: Record<string, any>, i: number) => {
      refund_line_items[i].refund_amount = item.refund_amount;
    });

    setOrderState(prevState => ({
      ...prevState,
      calculated_refund_transactions: calculateRes.data.data.refund_transactions,
      total_tax: calculateRes.data.data.total_tax,
      subtotal_price: calculateRes.data.data.subtotal_price,
      total_price: calculateRes.data.data.total_price,
      refund_line_items,
      tax_lines: calculateRes.data.data.tax_lines,
    }));
  }

  function getInteracTransactions() {
    const terminal_transactions: IOrderTransaction[] = [];
    let refund_transactions = orderState.calculated_refund_transactions;
    orderState.transactions?.forEach((transaction, i) => {
      //Add interac transaction with amount > 0
      if (transaction.payment_type === "interac" && orderState.calculated_refund_transactions[i].amount > 0) {
        terminal_transactions.push({
          ...transaction,
          amount: Number(orderState.calculated_refund_transactions[i].amount),
        });
      } else if (
        transaction.payment_type === "interac" &&
        (!orderState.calculated_refund_transactions[i].amount ||
          orderState.calculated_refund_transactions[i].amount == 0)
      ) {
        //Amount = 0, remove from refund_transaction
        refund_transactions = refund_transactions.filter(
          refund_transaction => refund_transaction.parent_id !== transaction.id,
        );
      }
    });

    return [terminal_transactions, refund_transactions];
  }

  async function handleRefund() {
    //Add all interac transactions to an array to be processed
    const transactions = getInteracTransactions();
    const [terminal_transactions] = transactions;
    let [, refund_transactions] = transactions;
    let interacRefundSuccessful = false;

    // Handle interac refunds with the terminal
    if (terminal_transactions.length > 0) {
      //Check if card reader is connected
      if (terminalStore?.reader) {
        for (const transaction of terminal_transactions) {
          // Set sheet to 'Processing'
          setStripePaymentState(prevState => ({
            ...prevState,
            sheetVisible: true,
            onCancel: undefined,
            okText: "Cancel Refund",
            processing: true,
            onOk: async () => {
              setStripePaymentState(prevState => ({
                ...prevState,
                sheetVisible: false,
                onCancel: undefined,
                okText: undefined,
                processing: false,
                onOk: () => {},
                titleText: "",
                success: false,
                message: "",
                amount: null,
              }));
              await clearReaderDisplay();
            },
            amount: transaction.amount,
            titleText: "Processing Refund",
            message: "Please wait while the customer finishes the refund transaction…",
          }));
          //Process interac refund
          const refundRes = await stripeProcessRefund(
            transaction.charge_id,
            transaction.amount * 100,
            transaction.currency,
            handleStripeSuccess,
            handleStripeError,
          );
          uiActions.enqueue();
          await delay(1000);
          uiActions.dequeue();
          if (refundRes?.refund?.status === "succeeded") {
            interacRefundSuccessful = true;
            uiActions.showSuccess("Customer has successfully completed their refund");
          } else {
            //Error, remove from refund_transaction
            refund_transactions = refund_transactions.filter(
              refund_transaction => refund_transaction.parent_id !== transaction.id,
            );
            uiActions.showError("Error proccessing refund");
            uiActions.enqueue();
            await delay(1000);
            uiActions.dequeue();
          }
        }
      } else {
        uiActions.showError("Card reader not connected");
        return;
      }
    }
    //If at least 1 interac refund succeeded, POST the refund for those transactions + non interac transactions
    if ((terminal_transactions.length > 0 && interacRefundSuccessful) || terminal_transactions.length === 0) {
      const refundRes = await PostRefund(
        {
          order_id: orderState.order.id,
          refund_line_items: orderState.refund_line_items,
          refund_transactions: refund_transactions,
        },
        true,
      );
      if (refundRes.status !== StatusCode.OK) {
        uiActions.showError(refundRes.data.message);
        return;
      }
      uiActions.showSuccess(refundRes.data.message);
      history.push("/admin/order/" + String(orderId));
    } else {
      uiActions.showError("Error processing refund");
      if (terminalStore?.reader) {
        await clearReaderDisplay();
      }
    }
  }

  function handleStripeSuccess() {
    setStripePaymentState(prevState => ({
      ...prevState,
      sheetVisible: false,
      onCancel: undefined,
      okText: "Close",
      //Close sheet & reset stripe payment state
      onOk: () => {
        setStripePaymentState(prevState => ({
          ...prevState,
          sheetVisible: false,
          onCancel: undefined,
          okText: undefined,
          processing: false,
          onOk: () => {},
          titleText: "",
          message: "",
          amount: null,
        }));
      },
      processing: false,
      success: true,
      amount: null,
      titleText: "Refund Processed",
      message: "Refund successful",
    }));
  }

  function handleStripeError(message: string, sheetInfo: sheetInfo) {
    //Set error sheet
    setStripePaymentState(prevState => ({
      ...prevState,
      onCancel: undefined,
      onOk: () => {
        // Close sheet
        //sheetInfo.onOk();
        setStripePaymentState(prevState => ({
          ...prevState,
          sheetVisible: false,
          onCancel: undefined,
          okText: undefined,
          processing: false,
          onOk: () => {},
          titleText: "",
          success: false,
          message: "",
          amount: null,
        }));
      },
      okText: "Close",
      sheetVisible: true,
      titleText: sheetInfo.title,
      message: sheetInfo.message,
      convertedCode: sheetInfo.convertedErrorCode,
      success: false,
      processing: false,
      amount: null,
    }));
  }

  function handleQuantityInputChange(event: React.ChangeEvent<HTMLInputElement>, item_quantity: number, index: number) {
    const { value } = event.target;
    const refundLineItems = orderState.refund_line_items;
    if (Number(value) >= 0 && Number(value) <= item_quantity) {
      refundLineItems[index].refund_quantity = Number(value);
      setOrderState(prevState => ({ ...prevState, refund_line_items: refundLineItems }));
      setCalculateState(prevState => ({ ...prevState, index, quantity: value }));
    }
  }

  function handleRefundAmountInputChange(event: React.ChangeEvent<HTMLInputElement>, index: number) {
    const { value } = event.target;
    const transactions = orderState.calculated_refund_transactions;
    //Don't allow an amount greater than the maxiumum refundable amount
    if (Number(value) >= 0 && Number(value) <= transactions[index].maximum_refundable) {
      transactions[index].amount = value;
      setOrderState(prevState => ({ ...prevState, calculated_refund_transactions: transactions }));
    }
  }

  const disableRefundBtn = orderState.calculated_refund_transactions?.every(
    transaction => transaction.maximum_refundable === 0,
  );

  return (
    <>
      <Page
        narrow
        splitLayout
        title={"Order " + orderState.order?.name}
        breadcrumbs={[{ prefix: true, label: "Back to Order", url: "/admin/order/" + String(orderId) }]}
      >
        <Page.Section twoThirds>
          <Card title="Order Items">
            <Card.Section>
              {orderState.order?.line_items &&
                orderState.order.line_items?.map((item: any, index: number) => {
                  return (
                    <Card.SubSection key={index}>
                      <div className="order-line-item">
                        <div>
                          <p className="text-lg font-semibold">{item.product_title}</p>
                          {item.product_title !== item.variant_title ? (
                            <p className="text-sm text-gray-600">{item.variant_title}</p>
                          ) : null}
                        </div>
                        <div className="text-right">
                          <Input
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              handleQuantityInputChange(e, item.refundable_quantity, index)
                            }
                            value={orderState.refund_line_items[index].refund_quantity}
                            suffix={"/ " + String(item.refundable_quantity)}
                            disabled={item.refundable_quantity === 0 || item.non_refundable ? true : false}
                          />
                          <p className="text-lg text-gray-600">
                            x <LocaleCurrency currency="cad" amount={item.price} />
                          </p>
                        </div>
                        <div className="text-right text-lg">
                          <LocaleCurrency currency="cad" amount={orderState.refund_line_items[index].refund_amount} />
                        </div>
                      </div>
                    </Card.SubSection>
                  );
                })}
            </Card.Section>
          </Card>
        </Page.Section>
        <Page.Section oneThird>
          <Card>
            <Card.Section title="Refund Overview">
              <Card.SubSection>
                <div className="flex items-center justify-between">
                  <p className="text-lg">Items Subtotal</p>
                  <LocaleCurrency currency="cad" amount={orderState.subtotal_price} />
                </div>
              </Card.SubSection>
              <Card.SubSection>
                {/* Hide Discount total for now */}
                <div className="flex items-center justify-between" style={{ display: "none" }}>
                  <p className="text-lg">Discount</p>
                  <LocaleCurrency currency="cad" amount={0} />
                </div>

                {orderState.tax_lines?.map((tax_line, i) => {
                  return (
                    <div key={i} className="flex items-center justify-between">
                      <p className="text-lg">{tax_line.title}</p>
                      <LocaleCurrency currency="cad" amount={tax_line.price} />
                    </div>
                  );
                })}

                <div className="flex items-center justify-between">
                  <p className="text-lg">Total Tax</p>
                  <LocaleCurrency currency="cad" amount={orderState.total_tax} />
                </div>
              </Card.SubSection>
              <Card.SubSection>
                <div className="flex items-center justify-between">
                  <p className="text-lg font-semibold">Refund</p>
                  <LocaleCurrency currency="cad" amount={orderState.total_price} />
                </div>
              </Card.SubSection>
              <Card.SubSection>
                <span>Transactions</span>
                {orderState.transactions?.map((transaction: IOrderTransaction, i: number) => {
                  return (
                    <div key={i} className="flex items-center justify-between">
                      <p className="mr-3 mt-3">
                        {(transaction.payment_type[0].toUpperCase() + transaction.payment_type.slice(1))
                          .split("_")
                          .join(" ")}
                      </p>
                      <div className="w-4/5">
                        <Input
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleRefundAmountInputChange(e, i)}
                          value={orderState.calculated_refund_transactions[i]?.amount}
                          prefix="$"
                          type="number"
                        />
                      </div>
                    </div>
                  );
                })}
              </Card.SubSection>
              <Card.SubSection>
                <ButtonNew onClick={handleRefund} block size="medium" type="primary" disabled={disableRefundBtn}>
                  Refund
                </ButtonNew>
              </Card.SubSection>
            </Card.Section>
          </Card>
        </Page.Section>
      </Page>
      {/* Payment Handler Sheet - Handles successes/errors */}
      <>
        {stripePaymentState.processing ? (
          <Popup
            type="info"
            open={stripePaymentState.sheetVisible}
            title="Please wait.."
            description={`Customer is completing refund of $${stripePaymentState.amount}`}
            closable
            onCancel={stripePaymentState.onCancel}
            onOk={stripePaymentState.onOk}
            cancelText={stripePaymentState.cancelText}
            okText={stripePaymentState.okText}
            backDropCancel={false}
          />
        ) : (
          <>
            {stripePaymentState.success ? (
              <Popup
                type="success"
                open={stripePaymentState.sheetVisible}
                title="Refund Complete"
                description={"Customer has successfully completed their refund"}
                closable
                onCancel={stripePaymentState.onCancel}
                onOk={stripePaymentState.onOk}
                cancelText={stripePaymentState.cancelText}
                okText={stripePaymentState.okText}
                backDropCancel={false}
              />
            ) : (
              <Popup
                type="error"
                open={stripePaymentState.sheetVisible}
                title="Refund Error"
                description={stripePaymentState.message + " - Error Code: " + stripePaymentState.convertedCode}
                closable
                onCancel={stripePaymentState.onCancel}
                onOk={stripePaymentState.onOk}
                cancelText={stripePaymentState.cancelText}
                okText={stripePaymentState.okText}
                backDropCancel={false}
              />
            )}
          </>
        )}
      </>
    </>
  );
}
