import Sheet from "components/sheet/Sheet";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NewCustomer from "components/newCustomer/NewCustomer";
import { ICustomer } from "redux/reducers/models/customer";
import GolferCard from "components/bookingPopUp/golferCard/GolferCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select } from "components/select/index";

interface IChangeCustomerProps {
  open: boolean;
  title: string;
  currentCustomerTitle: string;
  newCustomerTitle: string;
  okText: string;
  changeCustomerState: any;
  searchResults: Array<ICustomer>;
  searching: boolean;
  newCustomerActive: boolean;
  onOk: () => void;
  onCancel: () => void;
  handleSearchQueryChange: (customerQuery: string) => void;
  handleCustomerSelection: (id: number, customer: ICustomer) => void;
  handleRemoveSelectedCustomer: () => void;
  handleCreateNewCustomer: (customerInfo: any) => void;
  handleCloseCreateNewCustomer: () => void;
  handleNewCustomerActive: () => void;
}

function ChangeCustomer(props: IChangeCustomerProps) {
  console.log(props);
  const { t, i18n } = useTranslation();
  const { Option } = Select;

  function onOk() {
    void props.onOk();
  }

  function onCancel() {
    props.onCancel();
  }

  function handleCustomerSearch(query: string) {
    void props.handleSearchQueryChange(query);
  }

  function handleCustomerSelection(id: number, customer: ICustomer) {
    void props.handleCustomerSelection(id, customer);
  }

  function handleCreateNewCustomer(customerInfo: any) {
    void props.handleCreateNewCustomer(customerInfo);
  }

  function handleCloseCreateNewCustomer() {
    void props.handleCloseCreateNewCustomer();
  }

  function handleNewCustomerActive() {
    void props.handleNewCustomerActive();
  }

  return (
    <Sheet
      title={props.title}
      open={props.open}
      size="small"
      closable
      onCancel={onCancel}
      onOk={onOk}
      okText={props.okText}
      okDisabled={props.changeCustomerState.selectedCustomer ? false : true}
      overflow
    >
      {props.newCustomerActive ? (
        <NewCustomer
          newCustomerSheetActive={props.newCustomerActive}
          onCancel={handleCloseCreateNewCustomer}
          onOk={handleCreateNewCustomer}
          searchValue={props.changeCustomerState.customerQuery}
        />
      ) : (
        <>
          <span>{props.currentCustomerTitle}</span>
          <GolferCard
            email={
              props.changeCustomerState.bookingParticipant?.customer
                ? props.changeCustomerState.bookingParticipant?.customer?.email
                : props.changeCustomerState.bookingParticipant?.email
            }
            name={props.changeCustomerState.bookingParticipant?.full_name}
            memberCode={
              props.changeCustomerState.bookingParticipant?.customer
                ? props.changeCustomerState.bookingParticipant?.customer?.member_code
                : props.changeCustomerState.bookingParticipant?.member_code
            }
            customerType={
              props.changeCustomerState.bookingParticipant?.customer
                ? props.changeCustomerState.bookingParticipant?.customer?.customer_type
                : props.changeCustomerState.bookingParticipant?.customer_type
            }
            phone={
              props.changeCustomerState.bookingParticipant?.customer
                ? props.changeCustomerState.bookingParticipant?.customer?.phone
                : props.changeCustomerState.bookingParticipant?.phone
            }
          />

          <FontAwesomeIcon
            className="ml-auto mr-auto mt-4 mb-2 block text-primary-500"
            icon={["far", "arrow-circle-down"]}
            size="2x"
          />

          <span>{props.newCustomerTitle}</span>
          {props.changeCustomerState.selectedCustomer ? (
            <GolferCard
              closable
              removeGolfer={props.handleRemoveSelectedCustomer}
              email={
                props.changeCustomerState.selectedCustomer?.customer
                  ? props.changeCustomerState.selectedCustomer?.customer?.email
                  : props.changeCustomerState.selectedCustomer?.email
              }
              name={props.changeCustomerState.selectedCustomer?.full_name}
              memberCode={
                props.changeCustomerState.selectedCustomer?.customer
                  ? props.changeCustomerState.selectedCustomer?.customer?.member_code
                  : props.changeCustomerState.selectedCustomer?.member_code
              }
              customerType={
                props.changeCustomerState.selectedCustomer?.customer
                  ? props.changeCustomerState.selectedCustomer?.customer?.customer_type
                  : props.changeCustomerState.selectedCustomer?.customer_type
              }
              phone={
                props.changeCustomerState.selectedCustomer?.customer
                  ? props.changeCustomerState.selectedCustomer?.customer?.phone
                  : props.changeCustomerState.selectedCustomer?.phone
              }
            />
          ) : (
            <Select
              showSearch
              className={`flex justify-center align-center w-full h-10 position-relative z-20 text-black text-medium appearance-none border-none focus:outline-none placeholder-gray-200`}
              onSearch={(query: string) => handleCustomerSearch(query)}
              onChange={(id: number, customer: ICustomer) => handleCustomerSelection(id, customer)}
              placeholder={"Search customer..."} //TODO: Translate
              allowClear
              searchValue={props.changeCustomerState.customerQuery}
              showDropDownOnFocus={true}
              searching={props.searching}
            >
              <div className="ui-select-dropdown-list-item" onClick={handleNewCustomerActive}>
                <p>New Customer</p> {/** TODO: Translate */}
              </div>
              {props?.searchResults?.map((customer, index) => {
                return (
                  <Option key={index} value={customer.id} name={customer.full_name} extraValues={customer}>
                    <div className="flex justify-between">
                      <div>
                        <div className="text-semibold text-lg">{customer?.full_name}</div>
                        <div className="text-sm text-gray-500">{customer.customer_type}</div>
                        <div className="text-sm text-gray-500">{customer.email}</div>
                        <div className="text-sm text-gray-500">{customer.phone ? customer.phone : null}</div>
                      </div>

                      <div className="text-medium text-base text-gray-500 self-end">{customer.member_code}</div>
                    </div>
                  </Option>
                );
              })}
            </Select>
          )}
        </>
      )}
    </Sheet>
  );
}

export default ChangeCustomer;
