import { APIGet, APIPost, APIPut, APIDelete, StatusCode, IAPIResponse } from "api/protocols";
import { CancelToken } from "axios";
import { weekdayNames } from "helpers/Helpers";
import { ILeague } from "redux/reducers/models/league";
import { IProduct, IVariant } from "redux/reducers/models/product";
import { TWeekDays, WEEK_DAYS } from "components/form/calendarField/helpers";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseLeagueUrl = "/" + apiVersion + "/" + adminType + "/league";

interface ILeagueGet {
  id?: number | string;
  ids?: Array<number> | Array<string>;
  search?: string;
  year?: number;
  logo_source?: string;
}

export interface ILeaguePost {
  name?: string;
  week_day?: TWeekDays;
  start_time?: string;
  start_date?: string;
  end_date?: string;
}

export interface ILeaguePut {
  id: number | string;
  name?: string;
  week_day?: TWeekDays;
  start_time?: string;
  description?: string;
  capacity?: number;
  registration_open?: boolean;
  player_limit?: number;
  waitlist_limit?: number;
  start_date?: string;
  end_date?: string;
  /** TODO: Check backend is attached */
  registration_open_date?: string;
  /** TODO: Check backend is attached */
  registration_close_date?: string;
  default_open_structure?: string;
  default_days_in_advance_start?: number;
  default_days_in_advance_end?: number;
  default_open_time?: string;
  enable_custom_booking_window?: boolean;
  minimum_registration_amount?: number;
  maximum_registration_amount?: number;
}

interface IGetLeagueResponse {
  data: ILeague[];
  status: number;
  message: string;
}

// GET League
// Retrieve a list of leagues
export async function GetLeague(
  params: ILeagueGet,
  useGlobalLoader: boolean,
  token?: CancelToken,
): Promise<IGetLeagueResponse> {
  return await APIGet(baseLeagueUrl, params, useGlobalLoader, token);
}

// POST League
// Create a new league
export async function PostLeague(params: ILeaguePost, useGlobalLoader: boolean) {
  return await APIPost(baseLeagueUrl, params, useGlobalLoader);
}

// PUT League
// Update an existing league
export async function PutLeague(params: ILeaguePut, useGlobalLoader: boolean): Promise<IAPIResponse<ILeague>> {
  return await APIPut(baseLeagueUrl, params, useGlobalLoader);
}

// PUT League
// Update an existing league
export async function DeleteLeague(params: { id: string | number }, useGlobalLoader: boolean) {
  return await APIDelete(baseLeagueUrl, params, useGlobalLoader);
}

//League Logo
export async function UploadLeagueLogo(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseLeagueUrl + "/logo", params, useGlobalLoader);
}

export async function DeleteLeagueLogo(params: any, useGlobalLoader: boolean) {
  return await APIDelete(baseLeagueUrl + "/logo", params, useGlobalLoader);
}
