import React, { useEffect, useState, useCallback } from "react";
import { useHistory, Link } from "react-router-dom";
import { useParams } from "react-router";

// API
import { GetKitchenLocations, PutKitchenLocation } from "api/rpc/facilityAdmin/facility/kitchen/kitchen";
import { GetPrinterAll } from "api/rpc/2022-09/facilityAdmin/facility/printer";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import { Select } from "components/select/";

import { useTranslation, Trans } from "react-i18next";
import { isEqualWith } from "lodash";
import SelectNew from "components/select/SelectNew";

export default function KitchenLocation(props: any) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { Option } = Select;
  const [kitchenStateBeforeChanges, setKitchenStateBeforeChanges] = useState(undefined);
  const [kitchenStateLoaded, setKitchenStateLoaded] = useState<boolean>(false);

  const { locationId }: any = useParams();
  const [state, setState] = useState({
    id: null,
    title: "",
    printer_id: null,
    type: null,
  });

  const [optionState, setOptionState] = useState({
    printers: [],
  });

  const typeOptions = [
    {
      label: t("secure.facility.settings.kitchen.kitchen_location.001"),
      value: "expo",
    },
    {
      label: t("secure.facility.settings.kitchen.kitchen_location.002"),
      value: "kitchen",
    },
    {
      label: t("secure.facility.settings.kitchen.kitchen_location.003"),
      value: "bar",
    },
  ];

  useEffect(() => {
    void loadLocation();
    void loadPrinters();
  }, []);

  async function loadPrinters() {
    const printerRes = await GetPrinterAll(null, false);
    if (printerRes.status !== StatusCode.OK) {
      return;
    }

    const printers = printerRes.data;
    printers.unshift({ title: "None", value: null });

    setOptionState(prev => ({
      ...prev,
      printers: printers,
    }));
  }

  async function loadLocation() {
    const res = await GetKitchenLocations({ id: locationId }, true);
    if (res.status !== StatusCode.OK) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      id: res.data[0].id,
      title: res.data[0].title,
      printer_id: res.data[0].printer_id,
      type: res.data[0].type,
    }));

    setKitchenStateLoaded(true);
  }

  function navigateTo(url: string) {
    history.push(url);
  }

  async function saveLocation() {
    const params = {
      id: state.id,
      title: state.title,
      printer_id: !state.printer_id ? null : state.printer_id,
      type: state.type,
    };

    const updateRes = await PutKitchenLocation(params, true);
    if (updateRes.status !== StatusCode.OK) {
      return;
    }

    history.push("/admin/settings/kitchen-location");
  }

  const primaryAction = {
    content: t("secure.facility.settings.kitchen.kitchen_location.004"),
    action: saveLocation,
  };

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleDropDownChange(value: any, property: string) {
    setState(prev => ({ ...prev, [property]: value }));
  }

  function unsavedChangesExist() {
    if (kitchenStateBeforeChanges === undefined) {
      if (kitchenStateLoaded) {
        setKitchenStateBeforeChanges(state);
      }
      return false;
    }

    return !isEqualWith(kitchenStateBeforeChanges, state);
  }

  function cancelUnsavedChanges() {
    setState(kitchenStateBeforeChanges);
  }

  return (
    <Page
      title={state.title}
      narrow
      // primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.kitchen.kitchen_location.005"),
          url: "/admin/settings/kitchen-location",
        },
      ]}
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: saveLocation,
        onCancel: cancelUnsavedChanges,
      }}
    >
      <Card title={t("secure.facility.settings.kitchen.kitchen_location.006")}>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Input
                value={state.title}
                label={t("secure.facility.settings.kitchen.kitchen_location.007")}
                id="title"
                onChange={handleInputChange}
                placeholder={t("secure.facility.settings.kitchen.kitchen_location.008")}
              />
              <SelectNew
                id={"printer_id"}
                value={state.printer_id}
                onChange={value => handleDropDownChange(value, "printer_id")}
                label={t("secure.facility.settings.kitchen.kitchen_location.009")}
                options={optionState?.printers?.map((printer: any) => ({
                  id: printer?.id,
                  label: printer?.title,
                }))}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <SelectNew
                id={"type"}
                value={state.type}
                onChange={(value: any) => handleDropDownChange(value, "type")}
                label={t("secure.facility.settings.kitchen.kitchen_location.010")}
                options={typeOptions?.map((type: any, index: number) => ({
                  id: type?.value,
                  label: type?.label,
                }))}
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>
    </Page>
  );
}
