import { GetLeague } from "api/rpc/league/league";
// import { LocalStorage } from "api/localstorage";
// import { ICartState } from "reducers/cart";
import { StatusCode } from "api/protocols";

export function loadLeague(params: string, useGlobalLoader: boolean) {
  return async (dispatch: any) => {
    const res = await GetLeague(params, useGlobalLoader);
    if (res.status !== StatusCode.OK) {
      console.log(res);
      return;
    }

    console.log("League: ", res.data.data[0]);

    dispatch({
      type: "league.update",
      payload: {
        isLoaded: true,
        league: res.data.data[0],
      },
    });
  };
}
