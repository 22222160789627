import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useEffect, useState, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import "./category.scss";

interface ISubOptions {
  label: string;
  url: string;
  setSelected?: (url: string) => void;
  showStyle?: boolean;
}

interface IProps {
  label: string;
  url: string;
  setSelected?: (url: string) => void;
  showStyle?: boolean;
  subOptions?: ISubOptions[];
  currentUrl?: string;
  icon?: IconName;
}

export default function Category(props: IProps) {
  const [open, setOpen] = useState(false);

  function updateOpenState() {
    setOpen(!open);
  }

  return (
    <>
      <Link
        to={!props.subOptions ? props.url : "#"}
        className={
          "flex items-center last:mb-0 hover:rounded-lg cursor-pointer relative ui-category " +
          (props.showStyle && "ui-category-select")
        }
        onClick={!props.subOptions && props.setSelected ? () => props.setSelected(props.url) : updateOpenState}
      >
        <div className="ui-category-label-group">
          {props.icon && (
            <FontAwesomeIcon
              fixedWidth
              icon={["far", props.icon]}
              className={classNames({ "ui-category-selected": !props.subOptions && props.showStyle })}
              style={{ marginRight: "1rem" }}
            />
          )}
          <p
            className={classNames("font-medium text-base", {
              "ui-category-selected": !props.subOptions && props.showStyle,
            })}
            style={{ fontSize: "1rem" }}
          >
            {props.label}
          </p>
        </div>
      </Link>
      {props.subOptions && open && (
        <div className="ui-category-subcategory">
          {props.subOptions.map((item: any, index: number) => (
            <Category
              key={index}
              label={item.label}
              url={item.url}
              showStyle={props.currentUrl === item.url ? true : false}
              setSelected={props.setSelected}
              currentUrl={props.currentUrl}
              icon={item?.icon}
            />
          ))}
        </div>
      )}
    </>
  );
}
