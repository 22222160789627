export interface IUIState {
  queue: number;
  loading: boolean;
  showError: boolean;
  errorMessage: string;
  showSuccess: boolean;
  successMessage: string;
  duration: number | null;
  newBookingActive: boolean;
  editBookingActive: boolean;
  editBookingStartTab: number | null;
}

export interface IUIAction {
  type: string;
  payload: any;
}

const initialState: IUIState = {
  queue: 0,
  loading: false,
  showError: false,
  errorMessage: "",
  showSuccess: false,
  successMessage: "",
  duration: null,
  newBookingActive: false,
  editBookingActive: false,
  editBookingStartTab: null,
};

export default function UIReducer(state = initialState, action: IUIAction) {
  switch (action.type) {
    case "loading":
      return {
        ...state,
        loading: action.payload,
      };
    case "modify.queue":
      return {
        ...state,
        queue: action.payload,
      };
    case "error.show":
      return {
        ...state,
        showError: true,
        errorMessage: action.payload.errorMessage,
        duration: action.payload.duration,
      };
    case "error.close":
      return {
        ...state,
        showError: false,
        errorMessage: "",
        duration: null,
      };
    case "success.show":
      return {
        ...state,
        showSuccess: true,
        successMessage: action.payload.successMessage,
        duration: action.payload.duration,
      };
    case "success.close":
      return {
        ...state,
        showSuccess: false,
        successMessage: "",
        duration: null,
      };

    case "booking.initiate":
      return {
        ...state,
        newBookingActive: true,
      };

    case "booking.close":
      return {
        ...state,
        newBookingActive: false,
      };

    case "editBooking.initiate":
      return {
        ...state,
        editBookingActive: true,
        editBookingStartTab: action.payload.editBookingStartTab,
      };

    case "editBooking.close":
      return {
        ...state,
        editBookingActive: false,
      };

    default:
      return state;
  }
}
