import React, { forwardRef, MouseEvent } from "react";

type TCalendarGridItem = {
  dayOfMonth: number;
  dayOfMonthClick?: () => void;
  gridClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  selected?: boolean;
  today?: boolean;
  /** In mobile view, do not display selected date style */
  mobileView?: boolean;
  children?: any;
};

const CalendarGridDay = forwardRef<HTMLDivElement, TCalendarGridItem>((props, ref) => {
  function handleOnClick(e: MouseEvent<HTMLDivElement | HTMLSpanElement>, type: "grid" | "dayOfMonth") {
    if (type === "grid" && props.gridClick) {
      e.stopPropagation();

      props.gridClick(e as MouseEvent<HTMLDivElement>);
    }
    if (type === "dayOfMonth" && props.dayOfMonthClick) {
      props.dayOfMonthClick();
    }
  }

  return (
    <div
      key={props.dayOfMonth}
      className={`grid-item${props.today ? " today" : ""}${props.selected ? " selected" : ""}${
        props.gridClick ? " clickable" : ""
      }${props.selected && props.mobileView ? " mobile-selected" : ""}`}
      onClick={e => handleOnClick(e, "grid")}
      ref={ref}
    >
      <span className="grid-item-date-wrap" onClick={e => handleOnClick(e, "dayOfMonth")}>
        <p className="grid-item-date">{props.dayOfMonth}</p>
        {props.today ? <p className="grid-item-today-indicator">&#x2022;</p> : null}
      </span>
      {props.children}
    </div>
  );
});

CalendarGridDay.displayName = "CalendarGridDay";
export default CalendarGridDay;
