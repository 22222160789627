import React, { useEffect, useRef, useState } from "react";
import axios, { CancelToken } from "axios";
import { useParams } from "react-router";

import { StatusCode } from "api/protocols";
import { GetGuestFacilityStatus } from "api/rpc/2024-04/guest/facility";

import { showError } from "redux/actions/ui";
import { TFacilityStatus } from "redux/reducers/models/facility";

import { useAppDispatch } from "hooks/redux";
import useModal from "hooks/modals/useModal";
import { formatDate, sortObj } from "helpers/Helpers";

import Calendar from "components/calendar/Calendar";
import CalendarGridDay from "components/calendar/CalendarGridDay";
import { Badge } from "components/badge/Badge";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import FacilityStatusInfo from "../pages/guest/Customer/ClientPortal/Calendar/FacilityStatusInfo";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { MOBILE_WIDTH } from "helpers/ScreenSizes";

export default function CalendarIFrame() {
    const { facilityShortName } = useParams<{ facilityShortName: string }>();
    const [calendarDay, setCalendarDay] = useState(new Date());
    const [facilityStatusList, setFacilityStatusList] = useState<TFacilityStatus[]>(undefined);
    const [statusLoadTrigger, setStatusLoadTrigger] = useState(true);

    const dayRefs = useRef<HTMLDivElement[]>([]);

    const windowSize = useWindowSize();
    const isMobile = windowSize.width <= MOBILE_WIDTH;
    
    const {
        state: statusModal,
        updateModal: updateStatusModal,
        closeModal: closeStatusModal,
    } = useModal({
        activeDate: null as string,
        ref: null as HTMLDivElement,
        statusData: [] as TFacilityStatus[],
        type: null as "updates" | "status",
    });

    const dispatch = useAppDispatch();

      // Reload when selected facility changes
  useEffect(() => {
    const source = axios.CancelToken.source();
    if (statusLoadTrigger) {
        void loadFacilityStatusList(facilityShortName, calendarDay.getMonth(), source.token);
    }
    return () => source.cancel();
  }, [facilityShortName, statusLoadTrigger, calendarDay]);

    async function loadFacilityStatusList(shortName: string, month: number, token?: CancelToken) {
        if (facilityStatusList !== undefined) {
            setFacilityStatusList(undefined);
        }

        const res = await GetGuestFacilityStatus(
            { short_name: shortName, month: month + 1 },
            token ? false : true,
            token,
        );

        if (token && token.reason) {
            return;
        }

        if (res.status !== StatusCode.OK) {
            dispatch(showError("Error loading facility details.")); // TODO: Translation
        }

        setFacilityStatusList(res.status !== StatusCode.OK ? [] : res.data);
        if (statusLoadTrigger) {
            setStatusLoadTrigger(false);
        }
    }

    function handleDateChange(date: Date) {
        if (date.getMonth() !== calendarDay.getMonth() || date.getFullYear() !== calendarDay.getFullYear()) {
          setStatusLoadTrigger(true);
        }
    
        setCalendarDay(date);
    }

    function handleCalendarCellClick(data: TFacilityStatus[], dateOfMonth: number) {
        updateStatusModal({
          isOpen: true,
          activeDate: data[0].date,
          ref: dayRefs.current[dateOfMonth],
          statusData: sortObj<TFacilityStatus>(data, "label"),
          type: "status",
        });
    }

    return (
        <div style={{ padding: "16px 0 16px 24px" }}>
          <Calendar 
            selectedDate={calendarDay}
            setSelectedDate={date => handleDateChange(date)}
            loading={facilityStatusList === undefined}
            renderGridItem={(dateOfMonth: number, formattedDate: string) => {
                const data = facilityStatusList.filter(val => val.date === formattedDate);

                return (
                    <CalendarGridDay 
                        key={formattedDate} 
                        dayOfMonth={dateOfMonth}
                        dayOfMonthClick={() => (isMobile ? handleCalendarCellClick(data, dateOfMonth) : undefined)}
                        gridClick={e => setCalendarDay(new Date(formattedDate.replace(/-/g, "/")))}
                        selected={formatDate(new Date(formattedDate.replace(/-/g, "/"))) === formatDate(calendarDay)} today={formatDate(new Date(formattedDate.replace(/-/g, "/"))) === formatDate(new Date())}
                        ref={el => (dayRefs.current[dateOfMonth] = el)}
                        mobileView={isMobile}
                    >
                      {data.length > 0 && (
                        <div
                            className={`calendar-badge-grouping${isMobile ? " mobile" : ""}${
                            statusModal.isOpen && statusModal.activeDate === formattedDate && statusModal.type === "status"
                                ? " active"
                                : ""
                            }`}
                            onClick={e => (isMobile ? handleCalendarCellClick(data, dateOfMonth) : undefined)}
                        >
                            <Badge
                            className={`status-badge event${
                                statusModal.isOpen && statusModal.activeDate === formattedDate && statusModal.type === "status"
                                ? " active"
                                : ""
                            }`}
                            size="small"
                            onClick={e => (!isMobile ? handleCalendarCellClick(data, dateOfMonth) : undefined)}
                            >
                                {isMobile ? "" : "Daily Updates" /* TODO: Translation */}
                            </Badge>
                        </div>
                      )}
                    </CalendarGridDay>
                );
            }}
          />
          <FacilityStatusInfo
            isOpen={statusModal.isOpen}
            close={closeStatusModal}
            dateString={statusModal.activeDate}
            gridRefElement={!isMobile ? statusModal.ref : undefined}
            iFrame={true}
          >
            {statusModal.statusData
                .filter(val => val.label.toLowerCase().includes("note"))
                .map(status => (
                    <div key={status.id} className="status-note">
                    <p className="note-label">Note</p> {/* TODO: Translation */}
                    <p className="note-value">{status.value}</p>
                    </div>
                ))
            }
            <DataTable
              columns={[
                { label: "", width: "50%" },
                { label: "", width: "50%" },
              ]}
              hideHeader
              className="client-portal-calendar"
            >
            {statusModal.statusData
                .filter(val => !val.label.toLowerCase().includes("note"))
                .map(status => (
                <tr key={status.id}>
                    <td>{status.label}</td>
                    <td>{status.value}</td>
                </tr>
                ))}
            </DataTable>
          </FacilityStatusInfo>
        </div>
    );
}