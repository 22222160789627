import React, { Fragment, useRef, useState } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ITournament } from "redux/reducers/models/tournament";
import { ILeague } from "redux/reducers/models/league";
import moment from "moment";
import { convertTime } from "helpers/Helpers";
import "./mobileEventCard.scss";

export interface IMobileEventProps {
  tournament?: ITournament;
  league?: ILeague;
  disableRegister: boolean;
  registerDates: { open_date: string; open_time: string; close_date: string; close_time: string };
  navigateToRegisterLink: (type: "tournament" | "league", handle: string) => void;
}

interface IFilterState {
  checked: boolean;
}

const MobileEventCard: React.FC<IMobileEventProps> = props => {
  const { tournament, league, disableRegister, registerDates, navigateToRegisterLink, ...rest } = props;
  const [filterState, setFilterState] = useState<IFilterState>({
    checked: false,
  });

  const icon = filterState.checked ? "chevron-up" : "chevron-down";

  return (
    <div
      className={classNames("mobile-event-card_container", {})}
      onClick={() => setFilterState(prevState => ({ ...prevState, checked: !filterState.checked }))}
    >
      <div>
        <div className="flex justify-between items-center">
          <div className="mobile-event-card_event_name">
            <div>{tournament ? tournament.name : league.name}</div>
          </div>

          <div>
            <FontAwesomeIcon className="mobile-event-card_icon" icon={["fal", icon]} />
          </div>
        </div>

        <div>{league && <div className="mobile-event-card_league_day">{league.week_day}</div>}</div>
      </div>

      <div>
        {tournament && (
          <div className="mobile-event-card_date">
            {moment(`${tournament?.date} ${tournament?.start_time}`).format("MMMM DD, YYYY h:mm A")}
          </div>
        )}

        {league && (
          <Fragment>
            <div className="mobile-event-card_date">
              {league?.start_date && league?.end_date && (
                <div>
                  {moment(`${league?.start_date}`).format("MMMM DD ")} -{" "}
                  {moment(`${league?.end_date}`).format("MMMM DD, YYYY")}
                </div>
              )}
              {league?.start_time && <div>{convertTime(league?.start_time)}</div>}
            </div>
          </Fragment>
        )}
      </div>

      {filterState.checked ? (
        <Fragment>
          <div className="mobile-event-card_player_text">
            <FontAwesomeIcon className="events-players" icon={["fal", "user-group"]} /> Players:{" "}
            {tournament ? tournament?.total_participant_count : league?.total_participant_count}
            {tournament?.player_limit
              ? ` / ${tournament?.player_limit}`
              : league?.player_limit
              ? ` / ${league?.player_limit}`
              : ""}
          </div>

          <div>
            {tournament && (
              <Fragment>
                {!!tournament?.registration_enabled && (
                  <>
                    {registerDates.open_date && registerDates.open_time && (
                      <p className="mobile-event-card_events_registration">
                        <span>Registration Opens</span> {registerDates.open_date}{" "}
                        {moment(registerDates.open_time, "HH:mm:ss").format("h:mm A")}
                      </p>
                    )}
                    {registerDates.close_date && registerDates.close_time && (
                      <p className="mobile-event-card_events_registration">
                        <span>Registration Closes</span> {registerDates.close_date}{" "}
                        {moment(registerDates.close_time, "HH:mm:ss").format("h:mm A")}
                      </p>
                    )}
                  </>
                )}
              </Fragment>
            )}
          </div>
          <div>
            {league && (
              <Fragment>
                {!!league?.registration_open && (
                  <>
                    {registerDates.open_date && registerDates.open_time && (
                      <p className="mobile-event-card_events_registration">
                        <span>Registration Opens</span> {registerDates.open_date}{" "}
                        {moment(props.registerDates.open_time, "HH:mm:ss").format("h:mm A")}
                      </p>
                    )}
                    {registerDates.close_date && registerDates.close_time && (
                      <p className="mobile-event-card_events_registration">
                        <span>Registration Closes</span> {registerDates.close_date}{" "}
                        {moment(registerDates.close_time, "HH:mm:ss").format("h:mm A")}
                      </p>
                    )}
                  </>
                )}
              </Fragment>
            )}
          </div>

          <div>
            {tournament && (
              <Fragment>
                {!!tournament?.registration_enabled && (
                  <button
                    className={classNames("mobile-event-card_events_data_button")}
                    disabled={disableRegister}
                    onClick={() => navigateToRegisterLink("tournament", tournament?.handle)}
                  >
                    Register
                  </button>
                )}

                {/* <span className="events-data-button">Results</span> */}
              </Fragment>
            )}

            {league && (
              <Fragment>
                {!!league?.registration_open && (
                  <button
                    className={classNames("mobile-event-card_events_data_button")}
                    disabled={disableRegister}
                    onClick={() => navigateToRegisterLink("league", league?.handle)}
                  >
                    Register
                  </button>
                )}

                {/* <button
                       className="events-data-button"
                       onClick={() => navigateToResultsLink("league", league?.handle)}
                     >
                       Results
                     </button> */}
              </Fragment>
            )}
          </div>
        </Fragment>
      ) : null}
    </div>
  );
};

export default MobileEventCard;
