import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import {
  GetMembership,
  TFacilityMembership,
  TMembershipGet,
} from "api/rpc/2024-04/facilityAdmin/membership/membership";

import { showError } from "redux/actions/ui";
import { FilterValue } from "redux/actions/filters/filters";
import { MembershipFilterGrouping } from "redux/reducers/filters/types";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { valueToString } from "helpers/Helpers";

import Page from "components/page/Page";
import DropFilter from "components/dropdown/DropFilter";
import Search from "components/search/Search";
import DataTable from "../customer/tabs/houseAccounts/DataTable";
import CustomerImage from "elements/customer/CustomerImage";

export default function Memberships() {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { authStore, facilityStore } = useAppSelector(store => store);
  const creationPermission =
    facilityStore.facility?.permissions?.products_create && authStore?.user?.permissions?.products_create;
  const [memberships, setMemberships] = useState<TFacilityMembership[]>(undefined);
  const [filters, setFilters] = useState<TMembershipGet>({
    search: "",
    filters: [],
  });

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (filters.search !== undefined) {
      void loadMemberships(source.token);
    }

    return () => source.cancel();
  }, [filters]);

  async function loadMemberships(token?: CancelToken) {
    if (memberships !== undefined) {
      setMemberships(undefined);
    }

    const membershipRes = await GetMembership(filters, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (membershipRes.status !== StatusCode.OK) {
      dispatch(showError(typeof membershipRes.data === "string" ? membershipRes.data : "Error loading memberships."));
    }

    setMemberships(membershipRes.status === StatusCode.OK ? membershipRes.data : []);
  }

  function applyFilter(filterData: Array<Record<string, any>>, groupLabel: MembershipFilterGrouping) {
    const values = filterData.map((type: any) => type?.value as FilterValue);

    if (filters.filters?.length === 0) {
      setFilters(prev => ({ ...prev, filters: [{ label: groupLabel, value: values }] }));
      return;
    }

    const newFilterGroup = [...filters.filters]
      .map(group => {
        // do not touch a different filter group
        if (group?.label !== groupLabel) {
          return group;
        }

        //remove filter group if values are empty
        if (values.length === 0) {
          return;
        } else {
          return {
            label: groupLabel,
            value: values,
          };
        }
      })
      .filter(val => val !== undefined);

    setFilters(prev => ({
      ...prev,
      filters: newFilterGroup,
    }));
  }

  const primaryAction = {
    content: t("secure.facility.product.memberships.001"),
    action: () => history.push("/admin/product/new"),
    disabled: !creationPermission,
  };

  return (
    <Page title={t("secure.facility.product.memberships.002")} narrow primaryAction={primaryAction}>
      <DropFilter
        title={t("secure.facility.product.memberships.003")}
        filterData={[
          { value: "membership", title: "Membership" }, // TODO: Translation
          { value: "add_on", title: "Add On" }, // TODO: Translation
        ]}
        filterLabelPropFromData="title"
        filterIdPropFromData="value"
        filterType="Checkbox"
        applyFilters={filterReturn => applyFilter(filterReturn, "membership_type")}
        leftAligned
      />
      <div className="flex-grow mb-4 pt-4">
        <Search
          searchCallback={searchValue => setFilters(prev => ({ ...prev, search: searchValue }))}
          historyKey="memberships-all"
          restrictPresetToRoutes={["admin/membership"]}
        />
      </div>

      <DataTable<TFacilityMembership>
        columns={[
          { label: "", width: "20%" }, //TODO: Translation
          { label: "Title", width: "35%" }, //TODO: Translation
          { label: "", width: "25%" },
          { label: "Type", width: "10%" }, //TODO: Translation
          { label: "Customer Type", width: "10%" }, //TODO: Translation
        ]}
        loading={memberships === undefined}
      >
        {memberships?.map((membership: any, index: number) => {
          return (
            <tr
              key={index}
              onClick={() => history.push("/admin/membership/" + String(membership.id))}
              className="clickable"
            >
              {membership.logo_image_source ? (
                <td>
                  <div>
                    <img className="customer-ticket-logo" src={membership.logo_image_source} />
                  </div>
                </td>
              ) : (
                <td></td>
              )}
              <td>
                <p className="lead-text">{membership.title}</p>
                {membership.subtitle ? <p className="support-text">{membership.subtitle}</p> : null}
              </td>
              <td>{valueToString(membership.type)}</td>
              <td>{valueToString(membership?.membership_type)}</td>
              <td>{membership?.customer_type_title}</td>
            </tr>
          );
        })}
      </DataTable>
    </Page>
  );
}
