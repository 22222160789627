import { APIGet, APIPost, APIPut, APIDelete, StatusCode } from "api/protocols";
import { CancelToken } from "axios";
import { IProduct } from "redux/reducers/models/product";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/product";

interface IGetProduct {
  id?: number;
  search?: string;
  extended?: boolean;
  extended_variants?: boolean;
  sales_channels?: boolean;
  inventory?: boolean;
  limit?: number;
  offset?: number;
  type?: string;
  types?: any;
  all?: boolean;
  modifier_groups?: number;
  track_inventory?: boolean;
  filters?: {
    label: ExtendedFacilityProductFilterKey;
    value: string[] | number[];
  }[];
}

/** filter params that can be added into the product API call. */
export type FacilityProductExtendedFilters = {
  /** Return all the products that have these product types. */
  type: string[];
  /** Return all the products from the department with these ID's. */
  department_id: number[];
  /** Return all the products from the department categories with these ID's. */
  category_id: number[];
  /** Return all the products from the subcategories with these ID's. */
  subcategory_id: number[];
};

export type ExtendedFacilityProductFilterKey = keyof FacilityProductExtendedFilters;

interface IGetProductRes {
  data: IProduct[];
  message: string;
  status: StatusCode;
}

export async function GetProduct(
  params: IGetProduct,
  useGlobalLoader: boolean,
  cancelToken?: CancelToken,
): Promise<IGetProductRes> {
  return await APIGet(baseUrl, params, useGlobalLoader, cancelToken);
}

interface IPutProductAttach {
  product_id: number;
}

interface IProductRes {
  status: number;
  message?: string;
  data: any;
}

// Attach a product to a facility
export async function PutProductAttach(params: IPutProductAttach, useGlobalLoader?: boolean): Promise<IProductRes> {
  return await APIPut(baseUrl + "/attach", params, useGlobalLoader);
}

export async function PutProductDetach(params: { product_ids: Array<number> }, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/detach", params, useGlobalLoader);
}

// POST Products
export async function PostProduct(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

// PUT Product
export async function PutProduct(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

// DELETE Product
export async function DeleteProduct(useGlobalLoader: boolean) {
  return await APIDelete(baseUrl, useGlobalLoader);
}

// Duplicate Product / Variant
export async function DuplicateProduct(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/duplicate", params, useGlobalLoader);
}

// GET Variant
interface IGetVariant {
  id?: number;
  product_id?: number;
  search?: string;
  barcode?: string;
  extended?: boolean;
  types?: any;
}

interface IPutVariantLabels {
  offset: number;
  variants: Array<{ variant_id: number; quantity: number }>;
  template_id?: number;
}

export async function GetVariant(params: IGetVariant, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseUrl + "/variant", params, useGlobalLoader, token);
}

// PUT Variant
export async function PostVariant(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/variant", params, useGlobalLoader);
}

// PUT Variant
export async function PutVariant(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/variant", params, useGlobalLoader);
}

// Duplicate Variant
export async function DuplicateVariant(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/variant/duplicate", params, useGlobalLoader);
}

interface IVariantFacilityAccess {
  id: number;
  facility_access: boolean;
}

interface IPutVariantsFacilityAccess {
  variants: IVariantFacilityAccess[];
}

export async function PutVariantsFacilityAccess(params: IPutVariantsFacilityAccess, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/variant/attach", params, useGlobalLoader);
}

export async function PutVariantLabels(params: IPutVariantLabels, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/variant/label", params, useGlobalLoader);
}

// GET Product types
export async function GetProductTypes(params: { id?: number }, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseUrl + "/type", params, useGlobalLoader, token);
}
export type TPostProductTypeParams = { title: string };
export async function PostProductType(params: { title: string }, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/type", params, useGlobalLoader);
}

export type TPutProductTypeParams = { id: number; title: string };
export async function PutProductType(params: TPutProductTypeParams, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/type", params, useGlobalLoader);
}
export async function DeleteProductType(params: { id: number }, useGlobalLoader: boolean) {
  return await APIDelete(baseUrl + "/type", params, useGlobalLoader);
}

interface IGetInventoryLevel {
  variant_id: number;
  extended: boolean;
}

// GET Inventory Levels
export async function GetInventoryLevels(params: IGetInventoryLevel, useGlobalLoader: boolean) {
  return await APIGet(baseUrl + "/inventory/level", params, useGlobalLoader);
}

// PUT Update Inventory Level
export async function UpdateInventoryLevels(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/inventory/level", params, useGlobalLoader);
}

// POST Add Products to Variant
export async function AddProductToVariant(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/combination", params, useGlobalLoader);
}

//Attach modifier group to product
export async function AttachModifierGroup(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/modifier", params, useGlobalLoader);
}

export async function PostProductImage(params: FormData, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/image", params, useGlobalLoader);
}

export async function DeleteProductImage(params: { id: number }, useGlobalLoader: boolean) {
  return await APIDelete(baseUrl + "/image", params, useGlobalLoader);
}

//Product Options
export async function PostProductOption(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/option", params, useGlobalLoader);
}
