import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "master-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/client/admin";

interface IGetStaffAccount {
  id?: number;
  client_id?: number;
  facility_id?: number;
}

interface IPutStaffAccount {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  username: string;
  quick_code: number;
}

interface IPostStaffAccount {
  client_id: number;
  user_type: number;
  first_name: string;
  last_name: string;
  email: string;
  username: string;
}

// GET Product Types
export async function GetStaffAccounts(params: IGetStaffAccount, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseUrl, params, useGlobalLoader, token);
}

// PUT Product Type
export async function PutStaffAccount(params: IPutStaffAccount, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

// POST Product Type
export async function PostStaffAccount(params: IPostStaffAccount, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

// Disable Staff Account
export async function DisableStaffAccount(params: { admin_id: number }, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/disable", params, useGlobalLoader);
}

// Reactivate Staff Account
export async function ReactivateStaffAccount(params: { admin_id: number }, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/reactivate", params, useGlobalLoader);
}

interface IPutStaffAccountPermissions {
  user_id: number;
  accounts_charge_all: boolean;
  accounts_post_adjustment: boolean;
  admin_accounts_disable: boolean;
  admin_permissions_edit: boolean;
  admin_accounts_edit: boolean;
  products_archive: boolean;
  products_create: boolean;
  products_edit: boolean;
  products_edit_cost: boolean;
  products_edit_price: boolean;
  orders_remove_line_item: boolean;
  orders_remove_line_item_after_send: boolean;
  orders_edit_line_item_price: boolean;
  orders_apply_discount: boolean;
  orders_void: boolean;
  orders_refund_tip: boolean;
  reports_access: boolean;
  tee_sheet_credit_card_override: boolean;
  tee_sheet_charge_no_show: boolean;
  orders_edit_user: boolean;
  orders_edit_register: boolean;
  orders_backdate: boolean;
  table_service_table_override: boolean;
  reservations_credit_card_override: boolean;
  merge_customers: boolean;

  cash_outs_create: boolean;
  cash_outs_edit: boolean;
  cash_outs_view_summary: boolean;

  memberships_apply_manual: boolean;
  user_edit_customer_type: boolean;
  discounts_create: boolean;
  gift_cards_view: boolean;
  customers_edit_note: boolean;
  bookings_edit_note: boolean;
  gift_cards_import: boolean;
}

export async function PutStaffAccountPermissions(params: IPutStaffAccountPermissions, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/permission", params, useGlobalLoader);
}
