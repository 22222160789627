import React, { useEffect, useRef } from "react";
import { Handle, Position, Node, NodeProps } from "reactflow";
import "./dynamicPricingFactorNode.scss";
import { useDynamicPricingContext } from "../DynamicPricingContext";
import {
  DYNAMIC_PRICING_FACTOR_GROUP_NODE_CLASS_NAME,
  DYNAMIC_PRICING_FACTOR_GROUP_VARIATION_ID,
  DynamicPricingFactorValue,
} from "./DynamicPricingFactorGroupNode";
import FactorNodeNumberRangeEditMode, {
  isFactorNumberRangeValid,
} from "./factorNodeEditModes/FactorNodeNumberRangeEditMode";
import FactorNodeTimeRangeEditMode, {
  IFactorTimeValue,
  isFactorTimeRangeValid,
} from "./factorNodeEditModes/FactorNodeTimeRangeEditMode";
import FactorNodePercentRangeEditMode, {
  isFactorPercentRangeValid,
} from "./factorNodeEditModes/FactorNodePercentRangeEditMode";
import FactorNodePercentVariationEditMode, {
  isFactorPercentVariationValid,
} from "./factorNodeEditModes/FactorNodePercentVariationEditMode";
import { useLimitOutgoingBranching } from "./useLimitOutgoingBranching";
import classNames from "classnames";

export const DYNAMIC_PRICING_FACTOR_NODE = "DYNAMIC_PRICING_FACTOR_NODE";
export const DYNAMIC_PRICING_FACTOR_NODE_HEIGHT = 37;
export const DYNAMIC_PRICING_FACTOR_NODE_CLASS_NAME = "dynamic-pricing-factor-node";

export type DynamicPricingFactorNodeData = {
  primaryKey: number;
  groupForeignKey: number;
  parentId: string;
  groupElement: HTMLDivElement;
  groupId: string;
  nextGroupId: string;
  connectedVariationId: string;
  path: string;
  outgoingBranchingLimit: number;
} & DynamicPricingFactorValue;

export interface IDynamicPricingFactorNode extends Node {
  type: typeof DYNAMIC_PRICING_FACTOR_NODE;
  data: DynamicPricingFactorNodeData;
}

type DynamicPricingFactorNodeProps = NodeProps<DynamicPricingFactorNodeData>;

export function isFactorValid(data: DynamicPricingFactorNodeData) {
  switch (data.valueType) {
    case "number_range":
      return isFactorNumberRangeValid(data.startNumber, data.endNumber);
    case "time_range":
      return isFactorTimeRangeValid(data.startTime, data.endTime);
    case "percent_range":
      return isFactorPercentRangeValid(data.startPercent, data.endPercent);
    case "percent_variation":
      return isFactorPercentVariationValid(data.percentVariation);
    default:
      return false;
  }
}

export default function DynamicPricingFactorNode(props: DynamicPricingFactorNodeProps) {
  const { id, data } = props;

  const dynamicPricingFactorNodeRef = useRef<HTMLDivElement>();

  const dynamicPricingContext = useDynamicPricingContext();

  const isHandleConnectable = useLimitOutgoingBranching(id, data.outgoingBranchingLimit);

  const isSelected = dynamicPricingContext.selectedNode?.id === id;

  const factorValid = isFactorValid(data);

  function unselectFactor() {
    if (!factorValid) {
      dynamicPricingContext.deleteFactor(id);
    } else {
      dynamicPricingContext.setSelectedNode(null);
    }
  }

  function handleDocumentMousedown(event: any) {
    if (
      !isSelected ||
      !event?.target ||
      !dynamicPricingFactorNodeRef.current ||
      dynamicPricingFactorNodeRef.current.contains(event.target) ||
      dynamicPricingContext.flowActionPanelRef?.current?.contains(event.target) ||
      ((event.target.closest(`.${DYNAMIC_PRICING_FACTOR_NODE_CLASS_NAME}`) != null ||
        event.target.closest(`.${DYNAMIC_PRICING_FACTOR_GROUP_NODE_CLASS_NAME}`) != null) &&
        event.target.id !== DYNAMIC_PRICING_FACTOR_GROUP_VARIATION_ID &&
        factorValid)
    ) {
      return;
    }

    unselectFactor();
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleDocumentMousedown, true);

    return () => {
      document.removeEventListener("mousedown", handleDocumentMousedown, true);
    };
  }, [isSelected, factorValid]);

  function formatTime(time: IFactorTimeValue) {
    const hours12 = time.hours === 0 || time.hours === 12 ? 12 : time.hours % 12;

    return `${hours12}:${time.minutes < 10 ? `0${time.minutes}` : `${time.minutes}`} ${time.hours >= 12 ? "PM" : "AM"}`;
  }

  function displayViewMode() {
    let label = "Invalid";
    let labelColor: "black" | "red" | "green" = "black";

    if (factorValid) {
      switch (data?.valueType) {
        case "number_range":
          label = `${data.startNumber} - ${data.endNumber}`;
          break;
        case "time_range":
          label = `${formatTime(data.startTime)} - ${formatTime(data.endTime)}`;
          break;
        case "percent_range":
          label = `${data.startPercent}% - ${data.endPercent}%`;
          break;
        case "percent_variation":
          label = `${data.percentVariation > 0 ? "+" : ""}${data.percentVariation}%`;

          if (data.percentVariation < 0) {
            labelColor = "red";
          } else if (data.percentVariation > 0) {
            labelColor = "green";
          }
          break;
      }
    }

    return <div style={{ color: labelColor }}>{label}</div>;
  }

  function handleConfirmFactorValueChange(value: DynamicPricingFactorValue) {
    dynamicPricingContext.updateFactor(id, value);
  }

  function handleCancelFactorValueChange() {
    unselectFactor();
  }

  function displayEditMode() {
    switch (data.valueType) {
      case "number_range":
        return (
          <FactorNodeNumberRangeEditMode
            parentId={data.parentId}
            id={id}
            defaultStartNumber={data.startNumber}
            defaultEndNumber={data.endNumber}
            onConfirm={handleConfirmFactorValueChange}
            onCancel={handleCancelFactorValueChange}
          />
        );
      case "time_range":
        return (
          <FactorNodeTimeRangeEditMode
            parentId={data.parentId}
            id={id}
            defaultStartTime={data.startTime}
            defaultEndTime={data.endTime}
            onConfirm={handleConfirmFactorValueChange}
            onCancel={handleCancelFactorValueChange}
          />
        );
      case "percent_range":
        return (
          <FactorNodePercentRangeEditMode
            parentId={data.parentId}
            id={id}
            defaultStartPercent={data.startPercent}
            defaultEndPercent={data.endPercent}
            onConfirm={handleConfirmFactorValueChange}
            onCancel={handleCancelFactorValueChange}
          />
        );
      case "percent_variation":
        return (
          <FactorNodePercentVariationEditMode
            defaultPercentVariation={data.percentVariation}
            onConfirm={handleConfirmFactorValueChange}
            onCancel={handleCancelFactorValueChange}
          />
        );
      default:
        return <div>Invalid</div>;
    }
  }

  return (
    <div
      ref={dynamicPricingFactorNodeRef}
      className={DYNAMIC_PRICING_FACTOR_NODE_CLASS_NAME}
      style={{
        border: isSelected && "1px solid black",
        height: `${DYNAMIC_PRICING_FACTOR_NODE_HEIGHT}px`,
      }}
      onClick={() => {
        if (!isSelected) {
          dynamicPricingContext.setSelectedNode({
            id,
            isGroup: false,
            isVariation: data.groupId === DYNAMIC_PRICING_FACTOR_GROUP_VARIATION_ID,
          });
        }
      }}
    >
      {!isSelected && <>{displayViewMode()}</>}

      {isSelected && <>{displayEditMode()}</>}

      <Handle
        type="target"
        position={Position.Left}
        isConnectable={false}
        className="dynamic-pricing-factor-node-handle"
      />
      {data.groupId !== DYNAMIC_PRICING_FACTOR_GROUP_VARIATION_ID &&
        (factorValid || !!data.connectedVariationId) &&
        data.outgoingBranchingLimit != null &&
        data.outgoingBranchingLimit > 0 && (
          <Handle
            type="source"
            position={Position.Right}
            onMouseDownCapture={e => {
              if (!isHandleConnectable) {
                e.stopPropagation();
              }
            }}
            className={classNames("dynamic-pricing-factor-node-handle", {
              "dynamic-pricing-factor-node-handle-disabled": !isHandleConnectable,
            })}
          />
        )}
    </div>
  );
}
