import { APIGet } from "api/protocols";
import { IProduct } from "pages/guest/models/product";

const apiVersion = "2022-09";
const adminType = "guest";
const baseProductUrl = "/" + apiVersion + "/" + adminType + "/product";

interface IGetProducts {
  sales_channel_type: string;
  facility_id: number;
  handle?: string;
  inventory?: boolean;
}

interface IGetProductsRes {
  data: IProduct[];
  message: string;
  status: number;
}

export async function GetProducts(params: IGetProducts, useGlobalLoader: boolean): Promise<IGetProductsRes> {
  return await APIGet(baseProductUrl, params, useGlobalLoader);
}
