import { APIGet, APIPost, APIPut, APIDelete, StatusCode, IAPIResponse } from "api/protocols";
const apiVersion = "2022-09";
const adminType = "facility-admin";

const baseUrl = "/" + apiVersion + "/" + adminType + "/client";

interface IPasswordResetCode {
  email: string;
  facility_id?: number;
}

export async function PostPasswordResetCode(params: IPasswordResetCode, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/admin/reset", params, useGlobalLoader);
}
