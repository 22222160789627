import React from "react";
import { useParams } from "react-router-dom";

import CustomerContextProvider from "./CustomerContext";
import Customer from "../main/Customer";

import "./CustomerProvider.scss";

/**
 * ContextProvider wrapper for the base customer element.
 *   - Obtains customer ID found within the URL, and passes to context.
 */
export default function CustomerProvider() {
  const { customerId }: any = useParams();

  return (
    <CustomerContextProvider customerId={customerId}>
      <div className="customer-provider-container">
        <Customer />
      </div>
    </CustomerContextProvider>
  );
}
