import React, { useState } from "react";

import Sheet from "components/sheet/Sheet";
import TextField from "components/form/textField/TextField";
import { useTranslation } from "react-i18next";

type CustomerNoteModalNewProps = {
  isVisible: boolean;
  onClose: () => void;
  onOk: (newNote: string) => void;
};

export default function CustomerNoteModalNew(props: CustomerNoteModalNewProps) {
  const [note, setNote] = useState<string>("");
  const { t, i18n } = useTranslation();
  function handleOk() {
    props.onOk(note);
    setNote("");
  }

  function handleClose() {
    setNote("");
    props.onClose();
  }

  return (
    <Sheet
      open={props.isVisible}
      size="small"
      height="flexible"
      closable
      title={t("elements.customer.customer_note_modal_new.001")}
      onOk={() => handleOk()}
      okDisabled={note.length < 2}
      cancelText={t("elements.customer.customer_note_modal_new.002")}
      okText={t("elements.customer.customer_note_modal_new.003")}
      onCancel={() => handleClose()}
    >
      <div>
        <TextField
          label={t("elements.customer.customer_note_modal_new.004")}
          id="booking-modal-notes-input"
          rows={2}
          value={note}
          onChange={({ target }: { target: EventTarget & HTMLTextAreaElement }) => setNote(target.value)}
        ></TextField>
      </div>
    </Sheet>
  );
}
