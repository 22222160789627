import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { TTeesheetPricingSheet } from "redux/reducers/models/teesheet";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const basePricingSheetUrl = "/" + apiVersion + "/" + adminType + "/tee-sheet/pricing-sheet";

interface IGetPricingSheet {
  id?: string;
  extended?: boolean;
}

interface IPostPricingSheet {
  title: string;
  day_of_week: string;
  start_time: string;
  end_time: string;
  course_id: number;
  application: string;
}

interface IPricingSheetRes {
  status: number;
  data: any;
}

interface IDuplicatePricingSheet {
  day_of_week?: string;
  pricing_sheet_ids: Array<number>;
}

interface IDeletePricingSheet {
  id: number;
}

// GET Cart
// Retrieve a list of customers
// memberships: boolean
export async function GetPricingSheet(params: IGetPricingSheet, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<TTeesheetPricingSheet[]>(basePricingSheetUrl, params, useGlobalLoader, token);
}

export async function PostPricingSheet(params: IPostPricingSheet, useGlobalLoader: boolean): Promise<IPricingSheetRes> {
  return await APIPost(basePricingSheetUrl, params, useGlobalLoader);
}

export async function PutPricingSheet(params: any, useGlobalLoader: boolean): Promise<IPricingSheetRes> {
  return await APIPut(basePricingSheetUrl, params, useGlobalLoader);
}

export async function PostPricingSheetProduct(params: any, useGlobalLoader: boolean): Promise<IPricingSheetRes> {
  return await APIPost(basePricingSheetUrl + "/product", params, useGlobalLoader);
}

export async function DeletePricingSheet(
  params: IDeletePricingSheet,
  useGlobalLoader: boolean,
): Promise<IPricingSheetRes> {
  return await APIDelete(basePricingSheetUrl, params, useGlobalLoader);
}

export async function PostDuplicatePricingSheet(
  params: IDuplicatePricingSheet,
  useGlobalLoader: boolean,
): Promise<IPricingSheetRes> {
  return await APIPost(basePricingSheetUrl + "/duplicate", params, useGlobalLoader);
}
