import React, { useState } from "react";
import DropFilter from "components/dropdown/DropFilter";
import { DropFilterOptionals, PriceMatchState } from "components/dropdown/dropFilterTypes";
import { useTranslation } from "react-i18next";

type PriceMatchFilterProps = DropFilterOptionals & {
  /** When 'Apply' is clicked, return all price match id and value */
  handleApply: (filter: { id: string; value: string }[] | []) => void;
};

export default function PriceMatchDropFilter(props: PriceMatchFilterProps) {
  const { t, i18n } = useTranslation();
  const [priceMatchState, setPriceMatchState] = useState<PriceMatchState>({
    id: "total_greater_than",
    label: "Greater Than",
    value: "",
  });

  return (
    <DropFilter
      key="total_price_match"
      title={t("secure.facility.order.price_match_filter.001")}
      filterData={[priceMatchState]}
      filterLabelPropFromData="label"
      filterIdPropFromData="id"
      updateFilters={returnValue =>
        setPriceMatchState(prev => ({
          ...prev,
          ...(returnValue as PriceMatchState),
        }))
      }
      filterType="Price Match"
      applyFilters={filterReturn =>
        props.handleApply(filterReturn[0] ? [{ id: filterReturn[0]?.id, value: filterReturn[0]?.value }] : [])
      }
      leftAligned={props.leftAligned ?? false}
      rightAligned={props.rightAligned ?? false}
      dropdownSize={props.dropdownSize}
      darkMode={props.darkMode}
      save={props.save}
      historyKey={props.historyKey}
      disableDropdown={props.disableDropdown}
    />
  );
}
