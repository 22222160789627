import React, { useEffect, useState } from "react";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Sheet from "components/sheet/Sheet";
import Input from "components/form/input/Input";
import { Select as SelectNew, Select } from "components/select/";
import { useCustomerContext } from "../../context/CustomerContext";
import { PostAdjustAccount } from "api/rpc/2022-09/facilityAdmin/client/accounts";
import { StatusCode } from "api/protocols";
import { useAppDispatch } from "hooks/redux";
import { showError, showSuccess } from "redux/actions/ui";
type AccountAdjustProps = {
  open: boolean;
  onClose: () => void;
  onOk: () => Promise<void>;
};

interface IAdjustAccount {
  amount: string;
  note: string;
}

export default function HouseAccountAdjustModal(props: AccountAdjustProps) {
  const { open, onClose, onOk } = props;
  const { state, updateState } = useCustomerContext();
  const dispatch = useAppDispatch();
  const { Option } = SelectNew;

  const [adjustAccountState, setAdjustAccountState] = useState<IAdjustAccount>({
    amount: "0",
    note: "",
  });

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;
    setAdjustAccountState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleOnClose() {
    setAdjustAccountState(prevState => ({
      ...prevState,
      amount: "0",
      note: "",
    }));
    void onClose();
  }

  async function adjustAccount() {
    const res = await PostAdjustAccount(
      {
        account_id: state?.selectedHouseAccount?.id,
        amount: Number(adjustAccountState?.amount),
        note: adjustAccountState?.note,
      },
      true,
    );
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error adjusting account balance"));
      return;
    }
    dispatch(showSuccess("Successfully adjusted account balance"));
    void handleOnClose();
    await onOk();
  }

  const disableOK = adjustAccountState.amount === "" || isNaN(Number(adjustAccountState.amount));

  return (
    <Sheet
      open={open}
      size="small"
      height="flexible"
      closable
      title={"Adjust Account Balance"}
      onCancel={handleOnClose}
      onOk={adjustAccount}
      cancelText={"Cancel"}
      okText={"Adjust"}
      overflow
      okDisabled={disableOK}
    >
      <Form>
        <FormLayout>
          <FormLayout.Group>
            <Input
              label="Amount"
              type="number"
              id="amount"
              value={adjustAccountState.amount}
              onChange={handleInputChange}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <Input label="Note" id="note" value={adjustAccountState.note} onChange={handleInputChange} />
          </FormLayout.Group>
        </FormLayout>
      </Form>
    </Sheet>
  );
}
