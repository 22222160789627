import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { StatusCode } from "api/protocols";
import { GetTournament } from "api/rpc/2024-04/clientAdmin/tournament/tournament";

import { ITournament } from "redux/reducers/models/tournament";

import { convertTime } from "helpers/Helpers";
import { THIS_YEAR } from "components/form/calendarField/helpers";

import Page from "components/page/Page";
import Card from "components/card/Card";
import YearDropFilter from "components/filters/YearDropFilter";
import FacilityDropFilter from "components/filters/FacilityDropFilter";
import Spin from "components/spin/spin";
import axios, { CancelToken } from "axios";
import Search from "components/search/Search";
import { useAppSelector } from "hooks/redux";
import { useTranslation } from "react-i18next";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

const TABLE_LIMIT = 50;
export default function Tournaments() {
  const history = useHistory();
  const { t } = useTranslation();

  const { clientFacilityStore } = useAppSelector(store => store);

  const [tournaments, setTournaments] = useState<ITournament[]>(undefined);
  const [facilityFiltersLoaded, setFacilityFiltersLoaded] = useState(false);

  // Starting filters for the Leagues API call.
  const [filterState, setFilterState] = useState({
    year: THIS_YEAR,
    facility_id: null,
    search: "",
    offset: 0,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (facilityFiltersLoaded) {
      void loadTournaments(source.token);
    }

    return () => {
      source.cancel();
    };
  }, [filterState, facilityFiltersLoaded]);

  async function loadTournaments(token: CancelToken) {
    // Allow UI Spinner
    if (tournaments !== undefined) {
      setTournaments(undefined);
    }

    const res = await GetTournament({ ...filterState, limit: TABLE_LIMIT }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      setTournaments([]);
      return;
    }

    setTournaments(res.data);
  }

  function handleTableNavigation(direction: "prev" | "next") {
    switch (direction) {
      case "prev": {
        setFilterState(prev => ({ ...prev, offset: filterState.offset - TABLE_LIMIT }));
        return;
      }
      case "next": {
        setFilterState(prev => ({ ...prev, offset: filterState.offset + TABLE_LIMIT }));
        return;
      }
      default:
        return;
    }
  }

  const primaryAction = {
    content: "New Tournament", // TODO: Translation
    action: () => history.push("/admin/events-tournament/new"),
  };

  return (
    <Page title="Tournaments" /* TODO: Translation */ narrow primaryAction={primaryAction}>
      <div className="flex flex-row flex-wrap gap-4 pb-4">
        <YearDropFilter
          handleApply={year => setFilterState({ ...filterState, year: year })}
          defaultCheckboxes={[filterState.year]}
          disableDropdown={!facilityFiltersLoaded}
          leftAligned
        />

        <FacilityDropFilter
          dataLoaded={() => setFacilityFiltersLoaded(true)}
          handleApply={facility_ids => setFilterState({ ...filterState, facility_id: facility_ids[0] })}
          defaultCheckboxes={[filterState.facility_id]}
          disableDropdown={!facilityFiltersLoaded}
          restrain
        />
      </div>
      <div style={{ paddingBottom: "1.5rem" }}>
        <Search
          historyKey={"client-tournaments-search"}
          searchCallback={searchValue => setFilterState(prev => ({ ...prev, search: searchValue }))}
        />
      </div>

      <DataTable
        columns={[
          { label: "Name", width: "20%" }, // TODO: Translation
          { label: "Facility Name", width: "20%" }, // TODO: Translation
          { label: "Date", width: "30%" }, // TODO: Translation
          { label: "Players", width: "20%" }, // TODO: Translation
        ]}
        footer={{
          tableLimit: TABLE_LIMIT,
          tableOffset: filterState.offset,
          handleTableOffset: direction => handleTableNavigation(direction),
          disableNextOffset: !tournaments || tournaments.length <= TABLE_LIMIT,
        }}
        loading={tournaments === undefined}
      >
        {tournaments?.map(tournament => {
          const facility = clientFacilityStore?.facilities?.filter(
            facility => facility.id === tournament.facility_id,
          );
          return (
            <tr
              key={tournament.id}
              className="clickable"
              onClick={() => history.push("/admin/tournament/" + String(tournament.id) + "/home")}
            >
              <td>{tournament.name}</td>
              <td>{facility[0].long_name}</td>
              <td>
                <span>{moment.utc(tournament.date).local(true).format("MMM DD, YYYY")} at </span>
                <span>{convertTime(tournament.start_time)}</span>
              </td>
              <td>
                <span>{tournament.total_participant_count}</span>
                {tournament.capacity ? <span>{"/" + String(tournament.capacity)}</span> : null}
                <span> players</span>
              </td>
            </tr>
          );
        })}
      </DataTable>
    </Page>
  );
}
