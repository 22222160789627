import React, { useEffect, useState } from "react";
import { GetLeagueRegistrationFee } from "api/rpc/guest/league";
import { useHistory, useParams } from "react-router";
import { StatusCode } from "api/protocols";
import { ILeague, ILeagueRegistrationFee } from "redux/reducers/models/league";
import LeagueHeader from "../LeagueHeader/LeagueHeader";
import "./LeagueRegister.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { IUIActions } from "redux/actions/ui";
import { ICartState } from "redux/reducers/customer/cart";
import { ICartActions } from "redux/actions/customer/cart";
import { ICart } from "redux/reducers/customer/models/cart";
import OrderSummary from "components/OrderSummary/OrderSummary";
import Callout from "components/callout/Callout";
import Page from "components/page/Page";
import { useTranslation } from "react-i18next";
import { ILeagueRegistrationState, ISelectedLeagueRegistrationFee } from "redux/reducers/customer/leagueRegistration";
import { ILeagueRegistrationActions } from "redux/actions/customer/leagueRegistration";
import { IFacility } from "pages/guest/models/facility";
import { cloneDeep } from "lodash";
import LeagueTitle from "../LeagueTitle/LeagueTitle";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { TABLET_WIDTH } from "helpers/ScreenSizes";
import Card from "components/card/Card";
import { ButtonNew as Button } from "components/buttonNew";
import Markdown from "components/markdown/Markdown";

interface ILeagueRegisterParams {
  facilityShortName: string;
  leagueHandle: string;
}

interface ILeagueRegisterProps {
  cartStore: ICartState;
  cartActions: ICartActions;
  uiActions: IUIActions;
  leagueRegistrationStore: ILeagueRegistrationState;
  leagueRegistrationActions: ILeagueRegistrationActions;
}

interface ILeagueRegisterState {
  fullDescriptionVisible: boolean;
  descriptionOverflow: boolean;
  hoveredLeagueRegistrationFeeId: number;
  selectedLeagueRegistrationFees: ISelectedLeagueRegistrationFee[];
  leagueRegistrationFees: ILeagueRegistrationFee[];
}

export function getLeagueCartTokenName(facilityShortName: string, leagueHandle: string) {
  return `${facilityShortName}_${leagueHandle}_league_registration_cart_token`;
}

export function getLeagueCartToken(facilityShortName: string, leagueHandle: string) {
  return localStorage.getItem(getLeagueCartTokenName(facilityShortName, leagueHandle));
}

type LeagueRegistrationWarning = "closed" | "will-open" | "closed-previously" | "no-capacity" | "none";

type LeagueRegistrationAmountWarning = "too-few" | "too-many" | "none";

export function determineLeagueRegistrationWarning(league: ILeague): LeagueRegistrationWarning {
  if (!league?.registration_open) {
    return "closed";
  }

  const currentDate = new Date();
  const openDate = !league.registration_open_date ? null : new Date(league.registration_open_date + "Z");
  const closeDate = !league.registration_close_date ? null : new Date(league.registration_close_date + "Z");

  if (openDate && openDate.getTime() > currentDate.getTime()) {
    return "will-open";
  } else if (closeDate && closeDate.getTime() <= currentDate.getTime()) {
    return "closed-previously";
  } else if (league.capacity_remaining <= 0) {
    return "no-capacity";
  }

  return "none";
}

export default function LeagueRegister(props: ILeagueRegisterProps) {
  const { facilityShortName, leagueHandle } = useParams<ILeagueRegisterParams>();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { cartStore, cartActions, uiActions, leagueRegistrationStore, leagueRegistrationActions } = props;

  const windowSize = useWindowSize();

  const [leagueRegisterState, setLeagueRegisterState] = useState<ILeagueRegisterState>({
    fullDescriptionVisible: false,
    descriptionOverflow: false,
    hoveredLeagueRegistrationFeeId: undefined,
    selectedLeagueRegistrationFees: [],
    leagueRegistrationFees: [],
  });

  useEffect(() => {
    void loadLeagueRegister();
  }, []);

  useEffect(() => {
    void checkDescriptionOverflow();
  }, [leagueRegistrationStore.league]);

  async function loadLeagueRegister() {
    let facility: IFacility = undefined;

    uiActions.enqueue();

    try {
      const getFacilityPromise: Promise<IFacility> = leagueRegistrationStore.facility
        ? Promise.resolve(leagueRegistrationStore.facility)
        : (leagueRegistrationActions.loadFacility(facilityShortName, true) as unknown as Promise<IFacility>);

      facility = await getFacilityPromise;
    } catch {
      uiActions.showError(t("guest.league.league_registration.league_register.001"));
      uiActions.dequeue();
      return;
    }

    const facilityId = facility.id;

    let league: ILeague = undefined;

    try {
      const getLeaguePromise: Promise<ILeague> = leagueRegistrationStore.league
        ? Promise.resolve(leagueRegistrationStore.league)
        : (leagueRegistrationActions.loadLeague(facilityId, leagueHandle, true) as unknown as Promise<ILeague>);

      league = await getLeaguePromise;
    } catch {
      uiActions.showError(t("guest.league.league_registration.league_register.002"));
      uiActions.dequeue();
      return;
    }

    if (!league) {
      uiActions.showError("League could not be found");
      uiActions.dequeue();
      return;
    }

    const getLeagueRegistrationFeeResponse = await GetLeagueRegistrationFee(
      { facility_id: facilityId, league_id: league.id },
      true,
    );

    if (getLeagueRegistrationFeeResponse.status !== StatusCode.OK) {
      uiActions.showError(t("guest.league.league_registration.league_register.003"));
      uiActions.dequeue();
      return;
    }

    setLeagueRegisterState(prev => ({
      ...prev,
      leagueRegistrationFees: getLeagueRegistrationFeeResponse.data,
    }));

    let cart: ICart = undefined;

    if (getLeagueCartToken(facilityShortName, leagueHandle)) {
      try {
        const getCartPromise: Promise<ICart> = cartStore?.isLoaded
          ? Promise.resolve(cartStore?.cart)
          : (cartActions.loadCart(
              {
                facility_id: facilityId,
                tokenName: getLeagueCartTokenName(facilityShortName, leagueHandle),
                token: getLeagueCartToken(facilityShortName, leagueHandle),
              },
              true,
            ) as unknown as Promise<ICart>);

        cart = await getCartPromise;
      } catch {
        uiActions.showError(t("guest.league.league_registration.league_register.004"));
        uiActions.dequeue();
        return;
      }
    }

    if (cart?.status === "complete") {
      cartActions.cartClear({ tokenName: getLeagueCartTokenName(facilityShortName, leagueHandle) });
    }

    uiActions.dequeue();
  }

  function calculateLeagueSpotsLeft(
    capacityRemaining: number,
    registrationFees: ISelectedLeagueRegistrationFee[],
  ): number {
    return registrationFees
      .filter(registrationFee => registrationFee.type === "registration_fee")
      .map(registrationFee => registrationFee.players * registrationFee.quantity)
      .reduce((prev, next) => prev - next, capacityRemaining);
  }

  function getLeagueSpotsLeft() {
    if (leagueRegistrationStore?.league?.capacity_remaining != null) {
      if (leagueRegisterState.selectedLeagueRegistrationFees?.length > 0) {
        return calculateLeagueSpotsLeft(
          leagueRegistrationStore.league.capacity_remaining,
          leagueRegisterState.selectedLeagueRegistrationFees,
        );
      } else if (cartStore.cart?.line_items?.length > 0) {
        const activeRegistrationFees: ISelectedLeagueRegistrationFee[] = [];

        for (const lineItem of cartStore.cart.line_items) {
          const activeRegistrationFee = leagueRegisterState.leagueRegistrationFees?.find(leagueRegistrationFee => {
            return leagueRegistrationFee.variant_id === lineItem.variant_id;
          });

          if (activeRegistrationFee) {
            activeRegistrationFees.push({
              ...activeRegistrationFee,
              quantity: lineItem.quantity,
            });
          }
        }

        return calculateLeagueSpotsLeft(leagueRegistrationStore.league.capacity_remaining, activeRegistrationFees);
      } else {
        return leagueRegistrationStore.league.capacity_remaining;
      }
    } else {
      return 0;
    }
  }

  const leagueSpotsLeft = getLeagueSpotsLeft();

  function getLeagueRegistrationFeePrice(leagueRegistrationFee: ILeagueRegistrationFee) {
    if (!leagueRegistrationFee) {
      return undefined;
    }

    const price = leagueRegistrationFee.price;

    const priceOverride = leagueRegistrationFee.variant?.inventory_item?.inventory_levels?.find(
      inventoryLevel => inventoryLevel?.facility_id === leagueRegistrationStore.facility?.id,
    )?.price_override;

    if (price) {
      return price;
    } else if (priceOverride) {
      return priceOverride;
    } else {
      return leagueRegistrationFee.variant?.price;
    }
  }

  function formatLeagueRegistrationFeePrice(leagueRegistrationFee: ILeagueRegistrationFee) {
    const price = getLeagueRegistrationFeePrice(leagueRegistrationFee);

    if (price !== undefined) {
      return "$" + String(price);
    } else {
      return "";
    }
  }

  function getTotalRegistrationFeePrice(): number {
    let total = 0;
    for (let i = 0; i < leagueRegisterState.selectedLeagueRegistrationFees.length; i++) {
      total +=
        getLeagueRegistrationFeePrice(leagueRegisterState.selectedLeagueRegistrationFees[i]) *
        leagueRegisterState.selectedLeagueRegistrationFees[i].quantity;
    }
    return total;
  }

  function hoverLeagueRegistrationFee(id: number, isDisabled: boolean) {
    if (isDisabled) {
      return;
    }

    setLeagueRegisterState(prev => ({
      ...prev,
      hoveredLeagueRegistrationFeeId: id,
    }));
  }

  function selectLeagueRegistrationFee(leagueRegistrationFee: ILeagueRegistrationFee, isDisabled: boolean) {
    if (isDisabled) {
      return;
    }

    const temp = cloneDeep(leagueRegisterState.selectedLeagueRegistrationFees);

    const feeIndex = temp.findIndex(fee => fee.id === leagueRegistrationFee.id);

    if (feeIndex >= 0) {
      temp.splice(feeIndex, 1);
    } else {
      temp.push({ ...leagueRegistrationFee, quantity: 1 });
    }

    setLeagueRegisterState(prev => ({
      ...prev,
      selectedLeagueRegistrationFees: temp,
    }));
  }

  function navigateToCheckout() {
    history.push(`/league/${facilityShortName}/${leagueHandle}/register/checkout`);
  }

  function determineRegistrationAmountWarning(): LeagueRegistrationAmountWarning {
    const leagueSpotsUsed = (leagueRegistrationStore.league?.capacity_remaining ?? 0) - (leagueSpotsLeft ?? 0);
    const minLeagueSpots = leagueRegistrationStore.league?.minimum_registration_amount;
    const maxLeagueSpots = leagueRegistrationStore.league?.maximum_registration_amount;

    if (minLeagueSpots != null && leagueSpotsUsed < minLeagueSpots) {
      return "too-few";
    } else if (maxLeagueSpots != null && leagueSpotsUsed > maxLeagueSpots) {
      return "too-many";
    } else {
      return "none";
    }
  }

  const leagueRegistrationAmountWarning = determineRegistrationAmountWarning();

  function renderRegistrationAmountWarningCallout() {
    const minRegistrationAmount = leagueRegistrationStore.league?.minimum_registration_amount;
    const maxRegistrationAmount = leagueRegistrationStore.league?.maximum_registration_amount;

    switch (leagueRegistrationAmountWarning) {
      case "too-few":
        return (
          <Callout
            type="warning"
            title="Too few players"
            content={`You must register at least ${minRegistrationAmount} ${
              minRegistrationAmount === 1 ? "player" : "players"
            }`}
          />
        );
      case "too-many":
        return (
          <Callout
            type="warning"
            title="Too many players"
            content={`You must register at most ${maxRegistrationAmount} ${
              maxRegistrationAmount === 1 ? "player" : "players"
            }`}
          />
        );
      default:
        return <></>;
    }
  }

  function disabledGoToCheckout() {
    return (
      ((!cartStore?.cart?.line_items || cartStore?.cart?.line_items?.length === 0) &&
        leagueRegisterState.selectedLeagueRegistrationFees.length <= 0) ||
      leagueSpotsLeft < 0 ||
      leagueRegistrationAmountWarning !== "none"
    );
  }

  async function goToCheckout() {
    if (disabledGoToCheckout()) {
      return;
    } else if (
      cartStore?.cart?.line_items?.length > 0 &&
      (!leagueRegisterState.selectedLeagueRegistrationFees ||
        leagueRegisterState.selectedLeagueRegistrationFees.length === 0)
    ) {
      const updatedActiveRegistrationFees: ISelectedLeagueRegistrationFee[] = [];

      for (const lineItem of cartStore.cart.line_items) {
        const activeRegistrationFee = leagueRegisterState.leagueRegistrationFees?.find(leagueRegistrationFee => {
          return leagueRegistrationFee.variant_id === lineItem.variant_id;
        });

        if (!activeRegistrationFee) {
          uiActions.showError(`${lineItem.product_title} is unavailable`);
          return;
        }

        updatedActiveRegistrationFees.push({
          ...activeRegistrationFee,
          quantity: lineItem.quantity,
        });
      }

      leagueRegistrationActions.updateActiveRegistrationFees(updatedActiveRegistrationFees);
      navigateToCheckout();
      return;
    }

    uiActions.enqueue();

    let cart: ICart = undefined;

    if (cartStore.isLoaded) {
      cart = cartStore?.cart;
    } else {
      try {
        const postCartPromise: Promise<ICart> = cartActions.postCart(
          {
            tokenName: getLeagueCartTokenName(facilityShortName, leagueHandle),
            facility_id: leagueRegistrationStore.facility?.id,
          },
          false,
        ) as unknown as Promise<ICart>;

        cart = await postCartPromise;
      } catch {
        uiActions.dequeue();
        uiActions.showError(t("guest.league.league_registration.league_register.005"));
        return;
      }
    }

    if (cart?.line_items?.length > 0) {
      const lineItemCount = cart?.line_items?.length;
      for (let i = 0; i < lineItemCount; i++) {
        try {
          const deleteLineItemPromise: Promise<ICart> = cartActions.deleteLineItem(
            cart?.line_items?.[0].id,
            cart?.token,
            false,
          ) as unknown as Promise<ICart>;

          cart = await deleteLineItemPromise;
        } catch {
          uiActions.dequeue();
          uiActions.showError(t("guest.league.league_registration.league_register.006"));
          return;
        }
      }
    }

    for (let i = 0; i < leagueRegisterState.selectedLeagueRegistrationFees.length; i++) {
      try {
        const postLineItemPromise: Promise<ICart> = cartActions.postLineItem(
          cart?.token,
          leagueRegisterState.selectedLeagueRegistrationFees[i]?.variant_id,
          leagueRegisterState.selectedLeagueRegistrationFees[i]?.quantity,
          false,
          undefined,
          leagueRegisterState.selectedLeagueRegistrationFees[i]?.id,
        ) as unknown as Promise<ICart>;

        cart = await postLineItemPromise;
      } catch {
        uiActions.dequeue();
        uiActions.showError(t("guest.league.league_registration.league_register.007"));
        return;
      }
    }

    if (cart) {
      uiActions.dequeue();
      leagueRegistrationActions.updateActiveRegistrationFees(leagueRegisterState.selectedLeagueRegistrationFees);
      navigateToCheckout();
      return;
    }
  }

  function handleChangeQuantity(feeIndex: number, type: "add" | "subtract") {
    const selectedFees = leagueRegisterState.selectedLeagueRegistrationFees;

    if (type === "add") {
      selectedFees[feeIndex].quantity++;
    } else {
      selectedFees[feeIndex].quantity--;
    }

    setLeagueRegisterState(prev => ({
      ...prev,
      selectedLeagueRegistrationFees: selectedFees,
    }));
  }

  function renderWarnings() {
    const leagueWarning = determineLeagueRegistrationWarning(leagueRegistrationStore.league);

    const localeStringOptions: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };

    const openDateFormatString = new Date(leagueRegistrationStore.league.registration_open_date + "Z").toLocaleString(
      "en-CA",
      localeStringOptions,
    );

    const closeDateFormatString = new Date(leagueRegistrationStore.league.registration_close_date + "Z").toLocaleString(
      "en-CA",
      localeStringOptions,
    );

    switch (leagueWarning) {
      case "closed":
        return (
          <Callout
            type="warning"
            title={t("guest.league.league_registration.league_register.009")}
            content={t("guest.league.league_registration.league_register.008")}
          />
        );
      case "will-open":
        return (
          <Callout
            type="warning"
            title={"Registration is currently closed"}
            content={`Registration for the league will open on ${openDateFormatString}`}
          />
        );
      case "closed-previously":
        return (
          <Callout
            type="warning"
            title={"Registration is currently closed"}
            content={`Registration for the league ended on ${closeDateFormatString}`}
          />
        );
      case "no-capacity":
        return (
          <Callout
            type="warning"
            title={t("guest.league.league_registration.league_register.011")}
            content={t("guest.league.league_registration.league_register.010")}
          />
        );
      case "none":
      default:
        return <></>;
    }
  }

  function checkDescriptionOverflow() {
    const element = document.getElementById("description_text");

    if (!element) {
      return;
    }

    setLeagueRegisterState(prevState => ({
      ...prevState,
      descriptionOverflow: element.clientHeight < element.scrollHeight,
    }));
  }

  return (
    <>
      <LeagueHeader facility={leagueRegistrationStore.facility} league={leagueRegistrationStore.league} />
      {leagueRegistrationStore.facility && leagueRegistrationStore.league && (
        <Page>
          {renderWarnings()}
          {determineLeagueRegistrationWarning(leagueRegistrationStore.league) === "none" && (
            <div className="league-register">
              <div className="league-register-main">
                {leagueRegistrationStore.league?.description && (
                  <Card>
                    <Card.Section>
                      <div
                        id="description_text"
                        className={
                          leagueRegisterState.fullDescriptionVisible
                            ? "league-register-description-full"
                            : "league-register-description"
                        }
                      >
                        <Markdown markdownText={leagueRegistrationStore.league.description} />
                      </div>
                      {leagueRegisterState.descriptionOverflow && (
                        <div
                          className="league-register-description-read-more"
                          onClick={() =>
                            setLeagueRegisterState(prevState => ({
                              ...prevState,
                              fullDescriptionVisible: !prevState.fullDescriptionVisible,
                            }))
                          }
                        >
                          {leagueRegisterState.fullDescriptionVisible ? "Show Less" : "Read More"}
                        </div>
                      )}
                    </Card.Section>
                  </Card>
                )}
                <div className="league-register-registration-fees-heading mt-2">{"Registration Fees"}</div>
                {leagueRegisterState.leagueRegistrationFees
                  ?.filter(fee => fee.type === "registration_fee")
                  ?.map(leagueRegistrationFee => {
                    const id = leagueRegistrationFee.id;
                    const isHovered = leagueRegisterState.hoveredLeagueRegistrationFeeId === id;
                    const isSelected =
                      leagueRegisterState.selectedLeagueRegistrationFees.findIndex(fee => fee.id === id) >= 0;
                    const isDisabled = leagueRegistrationFee.players > leagueSpotsLeft && !isSelected;

                    return (
                      <div
                        key={id}
                        className={classNames("league-register-registration-fee", {
                          "league-register-registration-fee-selected": isSelected,
                          "league-register-registration-fee-disabled": isDisabled,
                        })}
                        onMouseOver={() => hoverLeagueRegistrationFee(id, isDisabled)}
                        onMouseLeave={() => hoverLeagueRegistrationFee(undefined, isDisabled)}
                        onClick={() => selectLeagueRegistrationFee(leagueRegistrationFee, isDisabled)}
                      >
                        <div
                          className={classNames("league-register-registration-fee-header", {
                            "league-register-registration-fee-header-selected": isSelected,
                          })}
                        >
                          <div>
                            <div>{leagueRegistrationFee.product_title}</div>
                            {leagueRegistrationFee.product_title !== leagueRegistrationFee.variant_title && (
                              <div className="league-registration-registration-fee-header-subtitle">
                                {leagueRegistrationFee.variant_title}
                              </div>
                            )}
                          </div>

                          <FontAwesomeIcon
                            className={classNames({
                              "league-register-registration-fee-header-selection-default":
                                !isSelected && !isHovered && !isDisabled,
                              "league-register-registration-fee-header-selection-disabled": isDisabled,
                              "league-register-registration-fee-header-selection-hovered": !isSelected && isHovered,
                              "league-register-registration-fee-header-selection-selected": isSelected,
                            })}
                            icon={[isSelected ? "fas" : "far", isSelected ? "circle-check" : "circle"]}
                          />
                        </div>
                        <hr
                          className={classNames("league-register-registration-fee-dividing-line", {
                            "league-register-registration-fee-dividing-line-highlighted": isHovered || isSelected,
                          })}
                        />
                        <div className="league-register-registration-fee-main">
                          <div className="flex justify-between">
                            <div>
                              <div className="league-register-registration-fee-main-price">
                                {formatLeagueRegistrationFeePrice(leagueRegistrationFee)}
                              </div>
                              <div className="league-register-registration-fee-main-description">
                                This registration includes {leagueRegistrationFee.players}{" "}
                                {leagueRegistrationFee.players === 1 ? "player" : "players"}
                              </div>
                              <div className="league-register-registration-fee-main-description">
                                {leagueRegistrationFee.description ?? ""}
                              </div>
                            </div>
                            {leagueRegisterState.selectedLeagueRegistrationFees.findIndex(
                              fee => fee.id === leagueRegistrationFee.id,
                            ) >= 0 && (
                              <div className="flex justify-between" onClick={e => e.stopPropagation()}>
                                <div>
                                  <Button
                                    onClick={() =>
                                      handleChangeQuantity(
                                        leagueRegisterState.selectedLeagueRegistrationFees.findIndex(
                                          fee => fee.id === leagueRegistrationFee.id,
                                        ),
                                        "subtract",
                                      )
                                    }
                                    type={"tertiary"}
                                    size="large"
                                    disabled={
                                      leagueRegisterState.selectedLeagueRegistrationFees.find(
                                        fee => fee.id === leagueRegistrationFee.id,
                                      ).quantity <= 1
                                    }
                                  >
                                    <FontAwesomeIcon icon={["fas", "minus"]} />
                                  </Button>
                                </div>
                                <div className="league-register-registration-fee-main-quantity">
                                  {
                                    leagueRegisterState.selectedLeagueRegistrationFees.find(
                                      fee => fee.id === leagueRegistrationFee.id,
                                    ).quantity
                                  }
                                </div>
                                <div>
                                  <Button
                                    onClick={() =>
                                      handleChangeQuantity(
                                        leagueRegisterState.selectedLeagueRegistrationFees.findIndex(
                                          fee => fee.id === leagueRegistrationFee.id,
                                        ),
                                        "add",
                                      )
                                    }
                                    disabled={leagueRegistrationFee.players > leagueSpotsLeft}
                                    type={"tertiary"}
                                    size="large"
                                  >
                                    <FontAwesomeIcon icon={["fas", "plus"]} />
                                  </Button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}

                {leagueRegisterState.leagueRegistrationFees?.filter(fee => fee.type === "add_on").length > 0 && (
                  <h1 className="league-register-registration-fees-heading">{"Add Ons"}</h1>
                )}
                {leagueRegisterState.leagueRegistrationFees
                  ?.filter(fee => fee.type === "add_on")
                  ?.map(leagueRegistrationFee => {
                    const id = leagueRegistrationFee.id;
                    const isHovered = leagueRegisterState.hoveredLeagueRegistrationFeeId === id;
                    const isSelected =
                      leagueRegisterState.selectedLeagueRegistrationFees.findIndex(fee => fee.id === id) >= 0;

                    return (
                      <div
                        key={id}
                        className={classNames("league-register-registration-fee", {
                          "league-register-registration-fee-selected": isSelected,
                        })}
                        onMouseOver={() => hoverLeagueRegistrationFee(id, false)}
                        onMouseLeave={() => hoverLeagueRegistrationFee(undefined, false)}
                        onClick={() => selectLeagueRegistrationFee(leagueRegistrationFee, false)}
                      >
                        <div
                          className={classNames("league-register-registration-fee-header", {
                            "league-register-registration-fee-header-selected": isSelected,
                          })}
                        >
                          <div>
                            <div>{leagueRegistrationFee.product_title}</div>
                            {leagueRegistrationFee.product_title !== leagueRegistrationFee.variant_title && (
                              <div className="league-register-registration-fee-header-subtitle">
                                {leagueRegistrationFee.variant_title}
                              </div>
                            )}
                          </div>

                          <FontAwesomeIcon
                            className={classNames({
                              "league-register-registration-fee-header-selection-default": !isSelected && !isHovered,
                              "league-register-registration-fee-header-selection-hovered": !isSelected && isHovered,
                              "league-register-registration-fee-header-selection-selected": isSelected,
                            })}
                            icon={[isSelected ? "fas" : "far", isSelected ? "circle-check" : "circle"]}
                          />
                        </div>
                        <hr
                          className={classNames("league-register-registration-fee-dividing-line", {
                            "league-register-registration-fee-dividing-line-highlighted": isHovered || isSelected,
                          })}
                        />
                        <div className="league-register-registration-fee-main">
                          <div className="flex justify-between">
                            <div>
                              <div className="league-register-registration-fee-main-price">
                                {formatLeagueRegistrationFeePrice(leagueRegistrationFee)}
                              </div>
                              <div className="league-register-registration-fee-main-description">
                                {leagueRegistrationFee.description ?? ""}
                              </div>
                            </div>
                            {leagueRegisterState.selectedLeagueRegistrationFees.findIndex(
                              fee => fee.id === leagueRegistrationFee.id,
                            ) >= 0 && (
                              <div className="flex justify-between" onClick={e => e.stopPropagation()}>
                                <div>
                                  <Button
                                    onClick={() =>
                                      handleChangeQuantity(
                                        leagueRegisterState.selectedLeagueRegistrationFees.findIndex(
                                          fee => fee.id === leagueRegistrationFee.id,
                                        ),
                                        "subtract",
                                      )
                                    }
                                    type={"tertiary"}
                                    size="large"
                                    disabled={
                                      leagueRegisterState.selectedLeagueRegistrationFees.find(
                                        fee => fee.id === leagueRegistrationFee.id,
                                      ).quantity <= 1
                                    }
                                  >
                                    <FontAwesomeIcon icon={["fas", "minus"]} />
                                  </Button>
                                </div>
                                <div className="league-register-registration-fee-main-quantity">
                                  {
                                    leagueRegisterState.selectedLeagueRegistrationFees.find(
                                      fee => fee.id === leagueRegistrationFee.id,
                                    ).quantity
                                  }
                                </div>
                                <div>
                                  <Button
                                    onClick={() =>
                                      handleChangeQuantity(
                                        leagueRegisterState.selectedLeagueRegistrationFees.findIndex(
                                          fee => fee.id === leagueRegistrationFee.id,
                                        ),
                                        "add",
                                      )
                                    }
                                    type={"tertiary"}
                                    size="large"
                                  >
                                    <FontAwesomeIcon icon={["fas", "plus"]} />
                                  </Button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="league-register-order-summary">
                {windowSize.width <= TABLET_WIDTH && (
                  <div className="league-register-order-summary-callout-tablet">
                    {renderRegistrationAmountWarningCallout()}
                  </div>
                )}
                {leagueRegisterState.selectedLeagueRegistrationFees?.length > 0 ? (
                  <OrderSummary
                    headerBadge={{
                      type: "success",
                      children: (
                        <div>
                          {leagueSpotsLeft} {leagueSpotsLeft === 1 ? "Spot" : "Spots"} Left
                        </div>
                      ),
                    }}
                    subtotal={getTotalRegistrationFeePrice()}
                    taxLines={[]}
                    discount={0}
                    total={getTotalRegistrationFeePrice()}
                    displayLineItemsWithoutToggle={true}
                    lineItems={leagueRegisterState.selectedLeagueRegistrationFees.map(fee => {
                      return {
                        src: fee.product?.default_image?.source,
                        productTitle: fee.product_title,
                        variantTitle: fee.variant_title,
                        quantity: fee.quantity,
                        price: getLeagueRegistrationFeePrice(fee) * fee.quantity,
                      };
                    })}
                  >
                    <div className="league-register-order-summary-go-to-checkout-section">
                      <button
                        className="league-register-order-summary-go-to-checkout"
                        onClick={goToCheckout}
                        disabled={disabledGoToCheckout()}
                      >
                        Checkout
                      </button>
                      {windowSize.width > TABLET_WIDTH && renderRegistrationAmountWarningCallout()}
                    </div>
                  </OrderSummary>
                ) : (
                  <OrderSummary
                    headerBadge={{
                      type: "success",
                      children: (
                        <div>
                          {leagueSpotsLeft} {leagueSpotsLeft === 1 ? "Spot" : "Spots"} Left
                        </div>
                      ),
                    }}
                    headerAction={{
                      label: "Clear Cart",
                      action: () =>
                        cartActions.cartClear({ tokenName: getLeagueCartTokenName(facilityShortName, leagueHandle) }),
                    }}
                    subtotal={cartStore?.cart?.subtotal_price ?? 0}
                    taxLines={cartStore?.cart?.tax_lines ?? []}
                    discount={cartStore?.cart?.total_discount ?? 0}
                    total={cartStore?.cart?.total_price ?? 0}
                    displayLineItemsWithoutToggle={true}
                    lineItems={cartStore?.cart?.line_items?.map(lineItem => {
                      return {
                        src: lineItem.product_default_image?.source,
                        productTitle: lineItem.product_title,
                        variantTitle: lineItem.variant_title,
                        quantity: lineItem.quantity,
                        price: lineItem.subtotal_price,
                      };
                    })}
                  >
                    <div className="league-register-order-summary-go-to-checkout-section">
                      <button
                        className="league-register-order-summary-go-to-checkout"
                        onClick={goToCheckout}
                        disabled={disabledGoToCheckout()}
                      >
                        Checkout
                      </button>
                      {windowSize.width > TABLET_WIDTH && renderRegistrationAmountWarningCallout()}
                    </div>
                  </OrderSummary>
                )}
              </div>
            </div>
          )}
        </Page>
      )}
    </>
  );
}
