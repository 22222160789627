import React from "react";
import classNames from "classnames";
import Icon from "components/icon/Icon";

import "./badge.scss";

export interface IBadgeProps {
  className?: string;
  type?: "primary" | "success" | "warning" | "error" | "gray" | "public";
  size?: "xsmall" | "small" | "medium" | "large";
  iconLeft?: any;
  iconSize?: string;
  iconRight?: any;
  icon?: any;
  iconStyle?: string;
  children?: any;
  outline?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  colour?: string;
}

export const Badge: React.FC<IBadgeProps> = props => {
  const { className, type, size, iconLeft, iconRight, icon, iconStyle, outline, children, ...rest } = props;

  return (
    <span
      className={classNames(`ui-badge ${className ?? ""}`, {
        "ui-badge_primary": props.type === "primary",
        "ui-badge_success": props.type === "success",
        "ui-badge_warning": props.type === "warning",
        "ui-badge_error": props.type === "error",
        "ui-badge_gray": props.type === "gray",
        "ui-badge_public": props.type === "public",

        "ui-badge_xsmall": props.size === "xsmall",
        "ui-badge_small": props.size === "small",
        "ui-badge_medium": props.size === "medium",
        "ui-badge_large": props.size === "large",

        "ui-badge_outline": props.outline,
      })}
      onClick={props.onClick}
      style={{ borderColor: props.colour, color: props.colour }}
    >
      {props.iconLeft ? (
        <span
          className={classNames("ui-badge_icon ui-badge_icon-left", {
            "ui-badge_icon-small": props.iconSize === "small",
            "ui-badge_icon-medium": props.iconSize === "medium",
            "ui-badge_icon-large": props.iconSize === "large",
          })}
        >
          {props.iconLeft}
        </span>
      ) : null}
      {children}
      {props.iconRight ? (
        <span className="ui-badge_icon ui-badge_icon-right">
          <Icon icon={props.icon} style="far" size="xsmall" />
        </span>
      ) : null}
    </span>
  );
};
