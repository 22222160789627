import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { capitalize, formatDate, valueToString } from "helpers/Helpers";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { showError, showSuccess } from "redux/actions/ui";

import { StatusCode } from "api/protocols";
import {
  GetHouseAccount,
  GetScheduledPayment,
  GetAccountTransactions,
  GetAccountStatements,
  CancelScheduledPayment,
} from "api/rpc/2022-09/clientAdmin/houseAccount/houseAccount";
import { useCustomerClientContext } from "../../context/CustomerClientContext";
import { Select } from "components/select/";
import { Badge } from "components/badge/Badge";
import Spin from "components/spin/spin";
import { LocaleCurrency } from "helpers/Locale";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import Tabs from "components/tabs/Tabs";
import { HouseAccountTransactionType, TransactionKind, HouseAccountStatementType } from "../../context/contextTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icon from "components/icon/Icon";
import { NavigationDropdownNew } from "components/navigationDropdownNew/NavigationDropdownNew";
import AccountScheduledPaymentModal from "./AccountScheduledPaymentModal";
import EditAccountScheduledPaymentModal from "./EditAccountScheduledPaymentModal";
import Popup from "components/popup/Popup";

export default function CustomerHouseAccounts() {
  const { Option } = Select;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { state, updateState } = useCustomerClientContext();

  const [selectedTableTab, setSelectedTableTab] = useState<number>(0);

  const [scheduledPaymentModalVisible, setScheduledPaymentModalVisible] = useState<boolean>(false);

  const [editScheduledPaymentModalVisible, setEditScheduledPaymentModalVisible] = useState<boolean>(false);

  const [cancelModal, setCancelModal] = useState({
    scheduledPaymentId: null,
    modalVisible: false,
  });

  const [editScheduledPayment, setEditScheduledPayment] = useState<any>(null);

  const tableTabs = [
    {
      id: "statements",
      content: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.044"),
    },
    {
      id: "transactions",
      content: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.050"),
    },
    {
      id: "scheduledPayments",
      content: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.057"),
    },
  ];

  useEffect(() => {
    void loadHouseAccounts();
  }, []);

  useEffect(() => {
    if (state.selectedHouseAccount) {
      void loadScheduledPayments();
      void loadAccountTransactions();
      void loadAccountStatements();
    }
  }, [state.selectedHouseAccount]);

  async function loadHouseAccounts() {
    const accountsRes = await GetHouseAccount({ customer_id: state.customer.id }, true);

    updateState({
      houseAccounts: accountsRes.data,
      selectedHouseAccount: accountsRes.data.length > 0 ? accountsRes.data[0] : [],
    });
  }

  async function loadScheduledPayments() {
    const paymentRes = await GetScheduledPayment({ account_id: state.selectedHouseAccount?.id }, true);
    updateState({ accountScheduledPayments: paymentRes?.data });
  }

  async function loadAccountTransactions() {
    const transactionRes = await GetAccountTransactions({ account_id: state.selectedHouseAccount?.id }, true);

    if (transactionRes.status !== StatusCode.OK) {
      dispatch(showError("Error getting account transactions"));
      return;
    }

    const transactions: any = [];

    for (let i = 0; i < transactionRes.data.length; i++) {
      if (transactionRes.data[i].authorization_id === null) {
        transactions.push(transactionRes.data[i]);
      }
    }

    updateState({ accountTransactions: transactions });
  }

  async function loadAccountStatements() {
    const statementRes = await GetAccountStatements({ account_id: state.selectedHouseAccount?.id }, true);
    updateState({ accountStatements: statementRes.data });
  }

  function handleHouseAccountChange(accountId: number) {
    if (accountId !== null) {
      const account = state.houseAccounts.find(account => {
        return account.id === accountId;
      });

      updateState({
        selectedHouseAccount: account,
      });
    }
  }

  function handleShowCancelScheduledPayment(id: number) {
    setCancelModal({ modalVisible: true, scheduledPaymentId: id });
  }

  async function cancelScheduledPayment() {
    const cancelRes = await CancelScheduledPayment({ id: cancelModal.scheduledPaymentId }, true);

    if (cancelRes.status !== StatusCode.OK) {
      dispatch(showError("Error cancelling payment"));
      return;
    }

    setCancelModal({ modalVisible: false, scheduledPaymentId: null });
    dispatch(showSuccess("Payment cancelled successfully"));
    void loadScheduledPayments();
  }

  return (
    <div>
      {state.houseAccounts ? (
        <>
          <div className="house-accounts-header">
            <div className="ui-customer_page-header house-accounts-title">
              {t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.060")}
            </div>
            <div className="house-accounts-actions mr-2">
              <NavigationDropdownNew
                rightAlign
                label={"Account Options"}
                sections={[
                  [
                    {
                      label: "Schedule Payment",
                      icon: "calendar-days",
                      onClick: () => setScheduledPaymentModalVisible(true),
                    },
                  ],
                ]}
              />
            </div>
          </div>
          <div className="house-accounts-divider"></div>
          <Select
            className="mb-4"
            defaultValue={state.selectedHouseAccount?.id}
            onChange={(id: number) => handleHouseAccountChange(id)}
          >
            {state.houseAccounts.map(account => {
              return (
                <Option key={account.id} value={account.id} name={account.title}>
                  {account?.closed ? (
                    <span>
                      {account.title}
                      &nbsp;
                      <Badge type="gray">Closed</Badge>
                    </span>
                  ) : (
                    <span>{account.title}</span>
                  )}
                </Option>
              );
            })}
          </Select>
          <div className="balance-credit-container">
            <div className="house-accounts_left">
              <div className="house-accounts_balance">
                <div className="currency-text">
                  <LocaleCurrency amount={state.selectedHouseAccount?.balance} />
                </div>
                <div>
                  <p className="left-text">{"Balance"}</p>
                </div>
              </div>
              <div className="house-accounts_credit-limit">
                <div className="currency-text">
                  <LocaleCurrency amount={state.selectedHouseAccount?.limit} />
                </div>
                <p className="left-text">{"Limit"}</p>
              </div>
            </div>
          </div>
          <Tabs tabs={tableTabs} selected={selectedTableTab} onSelect={setSelectedTableTab}>
            <div style={{ display: selectedTableTab === 0 ? "block" : "none", paddingTop: "8px" }}>
              <DataTable<HouseAccountStatementType>
                columns={[
                  { label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.006") },
                  { label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.007") },
                  { label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.008") },
                  { label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.009") },
                ]}
                // sort={(column, direction) => updateState({ accountStatements: sortColumn(state.accountStatements, column, direction) })}
                loading={!state.accountStatements}
              >
                {state.accountStatements?.map((statement, index) => {
                  return (
                    <tr key={statement.id}>
                      <td>{statement.id}</td>
                      <td>{moment(statement.start_date).utc().format("LL")}</td>
                      <td>{moment(statement.end_date).utc().format("LL")}</td>
                      <td>
                        <LocaleCurrency currency={"CAD"} amount={statement.current_balance} />
                      </td>
                    </tr>
                  );
                })}
              </DataTable>
            </div>
            <div style={{ display: selectedTableTab === 1 ? "block" : "none", paddingTop: "8px" }}>
              <DataTable<HouseAccountTransactionType>
                columns={[
                  { label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.067") },
                  { label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.068") },
                  { label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.010") },

                  { label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.069") },
                  { label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.070") },
                  { label: "" }, // 'Transaction Note'
                ]}
                loading={!state.accountTransactions}
              >
                {state.accountTransactions?.map((transaction, index: number) => {
                  return (
                    <tr key={transaction.id}>
                      <td>{moment(transaction?.processed_at).format("ll")}</td>

                      <td>{transaction?.facility?.long_name}</td>

                      <td>
                        <span>
                          <LocaleCurrency amount={transaction?.amount} currency={transaction?.currency} />{" "}
                          {transaction?.currency.toUpperCase()}
                        </span>
                      </td>
                      <td>
                        <p style={{ fontSize: "12px" }}>{valueToString(transaction?.kind)}</p>
                        <p>{transactionBadge(transaction?.kind)}</p>
                      </td>
                      <td>
                        <span>{valueToString(transaction?.payment_type) || ""}</span>
                      </td>
                      <td>
                        {transaction.note && (
                          <Icon
                            style="far"
                            icon="notes"
                            size="medium"
                            tooltip={{ title: "Note", description: transaction.note, position: "right" }}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </DataTable>
            </div>
            <div style={{ display: selectedTableTab === 2 ? "block" : "none", paddingTop: "8px" }}>
              <DataTable
                columns={[
                  { label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.062") },
                  { label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.063") },
                  { label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.064") },
                  { label: t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.065") },
                  { label: "" },
                  { label: "", width: "5%" },
                ]}
                loading={!state?.accountScheduledPayments}
              >
                {state.accountScheduledPayments?.map((payment, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{moment(payment?.process_date).format("ll")}</td>
                      <td>{<LocaleCurrency amount={payment?.amount} />}</td>
                      <td>{scheduledPaymentBadge(payment.status)}</td>
                      <td>{payment?.note}</td>
                      <td>
                        {payment?.charge_balance ? (
                          <Badge type="primary" size="small">
                            {t("secure.facility.customer.tabs.house_accounts.customer_house_accounts.066")}
                          </Badge>
                        ) : null}
                      </td>
                      <td>
                        {payment.status === "pending" && (
                          <NavigationDropdownNew
                            rightAlign
                            showPlainTextLabel
                            label={<FontAwesomeIcon icon={["far", "chevron-down"]} />}
                            sections={[
                              [
                                {
                                  label: "Edit",
                                  icon: "pen-to-square",
                                  onClick: () => {
                                    setEditScheduledPayment(payment);
                                    setEditScheduledPaymentModalVisible(true);
                                  },
                                },
                                {
                                  label: "Cancel",
                                  icon: "ban",
                                  onClick: () => handleShowCancelScheduledPayment(payment.id),
                                },
                              ],
                            ]}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </DataTable>
            </div>
          </Tabs>
        </>
      ) : (
        <div style={{ marginTop: "4em", pointerEvents: "none", textAlign: "center" }}>
          <span style={{ display: "inline-flex", transform: "scale(2)" }}>
            <Spin />
          </span>
        </div>
      )}

      <AccountScheduledPaymentModal
        open={scheduledPaymentModalVisible}
        onClose={() => setScheduledPaymentModalVisible(false)}
        onOk={loadScheduledPayments}
      />

      <EditAccountScheduledPaymentModal
        open={editScheduledPaymentModalVisible}
        onClose={() => setEditScheduledPaymentModalVisible(false)}
        onOk={loadScheduledPayments}
        scheduledPayment={editScheduledPayment}
      />

      <Popup
        open={cancelModal.modalVisible}
        type="warning"
        title={"Cancel Payment?"}
        description={"Are you sure you want to cancel this scheduled payment? This can not be undone"}
        onOk={() => cancelScheduledPayment()}
        okText={"Ok"}
        cancelText={"Cancel"}
        onCancel={() => setCancelModal({ modalVisible: false, scheduledPaymentId: null })}
      />
    </div>
  );
}

// Account Payments and Refunds decrease balance on account
const transactionBadge = (type: TransactionKind) => {
  switch (type) {
    case "account_payment":
    case "refund":
    case "credit_adjustment":
      return (
        <Badge type="success" iconLeft={<FontAwesomeIcon icon={["fas", "arrow-up-left"]} />}>
          Credit
        </Badge>
      );
    case "debit_adjustment":
    case "sale":
      return (
        <Badge type="error" iconLeft={<FontAwesomeIcon icon={["fas", "arrow-down-left"]} />}>
          Debit
        </Badge>
      );
    default:
      return <></>;
  }
};

const scheduledPaymentBadge = (status: string) => {
  switch (status) {
    case "complete":
      return <Badge type="success">{capitalize(status)}</Badge>;
    case "canceled":
      return <Badge type="error">{capitalize(status)}</Badge>;
    default:
      return <Badge type="gray">{capitalize(status)}</Badge>;
  }
};
