import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios, { CancelToken } from "axios";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { GetFloorPlans, IFloorPlan } from "api/rpc/facilityAdmin/tables/tables";

import { showError } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

export default function FloorPlans() {
  const history = useHistory();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [floorPlans, setFloorPlans] = useState<IFloorPlan[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadFloorPlans(source.token);
    return () => source.cancel();
  }, []);

  async function loadFloorPlans(token?: CancelToken) {
    if (floorPlans !== undefined) {
      setFloorPlans(undefined);
    }
    const res = await GetFloorPlans({}, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.floor_plan.floor_plans.floor_plans.001")));
    }

    setFloorPlans(res.status !== StatusCode.OK ? [] : res.data);
  }

  const primaryAction = {
    content: t("secure.facility.settings.floor_plan.floor_plans.floor_plans.004"),
    action: () => history.push("/admin/settings/floor-plan/new"),
  };

  return (
    <Page
      title={t("secure.facility.settings.floor_plan.floor_plans.floor_plans.002")}
      narrow
      primaryAction={primaryAction}
    >
      <DataTable
        columns={[{ label: t("secure.facility.settings.floor_plan.floor_plans.floor_plans.003") }]}
        loading={floorPlans === undefined}
      >
        {floorPlans?.map(plan => (
          <tr
            key={plan.id}
            className="clickable"
            onClick={() => history.push("/admin/settings/floor-plan/" + String(plan.id))}
          >
            <td>{plan.title}</td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
