import React from "react";
import { useTranslation } from "react-i18next";

import { ICustomer } from "redux/reducers/models/customer";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

import "elements/register/register.scss";

interface ICustomerTableProps {
  /** undefined = table loader */
  customers: ICustomer[];
  onClick: (customer: ICustomer) => void;
  tableLimit: number;
  tableOffset: number;
  handlePagination: (direction: "prev" | "next") => void;
}

export default function CustomerTable(props: ICustomerTableProps) {
  const { t } = useTranslation();

  return (
    <DataTable
      columns={[
        { label: t("elements.customer.customer_table.001") },
        { label: t("elements.customer.customer_table.003") },
        { label: t("elements.customer.customer_table.004") },
        { label: t("elements.customer.customer_table.005") },
      ]}
      loading={props.customers === undefined}
      footer={{
        tableLimit: props.tableLimit,
        tableOffset: props.tableOffset,
        handleTableOffset: props.handlePagination,
        disableNextOffset: !props.customers || props.customers?.length <= props.tableLimit,
      }}
    >
      {props.customers?.map((customer, index) => (
        <tr key={index} className="clickable" onClick={() => props.onClick(customer)}>
          <td>
            <p>{customer.full_name}</p>
            <p>{customer.email}</p>
          </td>
          <td>
            {customer.phone ? (
              customer.phone
            ) : (
              <p className="field-unavailable">{t("elements.customer.customer_table.006")}</p>
            )}
          </td>
          <td>
            {customer.default_address_line ? (
              <p>
                {customer.default_address_line.city}, {customer.default_address_line.province_code}
              </p>
            ) : (
              <p className="field-unavailable">{t("elements.customer.customer_table.007")}</p>
            )}
          </td>
          <td>
            {customer.customer_type ? <p>{customer.customer_type}</p> : null}
            {customer.cart_customer_type ? <p>{customer.cart_customer_type}</p> : null}
          </td>
        </tr>
      ))}
    </DataTable>
  );
}
