import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { showError, showSuccess } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";

import { StatusCode } from "api/protocols";
import { GetEmailTemplate } from "api/rpc/2024-04/facilityAdmin/facility/emailTemplate";

import Page from "components/page/Page";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import Card from "components/card/Card";
import { valueToString } from "helpers/Helpers";

export type TEmailTemplate = {
  id: number;
  facility_id: number;
  title: string;
  type: string;
  subject: string;
  content: string;
};

type TEditEmailTemplate = {
  id: number;
  title: string;
  subject: string;
  content: string;
};

interface IEmailTemplatesState {
  templates: Array<TEmailTemplate>;
}

export default function EmailTemplates(props: any) {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [emailTemplates, setEmailTemplates] = useState(undefined);

  useEffect(() => {
    void loadTemplates();
  }, []);

  async function loadTemplates() {
    const templateRes = await GetEmailTemplate(null, false);

    if (templateRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading email templates"));
    }

    setEmailTemplates(templateRes.data);
  }

  const primaryAction = {
    content: "New",
    action: () => history.push("/admin/settings/email-template/new"),
  };

  return (
    <Page title="Email Templates" narrow primaryAction={primaryAction}>
      <Card>
        <Card.Section table={true}>
          <DataTable columns={[{ label: "Title" }, { label: "Type" }]} loading={emailTemplates === undefined}>
            {emailTemplates?.map((template: TEmailTemplate, index: number) => {
              return (
                <tr
                  key={index}
                  onClick={() => history.push("/admin/settings/email-template/" + String(template.id))}
                  className="clickable"
                >
                  <td>{template.title}</td>
                  <td>{valueToString(template.type)}</td>
                </tr>
              );
            })}
          </DataTable>
        </Card.Section>
      </Card>
    </Page>
  );
}
