import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import classNames from "classnames";
import moment from "moment";

import { StatusCode } from "api/protocols";
import { UserActive } from "api/rpc";
import {
  GetReservationLocation,
  GetReservationModule,
  GetReservationSegment,
} from "api/rpc/guest/reservations/reservations";
import Callout from "components/callout/Callout";
import Page from "components/page/Page";
import DateBar from "elements/teesheet/DateBar";

import { IUIActions } from "redux/actions/ui";
import { ILocation, IModule, ISegment } from "redux/reducers/models/reservations";
import { IReservationActions } from "redux/actions/reservations";
import "./reservations.scss";
import { ReservationsNavigation } from "./ReservationsNavigation/ReservationsNavigation";
import { IReservationState as IReservationStore } from "redux/reducers/reservations";
import ReactDOM from "react-dom";
import { capitalize, formatDate } from "helpers/Helpers";
import { GetFacility } from "api/rpc/guest/facility";
import { LocaleCurrency } from "helpers/Locale";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import { GetCustomerReservationSegment } from "api/rpc/facilityAdmin/customer/reservations";
interface IProps {
  uiActions: IUIActions;
  reservationActions: IReservationActions;
  reservationStore: IReservationStore;
}

interface IParams {
  facilityShortName: string;
  moduleHandle: string;
}

interface IReservationState {
  module: IModule;
  locations: Array<ILocation>;
  segments: { [key: string]: Array<ISegment> };
  selectedDate: Date;
  activeUser: boolean;
}

export default function Reservations(props: IProps) {
  const { uiActions, reservationActions, reservationStore } = props;
  const { facilityShortName, moduleHandle } = useParams<IParams>();
  const [reservationState, setReservationState] = useState<IReservationState>({
    module: null,
    segments: null,
    locations: null,
    selectedDate: new Date(),
    activeUser: false,
  });
  const history = useHistory();

  useEffect(() => {
    void getModule();
  }, []);

  useEffect(() => {
    if (reservationState.module) {
      void GetSegment();
    }
  }, [reservationState.selectedDate]);

  async function getModule() {
    uiActions.enqueue();
    const activeUserRes = await UserActive(false);

    if (activeUserRes.status === StatusCode.OK) {
      reservationActions.update({ active_user: activeUserRes?.data });
      setReservationState(prevState => ({
        ...prevState,
        activeUser: true,
      }));
    }

    const moduleRes = await GetReservationModule(
      { facility_short_name: facilityShortName, handle: moduleHandle },
      false,
    );
    if (moduleRes.status !== StatusCode.OK) {
      uiActions.showError("Error getting module");
      uiActions.dequeue();
      return;
    }

    if (!reservationStore?.facility) {
      const facilityRes = await GetFacility({ id: moduleRes?.data?.facility_id }, false);
      if (facilityRes?.status !== StatusCode.OK) {
        uiActions.showError("Error getting facility");
        uiActions.dequeue();
        return;
      }
      reservationActions.update({ facility: facilityRes?.data[0] });
    }

    const locationRes = await GetReservationLocation({ facility_id: moduleRes.data?.facility_id }, false);
    if (locationRes.status !== StatusCode.OK) {
      uiActions.showError("Error getting location");
      uiActions.dequeue();
      return;
    }

    let segmentRes;

    //Call the Customer GET Segments endpoint if the customer is logged in, else call Guest endpoint
    if (activeUserRes.status === StatusCode.OK) {
      segmentRes = await GetCustomerReservationSegment(
        { module_id: moduleRes.data?.id, date: moment(reservationState.selectedDate).format("YYYY-MM-DD") },
        false,
      );
      if (segmentRes.status !== StatusCode.OK) {
        uiActions.showError("Reservations unavailable");
        setReservationState(prevState => ({ ...prevState, segments: null }));
      }
    } else {
      segmentRes = await GetReservationSegment(
        { module_id: moduleRes.data?.id, date: moment(reservationState.selectedDate).format("YYYY-MM-DD") },
        false,
      );
      if (segmentRes.status !== StatusCode.OK) {
        uiActions.showError("Reservations unavailable");
        setReservationState(prevState => ({ ...prevState, segments: null }));
      }
    }

    uiActions.dequeue();

    const formattedSegments = segmentRes.data?.reduce(
      (formattedSegments: { [key: string]: Array<ISegment> }, currentSegment: ISegment) => {
        return {
          ...formattedSegments,
          [currentSegment.start_time]: formattedSegments[currentSegment.start_time]
            ? [...formattedSegments[currentSegment.start_time], currentSegment]
            : [currentSegment],
        };
      },
      {},
    );

    ReactDOM.unstable_batchedUpdates(() => {
      reservationActions.update({ module: moduleRes.data, selectedSegment: null, selectedLocation: null });
      setReservationState(prevState => ({
        ...prevState,
        module: moduleRes.data,
        segments: formattedSegments,
        locations: locationRes.data,
      }));
    });
  }

  async function GetSegment() {
    let segmentRes;
    //Call the Customer GET Segments endpoint if the customer is logged in, else call Guest endpoint
    if (reservationState.activeUser) {
      segmentRes = await GetCustomerReservationSegment(
        { module_id: reservationState?.module?.id, date: moment(reservationState.selectedDate).format("YYYY-MM-DD") },
        true,
      );
      if (segmentRes.status !== StatusCode.OK) {
        uiActions.showError("Reservations unavailable");
        setReservationState(prevState => ({ ...prevState, segments: null }));
        return;
      }
    } else {
      segmentRes = await GetReservationSegment(
        {
          module_id: reservationState?.module?.id,
          date: moment(reservationState.selectedDate).format("YYYY-MM-DD"),
        },
        true,
      );
      if (segmentRes.status !== StatusCode.OK) {
        uiActions.showError("Reservations unavailable");
        setReservationState(prevState => ({ ...prevState, segments: null }));
        return;
      }
    }

    const formattedSegments = segmentRes.data?.reduce(
      (formattedSegments: { [key: string]: Array<ISegment> }, currentSegment: ISegment) => {
        return {
          ...formattedSegments,
          [currentSegment.start_time]: formattedSegments[currentSegment.start_time]
            ? [...formattedSegments[currentSegment.start_time], currentSegment]
            : [currentSegment],
        };
      },
      {},
    );

    setReservationState(prevState => ({ ...prevState, segments: formattedSegments }));
  }

  function handleDateChange(selectedDate: Date) {
    setReservationState(prevState => ({ ...prevState, selectedDate }));
  }

  function checkUserLoggedIn(location: ILocation, segment: ISegment) {
    reservationActions.update({ selectedSegment: segment, selectedLocation: location });
    if (reservationState.activeUser) {
      history.push(
        `/${facilityShortName}/${moduleHandle}/booking?start_time=${segment?.start_time}&date=${segment?.date}&location_id=${location?.id}`,
      );
    } else {
      history.push(
        `/${facilityShortName}/${moduleHandle}/login/?start_time=${segment?.start_time}&date=${segment?.date}&location_id=${location?.id}`,
      );
    }
  }

  return (
    <>
      <ReservationsNavigation
        uiActions={uiActions}
        activeUser={reservationState.activeUser}
        userFirstName={reservationStore?.active_user?.first_name}
      />
      <div className="reservations-flex-row reservations-facility-container">
        <span className="facility-logo">
          {reservationStore?.facility?.logo_source && (
            <img className="facility-logo" src={reservationStore?.facility?.logo_source} alt="Facility Logo" />
          )}
        </span>
        {reservationStore?.facility && (
          <div className="reservations-facility-container-group">
            <p className="facility-name">{reservationStore?.facility?.long_name}</p>
            <p className="facility-address">
              {reservationStore?.facility?.address_line_1}, {reservationStore?.facility?.city},{" "}
              {reservationStore?.facility?.province_name}, {reservationStore?.facility?.postal}
            </p>
          </div>
        )}
      </div>
      <Page title={reservationState?.module?.title}>
        <div className="reservations-date-container">
          <DatePickerInput
            months={1}
            position="left"
            startingDate={reservationState.selectedDate}
            setStartingDate={handleDateChange}
          />
          <div className="reservations-date-bar">
            <DateBar numberOfDays={8} selectedDate={reservationState.selectedDate} onClick={handleDateChange} />
          </div>
        </div>
        {reservationState.module &&
        reservationState.locations &&
        reservationState.segments &&
        Object.keys(reservationState.segments)?.length > 0 ? (
          <div className="reservations-segments-container">
            <table className="ui-table ui-table-condensed ui-table-separated-horizontal reservations-segments-table">
              <thead>
                <tr>
                  <td>Time</td>
                  {reservationState?.locations?.map((location, locationIndex) => {
                    return <td key={locationIndex}>{location.title}</td>;
                  })}
                </tr>
              </thead>
              <tbody>
                {Object.keys(reservationState?.segments)?.map((segmentKey, segmentKeyIndex) => {
                  return (
                    <tr key={segmentKeyIndex}>
                      <td className="text-bold">{moment(segmentKey, "hh:mm:ss").format("h:mm A")}</td>
                      {reservationState?.locations?.map((location, locationIndex) => {
                        const segment = reservationState?.segments[segmentKey]?.find(
                          searchSegment => searchSegment?.location_id === location?.id,
                        );
                        if (segment?.league_id !== null || segment?.tournament_id !== null) {
                          return (
                            <td
                              key={locationIndex}
                              className={classNames({
                                "segment-league": segment?.league_id !== null,
                                "segment-tournament": segment?.tournament_id !== null,
                              })}
                            >
                              <div className="segment-container">
                                {segment?.league_id && (
                                  <>
                                    <p className="text-semibold">
                                      <span>{location.title}, </span>
                                      <span>{moment(segmentKey, "hh:mm:ss").format("h:mm A")}</span>
                                    </p>
                                    <p>
                                      <span>League</span>
                                    </p>
                                  </>
                                )}
                                {segment?.tournament_id && (
                                  <>
                                    <p className="text-semibold">
                                      <span>{location.title}, </span>
                                      <span>{moment(segmentKey, "hh:mm:ss").format("h:mm A")}</span>
                                    </p>
                                    <p>
                                      <span>Tournament</span>
                                    </p>
                                  </>
                                )}
                              </div>
                            </td>
                          );
                        } else if (segment?.blocked_type === "open" && segment?.booking_id === null) {
                          return (
                            <td
                              key={locationIndex}
                              className={classNames("segment-available")}
                              onClick={() => checkUserLoggedIn(location, segment)}
                            >
                              <div className="segment-container">
                                <p className="text-semibold">
                                  <span>{location.title}, </span>
                                  <span>{moment(segmentKey, "hh:mm:ss").format("h:mm A")}</span>
                                </p>
                                <p>
                                  {segment?.blocks[0]?.variant_price ? (
                                    <LocaleCurrency amount={segment?.blocks[0]?.variant_price} />
                                  ) : (
                                    "$"
                                  )}{" "}
                                  / hour
                                </p>
                              </div>
                            </td>
                          );
                        } else if (segment?.blocked_type === "open_reserved") {
                          return (
                            <td key={locationIndex} className="segment-available">
                              <div className="segment-container">
                                <p>{capitalize(segment?.blocked_type)}</p>
                                {segment?.blocks[0]?.variant_price && (
                                  <LocaleCurrency amount={segment?.blocks[0]?.variant_price} />
                                )}
                              </div>
                            </td>
                          );
                        } else if (segment?.blocked_type === "blocked" || segment?.blocked_type === "booked") {
                          return (
                            <td key={locationIndex} className="segment-disabled">
                              <div className="segment-container">
                                <p>{capitalize(segment?.blocked_type)}</p>
                              </div>
                            </td>
                          );
                        } else {
                          return <td key={locationIndex} className="segment-unavailable"></td>;
                        }
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <Callout type="error" title="No Reservations" content="There are no reservations for this day" />
        )}
      </Page>
    </>
  );
}
