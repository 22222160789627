import React, { useEffect, useState } from "react";
import "./registrationFeeInputs.scss";
import { Dictionary, cloneDeep, groupBy } from "lodash";
import { useAppDispatch } from "hooks/redux";
import { ISelectedEditInput } from "components/draggableEditInput/DraggableEditInput";
import useModal from "hooks/modals/useModal";
import useUnsavedChangesContainer from "hooks/useUnsavedChangesContainer/useUnsavedChangesContainer";
import axios, { CancelToken } from "axios";
import { StatusCode } from "api/protocols";
import { generateInputRowsWithSequentialPositions } from "hooks/editInputDragAndDrop/useDropEditInputRow/useDropEditInputRow";
import { showError, showSuccess } from "redux/actions/ui";
import Page from "components/page/Page";
import Card from "components/card/Card";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import Spin from "components/spin/spin";
import Portal from "elements/Portal";
import Sheet from "components/sheet/Sheet";
import FormLayout from "components/form/FormLayout";
import Popup from "components/popup/Popup";
import Input from "components/form/input/Input";
import { Select } from "components/select/index";
import { capitalize } from "helpers/Helpers";
import { LeagueRegistrationContext } from "../../LeagueRegistrationContext";
import {
  DeleteLeagueRegistrationInput,
  GetLeagueRegistrationInput,
  ILeagueRegistrationInput,
  PostLeagueRegistrationInput,
  PutLeagueRegistrationInput,
  PutLeagueRegistrationInputPositions,
  TLeagueRegistrationInputTypes,
  TPostLeagueRegistrationInput,
  TPutLeagueRegistrationInput,
  TPutLeagueRegistrationInputPosition,
} from "api/rpc/2024-04/facilityAdmin/league/registration/inputs";
import LeagueRegistrationEditInputLayout from "../../dragAndDropElements/LeagueRegistrationEditInputLayout";

interface IRegistrationFeeInputs {
  leagueId: number;
}

export type TLeagueRegistrationInputRows = Dictionary<ILeagueRegistrationInput[]>;

export interface ILeagueRegistrationNewInputModal extends Omit<TPostLeagueRegistrationInput, "type"> {
  isOpen: boolean;
  type: TLeagueRegistrationInputTypes | "none";
}

export default function RegistrationFeeInputs(props: IRegistrationFeeInputs) {
  const dispatch = useAppDispatch();

  const [selectedInput, setSelectedInput] = useState<ISelectedEditInput>({
    id: null,
    deletePopupOpen: false,
    editModalOpen: false,
  });

  const { state, updateModal } = useModal({
    league_id: props.leagueId,
    label: "",
    help_text: "",
    required: true,
    type: "none" as TLeagueRegistrationInputTypes | "none",
  });

  const [leagueRegistrationContainer, leagueRegistrationContainerActions] =
    useUnsavedChangesContainer<TLeagueRegistrationInputRows>();

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadLeagueRegistrationInputs(props.leagueId, source.token);
    return () => source.cancel();
  }, [props.leagueId]);

  async function loadLeagueRegistrationInputs(league_id: number, token?: CancelToken) {
    const res = await GetLeagueRegistrationInput({ league_id }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      leagueRegistrationContainerActions.set(undefined);
      return;
    }

    const inputRows = groupBy(res.data, input => input.row);

    for (const row in inputRows) {
      inputRows[row].sort((prev, next) => prev.column - next.column);
    }

    leagueRegistrationContainerActions.set(inputRows);
  }

  async function updateLeagueRegistrationInputRowPositions() {
    const updatedLeagueRegistrationInputRows: TLeagueRegistrationInputRows = generateInputRowsWithSequentialPositions(
      leagueRegistrationContainer.updatedState,
    );

    const leagueRegistrationInputPositions: TPutLeagueRegistrationInputPosition[] = [];

    Object.keys(updatedLeagueRegistrationInputRows).forEach(row => {
      updatedLeagueRegistrationInputRows[row].forEach(input => {
        leagueRegistrationInputPositions.push({ id: input.id, row: input.row, column: input.column });
      });
    });

    const putLeagueRegistrationInputPositions = await PutLeagueRegistrationInputPositions({
      inputs: leagueRegistrationInputPositions,
    });

    if (putLeagueRegistrationInputPositions.status !== StatusCode.OK) {
      dispatch(showError("Error updating league registration input positions")); // TODO: Translation
      return undefined;
    }

    leagueRegistrationContainerActions.set(updatedLeagueRegistrationInputRows);
    return updatedLeagueRegistrationInputRows;
  }

  async function handleAddNewInput() {
    let inputRows: TLeagueRegistrationInputRows = undefined;
    if (leagueRegistrationContainer.changesExist) {
      inputRows = await updateLeagueRegistrationInputRowPositions();
      if (!inputRows) {
        return;
      }
    }

    // only assign right operand if inputRows = null | undefined
    inputRows ??= cloneDeep(leagueRegistrationContainer.updatedState);

    // destructure & remove isOpen prop
    const parsedModal: ILeagueRegistrationNewInputModal = JSON.parse(JSON.stringify(state));
    const params = (({ isOpen, ...rest }) => rest)(parsedModal);

    const res = await PostLeagueRegistrationInput(
      {
        league_id: Number(props.leagueId),
        ...params,
        type: params.type === "none" ? null : params.type,
        required: params.type === "text" ? false : params.required,
      },
      true,
    );

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error creating league registration input")); // TODO: Translation
      return;
    }

    // close modal
    dispatch(showSuccess("Successfully added a new input."));
    updateModal({
      isOpen: false,
      label: "",
      help_text: "",
      type: "none",
    });

    // set updated inputs
    const row = String(res.data.row);
    const updatedInputRowsWithInput: TLeagueRegistrationInputRows = {
      ...inputRows,
      [row]: [res.data],
    };
    leagueRegistrationContainerActions.set(updatedInputRowsWithInput);
  }

  async function handleRemoveInput(id: number) {
    let updatedInputRows: TLeagueRegistrationInputRows = undefined;

    if (leagueRegistrationContainer.changesExist) {
      updatedInputRows = await updateLeagueRegistrationInputRowPositions();
      if (!updatedInputRows) {
        return;
      }
    }

    // only assign right operand if inputRows = null | undefined
    updatedInputRows ??= cloneDeep(leagueRegistrationContainer.updatedState);

    const res = await DeleteLeagueRegistrationInput({ id }, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError("There was an error removing the input.")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Successfully removed the input.")); // TODO: Translation
    setSelectedInput(prev => ({ ...prev, id: null, deletePopupOpen: false }));

    // update container context
    let rowDeletingFrom = "";

    Object.keys(updatedInputRows).forEach(row => {
      if (updatedInputRows[row]?.some(input => input.id === id)) {
        rowDeletingFrom = row;
      }
    });

    if (rowDeletingFrom.length === 0) {
      return;
    }

    const { [rowDeletingFrom]: originalRow, ...rest } = updatedInputRows;

    const updatedRow = originalRow
      .filter(input => input.id !== id)
      .map((input, index) => ({ ...input, column: index + 1 }));

    const updatedRowsWithDelete: TLeagueRegistrationInputRows = {
      ...rest,
      ...(updatedRow.length > 0 ? { [rowDeletingFrom]: updatedRow } : {}),
    };

    if (updatedRow.length > 0) {
      leagueRegistrationContainerActions.set(updatedRowsWithDelete);
      return;
    }

    const updatedRowsWithShift: TLeagueRegistrationInputRows = {};
    const numericRowDeletingFrom = Number(rowDeletingFrom);

    Object.keys(updatedRowsWithDelete).forEach(row => {
      const numericRow = Number(row);

      if (numericRow > numericRowDeletingFrom) {
        const shiftedRow = numericRow - 1;

        updatedRowsWithShift[String(shiftedRow)] = updatedRowsWithDelete[row].map(input => {
          return {
            ...input,
            row: shiftedRow,
          };
        });
      } else {
        updatedRowsWithShift[row] = updatedRowsWithDelete[row];
      }
    });

    leagueRegistrationContainerActions.set(updatedRowsWithShift);
  }

  async function handleUpdateInput(id: number, label: string, helpText: string, value: string[], isRequired: boolean) {
    let updatedLeagueRegistrationInputRows: TLeagueRegistrationInputRows = undefined;

    if (leagueRegistrationContainer.changesExist) {
      updatedLeagueRegistrationInputRows = await updateLeagueRegistrationInputRowPositions();

      if (!updatedLeagueRegistrationInputRows) {
        return;
      }
    }

    updatedLeagueRegistrationInputRows ??= leagueRegistrationContainer.updatedState;

    const params: TPutLeagueRegistrationInput = {
      id: id,
      label: label,
      help_text: helpText,
      values: value,
      required: isRequired,
    };

    const res = await PutLeagueRegistrationInput(params, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error updating input.")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Input successfully saved.")); // TODO: Translation

    const updatedLeagueRegistrationInput = cloneDeep(res.data);
    const updatedInputRowsWithChanges: TLeagueRegistrationInputRows = cloneDeep(updatedLeagueRegistrationInputRows);

    for (const row in updatedInputRowsWithChanges) {
      const updatedRow = updatedInputRowsWithChanges[row];

      for (let i = 0; i < updatedRow.length; i++) {
        if (updatedRow[i].id === updatedLeagueRegistrationInput.id) {
          updatedRow[i] = updatedLeagueRegistrationInput;
        }
      }
    }

    leagueRegistrationContainerActions.set(updatedInputRowsWithChanges);
    setSelectedInput(prev => ({ ...prev, id: null, editModalOpen: false }));
  }

  return (
    <Page
      full
      notificationBarProps={{
        isVisible: leagueRegistrationContainer.changesExist,
        onAction: updateLeagueRegistrationInputRowPositions,
        onCancel: leagueRegistrationContainerActions.rollback,
      }}
    >
      <Card
        title="Inputs" // TODO: Translation
        titleActions={[{ content: "Add Input", /* TODO: Translation */ action: () => updateModal({ isOpen: true }) }]}
        style={{ marginTop: "8px" }}
      >
        <Card.Section>
          <DndProvider backend={HTML5Backend}>
            <LeagueRegistrationContext.Provider
              value={{
                selectedInput: selectedInput,
                setSelectedInput: setSelectedInput,
                inputRowsContainer: leagueRegistrationContainer,
                inputRowsContainerActions: leagueRegistrationContainerActions,
                handleUpdateInput: handleUpdateInput,
                closeInputEditModal: () => setSelectedInput(prev => ({ ...prev, id: null, editModalOpen: false })),
              }}
            >
              {!leagueRegistrationContainer.updatedState ? (
                <div style={{ height: "106px", overflow: "hidden", transform: "scale(0.5)" }}>
                  <Spin />
                </div>
              ) : (
                <Card>
                  <Card.Section>
                    <LeagueRegistrationEditInputLayout openModal={() => updateModal({ isOpen: true })} />
                  </Card.Section>
                </Card>
              )}
            </LeagueRegistrationContext.Provider>
          </DndProvider>
        </Card.Section>
      </Card>

      {/* <div className="league-registration-add-input-container">
        <Button type="text" onClick={() => updateModal({ isOpen: true })}>
          <FontAwesomeIcon className="league-registration-add-input-icon" size="sm" icon={["far", "plus"]} />
          <span>Add Input</span>
        </Button>
      </div> */}

      {/* New Input Modal */}
      <Portal isMounted={state.isOpen}>
        <Sheet
          open={state.isOpen}
          onOk={handleAddNewInput}
          okText="Create" // TODO: Translation
          onCancel={() => updateModal({ isOpen: false, label: "", help_text: "", type: "none" })}
          okDisabled={state.type === "none" || !state.label}
          size="small"
          title="New Input" // TODO: Translation
          overflow
        >
          <FormLayout>
            <FormLayout.Group>
              <Select
                label="Input Type" // TODO: Translation
                onChange={(value: TLeagueRegistrationInputTypes | "none") => updateModal({ type: value })}
                defaultValue="none"
              >
                {(["none", "input", "select", "checkbox", "text"] as TLeagueRegistrationInputTypes[]).map(
                  (value, index) => {
                    return (
                      <Select.Option key={index} value={value} name={capitalize(value)}>
                        {capitalize(value)}
                      </Select.Option>
                    );
                  },
                )}
              </Select>
            </FormLayout.Group>
            <FormLayout.Group>
              <Input
                label="Label" // TODO: Translation
                value={state.label}
                onChange={e => updateModal({ label: e.target.value })}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <Input
                label={state?.type === "text" ? "Subtext" : "Help Text"} // TODO: Translation
                value={state.help_text}
                onChange={e => updateModal({ help_text: e.target.value })}
              />
            </FormLayout.Group>
          </FormLayout>
        </Sheet>
      </Portal>

      {/* Remove Input Popup */}
      <Portal isMounted={selectedInput.deletePopupOpen}>
        <Popup
          open={selectedInput.deletePopupOpen}
          type="warning"
          title="Delete Input?" // TODO: Translation
          description="Are you sure you want to remove this input?" // TODO: Translation
          onCancel={() => setSelectedInput(prev => ({ ...prev, id: null, deletePopupOpen: false }))}
          onOk={() => handleRemoveInput(selectedInput.id)}
        />
      </Portal>
    </Page>
  );
}
