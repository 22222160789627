import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { GetMembership } from "api/rpc/2022-09/facilityAdmin/membership/membership";

import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Sheet from "components/sheet/Sheet";
import Search from "components/search/Search";
import Spin from "components/spin/spin";
import Checkbox from "components/form/checkbox/Checkbox";

type NewCustomerMembershipModalProps = {
  children: React.ReactElement<any>;
  applyMembershipToCustomer: (selectedMembershipId: number) => void;
  adminType: "facility" | "client";
};

export default function NewCustomerMembershipModal(props: NewCustomerMembershipModalProps) {
  const [state, setState] = useState({
    isOpen: false,
    searchValue: "",
    selectedMembership: null,
  });
  const [searchedMemberships, setSearchedMemberships] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    let mounted = true;

    void loadAllMemberships();

    return () => {
      mounted = false;
    };

    async function loadAllMemberships() {
      // loading spinner while waiting for response
      if (searchedMemberships !== null) {
        setSearchedMemberships(null);
      }
      let res: any;

      //different endpoints
      switch (props.adminType) {
        case "facility":
          res = await GetMembership({ search: state.searchValue }, false);
          break;
        case "client":
          // TODO: Add Client Admin GetMembership (all memberships) endpoint
          // res = await GetMembership({ search: state.searchValue }, false);
          break;
      }

      if (res?.status !== StatusCode.OK) {
        console.log(res.message);
        setSearchedMemberships([]); //stop loading spinner
        return;
      }

      console.log(res.data); //debug

      if (mounted) {
        setSearchedMemberships(res.data);
      }
    }
  }, [state.searchValue]);

  function handleApplyMembership() {
    props.applyMembershipToCustomer(state.selectedMembership.id);
    setState({ ...state, isOpen: false, selectedMembership: null });
  }

  return (
    <>
      {/* Child == modal trigger */}
      {React.cloneElement(props.children, { onClick: () => setState({ ...state, isOpen: true }) })}

      {state.isOpen && (
        <Sheet
          open
          size="medium"
          closable
          title={t("elements.customer.memberships.new_customer_memberships_modal.001")} //TODO: Translation
          onCancel={() => setState({ ...state, isOpen: false, selectedMembership: null })}
          onOk={handleApplyMembership}
          cancelText={t("elements.customer.memberships.new_customer_memberships_modal.002")}
          okText={t("elements.customer.memberships.new_customer_memberships_modal.003")} //TODO: Translation
          okDisabled={state.selectedMembership ? false : true}
        >
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Search
                  historyKey={"ui-customer-membership"}
                  searchCallback={searchValue =>
                    setState({
                      ...state,
                      searchValue: searchValue,
                      selectedMembership: null,
                    })
                  }
                  placeholder={t("elements.customer.memberships.new_customer_memberships_modal.004")} //TODO: Translation
                  inputDisabled={searchedMemberships === null ? true : false} // When API called, input disabled
                />
              </FormLayout.Group>

              <FormLayout.Group>
                <div className="ui-checkbox-group">
                  <br />
                  {searchedMemberships ? (
                    searchedMemberships.map((membership: any, index: number) => {
                      console.log(membership);
                      return (
                        <div
                          key={(membership.id as number) + index}
                          onClick={() => setState({ ...state, selectedMembership: membership })}
                          className={`
                                  ui-checkbox-group-item
                                  ${state.selectedMembership?.id === membership.id && "ui-checkbox-group-item_selected"}
                                `}
                        >
                          <div className="ui-checkbox-group-item-content">
                            <div className="ui-checkbox-group-item-text">
                              <p className="font-bold">{membership.title}</p>
                              <p className="mb-2">{membership.subtitle}</p>
                              <p>
                                <span className="font-medium">
                                  {t("elements.customer.memberships.new_customer_memberships_modal.005")}{" "}
                                </span>
                                {membership.expiry ? membership.expiry : "N/A"}
                              </p>
                            </div>
                          </div>
                          <Checkbox
                            size="medium"
                            value={membership.id}
                            checked={state.selectedMembership?.id === membership.id}
                            onChange={() => setState({ ...state, selectedMembership: membership })}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <div
                      style={{ height: "114px", justifyContent: "center", alignItems: "center" }}
                      className="ui-checkbox-group-item"
                    >
                      <Spin />
                    </div>
                  )}
                </div>
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Sheet>
      )}
    </>
  );
}
