import { ILeague } from "./models/league";

export interface ILeagueState {
  isLoaded: boolean;
  error?: any;
  league: ILeague;
}

export interface ILeagueAction {
  type: string;
  payload: any;
}

const initialState: ILeagueState = {
  isLoaded: false,
  error: null,
  league: null,
};

export default function LeagueReducer(state = initialState, action: ILeagueAction) {
  switch (action.type) {
    case "league.update":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
