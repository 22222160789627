import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/tee-sheet/booking-engine/category";

interface IGetBookingCategory {
  id?: string;
}

export async function GetBookingCategory(params: IGetBookingCategory, useGlobalLoader: boolean) {
  return await APIGet(baseUrl, params, useGlobalLoader);
}
