import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "components/bookingPopUp/note/Note.scss";

interface INoteProps {
  user: string;
  description: string;
  dateString: string;
  isQuickNote?: boolean;
  limitWidth?: boolean;
}

function Note(props: INoteProps) {
  const [isQuickNote, setIsQuickNote] = useState(props.isQuickNote || false);

  return (
    <>
      {!isQuickNote ? (
        <div className="bm-note-container">
          <div className="bm-note-content">
            <div className="bm-note-date-user">
              <p className="bm-note-font-tint">{props.dateString}</p>
              <p className="bm-note-font-tint">
                <FontAwesomeIcon icon="user-alt" /> &nbsp; {props.user}
              </p>
            </div>
            <p className="bm-note-description">{props.description}</p>
          </div>
        </div>
      ) : (
        <div className="bm-note-container note-preview">
          <div className="quick_note">
            <div className="quick_note-icon">
              <FontAwesomeIcon icon="user-alt" size="lg" />
            </div>
            <div>
              <p className={props.limitWidth ? "quick_note-description-limit_width" : "quick_note-description"}>
                {props.user} <span className="quick_note-description-tint">posted</span> {props.description}
              </p>
              <p className="bm-note-font-tint"> {props.dateString}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Note;

// function formatAMPM(date: any) {
//   let hours = date.getHours();
//   let minutes = date.getMinutes();
//   const ampm = hours >= 12 ? "pm" : "am";
//   hours = hours % 12;
//   hours = hours ? hours : 12; // the hour '0' should be '12'
//   minutes = minutes < 10 ? "0" + String(minutes) : String(minutes);
//   const strTime = String(hours) + ":" + String(minutes) + " " + String(ampm);
//   return strTime;
// }
