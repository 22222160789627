import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { update } from "lodash";
import { IFacility } from "redux/reducers/models/facility";

const apiVersion = "2021-01";
const adminType = "facility-admin";
const baseFacilityUrl = "/" + apiVersion + "/" + adminType + "/facility";

const updatedBaseUrl = "/2022-09/" + adminType + "/facility";

const baseFacilityUrlNew = "/2022-09/" + adminType + "/facility";

interface IGetFacilityRes {
  status: number;
  data: IFacility[];
  message: string;
}

interface IPutFacilityRes {
  status: number;
  data: IFacility[];
  message: string;
}

interface IDepartments {
  id?: number;
  title?: string;
  type?: string;
  parent_id?: number;
}

interface IAccountingRef {
  id?: number;
  title?: string;
  reference_number: string;
}

export async function GetFacilityNew(params: any, useGlobalLoader: boolean): Promise<IGetFacilityRes> {
  return await APIGet(baseFacilityUrlNew, params, useGlobalLoader);
}

// GET Facility
export async function GetFacility(params: string, useGlobalLoader: boolean): Promise<IGetFacilityRes> {
  return await APIGet(baseFacilityUrl + "?" + params, useGlobalLoader);
}

// PUT Facility
export async function PutFacility(body: any, useGlobalLoader: boolean): Promise<IPutFacilityRes> {
  return await APIPut("/" + apiVersion + "/" + adminType + "/facility", body, useGlobalLoader);
}

export async function GetPaymentOptions(
  params?: Record<string, unknown>,
  useGlobalLoader = false,
  token?: CancelToken,
) {
  return await APIGet(baseFacilityUrl + "/payment-option", params, useGlobalLoader, token);
}

//GET Departments
export async function GetDepartments(useGlobalLoader: boolean) {
  return await APIGet(baseFacilityUrl + "/department?extended=true", null, useGlobalLoader);
}

//POST Departments
export async function PostDepartments(params: IDepartments, useGlobalLoader?: boolean) {
  return await APIPost(baseFacilityUrl + "/department", params, useGlobalLoader);
}

//PUT Departments
export async function PutDepartments(params: IDepartments, useGlobalLoader?: boolean) {
  return await APIPut(baseFacilityUrl + "/department", params, useGlobalLoader);
}

//DELETE Departments
export async function DeleteDepartments(params: IDepartments, useGlobalLoader?: boolean) {
  return await APIDelete(baseFacilityUrl + "/department", params, useGlobalLoader);
}

export async function GetPayout(useGlobalLoader: boolean) {
  return await APIGet(baseFacilityUrl + "/payout", null, useGlobalLoader);
}

export async function GetTransactions(params: string, useGlobalLoader: boolean) {
  return await APIGet(baseFacilityUrl + "/payout/transaction" + "?" + params, null, useGlobalLoader);
}

// GET Accounting Reference
export async function GetAccountingReference(params: string, useGlobalLoader?: boolean) {
  return await APIGet(baseFacilityUrl + "/accounting?" + params, null, useGlobalLoader);
}

// POST Accounting Reference
export async function PostAccountingReference(params: IAccountingRef, useGlobalLoader?: boolean) {
  return await APIPost(baseFacilityUrl + "/accounting", params, useGlobalLoader);
}

// PUT Accounting Reference
export async function PutAccountingReference(params: IAccountingRef, useGlobalLoader?: boolean) {
  return await APIPut(baseFacilityUrl + "/accounting", params, useGlobalLoader);
}

// DELETE Accounting Reference
export async function DeleteAccountingReference(params: IAccountingRef, useGlobalLoader?: boolean) {
  return await APIDelete(baseFacilityUrl + "/accounting", params, useGlobalLoader);
}

//GET Accounts
export async function GetAccounts(useGlobalLoader: boolean) {
  return await APIGet("/" + apiVersion + "/" + adminType + "/client/account", useGlobalLoader);
}

//GET Sales Channels
//Retrieve a list of sales channels for the facility
export async function GetSalesChannels(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseFacilityUrl + "/sales-channel", useGlobalLoader);
}

//PUT Update Sales Channel
//Adds or removes a product from sales channel
export async function UpdateSalesChannel(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseFacilityUrl + "/sales-channel/product", params, useGlobalLoader);
}

//GET TeeDecks
export async function GetTeeDecks(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseFacilityUrlNew + "/tee-deck", params, useGlobalLoader);
}

//GET Divisions
export async function GetDivisions(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseFacilityUrlNew + "/division", params, useGlobalLoader);
}
