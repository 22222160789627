import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2021-01";
const adminType = "facility-admin";
const baseCustomerUrl = "/" + apiVersion + "/" + adminType + "/customer";

interface IGetCustomer {
  id?: number;
  extended?: boolean;
  memberships?: boolean;
  search?: string;
  configuration?: boolean;
}

// GET Cart
// Retrieve a list of customers
// memberships: boolean
export async function GetCustomer(params: IGetCustomer, useGlobalLoader: boolean, cancelToken?: CancelToken) {
  return await APIGet(baseCustomerUrl, params, useGlobalLoader, cancelToken);
}

interface IPostCustomer {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  state?: string;
}

interface IPostCustomerRes {
  status: number;
  data: any;
}

export async function PostCustomer(params: IPostCustomer, useGlobalLoader: boolean): Promise<IPostCustomerRes> {
  return await APIPost(baseCustomerUrl, params, useGlobalLoader);
}
