import { IOptions } from "components/sideMenu/SideMenu";
import { matchPath } from "react-router";
import { TFunction, useTranslation } from "react-i18next";

const urlParams = matchPath<{ facilityShortName: string; bookingEngineHandle: string }>(window.location.pathname, {
  path: "/booking-engine/:facilityShortName/:bookingEngineHandle",
});
export default function PlayerProfileTranslation(t: TFunction<"translation", undefined>) {
  const options: IOptions[] = [
    {
      label: t("guest.customer.customer_nav_menu.player_profile_options.001"),
      url: `/booking-engine/${urlParams?.params?.facilityShortName}/${urlParams?.params?.bookingEngineHandle}/profile/overview`,
      icon: "user",
    },

    {
      label: t("guest.customer.customer_nav_menu.player_profile_options.002"),
      url: `/booking-engine/${urlParams?.params?.facilityShortName}/${urlParams?.params?.bookingEngineHandle}/profile/reservations`,
      icon: "calendar",
    },
  ];

  return options;
}
