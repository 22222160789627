import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "components/bookingEngine/orderSummaryLineItem/OrderSummaryLineItem.scss";
import { Badge } from "components/badge/Badge";
import classNames from "classnames";

interface IProps {
  lineItem?: string;
  itemValue?: any;
  lineItemVariant?: string;
  lineItemQuantity?: number;
  itemSubtitle?: string;
  prepaid?: boolean;
  className?: string;
}

function OrderSummaryLineItem(props: IProps) {
  const itemName = props.lineItem;
  const itemValue = props.itemValue;
  const itemVariantName = props?.lineItemVariant;
  const itemQuantity = props?.lineItemQuantity;

  return (
    <div>
      <ul className="leaders">
        <li>
          <span className={classNames("line-item", props.className)}>
            {itemQuantity && (
              <span className="item-quantity">
                {itemQuantity}
                {"  "}
              </span>
            )}
            <span className="items-title-container">
              <span className="item-title">{itemName}</span>
              {itemVariantName && itemVariantName !== itemName && (
                <span className="item-variant-title">{itemVariantName}</span>
              )}
            </span>

            {props.itemSubtitle && <span className="item-subtitle">{props.itemSubtitle}</span>}
            <div className="item-dots"></div>
            <span className={classNames("item-value", props.className)}>{itemValue}</span>
          </span>
          {props.prepaid ? (
            <Badge type="gray" size="small">
              Prepaid
            </Badge>
          ) : null}
        </li>
      </ul>
    </div>
  );
}

export default OrderSummaryLineItem;
