import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetTickets, TTicketGet, ITicket } from "api/rpc/2024-04/clientAdmin/ticket/ticket";
import { showError } from "redux/actions/ui";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import Avatar from "components/avatar/Avatar";
import Search from "components/search/Search";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

import "pages/secure/facility/product/Ticket.scss";

export default function Tickets() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const [tickets, setTickets] = useState<ITicket[]>(undefined);
  const [filters, setFilters] = useState<TTicketGet>({
    search: "",
    limit: 50,
    offset: 0,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadTickets(source.token);

    return () => source.cancel();
  }, [filters]);

  async function loadTickets(token?: CancelToken) {
    if (tickets !== undefined) {
      setTickets(undefined);
    }
    const ticketRes = await GetTickets(filters, token ? false : true, token);
    console.log(ticketRes);

    if (token && token.reason) {
      return;
    }
    if (ticketRes.status !== StatusCode.OK) {
      dispatch(showError(typeof ticketRes.data === "string" ? ticketRes.data : "Error loading tickets."));
    }

    setTickets(ticketRes.status === StatusCode.OK ? ticketRes.data : []);
  }

  const primaryAction = {
    content: "New Ticket", // TODO: Translation
    action: () => history.push("/admin/tickets/new"),
  };

  const secondaryActions = [
    {
      content: "Redeem", // TODO: Translation
      action: () => history.push("/admin/tickets/stubs"),
    },
  ];

  return (
    <Page title="Tickets" narrow primaryAction={primaryAction} secondaryActions={secondaryActions}>
      <div className="flex-grow mb-4">
        <Search
          historyKey={"search_ticket_table_client"}
          searchCallback={searchValue => setFilters(prev => ({ ...prev, search: searchValue }))}
        />
      </div>
      <DataTable<ITicket>
        columns={[
          { label: "", width: "50%" },
          { label: "Included", width: "50%" }, //TODO: Translation
        ]}
        loading={tickets === undefined}
        footer={{
          tableLimit: filters.limit,
          tableOffset: filters.offset,
          disableNextOffset: !tickets || !(tickets?.length === filters.limit),
          handleTableOffset: direction =>
            setFilters(prev => ({
              ...prev,
              offset: direction === "prev" ? prev.offset - prev.limit : prev.offset + prev.limit,
            })),
        }}
      >
        {tickets?.map(ticket => (
          <tr key={ticket.id} className="clickable" onClick={() => history.push("/admin/tickets/" + String(ticket.id))}>
            <td>
              <div className="flex items-center gap-4">
                <Avatar source={ticket.logo_image} size="medium" />
                <span className="table-cell-text">
                  <p className="lead-text">{ticket.title}</p>
                  {ticket.subtitle ? <p className="support-text">{ticket.subtitle}</p> : null}
                </span>
              </div>
            </td>
            <td>{ticket.included}</td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
