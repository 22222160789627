import React from "react";
import "components/page/page.scss";
import { ButtonNew as Button } from "components/buttonNew";
import MultipleActionDropdown, {
  IMultipleActionDropdownProps,
} from "components/multipleActionDropdown/MultipleActionDropdown";
import SplitButton, { ISplitButtonProps } from "components/splitButton/SplitButton";

export interface IPrimaryPageAction {
  action: React.MouseEventHandler<HTMLElement>;
  disabled?: boolean;
  content: JSX.Element | string;
}

export interface ISecondaryPageAction {
  action: React.MouseEventHandler<HTMLElement>;
  disabled?: boolean;
  content: JSX.Element | string;
}

export interface IMultipleActionDropdownPageAction {
  label: string;
  disabled?: boolean;
  dropdownProps: Omit<IMultipleActionDropdownProps, "children">;
}

interface IPageActionsProps {
  primaryAction?: IPrimaryPageAction;
  secondaryActions?: ISecondaryPageAction[];
  multipleActionDropdownAction?: IMultipleActionDropdownPageAction;
  splitButtonAction: ISplitButtonProps;
}

function PageActions(props: IPageActionsProps) {
  const { primaryAction, secondaryActions, multipleActionDropdownAction, splitButtonAction } = props;

  return (
    <div className="ui-page-actions">
      {multipleActionDropdownAction && (
        <MultipleActionDropdown
          {...multipleActionDropdownAction.dropdownProps}
          disabled={multipleActionDropdownAction.disabled}
        >
          <Button size="medium" type="secondary" disabled={!!multipleActionDropdownAction?.disabled}>
            {multipleActionDropdownAction.label}
          </Button>
        </MultipleActionDropdown>
      )}
      {secondaryActions?.map((item: ISecondaryPageAction, index: number) => (
        <Button key={index} onClick={item.action} disabled={!!item.disabled}>
          {item.content}
        </Button>
      ))}
      {splitButtonAction && <SplitButton {...splitButtonAction}></SplitButton>}
      {primaryAction && (
        <Button size="medium" type="primary" disabled={!!primaryAction.disabled} onClick={primaryAction.action}>
          {primaryAction.content}
        </Button>
      )}
    </div>
  );
}

export default PageActions;
