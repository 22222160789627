import React, { useEffect, useState } from "react";

import { StatusCode } from "api/protocols";
import {
  GetCustomer,
  PutCustomer,
  PutMergeCustomers,
  UploadCustomerProfileImage,
  DeleteCustomerProfileImage,
} from "api/rpc/2022-09/clientAdmin/customer/customer";
import { ICustomer, ICustomerPreferences } from "redux/reducers/models/customer";

import { useCustomerClientContext } from "../context/CustomerClientContext";

import CustomerClientTabs from "../tabs/CustomerClientTabs";
import CustomerImage from "elements/customer/CustomerImage";

import "../../../facility/customer/main/customer.scss";
import { ButtonNew as Button } from "components/buttonNew";
import Sheet from "components/sheet/Sheet";
import GolferCard from "components/bookingPopUp/golferCard/GolferCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select } from "components/select/index";
import axios, { CancelToken } from "axios";
import Popup from "components/popup/Popup";
import { useAppDispatch } from "hooks/redux";
import { showError, showSuccess } from "redux/actions/ui";
import { NavigationDropdownNew } from "components/navigationDropdownNew/NavigationDropdownNew";
import PreferenceCard from "components/preferenceCard/PreferenceCard";
import { PutCustomerPreferences, SendPasswordReset } from "api/rpc/2024-04/clientAdmin/customer/customer";
import ReactDOM from "react-dom";
import { t } from "i18next";

interface IMergeState {
  //Merge Modal
  showMergeModal: boolean;
  selectedCustomer: ICustomer;
  customerSearchResults: Array<ICustomer>;
  customerSearchQuery: string;
  customerSearching: boolean;

  //Warning Popup
  showWarning: boolean;
}

/**
 * Customer Profile 'landing' page for client-side.
 *   - Wrapped in a Customer Context to handle persistent data across Tab navigation.
 *   - Only renders after the context has loaded and set the customer info within context state.
 */
export default function Customer() {
  const { state, updateState, loadCustomer } = useCustomerClientContext();
  const [mergeState, setMergeState] = useState<IMergeState>({
    showMergeModal: false,
    selectedCustomer: null,
    customerSearchResults: [],
    customerSearchQuery: "",
    customerSearching: false,

    showWarning: false,
  });
  const { Option } = Select;
  const dispatch = useAppDispatch();

  const [preferencesVisible, setPreferencesVisible] = useState(false);
  const [resetPasswordVisible, setResetPasswordVisible] = useState(false);
  const [prevPreferences, setPrevPreferences] = useState<ICustomerPreferences>(undefined); // Default preferences on load

  useEffect(() => {
    if (state.preferences) {
      setPrevPreferences({ ...state.preferences });
    }
  }, [state?.customer?.id]);

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const source = axios.CancelToken.source();
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (async () => {
          if (mergeState.customerSearchQuery !== "") {
            try {
              setMergeState(prevState => ({ ...prevState, customerSearching: true }));
              const customers = await searchCustomer(source.token);
              if (mounted) {
                setMergeState(prevState => ({
                  ...prevState,
                  customerSearchResults: customers,
                  customerSearching: false,
                }));
              }
            } catch (error) {
              console.log("err", error);
            }
            return;
          } else {
            setMergeState(prevState => ({ ...prevState, customerSearchResults: [], customerSearching: false }));
          }
        })();
      }, 1000);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      source.cancel();
    };
  }, [mergeState.customerSearchQuery]);

  async function searchCustomer(cancelToken: CancelToken) {
    const customerRes = await GetCustomer({ search: mergeState.customerSearchQuery }, false, cancelToken);
    if (customerRes.status !== StatusCode.OK) {
      return [];
    }
    const filteredCustomers = customerRes?.data?.filter((customer: ICustomer) => customer?.id !== state?.customer?.id);
    return filteredCustomers;
  }

  async function saveCustomerImage(imageFile: File) {
    const formData = new FormData();

    formData.append("profile_image", imageFile);
    formData.append("id", state.customer.id.toString());

    const imageRes = await UploadCustomerProfileImage(formData, true);
    if (imageRes.status !== StatusCode.OK) {
      return;
    }

    console.log(imageRes.data); //debug

    const tempCustomer: ICustomer = { ...state.customer, ...imageRes.data };
    updateState({ customer: tempCustomer });
  }

  async function deleteCustomerImage() {
    const imageRes = await DeleteCustomerProfileImage({ id: state.customer.id }, true);
    if (imageRes.status !== StatusCode.OK) {
      return;
    }

    console.log("imageUpdate", imageRes.data); //debug

    const tempCustomer: ICustomer = { ...state.customer, ...imageRes.data };
    updateState({ customer: tempCustomer });
  }

  function closeMergeAccounts() {
    setMergeState(prevState => ({
      ...prevState,
      showMergeModal: false,
      customerSearchQuery: "",
      customerSearchResults: [],
      selectedCustomer: null,
      showWarning: false,
    }));
  }

  /** Called after POPUP to reset customer password */
  async function sendPasswordReset() {
    const params = {
      email: state.customer.email,
    };

    const sendPasswordResetRes = await SendPasswordReset(params, true);

    if (sendPasswordResetRes?.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.customer.tabs.profile.Customer_Profile.043")));
      return;
    }

    setResetPasswordVisible(false);
    dispatch(showSuccess(t("secure.facility.customer.tabs.profile.Customer_Profile.044")));
  }

  async function handleSavePreferences() {
    const preferencesRes = await PutCustomerPreferences(
      {
        ...state.preferences,
      },
      true,
    );

    if (preferencesRes.status !== StatusCode.OK) {
      dispatch(showError("Error saving preferences"));
      return;
    }

    ReactDOM.unstable_batchedUpdates(() => {
      setPreferencesVisible(false);
      setPrevPreferences({ ...state.preferences });
    });
    dispatch(showSuccess("Preferences saved successfully"));
  }

  function handleCustomerSearch(query: string) {
    setMergeState(prevState => ({ ...prevState, customerSearchQuery: query }));
  }

  function handleCustomerSelection(customer: ICustomer) {
    setMergeState(prevState => ({ ...prevState, selectedCustomer: customer }));
  }

  function handleToggleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, checked } = event.target;
    updateState({ preferences: { ...state.preferences, [id]: checked } });
  }

  async function handleCustomerMerge() {
    const mergeRes = await PutMergeCustomers(
      { parent_id: state?.customer?.id, child_id: mergeState?.selectedCustomer?.id },
      true,
    );
    if (mergeRes?.status !== StatusCode.OK) {
      dispatch(showError("Error merging customer accounts"));
      return;
    }
    dispatch(showSuccess("Successfully merged customer accounts"));
    void closeMergeAccounts();
    await loadCustomer(state?.customer?.id);
  }

  function renderCustomerStatsGroup(screenSize: "desktop" | "mobile") {
    // if (state.bookingCounts.length <= 0) {
    //   return null;
    // }
    return (
      <div className={"booking-stats-general"}>
        <div className={`customer-stats-group-${screenSize}`}>
          {screenSize === "desktop" && <span className="customer-stats-group-desktop-empty-space" />}
          <div className={`customer-dropdown-${screenSize}`}>
            <NavigationDropdownNew
              rightAlign
              label={"Settings"}
              sections={[
                [
                  {
                    label: "Customer Preferences",
                    onClick: () => setPreferencesVisible(true),
                    icon: "eye",
                  },
                ],
                [
                  {
                    label: "Password Reset",
                    onClick: () => setResetPasswordVisible(true),
                    icon: "lock-a",
                  },
                ],
                [
                  {
                    label: "Merge Customer",
                    onClick: () => setMergeState(prevState => ({ ...prevState, showMergeModal: true })),
                    icon: "copy",
                  },
                ],
              ]}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {state.customer && (
        <>
          <div>
            <div className="customer_stats_bar">
              <div className="stats_bar-picture">
                <CustomerImage
                  imageSource={state.customer.profile_image_source}
                  saveProfileImage={imageFile => saveCustomerImage(imageFile)}
                  deleteProfileImage={deleteCustomerImage}
                />
              </div>

              <div className="stats-general">
                <div className="customer-name-container">
                  <h1 className="display-md text-semibold">{state.customer.full_name}</h1>
                  <div className={`customer-dropdown-mobile`}>
                    <NavigationDropdownNew
                      rightAlign
                      label={"Settings"}
                      sections={[
                        [
                          {
                            label: "Customer Preferences",
                            onClick: () => setPreferencesVisible(true),
                            icon: "eye",
                          },
                        ],
                        [
                          {
                            label: "Password Reset",
                            onClick: () => setResetPasswordVisible(true),
                            icon: "lock-a",
                          },
                        ],
                        [
                          {
                            label: "Merge Customer",
                            onClick: () => setMergeState(prevState => ({ ...prevState, showMergeModal: true })),
                            icon: "copy",
                          },
                        ],
                      ]}
                    />
                  </div>
                </div>
                <span className="customer-types">
                  <p className="customer-membership">{state.customer.customer_type}</p>
                  <p className="customer-membership">{state.customer.cart_customer_type}</p>
                </span>

                <div className="customer-contact">
                  {state.customer.email ? (
                    <div>{state.customer.email}</div>
                  ) : (
                    <div className="field-unavailable">No Email On File</div>
                  )}
                </div>
                <div className="customer-contact-phone-number">
                  {state.customer.phone ? (
                    <p>{state.customer.phone}</p>
                  ) : (
                    <p className="field-unavailable" style={{ marginLeft: "0px" }}>
                      No Phone Number
                    </p>
                  )}
                </div>
              </div>
              {renderCustomerStatsGroup("desktop")}
            </div>
          </div>

          <CustomerClientTabs customerId={state.customer.id} />
        </>
      )}

      <Sheet
        title={"Merge Customer Accounts"}
        open={mergeState.showMergeModal}
        size="small"
        closable
        onCancel={closeMergeAccounts}
        onOk={() => setMergeState(prevState => ({ ...prevState, showWarning: true }))}
        okText={"Continue"}
        okDisabled={!mergeState.selectedCustomer ? true : false}
        overflow
      >
        <span className="merge-customer-title">{"Customer To Merge"}</span>
        {mergeState.selectedCustomer ? (
          <GolferCard
            closable
            removeGolfer={() =>
              setMergeState(prevState => ({
                ...prevState,
                selectedCustomer: null,
                customerSearchResults: [],
                customerSearchQuery: "",
              }))
            }
            email={mergeState?.selectedCustomer?.email}
            name={mergeState?.selectedCustomer?.full_name}
            memberCode={mergeState?.selectedCustomer?.member_code}
            customerType={mergeState?.selectedCustomer?.customer_type}
            phone={mergeState?.selectedCustomer?.phone}
          />
        ) : (
          <>
            <Select
              showSearch
              onSearch={(query: string) => handleCustomerSearch(query)}
              onChange={(id: number, customer: ICustomer) => handleCustomerSelection(customer)}
              placeholder={"Search..."}
              allowClear
              searchValue={mergeState.customerSearchQuery}
              showDropDownOnFocus={true}
              searching={mergeState.customerSearching}
              noData={
                <div className="ui-select-dropdown-list-item">
                  <span>No users found...</span>
                  <FontAwesomeIcon style={{ marginLeft: "5px" }} icon={["far", "users-slash"]} size="1x" />
                </div>
              }
            >
              {mergeState.customerSearchResults?.map((customer, index) => {
                return (
                  <Option key={index} value={customer.id} name={customer.full_name} extraValues={customer}>
                    <div className="flex justify-between">
                      <div>
                        <div className="text-semibold text-lg">{customer?.full_name}</div>
                        <div className="text-sm text-gray-500">{customer.customer_type}</div>
                        <div className="text-sm text-gray-500">{customer.email}</div>
                        <div className="text-sm text-gray-500">{customer.phone ? customer.phone : null}</div>
                      </div>

                      <div className="font-medium text-base text-gray-500 self-end">{customer.member_code}</div>
                    </div>
                  </Option>
                );
              })}
            </Select>
          </>
        )}
        {state.customer !== null ? (
          <div>
            <FontAwesomeIcon className="merge-customer-arrow" icon={["far", "arrow-circle-down"]} size="2x" />
            <span className="merge-customer-title">{"Current Customer"}</span>
            <GolferCard
              email={state.customer?.email}
              name={state.customer?.full_name}
              memberCode={state.customer?.member_code}
              customerType={state.customer?.customer_type}
              phone={state.customer?.phone}
            />
          </div>
        ) : null}
      </Sheet>

      <Popup
        open={mergeState?.showWarning}
        type={"warning"}
        stacked
        title={"Merge Customers?"}
        onCancel={() => setMergeState(prevState => ({ ...prevState, showWarning: false }))}
        cancelText={"Cancel"}
        okText="Merge"
        onOk={handleCustomerMerge}
        description={`The account of ${mergeState?.selectedCustomer?.full_name} will be merged into the account of 
        ${state?.customer?.full_name}. All gift cards, tickets, and accounts will be merged. 
        This action cannot be undone. Are you sure you want to continue with this merge?
        `}
      />

      {/* RESET PASSWORD POPUP */}
      <Popup
        open={resetPasswordVisible}
        title={"Reset Password?"}
        description={"An email will be sent to reset the customer's password"}
        type="info"
        onCancel={() => setResetPasswordVisible(false)}
        onOk={sendPasswordReset}
      />

      {/* CUSTOMER PREFERENCES SHEET */}
      <Sheet
        open={preferencesVisible}
        size="medium"
        closable
        title={"Customer Preferences"}
        onCancel={() => {
          setPreferencesVisible(false);
          updateState({ preferences: prevPreferences ? prevPreferences : state.preferences });
        }}
        // onOk={handleSavePreferences}
        // okText="Save"
        overflow
      >
        <div>
          <div>
            <span className="portal-preferences-header">Notifications Settings</span>
            <p className="text-subdued">
              {
                "We may still send you important notifications about your account outside of your normal notification settings"
              }
            </p>
          </div>
          <div className="portal-preferences-divider" />
          <PreferenceCard
            title="Marketing Notifications"
            description="Information about upcoming promotions"
            handleToggleChange={handleToggleChange}
            options={[
              {
                label: "Email",
                id: "marketing_email",
                checked: state.preferences?.marketing_email,
                disabled: true,
              },
            ]}
          />
          <PreferenceCard
            title="Booking Confirmations"
            description="Notifications when you create a new booking"
            handleToggleChange={handleToggleChange}
            options={[
              {
                label: "Email",
                id: "booking_confirmation_email",
                checked: state.preferences?.booking_confirmation_email,
                disabled: true,
              },
            ]}
          />
          <PreferenceCard
            title="Booking Reminders"
            description="Notifications when you have an upcoming booking"
            handleToggleChange={handleToggleChange}
            options={[
              {
                label: "Email",
                id: "booking_reminder_email",
                checked: state.preferences?.booking_reminder_email,
                disabled: true,
              },
            ]}
          />
          <PreferenceCard
            title="Booking Updates"
            description="Notifications when you update an upcoming booking"
            handleToggleChange={handleToggleChange}
            options={[
              {
                label: "Email",
                id: "booking_update_email",
                checked: state.preferences?.booking_update_email,
                disabled: true,
              },
            ]}
          />
          <PreferenceCard
            title="Booking Cancellations"
            description="Notifications when you cancel an upcoming booking"
            handleToggleChange={handleToggleChange}
            options={[
              {
                label: "Email",
                id: "booking_cancellation_email",
                checked: state.preferences?.booking_cancellation_email,
                disabled: true,
              },
            ]}
          />
        </div>
      </Sheet>
    </>
  );
}
