import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IProductOverride } from "pages/secure/facility/settings/modifierGroup/ModifierGroupEdit";
import React from "react";
import { useDrag } from "react-dnd";
import { IVariant } from "redux/reducers/models/product";
import { VariantCardsItemTypes } from "../VariantCards";
import "./variantCard.scss";

interface IVariantCardProps {
  variant: IVariant;
  isSelected: boolean;
  isPositionInASelectedRow: (position: number) => boolean;
  selectVariant: (event: any, variantId: number) => void;
  onRemoveVariant: (id: number) => void;
  productOverrides: IProductOverride[];
  isDefault: boolean;
}

export interface IVariantCardItem {
  id: number;
}

export default function VariantCard(props: IVariantCardProps) {
  const { variant, isSelected, isPositionInASelectedRow, selectVariant, onRemoveVariant, productOverrides, isDefault } =
    props;

  const item: IVariantCardItem = {
    id: variant.id,
  };

  const [{ isDragging }, drag] = useDrag(() => {
    return {
      item: item,
      type: VariantCardsItemTypes.VARIANT_CARD,
      collect: monitor => ({
        isDragging: !!monitor.isDragging(),
      }),
    };
  }, []);

  function getVariantCardColor(): React.CSSProperties {
    if (isPositionInASelectedRow(variant.modifier_group?.position)) {
      return { backgroundColor: "blue" };
    } else {
      const overridenProduct = productOverrides.find(productOverride => productOverride.id === variant?.product_id);

      if (overridenProduct) {
        return { backgroundColor: overridenProduct.button_color };
      } else {
        return variant?.product?.meta?.button_color ? { backgroundColor: variant.product.meta.button_color } : {};
      }
    }
  }

  return (
    <div className="variant-card-container" ref={drag}>
      <div
        className="variant-card"
        style={{
          ...getVariantCardColor(),
          opacity: isSelected ? 0.7 : 1,
          cursor: isDragging ? "grabbing" : "grab",
        }}
        onClick={e => selectVariant(e, variant.id)}
      >
        <div className="variant-card-title">
          {variant?.product?.meta?.preferred_title ? variant?.product?.meta?.preferred_title : variant?.product?.title}
        </div>
        <FontAwesomeIcon
          onClick={() => onRemoveVariant(variant.id)}
          className="variant-card-remove-icon"
          icon={["far", "xmark"]}
        />
        {isDefault ? <FontAwesomeIcon className="variant-card-default-icon" icon={["far", "check-circle"]} /> : null}
      </div>
    </div>
  );
}
