import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import KitchenDisplaySystem from "pages/secure/facility/settings/kitchen/KitchenDisplaySystem";

import * as CartActions from "redux/actions/cart";
import * as OrderActions from "redux/actions/order";
import * as CustomerActions from "redux/actions/customer";
import * as TerminalActions from "redux/actions/facilityAdmin/terminal";
import * as UIActions from "redux/actions/ui";

const mapStateToProps = (state: any) => {
  return {
    authStore: { ...state.authStore },
    cartStore: { ...state.cartStore },
    customerStore: { ...state.customerStore },
    orderStore: { ...state.orderStore },
    terminalStore: { ...state.terminalStore },
    uiStore: { ...state.uiStore },
    uiActions: { ...state.uiActions },
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    cartActions: bindActionCreators(
      {
        ...CartActions,
      },
      dispatch,
    ),
    orderActions: bindActionCreators(
      {
        ...OrderActions,
      },
      dispatch,
    ),
    customerActions: bindActionCreators(
      {
        ...CustomerActions,
      },
      dispatch,
    ),
    terminalActions: bindActionCreators(
      {
        ...TerminalActions,
      },
      dispatch,
    ),
    UIActions: bindActionCreators(
      {
        ...UIActions,
      },
      dispatch,
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(KitchenDisplaySystem);
