import React from "react";
import Checkbox, { ICheckboxProps } from "components/form/checkbox/Checkbox";

import "./checkboxBlock.scss";

type CheckboxBlockProps = Omit<ICheckboxProps, "onChange"> & {
  label: string;
  checked: boolean;
  onChange: () => void;
  className?: string;
};

/**
 * Styling for checkboxes used across Custom Forms
 */
export default function CheckboxBlock(props: CheckboxBlockProps) {
  const { disabled, ...rest } = props;

  function handleWrapClick(e: React.MouseEvent<HTMLDivElement>) {
    if (e.target == e.currentTarget) {
      props.onChange();
    }
  }

  return (
    <div
      className={`checkbox-block${props.checked ? " selected" : ""}${props.className ? ` ${props.className}` : ""}${
        disabled ? " disabled" : ""
      }`}
      onClick={handleWrapClick}
    >
      <Checkbox size="small" ariaChecked={props.checked} {...rest} />
    </div>
  );
}
