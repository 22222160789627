import { APIGet, APIPost, APIPut, APIDelete, StatusCode } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2022-09";
const adminType = "customer";
const baseReservationsUrl = "/" + apiVersion + "/" + adminType + "/reservation";

interface IGetReservationSegment {
  id?: number;
  module_id?: number;
  date?: string;
  start_time?: string;
  location_id?: number;
}

export async function GetCustomerReservationSegment(
  params: IGetReservationSegment,
  useGlobalLoader: boolean,
  cancelToken?: CancelToken,
) {
  return await APIGet(baseReservationsUrl + "/segment", params, useGlobalLoader, cancelToken);
}
