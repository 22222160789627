import React, { useEffect, useState } from "react";
import classNames from "classnames";

import "components/button/button.scss";

const ButtonGroup = (props: any) => (
  <div
    className={classNames("ui-button-group", {
      "ui-button-group-left": props.left,
      "ui-button-group-right": props.right,
      "ui-button-group_segmented": props.segmented,
      "ui-button-group_block": props.block,
      "ui-button-group-spacing_extra-tight": props.spacing === "extraTight",
      "ui-button-group-spacing_tight": props.spacing === "tight",
      "ui-button-group-spacing_loose": props.spacing === "loose",
    })}
  >
    {React.Children.map(props.children, child => {
      return <div className="ui-button-group_item">{child}</div>;
    })}
  </div>
);

export default ButtonGroup;
