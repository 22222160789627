import { APIGet, APIPost, APIPut, APIDelete, IAPIResponse } from "api/protocols";
import { CancelToken } from "axios";
import { ITournament } from "redux/reducers/models/tournament";

const apiVersion = "2024-04";
const adminType = "guest";
const baseTournamentUrl = "/" + apiVersion + "/" + adminType + "/tournament";

export type TGetTournamentRegistrationFee = {
  facility_id: string | number;
  tournament_id: string | number;
};

export async function GetTournamentRegistrationFee(
  params: TGetTournamentRegistrationFee,
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseTournamentUrl + "/registration-fee", params, useGlobalLoader, token);
}
