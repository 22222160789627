import { BookingEngineActionTypes } from "redux/actions/actionTypes";
import { AnyStyledComponent } from "styled-components";
import { ICart } from "./models/cart";
import { IBooking, IBookingCategory, ITeeTime } from "./models/teetime";

export interface IBookingEngine {
  /** given with extended call on GET. */
  booking_categories?: IBookingCategory[];
  booking_terms: string;
  booking_window: {
    booking_engine_id: number;
    days_in_advance_end: number;
    days_in_advance_start: number;
    id: number;
    open_structure: string;
    open_time: string;
    title: string;
  };
  courses: Array<number>;
  default: boolean;
  display: string;
  enabled_payment_methods: Array<string>;
  facilities: Array<number>;
  facility_id: number;
  handle: string;
  id: number;
  locked: boolean;
  payment_terms: string;
  title: string;
}

export interface IBookingEngineGuest extends IBookingEngine {
  facility: {
    address_line_1: string;
    address_line_2: string | null;
    city: string;
    country_code: string;
    country_id: number;
    country_name: string;
    client_id: number;
    customer_email: string;
    email: string;
    emblem_soure_url: string | null;
    full_name: string;
    holes: number;
    id: number;
    logo_source: string;
    long_name: string;
    phone: string;
    postal: string;
    primary_locale: string;
    province_code: string;
    province_id: number;
    province_name: string;
    short_name: string;
    stripe_location_id: string | null;
    module_alpha: boolean;
  };
  long_name: string;
  requires_login: number;
}

export interface IBookingEngineAction {
  type: BookingEngineActionTypes;
  payload: Partial<IBookingEngineState>;
}

export interface IBookingEngineBooking extends IBooking {
  cart?: ICart;
}

export interface IBookingEngineState {
  //Course info
  selectedAddress: any;
  selectedFullAddress: any;
  selectedCity: any;
  selectedCourseName: any;
  selectedCourseFacility: any;
  selectedCourseLongName: string;
  selectedPostalCode: any;
  selectedProvince: any;
  selectedFormattedDate: any;
  selectedDate: any;
  selectedCourseLogo: any;
  selectedHeaderBackground: any;
  selectedFacilityId: any;
  selectedCourseFullName: any;
  courseLogo: any;

  //TeeTime Info
  selectedOnlyNineHoles: any;
  selectedGolferSpotsRemaining: number;
  selectedStartTime: any;
  selectedStartHole: any;
  selectedTeeTimeId: any;
  selectedHoleAmount: any;
  selectedLockoutDuration: any;
  selectedLockoutStartTime: any;
  selectedLockoutExpireTime: any;
  cart_rule: any;
  selectedTeeTime: ITeeTime;

  //Booking Info
  selectedToken: any;
  selectedGolferAmount: any;
  selectedCartAmount: any;
  selectedLoginRequired: number;
  selectedHoleDescription: any;
  selectedCartDescription: any;
  selectedGolferDescription: any;
  selectedConfirmationNumber: any;
  selectedSubTotal: any;
  selectedTotal: any;
  selectedTax: any;
  selectedCartItemPrice: any;
  selectedCartItems: any;
  selectedCartId: any;
  selectedBookingEngineId: any;
  selectedBookingTerms: any;
  teeTimeSlots: any;
  activeCustomerId: number;
  availableSlots: any;
  golferNames: any;
  facilityClientId: any;
  creditCardRequired: any;
  prepaidRequired: any;
  paymentTerms: string;
  bookingTerms: string;
  acceptTerms: boolean;
  cartsAvailable: boolean;
  divisionsAvailable: Array<number>;
  turnTeeTime: Record<string, any>;

  enabled_payment_methods: any;
  enable_cart_bookings: boolean;

  //Sign In Info
  selectedUserFirstName: any;
  selectedUserLastName: string;
  selectedUserEmail: string;
  selectedUserPhone: string;
  userFullName: any;

  postedBooking: IBookingEngineBooking;
  selectedPaymentMethodId: number;

  singleBookings: string;
  twosomeBookings: string;
  threesomeBookings: string;
  foursomeBookings: string;

  display_online_pricing: number;
}

const initialState: IBookingEngineState = {
  selectedAddress: "",
  selectedDate: "",
  selectedFormattedDate: "",
  selectedCity: "",
  selectedCourseName: "",
  selectedCourseFacility: "",
  selectedPostalCode: "",
  selectedProvince: "",
  selectedToken: "",
  selectedGolferAmount: "",
  selectedCartAmount: "",
  selectedUserFirstName: "",
  selectedUserLastName: "",
  selectedUserEmail: "",
  selectedUserPhone: "",
  selectedOnlyNineHoles: null,
  selectedGolferSpotsRemaining: null,
  selectedStartTime: "",
  selectedStartHole: "",
  selectedTeeTimeId: "",
  selectedHoleAmount: "",
  selectedLoginRequired: null,
  selectedCourseFullName: "",
  selectedCourseLongName: "",
  selectedHoleDescription: "",
  selectedGolferDescription: "",
  selectedCartDescription: "",
  selectedFullAddress: "",
  selectedConfirmationNumber: "",
  selectedLockoutDuration: null,
  selectedLockoutStartTime: null,
  selectedLockoutExpireTime: null,
  selectedCourseLogo: "",
  selectedHeaderBackground: "",
  selectedSubTotal: null,
  selectedTax: null,
  selectedTotal: null,
  selectedCartItems: [],
  selectedCartItemPrice: null,
  selectedCartId: "",
  selectedFacilityId: -1,
  selectedBookingEngineId: "",
  selectedBookingTerms: "",
  teeTimeSlots: [],
  availableSlots: [],
  activeCustomerId: null,
  golferNames: [],
  facilityClientId: null,
  creditCardRequired: null,
  prepaidRequired: null,
  userFullName: "",
  paymentTerms: "",
  bookingTerms: "",
  acceptTerms: null,
  enabled_payment_methods: [],
  enable_cart_bookings: null,
  cart_rule: null,
  courseLogo: "",
  cartsAvailable: false,
  divisionsAvailable: [],
  turnTeeTime: null,
  postedBooking: null,
  selectedPaymentMethodId: undefined,
  singleBookings: "",
  twosomeBookings: "",
  threesomeBookings: "",
  foursomeBookings: "",
  selectedTeeTime: null,
  display_online_pricing: null,
};

export default function BookingEngineReducer(state = initialState, action: IBookingEngineAction) {
  switch (action.type) {
    case BookingEngineActionTypes.UPDATE:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
