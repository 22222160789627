import React, { useEffect, useState } from "react";
import { StatusCode } from "api/protocols";
import { showError, showSuccess } from "redux/actions/ui";
import { useGuestPortalContext } from "./GuestPortalContext";
import { useAppDispatch } from "hooks/redux";
import Input from "components/form/input";
import { useTranslation } from "react-i18next";
import "./portalPlayingPartners.scss";
import { ButtonNew as Button } from "components/buttonNew";
import Sheet from "components/sheet/Sheet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios, { Cancel, CancelToken } from "axios";
import Popup from "components/popup/Popup";
import { searchHistoryUpdateSearch } from "redux/actions/searchBars/searchHistory";
import Spin from "components/spin/spin";
import ReactDOM from "react-dom";
import {
  DeleteFriend,
  GetCustomerFriends,
  ICustomerFriend,
  PostFriends,
  SearchCustomer,
} from "api/rpc/2024-04/customer/customer";
import "../Customer/ClientPortal/Home/clientPortalHome.scss";
import Panel from "components/panel/Panel";
import { MOBILE_WIDTH } from "helpers/ScreenSizes";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import BottomSheet from "components/bottomSheet/BottomSheet";

interface IPlayingPartners {
  openAddPlayingPartners: boolean;
  newPlayingPartnerQuery: string;
  playingPartnersList: Array<ICustomerFriend>;
  removePlayerWarning: boolean;
  playerToRemove: ICustomerFriend;
  playersToAdd: Array<ICustomerFriend>;
  filterQuery: string;
  playersLoaded: boolean;
}

export default function PortalPlayingPartners() {
  const dispatch = useAppDispatch();
  const { portalState, updatePortalState } = useGuestPortalContext();

  const [searchedPlayers, setSearchedPlayers] = useState([]);

  const [playerState, setPlayerState] = useState<IPlayingPartners>({
    openAddPlayingPartners: false,
    newPlayingPartnerQuery: "",
    playingPartnersList: [],
    playersToAdd: [],
    removePlayerWarning: false,
    playerToRemove: null,
    filterQuery: "",
    playersLoaded: false,
  });

  const windowSize = useWindowSize();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    void loadPlayingPartners();
  }, []);

  async function loadPlayingPartners() {
    const friendsListRes = await GetCustomerFriends(null, false);

    if (friendsListRes.status !== StatusCode.OK) {
      dispatch(showError(friendsListRes.message));

      return;
    }
    setPlayerState(prevState => ({ ...prevState, playingPartnersList: friendsListRes.data, playersLoaded: true }));
  }

  async function handleFriendSelection() {
    const playerIds = [...playerState?.playersToAdd]?.map(player => player?.id);
    const addFriendsRes = await PostFriends({ friend_user_ids: playerIds }, true);

    if (addFriendsRes.status !== StatusCode.OK) {
      dispatch(showError("Error adding playing partners"));
      return;
    }

    setPlayerState(prevState => ({
      ...prevState,
      openAddPlayingPartners: false,
      newPlayingPartnerQuery: "",
      playersToAdd: [],
    }));

    dispatch(showSuccess("Successfully added playing partners"));

    void loadPlayingPartners();
  }

  function closeAddFriendModal() {
    setPlayerState(prevState => ({
      ...prevState,
      openAddPlayingPartners: false,
      selectedNewPlayingPartner: null,
      newPlayingPartnerQuery: "",
      customerSearchResult: [],
      playersToAdd: [],
    }));

    dispatch(searchHistoryUpdateSearch("playing-partners", ""));
  }

  async function searchCustomers(cancelToken: CancelToken) {
    // let searchCustomersRes;
    // const phoneNumberFormats = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(playerState?.newPlayingPartnerQuery);
    // if (
    //   validator.isEmail(playerState?.newPlayingPartnerQuery) ||
    //   validator.isMobilePhone(playerState?.newPlayingPartnerQuery) ||
    //   validator.isNumeric(playerState?.newPlayingPartnerQuery) ||
    //   phoneNumberFormats
    // ) {
    const searchCustomersRes = await SearchCustomer(
      { search: playerState?.newPlayingPartnerQuery },
      false,
      cancelToken,
    );
    // } else {
    //   searchCustomersRes = await SearchCustomer(
    //     { member_search: playerState?.newPlayingPartnerQuery },
    //     false,
    //     cancelToken,
    //   );
    //   searchCustomersRes.data = searchCustomersRes?.data?.filter(
    //     (customer: Record<string, any>) => !customer?.customer_type[0]?.default,
    //   );
    // }

    if (searchCustomersRes.status !== StatusCode.OK) {
      if (cancelToken.reason) {
        return null;
      }
      dispatch(showError("Error finding player"));
      return [];
    }
    return searchCustomersRes.data as Array<ICustomerFriend>;
  }

  useEffect(() => {
    let mounted = true;
    const source = axios.CancelToken.source();
    let timeoutId: NodeJS.Timeout = null;
    if (mounted === true) {
      timeoutId = global.setTimeout(() => {
        if (playerState.newPlayingPartnerQuery) {
          void loadSearchedPlayers(mounted, source.token);
        }
      }, 500);
    }

    return () => {
      mounted = false;
      source.cancel("Cancelled");
      clearTimeout(timeoutId);
      setSearchedPlayers([]);
    };
  }, [playerState.newPlayingPartnerQuery]);

  async function loadSearchedPlayers(mounted: boolean, cancelToken: CancelToken) {
    // loading spinner while waiting for response
    if (searchedPlayers !== null) {
      setSearchedPlayers(null);
    }
    const friendsResults = await searchCustomers(cancelToken);

    //Search request cancelled via Cancel Token
    if (!friendsResults) {
      return;
    }

    const playerList = playerState.playingPartnersList;
    const friendArray = friendsResults?.filter(
      result => !playerList?.some(player => player?.id === result?.id) && result?.id !== portalState?.guest?.id,
    );

    if (friendsResults.length > 0 && friendArray.length === 0) {
      dispatch(showError("Player already added to playing partners"));
    }

    if (friendsResults.length === 0) {
      dispatch(showError("User not found"));
    }

    if (mounted) {
      setSearchedPlayers(friendArray);
    }
  }

  async function removePlayingPartner() {
    const removeFriendRes = await DeleteFriend({ friend_user_id: playerState.playerToRemove.id }, true);

    if (removeFriendRes.status !== StatusCode.OK) {
      //   dispatch(showError("Error removing playing partner"));
      dispatch(showError(removeFriendRes.data));

      return;
    }

    dispatch(showSuccess("Successfully removed playing partner"));
    setPlayerState(prevState => ({ ...prevState, playerToRemove: null, removePlayerWarning: false }));
    void loadPlayingPartners();
  }

  function handleRemovePlayer(player: any) {
    setPlayerState(prevState => ({ ...prevState, playerToRemove: player, removePlayerWarning: true }));
  }

  const secondaryActions = [
    {
      content: "+ Add Players",
      action: () => setPlayerState(prevState => ({ ...prevState, openAddPlayingPartners: true })),
    },
  ];

  function handleAddPlayer(newPlayer: ICustomerFriend) {
    const playerList = playerState.playersToAdd;
    let isAdded = false;
    isAdded = playerList.some(player => {
      if (player.id === newPlayer.id) {
        return true;
      }
    });

    if (isAdded) {
      dispatch(showError("Player already added"));
    } else {
      playerList.push(newPlayer);
      const playerInputId = document.getElementById("addPlayerSearchInput");
      playerInputId.focus();
      ReactDOM.unstable_batchedUpdates(() => {
        setPlayerState(prevState => ({ ...prevState, playersToAdd: playerList, newPlayingPartnerQuery: "" }));
        setSearchedPlayers([]);
      });
    }
  }

  function handleRemoveSelectedPlayer(removePlayer: ICustomerFriend) {
    const playerList = playerState.playersToAdd;

    const updatePlayerList = playerList.filter(player => player?.id !== removePlayer.id);

    setPlayerState(prevState => ({ ...prevState, playersToAdd: updatePlayerList }));
  }

  function playerMatchesSearchQuery(player: ICustomerFriend) {
    const upperCasedSearchQuery = playerState?.filterQuery?.toUpperCase();
    const upperCasedFullName = player.full_name.toUpperCase();
    return upperCasedFullName.indexOf(upperCasedSearchQuery) > -1;
  }

  function handleFilterPlayer(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    setPlayerState(prevState => ({ ...prevState, filterQuery: value }));
  }

  return (
    <div className="playing-partners-container">
      <div className="flex justify-between mt-4 items-center">
        <div className="playing-partners-title">Playing Partners</div>

        <div>
          {" "}
          <Button
            type="default"
            block
            onClick={() => setPlayerState(prevState => ({ ...prevState, openAddPlayingPartners: true }))}
          >
            <FontAwesomeIcon icon={["far", "plus"]} /> &nbsp; Add Players
          </Button>
        </div>
      </div>

      <div className="playing-partners-search-container">
        <div className="playing-partners-search-input">
          <Input
            label={"Search"}
            placeholder={"Search players"}
            type="search"
            onChange={handleFilterPlayer}
            value={playerState.filterQuery}
          />
        </div>
      </div>

      <div className="playing-partners-list-container">
        <div className="playing-partners-player-list-title">Playing Partners</div>

        {playerState.playersLoaded ? (
          <div className="playing-partners-player-list-container">
            {playerState?.playingPartnersList?.filter(playerMatchesSearchQuery).map((player: any, index: number) => {
              return (
                <div key={index} className="playing-partners-player-container">
                  <div className="playing-partners-player-container-left-side">
                    <div className="playing-partners-profile-image_container">
                      <div className="client-portal-profile-default-image">
                        <p>
                          {player?.first_name?.charAt(0)?.toUpperCase()}
                          {player?.last_name?.charAt(0)?.toUpperCase()}
                        </p>
                      </div>
                    </div>

                    <div>
                      <p className="playing-partners-player-container-player-name"> {player.full_name}</p>
                      <p className="playing-partners-player-container-player-email"> {player.email}</p>
                    </div>
                  </div>

                  <div className="playing-partners-player-container-right-side">
                    <button
                      onClick={() => handleRemovePlayer(player)}
                      className="playing-partners-player-container-remove-button"
                    >
                      Remove
                    </button>{" "}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="playing-partners-player-list-container">
            <span style={{ display: "flex", justifyContent: "center", marginTop: "5px" }}>
              <Spin />
            </span>
          </div>
        )}
      </div>

      <Panel
        open={playerState.openAddPlayingPartners && windowSize.width < MOBILE_WIDTH}
        onClose={closeAddFriendModal}
        title={"Add Playing Partners"}
        animate
        primaryAction={{
          onClick: handleFriendSelection,
          label: "Add Player",
          disabled: playerState.playersToAdd.length === 0,
        }}
        secondaryAction={{
          onClick: closeAddFriendModal,
          label: "Cancel",
        }}
      >
        <div>
          <div>
            <div className="playing-partners-add-player-icon-circle">
              <div>
                <FontAwesomeIcon icon={["far", "user-plus"]} style={{ color: "#2D4675" }} />
              </div>
            </div>
          </div>

          <div className="playing-partners-add-player-text-container">
            <h1>Add Player</h1>
            <p>Search for a playing partner using an email or phone number</p>
          </div>

          <div className="py-3">
            <Input
              labelHidden
              placeholder={"Search by email or phone number..."}
              type="search"
              onChange={e =>
                setPlayerState(prevState => ({ ...prevState, newPlayingPartnerQuery: e.target?.value }))
              }
              value={playerState.newPlayingPartnerQuery}
              id="addPlayerSearchInput"
            />
          </div>
        </div>

        {playerState?.playersToAdd?.length > 0 ? (
          <div className="playing-partners-players-to-add-container">
            <h1>Add to list</h1>
            <p className="playing-partners-add-list-subtext">
              The following users will be added to your playing partner&apos;s list:
            </p>
            <div>
              {playerState.playersToAdd?.map((player: any, index: number) => {
                return (
                  <div key={index} style={{ margin: "10px 0px" }}>
                    <div key={index} className="playing-partners-add-player-container">
                      <div className="playing-partners-player-container-left-side">
                        <div className="playing-partners-profile-image_container">
                          <div className="client-portal-profile-default-image">
                            <p>
                              {player?.first_name?.charAt(0)?.toUpperCase()}
                              {player?.last_name?.charAt(0)?.toUpperCase()}
                            </p>
                          </div>
                        </div>

                        <div>
                          <p className="playing-partners-add-player-name"> {player.full_name}</p>
                          <p className="playing-partners-add-player-email"> {player.email}</p>
                        </div>
                      </div>

                      <div className="playing-partners-player-container-right-side">
                        <button
                          onClick={() => handleRemoveSelectedPlayer(player)}
                          className="playing-partners-player-container-remove-button"
                        >
                          Remove
                        </button>{" "}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}

        {searchedPlayers && searchedPlayers?.length > 0 ? (
          <div className="playing-partners-searched-players-container">
            <h1>Players</h1>

            <div>
              {searchedPlayers?.map((player: any, index: number) => {
                return (
                  <div key={index} style={{ margin: "10px 0px" }}>
                    <div
                      className="playing-partners-player-container-left-side playing-partners-player-container-add"
                      onClick={() => handleAddPlayer(player)}
                    >
                      <div className="playing-partners-profile-image_container">
                        <div className="client-portal-profile-default-image">
                          <p>
                            {player?.first_name?.charAt(0)?.toUpperCase()}
                            {player?.last_name?.charAt(0)?.toUpperCase()}
                          </p>
                        </div>
                      </div>

                      <div>
                        <p className="playing-partners-add-player-name"> {player.full_name}</p>
                        <p className="playing-partners-add-player-email"> {player.email}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : !searchedPlayers ? (
          <span style={{ display: "flex", justifyContent: "center", marginTop: "5px" }}>
            <Spin />
          </span>
        ) : null}
      </Panel>

      <Sheet
        open={playerState.openAddPlayingPartners && windowSize.width > MOBILE_WIDTH}
        size="small"
        height="flexible"
        closable
        onCancel={closeAddFriendModal}
        onOk={handleFriendSelection}
        cancelText={"Cancel"}
        okText={"Add players"}
        okDisabled={playerState.playersToAdd.length === 0}
      >
        <div>
          <div>
            <div className="playing-partners-add-player-icon-circle">
              <div>
                <FontAwesomeIcon icon={["far", "user-plus"]} style={{ color: "#2D4675" }} />
              </div>
            </div>
          </div>

          <div className="playing-partners-add-player-text-container">
            <h1>Add Player</h1>
            <p>Search for a playing partner using an email or phone number</p>
          </div>

          <div className="py-3">
            <Input
              labelHidden
              placeholder={"Search by email or phone number..."}
              type="search"
              onChange={e =>
                setPlayerState(prevState => ({ ...prevState, newPlayingPartnerQuery: e.target?.value }))
              }
              value={playerState.newPlayingPartnerQuery}
              id="addPlayerSearchInput"
            />
          </div>
        </div>

        {playerState?.playersToAdd?.length > 0 ? (
          <div className="playing-partners-players-to-add-container">
            <h1>Add to list</h1>
            <p className="playing-partners-add-list-subtext">
              The following users will be added to your playing partner&apos;s list:
            </p>
            <div>
              {playerState.playersToAdd?.map((player: any, index: number) => {
                return (
                  <div key={index} style={{ margin: "10px 0px" }}>
                    <div key={index} className="playing-partners-add-player-container">
                      <div className="playing-partners-player-container-left-side">
                        <div className="playing-partners-profile-image_container">
                          <div className="client-portal-profile-default-image">
                            <p>
                              {player?.first_name?.charAt(0)?.toUpperCase()}
                              {player?.last_name?.charAt(0)?.toUpperCase()}
                            </p>
                          </div>
                        </div>

                        <div>
                          <p className="playing-partners-add-player-name"> {player.full_name}</p>
                          <p className="playing-partners-add-player-email"> {player.email}</p>
                        </div>
                      </div>

                      <div className="playing-partners-player-container-right-side">
                        <button
                          onClick={() => handleRemoveSelectedPlayer(player)}
                          className="playing-partners-player-container-remove-button"
                        >
                          Remove
                        </button>{" "}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}

        {searchedPlayers && searchedPlayers?.length > 0 ? (
          <div className="playing-partners-searched-players-container">
            <h1>Players</h1>

            <div>
              {searchedPlayers?.map((player: any, index: number) => {
                return (
                  <div key={index} style={{ margin: "10px 0px" }}>
                    <div
                      className="playing-partners-player-container-left-side playing-partners-player-container-add"
                      onClick={() => handleAddPlayer(player)}
                    >
                      <div className="playing-partners-profile-image_container">
                        <div className="client-portal-profile-default-image">
                          <p>
                            {player?.first_name?.charAt(0)?.toUpperCase()}
                            {player?.last_name?.charAt(0)?.toUpperCase()}
                          </p>
                        </div>
                      </div>

                      <div>
                        <p className="playing-partners-add-player-name"> {player.full_name}</p>
                        <p className="playing-partners-add-player-email"> {player.email}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : !searchedPlayers ? (
          <span style={{ display: "flex", justifyContent: "center", marginTop: "5px" }}>
            <Spin />
          </span>
        ) : null}
      </Sheet>

      <Popup
        open={playerState.removePlayerWarning && windowSize.width > MOBILE_WIDTH}
        type="warning"
        title={"Remove Playing Partner"}
        description={"Are you sure you want to remove this playing partner?"}
        onCancel={() =>
          setPlayerState(prevState => ({ ...prevState, removePlayerWarning: false, playerToRemove: null }))
        }
        onOk={removePlayingPartner}
        okText="Remove"
      />

      <BottomSheet
        open={playerState.removePlayerWarning && windowSize.width <= MOBILE_WIDTH}
        onClose={() =>
          setPlayerState(prevState => ({ ...prevState, removePlayerWarning: false, playerToRemove: null }))
        }
        type="warning"
        content="Are you sure you want to remove this playing partner?"
        primaryAction={{ label: "Remove", onClick: removePlayingPartner }}
      />
    </div>
  );
}
