import React, { useEffect, useState, useCallback } from "react";
import { useHistory, Link } from "react-router-dom";
import { useParams } from "react-router";

import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import { ButtonNew as Button } from "components/buttonNew";
import ResourceList from "components/resourceList/ResourceList";
import ResourceItem from "components/resourceList/ResourceItem";
import TextStyle from "components/text/textStyle/TextStyle";
import Stack from "components/stack/Stack";
import Checkbox from "components/form/checkbox/Checkbox";
import Category from "elements/settings/Category";
import { GetLeague } from "api/rpc/clientAdmin/league/league";

export default function Settings(props: any) {
  const history = useHistory();

  const { leagueId }: any = useParams();
  const [state, setState] = useState({
    league: null,
  });

  useEffect(() => {
    void loadLeague();
  }, []);

  async function loadLeague() {
    const leagueRes = await GetLeague(null, false);
    if (leagueRes.status === StatusCode.OK) {
      setState(prevState => ({
        ...prevState,
        league: leagueRes.data[0],
      }));
    }
  }

  const options = [
    {
      label: "General",
      description: "General league information",
      url: "/admin/league/menu/" + String(leagueId) + "/settings/general",
      icon: "home",
    },
    {
      label: "Registration Fees",
      description: "Manage available registration fees",
      url: "/admin/league/menu/" + String(leagueId) + "/settings/registration",
      icon: "money-check-alt",
    },
  ];

  return (
    <Page title="Settings" narrow>
      <Card>
        <Card.Section>
          {options.map((option: any, index: number) => {
            return <Category key={index} label={option.label} url={option.url} />;
          })}
        </Card.Section>
      </Card>
    </Page>
  );
}
