import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { StatusCode } from "api/protocols";

import { displayCurrency, valueToString } from "helpers/Helpers";
import { THIS_MONTH, THIS_YEAR } from "components/form/calendarField/helpers";
import { annualFiltersObj } from "hooks/useOrderFilters/useDropFilters";
import { Badge } from "components/badge/Badge";
import Card from "components/card/Card";
import DropFilter from "components/dropdown/DropFilter";
import Page from "components/page/Page";

import "../facility/facility.scss";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";
import axios, { CancelToken } from "axios";
import { GetPayout } from "api/rpc/2024-04/facilityAdmin/facility/payout";

const TABLE_LIMIT = 25;

export default function Payouts(props: any) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const payoutFilters = annualFiltersObj();
  const [filterState, setFilterState] = useState({
    month: THIS_MONTH,
    year: THIS_YEAR,
    offset: 0,
  });

  const [payoutState, setPayoutState] = useState({ payout: null });

  useEffect(() => {
    const source = axios.CancelToken.source();

    void loadPayouts(source.token);

    return () => {
      source.cancel();
    };
  }, [filterState.month, filterState.year, filterState.offset]);

  async function loadPayouts(token?: CancelToken) {
    console.log("----> API CALL", filterState); //debug

    if (payoutState.payout) {
      setPayoutState(prevState => ({ ...prevState, payout: null }));
    }

    const res = await GetPayout({ ...filterState, limit: TABLE_LIMIT }, token ? false : true, token);

    console.log(res);

    if (token && token.reason) {
      return;
    }

    if (res.status !== StatusCode.OK) {
      setPayoutState(prevState => ({ ...prevState, payout: [] }));

      return;
    }

    setPayoutState(prev => ({
      ...prev,
      payout: res.data,
    }));
  }

  function navigateToViewPayout(url: string) {
    history.push(url);
  }

  function handleApplyFilter(filterReturn: Record<string, any>[], type: string) {
    const tempReturn = filterReturn[0]; //works because of 'restrain' prop on dropfilter

    if (tempReturn === undefined) {
      setFilterState({ ...filterState, [type]: undefined });
      return;
    }

    setFilterState({ ...filterState, [type]: tempReturn.id });
  }

  function handleTableNavigation(direction: "prev" | "next") {
    switch (direction) {
      case "prev": {
        setFilterState(prev => ({ ...prev, offset: filterState.offset - TABLE_LIMIT }));
        return;
      }
      case "next": {
        setFilterState(prev => ({ ...prev, offset: filterState.offset + TABLE_LIMIT }));
        return;
      }
      default:
        return;
    }
  }

  return (
    <Page title={t("secure.facility.settings.payouts.payouts.001")}>
      <div className="flex flex-row flex-wrap gap-4 pb-4">
        <DropFilter
          key="months"
          title="Month"
          filterData={payoutFilters.monthFilters("index")}
          filterLabelPropFromData="label"
          filterIdPropFromData="id"
          filterType="Checkbox"
          applyFilters={filterReturn => handleApplyFilter(filterReturn, "month")}
          defaultCheckboxes={[filterState.month]}
          restrain
          leftAligned
        />

        <DropFilter
          key="year"
          title="Year"
          filterData={payoutFilters.yearFilters()}
          filterLabelPropFromData="label"
          filterIdPropFromData="id"
          filterType="Checkbox"
          applyFilters={filterReturn => handleApplyFilter(filterReturn, "year")}
          defaultCheckboxes={[filterState.year]}
          restrain
        />
      </div>

      <DataTable
        loading={!payoutState?.payout ? true : false}
        columns={[
          { label: "Date" },
          { label: t("secure.facility.settings.payouts.payouts.004") },
          { label: t("secure.facility.settings.payouts.payouts.005") },
          { label: t("secure.facility.settings.payouts.payouts.006") },
          { label: t("secure.facility.settings.payouts.payouts.007") },
          { label: t("secure.facility.settings.payouts.payouts.008") },
          { label: t("secure.facility.settings.payouts.payouts.009") },
          { label: t("secure.facility.settings.payouts.payouts.003") },
        ]}
        footer={{
          tableLimit: TABLE_LIMIT,
          tableOffset: filterState.offset,
          disableNextOffset: !payoutState?.payout || !(payoutState?.payout?.length === TABLE_LIMIT),
          handleTableOffset: direction => handleTableNavigation(direction),
        }}
      >
        {payoutState.payout?.map((payout: any, index: any) => {
          return (
            <tr
              key={index}
              className="clickable"
              onClick={() => navigateToViewPayout("/admin/settings/payouts/" + String(payout.id))}
            >
              <td>{payout.statement_description}</td>
              <td>
                <Badge type="gray">{valueToString(payout.status, "capitalize")}</Badge>
              </td>
              <td>{valueToString(payout.currency, "uppercase")}</td>
              <td>{displayCurrency(payout.currency.toLowerCase(), payout.gross)}</td>
              <td>{displayCurrency(payout.currency.toLowerCase(), payout.fee)}</td>
              <td>{displayCurrency(payout.currency.toLowerCase(), payout.net)}</td>
              <td>{payout.process_date}</td>
              <td>{payout.authorization}</td>
            </tr>
          );
        })}
      </DataTable>
    </Page>
  );
}
