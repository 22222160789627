import React, { ChangeEvent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { GetTeeDecks, PostTeeDeck, PutTeeDeck } from "api/rpc/2022-09/facilityAdmin/facility/teedeck";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Input from "components/form/input/Input";
import FormLayout from "components/form/FormLayout";
import Card from "components/card/Card";
import Form from "components/form/Form";
import { Select } from "components/select/index";
import { IUIActions } from "redux/actions/ui";
import { IFacilityState } from "redux/reducers/facility";

import { useTranslation } from "react-i18next";

interface ITeeDeckProps {
  uiActions: IUIActions;
  facilityStore: IFacilityState;
}

interface ITeeDeck {
  id: number;
  title: string;
  course_id: number;
}

export default function TeeDeckNew(props: ITeeDeckProps) {
  const { teeDeckId } = useParams<{ teeDeckId: string }>();
  const { t, i18n } = useTranslation();
  const { Option } = Select;
  const history = useHistory();
  const courses = props.facilityStore?.facility?.courses ?? [];

  const courseOptions = courses.map((course: any) => {
    return { label: course.full_name, value: course.id };
  });

  const [teeDeckState, setTeeDeckState] = useState<ITeeDeck>({
    id: -1,
    title: "",
    course_id: -1,
  });

  async function createTeeDeck() {
    const putTeeDeckRes = await PostTeeDeck(
      {
        id: teeDeckState.id,
        title: teeDeckState.title,
        course_id: teeDeckState.course_id,
      },
      true,
    );

    if (putTeeDeckRes.status !== StatusCode.OK) {
      props.uiActions.showError(t("secure.facility.settings.facility.tee_deck_new.001"));
      return;
    }

    history.push("/admin/settings/tee_deck");
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setTeeDeckState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleDropDownChange(value: any, property: string) {
    setTeeDeckState(prevState => ({ ...prevState, [property]: value }));
  }

  const primaryAction = {
    content: t("secure.facility.settings.facility.tee_deck_new.002"),
    action: createTeeDeck,
  };

  return (
    <Page
      title={t("secure.facility.settings.facility.tee_deck_new.003")}
      primaryAction={primaryAction}
      narrow
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.facility.tee_deck_new.004"),
          url: "/admin/settings/tee_deck",
        },
      ]}
    >
      {teeDeckState && (
        <Form>
          <Card>
            <Card.Section>
              <FormLayout>
                <FormLayout.Group>
                  <Input
                    value={teeDeckState.title}
                    label={t("secure.facility.settings.facility.tee_deck_new.005")}
                    id="title"
                    onChange={handleInputChange}
                    placeholder={t("secure.facility.settings.facility.tee_deck_new.006")}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  {courseOptions.length > 1 && (
                    <Select
                      defaultValue={teeDeckState.course_id}
                      label={t("secure.facility.settings.tee_sheets.tee_sheet_template_new.030")}
                      onChange={(value: any) => handleDropDownChange(value, "course_id")}
                    >
                      {courseOptions?.map((courseOption: any, index: number) => {
                        return (
                          <Option key={index} name={courseOption.label} value={courseOption.value}>
                            <span>{courseOption.label}</span>
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>
          </Card>
        </Form>
      )}
    </Page>
  );
}
