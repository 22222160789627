import { APIGet, APIPost, APIPut, APIDelete, StatusCode, IAPIResponse } from "api/protocols";
const apiVersion = "2024-04";
const baseCustomerUrl = "/" + apiVersion + "/customer";

interface IPostBookingParams {
  tee_time_id: number;
  holes: number;
  quantity: number;
  power_cart_quantity: number;
  customer_payment_method_id: number;
  accept_payment_terms: boolean;
  cart_token: string;
  order_id?: number;
  booking_fee_order_id: number;
  lock_quantity?: boolean;
  lock_holes?: boolean;
  lock_power_cart_quantity?: boolean;
  customer_ids: Array<number>;
  waitlist_reference_id?: string;
}

export async function PostCustomerBooking(params: IPostBookingParams, useGlobalLoader: boolean) {
  return await APIPost(baseCustomerUrl + "/tee-time/booking", params, useGlobalLoader);
}
