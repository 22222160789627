import { APIGet, APIPost, APIPut, StatusCode } from "api/protocols";

const apiVersion = "2021-01";
const adminType = "facility-admin";
const baseOrderUrl = "/" + apiVersion + "/" + adminType + "/order";

const apiVersionNew = "2022-09";
const baseOrderUrlNew = "/" + apiVersionNew + "/" + adminType + "/order";

interface IGetOrder {
  id?: number;
  search?: string;
  customer_id?: number;
  extended?: boolean;
  refund?: boolean;
  limit?: number;
  offset?: number;
  filters?: Array<Record<string, any>>;
}

// GET Order
// Retrieve a list of orders for a facility
// extended: boolean
export async function GetOrder(params: IGetOrder, useGlobalLoader: boolean) {
  return await APIGet(baseOrderUrlNew, params, useGlobalLoader);
}

interface IPostOrder {
  customer_id: number;
}

interface IPostOrderRes {
  status: StatusCode;
  data: any;
}

// POST Order
// Create a new order
export async function PostOrder(params: IPostOrder, useGlobalLoader: boolean): Promise<IPostOrderRes> {
  return await APIPost(baseOrderUrl, params, useGlobalLoader);
}

// POST Line Items
// Add a new line to an order
interface IPostLineItemsToOrder {
  order_id: number;
  variant_id: number;
  quantity: number;
}

interface IPostLineItemsToOrderRes {
  status: StatusCode;
  data: any;
}

export async function PostLineItemsToOrder(
  params: IPostLineItemsToOrder,
  useGlobalLoader: boolean,
): Promise<IPostLineItemsToOrderRes> {
  return await APIPost(baseOrderUrl + "/line-item", params, useGlobalLoader);
}

interface IPutOrderComplete {
  order_id: number;
  gift_cards: { cart_line_item_id: number; code: string; pin: number; random: boolean }[];
}

interface IPutVoidOrder {
  order_id: number;
}

// PUT Order
// Update some attributes on an order
export async function PutOrder(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseOrderUrlNew, params, useGlobalLoader);
}

export async function GetReceipt(params: { id: number; tee_time_id?: number }, useGlobalLoader?: boolean) {
  return await APIGet(baseOrderUrlNew + "/receipt", params, useGlobalLoader);
}

export async function PutOrderComplete(params: IPutOrderComplete, useGlobalLoader?: boolean) {
  return await APIPut(baseOrderUrl + "/complete", params, useGlobalLoader);
}

export async function PutVoidOrder(params: IPutVoidOrder, useGlobalLoader: boolean) {
  return await APIPut(baseOrderUrl + "/void", params, useGlobalLoader);
}

//Email Order Receipt
export async function EmailReceipt(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseOrderUrlNew + "/receipt/email", params, useGlobalLoader);
}
