import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { PostLeagueDivision, TPostLeagueDivision } from "api/rpc/2024-04/facilityAdmin/league/scoring/division";
import { useAppDispatch } from "hooks/redux";
import { showError, showSuccess } from "redux/actions/ui";

import Page from "components/page/Page";
import Form from "components/form/Form";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";

export default function LeagueDivisionsNew() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { leagueId } = useParams<{ leagueId: string }>();

  const dispatch = useAppDispatch();

  const [division, setDivision] = useState<Omit<TPostLeagueDivision, "league_id">>({
    title: "",
  });

  // TODO: Typesafety
  async function createDivision(params: TPostLeagueDivision) {
    const res = await PostLeagueDivision({ league_id: leagueId, ...params }, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error creating new division")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Successfully created new division")); // TODO: Translation
    history.push(`/admin/league/${leagueId}/divisions`);
  }

  const primaryAction = {
    content: "Create", // TODO: Translation
    action: () => createDivision({ league_id: leagueId, ...division }),
    // disabled: !flightState.name ? true : false,
  };

  return (
    <Page
      title={"New Division"} // TODO: Translation
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: "Divisions", // TODO: Translation
          url: `/admin/league/${leagueId}/divisions`,
        },
      ]}
    >
      <Form>
        <Card title={"Division Details"}>
          {" "}
          {/* TODO: Translation */}
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={division.title ?? ""}
                  label={"Name"} // TODO: Translation
                  id="name"
                  onChange={e => setDivision(prev => ({ ...prev, title: e.target.value }))}
                  placeholder={"Division name"} // TODO: Translation
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>
      </Form>
    </Page>
  );
}
