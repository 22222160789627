import { APIGet, APIPost, APIPut, APIDelete, APIUrl, AdminType } from "api/protocols";
import { CancelToken } from "axios";
import { TTeeOnPaymentAccount, TTeeOnPaymentRepresentative } from "redux/reducers/models/transaction";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/payment/tee-on-payment/account";

export async function GetTeeOnPaymentAccounts(params: any, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<TTeeOnPaymentAccount>(baseUrl, params, useGlobalLoader, token);
}

export async function PutActivateAccount(useGlobalLoader: boolean) {
  return await APIPut(
    APIUrl(AdminType.FacilityAdmin, "/payment/tee-on-payment/account/activate"),
    null,
    useGlobalLoader,
  );
}

export async function PutAcceptTerms(useGlobalLoader: boolean) {
  return await APIPut(APIUrl(AdminType.FacilityAdmin, "/payment/tee-on-payment/account/accept"), null, useGlobalLoader);
}

/* Retrieve tee on payment representative(s) */
export async function GetTeeOnPaymentRepresentative(params: any, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<TTeeOnPaymentRepresentative[]>(
    APIUrl(AdminType.FacilityAdmin, "/payment/tee-on-payment/account/person"),
    params,
    useGlobalLoader,
    token,
  );
}

export type TPostTeeOnPaymentRepresentative = {
  first_name: string;
  last_name: string;
  address_line_1: string;
  city: string;
  postal: string;
  province_id: number;
  country_id: number;
  /** 01 - 31 */
  dob_day: string;
  /** 01 - 12 */
  dob_month: string;
  /** 19** - 20** */
  dob_year: string;
  relationship: {
    director: boolean;
    executive: boolean;
    owner: boolean;
    representative: boolean;
  };
  id_number: string;
};

/* Add a new tee on payment representative */
export async function PostTeeOnPaymentRepresentative(
  params: TPostTeeOnPaymentRepresentative,
  useGlobalLoader: boolean,
) {
  return await APIPost(baseUrl + "/person", params, useGlobalLoader);
}
