import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";

import { showError, showSuccess } from "redux/actions/ui";
import { TTeesheetPricingSheet } from "redux/reducers/models/teesheet";
import { convertTime } from "helpers/Helpers";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Sheet from "components/sheet/Sheet";
import Checkbox from "components/form/checkbox/Checkbox";
import Callout from "components/callout/Callout";
import { Badge } from "components/badge/Badge";
import Input from "components/form/input/Input";
import { Select } from "components/select/index";
import TimePick from "components/timePick/TimePick";
import Portal from "elements/Portal";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

import "../templates/TeeSheetTemplate.scss";
import FormLayout from "components/form/FormLayout";
import {
  GetPricingSheet,
  PostDuplicatePricingSheet,
  PostPricingSheet,
} from "api/rpc/2024-04/facilityAdmin/teesheet/pricingSheet";

export enum Day {
  Holiday = 99,
  Monday = 1,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday,
}

export type DayValue = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 99;

export default function PricingSheets() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const facilityStore = useAppSelector(store => store.facilityStore);
  const dispatch = useAppDispatch();

  const { Option } = Select;

  const [pricingSheets, setPricingSheets] = useState<TTeesheetPricingSheet[]>(undefined);

  const [state, setState] = useState({
    newSheetActive: false,
    title: "",
    day_of_week: t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.001"),
    start_time: "",
    end_time: "",
    course_id: null,
    application: "green_fee",

    pricingSheetIds: [],

    showDuplicateModal: false,
    selectedFacilityId: null,
    selectedDayOfWeek: "",
    showDeleteModal: false,
    selectAllPricingSheets: false,
  });

  const [filterDayState, setFilterDayState] = useState<DayValue>(
    window.sessionStorage.getItem("retainedSelectedDay") === null
      ? Day.Monday
      : (Number(window.sessionStorage.getItem("retainedSelectedDay")) as DayValue),
  );

  useEffect(() => {
    window.sessionStorage.setItem("retainedDayState", String(filterDayState));
  }),
    [filterDayState];

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadPricingSheets(source.token);
    return () => source.cancel();
  }, []);

  async function loadPricingSheets(token?: CancelToken) {
    if (pricingSheets !== undefined) {
      setPricingSheets(undefined);
    }
    const res = await GetPricingSheet(null, token ? false : true, token);
    if (token && token.reason) {
      return;
    }

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading pricing sheets.")); // TODO: Translation
    }

    setPricingSheets(res.status !== StatusCode.OK ? [] : res.data);
  }

  async function handleDuplicatePricingSheets() {
    const duplicateRes = await PostDuplicatePricingSheet(
      {
        pricing_sheet_ids: state.pricingSheetIds,
        day_of_week: state.selectedDayOfWeek,
      },
      true,
    );
    if (duplicateRes.status !== StatusCode.OK) {
      dispatch(showError("Error duplicating pricing sheets")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Successfully duplicated pricing sheets")); //TODO: Translation
    void loadPricingSheets();
    setState(prevState => ({ ...prevState, pricingSheetIds: [], showDuplicateModal: false }));
  }

  async function addPricingSheet() {
    const courseId = state.course_id ? state.course_id : facilityStore.facility?.courses[0]?.id;
    const pricingSheetRes = await PostPricingSheet(
      {
        title: state.title,
        day_of_week: state.day_of_week,
        start_time: state.start_time,
        end_time: state.end_time,
        course_id: courseId,
        application: state.application,
      },
      true,
    );
    if (pricingSheetRes.status !== StatusCode.OK) {
      return;
    }

    toggleNewPricingSheet();
    void loadPricingSheets();

    return;
  }

  function toggleNewPricingSheet() {
    setState(prevState => ({
      ...prevState,
      newSheetActive: !state.newSheetActive,
      start_time: "",
      end_time: "",
    }));
  }

  function handleCheckboxClick(pricingSheetId: number) {
    const pricingSheetIds = [...state.pricingSheetIds];
    const foundIndex = pricingSheetIds.indexOf(pricingSheetId);
    if (foundIndex === -1) {
      pricingSheetIds.push(pricingSheetId);
    } else {
      pricingSheetIds.splice(foundIndex, 1);
    }
    setState(prevState => ({ ...prevState, pricingSheetIds }));
  }

  const handleSetAllClick = () => {
    //Select all pricing sheets for the selected day of the week
    const everyPricingSheetId = pricingSheets
      ?.filter(filteredSheet => filteredSheet.day_of_week_number === filterDayState)
      .map(sheet => {
        return sheet?.id;
      });

    setState(prevState => ({ ...prevState, pricingSheetIds: everyPricingSheetId, selectAllPricingSheets: true }));
  };

  const handleClearClick = () => {
    setState(prevState => ({ ...prevState, pricingSheetIds: [], selectAllPricingSheets: false }));
  };

  function handleClearSelectedSheets() {
    setState(prevState => ({ ...prevState, pricingSheetIds: [], selectAllPricingSheets: false }));
  }

  function toggleDuplicateSheet() {
    setState(prevState => ({
      ...prevState,
      showDuplicateModal: !prevState.showDuplicateModal,
    }));
  }

  const handleInputChange = (e: any) => {
    const id = e.target.id;
    const value = e.target.value;

    setState(prevState => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSelectChange = (value: string | number, id: string) => {
    setState(prev => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleCourseSelectChange = (value: string | number, id: string) => {
    setState(prev => ({
      ...prev,
      [id]: value,
    }));
  };

  function handleSelectAllCheckboxChange(event: any) {
    const { id, checked, value } = event.target;

    if (!checked) {
      void handleClearClick();
    } else {
      void handleSetAllClick();
    }
  }

  function handleDayClick(value: any) {
    setFilterDayState(value);
    setState(prevState => ({ ...prevState, selectAllPricingSheets: false, pricingSheetIds: [] }));
  }

  const primaryAction = {
    content: t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.002"),
    action: toggleNewPricingSheet,
  };
  return (
    <>
      <Page
        title={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.023")}
        subtitle={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.024")}
        secondaryActions={[
          // {
          //   content: "Clear", // TODO: Translation
          //   action: handleClearSelectedSheets,
          // },
          {
            content: "Duplicate", // TODO: Translation
            action: toggleDuplicateSheet,
          },
        ]}
        primaryAction={primaryAction}
      >
        <Card>
          <div className="flex">
            <DayTab
              title={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.025")}
              value={Day.Monday}
              compare={filterDayState}
              onClick={value => handleDayClick(value)}
            />
            <DayTab
              title={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.026")}
              value={Day.Tuesday}
              compare={filterDayState}
              onClick={value => handleDayClick(value)}
            />
            <DayTab
              title={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.027")}
              value={Day.Wednesday}
              compare={filterDayState}
              onClick={value => handleDayClick(value)}
            />
            <DayTab
              title={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.028")}
              value={Day.Thursday}
              compare={filterDayState}
              onClick={value => handleDayClick(value)}
            />
            <DayTab
              title={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.029")}
              value={Day.Friday}
              compare={filterDayState}
              onClick={value => handleDayClick(value)}
            />
            <DayTab
              title={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.030")}
              value={Day.Saturday}
              compare={filterDayState}
              onClick={value => handleDayClick(value)}
            />
            <DayTab
              title={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.031")}
              value={Day.Sunday}
              compare={filterDayState}
              onClick={value => handleDayClick(value)}
            />
            <DayTab
              title={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.032")}
              value={Day.Holiday}
              compare={filterDayState}
              onClick={value => handleDayClick(value)}
            />
          </div>
          <DataTable
            columns={[
              {
                label: "",
                width: "5%",
                content: (
                  <div>
                    <Checkbox
                      id="selectAllPricingSheets"
                      size="medium"
                      checked={state.selectAllPricingSheets}
                      onChange={handleSelectAllCheckboxChange}
                      labelHidden
                    />
                  </div>
                ),
              }, // Checkbox Column
              { label: t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.033") },
              { label: t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.034") },
              { label: t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.035") },
              { label: t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.036") },
              { label: t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.037") },
              { label: t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.038") },
            ]}
            loading={pricingSheets === undefined}
          >
            {pricingSheets
              ?.filter(sheet => sheet.day_of_week_number === filterDayState)
              ?.map(sheet => (
                <tr
                  key={sheet.id}
                  className="clickable"
                  onClick={() => history.push("/admin/settings/tee-sheet/pricing-sheet/" + String(sheet.id))}
                >
                  <td onClick={e => e.stopPropagation()}>
                    <Checkbox
                      onChange={() => handleCheckboxClick(sheet?.id)}
                      size="medium"
                      checked={state?.pricingSheetIds?.includes(sheet?.id)}
                    />
                  </td>
                  <td>{sheet.title}</td>
                  <td>{sheet.course?.full_name}</td>
                  <td>
                    {sheet.application === "green_fee" ? (
                      <Badge size="medium" type="success">
                        {t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.039")}
                      </Badge>
                    ) : null}
                    {sheet.application === "green_fee_league" ? (
                      <Badge size="medium" type="success">
                        {"League Green Fee"} {/**TODO: Translate */}
                      </Badge>
                    ) : null}
                    {sheet.application === "power_cart" ? (
                      <Badge size="medium" type="gray">
                        {t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.040")}
                      </Badge>
                    ) : null}
                    {sheet.application === "power_cart_league" ? (
                      <Badge size="medium" type="gray">
                        {"League Power Cart"} {/**TODO: Translate */}
                      </Badge>
                    ) : null}
                    {sheet.application === "no_show" ? (
                      <Badge size="medium" type="error">
                        {"No Show"} {/* TODO: Translation */}
                      </Badge>
                    ) : null}
                  </td>
                  <td>{convertTime(sheet.start_time)}</td>
                  <td>{convertTime(sheet.end_time)}</td>
                  <td>{sheet.day_of_week}</td>
                </tr>
              ))}
          </DataTable>
        </Card>
      </Page>

      <Portal isMounted={state.newSheetActive}>
        <Sheet
          open={state.newSheetActive}
          size="small"
          closable
          title={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.003")}
          onCancel={toggleNewPricingSheet}
          onOk={() => addPricingSheet()}
          cancelText={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.004")}
          okText={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.005")}
          overflow
        >
          <div>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  label={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.006")}
                  placeholder={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.007")}
                  id="title"
                  onChange={handleInputChange}
                />
              </FormLayout.Group>

              <FormLayout.Group>
                <div>
                  <Select
                    onChange={(value: string | number) => handleSelectChange(value, "day_of_week")}
                    label={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.008")}
                    allowClear
                  >
                    <Option value="Monday">
                      {t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.009")}
                    </Option>
                    <Option value="Tuesday">
                      {t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.010")}
                    </Option>
                    <Option value="Wednesday">
                      {t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.011")}
                    </Option>
                    <Option value="Thursday">
                      {t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.012")}
                    </Option>
                    <Option value="Friday">
                      {t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.013")}
                    </Option>
                    <Option value="Saturday">
                      {t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.014")}
                    </Option>
                    <Option value="Sunday">
                      {t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.015")}
                    </Option>
                    <Option value="Holiday">
                      {t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.016")}
                    </Option>
                  </Select>
                </div>

                <div>
                  <Select
                    onChange={(value: string | number) => handleSelectChange(value, "application")}
                    label={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.018")}
                    allowClear
                  >
                    <Option value="green_fee">
                      {t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.019")}
                    </Option>
                    <Option value="power_cart">
                      {t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.020")}
                    </Option>
                    <Option value="no_show">{"No Show"}</Option>
                    <Option value="green_fee_league">{"League Green Fee"}</Option>
                    <Option value="power_cart_league">{"League Power Cart"}</Option>
                  </Select>
                </div>
              </FormLayout.Group>

              <FormLayout.Group>
                <div>
                  <TimePick
                    value={state.start_time}
                    onChange={timeString => setState(prev => ({ ...prev, start_time: timeString }))}
                    label={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.021")}
                    size="large"
                    status={state.start_time === undefined ? "warning" : undefined}
                    positionTop
                  />
                </div>

                <div>
                  <TimePick
                    value={state.end_time}
                    onChange={timeString => setState(prev => ({ ...prev, end_time: timeString }))}
                    label={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.022")}
                    size="large"
                    status={state.end_time === undefined ? "warning" : undefined}
                    positionTop
                  />
                </div>
              </FormLayout.Group>

              <FormLayout.Group>
                {facilityStore.facility?.courses?.length > 1 && (
                  <Select
                    onChange={(value: string | number) => handleCourseSelectChange(value, "course_id")}
                    label={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.017")}
                    allowClear
                  >
                    {facilityStore.facility?.courses?.map((course: Record<string, any>, index: number) => {
                      return (
                        <Option key={index} value={course.id}>
                          {course.long_name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </FormLayout.Group>
            </FormLayout>
          </div>
        </Sheet>
      </Portal>

      <div className="duplicate-sheet-overflow">
        <Sheet
          open={state.showDuplicateModal}
          size="small"
          closable
          title={"Duplicate Pricing Sheet"}
          onCancel={toggleDuplicateSheet}
          onOk={handleDuplicatePricingSheets}
          cancelText={"Cancel"}
          okText={"Duplicate"}
          okDisabled={state.pricingSheetIds?.length === 0 || !state.selectedDayOfWeek ? true : false}
        >
          <Select
            onChange={(value: string | number) => handleSelectChange(value, "selectedDayOfWeek")}
            label={"Day of Week"}
          >
            <Option value="Monday">{"Monday"}</Option>
            <Option value="Tuesday">{"Tuesday"}</Option>
            <Option value="Wednesday">{"Wednesday"}</Option>
            <Option value="Thursday">{"Thursday"}</Option>
            <Option value="Friday">{"Friday"}</Option>
            <Option value="Saturday">{"Saturday"}</Option>
            <Option value="Sunday">{"Sunday"}</Option>
            <Option value="Holiday">{"Holiday"}</Option>
          </Select>

          {state?.pricingSheetIds?.length === 0 && (
            <Callout
              type="error"
              title="No Pricing Sheets Selected"
              content="Select the check boxes of the pricing sheets you wish to duplicate and try again"
            />
          )}
        </Sheet>
      </div>
    </>
  );
}

export function DayTab({
  title,
  value,
  compare,
  onClick,
}: {
  title: string;
  value: DayValue;
  compare: DayValue;
  onClick: (value: DayValue) => void;
}): JSX.Element {
  return (
    <button
      className="flex-1 py-2 rounded-t-m"
      style={
        compare === value
          ? { backgroundColor: "#e7e7e7", borderTopLeftRadius: "7px", borderTopRightRadius: "7px" }
          : { backgroundColor: "#ffffff" }
      }
      onClick={() => onClick(value)}
    >
      {title}
    </button>
  );
}
