import { GetTournament } from "api/rpc/tournament/tournament";
import { StatusCode } from "api/protocols";

export function loadTournament(params: string, useGlobalLoader: boolean) {
  return async (dispatch: any) => {
    const res = await GetTournament(params, useGlobalLoader);
    if (res.status !== StatusCode.OK) {
      console.log(res);
      return;
    }

    console.log("Tournament: ", res.data.data[0]);

    dispatch({
      type: "tournament.update",
      payload: {
        isLoaded: true,
        tournament: res.data.data[0],
      },
    });
  };
}
