import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { TLeagueScoringSettings } from "redux/reducers/models/league";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseLeagueUrl = "/" + apiVersion + "/" + adminType + "/league/scoring";

type TEventCompetition = "player_vs_field" | "team_vs_field" | "player_vs_flight" | "team_vs_flight";

export type TPutLeagueScoreSettings = {
  best_of_order_of_merit?: number | null;
  best_of_team?: number | null;
  competition: TEventCompetition;
  default_participation_points?: number;
  format: string;
  handicap_allowance?: number;
  handicap_format: "gross" | "net";
  holes: 9 | 18;
  league_id: string | number;
  point_allocation?: any | null;
  tee_on_deck_id: string | number;
};

//GET League Settings
export async function GetScoringSettings(
  params: { league_id: string | number },
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet<TLeagueScoringSettings>(baseLeagueUrl + "/settings", params, useGlobalLoader, token);
}

// PUT League Settings
export async function PutScoringSettings(params: TPutLeagueScoreSettings, useGlobalLoader: boolean) {
  return await APIPut(baseLeagueUrl + "/settings", params, useGlobalLoader);
}
