import React, { Fragment, useEffect, useState } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./newBadgeGroup.scss";
import { IconName } from "@fortawesome/fontawesome-svg-core";

/**
 * @param {string} primaryContent Text displayed as primary content
 * @param {string} secondaryContent Text displayed as secondary content
 * @param {boolean} icon Option to display arrow icon
 * @param {boolean} rightAlign Option to move primary content to right side of badge
 * @param {string} badgeGroupType Type of badge - Default, Gray, Error, Warning, Success
 * @param {string} badgeGroupStyle Style of badge - Primary, Secondary, Tertiary, Quaternary
 * @param {string} badgeGroupSize Size of badge - Large or Small
 */

interface IBadgeGroupProps {
  primaryContent?: string;
  secondaryContent?: string;
  icon?: IconName;
  rightAlign?: boolean;
  badgeGroupType: string;
  badgeGroupStyle: string;
  badgeGroupSize: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  iconClick?: React.MouseEventHandler<HTMLElement>;
}

export const NewBadgeGroup: React.FC<IBadgeGroupProps> = props => {
  const { primaryContent, secondaryContent, icon, rightAlign, badgeGroupSize, badgeGroupStyle, badgeGroupType } = props;
  const [scrollText, setScrollText] = useState<boolean>(false);
  const [onHover, setOnHover] = useState<boolean>(false);

  useEffect(() => {
    if (onHover) {
      setScrollText(true);
    } else {
      setScrollText(false);
    }
  }, [onHover]);

  return (
    <Fragment>
      {(primaryContent || secondaryContent || icon) && (
        <div
          className={classNames("new-badge-group", `new-badge-group_type_${badgeGroupType}_${badgeGroupStyle}`, {
            "new-badge-group_right_align": rightAlign,
            "new-badge-group_single_content":
              (!secondaryContent && !icon) || (!primaryContent && !icon) || (!secondaryContent && !primaryContent),

            //Badge Size
            "new-badge-group_size_small": badgeGroupSize === "small",
            "new-badge-group_size_large": badgeGroupSize === "large",
          })}
        >
          {primaryContent && (
            <div
              className={classNames(
                "new-badge-group_primary_content",
                `new-badge-group_primary_content_${badgeGroupType}_${badgeGroupStyle}`,
                {
                  "new-badge-group_hover": props.onClick,
                },
              )}
              onClick={props.onClick}
            >
              <span>
                {primaryContent}

                {rightAlign && (
                  <Fragment>
                    {icon && (
                      <span
                        className={classNames("new-badge-group_icon", `new-badge-group_icon_${badgeGroupType}`, {
                          "new-badge-group_icon_clickable": props.onClick,
                        })}
                        onClick={props.onClick}
                      >
                        {"  "}
                        <FontAwesomeIcon className="new-badge-group_icon" icon={["fas", icon]} />
                      </span>
                    )}
                  </Fragment>
                )}
              </span>
            </div>
          )}

          {(icon || secondaryContent) && (
            <div className="new-badge-group_secondary_content_container">
              {secondaryContent && (
                <span
                  className={classNames("new-badge-group_secondary_content", {
                    "scroll-text": scrollText,
                    "new-badge-group_hover": props.onClick,
                  })}
                  onMouseEnter={() => setOnHover(true)}
                  onMouseLeave={() => setOnHover(false)}
                  onClick={props.onClick}
                >
                  {secondaryContent}
                </span>
              )}

              {icon && (
                <Fragment>
                  {!rightAlign && (
                    <span
                      className={classNames("new-badge-group_icon", `new-badge-group_icon_${badgeGroupType}`, {
                        "new-badge-group_icon_clickable": props.iconClick,
                      })}
                      onClick={props.iconClick}
                    >
                      <FontAwesomeIcon className="new-badge-group_icon" icon={["fas", icon]} />
                    </span>
                  )}
                </Fragment>
              )}
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};
