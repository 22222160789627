import { APIGet, APIPost, APIPut, APIDelete, StatusCode } from "api/protocols";
import { CancelToken } from "axios";
import { ICourse, IFacility } from "redux/reducers/models/facility";

const apiVersion = "2024-04";
const adminType = "client-admin";

const baseFacilityUrl = "/" + apiVersion + "/" + adminType + "/facility";
const baseClientUrl = "/" + apiVersion + "/" + adminType + "/client";

// export interface IFacility {
//   id: number;
//   long_name: string;
//   full_name: string;
//   short_name: string;
//   code: string;
//   courses: ICourse[];
//   holes: number;
//   client_id: number;
//   address_line_1: string;
//   address_line_2: string | null;
//   city: string;
//   province_id: number;
//   province_code: string;
//   province_name: string;
//   country_id: number;
//   country_code: string;
//   country_name: string;
//   postal: string;
//   phone: string;
//   email: string;
//   customer_email: string;
//   logo_source: string;
//   stripe_connect_id: string | null;
//   stripe_location_id: string | null;
//   stripe_customer_id: string | null;
//   timezone: string;
//   primary_locale: string;
//   region_id: number;
//   region_name: string;
//   latitude: number;
//   longitude: number;
//   created_at: string | null;
//   updated_at: string | null;
//   deleted_at: string | null;
//   prefix: string;
//   products_create?: boolean;
// }

type IFacilityHoles = 9 | 18 | 27 | 36;

type IFacilityRegion = "Greater Toronto Area" | "Hamilton" | "Kitchener/Waterloo";

interface IGetFacility {
  id?: number;
  search?: string;
  extended?: boolean;
  permissions?: boolean;
  limit?: number;
  offset?: number;
  type?: IFacilityHoles | IFacilityRegion;
}

interface IGetFacilityRes {
  data: IFacility[];
  message: string;
  status: StatusCode;
}

// GET Facility
export async function GetFacility(
  params: IGetFacility,
  useGlobalLoader: boolean,
  token?: CancelToken,
): Promise<IGetFacilityRes> {
  return await APIGet(baseFacilityUrl, params, useGlobalLoader, token);
}

//GET Facility Admin
export async function GetFacilityAdmin(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseFacilityUrl + "/admin", params, useGlobalLoader);
}

export async function PostFacility(body: any, useGlobalLoader: boolean) {
  return await APIPost(baseFacilityUrl, body, useGlobalLoader);
}

// PUT Facility
export async function PutFacility(body: any, useGlobalLoader: boolean) {
  return await APIPut(baseFacilityUrl, body, useGlobalLoader);
}

//PUT Facility Permissions
export async function PutFacilityPermissions(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseFacilityUrl + "/permission", params, useGlobalLoader);
}

// POST Facility Image
export async function PostFacilityImage(params: FormData, useGlobalLoader: boolean) {
  return await APIPost(baseFacilityUrl + "/image", params, useGlobalLoader);
}

// DELETE Facility Image
export async function DeleteFacilityImage(params: { facility_id: string | number }, useGlobalLoader: boolean) {
  return await APIDelete(baseFacilityUrl + "/image", params, useGlobalLoader);
}
