import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import axios, { CancelToken } from "axios";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Input from "components/form/input/Input";
import Sheet from "components/sheet/Sheet";
import {
  GetProductTypes,
  PutProductType,
  PostProductType,
  DeleteProductType,
} from "api/rpc/2024-04/masterAdmin/client/settings/productType";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { showError, showSuccess } from "redux/actions/ui";
import { TProductType } from "redux/reducers/models/product";

interface IProductTypesState {
  types: Array<TProductType>;
  selectedType: TProductType;
  typeTitle: string;
  editTypeModalVisible: boolean;
  newTypeModalVisible: boolean;
}

export default function ProductTypes(props: any) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { masterClientStore } = useAppSelector(store => store);

  const [state, setState] = useState<IProductTypesState>({
    types: [],
    selectedType: null,
    typeTitle: "",
    editTypeModalVisible: false,
    newTypeModalVisible: false,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (masterClientStore.client) {
      void loadProductTypes(source.token);
    }

    return () => {
      source.cancel();
    };
  }, [masterClientStore.client]);

  async function loadProductTypes(token?: CancelToken) {
    const typeRes = await GetProductTypes(
      {
        client_id: masterClientStore.client?.id,
      },
      true,
      token,
    );

    if (typeRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading product types"));
      return;
    }

    setState(prevState => ({ ...prevState, types: typeRes.data }));
  }

  async function updateProductType() {
    const typeRes = await PutProductType(
      {
        id: state.selectedType.id,
        client_id: masterClientStore.client?.id,
        title: state.typeTitle,
      },
      true,
    );

    if (typeRes.status !== StatusCode.OK) {
      dispatch(showError("Error updating product type"));
      return;
    }

    dispatch(showSuccess("Product type updated successfully"));
    setState(prevState => ({
      ...prevState,
      selectedType: null,
      typeTitle: "",
      editTypeModalVisible: false,
    }));
    void loadProductTypes();
  }

  async function createProductType() {
    const typeRes = await PostProductType(
      {
        client_id: masterClientStore?.client?.id,
        title: state.typeTitle,
      },
      true,
    );

    if (typeRes.status !== StatusCode.OK) {
      dispatch(showError("Error creating product type"));
      return;
    }

    dispatch(showSuccess("Product type created successfully"));
    setState(prevState => ({
      ...prevState,
      typeTitle: "",
      newTypeModalVisible: false,
    }));
    void loadProductTypes();
  }

  function handleEditProductType(type: TProductType) {
    setState(prevState => ({
      ...prevState,
      selectedType: type,
      typeTitle: type.title,
      editTypeModalVisible: true,
    }));
  }

  function handleNewProductType() {
    setState(prevState => ({
      ...prevState,
      typeTitle: "",
      newTypeModalVisible: true,
    }));
  }

  function handleCancelEdit() {
    setState(prevState => ({
      ...prevState,
      selectedType: null,
      typeTitle: "",
      editTypeModalVisible: false,
    }));
  }

  function handleCancelNew() {
    setState(prevState => ({
      ...prevState,
      typeTitle: "",
      newTypeModalVisible: false,
    }));
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setState(prevState => ({ ...prevState, [id]: value }));
  }

  const primaryAction = {
    content: "New Product Type",
    action: handleNewProductType,
    disabled: !masterClientStore.client,
  };

  return (
    <Page
      title="Product Types"
      subtitle={masterClientStore.client ? masterClientStore.client.full_name : "No Client Selected"}
      narrow
      primaryAction={primaryAction}
    >
      {masterClientStore.client && (
        <Card>
          <Card.Section table="true">
            <table className="ui-table ui-table-clickable">
              <thead>
                <tr>
                  <th>Title</th>
                </tr>
              </thead>
              <tbody>
                {state.types ? (
                  <>
                    {state.types.map((type: TProductType, index: number) => {
                      return (
                        <tr key={index} onClick={() => handleEditProductType(type)}>
                          <td>
                            <p className="table-cell-lead-text">{type.title}</p>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : null}
              </tbody>
            </table>
          </Card.Section>
        </Card>
      )}

      <Sheet
        title="Edit Product Type"
        open={state.editTypeModalVisible}
        size="small"
        onCancel={handleCancelEdit}
        onOk={updateProductType}
        closable
        backDropCancel
        cancelText="Cancel"
        okText="Update"
      >
        <Input value={state.typeTitle} label={"Title"} id="typeTitle" onChange={handleInputChange} />
      </Sheet>

      <Sheet
        title="New Product Type"
        open={state.newTypeModalVisible}
        size="small"
        onCancel={handleCancelNew}
        onOk={createProductType}
        closable
        backDropCancel
        cancelText="Cancel"
        okText="Create"
      >
        <Input value={state.typeTitle} label={"Title"} id="typeTitle" onChange={handleInputChange} />
      </Sheet>
    </Page>
  );
}
