import React from "react";
import "./customIcon.scss";
import classNames from "classnames";

interface IProps {
  src: string;
  size: string;
  colour?: string;
}

export function CustomIcon(props: IProps) {
  const { src, size, colour } = props;

  return (
    <div>
      <img
        src={src}
        alt="custom_icon"
        className={classNames({
          "custom-icon-small": size === "small",
          "custom-icon-medium": size === "medium",
          "custom-icon-large": size === "large",
          "custom-icon-blue": colour === "blue",
        })}
      />
    </div>
  );
}
