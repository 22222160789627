import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { GetRound } from "api/rpc/2022-09/facilityAdmin/league/scoring/round";
import { GetLeagueFlights, GetLeagueTeamScores } from "api/rpc/2022-09/facilityAdmin/league/league";
import { GetScoringSettings } from "api/rpc/2022-09/facilityAdmin/league/scoring/settings";

import { showError } from "redux/actions/ui";
import { TLeagueFlight, TLeagueTeamScores } from "redux/reducers/models/league";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";

import "../../leaderboards/leagueLeaderboard.scss";

interface ISortBy {
  /**Column to sort by. Default sorts the data by gross in ascending order */
  type: "default" | "gross" | "net" | "pts" | "overall";
  /**Ascending is true, Descending is false*/
  direction: boolean;
}

interface IState {
  rounds: Array<Record<string, any>>;
  flights: Array<TLeagueFlight>;
  currentFlightId: number;
  sortBy: ISortBy;
}

export default function LeagueTvLeaderboard() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { leagueId, scoresheetId } = useParams<{ leagueId: string; scoresheetId: string }>();

  const dispatch = useAppDispatch();

  const [state, setState] = useState<IState>({
    rounds: [],
    flights: null,
    currentFlightId: -1,
    sortBy: { type: "default", direction: true },
  });

  const [leagueTeamScores, setLeagueTeamScores] = useState<TLeagueTeamScores[]>(null);
  const [showTeamScores, setShowTeamScores] = useState(false);

  useEffect(() => {
    void loadRounds();
    void loadLeagueScoringSettings();
  }, []);

  async function loadRounds() {
    const roundsRes = await GetRound({ scoresheet_id: Number(scoresheetId), league_id: Number(leagueId) }, true);

    if (roundsRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.league.league_tv_leaderboard.001")));
      history.push(`/admin/league/${leagueId}/scoring`);
      return;
    }

    const flightRes = await GetLeagueFlights({ league_id: leagueId }, true);

    if (flightRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.league.league_tv_leaderboard.002")));
      return;
    }

    let rounds = roundsRes.data;

    if (flightRes.data.length > 0) {
      const participants: any = [];

      for (let i = 0; i < flightRes.data.length; i++) {
        for (let j = 0; j < flightRes.data[i].participants.length; j++) {
          participants.push(flightRes.data[i].participants[j]);
        }
      }

      const flightRounds: Array<Record<string, any>> = roundsRes?.data?.reduce(
        (formattedRounds: Array<Record<string, any>>, currentRound: Record<string, any>) => {
          const foundPlayer = participants?.find((player: any) => player?.user_id === currentRound?.round?.customer_id);
          if (foundPlayer) {
            formattedRounds.push({ ...currentRound, flight_id: foundPlayer?.flight_id });
          }
          return formattedRounds;
        },
        [],
      );

      rounds = flightRounds;
    }

    if (flightRes.data.length < 1) {
      flightRes.data.push({ name: t("secure.facility.league.league_tv_leaderboard.003"), id: -1 });
    }

    const sortedRounds = rounds.sort((currentRound: any, nextRound: any) => {
      if (!currentRound?.round?.gross_score) {
        return 1;
      } else if (!nextRound?.round?.gross_score) {
        return -1;
      } else {
        return currentRound?.round?.gross_score - nextRound?.round?.gross_score;
      }
    });
    setState(prevState => ({ ...prevState, rounds: sortedRounds, flights: flightRes.data }));
  }

  async function loadLeagueScoringSettings() {
    const params = {
      league_id: Number(leagueId),
    };

    const leagueScoringSettingsRes = await GetScoringSettings(params, true);

    if (leagueScoringSettingsRes.status !== StatusCode.OK) {
      return;
    }

    if (leagueScoringSettingsRes.data.competition === "team_vs_field") {
      setShowTeamScores(true); //default is shown
      void loadLeagueTeamScores();
    }
  }

  /** Only runs if Score Settings competition = "team_vs_field" */
  async function loadLeagueTeamScores() {
    const teamScoreRes = await GetLeagueTeamScores({ scoresheet_id: scoresheetId }, true);
    console.log("GetLeagueTeamScores ", teamScoreRes.data);

    if (teamScoreRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.league.league_tv_leaderboard.004")));
      return;
    }

    setLeagueTeamScores(teamScoreRes.data);
  }

  function sortByDefault() {
    const rounds = [...state.rounds];
    const sortedRounds = rounds?.sort((currentRound, nextRound) => {
      if (!currentRound?.round?.gross_score) {
        return 1;
      } else if (!nextRound?.round?.gross_score) {
        return -1;
      } else {
        return currentRound?.round?.gross_score - nextRound?.round?.gross_score;
      }
    });
    setState(prevState => ({ ...prevState, rounds: sortedRounds, sortBy: { type: "default", direction: true } }));
  }

  function sortLeaderboard(sortType: "default" | "gross" | "net" | "pts" | "overall", sortValue: string) {
    const rounds = [...state.rounds];
    let direction: boolean = state?.sortBy?.direction;
    if (state?.sortBy?.type !== sortType) {
      direction = true;
    } else if (direction === false) {
      //Descending order, return to default order
      void sortByDefault();
      return;
    } else {
      direction = !direction;
    }

    const sortedRounds = rounds.sort((currentRound, nextRound) => {
      //Sort null values last
      if (!currentRound?.round?.[sortValue]) {
        return 1;
      } else if (!nextRound?.round?.[sortValue]) {
        return -1;
      } else if (sortType === "pts" || sortType === "overall") {
        return direction
          ? nextRound?.round?.[sortValue] - currentRound?.round?.[sortValue]
          : currentRound?.round?.[sortValue] - nextRound?.round?.[sortValue];
      } else {
        return direction
          ? currentRound?.round?.[sortValue] - nextRound?.round?.[sortValue]
          : nextRound?.round?.[sortValue] - currentRound?.round?.[sortValue];
      }
    });
    setState(prevState => ({ ...prevState, rounds: sortedRounds, sortBy: { type: sortType, direction } }));
  }

  const memoizedLeaderboard = useMemo(() => {
    return (
      state.flights &&
      state.flights.map((flight: any, index: number) => {
        return (
          <Card title={flight.name} key={index}>
            <Card.Section table="true">
              <table className="ui-table league-leaderboard">
                <thead>
                  <tr>
                    <th>
                      <p>
                        <FontAwesomeIcon icon={["far", "arrow-down-1-9"]} />
                      </p>
                    </th>
                    <th>
                      <p>{t("secure.facility.league.league_tv_leaderboard.005")}</p>
                    </th>
                    <th>
                      <p>{t("secure.facility.league.league_tv_leaderboard.006")}</p>
                    </th>
                    <th>
                      <p onClick={() => sortLeaderboard("gross", "gross_score")} className="leaderboard-header">
                        {t("secure.facility.league.league_tv_leaderboard.007")}{" "}
                        <FontAwesomeIcon
                          style={{ visibility: state?.sortBy?.type === "gross" ? "visible" : "hidden" }}
                          icon={["far", state?.sortBy?.direction ? "arrow-down-long" : "arrow-up-long"]}
                        />
                      </p>
                    </th>
                    <th>
                      <p onClick={() => sortLeaderboard("net", "net_score")} className="leaderboard-header">
                        {t("secure.facility.league.league_tv_leaderboard.008")}{" "}
                        <FontAwesomeIcon
                          style={{ visibility: state?.sortBy?.type === "net" ? "visible" : "hidden" }}
                          icon={["far", state?.sortBy?.direction ? "arrow-down-long" : "arrow-up-long"]}
                        />
                      </p>
                    </th>
                    <th>
                      <p onClick={() => sortLeaderboard("pts", "rating")} className="leaderboard-header">
                        {t("secure.facility.league.league_tv_leaderboard.009")}{" "}
                        <FontAwesomeIcon
                          style={{ visibility: state?.sortBy?.type === "pts" ? "visible" : "hidden" }}
                          icon={["far", state?.sortBy?.direction ? "arrow-down-long" : "arrow-up-long"]}
                        />
                      </p>
                    </th>
                    <th>
                      <p
                        onClick={() => sortLeaderboard("overall", "overall_gross_score")}
                        className="leaderboard-header"
                      >
                        {t("secure.facility.league.league_tv_leaderboard.010")}{" "}
                        <FontAwesomeIcon
                          style={{ visibility: state?.sortBy?.type === "overall" ? "visible" : "hidden" }}
                          icon={["far", state?.sortBy?.direction ? "arrow-down-long" : "arrow-up-long"]}
                        />
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {state.rounds ? (
                    <>
                      {state.flights && state.flights.length > 0 && flight.id !== -1
                        ? state.rounds
                            .filter((round: any) => round.flight_id === flight.id)
                            .map((round, index: number) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <p className="table-cell-lead-text">{index + 1}</p>
                                  </td>
                                  <td>
                                    <p>{round?.round?.customer?.full_name}</p>
                                  </td>
                                  <td>
                                    <p>{round?.round?.round_handicap}</p>
                                  </td>
                                  <td>
                                    <p>{round?.round?.gross_score}</p>
                                  </td>
                                  <td>
                                    <p>{round?.round?.net_score}</p>
                                  </td>
                                  <td>
                                    <p>{round?.round?.points_awarded}</p>
                                  </td>
                                  <td>
                                    <p>{round?.round?.overall_gross_score}</p>
                                  </td>
                                </tr>
                              );
                            })
                        : state.rounds.map((round, index: number) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <p className="table-cell-lead-text">{index + 1}</p>
                                </td>
                                <td>
                                  <p>{round?.round?.customer?.full_name}</p>
                                </td>
                                <td>
                                  <p>{round?.round?.round_handicap}</p>
                                </td>
                                <td>
                                  <p>{round?.round?.gross_score}</p>
                                </td>
                                <td>
                                  <p>{round?.round?.net_score}</p>
                                </td>
                                <td>
                                  <p>{round?.round?.points_awarded}</p>
                                </td>
                                <td>
                                  <p>{round?.round?.overall_gross_score}</p>
                                </td>
                              </tr>
                            );
                          })}
                    </>
                  ) : null}
                </tbody>
              </table>
            </Card.Section>
          </Card>
        );
      })
    );
  }, [state.sortBy, state.rounds, state.currentFlightId]);

  return (
    <Page
      title={t("secure.facility.league.league_tv_leaderboard.011")}
      splitLayout
      subActions={[
        {
          label: t("secure.facility.league.league_tv_leaderboard.012"),
          icon: null,
          type: "tertiary",
          onClick: () =>
            history.push(
              "/admin/league/" + String(leagueId) + "/scoring/scoresheet/" + String(scoresheetId) + "/skins",
            ),
        },
        {
          label: t("secure.facility.league.league_tv_leaderboard.013"),
          icon: null,
          type: "tertiary",
          onClick: () =>
            history.push(
              "/admin/league/" + String(leagueId) + "/rounds/scoresheet/" + String(scoresheetId) + "/leaderboard",
            ),
        },
        {
          label: leagueTeamScores
            ? `${
                showTeamScores
                  ? t("secure.facility.league.league_tv_leaderboard.014")
                  : t("secure.facility.league.league_tv_leaderboard.015")
              } ${t("secure.facility.league.league_tv_leaderboard.016")}`
            : "",
          icon: null,
          type: "tertiary",
          onClick: () => setShowTeamScores(!showTeamScores),
        },
      ]}
      full
      breadcrumbs={[
        {
          prefix: true,
          label: "Scoresheet Overview", // TODO: Translation
          url: `/admin/league/${leagueId}/rounds/scoresheet/${scoresheetId}/overview`,
          // url: "/admin/league/" + String(leagueId) + "/rounds/scoresheet",
        },
      ]}
    >
      {/* {leagueTeamScores !== null && (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Toggle label="Toggle Team Scores" onChange={() => setShowTeamScores(!showTeamScores)} />
        </div>
      )} */}

      <Page.Section twoThirds>
        <Form>
          <FormLayout>{memoizedLeaderboard}</FormLayout>
        </Form>
      </Page.Section>

      <Page.Section oneThird>
        {showTeamScores && leagueTeamScores ? (
          <Card title={t("secure.facility.league.league_tv_leaderboard.018")}>
            <Card.Section table="true">
              <table className="ui-table">
                <thead>
                  <tr>
                    <th>
                      <p>
                        <FontAwesomeIcon icon={["far", "arrow-down-1-9"]} />
                      </p>
                    </th>
                    <th>
                      <p>{t("secure.facility.league.league_tv_leaderboard.019")}</p>
                    </th>
                    <th>
                      <p>{t("secure.facility.league.league_tv_leaderboard.020")}</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {leagueTeamScores?.map((team, index) => {
                    return (
                      <tr key={team.id}>
                        <td>
                          <p className="table-cell-lead-text">{index + 1}</p>
                        </td>
                        <td>
                          <p>{team.team.name}</p>
                        </td>
                        <td>
                          <p>{team.total}</p>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Card.Section>
          </Card>
        ) : null}
      </Page.Section>
    </Page>
  );
}
