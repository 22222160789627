import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Prompt, useHistory, useLocation, useParams } from "react-router-dom";
import { IProduct, IVariant } from "redux/reducers/models/product";
import { useAppDispatch } from "hooks/redux";
import Page from "components/page/Page";
import FormLayout from "components/form/FormLayout";
import Card from "components/card/Card";
import { IPrimaryPageAction } from "components/page/PageActions";
import "./newInventoryCount.scss";
import ProductSelect from "components/productModal/ProductSelect";
import Portal from "elements/Portal";
import Popup from "components/popup/Popup";
import ReactDOM from "react-dom";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";
import Input from "components/form/input";
import { ButtonNew as Button } from "components/buttonNew";
import Sheet from "components/sheet/Sheet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { StatusCode } from "api/protocols";
import { dequeue, enqueue, showError, showSuccess } from "redux/actions/ui";
import { cloneDeep } from "lodash";
import { PostInventoryCountVariants, TInventoryCount } from "api/rpc/2024-04/facilityAdmin/product/inventoryCount";
import Spin from "components/spin/spin";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { findSkuProduct } from "./inventoryHelpers";
import { isEmpty } from "helpers/Helpers";

export type TSku = {
  sku: string;
  quantity: number;
  /**False value will display loader */
  searched: boolean;
};
interface IProductState {
  selectedProducts: Array<IProduct>;
  skusNotFound: Array<TSku>;
  openModal: boolean;
}

interface ISkuState {
  skus: Array<TSku>;
  skuString: string;
}
interface ILocationState {
  params: { inventoryCount: TInventoryCount; products: Array<IProduct> };
}

export default function NewInventoryCount() {
  const history = useHistory();
  const location = useLocation();
  const locationState = location.state as ILocationState;
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const [productState, setProductState] = useState<IProductState>({
    selectedProducts: [],
    skusNotFound: [],
    openModal: false,
  });
  const [skuState, setSkuState] = useState<ISkuState>({
    skus: [],
    skuString: "",
  });

  const [savePopupState, setSavePopupState] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = useState<boolean>(false);
  const [variantNotFoundState, setVariantNotFoundState] = useState<{ modalOpen: boolean; sku: string }>({
    modalOpen: false,
    sku: "",
  });

  useEffect(() => {
    //Show warning dialog box when browser is refreshed
    function handleBeforeUnload(event: BeforeUnloadEvent) {
      event.preventDefault();
      event.returnValue = "";
      return "";
    }
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  //For searching variants by sku
  useEffect(() => {
    const source = axios.CancelToken.source();
    const currentSku = skuState?.skuString?.slice();
    const skuIndex = skuState?.skus?.findIndex(sku => sku.sku === currentSku);
    setSkuState(prevState => ({ ...prevState, skuString: "" }));
    if (skuIndex !== -1) {
      const foundSku = skuState.skus[skuIndex];
      // First sku added, search to see if variant exists
      if (foundSku.quantity === 1 || !foundSku?.searched) {
        void findSkuProduct(
          productState.selectedProducts,
          skuState.skus,
          setProductState,
          setSkuState,
          dispatch,
          source.token,
        );
      }
    }
    return () => {
      source.cancel();
    };
  }, [skuState?.skus]);

  function handleEnterKeydown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      void addSku();
    }
  }

  async function handleSaveCount() {
    dispatch(enqueue());
    const variants: Array<IVariant> = [];
    productState?.selectedProducts?.forEach(product => {
      variants?.push(...[...product?.variants]);
    });
    const formattedVariants = variants?.map(variant => ({
      variant_id: variant?.id,
      quantity_counted: variant?.quantity,
    }));
    setSavePopupState(false);

    const variantsRes = await PostInventoryCountVariants(
      { inventory_count_id: Number(id), variants: formattedVariants },
      false,
    );

    if (variantsRes?.status !== StatusCode.OK) {
      dispatch(showError("Error updating inventory counts"));
      dispatch(dequeue());
      return;
    }
    setSaveSuccess(true);
    dispatch(showSuccess("Successfully saved inventory counts"));
    dispatch(dequeue());
    history.push(`/admin/settings/counts/view/${id}`);
  }

  function addSku() {
    if (skuState.skuString) {
      const updatedSkus = [...skuState.skus];
      const productIncremented = incrementProductQuantity(skuState.skuString);
      if (!productIncremented) {
        const foundSkuIndex = updatedSkus?.findIndex(skuObj => skuObj.sku === skuState.skuString);
        if (foundSkuIndex !== -1) {
          //Increment quantity of sku found
          const foundSku = updatedSkus[foundSkuIndex];
          updatedSkus.splice(foundSkuIndex, 1, { ...foundSku, quantity: ++foundSku.quantity });
        } else {
          //Add new sku
          const newSku: TSku = { sku: skuState.skuString, quantity: 1, searched: false };
          updatedSkus.push(newSku);
        }
        setSkuState(prevState => ({ ...prevState, skus: updatedSkus }));
      } else {
        setSkuState(prevState => ({ ...prevState, skuString: "" }));
      }
    }
  }

  function incrementProductQuantity(currentSku: string) {
    const products = [...productState?.selectedProducts];
    let productInList = false;
    let variantIndex: number = null;
    const productIndex = products?.findIndex(product => {
      const foundVariantIndex = product?.variants?.findIndex(
        variant => variant?.sku === currentSku || variant?.barcode === currentSku,
      );
      if (foundVariantIndex !== -1) {
        variantIndex = foundVariantIndex;
        return true;
      }
    });
    if (productIndex !== -1 && variantIndex !== -1) {
      // Product already in list, increment the quantity
      const foundVariant = products[productIndex]?.variants[variantIndex];
      products[productIndex].variants[variantIndex] = { ...foundVariant, quantity: ++foundVariant.quantity };
      productInList = true;
      setProductState(prevState => ({ ...prevState, selectedProducts: products }));
    }
    return productInList;
  }

  function handleProductSelect(products: Array<IProduct>) {
    const selectedProducts = [...products];
    selectedProducts?.forEach((product, productIndex) => {
      product?.variants?.forEach((variant, variantIndex) => {
        if (variant?.quantity == null) {
          selectedProducts[productIndex].variants[variantIndex] = { ...variant, quantity: 1 };
        }
      });
    });
    const { updatedSkus, updatedProducts } = updateSkus(selectedProducts);
    ReactDOM.unstable_batchedUpdates(() => {
      setProductState(prevState => ({ ...prevState, selectedProducts: updatedProducts }));
      setSkuState(prevState => ({ ...prevState, skus: updatedSkus }));
    });
  }

  function updateSkus(products: Array<IProduct>) {
    const updatedSkus = [...skuState.skus];
    const updatedProducts = [...products];
    updatedProducts?.forEach((product, productIndex) => {
      product?.variants?.forEach((variant, variantIndex) => {
        const skuIndex = updatedSkus?.findIndex(sku => sku.sku === variant?.sku || sku.sku === variant?.barcode);
        if (skuIndex !== -1) {
          updatedProducts[productIndex].variants[variantIndex] = {
            ...variant,
            quantity: Number(variant.quantity) + Number(updatedSkus[skuIndex]?.quantity),
          };
          updatedSkus.splice(skuIndex, 1);
        }
      });
    });
    return { updatedSkus: updatedSkus, updatedProducts: updatedProducts };
  }

  function handleEnterClick() {
    const skuSearchId = document.getElementById("skuSearchId");
    skuSearchId.focus();
    void addSku();
  }

  function removeSku(index: number) {
    const updatedSkus = [...skuState.skus];
    // Sku cannot be removed if search is in progress
    const allSkusSearched = updatedSkus?.every(sku => sku.searched);
    if (updatedSkus[index] && allSkusSearched) {
      updatedSkus.splice(index, 1);
      setSkuState(prevState => ({ ...prevState, skus: updatedSkus }));
    }
  }

  function removeVariant(productIndex: number, variantIndex: number) {
    const updatedProducts = [...productState?.selectedProducts];
    //Product with single variant
    if (variantIndex == null || updatedProducts[productIndex]?.variants?.length === 1) {
      updatedProducts.splice(productIndex, 1);
    } else {
      // Product with multiple variants
      if (updatedProducts[productIndex]?.variants[variantIndex]) {
        updatedProducts[productIndex]?.variants?.splice(variantIndex, 1);
      }
    }

    setProductState(prevState => ({ ...prevState, selectedProducts: updatedProducts }));
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>, productIndex: number, variantIndex: number) {
    const { valueAsNumber } = e.target;
    const updatedProducts = [...productState?.selectedProducts];
    const updatedVariant = updatedProducts[productIndex].variants[variantIndex];
    updatedProducts[productIndex].variants[variantIndex] = {
      ...updatedVariant,
      quantity: isNaN(valueAsNumber) ? null : valueAsNumber,
    };
    setProductState(prevState => ({ ...prevState, selectedProducts: updatedProducts }));
  }

  function quantityOnBlur(productIndex: number, variantIndex: number, quantity: number) {
    const currentQuantity = Number(quantity);
    //Set the quantity to '0' if the entered value is empty or negative
    if (currentQuantity < 0 || isEmpty(quantity)) {
      const updatedProducts = [...productState?.selectedProducts];
      const updatedVariant = updatedProducts[productIndex].variants[variantIndex];
      updatedProducts[productIndex].variants[variantIndex] = {
        ...updatedVariant,
        quantity: 0,
      };
      setProductState(prevState => ({ ...prevState, selectedProducts: updatedProducts }));
    }
  }

  const disableSave =
    productState?.selectedProducts?.length === 0 ||
    productState?.selectedProducts?.some(product =>
      product?.variants?.some(variant => isEmpty(variant.quantity)),
    ) ||
    skuState?.skus?.some(sku => !sku.searched);

  const saveAction: IPrimaryPageAction = {
    content: "Save",
    action: () => setSavePopupState(true),
    disabled: disableSave,
  };

  return (
    <Page
      title={"Count Inventory"}
      primaryAction={saveAction}
      breadcrumbs={[{ prefix: true, label: "Back to Inventory Counts", url: "/admin/settings/counts" }]}
    >
      <Card>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Input
                id="skuSearchId"
                onKeyDown={handleEnterKeydown}
                value={skuState.skuString}
                placeholder="Scan Sku / Barcode"
                onChange={e => setSkuState(prevState => ({ ...prevState, skuString: e.target.value }))}
                trailingButtons={[
                  <Button key={1} onClick={handleEnterClick} type="secondary">
                    Enter
                  </Button>,
                  <Button
                    key={2}
                    type="primary"
                    size="medium"
                    icon={<FontAwesomeIcon className="ml-2" icon={["far", "magnifying-glass"]} />}
                    onClick={() => setProductState(prevState => ({ ...prevState, openModal: true }))}
                  >
                    Search
                  </Button>,
                ]}
              />
            </FormLayout.Group>
          </FormLayout>
          <DataTable
            columns={[
              ...(productState?.selectedProducts?.length > 0 ? [{ label: "Title" }, { label: "Vendor" }] : []),
              ...(productState?.selectedProducts?.length > 0
                ? [{ label: "Sku" }, { label: "Barcode" }]
                : [{ label: "Sku / Barcode" }]),
              {
                label: "Quantity",
              },
              { label: "" },
              { label: "" },
            ]}
          >
            {skuState?.skus?.map((sku, index) => {
              return (
                <tr key={sku.sku}>
                  {productState?.selectedProducts?.length > 0 && (
                    <>
                      <td></td>
                      <td></td>
                      <td>{sku?.sku}</td>
                    </>
                  )}
                  <td>{sku?.sku}</td>
                  <td>{sku?.quantity}</td>
                  <td className="text-right">
                    {!sku.searched ? (
                      <div style={{ display: "inline-flex" }}>
                        <Spin />
                      </div>
                    ) : (
                      <OverlayTrigger
                        placement="auto"
                        overlay={<Tooltip id="headingTooltip">Product not found</Tooltip>}
                      >
                        <FontAwesomeIcon
                          className="inventory-counts-error"
                          size="1x"
                          icon={["fas", "circle-xmark"]}
                          onClick={() => setVariantNotFoundState({ modalOpen: true, sku: sku.sku })}
                        />
                      </OverlayTrigger>
                    )}
                  </td>
                  <td className="text-right">
                    <FontAwesomeIcon
                      onClick={() => removeSku(index)}
                      icon={["far", "trash-can-xmark"]}
                      size="1x"
                      className="cursor-pointer"
                    />
                  </td>
                </tr>
              );
            })}
            {productState?.selectedProducts?.map((product, productIndex) => {
              const singleVariant = product?.variants?.length === 1 && product?.title === product?.variants[0]?.title;
              return (
                <React.Fragment key={product.id}>
                  <tr className="inventory-counts-product-header">
                    <td>{product?.preferred_title ? product?.preferred_title : product.title}</td>
                    <td>{product.vendor_title}</td>
                    <td>{singleVariant && product?.variants[0]?.sku}</td>
                    <td>{singleVariant && product?.variants[0]?.barcode}</td>
                    <td style={{ width: "15%" }} onClick={e => e.stopPropagation()}>
                      {singleVariant && (
                        <Input
                          type="number"
                          labelHidden
                          onChange={e => handleInputChange(e, productIndex, 0)}
                          value={product?.variants[0]?.quantity ?? ""}
                          placeholder="Quantity"
                          onBlur={() => quantityOnBlur(productIndex, 0, product?.variants[0]?.quantity)}
                          error={isEmpty(product?.variants[0]?.quantity)}
                        />
                      )}
                    </td>
                    <td className="text-right">
                      {singleVariant && (
                        <OverlayTrigger placement="auto" overlay={<Tooltip id="headingTooltip">Product found</Tooltip>}>
                          <FontAwesomeIcon
                            className="inventory-counts-success"
                            size="1x"
                            icon={["fas", "circle-check"]}
                          />
                        </OverlayTrigger>
                      )}
                    </td>
                    <td className="text-right">
                      {singleVariant ? (
                        <FontAwesomeIcon
                          onClick={() => removeVariant(productIndex, null)}
                          icon={["far", "trash-can-xmark"]}
                          size="1x"
                          className="cursor-pointer"
                        />
                      ) : null}
                    </td>
                  </tr>
                  {!singleVariant &&
                    product?.variants?.map((variant, variantIndex) => (
                      <tr key={variant.id}>
                        <td style={{ textIndent: "3rem" }}>{variant?.title}</td>
                        <td></td>
                        <td>{variant?.sku}</td>
                        <td>{variant?.barcode}</td>
                        <td style={{ width: "15%" }} onClick={e => e.stopPropagation()}>
                          <Input
                            type="number"
                            labelHidden
                            onChange={e => handleInputChange(e, productIndex, variantIndex)}
                            value={variant?.quantity ?? ""}
                            placeholder="Quantity"
                            onBlur={() => quantityOnBlur(productIndex, variantIndex, variant?.quantity)}
                            error={isEmpty(variant?.quantity)}
                          />
                        </td>
                        <td className="text-right">
                          {
                            <OverlayTrigger
                              placement="auto"
                              overlay={<Tooltip id="headingTooltip">Product found</Tooltip>}
                            >
                              <FontAwesomeIcon
                                className="inventory-counts-success"
                                size="1x"
                                icon={["fas", "circle-check"]}
                              />
                            </OverlayTrigger>
                          }
                        </td>
                        <td className="text-right">
                          <FontAwesomeIcon
                            onClick={() => removeVariant(productIndex, variantIndex)}
                            icon={["far", "trash-can-xmark"]}
                            size="1x"
                            className="cursor-pointer"
                          />
                        </td>
                      </tr>
                    ))}
                </React.Fragment>
              );
            })}
          </DataTable>
        </Card.Section>
      </Card>

      <div className="inventory-counts-products-modal">
        <Sheet
          open={productState.openModal}
          title="Search Products"
          size="medium"
          onCancel={() => setProductState(prevState => ({ ...prevState, openModal: false }))}
          cancelText="Close"
          closable
          onOk={() => setProductState(prevState => ({ ...prevState, openModal: false }))}
          okText="Confirm"
        >
          <ProductSelect
            selectedProducts={productState.selectedProducts}
            onChange={handleProductSelect}
            search
            getProductsParams={{ track_inventory: true, all: true }}
            variantInput={{ key: "quantity", placeholder: "Quantity" }}
          />
        </Sheet>
      </div>
      <Portal isMounted={savePopupState}>
        <Popup
          open={savePopupState}
          onOk={() => handleSaveCount()}
          onCancel={() => setSavePopupState(false)}
          type="info"
          okText="Save"
          title={"Save Inventory Count?"}
          description={`Count can be edited and submitted after saving`}
        />
      </Portal>
      <Portal isMounted={variantNotFoundState.modalOpen}>
        <Popup
          open={variantNotFoundState.modalOpen}
          type="warning"
          title={"Product Not Found"}
          onOk={() => setVariantNotFoundState({ modalOpen: false, sku: "" })}
          okText="Close"
          description=""
          closable
          backDropCancel
        >
          <p className="mb-2">
            A product corresponding to SKU or barcode {<b>{variantNotFoundState.sku}</b>} was not found.
          </p>
          <p className="mb-2">Please proceed to the products page to update a product with this SKU / barcode.</p>
        </Popup>
      </Portal>
      <Prompt
        when={!saveSuccess}
        message={location => "Unsaved changes will be lost if you leave the page. Continue?"}
      />
    </Page>
  );
}
