import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import Page from "components/page/Page";
import { GetTaxLine, DeleteTaxLine } from "api/rpc/facilityAdmin/client/taxLine";
import { StatusCode } from "api/protocols";

import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import Card from "components/card/Card";
import Sheet from "components/sheet/Sheet";
import { isEqualWith, isNumber } from "lodash";
import { Select } from "components/select/index";

import { useTranslation, Trans } from "react-i18next";
import Popup from "components/popup/Popup";
import { PutTaxLine } from "api/rpc/2024-04/facilityAdmin/client/taxLines";
import axios, { CancelToken } from "axios";
import { GetAccountingReference } from "api/rpc/2024-04/facilityAdmin/facility/accounting";
import { TAccountingReference } from "redux/reducers/models/facility";
import Icon from "components/icon/Icon";

interface ITaxLineState {
  id: number;
  name: string;
  percentage: number;
  migrate_tax_line_id?: number;
  accountingReferences: Array<TAccountingReference>;
  accounting_reference_id: number;
  accountingRefSearch: string;
}

export default function TaxLinesEdit(props: any) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { Option } = Select;
  const { id } = useParams<{ id: string }>();
  const [taxLineState, setTaxLineState] = useState<ITaxLineState>({
    id: undefined,
    name: "",
    percentage: null,
    migrate_tax_line_id: undefined,
    accountingReferences: [],
    accounting_reference_id: null,
    accountingRefSearch: "",
  });
  const [prevTaxLineState, setPrevTaxLineState] = useState<ITaxLineState>(taxLineState);
  const [dropDownState, setDropDownState] = useState({ taxLine: [] });
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);

  const [taxLineBeforeChanges, setTaxLineBeforeChanges] = useState(undefined);
  const [taxLineLoaded, setTaxLineLoaded] = useState<boolean>(false);
  const [accountingReferencesLoaded, setAccountingReferencesLoaded] = useState<boolean>(false);

  useEffect(() => {
    void loadTaxLines();

    return () => {
      setTaxLineState({
        id: undefined,
        name: "",
        percentage: null,
        migrate_tax_line_id: undefined,
        accountingReferences: [],
        accounting_reference_id: null,
        accountingRefSearch: "",
      });
      setPrevTaxLineState({
        id: undefined,
        name: "",
        percentage: null,
        migrate_tax_line_id: undefined,
        accountingReferences: [],
        accounting_reference_id: null,
        accountingRefSearch: "",
      });
      setDropDownState({ taxLine: [] });
    };
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadAccountingReferences(source.token);
  }, []);

  async function loadTaxLines() {
    const res = await GetTaxLine(null, true);
    if (res.status !== StatusCode.OK) {
      return;
    }

    setDropDownState(prev => ({
      ...prev,
      taxLine: res.data,
    }));

    const currentType = res.data.find((type: any) => type.id === Number(id));
    setTaxLineState(prev => ({
      ...prev,
      id: currentType.id,
      name: currentType.name,
      percentage: currentType.percentage,
      accounting_reference_id: currentType.accounting_reference_id,
    }));

    setPrevTaxLineState(prev => ({
      ...prev,
      id: currentType.id,
      name: currentType.name,
      percentage: currentType.percentage,
      accounting_reference_id: currentType.accounting_reference_id,
    }));

    setTaxLineLoaded(true);
  }

  async function loadAccountingReferences(token: CancelToken) {
    const res = await GetAccountingReference(null, false, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      return;
    }

    const normalizedReferences: TAccountingReference[] = [
      { id: -1, client_id: null as number, title: "None", reference_number: null as string },
      ...res.data,
    ];

    setTaxLineState(prev => ({ ...prev, accountingReferences: normalizedReferences }));
    setAccountingReferencesLoaded(true);
  }

  async function saveTaxLine() {
    const res = await PutTaxLine(taxLineState, true);
    if (res.status !== StatusCode.OK) {
      return;
    }

    history.push("/admin/settings/taxes");
  }

  async function removeTaxLine() {
    if (prevTaxLineState.migrate_tax_line_id === undefined) {
      return;
    }

    const res = await DeleteTaxLine(prevTaxLineState, true);

    if (res.status !== StatusCode.OK) {
      return;
    }

    history.push("/admin/settings/taxes");
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setTaxLineState(prevState => ({ ...prevState, [id]: id === "percentage" ? Number(value) : value }));
  }

  function handleDeleteModalVisibility() {
    setDeleteModalVisibility(!deleteModalVisibility);
  }

  function handleDropDownChange(id: any) {
    setPrevTaxLineState(prev => ({
      ...prev,
      migrate_tax_line_id: id,
    }));
  }

  const primaryAction = {
    content: t("secure.facility.settings.tax_lines.tax_lines_edit.001"),
    action: saveTaxLine,
  };

  const secondaryAction = {
    content: t("secure.facility.settings.tax_lines.tax_lines_edit.002"),
    action: handleDeleteModalVisibility,
  };

  function cancelUnsavedChanges() {
    setTaxLineState(taxLineBeforeChanges);
  }

  function unsavedChangesExist() {
    if (taxLineBeforeChanges === undefined) {
      if (taxLineLoaded && accountingReferencesLoaded) {
        setTaxLineBeforeChanges(taxLineState);
      }
      return false;
    }

    return !isEqualWith(taxLineBeforeChanges, taxLineState);
  }

  return (
    <>
      <Page
        title={t("secure.facility.settings.tax_lines.tax_lines_edit.003") + prevTaxLineState.name}
        narrow
        // primaryAction={primaryAction}
        secondaryActions={[secondaryAction]}
        breadcrumbs={[
          {
            prefix: true,
            label: t("secure.facility.settings.tax_lines.tax_lines_edit.004"),
            url: "/admin/settings/taxes",
          },
        ]}
        notificationBarProps={{
          isVisible: unsavedChangesExist(),
          onAction: saveTaxLine,
          onCancel: cancelUnsavedChanges,
        }}
      >
        <Card>
          <Card.Section>
            <Form>
              <FormLayout>
                <FormLayout.Group>
                  <Input
                    value={taxLineState.name}
                    label={t("secure.facility.settings.tax_lines.tax_lines_edit.005")}
                    id="name"
                    onChange={handleInputChange}
                    placeholder={t("secure.facility.settings.tax_lines.tax_lines_edit.006")}
                  />
                  <Input
                    value={taxLineState.percentage}
                    label={t("secure.facility.settings.tax_lines.tax_lines_edit.007")}
                    id="percentage"
                    onChange={handleInputChange}
                    placeholder={t("secure.facility.settings.tax_lines.tax_lines_edit.008")}
                    type={"number"}
                    suffix="%"
                  />
                </FormLayout.Group>

                <FormLayout.Group>
                  {taxLineState?.accounting_reference_id ? (
                    <div>
                      <div>
                        <p className="inventory-vendor-label">{"Accounting Reference"}</p>
                      </div>
                      <div className="vendor-name">
                        <div>
                          {" "}
                          {
                            taxLineState?.accountingReferences?.find(
                              (reference: any) => reference.id === Number(taxLineState?.accounting_reference_id),
                            )?.title
                          }{" "}
                        </div>
                        <button
                          onClick={() =>
                            setTaxLineState(prevState => ({
                              ...prevState,
                              accounting_reference_id: null,
                              accountingRefSearch: "",
                            }))
                          }
                        >
                          <Icon style="far" icon="times" />
                        </button>
                      </div>
                    </div>
                  ) : (
                    <Select
                      label={"Accounting Reference"}
                      showSearch
                      onSearch={(query: string) => setTaxLineState(prev => ({ ...prev, accountingRefSearch: query }))}
                      onChange={(value: number) =>
                        setTaxLineState(prev => ({ ...prev, accounting_reference_id: value }))
                      }
                      allowClear
                      placeholder={"Search for Accounting References..."}
                      searchValue={taxLineState.accountingRefSearch}
                      showDropDownOnFocus={true}
                      defaultValue={taxLineState.accounting_reference_id}
                    >
                      {taxLineState.accountingReferences
                        .filter(accountRef =>
                          accountRef.title.toLowerCase().includes(taxLineState.accountingRefSearch.toLowerCase()),
                        )
                        .map(reference => {
                          return (
                            <div key={reference.id}>
                              {taxLineState.accountingRefSearch !== "" ? (
                                <div>
                                  <Select.Option key={reference.id} value={reference.id} name={reference.title}>
                                    <p className="font-semibold text-lg">{reference.title}</p>
                                  </Select.Option>
                                </div>
                              ) : null}
                            </div>
                          );
                        })}
                    </Select>
                  )}
                </FormLayout.Group>
              </FormLayout>
            </Form>
          </Card.Section>
        </Card>
      </Page>

      <Popup
        open={deleteModalVisibility}
        type="warning"
        title={t("secure.facility.settings.tax_lines.tax_lines_edit.011")}
        description={t("secure.facility.settings.tax_lines.tax_lines_edit.012")}
        onOk={isNumber(prevTaxLineState.migrate_tax_line_id) ? removeTaxLine : undefined}
        okText={t("secure.facility.settings.tax_lines.tax_lines_edit.010")}
        onCancel={handleDeleteModalVisibility}
        backDropCancel={true}
      />
    </>
  );
}
