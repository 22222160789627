import { APIPost, APIPut } from "api/protocols";

const apiVersion = "2022-09";
const adminType = "guest";

const baseTransactionUrl = "/" + apiVersion + "/" + adminType + "/order/transaction";

interface IPostTransaction {
  processing_type: string;
  order_token: string;
  kind: string;
  source: string;
  amount: number;
  payment_method: string;
  payment_method_id?: number;
}

interface ITransaction {
  id: number;
  order_id: number;
  facility_id: number;
  client_id: number;
  amount: number;
  tip_amount: number;
  currency: string;
  payment_method: string;
  payment_type: string;
  payment_last_4: string;
  payment_intent_id: string;
  authorization_id: number;
  kind: string;
  parent_id: number;
  source: string;
  processing_type: string;
  gateway: string;
  device: string;
  error_code: string;
  note: string;
  processed_at: string;
  client_secret: string;
}

interface IPostTransactionResponse {
  data: ITransaction;
  status: number;
}

interface IPutCapture {
  payment_intent_id: string;
  transaction_id: number;
}

interface ICapture {
  id: number;
  order_id: number;
  facility_id: number;
  client_id: number;
  amount: number;
  tip_amount: number;
  currency: string;
  payment_option_id: number;
  payment_method: string;
  payment_type: string;
  payment_last_4: string;
  payment_intent_id: string;
  charge_id: number;
  kind: string;
  parent_id: number;
  order_refund_id: number;
  source: string;
  processing_type: string;
  gateway: string;
  device: string;
  processed_at: string;
  processed_at_local: string;
  error_code: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  note: string;
}

interface IPutCaptureResponse {
  data: ICapture;
  status: number;
}

export async function PostTransaction(
  params: IPostTransaction,
  useGlobalLoader: boolean,
): Promise<IPostTransactionResponse> {
  return await APIPost(baseTransactionUrl, params, useGlobalLoader);
}

export async function PutCapture(params: IPutCapture, useGlobalLoader: boolean): Promise<IPutCaptureResponse> {
  return await APIPut(baseTransactionUrl + "/capture", params, useGlobalLoader);
}
