import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { StatusCode } from "api/protocols";
import { GetTeeSheetPrint, PostEmailTeeSheet } from "api/rpc/2024-04/facilityAdmin/teesheet/teeSheet";
import { GetTeeSheetPrint as ClientTeeSheetPrint } from "api/rpc/2024-04/clientAdmin/teesheet/teeSheet";
import { PostFacilityStatus, TPostFacilityStatus } from "api/rpc/2024-04/facilityAdmin/facility/status";

import { showError, showSuccess } from "redux/actions/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import useModal from "hooks/modals/useModal";
import { formatDate } from "helpers/Helpers";

import Weather from "components/weather/Weather";
import { formatMarkdownBreaks } from "components/markdown/Markdown";
import { NavigationDropdownNew } from "components/navigationDropdownNew/NavigationDropdownNew";
import InformationItem from "elements/teesheet/statisticsbar/InformationItem";
import EmailTeesheetModal, { IEmailTeeSheet } from "elements/teesheet/statisticsbar/EmailTeesheetModal";
import PrintTeesheetModal, { IPrintTeesheet } from "elements/teesheet/statisticsbar/PrintTeesheetModal";
import StatusModal, { IFacilityStatusState } from "elements/teesheet/statisticsbar/StatusModal";

import { ITeeTimeNew } from "redux/reducers/models/teetime";

import "elements/teesheet/statisticsbar/statisticsBar.scss";

export type TRecipient = {
  id: string | number;
  start_time: string;
};

export default function NewStatisticsBar(props: { client: boolean; teetimes: ITeeTimeNew[] }) {
  const history = useHistory();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { facilityStore, clientFacilityStore, teeSheetStore, clientTeeSheetStore } = useAppSelector(store => store);
  const chosenFacilityStore = props.client ? clientFacilityStore : facilityStore;
  const chosenTeeSheetStore = props.client ? clientTeeSheetStore : teeSheetStore;

  const {
    state: emailTeesheetModal,
    updateModal: updateEmailTeesheetModal,
    closeModal: closeEmailTeesheetModal,
  } = useModal<IEmailTeeSheet>({
    subject: "",
    content: "",
    recipientIds: [],
    newEmail: "",
    additionalEmails: [],
  });

  const {
    state: printTeesheetModal,
    updateModal: updatePrintTeesheetModal,
    closeModal: closePrintTeesheetModal,
  } = useModal<IPrintTeesheet>({
    startTime: "06:00",
    endTime: "21:00",
    date: null,
    fontSize: 8,
    selectedDivisionIds: [],
    selectedCourseIds: [],
    showCustomerType: false,
    showGuest: false,
    showBagStorage: false,
  });

  const {
    state: statusModal,
    updateModal: updateStatusModal,
    closeModal: closeStatusModal,
  } = useModal<IFacilityStatusState>({
    date: null,
    inputData: [] as Array<{ status_input_id: number; value: string }>,
  });

  const recipients = useMemo(() => {
    const recipients: TRecipient[] = [];

    if (!props.teetimes) {
      return recipients;
    }
    props.teetimes?.map(teeTime => {
      if (teeTime.slots[0].blocked_type !== "crossover" && teeTime.slots[0].quantity_remaining !== 4) {
        recipients.push({ id: teeTime.slots[0].tee_time_id, start_time: teeTime.start_time });
      }
    });

    return recipients;
  }, [props.teetimes]);

  const bookedSlotsCount = useMemo(() => {
    let count = 0;

    if (!props.teetimes) {
      return count;
    }

    props.teetimes.map(teeTime => {
      if (teeTime.slots[0].blocked_type === "open" || teeTime.slots[0].blocked_type === "shotgun") {
        teeTime.slots.map(slot => {
          if (slot.tee_time_slot_status === "booked") {
            count += 1;
          }
        });
      }
    });

    return count;
  }, [props.teetimes]);

  const availableSlotsCount = useMemo(() => {
    let count = 0;

    if (!props.teetimes) {
      return count;
    }

    props.teetimes.map(teeTime => {
      if (teeTime.slots[0].blocked_type === "open" || teeTime.slots[0].blocked_type === "shotgun") {
        teeTime.slots.map(slot => {
          if (slot.tee_time_slot_status === "available") {
            count += 1;
          }
        });
      }
    });

    return count;
  }, [props.teetimes]);

  const currentNoShows = useMemo(() => {
    const count: string[] = [];

    if (!props.teetimes) {
      return {
        total: 0,
        no_show_charge: 0,
        no_show_weather: 0,
        no_show_defer: 0,
      };
    }

    props.teetimes.map(teeTime => {
      if (teeTime.slots[0].blocked_type === "open" || teeTime.slots[0].blocked_type === "shotgun") {
        teeTime.slots.map(slot => {
          if (slot.customer_id && slot.check_in_status === "no_show") {
            count.push(slot.no_show_type);
          }
        });
      }
    });

    // Add more filters to return obj for more no_shows
    return {
      total: count.length,
      no_show_charge: count.filter(val => val === "charge").length,
      no_show_weather: count.filter(val => val === "weather").length,
      no_show_defer: count.filter(val => val === "defer").length,
    };
  }, [props.teetimes]);

  async function sendTeesheetEmail() {
    const formattedDate = formatDate(chosenTeeSheetStore.selectedDate as Date);

    const params = {
      date: formattedDate,
      subject: emailTeesheetModal.subject,
      content: formatMarkdownBreaks(emailTeesheetModal.content),
      tee_time_ids: emailTeesheetModal.recipientIds,
      additional_email_addresses: emailTeesheetModal.additionalEmails,
    };

    const res = await PostEmailTeeSheet(params, true);

    if (res.status !== StatusCode.OK) {
      return;
    }

    closeEmailTeesheetModal();
  }

  async function printTeesheet() {
    const formattedDate = formatDate(printTeesheetModal.date);

    const params = {
      date: formattedDate,
      start_time: printTeesheetModal.startTime,
      end_time: printTeesheetModal.endTime,
      division_ids: printTeesheetModal.selectedDivisionIds,
      course_ids: printTeesheetModal.selectedCourseIds,
      font_size: printTeesheetModal.fontSize,
      show_bag_storage: printTeesheetModal.showBagStorage,
      show_customer_type: printTeesheetModal.showCustomerType,
      facility_id: props.client ? clientFacilityStore?.facility?.id : undefined,
    };

    if (props.client) {
      const teeSheetRes = await ClientTeeSheetPrint(params, true);

      if (teeSheetRes.status === StatusCode.OK) {
        window.open().document.write(teeSheetRes.data);
      } else {
        dispatch(showError(t("elements.tee_sheet.statistic_bar.013")));
      }
    } else {
      const teeSheetRes = await GetTeeSheetPrint(params, true);

      if (teeSheetRes.status === StatusCode.OK) {
        window.open().document.write(teeSheetRes.data);
      } else {
        dispatch(showError(t("elements.tee_sheet.statistic_bar.013")));
      }
    }
  }

  async function saveFacilityStatus() {
    const { isOpen, ...rest } = statusModal;
    const params: TPostFacilityStatus = {
      date: formatDate(rest.date),
      input_data: rest.inputData,
    };

    const res = await PostFacilityStatus(params, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error saving facility status.")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Successfully saved the faclity status.")); // TODO: Translation
    closeStatusModal();
  }

  return (
    <div className="statistics-bar">
      <div className="statistics-bar-booking-content">
        <InformationItem
          label={t("elements.tee_sheet.statistic_bar.001")}
          icon={["far", "dot-circle"]}
          value={bookedSlotsCount}
        />
        <InformationItem
          label={t("elements.tee_sheet.statistic_bar.002")}
          icon={["far", "circle"]}
          value={availableSlotsCount}
        />
        <InformationItem
          label={t("elements.tee_sheet.statistic_bar.003")}
          icon={["far", "percent"]}
          value={`${Math.round((bookedSlotsCount / (bookedSlotsCount + availableSlotsCount)) * 100)}%`}
        />
        <InformationItem
          label={currentNoShows.total === 1 ? "No Show" : "No Shows"} // TODO: Translation
          icon={["far", "ban"]}
          value={currentNoShows.total}
          hoverable
        >
          <ul className="information-item-hover-content">
            <li>
              <p className="type">Charge</p> {/* TODO: Translation */}
              <p className="value">{currentNoShows.no_show_charge}</p>
            </li>
            <li>
              <p className="type">Deferred</p> {/* TODO: Translation */}
              <p className="value">{currentNoShows.no_show_defer}</p>
            </li>
            <li>
              <p className="type">Weather</p> {/* TODO: Translation */}
              <p className="value">{currentNoShows.no_show_weather}</p>
            </li>
          </ul>
        </InformationItem>
      </div>
      <div className="statistics-bar-action-content">
        <div>{chosenFacilityStore.weather && <Weather weather={chosenFacilityStore.weather} />}</div>

        <div className="statistics-bar-action-content-actions">
          <NavigationDropdownNew
            rightAlign
            label={"Options"} // TODO: Translation
            type="text"
            sections={[
              [
                {
                  label: t("elements.tee_sheet.statistic_bar.004"),
                  onClick: () =>
                    updatePrintTeesheetModal({ isOpen: true, date: chosenTeeSheetStore.selectedDate as Date }),
                  icon: "print",
                },
              ],
              [
                {
                  label: "Daily Status", // TODO: Translation
                  onClick: () => updateStatusModal({ isOpen: true, date: chosenTeeSheetStore.selectedDate as Date }),
                  icon: "calendars", // TEMP ICON
                  hidden: props.client,
                },
              ],
              [
                {
                  label: t("elements.tee_sheet.statistic_bar.005"),
                  onClick: () => updateEmailTeesheetModal({ isOpen: true }),
                  icon: "envelope",
                  hidden: props.client,
                },
              ],
              [
                {
                  label: t("elements.tee_sheet.statistic_bar.006"),
                  onClick: () => history.push("/admin/teesheet/edit"),
                  icon: "edit",
                  hidden: props.client,
                },
              ],
            ]}
          />
        </div>
      </div>

      <EmailTeesheetModal
        state={emailTeesheetModal}
        update={updateEmailTeesheetModal}
        close={closeEmailTeesheetModal}
        recipients={recipients}
        onOk={() => sendTeesheetEmail()}
      />

      <PrintTeesheetModal
        state={printTeesheetModal}
        update={updatePrintTeesheetModal}
        close={closePrintTeesheetModal}
        onOk={() => printTeesheet()}
        facility={chosenFacilityStore.facility}
      />

      <StatusModal
        state={statusModal}
        update={updateStatusModal}
        close={closeStatusModal}
        onOk={() => saveFacilityStatus()}
      />
    </div>
  );
}
