import { APIGet, APIPost, APIPut, APIDelete, StatusCode } from "api/protocols";
import { TEventRound } from "redux/reducers/models/league";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseLeagueUrl = "/" + apiVersion + "/" + adminType + "/league";

interface ISkinsRounds extends TEventRound {
  customer_id: number;
  id: number;
  skins_sheet_id: number;
  round_id: number;
}
interface ISkinsRoundPromise {
  status: StatusCode;
  data: {
    rounds: Array<ISkinsRounds>;
    best_scores: Array<number>;
  };
}

//GET Skins Sheet
export async function GetSkinsSheet(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseLeagueUrl + "/scoring/skins-sheet", params, useGlobalLoader);
}

// PUT Skins Sheet
export async function PutSkinsSheet(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseLeagueUrl + "/scoring/skins-sheet/round", params, useGlobalLoader);
}

// POST Skins Sheet
export async function PostSkinsSheet(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseLeagueUrl + "/scoring/skins-sheet", params, useGlobalLoader);
}

// GET Skins Round
export async function GetSkinsRound(params: any, useGlobalLoader: boolean): Promise<ISkinsRoundPromise> {
  return await APIGet(baseLeagueUrl + "/scoring/skins-sheet/round", params, useGlobalLoader);
}
