import React, { ChangeEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";
import { StatusCode } from "api/protocols";
import { GetLeague, PutLeague } from "api/rpc/2024-04/facilityAdmin/league/league";
import { showError, showSuccess } from "redux/actions/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import Page from "components/page/Page";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import Checkbox from "components/form/checkbox/Checkbox";
import TimePick from "components/timePick/TimePick";
import { ILeague } from "redux/reducers/models/league";

interface ILeagueState {
  league: ILeague;
}

export default function LeagueTeeTimes() {
  const history = useHistory();
  const { leagueId } = useParams<{ leagueId: string }>();
  const { t, i18n } = useTranslation();

  const { facilityStore } = useAppSelector(store => store);
  const dispatch = useAppDispatch();

  const [leagueState, setLeagueState] = useState<ILeagueState>({
    league: null,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (facilityStore.facility) {
      void loadLeague(leagueId, source.token);
    }

    return () => {
      source.cancel();
    };
  }, [leagueId, facilityStore]);

  async function loadLeague(id?: string, token?: CancelToken) {
    const leagueRes = await GetLeague({ id: id }, true, token);

    if (token && token.reason) {
      return;
    }
    if (leagueRes.status !== StatusCode.OK) {
      dispatch(showError("Error getting league"));
      return;
    }

    setLeagueState(prevState => ({ ...prevState, league: leagueRes?.data[0] }));
  }

  async function updateLeague() {
    if (
      !leagueState?.league?.default_open_time ||
      leagueState?.league?.default_days_in_advance_end === "" ||
      leagueState?.league?.default_days_in_advance_start === ""
    ) {
      dispatch(showError("Fill all required inputs"));
      return;
    }

    const leagueParams = {
      id: leagueId,
      enable_custom_booking_window: leagueState?.league?.enable_custom_booking_window,
      default_open_time: leagueState?.league?.default_open_time,
      default_days_in_advance_start: Number(leagueState?.league?.default_days_in_advance_start),
      default_days_in_advance_end: Number(leagueState?.league?.default_days_in_advance_end),
    };

    const updateRes = await PutLeague(leagueParams, true);

    if (updateRes.status !== StatusCode.OK) {
      dispatch(showError("Error updating league settings"));
      return;
    }
    dispatch(showSuccess("Successfully updated league tee time settings"));
  }

  const primaryAction = {
    content: "Save",
    action: () => updateLeague(),
    disabled:
      !leagueState?.league?.default_open_time ||
      leagueState?.league?.default_days_in_advance_end === "" ||
      leagueState?.league?.default_days_in_advance_start === "",
  };

  return (
    <Page title={"League Tee Time Settings"} narrow primaryAction={primaryAction}>
      <Card title={t("secure.facility.league.league_settings.007")}>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Checkbox
                size="medium"
                checked={!!leagueState?.league?.enable_custom_booking_window}
                onChange={() =>
                  setLeagueState(prevState => ({
                    ...prevState,
                    league: {
                      ...prevState.league,
                      enable_custom_booking_window: !prevState?.league?.enable_custom_booking_window,
                    },
                  }))
                }
                label={"Custom Booking Window"}
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
        {leagueState?.league?.enable_custom_booking_window && (
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <TimePick
                  label="Open Time"
                  value={leagueState?.league?.default_open_time}
                  onChange={timeString =>
                    setLeagueState(prevState => ({
                      ...prevState,
                      league: { ...prevState.league, default_open_time: timeString },
                    }))
                  }
                  size="large"
                  align="right"
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Input
                  value={leagueState?.league?.default_days_in_advance_start}
                  label={"Days in Advance Start"}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setLeagueState(prevState => ({
                      ...prevState,
                      league: { ...prevState.league, default_days_in_advance_start: e.target.value },
                    }))
                  }
                  placeholder={"Days in Advance Start"}
                  type="number"
                />
                <Input
                  value={leagueState?.league?.default_days_in_advance_end}
                  label={"Days in Advance End"}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setLeagueState(prevState => ({
                      ...prevState,
                      league: { ...prevState.league, default_days_in_advance_end: e.target.value },
                    }))
                  }
                  placeholder={"Days in Advance End"}
                  type="number"
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        )}
      </Card>
    </Page>
  );
}
