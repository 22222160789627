import React, { useEffect, useRef, useState } from "react";
import Card from "components/card/Card";
import { useHistory, useParams } from "react-router";
import "./TournamentCheckout.scss";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StatusCode } from "api/protocols";
import { IFacility } from "pages/guest/models/facility";
import { IUIActions } from "redux/actions/ui";
import { ICartState } from "redux/reducers/customer/cart";
import { ICartActions } from "redux/actions/customer/cart";
import validator from "validator";
import { PostCustomer } from "api/rpc/guest/customer";
import { PostCheckout } from "api/rpc/guest/cart";
import { PostTransaction, PutCapture } from "api/rpc/guest/transaction";
import { PutCompleteOrder } from "api/rpc/guest/order";
import { ICart } from "redux/reducers/customer/models/cart";
import OrderSummary from "components/OrderSummary/OrderSummary";
import { ITournament } from "redux/reducers/models/tournament";
import {
  GetTournamentRegistrationInput,
  PostTournamentRegistrationInputs,
  PostTournamentParticipant,
  IPostTournamentRegistrationInputData,
} from "api/rpc/guest/tournament";
import {
  determineTournamentRegistrationWarning,
  getTournamentCartToken,
  getTournamentCartTokenName,
} from "./TournamentHome";
import { Select } from "components/select/index";
import { useTranslation } from "react-i18next";
import Page from "components/page/Page";
import Form from "components/form/Form";
import Checkbox from "components/form/checkbox/Checkbox";
import TournamentHeader from "./TournamentHeader";
import { ITournamentRegistrationInput } from "api/rpc/facilityAdmin/tournament/tournamentRegistrationInput";
import { cloneDeep, range } from "lodash";
import { ITournamentRegistrationState } from "redux/reducers/customer/tournamentRegistration";
import { ITournamentRegistrationActions } from "redux/actions/customer/tournamentRegistration";

interface ITournamentCheckoutParams {
  facilityShortName: string;
  tournamentHandle: string;
}

interface ITournamentCheckoutProps {
  cartStore: ICartState;
  cartActions: ICartActions;
  uiActions: IUIActions;
  tournamentRegistrationStore: ITournamentRegistrationState;
  tournamentRegistrationActions: ITournamentRegistrationActions;
}

interface ITournamentCheckoutInputField {
  data: string;
  isDirty: boolean;
  isValid: boolean;
}

interface ITournamentCheckoutPlayerForm {
  index: number;
  groupId: number;
  feeId: number;
  firstNameField: ITournamentCheckoutInputField;
  lastNameField: ITournamentCheckoutInputField;
  emailAddressField: ITournamentCheckoutInputField;
  registrationInputs: ITournamentRegistrationInput[];
  registrationInputsData: TournamentRegistrationInputData[];
}

interface ITournamentCheckoutState {
  customerNotes: string;
  cardElementComplete: boolean;
  cardElementIsDirty: boolean;
  playerForms: ITournamentCheckoutPlayerForm[];
  addCustomer: boolean;
}

type TournamentRegistrationInputData = {
  id: number;
  isValid: boolean;
  isDirty: boolean;
} & ({ type: "select"; value: string } | { type: "input"; value: string } | { type: "checkbox"; value: boolean });

interface ITournamentCheckoutPreviousAttempt {
  customersStep: {
    customerIds: number[];
  };
  customerCartStep: {
    customerCart: ICart;
  };
  checkoutStep: {
    orderToken: string;
    orderAmount: number;
    orderId: number;
  };
  transactionStep: {
    transactionId: number;
    clientSecret: string;
  };
  confirmPaymentStep: {
    paymentIntentId: string;
  };
  captureStepCompleted: boolean;
  completeOrderStepCompleted: boolean;
  tournamentParticipantStep: {
    tournamentParticipantIds: number[];
  };
  registrationInputStepCompleted: boolean;
}

export default function TournamentCheckout(props: ITournamentCheckoutProps) {
  const { facilityShortName, tournamentHandle } = useParams<ITournamentCheckoutParams>();
  const { cartStore, cartActions, uiActions, tournamentRegistrationStore, tournamentRegistrationActions } = props;
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const { t, i18n } = useTranslation();
  const { Option } = Select;

  const playerFormRefs = useRef<HTMLDivElement[]>([]);
  const paymentFormRef = useRef<HTMLDivElement>(null);

  const [tournamentCheckoutState, setTournamentCheckoutState] = useState<ITournamentCheckoutState>({
    customerNotes: "",
    cardElementComplete: false,
    cardElementIsDirty: false,
    playerForms: [],
    addCustomer: true,
  });

  const [previousAttempt, setPreviousAttempt] = useState<ITournamentCheckoutPreviousAttempt>({
    customersStep: null,
    customerCartStep: null,
    checkoutStep: null,
    transactionStep: null,
    confirmPaymentStep: null,
    captureStepCompleted: false,
    completeOrderStepCompleted: false,
    tournamentParticipantStep: null,
    registrationInputStepCompleted: false,
  });

  function onBeforeUnload(event: any) {
    if (cartStore.cart.status === "complete") {
      event.preventDefault();
      return (event.returnValue = "");
    }
  }

  useEffect(() => {
    window.addEventListener("beforeunload", onBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [cartStore.cart]);

  useEffect(() => {
    void loadTournamentCheckout();
  }, []);

  function navigateToHome() {
    history.push(`/tournament/${facilityShortName}/${tournamentHandle}/`);
  }

  function navigateToConfirmation(orderToken: string) {
    history.push(`/tournament/${facilityShortName}/${tournamentHandle}/${orderToken}/confirmation`);
  }

  function isTournamentFormInputValueValid(required: boolean, value: string | boolean) {
    return !required || (required && !!value);
  }

  async function loadTournamentCheckout() {
    let facility: IFacility = undefined;

    if (!tournamentRegistrationStore.activeRegistrationFees) {
      navigateToHome();
      return;
    }

    uiActions.enqueue();

    try {
      const getFacilityPromise: Promise<IFacility> = tournamentRegistrationStore.facility
        ? Promise.resolve(tournamentRegistrationStore.facility)
        : (tournamentRegistrationActions.loadFacility(facilityShortName, false) as unknown as Promise<IFacility>);

      facility = await getFacilityPromise;
    } catch {
      uiActions.showError(t("guest.tournament_registration.tournament_checkout.001"));
      uiActions.dequeue();
      return;
    }

    const facilityId = facility.id;

    let tournament: ITournament = undefined;

    try {
      const getTournamentPromise: Promise<ITournament> = tournamentRegistrationStore.tournament
        ? Promise.resolve(tournamentRegistrationStore.tournament)
        : (tournamentRegistrationActions.loadTournament(
            facilityId,
            tournamentHandle,
            true,
          ) as unknown as Promise<ITournament>);

      tournament = await getTournamentPromise;
    } catch {
      uiActions.showError(t("guest.tournament_registration.tournament_checkout.002"));
      uiActions.dequeue();
      return;
    }

    if (!tournament || determineTournamentRegistrationWarning(tournament) !== "none") {
      uiActions.dequeue();
      navigateToHome();
      return;
    }

    let cart: ICart = undefined;

    if (getTournamentCartToken(facilityShortName, tournamentHandle)) {
      try {
        const getCartPromise: Promise<ICart> = cartStore?.isLoaded
          ? Promise.resolve(cartStore?.cart)
          : (cartActions.loadCart(
              {
                facility_id: facilityId,
                tokenName: getTournamentCartTokenName(facilityShortName, tournamentHandle),
                token: getTournamentCartToken(facilityShortName, tournamentHandle),
              },
              true,
            ) as unknown as Promise<ICart>);

        cart = await getCartPromise;
      } catch {
        uiActions.dequeue();
        navigateToHome();
        return;
      }
    } else {
      uiActions.dequeue();
      navigateToHome();
      return;
    }

    if (cart === undefined || cart.line_items.length === 0) {
      uiActions.dequeue();
      navigateToHome();
      return;
    } else if (cart.status === "complete") {
      uiActions.dequeue();
      cancelTransaction();
      return;
    }

    const tournamentRegistrationInputsRes = await GetTournamentRegistrationInput(
      { tournament_id: tournament.id, facility_id: facilityId },
      false,
    );

    if (tournamentRegistrationInputsRes.status !== StatusCode.OK) {
      uiActions.showError("Failed to get tournament registration inputs");
      uiActions.dequeue();
      navigateToHome();
      return;
    }

    const playerForms: ITournamentCheckoutPlayerForm[] = [];
    let addCustomer = true;

    let playerCount = 0;

    for (let f = 0; f < tournamentRegistrationStore.activeRegistrationFees.length; f++) {
      if (tournamentRegistrationStore.activeRegistrationFees[f].type === "add_on") {
        continue;
      }
      for (let q = 1; q <= tournamentRegistrationStore.activeRegistrationFees[f].quantity; q++) {
        const players = tournamentRegistrationStore.activeRegistrationFees[f].players;

        for (let i = 0; i < players; i++) {
          playerForms.push({
            index: playerCount,
            groupId: q,
            feeId: tournamentRegistrationStore.activeRegistrationFees[f].id,
            firstNameField: { data: "", isDirty: false, isValid: false },
            lastNameField: { data: "", isDirty: false, isValid: false },
            emailAddressField: { data: "", isDirty: false, isValid: false },
            registrationInputs: tournamentRegistrationInputsRes.data,
            registrationInputsData: [],
          });
          playerCount++;
        }

        playerForms.forEach(form => {
          form.registrationInputs.sort((prevInput, nextInput) => {
            const prevInputRow = prevInput.row;
            const nextInputRow = nextInput.row;

            if (prevInputRow < nextInputRow) {
              return -1;
            } else if (prevInputRow > nextInputRow) {
              return 1;
            } else {
              return prevInput.column - nextInput.column;
            }
          });
        });

        playerForms.forEach(form => {
          const registrationInputsData: TournamentRegistrationInputData[] = [];

          form.registrationInputs.forEach(input => {
            let registrationInputData: TournamentRegistrationInputData = undefined;

            if (input.type === "select") {
              registrationInputData = {
                id: input.id,
                isValid: isTournamentFormInputValueValid(input.required, ""),
                isDirty: false,
                type: "select",
                value: "",
              };
            } else if (input.type === "input") {
              registrationInputData = {
                id: input.id,
                isValid: isTournamentFormInputValueValid(input.required, ""),
                isDirty: false,
                type: "input",
                value: "",
              };
            } else if (input.type === "checkbox") {
              registrationInputData = {
                id: input.id,
                isValid: isTournamentFormInputValueValid(input.required, false),
                isDirty: false,
                type: "checkbox",
                value: false,
              };
            }

            if (registrationInputData) {
              registrationInputsData.push(registrationInputData);
            }
          });

          form.registrationInputsData = registrationInputsData;
        });
      }
    }

    // Create player form if fees are only add ons
    if (playerForms.length <= 0) {
      playerForms.push({
        index: 0,
        groupId: 1,
        feeId: tournamentRegistrationStore.activeRegistrationFees[0].id,
        firstNameField: { data: "", isDirty: false, isValid: false },
        lastNameField: { data: "", isDirty: false, isValid: false },
        emailAddressField: { data: "", isDirty: false, isValid: false },
        registrationInputs: [],
        registrationInputsData: [],
      });
      addCustomer = false;
    }

    setTournamentCheckoutState(prev => ({
      ...prev,
      playerForms,
      addCustomer: addCustomer,
    }));

    uiActions.dequeue();
  }

  function cancelTransaction() {
    cartActions.cartClear({ tokenName: getTournamentCartTokenName(facilityShortName, tournamentHandle) });
    navigateToHome();
  }

  function handleCancelTransactionOnClick() {
    if (cartStore.cart.status === "complete") {
      cancelTransaction();
    } else {
      navigateToHome();
    }
  }

  function requiredValueExists(value: string) {
    return value !== "" && value !== undefined;
  }

  function emailIsValid(email: string) {
    if (email === undefined || email === null) {
      return false;
    } else {
      return validator.isEmail(email);
    }
  }

  function handlePlayerFormInputFieldChange(playerFormIndex: number, id: string, value: string, isValid: boolean) {
    if (playerFormIndex === undefined || id === undefined || value === undefined || isValid === undefined) {
      return;
    }

    let updatedPlayerForm = cloneDeep(tournamentCheckoutState.playerForms?.[playerFormIndex]);

    if (!updatedPlayerForm) {
      return;
    }

    updatedPlayerForm = {
      ...updatedPlayerForm,
      [id]: {
        ...(updatedPlayerForm[id as keyof ITournamentCheckoutPlayerForm] as Record<string, any>),
        data: value,
        isValid: isValid,
        isDirty: true,
      },
    };

    const updatedPlayerForms = cloneDeep(tournamentCheckoutState.playerForms);

    updatedPlayerForms[playerFormIndex] = updatedPlayerForm;

    setTournamentCheckoutState(prev => {
      return {
        ...prev,
        playerForms: updatedPlayerForms,
      };
    });
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setTournamentCheckoutState(prev => ({ ...prev, [id]: value }));
  }

  function handleCardElementChange(e: any) {
    setTournamentCheckoutState(prev => ({ ...prev, cardElementComplete: e.complete }));
  }

  function completePaymentError(errorMessage: string) {
    uiActions.dequeue();
    cartActions.loadCart(
      {
        facility_id: tournamentRegistrationStore.facility.id,
        tokenName: getTournamentCartTokenName(facilityShortName, tournamentHandle),
        token: getTournamentCartToken(facilityShortName, tournamentHandle),
      },
      true,
    );
    uiActions.showError(errorMessage);
  }

  async function completeOrder(tournament: ITournament, orderToken: string, orderId: number, customerIds: number[]) {
    if (!previousAttempt.completeOrderStepCompleted) {
      const putCompleteOrder = await PutCompleteOrder({ token: orderToken }, false);

      if (putCompleteOrder.status !== StatusCode.OK) {
        completePaymentError(t("guest.tournament_registration.tournament_checkout.008"));
        return;
      }

      setPreviousAttempt(prev => ({
        ...prev,
        completeOrderStepCompleted: true,
      }));
    }

    let tournamentParticipantIds = previousAttempt.tournamentParticipantStep?.tournamentParticipantIds;

    if (tournamentCheckoutState.addCustomer) {
      if (tournamentParticipantIds == null) {
        try {
          tournamentParticipantIds = await Promise.all(
            customerIds.map(async customerId => {
              const postTournamentParticipant = await PostTournamentParticipant(
                {
                  facility_id: tournamentRegistrationStore.facility.id,
                  order_id: orderId,
                  tournament_id: tournament.id,
                  customer_id: customerId,
                },
                false,
              );

              if (postTournamentParticipant.status !== StatusCode.OK) {
                throw new Error();
              } else {
                return postTournamentParticipant.data.id;
              }
            }),
          );
        } catch {
          completePaymentError(t("guest.tournament_registration.tournament_checkout.009"));
          return;
        }

        setPreviousAttempt(prev => ({
          ...prev,
          tournamentParticipantStep: {
            tournamentParticipantIds,
          },
        }));
      }

      if (!previousAttempt.registrationInputStepCompleted) {
        const registrationInputData: IPostTournamentRegistrationInputData[] = [];

        for (let i = 0; i < tournamentCheckoutState.playerForms.length; i++) {
          const playerForm = tournamentCheckoutState.playerForms[i];
          const tournamentParticipantId = tournamentParticipantIds[i];

          const playerFormRegistrationInputData: IPostTournamentRegistrationInputData[] =
            playerForm.registrationInputsData.map(registrationInputData => ({
              registration_input_id: registrationInputData.id,
              value: registrationInputData.value,
              participant_id: tournamentParticipantId,
            }));

          registrationInputData.push(...playerFormRegistrationInputData);
        }

        const postTournamentRegistrationInputs = await PostTournamentRegistrationInputs(
          {
            tournament_id: tournament.id,
            facility_id: tournamentRegistrationStore.facility.id,
            registration_input_data: registrationInputData,
          },
          false,
        );

        if (postTournamentRegistrationInputs.status !== StatusCode.OK) {
          completePaymentError("Failed to submit registration inputs");
          return;
        }

        setPreviousAttempt(prev => ({
          ...prev,
          registrationInputStepCompleted: true,
        }));
      }
    }

    try {
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await tournamentRegistrationActions.loadTournament(
        tournamentRegistrationStore.facility.id,
        tournamentHandle,
        false,
      );
    } catch {
      tournamentRegistrationActions.clearTournament();
    }

    uiActions.dequeue();
    cartActions.cartClear({ tokenName: getTournamentCartTokenName(facilityShortName, tournamentHandle) });
    navigateToConfirmation(orderToken);
  }

  async function completeCheckout() {
    uiActions.enqueue();

    let tournament: ITournament = null;

    try {
      const getTournamentPromise: Promise<ITournament> = tournamentRegistrationActions.loadTournament(
        tournamentRegistrationStore.facility.id,
        tournamentHandle,
        true,
      ) as unknown as Promise<ITournament>;
      tournament = await getTournamentPromise;
    } catch {
      uiActions.dequeue();
      uiActions.showError(t("guest.tournament_registration.tournament_checkout.002"));
      return;
    }

    if (
      determineTournamentRegistrationWarning(tournament) !== "none" &&
      previousAttempt.tournamentParticipantStep?.tournamentParticipantIds == null
    ) {
      uiActions.dequeue();
      uiActions.showError("Registration not allowed");
      cancelTransaction();
      return;
    }

    let customerIds: number[] = previousAttempt.customersStep?.customerIds;

    if (customerIds == null) {
      try {
        customerIds = await Promise.all(
          tournamentCheckoutState.playerForms?.map(async playerForm => {
            const postCustomerResponse = await PostCustomer(
              {
                first_name: playerForm.firstNameField.data,
                last_name: playerForm.lastNameField.data,
                email: playerForm.emailAddressField.data,
              },
              false,
            );

            if (postCustomerResponse.status !== StatusCode.OK) {
              throw new Error();
            } else {
              return postCustomerResponse.data.id;
            }
          }),
        );

        setPreviousAttempt(prev => ({
          ...prev,
          customersStep: {
            customerIds,
          },
        }));
      } catch {
        uiActions.dequeue();
        uiActions.showError(t("guest.tournament_registration.tournament_checkout.010"));
        return;
      }
    }

    let customerCart: ICart = previousAttempt.customerCartStep?.customerCart;

    if (customerCart == null) {
      try {
        // eslint-disable-next-line @typescript-eslint/await-thenable
        customerCart = (await cartActions.putCart(
          {
            cart_token: cartStore.cart.token,
            customer_email: tournamentCheckoutState.playerForms[0]?.emailAddressField?.data,
            customer_notes: tournamentCheckoutState.customerNotes,
          },
          false,
        )) as unknown as ICart;

        setPreviousAttempt(prev => ({
          ...prev,
          customerCartStep: {
            customerCart,
          },
        }));
      } catch {
        uiActions.dequeue();
        uiActions.showError(t("guest.tournament_registration.tournament_checkout.011"));
        return;
      }
    }

    let orderToken = previousAttempt.checkoutStep?.orderToken;
    let orderAmount = previousAttempt.checkoutStep?.orderAmount;
    let orderId = previousAttempt.checkoutStep?.orderId;

    if (orderToken == null || orderAmount == null || orderId == null) {
      const postCheckoutResponse = await PostCheckout({ cart_token: customerCart.token }, false);
      const order = postCheckoutResponse.data?.order;

      if (postCheckoutResponse.status !== StatusCode.OK) {
        completePaymentError(t("guest.tournament_registration.tournament_checkout.003"));
        return;
      }

      if (order?.token == null) {
        completePaymentError(t("guest.tournament_registration.tournament_checkout.004"));
        return;
      }

      orderToken = order.token;
      orderAmount = order.total_price;
      orderId = order.id;

      setPreviousAttempt(prev => ({
        ...prev,
        checkoutStep: {
          orderToken,
          orderAmount,
          orderId,
        },
      }));
    }

    if (customerCart?.total_price === 0) {
      await completeOrder(tournament, orderToken, orderId, customerIds);
      return;
    }

    let transactionId = previousAttempt.transactionStep?.transactionId;
    let clientSecret = previousAttempt.transactionStep?.clientSecret;

    if (transactionId == null || clientSecret == null) {
      const postTransactionResponse = await PostTransaction(
        {
          processing_type: "online",
          order_token: orderToken,
          kind: "authorization",
          source: "online",
          amount: orderAmount,
          payment_method: "card",
        },
        false,
      );

      clientSecret = postTransactionResponse.data?.client_secret;

      if (postTransactionResponse.status !== StatusCode.OK || clientSecret == null) {
        completePaymentError(t("guest.tournament_registration.tournament_checkout.005"));
        return;
      }

      transactionId = postTransactionResponse.data.id;

      setPreviousAttempt(prev => ({
        ...prev,
        transactionStep: {
          transactionId,
          clientSecret,
        },
      }));
    }

    let paymentIntentId = previousAttempt.confirmPaymentStep?.paymentIntentId;

    if (paymentIntentId == null) {
      if (!stripe || !elements) {
        completePaymentError(t("guest.tournament_registration.tournament_checkout.006"));
        return;
      }

      const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

      if (stripeError) {
        completePaymentError(stripeError.message);
        return;
      }

      paymentIntentId = paymentIntent.id;

      setPreviousAttempt(prev => ({
        ...prev,
        confirmPaymentStep: {
          paymentIntentId,
        },
      }));
    }

    if (!previousAttempt.captureStepCompleted) {
      const putCaptureResponse = await PutCapture(
        { payment_intent_id: paymentIntentId, transaction_id: transactionId },
        false,
      );

      if (putCaptureResponse.status !== StatusCode.OK) {
        completePaymentError(t("guest.tournament_registration.tournament_checkout.007"));
        return;
      }

      setPreviousAttempt(prev => ({
        ...prev,
        captureStepCompleted: true,
      }));
    }

    await completeOrder(tournament, orderToken, orderId, customerIds);
  }

  function handleCompleteCheckout() {
    setTournamentCheckoutState(prev => ({
      ...prev,
      playerForms: prev.playerForms.map(playerForm => ({
        ...playerForm,
        firstNameField: { ...playerForm.firstNameField, isDirty: true },
        lastNameField: { ...playerForm.lastNameField, isDirty: true },
        emailAddressField: { ...playerForm.emailAddressField, isDirty: true },
        registrationInputsData: playerForm.registrationInputsData.map(inputData => ({
          ...inputData,
          isDirty: true,
        })),
      })),
      cardElementIsDirty: true,
    }));

    for (let i = 0; i < tournamentCheckoutState.playerForms.length; i++) {
      const playerForm = tournamentCheckoutState.playerForms[i];

      if (
        !playerForm.firstNameField.isValid ||
        !playerForm.lastNameField.isValid ||
        !playerForm.emailAddressField.isValid ||
        playerForm.registrationInputsData.some(inputData => !inputData.isValid)
      ) {
        playerFormRefs?.current?.[i]?.scrollIntoView({ behavior: "smooth", block: "center" });
        return;
      }
    }

    if (!tournamentCheckoutState.cardElementComplete && cartStore?.cart?.total_price !== 0) {
      paymentFormRef?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
      return;
    }

    void completeCheckout();
  }

  function handlePlayerFormRegistrationInputDataChange(
    playerFormIndex: number,
    registrationInputDataToChange: TournamentRegistrationInputData,
  ) {
    const updatedPlayerForm = cloneDeep(tournamentCheckoutState.playerForms[playerFormIndex]);

    const isRequired = updatedPlayerForm.registrationInputs.find(
      input => input.id === registrationInputDataToChange.id,
    )?.required;

    const inputToChangeIndex = updatedPlayerForm.registrationInputsData.findIndex(
      inputData => inputData.id === registrationInputDataToChange.id,
    );

    if (inputToChangeIndex !== -1) {
      updatedPlayerForm.registrationInputsData[inputToChangeIndex] = {
        ...registrationInputDataToChange,
        isValid: isTournamentFormInputValueValid(isRequired, registrationInputDataToChange.value),
        isDirty: true,
      };
    }

    const updatedPlayerForms = cloneDeep(tournamentCheckoutState.playerForms);
    updatedPlayerForms[playerFormIndex] = updatedPlayerForm;

    setTournamentCheckoutState(prev => ({ ...prev, playerForms: updatedPlayerForms }));
  }

  function getPlayerFormRegistrationInputRowRange(playerForm: ITournamentCheckoutPlayerForm) {
    if (!playerForm?.registrationInputs) {
      return [];
    }

    let maxRow = 0;

    playerForm.registrationInputs.forEach(input => {
      if (input.row > maxRow) {
        maxRow = input.row;
      }
    });

    return range(1, maxRow + 1, 1);
  }

  return (
    <Page>
      {tournamentRegistrationStore.facility &&
        tournamentRegistrationStore.tournament &&
        cartStore.isLoaded &&
        cartStore.cart && (
          <>
            <TournamentHeader
              facility={tournamentRegistrationStore.facility}
              tournament={tournamentRegistrationStore.tournament}
            />
            <div className="tournament-checkout-sections">
              <div className="tournament-checkout-information-section">
                {tournamentRegistrationStore.activeRegistrationFees
                  .filter(fee => (tournamentCheckoutState.addCustomer ? fee.type === "registration_fee" : true))
                  .map(fee => {
                    const groups = [];
                    for (let group = 1; group <= fee.quantity; group++) {
                      groups.push(
                        <div key={group} className="player-form-container">
                          <div className="player-form-fee-header">
                            <div>
                              <div>{fee.product_title}</div>
                              {fee.product_title !== fee.variant_title && (
                                <div className="player-form-fee-header-subtitle">{fee.variant_title}</div>
                              )}
                            </div>
                          </div>
                          {tournamentCheckoutState.playerForms
                            .filter(form => form.feeId === fee.id)
                            .filter(form => form.groupId === group)
                            .map((playerForm, playerFormIndex) => {
                              return (
                                <div
                                  key={playerFormIndex}
                                  ref={el => (playerFormRefs.current[playerForm.index] = el)}
                                  className="player-form-input-container"
                                >
                                  <h4 className="mb-2 mt-2">{`Player ${playerFormIndex + 1}`}</h4>
                                  <FormLayout>
                                    <FormLayout.Group>
                                      <div className="required">
                                        <Input
                                          error={
                                            playerForm.firstNameField.isDirty && !playerForm.firstNameField.isValid
                                          }
                                          value={playerForm.firstNameField.data}
                                          label={t("guest.tournament_registration.tournament_checkout.013")}
                                          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                            handlePlayerFormInputFieldChange(
                                              playerForm.index,
                                              "firstNameField",
                                              event?.target?.value,
                                              requiredValueExists(event?.target?.value),
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="required">
                                        <Input
                                          error={playerForm.lastNameField.isDirty && !playerForm.lastNameField.isValid}
                                          value={playerForm.lastNameField.data}
                                          label={t("guest.tournament_registration.tournament_checkout.014")}
                                          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                            handlePlayerFormInputFieldChange(
                                              playerForm.index,
                                              "lastNameField",
                                              event?.target?.value,
                                              requiredValueExists(event?.target?.value),
                                            )
                                          }
                                        />
                                      </div>
                                    </FormLayout.Group>
                                    <FormLayout.Group>
                                      <div className="required">
                                        <Input
                                          error={
                                            playerForm.emailAddressField.isDirty &&
                                            !playerForm.emailAddressField.isValid
                                          }
                                          value={playerForm.emailAddressField.data}
                                          label={t("guest.tournament_registration.tournament_checkout.015")}
                                          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                            handlePlayerFormInputFieldChange(
                                              playerForm.index,
                                              "emailAddressField",
                                              event?.target?.value,
                                              emailIsValid(event?.target?.value),
                                            )
                                          }
                                        />
                                      </div>
                                    </FormLayout.Group>
                                    {getPlayerFormRegistrationInputRowRange(playerForm).map(row => {
                                      const rowRegistrationInputs = playerForm.registrationInputs.filter(
                                        input => input.row === row,
                                      );

                                      return (
                                        <FormLayout.Group key={row}>
                                          {rowRegistrationInputs?.map(rowRegistrationInput => {
                                            const rowRegistrationInputData = playerForm.registrationInputsData.find(
                                              dataInput => dataInput.id === rowRegistrationInput.id,
                                            );

                                            if (rowRegistrationInput.type === "text") {
                                              return (
                                                <div key={rowRegistrationInput.id}>
                                                  <div className="player-form-text-input-title">
                                                    {rowRegistrationInput.label}
                                                  </div>
                                                  <div className="player-form-text-input-content">
                                                    {rowRegistrationInput.help_text}
                                                  </div>
                                                </div>
                                              );
                                            } else if (!rowRegistrationInputData) {
                                              return null;
                                            } else if (rowRegistrationInput.type === "select") {
                                              return (
                                                <div
                                                  key={rowRegistrationInput.id}
                                                  className={rowRegistrationInput.required ? " required" : ""}
                                                >
                                                  <Select
                                                    label={rowRegistrationInput.label}
                                                    onChange={(value: string) => {
                                                      if (value !== rowRegistrationInputData.value) {
                                                        handlePlayerFormRegistrationInputDataChange(playerForm.index, {
                                                          ...rowRegistrationInputData,
                                                          type: "select",
                                                          value,
                                                        });
                                                      }
                                                    }}
                                                    defaultValue={rowRegistrationInputData.value as string}
                                                    error={
                                                      !rowRegistrationInputData.isValid &&
                                                      rowRegistrationInputData.isDirty
                                                    }
                                                    helpText={rowRegistrationInput.help_text}
                                                  >
                                                    {Array.isArray(rowRegistrationInput.values) &&
                                                      rowRegistrationInput.values.map((value: any, index: number) => {
                                                        return (
                                                          <Option key={index} value={value} name={value}>
                                                            {value}
                                                          </Option>
                                                        );
                                                      })}
                                                  </Select>
                                                </div>
                                              );
                                            } else if (rowRegistrationInput.type === "input") {
                                              return (
                                                <div
                                                  key={rowRegistrationInput.id}
                                                  className={rowRegistrationInput.required ? " required" : ""}
                                                >
                                                  <Input
                                                    label={rowRegistrationInput.label}
                                                    value={rowRegistrationInputData.value as string}
                                                    error={
                                                      !rowRegistrationInputData.isValid &&
                                                      rowRegistrationInputData.isDirty
                                                    }
                                                    onChange={(e: any) =>
                                                      handlePlayerFormRegistrationInputDataChange(playerForm.index, {
                                                        ...rowRegistrationInputData,
                                                        type: "input",
                                                        value: e.target.value,
                                                      })
                                                    }
                                                    helpText={rowRegistrationInput.help_text}
                                                  />
                                                </div>
                                              );
                                            } else if (rowRegistrationInput.type === "checkbox") {
                                              return (
                                                <div
                                                  key={rowRegistrationInput.id}
                                                  className={rowRegistrationInput.required ? " required" : ""}
                                                >
                                                  <Checkbox
                                                    size="medium"
                                                    label={rowRegistrationInput.label}
                                                    checked={rowRegistrationInputData.value as boolean}
                                                    error={
                                                      !rowRegistrationInputData.isValid &&
                                                      rowRegistrationInputData.isDirty
                                                    }
                                                    onChange={(e: any) =>
                                                      handlePlayerFormRegistrationInputDataChange(playerForm.index, {
                                                        ...rowRegistrationInputData,
                                                        type: "checkbox",
                                                        value: e.target.checked,
                                                      })
                                                    }
                                                  />
                                                </div>
                                              );
                                            }
                                          })}
                                        </FormLayout.Group>
                                      );
                                    })}
                                  </FormLayout>
                                </div>
                              );
                            })}
                        </div>,
                      );
                    }
                    return groups;
                  })}

                <Card>
                  <Card.Section title={t("guest.tournament_registration.tournament_checkout.019")}>
                    <Input
                      label={t("guest.tournament_registration.tournament_checkout.020")}
                      id="customerNotes"
                      value={tournamentCheckoutState.customerNotes}
                      onChange={handleInputChange}
                    />
                  </Card.Section>
                </Card>
                {cartStore?.cart?.total_price !== 0 && (
                  <Card>
                    <div ref={paymentFormRef}>
                      <Card.Section title={t("guest.tournament_registration.tournament_checkout.016")}>
                        <div
                          className="player-form-card-element-container"
                          style={{
                            border:
                              !tournamentCheckoutState.cardElementComplete && tournamentCheckoutState.cardElementIsDirty
                                ? "1px solid red"
                                : "1px solid white",
                          }}
                        >
                          <CardElement onChange={handleCardElementChange} options={{ hidePostalCode: true }} />
                        </div>
                      </Card.Section>
                    </div>
                  </Card>
                )}
              </div>
              <div className="tournament-checkout-order-section">
                <OrderSummary
                  subtotal={cartStore?.cart?.subtotal_price ?? 0}
                  taxLines={cartStore?.cart?.tax_lines ?? []}
                  discount={cartStore?.cart?.total_discount ?? 0}
                  total={cartStore?.cart?.total_price ?? 0}
                  displayLineItemsWithoutToggle={true}
                  lineItems={cartStore?.cart?.line_items?.map(lineItem => {
                    return {
                      src: lineItem.product_default_image?.source,
                      productTitle: lineItem.product_title,
                      variantTitle: lineItem.variant_title,
                      quantity: lineItem.quantity,
                      price: lineItem.subtotal_price,
                    };
                  })}
                >
                  <div className="tournament-checkout-order-section-actions">
                    <button
                      className="tournament-checkout-order-section-actions-primary"
                      onClick={handleCompleteCheckout}
                    >
                      {t("guest.tournament_registration.tournament_checkout.017")}
                    </button>
                    <button
                      className="tournament-checkout-order-section-actions-secondary"
                      onClick={handleCancelTransactionOnClick}
                    >
                      {t("guest.tournament_registration.tournament_checkout.018")}
                    </button>
                  </div>
                </OrderSummary>
              </div>
            </div>
          </>
        )}
    </Page>
  );
}
