import React, { useEffect, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import { loadLeague, loadFacility } from "redux/actions/customer/leagueRegistration";
import { GetLeagueScoresheets, GetLeagueAnnouncements } from "api/rpc/2024-04/guest/league/league";
import { StatusCode } from "api/protocols";
import axios, { CancelToken } from "axios";

import LeagueHeader from "../LeagueHeader/LeagueHeader";
import Page from "components/page/Page";
import Card from "components/card/Card";
import { ButtonNew as Button } from "components/buttonNew";
import CopyLink from "components/copyLink/CopyLink";
import { Badge } from "components/badge/Badge";
import { TLeagueScoresheet } from "redux/reducers/models/league";
import { TLeagueAnnouncement } from "api/rpc/2024-04/facilityAdmin/league/announcement";
import { determineLeagueRegistrationWarning } from "../LeagueRegistration/LeagueRegister";

interface IParams {
  facilityShortName: string;
  leagueHandle: string;
}

interface ILeagueHomeState {
  latestScoresheet: TLeagueScoresheet;
  announcements: TLeagueAnnouncement[];
}

const ANNOUNCEMENT_LIMIT = 3;

export default function LeagueHome() {
  const { facilityShortName, leagueHandle } = useParams<IParams>();
  const uiActions = useAppDispatch();
  const { leagueRegistrationStore } = useAppSelector(store => store);
  const history = useHistory();

  const [state, setState] = useState<ILeagueHomeState>({
    latestScoresheet: undefined,
    announcements: undefined,
  });

  useEffect(() => {
    if (!leagueRegistrationStore.facility) {
      void uiActions(loadFacility(facilityShortName, true));
    }
  }, []);

  useEffect(() => {
    if (leagueRegistrationStore.facility && !leagueRegistrationStore.league) {
      void uiActions(loadLeague(leagueRegistrationStore.facility.id, leagueHandle, true));
    }
  }, [leagueRegistrationStore.facility]);

  useEffect(() => {
    if (leagueRegistrationStore.league) {
      const source = axios.CancelToken.source();
      void loadLeagueInfo(source.token);
      return () => source.cancel();
    }
  }, [leagueRegistrationStore.league]);

  async function loadLeagueInfo(token?: CancelToken) {
    const announcementRes = await GetLeagueAnnouncements(
      {
        league_id: leagueRegistrationStore.league?.id,
        limit: ANNOUNCEMENT_LIMIT,
      },
      true,
    );

    if (token && token.reason) {
      return;
    }
    if (announcementRes.status === StatusCode.OK) {
      setState(prevState => ({
        ...prevState,
        announcements: announcementRes.data,
      }));
    }

    const scoresheetRes = await GetLeagueScoresheets(
      {
        league_id: leagueRegistrationStore.league?.id,
        facility_id: leagueRegistrationStore.facility.id,
        latest: true,
      },
      true,
    );

    if (token && token.reason) {
      return;
    }
    if (scoresheetRes.status === StatusCode.OK) {
      setState(prevState => ({
        ...prevState,
        latestScoresheet: scoresheetRes.data[0],
      }));
    }
  }

  function renderRegistration() {
    if (!leagueRegistrationStore.league) {
      return;
    }

    const leagueWarning = determineLeagueRegistrationWarning(leagueRegistrationStore.league);

    const localeStringOptions: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };

    const openDateFormatString = new Date(leagueRegistrationStore.league.registration_open_date + "Z").toLocaleString(
      "en-CA",
      localeStringOptions,
    );

    const closeDateFormatString = new Date(leagueRegistrationStore.league.registration_close_date + "Z").toLocaleString(
      "en-CA",
      localeStringOptions,
    );

    switch (leagueWarning) {
      case "closed":
        return (
          <div className="league-home-registration">
            <div className="league-home-registration-header flex justify-start gap-2">
              <FontAwesomeIcon className="mt-1" icon={"circle-x"} color={"#AA0504"} />
              {"Registration is Closed"}
            </div>
          </div>
        );
      case "will-open":
        return (
          <div className="league-home-registration">
            <div className="league-home-registration-header flex justify-start gap-2">
              <FontAwesomeIcon className="mt-1" icon={"circle-x"} color={"#AA0504"} />
              {"Registration is Closed"}
            </div>
            <div className="league-home-registration-info">
              {`Registration for the league will open on ${openDateFormatString}`}
            </div>
          </div>
        );
      case "closed-previously":
        return (
          <div className="league-home-registration">
            <div className="league-home-registration-header flex justify-start gap-2">
              <FontAwesomeIcon className="mt-1" icon={"circle-x"} color={"#AA0504"} />
              {"Registration is Closed"}
            </div>
            <div className="league-home-registration-info">
              {`Registration for the league ended on ${closeDateFormatString}`}
            </div>
          </div>
        );
      case "no-capacity":
        return (
          <div className="league-home-registration">
            <div className="league-home-registration-header flex justify-start gap-2">
              <FontAwesomeIcon className="mt-1" icon={"circle-x"} color={"#AA0504"} />
              {"Registration is Closed"}
            </div>
            <div className="league-home-registration-info">{"League is currently at capacity"}</div>
          </div>
        );
      case "none":
      default:
        return (
          <div className="league-home-registration">
            <div className="league-home-registration-header flex justify-start gap-2">
              <FontAwesomeIcon className="mt-1" icon={"circle-check"} color={"#46B171"} />
              {"Registration is Open!"}
              <Badge type="success">{`${leagueRegistrationStore.league?.capacity_remaining} Spots Left`}</Badge>
            </div>
            <div className="flex justify-start">
              <Button
                onClick={() => history.push(`/league/${facilityShortName}/${leagueHandle}/register`)}
                type="primary"
              >
                Register {/* TODO: Translation */}
                <FontAwesomeIcon className="ml-2" icon={"arrow-right"} />
              </Button>
            </div>
          </div>
        );
    }
  }

  return (
    <>
      <LeagueHeader facility={leagueRegistrationStore.facility} league={leagueRegistrationStore.league} />
      <Page splitLayout>
        <Page.Section twoThirds>
          <Card title="Current Round">
            {state.latestScoresheet ? (
              <Card.Section>
                <div className="league-current-round">
                  <div className="league-current-round-header">{state.latestScoresheet?.name}</div>
                  <div className="league-current-round-info">
                    {moment(state.latestScoresheet?.date_played).format("MMM DD, YYYY")}
                    <div className="flex justify-start gap-1">
                      {state.latestScoresheet?.front_division_title}
                      {state.latestScoresheet?.division_back_id && (
                        <>
                          <FontAwesomeIcon className="mt-1" icon={"arrow-right"} />
                          {state.latestScoresheet?.back_division_title}
                        </>
                      )}
                    </div>
                    {`${state.latestScoresheet?.participation} Rounds Played`}
                  </div>
                  <div className="league-current-round-actions">
                    <Button
                      onClick={() =>
                        history.push(
                          `/portal/${leagueRegistrationStore.facility?.client_short_name}/teetimes/${facilityShortName}`,
                        )
                      }
                      type="primary"
                    >
                      Book a Tee Time {/* TODO: Translation */}
                      <FontAwesomeIcon className="ml-2" icon={"arrow-right"} />
                    </Button>
                    <Button
                      onClick={() =>
                        history.push(
                          `/league/${facilityShortName}/${leagueHandle}/scoring/results/${state.latestScoresheet?.id}`,
                        )
                      }
                    >
                      Results {/* TODO: Translation */}
                      <FontAwesomeIcon className="ml-2" icon={"arrow-right"} />
                    </Button>
                  </div>
                </div>
              </Card.Section>
            ) : (
              <Card.Section>
                <div className="league-current-round">
                  <div className="league-current-round-header">
                    {"No rounds have been created" /* TODO: Translation */}
                  </div>
                </div>
              </Card.Section>
            )}
          </Card>
          <Card title="Registration">
            <Card.Section>{renderRegistration()}</Card.Section>
          </Card>
        </Page.Section>
        <Page.Section oneThird>
          <Card title="Latest Announcements" /* TODO: Translation */>
            {state.announcements?.length > 0 ? (
              <div className="league-home-announcements">
                {state.announcements.map(announcement => (
                  <Card.Section key={announcement.id}>
                    <div className="league-home-announcements-header">{announcement.subject}</div>
                    <div className="league-home-announcements-details">
                      {moment(announcement.created_at).format("MMM DD, YYYY")}
                    </div>
                    <div className="league-home-announcements-content">{announcement.content}</div>
                  </Card.Section>
                ))}
                <Card.Section>
                  <div
                    className=" league-home-announcements-clickable flex justify-start gap-2"
                    onClick={() => history.push(`/league/${facilityShortName}/${leagueHandle}/announcements`)}
                  >
                    {"View All Announcements" /* TODO: Translation */}
                    <FontAwesomeIcon className="mt-1" icon={"arrow-right"} />
                  </div>
                </Card.Section>
              </div>
            ) : (
              <Card.Section>
                <p>{"No Announcements" /* TODO: Translation */}</p>
              </Card.Section>
            )}
          </Card>
        </Page.Section>
      </Page>
    </>
  );
}
