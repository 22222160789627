import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios, { CancelToken } from "axios";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { GetCreditBook } from "api/rpc/2022-09/facilityAdmin/client/creditBook";
import { GetLeague } from "api/rpc/2024-04/facilityAdmin/league/league";

import { showError } from "redux/actions/ui";
import { ILeague } from "redux/reducers/models/league";

import { useAppDispatch } from "hooks/redux";
import { displayCurrency } from "helpers/Helpers";

import Page from "components/page/Page";
import { IPrizeAccount } from "pages/secure/facility/settings/prizeAccounts/PrizeAccount";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

export default function LeaguePrizeAccounts() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { leagueId }: any = useParams();

  const [prizeAccounts, setPrizeAccounts] = useState<IPrizeAccount[]>(undefined);

  const [leagues, setLeagues] = useState<Array<ILeague>>([]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    void loadPrizeAccounts(source.token);
    void loadLeagues(source.token);

    return () => source.cancel();
  }, []);

  async function loadPrizeAccounts(token?: CancelToken) {
    if (prizeAccounts !== undefined) {
      setPrizeAccounts(undefined);
    }
    const { status, data } = await GetCreditBook(
      { type: "prize_account", league_id: leagueId },
      token ? false : true,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (status !== StatusCode.OK) {
      dispatch(showError("Error loading prize accounts"));
    }

    setPrizeAccounts(status !== StatusCode.OK ? [] : data);
  }

  async function loadLeagues(token?: CancelToken) {
    const { status, data } = await GetLeague(null, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (status !== StatusCode.OK) {
      dispatch(showError("Error loading leagues"));
      return;
    }

    setLeagues(data);
  }

  const primaryAction = {
    content: "New Prize Account",
    action: () => history.push("/admin/league/" + String(leagueId) + "/prizeAccounts/new"),
  };

  return (
    <>
      <Page title={"Prize Accounts"} primaryAction={primaryAction} narrow>
        <DataTable
          columns={[{ label: "Name" }, { label: "Event" }, { label: "Balance" }]}
          loading={prizeAccounts === undefined}
        >
          {prizeAccounts?.map(account => {
            const prizeLeague = leagues.filter(league => league.id === account.league_id);
            console.log(account);

            return (
              <tr
                key={account.id}
                onClick={() =>
                  history.push("/admin/league/" + String(leagueId) + "/prizeAccounts/view/" + String(account.id))
                }
                className="clickable"
              >
                <td>
                  <p>{account.title}</p>
                </td>
                <td>
                  <p>{prizeLeague[0] ? prizeLeague[0].name : ""}</p>
                </td>

                <td>
                  <p>{displayCurrency("cad", account.balance)}</p>
                </td>
              </tr>
            );
          })}
        </DataTable>
      </Page>
    </>
  );
}
