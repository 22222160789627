import React, { useEffect, useRef, useState } from "react";
import axios, { CancelToken } from "axios";
import { Prompt, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { StatusCode } from "api/protocols";
import { GetFolder, IFolder } from "api/rpc/2022-09/facilityAdmin/product/folder";
import { GetProduct } from "api/rpc/2022-09/facilityAdmin/product/product";
import { GetCart } from "api/rpc/2022-09/facilityAdmin/cart/cart";
import { GetOrder } from "api/rpc/2022-09/facilityAdmin/order/order";

import { dequeue, enqueue, showError, showSuccess } from "redux/actions/ui";
import { IProduct } from "redux/reducers/models/product";
import { IOrder } from "redux/reducers/models/order";
import useLocalStorage from "hooks/useLocalStorage";

import { ButtonNew as Button } from "components/buttonNew";
import Input from "components/form/input/Input";
import Icon from "components/icon/Icon";
import { FolderMenu } from "components/folderMenu/FolderMenu";
import { PRODUCT_FOLDERS_KEY } from "../../settings/folders/Folders";
import { IRegisterGroup } from "../../Admin";

import "elements/register/register.scss";
import { GetVariant } from "api/rpc/2024-04/facilityAdmin/product/variant";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { loadDiscountOptions, loadPaymentOptions } from "redux/actions/facility";
import { loadCart, postCart, postCartVariants, postLineItem } from "redux/actions/cart";
import CartMenuNew from "./CartMenuNew";
import ProductsNew from "./ProductsNew";
import { useRegisterContext } from "./RegisterContext";
import Spin from "components/spin/spin";
import Callout from "components/callout/Callout";

export interface IMainState {
  products: IProduct[];
  productsLoadingMessage: string;
  folders: IFolder[];
  selectedFolder: IFolder;
  unPaidOrder: IOrder;
  order: IOrder;
}

export interface IFilterState {
  search: string;
  barcodeSearch: boolean;
  sort: string;
}

export interface ITerminalState {
  online: boolean;
  connecting: boolean;
  reader: any;
}

interface ILocationState {
  params: { cartToken: string; orderId: number };
}

export default function RegisterNew() {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { facilityStore, cartStore } = useAppSelector(store => store);

  const registerGroup = useLocalStorage<IRegisterGroup>("register_group");
  const defaultFolderId = useLocalStorage<number>("default_folder_id");
  const { addLoadingVariant, removeLoadingVariant } = useRegisterContext();

  const [state, setState] = useState<IMainState>({
    products: null,
    productsLoadingMessage: null,
    folders: null,
    selectedFolder: null,
    unPaidOrder: null,
    order: null,
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    search: "",
    barcodeSearch: false,
    sort: "alpha",
  });

  useEffect(() => {
    //Show warning dialog box when browser is refreshed
    function handleBeforeUnload(event: BeforeUnloadEvent) {
      event.preventDefault();
      event.returnValue = "";
      return "";
    }
    window.addEventListener("beforeunload", handleBeforeUnload);
    void loadRegisterCart();
    if (facilityStore?.paymentOptions?.length === 0) {
      void dispatch(loadPaymentOptions());
    }
    if (facilityStore?.discountOptions?.length === 0) {
      void dispatch(loadDiscountOptions());
    }
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (registerGroup) {
      void loadFolders(registerGroup.id, source.token);
    }
    return () => source.cancel();
  }, [registerGroup]);

  // Used for searching all products
  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (() => {
          try {
            if (filterState.search === "") {
              if (mounted) {
                updateMainState({ products: [] });
              }
              return;
            } else {
              if (mounted) {
                void loadProducts(source.token);
              }
            }
          } catch (error) {
            console.log("err", error);
          }
          return;
        })();
      }, 500);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      source.cancel();
    };
  }, [filterState.search]);

  function updateMainState(newMainState: Partial<IMainState>) {
    setState((cur: IMainState) => {
      return { ...cur, ...newMainState };
    });
  }

  function updateFilterState(newFilterState: Partial<IFilterState>) {
    setFilterState(cur => {
      return { ...cur, ...newFilterState };
    });
  }

  async function loadFolders(registerGroupId: number, token?: CancelToken) {
    const folderRes = await GetFolder({ register_group_id: registerGroupId, extended: false }, false, token);

    if (token && token.reason) {
      return;
    }
    if (folderRes.status !== StatusCode.OK) {
      updateMainState({ folders: [] });
      dispatch(showError(t("secure.facility.register.register.008")));
      return;
    }

    const folders = folderRes.data;

    // Get default folder for the selected Register or the default set for the facility
    let selectedFolder: IFolder = undefined;

    if (defaultFolderId > 0) {
      selectedFolder = folders.find(folder => folder.id === defaultFolderId);
    } else {
      selectedFolder = folders.find(folder => folder.default === true);
    }

    if (!selectedFolder) {
      selectedFolder = folders?.[0];
    }

    updateMainState({ folders: folders });

    if (selectedFolder) {
      void handleChangeSelectedFolder(selectedFolder);
    }
  }

  async function loadProducts(cancelToken: CancelToken) {
    updateMainState({ productsLoadingMessage: "Products loading..." });
    //Search products
    if (!filterState.barcodeSearch) {
      const productRes = await GetProduct({ extended: true, search: filterState.search }, false, cancelToken);
      updateMainState({ productsLoadingMessage: null });
      if (productRes.status === StatusCode.OK) {
        updateMainState({ products: productRes.data });
      } else {
        if (cancelToken && cancelToken?.reason) {
          updateMainState({ productsLoadingMessage: null });
          return;
        }
        dispatch(showError("Error searching products"));
      }
    } else {
      //Cannot scan and add item to cart if an order is already in progress (partial payment made)
      if (state?.order) {
        dispatch(showError("Order in progress. Start a new order to add item to cart"));
        updateMainState({ productsLoadingMessage: null });
        return;
      }
      //Search variant by barcode and add to cart
      const res = await GetVariant({ extended: true, barcode: filterState.search }, false, cancelToken);
      updateMainState({ productsLoadingMessage: "Adding line item to cart..." });
      if (res.status === StatusCode.OK) {
        const variants = res.data.filter(variant => variant.facility_access === true);

        if (variants.length == 1) {
          const variant = variants[0];
          void addLoadingVariant(variant, variant?.product);
          const updatedCart = await dispatch(
            postCartVariants(
              { cart_id: cartStore?.cart?.id, variants: [{ variant_id: variant?.id, quantity: 1, parent_id: null }] },
              false,
            ),
          );
          updateMainState({ productsLoadingMessage: null });
          void removeLoadingVariant(variant?.id);
          if (!updatedCart) {
            dispatch(showError("Error adding line item to cart. Please try again"));
            return;
          }

          updateFilterState({ search: "" });
        } else {
          updateMainState({ productsLoadingMessage: null });
          dispatch(showError("Product not found"));
        }
        updateMainState({ products: [] });
      } else {
        updateMainState({ productsLoadingMessage: null });
        if (cancelToken?.reason) {
          return;
        }
        dispatch(showError("Error searching products"));
      }
    }
  }

  async function loadRegisterCart() {
    const locationState = location.state as ILocationState;

    if (locationState?.params) {
      dispatch(enqueue());
      //Get cart if location state cart token exists
      const cartRes = await GetCart({ token: locationState?.params?.cartToken }, false);
      if (cartRes.status !== StatusCode.OK) {
        dispatch(showError(t("secure.facility.register.register.009")));
        dispatch(dequeue());
        return;
      }
      //Set the cart in redux without making another GET Cart call (tableCart used to avoid setting token in localStorage)
      void dispatch(loadCart({ tableCart: cartRes?.data[0] }, false));

      //Get the order so the user can continue to complete the order
      const orderRes = await GetOrder({ id: locationState?.params?.orderId, extended: true }, false);
      if (orderRes.status !== StatusCode.OK) {
        dispatch(showError(t("secure.facility.register.register.010")));
        dispatch(dequeue());
        return;
      }
      dispatch(dequeue());
      setState(prevState => ({ ...prevState, unPaidOrder: orderRes?.data[0] }));
    } else {
      void dispatch(loadCart({ tableCart: null }, false));
      if (localStorage.getItem("register_cart_token")) {
        await dispatch(loadCart({ token: String(localStorage.getItem("register_cart_token")) }, false));
      } else {
        await dispatch(postCart(null, false));
      }
    }
  }

  async function handleChangeSelectedFolder(selectedFolder: IFolder) {
    if (selectedFolder?.id === undefined) {
      return;
    }

    const storedProductFolders: IFolder[] = JSON.parse(localStorage.getItem(PRODUCT_FOLDERS_KEY)) ?? [];
    const storedProductFolder = storedProductFolders.find(productFolder => productFolder?.id === selectedFolder.id);

    const selectedFolderDate = new Date(selectedFolder.updated_at);
    const storedProductFolderDate = new Date(storedProductFolder?.updated_at);

    const storedProductFolderIsValid =
      !isNaN(selectedFolderDate?.getTime()) &&
      !isNaN(storedProductFolderDate?.getTime()) &&
      storedProductFolderDate.getTime() >= selectedFolderDate.getTime();

    if (storedProductFolderIsValid) {
      updateMainState({ selectedFolder: storedProductFolder });
    } else {
      const folderRes = await GetFolder({ id: selectedFolder.id, extended: true }, true);

      if (folderRes.status !== StatusCode.OK || folderRes.data?.length === undefined || folderRes.data.length === 0) {
        dispatch(showError(t("secure.facility.register.register.011")));
        return;
      }

      const newSelectedFolder = folderRes.data[0];

      if (!storedProductFolder) {
        const updatedStoredProductFolders = [...storedProductFolders, newSelectedFolder];
        localStorage.setItem(PRODUCT_FOLDERS_KEY, JSON.stringify(updatedStoredProductFolders));
      } else {
        const updatedStoredProductFolders = storedProductFolders.map(productFolder => {
          if (productFolder.id === newSelectedFolder.id) {
            return newSelectedFolder;
          } else {
            return productFolder;
          }
        });

        localStorage.setItem(PRODUCT_FOLDERS_KEY, JSON.stringify(updatedStoredProductFolders));
      }

      updateMainState({ selectedFolder: newSelectedFolder });
    }
  }

  function handleBarcodeSearchClick() {
    const productSearchId = document.getElementById("productSearchId");
    productSearchId.focus();
    updateFilterState({ barcodeSearch: !filterState.barcodeSearch });
  }

  function refreshFolderStorage() {
    localStorage.removeItem(PRODUCT_FOLDERS_KEY);

    if (state.selectedFolder) {
      void handleChangeSelectedFolder(state.selectedFolder);
    }

    dispatch(showSuccess(t("secure.facility.register.register.012")));
  }

  function renderProducts() {
    if (filterState.search && state.products) {
      const products = state.products?.sort((firstProd: IProduct, secondProd: IProduct) => {
        if (filterState.sort === "alpha") {
          if (firstProd.title < secondProd.title) {
            return -1;
          } else if (firstProd.title > secondProd.title) {
            return 1;
          } else {
            return 0;
          }
        }
      }) as [];
      return { useGridPositions: false, products };
    } else {
      const products = state.selectedFolder?.products ?? ([] as []);
      return { useGridPositions: true, products };
    }
  }

  return (
    <>
      <div className="ui-register">
        {!registerGroup ? (
          <RegisterNotFound />
        ) : !state?.folders ? (
          <div style={{ display: "flex", height: "100%", margin: "auto" }}>
            <div className="text-center m-auto">
              <div style={{ height: "32px" }}>
                <Spin />
              </div>
              <div>Preparing register...</div>
            </div>
          </div>
        ) : (
          <>
            <div className="ui-register-folders_container">
              <FolderMenu
                options={state.folders}
                optionTitle="title"
                defaultSelected={state.folders.findIndex(folder => folder.id === state.selectedFolder?.id)}
                onClick={(option, selectedIndex) => handleChangeSelectedFolder(option)}
              />
            </div>
            <div className="ui-register-new-products-container">
              <div className="ui-register-new-products-content">
                <div className="ui-register-top-bar">
                  <div className="flex justify-between mb-4">
                    <div className="flex-grow">
                      <Input
                        id="productSearchId"
                        value={filterState.search}
                        onChange={(value: any) => updateFilterState({ search: value.target.value })}
                        type="search"
                        placeholder={t("secure.facility.register.register.006")}
                        autoFocus
                        labelHidden
                        trailingButtons={[
                          <Button
                            key={1}
                            type="secondary"
                            active={filterState.barcodeSearch}
                            onClick={handleBarcodeSearchClick}
                          >
                            <Icon style="far" icon="barcode-read" size="medium" />
                          </Button>,
                          <Button key={2} type="secondary" onClick={refreshFolderStorage}>
                            <Icon style="far" icon="refresh" size="medium" />
                          </Button>,
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <div className={"product-content"}>
                  <ProductsNew
                    {...renderProducts()}
                    disabled={
                      !cartStore?.cart || cartStore?.cart?.status === "complete" || state?.unPaidOrder || state.order
                        ? true
                        : false
                    }
                    cart={cartStore?.cart}
                    selectedFolder={state.selectedFolder}
                    productsLoadingMessage={state?.productsLoadingMessage}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        <div className="ui-register-cart-menu ui-register-cart-menu-new ">
          <CartMenuNew
            dataOrigin={{
              setRegisterState: updateMainState,
              setFilterState: updateFilterState,
              unPaidOrder: state.unPaidOrder,
            }}
          />
        </div>
      </div>

      <Prompt
        when={!!state?.order}
        message={location =>
          "Order is in progress. The current order has not been completed. This will result in a partially paid order. Are you sure you want to continue?"
        }
      />
    </>
  );
}

export function RegisterNotFound() {
  return (
    <div style={{ width: "100%" }}>
      <Callout
        title="No Register Selected"
        content="Select a register to continue"
        type="error"
        actions={{
          primary: {
            label: "Learn more",
            onClick: () =>
              window.open("https://support-cloud.teeon.com/hc/en-us/articles/20125325436429-Changing-a-Register"),
          },
        }}
      />
    </div>
  );
}
