import React, { useEffect, useState, useCallback, ChangeEvent } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import useModal from "hooks/modals/useModal";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Input from "components/form/input/Input";
import Toggle from "components/form/toggle/Toggle";
import { Select } from "components/select/index";
import Popup from "components/popup/Popup";

import { IAuthState } from "redux/reducers/auth";
import { IUIActions } from "redux/actions/ui";

import { useTranslation } from "react-i18next";
import { StatusCode } from "api/protocols";
import FormLayout from "components/form/FormLayout";
import { isEqualWith } from "lodash";
import { DeleteDiscount, GetDiscount, PutDiscount } from "api/rpc/2024-04/facilityAdmin/discount/discount";

interface IEditDiscountProps {
  authStore: IAuthState;
  uiActions: IUIActions;
}

interface IDiscountState {
  title: string;
  application: any;
  value_type: any;
  value: number;
  custom: boolean;
  tracked: boolean;
}

export default function EditDiscount(props: IEditDiscountProps) {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { t, i18n } = useTranslation();
  const { leagueId }: any = useParams();
  const [state, setState] = useState<IDiscountState>({
    title: "",
    application: null,
    value_type: null,
    value: 0,
    custom: false,
    tracked: false,
  });

  const [discountBeforeChanges, setDiscountBeforeChanges] = useState(undefined);
  const [discountLoaded, setDiscountLoaded] = useState<boolean>(false);

  const permissions = props.authStore?.user.permissions;

  const { Option } = Select;

  const {
    state: deleteDiscountPopup,
    updateModal: updateDeleteDiscountPopup,
    closeModal: closeDeleteDiscountPopup,
  } = useModal();

  useEffect(() => {
    void loadDiscount();
  }, []);

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setState({ ...state, [id]: value });
  }

  function handleDropdownChange(value: string) {
    setState({ ...state, value_type: value });
  }

  function handleToggleChange(event: ChangeEvent<HTMLInputElement>) {
    const id = event?.target?.id;
    const checked = event?.target?.checked;

    if (id === undefined || checked === undefined) {
      return;
    }

    setState({ ...state, [id]: checked });
  }

  async function loadDiscount() {
    const discountRes = await GetDiscount(
      {
        id: Number(id),
      },
      true,
    );

    if (discountRes.status !== StatusCode.OK) {
      return;
    }

    setState({
      ...state,
      title: discountRes.data[0].title,
      application: discountRes.data[0].application,
      value_type: discountRes.data[0].value_type,
      value: discountRes.data[0].value,
      custom: discountRes.data[0].custom,
      tracked: discountRes.data[0].tracked === undefined ? false : discountRes.data[0].tracked, //Tracked value is currently not stores in the back end
    });

    setDiscountLoaded(true);
  }

  async function updateDiscount() {
    const discountRes = await PutDiscount(
      {
        id: Number(id),
        title: state.title,
        value_type: state.value_type,
        application: "line_item",
        value: state.value,
        custom: state.custom,
        tracked: state.tracked,
      },
      true,
    );

    if (discountRes.status !== StatusCode.OK) {
      props.uiActions.showError("Can't Update Discount");
      return;
    }

    props.uiActions.showSuccess("Discount Updated Successfully");
    history.push("/admin/settings/discount");
  }

  async function handleDeleteDiscount() {
    const discountRes = await DeleteDiscount(
      {
        id: Number(id),
      },
      true,
    );

    if (discountRes.status !== StatusCode.OK) {
      props.uiActions.showError("Can't Delete Discount");
      return;
    }

    props.uiActions.showSuccess("Discount Deleted Successfully");
    history.push("/admin/settings/discount");
  }

  const primaryAction = {
    content: "Save",
    action: updateDiscount,
    disabled: !permissions.discounts_create,
  };

  const secondaryActions = [
    {
      content: "Delete",
      action: () => updateDeleteDiscountPopup({ isOpen: true }),
      disabled: !permissions.discounts_create,
    },
  ];

  function unsavedChangesExist() {
    if (discountBeforeChanges === undefined) {
      if (discountLoaded) {
        setDiscountBeforeChanges(state);
      }
      return false;
    }

    return !isEqualWith(discountBeforeChanges, state);
  }

  function cancelUnsavedChanges() {
    setState(discountBeforeChanges);
  }

  return (
    <Page
      title={"Edit Discount"}
      narrow
      // primaryAction={primaryAction}
      secondaryActions={secondaryActions}
      breadcrumbs={[
        {
          prefix: true,
          label: "Back to Discounts",
          url: "/admin/settings/discount",
        },
      ]}
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: updateDiscount,
        onCancel: cancelUnsavedChanges,
      }}
    >
      <Card>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Input
                value={state.title}
                label={"Title"}
                id="title"
                onChange={handleInputChange}
                placeholder={"Title"}
                disabled={!permissions.discounts_create}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <Select
                defaultValue={state.value_type}
                label="Value Type"
                className="flex justify-center align-center w-full h-10 position-relative z-20 text-black text-medium appearance-none border-none focus:outline-none placeholder-gray-200"
                placeholder="Value Type"
                onChange={(value: any) => handleDropdownChange(value)}
                disabled={!permissions.discounts_create}
              >
                <Option value={"percent"}>Percent</Option>
                <Option value={"fixed_amount"}>Fixed Amount</Option>
              </Select>
              <Input
                value={state.value}
                label={"Value"}
                id="value"
                onChange={handleInputChange}
                placeholder={"Value"}
                type="number"
                prefix={state.value_type === "percent" ? "%" : "$"}
                disabled={!permissions.discounts_create}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <Toggle
                labelRight="Customizable"
                id="custom"
                onChange={handleToggleChange}
                checked={state.custom}
                size="medium"
                disabled={!permissions.discounts_create}
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>

      <Popup
        type="warning"
        open={deleteDiscountPopup.isOpen}
        title={"Delete Discount?"}
        description="Are you sure you want to delete this discount?"
        closable
        onOk={handleDeleteDiscount}
        onCancel={closeDeleteDiscountPopup}
        okText="Delete"
        cancelText="Cancel"
        backDropCancel={false}
      />
    </Page>
  );
}
