import { APIGet, APIPost, APIPut, APIDelete, IAPIResponse } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/product/inventory";

export type TGetProductInventory = {
  id?: string | number;
  variant_id?: string | number;
  extended?: boolean;
};

export type TPutProductInventory = {
  variant_id: string | number;
  price_override?: number;
  cost_override?: number;
  quantity_available?: number;
  quantity_receiving?: number;
  tax_line_ids?: Array<string | number>;
};

export type TInventory = {
  id: string | number;
  facility_id: string | number;
  processed_at: string; // ie. "2023-07-11 13:00:21"
  admin_user_id: string | number;
  admin_user_full_name: string;
  vendor_title: string;
};
interface IPutInventoryRecords {
  id: number;
  vendor_id?: number;
  packing_slip_number?: string;
}

// GET Inventory Levels
export async function GetInventoryLevels(params: TGetProductInventory, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseUrl + "/level", params, useGlobalLoader, token);
}

// PUT Update Inventory Level
export async function UpdateInventoryLevels(params: TPutProductInventory, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/level", params, useGlobalLoader);
}

export async function GetInventoryRecord(
  params: { id: string | number },
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseUrl + "/record", params, useGlobalLoader, token);
}

export async function GetInventoryRecordsAll(
  params: { offset?: number; limit?: number },
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet<TInventory[]>(baseUrl + "/record/all", params, useGlobalLoader, token);
}

export async function GetInventoryLog(
  params: { inventory_record_id: string | number },
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseUrl + "/record/log", params, useGlobalLoader, token);
}

export async function PostInventoryRecords(
  params: {
    variants: Array<{ variant_id: number; quantity_receiving: number }>;
    vendor_id?: number;
    packing_slip_number: string;
  },
  useGlobalLoader: boolean,
) {
  return await APIPost(baseUrl + "/record", params, useGlobalLoader);
}

export async function PutInventoryRecord(params: IPutInventoryRecords, useGlobalLoader?: boolean) {
  return await APIPut(baseUrl + "/record", params, useGlobalLoader);
}
