import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";

import { DBFilter } from "redux/reducers/filters/filters";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/membership";

//MEMBERSHIPS
export type TFacilityMembership = {
  add_on?: any;
  client_id: number;
  customer_type_id: number;
  customer_type_title: string;
  expiry: string;
  id: number;
  membership_type: string;
  product_id: number;
  reciprocal: boolean;
  subtitle: string;
  title: string;
  type: "multi-course" | "single-course";
  variant_id: number;
};
export type TMembershipGet = {
  id?: number;
  /** Search by title and subtitle */
  search?: string;
  filters?: DBFilter[]; // Can be used to filter multiple membership_types
  membership_type?: number;
  privileges?: boolean;
};

// GET Membership
export async function GetMembership(params: TMembershipGet, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseUrl, params, useGlobalLoader, token);
}

// PUT Membership
export async function PutMembership(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

//MEMBERSHIP ADD ON

//GET Membership Add Ons
export async function GetMembershipAddOn(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseUrl + "/add-on", params, useGlobalLoader);
}

//PUT Update Memmbership ADd On
export async function PutMembershipAddOn(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/add-on", params, useGlobalLoader);
}
