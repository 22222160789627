import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StatusCode } from "api/protocols";
import { GetFolder, IFolder } from "api/rpc/2022-09/facilityAdmin/product/folder";
import { GetProduct } from "api/rpc/2024-04/facilityAdmin/product/product";
import { GetVariant } from "api/rpc/2024-04/facilityAdmin/product/variant";
import axios, { CancelToken } from "axios";
import { Badge } from "components/badge/Badge";
import { ButtonNew as Button } from "components/buttonNew";
import Input from "components/form/input";
import Icon from "components/icon/Icon";
import Spin from "components/spin/spin";
import TableServiceFoldersMenu from "containers/facility/tableServiceFoldersMenu";
import RegistersModal from "elements/register/RegistersModal";
import { clearReaderDisplay } from "helpers/StripeTerminalWrapper";
import useModal from "hooks/modals/useModal";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import useLocalStorage from "hooks/useLocalStorage";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useHistory } from "react-router";
import { loadTableCart, postCartVariants, setActiveTable } from "redux/actions/cart";
import { loadDiscountOptions, loadPaymentOptions } from "redux/actions/facility";
import { showError, showSuccess } from "redux/actions/ui";
import { IOrder } from "redux/reducers/models/order";
import { IProduct } from "redux/reducers/models/product";
import { IRegister, IRegisterGroup } from "../../Admin";
import ProductsNew from "../../register/registerNew/ProductsNew";
import { useRegisterContext } from "../../register/registerNew/RegisterContext";
import { RegisterNotFound } from "../../register/registerNew/RegisterNew";
import { PRODUCT_FOLDERS_KEY } from "../../settings/folders/Folders";
import "./newTableService.scss";
import TableServiceCart from "./tableServiceCart/TableServiceCart";

export interface ITableServiceState {
  folders: Array<IFolder>;
  selectedFolder: IFolder;

  /**List of products displayed */
  products: Array<IProduct>;
  /**Product search query string */
  productSearch: string;
  /**Message displayed when products are loading */
  productsLoadingMessage: string;
  /**Toggles barcode/sku search to auto-add variant to cart */
  barcodeSearch: boolean;

  order: IOrder;

  selectedCourseId: number;

  /**Message displayed when the table is loading */
  tableLoadingMessage: string;
  /**Message displayed when the cart (seat) is loading */
  cartLoadingMessage: string;
}

export default function NewTableService() {
  const {
    cartStore: { cart, activeTable },
    terminalStore,
    authStore,
    facilityStore,
  } = useAppSelector(store => store);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const register = useLocalStorage<IRegister>("register");
  const registerGroup = useLocalStorage<IRegisterGroup>("register_group");
  const { addLoadingVariant, removeLoadingVariant } = useRegisterContext();

  const [state, setState] = useState<ITableServiceState>({
    //Folders
    folders: null,
    selectedFolder: null,

    //Products
    products: null,
    productSearch: "",
    productsLoadingMessage: "",
    barcodeSearch: false,

    //Order
    order: null,

    selectedCourseId: null,

    tableLoadingMessage: "",
    cartLoadingMessage: "",
  });

  const { state: registerModal, updateModal: updateRegisterModal, closeModal: closeRegisterModal } = useModal();

  useEffect(() => {
    if (activeTable) {
      //Set the cart in redux as the first cart returned on the table
      void loadFirstSeatCart();
      //Load payment options and discount options if values in redux are empty
      if (facilityStore?.paymentOptions?.length === 0) {
        void dispatch(loadPaymentOptions());
      }
      if (facilityStore?.discountOptions?.length === 0) {
        void dispatch(loadDiscountOptions());
      }
    } else {
      void navigateToTableSelection();
    }
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();
    setState(prevState => ({ ...prevState, tableLoadingMessage: "Preparing table service..." }));
    if (registerGroup) {
      void loadFolders(registerGroup?.id, source.token);
    } else {
      setState(prevState => ({ ...prevState, tableLoadingMessage: "" }));
    }
    return () => source.cancel();
  }, [registerGroup]);

  // Used for searching all products
  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    let timeoutId: NodeJS.Timeout = null;
    const search = () => {
      timeoutId = global.setTimeout(() => {
        void (() => {
          try {
            if (state.productSearch === "") {
              if (mounted) {
                setState(prevState => ({ ...prevState, products: [] }));
              }
              return;
            } else {
              if (mounted) {
                void loadProducts(source.token);
              }
            }
          } catch (error) {
            console.log("err", error);
          }
          return;
        })();
      }, 500);
    };
    search();
    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      source.cancel();
    };
  }, [state.productSearch]);

  function loadFirstSeatCart() {
    const firstCart = activeTable?.carts?.length > 0 ? activeTable?.carts[0] : null;
    ReactDOM.unstable_batchedUpdates(() => {
      setState(prevState => ({ ...prevState, order: firstCart ? firstCart?.order : null }));
      void dispatch(loadTableCart({ tableCart: firstCart }, false));
    });
  }

  function navigateToTableSelection() {
    if (terminalStore?.reader) {
      void clearReaderDisplay();
    }
    dispatch(setActiveTable(null));
    history.push("/admin/table-selection");
  }

  async function loadProducts(cancelToken: CancelToken) {
    setState(prevState => ({ ...prevState, productsLoadingMessage: "Products loading..." }));
    //Search products
    if (!state.barcodeSearch) {
      const productRes = await GetProduct({ extended: true, search: state.productSearch }, false, cancelToken);
      setState(prevState => ({ ...prevState, productsLoadingMessage: null }));
      if (productRes.status === StatusCode.OK) {
        setState(prevState => ({ ...prevState, products: productRes?.data }));
      } else {
        if (cancelToken && cancelToken?.reason) {
          setState(prevState => ({ ...prevState, productsLoadingMessage: null }));
          return;
        }
        dispatch(showError("Error searching products"));
      }
    } else {
      //Cannot scan and add item to cart if an order is already in progress (partial payment made)
      if (state?.order) {
        dispatch(showError("Order in progress. Start a new order to add item to cart"));
        setState(prevState => ({ ...prevState, productsLoadingMessage: null }));
        return;
      }
      //Search variant by barcode and add to cart
      const res = await GetVariant({ extended: true, barcode: state.productSearch }, false, cancelToken);
      setState(prevState => ({ ...prevState, productsLoadingMessage: "Adding line item to cart..." }));
      if (res.status === StatusCode.OK) {
        const variants = res?.data?.filter(variant => variant.facility_access === true);

        if (variants.length == 1) {
          const variant = variants[0];
          void addLoadingVariant(variant, variant?.product);
          const updatedCart = await dispatch(
            postCartVariants(
              { cart_id: cart?.id, variants: [{ variant_id: variant?.id, quantity: 1, parent_id: null }] },
              false,
            ),
          );
          setState(prevState => ({ ...prevState, productsLoadingMessage: null }));
          void removeLoadingVariant(variant?.id);
          if (!updatedCart) {
            dispatch(showError("Error adding line item to cart. Please try again"));
            return;
          }
          setState(prevState => ({ ...prevState, productSearch: "" }));
        } else {
          setState(prevState => ({ ...prevState, productsLoadingMessage: null }));
          dispatch(showError("Product not found"));
        }
        setState(prevState => ({ ...prevState, products: [] }));
      } else {
        setState(prevState => ({ ...prevState, productsLoadingMessage: null }));
        if (cancelToken?.reason) {
          return;
        }
        dispatch(showError("Error searching products"));
      }
    }
  }

  async function loadFolders(registerGroupId: number, cancelToken: CancelToken) {
    const folderRes = await GetFolder({ register_group_id: registerGroupId, extended: false }, false, cancelToken);

    if (folderRes.status !== StatusCode.OK) {
      if (cancelToken && cancelToken.reason) {
        return;
      }
      dispatch(showError("Error getting folders"));
      setState(prevState => ({ ...prevState, tableLoadingMessage: "" }));
      return;
    }

    const folders = folderRes.data;

    // Get default folder for the selected Register or the default set for the facility
    const defaultFolderId = Number(localStorage.getItem("default_folder_id"));
    let selectedFolder: IFolder = undefined;
    if (defaultFolderId > 0) {
      selectedFolder = folders.find((folder: IFolder) => folder.id === defaultFolderId);
    } else {
      selectedFolder = folders.find((folder: IFolder) => folder.default === true);
    }

    if (!selectedFolder) {
      selectedFolder = folders?.[0];
    }

    setState(prevState => ({
      ...prevState,
      folders: folders,
      tableLoadingMessage: "",
    }));

    if (selectedFolder) {
      void handleChangeSelectedFolder(selectedFolder);
    }
  }

  async function handleChangeSelectedFolder(selectedFolder: IFolder) {
    if (selectedFolder?.id === undefined) {
      return;
    }

    const storedProductFolders: IFolder[] = JSON.parse(localStorage.getItem(PRODUCT_FOLDERS_KEY)) ?? [];
    const storedProductFolder = storedProductFolders.find(productFolder => productFolder?.id === selectedFolder.id);

    const selectedFolderDate = new Date(selectedFolder.updated_at);
    const storedProductFolderDate = new Date(storedProductFolder?.updated_at);

    const storedProductFolderIsValid =
      !isNaN(selectedFolderDate?.getTime()) &&
      !isNaN(storedProductFolderDate?.getTime()) &&
      storedProductFolderDate.getTime() >= selectedFolderDate.getTime();

    if (storedProductFolderIsValid) {
      setState(prevState => ({ ...prevState, selectedFolder: storedProductFolder }));
    } else {
      const folderRes = await GetFolder({ id: selectedFolder.id, extended: true }, true);

      if (folderRes.status !== StatusCode.OK || folderRes.data?.length === undefined || folderRes.data.length === 0) {
        dispatch(showError("Error getting folder"));
        return;
      }

      const newSelectedFolder = folderRes.data[0];

      if (!storedProductFolder) {
        const updatedStoredProductFolders = [...storedProductFolders, newSelectedFolder];
        localStorage.setItem(PRODUCT_FOLDERS_KEY, JSON.stringify(updatedStoredProductFolders));
      } else {
        const updatedStoredProductFolders = storedProductFolders.map(productFolder => {
          if (productFolder.id === newSelectedFolder.id) {
            return newSelectedFolder;
          } else {
            return productFolder;
          }
        });

        localStorage.setItem(PRODUCT_FOLDERS_KEY, JSON.stringify(updatedStoredProductFolders));
      }

      setState(prevState => ({ ...prevState, selectedFolder: newSelectedFolder }));
    }
  }

  function renderProducts() {
    if (state.productSearch && state.products) {
      const products = state.products?.sort((firstProd: IProduct, secondProd: IProduct) => {
        if (firstProd.title < secondProd.title) {
          return -1;
        } else if (firstProd.title > secondProd.title) {
          return 1;
        } else {
          return 0;
        }
      }) as [];
      return { useGridPositions: false, products };
    } else {
      const products = state.selectedFolder?.products ?? ([] as []);
      return { useGridPositions: true, products };
    }
  }

  function handleBarcodeSearchClick() {
    const productSearchId = document.getElementById("productSearchId");
    productSearchId.focus();
    setState(prevState => ({ ...prevState, barcodeSearch: !prevState.barcodeSearch }));
  }

  function handleRegisterChange(register?: IRegister | undefined) {
    // Close register modal
    void closeRegisterModal();

    // Set selected register in localStorage
    if (register) {
      localStorage.setItem("register", JSON.stringify(register));
      localStorage.setItem("default_folder_id", JSON.stringify(register.default_folder_id));
      localStorage.setItem("register_group", JSON.stringify(register.register_group));
    }
  }

  return (
    <div className="new-table-service">
      <div className="new-table-service-top-bar">
        <div className="new-table-service-top-bar-left">
          <Button onClick={navigateToTableSelection} size="medium" type="text">
            <FontAwesomeIcon className="mr-2" icon={["far", "arrow-left"]} /> Back
          </Button>
        </div>
        <div className="new-table-service-top-bar-center">
          <div>
            <p className="table-primary-title">{activeTable?.title}</p>
            <p className="table-secondary-title">{activeTable?.user_full_name}</p>
          </div>
          <div className="table-product-search-input">
            <Input
              id="productSearchId"
              value={state.productSearch}
              onChange={value => setState(prevState => ({ ...prevState, productSearch: value.target.value }))}
              type="search"
              placeholder="Search for a product..."
              labelHidden
              disabled={!!state?.tableLoadingMessage || !registerGroup}
              trailingButtons={[
                <Button key={1} type="secondary" active={state.barcodeSearch} onClick={handleBarcodeSearchClick}>
                  <Icon style="far" icon="barcode-read" size="medium" />
                </Button>,
              ]}
            />
          </div>
        </div>
        <div className="new-table-service-top-bar-right">
          {terminalStore?.reader ? (
            <div>
              <Badge type="success" outline size="medium" iconLeft={<FontAwesomeIcon icon={["fas", "circle"]} />}>
                {terminalStore?.reader?.label}
              </Badge>
            </div>
          ) : (
            <div></div>
          )}
          <div className="new-table-service-top-bar-right-user-container">
            <div>
              <p className="table-primary-title">{authStore?.user?.full_name}</p>
              <p
                className="table-secondary-title table-title-selectable"
                onClick={() => updateRegisterModal({ isOpen: true })}
              >
                {register && register?.title ? register?.title : "No Register"}
              </p>
            </div>
            <div className="table-user-icon-container">
              <div className="table-user-icon-inner">
                {authStore?.user?.first_name?.charAt(0)}
                {authStore?.user?.last_name?.charAt(0)}
              </div>
            </div>
          </div>
        </div>
      </div>
      {state?.tableLoadingMessage ? (
        <div style={{ display: "flex", height: "100%" }}>
          <div className="text-center m-auto">
            <div style={{ height: "32px" }}>
              <Spin />
            </div>
            <div>{state?.tableLoadingMessage}</div>
          </div>
        </div>
      ) : (
        <div className="new-table-service-main-container">
          <div className="new-table-service-main-container-left">
            <TableServiceFoldersMenu
              selectedFolder={state.selectedFolder}
              setSelectedFolder={handleChangeSelectedFolder}
              folders={state.folders}
            />
          </div>
          <div className="new-table-service-main-container-center">
            {!registerGroup ? (
              <RegisterNotFound />
            ) : (
              <>
                <div className="new-table-service-products-container">
                  <ProductsNew
                    {...renderProducts()}
                    disabled={
                      !cart ||
                      cart?.status === "complete" ||
                      state?.order?.financial_status === "partially_paid" ||
                      cart?.status === "void" ||
                      !!state.cartLoadingMessage
                    }
                    cart={cart}
                    selectedFolder={state.selectedFolder}
                    productsLoadingMessage={state?.productsLoadingMessage}
                    selectedCourseId={state.selectedCourseId}
                  />
                </div>
                <div className="new-table-service-folders-container-horizontal">
                  <TableServiceFoldersMenu
                    selectedFolder={state.selectedFolder}
                    setSelectedFolder={handleChangeSelectedFolder}
                    folders={state.folders}
                  />
                </div>
              </>
            )}
          </div>
          <div className="new-table-service-main-container-right">
            <TableServiceCart
              order={state.order}
              selectCourse={id =>
                setState(prevState => ({
                  ...prevState,
                  selectedCourseId: id === prevState.selectedCourseId ? null : id,
                }))
              }
              selectedCourseId={state.selectedCourseId}
              setTableState={setState}
              navigateToTableSelection={navigateToTableSelection}
              cartLoadingMessage={state.cartLoadingMessage}
            />
          </div>
        </div>
      )}
      {/* Modal for updating the selected register */}
      <RegistersModal open={registerModal?.isOpen} onClose={register => handleRegisterChange(register)} />
    </div>
  );
}
