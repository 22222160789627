import { APIGet, APIPost, APIPut, APIDelete, StatusCode } from "api/protocols";
import { CancelToken } from "axios";
import { IVariant } from "redux/reducers/models/product";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/product/variant";

export type TGetVariant = {
  id?: number;
  product_id?: number;
  search?: string;
  barcode?: string;
  extended?: boolean;
  types?: any;
  skus?: Array<string>;
};

export type TPostVariant = {
  product_id: string | number;
  option1: string;
  /** Default = null.  Required IF product.options > 1  */
  option2?: string;
  /** Default = null.  Required IF product.options > 2  */
  option3?: string;
  /** Default = 0 */
  price: number;
  original_price?: number;
  cost?: number;
  /** Default = Truthy */
  taxable?: boolean;
  barcode?: string;
  sku?: string;
  /** Default = Falsy */
  tracked?: boolean;
  accounting_reference_id?: number;
  /** Default: Falsy */
  cannot_be_sold?: boolean;
  description?: string;
};

export type TPutVariant = {
  /** variant_id */
  id: string | number;
  title?: string;
  option1?: string;
  /** Default = null.  Required IF product.options > 1  */
  option2?: string;
  /** Default = null.  Required IF product.options > 2  */
  option3?: string;
  original_price?: number;
  taxable?: boolean;
  barcode?: string;
  sku?: string;
  tracked?: boolean;
  accounting_reference_id?: number;
  cannot_be_sold?: boolean;
  description?: string;
};

export type TPutVariantLabel = {
  offset: number;
  variants: Array<{ variant_id: number; quantity: number }>;
  template_id?: number;
};

export type TPutVariantFacilityAccess = {
  variants: {
    id: number;
    facility_access: boolean;
  }[];
};

export async function GetVariant(params: TGetVariant, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<IVariant[]>(baseUrl, params, useGlobalLoader, token);
}

// POST Variant
export async function PostVariant(params: TPostVariant, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

// PUT Variant
export async function PutVariant(params: TPutVariant, useGlobalLoader: boolean) {
  return await APIPut(baseUrl, params, useGlobalLoader);
}

export async function PutVariantLabels(params: TPutVariantLabel, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/label", params, useGlobalLoader);
}

// Update Facility Access within the given variants
export async function PutVariantsFacilityAccess(params: TPutVariantFacilityAccess, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/attach", params, useGlobalLoader);
}

// PUT Archive Variant
export async function ArchiveVariant(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/archive", params, useGlobalLoader);
}

//Print Product / Variant Labels
export async function PrintLabels(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/label", params, useGlobalLoader);
}
