import Divider from "components/divider";
import Checkbox from "components/form/checkbox/Checkbox";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import Sheet from "components/sheet/Sheet";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface IGiftCardProps {
  sellGiftCardsActive: boolean;
  onCancel?: () => void;
  onOk?: (giftCardInfo: IGiftCardSellState) => void;
  gift_cards: {
    cart_line_item_id: number;
    code: string;
    pin: number;
    random: boolean;
    balance: number;
    reload: number;
  }[];
}

export interface IGiftCardSellState {
  gift_cards: {
    cart_line_item_id: number;
    code: string;
    pin: number;
    random: boolean;
    balance: number;
    reload: number;
  }[];
}

function GiftCardSell(props: IGiftCardProps) {
  const { t, i18n } = useTranslation();

  const [state, setState] = useState<IGiftCardSellState>({
    gift_cards: null,
  });

  const [selectAllCheckboxState, setSelectAllCheckboxState] = useState(false);

  useEffect(() => {
    if (selectAllCheckboxState === true) {
      setSelectAllCheckboxState(false);
    }

    setState(prevState => ({ ...prevState, gift_cards: props.gift_cards }));
  }, [props.sellGiftCardsActive, props.gift_cards]);

  function onOk() {
    setSelectAllCheckboxState(false);
    void props.onOk({ ...state });
  }

  const handleSellGiftCardInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    let { value } = e.target;
    let valueNum = e.target.valueAsNumber;
    const gift_cards = state.gift_cards;

    if (value === "") {
      value = null;
      valueNum = null;
    }

    const giftCard = { ...gift_cards[index], [e.target.name]: e.target.name === "pin" ? valueNum : value };
    gift_cards[index] = giftCard;

    setState(prevState => ({ ...prevState, gift_cards }));
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const gift_cards = [...state.gift_cards];

    gift_cards.forEach((gift_card, index) => {
      gift_card.random = !selectAllCheckboxState;
      gift_card.reload = 0;
    });

    setSelectAllCheckboxState(!selectAllCheckboxState);
    setState(prevState => ({ ...prevState, gift_cards: gift_cards }));
  };

  const handleGiftCardCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, checked } = e.target;
    const gift_cards = state.gift_cards;
    let giftCard = { ...gift_cards[index] };
    //Deselect reload if random checkbox is selected

    if (name === "random" && checked) {
      giftCard = { ...gift_cards[index], [name]: checked, reload: 0 };
    } else if (name === "reload") {
      giftCard = { ...gift_cards[index], [name]: Number(checked) };
    } else {
      giftCard = { ...gift_cards[index], [name]: checked };
    }

    gift_cards[index] = giftCard;

    setState(prevState => ({ ...prevState, gift_cards }));
  };

  function onCancel() {
    setState(prevState => ({ ...prevState, gift_cards: null }));
    props.onCancel();
  }

  const sellGiftCardFieldsEmpty = !state.gift_cards?.every(card =>
    Object.values(card).every(cardAttr => {
      if (card.random === true) {
        return true;
      }
      return cardAttr != null;
    }),
  );

  return (
    <Sheet
      title={"Enter Gift Card Numbers"}
      open={props.sellGiftCardsActive}
      size="small"
      closable
      onCancel={onCancel}
      onOk={onOk}
      okText={"Add Gift Cards"}
      okDisabled={sellGiftCardFieldsEmpty}
    >
      {state.gift_cards?.length > 1 && (
        <div>
          <Checkbox
            name="random"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSelectAll(e)}
            label="Generate Random Codes for All"
            size="small"
            checked={selectAllCheckboxState}
          />
          <Divider style={{ margin: "1rem 0 1rem 0" }} />
        </div>
      )}

      {state.gift_cards?.map((gift_card, index: number) => {
        return (
          <div key={index}>
            <span>
              {t("secure.facility.table_service.table_service.154")} {index + 1}
            </span>
            <Input
              disabled={gift_card.random}
              value={gift_card.code || ""}
              placeholder="Gift card code"
              name="code"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSellGiftCardInputChange(e, index)}
            />
            <Input
              disabled={gift_card.random}
              type="number"
              value={gift_card.pin || ""}
              className="mb-2"
              placeholder="Gift card pin"
              name="pin"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSellGiftCardInputChange(e, index)}
            />
            <Checkbox
              name="random"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleGiftCardCheckboxChange(e, index)}
              label="Generate Random Code"
              size="small"
              checked={state.gift_cards[index]?.random}
            />
            <Checkbox
              name="reload"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleGiftCardCheckboxChange(e, index)}
              label="Reload Existing Card"
              size="small"
              //Disable if random is true
              disabled={state.gift_cards[index].random ? true : false}
              checked={state.gift_cards[index].reload === 1 ? true : false}
            />
            {index !== state.gift_cards?.length - 1 && <Divider style={{ margin: "1rem 0 1rem 0" }} />}
          </div>
        );
      })}
    </Sheet>
  );
}

export default GiftCardSell;
