import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ClientAdmin from "pages/secure/client/Admin";
import * as ClientActions from "redux/actions/client";
import * as FacilityActions from "redux/actions/clientAdmin/facility";
import * as UIActions from "redux/actions/ui";

const mapDispatchToProps = (dispatch: any) => {
  return {
    clientActions: bindActionCreators(
      {
        ...ClientActions,
      },
      dispatch,
    ),
    facilityActions: bindActionCreators(
      {
        ...FacilityActions,
      },
      dispatch,
    ),
    uiActions: bindActionCreators(
      {
        ...UIActions,
      },
      dispatch,
    ),
  };
};

const mapStateToProps = (state: any) => {
  const { clientFacilityStore, uiStore, authStore, clientStore } = state;
  return {
    uiStore: { ...uiStore },
    clientFacilityStore: { ...clientFacilityStore },
    clientStore: { ...clientStore },
    authStore: { ...authStore },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientAdmin);
