import React, { useState, useContext } from "react";
import classNames from "classnames";
import { GroupContext } from "./Group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./teeSheetCard.scss";
import { ButtonNew as Button } from "components/buttonNew/index";
import { TagNew as Tag } from "components/tagNew/index";
import Icon from "components/icon/Icon";

export interface ITeeSheetCardProps {
  prefixCls?: string;
  className?: string;
  value: string | number;
  onClick?: any;
  row?: string;
  disabled?: boolean;
  accepted?: boolean;
  player?: string | null;
  expanded?: boolean;
}

export const TeeSheetCard: React.FC<ITeeSheetCardProps> = (props) => {
  const {
    prefixCls = "teesheet-card",
    className,
    value,
    onClick,
    row,
    disabled = false,
    accepted,
    player = "Default",
    expanded = false,
  } = props;

  const groupStore = useContext(GroupContext);

  const [expand, setExpand] = useState(false);

  const handleClick = (e: any) => {
    groupStore.setState({ value, row });
    if (onClick) {
      onClick();
    }
  };

  const renderCard = () => {
    if (player) {
      const teesheetCls = classNames(
        prefixCls,
        {
          [`${prefixCls}-player`]: player,
          [`${prefixCls}-expand`]: expand || expanded,
        },
        className,
      );

      let expandCard;

      if (expanded) {
        expandCard = expanded;
      } else {
        expandCard = expand;
      }

      const expandedCard = () => {
        if (expanded) {
          return (
            <div>
              <div className="teesheet-card-player-name">{player}</div>

              <div className="teesheet-card-fees">
                Green Fee: <span>$45.97</span>
              </div>
            </div>
          );
        } else {
          return <div className="teesheet-card-player-name">{player}</div>;
        }
      };

      return (
        <div className={teesheetCls} onClick={handleClick}>
          <div className="teesheet-card-content">
            <div className="teesheet-card-header">
              <div>
                {accepted ? (
                  <Icon style="fas" icon="check-circle" />
                ) : (
                  // <FontAwesomeIcon icon={["fas", "check-circle"]} className="teesheet-card-player-accepted-icon" />
                  <Icon style="far" icon="circle" />
                  // <FontAwesomeIcon icon={["far", "circle"]} className="teesheet-card-player-not-accepted-icon" />
                )}
              </div>

              {expandedCard()}

              <div className="money-icon-container">
                <FontAwesomeIcon icon={["fas", "usd-circle"]} className="teesheet-card-player-money-icon" />
              </div>
            </div>

            {expandCard && (
              <>
                <div className="teesheet-card-tags">
                  <Tag icon={<FontAwesomeIcon icon={["fas", "walking"]} className="teesheet-card-icon" />}>Member</Tag>
                  <Tag icon={<FontAwesomeIcon icon={["far", "tag"]} className="teesheet-card-icon" />}>Walking</Tag>
                </div>
                {accepted ? (
                  <Button block type="secondary">
                    Checked In
                  </Button>
                ) : (
                  <Button block type="primary">
                    Check In
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      );
    }

    if (disabled) {
      const teesheetCls = classNames(
        prefixCls,
        {
          [`${prefixCls}-disabled`]: disabled,
        },
        className,
      );

      return (
        <div className={teesheetCls} onClick={handleClick}>
          <FontAwesomeIcon icon={["far", "ban"]} className="teesheet-card-icon" />
        </div>
      );
    }
  };

  return renderCard();
};

export default TeeSheetCard;
