import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { isEqualWith, isNull, update } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { StatusCode } from "api/protocols";
import { GetCashOuts, GetCashOutSummary, UpdateCashOut } from "api/rpc/2024-04/facilityAdmin/facility/register/shift";

import { IUIActions } from "redux/actions/ui";
import { IUserPermissions } from "redux/reducers/models/user";

import { IRegister } from "../../Admin";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Page from "components/page/Page";
import Input from "components/form/input/Input";
import TextField from "components/form/textField/TextField";
import { NotificationType } from "components/notificationBar/NotificationBar";
import { Select } from "components/select/index";
import { formatDate, isNumeric } from "helpers/Helpers";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import { ButtonNew as Button } from "components/buttonNew";
import { GetFacilityAdmin } from "api/rpc/facilityAdmin/client/admin";
import moment from "moment";
import Icon from "components/icon/Icon";
import { ICashOut } from "api/rpc/2022-09/facilityAdmin/facility/register/cashOut";
import { GetRegisters } from "api/rpc/2024-04/facilityAdmin/facility/register/register";

interface ICashOutEditProps {
  uiActions: IUIActions;
  permissions: IUserPermissions;
}

interface ICashOutEditState {
  cashOut: ICashOut;
  cashOutBeforeChanges: ICashOut;
  cashOutLoaded: boolean;
  depositCashInput: string;
  depositCheckInput: string;
  registers: IRegister[];
  deposit_date: Date;
  deposited_by: number;
  deposited_by_name: string;
  closed_at: Date;
}

interface IParams {
  cashOutId: string;
}

interface IFilterState {
  adminSearch: string;
  searchResults: any[];
  deposit_date?: Date;
  showDepositInfo: boolean;
  showDepositInfoButton: boolean;
}

export default function CashOutEdit(props: ICashOutEditProps) {
  const { cashOutId } = useParams<IParams>();

  const { uiActions, permissions } = props;

  const { Option } = Select;

  const { t, i18n } = useTranslation();

  const [cashOutEditState, setCashOutEditState] = useState<ICashOutEditState>({
    cashOut: undefined,
    cashOutBeforeChanges: undefined,
    cashOutLoaded: false,
    depositCashInput: "",
    depositCheckInput: "",
    registers: [],
    deposit_date: new Date(),
    deposited_by: null,
    deposited_by_name: "",
    closed_at: null,
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    adminSearch: "",
    searchResults: [],
    showDepositInfo: false,
    showDepositInfoButton: false,
  });

  useEffect(() => {
    void loadCashOut();
    void loadRegisters();
    // void loadAdminUsers();
  }, []);

  async function loadCashOut() {
    const getCashOutsResponse = await GetCashOuts({ id: parseInt(cashOutId) }, true);

    if (getCashOutsResponse.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.settings.cash_outs.cash_out_edit.001"));
      return;
    }

    const cashOut = getCashOutsResponse.data?.[0];

    let tempClosedDateString = "";
    let tempClosedDate = new Date();

    tempClosedDateString = moment(cashOut.closed_at).format();
    tempClosedDate = new Date(tempClosedDateString);

    let tempDateString = "";
    let tempDate = new Date();
    let showDepositInfo = false;
    let showDepositInfoButton = true;

    if (cashOut.deposit_date !== null && cashOut.deposit_date !== undefined) {
      tempDateString = moment(cashOut.deposit_date).format();
      tempDate = new Date(tempDateString);
      showDepositInfo = true;
      showDepositInfoButton = false;
    }

    const defaultSearch = "";

    const usersRes = await GetFacilityAdmin(null, true);

    if (usersRes.status !== StatusCode.OK) {
      uiActions.showError(usersRes.message);
      return;
    }

    setCashOutEditState(prev => ({
      ...prev,
      cashOut: cashOut,
      cashOutLoaded: true,
      depositCashInput: String(cashOut?.deposit_cash),
      depositCheckInput: String(cashOut?.deposit_check),
      deposited_by: cashOut.deposited_by,
      deposit_date: tempDate,
      deposited_by_name: defaultSearch,
      closed_at: tempClosedDate,
    }));

    setFilterState(prevState => ({
      ...prevState,
      deposit_date: tempDate,
      showDepositInfo: showDepositInfo,
      showDepositInfoButton: showDepositInfoButton,
      searchResults: usersRes.data,
      adminSearch: defaultSearch,
    }));
  }

  async function loadRegisters() {
    const getRegistersResponse = await GetRegisters({}, true);

    if (getRegistersResponse.status !== StatusCode.OK) {
      uiActions.showError(t("secure.facility.settings.cash_outs.cash_out_edit.002"));
      return;
    }

    setCashOutEditState(prevState => ({ ...prevState, registers: getRegistersResponse.data }));
  }

  function handleValidatedCashOutChange(event: any, cashOutChange: Partial<ICashOut>) {
    const { id, value } = event.target;
    setCashOutEditState(prev => ({
      ...prev,
      [id]: value,
      cashOut: {
        ...prev.cashOut,
        ...cashOutChange,
      },
    }));
  }

  function handleCashOutInputChange(event: any) {
    const { id, value } = event.target;
    setCashOutEditState(prev => ({
      ...prev,
      cashOut: {
        ...prev.cashOut,
        [id]: value,
      },
    }));
  }

  function handleCashOutRegisterChange(register_id: number) {
    setCashOutEditState(prev => ({
      ...prev,
      cashOut: {
        ...prev.cashOut,
        register_id,
      },
    }));
  }

  function unsavedChangesExist() {
    if (cashOutEditState.cashOutBeforeChanges === undefined) {
      if (cashOutEditState.cashOutLoaded) {
        setCashOutEditState(prev => ({
          ...prev,
          cashOutBeforeChanges: prev.cashOut,
        }));
      }

      return false;
    }

    return !isEqualWith(cashOutEditState.cashOutBeforeChanges, cashOutEditState.cashOut, (originalValue, newValue) => {
      if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
        return true;
      }
    });
  }

  function cancelUnsavedChanges() {
    console.log(cashOutEditState);
    console.log(filterState);

    const tempDateString = moment(filterState.deposit_date).format();
    const tempDate = new Date(tempDateString);

    setCashOutEditState(prev => ({
      ...prev,
      cashOut: prev.cashOutBeforeChanges,
      depositCashInput: String(prev.cashOutBeforeChanges.deposit_cash),
      depositCheckInput: String(prev.cashOutBeforeChanges.deposit_check),
      deposit_date: tempDate,
    }));

    if (filterState.showDepositInfoButton) {
      setFilterState(prevState => ({ ...prevState, showDepositInfo: false, adminSearch: "" }));
    }
  }

  async function printCashOutSummary() {
    const res = await GetCashOutSummary(
      {
        register_shift_id: Number(cashOutId),
      },
      true,
    );

    if (res.status === StatusCode.OK) {
      window.open().document.write(res.data);
    } else {
      uiActions.showError("Error getting summary"); //TODO: Translation
    }
  }

  function saveCashout() {
    void updateCashOut();
  }

  const primaryAction = {
    content: "Save", // TODO: Translation
    action: saveCashout,
  };

  function handleDateSelector(deposit_date: Date) {
    setCashOutEditState(prev => ({
      ...prev,
      cashOut: {
        ...prev.cashOut,
        deposit_date,
      },

      deposit_date: deposit_date,
    }));
  }

  async function updateCashOut() {
    const params = {
      deposited_by: cashOutEditState.cashOut.deposited_by,
      deposit_date: formatDate(cashOutEditState.deposit_date),
      register_shift_id: parseInt(cashOutId),
      deposit_cash: parseFloat(cashOutEditState.depositCashInput),
      deposit_check: parseFloat(cashOutEditState.depositCheckInput),
      deposit_bag_number: cashOutEditState.cashOut.deposit_bag_number,
      notes: cashOutEditState.cashOut.notes,
      register_id: cashOutEditState.cashOut.register_id,
    };

    const updateRes = await UpdateCashOut(params, true);

    if (updateRes.status !== StatusCode.OK) {
      uiActions.showError(updateRes.message);
      return;
    }

    const cashOut = updateRes.data;

    let tempDateString = "";
    let tempDate = new Date();

    if (cashOut.deposit_date !== null && cashOut.deposit_date !== undefined) {
      tempDateString = moment(cashOut.deposit_date).format();
      tempDate = new Date(tempDateString);
    }

    uiActions.showSuccess("Cash out saved successfully"); // TODO: Translation

    setFilterState(prevState => ({ ...prevState, deposit_date: cashOutEditState.cashOut.deposit_date }));
    setCashOutEditState(prev => ({
      ...prev,
      cashOut: cashOut,
      cashOutBeforeChanges: cashOut,
      depositCashInput: String(cashOut?.deposit_cash),
      depositCheckInput: String(cashOut?.deposit_check),
      deposited_by: cashOut.deposited_by,
      deposit_date: tempDate,
    }));
  }

  function handleCashOutDepositByChange(deposited_by: number, deposited_by_full_name: string) {
    setCashOutEditState(prev => ({
      ...prev,
      cashOut: {
        ...prev.cashOut,
        deposited_by,
        deposited_by_full_name,
      },
    }));
    setFilterState(prevState => ({ ...prevState, adminSearch: "" }));
  }

  const handleAdminSearch = (query: string) => {
    setFilterState(prevState => ({ ...prevState, adminSearch: query }));
  };

  function handleAddDepositDetails() {
    if (filterState.showDepositInfo) {
      setFilterState(prevState => ({ ...prevState, showDepositInfo: false }));
      setCashOutEditState(prev => ({
        ...prev,
        cashOut: {
          ...prev.cashOut,
          deposit_date: null,
        },
        deposit_date: filterState.deposit_date,
      }));
    } else {
      setFilterState(prevState => ({ ...prevState, showDepositInfo: true }));
      setCashOutEditState(prev => ({
        ...prev,
        cashOut: {
          ...prev.cashOut,
          deposit_date: cashOutEditState.deposit_date,
        },
      }));
    }
  }

  return (
    <Page
      title={`${t("secure.facility.settings.cash_outs.cash_out_edit.003")} ${
        cashOutEditState.closed_at ? moment(cashOutEditState.closed_at).format("MMM DD, YYYY") : ""
      }`}
      narrow
      primaryAction={permissions.cash_outs_view_summary && primaryAction}
      multipleActionDropdownAction={{
        label: "Options",
        dropdownProps: {
          alignment: "right",
          options: [
            {
              type: "handler",
              label: "Print Summary", // TODO: Translation
              icon: "print",
              handler: () => printCashOutSummary(),
            },
            {
              type: "handler",
              label: "Void", // TODO: Translation
              icon: "circle-x",
              handler: () => {},
              disabled: !permissions.cash_outs_edit,
            },
          ],
        },
      }}
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.cash_outs.cash_out_edit.004"),
          url: "/admin/settings/cash-out",
        },
      ]}
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: saveCashout,
        onCancel: cancelUnsavedChanges,
      }}
    >
      {cashOutEditState.cashOut && cashOutEditState.cashOutLoaded && (
        <>
          <Card>
            <Card.Section>
              <FormLayout>
                <FormLayout.Group>
                  <Input
                    label={t("secure.facility.settings.cash_outs.cash_out_edit.006")}
                    onChange={() => {
                      return;
                    }}
                    value={cashOutEditState.cashOut.closed_by_full_name}
                    disabled={!permissions.cash_outs_edit}
                    readOnly
                  />
                  <Input
                    label={"Closed On"} // TODO: Translation
                    onChange={() => {
                      return;
                    }}
                    value={moment(cashOutEditState.closed_at).format("YYYY-MM-DD")}
                    disabled={true}
                    readOnly
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Input
                    value={cashOutEditState.depositCashInput}
                    label={t("secure.facility.settings.cash_outs.cash_out_edit.007")}
                    id="depositCashInput"
                    onChange={(e: any) =>
                      handleValidatedCashOutChange(
                        e,
                        isNumeric(e.target.value) ? { deposit_cash: parseFloat(e.target.value) } : {},
                      )
                    }
                    error={!isNumeric(cashOutEditState.depositCashInput)}
                    disabled={!permissions.cash_outs_edit}
                    readOnly
                  />
                  <Input
                    value={cashOutEditState.depositCheckInput}
                    label={t("secure.facility.settings.cash_outs.cash_out_edit.008")}
                    id="depositCheckInput"
                    onChange={(e: any) =>
                      handleValidatedCashOutChange(
                        e,
                        isNumeric(e.target.value) ? { deposit_check: parseFloat(e.target.value) } : {},
                      )
                    }
                    error={!isNumeric(cashOutEditState.depositCheckInput)}
                    disabled={!permissions.cash_outs_edit}
                    readOnly
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Input
                    value={cashOutEditState.cashOut.deposit_bag_number}
                    label={t("secure.facility.settings.cash_outs.cash_out_edit.009")}
                    id="deposit_bag_number"
                    onChange={handleCashOutInputChange}
                    disabled={!permissions.cash_outs_edit}
                    readOnly
                  />
                  <Select
                    label={t("secure.facility.settings.cash_outs.cash_out_edit.010")}
                    onChange={handleCashOutRegisterChange}
                    defaultValue={cashOutEditState.cashOut.register_id}
                    disabled={true}
                  >
                    {cashOutEditState.registers?.map((register, index) => {
                      return (
                        <Option key={index} value={register.id}>
                          {register.title}
                        </Option>
                      );
                    })}
                  </Select>
                </FormLayout.Group>
                <FormLayout.Group>
                  <TextField
                    value={cashOutEditState.cashOut.notes}
                    label={t("secure.facility.settings.cash_outs.cash_out_edit.011")}
                    id="notes"
                    onChange={handleCashOutInputChange}
                    disabled={!permissions.cash_outs_edit}
                  />
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>
          </Card>

          <Card title="Deposit">
            <Card.Section>
              <FormLayout>
                <FormLayout.Group>
                  <div className="flex align-items-end h-full">
                    <DatePickerInput
                      months={1}
                      position={"left"}
                      label="Deposited On" // TODO: Translation
                      startingDate={cashOutEditState.deposit_date}
                      setStartingDate={handleDateSelector}
                    />
                  </div>

                  {cashOutEditState.cashOut?.deposited_by ? (
                    <div style={{ marginTop: "5px" }}>
                      <div>
                        <p className="event-label"> {"Deposited By" /* TODO: Translation */} </p>
                      </div>

                      <div className="selected-container">
                        <div className="event-name">
                          <div>{cashOutEditState.cashOut?.deposited_by_full_name}</div>

                          <div>
                            <button
                              className=""
                              onClick={() =>
                                setCashOutEditState(prevState => ({
                                  ...prevState,
                                  cashOut: { ...prevState.cashOut, deposited_by: null, deposited_by_full_name: null },
                                }))
                              }
                            >
                              <Icon style="far" icon="times" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Select
                      showSearch
                      onSearch={(query: string) => handleAdminSearch(query)}
                      onChange={(id: number, extraValues: string) => handleCashOutDepositByChange(id, extraValues)}
                      allowClear
                      searchValue={filterState.adminSearch}
                      label="Deposited By" // TODO: Translation
                      placeholder="Users" // TODO: Translation
                      defaultValue={cashOutEditState.cashOut.deposited_by}
                      dropDownPositionTop
                      noData={
                        <div className="px-4">
                          <div className="border-b flex items-center p-4 border-b mb-4">
                            <Icon icon="users-slash" style="fas" className="m-0" />
                            <span className="ml-3 italic">No user found...</span>
                          </div>
                        </div>
                      }
                    >
                      {filterState.searchResults
                        .filter(result =>
                          result.full_name.toLowerCase().includes(filterState.adminSearch.toLowerCase()),
                        )
                        .map((user: any) => {
                          if (user !== null && user !== undefined) {
                            return (
                              <Option key={user?.id} value={user?.id} extraValues={user?.full_name}>
                                <div className="flex justify-between">
                                  <div>
                                    <div className="text-semibold text-lg">{user?.full_name}</div>
                                    <div className="text-sm text-gray-500">{user?.email}</div>
                                  </div>
                                </div>
                              </Option>
                            );
                          }
                        })}
                    </Select>
                  )}
                </FormLayout.Group>
              </FormLayout>
            </Card.Section>
          </Card>
        </>
      )}
    </Page>
  );
}
