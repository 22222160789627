import React, { useMemo } from "react";
import { LocaleCurrency } from "helpers/Locale";

import "elements/register/register.scss";
import Sheet from "components/sheet/Sheet";
import { range } from "lodash";
import { IProduct } from "redux/reducers/models/product";
import classNames from "classnames";
import RightClickMenu from "components/rightClickMenu/RightClickMenu";
import useModal from "hooks/modals/useModal";
import Portal from "elements/Portal";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import { displayCurrency } from "helpers/Helpers";

interface IProps {
  productState: IProductState;
  products: IProduct[];
  useGridPositions: boolean;
  onClick: (product: IProduct) => void;
  onCancel: () => void;
  onConfirmVariant: (variant: Record<string, any>) => void;
  darkStyle?: boolean;
  disabled?: boolean;
}

export interface IProductState {
  selectedProduct: undefined | Record<string, any>;
  variants: Record<string, any>[];
  variantSheetOpen: boolean;
}

export default function Products(props: IProps) {
  const GRID_COL = 6;

  const { state, updateModal, closeModal } = useModal({ product: undefined as IProduct });

  const gridRows: number = useMemo(() => {
    const positions = props.products?.map(product => product.folder_position);

    if (positions == null || positions.length === 0) {
      return 0;
    } else {
      const maxPosition = positions.reduce((prev, next) => (prev < next ? next : prev));
      const gridRows = Math.ceil(maxPosition / GRID_COL);
      return gridRows;
    }
  }, [props.products]);

  function renderProducts() {
    if (props.useGridPositions) {
      return range(1, GRID_COL * gridRows + 1).map((position, index) => {
        const product = props.products.find(product => product.folder_position === position);

        if (product) {
          return (
            <SingleProductRender
              key={index}
              product={product}
              disabled={props.disabled}
              onContentClick={props.onClick}
              onMenuClick={product => updateModal({ isOpen: true, product: product })}
            />
          );
        } else {
          return <div key={index} className="product_container" />;
        }
      });
    } else {
      return props.products.map((product, index) => {
        return (
          <SingleProductRender
            key={index}
            product={product}
            disabled={props.disabled}
            onContentClick={props.onClick}
            onMenuClick={product => updateModal({ isOpen: true, product: product })}
          />
        );
      });
    }
  }

  function handleOnConfirmVariant(variant: Record<string, any>) {
    if (variant != null && !variant?.meta?.cannot_be_sold) {
      props.onConfirmVariant(variant);
    }
  }

  return (
    <div>
      <div className="ui-register-products">{renderProducts()}</div>
      {/*Variant modal */}
      <Sheet
        size={"small"}
        title={"Select Product Variant"}
        open={props.productState.variantSheetOpen}
        onCancel={props.onCancel}
        closable
        darkStyle={props.darkStyle ? true : false}
      >
        <div className="flex-1 overflow-auto h-full">
          <div
            className={classNames("flex flex-col py-2 px-3 mb-4 ui-register-product-variant-title-container", {
              "ui-register-dark-style": props.darkStyle,
            })}
          >
            <span className="ui-register-product-variant-title">{props.productState.selectedProduct?.title}</span>
            <span className="ui-register-product-variant-sub_title" style={{ color: props.darkStyle && "#FFFFFF" }}>{`${
              props.productState?.variants.length
            } ${props.productState?.variants.length === 1 ? "Variant" : "Variants"}`}</span>
          </div>
          {props.productState?.variants.map((variant: any) => {
            return (
              <div
                key={String(variant.id)}
                className={classNames("ui-register-product-variant-container", {
                  "ui-register-product-variant-disabled": variant?.meta?.cannot_be_sold,
                })}
                onClick={() => handleOnConfirmVariant(variant)}
              >
                <div className="flex gap-4 items-center">
                  <div className="flex w-full flex-col">
                    <span className="ui-register-product-variant-container-title">{variant.title}</span>
                    <span className="ui-register-product-variant-container-sub_title">{`${
                      variant.inventory_item?.inventory_level?.quantity_available
                        ? `${String(variant.inventory_item.inventory_level.quantity_available)} in stock`
                        : ""
                    }`}</span>
                  </div>
                </div>

                <div className="mr-6 ui-register-product-variant-container-price_title">
                  <LocaleCurrency currency="cad" amount={variant.price} />
                </div>
              </div>
            );
          })}
        </div>
      </Sheet>

      {/* Details modal */}
      <Portal isMounted={state.isOpen}>
        <Sheet
          title={`${state.product?.title} Details`}
          open={state.isOpen}
          onCancel={closeModal}
          darkStyle={props.darkStyle}
          closable
          hideCancelButton
          size="small"
        >
          {!state.product ? null : (
            <>
              {state.product.track_inventory ? (
                // Inventory is tracked.  Column added to end of table
                <DataTable
                  columns={
                    state.product.variant_count > 1
                      ? [
                          { label: "" }, // variant.title
                          { label: "Cost", justify: "right" }, // TODO: Translation
                          { label: "Price", justify: "right" }, // TODO: Translation
                          { label: "Inventory", justify: "right" }, // TODO: Translation
                        ]
                      : [
                          { label: "Cost", justify: "right" }, // TODO: Translation
                          { label: "Price", justify: "right" }, // TODO: Translation
                          { label: "Inventory", justify: "right" }, // TODO: Translation
                        ]
                  }
                >
                  {state.product.variants.map(variant => (
                    <tr key={variant.id}>
                      {state.product.variant_count > 1 ? <td>{variant.title}</td> : null}
                      <td align="right">{displayCurrency("cad", variant.cost)}</td>
                      <td align="right">{displayCurrency("cad", variant.price)}</td>
                      <td align="right">{variant.inventory_item.inventory_level?.quantity_available}</td>
                    </tr>
                  ))}
                </DataTable>
              ) : (
                // NO Inventory count column
                <DataTable
                  columns={
                    state.product.variant_count > 1
                      ? [
                          { label: "" }, // variant.title
                          { label: "Cost", justify: "right" }, // TODO: Translation
                          { label: "Price", justify: "right" }, // TODO: Translation
                        ]
                      : [
                          { label: "Cost", justify: "right" }, // TODO: Translation
                          { label: "Price", justify: "right" }, // TODO: Translation
                        ]
                  }
                >
                  {state.product.variants.map(variant => (
                    <tr key={variant.id}>
                      {state.product.variant_count > 1 ? <td>{variant.title}</td> : null}
                      <td align="right">{displayCurrency("cad", variant.cost)}</td>
                      <td align="right">{displayCurrency("cad", variant.price)}</td>
                    </tr>
                  ))}
                </DataTable>
              )}
            </>
          )}
        </Sheet>
      </Portal>
    </div>
  );
}

function SingleProductRender(props: {
  product: IProduct;
  disabled: boolean;
  onContentClick: (product: IProduct) => void;
  onMenuClick: (product: IProduct) => void;
}) {
  const { product, disabled, onContentClick } = props;
  return (
    <RightClickMenu
      sections={[
        [
          {
            icon: "circle-info",
            title: "Product Details", // TODO: Translation
            action: () => props.onMenuClick(product),
            // disabled: String(segment.blocked_type) === "open",
          },
        ],
      ]}
    >
      <div className="product_container">
        <div
          className={"product_content " + (disabled || product?.meta?.cannot_be_sold ? "product_content-disabled" : "")}
          style={product.meta?.button_color ? { background: product?.meta?.button_color } : {}}
          onClick={disabled || product?.meta?.cannot_be_sold ? undefined : () => onContentClick(product)}
        >
          <div>
            <div className="product-title">{product.preferred_title ? product.preferred_title : product.title}</div>
            {product.subtitle ? <div className="product-vendor">{product.subtitle}</div> : null}
            <div className="product-vendor">{product.vendor_title}</div>
          </div>
          <div className="product-details">
            <div className="product-stock">
              {product?.variant_count === 1 && product?.variants[0]?.inventory_item?.tracked
                ? product.variants[0]?.inventory_item?.inventory_level?.quantity_available
                : null}
            </div>
            <div className="product-price">
              {product.variants?.length === 1 ? `$${String(product?.variants[0]?.price?.toFixed(2))}` : ""}
            </div>
          </div>
        </div>
      </div>
    </RightClickMenu>
  );
}
