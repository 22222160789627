import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import { UpdateShotgunStartHole } from "api/rpc/2024-04/facilityAdmin/teesheet/teeSheet";
import { StatusCode } from "api/protocols";

import { showSuccess, showError } from "redux/actions/ui";

import Sheet from "components/sheet/Sheet";
import Input from "components/form/input";

import { ITeeTimeNew } from "redux/reducers/models/teetime";

interface IAddTeeTimeModalProps {
  open: boolean;
  teetime: ITeeTimeNew;
  onOk: () => void;
  onCancel: () => void;
}

interface IAddTeeTimeModalState {
  start_hole: string;
}

export default function EditStartHoleModal(props: IAddTeeTimeModalProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { open, teetime, onOk, onCancel } = props;

  const [state, setState] = useState<IAddTeeTimeModalState>({
    start_hole: teetime.slots[0].start_hole,
  });

  async function editStartHole() {
    const params = {
      tee_time_id: teetime.slots[0].tee_time_id,
      start_hole: state.start_hole,
    };

    const editStartHoleRes = await UpdateShotgunStartHole(params, true);

    if (editStartHoleRes.status !== StatusCode.OK) {
      dispatch(showError("Error updating shotgun start hole")); // TODO: Translation
      return;
    }

    dispatch(showSuccess("Successfully updated shotgun start hole")); // TODO: Translation

    onOk();
  }

  return (
    <Sheet
      open={open}
      size={"small"}
      closable
      title={t("elements.tee_sheet.tee_sheet_tabs.135")}
      onCancel={onCancel}
      cancelText={t("elements.tee_sheet.tee_sheet_tabs.136")}
      onOk={editStartHole}
      okText={t("elements.tee_sheet.tee_sheet_tabs.137")}
    >
      <div className="py-4">
        <Input
          value={state.start_hole}
          label={t("elements.tee_sheet.tee_sheet_tabs.133")}
          id="start_hole"
          onChange={e => setState(prevState => ({ ...prevState, start_hole: e.target.value }))}
        />
      </div>
    </Sheet>
  );
}
