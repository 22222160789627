import React, { useEffect, useState, useCallback } from "react";
import { useHistory, Link } from "react-router-dom";
import { useParams } from "react-router";

// API
import { GetKitchenLocations, PutKitchenLocation } from "api/rpc/facilityAdmin/facility/kitchen/kitchen";
import { GetPrinterAll } from "api/rpc/2022-09/facilityAdmin/facility/printer";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import { Select } from "components/select/";

import { useTranslation, Trans } from "react-i18next";
import { GetVendor, PutVendor } from "api/rpc/2022-09/clientAdmin/product/vendors";

export default function VendorsEdit(props: any) {
  const { uiActions } = props;
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { t, i18n } = useTranslation();
  const [state, setState] = useState({
    title: null,
  });

  useEffect(() => {
    void loadVendor();
  }, []);

  async function loadVendor() {
    const params = {
      id: Number(id),
    };

    const res = await GetVendor(params, true);
    if (res.status !== StatusCode.OK) {
      uiActions.showError("Error loading vendor");
      return;
    }

    setState(prevState => ({
      ...prevState,
      id: res.data[0].id,
      title: res.data[0].title,
    }));
  }

  async function saveVendor() {
    const params = {
      id: Number(id),
      title: state.title,
    };

    const updateRes = await PutVendor(params, true);
    if (updateRes.status !== StatusCode.OK) {
      uiActions.showError("Error updating vendor title");
      return;
    }

    history.push("/admin/settings/client-settings/vendors");
  }

  const primaryAction = {
    content: t("secure.facility.settings.kitchen.kitchen_location.004"),
    action: saveVendor,
  };

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setState(prevState => ({ ...prevState, [id]: value }));
  }

  return (
    <Page
      title={"Edit Vendor"}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: "Vendors",
          url: "/admin/settings/client-settings/vendors",
        },
      ]}
    >
      <Card>
        <Card.Section>
          <Input
            value={state?.title}
            label={t("secure.facility.settings.folders.folders_edit.013")}
            id="title"
            onChange={handleInputChange}
            placeholder={t("secure.facility.settings.folders.folders_edit.014")}
            className="mb-2"
          />
        </Card.Section>
      </Card>
    </Page>
  );
}
