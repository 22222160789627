export function enqueue() {
  return (dispatch: any, getState: any) => {
    const queueCount = getState().uiStore.queue;
    if (queueCount == 0) {
      dispatch({ type: "loading", payload: true });
    }
    dispatch({ type: "modify.queue", payload: Number(queueCount) + 1 });
  };
}

export function dequeue() {
  return (dispatch: any, getState: any) => {
    const queueCount = getState().uiStore.queue;
    if (queueCount == 1) {
      dispatch({ type: "loading", payload: false });
    }
    dispatch({ type: "modify.queue", payload: Number(queueCount) - 1 });
  };
}

export function showError(errorMessage: string, duration: number | null = 5000) {
  return (dispatch: any) => {
    dispatch({ type: "error.show", payload: { errorMessage, duration } });
  };
}

export function closeError() {
  return (dispatch: any) => {
    dispatch({ type: "error.close" });
  };
}

export function showSuccess(successMessage: string, duration: number | null = 5000) {
  return (dispatch: any) => {
    dispatch({ type: "success.show", payload: { successMessage, duration } });
  };
}

export function closeSuccess() {
  return (dispatch: any) => {
    dispatch({ type: "success.close" });
  };
}

export const initiateNewBooking = () => {
  return (dispatch: any) => {
    dispatch({ type: "booking.initiate" });
  };
};

export const closeBooking = () => {
  return (dispatch: any) => {
    dispatch({ type: "booking.close" });
  };
};

export const initiateEditBooking = (editBookingStartTab: number | null = 0) => {
  return (dispatch: any) => {
    dispatch({ type: "editBooking.initiate", payload: { editBookingStartTab } });
  };
};

export const closeEditBooking = () => {
  return (dispatch: any) => {
    dispatch({ type: "editBooking.close" });
  };
};

export interface IUIActions {
  enqueue: typeof enqueue;
  dequeue: typeof dequeue;
  showError: typeof showError;
  closeError: typeof closeError;
  showSuccess: typeof showSuccess;
  closeSuccess: typeof closeSuccess;
  initiateNewBooking: typeof initiateNewBooking;
  initiateEditBooking: typeof initiateEditBooking;
  closeBooking: typeof closeBooking;
  closeEditBooking: typeof closeEditBooking;
}
