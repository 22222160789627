import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppSelector } from "hooks/redux";
import "./style.scss";

export default function Loader() {
  const uiStore = useAppSelector(store => store.uiStore);

  if (!uiStore.loading) {
    return null;
  }
  return (
    <div className="loader_container">
      <div className="loader_content">
        <div className="loader">
          <FontAwesomeIcon icon={["far", "spinner-third"]} spin size="lg" />
          <p>Loading...</p>
        </div>
      </div>
    </div>
  );
}
