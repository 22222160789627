import ColorPicker from "components/colorpicker/color-picker";
import React, { useEffect, useRef, useState } from "react";
import "./colorPickerDropdown.scss";

interface IColorPickerDropdownProps {
  isDropdownOpen: boolean;
  setIsDropdownOpen: (isDropdownOpen: boolean) => void;
  onChange: (color: string) => void;
  children: React.ReactNode;
}

export function ColorPickerDropdown(props: IColorPickerDropdownProps) {
  const { isDropdownOpen, setIsDropdownOpen, onChange, children } = props;

  const dropdownRef = useRef<HTMLDivElement>();
  const [color, setColor] = useState<string>("");

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideDropdownClick);
    document.addEventListener("touchstart", handleOutsideDropdownClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideDropdownClick);
      document.removeEventListener("touchstart", handleOutsideDropdownClick);
    };
  }, []);

  useEffect(() => {
    if (!isDropdownOpen) {
      setColor("");
    }
  }, [isDropdownOpen]);

  useEffect(() => {
    if (color !== "") {
      onChange(color);
    }
  }, [color]);

  function handleOutsideDropdownClick(event: any) {
    if (!dropdownRef.current || dropdownRef.current.contains(event.target)) {
      return;
    }

    setIsDropdownOpen(false);
  }

  return (
    <div ref={dropdownRef} className="color-picker-dropdown">
      {children}
      <div style={{ display: isDropdownOpen ? "block" : "none" }} className="color-picker-dropdown-content">
        <ColorPicker colorValue={color} onClick={setColor} compact />
      </div>
    </div>
  );
}
