import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetTournamentScoresheet } from "api/rpc/2024-04/facilityAdmin/tournament/scoring/scoresheet";

import { showError } from "redux/actions/ui";
import { TTournamentScoresheet } from "redux/reducers/models/tournament";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import { ButtonNew } from "components/buttonNew";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

/** ScoreSheet display Table on Scoring > Scoresheets */
export default function TournamentScores() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { tournamentId } = useParams<{ tournamentId: string }>();

  const dispatch = useAppDispatch();

  const [tournamentScoresheets, setTournamentScoresheets] = useState<TTournamentScoresheet[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadTournament(Number(tournamentId), source.token);
    return () => source.cancel();
  }, [tournamentId]);

  async function loadTournament(id: string | number, token?: CancelToken) {
    if (tournamentScoresheets !== undefined) {
      setTournamentScoresheets(undefined);
    }
    const res = await GetTournamentScoresheet({ tournament_id: id }, token ? false : true, token);

    if (res.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.tournament.tournament_scores.002")));
    }

    setTournamentScoresheets(res.status !== StatusCode.OK ? [] : res.data);
  }

  const primaryAction = {
    content: t("secure.facility.league.league_scores.001"),
    action: () => history.push("/admin/tournament/" + tournamentId + "/scoring/scoresheet/new"),
    disabled: tournamentScoresheets === undefined,
  };

  return (
    <Page title={`Tournament Scoresheets`} narrow primaryAction={primaryAction}>
      <DataTable
        columns={[
          { label: t("secure.facility.league.league_scores.003"), width: "24%" },
          { label: t("secure.facility.league.league_scores.004"), width: "24%" },
          { label: t("secure.facility.league.league_scores.005"), width: "12%" },
          { label: "", width: "20%" }, // Details
          { label: "", width: "20%" }, // Results
        ]}
        loading={tournamentScoresheets === undefined}
      >
        {tournamentScoresheets?.map(scoresheet => (
          <tr key={scoresheet.id}>
            <td>{scoresheet.name}</td>
            <td>{scoresheet.date_played}</td>
            <td>{scoresheet.holes}</td>
            <td>
              <ButtonNew
                size="small"
                type="text"
                onClick={() =>
                  history.push(`/admin/tournament/${tournamentId}/scoring/scoresheet/${String(scoresheet.id)}`)
                }
              >
                Details
              </ButtonNew>
            </td>
            <td>
              <ButtonNew
                size="small"
                type="text"
                onClick={() =>
                  history.push(`/admin/tournament/${tournamentId}/scoring/results/${String(scoresheet.id)}`)
                }
              >
                Results
              </ButtonNew>
            </td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
