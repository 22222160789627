import React, { createContext, useContext, useEffect, useState } from "react";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetCustomer } from "api/rpc/2024-04/clientAdmin/customer/customer";
import { GetFacility } from "api/rpc/2022-09/clientAdmin/facility/facility";
import { GetAccountingReference } from "api/rpc/clientAdmin/facility/facility";

import { CustomerContextClientType, CustomerContextClientState, BookingCountType } from "./contextTypes";
import { GetTotalBookings } from "api/rpc/2022-09/clientAdmin/teeSheets/booking";
import { GetCustomerPaymentMethod } from "api/rpc/clientAdmin/customer/customer";
import { GetGiftCard } from "api/rpc/2024-04/clientAdmin/payment/giftCard";
import { GetRainChecks } from "api/rpc/2024-04/clientAdmin/payment/rainCheck";

const defaultState: CustomerContextClientType = {
  state: {
    customer: null,
    editState: null,
    preferences: null,
    notes: null,
    bookings: null,
    bookingCounts: [],
    memberships: null,
    accountingReferenceTypes: null,
    ticketStubs: null,
    rainChecks: null,
    countries: null,
    customerTypes: null,
    cartTypes: null,
    facilityFilters: null,
    houseAccounts: null,
    selectedHouseAccount: null,
    accountTransactions: null,
    accountStatements: null, // assigning NULL disables HouseAccount inputs
    accountScheduledPayments: null,
    paymentMethods: null,
    giftCards: null,
  },
  updateState: null,
  loadCustomer: null,
  refreshGiftCards: null,
  reloadPaymentMethods: null,
};

/* ACTUAL CONTEXT */
const CustomerClientContext = createContext<CustomerContextClientType>(defaultState);

/* CONTEXT PROVIDER */
type CustomerProviderClientProps = {
  customerId: number;
  children: any;
};

export const useCustomerClientContext = () => useContext(CustomerClientContext);

/** Loads necessary customer data with Global-Loader based off ID passed.  This is data available on first-load, other state defined in specified tabs. */
export default function CustomerContextProvider(props: CustomerProviderClientProps) {
  const [state, setState] = useState<CustomerContextClientState>({ ...defaultState.state });

  function updateState(newCustomerState: Partial<CustomerContextClientState>) {
    setState((cur: CustomerContextClientState) => {
      return { ...cur, ...newCustomerState };
    });
  }

  useEffect(() => {
    const source = axios.CancelToken.source();

    void loadCustomer(props.customerId, source.token);
    void loadAccountingReferenceTypes(source.token);
    void loadClientFacilityFilters(source.token);
    void loadTotalBookings(props.customerId);

    // Payment methods and cards
    void reloadPaymentMethods(props.customerId, true, source.token);
    void refreshGiftCards(props.customerId, false, source.token);
    void loadRainChecks(props.customerId, source.token);

    return () => {
      source.cancel();
    };
  }, []);

  async function loadCustomer(customerId: number, token?: CancelToken) {
    const customerRes = await GetCustomer(
      {
        id: customerId,
        memberships: true,
        preferences: true,
        "default-address-line": true,
        "address-lines": true,
      },
      true,
      token,
    );

    if (customerRes.status !== StatusCode.OK) {
      return;
    }

    let preferences = customerRes.data[0].preferences;

    // Set default preferences if preferences don't exist
    if (preferences === null) {
      preferences = {
        customer_id: customerId,
        marketing_email: false,
        booking_confirmation_email: true,
        booking_reminder_email: true,
        booking_update_email: true,
        booking_cancellation_email: true,
      };
    }

    console.log("Customer", customerRes.data[0]);

    updateState({
      customer: customerRes.data[0],
      preferences: preferences,
      editState: customerRes.data[0],
    });
  }

  async function loadAccountingReferenceTypes(token?: CancelToken) {
    const accountingResponse = await GetAccountingReference("", false, token);

    if (accountingResponse.status !== StatusCode.OK) {
      return;
    }

    const accountingReferenceTypes: any[] = [];

    accountingReferenceTypes.push({ label: "None", value: 0 });

    if (accountingResponse?.data?.length !== undefined && accountingResponse.data.length > 0) {
      const accountingReferenceTypesResponse = accountingResponse.data.map((accountingReference: any) => {
        return {
          label: accountingReference.title ?? "",
          value: accountingReference.id ?? 0,
        };
      });

      accountingReferenceTypes.push(...accountingReferenceTypesResponse);
    }

    console.log("AccountingRefType ", accountingReferenceTypes);

    updateState({ accountingReferenceTypes: accountingReferenceTypes });
  }

  async function loadClientFacilityFilters(token?: CancelToken) {
    const res = await GetFacility(null, false, token);
    if (res.status !== StatusCode.OK) {
      console.log("ClientFacility error: ", res.message);
      return;
    }

    updateState({ facilityFilters: res.data });
  }

  async function loadTotalBookings(customerId: number, token?: CancelToken) {
    const bookingRes = await GetTotalBookings({ customer_id: customerId }, false, token);
    if (bookingRes.status !== StatusCode.OK) {
      console.log("Total bookings error");
      return;
    }

    const bookingCounts: Array<BookingCountType> = [];

    const keysByYear = Object.keys(bookingRes.data);

    keysByYear.map(year => {
      let total = 0;
      let noShow = 0;
      let weatherNoShow = 0;
      total += (bookingRes.data[year].checked ?? 0) + (bookingRes.data[year].unchecked ?? 0);
      noShow += (bookingRes.data[year].no_show_defer ?? 0) + (bookingRes.data[year].no_show_charge ?? 0);
      weatherNoShow += bookingRes.data[year].no_show_weather ?? 0;
      bookingCounts.push({
        year: year,
        count: total,
        no_shows: noShow,
        weather_no_shows: weatherNoShow,
      });
    });

    updateState({ bookingCounts: bookingCounts });
  }

  async function reloadPaymentMethods(customerId: any, useGlobalLoader = true, token?: CancelToken) {
    if (state.paymentMethods !== null) {
      updateState({ paymentMethods: null });
    }

    const cardRes = await GetCustomerPaymentMethod({ customer_id: customerId }, useGlobalLoader);
    if (cardRes.status !== StatusCode.OK) {
      return;
    }

    updateState({ paymentMethods: cardRes.data });
  }

  async function refreshGiftCards(customer_id: number, useGlobalLoader = false, token?: CancelToken) {
    if (state.giftCards !== null) {
      updateState({ giftCards: null });
    }

    const giftCardRes = await GetGiftCard({ customer_id: customer_id }, useGlobalLoader, token);
    if (giftCardRes.status !== StatusCode.OK) {
      return;
    }

    console.log("gift cards ", giftCardRes); //debug
    updateState({ giftCards: giftCardRes.data.reverse() }); // Newest displayed first
  }

  async function loadRainChecks(customer_id: number, token?: CancelToken) {
    if (state.rainChecks !== undefined) {
      updateState({ rainChecks: undefined });
    }

    const rainChecksRes = await GetRainChecks({ customer_id: customer_id }, token ? false : true, token);
    if (token && token.reason) {
      return;
    }
    if (rainChecksRes.status !== StatusCode.OK) {
      updateState({ rainChecks: [] });
      return;
    }

    updateState({ rainChecks: rainChecksRes.data });
  }

  return (
    <CustomerClientContext.Provider
      value={{ state, updateState, loadCustomer, reloadPaymentMethods, refreshGiftCards }}
    >
      {props.children}
    </CustomerClientContext.Provider>
  );
}
