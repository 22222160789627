import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { StatusCode } from "api/protocols";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { showError } from "redux/actions/ui";

import Page from "components/page/Page";
import Tabs from "components/tabs/Tabs";
import Card from "components/card/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { GetRingerBoard } from "api/rpc/2022-09/facilityAdmin/league/scoring/round";

import "./leagueRingerBoard.scss";

interface IParams {
  leagueId: string;
}

interface ISortBy {
  /**Column to sort by. Default sorts the data by gross in ascending order */
  type: "total" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  direction: boolean;
}

interface IRingerBoardState {
  ringerBoard: any;
  sortBy: ISortBy;
  defaultSorted: boolean;
}

export default function LeagueRingerBoard(props: any) {
  const { facilityStore } = useAppSelector(store => store);
  const dispatch = useAppDispatch();

  const { leagueId } = useParams<IParams>();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const holeNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  const [state, setState] = useState<IRingerBoardState>({
    ringerBoard: [],
    sortBy: { type: "total", direction: true },
    defaultSorted: false,
  });

  const tabs = facilityStore.facility?.courses[0]?.divisions?.map((division: any) => {
    return {
      id: `${String(division.title)} - ${String(division.id)}`,
      content: division.title,
    };
  });

  useEffect(() => {
    void loadRingerBoard();
  }, []);

  useEffect(() => {
    if (!state.defaultSorted && state.ringerBoard.length > 0) {
      void sortByDefault();
      setState(prevState => ({ ...prevState, defaultSorted: true }));
    }
  }, [state.ringerBoard]);

  async function loadRingerBoard() {
    const ringerRes = await GetRingerBoard({ league_id: Number(leagueId) }, true);

    if (ringerRes.status !== StatusCode.OK) {
      dispatch(showError("Error loading ringer board"));
      return;
    }

    setState(prevState => ({ ...prevState, ringerBoard: ringerRes.data }));
  }

  function getTotalScore(scores: any[]) {
    const score: number = scores.reduce((partialSum, score) => Number(partialSum) + Number(score.gross), 0);
    return score;
  }

  function sortByDefault() {
    const sortedOrder = state.ringerBoard[selectedTab]?.rounds.sort((currentRound: any, nextRound: any) => {
      const currentRoundTotal = getTotalScore(currentRound.hole_scores);
      const nextRoundTotal = getTotalScore(nextRound.hole_scores);

      const currentTotal = currentRoundTotal === 0 ? 10000 : currentRoundTotal;
      const nextTotal = nextRoundTotal === 0 ? 10000 : nextRoundTotal;
      return currentTotal - nextTotal;
    });

    const newRingerBoard = state.ringerBoard;
    newRingerBoard[selectedTab].rounds = sortedOrder;

    setState(prevState => ({
      ...prevState,
      ringerBoard: newRingerBoard,
      sortBy: { type: "total", direction: true },
    }));
  }

  function sortLeaderboard(sortType: "total" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9, sortValue: string) {
    let direction: boolean = state?.sortBy?.direction;
    if (state?.sortBy?.type !== sortType) {
      direction = true;
    } else if (direction === false) {
      //Descending order, return to default order
      void sortByDefault();
      return;
    } else {
      direction = !direction;
    }

    const sortedOrder = state.ringerBoard[selectedTab]?.rounds.sort((currentRound: any, nextRound: any) => {
      if (sortType == "total") {
        const currentRoundTotal = getTotalScore(currentRound.hole_scores);
        const nextRoundTotal = getTotalScore(nextRound.hole_scores);

        if (currentRoundTotal === 0) {
          return 1;
        }
        if (nextRoundTotal === 0) {
          return -1;
        }

        return direction ? currentRoundTotal - nextRoundTotal : nextRoundTotal - currentRoundTotal;
      } else {
        if (!currentRound?.hole_scores[sortType - 1]) {
          return 1;
        }
        if (!nextRound?.hole_scores[sortType - 1]) {
          return -1;
        }
        return direction
          ? currentRound?.hole_scores[sortType - 1].gross - nextRound?.hole_scores[sortType - 1].gross
          : nextRound?.hole_scores[sortType - 1].gross - currentRound?.hole_scores[sortType - 1].gross;
      }
    });

    const newRingerBoard = state.ringerBoard;
    newRingerBoard[selectedTab].rounds = sortedOrder;

    setState(prevState => ({ ...prevState, ringerBoard: newRingerBoard, sortBy: { type: sortType, direction } }));
  }

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
    void sortByDefault();
  };

  return (
    <Page title="Ringer Board" narrow>
      <Card>
        {tabs && <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange} />}
        <table className="ui-table league-ringer-board">
          <thead>
            <tr>
              <th>Name</th>
              {holeNumbers.map((hole: any, index: number) => {
                return (
                  <th key={index}>
                    <p
                      onClick={() => sortLeaderboard(hole, "total")}
                      className="ringer-board-header flex justify-between"
                    >
                      {hole}{" "}
                      <FontAwesomeIcon
                        style={{ visibility: state?.sortBy?.type === hole ? "visible" : "hidden" }}
                        className="ml-1 mt-1"
                        icon={["far", state?.sortBy?.direction ? "arrow-up-long" : "arrow-down-long"]}
                      />
                    </p>
                  </th>
                );
              })}
              <th>
                <p
                  onClick={() => sortLeaderboard("total", "total")}
                  className="ringer-board-header flex justify-between"
                >
                  {"Total "}{" "}
                  <FontAwesomeIcon
                    style={{ visibility: state?.sortBy?.type === "total" ? "visible" : "hidden" }}
                    className="ml-1 mt-1"
                    icon={["far", state?.sortBy?.direction ? "arrow-up-long" : "arrow-down-long"]}
                  />
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            {state.ringerBoard[selectedTab]?.rounds.map((round: any, index: number) => {
              return (
                <tr key={index}>
                  <td>{round.customer_full_name}</td>
                  {round.hole_scores.length > 0 ? (
                    round.hole_scores.map((score: any, index: number) => {
                      return (
                        <td key={index} className="text-center">
                          {score.gross}
                        </td>
                      );
                    })
                  ) : (
                    <td colSpan={9} className="text-center">
                      No scores recorded
                    </td>
                  )}
                  <td>{getTotalScore(round.hole_scores)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
    </Page>
  );
}
