import React, { useCallback, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory, useLocation } from "react-router";
import moment from "moment";

import { IOrder, OrderFinancialStatus } from "redux/reducers/models/order";
import { IFacility } from "redux/reducers/models/facility";
import { valueToString } from "helpers/Helpers";
import { LocaleCurrency } from "helpers/Locale";

import Avatar from "components/avatar/Avatar";
import { Badge } from "components/badge/Badge";
import Icon from "components/icon/Icon";
import { ButtonNew as Button } from "components/buttonNew";

import "components/orderTable/ordersTable.scss";
import Spin from "components/spin/spin";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { filterHistoryAddItem, filterHistoryCreate } from "redux/actions/filters/filterHistory";
type OrdersTableProps = {
  /** Passing undefined triggers Spin component */
  orders: IOrder[];
  tableLimit: number;
  tableOffset: number;
  handleTableOffset: (direction: "prev" | "next") => void;
  handleTableNavigation?: (order_id: number) => void;
};

interface ILocationState {
  params: { orderId: number };
}

const OFFSET_HISTORY_KEY = "facility_orders_offset";

export default function MasterOrdersTable(props: OrdersTableProps) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const filterHistoryStore = useAppSelector(store => store.filterHistoryStore);

  useEffect(() => {
    if (filterHistoryStore[OFFSET_HISTORY_KEY] === undefined) {
      dispatch(filterHistoryCreate(OFFSET_HISTORY_KEY));
    }
  }, []);

  function navigateToItem(id: number) {
    if (props.handleTableNavigation) {
      props.handleTableNavigation(id);
      return;
    }

    dispatch(filterHistoryAddItem(OFFSET_HISTORY_KEY, props.tableOffset));
    history.push("/admin/order/" + String(id));
  }

  const elementScrollRef = useCallback(element => {
    const locationState = location.state as ILocationState;
    if (element !== null && locationState?.params?.orderId) {
      if (element?.id === locationState?.params?.orderId) {
        element?.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, []);

  return (
    <table className="order-table ui-table">
      <thead>
        <tr>
          <th>{t("components.orders_table.orders_table.001")}</th>
          <th>{t("components.orders_table.orders_table.002")}</th>
          <th>{t("components.orders_table.orders_table.003")}</th>
          <th>{"Client"}</th>
          <th>{"Facility"}</th>
          <th>{t("components.orders_table.orders_table.004")}</th>
          <th>{t("components.orders_table.orders_table.005")}</th>
        </tr>
      </thead>

      <tbody>
        {props.orders ? (
          <>
            {props.orders.map(order => {
              return (
                <tr
                  key={order.id}
                  onClick={() => navigateToItem(order.id)}
                  id={String(order.id)}
                  ref={elementScrollRef}
                >
                  <td>{`# ${order.number}`}</td>
                  <td>
                    {order.processed_at ? (
                      <>
                        <span>{moment.utc(order.processed_at).local().format("MMM DD, YYYY")} at </span>
                        <span>{moment.utc(order.processed_at).local().format("h:mm A")}</span>
                      </>
                    ) : (
                      <>
                        <span>{moment.utc(order.created_at).local().format("MMM DD, YYYY")} at </span>
                        <span>{moment.utc(order.created_at).local().format("h:mm A")}</span>
                      </>
                    )}
                  </td>
                  <td className="table-data-customer">
                    <div className="wrap">
                      {order.customer ? (
                        <>
                          <Avatar size="medium" source={order.customer?.profile_image_source} />
                          <div className="customer">
                            <span>{order.customer?.full_name}</span>
                            <span>{order.customer?.email}</span>
                          </div>
                        </>
                      ) : (
                        <div className="customer" />
                      )}
                    </div>
                  </td>
                  <td>{valueToString(order.client?.short_name, "capitalize")}</td>
                  <td>{valueToString(order.facility?.short_name, "capitalize")}</td>
                  <td>{generateOrderStatusBadge(order.financial_status)}</td>
                  <td>
                    <LocaleCurrency currency={order.currency} amount={order.total_price} />
                  </td>
                </tr>
              );
            })}
          </>
        ) : (
          <tr>
            <td colSpan={7} height="58.5px" style={{ pointerEvents: "none", textAlign: "center" }}>
              <span style={{ display: "inline-flex" }}>
                <Spin />
              </span>
            </td>
          </tr>
        )}
      </tbody>

      <tfoot>
        <tr>
          <td colSpan={2}>
            <Button
              size="small"
              type="secondary"
              onClick={() => {
                props.handleTableOffset("prev");
              }}
              disabled={!(props.tableLimit <= props.tableOffset)}
            >
              <FontAwesomeIcon icon={"arrow-left"} />
              &nbsp;
              {t("components.orders_table.orders_table.007")} {/* TODO: Translation */}
            </Button>
          </td>

          <td colSpan={3} />

          <td colSpan={2}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                size="small"
                type="secondary"
                onClick={() => {
                  props.handleTableOffset("next");
                }}
                disabled={!props.orders || !(props.orders?.length === props.tableLimit)}
              >
                {t("components.orders_table.orders_table.008")} {/* TODO: Translation */}
                &nbsp;
                <FontAwesomeIcon icon={"arrow-right"} />
              </Button>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  );
}

/** Generate order status badges from financial_status title.
 *   -  Easily adjust badge styling in 1 place going forward
 *   -  TODO: Translations
 */
function generateOrderStatusBadge(status: OrderFinancialStatus): JSX.Element {
  switch (status) {
    case "paid":
      return (
        <Badge
          type="success"
          // className="paid-order-badge"
          iconLeft={<Icon icon={"check"} style={"far"} size="small" />}
        >
          {valueToString(status, "capitalize")}
        </Badge>
      );
    case "partially_paid":
      return (
        <Badge
          type="primary"
          // className="partial_paid-order-badge"
          iconLeft={<Icon icon={"circle-exclamation"} style={"far"} size="small" />}
        >
          {valueToString(status, "capitalize")}
        </Badge>
      );
    case "cancelled":
      return (
        <Badge
          type="error"
          // className="cancelled-order-badge"
          iconLeft={<Icon icon={"circle-xmark"} style={"far"} size="small" />}
        >
          {valueToString(status, "capitalize")}
        </Badge>
      );

    case "refunded":
      return (
        <Badge
          type="warning"
          // className="refunded-order-badge"
          iconLeft={<Icon icon={"turn-down-left"} style={"far"} size="small" />}
        >
          {valueToString(status, "capitalize")}
        </Badge>
      );

    // case "unpaid":
    //   return (
    //     <Badge
    //       type="gray"
    //       // className="unpaid-order-badge"
    //       iconLeft={<Icon icon={"turn-down-left"} style={"fas"} size="small" />}
    //     >
    //       {valueToString(status, "capitalize")}
    //     </Badge>
    //   );

    // case "partially_refunded":
    //   return (
    //     <Badge
    //       type="gray"
    //       // className="partial_refunded-order-badge"
    //       iconLeft={<Icon icon={"turn-down-left"} style={"far"} size="small" />}
    //     >
    //       {valueToString(status, "capitalize")}
    //     </Badge>
    //   );

    // case "voided":
    //   return (
    //     <Badge
    //       type="gray"
    //       // className="voided-order-badge"
    //       iconLeft={<Icon icon={"turn-down-left"} style={"fas"} size="small" />}
    //     >
    //       {valueToString(status, "capitalize")}
    //     </Badge>
    //   );

    default:
      return (
        <Badge type="gray" className="sales-channel-badge">
          {valueToString(status, "capitalize")}
        </Badge>
      );
  }
}
