import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseLeagueUrl = "/" + apiVersion + "/" + adminType + "/league";

//GET League Settings
export async function GetScoringSettings(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseLeagueUrl + "/scoring/settings", params, useGlobalLoader);
}

// PUT League Settings
export async function PutScoringSettings(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseLeagueUrl + "/scoring/settings", params, useGlobalLoader);
}
