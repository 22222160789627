import React from "react";
import "components/membership-display/membership-style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonNew as Button } from "components/buttonNew";
import { valueToString } from "helpers/Helpers";
import moment from "moment";

interface IParentMembership {
  client_id: number;
  customer_type_id: number;
  customer_type_title: string;
  expiry: string;
  id: number;
  membership_type: string;
  product_id: number;
  reciprocal: boolean;
  subtitle: string;
  title: string;
  type: string;
  variant_id: number;
}

interface IMembershipProps {
  title: string;
  subtitle?: string;
  homeCourse?: string;
  memberNumber?: number;
  season?: string;
  expiry?: any;
  status?: string;
  parent_membership?: IParentMembership;
  clickHandler?: () => void;
}

const MembershipDisplay = (props: IMembershipProps) => {
  return (
    <div className="membership-container">
      {/*
      <div className="img-container" style={{ background: "#FFFFFF" }}>
        <FontAwesomeIcon icon={["far", "tag"]} style={{ color: "#999999" }} />
      </div>*/}
      <div className="text-container">
        <div>
          {props.season && (
            <span className="membership-text">
              {props.season} {props.title || props.parent_membership?.subtitle ? " - " : ""}
            </span>
          )}
          {props.title && (
            <span className="membership-text">
              {props?.title} {props.parent_membership?.subtitle ? " - " : ""}
            </span>
          )}
          {props.parent_membership?.subtitle && (
            <span className="membership-text">{props.parent_membership?.subtitle}</span>
          )}
        </div>
        <span className="valid-thru-text">
          <span className="font-bold">{props.status}</span> {props.homeCourse}
        </span>
        <span className="valid-thru-text">
          Expires: <span>{moment(props.expiry).format("MMMM Do, YYYY")}</span>
        </span>
      </div>
    </div>
  );
};

export default MembershipDisplay;
