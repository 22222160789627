import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetTournamentFlights } from "api/rpc/2024-04/facilityAdmin/tournament/scoring/flights/flights";

import { showError } from "redux/actions/ui";
import { TTournamentFlight } from "redux/reducers/models/tournament";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

export default function TournamentFlights() {
  const history = useHistory();
  const { t } = useTranslation();
  const { tournamentId } = useParams<{ tournamentId: string }>();

  const dispatch = useAppDispatch();

  const [flights, setFlights] = useState<TTournamentFlight[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadTournamentFlights(Number(tournamentId), source.token);
    return () => source.cancel();
  }, [tournamentId]);

  async function loadTournamentFlights(tournament_id: number, token?: CancelToken) {
    if (flights !== undefined) {
      setFlights(undefined);
    }
    const res = await GetTournamentFlights({ tournament_id }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error getting tournament flights.")); // TODO: Translation
    }

    setFlights(res.status !== StatusCode.OK ? [] : res.data);
  }

  const primaryAction = {
    content: "New Flight", // TODO: Translation
    action: () => history.push(`/admin/tournament/${tournamentId}/flights/new`),
  };

  return (
    <Page
      title={"Tournament Flights"} // TODO: Translation
      narrow
      primaryAction={primaryAction}
    >
      <DataTable columns={[{ label: "Name" } /* TODO: Translation */]} loading={flights === undefined}>
        {flights?.map(flight => (
          <tr
            key={flight.id}
            className="clickable"
            onClick={() => history.push(`/admin/tournament/${tournamentId}/flights/${String(flight.id)}/edit`)}
          >
            <td>{flight?.name}</td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
