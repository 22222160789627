import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios, { CancelToken } from "axios";

import Page from "components/page/Page";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Input from "components/form/input/Input";
import { Select } from "components/select/index";
import { StatusCode } from "api/protocols";

import { useTranslation, Trans } from "react-i18next";
import { PostTaxLine } from "api/rpc/2024-04/facilityAdmin/client/taxLines";
import { GetAccountingReference } from "api/rpc/2024-04/facilityAdmin/facility/accounting";
import { TAccountingReference } from "redux/reducers/models/facility";
import { Form } from "react-bootstrap";

interface ITaxLineState {
  name: string;
  percentage: number;
  accountingReferences: Array<TAccountingReference>;
  accounting_reference_id: number;
  accountingRefSearch: string;
}

export default function TaxLinesNew(props: any) {
  const history = useHistory();
  const { Option } = Select;
  const { t, i18n } = useTranslation();
  const [taxLineState, setTaxLineState] = useState<ITaxLineState>({
    name: "",
    percentage: null,
    accountingReferences: [],
    accounting_reference_id: null,
    accountingRefSearch: "",
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadAccountingReferences(source.token);
  }, []);

  async function loadAccountingReferences(token: CancelToken) {
    const res = await GetAccountingReference(null, false, token);

    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      return;
    }

    const normalizedReferences: TAccountingReference[] = [
      { id: -1, client_id: null as number, title: "None", reference_number: null as string },
      ...res.data,
    ];

    setTaxLineState(prev => ({ ...prev, accountingReferences: normalizedReferences }));
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setTaxLineState(prevState => ({ ...prevState, [id]: value }));
  }

  async function saveNewTaxLine() {
    console.log(taxLineState);
    const res = await PostTaxLine(
      {
        name: taxLineState.name,
        percentage: taxLineState.percentage,
        accounting_reference_id: taxLineState.accounting_reference_id,
      },
      true,
    );
    if (res.status !== StatusCode.OK) {
      return;
    }

    console.log(res);
    history.push("/admin/settings/taxes");
  }

  const primaryAction = {
    content: t("secure.facility.settings.tax_lines.tax_lines_new.001"),
    action: saveNewTaxLine,
  };

  return (
    <Page
      title={t("secure.facility.settings.tax_lines.tax_lines_new.002")}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.tax_lines.tax_lines_new.003"),
          url: "/admin/settings/taxes",
        },
      ]}
    >
      <Card>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Input
                value={taxLineState.name}
                label={t("secure.facility.settings.tax_lines.tax_lines_new.004")}
                id="name"
                onChange={handleInputChange}
                placeholder={t("secure.facility.settings.tax_lines.tax_lines_new.005")}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <Input
                value={taxLineState.percentage}
                label={t("secure.facility.settings.tax_lines.tax_lines_new.006")}
                id="percentage"
                onChange={handleInputChange}
                placeholder={t("secure.facility.settings.tax_lines.tax_lines_new.007")}
              />
              <Select
                label={"Accounting Reference"}
                showSearch
                onSearch={(query: string) => setTaxLineState(prev => ({ ...prev, accountingRefSearch: query }))}
                onChange={(value: number) => setTaxLineState(prev => ({ ...prev, accounting_reference_id: value }))}
                allowClear
                placeholder={t("secure.facility.product.product_new.031")}
                searchValue={taxLineState.accountingRefSearch}
                showDropDownOnFocus={true}
              >
                {taxLineState.accountingReferences
                  .filter(accountRef =>
                    accountRef.title.toLowerCase().includes(taxLineState.accountingRefSearch.toLowerCase()),
                  )
                  .map(reference => {
                    return (
                      <div key={reference.id}>
                        {taxLineState.accountingRefSearch !== "" ? (
                          <div>
                            <Select.Option key={reference.id} value={reference.id} name={reference.title}>
                              <p className="font-semibold text-lg">{reference.title}</p>
                            </Select.Option>
                          </div>
                        ) : null}
                      </div>
                    );
                  })}
              </Select>
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>
    </Page>
  );
}
