import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { IForm } from "api/rpc/2022-09/guest/form";
import { CancelToken } from "axios";
import { IOrder } from "redux/reducers/models/order";

const apiVersion = "2022-09";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/client/form/response";

export type FormInputTypes = "input" | "select" | "checkbox";

interface IFormResponseRes<T> {
  status: number;
  data: Array<T>;
  message?: string;
}

export type FormResponseInput = {
  id: number;
  form_response_id: number;
  form_input_id: number;
  label: string;
  type: FormInputTypes;
  value: string;
};

export type FormResponse = {
  id: number;
  form_id: number;
  form: IForm;
  cart_id: number;
  order_id: number;
  customer_id: number;
  response_inputs: FormResponseInput[];
  order: IOrder;
  created_at: string;
};

type IGetFormResponse = {
  cart_id?: number;
  order_id?: number;
  form_id?: number;
  order?: boolean;
};

export async function GetFormResponse(
  params: IGetFormResponse,
  useGlobalLoader = true,
): Promise<IFormResponseRes<FormResponse>> {
  return await APIGet(baseUrl, params, useGlobalLoader);
}
