import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useTranslation, Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { StatusCode } from "api/protocols";
import {
  DeletePricingSheet,
  GetPricingSheet,
  PostDuplicatePricingSheet,
  PutPricingSheet,
} from "api/rpc/2022-09/facilityAdmin/teesheet/pricingSheet";
import {
  DeletePricingSheetProduct,
  PostPricingSheetProduct,
  CopyPricingSheetProducts,
} from "api/rpc/2022-09/facilityAdmin/teesheet/pricingSheetProduct";
import { GetVariant } from "api/rpc/facilityAdmin/product/products";

import { showError, showSuccess } from "redux/actions/ui";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { convertTime } from "helpers/Helpers";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Sheet from "components/sheet/Sheet";
import { Badge } from "components/badge/Badge";
import { ButtonNew as Button } from "components/buttonNew/index";
import Checkbox from "components/form/checkbox/Checkbox";
import Input from "components/form/input/Input";
import Search from "components/search/Search";
import { Select } from "components/select/index";
import FormLayout from "components/form/FormLayout";
import Popup from "components/popup/Popup";
import TimePick from "components/timePick/TimePick";
import { Day, DayTab, DayValue } from "./TeeSheetPricingSheets";

import "./teeSheetPricingSheet.scss";
import ProductSelect from "components/productModal/ProductSelect";
import { IProduct, IVariant } from "redux/reducers/models/product";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

interface IPricingSheetState {
  id?: number;
  title?: string;
  course_id?: number;
  prevTitle?: string;
  start_time: string;
  end_time: string;
  day_of_week: string;
  day_of_week_number: number;
  duplicateModalVisibility: boolean;
  deleteModalVisibility: boolean;
}

interface IPricingSheetList {
  pricingSheets: Array<IPricingSheetState>;
  selectedPricingSheetID: Array<number>;
}

interface IProductState {
  products: IProduct;
  variants: Array<Partial<IVariant>>;
  title: string;
  addProductVisibility: boolean;
  deleteProductVisibility: boolean;
  copyProductsVisibility: boolean;
  addedVariants: Array<Partial<IVariant>>;
  selectedVariants: Array<number>;
  selectedProducts: Array<IProduct>;
}

const TeeSheetPricingSheet: React.FC = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { pricingSheetId } = useParams<{ pricingSheetId: string }>();

  const facilityStore = useAppSelector(store => store.facilityStore);
  const dispatch = useAppDispatch();

  const { Option } = Select;

  const [pricingSheetListState, setPricingSheetListState] = useState<IPricingSheetList>({
    pricingSheets: [],
    selectedPricingSheetID: [],
  });

  const [pricingSheetState, setPricingSheetState] = useState<IPricingSheetState>({
    id: undefined,
    title: "",
    course_id: undefined,
    prevTitle: "",
    start_time: "",
    end_time: "",
    day_of_week: "",
    day_of_week_number: null,
    duplicateModalVisibility: false,
    deleteModalVisibility: false,
  });

  const [productState, setProductState] = useState<IProductState>({
    products: null,
    variants: null,
    title: "",
    addProductVisibility: false,
    deleteProductVisibility: false,
    copyProductsVisibility: false,
    addedVariants: [],
    selectedVariants: [],
    selectedProducts: [],
  });

  const [filterState, setFilterState] = useState("");

  const [filterDayState, setFilterDayState] = useState<DayValue>(Day.Monday);

  useEffect(() => {
    void loadPricingSheet();

    return () => {
      setPricingSheetState({
        id: undefined,
        title: "",
        course_id: undefined,
        prevTitle: "",
        start_time: "",
        end_time: "",
        day_of_week: "",
        day_of_week_number: null,
        duplicateModalVisibility: false,
        deleteModalVisibility: false,
      });
      setProductState({
        products: null,
        variants: null,
        title: "",
        addProductVisibility: false,
        deleteProductVisibility: false,
        copyProductsVisibility: false,
        addedVariants: [],
        selectedVariants: [],
        selectedProducts: [],
      });
    };
  }, []);

  useEffect(() => {
    if (filterState !== undefined) {
      void loadVariants();
    }
  }, [filterState]);

  async function loadPricingSheets() {
    const pricingSheetRes = await GetPricingSheet(null, false);

    setPricingSheetListState(prevState => ({
      ...prevState,
      pricingSheets: pricingSheetRes.data.map(sheet => ({
        id: sheet.id,
        title: sheet.title,
        course_id: sheet.course_id,
        prevTitle: sheet.title,
        start_time: sheet.start_time,
        end_time: sheet.end_time,
        day_of_week: sheet.day_of_week,
        day_of_week_number: sheet.day_of_week_number,
        duplicateModalVisibility: false,
        deleteModalVisibility: false,
      })),
    }));
  }

  async function handleDeletePricingSheet() {
    const deleteRes = await DeletePricingSheet(
      {
        id: pricingSheetState.id,
      },
      true,
    );

    if (deleteRes.status !== StatusCode.OK) {
      dispatch(showError("Error deleting pricing sheet")); // TODO: Translation
      return;
    }

    void handleShowDelete();
    dispatch(showSuccess("Pricing Sheets deleted successfully")); // TODO: Translation
    history.push("/admin/settings/tee-sheet/pricing-sheet");
  }

  async function copyProductsToPricingSheet() {
    const postRes = await CopyPricingSheetProducts(
      {
        pricing_sheet_ids: pricingSheetListState.selectedPricingSheetID,
        variant_ids: productState.selectedVariants,
      },
      true,
    );

    if (postRes.status !== StatusCode.OK) {
      dispatch(showError("Error copying products to pricing sheet")); // TODO: Translation
      return;
    }
    void handleCopyProductsVisibility();
    dispatch(showSuccess("Products successfully copied")); // TODO: Translation
  }

  async function loadPricingSheet() {
    const pricingSheetRes = await GetPricingSheet({ id: pricingSheetId, extended: true }, true);
    if (pricingSheetRes.status !== StatusCode.OK) {
      return;
    }

    setPricingSheetState(prevState => ({
      ...prevState,
      id: pricingSheetRes.data[0].id,
      title: pricingSheetRes.data[0].title,
      course_id: pricingSheetRes.data[0].course_id,
      start_time: pricingSheetRes.data[0].start_time,
      end_time: pricingSheetRes.data[0].end_time,
      day_of_week: pricingSheetRes.data[0].day_of_week,
      prevTitle: pricingSheetRes.data[0].title,
      // products
    }));

    setProductState(prevState => ({
      ...prevState,
      title: pricingSheetRes.data[0].title,
      addedVariants: pricingSheetRes.data[0].variants,
    }));
  }

  async function loadVariants() {
    if (filterState.length > 2) {
      const variantRes = await GetVariant(
        {
          types: ["Green Fee", "Power Cart"],
          extended: true,
          search: filterState,
        },
        true,
      );

      if (variantRes.status !== StatusCode.OK) {
        return;
      }

      setProductState(prevState => ({
        ...prevState,
        variants: variantRes.data,
      }));
    }
  }

  async function deleteProducts() {
    if (productState.selectedVariants.length <= 0) {
      dispatch(showError("A product to delete must be selected")); // TODO: Translation
      return;
    }

    const res = await DeletePricingSheetProduct(
      {
        pricing_sheet_id: Number(pricingSheetId),
        variant_id: productState.selectedVariants,
      },
      true,
    );

    if (res.status !== StatusCode.OK) {
      dispatch(showError(res.data.message));
      return;
    }

    setProductState(prevState => ({ ...prevState, selectedVariants: [] }));
    void loadPricingSheet();
    void handleDeleteProductsVisibility();
  }

  // Save new pricing sheet info (Not for saving products)
  async function savePricingSheet() {
    //Update pricing sheet info
    const editRes = await PutPricingSheet(
      {
        id: Number(pricingSheetId),
        title: pricingSheetState.title,
        start_time: pricingSheetState.start_time ?? null,
        end_time: pricingSheetState.end_time ?? null,
        day_of_week: pricingSheetState.day_of_week,
        course_id: pricingSheetState.course_id ? pricingSheetState.course_id : undefined,
      },
      true,
    );

    if (editRes.status !== StatusCode.OK) {
      dispatch(showError(editRes.data));
      return;
    }

    history.push("/admin/settings/tee-sheet/pricing-sheet");
  }

  async function postPricingSheetProduct() {
    const variantIds: Array<number> = [];
    productState?.selectedProducts?.forEach(product => {
      variantIds.push(...product?.variants?.map(variant => variant.id));
    });

    if (variantIds?.length <= 0) {
      dispatch(showError("A product must be selected")); // TODO: Translation
      return;
    }
    const postRes = await PostPricingSheetProduct(
      {
        pricing_sheet_id: parseInt(pricingSheetId),
        variant_ids: variantIds,
      },
      true,
    );

    if (postRes.status !== StatusCode.OK) {
      dispatch(showError(postRes.data.message));
    }

    setProductState(prevState => ({ ...prevState, selectedProducts: [] }));
    void loadPricingSheet();
    void handleAddProductsVisibility();
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;
    setPricingSheetState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleSelectChange(value: string, id: string) {
    setPricingSheetState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleToggleAllSelectedVariants() {
    if (productState?.selectedVariants?.length !== productState.addedVariants?.length) {
      setProductState(prev => ({
        ...prev,
        selectedVariants: productState.addedVariants.map(variant => variant.id),
      }));
    } else {
      setProductState(prev => ({ ...prev, selectedVariants: [] }));
    }
  }

  function handleSelectVariant(variant_id: number) {
    const foundId = productState.selectedVariants.filter(val => val === variant_id)[0];

    if (foundId) {
      setProductState(prev => ({
        ...prev,
        selectedVariants: prev.selectedVariants.filter(val => val !== variant_id),
      }));
    } else {
      setProductState(prev => ({ ...prev, selectedVariants: [...prev.selectedVariants, variant_id] }));
    }
  }

  function handleAddProductsVisibility() {
    setProductState(prevState => ({ ...prevState, addProductVisibility: !prevState.addProductVisibility }));
  }

  function handleSelectPricingSheetId(sheet_id: number) {
    const foundId = pricingSheetListState.selectedPricingSheetID.filter(val => val === sheet_id)[0];

    if (foundId) {
      setPricingSheetListState(prev => ({
        ...prev,
        selectedPricingSheetID: prev.selectedPricingSheetID.filter(val => val !== sheet_id),
      }));
    } else {
      setPricingSheetListState(prev => ({
        ...prev,
        selectedPricingSheetID: [...prev.selectedPricingSheetID, sheet_id],
      }));
    }
  }

  function handleCopyProductsVisibility() {
    if (!productState.copyProductsVisibility && pricingSheetListState.pricingSheets.length <= 0) {
      void loadPricingSheets();
    }
    setProductState(prevState => ({ ...prevState, copyProductsVisibility: !prevState.copyProductsVisibility }));
  }

  function handleShowDelete() {
    setPricingSheetState(prevState => ({ ...prevState, deleteModalVisibility: !prevState.deleteModalVisibility }));
  }

  function handleDeleteProductsVisibility() {
    if (productState.selectedVariants.length <= 0) {
      dispatch(showError("Please select a variant to remove.")); // TODO: Translation
      return;
    }
    setProductState(prevState => ({ ...prevState, deleteProductVisibility: !prevState.deleteProductVisibility }));
  }

  const primaryAction = {
    content: t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheet.001"),
    action: savePricingSheet,
  };

  const showDeleteAction = {
    content: "Delete Sheet", // TODO: Translation
    action: handleShowDelete,
  };

  return (
    <>
      <Page
        title={pricingSheetState.prevTitle}
        narrow
        primaryAction={primaryAction}
        secondaryActions={[showDeleteAction]}
        breadcrumbs={[
          {
            prefix: true,
            label: t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheet.004"),
            url: "/admin/settings/tee-sheet/pricing-sheet",
          },
        ]}
      >
        <Card>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={pricingSheetState.title}
                  label={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheet.005")}
                  id="title"
                  onChange={handleInputChange}
                  placeholder={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheet.006")}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                {facilityStore.facility?.courses?.length > 1 && (
                  <Select
                    onChange={(value: string) => handleSelectChange(value, "course_id")}
                    label={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheet.007")}
                    defaultValue={pricingSheetState.course_id}
                    allowClear
                  >
                    {facilityStore.facility?.courses?.map((course: Record<string, any>, index: number) => {
                      return (
                        <Option key={index} value={course.id}>
                          {course.long_name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </FormLayout.Group>
              <FormLayout.Group>
                <Select
                  onChange={(value: string) => handleSelectChange(value, "day_of_week")}
                  label={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheet.008")}
                  defaultValue={pricingSheetState.day_of_week}
                >
                  <Option value="Monday">{t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheet.009")}</Option>
                  <Option value="Tuesday">
                    {t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheet.010")}
                  </Option>
                  <Option value="Wednesday">
                    {t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheet.011")}
                  </Option>
                  <Option value="Thursday">
                    {t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheet.012")}
                  </Option>
                  <Option value="Friday">{t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheet.013")}</Option>
                  <Option value="Saturday">
                    {t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheet.014")}
                  </Option>
                  <Option value="Sunday">{t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheet.015")}</Option>
                  <Option value="Holiday">
                    {t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheet.016")}
                  </Option>
                </Select>
              </FormLayout.Group>
            </FormLayout>
            <FormLayout>
              <FormLayout.Group>
                <TimePick
                  value={pricingSheetState.start_time}
                  onChange={timeString => setPricingSheetState(prev => ({ ...prev, start_time: timeString }))}
                  label={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheet.017")}
                  size="large"
                  status={pricingSheetState.start_time === undefined ? "warning" : undefined}
                />
                <TimePick
                  value={pricingSheetState.end_time}
                  onChange={timeString => setPricingSheetState(prev => ({ ...prev, end_time: timeString }))}
                  label={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheet.019")}
                  size="large"
                  status={pricingSheetState.end_time === undefined ? "warning" : undefined}
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
          <Card.Section>
            <div className="header">
              <div className="title-header">Products</div>
              <div className="button-header">
                <Button className="ml-2" type="secondary" size="xsmall" onClick={handleAddProductsVisibility}>
                  <FontAwesomeIcon icon={["fas", "plus"]} className="mr-2" /> <span>Add</span>
                </Button>

                <Button className="ml-2" type="secondary" size="xsmall" onClick={handleDeleteProductsVisibility}>
                  <FontAwesomeIcon icon={["far", "trash-can"]} className="mr-2" /> <span>Remove</span>
                </Button>

                <Button className="ml-2" type="secondary" size="xsmall" onClick={handleCopyProductsVisibility}>
                  <FontAwesomeIcon icon={["fas", "copy"]} className="mr-2" /> <span>Copy</span>
                </Button>
              </div>
            </div>

            <DataTable
              columns={[
                {
                  label: "",
                  width: "7%",
                  content: (
                    <Checkbox
                      onChange={() => handleToggleAllSelectedVariants()}
                      size="small"
                      checked={productState?.selectedVariants?.length === productState.addedVariants?.length}
                    />
                  ),
                },
                { label: "Product", width: "31%" }, // TODO: Translation
                { label: "Variant", width: "31%" }, // TODO: Translation
                { label: "Type", width: "31%" }, // TODO: Translation
              ]}
            >
              {productState.addedVariants.map(product => (
                <tr key={product.id} className="clickable" onClick={() => handleSelectVariant(product.id)}>
                  <td>
                    <Checkbox
                      onChange={e => e.stopPropagation()}
                      size="small"
                      checked={productState?.selectedVariants?.includes(product.id)}
                    />
                  </td>
                  <td>{product?.product?.title}</td>
                  <td>{product.title}</td>
                  <td>{product?.product?.type}</td>
                </tr>
              ))}
            </DataTable>
          </Card.Section>
        </Card>
      </Page>

      {/* Add Products Sheet */}
      <div className="pricing-sheets-products-modal">
        <Sheet
          closable
          onCancel={handleAddProductsVisibility}
          size="medium"
          open={productState.addProductVisibility}
          cancelText={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheet.025")}
          okText={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheet.026")}
          title={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheet.027")}
          okDisabled={productState?.selectedProducts?.length === 0}
          onOk={postPricingSheetProduct}
        >
          <ProductSelect
            selectedProducts={productState.selectedProducts}
            limit={50}
            search
            onChange={products => setProductState(prevState => ({ ...prevState, selectedProducts: products }))}
            getProductsParams={{ types: ["Green Fee", "Power Cart", "No Show Green Fee"] }}
          />
        </Sheet>
      </div>

      {/* Copy Products Sheet */}
      <Sheet
        closable
        title={"Copy Products to Pricing Sheet"}
        size="medium"
        open={productState.copyProductsVisibility}
        cancelText={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheet.025")}
        onCancel={handleCopyProductsVisibility}
        okText={"Continue"}
        onOk={copyProductsToPricingSheet}
      >
        <Card>
          <div className="flex">
            <DayTab
              title={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.025")}
              value={Day.Monday}
              compare={filterDayState}
              onClick={value => setFilterDayState(value)}
            />
            <DayTab
              title={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.026")}
              value={Day.Tuesday}
              compare={filterDayState}
              onClick={value => setFilterDayState(value)}
            />
            <DayTab
              title={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.027")}
              value={Day.Wednesday}
              compare={filterDayState}
              onClick={value => setFilterDayState(value)}
            />
            <DayTab
              title={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.028")}
              value={Day.Thursday}
              compare={filterDayState}
              onClick={value => setFilterDayState(value)}
            />
            <DayTab
              title={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.029")}
              value={Day.Friday}
              compare={filterDayState}
              onClick={value => setFilterDayState(value)}
            />
            <DayTab
              title={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.030")}
              value={Day.Saturday}
              compare={filterDayState}
              onClick={value => setFilterDayState(value)}
            />
            <DayTab
              title={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.031")}
              value={Day.Sunday}
              compare={filterDayState}
              onClick={value => setFilterDayState(value)}
            />
            <DayTab
              title={t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.032")}
              value={Day.Holiday}
              compare={filterDayState}
              onClick={value => setFilterDayState(value)}
            />
          </div>
          <DataTable
            columns={[
              { label: "" },
              { label: t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.033") },
              { label: t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.034") },
              { label: t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.035") },
              { label: t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.036") },
              { label: t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.037") },
              { label: t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.038") },
            ]}
            loading={pricingSheetListState.pricingSheets === undefined}
          >
            {pricingSheetListState.pricingSheets
              ?.filter(val => val.day_of_week_number === filterDayState)
              ?.map((pricingSheet: any) => (
                <tr
                  key={pricingSheet.id}
                  className="clickable"
                  onClick={() => handleSelectPricingSheetId(pricingSheet.id)}
                >
                  <td>
                    <Checkbox
                      onChange={e => e.stopPropagation()}
                      size="medium"
                      checked={pricingSheetListState?.selectedPricingSheetID?.includes(pricingSheet.id)}
                    />
                  </td>
                  <td>{pricingSheet.title}</td>
                  <td>{pricingSheet.course?.full_name}</td>
                  <td>
                    {pricingSheet.application === "green_fee" ? (
                      <Badge size="medium" type="success">
                        {t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.039")}
                      </Badge>
                    ) : null}
                    {pricingSheet.application === "power_cart" ? (
                      <Badge size="medium" type="success">
                        {t("secure.facility.settings.tee_sheets.tee_sheet_pricing_sheets.040")}
                      </Badge>
                    ) : null}
                  </td>
                  <td>{convertTime(pricingSheet.start_time)}</td>
                  <td>{convertTime(pricingSheet.end_time)}</td>
                  <td>{pricingSheet.day_of_week}</td>
                </tr>
              ))}
          </DataTable>
        </Card>
      </Sheet>

      {/* Delete Pricing Sheet Warning */}
      <Popup
        open={pricingSheetState.deleteModalVisibility}
        type="warning"
        title="Delete Pricing Sheet"
        description={"Are you sure you want to delete the selected pricing sheet?"}
        onOk={() => handleDeletePricingSheet()}
        okText="Delete"
        onCancel={() => handleShowDelete()}
        backDropCancel={true}
      />

      {/* Delete Pricing Sheet Product */}
      <Popup
        open={productState.deleteProductVisibility}
        type="warning"
        title="Remove Products"
        description={"Are you sure you want to remove the pricing sheet products?"}
        onOk={() => deleteProducts()}
        okText="Delete"
        onCancel={() => handleDeleteProductsVisibility()}
        backDropCancel={true}
      />
    </>
  );
};

export default TeeSheetPricingSheet;
