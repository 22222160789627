import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { isEqualWith, isNumber } from "lodash";

import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Input from "components/form/input/Input";
import Card from "components/card/Card";
import Sheet from "components/sheet/Sheet";
import { Select } from "components/select/index";
import FormLayout from "components/form/FormLayout";
import Form from "components/form/Form";
import ColorPicker from "components/colorpicker/color-picker";
import { GetBookingCategory } from "api/rpc/2024-04/facilityAdmin/teesheet/teeSheet";
import { DeleteCustomerType, GetCustomerType, PutCustomerType } from "api/rpc/2024-04/facilityAdmin/customer/type";

interface ICustomerTypeState {
  id: number;
  title: string;
  subtitle: string;
  application: string;
  color: string;
  default_booking_category_id: number;
  migrate_customer_type_id: number;
}

export default function CustomerTypesEdit(props: any) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { Option } = Select;
  const { id } = useParams<{ id: string }>();
  const [customerTypeState, setCustomerTypeState] = useState<ICustomerTypeState>({
    id: undefined,
    title: "",
    subtitle: "",
    application: "",
    color: "",
    migrate_customer_type_id: undefined,
    default_booking_category_id: undefined,
  });
  const [prevCustomerTypeState, setPrevCustomerTypeState] = useState<ICustomerTypeState>(customerTypeState);
  const [dropDownState, setDropDownState] = useState({ customerType: [] });
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
  const [customerTypeStateBeforeChanges, setCustomerTypeStateBeforeChanges] = useState(undefined);
  const [customerTypeStateLoaded, setCustomerTypeStateLoaded] = useState<boolean>(false);

  const [bookingCategoryState, setBookingCategoryState] = useState({
    bookingCategories: [],
  });

  useEffect(() => {
    void loadCustomerTypes();
    void loadBookingCategories();

    return () => {
      setCustomerTypeState({
        id: undefined,
        title: "",
        subtitle: "",
        application: "",
        color: "",
        migrate_customer_type_id: undefined,
        default_booking_category_id: undefined,
      });
      setPrevCustomerTypeState({
        id: undefined,
        title: "",
        subtitle: "",
        application: "",
        color: "",
        migrate_customer_type_id: undefined,
        default_booking_category_id: undefined,
      });
      setDropDownState({ customerType: [] });
    };
  }, []);

  async function loadCustomerTypes() {
    const res = await GetCustomerType(null, true);

    if (res.status !== StatusCode.OK) {
      return;
    }

    setDropDownState(prev => ({
      ...prev,
      customerType: res.data,
    }));

    const currentType = res.data.find((type: any) => type.id === Number(id));
    setCustomerTypeState(prev => ({
      ...prev,
      id: currentType.id,
      title: currentType.title,
      subtitle: currentType.subtitle,
      application: currentType.application,
      color: currentType.color,
      default_booking_category_id: currentType.default_booking_category_id,
    }));

    setPrevCustomerTypeState(prev => ({
      ...prev,
      id: currentType.id,
      title: currentType.title,
      subtitle: currentType.subtitle,
      application: currentType.application,
      color: currentType.color,
      default_booking_category_id: currentType.default_booking_category_id,
    }));

    setCustomerTypeStateLoaded(true);
  }

  async function loadBookingCategories() {
    const res = await GetBookingCategory(null, false);
    if (res.status !== StatusCode.OK) {
      return;
    }

    setBookingCategoryState(prev => ({
      ...prev,
      bookingCategories: res.data,
    }));
  }

  async function saveCustomerType() {
    const res = await PutCustomerType(customerTypeState, true);
    if (res.status !== StatusCode.OK) {
      return;
    }

    history.push("/admin/settings/customer_types");
  }

  async function removeCustomerType() {
    if (prevCustomerTypeState.migrate_customer_type_id === undefined) {
      return;
    }

    const res = await DeleteCustomerType(prevCustomerTypeState, true);
    if (res.status !== StatusCode.OK) {
      return;
    }

    history.push("/admin/settings/customer_types");
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setCustomerTypeState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleDeleteModalVisibility() {
    setDeleteModalVisibility(!deleteModalVisibility);
  }

  function handleBookingCategoryChange(value: any) {
    setCustomerTypeState(prevState => ({
      ...prevState,
      default_booking_category_id: value,
    }));
  }

  function handleMigratedCustomerIdChange(id: any) {
    setPrevCustomerTypeState(prev => ({
      ...prev,
      migrate_customer_type_id: id,
    }));
  }

  function handleColorPick(value: string) {
    setCustomerTypeState(prevState => ({
      ...prevState,
      color: value,
    }));
  }

  const primaryAction = {
    content: t("secure.facility.settings.customer_type.customer_type_edit.001"),
    action: saveCustomerType,
  };

  const secondaryAction = {
    content: t("secure.facility.settings.customer_type.customer_type_edit.002"),
    action: handleDeleteModalVisibility,
  };

  function unsavedChangesExist() {
    if (customerTypeStateBeforeChanges === undefined) {
      if (customerTypeStateLoaded) {
        setCustomerTypeStateBeforeChanges(customerTypeState);
      }
      return false;
    }

    return !isEqualWith(customerTypeStateBeforeChanges, customerTypeState);
  }

  function cancelUnsavedChanges() {
    setCustomerTypeState(customerTypeStateBeforeChanges);
  }

  return (
    <div>
      <Page
        title={t("secure.facility.settings.customer_type.customer_type_edit.003") + prevCustomerTypeState.title}
        narrow
        // primaryAction={primaryAction}
        secondaryActions={[secondaryAction]}
        breadcrumbs={[
          {
            prefix: true,
            label: t("secure.facility.settings.customer_type.customer_type_edit.004"),
            url: "/admin/settings/customer_types",
          },
        ]}
        notificationBarProps={{
          isVisible: unsavedChangesExist(),
          onAction: saveCustomerType,
          onCancel: cancelUnsavedChanges,
        }}
      >
        <Card>
          <Card.Section>
            <Form>
              <FormLayout>
                <FormLayout.Group>
                  <Input
                    value={customerTypeState.title ?? ""}
                    label={t("secure.facility.settings.customer_type.customer_type_edit.005")}
                    id="title"
                    onChange={handleInputChange}
                    placeholder={t("secure.facility.settings.customer_type.customer_type_edit.006")}
                  />
                  <Input
                    value={customerTypeState.subtitle ?? ""}
                    label={t("secure.facility.settings.customer_type.customer_type_edit.007")}
                    id="subtitle"
                    onChange={handleInputChange}
                    placeholder={t("secure.facility.settings.customer_type.customer_type_edit.008")}
                  />
                </FormLayout.Group>
                {customerTypeState.application === "green_fee" && (
                  <FormLayout.Group>
                    <Select
                      label={t("secure.facility.settings.customer_type.customer_type_edit.014")}
                      onChange={handleBookingCategoryChange}
                      defaultValue={customerTypeState.default_booking_category_id}
                    >
                      {bookingCategoryState.bookingCategories.map((category: any, index: number) => {
                        return (
                          <Option key={index} value={category.id} name={category.title}>
                            <span>{category.title}</span>
                          </Option>
                        );
                      })}
                    </Select>
                  </FormLayout.Group>
                )}
              </FormLayout>
            </Form>
          </Card.Section>
        </Card>
        <Card title="Tee Sheet Color" subtitle="Select a color for this customer type to use on the tee sheet">
          <Card.Section>
            <ColorPicker colorValue={customerTypeState.color} onClick={handleColorPick} />
          </Card.Section>
        </Card>
      </Page>

      <Sheet
        closable
        onCancel={handleDeleteModalVisibility}
        size="small"
        open={deleteModalVisibility}
        cancelText={t("secure.facility.settings.customer_type.customer_type_edit.009")}
        okText={t("secure.facility.settings.customer_type.customer_type_edit.010")}
        title={t("secure.facility.settings.customer_type.customer_type_edit.011")}
        onOk={isNumber(prevCustomerTypeState.migrate_customer_type_id) ? removeCustomerType : undefined}
        overflow
      >
        <div className="text-left p-3">
          <Select
            label={t("secure.facility.settings.customer_type.customer_type_edit.012")}
            onChange={handleMigratedCustomerIdChange}
            placeholder={t("secure.facility.settings.customer_type.customer_type_edit.013")}
          >
            {dropDownState.customerType
              ?.filter((type: any) => type.id !== prevCustomerTypeState.id)
              .map((type: any, index: number) => {
                return (
                  <Option key={index} value={type.id} name={type.title}>
                    <span>{type.title}</span>
                  </Option>
                );
              })}
          </Select>
        </div>
      </Sheet>
    </div>
  );
}
