import { APIGet, APIPut } from "api/protocols";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseTeesheetUrl = "/" + apiVersion + "/" + adminType + "/facility/settings/tee-sheet";

interface IPutCartWaiver {
  cart_waiver_content: string;
}

export async function GetCartWaiver(useGlobalLoader?: boolean) {
  return await APIGet(baseTeesheetUrl, useGlobalLoader);
}

export async function PutCartWaiver(params: IPutCartWaiver, useGlobalLoader?: boolean) {
  return await APIPut(baseTeesheetUrl, params, useGlobalLoader);
}
