import { StatusCode } from "api/protocols";
import { PostCart, PutLineItemSplit } from "api/rpc/2024-04/facilityAdmin/order/cart/cart";
import classNames from "classnames";
import Checkbox from "components/form/checkbox/Checkbox";
import Sheet from "components/sheet/Sheet";
import Portal from "elements/Portal";
import { LocaleCurrency } from "helpers/Locale";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { showError, showSuccess } from "redux/actions/ui";
import { ITableServiceState } from "../../newTableService/NewTableService";
import "./splitLineItemsModal.scss";

interface IProps {
  open: boolean;
  onCancel: () => void;
  onRefresh: () => Promise<void>;
  setTableState: React.Dispatch<React.SetStateAction<ITableServiceState>>;
}

interface IState {
  lineItemIds: Array<number>;
  cartIds: Array<number>;
}

export default function SplitLineItemsModal(props: IProps) {
  const { open, onCancel, onRefresh, setTableState } = props;
  const { cartStore } = useAppSelector(store => store);
  const dispatch = useAppDispatch();
  const [state, setState] = useState<IState>({
    lineItemIds: [],
    cartIds: [],
  });

  function handleCancel() {
    setState(prevState => ({ ...prevState, lineItemIds: [], cartIds: [] }));
    void onCancel();
  }

  function handleSplitLineItemCheckboxChange(lineItemId: number) {
    let updatedLineitemIds = [...state?.lineItemIds];
    const foundIndex = updatedLineitemIds?.indexOf(lineItemId);
    if (foundIndex === -1) {
      updatedLineitemIds = [...updatedLineitemIds, lineItemId];
    } else {
      updatedLineitemIds = updatedLineitemIds?.filter(id => id !== lineItemId);
    }
    setState(prevState => ({ ...prevState, lineItemIds: updatedLineitemIds }));
  }

  function handleSplitCartCheckboxChange(cartId: number) {
    let updatedCartIds = [...state?.cartIds];
    const foundIndex = updatedCartIds?.indexOf(cartId);
    if (foundIndex === -1) {
      updatedCartIds = [...updatedCartIds, cartId];
    } else {
      updatedCartIds = updatedCartIds.filter(id => id !== cartId);
    }
    setState(prevState => ({ ...prevState, cartIds: updatedCartIds }));
  }

  async function handleLineItemSplit() {
    let cartIds = [...state?.cartIds];
    const lineItemIds = [...state?.lineItemIds];
    //Add current cart's id to the front of the array
    cartIds?.unshift(cartStore?.cart?.id);
    setTableState(prevState => ({ ...prevState, cartLoadingMessage: "Splitting line items..." }));
    void onCancel();
    // Table only has 1 cart, create a new cart to split items to
    if (cartIds.includes(0)) {
      const postRes = await PostCart({ type: "tab", table_id: cartStore?.activeTable?.id }, false);
      if (postRes.status !== StatusCode.OK) {
        dispatch(showError("Error occurred when creating a new seat"));
        setTableState(prevState => ({ ...prevState, cartLoadingMessage: "" }));
        return;
      }
      //Remove the temp id '0'
      cartIds = cartIds?.filter(id => id !== 0);

      //Add the id of the newly created cart
      cartIds = [...cartIds, postRes?.data?.id];
    }

    //Split line items
    const splitRes = await PutLineItemSplit(
      {
        cart_ids: cartIds,
        line_item_ids: lineItemIds,
      },
      false,
    );

    if (splitRes.status !== StatusCode.OK) {
      dispatch(showError("Error splitting items"));
      setTableState(prevState => ({ ...prevState, cartLoadingMessage: "" }));
      return;
    }

    dispatch(showSuccess("Successfully split items"));
    await onRefresh();
    ReactDOM.unstable_batchedUpdates(() => {
      setState(prevState => ({ ...prevState, lineItemIds: [], cartIds: [] }));
      setTableState(prevState => ({ ...prevState, cartLoadingMessage: "" }));
    });
  }

  return (
    <Portal isMounted={open}>
      <Sheet
        title="Split Line Items"
        open={open}
        size="medium"
        closable
        onCancel={handleCancel}
        onOk={handleLineItemSplit}
        okText="OK"
        okDisabled={state?.cartIds?.length < 1 || state?.lineItemIds?.length < 1}
      >
        <div className="split-line-item-modal split-line-item-modal-container">
          <div className="split-container">
            <p className="split-container-header split-container-margin-bottom">Select Items to Split</p>
            <div className="ui-checkbox-group">
              {cartStore?.cart?.line_items?.map((lineItem, index) => {
                //Don't display modifiers
                if (!lineItem?.parent_id) {
                  return (
                    <div
                      key={index}
                      style={{ gap: "1rem" }}
                      onClick={
                        lineItem?.quantity < 1 ? undefined : () => handleSplitLineItemCheckboxChange(lineItem?.id)
                      }
                      className={classNames("ui-checkbox-group-item", {
                        "ui-checkbox-group-item_selected": state?.lineItemIds.includes(lineItem?.id),
                        "split-container-disabled": lineItem?.quantity < 1,
                      })}
                    >
                      <div className="ui-checkbox-group-item-content split-container-checkbox-group">
                        <p className="split-container-header">{lineItem?.quantity}</p>
                        {lineItem.product_title === lineItem.variant_title ? (
                          <span className="split-container-subtitle">
                            {lineItem?.product?.preferred_title
                              ? lineItem?.product?.preferred_title
                              : lineItem?.product_title}
                          </span>
                        ) : (
                          <span className="split-container-subtitle">
                            {lineItem?.product?.preferred_title
                              ? lineItem?.product?.preferred_title
                              : lineItem?.product_title}
                            <span className="split-container-subtitle"> - {lineItem.variant_title}</span>
                          </span>
                        )}
                      </div>
                      <span style={{ marginLeft: "1rem" }}>
                        <LocaleCurrency
                          style={{ color: "#475467", fontSize: "14px" }}
                          currency={cartStore?.cart?.currency}
                          amount={lineItem?.subtotal_price}
                        />
                      </span>
                      <div className="pointer-events-none">
                        <Checkbox
                          size="medium"
                          value={lineItem?.id}
                          checked={state?.lineItemIds.includes(lineItem?.id)}
                          onChange={() => handleSplitLineItemCheckboxChange(lineItem?.id)}
                          disabled={lineItem?.quantity < 1}
                        />
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <div className="split-container">
            <p className="split-container-header split-container-margin-bottom">Select Seats to Split with</p>
            <div className="ui-checkbox-group">
              {/* If table only has 1 seat, show a temporary seat that will be created when items are split */}
              {cartStore?.activeTable?.carts?.length === 1 && (
                <div
                  style={{ gap: "1rem" }}
                  onClick={() => handleSplitCartCheckboxChange(0)}
                  className={classNames("ui-checkbox-group-item", {
                    "ui-checkbox-group-item_selected": state?.cartIds.includes(0),
                  })}
                >
                  <div className="ui-checkbox-group-item-content split-container-checkbox-group">
                    <div className="ui-checkbox-group-item-text">
                      <p className="split-container-header">New Seat</p>
                    </div>
                    <div>
                      <LocaleCurrency
                        style={{ color: "#475467", fontSize: "14px" }}
                        currency={cartStore?.cart?.currency}
                        amount={0}
                      />
                    </div>
                  </div>
                  <div className="pointer-events-none">
                    <Checkbox
                      size="medium"
                      value={0}
                      checked={state?.cartIds.includes(0)}
                      onChange={() => handleSplitCartCheckboxChange(0)}
                    />
                  </div>
                </div>
              )}
              {cartStore?.activeTable?.carts?.map((cart, index) => {
                if (
                  cart?.id !== cartStore?.cart?.id &&
                  cart.status !== "complete" &&
                  cart?.status !== "void" &&
                  cart?.order?.financial_status !== "partially_paid"
                ) {
                  return (
                    <div
                      key={index}
                      style={{ gap: "1rem" }}
                      onClick={() => handleSplitCartCheckboxChange(cart?.id)}
                      className={classNames("ui-checkbox-group-item", {
                        "ui-checkbox-group-item_selected": state?.cartIds.includes(cart?.id),
                      })}
                    >
                      <div className="ui-checkbox-group-item-content split-container-checkbox-group">
                        <div className="ui-checkbox-group-item-text">
                          <p className="split-container-header">Seat #{index + 1}</p>
                        </div>
                        <div>
                          <LocaleCurrency
                            style={{ color: "#475467", fontSize: "14px" }}
                            currency={cartStore?.cart?.currency}
                            amount={cart?.total_price}
                          />
                        </div>
                      </div>
                      <div className="pointer-events-none">
                        <Checkbox
                          size="medium"
                          value={cart?.id}
                          checked={state?.cartIds.includes(cart?.id)}
                          onChange={() => handleSplitCartCheckboxChange(cart?.id)}
                        />
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </Sheet>
    </Portal>
  );
}
