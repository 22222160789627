import React, { useEffect, useState } from "react";
import { Redirect, Route, useHistory, useLocation, RouteProps } from "react-router-dom";

import Frame from "components/frame/Frame";
import Menu from "components/frame/Menu";
import TopBar from "components/frame/TopBar";

import { bindActionCreators } from "redux";
import * as AuthActions from "redux/actions/auth";
import * as UIActions from "redux/actions/ui";
import * as LeagueActions from "redux/actions/league";
import * as TournamentActions from "redux/actions/tournament";
import * as OrderActions from "redux/actions/order";
import * as CartActions from "redux/actions/cart";
import * as ReportActions from "pages/secure/facility/reports/Reports";
import { IAuthState } from "redux/reducers/auth";

import { connect } from "react-redux";
import { UserType } from "redux/reducers/models/user";

type IProtectedRoute = {
  path: string;
  topBarTitle?: string;
  permission: UserType;
  hasSubMenu?: boolean;
  component: any;
  authStore: IAuthState;
};

const ProtectedRoute: React.FC<IProtectedRoute> = props => {
  const { authStore, component: Component, permission, ...routeProps } = props;

  if (authStore.initialized === false) {
    return null;
  }

  const permissionGranted = authStore.user.user_type === permission;

  if (authStore.user.id !== undefined && permissionGranted) {
    return (
      <Route {...routeProps}>
        <Component {...routeProps} />
      </Route>
    );
  } else {
    return <Redirect to={{ pathname: "/login" }} />;
  }
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    authActions: bindActionCreators(
      {
        ...AuthActions,
      },
      dispatch,
    ),
    uiActions: bindActionCreators(
      {
        ...UIActions,
      },
      dispatch,
    ),
    cartActions: bindActionCreators(
      {
        ...CartActions,
      },
      dispatch,
    ),
    leagueActions: bindActionCreators(
      {
        ...LeagueActions,
      },
      dispatch,
    ),
    tournamentActions: bindActionCreators(
      {
        ...TournamentActions,
      },
      dispatch,
    ),
    orderActions: bindActionCreators(
      {
        ...OrderActions,
      },
      dispatch,
    ),
    reportActions: bindActionCreators(
      {
        ...ReportActions,
      },
      dispatch,
    ),
  };
};

const mapStateToProps = (state: any) => {
  return {
    leagueStore: { ...state.leagueStore },
    authStore: { ...state.authStore },
    tournamentStore: { ...state.tournamentStore },
    orderStore: { ...state.orderStore },
    customerStore: { ...state.customerStore },
    cartStore: { ...state.cartStore },
    reportStore: { ...state.reportStore },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
