import React, { useEffect, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";
import moment from "moment";

import { StatusCode } from "api/protocols";
import {
  CustomerMembership,
  GetMemberships,
  IGetCustomerMemberships,
} from "api/rpc/2024-04/clientAdmin/customer/membership";

import { showError } from "redux/actions/ui";
import { valueToString } from "helpers/Helpers";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import Page from "components/page/Page";
import Search from "components/search/Search";

import { Badge } from "components/badge/Badge";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";

/** TODO: Translation labels */
const tableColumns = (t: TFunction<"translation", undefined>) => {
  return [
    { label: "Customer", width: "auto" }, // full_name + email
    { label: "Home Facility", width: "auto" },
    { label: "Membership", width: "auto" },
    { label: "Member Number", width: "auto" },
    { label: "Season", width: "auto" },
    { label: "Status", width: "auto" },
    { label: "Expiry", width: "auto" },
  ];
};

const TABLE_LIMIT = 50;
export default function MembershipsSold() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { facilityStore } = useAppSelector(store => store);

  const [membershipList, setMembershipList] = useState<CustomerMembership[]>(undefined);
  const [filters, setFilters] = useState<Partial<IGetCustomerMemberships>>({
    search: "",
    offset: 0,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadMembershipList(source.token);

    return () => {
      source.cancel();
    };
  }, [filters]);

  async function loadMembershipList(token?: CancelToken) {
    if (membershipList !== undefined) {
      setMembershipList(undefined);
    }
    const res = await GetMemberships({ ...filters, limit: TABLE_LIMIT }, false, token);

    if (token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading customer memberships.")); // TODO: Translation
    }

    setMembershipList(res.status !== StatusCode.OK ? [] : res.data);
  }

  return (
    <Page
      title={"Sold Memberships"} // TODO: Translation
    >
      <Search
        historyKey={"facility-customer-sold-memberships"}
        searchCallback={(searchValue: string) => setFilters(prev => ({ ...prev, search: searchValue }))}
        placeholder="Search by customer or membership number..." // TODO: Translation
      />
      <div style={{ paddingTop: "8px" }}>
        <DataTable
          columns={tableColumns(t)}
          footer={{
            tableLimit: TABLE_LIMIT,
            tableOffset: filters.offset,
            disableNextOffset: !membershipList || !(membershipList?.length === TABLE_LIMIT),
            handleTableOffset: direction =>
              setFilters(prev => ({
                ...prev,
                offset: direction === "prev" ? prev.offset - TABLE_LIMIT : prev.offset + TABLE_LIMIT,
              })),
          }}
          loading={membershipList === undefined}
        >
          {membershipList?.map(membership => (
            <tr key={membership.id}>
              <td>
                <div>{membership.customer?.full_name}</div>
                <div>{membership.customer?.email}</div>
              </td>
              <td>
                {facilityStore.facilities.find(obj => obj.id === membership.home_facility_id) ? (
                  <>{facilityStore.facilities.find(obj => obj.id === membership.home_facility_id).long_name}</>
                ) : (
                  <p className="field-unavailable">N/A</p>
                )}
              </td>
              <td>
                <div>{membership.membership_title}</div>
                <div>{membership.membership_subtitle}</div>
              </td>

              <td>{membership.customer?.member_code ? membership.customer.member_code : ""}</td>
              <td>{membership.season && <Badge type="primary">{membership.season}</Badge>}</td>
              <td>
                <Badge type={generateBadgeType(membership.status)}>{valueToString(membership.status)}</Badge>
              </td>
              <td>
                {membership.expiry ? (
                  moment(membership.expiry).format("MMMM Do, YYYY")
                ) : (
                  <p className="field-unavailable">N/A</p>
                )}
              </td>
            </tr>
          ))}
        </DataTable>
      </div>
    </Page>
  );
}

/** Return badge type from membership.status
 *   -  Easily adjust badge styling in 1 place going forward
 */
function generateBadgeType(status: "active" | "expired" | "disabled") {
  switch (status) {
    case "active":
      return "success";
    case "expired":
      return "warning";
    case "disabled":
      return "error";
    default:
      return "primary";
  }
}
