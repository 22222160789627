import { APIGet, APIPost, APIPut, APIDelete, IAPIResponse, StatusCode } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "client-admin";
const baseBookingUrl = "/" + apiVersion + "/" + adminType + "/tee-sheet/tee-time/booking";

interface IGetBooking {
  id?: number;
  tee_time_id?: number;
  customer_id?: number;
  status?: boolean;
  extended?: boolean;
  extended_tee_time?: boolean;
}

// GET Tee Time Booking
// Retrieve an array of tee time bookings
export async function GetBooking(params: IGetBooking, useGlobalLoader: boolean) {
  return await APIGet(baseBookingUrl, params, useGlobalLoader);
}
// POST Booking
// Create a new booking for a tee time
export async function PostBooking(params: IPostBooking, useGlobalLoader: boolean) {
  return await APIPost(baseBookingUrl, params, useGlobalLoader);
}
export interface IPostBooking {
  tee_time_id?: number;
  send_confirmation?: boolean;
  quantity?: number;
  holes?: number;
  power_cart_quantity?: number;
  customer_id?: number;
  customer_payment_method_id?: number;
  tee_time_slots?: {
    id: number;
    customer_id: number;
    guest: boolean;
  }[];
  booking_group_id?: string;
}

// PUT Booking
// Update an existing tee time booking
export async function PutBooking(params: IPutBooking, useGlobalLoader: boolean): Promise<IPutBookingRes> {
  return await APIPut(baseBookingUrl, params, useGlobalLoader);
}
export interface IPutBooking {
  id: number;
  quantity?: number;
  order_id?: number;
  customer_id?: number;
  holes?: number;
  power_cart_quantity?: number;
  customer_payment_method_id?: number;
  tee_time_slots?: { id: number; customer_id: number; guest: boolean }[];
  extended?: boolean;
}

interface IPutBookingRes {
  status: StatusCode;
  data: {
    message?: string;
    data: {
      order_id: number;
    };
  };
  message?: string;
}

interface IBookingCounts {
  checked: number;

  no_show: number;
  no_show_details: {
    charge: number;
    defer: number;
    waived: number;
  };
  unchecked: number;
}

interface IBookingCountsRes {
  status: StatusCode;
  data: IBookingCounts;
}

export async function GetTotalBookings(
  params: { customer_id: number },
  useGlobalLoader: boolean,
  cancelToken?: CancelToken,
): Promise<IBookingCountsRes> {
  return await APIGet(baseBookingUrl + "/participant/count", params, useGlobalLoader, cancelToken);
}

// Move Booking
// Move a booking
export async function PutMoveBooking(params: IPutMoveBooking, useGlobalLoader: boolean): Promise<IPutMoveBookingRes> {
  return await APIPut(baseBookingUrl + "/move", params, useGlobalLoader);
}
export interface IPutMoveBooking {
  tee_time_booking_id: number;
  tee_time_id: number;
}
interface IPutMoveBookingRes {
  status: StatusCode;
}

export async function GetAuditTrails(
  params: { tee_time_booking_id: number },
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseBookingUrl + "/audit", params, useGlobalLoader, token);
}

export async function PutCancelBooking(params: { tee_time_booking_id: number }, useGlobalLoader: boolean) {
  return await APIPut(baseBookingUrl + "/cancel", params, useGlobalLoader);
}

export async function PutCopyBooking(
  params: { tee_time_booking_id: number; tee_time_ids: Array<string> },
  useGlobalLoader: boolean,
) {
  return await APIPut(baseBookingUrl + "/copy", params, useGlobalLoader);
}

interface IPutBookingConfirmation {
  booking_group_id: number;
}

export async function PutBookingConfirmation(params: IPutBookingConfirmation, useGlobalLoader?: boolean) {
  return await APIPut(baseBookingUrl + "/confirmation", params, useGlobalLoader);
}

interface IPutCancelParticipant {
  id: number;
  facility_id: number;
}

export async function PutCancelParticipant(params: IPutCancelParticipant, useGlobalLoader: boolean) {
  return await APIPut(baseBookingUrl + "/participant/cancel", params, useGlobalLoader);
}

interface IGetBookingParticipant {
  customer_id: number;
  extended_booking: boolean;
  offset?: number;
  limit?: number;
  year?: string;
}

export async function GetBookingParticipant(
  params: IGetBookingParticipant,
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet(baseBookingUrl + "/participant", params, useGlobalLoader, token);
}

interface IPutBookingParticipantNoShow {
  id: number;
  no_show_type: string;
  variant_id?: number;
}

export async function PutBookingParticipantNoShow(params: IPutBookingParticipantNoShow, useGlobalLoader: boolean) {
  return await APIPut(baseBookingUrl + "/participant/no-show", params, useGlobalLoader);
}

// Get/Add/Remove/Update Note to Booking
export async function GetBookingNotes(params: { tee_time_booking_id: number }, useGlobalLoader: boolean) {
  return await APIGet(baseBookingUrl + "/note", params, useGlobalLoader);
}

export async function PostBookingNote(
  params: { tee_time_booking_id: number; tee_time_booking_participant_id?: number; content: string },
  useGlobalLoader: boolean,
) {
  return await APIPost(baseBookingUrl + "/note", params, useGlobalLoader);
}

export async function PutBookingNote(params: { booking_note_id: number; content: string }, useGlobalLoader: boolean) {
  return await APIPut(baseBookingUrl + "/note", params, useGlobalLoader);
}

export async function DeleteBookingNote(params: { booking_note_id: number }, useGlobalLoader: boolean) {
  return await APIDelete(baseBookingUrl + "/note", params, useGlobalLoader);
}
