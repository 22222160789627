import React, { useEffect, useState, useCallback } from "react";
import { useHistory, Link } from "react-router-dom";
import { useParams } from "react-router";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import FormLayout from "components/form/FormLayout";
import Form from "components/form/Form";
import Input from "components/form/input/Input";
import { Select } from "components/select/index";

import { useTranslation, Trans } from "react-i18next";
import { GetRegisterGroups, PutRegisterGroup } from "api/rpc/2022-09/facilityAdmin/facility/register/group";
import { useAppDispatch } from "hooks/redux";
import { showError, showSuccess } from "redux/actions/ui";
import { isEqualWith, isNull } from "lodash";

export default function Group(props: any) {
  const { uiActions } = props;
  const { Option } = Select;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { registerGroupId }: any = useParams();
  const [registerGroupState, setRegisterGroupState] = useState({
    id: null,
    title: "",
    tipping_type: "none",
    tip_percentage_low: null,
    tip_percentage_mid: null,
    tip_percentage_high: null,
    tip_fixed_amount_low: null,
    tip_fixed_amount_mid: null,
    tip_fixed_amount_high: null,
  });

  const [registerGroupStateBeforeChanges, setRegisterGroupStateBeforeChanges] = useState(undefined);
  const [registerGroupLoaded, setRegisterGroupLoaded] = useState<boolean>(false);

  const tippingTypeOptions = [
    {
      label: t("secure.facility.settings.register.group.001"),
      value: "none",
    },
    {
      label: t("secure.facility.settings.register.group.002"),
      value: "percent",
    },
    {
      label: t("secure.facility.settings.register.group.003"),
      value: "fixed_amount",
    },
  ];

  useEffect(() => {
    void loadRegisterGroup();
  }, []);

  async function loadRegisterGroup() {
    const res = await GetRegisterGroups({ id: registerGroupId }, true);
    if (res.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.register.group.015")));
      history.push("/admin/settings/register-group");
      return;
    }

    console.log(res);

    setRegisterGroupState(prevState => ({
      ...prevState,
      id: res.data[0].id,
      title: res.data[0].title,
      tipping_type: res.data[0].tipping_type,
      tip_percentage_low: res.data[0].tip_percentage_low,
      tip_percentage_mid: res.data[0].tip_percentage_mid,
      tip_percentage_high: res.data[0].tip_percentage_high,
      tip_fixed_amount_low: res.data[0].tip_fixed_amount_low,
      tip_fixed_amount_mid: res.data[0].tip_fixed_amount_mid,
      tip_fixed_amount_high: res.data[0].tip_fixed_amount_high,
    }));

    setRegisterGroupLoaded(true);
  }

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setRegisterGroupState(prevState => ({ ...prevState, [id]: value }));
  }

  function handleDropDownChange(value: any, property: string) {
    setRegisterGroupState(prevState => ({ ...prevState, [property]: value }));
  }

  function navigateTo(url: string) {
    history.push(url);
  }

  async function saveRegisterGroup() {
    console.log(registerGroupState);
    const res = await PutRegisterGroup(
      {
        id: registerGroupState.id,
        title: registerGroupState.title,
        tipping_type: registerGroupState.tipping_type,
        tip_percentage_low: registerGroupState.tip_percentage_low,
        tip_percentage_mid: registerGroupState.tip_percentage_mid,
        tip_percentage_high: registerGroupState.tip_percentage_high,
        tip_fixed_amount_low: registerGroupState.tip_fixed_amount_low,
        tip_fixed_amount_mid: registerGroupState.tip_fixed_amount_mid,
        tip_fixed_amount_high: registerGroupState.tip_fixed_amount_high,
      },
      true,
    );

    if (res.status === StatusCode.OK) {
      const selectedRegisterGroup = JSON.parse(localStorage.getItem("register_group"));
      if (selectedRegisterGroup && selectedRegisterGroup?.id === registerGroupState?.id) {
        localStorage.setItem("register_group", JSON.stringify(res.data));
      }
      dispatch(showSuccess(t("secure.facility.settings.register.group.016")));
      history.push("/admin/settings/register-group");
    } else {
      dispatch(showError(t("secure.facility.settings.register.group.017")));
    }

    return;
  }

  const primaryAction = {
    content: t("secure.facility.settings.register.group.004"),
    action: saveRegisterGroup,
  };

  function unsavedChangesExist() {
    if (registerGroupStateBeforeChanges === undefined) {
      if (registerGroupLoaded) {
        setRegisterGroupStateBeforeChanges(registerGroupState);
      }
      return false;
    }

    return !isEqualWith(registerGroupStateBeforeChanges, registerGroupState, (originalValue, newValue) => {
      if ((isNull(originalValue) || originalValue === "") && (isNull(newValue) || newValue === "")) {
        return true;
      }
    });
  }

  function cancelUnsavedChanges() {
    setRegisterGroupState(registerGroupStateBeforeChanges);
  }

  return (
    <Page
      title={registerGroupState.title}
      narrow
      // primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.register.group.018"),
          url: `/admin/settings/register-group`,
        },
      ]}
      notificationBarProps={{
        isVisible: unsavedChangesExist(),
        onAction: saveRegisterGroup,
        onCancel: cancelUnsavedChanges,
      }}
    >
      <Card>
        <Card.Section>
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  value={registerGroupState.title ? registerGroupState.title : ""}
                  label={t("secure.facility.settings.register.group.005")}
                  id="title"
                  onChange={handleInputChange}
                  placeholder={t("secure.facility.settings.register.group.006")}
                />
                <Select
                  label={t("secure.facility.settings.register.group.007")}
                  defaultValue={registerGroupState.tipping_type}
                  className={`flex justify-center align-center w-full h-10 position-relative z-20 text-black font-medium appearance-none border-none focus:outline-none placeholder-gray-200`}
                  onChange={(value: any) => handleDropDownChange(value, "tipping_type")}
                >
                  {tippingTypeOptions.map((option: any) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card.Section>
      </Card>

      {registerGroupState.tipping_type !== "none" ? (
        <Card title={t("secure.facility.settings.register.group.008")}>
          <Card.Section>
            <>
              {registerGroupState.tipping_type === "percent" ? (
                <Form>
                  <FormLayout>
                    <FormLayout.Group>
                      <Input
                        value={registerGroupState.tip_percentage_low ?? ""}
                        label={t("secure.facility.settings.register.group.009")}
                        id="tip_percentage_low"
                        onChange={handleInputChange}
                        placeholder=""
                        suffix="%"
                      />
                      <Input
                        value={registerGroupState.tip_percentage_mid ?? ""}
                        label={t("secure.facility.settings.register.group.010")}
                        id="tip_percentage_mid"
                        onChange={handleInputChange}
                        placeholder=""
                        suffix="%"
                      />
                      <Input
                        value={registerGroupState.tip_percentage_high ?? ""}
                        label={t("secure.facility.settings.register.group.011")}
                        id="tip_percentage_high"
                        onChange={handleInputChange}
                        placeholder=""
                        suffix="%"
                      />
                    </FormLayout.Group>
                  </FormLayout>
                </Form>
              ) : null}
              {registerGroupState.tipping_type === "fixed_amount" ? (
                <Form>
                  <FormLayout>
                    <FormLayout.Group>
                      <Input
                        value={registerGroupState.tip_fixed_amount_low ?? ""}
                        label={t("secure.facility.settings.register.group.012")}
                        id="tip_fixed_amount_low"
                        onChange={handleInputChange}
                        placeholder=""
                        prefix="$"
                      />
                      <Input
                        value={registerGroupState.tip_fixed_amount_mid ?? ""}
                        label={t("secure.facility.settings.register.group.013")}
                        id="tip_fixed_amount_mid"
                        onChange={handleInputChange}
                        placeholder=""
                        prefix="$"
                      />
                      <Input
                        value={registerGroupState.tip_fixed_amount_high ?? ""}
                        label={t("secure.facility.settings.register.group.014")}
                        id="tip_fixed_amount_high"
                        onChange={handleInputChange}
                        placeholder=""
                        prefix="$"
                      />
                    </FormLayout.Group>
                  </FormLayout>
                </Form>
              ) : null}
            </>
          </Card.Section>
        </Card>
      ) : null}
    </Page>
  );
}
