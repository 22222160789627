import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Page from "components/page/Page";
import Card from "components/card/Card";
import Input from "components/form/input/Input";
import { StatusCode } from "api/protocols";
import { PostModifierGroup } from "api/rpc/facilityAdmin/modifierGroups";

import { useTranslation, Trans } from "react-i18next";

interface IModifierState {
  title: string;
}

export default function ModifierGroupNew(props: any) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [modifierGroupState, setModifierGroupState] = useState<IModifierState>({
    title: "",
  });

  function handleInputChange(event: any) {
    const { id, value } = event.target;
    setModifierGroupState(prevState => ({ ...prevState, [id]: value }));
  }

  async function saveNewModifierGroup() {
    const res = await PostModifierGroup(modifierGroupState, true);
    if (res.status !== StatusCode.OK) {
      return;
    }

    history.push("/admin/settings/modifier_group");
  }

  const primaryAction = {
    content: t("secure.facility.settings.modifier_group.modifier_group_new.001"),
    action: saveNewModifierGroup,
  };

  return (
    <Page
      title={t("secure.facility.settings.modifier_group.modifier_group_new.002")}
      narrow
      primaryAction={primaryAction}
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.modifier_group.modifier_group_new.003"),
          url: "/admin/settings/modifier_group",
        },
      ]}
    >
      <Card>
        <Card.Section>
          <Input
            value={modifierGroupState.title}
            label={t("secure.facility.settings.modifier_group.modifier_group_new.004")}
            id="title"
            onChange={handleInputChange}
            placeholder={t("secure.facility.settings.modifier_group.modifier_group_new.005")}
          />
        </Card.Section>
      </Card>
    </Page>
  );
}
