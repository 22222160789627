/* eslint-disable @typescript-eslint/no-unsafe-return */
import { faShopify } from "@fortawesome/free-brands-svg-icons";
import { StatusCode } from "api/protocols";
import { PostTransaction, PutCapture } from "api/rpc/2022-09/facilityAdmin/order/transaction";
import { PostAccountTransaction, PutAccountTransactionCapture } from "api/rpc/facilityAdmin/customer";
import { PostInvoiceTransaction, PutCaptureInvoiceTransaction } from "api/rpc/2024-04/facilityAdmin/order/invoice";
import { PostConnection } from "api/rpc/facilityAdmin/payment/teeOnPayments/reader";

import { store } from "index";
import { IOrder } from "redux/reducers/models/order";
import { IPaymentMethod, IProcessingType } from "redux/reducers/models/transaction";
import { delay } from "./Helpers";
import { TFunction, useTranslation } from "react-i18next";

// import { LocalStorage } from "api/localstorage";

export const MAX_RECONNECT_ATTEMPTS = 3;

export type reader = {
  id: string;
  device_type: string;
  label: string;
  location: string | null;
  metadata: Record<string, unknown>;
  serial_number: string;
  status: string;
};

type stripeError = {
  code: stripeErrorCode;
  message: string;
  decline_code: stripeDeclineCode;
};

type stripeErrorCode =
  | "no_established_connection"
  | "no_active_collect_payment_method_attempt"
  | "no_active_read_reusable_card_attempt"
  | "canceled"
  | "cancelable_already_completed"
  | "cancelable_already_canceled"
  | "network_error"
  | "network_timeout"
  | "already_connected"
  | "failed_fetch_connection_token"
  | "discovery_too_many_readers"
  | "invalid_reader_version"
  | "reader_error"
  | "command_already_in_progress"
  | "card_declined";

type stripeDeclineCode =
  | "authentication_required"
  | "approve_with_id"
  | "call_issuer"
  | "card_not_supported"
  | "card_velocity_exceeded"
  | "currency_not_supported"
  | "do_not_honor"
  | "do_not_try_again"
  | "duplicate_transaction"
  | "expired_card"
  | "fraudulent"
  | "generic_decline"
  | "incorrect_number"
  | "incorrect_cvc"
  | "incorrect_pin"
  | "incorrect_zip"
  | "insufficient_funds"
  | "invalid_account"
  | "invalid_amount"
  | "invalid_cvc"
  | "invalid_expiry_month"
  | "invalid_expiry_year"
  | "invalid_number"
  | "invalid_pin"
  | "issuer_not_available"
  | "lost_card"
  | "merchant_blacklist"
  | "new_account_information_available"
  | "no_action_taken"
  | "not_permitted"
  | "offline_pin_required"
  | "online_or_offline_pin_required"
  | "pickup_card"
  | "pin_try_exceeded"
  | "processing_error"
  | "reenter_transaction"
  | "restricted_card"
  | "revocation_of_all_authorizations"
  | "revocation_of_authorization"
  | "security_violation"
  | "service_not_allowed"
  | "stolen_card"
  | "stop_payment_order"
  | "testmode_decline"
  | "transaction_not_allowed"
  | "try_again_later"
  | "withdrawal_count_limit_exceeded";

type discoverResult = {
  discoveredReaders: reader[];
  error: stripeError;
  decline_code: stripeDeclineCode;
};

type connectResult = {
  reader: reader;
  error: stripeError;
  decline_code: stripeDeclineCode;
};

type paymentResult = {
  paymentIntent: any;
  error: stripeError;
  decline_code: stripeDeclineCode;
};

type displayInfo = {
  type: "cart";
  cart: {
    line_items: {
      description: string;
      amount: number;
      quantity: number;
    }[];
    tax: number;
    total: number;
    currency: string;
  };
};

// Used any on functions - A promise or a void function can be used
export type sheetInfo = {
  onOk: any;
  onCancel: any;
  okText: string;
  cancelText: string;
  message?: string;
  title?: string;
  convertedErrorCode?: string;
};

declare const StripeTerminal: any;

let terminal: any;

function handleStripeError(
  stripeError: paymentResult | connectResult | discoverResult,
  onOk: any,
  onCancel: any,
): sheetInfo {
  let sheetInfo: sheetInfo = {
    onOk: () => {},
    onCancel: () => {},
    okText: "Error",
    cancelText: "Cancel",
    title: "Something Went Wrong!",
    message: "An unknown error occured - Try again, if the error persists contact administrator.",
    convertedErrorCode: "E999",
  };
  //(02/Aug/21)::(12:33:44) - TODO create a dictionary to match erros with temp codes
  type dictStripeError = stripeDeclineCode | stripeErrorCode;
  const errorDictionary: Record<dictStripeError, sheetInfo> = {
    no_established_connection: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Error",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "An unknown error occured - Try again, if the error persists contact administrator.",
      convertedErrorCode: "E999",
    },
    no_active_collect_payment_method_attempt: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Error",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "An unknown error occured - Try again, if the error persists contact administrator.",
      convertedErrorCode: "E999",
    },
    no_active_read_reusable_card_attempt: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Error",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "An unknown error occured - Try again, if the error persists contact administrator.",
      convertedErrorCode: "E999",
    },
    canceled: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Error",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "An unknown error occured - Try again, if the error persists contact administrator.",
      convertedErrorCode: "E999",
    },
    cancelable_already_completed: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Error",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "An unknown error occured - Try again, if the error persists contact administrator.",
      convertedErrorCode: "E999",
    },
    cancelable_already_canceled: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Error",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "An unknown error occured - Try again, if the error persists contact administrator.",
      convertedErrorCode: "E999",
    },
    network_error: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Error",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "An unknown error occured - Try again, if the error persists contact administrator.",
      convertedErrorCode: "E999",
    },
    network_timeout: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Error",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "An unknown error occured - Try again, if the error persists contact administrator.",
      convertedErrorCode: "E999",
    },
    already_connected: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Error",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "An unknown error occured - Try again, if the error persists contact administrator.",
      convertedErrorCode: "E999",
    },
    failed_fetch_connection_token: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Error",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "An unknown error occured - Try again, if the error persists contact administrator.",
      convertedErrorCode: "E999",
    },
    discovery_too_many_readers: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Error",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "An unknown error occured - Try again, if the error persists contact administrator.",
      convertedErrorCode: "E999",
    },
    invalid_reader_version: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Error",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "An unknown error occured - Try again, if the error persists contact administrator.",
      convertedErrorCode: "E999",
    },
    reader_error: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Error",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "An unknown error occured - Try again, if the error persists contact administrator.",
      convertedErrorCode: "E999",
    },
    command_already_in_progress: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Error",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "An unknown error occured - Try again, if the error persists contact administrator.",
      convertedErrorCode: "E999",
    },
    card_declined: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Try Again",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "An unknown error occured - Try again, if the error persists contact administrator.",
      convertedErrorCode: "E999",
    },
    authentication_required: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Try Again",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The card was declined as the transaction requires authentication.",
      convertedErrorCode: "DECLINE:1001",
    },
    approve_with_id: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Try Again",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The payment cannot be authorized.",
      convertedErrorCode: "DECLINE:1002",
    },
    call_issuer: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Try Again",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The card has been declined for an unknown reason.",
      convertedErrorCode: "DECLINE:1003",
    },
    card_not_supported: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Try Again",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The card does not support this type of purchase.",
      convertedErrorCode: "DECLINE:1004",
    },
    card_velocity_exceeded: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Try Again",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The customer has exceeded the balance or credit limit available on their card.",
      convertedErrorCode: "DECLINE:1005",
    },
    currency_not_supported: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Notify Customer",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The card does not support the specified currency.",
      convertedErrorCode: "DECLINE:1006",
    },
    do_not_honor: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Notify Customer",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The card has been declined for an unknown reason.",
      convertedErrorCode: "DECLINE:1007",
    },
    do_not_try_again: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Notify Customer",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The card has been declined for an unknown reason.",
      convertedErrorCode: "DECLINE:1008",
    },
    duplicate_transaction: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Try Again",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "A transaction with identical amount and credit card information was submitted recently.",
      convertedErrorCode: "DECLINE:1009",
    },
    expired_card: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Notify Customer",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The card has expired.",
      convertedErrorCode: "DECLINE:1010",
    },
    fraudulent: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Notify Customer",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The card has been declined for an unknown reason.",
      convertedErrorCode: "DECLINE:1011",
    },
    generic_decline: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Try Again",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The card has been declined for an unknown reason.",
      convertedErrorCode: "DECLINE:1012",
    },
    incorrect_number: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Try Again",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The card number is incorrect",
      convertedErrorCode: "DECLINE:1013",
    },
    incorrect_cvc: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Try Again",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The CVC number is incorrect.",
      convertedErrorCode: "DECLINE:1014",
    },
    incorrect_pin: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Try Again",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The PIN entered is incorrect. This decline code only applied to payments made with a card reader.",
      convertedErrorCode: "DECLINE:1015",
    },
    incorrect_zip: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Try Again",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The ZIP/postal code is incorrect.",
      convertedErrorCode: "DECLINE:1016",
    },
    insufficient_funds: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Try Again",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The card has insufficient funds to complete the purchase.",
      convertedErrorCode: "DECLINE:1017",
    },
    invalid_account: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Try Again",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The card, or account the card is connected to, is invalid.",
      convertedErrorCode: "DECLINE:1018",
    },
    invalid_amount: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Try Again",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The payment amount is invalid, or exceeds the amount that is allowed.",
      convertedErrorCode: "DECLINE:1019",
    },
    invalid_cvc: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Try Again",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The CVC number is incorrect.",
      convertedErrorCode: "DECLINE:1020",
    },
    invalid_expiry_month: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Try Again",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The expiration month is invalid.",
      convertedErrorCode: "DECLINE:1021",
    },
    invalid_expiry_year: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Try Again",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The expiration year is invalid.",
      convertedErrorCode: "DECLINE:1022",
    },
    invalid_number: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Try Again",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The card number is incorrect.",
      convertedErrorCode: "DECLINE:1023",
    },
    invalid_pin: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Try Again",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The PIN entered is incorrect. This decline code only applied to payments made with a card reader.",
      convertedErrorCode: "DECLINE:1024",
    },
    issuer_not_available: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Try Again",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The card issuer could not be reached, so the payment could not be authorized.",
      convertedErrorCode: "DECLINE:1025",
    },
    lost_card: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Notify Customer",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The card has been declined for an unknown reason.",
      convertedErrorCode: "DECLINE:1026",
    },
    merchant_blacklist: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Notify Customer",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "AThe card has been declined for an unknown reason.",
      convertedErrorCode: "DECLINE:1027",
    },
    new_account_information_available: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Notify Customer",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The card, or account the card is connected to, is invalid.",
      convertedErrorCode: "DECLINE:1028",
    },
    no_action_taken: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Notify Customer",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The card has been declined for an unknown reason.",
      convertedErrorCode: "DECLINE:1029",
    },
    not_permitted: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Notify Customer",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The payment is not permitted.",
      convertedErrorCode: "DECLINE:1030",
    },
    offline_pin_required: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Try Again",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The card has been declined as it requires a PIN.",
      convertedErrorCode: "DECLINE:1031",
    },
    online_or_offline_pin_required: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Try Again",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The card has been declined as it requires a PIN.",
      convertedErrorCode: "DECLINE:1032",
    },
    pickup_card: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Notify Customer",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The card cannot be used to make this payment (it is possible it has been reported last or stolen).",
      convertedErrorCode: "DECLINE:1033",
    },
    pin_try_exceeded: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Try Again",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The allowable number of PIN tries has been exceeded.",
      convertedErrorCode: "DECLINE:1034",
    },
    processing_error: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Try Again",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "An error occurred while processing the card.",
      convertedErrorCode: "DECLINE:1035",
    },
    reenter_transaction: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Try Again",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The payment could not be processed by the issuer for an unknown reason.",
      convertedErrorCode: "DECLINE:1036",
    },
    restricted_card: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Notify Customer",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The card cannot be used to make this payment (it is possible it has been reported last or stolen).",
      convertedErrorCode: "DECLINE:1037",
    },
    revocation_of_all_authorizations: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Notify Customer",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The card has been declined for an unknown reason.",
      convertedErrorCode: "DECLINE:1038",
    },
    revocation_of_authorization: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Notify Customer",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The card has been declined for an unknown reason.",
      convertedErrorCode: "DECLINE:1039",
    },
    security_violation: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Notify Customer",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The card has been declined for an unknown reason.",
      convertedErrorCode: "DECLINE:1040",
    },
    service_not_allowed: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Notify Customer",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The card has been declined for an unknown reason.",
      convertedErrorCode: "DECLINE:1041",
    },
    stolen_card: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Notify Customer",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The card has been declined for an unknown reason.",
      convertedErrorCode: "DECLINE:1042",
    },
    stop_payment_order: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Notify Customer",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The card has been declined for an unknown reason.",
      convertedErrorCode: "DECLINE:1043",
    },
    testmode_decline: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Notify Customer",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "A Stripe test card was used.",
      convertedErrorCode: "DECLINE:1044",
    },
    transaction_not_allowed: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Notify Customer",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The card has been declined for an unknown reason.",
      convertedErrorCode: "DECLINE:1045",
    },
    try_again_later: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Try Again",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The card has been declined for an unknown reason.",
      convertedErrorCode: "DECLINE:1046",
    },
    withdrawal_count_limit_exceeded: {
      onOk: onOk,
      onCancel: () => {},
      okText: "Try Again",
      cancelText: "Cancel",
      title: "Something Went Wrong!",
      message: "The customer has exceeded the balance or credit limit available on their card.",
      convertedErrorCode: "DECLINE:1047",
    },
  };

  //Handle Payment issues - Might be a better typescript way to handle
  if (stripeError.error.code === "card_declined") {
    const { decline_code } = stripeError.error;
    sheetInfo = errorDictionary[decline_code];
  } else {
    const { code } = stripeError.error;

    sheetInfo = errorDictionary[code];
  }

  // Code not found, display generic error message
  if (!sheetInfo) {
    sheetInfo = errorDictionary.card_declined;
  }

  console.log(sheetInfo);

  return sheetInfo;
}

export async function setupStripe(register_group_id?: number) {
  terminal = await StripeTerminal.create({
    onFetchConnectionToken: () => fetchConnectionToken(register_group_id),
    onUnexpectedReaderDisconnect: handleUnexpectedReaderDisconnect,
    onConnectionStatusChange: connectionStatusChange,
  });
}

export async function discoverReaders(
  simulatedReader?: boolean,
  locationID?: string,
  onSucces?: (message: string) => void,
  onError?: (message: string, sheetInfo: sheetInfo) => void,
) {
  store.dispatch({
    type: "terminal.update",
    payload: {
      status: "Searching...",
    },
  });

  // Clear readers before searching
  store.dispatch({
    type: "terminal.update",
    payload: {
      readers: [],
    },
  });
  //General Reader status
  const discoverResult: discoverResult = await terminal.discoverReaders({
    simulated: simulatedReader,
    location: simulatedReader ? undefined : locationID,
  });

  if (discoverResult.error) {
    console.log("Failed to discover", discoverResult.error);
    if (onError) {
      onError(discoverResult.error.message, {
        okText: discoverResult.error.code === "discovery_too_many_readers" ? "Manage Readers" : "Try again",
        cancelText: "Cancel",
        onCancel: () => {}, // Nothing - Handle closing in parent componenent
        onOk:
          discoverResult.error.code === "discovery_too_many_readers"
            ? () => {}
            : discoverReaders(simulatedReader, locationID, onSucces, onError), //TO-DO Navigate to settings/readers/
      });
    }
    store.dispatch({
      type: "terminal.update",
      payload: {
        status: "Error searching",
      },
    });
  } else if (discoverResult.discoveredReaders.length === 0) {
    if (onError) {
      onError("No available readers.", {
        okText: "Setup a Reader",
        cancelText: "Cancel",
        onCancel: () => {}, // Nothing - Handle closing in parent componenent
        onOk: () => {}, //TO-DO Navigate to settings/reader/newReader
      });
    }

    store.dispatch({
      type: "terminal.update",
      payload: {
        status: "Not connected",
      },
    });
  } else {
    const connectedReader = store.getState().terminalStore.reader;

    store.dispatch({
      type: "terminal.update",
      payload: {
        status: connectedReader ? "Connected" : "Readers found",
        readers: discoverResult.discoveredReaders,
      },
    });

    store.dispatch({
      type: "terminal.update",
      payload: {},
    });
    //Automatically connect to last connected reader
    if (onSucces) {
      onSucces("Readers succesfully retrieved.");
    }
  }

  return discoverResult.discoveredReaders;
}

export async function reconnectReader(
  readers: reader[],
  t: TFunction<"translation", undefined>,
  onSuccess?: (message: string, reader?: reader) => void,
  onError?: (message: string) => void,
) {
  const defaultTerminalSerial = localStorage.getItem("defaultTerminal");
  const facility = store.getState().facilityStore.facility;

  try {
    if (defaultTerminalSerial !== null) {
      store.dispatch({
        type: "terminal.update",
        payload: {
          status: "Reconnecting",
        },
      });
      if (readers.length === 0) {
        if (onError) {
          onError("No readers found from server.");
        }
      } else {
        const defaultReader = readers.find(reader => reader.serial_number === defaultTerminalSerial);
        if (defaultReader) {
          console.log(defaultTerminalSerial, defaultReader);
          if (defaultReader.status !== "offline") {
            if (facility.terminal_integration === "server") {
              store.dispatch({
                type: "terminal.update",
                payload: {
                  isLoaded: true,
                  reader: defaultReader,
                  reconnectionAttempts: 0,
                  status: `Selected`,
                },
              });
            } else {
              store.dispatch({
                type: "terminal.update",
                payload: {
                  status: `Reconnecting ${defaultReader.label}`,
                },
              });
              if (onSuccess) {
                onSuccess(`Trying to connect to reader ${defaultReader.label}.`, defaultReader);
              }
              await connectReader(defaultReader, false, onSuccess, onError);
              // An attempt has occured
            }
          } else {
            // Work around solution - wait 15 (x) seconds before updating for a reattempt
            store.dispatch({
              type: "terminal.update",
              payload: {
                status: "Not Connected",
              },
            });
            if (onError) {
              onError(t("helpers.stripe_terminal_wrapper.001"));
            }
          }
        } else {
          console.log("No Default terminal");
          store.dispatch({
            type: "terminal.update",
            payload: {
              status: "Not Connected",
              reader: null,
              reconnectionAttempts: MAX_RECONNECT_ATTEMPTS, // Default terminal is not set we will never be able to reconnect
            },
          });
          if (onError) {
            //(28/Jan/22)::(10:24:54) - User does not directly set a default reader, could cause some confusion.
            //onError("No default terminal set.");
          }
        }
      }
    } else {
      console.log("No Default terminal");
      store.dispatch({
        type: "terminal.update",
        payload: {
          status: "Not Connected",
        },
      });
    }
  } catch (e) {
    localStorage.removeItem("defaultTerminal");
    //await discoverReaders(false, store.getState().facilityStore.facility.stripe_location_id, undefined, undefined);
  }
  await delay(15000);
  if (store.getState().terminalStore.reconnectionAttempts < MAX_RECONNECT_ATTEMPTS) {
    store.dispatch({
      type: "terminal.update",
      payload: {
        reconnectionAttempts: Number(store.getState().terminalStore.reconnectionAttempts) + 1,
      },
    });
  }

  console.log("Reconenction Attempts " + String(store.getState().terminalStore.reconnectionAttempts));
}

export async function connectReader(
  reader: reader,
  failIfInUse?: boolean,
  onSucces?: (message: string, reader?: reader) => void,
  onError?: (message: string, sheetInfo: sheetInfo) => void,
) {
  try {
    const connectionResult = await getConnectionStatus();

    console.log("selecting a reader");

    // See if connecting to default reader

    const defaultTerminalSerial = localStorage.getItem("defaultTerminal");
    let tmpFailIfInUse = failIfInUse;
    if (defaultTerminalSerial === reader.serial_number) {
      tmpFailIfInUse = false;
    }

    if (
      connectionResult !== "connected" ||
      connectionResult !== "connecting" ||
      store.getState().facilityStore.facility.terminal_integration === "server"
    ) {
      if (reader !== null) {
        if (store.getState().facilityStore.facility.terminal_integration === "server") {
          // Save reader to automatically reconnect
          localStorage.setItem("defaultTerminal", reader.serial_number);
          // Save Reader to terminal
          store.dispatch({
            type: "terminal.update",
            payload: {
              status: "Selected",
              isLoaded: true,
              reader: reader,
              reconnectionAttempts: 0,
            },
          });

          if (onSucces) {
            onSucces(`Successfully connected to ${reader.label}`, reader);
          }
        } else {
          if (reader.status !== "offline") {
            const connectResult: connectResult = await terminal.connectReader(reader, {
              fail_if_in_use: failIfInUse,
            });
            console.log("On Connect");

            if (connectResult.error) {
              console.log("connectResult", connectResult);
              localStorage.removeItem("defaultTerminal");
              store.dispatch({
                type: "terminal.update",
                payload: {
                  isLoaded: true,
                  reader: null,
                },
              });
              if (onError) {
                const onOk =
                  connectResult.error.code === "already_connected"
                    ? () => disconnectReader()
                    : async () => await connectReader(reader, failIfInUse, onSucces, onError);
                onError(
                  connectResult.error.message,
                  handleStripeError(connectResult, onOk, () => {}),
                );
              }
              // await discoverReaders(
              //   false,
              //   store.getState().facilityStore.facility.stripe_location_id,
              //   undefined,
              //   undefined,
              // );
            } else {
              // Save reader to automatically reconnect
              localStorage.setItem("defaultTerminal", connectResult.reader.serial_number);
              // Save Reader to terminal
              store.dispatch({
                type: "terminal.update",
                payload: {
                  isLoaded: true,
                  reader: connectResult.reader,
                  reconnectionAttempts: 0,
                },
              });

              if (onSucces) {
                onSucces(`Successfully connected to ${connectResult.reader.label}`, connectResult.reader);
              }
            }
          }
        }
      }
    }
  } catch (e) {
    if (onError) {
      // Error not returned in connectResult - handle manually
      console.log(e);
      localStorage.removeItem("defaultTerminal");
      await discoverReaders(false, store.getState().facilityStore.facility.stripe_location_id, undefined, undefined);
      onError("A Reader is already connected.", {
        okText: "Disconnect Reader",
        cancelText: "Cancel",
        onCancel: () => {}, // Nothing - Handle closing in parent componenent
        onOk: disconnectReader,
      });
    }
  }
}

async function fetchConnectionToken(register_group_id?: number) {
  const connectionResult = await getConnectionStatus();

  if (connectionResult !== "connecting" || connectionResult !== "connected") {
    // Check to see if a register group is saved - if none is passed default to 1
    const rgid = JSON.parse(localStorage.getItem("register_group")).id
      ? Number(JSON.parse(localStorage.getItem("register_group")).id)
      : register_group_id
      ? register_group_id
      : 1;

    console.log("Fetching Reader Group", JSON.parse(localStorage.getItem("register_group")));

    //(28/Jan/22)::(10:26:16) - Under the assumption that all facilies will return a register group.
    const res = await PostConnection({ register_group_id: rgid });
    //console.log(res);

    if (res.status !== StatusCode.OK) {
      store.dispatch({
        type: "error.show",
        payload: { errorMessage: "Something went wrong connecting to the server.", duration: 5000 },
      });
      return;
    }

    return String(res.data.data.secret);
  }
}

export async function disconnectReader() {
  const facility = store.getState().facilityStore.facility;
  if (facility.terminal_integration !== "server") {
    try {
      await terminal.clearReaderDisplay();
      const disconnect = await terminal.disconnectReader();
    } catch (e) {
      store.dispatch({
        type: "error.show",
        payload: { errorMessage: "Reader already disconnected. Try connecting a reader first.", duration: 5000 },
      });
    }
  }

  // Terminal manually disconnected - remove default
  localStorage.removeItem("defaultTerminal");
  store.dispatch({
    type: "terminal.update",
    payload: {
      isLoaded: true,
      reader: null,
      reconnectionAttempts: 0,
    },
  });
}

function handleUnexpectedReaderDisconnect(e: any) {
  store.dispatch({
    type: "error.show",
    payload: {
      errorMessage: "Reader unexpectedly disconnected. Please wait for automatic reconnection, or proceed manually",
      duration: 2000,
    },
  });

  console.log(e);

  // Might be too many messages at once. Would be nice to have a title and then a message.
  store.dispatch({
    type: "error.show",
    payload: {
      message: e.error.message,
      duration: 4000,
    },
  });

  store.dispatch({
    type: "terminal.update",
    payload: {
      status: "Not Connected",
      reader: null,
      isLoaded: true,
      readers: [],
    },
  });

  const testTimeout = setTimeout(() => {
    void discoverOnUnexpectedDisconnect();
  }, 30000);
}

async function discoverOnUnexpectedDisconnect() {
  // Get saved location if not default to facility stripe_location - maybe we should fail?
  const rgslid = JSON.parse(localStorage.getItem("register_group")).stripe_location_id
    ? JSON.parse(localStorage.getItem("register_group")).stripe_location_id
    : store.getState().facilityStore.facility.stripe_location_id;

  console.log("Testing A Timeout for reconnection");
  store.dispatch({
    type: "error.show",
    payload: { errorMessage: "Attempting automatic reconnection", duration: 3000 },
  });
  const status = await getConnectionStatus();

  console.log("Connection Status - fail reconnection dependant", status);
  // await disconnectReader();

  await discoverReaders(false, rgslid, undefined, undefined);
}

export async function processRefund(
  chargeId: string,
  amount: number,
  currency: string,
  onSuccess?: () => void,
  onError?: (message: string, sheetInfo: sheetInfo) => void,
) {
  try {
    let networkError = false;
    const collectRefund = await terminal.collectRefundPaymentMethod(chargeId, amount, currency);
    if (collectRefund.error) {
      if (collectRefund.error.code === "network_error") {
        networkError = true;
      } else if (collectRefund.error.code === "network_timeout") {
        networkError = true;
      }

      if (onError) {
        console.log(collectRefund);
        if (networkError) {
          onError(
            collectRefund.error.message,
            handleStripeError(
              collectRefund,
              () => processRefund(chargeId, amount, currency, onSuccess, onError),
              () => {},
            ),
          );
        } else {
          if (collectRefund.error.message.includes("cancelled by the user")) {
            onError(collectRefund.error.message, {
              onOk: () => {},
              onCancel: () => {},
              okText: undefined,
              cancelText: "Cancel",
              title: "Transaction Cancelled!",
              message: "The transaction has been cancelled",
              convertedErrorCode: "E999",
            });
          } else if (!collectRefund.error.message.includes("not in progress")) {
            onError(
              collectRefund.error.message,
              handleStripeError(
                collectRefund,
                () => {},
                () => {},
              ),
            );
          } else {
            return;
          }
        }
      }
    } else {
      const processRefundRes = await terminal.processRefund();
      if (processRefundRes.error) {
        if (processRefundRes.error.code === "network_error") {
          networkError = true;
        } else if (processRefundRes.error.code === "network_timeout") {
          networkError = true;
        }
        if (networkError) {
          onError(
            processRefundRes.error.message,
            handleStripeError(
              processRefundRes,
              () => processRefund(chargeId, amount, currency, onSuccess, onError),
              () => {},
            ),
          );
        } else {
          onError(
            processRefundRes.error.message,
            handleStripeError(
              processRefundRes,
              () => processRefund(chargeId, amount, currency, onSuccess, onError),
              () => {},
            ),
          );
        }
      } else {
        if (onSuccess) {
          onSuccess();
        }
        return processRefundRes;
      }
    }
  } catch (e) {
    console.log(e);
  }
}

export async function processPayment(
  order_id: number,
  order_amount: number,
  paymentOption: Record<string, any>,
  onSuccess?: (message: string) => void,
  onError?: (meessage: string, sheetInfo: sheetInfo) => void,
) {
  const transactionRes = await PostTransaction(
    {
      kind: "sale",
      amount: order_amount,
      order_id: order_id,
      payment_option_id: paymentOption?.id,
    },
    false,
  );

  if (transactionRes.status === StatusCode.OK) {
    await collectPaymentMethod(transactionRes.data, paymentOption?.payment_method, onSuccess, onError, false);
  } else {
    if (onError) {
      // Non related Stripe Error - handle directly
      onError("Transaction Failed", {
        okText: "Try Again",
        cancelText: "Cancel",
        onCancel: () => {}, // Nothing - Handle closing in parent componenent
        onOk: processPayment(order_id, order_amount, paymentOption, onSuccess, onError),
      });
    }
  }
}

export async function processAccountPayment(
  account_id: number,
  amount: number,
  paymentOption: Record<string, any>,
  onSuccess?: (message: string) => void,
  onError?: (meessage: string, sheetInfo: sheetInfo) => void,
) {
  const transactionRes = await PostAccountTransaction(
    {
      kind: "account_payment",
      amount,
      account_id,
      payment_option_id: paymentOption?.id,
    },
    true,
  );

  if (transactionRes.status === StatusCode.OK) {
    await collectPaymentMethod(transactionRes.data, paymentOption?.payment_method, onSuccess, onError, true);
  } else {
    if (onError) {
      // Non related Stripe Error - handle directly
      onError("Account Transaction Failed", {
        okText: "Try Again",
        cancelText: "Cancel",
        onCancel: () => {}, // Nothing - Handle closing in parent componenent
        onOk: processPayment(account_id, amount, paymentOption, onSuccess, onError),
      });
    }
  }
}

export async function processInvoicePayment(
  invoice_id: number,
  amount: number,
  paymentOption: Record<string, any>,
  onSuccess?: (message: string) => void,
  onError?: (meessage: string, sheetInfo: sheetInfo) => void,
  invoice?: boolean,
  league_id?: number,
  tournament_id?: number,
  hospitality_event_id?: number,
) {
  const transactionRes = await PostInvoiceTransaction(
    {
      invoice_id: invoice_id,
      kind: "invoice_payment",
      amount: amount,
      payment_option_id: paymentOption?.id,
      league_id: league_id,
      tournament_id: tournament_id,
      hospitality_event_id: hospitality_event_id,
    },
    true,
  );

  if (transactionRes.status === StatusCode.OK) {
    await collectPaymentMethod(transactionRes.data, paymentOption?.payment_method, onSuccess, onError, true);
  } else {
    if (onError) {
      // Non related Stripe Error - handle directly
      onError("Account Transaction Failed", {
        okText: "Try Again",
        cancelText: "Cancel",
        onCancel: () => {}, // Nothing - Handle closing in parent componenent
        onOk: processInvoicePayment(invoice_id, amount, paymentOption, onSuccess, onError),
      });
    }
  }
}

export function convertOrderIntoDisplayInfo(cart: any, order?: any): displayInfo {
  const displayInfo: displayInfo = {
    type: "cart",
    cart: {
      line_items: [],
      tax: -1,
      total: -1,
      currency: "",
    },
  };

  const total = order?.balance ? cart.total_price - order?.balance : cart.total_price;
  displayInfo.type = "cart";
  displayInfo.cart.currency = cart.currency;
  displayInfo.cart.total = convertToStripeAmount(total);
  displayInfo.cart.tax = convertToStripeAmount(cart.total_tax);
  displayInfo.cart.line_items = cart.line_items.map((line_item: any) => ({
    description: line_item.product_title,
    amount: convertToStripeAmount(line_item.subtotal_price),
    quantity: line_item.quantity,
  }));

  console.log(displayInfo);
  return displayInfo;
}

function convertToStripeAmount(amount: number) {
  let number = "-1";

  if (amount.toString().includes(".")) {
    if (amount.toString().split(".")[1].length === 0) {
      number = amount.toString().replace(".", "");
    } else if (amount.toString().split(".")[1].length === 1) {
      number = amount.toString().replace(".", "") + "0";
    } else {
      number = amount.toString().replace(".", "");
    }
  } else {
    number = amount.toString().replace(".", "") + "00";
  }
  const convertedNumber = Number.parseInt(number);
  console.log(convertedNumber);
  return convertedNumber;
}

export async function setReaderDisplay(
  displayInfo: displayInfo,
  onSuccess?: (message: string) => void,
  onError?: (meessage: string, sheetInfo: sheetInfo) => void,
) {
  try {
    const setReaderResult: { error: stripeError } = await terminal.setReaderDisplay(displayInfo);
    let networkError = false;

    if (setReaderResult.error) {
      if (setReaderResult.error.code === "network_error") {
        networkError = true;
      } else if (setReaderResult.error.code === "network_timeout") {
        networkError = true;
      }

      if (onError) {
        onError(setReaderResult.error.message, {
          okText: "Retry",
          onOk: () => {
            void setReaderDisplay(displayInfo, onSuccess, onError); //Create function to connect to last known readetr
          },
          cancelText: "Cancel",
          onCancel: () => {}, //Handle closing modal in parent
        });
      }
    }
  } catch (e) {
    if (onError) {
      onError("Reader not connected.", {
        message: "No reader connected - Error communicating to reader when it is not connected.",
        title: "Error",
        okText: "Retry",
        onOk: () => setReaderDisplay(displayInfo, onSuccess, onError), // Use TermainlActions in parent to connect
        cancelText: "Cancel",
        onCancel: () => {}, //Handle closing modal in parent
      });
    }
  }
}

export async function clearReaderDisplay() {
  const facility = store.getState().facilityStore.facility;

  if (facility.terminal_integration !== "server") {
    try {
      const clearReaderResult = await terminal.clearReaderDisplay();

      console.log("clearReaderResult", clearReaderResult);
    } catch (e) {
      console.log("clearReaderResult", e);
      store.dispatch({
        type: "error.show",
        payload: { errorMessage: "Error occured while clearing the reader display.", duration: 5000 },
      });
    }
  }
}

export async function getConnectionStatus() {
  try {
    const connectionResult = await terminal.getConnectionStatus();

    if (connectionResult.status) {
      let convertedMessage = connectionResult.status;
      convertedMessage = convertedMessage.split("_");

      convertedMessage.forEach((element: string, index: number) => {
        convertedMessage[index] =
          String(convertedMessage[index].charAt(0).toUpperCase()) + String(convertedMessage[index].substring(1));
      });

      convertedMessage.join(" ").trim();

      store.dispatch({
        type: "terminal.update",
        payload: {
          status: connectionResult.convertedMessage[0],
        },
      });
    }

    return connectionResult.status;
  } catch (e) {
    store.dispatch({
      type: "error.show",
      payload: { errorMessage: "Error occured while collecting the status of the reader.", duration: 5000 },
    });
  }
}

function connectionStatusChange(status: any) {
  let convertedMessage = status.status;
  convertedMessage = convertedMessage.split("_");

  convertedMessage.forEach((element: string, index: number) => {
    convertedMessage[index] =
      String(convertedMessage[index].charAt(0).toUpperCase()) + String(convertedMessage[index].substring(1));
  });

  const _status = convertedMessage.join(" ");

  store.dispatch({
    type: "terminal.update",
    payload: {
      status: _status,
    },
  });
}

async function collectPaymentMethod(
  transaction: any,
  payment_method: string,
  onSuccess?: (message: string, order_id: number) => void,
  onError?: (message: string, sheetInfo: sheetInfo) => void,
  accountTransaction?: boolean, //set to true if making an account payment
) {
  const collectPaymentResult: paymentResult = await terminal.collectPaymentMethod(transaction.client_secret);
  console.log("collect payment", collectPaymentResult);

  let networkError = false;

  if (collectPaymentResult.error) {
    // Handle Error Types
    if (collectPaymentResult.error.code === "network_error") {
      networkError = true;
    } else if (collectPaymentResult.error.code === "network_timeout") {
      networkError = true;
    }

    if (onError) {
      console.log(collectPaymentResult);
      if (networkError) {
        onError(
          collectPaymentResult.error.message,
          handleStripeError(
            collectPaymentResult,
            () => collectPaymentMethod(transaction, payment_method, onSuccess, onError, accountTransaction),
            () => {},
          ),
        );
      } else {
        if (!collectPaymentResult.error.message.includes("not in progress")) {
          onError(
            collectPaymentResult.error.message,
            handleStripeError(
              collectPaymentResult,
              () => {},
              () => {},
            ),
          );
        } else {
          return;
        }
      }
    }
  } else {
    let paymentCompleteResult: paymentResult = await terminal.processPayment(collectPaymentResult.paymentIntent);
    console.log("payment complete", paymentCompleteResult);
    if (paymentCompleteResult.error) {
      console.log("payment complete - error", paymentCompleteResult);

      if (paymentCompleteResult.error.code === "network_error") {
        networkError = true;
      } else if (paymentCompleteResult.error.code === "network_timeout") {
        networkError = true;
      }

      // Handle Credit Card Errors
      if (payment_method !== "Interac") {
        if (onError) {
          // Payment Intent Error
          if (paymentCompleteResult.paymentIntent) {
            if (paymentCompleteResult.paymentIntent.status === "requires_payment_method") {
              onError(
                paymentCompleteResult.error.message,
                handleStripeError(
                  paymentCompleteResult,
                  () => collectPaymentMethod(transaction, payment_method, onSuccess, onError),
                  () => {},
                ),
              );
            } else if (paymentCompleteResult.paymentIntent.status === "requires_confirmation") {
              onError(
                paymentCompleteResult.error.message,
                handleStripeError(
                  paymentCompleteResult,
                  () => terminal.processPayment(collectPaymentResult.paymentIntent),
                  () => {},
                ),
              );
            }
          }

          if (!paymentCompleteResult.paymentIntent) {
            if (paymentCompleteResult.error.decline_code === "call_issuer") {
              onError(
                paymentCompleteResult.error.message,
                handleStripeError(
                  paymentCompleteResult,
                  () => collectPaymentMethod(transaction, payment_method, onSuccess, onError, accountTransaction),
                  () => {},
                ),
              );
            } else {
              onError(
                paymentCompleteResult.error.message,
                handleStripeError(
                  paymentCompleteResult,
                  () => collectPaymentMethod(transaction, payment_method, onSuccess, onError, accountTransaction),
                  () => {},
                ),
              );
            }
          }
        }
        // Handle Interac Errors
      } else {
        if (networkError) {
          onError(
            paymentCompleteResult.error.message,
            handleStripeError(
              paymentCompleteResult,
              () => collectPaymentMethod(transaction, payment_method, onSuccess, onError, accountTransaction),
              () => {},
            ),
          );
        } else {
          onError(
            paymentCompleteResult.error.message,
            handleStripeError(
              paymentCompleteResult,
              async () => {
                paymentCompleteResult = await terminal.processPayment(collectPaymentResult.paymentIntent);
              },
              () => {},
            ),
          );
        }
      }
    } else if (paymentCompleteResult.paymentIntent) {
      //Handle Interac Payments
      if (payment_method === "Interac") {
        if (onSuccess) {
          let captureResult;
          if (accountTransaction) {
            captureResult = await PutAccountTransactionCapture(
              { payment_intent_id: paymentCompleteResult.paymentIntent.id, transaction_id: transaction.id },
              true,
            );
          } else {
            captureResult = await PutCapture(
              { payment_intent_id: paymentCompleteResult.paymentIntent.id, transaction_id: transaction.id },
              true,
            );
          }
          if (captureResult.status === StatusCode.OK) {
            if (onSuccess) {
              onSuccess("Payment Completed", transaction.order_id);
            }
          }
        }
      } else {
        let captureResult;
        if (accountTransaction) {
          captureResult = await PutAccountTransactionCapture(
            { payment_intent_id: paymentCompleteResult.paymentIntent.id, transaction_id: transaction.id },
            true,
          );
        } else {
          captureResult = await PutCapture(
            { payment_intent_id: paymentCompleteResult.paymentIntent.id, transaction_id: transaction.id },
            true,
          );
        }

        if (captureResult.status === StatusCode.OK) {
          if (onSuccess) {
            onSuccess("Payment Completed", transaction.order_id);
          }
        } else {
          if (onError) {
            onError("An error occured", {
              onCancel: undefined,
              onOk: () => {},
              cancelText: undefined,
              okText: "Retry",
              message: "An error occured caputuring the payment",
              title: "Something went wrong!",
            });
          }
        }
      }
    }
  }
}
