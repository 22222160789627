import React from "react";
import "./storePage.scss";

interface IStorePageProps {
  children: React.ReactNode;
}

export function StorePage(props: IStorePageProps) {
  return <div className="online-store-page">{props.children}</div>;
}
