import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useOnclickOutside from "hooks/useOnclickOutside/useOnclickOutside";
import "./NavigationDropdown.scss";

interface INavigationDropdownOption {
  label: string | JSX.Element;
  onClick: () => void;
}

interface INavigationDropdownProps {
  showPlainTextLabel?: boolean;
  leftAlign?: boolean;
  rightAlign?: boolean;
  label: string | JSX.Element;
  options: INavigationDropdownOption[];
}

interface INavigationDropdownState {
  isDropdownOpen: boolean;
  isFirstOptionHovered: boolean;
}

export function NavigationDropdown(props: INavigationDropdownProps) {
  const [navigationDropdownState, setNavigationDropdownState] = useState<INavigationDropdownState>({
    isDropdownOpen: false,
    isFirstOptionHovered: false,
  });

  const dropdownRef = useOnclickOutside(() => {
    updateNavigationDropdownState({ isDropdownOpen: false });
  });

  function updateNavigationDropdownState(newState: Partial<INavigationDropdownState>) {
    setNavigationDropdownState((cur: INavigationDropdownState) => {
      return { ...cur, ...newState };
    });
  }

  function handleDropdownClick() {
    updateNavigationDropdownState({ isDropdownOpen: !navigationDropdownState.isDropdownOpen });
  }

  function handleFirstOptionHovered(isHovered: boolean) {
    updateNavigationDropdownState({ isFirstOptionHovered: isHovered });
  }

  function handleOptionClick(onClick: () => void) {
    onClick();
    updateNavigationDropdownState({ isDropdownOpen: false });
  }

  return (
    <div ref={dropdownRef} className="navigation-dropdown">
      <div
        onClick={handleDropdownClick}
        className={props.showPlainTextLabel ? "navigation-plain-text-dropbutton" : "navigation-dropbutton"}
      >
        {props.label}
        {!props.showPlainTextLabel && (
          <FontAwesomeIcon className="navigation-dropbutton-icon" icon={["fas", "chevron-down"]} />
        )}
      </div>
      <div
        className={
          props.showPlainTextLabel
            ? `navigation-dropdown-content-plain-text-${
                props.leftAlign ? "left-align-" : props.rightAlign ? "right-align-" : ""
              }container`
            : `navigation-dropdown-content-${
                props.leftAlign ? "left-align-" : props.rightAlign ? "right-align-" : ""
              }container`
        }
      >
        <div
          style={{ display: navigationDropdownState.isDropdownOpen && props.options?.length > 0 ? "block" : "none" }}
          className="navigation-dropdown-content"
        >
          {props.options.map((option, index) => {
            if (index === 0 && !props.leftAlign && !props.rightAlign) {
              return (
                <React.Fragment key={"fragment"}>
                  <div className="navigation-dropdown-content-triangle">
                    <div className="navigation-dropdown-content-arrow-up">
                      <div
                        className="navigation-dropdown-content-arrow-up-inner"
                        style={{
                          borderBottomColor: navigationDropdownState.isFirstOptionHovered ? "#f6f7f9" : "#ffffff",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="navigation-dropdown-content-option"
                    onMouseOver={() => handleFirstOptionHovered(true)}
                    onMouseOut={() => handleFirstOptionHovered(false)}
                    onClick={() => handleOptionClick(option.onClick)}
                  >
                    {option.label}
                  </div>
                </React.Fragment>
              );
            } else {
              return (
                <div key={index}>
                  <div className="navigation-dropdown-content-triangle" />
                  <div className="navigation-dropdown-content-option" onClick={() => handleOptionClick(option.onClick)}>
                    {option.label}
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}
