import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";
import moment from "moment";

import { StatusCode } from "api/protocols";
import { GetBookingParticipant } from "api/rpc/2022-09/facilityAdmin/teesheet/booking";
import { GetReservationBookingAll } from "api/rpc/facilityAdmin/reservation/reservation";

import { showError } from "redux/actions/ui";

import { convertTime, valueToString } from "helpers/Helpers";
import { useAppDispatch } from "hooks/redux";
import { useCustomerContext } from "../../context/CustomerContext";

import { Badge } from "components/badge/Badge";
import DataTable from "../houseAccounts/DataTable";

import "./customerReservations.scss";

const TABLE_LIMIT = 25;

export default function CustomerReservations() {
  const { state, updateState } = useCustomerContext();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [offsetState, setOffsetState] = useState<number>(0);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadReservationBookings(state.customer.id, source.token);
    return () => {
      source.cancel("Customer Reservations unmounted.");
    };
  }, [state.customer.id]);

  // Table OFFSET for booking history
  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadBooking(state.customer.id, offsetState, source.token);
    return () => {
      source.cancel("Customer Reservations unmounted.");
    };
  }, [state.customer.id, offsetState]);

  async function loadBooking(customerId: number, offset: number, token?: CancelToken) {
    if (state.bookings !== null) {
      updateState({ bookings: null });
    }
    const bookingRes = await GetBookingParticipant(
      {
        customer_id: customerId,
        extended_booking: true,
        offset,
      },
      token ? false : true,
      token,
    );

    if (token && token.reason) {
      return;
    }
    if (bookingRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.customer.tabs.reservation.customer_reservations.016")));
      updateState({ bookings: [] });
      return;
    }

    updateState({ bookings: bookingRes.data });
  }

  async function loadReservationBookings(customerId: number, token?: CancelToken) {
    if (state.reservationBookings !== null) {
      updateState({ reservationBookings: null });
    }
    const bookingRes = await GetReservationBookingAll({ customer_id: customerId }, token ? false : true, token);
    if (token && token.reason) {
      return;
    }
    if (bookingRes.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.customer.tabs.reservation.customer_reservations.017")));
      updateState({ reservationBookings: [] });
      return;
    }

    updateState({ reservationBookings: bookingRes.data });
  }

  return (
    <>
      <h1 className="customer-reservation-header">
        {t("secure.facility.customer.tabs.reservation.customer_reservations.001")}
      </h1>
      <DataTable
        columns={[
          { label: t("secure.facility.customer.tabs.reservation.customer_reservations.002") },
          { label: t("secure.facility.customer.tabs.reservation.customer_reservations.003") },
          { label: t("secure.facility.customer.tabs.reservation.customer_reservations.004") },
          { label: t("secure.facility.customer.tabs.reservation.customer_reservations.005") },
          { label: t("secure.facility.customer.tabs.reservation.customer_reservations.006") },
          { label: "Status" }, // TODO: Translation
        ]}
        loading={state.bookings === null}
        footer={{
          tableLimit: TABLE_LIMIT,
          tableOffset: offsetState,
          handleTableOffset: direction =>
            setOffsetState(direction === "prev" ? offsetState - TABLE_LIMIT : offsetState + TABLE_LIMIT),
          disableNextOffset: !state.bookings || !(state.bookings?.length === TABLE_LIMIT),
        }}
      >
        {state.bookings
          ?.filter(bookingParticipant => !!bookingParticipant.turn === false)
          ?.map((bookingParticipant, rowIndex) => {
            return (
              <tr key={bookingParticipant.id}>
                <td>{bookingParticipant.booking.confirmation_number}</td>

                {bookingParticipant.tee_time ? (
                  <td>
                    <span>{moment(bookingParticipant.tee_time.date).format("MMMM DD YYYY")}</span>
                    <span>
                      {" "}
                      {t("secure.facility.customer.tabs.reservation.customer_reservations.008")}{" "}
                      {convertTime(bookingParticipant.tee_time.start_time)}
                    </span>
                  </td>
                ) : (
                  <td>{t("secure.facility.customer.tabs.reservation.customer_reservations.009")}</td>
                )}

                {bookingParticipant.booking.completed_at ? (
                  <td>
                    <span>{moment(bookingParticipant.booking.completed_at).format("MMMM DD YYYY, h:mm A")}</span>
                  </td>
                ) : (
                  <td>{t("secure.facility.customer.tabs.reservation.customer_reservations.009")}</td>
                )}

                {bookingParticipant.tee_time ? (
                  <td>{bookingParticipant.tee_time.course.long_name}</td>
                ) : (
                  <td>{t("secure.facility.customer.tabs.reservation.customer_reservations.009")}</td>
                )}

                <td>{bookingParticipant.booking.quantity}</td>
                <td>
                  {generateStatusBadge(
                    bookingParticipant.status === "complete"
                      ? bookingParticipant.check_in_status
                      : bookingParticipant.status,
                  )}
                </td>
              </tr>
            );
          })}
      </DataTable>

      <h1 className="customer-reservation-header">
        {t("secure.facility.customer.tabs.reservation.customer_reservations.010")}
      </h1>

      <DataTable
        columns={[
          { label: t("secure.facility.customer.tabs.reservation.customer_reservations.011") },
          { label: t("secure.facility.customer.tabs.reservation.customer_reservations.012") },
          { label: t("secure.facility.customer.tabs.reservation.customer_reservations.013") },
          { label: t("secure.facility.customer.tabs.reservation.customer_reservations.014") },
        ]}
        loading={state.reservationBookings === null}
      >
        {state.reservationBookings?.map(reservation => {
          return (
            <tr key={reservation.id}>
              <td>{reservation.confirmation_number}</td>
              <td>{moment(reservation.date).format("LL")}</td>
              <td>{moment(reservation.start_time, "hh:mm:ss").format("h:mm A")}</td>
              <td>{reservation.quantity}</td>
            </tr>
          );
        })}
      </DataTable>
    </>
  );
}

function generateStatusBadge(status: string) {
  switch (status) {
    case "checked":
      return <Badge type="success">{valueToString(status, "capitalize")}</Badge>;
    case "unchecked":
      return <Badge type="warning">{valueToString(status, "capitalize")}</Badge>;
    case "cancelled":
      return <Badge type="error">{valueToString(status, "capitalize")}</Badge>;
    case "no_show":
      return <Badge type="error">{valueToString(status, "capitalize")}</Badge>;
    default:
      return <Badge type="gray">{valueToString(status, "capitalize")}</Badge>;
  }
}
