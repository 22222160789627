import React, { useEffect, useState } from "react";
import classNames from "classnames";
import "components/stack/stack.scss";

import TextContainer from "components/text/textContainer/TextContainer";
import Heading from "components/text/heading/Heading";

const Item = (props: any) => (
  <div
    className={classNames("ui-stack-item", {
      "ui-stack-item_fill": props.fill,
    })}
  >
    {props.children}
  </div>
);

const Stack = (props: any) => (
  <div
    className={classNames("ui-stack", {
      "ui-stack-wrap_none": props.wrap === false,
      "ui-stack-spacing_extra-loose": props.spacing === "extraLoose",
      "ui-stack-spacing_loose": props.spacing === "loose",
      "ui-stack-spacing_tight": props.spacing === "tight",
      "ui-stack-spacing_extra-tight": props.spacing === "extraTight",
      "ui-stack-spacing_none": props.spacing === "none",

      "ui-stack-align_center": props.align === "center",
      "ui-stack-align_start": props.align === "start",
      "ui-stack-align_end": props.align === "end",
      "ui-stack-align_fill": props.align === "fill",
      "ui-stack-align_baseline": props.align === "baseline",

      "ui-stack-distribute_equal": props.distribute === "equal",
      "ui-stack-distribute_start": props.distribute === "start",
      "ui-stack-distribute_end": props.distribute === "end",
      "ui-stack-distribute_fill": props.distribute === "fill",
      "ui-stack-distribute_fill-evenly": props.distribute === "fillEvenly",
      "ui-stack-distribute_center": props.distribute === "center",

      "ui-stack--vertical": props.vertical,
      "ui-stack--full-width": props.fullWidth,
    })}
  >
    {React.Children.map(props.children, (child) => {
      if (child.type.displayName === "Item") {
        return <>{child}</>;
      } else {
        return <Item>{child}</Item>;
      }
    })}
  </div>
);

Item.displayName = "Item";
Stack.Item = Item;
export default Stack;
