import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { TWeather } from "redux/reducers/models/facility";
import { capitalizeWords } from "helpers/Helpers";
import { useTranslation } from "react-i18next";
import "./weather.scss";

export default function Weather(props: { weather: TWeather }) {
  const { weather } = props;
  const { t } = useTranslation();
  return (
    <div className="flex flex-row items-center justify-center">
      <p className="ml-1 weather-size">
        {weather.weather.main ? (
          <>
            <FontAwesomeIcon
              icon={CONDITIONS[props.weather.weather.main].icon}
              className={CONDITIONS[props.weather.weather.main].theme}
            />

            <span className="text-semibold ml-2">{`${String(weather.temperature.temperature)}\u00B0`} </span>
            <span className="text-gray-500 font-medium">{capitalizeWords(weather.weather.description)}</span>
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={["far", "circle-exclamation"]} className={"theme-clouds"} />
            <span className="ml-2 text-gray-500 text-medium">{t("components.weather.weather.001")}</span>
          </>
        )}
      </p>
    </div>
  );
}

const CONDITIONS: Record<string, { icon: IconProp; theme: string }> = {
  Thunderstorm: { icon: ["fad", "thunderstorm"], theme: "theme-thunderstorm" },
  Drizzle: { icon: ["fad", "cloud-drizzle"], theme: "theme-drizzle" },
  Rain: { icon: ["fad", "cloud-rain"], theme: "theme-rain" },
  Snow: { icon: ["fad", "snowflake"], theme: "theme-snow" },
  Mist: { icon: ["fad", "fog"], theme: "theme-mist" },
  Smoke: { icon: ["fad", "smoke"], theme: "theme-smoke" },
  Haze: { icon: ["fad", "sun-haze"], theme: "theme-haze" },
  Dust: { icon: ["fad", "sun-dust"], theme: "theme-dust" },
  Fog: { icon: ["fad", "fog"], theme: "theme-fog" },
  Sand: { icon: ["fad", "sun-dust"], theme: "theme-sand" },
  Ash: { icon: ["fad", "sun-dust"], theme: "theme-ash" },
  Squall: { icon: ["fad", "wind-warning"], theme: "theme-squall" },
  Tornado: { icon: ["fad", "tornado"], theme: "theme-tornado" },
  Clear: { icon: ["fad", "sun"], theme: "theme-clear" },
  Clouds: { icon: ["fad", "clouds"], theme: "theme-clouds" },
};
