import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Order from "pages/secure/client/order/Order";

const mapStateToProps = (state: any) => {
  return {
    clientFacilityStore: { ...state.clientFacilityStore },
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    authActions: bindActionCreators({}, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Order);
