import { AdminType, APIDelete, APIGet, APIPost, APIPut, APIUrl } from "api/protocols";
import { IDiscount } from "api/rpc/2022-09/facilityAdmin/product/discount";
import { CancelToken } from "axios";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/discount";

type TGetDiscount = {
  id?: string | number;
  application?: string;
};

type TPostDiscount = {
  title: string;
  application: "order" | "line_item";
  value_type: "percent" | "fixed_amount";
  value: number;
  custom?: boolean; // default to 0
  start_date?: Date; // default to null
  end_date?: Date; // default to null
  tracked?: boolean; // default to 0;
};

type TPutDiscount = {
  id: string | number;
  title?: string;
  application?: "order" | "line_item";
  value_type?: "percent" | "fixed_amount";
  value?: number;
  custom?: boolean;
  tracked?: boolean;
};

// Retrieve discount(s)
export async function GetDiscount(params?: TGetDiscount, useGlobalLoader?: boolean, token?: CancelToken) {
  return await APIGet<IDiscount[]>(APIUrl(AdminType.FacilityAdmin, "/discount"), params, useGlobalLoader, token);
}

//POST Discount
//Create a new discount
export async function PostDiscount(params?: TPostDiscount, useGlobalLoader?: boolean) {
  return await APIPost(APIUrl(AdminType.FacilityAdmin, "/discount"), params, useGlobalLoader);
}

//PUT Discount
//Update an existing discount
export async function PutDiscount(params?: TPutDiscount, useGlobalLoader?: boolean) {
  return await APIPut(APIUrl(AdminType.FacilityAdmin, "/discount"), params, useGlobalLoader);
}

//DELETE Discount
//Delete a discount
export async function DeleteDiscount(params?: { id: string | number }, useGlobalLoader?: boolean) {
  return await APIDelete(APIUrl(AdminType.FacilityAdmin, "/discount"), params, useGlobalLoader);
}
