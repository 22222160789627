import { StatusCode } from "api/protocols";
import { EmailReceipt, GetReceipt } from "api/rpc/2022-09/facilityAdmin/order/order";
import { GetGiftCard, PrintGiftCard } from "api/rpc/2022-09/facilityAdmin/payment/giftCard";
import Sheet from "components/sheet/Sheet";
import React, { useEffect, useState } from "react";
import { IUIActions, showError, showSuccess } from "redux/actions/ui";
import { IOrder } from "redux/reducers/models/order";
import Input from "components/form/input/Input";
import Popup from "components/popup/Popup";
import Form from "components/form/Form";
import FormLayout from "components/form/FormLayout";
import { ButtonNew as Button } from "components/buttonNew";
import Spin from "components/spin/spin";
import ReactDOM from "react-dom";
import { GetCartWaiver } from "api/rpc/teeSheet/booking";
import { GetRainCheckReceipt, EmailRainCheckReceipt } from "api/rpc/facilityAdmin/payment/rainCheck";
import { useAppDispatch } from "hooks/redux";

interface IRainCheck {
  id: number;
  code: string;
  last4: string;
  balance: number;
  currency: string;
  facility_id: number;
  client_id: number;
  customer_id: number;
  status: string;
  order_id: number;
  note: string;
  user_id: number;
  tee_time_booking_participant_id: number;
  updated_at: Date;
  created_at: Date;
}

interface IProps {
  /**Boolean that determines if the action bar is displayed */
  open: boolean;
  /**Function that sets the state of the open boolean */
  setClose: (open: boolean) => void;
  /**Current active order */
  rainCheck: Record<string, any> | IRainCheck;

  //Raincheck Customer
  bookingParticipant: any;
}

interface IEmailState {
  modalVisible: boolean;
  email: string;
}

export default function RainCheckCompletePopup(props: IProps) {
  const { open, setClose, rainCheck, bookingParticipant } = props;
  const dispatch = useAppDispatch();

  const [emailState, setEmailState] = useState<IEmailState>({
    modalVisible: false,
    email: "",
  });

  async function printReceipt() {
    if (rainCheck) {
      const rainCheckReceiptRes = await GetRainCheckReceipt({ code: rainCheck.code }, true);
      if (rainCheckReceiptRes.status !== StatusCode.OK) {
        dispatch(showError(rainCheckReceiptRes.message));
        return;
      } else {
        window.open().document.write(rainCheckReceiptRes.data);
      }
    }
  }

  async function emailReceipt() {
    const emailReceiptRes = await EmailRainCheckReceipt(
      { code: rainCheck?.code, customer_email: emailState?.email },
      true,
    );
    if (emailReceiptRes?.status !== StatusCode.OK) {
      dispatch(showError("Error sending email rain check"));
      return;
    }
    setEmailState(prevState => ({ ...prevState, modalVisible: false, email: "" }));
    dispatch(showSuccess("Email rain check sent successfully"));
  }

  function handleEmailInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setEmailState(prevState => ({ ...prevState, [id]: value }));
  }

  return (
    <>
      <Popup
        type="success"
        open={open}
        title={"Rain Check Created"}
        description="Rain Check Has Been Created"
        closable
        onOk={setClose}
        okText="Close"
        backDropCancel={false}
      >
        <div className="mt-4">
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Button block size="medium" onClick={printReceipt}>
                  Print
                </Button>
                <Button
                  block
                  size="medium"
                  onClick={() =>
                    setEmailState(prevState => ({
                      ...prevState,
                      modalVisible: true,
                      email: bookingParticipant?.customer?.email,
                    }))
                  }
                >
                  Email
                </Button>
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </div>
      </Popup>

      <Sheet
        title="Enter Email"
        open={emailState.modalVisible}
        size="small"
        stacked
        closable
        onCancel={() => setEmailState(prevState => ({ ...prevState, modalVisible: false, email: "" }))}
        onOk={emailReceipt}
        okText="Send Email"
        okDisabled={!emailState.email ? true : false}
      >
        <Input placeholder="Email" value={emailState.email} id="email" onChange={handleEmailInputChange} />
      </Sheet>
    </>
  );
}
