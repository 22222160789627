import React, { useEffect, useState } from "react";
import Page from "components/page/Page";
import DataTable from "pages/secure/facility/customer/tabs/houseAccounts/DataTable";
import { useHistory } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./teeSheetDynamicPricing.scss";
import { IDynamicPricingTemplate } from "redux/reducers/models/dynamicPricing";
import { GetDynamicPricingTemplates } from "api/rpc/2024-04/facilityAdmin/teesheet/dynamicPricing";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { StatusCode } from "api/protocols";
import { showError } from "redux/actions/ui";
import axios, { CancelToken } from "axios";

interface ITeeSheetDynamicPricingState {
  templates: IDynamicPricingTemplate[];
  finishedLoadingTemplatesAttempt: boolean;
}

export default function TeeSheetDynamicPricing() {
  const history = useHistory();

  const dispatch = useAppDispatch();

  const [state, setState] = useState<ITeeSheetDynamicPricingState>({
    templates: [],
    finishedLoadingTemplatesAttempt: false,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (!state.finishedLoadingTemplatesAttempt) {
      void loadTemplates(source.token);
    }

    return () => {
      source.cancel();
    };
  }, [state.finishedLoadingTemplatesAttempt]);

  async function loadTemplates(token: CancelToken) {
    const getTemplatesResponse = await GetDynamicPricingTemplates({}, false, token);

    if (token && token.reason) {
      setState(prev => ({
        ...prev,
        templates: [],
        finishedLoadingTemplatesAttempt: true,
      }));

      return;
    }

    if (getTemplatesResponse.status !== StatusCode.OK) {
      dispatch(showError("Error loading templates"));

      setState(prev => ({
        ...prev,
        templates: [],
        finishedLoadingTemplatesAttempt: true,
      }));

      return;
    }

    setState(prev => ({
      ...prev,
      templates: getTemplatesResponse.data,
      finishedLoadingTemplatesAttempt: true,
    }));
  }

  function navigateTo(pathEnding: string) {
    history.push(`/admin/settings/tee-sheet/dynamic-pricing/${pathEnding}`);
  }

  return (
    <Page
      title="Dynamic Pricing"
      narrow
      primaryAction={{
        content: "New Template",
        action: () => navigateTo("new"),
      }}
    >
      <DataTable
        columns={[
          {
            label: "Title",
            width: "50%",
          },
          {
            label: "Active",
            width: "50%",
          },
        ]}
        loading={!state.finishedLoadingTemplatesAttempt}
      >
        {state.templates?.map(template => {
          return (
            <tr key={template.id} className="clickable" onClick={() => navigateTo(String(template.id))}>
              <td>{template.title}</td>
              <td>
                <FontAwesomeIcon
                  className="dynamic-pricing-template-active-icon"
                  icon={["far", template.active ? "check-square" : "square"]}
                />
              </td>
            </tr>
          );
        })}
      </DataTable>
    </Page>
  );
}
