import { APIPost, APIDelete } from "api/protocols";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/product/image";

// Post Image to product
export async function PostProductImage(params: FormData, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}

// Remove Image from product
export async function DeleteProductImage(params: { id: number }, useGlobalLoader: boolean) {
  return await APIDelete(baseUrl, params, useGlobalLoader);
}
