import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { IProduct } from "redux/reducers/models/product";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/product";

export type TExtendedProductFilters = {
  /** Return all the products that have these product types. */
  type: string[];
  /** Return all the products from the department with these ID's. */
  department_id: number[];
  /** Return all the products from the department categories with these ID's. */
  category_id: number[];
  /** Return all the products from the subcategories with these ID's. */
  subcategory_id: number[];
};

export type TProductMetaData = {
  membership_type?: string;
  /** Default = false */
  reciprocal?: boolean;
  expiry?: Date | string;
  customer_type_id?: number | null;
};

export type TGetProduct = {
  all?: boolean;
  id?: string | number;
  ids?: Array<number>;
  search?: string;
  filters?: {
    label: keyof TExtendedProductFilters;
    value: string[] | number[];
  }[];
  type?: string;
  types?: string[];
  track_inventory?: boolean;
  folder_id?: string | number;
  variants?: boolean;
  /** This is a taxing call to make. */
  extended?: boolean;
  extended_variants?: boolean;
  inventory?: boolean;
  sales_channels?: boolean;
  modifier_groups?: boolean;
  /** Default: 50 */
  limit?: number;
  /** Default: 0 */
  offset?: number;
  /** Default: 0 */
  archived?: 0 | 1;
  vendor_id?: number;
  skus?: Array<string>;
};

export type TPostProduct = {
  title?: string;
  /** Default: null */
  subtitle?: string;
  /** Default: null */
  preferred_title?: string;
  type?: string;
  description?: string;
  internal_description?: string;
  vendor_id?: number;
  /** Default = 0 */
  customer_required?: boolean;
  /** Default = 0 */
  shipping_required?: boolean;
  /** Default = 0 */
  fulfillment_required?: boolean;
  /** Default = 0 */
  track_inventory?: boolean;
  /** Default = 0 */
  gift_card?: boolean;
  /** Default = null */
  available_on?: Date;
  /** Default = null */
  unavailable_on?: Date;
  /** Default = facility */
  applicable_taxes?: "facility" | "customer";
  department_id?: number;
  category_id?: number;
  subcategory_id?: number;
  accounting_reference_id?: number;
  account_id?: number;
  price?: number;
  original_price?: number;
  cost?: number;
  /** Default = 1 */
  taxable?: boolean;
  barcode?: string | null;
  sku?: string | null;
  /** Default = 0 */
  tracked?: boolean;
  tax_line_ids?: number[];
  options?: { name: string; values: string[] }[];
  meta?: TProductMetaData;
  auto_generate_sku?: 0 | 1;
};

export type TPutProduct = {
  id: string | number;
  title?: string;
  subtitle?: string;
  /** Default = null */
  preferred_title?: string;
  type?: string;
  description?: string;
  internal_description?: string;
  vendor_id?: number;
  customer_required?: boolean;
  shipping_required?: boolean;
  fulfillment_required?: boolean;
  track_inventory?: boolean;
  gift_card?: boolean;
  available_on?: Date | string; //Date string
  unavailable_on?: Date | string; // Date string
  applicable_taxes?: string; // "facility" | "customer"
  department_id?: number;
  category_id?: number;
  subcategory_id?: number;
  accounting_reference_id?: string | number;
  account_id?: number;
  price?: number;
  original_price?: number;
  cost?: number;
  barcode?: string | null;
  sku?: string | null;
  meta?: {
    kitchen_location_id?: number;
    kitchen_location_id_2?: number;
    kitchen_location_id_3?: number;
    button_color?: string;
    cannot_be_sold?: boolean;
  };
  taxable?: boolean;
};

export async function GetProduct(params: TGetProduct, useGlobalLoader: boolean, cancelToken?: CancelToken) {
  return await APIGet<IProduct[]>(baseUrl, params, useGlobalLoader, cancelToken);
}
export async function PostProduct(params: TPostProduct, useGlobalLoader: boolean) {
  return await APIPost(baseUrl, params, useGlobalLoader);
}
export async function PutProduct(params: TPutProduct, useGlobalLoader: boolean) {
  return await APIPut<IProduct[]>(baseUrl, params, useGlobalLoader);
}
/** Deleting a product will only remove it from the facilities list. */
export async function DeleteProduct(params: { id: string | number }, useGlobalLoader: boolean) {
  return await APIDelete(baseUrl, params, useGlobalLoader);
}

// Attach a product to a facility
export async function PutProductAttach(params: { product_id: string | number }, useGlobalLoader?: boolean) {
  return await APIPut(baseUrl + "/attach", params, useGlobalLoader);
}

export async function PutProductDetach(params: { product_ids: Array<number> }, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/detach", params, useGlobalLoader);
}

// Duplicate Product / Variant
export async function DuplicateProduct(params: { product_id: string | number }, useGlobalLoader: boolean) {
  return await APIPost<IProduct[]>(baseUrl + "/duplicate", params, useGlobalLoader);
}

// Post a product option
export async function PostProductOption(
  params: { product_id: string | number; name: string; values?: any[] },
  useGlobalLoader: boolean,
) {
  return await APIPost(baseUrl + "/option", params, useGlobalLoader);
}

// POST Add Product to Variant.  Used with combo products
export async function AddProductToVariant(
  params: { base_variant_id: number; variant_id: number },
  useGlobalLoader: boolean,
) {
  return await APIPost(baseUrl + "/combination", params, useGlobalLoader);
}

/** Update a product combo price | cost */
export async function UpdateComboProduct(
  params: { base_variant_id: number; variant_id: number; price: string; cost: string },
  useGlobalLoader: boolean,
) {
  return await APIPost(baseUrl + "/combination", params, useGlobalLoader);
}

export type TRemoveProductFromVariant = {
  /** the base product ID */
  base_variant_id: number;

  /** the variant ID to remove from the base product */
  variant_id: number;
};
// DELETE Products from Variant
export async function RemoveProductFromVariant(params: TRemoveProductFromVariant, useGlobalLoader: boolean) {
  return await APIDelete(baseUrl + "/combination", params, useGlobalLoader);
}
