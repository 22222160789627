import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios, { CancelToken } from "axios";

import { StatusCode } from "api/protocols";
import { GetReservationModuleAll } from "api/rpc/facilityAdmin/reservation/reservation";

import { showError } from "redux/actions/ui";
import { IModule } from "redux/reducers/models/reservations";
import { useAppDispatch } from "hooks/redux";

import Page from "components/page/Page";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";

export default function Modules() {
  const history = useHistory();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const [modules, setModules] = useState<IModule[]>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadAllReservationModules(source.token);
    return () => source.cancel();
  }, []);

  async function loadAllReservationModules(token?: CancelToken) {
    if (modules !== undefined) {
      setModules(undefined);
    }
    const { status, data } = await GetReservationModuleAll(null, token ? false : true, token);

    if (token && token.reason) {
      return;
    }
    if (status !== StatusCode.OK) {
      dispatch(showError("Error loading modules"));
    } // TODO: Translation

    setModules(status !== StatusCode.OK ? [] : data);
  }

  return (
    <Page
      title={t("secure.facility.settings.reservations.modules.001")}
      subtitle={t("secure.facility.settings.reservations.modules.002")}
      narrow
    >
      <DataTable
        columns={[{ label: t("secure.facility.settings.reservations.modules.003") }]}
        loading={modules === undefined}
      >
        {modules?.map(module => (
          <tr
            key={module.id}
            onClick={() => history.push("/admin/settings/reservations/module/" + String(module.id))}
            className="clickable"
          >
            <td>{module.title}</td>
          </tr>
        ))}
      </DataTable>
    </Page>
  );
}
