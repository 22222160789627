import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TableSelection from "pages/secure/facility/tableService/TableSelection";
import * as UIActions from "redux/actions/ui";
import * as CartActions from "redux/actions/cart";
import * as AuthActions from "redux/actions/auth";

const mapDispatchToProps = (dispatch: any) => {
  return {
    uiActions: bindActionCreators(
      {
        ...UIActions,
      },
      dispatch,
    ),
    cartActions: bindActionCreators(
      {
        ...CartActions,
      },
      dispatch,
    ),
    authActions: bindActionCreators(
      {
        ...AuthActions,
      },
      dispatch,
    ),
  };
};

const mapStateToProps = (state: any) => {
  return {
    cartStore: { ...state.cartStore },
    authStore: { ...state.authStore },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableSelection);
