import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";

import useHover from "hooks/events/useHover";

import "./informationItem.scss";

type TInformationItemMainProps = {
  icon: IconProp;
  label: string;
  value: string | number;
};

type TInformationItemHover = {
  hoverable: true;
  children: JSX.Element;
};

type TInformationHoverRef = {
  hoverable?: false;
  children?: never;
};

type TInformationItemProps = TInformationItemMainProps & (TInformationItemHover | TInformationHoverRef);

export default function InformationItem(props: TInformationItemProps) {
  const { icon, label, value, hoverable, children } = props;
  const ref = useRef<HTMLParagraphElement>(null);
  const hovered = useHover(ref);

  return (
    <div className="statistics-bar-booking-content-information-container">
      <p ref={ref} className="statistics-bar-booking-content-information">
        <FontAwesomeIcon icon={icon} className="statistics-bar-booking-content-information-icon" />
        <span className="statistics-bar-booking-content-information-title">{value}</span>{" "}
        <span className="statistics-bar-booking-content-information-subtitle">{label}</span>
        {hoverable && (
          <FontAwesomeIcon
            color="gray"
            className="statistics-bar-booking-content-information-hover-icon"
            icon={["far", "info-circle"]}
          />
        )}
      </p>

      {hoverable && (
        <div className={`statistics-bar-booking-content-information-hover ${hovered && "hovered"}`}>{children}</div>
      )}
    </div>
  );
}
