import React from "react";
import { IFacility } from "pages/guest/models/facility";
import "./TournamentHeader.scss";
import { useHistory } from "react-router";
import { ITournament } from "redux/reducers/models/tournament";

interface ITournamentHeaderProps {
  facility: IFacility;
  tournament: ITournament;
}

export default function TournamentHeader(props: ITournamentHeaderProps) {
  const { facility, tournament } = props;
  const history = useHistory();

  function navigateToHome() {
    history.push(`/tournament/${facility.short_name}/${tournament.handle}/`);
  }

  if (facility && tournament) {
    return (
      <div className="tournament-header">
        <img className="tournament-header-facility-logo" src={tournament.logo_source} onClick={navigateToHome} />
        <div>
          <div className="tournament-header-title">{tournament.name ?? ""}</div>
          <div className="tournament-header-sub-title">{facility.long_name ?? ""}</div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
