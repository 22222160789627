import { APIGet, APIPost, APIPut, APIDelete, StatusCode } from "api/protocols";
import { CancelToken } from "axios";

const apiVersion = "2021-01";
const adminType = "client-admin";
const baseFacilityUrl = "/" + apiVersion + "/" + adminType + "/facility";
const newBaseFacilityUrl = "/2022-09/" + adminType + "/facility";
const baseClientUrl = "/" + apiVersion + "/" + adminType + "/client";

export interface IFacility {
  id: number;
  long_name: string;
  full_name: string;
  short_name: string;
  code: string;
  holes: number;
  client_id: number;
  address_line_1: string;
  address_line_2: string | null;
  city: string;
  province_id: number;
  province_code: string;
  province_name: string;
  country_id: number;
  country_code: string;
  country_name: string;
  postal: string;
  phone: string;
  email: string;
  customer_email: string;
  logo_source: string;
  stripe_connect_id: string | null;
  stripe_location_id: string | null;
  stripe_customer_id: string | null;
  timezone: string;
  primary_locale: string;
  region_id: number;
  region_name: string;
  latitude: number;
  longitude: number;
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
  prefix: string;
}

type IFacilityHoles = 9 | 18 | 27 | 36;

type IFacilityRegion = "Greater Toronto Area" | "Hamilton" | "Kitchener/Waterloo";

interface IGetFacility {
  id?: number;
  search?: string;
  extended?: boolean;
  limit?: number;
  offset?: number;
  type?: IFacilityHoles | IFacilityRegion;
}

interface IGetFacilityRes {
  data: IFacility[];
  message: string;
  status: StatusCode;
}

export async function GetFacilityNew(params: IGetFacility, useGlobalLoader: boolean): Promise<IGetFacilityRes> {
  return await APIGet(newBaseFacilityUrl, params, useGlobalLoader);
}

// GET Facility
export async function GetFacility(
  params: IGetFacility,
  useGlobalLoader: boolean,
  token?: CancelToken,
): Promise<IGetFacilityRes> {
  return await APIGet(baseFacilityUrl, params, useGlobalLoader, token);
}

export async function PostFacility(body: any, useGlobalLoader: boolean) {
  return await APIPost(baseFacilityUrl, body, useGlobalLoader);
}

// PUT Facility
export async function PutFacility(body: any, useGlobalLoader: boolean) {
  return await APIPut(baseFacilityUrl, body, useGlobalLoader);
}

export async function GetPaymentOptions(params?: Record<string, unknown>, useGlobalLoader?: boolean) {
  return await APIGet(baseFacilityUrl + "/payment-option", (params = null), (useGlobalLoader = false));
}

export async function GetFacilityHoles(useGlobalLoader: boolean) {
  return await APIGet(baseFacilityUrl + "/holes", useGlobalLoader);
}

// GET Accounting Reference
export async function GetAccountingReference(params: string, useGlobalLoader?: boolean, token?: CancelToken) {
  return await APIGet(baseClientUrl + "/accounting?" + params, null, useGlobalLoader, token);
}

//GET Accounts
export async function GetAccounts(useGlobalLoader: boolean) {
  return await APIGet(baseClientUrl + "/account", useGlobalLoader);
}

//GET Departments
export async function GetDepartments(useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet(baseClientUrl + "/department?extended=true", null, useGlobalLoader, token);
}

//GET Sales Channels
//Retrieve a list of sales channels for the facility
export async function GetSalesChannels(params: any, useGlobalLoader: boolean) {
  return await APIGet(baseFacilityUrl + "/sales-channel", useGlobalLoader);
}

//PUT Update Sales Channel
//Adds or removes a product from sales channel
export async function UpdateSalesChannel(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseFacilityUrl + "/sales-channel/product", params, useGlobalLoader);
}
