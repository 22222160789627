import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { IProduct, IVariant, TProductVendor } from "redux/reducers/models/product";
import { useAppDispatch } from "hooks/redux";
import Page from "components/page/Page";
import FormLayout from "components/form/FormLayout";
import { Select } from "components/select/index";
import Card from "components/card/Card";
import { IPrimaryPageAction } from "components/page/PageActions";
import "./newReceiving.scss";
import ProductSelect from "components/productModal/ProductSelect";
import Portal from "elements/Portal";
import Popup from "components/popup/Popup";
import ReactDOM from "react-dom";
import DataTable from "../../customer/tabs/houseAccounts/DataTable";
import Input from "components/form/input";
import { ButtonNew as Button } from "components/buttonNew";
import Sheet from "components/sheet/Sheet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios, { CancelToken } from "axios";
import { StatusCode } from "api/protocols";
import { showError, showSuccess } from "redux/actions/ui";
import { cloneDeep } from "lodash";
import { PostInventoryRecords } from "api/rpc/2024-04/facilityAdmin/product/inventory";
import Icon from "components/icon/Icon";
import { GetVendor } from "api/rpc/2024-04/facilityAdmin/product/vendor";
import { TSku } from "../inventoryCounts/NewInventoryCount";
import { findSkuProduct } from "../inventoryCounts/inventoryHelpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Spin from "components/spin/spin";

interface IProductState {
  selectedProducts: Array<IProduct>;
  finalSelectedProducts: Array<IProduct>;
  skusNotFound: Array<TSku>;
  openModal: boolean;
}

interface ISkuState {
  skus: Array<TSku>;
  skuString: string;
}

/**Extra details on the receving
 * Ex: Vendor and Packing Slip
 */
interface IDetailState {
  packingSlip: string;

  vendor: TProductVendor;
  vendorQuery: string;
  searching: boolean;
  vendors: Array<TProductVendor>;
}

enum TransferPage {
  InventoryDetails = 0,
  InventorySelection = 1,
  InventoryConfirmation = 2,
}

export default function NewReceiving() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { Option } = Select;
  const [pageTitle, setPageTitle] = useState<{ title: string; subtitle: string }>({ title: "", subtitle: "" });

  const [productState, setProductState] = useState<IProductState>({
    selectedProducts: [],
    finalSelectedProducts: [],
    skusNotFound: [],
    openModal: false,
  });

  const [skuState, setSkuState] = useState<ISkuState>({
    skus: [],
    skuString: "",
  });

  const [detailState, setDetailState] = useState<IDetailState>({
    packingSlip: "",

    vendor: null,
    vendorQuery: "",
    searching: false,
    vendors: [],
  });

  const [variantNotFoundState, setVariantNotFoundState] = useState<{ modalOpen: boolean; sku: string }>({
    modalOpen: false,
    sku: "",
  });

  const [page, setPage] = useState<TransferPage>(TransferPage.InventoryDetails);

  const [submitPopupState, setSubmitPopupState] = useState<boolean>(false);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (page === TransferPage.InventorySelection) {
      setProductState(prevState => ({ ...prevState, finalSelectedProducts: [], skusNotFound: [] }));
    } else if (page === TransferPage.InventoryConfirmation) {
      setProductState(prevState => ({
        ...prevState,
        finalSelectedProducts: cloneDeep(productState.selectedProducts),
        skusNotFound: cloneDeep(skuState.skus),
      }));
    }
    const pageTitle = generatePageTitle();
    setPageTitle(pageTitle);
    return () => {
      source.cancel();
    };
  }, [page]);

  //Search variants by sku / barcode
  useEffect(() => {
    const source = axios.CancelToken.source();
    const currentSku = skuState?.skuString?.slice();
    const skuIndex = skuState?.skus?.findIndex(sku => sku.sku === currentSku);
    setSkuState(prevState => ({ ...prevState, skuString: "" }));
    if (skuIndex !== -1) {
      const foundSku = skuState.skus[skuIndex];
      // First sku added, search to see if variant exists. Or, sku search hasn't completed yet
      if (foundSku.quantity === 1 || !foundSku?.searched) {
        void findSkuProduct(
          productState.selectedProducts,
          skuState.skus,
          setProductState,
          setSkuState,
          dispatch,
          source.token,
        );
      }
    }
    return () => {
      source.cancel();
    };
  }, [skuState?.skus]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    let timeoutId: NodeJS.Timeout = null;
    timeoutId = global.setTimeout(() => {
      void loadVendors(source.token);
    }, 300);

    return () => {
      source.cancel();
      clearTimeout(timeoutId);
    };
  }, [detailState.vendorQuery]);

  function generatePageTitle() {
    switch (page) {
      case TransferPage.InventoryDetails:
        return {
          title: t("secure.facility.settings.inventory.new_receiving.024"),
          subtitle: t("secure.facility.settings.inventory.new_receiving.002"),
        };
      case TransferPage.InventorySelection:
        return {
          title: t("secure.facility.settings.inventory.new_receiving.001"),
          subtitle: t("secure.facility.settings.inventory.new_receiving.004"),
        };
      case TransferPage.InventoryConfirmation:
        return {
          title: t("secure.facility.settings.inventory.new_receiving.003"),
          subtitle: t("secure.facility.settings.inventory.new_receiving.026"),
        };
      default:
        return { title: t("secure.facility.settings.inventory.new_receiving.005"), subtitle: "" };
    }
  }

  async function loadVendors(token?: CancelToken) {
    //Show loader
    setDetailState(prev => ({ ...prev, vendors: [], searching: true }));

    const res = await GetVendor({ search: detailState.vendorQuery ?? undefined }, token ? false : true, token);
    if (token && token.reason) {
      return;
    }
    if (res.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.inventory.new_receiving.006")));
    }
    setDetailState(prev => ({ ...prev, vendors: res.status !== StatusCode.OK ? [] : res.data, searching: false }));
  }

  function handleEnterKeydown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      void addSku();
    }
  }

  async function handleCreateNewReceiving() {
    const variants: Array<IVariant> = [];
    productState?.finalSelectedProducts?.forEach(product => {
      variants?.push(...[...product?.variants]);
    });
    const formattedVariants = variants?.map(variant => ({
      variant_id: variant?.id,
      quantity_receiving: variant?.quantity,
    }));

    const res = await PostInventoryRecords(
      {
        variants: formattedVariants,
        vendor_id: detailState?.vendor?.id ?? undefined,
        packing_slip_number: detailState?.packingSlip ?? undefined,
      },
      true,
    );

    if (res.status !== StatusCode.OK) {
      dispatch(showError(t("secure.facility.settings.inventory.new_receiving.008")));
      return;
    }
    dispatch(showSuccess(t("secure.facility.settings.inventory.inventory.005")));
    setSubmitPopupState(false);
    history.push("/admin/settings/inventory");
  }

  function addSku() {
    if (skuState.skuString) {
      const updatedSkus = [...skuState.skus];
      const productIncremented = incrementProductQuantity(skuState.skuString);
      if (!productIncremented) {
        const foundSkuIndex = updatedSkus?.findIndex(skuObj => skuObj.sku === skuState.skuString);
        if (foundSkuIndex !== -1) {
          //Increment quantity of sku found
          const foundSku = updatedSkus[foundSkuIndex];
          updatedSkus.splice(foundSkuIndex, 1, { ...foundSku, quantity: ++foundSku.quantity });
        } else {
          //Add new sku
          const newSku: TSku = { sku: skuState.skuString, quantity: 1, searched: false };
          updatedSkus.push(newSku);
        }
        setSkuState(prevState => ({ ...prevState, skus: updatedSkus }));
      } else {
        setSkuState(prevState => ({ ...prevState, skuString: "" }));
      }
    }
  }

  function incrementProductQuantity(currentSku: string) {
    const products = [...productState?.selectedProducts];
    let productInList = false;
    let variantIndex: number = null;
    const productIndex = products?.findIndex(product => {
      const foundVariantIndex = product?.variants?.findIndex(
        variant => variant?.sku === currentSku || variant?.barcode === currentSku,
      );
      if (foundVariantIndex !== -1) {
        variantIndex = foundVariantIndex;
        return true;
      }
    });
    if (productIndex !== -1 && variantIndex !== -1) {
      // Product already in list, increment the quantity
      const foundVariant = products[productIndex]?.variants[variantIndex];
      products[productIndex].variants[variantIndex] = { ...foundVariant, quantity: ++foundVariant.quantity };
      productInList = true;
      setProductState(prevState => ({ ...prevState, selectedProducts: products }));
    }
    return productInList;
  }

  function handleProductSelect(products: Array<IProduct>) {
    const selectedProducts = [...products];
    selectedProducts?.forEach((product, productIndex) => {
      product?.variants?.forEach((variant, variantIndex) => {
        if (variant?.quantity == null) {
          selectedProducts[productIndex].variants[variantIndex] = { ...variant, quantity: 1 };
        }
      });
    });
    const { updatedSkus, updatedProducts } = updateSkus(selectedProducts);
    ReactDOM.unstable_batchedUpdates(() => {
      setProductState(prevState => ({ ...prevState, selectedProducts: updatedProducts }));
      setSkuState(prevState => ({ ...prevState, skus: updatedSkus }));
    });
  }

  function updateSkus(products: Array<IProduct>) {
    const updatedSkus = [...skuState.skus];
    const updatedProducts = [...products];
    updatedProducts?.forEach((product, productIndex) => {
      product?.variants?.forEach((variant, variantIndex) => {
        const skuIndex = updatedSkus?.findIndex(sku => sku.sku === variant?.sku || sku.sku === variant?.barcode);
        if (skuIndex !== -1) {
          updatedProducts[productIndex].variants[variantIndex] = {
            ...variant,
            quantity: Number(variant.quantity) + Number(updatedSkus[skuIndex]?.quantity),
          };
          updatedSkus.splice(skuIndex, 1);
        }
      });
    });
    return { updatedSkus: updatedSkus, updatedProducts: updatedProducts };
  }

  function handleEnterClick() {
    const skuSearchId = document.getElementById("skuSearchId");
    skuSearchId.focus();
    void addSku();
  }

  function removeSku(index: number) {
    const updatedSkus = [...skuState.skus];
    // Sku cannot be removed if search is in progress
    const allSkusSearched = updatedSkus?.every(sku => sku.searched);
    if (updatedSkus[index] && allSkusSearched) {
      updatedSkus.splice(index, 1);
      setSkuState(prevState => ({ ...prevState, skus: updatedSkus }));
    }
  }

  function removeVariant(
    productIndex: number,
    key: keyof Pick<IProductState, "finalSelectedProducts" | "selectedProducts">,
    variantIndex?: number,
  ) {
    const updatedProducts = [...productState[key]];
    //Product with single variant
    if (variantIndex == null || updatedProducts[productIndex]?.variants?.length === 1) {
      updatedProducts.splice(productIndex, 1);
    } else {
      // Product with multiple variants
      if (updatedProducts[productIndex]?.variants[variantIndex]) {
        updatedProducts[productIndex]?.variants?.splice(variantIndex, 1);
      }
    }
    setProductState(prevState => ({ ...prevState, [key]: updatedProducts }));
  }

  function handleInputChange(
    e: React.ChangeEvent<HTMLInputElement>,
    key: keyof Pick<IProductState, "finalSelectedProducts" | "selectedProducts">,
    productIndex: number,
    variantIndex: number,
  ) {
    const { valueAsNumber } = e.target;
    const updatedProducts = [...productState[key]];
    const updatedVariant = updatedProducts[productIndex].variants[variantIndex];
    updatedProducts[productIndex].variants[variantIndex] = {
      ...updatedVariant,
      quantity: isNaN(valueAsNumber) ? null : valueAsNumber,
    };
    setProductState(prevState => ({ ...prevState, [key]: updatedProducts }));
  }

  const disablePrimaryAction =
    (page === TransferPage.InventorySelection &&
      (productState?.selectedProducts?.length === 0 ||
        skuState?.skus?.some(sku => !sku.searched) ||
        productState?.selectedProducts?.some(product => product?.variants?.some(variant => !variant.quantity)))) ||
    (page === TransferPage.InventoryConfirmation &&
      (productState.finalSelectedProducts?.length === 0 ||
        productState?.finalSelectedProducts?.some(product =>
          product?.variants?.some(variant => !variant.quantity),
        )));

  const continueAction: IPrimaryPageAction = {
    content: t("secure.facility.settings.inventory.new_receiving.009"),
    action: () => setPage(prevTab => ++prevTab),
    disabled: disablePrimaryAction,
  };

  const submitAction: IPrimaryPageAction = {
    content: t("secure.facility.settings.inventory.new_receiving.010"),
    action: () => setSubmitPopupState(true),
  };

  return (
    <Page
      title={pageTitle.title}
      subtitle={pageTitle.subtitle}
      primaryAction={page === TransferPage.InventoryConfirmation ? submitAction : continueAction}
      secondaryActions={
        page !== TransferPage.InventoryDetails
          ? [
              {
                content: `${t("secure.facility.settings.inventory.new_receiving.011")} ${page}`,
                action: () => setPage(prev => --prev),
              },
            ]
          : []
      }
      breadcrumbs={[
        {
          prefix: true,
          label: t("secure.facility.settings.inventory.new_receiving.012"),
          url: "/admin/settings/inventory",
        },
      ]}
    >
      {page === TransferPage.InventoryDetails && (
        <Card>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                {detailState?.vendor ? (
                  <>
                    <div>
                      <p className="inventory-receiving-vendor-label">
                        {t("secure.facility.settings.inventory.new_receiving.017")}
                      </p>
                    </div>
                    <div className="inventory-receiving-vendor-name">
                      <div>{detailState?.vendor?.title}</div>
                      <button
                        onClick={() =>
                          setDetailState(prevState => ({
                            ...prevState,
                            vendor: null,
                            vendorQuery: "",
                          }))
                        }
                      >
                        <Icon style="far" icon="times" />
                      </button>
                    </div>
                  </>
                ) : (
                  <Select
                    label={t("secure.facility.settings.inventory.new_receiving.017")}
                    onChange={(value: number, vendor: TProductVendor) =>
                      setDetailState(prevState => ({ ...prevState, vendor }))
                    }
                    showSearch
                    searchValue={detailState.vendorQuery}
                    searching={detailState.searching}
                    onSearch={(query: string) => setDetailState(prevState => ({ ...prevState, vendorQuery: query }))}
                    showDropDownOnFocus
                    placeholder={t("secure.facility.settings.inventory.new_receiving.027")}
                  >
                    {detailState.vendors?.map((vendor, index) => {
                      return (
                        <Option key={index} value={vendor?.id} extraValues={vendor}>
                          {vendor?.title}
                        </Option>
                      );
                    })}
                  </Select>
                )}
                <Input
                  value={detailState.packingSlip}
                  label={t("secure.facility.settings.inventory.new_receiving.020")}
                  placeholder="012345"
                  onChange={e => setDetailState(prevState => ({ ...prevState, packingSlip: e.target.value }))}
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>
      )}

      {page === TransferPage.InventorySelection && (
        <Card>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Input
                  id="skuSearchId"
                  onKeyDown={handleEnterKeydown}
                  value={skuState.skuString}
                  placeholder={t("secure.facility.settings.inventory.new_receiving.031")}
                  onChange={e => setSkuState(prevState => ({ ...prevState, skuString: e.target.value }))}
                  trailingButtons={[
                    <Button key={1} onClick={handleEnterClick} type="secondary">
                      {t("secure.facility.settings.inventory.new_receiving.014")}
                    </Button>,
                    <Button
                      key={2}
                      type="primary"
                      size="medium"
                      icon={<FontAwesomeIcon className="ml-2" icon={["far", "magnifying-glass"]} />}
                      onClick={() => setProductState(prevState => ({ ...prevState, openModal: true }))}
                    >
                      {t("secure.facility.settings.inventory.new_receiving.015")}
                    </Button>,
                  ]}
                />
              </FormLayout.Group>
            </FormLayout>
            <DataTable
              columns={[
                ...(productState?.selectedProducts?.length > 0 ? [{ label: "Title" }, { label: "Vendor" }] : []),
                ...(productState?.selectedProducts?.length > 0
                  ? [{ label: "Sku" }, { label: "Barcode" }]
                  : [{ label: t("secure.facility.settings.inventory.new_receiving.031") }]),
                {
                  label: t("secure.facility.settings.inventory.new_receiving.019"),
                  content: (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="headingTooltip">{`${t(
                          "secure.facility.settings.inventory.new_receiving.032",
                        )}`}</Tooltip>
                      }
                    >
                      <div>
                        <span className="inventory-receiving-quantity-label">
                          {t("secure.facility.settings.inventory.new_receiving.019")}{" "}
                        </span>
                        <FontAwesomeIcon size="sm" icon={["fas", "square-info"]} />
                      </div>
                    </OverlayTrigger>
                  ),
                },
                { label: "" },
                { label: "" },
              ]}
            >
              {skuState?.skus?.map((sku, index) => {
                return (
                  <tr key={index}>
                    {productState?.selectedProducts?.length > 0 && (
                      <>
                        <td></td>
                        <td></td>
                        <td>{sku?.sku}</td>
                      </>
                    )}
                    <td>{sku?.sku}</td>
                    <td>{sku?.quantity}</td>
                    <td className="text-right">
                      {!sku.searched ? (
                        <div style={{ display: "inline-flex" }}>
                          <Spin />
                        </div>
                      ) : (
                        <OverlayTrigger
                          placement="auto"
                          overlay={
                            <Tooltip id="headingTooltip">{`${t(
                              "secure.facility.settings.inventory.new_receiving.033",
                            )}`}</Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            className="inventory-receiving-error"
                            size="1x"
                            icon={["fas", "circle-xmark"]}
                            onClick={() => setVariantNotFoundState({ modalOpen: true, sku: sku.sku })}
                          />
                        </OverlayTrigger>
                      )}
                    </td>
                    <td className="text-right">
                      <FontAwesomeIcon
                        onClick={() => removeSku(index)}
                        icon={["far", "trash-can-xmark"]}
                        size="1x"
                        className="cursor-pointer"
                      />
                    </td>
                  </tr>
                );
              })}
              {productState?.selectedProducts?.map((product, productIndex) => {
                return (
                  <React.Fragment key={productIndex}>
                    <tr className="inventory-receiving-product-header">
                      <td>{product?.preferred_title ? product?.preferred_title : product.title}</td>
                      <td>{product.vendor_title}</td>
                      <td>{product?.variant_count === 1 && product?.variants[0]?.sku}</td>
                      <td>{product?.variant_count === 1 && product?.variants[0]?.barcode}</td>
                      <td>{product?.variant_count === 1 && product?.variants[0]?.quantity}</td>
                      <td className="text-right">
                        {product?.variant_count === 1 && (
                          <OverlayTrigger
                            placement="auto"
                            overlay={
                              <Tooltip id="headingTooltip">{`${t(
                                "secure.facility.settings.inventory.new_receiving.039",
                              )}`}</Tooltip>
                            }
                          >
                            <FontAwesomeIcon
                              className="inventory-receiving-success"
                              size="1x"
                              icon={["fas", "circle-check"]}
                            />
                          </OverlayTrigger>
                        )}
                      </td>
                      <td className="text-right">
                        {product?.variant_count === 1 && (
                          <FontAwesomeIcon
                            onClick={() => removeVariant(productIndex, "selectedProducts")}
                            icon={["far", "trash-can-xmark"]}
                            size="1x"
                            className="cursor-pointer"
                          />
                        )}
                      </td>
                    </tr>
                    {product?.variant_count > 1 &&
                      product?.variants?.map((variant, variantIndex) => (
                        <tr key={variantIndex}>
                          <td style={{ textIndent: "3rem" }}>{variant?.title}</td>
                          <td></td>
                          <td>{variant?.sku}</td>
                          <td>{variant?.barcode}</td>
                          <td>{variant?.quantity}</td>
                          <td className="text-right">
                            {
                              <OverlayTrigger
                                placement="auto"
                                overlay={
                                  <Tooltip id="headingTooltip">{`${t(
                                    "secure.facility.settings.inventory.new_receiving.039",
                                  )}`}</Tooltip>
                                }
                              >
                                <FontAwesomeIcon
                                  className="inventory-receiving-success"
                                  size="1x"
                                  icon={["fas", "circle-check"]}
                                />
                              </OverlayTrigger>
                            }
                          </td>
                          <td className="text-right">
                            <FontAwesomeIcon
                              onClick={() => removeVariant(productIndex, "selectedProducts", variantIndex)}
                              icon={["far", "trash-can-xmark"]}
                              size="1x"
                              className="cursor-pointer"
                            />
                          </td>
                        </tr>
                      ))}
                  </React.Fragment>
                );
              })}
            </DataTable>
          </Card.Section>
        </Card>
      )}

      {page === TransferPage.InventoryConfirmation && (
        <>
          <Card>
            <Card.Section>
              <DataTable
                columns={[
                  { label: t("secure.facility.settings.inventory.new_receiving.016") },
                  { label: t("secure.facility.settings.inventory.new_receiving.017") },
                  { label: t("secure.facility.settings.inventory.new_receiving.018") },
                  { label: t("secure.facility.settings.inventory.new_receiving.034") },
                  { label: t("secure.facility.settings.inventory.new_receiving.019") },
                  { label: "" },
                ]}
                loading={!productState.finalSelectedProducts}
              >
                {productState?.finalSelectedProducts?.map((product, productIndex) => {
                  return (
                    <React.Fragment key={productIndex}>
                      <tr className="inventory-receiving-product-header">
                        <td>{product?.preferred_title ? product?.preferred_title : product.title}</td>
                        <td>{product.vendor_title}</td>
                        <td>{product?.variant_count === 1 && product?.variants[0]?.sku}</td>
                        <td>{product?.variant_count === 1 && product?.variants[0]?.barcode}</td>
                        <td style={{ width: "15%" }}>
                          {product?.variant_count === 1 && (
                            <Input
                              type="number"
                              labelHidden
                              onChange={e => handleInputChange(e, "finalSelectedProducts", productIndex, 0)}
                              value={product?.variants[0]?.quantity ?? ""}
                              placeholder={t("secure.facility.settings.inventory.new_receiving.019")}
                            />
                          )}
                        </td>
                        <td>
                          {product?.variant_count === 1 && (
                            <FontAwesomeIcon
                              onClick={() => removeVariant(productIndex, "finalSelectedProducts")}
                              icon={["far", "trash-can-xmark"]}
                              size="1x"
                              className="cursor-pointer"
                            />
                          )}
                        </td>
                      </tr>
                      {product?.variant_count > 1 &&
                        product?.variants?.map((variant, variantIndex) => (
                          <tr key={variantIndex}>
                            <td style={{ textIndent: "3rem" }}>{variant?.title}</td>
                            <td></td>
                            <td>{variant?.sku}</td>
                            <td>{variant?.barcode}</td>
                            <td style={{ width: "15%" }}>
                              <Input
                                type="number"
                                labelHidden
                                onChange={e =>
                                  handleInputChange(e, "finalSelectedProducts", productIndex, variantIndex)
                                }
                                value={variant?.quantity ?? ""}
                                placeholder={t("secure.facility.settings.inventory.new_receiving.019")}
                              />
                            </td>
                            <td>
                              <FontAwesomeIcon
                                onClick={() => removeVariant(productIndex, "finalSelectedProducts", variantIndex)}
                                icon={["far", "trash-can-xmark"]}
                                size="1x"
                                className="cursor-pointer"
                              />
                            </td>
                          </tr>
                        ))}
                    </React.Fragment>
                  );
                })}
              </DataTable>
            </Card.Section>
            {productState?.skusNotFound?.length > 0 && (
              <Card.Section title={t("secure.facility.settings.inventory.new_receiving.035")}>
                <DataTable
                  columns={[
                    { label: t("secure.facility.settings.inventory.new_receiving.031") },
                    { label: t("secure.facility.settings.inventory.new_receiving.019") },
                  ]}
                >
                  {productState?.skusNotFound?.map((sku, index) => {
                    return (
                      <tr key={index}>
                        <td>{sku.sku}</td>
                        <td>{sku.quantity}</td>
                      </tr>
                    );
                  })}
                </DataTable>
              </Card.Section>
            )}
          </Card>
        </>
      )}
      <div className="inventory-receiving-products-modal">
        <Sheet
          open={productState.openModal}
          title={t("secure.facility.settings.inventory.new_receiving.028")}
          size="medium"
          onCancel={() => setProductState(prevState => ({ ...prevState, openModal: false }))}
          cancelText={t("secure.facility.settings.inventory.new_receiving.029")}
          closable
          onOk={() => setProductState(prevState => ({ ...prevState, openModal: false }))}
          okText={t("secure.facility.settings.inventory.new_receiving.030")}
        >
          <ProductSelect
            selectedProducts={productState.selectedProducts}
            onChange={handleProductSelect}
            search
            getProductsParams={{ track_inventory: true, all: true }}
            variantInput={{ key: "quantity", placeholder: t("secure.facility.settings.inventory.new_receiving.019") }}
          />
        </Sheet>
      </div>
      <Portal isMounted={submitPopupState}>
        <Popup
          open={submitPopupState}
          onOk={() => handleCreateNewReceiving()}
          onCancel={() => setSubmitPopupState(false)}
          okText={t("secure.facility.settings.inventory.new_receiving.030")}
          type="info"
          title={t("secure.facility.settings.inventory.new_receiving.022")}
          description={t("secure.facility.settings.inventory.new_receiving.023")}
        />
      </Portal>
      <Portal isMounted={variantNotFoundState.modalOpen}>
        <Popup
          open={variantNotFoundState.modalOpen}
          type="warning"
          title={t("secure.facility.settings.inventory.new_receiving.033")}
          onOk={() => setVariantNotFoundState({ modalOpen: false, sku: "" })}
          okText={t("secure.facility.settings.inventory.new_receiving.029")}
          description=""
          closable
          backDropCancel
        >
          <p className="mb-2">
            {t("secure.facility.settings.inventory.new_receiving.036")} {<b>{variantNotFoundState.sku}</b>}{" "}
            {t("secure.facility.settings.inventory.new_receiving.037")}
          </p>
          <p className="mb-2">{t("secure.facility.settings.inventory.new_receiving.038")}</p>
        </Popup>
      </Portal>
    </Page>
  );
}
