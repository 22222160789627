import React, { useEffect, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import axios, { CancelToken } from "axios";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { ITournament, ITournamentRegistrationFee } from "redux/reducers/models/tournament";
import { GetTournament, TPutTournament, PutTournament } from "api/rpc/2024-04/facilityAdmin/tournament/tournament";
import { StatusCode } from "api/protocols";
import { showError, showSuccess } from "redux/actions/ui";
import { GetTournamentRegistrationFees } from "api/rpc/2024-04/facilityAdmin/tournament/registration/registration";
import { formatDate, handleChangeEventInput } from "helpers/Helpers";
import useRegisterDates, { IRegisterDate } from "hooks/useDateTime/useDateTime";

import Page from "components/page/Page";
import Tabs from "components/tabs/Tabs";
import FormLayout from "components/form/FormLayout";
import RegistrationFeeTab from "./tabs/fee/RegistrationFeeTab";
import { ButtonNew as Button } from "components/buttonNew";
import DatePickerInput from "components/datePickerInput/DatePickerInput";
import TimePick from "components/timePick/TimePick";
import Card from "components/card/Card";
import Toggle from "components/form/toggle/Toggle";
import Input from "components/form/input/Input";

import "./tournamentRegistrationLanding.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ITournamentRegistrationAmountRange {
  min: number;
  max: number;
}

export default function TournamentRegistrationLanding() {
  const { tournamentId } = useParams<{ tournamentId: string }>();
  const { t } = useTranslation();
  const { facilityStore } = useAppSelector(store => store);

  const dispatch = useAppDispatch();

  const [tabIndex, setTabIndex] = useState(0);
  const [tournamentDetails, setTournamentDetails] = useState<ITournament>(undefined);

  const {
    registerOpen,
    registerClose,
    registerDateRangeValid,
    registerTimeRangeValid,
    updateRegisterOpen,
    updateRegisterClose,
  } = useRegisterDates();

  const [registrationFee, setRegistrationFee] = useState({
    loadTrigger: false,
    fees: undefined as ITournamentRegistrationFee[],
    addons: undefined as ITournamentRegistrationFee[],
  });

  const [link, setLink] = useState({
    shortName: "",
    handle: "",
    registerLink: "",
    leaderboardLink: "",
  });

  const [registrationAmountRange, setRegistrationAmountRange] = useState<ITournamentRegistrationAmountRange>({
    min: null,
    max: null,
  });

  const pageTabs = [
    {
      id: "tournament_registration-general",
      content: "General",
      disabled: false,
    },
    {
      id: "tournament_registration-fees",
      content: "Fees",
      disabled: !tournamentDetails?.registration_enabled,
    },
  ];

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (facilityStore.facility) {
      void loadTournament(tournamentId, source.token);
    }

    return () => {
      source.cancel();
    };
  }, [tournamentId, facilityStore]);

  useEffect(() => {
    setLink(prevState => ({ ...prevState, shortName: facilityStore?.facility?.short_name }));
  }, [facilityStore.facility?.short_name]);

  // define registration fee state only when toggled
  useEffect(() => {
    const source = axios.CancelToken.source();
    if (registrationFee.loadTrigger) {
      void loadTournamentRegistrationFees(tournamentId, source.token);
    }
    return () => {
      source.cancel();
    };
  }, [registrationFee.loadTrigger, tournamentId]);

  function getRegisterDateFromDateString(dateString: string, timezone: string): IRegisterDate {
    if (dateString) {
      const facilityDate = moment.utc(dateString).tz(timezone);

      const date = new Date(facilityDate.year(), facilityDate.month(), facilityDate.date());

      const time = facilityDate.format("HH:mm");

      return {
        date,
        time,
      };
    } else {
      return null;
    }
  }

  async function loadTournament(id: string | number, token?: CancelToken) {
    const res = await GetTournament({ id: id }, token ? false : true, token);
    if (token && token.reason) {
      return;
    }

    if (res.status !== StatusCode.OK || res.data.length === 0) {
      return;
    }

    setTournamentDetails(res.data[0]);

    setLink(prevState => ({
      ...prevState,
      handle: res?.data[0]?.handle,
    }));

    setRegistrationAmountRange(prev => ({
      ...prev,
      min: res.data?.[0]?.minimum_registration_amount,
      max: res.data?.[0]?.maximum_registration_amount,
    }));

    const updatedRegisterOpen = getRegisterDateFromDateString(
      res.data[0]?.registration_open_date,
      facilityStore.facility?.timezone,
    );
    updateRegisterOpen(updatedRegisterOpen);

    const updatedRegisterClose = getRegisterDateFromDateString(
      res.data[0]?.registration_close_date,
      facilityStore.facility?.timezone,
    );
    updateRegisterClose(updatedRegisterClose);

    if (res.data[0].registration_enabled) {
      setRegistrationFee(prev => ({ ...prev, loadTrigger: true }));
    }
  }

  async function loadTournamentRegistrationFees(id: string | number, token?: CancelToken) {
    if (registrationFee.fees !== undefined) {
      setRegistrationFee(prev => ({ ...prev, fees: undefined }));
    }

    if (registrationFee.addons !== undefined) {
      setRegistrationFee(prev => ({ ...prev, addons: undefined }));
    }

    const res = await GetTournamentRegistrationFees({ tournament_id: id }, token ? false : true, token);

    if (token && token.reason) {
      return;
    }

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error loading registration fees."));
    }

    setRegistrationFee(prev => ({
      ...prev,
      loadTrigger: false,
      fees: res.status !== StatusCode.OK ? [] : res.data.filter((fee: any) => fee.type === "registration_fee"),
      addons: res.status !== StatusCode.OK ? [] : res.data.filter((fee: any) => fee.type === "add_on"),
    }));
  }

  /**
   * Handles Toggle element state before and after PUTTournament call
   * - PUTTournament registration_enabled + GETTournamentRegistrationFees trigger
   * */
  async function toggleTournamentRegistration(toggled: boolean) {
    setTournamentDetails(prev => ({ ...prev, registration_enabled: toggled }));
    const res = await PutTournament({ id: tournamentId, registration_enabled: toggled }, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError("Error enabling registration."));
      setTournamentDetails(prev => ({ ...prev, registration_enabled: !toggled }));
      return;
    }

    // allow CancelToken with call
    setRegistrationFee(prev => ({ ...prev, loadTrigger: true }));
  }

  async function updateTournament(args: Omit<TPutTournament, "id">) {
    const registrationOpenTime = registerOpen?.time?.split(":");

    const registrationOpenDate =
      !registerOpen?.date || !registerOpen?.time
        ? null
        : moment
            .tz(
              [
                registerOpen.date.getFullYear(),
                registerOpen.date.getMonth(),
                registerOpen.date.getDate(),
                Number(registrationOpenTime?.[0]),
                Number(registrationOpenTime?.[1]),
              ],
              facilityStore.facility?.timezone,
            )
            .utc()
            .format("YYYY-MM-DD HH:mm:ss");

    const registrationCloseTime = registerClose?.time?.split(":");

    const registrationCloseDate =
      !registerClose?.date || !registerClose?.time
        ? null
        : moment
            .tz(
              [
                registerClose.date.getFullYear(),
                registerClose.date.getMonth(),
                registerClose.date.getDate(),
                Number(registrationCloseTime?.[0]),
                Number(registrationCloseTime?.[1]),
              ],
              facilityStore.facility?.timezone,
            )
            .utc()
            .format("YYYY-MM-DD HH:mm:ss");

    const params: TPutTournament = {
      id: tournamentId,
      registration_open_date: registrationOpenDate,
      registration_close_date: registrationCloseDate,
      minimum_registration_amount: registrationAmountRange.min,
      maximum_registration_amount: registrationAmountRange.max,
      ...args,
    };

    const res = await PutTournament(params, true);

    if (res.status !== StatusCode.OK) {
      dispatch(showError(typeof res.data === "string" ? res.data : "Error updating tournament."));
      return;
    }

    dispatch(showSuccess("Successfully updated tournament."));
    setTournamentDetails(prev => ({ ...prev, ...res?.data }));
  }

  // When player_limit is empty, remove waitlist_limit value
  function handlePlayerLimitChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.value && e.target.value == "0" && tournamentDetails.waitlist_limit) {
      setTournamentDetails(prev => ({ ...prev, waitlist_limit: null }));
    }
    handleChangeEventInput(e, tournamentDetails, setTournamentDetails);
  }

  function handleRegistrationAmountChange(e: React.ChangeEvent<HTMLInputElement>) {
    handleChangeEventInput(e, registrationAmountRange, setRegistrationAmountRange);
  }

  async function writeTextToClipBoard(link: string) {
    await navigator.clipboard.writeText(link);
    dispatch(showSuccess(t("secure.facility.tournament.tournament.003")));
  }

  function toggleRegisterOpen(checked: boolean) {
    if (!checked) {
      updateRegisterOpen(null);
    } else {
      if (!tournamentDetails?.registration_open_date) {
        updateRegisterOpen({
          date: new Date(),
          time: "08:00",
        });
      } else {
        const updatedRegisterOpen = getRegisterDateFromDateString(
          tournamentDetails?.registration_open_date,
          facilityStore.facility?.timezone,
        );
        updateRegisterOpen(updatedRegisterOpen);
      }
    }
  }

  function toggleRegisterClose(checked: boolean) {
    if (!checked) {
      updateRegisterClose(null);
    } else {
      if (!tournamentDetails?.registration_close_date) {
        updateRegisterClose({
          date: new Date(),
          time: "20:00",
        });
      } else {
        const updatedRegisterClose = getRegisterDateFromDateString(
          tournamentDetails?.registration_close_date,
          facilityStore.facility?.timezone,
        );
        updateRegisterClose(updatedRegisterClose);
      }
    }
  }

  function toggleRegistrationAmountRangeMinimum(checked: boolean) {
    if (!checked) {
      setRegistrationAmountRange(prev => ({ ...prev, min: null }));
    } else {
      setRegistrationAmountRange(prev => ({ ...prev, min: tournamentDetails.minimum_registration_amount ?? 2 }));
    }
  }

  function toggleRegistrationAmountRangeMaximum(checked: boolean) {
    if (!checked) {
      setRegistrationAmountRange(prev => ({ ...prev, max: null }));
    } else {
      setRegistrationAmountRange(prev => ({ ...prev, max: tournamentDetails.maximum_registration_amount ?? 4 }));
    }
  }

  return (
    <Page title={"Registration"}>
      <Tabs tabs={pageTabs} selected={tabIndex} onSelect={index => setTabIndex(index)}>
        {tabIndex === 0 && (
          <>
            <div className="tournament_registration-save_btn">
              <Button
                type="primary"
                disabled={
                  !registerDateRangeValid ||
                  !registerTimeRangeValid ||
                  registrationAmountRange.min < 0 ||
                  registrationAmountRange.max < 0 ||
                  (registrationAmountRange.min != null &&
                    registrationAmountRange.max != null &&
                    registrationAmountRange.min > registrationAmountRange.max)
                }
                onClick={e =>
                  updateTournament({
                    player_limit: tournamentDetails.player_limit,
                    waitlist_limit: tournamentDetails.waitlist_limit,
                  })
                }
              >
                {"Save"}
              </Button>
            </div>
            <Card>
              <Card.Section title="General Settings">
                <FormLayout.Group>
                  <div className="tournament_registration-toggle">
                    <Toggle
                      id="registration_enabled"
                      labelRight="Enable Registration"
                      checked={tournamentDetails ? tournamentDetails.registration_enabled : false}
                      onChange={e => toggleTournamentRegistration(e.target.checked)}
                    />
                  </div>
                  {tournamentDetails?.registration_enabled ? (
                    <div className="tournament_registration-limit_inputs">
                      <Input
                        id="player_limit"
                        value={!tournamentDetails.player_limit ? 0 : tournamentDetails.player_limit}
                        type="number"
                        onChange={handlePlayerLimitChange}
                        label={"Player Limit"}
                      />
                      <div>
                        {!!tournamentDetails.player_limit && tournamentDetails.player_limit !== 0 ? (
                          <Input
                            id="waitlist_limit"
                            value={!tournamentDetails.waitlist_limit ? 0 : tournamentDetails.waitlist_limit}
                            type="number"
                            onChange={e => handleChangeEventInput(e, tournamentDetails, setTournamentDetails)}
                            label={"WaitList Limit"}
                          />
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                </FormLayout.Group>
              </Card.Section>
            </Card>
            <Card>
              <Card.Section title="Registration Amount Range">
                <div className="mt-3">
                  <FormLayout.Group>
                    <Toggle
                      labelRight="Set Minimum"
                      checked={registrationAmountRange.min != null}
                      onChange={e => toggleRegistrationAmountRangeMinimum(e.target.checked)}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    {registrationAmountRange.min != null && (
                      <div className="tournament_registration-amount-range-input-container">
                        <Input
                          id="min"
                          value={!registrationAmountRange.min ? 0 : registrationAmountRange.min}
                          type="number"
                          onChange={handleRegistrationAmountChange}
                          label="Minimum"
                        />
                      </div>
                    )}
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <Toggle
                      labelRight="Set Maximum"
                      checked={registrationAmountRange.max != null}
                      onChange={e => toggleRegistrationAmountRangeMaximum(e.target.checked)}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    {registrationAmountRange.max != null && (
                      <div className="tournament_registration-amount-range-input-container">
                        <Input
                          id="max"
                          value={!registrationAmountRange.max ? 0 : registrationAmountRange.max}
                          type="number"
                          onChange={handleRegistrationAmountChange}
                          label="Maximum"
                        />
                      </div>
                    )}
                  </FormLayout.Group>
                </div>
              </Card.Section>
            </Card>
            <Card>
              <Card.Section title="Registration Dates">
                <div className="mt-3">
                  <FormLayout.Group>
                    <Toggle
                      labelRight="Set Open Date"
                      checked={!!registerOpen?.date}
                      onChange={e => toggleRegisterOpen(e.target.checked)}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    {registerOpen && (
                      <div className="tournament_registration-date-inputs">
                        <div style={{ flex: 1 }}>
                          <DatePickerInput
                            months={1}
                            position={"left"}
                            label={"Open Date"}
                            startingDate={registerOpen?.date}
                            setStartingDate={selectedDate =>
                              updateRegisterOpen({ date: selectedDate as Date, time: registerOpen?.time })
                            }
                            error={!registerDateRangeValid}
                            showBlurTitleOnError
                          />
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            alignItems: "center",
                          }}
                        >
                          <TimePick
                            value={registerOpen?.time}
                            onChange={timeString =>
                              updateRegisterOpen({ date: registerOpen?.date, time: timeString })
                            }
                            align="right"
                            size="large"
                            label="Open Time"
                            status={!registerTimeRangeValid ? "error" : undefined}
                          />
                        </div>
                      </div>
                    )}
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <Toggle
                      labelRight="Set Close Date"
                      checked={!!registerClose?.date}
                      onChange={e => toggleRegisterClose(e.target.checked)}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    {registerClose && (
                      <div className="tournament_registration-date-inputs">
                        <div style={{ flex: 1 }}>
                          <DatePickerInput
                            months={1}
                            position={"left"}
                            label={"Close Date"}
                            startingDate={registerClose?.date}
                            setStartingDate={selectedDate =>
                              updateRegisterClose({ date: selectedDate as Date, time: registerClose?.time })
                            }
                            error={!registerDateRangeValid}
                            showBlurTitleOnError
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            alignItems: "center",
                          }}
                        >
                          <TimePick
                            value={registerClose?.time}
                            onChange={timeString =>
                              updateRegisterClose({ date: registerClose?.date, time: timeString })
                            }
                            align="right"
                            size="large"
                            label="Close Time"
                            status={!registerTimeRangeValid ? "error" : undefined}
                          />
                        </div>
                      </div>
                    )}
                  </FormLayout.Group>
                </div>
              </Card.Section>
            </Card>

            <Card title="Link">
              <Card.Section>
                <Input
                  disabled={true}
                  value={window.origin + `/tournament/${link?.shortName}/${link?.handle}/`}
                  id="register_link"
                  onChange={e => handleChangeEventInput(e, tournamentDetails, setTournamentDetails)}
                  trailingButtons={[
                    <Button
                      key={1}
                      onClick={() =>
                        writeTextToClipBoard(window.origin + `/tournament/${link?.shortName}/${link?.handle}/`)
                      }
                    >
                      {" "}
                      <FontAwesomeIcon icon={["far", "copy"]} style={{ marginRight: "5px" }} /> Copy{" "}
                    </Button>,
                  ]}
                />
              </Card.Section>
            </Card>
          </>
        )}

        {tabIndex === 1 && (
          <RegistrationFeeTab
            tournamentId={parseInt(tournamentId)}
            fees={registrationFee.fees}
            addons={registrationFee.addons}
            setUpdatedFees={(fees, trigger) =>
              setRegistrationFee(prev => ({ ...prev, fees: fees, loadTrigger: trigger }))
            }
            setUpdatedAddons={(addons, trigger) =>
              setRegistrationFee(prev => ({ ...prev, addons: addons, loadTrigger: trigger }))
            }
          />
        )}
      </Tabs>
    </Page>
  );
}
