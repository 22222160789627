import Sheet from "components/sheet/Sheet";
import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import Checkbox from "components/form/checkbox/Checkbox";
import { IKitchenCourse } from "pages/secure/facility/settings/kitchenCourse/KitchenCourse";

interface ICourseChangeProps {
  openCourseChange: boolean;
  itemActionsState: any;
  kitchenCourses: Array<IKitchenCourse | Partial<IKitchenCourse>>;
  onCancel?: () => void;
  onOk?: (courseChangeInfo: ICourseChangeState) => void;
}

interface ICourseChangeState {
  selectedCourseToChange: number;
}

function CourseChange(props: ICourseChangeProps) {
  const [state, setState] = useState<ICourseChangeState>({
    selectedCourseToChange: null,
  });

  function onOk() {
    void props.onOk({ ...state });
  }

  function onCancel() {
    setState(prevState => ({
      ...prevState,
      selectedCourseToChange: null,
    }));
    props.onCancel();
  }

  function handleCourseSelection(courseId: number) {
    setState(prevState => ({ ...prevState, selectedCourseToChange: courseId }));
  }

  return (
    <Sheet
      title={"Change Course"}
      open={props.openCourseChange}
      size="small"
      closable
      onCancel={onCancel}
      onOk={onOk}
      okText={"Confirm"}
      okDisabled={!state?.selectedCourseToChange}
    >
      <div className="table-service-course-change-container">
        <p className="text-lg">
          {props.itemActionsState?.selectedLineItem?.preferred_title
            ? props.itemActionsState?.selectedLineItem?.preferred_title
            : props.itemActionsState?.selectedLineItem?.product_title}
        </p>
        {props.itemActionsState?.selectedLineItem?.product_title !==
          props.itemActionsState?.selectedLineItem?.variant_title && (
          <p className="text-sm text-subdued">{props.itemActionsState?.selectedLineItem?.variant_title}</p>
        )}
      </div>

      <div className="ui-checkbox-group">
        {props?.kitchenCourses
          ?.filter(filteredCourse => props.itemActionsState?.selectedLineItem?.kitchen_meal_id !== filteredCourse?.id)
          ?.map((course, index) => {
            return (
              <div
                key={index}
                onClick={() => handleCourseSelection(course?.id)}
                className={classNames("ui-checkbox-group-item", {
                  "ui-checkbox-group-item_selected": course?.id === state.selectedCourseToChange,
                })}
              >
                <div className="ui-checkbox-group-item-content">
                  <div className="ui-checkbox-group-item-text">
                    <p>{course?.title}</p>
                  </div>
                </div>
                <Checkbox
                  size="medium"
                  value={course?.id}
                  checked={course?.id === state.selectedCourseToChange}
                  onChange={() => handleCourseSelection(course?.id)}
                />
              </div>
            );
          })}
      </div>
    </Sheet>
  );
}

export default CourseChange;
