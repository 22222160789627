import { APIGet, APIPost, APIPut, APIDelete, StatusCode } from "api/protocols";

const apiVersion = "2022-09";
const newApiVersion = "2024-04";
const adminType = "facility-admin";
const baseUrl = "/" + apiVersion + "/" + adminType + "/facility";
const newBaseUrl = "/" + newApiVersion + "/" + adminType + "/facility";

interface IGetTeeDeck {
  id: number;
}

export interface ITeeDeckRating {
  course_rating: number;
  created_at: string;
  deleted_at: string;
  division_id: number;
  gender: string;
  id: number;
  slope_rating: number;
  tee_deck_id: number;
  updated_at: string;
}

interface IGetTeeDecksResponse {
  data: Array<{
    course_id: number;
    facility_id: number;
    id: number;
    ratings: ITeeDeckRating[];
    title: string;
  }>;
  status: StatusCode;
}

// GET TeeDecks
export async function GetTeeDecks(params: IGetTeeDeck, useGlobalLoader: boolean): Promise<IGetTeeDecksResponse> {
  return await APIGet(baseUrl + "/tee-deck", params, useGlobalLoader);
}

// POST TeeDeck
export async function PostTeeDeck(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseUrl + "/tee-deck", params, useGlobalLoader);
}

// PUT TeeDeck
export async function PutTeeDeck(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseUrl + "/tee-deck", params, useGlobalLoader);
}

// PUT TeeDeck
export async function DeleteTeeDeck(params: any, useGlobalLoader: boolean) {
  return await APIDelete(baseUrl + "/tee-deck", params, useGlobalLoader);
}

// GET Yardages
// GET TeeDecks
export async function GetYardages(params: any, useGlobalLoader: boolean) {
  return await APIGet(newBaseUrl + "/tee-deck/yardages", params, useGlobalLoader);
}

// PUT Yardages
export async function PutYardages(params: any, useGlobalLoader: boolean) {
  return await APIPut(newBaseUrl + "/tee-deck/yardages", params, useGlobalLoader);
}

interface IPostYardages {
  division_id: number;
  tee_deck_id: number;
}

// POST Yardages
export async function PostYardages(params: IPostYardages, useGlobalLoader: boolean) {
  return await APIPost(newBaseUrl + "/tee-deck/yardages", params, useGlobalLoader);
}
