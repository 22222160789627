import { APIGet, APIPost, APIPut, APIDelete, APIUrl, AdminType } from "api/protocols";
import { CancelToken } from "axios";
import { IEvent } from "redux/reducers/models/event";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseEventUrl = "/" + apiVersion + "/" + adminType + "/event";

export type TGetEvent = {
  id?: string | number;
  search?: string; // Search by tournament name
  year?: number;
  limit?: number;
  offset?: number;
};

export type TPostEvent = {
  name: string;
  start_time: string;
  date: string;
  type: string;
};

export type TPutEvent = {
  id: string | number;
  name?: string;
  start_time?: string;
  date?: string | Date;
  registration_deadline?: Date;
  description?: string;
  capacity?: number;
  tee_sheet_note?: string;
  logo_source?: string;
  display_online?: boolean;
};

// GET Event
// Retrieve a list of events
export async function GetEvent(params: TGetEvent, useGlobalLoader: boolean, token?: CancelToken) {
  return await APIGet<IEvent[]>(APIUrl(AdminType.FacilityAdmin, "/event"), params, useGlobalLoader, token);
}

// POST Event
// Create a new event
export async function PostEvent(params: TPostEvent, useGlobalLoader: boolean) {
  return await APIPost<IEvent>(APIUrl(AdminType.FacilityAdmin, "/event"), params, useGlobalLoader);
}

// PUT Event
// Update an existing event
export async function PutEvent(params: TPutEvent, useGlobalLoader: boolean) {
  return await APIPut<IEvent>(APIUrl(AdminType.FacilityAdmin, "/event"), params, useGlobalLoader);
}

// DELETE Event
// Delete an existing event
export async function DeleteEvent(params: { id: string | number }, useGlobalLoader: boolean) {
  return await APIDelete(baseEventUrl, params, useGlobalLoader);
}

//Event Logo
export async function UploadEventLogo(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseEventUrl + "/logo", params, useGlobalLoader);
}

export async function DeleteEventLogo(params: any, useGlobalLoader: boolean) {
  return await APIDelete(baseEventUrl + "/logo", params, useGlobalLoader);
}
