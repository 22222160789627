import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

import { GetTeeSheetTemplate, ITeeSheetTemplate } from "api/rpc/2024-04/facilityAdmin/teesheet/teesheetTemplate";
import { StatusCode } from "api/protocols";

import Page from "components/page/Page";
import Card from "components/card/Card";
import Spin from "components/spin/spin";
import axios, { CancelToken } from "axios";
import { Table } from "react-bootstrap";
import DataTable from "../../../customer/tabs/houseAccounts/DataTable";
import { capitalize } from "helpers/Helpers";

export default function TeeSheetTemplates() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [state, setState] = useState({
    templates: undefined as ITeeSheetTemplate[],
  });

  const columns = ["Name", "Version", "Format", "Interval", "Holes"];

  useEffect(() => {
    const source = axios.CancelToken.source();
    void loadTemplates(source.token);
    return () => {
      source.cancel();
    };
  }, []);

  async function loadTemplates(token?: CancelToken) {
    if (state.templates !== undefined) {
      setState(prev => ({ ...prev, templates: undefined }));
    }

    const templateRes = await GetTeeSheetTemplate(null, token ? false : true, token);
    if (templateRes.status !== StatusCode.OK) {
      setState(prev => ({ ...prev, templates: [] }));
      return;
    }

    setState(prevState => ({
      ...prevState,
      templates: templateRes.data,
    }));
  }

  function navigateTo(url: string) {
    history.push(url);
  }

  function navigateToNewTemplate() {
    history.push("/admin/settings/tee-sheet/template/new");
  }

  const primaryAction = {
    content: t("secure.facility.settings.tee_sheets.tee_sheet_templates.001"),
    action: navigateToNewTemplate,
  };

  return (
    <Page
      title={t("secure.facility.settings.tee_sheets.tee_sheet_templates.002")}
      subtitle={t("secure.facility.settings.tee_sheets.tee_sheet_templates.003")}
      narrow
      primaryAction={primaryAction}
    >
      <hr className="my-8" />
      <DataTable
        columns={[
          { label: t("secure.facility.settings.tee_sheets.tee_sheet_templates.004") },
          { label: "Description" },

          { label: "Version" },
          { label: t("secure.facility.settings.tee_sheets.tee_sheet_templates.005") },
          { label: t("secure.facility.settings.tee_sheets.tee_sheet_templates.007") },
        ]}
        loading={!state.templates}
        className="ui-table ui-table-clickable"
      >
        {state.templates?.map((template, index) => {
          return (
            <tr
              key={template.id}
              onClick={() => navigateTo("/admin/settings/tee-sheet/template/" + String(template.id))}
            >
              <td>{template.name}</td>
              <td>{template.description}</td>

              <td>{template.version}</td>
              <td>{capitalize(template.format)}</td>
              <td>{template.holes}</td>
            </tr>
          );
        })}
      </DataTable>
    </Page>
  );
}
