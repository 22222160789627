import { APIGet, APIPost, APIPut, APIDelete } from "api/protocols";
import { CancelToken } from "axios";
import { ILeagueParticipant } from "redux/reducers/models/league";

const apiVersion = "2024-04";
const adminType = "facility-admin";
const baseLeagueUrl = "/" + apiVersion + "/" + adminType + "/league";

//GET Players
//Retrieve a list of players in a specific league
export async function GetParticipant(
  params: { id?: string | number; league_id?: string | number; league_division_id?: string | number; limit?: number },
  useGlobalLoader: boolean,
  token?: CancelToken,
) {
  return await APIGet<ILeagueParticipant[]>(baseLeagueUrl + "/participant", params, useGlobalLoader, token);
}

//POST New Player
//Add new player to leage
export async function PostParticipant(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseLeagueUrl + "/participant", params, useGlobalLoader);
}

// Update Player
//Update player within a league
export async function PutParticipant(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseLeagueUrl + "/participant", params, useGlobalLoader);
}

type DeleteLeagueParticipant = {
  participant_id: number;
};
// Delete Player
//Delete player within a league
export async function DeleteLeagueParticipant(params: DeleteLeagueParticipant, useGlobalLoader: boolean) {
  return await APIDelete(baseLeagueUrl + "/participant", params, useGlobalLoader);
}

export async function PutExportParticipants(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseLeagueUrl + "/participant/export", params, useGlobalLoader);
}

//Import League Players
export async function ImportLeaguePlayers(params: any, useGlobalLoader: boolean) {
  return await APIPost(baseLeagueUrl + "/participant/import", params, useGlobalLoader);
}

export async function CopyLeagueParticipants(params: any, useGlobalLoader: boolean) {
  return await APIPut(baseLeagueUrl + "/participant/copy", params, useGlobalLoader);
}
