import { useTranslation } from "react-i18next";
import {
  TCompetitionFormat,
  TCompetitionFormatHoles,
  TCompetitionHandicap,
  TCompetitionOrganization,
  TCompetitionScoreCombo,
  TCompetitionTeamScores,
  TPlayerCompetition,
  TTeamCompetition,
  TCompetitionFormatPointAllocation,
} from "redux/reducers/models/league";

export default function useLeagueScoringFormatOptions() {
  const { t } = useTranslation();

  const organizationOptions: Array<{ id: TCompetitionOrganization; label: string }> = [
    { id: "individual", label: "Individual" }, // TODO: Translation
    { id: "team", label: "Teams" }, // TODO: Translation
  ];

  const formatOptions: Array<{ id: TCompetitionFormat; label: string }> = [
    // { id: "match_play", label: "Match Play" },
    { id: "stableford", label: "Stableford" }, // TODO: Translation
    { id: "stroke_play", label: "Stroke Play" }, // TODO: Translation
    { id: "points_by_score", label: "Points by Score" }, // TODO: Translation
    { id: "skins", label: "Skins" }, // TODO: Translation
  ];

  const formatHoleOptions: Array<{ id: TCompetitionFormatHoles; label: string }> = [
    { id: "9_holes", label: "9 Holes" }, // TODO: Translation
    { id: "18_holes", label: "18 Holes" }, // TODO: Translation
  ];

  const handicapOptions: Array<{ id: TCompetitionHandicap; label: string }> = [
    { id: "gross", label: "Gross" }, // TODO: Translation
    { id: "internal_net", label: "Net" }, // TODO: Translation
    // { id: "internal_net_off_lowest", label: "Internal Net Off Lowest" }, // TODO: Translation
  ];

  const scoreComboOptions: Array<{ id: TCompetitionScoreCombo; label: string }> = [
    { id: "all", label: "All" }, // TODO: Translation
    { id: "gross_first", label: "Gross First" }, // TODO: Translation
    { id: "net_first", label: "Net First" }, // TODO: Translation
  ];

  const teamScoreOptions: Array<{ id: TCompetitionTeamScores; label: string }> = [
    { id: "gross_scores", label: "Gross Scores" }, // TODO: Translation
    { id: "net_scores", label: "Net Scores" }, // TODO: Translation
    { id: "best_scores", label: "Best Scores" }, // TODO: Translation
  ];

  const playerCompetitionOptions: Array<{
    id: TPlayerCompetition | TTeamCompetition;
    label: string;
    organization: TCompetitionOrganization;
  }> = [
    { id: "player_vs_field", label: "Player vs. Field", organization: "individual" }, // TODO: Translation
    { id: "player_vs_flight", label: "Player vs. Flight", organization: "individual" }, // TODO: Translation
    { id: "team_vs_field", label: "Team vs. Field", organization: "team" }, // TODO: Translation
  ];

  const nineHolePoints: Array<{ id: string; label: string; points: number }> = [
    { id: "four_over", label: "4 or More", points: 1 }, // TODO: Translation
    { id: "three_over", label: "3", points: 2 },
    { id: "two_over", label: "2", points: 2 },
    { id: "one_over", label: "1", points: 3 },
    { id: "even", label: "Even", points: 3 },
    { id: "one_under", label: "-1", points: 4 },
    { id: "two_under", label: "-2", points: 4 },
    { id: "three_under", label: "-3", points: 5 },
    { id: "four_under", label: "-4", points: 5 },
    { id: "five_under", label: "-5", points: 6 },
    { id: "six_under", label: "-6", points: 6 },
    { id: "seven_under", label: "-7 or Less", points: 7 }, // TODO: Translation
  ];

  const eighteenHolePoints: Array<{ id: string; label: string; points: number }> = [
    { id: "eight_over", label: "8 or More", points: 1 }, // TODO: Translation
    { id: "seven_over", label: "7", points: 2 },
    { id: "six_over", label: "6", points: 3 },
    { id: "five_over", label: "5", points: 3 },
    { id: "four_over", label: "4", points: 4 },
    { id: "three_over", label: "3", points: 4 },
    { id: "two_over", label: "2", points: 5 },
    { id: "one_over", label: "1", points: 5 },
    { id: "even", label: "Even", points: 6 },
    { id: "one_under", label: "-1", points: 7 },
    { id: "two_under", label: "-2", points: 8 },
    { id: "three_under", label: "-3", points: 9 },
    { id: "four_under", label: "-4", points: 10 },
    { id: "five_under", label: "-5", points: 11 },
    { id: "six_under", label: "-6", points: 12 },
    { id: "seven_under", label: "-7", points: 13 },
    { id: "eight_under", label: "-8", points: 14 },
    { id: "nine_under", label: "-9", points: 15 },
    { id: "ten_under", label: "-10 or Less", points: 16 }, // TODO: Translation
  ];

  const pointAllocationOptions: Array<{ id: TCompetitionFormatPointAllocation; label: string }> = [
    // { id: "custom", label: "Custom" }, // TODO: Translation
    { id: "posted_points", label: "Posted Points" }, // TODO: Translation
    { id: "posted", label: "Posted Score" }, // TODO: Translation
  ];

  return {
    organizationOptions,
    formatOptions,
    formatHoleOptions,
    handicapOptions,
    scoreComboOptions,
    teamScoreOptions,
    playerCompetitionOptions,
    nineHolePoints,
    eighteenHolePoints,
    pointAllocationOptions,
  };
}
